<div class="row main-row app-accordion-row">
  <div class="col-12">
    <app-card
      header="contract-monitoring.title"
      detail="contract-monitoring.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-accordion>
        <app-accordion-item
          header="contract-monitoring.accordion.contractClosed"
          [isExpanded]="true"
        >
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
            *ngIf="!formLoading"
          >
            <ng-container
              *ngIf="
                contract?.dealingAgreementType.name ===
                global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN
              "
            >
              <div
                class="form-group row text-sm-right"
                [ngClass]="!formGroup.isView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'contract-monitoring.form.date' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-date-picker formControlName="date" [maxDate]="endDate">
                  </app-date-picker>
                </div>
              </div>
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'contract-monitoring.form.file' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-upload
                    formControlName="contractFile"
                    [fileUploader]="fileUploader"
                  >
                  </app-upload>
                </div>
              </div>
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'contract-monitoring.form.description' | translate
                }}</label>
                <div
                  [ngClass]="
                    !formGroup.isView
                      ? 'col-sm-9 text-left'
                      : 'col-sm-6 text-left'
                  "
                >
                  <app-text-area formControlName="description"> </app-text-area>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                contract?.dealingAgreementType.name !==
                global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN
              "
            >
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'contract-monitoring.form.requestBy' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  {{ requestBy ? requestBy.name : '-' }}
                </div>
              </div>
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'contract-monitoring.form.file' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-upload
                    formControlName="contractFile"
                    [fileUploader]="fileUploader"
                  >
                  </app-upload>
                </div>
              </div>
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'contract-monitoring.form.description' | translate
                }}</label>
                <div class="col-sm-6 text-left">
                  <app-text-area formControlName="description" maxLength="512">
                  </app-text-area>
                </div>
              </div>
              <div
                class="form-group row text-sm-right"
                [ngClass]="!formGroup.isView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'contract-monitoring.form.date' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-date-picker formControlName="date" [maxDate]="endDate">
                  </app-date-picker>
                </div>
              </div>
            </ng-container>
          </form>
        </app-accordion-item>
        <app-accordion-item
          header="contract-monitoring.accordion.procurementInformation"
        >
          <app-procurement-information-view
            *ngIf="!formLoading"
            [procurementVendorId]="contract.procurementVendor.id"
          >
          </app-procurement-information-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-monitoring.accordion.requestInformation"
        >
          <app-contract-request-view [contractId]="contractId">
          </app-contract-request-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-monitoring.accordion.contractInformation"
        >
          <app-procurement-information-view [contractId]="contractId">
          </app-procurement-information-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-monitoring.accordion.vendorInformation"
        >
          <app-vendor-payment-information
            [procurementVendorId]="contract.procurementVendor.id"
            [todo]="todo"
            [temp]="formGroup.value"
          ></app-vendor-payment-information>
        </app-accordion-item>
      </app-accordion>
      <div class="floating-button mt-5">
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button
                mode="BYPASS"
                color="SECONDARY"
                (onClick)="doCancel()"
                class="mr-1"
                [disabled]="formSaving"
                >{{ 'app.button.back' | translate }}
              </app-button>
              <app-button
                *ngIf="!formGroup.isView"
                color="PRIMARY"
                (onClick)="doSave()"
                [disabled]="formSaving"
                >{{ 'app.button.save' | translate }}</app-button
              >
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
