import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  Optional,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { ActiveDropDown } from '../app-drop-down/active-drop-down.service';
import { AppPopupService } from '../app-popup/app-popup.service';
import { TreeItemModel, TreeModel } from '../app-tree-x';
@Component({
  selector: 'app-combo-box-tree',
  templateUrl: './app-combo-box-tree.component.html',
  styleUrls: ['./app-combo-box-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: makeProvider(AppComboBoxTreeComponent)
})
export class AppComboBoxTreeComponent extends BaseValueAccessorComponent<string> {
  @Input() id: any;
  @Input() isMultiple: boolean;
  @Input() header: string;
  @Input() onlyLastChild: boolean;
  @Input() limitLevel: number;
  @Input() isLazy: boolean;
  @ContentChild('option') optionTmpl: TemplateRef<any>;
  @ContentChild('value') valueTmpl: TemplateRef<any>;
  treeModel: TreeModel;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    private activeDropDown: ActiveDropDown,
    public popupService: AppPopupService
  ) {
    super('app-combo-box-tree', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    if (this.formControl) {
      this.setFormControlValue();
    }
    this.doBuildTreeModel();
  }

  setFormControlValue(): void {
    if (this.isMultiple && this.formControl.value === null && !this.ISVIEW) {
      this.formControl.patchValue([]);
    }
  }

  doBuildTreeModel(): void {
    this.treeModel = new TreeModel(
      this.stringUrl.slice(1),
      'READ',
      this.id,
      this.isMultiple
    );
    this.treeModel.onlyLastChild = this.onlyLastChild;
    this.treeModel.limitLevel = this.limitLevel;
  }

  doClick(): void {
    this.treeModel.checkedTreeItemListTemp = Array.from(
      this.formControl.value || []
    );
    this.formControl.markAllAsTouched();
  }

  onChangeTree(event: TreeItemModel[]): void {
    this.activeDropDown.close();
    this.formControl.patchValue(event);
    this.onChange.emit(event);
  }

  onChoose(): void {
    if (
      this.treeModel.onlyLastChild &&
      this.treeModel.value &&
      [...this.treeModel.value].pop().hasChild &&
      ((this.treeModel.limitLevel &&
        this.treeModel.value.length - 1 !== this.treeModel.limitLevel) ||
        !this.treeModel.limitLevel)
    ) {
      this.global.alertService.showError(
        'app.validation.chooseLastLevel',
        '.app-tree-x',
        { totalAlert: 1 }
      );
    } else {
      this.activeDropDown.close();
      this.formControl.patchValue(this.treeModel.value);
      this.onChange.emit(this.treeModel.value);
    }
  }
}
