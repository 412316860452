import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppBadgeModule } from '../../components/app-badge/app-badge.module';
import { AppWidgetContentSliderModule } from '../app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetPrStatusComponent } from './app-widget-pr-status.component';
@NgModule({
  imports: [
    VendorModule,
    AppWidgetModule,
    AppWidgetContentSliderModule,
    AppBadgeModule
  ],
  declarations: [AppWidgetPrStatusComponent],
  entryComponents: [AppWidgetPrStatusComponent],
  exports: [AppWidgetPrStatusComponent]
})
export class AppWidgetPrStatusModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-pr-status', AppWidgetPrStatusComponent);
  }
}
