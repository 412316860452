<app-layout [isLoading]="formLoading">
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge-catalog
      class="cml-2"
      *ngIf="procurement?.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge
      class="cml-2"
      [color]="
        badgeColor[
          complianceTestResponse.complianceTest.complianceTestStatus.code
        ]
      "
    >
      {{
        complianceTestResponse.complianceTest.complianceTestStatus.translationKey.module.code.toLowerCase() +
          '.' +
          complianceTestResponse.complianceTest.complianceTestStatus
            .translationKey.key | translate
      }}
    </app-badge>
  </ng-template>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form [formGroup]="formGroup" *ngIf="!formLoading">
        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="complianceTestResponse.workflowStepInfoModel"
          *ngIf="!formLoading"
        >
        </app-workflow-step-info>
        <app-card
          header="compliance-test.detail.procurementDetail.title"
          tips="compliance-test.detail.procurementDetail.tips"
        >
          <app-procurement-info [procurementId]="procurementId">
          </app-procurement-info>
        </app-card>
        <app-item-request
          [objectList]="complianceTestResponse.procurementItemList"
          [isShowPrice]="true"
          [itemTypeList]="complianceTestResponse.itemTypeList"
          [isView]="true"
          *ngIf="!formLoading"
        ></app-item-request>
        <app-card
          *ngIf="!formLoading"
          header="compliance-test.detail.complianceType.title"
          tips="compliance-test.detail.complianceType.tips"
        >
          <div class="border-compliance-type">
            <div
              class="d-flex align-items-center"
              [ngClass]="
                roleCode === global.appConstant.ROLE_CODE_BUYER &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code !==
                  global.appConstant.pm
                    .COMPLIANCE_TEST_STATUS_REVISION_BY_COMPLIANCE &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code !==
                  global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION &&
                !isViewOnly &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code !== global.appConstant.pm.COMPLIANCE_TEST_STATUS_DONE
                  ? 'justify-content-between'
                  : ''
              "
            >
              <div>
                <h4>{{
                  complianceType.nameTranslationKey.module.code.toLowerCase() +
                    '.' +
                    complianceType.nameTranslationKey.key | translate
                }}</h4>
                {{
                  complianceType.descTranslationKey.module.code.toLowerCase() +
                    '.' +
                    complianceType.descTranslationKey.key | translate
                }}
              </div>
              <app-button
                (onClick)="doChangeComplianceType()"
                *ngIf="
                  roleCode === global.appConstant.ROLE_CODE_BUYER &&
                  complianceTestResponse.complianceTest.complianceTestStatus
                    .code !==
                    global.appConstant.pm
                      .COMPLIANCE_TEST_STATUS_REVISION_BY_COMPLIANCE &&
                  complianceTestResponse.complianceTest.complianceTestStatus
                    .code !==
                    global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION &&
                  !isViewOnly &&
                  complianceTestResponse.complianceTest.complianceTestStatus
                    .code !== global.appConstant.pm.COMPLIANCE_TEST_STATUS_DONE
                "
                >{{
                  'compliance-test.detail.button.change' | translate
                }}</app-button
              >
            </div>
            <h5
              class="separator mb-3"
              *ngIf="
                complianceType.code ===
                global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT
              "
            ></h5>
            <div
              class="row text-sm-left"
              *ngIf="
                complianceType.code ===
                global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT
              "
            >
              <label class="col-sm-3">
                {{ 'compliance-test.detail.form.complianceNote' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-text-area
                  formControlName="complianceNote"
                  size="XL"
                  maxLength="512"
                ></app-text-area>
              </div>
            </div>
          </div>
        </app-card>
        <app-card
          *ngIf="!formLoading"
          header="compliance-test.detail.procurementDocument.title"
          tips="compliance-test.detail.procurementDocument.tips"
        >
          <ng-template #headerRight>
            <app-button (onClick)="doProcurementSummary()">{{
              'compliance-test.detail.button.procurementSummary' | translate
            }}</app-button>
          </ng-template>
          <app-fullfilled *ngIf="!isViewOnly"></app-fullfilled>
          <app-accordion [isLoading]="formLoading" [isExpandAll]="true">
            <app-accordion-item
              header="{{ procurementModel.complianceTestGroup.name }}"
              [isExpanded]="true"
              *ngFor="
                let procurementModel of complianceTestResponse.procurementModelList;
                let i = index
              "
            >
              <app-table-xx
                *ngIf="
                  (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                    complianceType.code ===
                      global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT) ||
                  (roleCode !== global.appConstant.ROLE_CODE_BUYER &&
                    complianceType.code ===
                      global.appConstant.pm
                        .COMPLIANCE_TYPE_NON_SELF_ASSESSMENT) ||
                  (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                    complianceType.code ===
                      global.appConstant.pm
                        .COMPLIANCE_TYPE_NON_SELF_ASSESSMENT &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION) ||
                  complianceTestResponse.isUserApproval
                "
                [model]="tableResponseProcurementList[i]"
                [isShowPagination]="false"
                [isShowSearch]="false"
                (onAfterRowCreated)="onAfterRowCreated($event)"
              >
              </app-table-xx>
              <app-table-xx
                *ngIf="
                  roleCode === global.appConstant.ROLE_CODE_BUYER &&
                  complianceType.code ===
                    global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT &&
                  (complianceTestResponse.complianceTest.complianceTestStatus
                    .code ===
                    global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW ||
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code ===
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT ||
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code ===
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION)
                "
                [model]="tableResponseProcurementList[i]"
                [isShowPagination]="false"
                [isShowSearch]="false"
                (onAfterRowCreated)="onAfterRowCreated($event)"
              >
              </app-table-xx>
            </app-accordion-item>
          </app-accordion>
        </app-card>
        <app-card
          *ngIf="!formLoading"
          header="compliance-test.detail.vendorDocument.title"
          tips="compliance-test.detail.vendorDocument.tips"
        >
          <ng-template #headerRight>
            <app-button (onClick)="doVendorWinnerInfo()">{{
              'compliance-test.detail.button.vendorWinnerInfo' | translate
            }}</app-button>
          </ng-template>
          <app-fullfilled *ngIf="!isViewOnly"></app-fullfilled>
          <app-accordion [isLoading]="formLoading" [isExpandAll]="true">
            <app-accordion-item
              header="{{ vendorModel.procurementVendor.vendor.name }}"
              [isExpanded]="true"
              *ngFor="
                let vendorModel of complianceTestResponse.vendorModelList;
                let i = index
              "
            >
              <app-table-xx
                *ngIf="
                  (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                    complianceType.code ===
                      global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT) ||
                  (roleCode !== global.appConstant.ROLE_CODE_BUYER &&
                    complianceType.code ===
                      global.appConstant.pm
                        .COMPLIANCE_TYPE_NON_SELF_ASSESSMENT) ||
                  (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                    complianceType.code ===
                      global.appConstant.pm
                        .COMPLIANCE_TYPE_NON_SELF_ASSESSMENT &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION) ||
                  complianceTestResponse.isUserApproval
                "
                [model]="tableResponseVendorList[i]"
                [isShowPagination]="false"
                [isShowSearch]="false"
                (onAfterRowCreated)="onAfterRowCreated($event)"
              >
              </app-table-xx>
              <app-table-xx
                *ngIf="
                  roleCode === global.appConstant.ROLE_CODE_BUYER &&
                  complianceType.code ===
                    global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT &&
                  (complianceTestResponse.complianceTest.complianceTestStatus
                    .code ===
                    global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW ||
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code ===
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT ||
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code ===
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION)
                "
                [model]="tableResponseVendorList[i]"
                [isShowPagination]="false"
                [isShowSearch]="false"
                (onAfterRowCreated)="onAfterRowCreated($event)"
              >
              </app-table-xx>
            </app-accordion-item>
          </app-accordion>
        </app-card>
        <app-card
          *ngIf="!formLoading"
          header="compliance-test.detail.complianceSummary.title"
          tips="compliance-test.detail.complianceSummary.tips"
        >
          <app-fullfilled
            *ngIf="
              !isViewOnly &&
              ((roleCode === global.appConstant.ROLE_CODE_BUYER &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT) ||
                (roleCode !== global.appConstant.ROLE_CODE_BUYER &&
                  complianceType.code ===
                    global.appConstant.pm
                      .COMPLIANCE_TYPE_NON_SELF_ASSESSMENT)) &&
              !isSummaryValid
            "
          ></app-fullfilled>
          <div
            class="form-group row text-sm-left"
            *ngIf="
              (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT) ||
              (roleCode !== global.appConstant.ROLE_CODE_BUYER &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT) ||
              (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code !== global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code !==
                  global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code !==
                  global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION &&
                complianceTestResponse.isRevise) ||
              (complianceTestResponse.isUserApproval &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT)
            "
          >
            <label class="col-sm-3">
              {{ 'compliance-test.detail.form.summary' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              <app-text-area
                formControlName="summaryNote"
                size="XL"
                maxLength="512"
                (onChange)="onChangeSummary()"
              ></app-text-area>
            </div>
          </div>
          <div
            class="form-group row text-sm-left"
            *ngIf="
              (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT) ||
              (roleCode !== global.appConstant.ROLE_CODE_BUYER &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT) ||
              (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code !== global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code !==
                  global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code !==
                  global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION &&
                complianceTestResponse.isRevise) ||
              (complianceTestResponse.isUserApproval &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT)
            "
          >
            <label class="col-sm-3 optional">
              {{ 'compliance-test.detail.form.attachmentFile' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              <app-upload-x
                formControlName="attachmentFile"
                [fileUploader]="fileUploader"
              ></app-upload-x>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'compliance-test.detail.form.preparedBy' | translate }}
            </label>
            <div
              class="col-sm-6 text-left mb-3"
              *ngIf="
                (!isViewOnly &&
                  roleCode === global.appConstant.ROLE_CODE_BUYER) ||
                (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                  (complianceTestResponse.complianceTest.complianceTestStatus
                    .code ===
                    global.appConstant.pm
                      .COMPLIANCE_TEST_STATUS_REVISION_BY_COMPLIANCE ||
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code ===
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_DONE))
              "
            >
              <app-button (onClick)="doAddMorePreparedByUser()"
                ><em class="pir pi-user-plus"></em>&nbsp;{{
                  'compliance-test.detail.button.addMoreUser' | translate
                }}</app-button
              >
            </div>
            <div
              class="col-sm-3"
              *ngIf="
                (!isViewOnly &&
                  roleCode === global.appConstant.ROLE_CODE_BUYER) ||
                (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                  (complianceTestResponse.complianceTest.complianceTestStatus
                    .code ===
                    global.appConstant.pm
                      .COMPLIANCE_TEST_STATUS_REVISION_BY_COMPLIANCE ||
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code ===
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_DONE))
              "
            ></div>
            <div
              class="col-sm-3"
              *ngIf="
                (!isViewOnly &&
                  roleCode === global.appConstant.ROLE_CODE_BUYER) ||
                (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                  (complianceTestResponse.complianceTest.complianceTestStatus
                    .code ===
                    global.appConstant.pm
                      .COMPLIANCE_TEST_STATUS_REVISION_BY_COMPLIANCE ||
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code ===
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_DONE))
              "
            ></div>
            <div class="col-sm-6 text-left">
              <app-table-xx
                [model]="tableResponsePreparedBy"
                [isMultipleSelect]="true"
                [isShowCheckBox]="
                  isViewOnly || roleCode !== global.appConstant.ROLE_CODE_BUYER
                    ? false
                    : true
                "
                [isShowPagination]="false"
                [isShowSearch]="false"
              >
                <ng-container
                  *ngIf="
                    (!isViewOnly &&
                      roleCode === global.appConstant.ROLE_CODE_BUYER) ||
                    (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                      (complianceTestResponse.complianceTest
                        .complianceTestStatus.code ===
                        global.appConstant.pm
                          .COMPLIANCE_TEST_STATUS_REVISION_BY_COMPLIANCE ||
                        complianceTestResponse.complianceTest
                          .complianceTestStatus.code ===
                          global.appConstant.pm.COMPLIANCE_TEST_STATUS_DONE))
                  "
                >
                  <ng-template #actionButtons let-rowData>
                    <app-button
                      size="TN"
                      (onClick)="doDeletePreparedByUser(rowData)"
                      title="{{ 'app.tooltip.delete' | translate }}"
                    >
                      <em class="pir pi-trash-alt"></em>
                      {{ 'app.button.delete' | translate }}
                    </app-button>
                  </ng-template>
                </ng-container>
              </app-table-xx>
            </div>
          </div>
          <div
            class="form-group row text-sm-left"
            *ngIf="
              (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT) ||
              (roleCode !== global.appConstant.ROLE_CODE_BUYER &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT) ||
              (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code !== global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code !==
                  global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code !==
                  global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION &&
                complianceTestResponse.isRevise) ||
              (complianceTestResponse.isUserApproval &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT)
            "
          >
            <label class="col-sm-3">
              {{ 'compliance-test.detail.form.checkedBy' | translate }}
            </label>
            <div
              class="col-sm-6 text-left mb-3"
              *ngIf="
                ((roleCode === global.appConstant.ROLE_CODE_BUYER &&
                  complianceType.code ===
                    global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT) ||
                  (roleCode !== global.appConstant.ROLE_CODE_BUYER &&
                    complianceType.code ===
                      global.appConstant.pm
                        .COMPLIANCE_TYPE_NON_SELF_ASSESSMENT) ||
                  (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                    complianceType.code ===
                      global.appConstant.pm
                        .COMPLIANCE_TYPE_NON_SELF_ASSESSMENT &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION)) &&
                !formGroup.isView
              "
            >
              <app-button (onClick)="doAddMoreCheckedByUser()"
                ><em class="pir pi-user-plus"></em>&nbsp;{{
                  'compliance-test.detail.button.addMoreUser' | translate
                }}</app-button
              >
            </div>
            <div
              class="col-sm-3"
              *ngIf="
                ((roleCode === global.appConstant.ROLE_CODE_BUYER &&
                  complianceType.code ===
                    global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT) ||
                  (roleCode !== global.appConstant.ROLE_CODE_BUYER &&
                    complianceType.code ===
                      global.appConstant.pm
                        .COMPLIANCE_TYPE_NON_SELF_ASSESSMENT) ||
                  (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                    complianceType.code ===
                      global.appConstant.pm
                        .COMPLIANCE_TYPE_NON_SELF_ASSESSMENT &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION) ||
                  (complianceTestResponse.isUserApproval &&
                    complianceType.code ===
                      global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT)) &&
                !formGroup.isView
              "
            ></div>
            <div
              class="col-sm-3"
              *ngIf="
                ((roleCode === global.appConstant.ROLE_CODE_BUYER &&
                  complianceType.code ===
                    global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT) ||
                  (roleCode !== global.appConstant.ROLE_CODE_BUYER &&
                    complianceType.code ===
                      global.appConstant.pm
                        .COMPLIANCE_TYPE_NON_SELF_ASSESSMENT) ||
                  (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                    complianceType.code ===
                      global.appConstant.pm
                        .COMPLIANCE_TYPE_NON_SELF_ASSESSMENT &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT &&
                    complianceTestResponse.complianceTest.complianceTestStatus
                      .code !==
                      global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION) ||
                  (complianceTestResponse.isUserApproval &&
                    complianceType.code ===
                      global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT)) &&
                !formGroup.isView
              "
            ></div>
            <div class="col-sm-6 text-left">
              <app-table-xx
                [model]="tableResponseCheckedBy"
                [isMultipleSelect]="true"
                [isShowCheckBox]="formGroup.isView ? false : true"
                [isShowPagination]="false"
                [isShowSearch]="false"
              >
                <ng-container *ngIf="!formGroup.isView">
                  <ng-template #actionButtons let-rowData>
                    <app-button
                      size="TN"
                      (onClick)="doDeleteCheckedByUser(rowData)"
                      title="{{ 'app.tooltip.delete' | translate }}"
                    >
                      <em class="pir pi-trash-alt"></em>
                      {{ 'app.button.delete' | translate }}
                    </app-button>
                  </ng-template>
                </ng-container>
              </app-table-xx>
            </div>
          </div>
        </app-card>
        <app-card
          *ngIf="
            !formLoading &&
            ((roleCode !== global.appConstant.ROLE_CODE_BUYER &&
              complianceType.code ===
                global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT) ||
              (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT) ||
              (complianceTestResponse.isUserApproval &&
                complianceType.code ===
                  global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT))
          "
          header="compliance-test.detail.officialReport.title"
          tips="compliance-test.detail.officialReport.tips"
        >
          <app-alert-x
            color="WARNING"
            *ngIf="!isOfficialReportValid && !isViewOnly"
            >{{
              'compliance-test.detail.officialReport.alertInfo' | translate
            }}</app-alert-x
          >
          <app-official-report
            *ngIf="!formLoading"
            [objectId]="procurementId"
            [templateCode]="
              global.appConstant.pm
                .OFFICIAL_REPORT_CODE_ORT_COMPLIANCE_TEST_LETTER
            "
            [appOfficialReportModel]="appOfficialReportModel"
            [mode]="
              (roleCode !== global.appConstant.ROLE_CODE_BUYER &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code ===
                  global.appConstant.pm
                    .COMPLIANCE_TEST_STATUS_WAITING_REVIEW) ||
              (roleCode === global.appConstant.ROLE_CODE_BUYER &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code === global.appConstant.pm.COMPLIANCE_TEST_STATUS_DONE)
                ? 'edit'
                : todo
            "
            (onChange)="doOnChange($event)"
            [isLoading]="formLoading"
            numberGeneratorCode="NUMBER"
          ></app-official-report>
        </app-card>

        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            (complianceTestResponse.complianceTest.complianceTestStatus.code ===
              global.appConstant.pm.COMPLIANCE_TEST_STATUS_WAITING_APPROVAL ||
              complianceTestResponse.complianceTest.complianceTestStatus
                .code ===
                global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION) &&
            complianceTestResponse.approvalPathResponseModel &&
            !complianceTestResponse.isUserApproval
          "
          [workflowPrcs]="complianceTestResponse.procurement?.workflowPrcs"
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="
            complianceTestResponse.approvalPathResponseModel
          "
        >
        </app-approval-monitoring>

        <div
          class="floating-button mt-5"
          *ngIf="
            !formLoading &&
            ((!isViewOnly &&
              ((roleCode === global.appConstant.ROLE_CODE_BUYER &&
                complianceTestResponse.complianceTest.complianceTestStatus
                  .code !==
                  global.appConstant.pm
                    .COMPLIANCE_TEST_STATUS_WAITING_REVIEW) ||
                (roleCode !== global.appConstant.ROLE_CODE_BUYER &&
                  complianceTestResponse.complianceTest.complianceTestStatus
                    .code !==
                    global.appConstant.pm
                      .COMPLIANCE_TEST_STATUS_REVISION_BY_COMPLIANCE)) &&
              complianceTestResponse.complianceTest.complianceTestStatus
                .code !==
                global.appConstant.pm
                  .COMPLIANCE_TEST_STATUS_WAITING_APPROVAL) ||
              complianceTestResponse.complianceTest.complianceTestStatus
                .code === global.appConstant.pm.COMPLIANCE_TEST_STATUS_DONE)
          "
        >
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center">
                <app-button
                  [outline]="true"
                  color="SECONDARY"
                  (onClick)="doSave()"
                  [disabled]="formSaving"
                  >{{ 'app.button.save' | translate }}</app-button
                >
                <app-button (onClick)="doSubmit()" [disabled]="formSaving">{{
                  'app.button.submit' | translate
                }}</app-button>
              </div>
            </div>
          </div>
        </div>
        <app-approval-prcs-x
          *ngIf="!formLoading && complianceTestResponse.isUserApproval"
          [workflowModelPrcs]="
            complianceTestResponse.procurement?.workflowModelPrcs
          "
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
