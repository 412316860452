<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="stage-of-work.title" detail="app.action.{{ todo }}"
      [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'stage-of-work.form.code' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field type="code" autofocus="true" formControlName="code"
              maxLength="64"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'stage-of-work.form.name' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field type="alphanumeric"
              formControlName="name" maxLength="128"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'stage-of-work.form.description' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxLength="512">
            </app-text-area>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button color="SECONDARY" class="mr-1" (onClick)="doCancel()"
            [disabled]="formSaving">
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button color="PRIMARY" (onClick)="doSave()"
            [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>

      </form>
    </app-card>
  </div>
</div>