import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppChatModule } from 'src/app/core/components/app-chat/app-chat.module';
import { AppSessionTimerModule } from 'src/app/core/components/app-session-timer/app-session-timer.module';
import { AppSignatureModule } from 'src/app/core/components/app-signature/app-signature.module';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AanwijzingOthersPopupSessionComponent } from './aanwijzing-others-popup-session.component';
import { AanwijzingOthersSessionComponent } from './aanwijzing-others-session.component';
import { AanwijzingOthersComponent } from './aanwijzing-others.component';
export const routes = [
    {
      path: 'join-session/:id',
      component: AanwijzingOthersComponent,
      data: { breadcrumb: '' }
    },
    {
        path: 'session-room',
        component: AanwijzingOthersSessionComponent,
        data: { breadcrumb: '' }
    }
];
@NgModule({
  imports: [RouterModule.forChild(routes),
    SharedModule,
    AppSignatureModule,
    AppSessionTimerModule,
    AppChatModule
  ],
  declarations: [
    AanwijzingOthersComponent,
    AanwijzingOthersSessionComponent,
    AanwijzingOthersPopupSessionComponent
]
})
export class AanwijzingOthersModule {}
