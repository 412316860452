import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../../../../../core/base/angular/base-component.component';
import { CatalogCategory } from '../../../../../../core/bean/catalog-category';
import { CatalogSpecification } from '../../../../../../core/bean/catalog-specification';
import { VendorSegmentation } from '../../../../../../core/bean/vendor-segmentation';
@Component({
  selector: 'app-catalog-tab',
  templateUrl: './app-catalog-tab.component.html',
  styleUrls: ['./app-catalog-tab.component.scss']
})
export class AppCatalogTabComponent extends BaseComponentComponent {
  @Input() model: any;
  constructor(public currencyPipe: CurrencyPipe) {
    super('app-catalog-tab');
  }

  public onInit(): void {
    this.setStateReady();
  }

  public setCategories(catalogCategoryList: CatalogCategory[]): string {
    let categoryNames = '';
    catalogCategoryList.forEach((catalogCategory, i) => {
      categoryNames += catalogCategory.itemCategory.name;
      if (i !== catalogCategoryList.length - 1) {
        categoryNames += ', ';
      }
    });

    return categoryNames;
  }

  public setSegmentations(
    vendorSegmentationList: VendorSegmentation[]
  ): string {
    let segmentationNames = '';
    vendorSegmentationList.forEach((vendorSegmentation, i) => {
      segmentationNames += vendorSegmentation.segmentation.name;
      if (i !== vendorSegmentationList.length - 1) {
        segmentationNames += ', ';
      }
    });

    return segmentationNames;
  }

  public doParseValue(catalogSpesification: CatalogSpecification): string {
    let value = catalogSpesification.value;
    const parsedValue: any = JSON.parse(value);
    if (parsedValue) {
      if (Array.isArray(parsedValue)) {
        value = '';
        parsedValue.forEach((valueObject, i) => {
          value += valueObject.name;
          if (i !== parsedValue.length - 1) {
            value += ', ';
          }
        });
      } else if (typeof parsedValue === 'string') {
        value = parsedValue;
      } else if (
        catalogSpesification.catalogAttribute.formObject.code === 'FIELD-PRICE'
      ) {
        const price = this.currencyPipe.transform(
          parsedValue.price,
          '',
          '',
          '1.2-2'
        );
        value = price + ' ' + parsedValue.currency.code;
      } else {
        value = parsedValue.name;
      }
    } else {
      value = '-';
    }

    return value;
  }

  public doConvertToDate(value: string): Date {
    const parsedValue = JSON.parse(value);
    return new Date(parsedValue);
  }
}
