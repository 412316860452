import { BaseEntity } from '../base/base-entity';
import { Icon } from '../bean/icon';
import { Module } from '../bean/module';

export class PushNotificationEntity extends BaseEntity {
  module: Module;
  name: string;
  icon: Icon;
  description: string;
  isActive: boolean;
  keyword: string;
}
