import { DatePipe } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { TextUtils } from '../../utils';
import { StepperResponseModel } from '../app-stepper/stepper-response-model';
import { WorkflowStepInfoModel } from './model/workflow-step-info-model';
@Component({
  selector: 'app-workflow-step-info',
  templateUrl: './app-workflow-step-info.component.html',
  styleUrls: ['./app-workflow-step-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWorkflowStepInfoComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() public model: WorkflowStepInfoModel;
  @Input() public isLoading: boolean;

  public generatedId: string = TextUtils.generateRandomString();
  public stepperModel = new StepperResponseModel('text');
  constructor(public datePipe: DatePipe) {
    super('app-workflow-step-info');
  }

  onInit(): void {
    this.setStepperRecordList();
    this.setStateReady();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (
      simpleChanges.isLoading &&
      simpleChanges.isLoading.currentValue !==
        simpleChanges.isLoading.previousValue
    ) {
      this.setStepperRecordList();
    }
  }

  public setStepperRecordList(): void {
    if (!this.isLoading) {
      this.stepperModel.setRecordList(this.model.workflowStepModelList);
    }
  }
}
