import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { WidgetTaskActivityResponse } from './widget-task-activity.response';
@Component({
  templateUrl: './app-widget-task-activity.component.html',
  styleUrls: ['./app-widget-task-activity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetTaskActivityComponent extends BaseComponentComponent {
  public widgetTaskActivityResponse: WidgetTaskActivityResponse;
  public taskActivityList = [];
  private TASK_CODE_PROC_ANNOUNCEMENT = 'PENGUMUMAN_PENGADAAN';
  private TASK_CODE_PROC_INVITATION = 'UNDANGAN_PENGADAAN';
  private TASK_CODE_CONTRACT = 'KONTRAK';
  private TASK_CODE_ACTIVE_PROC = 'PENGADAAN_AKTIF';
  private TASK_CODE_JOIN_PROC = 'MENGIKUTI_PENGADAAN';
  private TASK_CODE_PROC_WINS = 'MENANG_PENGADAAN';
  private TASK_CODE_REMAINING_PAYMENT = 'SISA_PEMBAYARAN';

  constructor() {
    super('app-widget-task-activity');
  }

  public onInit(): void {
    this.getAndSetTaskActivity();
  }
  public getAndSetTaskActivity(): void {
    this.httpClientService
      .get('/widget-task-activity/index')
      .subscribe((widgetTaskActivityResponse: WidgetTaskActivityResponse) => {
        this.taskActivityList = [
          {
            id: 1,
            code: this.TASK_CODE_PROC_ANNOUNCEMENT,
            title: 'app-widget-task-activity.procurementAnnouncement',
            content: widgetTaskActivityResponse.numberOfProcurementAnnouncement
              ? widgetTaskActivityResponse.numberOfProcurementAnnouncement
              : widgetTaskActivityResponse.numberOfProcurementAnnouncement === 0
              ? widgetTaskActivityResponse.numberOfProcurementAnnouncement
              : '-'
          },
          {
            id: 2,
            code: this.TASK_CODE_PROC_INVITATION,
            title: 'app-widget-task-activity.procurementInvitation',
            content: widgetTaskActivityResponse.numberOfProcurementInvitation
              ? widgetTaskActivityResponse.numberOfProcurementInvitation
              : widgetTaskActivityResponse.numberOfProcurementInvitation === 0
              ? widgetTaskActivityResponse.numberOfProcurementInvitation
              : '-'
          },
          {
            id: 3,
            code: this.TASK_CODE_CONTRACT,
            title: 'app-widget-task-activity.contract',
            content:
              widgetTaskActivityResponse.numberOfContract ||
              widgetTaskActivityResponse.numberOfContract === 0
                ? widgetTaskActivityResponse.numberOfContract
                : '-'
          },
          {
            id: 4,
            code: this.TASK_CODE_ACTIVE_PROC,
            title: 'app-widget-task-activity.activeProcurement',
            content:
              widgetTaskActivityResponse.numberOfActiveProcurement ||
              widgetTaskActivityResponse.numberOfActiveProcurement === 0
                ? widgetTaskActivityResponse.numberOfActiveProcurement
                : '-'
          },
          {
            id: 5,
            code: this.TASK_CODE_JOIN_PROC,
            title: 'app-widget-task-activity.totalJoinProcurement',
            content:
              widgetTaskActivityResponse.totalJoinProcurement ||
              widgetTaskActivityResponse.totalJoinProcurement === 0
                ? widgetTaskActivityResponse.totalJoinProcurement
                : '-'
          },
          {
            id: 6,
            code: this.TASK_CODE_PROC_WINS,
            title: 'app-widget-task-activity.totalProcurementWins',
            content:
              widgetTaskActivityResponse.totalProcurementWins ||
              widgetTaskActivityResponse.totalProcurementWins === 0
                ? widgetTaskActivityResponse.totalProcurementWins
                : '-'
          },
          {
            id: 7,
            code: this.TASK_CODE_REMAINING_PAYMENT,
            title: 'app-widget-task-activity.remainingPayment',
            content: widgetTaskActivityResponse.remainingPayment
              ? this.global.converterService.convertMoney(
                  widgetTaskActivityResponse.remainingPayment
                ) + ' IDR'
              : widgetTaskActivityResponse.remainingPayment === 0
              ? '0 IDR'
              : '-'
          }
        ];
        this.global.widgetLoadingStatus.set(this.moduleCode, true);
        this.setStateReady();
      });
  }

  public goToMoreDetail(task): void {
    if (task.code === this.TASK_CODE_REMAINING_PAYMENT) {
      this.router.navigate(['/pages/vendor-payment-request']);
    } else if (
      task.code === this.TASK_CODE_PROC_ANNOUNCEMENT &&
      task.content !== '-'
    ) {
      window.location.href =
        this.global.config.parameterModel.pmAddressUrl +
        '/pm/#/pages/pengumumanpengadaan';
    } else if (
      task.code === this.TASK_CODE_PROC_INVITATION &&
      task.content !== '-'
    ) {
      window.location.href =
        this.global.config.parameterModel.pmAddressUrl +
        '/pm/#/pages/undanganvendor';
    } else {
      this.global.alertService.showInfo(
        this.global.translateService.instant(
          'app-widget-task-activity.validation.procurement'
        ) +
          ' ' +
          this.global.config.parameterModel.companyName
      );
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
