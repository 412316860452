import { Component, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { NotificationJob } from '../../core/bean/notification-job';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: 'notification-job.component.html',
  styles: [
    '.width-company-name { min-width : 250px; white-space: normal ! important; text-align: center ! important }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class NotificationJobComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<NotificationJob>;
  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('notification-log', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.doBuildFormGroup();
    setTimeout(() => {
      this.setStateReady();
    }, 1000);
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'notificationType.name',
        header: 'table.column.tipe',
        customClass: 'text-center'
      },
      { field: 'receiver', header: 'table.column.rceiver' },
      {
        field: 'cc',
        header: 'table.column.cc',
        customClass: 'white-space-normal'
      },
      {
        field: 'subject',
        header: 'table.column.subject',
        customClass: 'white-space-normal'
      },
      {
        field: 'logDate',
        header: 'table.column.logDate',
        format: FieldFormatEnum.ShortDateAndTime
      },
      {
        field: 'status',
        header: 'table.column.status',
        customClass: 'text-center'
      }
    ]);
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      logDateFrom: [null, Validators.required],
      logDateTo: [null, Validators.required],
      organizationName: [null],
      organizationId: [null]
    });
  }

  public doDetail(notificationJob: NotificationJob): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'detail');
    this.global.routerParams.set('notificationJobId', notificationJob.id);
    this.router.navigate(['/pages/notification-job/detail']);
  }

  public doApply(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }

  public doSend(): void {
    this.global.alertService.showSuccess('Email Berhasil dikirim  ');
  }

  public doOpenPopupTree(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    const userOrganizationId = this.global.userSession.user.organization.id;
    this.appPopupOrganizationService
      .open(userOrganizationId)
      .subscribe(organization => {
        const { name: organizationName, id: organizationId } =
          organization.pop();
        this.formGroup.patchValue({ organizationName, organizationId });
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
      });
  }
}
