<app-popup [header]="header" [isLoading]="formLoading">
  <div class="main-row-popup-scope-work">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <div
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'app-popup-procurement-scope-work.form.stage' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            size="LG"
            [optionList]="stageOfWorkOptionList"
            formControlName="stageOfWork"
            (onChange)="onChangeStage()"
          ></app-combo-box>
        </div>
      </div>
      <div
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
        *ngIf="
          !formGroup.isView &&
          router.url !== '/pages/procurement-scope-work/edit'
        "
      >
        <label class="col-sm-3 control-label">{{
          'app-popup-procurement-scope-work.form.periodType' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-radio
            size="MD"
            formControlName="periodType"
            [optionList]="typeOptionList"
            position="HORIZONTAL"
            (onChange)="onChangeType()"
            tooltip="app-popup-procurement-scope-work.tooltip.periodType"
          ></app-radio>
        </div>
      </div>
      <div
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
        *ngIf="router.url !== '/pages/procurement-scope-work/edit' && !isFromWorkDefinition"
      >
        <label class="col-sm-3 control-label">{{
          'app-popup-procurement-scope-work.form.periodStart' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-date-picker
            size="MD"
            formControlName="startDate"
            minDate="currentDate"
            maxDate="endDateValidate"
          >
          </app-date-picker>
        </div>
      </div>
      <div
        *ngIf="isDate && router.url !== '/pages/procurement-scope-work/edit' && !isFromWorkDefinition"
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'app-popup-procurement-scope-work.form.periodEnd' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-date-picker
            size="MD"
            formControlName="endDate"
            minDate="startDate"
            maxDate="endDateValidate"
          >
          </app-date-picker>
        </div>
      </div>
      <div
        *ngIf="isDuration"
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'app-popup-procurement-scope-work.form.duration' | translate
        }}</label>
        <div
          class="text-left"
          [ngClass]="!formGroup.isView ? 'col-sm-2' : 'col-sm-1'"
          [ngStyle]="{ 'max-width': 'fit-content' }"
        >
          <app-text-field
            size="XL"
            formControlName="duration"
            type="text"
            maxLength="5"
          ></app-text-field>
        </div>
        <div class="col-sm-4 text-left">
          <app-combo-box
            size="LG"
            [optionList]="durationOptionList"
            formControlName="durationType"
          ></app-combo-box>
        </div>
      </div>
      <div
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
        *ngIf="
          dealingAgreementType?.name !==
          global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN
        "
      >
        <label class="col-sm-3 control-label">{{
          'app-popup-procurement-scope-work.form.isMonthlyPayment' | translate
        }}</label>
        <div class="col-sm-9 text-left" *ngIf="!formGroup.isView">
          <app-toggle-switch
            formControlName="isMonthlyPayment"
          ></app-toggle-switch>
        </div>
        <div class="col-sm-9 text-left" *ngIf="formGroup.isView">
          {{
            formGroup.value.isMonthlyPayment === true
              ? translateService.instant(
                  'app-popup-procurement-scope-work.form.yes'
                )
              : translateService.instant(
                  'app-popup-procurement-scope-work.form.no'
                )
          }}
        </div>
      </div>

      <app-accordion
        formArrayName="procurementSowPaymentTermList"
        [isLoading]="formLoading"
      >
        <app-accordion-item
          header="{{
            'app-popup-procurement-scope-work.accordion.payment' | translate
          }} {{ i + 1 }}"
          *ngFor="
            let payment of formGroup.controls.procurementSowPaymentTermList
              .controls;
            let i = index
          "
          [formGroupName]="i"
          [isExpanded]="i === procurementSowPaymentTermList.length - 1"
        >
          <ng-template #headerRight *ngIf="!formGroup.isView">
            <em
              *ngIf="
                (procurementSowPaymentTermList.value.length > 1 &&
                  (payment.value.status ===
                    global.appConstant.cm
                      .PROCUREMENT_SOW_PAYMENT_TERM_STATUS_NEW ||
                    payment.value.status === null)) ||
                (contractType?.code !==
                  global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT &&
                  i !== 0)
              "
              class="fas fa-times text-danger float-right"
              [ngStyle]="{ 'padding-right': '10px' }"
              (click)="doDeletePaymentTerm(i)"
              (keyup)="onKeyUp($event)"
              (keydown)="onKeyDown($event)"
            ></em>
          </ng-template>
          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView && !payment.isView ? 'required' : ''"
          >
            <label class="col-sm-3 control-label">{{
              'app-popup-procurement-scope-work.form.termin' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box
                size="LG"
                [optionList]="paymentTermOptionList"
                formControlName="paymentTerm"
                (onChange)="onChangePaymentTerm(i)"
              ></app-combo-box>
            </div>
          </div>
          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView && !payment.isView ? 'required' : ''"
            *ngIf="!formGroup.isView"
          >
            <label class="col-sm-3 control-label">{{
              'app-popup-procurement-scope-work.form.percentageValue'
                | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                size="MD"
                type="decimal"
                formControlName="percentageValue"
                maxLength="5"
                symbolic="%"
                (onInput)="onChangePercentageValue(payment, i)"
                tooltip="app-popup-procurement-scope-work.tooltip.percentageValue"
              >
              </app-text-field>
            </div>
          </div>
          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView && !payment.isView ? 'required' : ''"
          >
            <label class="col-sm-3 control-label">{{
              'app-popup-procurement-scope-work.form.amount' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                size="LG"
                formControlName="amount"
                type="currency"
                maxLength="16"
                [max]="totalContractValue"
                [symbolic]="
                  contract
                    ? contract.procurementVendor.currency.code
                    : procurementVendor.currency.code
                "
                (onChange)="onChangeAmount(i)"
                tooltip="app-popup-procurement-scope-work.tooltip.amount"
              ></app-text-field>
              <small
                *ngIf="
                  !formGroup.isView &&
                  payment.value.status !==
                    global.appConstant.cm
                      .PROCUREMENT_SOW_PAYMENT_TERM_STATUS_CLOSED &&
                  dealingAgreementType?.name !==
                    global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN
                "
                >{{
                  'app-popup-procurement-scope-work.msg.totalContractValue'
                    | translate
                }}
                {{
                  global.converterService.convertMoney(totalContractValue) ||
                    0.0
                }}</small
              >
            </div>
          </div>
          <!-- comment for bjb -->
          <!-- <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'app-popup-procurement-scope-work.form.isPartial' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-radio
              size="LG"
              formControlName="isPartial"
              [optionList]="partialOptionList"
              position="HORIZONTAL"
            ></app-radio>
          </div>
        </div>  -->
          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView && !payment.isView ? 'required' : ''"
            *ngIf="!formLoading"
          >
            <label class="col-sm-3 control-label">{{
              'app-popup-procurement-scope-work.form.billingTerm' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                id="billingChosen{{ i }}"
                formControlName="billingTermList"
                [optionList]="billingTermOptionList"
                (onChange)="doOnChangeBillingTermList(i, $event)"
              ></app-chosen>
              <small *ngIf="!formGroup.isView && !payment.isView">{{
                'app-popup-procurement-scope-work.msg.billingTermList'
                  | translate
              }}</small>
            </div>
          </div>
          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView && !payment.isView ? 'required' : ''"
            *ngIf="isShowAssesment"
          >
            <label class="col-sm-3 control-label">{{
              'app-popup-procurement-scope-work.form.isAssessment' | translate
            }}</label>
            <div class="col-sm-9 text-left" *ngIf="!formGroup.isView && !payment.isView">
              <app-toggle-switch
                formControlName="isAssessment"
              ></app-toggle-switch>
            </div>
            <div class="col-sm-9 text-left" *ngIf="formGroup.isView || payment.isView">
              {{
                payment.value.isAssessment === true
                  ? translateService.instant(
                      'app-popup-procurement-scope-work.form.yes'
                    )
                  : translateService.instant(
                      'app-popup-procurement-scope-work.form.no'
                    )
              }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'app-popup-procurement-scope-work.form.description' | translate
            }}</label>
            <div
              class="text-left"
              [ngClass]="!formGroup.isView || !payment.isView ? 'col-sm-9' : 'col-sm-6'"
            >
              <app-text-area
                size="LG"
                formControlName="description"
                maxlength="512"
              ></app-text-area>
            </div>
          </div>
          <ng-container
            *ngIf="
              dealingAgreementType?.name !==
              global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN
            "
          >
            <h5 class="separator">{{
              'app-popup-procurement-scope-work.separator.item' | translate
            }}</h5>
            <h6 class="separator">{{
              'app-popup-procurement-scope-work.separator.itemProduct'
                | translate
            }}</h6>
            <div
              class="table-responsive custom-table float-none"
              [ngStyle]="{ margin: '0 auto' }"
            >
              <table
                class="table table-striped box-shadow"
                aria-describedby="Table"
              >
                <thead>
                  <tr class="text-center">
                    <th scope="col" class="text-center">{{
                      'app.table.column.no' | translate
                    }}</th>
                    <th scope="col" class="text-left">
                      {{
                        'app-popup-procurement-scope-work.table.column.item'
                          | translate
                      }}
                    </th>
                    <th scope="col" class="text-center">
                      {{
                        'app-popup-procurement-scope-work.table.column.uom'
                          | translate
                      }}
                    </th>
                    <th
                      scope="col"
                      [ngClass]="
                        !formGroup.isView ? 'text-center' : 'text-right'
                      "
                    >
                      {{
                        'app-popup-procurement-scope-work.table.column.quantity'
                          | translate
                      }}
                    </th>
                    <th
                      scope="col"
                      class="text-right"
                      *ngIf="!formGroup.isView"
                    >
                      {{
                        'app-popup-procurement-scope-work.table.column.remainingQuantity'
                          | translate
                      }}
                    </th>
                    <th
                      scope="col"
                      class="text-right"
                      *ngIf="!formGroup.isView"
                    >
                      {{
                        'app-popup-procurement-scope-work.table.column.total'
                          | translate
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody formArrayName="procurementSowPaymentTermItemProductList">
                  <tr
                    *ngIf="
                      payment.controls.procurementSowPaymentTermItemProductList
                        .controls.length == 0
                    "
                  >
                    <td colspan="99" class="bg-white text-center">
                      {{ 'app.info.noData' | translate }}
                    </td>
                  </tr>
                  <tr
                    *ngFor="
                      let paymentItemProduct of payment.controls
                        .procurementSowPaymentTermItemProductList.controls;
                      let j = index
                    "
                    [formGroupName]="j"
                  >
                    <td class="text-center">{{ j + 1 }}.</td>
                    <td>
                      {{
                        paymentItemProduct.value.procurementResult
                          .procurementItem.item.name
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        paymentItemProduct.value.procurementResult
                          .procurementItem.item.uom.name
                      }}
                    </td>
                    <td
                      [ngClass]="
                        !formGroup.isView ? 'text-center row' : 'text-right'
                      "
                    >
                      <app-text-field
                        size="LG"
                        type="decimal"
                        formControlName="quantity"
                        (onInput)="
                          onInputQuantityProduct(paymentItemProduct, j, i)
                        "
                        [ngStyle]="{ 'margin-left': 'auto' }"
                      ></app-text-field>
                    </td>
                    <td class="text-right" *ngIf="!formGroup.isView">
                      {{
                        paymentItemProduct.value.remainingQuantity !== null
                          ? (paymentItemProduct.value.remainingQuantity
                            | currency: ' ')
                          : (paymentItemProduct.value.procurementResult
                              .itemQuantity | currency: ' ')
                      }}
                    </td>
                    <td class="text-right" *ngIf="!formGroup.isView">
                      {{
                        currency.transform(
                          paymentItemProduct.value.procurementResult
                            .itemQuantity,
                          '',
                          '',
                          this.CURRENCY_DIGITS_INFO
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h6 class="separator">{{
              'app-popup-procurement-scope-work.separator.itemService'
                | translate
            }}</h6>
            <div
              class="table-responsive custom-table float-none"
              [ngStyle]="{ margin: '0 auto' }"
            >
              <table
                class="table table-striped box-shadow"
                aria-describedby="Table"
              >
                <thead>
                  <tr class="text-center">
                    <th scope="col" class="text-center">{{
                      'app.table.column.no' | translate
                    }}</th>
                    <th scope="col" class="text-left">
                      {{
                        'app-popup-procurement-scope-work.table.column.item'
                          | translate
                      }}
                    </th>
                    <th scope="col" class="text-center">
                      {{
                        'app-popup-procurement-scope-work.table.column.uom'
                          | translate
                      }}
                    </th>
                    <th
                      scope="col"
                      [ngClass]="
                        !formGroup.isView ? 'text-center' : 'text-right'
                      "
                    >
                      {{
                        'app-popup-procurement-scope-work.table.column.deliverible'
                          | translate
                      }}
                    </th>
                    <th
                      scope="col"
                      class="text-right"
                      *ngIf="!formGroup.isView"
                    >
                      {{
                        'app-popup-procurement-scope-work.table.column.remainingDeliverible'
                          | translate
                      }}(%)
                    </th>
                  </tr>
                </thead>
                <tbody formArrayName="procurementSowPaymentTermItemServiceList">
                  <tr
                    *ngIf="
                      payment.controls.procurementSowPaymentTermItemServiceList
                        .controls.length == 0
                    "
                  >
                    <td colspan="99" class="bg-white text-center">
                      {{ 'app.info.noData' | translate }}
                    </td>
                  </tr>
                  <tr
                    *ngFor="
                      let paymentItemService of payment.controls
                        .procurementSowPaymentTermItemServiceList.controls;
                      let j = index
                    "
                    [formGroupName]="j"
                  >
                    <td class="text-center">{{ j + 1 }}.</td>
                    <td>
                      {{
                        paymentItemService.value.procurementResult
                          .procurementItem.item.name
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        paymentItemService.value.procurementResult
                          .procurementItem.item.uom.name
                      }}
                    </td>
                    <td
                      [ngClass]="
                        !formGroup.isView ? 'text-center row' : 'text-right'
                      "
                    >
                      <app-text-field
                        size="LG"
                        type="decimal"
                        formControlName="quantity"
                        (onInput)="
                          onInputQuantityService(paymentItemService, j, i)
                        "
                        [ngStyle]="{ 'margin-left': 'auto' }"
                      ></app-text-field>
                    </td>
                    <td class="text-right" *ngIf="!formGroup.isView">
                      {{
                        paymentItemService.value.remainingQuantity !== null
                          ? currency.transform(
                              paymentItemService.value.remainingQuantity,
                              '',
                              '',
                              this.CURRENCY_DIGITS_INFO
                            )
                          : 100.0
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
        </app-accordion-item>
      </app-accordion>

      <div class="form-group row text-sm-right" *ngIf="!formGroup.isView">
        <div class="col-12 mt-2 text-center">
          <app-button
            *ngIf="
              formGroup.controls.isMonthlyPayment.value === false &&
              dealingAgreementType?.name !==
                global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN
            "
            [disabled]="totalContractValue === 0"
            (onClick)="addProcurementSowPaymentTerm()"
            >{{ 'app.button.addMore' | translate }}</app-button
          >
        </div>
      </div>
    </form>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
        <app-button
          *ngIf="!formGroup.isView"
          color="PRIMARY"
          (onClick)="doSave()"
          >{{ 'app.button.save' | translate }}</app-button
        >
        <app-button
          *ngIf="
            router.url === '/pages/contract-monitoring/detail' &&
            todo === 'edit'
          "
          color="PRIMARY"
          (onClick)="doSave()"
          >{{ 'app.button.save' | translate }}</app-button
        >
      </div>
    </ng-template>
  </div>
</app-popup>
