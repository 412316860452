<app-layout
  [isLoading]="formLoading"
  header="rfq.breadcrumb.index"
  [backTo]="urlBackOutside"
>
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge
      *ngIf="todo !== 'add'"
      class="cml-2"
      [color]="badgeColor[rfqResponse?.rfq?.rfqStatus?.code]"
    >
      <!-- {{ 'rfq.badge.status.new' | translate }} -->
      {{ getTranslateKey(rfqResponse?.rfq?.rfqStatus) | translate }}
    </app-badge>
    <app-badge *ngIf="todo === 'add'" class="cml-2" color="INFO">
      {{ 'rfq.badge.status.new' | translate }}
    </app-badge>
  </ng-template>
  <app-alert-x
    color="DANGER"
    *ngIf="
      !formLoading &&
      status === global.appConstant.pm.RFQ_STATUS_CANCELED &&
      rfqResponse?.rfqCancellation?.rfq
    "
  >
    {{ 'rfq.alert.msg.canceledBy' | translate }}
    {{ rfqResponse.rfqCancellation.canceledByUser.name }}
    {{ 'rfq.alert.msg.canceledAt' | translate }}
    {{
      rfqResponse.rfqCancellation.cancelDate
        | date: global.config.parameterModel.datetimeFormatShort
    }}
    {{ 'rfq.alert.msg.canceledNote' | translate }}
    {{ rfqResponse.rfqCancellation.note }}
  </app-alert-x>

  <div class="row main-row">
    <div class="col-lg-12">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-card
          header="rfq.detail.rfqDetail.title"
          tips="rfq.tips.rfqDetails"
        >
          <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'rfq.form.code' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              {{ formGroup.get('code').value }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'rfq.form.title' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="title"
                type="text"
                placeholder="{{ 'rfq.placeholder.inputText' | translate }}"
                maxLength="128"
              >
              </app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'rfq.form.responsePeriod' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-date-picker-x
                formControlName="responsePeriod"
                [range]="true"
              >
              </app-date-picker-x>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'rfq.form.upload' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              <app-upload-x
                size="XL"
                formControlName="template"
                [fileUploader]="fileUploader"
              >
              </app-upload-x>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{ 'rfq.form.note' | translate }}</label>
            <div class="col-sm-6 text-left">
              <app-text-area formControlName="note" maxLength="512" size="XL">
              </app-text-area>
            </div>
          </div>
        </app-card>

        <app-card>
          <!-- <app-fullfilled *ngIf="procurementItemList.length === 0
                  && !formGroup.isView"></app-fullfilled> -->
          <app-table-xx
            header="rfq.detail.itemRequest.title"
            tips="rfq.tips.rfqItemRequest"
            [model]="tableResponseItem"
            (onClick)="doViewItem($event)"
          >
            <ng-template #headerFilter>
              <div class="app-table-filter-item" style="height: 34px">
                <div class="form-group row text-sm-left">
                  <div
                    class="col text-left"
                    [ngStyle]="{ 'min-width': '10rem' }"
                  >
                    <app-dropdown-select
                      size="XL"
                      formControlName="itemType"
                      type="COMBOBOX"
                      [optionList]="itemTypeOptionList"
                      (onChange)="onChangeItemType()"
                      container="body"
                      placeholder="rfq.placeholder.type"
                    ></app-dropdown-select>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-container *ngIf="tableResponseItem.getRecordList()?.length > 0">
              <ng-template #row>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'rfq.itemRequest.footer.goodsAmount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{
                        formGroup.value.goodsAmount | resolveNumberToCurrency
                      }}
                      IDR
                    </strong>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'rfq.itemRequest.footer.serviceAmount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{
                        formGroup.value.serviceAmount | resolveNumberToCurrency
                      }}
                      IDR
                    </strong>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'rfq.itemRequest.footer.amount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{ formGroup.value.amount | resolveNumberToCurrency }}
                      IDR
                    </strong>
                    <br />
                    <i>
                      ({{
                        formGroup.value.amount
                          | resolveNumberToWords: global.currentLang
                      }}
                      {{ 'Rupiah' }})
                    </i>
                  </td>
                </tr>
              </ng-template>
            </ng-container>
          </app-table-xx>
        </app-card>

        <app-card>
          <app-fullfilled
            *ngIf="status === global.appConstant.pm.RFQ_STATUS_CLOSED_RESPONSE"
          ></app-fullfilled>
          <ng-container
            *ngIf="
              !formLoading &&
              (status === global.appConstant.pm.RFQ_STATUS_WAITING_RESPONSE ||
                status === global.appConstant.pm.RFQ_STATUS_COMPLETED ||
                (status === global.appConstant.pm.RFQ_STATUS_WAITING_APPROVAL &&
                  rfq.workflowPrcs.code.includes('RFQ_SUBMISSION')))
            "
          >
            <app-table-xx
              header="rfq.detail.rfqVendor.title"
              tips="rfq.tips.rfqVendor"
              [model]="tableResponseVendorWR"
              (onClick)="
                !(
                  status ===
                    global.appConstant.pm.RFQ_STATUS_WAITING_APPROVAL &&
                  rfq.workflowPrcs.code.includes('RFQ_SUBMISSION')
                )
                  ? onClickVendor($event)
                  : ''
              "
              [isShowCheckBox]="false"
            >
            </app-table-xx>
          </ng-container>
          <ng-container
            *ngIf="
              !formLoading &&
              !(
                status === global.appConstant.pm.RFQ_STATUS_WAITING_APPROVAL &&
                rfq.workflowPrcs.code.includes('RFQ_SUBMISSION')
              ) &&
              status !== global.appConstant.pm.RFQ_STATUS_WAITING_RESPONSE &&
              status !== global.appConstant.pm.RFQ_STATUS_COMPLETED &&
              this.isFromRfp
            "
          >
            <app-table-xx
              header="rfq.detail.rfqVendor.title"
              tips="rfq.tips.rfqVendor"
              [model]="tableResponseVendorRfp"
              (onClick)="
                !(
                  status ===
                    global.appConstant.pm.RFQ_STATUS_WAITING_APPROVAL &&
                  rfq.workflowPrcs.code.includes('RFQ_SUBMISSION')
                )
                  ? onClickVendor($event)
                  : ''
              "
              [isShowCheckBox]="
                status !== global.appConstant.pm.RFQ_STATUS_WAITING_APPROVAL &&
                status !== global.appConstant.pm.RFQ_STATUS_DONE &&
                status !== global.appConstant.pm.RFQ_STATUS_CANCELED &&
                !rfqResponse?.isUserApproval
              "
              [isMultipleSelect]="false"
              (onChange)="onChangeSelectRecord()"
            >
            </app-table-xx>
          </ng-container>
          <ng-container
            *ngIf="
              !formLoading &&
              !(
                status === global.appConstant.pm.RFQ_STATUS_WAITING_APPROVAL &&
                rfq.workflowPrcs.code.includes('RFQ_SUBMISSION')
              ) &&
              status !== global.appConstant.pm.RFQ_STATUS_WAITING_RESPONSE &&
              status !== global.appConstant.pm.RFQ_STATUS_COMPLETED &&
              !this.isFromRfp
            "
          >
            <app-table-xx
              header="rfq.detail.rfqVendor.title"
              tips="rfq.tips.rfqVendor"
              [model]="tableResponseVendorPr"
              (onClick)="
                !(
                  status ===
                    global.appConstant.pm.RFQ_STATUS_WAITING_APPROVAL &&
                  rfq.workflowPrcs.code.includes('RFQ_SUBMISSION')
                )
                  ? onClickVendor($event)
                  : ''
              "
              [isShowCheckBox]="
                status !== global.appConstant.pm.RFQ_STATUS_WAITING_APPROVAL &&
                status !== global.appConstant.pm.RFQ_STATUS_DONE &&
                status !== global.appConstant.pm.RFQ_STATUS_CANCELED &&
                !rfqResponse?.isUserApproval
              "
              [isMultipleSelect]="false"
              (onChange)="onChangeSelectRecord()"
            >
            </app-table-xx>
          </ng-container>
        </app-card>

        <app-agreement-type
          *ngIf="
            rfqResponse?.rfq?.rfqStatus.code === 'CLOSED_RESPONSE' ||
            agreementTypeParam != null
          "
          [component]="this.global.config.parameterModel.rfqNextProcess"
          (onChange)="onChangeAgreementType($event)"
          [agreementTypeParam]="agreementTypeParam"
          [isAgreementTypeNull]="isAgreementTypeNull"
          [isShowInputControl]="isShowInputControl"
        ></app-agreement-type>
      </form>

      <app-approval-monitoring
        *ngIf="
          !formLoading &&
          !rfqResponse.isUserApproval &&
          rfqResponse.approvalPathResponseModel
        "
        [workflowPrcs]="rfqResponse?.rfq?.workflowPrcs"
        [moduleCode]="moduleCode"
        [approvalPathResponseModel]="rfqResponse.approvalPathResponseModel"
      ></app-approval-monitoring>

      <div
        class="floating-button mt-5"
        *ngIf="!formLoading && !rfqResponse.isUserApproval"
      >
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div
              class="button-group button-group-center"
              *ngIf="!formLoading && !rfqResponse.isUserApproval"
            >
              <app-button
                *ngIf="
                  rfqResponse?.rfq?.rfqStatus?.code !==
                    global.appConstant.pm.RFQ_STATUS_WAITING_APPROVAL &&
                  rfqResponse?.rfq?.rfqStatus?.code !==
                    global.appConstant.pm.RFQ_STATUS_CANCELED &&
                  rfqResponse?.rfq?.rfqStatus?.code !==
                    global.appConstant.pm.RFQ_STATUS_DONE
                "
                color="DANGER"
                [outline]="true"
                (onClick)="doCancel()"
                >{{ 'rfq.button.cancelRfq' | translate }}
              </app-button>
              <app-button
                *ngIf="
                  rfqResponse?.rfq?.rfqStatus?.code ===
                    global.appConstant.pm.RFQ_STATUS_COMPLETED ||
                  (rfqResponse?.rfq?.rfqStatus?.code ===
                    global.appConstant.pm.RFQ_STATUS_WAITING_RESPONSE &&
                    isShowCloseButton)
                "
                color="LIGHT"
                (onClick)="doClose()"
                [disabled]="formSaving"
                [outline]="true"
                >{{ 'rfq.button.closeResponse' | translate }}
              </app-button>
              <app-button
                (onClick)="doSave(false)"
                color="SECONDARY"
                [outline]="true"
                *ngIf="
                  formGroup.isView &&
                  (rfqResponse?.rfq?.rfqStatus?.code ===
                    global.appConstant.pm.RFQ_STATUS_CLOSED_RESPONSE ||
                    rfqResponse?.rfq?.rfqStatus?.code ===
                      global.appConstant.pm.RFQ_STATUS_REVISION)
                "
              >
                {{ 'app.button.save' | translate }}</app-button
              >
              <app-button
                (onClick)="doSave(true)"
                *ngIf="
                  formGroup.isView &&
                  (rfqResponse?.rfq?.rfqStatus?.code ===
                    global.appConstant.pm.RFQ_STATUS_CLOSED_RESPONSE ||
                    rfqResponse?.rfq?.rfqStatus?.code ===
                      global.appConstant.pm.RFQ_STATUS_REVISION)
                "
              >
                {{ 'app.button.submit' | translate }}</app-button
              >
            </div>
          </div>
        </div>
      </div>

      <app-approval-prcs-x
        *ngIf="!formLoading && rfqResponse.isUserApproval"
        [workflowModelPrcs]="rfqResponse.rfq.workflowModelPrcs"
        [approvalModelPrcsId]="approvalModelPrcsId"
      >
      </app-approval-prcs-x>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips *ngIf="!formLoading" #appTips></app-tips>
  </ng-template>
</app-layout>
