import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Module } from '../../core/bean/module';
import { ModuleGroup } from '../../core/bean/module-group';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: 'translation.component.html'
})
export class TranslationComponent extends BaseModuleComponent {
  public moduleGroupList: ModuleGroup[];
  public tableResponse: TableResponseModel<Module>;
  constructor(translateService: TranslateService) {
    super('translation', translateService);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.doGetAndSetModuleGroupList();
    this.setStateReady();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      moduleGroup: [null]
    });
  }

  public doGetAndSetModuleGroupList(): void {
    this.httpClientService
      .get<ModuleGroup[]>('/translation/module-group-list', {})
      .subscribe(moduleGroupList => {
        this.moduleGroupList = moduleGroupList;
      });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'moduleGroupCode',
        header: 'table.index.col.moduleGroup',
        customClass: 'text-center'
      },
      { field: 'name', header: 'table.index.col.name' },
      { field: 'description', header: 'table.index.col.description' }
    ]);
  }

  public doEdit(module: Module): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('module', module);
    this.router.navigate(['/pages/translation/edit']);
  }

  public doOnChangeModuleGroup(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
