import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Faq } from 'src/app/core/bean/faq';
import { Lang } from 'src/app/core/bean/lang';
import { Validators } from 'src/app/core/validators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { FaqResponse } from './faq.response';
@Component({
  templateUrl: './faq-edit-add.component.html'
})
export class FaqEditAddComponent extends BaseModuleComponent {
  public delfaqList: Faq[] = [];

  constructor(translateService: TranslateService) {
    super('faq', translateService);
  }

  public onInit(): void {
    this.doSetTodoFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetTodoFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      faqLangList: this.formBuilder.array([])
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<FaqResponse>('/faq/add-edit', {})
      .subscribe((faqResponse: FaqResponse) => {
        faqResponse.langList.forEach(lang => {
          const faqList = new FormArray([]);
          if (faqResponse.faqList.length > 0) {
            faqResponse.faqList.forEach((faq: Faq) => {
              if (lang.id === faq.lang.id) {
                const formGroup = this.formBuilder.group({
                  id: faq.id,
                  lang: faq.lang,
                  question: [faq.question, Validators.required()],
                  answer: [faq.answer, Validators.required()]
                });
                faqList.push(formGroup);
              }
            });
          } else {
            faqList.push(this.newFaq(lang));
          }
          this.faqLangList.push(
            this.formBuilder.group({
              lang,
              faqList
            })
          );
        });
        this.setStateReady();
      });
  }

  public newFaq(lang): FormGroup {
    const formGroup = this.formBuilder.group({
      id: [null],
      lang: [lang],
      code: [null],
      question: [null, Validators.required()],
      answer: [null, Validators.required()]
    });
    return formGroup;
  }

  public doAddFaq(i: number, lang: Lang): void {
    const faqListForm = this.faqLangList.controls[i].get(
      'faqList'
    ) as FormArray;
    faqListForm.push(this.newFaq(lang));
  }

  public doDeleteFaq(event: PointerEvent, i: number, j: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.preventDefault();
          const faq = (this.faqLangList.controls[i].get('faqList') as FormArray)
            .controls[j].value;
          if (faq.id !== null) {
            faq.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_DELETE;
            this.delfaqList.push(faq);
          }
          (this.faqLangList.controls[i].get('faqList') as FormArray).removeAt(
            j
          );
        }
      });
  }

  public onDrop(event: CdkDragDrop<string[]>, indexI: number) {
    const formArray: FormArray = (
      this.formGroup.get('faqLangList') as FormArray
    ).controls[indexI].get('faqList') as FormArray;
    this.moveItemInFormArray(
      formArray,
      event.previousIndex,
      event.currentIndex
    );
  }

  public moveItemInFormArray(
    formArray: FormArray,
    fromIndex: number,
    toIndex: number
  ): void {
    const dir = toIndex > fromIndex ? 1 : -1;

    const from = fromIndex;
    const to = toIndex;

    const temp = formArray.at(from);
    for (let i = from; i * dir < to * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(to, temp);
  }

  public onMouseEnter(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public doSaveFaq(i: number, j: number): void {
    console.log(i);
    console.log(j);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const faqList: Faq[] = [];
            this.formGroup.controls.faqLangList.value.forEach(faqLang => {
              faqLang.faqList.forEach((faq: Faq) => {
                if (faq.id !== null) {
                  faq.crudOperation =
                    this.global.appConstant.core.CRUD_OPERATION_UPDATE;
                } else {
                  faq.crudOperation =
                    this.global.appConstant.core.CRUD_OPERATION_INSERT;
                }
                faqList.push(faq);
              });
            });
            const faqAllList = faqList.concat(this.delfaqList);
            this.httpClientService
              .post<Response<Faq[]>>('/faq/update', faqAllList)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/content/']);
                  this.global.modalService.confirmation(
                    'faq.confirmation.saveConfirmation.prompt',
                    'faq.confirmation.saveConfirmation.title',
                    null,
                    null,
                    'pvc pv-check-circle',
                    true
                  );
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/content/']);
  }

  public get faqList(): FormArray {
    return this.formGroup.get('faqList') as FormArray;
  }

  public get faqLangList(): FormArray {
    return this.formGroup.get('faqLangList') as FormArray;
  }
}
