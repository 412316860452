import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { BlacklistCriteria } from '../../core/bean/blacklist-criteria';
import { BlacklistDuration } from '../../core/bean/blaclist-duration';
import { Vendor } from '../../core/bean/vendor';
import { VendorViolation } from '../../core/bean/vendor-violation';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { AppPopupVendorService } from '../../core/components/app-popup/app-popup-vendor/app-popup-vendor.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { VMConstant } from './../../core/constant/vm-constant';
import { AppPopupBlacklistCriteriaComponent } from './app-popup-blacklist-criteria.component';
import { VendorBlacklistRequest } from './vendor-blacklist-request';
import { VendorBlacklistResponse } from './vendor-blacklist-response';

@Component({
  templateUrl: './vendor-blacklist-edit-add.component.html'
})
export class VendorBlacklistEditAddComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;

  public vendorViolationList: VendorViolation[] = [];
  public vendor: Vendor;
  public blacklistCriteriaList: BlacklistCriteria[] = [];
  public vendorViolation: VendorViolation;
  public vendorId: number;
  public vendorBlacklistResponse: VendorBlacklistResponse =
    new VendorBlacklistResponse();
  public vendorBlacklistRequest: VendorBlacklistRequest =
    new VendorBlacklistRequest();
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public blacklistCriteriaIdList: number[] = [];
  public formReady = false;
  public isEdit = false;
  public isSave = false;
  public isApproval = false;
  public objectId: number;
  public currentDate: Date = new Date();
  public isReset = false;
  public vmConstant: VMConstant = new VMConstant();

  public tableResponseVendorViolation: TableResponseModel<BlacklistCriteria>;

  public readonly urlEditAddVendorBlacklist = '/vendor-blacklist/add-edit';
  public readonly urlUpdateVendorBlacklist = '/vendor-blacklist/update';
  public readonly urlInsertVendorBlacklist = '/vendor-blacklist/insert';
  public readonly urlRouterNavigateToVendorBlacklist =
    '/pages/vendor-blacklist';

  public fileUploader: FileUploader = new FileUploader(
    '/vendor-blacklist/',
    'vendor-blacklist.title',
    this.global.appConstant.fileType.DOC_VIOLATION_BLACKLIST
  );

  public blacklistDurationOptionList: OptionListModel<BlacklistDuration> =
    new OptionListModel(true);
  public approvalModelPrcsId: number;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupVendorService: AppPopupVendorService
  ) {
    super('vendor-blacklist', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setVendorBlacklistEdit();
    this.buildTableResponse();
    this.getAndSetVendorBlacklistToFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorId = this.global.routerParams.get('vendorId');
    this.isApproval = this.global.routerParams.get('isApproval');
    this.objectId = this.global.routerParams.get('objectId');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
  }
  public setVendorBlacklistEdit(): void {
    if (this.todo === 'editVendorBlacklist') {
      this.isEdit = true;
    }
    if (this.todo === 'view' || this.todo === 'viewVendorBlacklist') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      vendorName: [null, Validators.compose([Validators.required()])],
      vendorCode: [null],
      vendorViolationList: [null],
      startDate: [null, Validators.compose([Validators.required()])],
      blacklistDuration: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(512)])
      ],
      note: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(512)])
      ],
      reason: [null, Validators.compose([Validators.required()])],
      docFile: [null],
      sequence: [null],
      currentDate: new Date()
    });
  }

  public buildTableResponse(): void {
    this.tableResponseVendorViolation = new TableResponseModel(
      this.moduleCode,
      [
        { field: 'violation', header: 'table.column.blacklistCriteriaName' },
        {
          field: 'durationInMonth',
          header: 'table.column.blacklistCriteriaDuration',
          fn: this.global.converterService.convertMonthToYears,
          customClass: 'text-center'
        }
      ]
    );
    this.tableResponseVendorViolation.page.records = this.blacklistCriteriaList;
    this.tableResponseVendorViolation.page.totalRecords =
      this.blacklistCriteriaList.length;
  }

  public getAndSetVendorBlacklistToFormGroup(): void {
    this.httpClientService
      .post<VendorBlacklistResponse>(
        '/vendor-blacklist/add-edit',
        new RouteRequestModel(
          this.todo,
          this.objectId != null ? this.objectId : this.vendorId
        )
      )
      .subscribe(vendorBlacklistResponse => {
        this.vendorBlacklistResponse = vendorBlacklistResponse;
        this.blacklistDurationOptionList.setRequestValues(
          vendorBlacklistResponse.blacklistDurationList
        );
        this.vendorViolationList = vendorBlacklistResponse.vendorViolationList;
        this.vendor = vendorBlacklistResponse.vendor;
        this.workflowModelPrcs =
          vendorBlacklistResponse.vendorBlacklist.workflowModelPrcs;

        if (this.vendorBlacklistResponse.vendorBlacklist.file !== null) {
          this.fileUploader.setFile(
            this.vendorBlacklistResponse.vendorBlacklist.file
          );
        }

        if (this.vendorBlacklistResponse.vendor != null) {
          const startDate: Date = new Date(
            this.vendorBlacklistResponse.vendorBlacklist.startDate
          );

          this.formGroup.patchValue({
            id: this.vendorId,
            vendorName: this.vendor.name,
            vendorCode: this.vendor.code,
            vendorViolationList: this.vendorViolationList,
            startDate,
            blacklistDuration:
              this.vendorBlacklistResponse.vendorBlacklist.blacklistDuration,
            note: vendorBlacklistResponse.vendorBlacklist.note,
            reason: vendorBlacklistResponse.vendorBlacklist.reason,
            sequence: vendorBlacklistResponse.vendorBlacklist.sequence
          });

          if (this.vendorBlacklistResponse.vendorBlacklist.file !== null) {
            this.formGroup.patchValue({
              docFile: this.fileUploader.fileObjectList
            });
          }
        }
        this.formReady = true;

        this.vendorViolationList.forEach(element => {
          this.blacklistCriteriaList.push(element.blacklistCriteria);
          this.blacklistCriteriaIdList.push(element.blacklistCriteria.id);
        });

        this.setStateReady();
      });
  }
  public chooseVendor(): void {
    const vendorActivityStatusCodeList: string[] = [
      this.vmConstant.V_ACTIVITY_STATUS_VENDOR_ACTIVE
    ];
    this.appPopupVendorService
      .open(null, vendorActivityStatusCodeList)
      .subscribe(vendor => {
        this.vendorBlacklistResponse.vendor = vendor;
        this.vendorId = vendor.id;
        this.formGroup.patchValue({
          vendorName: vendor.name,
          vendorCode: vendor.code
        });
      });
  }

  public doAddViolation(): void {
    const blacklistCriteriaIdList = this.blacklistCriteriaIdList;
    this.appPopupService
      .open(AppPopupBlacklistCriteriaComponent, { blacklistCriteriaIdList })
      .subscribe(violationList => {
        violationList.forEach(blacklistCriteria => {
          this.blacklistCriteriaList.push(blacklistCriteria);
          this.blacklistCriteriaIdList.push(blacklistCriteria.id);

          this.vendorViolation = {
            id: null,
            isDeleted: 0,
            createdBy: 1,
            uuid: null,
            createdAt: new Date(),
            blacklistCriteria: null,
            vendorBlacklist: null,
            updatedAt: null,
            updatedBy: null,
            code: null,
            organization: null
          };

          this.vendorViolation.blacklistCriteria = blacklistCriteria;
          this.vendorViolation.vendorBlacklist =
            this.vendorBlacklistResponse.vendorBlacklist;

          this.vendorBlacklistResponse.vendorViolationList.push(
            this.vendorViolation
          );

          this.tableResponseVendorViolation.page.records =
            this.blacklistCriteriaList;
          this.tableResponseVendorViolation.page.totalRecords =
            this.blacklistCriteriaList.length;
        });

        this.formGroup.patchValue({
          vendorViolationList: this.vendorBlacklistResponse.vendorViolationList
        });
      });
  }

  public doRemove(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.vendorBlacklistResponse.vendorViolationList.splice(index, 1);
          this.blacklistCriteriaIdList.splice(index, 1);
        }
      });
  }

  public doCancel(): void {
    this.router.navigate([this.urlRouterNavigateToVendorBlacklist]);
  }

  public doSave(): void {
    this.isSave = true;
    this.validate();
    this.log.debug(this.formGroup);
    if (
      this.formGroup.valid &&
      this.vendorBlacklistResponse.vendorViolationList.length > 0
    ) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.saveVendorBlacklist();
          }
        });
    }
  }

  public saveVendorBlacklist(): void {
    this.setStateProcessing();
    this.vendorBlacklistRequest.docFileList =
      this.formGroup.get('docFile').value;
    this.vendorBlacklistRequest.vendorBlacklist = this.formGroup.value;
    this.vendorBlacklistRequest.vendor = this.vendorBlacklistResponse.vendor;
    this.vendorBlacklistRequest.vendorViolationList =
      this.vendorBlacklistResponse.vendorViolationList;

    this.saveVendorBlacklistToServer(
      this.urlSaveVendorBlacklist,
      this.vendorBlacklistRequest
    ).subscribe(response => {
      if (response.status === ResponseStatusModel.OK) {
        this.global.alertService.showSuccessSavingOnNextRoute();
        this.router.navigate([this.urlRouterNavigateToVendorBlacklist]);
      } else {
        this.global.alertService.showError(response.statusText);
        this.setStateReady();
      }
    });
  }

  public get urlSaveVendorBlacklist(): string {
    return this.todo === 'editVendorBlacklist'
      ? this.urlUpdateVendorBlacklist
      : this.urlInsertVendorBlacklist;
  }

  public getVendorBlacklistByIdFromServer(
    urlGetVendorBlacklistById: string,
    todo: string,
    vendorBlacklistId: number
  ): Observable<VendorBlacklistResponse> {
    return this.httpClientService.post<VendorBlacklistResponse>(
      urlGetVendorBlacklistById,
      new RouteRequestModel(todo, vendorBlacklistId)
    );
  }

  public saveVendorBlacklistToServer(
    urlSaveVendorBlacklist: string,
    vendorBlacklistRequest: VendorBlacklistRequest
  ): Observable<Response<VendorBlacklistResponse>> {
    return this.httpClientService.post<Response<VendorBlacklistResponse>>(
      urlSaveVendorBlacklist,
      vendorBlacklistRequest
    );
  }

  public doReset(): void {
    this.formGroup.reset();
    this.isReset = true;

    if (this.vendorBlacklistResponse.vendor && this.todo !== 'add') {
      this.formGroup.patchValue({
        vendorName: this.vendorBlacklistResponse.vendor.name,
        vendorCode: this.vendorBlacklistResponse.vendor.code
      });
    } else {
      this.vendorBlacklistResponse.vendor = null;
    }
    this.vendorViolationList.length = 0;
    this.blacklistCriteriaIdList.splice(0, this.blacklistCriteriaIdList.length);
    this.fileUploader.setFileList([]);
    this.fileUploader.fileObjectList.splice(
      0,
      this.fileUploader.fileObjectList.length
    );

    setTimeout(() => {
      this.isReset = false;
    }, 1000);
  }
}
