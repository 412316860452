export const chartColorConstant = [
  '#4bae4f',
  '#a0a0a0',
  '#204d95',
  '#206f95',
  '#209590',
  '#279520',
  '#7e9520',
  '#956c20',
  '#954b20',
  '#952020',
  '#952071',
  '#7e2095',
  '#582095',
  '#322095'
];
