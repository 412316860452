<app-popup [header]="header" [isLoading]="formLoading">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" novalidate>
        <div class="form-group row text-sm-right" *ngIf="!formLoading">
          <label
            *ngIf="wanprestasi.procurement !== null"
            class="col-sm-3 control-label"
            >{{ 'wanprestasi.form.procurement.name' | translate }}</label
          >
          <label
            *ngIf="wanprestasi.contract !== null"
            class="col-sm-3 control-label"
            >{{ 'wanprestasi.form.contract.name' | translate }}</label
          >
          <div class="col-sm-9 text-left">
            <div *ngIf="wanprestasi.procurement !== null">{{
              wanprestasi.procurement.name
            }}</div>
            <div *ngIf="wanprestasi.contract !== null">{{
              wanprestasi.contract.name
            }}</div>
          </div>
        </div>

        <div class="form-group row text-sm-right" *ngIf="!formLoading">
          <label class="col-sm-3 control-label">{{
            'wanprestasi.stages' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p *ngIf="wanprestasi.contract !== null">{{
              wanprestasi.contract.stage === null
                ? '-'
                : wanprestasi.contract.stage
            }}</p>
            <p *ngIf="wanprestasi.procurement !== null">{{
              wanprestasi.procurement.stage === null
                ? '-'
                : wanprestasi.procurement.stage
            }}</p>
            <p
              *ngIf="
                wanprestasi.contract === null &&
                wanprestasi.procurement === null
              "
            >
              -</p
            >
          </div>
        </div>

        <div class="form-group row text-sm-right" *ngIf="!formLoading">
          <label *ngIf="wanprestasi.contract" class="col-sm-3 control-label">{{
            'wanprestasi.form.contract.type' | translate
          }}</label>
          <label
            *ngIf="wanprestasi.procurement"
            class="col-sm-3 control-label"
            >{{ 'wanprestasi.form.procurement.type' | translate }}</label
          >
          <div class="col-sm-9 text-left">
            <p
              *ngIf="
                wanprestasi.contract &&
                wanprestasi.contract.contractType !== null
              "
            >
              {{ wanprestasi.contract.contractType.name }}</p
            >
            <p
              *ngIf="
                wanprestasi.procurement &&
                wanprestasi.procurement.procurementType !== null
              "
            >
              {{ wanprestasi.procurement.procurementType.name }}</p
            >
            <p
              *ngIf="
                wanprestasi.contract === null &&
                wanprestasi.procurement === null
              "
            >
              -</p
            >
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'wanprestasi.form.date' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div>{{ wanprestasi.wanprestasiDate | date: 'dd/MM/yyyy' }}</div>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'wanprestasi.form.detail' | translate
          }}</label>
          <div class="text-left col-sm-6">
            <div>{{ wanprestasi.wanprestasiDetail }}</div>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'wanprestasi.form.memoNumber' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div>{{ wanprestasi.memoNumber }}</div>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'wanprestasi.form.upload' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div *ngIf="wanprestasi.file == null"> - </div>
            <div *ngIf="wanprestasi.file != null">
              <a
                href="#"
                (click)="
                  doDownload(
                    $event,
                    '/wanprestasi/file/view/' +
                      wanprestasi.file.uploadedFileName +
                      '/' +
                      global.appConstant.fileType.DOC_MEMO_WANPRESTASI,
                    wanprestasi.file.fileName
                  )
                "
              >
                <p>{{ wanprestasi.file.fileName }}</p>
              </a>
            </div>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'wanprestasi.form.note' | translate
          }}</label>
          <div class="text-left col-sm-6">
            <div>{{ wanprestasi.note !== null ? wanprestasi.note : '-' }}</div>
          </div>
        </div>

        <ng-template #modalFooter let-activeModal>
          <div class="button-group button-group-center">
            <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
              'app.button.close' | translate
            }}</app-button>
          </div>
        </ng-template>
      </form>
    </div>
  </div>
</app-popup>
