import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from '../../../core/global/global';
@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  constructor(public global: Global, private router: Router) {}

  doBack(): void {
    const lastActiveRouterlistIndex =
      this.global.lastActiveRouterList.length - 1;
    this.router.navigate([
      this.global.lastActiveRouterList[lastActiveRouterlistIndex]
    ]);
  }
}
