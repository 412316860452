<div class="app-widget-payment-monitoring">
    <app-widget [header]="moduleCode + '.title'">
        <div class="row px-3">
            <div class="col-12 col-md-6 custom-col px-1">
                <div class="card-left" (click)="goToMoreDetail('pending')" (keyup)="onKeyUp($event)">
                    <div class="text-center">
                        <h4>{{ 'app-widget-payment-monitoring.pendingPayment' | translate }}</h4>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-3 mb-3">
                        <em class="fas fa-money-bill-alt"></em>
                        <div class="row-left-text">
                            <h4 class="text-secondary">{{ paymentMonitoringDTO.pendingAmount }} IDR</h4>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 custom-col px-1">
                <div class="card-right" (click)="goToMoreDetail('paid')" (keyup)="onKeyUp($event)">
                    <div class="text-center">
                        <h4>{{ 'app-widget-payment-monitoring.paidPayment' | translate }}</h4>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-3 mb-3">
                        <em class="fas fa-money-bill-alt"></em>
                        <div class="row-left-text">
                            <h4 class="text-secondary">{{ paymentMonitoringDTO.paidAmount }} IDR</h4>
                        </div>
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    </app-widget>
</div>