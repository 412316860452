<app-popup header="app-organization-plan-info.organization">
  <div
    class="app-organizastion-rk-info-popup-wrapper pb-2 d-block overflow-hidden"
  >
    <app-organization-rk-info-item-card
      *ngFor="let organizationPlanInfoModel of organizationPlanInfoModelList"
      [organizationPlanInfoModel]="organizationPlanInfoModel"
      (onClick)="onChange.emit($event)"
    >
    </app-organization-rk-info-item-card>
  </div>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <button
        type="button "
        class="btn btn-secondary ml-2"
        (click)="activeModal.close(true)"
      >
        {{ 'app.button.close' | translate }}
      </button>
    </div>
  </ng-template>
</app-popup>
