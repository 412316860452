import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { FileType } from '../../../bean/file-type';
import * as XLSX from 'xlsx';
@Component({
  templateUrl: './app-popup-import-quantity.component.html',
  styleUrls: ['./app-popup-import-quantity.component.scss']
})
export class AppPopupImportQuantityComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() urlSave: string;
  @Input() isLoading: boolean;
  @Input() onCloseModal: EventEmitter<boolean>;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  fileType: FileType = this.global.config.allowedFile.get('IMPORT_EXCEL');
  isSaving: boolean;
  uploadedFileName: string;
  formdata: FormData = new FormData();
  errors: any = [];
  file: any = [];
  urlModuleCode: string;
  isTemplateExists = false;
  catalogTemplate: any = [];
  uploadCatalogRowLength: number;

  constructor(
    public activeModal: NgbActiveModal
  ) {
    super('app-popup-import-quantity');
  }

  ngOnChanges(value): void {
    if (value && +value.progressPercentage.currentValue === 100.0) {
      this.onChange.emit();
    }
  }

  onInit(): void {
    this.urlModuleCode = this.urlSave.split('/')[1];
    this.httpClientService
      .get('/' + this.urlModuleCode + '/template/view', {
        responseType: 'arraybuffer',
        observe: 'response'
      })
      .subscribe(response => {
        if (response.body.byteLength !== 0) {
          this.isTemplateExists = true;
        }
      });
  }

  doUpload(event: any): void {
    this.file = event.target.files[0];
    if (
      this.fileType.fileExtension
        .toLowerCase()
        .includes(this.fileExtension.toLowerCase())
    ) {
      const fileReader = new FileReader();
      fileReader.onload = event => {
        const workBook = XLSX.read(event.target.result, {type: 'binary'});
        const sheetNames = workBook.SheetNames;
        this.catalogTemplate = XLSX.utils.sheet_to_json(workBook.Sheets[sheetNames[0]]);
        this.uploadCatalogRowLength = this.catalogTemplate.length;
        if (this.isValid) {
          this.errors = [];
        }
      };
      fileReader.readAsBinaryString(this.file);
    } else {
      if (this.isValid) {
        this.errors = [];
      }
    }
    this.uploadedFileName = event.target.files[0].name;
    this.formdata.append('file', event.target.files[0]);
  }

  get fileExtension(): any {
    return this.file.name.substring(this.file.name.lastIndexOf('.') + 1);
  }

  get isValid(): boolean {
    if (this.file.length === 0) {
      this.errors['label'] = 'app.label.noFileChosen';
      this.errors['text'] = 'app.msg.noFileChosen';
      return false;
    }

    if (
      !this.fileType.fileExtension
        .toLowerCase()
        .includes(this.fileExtension.toLowerCase())
    ) {
      this.errors['label'] = 'app.acceptable.extension';
      this.errors['text'] = this.fileType.fileExtension;
      return false;
    }

    if (this.fileType.maxSize < this.file.size) {
      this.errors['label'] = 'app.acceptable.size';
      this.errors['text'] = this.fileType.maxSize / 1000000 + 'MB';
      return false;
    }

    if (this.uploadCatalogRowLength > 200) {
      this.errors['label'] = 'app-popup-import-quantity.label.maxRowsCatalogTemplate';
      this.errors['text'] = 'app-popup-import-quantity.msg.maxRowsCatalogTemplate';
      return false;
    }

    return true;
  }

  doSave(): void {
    if (this.isValid) {
      this.isSaving = true;
      this.httpClientService.post(this.urlSave, this.formdata).subscribe(result => {
        if (result.status !== ResponseStatusModel.OK) {
          this.global.alertService.showError(
              result.statusText
            );
        } else {
          this.global.alertService.showSuccess(
            this.global.translateService.instant(
              'app-popup-import-quantity.info.success'
            )
          );
        }
        this.onChange.emit(result.status);
        this.setStateReady();
      });
      this.isLoading = false;
    }
  }

  public doCancel(): void {
      this.activeModal.dismiss();
  }
}
