import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ReportRecapColumn } from '../../bean/report-recap-column';
import { AppPopupService } from '../../components/app-popup/app-popup.service';
import { TableResponseModel } from '../../components/table/model/table-response-model';
import { RecapPrintService } from '../../services/recap-print.service';
import { ReportRecapRfpPopupChooseColumnComponent } from './report-recap-rfp-popup-choose-column.component';

@Component({
  templateUrl: './report-recap-rfp-popup-full-view.component.html'
})
export class ReportRecapRfpPopupFullViewComponent extends BaseComponentComponent {
  @Input() reportRecapColumnList: ReportRecapColumn[];
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  public tableResponse: TableResponseModel<any>;

  constructor(
    public activeModal: NgbActiveModal,
    public appPopupService: AppPopupService,
    public translateService: TranslateService,
    public recapPrintService: RecapPrintService
  ) {
    super('report-recap-rfp');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.rfpNumber'
      },
      {
        field: 'title',
        header: 'table.column.title'
      },
      {
        field: 'organization.name',
        header: 'table.column.department'
      },
      {
        field: 'createdDate',
        header: 'table.column.createdDate'
      },
      {
        field: 'creator',
        header: 'table.column.creator'
      },
      {
        field: 'startDate',
        header: 'table.column.responseStartDate'
      },
      {
        field: 'endDate',
        header: 'table.column.responseEndDate'
      },
      {
        field: 'note',
        header: 'table.column.note'
      },
      {
        field: 'rfpStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'rfpStatus.code',
          colorMap: {
            DRAFT: 'SECONDARY',
            REVISION: 'FEEDBACK',
            WAITING_APPROVAL: 'WARNING',
            WAITING_RESPONSE: 'GOOD',
            CANCELED: 'DANGER',
            CLOSED_RESPONSE: 'DANGER',
            DONE: 'SUCCESS',
            COMPLETED: 'SUCCESS',
            WAITING_RFQ: 'WARNING',
            RFQ_PROCESS: 'PROGRESS'
          }
        }
      }
    ]);
  }

  public doMinimize(): void {
    this.onChange.emit();
  }

  public doExport(): void {
    this.appPopupService.open(ReportRecapRfpPopupChooseColumnComponent, {
      reportRecapColumnList: this.reportRecapColumnList
    });
    // this.loadingBlockService.showInfo(
    //   this.translateService.instant('report-recap-rfp.info.preparingData')
    // );
    // this.recapPrintService
    //   .print('/report-recap-rfp/export-list', null)
    //   .subscribe(result => {
    //     console.log(result);
    //     this.loadingBlockService.showCompleteAndClose(
    //       this.translateService.instant('report-recap-rfp.info.successImported')
    //     );
    //   });
  }
}
