<app-layout
  header="contract-negotiation.breadcrumb.index"
  [backTo]="urlBackOutside"
  [isLoading]="formLoading"
>
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge class="cml-2" [color]="badgeColor[contractWorklistStatus.code]">
      {{
        contractWorklistStatus.translationKey.module.code.toLowerCase() +
          '.' +
          contractWorklistStatus.translationKey.key | translate
      }}
    </app-badge>
  </ng-template>
  <form [formGroup]="formGroup">
    <app-workflow-step-info
      [isLoading]="formLoading"
      [model]="contractNegotiationEditResponse.workflowStepInfoModel"
      *ngIf="!formLoading && roleCode !== global.appConstant.ROLE_CODE_VENDOR"
    >
    </app-workflow-step-info>
    <app-contract-request-info
      *ngIf="!formLoading"
      [contractId]="contractId"
    ></app-contract-request-info>
    <app-work-definition
      *ngIf="!formLoading"
      [contractId]="contractId"
    ></app-work-definition>
    <app-vendor-information
      *ngIf="!formLoading && roleCode !== global.appConstant.ROLE_CODE_VENDOR"
      [vendorId]="contract.vendor.id"
    ></app-vendor-information>
    <app-card
      *ngIf="!formLoading"
      header="contract-negotiation.detail.card.contractNegotiation.title"
      tips="contract-negotiation.detail.card.contractNegotiation.tips"
    >
      <ng-template #headerRight>
        <app-button
          (onClick)="doViewReviewer()"
          *ngIf="roleCode !== global.appConstant.ROLE_CODE_VENDOR"
          >{{
            'contract-negotiation.detail.button.contractReviewer' | translate
          }}</app-button
        >
      </ng-template>
      <div *ngIf="roleCode !== global.appConstant.ROLE_CODE_VENDOR">
        <div
          class="form-group row text-sm-left"
          *ngIf="contractNegotiation.isVendorRevised"
        >
          <label class="col-sm-3">
            {{
              'contract-negotiation.detail.form.contractRevisionFromVendor'
                | translate
            }}
          </label>
          <div class="col-sm-6 text-left">
            <app-upload-x
              formControlName="docReceivedFile"
              [fileUploader]="fileReceivedUploader"
            ></app-upload-x>
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{
              'contract-negotiation.detail.form.contractNegotiation' | translate
            }}
          </label>
          <div class="col-sm-6 text-left">
            <app-upload-x
              formControlName="docFile"
              [fileUploader]="fileUploader"
            ></app-upload-x>
          </div>
        </div>
      </div>
      <div *ngIf="roleCode === global.appConstant.ROLE_CODE_VENDOR">
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{
              'contract-negotiation.detail.form.contractNegotiation' | translate
            }}
          </label>
          <div class="col-sm-6 text-left">
            <app-upload-x
              formControlName="docReceivedFile"
              [fileUploader]="fileReceivedUploader"
            ></app-upload-x>
          </div>
        </div>
        <div
          class="form-group row text-sm-left"
          *ngIf="formGroup.value.status?.value !== APPROVED"
        >
          <label class="col-sm-3">
            {{
              'contract-negotiation.detail.form.contractRevisionFromVendor'
                | translate
            }}
          </label>
          <div class="col-sm-6 text-left">
            <app-upload-x
              formControlName="docFile"
              [fileUploader]="fileUploader"
            ></app-upload-x>
          </div>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-negotiation.detail.form.documentVersion' | translate }}
        </label>
        <div class="col-sm-6 text-left">
          <label>Ver.&nbsp;{{ contractNegotiation.sequence }}</label
          >&nbsp;
          <span
            class="hyperlink-text cursor-pointer"
            (click)="onClickVersionHistory()"
          >
            <strong>
              {{
                'contract-negotiation.detail.from.viewVersionHistory'
                  | translate
              }}
            </strong>
          </span>
        </div>
      </div>
      <div
        class="form-group row text-sm-left"
        *ngIf="roleCode === global.appConstant.ROLE_CODE_VENDOR"
      >
        <label class="col-sm-3">
          {{ 'contract-negotiation.detail.form.status' | translate }}
        </label>
        <div class="col-sm-6 text-left">
          <app-dropdown-select
            formControlName="status"
            [optionList]="statusOptionList"
            type="COMBOBOX"
            size="XL"
            (onChange)="onChangeStatus()"
          >
            <ng-template #value let-value>
              <div *ngIf="value" style="display: inline-flex">
                <em
                  style="font-size: 1.5rem"
                  [ngClass]="getColorAndIconConfirm[value.code].icon"
                >
                </em>
                &nbsp;<span
                  class="ml-1"
                  [ngClass]="getColorAndIconConfirm[value.code].color"
                  >{{ value.name }}</span
                >
              </div>
              <div *ngIf="!value && todo === 'view'">-</div>
            </ng-template>
          </app-dropdown-select>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3" *ngIf="isRevision">
          {{ 'contract-negotiation.detail.form.note' | translate }}
        </label>
        <label class="col-sm-3 optional" *ngIf="!isRevision">
          {{ 'contract-negotiation.detail.form.note' | translate }}
        </label>
        <div class="col-sm-6 text-left">
          <app-text-area
            size="XL"
            formControlName="note"
            maxLength="2000"
          ></app-text-area>
        </div>
      </div>
      <app-contract-negotiation-view
        *ngIf="!formLoading"
        [contractId]="contractId"
      >
      </app-contract-negotiation-view>
    </app-card>
  </form>

  <app-approval-monitoring
    *ngIf="
      !formLoading &&
      roleCode !== global.appConstant.ROLE_CODE_VENDOR &&
      contractNegotiationEditResponse?.isProcessed &&
      ((todo === 'view' &&
        contractNegotiationEditResponse &&
        contractNegotiationEditResponse.approvalPathResponseModel &&
        !contractNegotiationEditResponse.isUserApproval) ||
        contractWorklistStatus?.code ===
          global.appConstant.cm.CONTRACT_WORKLIST_STATUS_REVISION)
    "
    [workflowPrcs]="contract?.workflowPrcs"
    [moduleCode]="moduleCode"
    [approvalPathResponseModel]="
      contractNegotiationEditResponse.approvalPathResponseModel
    "
  >
  </app-approval-monitoring>
  <app-approval-prcs-x
    *ngIf="!formLoading && contractNegotiationEditResponse.isUserApproval"
    [workflowModelPrcs]="contract?.workflowModelPrcs"
    [approvalModelPrcsId]="approvalModelPrcsId"
  >
  </app-approval-prcs-x>

  <div
    class="floating-button mt-5"
    *ngIf="!formLoading && todo !== 'view' && !formGroup.isView"
  >
    <div class="floating-button-wrapper">
      <div class="floating-button-content">
        <div class="button-group button-group-center">
          <app-button
            (onClick)="doSubmit()"
            [disabled]="formSaving"
            *ngIf="!formGroup.isView"
            >{{ 'app.button.submit' | translate }}</app-button
          >
        </div>
      </div>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips
      *ngIf="!formLoading"
      activeTipHeader="contract-negotiation.detail.card.contractNegotiation.title"
    ></app-tips>
  </ng-template>
</app-layout>
