import { FileExtension } from '../../core/bean/file-extension';
import { FileType } from '../../core/bean/file-type';
import { Lang } from '../../core/bean/lang';
import { Module } from '../../core/bean/module';
import { Translation } from '../../core/bean/translation';
import { TranslationKey } from '../../core/bean/translation-key';

export class FileTypeResponse {
  fileExtensionList: FileExtension[];
  translationList: Translation[];
  langList: Lang[];
  fileType: FileType;
  fileExtension: FileExtension[];
  translationKey: TranslationKey;
  translation: string[];
  lang: Lang;
  module: Module;
}