import { TabState } from '../interfaces/tab-state.interface';
import { TabElement } from '../models/tab-element-model';

export function changeTabItemSelected(
  tabState: TabState,
  payload: {
    id: string;
    selected: boolean;
    isDynamic: boolean;
  }
): void {
  if (!payload.isDynamic) {
    const tabElement = new TabElement(tabState.tabElement);
    const tabItemContentId = `tab-item-content-${payload.id}`;
    document.getElementById(tabItemContentId).classList.add('active');
    Object.values(tabElement.tabContentElement.children).forEach(element => {
      if (element.id !== tabItemContentId) {
        document.getElementById(element.id).classList.remove('active');
      }
    });
  }

  if (tabState.isTabWithModel) {
    tabState.tabSelected[payload.id] = payload.selected;
  } else {
    Object.keys(tabState.tabSelected).forEach((key: string) => {
      tabState.tabSelected[key] = payload.id === key;
    });
  }
}
