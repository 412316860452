import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppCarouselComponent } from './app-carousel.component';
@NgModule({
  imports: [VendorModule, PipesModule],
  declarations: [AppCarouselComponent],
  exports: [AppCarouselComponent]
})
export class AppCarouselModule {}
