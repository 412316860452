import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { MapResponseModel } from '../../core/components/app-map/model/map-response-model';
import { Social } from './../../core/bean/social';
import { ContactPortalResponse } from './contact-portal-response';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent extends BaseModuleComponent {
  public contact: string;
  public location: string;
  public socialList: Social[] = [];
  public mapModel: MapResponseModel = new MapResponseModel(null, true, true);
  public socialIconData = {
    facebook: 'fa-facebook-official',
    twitter: 'fa-twitter',
    instagram: 'fa-instagram',
    youtube: 'fa-youtube',
    linkedin: 'fa-linkedin'
  };
  constructor(translateService: TranslateService) {
    super('contact', translateService);
  }

  onInit(): void {
    this.doGetContact();
  }

  doGetContact(): void {
    this.httpClientService
      .get<ContactPortalResponse>(
        '/contact/get-contact/' + this.global.currentLang
      )
      .subscribe(contactSocial => {
        this.contact = contactSocial.contact.value;
        this.location = contactSocial.coordinateLocation.value;
        this.socialList = contactSocial.socialList;
        this.doSetMap();
        this.setStateReady();
      });
  }

  doSetMap(): void {
    const longitudeLatitude = this.mapModel.convertStringToLatLng(
      this.location
    );
    this.mapModel.setLatitudeAndLongitude(
      longitudeLatitude.latitude,
      longitudeLatitude.longitude
    );
  }
}
