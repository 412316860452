import { Payment } from 'src/app/core/bean/payment';
import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { PaymentBillingDoc } from 'src/app/core/bean/payment-billing-doc';
import { PaymentInvoice } from 'src/app/core/bean/payment-invoice';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { FileObject } from 'src/app/core/components/upload';

export class VendorPaymentRequestUpdateRequest {
    payment: Payment;
    paymentInvoice: PaymentInvoice;
    procurementVendor: ProcurementVendor;
    paymentBilling: PaymentBilling;
    paymentBillingDocumentList: PaymentBillingDoc[];
    fileObjectList: FileObject[];
    paymentBillingDocList: PaymentBillingDoc[];
    contractId: number;
    isPartial: boolean;
    remainingPayment: number;
    isSubmit: boolean;
    paymentBillingId: number;
    amount: number;
}
