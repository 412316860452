import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Question } from '../../core/bean/question';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';

@Component({
  templateUrl: './question-edit-add.component.html'
})
export class QuestionEditAddComponent extends BaseModuleComponent {
  public questionId: number;
  constructor(translateService: TranslateService) {
    super('question', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.questionId = this.global.routerParams.get('questionId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [null, Validators.compose([Validators.required()])],
      code: [null, Validators.compose([Validators.required()])],
      description: [null, Validators.maxLength(512)]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<Question>(
        '/question/add-edit',
        new RouteRequestModel(this.todo, this.questionId)
      )
      .subscribe(question => {
        if (question != null) {
          const { id, name, code, description } = question;
          this.formGroup.patchValue({ id, name, code, description });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const question = this.global.copyFormAttributeToModel(
              new Question(),
              this.formGroup
            );
            const url =
              this.todo === 'edit' ? '/question/update' : '/question/insert';
            this.httpClientService
              .post<Response<Question>>(url, question)
              .subscribe((response: Response<Question>) => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/question']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/question']);
  }
}
