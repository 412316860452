import { Injectable } from '@angular/core';
import { AppPopupService } from '../app-popup.service';
import { AppPopupCoaBjbComponent } from './app-popup-coa-bjb.component';

@Injectable()
export class AppPopupCoaBjbService {
  constructor(public appPopupService: AppPopupService) {}

  public open() {
    return this.appPopupService.open(AppPopupCoaBjbComponent);
  }
}
