import { createYearList } from '../helper/create-year-list-helper';
import { DatePickerState } from '../model';
import { DatePickerDropDownView } from '../type/date-picker-drop-down-view-type';
export const setDatePickerDropDownView = (
  state: DatePickerState,
  payload: { view: DatePickerDropDownView }
): void => {
  state.view = payload.view;
  if (state.view === 'YEAR') {
    state.yearList = createYearList(state.currentDate.year);
  }
};
