import { Payment } from 'src/app/core/bean/payment';
import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { PaymentInvoice } from 'src/app/core/bean/payment-invoice';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { FileObject } from 'src/app/core/components/upload';
import { PaymentBillingDoc } from '../../core/bean/payment-billing-doc';

export class InternalPaymentRequestUpdateRequest {
  payment: Payment;
  paymentInvoice: PaymentInvoice;
  paymentBilling: PaymentBilling;
  procurementVendor: ProcurementVendor;
  paymentBillingDocList: PaymentBillingDoc[];
  fileObjectList: FileObject[];
  contractId: number;
  isSubmit: boolean;
}
