import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { SensitiveDataService } from 'src/app/core/services/sensitive-data.service';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { User } from '../../core/bean/user';
import { UserRole } from '../../core/bean/user-role';
import { UserRoleLog } from '../../core/bean/user-role-log';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { FileObject, FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { Lang } from './../../core/bean/lang';
import { AccountSettingRequest } from './account-setting.request';
import { AccountSettingResponse } from './account-setting.response';
import { UserPreference } from 'src/app/core/bean/user-preference';

@Component({
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountSettingComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<UserRoleLog>;
  public accountSettingResponse: AccountSettingResponse =
    new AccountSettingResponse();
  public accountSettingRequest: AccountSettingRequest =
    new AccountSettingRequest();
  public isPasswordBlock = false;
  public isEmailBlock = false;
  public roleOptionList: OptionListModel<UserRole> = new OptionListModel(
    false,
    'role.name'
  );
  public languageOptionList: OptionListModel<Lang> = new OptionListModel(false);
  public newEmail: string;
  public isPhotoChanged = false;
  public isDisable = false;
  public isEdit = false;
  public roleDefault = null;
  public fileUploader: FileUploader = new FileUploader(
    '/account-setting/',
    '',
    this.global.appConstant.fileType.IMG_PROFILE_PICTURE,
    false,
    5
  );
  public userPreference: UserPreference = new UserPreference();

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public sensitiveDataService: SensitiveDataService
  ) {
    super('account-setting', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(255)])
      ],
      oldPassword: [null],
      newPassword: [null],
      confirmNewPassword: [null],
      lang: [null, Validators.compose([Validators.required()])],
      email: [null],
      userRole: [null],
      isActive: [null],
      imgFile: [null],
      newEmail: [null],
      confirmEmail: [null],
      isNotifEnable: [true],
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'role.name',
        header: 'table.column.role',
        customClass: 'text-left'
      },
      {
        field: 'organization.name',
        header: 'table.column.organization',
        customClass: 'text-left'
      },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        format: FieldFormatEnum.ShortDate
      }
    ]);
  }

  public setFormGroup(): void {
    this.isPhotoChanged = true;
    this.httpClientService
      .get<AccountSettingResponse>('/account-setting/index', {})
      .subscribe(accountSettingResponse => {
        this.accountSettingResponse = accountSettingResponse;
        this.accountSettingRequest.user = accountSettingResponse.user;
        const user: User = this.accountSettingResponse.user;
        this.languageOptionList.setRequestValues(
          this.accountSettingResponse.langList
        );
        this.roleOptionList.setRequestValues(user.validUserRoleList);
        const indexRole = user.validUserRoleList.findIndex(
          (uRole: UserRole) => uRole.isDefault === true
        );
        if (indexRole != null) {
          this.roleDefault = user.validUserRoleList[indexRole];
        }
        const indexLang = this.accountSettingResponse.langList.findIndex(
          lang => lang.id === user.lang.id
        );
        if (user.imgFile) {
          this.fileUploader.setFileList([user.imgFile]);
        }
        this.formGroup.patchValue({
          name: user.name,
          email: user.email,
          isActive: user.isActive,
          lang: this.accountSettingResponse.langList[indexLang],
          userRole: user.validUserRoleList[indexRole],
          imgFile: this.fileUploader.fileObjectList
        });
        this.isPhotoChanged = false;
        if (accountSettingResponse.userPreferenceNotif) {
          this.userPreference = accountSettingResponse.userPreferenceNotif;
        }
        if (accountSettingResponse.userPreferenceNotif?.value == "TRUE") {
          this.formGroup.get('isNotifEnable').patchValue(false);
        } else {
          this.formGroup.get('isNotifEnable').patchValue(true);
        }
        this.setStateReady();
      });
  }

  public doEdit(): void {
    this.isEdit = true;
    this.setFormGroup();
    this.formGroup.patchValue({
      oldPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      newEmail: null,
      confirmEmail: null
    });
  }

  public doSave(): void {
    this.validate();

    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const { userRole } = this.formGroup.value;
            const accountSettingRequest: AccountSettingRequest =
              this.global.copyFormAttributeToModel(new User(), this.formGroup);

            if (
              this.formGroup.get('imgFile').value &&
              !Array.isArray(this.formGroup.get('imgFile').value)
            ) {
              /* sudah ada foto sblumnya dan langsung simpan */
              const fileObjectList: FileObject[] = [];
              fileObjectList.push(
                new FileObject(this.formGroup.get('imgFile').value, false)
              );
              accountSettingRequest.imgFile = fileObjectList;
            }

            accountSettingRequest.newEmail = this.formGroup.get('newEmail').value;
            accountSettingRequest.userRoleDefaultId =
              userRole != null
                ? userRole.id
                : this.global.userSession.activeUserRole.id;

            const oldPassword = this.formGroup.get('oldPassword').value;
            const newPassword = this.formGroup.get('newPassword').value;

            delete accountSettingRequest['oldPassword'];
            delete accountSettingRequest['newPassword'];
            delete accountSettingRequest['confirmNewPassword'];

            accountSettingRequest.auth = this.sensitiveDataService.encrypt([
              oldPassword,
              newPassword
            ]);
            if (this.formGroup.get('isNotifEnable').value == true) {
              this.userPreference.value = "FALSE";
            } else {
              this.userPreference.value = "TRUE";
            }
            accountSettingRequest.userPreferenceNotif = this.userPreference;

            this.httpClientService
              .post<Response<AccountSettingRequest>>(
                '/account-setting/update',
                accountSettingRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.changeLang(
                    accountSettingRequest.lang.code.toLowerCase(),
                    'pages'
                  );
                  this.global.userSession.user = response.body;
                  this.global.alertService.showSuccessSaving();
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.isEdit = false;
                this.isPasswordBlock = false;
                this.isEmailBlock = false;
                this.updateValidationPassword();
                this.resetPasswordEmailValue();
                this.setFormGroup();
                this.setStateReady();
              });
          }
        });
    }
  }

  public resetPasswordEmailValue(): void {
    this.formGroupService.bulkResetFormControl(this.formGroup, [
      'oldPassword',
      'newPassword',
      'confirmNewPassword',
      'newEmail',
      'confirmEmail'
    ]);
  }

  public doChangeEmail(): void {
    this.isEmailBlock = true;
    this.formGroup
      .get('newEmail')
      .setValidators(
        Validators.compose([
          Validators.required(),
          Validators.email(),
          Validators.maxLength(64),
          Validators.exists(
            [this.global.userSession.user.email],
            'Tidak boleh sama dengan email sebelumnya'
          )
        ]));
    this.formGroup
      .get('confirmEmail')
      .setValidators([Validators.required(), Validators.match('newEmail')]);

    this.formGroup.get('newEmail').updateValueAndValidity();
    this.formGroup.get('confirmEmail').updateValueAndValidity();
  }

  public doChangePassword(): void {
    this.isPasswordBlock = true;
    this.formGroup
      .get('oldPassword')
      .setValidators([Validators.required(), Validators.maxLength(32)]);
    this.formGroup
      .get('newPassword')
      .setValidators([Validators.required(), Validators.password()]);
    this.formGroup
      .get('confirmNewPassword')
      .setValidators([
        Validators.required(),
        Validators.matchPassword('newPassword'),
        Validators.password()
      ]);

    this.formGroup.get('oldPassword').updateValueAndValidity();
    this.formGroup.get('newPassword').updateValueAndValidity();
    this.formGroup.get('confirmNewPassword').updateValueAndValidity();
  }

  public doDisableButton(event): void {
    if (event) {
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);
    }
  }

  public doCancel(): void {
    this.isEdit = false;
    this.setFormGroup();
    this.isPasswordBlock = false;
    this.isEmailBlock = false;
    this.formGroup.patchValue({
      oldPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      newEmail: null,
      confirmEmail: null
    });
    this.updateValidationPassword();
  }

  public updateValidationPassword(): void {
    this.formGroup.get('oldPassword').clearValidators();
    this.formGroup.get('newPassword').clearValidators();
    this.formGroup.get('confirmNewPassword').clearValidators();
    this.formGroup.get('newEmail').clearValidators();
    this.formGroup.get('confirmEmail').clearValidators();
    this.formGroup.get('oldPassword').updateValueAndValidity();
    this.formGroup.get('newPassword').updateValueAndValidity();
    this.formGroup.get('confirmNewPassword').updateValueAndValidity();
    this.formGroup.get('newEmail').updateValueAndValidity();
    this.formGroup.get('confirmEmail').updateValueAndValidity();
  }

  generatePasswordMask(password: string): string {
    return '*'.repeat(password.length);
  }
}
