<app-popup
  header="contract-history.popup.sendReminder.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <app-alert-x *ngIf="guarantee"
    >{{
      'contract-history.popup.sendReminder.alert.guarantee' | translate
    }}&nbsp;{{ guarantee.guaranteeBondType.name }}</app-alert-x
  >
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <app-select
      formControlName="userReminderList"
      [optionList]="contractReminderOptionList"
      selectAllText="{{
        'contract-history.popup.sendReminder.selectAll' | translate
      }}"
      [isMultiple]="true"
      [isShowInputControl]="true"
      [search]="true"
    >
      <ng-template #headerFilterGroup>
        <div class="form-group row text-sm-left">
          <div class="col-sm-5 text-left">
            <app-combo-box-tree
              size="XL"
              formControlName="organization"
              stringUrl="/organization"
              (onChange)="onChangeFilter()"
              placeholder="contract-draft.placeholder.department"
            >
            </app-combo-box-tree>
          </div>
        </div>
      </ng-template>

      <ng-template #headerButtons>
        <app-button [outline]="true" (onClick)="doAddNewEmail()">
          <em class="pir pi-plus-circle"></em>
          {{ 'contract-history.popup.button.newEmail' | translate }}
        </app-button>
      </ng-template>
      <ng-template #content let-data>
        <div style="margin: 0 auto; display: flex; position: relative">
          <div
            style="
              width: 50px;
              height: 50px;
              overflow: hidden;
              border-radius: 50px;
            "
            class="mr-3"
          >
            <img
              style="width: 100%"
              [src]="'assets/' + data.user?.imgFile?.fileName"
              alt="user image"
              [default]="global.appConstant.core.URL_DEFAULT_PROFILE_IMAGE"
            />
          </div>

          <div class="d-inline-block">
            <ng-container *ngIf="!data.user && !data.position">
              <span class="d-inline-flex">
                <h5 class="mr-1">{{ data.name }}</h5>
                <i class="text-secondary">( {{ data.email }} )</i>
              </span>
              <p class="text-secondary">{{ data.code }}</p>
            </ng-container>
            <ng-container *ngIf="!data.user && data.position">
              <span class="d-inline-flex">
                <h5 class="mr-1">{{ data.name }}</h5>
                <i class="text-secondary">( {{ data.email }} )</i>
              </span>
              <p class="text-secondary">{{ data.position }}</p>
            </ng-container>
            <ng-container *ngIf="data.user">
              <span class="d-inline-flex">
                <h5 class="mr-1">{{ data.user.name }}</h5>
                <i class="text-secondary">( {{ data.user.email }} )</i>
              </span>
              <p class="text-secondary">{{ data.user.organization.name }}</p>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </app-select>
  </form>
  <div class="button-group button-group-center mt-5">
    <app-button [outline]="true" color="SECONDARY" (onClick)="doCancel()">{{
      'app.button.cancel' | translate
    }}</app-button>
    <app-button (onClick)="doSendReminder()">{{
      'contract-history.popup.button.sendReminder' | translate
    }}</app-button>
  </div>
</app-popup>
