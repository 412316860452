import { ArrayUtils } from '../../../utils';
import { TableState } from '../domain/table-state';
import { TableColumnComponentModel } from '../model/table-column-component-model';
import { TableColumnModel } from '../model/table-column-model';
export const searchTableRecords = (
  state: TableState,
  records: Array<any>
): Array<any> => {
  const keyword = state.tableRequest.globalFilter;
  if (keyword) {
    records = records.filter((record: any) => {
      let isMatch = false;
      const newRecord =
        state.recordTemp.recordMap.get(JSON.stringify(record)) || record;
      state.tableRequest.columnList.forEach((column: TableColumnModel) => {
        if (column.component) {
          if (column.component.hasOwnProperty('target')) {
            const component = column.component as TableColumnComponentModel;
            if (component.callbacks && component.callbacks.onSearch) {
              const result = component.callbacks.onSearch(newRecord);
              if (result.toLowerCase().includes(keyword.toLowerCase())) {
                isMatch = true;
              }
            }
          }
        } else {
          if (!isMatch && column.isSearchTable !== false) {
            let value: any = ArrayUtils.resolveFieldData(
              newRecord,
              column.field,
              JSON.stringify(column.dataMap)
            );
            const isDateValue =
              new Date(value).toDateString() !== 'Invalid Date';
            if (
              !isMatch &&
              value &&
              (typeof value === 'number'
                ? value.toString()
                : isDateValue
                  ? value.toString()
                  : value
              )
                .toLowerCase()
                .includes(keyword.toLowerCase())
            ) {
              isMatch = true;
            }
          }
        }
      });
      if (isMatch) {
        return newRecord;
      }
    });
  }
  return records;
};
