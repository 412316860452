import { BaseEntity } from '../base/base-entity';
import { Role } from '../bean/role';
import { ThresholdRange } from '../bean/threshold-range';
import { User } from '../bean/user';
import { Condition } from './../bean/condition';


export class ThresholdEntity extends BaseEntity {
  thresholdRangeList: ThresholdRange[];

  user: User;
  role: Role;
  condition: Condition;
}
