import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
export const expandCollapseAnimation = (
  minHeight = '0',
  addtionalStyle: any = { opacity: 1, visibility: 'hidden' },
  animateTime = '250ms'
): AnimationTriggerMetadata => {
  return trigger('expandedCollapsed', [
    state(
      'collapsed',
      style(
        Object.assign(
          {
            height: minHeight,
            overflow: 'hidden'
          },
          addtionalStyle
        )
      )
    ),
    state(
      'expanded',
      style({
        overflow: 'hidden'
      })
    ),
    transition('collapsed<=>expanded', animate(animateTime))
  ]);
};
