<app-layout [isLoading]="formLoading" [isEnableBackButton]="true">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <div class="row">
        <div class="col-4">
          <app-card
            header="report-recap-rfp.detail.filterReport.title"
            [isLoading]="formLoading"
            [isSaving]="formSaving"
          >
            <form
              class="form-horizontal"
              [formGroup]="formGroup"
              novalidate
              *ngIf="!formLoading"
            >
              <h4 class="separator">General</h4>
              <div class="form-group row text-sm-left">
                <label class="col-sm-4 control-label">{{
                  'report-recap-rfp.form.status' | translate
                }}</label>
                <div class="col-sm-8 text-left">
                  <app-dropdown-select
                    size="XL"
                    formControlName="statusList"
                    showLimitValue="3"
                    type="CHOSEN"
                    [optionList]="statusOptionList"
                    placeholder="report-recap-rfp.placeholder.status"
                  >
                  </app-dropdown-select>
                </div>
              </div>
              <h4 class="separator">Type of RFP</h4>
              <div class="form-group row text-sm-left">
                <label class="col-sm-4 control-label">{{
                  'report-recap-rfp.form.responseStartDate' | translate
                }}</label>
                <div class="col-sm-8 text-left">
                  <app-date-picker-x
                    size="XL"
                    formControlName="startDate"
                    [range]="true"
                  >
                  </app-date-picker-x>
                </div>
              </div>
              <div class="form-group row text-sm-left">
                <label class="col-sm-4 control-label">{{
                  'report-recap-rfp.form.responseEndDate' | translate
                }}</label>
                <div class="col-sm-8 text-left">
                  <app-date-picker-x
                    size="XL"
                    formControlName="endDate"
                    [range]="true"
                  >
                  </app-date-picker-x>
                </div>
              </div>
              <div class="form-group row text-sm-left">
                <label class="col-sm-4 control-label">{{
                  'report-recap-rfp.form.department' | translate
                }}</label>
                <div class="col-sm-8 text-left">
                  <app-dropdown-select
                    size="XL"
                    formControlName="organizationList"
                    showLimitValue="3"
                    type="CHOSEN"
                    [optionList]="organizationOptionList"
                    placeholder="report-recap-rfp.placeholder.department"
                  >
                  </app-dropdown-select>
                </div>
              </div>
              <div class="form-group row text-sm-left">
                <label class="col-sm-4 control-label">{{
                  'report-recap-rfp.form.minimumTotalCost' | translate
                }}</label>
                <div class="col-sm-8 text-left">
                  <app-currency
                    size="XL"
                    placeholder="{{
                      'report-recap-rfp.placeholder.minimumCost' | translate
                    }}"
                    [optionList]="currencyOptionList"
                    formControlName="minimumAmount"
                  ></app-currency>
                </div>
              </div>
              <div class="form-group row text-sm-left">
                <label class="col-sm-4 control-label">{{
                  'report-recap-rfp.form.maximumTotalCost' | translate
                }}</label>
                <div class="col-sm-8 text-left">
                  <app-currency
                    placeholder="{{
                      'report-recap-rfp.filter.placeholder.maximumCost'
                        | translate
                    }}"
                    size="XL"
                    [optionList]="currencyOptionList"
                    formControlName="maximumAmount"
                  ></app-currency>
                </div>
              </div>
              <div class="button-group half-button">
                <app-button
                  mode="BYPASS"
                  color="LIGHT"
                  [outline]="true"
                  (onClick)="doReset()"
                >
                  <em class="fas fa-sync-alt"></em>
                  {{ 'app.button.reset' | translate }}
                </app-button>
                <app-button
                  mode="BYPASS"
                  color="PRIMARY"
                  (onClick)="doGenerate()"
                  [disabled]="formSaving"
                >
                  {{ 'app.button.generate' | translate }}
                </app-button>
              </div>
            </form>
          </app-card>
        </div>
        <div class="col-8">
          <app-card [isLoading]="formLoading" [isSaving]="formSaving">
            <app-table-xx
              header="report-recap-rfp.detail.result.title"
              [model]="tableResponse"
              stringUrl="/report-recap-rfp/index"
              [isShowPagination]="true"
              [isShowPopupModifyTable]="isShowPopupModifyTable"
              [isFullViewTable]="isFullViewTable"
            >
              <ng-template #headerButtons>
                <app-button
                  [outline]="true"
                  [borderLess]="true"
                  (onClick)="doCustomSetting()"
                  *ngIf="reportBuilderId"
                >
                  <em
                    class="pir pi-cog mr-1"
                    style="
                      top: auto;
                      left: auto;
                      font-size: 16px;
                      font-weight: 500;
                    "
                  ></em>
                  <span class="text-underline">
                    {{ 'app.button.customSetting' | translate }}
                  </span>
                </app-button>
                <app-button
                  [outline]="true"
                  [borderLess]="true"
                  (onClick)="isShowPopupModifyTable = !isShowPopupModifyTable"
                >
                  <em class="pir pi-cog mr-1"> </em>
                  {{ 'app-table.button.modifyTable' | translate }}
                </app-button>
                <app-button
                  [outline]="true"
                  [borderLess]="true"
                  (onClick)="isFullViewTable = !isFullViewTable"
                >
                  <em
                    class="fas mr-1"
                    [ngClass]="
                      isFullViewTable
                        ? 'pir pi-compress-alt'
                        : 'pir pi-expand-alt'
                    "
                  ></em>
                  {{
                    (isFullViewTable
                      ? 'app-table.button.minimize'
                      : 'app-table.button.maximize'
                    ) | translate
                  }}
                </app-button>
                <app-button
                  (onClick)="doExport()"
                  [outline]="true"
                  [borderLess]="true"
                >
                  <em class="pir pi-file-download mr-1"></em>
                  <span class="text-underline">{{
                    'report-recap-rfp.detail.downloadReport' | translate
                  }}</span>
                </app-button>
              </ng-template>
            </app-table-xx>
          </app-card>
        </div>
      </div>
    </div>
  </div>
</app-layout>
