import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { Period } from '../bean/period';
import { Vendor } from '../bean/vendor';
export class VendorPerformanceOverallEntity extends BaseWorkflowObjectEntity {
  vendor: Vendor = new Vendor();
  createdDate: Date;
  rating: number;
  startMonth: string;
  endMonth: string;
  totalMonth: number;
  startYear: string;
  endYear: string;
  isQualified: boolean;
  period: Period = new Period();
}
