import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Notification } from './../../core/bean/notification';

@Component({
  templateUrl: 'reminder.component.html'
})
export class ReminderComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Notification>;
  constructor(translateService: TranslateService) {
    super('reminder', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.column.code' },
      { field: 'name', header: 'table.column.name' },
      { field: 'description', header: 'table.column.description' },
      { field: 'reminder.time', header: 'table.column.time', sortable: false },
      {
        field: 'isRepeatReminder',
        header: 'table.column.isRepeat',
        datamap: '{"false" : "Repeat" , "true" : "One Time" }'
      },
      {
        field: 'reminder.period',
        header: 'table.column.period',
        sortable: false
      },
      {
        field: 'isActiveReminder',
        header: 'table.column.isActive',
        datamap: '{"false" : "Not Active" , "true" : "Active" }'
      }
    ]);
  }

  public doEdit(notification: Notification): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('notification', notification);
    this.router.navigate(['/pages/reminder/edit']);
  }
}
