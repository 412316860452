import { BaseEntity } from '../base/base-entity';
import { ProcurementItem } from '../bean/procurement-item';
import { ProcurementVendor } from '../bean/procurement-vendor';

export class ProcurementResultEntity extends BaseEntity {
  status: number;
  percentageValue: string;
  receivedItemPerQuantity: string;

  procurementVendor: ProcurementVendor = new ProcurementVendor();
  procurementItem: ProcurementItem = new ProcurementItem();
  itemQuantity: number;
  price: number;
  totalPrice: number;
}
