<app-popup header="initiation.popup.requirementPq.title" [isLoading]="formLoading"
    [isShowHeaderCloseButton]="false">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-left">
            <div class="col">
                <app-select formControlName="pqList"
                [optionList]="pqOptionList"
                [isMultiple]="true"
                [search]="true"
                selectAllText="{{ 'initiation.popup.requirementPq.selectAll' | translate }}"
                size="XL"
                >

                </app-select>
            </div>
        </div>

        <ng-template #modalFooter let-activeModal>
            <div class="button-group button-group-center">
                <app-button color="LIGHT" (onClick)="activeModal.close(true)">
                    {{ 'app.button.cancel' | translate }}</app-button>
                <app-button (onClick)="doAddPq()">
                    {{ 'app.button.add' | translate }}</app-button>
            </div>
        </ng-template>
    </form>
</app-popup>