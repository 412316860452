<app-layout>

  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-card
          header="proposal-criteria.detail.general.title"
          tips="proposal-criteria.detail.general.tips"
        >
        <app-fullfilled></app-fullfilled>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">{{
              'proposal-criteria.form.code' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="code" placeholder="Type Code">
              </app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">{{
              'proposal-criteria.form.name' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="idName" placeholder="Type Name" tooltip="proposal-criteria.tooltip.englishName">
              </app-text-field>
              <small>{{ 'proposal-criteria.label.documentNameInBahasaIndonesia' | translate }}</small>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label"></label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="enName" placeholder="Type Name" tooltip="proposal-criteria.tooltip.indonesianName">
              </app-text-field>
              <small>{{ 'proposal-criteria.label.translationNameInEnglish' | translate }}</small>
            </div>
          </div>

        </app-card>

        <div
          class="button-group button-group-center mt-5"
        >
          <app-button
            (onClick)="doSave()"
            [disabled]="formSaving"
            >{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips *ngIf="!formLoading"></app-tips>
  </ng-template>
</app-layout>
