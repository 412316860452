import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAutoCompleteModule } from '../app-auto-complete/app-auto-complete.module';
import { AppBinaryTreeModule } from '../app-binary-tree/app-binary-tree.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppChosenModule } from '../app-chosen/app-chosen.module';
import { AppComboBoxModule } from '../app-combo-box/app-combo-box.module';
import { AppDropdownSelectModule } from '../app-dropdown-select/app-dropdown-select.module';
import { AppFullfilledModule } from '../app-fullfilled/app-fullfilled.module';
import { AppPopupModule } from '../app-popup/app-popup.module';
import { AppSelectModule } from '../app-select/app-select.module';
import { AppTextFieldModule } from '../app-text-field/app-text-field.module';
import { AppTextTreeModule } from '../app-text-tree/app-text-tree.module';
import { AppCommitteeComponent } from './app-committee.component';
import { AppPopupAddCommitteeComponent } from './app-popup-add-committee.component';
import { AppPopupChooseCommitteeComponent } from './app-popup-choose-committee.component';

@NgModule({
  imports: [
    VendorModule,
    AppBinaryTreeModule,
    AppPopupModule,
    AppComboBoxModule,
    AppAutoCompleteModule,
    AppButtonModule,
    AppSelectModule,
    AppChosenModule,
    DirectivesModule,
    AppFullfilledModule,
    AppCardModule,
    AppDropdownSelectModule,
    AppTextTreeModule,
    AppTextFieldModule
  ],
  declarations: [
    AppCommitteeComponent,
    AppPopupAddCommitteeComponent,
    AppPopupChooseCommitteeComponent
  ],
  entryComponents: [
    AppPopupAddCommitteeComponent,
    AppPopupChooseCommitteeComponent
  ],
  exports: [
    AppCommitteeComponent,
    AppPopupAddCommitteeComponent,
    AppPopupChooseCommitteeComponent
  ]
})
export class AppCommitteeModule {}
