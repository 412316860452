import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ModalService } from '../services/modal.service';
@Injectable({ providedIn: 'root' })
export class ConfirmGuard implements CanDeactivate<any> {
  constructor(private modalService: ModalService) {}
  canDeactivate(
    component: any
  ): Observable<boolean> | Promise<boolean> | boolean {
    if ((component.formGroup.dirty && !component.formGroup.isSubmitted)
      || component.leaveConfirmation ) {
      return component.leaveConfirmation
      ? component.leaveConfirmation()
      : new Promise(resolve => {
        this.modalService.leaveConfirmation().subscribe(result => {
          resolve(result);
        });
      });
    }
    return true;
  }
}
