import { Committee } from '../../bean/committee';
import { CommitteeParticipant } from '../../bean/committee-participant';
import { CommitteePosition } from '../../bean/committee-position';
import { Organization } from '../../bean/organization';
import { User } from '../../bean/user';

export class AppCommitteeResponse {
  userList: User[] = [];
  committeeList: Committee[] = [];
  committeePositionList: CommitteePosition[] = [];
  organizationList: Organization[] = [];

  committee: Committee;
  committeeParticipantList: CommitteeParticipant[] = [];
}
