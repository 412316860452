export class TextUtils {
  public static generateRandomString(
    length = 4,
    customCharacters?: string
  ): string {
    let result = '';
    const crypto = window.crypto;
    const arrayNumber = new Uint32Array(1);
    const characters =
      customCharacters ||
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    while (result.length < length) {
      crypto.getRandomValues(arrayNumber);
      result += characters.charAt(arrayNumber[0] % characters.length);
    }
    return result;
  }
}
