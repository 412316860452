import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppComboBoxTreeModule } from '../../app-combo-box-tree/app-combo-box-tree.module';
import { AppDropdownSelectModule } from '../../app-dropdown-select/app-dropdown-select.module';
import { AppSelectModule } from '../../app-select/app-select.module';
import { AppTextAreaModule } from '../../app-text-area/app-text-area.module';
import { AppTextFieldModule } from '../../app-text-field/app-text-field.module';
import { AppTextTreeModule } from '../../app-text-tree/app-text-tree.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupAddressComponent } from './app-popup-address.component';
import { AppPopupAddressService } from './app-popup-address.service';
@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppTextFieldModule,
    AppTextAreaModule,
    AppButtonModule,
    AppSelectModule,
    AppTextTreeModule,
    AppComboBoxTreeModule,
    AppDropdownSelectModule
  ],
  declarations: [AppPopupAddressComponent],
  entryComponents: [AppPopupAddressComponent],
  providers: [AppPopupAddressService],
  exports: [AppPopupAddressComponent]
})
export class AppPopupAddressModule {}
