import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { TabProfileGeneralInformationComponent } from './tab-profile-general-information.component';
export const routes = [
  {
    path: '',
    component: TabProfileGeneralInformationComponent,
    data: { breadcrumb: '' }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    TabProfileGeneralInformationComponent
  ],
  exports: [
    TabProfileGeneralInformationComponent
  ]
})

export class TabProfileGeneralInformationModule {}
