<app-popup [isShowHeaderCloseButton]="false">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="text-center"
      ><br />
      <h3 [ngClass]="'text-primary'">{{
        'procurement-schedule.popup.change.title' | translate
      }}</h3
      ><br />
      <div
        innerHTML="{{ 'procurement-schedule.popup.change.detail' | translate }}"
      ></div>
    </div>
    <div class="row text-sm-left mt-4">
      <div class="col-sm-8 text-left text-secondary">
        <p>{{ 'procurement-schedule.popup.change.reason' | translate }}</p>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <div class="col-sm-12 text-left">
        <app-text-area
          formControlName="reason"
          size="XL"
          maxLength="255"
        ></app-text-area>
      </div>
    </div>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button
          color="LIGHT"
          [outline]="true"
          (onClick)="activeModal.close(false)"
        >
          {{ 'app.button.cancel' | translate }}
        </app-button>
        <app-button (onClick)="doSave()">
          {{ 'procurement-schedule.button.sure' | translate }}
        </app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
