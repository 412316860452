import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcurementType } from 'src/app/core/bean/procurement-type';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Wanprestasi } from '../../core/bean/wanprestasi';
import { AppPopupProcurementService } from '../../core/components/app-popup/app-popup-procurement/app-popup-procurement.service';
import { FileUploader } from '../../core/components/upload';
import { Validators } from '../../core/validators';
import { WanprestasiResponse } from './wanprestasi-response';
@Component({
  selector: 'app-popup-procurement-activity',
  templateUrl: './app-popup-procurement-activity.component.html'
})
export class AppPopupProcurementActivityComponent extends BaseComponentComponent {
  @Input() public todo: string;
  @Input() public procurementIdList: number[];
  @Input() public wanprestasiEditView: Wanprestasi;
  @Input() public wanprestasiList: Wanprestasi[];
  @Output() public onChange: EventEmitter<Wanprestasi> = new EventEmitter();

  public wanprestasiResponse: WanprestasiResponse = new WanprestasiResponse();
  public vendorId: number;
  public stages: string = null;
  public procurementType: string = null;
  public procVendorId: number;
  public fileUploader: FileUploader = new FileUploader(
    '/wanprestasi/',
    'wanprestasi',
    this.global.appConstant.fileType.DOC_MEMO_WANPRESTASI
  );
  constructor(
    public appPopupProcurementService: AppPopupProcurementService,
    public activeModal: NgbActiveModal
  ) {
    super('wanprestasi');
  }

  onInit(): void {
    this.setVendorIdFromRouterParams();
    this.buildFormGroup();
    this.getAndSetWanprestasiToFormGroup();
    this.setStateReady();
  }

  public setVendorIdFromRouterParams(): void {
    this.vendorId = this.global.routerParams.get('vendorId');
  }

  public buildFormGroup(): void {
    const id: number = null;
    const procurementType = new ProcurementType();
    this.formGroup = this.formBuilder.group({
      id: [null], // id wanprestasi
      nameProcurement: [null, Validators.required()],
      wanprestasiDate: [null, Validators.required()],
      wanprestasiDetail: [null, Validators.required()],
      memoNumber: [
        null,
        {
          validators: [Validators.required()],
          asyncValidators: [
            Validators.existsAsync('/wanprestasi/check-exist', null, null, {
              id: null
            })
          ]
        }
      ],
      file: [null, Validators.required()],
      note: [null],
      procurementDate: [null],
      procurementType: [null],
      stage: [null],
      vendor: {
        id
      },
      procurement: {
        id,
        name: null,
        stage: null,
        date: null,
        procurementType
      }
    });
  }

  public getAndSetWanprestasiToFormGroup(): void {
    if (this.wanprestasiEditView != null) {
      this.stages =
        this.wanprestasiEditView.procurement.workflowModelPrcs?.name;
      this.procurementType =
        this.wanprestasiEditView.procurement.procurementType.name;
      if (this.todo === 'edit') {
        // this.fileUploader.setFile(this.wanprestasiEditView.file.fileObjectList[0].file);
        this.fileUploader.setFile(this.wanprestasiEditView.file[0].file);
      } else if (this.todo === 'view') {
        this.fileUploader.setFile(this.wanprestasiEditView.file);
      }

      this.formGroup.patchValue({
        id: this.wanprestasiEditView.id,
        nameProcurement: this.wanprestasiEditView.procurement.name,
        wanprestasiDate: this.wanprestasiEditView.wanprestasiDate,
        wanprestasiDetail: this.wanprestasiEditView.wanprestasiDetail,
        memoNumber: this.wanprestasiEditView.memoNumber,
        file: this.fileUploader.fileObjectList,
        note: this.wanprestasiEditView.note,
        procurementDate: this.wanprestasiEditView.procurement.procurementDate,
        procurementType:
          this.wanprestasiEditView.procurement.procurementType.name,
        stage: this.wanprestasiEditView.procurement.workflowModelPrcs?.name,
        procurement: {
          id: this.wanprestasiEditView.procurement.id,
          name: this.wanprestasiEditView.procurement.name,
          stage: this.wanprestasiEditView.procurement.workflowModelPrcs?.name,
          date: this.wanprestasiEditView.procurement.procurementDate,
          procurementType: {
            name: this.wanprestasiEditView.procurement.procurementType.name
          }
        },
        vendor: {
          id: this.wanprestasiEditView.vendor.id
        }
      });

      if (this.todo === 'view') {
        this.setViewOnly();
        this.formGroup.patchValue({
          nameProcurement: this.wanprestasiEditView.procurement.name
        });
        this.stages =
          this.wanprestasiEditView.procurement.workflowModelPrcs?.name;
        this.procurementType =
          this.wanprestasiEditView.procurement.procurementType.name;
      }
    }
  }

  public doChooseProcurement(): void {
    this.appPopupProcurementService
      .open(this.procurementIdList)
      .subscribe(procVendor => {
        this.procVendorId = procVendor.id;

        const nameProcurement: string = procVendor.name;
        const procurementDate: Date = procVendor.date;
        const procurementType: string = procVendor.procurementType.name;
        const stage: string = procVendor.workflowModelPrcs?.name;

        const procurement = {
          id: procVendor.id,
          name: procVendor.name,
          stage: procVendor.workflowModelPrcs?.name,
          date: procVendor.date,
          procurementType: {
            name: procVendor.procurementType.name
          }
        };

        const vendor = {
          id: this.vendorId
        };

        this.stages = procVendor.workflowModelPrcs?.name;
        this.procurementType = procVendor.procurementType.name;
        this.formGroup.patchValue({
          nameProcurement,
          procurementDate,
          procurementType,
          stage,
          procurement,
          vendor
        });
      });
  }

  public doSaveWanprestasiProcurement(): void {
    this.validate();
    const formGroupValue = this.formGroup.value;

    const memoNumberList: string[] = [];
    this.wanprestasiList.forEach(wanprestasi => {
      memoNumberList.push(wanprestasi.memoNumber);
    });

    const mNumber = new Set(memoNumberList);
    if (
      mNumber.size !== memoNumberList.length ||
      (this.todo !== 'edit' &&
        memoNumberList.length > 0 &&
        memoNumberList[0] === this.formGroup.get('memoNumber').value)
    ) {
      this.global.alertService.showError(
        'wanprestasi.msg.error.memoNumberExist',
        '.main-row-popup'
      );
    } else {
      if (this.formGroup.valid) {
        this.onChange.emit(formGroupValue);
        this.activeModal.dismiss();
      }
    }
  }
}
