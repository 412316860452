import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { DrDetailHistory } from '../../bean/dr-detail-history';
import { PaymentTerm } from '../../bean/payment-term';
import { SowPaymentTermItem } from '../../bean/sow-payment-term-item';
import { StageOfWork } from '../../bean/stage-of-work';
import { TablePluginData } from '../table/interface/table-plugin-data';
import { TableResponseModel } from '../table/model/table-response-model';
import { FileUploader } from '../upload';

@Component({
  templateUrl: './app-popup-deliverable-item-detail.component.html'
})
export class AppPopupDeliverableItemDetailComponent extends BaseComponentComponent {
  @Input() public paymentBillingItem;
  @Input() public sowPaymentTermItem: SowPaymentTermItem;
  @Input() public stageOfWork: StageOfWork;
  @Input() public paymentTerm: PaymentTerm;

  public tableResponseGoods: TableResponseModel<DrDetailHistory>;
  public tableResponseService: TableResponseModel<DrDetailHistory>;

  constructor(public currency: CurrencyPipe) {
    super('app-deliverable-item');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponseGoods = new TableResponseModel(this.moduleCode, [
      {
        field: 'processedByUser.name',
        header: 'table.column.user'
      },
      {
        header: 'table.column.date',
        columnList: [
          {
            field: 'receivedDate',
            header: 'table.column.receivedDate',
            plugins: {
              name: 'date',
              format: 'short-date'
            }
          },
          {
            field: 'processedDate',
            header: 'table.column.processedDate',
            plugins: {
              name: 'date',
              format: 'short-date'
            }
          }
        ]
      },
      {
        header: 'table.column.total',
        columnList: [
          {
            field: 'receivedQuantity',
            className: 'text-right',
            header: 'table.column.received',
            plugins: {
              name: 'custom-plugin',
              before: (tablePlugin: TablePluginData): string => {
                return (
                  this.global.converterService.convertDecimal(
                    tablePlugin.value
                  ) +
                  ' ' +
                  tablePlugin?.row?.record?.drDetail?.sowPaymentTermItem
                    ?.prItemReleased?.prItem?.item?.uom?.name
                );
              }
            }
          },
          {
            field: 'passQuantity',
            className: 'text-right',
            header: 'table.column.pass',
            plugins: {
              name: 'custom-plugin',
              before: (tablePlugin: TablePluginData): string => {
                return (
                  this.global.converterService.convertDecimal(
                    tablePlugin.value
                  ) +
                  ' ' +
                  tablePlugin?.row?.record?.drDetail?.sowPaymentTermItem
                    ?.prItemReleased?.prItem?.item?.uom?.name
                );
              }
            }
          },
          {
            field: 'rejectQuantity',
            className: 'text-right',
            header: 'table.column.reject',
            plugins: {
              name: 'custom-plugin',
              before: (tablePlugin: TablePluginData): string => {
                return (
                  this.global.converterService.convertDecimal(
                    tablePlugin.value
                  ) +
                  ' ' +
                  tablePlugin?.row?.record?.drDetail?.sowPaymentTermItem
                    ?.prItemReleased?.prItem?.item?.uom?.name
                );
              }
            }
          }
        ]
      },
      {
        field: 'performanceValue',
        header: 'table.column.deliveryPerformance',
        plugins: {
          name: 'rating',
          isView: true
        }
      },
      {
        field: 'drShipping.address.addressDetail',
        header: 'table.column.address'
      },
      {
        field: 'note',
        header: 'table.column.note'
      },
      {
        field: 'fileList',
        header: 'table.column.attachment',
        plugins: [
          {
            name: 'upload',
            fileUploader: new FileUploader(
              '/app-deliverable-item/',
              'app-deliverable-item.form.file',
              this.global.appConstant.fileType.DOC_DELIVERY_ITEM,
              false,
              this.global.config.parameterModel.maxFileDr
            ),
            isView: true
          }
        ]
      }
    ]);

    this.tableResponseService = new TableResponseModel(this.moduleCode, [
      {
        field: 'processedByUser.name',
        header: 'table.column.user'
      },
      {
        header: 'table.column.date',
        columnList: [
          {
            field: 'receivedDate',
            header: 'table.column.receivedDate',
            plugins: {
              name: 'date',
              format: 'short-date'
            }
          },
          {
            field: 'processedDate',
            header: 'table.column.processedDate',
            plugins: {
              name: 'date',
              format: 'short-date'
            }
          }
        ]
      },
      {
        field: 'passPercentageValue',
        className: 'text-right',
        header: 'table.column.totalReceived',
        plugins: {
          name: 'custom-plugin',
          before: (tablePlugin: TablePluginData): string => {
            return (
              this.global.converterService.convertDecimal(tablePlugin.value) +
              ' %'
            );
          }
        }
      },
      {
        field: 'performanceValue',
        header: 'table.column.deliveryPerformance',
        plugins: {
          name: 'rating',
          isView: true
        }
      },
      {
        field: 'drShipping.address.addressDetail',
        header: 'table.column.address'
      },
      {
        field: 'note',
        header: 'table.column.note'
      },
      {
        field: 'fileList',
        header: 'table.column.attachment',
        plugins: [
          {
            name: 'upload',
            fileUploader: new FileUploader(
              '/app-deliverable-item/',
              'app-deliverable-item.form.file',
              this.global.appConstant.fileType.DOC_DELIVERY_ITEM,
              false,
              this.global.config.parameterModel.maxFileDr
            ),
            isView: true
          }
        ]
      }
    ]);

    this.tableResponseGoods.setCustomData({ id: this.sowPaymentTermItem.id });
    this.tableResponseService.setCustomData({ id: this.sowPaymentTermItem.id });
  }
}
