import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { DocGroup } from '../../../bean/doc-group';
import { TableResponseModel } from '../../app-table/model/table-response-model';
@Component({
  templateUrl: './app-popup-doc-group.component.html'
})
export class AppPopupDocGroupComponent extends BaseComponentComponent {
  @Input() customData: any;
  @Input() urlString: string;
  @Input() dataMap: Map<string, Object> = new Map<string, Object>();
  @Input() filter: string;
  @Output() onChange: EventEmitter<DocGroup> = new EventEmitter();

  public tableResponse: TableResponseModel<DocGroup>;

  constructor() {
    super('app-popup-doc-group');
  }

  onInit(): void {
    this.setCustomData();
    this.buildTableResponse();
  }

  setCustomData(): void {
    this.customData = JSON.stringify(this.filter);
    if (this.dataMap !== null) {
      this.customData = JSON.stringify(this.dataMap);
    }
  }

  buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.column.code' },
      { field: 'translationKey.key', header: 'table.column.translationKey' }
    ]);
  }

  doChoose(docGroupChoose: DocGroup): void {
    this.onChange.emit(docGroupChoose);
  }
}
