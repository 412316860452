import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Organization } from '../../../bean/organization';
import { AppPopupService } from '../app-popup.service';
import { AppPopupOfficerComponent } from './app-popup-officer.component';
@Injectable()
export class AppPopupOfficerService {
  constructor(public appPopupService: AppPopupService) {}
  public open(officerList?: any, organization?: Organization): Observable<any> {
    return this.appPopupService.open(
      AppPopupOfficerComponent,
      { officerList, organization },
      { size: 'lg', windowClass: 'modal-custom' }
    );
  }
}
