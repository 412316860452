import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppTextAreaComponent } from './app-text-area.component';
@NgModule({
  imports: [VendorModule, AppTooltipWrapperModule],
  declarations: [AppTextAreaComponent],
  entryComponents: [AppTextAreaComponent],
  exports: [AppTextAreaComponent]
})
export class AppTextAreaModule {}
