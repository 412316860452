<div class="profile-content">
  <div
    class="profile-image"
    [appDropDown]="profileDropDown"
    [isFixed]="true"
    [isShowArrow]="true"
  >
    <img
      *ngIf="global.userSession?.user.imgFile !== null"
      width="100"
      [attr.src]="
        global.userImageProfile('user', global.userSession?.user)
          | resolveImgSrc
          | async
      "
      class="user-img rounded-circle"
      alt="Profile Picture"
      height="auto"
      [disabled]="true"
    />
    <img
      *ngIf="global.userSession?.user.imgFile === null"
      src="{{ global.userImageProfile('user', global.userSession?.user) }}"
      class="user-img"
      alt="Profile Picture"
      [disabled]="true"
    />
  </div>
  <div class="profile-info">
    <span class="name" maxLine [attr.title]="global.userSession?.user.name">
      {{ global.userSession?.user.name }}
    </span>
    <div
      class="button-role"
      [appDropDown]="roleDropDown"
      [isFixed]="true"
      [disabled]="
        global.userSession?.user.userRoleList &&
        global.userSession?.user.userRoleList.length === 1
      "
    >
      <span class="button-text" maxLine>
        {{ global.userSession?.activeUserRole.role.name }}
      </span>
      <em
        class="fas fa-chevron-down"
        *ngIf="
          global.userSession?.user.userRoleList &&
          global.userSession?.user.userRoleList.length > 1
        "
      ></em>
    </div>
  </div>
</div>

<ng-template #profileDropDown>
  <ul class="profile-drop-down">
    <li class="drop-down-item">
      <p class="item-link" (click)="goToAccountSetting($event)">{{
        'app.profile.accountSetting' | translate
      }}</p>
    </li>
    <li class="drop-down-item">
      <p class="item-link" (click)="doLogOut()">{{
        'app.profile.logOut' | translate
      }}</p>
    </li>
  </ul>
</ng-template>

<ng-template #roleDropDown>
  <ul class="profile-drop-down">
    <li
      class="drop-down-item"
      *ngFor="let userRole of global.userSession?.user.userRoleList"
    >
      <p
        class="item-link"
        [ngClass]="
          userRole.role.code === global.userSession?.activeUserRole.role.code
            ? 'active'
            : ''
        "
        (click)="doSelectRole(userRole.role.code)"
      >
        {{ userRole.role.name }}
      </p>
    </li>
  </ul>
</ng-template>
