<app-popup header="user.changeEmail">
  <form class="mt-3" [formGroup]="formGroup" id="form-change-email">
    <div class="form-group row text-sm-right">
      <label class="col-sm-4 control-label">Email</label>
      <div class="col-sm-8 text-left">
        {{ userSendVerificationEmailRequest.user && userSendVerificationEmailRequest.user.email }}
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-4 control-label">{{ 'user.form.newEmail' | translate
        }}</label>
      <div class="col-sm-8 text-left">
        <app-text-field autofocus="true" formControlName="email" size="LG"
          maxLength="64"></app-text-field>
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-4 control-label">{{ 'user.form.newEmailConfirm' |
        translate }}</label>
      <div class="col-sm-8 text-left">
        <app-text-field formControlName="confirmEmail" size="LG" maxLength="64">
        </app-text-field>
      </div>
    </div>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-right">
      <app-button color="SECONDARY" (onClick)="activeModal.close(false)">{{
        (hasSent ? 'app.button.back' : 'app.button.cancel') | translate }}
      </app-button>
      <app-button (onClick)="doSubmit()" *ngIf="!hasSent">{{ 'app.button.submit'
        | translate }}</app-button>
    </div>
  </ng-template>
</app-popup>