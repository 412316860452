import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Contract } from '../../bean/contract';
import { ContractPreparation } from '../../bean/contract-preparation';
import { AppPopupService } from '../app-popup/app-popup.service';
import { AppPopupPrintContractRequestInfoComponent } from './app-popup-print-contract-request-info.component';

@Component({
  selector: 'app-contract-request-info',
  templateUrl: './app-contract-request-info.component.html'
})
export class AppContractRequestInfoComponent extends BaseComponentComponent {
  @Input() public contractId: number;
  @Input() public isShowDetail = true;
  @Input() public isShowPrintBtn: boolean;
  public contract: Contract;
  public contractPreparation: ContractPreparation;

  constructor(public appPopupService: AppPopupService) {
    super('app-contract-request-info');
  }

  public onInit(): void {
    this.getAndsetContractRequestInfoData();
  }

  public getAndsetContractRequestInfoData(): void {
    this.httpClientService
      .get<Contract[]>('/app-contract-request-info/getData/' + this.contractId)
      .subscribe(response => {
        this.contract = response;
        this.contractPreparation = response.contractPreparation;
        this.setStateReady();
      });
  }

  public viewDetail(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('objectId', this.contractPreparation.objectId);
    this.global.routerParams.set('todo', 'view');
    localStorage.setItem(
      'objectId',
      this.contractPreparation.objectId.toString()
    );
    localStorage.setItem('todo', 'view');
    localStorage.setItem('urlBackOutside', 'close-window');
    localStorage.setItem('isShowBadgeStatus', false.toString());
    if (this.contractPreparation.source === 'ORDER') {
      this.global.routerParams.set(
        'orderId',
        this.contractPreparation.objectId
      );
      localStorage.setItem(
        'orderId',
        this.contractPreparation.objectId.toString()
      );
    } else if (this.contractPreparation.source === 'RFP') {
      this.global.routerParams.set('rfpId', this.contractPreparation.objectId);
      localStorage.setItem(
        'rfpId',
        this.contractPreparation.objectId.toString()
      );
    } else if (this.contractPreparation.source === 'RFQ') {
      this.global.routerParams.set('rfqId', this.contractPreparation.objectId);
      localStorage.setItem(
        'rfqId',
        this.contractPreparation.objectId.toString()
      );
    } else if (this.contractPreparation.source === 'PROCUREMENT') {
      this.global.routerParams.set(
        'procurementId',
        this.contractPreparation.objectId
      );
      localStorage.setItem(
        'procurementId',
        this.contractPreparation.objectId.toString()
      );
    }

    if (this.contractPreparation.module.code === 'ORDER') {
      window.open(
        '/pages/' + this.contractPreparation.module.code.toLowerCase() + '/edit'
      );
    } else if (this.contractPreparation.source === 'PROCUREMENT') {
      window.open('/pages/procurement-history/edit');
    } else {
      window.open(
        '/pages/' +
          this.contractPreparation.module.code.toLowerCase() +
          '/detail'
      );
    }
  }

  public doPrint(): void {
    this.appPopupService.open(AppPopupPrintContractRequestInfoComponent, {
      contractId: this.contractId
    });
  }
}
