import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppFinesModule } from 'src/app/core/components/app-fines/app-fines.module';
import { AppGuaranteeModule } from 'src/app/core/components/app-guarantee/app-guarantee.module';
import { AppOfficialReportModule } from 'src/app/core/components/app-official-report/app-official-report.module';
import { AppItemRequestReleasedModule } from '../../core/components/app-item-request-released/app-item-request-released.module';
import { AppItemRequestModule } from '../../core/components/app-item-request/app-item-request.module';
import { AppSowModule } from '../../core/components/app-sow/app-sow.module';
import { DirectivesModule } from '../../core/directives/directives.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupOrderResponseViewItemComponent } from './app-popup-order-response-view-item.component';
import { OrderResponseEditComponent } from './order-response-edit.component';
import { OrderResponseComponent } from './order-response.component';
import { AppSowXModule } from 'src/app/core/components/app-sow-x/app-sow-x.module';

const routes: Routes = [
  {
    path: '',
    component: OrderResponseComponent,
    data: {
      breadcrumb: ''
    }
  },
  {
    path: 'detail',
    component: OrderResponseEditComponent,
    data: {
      breadcrumb: 'order-response.breadcrumb.detail'
    },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: OrderResponseEditComponent,
    data: {
      breadcrumb: 'order-response.breadcrumb.detail'
    },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppItemRequestModule,
    DirectivesModule,
    AppSowModule,
    AppItemRequestReleasedModule,
    AppFinesModule,
    AppGuaranteeModule,
    AppOfficialReportModule,
    AppSowXModule
  ],
  declarations: [
    OrderResponseComponent,
    OrderResponseEditComponent,
    AppPopupOrderResponseViewItemComponent
  ],
  providers: [],
  entryComponents: [AppPopupOrderResponseViewItemComponent],
  exports: [AppPopupOrderResponseViewItemComponent]
})
export class OrderResponseModule {}
