import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { PriceEvaluation } from 'src/app/core/bean/price-evaluation';
import { PriceEvaluationMethod } from 'src/app/core/bean/price-evaluation-method';
import { Procurement } from 'src/app/core/bean/procurement';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { AppPopupFinalPriceDetailService } from 'src/app/core/components/app-popup/app-popup-final-price-detail/app-popup-final-price-detail.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import {
  TableHeaderPluginData,
  TablePluginData
} from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { ModalService } from 'src/app/core/services/modal.service';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { Validators } from 'src/app/core/validators';
import { VendorQuotationByItemView } from 'src/app/core/view/entity/bean/vendor-quotation-by-item-view';
import { VendorQuotationByVendorView } from 'src/app/core/view/entity/bean/vendor-quotation-by-vendor-view';
import { VendorQuotationView } from 'src/app/core/view/entity/bean/vendor-quotation-view';
import { ProcurementAuction } from '../../core/bean/procurement-auction';
import { QuotationEvaluation } from '../../core/bean/quotation-evaluation';
import { AppPopupBidPriceComponent } from './app-popup-bid-price.component';
import { AppPopupCreateAuctionComponent } from './app-popup-create-auction.component';
import { AppPopupOepComponent } from './app-popup-oep.component';
import { AppPopupPriceEvaluationComponent } from './app-popup-price-evaluation.component';
import { AppPopupSuccessfulyCreateComponent } from './app-popup-successfuly-create.component';
import { PriceEvaluationContinueRequest } from './price-evaluation-continue.request';
import { PriceEvaluationUpdateRequest } from './price-evaluation-update.request';
import { PriceEvaluationResponse } from './price-evaluation.response';

@Component({
  templateUrl: './price-evaluation-edit.component.html',
  styleUrls: ['./price-evaluation-edit.component.scss']
})
export class PriceEvaluationEditComponent extends BaseModuleComponent {
  public procurement: Procurement;
  public procurementAuction: ProcurementAuction;
  public priceEvaluationResponse: PriceEvaluationResponse;
  public tableResponsePriceEval: TableResponseModel<VendorQuotationView>;
  public tableResponseFinalPriceEval: TableResponseModel<VendorQuotationView>;
  public tableResponseQuotationItem: TableResponseModel<VendorQuotationByItemView>;
  public tableResponseFinalQuotationItem: TableResponseModel<VendorQuotationByItemView>;
  public tableResponseQuotationVendor: TableResponseModel<VendorQuotationByVendorView>;
  public tableResponseFinalQuotationVendor: TableResponseModel<VendorQuotationByVendorView>;
  public priceEvaluationMethod: PriceEvaluationMethod;
  public appOfficialReportModel: AppOfficialReportModel;

  public urlBackOutside: string;
  public procurementId: number;
  public approvalModelPrcsId: number;
  public procurementWorklistStatusCode: string;
  public isMethodSelected = false;
  public isContinue = false;
  public isOfficialReportValid = true;
  public isFailedAssessment: boolean;
  public isCancelation: boolean;
  public isShowBadgeStatus: boolean;

  public badgeColor = {
    DRAFT: 'SECONDARY',
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    ON_PROGRESS: 'PROGRESS',
    DONE: 'SUCCESS',
    NEW: 'INFO',
    HOLD: 'DANGER'
  };

  public evaluationMethodOptionList: OptionListModel<PriceEvaluationMethod> =
    new OptionListModel(true, 'name');

  public constructor(
    translateService: TranslateService,
    public modalService: ModalService,
    public appPopupService: AppPopupService,
    public snackbarService: SnackbarService,
    public appPopupFinalPriceDetailService: AppPopupFinalPriceDetailService,
    public datePipe: DatePipe
  ) {
    super('price-evaluation', translateService);
  }

  public onInit(): void {
    this.getDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public getDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurement = this.global.routerParams.get('procurement');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.procurementId =
      localStorage.getItem('procurementId') ||
      (this.procurement && this.procurement.id) ||
      this.global.routerParams.get('objectId');
    this.procurementWorklistStatusCode = this.global.routerParams.get(
      'procurementWorklistStatusCode'
    );
    this.isContinue = this.todo !== 'add';
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      evaluationMethod: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<PriceEvaluationResponse>('/price-evaluation/edit', {
        id: this.procurementId,
        todo: this.todo
      })
      .subscribe((response: PriceEvaluationResponse) => {
        this.priceEvaluationResponse = response;
        this.procurement = response.procurement;
        this.procurementAuction = response.procurementAuction;
        this.appOfficialReportModel = response.appOfficialReportModel;
        this.buildTableResponse();
        this.priceEvaluationMethod = response.procurement.priceEvaluationMethod
          ? response.procurement.priceEvaluationMethod
          : response.procurement.fromProcurement
          ? response.procurement.fromProcurement.priceEvaluationMethod
          : response.procurement.priceEvaluationMethod;
        if (this.priceEvaluationMethod) {
          this.evaluationMethodOptionList.setRequestValues(
            response.priceEvaluationMethodList
          );
          if (
            (this.todo !== 'add' && this.todo !== 'edit') ||
            (this.todo === 'edit' &&
              this.priceEvaluationMethod.code !==
                this.global.appConstant.pm
                  .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE &&
              this.priceEvaluationMethod.code !==
                this.global.appConstant.pm
                  .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION &&
              !this.priceEvaluationResponse.isHasFinal)
          ) {
            this.formGroup.setIsView(true);
            const methodList = [];
            methodList.push(this.priceEvaluationMethod);
            this.evaluationMethodOptionList.setRequestValues(methodList);
            this.formGroup.patchValue({
              evaluationMethod: this.priceEvaluationMethod
            });
          } else if (this.procurement.isRepeat) {
            this.formGroup.patchValue({
              evaluationMethod: this.priceEvaluationMethod
            });
          } else if (
            this.procurementWorklistStatusCode ===
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION
          ) {
            this.isContinue = true;
            this.formGroup.patchValue({
              evaluationMethod: this.priceEvaluationMethod
            });
          } else if (
            this.procurementWorklistStatusCode ===
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_ON_PROGRESS
          ) {
            this.isContinue = true;
            this.formGroup.patchValue({
              evaluationMethod: this.priceEvaluationMethod
            });
            const methodList = [];
            methodList.push(this.priceEvaluationMethod);
            this.evaluationMethodOptionList.setRequestValues(methodList);
            this.formGroup.setIsView(true);
          } else {
            this.isContinue = true;
            this.formGroup.patchValue({
              evaluationMethod: this.priceEvaluationMethod
            });
          }
        } else {
          if (
            this.procurementWorklistStatusCode ===
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION
          ) {
            this.isContinue = false;
          }
          this.evaluationMethodOptionList.setRequestValues(
            response.priceEvaluationMethodList
          );
        }

        if (
          this.procurement.procurementStatus?.code ===
            this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT ||
          this.procurement.procurementStatus?.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL
        ) {
          this.isFailedAssessment = true;
          this.formGroup.setIsView(true);
        } else if (
          this.procurement.procurementStatus?.code ===
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
        ) {
          this.isCancelation = true;
          this.formGroup.setIsView(true);
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponsePriceEval = new TableResponseModel(
      this.moduleCode,
      this.procurement.biddingEvaluation.code !==
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
        ? [
            {
              field: 'vendorName',
              header: 'table.column.vendorName',
              plugins: {
                name: 'hyperlink',
                onClick: this.doViewQuotation.bind(this)
              }
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScoreStatus.name',
                  header: 'table.column.technical',
                  plugins: {
                    name: 'badge',
                    colorField: 'technicalScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                this.procurement.biddingEvaluation.code ===
                this.global.appConstant.pm
                  .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
                  ? {
                      field: 'priceScoreStatus.name',
                      header: 'table.column.price',
                      plugins: {
                        name: 'badge',
                        colorField: 'priceScoreStatus.code',
                        colorMap: {
                          PASSED: 'SUCCESS',
                          NOT_PASSED: 'DANGER'
                        }
                      }
                    }
                  : {
                      field: 'priceScore',
                      header: 'table.column.price',
                      className: 'text-right',
                      plugins: [
                        {
                          name: 'text-field',
                          type: 'decimal',
                          isView: true
                        },
                        {
                          name: 'custom-plugin',
                          after: (tablePlugin: TablePluginData): void => {
                            console.log(tablePlugin.value);
                            if (!tablePlugin.value) {
                              tablePlugin.element.innerText = '0';
                            }
                          }
                        }
                      ]
                    }
              ]
            },
            {
              field: 'bidPrice',
              header: 'table.column.bidPrice',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: 'text-right'
            },
            {
              field: 'profitLossAmount',
              header: 'table.column.potensialSavingLoss',
              className: 'text-right',
              plugins: [
                {
                  name: 'custom-plugin',
                  before: (tablePlugin: TablePluginData): void => {
                    if (tablePlugin.value) {
                      tablePlugin.element.innerText =
                        this.global.converterService.convertMoney(
                          tablePlugin.value
                        ) + ' %';
                    }
                  }
                },
                {
                  name: 'badge',
                  field: 'profitLossStatus.name',
                  colorMap: {
                    SAVE: 'SUCCESS',
                    LOSS: 'DANGER'
                  },
                  pill: false,
                  colorField: 'profitLossStatus.code',
                  className: 'badge-catalog ml-3'
                }
              ]
            }
          ]
        : [
            {
              field: 'vendorName',
              header: 'table.column.vendorName',
              plugins: {
                name: 'hyperlink',
                onClick: this.doViewQuotation.bind(this)
              }
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScore',
                  header: {
                    key: 'table.column.technical',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' +
                            (this.priceEvaluationResponse.technicalPercentage ||
                              ' - ') +
                            '%)';
                          tableHeaderPlugin.element.className = 'text-right';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: [
                    {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    },
                    {
                      name: 'custom-plugin',
                      after: (tablePlugin: TablePluginData): void => {
                        console.log(tablePlugin.value);
                        if (!tablePlugin.value) {
                          tablePlugin.element.innerText = '0';
                        }
                      }
                    }
                  ]
                },
                {
                  field: 'priceScore',
                  header: {
                    key: 'table.column.price',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' +
                            (this.priceEvaluationResponse.pricePercentage ||
                              ' - ') +
                            '%)';
                          tableHeaderPlugin.element.className = 'text-right';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: [
                    {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    },
                    {
                      name: 'custom-plugin',
                      after: (tablePlugin: TablePluginData): void => {
                        console.log(tablePlugin.value);
                        if (!tablePlugin.value) {
                          tablePlugin.element.innerText = '0';
                        }
                      }
                    }
                  ]
                },
                {
                  field: 'totalEvaluationScore',
                  header: 'table.column.total',
                  className: 'text-right',
                  plugins: [
                    {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    },
                    {
                      name: 'custom-plugin',
                      after: (tablePlugin: TablePluginData): void => {
                        console.log(tablePlugin.value);
                        if (!tablePlugin.value) {
                          tablePlugin.element.innerText = '0';
                        }
                      }
                    }
                  ]
                }
              ]
            },
            {
              field: 'bidPrice',
              header: 'table.column.bidPrice',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: 'text-right'
            },
            {
              field: 'profitLossAmount',
              header: 'table.column.potensialSavingLoss',
              className: 'text-right',
              plugins: [
                {
                  name: 'custom-plugin',
                  before: (tablePlugin: TablePluginData): void => {
                    if (tablePlugin.value) {
                      tablePlugin.element.innerText =
                        this.global.converterService.convertMoney(
                          tablePlugin.value
                        ) + ' %';
                    }
                  }
                },
                {
                  name: 'badge',
                  field: 'profitLossStatus.name',
                  colorMap: {
                    SAVE: 'SUCCESS',
                    LOSS: 'DANGER'
                  },
                  pill: false,
                  colorField: 'profitLossStatus.code',
                  className: 'badge-catalog ml-3'
                }
              ]
            }
          ]
    );

    this.tableResponsePriceEval.setCustomData({
      procurementId: this.procurementId
    });

    this.tableResponseFinalPriceEval = new TableResponseModel(
      this.moduleCode,
      this.procurement.biddingEvaluation.code !==
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
        ? [
            {
              field: 'vendorName',
              header: 'table.column.vendorName',
              plugins: {
                name: 'hyperlink',
                onClick: this.doViewFinalQuotation.bind(this)
              }
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScoreStatus.name',
                  header: 'table.column.technical',
                  plugins: {
                    name: 'badge',
                    colorField: 'technicalScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                this.procurement.biddingEvaluation.code ===
                this.global.appConstant.pm
                  .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
                  ? {
                      field: 'finalPriceScoreStatus.name',
                      header: 'table.column.price',
                      plugins: {
                        name: 'badge',
                        colorField: 'finalPriceScoreStatus.code',
                        colorMap: {
                          PASSED: 'SUCCESS',
                          NOT_PASSED: 'DANGER'
                        }
                      }
                    }
                  : {
                      field: 'finalPriceScore',
                      header: 'table.column.price',
                      className: 'text-right',
                      plugins: [
                        {
                          name: 'text-field',
                          type: 'decimal',
                          isView: true
                        },
                        {
                          name: 'custom-plugin',
                          after: (tablePlugin: TablePluginData): void => {
                            console.log(tablePlugin.value);
                            if (!tablePlugin.value) {
                              tablePlugin.element.innerText = '0';
                            }
                          }
                        }
                      ]
                    }
              ]
            },
            {
              field: 'finalPrice',
              header: 'table.column.finalPrice',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: 'text-right'
            },
            {
              field: 'finalProfitLossAmount',
              header: 'table.column.savingLoss',
              className: 'text-right',
              plugins: [
                {
                  name: 'custom-plugin',
                  before: (tablePlugin: TablePluginData): void => {
                    if (tablePlugin.value) {
                      tablePlugin.element.innerText =
                        this.global.converterService.convertMoney(
                          tablePlugin.value
                        ) + ' %';
                    }
                  }
                },
                {
                  name: 'badge',
                  field: 'finalProfitLossStatus.name',
                  colorMap: {
                    SAVE: 'SUCCESS',
                    LOSS: 'DANGER'
                  },
                  pill: false,
                  colorField: 'finalProfitLossStatus.code',
                  className: 'badge-catalog ml-3'
                }
              ]
            }
          ]
        : [
            {
              field: 'vendorName',
              header: 'table.column.vendorName',
              plugins: {
                name: 'hyperlink',
                onClick: this.doViewFinalQuotation.bind(this)
              }
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScore',
                  header: {
                    key: 'table.column.technical',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' +
                            (this.priceEvaluationResponse.technicalPercentage ||
                              ' - ') +
                            '%)';
                          tableHeaderPlugin.element.className = 'text-right';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: [
                    {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    },
                    {
                      name: 'custom-plugin',
                      after: (tablePlugin: TablePluginData): void => {
                        console.log(tablePlugin.value);
                        if (!tablePlugin.value) {
                          tablePlugin.element.innerText = '0';
                        }
                      }
                    }
                  ]
                },
                {
                  field: 'finalPriceScore',
                  header: {
                    key: 'table.column.price',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' +
                            (this.priceEvaluationResponse.pricePercentage ||
                              ' - ') +
                            '%)';
                          tableHeaderPlugin.element.className = 'text-right';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: [
                    {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    },
                    {
                      name: 'custom-plugin',
                      after: (tablePlugin: TablePluginData): void => {
                        console.log(tablePlugin.value);
                        if (!tablePlugin.value) {
                          tablePlugin.element.innerText = '0';
                        }
                      }
                    }
                  ]
                },
                {
                  field: 'totalFinalEvaluationScore',
                  header: 'table.column.total',
                  className: 'text-right',
                  plugins: [
                    {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    },
                    {
                      name: 'custom-plugin',
                      after: (tablePlugin: TablePluginData): void => {
                        console.log(tablePlugin.value);
                        if (!tablePlugin.value) {
                          tablePlugin.element.innerText = '0';
                        }
                      }
                    }
                  ]
                }
              ]
            },
            {
              field: 'finalPrice',
              header: 'table.column.finalPrice',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: 'text-right'
            },
            {
              field: 'finalProfitLossAmount',
              header: 'table.column.savingLoss',
              className: 'text-right',
              plugins: [
                {
                  name: 'custom-plugin',
                  before: (tablePlugin: TablePluginData): void => {
                    if (tablePlugin.value) {
                      tablePlugin.element.innerText =
                        this.global.converterService.convertMoney(
                          tablePlugin.value
                        ) + ' %';
                    }
                  }
                },
                {
                  name: 'badge',
                  field: 'finalProfitLossStatus.name',
                  colorMap: {
                    SAVE: 'SUCCESS',
                    LOSS: 'DANGER'
                  },
                  pill: false,
                  colorField: 'finalProfitLossStatus.code',
                  className: 'badge-catalog ml-3'
                }
              ]
            }
          ]
    );

    this.tableResponseFinalPriceEval.setCustomData({
      procurementId: this.procurementId
    });

    if (
      this.procurement.biddingSubmissionType.code ===
      this.global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
    ) {
      this.buildTableQuotationItem();
      this.buildTableQuotationVendor();
    }
  }

  public buildTableQuotationItem(): void {
    this.tableResponseQuotationItem = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementItem.prItem.item.name',
        header: 'table.column.itemName',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClickQuotationItem.bind(this)
        }
      },
      {
        field: 'procurementItem.prItem.pr.code',
        header: 'table.column.prNumber'
      },
      {
        field: 'procurementItem.prItem.code',
        header: 'table.column.prLine'
      },
      {
        field: 'procurementItem.prItem.item.code',
        header: 'table.column.itemCode'
      },
      {
        field: 'procurementItem.prItem.item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'procurementItem.prItem.item.itemType.code'
        }
      },
      {
        field: 'procurementItem.prItem.quantity',
        header: 'table.column.quantity',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'procurementItem.prItem.item.uom.name',
        header: 'table.column.unit'
      },
      {
        field: 'vendorReference',
        header: 'table.column.vendorReference',
        className: 'text-right'
      }
    ]);

    this.tableResponseQuotationItem.setCustomData({
      procurementId: this.procurementId
    });

    this.tableResponseFinalQuotationItem = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'procurementItem.prItem.item.name',
          header: 'table.column.itemName',
          plugins: {
            name: 'hyperlink',
            onClick: this.doClickFinalQuotationItem.bind(this)
          }
        },
        {
          field: 'procurementItem.prItem.pr.code',
          header: 'table.column.prNumber'
        },
        {
          field: 'procurementItem.prItem.code',
          header: 'table.column.prLine'
        },
        {
          field: 'procurementItem.prItem.item.code',
          header: 'table.column.itemCode'
        },
        {
          field: 'procurementItem.prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'procurementItem.prItem.item.itemType.code'
          }
        },
        {
          field: 'procurementItem.prItem.quantity',
          header: 'table.column.quantity',
          className: 'text-right',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            isView: true
          }
        },
        {
          field: 'procurementItem.prItem.item.uom.name',
          header: 'table.column.unit'
        },
        {
          field: 'vendorReference',
          header: 'table.column.vendorReference',
          className: 'text-right'
        }
      ]
    );

    this.tableResponseFinalQuotationItem.setCustomData({
      procurementId: this.procurementId
    });
  }

  public buildTableQuotationVendor(): void {
    this.tableResponseQuotationVendor = new TableResponseModel(
      this.moduleCode,
      this.procurement.biddingEvaluation.code !==
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
        ? [
            {
              field: 'quotation.procurementVendor.vendor.name',
              header: 'table.column.vendorName',
              plugins: {
                name: 'hyperlink',
                onClick: this.doClickQuotationVendor.bind(this)
              }
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScoreStatus.name',
                  header: 'table.column.technical',
                  plugins: {
                    name: 'badge',
                    colorField: 'technicalScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                }
              ]
            },
            {
              field: 'selectedItem',
              header: 'table.column.selectedItem'
            }
          ]
        : [
            {
              field: 'quotation.procurementVendor.vendor.name',
              header: 'table.column.vendorName',
              plugins: {
                name: 'hyperlink',
                onClick: this.doClickQuotationVendor.bind(this)
              }
            },
            {
              field: 'selectedItem',
              header: 'table.column.selectedItem'
            }
          ]
    );

    this.tableResponseQuotationVendor.setCustomData({
      procurementId: this.procurementId
    });

    this.tableResponseFinalQuotationVendor = new TableResponseModel(
      this.moduleCode,
      this.procurement.biddingEvaluation.code !==
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
        ? [
            {
              field: 'quotation.procurementVendor.vendor.name',
              header: 'table.column.vendorName',
              plugins: {
                name: 'hyperlink',
                onClick: this.doClickFinalQuotationVendor.bind(this)
              }
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScoreStatus.name',
                  header: 'table.column.technical',
                  plugins: {
                    name: 'badge',
                    colorField: 'technicalScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                }
              ]
            },
            {
              field: 'selectedItem',
              header: 'table.column.selectedItem'
            }
          ]
        : [
            {
              field: 'quotation.procurementVendor.vendor.name',
              header: 'table.column.vendorName',
              plugins: {
                name: 'hyperlink',
                onClick: this.doClickFinalQuotationVendor.bind(this)
              }
            },
            {
              field: 'selectedItem',
              header: 'table.column.selectedItem'
            }
          ]
    );

    this.tableResponseFinalQuotationVendor.setCustomData({
      procurementId: this.procurementId
    });
  }

  public doViewQuotation(vendorQuotationView: VendorQuotationView): void {
    this.appPopupService.open(
      AppPopupBidPriceComponent,
      {
        quotationId: vendorQuotationView.id,
        vendorName: vendorQuotationView.vendorName
      },
      { size: 'xl' }
    );
  }

  public doViewFinalQuotation(vendorQuotationView: VendorQuotationView): void {
    this.appPopupFinalPriceDetailService.open(
      vendorQuotationView.procurementVendor.id,
      this.procurement.biddingEvaluation,
      this.procurement.vendorSelectionType,
      this.moduleCode
    );
  }

  public doClickQuotationItem(
    vendorQuotationByItemView: VendorQuotationByItemView
  ): void {
    this.appPopupService.open(
      AppPopupPriceEvaluationComponent,
      {
        procurementItem: vendorQuotationByItemView.procurementItem,
        procurementId: vendorQuotationByItemView.procurementItem.procurement.id,
        isHasOep: this.priceEvaluationResponse.isHasOep,
        isFinal: false
      },
      { size: 'xl' }
    );
  }

  public doClickFinalQuotationItem(
    vendorQuotationByItemView: VendorQuotationByItemView
  ): void {
    this.appPopupService.open(
      AppPopupPriceEvaluationComponent,
      {
        procurementItem: vendorQuotationByItemView.procurementItem,
        procurementId: vendorQuotationByItemView.procurementItem.procurement.id,
        isFinal: true,
        isHasOep: this.priceEvaluationResponse.isHasOep
      },
      { size: 'xl' }
    );
  }

  public doClickQuotationVendor(
    vendorQuotationByVendorView: VendorQuotationByVendorView
  ): void {
    this.appPopupService.open(
      AppPopupBidPriceComponent,
      {
        quotationId: vendorQuotationByVendorView.quotation.id,
        vendorName:
          vendorQuotationByVendorView.quotation.procurementVendor.vendor.name
      },
      { size: 'xl' }
    );
  }

  public doClickFinalQuotationVendor(
    vendorQuotationByVendorView: VendorQuotationByVendorView
  ): void {
    this.appPopupFinalPriceDetailService.open(
      vendorQuotationByVendorView.quotation.procurementVendor.id,
      this.procurement.biddingEvaluation,
      this.procurement.vendorSelectionType,
      this.moduleCode
    );
  }

  public doViewEstimationPrice(): void {
    this.appPopupService.open(
      AppPopupOepComponent,
      {
        procurementId: this.procurementId
      },
      { size: 'xl' }
    );
  }

  public doChangeMethod(priceEvaluationMethod: PriceEvaluationMethod): void {
    this.priceEvaluationMethod = priceEvaluationMethod;
    if (
      priceEvaluationMethod &&
      (priceEvaluationMethod.code ===
        this.global.appConstant.pm
          .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE ||
        priceEvaluationMethod.code ===
          this.global.appConstant.pm
            .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION)
    ) {
      this.isContinue = true;
    } else if (
      priceEvaluationMethod &&
      priceEvaluationMethod.code ===
        this.global.appConstant.pm.PRICE_EVALUATION_METHOD_AUCTION
    ) {
      this.isContinue = false;
    } else if (
      priceEvaluationMethod &&
      (priceEvaluationMethod.code ===
        this.global.appConstant.pm.PRICE_EVALUATION_METHOD_NEGOTIATION ||
        priceEvaluationMethod.code ===
          this.global.appConstant.pm
            .PRICE_EVALUATION_METHOD_NEGOTIATION_AUCTION)
    ) {
      this.isContinue = false;
    }
  }

  public doContinue(): void {
    this.priceEvaluationMethod = this.formGroup.value.evaluationMethod;
    if (
      this.priceEvaluationMethod &&
      this.priceEvaluationMethod.code ===
        this.global.appConstant.pm.PRICE_EVALUATION_METHOD_AUCTION
    ) {
      const request = new PriceEvaluationContinueRequest();
      request.procurement = this.procurement;
      request.priceEvaluationMethod = this.priceEvaluationMethod;
      this.appPopupService.open(
        AppPopupCreateAuctionComponent,
        {
          request,
          procurementWorklistStatusCode: this.procurementWorklistStatusCode
        },
        { size: 'md' }
      );
    } else if (
      this.priceEvaluationMethod &&
      (this.priceEvaluationMethod.code ===
        this.global.appConstant.pm.PRICE_EVALUATION_METHOD_NEGOTIATION ||
        this.priceEvaluationMethod.code ===
          this.global.appConstant.pm
            .PRICE_EVALUATION_METHOD_NEGOTIATION_AUCTION)
    ) {
      const prompt = 'price-evaluation.confirmation.negotiation.prompt';
      const title = 'price-evaluation.confirmation.negotiation.title';
      this.modalService
        .confirmation(
          prompt,
          title,
          'price-evaluation.button.cancel',
          'price-evaluation.button.yesImSure',
          'pvc pv-user-decision',
          false
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const request = new PriceEvaluationContinueRequest();
            request.procurement = this.procurement;
            request.priceEvaluationMethod = this.priceEvaluationMethod;
            this.setStateProcessing();
            this.httpClientService
              .post<Response<Procurement>>(
                '/price-evaluation/continue',
                request
              )
              .subscribe(response => {
                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  const prompt =
                    'price-evaluation.success.createNegotiation.prompt';
                  const title =
                    'price-evaluation.success.createNegotiation.title';
                  this.appPopupService.open(
                    AppPopupSuccessfulyCreateComponent,
                    {
                      procurement: this.procurement,
                      procurementWorklistStatusCode:
                        this.procurementWorklistStatusCode,
                      title,
                      prompt
                    },
                    { size: 'md' }
                  );
                }
              });
          }
        });
    }
  }

  public doViewDetail(): void {
    window.location.href =
      this.global.config.parameterModel.promiseAuctionAddressUrl +
      '/initiation/form/' +
      this.procurementAuction.promiseAuctionCode;
  }

  public doSave(): void {
    this.setStateProcessing();
    this.doOnChange(this.appOfficialReportModel);
    const request = new PriceEvaluationUpdateRequest();
    request.procurement = this.procurement;
    request.priceEvaluationMethod = this.priceEvaluationMethod;
    request.appOfficialReportModel = this.appOfficialReportModel;
    request.isProcurementFailed =
      this.priceEvaluationResponse.isProcurementFailed;
    request.isSubmit = false;
    this.httpClientService
      .post<Response<PriceEvaluation>>('/price-evaluation/update', request)
      .subscribe(response => {
        this.setStateReady();
        if (response.status === ResponseStatusModel.OK) {
          this.snackbarService.showWarning('app.msg.info.sucsessSave');
          this.router
            .navigateByUrl('/', {
              skipLocationChange: true
            })
            .then(() => {
              this.global.routerParams.clear();
              this.global.routerParams.set('procurement', this.procurement);
              this.global.routerParams.set(
                'urlBackOutside',
                '/pages/worklist-pm'
              );
              this.global.routerParams.set('todo', 'edit');
              this.router.navigate(['/pages/price-evaluation/edit']);
            });
        }
      });
  }

  public doSubmit(): void {
    this.doOnChange(this.appOfficialReportModel);
    if (
      this.procurement.biddingEvaluation.code !==
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
    ) {
      this.checkProcurementFailed(
        this.priceEvaluationResponse.quotationEvaluationList
      );
    }

    if (this.priceEvaluationResponse.isAuctionOnProgress) {
      this.global.modalService.confirmation(
        'price-evaluation.confirmation.finalOnProgress.prompt',
        'price-evaluation.confirmation.finalOnProgress.title',
        null,
        null,
        'pvc pv-exclamation-circle',
        true
      );
    } else if (
      this.priceEvaluationResponse.isProcurementFailed &&
      this.procurement.biddingEvaluation.code !==
        this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
    ) {
      this.global.modalService
        .submitProcurementFailed(
          this.priceEvaluationResponse.procurementParticipant.minimumParticipant
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.doSubmitPriceEval();
          }
        });
    } else {
      if (this.isOfficialReportValid) {
        this.global.modalService
          .submitConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.doSubmitPriceEval();
            }
          });
      }
    }
  }

  public checkProcurementFailed(
    quotationEvaluationList: QuotationEvaluation[]
  ): void {
    let totalPassed = 0;
    if (
      this.priceEvaluationMethod &&
      (this.priceEvaluationMethod.code ===
        this.global.appConstant.pm
          .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE ||
        this.priceEvaluationMethod.code ===
          this.global.appConstant.pm
            .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION)
    ) {
      quotationEvaluationList.forEach(quotationEval => {
        if (
          quotationEval.scoringStatus.code ===
          this.global.appConstant.pm.SCORING_STATUS_PASSED
        ) {
          totalPassed += 1;
        }
      });

      if (
        totalPassed <
        this.priceEvaluationResponse.procurementParticipant.minimumParticipant
      ) {
        this.priceEvaluationResponse.isProcurementFailed = true;
      }
    }
  }

  public doSubmitPriceEval(): void {
    this.setStateProcessing();
    const request = new PriceEvaluationUpdateRequest();
    request.procurement = this.procurement;
    request.priceEvaluationMethod = this.priceEvaluationMethod;
    request.appOfficialReportModel = this.appOfficialReportModel;
    request.isProcurementFailed =
      this.priceEvaluationResponse.isProcurementFailed;
    request.isSubmit = true;
    this.httpClientService
      .post<Response<PriceEvaluation>>('/price-evaluation/update', request)
      .subscribe(response => {
        this.setStateReady();
        if (response.status === ResponseStatusModel.OK) {
          this.global.modalService.submitSuccess().subscribe(result => {
            if (result) {
              this.router.navigate([this.urlBackOutside]);
            } else {
              this.router
                .navigateByUrl('/', {
                  skipLocationChange: true
                })
                .then(() => {
                  this.global.routerParams.clear();
                  this.global.routerParams.set('procurement', this.procurement);
                  this.global.routerParams.set(
                    'urlBackOutside',
                    '/pages/worklist-pm'
                  );
                  this.global.routerParams.set('todo', 'view');
                  this.router.navigate(['/pages/price-evaluation/detail']);
                });
            }
          });
        }
      });
  }

  public translateLabel(code: string): string {
    if (code === this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_NEW) {
      return 'price-evaluation.badge.status.new';
    } else if (
      code ===
      this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_ON_PROGRESS
    ) {
      return 'price-evaluation.badge.status.onProgress';
    } else if (
      code === this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT
    ) {
      return 'price-evaluation.badge.status.draft';
    } else if (
      code === this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION
    ) {
      return 'price-evaluation.badge.status.revision';
    } else if (
      code ===
      this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_WAITING_APPROVAL
    ) {
      return 'price-evaluation.badge.status.waitingApproval';
    } else if (
      code === this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DONE
    ) {
      return 'price-evaluation.badge.status.done';
    } else if (
      code === this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_HOLD
    ) {
      return 'price-evaluation.badge.status.hold';
    }
  }

  public doOnChange(event: AppOfficialReportModel): void {
    let isAnyValue = false;
    event.fileObjectList.forEach(fileObject => {
      if (!fileObject.isDeleted) {
        isAnyValue = true;
      }
    });
    if (
      event.isGenerate &&
      event.letterDate &&
      event.letterNumber &&
      event.fileObjectList &&
      isAnyValue &&
      event.isGenerate
    ) {
      this.isOfficialReportValid = true;
    } else {
      this.isOfficialReportValid = false;
    }

    if (
      new Date(event.letterDate).getTime() !==
        new Date(this.appOfficialReportModel.letterDate).getTime() ||
      event.letterNumber !== this.appOfficialReportModel.letterNumber
    ) {
      this.isOfficialReportValid = false;
    }
    this.appOfficialReportModel = event;
  }
}
