import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BillingTerm } from 'src/app/core/bean/billing-term';
import { Contract } from 'src/app/core/bean/contract';
import { ContractType } from 'src/app/core/bean/contract-type';
import { DealingAgreementType } from 'src/app/core/bean/dealing-agreement-type';
import { PaymentTerm } from 'src/app/core/bean/payment-term';
import { ProcurementResult } from 'src/app/core/bean/procurement-result';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { StageOfWork } from 'src/app/core/bean/stage-of-work';
import { AppPopupService } from '../app-popup.service';
import { AppPopupProcurementScopeWorkComponent } from './app-popup-procurement-scope-work.component';

@Injectable()
export class AppPopupProcurementScopeWorkService {
  constructor(public appPopupService: AppPopupService) {}
  public open(
    todo?: string,
    header?: string,
    procurementSowEdit?: any,
    stageOfWorkList: StageOfWork[] = [],
    paymentTermList: PaymentTerm[] = [],
    billingTermList: BillingTerm[] = [],
    procurementResultServiceList: ProcurementResult[] = [],
    procurementResultProductList: ProcurementResult[] = [],
    contract?: Contract,
    procurementVendor?: ProcurementVendor,
    availableAmountSow?: number,
    procurementPaymentItemList?: any,
    procurementSowList?: any,
    procurementSowPaymentList?: any,
    contractType?: ContractType,
    dealingAgreementType?: DealingAgreementType,
    isFromWorkDefinition?: boolean
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupProcurementScopeWorkComponent,
      {
        todo,
        header,
        procurementSowEdit,
        stageOfWorkList,
        paymentTermList,
        billingTermList,
        procurementResultServiceList,
        procurementResultProductList,
        contract,
        procurementVendor,
        availableAmountSow,
        procurementPaymentItemList,
        procurementSowList,
        procurementSowPaymentList,
        contractType,
        dealingAgreementType,
        isFromWorkDefinition
      },
      { size: 'xl' }
    );
  }
}
