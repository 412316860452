import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppContentSliderModule } from '../app-content-slider/app-content-slider.module';
import { AppWorklistInfoComponent } from './app-worklist-info.component';
@NgModule({
  imports: [VendorModule, DirectivesModule, AppContentSliderModule],
  declarations: [AppWorklistInfoComponent],
  exports: [AppWorklistInfoComponent]
})
export class AppWorklistInfoModule {}
