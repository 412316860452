import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { TemplateGroupComponent } from './template-group.component';
export const routes = [
  {
    path: '',
    component: TemplateGroupComponent,
    data: { breadcrumb: '' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, NgbModule],
  declarations: [TemplateGroupComponent],
  providers: []
})
export class TemplateGroupModule {}
