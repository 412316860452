import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupAdjustmentContractComponent } from './app-popup-adjustment-contract.component';
import { SlaContractDetailComponent } from './sla-contract-detail.component';
import { SlaContractComponent } from './sla-contract.component';

export const routes = [
  { path: '', component: SlaContractComponent, data: { breadcrumb: '' } },
  {
    path: 'detail',
    component: SlaContractDetailComponent,
    data: { breadcrumb: 'sla-contract.breadcrumb.detail' }
  },
  {
    path: 'app-popup-adjustment-contract',
    component: AppPopupAdjustmentContractComponent,
    data: { breadcrumb: 'sla-contract.breadcrumb.adjustment' }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    SlaContractComponent,
    SlaContractDetailComponent,
    AppPopupAdjustmentContractComponent
  ],
  providers: []
})
export class SlaContractModule {}
