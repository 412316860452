import { ChartDataSets, ChartTooltipItem } from 'chart.js';
import { Global } from '../../../global/global';
import { Log } from '../../../services/logger';
import { ServiceLocator } from '../../../services/service-locator';
import { ChartModel } from '../../app-chart/models/chart-model';
import { ActualPlanBarChartInfoModel } from '../model/actual-plan-bar-chart-info-model';
import { ActualPlanBarChartModel } from '../model/actual-plan-bar-chart-model';
export function setBudgetInfo(
  state: ActualPlanBarChartModel,
  payload: any
): ChartModel {
  const log = new Log(this);
  log.debug(state);
  const global: Global = ServiceLocator.injector.get(Global);
  const labels: Array<string> = new Array();
  const dataSets: Array<ChartDataSets> = new Array();

  const budgetTotalPerMonthList: Array<number> = new Array();
  const bookedBudgetPerMonthList: Array<number> = new Array();
  const usedBudgetPerMonthList: Array<number> = new Array();
  const paidOfPerMonthList: Array<number> = new Array();

  const tooltipContentList: Array<{
    month: number;
    value: Array<{ title: string; value: string }>;
  }> = [];
  let tooltipList: Array<{ title: string; value: string }> = [];
  const title = [
    global.translateService.instant(
      'app-actual-plan-bar-chart-budget-info.budgetTotal'
    ),
    global.translateService.instant(
      'app-actual-plan-bar-chart-budget-info.bookedBudget'
    ),
    global.translateService.instant(
      'app-actual-plan-bar-chart-budget-info.usedBudget'
    ),
    global.translateService.instant(
      'app-actual-plan-bar-chart-budget-info.paidOffBudget'
    )
  ];

  payload.actualPlanBarChartInfoModelList.forEach(
    (
      actualPlanBarChartInfoModel: ActualPlanBarChartInfoModel,
      index: number
    ) => {
      const {
        budgetTotalPerMonth,
        bookedBudgetPerMonth,
        usedBudgetPerMonth,
        paidOffBudgetPerMonth
      } = actualPlanBarChartInfoModel;
      labels.push(actualPlanBarChartInfoModel.month);
      budgetTotalPerMonthList.push(budgetTotalPerMonth);
      bookedBudgetPerMonthList.push(bookedBudgetPerMonth);
      usedBudgetPerMonthList.push(usedBudgetPerMonth);
      paidOfPerMonthList.push(paidOffBudgetPerMonth);
      const value = [
        budgetTotalPerMonth,
        bookedBudgetPerMonth,
        usedBudgetPerMonth,
        paidOffBudgetPerMonth
      ];
      tooltipList = [];
      for (let i = 0; i < 4; i++) {
        tooltipList.push({
          title: title[i],
          value: global.converterService
            .convertToShortMoney(value[i])
            .toString()
        });
      }
      tooltipContentList.push({ month: index, value: tooltipList });
    }
  );

  dataSets.push({
    data: budgetTotalPerMonthList
  });
  dataSets.push({
    data: bookedBudgetPerMonthList
  });
  dataSets.push({
    data: usedBudgetPerMonthList
  });
  dataSets.push({
    data: paidOfPerMonthList
  });

  const { planBudgetTotal, paidOffBudget, usedBudget, bookedBudget } = payload;
  const chartModel = new ChartModel(
    'bar',
    labels,
    dataSets,
    [
      global.translateService.instant(
        'app-actual-plan-bar-chart-budget-info.budgetTotal'
      ) +
        ': ' +
        global.converterService.convertToShortMoney(planBudgetTotal),
      global.translateService.instant(
        'app-actual-plan-bar-chart-budget-info.bookedBudget'
      ) +
        ': ' +
        global.converterService.convertToShortMoney(bookedBudget),
      global.translateService.instant(
        'app-actual-plan-bar-chart-budget-info.usedBudget'
      ) +
        ': ' +
        global.converterService.convertToShortMoney(usedBudget),
      global.translateService.instant(
        'app-actual-plan-bar-chart-budget-info.paidOffBudget'
      ) +
        ': ' +
        global.converterService.convertToShortMoney(paidOffBudget)
    ],
    {
      legend: {
        display: true,
        position: 'top',
        labels: {
          usePointStyle: true
        },
        align: 'start'
      },
      defaultColor: ['#204d95 ', '#782095', '#3d9520', '#800000'],
      tooltips: {
        callbacks: {
          label: (item: ChartTooltipItem, data: Chart.ChartData) => {
            log.debug(data);
            const tooltipData =
              tooltipContentList[item.index].value[item.datasetIndex];
            return tooltipData.title + ': ' + tooltipData.value;
          }
        }
      }
    }
  );
  return chartModel;
}

// const setCustomBarHoverTitle = (item: any): any => {
//   const addtionalData = [
//     'Total Rencana Kegiatan',
//     'Total Rancangan Kegiatan',
//     'Anothing Else'
//   ];
//   const datasetIndex = item[0].datasetIndex;
//   const data = addtionalData[datasetIndex];
//   const key = Object.keys(data)[datasetIndex];
//   return data[key];
// };
