<app-popup header="Filter">
  <form [formGroup]="formGroup">
    <div class="form-group">
      <label for="view">
        {{ 'dashboard-widget-budget-spend.form.view' | translate }}
      </label>
      <app-combo-box
        size="XL"
        formControlName="view"
        [optionList]="budgetFilterTemplateData.viewOptionList"
      >
      </app-combo-box>
    </div>
    <div class="form-group">
      <label for="organization">
        {{ 'dashboard-widget-budget-spend.form.organization' | translate }}
      </label>
      <app-text-tree
        size="XL"
        formControlName="organization"
        stringUrl="/organization"
        header="Organization"
        isLazy="true"
      >
      </app-text-tree>
    </div>

    <ng-container
      *ngIf="
        formGroup.get('view').value === 'Yearly';
        then yearlyTmpl;
        else monthlyTmpl
      "
    ></ng-container>

    <ng-template #yearlyTmpl>
      <div class="form-group">
        <label for="period">
          {{ 'dashboard-widget-budget-spend.form.period' | translate }}
        </label>
        <app-combo-box
          size="XL"
          formControlName="periode"
          [optionList]="budgetFilterTemplateData.periodeOptionList"
        >
        </app-combo-box>
      </div>
    </ng-template>

    <ng-template #monthlyTmpl>
      <div class="col">
        <div class="row">
          <div class="col-12 col-md-6 pl-0">
            <div class="form-group">
              <label for="from">
                {{ 'dashboard-widget-budget-spend.from' | translate }}
              </label>
              <app-month-year-picker
                formControlName="dateFrom"
                size="XL"
                (onChange)="onChangeDateFrom($event)"
              >
              </app-month-year-picker>
            </div>
          </div>
          <div class="col-12 col-md-6 p-0">
            <div class="form-group">
              <label for="from">
                {{ 'dashboard-widget-budget-spend.to' | translate }}
              </label>
              <app-month-year-picker formControlName="dateTo" size="XL">
              </app-month-year-picker>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </form>
  <div class="button-group button-group-right mt-4">
    <app-button color="SECONDARY" (onClick)="doCancel()">
      {{ 'app.button.cancel' | translate }}
    </app-button>
    <app-button color="WARNING" (onClick)="doReset()">
      {{ 'app.button.reset' | translate }}
    </app-button>
    <app-button (onClick)="doApply()">
      {{ 'app.button.apply' | translate }}
    </app-button>
  </div>
</app-popup>
