<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-xx
        [model]="tableResponse"
        stringUrl="/internal-payment-request/index"
        (onClick)="doClick($event)"
        [isShowCheckBox]="true"
        header="{{ 'internal-payment-request.table.header' | translate }}"
      >
        <ng-template #headerFilterGroup>
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
            *ngIf="!formLoading"
          >
            <div class="form-group row text-sm-left">
              <div class="col-sm-12 text-left">
                <div class="form-row px-1" style="margin-bottom: -3rem">
                  <div class="form-group row px-4" style="align-items: center">
                    <label class="pr-3 pt-2">{{
                      'internal-payment-request.form.invoiceDate' | translate
                    }}</label>
                    <div class="text-left">
                      <app-date-picker-x
                        size="XL"
                        formControlName="date"
                        [range]="true"
                        (onChange)="onChangeFilter()"
                      >
                      </app-date-picker-x>
                    </div>
                  </div>
                  <div class="form-group row ml-4 col-sm-2">
                    <app-dropdown-select
                      style="width: 100%"
                      type="CHOSEN"
                      size="XL"
                      formControlName="paymentStatusList"
                      [optionList]="paymentStatusOptionList"
                      (onChange)="onChangeFilter()"
                      showLimitValue="1"
                      placeholder="{{
                        'internal-payment-request.placeholder.status'
                          | translate
                      }}"
                    >
                      <ng-template #selectAll>
                        <p>
                          {{
                            'internal-payment-request.placeholder.allStatus'
                              | translate
                          }}
                        </p>
                      </ng-template>
                      <ng-template #option let-data>
                        <p> {{ data?.name }} </p>
                      </ng-template>
                    </app-dropdown-select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-template>

        <ng-template #headerButtons>
          <app-button (onClick)="doAddBill()">
            <em class="pir pi-plus-circle"></em>
            {{ 'internal-payment-request.button.newRequest' | translate }}
          </app-button>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            (onClick)="doDelete(rowData)"
            title="{{ 'app.tooltip.delete' | translate }}"
          >
            <em class="pir pi-trash-alt"></em>
            {{ 'app.button.delete' | translate }}
          </app-button>
        </ng-template>
      </app-table-xx>
    </div>
  </div>
</app-layout>
