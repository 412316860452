import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AddressBook } from 'src/app/core/bean/address-book';
import { ItemType } from 'src/app/core/bean/item-type';
import { Pr } from 'src/app/core/bean/pr';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PrShipping } from 'src/app/core/bean/pr-shipping';
import { PrStatus } from 'src/app/core/bean/pr-status';
import { PrVerification } from 'src/app/core/bean/pr-verification';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { AppTableComponent } from 'src/app/core/components/table/components/app-table/app-table.component';
import { AppTableModule } from 'src/app/core/components/table/components/app-table/app-table.module';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { VendorView } from 'src/app/core/view/entity/bean/vendor-view';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AddressPic } from '../../core/bean/address-pic';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { PrVerificationRequest } from './pr-verification.request';
import { PrVerificationResponse } from './pr-verification.response';
@Component({
  templateUrl: './pr-verification-edit.component.html'
})
export class PrVerificationEditComponent extends BaseModuleComponent {
  public prId: number;
  public prVerificationResponse: PrVerificationResponse =
    new PrVerificationResponse();
  public pr: Pr = new Pr();
  public prStatusOptionList: OptionListModel<PrStatus> = new OptionListModel();
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel();
  @ViewChild(AppTableModule) public table: AppTableComponent;
  @ViewChild('selectorItem') tableItem: AppTableComponent;
  @ViewChild('selectorVendorReference')
  tableVendorReference: AppTableComponent;
  public tableResponseVendorReference: TableResponseModel<VendorView>;
  public tableResponseItem: TableResponseModel<PrItem>;
  @ViewChild('selectorShipping') tableShipping: AppTableComponent;
  public tableResponseShipping: TableResponseModel<PrShipping>;
  public addressBookOptionList: OptionListModel<any> = new OptionListModel(
    true,
    'addressDetail'
  );
  public prStatus: PrStatus = new PrStatus();
  public token: string;
  public urlBackOutside: string;
  public workloadPrcsId: number;
  public objectId: number;
  public itemType: FormControl = new FormControl('');
  public appOfficialReportModel: AppOfficialReportModel;
  public isUpdated: boolean;
  public isOfficialReportNull: boolean;
  public approvalModelPrcsId: number;

  constructor(
    translateService: TranslateService,
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public activatedRoute: ActivatedRoute
  ) {
    super('pr-verification', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Pr>('/pr-verification/notification/' + this.token)
      .subscribe((pr: Pr) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('prId', pr.id);
        this.global.routerParams.set('prStatus', pr.prStatus);
        this.global.routerParams.set(
          'urlBackOutside',
          '/pages/pr-verification'
        );
        if (
          pr.prStatus.code === this.global.appConstant.pm.PR_STATUS_REVISION ||
          pr.prStatus.code === this.global.appConstant.pm.PR_STATUS_VERIFIED
        ) {
          this.global.routerParams.set('todo', 'view');
          this.router.navigate(['/pages/pr-verification/detail']);
        } else {
          this.global.routerParams.set('todo', 'edit');
          this.router.navigate(['/pages/pr-verification/edit']);
        }
      });
  }

  public setInitializationState(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.prId = this.global.routerParams.get('prId');
    this.prStatus = this.global.routerParams.get('prStatus');
    this.workloadPrcsId = this.global.routerParams.get('workloadPrcsId');
    this.objectId = this.global.routerParams.get('objectId');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    if (this.objectId) {
      this.prId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
  }
  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }
  public setOptionModel(): void {
    this.prStatusOptionList.setRequestValues(
      this.prVerificationResponse.prStatusList
    );
  }
  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      prStatus: [null, Validators.compose([Validators.required()])],
      note: [
        null,
        Validators.compose([Validators.required(), , Validators.maxLength(512)])
      ],
      documentList: [null],
      addressBook: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<PrVerificationResponse>(
        '/pr-verification/edit',
        new RouteRequestModel(this.todo, this.prId)
      )
      .subscribe((prVerificationResponse: PrVerificationResponse) => {
        this.prVerificationResponse = prVerificationResponse;
        this.pr = prVerificationResponse.pr;
        this.itemTypeOptionList.setRequestValues(
          this.prVerificationResponse.itemTypeList
        );
        this.prStatus = this.pr.prStatus;
        if (
          this.prVerificationResponse.prVerification &&
          this.todo === 'view'
        ) {
          this.formGroup.patchValue({
            id: this.prVerificationResponse.prVerification.id,
            prStatus: this.prVerificationResponse.prVerification.prStatus,
            note: this.prVerificationResponse.prVerification.note
          });
        }
        if (this.prVerificationResponse.prVerification) {
          this.formGroup.patchValue({
            id: this.prVerificationResponse.prVerification.id
          });
        }
        this.appOfficialReportModel =
          prVerificationResponse.appOfficialReportModel;
        const addressList: AddressBook[] = [];
        this.prVerificationResponse.prShippingList.forEach(prShipping => {
          addressList.push(prShipping.address);
        });
        this.addressBookOptionList.setRequestValues(addressList);
        this.formGroup.get('addressBook').patchValue({
          addressList
        });
        this.formGroup
          .get('documentList')
          .patchValue(
            this.prVerificationResponse.appUploadDocumentTableModelList
          );
        this.formGroup.get('documentList').setIsView(true);
        this.setStateReady();
        this.formGroup.get('addressBook').setIsView(true);
        this.buildTableResponse();
        this.setOptionModel();
      });
  }

  public buildTableResponse(): void {
    this.tableResponseItem = new TableResponseModel(this.moduleCode, [
      {
        field: 'prItemImageList',
        header: 'table.column.thumbnail',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePluginData: TablePluginData): File => {
              if (tablePluginData.value && tablePluginData.value.length > 0) {
                return tablePluginData.value[0].file;
              }
              return null;
            }
          },
          {
            name: 'img',
            classNameMap: {
              MATERIAL: 'pvr pv-box',
              JASA: 'pvr pv-tools'
            },
            fieldClassName: 'item.itemType.code',
            onClick: this.doViewItem.bind(this)
          }
        ],
        isSortable: false,
        isSearchTable: false
      },
      {
        field: 'item.merk',
        header: 'table.column.itemName'
      },
      {
        field: 'code',
        header: 'table.column.prLine'
      },
      {
        field: 'catalog.catalogType.name',
        header: 'table.column.catalogType',
        plugins: {
          name: 'badge',
          className: 'badge-catalog',
          pill: false,
          colorMap: {
            ITEM: 'WARNING',
            VENDOR: 'INFO',
            CONTRACT: 'SUCCESS'
          },
          callbacks: {
            text: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.row.record.catalog) {
                return this.translateService.instant(
                  'dynamic-master-attribute.' +
                    tablePluginData.row.record.catalog.catalogType
                      .translationKey.key
                );
              } else {
                return 'Item';
              }
            },
            color: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.row.record.catalog) {
                if (
                  tablePluginData.row.record.catalog.catalogType.code ===
                  this.global.appConstant.vm.CATALOG_TYPE_VENDOR
                ) {
                  return 'VENDOR';
                } else if (
                  tablePluginData.row.record.catalog.catalogType.code ===
                  this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
                ) {
                  return 'CONTRACT';
                }
              } else {
                return 'ITEM';
              }
            }
          }
        }
      },
      {
        field: 'item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'item.itemType.code',
          callbacks: {
            text: (tablePluginData: TablePluginData): string => {
              if (
                tablePluginData.row.record.item.itemType.code ===
                this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
              ) {
                return this.translateService.instant(
                  'dynamic-master-attribute.itemType.good'
                );
              } else {
                return this.translateService.instant(
                  'dynamic-master-attribute.itemType.service'
                );
              }
            }
          }
        }
      },
      {
        field: 'workflowModelPrcs.name',
        header: 'table.column.stage'
      },
      {
        field: 'quantity',
        header: 'table.column.quantity',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        },
        className: 'text-right'
      },
      {
        field: 'price',
        header: 'table.column.price',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      {
        field: 'totalPrice',
        header: 'table.column.totalPrice',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      }
    ]);

    this.tableResponseVendorReference = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'name',
          header: 'table.column.vendorName'
        },
        {
          field: 'address',
          header: 'table.column.address'
        },
        {
          field: 'phone',
          header: 'table.column.phone'
        },
        {
          field: 'averageRatingOverall',
          header: 'table.column.rating',
          plugins: {
            name: 'rating',
            isView: true
          }
        }
      ]
    );

    this.tableResponseItem.setRecordList(
      this.prVerificationResponse.prItemList
    );

    this.tableResponseVendorReference.setRecordList(
      this.prVerificationResponse.vendorViewList
    );
  }

  public doViewItem(prItem: PrItem): void {
    this.appPopupPrItemInfoService.open(prItem.id, true);
  }

  public doSetAmount(): void {
    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;
    this.prVerificationResponse.prItemList.forEach(prItem => {
      if (
        prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      ) {
        goodsAmountTotal = +goodsAmountTotal + +prItem.totalPrice;
      }
      if (
        prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
      ) {
        serviceAmountTotal = +serviceAmountTotal + +prItem.totalPrice;
      }
    });
    this.formGroup.patchValue({
      goodsAmount: goodsAmountTotal,
      serviceAmount: serviceAmountTotal,
      amount: +goodsAmountTotal + +serviceAmountTotal
    });
  }

  public onChangeItem(): void {
    const itemType = this.itemType.value;
    if (itemType) {
      const prItemList = [];
      this.prVerificationResponse.prItemList.forEach((prItem: PrItem) => {
        if (prItem.item.itemType.id === itemType.id) {
          prItemList.push(prItem);
        }
      });
      this.tableResponseItem.setRecordList(prItemList);
    } else {
      this.tableResponseItem.setRecordList(
        this.prVerificationResponse.prItemList
      );
    }
    this.tableResponseItem.reload();
    this.doSetAmount();
  }

  public doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(results => {
          if (results) {
            this.setStateProcessing();
            const prVerificationRequest: PrVerificationRequest =
              new PrVerificationRequest();
            const prVerification: PrVerification =
              this.global.copyFormAttributeToModel(
                new PrVerification(),
                this.formGroup
              );
            prVerificationRequest.prVerification = prVerification;
            prVerificationRequest.pr = this.prVerificationResponse.pr;
            prVerificationRequest.isSubmit = true;
            prVerificationRequest.catalogList = this.prVerificationResponse.catalogList;
            this.httpClientService
              .post<Response<PrVerification>>(
                '/pr-verification/update',
                prVerificationRequest
              )
              .subscribe(response => {
                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccess()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate(['/pages/pr-verification/']);
                      } else {
                        this.router
                          .navigateByUrl('/', { skipLocationChange: true })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set('prId', this.prId);
                            this.global.routerParams.set(
                              'prStatus',
                              this.formGroup.get('prStatus').value
                            );
                            if (
                              this.formGroup.get('prStatus').value.code ===
                                this.global.appConstant.pm.PR_STATUS_REVISION ||
                              this.formGroup.get('prStatus').value.code ===
                                this.global.appConstant.pm.PR_STATUS_VERIFIED
                            ) {
                              this.global.routerParams.set('todo', 'view');
                              this.router.navigate([
                                '/pages/pr-verification/detail'
                              ]);
                            } else {
                              this.global.routerParams.set('todo', 'edit');
                              this.router.navigate([
                                '/pages/pr-verification/edit'
                              ]);
                            }
                          });
                      }
                    });
                } else {
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public getUserNameList(addressPicList: AddressPic[]): string {
    return addressPicList.map(a => a.user.name).join(', ');
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
          '.' +
          data.translationKey.key || '-'
      );
    }
  }
}
