import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { PrItem } from 'src/app/core/bean/pr-item';
import { AppPopupChooseBudgetXService } from 'src/app/core/components/app-popup/app-popup-choose-budget-x/app-popup-choose-budget-x.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { TabModel } from 'src/app/core/model/tab/tab-model';
import { TabResponseModel } from 'src/app/core/model/tab/tab-response-model';
import { BondType } from '../../core/bean/bond-type';
import { Currency } from '../../core/bean/currency';
import { QuotationAdministration } from '../../core/bean/quotation-administration';
import { QuotationBidBond } from '../../core/bean/quotation-bid-bond';
import { Validators } from '../../core/validators';
import { SubmitQuotationVendorResponse } from './submit-quotation-vendor.response';

@Component({
  templateUrl: './submit-quotation-vendor-quotation.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SubmitQuotationVendorQuotationComponent
  extends BaseModuleComponent
  implements OnInit
{
  public minBidBondValue: number;
  public isAdministrationCompleted: boolean;
  public isBidBondCompleted: boolean;
  public submitQuotationResponse: SubmitQuotationVendorResponse =
    new SubmitQuotationVendorResponse();
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel();
  public bondTypeOptionList: OptionListModel<BondType> = new OptionListModel();
  public prItem: PrItem = new PrItem();
  public autoCompleteValue: any;
  public isBudgetValue: boolean;
  public fileUploaderAdministration: FileUploader = new FileUploader(
    '/rfp/',
    '',
    'DOC_RFP_TEMPLATE'
  );
  public fileUploaderBidBond: FileUploader = new FileUploader(
    '/rfp/',
    '',
    'DOC_RFP_TEMPLATE'
  );
  public tabResponse: TabResponseModel;
  public tabTwo: TabModel;
  public backToUrl: string;
  public isInvalidBidBond = false;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupChooseBudgetService: AppPopupChooseBudgetXService
  ) {
    super('submit-quotation-vendor', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setOptionModel();
    this.checkComplete();
    this.setStateReady();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.tabResponse = this.global.routerParams.get('tabResponse');
    this.tabTwo = this.tabResponse.currentTab;
    this.submitQuotationResponse = this.global.routerParams.get(
      'submitQuotationResponse'
    );
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setOptionModel(): void {
    this.currencyOptionList.setRequestValues(
      this.submitQuotationResponse.currencyList
    );
    this.bondTypeOptionList.setRequestValues(
      this.submitQuotationResponse.bondTypeList
    );
    if (
      this.submitQuotationResponse.quotationAdministration &&
      this.submitQuotationResponse.quotationBidBond
    ) {
      if (this.submitQuotationResponse.quotationAdministration.docFile) {
        const admFileList = [];
        admFileList.push(
          this.submitQuotationResponse.quotationAdministration.docFile
        );
        this.fileUploaderAdministration.setFileList(admFileList);
      }

      if (this.submitQuotationResponse.quotationBidBond.docFile) {
        const bidFileList = [];
        bidFileList.push(this.submitQuotationResponse.quotationBidBond.docFile);
        this.fileUploaderBidBond.setFileList(bidFileList);
      }

      if (
        this.submitQuotationResponse.quotationAdministration.fileObjectList
          ?.length > 0
      ) {
        this.fileUploaderAdministration.fileObjectList =
          this.submitQuotationResponse.quotationAdministration.fileObjectList;
      }
      if (
        this.submitQuotationResponse.quotationBidBond.fileObjectList?.length > 0
      ) {
        this.fileUploaderBidBond.fileObjectList =
          this.submitQuotationResponse.quotationBidBond.fileObjectList;
      }

      const startLetterDate: Date = this.submitQuotationResponse
        .quotationAdministration.startDate
        ? new Date(
            this.submitQuotationResponse.quotationAdministration.startDate
          )
        : null;
      const endLetterDate: Date = this.submitQuotationResponse
        .quotationAdministration.endDate
        ? new Date(this.submitQuotationResponse.quotationAdministration.endDate)
        : null;
      const letterDate = { from: startLetterDate, to: endLetterDate };
      const startDate: Date = this.submitQuotationResponse.quotationBidBond
        .startDate
        ? new Date(this.submitQuotationResponse.quotationBidBond.startDate)
        : null;
      const endDate: Date = this.submitQuotationResponse.quotationBidBond
        .endDate
        ? new Date(this.submitQuotationResponse.quotationBidBond.endDate)
        : null;
      const validityPeriod = { from: startDate, to: endDate };
      const { letterNumber, currency } =
        this.submitQuotationResponse.quotationAdministration;
      const { bondType, bidBondNumber } =
        this.submitQuotationResponse.quotationBidBond;
      const amount = {
        price: this.submitQuotationResponse.quotationBidBond.amount,
        currency: this.submitQuotationResponse.currencyList[0]
      };

      this.formGroup.patchValue({
        letterNumber,
        letterDate,
        currency,
        letterOfQuotation: this.fileUploaderAdministration.fileObjectList,
        bondType,
        bidBondNumber,
        amount,
        validityPeriod,
        startDate,
        endDate,
        bidBondFile:
          this.fileUploaderBidBond.fileObjectList ||
          this.submitQuotationResponse.quotationBidBond.fileObjectList
      });
      if (this.todo !== 'add') {
        this.onChange();
      }
    }
  }

  public checkComplete(): void {
    if (
      this.formGroup.value.letterNumber &&
      this.formGroup.value.letterDate &&
      this.formGroup.value.currency &&
      this.formGroup.value.letterOfQuotation
    ) {
      this.isAdministrationCompleted = true;
      this.global.routerParams.set(
        'isAdministrationCompleted',
        this.isAdministrationCompleted
      );
    }
    if (
      this.formGroup.value.bondType &&
      this.formGroup.value.bidBondNumber &&
      this.formGroup.value.amount &&
      this.formGroup.value.validityPeriod &&
      this.formGroup.value.bidBondFile &&
      this.checkValidityBidBond()
    ) {
      this.isBidBondCompleted = true;
      this.global.routerParams.set(
        'isBidBondCompleted',
        this.isBidBondCompleted
      );
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      letterNumber: [null, Validators.required()],
      letterDate: [null, Validators.required()],
      currency: [null, Validators.required()],
      letterOfQuotation: [null, Validators.required()],
      bondType: [null, Validators.required()],
      bidBondNumber: [null, Validators.required()],
      amount: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.min(this.global.config.parameterModel.minBidBondValue)
        ])
      ],
      validityPeriod: [null, Validators.required()],
      startDate: [null],
      endDate: [null],
      bidBondFile: [null, Validators.required()]
    });
  }

  public checkValidityBidBond(): boolean {
    if (this.global.routerParams.get('amount')) {
      this.minBidBondValue =
        (+this.global.config.parameterModel.minBidBondValue *
          +this.global.routerParams.get('amount')) /
        100;
      this.global.routerParams.set('minBidBondValue', this.minBidBondValue);
      if (+this.formGroup.value.amount?.price < +this.minBidBondValue) {
        this.global.routerParams.set('validityBidBondValue', false);
        return false;
      } else {
        this.global.routerParams.set('validityBidBondValue', true);
        return true;
      }
    } else {
      this.global.routerParams.set('validityBidBondValue', true);
      return true;
    }
  }

  public onChange(): void {
    if (this.formGroup.value.validityPeriod) {
      this.formGroup.patchValue({
        startDate: this.formGroup.value.validityPeriod.from,
        endDate: this.formGroup.value.validityPeriod.to
      });
    }
    const quotationAdministration: QuotationAdministration =
      this.global.copyFormAttributeToModel(
        new QuotationAdministration(),
        this.formGroup
      );
    const quotationBidBond: QuotationBidBond =
      this.global.copyFormAttributeToModel(
        new QuotationBidBond(),
        this.formGroup
      );

    quotationAdministration.fileObjectList =
      this.fileUploaderAdministration.fileObjectList;
    quotationAdministration.docFile = null;
    quotationAdministration.startDate =
      this.formGroup.value.letterDate?.from || null;
    quotationAdministration.endDate =
      this.formGroup.value.letterDate?.to || null;

    quotationBidBond.fileObjectList = this.fileUploaderBidBond.fileObjectList;
    quotationBidBond.docFile = null;
    quotationBidBond.amount = quotationBidBond.amount
      ? quotationBidBond.amount['price']
      : 0;

    this.submitQuotationResponse.quotationAdministration =
      quotationAdministration;
    this.submitQuotationResponse.quotationBidBond = quotationBidBond;
    this.global.routerParams.set(
      'submitQuotationResponse',
      this.submitQuotationResponse
    );

    this.global.routerParams.set(
      'quotationAdministration',
      quotationAdministration
    );
    this.global.routerParams.set('quotationBidBond', quotationBidBond);
    this.checkComplete();
  }
}
