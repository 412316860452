import { BaseEntity } from '../base/base-entity';
import { ProcurementScopeWork } from '../bean/procurement-scope-work';
import { ProcurementSowPaymentTerm } from '../bean/procurement-sow-payment-term';
import { StageOfWork } from '../bean/stage-of-work';
export class ProcurementSowEntity extends BaseEntity {
  procurementSowPaymentTermList: ProcurementSowPaymentTerm[] = [];
  procurementScopeWork: ProcurementScopeWork = new ProcurementScopeWork();
  stageOfWork: StageOfWork = new StageOfWork();
  periodType: number;
  startDate: Date = new Date();
  endDate: Date = new Date();
  duration: number;
  durationType: number;
  status: number;
  workPercentageValue: number;
  isMonthlyPayment: boolean;
}
