import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './app-popup-procurement-schedule-changes.component.html'
})
export class AppPopupProcurementScheduleChangesComponent extends BaseComponentComponent {
  @Output() public onChange: EventEmitter<string> = new EventEmitter();

  constructor(public translateService: TranslateService) {
    super('procurement-schedule');
  }

  onInit(): void {
    this.buildFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      reason: [null, Validators.required()]
    });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup.value.reason);
    }
  }
}
