import { EventEmitter } from '@angular/core';
import { WorkPathItem } from '../interface/work-path-item.model';
export class WorkPathModel {
  public workPathItemList: Array<WorkPathItem> = new Array();
  public valueChanges: EventEmitter<Array<WorkPathItem>> = new EventEmitter();
  constructor(public isWaitingFromServer: boolean = false) { }
  setRequestValues(workPathItemList: Array<WorkPathItem>): void {
    this.workPathItemList = workPathItemList;
    this.valueChanges.emit(workPathItemList);
  }
}
