<app-layout
  [isLoading]="formLoading"
  header="compliance-test-master.detail.layout.header.complianceTestGroup"
>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form [formGroup]="formGroup">
        <app-card
          *ngIf="!formLoading"
          header="compliance-test-master.detail.procurementDocument.title"
          tips="compliance-test-master.detail.procurementDocument.tips"
        >
          <app-fullfilled></app-fullfilled>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'compliance-test-master.detail.form.procurementMethod'
                  | translate
              }}
            </label>
            <div class="col-sm-9 text-left">
              {{
                procurementMethod.translationKey.module.code.toLowerCase() +
                  '.' +
                  procurementMethod.translationKey.key | translate
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'compliance-test-master.detail.form.complianceGroup' | translate
              }}
            </label>
            <div class="col-sm-9 text-left mb-3">
              <app-button (onClick)="doAddComplianceGroup()"
                ><em class="pir pi-plus-circle"></em>&nbsp;{{
                  'compliance-test-master.detail.button.addComplianceGroup'
                    | translate
                }}</app-button
              >
            </div>
            <div class="col-sm-3"></div>
            <div
              class="col-sm-9 text-left"
              *ngIf="
                complianceTestGroupProcList &&
                complianceTestGroupProcList.length !== 0
              "
            >
              <app-table-xx
                [model]="tableResponse"
                [isMultipleSelect]="true"
                [isShowCheckBox]="true"
                [isShowPagination]="false"
                [isShowSearch]="false"
                (onClick)="doAddComplianceGroup($event)"
              >
                <ng-container>
                  <ng-template #actionButtons let-rowData>
                    <app-button
                      size="TN"
                      (onClick)="doDeleteComplianceGroup(rowData)"
                      title="{{ 'app.tooltip.delete' | translate }}"
                    >
                      <em class="pir pi-trash-alt"></em>
                      {{ 'app.button.delete' | translate }}
                    </app-button>
                  </ng-template>
                </ng-container>
              </app-table-xx>
            </div>
          </div>
        </app-card>
        <app-card
          *ngIf="!formLoading"
          header="compliance-test-master.detail.vendorDocument.title"
          tips="compliance-test-master.detail.vendorDocument.tips"
        >
          <app-fullfilled></app-fullfilled>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'compliance-test-master.detail.form.procurementMethod'
                  | translate
              }}
            </label>
            <div class="col-sm-9 text-left">
              {{
                procurementMethod.translationKey.module.code.toLowerCase() +
                  '.' +
                  procurementMethod.translationKey.key | translate
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'compliance-test-master.detail.form.complianceGroupName'
                  | translate
              }}
            </label>
            <div class="col-sm-9 text-left">
              {{
                'compliance-test-master.detail.form.vendorDocument' | translate
              }}
            </div>
          </div>
          <app-card
            *ngIf="!formLoading"
            header="compliance-test-master.detail.complianceTestChecklist.title"
          >
            <ng-template #headerRight>
              <app-button (onClick)="doAddChecklist()" [disable]="formSaving"
                ><em class="pir pi-plus-circle"></em>&nbsp;{{
                  'compliance-test-master.button.addCompliance' | translate
                }}</app-button
              >
            </ng-template>
            <app-accordion
              formArrayName="complianceTestChecklistList"
              [isLoading]="formLoading"
              cdkDropList
              (cdkDropListDropped)="onDrop($event)"
              [isShowMultiple]="true"
            >
              <app-accordion-item
                header="{{
                  checklist.value.testName ||
                    'compliance-test-master.accordionItem.header.addCompliance'
                }}"
                *ngFor="
                  let checklist of complianceTestChecklistList.controls;
                  let i = index
                "
                [formGroupName]="i"
                [isExpanded]="checklist.value.isExpanded"
                customClassHeader="bg-white"
                customClassBody="bg-light"
                cdkDrag
              >
                <ng-template #headerLeft>
                  <em
                    class="pir pi-bars pi-3x"
                    cdkDragHandle
                    (mouseenter)="onMouseEnter($event)"
                  ></em>
                </ng-template>
                <ng-template #headerRight>
                  <em
                    class="pir pi-trash-alt pi-2x"
                    (click)="doDeleteChecklist($event, i)"
                  ></em>
                </ng-template>
                <div class="form-group row text-sm-right required">
                  <label class="col-sm-3 text-left">{{
                    'compliance-test-master.form.complianceTestName' | translate
                  }}</label>
                  <div class="col-sm-9 text-left"
                    ><app-text-field
                      formControlName="testName"
                      size="XL"
                      maxLength="32"
                    ></app-text-field
                  ></div>
                </div>
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 text-left">{{
                    'compliance-test-master.form.checklistDocument' | translate
                  }}</label>
                  <div class="col-sm-9 text-left"
                    ><app-dropdown-select
                      size="XL"
                      [optionList]="vendorDocMasterOptionList"
                      formControlName="checklistDocument"
                      showLimitValue="5"
                      [isMultiple]="true"
                      container="body"
                      type="AUTOCOMPLETE"
                    >
                    </app-dropdown-select
                  ></div>
                </div>
              </app-accordion-item>
            </app-accordion>
          </app-card>
        </app-card>
      </form>

      <div class="floating-button mt-5">
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button (onClick)="doSave()">{{
                'app.button.save' | translate
              }}</app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
