import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ProcurementMethod } from '../../core/bean/procurement-method';
import { ProcurementParticipant } from '../../core/bean/procurement-participant';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './procurement-participant-edit-add.component.html'
})
export class ProcurementParticipantEditAddComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<ProcurementParticipant>;
  public procurementMethod: ProcurementMethod;
  public procurementParticipant: ProcurementParticipant;

  constructor(translateService: TranslateService) {
    super('procurement-participant', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildTableResponse();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.procurementMethod = this.global.routerParams.get('procurementMethod');
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'module.name', header: 'table.column.name' },
      {
        field: 'minimumParticipant',
        header: 'table.column.minimumParticipant',
        format: FieldFormatEnum.InputNumber
      },
      {
        field: 'maximumParticipant',
        header: 'table.column.maximumParticipant',
        format: FieldFormatEnum.InputNumber
      }
    ]);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const procurementParticipants = this.tableResponse.page.records;
            this.httpClientService
              .post<Response<ProcurementParticipant[]>>(
                '/procurement-participant/update',
                procurementParticipants
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/procurement-participant']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/procurement-participant']);
  }
}
