<div class="row main-row">
  <div class="col-12">
    <form class="form-horizontal pt-5" [formGroup]="tabOne.formGroup">
      <div class="form-group row text-sm-right required">
        <label class="col-sm-3 control-label">Name</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            autofocus="true"
            formControlName="name"
            tooltip="Example name tooltip"
            appAlphabet
          ></app-text-field>
        </div>
      </div>
      <div class="form-group row text-sm-right required">
        <label class="col-sm-3 control-label">Salary</label>
        <div class="col-sm-9 text-left">
          <app-text-field formControlName="salary" appCurrency></app-text-field>
        </div>
      </div>
      <div class="form-group row text-sm-right required">
        <label class="col-sm-3 control-label">Country</label>
        <div class="col-sm-9 text-left">
          <app-text-tree
            formControlName="region"
            stringUrl="/region"
            header="Country"
            isLazy="true"
          ></app-text-tree>
        </div>
      </div>
    </form>
  </div>
</div>
