<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="delivery-monitoring.title" [isLoading]="formLoading" [isSaving]="formSaving">
      <app-fieldset legend="Filter">
        <form class="form-horizontal" [formGroup]="formGroup" novalidate *ngIf="!formLoading">

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'delivery-monitoring.form.procurementNumber' | translate
              }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen formControlName="procurementNumberList" [optionList]="procurementNumberOptionList">
              </app-chosen>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'contract-monitoring.form.procurementName' | translate
              }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen formControlName="procurementNameList" [optionList]="procurementNameOptionList"></app-chosen>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{ 'delivery-monitoring.form.procurementDate' | translate }}</label>
            <div class="col-sm-9 text-left" style="display: flex">
              <app-date-picker size="XL" formControlName="fromDate" [ngStyle]="{
                  'margin-right': !formGroup.isView ? '0px' : '30px'
                }">
              </app-date-picker>
              <p class="ml-3 mr-3 control-label">{{
                'delivery-monitoring.form.until' | translate
                }}</p>
              <app-date-picker size="XL" formControlName="untilDate" minDate="fromDate"></app-date-picker>
            </div>
          </div>
          <div *ngIf="global.userSession?.activeUserRole.role.code !== 'VENDOR'" class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{ 'delivery-monitoring.form.vendorName' | translate }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen formControlName="vendorList" [optionList]="vendorOptionList"></app-chosen>
            </div>
          </div>
          <div class="button-group button-group-center mt-5">
            <app-button mode="BYPASS" color="SECONDARY" (onClick)="doReset()">
              <em class="fas fa-sync-alt"></em>
              {{ 'app.button.reset' | translate }}
            </app-button>
            <app-button mode="BYPASS" color="PRIMARY" (onClick)="doApply()" [disabled]="formSaving"><em
                class="fas fa-check"></em>
              {{ 'app.button.apply' | translate }}
            </app-button>
          </div>
        </form>
      </app-fieldset>
      <app-table *ngIf="global.userSession?.activeUserRole.role.code !== 'VENDOR'" [model]="tableResponse"
        [isServerSide]="true" stringUrl="/delivery-monitoring/index">
        <ng-template #actionButtons let-rowData>
          <app-button size="TN" (onClick)="doDetail(rowData)" title="{{ 'app.tooltip.view' | translate }}">
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
      <app-table *ngIf="global.userSession?.activeUserRole.role.code === 'VENDOR'" [model]="tableResponseVendor"
        [isServerSide]="true" stringUrl="/delivery-monitoring/index">
        <ng-template #actionButtons let-rowData>
          <app-button size="TN" (onClick)="doDetail(rowData)" title="{{ 'app.tooltip.view' | translate }}">
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>