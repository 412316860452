<app-popup>
  <h4 class="popup-modify-table-title fw-500">
    {{ 'app-table.header.editTable' | translate }}
  </h4>
  <div class="app-popup-modify-table-body">
    <app-select
      [formControl]="tableColumn"
      [optionList]="optionList"
      [isToggle]="true"
      maxHeight="576px"
    ></app-select>
  </div>
  <div class="button-group button-group-center">
    <app-button color="SECONDARY" [outline]="true" (onClick)="onClose.emit()">
      {{ 'app.button.cancel' | translate }}
    </app-button>
    <app-button (onClick)="doSave()">
      {{ 'app.button.save' | translate }}
    </app-button>
  </div>
</app-popup>
