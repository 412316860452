import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../guard/confirm.guard';
import { SharedModule } from '../../shared/shared.module';
import { ReportRecapOrderProcessPopupChooseColumnComponent } from './report-recap-order-process-popup-choose-column.component';
import { ReportRecapOrderProcessPopupFullViewComponent } from './report-recap-order-process-popup-full-view.component';
import { ReportRecapOrderProcessComponent } from './report-recap-order-process.component';

export const routes = [
  {
    path: '',
    component: ReportRecapOrderProcessComponent,
    data: { breadcrumb: 'report-recap-order-process.breadcrumb.index' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [
    ReportRecapOrderProcessComponent,
    ReportRecapOrderProcessPopupChooseColumnComponent,
    ReportRecapOrderProcessPopupFullViewComponent
  ],
  providers: []
})
export class ReportRecapOrderProcessModule {}
