<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="template-group.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-tree [model]="treeModel" (onEventChange)="doOnEventChange($event)">
        <ng-template #treePopupContent let-treePopupFormGroup>
          <form
            class="form-horizontal"
            [formGroup]="treePopupFormGroup"
            novalidate
          >
            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'template-group.form.code' | translate
              }}</label>
              <div class="col-sm-8 text-left">
                <app-text-field
                  autofocus="true"
                  size="XL"
                  formControlName="code"
                  type="code"
                  include="/."
                  (onChange)="doCodeChange($event)"
                ></app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'template-group.form.name' | translate
              }}</label>
              <div class="col-sm-8 text-left">
                <app-text-field
                  size="XL"
                  formControlName="name"
                  maxLength="64"
                ></app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'template-group.form.description' | translate
              }}</label>
              <div class="col-sm-8 text-left">
                <app-text-area
                  size="XL"
                  formControlName="description"
                  maxLength="512"
                ></app-text-area>
              </div>
            </div>
          </form>
        </ng-template>
      </app-tree>
    </app-card>
  </div>
</div>
