import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Procurement } from '../../core/bean/procurement';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { AppPopupSuccessfulyCreateComponent } from './app-popup-successfuly-create.component';
import { PriceEvaluationContinueRequest } from './price-evaluation-continue.request';

@Component({
  templateUrl: './app-popup-create-auction.component.html'
})
export class AppPopupCreateAuctionComponent extends BaseComponentComponent {
  @Input() public request: PriceEvaluationContinueRequest;
  @Input() public procurementWorklistStatusCode: string;

  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public biddingSubmissionTypeOptionList: OptionListModel<any> =
    new OptionListModel(false);

  public biddingSubmissionTypeList = [
    {
      id: 1,
      name: 'Total Price by Unit Price Item',
      value: 'TOTAL_PRICE_BY_UNIT'
    },
    { id: 2, name: 'Total Bulk Price', value: 'TOTAL_PRICE' }
  ];

  public constructor(
    private ngbActiveModal: NgbActiveModal,
    private appPopupService: AppPopupService
  ) {
    super('price-evaluation');
  }

  public onInit(): void {
    this.biddingSubmissionTypeOptionList.setRequestValues(
      this.biddingSubmissionTypeList
    );
    this.buildFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      biddingSubmissionType: [null]
    });
  }

  public doCreateAuction(): void {
    this.setStateProcessing();
    this.request.auctionBiddingType =
      this.formGroup.value.biddingSubmissionType?.value;
    this.httpClientService
      .post<Response<Procurement>>('/price-evaluation/continue', this.request)
      .subscribe(response => {
        this.onChange.emit('');
        this.setStateReady();
        if (response.status === ResponseStatusModel.OK) {
          const prompt = 'price-evaluation.success.createAuction.prompt';
          const title = 'price-evaluation.success.createAuction.title';
          this.appPopupService.open(
            AppPopupSuccessfulyCreateComponent,
            {
              procurement: this.request.procurement,
              procurementWorklistStatusCode: this.procurementWorklistStatusCode,
              title,
              prompt
            },
            { size: 'md' }
          );
        }
      });
  }

  public doCancel(): void {
    this.ngbActiveModal.close();
  }
}
