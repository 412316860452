import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppAutoCompleteModule } from '../../app-auto-complete/app-auto-complete.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppCheckBoxModule } from '../../app-check-box/app-check-box.module';
import { AppComboBoxTreeModule } from '../../app-combo-box-tree/app-combo-box-tree.module';
import { AppComboBoxModule } from '../../app-combo-box/app-combo-box.module';
import { AppDropdownSelectModule } from '../../app-dropdown-select/app-dropdown-select.module';
import { AppFieldsetModule } from '../../app-fieldset/app-fieldset.module';
import { AppRadioModule } from '../../app-radio/app-radio.module';
import { AppTextFieldModule } from '../../app-text-field/app-text-field.module';
import { AppTextTreeModule } from '../../app-text-tree/app-text-tree.module';
import { AppTableModule } from '../../table/components/app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupChooseBudgetXComponent } from './app-popup-choose-budget-x.component';
import { AppPopupChooseBudgetXService } from './app-popup-choose-budget-x.service';

@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppTableModule,
    AppButtonModule,
    AppTextFieldModule,
    AppCheckBoxModule,
    AppRadioModule,
    AppFieldsetModule,
    AppComboBoxModule,
    AppTextTreeModule,
    AppAutoCompleteModule,
    AppComboBoxTreeModule,
    AppDropdownSelectModule
  ],
  declarations: [AppPopupChooseBudgetXComponent],
  entryComponents: [AppPopupChooseBudgetXComponent],
  providers: [AppPopupChooseBudgetXService],
  exports: [AppPopupChooseBudgetXComponent]
})
export class AppPopupChooseBudgetXModule {}
