import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { FileObject } from 'src/app/core/components/upload';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Vendor } from '../../core/bean/vendor';
import { VendorEquipmentDocHistory } from '../../core/bean/vendor-equipment-doc-history';
import { VendorEquipmentHistory } from '../../core/bean/vendor-equipment-history';
import { VendorExpertiseCertificateHistory } from '../../core/bean/vendor-expertise-certificate-history';
import { VendorExpertiseHistory } from '../../core/bean/vendor-expertise-history';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { TabModel } from '../../core/model/tab/tab-model';
import { CompanyProfileServie } from '../company-profile/company-profile.service';
import { CompanyProfileDTO } from '../company-profile/dto/company-profile.dto';
import { TabProfileExpertAndEquipmentDTO } from './dto/tab-profile-expert-and-equipment.dto';
import { AppPopupEquipmentComponent } from './popup/app-popup-equipment/app-popup-equipment.component';
import { AppPopupExpertComponent } from './popup/app-popup-expert/app-popup-expert.component';

@Component({
  templateUrl: './tab-profile-expert-and-equipment.component.html'
})
export class TabProfileExpertAndEquipmentComponent extends BaseModuleComponent {
  @ViewChild('vendorExpert') public tableVendorExpertise: AppTableComponent;
  @ViewChild('vendorEquipment') public tableVendorEquipment: AppTableComponent;
  public userId: number;
  public companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public tabProfileExpertAndEquipmentDTO: TabProfileExpertAndEquipmentDTO =
    new TabProfileExpertAndEquipmentDTO();
  public vendor: Vendor = new Vendor();
  public now = new Date().getTime();
  public sessionId = null;
  public vendorExpertisehistory: VendorExpertiseHistory;
  public vendorEquipmentHistory: VendorEquipmentHistory;
  public vendorExpertiseCertificateHistory: VendorExpertiseCertificateHistory;
  public vendorExpertiseCertificateHistoryList: VendorExpertiseCertificateHistory[] =
    [];
  public vendorEquipmentDocHistory: VendorEquipmentDocHistory;
  public vendorEquipmentDocHistoryList: VendorEquipmentDocHistory[] = [];
  public tabExpertAndEquipment: TabModel;
  public dataCV: any;
  public dataDoc: any;
  public isView: boolean;
  public isVendorList = false;
  public fromOutside: string;

  public tableExpertResponse: TableResponseModel<any>;
  public tableEquipmentResponse: TableResponseModel<any>;

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public companyProfileService: CompanyProfileServie
  ) {
    super('tab-profile-expert-and-equipment', translate);
  }

  public onInit(): void {
    this.getDataFromRouterParam();
    if (
      this.tabExpertAndEquipment.routerParams.get(
        'tabProfileExpertAndEquipmentDTO'
      )
    ) {
      this.tabProfileExpertAndEquipmentDTO =
        this.tabExpertAndEquipment.routerParams.get(
          'tabProfileExpertAndEquipmentDTO'
        );
      this.buildTableResponse();
      this.getAndSetVendorToFormGroup();
    } else {
      this.getDataFromServer();
    }
  }

  public getDataFromServer(): void {
    this.httpClientService
      .get<TabProfileExpertAndEquipmentDTO>(
        this.global.userSession.activeUserRole.role.type !==
          this.global.appConstant.core.ROLE_TYPE_VENDOR
          ? '/tab-profile-expert-and-equipment/index?id=' +
              this.companyProfileDTO.vendorHistory.id +
              '&todo=' +
              this.todo
          : '/tab-profile-expert-and-equipment/index'
      )
      .subscribe(tabExpertAndEquip => {
        this.tabProfileExpertAndEquipmentDTO = tabExpertAndEquip;
        this.tabExpertAndEquipment.routerParams.set(
          'tabProfileExpertAndEquipmentDTO',
          tabExpertAndEquip
        );
        this.buildTableResponse();
        this.getAndSetVendorToFormGroup();
      });
  }

  public buildTableResponse(): void {
    if (
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR &&
      !this.isVendorList &&
      this.companyProfileDTO.vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE
    ) {
      this.tableExpertResponse = new TableResponseModel(this.moduleCode, [
        { field: 'name', header: 'table.column.name' },
        {
          field: 'birthDate',
          header: 'table.column.DOB',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'profession.name',
          header: 'table.column.profession'
        },
        {
          field: 'education.name',
          header: 'table.column.education'
        },
        {
          field: 'crudInfo',
          header: 'table.column.status',
          format: FieldFormatEnum.Html,
          customClass: 'text-center'
        }
      ]);

      this.tableEquipmentResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'equipmentType.name',
          header: 'table.column.type'
        },
        {
          field: 'quantity',
          header: 'table.column.quantity',
          customClass: 'text-right'
        },
        {
          field: 'capacityUom',
          header: 'table.column.capacity'
        },
        { field: 'brand', header: 'table.column.brand' },
        {
          field: 'equipmentCondition.name',
          header: 'table.column.condition'
        },
        { field: 'regionNameList', header: 'table.column.location' },
        {
          field: 'resourceOwnership.name',
          header: 'table.column.ownership'
        },
        {
          field: 'crudInfo',
          header: 'table.column.status',
          format: FieldFormatEnum.Html,
          customClass: 'text-center'
        }
      ]);
    } else {
      this.tableExpertResponse = new TableResponseModel(this.moduleCode, [
        { field: 'name', header: 'table.column.name' },
        {
          field: 'birthDate',
          header: 'table.column.DOB',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'profession.name',
          header: 'table.column.profession'
        },
        {
          field: 'education.name',
          header: 'table.column.education'
        }
      ]);

      this.tableEquipmentResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'equipmentType.name',
          header: 'table.column.type'
        },
        {
          field: 'quantity',
          header: 'table.column.quantity',
          customClass: 'text-right'
        },
        {
          field: 'capacityUom',
          header: 'table.column.capacity'
        },
        { field: 'brand', header: 'table.column.brand' },
        {
          field: 'equipmentCondition.name',
          header: 'table.column.condition'
        },
        { field: 'regionNameList', header: 'table.column.location' },
        {
          field: 'resourceOwnership.name',
          header: 'table.column.ownership'
        }
      ]);
    }
    this.tableExpertResponse.setRecords(
      this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList
    );
    this.tableExpertResponse.setTotalRecords(
      this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList.length
    );

    this.tableEquipmentResponse.setRecords(
      this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList
    );
    this.tableEquipmentResponse.setTotalRecords(
      this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList.length
    );
  }

  public doAddExpert(): void {
    const todo = 'add';
    this.appPopupService
      .open(
        AppPopupExpertComponent,
        { todo },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(expertData => {
        this.vendorExpertisehistory = new VendorExpertiseHistory();
        this.vendorExpertisehistory.birthDate = expertData.expert.birthDate;
        this.vendorExpertisehistory.education =
          expertData.educationInfo.education;
        this.vendorExpertisehistory.endDate =
          expertData.expert.employmentPeriode.endDate;
        this.vendorExpertisehistory.endYear =
          expertData.educationInfo.year.endYear !== ''
            ? expertData.educationInfo.year.endYear
            : null;
        this.vendorExpertisehistory.experience = expertData.expert.experience;
        this.vendorExpertisehistory.institution =
          expertData.educationInfo.institution;
        this.vendorExpertisehistory.name = expertData.expert.name;
        this.vendorExpertisehistory.profession = expertData.expert.profession;
        this.vendorExpertisehistory.startDate =
          expertData.expert.employmentPeriode.startDate;
        this.vendorExpertisehistory.startYear =
          expertData.educationInfo.year.startYear !== ''
            ? expertData.educationInfo.year.startYear
            : null;
        this.vendorExpertisehistory.vendorExpertiseId =
          expertData.vendorExpertiseId;
        const fileCV = expertData.educationInfo.fileCV;
        if (fileCV !== null && fileCV.length !== 0) {
          const fileObjectList: FileObject[] = [];
          fileObjectList.push(expertData.educationInfo.fileCV[0]);
          const dataCV = fileObjectList;
          this.vendorExpertisehistory.file = fileCV[0].isDeleted
            ? null
            : expertData.educationInfo.fileCV[0].file;
          this.vendorExpertisehistory.fileObjectList = !fileCV[0].isDeleted
            ? dataCV
            : expertData.educationInfo.fileCV[0].file.id
            ? dataCV
            : [];
        } else {
          this.vendorExpertisehistory.file = null;
          this.vendorExpertisehistory.fileObjectList = [];
        }
        this.vendorExpertiseCertificateHistoryList = [];
        expertData.certificationList.forEach(element => {
          this.vendorExpertiseCertificateHistory =
            new VendorExpertiseCertificateHistory();
          this.vendorExpertiseCertificateHistory.certificateNumber =
            element.certification;
          this.vendorExpertiseCertificateHistory.startDate =
            element.validationDate.startDate;
          this.vendorExpertiseCertificateHistory.endDate =
            element.validationDate.endDate;
          this.vendorExpertiseCertificateHistory.vendorExpertiseCertificateId =
            element.vendorExpertiseCertificateId;
          const file = element.fileCertificate;
          if (file !== null && file.length !== 0) {
            const fileObjectList: FileObject[] = [];
            fileObjectList.push(element.fileCertificate[0]);
            const dataCertificate = fileObjectList;
            this.vendorExpertiseCertificateHistory.file = file[0].isDeleted
              ? null
              : element.fileCertificate[0].file;
            this.vendorExpertiseCertificateHistory.fileObjectList = !file[0]
              .isDeleted
              ? dataCertificate
              : file[0].file.id
              ? dataCertificate
              : [];
          } else {
            this.vendorExpertiseCertificateHistory.file = null;
            this.vendorExpertiseCertificateHistory.fileObjectList = [];
          }
          this.vendorExpertiseCertificateHistoryList.push(
            this.vendorExpertiseCertificateHistory
          );
        });
        this.vendorExpertisehistory.vendorExpertiseCertificateHistoryList =
          this.vendorExpertiseCertificateHistoryList;
        this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList.push(
          this.vendorExpertisehistory
        );
        this.tableExpertResponse.setRecords(
          this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList
        );
        this.tableExpertResponse.setTotalRecords(
          this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList.length
        );
        this.tableExpertResponse.reloadClient();
      });
  }

  public doEditExpert(
    vendorExpertiseHistoryEdit: VendorExpertiseHistory
  ): void {
    const todo = 'edit';
    this.appPopupService
      .open(
        AppPopupExpertComponent,
        { todo, vendorExpertiseHistoryEdit },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(expertData => {
        this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList.forEach(
          (vendorExpertiseHistory, index) => {
            if (vendorExpertiseHistory === vendorExpertiseHistoryEdit) {
              this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                index
              ].birthDate = expertData.expert.birthDate;
              this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                index
              ].education = expertData.educationInfo.education;
              this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                index
              ].endDate = expertData.expert.employmentPeriode.endDate;
              this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                index
              ].endYear =
                expertData.educationInfo.year.endYear !== ''
                  ? expertData.educationInfo.year.endYear
                  : null;
              this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                index
              ].experience = expertData.expert.experience;
              this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                index
              ].institution = expertData.educationInfo.institution;
              this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                index
              ].name = expertData.expert.name;
              this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                index
              ].profession = expertData.expert.profession;
              this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                index
              ].startDate = expertData.expert.employmentPeriode.startDate;
              this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                index
              ].startYear =
                expertData.educationInfo.year.startYear !== ''
                  ? expertData.educationInfo.year.startYear
                  : null;
              this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                index
              ].vendorExpertiseId = expertData.vendorExpertiseId;
              const fileCV = expertData.educationInfo.fileCV;
              if (fileCV !== null && fileCV.length !== 0) {
                const fileObjectList: FileObject[] = [];
                fileObjectList.push(expertData.educationInfo.fileCV[0]);
                const dataCV = fileObjectList;
                this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                  index
                ].file =
                  fileCV.length !== undefined
                    ? fileCV[0].isDeleted
                      ? null
                      : expertData.educationInfo.fileCV[0].file
                    : expertData.educationInfo.fileCV;
                this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                  index
                ].fileObjectList =
                  fileCV.length !== undefined
                    ? !fileCV[0].isDeleted
                      ? dataCV
                      : fileCV[0].file.id
                      ? dataCV
                      : []
                    : expertData.educationInfo.fileUploader
                    ? expertData.educationInfo.fileUploader.fileObjectList
                    : [];
              } else {
                this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                  index
                ].file = null;
                this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                  index
                ].fileObjectList = [];
              }
              this.vendorExpertiseCertificateHistoryList = [];
              expertData.certificationList.forEach(element => {
                this.vendorExpertiseCertificateHistory =
                  new VendorExpertiseCertificateHistory();
                const file = element.fileCertificate;
                if (file !== null && file.length !== 0) {
                  const fileObjectList: FileObject[] = [];
                  fileObjectList.push(element.fileCertificate[0]);
                  const dataCertificate = fileObjectList;
                  this.vendorExpertiseCertificateHistory.file =
                    file.length !== undefined
                      ? file[0].isDeleted
                        ? null
                        : element.fileCertificate[0].file
                      : element.fileCertificate;
                  this.vendorExpertiseCertificateHistory.fileObjectList =
                    file.length !== undefined
                      ? !file[0].isDeleted
                        ? dataCertificate
                        : file[0].file.id
                        ? dataCertificate
                        : []
                      : element.fileUploader
                      ? element.fileUploader.fileObjectList
                      : [];
                } else {
                  this.vendorExpertiseCertificateHistory.file = null;
                  this.vendorExpertiseCertificateHistory.fileObjectList = [];
                }
                this.vendorExpertiseCertificateHistory.certificateNumber =
                  element.certification;
                this.vendorExpertiseCertificateHistory.startDate =
                  element.validationDate.startDate;
                this.vendorExpertiseCertificateHistory.endDate =
                  element.validationDate.endDate;
                this.vendorExpertiseCertificateHistory.vendorExpertiseCertificateId =
                  element.vendorExpertiseCertificateId;
                this.vendorExpertiseCertificateHistoryList.push(
                  this.vendorExpertiseCertificateHistory
                );
              });
              this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList[
                index
              ].vendorExpertiseCertificateHistoryList =
                this.vendorExpertiseCertificateHistoryList;
            }
          }
        );
        this.tableExpertResponse.setRecords(
          this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList
        );
        this.tableExpertResponse.setTotalRecords(
          this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList.length
        );
        this.tableExpertResponse.reloadClient();
      });
  }

  public doViewExpert(
    vendorExpertiseHistoryEdit: VendorExpertiseHistory
  ): void {
    const todo = 'detail';
    const vendorExpertiseHistoryList =
      this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList;
    const vendorExpertiseList =
      this.tabProfileExpertAndEquipmentDTO.vendorExpertiseList;
    this.appPopupService.open(
      AppPopupExpertComponent,
      {
        todo,
        vendorExpertiseHistoryEdit,
        vendorExpertiseHistoryList,
        vendorExpertiseList
      },
      { size: 'lg' }
    );
  }

  public doDeleteExpert(
    vendorExpertiseHistoryDelete: VendorExpertiseHistory
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList.forEach(
            (vendorExpertiseHistory, index) => {
              if (vendorExpertiseHistory === vendorExpertiseHistoryDelete) {
                this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList.splice(
                  index,
                  1
                );
              }
            }
          );
          this.tableExpertResponse.page.records =
            this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList;
          this.tableExpertResponse.page.totalRecords =
            this.tabProfileExpertAndEquipmentDTO.vendorExpertiseHistoryList.length;
          this.tableExpertResponse.reloadClient();
        }
      });
  }

  public doAddEquipment(): void {
    const todo = 'add';
    this.appPopupService
      .open(
        AppPopupEquipmentComponent,
        { todo },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(equipmentData => {
        this.vendorEquipmentHistory = new VendorEquipmentHistory();
        this.vendorEquipmentHistory.equipmentCondition =
          equipmentData.equipment.equipmentCondition;
        this.vendorEquipmentHistory.equipmentType =
          equipmentData.equipment.equipmentType;
        this.vendorEquipmentHistory.vendorEquipmentId =
          equipmentData.vendorEquipmentId;
        this.vendorEquipmentHistory.regionNameList = '';
        this.vendorEquipmentHistory.regionList = equipmentData.equipment.region;
        if (equipmentData.equipment.region.length !== 0) {
          equipmentData.equipment.region.forEach((region, index) => {
            if (index !== equipmentData.equipment.region.length - 1) {
              this.vendorEquipmentHistory.regionNameList =
                this.vendorEquipmentHistory.regionNameList + region.name + ', ';
            } else {
              this.vendorEquipmentHistory.regionNameList =
                this.vendorEquipmentHistory.regionNameList + region.name;
              this.vendorEquipmentHistory.region = region;
            }
          });
        } else {
          this.vendorEquipmentHistory.regionNameList = '-';
        }
        this.vendorEquipmentHistory.resourceOwnership =
          equipmentData.equipment.resourceOwnership;
        this.vendorEquipmentHistory.uom =
          equipmentData.equipment.capacities.uom;
        this.vendorEquipmentHistory.capacity =
          equipmentData.equipment.capacities.capacity;
        this.vendorEquipmentHistory.capacityUom =
          equipmentData.equipment.capacities.capacity +
          ' ' +
          equipmentData.equipment.capacities.uom.name;
        this.vendorEquipmentHistory.quantity = equipmentData.equipment.quantity;
        this.vendorEquipmentHistory.brand = equipmentData.equipment.brand;
        this.vendorEquipmentHistory.year = equipmentData.equipment.year;
        this.vendorEquipmentDocHistoryList = [];
        equipmentData.ownershipDocumentList.forEach(doc => {
          this.vendorEquipmentDocHistory = new VendorEquipmentDocHistory();
          this.vendorEquipmentDocHistory = doc;
          this.vendorEquipmentDocHistory.endDate = doc.validationDate.endDate;
          this.vendorEquipmentDocHistory.startDate =
            doc.validationDate.startDate;
          this.vendorEquipmentDocHistory.vendorEquipmentDocId =
            doc.vendorEquipmentDocId;
          const file = doc.fileDocument;
          if (file !== null && file.length !== 0) {
            const fileObjectList: FileObject[] = [];
            fileObjectList.push(doc.fileDocument[0]);
            const dataDocument = fileObjectList;
            this.vendorEquipmentDocHistory.file = file[0].isDeleted
              ? null
              : doc.fileDocument[0].file;
            this.vendorEquipmentDocHistory.fileObjectList = !file[0].isDeleted
              ? dataDocument
              : file[0].file.id
              ? dataDocument
              : [];
          } else {
            this.vendorEquipmentDocHistory.file = null;
            this.vendorEquipmentDocHistory.fileObjectList = [];
          }
          this.vendorEquipmentDocHistoryList.push(
            this.vendorEquipmentDocHistory
          );
        });
        this.vendorEquipmentHistory.vendorEquipmentDocHistoryList =
          this.vendorEquipmentDocHistoryList;
        this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList.push(
          this.vendorEquipmentHistory
        );

        this.tableEquipmentResponse.setRecords(
          this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList
        );
        this.tableEquipmentResponse.setTotalRecords(
          this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList.length
        );
        this.tableEquipmentResponse.reloadClient();
      });
  }

  public doEditEquipment(
    vendorEquipmentHistoryEdit: VendorEquipmentHistory
  ): void {
    const todo = 'edit';
    this.appPopupService
      .open(
        AppPopupEquipmentComponent,
        { todo, vendorEquipmentHistoryEdit },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(equipmentEdit => {
        this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList.forEach(
          (vendorEquipmentHistory, index) => {
            if (vendorEquipmentHistory === vendorEquipmentHistoryEdit) {
              this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                index
              ].brand = equipmentEdit.equipment.brand;
              this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                index
              ].capacity = equipmentEdit.equipment.capacities.capacity;
              this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                index
              ].equipmentCondition = equipmentEdit.equipment.equipmentCondition;
              this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                index
              ].equipmentType = equipmentEdit.equipment.equipmentType;
              this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                index
              ].quantity = equipmentEdit.equipment.quantity;
              this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                index
              ].vendorEquipmentId = equipmentEdit.vendorEquipmentId;
              this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                index
              ].regionNameList = '';
              this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                index
              ].regionList = equipmentEdit.equipment.region;
              if (equipmentEdit.equipment.region !== null) {
                if (equipmentEdit.equipment.region.length !== 0) {
                  equipmentEdit.equipment.region.forEach((region, index1) => {
                    if (index1 !== equipmentEdit.equipment.region.length - 1) {
                      // tslint:disable-next-line: max-line-length
                      this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                        index
                      ].regionNameList =
                        this.tabProfileExpertAndEquipmentDTO
                          .vendorEquipmentHistoryList[index].regionNameList +
                        region.name +
                        ', ';
                    } else {
                      // tslint:disable-next-line: max-line-length
                      this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                        index
                      ].regionNameList =
                        this.tabProfileExpertAndEquipmentDTO
                          .vendorEquipmentHistoryList[index].regionNameList +
                        region.name;
                      this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                        index
                      ].region = region;
                    }
                  });
                } else {
                  this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                    index
                  ].regionNameList = '-';
                }
              }
              this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                index
              ].resourceOwnership = equipmentEdit.equipment.resourceOwnership;
              this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                index
              ].uom = equipmentEdit.equipment.capacities.uom;
              this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                index
              ].capacityUom =
                equipmentEdit.equipment.capacities.capacity +
                ' ' +
                equipmentEdit.equipment.capacities.uom.name;
              this.vendorEquipmentDocHistoryList = [];
              equipmentEdit.ownershipDocumentList.forEach(doc => {
                this.vendorEquipmentDocHistory =
                  new VendorEquipmentDocHistory();
                this.vendorEquipmentDocHistory = doc;
                this.vendorEquipmentDocHistory.endDate =
                  doc.validationDate.endDate;
                this.vendorEquipmentDocHistory.startDate =
                  doc.validationDate.startDate;
                this.vendorEquipmentDocHistory.vendorEquipmentDocId =
                  doc.vendorEquipmentDocId;
                const file = doc.fileDocument;
                if (file !== null && file.length !== 0) {
                  const fileObjectList: FileObject[] = [];
                  fileObjectList.push(doc.fileDocument[0]);
                  const dataDocument = fileObjectList;
                  this.vendorEquipmentDocHistory.file =
                    file.length !== undefined
                      ? file[0].isDeleted
                        ? null
                        : doc.fileDocument[0].file
                      : doc.fileDocument;
                  this.vendorEquipmentDocHistory.fileObjectList =
                    file.length !== undefined
                      ? !file[0].isDeleted
                        ? dataDocument
                        : file[0].file.id
                        ? dataDocument
                        : []
                      : doc.fileUploader
                      ? doc.fileUploader.fileObjectList
                      : [];
                } else {
                  this.vendorEquipmentDocHistory.file = null;
                  this.vendorEquipmentDocHistory.fileObjectList = [];
                }
                this.vendorEquipmentDocHistoryList.push(
                  this.vendorEquipmentDocHistory
                );
              });
              this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                index
              ].vendorEquipmentDocHistoryList =
                this.vendorEquipmentDocHistoryList;
              this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList[
                index
              ].year = equipmentEdit.equipment.year;
            }
          }
        );
        this.tableEquipmentResponse.page.records =
          this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList;
        this.tableEquipmentResponse.setTotalRecords(
          this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList.length
        );
        this.tableEquipmentResponse.reloadClient();
      });
  }

  public doViewEquipment(
    vendorEquipmentHistoryEdit: VendorEquipmentHistory
  ): void {
    const todo = 'detail';
    const vendorEquipmentHistoryList =
      this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList;
    const vendorEquipmentList =
      this.tabProfileExpertAndEquipmentDTO.vendorEquipmentList;
    this.appPopupService.open(
      AppPopupEquipmentComponent,
      {
        todo,
        vendorEquipmentHistoryEdit,
        vendorEquipmentHistoryList,
        vendorEquipmentList
      },
      { size: 'lg' }
    );
  }

  public doDeleteEquipment(
    vendorEquipmentHistoryDelete: VendorEquipmentHistory
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList.forEach(
            (vendorEquipmentHistory, index) => {
              if (vendorEquipmentHistory === vendorEquipmentHistoryDelete) {
                this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList.splice(
                  index,
                  1
                );
              }
            }
          );
          this.tableEquipmentResponse.page.records =
            this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList;
          this.tableEquipmentResponse.page.totalRecords =
            this.tabProfileExpertAndEquipmentDTO.vendorEquipmentHistoryList.length;
          this.tableEquipmentResponse.reloadClient();
        }
      });
  }

  public getDataFromRouterParam(): void {
    this.tabExpertAndEquipment = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_EXPERTISE_EQUIPMENT);
    this.isView = this.companyProfileService.getTabResponse().isView;
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.todo = this.global.routerParams.get('todo');
    this.companyProfileDTO = this.global.routerParams.get('companyProfileDTO');
  }

  public getAndSetVendorToFormGroup(): void {
    this.tabExpertAndEquipment.formGroup.patchValue(this.companyProfileDTO);
    this.setStateReady();
  }
}
