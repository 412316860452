<div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-card header="distribution-region.title" detail="app.action.{{ todo }}"
        [isLoading]="formLoading" [isSaving]="formSaving">
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{ 'distribution-region.code' |
              translate }} </label>
            <div class="col-sm-9 text-left">
              <app-text-field autofocus="true" formControlName="code"
                type="code"
                maxLength="64"> </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{ 'distribution-region.name' |
              translate }} </label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="name" type="text"
                maxLength="128"></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{ 'distribution-region.region' |
              translate }}</label>
            <div class="col-sm-9 text-left">

            <app-dropdown-select
            [optionList]="regionOptionList"
            formControlName="regionList"
            type="AUTOCOMPLETE"
            [isMultiple]="true"
          >
          <ng-template #option let-option>
            {{ option.name }}
          </ng-template>
          <ng-template #value let-value>
            {{ value.name }}
          </ng-template>
          </app-dropdown-select>
            </div>
          </div>
          <div class="row justify-content-center mt-5">
            <app-button color="SECONDARY" class="mr-1" (onClick)="doBack()">
              {{ 'app.button.back' | translate }}
            </app-button>
            <app-button color="PRIMARY" (onClick)="doSave()">
              {{ 'app.button.save' | translate }}
            </app-button>
          </div>
        </form>
      </app-card>
    </div>
  </div>