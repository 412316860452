import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseModuleComponent } from '../../base/angular/base-module.component';
import { AddressBook } from '../../bean/address-book';
import { Currency } from '../../bean/currency';
import { OrderStatus } from '../../bean/order-status';
import { OrderType } from '../../bean/order-type';
import { ReportRecap } from '../../bean/report-recap';
import { ReportRecapColumn } from '../../bean/report-recap-column';
import { ReportRecapFilter } from '../../bean/report-recap-filter';
import { ReportFilterGroup } from '../../bean/report-recap-filter-group';
import { AppPopupProgressBarDownloadComponent } from '../../components/app-popup/app-popup-progress-bar-download/app-popup-progress-bar-download.component';
import { ProgressBarDownloadModel } from '../../components/app-popup/app-popup-progress-bar-download/progress-bar-download-model';
import { AppPopupService } from '../../components/app-popup/app-popup.service';
import { ToastService } from '../../components/app-toast/app-toast.service';
import { TableColumnModel } from '../../components/table/model/table-column-model';
import { TableResponseModel } from '../../components/table/model/table-response-model';
import { OptionListModel } from '../../model/option-list-model';
import { RecapPrintService } from '../../services/recap-print.service';
import { OrderProcessRecapView } from '../../view/entity/bean/order-process-recap-view';
import { ReportRecapOrderProcessRequest } from './report-recap-order-proces-request';
import { ReportRecapOrderProcessResponse } from './report-recap-order-proces-response';
import { ReportRecapOrderProcessPopupChooseColumnComponent } from './report-recap-order-process-popup-choose-column.component';
import { ReportRecapOrderProcessPopupFullViewComponent } from './report-recap-order-process-popup-full-view.component';

@Component({
  templateUrl: './report-recap-order-process.component.html'
})
export class ReportRecapOrderProcessComponent extends BaseModuleComponent {
  public reportRecapId: number;
  public reportBuilderId: number;
  public reportRecapOrderProcessResponse: ReportRecapOrderProcessResponse =
    new ReportRecapOrderProcessResponse();
  public reportRecapColumnList: ReportRecapColumn[];
  public reportRecapFilterList: ReportRecapFilter[];
  public reportFilterGroupList: ReportFilterGroup[];
  public reportRecap: ReportRecap;
  public tableResponse: TableResponseModel<OrderProcessRecapView>;
  public orderTypeOptionList: OptionListModel<OrderType> = new OptionListModel(
    true
  );
  public orderStatusOptionList: OptionListModel<OrderStatus> =
    new OptionListModel(true);
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true,
    'code'
  );
  public shippingAddressOptionList: OptionListModel<AddressBook> =
    new OptionListModel(true, 'address');
  public progressBarDownload = new ProgressBarDownloadModel();
  public httpRequest: Subscription;
  public generatedTableResponse: string;

  public isShowPopupModifyTable = false;
  public isFullViewTable = false;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public recapPrintService: RecapPrintService,
    public toastService: ToastService
  ) {
    super('report-recap-order-process', translateService);
  }
  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setInitializationState();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      orderDate: [null],
      orderDateTo: [null],
      orderDateFrom: [null],
      orderRequireDate: [null],
      orderRequireDateTo: [null],
      orderRequireDateFrom: [null],
      minimumAmount: [null],
      maximumAmount: [null],
      minOrderCost: [null],
      maxOrderCost: [null],
      organizationList: [null],
      orderTypeList: [null],
      orderStatusList: [null],
      shippingAddressList: [null]
    });
  }

  public buildTableResponse(): void {
    const arrayColumn: Array<TableColumnModel> = this.generatedTableResponse
      ? JSON.parse(this.generatedTableResponse)
      : null;
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      this.reportBuilderId
        ? arrayColumn
        : [
            {
              field: 'code',
              header: 'table.column.orderNumber'
            },
            {
              field: 'postDate',
              header: 'table.column.orderDate',
              plugins: {
                name: 'date',
                format: 'short-date'
              }
            },
            {
              field: 'requiredDate',
              header: 'table.column.orderRequireDate',
              plugins: {
                name: 'date',
                format: 'short-date'
              }
            },
            {
              field: 'title',
              header: 'table.column.title'
            },
            {
              field: 'organization.name',
              header: 'table.column.department'
            },
            {
              field: 'orderType.name',
              header: 'table.column.orderType'
            },
            {
              field: 'description',
              header: 'table.column.description'
            },
            {
              field: 'amount',
              header: 'table.column.orderCost',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: ' text-right'
            },
            {
              field: 'vendor.name',
              header: 'table.column.vendor'
            },
            {
              field: 'confirmationDate',
              header: 'table.column.confirmationDate',
              plugins: {
                name: 'date',
                format: 'short-date'
              }
            },
            {
              field: 'orderStatus.name',
              header: 'table.column.status',
              plugins: {
                name: 'badge',
                pill: true,
                colorField: 'orderStatus.code',
                colorMap: {
                  DRAFT: 'SECONDARY',
                  REVISION: 'FEEDBACK',
                  WAITING_APPROVAL: 'WARNING',
                  WAITING_CONFIRMATION: 'WARNING',
                  ON_PROGRESS: 'PROGRESS',
                  VENDOR_REVISION: 'FEEDBACK',
                  REJECTED: 'DANGER',
                  CANCELED: 'DANGER',
                  CLOSED: 'DANGER',
                  DONE: 'SUCCESS'
                }
              }
            },
            {
              field: 'lastDateStatus',
              header: 'table.column.lastDateStatus',
              plugins: {
                name: 'date',
                format: 'short-date'
              }
            },
            {
              field: 'creator',
              header: 'table.column.creator'
            },
            {
              field: 'approval',
              header: 'table.column.approval'
            },
            {
              field: 'approvalDate',
              header: 'table.column.approvalDate',
              plugins: {
                name: 'date',
                format: 'short-date'
              }
            },
            {
              field: 'item',
              header: 'table.column.item'
            },
            {
              field: 'shippingAddress',
              header: 'table.column.shippingAddress'
            },
            {
              field: 'totalStage',
              header: 'table.column.totalStage',
              className: 'text-right'
            },
            {
              field: 'totalTermin',
              header: 'table.column.totalTermin',
              className: 'text-right'
            },
            {
              field: 'totalSla',
              header: 'table.column.totalSla',
              className: 'text-right'
            }
          ]
    );
  }

  public setInitializationState(): void {
    this.httpClientService
      .get<ReportRecapOrderProcessResponse>(
        '/report-recap-order-process/edit?reportRecapId=' +
          this.reportRecapId +
          (this.reportBuilderId
            ? '&reportBuilderId=' + this.reportBuilderId
            : '')
      )
      .subscribe((response: ReportRecapOrderProcessResponse) => {
        this.reportRecapOrderProcessResponse = response;
        this.reportRecapColumnList = response.reportRecapColumnList;
        this.reportRecapFilterList = response.reportRecapFilterList;
        this.reportFilterGroupList = response.reportFilterGroupList;
        this.reportRecap = response.reportRecap;
        this.orderTypeOptionList.setRequestValues(response.orderTypeList);
        this.orderStatusOptionList.setRequestValues(response.orderStatusList);
        this.currencyOptionList.setRequestValues(response.currencyList);
        this.shippingAddressOptionList.setRequestValues(
          response.shippingAddressList
        );
        this.setStateReady();
      });
  }

  public setDataFromRouterParams(): void {
    this.reportBuilderId = this.global.routerParams.get('reportBuilderId');
    this.reportRecapId = this.global.routerParams.get('reportRecapId');
    this.generatedTableResponse = this.global.routerParams.get(
      'generatedTableResponse'
    );
  }

  public doGenerate(): void {
    this.formGroup.patchValue({
      orderDateFrom: this.formGroup.value.orderDate?.from,
      orderDateTo: this.formGroup.value.orderDate?.to,
      orderRequireDateFrom: this.formGroup.value.orderRequireDate?.from,
      orderRequireDateTo: this.formGroup.value.orderRequireDate?.to,
      minOrderCost: this.formGroup.value.minimumAmount?.price,
      maxOrderCost: this.formGroup.value.maximumAmount?.price
    });
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.setCustomData(null);
    this.tableResponse.reload();
  }

  public doExport(): void {
    if (this.reportBuilderId) {
      this.appPopupService
        .open(AppPopupProgressBarDownloadComponent, {
          model: this.progressBarDownload
        })
        .subscribe(isCancel => {
          if (isCancel) {
            this.httpClientService.unsubscribe();
          }
        });
      const request = new ReportRecapOrderProcessRequest();
      request.reportRecapColumnList = this.reportRecapColumnList;
      request.customData = JSON.stringify(this.tableResponse.getCustomData());

      this.recapPrintService
        .print('/report-recap-order-process/print', request)
        .subscribe(
          response => {
            if (response) {
              this.progressBarDownload.setProgress(100);
              setTimeout(() => {
                this.global.modalService.downloadSuccess();
              }, 500);
            }
          },
          error => {
            error.error.text().then(text => this.toastService.showError(text));
          }
        );
    } else {
      this.appPopupService.open(
        ReportRecapOrderProcessPopupChooseColumnComponent,
        {
          customData: this.tableResponse.getCustomData(),
          reportRecapColumnList: this.reportRecapColumnList
        }
      );
    }
  }

  public doFullView(): void {
    this.appPopupService.open(
      ReportRecapOrderProcessPopupFullViewComponent,
      {
        customData: this.tableResponse.getCustomData(),
        reportRecapColumnList: this.reportRecapColumnList,
        reportBuilderId: this.reportBuilderId
      },
      { size: 'xl' }
    );
  }

  public doCustomSetting(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('reportBuilderId', this.reportBuilderId);
    this.router.navigate(['/pages/report-recap/edit']);
  }
}
