import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';
import { BuildingLocation } from '../../../core/bean/building-location';
import { BuildingType } from '../../../core/bean/building-type';
import { Region } from '../../../core/bean/region';
import { ResourceOwnership } from '../../../core/bean/resource-ownership';
import { VendorAddress } from '../../../core/bean/vendor-address';
import { VendorAddressHistory } from '../../../core/bean/vendor-address-history';
import { OptionListModel } from '../../../core/model/option-list-model';
import { Validators } from '../../../core/validators';
import { VendorPopupVendorAddressDTO } from '../dto/vendor-popup-vendor-address.dto';
import { AppPopupVendorAddressAlertComponent } from './app-popup-vendor-address-alert.component';

@Component({
  templateUrl: './app-popup-vendor-address.component.html'
})
export class AppPopupVendorAddressComponent extends BaseComponentComponent {
  public readonly urlVendorAddressPopup =
    '/tab-profile-company-data/add-edit-popup-vendor-address';

  @Input() public model: any;
  @Input() public vendorAddressHistoryEdit: VendorAddressHistory;
  @Input() public todo: string;
  @Input() public vendorAddressHistoryList: VendorAddressHistory[];
  @Input() public vendorAddressList: VendorAddress[];
  @Input() public vendorAddress: VendorAddress;
  @Input() public vendorId: number;
  @Input() public vendorTypeCode: string;
  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();

  public vendorPopupVendorAddressDTO: VendorPopupVendorAddressDTO =
    new VendorPopupVendorAddressDTO();
  public isPhoneExist = false;
  public phoneNumber: string;
  public isVendorList: boolean;
  public fromOutside: string;
  public isMainAddressExist: boolean;
  public faxNumber: string;
  public faxNumberHistory: string;
  public size: string;
  public sizeHistory: string;

  public buildingTypeOptionList: OptionListModel<BuildingType> =
    new OptionListModel(true);
  public resourceOwnershipOptionList: OptionListModel<ResourceOwnership> =
    new OptionListModel(true);
  public buildingLocationOptionList: OptionListModel<BuildingLocation> =
    new OptionListModel(true);

  public countryRegionOptionList: OptionListModel<Region> =
    new OptionListModel();
  public provinceRegionOptionList: OptionListModel<Region> =
    new OptionListModel();
  public cityRegionOptionList: OptionListModel<Region> = new OptionListModel();
  public districtRegionOptionList: OptionListModel<Region> =
    new OptionListModel();
  public regionPhoneOptionList: OptionListModel<Region> = new OptionListModel();

  constructor(
    public appPopupService: AppPopupService,
    public activeModal: NgbActiveModal
  ) {
    super('tab-profile-company-data');
  }

  public onInit(): void {
    this.setParam();
    this.getDataVendorAddress();
    this.buildFormGroup();
    this.setFormGroup();
    if (this.todo === 'detail') {
      this.setViewOnly();
    }
  }

  public setParam(): void {
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      buildingName: [null, Validators.required()],
      buildingType: [null, Validators.required()],
      resourceOwnership: [null, Validators.required()],
      buildingLocation: [null, Validators.required()],
      size: [null, Validators.required()],
      address: [null, Validators.required()],
      countryRegion: [null, Validators.required()],
      provinceRegion: [
        null,
        this.vendorTypeCode !==
        this.global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
          ? Validators.required()
          : null
      ],
      cityRegion: [
        null,
        this.vendorTypeCode !==
        this.global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
          ? Validators.required()
          : null
      ],
      districtRegion: [
        null,
        this.vendorTypeCode !==
        this.global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
          ? Validators.required()
          : null
      ],
      postCode: [null, Validators.required()],
      poBox: [null],
      phone: [null, Validators.required()],
      fax: [null],
      coordinateLocation: [null],
      isMain: [null],
      id: [null],
      vendorAddressId: [null],
      indexAddress: [null],
      isAgree: [null]
    });
  }

  public setFormGroup(): void {
    this.getAppPopupVendorAddressByIdFromServer(
      this.urlVendorAddressPopup
    ).subscribe(vendorPopupVendorAddressDTO => {
      this.vendorPopupVendorAddressDTO = vendorPopupVendorAddressDTO;
      this.setOptionListModel();
      if (this.vendorAddressHistoryEdit !== undefined) {
        this.httpClientService
          .post<Region[]>(
            '/company-profile/change-region-list',
            this.vendorAddressHistoryEdit.countryRegion.id
          )
          .subscribe(provinceRegionList => {
            this.provinceRegionOptionList.setRequestValues(provinceRegionList);
          });
        if (this.vendorAddressHistoryEdit.provinceRegion) {
          this.httpClientService
            .post<Region[]>(
              '/company-profile/change-region-list',
              this.vendorAddressHistoryEdit.provinceRegion.id
            )
            .subscribe(cityRegionList => {
              this.cityRegionOptionList.setRequestValues(cityRegionList);
            });
        }
        if (this.vendorAddressHistoryEdit.cityRegion) {
          this.httpClientService
            .post<Region[]>(
              '/company-profile/change-region-list',
              this.vendorAddressHistoryEdit.cityRegion.id
            )
            .subscribe(districtRegionList => {
              this.districtRegionOptionList.setRequestValues(
                districtRegionList
              );
            });
        }
        this.formGroup.patchValue(this.vendorAddressHistoryEdit);
        this.formGroup.patchValue({
          phone: {
            country: this.vendorAddressHistoryEdit.phoneRegion,
            number: this.vendorAddressHistoryEdit.phone
          }
        });
        this.size = this.vendorAddressHistoryEdit.size.toString() + 'm';
        if (this.vendorAddressHistoryEdit.fax !== null) {
          this.formGroup.patchValue({
            fax: {
              country: this.vendorAddressHistoryEdit.faxRegion,
              number: this.vendorAddressHistoryEdit.fax
            }
          });
        }
      }
      this.setStateReady();
    });
  }

  public setOptionListModel(): void {
    this.buildingTypeOptionList.setRequestValues(
      this.vendorPopupVendorAddressDTO.buildingTypeList
    );
    this.resourceOwnershipOptionList.setRequestValues(
      this.vendorPopupVendorAddressDTO.resourceOwnershipList
    );
    this.buildingLocationOptionList.setRequestValues(
      this.vendorPopupVendorAddressDTO.buildingLocationList
    );

    const regionList = this.vendorPopupVendorAddressDTO.countryRegionList;
        const idReg = regionList.find(region => region.code === this.global.appConstant.core.REGION_CODE_INDONESIA);
        const indexIdReg = regionList.findIndex(region => region.code === this.global.appConstant.core.REGION_CODE_INDONESIA);

        if (indexIdReg !== -1) {
          regionList.splice(indexIdReg, 1);
          regionList.unshift(idReg);
        }

    this.countryRegionOptionList.setRequestValues(regionList);
    this.regionPhoneOptionList.stringUrl = '/resource/';
    this.regionPhoneOptionList.fileTypeCode =
      this.global.appConstant.fileType.IMG_ICON;
    this.regionPhoneOptionList.setRequestValues(
      this.vendorPopupVendorAddressDTO.regionPhoneList
    );
  }

  public getDataVendorAddress(): void {
    if (this.vendorAddressList) {
      this.vendorAddressHistoryList.forEach(addressHistory => {
        if (addressHistory === this.vendorAddressHistoryEdit) {
          this.vendorAddressList.forEach(address => {
            if (address.id === addressHistory.vendorAddressId) {
              this.vendorAddress = address;
              if (this.vendorAddress.fax !== null) {
                this.faxNumber =
                  this.vendorAddress.faxRegion.callingCode +
                  this.vendorAddress.fax;
              }
            }
          });
        }
      });
      if (this.vendorAddress === undefined) {
        this.vendorAddress = new VendorAddress();
      }
    }
    if (
      this.vendorAddressHistoryEdit !== undefined &&
      this.vendorAddressHistoryEdit.fax !== null
    ) {
      this.faxNumberHistory =
        this.vendorAddressHistoryEdit.faxRegion.callingCode +
        this.vendorAddressHistoryEdit.fax;
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      if (this.isMainAddressExist) {
        this.appPopupService
          .open(AppPopupVendorAddressAlertComponent, {
            size: 'lg',
            backdrop: 'static'
          })
          .subscribe(agree => {
            this.formGroup.patchValue({ isAgree: agree });
            this.onChange.emit(this.formGroup);
            this.activeModal.dismiss();
          });
      } else {
        this.onChange.emit(this.formGroup);
        this.activeModal.dismiss();
      }
    }
  }

  public getAppPopupVendorAddressByIdFromServer(
    urlVendorAddressPopup: string
  ): Observable<VendorPopupVendorAddressDTO> {
    return this.httpClientService.post<VendorPopupVendorAddressDTO>(
      urlVendorAddressPopup,
      this.vendorAddressHistoryEdit != null
        ? this.vendorAddressHistoryEdit
        : new VendorAddress()
    );
  }

  public doChangeCountryRegion(): void {
    this.formGroupService.bulkResetFormControl(this.formGroup, [
      'provinceRegion',
      'cityRegion',
      'districtRegion'
    ]);
    this.httpClientService
      .post<Region[]>(
        '/company-profile/change-region-list',
        this.formGroup.value.countryRegion.id
      )
      .subscribe(provinceRegionList => {
        this.vendorPopupVendorAddressDTO.provinceRegionList =
          provinceRegionList;
        this.provinceRegionOptionList.setRequestValues(provinceRegionList);
        this.cityRegionOptionList.reset();
        this.districtRegionOptionList.reset();
      });
  }

  public doChangeProvinceRegion(): void {
    this.formGroupService.bulkResetFormControl(this.formGroup, [
      'cityRegion',
      'districtRegion'
    ]);
    this.httpClientService
      .post<Region[]>(
        '/company-profile/change-region-list',
        this.formGroup.value.provinceRegion.id
      )
      .subscribe(cityRegionList => {
        this.vendorPopupVendorAddressDTO.cityRegionList = cityRegionList;
        this.cityRegionOptionList.setRequestValues(cityRegionList);
        this.districtRegionOptionList.reset();
      });
  }

  public doChangeCityRegion(): void {
    this.formGroup.get('districtRegion').reset();
    this.httpClientService
      .post<Region[]>(
        '/company-profile/change-region-list',
        this.formGroup.value.cityRegion.id
      )
      .subscribe(districtRegionList => {
        this.vendorPopupVendorAddressDTO.districtRegionList =
          districtRegionList;
        this.districtRegionOptionList.setRequestValues(districtRegionList);
      });
  }

  public onChangeMainAddress(): void {
    if (this.formGroup.value.isMain) {
      this.vendorAddressHistoryList.forEach((address, index) => {
        if (address.isMain) {
          this.isMainAddressExist = true;
          this.formGroup.patchValue({ indexAddress: index });
        }
      });
    }
  }
}
