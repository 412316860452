import { BaseDTO } from '../../../core/base/base-dto';
import { Catalog } from '../../../core/bean/catalog';
import { CatalogCategory } from '../../../core/bean/catalog-category';
import { CatalogImage } from '../../../core/bean/catalog-image';
import { CatalogInventory } from '../../../core/bean/catalog-inventory';
import { CatalogSpecification } from '../../../core/bean/catalog-specification';
import { CatalogStatus } from '../../../core/bean/catalog-status';
import { CatalogVerification } from '../../../core/bean/catalog-verification';
import { ManageCatalogTab } from '../../../core/bean/manage-catalog-tab';

export class CatalogVerificationDTO extends BaseDTO {
    catalog: Catalog;
    catalogVerificationList: CatalogVerification[] = [];
    catalogCategoryList: CatalogCategory[];
    catalogSpesificationList: CatalogSpecification[];
    catalogImageList: CatalogImage[];
    catalogInventoryList: CatalogInventory[];
    catalogStatusList: CatalogStatus[];
    manageCatalogTabList: ManageCatalogTab[];
}
