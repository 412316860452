import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Procurement } from 'src/app/core/bean/procurement';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { Vendor } from 'src/app/core/bean/vendor';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { ProcurementScopeWork } from '../../core/bean/procurement-scope-work';
import { DeliveryMonitoringResponse } from './response/delivery-monitoring.response';

@Component({
  templateUrl: './delivery-monitoring.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DeliveryMonitoringComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public deliveryMonitoringResponse: DeliveryMonitoringResponse =
    new DeliveryMonitoringResponse();
  public tableResponse: TableResponseModel<ProcurementScopeWork>;
  public tableResponseVendor: TableResponseModel<ProcurementScopeWork>;
  public procurementNumberOptionList: OptionListModel<Procurement> =
    new OptionListModel(true, 'number');
  public procurementNameOptionList: OptionListModel<Procurement> =
    new OptionListModel(true, 'name');
  public vendorOptionList: OptionListModel<Vendor> = new OptionListModel(
    true,
    'name'
  );

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('delivery-monitoring', translateService);
  }

  public onInit(): void {
    this.setOptionList();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      fromDate: [null],
      untilDate: [null],
      vendorList: [null],
      procurementNumberList: [null],
      procurementNameList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<DeliveryMonitoringResponse>('/delivery-monitoring/get-option-list')
      .subscribe((deliveryMonitoringResponse: DeliveryMonitoringResponse) => {
        this.deliveryMonitoringResponse = deliveryMonitoringResponse;
        this.procurementNumberOptionList.setRequestValues(
          deliveryMonitoringResponse.procurementList
        );
        this.procurementNameOptionList.setRequestValues(
          deliveryMonitoringResponse.procurementList
        );
        this.vendorOptionList.setRequestValues(
          deliveryMonitoringResponse.vendorList
        );
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementVendor.procurement.name',
        header: 'table.column.procurementName'
      },
      {
        field: 'procurementVendor.procurement.number',
        header: 'table.column.procurementNumber',
        customClass: 'text-center'
      },
      {
        field: 'procurementVendor.procurement.procurementDate',
        header: 'table.column.procurementDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'procurementVendor.procurement.organization.name',
        header: 'table.column.organization'
      },
      {
        field: 'procurementVendor',
        header: 'table.column.vendorName',
        customClass: 'text-center',
        fn: this.getVendorName.bind(this)
      },
      {
        field: 'contract.contractType.name',
        header: 'table.column.contractType',
        customClass: 'text-center'
      },
      {
        field: 'workPercentageValue',
        header: 'table.column.persentageOfWork',
        customClass: 'text-center',
        fn: this.setPercentageValue.bind(this)
      },
      {
        field: 'deliveryStatus',
        header: 'table.column.status',
        customClass: 'text-center',
        fn: this.handleStatusTextColor.bind(this)
      }
    ]);

    this.tableResponseVendor = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementVendor.procurement.name',
        header: 'table.column.procurementName'
      },
      {
        field: 'procurementVendor.procurement.number',
        header: 'table.column.procurementNumber',
        customClass: 'text-center'
      },
      {
        field: 'procurementVendor.procurement.procurementDate',
        header: 'table.column.procurementDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'contract.contractType.name',
        header: 'table.column.contractType',
        customClass: 'text-center'
      },
      {
        field: 'workPercentageValue',
        header: 'table.column.persentageOfWork',
        customClass: 'text-center',
        fn: this.setPercentageValue.bind(this)
      },
      {
        field: 'deliveryStatus',
        header: 'table.column.status',
        customClass: 'text-center',
        fn: this.handleStatusTextColor.bind(this)
      }
    ]);
  }

  public getVendorName(procurementVendor: ProcurementVendor): string {
    if (procurementVendor.vendor !== null) {
      return procurementVendor.vendor.name;
    } else {
      return procurementVendor.vendorOffline.name;
    }
  }

  public setPercentageValue(total: number): string {
    if (total === null || (total && total === 0)) {
      return '0.00 %';
    } else {
      return total.toString() + ' %';
    }
  }

  public handleStatusTextColor(
    value: string,
    record: ProcurementScopeWork
  ): HTMLElement {
    this.log.debug(value);
    const spanElement = document.createElement('span');
    if (record.deliveryStatus === 1) {
      spanElement.style.color = 'var(--body-foreground)';
      spanElement.textContent = this.global.translateService.instant(
        'delivery-monitoring.new'
      );
    } else if (record.deliveryStatus === 2) {
      spanElement.style.color = 'var(--primary)';
      spanElement.textContent = this.global.translateService.instant(
        'delivery-monitoring.onProgress'
      );
    } else if (record.deliveryStatus === 3) {
      spanElement.style.color = 'var(--success)';
      spanElement.textContent = this.global.translateService.instant(
        'delivery-monitoring.done'
      );
    }
    return spanElement;
  }

  public doApply(): void {
    const procurementList: Procurement[] = [];
    const procurementNumberList = this.formGroup.get('procurementNumberList')
      .value
      ? this.formGroup.get('procurementNumberList').value
      : [];
    procurementNumberList.forEach(procurement => {
      procurementList.push(procurement);
    });
    const procurementNameList = this.formGroup.get('procurementNameList').value
      ? this.formGroup.get('procurementNameList').value
      : [];
    procurementNameList.forEach(procurement => {
      procurementList.push(procurement);
    });
    const contractCustomDataDTO = {
      fromDate: this.formGroup.get('fromDate').value,
      untilDate: this.formGroup.get('untilDate').value,
      vendorList: this.formGroup.get('vendorList').value,
      procurementList
    };
    this.global.routerParams.clear();
    this.tableResponse.setCustomData(contractCustomDataDTO);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }

  public doDetail(procurementScopeWork: ProcurementScopeWork): void {
    if (procurementScopeWork.contract?.isProgressAddendum) {
      this.global.alertService.showInfo(
        this.translateService.instant(
          'delivery-monitoring.contractAddendumInfo'
        )
      );
    } else {
      this.global.routerParams.clear();
      this.global.routerParams.set('todo', 'view');
      this.global.routerParams.set(
        'procurementScopeWork',
        procurementScopeWork
      );
      this.router.navigate(['/pages/delivery-monitoring/detail']);
    }
  }
}
