<app-popup header="rfp.popup.response" [isLoading]="formLoading">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <h4 class="separator">{{ 'rfp.vendorInformation' | translate }} </h4>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{ 'rfp.form.vendorName' | translate }}</label>
      <div class="col-sm-6 text-left">
        {{ rfpVendorView.vendorName }}
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{ 'rfp.form.responseDate' | translate }}</label>
      <div class="col-sm-6 text-left">
        {{
          rfpVendorView.rfpVendor.submittedDate
            | date: global.config.parameterModel.datetimeFormatShort
        }}
      </div>
    </div>
    <h4 class="separator">{{ 'rfp.responseDocument' | translate }} </h4>
    <app-upload-x formControlName="file" [fileUploader]="fileUploader">
    </app-upload-x>
    <br />
    <div *ngIf="isScoring">
      <h4 class="separator">{{ 'rfp.form.proposalCriteria' | translate }} </h4>
      <app-alert-x *ngIf="todo === 'edit'">
        {{ 'rfp.message.info.optional' | translate }}
      </app-alert-x>
      <app-table-xx
        [model]="tableResponseProposalCriteria"
        [isShowSearch]="false"
        [isShowPagination]="false"
        *ngIf="!formLoading"
      >
        <div *ngIf="tableResponseProposalCriteria.getRecordList().length > 0">
          <ng-template #row>
            <tr>
              <td class="text-left">
                {{ 'rfp.totalProposalCriteria' | translate }}
              </td>
              <td></td>
              <td></td>
              <td>
                <div
                  [ngClass]="
                    todo === 'edit'
                      ? 'td-content text-right'
                      : 'td-content text-right'
                  "
                >
                  {{ (totalProposalCriteria | resolveNumberToDecimal) || 0.0 }}
                  <p *ngIf="todo === 'edit'"
                    ><em class="pir pi-info-circle"></em
                    ><small class="cml-1"
                      >Minimum score is
                      {{ rfpVendorView.rfpVendor.rfp.minimumScore }} to
                      passed</small
                    ></p
                  >
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-left">
                {{ 'rfp.totalProposalPoint' | translate }}
              </td>
              <td></td>
              <td></td>
              <td>
                <div
                  [ngClass]="
                    todo === 'edit'
                      ? 'td-content text-right'
                      : 'td-content text-right'
                  "
                >
                  {{ (totalProposalPoint | resolveNumberToDecimal) || 0.0 }}
                </div>
              </td>
            </tr>
          </ng-template>
        </div> </app-table-xx
      ><br />
      <h4 class="separator">{{ 'rfp.form.scoringCriteria' | translate }} </h4>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'rfp.form.minimumScoreOfProposalCriteria' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ rfpVendorView.rfpVendor.rfp.minimumScore }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'rfp.form.proposalPoint' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ rfpVendorView.rfpVendor.rfp.proposalPercentage }} %
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{ 'rfp.form.pricePoint' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{ rfpVendorView.rfpVendor.rfp.pricePercentage }} %
        </div>
      </div>
      <div *ngIf="todo === 'edit'">
        <ng-template #modalFooter let-activeModal>
          <div class="col-12 text-center">
            <app-button
              color="SECONDARY"
              [outline]="true"
              (onClick)="activeModal.close(true)"
              >{{ 'app.button.cancel' | translate }}</app-button
            >
            <app-button color="PRIMARY" (onClick)="doSubmit()">{{
              'app.button.save' | translate
            }}</app-button>
          </div>
        </ng-template>
      </div>
    </div>
  </form>
</app-popup>
