import { BaseEntity } from '../base/base-entity';
import { ApprovalPrcs } from '../bean/approval-prcs';
import { File } from '../bean/file';
import { Position } from '../bean/position';
import { ReviseCondition } from '../bean/revise-condition';
import { User } from '../bean/user';

export class ApprovalModelPrcsEntity extends BaseEntity {
  isViewOnly: boolean;
  slaRealizedName: string; // Transient
  approvalPath: string;
  approvalPrcs: ApprovalPrcs;
  file: File;
  position: Position;
  reviseCondition: ReviseCondition;
  status: number;
  approvedByUser: User;
  note: string;
  sequence: number;
  startDate: Date;
  endDate: Date;
  processedDate: Date;
  slaRealizedInMinutes: number;
  slaRealizedStatus: number;
  slaRealizedWorkingInDays: number;
  slaRealizedWorkingInHours: number;
  slaRealizedWorkingInMinutes: number;
}
