import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Organization } from 'src/app/core/bean/organization';
import { ProcurementPhase } from 'src/app/core/bean/procurement-phase';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ScoringCriteria } from '../../core/bean/scoring-criteria';
import { ScoringCriteriaGroup } from '../../core/bean/scoring-criteria-group';
import { Vendor } from '../../core/bean/vendor';
import { VendorPerformance } from '../../core/bean/vendor-performance';
import { VendorPerformanceScore } from '../../core/bean/vendor-performance-score';
import { VendorPerformanceSummary } from '../../core/bean/vendor-performance-summary';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { PerformanceProcurementDTO } from './dto/performance-procurement.dto';
import { PerformanceProcurementCriteriaModel } from './model/performance-procurement-criteria-model';
@Component({
  templateUrl: './performance-procurement-edit.component.html'
})
export class PerformanceProcurementEditComponent extends BaseModuleComponent {
  public get parentsCommonScoring(): FormArray {
    return this.formGroup.get('parentsCommonScoring') as FormArray;
  }

  public get parentsSpecificScoring(): FormArray {
    return this.formGroup.get('parentsSpecificScoring') as FormArray;
  }

  public get summaryList(): FormArray {
    return this.formGroup.get('summaryList') as FormArray;
  }

  public procurementId: number;
  public vendorId: number;
  public vendorAddress: string;
  public vendor: Vendor = new Vendor();
  public performanceProcurementDTO: PerformanceProcurementDTO =
    new PerformanceProcurementDTO();
  public showScoring = false;
  public totalScoring = 0;
  public isShowHistoryActivity = false;
  public procSubOrganization: Organization = null;

  public fileUploader: FileUploader = new FileUploader(
    '/performance-procurement/',
    'performance-procurement',
    this.global.appConstant.fileType.DOC_PERFORMANCE
  );

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('performance-procurement', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup(this.vendorId, this.procurementId);
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.procurementId = this.global.routerParams.get('procurementId');
    this.vendorId = this.global.routerParams.get('vendorId');
  }

  public showHistoryActivity(): void {
    if (
      this.performanceProcurementDTO.performance &&
      this.performanceProcurementDTO.performance.workflowPrcs &&
      this.performanceProcurementDTO.performance.workflowPrcs.workflowStatus.code
        .toUpperCase()
        .includes('REVISI')
    ) {
      this.isShowHistoryActivity = true;
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      file: [null],
      note: [null],
      parentsCommonScoring: this.formBuilder.array([]),
      parentsSpecificScoring: this.formBuilder.array([]),
      summaryList: this.formBuilder.array([]),
      rating: [null]
    });
  }

  public setFormGroup(vendorId: number, procurementId: number): void {
    this.formGroup.get('rating').setIsView(true);
    this.httpClientService
      .post<PerformanceProcurementDTO>(
        '/performance-procurement/edit',
        JSON.stringify({ vendorId, procurementId })
      )
      .subscribe(performanceProcurementDTO => {
        this.vendor = performanceProcurementDTO.vendor;
        this.vendorAddress = performanceProcurementDTO.vendorAddress.address;

        this.performanceProcurementDTO = performanceProcurementDTO;
        this.procSubOrganization = this.performanceProcurementDTO.procurement
          .procurementSubmission
          ? this.performanceProcurementDTO.procurement.procurementSubmission
              .organization
          : this.performanceProcurementDTO.procurement.organization;

        this.addCredsCommonScoring();
        this.addCredsSpecificScoring();
        this.buildSummaryList();
        this.showHistoryActivity();

        this.setStateReady();

        if (
          this.global.userSession.user.organization.id !==
          this.procSubOrganization.id
        ) {
          this.formGroup.get('file').setIsView(true);
          this.formGroup.get('note').setIsView(true);
        }

        this.log.debug('is same orgz');
        this.log.debug(this.global.userSession.user.organization.id);
        this.log.debug(this.procSubOrganization.id);
      });
  }

  public addCredsCommonScoring(): void {
    this.performanceProcurementDTO.scoringCriteriaGroupListCommon.forEach(
      scoreCriteriaGroup => {
        this.parentsCommonScoring.push(
          this.formBuilder.group({
            name: scoreCriteriaGroup.name, // utk header
            scoringCriteriaList: [scoreCriteriaGroup.scoringCriteriaList],
            scoringCriteriaGroup: scoreCriteriaGroup,
            avgTotal: [null],
            listOfScoringCriteria: this.formBuilder.array([])
          })
        );
      }
    );
  }

  public addCredsSpecificScoring(): void {
    this.performanceProcurementDTO.scoringCriteriaGroupListSpecific.forEach(
      scoreCriteriaGroup => {
        this.parentsSpecificScoring.push(
          this.formBuilder.group({
            name: scoreCriteriaGroup.name, // utk header
            scoringCriteriaList: [scoreCriteriaGroup.scoringCriteriaList],
            scoringCriteriaGroup: scoreCriteriaGroup,
            avgTotal: [null],
            listOfScoringCriteria: this.formBuilder.array([])
          })
        );
      }
    );
  }

  public addScoringCriteria(parent: any): FormArray {
    const listOfScoringCriteria = parent.get(
      'listOfScoringCriteria'
    ) as FormArray;

    if (listOfScoringCriteria.value.length === 0) {
      parent.controls.scoringCriteriaList.value.forEach(scoringCriteria => {
        let minInput = 0;
        let maxInput = 0;

        if (scoringCriteria.scoringCriteriaRangeList.length !== 0) {
          minInput = this.getMaxMinInput(scoringCriteria)[0];
          maxInput = this.getMaxMinInput(scoringCriteria)[1];
        }

        const criteriaModelList: PerformanceProcurementCriteriaModel<ProcurementPhase>[] =
          this.performanceProcurementDTO.criteriaProcurementPhaseModelList.filter(
            criteriaModel =>
              criteriaModel.scoringCriteria.id === scoringCriteria.id
          );

        const procurementPhaseList =
          criteriaModelList && criteriaModelList.length > 0
            ? criteriaModelList[0].dataSourceList
            : [];
        const optionList = new OptionListModel<ProcurementPhase>();
        optionList.setRequestValues(procurementPhaseList);
        this.log.debug(procurementPhaseList);

        listOfScoringCriteria.push(
          this.formBuilder.group({
            scoringCriteria,
            name: scoringCriteria.name,
            value:
              scoringCriteria.value &&
              scoringCriteria.attribute /** with attribute and value */
                ? this.performanceProcurementDTO.procurement.isPmGroup
                  ? scoringCriteria.value /* autogenerate from db */
                  : [
                      procurementPhaseList.filter(
                        procurementPhase =>
                          procurementPhase.name === scoringCriteria.value
                      )[0].name || null,
                      Validators.required()
                    ] /* patch to combobox */
                : !scoringCriteria.value &&
                  scoringCriteria.attribute /** with attribute and no value */
                ? [null, Validators.required()]
                : [
                    /** no attribute */ scoringCriteria.value,
                    Validators.compose([
                      Validators.required(),
                      Validators.min(minInput),
                      Validators.max(maxInput)
                    ])
                  ],
            result:
              scoringCriteria.result && scoringCriteria.attribute
                ? scoringCriteria.result
                : [scoringCriteria.result],
            criteriaModelOptionList: optionList
          })
        );
      });
    }

    return listOfScoringCriteria;
  }

  public buildSummaryList(): void {
    const parentForm = this.formGroup.controls.summaryList as FormArray;
    this.performanceProcurementDTO.scoringTemplateWeightList.forEach(
      scoringTempW => {
        const formGroup = this.formBuilder.group({
          scoringCriteriaGroup: scoringTempW.scoringCriteriaGroup,
          weight: scoringTempW.weight,
          result: [null],
          total: [null]
        });
        parentForm.push(formGroup);
      }
    );
  }

  // set Result for manual input
  public setResult(
    eventValue: any,
    data: FormGroup,
    scoringCriteria: ScoringCriteria
  ): void {
    eventValue = eventValue ? parseFloat(eventValue) : null;

    const rangeList = scoringCriteria.scoringCriteriaRangeList.filter(
      range => range.from <= eventValue && eventValue <= range.until
    );
    if (rangeList.length > 0) {
      data.value.result = rangeList[0].result;
    } else {
      const rangeListInfinite = scoringCriteria.scoringCriteriaRangeList.filter(
        range => range.from <= eventValue && range.isInfinity
      );
      data.value.result =
        rangeListInfinite.length > 0 ? rangeListInfinite[0].result : null;
    }

    data.patchValue({
      value: eventValue ? eventValue : null,
      result: data.value.result
    });
  }

  public setResultByCombobox(
    eventValue: ProcurementPhase,
    data: FormGroup,
    scoringCriteria: ScoringCriteria
  ): void {
    this.log.debug(eventValue);
    this.log.debug(data);
    this.log.debug(scoringCriteria);

    data.patchValue({
      value: eventValue.name || null,
      result: eventValue ? eventValue.value : 0
    });
  }

  public doSetAvgTotal(
    criteriaList: FormArray,
    scoringCriteriaGroup: ScoringCriteriaGroup,
    itemCommonSpesific: FormGroup
  ): number {
    let avgTotal = 0; // perhitungan rata2
    criteriaList.controls.forEach(data => {
      avgTotal += data.value.result * 100;
    });
    avgTotal = Math.round(avgTotal) / 100 / criteriaList.controls.length;

    itemCommonSpesific.patchValue({
      avgTotal
    });

    // di set ke formArray summaryList
    this.totalScoring = 0;

    const summaryList = this.formGroup.get('summaryList') as FormArray;
    summaryList.controls.forEach((summaryControl, index) => {
      if (
        summaryControl.value.scoringCriteriaGroup.code ===
        scoringCriteriaGroup.code
      ) {
        summaryList.at(index).patchValue({
          result: avgTotal,
          total: avgTotal * summaryControl.value.weight * 0.01
        });
      }

      this.totalScoring +=
        summaryControl.value.weight * 0.01 * summaryControl.value.result * 100;
    });
    this.totalScoring = Math.round(this.totalScoring) / 100;
    this.formGroup.patchValue({ rating: this.totalScoring });
    this.formGroup.get('rating').setIsView(true);

    return avgTotal;
  }

  public getMaxMinInput(scoringCriteria: ScoringCriteria): number[] {
    const lastIndex = scoringCriteria.scoringCriteriaRangeList.length - 1;
    const minInput = scoringCriteria.scoringCriteriaRangeList[0].from;
    const maxInput = scoringCriteria.scoringCriteriaRangeList[lastIndex].until;

    const inputList: number[] = [];
    inputList.push(minInput);
    inputList.push(maxInput);
    return inputList;
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const performance: VendorPerformance = new VendorPerformance();
            performance.vendor.id = this.vendorId;
            performance.procurement.id = this.procurementId;
            performance.rating = this.formGroup.get('rating').value;
            performance.note = this.formGroup.get('note').value;
            this.performanceProcurementDTO.performance = performance;

            this.parentsCommonScoring.controls.forEach(parent => {
              this.addScoringCriteria(parent).controls.forEach(criteria => {
                const performanceScore: VendorPerformanceScore =
                  new VendorPerformanceScore();
                performanceScore.scoringCriteriaCode =
                  criteria.value.scoringCriteria.code;
                performanceScore.scoringCriteriaName =
                  criteria.value.scoringCriteria.name;
                performanceScore.result = criteria.value.result;
                performanceScore.value = criteria.value.value;
                performanceScore.scoringCriteriaGroupCode = parent.get(
                  'scoringCriteriaGroup'
                ).value.code;
                performanceScore.scoringCriteriaGroupName = parent.get(
                  'scoringCriteriaGroup'
                ).value.name;
                this.performanceProcurementDTO.performanceScoreList.push(
                  performanceScore
                );
              });
            });

            this.parentsSpecificScoring.controls.forEach(parent => {
              this.addScoringCriteria(parent).controls.forEach(criteria => {
                const performanceScore: VendorPerformanceScore =
                  new VendorPerformanceScore();
                performanceScore.scoringCriteriaCode =
                  criteria.value.scoringCriteria.code;
                performanceScore.scoringCriteriaName =
                  criteria.value.scoringCriteria.name;
                performanceScore.result = criteria.value.result;
                performanceScore.value = criteria.value.value;
                performanceScore.scoringCriteriaGroupCode = parent.get(
                  'scoringCriteriaGroup'
                ).value.code;
                performanceScore.scoringCriteriaGroupName = parent.get(
                  'scoringCriteriaGroup'
                ).value.name;
                this.performanceProcurementDTO.performanceScoreList.push(
                  performanceScore
                );
              });
            });

            this.summaryList.controls.forEach(summary => {
              const performanceSummary: VendorPerformanceSummary =
                new VendorPerformanceSummary();
              performanceSummary.weight = summary.value.weight;
              performanceSummary.result =
                Math.round(summary.value.result * 100) / 100;
              performanceSummary.total =
                Math.round(summary.value.total * 100) / 100;
              performanceSummary.scoringCriteriaGroupCode =
                summary.value.scoringCriteriaGroup.code;
              performanceSummary.scoringCriteriaGroupName =
                summary.value.scoringCriteriaGroup.name;
              this.performanceProcurementDTO.performanceSummaryList.push(
                performanceSummary
              );
            });

            this.performanceProcurementDTO.filePerformanceList =
              this.formGroup.get('file').value;

            this.performanceProcurementDTO.performanceList = [];
            this.httpClientService
              .post<Response<PerformanceProcurementDTO>>(
                '/performance-procurement/insert',
                this.performanceProcurementDTO
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate([
                    '/pages/performance-procurement/detail-procurement'
                  ]);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    } else {
      /* have not completed scoring */
      this.global.alertService.showError(
        this.translateService.instant(
          'performance-procurement.alert.msg.error.makeScoringFirst'
        )
      );
    }
  }

  public doCancel(): void {
    this.global.routerParams.set('vendorId', this.vendorId);
    this.router.navigate(['/pages/performance-procurement/detail-procurement']);
  }
}
