import {
  Component,
  ElementRef,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { AppMapPopupComponent } from '../app-map/app-map-popup.component';
import { AppMapService } from '../app-map/app-map.service';
import { LatLngResponseModel } from '../app-map/model/lat-lng-response-model';
import { MapResponseModel } from '../app-map/model/map-response-model';
import { AppPopupService } from '../app-popup/app-popup.service';
@Component({
  selector: 'app-text-map',
  templateUrl: './app-text-map.component.html',
  styleUrls: ['./app-text-map.component.scss'],
  providers: [...makeProvider(AppTextMapComponent), AppMapService],
  encapsulation: ViewEncapsulation.None
})
export class AppTextMapComponent extends BaseValueAccessorComponent<LatLngResponseModel> {
  mapModel: MapResponseModel = new MapResponseModel();
  textValue = '';
  previousValue: string;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public appPopupService: AppPopupService,
    public appMapService: AppMapService
  ) {
    super('app-text-map', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setMapResponseModel(this.formControl.value);
    this.setTextValue(this.formControl.value);
    this.previousValue = this.formControl.value;
    this.formControl.valueChanges.subscribe(value => {
      if (!this.previousValue) {
        this.setMapResponseModel(value);
        this.setTextValue(value);
        this.previousValue = value;
      }
    });
  }

  public setMapResponseModel(latLngStr: string): void {
    if (latLngStr) {
      const latLngSplit = latLngStr.split(',');
      this.mapModel.setLatitudeAndLongitude(+latLngSplit[0], +latLngSplit[1]);
    }
  }

  public setTextValue(value: string): void {
    if (value) {
      this.textValue = this.mapModel.getTextValue();
    }
  }

  public doOpenAppTextMapPopup(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    this.formControl.markAsTouched();
    if (!this.disabled) {
      this.appPopupService
        .open(AppMapPopupComponent, { model: this.mapModel })
        .subscribe((map: MapResponseModel) => {
          this.formControl.patchValue(this.mapModel.getTextValue());
          this.textValue = map.getTextValue();
          this.onChange.emit(this.mapModel);
        });
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
