import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { BaseModuleComponent } from '../core/base/angular/base-module.component';
import { AppPopupService } from '../core/components/app-popup/app-popup.service';
import { AuthenticationService } from './../core/services/authentication.service';
import { AppLoginFormPopupComponent } from './login/app-login-form-popup.component';
@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PortalComponent extends BaseModuleComponent {
  public settings: Settings;
  public logoHeaderPortal: string;
  public currentYear: number = new Date().getFullYear();
  constructor(
    translateService: TranslateService,
    public appSettings: AppSettings,
    public authenticationService: AuthenticationService,
    public appPopupService: AppPopupService
  ) {
    super('portal', translateService);
    this.settings = this.appSettings.settings;
    if (sessionStorage['skin']) {
      this.settings.theme.skin = sessionStorage['skin'];
    }
  }

  public onInit(): void {
    if (this.global.userSession) {
      this.global.routerParams.set('reload', this.authenticationService.URL);
      this.router.navigate(['/reload']);
    }
    this.setStateReady();
  }

  private doCloseNavbar(): void {
    const customNavbarToggleElement: any = document.getElementById(
      'custom-navbar-toggle'
    );
    customNavbarToggleElement.checked = false;
  }

  public doLogin(): void {
    this.doCloseNavbar();
    this.appPopupService.open(AppLoginFormPopupComponent, null, {
      backdrop: true,
      windowClass: 'portal-popup-form',
      size: null
    });
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
