import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { TableModule } from '../../components/table/table.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetProcurementMonitoringOverdueComponent } from './app-widget-procurement-monitoring-overdue.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule, TableModule],
  declarations: [AppWidgetProcurementMonitoringOverdueComponent],
  entryComponents: [AppWidgetProcurementMonitoringOverdueComponent],
  exports: [AppWidgetProcurementMonitoringOverdueComponent]
})
export class AppWidgetProcurementMonitoringOverdueModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      'app-widget-procurement-monitoring-overdue',
      AppWidgetProcurementMonitoringOverdueComponent
    );
  }
}
