<app-layout header="rfp-response.title" [isLoading]="formLoading">
  <app-table-xx
    header="Request List"
    [model]="tableResponse"
    stringUrl="/rfp-response/index"
  >
    <ng-template #headerFilterGroup>
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <div class="form-group row text-sm-left">
          <label class="cml-3 d-flex align-items-center">{{
            'rfp-response.form.rfpDate' | translate
          }}</label>
          <div class="cml-3 text-left">
            <app-date-picker-x
              size="XL"
              formControlName="rfpDate"
              [range]="true"
              (onChange)="onChangeFilter()"
            >
            </app-date-picker-x>
          </div>
          <label class="cml-5 d-flex align-items-center">{{
            'rfp-response.form.dueDate' | translate
          }}</label>
          <div class="cml-3 text-left">
            <app-date-picker-x
              size="XL"
              formControlName="dueDate"
              [range]="true"
              (onChange)="onChangeFilter()"
            >
            </app-date-picker-x>
          </div>
          <div class="cml-5 col-sm-2 text-left">
            <app-dropdown-select
              size="XL"
              formControlName="statusList"
              (onChange)="onChangeFilter()"
              showLimitValue="3"
              type="CHOSEN"
              placeholder="rfp-response.placeholder.status"
              [optionList]="statusOptionList"
            >
            </app-dropdown-select>
          </div>
        </div>
      </form>
    </ng-template>
  </app-table-xx>
</app-layout>
