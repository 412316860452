import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupWorkflowComponent } from './app-popup-workflow.component';
@Injectable()
export class AppPopupWorkflowService {
  constructor(public appPopupService: AppPopupService) {}
  public open(urlPopup, dataMapPopup?, filter?): Observable<any> {
    return this.appPopupService.open(AppPopupWorkflowComponent, {
      urlString: urlPopup,
      dataMap: dataMapPopup,
      filter
    });
  }
}
