<dashboard-widget-card
  header="{{ 'dashboard-widget-total-joined-procurement.header' | translate }}"
  size="MD"
  [isLoading]="model.isLoading"
>
  <div class="d-flex flex-column justify-content-between h-100">
    <div class="bg-light p-2 d-flex justify-content-between border-radius">
      <h4 class="text-primary">{{ percentage }}%</h4>
      <span class="text-secondary">{{ fraction }}</span>
    </div>
    <div style="max-width: 200px; margin-left: auto; margin-right: auto">
      <div class="d-block">
        <app-chart [model]="chartModel" height="200px"></app-chart>
      </div>
      <div
        class="chart-shadow bg-light d-block w-100 my-2"
        style="height: 26px; border-radius: 100%"
      ></div>
    </div>
    <ul class="list">
      <li
        class="list-item w-50 d-inline-flex align-items-center"
        *ngFor="let legend of legendList"
      >
        <span
          style="width: 20px; height: 20px"
          [ngStyle]="{ background: legend.color }"
          class="d-inline-block mr-2"
        ></span>
        {{ legend.percentage }} -
        <span class="text-secondary d-inline-block ml-2">
          {{ legend.label }}
        </span>
      </li>
    </ul>
  </div>
</dashboard-widget-card>
