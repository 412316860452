import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppChartModule } from '../../../../../core/components/app-chart/app-chart.module';
import { AppWidgetModule } from '../../../../../core/widgets/app-widget.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetContractRequestByTypeComponent } from './widget-contract-request-by-type.component';
@NgModule({
  imports: [VendorModule, AppChartModule, WidgetCardModule, AppWidgetModule],
  declarations: [WidgetContractRequestByTypeComponent],
  entryComponents: [WidgetContractRequestByTypeComponent],
  exports: [WidgetContractRequestByTypeComponent]
})
export class WidgetContractRequestByTypeModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetContractRequestByTypeComponent.moduleCode,
      WidgetContractRequestByTypeComponent,
      'w-50'
    );
  }
}
