import { BaseEntity } from '../base/base-entity';
import { TranslationKey } from '../bean/translation-key';
import { VendorAttribute } from '../bean/vendor-attribute';
import { VendorTab } from '../bean/vendor-tab';
import { VendorType } from '../bean/vendor-type';
export class VendorAttributeGroupEntity extends BaseEntity {
  fieldName: string;
  vendorAttributeList: VendorAttribute[];
  translationKey: TranslationKey;
  vendorType: VendorType;
  vendorTab: VendorTab;
  description: string;
}
