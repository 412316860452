import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Region } from 'src/app/core/bean/region';
import { TextUtils } from 'src/app/core/utils';

@Component({
    templateUrl: 'popup-choose-address-location.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PopupChooseAddressLocationComponent extends BaseComponentComponent implements OnChanges {
    @Input() public todo: string;
    @Input() public checkedRegionCodeList: string[] = [];
    @Input() public locationDefChecklist: any[];
    @Output() public onChange: EventEmitter<string[]> = new EventEmitter();

    public regionNameList: string[] = [];
    public readonly allRegionCheckList = []; /** display only */
    public checkedList = [];
    public generatedId: string;
    public dynamicRegionList = []; /** dynamic checklist shown and search */
    public charList = [];
    public regionList: Region[] = [];
    public region;

    constructor(public translateService: TranslateService) {
        super('address-book');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.keywordLocation.currentValue) {
            this.onInputSearch(changes.keywordLocation.currentValue);
        }
    }

    public onInit(): void {
        this.generateCharArray();
        this.setInitialState();
        this.setDefaultStaticCheckboxList();
        this.buildAndSetFormGroup();
        this.setRegionChecklist();

        this.formGroup.valueChanges.subscribe(value => {
            this.log.debug('formGroup');
            this.log.debug(value);
            if (!value.keywordLocation) {
                value.keywordLocation = '';
            }
            this.onInputSearch(value.keywordLocation);
        });
    }

    public setInitialState(): void {
        this.generatedId = TextUtils.generateRandomString();
    }

    public setDefaultStaticCheckboxList(): void {
        this.locationDefChecklist.forEach(loc => {
            loc.isChecked = false;
        });
    }

    public generateCharArray(): void {
        let i = 'A'.charCodeAt(0);
        const j = 'Z'.charCodeAt(0);
        for (; i <= j; ++i) {
            this.charList.push(String.fromCharCode(i));
        }
    }

    public getGroupedList(dynamicRegionList: any[], char: string): object[] {
        return dynamicRegionList.filter(reg => this.isMatchGroup(reg.name, char));
    }

    public setRegionChecklist(): void {
        this.httpClientService
        .get('/region/get-city-region-list')
        .subscribe((regionList: Region[]) => {
            this.regionList = regionList;
            this.regionList.forEach(region => {
                this.allRegionCheckList.push({
                  region: region,
                  name: region.name,
                  code: region.code,
                  isChecked: false
                });

              });

            this.checkedRegionCodeList.forEach(checkedRegionCode => {
                const regCheck = this.allRegionCheckList.filter(regCheck => regCheck.code === checkedRegionCode)[0];
                if (regCheck) {
                    const indexRegCheck = this.allRegionCheckList.indexOf(regCheck);
                    if (indexRegCheck !== -1) {
                        this.allRegionCheckList[indexRegCheck].isChecked = true;
                    }
                }
            });

            this.dynamicRegionList = this.allRegionCheckList;
            this.setStateReady();
        });

    }

    public buildAndSetFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            keywordLocation: [null]
        });
    }

    public onInputSearch(keywordLocation: string): void {
        this.log.debug(keywordLocation);

        setTimeout(() => {
            const matchRegList: Region[] = this.regionList.filter(region => region.name.toLowerCase().includes(keywordLocation.toLowerCase()));
            const regionCheckList = [];
            matchRegList.forEach(matchReg => {
                const matchCheckInOldFormGroup = this.checkedList.filter(
                    regCheck => regCheck.code === matchReg.code )[0];
                regionCheckList.push({
                    region: matchReg,
                    name: matchReg.name,
                    code: matchReg.code,
                    isChecked: matchCheckInOldFormGroup ? matchCheckInOldFormGroup.isChecked : false
                });
            });
            this.dynamicRegionList = regionCheckList; // like patchvalue
        }, 700);

    }

    public handleChange(param: any): void {
        this.region = param.region;
        this.log.debug(this.region);
    }

    public doChoose(): void {
        this.formGroup.patchValue({
            keywordLocation: ''
        });
        this.onChange.emit(this.region);
    }

    public isMatchGroup(name: string, char: string): boolean {
        if (name.toUpperCase().includes('KOTA ') || name.toUpperCase().includes('KABUPATEN ')) {
            const simpleName = name.replace('KOTA ', '').replace('KABUPATEN ', '');
            return simpleName.charAt(0).toUpperCase() === char;
        } else {
            return name.charAt(0).toUpperCase() === char;
        }
    }
    	
}
