import { NgModule } from '@angular/core';
import { SharedModule } from '../../core/shared/shared.module';
import { AppLoginFormPopupComponent } from './app-login-form-popup.component';
import { AppLoginFormComponent } from './app-login-form.component';
@NgModule({
  imports: [SharedModule],
  declarations: [AppLoginFormComponent, AppLoginFormPopupComponent],
  entryComponents: [AppLoginFormPopupComponent],
  exports: [AppLoginFormComponent, AppLoginFormPopupComponent]
})
export class AppLoginFormModule {}
