import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppFinesModule } from '../app-fines/app-fines.module';
import { AppGuaranteeModule } from '../app-guarantee/app-guarantee.module';
import { AppSowModule } from '../app-sow/app-sow.module';
import { AppWorkDefinitionComponent } from './app-work-definition.component';

@NgModule({
  imports: [
    VendorModule,
    AppCardModule,
    AppSowModule,
    AppFinesModule,
    AppGuaranteeModule
  ],
  declarations: [AppWorkDefinitionComponent],
  exports: [AppWorkDefinitionComponent]
})
export class AppWorkDefinitionModule {}
