import { BaseEntity } from '../base/base-entity';
import { BondType } from '../bean/bond-type';
import { RefutationVendor } from '../bean/refutation-vendor';

export class RefutationSubmissionEntity extends BaseEntity {
  refutationVendor: RefutationVendor;
  bondType: BondType;
  bondNumber: string;
  bondIssued: string;
  amount: number;
  startDate: Date;
  endDate: Date;
  note: string;
}
