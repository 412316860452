<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="faq.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-tab formArrayName="faqLangList" [bordered]="true">
          <app-tab-item
            header="{{ faqlang.value.lang.name }}"
            *ngFor="let faqlang of faqLangList.controls; let i = index"
            [formGroup]="faqlang"
          >
            <div class="row">
              <div class="col-sm-6 text-left mb-3">
                <label class="col-sm-6 control-label">
                  {{ 'faq.label.askQuetion' | translate }}
                </label>
              </div>
              <div class="col-sm-6 text-right mb-3">
                <app-button
                  size="SM"
                  color="PRIMARY"
                  (onClick)="doAddFaq(i, faqlang.value.lang)"
                >
                  <em class="pir pi-plus-circle"></em>
                  {{ 'faq.button.addFaq' | translate }}
                </app-button>
              </div>
            </div>
            <app-accordion
              formArrayName="faqList"
              [isLoading]="formLoading"
              cdkDropList
              (cdkDropListDropped)="onDrop($event, i)"
            >
              <app-accordion-item
                header="{{ faq.value.question || 'Add a FAQ' }} "
                *ngFor="
                  let faq of faqlang.controls.faqList.controls;
                  let j = index
                "
                [formGroupName]="j"
                customClassHeader="bg-white"
                customClassBody="bg-light"
                cdkDrag
              >
                <ng-template #headerLeft>
                  <em
                    class="pir pi-bars pi-3x"
                    cdkDragHandle
                    (mouseenter)="onMouseEnter($event)"
                  ></em>
                </ng-template>
                <ng-template #headerRight>
                  <em
                    class="pir pi-times-circle text-danger pi-2x"
                    (click)="doDeleteFaq($event, i, j)"
                  ></em>
                </ng-template>
                <div class="form-group row text-sm-right required">
                  <label class="col-sm-3 text-left">{{
                    'faq.form.question' | translate
                  }}</label>
                  <div class="col-sm-9 text-left">
                    <app-text-field
                      formControlName="question"
                      size="LG"
                      maxLength="128"
                    ></app-text-field>
                  </div>
                </div>
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 text-left">{{
                    'faq.form.answer' | translate
                  }}</label>
                  <div class="col-sm-9 text-left">
                    <app-wysiwyg
                      formControlName="answer"
                      size="LG"
                      placeholder="Enter text here..."
                    ></app-wysiwyg>
                  </div>
                </div>
                <div class="button-group button-group-right">
                  <!-- <app-button
                    (onClick)="doDeleteFaq(i, j)"
                    [disabled]="formSaving"
                    color="SECONDARY"
                    [outline]="true"
                  >
                    {{ 'app.button.delete' | translate }}
                  </app-button> -->
                  <app-button
                    (onClick)="doSaveFaq(i, j)"
                    [disabled]="formSaving"
                  >
                    {{ 'app.button.save' | translate }}
                  </app-button>
                </div>
              </app-accordion-item>
            </app-accordion>
          </app-tab-item>
        </app-tab>
        <div class="button-group button-group-center mt-5">
          <app-button (onClick)="doSave()" [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
