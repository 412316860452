<div class="row">
  <div class="col-sm-3">
    <app-card [isLoading]="formLoading">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="vendor-logo-image-card">
          <div class="vendor-logo-image">
            <img
              width="100%"
              [attr.src]="
                response.vendor?.logoFile
                  ? (global.config.BACKEND_ADDRESS +
                      '/app-vendor-catalog-info/file/view/' +
                      response.vendor?.logoFile.uploadedFileName +
                      '/' +
                      global.appConstant.fileType.IMG_LOGO_VENDOR
                    | resolveImgSrc
                    | async)
                  : (global.config.BACKEND_ADDRESS +
                      '/app-vendor-catalog-info/file/view/' +
                      response.vendor?.user.imgFile?.uploadedFileName +
                      '/' +
                      global.appConstant.fileType.IMG_LOGO_VENDOR
                    | resolveImgSrc
                    | async)
              "
              attr.alt="image"
            />
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-sm-1">
            <em class="fas fa-store"></em>
          </div>
          <p class="col-sm-9 text-left">
            {{ response.vendor?.name }}
          </p>
        </div>

        <div class="row">
          <div class="col-sm-1">
            <em class="fas fa-map-marker-alt"></em>
          </div>
          <p class="col-sm-9 text-left">
            {{ response.vendorAddress?.cityRegion.name }}
          </p>
        </div>

        <div class="row">
          <div class="col-sm-1">
            <em class="far fa-envelope"></em>
          </div>
          <p class="col-sm-9 text-left">
            {{ response.vendor?.email }}
          </p>
        </div>

        <div class="row">
          <div class="col-sm-1">
            <em class="fas fa-phone-alt"></em>
          </div>
          <div class="col-sm-9 text-left">
            <app-phone formControlName="phone"></app-phone>
          </div>
        </div>
        <br />

        <label class="control-label">{{
          'app-vendor-catalog-info.form.vendorRating' | translate
        }}</label>
        <br />

        <div class="d-flex">
          <app-rating
            class="mr-3"
            formControlName="rating"
            [isViewOnly]="true"
          ></app-rating>
          <p [ngStyle]="{ 'font-size': 'medium', 'margin-bottom': '0rem' }">
            {{ response.rating || '-' }}</p
          >
        </div>
      </form>
    </app-card>
  </div>

  <div class="col">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <div class="ml-2 d-inline-block" [ngStyle]="{ width: '100%' }">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"
              ><em class="fas fa-search"></em
            ></span>
          </div>
          <div class="col p-0 mr-5">
            <app-text-field
              formControlName="keyword"
              size="XL"
              placeholder="{{
                'app-vendor-catalog-info.filter.placeholder.searchProduct'
                  | translate
              }}"
            >
            </app-text-field>
          </div>
          <span class="mt-2 mr-3 ml-4">{{
            'app-vendor-catalog-info.filter.sortBy' | translate
          }}</span>
          <div [ngStyle]="{ 'min-width': '135px' }">
            <app-combo-box
              size="XL"
              [optionList]="sortOptionList"
              formControlName="sortBy"
            ></app-combo-box>
          </div>
        </div>
      </div>
    </form>

    <app-catalog-item-x
      *ngIf="!formLoading"
      [itemPath]="itemPath"
      [customData]="customData"
    >
    </app-catalog-item-x>
  </div>
</div>
