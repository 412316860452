import { Icon } from '../../core/bean/icon';
import { Lang } from '../../core/bean/lang';
import { PushNotification } from '../../core/bean/push-notification';
import { PushNotificationTemplate } from '../../core/bean/push-notification-template';

export class PushNotificationResponse {
  pushNotification: PushNotification;
  pushNotificationTemplateList: PushNotificationTemplate[];
  langList: Lang[];
  iconList: Icon[];
}
