import { Component } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ResponseRfiQuestion } from 'src/app/core/bean/response-rfi-question';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { RfiQuestion } from '../../../core/bean/rfi-question';
import { ResponseRfiDTO } from '../dto/response-rfi.dto';
@Component({
    templateUrl: './response-rfi-question-detail.component.html'
})
export class ResponseRfiQuestionDetailComponent extends BaseModuleComponent {
    public rfiVendorId: number;
    public responseRfiDTO: ResponseRfiDTO;
    public rfiQuestionList: RfiQuestion[];
    public todo = '';

    constructor(translateService: TranslateService) {
        super('response-rfi', translateService);
    }

    onInit(): void {
        this.setDataFromRouterParams();
        this.buildFormGroup();
        this.setFormGroup();
        this.log.debug('answerList.controls');
        this.log.debug(this.answerList.controls);
    }

    public buildFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            answerList: this.formBuilder.array([])
        });
    }

    public get answerList(): FormArray {
        return this.formGroup.get('answerList') as FormArray;
    }

    public setDataFromRouterParams(): void {
        this.todo = this.global.routerParams.get('todo');
        this.responseRfiDTO = this.global.routerParams.get('responseRfiDTO');
    }

    public setFormGroup(): void {
        this.setListOfQuestionAndAnswer(this.responseRfiDTO);
        this.setStateReady();
    }

    public isArray(answer): boolean {
        if (Array.isArray(answer)) {
          return true;
        } else {
          return false;
        }
    }

    public setListOfQuestionAndAnswer(responseRfiDTO: ResponseRfiDTO): void {
        if (responseRfiDTO.responseRfiList !== null) {
            responseRfiDTO.responseRfiList.forEach(responseRfi => {
                const formObjectCode = responseRfi.rfiQuestion.formObject.code;
                let answer = responseRfi.answer && JSON.parse(responseRfi.answer);
                if (formObjectCode === 'CHECK-BOX') {
                    if (Array.isArray(answer)) {
                        answer = answer.filter((a: any) => a.isChecked);
                        if (answer.length === 0) {
                            answer = responseRfi.answer && JSON.parse(responseRfi.answer);
                        }
                    }
                }
                this.addToAnswerList(responseRfi, answer);
            });
            this.log.debug(this.answerList);
        }
    }

    public addToAnswerList(responseRfi: ResponseRfiQuestion, answer: string): void {
        this.answerList.push(
            this.formBuilder.group({
                rfiQuestion: responseRfi.rfiQuestion,
                formObjectCode: responseRfi.rfiQuestion.formObject.code,
                question: responseRfi.rfiQuestion.question,
                answer: [answer]
            })
        );
    }

    public doNext(): void {
        this.router.navigate(['/pages/response-rfi/']);
    }

    public doPrevious(): void {
        this.global.routerParams.clear();
        this.global.routerParams.set('todo', 'detail');
        this.global.routerParams.set('rfiVendorId', this.responseRfiDTO.rfiVendor.id);
        this.router.navigate(['/pages/response-rfi/detail']);
    }

    public doBack(): void {
        this.global.routerParams.clear();
        this.router.navigate(['/pages/response-rfi']);
    }

    public get formGroupControls(): {[key: string]: AbstractControl} {
        return this.formGroup.controls;
    }
}
