import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppButtonModule } from './../../app-button/app-button.module';
import { AppPopupItemComponent } from './app-popup-item.component';
import { AppPopupItemService } from './app-popup-item.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppTableModule, AppButtonModule],
  declarations: [AppPopupItemComponent],
  entryComponents: [AppPopupItemComponent],
  providers: [AppPopupItemService],
  exports: [AppPopupItemComponent]
})
export class AppPopupItemModule {}
