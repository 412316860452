<div class="row main-row">
  <div class="col-12">
    <form class="form-horizontal pt-5" [formGroup]="tabTwo.formGroup">
      <div class="form-group row text-sm-right required">
        <label class="col-sm-3 control-label">About</label>
        <div class="col-sm-9 text-left">
          <app-text-area autofocus="true" formControlName="about"></app-text-area>
        </div>
      </div>
    </form>
  </div>
</div>
