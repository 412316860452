import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppCheckBoxModule } from '../../app-check-box/app-check-box.module';
import { AppComboBoxModule } from '../../app-combo-box/app-combo-box.module';
import { AppDropdownSelectModule } from '../../app-dropdown-select/app-dropdown-select.module';
import { AppTableXModule } from '../../app-table-x/components/app-table-x/app-table-x.module';
import { AppTextFieldModule } from '../../app-text-field/app-text-field.module';
import { TableModule } from '../../table/table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupChoosePrComponent } from './app-popup-choose-pr.component';
import { AppPopupChoosePrService } from './app-popup-choose-pr.service';

@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppTableXModule,
    AppButtonModule,
    AppTextFieldModule,
    AppCheckBoxModule,
    AppComboBoxModule,
    TableModule,
    AppDropdownSelectModule
  ],
  declarations: [AppPopupChoosePrComponent],
  entryComponents: [AppPopupChoosePrComponent],
  providers: [AppPopupChoosePrService],
  exports: [AppPopupChoosePrComponent]
})
export class AppPopupChoosePrModule {}
