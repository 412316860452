import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { CatalogAttribute } from '../../core/bean/catalog-attribute';
import { CatalogGroupTemplate } from '../../core/bean/catalog-group-template';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { AppPopupCatalogAttributeComponent } from './app-popup-catalog-attribute.component';
import { CatalogAttributeGroupRequest } from './catalog-attribute-group-request';
import { CatalogAttributeGroupResponse } from './catalog-attribute-group-response';

@Component({
  templateUrl: './catalog-attribute-group-edit-add.component.html',
  styles: [
    `
      .removeSearchText .app-table-search {
        display: none !important;
      }
    `
  ],
  encapsulation: ViewEncapsulation.None
})
export class CatalogAttributeGroupEditAddComponent extends BaseModuleComponent {
  @ViewChild('selectorCatalogAttribute')
  tableCatalogAttribute: AppTableComponent;

  public tableResponse: TableResponseModel<CatalogAttribute>;
  public catalogAttributeGroupResponse: CatalogAttributeGroupResponse;
  public catalogAttributeGroupRequest: CatalogAttributeGroupRequest =
    new CatalogAttributeGroupRequest();
  public catalogGroupTemplate: CatalogGroupTemplate;
  public catalogAttributeList: CatalogAttribute[] = [];
  public catalogAttributeGroupId: number;
  public catalogAttributeIdList: number[] = [];
  public statusOptionList: OptionListModel<any> = new OptionListModel();
  public dataReady = false;
  public tableReady = false;

  public readonly urlUpdateCatalogAttributeGroup =
    '/catalog-attribute-group/update';
  public readonly urlInsertCatalogAttributeGroup =
    '/catalog-attribute-group/insert';
  public readonly urlNavigateTOCatalogAttributeGroupList =
    '/pages/catalog-attribute-group';

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('catalog-attribute-group', translate);
  }

  onInit(): void {
    this.setDataFromRouterParam();
    this.setStatusOptionList();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [
        null,
        {
          validators: [Validators.required()],
          asyncValidators: [
            Validators.existsAsync(
              '/catalog-attribute-group/check-name',
              null,
              null,
              {
                id:
                  this.catalogAttributeGroupId !== undefined
                    ? this.catalogAttributeGroupId
                    : null
              }
            )
          ],
          updateOn: 'blur'
        }
      ],
      description: [null],
      isActive: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.getCatalogAttributeGroupByIdFromServer(
      '/catalog-attribute-group/edit-add',
      this.todo,
      this.catalogAttributeGroupId
    ).subscribe(catalogAttributeGroupResponse => {
      this.catalogAttributeGroupResponse = catalogAttributeGroupResponse;
      if (catalogAttributeGroupResponse.catalogAttributeGroup != null) {
        const {
          id,
          name,
          description,
          isActive: isActiveStatus
        } = catalogAttributeGroupResponse.catalogAttributeGroup;
        const isActive = this.statusOptionList
          .getRequestValues()
          .filter(status => status.value === isActiveStatus)[0];
        this.formGroup.patchValue({ id, name, description, isActive });
      }
      catalogAttributeGroupResponse.catalogGroupTemplateList.forEach(
        element => {
          this.catalogAttributeList.push(element.catalogAttribute);
          this.catalogAttributeIdList.push(element.catalogAttribute.id);
        }
      );
      this.buildTableResponse();
      this.setStateReady();
      this.dataReady = true;
      this.tableReady = true;
    });
  }

  public setStatusOptionList(): void {
    const statusOptionList = [
      { name: 'Active', value: true },
      { name: 'Not Active', value: false }
    ];
    this.statusOptionList.setRequestValues(statusOptionList);
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field:
          this.global.currentLang === 'id'
            ? 'translationKey.translationList.0.value'
            : 'translationKey.translationList.1.value',
        header: 'table.column.catalogAttribute',
        customClass: 'white-space-normal'
      }
    ]);
    this.tableResponse.page.records = this.catalogAttributeList;
    this.tableResponse.page.totalRecords = this.catalogAttributeList.length;

    this.setStateReady();
  }

  public doAdd(): void {
    const catalogAttributeIdList = this.catalogAttributeIdList;
    this.appPopupService
      .open(
        AppPopupCatalogAttributeComponent,
        { catalogAttributeIdList },
        { size: 'lg' }
      )
      .subscribe(newCatalogAttributeList => {
        newCatalogAttributeList.forEach(newCatalogAttribute => {
          this.catalogAttributeList.push(newCatalogAttribute);
          this.catalogAttributeIdList.push(newCatalogAttribute.id);
          this.catalogGroupTemplate = new CatalogGroupTemplate();
          this.catalogGroupTemplate.catalogAttribute = newCatalogAttribute;
          this.catalogGroupTemplate.catalogAttributeGroup =
            this.catalogAttributeGroupResponse.catalogAttributeGroup;
          this.catalogAttributeGroupResponse.catalogGroupTemplateList.push(
            this.catalogGroupTemplate
          );
          this.tableResponse.page.records = this.catalogAttributeList;
          this.tableResponse.page.totalRecords =
            this.catalogAttributeList.length;
        });
        this.tableCatalogAttribute.reloadClient();
      });
  }

  public doDelete(catalogAttributeDelete: CatalogAttribute): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.catalogAttributeList.forEach((catalogAttribute, index) => {
            if (catalogAttribute === catalogAttributeDelete) {
              this.catalogAttributeList.splice(index, 1);
              this.catalogAttributeIdList.splice(index, 1);
              this.catalogAttributeGroupResponse.catalogGroupTemplateList.splice(
                index,
                1
              );
            }
          });
          this.tableResponse.page.records = this.catalogAttributeList;
          this.tableResponse.page.totalRecords =
            this.catalogAttributeList.length;
          this.tableCatalogAttribute.reloadClient();
        }
      });
  }

  public setDataFromRouterParam(): void {
    this.todo = this.global.routerParams.get('todo');
    this.catalogAttributeGroupId = this.global.routerParams.get(
      'catalogAttributeGroupId'
    );
  }

  public doCancel(): void {
    this.router.navigate([this.urlNavigateTOCatalogAttributeGroupList]);
  }

  public doSave(): void {
    this.validate();
    if (
      this.formGroup.valid &&
      this.catalogAttributeGroupResponse.catalogGroupTemplateList &&
      this.catalogAttributeGroupResponse.catalogGroupTemplateList.length > 0
    ) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.saveCatalogAttribute();
          }
        });
    } else {
      this.global.alertService.showError(
        this.translateService.instant(
          'catalog-attribute-group.alert.completeDataChooseCatalogAttribute'
        )
      );
    }
  }

  public saveCatalogAttribute(): void {
    this.setStateProcessing();
    this.catalogAttributeGroupRequest.catalogAttributeGroup =
      this.formGroup.value;
    this.catalogAttributeGroupRequest.catalogAttributeGroup.isActive =
      this.formGroup.value.isActive.value;
    this.catalogAttributeGroupRequest.catalogGroupTemplateList =
      this.catalogAttributeGroupResponse.catalogGroupTemplateList;
    this.saveCatalogAttributeGroupToServer(
      this.urlSaveCatalogAttributeGroup,
      this.catalogAttributeGroupRequest
    ).subscribe(response => {
      if (response.status === ResponseStatusModel.OK) {
        this.global.alertService.showSuccessSavingOnNextRoute();
        this.router.navigate([this.urlNavigateTOCatalogAttributeGroupList]);
      } else {
        this.global.alertService.showError(response.statusText);
      }
      this.setStateReady();
    });
  }

  public get urlSaveCatalogAttributeGroup(): string {
    return this.todo === 'edit'
      ? this.urlUpdateCatalogAttributeGroup
      : this.urlInsertCatalogAttributeGroup;
  }

  public getCatalogAttributeGroupByIdFromServer(
    urlGetCatalogAttributeGroupById: string,
    todo: string,
    catalogAttributeGroupId: number
  ): Observable<CatalogAttributeGroupResponse> {
    return this.httpClientService.post<CatalogAttributeGroupResponse>(
      urlGetCatalogAttributeGroupById,
      new RouteRequestModel(todo, catalogAttributeGroupId)
    );
  }

  public saveCatalogAttributeGroupToServer(
    urlSaveCatalogAttributeGroup: string,
    catalogAttributeGroupRequest: CatalogAttributeGroupRequest
  ): Observable<Response<CatalogAttributeGroupRequest>> {
    return this.httpClientService.post<Response<CatalogAttributeGroupRequest>>(
      urlSaveCatalogAttributeGroup,
      catalogAttributeGroupRequest
    );
  }
}
