import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppDropdownSelectModule } from '../../core/components/app-dropdown-select/app-dropdown-select.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { RfqResponseEditComponent } from './rfq-response-edit.component';
import { RfqResponseComponent } from './rfq-response.component';

export const routes = [
  { path: '', component: RfqResponseComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: RfqResponseEditComponent,
    data: { breadcrumb: 'rfq.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: RfqResponseEditComponent,
    data: { breadcrumb: 'rfq.breadcrumb.detail' }
  },
  {
    path: 'detail/:token',
    component: RfqResponseEditComponent,
    data: { breadcrumb: '' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppDropdownSelectModule
  ],
  declarations: [RfqResponseComponent, RfqResponseEditComponent]
})
export class RfqResponseModule {}
