<app-popup
  header="{{ 'performance-procurement.title' | translate }} - {{
    'app.action.add' | translate
  }}"
  [isLoading]="formLoading"
>
  <div class="row main-row-procurement">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'performance-procurement.popup.procurementName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              size="LG"
              maxLength="32"
              formControlName="procurementName"
            ></app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'performance-procurement.popup.procurementNumber' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              size="LG"
              maxLength="32"
              formControlName="number"
            ></app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'performance-procurement.popup.procurementType' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              size="LG"
              formControlName="procurementType"
              [optionList]="procurementTypeOptionList"
            ></app-combo-box>
          </div>
        </div>

        <ng-template #modalFooter let-activeModal>
          <div class="button-group button-group-center">
            <app-button
              color="SECONDARY"
              (onClick)="activeModal.close(true)"
              [disabled]="!isEnabled"
              >{{ 'app.button.close' | translate }}
            </app-button>
            <app-button
              color="PRIMARY"
              (onClick)="doSave()"
              [disabled]="!isEnabled"
              >{{ 'app.button.save' | translate }}
            </app-button>
          </div>
        </ng-template>
      </form>
    </div>
  </div>
</app-popup>
