import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { AppAlertXModule } from '../app-alert-x/app-alert-x.module';
import { AppBadgeCatalogModule } from '../app-badge-catalog/app-badge-catalog.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppComboBoxModule } from '../app-combo-box/app-combo-box.module';
import { AppDropdownSelectModule } from '../app-dropdown-select/app-dropdown-select.module';
import { AppSelectModule } from '../app-select/app-select.module';
import { AppTableXModule } from '../app-table-x/components/app-table-x/app-table-x.module';
import { AppTextFieldModule } from '../app-text-field/app-text-field.module';
import { AppUploadXModule } from '../upload/components/app-upload-x/app-upload-x.module';
import { UploadSharedModule } from '../upload/shared/upload-shared.module';
import { AppUploadDocumentTableXComponent } from './app-upload-document-table-x.component';
@NgModule({
  imports: [
    UploadSharedModule,
    AppTableXModule,
    AppComboBoxModule,
    AppButtonModule,
    AppUploadXModule,
    AppSelectModule,
    AppDropdownSelectModule,
    AppTextFieldModule,
    DirectivesModule,
    AppBadgeCatalogModule,
    AppAlertXModule
  ],
  declarations: [AppUploadDocumentTableXComponent],
  exports: [AppUploadDocumentTableXComponent]
})
export class AppUploadDocumentTableXModule {}
