<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-xx
        header="push-notification.index.title"
        [model]="tableResponse"
        stringUrl="/push-notification/index"
        (onClick)="doEdit($event)"
      >
        <ng-template #headerFilterGroup>
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            *ngIf="!formLoading"
            novalidate
          >
            <div
              class="form-group row text-sm-left"
              style="margin-bottom: -3rem"
            >
              <div class="col-sm-12 text-left">
                <div class="form-row px-1">
                  <div class="form-group row px-4">
                    <label class="pr-4 pt-2">{{
                      'push-notification.form.filter.module' | translate
                    }}</label>
                    <div class="form-group row px-4">
                      <app-dropdown-select
                        style="width: 100%"
                        size="XL"
                        [optionList]="moduleOptionList"
                        formControlName="moduleList"
                        showLimitValue="2"
                        type="CHOSEN"
                        placeholder="{{
                          'push-notification.form.placeholder.module'
                            | translate
                        }}
                        "
                        (onChange)="onChangeFilter()"
                      >
                      </app-dropdown-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </app-table-xx>
    </div>
  </div>
</app-layout>
