import { EventEmitter, Injectable } from '@angular/core';
import { AppPopupLoadingBlockComponent } from '../components/app-popup/app-popup-loading-block/app-popup-loading-block.component';
import { AppPopupService } from '../components/app-popup/app-popup.service';
@Injectable({
  providedIn: 'root'
})
export class LoadingBlockService {
  private onCloseChanges: EventEmitter<boolean> = new EventEmitter();
  constructor(private appPopupService: AppPopupService) {}

  public showInfo(message?: string): void {
    this.appPopupService.open(
      AppPopupLoadingBlockComponent,
      { message, onCloseChanges: this.onCloseChanges },
      { size: 'SM', windowClass: 'app-popup-loading-block' }
    );
  }

  public showCompleteAndClose(message: string): void {
    this.appPopupService.componentInstance.message = message;
    this.appPopupService.componentInstance.isShowCheckMark = true;
    setTimeout(() => {
      this.onCloseChanges.emit(true);
    }, 2500);
  }

  public close(): void {
    this.onCloseChanges.emit(true);
  }
}
