import {
  Component,
  ElementRef,
  Input,
  Optional,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { take } from 'rxjs/operators';
import { AppPopupService } from '../../../app-popup/app-popup.service';
import * as uploadAction from '../../actions/upload-file.action';
import {
  BaseUploadComponent,
  makeUploaderProvider
} from '../../base/base-upload.component';
import { UploadDragAndDropEvent } from '../../events';
import { UploadMapper } from '../../mapper/upload-mapper';
import { Uploader } from '../../model';
import { UploadService } from '../../upload.service';
import { AppPopupUploadImagesComponent } from './app-popup-upload-images.component';

@Component({
  selector: 'app-upload-images',
  templateUrl: './app-upload-images.component.html',
  styleUrls: ['./app-upload-images.component.scss'],
  providers: [...makeUploaderProvider(AppUploadImagesComponent)],
  encapsulation: ViewEncapsulation.None
})
export class AppUploadImagesComponent extends BaseUploadComponent {
  @Input() isMaintainAspectRatio = true;

  public isDragOver: boolean;
  public readonly MAX_IMAGE_BOX = 5;
  public boxItemList: Array<number>;
  public srcBase64Map: Map<number, string> = new Map<number, string>();
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public uploadService: UploadService,
    public renderer2: Renderer2,
    public translate: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('app-upload-images', controlContainer, elementRef, uploadService);
  }

  protected onInitBaseUpload(): void {
    this.setModelOptions();
    this.setStateBoxItemList();
    this.uploadService
      .setUploader(this.model, this.formControl)
      .subscribe((uploader: Uploader) => {
        const values = UploadMapper.toValues(uploader);
        this.formControl.patchValue(values);
        this.doSetFileListToFileUploader();
        this.onChange.emit(this.formControl.value);
      });
  }

  private setModelOptions(): void {
    this.model.options = {
      maxFile: this.model.options.maxFile || this.MAX_IMAGE_BOX
    };
  }

  private setStateBoxItemList(): void {
    this.boxItemList = Array(this.model.options.maxFile)
      .fill(0)
      .map((val: number, index: number) => val + index);
  }

  public onUploadChange(event: any, index?: number): void {
    typeof index !== 'undefined'
      ? this.uploadService.dispatch(
          new uploadAction.ChangeFile({ fileList: event.target.files, index })
        )
      : this.fileChangeEvent(event, event.target.files);
  }

  public onDragAndDropChange(
    uploadDragAndDropEvent: UploadDragAndDropEvent
  ): void {
    switch (uploadDragAndDropEvent.type) {
      case 'ON-DRAGOVER':
        this.isDragOver = true;
        break;
      case 'ON-DRAGLEAVE':
        this.isDragOver = false;
        break;
      case 'ON-DROP':
        this.isDragOver = false;
        this.fileChangeEvent(null, uploadDragAndDropEvent.fileList);
        break;
      default:
        break;
    }
  }

  public fileChangeEvent(event: any, fileList: FileList): void {
    this.model.error = null;
    if (fileList[0] && fileList[0].type.includes('image/')) {
      this.appPopupService
        .open(AppPopupUploadImagesComponent, {
          isMaintainAspectRatio: this.isMaintainAspectRatio,
          imageFile: fileList[0]
        })
        .subscribe((imageCroppedEvent: ImageCroppedEvent) => {
          this.srcBase64Map.set(
            this.model.uploaderFileList.length,
            imageCroppedEvent.base64
          );
          this.uploadService.dispatch(
            new uploadAction.AddFile({
              fileList: fileList,
              croppedPoints: imageCroppedEvent.imagePosition
            })
          );

          if (event !== null) {
            event.target.value = null;
          }
        });
    } else {
      this.model.setError({
        type: 'EXTENSION_ERROR',
        message: this.global.translateService.instant('app.acceptable.extension')
      });
    }
  }

  public doDeleteFile(index?: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (typeof index !== 'undefined') {
            if (this.srcBase64Map && this.srcBase64Map.size > 0) {
              const uploaderFile = this.model.uploaderFileList[index];
              if (uploaderFile.isNew) {
                this.srcBase64Map.delete(index);
              }
              const keyList: number[] = Array.from(this.srcBase64Map.keys())
                .filter(key => key > index)
                .sort();
              const valueList: string[] = [];
              keyList.forEach(key => {
                valueList.push(this.srcBase64Map.get(key));
                this.srcBase64Map.delete(key);
              });

              valueList.forEach((value, i) => {
                this.srcBase64Map.set(index + i, value);
              });

              this.srcBase64Map = new Map(
                [...this.srcBase64Map.entries()].sort()
              );

              // for (let [key, value] of this.srcBase64Map) {
              //   if (key > index) {
              //     const valueTemp = value;
              //     this.srcBase64Map.delete(key);
              //     this.srcBase64Map.set(key - 1, valueTemp);
              //   }
              // }
            }

            this.uploadService.dispatch(new uploadAction.DeleteFile({ index }));
          } else {
            this.uploadService.dispatch(new uploadAction.BulkDeleteFile());
          }
        }
      });
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
