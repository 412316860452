import { BaseEntity } from '../base/base-entity';
import { Order } from '../bean/order';
import { User } from '../bean/user';

export class OrderCancellationEntity extends BaseEntity {
  order: Order;
  cancelByUser: User;
  cancelDate: Date;
  cancelNote: string;
}
