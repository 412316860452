import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { ChartModel } from '../../components/app-chart/models/chart-model';
import { WidgetService } from '../widgets.service';
import { WidgetContractPerStatusResponse } from './model/widget-contract-per-status-response';
@Component({
  selector: 'app-widget-contract-per-status',
  templateUrl: './app-widget-contract-per-status.component.html',
  //   styleUrls: ['./app-widget-contract-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetContractPerStatusComponent extends BaseComponentComponent {
  public chartModel: ChartModel;
  constructor(
    public widgetService: WidgetService,
    public translateService: TranslateService
  ) {
    super('app-widget-contract-per-status');
  }

  public onInit(): void {
    this.getAndSetContractInformation();
  }

  public getAndSetContractInformation(): void {
    this.httpClientService
      .get<WidgetContractPerStatusResponse>('/widget-contract-per-status/index').subscribe(
        (widgetContractPerStatusResponse: WidgetContractPerStatusResponse) => {
          if (widgetContractPerStatusResponse) {
            const labels = [];
            const dataSets = [];
            widgetContractPerStatusResponse.widgetContractPerStatusModelList.forEach(
              widgetContractPerStatusModel => {
                labels.push(
                  this.translateService.instant(
                    'app-widget-contract-per-status.' +
                      widgetContractPerStatusModel.name
                        .toLowerCase()
                        .replace(/\s/g, '')
                        .trim()
                  )
                );
                dataSets.push(widgetContractPerStatusModel.total);
              }
            );
            this.chartModel = new ChartModel('pie', labels, dataSets, null, {
              defaultColor: ['#204d95 ', '#782095', '#3d9520', '#334d4d']
            });
          }
          this.setStateReady();
        }
      );
  }
}
