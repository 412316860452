<div class="text-center py-4" *ngIf="formLoading">
  <img src="assets/img/common/loader-small.gif" alt="Loading" />
</div>
<form *ngIf="!formLoading">
  <ng-container *ngIf="paymentRequestView">
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-payment-request-information-view.form.procurementName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ paymentRequestView.procurementName }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-payment-request-information-view.form.agreementNumber' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ paymentRequestView.agreementNumber }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-payment-request-information-view.form.stageName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ paymentRequestView.stageName }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-payment-request-information-view.form.paymentTermName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ paymentRequestView.paymentTermName }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-payment-request-information-view.form.totalPayment' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          global.converterService.convertMoney(paymentRequestView.paymentAmount)
        }}
        IDR
      </div>
    </div>
  </ng-container>

  <h5 class="separator">{{
    'app-payment-request-information-view.separator.invoice' | translate
  }}</h5>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'app-payment-request-information-view.form.number' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ paymentInvoice.number }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'app-payment-request-information-view.form.date' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{
        paymentInvoice.invoiceDate
          | date: global.config.parameterModel.dateFormatShort
      }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'app-payment-request-information-view.form.billingAmount' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ global.converterService.convertMoney(paymentInvoice.amount) }}
      &nbsp;{{ paymentInvoice.payment.currency.code }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'app-payment-request-information-view.form.file' | translate
    }}</label>
    <div class="col">
      <div class="col-12 text-left p-0">
        <a
          href="#"
          (click)="
            doDownload(
              $event,
              '/payment-request-review/file/view/' +
                paymentInvoice.docFile.uploadedFileName +
                '/' +
                paymentInvoice.docFile.fileType.code,
              paymentInvoice.docFile.fileName
            )
          "
        >
          {{
            paymentInvoice.docFile.fileName
              ? paymentInvoice.docFile.fileName
              : '-'
          }}
        </a>
      </div>
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'app-payment-request-information-view.form.description' | translate
    }}</label>
    <div class="col-sm-6 text-left">
      {{ paymentInvoice.description || '-' }}
    </div>
  </div>
  <h5 class="separator">{{
    'app-payment-request-information-view.separator.billingTerms' | translate
  }}</h5>
  <app-table
    *ngIf="!formLoading"
    [model]="tableResponse"
    [isServerSide]="true"
    stringUrl="/app-payment-request-information-view/get-payment-billing-document-list"
  >
  </app-table>
</form>
