import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contract } from '../../../bean/contract';
import { GuaranteeBondType } from '../../../bean/guarantee-bond-type';
import { ProcurementGuarantee } from '../../../bean/procurement-guarantee';
import { ProcurementVendor } from '../../../bean/procurement-vendor';
import { AppPopupService } from '../app-popup.service';
import { AppPopupProcurementGuaranteeComponent } from './app-popup-procurement-guarantee.component';

@Injectable()
export class AppPopupProcurementGuaranteeService {
  constructor(public appPopupService: AppPopupService) {}
  public open(
    todo: string,
    header: string,
    procurementGuaranteeEdit: ProcurementGuarantee,
    guaranteeBondTypeList: GuaranteeBondType[] = [],
    contract: Contract,
    procurementVendor: ProcurementVendor
  ): Observable<any> {
    return this.appPopupService.open(AppPopupProcurementGuaranteeComponent, {
      todo,
      header,
      procurementGuaranteeEdit,
      guaranteeBondTypeList,
      contract,
      procurementVendor
    });
  }
}
