import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Module } from '../../core/bean/module';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './module.component.html',
})
export class ModuleComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Module>;
  constructor(translateService: TranslateService) {
    super('module', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'moduleGroupCode', header: 'table.column.moduleGroup', customClass: 'text-center' },
      { field: 'code', header: 'table.column.code' },
      { field: 'name', header: 'table.column.name', customClass: 'white-space-normal' },
      { field: 'moduleType.name', header: 'table.column.moduleType', customClass: 'text-center' },
      { field: 'isStage', header: 'table.column.stage', datamap: '{"false" : "No" , "true" : "Yes" }', customClass: 'text-center' },
      { field: 'isStarter', header: 'table.column.starter', datamap: '{"false" : "No" , "true" : "Yes" }', customClass: 'text-center' },
      { field: 'isNumberGenerator', header: 'table.column.generator', datamap: '{"false" : "No" , "true" : "Yes" }', customClass: 'text-center' },
      { field: 'isSchedule', header: 'table.column.schedule', datamap: '{"false" : "No" , "true" : "Yes" }', customClass: 'text-center' },
      { field: 'isImport', header: 'table.column.import', datamap: '{"false" : "No" , "true" : "Yes" }', customClass: 'text-center' }
    ]);
  }

  public doEdit(module: Module): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('moduleId', module.id);
    this.router.navigate(['/pages/module/edit']);
  }
}
