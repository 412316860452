import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupWorkPlanItemInfoComponent } from './app-popup-work-plan-item-info.component';
import { AppPopupWorkPlanItemInfoService } from './app-popup-work-plan-item-info.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppTableModule],
  declarations: [AppPopupWorkPlanItemInfoComponent],
  entryComponents: [AppPopupWorkPlanItemInfoComponent],
  providers: [AppPopupWorkPlanItemInfoService],
  exports: [AppPopupWorkPlanItemInfoComponent]
})
export class AppPopupWorkplanItemInfoModule {}
