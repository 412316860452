<app-popup header="app-popup-change-password.title" (onClose)="onClosePopup()">
  <div class="row main-row">
    <form [formGroup]="formGroup" >
    <div class="col-12">
      <div class="app-popup-change-password" [innerHTML]="termsAndCondition"></div>
    </div>  
    <div class="form-group row text-sm-right">
      <label class="col-sm-5 control-label">{{ 'account-setting.form.email' |
        translate }}</label>
        <div class="col-sm-7 text-left">
          {{
            global.userSession.user.email
          }}
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-5 control-label">{{
        'account-setting.form.newPassword' | translate
        }}</label>
          <div class="col-sm-7 text-left">
        <app-text-field formControlName="newPassword" type="password" maxLength="32" ></app-text-field>
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-5 control-label">{{
        'account-setting.form.newPasswordConfirm' | translate
        }}</label>
          <div class="col-sm-7 text-left">
        <app-text-field formControlName="confirmNewPassword" type="password" maxLength="32" (onChange)="doChangePassword()"></app-text-field>
      </div>
    </div>
  </form>
  </div>
  <div class="button-group button-group-center mt-3">
    <button
      type="button"
      class="btn btn-primary"
      (click)="doSave()"
      [disabled]="formSaving"
    >
      {{ 'app.button.submit' | translate }}
    </button>
  </div>
</app-popup>
