import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { File } from '../../bean/file';

@Component({
  templateUrl: './app-popup-image-slideshow.component.html',
  styleUrls: ['./app-popup-image-slideshow.component.scss']
})
export class AppPopupImageSlideshowComponent extends BaseComponentComponent {
  @Input() activeIndex: number;
  @Input() fileList: File[];
  @Input() title: string;
  @Input() parentModuleCode: string;
  @Output() public onClose: EventEmitter<boolean> = new EventEmitter();
  public activeBorder: number;

  constructor() {
    super('');
  }
  public onInit(): void {
    this.activeBorder = this.activeIndex;
  }

  public onNext(): void {
    this.activeBorder += 1;
    if (this.activeBorder > this.fileList.length - 1) {
      this.activeBorder = 0;
    }
  }

  public onPrevious(): void {
    this.activeBorder -= 1;
    if (this.activeBorder < 0) {
      this.activeBorder = this.fileList.length - 1;
    }
  }

  public onClickImage(index: number): void {
    this.activeBorder = index;
  }

  public doCloseImage(): void {
    this.onClose.emit(true);
  }
}
