import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { FileType } from '../../core/bean/file-type';
import { ProcurementDocType } from '../../core/bean/procurement-doc-type';
import { Translation } from '../../core/bean/translation';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { ProcurementDocTypeRequest } from './procurement-doc-type-request';
import { ProcurementDocTypeResponse } from './procurement-doc-type-response';
@Component({
  templateUrl: './procurement-doc-type-edit-add.component.html'
})
export class ProcurementDocTypeEditAddComponent extends BaseModuleComponent {
  public procurementDocTypeId: number;
  public procurementDocTypeResponse: ProcurementDocTypeResponse =
    new ProcurementDocTypeResponse();
  public procurementDocTypeRequest: ProcurementDocTypeRequest =
    new ProcurementDocTypeRequest();
  public langList = [];
  public translationListLabel = [];
  public fileTypeList: OptionListModel<FileType> = new OptionListModel(
    true,
    'code'
  );
  constructor(translateService: TranslateService) {
    super('procurement-doc-type', translateService);
  }

  onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.getAndSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.procurementDocTypeId = this.global.routerParams.get(
      'procurementDocTypeId'
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      langList: this.formBuilder.array([]),
      description: [null, Validators.maxLength(512)],
      translationList: this.formBuilder.array([]),
      translationKey: [null],
      fileType: [null, Validators.required()]
    });
  }

  public getAndSetFormGroup(): void {
    this.httpClientService
      .post<ProcurementDocTypeResponse>(
        '/procurement-doc-type/add-edit',
        new RouteRequestModel(this.todo, this.procurementDocTypeId)
      )
      .subscribe(procurementDocTypeResponse => {
        this.langList = procurementDocTypeResponse.langList;
        this.setTranslation(procurementDocTypeResponse);
        this.fileTypeList.setRequestValues(
          procurementDocTypeResponse.fileTypeList
        );
        if (procurementDocTypeResponse.procurementDocType !== null) {
          this.setFormGroup(procurementDocTypeResponse.procurementDocType);
        }
        this.setStateReady();
      });
  }

  public setFormGroup(procurementDocType: ProcurementDocType): void {
    if (procurementDocType !== null) {
      const { id, code, description, fileType, translationKey } =
        procurementDocType;
      this.formGroup.patchValue({
        id,
        code,
        description,
        fileType,
        translationKey
      });
    }
  }

  public setTranslation(
    procurementDocTypeResponse: ProcurementDocTypeResponse
  ): void {
    if (procurementDocTypeResponse.translationList.length > 0) {
      procurementDocTypeResponse.translationList.forEach(translation => {
        this.translationListLabel.push(translation.lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: translation.value
          })
        );
      });
    } else {
      procurementDocTypeResponse.langList.forEach(lang => {
        this.translationListLabel.push(lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: [null, Validators.required()]
          })
        );
      });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/procurement-doc-type/']);
  }

  public doSave(): void {
    this.validate();
    this.setFormArrayValidation();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.procurementDocTypeRequest.procurementDocType =
              this.global.copyFormAttributeToModel(
                new ProcurementDocType(),
                this.formGroup
              );
            this.procurementDocTypeRequest.translation = this.formGroup
              .get('translationList')
              .value.map((a: Translation) => a.value);
            const url =
              this.todo === 'edit'
                ? '/procurement-doc-type/update'
                : '/procurement-doc-type/insert';
            this.httpClientService
              .post<Response<ProcurementDocTypeRequest>>(
                url,
                this.procurementDocTypeRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/procurement-doc-type/']);
                  this.translateService.reloadLang(
                    this.global.getCurrentLang()
                  );
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public setFormArrayValidation(): void {
    for (const control of this.translationList.controls) {
      control.setStateSubmitted();
    }
  }

  public get translationList(): FormArray {
    return this.formGroup.get('translationList') as FormArray;
  }
}
