import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Resource } from '../../core/bean/resource';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './resource.component.html'
})
export class ResourceComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Resource>;
  constructor(translateService: TranslateService) {
    super('resource', translateService);
  }
  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      { field: 'name', header: 'table.column.name' },
      { field: 'height', header: 'table.column.height' },
      { field: 'width', header: 'table.column.width' },
      { field: 'type', header: 'table.column.type' }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/resource/add']);
  }

  public doEdit(resource: Resource): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('resourceId', resource.id);
    this.router.navigate(['/pages/resource/edit']);
  }

  public doDelete(resource: Resource): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<Resource>>('/resource/delete', resource)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
                this.setStateReady();
              }
            });
        }
      });
  }
}
