import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { TextUtils } from '../../utils';
import * as tabAction from './actions/tab.action';
import { TabItemChanges } from './interfaces/tab-item-changes.interface';
import { TabStoreService } from './services/tab-store.service';
@Component({
  selector: 'app-tab-item',
  templateUrl: './app-tab-item.component.html',
  styleUrls: ['./app-tab-item.component.scss']
})
export class AppTabItemComponent
  extends BaseComponentComponent
  implements OnChanges {
  @Input() header: string;
  @Input() disabled: boolean;
  @Input() selected: boolean;
  @Input() isDynamic: boolean;

  @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter();

  public tabItemId: string;
  constructor(
    public tabStoreService: TabStoreService,
    private elementRef: ElementRef
  ) {
    super('app-tab-item');
  }

  onInit(): void {
    this.doSetTabItemId();
    this.doDispatchAddTabItem();
  }

  public doChangeTab(event: MouseEvent): void {
    if (!this.tabStoreService.tabState.tabDisabled[this.tabItemId]) {
      if (!this.isDynamic) {
        this.tabStoreService.tabState.activeId = this.tabItemId;
        this.tabStoreService.dispatch(
          new tabAction.ChangeTabItemSelected({
            id: this.tabItemId,
            selected: this.selected,
            isDynamic: this.isDynamic
          })
        );
      }
      this.onClick.emit(event);
    }
  }

  ngOnChanges(values: any): void {
    const tabItemChanges: TabItemChanges = {};
    if (values) {
      if (values.disabled) {
        tabItemChanges.type = 'DISABLED';
        tabItemChanges.value = values.disabled;
        this.handleNgOnChanges(tabItemChanges);
      }

      if (values.selected) {
        tabItemChanges.type = 'SELECTED';
        tabItemChanges.value = values.selected;
        this.handleNgOnChanges(tabItemChanges);
      }
    }
  }

  private doSetTabItemId(): void {
    this.tabItemId = `${
      this.tabStoreService.tabState.id
    }-${TextUtils.generateRandomString()}`;
  }

  private doDispatchAddTabItem(): void {
    this.tabStoreService.dispatch(
      new tabAction.AddTabItem({
        id: this.tabItemId,
        tabItemElement: this.elementRef.nativeElement,
        disabled: this.disabled,
        selected: this.selected,
        isDynamic: this.isDynamic
      })
    );
  }

  private handleNgOnChanges(tabItemChanges: TabItemChanges): void {
    if (
      tabItemChanges.value.previousValue !==
        tabItemChanges.value.currentValue &&
      !tabItemChanges.value.firstChange
    ) {
      if (tabItemChanges.type === 'DISABLED') {
        this.tabStoreService.dispatch(
          new tabAction.ChangeTabItemDisabled({
            id: this.tabItemId,
            disabled: this.disabled
          })
        );
      } else if (tabItemChanges.type === 'SELECTED') {
        this.tabStoreService.dispatch(
          new tabAction.ChangeTabItemSelected({
            id: this.tabItemId,
            selected: this.selected,
            isDynamic: this.isDynamic
          })
        );
      }
    }
  }
}
