import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorContentManagementComponent } from './vendor-content-management.component';
const routes = [
  {
    path: '',
    component: VendorContentManagementComponent,
    data: { breadcrumb: 'index' }
  },
  {
    path: 'toc',
    loadChildren: () => import('./toc/toc.module').then(m => m.TocModule),
    data: { breadcrumb: 'vendor-content-management.breadcrumb.toc' }
  },
  {
    path: 'registration-document',
    loadChildren: () =>
      import('./registration-document/registration-document.module').then(
        m => m.RegistrationDocumentModule
      ),
    data: {
      breadcrumb: 'vendor-content-management.breadcrumb.registrationDocument'
    }
  },
  {
    path: 'manage-catalog',
    loadChildren: () => import('./toc/toc.module').then(m => m.TocModule),
    data: { breadcrumb: 'vendor-content-management.breadcrumb.toc' }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [VendorContentManagementComponent]
})
export class VendorContentManagementModule {}
