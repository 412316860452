<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-xx
        header="Submit Quotation List"
        [model]="tableResponse"
        stringUrl="/submit-quotation-vendor/index"
        isShowButtonModifyTable="true"
        (onClick)="doEdit($event)"
      >
        <ng-template #headerFilterGroup>
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
            *ngIf="!formLoading"
          >
            <div class="form-group row text-sm-left" style="margin: auto">
              <label class="cml-3 d-flex align-items-center">{{
                'submit-quotation-vendor.form.lastSubmissionDate' | translate
              }}</label>
              <div class="cml-3 text-left">
                <app-date-picker-x
                  size="XL"
                  formControlName="date"
                  [range]="true"
                  (onChange)="onChangeFilter()"
                >
                </app-date-picker-x>
              </div>
              <div class="cml-2 col-sm-2 text-left">
                <app-dropdown-select
                  size="XL"
                  formControlName="statusList"
                  (onChange)="onChangeFilter()"
                  showLimitValue="3"
                  type="CHOSEN"
                  placeholder="submit-quotation-vendor.placeholder.status"
                  [optionList]="statusOptionList"
                >
                </app-dropdown-select>
              </div>
            </div>
          </form>
        </ng-template>
      </app-table-xx>
    </div>
  </div>
</app-layout>
