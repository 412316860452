<app-popup
  header="contract-draft.header.version-history"
  [isLoading]="formLoading"
>
  <div class="removeSearchText app-table-search">
    <app-table [model]="tableResponse"> </app-table>
  </div>
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center">
      <app-button
        color="SECONDARY"
        (onClick)="activeModal.close(true)"
        [disabled]="formSaving"
      >
        {{ 'app.button.close' | translate }}
      </app-button>
    </div>
  </ng-template>
</app-popup>
