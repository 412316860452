<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="vendor-reactivation.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-table [tableResponse]="tableResponse" [isServerSide]="true"
        stringUrl="/vendor-reactivation/index">
        <ng-template #actionButtons let-rowData>
          <app-button
            *ngIf="rowData.vendor.vendorActivityStatus.code == 'REJECT_REGISTRATION' 
            || rowData.vendor.vendorActivityStatus.code == 'REVISION_REACTIVATION' "
            (onClick)="doEdit(rowData)" size="TN"
            title="{{ 'app.tooltip.edit' | translate }}">
            <em class="fas fa-pencil-alt"></em>
          </app-button>
          <app-button mode="BYPASS"
            *ngIf="rowData.vendor.vendorActivityStatus.code == 'REJECT_REGISTRATION' "
            size="TN" (onClick)="doViewVendor(rowData)"
            title="{{ 'app.tooltip.view' | translate }}">
            <em class="fas fa-search"></em>
          </app-button>
          <app-button mode="BYPASS"
            *ngIf="rowData.vendor.vendorActivityStatus.code == 'APPROVAL_REACTIVATION' "
            size="TN" (onClick)="doView(rowData)"
            title="{{ 'app.tooltip.view' | translate }}">
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>