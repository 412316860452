import { DueDiligenceExecutor } from 'src/app/core/bean/due-diligence-executor';
import { DueDiligenceSchedule } from 'src/app/core/bean/due-diligence-schedule';
import { VendorHistory } from 'src/app/core/bean/vendor-history';

export class DueDiligenceRequest {
    vendorHistory: VendorHistory = new VendorHistory();
    isTemplateDifferent: boolean;
    isSendEmailDueDiligence: boolean;
    dueDiligenceScheduleOTS: DueDiligenceSchedule = new DueDiligenceSchedule();
    dueDiligenceScheduleInterview: DueDiligenceSchedule = new DueDiligenceSchedule();
    dueDiligenceExecutorOTSList: DueDiligenceExecutor[] = [];
    dueDiligenceExecutorInterviewList: DueDiligenceExecutor[] = [];
    userIdTaskGiverList: number[] = [];
    userIdAcknowledgedByList: number[] = [];
}
