import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAccordionModule } from '../app-accordion/app-accordion.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppFieldsetModule } from '../app-fieldset/app-fieldset.module';
import { AppTableModule } from '../table/components/app-table/app-table.module';
import { AppProcurementInfoComponent } from './app-procurement-info.component';

@NgModule({
  imports: [
    VendorModule,
    AppTableModule,
    AppCardModule,
    AppAccordionModule,
    AppFieldsetModule
  ],
  declarations: [AppProcurementInfoComponent],
  exports: [AppProcurementInfoComponent]
})
export class AppProcurementInfoModule {}
