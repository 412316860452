import { DueDiligenceAssigner } from 'src/app/core/bean/due-diligence-assigner';
import { DueDiligenceExecutor } from 'src/app/core/bean/due-diligence-executor';
import { DueDiligenceInterview } from 'src/app/core/bean/due-diligence-interview';
import { DueDiligenceRealization } from 'src/app/core/bean/due-diligence-realization';
import { DueDiligenceSchedule } from 'src/app/core/bean/due-diligence-schedule';
import { DueDiligenceTemplateInterview } from 'src/app/core/bean/due-diligence-template-interview';
import { VendorHistory } from 'src/app/core/bean/vendor-history';
import { VendorVerification } from 'src/app/core/bean/vendor-verification';

export class InterviewResponse {

    vendorHistory: VendorHistory = new VendorHistory();
    vendorVerification: VendorVerification = new VendorVerification();
    isUserApproval: boolean;

    isTemplateDifferent: boolean;
    dueDiligenceScheduleInterview: DueDiligenceSchedule = new DueDiligenceSchedule();
    dueDiligenceAssignerTaskGiverList: DueDiligenceAssigner[] = [];
    dueDiligenceAssignerAcknowledgedByList: DueDiligenceAssigner[] = [];
    dueDiligenceExecutorInterviewList: DueDiligenceExecutor[] = [];

    interviewQuestionList: DueDiligenceTemplateInterview[] = [];
    dueDiligenceRealizationInterview: DueDiligenceRealization;
    dueDiligenceInterviewList: DueDiligenceInterview[] = [];
}
