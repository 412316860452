import { FormControl } from '@angular/forms';
import { UploadMapper } from '../mapper/upload-mapper';
import { FileObject } from '../model/file-object';
import { Uploader } from '../model/uploader';
export function createFileFromFormControlValue(
  uploader: Uploader,
  formControl: FormControl
): Uploader {
  const fileObjectList: FileObject[] = formControl.value;
  if (fileObjectList !== null) {
    uploader.uploaderFileList = UploadMapper.toModel(uploader, fileObjectList);
    uploader.isFormControlChanges = true;
  }
  formControl.valueChanges.subscribe((fileObjectList: FileObject[]) => {
    if (
      !uploader.isFormControlChanges &&
      fileObjectList &&
      uploader.uploaderFileList.length !== fileObjectList.length
    ) {
      uploader.uploaderFileList = UploadMapper.toModel(
        uploader,
        fileObjectList
      );
      uploader.isFormControlChanges = true;
    }
  });
  return uploader;
}
