import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../core/shared/shared.module';
import { ExampleOneComponent } from './example-one.component';
const routes = [{ path: '', component: ExampleOneComponent, data: { breadcrumb: '' } }];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ExampleOneComponent]
})
export class ExampleOneModule {}
