import { Injectable } from '@angular/core';
@Injectable()
export class TimeService {
  public wibOffset = 7;
  constructor() { }

  convertToCurrentTimeZone(date: Date): Date {
    if (!date) return null;
    const currentOffset = date.getTimezoneOffset() * 60000;
    const GMTPlus0700Offset = this.wibOffset * 60 * 60 * 1000;
    const convertedTimeInMillis = date.getTime() + currentOffset + GMTPlus0700Offset;
    return new Date(convertedTimeInMillis);
  }

  convertToWIB(date: Date): Date {
    if (!date) return null;
    const originalTimestamp = date.getTime();
    const originalOffsetMinutes = date.getTimezoneOffset();
    const wibOffsetMinutes = - (this.wibOffset * 60);
    const offsetDifferenceMinutes = originalOffsetMinutes - wibOffsetMinutes;
    const newTimestamp = originalTimestamp - (offsetDifferenceMinutes * 60 * 1000);
    return new Date(newTimestamp);

  }
}
