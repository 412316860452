import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../../../core/shared/shared.module';
import { SampleEditAddComponent } from './sample-edit-add.component';
import { SampleComponent } from './sample.component';
export const routes = [
  {
    path: '',
    component: SampleComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'add',
    component: SampleEditAddComponent,
    data: { breadcrumb: 'sample.breadcrumb.add' }
  },
  {
    path: 'edit',
    component: SampleEditAddComponent,
    data: { breadcrumb: 'sample.breadcrumb.edit' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [
    SampleComponent,
    SampleEditAddComponent
  ],
  providers: []
})
export class SampleModule {}
