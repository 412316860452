import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Payment } from 'src/app/core/bean/payment';
import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { PaymentHistory } from 'src/app/core/bean/payment-history';
import { PaymentInvoice } from 'src/app/core/bean/payment-invoice';
import { SowPaymentTermBilling } from 'src/app/core/bean/sow-payment-term-billing';
import { StageOfWork } from 'src/app/core/bean/stage-of-work';
import { WorkflowModelPrcs } from 'src/app/core/bean/workflow-model-prcs';
import { WorkflowPrcs } from 'src/app/core/bean/workflow-prcs';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { WorkflowStepInfoModel } from 'src/app/core/components/app-workflow-step-info/model/workflow-step-info-model';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { PaymentBillingView } from 'src/app/core/view/entity/bean/payment-billing-view';
import { PaymentBillingDoc } from '../../core/bean/payment-billing-doc';
import { PaymentRequestReviewEditRequest } from './payment-request-review-edit-request';
import { PaymentRequestReviewEditResponse } from './payment-request-review-edit-response';

@Component({
  templateUrl: './payment-request-review-edit.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None
})
export class PaymentRequestReviewEditComponent
  extends BaseModuleComponent
  implements OnInit
{
  public tableResponse: TableResponseModel<PaymentBillingDoc>;
  public paymentId: number;
  public sowPaymentTermId: number;
  public deliveryItemId: number;
  public payment: Payment = new Payment();
  public paymentBilling: PaymentBilling = new PaymentBilling();
  public paymentHistory: PaymentHistory = new PaymentHistory();
  public paymentInvoice: PaymentInvoice = new PaymentInvoice();
  public fileUploaderBillingTermList: FileUploader[] = [];
  public sowPaymentTermBillingList: SowPaymentTermBilling[];
  public paymentBillingDocList: PaymentBillingDoc[];
  public paymentHistoryList: PaymentHistory[];
  public paymentRequestReviewEditRequest: PaymentRequestReviewEditRequest =
    new PaymentRequestReviewEditRequest();
  public paymentRequestReviewEditResponse: PaymentRequestReviewEditResponse =
    new PaymentRequestReviewEditResponse();
  public paymentBillingView: PaymentBillingView = new PaymentBillingView();
  public tableResponsePaymentHistory: TableResponseModel<PaymentHistory>;
  public statusOptionList: OptionListModel<any> = new OptionListModel();
  public urlBack: string;
  public isLoading: boolean;
  public index: number;
  public objectId: number;
  public paymentBillingId: number;
  public workflowPrcs: WorkflowPrcs;
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public stageOfWork: StageOfWork;
  public approvalModelPrcsId: number;
  public workflowStepInfoModel: WorkflowStepInfoModel;
  public isDeliverableItem: boolean;
  public token: string;

  public fileUploader: FileUploader = new FileUploader(
    '/payment-request-review/',
    '',
    this.global.appConstant.fileType.DOC_INVOICE
  );
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public activatedRoute: ActivatedRoute
  ) {
    super('payment-request-review', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<PaymentBillingView>(
        '/payment-request-review/notification/' + this.token
      )
      .subscribe((paymentBillingView: PaymentBillingView) => {
        this.global.routerParams.clear();
        this.global.routerParams.set(
          'paymentId',
          paymentBillingView.payment.id
        );
        this.global.routerParams.set('payment', paymentBillingView.payment);
        this.global.routerParams.set('vendor', paymentBillingView.vendor);
        this.global.routerParams.set('paymentBillingView', paymentBillingView);
        this.global.routerParams.set(
          'paymentInvoice',
          paymentBillingView.paymentInvoice
        );
        this.global.routerParams.set(
          'urlBackOutside',
          '/pages/payment-request-review'
        );
        if (
          paymentBillingView.payment.paymentStatus.code ===
            this.global.appConstant.cm.PAYMENT_STATUS_WAITING_FOR_REVIEW &&
          paymentBillingView.payment.paymentStatus.code ===
            this.global.appConstant.cm.PAYMENT_STATUS_REVISION
        ) {
          this.global.routerParams.set('todo', 'edit');
          this.router.navigate(['/pages/payment-request-review/edit']);
        } else {
          this.global.routerParams.set('todo', 'view');
          this.router.navigate(['/pages/payment-request-review/detail']);
        }
      });
  }

  public setInitializationState(): void {
    this.buildTableResponse();
    this.setDataFromRouterParams();
    this.setFormGroup();
    this.doSetStatusOptionList();
    this.getAndSetPaymentHistoryList();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.doSetCustomData();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.paymentBillingId = this.global.routerParams.get('paymentBillingId');
    this.objectId = this.global.routerParams.get('objectId');
    this.paymentId = this.global.routerParams.get('paymentId') || this.objectId;
    this.urlBack = this.global.routerParams.get('urlBackOutside');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
  }

  public doSetCustomData(): void {
    this.tableResponse.setCustomData(this.paymentId);
  }

  public doSetStatusOptionList(): void {
    const statusList = [
      {
        view: this.global.translateService.instant(
          'payment-request-review.status.revision'
        ),
        value: 2
      },
      {
        view: this.global.translateService.instant(
          'payment-request-review.status.approved'
        ),
        value: 3
      }
    ];
    this.statusOptionList
      .setRequestValues(statusList)
      .setViewPath('view')
      .setValuePath('value');
  }

  public getAndSetPaymentHistoryList(): void {
    this.httpClientService
      .post<PaymentHistory[]>(
        '/payment-request-review/get-payment-history-list',
        new RouteRequestModel(null, this.paymentId)
      )
      .subscribe(paymentHistoryList => {
        this.paymentHistoryList = paymentHistoryList;
        if (paymentHistoryList !== []) {
          this.tableResponsePaymentHistory.page.records =
            this.paymentHistoryList;
          this.tableResponsePaymentHistory.page.totalRecords =
            this.paymentHistoryList && this.paymentHistoryList.length;
          this.tableResponsePaymentHistory.reloadClient();
        }
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'sowPaymentTermBilling.billingTerm.name',
        header: 'table.column.billingTerm'
      },
      {
        field: 'code',
        header: 'table.column.documentNumber'
      },
      {
        field: 'billingDocDate',
        header: 'table.column.documentDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'file.fileName',
        header: 'table.column.uploadFile',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName'
      }
    ]);

    this.tableResponsePaymentHistory = new TableResponseModel(this.moduleCode, [
      {
        field: 'date',
        header: 'table.column.documentDate',
        format: FieldFormatEnum.ShortDateAndTime
      },
      {
        field: 'status',
        header: 'table.column.status',
        fn: this.handleStatusTextColor.bind(this)
      },
      {
        field: 'picName',
        header: 'table.column.picName'
      },
      {
        field: 'note',
        header: 'table.column.note',
        customClass: 'white-space-normal'
      }
    ]);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      status: [null, Validators.required()],
      note: [null, Validators.required()],
      isSubmit: [false]
    });
  }

  public handleStatusTextColor(value: string, record: any): HTMLElement {
    this.log.debug(value);
    const spanElement = document.createElement('span');
    if (record.status === 1) {
      spanElement.style.color = 'var(--black)';
      spanElement.textContent = this.global.translateService.instant(
        'payment-request-review.new'
      );
    } else if (record.status === 2) {
      spanElement.style.color = 'var(--warning)';
      spanElement.textContent = this.global.translateService.instant(
        'payment-request-review.revision'
      );
    } else if (record.status === 3) {
      spanElement.style.color = 'var(--success)';
      spanElement.textContent = this.global.translateService.instant(
        'payment-request-review.approved'
      );
    }
    return spanElement;
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setFormGroup(): void {
    this.paymentRequestReviewEditRequest.paymentId = this.paymentId;
    this.httpClientService
      .post<PaymentRequestReviewEditResponse>(
        '/payment-request-review/edit',
        this.paymentRequestReviewEditRequest
      )
      .subscribe(
        (
          paymentRequestReviewEditResponse: PaymentRequestReviewEditResponse
        ) => {
          this.paymentRequestReviewEditResponse =
            paymentRequestReviewEditResponse;
          this.isDeliverableItem =
            this.paymentRequestReviewEditResponse.isDeliverableItem;
          this.paymentBillingView =
            paymentRequestReviewEditResponse.paymentBillingViewBean;
          this.paymentInvoice = this.paymentBillingView.paymentInvoice;

          this.payment =
            paymentRequestReviewEditResponse.paymentBillingViewBean.payment;
          this.workflowPrcs = this.payment.workflowPrcs;
          this.workflowModelPrcs = this.payment.workflowModelPrcs;

          this.paymentHistory = paymentRequestReviewEditResponse.paymentHistory;
          if (this.paymentHistory !== null) {
            this.payment =
              paymentRequestReviewEditResponse.paymentHistory.payment;
            this.workflowPrcs = this.payment.workflowPrcs;
            this.workflowModelPrcs = this.payment.workflowModelPrcs;
          }
          if (this.todo === 'view') {
            if (this.paymentHistory != null) {
              const status = this.statusOptionList
                .getRequestValues()
                .filter(
                  status => status.value === this.paymentHistory.status
                )[0];
              let data = localStorage.getItem('temp') as Object;
              if (data) {
                data = JSON.parse(data.toString()) as PaymentHistory;
                this.formGroup.patchValue(data);
                localStorage.removeItem('temp');
                localStorage.removeItem('todo');
              } else {
                this.formGroup.patchValue({
                  status: status?.value,
                  note: this.paymentHistory.note
                });
              }
            }
          }
          this.setStateReady();
        }
      );
  }

  public doSave(): void {
    this.formGroup.get('isSubmit').patchValue(true);
    this.validate();
    this.paymentHistory.isSubmit = false;
    this.paymentHistory.payment = this.payment;
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.paymentHistory.status = this.formGroup.get('status').value;
            this.paymentHistory.note = this.formGroup.get('note').value;
            this.httpClientService
              .post<Response<PaymentHistory>>(
                '/payment-request-review/insert',
                this.paymentHistory
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/payment-request-review/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }
  public doCancel(): void {
    this.router.navigate([this.urlBack]);
  }
}
