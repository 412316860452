import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ContractParameterEditAddComponent } from './contract-parameter-edit-add.component';
import { ContractParameterComponent } from './contract-parameter.component';
export const routes = [
  { path: '', component: ContractParameterComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: ContractParameterEditAddComponent,
    data: { breadcrumb: 'contract-parameter.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: ContractParameterEditAddComponent,
    data: { breadcrumb: 'contract-parameter.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [ContractParameterComponent, ContractParameterEditAddComponent],
  providers: []
})
export class ContractParameterModule {}
