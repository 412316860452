import { Region } from '../bean/region';
import { BaseEntity } from './../base/base-entity';
import { BuildingLocation } from './../bean/building-location';
import { BuildingType } from './../bean/building-type';
import { ResourceOwnership } from './../bean/resource-ownership';
import { Vendor } from './../bean/vendor';

export class VendorAddressEntity extends BaseEntity {
  phoneNumber: string;
  countryRegion: Region;
  provinceRegion: Region;
  cityRegion: Region;
  districtRegion: Region;
  phoneRegion: Region;
  faxRegion: Region;
  vendor: Vendor;
  buildingType: BuildingType;
  buildingLocation: BuildingLocation;
  resourceOwnership: ResourceOwnership;
  buildingName: string;
  size: number;
  address: string;
  postCode: Region;
  phone: string;
  fax: string;
  poBox: string;
  isMain: boolean;
  coordinateLocation: string;
}
