import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { Employee } from '../../../core/bean/employee';
import { AppPopupService } from '../../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../../core/components/app-table/model/table-response-model';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { SnackbarService } from '../../../core/services/snackbar.services';
import { EmployeePopupComponent } from './employee-popup.component';
@Component({
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Employee>;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public snackbarService: SnackbarService
  ) {
    super('employee', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.col.name'
      },
      { field: 'address', header: 'table.col.address' },
      {
        field: 'birthDate',
        header: 'table.col.dob',
        format: FieldFormatEnum.ShortDate
      },
      { field: 'region.name', header: 'table.col.country' },
      {
        field: 'npwpfile.fileName',
        header: 'NPWP',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'npwpfile.uploadedFileName'
      },
      {
        field: 'id',
        header: 'select',
        format: FieldFormatEnum.CheckBox,
        sortable: false
      }
    ]);
  }

  public doOpen(): void {
    this.appPopupService.open(EmployeePopupComponent, { static: true });
  }
  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/employee/add']);
  }

  public doEdit(employee: Employee): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('id', employee.id);
    this.router.navigate(['/pages/employee/edit']);
  }

  public doView(employee: Employee): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('id', employee.id);
    this.router.navigate(['/pages/employee/edit']);
  }

  public doCart(): void {
    this.router.navigate(['/pages/cart']);
  }

  public doDelete(employee: Employee): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<Employee>>('/employee/delete', employee)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
