<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="resource.title" detail="app.action.{{ todo }}"
      [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'resource.form.code' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field type="code" autofocus="true" formControlName="code"
              maxLength="64"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'resource.form.name' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field type="alphanumeric" formControlName="name"
              maxLength="128"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'resource.form.height' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field type="integer" formControlName="height"
              maxLength="11"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'resource.form.width' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field type="integer" formControlName="width"
              maxLength="11"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'resource.form.type' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box [optionList]="typeOptionList" formControlName="type">
            </app-combo-box>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'resource.form.imgFile' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-upload formControlName="file" [fileUploader]="fileUploader">
            </app-upload>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button color="SECONDARY" class="mr-1" (onClick)="doBack()"
            [disabled]="formSaving" mode="BYPASS">
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button color="PRIMARY" (onClick)="doSave()"
            [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>

      </form>
    </app-card>
  </div>
</div>