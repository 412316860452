import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { BiddingEvaluation } from 'src/app/core/bean/bidding-evaluation';
import { Quotation } from 'src/app/core/bean/quotation';
import { QuotationItem } from 'src/app/core/bean/quotation-item';
import { VendorSelectionType } from 'src/app/core/bean/vendor-selection-type';
import { TableHeaderPluginData } from '../../table/interface/table-plugin-data';
import { TableResponseModel } from '../../table/model/table-response-model';
import { AppPopupFinalPriceDetailFieldComponent } from './app-popup-final-price-detail-field.component';
import { AppPopupFinalPriceDetailResponse } from './app-popup-final-price-detail.response';
@Component({
  selector: 'app-popup-final-price-detail',
  templateUrl: './app-popup-final-price-detail.component.html'
  // styleUrls: ['./app-popup-final-price-detail.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class AppPopupFinalPriceDetailComponent extends BaseComponentComponent {
  @Input() procurementVendorId: number;
  @Input() biddingEvaluation: BiddingEvaluation;
  @Input() vendorSelectionType: VendorSelectionType;
  @Input() fromModuleCode: string;
  public response: AppPopupFinalPriceDetailResponse;
  public tableResponse: TableResponseModel<QuotationItem>;
  public quotation: Quotation;
  public isSingleWinner: boolean;
  public isMeritPoint: boolean;
  public isPassingGrade: boolean;
  public isLeastCostSystem: boolean;

  constructor(public translateService: TranslateService) {
    super('app-popup-final-price-detail');
  }

  public onInit(): void {
    this.setCondition();
    this.setFormGroup();
  }

  public setCondition(): void {
    if (
      this.vendorSelectionType?.code ===
      this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
    ) {
      this.isSingleWinner = true;
    } else {
      this.isSingleWinner = false;
    }

    if (
      this.biddingEvaluation?.code ===
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
    ) {
      this.isMeritPoint = true;
    } else if (
      this.biddingEvaluation?.code ===
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_PASSING_GRADE
    ) {
      this.isPassingGrade = true;
    } else if (
      this.biddingEvaluation?.code ===
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
    ) {
      this.isLeastCostSystem = true;
    }
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<AppPopupFinalPriceDetailResponse>(
        '/app-popup-final-price-detail/detail/' + this.procurementVendorId
      )
      .subscribe((response: AppPopupFinalPriceDetailResponse) => {
        this.response = response;
        this.quotation = response.quotation;

        this.doBuildTableResponse();
        this.doSetCustomData();
        this.setStateReady();
      });
  }

  public doBuildTableResponse(): void {
    if (this.isSingleWinner) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: '',
          header: 'table.column.itemName',
          component: AppPopupFinalPriceDetailFieldComponent,
          isSortable: false
        },
        {
          field: 'procurementItem.prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'procurementItem.prItem.item.itemType.code'
          },
          isSortable: false
        },
        {
          field: '',
          header: 'table.column.bidPrice',
          component: AppPopupFinalPriceDetailFieldComponent,
          isSortable: false
        },
        {
          field: '',
          header: 'table.column.finalPrice',
          component: AppPopupFinalPriceDetailFieldComponent,
          isSortable: false
        }
      ]);
    } else {
      if (this.isLeastCostSystem) {
        this.tableResponse = new TableResponseModel(this.moduleCode, [
          {
            field: '',
            header: 'table.column.itemName',
            component: AppPopupFinalPriceDetailFieldComponent,
            isSortable: false
          },
          {
            field: 'procurementItem.prItem.item.itemType.name',
            header: 'table.column.type',
            plugins: {
              name: 'badge',
              pill: true,
              colorMap: {
                MATERIAL: 'GOOD',
                JASA: 'SERVICE'
              },
              colorField: 'procurementItem.prItem.item.itemType.code'
            },
            isSortable: false
          },
          {
            field: 'finalScoringStatus.name',
            header: 'table.column.evaluationPrice',
            plugins: {
              name: 'badge',
              pill: true,
              colorMap: {
                PASSED: 'SUCCESS',
                NOT_PASSED: 'DANGER',
                WAITING_SCORING: 'WARNING'
              },
              colorField: 'finalScoringStatus.code'
            },
            isSortable: false
          },
          {
            field: '',
            header: 'table.column.oep',
            className: 'text-right',
            component: AppPopupFinalPriceDetailFieldComponent,
            isSortable: false
          },
          {
            field: '',
            header: 'table.column.bidPrice',
            component: AppPopupFinalPriceDetailFieldComponent,
            isSortable: false
          },
          {
            field: '',
            header: 'table.column.finalPrice',
            component: AppPopupFinalPriceDetailFieldComponent,
            isSortable: false
          }
        ]);
      } else {
        /** passing grade, merit point */
        this.tableResponse = new TableResponseModel(this.moduleCode, [
          {
            field: '',
            header: 'table.column.itemName',
            component: AppPopupFinalPriceDetailFieldComponent,
            isSortable: false
          },
          {
            field: 'procurementItem.prItem.item.itemType.name',
            header: 'table.column.type',
            plugins: {
              name: 'badge',
              pill: true,
              colorMap: {
                MATERIAL: 'GOOD',
                JASA: 'SERVICE'
              },
              colorField: 'procurementItem.prItem.item.itemType.code'
            },
            isSortable: false
          },
          {
            field: 'finalEvaluationScore',
            plugins: {
              name: 'text-field',
              isView: true,
              type: 'decimal'
            },
            header: {
              key: 'table.column.evaluationPrice',
              plugins: {
                name: 'custom-plugin',
                after: (tableHeaderPlugin: TableHeaderPluginData): void => {
                  if (tableHeaderPlugin) {
                    if (this.isMeritPoint) {
                      tableHeaderPlugin.element.innerText +=
                        ' (' +
                        (this.response.technicalPercentage || ' - ') +
                        '%)';
                    }
                  }
                }
              }
            },
            isSortable: false
          },
          {
            field: '',
            header: 'table.column.oep',
            className: 'text-right',
            component: AppPopupFinalPriceDetailFieldComponent,
            isSortable: false
          },
          {
            field: '',
            header: 'table.column.bidPrice',
            component: AppPopupFinalPriceDetailFieldComponent,
            isSortable: false
          },
          {
            field: '',
            header: 'table.column.finalPrice',
            component: AppPopupFinalPriceDetailFieldComponent,
            isSortable: false
          }
        ]);
      }
    }
  }

  public doSetCustomData(): void {
    const customData = {
      procurementVendorId: this.procurementVendorId,
      moduleCode: this.fromModuleCode
    };
    this.tableResponse.setCustomData(customData);
  }
}
