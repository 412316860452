import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { Dr } from '../bean/dr';
import { ExportStatus } from '../bean/export-status';
import { OrderActivityStatus } from '../bean/order-activity-status';
import { OrderPreparation } from '../bean/order-preparation';
import { OrderStatus } from '../bean/order-status';
import { OrderType } from '../bean/order-type';
import { Organization } from '../bean/organization';
import { Vendor } from '../bean/vendor';

export class OrderEntity extends BaseWorkflowObjectEntity {
  orderType: OrderType;
  vendor: Vendor;
  orderStatus: OrderStatus;
  orderActivityStatus: OrderActivityStatus;
  dr: Dr;
  orderPreparation: OrderPreparation;
  amount: number;
  goodsAmount: number;
  serviceAmount: number;
  title: string;
  requiredDate: Date;
  postDate: Date;
  description: string;
  source: string;
  isNextProcessExist: boolean;
  organizationDistributor: Organization = new Organization ();
  exportStatus: ExportStatus;
}
