import { ProcurementPhase } from 'src/app/core/bean/procurement-phase';
import { BaseDTO } from '../../../core/base/base-dto';
import { Procurement } from '../../../core/bean/procurement';
import { ScoringCriteriaGroup } from '../../../core/bean/scoring-criteria-group';
import { ScoringTemplate } from '../../../core/bean/scoring-template';
import { ScoringTemplateWeight } from '../../../core/bean/scoring-template-weight';
import { Vendor } from '../../../core/bean/vendor';
import { VendorAddress } from '../../../core/bean/vendor-address';
import { VendorPerformance } from '../../../core/bean/vendor-performance';
import { VendorPerformanceScore } from '../../../core/bean/vendor-performance-score';
import { VendorPerformanceSummary } from '../../../core/bean/vendor-performance-summary';
import { FileObject } from '../../../core/components/upload';
import { PerformanceProcurementCriteriaModel } from '../model/performance-procurement-criteria-model';
export class PerformanceProcurementDTO extends BaseDTO {
  vendor: Vendor = new Vendor();
  vendorAddress: VendorAddress = new VendorAddress();
  procurement: Procurement = new Procurement();

  scoringCriteriaGroupListCommon: ScoringCriteriaGroup[] = [];
  scoringCriteriaGroupListSpecific: ScoringCriteriaGroup[] = [];
  scoringTemplateWeightList: ScoringTemplateWeight[] = [];
  scoringTemplate: ScoringTemplate = new ScoringTemplate();

  performance: VendorPerformance = new VendorPerformance();
  performanceScoreList: VendorPerformanceScore[] = [];
  performanceSummaryList: VendorPerformanceSummary[] = [];
  filePerformanceList: FileObject[] = [];
  isUserApproval: boolean;
  performanceList: VendorPerformance[] = [];

  criteriaProcurementPhaseModelList: Array<PerformanceProcurementCriteriaModel<ProcurementPhase>> = [];
}
