import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorPosition } from '../../core/bean/vendor-position';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';

@Component({
  templateUrl: './vendor-position-edit-add.component.html'
})
export class VendorPositionEditAddComponent extends BaseModuleComponent {
  public vendorPositionId: number;
  public positionTypeCodeOptionList: OptionListModel<any> = new OptionListModel();

  constructor(translateService: TranslateService) {
    super('vendor-position', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setOptionListRequestValue();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorPositionId = this.global.routerParams.get('vendorPositionId');
  }

  public setOptionListRequestValue(): void {
    const positionTypeCodeList = [
      { id: 'COMMI', name: 'Commissioner' },
      { id: 'DIR', name: 'Director' },
      { id: 'COM', name: 'Common' }
    ];
    this.positionTypeCodeOptionList.setRequestValues(positionTypeCodeList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      description: [null, Validators.maxLength(512)],
      positionTypeCode: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<VendorPosition>(
        '/vendor-position/add-edit',
        new RouteRequestModel(this.todo, this.vendorPositionId)
      )
      .subscribe(vendorPosition => {
        if (vendorPosition != null) {
          let name: string;
          if (vendorPosition.positionTypeCode !== 'COM') {
            name =
              vendorPosition.positionTypeCode === 'COMMI'
                ? 'Commissioner'
                : 'Director';
          } else {
            name = 'Common';
          }
          const positionTypeCodeObj = {
            id: vendorPosition.positionTypeCode,
            name
          };
          this.formGroup.patchValue(vendorPosition);
          this.formGroup.patchValue({
            positionTypeCode: positionTypeCodeObj
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const vendorPosition: VendorPosition = this.global.copyFormAttributeToModel(
              new VendorPosition(),
              this.formGroup
            );
            const positionTypeCodeList = [
              this.formGroup.get('positionTypeCode').value
            ];
            positionTypeCodeList.forEach(positionTypeCode => {
              vendorPosition.positionTypeCode = positionTypeCode.id;
            });
            const url: string =
              this.todo === 'edit'
                ? '/vendor-position/update'
                : '/vendor-position/insert';
            this.httpClientService
              .post<Response<VendorPosition>>(url, vendorPosition)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/vendor-position/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/vendor-position/']);
  }

  public get formGroupControls() {
    return this.formGroup.controls;
  }
}
