import { File } from '../../core/bean/file';
import { Organization } from '../../core/bean/organization';
import { TorSubmission } from '../../core/bean/tor-submission';
import { WorkPlan } from '../../core/bean/work-plan';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path';

export class TorSubmissionResponse {
  tor: TorSubmission;
  isNumberGenerator: boolean;
  workPlan: WorkPlan;
  fileList: File[];
  organizationList: Organization[];
  isUserApproval: boolean;
  approvalPathResponseModel: ApprovalPathResponseModel;
}
