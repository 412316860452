<app-popup header="app-popup-analysis-multiple.title" [isLoading]="formLoading">
  <div *ngIf="!formLoading">
    <div class="form-group row text-left">
      <label class="col-sm-3 control-label">{{
        'app-popup-analysis-multiple.form.prNumber' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ procurementItem.prItem.pr.code }}
      </div>
    </div>
    <div class="form-group row text-left">
      <label class="col-sm-3 control-label">{{
        'app-popup-analysis-multiple.form.itemName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ procurementItem.prItem.item.name }}
      </div>
    </div>
    <div class="form-group row text-left">
      <label class="col-sm-3 control-label">{{
        'app-popup-analysis-multiple.form.itemCode' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ procurementItem.prItem.item.code }}
      </div>
    </div>
    <div class="form-group row text-left">
      <label class="col-sm-3 control-label">{{
        'app-popup-analysis-multiple.form.unitValue' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          global.converterService.convertMoney(procurementItem.prItem.price)
        }}
        IDR
      </div>
    </div>
  </div>
  <h4 class="separator"></h4>
  <app-table-xx
    *ngIf="!formLoading"
    [model]="tableResponse"
    stringUrl="/app-popup-analysis-multiple/index"
  >
  </app-table-xx>
</app-popup>
