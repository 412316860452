import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from '../../services/http-client.service';
@Injectable()
export class AppMapService {
  constructor(public httpClientService: HttpClientService) {}
  public getLocationByLatLng(latitude: string | number, longitude: string | number): Observable<any> {
    return this.httpClientService.post(`/app-map/get-location-name`, `${latitude},${longitude}`);
  }
}
