import {
  Component,
  ElementRef,
  Input,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { TextUtils } from '../../utils';
@Component({
  selector: 'app-radio',
  templateUrl: './app-radio.component.html',
  styleUrls: ['./app-radio.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: makeProvider(AppRadioComponent)
})
export class AppRadioComponent extends BaseValueAccessorComponent<
  string | number | boolean
> {
  @Input() position = 'VERTICAL';
  generatedId: string = TextUtils.generateRandomString();
  arrayPosition = ['VERTICAL', 'HORIZONTAL'];
  positionClassName: string;
  optionsCopy: Array<any> = [];
  viewValue: any;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super('app-radio', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setOptionsCopy();
    this.setOptionViewAndOptionValue();
    if (!this.ISVIEW) {
      this.setPosition();
    } else {
      this.setViewValue();
    }
  }

  setOptionsCopy(): void {
    if (!this.optionList.isWaitFromServer) {
      this.optionsCopy = this.optionList.getRequestValues();
    } else {
      this.optionList.requestValueChanges.subscribe((values: any) => {
        this.optionsCopy = values;
      });
    }
  }

  setOptionViewAndOptionValue(): void {
    this.optionView = this.optionList.viewPath
      ? String(this.optionList.viewPath)
      : this.optionView;
    this.optionValue = this.optionList.valuePath
      ? this.optionList.valuePath
      : this.optionValue;
  }

  setViewValue(): void {
    this.viewValue = this.optionList.getOptionByValue(
      this.formControl.value,
      this.optionValue || 'id'
    );
    this.formControl.valueChanges.subscribe(value => {
      this.viewValue = this.optionList.getOptionByValue(
        value,
        this.optionValue || 'id'
      );
    });
  }

  setPosition(): void {
    const indexOfPosition = this.arrayPosition.indexOf(
      this.position.toUpperCase()
    );
    if (indexOfPosition === -1) {
      throw new Error(`${this.position} is not position of 'position'`);
    }
    this.setPositionClassName();
  }

  setPositionClassName(): void {
    if (this.position === 'HORIZONTAL') {
      this.positionClassName = 'custom-control-inline';
    }
  }
}
