import { createTableCustomData } from '../helpers/create-table-custom-data';
import { resolveTableRecords } from '../helpers/resolve-table-records.helper';
import { TableState } from '../model/table-state';
export const firstLoadTable = (state: TableState): void => {
  state.tableRequest.customData = createTableCustomData(state.model.customData);
  resolveTableRecords(state).then((records: Array<any>) => {
    state.setRecords(records);
    state.setStateReady();
  });
};
