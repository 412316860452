<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card *ngIf="isSyncRunning">
      <div id="budget-allocation-sync-info" class="alert alert-warning"  role="alert" *ngIf="(websocketBudgetService.message | async)">
        <strong class="mr-2"><em class="fa fa-info-circle mr-2"></em>{{ 'budget-allocation-bjb.sync.info' | translate }}</strong>
        {{ (websocketBudgetService.message | async) }}
      </div>
      <div id="work-program-sync-info" class="alert alert-warning"  role="alert" *ngIf="(websocketWorkProgramService.message | async)">
        <strong class="mr-2"><em class="fa fa-info-circle mr-2"></em>{{ 'work-program.sync.info' | translate }}</strong>
        {{ (websocketWorkProgramService.message | async) }}
      </div>
    </app-card>
    <app-card
      header="budget-allocation-bjb.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
      *ngIf="!isSyncRunning"
    >
    <div *ngIf="!formLoading">
      <app-fieldset legend="Filter">
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'budget-allocation-bjb.form.coa' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="coaList"
                [optionList]="coaOptionList"
              ></app-chosen>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'budget-allocation-bjb.form.type' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="budgetTypeList"
                [optionList]="budgetTypeOptionList"
              ></app-chosen>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'budget-allocation-bjb.form.organization' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-tree
                formControlName="organizationList"
                stringUrl="/organization"
                isLazy="true"
                header="Organization"
                [isMultiple]="true"
              >
              </app-text-tree>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'budget-allocation-bjb.form.currency' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box
                [optionList]="currencyOptionList"
                formControlName="currency"
              ></app-combo-box>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'budget-allocation-bjb.form.periodFrom' | translate
            }}</label>
            <div class="col-sm-9 text-left" style="display: flex">
              <app-combo-box
                size="XL"
                [optionList]="budgetAllocationOptionList"
                formControlName="fromPeriod"
              ></app-combo-box>
              <p class="ml-3 mr-3 control-label">{{
                'budget-allocation-bjb.form.until' | translate
              }}</p>
              <app-combo-box
                size="XL"
                [optionList]="budgetAllocationOptionList"
                formControlName="untilPeriod"
              >
              </app-combo-box>
            </div>
          </div>

          <div class="button-group button-group-center mt-5">
            <app-button mode="BYPASS" color="SECONDARY" (onClick)="doReset()"
              [disabled]="(websocketBudgetService.message | async) || (websocketWorkProgramService.message | async)">
              <em class="fas fa-sync-alt"></em>
              {{ 'app.button.reset' | translate }}
            </app-button>
            <app-button
              mode="BYPASS"
              color="PRIMARY"
              (onClick)="doApply()"
              [disabled]="formSaving"
              [disabled]="(websocketBudgetService.message | async) || (websocketWorkProgramService.message | async)"
              ><em class="fas fa-check"></em>
              {{ 'app.button.apply' | translate }}
            </app-button>
          </div>
        </form>
      </app-fieldset>

      <app-card
        header="Resume"
        [isLoading]="formLoading"
        [isSaving]="formSaving"
        *ngIf="isLoading"
      >
        <form
          class="form-horizontal"
          [formGroup]="budgetInfoFormGroup"
          novalidate
        >
          <app-widget-content-slider>
            <app-widget-card>
              <h4 class="widget-card-header">{{
                'budget-allocation-bjb.widgetCard.totalBudget' | translate
              }}</h4>
              <h5
                >{{ budgetInfoFormGroup.value.totalBudgetResume }}
                {{ currencyCode == null ? 'IDR' : currencyCode }}</h5
              >
            </app-widget-card>
            <app-widget-card>
              <h4 class="widget-card-header">{{
                'budget-allocation-bjb.widgetCard.bookedBudget' | translate
              }}</h4>
              <h5
                >{{ budgetInfoFormGroup.value.bookedBudgetResume }}
                {{ currencyCode == null ? 'IDR' : currencyCode }}</h5
              >
            </app-widget-card>
            <app-widget-card>
              <h4 class="widget-card-header">{{
                'budget-allocation-bjb.widgetCard.usedBudget' | translate
              }}</h4>
              <h5
                >{{ budgetInfoFormGroup.value.usedBudgetResume }}
                {{ currencyCode == null ? 'IDR' : currencyCode }}</h5
              >
            </app-widget-card>
            <app-widget-card>
              <h4 class="widget-card-header">{{
                'budget-allocation-bjb.widgetCard.paidOffBudget' | translate
              }}</h4>
              <h5
                >{{ budgetInfoFormGroup.value.paidOffBudgetResume }}
                {{ currencyCode == null ? 'IDR' : currencyCode }}</h5
              >
            </app-widget-card>
            <app-widget-card>
              <h4 class="widget-card-header">{{
                'budget-allocation-bjb.widgetCard.remainingPayment' | translate
              }}</h4>
              <h5
                >{{ budgetInfoFormGroup.value.remainingPaymentResume }}
                {{ currencyCode == null ? 'IDR' : currencyCode }}</h5
              >
            </app-widget-card>
            <app-widget-card>
              <h4 class="widget-card-header"
                >{{
                  'budget-allocation-bjb.widgetCard.availableBudget' | translate
                }}
              </h4>
              <h5
                >{{ budgetInfoFormGroup.value.availableBudgetResume }}
                {{ currencyCode == null ? 'IDR' : currencyCode }}</h5
              >
            </app-widget-card>
          </app-widget-content-slider>
        </form>
      </app-card>
      <app-card
        header="budget-allocation-bjb.title"
        [isLoading]="formLoading"
        [isSaving]="formSaving"
      >
      <div id="budget-allocation-sync-info" class="alert alert-warning"  role="alert" *ngIf="(websocketBudgetService.message | async)">
        <strong class="mr-2"><em class="fa fa-info-circle mr-2"></em>{{ 'budget-allocation-bjb.sync.info' | translate }}</strong>
        {{ (websocketBudgetService.message | async) }}
      </div>
      <div id="work-program-sync-info" class="alert alert-warning"  role="alert" *ngIf="(websocketWorkProgramService.message | async)">
        <strong class="mr-2"><em class="fa fa-info-circle mr-2"></em>{{ 'work-program.sync.info' | translate }}</strong>
        {{ (websocketWorkProgramService.message | async) }}
      </div>
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
          <app-tab>
            <app-tab-item header="budget-allocation-bjb.byOrganization">
              <app-table
                [model]="tableResponseOrganization"
                [isServerSide]="true"
                key="by-organization-index"
                stringUrl="/budget-allocation-bjb/organization/index"
                isShowEditTable="true"
              >
                <ng-template #headerButtons>
                  <!-- BJB -->
                  <app-button
                    *ngIf="isAllowAccess"
                    mode="BYPASS"
                    (onClick)="doSync()"
                    [disabled]="formLoading || isLoadingSync || isLoadingReport 
                    || (websocketBudgetService.message | async) || (websocketWorkProgramService.message | async)"
                    ><em
                      class="fas"
                      [ngClass]="
                        !isLoadingSync ? 'fa-sync-alt' : 'fa-circle-notch fa-spin'
                      "
                    ></em
                    >{{ 'budget-allocation-bjb.button.sync' | translate
                    }}</app-button
                  >
                  <app-button
                    mode="BYPASS"
                    (onClick)="doExport()"
                    [disabled]="formLoading || isLoadingSync || isLoadingReport || (websocketBudgetService.message | async)
                    || (websocketWorkProgramService.message | async)"
                    ><em
                      class="fas"
                      [ngClass]="
                        !isLoadingReport
                          ? 'fa-cloud-download-alt'
                          : 'fa-circle-notch fa-spin'
                      "
                    ></em
                    >{{
                      'budget-allocation-bjb.button.report' | translate
                    }}</app-button
                  >
                  <!-- <app-button mode="BYPASS" 
                  (onClick)="doImport()">
                  <em class="fas fa-cloud-upload">
                  </em>{{ 'app.button.import' | translate }}
                  </app-button> -->
                  <app-button
                    (onClick)="doAdd()"
                    [disabled]="formLoading || isLoadingSync || isLoadingReport || (websocketBudgetService.message | async) || (websocketWorkProgramService.message | async)"
                    ><em class="fas fa-plus"></em
                    >{{ 'app.button.add' | translate }}</app-button
                  >
                </ng-template>
                <ng-template #actionButtons let-rowData>
                  <app-button
                    mode="BYPASS"
                    size="TN"
                    class="mr-2"
                    title="{{ 'app.tooltip.view' | translate }}"
                    (onClick)="doDetailOrganization(rowData)"
                    [disabled]="(websocketBudgetService.message | async) || (websocketWorkProgramService.message | async)"
                  >
                    <em class="fas fa-search"></em>
                  </app-button>
                </ng-template>
              </app-table>
            </app-tab-item>
            <app-tab-item header="budget-allocation-bjb.byCoa">
              <app-table
                [model]="tableResponseCoa"
                [isServerSide]="true"
                key="by-coa-index"
                stringUrl="/budget-allocation-bjb/coa/index"
                isShowEditTable="true"
              >
                <ng-template #headerButtons>
                  <!-- BJB -->
                  <app-button
                    *ngIf="isAllowAccess"
                    mode="BYPASS"
                    (onClick)="doSync()"
                    [disabled]="formLoading || isLoadingSync || isLoadingReport || (websocketBudgetService.message | async)
                    || (websocketWorkProgramService.message | async)"
                    ><em
                      class="fas"
                      [ngClass]="
                        !isLoadingSync ? 'fa-sync-alt' : 'fa-circle-notch fa-spin'
                      "
                    ></em
                    >{{ 'budget-allocation-bjb.button.sync' | translate
                    }}</app-button
                  >
                  <app-button
                    mode="BYPASS"
                    (onClick)="doExport()"
                    [disabled]="formLoading || isLoadingReport || isLoadingSync || (websocketBudgetService.message | async)
                    || (websocketWorkProgramService.message | async)"
                    ><em
                      class="fas"
                      [ngClass]="
                        !isLoadingReport
                          ? 'fa-cloud-download-alt'
                          : 'fa-circle-notch fa-spin'
                      "
                    ></em
                    >{{
                      'budget-allocation-bjb.button.report' | translate
                    }}</app-button
                  >
                  <!-- <app-button mode="BYPASS" (onClick)="doImport()"><em class="fas fa-cloud-upload"></em>{{
                    'app.button.import' | translate }}</app-button> -->
                  <app-button
                    (onClick)="doAdd()"
                    [disabled]="formLoading || isLoadingSync || isLoadingReport
                    || (websocketBudgetService.message | async) || (websocketWorkProgramService.message | async)"
                    ><em class="fas fa-plus"></em
                    >{{ 'app.button.add' | translate }}</app-button
                  >
                </ng-template>
                <ng-template #actionButtons let-rowData>
                  <app-button
                    mode="BYPASS"
                    *ngIf="rowData.coa.isHasChild"
                    size="TN"
                    class="mr-2"
                    title="{{ 'app.tooltip.view' | translate }}"
                    (onClick)="doDetailCoa(rowData)"
                    [disabled]="(websocketBudgetService.message | async) || (websocketWorkProgramService.message | async)"
                  >
                    <em class="fas fa-search"></em>
                  </app-button>
                </ng-template>
              </app-table>
            </app-tab-item>
          </app-tab>
        </form>
      </app-card>
    </div>
    </app-card>
  </div>
</div>
