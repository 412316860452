import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAccordionModule } from '../app-accordion/app-accordion.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppFieldsetModule } from '../app-fieldset/app-fieldset.module';
import { AppPopupModule } from '../app-popup/app-popup.module';
import { AppTableModule } from '../app-table/app-table.module';
import { AppContractRequestViewComponent } from './app-contract-request-view.component';

@NgModule({
  imports: [
    SharedModule,
    VendorModule,
    AppTableModule,
    AppCardModule,
    AppAccordionModule,
    AppFieldsetModule,
    AppPopupModule,
    AppButtonModule
  ],
  declarations: [AppContractRequestViewComponent],
  exports: [AppContractRequestViewComponent]
})
export class AppContractRequestViewModule {}
