import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
@Component({
  selector: 'app-popup-comparison',
  templateUrl: './app-popup-comparison.component.html',
  styleUrls: ['./app-popup-comparison.component.scss']
})
export class AppPopupComparisonComponent extends BaseComponentComponent {
  @Input() itemList: Array<any>;
  @Input() header = 'app-popup-comparison.title';
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public isCheckedAll = false;
  public checkedItemList: Array<any>;

  @ContentChild('content') contentTmpl: TemplateRef<any>;

  constructor(
    public activeModal: NgbActiveModal,
    public translateService: TranslateService
  ) {
    super('app-popup-comparison');
  }

  onInit(): void {
    this.setIsChecked();
    this.setStateReady();
  }

  public doCompare(): void {
    this.checkedItemList = this.itemList.filter(item => item.isChecked);
    this.onChange.emit(this.checkedItemList);
  }

  public doCheckAll(): void {
    this.isCheckedAll = !this.isCheckedAll;
    this.itemList.forEach(item => {
      item.isChecked = this.isCheckedAll;
    });
  }

  public doCheck(index: number): void {
    this.itemList[index].isChecked = !this.itemList[index].isChecked;
    this.isCheckedAll = false;
  }

  public setIsChecked(): void {
    this.itemList.forEach(item => {
      item.isChecked = false;
    });
  }

  public doDelete(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.itemList.splice(index, 1);
        }
      });
  }

  public doDeleteAll(): void {
    this.global.modalService
      .confirm(
        this.translateService.instant(
          'app-popup-comparison.confirmation.clearAll'
        ),
        this.translateService.instant('app.confirmation.delete.title')
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.itemList.splice(0, this.itemList.length);
        }
      });
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
