import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  templateUrl: './app-widget-announcement.component.html',
  styleUrls: ['./app-widget-announcement.component.scss']
})
export class AppWidgetAnnouncementComponent extends BaseComponentComponent {
  @Input() model: any;
  public announcementList: Array<any> = new Array();
  constructor() {
    super('app-widget-announcement');
  }

  public onInit(): void {
    this.setAnnouncementList();
  }

  setAnnouncementList(): void {
    this.announcementList = [];
  }

  handleShowAll(): void {
    this.setStateReady();
  }
}
