<app-popup header="contract-draft.popup.header.reviewer.invite">
    <form
      class="form-horizontal participant-reviewer"
      [formGroup]="formGroup"
      novalidate
    >
      <div
        class="form-group row text-sm-right"
        [ngClass]="isInternal ? '' : 'required'"
      >
        <label class="col-sm-3 control-label">{{
          'contract-draft.form.name' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            formControlName="name"
            maxLength="512"
            size="LG"
          ></app-text-field>
        </div>
      </div>
      <div class="form-group row text-sm-right required">
        <label class="col-sm-3 control-label">{{
          'contract-draft.form.email' | translate
        }}</label>
        <div class="col-sm-9 text-left">
            <app-text-field
              formControlName="email"
              maxLength="512"
              size="LG"
              (onChange)="onChangeEmailEksternal()"
            ></app-text-field>
        </div>
      </div>
      <div
        class="form-group row text-sm-right"
        [ngClass]="isInternal ? '' : 'required'"
      >
        <label class="col-sm-3 control-label">{{
          'contract-draft.form.position' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            formControlName="position"
            maxLength="512"
            size="LG"
          ></app-text-field>
        </div>
      </div>
    </form>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="doCancel()">
          {{ 'app.button.close' | translate }}
        </app-button>
        <app-button (onClick)="doSave()">
          {{ 'app.button.save' | translate }}
        </app-button>
      </div>
    </ng-template>
</app-popup>
  