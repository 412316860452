<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-xx
        header="doc-retrieval-response.index.title"
        [model]="tableResponse"
        stringUrl="/doc-retrieval-response/index"
        [isShowCheckBox]="false"
        (onClick)="doClick($event)"
      >
        <ng-template #headerFilterGroup>
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
            *ngIf="!formLoading"
          >
            <div class="form-group row text-sm-left">
              <div class="col-sm-12 text-left">
                <div class="form-row px-1" style="margin-bottom: -3rem">
                  <div class="form-group row px-4" style="align-items: center">
                    <label class="pr-2 pt-2">{{
                      'doc-retrieval-response.form.filter.date' | translate
                    }}</label>
                    <div class="text-left">
                      <app-date-picker-x
                        size="XL"
                        formControlName="date"
                        [range]="true"
                        (onChange)="onChangeFilter()"
                      >
                      </app-date-picker-x>
                    </div>
                  </div>
                  <div class="form-group row ml-2 col-sm-2">
                    <app-dropdown-select
                      style="width: 100%"
                      size="XL"
                      [optionList]="docRetrievalStatusOptionList"
                      formControlName="docRetrievalStatusList"
                      showLimitValue="2"
                      type="CHOSEN"
                      placeholder="doc-retrieval-response.placeholder.status"
                      (onChange)="onChangeFilter()"
                    >
                    </app-dropdown-select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </app-table-xx>
    </div>
  </div>
</app-layout>
