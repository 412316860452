import {
  Component,
  ElementRef,
  Input,
  Optional,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { Response } from '../../model/response-model';
import { ResponseStatusModel } from '../../model/response-status-model';
import { Validators } from '../../validators';

@Component({
  selector: 'app-text-generated',
  templateUrl: './app-text-generated.component.html',
  styleUrls: ['./app-text-generated.component.scss'],
  providers: makeProvider(AppTextGeneratedComponent),
  encapsulation: ViewEncapsulation.None
})
export class AppTextGeneratedComponent extends BaseValueAccessorComponent<
  string | number
> {
  @Input() symbolic: string;
  @Input() type: string;
  @Input() min: number;
  @Input() max: number;
  @Input() numberGeneratorCode: string;
  @Input() moduleCode: string;
  appTextGeneratedType = 'text';
  divAppTextGeneratedElement: any;
  previousTextValue: string;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public renderer2: Renderer2
  ) {
    super('app-text-generated', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setAppTextGeneratedType();
    this.setGeneratedElement();
    this.setMaxLength();
    this.getFormatGenerated();
    this.setStateReady();
  }

  public getFormatGenerated(): void {
    this.httpClientService
      .get<Response<string>>(
        '/app-text-generated/get-format/' +
          this.moduleCode +
          '/' +
          this.numberGeneratorCode
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          if (response.body) {
            if (!this.formControl.value) {
              this.formControl.patchValue(response.body);
              this.onInput.emit(response.body);
            }
            this.formControl.setIsView(true);
            this.formControl.setValidators([Validators.required()]);
          }
        }
      });
  }

  public setGeneratedElement(): void {
    this.divAppTextGeneratedElement = this.elementRef.nativeElement.children[0];
  }

  public setAppTextGeneratedType(): void {
    this.appTextGeneratedType = this.type;
  }

  public doOnInput(value: string | number): void {
    if (this.type === 'number') {
      this.formControl.patchValue(+Number(value));
    }
    this.onInput.emit(value);
  }

  public setMaxLength(): void {
    if (!this.maxLength) {
      if (this.isTinySize) {
        this.maxLength = 4;
      } else if (this.isSmallSize) {
        this.maxLength = 8;
      } else if (this.isMediumSize) {
        this.maxLength = 32;
      } else if (this.isLongSize) {
        this.maxLength = 128;
      }
    }
  }
}
