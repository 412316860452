import { NgModule } from '@angular/core';
import { AppFieldsetModule } from 'src/app/core/components/app-fieldset/app-fieldset.module';
import { AppTableModule } from 'src/app/core/components/app-table/app-table.module';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppPaymentInformationComponent } from './payment-information.component';

@NgModule({
  imports: [VendorModule, AppTableModule, AppFieldsetModule],
  declarations: [AppPaymentInformationComponent],
  exports: [AppPaymentInformationComponent]
})
export class AppPaymentInformationModule {}
