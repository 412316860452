import { BaseEntity } from '../base/base-entity';
import { AddressBook } from '../bean/address-book';
import { Contract } from '../bean/contract';

export class ContractShippingEntity extends BaseEntity {
    contract: Contract;
    address: AddressBook;
    crudOperation: number;

}
