import { Component, ViewEncapsulation } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Organization } from '../../core/bean/organization';
import { PrinciplePermit } from '../../core/bean/principle-permit';
import { WorkPlan } from '../../core/bean/work-plan';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { ApprovalPathModel } from '../../core/components/app-approval-path';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { WorkflowPrcs } from './../../core/bean/workflow-prcs';
import { PrinciplePermitRequest } from './principle-permit-submission-request';
import { PrinciplePermitResponse } from './principle-permit-submission-response';

@Component({
  templateUrl: './principle-permit-submission-edit-add.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./principle-permit-submission-edit-add.component.scss']
})
export class PrinciplePermitSubmissionEditAddComponent extends BaseModuleComponent {
  public isAllUploaded = false;
  public principlePermit: PrinciplePermit = new PrinciplePermit();
  public workPlan: WorkPlan = new WorkPlan();
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public principlePermitResponse: PrinciplePermitResponse =
    new PrinciplePermitResponse();
  public principlePermitRequest: PrinciplePermitRequest =
    new PrinciplePermitRequest();
  public approvalPath: ApprovalPathModel<any> = new ApprovalPathModel(
    this.moduleCode,
    'READ'
  );
  public fileUploader: FileUploader = new FileUploader(
    '/principle-permit-submission/',
    '',
    this.global.appConstant.fileType.DOC_PRINCIPLE_PERMIT,
    false,
    5
  );

  public url: string;
  public principlePermitId: number;
  public id: number;
  public objectId: number;
  public workPlanId: number;
  public workflowModelPrcsId: number;
  public workflowPrcs: WorkflowPrcs;
  public submitted: boolean;

  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('principle-permit-submission', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.objectId = this.global.routerParams.get('objectId');
    this.workPlan = this.global.routerParams.get('workPlan');
    this.workPlanId = this.objectId;
    if (this.workPlan != null) {
      this.workPlanId = this.workPlan.id;
      this.workflowPrcs = this.workPlan.workflowPrcs;
      this.workflowModelPrcsId = this.workPlan.workflowModelPrcs.id;
      this.workflowModelPrcs = this.workPlan.workflowModelPrcs;
    }
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      number: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      today: [new Date()],
      createdDate: [null, Validators.required()],
      organization: [null, Validators.required()],
      subject: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(128)])
      ],
      description: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(512)])
      ],
      uploadFile: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<PrinciplePermitResponse>(
        '/principle-permit-submission/add-edit',
        new RouteRequestModel(this.todo, this.workPlanId)
      )
      .subscribe(principlePermitResponse => {
        this.principlePermitResponse = principlePermitResponse;
        if (this.objectId != null) {
          this.workPlan = principlePermitResponse.workPlan;
          this.workflowPrcs = this.workPlan.workflowPrcs;
          this.workflowModelPrcsId = this.workPlan.workflowModelPrcs.id;
          this.workflowModelPrcs = this.workPlan.workflowModelPrcs;
        }
        this.formGroup.patchValue({
          organization: this.principlePermitResponse.organizationList
        });
        if (principlePermitResponse.principlePermit != null) {
          this.todo = this.todo !== 'view' ? 'edit' : 'view';
          if (principlePermitResponse.approvalPathResponseModel != null) {
            this.approvalPath
              .setApproverList(
                principlePermitResponse.approvalPathResponseModel
                  .userApproverList
              )
              .setType(principlePermitResponse.approvalPathResponseModel.type);
          }
          const createdDate: Date = new Date(
            principlePermitResponse.principlePermit.createdDate
          );
          this.fileUploader.setFileList(principlePermitResponse.fileList);
          this.formGroup.patchValue({
            id: principlePermitResponse.principlePermit.id,
            number: principlePermitResponse.principlePermit.number,
            createdDate,
            subject: principlePermitResponse.principlePermit.subject,
            description: principlePermitResponse.principlePermit.description,
            uploadFile: this.fileUploader.fileObjectList
          });
        } else if (
          principlePermitResponse.principlePermit == null &&
          this.todo !== 'add'
        ) {
          this.global.routerParams.clear();
          this.global.routerParams.set('todo', 'add');
          this.global.routerParams.set('workPlan', this.workPlan);
          this.router.navigate(['/pages/principle-permit-submission/add']);
        }
        this.setStateReady();
      });
    this.formGroup.get('organization').setIsView(true);
  }

  public doSave(): void {
    if (this.principlePermitResponse.isNumberGenerator) {
      this.formGroup.get('number').clearValidators();
      this.formGroup.get('number').updateValueAndValidity();
    }
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.principlePermit = this.formGroup.value;
            const organizationList: Organization[] =
              this.formGroup.get('organization').value;
            this.principlePermit.organization =
              organizationList[organizationList.length - 1];
            this.principlePermit.workPlan = this.workPlan;
            this.principlePermit.workPlan.workflowModelPrcs =
              this.workPlan.workflowModelPrcs;
            this.principlePermitRequest.workPlan =
              this.principlePermitResponse.workPlan;
            this.principlePermitRequest.fileObjectList =
              this.fileUploader.fileObjectList;
            this.principlePermit = this.global.copyFormAttributeToModel(
              this.principlePermit,
              this.formGroup
            );
            this.principlePermitRequest.principlePermit = this.principlePermit;
            if (this.principlePermit.id !== null) {
              this.url = '/principle-permit-submission/update';
            } else {
              this.url = '/principle-permit-submission/insert';
            }
            this.httpClientService
              .post<Response<PrinciplePermitResponse>>(
                this.url,
                this.principlePermitRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/worklist-pra-procurement']);
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  onChangeFileUpload(fileUploader: FileUploader): void {
    let isAllUploaded = true;
    if (!fileUploader.isAllUploaded) {
      isAllUploaded = fileUploader.isAllUploaded;
    }
    this.isAllUploaded = isAllUploaded;
  }

  public doBack(): void {
    this.router.navigate(['/pages/worklist-pra-procurement']);
  }
}
