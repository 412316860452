<app-popup
  header="address-book.popupChooseLocation.title"
  [isLoading]="formLoading"
>
  <form class="form-horizontal ml-4 mr-4" [formGroup]="formGroup" novalidate>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><em class="fas fa-search"></em></span>
      </div>
      <div class="col p-0 mr-2">
        <app-text-field
          formControlName="keywordLocation"
          size="XL"
          placeholder="{{
            'address-book.popup.placeholder.searchLocation' | translate
          }}"
          (onChange)="onInputSearch($event)"
        >
        </app-text-field>
      </div>
    </div>
    <br />

    <div
      [ngStyle]="{
        height: '351px',
        'overflow-y': 'scroll',
        'overflow-x': 'hidden'
      }"
    >
      <div class="address-book-location-radio">
        <!-- START radio -->
        <!-- static radio -->
        <ul class="multi is-inline-check-box">
          <li
            class="custom-control custom-radio custom-control-inline col-sm-3 mb-2"
            *ngFor="let staticVal of locationDefChecklist; index as i"
          >
            <input
              type="radio"
              name="location"
              class="custom-control-input radio-primary"
              id="{{ generatedId + staticVal.name + i }}"
              (change)="handleChange(staticVal)"
              [value]="staticVal.codeList"
            />
            <label
              class="custom-control-label cursor-pointer"
              for="{{ generatedId + staticVal.name + i }}"
              [title]="staticVal.name"
              ><span maxLine="1">{{ staticVal.name }}</span></label
            >
          </li>
        </ul>

        <!-- generated radio -->
        <ng-container *ngFor="let char of charList">
          <h5 *ngIf="getGroupedList(dynamicRegionList, char).length > 0">
            {{ char }}
          </h5>
          <ul
            class="multi is-inline-check-box"
            *ngIf="getGroupedList(dynamicRegionList, char).length > 0"
          >
            <li
              class="custom-control custom-radio custom-control-inline col-sm-3 mb-2"
              *ngFor="
                let val of getGroupedList(dynamicRegionList, char);
                index as i
              "
            >
              <ng-container *ngIf="isMatchGroup(val.name, char)">
                <input
                  type="radio"
                  name="location"
                  class="custom-control-input radio-primary"
                  id="{{ generatedId + val.code + i }}"
                  (change)="handleChange(val)"
                  [value]="val.code"
                />
                <label
                  class="custom-control-label cursor-pointer"
                  for="{{ generatedId + val.code + i }}"
                  [title]="val.name"
                  ><span maxLine="1">{{ val.name }}</span>
                </label>
              </ng-container>
            </li>
          </ul>
        </ng-container>
        <!-- END radio -->
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
        >
          {{ 'app.button.cancel' | translate }}
        </app-button>
        <app-button 
          (onClick)="doChoose()"
          [disabled]="region === undefined"
        >
          {{ 'app.button.choose' | translate }}
        </app-button>
      </div>
    </ng-template>
  </form>
  <!-- </div> -->
</app-popup>
