import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { FormObject } from '../../core/bean/form-object';
import { Rfi } from '../../core/bean/rfi';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { RfiDTO } from './dto/rfi.dto';
@Component({
  templateUrl: './rfi-question.component.html'
})
export class RfiQuestionComponent extends BaseModuleComponent {
  public rfiDTO: RfiDTO;
  public rfiId: number;
  public rfiQuestionId: number;
  public rfi: Rfi;
  public formObjectValue: boolean[] = [];
  public isChanged: boolean;
  public listOfValue: FormArray;
  public formReady = false;
  public isDisabled: boolean;

  public readonly urlEditAddRfi = '/request-for-information/add-edit';
  public readonly urlUpdateRfi = '/request-for-information/update';
  public readonly urlInsertRfi = '/request-for-information/insert';
  public readonly urlRouterNavigateToRfi = '/pages/request-for-information';
  public readonly urlRouterAddRfi = '/pages/request-for-information/edit';

  public formObjectOptionList: OptionListModel<FormObject> = new OptionListModel();
  constructor(translate: TranslateService) {
    super('rfi', translate);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.setOptionListModel();
    this.buildFormGroup();
    this.setFormArray();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.rfiDTO = this.global.routerParams.get('rfiDTO');
  }

  public setOptionListModel(): void {
    this.formObjectOptionList.setRequestValues(this.rfiDTO.formObjectList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      parents: this.formBuilder.array([])
    });
  }

  public setFormArray(): void {
    this.rfiDTO.rfiQuestionList.forEach(parent => {
      const { question, formObject, rfi, listOfValue, id } = parent;
      const listOfValueFormArray: FormArray = new FormArray([]);
      listOfValue.forEach(listValue => {
        listOfValueFormArray.push(
          this.formBuilder.group({
            name: [listValue.name, Validators.required()],
            value: [listValue.value, Validators.required()]
          })
        );
      });

      this.parents.push(
        this.formBuilder.group({
          question: [question, Validators.required()],
          formObject: [formObject, Validators.required()],
          rfi,
          listOfValue: listOfValueFormArray,
          id
      }));

      this.listOfValue = listOfValueFormArray;
    });

    this.log.debug('this parents');
    this.log.debug(this.parents);

    this.setStateReady();
  }

  public get parents(): FormArray {
    return this.formGroup.get('parents') as FormArray;
  }

  public addMoreQuestion(): void {
    this.parents.push(
      this.formBuilder.group({
        question: [
          null,
          Validators.compose([
            Validators.required(),
            Validators.maxLength(128)
         ])
       ],
        formObject: [null, Validators.required()],
        listOfValue: this.formBuilder.array([]),
        rfi: null
     })
    );
  }

  public doDeleteQuestion(index: number): void {
    this.global.modalService
        .deleteConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.parents.removeAt(index);
          }
        });
  }

  public showFormOption(index: number, parents: FormGroup): void {
    this.log.debug('index: ' + index);
    this.log.debug(parents);
    this.log.debug('list of value BEFORE: ');
    this.log.debug(this.listOfValue);

    this.isDisabled = true;
    this.httpClientService.post<RfiDTO>(
      this.urlEditAddRfi,
      new RouteRequestModel(this.todo, this.rfiId)
    ).subscribe(rfiDTO => {
      const listOfValue = parents.get('listOfValue') as FormArray;
      this.listOfValue = listOfValue;

      this.log.debug('list of value AFTER: ');
      this.log.debug(this.listOfValue);

      if (parents.get('formObject') && parents.get('formObject').value.isValue === true) {
        this.formObjectValue[index] = true;
        this.setListOfValue(rfiDTO);

        if (!(listOfValue && listOfValue.length > 0)) {
          this.addValue(parents); /* tambah option jika blm ada satupun option sblumnya */
        }
      } else {
        this.isChanged = true;
        this.formObjectValue[index] = false;
        this.setListOfValue(rfiDTO);
      }

      this.isDisabled = false;
    });
  }

  public addValue(parents: FormGroup): void {
    const listOfValue = parents.get('listOfValue') as FormArray;
    listOfValue.push(
      this.formBuilder.group({
        value: [null, Validators.required()],
        name: [null, Validators.required()]
     })
    );
    // this.listOfValue = listOfValue;
  }

  public setListOfValue(rfiDTO: RfiDTO): void {
    if (rfiDTO.listOfValue !== null) {
      // const listOfValueLength = this.listOfValue.length;
      // const rfiQuestionList = rfiDTO.rfiQuestionList;
      if (this.isChanged === true) {
        while (this.listOfValue.length !== 0) {
          this.listOfValue.removeAt(0);
        }

        this.log.debug('set list of value, isChanged TRUE');
        this.log.debug(this.listOfValue);

      } else {
        for (let i = 0; i <= this.rfiDTO.listOfValue.length; i++) {
          this.listOfValue.removeAt(i);
        }
        rfiDTO.listOfValue.forEach(listOfValue => {
          this.listOfValue.push(
            this.formBuilder.group({
              name: listOfValue.name,
              value: listOfValue.value
           })
          );
        });

        this.log.debug('set list of value, isChanged FALSE');
        this.log.debug(this.listOfValue);
      }
    }
  }

  public doRemove(parentFormGroup: FormGroup, index: number): void {
    this.global.modalService
        .deleteConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            (parentFormGroup.get('listOfValue') as FormArray).removeAt(index);
          }
        });
  }

  public addNoMoreQuestion(): void {
    this.global.alertService.showError(
      this.translateService.instant('rfi.alert.msg.maxQuestion')
    );
  }

  public doBack(): void {
    this.rfiDTO.rfiQuestionList = this.formGroup.value.parents;
    this.global.routerParams.set('rfiDTO', this.rfiDTO);
    this.router.navigate([this.urlRouterAddRfi]);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();

          this.rfiDTO.rfi.publishDate.setHours(0);
          this.rfiDTO.rfi.responseDeadlineDate.setHours(23);
          this.rfiDTO.rfi.responseDeadlineDate.setMinutes(59);
          this.rfiDTO.rfi.responseDeadlineDate.setSeconds(59);
          this.log.debug(this.rfiDTO.rfi.publishDate + ', ' + this.rfiDTO.rfi.responseDeadlineDate);

          this.rfiDTO.rfiQuestionList = this.formGroup.value.parents;
          this.httpClientService.post<Response<RfiDTO>>(this.todo === 'edit' ? this.urlUpdateRfi : this.urlInsertRfi, this.rfiDTO).subscribe(response => {
            if (response.status === ResponseStatusModel.OK) {
              this.global.alertService.showSuccessSavingOnNextRoute();
              this.router.navigate([this.urlRouterNavigateToRfi]);
            } else {
              this.global.alertService.showError(response.statusText);
              this.setStateReady();
            }
          });
        }
      });
    }
  }

  public getFormArray(
    formGroup: FormGroup,
    formControlName: string
  ): FormArray {
    return formGroup.get(formControlName) as FormArray;
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
