import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../../core/shared/shared.module';
import { AppCatalogItemCarouselModule } from '../app-catalog-item-carousel/app-catalog-item-carousel.module';
import { AppCatalogItemCategoriesModule } from '../app-catalog-item-categories/app-catalog-item-categories.module';
import { AppPopupCatalogComparisonComponent } from '../app-popup-catalog-comparison/app-popup-catalog-comparison.component';
import { AppTableCatalogFilterComponent } from './app-table-catalog-filter/app-table-catalog-filter.component';
import { AppTableCatalogComponent } from './app-table-catalog.component';
@NgModule({
  imports: [SharedModule, AppCatalogItemCategoriesModule, AppCatalogItemCarouselModule],
  declarations: [AppTableCatalogComponent, AppTableCatalogFilterComponent, AppPopupCatalogComparisonComponent ],
  exports: [AppTableCatalogComponent, AppTableCatalogFilterComponent, AppPopupCatalogComparisonComponent]
})
export class AppTableCatalogModule {}
