import { NgModule } from '@angular/core';
import { AppButtonModule } from '../../components/app-button/app-button.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetAnnouncementItemComponent } from './app-widget-announcement-item.component';
import { AppWidgetAnnouncementComponent } from './app-widget-announcement.component';
@NgModule({
  imports: [VendorModule, AppButtonModule, AppWidgetModule],
  declarations: [AppWidgetAnnouncementComponent, AppWidgetAnnouncementItemComponent],
  entryComponents: [AppWidgetAnnouncementComponent],
  exports: [AppWidgetAnnouncementComponent]
})
export class AppWidgetAnnouncementModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-announcement', AppWidgetAnnouncementComponent);
  }
}
