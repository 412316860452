import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { WorkPlan } from '../../core/bean/work-plan';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';

@Component({
  templateUrl: './project-team-submission.component.html'
})
export class ProjectTeamSubmissionComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<WorkPlan>;

  constructor(translateService: TranslateService) {
    super('team-project', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'number', header: 'table.column.number' },
      { field: 'name', header: 'table.column.name' }
    ]);
  }

  public doEdit(workPlan: WorkPlan) {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('workPlan', workPlan);
    this.router.navigate(['/pages/team-project/edit']);
  }

  public doView(workPlan: WorkPlan) {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('workPlanId', workPlan.id);
    this.router.navigate(['/pages/team-project/detail']);
  }
}
