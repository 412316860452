import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { TcEditAddComponent } from './tc-edit-add.component';
import { TcComponent } from './tc.component';
export const routes = [
  { path: '', component: TcComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: TcEditAddComponent,
    data: { breadcrumb: 'tc.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: TcEditAddComponent,
    data: { breadcrumb: 'tc.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [TcComponent, TcEditAddComponent],
  providers: []
})
export class TcModule {}
