import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupAttributeComponent } from './app-popup-attribute.component';
@Injectable()
export class AppPopupAttributeService {
  constructor(public appPopupService: AppPopupService) {}
  public open(attributeIdList?: any[]): Observable<any> {
    return this.appPopupService.open(AppPopupAttributeComponent, {
      attributeIdList
    });
  }
}
