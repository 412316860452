import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { FormObject } from '../../core/bean/form-object';
import { Lang } from '../../core/bean/lang';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { CatalogAttribute } from './../../core/bean/catalog-attribute';
import { CatalogAttributeResponse } from './catalog-attribute-response';
import { CatalogAttributeRequest } from './catalog-attribute-request';

@Component({
  templateUrl: './catalog-attribute-edit-add.component.html'
})
export class CatalogAttributeEditAddComponent extends BaseModuleComponent {
  public catalogAttribute: CatalogAttribute = new CatalogAttribute();
  public catalogAttributeResponse: CatalogAttributeResponse = new CatalogAttributeResponse();
  public catalogAttributeRequest: CatalogAttributeRequest = new CatalogAttributeRequest();
  public catalogAttributeId: number;
  public name: string[] = [];
  public langList = [];
  public formObjectValue = false;
  public isChanged: boolean;
  public isDisabled: boolean;
  public formObjectList: OptionListModel<FormObject> = new OptionListModel(
    true
  );
  public statusList: OptionListModel<any> = new OptionListModel();
  public checkList: any[] = [];
  public function = '';
  public functionList: string[] = [];
  public translationListLabel: string[] = [];

  public readonly urlEditAddCatalogAttribute = '/catalog-attribute/add-edit';
  public readonly urlRouterNavigateToCatalogAttribute =
    'pages/catalog-attribute';
  public readonly urlInsertCatalogAttribute = '/catalog-attribute/insert';
  public readonly urlUpdateCatalogAttribute = '/catalog-attribute/update';

  constructor(translateService: TranslateService) {
    super('catalog-attribute', translateService);
    Object.assign(this, this.global.routerParams);
  }

  onInit(): void {
    this.catalogAttributeId = this.global.routerParams.get(
      'catalogAttributeId'
    );
    this.todo = this.global.routerParams.get('todo');
    this.buildFormGroup();
    this.setOptionsValue();
    this.setFormGroup();
  }

  public setOptionsValue(): void {
    const statusList = [
      { value: true, name: 'Active' },
      { value: false, name: 'Not Active' }
    ];
    this.statusList.setRequestValues(statusList);
  }

  public setCheckBoxValue(catalogAttributeResponse: CatalogAttributeResponse): void {
    const listOfCheck = ['Mandatory', 'Unique', 'Searchable'];

    if (catalogAttributeResponse.catalogAttribute != null) {
      listOfCheck.forEach(itemCheck => {
        const check = { name: itemCheck, isChecked: false };
        if (catalogAttributeResponse.catalogAttribute.function !== null) {
          this.functionList = catalogAttributeResponse.catalogAttribute.function.split(
            ', '
          );
          this.functionList.forEach(item => {
            if (check.name === item) {
              check.isChecked = true;
            }
          });
        }
        this.checkList.push(check);
      });
    } else {
      this.checkList = [
        { name: 'Mandatory', isChecked: false },
        { name: 'Unique', isChecked: false },
        { name: 'Searchable', isChecked: false }
      ];
    }

    this.formGroup.get('checkList').patchValue(this.checkList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null, Validators.compose([Validators.required()])],
      description: [null],
      formObject: [null, Validators.required()],
      checkList: [null, Validators.isChecked()],
      credentials: this.formBuilder.array([]),
      translationList: this.formBuilder.array([]),
      isActive: [null, Validators.required()]
    });
  }

  public showFormOption(formObject: FormObject): void {
    this.getCatalogAttributeByIdFromServer(
      this.urlEditAddCatalogAttribute,
      this.todo,
      this.catalogAttributeId
    ).subscribe(catalogAttributeResponse => {
      if (this.formGroup.get('formObject').value.isValue === true) {
        this.formObjectValue = true;
        if (this.inputList.length === 0) {
          for (let i = 0; i < 2; i++) {
            this.addCreds();
          }
        }
      } else {
        this.isChanged = true;
        this.formObjectValue = false;
        this.setListOfValue(catalogAttributeResponse, formObject);
      }
    });
  }

  public addCreds(): void {
    if (this.formGroup.get('formObject').value.isValue === true) {
      this.inputList.push(
        this.formBuilder.group({
          value: [null, Validators.required()],
          name: [null, Validators.required()]
        })
      );
    }
  }

  public get inputList(): FormArray {
    return this.formGroup.get('credentials') as FormArray;
  }

  public setFormGroup(): void {
    this.getCatalogAttributeByIdFromServer(
      this.urlEditAddCatalogAttribute,
      this.todo,
      this.catalogAttributeId
    ).subscribe(catalogAttributeResponse => {
      this.langList = catalogAttributeResponse.langList;
      this.formObjectList.setRequestValues(catalogAttributeResponse.formObjectList);
      this.setTranslation(catalogAttributeResponse);
      if (catalogAttributeResponse.catalogAttribute != null) {
        this.setCatalogAttribute(catalogAttributeResponse);
      }
      this.setCheckBoxValue(catalogAttributeResponse);
      this.setListOfValue(catalogAttributeResponse);

      this.setStateReady();
    });
  }

  public setCatalogAttribute(catalogAttributeResponse: CatalogAttributeResponse): void {
    const {
      id,
      code,
      description,
      formObject,
      isActive: isActiveStatus
    } = catalogAttributeResponse.catalogAttribute;
    const isActive = this.statusList
      .getRequestValues()
      .filter(status => status.value === isActiveStatus)[0];
    this.formGroup.patchValue({ id, code, description, formObject, isActive });
  }

  public setTranslation(catalogAttributeResponse: CatalogAttributeResponse): void {
    if (catalogAttributeResponse.translationList.length > 0) {
      catalogAttributeResponse.translationList.forEach(translation => {
        this.translationListLabel.push(translation.lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: translation.value
          })
        );
      });

      if (
        catalogAttributeResponse.langList.length !==
        catalogAttributeResponse.translationList.length
      ) {
        // edit old data after add new active lang
        const oldActiveLangList: string[] = [];
        catalogAttributeResponse.translationList.forEach(translation => {
          oldActiveLangList.push(translation.lang.code);
        });
        const newActiveLangList: Lang[] = catalogAttributeResponse.langList.filter(
          lang => !oldActiveLangList.includes(lang.code)
        );
        newActiveLangList.forEach(newActiveLang => {
          this.translationListLabel.push(newActiveLang.code);
          this.translationList.push(
            this.formBuilder.group({
              value: [null, Validators.required()]
            })
          );
        });
      }
    } else {
      catalogAttributeResponse.langList.forEach(lang => {
        this.translationListLabel.push(lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: [null, Validators.required()]
          })
        );
      });
    }
  }

  public setListOfValue(
    catalogAttributeResponse: CatalogAttributeResponse,
    formObject?: FormObject
  ): void {
    if (
      catalogAttributeResponse.listOfValueModel != null &&
      (!formObject || (formObject && formObject.isValue))
    ) {
      this.formObjectValue = true;
      if (this.isChanged === true) {
        this.inputList.removeAt(0);
      } else {
        for (
          let index = 0;
          index < catalogAttributeResponse.listOfValueModel.length;
          index++
        ) {
          this.inputList.removeAt(index);
        }
        catalogAttributeResponse.listOfValueModel.forEach(listOfValue => {
          this.inputList.push(
            this.formBuilder.group({
              name: listOfValue.name,
              value: listOfValue.value
            })
          );
        });
      }
    } else {
      if (this.inputList.controls.length > 0) {
        this.inputList.controls = [];
        this.inputList.patchValue([]);
      }
    }
  }

  public doRemove(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.inputList.removeAt(index);
          this.isDisabled = false;
        }
      });
  }

  public doSave(): void {
    this.validate();
    this.setFormArrayValidation();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.saveCatalogAttribute();
          }
        });
    }
  }

  public setFormArrayValidation(): void {
    for (const control of this.translationList.controls) {
      control.setStateSubmitted();
    }
  }

  public saveCatalogAttribute(): void {
    this.setStateProcessing();
    this.catalogAttributeRequest.catalogAttribute = this.formGroup.value;
    this.catalogAttributeRequest.translationValueList = this.formGroup
      .get('translationList')
      .value.map(a => a.value);
    this.catalogAttributeRequest.catalogAttribute.isActive = this.formGroup.get(
      'isActive'
    ).value.value;

    this.checkList = this.formGroup.get('checkList').value;
    this.checkList.forEach((element, index) => {
      if (element.isChecked) {
        if (index === this.checkList.length || this.function === '') {
          this.function = this.function + element.name;
        } else {
          this.function = this.function + ', ' + element.name;
        }
      }
    });
    this.catalogAttributeRequest.catalogAttribute.function = this.function;
    // this.catalogAttributeDTO.translationValueList = this.name;

    if (this.formGroup.get('formObject').value.isValue === false) {
      this.catalogAttributeRequest.listOfValueModel = null;
    } else {
      this.catalogAttributeRequest.listOfValueModel = this.formGroup.get(
        'credentials'
      ).value;
    }

    this.saveCatalogAttributeToServer(
      this.urlSaveCatalogAttribute,
      this.catalogAttributeRequest
    ).subscribe(response => {
      this.showAlertMessage(response);
      this.setStateReady();
    });
  }

  public showAlertMessage(response: Response<CatalogAttributeRequest>): void {
    if (response.status === ResponseStatusModel.OK) {
      this.global.alertService.showSuccessSavingOnNextRoute();
      this.router.navigate([this.urlRouterNavigateToCatalogAttribute]);
      this.translateService.reloadLang(this.global.getCurrentLang());
    } else {
      this.global.alertService.showError(response.statusText);
    }
  }

  public get urlSaveCatalogAttribute(): string {
    return this.todo === 'edit'
      ? this.urlUpdateCatalogAttribute
      : this.urlInsertCatalogAttribute;
  }

  public doCancel(): void {
    this.router.navigate([this.urlRouterNavigateToCatalogAttribute]);
  }
  public saveCatalogAttributeToServer(
    urlSaveCatalogAttribute: string,
    catalogAttributeRequest: CatalogAttributeRequest
  ): Observable<Response<CatalogAttributeRequest>> {
    return this.httpClientService.post<Response<CatalogAttributeRequest>>(
      urlSaveCatalogAttribute,
      catalogAttributeRequest
    );
  }

  public getCatalogAttributeByIdFromServer(
    urlGetCatalogAttributeById: string,
    todo: string,
    catalogAttributeId: number
  ): Observable<CatalogAttributeResponse> {
    return this.httpClientService.post<CatalogAttributeResponse>(
      urlGetCatalogAttributeById,
      new RouteRequestModel(todo, catalogAttributeId)
    );
  }

  public get translationList(): FormArray {
    return this.formGroup.get('translationList') as FormArray;
  }
}
