import { ContractDoc } from 'src/app/core/bean/contract-doc';
import { ContractFileHistory } from 'src/app/core/bean/contract-file-history';
import { ProcurementResult } from 'src/app/core/bean/procurement-result';
import { ProcurementVendorReference } from 'src/app/core/bean/procurement-vendor-reference';
import { Contract } from '../../bean/contract';
import { Procurement } from '../../bean/procurement';
import { ProcurementDoc } from '../../bean/procurement-doc';
import { ProcurementVendor } from '../../bean/procurement-vendor';
import { VendorAddress } from '../../bean/vendor-address';
import { VendorBank } from '../../bean/vendor-bank';
import { VendorDoc } from '../../bean/vendor-doc';

export class AppProcurementInformationViewResponse {
  procurement: Procurement;
  procurementVendor: ProcurementVendor;
  vendorAddress: VendorAddress;
  vendorBank: VendorBank;
  contract: Contract;
  procurementVendorReference: ProcurementVendorReference = new ProcurementVendorReference();
  contractFileHistory: ContractFileHistory = new ContractFileHistory();
  contractDocList: ContractDoc[];
  procurementDocList: ProcurementDoc[];
  vendorLegalityDocList: VendorDoc[];
  procurementResultProductList: ProcurementResult[] = [];
  procurementResultServiceList: ProcurementResult[] = [];
  totalProductCost: number;
  totalServiceCost: number;
}
