<app-popup
  header="payment-voucher.popup.title.addIncomeTax"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="isViewOnly"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'payment-voucher.detail.popup.form.tax' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-dropdown-select
          size="XL"
          formControlName="tax"
          type="COMBOBOX"
          [optionList]="taxOptionList"
          container="body"
          placeholder="payment-voucher.detail.popup.form.placeholder.inputTax"
          showLimitValue="5"
          (onChange)="onChangePayment()"
        ></app-dropdown-select>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'payment-voucher.detail.popup.form.coa' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-dropdown-select
          size="XL"
          formControlName="coa"
          type="AUTOCOMPLETE"
          container="body"
          stringUrl="/payment-voucher/auto-complete-coa-list"
          placeholder="payment-voucher.detail.popup.form.placeholder.inputCoa"
          showLimitValue="5"
        ></app-dropdown-select>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'payment-voucher.detail.popup.form.costCenter' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-dropdown-select
          size="XL"
          formControlName="costCenter"
          type="AUTOCOMPLETE"
          stringUrl="/payment-voucher/auto-complete-cost-center-list"
          container="body"
          placeholder="payment-voucher.detail.popup.form.placeholder.inputCostCenter"
          showLimitValue="5"
        ></app-dropdown-select>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label
        class="col-sm-3"
        *ngIf="
          !formGroup.value?.taxMethod ||
          formGroup.value?.taxMethod?.code ===
            global.appConstant.cm.TAX_METHOD_PERCENT
        "
      >
        {{ 'payment-voucher.detail.popup.form.taxPercentage' | translate }}
      </label>
      <label
        class="col-sm-3"
        *ngIf="
          formGroup.value?.taxMethod?.code ===
          global.appConstant.cm.TAX_METHOD_VALUE
        "
      >
        {{ 'payment-voucher.detail.popup.form.taxValue' | translate }}
      </label>
      <div
        class="col-sm-9 text-left"
        *ngIf="
          !formGroup.value?.taxMethod ||
          formGroup.value?.taxMethod?.code ===
            global.appConstant.cm.TAX_METHOD_PERCENT
        "
      >
        <app-text-field
          formControlName="taxPercentage"
          groupText="%"
          (onInput)="onInputTaxPercentageOrBase($event)"
          type="decimal"
        >
        </app-text-field>
        <span *ngIf="isViewOnly">%</span>
      </div>
      <div
        class="col-sm-9 text-left"
        *ngIf="
          formGroup.value?.taxMethod?.code ===
          global.appConstant.cm.TAX_METHOD_VALUE
        "
      >
        <app-currency
          size="LG"
          [optionList]="currencyOptionList"
          formControlName="taxValue"
          (onInput)="onInputTaxPercentageOrBase($event)"
        ></app-currency>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'payment-voucher.detail.popup.form.taxBase' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-currency
          size="LG"
          [optionList]="currencyOptionList"
          formControlName="taxBase"
          (onInput)="onInputTaxPercentageOrBase($event)"
        ></app-currency>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'payment-voucher.detail.popup.form.incomeTax' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        {{
          formGroup.value.incomeTax
            ? (formGroup.value.incomeTax | resolveNumberToCurrency) +
              ' ' +
              currencyList[0].code
            : '-'
        }}
      </div>
    </div>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center" *ngIf="!isViewOnly">
      <app-button
        color="SECONDARY"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button
        color="PRIMARY"
        (onClick)="doAdd()"
        [disabled]="formLoading"
        >{{ 'app.button.add' | translate }}</app-button
      >
    </div>
  </ng-template>
</app-popup>
