import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppBillingMemoInformationViewModule } from 'src/app/core/components/app-billing-memo-information-view/app-billing-memo-information-view.module';
import { AppDeliverableItemModule } from 'src/app/core/components/app-deliverable-item/app-deliverable-item.module';
import { AppPaymentRequestInformationViewModule } from 'src/app/core/components/app-payment-request-information-view/app-payment-request-information-view.module';
import { AppProcurementInformationViewModule } from 'src/app/core/components/app-procurement-information-view/app-procurement-information-view.module';
import { AppVendorPaymentInformationModule } from 'src/app/core/components/app-vendor-payment-information/app-vendor-payment-information.module';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AppPopupPaymentMonitoringUploadPaymentProofComponent } from './app-popup-payment-monitoring-upload-payment-proof.component';
import { AppPopupPaymentMonitoringUploadPaymentTaxProofComponent } from './app-popup-payment-monitoring-upload-payment-tax-proof.component';
import { AppPopupSyncPaymentMonitoringComponent } from './app-popup-sync-payment-monitoring.component';
import { PaymentMonitoringEditComponent } from './payment-monitoring-edit.component';
import { PaymentMonitoringComponent } from './payment-monitoring.component';
import { AppActivityHistoryViewModule } from 'src/app/core/components/app-activity-history-view/app-activity-history-view.module';

export const routes = [
    {
        path: '',
        component: PaymentMonitoringComponent,
        data: { breadcrumb: '' }
    },
    {
        path: 'edit',
        component: PaymentMonitoringEditComponent,
        data: { breadcrumb: 'payment-monitoring.breadcrumb.edit' }
    },
    {
        path: 'detail',
        component: PaymentMonitoringEditComponent,
        data: { breadcrumb: 'payment-monitoring.breadcrumb.detail' }
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        NgbModule,
        AppProcurementInformationViewModule,
        AppVendorPaymentInformationModule,
        AppDeliverableItemModule,
        AppBillingMemoInformationViewModule,
        AppPaymentRequestInformationViewModule,
        AppActivityHistoryViewModule
    ],
    declarations: [
        PaymentMonitoringComponent,
        PaymentMonitoringEditComponent,
        AppPopupPaymentMonitoringUploadPaymentTaxProofComponent,
        AppPopupPaymentMonitoringUploadPaymentProofComponent,
        AppPopupSyncPaymentMonitoringComponent
    ],
    entryComponents: [
        AppPopupPaymentMonitoringUploadPaymentTaxProofComponent,
        AppPopupPaymentMonitoringUploadPaymentProofComponent,
        AppPopupSyncPaymentMonitoringComponent
    ],
    exports: [
        AppPopupPaymentMonitoringUploadPaymentTaxProofComponent,
        AppPopupPaymentMonitoringUploadPaymentProofComponent,
        AppPopupSyncPaymentMonitoringComponent
    ]
})
export class PaymentMonitoringModule { }
