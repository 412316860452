import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Contract } from '../../core/bean/contract';
import { ContractReminder } from '../../core/bean/contract-reminder';
import { Guarantee } from '../../core/bean/guarantee';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { ContractHistoryPopupNewEmailComponent } from './contract-history-popup-new-email.component';
import { ContractHistorySendReminderRequest } from './contract-history-send-reminder-request';

@Component({
  templateUrl: './contract-history-popup-send-reminder.component.html'
})
export class ContractHistoryPopupSendReminderComponent extends BaseComponentComponent {
  @Input() guarantee: Guarantee;
  @Input() contract: Contract;
  @Input() defaultContractReminderList: ContractReminder[] = [];
  @Input() userReminderMap: Map<string, ContractReminder[]>;

  public contractReminderList: ContractReminder[] = [];
  public contractReminderOptionList: OptionListModel<ContractReminder> =
    new OptionListModel(false);

  constructor(
    public ngbActiveModal: NgbActiveModal,
    public appPopupService: AppPopupService,
    public snackbarService: SnackbarService
  ) {
    super('contract-history');
  }

  public onInit(): void {
    this.setOptionList();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setOptionList(): void {
    this.contractReminderOptionList.setRequestValues([]);
    this.contractReminderOptionList.searchFieldList = [
      'user.organization.name',
      'user.name',
      'user.email',
      'name',
      'position',
      'email'
    ];
    if (this.guarantee) {
      this.contractReminderList =
        this.userReminderMap[this.guarantee.guaranteeBondType.code];
    } else {
      this.contractReminderList = this.userReminderMap['CONTRACT'];
    }
    this.contractReminderOptionList.setRequestValues(this.contractReminderList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      userReminderList: [null],
      organization: [null]
    });
  }

  public setFormGroup(): void {
    this.formGroup.patchValue({
      userReminderList: this.defaultContractReminderList
    });
    this.setStateReady();
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.organization) {
      const organizationList = this.formGroup.value.organization;
      const userFilteredList = this.contractReminderList.filter(
        u =>
          u.organization?.id ===
          organizationList[organizationList.length - 1].id
      );
      this.contractReminderOptionList.reset();
      this.contractReminderOptionList.searchFieldList = [
        'user.organization.name',
        'user.name',
        'user.email',
        'name',
        'position',
        'email'
      ];
      this.contractReminderOptionList.setRequestValues(userFilteredList);
    } else {
      this.contractReminderOptionList.reset();
      this.contractReminderOptionList.searchFieldList = [
        'user.organization.name',
        'user.name',
        'user.email',
        'name',
        'position',
        'email'
      ];
      this.contractReminderOptionList.setRequestValues(
        this.contractReminderList
      );
    }
  }

  public doAddNewEmail(): void {
    this.appPopupService
      .open(ContractHistoryPopupNewEmailComponent, null, {
        size: 'LG'
      })
      .subscribe((contractReminder: ContractReminder) => {
        contractReminder.contract = this.contract;
        this.contractReminderList.push(contractReminder);
        this.contractReminderOptionList.reset();
        this.contractReminderOptionList.searchFieldList = [
          'user.organization.name',
          'user.name',
          'user.email',
          'name',
          'position',
          'email'
        ];
        this.contractReminderOptionList.setRequestValues(
          this.contractReminderList
        );
      });
  }

  public doCancel(): void {
    this.ngbActiveModal.close();
  }

  public doSendReminder(): void {
    const request = new ContractHistorySendReminderRequest();
    request.contractReminderList = this.formGroup.value.userReminderList;
    if (this.guarantee) {
      request.reminderType = this.guarantee.guaranteeBondType.code;
      request.guarantee = this.guarantee;
    } else {
      request.reminderType = 'CONTRACT';
    }
    this.setStateProcessing();
    this.httpClientService
      .post<Response<ContractReminder>>(
        '/contract-history/send-notification',
        request
      )
      .subscribe(response => {
        this.setStateReady();
        if (response.status === ResponseStatusModel.OK) {
          this.snackbarService.showWarning(
            'contract-history.popup.sendReminder.msg.sendEmail'
          );
          this.ngbActiveModal.close();
        }
      });
  }
}
