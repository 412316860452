<app-layout [isLoading]="formLoading" [backTo]="urlBackOutside">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-card
          header="hospital-stock.detail.informationItem"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'hospital-stock.form.noTransaksi' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ hospitalStock.item.code }}
            </div>
          </div>
          
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'hospital-stock.form.namaGenerik' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ hospitalStock.item.name }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'hospital-stock.form.dose' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ hospitalStock.item.dose }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'hospital-stock.form.uom' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ hospitalStock.item.uom.name }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'hospital-stock.form.stock' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ hospitalStock.stock }}
            </div>
          </div>
        </app-card>
        <div class="col-lg-12 mb-4">
          <app-table-x
          [model]="tableResponse"
          stringUrl="/hospital-stock/activity-history"
          header="{{ 'hospital-stock.table.activityHistory' | translate }}"
        >
        </app-table-x>
        </div>
      
    </div>
  </div>
  
</app-layout>
