<app-layout [isShowBreadcrumbs]="false" [isEnableBackButton]="true">
  <div class="main-row">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      *ngIf="!formLoading && cartViewList.length !== 0"
    >
      <div style="padding-left: 15px">
        <app-check-box
          [indeterminate]="formGroup.value.isIndeterminateAll"
          formControlName="isCheckedAll"
          (onChange)="doChecked($event)"
        ></app-check-box>
        <label>{{ 'cart.button.label.checklist' | translate }}</label
        >&nbsp;&nbsp;&nbsp;
        <ng-container
          *ngIf="
            formGroup.value.isIndeterminateAll || formGroup.value.isCheckedAll
          "
        >
          <em class="far fa-trash-alt cursor-pointer" (click)="doDelete()"></em
          >&nbsp;
          <label>{{
            'cart.button.label.delete' | translate
          }}</label></ng-container
        >
      </div>
      <ng-container formArrayName="cartGroupList">
        <div
          *ngFor="
            let cartGroup of formGroup.controls.cartGroupList.controls;
            let i = index
          "
          [formGroupName]="i"
        >
          <app-card [isLoading]="formLoading" [isSaving]="formSaving">
            <div class="d-flex">
              <app-check-box
                [indeterminate]="cartGroup.value.isIndeterminateGroup"
                formControlName="isCheckedGroup"
                (onChange)="doChecked($event, i)"
              ></app-check-box>
              <div
                class="text-secondary"
                (click)="
                  doClickVendor(
                    cartGroup.value.isFromCatalog,
                    cartGroup.value.companyId
                  )
                "
                [ngClass]="
                  cartGroup.value.isFromCatalog ? 'cursor-pointer' : ''
                "
              >
                &nbsp;&nbsp;&nbsp;
                <em
                  class="fas fa-store"
                  [ngClass]="
                    cartGroup.value.isFromCatalog ? 'cursor-pointer' : ''
                  "
                ></em
                >&nbsp;
                <label
                  [ngClass]="
                    cartGroup.value.isFromCatalog ? 'cursor-pointer' : ''
                  "
                  >{{ cartGroup.value.companyName }}</label
                >
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <em
                  class="fas fa-map-marker-alt"
                  [ngClass]="
                    cartGroup.value.isFromCatalog ? 'cursor-pointer' : ''
                  "
                ></em
                >&nbsp;
                <label
                  [ngClass]="
                    cartGroup.value.isFromCatalog ? 'cursor-pointer' : ''
                  "
                  >{{ cartGroup.value.companyLocation }}</label
                >
              </div>
            </div>
            <hr />
            <ng-container formArrayName="cartList">
              <div
                *ngFor="
                  let cart of cartGroup.controls.cartList.controls;
                  let j = index
                "
                [formGroupName]="j"
              >
                <div class="row">
                  <div class="col-sm-2 d-flex image-box">
                    <app-check-box
                      formControlName="isChecked"
                      (onChange)="doChecked($event, i, j)"
                    ></app-check-box>
                    <img
                      [disabled]="true"
                      style="max-width: 100%"
                      [src]="
                        cart.value.cartView.imgFile
                          ? (global.config.BACKEND_ADDRESS +
                              '/cart/thumbnail/view/' +
                              cart.value.cartView.imgFile?.uploadedFileName +
                              '/' +
                              cart.value.cartView.imgFile?.fileType.code
                            | resolveImgSrc
                            | async)
                          : 'assets/img/app/no-image.png'
                      "
                      alt="cart.value.cartView.imgFile ? cart.value.cartView.imgFile.uploadedFileName : image"
                    />
                  </div>
                  <div class="col-sm-7 vertical-line">
                    <label *ngIf="!cartGroup.value.isFromCatalog">
                      {{ cart.value.name }}
                    </label>
                    <label *ngIf="cartGroup.value.isFromCatalog">
                      <a
                        (click)="
                          doClickCatalog(
                            cartGroup.value.isFromCatalog,
                            cart.value.cartView.catalog.id
                          )
                        "
                        >{{ cart.value.name }}</a
                      >
                    </label>
                    <br />
                    <app-badge-catalog
                      [color]="
                        cart.value.cartView.catalogTypeCode
                          ? cart.value.cartView.catalogTypeCode ===
                            global.appConstant.vm.CATALOG_TYPE_VENDOR
                            ? 'INFO'
                            : 'PROGRESS'
                          : 'WARNING'
                      "
                      >{{
                        (cart.value.cartView.catalogTypeTranslationKey !== '.'
                          ? cart.value.cartView.catalogTypeTranslationKey
                          : 'cart.label.item'
                        ) | translate
                      }}</app-badge-catalog
                    >
                  </div>
                  <div class="col-sm-3 cart-line">
                    <div class="grid-container">
                      <div class="grid-item"> </div>
                      <div class="grid-item text-right text-primary"> </div>
                      <div class="grid-item" style="color: 'var(--gray-500)'">
                        {{ 'cart.catalog.label.price' | translate }}
                      </div>
                      <div class="grid-item text-right text-primary">
                        <strong>
                          {{
                            cart.value.cartView.price | resolveNumberToCurrency
                          }}
                          {{ currencyCode }}
                        </strong>
                      </div>
                      <div class="grid-item" style="color: 'var(--gray-500)'">
                        {{ 'cart.catalog.label.quantity' | translate }}
                      </div>
                      <div class="grid-item text-right text-primary">
                        <strong>
                          {{
                            cart.value.cartView.quantity
                              | resolveNumberToDecimal
                          }}
                        </strong>
                      </div>
                      <div class="grid-item" style="color: 'var(--gray-500)'">
                        {{ 'cart.catalog.label.totalPrice' | translate }}
                      </div>
                      <div class="grid-item text-right text-primary">
                        <strong>
                          {{
                            cart.value.cartView.totalPrice
                              | resolveNumberToCurrency
                          }}
                          {{ currencyCode }}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
                <hr
                  *ngIf="
                    j !== cartGroup.controls.cartList.controls.length - 1 &&
                    cartGroup.controls.cartList.controls.length !== 1
                  "
                />
              </div>
            </ng-container>
          </app-card>
        </div>
      </ng-container>
    </form>
    <div
      class="text-center cart-main-row"
      *ngIf="!formLoading && cartViewList.length === 0"
    >
      <h3>{{ 'cart.info.noData.message' | translate }}</h3>
    </div>
    <ng-template #contentSidebarRight>
      <div
        class="row"
        style="display: flex; place-content: center; margin: 15px 0 20px"
        *ngIf="!formLoading && cartViewList.length !== 0"
      >
        <div class="col-sm-12">
          <app-card>
            <h5>{{ 'cart.sidebarRight.purchaseReview.header' | translate }}</h5>
            <br />
            <div class="row">
              <div class="col-sm-8 text-left" style="color: 'var(--gray-500)'">
                <label>{{
                  'cart.sidebarRight.purchaseReview.totalItem' | translate
                }}</label>
              </div>
              <div class="col-sm-4 text-right">
                <label>{{ totalItem | resolveNumberToDecimal }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8 text-left" style="color: 'var(--gray-500)'">
                <label>{{
                  'cart.sidebarRight.purchaseReview.totalQuantity' | translate
                }}</label>
              </div>
              <div class="col-sm-4 text-right">
                <label>{{ totalQuantity | resolveNumberToDecimal }}</label>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-sm-2 text-left" style="color: 'var(--gray-500)'">
                <label>{{
                  'cart.sidebarRight.purchaseReview.total' | translate
                }}</label>
              </div>
              <div class="col-sm-10 text-right">
                <h5
                  >{{ totalAmount | resolveNumberToCurrency }}
                  {{ currencyCode }}</h5
                >
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-sm-12">
                <app-badge-catalog class="float-right" color="WARNING">{{
                  'cart.sidebarRight.purchaseReview.taxIncluded' | translate
                }}</app-badge-catalog>
              </div>
            </div> -->
            <br />
            <div class="row">
              <div class="col-sm-12 button-process-to-pr">
                <app-button (onClick)="doProcessToPr()">{{
                  'cart.sidebarRight.purchaseReview.button.processToPr'
                    | translate
                }}</app-button>
              </div>
            </div>
          </app-card>
          <div>
            <app-alert-x *ngIf="isContractExist" color="WARNING">
              <div class="row" style="width: 170px">
                <div class="col-sm-12 text-left" style="font-size: 12px">
                  <strong>{{
                    'cart.itemDetail.isContractExist.header' | translate
                  }}</strong>
                </div>
                <div class="col-sm-12 text-left" style="font-size: 12px">
                  {{ 'cart.itemDetail.isContractExist.body' | translate }}
                </div></div
              >
            </app-alert-x>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="text-center cart-main-row" *ngIf="formLoading">
    <img
      src="assets/img/common/loader-small.gif"
      alt="Loading"
      height="11"
      width="40"
    />
    <p>{{ 'cart.info.loadingData.message' | translate }} . . .</p>
  </div>
</app-layout>
