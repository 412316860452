import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ReportRecapColumn } from '../../bean/report-recap-column';
import { AppPopupProgressBarDownloadComponent } from '../../components/app-popup/app-popup-progress-bar-download/app-popup-progress-bar-download.component';
import { ProgressBarDownloadModel } from '../../components/app-popup/app-popup-progress-bar-download/progress-bar-download-model';
import { AppPopupService } from '../../components/app-popup/app-popup.service';
import { ToastService } from '../../components/app-toast/app-toast.service';
import { OptionListModel } from '../../model/option-list-model';
import { RecapPrintService } from '../../services/recap-print.service';
import { ReportRecapBudgetUsedRequest } from './report-recap-budget-used-request';

@Component({
  templateUrl: './report-recap-budget-used-popup-choose-column.component.html'
})
export class ReportRecapBudgetUsedPopupChooseColumnComponent extends BaseComponentComponent {
  public progressBarDownload = new ProgressBarDownloadModel();
  public optionList: OptionListModel<any> = new OptionListModel(false, 'name');
  public httpRequest: Subscription;
  @Input() reportRecapColumnList: ReportRecapColumn[];
  @Input() customData: any;
  @Output() onChange: EventEmitter<ReportRecapColumn[]> = new EventEmitter();
  public isLessThen3Fields: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public translateService: TranslateService,
    public recapPrintService: RecapPrintService,
    public appPopupService: AppPopupService,
    public toastService: ToastService
  ) {
    super('report-recap-budget-used');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      column: [null]
    });
  }

  public setFormGroup(): void {
    this.optionList.setRequestValues(this.reportRecapColumnList);
    this.formGroup.patchValue({
      column: this.reportRecapColumnList
    });
    this.setStateReady();
  }

  public doExport(): void {
    if (
      this.formGroup.value.column &&
      this.formGroup.value.column.length >= 3
    ) {
      this.isLessThen3Fields = false;
      this.appPopupService
        .open(AppPopupProgressBarDownloadComponent, {
          model: this.progressBarDownload
        })
        .subscribe(isCancel => {
          if (isCancel) {
            this.httpClientService.unsubscribe();
          }
        });
      const request = new ReportRecapBudgetUsedRequest();
      request.reportRecapColumnList = this.formGroup.value.column;
      request.customData = JSON.stringify(this.customData);

      this.recapPrintService
        .print('/report-recap-budget-used/print', request)
        .subscribe(
          response => {
            if (response) {
              this.progressBarDownload.setProgress(100);
              setTimeout(() => {
                this.global.modalService.downloadSuccess();
                this.onChange.emit();
              }, 500);
            }
          },
          error => {
            error.error.text().then(text => this.toastService.showError(text));
          }
        );
    } else {
      this.isLessThen3Fields = true;
    }
  }
}
