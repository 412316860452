import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2
} from '@angular/core';
import { AppPopupPreviewImageComponent } from '../components/app-popup/app-popup-preview-image/app-popup-preview-image.component';
import { AppPopupService } from '../components/app-popup/app-popup.service';
import { Global } from '../global/global';
const selector = '[backgroundImage]';
@Directive({ selector })
export class BackgroundImageDirective implements AfterViewInit {
  @Input() backgroundImage: string;
  constructor(
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    private global: Global,
    private appPopupService: AppPopupService
  ) {}
  ngAfterViewInit(): void {
    this.elementRef.nativeElement.classList.add(
      'background-image-directive',
      'cursor-pointer'
    );
    const imageUrl = this.global.config.BACKEND_ADDRESS + this.backgroundImage;
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      this.renderer2.setStyle(
        this.elementRef.nativeElement,
        'background-image',
        `url('${imageUrl}')`
      );
    };
    image.onerror = () => {
      this.renderer2.setStyle(
        this.elementRef.nativeElement,
        'background-image',
        `url('assets/img/app/no-image.png')`
      );
    };
  }

  @HostListener('click', ['$event'])
  onPreviewImage(event: PointerEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.appPopupService.open(
      AppPopupPreviewImageComponent,
      {
        imageUrl: this.global.config.BACKEND_ADDRESS + this.backgroundImage
      },
      { backdrop: true, windowClass: 'app-popup-preview-image' }
    );
  }
}
