<app-popup
  header="app-popup-choose-vendor-multi-winner.popup.title"
  [isLoading]="formLoading"
>
  <app-alert-x color="DANGER" *ngIf="!formLoading && isRefute">
    {{
      'app-popup-choose-vendor-multi-winner.alert.msg.itemRefute' | translate
    }}
  </app-alert-x>
  <div class="popup-choose-vendor">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'app-popup-choose-vendor.form.itemName' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{ procurementItem.prItem.item.name }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-choose-vendor.form.itemCode' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ procurementItem.prItem.item.code }}
        </div>
      </div>

      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-choose-vendor.form.uom' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ procurementItem.prItem.item.uom.name }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-choose-vendor.form.quantity' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ procurementItem.prItem.quantity | resolveNumberToDecimal }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-choose-vendor.form.reminingQuantity' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ reminingQuantity | resolveNumberToDecimal }}
        </div>
      </div>
    </form>
    <h5 class="separator"></h5>
    <app-alert-x
      color="WARNING"
      *ngIf="!isHasRemainingQuantity && !isMustFullFill && isThingsToConsider"
      >{{
        'app-popup-choose-vendor-multi-winner.popup.alertInfo.thingsToConsider'
          | translate
      }}</app-alert-x
    >
    <app-alert-x
      color="DANGER"
      *ngIf="!isHasRemainingQuantity && isMustFullFill"
      >{{
        'app-popup-choose-vendor-multi-winner.popup.alertInfo.mustBeFullfilled'
          | translate
      }}</app-alert-x
    >
    <app-alert-x color="WARNING" *ngIf="isHasRemainingQuantity">{{
      ('app-popup-choose-vendor-multi-winner.popup.alertInfo.youStillHave'
        | translate) +
        ' ' +
        reminingQuantity +
        ' ' +
        ('app-popup-choose-vendor-multi-winner.popup.alertInfo.toAllocate'
          | translate)
    }}</app-alert-x>
    <div
      *ngIf="isShowChangeWinningVendor && todo === 'view'"
      class="col-sm-12 pr-0 pb-2 text-right"
    >
      <app-button (onClick)="doChangeWinningVendor()">
        {{
          'app-popup-choose-vendor-multi-winner.button.changeWinningVendor'
            | translate
        }}</app-button
      >
    </div>
    <br />

    <app-table-xx
      [model]="tableResponse"
      [isShowSearch]="false"
      *ngIf="!formLoading"
      (onAfterRowCreated)="onAfterRowCreated($event)"
    >
    </app-table-xx>

    <ng-template #modalFooter let-activeModal>
      <div *ngIf="todo !== 'view'" class="col-12 text-center">
        <app-button
          class="mr-1"
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button (onClick)="doEmitData()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </div>
</app-popup>
