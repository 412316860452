<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="user-action.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-table [model]="tableResponse" [isServerSide]="true"
        stringUrl="/user-action/user/index">
        <ng-template #headerFilterTable>
          <div class="app-table-filter-item" [formGroup]="formGroup">
            <label for="organizationName">{{'user-action.form.organization' |
              translate}}</label>
            <app-choose size="XL" formControlName="organizationName"
              (onClick)="onChooseOrganization($event)"></app-choose>
          </div>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button mode="BYPASS" size="TN" (onClick)="doView(rowData)"
            title="{{ 'app.tooltip.view' | translate }}">
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>