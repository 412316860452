import { Component, ViewEncapsulation } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { Contract } from '../../../core/bean/contract';
import { File } from '../../../core/bean/file';
import { PurchaseRequest } from '../../../core/bean/purchase-request';
import { PurchaseRequestItem } from '../../../core/bean/purchase-request-item';
import { TableResponseModel } from '../../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../../core/components/upload';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { RouteRequestModel } from '../../../core/model/route-request-model';
import { Validators } from '../../../core/validators';
import { WorkPlanContractDTO } from '../dto/work-plan-contract.dto';
@Component({
  templateUrl: 'work-plan-contract.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class WorkPlanContractComponent extends BaseModuleComponent {
  public readonly FILE_TYPE_CODE = this.global.appConstant.fileType.DOC_CONTRACT;
  public tableResponse: TableResponseModel<File>;
  public workPlanRealizeId: number;
  organizationName: string;
  url: string;
  public routeList: string[] = [
    'work-plan',
    'purchase-request',
    'procurement',
    'contract',
    'purchase-order',
    'delivery-received',
    'payment'
  ];
  public workPlanContractDTO: WorkPlanContractDTO = new WorkPlanContractDTO();
  fileUploader: FileUploader = new FileUploader(
    '/work-plan-contract/',
    'TES',
    this.FILE_TYPE_CODE,
    false,
    5
  );
  public fileUploaderList: FileUploader[] = new Array();
  public isAllUploaded = false;
  purchaseRequestItem: PurchaseRequestItem = new PurchaseRequestItem();
  purchaseRequestList: PurchaseRequest[];
  purchaseRequestItemList: PurchaseRequestItem[];
  contractList: Contract[];
  isDisabledAddMore = false;
  public purchaseRequestIds: number[] = [];
  public purchaseRequestIdsDisable: number[] = [];
  msgInfo = '';
  public isHavePO = false;
  public isHaveDR = false;
  public isHaveContract = false;
  public isContractValidList: boolean[] = new Array();
  public sameContractNumberList: string[] = new Array();
  public isAllContractNumberValid = true;

  constructor(translateService: TranslateService) {
    super('work-plan-contract', translateService);
  }

  onInit(): void {
    // this.workPlanRealizeId = this.global.routerParams['workPlanRealizeId'];
    this.workPlanRealizeId = this.global.routerParams.get('workPlanRealizeId');

    this.formGroup = this.formBuilder.group({
      contractList: this.formBuilder.array([])
    });
    this.httpClientService
      .post<WorkPlanContractDTO>(
        '/work-plan-contract/add-edit',
        new RouteRequestModel(this.todo, this.workPlanRealizeId)
      )
      .subscribe(workPlanContractDTO => {
        this.workPlanContractDTO = workPlanContractDTO;
        this.workPlanContractDTO.workPlanRealizeId = this.workPlanRealizeId;
        this.contractList = workPlanContractDTO.contractList;
        this.workPlanContractDTO.organizationName =
          workPlanContractDTO.organizationName;
        if (this.workPlanContractDTO.contractList != null) {
          this.isHaveContract = true;
          this.todo = 'edit';
          this.buildContract(this.workPlanContractDTO.contractList);
        } else {
          this.buildContract();
        }
        this.setStateReady();
      });
    this.checkUploadedStatus();
  }

  public buildContract(contractList?: Contract[]): void {
    const contractForm = this.formGroup.controls.contractList as FormArray;
    if (contractList != null) {
      this.workPlanContractDTO.contractList.forEach((contract, index) => {
        const startDate: Date = new Date(contract.startDate);
        const endDate: Date = new Date(contract.endDate);
        contractForm.push(
          this.formBuilder.group({
            id: contract.id,
            contractNumber: [contract.number, Validators.required()],
            startDate: [startDate, Validators.required()],
            endDate: [endDate, Validators.required()],
            description: [contract.description, Validators.required()],
            purchaseRequestList: this.formBuilder.array([]),
            file: [null, Validators.required()]
          })
        );
        this.buildPurchaseRequestList(contract.purchaseRequestList, index);
        this.fileUploaderList.push(
          new FileUploader(
            '/work-plan-contract/',
            'TES',
            this.FILE_TYPE_CODE,
            false,
            5
          )
        );
        this.fileUploaderList[index].setFileList(contract.fileList);
        this.isContractValidList.push(true);
      });
    } else {
      const index = contractForm.length;
      contractForm.push(
        this.formBuilder.group({
          id: [null],
          contractNumber: [null, Validators.required()],
          startDate: [null, Validators.required()],
          endDate: [null, Validators.required()],
          description: [null, Validators.required()],
          purchaseRequestList: this.formBuilder.array([]),
          file: [null, Validators.required()]
        })
      );
      this.buildPurchaseRequestList(null, index);
      this.fileUploaderList.push(
        new FileUploader(
          '/work-plan-contract/',
          'TES',
          this.FILE_TYPE_CODE,
          false,
          5
        )
      );
      this.isContractValidList.push(true);
    }
  }

  public buildPurchaseRequestList(
    contractPurchaseRequestList: PurchaseRequest[],
    index: number
  ): void {
    const contractForm = this.formGroup.controls.contractList as FormArray;
    const purchaseRequestListForm = contractForm.controls[index].get(
      'purchaseRequestList'
    ) as FormArray;

    if (contractPurchaseRequestList === null) {
      purchaseRequestListForm.push(
        this.formBuilder.group({
          id: [null],
          purchaseRequest: [null, Validators.required()],
          purchaseRequestItemList: this.formBuilder.array([]),
          vendor: [null, Validators.required()],
          totalRealisasi: [null]
        })
      );
    } else {
      contractPurchaseRequestList.forEach((purchaseRequestValue, indexPR) => {
        // tslint:disable-next-line:max-line-length
        const indexPurchaseRequest = this.workPlanContractDTO.purchaseRequestList.findIndex(
          purchaseRequest =>
            purchaseRequest.id ===
            this.workPlanContractDTO.contractList[index].purchaseRequestList[
              indexPR
            ].id
        );
        purchaseRequestListForm.push(
          this.formBuilder.group({
            id: purchaseRequestValue.id,
            purchaseRequest: [
              this.workPlanContractDTO.purchaseRequestList[
                indexPurchaseRequest
              ],
              Validators.required()
            ],
            vendor: [purchaseRequestValue.vendor, Validators.required()],
            purchaseRequestItemList: this.formBuilder.array([]),
            totalRealisasi: purchaseRequestValue.name
          })
        );
        this.doOnChangePurchaseRequest(
          purchaseRequestListForm.controls[indexPR]
        );
      });
    }
  }

  public buildPurchaseRequestItemList(
    purchaseRequestItemList: PurchaseRequestItem[],
    index: number,
    purchaseRequestListForm: FormArray
  ): void {
    const purchaseRequesItemtListForm = purchaseRequestListForm.controls[
      index
    ].get('purchaseRequestItemList') as FormArray;
    if (purchaseRequestItemList != null) {
      purchaseRequestItemList.forEach(purchaseRequestItem => {
        this.purchaseRequestItem = purchaseRequestItem;
        purchaseRequesItemtListForm.push(
          this.formBuilder.group({
            id: purchaseRequestItem.id,
            quantity: purchaseRequestItem.quantity,
            unitPrice: purchaseRequestItem.unitPrice,
            totalPrice: purchaseRequestItem.totalPrice,
            unitPriceRealize: purchaseRequestItem.unitPriceRealize,
            totalPriceRealize: purchaseRequestItem.totalPriceRealize,
            workPlanItem: purchaseRequestItem.workPlanItem,
            description: purchaseRequestItem.description,
            vendor: null
          })
        );
      });
    }
  }

  onTabChange(event): void {
    this.router.navigate([
      '/pages/work-plan-realize/' + this.routeList[event.index]
    ]);
  }

  public onClickAddContract(): void {
    this.buildContract(null);
  }

  public onClickAddPurchaseRequest(i: number): void {
    const contractForm = this.formGroup.controls.contractList as FormArray;
    const purchaseRequestListForm = contractForm.controls[i].get(
      'purchaseRequestList'
    ) as FormArray;
    purchaseRequestListForm.push(
      this.formBuilder.group({
        id: null,
        purchaseRequest: [null],
        vendor: null,
        purchaseRequestItemList: this.formBuilder.array([]),
        totalRealisasi: [null]
      })
    );
  }

  public doOnChangePurchaseRequest(purchaseRequestObj?: any): void {
    const contractListForm = this.formGroup.controls.contractList as FormArray;
    let purchaseRequest = new PurchaseRequest();
    purchaseRequest = purchaseRequestObj.get('purchaseRequest').value;
    const purchaseRequestItemListForm = purchaseRequestObj.controls
      .purchaseRequestItemList as FormArray;
    purchaseRequest.isDisabled = true;
    this.purchaseRequestIds = [];
    this.purchaseRequestIdsDisable = [];

    for (const contract of contractListForm.value) {
      for (const pr of contract.purchaseRequestList) {
        this.purchaseRequestIds.push(pr.purchaseRequest.id);
      }
    }

    this.workPlanContractDTO.purchaseRequestList.forEach(pr => {
      this.purchaseRequestIds.forEach(id => {
        if (pr.id === id) {
          this.purchaseRequestIdsDisable.push(pr.id);
        }
      });
    });

    this.workPlanContractDTO.purchaseRequestList.forEach(pr => {
      pr.isDisabled = false;
      this.purchaseRequestIdsDisable.forEach(id => {
        if (pr.id === id) {
          pr.isDisabled = true;
        }
      });
    });
    const lengthPR = this.workPlanContractDTO.purchaseRequestList.length;
    const lengthPRSelected = this.purchaseRequestIdsDisable.length;

    if (lengthPR === lengthPRSelected) {
      this.isDisabledAddMore = true;
      this.msgInfo = this.translateService.instant(
        'work-plan-contract.tooltip.pRoutOfStock'
      );
    }

    let totalRealisasi = 0;
    for (let i = 0; i <= purchaseRequestItemListForm.length; i++) {
      purchaseRequestItemListForm.removeAt(0);
    }

    purchaseRequest.purchaseRequestItemList.forEach(purchaseRequestItem => {
      purchaseRequestItemListForm.push(
        this.formBuilder.group({
          id: purchaseRequestItem.id,
          quantity: purchaseRequestItem.quantity,
          unitPrice: purchaseRequestItem.unitPrice,
          totalPrice: purchaseRequestItem.totalPrice,
          convertUnitPriceRealize: this.global.converterService.convertMoney(
            purchaseRequestItem.unitPriceRealize.toString()
          ),
          convertTotalPriceRealize: this.global.converterService.convertMoney(
            purchaseRequestItem.totalPriceRealize.toString()
          ),
          workPlanItem: purchaseRequestItem.workPlanItem,
          description: purchaseRequestItem.description
        })
      );

      totalRealisasi = totalRealisasi + purchaseRequestItem.totalPriceRealize;
    });
    purchaseRequestObj.patchValue({
      id: purchaseRequest.id,
      purchaseRequest,
      totalRealisasi: this.global.converterService.convertMoney(
        totalRealisasi.toString()
      )
    });
  }

  public doSave(): void {
    this.validate();
    this.workPlanContractDTO.contractList = this.formGroup.controls.contractList.value;
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.workPlanContractDTO = this.global.copyFormAttributeToModel(
            this.workPlanContractDTO,
            this.formGroup
          );
          this.workPlanContractDTO.contractList.forEach((contract, i) => {
            contract.fileObjectList = this.fileUploaderList[i].fileObjectList;
          });
          this.setStateProcessing();
          if (this.todo === 'edit') {
            this.url = '/work-plan-contract/update';
          } else {
            this.url = '/work-plan-contract/insert';
          }
          this.httpClientService
            .post<Response<Contract>>(this.url, this.workPlanContractDTO)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.isHaveContract = true;
                this.global.alertService.showSuccessSaving();
                this.setStateReady();
              } else {
                this.global.alertService.showError(response.statusText);
                this.setStateReady();
              }
            });
        }
      });
  }

  onChangeFileUpload(): void {
    this.checkUploadedStatus();
  }

  public doDeletePurchaseRequest(
    i: number,
    index: number,
    purchaseRequestList: FormArray
  ): void {
    const id = purchaseRequestList.at(i).value.id;
    this.workPlanContractDTO.purchaseRequestList.forEach(purchaseRequest => {
      if (purchaseRequest.id === id) {
        purchaseRequest.isDisabled = false;
      }
    });
    const contractListForm = this.formGroup.controls.contractList as FormArray;
    const purchaseRequestListForm = contractListForm.controls[index].get(
      'purchaseRequestList'
    ) as FormArray;
    if (purchaseRequestListForm.length > 1) {
      purchaseRequestListForm.removeAt(i);
    } else {
      this.global.alertService.showError(
        this.translateService.instant(
          'work-plan-realize.alert.addressJobMinimum'
        )
      );
    }
    this.checkUploadedStatus();
    this.isDisabledAddMore = false;
    this.msgInfo = '';
  }

  public doDeleteContract(i: number): void {
    const contractForm = this.formGroup.controls.contractList as FormArray;
    if (contractForm.length > 1) {
      contractForm.removeAt(i);
      this.fileUploaderList.splice(i);
    } else {
      this.global.alertService.showError(
        this.translateService.instant(
          'work-plan-realize.alert.addressJobMinimum'
        )
      );
    }
    this.checkUploadedStatus();
  }

  public checkUploadedStatus(): void {
    let isAllUploaded = true;
    this.fileUploaderList.forEach(fileuploader => {
      if (!fileuploader.isAllUploaded) {
        isAllUploaded = fileuploader.isAllUploaded;
      }
    });
    this.isAllUploaded = isAllUploaded;
  }

  public checkContractNumber(index: number): void {
    let next = true;
    const contractList: Contract[] = this.formGroup.controls.contractList.value;
    const contractNumber = contractList[index].number;
    this.log.info(contractNumber);
    contractList.forEach((contract, indexContract) => {
      this.isContractValidList[indexContract] = true;
      contractList.forEach((contract2, indexContract2) => {
        if (
          indexContract !== indexContract2 &&
          contract.number === contract2.number
        ) {
          this.isContractValidList[indexContract] = false;
          this.sameContractNumberList[indexContract] = contract.number;
          next = false;
        }
      });
    });
    /* if (next && contractNumber !== '') {
            this.httpClient.post<ResponseModel<Contract>>('/work-plan-contract/check-contract', contractNumber
            ).subscribe(response => {
                if (response.status !== ResponseStatusModel.OK) {
                    this.isContractValidList[index] = false;
                    this.isAllContractNumberValid = false;
                    this.sameContractNumberList[index] = response.body.number;
                }
            })
        } */
    this.isAllContractNumberValid = next;
  }

  public doPrevious(): void {
    this.router.navigate(['/pages/work-plan-realize/procurement']);
  }
  public doNext(): void {
    this.router.navigate(['/pages/work-plan-realize/purchase-order']);
  }

  public get formArrayContractList(): FormArray {
    return this.formGroup.get('contractList') as FormArray;
  }
}
