import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';
import { Association } from '../../../core/bean/association';
import { Qualification } from '../../../core/bean/qualification';
import { Segmentation } from '../../../core/bean/segmentation';
import { VendorAssociation } from '../../../core/bean/vendor-association';
import { VendorSegmentation } from '../../../core/bean/vendor-segmentation';
import { VendorSegmentationHistory } from '../../../core/bean/vendor-segmentation-history';
import { FileUploader } from '../../../core/components/upload';
import { OptionListModel } from '../../../core/model/option-list-model';
import { Validators } from '../../../core/validators';
import { VendorPopupVendorSegmentationDTO } from '../dto/vendor-popup-vendor-segmenetation.dto';

@Component({
  templateUrl: './app-popup-segmentation-vendor.component.html',
  styles: [
    '.company-profile-insert { color: blue; border-style: solid; border-width: thin; float: right }',
    '.company-profile-update { color: orange; border-style: solid; border-width: thin; float: right }',
    '.company-profile-delete { color: red; border-style: solid; border-width: thin; float: right }',
    '.company-profile-text-crud { margin: 0.5rem }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupSegmentationVendorComponent extends BaseComponentComponent {
  @Input() public todo: string;
  @Input() public vendorSegmentationHistoryEdit: VendorSegmentationHistory;
  @Input() public vendorSegmentationHistoryList: VendorSegmentationHistory[];
  @Input() public vendorSegmentationList: VendorSegmentation[];
  @Input() public existSegmentationList: Segmentation[];
  @Input() public vendorSegmentation: VendorSegmentation;
  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();

  public vendorPopupVendorSegmentationDTO: VendorPopupVendorSegmentationDTO =
    new VendorPopupVendorSegmentationDTO();
  public vendorAssociationHistoryList: any[] = [];

  public fileUploaderList: FileUploader[] = [];
  public dataAutoComplete: Association;
  public vendorAssociationList: VendorAssociation[] = [];
  public isVendorList: boolean;
  public fromOutside: string;
  public associations: Association[] = [];
  public startDateActualList: string[] = [];
  public endDateActualList: string[] = [];
  public startDateHistoryList: string[] = [];
  public endDateHistoryList: string[] = [];
  public lastDataSegmentation: Segmentation;

  public qualificationOptionList: OptionListModel<Qualification> =
    new OptionListModel(true);

  constructor(public datePipe: DatePipe, public activeModal: NgbActiveModal) {
    super('tab-profile-segmentation');
  }

  public onInit(): void {
    this.setParam();
    this.getVendorSegmentation();
    this.buildFormGroup();
    this.buildFormArray();
    this.setFormGroup();
    if (this.todo === 'detail') {
      this.setViewOnly();
    }
  }

  public setParam(): void {
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      segmentation: [null, { validators: [Validators.required()] }],
      qualification: [null, Validators.required()],
      associationList: this.formBuilder.array([]),
      vendorSegmentationId: [null]
    });
  }

  public buildFormArray(): void {
    this.associationList.push(this.newAssociation());
  }

  public setFormGroup(): void {
    this.getAppPopupVendorSegmentationFromServer(
      '/tab-profile-segmentation/add-edit-popup-vendor-segmentation'
    ).subscribe(vendorPopupVendorSegmentationDTO => {
      this.vendorPopupVendorSegmentationDTO = vendorPopupVendorSegmentationDTO;
      this.qualificationOptionList.setRequestValues(
        vendorPopupVendorSegmentationDTO.qualificationList
      );
      if (this.vendorSegmentationHistoryEdit !== undefined) {
        const { segmentationList, qualification, id, vendorSegmentationId } =
          this.vendorSegmentationHistoryEdit;
        // this.segmentationList.push(segmentation);
        if (
          this.fileUploaderList[0].fileObjectList &&
          this.fileUploaderList[0].fileObjectList.length === 0 &&
          this.vendorSegmentationHistoryEdit.vendorAssociationHistoryList
            .length !== 0 &&
          this.vendorSegmentationHistoryEdit.vendorAssociationHistoryList[0]
            .association !== null
        ) {
          this.fileUploaderList = [];
        }
        this.vendorSegmentationHistoryEdit.vendorAssociationHistoryList.forEach(
          vendorAssociationHistory => {
            if (vendorAssociationHistory.association !== null) {
              this.associations.push(vendorAssociationHistory.association);
            }
            const fileUploader: FileUploader = new FileUploader(
              '/tab-profile-segmentation/',
              'segmentation.association',
              this.global.appConstant.fileType.DOC_CERTIFICATE_VENDOR
            );
            const association = vendorAssociationHistory.association;
            const certificateNumber =
              vendorAssociationHistory.certificateNumber;
            const vendorAssociationId =
              vendorAssociationHistory.vendorAssociationId;
            let endDate = null;
            let startDate = null;
            if (vendorAssociationHistory.endDate !== null) {
              endDate = new Date(vendorAssociationHistory.endDate);
            }
            if (vendorAssociationHistory.startDate !== null) {
              startDate = new Date(vendorAssociationHistory.startDate);
            }
            fileUploader.setFile(vendorAssociationHistory.file);
            if (
              vendorAssociationHistory.fileObjectList &&
              vendorAssociationHistory.fileObjectList.length !== 0
            ) {
              fileUploader.fileObjectList =
                vendorAssociationHistory.fileObjectList;
            }
            this.fileUploaderList.push(fileUploader);
            const addAssociation = {
              certificateNumber,
              association,
              endDate,
              startDate,
              fileCertificate: fileUploader.fileObjectList,
              vendorAssociationId,
              fileUploader,
              crudInfo: vendorAssociationHistory.crudInfo,
              file: vendorAssociationHistory.file
            };
            this.vendorAssociationHistoryList.push(addAssociation);
          }
        );

        for (
          let index = 0;
          index < this.vendorAssociationHistoryList.length;
          index++
        ) {
          if (index !== this.vendorAssociationHistoryList.length - 1) {
            this.associationList.push(this.newAssociation());
          }
        }

        this.formGroup.patchValue({
          id,
          segmentation: segmentationList,
          qualification,
          associationList: this.vendorAssociationHistoryList,
          vendorSegmentationId
        });
      }
      this.setStateReady();
    });
  }

  public get associationList(): FormArray {
    return this.formGroup.get('associationList') as FormArray;
  }

  public newAssociation(): FormGroup {
    const fileUploader = new FileUploader(
      '/tab-profile-segmentation/',
      'association',
      this.global.appConstant.fileType.DOC_CERTIFICATE_VENDOR
    );
    this.fileUploaderList.push(fileUploader);
    const formGroup = this.formBuilder.group({
      association: [null],
      certificateNumber: [null, Validators.maxLength(32)],
      endDate: [null],
      startDate: [null],
      fileCertificate: [null],
      vendorAssociationId: [null],
      fileUploader: [null],
      crudInfo: [null],
      file: [null]
    });
    if (this.todo === 'detail') {
      formGroup.setIsView(true);
    }
    return formGroup;
  }

  public autoCompleteValueChange(value: any, i: any): void {
    this.dataAutoComplete = value;
    if (this.associations[i] === undefined) {
      this.associations.push(value);
    } else {
      this.associations[i] = value;
    }
  }

  public getVendorSegmentation(): void {
    if (this.vendorSegmentationList) {
      this.vendorSegmentationHistoryList.forEach(segmentationHistory => {
        if (segmentationHistory === this.vendorSegmentationHistoryEdit) {
          this.vendorSegmentationList.forEach(segmentation => {
            if (segmentation.id === segmentationHistory.vendorSegmentationId) {
              this.vendorSegmentation = segmentation;
            }
          });
        }
      });
      if (this.vendorSegmentation === undefined) {
        this.vendorSegmentation = new VendorSegmentation();
      }
    }
    if (this.vendorSegmentationHistoryEdit !== undefined) {
      this.vendorSegmentationHistoryEdit.vendorAssociationHistoryList.forEach(
        (associationHistory, index) => {
          let vendorAssociation = null;
          if (
            this.vendorSegmentationHistoryEdit.vendorAssociationList !==
            undefined
          ) {
            vendorAssociation =
              this.vendorSegmentationHistoryEdit.vendorAssociationList[index];
          }
          const endDateHistory =
            associationHistory.endDate !== null
              ? this.datePipe.transform(
                  new Date(associationHistory.endDate),
                  this.global.config.parameterModel.dateFormatShort
                )
              : null;
          this.endDateHistoryList.push(endDateHistory);
          const startDateHistory =
            associationHistory.startDate !== null
              ? this.datePipe.transform(
                  new Date(associationHistory.startDate),
                  this.global.config.parameterModel.dateFormatShort
                )
              : null;
          this.startDateHistoryList.push(startDateHistory);
          if (vendorAssociation !== undefined && vendorAssociation !== null) {
            const endDateActual =
              vendorAssociation.endDate !== null
                ? this.datePipe.transform(
                    new Date(vendorAssociation.endDate),
                    this.global.config.parameterModel.dateFormatShort
                  )
                : null;
            const startDateActual =
              vendorAssociation.startDate !== null
                ? this.datePipe.transform(
                    new Date(vendorAssociation.startDate),
                    this.global.config.parameterModel.dateFormatShort
                  )
                : null;
            this.endDateActualList.push(endDateActual);
            this.startDateActualList.push(startDateActual);
          }
        }
      );
    }
  }

  public doAddMoreAssociation(): void {
    this.associationList.push(this.newAssociation());
  }

  public doChangeSegmentation(value: any): void {
    this.lastDataSegmentation = value[value.length - 1];
    this.existSegmentationList.forEach(segmentation => {
      if (segmentation.name === this.lastDataSegmentation.name) {
        this.formGroup
          .get('segmentation')
          .setErrors({ message: 'app.validation.exists' });
      }
    });
  }

  public doDeleteAssociation(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.associationList.removeAt(index);
          this.associations.splice(index, 1);
        }
      });
  }

  public getAppPopupVendorSegmentationFromServer(
    urlVendorSegmentationPopup: string
  ): Observable<VendorPopupVendorSegmentationDTO> {
    return this.httpClientService.post<VendorPopupVendorSegmentationDTO>(
      urlVendorSegmentationPopup,
      null
    );
  }

  public doSave(): void {
    this.validate();
    let isAssociationDifferent = true;
    this.associations.forEach((association, index1) => {
      this.associations.forEach((associationTemp, index2) => {
        if (association.code === associationTemp.code && index1 !== index2) {
          isAssociationDifferent = false;
        }
      });
    });
    if (this.formGroup.valid && isAssociationDifferent) {
      this.onChange.emit(this.formGroup.value);
      this.activeModal.dismiss();
    } else if (this.associations.length !== 0) {
      this.global.alertService.showError(
        'tab-profile-segmentation.validation.association',
        '.app-popup-segmentation-vendor'
      );
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
