<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="vendor-list.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-fieldset legend="Filter">
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-list.form.vendorType' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="vendorTypeList"
                [optionList]="vendorTypeOptionList"
              ></app-chosen>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-list.form.vendorStatus' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box
                formControlName="vendorStatus"
                [optionList]="vendorStatusOptionList"
              ></app-combo-box>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-list.form.certificateStatus' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="certificateStatusList"
                [optionList]="certificateStatusOptionList"
              ></app-chosen>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-list.form.segmentation' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-tree
                formControlName="segmentationList"
                stringUrl="/segmentation"
                [isMultiple]="true"
                header="{{ 'vendor-list.popup.segmentation' | translate }}"
              >
              </app-text-tree>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-list.form.category' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="vendorCategoryList"
                [optionList]="vendorCategoryOptionList"
              ></app-chosen>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-list.form.location' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-tree
                formControlName="regionList"
                stringUrl="/region"
                [isMultiple]="true"
                header="{{ 'vendor-list.popup.country' | translate }}"
                isLazy="true"
              >
              </app-text-tree>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-list.form.qualification' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="qualificationList"
                [optionList]="qualificationOptionList"
              ></app-chosen>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-list.form.vendorActivityStatus' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="vendorActivityStatusList"
                [optionList]="vendorActivityStatusOptionList"
              ></app-chosen>
            </div>
          </div>

          <div class="button-group button-group-center mt-5">
            <app-button mode="BYPASS" color="SECONDARY" (onClick)="doReset()">
              <em class="fas fa-sync-alt"></em>
              {{ 'app.button.reset' | translate }}
            </app-button>
            <app-button
              mode="BYPASS"
              color="PRIMARY"
              (onClick)="doApply()"
              [disabled]="formSaving"
              ><em class="fas fa-check"></em>
              {{ 'app.button.apply' | translate }}
            </app-button>
          </div>
        </form>
      </app-fieldset>

      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/vendor-list/index"
      >
        <ng-template #headerButtons>
          <app-button
            mode="BYPASS"
            (onClick)="doExport()"
            [disabled]="isLoading"
            ><em
              class="fas"
              [ngClass]="
                !isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'
              "
            ></em
            >{{ 'app.button.export' | translate }}</app-button
          >
        </ng-template>

        <ng-template #actionButtons let-rowData>
          <ng-container
            *ngIf="
              rowData.vendorActivityStatus?.code ===
                global.appConstant.vm.V_ACTIVITY_STATUS_PROFILE_COMPLETION &&
                rowData.vendorStatus?.code ===
                  global.appConstant.vm.V_ACTIVITY_STATUS_CANDIDATE;
              then buttonView;
              else buttonViewVendor
            "
          ></ng-container>
          <ng-template #buttonView>
            <app-button
              mode="BYPASS"
              size="TN"
              (onClick)="doView(rowData)"
              title="{{ 'app.tooltip.view' | translate }}"
            >
              <span class="fas fa-search"></span>
            </app-button>
          </ng-template>

          <ng-template #buttonViewVendor>
            <app-button
              mode="BYPASS"
              size="TN"
              (onClick)="doViewVendor(rowData)"
              title="{{ 'app.tooltip.view' | translate }}"
            >
              <span class="fas fa-search"></span>
            </app-button>
          </ng-template>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
