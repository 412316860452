import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { Coa } from './../../core/bean/coa';
import { Response } from './../../core/model/response-model';
@Component({
  templateUrl: './coa-bjb-edit-add.component.html'
})
export class CoaBjbEditAddComponent extends BaseModuleComponent {
  public coaId: number;
  constructor(translateService: TranslateService) {
    super('coa-bjb', translateService);
  }
  public onInit(): void {
    this.todo = this.global.routerParams.get('todo');
    this.coaId = this.global.routerParams.get('coaId');
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      description: [null, Validators.maxLength(512)],
      isMultiYears: [false],
      parentId: [null],
      tempId: [null],
      hasChild: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<Coa>(
        '/coa-bjb/add-edit',
        new RouteRequestModel(this.todo, this.coaId)
      )
      .subscribe((coa: Coa) => {
        if (coa != null) {
          this.formGroup.patchValue(coa);
        }
        this.setStateReady();
      });
    if (this.todo === 'edit') {
      this.formGroup.get('code').setIsView(true);
    }
  }

  public doOnChangeIsMultiYears(isMultiYears: boolean): void {
    if (isMultiYears) {
      this.global.modalService
        .confirm(
          this.translateService.instant('coa-bjb.confirmation.body'),
          this.translateService.instant('coa-bjb.confirmation.header')
        )
        .pipe(take(1))
        .subscribe(result => {
          if (!result) {
            this.formGroup.get('isMultiYears').patchValue(false);
          }
        });
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const url =
              this.todo === 'edit' ? '/coa-bjb/update' : '/coa-bjb/insert';
            const coa: Coa = this.formGroup.value;
            if (coa.isMultiYears == null) {
              coa.isMultiYears = false;
            }
            this.httpClientService
              .post<Response<Coa>>(url, coa)
              .subscribe((response: Response<Coa>) => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/coa-bjb/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/coa-bjb/']);
  }
}
