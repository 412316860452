<app-popup
  [header]="'tab-profile-partnership.form.popup.header.partnership.' + todo"
  [isLoading]="formLoading"
>
  <form [formGroup]="formGroup" class="app-popup-expert" *ngIf="!formLoading">
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-partnership.form.popup.partnership' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        <app-text-field
          [autofocus]="true"
          size="LG"
          formControlName="partnership"
          maxLength="255"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorPartnershipHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorPartnership.partnership"
            [newValue]="vendorPartnershipHistoryEdit.partnership"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-partnership.form.popup.country' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-dropdown-select
          size="LG"
          [optionList]="countryRegionOptionList"
          formControlName="countryRegion"
          (onChange)="doChangeCountryRegion()"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorPartnershipHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorPartnership.countryRegion
                ? vendorPartnership.countryRegion.name
                : null
            "
            [newValue]="
              vendorPartnershipHistoryEdit.countryRegion
                ? vendorPartnershipHistoryEdit.countryRegion.name
                : null
            "
          ></app-flag-new>
        </app-dropdown-select>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-partnership.form.popup.city' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-auto-complete
          size="LG"
          formControlName="cityRegion"
          stringUrl="/company-profile/auto-complete-city-region-list"
          [params]="countryRegionId"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorPartnershipHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorPartnership.cityRegion
                ? vendorPartnership.cityRegion.name
                : null
            "
            [newValue]="
              vendorPartnershipHistoryEdit.cityRegion
                ? vendorPartnershipHistoryEdit.cityRegion.name
                : null
            "
          ></app-flag-new>
        </app-auto-complete>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-partnership.form.popup.address' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        <app-text-area size="LG" formControlName="address" maxLength="512">
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorPartnershipHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorPartnership.address"
            [newValue]="vendorPartnershipHistoryEdit.address"
            [ngStyle]="{ 'padding-left': '3px' }"
          ></app-flag-new>
        </app-text-area>
      </div>
    </div>

    <div class="form-group row text-sm-right" *ngIf="!formLoading">
      <label class="col-sm-3 control-label">{{
        'tab-profile-partnership.form.popup.coordinateLocation' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        <app-text-map size="LG" formControlName="coordinateLocation">
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorPartnershipHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorPartnership.coordinateLocation"
            [newValue]="vendorPartnershipHistoryEdit.coordinateLocation"
          ></app-flag-new>
        </app-text-map>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-partnership.form.popup.statusPartnership' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="partnershipStatus"
          maxLength="32"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorPartnershipHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorPartnership.partnershipStatus"
            [newValue]="vendorPartnershipHistoryEdit.partnershipStatus"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-partnership.form.popup.projectOwner' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="projectOwner"
          maxLength="64"
          type="alphanumeric"
          include="-.\',"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorPartnershipHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorPartnership.projectOwner"
            [newValue]="vendorPartnershipHistoryEdit.projectOwner"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-partnership.form.popup.projectType' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field size="LG" formControlName="projectType" maxLength="32">
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorPartnershipHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorPartnership.projectType"
            [newValue]="vendorPartnershipHistoryEdit.projectType"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-partnership.form.popup.segmentation' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        <app-text-tree
          size="LG"
          formControlName="segmentationList"
          stringUrl="/segmentation"
          [isMultiple]="true"
          header="{{
            'tab-profile-partnership.header.segmentation' | translate
          }}"
          [onlyLastChild]="false"
          isLazy="true"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorPartnershipHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorPartnership.segmentation"
            [newValue]="vendorPartnershipHistoryEdit.segmentationNamesHistory"
          ></app-flag-new>
        </app-text-tree>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-partnership.form.popup.status' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-dropdown-select
          [optionList]="vendorExperienceStatusOptionList"
          formControlName="vendorExperienceStatus"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorPartnershipHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorPartnership.vendorExperienceStatus
                ? vendorPartnership.vendorExperienceStatus.name
                : null
            "
            [newValue]="
              vendorPartnershipHistoryEdit.vendorExperienceStatus
                ? vendorPartnershipHistoryEdit.vendorExperienceStatus.name
                : null
            "
          ></app-flag-new>
        </app-dropdown-select>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-partnership.form.popup.contractNumber' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="contractNumber"
          maxLength="32"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorPartnershipHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorPartnership.contractNumber"
            [newValue]="vendorPartnershipHistoryEdit.contractNumber"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-partnership.form.popup.contractDate' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-date-picker formControlName="contractDate" maxDate="newDate">
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorPartnershipHistoryEdit.vendorHistory !== null
            "
            [oldValue]="date"
            [newValue]="dateHistory"
          ></app-flag-new>
        </app-date-picker>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-partnership.form.popup.contractValue' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-currency
          size="LG"
          formControlName="currencyValue"
          [optionList]="currencyOptionList"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorPartnershipHistoryEdit.vendorHistory !== null
            "
            [oldValue]="valueCurrencyActual"
            [newValue]="valueCurrency"
          ></app-flag-new>
        </app-currency>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-partnership.form.popup.proofOfContract' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-upload
          size="LG"
          formControlName="contractFile"
          [fileUploader]="fileUploader"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorPartnershipHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorPartnership.file ? vendorPartnership.file.id : null
            "
            [newValue]="
              vendorPartnershipHistoryEdit.file
                ? vendorPartnershipHistoryEdit.file.id
                : null
            "
            [hoverValue]="
              vendorPartnership.file ? vendorPartnership.file.fileName : null
            "
          ></app-flag-new>
        </app-upload>
      </div>
    </div>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
        <app-button *ngIf="!formGroup.isView" (onClick)="doSave()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
