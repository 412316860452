import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppItemRequestModule } from 'src/app/core/components/app-item-request/app-item-request.module';
import { AppOfficialReportModule } from 'src/app/core/components/app-official-report/app-official-report.module';
import { AppPopupFinalPriceDetailModule } from 'src/app/core/components/app-popup/app-popup-final-price-detail/app-popup-final-price-detail.module';
import { AppProcurementInfoModule } from 'src/app/core/components/app-procurement-info/app-procurement-info.module';
import { AppWorkflowStepInfoModule } from 'src/app/core/components/app-workflow-step-info/app-workflow-step-info.module';
import { AppTableModule } from 'src/app/core/components/table/components/app-table/app-table.module';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppPopupBidPriceComponent } from './app-popup-bid-price.component';
import { AppPopupCreateAuctionComponent } from './app-popup-create-auction.component';
import { AppPopupOepComponent } from './app-popup-oep.component';
import { AppPopupPriceEvaluationComponent } from './app-popup-price-evaluation.component';
import { AppPopupSuccessfulyCreateComponent } from './app-popup-successfuly-create.component';
import { PriceEvaluationEditComponent } from './price-evaluation-edit.component';

const routes: Routes = [
  {
    path: 'add',
    component: PriceEvaluationEditComponent,
    data: { breadcrumb: 'price-evaluation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: PriceEvaluationEditComponent,
    data: { breadcrumb: 'price-evaluation.breadcrumb.detail' }
  },
  {
    path: 'detail',
    component: PriceEvaluationEditComponent,
    data: { breadcrumb: 'price-evaluation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppProcurementInfoModule,
    AppWorkflowStepInfoModule,
    AppItemRequestModule,
    AppTableModule,
    AppApprovalPrcsXModule,
    AppPopupFinalPriceDetailModule,
    AppOfficialReportModule,
    AppBadgeCatalogModule
  ],
  declarations: [
    PriceEvaluationEditComponent,
    AppPopupOepComponent,
    AppPopupBidPriceComponent,
    AppPopupPriceEvaluationComponent,
    AppPopupCreateAuctionComponent,
    AppPopupSuccessfulyCreateComponent
  ],
  entryComponents: [
    AppPopupOepComponent,
    AppPopupBidPriceComponent,
    AppPopupPriceEvaluationComponent,
    AppPopupCreateAuctionComponent,
    AppPopupSuccessfulyCreateComponent
  ]
})
export class PriceEvaluationModule {}
