import { WebSocketResponseModel } from './../../core/model/websocket-response-model';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WebSocketService } from 'src/app/core/services/websocket/websocket.service';
import { AppPopupSyncBudgetBjbComponent } from './app-popup-sync-budget-allocation-bjb.component';
import { BudgetAllocationBjbComponent } from './budget-allocation-bjb.component';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';

@Injectable()
export class WebSocketBudgetService extends WebSocketService<string> {
  public router: Router;
  public appPopupSyncBudgetBjbComponent: AppPopupSyncBudgetBjbComponent;
  public onCloseModal: EventEmitter<boolean> = new EventEmitter();
  public message: EventEmitter<string> = new EventEmitter();
  public budgetAllocation: BudgetAllocationBjbComponent;

  constructor(public appPopupService: AppPopupService) {
    super('synchronize/budget');
  }

  public onReceivedMessage(message: string): Promise<any> {
    return new Promise(resolve => {
      const webSocketResponseModel: WebSocketResponseModel = JSON.parse(message);
      if (this.appPopupSyncBudgetBjbComponent) {
        if (webSocketResponseModel && webSocketResponseModel.status !== this.global.appConstant.core.WEBSOCKET_STATUS_ON_PROGRESS) {
          const result = webSocketResponseModel.status === this.global.appConstant.core.WEBSOCKET_STATUS_FINISH
            ? true : false;
          setTimeout(() => {
            this.appPopupSyncBudgetBjbComponent.isLoadingSync = false;
            this.appPopupSyncBudgetBjbComponent = null;
            this.onCloseModal.emit(true);
            resolve(result);
          }, 500);
        } else {
          this.appPopupSyncBudgetBjbComponent.isLoadingSync = true;
          this.appPopupSyncBudgetBjbComponent.webSocketResponseModel = webSocketResponseModel;
        }
      } else {
        let bodyMessage;
        if (webSocketResponseModel && webSocketResponseModel.status === this.global.appConstant.core.WEBSOCKET_STATUS_ON_PROGRESS) {
          bodyMessage = webSocketResponseModel.progressValue + ' ' + webSocketResponseModel.progressUnit;
          this.message.emit(bodyMessage);
        } else {
            const result = webSocketResponseModel.status === this.global.appConstant.core.WEBSOCKET_STATUS_FINISH
               ? true : false;
            setTimeout(() => {
              this.message.emit(null);
              resolve(result);
            }, 500);
        }
      }
    });
  }
}
