import { TableRecord } from './table-record';

export class CheckedRecord {
  isCheckedAll: boolean;
  checkedRecordList: Array<TableRecord> = new Array();
  unCheckedRecordList: Array<TableRecord> = new Array();
  customData: string;
  keywords: string;

  public setCheckedRecordList(tableRecordList: Array<TableRecord>): void {
    this.checkedRecordList = tableRecordList;
  }

  public addToCheckedRecordList(tableRecord: TableRecord): void {
    this.checkedRecordList.push(tableRecord);
  }

  public addToUnCheckedRecordList(tableRecord: TableRecord): void {
    this.unCheckedRecordList.push(tableRecord);
  }

  public removeRecordFromCheckedRecordList(tableRecord: TableRecord): void {
    this.removeRecord('checkedRecordList', tableRecord);
  }

  public removeRecordFromUnCheckedRecordList(record: TableRecord): void {
    this.removeRecord('uncheckedRecordList', record);
  }

  private removeRecord(
    from: 'checkedRecordList' | 'uncheckedRecordList',
    record: TableRecord
  ): void {
    const indexOfRecord = (
      from === 'checkedRecordList'
        ? this.checkedRecordList
        : this.unCheckedRecordList
    ).findIndex(r => JSON.stringify(r) === JSON.stringify(record));
    if (indexOfRecord !== -1) {
      (from === 'checkedRecordList'
        ? this.checkedRecordList
        : this.unCheckedRecordList
      ).splice(indexOfRecord, 1);
    }
  }

  public getValue(): {
    isCheckedAll: boolean;
    checkedRecordList: Array<any>;
    unCheckedRecordList: Array<any>;
    customData: string;
    keywords: string;
  } {
    return {
      isCheckedAll: this.isCheckedAll,
      checkedRecordList: this.getCheckedRecordList(),
      unCheckedRecordList: this.getUnCheckedRecordList(),
      customData: this.customData,
      keywords: this.keywords
    };
  }

  public getCheckedRecordList(): Array<any> {
    return Array.from(this.checkedRecordList).map(
      checkedRecord => checkedRecord.record
    );
  }

  public getUnCheckedRecordList(): Array<any> {
    return Array.from(this.unCheckedRecordList).map(
      checkedRecord => checkedRecord.record
    );
  }

  public reset(): void {
    this.isCheckedAll = false;
    this.checkedRecordList.splice(0);
    this.unCheckedRecordList.splice(0);
    this.customData = null;
    this.keywords = null;
  }
}
