import { BudgetAllocation } from '../../../bean/budget-allocation';

export class AppPopupAddBudgetItemModel {
  isCheckedAll = false;
  recordPageMap: Map<number, ChangeBudget> = new Map();
}

export interface ChangeBudget {
  isChecked: boolean;
  budgetAllocation: BudgetAllocation;
}
