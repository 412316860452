<div class="row main-row">
  <div class="col-12">
    <app-card
      header="vendor-blacklist.title"
      detail="vendor-blacklist.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <h5 class="separator">{{
        'vendor-blacklist.separator.dataVendor' | translate
      }}</h5>
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div
          class="form-group row text-sm-right"
          [ngClass]="todo === 'add' ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'vendor-blacklist.form.vendorName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-choose
              *ngIf="todo == 'add'"
              formControlName="vendorName"
              (onClick)="chooseVendor()"
            ></app-choose>
            <p *ngIf="isEdit || formGroup.isView">{{
              vendorBlacklistResponse.vendor.name
            }}</p>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-blacklist.form.vendorCode' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p *ngIf="vendorBlacklistResponse.vendor == null">-</p>
            <p *ngIf="vendorBlacklistResponse.vendor !== null">{{
              vendorBlacklistResponse.vendor.code
            }}</p>
          </div>
        </div>
        <br />
        <!-- </form> -->
        <h5 class="separator">{{
          'vendor-blacklist.separator.vendorViolation' | translate
        }}</h5>
        <small
          class="text-danger"
          *ngIf="
            isSave && vendorBlacklistResponse.vendorViolationList.length === 0
          "
        >
          {{ 'app.validation.required' | translate }}
        </small>
        <app-button
          class="float-right"
          *ngIf="!formGroup.isView"
          (onClick)="doAddViolation()"
          [ngStyle]="{ 'margin-bottom': '2%' }"
          ><em class="fas fa-plus"></em>
          {{ 'app.button.add' | translate }}</app-button
        >
        <div
          class="table-responsive custom-table float-none"
          [ngStyle]="{ margin: '0 auto' }"
        >
          <table
            class="table table-striped box-shadow"
            aria-describedby="Table"
          >
            <thead>
              <tr class="text-center">
                <th scope="col">{{ 'app.table.column.no' | translate }}</th>
                <th scope="col">
                  {{
                    'vendor-blacklist.table.column.blacklistCriteriaName'
                      | translate
                  }}
                </th>
                <th scope="col">
                  {{
                    'vendor-blacklist.table.column.blacklistCriteriaDuration'
                      | translate
                  }}
                </th>
                <th scope="col" *ngIf="!formGroup.isView">
                  {{ 'app.table.column.action' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="vendorViolationList.length == 0">
                <td colspan="99" class="bg-white text-center">
                  {{ 'app.info.noData' | translate }}
                </td>
              </tr>
              <tr
                *ngFor="
                  let vendorViolation of vendorViolationList;
                  let i = index
                "
              >
                <td class="text-center">{{ i + 1 }}.</td>
                <td>
                  {{ vendorViolation.blacklistCriteria.violation }}
                </td>
                <td class="text-center">
                  {{
                    global.converterService.convertMonthToYears(
                      vendorViolation.blacklistCriteria.durationInMonth
                    )
                  }}
                </td>
                <td *ngIf="!formGroup.isView" class="text-center">
                  <app-button
                    size="TN"
                    color="DANGER"
                    (onClick)="doRemove(i)"
                    title="{{ 'app.tooltip.delete' | translate }}"
                  >
                    <span class="fa fas fa-trash"></span>
                  </app-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <br />
        <h5 class="separator">{{
          'vendor-blacklist.separator.blacklistInformation' | translate
        }}</h5>

        <!-- <form class="form-horizontal" [formGroup]="formGroup" novalidate> -->
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'vendor-blacklist.form.startDate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-date-picker
              [minDate]="currentDate"
              formControlName="startDate"
            ></app-date-picker>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'vendor-blacklist.form.blacklistDuration' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              [optionList]="blacklistDurationOptionList"
              formControlName="blacklistDuration"
            ></app-combo-box>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'vendor-blacklist.form.note' | translate
          }}</label>
          <div
            class="text-left"
            [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'"
          >
            <app-text-area formControlName="note" maxLength="512">
            </app-text-area>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'vendor-blacklist.form.reason' | translate
          }}</label>
          <div
            class="text-left"
            [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'"
          >
            <app-text-area formControlName="reason" maxLength="512">
            </app-text-area>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-blacklist.form.docFile' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-upload
              *ngIf="!isReset"
              formControlName="docFile"
              [fileUploader]="fileUploader"
            >
            </app-upload>
            <div *ngIf="isReset">
              <!-- class="text-center" [ngStyle]="{ margin: '5vh 0' }"-->
              <img src="assets/img/common/loader-small.gif" alt="Loading" />
            </div>
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          *ngIf="
            vendor &&
            vendor.vendorActivityStatus.code === 'REVISION_VENDOR_BLACKLIST'
          "
        >
          <label class="col-sm-3 control-label">{{
            'vendor-blacklist.form.approvalNote' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            {{ vendorBlacklistResponse.transactionLog?.approvalNote || '-' }}
          </div>
        </div>

        <div
          *ngIf="!vendorBlacklistResponse.isUserApproval"
          class="row justify-content-center mt-5"
        >
          <app-button
            mode="BYPASS"
            color="SECONDARY"
            (onClick)="doCancel()"
            class="mr-1"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button
            *ngIf="!formGroup.isView"
            color="PRIMARY"
            (onClick)="doReset()"
            class="mr-1"
            [disabled]="formSaving"
            >{{ 'app.button.reset' | translate }}</app-button
          >
          <app-button
            *ngIf="!formGroup.isView"
            color="PRIMARY"
            (onClick)="doSave()"
            [disabled]="formSaving"
            >{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>

    <app-approval-prcs-x
      *ngIf="vendorBlacklistResponse.isUserApproval"
      [workflowModelPrcs]="workflowModelPrcs"
      [approvalModelPrcsId]="approvalModelPrcsId"
    ></app-approval-prcs-x>
  </div>
</div>
