<app-popup [isShowHeaderCloseButton]="true">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <div class="text-center"
        ><br />
        <h3 [ngClass]="'text-primary'">{{
          'pr.popup.item.distributor.title' | translate
        }}</h3
        ><br />
        <div innerHTML="{{ 'pr.popup.item.distributor.note' | translate }}"></div>
      </div>
      <div class="form-group row text-sm-left">
        <div class="col-sm-12 text-left">
          <app-text-area
            formControlName="messageRequestItemToDistributor"
            size="XL"
            maxLength="1024"
          ></app-text-area>
        </div>
      </div>
      <ng-template #modalFooter let-activeModal>
        <div class="button-group button-group-center">
          <app-button
            color="LIGHT"
            [outline]="true"
            (onClick)="activeModal.close(false)"
          >
            {{ 'app.button.cancel' | translate }}
          </app-button>
          <app-button (onClick)="doSend()">
            {{ 'app.button.send' | translate }}
          </app-button>
        </div>
      </ng-template>
    </form>
  </app-popup>
  