import { Action } from '../../../interfaces/action';
import { DatePickerDate } from '../model';
import { DatePickerTime } from '../model/date-picker-time-model';
import { DatePickerDropDownView } from '../type/date-picker-drop-down-view-type';

export const FIRST_LOAD_DATE_PICKER = `[ DATE PICKER ]: First Load`;
export const SET_DATE_PICKER_DATE = `[ DATE PICKER ]: Set Date Picker Date`;
export const SET_DATE_PICKER_MONTH = `[ DATE PICKER ]: Set Date Picker Month`;
export const SET_DATE_PICKER_YEAR = `[ DATE PICKER ]: Set Date Picker Year `;
export const SET_DATE_PICKER_TIME = `[ DATE PICKER ]: Set Date Picker Time`;
export const SET_DATE_PICKER_TIME_HTML_TEXT = `[ DATE PICKER ]: Set Date Picker Time Html Text`;
export const SET_DATE_PICKER_DROP_DOWN_VIEW = `[ DATE PICKER ]: Set Date Picker Drop Down View`;
export const SET_DATE_PICKER_ON_RANGE = `[ DATE PICKER ]: Set Date Picker On Range`;
export const SET_DATE_PICKER_YEAR_LIST = `[ DATE PICKER ]: Set Date Picker Year List`;
export const SET_DATE_PICKER_MIN_OR_MAX_DATE = `[ DATE PICKER ]: Set Min Or Max Date`;
export const CHANGE_DATE_PICKER_TIME = `[ DATE PICKER ]: Change Date Picker Time`;
export const RESET_DATE_PICKER = `[ DATE PICKER ]: Reset Date Picker`;
export const RESET_DATE_PICKER_TIME_HTML_TEXT = `[ DATE PICKER ]: Reset Date Picker Time Html Text`;

export class FirstLoadDatePicker implements Action<void> {
  readonly type = FIRST_LOAD_DATE_PICKER;
}

export class SetDatePickerDate
  implements Action<{ datePickerDate: DatePickerDate }>
{
  readonly type = SET_DATE_PICKER_DATE;
  constructor(public payload: { datePickerDate: DatePickerDate }) {}
}

export class SetDatePickerMonth implements Action<{ month: number }> {
  readonly type = SET_DATE_PICKER_MONTH;
  constructor(public payload: { month: number }) {}
}

export class SetDatePickerYear implements Action<{ year: number }> {
  readonly type = SET_DATE_PICKER_YEAR;
  constructor(public payload: { year: number }) {}
}

export class SetDatePickerTime
  implements Action<{ datePickerTime: DatePickerTime }>
{
  readonly type = SET_DATE_PICKER_TIME;
  constructor(public payload: { datePickerTime: DatePickerTime }) {}
}

export class SetDatePickerTimeHtmlText
  implements
    Action<{
      event: InputEvent & { target: HTMLElement };
      type: 'HOURS' | 'MINUTES';
    }>
{
  readonly type = SET_DATE_PICKER_TIME_HTML_TEXT;
  constructor(
    public payload: {
      event: InputEvent & { target: HTMLElement };
      type: 'HOURS' | 'MINUTES';
    }
  ) {}
}

export class SetDatePickerDropDownView
  implements Action<{ view: DatePickerDropDownView; year?: number }>
{
  readonly type = SET_DATE_PICKER_DROP_DOWN_VIEW;
  constructor(
    public payload: { view: DatePickerDropDownView; year?: number }
  ) {}
}

export class SetDatePickerOnRange
  implements Action<{ datePickerDate: DatePickerDate }>
{
  readonly type = SET_DATE_PICKER_ON_RANGE;
  constructor(public payload: { datePickerDate: DatePickerDate }) {}
}

export class SetDatePickerYearList
  implements Action<{ action: 'BACK' | 'NEXT' }>
{
  readonly type = SET_DATE_PICKER_YEAR_LIST;
  constructor(public payload: { action: 'BACK' | 'NEXT' }) {}
}

export class SetDatePickerMinOrMaxDate
  implements Action<{ minDate: Date | string; maxDate: Date | string }>
{
  readonly type = SET_DATE_PICKER_MIN_OR_MAX_DATE;
  constructor(
    public payload: { minDate: Date | string; maxDate: Date | string }
  ) {}
}

export class ChangeDatePickerTime
  implements
    Action<{
      event: InputEvent & { target: HTMLElement };
      type: 'HOURS' | 'MINUTES';
    }>
{
  readonly type = CHANGE_DATE_PICKER_TIME;
  constructor(
    public payload: {
      event: InputEvent & { target: HTMLElement };
      type: 'HOURS' | 'MINUTES';
    }
  ) {}
}

export class ResetDatePicker implements Action<void> {
  readonly type = RESET_DATE_PICKER;
}

export class ResetDatePickerTimeHtmlText
  implements
    Action<{
      event: FocusEvent & { target: HTMLElement };
      type: 'HOURS' | 'MINUTES';
    }>
{
  readonly type = RESET_DATE_PICKER_TIME_HTML_TEXT;
  constructor(
    public payload: {
      event: FocusEvent & { target: HTMLElement };
      type: 'HOURS' | 'MINUTES';
    }
  ) {}
}

export type AllDatePickerAction =
  | FirstLoadDatePicker
  | SetDatePickerDate
  | SetDatePickerMonth
  | SetDatePickerYear
  | SetDatePickerTime
  | SetDatePickerTimeHtmlText
  | SetDatePickerDropDownView
  | SetDatePickerOnRange
  | SetDatePickerYearList
  | SetDatePickerMinOrMaxDate
  | ChangeDatePickerTime
  | ResetDatePicker
  | ResetDatePickerTimeHtmlText;
