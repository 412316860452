<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="vendor-reactivation.title"
            detail="app.action.{{ todo }}"
            [isLoading]="formLoading" [isSaving]="formSaving">
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>

                <div class="form-group row text-sm-right">
                    <label class="col-sm-3 control-label">{{
                        'vendor-reactivation.table.column.name' |
                        translate}}</label>
                    <div class="col-sm-9 text-left">
                        <p>{{vendorReactivationResponse.vendor.name}}</p>
                    </div>
                </div>

                <div class="form-group row text-sm-right">
                    <label class="col-sm-3 control-label">{{
                        'vendor-reactivation.table.column.registNo' |
                        translate}}</label>
                    <div class="col-sm-9 text-left">
                        <p>{{vendorReactivationResponse.vendor.registrationNumber}}
                        </p>
                    </div>
                </div>

                <br>
                <h5 class="separator">{{'vendor-reactivation.form.info' |
                    translate}}</h5>
                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        'vendor-reactivation.form.reason' | translate}}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-area autofocus="true"
                            maxLength="512" formControlName="reason">
                        </app-text-area>
                    </div>
                </div>

                <div class="form-group row text-sm-right">
                    <label class="col-sm-3 control-label">{{
                        'vendor-reactivation.form.upload' | translate}}</label>
                    <div class="col-sm-9 text-left">
                        <app-upload formControlName="file"
                            [fileUploader]="fileUploader"></app-upload>
                    </div>
                </div>

                <div class="form-group row text-sm-right">
                    <label class="col-sm-3 control-label">{{
                        'vendor-reactivation.form.approvalnote' |
                        translate}}</label>
                    <div class="col-sm-5 text-left">
                        <p *ngIf="vendorReactivationResponse.approvalNote">
                            {{vendorReactivationResponse.approvalNote}}</p>
                        <p *ngIf="!vendorReactivationResponse.approvalNote">
                            - </p>
                    </div>
                </div>

                <div class="button-group button-group-center mt-5">
                    <app-button color="SECONDARY" (onClick)="doCancel()"
                        [disabled]="formSaving">
                        {{ 'app.button.back' | translate}}
                    </app-button>
                    <app-button (onClick)="doSave()" [disabled]="formSaving">
                        {{ 'app.button.save' | translate}}
                    </app-button>
                </div>
            </form>
        </app-card>
    </div>
</div>