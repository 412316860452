import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppWidgetContentSliderModule } from '../../core/widgets/app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../../core/widgets/app-widget.module';
import { AppPopupBudgetAllocationComponent } from './app-popup-budget-allocation.component';
import { AppPopupExportBudgetComponent } from './app-popup-export-budget-allocation.component';
import { BudgetAllocationCoaComponent } from './budget-allocation-coa.component';
import { BudgetAllocationEditAddComponent } from './budget-allocation-edit-add.component';
import { BudgetAllocationOrganizationComponent } from './budget-allocation-organization.component';
import { BudgetAllocationComponent } from './budget-allocation.component';
const routes = [
  { path: '', component: BudgetAllocationComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: BudgetAllocationEditAddComponent,
    data: { breadcrumb: 'budget-allocation.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: BudgetAllocationEditAddComponent,
    data: { breadcrumb: 'budget-allocation.breadcrumb.detail' }
  },
  {
    path: 'edit',
    component: BudgetAllocationEditAddComponent,
    data: { breadcrumb: 'budget-allocation.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'organization/detail/index',
    component: BudgetAllocationOrganizationComponent,
    data: { breadcrumb: 'budget-allocation.breadcrumb.detail' }
  },
  {
    path: 'coa/detail/index',
    component: BudgetAllocationCoaComponent,
    data: { breadcrumb: 'budget-allocation.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppWidgetContentSliderModule,
    AppWidgetModule
  ],
  declarations: [
    BudgetAllocationComponent,
    BudgetAllocationEditAddComponent,
    BudgetAllocationCoaComponent,
    BudgetAllocationOrganizationComponent,
    AppPopupBudgetAllocationComponent,
    AppPopupExportBudgetComponent
  ],
  providers: [],
  entryComponents: [
    AppPopupBudgetAllocationComponent,
    AppPopupExportBudgetComponent
  ],
  exports: [AppPopupBudgetAllocationComponent, AppPopupExportBudgetComponent]
})
export class BudgetAllocationModule {}
