import { ChartOptions } from 'chart.js';
import { Color, MultiDataSet } from 'ng2-charts';
import { chartColorConstant } from '../constant/chart-color-constant';
import { Chart } from '../models/chart';
import { ChartModel } from '../models/chart-model';
import { doughnutChartOptions } from '../options/doughnut-chart.options';
import { customChartPlugin } from '../plugins/app-chart-custom-plugins';
export function setPieChart(chartState: ChartModel): Array<Chart> {
  const { type } = { ...chartState };
  let dataSets: MultiDataSet = [];
  const firstDataOfDataSets = chartState.dataSets[0];
  let labels: Array<string | Array<string>> = [];
  if (typeof firstDataOfDataSets === 'number') {
    dataSets = [chartState.dataSets] as Array<Array<number>>;
  } else if (Array.isArray(firstDataOfDataSets)) {
    dataSets = chartState.dataSets as Array<Array<number>>;
  } else if (
    typeof firstDataOfDataSets === 'object' &&
    firstDataOfDataSets.hasOwnProperty('data')
  ) {
    chartState.dataSets.forEach(dataSet => {
      dataSets.push(dataSet.data);
    });
  }

  labels = [...chartState.labels].splice(0, dataSets[0].length);

  const colors: Array<Color> = [];
  dataSets.forEach(() => {
    colors.push({
      backgroundColor:
        (chartState.options &&
          (chartState.options.defaultColor as Array<string>)) ||
        chartColorConstant
    });
  });

  const options: ChartOptions = doughnutChartOptions;
  if (chartState.options) {
    Object.assign(options, chartState.options);
  }

  return [
    {
      colors,
      dataSets,
      options,
      plugins: [customChartPlugin],
      labels,
      type
    }
  ];
}
