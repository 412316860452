import { Currency } from 'src/app/core/bean/currency';
import { Region } from 'src/app/core/bean/region';
import { Segmentation } from 'src/app/core/bean/segmentation';
import { VendorCategory } from 'src/app/core/bean/vendor-category';
import { VendorType } from 'src/app/core/bean/vendor-type';

export class AppPopupChooseVendorOptionResponse {
  vendorCategoryList: VendorCategory[] = [];
  segmentationList: Segmentation[] = [];
  vendorTypeList: VendorType[] = [];
  regionList: Region[] = [];
  currencyList: Currency[] = [];
  isModuleGroupVmActive: boolean;
}
