import { Component, ViewEncapsulation } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { WebContent } from '../../../core/bean/web-content';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { Validators } from '../../../core/validators';
@Component({
  templateUrl: './refutation-toc-edit-add.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RefutationTocEditAddComponent extends BaseModuleComponent {
  refutationTocList: WebContent[] = [];
  constructor(translateService: TranslateService) {
    super('refutation-toc', translateService);
  }

  public onInit(): void {
    this.todo = this.global.routerParams.get('todo');
    this.formGroup = this.formBuilder.group({
      refutationTocList: this.formBuilder.array([])
    });
    this.httpClientService
      .get<WebContent[]>('/refutation-toc/add-edit')
      .subscribe(refutationTocList => {
        this.buildForm(refutationTocList);
        this.setStateReady();
      });
  }

  public buildForm(refutationTocList: WebContent[]): void {
    const control = this.formGroup.controls.refutationTocList as FormArray;
    refutationTocList.forEach(refutationTOC => {
      control.push(
        this.formBuilder.group({
          id: refutationTOC.id,
          code: refutationTOC.code,
          key: refutationTOC.key,
          description: refutationTOC.description,
          lang: refutationTOC.lang,
          value: [refutationTOC.value, Validators.required()]
        })
      );
    });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const refutationTocList = (
              this.formGroup.controls.refutationTocList as FormArray
            ).value as Array<WebContent>;
            this.httpClientService
              .post<Response<WebContent[]>>(
                '/refutation-toc/update',
                refutationTocList
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate([
                    '/pages/procurement-content-management/'
                  ]);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/procurement-content-management/']);
  }
}
