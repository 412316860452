<div class="sidebar-separator"></div>
<div class="sidebar-content">
  <nav class="sidebar-navigation ps ps--active-y" perfectScrollbar>
    <ul class="sidebar-menu">
      <li
        class="menu-item"
        *ngFor="let menu of menuItems"
        [ngClass]="[
          menu.isActive ? 'active' : '',
          menu.isExpanded ? 'expanded' : ''
        ]"
        (click)="doClickMenuItem($event, menu)"
        [attr.id]="'menuItem' + menu.id"
      >
        <a
          class="menu-link"
          (mouseenter)="onMouseEnterOnMenu($event, menuTooltip)"
          (mouseleave)="onMouseLeaveFromMenu($event, menuTooltip)"
        >
          <span class="menu-icon">
            <em [ngClass]="menu.icon"></em>
          </span>
          <span
            class="menu-text"
            [ngClass]="menu.childrens.length === 0 ? 'with-padding' : ''"
            maxLine
          >
            {{ 'sidebar.' + menu.translateKey | translate }}
          </span>
          <span class="menu-drop-down" *ngIf="menu.childrens.length > 0">
            <em
              class="fas fa-chevron-down"
              [@rotate]="!menu.isExpanded ? 'default' : 'rotated'"
            ></em>
          </span>
          <span class="menu-tooltip" #menuTooltip>
            {{ 'sidebar.' + menu.translateKey | translate }}
          </span>
        </a>
        <ng-container *ngIf="menu.childrens.length > 0">
          <ng-container
            *ngTemplateOutlet="
              subMenuTemplate;
              context: { $implicit: menu.childrens, parent: menu }
            "
          ></ng-container>
        </ng-container>
      </li>
    </ul>
  </nav>
</div>

<ng-template #subMenuTemplate let-menuList let-parent="parent">
  <ul
    class="sub-menu"
    [@expandedCollapsed]="!parent.isExpanded ? 'collapsed' : 'expanded'"
  >
    <li class="sub-menu-item title">
      <span class="menu-text">
        {{ 'sidebar.' + parent.translateKey | translate }}
      </span>
    </li>
    <li
      class="sub-menu-item"
      *ngFor="let menu of menuList"
      [ngClass]="[
        menu.childrens.length > 0 ? 'has-child' : '',
        menu.isExpanded ? 'expanded' : '',
        menu.isActive ? 'active' : ''
      ]"
      (click)="doClickMenuItem($event, menu)"
      [attr.id]="'menuItem' + menu.id"
    >
      <a
        class="menu-link"
        (mouseenter)="onMouseEnterOnMenu($event, menuTooltip)"
        (mouseleave)="onMouseLeaveFromMenu($event, menuTooltip)"
      >
        <span class="menu-icon">
          <em [ngClass]="menu.icon"></em>
        </span>
        <span
          class="menu-text"
          [ngClass]="menu.childrens.length === 0 ? 'with-padding' : ''"
          maxLine
        >
          {{ 'sidebar.' + menu.translateKey | translate }}
        </span>
        <span class="menu-drop-down" *ngIf="menu.childrens.length > 0">
          <em
            class="fas fa-chevron-down"
            [@rotate]="!menu.isExpanded ? 'default' : 'rotated'"
          ></em>
        </span>
        <span class="menu-tooltip" #menuTooltip>
          {{ 'sidebar.' + menu.translateKey | translate }}
        </span>
      </a>
      <ng-container *ngIf="menu.childrens.length > 0">
        <ng-container
          *ngTemplateOutlet="
            subMenuTemplate;
            context: { $implicit: menu.childrens, parent: menu }
          "
        ></ng-container>
      </ng-container>
    </li>
  </ul>
</ng-template>
