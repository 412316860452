import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { RfqStatus } from '../bean/rfq-status';
import { AgreementType } from '../bean/agreement-type';

export class RfqEntity extends BaseWorkflowObjectEntity {
  rfqStatus: RfqStatus = new RfqStatus();
  agreementType: AgreementType = new AgreementType();
  title: string;
  startDate: Date = new Date();
  endDate: Date = new Date();
  note: string;
  amount: number;
  goodsAmount: number;
  serviceAmount: number;
}
