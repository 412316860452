import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../core/shared/shared.module';
import { RabbitMQComponent } from './rabbit-mq.component';

const routes = [{ path: '', component: RabbitMQComponent, data: { breadcrumb: '' } }];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [RabbitMQComponent]
})
export class RabbitMQModule {}
