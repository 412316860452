import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Contract } from 'src/app/core/bean/contract';
import { ContractStatus } from 'src/app/core/bean/contract-status';
import { ContractTermination } from 'src/app/core/bean/contract-termination';
import { Procurement } from 'src/app/core/bean/procurement';
import { Vendor } from 'src/app/core/bean/vendor';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { AppPopupContractTerminationChooseContractComponent } from './app-popup-contract-termination-choose-contract.component';
import { ContractTerminationOptionListResponse } from './contract-termination-option-list-response';
@Component({
  templateUrl: './contract-termination.component.html'
})
export class ContractTerminationComponent
  extends BaseModuleComponent
  implements OnInit {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<ContractTermination>;
  public isLoading: boolean;
  public filter: string;

  public contractNumberOptionList: OptionListModel<Contract> = new OptionListModel(
    true,
    'number'
  );
  public vendorOptionList: OptionListModel<Vendor> = new OptionListModel(true);
  public contractStatusOptionList: OptionListModel<ContractStatus> = new OptionListModel(
    true
  );
  public procurementOptionList: OptionListModel<Procurement> = new OptionListModel(
    true
  );

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('contract-termination', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.setOptionList();
    this.buildFormGroup();
    this.buildTableResponse();
    this.doSetCustomData();
    this.setStateReady();
    localStorage.removeItem('temp');
    localStorage.removeItem('todo');
  }

  public setDataFromRouterParams(): void {
    this.filter = this.global.routerParams.get('filter');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      fromDate: [null],
      untilDate: [null],
      contractList: [null],
      vendorList: [null],
      procurementList: [null],
      contractStatusList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<ContractTerminationOptionListResponse>(
        '/contract-termination/get-option-list'
      )
      .subscribe(
        (
          contractTerminationOptionListResponse: ContractTerminationOptionListResponse
        ) => {
          this.contractNumberOptionList.setRequestValues(
            contractTerminationOptionListResponse.contractList
          );
          this.vendorOptionList.setRequestValues(
            contractTerminationOptionListResponse.vendorList
          );
          this.procurementOptionList.setRequestValues(
            contractTerminationOptionListResponse.procurementList
          );
          this.contractStatusOptionList.setRequestValues(
            contractTerminationOptionListResponse.contractStatusList
          );
        }
      );
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'contract.requestNumber',
        header: 'table.column.contractNumber',
        customClass: 'text-center'
      },
      {
        field: 'contract.startDate',
        header: 'table.column.startDateContract',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'contract.endDate',
        header: 'table.column.endDateContract',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'terminationDate',
        header: 'table.column.terminationDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'contract.title',
        header: 'table.column.title'
      },
      {
        field: 'contract.vendor.name',
        header: 'table.column.vendor',
        customClass: 'white-space-normal'
      },
      {
        field: 'contract.contractType.name',
        header: 'table.column.contractType',
        customClass: 'text-center'
      },
      {
        field: 'terminationStatus.name',
        header: 'table.column.contractStatus',
        customClass: 'text-center'
      }
    ]);
  }

  public doSetCustomData(): void {
    if (this.filter) {
      const customFilter = {
        contractStatusCode: this.filter
      };
      this.global.routerParams.clear();
      this.tableResponse.setCustomData(customFilter);
      this.tableResponse.reload();
    }
  }

  public doApply(): void {
    this.global.routerParams.clear();
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }

  public doAdd(): void {
    this.appPopupService
      .open(AppPopupContractTerminationChooseContractComponent, null, {
        size: 'xl'
      })
      .subscribe(contract => {
        this.log.debug(contract);
        this.global.routerParams.clear();
        this.global.routerParams.set('todo', 'add');
        this.global.routerParams.set('contractId', contract.id);
        this.global.routerParams.set('contract', contract);
        this.global.routerParams.set(
          'urlBackOutside',
          '/pages/contract-termination'
        );
        this.router.navigate(['/pages/contract-termination/add']);
      });
  }

  public doEdit(contractTermination: ContractTermination): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('contractTerminationId', contractTermination.id);
    this.global.routerParams.set('contractId', contractTermination.contract.id);
    this.global.routerParams.set('contract', contractTermination.contract);
    this.router.navigate(['/pages/contract-termination/edit']);
  }

  public doDetail(contractTermination: ContractTermination): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('contractTerminationId', contractTermination.id);
    this.global.routerParams.set('contractId', contractTermination.contract.id);
    this.global.routerParams.set('contract', contractTermination.contract);
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/contract-termination'
    );
    this.router.navigate(['/pages/contract-termination/detail']);
  }
}
