import { Pipe, PipeTransform } from '@angular/core';
import { TableRecord } from '../model/table-record';
import { TableState } from '../model/table-state';
@Pipe({
  name: 'resolveIsShowCheckBox'
})
export class ResolveIsShoWCheckBoxPipe implements PipeTransform {
  transform(state: TableState, tableRecord: TableRecord): boolean {
    if (state.model.options && state.model.options.checkBoxFn) {
      return !state.model.options.checkBoxFn(tableRecord.record);
    }
    return false;
  }
}
