<app-layout
  [isLoading]="formLoading"
  header="workload.detail.layout.header.workloadAssignment"
>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form [formGroup]="formGroup">
        <app-card
          *ngIf="!formLoading"
          header="workload.detail.details.title"
          tips="workload.detail.details.tips"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'workload.detail.form.stage' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              {{ module.name }}
            </div>
          </div>
          <div
            class="form-group row text-sm-left"
            *ngIf="
              module.delegation ===
              global.appConstant.core.DELEGATION_AUTO_DELEGATION
            "
          >
            <label class="col-sm-3">
              {{ 'workload.detail.form.workloadBalancingType' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                *ngIf="!formLoading"
                size="MD"
                formControlName="workloadBalancingType"
                placeholder="{{
                  'workload.detail.form.placeholder.workloadType' | translate
                }}"
                [optionList]="workloadTypeOptionList"
                type="COMBOBOX"
              ></app-dropdown-select>
            </div>
          </div>
          <br />
          <div class="d-flex justify-content-between align-items-center mb-2">
            <h3 class="mb-0">{{
              'workload.detail.form.workloadLevelDefinition' | translate
            }}</h3>
            <app-button (onClick)="doChangeDefinition()">{{
              'workload.detail.button.changeDefinition' | translate
            }}</app-button>
          </div>
          <h3 class="separator mb-3"></h3>
          <app-fullfilled></app-fullfilled>
          <app-card
            *ngIf="workloadThresholdObject"
            [header]="getTranslateKey(workloadThresholdObject)"
          >
            <app-table-xx
              *ngIf="
                workloadThresholdObject.type ===
                global.appConstant.core.WOKRLOAD_THRESHOLD_OBJECT_TYPE_CURRENCY
              "
              [model]="tableResponseLevel"
              (onAfterRowCreated)="onAfterRowCreated($event)"
            >
            </app-table-xx>
            <app-table-xx
              *ngIf="
                workloadThresholdObject.type ===
                global.appConstant.core.WOKRLOAD_THRESHOLD_OBJECT_TYPE_QUANTITY
              "
              [model]="tableResponseLevel"
              (onAfterRowCreated)="onAfterRowCreated($event)"
            >
            </app-table-xx>
          </app-card>
          <br />
          <h3 class="separator">{{
            'workload.detail.form.rulesCondition' | translate
          }}</h3>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'workload.detail.form.activateRulesCondition' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-toggle-switch
                formControlName="isActivateCondition"
                (onChange)="onChangeActivateCondition($event)"
              ></app-toggle-switch>
            </div>
          </div>
          <div class="form-group row text-sm-left" *ngIf="isActivateCondition">
            <label class="col-sm-3"> </label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                formControlName="conditionList"
                [optionList]="conditionOptionList"
                [isMultiple]="true"
                container="body"
                type="AUTOCOMPLETE"
                size="XL"
              ></app-dropdown-select>
            </div>
          </div>
          <div class="text-right" *ngIf="isActivateCondition">
            <app-button (onClick)="doGenerateCondition()">{{
              'workload.detail.button.generateCondition' | translate
            }}</app-button>
          </div>
        </app-card>
        <div
          *ngIf="
            !formLoading && workloadGenerateModelList && isActivateCondition
          "
        >
          <app-card
            *ngFor="let model of workloadGenerateModelList; let i = index"
            [header]="model.conditionName"
            [tips]="'Condition ' + (i + 1)"
          >
            <div
              class="form-group row text-sm-left"
              *ngFor="
                let detailModel of model.workloadGenerateDetailModelList;
                let j = index
              "
            >
              <label class="col-sm-3">
                {{ detailModel.condition.name }}
              </label>
              <div class="col-sm-9 text-left">
                {{ detailModel.valueName }}
              </div>
            </div>
            <br />
            <div class="d-flex justify-content-between align-items-center mb-2">
              <h3 class="mb-0">{{
                'workload.detail.form.assignedUser' | translate
              }}</h3>
              <app-button (onClick)="doAddUserCondition(i)"
                ><em class="pir pi-plus-circle"></em>&nbsp;{{
                  'workload.detail.button.addUser' | translate
                }}</app-button
              >
            </div>
            <h3 class="separator mb-3"></h3>
            <app-fullfilled></app-fullfilled>
            <app-table-xx [model]="tableResponseUserConditionList[i]">
              <ng-template #actionButtons let-rowData>
                <app-button
                  size="TN"
                  (onClick)="doDeleteUserCondition(rowData, i)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="pir pi-trash-alt"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>

                <app-button
                  size="TN"
                  (onClick)="doMoveUserCondition(rowData, i)"
                  title="{{
                    'workload.tooltip.moveToAnotherCondition' | translate
                  }}"
                >
                  <em class="pir pi-user-plus"></em>
                  {{ 'workload.tooltip.moveToAnotherCondition' | translate }}
                </app-button>
              </ng-template>
            </app-table-xx>
          </app-card>
        </div>
        <app-card
          *ngIf="!formLoading && !isActivateCondition"
          header="workload.detail.assignUser.title"
          tips="workload.detail.assignUser.tips"
        >
          <ng-template #headerRight>
            <app-button (onClick)="doAddUser()">{{
              'workload.detail.button.addUser' | translate
            }}</app-button>
          </ng-template>
          <app-fullfilled></app-fullfilled>
          <app-table-xx
            [model]="tableResponseUser"
            [isMultipleSelect]="true"
            [isShowCheckBox]="true"
          >
            <ng-template #actionButtons let-rowData>
              <app-button
                size="TN"
                (onClick)="doDeleteUser(rowData)"
                title="{{ 'app.tooltip.delete' | translate }}"
              >
                <em class="pir pi-trash-alt"></em>
                {{ 'app.button.delete' | translate }}
              </app-button>
            </ng-template>
          </app-table-xx>
        </app-card>
      </form>

      <div class="floating-button mt-5">
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button (onClick)="doSave()">{{
                'app.button.save' | translate
              }}</app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips #appTips></app-tips>
  </ng-template>
</app-layout>
