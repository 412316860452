import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { OfficialReport } from 'src/app/core/bean/official-report';
import { AppPopupImportService } from 'src/app/core/components/app-popup/app-popup-import/app-popup-import.service';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from 'src/app/core/components/app-table-x/model/table-response-model';

@Component({
  templateUrl: './official-report.component.html'
})
export class OfficialReportComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableXComponent)
  public table: AppTableXComponent;
  public tableResponse: TableResponseModel<OfficialReport>;
  constructor(
    translateService: TranslateService,
    public appPopupImportService: AppPopupImportService
  ) {
    super('official-report', translateService);
  }
  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        plugins: 'hyperlink',
        customClass: 'white-space-normal'
      },
      {
        field: 'name',
        header: 'table.column.name'
      },
      {
        field: 'module.name',
        header: 'table.column.name'
      },
      {
        field: 'description',
        header: 'table.column.description'
      }
    ]);
  }

  public doEdit(data: any): void {
    const officialReport: OfficialReport = data;
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('officialReportId', officialReport.id);
    this.router.navigate(['/pages/official-report/edit']);
  }
}
