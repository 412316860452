<app-popup header="pr.popup.addBudget">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3">{{ 'pr.form.organization' | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box-tree
          formControlName="organizationName"
          stringUrl="/organization"
          header="Organization"
          size="LG"
        >
        </app-combo-box-tree>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3">{{ 'pr.form.workProgram' | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          [optionList]="workProgramOptionList"
          formControlName="workProgram"
          size="LG"
        ></app-combo-box>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3">{{ 'pr.form.budgetType' | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          [optionList]="budgetTypeOptionList"
          formControlName="budgetType"
          size="LG"
        ></app-combo-box>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3">{{ 'pr.form.costCenter' | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          [optionList]="costCenterOptionList"
          formControlName="costCenter"
          size="LG"
        ></app-combo-box>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3">{{ 'pr.form.coa' | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          [optionList]="coaOptionList"
          formControlName="coa"
          size="LG"
        ></app-combo-box>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3">{{ 'pr.form.activityPeriod' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{
          formGroup.value.workProgram
            ? formGroup.value.workProgram.activityPeriod
            : '-'
        }}
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3">{{ 'pr.form.budgetValue' | translate }}</label>
      <div class="col-sm-9 text-left">
        <div class="form-row">
          <div class="form-group row col-5">
            <div class="col text-left">
              <app-text-field
                size="XL"
                maxLength="16"
                formControlName="totalBudget"
                type="currency"
              >
              </app-text-field>
            </div>
          </div>
          <div class="form-group row col-3">
            <div class="col text-left">
              <app-combo-box
                size="LG"
                formControlName="currency"
                [optionList]="currencyOptionList"
              ></app-combo-box>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
        'app.button.close' | translate
      }}</app-button>

      <app-button color="PRIMARY" (onClick)="doSave()">{{
        'app.button.save' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
