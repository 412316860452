import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReloadComponent } from './reload.component';
const routes = [{ path: '', component: ReloadComponent }];
@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  declarations: [ReloadComponent]
})
export class ReloadModule {}
