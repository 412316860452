import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetWelcomeComponent } from './app-widget-welcome.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule],
  declarations: [AppWidgetWelcomeComponent],
  entryComponents: [AppWidgetWelcomeComponent],
  exports: [AppWidgetWelcomeComponent]
})
export class AppWidgetWelcomeModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-welcome', AppWidgetWelcomeComponent);
  }
}
