import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { ContractPreparation } from 'src/app/core/bean/contract-preparation';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';

@Component({
  templateUrl: './app-popup-contract-request-choose-procurement-for-contract.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppPopupContractRequestChooseProcurementForContractComponent extends BaseComponentComponent {
  @Input() contractPreparation: ContractPreparation;
  @Output() public onChange: EventEmitter<ContractPreparation> = new EventEmitter();

  public tableResponseContractPreparation: TableResponseModel<ContractPreparation>;

  constructor(
    public activeModal: NgbActiveModal,
    public translateService: TranslateService
  ) {
    super('contract-request');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
        contractPreparation: null
    });
  }

  public setFormGroup(): void {
    this.buildTableResponse();
    this.setContractPreparation();
    this.setStateReady();
  }

  public buildTableResponse(): void {
      !this.tableResponseContractPreparation
        ? (this.tableResponseContractPreparation = new TableResponseModel(this.moduleCode, [
            {
              field: 'code',
              header: 'table.column.code',
              plugins: [
                { name: 'hyperlink', onClick: this.doShowContractPreparationDetail.bind(this) },
                {
                  name: 'icon',
                  className: 'pir pi-external-link',
                  onClick: this.doShowContractPreparationDetail.bind(this)
                }
              ]
            },
            {
              field: 'title',
              header: 'table.column.title'
            },
            {
              field: 'orderDate',
              header: 'table.column.date',
              plugins: 'date'
            },
            {
              field: 'organization.name',
              header: 'table.column.organization'
            },
            {
              field: 'vendor.name',
              header: 'table.column.vendorName'
            }
          ]))
        : '';
  }

  public setContractPreparation(): void {
    if (this.contractPreparation) {
        const contractPreparationList = [];
        contractPreparationList.push(this.contractPreparation);
        this.tableResponseContractPreparation.setSelectedRowList(contractPreparationList);
    }
  }

  public doShowContractPreparationDetail(cp: ContractPreparation) {
    this.global.routerParams.clear();
    this.global.routerParams.set('objectId', cp.objectId);
    this.global.routerParams.set('todo', 'view');
    localStorage.setItem('objectId', cp.objectId.toString());
    localStorage.setItem('todo', 'view');
    localStorage.setItem('urlBackOutside', 'close');
    localStorage.setItem('isShowBadgeStatus', false.toString());
    if(cp.source === "ORDER"){
      this.global.routerParams.set('orderId', cp.objectId);
      localStorage.setItem('orderId', cp.objectId.toString());
    }
    else if (cp.source === "RFP"){
      this.global.routerParams.set('rfpId', cp.objectId);
      localStorage.setItem('rfpId', cp.objectId.toString());
    }
    else if (cp.source === "RFQ"){
      this.global.routerParams.set('rfqId', cp.objectId);
      localStorage.setItem('rfqId', cp.objectId.toString());
    }
    else if (cp.source === "PROCUREMENT"){
      this.global.routerParams.set('procurementId', cp.objectId);
      localStorage.setItem('procurementId', cp.objectId.toString());
    }
    if(cp.module.code === "ORDER" || cp.module.code === "INITIATION"){
      window.open(
        '/pages/' + cp.module.code.toLowerCase() + '/edit'
      );
    }else{
      window.open(
        '/pages/' + cp.module.code.toLowerCase() + '/detail'
      );
    }
    // this.global.routerParams.clear();
    // this.global.routerParams.set('urlBackOutside', '/pages/contract-request');
    // if(cp.source === "ORDER"){
    //   this.global.routerParams.set('orderId', cp.objectId);
    //   this.global.routerParams.set('todo', 'view');
    //   this.router.navigate(['/pages/order/edit']);
    // }
    // else if (cp.source === "RFP"){
    //   this.global.routerParams.set('rfpId', cp.objectId);
    //   this.global.routerParams.set('todo', 'view');
    //   this.router.navigate(['/pages/rfp/detail']);
    // }
    // else if (cp.source === "RFQ"){
    //   this.global.routerParams.set('rfqId', cp.objectId);
    //   this.global.routerParams.set('todo', 'view');
    //   this.router.navigate(['/pages/rfq/detail']);
    // }
    // else if (cp.source === "PROCUREMENT"){
    //   this.global.routerParams.set('procurementId', cp.objectId);
    //   this.global.routerParams.set('todo', 'view');
    //   this.router.navigate(['/pages/initiation/edit']);
    // }
  }


  public doSave(): void {
    if (
        this.tableResponseContractPreparation.selectedRow &&
        this.tableResponseContractPreparation.selectedRow.getSelectedRecordList() &&
        this.tableResponseContractPreparation.selectedRow.getSelectedRecordList().length > 0
    ) {
        this.contractPreparation = this.tableResponseContractPreparation.selectedRow.getSelectedRecordList()[0];
        this.onChange.emit(
            this.contractPreparation
          );
    } else {
      this.global.alertService.showError(
        'rfq.validation.chooseItem',
        '.popup-choose-procurement-for-contract'
      );
    }
  }
}
