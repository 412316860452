import { Payment } from 'src/app/core/bean/payment';
import { PaymentProof } from 'src/app/core/bean/payment-proof';
import { FileObject } from 'src/app/core/components/upload';

export class PaymentMonitoringRequest {
    paymentProof: PaymentProof = new PaymentProof();
    paymentTaxProof: PaymentProof = new PaymentProof();
    payment: Payment = new Payment();
    fileObjectList: FileObject[] = [];
}
