<app-popup
  [header]="'tab-profile-experience.form.popup.header.experience.' + todo"
  [isLoading]="formLoading"
>
  <form [formGroup]="formGroup" class="app-popup-expert" *ngIf="!formLoading">
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-experience.form.popup.projectName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          [autofocus]="true"
          size="LG"
          formControlName="projectName"
          type="alphanumeric"
          maxLength="128"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorExperienceHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorExperience.projectName"
            [newValue]="vendorExperienceHistoryEdit.projectName"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-experience.form.popup.businessPartnerName' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        <app-text-field
          size="LG"
          formControlName="businessPartnerName"
          maxLength="255"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorExperienceHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorExperience.businessPartnerName"
            [newValue]="vendorExperienceHistoryEdit.businessPartnerName"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-experience.form.popup.country' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-dropdown-select
          size="LG"
          [optionList]="countryRegionOptionList"
          formControlName="countryRegion"
          (onChange)="doChangeCountryRegion()"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorExperienceHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorExperience.countryRegion
                ? vendorExperience.countryRegion.name
                : null
            "
            [newValue]="
              vendorExperienceHistoryEdit.countryRegion
                ? vendorExperienceHistoryEdit.countryRegion.name
                : null
            "
          ></app-flag-new>
        </app-dropdown-select>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-experience.form.popup.city' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-auto-complete
          size="LG"
          formControlName="cityRegion"
          stringUrl="/company-profile/auto-complete-city-region-list"
          [params]="countryRegionId"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorExperienceHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorExperience.cityRegion
                ? vendorExperience.cityRegion.name
                : null
            "
            [newValue]="
              vendorExperienceHistoryEdit.cityRegion
                ? vendorExperienceHistoryEdit.cityRegion.name
                : null
            "
          ></app-flag-new>
        </app-auto-complete>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-experience.form.popup.address' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        <app-text-area size="LG" formControlName="address" maxLength="512">
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorExperienceHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorExperience.address"
            [newValue]="vendorExperienceHistoryEdit.address"
            [ngStyle]="{ 'padding-left': '3px' }"
          ></app-flag-new>
        </app-text-area>
      </div>
    </div>

    <div class="form-group row text-sm-right" *ngIf="!formLoading">
      <label class="col-sm-3 control-label">{{
        'tab-profile-experience.form.popup.coordinateLocation' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        <app-text-map size="LG" formControlName="coordinateLocation">
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorExperienceHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorExperience.coordinateLocation"
            [newValue]="vendorExperienceHistoryEdit.coordinateLocation"
          ></app-flag-new>
        </app-text-map>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-experience.form.popup.projectOwner' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="projectOwner"
          maxLength="64"
          type="alphanumeric"
          include="-.\',"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorExperienceHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorExperience.projectOwner"
            [newValue]="vendorExperienceHistoryEdit.projectOwner"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-experience.form.popup.projectType' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field size="LG" formControlName="projectType" maxLength="32">
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorExperienceHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorExperience.projectType"
            [newValue]="vendorExperienceHistoryEdit.projectType"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-experience.form.popup.segmentation' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        <app-text-tree
          size="LG"
          formControlName="segmentationList"
          stringUrl="/segmentation"
          [isMultiple]="true"
          header="{{
            'tab-profile-experience.header.segmentation' | translate
          }}"
          [onlyLastChild]="false"
          isLazy="true"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorExperienceHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorExperience.segmentation"
            [newValue]="vendorExperienceHistoryEdit.segmentationNamesHistory"
          ></app-flag-new>
        </app-text-tree>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-experience.form.popup.status' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-dropdown-select
          [optionList]="vendorExperienceStatusOptionList"
          formControlName="vendorExperienceStatus"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorExperienceHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorExperience.vendorExperienceStatus
                ? vendorExperience.vendorExperienceStatus.name
                : null
            "
            [newValue]="
              vendorExperienceHistoryEdit.vendorExperienceStatus
                ? vendorExperienceHistoryEdit.vendorExperienceStatus.name
                : null
            "
          ></app-flag-new>
        </app-dropdown-select>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-experience.form.popup.contractNumber' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="contractNumber"
          maxLength="32"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorExperienceHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorExperience.contractNumber"
            [newValue]="vendorExperienceHistoryEdit.contractNumber"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-experience.form.popup.contractDate' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-date-picker
          formControlName="contractDate"
          minDate="minDate"
          maxDate="todayDate"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorExperienceHistoryEdit.vendorHistory !== null
            "
            [oldValue]="date"
            [newValue]="dateHistory"
          ></app-flag-new>
        </app-date-picker>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-experience.form.popup.contractValue' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-currency
          size="LG"
          formControlName="currencyValue"
          [optionList]="currencyOptionList"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorExperienceHistoryEdit.vendorHistory !== null
            "
            [oldValue]="valueCurrencyActual"
            [newValue]="valueCurrency"
          ></app-flag-new>
        </app-currency>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-experience.form.popup.proofOfContract' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-upload
          size="LG"
          formControlName="file"
          [fileUploader]="fileUploader"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorExperienceHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorExperience.file ? vendorExperience.file.id : null"
            [newValue]="
              vendorExperienceHistoryEdit.file
                ? vendorExperienceHistoryEdit.file.id
                : null
            "
            [hoverValue]="
              vendorExperience.file ? vendorExperience.file.fileName : null
            "
          ></app-flag-new>
        </app-upload>
      </div>
    </div>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
        <app-button *ngIf="!formGroup.isView" (onClick)="doSave()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
