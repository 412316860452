import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';
import { Validators } from '../../../core/validators';
import { UserSendVerificationEmailRequest } from '../user-send-verification-email-request';
@Component({
  templateUrl: './app-popup-change-email-user.component.html',
  styleUrls: ['./app-popup-change-email-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupChangeEmailUserComponent extends BaseComponentComponent {
  @Input() userSendVerificationEmailRequest: UserSendVerificationEmailRequest;
  @Output() onChange: EventEmitter<UserSendVerificationEmailRequest> =
    new EventEmitter();
  public hasSent: boolean;
  constructor(public translateService: TranslateService) {
    super('app-popup-change-email-user');
  }

  public onInit(): void {
    this.buildFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group(
      {
        email: [
          null,
          // tslint:disable-next-line: max-line-length
          Validators.compose([
            Validators.required(),
            Validators.email(),
            Validators.maxLength(64),
            Validators.exists(
              [this.userSendVerificationEmailRequest.user.email],
              'account-setting.alert.emailExist'
            )
          ])
        ],
        confirmEmail: [
          null,
          Validators.compose([Validators.required(), Validators.matchEmail()])
        ]
      },
      { updateOn: 'blur' }
    );
  }

  public doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .confirm(this.translateService.instant('user.confirmation.changeEmail'))
        .subscribe((isChange: any) => {
          if (isChange) {
            this.userSendVerificationEmailRequest.newEmail =
              this.formGroup.get('email').value;
            this.onChange.emit(this.userSendVerificationEmailRequest);
          }
        });
    }
  }

  public doChangeEmail(): void {
    this.hasSent = false;
    this.formGroup.reset();
    this.formGroup.enable();
  }
}
