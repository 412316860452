import { BaseEntity } from '../base/base-entity';
import { AddressBook } from '../bean/address-book';
import { Dr } from '../bean/dr';

export class DrShippingEntity extends BaseEntity {
  isFromDr: boolean; // flag menandakan shipping baru ditambah di dr dan belum di kirim ke alamt tsb

  address: AddressBook;
  dr: Dr;
}
