import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { BudgetAllocation } from '../../core/bean/budget-allocation';
import { BudgetHistory } from '../../core/bean/budget-history';
import { BudgetHistoryView } from '../../core/bean/budget-history-view';
import { BudgetType } from '../../core/bean/budget-type';
import { Coa } from '../../core/bean/coa';
import { Currency } from '../../core/bean/currency';
import { Organization } from '../../core/bean/organization';
import { WorkPlanItem } from '../../core/bean/work-plan-item';
import { AppChooseComponent } from '../../core/components/app-choose/app-choose.component';
import { AppPopupCoaBjbService } from '../../core/components/app-popup/app-popup-coa-bjb/app-popup-coa-bjb.service';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { AppPopupWorkPlanItemInfoService } from '../../core/components/app-popup/app-popup-work-plan-item-info/app-popup-work-plan-item-info.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { ReportService } from '../../core/services/report.service';
import { Validators } from '../../core/validators';
import { BudgetAllocationRequest } from './request/budget-allocation-request';
import { BudgetAllocationResponse } from './response/budget-allocation-response';

@Component({
  templateUrl: './budget-allocation-bjb-edit-add.component.html'
})
export class BudgetAllocationBjbEditAddComponent extends BaseModuleComponent {
  @ViewChild(AppChooseComponent) public appChoose: AppChooseComponent;

  public today: Date = new Date();
  public tableResponse: TableResponseModel<BudgetHistoryView>;
  public budgetAllocation: BudgetAllocation = new BudgetAllocation();
  public budgetAllocationId: number;
  public isLoading: boolean;
  public activityPeriod: number;
  public backUrl: string;
  public budgetAllocationResponse: BudgetAllocationResponse =
    new BudgetAllocationResponse();
  public budgetAllocationRequest: BudgetAllocationRequest =
    new BudgetAllocationRequest();
  public budgetTypeOptionList: OptionListModel<BudgetType> =
    new OptionListModel(false);
  public defaultCurrency: string;
  public autoCompleteValue: any;
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );

  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService,
    public appPopupWorkPlanItemInfoService: AppPopupWorkPlanItemInfoService,
    public reportService: ReportService,
    public appPopupCoaBjbService: AppPopupCoaBjbService
  ) {
    super('budget-allocation-bjb', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.getAndSetBudgetAllocationToFormGroup();
    this.buildTableResponse();
  }

  public setOptionModel(): void {
    this.budgetTypeOptionList.setRequestValues(
      this.budgetAllocationResponse.budgetTypeList
    );
    this.currencyOptionList.setRequestValues(
      this.budgetAllocationResponse.currencyList
    );
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.budgetAllocationId = this.global.routerParams.get('budgetId');
    this.backUrl = this.global.routerParams.get('backUrl');
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.activityPeriod = new Date().getFullYear();
    this.formGroup = this.formBuilder.group({
      id: [null],
      organizationName: [null, Validators.required()],
      workProgram: [null, Validators.required()],
      budgetType: [null, Validators.required()],
      costCenter: [null, Validators.required()],
      coa: [null, Validators.required()],
      coaName: [null, Validators.required()],
      activityPeriod: [this.activityPeriod, Validators.required()],
      currency: [null, Validators.required()],
      availableBudget: [null],
      totalBudget: [null, Validators.required()],
      bookedBudget: [null],
      usedBudget: [null],
      paidOffBudget: [null]
    });
    if (this.todo === 'edit') {
      this.formGroup = this.formBuilder.group({
        id: [null],
        organizationName: [null, Validators.required()],
        workProgram: [null, Validators.required()],
        budgetType: [null, Validators.required()],
        costCenter: [null, Validators.required()],
        coa: [null, Validators.required()],
        coaName: [null, Validators.required()],
        activityPeriod: [this.activityPeriod, Validators.required()],
        availableBudget: [null],
        totalBudget: [null, Validators.required()],
        bookedBudget: [null],
        usedBudget: [null],
        paidOffBudget: [null],
        currency: [null, Validators.required()],
        note: [null, Validators.required()]
      });
    }
  }

  public getAndSetBudgetAllocationToFormGroup(): void {
    this.httpClientService
      .post<BudgetAllocationResponse>(
        '/budget-allocation-bjb/add-edit',
        new RouteRequestModel(this.todo, this.budgetAllocationId)
      )
      .subscribe(budgetAllocationResponse => {
        this.budgetAllocationResponse = budgetAllocationResponse;
        if (
          this.budgetAllocationResponse.budgetAllocation &&
          this.budgetAllocationResponse.budgetAllocation.id
        ) {
          this.defaultCurrency =
            this.budgetAllocationResponse.budgetAllocation.currency.code;
          this.formGroup.patchValue({
            id: this.budgetAllocationResponse.budgetAllocation.id,
            organizationName: this.budgetAllocationResponse.organizationList,
            workProgram:
              this.budgetAllocationResponse.budgetAllocation.workProgram,
            budgetType:
              this.budgetAllocationResponse.budgetAllocation.budgetType,
            costCenter:
              this.budgetAllocationResponse.budgetAllocation.costCenter,
            /* PRODUCT coa: this.budgetAllocationResponse.coaList, */
            coa: this.budgetAllocationResponse.budgetAllocation.coa,
            coaName: this.budgetAllocationResponse.budgetAllocation.coa.name,
            activityPeriod:
              this.budgetAllocationResponse.budgetAllocation.activityPeriod,
            currency: this.budgetAllocationResponse.budgetAllocation.currency,
            availableBudget: this.global.converterService.convertMoney(
              this.budgetAllocationResponse.budgetAllocation.availableBudget.toString()
            ),
            totalBudget: null,
            bookedBudget:
              this.budgetAllocationResponse.budgetAllocation.bookedBudget,
            usedBudget:
              this.budgetAllocationResponse.budgetAllocation.usedBudget,
            paidOffBudget:
              this.budgetAllocationResponse.budgetAllocation.paidOffBudget,
            note: null
          });
        } else {
          this.formGroup.patchValue({
            currency: this.budgetAllocationResponse.currencyList[0]
          });
          this.defaultCurrency =
            this.budgetAllocationResponse.currencyList[0].code;
        }

        this.setOptionModel();
        this.setStateReady();
        if (this.todo === 'edit') {
          this.formGroup.get('currency').disable();
        }
      });
    if (this.todo === 'edit') {
      this.formGroup.get('organizationName').setIsView(true);
    }
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'user.name',
        header: 'table.column.userName'
      },
      {
        field: 'updatedDate',
        header: 'table.column.modifyDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'valueFrom',
        header: 'table.column.valueFrom',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'valueTo',
        header: 'table.column.valueTo',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      { field: 'note', header: 'table.column.note' }
    ]);
  }

  public doChooseCoa(): void {
    this.appPopupCoaBjbService.open().subscribe((coa: Coa) => {
      this.formGroup.patchValue({
        coa,
        coaName: coa.name
      });
    });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      if (
        this.todo === 'edit' &&
        this.budgetAllocationResponse.budgetAllocation.availableBudget +
          +this.formGroup.value.totalBudget <
          0
      ) {
        this.global.alertService.showError(
          this.translateService.instant(
            'budget-allocation-bjb.alert.availableBudget'
          )
        );
      } else {
        this.global.modalService
          .saveConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.budgetAllocationRequest.budgetAllocation =
                this.budgetAllocationResponse.budgetAllocation;
              this.budgetAllocationRequest.budgetHistory =
                this.budgetAllocationResponse.budgetHistory;
              this.setStateProcessing();
              if (this.todo === 'edit') {
                this.formGroup.patchValue({
                  availableBudget: this.global.converterService.uConvertMoney(
                    this.formGroup.value.availableBudget
                  )
                });
              }
              this.budgetAllocation = this.global.copyFormAttributeToModel(
                this.budgetAllocation,
                this.formGroup
              );
              this.budgetAllocationRequest.budgetAllocation =
                this.budgetAllocation;
              this.budgetAllocationRequest.budgetAllocation.activityPeriod =
                this.formGroup.value.workProgram.activityPeriod;
              const organizationList: Organization[] =
                this.formGroup.get('organizationName').value;
              this.budgetAllocationRequest.budgetAllocation.organization =
                organizationList[organizationList.length - 1];
              /* PRODUCT const coaList: Coa[] = this.formGroup.get('coa').value;
              this.budgetAllocationRequest.budgetAllocation.coa =
                 coaList[coaList.length - 1]; PRODUCT */
              this.budgetAllocationRequest.budgetHistory = new BudgetHistory();
              this.budgetAllocationRequest.budgetHistory.note =
                this.formGroup.value.note;
              const url =
                this.todo === 'edit'
                  ? '/budget-allocation-bjb/update'
                  : '/budget-allocation-bjb/insert';
              this.httpClientService
                .post<Response<BudgetAllocation>>(
                  url,
                  this.budgetAllocationRequest
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.router.navigate([this.backUrl]);
                    this.global.alertService.showSuccessSavingOnNextRoute();
                  } else {
                    this.global.alertService.showError(response.statusText);
                  }
                  this.setStateReady();
                });
            }
          });
      }
    }
  }

  public doDetailItem(workPlanItem: WorkPlanItem): void {
    this.appPopupWorkPlanItemInfoService.open(workPlanItem.workPlan);
  }

  public autoCompleteValueChange(event: any): void {
    this.autoCompleteValue = event;
  }

  public doReport(budgetAllocation: BudgetAllocation): void {
    this.isLoading = true;
    this.reportService
      .print('/budget-allocation-bjb/print/report', budgetAllocation)
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  public doCancel(): void {
    this.router.navigate([this.backUrl]);
  }
}
