import { Component, HostListener, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'theme-promise-portal',
  templateUrl: './promise-portal-theme.component.html',
  styleUrls: ['./promise-portal-theme.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromisePortalThemeComponent {
  public isOffline: boolean;

  @HostListener('window:online')
  onWindowOnline(): void {
    this.isOffline = false;
  }

  @HostListener('window:offline')
  onWindowOffliine(): void {
    this.isOffline = true;
  }
}
