import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from './content.component';
const routes: Routes = [
  { path: '', component: ContentComponent, data: { breadcrumb: 'index' } },
  {
    path: 'disclaimer',
    loadChildren: () =>
      import('./disclaimer/disclaimer.module').then(m => m.DisclaimerModule),
    data: { breadcrumb: 'content.breadcrumb.disclaimer' }
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./contact/contact.module').then(m => m.ContactModule),
    data: { breadcrumb: 'content.breadcrumb.contactUs' }
  },
  {
    path: 'vendor-policy',
    loadChildren: () =>
      import('./vendor-policy/vendor-policy.module').then(
        m => m.VendorPolicyModule
      ),
    data: { breadcrumb: 'content.breadcrumb.vendorPolicy' }
  },
  {
    path: 'user-toc',
    loadChildren: () =>
      import('./user-toc/user-toc.module').then(m => m.UserTocModule),
    data: { breadcrumb: 'content.breadcrumb.userToc' }
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
    data: { breadcrumb: 'content.breadcrumb.faq' }
  },
  {
    path: 'home-slide',
    loadChildren: () =>
      import('./home-slide/home-slide.module').then(m => m.HomeSlideModule),
    data: { breadcrumb: 'content.breadcrumb.homeSlide' }
  },
  {
    path: 'logo',
    loadChildren: () => import('./logo/logo.module').then(m => m.LogoModule),
    data: { breadcrumb: 'content.breadcrumb.logo' }
  },
  {
    path: 'watermark-report',
    loadChildren: () =>
      import('./watermark-report/watermark-report.module').then(
        m => m.WatermarkReportModule
      ),
    data: { breadcrumb: 'content.breadcrumb.watermarkReport' }
  },
  {
    path: 'background-image',
    loadChildren: () =>
      import('./background-image/background-image.module').then(
        m => m.BackgroundImageModule
      ),
    data: { breadcrumb: 'content.breadcrumb.backgroundImage' }
  },
  {
    path: 'company-identity',
    loadChildren: () =>
      import('./company-identity/company-identity.module').then(
        m => m.CompanyIdentityModule
      ),
    data: { breadcrumb: 'content.breadcrumb.companyIdentity' }
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentRoutingModule {}
