import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { QualificationEditAddComponent } from './qualification-edit-add.component';
import { QualificationComponent } from './qualification.component';
const routes: Routes = [
  { path: '', component: QualificationComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: QualificationEditAddComponent,
    data: { breadcrumb: 'qualification.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: QualificationEditAddComponent,
    data: { breadcrumb: 'qualification.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [QualificationComponent, QualificationEditAddComponent]
})
export class QualificationModule {}
