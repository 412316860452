import { EventEmitter, Injector } from '@angular/core';
import { ServiceLocator } from '../../../services/service-locator';
import { PaginationModel } from '../../app-pagination/model/pagination-model';
import { TablePaginationModel } from '../../app-table/model/table-pagination-model';
import { TablePlugin } from '../interfaces/table-plugin';
import { TableEvent } from '../types/table-event';
import { CheckedRecord } from './checked-record';
import { TableRecord } from './table-record';
import { TableRequestModel } from './table-request-model';
import { TableResponseModel } from './table-response-model';
import { TableStore } from './table-store';
export class TableState {
  injector: Injector;
  model: TableResponseModel<any>;
  pagination: TablePaginationModel;
  sortOrder: 'ASC' | 'DESC' | '';
  stringUrl: string;
  isServerSide: boolean;
  tableRequest: TableRequestModel;
  plugins: Array<TablePlugin>;
  requestReload: EventEmitter<void> = new EventEmitter();
  isLoading: boolean;
  records: Array<TableRecord> = new Array();
  recordsChanges: EventEmitter<Array<any>> = new EventEmitter();
  checkedRecord: CheckedRecord = new CheckedRecord();
  isShowFilterGroup: boolean;
  isExpandedAll: boolean;
  event: EventEmitter<TableEvent> = new EventEmitter();
  store: TableStore = new TableStore();
  isView: boolean;
  isMultipleSelect: boolean;
  isShowPagination: boolean;
  isGroup: boolean;
  public setRecords(records: Array<any>): void {
    this.records = records;
    this.recordsChanges.emit(records);
  }

  public addRecords(records: Array<any>): void {
    this.records.push(...records);
  }

  public setStateReady(): void {
    this.isLoading = false;
  }

  public setStateLoading(): void {
    this.isLoading = true;
  }

  public static create(
    model: TableResponseModel<any>,
    stringUrl: string,
    isView: boolean,
    isMultipleSelect: boolean,
    isShowPagination: boolean
  ): TableState {
    const state = new TableState();
    state.model = model;
    state.pagination = new PaginationModel();
    state.pagination.setTotalRecords(model.getRecords().length);
    state.tableRequest = new TableRequestModel(model.columns);
    state.stringUrl = stringUrl;
    state.isServerSide = !!stringUrl;
    state.injector = ServiceLocator.injector;
    state.isView = isView;
    state.checkedRecord.checkedRecordList =
      model.checkedRecord.checkedRecordList;
    state.isMultipleSelect = isMultipleSelect;
    state.isShowPagination = isShowPagination;
    state.isGroup = !!(model.options && model.options.group);
    return state;
  }
}
