import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { take } from 'rxjs/operators';
import {} from 'src/app/pages/example/employee/dto/employee.dto';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Guarantee } from '../../bean/guarantee';
import { AppPopupGuaranteeService } from '../app-popup/app-popup-guarantee/app-popup-guarantee.service';
import { TablePluginData } from '../table/interface/table-plugin-data';
import { SelectedTableRecordModel } from '../table/model/selected-table-record-model';
import { TableResponseModel } from '../table/model/table-response-model';

@Component({
  selector: 'app-guarantee',
  templateUrl: './app-guarantee.component.html'
})
export class AppGuaranteeComponent extends BaseComponentComponent {
  @Input() public guaranteeList: Guarantee[] = [];
  @Input() public isView: boolean;
  @Input() public amount: number;
  @Input() public isDisabled = false;
  @Output() public onChange: EventEmitter<Guarantee[]> = new EventEmitter();
  public tableResponse: TableResponseModel<Guarantee>;

  constructor(public appPopupGuaranteeService: AppPopupGuaranteeService) {
    super('app-guarantee');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      guaranteeLists: this.formBuilder.array([])
    });
  }
  public get guaranteeLists(): FormArray {
    return this.formGroup.get('guaranteeLists') as FormArray;
  }

  public setFormGroup(): void {
    this.guaranteeList.forEach((guarantee: Guarantee) => {
      const formGroup = this.formBuilder.group({
        id: guarantee.id,
        guaranteeBondType: guarantee.guaranteeBondType,
        guarantorName: guarantee.guarantorName,
        percentageValue: guarantee.percentageValue,
        description: guarantee.description,
        submissionDate: guarantee.submissionDate,
        startDate: guarantee.startDate,
        endDate: guarantee.endDate,
        amount: guarantee.amount
      });
      this.guaranteeLists.push(formGroup);
      this.buildTableResponse();
    });
  }

  public setPercentageValue(total: number): string {
    if (total === null || (total && total === 0)) {
      return '0.00 %';
    } else {
      return total.toString() + ' %';
    }
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'guaranteeBondType.name',
        header: 'table.column.type',
        plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
      },
      {
        field: 'guarantorName',
        header: 'table.column.guarantorName'
      },
      {
        field: 'percentageValue',
        header: 'table.column.percentageValue',
        className: 'text-right',
        plugins: {
          name: 'custom-plugin',
          before: (tablePlugin: TablePluginData): string => {
            return (
              this.global.converterService.convertDecimal(tablePlugin.value) +
              ' %'
            );
          }
        }
      },
      {
        header: 'table.column.guarantee',
        columnList: [
          {
            field: 'submissionDate',
            header: 'table.column.date',
            plugins: 'date'
          },
          {
            field: 'startDate',
            header: 'table.column.startDate',
            plugins: 'date'
          },
          {
            field: 'endDate',
            header: 'table.column.endDate',
            plugins: 'date'
          }
        ]
      },
      {
        field: 'amount',
        header: 'table.column.amount',
        className: 'text-right',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      },
      {
        field: 'description',
        header: 'table.column.description',
        className: 'white-space-normal'
      }
    ]);
    this.tableResponse.setRecordList(this.guaranteeLists.value);
  }

  public doAdd(): void {
    const todo = 'add';
    const amount = this.amount;
    const guatanteelist = this.guaranteeLists.value;
    this.appPopupGuaranteeService
      .openPo(todo, amount, guatanteelist)
      .subscribe(guarantee => {
        const formGroup = this.formBuilder.group({
          id: guarantee.id,
          guaranteeBondType: guarantee.guaranteeBondType,
          guarantorName: guarantee.guarantorName,
          percentageValue: guarantee.percentageValue,
          description: guarantee.description,
          submissionDate: guarantee.submissionDate,
          startDate: guarantee.startDate,
          endDate: guarantee.endDate,
          amount: guarantee.amount
        });
        this.guaranteeLists.push(formGroup);
        this.tableResponse.setRecordList(this.guaranteeLists.value);
        this.tableResponse.reload();
        this.onChange.emit(this.guaranteeLists.value);
      });
  }

  public doClick(guaranteeEdit): void {
    let todo = '';
    if (this.isView) {
      todo = 'view';
    } else {
      todo = 'edit';
    }
    const amount = this.amount;
    const guatanteelist = this.guaranteeLists.value;
    this.appPopupGuaranteeService
      .openPo(todo, amount, guatanteelist, guaranteeEdit)
      .subscribe(guaranteeTemp => {
        this.guaranteeLists.value.forEach((guarantee, index) => {
          if (JSON.stringify(guarantee) === JSON.stringify(guaranteeEdit)) {
            this.guaranteeLists.value[index] = guaranteeTemp;
            this.guaranteeLists.controls[index].patchValue(guaranteeTemp);
          }
        });
        this.tableResponse.setRecordList(this.guaranteeLists.value);
        this.tableResponse.reload();
        this.onChange.emit(this.guaranteeLists.value);
      });
  }

  public doDelete(event: SelectedTableRecordModel): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach((record: Guarantee) => {
            const indexOfRecord = this.guaranteeLists.controls.findIndex(
              r => r.value.guaranteeBondType.id === record.guaranteeBondType.id
            );
            if (indexOfRecord !== -1) {
              this.guaranteeLists.value.splice(indexOfRecord, 1);
              this.guaranteeLists.removeAt(indexOfRecord);
            }
          });
          this.tableResponse.setRecordList(this.guaranteeLists.value);
          this.tableResponse.reload();
          this.onChange.emit(this.guaranteeLists.value);
        }
      });
  }
}
