<app-popup
  header="report-recap-rfq.popup.selectFieldToDownload"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <app-alert-x *ngIf="!isLessThen3Fields">
      {{ 'report-recap-rfq.message.selectField' | translate }}
    </app-alert-x>
    <app-alert-x *ngIf="isLessThen3Fields">
      {{ 'report-recap-rfq.message.chooseAtLeast3Fields' | translate }}
    </app-alert-x>
    <app-select
      [optionList]="optionList"
      [isMultiple]="true"
      formControlName="column"
    >
    </app-select>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          color="LIGHT"
          [outline]="true"
          minWidth="165px"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button color="PRIMARY" minWidth="165px" (onClick)="doExport()">{{
          'app.button.download' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
