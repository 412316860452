import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AanwijzingParticipant } from 'src/app/core/bean/aanwijzing-participant';
import { SignatureModel } from 'src/app/core/components/app-signature/signature.model';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './aanwijzing-popup-join-offline.component.html'
})
export class AanwijzingPopupJoinOfflineComponent extends BaseModuleComponent implements OnInit {

    // @Input() regionList: Region[];
    // public countryRegionOptionList: OptionListModel<Region> = new OptionListModel(true);
    @Input() public aanwijzingParticipant: AanwijzingParticipant;
    public isSureAlert = false;
    public model: SignatureModel = new SignatureModel();
    public user = this.global.userSession.user;

    @Output() onChange: EventEmitter<AanwijzingParticipant> = new EventEmitter();

    constructor(translateService: TranslateService) {
        super('aanwijzing', translateService);
    }

    public onInit(): void {
        this.buildAndSetFormGroup();
        this.setStateReady();
    }

    public buildAndSetFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            signatureImg: [null, Validators.required()],
            isCheck: [null, Validators.required()]
        });

        // this.countryRegionOptionList.setRequestValues(this.regionList);

        // this.formGroup.patchValue({
        //     phone: { country: this.regionList.find(r => r.code === 'ID'), number: null }
        // });
    }

    public doSubmit(): void {
        this.setStateProcessing();
        this.model.requestUpload().subscribe(() => {
            this.setStateReady();
            this.validate();
            if (this.formGroup.valid) {
                this.log.debug(this.formGroup.value.signatureImg);

                this.setStateProcessing();
                const aanwijzingParticipant = this.aanwijzingParticipant;
                aanwijzingParticipant.isJoin = true;

                const req = {
                    aanwijzingParticipant,
                    fileObject: this.formGroup.value.signatureImg[0]
                };
                this.log.debug(req);

                this.httpClientService
                .post<any>('/aanwijzing/insert-update-participant', req)
                .subscribe(response => {
                    this.log.debug(response);
                    this.setStateReady();
                    this.onChange.emit(response);
                });
            }
        });
    }

}
