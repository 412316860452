import { Lang } from '../../core/bean/lang';
import { Organization } from '../../core/bean/organization';
import { Region } from '../../core/bean/region';
import { VendorType } from '../../core/bean/vendor-type';
import { WebContent } from '../../core/bean/web-content';
import { VendorEntity } from '../../core/entity/vendor.entity';

export class RegistrationVendorResponse {
  vendorTypeList: VendorType[];
  langList: Lang[];
  defaultOrganization: Organization;
  regionList: Region[];
  vendorEntityList: VendorEntity[];
  contentVendorTOCList: WebContent[];
}
