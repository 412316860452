import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { WidgetService } from '../widgets.service';
import { WidgetContractedVendorResponse } from './app-widget-contracted-vendor.response';
@Component({
  templateUrl: './app-widget-contracted-vendor.component.html',
  styleUrls: ['./app-widget-contracted-vendor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetContractedVendorComponent extends BaseComponentComponent {
  public total: number;

  constructor(public widgetService: WidgetService) {
    super('app-widget-contracted-vendor');
  }

  public onInit(): void {
    this.getAndSetContractedVendorInformation();
  }

  public getAndSetContractedVendorInformation(): void {
    this.httpClientService
      .get<WidgetContractedVendorResponse>('/widget-contracted-vendor/index')
      .subscribe(response => {
        if (response.total) {
          this.total = response.total;
        }
        this.setStateReady();
      });
  }

  public goToMoreDetail(): void {
    this.router.navigate(['/pages/vendor-list']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
