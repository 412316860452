<app-layout
  [isLoading]="formLoading"
  header="contract-history-vendor.breadcrumb.index"
  [backTo]="urlBack"
>
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge class="cml-2" [color]="badgeColor[contractStatus.code]">
      {{
        contractStatus.translationKey
          ? (contractStatus.translationKey.module.code.toLowerCase() +
              '.' +
              contractStatus.translationKey.key | translate)
          : contractStatus.name
      }}
    </app-badge>
  </ng-template>
  <app-alert-x
    *ngIf="
      !formLoading &&
      contractStatus.code === global.appConstant.cm.CONTRACT_STATUS_TERMINATED
    "
    color="DANGER"
    [isBold]="false"
  >
    {{
      'contract-history-vendor.alert.msg.thisProcessWasTerminatedBy' | translate
    }}
    <strong>{{
      detailResponse.contractTermination?.terminatedByUser?.name
    }}</strong>
    {{ 'contract-history-vendor.alert.msg.at' | translate }}
    <strong>
      {{
        detailResponse.contractTermination?.terminationDate
          | date: global.config.parameterModel.datetimeFormatShort
      }}
    </strong>
    {{ 'contract-history-vendor.alert.msg.theTerminationReasonIs' | translate }}
    <strong
      >&#34;{{ detailResponse.contractTermination?.description }}&#34;</strong
    >
  </app-alert-x>
  <app-alert-x
    color="WARNING"
    *ngIf="
      !formLoading &&
      contractStatus.code === global.appConstant.cm.CONTRACT_STATUS_EXPIRED
    "
  >
    {{
      'contract-history-vendor.detail.alert.contractExpired' | translate
    }}&nbsp;
    {{ contract.endDate | date: global.appConstant.core.FORMAT_LONG_DATE }}
  </app-alert-x>
  <app-alert-x
    [isShowIcon]="false"
    *ngIf="
      contract.dr &&
      !formLoading &&
      contractStatus.code !== global.appConstant.cm.CONTRACT_STATUS_EXPIRED &&
      contractStatus.code !== global.appConstant.cm.CONTRACT_STATUS_CANCEL &&
      contractStatus.code !==
        global.appConstant.cm.CONTRACT_STATUS_TERMINATED &&
      contractStatus.code !==
        global.appConstant.cm.CONTRACT_STATUS_WAITING_FOR_TERMINATION
    "
  >
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <div class="pvc pv-box-delivery mr-3"></div>
        <label class="mb-0 text-primary">{{
          'contract-history-vendor.detail.alert.monitoringDR' | translate
        }}</label>
      </div>
      <app-button (onClick)="doViewDetailDR()">
        {{
          'contract-history-vendor.detail.alert.button.viewDetail' | translate
        }}
      </app-button>
    </div>
  </app-alert-x>
  <form *ngIf="!formLoading" [formGroup]="formGroup" novalidate>
    <app-contract-request-info
      *ngIf="!formLoading"
      [contractId]="contractId"
      [isShowDetail]="false"
    ></app-contract-request-info>
    <app-work-definition [contractId]="contractId"></app-work-definition>
    <app-card
      *ngIf="
        !formLoading &&
        contractStatus.code !== global.appConstant.cm.CONTRACT_STATUS_CANCEL
      "
      header="contract-history-vendor.detail.card.contractFinalization.title"
      tips="contract-history-vendor.detail.card.contractFinalization.tips"
    >
      <form [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{
              'contract-history-vendor.detail.form.finalContract' | translate
            }}
          </label>
          <div class="col-sm-7 text-left">
            <div
              style="
                margin: 0 auto;
                display: flex;
                position: relative;
                border-radius: 5px;
                box-sizing: border-box;
                height: 78px;
                border: 1px solid var(--gray-400);
              "
            >
              <div
                style="
                  width: 60px;
                  height: 60px;
                  overflow: hidden;
                  border-radius: 60px;
                  display: contents;
                "
                class="mr-3"
              >
                <img
                  [attr.src]="
                    '/assets/component/fileuploader/logo-icon/' +
                    model.docFile.extension.toLowerCase() +
                    '.png'
                  "
                  [attr.alt]="model.docFile.fileName"
                  [default]="global.appConstant.core.URL_DEFAULT_ICON"
                  class="img-thumbnail"
                  style="width: 60px; height: 60px; border: none"
                />
              </div>

              <div
                style="flex: 1; padding: 0.25rem 1rem"
                class="d-inline-block"
              >
                <a
                  class="item-content-name"
                  href="#"
                  (click)="
                    doDownload(
                      $event,
                      '/contract-history-vendor/file/view/' +
                        model.docFile.uploadedFileName +
                        '/' +
                        model.docFile.fileType.code,
                      model.docFile.fileName
                    )
                  "
                  maxLine
                  [attr.title]="model.docFile.fileName"
                >
                  {{ model.docFile.fileName }}
                </a>
                <ng-container>
                  <span class="d-inline-flex">
                    <strong class="mr-1">{{ model.version }}</strong>
                    <span class="item-content-size">
                      {{ model.docFile.size / 1000000 | number: '1.2-2' }} MB
                    </span>
                  </span>
                  <p class="text-secondary">
                    {{
                      'app-popup-version-history.form.uploadedBy' | translate
                    }}
                    {{ model.uploaderName }}
                    {{ 'app-popup-version-history.form.at' | translate }}
                    {{
                      model.lastModifiedDate
                        | date
                          : global.appConstant.core.FORMAT_SHORT_DATE_AND_TIME
                    }}
                  </p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{
              'contract-history-vendor.detail.form.documentVersion' | translate
            }}
          </label>
          <div class="col-sm-6 text-left">
            <label>{{ model.version }}</label
            >&nbsp;
            <span
              class="hyperlink-text cursor-pointer"
              (click)="doClickVersionHistory()"
            >
              <strong>
                {{
                  'contract-history-vendor.detail.from.viewVersionHistory'
                    | translate
                }}
              </strong>
            </span>
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{
              'contract-history-vendor.detail.form.contractNumber' | translate
            }}
          </label>
          <div class="col-sm-9 text-left">
            {{ contract.number }}
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{
              'contract-history-vendor.detail.form.contractPeriod' | translate
            }}
          </label>
          <div class="col-sm-9 text-left">
            <app-date-picker-x size="XL" formControlName="date" [range]="true">
            </app-date-picker-x>
          </div>
        </div>
        <div
          class="form-group row text-sm-left"
          *ngIf="contractDoc?.processedByUser && todo === 'view'"
        >
          <label class="col-sm-3">
            {{
              'contract-history-vendor.detail.form.internalContractSignator'
                | translate
            }}
          </label>
          <div class="col-sm-9 text-left">
            {{ contractDoc?.processedByUser?.name || '-' }}
          </div>
        </div>
      </form>
    </app-card>
    <app-contract-document
      *ngIf="!formLoading"
      mode="view"
      [object]="contractDoc"
    ></app-contract-document>
    <app-vendor-information
      *ngIf="!formLoading"
      [vendorId]="contract.vendor.id"
    ></app-vendor-information>
    <app-card
      *ngIf="
        !formLoading &&
        contractStatus.code === global.appConstant.cm.CONTRACT_STATUS_CLOSED
      "
      header="contract-history-vendor.detail.card.contractClosure.title"
      tips="contract-history-vendor.detail.card.contractClosure.tips"
    >
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-history-vendor.detail.form.closureDate' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{
            detailResponse.contractClosed.processedDate
              | date: date:global.appConstant.core.FORMAT_SHORT_DATE
          }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-history-vendor.detail.form.description' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{ detailResponse.contractClosed.description }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-history-vendor.detail.form.attachmentFile' | translate }}
        </label>
        <div class="col-sm-6 text-left">
          <app-upload-x
            formControlName="file"
            size="LG"
            [fileUploader]="fileUploader"
          ></app-upload-x>
        </div>
      </div>
    </app-card>
    <app-card
      *ngIf="
        !formLoading &&
        (contractStatus.code ===
          global.appConstant.cm.CONTRACT_STATUS_TERMINATED ||
          contractStatus.code ===
            global.appConstant.cm.CONTRACT_STATUS_WAITING_FOR_TERMINATION)
      "
      header="contract-history-vendor.detail.card.contractTermination.title"
      tips="contract-history-vendor.detail.card.contractTermination.tips"
    >
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{
            'contract-history-vendor.detail.form.terminationDate' | translate
          }}
        </label>
        <div class="col-sm-9 text-left">
          {{
            detailResponse.contractTermination.terminationDate
              | date: date:global.appConstant.core.FORMAT_SHORT_DATE
          }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{
            'contract-history-vendor.detail.form.terminationNumber' | translate
          }}
        </label>
        <div class="col-sm-9 text-left">
          {{ detailResponse.contractTermination.code }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{
            'contract-history-vendor.detail.form.terminationReason' | translate
          }}
        </label>
        <div class="col-sm-9 text-left">
          {{ detailResponse.contractTermination.description }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-history-vendor.detail.form.attachmentFile' | translate }}
        </label>
        <div class="col-sm-6 text-left">
          <app-upload-x
            formControlName="fileTermination"
            size="LG"
            [fileUploader]="fileUploaderTermination"
          ></app-upload-x>
        </div>
      </div>
    </app-card>
  </form>

  <ng-template #contentSidebarRight>
    <app-tips *ngIf="!formLoading"></app-tips>
  </ng-template>
</app-layout>
