import { AppDynamicComponentService } from '../../../services/app-dynamic-component.service';
import { AppUploadXComponent } from '../../upload/components/app-upload-x/app-upload-x.component';
import { createPluginFormcontrol } from '../helpers';
import { setTableRecordFormValue } from '../helpers/set-table-record-form-value.helper';
import { UploadPlugin } from '../interfaces/available-table-plugin';
import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';

export const AppTableUploadPlugin: TablePlugin = {
  name: 'upload',
  before: (data: TablePluginData) => {
    data.element.addEventListener('click', (event: PointerEvent) =>
      event.stopPropagation()
    );
    const uploadPlugin: UploadPlugin = data.column.plugins as UploadPlugin;
    const appDynamicComponent = data.state.injector.get(
      AppDynamicComponentService
    );
    let config: any = {};
    if (typeof uploadPlugin !== 'string' && uploadPlugin) {
      config = uploadPlugin;
    }

    if (data.value) {
      uploadPlugin.fileUploader.setFile(data.value);
      data.record.record.file = uploadPlugin.fileUploader.fileObjectList;
    }
    const formControl = createPluginFormcontrol(
      data,
      uploadPlugin.fileUploader.fileObjectList
    );
    formControl.setIsView(!!data.state.isView);
    appDynamicComponent
      .create(AppUploadXComponent, data.element, {
        ...config,
        fileUploader: Object.assign({}, uploadPlugin.fileUploader),
        isSingle: true,
        formControl: formControl,
        size: config.size || 'XL'
      })
      .onChange.subscribe(
        setTableRecordFormValue.bind(
          this,
          data.state,
          data.record,
          data.column,
          data.indexI
        )
      );
  }
};
