import { BaseEntity } from '../base/base-entity';
import { File } from './../bean/file';

export class HomeSlideEntity extends BaseEntity {
  imgFile: File;
  title: string;
  body: string;
  referenceLink: string;
  isActive: boolean;
  text: string;
  slideText: string;
}
