<div class="row" [ngClass]="isView ? '' : 'main-row'" *ngIf="isAllowed">
  <div class="col-12 custom-tabs">
    <app-card
      header="{{
        roleType !==
          global.appConstant.core.ROLE_TYPE_VENDOR &&
        isView &&
        (this.urlBack === '/pages/on-boarding' ||
          this.urlBack === '/pages/approval-prcs') &&
        vendorStatus === 'ACTIVE'
          ? header + '<br><label ' + style + '>' + info + '</label>'
          : header
      }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-tab
        [model]="tabResponse"
        (onChangeTab)="doNext($event)"
        *ngIf="!formLoading"
      >
        <router-outlet></router-outlet>
        <ng-template #tabFooter let-currentTab>
          <div class="floating-button" *ngIf="currentTab.code !== 'CHECKLIST'">
            <div class="floating-button-wrapper">
              <div class="floating-button-content">
                <div
                  class="button-group button-group-center"
                  *ngIf="
                    !isView &&
                    roleType ===
                      global.appConstant.core.ROLE_TYPE_VENDOR
                  "
                  [ngStyle]="{ padding: '0.44rem 0' }"
                >
                  <app-button
                    color="SECONDARY"
                    (onClick)="doPrevious(currentTab.previousTab)"
                    *ngIf="!currentTab.isFirstTab"
                    [disabled]="formSaving"
                    >{{ 'app.button.previous' | translate }}</app-button
                  >
                  <app-button (onClick)="doSaveDraft()" [disabled]="formSaving">
                    {{
                      'company-profile.button.save-draft' | translate
                    }}</app-button
                  >
                  <app-button
                    (onClick)="doNext(currentTab.nextTab)"
                    *ngIf="!currentTab.isLastTab"
                    [disabled]="formSaving"
                    >{{ 'app.button.next' | translate }}</app-button
                  >
                  <app-button
                    (onClick)="doSave()"
                    *ngIf="currentTab.isLastTab"
                    [disabled]="formSaving"
                    >{{
                      'company-profile.button.submit' | translate
                    }}</app-button
                  >
                </div>
                <div
                  class="button-group button-group-center"
                  *ngIf="
                    isView &&
                    roleType ===
                      global.appConstant.core.ROLE_TYPE_VENDOR
                  "
                >
                  <app-button
                    color="SECONDARY"
                    (onClick)="doBack()"
                    *ngIf="currentTab.isFirstTab && isFromVendor"
                    >{{ 'app.button.back' | translate }}</app-button
                  >
                  <app-button
                    color="SECONDARY"
                    (onClick)="doPrevious(currentTab.previousTab)"
                    *ngIf="!currentTab.isFirstTab"
                    >{{ 'app.button.previous' | translate }}</app-button
                  >
                  <app-button
                    (onClick)="doNext(currentTab.nextTab)"
                    *ngIf="!currentTab.isLastTab"
                    >{{ 'app.button.next' | translate }}</app-button
                  >
                </div>
                <div
                  class="button-group button-group-center"
                  *ngIf="
                    isView &&
                    urlBack !== undefined &&
                    !currentTab.isLastTab &&
                    !isFromVendorList &&
                    roleType !==
                      global.appConstant.core.ROLE_TYPE_VENDOR
                  "
                >
                  <app-button
                    color="SECONDARY"
                    (onClick)="doPrevious(currentTab.previousTab)"
                    *ngIf="!currentTab.isFirstTab"
                    >{{ 'app.button.previous' | translate }}</app-button
                  >
                  <app-button
                    color="SECONDARY"
                    (onClick)="doBack()"
                    *ngIf="currentTab.isFirstTab"
                    >{{ 'app.button.back' | translate }}</app-button
                  >
                  <app-button (onClick)="doNext(currentTab.nextTab)">{{
                    'app.button.next' | translate
                  }}</app-button>
                </div>
                <div
                  class="button-group button-group-center"
                  *ngIf="
                    isView &&
                    urlBack !== undefined &&
                    isFromVendorList &&
                    roleType !==
                      global.appConstant.core.ROLE_TYPE_VENDOR
                  "
                >
                  <app-button
                    color="SECONDARY"
                    (onClick)="doPrevious(currentTab.previousTab)"
                    *ngIf="!currentTab.isFirstTab"
                    >{{ 'app.button.previous' | translate }}</app-button
                  >
                  <app-button
                    color="SECONDARY"
                    (onClick)="doBack()"
                    *ngIf="currentTab.isFirstTab"
                    >{{ 'app.button.back' | translate }}</app-button
                  >
                  <app-button
                    (onClick)="doNext(currentTab.nextTab)"
                    *ngIf="!currentTab.isLastTab"
                    >{{ 'app.button.next' | translate }}</app-button
                  >
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </app-tab>
    </app-card>
  </div>
</div>
