import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorCategoryEditAddComponent } from './vendor-category-edit-add.component';
import { VendorCategoryComponent } from './vendor-category.component';
export const routes = [
  { path: '', component: VendorCategoryComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: VendorCategoryEditAddComponent,
    data: { breadcrumb: 'vendor-category.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: VendorCategoryEditAddComponent,
    data: { breadcrumb: 'vendor-category.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [VendorCategoryComponent, VendorCategoryEditAddComponent],
  providers: []
})
export class VendorCategoryModule {}
