import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementType } from '../../core/bean/procurement-type';
import { AppPopupContractService } from '../../core/components/app-popup/app-popup-contract/app-popup-contract.service';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
@Component({
  selector: 'app-popup-performance-procurement',
  templateUrl: './app-popup-performance-procurement.component.html'
})
export class AppPopupPerformanceProcurementComponent extends BaseComponentComponent {
  @Input() public todo: string;
  @Input() public vendorId: number;
  @Output() public onChange: EventEmitter<Procurement> = new EventEmitter();

  public procurementTypeOptionList: OptionListModel<ProcurementType> =
    new OptionListModel();

  constructor(
    public appPopupContractService: AppPopupContractService,
    public activeModal: NgbActiveModal
  ) {
    super('performance-procurement');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorId: [null],
      procurementName: [null, Validators.required()],
      procurementType: [null, Validators.required()],
      procurementTypeId: [null],
      number: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<ProcurementType[]>(
        '/performance-procurement/get-list-procurement-type'
      )
      .subscribe(procurementTypeList => {
        this.procurementTypeOptionList.setRequestValues(procurementTypeList);
      });
    this.formGroup.patchValue({
      vendorId: this.vendorId
    });
    this.setStateReady();
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const url = '/performance-procurement/insert-procurement';
            this.formGroup.patchValue({
              procurementTypeId: this.formGroup.value.procurementType.id
            });
            this.httpClientService
              .post<Response<Procurement>>(url, this.formGroup.value)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.activeModal.dismiss();
                  this.onChange.emit(this.formGroup.value);
                  this.setStateReady();
                  this.global.alertService.showSuccessSaving();
                  this.router.navigate([
                    '/pages/performance-procurement/detail-procurement'
                  ]);
                } else {
                  this.global.alertService.showError(
                    response.statusText,
                    '.main-row-procurement'
                  );
                  this.setStateReady();
                }
              });
          }
        });
    }
  }
}
