import { BaseDTO } from '../../../core/base/base-dto';
import { Currency } from '../../../core/bean/currency';
import { Region } from '../../../core/bean/region';
import { VendorExperienceStatus } from '../../../core/bean/vendor-experience-status';

export class VendorPopupVendorExperienceDTO extends BaseDTO {
    vendorExperienceStatusList: VendorExperienceStatus[];
    countryRegionList: Region[];
    currencyList: Currency[] = [];
}
