<div class="row main-row vendor-history-accordion">
  <div class="col-12 mb-4">
    <app-card
      header="vendor-history.title"
      [isLoading]="formLoading"
      customClassBody="mx-0"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-history.version' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              [optionList]="vendorHistoryIdList"
              formControlName="vendorHistoryIdList"
              (onChange)="doOnChange($event)"
            ></app-combo-box>
          </div>
        </div>
      </form>
      <app-accordion
        [model]="accordionResponse"
        [isShowButtonExpandCollapse]="true"
        customClass="m-0 border-0 border-bottom-1"
        customClassHeader="border-0"
        customClassBody="border-0"
        [isLoading]="formLoading"
      ></app-accordion>
      <div class="button-group button-group-center button-group-icon-text my-5">
        <app-button mode="BYPASS" color="SECONDARY" (onClick)="doBack()"
          >{{ 'app.button.back' | translate }}
        </app-button>
        <app-button mode="BYPASS" (onClick)="doOpenPopupPrint()">
          <em class="fas fa-print"></em>{{ 'app.button.print' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>
