import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupFinancialStatementComponent } from './popup/app-popup-financial-statement.component';
import { TabProfileFinancialStatementComponent } from './tab-profile-financial-statement.component';
export const routes = [
  {
    path: '',
    component: TabProfileFinancialStatementComponent,
    data: { breadcrumb: '' }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    TabProfileFinancialStatementComponent,
    AppPopupFinancialStatementComponent
  ],
  entryComponents: [
    AppPopupFinancialStatementComponent
  ],
  exports: [
    TabProfileFinancialStatementComponent,
    AppPopupFinancialStatementComponent
  ]
})

export class TabProfileFinancialStatementModule {}
