<app-popup header="procurement-schedule.popup.headerChangeHistoryDtl">
  <app-table-xx
    *ngIf="!formLoading"
    [model]="tableResponseChangeHistoryDtl"
    stringUrl="/procurement-schedule/get-schedule-history-dtl"
  >
    <ng-template #alertWrapper>
      <app-alert-x color="INFO">{{
        'procurement-schedule.detail.changeHistoryDtl.info' | translate
      }}</app-alert-x>
    </ng-template>
  </app-table-xx>
</app-popup>
