import { Chart } from '../models/chart';
import { ChartModel } from '../models/chart-model';
import * as chartAction from './chart.action';
import { setBarChart } from './set-bar-chart.action';
import { setBubbleChart } from './set-bubble-chart.action';
import { setDoughnutChart } from './set-doughnut-chart.action';
import { setFinancialChart } from './set-financial-chart.action';
import { setLineChart } from './set-line-chart.action';
import { setPieChart } from './set-pie-chart.action';
import { setPolarAreaChart } from './set-polar-area-chart.action';
import { setRadarChart } from './set-radar-chart.action';
import { setScatterChart } from './set-scatter-chart.action';
export function dispatchChart(
  chartState: ChartModel,
  action: chartAction.All
): Array<Chart> {
  switch (action.type) {
    case chartAction.SET_LINE_CHART:
      return setLineChart(chartState);
    case chartAction.SET_BAR_CHART:
      return setBarChart(chartState);
    case chartAction.SET_DOUGHNUT_CHART:
      return setDoughnutChart(chartState);
    case chartAction.SET_RADAR_CHART:
      return setRadarChart(chartState);
    case chartAction.SET_PIE_CHART:
      return setPieChart(chartState);
    case chartAction.SET_POLAR_AREA_CHART:
      return setPolarAreaChart(chartState);
    case chartAction.SET_BUBBLE_CHART:
      setBubbleChart(chartState);
      break;
    case chartAction.SET_SCATTER_CHART:
      setScatterChart(chartState);
      break;
    case chartAction.SET_FINANCIAL_CHART:
      setFinancialChart(chartState);
      break;
    default:
      console.log(`There's no action type`);
      break;
  }
}
