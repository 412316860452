import { BaseEntity } from '../base/base-entity';
import { BillingMemo } from '../bean/billing-memo';
import { BillingMemoBudgetDetail } from '../bean/billing-memo-budget-detail';
import { BudgetAllocation } from '../bean/budget-allocation';

export class BillingMemoBudgetEntity extends BaseEntity {
    billingMemoBudgetDetailList: BillingMemoBudgetDetail[];

    billingMemo: BillingMemo;
    budgetAllocation: BudgetAllocation;
    taxBaseAmount: number;
    vatAmount: number;
    totalAmount: number;
    taxPercentageValue: number;
}
