<app-popup [isLoading]="formLoading" [isShowHeaderCloseButton]="false">
  <app-table-xx
    header="report-recap-rfq.detail.result.title"
    [model]="tableResponse"
    stringUrl="/report-recap-rfq/index"
  >
    <ng-template #headerButtons>
      <span class="mt-3" (click)="doMinimize()">
        <a
          class="mr-2"
          style="text-decoration-line: underline; font-weight: 500"
        >
          <span>
            <em
              class="pir pi-compress-alt ml-1"
              style="top: auto; left: auto; font-size: 16px; font-weight: 500"
            ></em>
          </span>
          {{ 'report-recap-rfq.detail.minimize' | translate }}
        </a>
      </span>
      <span class="mt-3" (click)="doExport()">
        <a
          class="mr-2"
          style="text-decoration-line: underline; font-weight: 500"
        >
          <span>
            <em
              class="pir pi-file-download ml-1"
              style="top: auto; left: auto; font-size: 16px; font-weight: 500"
            ></em>
          </span>
          {{ 'report-recap-rfq.detail.downloadReport' | translate }}
        </a>
      </span>
    </ng-template>
  </app-table-xx>
</app-popup>
