import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppTextAreaModule } from '../../app-text-area/app-text-area.module';
import { AppTextFieldModule } from '../../app-text-field/app-text-field.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupBankComponent } from './app-popup-bank.component';
import { AppPopupBankService } from './app-popup-bank.service';
@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppTextFieldModule,
    AppTextAreaModule,
    AppButtonModule
  ],
  declarations: [AppPopupBankComponent],
  entryComponents: [AppPopupBankComponent],
  providers: [AppPopupBankService],
  exports: [AppPopupBankComponent]
})
export class AppPopupBankModule {}
