import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ProcurementMethod } from '../../core/bean/procurement-method';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
    templateUrl: './procurement-participant.component.html'
})
export class ProcurementParticipantComponent extends BaseModuleComponent {
    @ViewChild(AppTableComponent) public table: AppTableComponent;
    public tableResponse: TableResponseModel<ProcurementMethod>;

    constructor(translateService: TranslateService) {
        super('procurement-participant', translateService);
    }

    public onInit(): void {
        this.buildTableResponse();
        this.setStateReady();
    }

    public buildTableResponse(): void {
        this.tableResponse = new TableResponseModel(this.moduleCode, [
            {field: 'name', header: 'table.column.procurementMethodName'}
        ]);
    }

    public doEdit(procurementMethod: ProcurementMethod): void {
        this.global.routerParams.clear();
        this.global.routerParams.set('procurementMethod', procurementMethod);
        this.router.navigate(['/pages/procurement-participant/edit']);
    }

}
