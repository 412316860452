import { FileType } from '../../../bean/file-type';
export class UploaderOptions {
  maxFile?: number;
  maxSize?: number;
  allowedExtension?: string;
  formDataName?: string;
  fileType?: FileType;
  stringUrl?: string;
  maxFileNameLength?: number;
}
