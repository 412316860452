import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../core/shared/shared.module';
import { RegistrationConfirmationComponent } from './registration-confirmation.component';
import { RegistrationFormComponent } from './registration-form.component';
import { RegistrationComponent } from './registration.component';
export const routes: Routes = [
  {
    path: '',
    component: RegistrationComponent,
    data: { breadcrumb: '' },
    children: [
      { path: '', component: RegistrationFormComponent },
      { path: 'confirmation', component: RegistrationConfirmationComponent, data: { breadcrumb: 'confirmation' } }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, NgbModule],
  declarations: [RegistrationComponent, RegistrationFormComponent, RegistrationConfirmationComponent],
  providers: []
})
export class RegistrationModule { }
