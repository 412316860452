import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { CompanyProfileComponent } from './company-profile.component';
import { CompanyProfileServie } from './company-profile.service';

export const routes = [
  {
    path: '',
    component: CompanyProfileComponent,
    data: { breadcrumb: '' },
    children: [
      {
        path: 'company-data',
        loadChildren: () =>
          import(
            '../tab-profile-company-data/tab-profile-company-data.module'
          ).then(m => m.TabProfileCompanyDataModule),
        data: { breadcrumb: 'tab-profile-company-data.breadcrumb.index' }
      },
      {
        path: 'general-information',
        loadChildren: () =>
          import(
            '../tab-profile-general-information/tab-profile-general-information.module'
          ).then(m => m.TabProfileGeneralInformationModule),
        data: { breadcrumb: 'tab-profile-general-information.breadcrumb.index' }
      },
      {
        path: 'company-organization',
        loadChildren: () =>
          import(
            '../tab-profile-company-organization/tab-profile-company-organization.module'
          ).then(m => m.TabProfileCompanyOrganizationModule),
        data: {
          breadcrumb: 'tab-profile-company-organization.breadcrumb.index'
        }
      },
      {
        path: 'bank',
        loadChildren: () =>
          import('../tab-profile-bank/tab-profile-bank.module').then(
            m => m.TabProfileBankModule
          ),
        data: { breadcrumb: 'tab-profile-bank.breadcrumb.index' }
      },
      {
        path: 'segmentation',
        loadChildren: () =>
          import(
            '../tab-profile-segmentation/tab-profile-segmentation.module'
          ).then(m => m.TabProfileSegmentationModule),
        data: { breadcrumb: 'tab-profile-segmentation.breadcrumb.index' }
      },
      {
        path: 'document',
        loadChildren: () =>
          import('../tab-profile-document/tab-profile-document.module').then(
            m => m.TabProfileDocumentModule
          ),
        data: { breadcrumb: 'tab-profile-document.breadcrumb.index' }
      },
      {
        path: 'expert-and-equipment',
        loadChildren: () =>
          import(
            '../tab-profile-expert-and-equipment/tab-profile-expert-and-equipment.module'
          ).then(m => m.TabProfileExpertAndEquipmentModule),
        data: {
          breadcrumb: 'tab-profile-expert-and-equipment.breadcrumb.index'
        }
      },
      {
        path: 'experience',
        loadChildren: () =>
          import(
            '../tab-profile-experience/tab-profile-experience.module'
          ).then(m => m.TabProfileExperienceModule),
        data: { breadcrumb: 'tab-profile-experience.breadcrumb.index' }
      },
      {
        path: 'partnership',
        loadChildren: () =>
          import(
            '../tab-profile-partnership/tab-profile-partnership.module'
          ).then(m => m.TabProfilePartnershipModule),
        data: { breadcrumb: 'tab-profile-partnership.breadcrumb.index' }
      },
      {
        path: 'financial-statement',
        loadChildren: () =>
          import(
            '../tab-profile-financial-statement/tab-profile-financial-statement.module'
          ).then(m => m.TabProfileFinancialStatementModule),
        data: { breadcrumb: 'tab-profile-financial-statement.breadcrumb.index' }
      },
      {
        path: 'marketing',
        loadChildren: () =>
          import('../tab-profile-marketing/tab-profile-marketing.module').then(
            m => m.TabProfileMarketingModule
          ),
        data: { breadcrumb: 'tab-profile-marketing.breadcrumb.index' }
      },
      {
        path: 'rep-agent',
        loadChildren: () =>
          import('../tab-profile-rep-agent/tab-profile-rep-agent.module').then(
            m => m.TabProfileRepAgentModule
          ),
        data: { breadcrumb: 'tab-profile-rep-agent.breadcrumb.index' }
      },
      {
        path: 'verification',
        loadChildren: () =>
          import(
            '../tab-profile-verification/tab-profile-verification.module'
          ).then(m => m.TabProfileVerificationModule),
        data: { breadcrumb: 'tab-profile-verification.breadcrumb.index' }
      }
    ]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [CompanyProfileComponent],
  exports: [CompanyProfileComponent],
  providers: [CompanyProfileServie]
})
export class CompanyProfileModule {}
