import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { ComplianceType } from '../../core/bean/compliance-type';
import { OptionListModel } from '../../core/model/option-list-model';

@Component({
  templateUrl: './app-popup-compliance-type.component.html'
})
export class AppPopupComplianceTypeComponent extends BaseComponentComponent {
  @Input() public complianceType: ComplianceType;
  @Input() public complianceTypeList: ComplianceType[];
  @Output() public onChange: EventEmitter<ComplianceType> = new EventEmitter();

  public complianceTypeOptionList: OptionListModel<ComplianceType> =
    new OptionListModel(false);

  constructor(public ngbActiveModal: NgbActiveModal) {
    super('compliance-test');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      complianceType: [null]
    });
  }

  public setFormGroup(): void {
    this.formGroup.patchValue({ complianceType: this.complianceType });
    this.complianceTypeOptionList.setRequestValues(this.complianceTypeList);
    this.setStateReady();
  }

  public doCancel(): void {
    this.ngbActiveModal.close();
  }

  public doChoose(): void {
    this.onChange.emit(this.formGroup.value.complianceType);
  }
}
