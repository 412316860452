import { NgModule } from '@angular/core';
import { PipesModule } from '../../../pipe/pipe.module';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppDropdownSelectModule } from '../../app-dropdown-select/app-dropdown-select.module';
import { AppTableXModule } from '../../app-table-x/components/app-table-x/app-table-x.module';
import { TableModule } from '../../table/table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupAllocatedItemDetailComponent } from './app-popup-allocated-item-detail.component';
import { AppPopupAllocatedItemDetailService } from './app-popup-allocated-item-detail.service';

@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppTableXModule,
    AppButtonModule,
    AppDropdownSelectModule,
    TableModule,
    PipesModule
  ],
  declarations: [AppPopupAllocatedItemDetailComponent],
  entryComponents: [AppPopupAllocatedItemDetailComponent],
  providers: [AppPopupAllocatedItemDetailService],
  exports: [AppPopupAllocatedItemDetailComponent]
})
export class AppPopupAllocatedItemDetailModule {}
