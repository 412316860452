import { Organization } from '../bean/organization';
import { User } from '../bean/user';
import { BaseEntity } from './base-entity';

export class BaseDelegatorEntity extends BaseEntity {
  userRequester: User; // masih dipakai?
  userDelegated: User;
  organizationTenant: Organization;
  organizationDelegated: Organization;
}
