export const reSetTableRecord = (
  record: any,
  field: string,
  value: any
): any => {
  const newRecord = Object.assign({}, record);
  const fieldSplit = field.split('.');
  if (fieldSplit.length > 1) {
    const key = fieldSplit.shift();
    newRecord[key] = reSetTableRecord(
      newRecord[key],
      fieldSplit.join('.'),
      value
    );
  } else {
    newRecord[field] = value;
  }
  return newRecord;
};
