import {
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { FormArray } from '@angular/forms';
import {
    NgbDateAdapter,
    NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ProcurementType } from 'src/app/core/bean/procurement-type';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Item } from '../../core/bean/item';
import { Organization } from '../../core/bean/organization';
import { WorkPlan } from '../../core/bean/work-plan';
import { WorkPlanAddress } from '../../core/bean/work-plan-address';
import { WorkPlanHistory } from '../../core/bean/work-plan-history';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { ApprovalPathModel } from '../../core/components/app-approval-path/model/approval-path-model';
import { AppChooseComponent } from '../../core/components/app-choose/app-choose.component';
import { AppPopupAddressBookService } from '../../core/components/app-popup/app-popup-address-book/app-popup-address-book.service';
import { AppPopupWorkPlanItemService } from '../../core/components/app-popup/app-popup-work-plan-item/app-popup-work-plan-item.service';
import { AppPopupWorkflowService } from '../../core/components/app-popup/app-popup-workflow/app-popup-workflow.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../core/components/upload/model/v1/file.uploader';
import { OptionListModel } from '../../core/model/option-list-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { AddressBook } from './../../core/bean/address-book';
import { WorkPlanItem } from './../../core/bean/work-plan-item';
import { WorkflowPrcs } from './../../core/bean/workflow-prcs';
import { Response } from './../../core/model/response-model';
import { ResponseStatusModel } from './../../core/model/response-status-model';
import { AppPopupWorkPlanItemProductComponent } from './app-popup-work-plan-item-product.component';
import { AppPopupWorkPlanItemServiceComponent } from './app-popup-work-plan-item-service.component';
import { AppPopupWorkPlanLocationComponent } from './app-popup-work-plan-location.component';
import { WorkPlanRequest } from './work-plan-request';
import { WorkPlanResponse } from './work-plan-response';

@Component({
    selector: 'app-work-plan-edit',
    templateUrl: 'work-plan-edit-add.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class WorkPlanEditAddComponent
    extends BaseModuleComponent
    implements OnInit {
    @ViewChild(AppTableComponent) table: AppTableComponent;
    @ViewChild(AppChooseComponent) appChoose: AppChooseComponent;
    @ViewChild('selectorProduct') tableWorkPlanProduct: AppTableComponent;
    @ViewChild('selectorService') tableWorkPlanService: AppTableComponent;
    /* PRODUCT */
    /* @ViewChild('selectorAddress') tableWorkPlanAddress: AppTableComponent; */
    @ViewChild('selectorWorkPlanHistory') tableWorkPlanHistory: AppTableComponent;

    @Input() public workPlanId: number;
    public tableResponseProduct: TableResponseModel<WorkPlanItem>;
    public tableResponseService: TableResponseModel<WorkPlanItem>;
    public tableResponseAddress: TableResponseModel<WorkPlanAddress>;
    public tableResponseWorkPlanHistory: TableResponseModel<WorkPlanHistory>;
    public workPlan: WorkPlan = new WorkPlan();
    public workPlanResponse: WorkPlanResponse = new WorkPlanResponse();
    public workPlanRequest: WorkPlanRequest = new WorkPlanRequest();
    public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
    public url: string;
    public fileUploader: FileUploader = new FileUploader(
        '/work-plan/',
        '',
        this.global.appConstant.fileType.DOC_WORK_PLAN,
        false,
        5
    );
    public approvalMemoDocUploader: FileUploader = new FileUploader(
        '/work-plan/',
        '',
        this.global.appConstant.fileType.DOC_APPROVAL_MEMO,
        false,
        5
    );
    public disabledAddressJob: boolean;
    public clickTabViewAddressJob: boolean;
    public totalPriceOfAllItem: number;
    public convertTotalPriceOfAllItem: string;
    public countClickAddressJob: number;
    public dataMap: Map<string, Object> = new Map<string, Object>();
    public itemList: Item[] = [];
    public objectId: number;
    public msgInfo = '';
    public buttonAddAddressJobIsDisable = false;
    public workPlanProgress: WorkPlan;
    public isWorkflowProgress: false;
    public addressIds: number[] = [];
    public usedBudgetList: Map<number, number> = new Map();
    public addressBookOptionList: OptionListModel<AddressBook> =
        new OptionListModel(false, 'address');
    public procurementTypeOptionList: OptionListModel<ProcurementType> =
        new OptionListModel(false);
    public approvalModelPrcsId: number;
    public urlBack: string;
    public isEdit = false;
    public currentDate: Date = new Date();
    public totalProductCost: number;
    public totalServiceCost: number;
    public itemIdList: number[] = [];
    public approverPath: ApprovalPathModel<any> = new ApprovalPathModel(
        this.moduleCode,
        'READ'
    );
    public workflowPrcs: WorkflowPrcs;
    public usedItemAddress: Map<number, number> = new Map();
    public workPlanItemProductList: WorkPlanItem[] = [];
    public workPlanItemServiceList: WorkPlanItem[] = [];
    public isMemoMandatory: boolean;
    public placeholder = 'XXXXX/XXX-XXX';

    constructor(
        translateService: TranslateService,
        public appPopupWorkPlanItemService: AppPopupWorkPlanItemService,
        public appPopupWorkflowService: AppPopupWorkflowService,
        public appPopupAddressBookService: AppPopupAddressBookService,
        public appPopupService: AppPopupService
    ) {
        super('work-plan', translateService);
    }

    onInit(): void {
        this.setRouterParams();
        this.buildFormGroup();
        this.setIsViewOnlyFormGroup();
        this.setFormGroup();
    }

    public setRouterParams(): void {
        this.todo = this.global.routerParams.get('todo');
        this.objectId = this.global.routerParams.get('objectId');
        this.workPlanId = this.global.routerParams.get('workPlanId');
        this.isWorkflowProgress =
            this.global.routerParams.get('isWorkflowProgress');
        if (this.isWorkflowProgress) {
            this.workPlan = this.global.routerParams.get('workPlan');
        }
        this.urlBack = this.global.routerParams.get('urlBackOutside');
        this.approvalModelPrcsId = this.global.routerParams.get(
            'approvalModelPrcsId'
        );
    }

    public setIsViewOnlyFormGroup(): void {
        if (this.todo === 'view') {
            this.setViewOnly();
            if (!this.workPlanId) {
                this.workPlanId = this.objectId;
            }
        }
    }

    public buildFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            id: [null],
            number: [
                null,
                Validators.compose([
                    Validators.required(),
                    Validators.procurementNumber(
                        this.translateService.instant('work-plan.validation.wrongNumberFormat')
                    ),
                    Validators.maxLength(32)
                ])
            ],
            name: [
                null,
                Validators.compose([Validators.required(), Validators.maxLength(512)])
            ],
            createdDate: [null, Validators.required()],
            workTarget: [null, Validators.compose([Validators.required(), Validators.min('createdDate')])],
            organization: [null, Validators.required()],
            procurementType: [null, Validators.required()],
            description: [null, Validators.compose([Validators.required(), Validators.maxLength(512)])],
            toc: [null, Validators.compose([Validators.required(), Validators.maxLength(512)])],
            approvalMemoDoc: [null],
            workPlanItemList: this.formBuilder.array([]),
            /* workPlanAddressList: this.formBuilder.array([]), */
            workflow: [null],
            workflowName: [null]
            /* file: [null] */
        });
    }

    public buildTableResponseWorkPlanHistory(): void {
        this.tableResponseWorkPlanHistory = new TableResponseModel(
            this.moduleCode,
            [
                {
                    field: 'user.name',
                    header: 'table.column.user'
                },
                {
                    field: 'modifyDate',
                    header: 'table.column.createdDate',
                    format: FieldFormatEnum.LongDateAndTime
                },
                {
                    field: 'context',
                    header: 'table.column.context'
                },
                {
                    field: 'newValue',
                    header: 'table.column.newValue',
                    customClass: 'white-space-normal'
                },
                {
                    field: 'oldValue',
                    header: 'table.column.oldValue',
                    customClass: 'white-space-normal'
                }
            ]
        );
    }

    public doFormatQuantity(amount: number): string {
        const formattedQty = this.global.converterService.convertMoney(amount);
        return formattedQty;
    }

    public setFormGroup(): void {
        this.httpClientService
            .post<WorkPlanResponse>(
                '/work-plan/add-edit',
                new RouteRequestModel(
                    this.todo,
                    this.objectId !== null && this.objectId !== undefined
                        ? this.objectId
                        : this.workPlanId
                )
            )
            .subscribe((workPlanResponse: WorkPlanResponse) => {
                this.workPlanResponse = workPlanResponse;
                this.addressBookOptionList.setRequestValues(
                    this.workPlanResponse.addressBookList
                );
                this.procurementTypeOptionList.setRequestValues(
                    this.workPlanResponse.procurementTypeList
                );
                this.formGroup.get('organization').patchValue(workPlanResponse.organizationList);
                if (workPlanResponse.workPlan && workPlanResponse.workPlan.id) {
                    this.fileUploader.setFileList(workPlanResponse.fileList);
                    this.approvalMemoDocUploader.setFileList(
                        workPlanResponse.approvalMemoDocList
                    );

                    const createdDate: Date = workPlanResponse.workPlan.createdDate
                        ? new Date(workPlanResponse.workPlan.createdDate)
                        : null;
                    this.currentDate = createdDate ? createdDate : this.currentDate;
                    const workTarget: Date = workPlanResponse.workPlan.workTarget
                        ? new Date(workPlanResponse.workPlan.workTarget)
                        : null;
                    this.formGroup.patchValue({
                        id: workPlanResponse.workPlan.id,
                        number:
                            this.todo === 'view' && this.workPlanResponse.workPlan.generateMemoNumber
                                ? this.workPlanResponse.workPlan.number +
                                this.workPlanResponse.workPlan.generateMemoNumber
                                : this.workPlanResponse.workPlan.number,
                        name: workPlanResponse.workPlan.name,
                        currentDate: this.currentDate,
                        createdDate,
                        workTarget,
                        organization: workPlanResponse.organizationList,
                        procurementType: workPlanResponse.workPlan.procurementType,
                        description: workPlanResponse.workPlan.description,
                        toc: workPlanResponse.workPlan.toc,
                        approvalMemoDoc: this.approvalMemoDocUploader.fileObjectList
                        /* file: this.fileUploader.fileObjectList */
                    });
                    this.formGroup.get('createdDate').updateValueAndValidity();
                    if (this.formGroup.get('number').disabled) {
                        this.placeholder = '';
                    } else {
                        const newDataPlaceholder = this.placeholder.slice(
                            +this.workPlanResponse.workPlan.number.length,
                            this.placeholder.length
                        );
                        this.placeholder =
                            this.workPlanResponse.workPlan.number + newDataPlaceholder;
                    }
                    this.workflowPrcs = this.workPlanResponse.workPlan.workflowPrcs
                        ? this.workPlanResponse.workPlan.workflowPrcs
                        : null;
                    this.workflowModelPrcs =
                        this.workPlanResponse.workPlan.workflowModelPrcs;
                    this.isMemoMandatory = this.doCheckIsMemoMandatory();
                }

                if (this.todo === 'edit' || this.todo === 'view') {
                    this.doSetWorkPlanItem();
                    /* PRODUCT */
                    /* this.workPlanDTO.workPlanAddressList.forEach(workPlanAddress => {
                      let quantityAddress = '';
                      workPlanAddress.workPlanAddressItemList.forEach(
                        workPlanAddressItem => {
                          if (workPlanAddressItem.quantity) {
                            quantityAddress +=
                              workPlanAddressItem.workPlanItem.item.name +
                              ' - ' +
                              workPlanAddressItem.quantity +
                              ' ' +
                              workPlanAddressItem.workPlanItem.uom.name +
                              '<br/>';
                          }
                        }
                      );
                      const organizationLists = [];
                      organizationLists.push(workPlanAddress.organizationList);
                      const workPlanAddressItemLists = [];
                      workPlanAddressItemLists.push(
                        workPlanAddress.workPlanAddressItemList
                      );
                      this.workPlanAddressList.push(
                        this.formBuilder.group({
                          organizationList: organizationLists,
                          organization: workPlanAddress.organization,
                          addressBook: workPlanAddress.addressBook,
                          picName: workPlanAddress.picName,
                          picPhone: workPlanAddress.picPhone,
                          addressDetail: workPlanAddress.addressDetail,
                          quantityAddress,
                          workPlanAddressItemList: workPlanAddressItemLists
                        })
                      );
                    }); */
                    this.workPlanResponse.workPlanItemProductList.forEach(
                        workPlanItem => {
                            this.itemIdList.push(workPlanItem.item.id);
                        }
                    );
                    this.workPlanResponse.workPlanItemServiceList.forEach(
                        workPlanItem => {
                            this.itemIdList.push(workPlanItem.item.id);
                        }
                    );
                    if (this.workPlanResponse.approvalPathResponseModel !== null) {
                        const { type, userApproverList } =
                            this.workPlanResponse.approvalPathResponseModel;
                        this.approverPath.setApproverList(userApproverList).setType(type);
                    }
                }
                this.workPlanItemProductList =
                    this.workPlanResponse.workPlanItemProductList;
                this.workPlanItemServiceList =
                    this.workPlanResponse.workPlanItemServiceList;
                this.setStateReady();
                this.buildTableResponse();
                this.buildTableResponseWorkPlanHistory();
            });
        this.formGroup.get('organization').setIsView(true);
    }

    public get workPlanAddressList(): FormArray {
        return this.formGroup.get('workPlanAddressList') as FormArray;
    }

    public get workPlanItemList(): FormArray {
        return this.formGroup.get('workPlanItemList') as FormArray;
    }

    public buildWorkPlanItem(workPlanItemList: WorkPlanItem[]): void {
        const workPlanItemListForm = this.formGroup.controls
            .workPlanItemList as FormArray;
        workPlanItemList.forEach(workPlanItem => {
            const formGroup = this.formBuilder.group({
                id: workPlanItem.id,
                workPlan: workPlanItem.workPlan,
                item: workPlanItem.item,
                qty: workPlanItem.quantity,
                unitPrice: workPlanItem.unitPrice,
                convertUnitPrice: this.global.converterService.convertMoney(
                    workPlanItem.unitPrice
                ),
                totalPrice: workPlanItem.totalPrice,
                convertTotalPrice: this.global.converterService.convertMoney(
                    workPlanItem.totalPrice
                ),
                specification: workPlanItem.specification,
                crudOperation: [null]
            });
            workPlanItemListForm.push(formGroup);
            this.formGroup.setIsView(this.todo === 'view');
        });
    }

    public buildTableResponse(): void {
        this.tableResponseProduct = new TableResponseModel(this.moduleCode, [
            {
                field: 'item.code',
                header: 'product.table.column.code',
                customClass: 'text-center'
            },
            { field: 'item.name', header: 'product.table.column.name' },
            {
                field: 'item.category.name',
                header: 'product.table.column.category',
                customClass: 'text-center'
            },
            {
                field: 'uom.name',
                header: 'product.table.column.unit',
                customClass: 'text-center'
            },
            {
                field: 'quantity',
                header: 'product.table.column.quantity',
                customClass: 'text-right',
                fn: this.doFormatQuantity.bind(this)
            },
            {
                field: 'unitPrice',
                header: 'product.table.column.price',
                format: FieldFormatEnum.Currency,
                currencyCodeRef: 'currency.code'
            },
            {
                field: 'totalPrice',
                header: 'product.table.column.totalPrice',
                format: FieldFormatEnum.Currency,
                currencyCodeRef: 'currency.code'
            }
        ]);
        this.tableResponseProduct.setRecords(this.workPlanItemProductList);
        this.tableResponseProduct.setTotalRecords(
            this.workPlanItemProductList.length
        );

        this.tableResponseService = new TableResponseModel(this.moduleCode, [
            {
                field: 'item.code',
                header: 'service.table.column.code',
                customClass: 'text-center'
            },
            { field: 'item.name', header: 'service.table.column.name' },
            {
                field: 'item.category.name',
                header: 'service.table.column.category',
                customClass: 'text-center'
            },
            {
                field: 'uom.name',
                header: 'service.table.column.unit',
                customClass: 'text-center'
            },
            {
                field: 'quantity',
                header: 'service.table.column.quantity',
                customClass: 'text-right',
                fn: this.doFormatQuantity.bind(this)
            },
            {
                field: 'unitPrice',
                header: 'service.table.column.price',
                format: FieldFormatEnum.Currency,
                currencyCodeRef: 'currency.code'
            },
            {
                field: 'totalPrice',
                header: 'service.table.column.totalPrice',
                format: FieldFormatEnum.Currency,
                currencyCodeRef: 'currency.code'
            }
        ]);
        this.tableResponseService.setRecords(this.workPlanItemServiceList);
        this.tableResponseService.setTotalRecords(
            this.workPlanItemServiceList.length
        );

        /* Product */
        /* this.tableResponseAddress = new TableResponseModel(this.moduleCode, [
          { field: 'organization.name', header: 'table.column.organization' },
          { field: 'addressBook.address', header: 'table.column.address' },
          {
            field: 'picName',
            header: 'table.column.picName',
            customClass: 'text-center'
          },
          { field: 'picPhone', header: 'table.column.picPhone' },
          { field: 'addressDetail', header: 'table.column.addressDetail' },
          {
            field: 'quantityAddress',
            header: 'table.column.quantity',
            customClass: 'white-space-normal',
            format: FieldFormatEnum.Html
          }
        ]);
        this.tableResponseAddress.setRecords(this.workPlanAddressList.value);
        this.tableResponseAddress.setTotalRecords(this.workPlanAddressList.length);
        this.tableResponseAddress.reloadClient(); */
        this.totalPriceAllItem();
    }

    public doCheckIsMemoMandatory(): boolean {
        this.isMemoMandatory = false;
        this.formGroup.get('approvalMemoDoc').clearValidators();
        this.formGroup.get('approvalMemoDoc').updateValueAndValidity();
        this.workPlanItemProductList.forEach(workPlanItemProduct => {
            if (workPlanItemProduct.crudOperation !== 'Delete') {
                workPlanItemProduct.workPlanItemBudgetList.forEach(
                    workPlanItemBudget => {
                        if (
                            !this.workPlanResponse.organizationIdList.includes(
                                workPlanItemBudget.budgetAllocation.organization.id
                            )
                        ) {
                            this.formGroup
                                .get('approvalMemoDoc')
                                .setValidators(Validators.required());
                            this.formGroup.get('approvalMemoDoc').updateValueAndValidity();
                            this.isMemoMandatory = true;
                            return true;
                        }
                    }
                );
            }
        });
        this.workPlanItemServiceList.forEach(workPlanItemService => {
            if (workPlanItemService.crudOperation !== 'Delete') {
                workPlanItemService.workPlanItemBudgetList.forEach(
                    workPlanItemBudget => {
                        if (
                            !this.workPlanResponse.organizationIdList.includes(
                                workPlanItemBudget.budgetAllocation.organization.id
                            )
                        ) {
                            this.formGroup
                                .get('approvalMemoDoc')
                                .setValidators(Validators.required());
                            this.formGroup.get('approvalMemoDoc').updateValueAndValidity();
                            this.isMemoMandatory = true;
                            return true;
                        }
                    }
                );
            }
        });
        return this.isMemoMandatory;
    }

    public isAlphaNumeric(input: string): boolean {
        const regex = /\w/g;
        return regex.test(input);
    }

    public handleProcMemoNumbInput(event): void {
        const inputValueLength = event.target.value.length;
        const lastInputIsAlphaNumeric = this.isAlphaNumeric(event.data);
        event.target.value = event.target.value.toUpperCase();
        if (event.inputType === 'insertText') {
            if (lastInputIsAlphaNumeric) {
                if (
                    inputValueLength < this.placeholder.length &&
                    !this.isAlphaNumeric(this.placeholder[inputValueLength])
                ) {
                    event.target.value =
                        event.target.value + this.placeholder[inputValueLength];
                }
                this.setInputValue(event);
            } else {
                this.setInputValue(event);
            }
        } else if (event.inputType === 'deleteContentBackward') {
            this.setInputValue(event);
        } else if (event.inputType === 'deleteContentForward') {
            this.setInputValue(event);
        } else if (event.inputType === 'insertFromPaste') {
            this.setInputValue(event);
        }
        this.formGroup.get('number').patchValue(event.target.value);
        const newDataPlaceholder = this.placeholder.slice(
            event.target.value.length,
            this.placeholder.length
        );
        this.placeholder = event.target.value + newDataPlaceholder;
    }

    public setInputValue(event): void {
        if (event.target.value) {
            const procMemoNumb = event.target.value.split('')
                .filter(n => this.isAlphaNumeric(n))
                .join('');

            const symbol = this.placeholder.replace(/[\w]/g, '').split('');
            let arrayProcMemoNumb = procMemoNumb
                .slice(0, this.placeholder.length - symbol.length)
                .replace(/\./g, '')
                .split('');
            for (let i = 0; i < this.placeholder.length; i++) {
                if (i < arrayProcMemoNumb.length) {
                    const isAlphaNumeric = this.isAlphaNumeric(this.placeholder[i]);
                    if (!isAlphaNumeric) {
                        arrayProcMemoNumb = [
                            ...arrayProcMemoNumb.slice(0, i),
                            this.placeholder[i],
                            ...arrayProcMemoNumb.slice(i)
                        ];
                    }
                }
            }

            event.target.value = arrayProcMemoNumb.join().replace(/,/g, '');
        }
    }

    public doClearWorkflow(): void {
        this.formGroup.get('workflow').patchValue(null);
        this.formGroup.get('workflowName').patchValue(null);
    }

    public doAddWorkPlanItemProduct(): void {
        const todo = 'add';
        const header = this.translateService.instant(
            'work-plan.popup.title.addProduct'
        );
        const budgetTypeList = this.workPlanResponse.budgetTypeList;
        const costCenterList = this.workPlanResponse.costCenterList;
        const currencyList = this.workPlanResponse.currencyList;
        const workPlanItemList = this.workPlanResponse.workPlanItemProductList;
        const usedBudgetList = this.usedBudgetList;

        this.appPopupService
            .open(
                AppPopupWorkPlanItemProductComponent,
                {
                    todo,
                    header,
                    budgetTypeList,
                    costCenterList,
                    currencyList,
                    workPlanItemList,
                    usedBudgetList
                },
                { size: 'xl' }
            )
            .subscribe((workPlanItem: WorkPlanItem) => {
                this.doClearWorkflow();
                workPlanItem.crudOperation = 'Insert';
                this.workPlanResponse.workPlanItemProductList.push(workPlanItem);
                this.workPlanItemProductList = [];
                this.itemIdList.push(workPlanItem.item.id);
                this.workPlanResponse.workPlanItemProductList.forEach(workPlanItem => {
                    if (workPlanItem.crudOperation !== 'Delete') {
                        this.workPlanItemProductList.push(workPlanItem);
                    }
                });
                this.doCheckIsMemoMandatory();
                this.tableResponseProduct.setRecords(this.workPlanItemProductList);
                this.tableResponseProduct.setTotalRecords(
                    this.workPlanItemProductList.length
                );
                this.totalPriceAllItem();
                this.tableWorkPlanProduct.reloadClient();

                workPlanItem.workPlanItemBudgetList.forEach(itemBudget => {
                    if (this.usedBudgetList.get(itemBudget.budgetAllocation.id)) {
                        this.usedBudgetList.set(
                            itemBudget.budgetAllocation.id,
                            +itemBudget.updatedBudget +
                            +this.usedBudgetList.get(itemBudget.budgetAllocation.id)
                        );
                    } else {
                        this.usedBudgetList.set(
                            itemBudget.budgetAllocation.id,
                            +itemBudget.updatedBudget
                        );
                    }
                });
            });
    }
    public doEditWorkPlanItemProduct(workplanItemEdit: WorkPlanItem): void {
        const todo = 'edit';
        const header = this.translateService.instant(
            'work-plan.popup.title.editProduct'
        );
        const budgetTypeList = this.workPlanResponse.budgetTypeList;
        const costCenterList = this.workPlanResponse.costCenterList;
        const usedBudgetList = this.usedBudgetList;
        const currencyList = this.workPlanResponse.currencyList;
        const workPlanItemList = this.workPlanResponse.workPlanItemProductList;

        this.appPopupService
            .open(
                AppPopupWorkPlanItemProductComponent,
                {
                    todo,
                    workplanItemEdit,
                    header,
                    budgetTypeList,
                    costCenterList,
                    currencyList,
                    workPlanItemList,
                    usedBudgetList
                },
                { size: 'xl' }
            )
            .subscribe((workPlanItemTemp: WorkPlanItem) => {
                this.doClearWorkflow();
                if (workPlanItemTemp.id) {
                    workPlanItemTemp.crudOperation = 'Update';
                }
                this.workPlanResponse.workPlanItemProductList.forEach(
                    (workPlanItem, index) => {
                        if (workPlanItem === workplanItemEdit) {
                            this.workPlanResponse.workPlanItemProductList[index] =
                                workPlanItemTemp;
                        }
                    }
                );
                this.workPlanItemProductList = [];
                this.workPlanResponse.workPlanItemProductList.forEach(workPlanItem => {
                    if (workPlanItem.crudOperation !== 'Delete') {
                        this.workPlanItemProductList.push(workPlanItem);
                    }
                });
                this.doCheckIsMemoMandatory();
                /* PRODUCT */
                /* this.workPlanAddressList.value.forEach((workPlanAddress, i: number) => {
                  workPlanAddress.workPlanAddressItemList.forEach(
                    (workPlanAddressItem, j: number) => {
                      if (
                        workPlanAddressItem.workPlanItem.item.code ===
                        workplanItemEdit.item.code
                      ) {
                        this.usedItemAddress.set(
                          workPlanItemTemp.item.id,
                          +this.usedItemAddress.get(workPlanItemTemp.item.id) -
                            +workPlanAddressItem.quantity
                        );
                        this.workPlanAddressList.value[i].workPlanAddressItemList[
                          j
                        ].workPlanItem = workPlanItemTemp;
                        this.workPlanAddressList.value[i].workPlanAddressItemList[
                          j
                        ].quantity = 0;
                      }
                    }
                  );
                }); */
                this.tableResponseProduct.setRecords(this.workPlanItemProductList);
                this.tableResponseProduct.setTotalRecords(
                    this.workPlanItemProductList.length
                );
                this.totalPriceAllItem();
                this.tableResponseProduct.reloadClient();

                workplanItemEdit.workPlanItemBudgetList.forEach(itemBudget => {
                    if (this.usedBudgetList.get(itemBudget.budgetAllocation.id)) {
                        this.usedBudgetList.set(
                            itemBudget.budgetAllocation.id,
                            +this.usedBudgetList.get(itemBudget.budgetAllocation.id) +
                            -itemBudget.updatedBudget
                        );
                    } else {
                        this.usedBudgetList.set(
                            itemBudget.budgetAllocation.id,
                            -itemBudget.updatedBudget
                        );
                    }
                });
                workPlanItemTemp.workPlanItemBudgetList.forEach(itemBudget => {
                    if (this.usedBudgetList.get(itemBudget.budgetAllocation.id)) {
                        this.usedBudgetList.set(
                            itemBudget.budgetAllocation.id,
                            +this.usedBudgetList.get(itemBudget.budgetAllocation.id) +
                            +itemBudget.updatedBudget
                        );
                    } else {
                        this.usedBudgetList.set(
                            itemBudget.budgetAllocation.id,
                            +itemBudget.updatedBudget
                        );
                    }
                });
            });
    }

    public doDetailWorkPlanItemProduct(workplanItemEdit: WorkPlanItem): void {
        const todo = 'view';
        const header = this.translateService.instant(
            'work-plan.popup.title.detailProduct'
        );
        const budgetTypeList = this.workPlanResponse.budgetTypeList;
        const costCenterList = this.workPlanResponse.costCenterList;
        this.appPopupService.open(
            AppPopupWorkPlanItemProductComponent,
            {
                todo,
                workplanItemEdit,
                header,
                budgetTypeList,
                costCenterList
            },
            { size: 'xl' }
        );
    }

    public doDeleteWorkPlanItemProduct(
        workPlanItemDelete: WorkPlanItem,
        index: number
    ): void {
        this.global.modalService
            .deleteConfirmation()
            .pipe(take(1))
            .subscribe(result => {
                if (result) {
                    this.doClearWorkflow();
                    const workPlanItem: WorkPlanItem =
                        this.workPlanItemProductList[index];
                    const workPlanItemList: Map<WorkPlanItem, number> = new Map();
                    for (
                        let i = 0;
                        i <= this.workPlanResponse.workPlanItemProductList.length - 1;
                        i++
                    ) {
                        if (
                            this.workPlanResponse.workPlanItemProductList[i].crudOperation !==
                            'Delete'
                        ) {
                            workPlanItemList.set(
                                this.workPlanResponse.workPlanItemProductList[i],
                                i
                            );
                        }
                    }
                    const indexItemProduct = workPlanItemList.get(workPlanItemDelete);
                    !workPlanItem.id
                        ? this.workPlanResponse.workPlanItemProductList.splice(
                            indexItemProduct,
                            1
                        )
                        : (workPlanItem.crudOperation = 'Delete');
                    this.workPlanItemProductList =
                        this.workPlanResponse.workPlanItemProductList.filter(
                            workPlanItem => workPlanItem.crudOperation !== 'Delete'
                        );
                    this.doCheckIsMemoMandatory();
                    this.totalPriceAllItem();
                    this.tableResponseProduct.removeAt(index);
                    workPlanItemDelete.workPlanItemBudgetList.forEach(itemBudget => {
                        if (this.usedBudgetList.get(itemBudget.budgetAllocation.id)) {
                            this.usedBudgetList.set(
                                itemBudget.budgetAllocation.id,
                                +this.usedBudgetList.get(itemBudget.budgetAllocation.id) +
                                -itemBudget.updatedBudget
                            );
                        } else {
                            this.usedBudgetList.set(
                                itemBudget.budgetAllocation.id,
                                -itemBudget.updatedBudget
                            );
                        }
                    });
                }
            });
    }

    public doAddWorkPlanItemService(): void {
        const todo = 'add';
        const header = this.translateService.instant(
            'work-plan.popup.title.addService'
        );
        const budgetTypeList = this.workPlanResponse.budgetTypeList;
        const costCenterList = this.workPlanResponse.costCenterList;
        const currencyList = this.workPlanResponse.currencyList;
        /* Product */
        /* const itemIdList = this.itemIdList; */
        const workPlanItemList = this.workPlanResponse.workPlanItemServiceList;
        const usedBudgetList = this.usedBudgetList;
        this.appPopupService
            .open(
                AppPopupWorkPlanItemServiceComponent,
                {
                    todo,
                    header,
                    budgetTypeList,
                    costCenterList,
                    currencyList,
                    workPlanItemList,
                    usedBudgetList
                },
                { size: 'xl' }
            )
            .subscribe((workPlanItem: WorkPlanItem) => {
                this.doClearWorkflow();
                workPlanItem.crudOperation = 'Insert';
                this.workPlanItemServiceList = [];
                this.workPlanResponse.workPlanItemServiceList.push(workPlanItem);
                this.itemIdList.push(workPlanItem.item.id);
                this.workPlanResponse.workPlanItemServiceList.forEach(workPlanItem => {
                    if (workPlanItem.crudOperation !== 'Delete') {
                        this.workPlanItemServiceList.push(workPlanItem);
                    }
                });
                this.doCheckIsMemoMandatory();
                this.tableResponseService.setRecords(this.workPlanItemServiceList);
                this.tableResponseService.setTotalRecords(
                    this.workPlanItemServiceList.length
                );
                this.totalPriceAllItem();
                this.tableResponseService.reloadClient();

                workPlanItem.workPlanItemBudgetList.forEach(itemBudget => {
                    if (this.usedBudgetList.get(itemBudget.budgetAllocation.id)) {
                        this.usedBudgetList.set(
                            itemBudget.budgetAllocation.id,
                            +itemBudget.updatedBudget +
                            +this.usedBudgetList.get(itemBudget.budgetAllocation.id)
                        );
                    } else {
                        this.usedBudgetList.set(
                            itemBudget.budgetAllocation.id,
                            +itemBudget.updatedBudget
                        );
                    }
                });
            });
    }

    public doEditWorkPlanItemService(workplanItemEdit: WorkPlanItem): void {
        const todo = 'edit';
        const header = this.translateService.instant(
            'work-plan.popup.title.editService'
        );
        const budgetTypeList = this.workPlanResponse.budgetTypeList;
        const costCenterList = this.workPlanResponse.costCenterList;
        const currencyList = this.workPlanResponse.currencyList;
        /* Product */
        /* const itemIdList = this.itemIdList; */
        const workPlanItemList = this.workPlanResponse.workPlanItemServiceList;
        const usedBudgetList = this.usedBudgetList;
        this.appPopupService
            .open(
                AppPopupWorkPlanItemServiceComponent,
                {
                    todo,
                    workplanItemEdit,
                    header,
                    budgetTypeList,
                    costCenterList,
                    currencyList,
                    workPlanItemList,
                    usedBudgetList
                },
                { size: 'xl' }
            )
            .subscribe((workPlanItemTemp: WorkPlanItem) => {
                this.doClearWorkflow();
                if (workPlanItemTemp.id) {
                    workPlanItemTemp.crudOperation = 'Update';
                }
                this.workPlanResponse.workPlanItemServiceList.forEach(
                    (workPlanItem, index) => {
                        if (workPlanItem === workplanItemEdit) {
                            this.workPlanResponse.workPlanItemServiceList[index] =
                                workPlanItemTemp;
                        }
                    }
                );
                this.workPlanItemServiceList = [];
                this.workPlanResponse.workPlanItemServiceList.forEach(workPlanItem => {
                    if (workPlanItem.crudOperation !== 'Delete') {
                        this.workPlanItemServiceList.push(workPlanItem);
                    }
                });
                this.doCheckIsMemoMandatory();
                /* this.workPlanAddressList.value.forEach((workPlanAddress, i) => {
                  workPlanAddress.workPlanAddressItemList.forEach(
                    (workPlanAddressItem, j) => {
                      if (
                        workPlanAddressItem.workPlanItem.item.code ===
                        workplanItemEdit.item.code
                      ) {
                        this.usedItemAddress.set(
                          workPlanItemTemp.item.id,
                          +this.usedItemAddress.get(workPlanItemTemp.item.id) -
                            +workPlanAddressItem.quantity
                        );
                        this.workPlanAddressList.value[i].workPlanAddressItemList[
                          j
                        ].workPlanItem = workPlanItemTemp;
                        this.workPlanAddressList.value[i].workPlanAddressItemList[
                          j
                        ].quantity = 0;
                      }
                    }
                  );
                }); */
                this.tableResponseService.setRecords(this.workPlanItemServiceList);
                this.tableResponseService.setTotalRecords(
                    this.workPlanItemServiceList.length
                );
                this.totalPriceAllItem();
                this.tableResponseService.reloadClient();

                workplanItemEdit.workPlanItemBudgetList.forEach(itemBudget => {
                    if (this.usedBudgetList.get(itemBudget.budgetAllocation.id)) {
                        this.usedBudgetList.set(
                            itemBudget.budgetAllocation.id,
                            +this.usedBudgetList.get(itemBudget.budgetAllocation.id) +
                            -itemBudget.updatedBudget
                        );
                    } else {
                        this.usedBudgetList.set(
                            itemBudget.budgetAllocation.id,
                            -itemBudget.updatedBudget
                        );
                    }
                });
                workPlanItemTemp.workPlanItemBudgetList.forEach(itemBudget => {
                    if (this.usedBudgetList.get(itemBudget.budgetAllocation.id)) {
                        this.usedBudgetList.set(
                            itemBudget.budgetAllocation.id,
                            +this.usedBudgetList.get(itemBudget.budgetAllocation.id) +
                            +itemBudget.updatedBudget
                        );
                    } else {
                        this.usedBudgetList.set(
                            itemBudget.budgetAllocation.id,
                            +itemBudget.updatedBudget
                        );
                    }
                });
            });
    }

    public doDetailWorkPlanItemService(workplanItemEdit: WorkPlanItem): void {
        const todo = 'view';
        const header = this.translateService.instant(
            'work-plan.popup.title.detailService'
        );
        const budgetTypeList = this.workPlanResponse.budgetTypeList;
        const costCenterList = this.workPlanResponse.costCenterList;
        this.appPopupService.open(
            AppPopupWorkPlanItemServiceComponent,
            {
                todo,
                workplanItemEdit,
                header,
                budgetTypeList,
                costCenterList
            },
            { size: 'xl' }
        );
    }

    public doDeleteWorkPlanItemService(
        workPlanItemDelete: WorkPlanItem,
        index: number
    ): void {
        this.global.modalService
            .deleteConfirmation()
            .pipe(take(1))
            .subscribe(result => {
                if (result) {
                    this.doClearWorkflow();
                    const workPlanItem: WorkPlanItem =
                        this.workPlanItemServiceList[index];
                    const workPlanItemList: Map<WorkPlanItem, number> = new Map();
                    for (
                        let i = 0;
                        i <= this.workPlanResponse.workPlanItemServiceList.length - 1;
                        i++
                    ) {
                        if (
                            this.workPlanResponse.workPlanItemServiceList[i].crudOperation !==
                            'Delete'
                        ) {
                            workPlanItemList.set(
                                this.workPlanResponse.workPlanItemServiceList[i],
                                i
                            );
                        }
                    }
                    const indexItemProduct = workPlanItemList.get(workPlanItemDelete);
                    !workPlanItem.id
                        ? this.workPlanResponse.workPlanItemServiceList.splice(
                            indexItemProduct,
                            1
                        )
                        : (workPlanItem.crudOperation = 'Delete');
                    this.workPlanItemServiceList =
                        this.workPlanResponse.workPlanItemServiceList.filter(
                            workPlanItem => workPlanItem.crudOperation !== 'Delete'
                        );
                    this.doCheckIsMemoMandatory();
                    this.totalPriceAllItem();
                    this.tableResponseService.removeAt(index);

                    workPlanItemDelete.workPlanItemBudgetList.forEach(itemBudget => {
                        if (this.usedBudgetList.get(itemBudget.budgetAllocation.id)) {
                            this.usedBudgetList.set(
                                itemBudget.budgetAllocation.id,
                                +this.usedBudgetList.get(itemBudget.budgetAllocation.id) +
                                -itemBudget.updatedBudget
                            );
                        } else {
                            this.usedBudgetList.set(
                                itemBudget.budgetAllocation.id,
                                -itemBudget.updatedBudget
                            );
                        }
                    });
                }
            });
    }

    public doAddWorkPlanLocation(): void {
        const todo = 'add';
        const header = this.translateService.instant(
            'work-plan.popup.title.addLocation'
        );
        const addressBookList = this.workPlanResponse.addressBookList;
        const organizationList = this.workPlanResponse.organizationList;
        this.doSetWorkPlanItem();
        const workPlanItemList = this.workPlanItemList.value;
        const usedItemAddress = this.usedItemAddress;
        this.appPopupService
            .open(AppPopupWorkPlanLocationComponent, {
                todo,
                header,
                organizationList,
                addressBookList,
                workPlanItemList,
                usedItemAddress
            })
            .subscribe(workPlanAddress => {
                let quantityAddress = '';
                workPlanAddress.workPlanAddressItemList.forEach(workPlanAddressItem => {
                    if (workPlanAddressItem.quantity) {
                        quantityAddress +=
                            workPlanAddressItem.workPlanItem.item.name +
                            ' - ' +
                            workPlanAddressItem.quantity +
                            ' ' +
                            workPlanAddressItem.workPlanItem.uom.name +
                            '<br/>';
                    }
                });
                const organizationLists = [];
                organizationLists.push(workPlanAddress.organizationList);
                const workPlanAddressItemLists = [];
                workPlanAddressItemLists.push(workPlanAddress.workPlanAddressItemList);
                this.workPlanAddressList.push(
                    this.formBuilder.group({
                        organizationList: organizationLists,
                        organization: workPlanAddress.organization,
                        addressBook: workPlanAddress.addressBook,
                        picName: workPlanAddress.picName,
                        picPhone: workPlanAddress.picPhone,
                        addressDetail: workPlanAddress.addressDetail,
                        quantityAddress,
                        workPlanAddressItemList: workPlanAddressItemLists
                    })
                );
                this.tableResponseAddress.setRecords(this.workPlanAddressList.value);
                this.tableResponseAddress.setTotalRecords(
                    this.workPlanAddressList.length
                );
                this.tableResponseAddress.reloadClient();

                workPlanAddress.workPlanAddressItemList.forEach(itemAddress => {
                    if (this.usedItemAddress.get(itemAddress.workPlanItem.item.id)) {
                        this.usedItemAddress.set(
                            itemAddress.workPlanItem.item.id,
                            +itemAddress.quantity +
                            +this.usedItemAddress.get(itemAddress.workPlanItem.item.id)
                        );
                    } else {
                        this.usedItemAddress.set(
                            itemAddress.workPlanItem.item.id,
                            +itemAddress.quantity
                        );
                    }
                });
            });
    }
    public doEditWorkPlanLocation(workPlanAddressEdit: any): void {
        const todo = 'edit';
        const header = this.translateService.instant(
            'work-plan.popup.title.editLocation'
        );
        const addressBookList = this.workPlanResponse.addressBookList;
        const organizationList = this.workPlanResponse.organizationList;
        this.doSetWorkPlanItem();
        const workPlanItemList = this.workPlanItemList.value;
        const usedItemAddress = this.usedItemAddress;
        this.appPopupService
            .open(AppPopupWorkPlanLocationComponent, {
                todo,
                header,
                workPlanAddressEdit,
                addressBookList,
                organizationList,
                workPlanItemList,
                usedItemAddress
            })
            .subscribe(workplanAddressTemp => {
                this.workPlanAddressList.value.forEach(
                    (workPlanAddress: any, index: number) => {
                        if (
                            workPlanAddress === workPlanAddressEdit ||
                            workPlanAddress.addressBook === workPlanAddressEdit.addressBook
                        ) {
                            this.workPlanAddressList.value[index] = workplanAddressTemp;
                            this.workPlanAddressList.controls[index].patchValue(
                                workplanAddressTemp
                            );
                        }
                    }
                );
                this.tableResponseAddress.setRecords(this.workPlanAddressList.value);
                this.tableResponseAddress.setTotalRecords(
                    this.workPlanAddressList.value.length
                );
                this.tableResponseAddress.reloadClient();

                workPlanAddressEdit.workPlanAddressItemList.forEach(itemAddress => {
                    if (this.usedItemAddress.get(itemAddress.workPlanItem.item.id)) {
                        this.usedItemAddress.set(
                            itemAddress.workPlanItem.item.id,
                            +this.usedItemAddress.get(itemAddress.workPlanItem.item.id) +
                            -itemAddress.quantity
                        );
                    } else {
                        this.usedBudgetList.set(
                            itemAddress.workPlanItem.item.id,
                            -itemAddress.quantity
                        );
                    }
                });
                workplanAddressTemp.workPlanAddressItemList.forEach(itemAddress => {
                    if (this.usedItemAddress.get(itemAddress.workPlanItem.item.id)) {
                        this.usedItemAddress.set(
                            itemAddress.workPlanItem.item.id,
                            +this.usedItemAddress.get(itemAddress.workPlanItem.item.id) +
                            +itemAddress.quantity
                        );
                    } else {
                        this.usedItemAddress.set(
                            itemAddress.workPlanItem.item.id,
                            +itemAddress.quantity
                        );
                    }
                });
            });
    }

    public doDeleteWorkPlanLocation(workPlanAddressDelete: any): void {
        this.global.modalService
            .deleteConfirmation()
            .pipe(take(1))
            .subscribe(result => {
                if (result) {
                    this.workPlanAddressList.value.forEach(
                        (workPlanAddress: any, index: number) => {
                            if (workPlanAddress === workPlanAddressDelete) {
                                this.workPlanAddressList.value.splice(index, 1);
                                this.workPlanAddressList.removeAt(index);
                            }
                        }
                    );
                    this.tableResponseAddress.setRecords(this.workPlanAddressList.value);
                    this.tableResponseAddress.setTotalRecords(
                        this.workPlanAddressList.length
                    );
                    this.tableResponseAddress.reloadClient();

                    workPlanAddressDelete.workPlanAddressItemList.forEach(itemAddress => {
                        if (this.usedItemAddress.get(itemAddress.workPlanItem.item.id)) {
                            this.usedItemAddress.set(
                                itemAddress.workPlanItem.item.id,
                                +this.usedItemAddress.get(itemAddress.workPlanItem.item.id) +
                                -itemAddress.quantity
                            );
                        } else {
                            this.usedItemAddress.set(
                                itemAddress.workPlanItem.item.id,
                                -itemAddress.quantity
                            );
                        }
                    });
                }
            });
    }

    public totalPriceAllItem(): void {
        this.doSetWorkPlanItem();
        let totalProductCost = 0;
        this.workPlanResponse.workPlanItemProductList.forEach(workPlanItem => {
            if (workPlanItem.crudOperation !== 'Delete') {
                totalProductCost = totalProductCost + +workPlanItem.totalPrice;
            }
        });
        this.totalProductCost = totalProductCost;

        let totalServiceCost = 0;
        this.workPlanResponse.workPlanItemServiceList.forEach(workPlanItem => {
            if (workPlanItem.crudOperation !== 'Delete') {
                totalServiceCost = totalServiceCost + +workPlanItem.totalPrice;
            }
        });
        this.totalServiceCost = totalServiceCost;

        const totalPriceAllItem = +totalProductCost + +totalServiceCost;
        this.totalPriceOfAllItem = totalPriceAllItem;
        this.convertTotalPriceOfAllItem = this.global.converterService.convertMoney(
            totalPriceAllItem.toString()
        );
    }

    public chooseWorkflow(event): void {
        this.totalPriceAllItem();
        event.srcElement.blur();
        event.preventDefault();
        if (this.formGroup.value.organization === null) {
            this.global.alertService.showError(
                this.translateService.instant('work-plan.alert.organizationEmpty')
            );
        } else if (!this.totalPriceOfAllItem) {
            this.global.alertService.showError(
                this.translateService.instant(
                    'work-plan.alert.totalPriceOfAllItemEmpty'
                )
            );
        } else if (this.formGroup.value.procurementType === null) {
            this.global.alertService.showError(
                this.translateService.instant('work-plan.alert.procurementTypeEmpty')
            );
        } else {
            this.dataMap['MODULE'] = this.workPlanResponse.moduleCode;
            this.dataMap['BUDGET_VALUE'] = this.totalPriceOfAllItem;
            this.dataMap['PROCUREMENT_TYPE'] = this.formGroup
                .get('procurementType')
                .value.id.toString();
            this.appPopupWorkflowService
                .open('/app-popup-workflow/index', this.dataMap)
                .subscribe(workflow => {
                    this.formGroup.patchValue({
                        workflow,
                        workflowName: workflow.name
                    });
                });
        }
    }

    public doSetWorkPlanItem(): void {
        this.workPlanItemList.controls = [];
        this.workPlanResponse.workPlanItemProductList.forEach(workPlanItem => {
            const workPlanItemBudgetList = [];
            workPlanItemBudgetList.push(workPlanItem.workPlanItemBudgetList);
            this.workPlanItemList.push(
                this.formBuilder.group({
                    id: workPlanItem.id,
                    item: workPlanItem.item,
                    uom: workPlanItem.uom,
                    quantity: workPlanItem.quantity,
                    unitPrice: workPlanItem.unitPrice,
                    currency: workPlanItem.currency,
                    totalPrice: workPlanItem.totalPrice,
                    specification: workPlanItem.specification,
                    crudOperation: workPlanItem.crudOperation,
                    workPlanItemBudgetList
                })
            );
        });
        this.workPlanResponse.workPlanItemServiceList.forEach(workPlanItem => {
            const workPlanItemBudgetList = [];
            workPlanItemBudgetList.push(workPlanItem.workPlanItemBudgetList);
            this.workPlanItemList.push(
                this.formBuilder.group({
                    id: workPlanItem.id,
                    item: workPlanItem.item,
                    uom: workPlanItem.uom,
                    quantity: workPlanItem.quantity,
                    unitPrice: workPlanItem.unitPrice,
                    currency: workPlanItem.currency,
                    totalPrice: workPlanItem.totalPrice,
                    specification: workPlanItem.specification,
                    crudOperation: workPlanItem.crudOperation,
                    workPlanItemBudgetList
                })
            );
        });
    }

    public validateQuantity(): string {
        this.doSetWorkPlanItem();
        const workPlanItemListForm = this.workPlanItemList;
        const workPlanAddressListForm = this.workPlanAddressList;
        let totalQuantity = 0;
        for (const workPlanItem of workPlanItemListForm.value) {
            for (const workPlanAddress of workPlanAddressListForm.value) {
                for (const workPlanAddressItem of workPlanAddress.workPlanAddressItemList) {
                    if (
                        workPlanItem.item.code ===
                        workPlanAddressItem.workPlanItem.item.code
                    ) {
                        if (!workPlanAddressItem.quantity) {
                            workPlanAddressItem.quantity = null;
                        }
                        totalQuantity =
                            totalQuantity + JSON.parse(workPlanAddressItem.quantity);
                    }
                }
            }

            if (+workPlanItem.quantity !== totalQuantity) {
                return workPlanItem.item.name;
            }
            totalQuantity = 0;
        }
    }

    public doSave(): void {
        this.doSetWorkPlanItem();
        this.validate();
        if (this.formGroup.valid) {
            /* PRODUCT */
            /* const validateQuantity = this.validateQuantity();
            if (validateQuantity !== undefined) {
              this.global.alertService.showError(
                validateQuantity +
                  ' ' +
                  this.translateService.instant('work-plan.alert.quantityNotFit')
              );
            } else { */
            if (this.formGroup.value.workflow === null) {
                this.global.alertService.showError(
                    this.translateService.instant('work-plan.alert.workflowEmpty')
                );
            } else {
                this.global.modalService
                    .saveConfirmation()
                    .pipe(take(1))
                    .subscribe(result => {
                        if (result) {
                            this.setStateProcessing();
                            this.workPlan = this.global.copyFormAttributeToModel(
                                this.workPlan,
                                this.formGroup
                            );
                            if (this.todo === 'edit') {
                                this.url = '/work-plan/update';
                            } else {
                                this.url = '/work-plan/insert';
                            }
                            if (this.fileUploader.fileObjectList !== null) {
                                this.workPlanRequest.fileObjectList =
                                    this.fileUploader.fileObjectList;
                            }
                            if (this.approvalMemoDocUploader.fileObjectList !== null) {
                                this.workPlanRequest.approvalMemoDocObjectList =
                                    this.approvalMemoDocUploader.fileObjectList;
                            }
                            this.workPlan.generateMemoNumber =
                                this.workPlanResponse.workPlan.generateMemoNumber;
                            this.workPlanRequest.workPlan = this.workPlan;
                            this.workPlanRequest.workPlanItemList =
                                this.formGroup.get('workPlanItemList').value;
                            /* this.workPlanRequest.workPlanAddressList = this.formGroup.get(
                              'workPlanAddressList'
                            ).value;
                            this.workPlanRequest.workPlanAddressList.forEach(
                              (workPlanAddress: WorkPlanAddress) => {
                                workPlanAddress.workPlanAddressItemList.forEach(
                                  (
                                    workPlanAddressItem: WorkPlanAddressItem,
                                    index: number
                                  ) => {
                                    if (!workPlanAddressItem.quantity) {
                                      workPlanAddress.workPlanAddressItemList.splice(
                                        index,
                                        1
                                      );
                                    }
                                  }
                                );
                              }
                            ); */
                            const organizationList: Organization[] =
                                this.formGroup.get('organization').value;
                            this.workPlanRequest.workPlan.organization =
                                organizationList[organizationList.length - 1];
                            this.workPlanRequest.isSaveDraft = false;
                            this.httpClientService
                                .post<Response<WorkPlan>>(this.url, this.workPlanRequest)
                                .subscribe(response => {
                                    if (response.status === ResponseStatusModel.OK) {
                                        this.global.alertService.showSuccessSavingOnNextRoute();
                                        this.workPlan = response.body;
                                        this.global.routerParams.clear();
                                        /* BJB */
                                        this.global.routerParams.set('workPlan', this.workPlan);
                                        this.global.routerParams.set(
                                            'workPlanId',
                                            this.workPlan.id
                                        );
                                        this.global.routerParams.set('todo', 'add');
                                        this.global.routerParams.set(
                                            'urlBackOutside',
                                            '/pages/worklist-pra-procurement'
                                        );
                                        if (
                                            !this.workPlan.prcsCode.includes('APPROVAL-') &&
                                            !this.workPlan.prcsCode.includes('WORK-PLAN')
                                        ) {
                                            this.router.navigate([
                                                '/pages/' +
                                                this.workPlan.prcsCode.toLowerCase() +
                                                '/add'
                                            ]);
                                        } else {
                                            this.router.navigate(['/pages/work-plan/']);
                                        }
                                        /* PRODUCT */
                                        /* this.router.navigate(['/pages/work-plan/']); */
                                    } else {
                                        this.global.alertService.showError(response.statusText);
                                    }
                                    this.setStateReady();
                                });
                        }
                    });
            }
            /* } */
        }
    }

    public doSetValidator(): void {
        this.formGroup.get('name').setValidators(Validators.compose([Validators.required(), Validators.maxLength(128)]));
        this.formGroup.get('createdDate').setValidators(Validators.required());
        this.formGroup.get('workTarget').setValidators(Validators.compose([Validators.required(), Validators.min('createdDate')]));
        this.formGroup.get('description').setValidators(Validators.compose([Validators.required(), Validators.maxLength(512)]));
        this.formGroup.get('procurementType').setValidators(Validators.required());
        this.formGroup.get('toc').setValidators(Validators.compose([Validators.required(), Validators.maxLength(512)]));
        this.updateValueAndValidity();
        this.doCheckIsMemoMandatory();
    }

    public doRemoveValidator(): void {
        this.formGroup.get('name').clearValidators();
        this.formGroup.get('createdDate').clearValidators();
        this.formGroup.get('workTarget').clearValidators();
        this.formGroup.get('description').clearValidators();
        this.formGroup.get('procurementType').clearValidators();
        this.formGroup.get('toc').clearValidators();
        /* this.formGroup.get('file').clearValidators(); */
        this.formGroup.get('approvalMemoDoc').clearValidators();
        this.updateValueAndValidity();
    }

    public updateValueAndValidity(): void {
        this.formGroup.get('name').updateValueAndValidity();
        this.formGroup.get('createdDate').updateValueAndValidity();
        this.formGroup.get('workTarget').updateValueAndValidity();
        this.formGroup.get('description').updateValueAndValidity();
        this.formGroup.get('procurementType').updateValueAndValidity();
        this.formGroup.get('toc').updateValueAndValidity();
        /* this.formGroup.get('file').updateValueAndValidity(); */
        this.formGroup.get('approvalMemoDoc').updateValueAndValidity();
    }

    public checkSaveDraftValidation(): boolean {
        let value = false;
        const organizationList: Organization[] = this.formGroup.get('organization').value;
        if ((this.formGroup.get('number').value &&
            this.formGroup.get('number').valid &&
            organizationList !== null &&
            organizationList.length > 0)) {
            value = true;
        }
        if (this.formGroup.get('name').value) {
            this.formGroup.get('name').markAsTouched();
            if (value && this.formGroup.get('name').invalid) {
                value = false;
            }
        }
        if (this.formGroup.get('description').value) {
            this.formGroup.get('description').markAsTouched();
            if (value && this.formGroup.get('description').invalid) {
                value = false;
            }
        }
        if (this.formGroup.get('toc').value) {
            this.formGroup.get('toc').markAsTouched();
            if (value && this.formGroup.get('toc').invalid) {
                value = false;
            }
        }
        if (this.formGroup.get('workTarget').value) {
            this.formGroup.get('workTarget').markAsTouched();
            if (value && this.formGroup.get('workTarget').invalid) {
                value = false;
            }
        }
        return value;
    }

    public doSaveDraft(): void {
        this.doSetWorkPlanItem();
        this.formGroup.get('number').markAsTouched();
        this.formGroup.get('organization').markAsTouched();
        if (
            this.formGroup.valid ||
            this.checkSaveDraftValidation()
        ) {
            this.doRemoveValidator();
            this.validate();
            this.global.modalService
                .saveConfirmation()
                .pipe(take(1))
                .subscribe(result => {
                    if (result) {
                        this.setStateProcessing();
                        this.workPlan = this.global.copyFormAttributeToModel(
                            this.workPlan,
                            this.formGroup
                        );
                        if (this.todo === 'edit') {
                            this.url = '/work-plan/update';
                        } else {
                            this.url = '/work-plan/insert';
                        }

                        if (this.fileUploader.fileObjectList !== null) {
                            this.workPlanRequest.fileObjectList =
                                this.fileUploader.fileObjectList;
                        }
                        if (this.approvalMemoDocUploader.fileObjectList !== null) {
                            this.workPlanRequest.approvalMemoDocObjectList =
                                this.approvalMemoDocUploader.fileObjectList;
                        }
                        this.workPlan.generateMemoNumber =
                            this.workPlanResponse.workPlan.generateMemoNumber;
                        this.workPlanRequest.workPlan = this.workPlan;
                        this.workPlanRequest.workPlan.workflow = null;
                        this.workPlanRequest.workPlanItemList =
                            this.formGroup.get('workPlanItemList').value;
                        /* PRODUCT */
                        /* this.workPlanRequest.workPlanAddressList = this.formGroup.get(
                          'workPlanAddressList'
                        ).value;
                        if (this.workPlanRequest.workPlanAddressList) {
                          this.workPlanRequest.workPlanAddressList.forEach(
                            (workPlanAddress: WorkPlanAddress) => {
                              workPlanAddress.workPlanAddressItemList.forEach(
                                (
                                  workPlanAddressItem: WorkPlanAddressItem,
                                  index: number
                                ) => {
                                  if (!workPlanAddressItem.quantity) {
                                    workPlanAddress.workPlanAddressItemList.splice(
                                      index,
                                      1
                                    );
                                  }
                                }
                              );
                            }
                          );
                        } */
                        const organizationList: Organization[] =
                            this.formGroup.get('organization').value;
                        if (organizationList !== null && organizationList.length > 0) {
                            this.workPlanRequest.workPlan.organization =
                                organizationList[organizationList.length - 1];
                        }

                        this.workPlanRequest.isSaveDraft = true;
                        this.httpClientService
                            .post<Response<WorkPlan>>(this.url, this.workPlanRequest)
                            .subscribe(response => {
                                if (response.status === ResponseStatusModel.OK) {
                                    this.global.alertService.showSuccessSavingOnNextRoute();
                                    this.router.navigate(['/pages/work-plan/']);
                                } else {
                                    this.global.alertService.showError(response.statusText);
                                }
                                this.setStateReady();
                            });
                    } else {
                        this.doSetValidator();
                    }
                });
        }
    }

    public doCancel(): void {
        this.router.navigate([
            this.urlBack === undefined ? '/pages/work-plan/' : this.urlBack
        ]);
    }
}
