import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ContractType } from '../../core/bean/contract-type';
import { DealingAgreementType } from '../../core/bean/dealing-agreement-type';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './dealing-agreement-type-edit-add.component.html'
})
export class DealingAgreementTypeEditAddComponent extends BaseModuleComponent {
  public dealingAgreementTypeId: number;
  public contractTypeOptionList: OptionListModel<ContractType> = new OptionListModel(
    true
  );
  constructor(translateService: TranslateService) {
    super('dealing-agreement-type', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.dealingAgreementTypeId = this.global.routerParams.get(
      'dealingAgreementTypeId'
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [
        null,
        {
          validators: [Validators.required(), Validators.maxLength(128)]
        }
      ],
      code: [
        null,
        {
          validators: [Validators.required(), Validators.maxLength(32)],
          asyncValidators: [
            Validators.existsAsync(
              '/dealing-agreement-type/check-exist',
              null,
              null,
              { id: this.dealingAgreementTypeId }
            )
          ]
        }
      ],
      contractType: [null, Validators.required()],
      description: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<DealingAgreementType>(
        '/dealing-agreement-type/add-edit',
        new RouteRequestModel(this.todo, this.dealingAgreementTypeId)
      )
      .subscribe(dealingAgreementTypeResponse => {
        this.contractTypeOptionList.setRequestValues(
          dealingAgreementTypeResponse.contractTypeList
        );
        if (dealingAgreementTypeResponse.dealingAgreementType != null) {
          this.formGroup.patchValue(
            dealingAgreementTypeResponse.dealingAgreementType
          );
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const dealingAgreementType = this.global.copyFormAttributeToModel(
              new DealingAgreementType(),
              this.formGroup
            );
            const url =
              this.todo === 'edit'
                ? '/dealing-agreement-type/update'
                : '/dealing-agreement-type/insert';
            this.httpClientService
              .post<Response<DealingAgreementType>>(url, dealingAgreementType)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/dealing-agreement-type']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/dealing-agreement-type']);
  }
}
