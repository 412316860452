import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorLocation } from '../../core/bean/vendor-location';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './vendor-location-edit-add.component.html'
})
export class VendorLocationEditAddComponent extends BaseModuleComponent {
  public vendorLocationId: number;
  constructor(translateService: TranslateService) {
    super('vendor-location', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorLocationId = this.global.routerParams.get('vendorLocationId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      description: [null, Validators.maxLength(512)]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<VendorLocation>(
        '/vendor-location/add-edit',
        new RouteRequestModel(this.todo, this.vendorLocationId)
      )
      .subscribe(vendorLocation => {
        if (vendorLocation != null) {
          const { id, name, code, description } = vendorLocation;
          this.formGroup.patchValue({ id, name, code, description });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const vendorLocation: VendorLocation = this.global.copyFormAttributeToModel(
              new VendorLocation(),
              this.formGroup
            );
            const url: string =
              this.todo === 'edit'
                ? '/vendor-location/update'
                : '/vendor-location/insert';
            this.httpClientService
              .post<Response<VendorLocation>>(url, vendorLocation)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/vendor-location/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/vendor-location/']);
  }

  public get formGroupControls() {
    return this.formGroup.controls;
  }
}
