/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-input-dialog',
  templateUrl: './app-modal-input-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppModalInputDialogComponent {
  title: string;
  message: string;
  input = new FormControl('', Validators.required);
  set initialValue(value: string) {
    this.input.setValue(value);
  }
}
