import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppBadgeModule } from '../../components/app-badge/app-badge.module';
import { AppWidgetContentSliderModule } from '../app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetRfqStatusComponent } from './app-widget-rfq-status.component';
@NgModule({
  imports: [
    VendorModule,
    AppWidgetModule,
    AppWidgetContentSliderModule,
    AppBadgeModule
  ],
  declarations: [AppWidgetRfqStatusComponent],
  entryComponents: [AppWidgetRfqStatusComponent],
  exports: [AppWidgetRfqStatusComponent]
})
export class AppWidgetRfqStatusModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      'app-widget-rfq-status',
      AppWidgetRfqStatusComponent
    );
  }
}
