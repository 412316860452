import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Vendor } from '../../core/bean/vendor';
import { RouteRequestModel } from '../../core/model/route-request-model';

@Component({
  templateUrl: './vendor-list-detail.component.html'
})
export class VendorListDetailComponent extends BaseModuleComponent {
  public vendorId: number;
  public vendor: Vendor = new Vendor();

  constructor(translateService: TranslateService) {
    super('vendor-list', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
    this.setVendorListDetailIsView();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorId = this.global.routerParams.get('vendorId');
  }

  public setVendorListDetailIsView(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorType: [null],
      vendorEntity: [null],
      name: [null],
      user: [null],
      email: [null],
      phone: [null],
      organization: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService.post<Vendor>('/vendor-list/detail', new RouteRequestModel(this.todo, this.vendorId)).subscribe(vendor => {
      this.vendor = vendor;
      const phone = this.vendor.phone;

      this.formGroup.patchValue({
        phone: { country: this.vendor.phoneRegion, number: phone }
      });

      this.setStateReady();
    });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/vendor-list']);
  }
}
