import { NavigationRouterModel } from './navigation-router-model';

export class NavigationHistoryModel {
  navigationRouterList: Array<NavigationRouterModel> = new Array();
  constructor() {}
  public get currentNavigation(): NavigationRouterModel {
    return this.navigationRouterList[this.navigationRouterList.length - 1];
  }

  public addNavigationRouter(navigationRouter: NavigationRouterModel): void {
    navigationRouter.previousNavigation =
      this.navigationRouterList[this.navigationRouterList.length - 1];
    this.navigationRouterList.push(navigationRouter);
  }

  public removeLastNavigation(): void {
    this.navigationRouterList.pop();
  }
}
