import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './app-popup-widget-total-payment-bills.component.html'
})
export class AppPopupWidgetTotalPaymentBillsComponent extends BaseComponentComponent {
  public paymentBillingOptionList: OptionListModel<any> = new OptionListModel(
    false,
    'stageOfWork.name'
  );
  public paymentBillingList: PaymentBilling[] = [];
  public paymentBilling: PaymentBilling;
  @Input() paymentBillingId: number;
  @Output() onChange: EventEmitter<PaymentBilling> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {
    super('dashboard-widget-total-bills');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      paymentBilling: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<PaymentBilling[]>(
        '/widget-total-bills/payment-billing-list/' + this.paymentBillingId
      )
      .subscribe(paymentBillingList => {
        this.paymentBillingOptionList.setRequestValues(paymentBillingList);

        this.setStateReady();
      });
  }

  doSubmit(): void {
    this.paymentBilling = this.formGroup.get('paymentBilling').value;
    if (this.paymentBilling) {
      this.global.routerParams.set(
        'sowPaymentTermId',
        this.paymentBilling.sowPaymentTerm.id
      );
      this.router.navigate(['/pages/payment-monitoring']);
      this.onChange.emit(this.paymentBilling);
    } else {
      this.global.alertService.showError(
        'widget-total-bills.validation.chooseOne',
        '.popup-total-bills'
      );
    }
  }
}
