import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Global } from '../../../global/global';
import { ResolveImgSrc } from '../../../pipe/resolve-img-src.pipe';
import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';
export const AppTableImgPlugin: TablePlugin = {
  name: 'img',
  before: (data: TablePluginData): void => {
    const global = data.state.injector.get(Global);
    const imageUrl =
      global.config.BACKEND_ADDRESS +
      data.column.urlImage +
      '/file/view/' +
      data.value +
      '/' +
      data.column.fileTypeCode;

    const imageElement = document.createElement('img');
    imageElement.classList.add('img-thumbnail');
    imageElement.style.width = '100px';
    data.element.appendChild(imageElement);
    const resolveImgSrc = data.state.injector.get(ResolveImgSrc);
    const domSanitizer = data.state.injector.get(DomSanitizer);
    resolveImgSrc.transform(imageUrl).then(imgSrc => {
      imageElement.src = domSanitizer.sanitize(SecurityContext.URL, imgSrc);
    });
  }
};
