import { Component, Input } from '@angular/core';
import { ActiveToast } from './interface/active-toast';
@Component({
  templateUrl: './app-toast.component.html',
  styleUrls: ['./app-toast.component.scss']
})
export class AppToastComponent {
  @Input() public activeToast: ActiveToast;
  public toastIconObject = {
    INFO: 'fa-info-circle',
    SUCCESS: 'fa-check-circle',
    WARNING: 'fa-exclamation-circle',
    DANGER: 'fa-exclamation-triangle'
  };

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
