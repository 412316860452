import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProcurementMethod } from '../../../../../core/bean/procurement-method';
import { ChartModel } from '../../../../../core/components/app-chart/models/chart-model';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetTotalProcurementResponse } from './widget-total-procurement-response';

@Component({
  templateUrl: './widget-total-procurement.component.html'
})
export class WidgetTotalProcurementComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-total-procurement';
  public percentage: string;
  public fraction: string;
  public chartModel: ChartModel;
  public colorList = [
    '--primary-dark',
    '--warning-light',
    '--primary',
    '--good',
    '--warning',
    '--feedback'
  ];
  public legendList: Array<any>;
  constructor(public translateService: TranslateService) {
    super(WidgetTotalProcurementComponent.moduleCode);
  }

  onInit(): void {
    this.setInitializationState();
  }

  private setInitializationState(): void {
    const colorList = this.colorList.map(color =>
      getComputedStyle(document.body).getPropertyValue(color)
    );
    this.httpClientService
      .get<WidgetTotalProcurementResponse>('/widget-total-procurement/index')
      .subscribe((response: WidgetTotalProcurementResponse) => {
        this.percentage = response.totalAllProcurement
          ? this.global.converterService.convertDecimal(
              (response.totalDone / response.totalAllProcurement) * 100
            )
          : '0';
        this.fraction =
          response.totalDone + ' / ' + response.totalAllProcurement;
        this.legendList = response.widgetTotalProcurementModelList.map(
          (data, index) => {
            return {
              percentage: data.totalAll
                ? `${this.global.converterService.convertDecimal(
                    (data.totalFinish / data.totalAll) * 100
                  )}%`
                : '0%',
              total: data.totalAll
                ? Math.round((data.totalFinish / data.totalAll) * 100)
                : 0,
              fraction: data.totalFinish + ' / ' + data.totalAll,
              label: this.translateService.instant(
                this.getTranslateKey(data.procurementMethod)
              ),
              color: colorList[index]
            };
          }
        );
        const label = this.legendList.map(val => val.label);
        const dataSets = this.legendList.map(val => val.total);
        this.chartModel = new ChartModel('doughnut', label, dataSets, label, {
          defaultColor: colorList,
          legend: {
            display: false
          },
          elements: {
            arc: {
              borderWidth: 0
            }
          },
          tooltips: {
            bodyFontStyle: 'font-weight: 700',
            bodySpacing: 10,
            footerFontStyle: 'font-weight: 400',
            callbacks: {
              labelTextColor: () => 'transparent',
              footer: (
                item: Chart.ChartTooltipItem[],
                data: Chart.ChartData
              ): string => {
                const datasetIndex = item[0].index;
                return `${this.legendList[item[0].index].percentage} ${
                  data.labels[datasetIndex]
                }`;
              },
              afterFooter: (item: Chart.ChartTooltipItem[]): string => {
                return this.legendList[item[0].index].fraction;
              }
            }
          },
          plugins: {
            id: 'custom-chart-plugin'
          }
        });
        this.setStateReady();
      });
  }

  public getTranslateKey(data: ProcurementMethod): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }
}
