import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Currency } from 'src/app/core/bean/currency';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PrItemBudget } from 'src/app/core/bean/pr-item-budget';
import { AppPopupChooseBudgetXService } from 'src/app/core/components/app-popup/app-popup-choose-budget-x/app-popup-choose-budget-x.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { TabResponseModel } from 'src/app/core/model/tab/tab-response-model';
import { OrderAddEditResponse } from './response/order-add-edit.response';
import { OrderAddEditModel } from './model/order-add-edit.model';

@Component({
  templateUrl: './pr-item-edit-add.component.html'
})
export class PrItemEditAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  public prItemId: number;
  public orderResponse: OrderAddEditResponse = new OrderAddEditResponse();
  @ViewChild('selectorItemBudget') tableItemBudget: AppTableComponent;
  public tableResponseItemBudget: TableResponseModel<PrItemBudget>;
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel();
  public prItem: PrItem = new PrItem();
  public autoCompleteValue: any;
  public fileUploader: FileUploader = new FileUploader(
    '/pr/',
    '',
    this.global.appConstant.fileType.IMG_ITEM_PR
  );
  public isBudgetValue: boolean;
  public tabs: Array<any>;
  public tabResponse: TabResponseModel;
  public backToUrl: string;
  public orderAddEditModelMap: Map<number, OrderAddEditModel> = new Map<number, OrderAddEditModel>();
  public currentPage: number;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupChooseBudgetService: AppPopupChooseBudgetXService
  ) {
    super('pr', translateService);
  }

  public onInit(): void {
    this.setTabsData();
    this.buildTabResponse();
    this.doSetDataFromRouterParams();
    this.setStateReady();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.prItemId = this.global.routerParams.get('prItemId');
    this.orderAddEditModelMap = this.global.routerParams.get('orderAddEditModelMap');
    this.currentPage = this.global.routerParams.get('currentPage');
    this.orderResponse = this.orderAddEditModelMap.get(this.currentPage).response;
    this.orderResponse = this.global.routerParams.get('orderResponse');
    this.backToUrl = this.global.routerParams.get('backToUrl');
    this.global.routerParams.set('tabResponse', this.tabResponse);
  }

  public setTabsData(): void {
    this.tabs = [
      { code: 'TABONE', name: 'Catalog', routeUrl: '' }/*,
      {
        code: 'TABTWO',
        name: 'Item Catalog',
        routeUrl: 'catalog'
      }*/
    ];
  }

  public buildTabResponse(): void {
    this.tabResponse =
      this.global.routerParams.get('tabResponse') ||
      TabResponseModel.create(this.tabs, '/pages/order/add-item');
  }
}
