import { Component, Inject } from '@angular/core';
import { BaseComponent } from './base.component';
@Component({
  template: ''
})
export abstract class BaseComponentComponent extends BaseComponent {
  public abstract onInit(): void;
  constructor(@Inject(String) moduleCode: string) {
    super(moduleCode);
  }

  protected onBaseInit(): void {
    this.log.debug('[ Load Base Component Component ] : ' + this.moduleCode);
    this.setStateLoading();
    this.onInit();
  }
}
