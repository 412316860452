import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Validators } from 'src/app/core/validators';
import { OrderRequest } from '../request/order.request';

@Component({
  templateUrl: './app-popup-pr-item-distributor.component.html'
})
export class AppPopupPrItemDistributorComponent extends BaseComponentComponent {
  @Output() public onChange: EventEmitter<OrderRequest> = new EventEmitter();

  constructor(public translateService: TranslateService) {
    super('pr');
  }

  onInit(): void {
    this.buildFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
        messageRequestItemToDistributor: [null, Validators.required()]
    });
  }

  public doSend(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup.value);
    }
  }
}
