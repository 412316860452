<app-popup header="{{ header | translate }}">
  <div class="app-popup-comparison">
    <div class="row px-3 pb-2">
      <div class="col-12 d-flex justify-content-end">
        <div class="custom-control custom-checkbox mr-4">
          <input
            type="checkbox"
            class="custom-control-input"
            [(ngModel)]="isCheckedAll"
            aria-label="isCheckedAll"
          />
          <label
            class="custom-control-label cursor-pointer"
            (click)="doCheckAll()"
            for="isCheckedAll"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
            >{{ 'app-popup-comparison.selectAll' | translate }}</label
          >
        </div>
        <span
          class="cursor-pointer"
          (click)="doDeleteAll()"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        >
          <em
            class="fas fa-trash text-danger"
            title="{{ 'app-popup-comparison.clearAll' | translate }}"
          ></em>
          {{ 'app-popup-comparison.clearAll' | translate }}
        </span>
      </div>
    </div>
    <div class="content-wrapper border p-4">
      <p *ngIf="itemList.length === 0" class="text-center">{{
        'app.info.noData' | translate
      }}</p>
      <div
        class="comparison-row"
        *ngFor="let rowData of itemList; let i = index"
      >
        <div class="left">
          <ng-container
            *ngTemplateOutlet="contentTmpl; context: { $implicit: rowData }"
          >
          </ng-container>
        </div>
        <div class="right">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              [(ngModel)]="rowData.isChecked"
              aria-label="{{ 'isChecked' + i }}"
            />
            <label
              class="custom-control-label"
              (click)="doCheck(i)"
              for="{{ 'isChecked' + i }}"
              (keyup)="onKeyUp($event)"
              (keydown)="onKeyDown($event)"
            ></label>
          </div>
          <em
            class="fas fa-trash text-danger cursor-pointer"
            [ngStyle]="{ 'font-size': 'x-large' }"
            (click)="doDelete(i)"
            title="{{ 'app.button.delete' | translate }}"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
          ></em>
        </div>
      </div>
    </div>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center mt-5">
        <app-button
          color="SECONDARY"
          (onClick)="activeModal.close(true)"
          [disabled]="!isEnabled"
        >
          {{ 'app.button.close' | translate }}
        </app-button>
        <app-button (onClick)="doCompare()" [disabled]="!isEnabled"
          >{{ 'app-popup-comparison.button.compare' | translate }}
        </app-button>
      </div>
    </ng-template>
  </div>
</app-popup>
