import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Bank } from '../../core/bean/bank';
import { BankAccount } from '../../core/bean/bank-account';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { BankAccountResponse } from './bank-account-response';
@Component({
  templateUrl: './bank-account-edit-add.component.html'
})
export class BankAccountEditAddComponent extends BaseModuleComponent {
  public bankAccountId: number;
  public autoCompleteValue: any;
  constructor(translateService: TranslateService) {
    super('bank-account', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.bankAccountId = this.global.routerParams.get('bankAccountId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        {
          validators: Validators.compose([
            Validators.required(),
            Validators.maxLength(64)
          ]),
          asyncValidators: [
            Validators.existsAsync('/bank-account/check-exist', null, null, {
              id: this.bankAccountId
            })
          ]
        }
      ],
      bank: [null, Validators.required()],
      accountNumber: [
        null,
        {
          validators: Validators.compose([
            Validators.required(),
            Validators.maxLength(32)
          ]),
          asyncValidators: [
            Validators.existsAsync('/bank-account/check-exist', null, null, {
              id: this.bankAccountId
            })
          ]
        }
      ],
      accountHolder: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(128)])
      ],
      branch: [null, Validators.maxLength(128)],
      description: [null, Validators.maxLength(512)]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<BankAccountResponse>(
        '/bank-account/add-edit',
        new RouteRequestModel(this.todo, this.bankAccountId)
      )
      .subscribe((bankAccountResponse: BankAccountResponse) => {
        this.autoCompleteValue =
          bankAccountResponse.bankAccount && bankAccountResponse.bankAccount.bank;
        if (bankAccountResponse.bankAccount != null) {
          const {
            id,
            code,
            bank,
            accountNumber,
            accountHolder,
            branch,
            description
          } = bankAccountResponse.bankAccount;
          this.formGroup.patchValue({
            id,
            code,
            bank: bank.name,
            accountNumber,
            accountHolder,
            branch,
            description
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const bankAccount: BankAccount = this.global.copyFormAttributeToModel(
              new BankAccount(),
              this.formGroup
            );
            const bank: Bank = new Bank();
            bank.id = this.autoCompleteValue.id;
            bankAccount.bank = bank;
            const url =
              this.todo === 'edit'
                ? '/bank-account/update'
                : '/bank-account/insert';
            this.httpClientService
              .post<Response<BankAccount>>(url, bankAccount)
              .subscribe((response: Response<BankAccount>) => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/bank-account/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/bank-account/']);
  }

  public autoCompleteValueChange(event: any): void {
    this.autoCompleteValue = event;
  }
}
