import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { PopupJustificationComponent } from './popup-justification.component';
import { ReportingSlaWorkPlanDetailComponent } from './reporting-sla-work-plan-detail.component';
import { ReportingSlaWorkPlanComponent } from './reporting-sla-work-plan.component';

export const routes = [
  {
    path: '',
    component: ReportingSlaWorkPlanComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'detail',
    component: ReportingSlaWorkPlanDetailComponent,
    data: { breadcrumb: 'reporting-sla-work-plan.breadcrumb.view' }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    ReportingSlaWorkPlanComponent,
    ReportingSlaWorkPlanDetailComponent,
    PopupJustificationComponent
  ],
  entryComponents: [PopupJustificationComponent],
  providers: []
})
export class ReportingSlaWorkPlanModule {}
