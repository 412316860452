import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Committee } from '../../bean/committee';
import { OptionListModel } from '../../model/option-list-model';
import { Validators } from '../../validators';
import { AppCommitteeResponse } from './app-committee-response';

@Component({
  templateUrl: './app-popup-choose-committee.component.html'
})
export class AppPopupChooseCommitteeComponent extends BaseComponentComponent {
  public committeeOptionList: OptionListModel<Committee> = new OptionListModel(
    true,
    'name'
  );
  public isChecked = false;
  @Input() committee: Committee;
  @Input() public appCommitteeResponse: AppCommitteeResponse;
  @Output() onChange: EventEmitter<Committee> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {
    super('app-popup-choose-committee');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      committee: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.committeeOptionList.setRequestValues(
      this.appCommitteeResponse.committeeList
    );
    if (this.committee) {
      this.committee = this.appCommitteeResponse.committeeList?.find(
        com => com?.id === this.committee.id
      );
      this.formGroup.patchValue({
        committee: this.committee
      });
    }
    this.setStateReady();
  }

  doSubmit(): void {
    this.committee = this.formGroup.get('committee').value;
    if (this.committee) {
      this.onChange.emit(this.committee);
    } else {
      this.global.alertService.showError(
        'app-committee.validation.chooseCommittee',
        '.popup-committee'
      );
    }
  }
}
