import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { User } from 'src/app/core/bean/user';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { SensitiveDataService } from 'src/app/core/services/sensitive-data.service';
import { DashboardResponse } from '../../../../pages/dashboard/dashboard-response';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { Response } from '../../../model/response-model';
import { AuthenticationService } from '../../../services/authentication.service';
import { Validators } from '../../../validators';
import { AppModalConfirmDialogComponent } from '../../app-modal/app-modal-confirm-dialog/app-modal-confirm-dialog.component';
import { AppPopupService } from '../app-popup.service';
import { AppPopupChangePasswordRequest } from './app-popup-change-password.request';
@Component({
  templateUrl: './app-popup-change-password.component.html',
  styleUrls: ['./app-popup-change-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupChangePasswordComponent extends BaseComponentComponent {
  readonly urlRouterNavigateToPortal = '/portal';
  termsAndCondition: any;
  public appPopupChangePasswordRequest: AppPopupChangePasswordRequest =
  new AppPopupChangePasswordRequest();
  public translateService: TranslateService;
  @Input() dashboardResponse: DashboardResponse;

  constructor(
    public activeModal: NgbActiveModal,
    public authenticationService: AuthenticationService,
    public sanitized: DomSanitizer,
    public sensitiveDataService: SensitiveDataService,
    public appPopupService: AppPopupService
  ) {
    super('app-popup-change-password');
  }
  

  onInit(): void {
    this.test();
    this.buildFormGroup();
    this.setStateReady();
  }

  test(): void {
    this.termsAndCondition = this.sanitized.bypassSecurityTrustHtml(
      this.dashboardResponse.contentChangePassword.value.toString()
    );
  }

  buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      email:[null],
      newPassword: [null, Validators.required()],
      confirmNewPassword: [null, Validators.required()]
    });
  }

  public doChangePassword(): void {
      this.formGroup
        .get('newPassword')
        .setValidators([Validators.required(), Validators.password()]);
      this.formGroup
        .get('confirmNewPassword')
        .setValidators([
          Validators.required(),
          Validators.matchPassword('newPassword'),
          Validators.password()
        ]);
   
    this.formGroup.get('newPassword').updateValueAndValidity();
    this.formGroup.get('confirmNewPassword').updateValueAndValidity();
  }

  public doSave(): void {
    this.validate();

    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const appPopupChangePasswordRequest: AppPopupChangePasswordRequest =
            this.global.copyFormAttributeToModel(new User(), this.formGroup);

            const newPassword = this.formGroup.get('newPassword').value;

            delete appPopupChangePasswordRequest['newPassword'];
            delete appPopupChangePasswordRequest['confirmNewPassword'];

            appPopupChangePasswordRequest.userId = this.global.userSession.user.id;
            appPopupChangePasswordRequest.newPassword = newPassword;

            this.httpClientService
              .post<Response<User>>(
                '/user/save-change-password',
                appPopupChangePasswordRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.appPopupService.open(AppModalConfirmDialogComponent, {
                    prompt: 'app.confirmation.saveSuccess.reLoginDescription',
                    title: 'app.confirmation.saveSuccess.reLoginTitle',
                    icon: 'pvc pv-check-circle',
                    isSingleButton: true,
                    buttonRight: 'app.button.ok'
                  },
                  { size: 'md' })
                  .subscribe(result => {
                    if (result) {
                      this.authenticationService.logout();
                    }
                  });
                this.activeModal.dismiss();
              } 
              else {
                this.global.alertService.showError(response.statusText);
                this.resetPasswordValue();
              }
              this.setStateReady();
            });
          } 
        });
    }
  }

  public resetPasswordValue(): void {
    this.formGroupService.bulkResetFormControl(this.formGroup, [
      'newPassword',
      'confirmNewPassword'
    ]);
  }

  onClosePopup(): void {
    if (this.global.userSession.user.passwordChangedDate == null ){
      this.authenticationService.logout();
    } else {
    this.setStateReady();
    }
  }
}
