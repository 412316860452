import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartModel } from '../../../../../core/components/app-chart/models/chart-model';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetContractPerCategoryResponse } from './model/widget-contract-per-category-response';
@Component({
  selector: 'dashboard-widget-contract-per-category',
  templateUrl: './widget-contract-per-category.component.html',
  encapsulation: ViewEncapsulation.None
})
export class WidgetContractPerCategoryComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-contract-per-category';
  public chartModel: ChartModel;
  constructor(
    public translateService: TranslateService
  ) {
    super(WidgetContractPerCategoryComponent.moduleCode);
  }

  public onInit(): void {
    this.getAndSetContractInformation();
  }

  public getAndSetContractInformation(): void {
    this.httpClientService
      .get<WidgetContractPerCategoryResponse>('/widget-contract-per-category/index').subscribe(
        (
          widgetContractPerCategoryResponse: WidgetContractPerCategoryResponse
        ) => {
          if (widgetContractPerCategoryResponse) {
            const labels = [];
            const dataSets = [];
            widgetContractPerCategoryResponse.widgetContractPerCategoryModelList.forEach(
              widgetContractPerCategoryModel => {
                labels.push(
                  this.translateService.instant(
                    this.moduleCode + '.' +
                    widgetContractPerCategoryModel.name
                      .toLowerCase()
                      .replace(/\s/g, '')
                      .trim()
                  )
                );
                dataSets.push(widgetContractPerCategoryModel.total);
              }
            );
            this.chartModel = new ChartModel('pie', labels, dataSets, null, {
              defaultColor: ['#204d95 ', '#782095', '#3d9520']
            });
          }
          this.setStateReady();
        }
      );
  }
}
