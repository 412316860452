import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupLoadingBlockComponent } from './app-popup-loading-block.component';
@NgModule({
  imports: [VendorModule, AppPopupModule],
  declarations: [AppPopupLoadingBlockComponent],
  entryComponents: [AppPopupLoadingBlockComponent],
  exports: [AppPopupLoadingBlockComponent]
})
export class AppPopupLoadingBlockModule {}
