import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { ReportRecapAddBuilderComponent } from './report-recap-add-builder.component';
import { ReportRecapComponent } from './report-recap.component';

export const routes = [
  { path: '', component: ReportRecapComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: ReportRecapAddBuilderComponent,
    data: { breadcrumb: 'report-recap.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: ReportRecapAddBuilderComponent,
    data: { breadcrumb: 'report-recap.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [
    ReportRecapComponent,
    ReportRecapAddBuilderComponent
  ],
  providers: [],
  entryComponents: []
})
export class ReportRecapModule {}
