import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Wanprestasi } from '../../core/bean/wanprestasi';
import { AppPopupVendorService } from '../../core/components/app-popup/app-popup-vendor/app-popup-vendor.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { AppPopupContractActivityComponent } from './app-popup-contract-activity.component';
import { AppPopupProcurementActivityComponent } from './app-popup-procurement-activity.component';
import { WanprestasiResponse } from './wanprestasi-response';

@Component({
  templateUrl: './wanprestasi-add.component.html'
})
export class WanprestasiAddComponent extends BaseModuleComponent {
  @ViewChild('selectorProcurement') public tableProcurement: AppTableComponent;
  @ViewChild('selectorContract') public tableContract: AppTableComponent;
  public tableResponseProc: TableResponseModel<Wanprestasi>;
  public tableResponseContract: TableResponseModel<Wanprestasi>;

  public vendorId: number;
  public vendorCode: string = null;
  public wanprestasiResponse: WanprestasiResponse = new WanprestasiResponse();
  public procurementIdList: number[] = [];
  public contractIdList: number[] = [];
  public isDisable = false;

  constructor(
    translateService: TranslateService,
    public appPopUpVendorService: AppPopupVendorService,
    public appPopupService: AppPopupService
  ) {
    super('wanprestasi', translateService);
    Object.assign(this, this.global.routerParams);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
    this.buildTableResponse();
    this.disableButtonAdd();
  }

  public disableButtonAdd(): void {
    if (!this.formGroup.get('name').value) {
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorId = this.global.routerParams.get('vendorId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [null, Validators.required()]
    });
  }

  public buildTableResponse(): void {
    this.tableResponseProc = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurement.procurementDate',
        header: 'form.procurement.procurement.date',
        format: FieldFormatEnum.ShortDate
      },
      { field: 'procurement.name', header: 'form.procurement.name' },
      {
        field: 'procurement.procurementType.name',
        header: 'form.procurement.type'
      },
      { field: 'procurement.stage', header: 'stages' }
    ]);

    this.tableResponseContract = new TableResponseModel(this.moduleCode, [
      {
        field: 'contract.startDate',
        header: 'form.contract.startDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'contract.endDate',
        header: 'form.contract.endDate',
        format: FieldFormatEnum.ShortDate
      },
      { field: 'contract.name', header: 'form.contract.name' },
      { field: 'contract.number', header: 'form.contract.number' },
      { field: 'contract.stage', header: 'stages' }
    ]);

    this.tableResponseProc.page.records = []; // inisiasi
    this.tableResponseContract.page.records = [];
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<WanprestasiResponse>(
        '/wanprestasi/add-view',
        new RouteRequestModel(this.todo, this.vendorId)
      )
      .subscribe(wanprestasiResponse => {
        this.wanprestasiResponse = wanprestasiResponse;
        if (this.todo === 'view') {
          this.formGroup.patchValue({
            id: wanprestasiResponse.vendor.id,
            name: wanprestasiResponse.vendor.name
          });
          this.vendorCode = wanprestasiResponse.vendor.code;
          this.setViewOnly();
          this.tableResponseProc.setRecords(
            wanprestasiResponse.wanprestasiByProcurementList
          );
          this.tableResponseContract.setRecords(
            wanprestasiResponse.wanprestasiByContractList
          );

          this.tableResponseProc.setTotalRecords(
            wanprestasiResponse.wanprestasiByProcurementList.length
          );
          this.tableResponseContract.setTotalRecords(
            wanprestasiResponse.wanprestasiByContractList.length
          );

          this.tableResponseProc.reloadClient();
          this.tableResponseContract.reloadClient();
        }

        this.setStateReady();
      });
  }

  public setDatatableFromBackend(vendorId: number): void {
    this.tableResponseProc.setStateLoading();
    this.tableResponseContract.setStateLoading();
    this.httpClientService
      .post<WanprestasiResponse>(
        '/wanprestasi/get-wanprestasi-list',
        new RouteRequestModel(this.todo, vendorId)
      )
      .subscribe(wanprestasiResponse => {
        this.tableResponseProc.resetRecords();
        this.tableResponseContract.resetRecords();

        this.tableResponseProc.setRecords(
          wanprestasiResponse.wanprestasiByProcurementList
        );
        this.tableResponseContract.setRecords(
          wanprestasiResponse.wanprestasiByContractList
        );

        this.tableResponseProc.setTotalRecords(
          this.tableResponseProc.records.length
        );
        this.tableResponseContract.setTotalRecords(
          this.tableResponseContract.records.length
        );

        this.tableResponseProc.reloadClient();
        this.tableResponseContract.reloadClient();
        this.tableResponseProc.reload();
        this.tableResponseContract.reload();

        this.procurementIdList = [];
        wanprestasiResponse.wanprestasiByProcurementList.forEach(
          wanprestasi => {
            this.procurementIdList.push(wanprestasi.procurement.id);
          }
        );
        this.contractIdList = [];
        wanprestasiResponse.wanprestasiByContractList.forEach(wanprestasi => {
          this.contractIdList.push(wanprestasi.contract.id);
        });
        this.setStateReady();
      });
  }

  public doChooseVendor(): void {
    const vendorStatusCodeList: string[] = [
      this.global.appConstant.vm.VENDOR_STATUS_ACTIVE
    ];
    this.appPopUpVendorService
      .open(null, null, vendorStatusCodeList)
      .subscribe(vendor => {
        this.global.routerParams.set('vendorId', vendor.id);

        const id = vendor.id;
        const name = vendor.name;
        this.vendorCode = vendor.code;
        this.formGroup.patchValue({ id, name });
        this.disableButtonAdd();
        this.vendorId = this.global.routerParams.get('vendorId');
        this.setDatatableFromBackend(id);
      });
  }

  public doAddProcurementActivity(): void {
    this.validate();
    if (this.formGroup.valid) {
      const todo = 'add';
      const procurementIdList = [];
      const wanprestasiList = this.wanprestasiResponse.wanprestasiList;
      const dataEditView = null;

      this.appPopupService
        .open(
          AppPopupProcurementActivityComponent,
          { todo, procurementIdList, dataEditView, wanprestasiList },
          { size: 'lg', backdrop: 'static' }
        )
        .subscribe(data => {
          const wanprestasi: Wanprestasi = data;
          this.wanprestasiResponse.wanprestasiList.push(wanprestasi);
          this.wanprestasiResponse.fileObjectList.push(wanprestasi.file[0]);

          this.tableResponseProc.records.push(wanprestasi);
          this.tableResponseProc.setTotalRecords(
            this.tableResponseProc.records.length
          );
          this.tableResponseProc.reloadClient();
          this.procurementIdList.push(wanprestasi.procurement.id);
        });
    } else {
      this.global.alertService.showError('wanprestasi.msg.error.chooseVendor');
    }
  }

  public doEditProcurementActivity(wanprestasiEditView: Wanprestasi): void {
    const todo = 'edit';
    const procurementIdList = [];
    const wanprestasiList = this.wanprestasiResponse.wanprestasiList;

    this.appPopupService
      .open(
        AppPopupProcurementActivityComponent,
        { todo, procurementIdList, wanprestasiEditView, wanprestasiList },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(dataTemp => {
        const wanprestasiEdit = dataTemp;

        this.wanprestasiResponse.wanprestasiList.forEach(
          (wanprestasi, index) => {
            if (wanprestasi === wanprestasiEditView) {
              this.wanprestasiResponse.wanprestasiList[index] = wanprestasiEdit;
              this.wanprestasiResponse.fileObjectList[index] =
                wanprestasiEdit.file[0];
            }
          }
        );

        this.tableResponseProc.records.forEach((data, index) => {
          if (data === wanprestasiEditView) {
            this.tableResponseProc.records[index] = wanprestasiEdit;

            // tslint:disable-next-line: no-shadowed-variable
            procurementIdList.forEach((id, index) => {
              if (id === wanprestasiEditView.procurement.id) {
                procurementIdList.splice(index, 1);
              }
            });
          }
        });

        this.tableResponseProc.setTotalRecords(
          this.tableResponseProc.records.length
        );
        this.tableResponseProc.reloadClient();

        this.procurementIdList.push(wanprestasiEdit.procurement.id);
      });
  }

  public doDeleteProcurementActivity(wanprestasiDelete: Wanprestasi): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.wanprestasiResponse.wanprestasiList.forEach(
            (wanprestasi, index) => {
              if (wanprestasi === wanprestasiDelete) {
                this.wanprestasiResponse.wanprestasiList.splice(index, 1);
                this.wanprestasiResponse.fileObjectList.splice(index, 1);
              }
            }
          );

          this.tableResponseProc.records.forEach((data, index) => {
            if (data === wanprestasiDelete) {
              this.tableResponseProc.records.splice(index, 1);
            }
          });

          this.tableResponseProc.page.records = this.tableResponseProc.records;
          this.tableResponseProc.setTotalRecords(
            this.tableResponseProc.records.length
          );
          this.tableResponseProc.reloadClient();

          this.procurementIdList.forEach((id, index) => {
            if (id === wanprestasiDelete.procurement.id) {
              this.procurementIdList.splice(index, 1);
            }
          });
        }
      });
  }

  public doViewProcurementActivity(wanprestasiEditView: Wanprestasi): void {
    const todo = 'view';
    this.appPopupService.open(
      AppPopupProcurementActivityComponent,
      { todo, wanprestasiEditView },
      { size: 'lg', backdrop: 'static' }
    );
  }

  public doAddContractActivity(): void {
    this.validate();
    if (this.formGroup.valid) {
      const todo = 'add';
      const contractIdList = [];
      const wanprestasiList = this.wanprestasiResponse.wanprestasiList;
      const dataEditView = null;

      this.appPopupService
        .open(
          AppPopupContractActivityComponent,
          { todo, contractIdList, dataEditView, wanprestasiList },
          { size: 'lg', backdrop: 'static' }
        )
        .subscribe(data => {
          const wanprestasi = data;
          this.wanprestasiResponse.wanprestasiList.push(wanprestasi);
          this.wanprestasiResponse.fileObjectList.push(wanprestasi.file[0]);

          this.tableResponseContract.records.push(wanprestasi);
          this.tableResponseContract.setTotalRecords(
            this.tableResponseContract.records.length
          );
          this.tableResponseContract.reloadClient();
          this.contractIdList.push(wanprestasi.contract.id);
        });
    } else {
      this.global.alertService.showError('wanprestasi.msg.error.chooseVendor');
    }
  }

  public doEditContractActivity(wanprestasiEditView: Wanprestasi): void {
    const todo = 'edit';
    const contractIdList = [];
    const wanprestasiList = this.wanprestasiResponse.wanprestasiList;

    this.appPopupService
      .open(
        AppPopupContractActivityComponent,
        { todo, contractIdList, wanprestasiEditView, wanprestasiList },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(dataTemp => {
        const wanprestasiEdit = dataTemp;

        this.wanprestasiResponse.wanprestasiList.forEach(
          (wanprestasi, index) => {
            if (wanprestasi === wanprestasiEditView) {
              this.wanprestasiResponse.wanprestasiList[index] = wanprestasiEdit;
              this.wanprestasiResponse.fileObjectList[index] =
                wanprestasiEdit.file[0];
            }
          }
        );

        this.tableResponseContract.records.forEach((data, index) => {
          if (data === wanprestasiEditView) {
            this.tableResponseContract.records[index] = wanprestasiEdit;

            // tslint:disable-next-line: no-shadowed-variable
            contractIdList.forEach((id, index) => {
              if (id === wanprestasiEditView.contract.id) {
                contractIdList.splice(index, 1);
              }
            });
          }
        });

        this.tableResponseContract.setTotalRecords(
          this.tableResponseContract.records.length
        );
        this.tableResponseContract.reloadClient();

        this.contractIdList.push(wanprestasiEdit.contract.id);
      });
  }

  public doDeleteContractActivity(wanprestasiDelete: Wanprestasi): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.wanprestasiResponse.wanprestasiList.forEach(
            (wanprestasi, index) => {
              if (wanprestasi === wanprestasiDelete) {
                this.wanprestasiResponse.wanprestasiList.splice(index, 1);
                this.wanprestasiResponse.fileObjectList.splice(index, 1);
              }
            }
          );

          this.tableResponseContract.records.forEach((data, index) => {
            if (data === wanprestasiDelete) {
              this.tableResponseContract.records.splice(index, 1);
            }
          });

          this.tableResponseContract.page.records =
            this.tableResponseContract.records;
          this.tableResponseContract.setTotalRecords(
            this.tableResponseContract.records.length
          );
          this.tableResponseContract.reloadClient();

          this.contractIdList.forEach((id, index) => {
            if (id === wanprestasiDelete.contract.id) {
              this.contractIdList.splice(index, 1);
            }
          });
        }
      });
  }

  public doViewContractActivity(wanprestasiEditView: Wanprestasi): void {
    const todo = 'view';
    this.appPopupService.open(
      AppPopupContractActivityComponent,
      { todo, wanprestasiEditView },
      { size: 'lg', backdrop: 'static' }
    );
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const url = '/wanprestasi/insert';
            this.wanprestasiResponse.wanprestasiList.forEach(wanprestasi => {
              wanprestasi.file = null;
            });
            this.httpClientService
              .post<Response<WanprestasiResponse>>(
                url,
                this.wanprestasiResponse
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/wanprestasi']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/wanprestasi']);
  }

  // tslint:disable-next-line: typedef
  public get formGroupControls() {
    return this.formGroup.controls;
  }
}
