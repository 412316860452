<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="budget-allocation.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div
          class="form-group row text-sm-right"
          [ngClass]="todo !== 'edit' ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'budget-allocation.form.organization' | translate
          }}</label>
          <div class="col-sm-9 text-left" *ngIf="todo === 'add'">
            <app-text-tree
              formControlName="organizationName"
              stringUrl="/organization"
              isLazy="true"
              header="Organization"
            >
            </app-text-tree>
          </div>
          <div class="col-sm-9 text-left" *ngIf="todo === 'edit'">
            {{ budgetAllocationDTO.budgetAllocation.organization?.name }}
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="todo !== 'edit' ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'budget-allocation.form.workProgram' | translate
          }}</label>
          <div class="col-sm-9 text-left" *ngIf="todo === 'add'">
            <app-combo-box
              [optionList]="workProgramOptionList"
              formControlName="workProgram"
            ></app-combo-box>
          </div>
          <div class="col-sm-9 text-left" *ngIf="todo === 'edit'">
            {{ formGroup.value.workProgram?.name }}
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="todo !== 'edit' ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'budget-allocation.form.budgetType' | translate
          }}</label>
          <div class="col-sm-9 text-left" *ngIf="todo === 'add'">
            <app-combo-box
              [optionList]="budgetTypeOptionList"
              formControlName="budgetType"
            ></app-combo-box>
          </div>
          <div class="col-sm-9 text-left" *ngIf="todo === 'edit'">
            {{ formGroup.value.budgetType?.name }}
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="todo !== 'edit' ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'budget-allocation.form.costCenter' | translate
          }}</label>
          <div class="col-sm-9 text-left" *ngIf="todo === 'add'">
            <app-combo-box
              [optionList]="costCenterOptionList"
              formControlName="costCenter"
            ></app-combo-box>
          </div>
          <div class="col-sm-9 text-left" *ngIf="todo === 'edit'">
            {{ formGroup.value.costCenter?.name }}
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="todo !== 'edit' ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'budget-allocation.form.coa' | translate
          }}</label>
          <div class="col-sm-9 text-left" *ngIf="todo === 'add'">
            <app-text-tree
              formControlName="coa"
              stringUrl="/coa"
              header="Chart Of Account"
              [onlyLastChild]="true"
            >
            </app-text-tree>
          </div>
          <div class="col-sm-9 text-left" *ngIf="todo === 'edit'">
            {{ budgetAllocationDTO.budgetAllocation?.coa.name }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'budget-allocation.form.activityPeriod' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p *ngIf="formGroup.value.workProgram == null">-</p>
            <p *ngIf="formGroup.value.workProgram !== null">{{
              formGroup.value.workProgram.activityPeriod
            }}</p>
          </div>
        </div>
        <div class="form-group row text-sm-right" *ngIf="todo === 'edit'">
          <label class="col-sm-3 control-label">{{
            'budget-allocation.form.availableBudget' | translate
          }}</label>
          <div class="col-sm-3 text-right">
            {{
              formGroup.value.availableBudget +
                ' ' +
                budgetAllocationDTO.budgetAllocation.currency.code
            }}
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'budget-allocation.form.totalBudget' | translate
          }}</label>
          <div class="col-sm-9 text-sm-left row d-flex ml-1 border-0">
            <app-combo-box
              size="XL"
              formControlName="currency"
              [optionList]="currencyOptionList"
              [ngStyle]="{ 'min-width': '100px' }"
            >
            </app-combo-box>
            <app-text-field
              *ngIf="todo !== 'edit'"
              size="XL"
              formControlName="totalBudget"
              type="currency"
              maxLength="16"
              [ngStyle]="{ 'margin-inline-start': 'inherit', width: '23%' }"
            >
            </app-text-field>
            <app-text-field
              *ngIf="todo === 'edit'"
              size="XL"
              formControlName="totalBudget"
              type="currency"
              include="-"
              maxLength="16"
              tooltip="budget-allocation.tooltip.totalBudget"
              [ngStyle]="{ 'margin-inline-start': 'inherit', width: '23%' }"
            >
            </app-text-field>
          </div>
        </div>
        <div
          *ngIf="todo !== 'add'"
          class="form-group row text-sm-right required"
        >
          <label class="col-sm-3 control-label">{{
            'budget-allocation.form.note' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="note"></app-text-area>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            class="mr-1"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}</app-button
          >
          <app-button
            color="PRIMARY"
            (onClick)="doSave()"
            [disabled]="formSaving"
            >{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>

    <app-card
      *ngIf="todo === 'edit'"
      header="budget-allocation.budgetHistory.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [tableResponse]="tableResponse"
        [isServerSide]="true"
        stringUrl="/budget-allocation/budget-history/index"
        customData="{{ budgetAllocationId }}"
      >
      </app-table>
    </app-card>
  </div>
</div>
