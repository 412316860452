import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupTabBankComponent } from './app-popup-tab-bank.component';
import { TabBankComponent } from './tab-bank.component';
@NgModule({
  imports: [SharedModule],
  declarations: [TabBankComponent, AppPopupTabBankComponent],
  entryComponents: [TabBankComponent, AppPopupTabBankComponent],
  exports: [TabBankComponent, AppPopupTabBankComponent]
})
export class TabBankModule {
  constructor(accordionService: AccordionService) {
    accordionService.register('tab-bank', TabBankComponent);
  }
}
