import { BaseDTO } from '../../../core/base/base-dto';
import { Bank } from '../../../core/bean/bank';
import { VendorBank } from '../../../core/bean/vendor-bank';
import { VendorBankHistory } from '../../../core/bean/vendor-bank-history';

export class TabProfileBankDTO extends BaseDTO {
    public defaultBank: Bank;
    public vendorBankHistoryList: VendorBankHistory[];
    public vendorBankList: VendorBank[];
}
