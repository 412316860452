import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { Procedure } from 'src/app/core/bean/procedure';
import { ProcedureResponse } from './procedure.response';
import { HospitalClass } from 'src/app/core/bean/hospital-class';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Currency } from 'src/app/core/bean/currency';
import { ProcedureRequest } from './procedure.request';
import { ProcedurePrice } from 'src/app/core/bean/procedure-price';

@Component({
  templateUrl: './procedure-edit-add.component.html'
})
export class ProcedureEditAddComponent extends BaseModuleComponent {
  public procedureId: number;
  public procedure: Procedure;
  public hospitalClassList: HospitalClass[];
  public procedureResponse: ProcedureResponse;
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel();
  constructor(translateService: TranslateService) {
    super('procedure', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.procedureId = this.global.routerParams.get('procedureId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(128)])
      ]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ProcedureResponse>(
        '/procedure/add-edit',
        new RouteRequestModel(this.todo, this.procedureId)
      )
      .subscribe(response => {
        this.procedureResponse = response;
        this.hospitalClassList = this.procedureResponse.hospitalClassList;
        this.currencyOptionList.setRequestValues(
          this.procedureResponse.currencyList
        );
        if (this.procedureResponse.procedure != null) {
          this.procedure = this.procedureResponse.procedure;
          this.formGroup.patchValue({
            id: this.procedure.id,
            code: this.procedure.code,
            name: this.procedure.name
          });
        }

        this.hospitalClassList.forEach((hospitalClass, index) => {
          this.formGroup.addControl(
            `priceClass${hospitalClass.code}`,
            this.formBuilder.control(
              { price: this.procedureResponse.procedurePriceList[index]?.amount || null },
              Validators.required()
            )
          );
        });
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            const procedureRequest: ProcedureRequest = new ProcedureRequest();

            procedureRequest.procedure = {
              id: this.formGroup.get('id')?.value,
              code: this.formGroup.get('code')?.value,
              name: this.formGroup.get('name')?.value,
            } as any;

            const procedurePriceList: ProcedurePrice[] = this.hospitalClassList.map((hospitalClass, index) => {
              return {
                id: this.procedureResponse.procedurePriceList[index]?.id || null,
                procedure: procedureRequest.procedure,
                hospitalClass: hospitalClass,
                amount: this.formGroup.get(`amountClass${hospitalClass.code}`)?.value?.amount || null
              } as ProcedurePrice;
            });

            procedureRequest.procedurePriceList = procedurePriceList;

            const url: string =
              this.todo === 'edit' ? '/procedure/update' : '/procedure/insert';

            this.httpClientService
              .post<Response<Procedure>>(url, procedureRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/procedure/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }


  public doCancel(): void {
    this.router.navigate(['/pages/procedure/']);
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
}
