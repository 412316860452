import { BaseDTO } from '../../../core/base/base-dto';
import { Catalog } from '../../../core/bean/catalog';
import { Currency } from '../../../core/bean/currency';
import { FormObject } from '../../../core/bean/form-object';
import { Region } from '../../../core/bean/region';
import { ResponseRfiQuestion } from '../../../core/bean/response-rfi-question';
import { Rfi } from '../../../core/bean/rfi';
import { RfiItem } from '../../../core/bean/rfi-item';
import { RfiQuestion } from '../../../core/bean/rfi-question';
import { RfiVendor } from '../../../core/bean/rfi-vendor';
import { RfiVendorNonPromise } from '../../../core/bean/rfi-vendor-non-promise';
import { Uom } from '../../../core/bean/uom';
import { VendorCategory } from '../../../core/bean/vendor-category';
import { VendorType } from '../../../core/bean/vendor-type';
import { FileObject } from '../../../core/components/upload';
import { ListOfValueModel } from '../../../core/model/list-of-value-model';

export class RfiDTO extends BaseDTO {
  rfi: Rfi = new Rfi();
  currencyList: Currency[] = [];
  rfiItemList: RfiItem[] = [];
  rfiVendorList: RfiVendor[] = [];
  rfiVendorNonPromiseList: RfiVendorNonPromise[] = [];
  formObjectList: FormObject[] = [];
  rfiQuestionList: RfiQuestion[] = [];
  templateRfiList: FileObject[] = [];
  listOfValue: ListOfValueModel[] = [];
  regionPhoneList: Region[] = [];
  rfiQuestion: RfiQuestion = new RfiQuestion();
  vendorCategoryList: VendorCategory[] = [];
  responseRfiList: ResponseRfiQuestion[] = [];
  uomList: Uom[] = [];
  vendorTypeList: VendorType[] = [];
  catalogList: Catalog[] = [];
  parameterResponseInDays: number;
}
