import { BaseEntity } from '../base/base-entity';

export class NotificationBlastEntity extends BaseEntity {
  createdDate: Date;

  subject: string;
  content: string;
  cc: string;
  vendorStatusCode: string;
  vendorTypeCode: string;
  vendorCategoryCode: string;
  keyword: string;
}
