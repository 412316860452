import { TreeItemModel } from './tree-item-model';

export class TreeItemListModel {
  treeItemList: Array<TreeItemModel> = new Array();
  isLoading = false;
  isNoData = false;
  constructor() {}
  setTreeItemList(treeItemList: Array<TreeItemModel>): void {
    this.treeItemList = treeItemList;
  }

  setStateLoading(): void {
    this.isLoading = true;
  }

  setStateReady(): void {
    this.isLoading = false;
  }

  addTreeItem(treeItem: TreeItemModel): void {
    this.treeItemList.push(treeItem);
  }

  resetTreeItemSelected(): void {
    this.treeItemList.forEach((treeItem: TreeItemModel) => {
      treeItem.selected = false;
    });
  }

  getSelectedItem(): TreeItemModel {
    return this.treeItemList.filter(treeItem => treeItem.selected)[0];
  }

  getIndexOfSelectedItem(): number {
    return this.treeItemList.findIndex(treeItem => treeItem.selected);
  }

  removeAt(index: number): void {
    this.treeItemList.splice(index, 1);
  }

  replace(treeItem: TreeItemModel, index: number): void {
    this.treeItemList.splice(index, 1, treeItem);
  }

  getIndexOfTreeItemById(id: number): number {
    return this.treeItemList.findIndex((item: TreeItemModel) => item.id === id);
  }

  get length(): number {
    return this.treeItemList.length;
  }
}
