<app-popup [header]="header" [isLoading]="formLoading">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'delivery-monitoring.form.item' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ procurementResult.procurementItem?.item?.name }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'delivery-monitoring.form.deliveredItem' | translate
      }}</label>
      <div
        class="col-sm-9 text-left"
        *ngIf="
          procurementResult.procurementItem.item.itemType.code === global.appConstant.core.ITEM_TYPE_CODE_MATERIAL &&
          procurementSowPaymentTermItem === null
        "
      >
        {{ procurementResult.receivedItemPerQuantity }}
      </div>
      <div
        class="col-sm-9 text-left"
        *ngIf="
          procurementResult.procurementItem.item.itemType.code === global.appConstant.core.ITEM_TYPE_CODE_MATERIAL &&
          procurementSowPaymentTermItem !== null
        "
      >
        {{ procurementSowPaymentTermItem.receivedItemPerQuantity }} {{ procurementResult.procurementItem?.item?.uom?.name || '-'}}
      </div>
      <div
        class="col-sm-9 text-left"
        *ngIf="
          procurementResult.procurementItem.item.itemType.code !== global.appConstant.core.ITEM_TYPE_CODE_MATERIAL &&
          procurementSowPaymentTermItem === null
        "
      >
        {{ procurementResult.percentageValue }} % /
        {{ procurementResult.itemQuantity }} %
      </div>
      <div
        class="col-sm-9 text-left"
        *ngIf="
          procurementResult.procurementItem.item.itemType.code !== global.appConstant.core.ITEM_TYPE_CODE_MATERIAL &&
          procurementSowPaymentTermItem !== null
        "
      >
        {{ procurementSowPaymentTermItem.deliveredTotal }} % /
        {{ procurementSowPaymentTermItem.quantity + '.00' }} %
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      *ngIf="
        !formGroup.isView &&
        procurementResult.procurementItem.item.itemType.code === global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      "
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'delivery-monitoring.form.receivedTotal' | translate
      }}</label>
      <div class="col-sm-3 text-left">
        <app-text-field
          size="XL"
          formControlName="receivedQuantity"
          type="decimal"
          maxLength="15"
        ></app-text-field>
      </div>
      <div class="col-sm-6 text-left px-0 d-flex-align-items-center" style="line-height: 200%;">{{ procurementResult.procurementItem?.item?.uom?.name || ' '}}</div>
    </div>
    <div
      class="form-group row text-sm-right"
      *ngIf="
        !formGroup.isView &&
        procurementResult.procurementItem.item.itemType.code === global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      "
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'delivery-monitoring.form.passTotal' | translate
      }}</label>
      <div class="col-sm-3 text-left">
        <app-text-field
          size="XL"
          formControlName="passQuantity"
          type="decimal"
          maxLength="15"          
          (onChange)="onChangePassTotal()"
        ></app-text-field>
      </div>
      <div class="col-sm-6 text-left px-0 d-flex-align-items-center" style="line-height: 200%;">{{ procurementResult.procurementItem?.item?.uom?.name || ' '}}</div>
    </div>
    <div
      class="form-group row text-sm-right"
      *ngIf="
        !formGroup.isView &&
        procurementResult.procurementItem.item.itemType.code === global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      "
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'delivery-monitoring.form.rejectTotal' | translate
      }}</label>
      <div class="col-sm-3 text-left">
        <app-text-field
          size="XL"
          formControlName="rejectQuantity"
          type="decimal"
          maxLength="15"
        ></app-text-field>
      </div>
      <div class="col-sm-6 px-0 text-left d-flex-align-items-center" style="line-height: 200%;">{{ procurementResult.procurementItem?.item?.uom?.name || ' '}}</div>
    </div>
    <div
      class="form-group row text-sm-right"
      *ngIf="
        !formGroup.isView &&
        procurementResult.procurementItem.item.itemType.code === global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      "
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'delivery-monitoring.form.receivedDate' | translate
      }}</label>
      <div class="col-sm-3 text-left">
        <app-date-picker formControlName="receivedDate" size="XL"> </app-date-picker>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      *ngIf="
        !formGroup.isView &&
        procurementResult.procurementItem.item.itemType.code !== global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      "
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'delivery-monitoring.form.deliverable' | translate
      }}</label>
      <div class="col-sm-8 text-left">
        <app-text-field
          formControlName="passPercentageValue"
          type="decimal"
          maxLength="15"
          symbol="%"
        ></app-text-field>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      *ngIf="!formGroup.isView"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'delivery-monitoring.form.deliveryPerformance' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-rating formControlName="performanceValue"></app-rating>
      </div>
    </div>
    <div class="form-group row text-sm-right" *ngIf="!formGroup.isView">
      <label class="col-sm-3 control-label">{{
        'delivery-monitoring.form.note' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-area formControlName="note" size="LG"></app-text-area>
      </div>
    </div>
    <div class="form-group row text-sm-right" *ngIf="!formGroup.isView">
      <label class="col-sm-3 control-label">{{
        'delivery-monitoring.form.attachment' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-upload-multiple
          formControlName="uploadFile"
          [fileUploader]="fileUploader"
          (onChange)="onChangeFileUpload($event)"
        ></app-upload-multiple>
      </div>
    </div>
    <div class="col-12 text-center" *ngIf="!formGroup.isView">
      <app-button
        *ngIf="!formGroup.isView"
        color="PRIMARY"
        (onClick)="doSave()"
        >{{ 'app.button.save' | translate }}</app-button
      >
    </div>
    <div class="row">
      <div class="col-12">
        <h5 class="separator">{{
          'delivery-monitoring.separator.historyLog' | translate
        }}</h5>
        <app-table
          *ngIf="
            procurementResult.procurementItem.item.itemType.code === global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
          "
          #selectorProduct
          [model]="tableResponseProduct"
          stringUrl="/delivery-monitoring/table-history-log"
          [isServerSide]="true"
        >
        </app-table>
        <app-table
          *ngIf="
            procurementResult.procurementItem.item.itemType.code !== global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
          "
          #selectorService
          [model]="tableResponseService"
          stringUrl="/delivery-monitoring/table-history-log"
          [isServerSide]="true"
        >
        </app-table>
      </div>
    </div>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">
        {{ 'app.button.back' | translate }}</app-button
      >
    </div>
  </ng-template>
</app-popup>
