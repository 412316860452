import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorCompanyOrganizationHistory } from '../../core/bean/vendor-company-organization-history';
@Component({
  templateUrl: './app-popup-share-holders.component.html'
})
export class AppPopupShareHoldersComponent extends BaseModuleComponent {
  @Input() vendorShareHolder: VendorCompanyOrganizationHistory;

  constructor(translateService: TranslateService) {
    super('tab-company-organization', translateService);
  }

  public onInit(): void {
    this.setStateReady();
  }
}
