import { CommitteeParticipant } from '../../../bean/committee-participant';

export class BinaryTreeModel {
  treeList: Array<any>;
  isShowChooseButton = false;
  isWrite = false;
  recursiveTreeList = [];

  constructor(isShowChooseButton?: boolean, isWrite?: boolean) {
    this.isShowChooseButton = isShowChooseButton;
    this.isWrite = isWrite;
  }

  public getTreeList(): CommitteeParticipant[] {
    return this.treeList;
  }

  public setTreeList(treeList): void {
    this.treeList = treeList;
  }

  public getRecuresiveTreeList(): any[] {
    return this.recursiveTreeList;
  }

  public setViewOnly(): void {
    this.isShowChooseButton = false;
    this.isWrite = false;
  }

  public setRecursiveList(): void {
    this.recursiveTreeList = [];
    if (this.treeList && this.treeList.length > 0) {
      const headCommittee = this.treeList.find(
        x => x.committeePosition.level === 1
      );
      const viceHeadCommittee = this.treeList.find(
        x => x.committeePosition.level === 2
      );
      const member = this.treeList.filter(x => x.committeePosition.level === 3);
      if (headCommittee) {
        const object = {
          id: headCommittee.id,
          committee: headCommittee.committee,
          committeePosition: headCommittee.committeePosition,
          user: headCommittee.user,
          name: headCommittee.name,
          email: headCommittee.email,
          organization: headCommittee.organization,
          childrens: []
        };
        this.recursiveTreeList.push(object);
      }
      if (viceHeadCommittee) {
        const object = {
          id: viceHeadCommittee.id,
          committee: viceHeadCommittee.committee,
          committeePosition: viceHeadCommittee.committeePosition,
          user: viceHeadCommittee.user,
          name: viceHeadCommittee.name,
          email: viceHeadCommittee.email,
          organization: viceHeadCommittee.organization,
          childrens: []
        };
        this.recursiveTreeList[0].childrens.push(object);
      }

      if (member) {
        member.forEach(element => {
          if (viceHeadCommittee) {
            this.recursiveTreeList[0].childrens[0].childrens.push(element);
          } else if (headCommittee) {
            this.recursiveTreeList[0].childrens.push(element);
          } else {
            this.recursiveTreeList.push(element);
          }
        });
      }
    }
  }
}
