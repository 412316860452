<app-popup header="tab-blacklist-history.popup.title" [isLoading]="formLoading">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" novalidate>
        <h5 class="separator">{{
          'tab-blacklist-history.separator.dataVendor' | translate
        }}</h5>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-blacklist-history.form.vendorName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p>{{ vendorBlacklist.vendor.name }}</p>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-blacklist-history.form.vendorCode' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p>{{ vendorBlacklist.vendor.code }}</p>
          </div>
        </div>
        <br />
        <h5 class="separator">{{
          'tab-blacklist-history.separator.vendorViolation' | translate
        }}</h5>
        <div
          class="table-responsive custom-table float-none"
          [ngStyle]="{ margin: '0 auto' }"
        >
          <table
            class="table table-striped box-shadow"
            aria-describedby="Table"
          >
            <thead>
              <tr class="text-center">
                <th scope="col">{{ 'app.table.column.no' | translate }}</th>
                <th scope="col">
                  {{
                    'tab-blacklist-history.table.column.blacklistCriteriaName'
                      | translate
                  }}
                </th>
                <th scope="col">
                  {{
                    'tab-blacklist-history.table.column.blacklistCriteriaDuration'
                      | translate
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="vendorViolationList.length == 0">
                <td colspan="99" class="bg-white text-center">
                  {{ 'app.info.noData' | translate }}
                </td>
              </tr>
              <tr
                *ngFor="
                  let vendorViolation of vendorViolationList;
                  let i = index
                "
              >
                <td class="text-center">{{ i + 1 }}.</td>
                <td>
                  {{ vendorViolation.blacklistCriteria.violation }}
                </td>
                <td class="text-center">
                  {{
                    global.converterService.convertMonthToYears(
                      vendorViolation.blacklistCriteria.durationInMonth
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h5 class="separator"
          >{{
            'tab-blacklist-history.separator.blacklistInformation' | translate
          }}
        </h5>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-blacklist-history.form.startDate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p>{{ vendorBlacklist.startDate | date: 'dd/MM/yyyy' }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-blacklist-history.form.blacklistDuration' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p>{{ vendorBlacklist.blacklistDuration.name }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-blacklist-history.form.note' | translate
          }}</label>
          <div class="text-left col-sm-6">
            <p>{{ vendorBlacklist.note }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-blacklist-history.form.reason' | translate
          }}</label>
          <div class="text-left col-sm-6">
            <p>{{ vendorBlacklist.reason }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-blacklist-history.form.docFile' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div *ngIf="vendorBlacklist.file == null"> - </div>
            <div *ngIf="vendorBlacklist.file != null">
              <a
                href="#"
                (click)="
                  doDownload(
                    $event,
                    '/vendor-blacklist/file/view/' +
                      vendorBlacklist.file.uploadedFileName +
                      '/' +
                      global.appConstant.fileType.DOC_VIOLATION_BLACKLIST,
                    vendorBlacklist.file.fileName
                  )
                "
              >
                <p>{{ vendorBlacklist.file.fileName }}</p>
              </a>
            </div>
          </div>
        </div>

        <ng-template #modalFooter let-activeModal>
          <div class="button-group button-group-center">
            <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
              'app.button.close' | translate
            }}</app-button>
          </div>
        </ng-template>
      </form>
    </div>
  </div>
</app-popup>
