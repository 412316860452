import { Injectable } from '@angular/core';
import { TabResponseModel } from 'src/app/core/model/tab/tab-response-model';

@Injectable()
export class CompanyProfileServie {
    public tabResponse: TabResponseModel;

    public setTabResponse(tabResponse: TabResponseModel): void {
        this.tabResponse = tabResponse;
    }

    public getTabResponse(): TabResponseModel {
        return this.tabResponse;
    }
}
