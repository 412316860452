import { DatePickerTime } from '../model/date-picker-time-model';
export const createDatePickerTimeList = (): Array<DatePickerTime> => {
  return Array(24)
    .fill(0)
    .map((val, index) => {
      const time = val + index < 10 ? '0' + (val + index) : `${val + index}`;
      return `${time}:00,${time}:30`;
    })
    .toString()
    .split(',')
    .map(timeString => {
      const timeStringSplit = timeString.split(':');
      return {
        timeString,
        hoursString: timeStringSplit[0],
        minutesString: timeStringSplit[1]
      };
    });
};
