import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Module } from '../../core/bean/module';
import { WorkloadLevel } from '../../core/bean/workload-level';
import { WorkloadPrcs } from '../../core/bean/workload-prcs';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { WorkloadInfoModel } from '../../core/model/workload-info-model';
import { WorkloadUserLevelView } from '../../core/view/entity/bean/workload-user-level-view';
import { WorkloadMonitoringCustomDataRequest } from './workload-monitoring-custom-data-request';
import { WorkloadMonitoringResponse } from './workload-monitoring-response';

@Component({
  templateUrl: './workload-monitoring.component.html'
})
export class WorkloadMonitoringComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<WorkloadUserLevelView>;

  public currentPosition: string;
  public module: Module;
  public worklaodInfoModelList: WorkloadInfoModel[] = [];

  public workloadLevelOptionList: OptionListModel<WorkloadLevel> =
    new OptionListModel(true);

  public constructor(translateService: TranslateService) {
    super('workload-monitoring', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.buildFormGroup();
    this.getWorkloadInfo();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      workloadLevelList: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'user.name',
        header: 'table.column.name',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClickUser.bind(this)
        }
      },
      {
        field: 'totalNewAssignment',
        header: 'table.column.newAssignment',
        className: 'text-right',
        plugins: {
          name: 'custom-plugin',
          after: (tablePlugin: TablePluginData): void => {
            if (!tablePlugin.value) {
              tablePlugin.element.innerText = '0';
            }
          }
        }
      },
      {
        field: 'totalOnProgressAssignment',
        header: 'table.colum.onProgressAssignment',
        className: 'text-right',
        plugins: {
          name: 'custom-plugin',
          after: (tablePlugin: TablePluginData): void => {
            if (!tablePlugin.value) {
              tablePlugin.element.innerText = '0';
            }
          }
        }
      },
      {
        field: 'workloadLevel.name',
        header: 'table.colum.workloadLevel',
        plugins: [
          {
            name: 'badge',
            colorField: 'workloadLevel.code',
            colorMap: {
              LOW: 'SUCCESS',
              MEDIUM: 'WARNING',
              HIGH: 'DANGER',
              NOT_ASSIGNED: 'SECONDARY'
            }
          }
        ]
      }
    ]);
  }

  public getWorkloadInfo(): void {
    this.httpClientService
      .get<WorkloadMonitoringResponse>('/workload-monitoring/get-workload-info')
      .subscribe((response: WorkloadMonitoringResponse) => {
        this.worklaodInfoModelList = response.workloadInfoModelList;
        this.workloadLevelOptionList.setRequestValues(
          response.workloadLevelList
        );
        const request = new WorkloadMonitoringCustomDataRequest();
        if (this.worklaodInfoModelList[0]) {
          request.moduleCode = this.worklaodInfoModelList[0].module.code;
          this.module = this.worklaodInfoModelList[0].module;
          this.currentPosition = this.worklaodInfoModelList[0].module.code;
        }
        this.tableResponse.setCustomData(request);
        this.tableResponse.reload();
        this.setStateReady();
      });
  }

  public doChangeModule(module: Module): void {
    if (this.tableResponse) {
      this.module = module;
      this.currentPosition = module.code;
      const request = new WorkloadMonitoringCustomDataRequest();
      request.moduleCode = module.code;
      this.tableResponse.setCustomData(request);
      this.tableResponse.reload();
    }
  }

  public onChangeFilter(): void {
    const request = new WorkloadMonitoringCustomDataRequest();
    request.moduleCode = this.currentPosition;
    request.workloadLevelList = this.formGroup.value.workloadLevelList;
    this.tableResponse.setCustomData(request);
    this.tableResponse.reload();
  }

  public doReBalancing(): void {
    console.log('ReBalancing');
    const prompt = 'workload-monitoring.confirmation.reBalance.msg';

    const title =
      this.translateService.instant(
        'workload-monitoring.confirmation.reBalance.title'
      ) +
      ' ' +
      this.module.name +
      ' ' +
      this.translateService.instant(
        'workload-monitoring.confirmation.reBalance.msgAssignment'
      );
    this.global.modalService
      .confirmation(
        prompt,
        title,
        'app.button.cancel',
        'app.button.yesImSure',
        'pvc pv-handshake',
        false
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.httpClientService
            .post<Response<WorkloadPrcs>>(
              '/workload-monitoring/re-balance',
              this.currentPosition
            )
            .subscribe(response => {
              this.setStateReady();
              if (response.status === ResponseStatusModel.OK) {
                this.tableResponse.reload();
              }
            });
        }
      });
  }

  public doClickUser(workloadUserLevelView: WorkloadUserLevelView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('user', workloadUserLevelView.user);
    this.global.routerParams.set(
      'workloadLevel',
      workloadUserLevelView.workloadLevel
    );
    this.global.routerParams.set('moduleCode', this.currentPosition);
    this.router.navigate(['/pages/workload-monitoring/detail']);
  }
}
