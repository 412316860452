<app-popup [header]="header">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'app-popup-vendor-non-promise.form.name' | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field autofocus="true" size="LG" formControlName="name" maxLength="32">
        </app-text-field>
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'app-popup-vendor-non-promise.form.email' | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field size="LG" formControlName="email" type="email"
          maxLength="64"></app-text-field>
      </div>
    </div>

    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'app-popup-vendor-non-promise.form.phone' | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-phone size="LG" formControlName="phone"
          [optionList]="regionPhoneOptionList" maxLength="32"></app-phone>
      </div>
    </div>

    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'app-popup-vendor-non-promise.form.address' | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-text-area size="LG" formControlName="address" maxLength="512"></app-text-area>
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate }}</app-button>
        <app-button color="PRIMARY" (onClick)="doSave()">{{ 'app.button.save' |
          translate }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>