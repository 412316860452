import { BaseEntity } from '../base/base-entity';
import { Organization } from '../bean/organization';

export class WorkProgramEntity extends BaseEntity {
    organizationList: Organization[];

    name: string;
    description: string;
    activityPeriod: number;
}
