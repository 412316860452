import { PaymentVoucher } from '../../core/bean/payment-voucher';
import { PaymentVoucherBilling } from '../../core/bean/payment-voucher-billing';
import { PaymentVoucherOtherCost } from '../../core/bean/payment-voucher-other-cost';
import { PaymentVoucherTax } from '../../core/bean/payment-voucher-tax';

export class PaymentVoucherUpdateRequest {
  public isSubmit: boolean;
  public paymentId: number;
  public paymentVoucher: PaymentVoucher;
  public paymentVoucherBillingList: PaymentVoucherBilling[];
  public paymentVoucherTaxList: PaymentVoucherTax[];
  public paymentVoucherOtherCostList: PaymentVoucherOtherCost[];
}
