import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppLazyLoadModule } from '../app-lazy-load/app-lazy-load.module';
import { AppSelectResolveCheckedPipe } from './app-select-resolve-checked.pipe';
import { AppSelectComponent } from './app-select.component';

@NgModule({
  imports: [VendorModule, PipesModule, AppLazyLoadModule, AppButtonModule],
  declarations: [AppSelectComponent, AppSelectResolveCheckedPipe],
  exports: [AppSelectComponent]
})
export class AppSelectModule {}
