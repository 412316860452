import { CommitteeDecree } from 'src/app/core/bean/committee-decree';
import { FileObject } from 'src/app/core/components/upload';
import { Committee } from '../../core/bean/committee';
import { CommitteeParticipant } from '../../core/bean/committee-participant';

export class CommitteeRequest {
  committee: Committee;
  committeeParticipantList: CommitteeParticipant[] = [];
  committeeDecree: CommitteeDecree;
  fileObjectList: FileObject[] = [];
}
