import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import {
  expandCollapseAnimation,
  rotateAnimation
} from '../../../../animation';
import { BaseComponentComponent } from '../../../../base/angular/base-component.component';
import { UserPreference } from '../../../../bean/user-preference';
import { ValueType } from '../../../../bean/value-type';
import { Response } from '../../../../model/response-model';
import { ResponseStatusModel } from '../../../../model/response-status-model';
import { TextUtils } from '../../../../utils';
import { AppPopupEditTableComponent } from '../../../app-popup/app-popup-edit-table/app-popup-edit-table.component';
import { AppPopupService } from '../../../app-popup/app-popup.service';
import * as tableAction from '../../action/table-action';
import { TablePlugin } from '../../interfaces/table-plugin';
import { TableFieldModel } from '../../model/table-field-model';
import { TableRecord } from '../../model/table-record';
import { TableResponseModel } from '../../model/table-response-model';
import { TableState } from '../../model/table-state';
import { TableService } from '../../table.service';
import { TableEvent } from '../../types/table-event';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollY: true
};
@Component({
  selector: 'app-table-x',
  templateUrl: './app-table-x.component.html',
  styleUrls: ['./app-table-x.component.scss'],
  providers: [
    TableService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [expandCollapseAnimation(), rotateAnimation()]
})
export class AppTableXComponent
  extends BaseComponentComponent
  implements AfterViewInit
{
  @Input() header: string;
  @Input() model: TableResponseModel<any>;
  @Input() stringUrl: string;
  @Input() plugins: string | Array<string> | TablePlugin | Array<TablePlugin>;
  @Input() isShowEditTable: boolean;
  @Input() key: string;
  @Input() isView: boolean;
  @Input() isMultipleSelect: boolean;
  @Input() isShowCheckBox: boolean;
  @Input() isShowSearch: boolean;
  @Input() isShowPagination: boolean;
  @Input() isShowButtonExpandCollapse: boolean;

  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  @ContentChild('row') rowTemplate: TemplateRef<any>;
  @ContentChild('headerButtons') headerButtonsTmpl: TemplateRef<any>;
  @ContentChild('actionButtons') actionButtonsTemplate: TemplateRef<any>;
  @ContentChild('headerFilter') headerFilterTemplate: TemplateRef<any>;
  @ContentChild('headerFilterGroup')
  headerFilterGroupTemplate: TemplateRef<any>;
  @ContentChild('tableFooter') tableFooterTmpl: TemplateRef<any>;

  @ViewChild('theadDefault') theadDefaultElement: ElementRef;
  @ViewChild('theadFixed') theadFixedElement: ElementRef;

  public generatedId = TextUtils.generateRandomString();

  public state: TableState;

  // from old table
  private modelTemp: TableResponseModel<any>;
  public columnFieldList: Array<string> = new Array();
  public userPreference: UserPreference;

  constructor(
    private tableService: TableService,
    private appPopupService: AppPopupService
  ) {
    super('app-table');
  }

  onInit(): void {
    this.setInitializationstate();
    this.tableStateEventListener();
    this.modelReloadEventListener();
    this.modelDeleteEventListener();
    this.setStateReady();
  }

  private setInitializationstate(): void {
    this.modelTemp = Object.assign({}, this.model);
    this.setStateEditTable();
    this.state = this.tableService.setState(
      this.model,
      this.stringUrl,
      this.isView,
      this.isMultipleSelect,
      this.isShowPagination
    );
    this.tableService.dispatch(new tableAction.FirstLoadTable());
  }

  private tableStateEventListener(): void {
    this.state.event.subscribe((event: TableEvent) => {
      if (event.type === 'CLICK-RECORD') {
        this.onClick.emit(event.data.record);
      } else if (event.type === 'CHANGE-RECORD') {
        this.onChange.emit(event.data.record);
      }
    });
  }

  private modelReloadEventListener(): void {
    this.model.requestReload.subscribe(() => {
      this.tableService.dispatch(new tableAction.ReloadTable());
    });
  }

  private modelDeleteEventListener(): void {
    this.model.requestDelete.subscribe(() => {
      this.tableService.dispatch(new tableAction.DeleteTableRecord());
    });
  }

  ngAfterViewInit(): void {
    this.tableService.dispatch(
      new tableAction.SetStickyTableHeader({
        headerFixedElementRef: this.theadFixedElement,
        headerTempElementRef: this.theadDefaultElement
      })
    );
  }

  public doClickInputCheckbox(event: PointerEvent): void {
    event.stopPropagation();
  }

  public onChangeSelectTableRecord(
    isChecked: boolean,
    record: TableRecord,
    parentRecord: TableRecord
  ): void {
    this.tableService.dispatch(
      new tableAction.SelectTableRecord({ record, isChecked, parentRecord })
    );
  }

  public onChangeSelectAllTableRecord(isChecked: boolean): void {
    this.tableService.dispatch(
      new tableAction.SelectAllTableRecord({ isChecked })
    );
  }

  public doSort(column: TableFieldModel): void {
    this.tableService.dispatch(new tableAction.SortTable({ column }));
  }

  public doResetInputSearch(
    inputSearch: HTMLElement & { value: string }
  ): void {
    inputSearch.value = '';
    this.tableService.dispatch(new tableAction.SearchTable({ keywords: '' }));
  }

  public onInputSearch(keywords: string): void {
    this.tableService.dispatch(new tableAction.SearchTable({ keywords }));
  }

  public onChangePage(currentPage: number): void {
    this.tableService.dispatch(
      new tableAction.ChangeTablePage({ page: currentPage })
    );
  }

  public onChangeFilterPerPage(perPage: number): void {
    this.tableService.dispatch(
      new tableAction.ChangeFilterTablePerPage({ perPage })
    );
  }

  public doLoadTableRecordChildren(record: TableRecord): void {
    this.tableService.dispatch(
      new tableAction.LoadTableRecordChildrens({ record })
    );
  }

  public doExpandOrCollapseTableRow(record: TableRecord): void {
    this.tableService.dispatch(
      new tableAction.ExpandOrCollapseTableRow({ record })
    );
  }

  public doExpandOrCollapseAllTableRow(action: 'EXPAND' | 'COLLAPSE'): void {
    this.tableService.dispatch(
      new tableAction.ExpandOrCollapseAllTableRow({ action })
    );
  }

  public onScrollEnd(): void {
    this.onChangePage(this.state.pagination.page + 1);
  }

  public onPsScrollX(event: CustomEvent & { target: HTMLElement }): void {
    if (this.isShowPagination === false) {
      const theadFixedElement: HTMLElement =
        this.theadFixedElement.nativeElement.parentElement.parentElement;
      theadFixedElement.scrollTo({
        left: event.target.scrollLeft
      });
    }
  }

  // start edit table section
  public setStateEditTable(): void {
    this.userPreference = new UserPreference();
    if (this.isShowEditTable) {
      const name =
        'DATA-TABLE-' +
        this.model.moduleCode.toUpperCase() +
        (this.key ? '-' + this.key.toUpperCase() : '');
      this.userPreference.value =
        this.global.userSession.userPreferenceMap[name];
      if (this.userPreference.value) {
        this.columnFieldList = JSON.parse(this.userPreference.value);
        this.setStateModelColumn();
      }
    }
  }

  private setStateModelColumn(): void {
    this.model.columns = this.modelTemp.columns.filter(
      column => this.columnFieldList.indexOf(column.field) !== -1
    );
  }

  public doShowPopupEditTable(): void {
    this.appPopupService
      .open(
        AppPopupEditTableComponent,
        {
          tableParentResponse: this.modelTemp,
          columnFieldList: this.columnFieldList
        },
        { windowClass: 'app-popup-modify-table' }
      )
      .subscribe((columnFieldList: Array<any>) => {
        this.columnFieldList = columnFieldList;
        this.setStateModelColumn();
        const url = '/config/update-user-preference';
        const userPreference = this.userPreference;
        userPreference.name =
          'DATA-TABLE-' +
          this.model.moduleCode.toUpperCase() +
          (this.key ? '-' + this.key.toUpperCase() : '');
        userPreference.value = JSON.stringify(columnFieldList);
        userPreference.valueType = new ValueType();
        userPreference.valueType.code = 'STRING';
        this.httpClientService
          .post<Response<UserPreference>>(url, userPreference)
          .subscribe(response => {
            if (response.status === ResponseStatusModel.OK) {
              this.global.userSession.userPreferenceMap[userPreference.name] =
                userPreference.value;
              this.userPreference.value = userPreference.value;
            } else {
              this.model.columns = this.modelTemp.columns;
            }
          });
      });
  }
}
