import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from './../../core/shared/shared.module';
import { PaymentTermEditAddComponent } from './payment-term-edit-add.component';
import { PaymentTermComponent } from './payment-term.component';
export const routes = [
  { path: '', component: PaymentTermComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: PaymentTermEditAddComponent,
    data: { breadcrumb: 'payment-term.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: PaymentTermEditAddComponent,
    data: { breadcrumb: 'payment-term.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [PaymentTermComponent, PaymentTermEditAddComponent]
})
export class PaymentTermModule {}
