import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../../core/base/angular/base-module.component';
import { Employee } from '../../../../core/bean/employee';
import { TabModel } from '../../../../core/model/tab/tab-model';
import { TabResponseModel } from '../../../../core/model/tab/tab-response-model';
@Component({
  templateUrl: './tab-three.component.html',
  styleUrls: ['./tab-three.component.scss']
})
export class ExampleTabThreeComponent extends BaseModuleComponent {
  public tabResponse: TabResponseModel;
  public tabOne: TabModel;
  public tabTwo: TabModel;
  public employee: Employee;
  public addtionalInformation: any;

  constructor(translate: TranslateService) {
    super('', translate);
  }

  onInit(): void {
    this.setTabResponseAndTab();
    this.setEmployee();
  }

  public setTabResponseAndTab(): void {
    this.tabResponse = this.global.routerParams.get('tabResponse');
    this.tabOne = this.tabResponse.tabs.get('TABONE');
    this.tabTwo = this.tabResponse.tabs.get('TABTWO');
  }

  public setEmployee(): void {
    this.employee = this.tabOne.value;
    this.addtionalInformation = this.tabTwo.value;
  }
}
