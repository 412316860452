import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DirectivesModule } from '../../core/directives/directives.module';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPortaltableFilterModule } from '../../themes/promise-portal/components/app-portal-table-filter/app-portal-table-filter.module';
import { AppPortalTableModule } from '../../themes/promise-portal/components/app-portal-table/app-portal-table.module';
import { NewsPopupComponent } from './news-popup.component';
import { NewsComponent, ResolveIsShowReadMorePipe } from './news.component';
const routes: Routes = [{ path: '', component: NewsComponent }];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppPortalTableModule,
    DirectivesModule,
    AppPortaltableFilterModule
  ],
  declarations: [NewsComponent, NewsPopupComponent, ResolveIsShowReadMorePipe],
  entryComponents: [NewsPopupComponent]
})
export class NewsModule {}
