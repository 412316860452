import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { RfpStatus } from '../bean/rfp-status';
import { User } from '../bean/user';

export class RfpEntity extends BaseWorkflowObjectEntity {
  /* kebutuhan index rfp di popup tem rfq */
  createdDate: Date = new Date();
  createdByUser: User;

  rfpStatus: RfpStatus = new RfpStatus();
  startDate: Date = new Date();
  endDate: Date = new Date();
  title: string;
  note: string;
  minimumScore: number;
  proposalPercentage: number;
  pricePercentage: number;
  amount: number;
  goodsAmount: number;
  serviceAmount: number;
}
