import { Component, ViewEncapsulation } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Resource } from '../../core/bean/resource';
import { AppPopupResourceService } from '../../core/components/app-popup/app-popup-resource/app-popup-resource.service';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { BaseModuleComponent } from './../../core/base/angular/base-module.component';
import { Lang } from './../../core/bean/lang';
@Component({
  templateUrl: './lang-edit-add.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LangEditAddComponent extends BaseModuleComponent {
  public langId: number;
  public isReadOnly = false;
  public isDisabled = true;
  public resource: Resource = new Resource();
  constructor(
    translateService: TranslateService,
    public appPopupResourceService: AppPopupResourceService
  ) {
    super('lang', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.langId = this.global.routerParams.get('langId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [null, Validators.required()],
      code: [null, Validators.required()],
      isActive: [null, Validators.required()],
      isDefault: [false],
      isReadOnly: false,
      resource: [null, Validators.required()]
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<Lang>(
        '/lang/add-edit',
        new RouteRequestModel(this.todo, this.langId)
      )
      .subscribe(lang => {
        if (lang !== null) {
          const { id, code, isDefault, isReadOnly, name, resource } = lang;
          this.formGroup.patchValue({
            id,
            code,
            isDefault,
            isReadOnly,
            name,
            resource
          });
          this.formGroup.get('isActive').patchValue(lang.isActive.toString());
          this.isReadOnly = lang.isReadOnly;
          this.isDisabled = lang.isActive;
        }
        this.setStateReady();
        if (lang !== null && lang.isActive === false) {
          this.formGroup.get('isDefault').disable();
        }
      });
  }

  public doChooseFlagIcon(): void {
    this.appPopupResourceService.open().subscribe(data => {
      this.formGroup.patchValue({ resource: data });
    });
  }

  public doChangeStatus(param?: any): void {
    if (param.target.value === 'false') {
      this.formGroup.patchValue({ isDefault: false });
      this.formGroup.get('isDefault').disable();
    } else {
      this.formGroup.get('isDefault').enable();
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.formGroup.patchValue({
              isActive:
                this.formGroup.get('isActive').value === 'true' ? true : false
            });
            const lang = this.global.copyFormAttributeToModel(
              new Lang(),
              this.formGroup
            );
            const url = this.todo === 'edit' ? '/lang/update' : '/lang/insert';
            this.httpClientService
              .post<Response<Lang>>(url, lang)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/lang']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.formGroup
                    .get('code')
                    .setErrors({ message: response.statusText });
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/lang']);
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
}
