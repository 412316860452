<div class="row">
  <div class="col-lg-12">
    <app-card header="ots.title" [isSaving]="formSaving" [isLoading]="formLoading">
      <div *ngIf="!formLoading">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right" [ngStyle]="{ 'margin-left': '1px' }">
          <label class="col-sm-3 control-label">{{
            'ots.form.companyName' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            {{ otsResponse.vendorHistory.name }}
          </div>
        </div>

        <div class="form-group row text-sm-right" [ngStyle]="{ 'margin-left': '1px' }">
          <label class="col-sm-3 control-label">{{
            'ots.form.regisNum' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            {{ otsResponse.vendorHistory.registrationNumber }}
          </div>
        </div>

        <div class="form-group row text-sm-right" [ngStyle]="{ 'margin-left': '1px' }">
          <label class="col-sm-3 control-label">{{
            'ots.form.dueDiligenceNum' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            {{ otsResponse.dueDiligenceScheduleOTS.dueDiligenceNumber }}
          </div>
        </div>

        <!-- Schedule Plan -->
        <div class="card border-0">
          <div class="card-header bg-transparent" id="heading1" [ngStyle]="{ padding: '1rem 0 0.4rem' }">
            <h5 class="d-inline-block mb-0">{{
              'ots.form.schedulePlan' | translate
              }}</h5>
            <em class="fas fa-chevron-circle-down cursor-pointer float-right" data-toggle="collapse"
              [attr.data-target]="'#collapseSchedule'" aria-expanded="true" [attr.aria-controls]="'collapseSchedule'"
              [ngStyle]="{ 'font-size': '1.3rem' }"></em>
          </div>
          <div id="collapseSchedule" class="collapse show" [attr.aria-labelledby]="'heading1'">
            <div class="card-body">
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'ots.form.address' | translate
                  }}</label>
                <div class="col-sm-5 text-left">
                  {{ otsResponse.dueDiligenceScheduleOTS.address }}
                </div>
              </div>

              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'ots.form.date' | translate
                  }}</label>
                <div class="col-sm-4 text-left">
                  {{
                  otsResponse.dueDiligenceScheduleOTS.date
                  | date: global.config.parameterModel.dateFormatShort
                  }}
                </div>
              </div>

              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'ots.form.time' | translate
                  }}</label>
                <div class="col-sm-9 text-left">
                  <strong>{{ 'ots.form.from' | translate }}</strong>
                  {{
                  getHours(otsResponse.dueDiligenceScheduleOTS.startTime)
                  }}
                  WIB <strong>{{ 'ots.form.until' | translate }}</strong>
                  {{ getHours(otsResponse.dueDiligenceScheduleOTS.endTime) }}
                  WIB
                </div>
              </div>

              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'ots.form.objective' | translate
                  }}</label>
                <div class="col-sm-5 text-left">
                  {{ otsResponse.dueDiligenceScheduleOTS.objective }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Task Giver HTML Table-->
        <div class="card border-0">
          <div class="card-header bg-transparent" id="heading2" [ngStyle]="{ padding: '1rem 0 0.4rem' }">
            <h5 class="d-inline-block mb-0">{{
              'ots.form.taskGiver' | translate
              }}</h5>
            <em class="fas fa-chevron-circle-down float-right cursor-pointer" data-toggle="collapse"
              [attr.data-target]="'#collapseTaskGiver'" aria-expanded="true" [attr.aria-controls]="'collapseTaskGiver'"
              [ngStyle]="{ 'font-size': '1.3rem' }"></em>
          </div>
          <div id="collapseTaskGiver" class="collapse show" [attr.aria-labelledby]="'heading2'">
            <div class="card-body">
              <div class="table-responsive custom-table float-none" [ngStyle]="{ margin: '0 auto' }">
                <table class="table table-striped box-shadow" aria-describedby="Table">
                  <thead>
                    <tr>
                      <th scope="col" [ngStyle]="{ width: '250px' }" class="text-center">
                        {{ 'app.table.column.no' | translate }}
                      </th>
                      <th scope="col" [ngStyle]="{ width: '400px' }">{{
                        'ots.table.column.name' | translate
                        }}</th>
                      <th scope="col">{{
                        'ots.table.column.position' | translate
                        }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-left" *ngFor="
                        let data of otsResponse.dueDiligenceAssignerTaskGiverList;
                        let i = index
                      ">
                      <td class="text-center">{{ i + 1 }}.</td>
                      <td>{{ data.user.name }}</td>
                      <td *ngIf="data.position">{{ data.position }}</td>
                      <td *ngIf="!data.position"> - </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!--Acknowledged By HTML Table-->
        <div class="card border-0">
          <div class="card-header bg-transparent" id="heading3" [ngStyle]="{ padding: '1rem 0 0.4rem' }">
            <h5 class="d-inline-block mb-0">{{
              'ots.form.acknowledgedBy' | translate
              }}</h5>
            <em class="fas fa-chevron-circle-down float-right cursor-pointer" data-toggle="collapse"
              [attr.data-target]="'#collapseAcknowledged'" aria-expanded="true"
              [attr.aria-controls]="'collapseAcknowledged'" [ngStyle]="{ 'font-size': '1.3rem' }"></em>
          </div>
          <div id="collapseAcknowledged" class="collapse show" [attr.aria-labelledby]="'heading3'">
            <div class="card-body">
              <div class="table-responsive custom-table float-none" [ngStyle]="{ margin: '0 auto' }">
                <table class="table table-striped box-shadow" aria-describedby="Table">
                  <thead>
                    <tr>
                      <th scope="col" [ngStyle]="{ width: '250px' }" class="text-center">
                        {{ 'app.table.column.no' | translate }}
                      </th>
                      <th scope="col" [ngStyle]="{ width: '400px' }">{{
                        'ots.table.column.name' | translate
                        }}</th>
                      <th scope="col">{{
                        'ots.table.column.position' | translate
                        }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="
                        otsResponse.dueDiligenceAssignerAcknowledgedByList
                          .length == 0 ||
                        otsResponse.dueDiligenceAssignerAcknowledgedByList ==
                          null
                      ">
                      <td colspan="99" class="bg-white text-center">
                        {{ 'app.info.noData' | translate }}</td>
                    </tr>
                    <tr *ngFor="
                        let data of otsResponse.dueDiligenceAssignerAcknowledgedByList;
                        let i = index
                      ">
                      <td class="text-center">{{ i + 1 }}.</td>
                      <td>{{ data.user.name }}</td>
                      <td *ngIf="data.position">{{ data.position }}</td>
                      <td *ngIf="!data.position"> - </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!--Executor OTS HTML Table-->
        <div class="card border-0">
          <div class="card-header bg-transparent" id="heading4" [ngStyle]="{ padding: '1rem 0 0.4rem' }">
            <h5 class="d-inline-block mb-0">{{
              'ots.form.executor' | translate
              }}</h5>
            <em class="fas fa-chevron-circle-down float-right cursor-pointer" data-toggle="collapse"
              [attr.data-target]="'#collapseExecutorOTS'" aria-expanded="true"
              [attr.aria-controls]="'collapseExecutorOTS'" [ngStyle]="{ 'font-size': '1.3rem' }"></em>
          </div>
          <div id="collapseExecutorOTS" class="collapse show" [attr.aria-labelledby]="'heading4'">
            <div class="card-body">
              <div class="table-responsive custom-table float-none" [ngStyle]="{ margin: '0 auto' }">
                <table class="table table-striped box-shadow" aria-describedby="Table">
                  <thead>
                    <tr>
                      <th scope="col" [ngStyle]="{ width: '250px' }" class="text-center">
                        {{ 'app.table.column.no' | translate }}
                      </th>
                      <th scope="col" [ngStyle]="{ width: '400px' }">{{
                        'ots.table.column.name' | translate
                        }}</th>
                      <th scope="col">{{
                        'ots.table.column.position' | translate
                        }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="
                        otsResponse.dueDiligenceExecutorOTSList.length == 0 ||
                        otsResponse.dueDiligenceExecutorOTSList == null
                      ">
                      <td colspan="99" class="bg-white text-center">
                        {{ 'app.info.noData' | translate }}</td>
                    </tr>
                    <tr *ngFor="
                        let data of otsResponse.dueDiligenceExecutorOTSList;
                        let i = index
                      ">
                      <td class="text-center">{{ i + 1 }}.</td>
                      <td>{{ data.name }}</td>
                      <td>{{
                        data.position && data.position !== ''
                        ? data.position
                        : '-'
                        }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Realization -->
        <div class="card border-0">
          <div class="card-header bg-transparent" id="heading5" [ngStyle]="{ padding: '1rem 0 0.4rem' }">
            <h5 class="d-inline-block mb-0">{{
              'ots.form.realization' | translate
              }}</h5>
            <em class="fas fa-chevron-circle-down float-right cursor-pointer" data-toggle="collapse"
              [attr.data-target]="'#collapseRealization'" aria-expanded="true"
              [attr.aria-controls]="'collapseRealization'" [ngStyle]="{ 'font-size': '1.3rem' }"></em>
          </div>
          <div id="collapseRealization" class="collapse show" [attr.aria-labelledby]="'heading5'">
            <div class="card-body">
              <div class="form-group row text-sm-right" [ngClass]="!formGroup.isView ? 'required' : ''">
                <label class="col-sm-3 control-label">{{
                  'ots.form.date' | translate
                  }}</label>
                <div class="col-sm-9 col-lg-7 text-left">
                  <app-date-picker formControlName="date"></app-date-picker>
                </div>
              </div>

              <!-- time if isView false -->
              <div *ngIf="!isView" class="form-group row text-sm-right">
                <div class="col-sm-3 mb-3 pr-1 align-items-center" [ngStyle]="{
                    display: 'grid',
                    'padding-right': '0.4rem',
                    height: '72px'
                  }">
                  <label class="control-label">
                    {{ 'ots.form.time' | translate
                    }}<span *ngIf="!formGroup.isView" [ngStyle]="{ color: 'red' }">
                      *</span>
                  </label>
                </div>
                <div class="form-group row col-sm-9 text-left">
                  <label class="px-3 align-items-center" [ngStyle]="{
                      display: 'grid',
                      height: '72px'
                    }"><strong>{{
                      'ots.form.from' | translate
                      }}</strong></label>
                  <div [ngStyle]="{ 'max-width': '135px' }">
                    <app-time formControlName="startTime"></app-time>
                  </div>
                  <label class="ml-3 mr-3 align-items-center" [ngStyle]="{
                      display: 'grid',
                      height: '72px'
                    }"><strong>{{
                      'ots.form.until' | translate
                      }}</strong></label>
                  <div [ngStyle]="{ 'max-width': '135px' }">
                    <app-time formControlName="endTime"></app-time>
                  </div>
                  <label class="ml-3 align-items-center" [ngStyle]="{
                      display: 'grid',
                      height: '72px'
                    }"><strong>WIB</strong></label>
                </div>
              </div>

              <!-- time if isView true -->
              <div *ngIf="isView" class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'ots.form.time' | translate
                  }}</label>
                <div class="col-sm-5 text-left">
                  <strong>{{ 'ots.form.from' | translate }}</strong>
                  {{
                  getHours(
                  otsResponse.dueDiligenceRealizationOTS?.startTime
                  )
                  }}
                  WIB <strong>{{ 'ots.form.until' | translate }}</strong>
                  {{
                  getHours(otsResponse.dueDiligenceRealizationOTS?.endTime)
                  }}
                  WIB
                </div>
              </div>
              <div class="form-group row text-sm-right" [ngClass]="!formGroup.isView ? 'required' : ''">
                <label class="col-sm-3 control-label">{{
                  'ots.form.note' | translate
                  }}</label>
                <div class="text-left" [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'">
                  <app-text-area maxLength="512" formControlName="note">
                  </app-text-area>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Company Data -->
        <div class="card border-0">
          <div class="card-header bg-transparent" id="heading6" [ngStyle]="{ padding: '1rem 0 0.4rem' }">
            <h5 class="d-inline-block mb-0">{{
              'ots.form.companyData' | translate
              }}</h5>
            <em class="fas fa-chevron-circle-down float-right cursor-pointer" data-toggle="collapse"
              [attr.data-target]="'#collapseCompanyData'" aria-expanded="true"
              [attr.aria-controls]="'collapseCompanyData'" [ngStyle]="{ 'font-size': '1.3rem' }"></em>
          </div>
          <div id="collapseCompanyData" class="collapse show" [attr.aria-labelledby]="'heading6'">
            <div class="card-body">
              <!-- formArray if isView = false -->
              <ng-container formArrayName="credentialsOTS" *ngIf="!isView">
                <div class="form-group row text-sm-right required"
                  *ngFor="let creds of inputListOTS.controls; let i = index" [formGroupName]="i">
                  <div class="col text-left">
                    <div class="form-row">
                      <div class="form-group row col-3 pr-0">
                        <div class="col text-right pr-0">
                          <label><strong>{{
                              creds.value.attribute | translate
                              }}</strong></label>
                        </div>
                      </div>
                      <div class="form-group row col-sm-4 justify-content-center">
                        <div class="col-9 text-left">
                          <app-combo-box autofocus="true" [optionList]="optionList" size="LG" formControlName="isValid">
                          </app-combo-box>
                        </div>
                      </div>
                      <div class="form-group row col-4">
                        <label class="control-label">{{
                          'ots.form.note' | translate
                          }}</label>
                        <div class="col ml-3 text-left">
                          <app-text-area size="XL" maxLength="512" formControlName="noteOTS"></app-text-area>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- formArray if isView = true -->
              <ng-container formArrayName="credentialsOTS" *ngIf="isView">
                <div class="form-group row text-sm-right" *ngFor="let creds of inputListOTS.controls; let i = index"
                  [formGroupName]="i">
                  <div class="col text-left">
                    <div class="form-row">
                      <div class="form-group row col-3 pr-0">
                        <div class="col text-right pr-0">
                          <label><strong>{{
                              creds.value.attribute | translate
                              }}</strong></label>
                        </div>
                      </div>
                      <div class="form-group row col-sm-2">
                        <div class="col-7 text-center">
                          <label *ngIf="creds.value.isValid?.value">{{
                            'ots.form.yes' | translate
                            }}</label>
                          <label *ngIf="!creds.value.isValid?.value">{{
                            'ots.form.no' | translate
                            }}</label>
                        </div>
                      </div>
                      <div class="form-group row col-4">
                        <label class="control-label">{{
                          'ots.form.note' | translate
                          }}</label>
                        <div class="col ml-3 text-left">
                          <label class="text-break text-justify">{{
                            creds.value.noteOTS || '-'
                            }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <br />
        <h5 class="separator">{{ 'ots.form.upload' | translate }}</h5>
        <div class="card-body">
          <div class="form-group row text-sm-right" [ngClass]="!formGroup.isView ? 'required' : ''">
            <label class="col-sm-3 control-label">{{
              'ots.form.file' | translate
              }}</label>
            <div class="col-sm-9 text-left">
              <app-upload-multiple size="MD" formControlName="filesOTS" [fileUploader]="fileUploaders">
              </app-upload-multiple>
            </div>
          </div>
        </div>
      </form>

      <div class="button-group button-group-center">
        <app-button [disabled]="formSaving" *ngIf="!otsResponse.isUserApproval" color="SECONDARY" (onClick)="doBack()"
          mode="BYPASS">{{ 'app.button.back' | translate }}
        </app-button>
        <app-button [disabled]="formSaving" *ngIf="!formGroup.isView" color="PRIMARY" (onClick)="doSaveOTS()">{{
          'app.button.save' | translate }}</app-button>

          <app-button *ngIf="otsResponse.isUserApproval"
          color="SECONDARY"
          (onClick)="doBackApproval()"
          [disabled]="formSaving"
          mode="BYPASS"
          >{{ 'app.button.back' | translate }}</app-button>
      </div>
      </div> <!--!formLoading-->
    </app-card>
  </div>
</div>