import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppPopupService } from '../../../../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../../../../core/components/app-table/app-table.component';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { AppPopupDocumentExpiredComponent } from './app-popup-document-expired.component';
import { WidgetVendorInformationResponse } from './widget-vendor-information.response';
@Component({
  templateUrl: './widget-vendor-information.component.html',
  styleUrls: ['./widget-vendor-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetVendorInformationComponent extends BaseWidgetComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;

  public static moduleCode = 'dashboard-widget-vendor-information';
  public isShowSliderControl = false;
  public itemList = [1, 2, 3];
  public vendorInformationResponse: WidgetVendorInformationResponse;
  public vendorId = 0;
  public space = '    ';
  public isPerformanceShow = false;
  public isDocumentShow = false;
  public isRfiShow = false;
  constructor(public appPopupService: AppPopupService) {
    super('dashboard-widget-vendor-information');
  }

  public onInit(): void {
    this.isShowSliderControl = this.itemList.length > 5;
    this.getAndSetVendorInformation();
  }

  public getAndSetVendorInformation(): void {
    this.httpClientService
      .get('/widget-vendor-information/index')
      .subscribe((response: any) => {
        this.vendorInformationResponse = response;
        if (response !== null) {
          if (this.vendorInformationResponse.paramList !== null) {
            this.vendorInformationResponse.paramList.forEach(param => {
              if (param === 'PERFORMANCE') {
                this.isPerformanceShow = true;
              } else if (param === 'DOCUMENT') {
                this.isDocumentShow = true;
              } else if (param === 'RFI') {
                this.isRfiShow = true;
              }
            });
          }
          if (this.vendorInformationResponse.numberOfExpiredDoc !== 0) {
            this.vendorId = this.vendorInformationResponse.vendorId;
          }
        } else {
          this.vendorInformationResponse = null;
        }
        this.global.widgetLoadingStatus.set(this.moduleCode, true);
        this.setStateReady();
      });
  }

  public goToMoreDetail(vendorId: string): void {
    this.global.routerParams.set('vendorId', vendorId);
    this.appPopupService.open(
      AppPopupDocumentExpiredComponent,
      { vendorId },
      { size: 'lg' }
    );
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
