import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { TranslationEditComponent } from './translation-edit.component';
import { TranslationComponent } from './translation.component';
const routes = [
  { path: '', component: TranslationComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: TranslationEditComponent,
    data: { breadcrumb: 'translation.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [TranslationComponent, TranslationEditComponent]
})
export class TranslationModule {}
