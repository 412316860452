import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupVersionHistoryModel } from './app-popup-version-history-model';
import { AppPopupVersionHistoryComponent } from './app-popup-version-history.component';

@Injectable()
export class AppPopupVersionHistoryService {
  constructor(public appPopupService: AppPopupService) {}

  public open(modelList?: AppPopupVersionHistoryModel[]): Observable<any> {
    return this.appPopupService.open(AppPopupVersionHistoryComponent, {
      modelList
    });
  }
}
