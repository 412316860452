import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { NotificationBlastAddComponent } from './notification-blast-add.component';
import { NotificationBlastComponent } from './notification-blast.component';
const routes = [
  { path: '', component: NotificationBlastComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: NotificationBlastAddComponent,
    data: { breadcrumb: 'notification-blast.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: NotificationBlastAddComponent,
    data: { breadcrumb: 'notification-blast.breadcrumb.detail' }
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [NotificationBlastComponent, NotificationBlastAddComponent],
  providers: []
})
export class NotificationBlastModule {}
