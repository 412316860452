import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetWaitingApprovalComponent } from './widget-waiting-approval.component';

@NgModule({
  imports: [VendorModule, WidgetCardModule],
  declarations: [WidgetWaitingApprovalComponent],
  entryComponents: [WidgetWaitingApprovalComponent],
  exports: [WidgetWaitingApprovalComponent]
})
export class WidgetWaitingApprovalModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetWaitingApprovalComponent.moduleCode,
      WidgetWaitingApprovalComponent,
      'w-25'
    );
  }
}
