<div class="row main-row vendor-document-edit">
  <div class="col-12" [formGroup]="formGroup">
    <app-card
      header="{{ vendorName }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-accordion>
        <app-accordion-item
          header="Vendor Document Template"
          [isExpanded]="true"
        >
          <ng-container formArrayName="vendorDocTemplate">
            <div class="row py-1">
              <div class="col-sm-8 font-weight-bold">
                <div class="row">
                  <div class="col-sm-5">Document Name</div>
                  <div class="col-sm-5">File</div>
                </div>
              </div>
              <div class="col-4 text-right mb-3">
                <app-button size="SM" color="PRIMARY" (onClick)="addTemplate()">
                  <em class="fas fa-plus"></em>
                  {{ 'app.button.add' | translate }}
                </app-button>
              </div>
            </div>

            <div
              class="row py-1"
              *ngFor="
                let vendorDocTemplate of getFormArray('vendorDocTemplate')
                  .controls;
                let i = index
              "
              [formGroupName]="i"
            >
              <div class="col-sm-8">
                <div
                  class="row"
                  *ngIf="
                    vendorDocTemplate != null &&
                    vendorDocTemplate.value.crudOperation !== 0
                  "
                >
                  <div class="col-sm-5">
                    <app-text-field
                      autofocus="true"
                      size="XL"
                      formControlName="docName"
                      (onChange)="changeDocNameAndFile(i)"
                    ></app-text-field>
                  </div>
                  <div class="col-sm-5">
                    <app-upload
                      size="XL"
                      [fileUploader]="fileUploaderList[i]"
                      formControlName="templateFile"
                      (onChange)="changeDocNameAndFile(i)"
                    ></app-upload>
                  </div>
                  <div class="col-sm-2 text-left">
                    <app-button
                      size="TN"
                      color="DANGER"
                      (onClick)="removeTemplate(i)"
                    >
                      <em class="fas fa-trash"></em>
                    </app-button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </app-accordion-item>
      </app-accordion>

      <div
        class="row py-1 mb-2 mt-4 font-weight-bold"
        [ngStyle]="{ margin: '0 0.1rem' }"
      >
        <div class="col-sm-7">
          <div class="row">
            <div class="col-6">
              {{ 'vendor-document.form.nameTranslated' | translate }}
            </div>
            <div class="col-3 text-center">
              {{ 'vendor-document.form.isStart' | translate }}
            </div>
            <div class="col-3 text-center">
              {{ 'vendor-document.form.isExpiry' | translate }}
            </div>
          </div>
        </div>
        <div class="col-sm-5 text-right">
          <app-button
            size="SM"
            color="PRIMARY"
            (onClick)="doAddDocGroupList()"
            class="float-right"
          >
            {{ 'vendor-document.button.addDocGroup' | translate }}
          </app-button>
        </div>
      </div>

      <app-accordion formArrayName="parents">
        <app-accordion-item
          *ngFor="let parent of getFormArray('parents').controls; let i = index"
          [formGroupName]="i"
          [isHidden]="!(parent != null && parent.value.crudOperation !== 0)"
          [header]="parent.controls.docGroup.value.nameTranslated"
        >
          <ng-template #headerRight>
            <em
              class="fas fa-times text-danger float-right"
              [ngStyle]="{ 'padding-right': '50px' }"
              (click)="dodeleteDocGroupList(i)"
              (keyup)="onKeyUp($event)"
              (keydown)="onKeyDown($event)"
            ></em>
          </ng-template>
          <div class="row">
            <div
              class="col-12 text-right mb-3"
              [ngStyle]="{ 'padding-top': '0.5%' }"
            >
              <app-button
                size="SM"
                color="PRIMARY"
                (onClick)="doAddDocument(parent)"
              >
                {{ 'app.button.add' | translate }}
              </app-button>
            </div>
          </div>
          <ng-container formArrayName="vendorDocMasterList">
            <div
              class="row py-1"
              *ngFor="
                let vendorDocMasterLists of parent
                  | resolveFormArray: 'vendorDocMasterList';
                let j = index
              "
              [formGroupName]="j"
            >
              <div class="col-sm-7">
                <div
                  class="row"
                  *ngIf="
                    vendorDocMasterLists != null &&
                    vendorDocMasterLists.value.crudOperation !== 0
                  "
                >
                  <div class="col-6">
                    {{
                      vendorDocMasterLists.controls.fileType.value
                        .nameTranslated
                    }}
                  </div>
                  <div class="col-3 text-center">
                    <app-check-box
                      formControlName="isStart"
                      (onChange)="
                        changeIsStartIsExpired(
                          parent.controls.vendorDocMasterList,
                          j
                        )
                      "
                    >
                    </app-check-box>
                  </div>
                  <div class="col-3 text-center">
                    <app-check-box
                      formControlName="isExpiry"
                      (onChange)="
                        changeIsStartIsExpired(
                          parent.controls.vendorDocMasterList,
                          j
                        )
                      "
                    >
                    </app-check-box>
                  </div>
                </div>
              </div>
              <div
                class="col-sm-5"
                *ngIf="
                  vendorDocMasterLists != null &&
                  vendorDocMasterLists.value.crudOperation !== 0
                "
              >
                <app-button
                  size="TN"
                  color="DANGER"
                  (onClick)="
                    dodeleteDocument(parent.controls.vendorDocMasterList, j)
                  "
                >
                  <em class="fas fa-trash"></em>
                </app-button>
              </div>
            </div>
          </ng-container>
        </app-accordion-item>
      </app-accordion>
      <div class="button-group button-group-center mt-5">
        <app-button color="SECONDARY" (onClick)="doBack()" class="mr-1"
          >{{ 'app.button.back' | translate }}
        </app-button>
        <app-button color="PRIMARY" (onClick)="doSubmit()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </app-card>
  </div>
</div>
