<app-layout [isLoading]="formLoading">
  <app-table-xx
    header="price-evaluation-vendor.header.evaluationList"
    [model]="tableResponse"
    stringUrl="/price-evaluation-vendor/index"
    isShowButtonModifyTable="true"
    (onClick)="doClickProcurement($event)"
  >
    <ng-template #headerFilterGroup>
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <div class="form-group row text-sm-left">
          <div class="col-lg-12 row" style="margin-bottom: -1.5rem">
            <label
              class="cml-3 d-flex align-items-center"
              style="margin-top: auto"
              >{{ 'price-evaluation-vendor.form.procurementDate' | translate }}</label
            >
            <div class="cml-3 text-left">
              <app-date-picker-x
                size="XL"
                formControlName="date"
                [range]="true"
                (onChange)="onChangeFilter()"
              >
              </app-date-picker-x>
            </div>
            <div class="text-left col-sm-2">
              <app-dropdown-select
                size="XL"
                formControlName="statusList"
                (onChange)="onChangeFilter()"
                showLimitValue="3"
                type="CHOSEN"
                isMultiple="true"
                placeholder="price-evaluation-vendor.placeholder.status"
                [optionList]="statusOptionList"
              >
              </app-dropdown-select>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </app-table-xx>
</app-layout>
