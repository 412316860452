import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { WidgetService } from '../widgets.service';
import { WidgetProcurementActivityResponse } from './app-widget-procurement-activity.response';
@Component({
  templateUrl: './app-widget-procurement-activity.component.html',
  styleUrls: ['./app-widget-procurement-activity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetProcurementActivityComponent extends BaseComponentComponent {
  public total: number;

  constructor(public widgetService: WidgetService) {
    super('app-widget-procurement-activity');
  }

  public onInit(): void {
    this.getAndSetProcurementActivityInformation();
  }

  public getAndSetProcurementActivityInformation(): void {
    this.httpClientService
      .get<WidgetProcurementActivityResponse>(
        '/widget-procurement-activity/index'
      )
      .subscribe(response => {
        if (response.total) {
          this.total = response.total;
        }
        this.setStateReady();
      });
  }

  public goToMoreDetail(): void {
    this.router.navigate(['/pages/worklist-pm']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
