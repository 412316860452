<app-popup
  header="refutation.popup.vendorRefutation.title"
  [isShowHeaderCloseButton]="true"
  [isLoading]="formLoading"
>
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    *ngIf="!formLoading"
    novalidate
  >
    <div *ngIf="!formLoading">
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'refutation.form.vendorName' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{ vendor }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'refutation.form.bondType' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{ getTranslateKey(refutationSubmission.bondType) | translate }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'refutation.form.bondNumber' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{ refutationSubmission.bondNumber }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'refutation.form.bondIssued' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{ refutationSubmission.bondIssued }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3 text-left">{{
          'refutation.form.bondValue' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-currency
            [optionList]="currencyOptionList"
            formControlName="amount"
          ></app-currency>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'refutation.form.validityBondPeriod' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          <app-date-picker-x
            formControlName="date"
            [range]="true"
          ></app-date-picker-x>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3" [ngClass]="!formGroup.isView ? 'optional' : ''">
          {{ 'refutation.form.notes' | translate }}
        </label>
        <div class="col-sm-6 text-left">
          {{ refutationSubmission.note || '-' }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'refutation.form.refutationEvidence' | translate }}
        </label>
        <div class="col-sm-6 text-left">
          <app-upload-x
            formControlName="fileList"
            [fileUploader]="fileUploader"
          ></app-upload-x>
        </div>
      </div>
    </div>
  </form>
</app-popup>
