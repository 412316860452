import { BaseDTO } from '../../../core/base/base-dto';
import { Adjustment } from '../../../core/bean/adjustment';
import { ApprovalModelPrcs } from '../../../core/bean/approval-model-prcs';
import { TransactionLog } from '../../../core/bean/transaction-log';
import { WorkflowModelPrcs } from '../../../core/bean/workflow-model-prcs';
import { WorkflowPrcs } from '../../../core/bean/workflow-prcs';
export class SLASpendingDTO extends BaseDTO {
  workflowPrcs: WorkflowPrcs;
  workflowModelPrcsMainList: WorkflowModelPrcs[] = [];
  workflowModelPrcsAddList: WorkflowModelPrcs[] = [];
  approvalModelPrcsList: ApprovalModelPrcs[] = [];
  transactionLogList: TransactionLog[] = [];
  adjustmentList: Adjustment[] = [];
  totalRealizationWorkflowMain: string;
  totalRealizationWorkflowAdditional: string;
  totalRealizationApproval: string;
}
