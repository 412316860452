import { BaseEntity } from '../base/base-entity';
import { Rfp } from '../bean/rfp';
import { User } from '../bean/user';

export class RfpCancellationEntity extends BaseEntity {
  rfp: Rfp = new Rfp();
  cancelByUser: User = new User();
  cancelDate: Date = new Date();
  note: string;
}
