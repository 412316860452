import { AfterContentInit, Directive, Input } from '@angular/core';

@Directive({
  selector: '[app-table-th]'
})
export class TableThDirective implements AfterContentInit {
  @Input() targetElement: string;
  constructor() {}

  ngAfterContentInit(): void {
    console.group('Info: I am in');
    console.log(this.targetElement);
    console.log(document.getElementById(this.targetElement));
    console.log(this.targetElement);
    console.groupEnd();
  }
}
