<ol class="breadcrumbs" *ngIf="promiseThemeService.settings.isShowBreadcrumbs">
  <li *ngFor="let breadcrumb of breadcrumbs; let i = index">
    <a [hidden]="i == breadcrumbs.length - 1" [routerLink]="[breadcrumb.url]">
      {{ breadcrumb.name | translate }}
    </a>
    <a
      [hidden]="i != breadcrumbs.length - 1"
      style="color: 'var(--primary-dark)'"
    >
      {{ breadcrumb.name | translate }}
    </a>
  </li>
</ol>
