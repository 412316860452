<app-layout [isLoading]="formLoading">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-card
        header="organization.form.title.configuration"
        [isLoading]="formLoading"
        [isSaving]="formSaving"
      >
        <app-fullfilled></app-fullfilled>
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'organization.form.code' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-text-field
                autofocus="true"
                size="MD"
                formControlName="code"
                type="alphanumeric"
                minLength="3"
                maxLength="32"
                tooltip="{{ 'organization.tooltip.code' | translate }}"
              ></app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'organization.form.name' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-text-field
                size="MD"
                formControlName="name"
                maxLength="64"
              ></app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">{{
              'organization.form.mainlogo' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-upload-images
                formControlName="mainLogoFile"
                [fileUploader]="mainLogoUploader"
                [isMaintainAspectRatio]="false"
              ></app-upload-images>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">{{
              'organization.form.smalllogo' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-upload-images
                formControlName="smallLogoFile"
                [fileUploader]="smallLogoUploader"
                [isMaintainAspectRatio]="false"
              ></app-upload-images>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">{{
              'organization.form.reportlogo' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-upload-images
                formControlName="reportLogoFile"
                [fileUploader]="reportLogoUploader"
                [isMaintainAspectRatio]="false"
              ></app-upload-images>
            </div>
          </div>

          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'organization.form.isDistributor' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-toggle-switch
                formControlName="isDistributor"
                (onChange)="onChangeIsDistribution()"
              ></app-toggle-switch>
            </div>
          </div>

          <div
            class="form-group row text-sm-left required"
            *ngIf="!isDistributor"
          >
            <label class="col-sm-3 control-label">{{
              'organization.form.isBranch' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-toggle-switch
                formControlName="isBranch"
                (onChange)="onChangeIsBranch()"
              ></app-toggle-switch>
            </div>
          </div>

          <div
            class="form-group row text-sm-left required"
            *ngIf="!isBranch && !isDistributor"
          >
            <label class="col-sm-3 control-label">{{
              'organization.form.distributorLocationCode' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-text-field
                autofocus="true"
                size="MD"
                formControlName="distributorLocationCode"
                type="alphanumeric"
                minLength="6"
                maxLength="32"
                tooltip="{{
                  'organization.tooltip.distributorLocationCode' | translate
                }}"
              >
                (onChange)="doCodeChange($event)" ></app-text-field
              >
            </div>
          </div>

          <div
            class="form-group row text-sm-left required"
            *ngIf="!isBranch && !isDistributor"
          >
            <label class="col-sm-3 control-label">{{
              'organization.form.hospitalCode' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-text-field
                autofocus="true"
                size="MD"
                formControlName="hospitalCode"
                type="alphanumeric"
                minLength="3"
                maxLength="32"
                tooltip="{{ 'organization.tooltip.hospitalCode' | translate }}"
              ></app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label" tooltip="Country">{{
              'organization.form.country' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-dropdown-select
                size="MD"
                [optionList]="countryRegionOptionList"
                formControlName="countryRegion"
                type="AUTOCOMPLETE"
                (onChange)="doChangeCountryRegion()"
                tooltip="{{ 'organization.tooltip.country' | translate }}"
              >
              </app-dropdown-select>
            </div>
          </div>

          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'organization.form.province' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-dropdown-select
                size="MD"
                [optionList]="provinceRegionOptionList"
                formControlName="provinceRegion"
                type="AUTOCOMPLETE"
                (onChange)="doChangeProvinceRegion()"
              >
              </app-dropdown-select>
            </div>
          </div>

          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'organization.form.city' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-dropdown-select
                size="MD"
                [optionList]="cityRegionOptionList"
                formControlName="cityRegion"
                type="AUTOCOMPLETE"
              >
              </app-dropdown-select>
            </div>
          </div>

          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'organization.form.phone' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-text-field
                type="text"
                size="MD"
                formControlName="phone"
                maxLength="32"
                appInteger
              ></app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'organization.form.fax' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-text-field
                type="text"
                size="MD"
                formControlName="fax"
                maxLength="32"
                appInteger
              ></app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'organization.form.email' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-text-field
                size="MD"
                formControlName="email"
                maxLength="64"
                tooltip="{{ 'organization.tooltip.email' | translate }}"
                placeholder="Type email address"
              >
              </app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'organization.form.website' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-text-field
                size="MD"
                formControlName="website"
                maxLength="64"
                placeholder="{{
                  'organization.placeholder.website' | translate
                }}"
              >
              </app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'organization.form.address' | translate
            }}</label>
            <div class="col-sm-8 text-left">
              <app-text-area
                size="MD"
                formControlName="address"
                rows="5"
                maxLength="512"
              >
              </app-text-area>
            </div>
          </div>

          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'organization.form.zone' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                size="MD"
                [optionList]="zoneOptionList"
                formControlName="zone"
                type="AUTOCOMPLETE"
              >
              </app-dropdown-select>
            </div>
          </div>

          <div class="form-group row text-sm-left required">
            <label class="col-sm-3 control-label">{{
              'organization.form.hospitalClass' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                size="MD"
                [optionList]="hospitalClassOptionList"
                formControlName="hospitalClass"
                type="AUTOCOMPLETE"
              >
              </app-dropdown-select>
            </div>
          </div>

          <app-card
            *ngIf="!isBranch && !isDistributor"
            header="{{ 'upload.document' | translate }}"
          >
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>
              <div class="form-group row required">
                <div class="col-lg-12">
                  <h5 class="separator">{{
                    'organization.table.organizationDoc' | translate
                  }}</h5>
                  <ng-container formArrayName="organizationDocList">
                    <div class="form-group row text-sm-right">
                      <div
                        class="card border-0"
                        [ngStyle]="{
                          margin: '1px',
                          width: '-webkit-fill-available'
                        }"
                      >
                        <div class="card-body">
                          <div
                            class="table-responsive custom-table float-none w-100"
                            [ngStyle]="{ margin: '0 auto' }"
                          >
                            <table
                              class="table table-striped box-shadow"
                              aria-describedby="Table"
                            >
                              <thead>
                                <tr>
                                  <th class="text-left" scope="col">{{
                                    'organization.table.column.document'
                                      | translate
                                  }}</th>
                                  <th class="text-center" scope="col">{{
                                    'organization.table.column.documentNumber'
                                      | translate
                                  }}</th>
                                  <th class="text-left" scope="col">{{
                                    'organization.table.column.documentPublisher'
                                      | translate
                                  }}</th>
                                  <th class="text-center" scope="col">{{
                                    'organization.table.column.startDate'
                                      | translate
                                  }}</th>
                                  <th class="text-center" scope="col">{{
                                    'organization.table.column.endDate'
                                      | translate
                                  }}</th>
                                  <th
                                    class="text-left"
                                    scope="col"
                                    *ngIf="!isView"
                                    >{{
                                      'organization.table.column.uploadFile'
                                        | translate
                                    }}</th
                                  >
                                  <th
                                    class="text-left"
                                    scope="col"
                                    *ngIf="isView"
                                    >{{
                                      'organization.table.column.file'
                                        | translate
                                    }}</th
                                  >
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let organizationDoc of formGroup.controls
                                      .organizationDocList.controls;
                                    let i = index
                                  "
                                  [formGroupName]="i"
                                >
                                  <td
                                    class="text-left"
                                    [ngStyle]="{
                                      'max-width': '240px',
                                      'white-space': 'normal',
                                      'min-width': '200px'
                                    }"
                                  >
                                    {{
                                      'dynamic-master-attribute.' +
                                        organizationDoc.controls.fileType.value
                                          .translationKey.key | translate
                                    }}
                                    <span
                                      class="font-weight-bold text-danger"
                                      *ngIf="
                                        organizationDoc.controls.isMandatory
                                          .value
                                      "
                                      >*
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    <app-text-field
                                      size="XL"
                                      formControlName="number"
                                      maxLength="32"
                                    ></app-text-field>
                                  </td>
                                  <td class="text-left">
                                    <app-text-field
                                      size="XL"
                                      formControlName="publisher"
                                      type="alphabet"
                                      maxLength="64"
                                    ></app-text-field>
                                  </td>
                                  <td
                                    class="text-center"
                                    [ngStyle]="{ 'min-width': '175px' }"
                                  >
                                    <app-date-picker
                                      *ngIf="
                                        organizationDoc.controls.isUseDate.value
                                      "
                                      size="XL"
                                      formControlName="startDate"
                                      maxDate="currentDate"
                                    ></app-date-picker>
                                  </td>
                                  <td
                                    class="text-center"
                                    [ngStyle]="{ 'min-width': '175px' }"
                                  >
                                    <app-date-picker
                                      *ngIf="
                                        organizationDoc.controls.isUseDate.value
                                      "
                                      size="XL"
                                      formControlName="endDate"
                                      minDate="startDate"
                                    ></app-date-picker>
                                  </td>
                                  <td
                                    class="text-left"
                                    [ngStyle]="{
                                      'min-width': '210px',
                                      'max-width': '500px'
                                    }"
                                  >
                                    <app-upload
                                      size="XL"
                                      formControlName="file"
                                      [fileUploader]="
                                        fileUploaderList[
                                          organizationDoc.controls.index.value
                                        ]
                                      "
                                    >
                                    </app-upload>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </form>
          </app-card>

          <div>
            <h3 class="separator">{{
              'organization.form.title.colorconfiguration' | translate
            }}</h3>
          </div>
          <app-alert-x>
            {{ 'organization.alert.message.color' | translate }}
          </app-alert-x>
          <div class="row">
            <div class="col-sm-4">
              <div class="app-card" style="margin-top: -18px">
                <div class="card-body widget-body">
                  <div class="app-card">
                    <div widget class="card border-0 box-shadow lmb-4">
                      <div
                        class="card-header transparent border-0 d-flex align-items-center justify-content-between cmt-3"
                      >
                        <div class="flex-1" style="margin-top: -15px">
                          <h3 class="d-inline-block fw-500">
                            {{
                              'organization.form.title.color.component'
                                | translate
                            }}
                          </h3>
                        </div>
                        <div
                          class="ml-2 d-flex-align-items-center"
                          style="margin-top: -15px"
                        >
                          <app-button
                            (onClick)="doResetColor()"
                            color="PRIMARY"
                            [outline]="true"
                            title="{{
                              'organization.button.reset.default' | translate
                            }}"
                          >
                            {{
                              'organization.button.reset.default' | translate
                            }}
                          </app-button>
                        </div>
                      </div>
                      <div class="card-body widget-body">
                        <div class="form-group row text-sm-left">
                          <label class="col-sm-7 control-label">{{
                            'organization.form.primarycolor' | translate
                          }}</label>
                          <div class="col-sm-5 text-left input-group">
                            <input
                              #ignoredInput
                              class="form-control"
                              formControlName="primaryColor"
                              [value]="primaryColor"
                              [cpPresetColors]="[
                                '#0772B6',
                                '#FFFFFF',
                                '#04446C',
                                '#D0D6E0',
                                '#454545',
                                '#F3F5F4'
                              ]"
                              [(colorPicker)]="primaryColor"
                              [style.background]="primaryColor"
                              [style.color]="primaryColor"
                              [cpAlphaChannel]="'forced'"
                              [cpIgnoredElements]="[
                                ignoredButton,
                                ignoredInput
                              ]"
                              [(cpToggle)]="togglePrimaryColor"
                              (colorPickerClose)="updatePrimaryColor($event)"
                            />
                            <div
                              class="input-group-append"
                              #ignoredButton
                              (click)="togglePrimaryColor = !togglePrimaryColor"
                            >
                              <span
                                class="input-group-text cursor-pointer"
                                style="background: transparent"
                              >
                                <em class="pir pi-color-picker"></em>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row text-sm-left">
                          <label class="col-sm-7 control-label">{{
                            'organization.form.coloronprimary' | translate
                          }}</label>
                          <div class="col-sm-5 text-left input-group">
                            <input
                              #ignoredInput2
                              class="form-control"
                              formControlName="colorOnPrimaryColor"
                              [value]="colorOnPrimaryColor"
                              [cpPresetColors]="[
                                '#0772B6',
                                '#FFFFFF',
                                '#04446C',
                                '#D0D6E0',
                                '#454545',
                                '#F3F5F4'
                              ]"
                              [(colorPicker)]="colorOnPrimaryColor"
                              [style.background]="colorOnPrimaryColor"
                              [style.color]="colorOnPrimaryColor"
                              [cpAlphaChannel]="'forced'"
                              [cpIgnoredElements]="[
                                ignoredButton2,
                                ignoredInput2
                              ]"
                              [(cpToggle)]="toggleColorOnPrimaryColor"
                              (colorPickerClose)="updateColorOnPrimary($event)"
                            />
                            <div
                              class="input-group-append"
                              #ignoredButton2
                              (click)="
                                toggleColorOnPrimaryColor =
                                  !toggleColorOnPrimaryColor
                              "
                            >
                              <span
                                class="input-group-text cursor-pointer"
                                style="background: transparent"
                              >
                                <em class="pir pi-color-picker"></em>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row text-sm-left">
                          <label class="col-sm-7 control-label">{{
                            'organization.form.primaryDarkColor' | translate
                          }}</label>
                          <div class="col-sm-5 text-left input-group">
                            <input
                              #ignoredInput3
                              class="form-control"
                              formControlName="primaryDarkColor"
                              [value]="primaryDarkColor"
                              [cpPresetColors]="[
                                '#0772B6',
                                '#FFFFFF',
                                '#04446C',
                                '#D0D6E0',
                                '#454545',
                                '#F3F5F4'
                              ]"
                              [(colorPicker)]="primaryDarkColor"
                              [style.background]="primaryDarkColor"
                              [style.color]="primaryDarkColor"
                              [cpAlphaChannel]="'forced'"
                              [cpIgnoredElements]="[
                                ignoredButton3,
                                ignoredInput3
                              ]"
                              [(cpToggle)]="togglePrimaryDarkColor"
                              (colorPickerClose)="
                                updatePrimaryDarkColor($event)
                              "
                            />
                            <div
                              class="input-group-append"
                              #ignoredButton3
                              (click)="
                                togglePrimaryDarkColor = !togglePrimaryDarkColor
                              "
                            >
                              <span
                                class="input-group-text cursor-pointer"
                                style="background: transparent"
                              >
                                <em class="pir pi-color-picker"></em>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row text-sm-left">
                          <label class="col-sm-7 control-label">{{
                            'organization.form.colorOnPrimaryDarkColor'
                              | translate
                          }}</label>
                          <div class="col-sm-5 text-left input-group">
                            <input
                              #ignoredInput4
                              class="form-control"
                              formControlName="colorOnPrimaryDarkColor"
                              [value]="colorOnPrimaryDarkColor"
                              [cpPresetColors]="[
                                '#0772B6',
                                '#FFFFFF',
                                '#04446C',
                                '#D0D6E0',
                                '#454545',
                                '#F3F5F4'
                              ]"
                              [(colorPicker)]="colorOnPrimaryDarkColor"
                              [style.background]="colorOnPrimaryDarkColor"
                              [style.color]="colorOnPrimaryDarkColor"
                              [cpAlphaChannel]="'forced'"
                              [cpIgnoredElements]="[
                                ignoredButton4,
                                ignoredInput4
                              ]"
                              [(cpToggle)]="toggleColorOnPrimaryDarkColor"
                              (colorPickerClose)="
                                updateColorOnPrimaryDark($event)
                              "
                            />
                            <div
                              class="input-group-append"
                              #ignoredButton4
                              (click)="
                                toggleColorOnPrimaryDarkColor =
                                  !toggleColorOnPrimaryDarkColor
                              "
                            >
                              <span
                                class="input-group-text cursor-pointer"
                                style="background: transparent"
                              >
                                <em class="pir pi-color-picker"></em>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row text-sm-left">
                          <label class="col-sm-7 control-label">{{
                            'organization.form.tableheadercolor' | translate
                          }}</label>
                          <div class="col-sm-5 text-left input-group">
                            <input
                              #ignoredInput5
                              class="form-control"
                              formControlName="tableHeaderColor"
                              [value]="tableHeaderColor"
                              [cpPresetColors]="[
                                '#0772B6',
                                '#FFFFFF',
                                '#04446C',
                                '#D0D6E0',
                                '#454545',
                                '#F3F5F4'
                              ]"
                              [(colorPicker)]="tableHeaderColor"
                              [style.background]="tableHeaderColor"
                              [style.color]="tableHeaderColor"
                              [cpAlphaChannel]="'forced'"
                              [cpIgnoredElements]="[
                                ignoredButton5,
                                ignoredInput5
                              ]"
                              [(cpToggle)]="toggleTableHeaderColor"
                              (colorPickerClose)="
                                updateTableHeaderColor($event)
                              "
                            />
                            <div
                              class="input-group-append"
                              #ignoredButton5
                              (click)="
                                toggleTableHeaderColor = !toggleTableHeaderColor
                              "
                            >
                              <span
                                class="input-group-text cursor-pointer"
                                style="background: transparent"
                              >
                                <em class="pir pi-color-picker"></em>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row text-sm-left">
                          <label class="col-sm-7 control-label">{{
                            'organization.form.colorontableheader' | translate
                          }}</label>
                          <div class="col-sm-5 text-left input-group">
                            <input
                              #ignoredInput6
                              class="form-control"
                              formControlName="colorOnTableHeaderColor"
                              [value]="colorOnTableHeaderColor"
                              [cpPresetColors]="[
                                '#0772B6',
                                '#FFFFFF',
                                '#04446C',
                                '#D0D6E0',
                                '#454545',
                                '#F3F5F4'
                              ]"
                              [(colorPicker)]="colorOnTableHeaderColor"
                              [style.background]="colorOnTableHeaderColor"
                              [style.color]="colorOnTableHeaderColor"
                              [cpAlphaChannel]="'forced'"
                              [cpIgnoredElements]="[
                                ignoredButton6,
                                ignoredInput6
                              ]"
                              [(cpToggle)]="toggleColorOnTableHeaderColor"
                              (colorPickerClose)="
                                updateOnTableHeaderColor($event)
                              "
                            />
                            <div
                              class="input-group-append"
                              #ignoredButton6
                              (click)="
                                toggleColorOnTableHeaderColor =
                                  !toggleColorOnTableHeaderColor
                              "
                            >
                              <span
                                class="input-group-text cursor-pointer"
                                style="background: transparent"
                              >
                                <em class="pir pi-color-picker"></em>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row text-sm-left">
                          <label class="col-sm-7 control-label">{{
                            'organization.form.tablebodycolor' | translate
                          }}</label>
                          <div class="col-sm-5 text-left input-group">
                            <input
                              #ignoredInput7
                              class="form-control"
                              formControlName="tableBodyColor"
                              [value]="tableBodyColor"
                              [cpPresetColors]="[
                                '#0772B6',
                                '#FFFFFF',
                                '#04446C',
                                '#D0D6E0',
                                '#454545',
                                '#F3F5F4'
                              ]"
                              [(colorPicker)]="tableBodyColor"
                              [style.background]="tableBodyColor"
                              [style.color]="tableBodyColor"
                              [cpAlphaChannel]="'forced'"
                              [cpIgnoredElements]="[
                                ignoredButton7,
                                ignoredInput7
                              ]"
                              [(cpToggle)]="toggleTableBodyColor"
                              (colorPickerClose)="updateTableBodyColor($event)"
                            />

                            <div
                              class="input-group-append"
                              #ignoredButton7
                              (click)="
                                toggleTableBodyColor = !toggleTableBodyColor
                              "
                            >
                              <span
                                class="input-group-text cursor-pointer"
                                style="background: transparent"
                              >
                                <em class="pir pi-color-picker"></em>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row text-sm-left">
                          <label class="col-sm-7 control-label">{{
                            'organization.form.colorontablebody' | translate
                          }}</label>
                          <div class="col-sm-5 text-left input-group">
                            <input
                              #ignoredInput8
                              class="form-control"
                              formControlName="colorOnTableBodyColor"
                              [value]="colorOnTableBodyColor"
                              [cpPresetColors]="[
                                '#0772B6',
                                '#FFFFFF',
                                '#04446C',
                                '#D0D6E0',
                                '#454545',
                                '#F3F5F4'
                              ]"
                              [(colorPicker)]="colorOnTableBodyColor"
                              [style.background]="colorOnTableBodyColor"
                              [style.color]="colorOnTableBodyColor"
                              [cpAlphaChannel]="'forced'"
                              [cpIgnoredElements]="[
                                ignoredButton8,
                                ignoredInput8
                              ]"
                              [(cpToggle)]="toggleColorOnTableBodyColor"
                              (colorPickerClose)="
                                updateColorOnTableBody($event)
                              "
                            />
                            <div
                              class="input-group-append"
                              #ignoredButton8
                              (click)="
                                toggleColorOnTableBodyColor =
                                  !toggleColorOnTableBodyColor
                              "
                            >
                              <span
                                class="input-group-text cursor-pointer"
                                style="background: transparent"
                              >
                                <em class="pir pi-color-picker"></em>
                              </span>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="form-group row text-sm-left">
                        <label class="col-sm-7 control-label">
                          {{ 'organization.form.textcolor' | translate }}
                        </label>
                        <div class="col-sm-5 text-left input-group">
                          <input #ignoredInput9
                            class="form-control" 
                            formControlName="textColor" 
                            [value]="textColor" 
                            [cpPresetColors]="['#0772B6', '#FFFFFF', '#04446C', '#D0D6E0', '#454545', '#F3F5F4']"
                            [(colorPicker)]="textColor" 
                            [style.background]="textColor"
                            [style.color]="textColor" 
                            [cpAlphaChannel]="'forced'"
                            [cpIgnoredElements]="[ignoredButton9, ignoredInput9]"
                            [(cpToggle)]="toggleTextColor" 
                            (colorPickerClose)="updateTextColor($event)"
                          />
                          <div class="input-group-append" #ignoredButton9 (click)="toggleTextColor=!toggleTextColor">
                            <span class="input-group-text cursor-pointer" style="background:transparent">
                              <em class="pir pi-color-picker"></em>
                            </span>
                          </div>
                        </div>
                      </div> -->
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <app-card header="organization.form.title.preview">
                <div class="app-card">
                  <div class="card-body widget-body" style="margin: 20px">
                    <div
                      class="app-card"
                      style="
                        margin-left: 150px;
                        margin-right: 150px;
                        margin-top: 35px;
                        margin-bottom: 60px;
                      "
                    >
                      <div widget class="card border-0 box-shadow lmb-4">
                        <div
                          class="flex-1"
                          style="padding-left: 15px; padding-top: 15px"
                        >
                          <h2 [style.color]="textColor"
                            ><strong>Procurement Initiation</strong></h2
                          >
                        </div>
                        <div
                          class="card-header transparent border-0 d-flex align-items-center justify-content-between cmt-3"
                        >
                          <div class="flex-1">
                            <h3
                              [style.color]="textColor"
                              class="d-inline-block fw-500"
                            >
                              Initiation List
                            </h3>
                          </div>
                          <div class="ml-2 d-flex-align-items-center">
                            <button
                              class="btn"
                              role="button"
                              [style.background]="primaryColor"
                              [style.color]="colorOnPrimaryColor"
                            >
                              <em class="pir pi-plus-circle"></em>
                              New Initiation
                            </button>
                          </div>
                        </div>
                        <div class="card-body widget-body">
                          <div class="mr-2 d-flex align-items-center">
                            <button
                              class="btn"
                              role="button"
                              [style.background]="primaryDarkColor"
                              [style.color]="colorOnPrimaryDarkColor"
                            >
                              <span class="filter-text"> Filters </span>
                              <em
                                class="pir pi-chevron-circle-up pi-2x btn-icon-right"
                              ></em>
                            </button>
                          </div>
                          <div style="padding-top: 25px">
                            <table class="table table-responsive-md">
                              <thead [style.background]="tableHeaderColor">
                                <tr>
                                  <th
                                    [style.color]="colorOnTableHeaderColor"
                                    table-th
                                    style="text-align: center"
                                    scope="col"
                                    >Procurement Number<em
                                      [style.color]="colorOnTableHeaderColor"
                                      class="pis pi-sort ml-2"
                                      style="float: right; margin-top: 5px"
                                    ></em
                                  ></th>
                                  <th
                                    [style.color]="colorOnTableHeaderColor"
                                    table-th
                                    style="text-align: left"
                                    scope="col"
                                    >Procurement Date<em
                                      [style.color]="colorOnTableHeaderColor"
                                      class="pis pi-sort ml-2"
                                      style="float: right; margin-top: 5px"
                                    ></em
                                  ></th>
                                  <th
                                    [style.color]="colorOnTableHeaderColor"
                                    table-th
                                    style="text-align: left"
                                    scope="col"
                                    >Procurement Name<em
                                      [style.color]="colorOnTableHeaderColor"
                                      class="pis pi-sort ml-2"
                                      style="float: right; margin-top: 5px"
                                    ></em
                                  ></th>
                                </tr>
                              </thead>
                              <tbody [style.background]="tableBodyColor">
                                <tr table-row>
                                  <td
                                    [style.color]="colorOnTableBodyColor"
                                    style="text-align: center"
                                    ><u>PROCINI/0001/2021</u></td
                                  >
                                  <td [style.color]="textColor">19/01/2020</td>
                                  <td [style.color]="textColor"
                                    >Pengadaan Laptop</td
                                  >
                                </tr>
                                <tr table-row>
                                  <td
                                    [style.color]="textColor"
                                    style="text-align: center"
                                    ><u>PROCINI/0002/2021</u></td
                                  >
                                  <td [style.color]="textColor">20/01/2020</td>
                                  <td [style.color]="textColor"
                                    >Pengadaan Server</td
                                  >
                                </tr>
                                <tr table-row>
                                  <td
                                    [style.color]="textColor"
                                    style="text-align: center"
                                    ><u>PROCINI/0003/2021</u></td
                                  >
                                  <td [style.color]="textColor">21/01/2020</td>
                                  <td [style.color]="textColor"
                                    >Pengadaan ATK</td
                                  >
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </app-card>
            </div>
          </div>

          <div class="button-group button-group-center mt-5">
            <app-button
              color="SECONDARY"
              (onClick)="doCancel()"
              [disabled]="formSaving"
              *ngIf="hasChild || !isEdit"
            >
              {{ 'app.button.back' | translate }}
            </app-button>
            <app-button
              color="DANGER"
              [outline]="true"
              (onClick)="doDelete()"
              [disabled]="formSaving"
              *ngIf="!hasChild && isEdit"
            >
              {{ 'organization.button.delete' | translate }}
            </app-button>
            <app-button (onClick)="doSave()" [disabled]="formSaving">
              {{ 'app.button.save' | translate }}
            </app-button>
          </div>
        </form>
      </app-card>
    </div>
  </div>
</app-layout>
