import { FileType } from '../bean/file-type';
import { AppInfoResponse } from '../response/app-info-response';
import { ParameterModel } from './parameter-model';

/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */

export class ConfigModel {
  public readonly BASE_ADDRESS;
  public BACKEND_ADDRESS: string;
  public WEBSOCKET_ADDRESS: string;
  public parameterModel: ParameterModel;
  public appInfoResponse: AppInfoResponse;
  public allowedFile: Map<string, FileType> = new Map<string, FileType>();
}
