import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppPopupTreeComponent } from '../../core/components/app-popup/app-popup-tree/app-popup-tree.component';
import { AppPopupWorkflowService } from '../../core/components/app-popup/app-popup-workflow/app-popup-workflow.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { TreeModel } from '../../core/components/app-tree';
import { ListOfViewModel } from '../../core/model/list-of-view-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Condition } from './../../core/bean/condition';
import { ModuleGroup } from './../../core/bean/module-group';
import { WorkflowAdditional } from './../../core/bean/workflow-additional';
import { WorkflowCondition } from './../../core/bean/workflow-condition';
import { WorkflowConditionDetail } from './../../core/bean/workflow-condition-detail';
import { WorkflowModel } from './../../core/bean/workflow-model';
import { Validators } from './../../core/validators/index';
import { WorkflowConditionEditAddResponse } from './workflow-condition-edit-add-response';
import { WorkflowModelResponse } from './workflow-model-response';
@Component({
  templateUrl: './workflow-condition-edit-add.component.html'
})
export class WorkflowConditionEditAddComponent extends BaseModuleComponent {
  public tableResponseWorkflowModel: TableResponseModel<WorkflowModel>;
  public tableResponseWorkflowAdditional: TableResponseModel<WorkflowAdditional>;
  public moduleGroupList: ModuleGroup[];
  public condition: any = null;
  public moduleGroup: ModuleGroup;
  public conditionList: any[];
  public workflowCondition: WorkflowCondition = new WorkflowCondition();
  public workflowConditionDetailList: WorkflowConditionDetail[] = [];
  public workflowConditionResponse: WorkflowModelResponse =
    new WorkflowModelResponse();
  public showConditionList = false;
  public workflowConditionId: number;
  public workflowId = null;
  public oldWorkflowConditon: WorkflowCondition = new WorkflowCondition();
  public moduleGroupName: string;
  public showValidate = false;
  public isValid = false;
  public workflowModelList: WorkflowModel[] = [];
  public workflowAdditionalList: WorkflowAdditional[] = [];
  public moduleGroupOptionList: OptionListModel<ModuleGroup> =
    new OptionListModel();
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupWorkflowService: AppPopupWorkflowService
  ) {
    super('workflow-condition', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doSetIsValid();
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.doGetAndSetWorkflowConditionResponse();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.workflowConditionId = this.global.routerParams.get(
      'workflowConditionId'
    );
    this.workflowId = this.global.routerParams.get('workflowId');
  }

  public doSetIsValid(): void {
    this.todo === 'edit' ? (this.isValid = true) : (this.isValid = false);
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      moduleGroup: [null, Validators.compose([Validators.required()])],
      workflow: [null, Validators.compose([Validators.required()])],
      workflowName: [null, Validators.compose([Validators.required()])]
    });
  }

  public doBuildTableResponse(): void {
    this.tableResponseWorkflowModel = new TableResponseModel(this.moduleCode, [
      {
        field: 'module.name',
        header: 'table.column.stage',
        customClass: 'text-center'
      }
    ]);
    this.tableResponseWorkflowAdditional = new TableResponseModel(
      this.moduleCode,
      [
        { field: 'workflowModel.module.name', header: 'table.column.stage' },
        { field: 'afterModule.name', header: 'table.column.afterStage' },
        { field: 'beforeModule.name', header: 'table.column.beforeStage' },
        {
          field: 'isMandatory',
          header: 'table.column.isMandatory',
          datamap: '{"false" : "No" , "true" : "Yes" }'
        }
      ]
    );
  }

  public doGetAndSetWorkflowConditionResponse(): void {
    this.httpClientService
      .post<WorkflowConditionEditAddResponse>(
        '/workflow-condition/add-edit',
        new RouteRequestModel(this.todo, this.workflowConditionId)
      )
      .subscribe(workflowConditionEditAddResponse => {
        this.moduleGroupList = workflowConditionEditAddResponse.moduleGroupList;
        this.moduleGroupOptionList.setRequestValues(this.moduleGroupList);
        if (workflowConditionEditAddResponse.workflowCondition != null) {
          this.workflowCondition =
            workflowConditionEditAddResponse.workflowCondition;
          this.conditionList = workflowConditionEditAddResponse.conditionList;
          this.workflowConditionDetailList =
            this.workflowCondition.workflowConditionDetailList;

          this.workflowConditionDetailList.forEach(workflowConditionDetail => {
            if (workflowConditionDetail.condition.type === 'LIST') {
              let worlflowConditionDetailURL: string;
              // if the condition are ThresholdRangeBean
              if (
                workflowConditionDetail.condition.className ===
                'ThresholdRangeBean'
              ) {
                worlflowConditionDetailURL =
                  '/workflow-condition/condition-detail-range/' +
                  workflowConditionDetail.condition.id;
              } else {
                worlflowConditionDetailURL =
                  '/workflow-condition/condition-detail-list/' +
                  workflowConditionDetail.condition.className.replace(
                    'bean',
                    ''
                  );
              }
              this.httpClientService
                .get<ListOfViewModel[]>(worlflowConditionDetailURL)
                .subscribe(listOfView => {
                  workflowConditionDetail.listOfView = listOfView;
                });
            }
          });

          this.getConditionSelected(
            this.conditionList,
            this.workflowConditionDetailList
          );
          this.showConditionList = true;
          this.workflowId =
            workflowConditionEditAddResponse.workflowCondition.workflow.id;
          this.moduleGroupName = this.workflowCondition.moduleGroup.name;
          this.oldWorkflowConditon = this.workflowCondition;
          this.workflowModelList =
            workflowConditionEditAddResponse.workflowModelList;
          this.workflowAdditionalList =
            workflowConditionEditAddResponse.workflowAdditionalList;
          const indexModuleGroup = this.moduleGroupList.findIndex(
            moduleGroup =>
              moduleGroup.id === this.workflowCondition.moduleGroup.id
          );
          this.formGroup.patchValue({
            id: this.workflowCondition.id,
            moduleGroup: this.moduleGroupList[indexModuleGroup],
            workflow: this.workflowCondition.workflow,
            workflowName: this.workflowCondition.workflow.name
          });

          this.moduleGroup = this.moduleGroupList[indexModuleGroup];
          this.tableResponseWorkflowModel.setRecords(this.workflowModelList);
          this.tableResponseWorkflowAdditional.setRecords(
            this.workflowAdditionalList
          );
          this.tableResponseWorkflowModel.reloadClient();
          this.tableResponseWorkflowAdditional.reloadClient();
        }
        this.setStateReady();
      });
  }

  public onChangeModuleGroup(): void {
    this.showConditionList = true;
    this.conditionList = [];
    this.workflowConditionDetailList = [];
    this.moduleGroup = this.formGroup.get('moduleGroup').value;
    this.httpClientService
      .get<Condition[]>(
        '/workflow-condition/condition-list/' + this.moduleGroup.id
      )
      .subscribe(conditionList => {
        this.conditionList = conditionList;
        this.getConditionSelected(
          this.conditionList,
          this.workflowConditionDetailList
        );
      });
  }

  public onChooseCondition(condition): void {
    condition.selected = true;
    if (condition.type === 'LIST') {
      let worlflowConditionDetailURL: string;
      // if the condition are ThresholdRangeBean
      if (condition.className === 'ThresholdRangeBean') {
        worlflowConditionDetailURL =
          '/workflow-condition/condition-detail-range/' + condition.id;
      } else {
        worlflowConditionDetailURL =
          '/workflow-condition/condition-detail-list/' +
          condition.className.replace('bean', '');
      }
      this.httpClientService
        .get<ListOfViewModel[]>(worlflowConditionDetailURL)
        .subscribe(listOfView => {
          const workflowConditionDetail = new WorkflowConditionDetail();
          workflowConditionDetail.id = null;
          workflowConditionDetail.objectId = null;
          workflowConditionDetail.condition = this.condition;
          workflowConditionDetail.listOfView = listOfView;
          this.workflowConditionDetailList.push(workflowConditionDetail);
        });
    } else {
      const workflowConditionDetail = new WorkflowConditionDetail();
      workflowConditionDetail.id = null;
      workflowConditionDetail.objectId = null;
      workflowConditionDetail.condition = this.condition;
      this.workflowConditionDetailList.push(workflowConditionDetail);
    }
    this.setWorkflowNull();
    this.isValid = false;
    this.showValidate = true;
  }

  public onChooseConditionView(
    workflowConditionDetail: WorkflowConditionDetail
  ): void {
    workflowConditionDetail.listOfView.forEach(view => {
      if (workflowConditionDetail.objectId === view.id) {
        workflowConditionDetail.name = view.name;
        this.showValidate = true;
      }
    });
    this.setWorkflowNull();
    this.isValid = false;
  }

  public doDeleteCondition(
    index: number,
    workflowConditionDetail: WorkflowConditionDetail
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.conditionList.forEach(condition => {
            if (workflowConditionDetail.condition.id === condition.id) {
              condition.selected = false;
            }
          });
          this.workflowConditionDetailList.splice(index, 1);
          if (this.workflowConditionDetailList.length === 0) {
            this.showValidate = false;
          } else {
            this.showValidate = true;
          }
          const indexOfSelected = this.conditionList.findIndex(
            condition => condition.selected
          );
          this.condition = this.conditionList[indexOfSelected];
          this.setWorkflowNull();
        }
      });
  }

  public getConditionSelected(
    conditionList: any[],
    workflowConditionDetailList: WorkflowConditionDetail[]
  ): void {
    conditionList.forEach(condition => {
      condition.selected = false;
      workflowConditionDetailList.forEach(workflowConditionDetail => {
        if (
          workflowConditionDetail.condition.className === condition.className &&
          workflowConditionDetail.condition.id === condition.id
        ) {
          condition.selected = true;
        }
      });
    });
  }

  public doChooseTreeObject(
    event: any,
    workflowConditionDetail: WorkflowConditionDetail
  ): void {
    event.srcElement.blur();
    event.preventDefault();
    const moduleCode =
      workflowConditionDetail.condition.className.toLocaleLowerCase();
    const userOrganizationid = this.global.userSession.user.organization.id;
    const model: TreeModel = new TreeModel(
      moduleCode,
      'READ',
      userOrganizationid
    );
    this.appPopupService
      .open(AppPopupTreeComponent, { model })
      .subscribe(data => {
        const lastData = data.pop();
        workflowConditionDetail.objectId = lastData.id;
        workflowConditionDetail.name = lastData.name;
        this.setWorkflowNull();
        this.showValidate = true;
      });
    this.isValid = false;
  }

  public validateWorkflowConditon(): void {
    this.isValid = false;
    let checkObjectCondition = true;
    this.workflowConditionDetailList.forEach(conditionDetail => {
      if (checkObjectCondition) {
        if (conditionDetail.objectId === null) {
          this.global.alertService.showError(
            this.translateService.instant(
              'workflow-condition.msg.error.chooseConditionAndValidate'
            )
          );
          checkObjectCondition = false;
        } else {
          checkObjectCondition = true;
        }
      }
    });
    if (checkObjectCondition) {
      this.httpClientService
        .post<WorkflowCondition>(
          '/workflow-condition/validate-workflow-condition',
          this.workflowConditionDetailList
        )
        .subscribe(workflowCondition => {
          if (workflowCondition != null) {
            this.formGroup.patchValue({
              workflow: workflowCondition.workflow,
              workflowName: workflowCondition.workflow.name
            });
            this.oldWorkflowConditon = workflowCondition;
            this.global.alertService.showInfo(
              this.translateService.instant(
                'workflow-condition.msg.info.conditionAlreadyUse'
              ) +
                ' ' +
                this.oldWorkflowConditon.workflow.name
            );
          } else {
            this.oldWorkflowConditon = null;
            this.global.alertService.showInfo(
              this.translateService.instant(
                'workflow-condition.msg.info.pleaseChooseWorkflow'
              )
            );
          }
          this.isValid = true;
        });
    }
  }

  public chooseWorkflow(event: any): void {
    if (!this.isValid) {
      this.global.alertService.showError(
        this.translateService.instant(
          'workflow-condition.msg.error.chooseConditionAndValidate'
        )
      );
    } else {
      event.srcElement.blur();
      event.preventDefault();
      const filter = {
        organizationId: 1,
        moduleGroup: this.moduleGroup
      };

      this.appPopupWorkflowService
        .open('/app-popup-workflow/index-workflow-condition', null, filter)
        .subscribe(workflow => {
          this.workflowId = workflow.id;
          this.formGroup.patchValue({
            workflow,
            workflowName: workflow.name
          });

          this.httpClientService
            .get<WorkflowModelResponse>(
              '/workflow-condition/workflow-model/index/' + this.workflowId
            )
            .subscribe(workflowModelResponse => {
              this.workflowModelList = workflowModelResponse.workflowModelList;
              this.workflowAdditionalList =
                workflowModelResponse.workflowAdditionalList;
            });
        });
    }
  }

  public setWorkflowNull(): void {
    this.workflowId = null;
    this.formGroup.patchValue({ workflow: null, workflowName: null });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid && this.isValid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.workflowCondition = this.global.copyFormAttributeToModel(
              this.workflowCondition,
              this.formGroup
            );
            this.workflowCondition.workflowConditionDetailList =
              this.workflowConditionDetailList;
            const url =
              this.todo === 'edit'
                ? '/workflow-condition/update'
                : '/workflow-condition/insert';
            this.httpClientService
              .post<Response<WorkflowModelResponse>>(
                url,
                this.workflowCondition
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/workflow-condition']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/workflow-condition']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
