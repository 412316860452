<app-popup header="oep.popup.detailHistory" [isLoading]="formLoading">
  <app-table-xx
    [model]="tableResponse"
    stringUrl="/oep/get-oep-item-history"
    [isShowSearch]="false"
  >
    <ng-template #row>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{{ 'oep.table.column.grandTotal' | translate }}</td>
        <td class="text-right">
          :
          <strong
            >{{ grandTotal | resolveNumberToCurrency }}
            {{ currency.code }}</strong
          >
        </td>
      </tr>
    </ng-template>
  </app-table-xx>
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    novalidate
    *ngIf="!formLoading"
  >
    <h3 class="separator">{{ 'oep.separator.document' | translate }}</h3>
    <app-upload-x
      *ngIf="
        formGroup.controls.fileList.value &&
        formGroup.controls.fileList.value.length !== 0
      "
      formControlName="fileList"
      [fileUploader]="fileUploader"
    ></app-upload-x>
    <ng-container
      *ngIf="
        formGroup.controls.fileList.value &&
        formGroup.controls.fileList.value.length === 0
      "
    >
      <div style="padding: 12% 0">
        <div
          class="pvc pv-file-list"
          style="height: 88.28px; width: 76.17px; margin: 0 auto"
        ></div>
        <div
          style="
            text-align: center;
            padding: 50px 0;
            color: var(--gray-500);
            font-weight: 500;
          "
        >
          <span>
            {{ 'oep.detail.noFile' | translate }}
          </span>
        </div>
      </div>
    </ng-container>
  </form>
</app-popup>
