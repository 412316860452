import { DatePickerDate } from '../model';
import { createDatePickerDate } from './create-date-picker-date-helper';

export const createDatePickerDateList = (
  value: Date
): Array<DatePickerDate> => {
  const date = new Date(value),
    currentYear = date.getFullYear(),
    currentMonth = date.getMonth();

  const previousLastDate = new Date(currentYear, currentMonth, 0);
  const currentLastDate = new Date(currentYear, currentMonth + 1, 0);
  const currentFirstDate = new Date(currentYear, currentMonth, 1);
  const nextFirstDate = new Date(currentYear, currentMonth + 1, 1);

  const previousLastDateValue = previousLastDate.getDate();
  const prevDateList: Array<DatePickerDate> = Array(currentFirstDate.getDay())
    .fill(0)
    .map((val, index) => {
      const dateValue = previousLastDateValue - index - val;
      const previousDate = new Date(previousLastDate.setDate(dateValue));
      const datePickerItem = createDatePickerDate(previousDate);
      datePickerItem.monthPosition = 'PREVIOUS';
      return datePickerItem;
    })
    .reverse();
  const currentDateList: Array<DatePickerDate> = Array(
    currentLastDate.getDate()
  )
    .fill(0)
    .map((val, index) => {
      const dateValue = val + index + 1;
      const currentDate = new Date(date.setDate(dateValue));
      const datePickerItem = createDatePickerDate(currentDate);
      datePickerItem.monthPosition = 'CURRENT';
      return datePickerItem;
    });
  const nextDateList: Array<DatePickerDate> = Array(
    13 - currentLastDate.getDay()
  )
    .fill(0)
    .map((val, index) => {
      const dateValue = val + index + 1;
      const nextDate = new Date(nextFirstDate.setDate(dateValue));
      const datePickerItem = createDatePickerDate(nextDate);
      datePickerItem.monthPosition = 'NEXT';
      return datePickerItem;
    });

  const dateList = [...prevDateList, ...currentDateList, ...nextDateList];
  return dateList.length === 49 ? dateList.splice(0, 42) : dateList;
};
