import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { AgreementType } from '../../bean/agreement-type';
import { OptionListModel } from '../../model/option-list-model';
import { Validators } from '../../validators';

@Component({
  selector: 'app-agreement-type',
  templateUrl: './app-agreement-type.component.html'
})
export class AppAgreementTypeComponent extends BaseComponentComponent {
  @Input() component: String[] = [];
  @Input() agreementTypeParam: AgreementType;
  @Input() isAgreementTypeNull: boolean;
  @Input() isShowInputControl: boolean;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  public agreementTypeOptionList: OptionListModel<AgreementType>; 

  public isShowNextProcess = true;
  public agreementType: AgreementType;

  constructor(
    public translateService: TranslateService
  ) {
    super('app-agreement-type');
  }

  public onInit(): void {
    this.setOptionList();
    this.buildFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
        agreementType: [this.agreementTypeParam, Validators.required()]
      });
    
  }

  public setOptionList(): void {
    if(this.isShowInputControl){
      this.agreementTypeOptionList = new OptionListModel(true, 'name');
      this.httpClientService
      .get<AgreementType[]>('/app-agreement-type/get-option-list?component='+this.component)
      .subscribe((response: AgreementType[]) => {
        this.agreementTypeOptionList.setRequestValues(response);
        if(response.length >1){
          this.isShowNextProcess = true;
        }else{
          this.isShowNextProcess = false;
        }
        this.doChooseAgreementType();
      });
    }else{
      this.formGroup.patchValue({agreementType: this.agreementTypeParam});
      const agreementTypeList = [];
      agreementTypeList.push(this.agreementTypeParam);
      this.agreementTypeOptionList = new OptionListModel(false, 'name');
      this.agreementTypeOptionList.setRequestValues(agreementTypeList);
    }
  }

  public doChooseAgreementType(): void {
    this.agreementType = this.formGroup.get('agreementType').value;
    this.onChange.emit(this.agreementType);
  }
}
