import { Component, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Organization } from '../../core/bean/organization';
import { User } from '../../core/bean/user';
import { UserRole } from '../../core/bean/user-role';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { AppPopupChangeEmailUserComponent } from '../user/app-popup-change-email-user/app-popup-change-email-user.component';
import { UserRoleLog } from './../../core/bean/user-role-log';
import { UserCheckProposeRequest } from './user-check-propose-request';
import { UserResponse } from './user-response';
import { UserSendVerificationEmailRequest } from './user-send-verification-email-request';

@Component({
  templateUrl: './user-edit-add.component.html',
  styles: [
    '.table th.fit{ white-space: nowrap; width: 200px; min-width: 200px;}'
  ],
  encapsulation: ViewEncapsulation.None
})
export class UserEditAddComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<UserRoleLog>;
  public userResponse: UserResponse = new UserResponse();
  public userCheckProposeRequest: UserCheckProposeRequest =
    new UserCheckProposeRequest();
  public userSendVerificationEmail: UserSendVerificationEmailRequest =
    new UserSendVerificationEmailRequest();
  public removedUserRole: UserRole[] = [];
  public userId: number;
  public isVendor = false;
  public isRoleValid = false;
  public isEdit = false;
  public statusOptionList: OptionListModel<any> = new OptionListModel();
  public userRoleOptionList: OptionListModel<any> = new OptionListModel();
  public organizationName: string;
  public isUserRole: boolean;
  public buttonRemoveTooltip: string;
  public defaultRoleOptionList: OptionListModel<any> = new OptionListModel();
  public readonly DELETE = 0;
  public readonly UPDATE = 1;
  public readonly INSERT = 2;
  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService,
    public appPopupService: AppPopupService
  ) {
    super('user', translateService);
  }

  public onInit(): void {
    this.setOptionList();
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.doSetCustomDataTable();
    this.doGetAndSetUserDTOToFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.userId = this.global.routerParams.get('userId');
  }

  public doSetOrganizationName(): void {
    if (
      this.userResponse.organizationList &&
      this.userResponse.organizationList.length > 0
    ) {
      this.organizationName = this.userResponse.organizationList
        .map(organization => organization.name)
        .join(' > ');
    }
  }

  public setOptionList(): void {
    this.defaultRoleOptionList.setRequestValues([
      {
        value: true
      }
    ]);
  }

  public onChangeDefaultRole(event, index: number): void {
    this.log.debug(event.returnValue);
    this.userRoleList.controls.forEach((control, i) => {
      if (i === index) {
        control.get('isDefault').patchValue(true);
      } else {
        control.get('isDefault').patchValue(false);
      }
    });
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [null, Validators.required()],
      email: [
        null,
        { validators: [Validators.required(), Validators.email()] }
      ],
      organization: [null, Validators.required()],
      password: [null],
      isActive: [true],
      isLocked: [true],
      startDate: [null],
      endDate: [null],
      userRoleList: this.formBuilder.array([]),
      loginAttempt: [null],
      sipaNumber: [null],
    });
  }

  public doBuildTableResponse(): void {
    if (this.todo === 'edit') {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        { field: 'role.name', header: 'table.column.role' },
        {
          field: 'startDate',
          header: 'table.column.startDate',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'endDate',
          header: 'table.column.endDate',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'logDate',
          header: 'table.column.logDate',
          format: FieldFormatEnum.ShortDate
        }
      ]);
    }
  }

  public doSetCustomDataTable(): void {
    if (this.todo === 'edit') {
      this.tableResponse.setCustomData(this.userId);
    }
  }

  public doSetOptionList(): void {
    const option = [
      { name: 'Active', value: true },
      { name: 'Non Active', value: false }
    ];
    this.statusOptionList.setRequestValues(option);
  }

  public doGetAndSetUserDTOToFormGroup(): void {
    this.httpClientService
      .post<UserResponse>(
        '/user/add-edit',
        new RouteRequestModel(this.todo, this.userId)
      )
      .subscribe(userResponse => {
        this.userResponse = userResponse;
        this.userRoleOptionList.setRequestValues(userResponse.roleList);
        this.setFormGroup(userResponse);
        if (this.todo === 'edit') {
          this.isVendor = userResponse.isVendor;
          this.isEdit = true;
          this.buildFormGroupUserRoleList(userResponse.userRoleList);
          this.formGroup
            .get('organization')
            .patchValue(userResponse.organizationList);
        } else {
          this.doAdd();
        }
        this.doSetOrganizationName();
        this.setStateReady();
      });
  }

  public setFormGroup(userResponse: UserResponse): void {
    if (userResponse.user.id != null) {
      const {
        id,
        name,
        email,
        password,
        organization,
        isActive,
        loginAttempt,
        sipaNumber
      } = userResponse.user;
      this.formGroup.patchValue({
        id,
        name,
        email,
        isActive,
        password,
        organization,
        loginAttempt,
        sipaNumber
      });
      if (!userResponse.user.isLocked) {
        this.formGroup.get('isLocked').patchValue(true);
      } else {
        this.formGroup.get('isLocked').patchValue(false);
      }
    }
  }

  public buildFormGroupUserRoleList(userRoleList: UserRole[]): void {
    if (userRoleList !== null) {
      this.isVendor = false;
      userRoleList.forEach(userRole => {
        this.userRoleList.push(
          this.formBuilder.group({
            id: userRole.id,
            role: [userRole.role, Validators.required()],
            startDate: [new Date(userRole.startDate), Validators.required()],
            endDate: [new Date(userRole.endDate), Validators.required()],
            currentDate: new Date(),
            isDefault: [userRole.isDefault],
            crudOperation: this.UPDATE
          })
        );
      });
      this.setMinDate(this.userRoleList);
    }
  }

  public setMinDate(userRoleList: FormArray): void {
    userRoleList.value.forEach((userRole, index) => {
      if (userRole.startDate && userRole.startDate < new Date()) {
        this.userRoleList.controls[index].patchValue({
          currentDate: userRole.startDate
        });
      }
    });
  }

  public selectedRole(): void {
    const selectedUserRole = this.formGroup.get('userRoleList').value;
    const userRoleOptionList = this.userRoleOptionList.getRequestValues();
    const userRoleOptionListTemp = [];
    const selectedUserRoleNew = [];

    selectedUserRole.forEach(userRole => {
      selectedUserRoleNew.push(userRole.role);
    });

    userRoleOptionList.forEach(option => {
      const indexUserRole = selectedUserRoleNew.findIndex(
        (userRole: any) => JSON.stringify(userRole) === JSON.stringify(option)
      );
      if (indexUserRole === -1) {
        userRoleOptionListTemp.push(option);
      }
    });
    this.userRoleOptionList.setRequestValues(userRoleOptionListTemp);
  }

  public doAdd(): void {
    this.userRoleList.push(this.formGroupUserRoleList);
    this.selectedRole();
    this.isRoleValid = false;
    this.isVendor = false;
  }

  public doRemove(index: number, userRole: UserRole): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (userRole.id === null) {
            this.userRoleList.removeAt(index);
          } else {
            this.removedUserRole.push(userRole);
            if (
              this.userRoleList.controls[index].value.crudOperation ===
              this.INSERT
            ) {
              this.userRoleList.removeAt(index);
            } else {
              this.userRoleList.controls[index].patchValue({
                crudOperation: this.DELETE
              });
            }
          }
        }
      });
  }

  public doChooseOrganization(
    userRole: FormGroup,
    event: any,
    i: number
  ): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(data => {
        const { name: organizationName } = data[0];
        userRole.patchValue({ organizationName, organization: data[0] });
        this.validateRoleOrganization(userRole, i);
      });
  }

  public doChooseRole(userRole: FormGroup, i: number): void {
    this.validateRoleOrganization(userRole, i);
  }

  public validateRoleOrganization(userRole: FormGroup, i: number): void {
    if (userRole.value.organization !== null && userRole.value.role !== null) {
      if (this.userRoleList.length > 1) {
        for (
          let index = 0;
          index < this.userRoleList.controls.length;
          index++
        ) {
          if (index !== i) {
            if (
              this.userRoleList.controls[index].value.role.id ===
              userRole.value.role.id &&
              this.userRoleList.controls[index].value.organization.id ===
              userRole.value.organization.id
            ) {
              this.global.alertService.showError(
                this.translateService.instant('user.error.userRole')
              );
              this.isRoleValid = false;
            } else {
              this.isRoleValid = true;
            }
          }
        }
      } else {
        this.isRoleValid = true;
      }
    }
  }

  public doSave(): void {
    this.validate();
    let countingZero = 0;
    this.formGroup.get('userRoleList').value.forEach(userRole => {
      if (userRole.crudOperation === 0) {
        countingZero += 1;
      }
    });

    let savingIsVendor = false;
    if (
      this.isVendor &&
      this.formGroup.get('userRoleList').value.length === 0
    ) {
      savingIsVendor = true;
    }

    const isDefaultOneSelected =
      (this.formGroup
        .get('userRoleList')
        .value.filter(
          (userRole: UserRole) =>
            userRole.isDefault && userRole.crudOperation !== this.DELETE
        )?.length === 1 &&
        !savingIsVendor) ||
      savingIsVendor;

    if (
      this.formGroup.valid &&
      ((this.formGroup.get('userRoleList').value.length > 0 &&
        this.formGroup.get('userRoleList').value.length !== countingZero &&
        !savingIsVendor) ||
        this.formGroup.get('isActive').value ||
        savingIsVendor) &&
      isDefaultOneSelected
    ) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const url = this.todo === 'edit' ? '/user/update' : '/user/insert';
            const user = this.global.copyFormAttributeToModel(
              new User(),
              this.formGroup
            );
            const organizationList: Organization[] =
              this.formGroup.get('organization').value;
            user.organization = organizationList[organizationList.length - 1];
            if (user.isLocked) {
              user.isLocked = false;
            } else {
              user.isLocked = true;
            }
            this.httpClientService
              .post<Response<User>>(url, user)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/user/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    } else if (
      this.formGroup.get('userRoleList').value.length <= 0 ||
      (this.formGroup.get('userRoleList').value.length === countingZero &&
        !savingIsVendor)
    ) {
      this.global.alertService.showError(
        this.translateService.instant('user.msg.minimalOneRole')
      );
    } else if (!isDefaultOneSelected) {
      this.global.alertService.showError(
        this.translateService.instant('user.msg.chooseOneDefaultRole')
      );
    }
  }

  public doResetPassword(): void {
    const user = this.global.copyFormAttributeToModel(
      new User(),
      this.formGroup
    );
    const organizationList: Organization[] =
      this.formGroup.get('organization').value;
    user.organization = organizationList[organizationList.length - 1];
    if (user.isLocked) {
      user.isLocked = false;
    } else {
      user.isLocked = true;
    }
    this.global.modalService
      .confirm(
        this.translateService.instant(
          'reset-password-confirmation.confirmation.resetPassword'
        )
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.userCheckProposeRequest.actionUser = 'reset-password';
          this.userCheckProposeRequest.user = user;
          this.httpClientService
            .post<UserCheckProposeRequest>(
              '/user/check-propose',
              this.userCheckProposeRequest
            )
            .subscribe((userCheckProposeRequest: UserCheckProposeRequest) => {
              if (
                userCheckProposeRequest.user.proposeType === 'EMAIL' &&
                userCheckProposeRequest.validDatePropose === true
              ) {
                this.global.alertService.showError(
                  'Anda sudah mengajukan perubahan email'
                );
              } else if (
                userCheckProposeRequest.user.proposeType === 'PASSWORD' &&
                userCheckProposeRequest.validDatePropose === true
              ) {
                this.global.alertService.showError(
                  'Anda sudah mengajukan perubahan password'
                );
              } else {
                this.httpClientService
                  .post<Response<User>>('/user/reset-password', user)
                  .subscribe((response: Response<User>) => {
                    this.formSaving = false;
                    this.formGroup.enable();
                    if (response.status === ResponseStatusModel.OK) {
                      this.global.alertService.showSuccess(
                        this.translateService.instant(
                          'reset-password-confirmation.msg.success.sendEmail'
                        )
                      );
                    } else {
                      this.global.alertService.showError(response.statusText);
                    }
                    this.setStateReady();
                  });
              }
            });
        }
      });
  }

  public doResetAttempt(): void {
    this.global.modalService
      .confirm(
        this.translateService.instant('user.msg.confirmation.resetAttempt')
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const user = this.userResponse.user;
          this.setStateProcessing();
          this.httpClientService
            .post<Response<User>>('/user/reset-attempt', user)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessOnNextRoute(
                  this.translateService.instant('user.msg.success.resetAttempt')
                );
                this.router.navigate(['/pages/user/']);
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/user/']);
  }

  public get formGroupUserRoleList(): FormGroup {
    return this.formBuilder.group({
      role: [null, Validators.required()],
      startDate: [null, Validators.required()],
      endDate: [null, Validators.required()],
      currentDate: [new Date(), Validators.required()],
      isDefault: [null],
      crudOperation: this.INSERT
    });
  }

  public get userRoleList(): FormArray {
    return this.formGroup.controls.userRoleList as FormArray;
  }

  public doChangeEmail(): void {
    const user = this.global.copyFormAttributeToModel(
      new User(),
      this.formGroup
    );
    const organizationList: Organization[] =
      this.formGroup.get('organization').value;
    user.organization = organizationList[organizationList.length - 1];
    if (user.isLocked) {
      user.isLocked = false;
    } else {
      user.isLocked = true;
    }
    this.userCheckProposeRequest.actionUser = 'change-email';
    this.userCheckProposeRequest.user = user;
    this.loadingBlockService.showInfo('Checking user propose...');
    this.httpClientService
      .post<UserCheckProposeRequest>(
        '/user/check-propose',
        this.userCheckProposeRequest
      )
      .subscribe((userCheckProposeRequest: UserCheckProposeRequest) => {
        this.loadingBlockService.close();
        if (
          userCheckProposeRequest.user.proposeType === 'EMAIL' &&
          userCheckProposeRequest.validDatePropose === true
        ) {
          this.global.alertService.showError(
            'Anda sudah mengajukan perubahan email'
          );
        } else if (
          userCheckProposeRequest.user.proposeType === 'PASSWORD' &&
          userCheckProposeRequest.validDatePropose === true
        ) {
          this.global.alertService.showError(
            'Anda sudah mengajukan perubahan password'
          );
        } else {
          this.userSendVerificationEmail.user = user;
          this.appPopupService
            .open(
              AppPopupChangeEmailUserComponent,
              {
                userSendVerificationEmailRequest: this.userSendVerificationEmail
              },
              { centered: false }
            )
            .subscribe(userSendVerificationEmail => {
              this.loadingBlockService.showInfo('app.msg.processing');
              this.httpClientService
                .post<Response<User>>(
                  '/user/send-verification-email',
                  userSendVerificationEmail
                )
                .subscribe((resp: Response<UserResponse>) => {
                  this.loadingBlockService.close();
                  if (resp.status === ResponseStatusModel.OK) {
                    this.global.alertService.showSuccess(
                      this.translateService.instant('Email Berhasil Dikirim')
                    );
                    this.onInit();
                  } else {
                    this.global.alertService.showError(resp.statusText);
                  }
                  this.setStateReady();
                });
            });
        }
      });
  }

  public resolveTableIndex(currentIndex: number, dataList: Array<any>): number {
    const dataListCopy = Array.from(dataList);
    const dataListWithHaveNocrudOperation = dataListCopy
      .splice(0, currentIndex)
      .filter(data => data.value.crudOperation === this.DELETE);
    return currentIndex + 1 - dataListWithHaveNocrudOperation.length;
  }
}
