import { FinesAdjustment } from '../../../core/bean/fines-adjustment';
import { FinesAdjustmentHistory } from '../../../core/bean/fines-adjustment-history';
import { ProcurementSow } from '../../../core/bean/procurement-sow';
import { ProcurementVendor } from '../../../core/bean/procurement-vendor';

export class FinesMonitoringResponse {
  procurementVendor: ProcurementVendor;
  vendorNameList: string[];
  procurementNameList: string[];
  agreementNumberList: string[];
  procurementSowList: ProcurementSow[] = [];
  finesAdjustmentList: FinesAdjustment[] = [];
  finesAdjustmentHistoryList: FinesAdjustmentHistory[] = [];
}
