import { ScoringCriteriaGroup } from 'src/app/core/bean/scoring-criteria-group';
import { ScoringGroupTemplate } from 'src/app/core/bean/scoring-group-template';
import { ScoringTemplate } from 'src/app/core/bean/scoring-template';
import { Vendor } from 'src/app/core/bean/vendor';
import { VendorAddress } from 'src/app/core/bean/vendor-address';
import { VendorPerformance } from 'src/app/core/bean/vendor-performance';
import { VendorPerformanceScore } from 'src/app/core/bean/vendor-performance-score';
import { VendorPerformanceSummary } from 'src/app/core/bean/vendor-performance-summary';

export class PerformanceFulfillmentDetailResponse {
    vendor: Vendor = new Vendor();
    vendorAddress: VendorAddress = new VendorAddress();
    agreementNumber: string;
    agreementType: string;
    termin: string;

    performance: VendorPerformance = new VendorPerformance();
    scoringTemplate: ScoringTemplate = new ScoringTemplate();
    scoringGroupTemplateList: ScoringGroupTemplate[] = [];
    scoringCriteriaGroupByIsCommonList: ScoringCriteriaGroup[] = [];
    scoringCriteriaGroupByIsSpecificList: ScoringCriteriaGroup[] = [];
    performanceScoreList: VendorPerformanceScore[] = [];
    performanceSummaryList: VendorPerformanceSummary[] = [];
}
