<app-popup
  [header]="'tab-profile-rep-agent.form.popup.header.repAgent.' + todo"
  [isLoading]="formLoading"
>
  <form [formGroup]="formGroup" class="app-popup-rep-agent">
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-rep-agent.form.popup.companyStatus' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-radio
          size="LG"
          formControlName="companyStatus"
          position="HORIZONTAL"
          [optionList]="companyStatusOptionList"
          (onChange)="handleChange()"
        >
          <app-flag-new
            *ngIf="
            global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
            "
            [oldValue]="companyStatusName"
            [newValue]="companyStatusHistoryName"
          ></app-flag-new>
        </app-radio>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
      *ngIf="formGroup.get('companyStatus').value"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-rep-agent.form.popup.dataSource' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-radio
          size="LG"
          formControlName="dataSource"
          position="HORIZONTAL"
          [optionList]="dataSouceOptionsList"
          (change)="handleChangeSource()"
          (onChange)="doChange()"
          tooltip="{{ 'tab-profile-rep-agent.tooltip.dataSource' | translate }}"
        >
          <app-flag-new
            *ngIf="
            global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
            "
            [oldValue]="dataSourceActualName"
            [newValue]="dataSourceName"
          ></app-flag-new>
        </app-radio>
      </div>
    </div>

    <div
      *ngIf="
        formGroup.get('dataSource').value === 1;
        then blockOne;
        else blockTwo
      "
    ></div>

    <ng-template #blockOne>
      <ng-container *ngIf="formGroup.get('dataSource').value === 1">
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'tab-profile-rep-agent.form.popup.companyName' | translate
          }}</label>
          <div
            class="text-left"
            [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
          >
            <app-auto-complete
              size="LG"
              formControlName="companyName"
              stringUrl="/tab-profile-rep-agent/auto-complete-vendor-list"
              (onChange)="autoCompleteValueChange($event)"
              maxLength="255"
            >
              <app-flag-new
                *ngIf="
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
                "
                [oldValue]="vendorRepAgent.companyName"
                [newValue]="vendorRepresentativeAgentHistoryEdit.companyName"
              ></app-flag-new>
            </app-auto-complete>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'tab-profile-rep-agent.form.popup.taxNumber' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div class="row" [ngStyle]="{ 'padding-left': '15px' }">
              <div [ngStyle]="{ display: 'inline-grid', width: '190px' }">
                <app-npwp
                  size="XL"
                  formControlName="taxNumber"
                  [ngStyle]="{ width: '333px', 'margin-right': '0.5rem' }"
                  *ngIf="
                    vendorType.code !==
                    global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
                  "
                  (onChange)="onChangeTaxNumber()"
                >
                  <app-flag-new
                    *ngIf="
                      global.userSession.activeUserRole.role.type !==
                        global.appConstant.core.ROLE_TYPE_VENDOR &&
                      formGroup.isView &&
                      !isVendorList &&
                      (fromOutside === '/pages/on-boarding' ||
                        fromOutside === '/pages/approval-prcs') &&
                      vendorRepresentativeAgentHistoryEdit.vendorHistory !==
                        null
                    "
                    [oldValue]="vendorRepAgent.taxNumber"
                    [newValue]="vendorRepresentativeAgentHistoryEdit.taxNumber"
                  ></app-flag-new>
                </app-npwp>
                <app-text-field
                  size="XL"
                  formControlName="taxNumber"
                  maxLength="32"
                  type="alphanumeric"
                  [ngStyle]="{ width: '333px', 'margin-right': '0.5rem' }"
                  *ngIf="
                    vendorType.code ===
                    global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
                  "
                  (onChange)="onChangeTaxNumber()"
                >
                  <app-flag-new
                    *ngIf="
                      global.userSession.activeUserRole.role.type !==
                        global.appConstant.core.ROLE_TYPE_VENDOR &&
                      formGroup.isView &&
                      !isVendorList &&
                      (fromOutside === '/pages/on-boarding' ||
                        fromOutside === '/pages/approval-prcs') &&
                      vendorRepresentativeAgentHistoryEdit.vendorHistory !==
                        null
                    "
                    [oldValue]="vendorRepAgent.taxNumber"
                    [newValue]="vendorRepresentativeAgentHistoryEdit.taxNumber"
                  ></app-flag-new>
                </app-text-field>
              </div>
              <div
                class="ml-1 col-sm-3 pl-1 pr-0"
                [ngStyle]="{ display: 'inline-grid', width: '139px' }"
              >
                <app-button
                  [ngStyle]="{ 'padding-left': '143px' }"
                  *ngIf="!isViewOnly"
                  (onClick)="doSearch()"
                  >{{ 'app.button.search' | translate }}
                </app-button>
              </div>
            </div>
            <div class="row" [ngStyle]="{ 'padding-left': '15px' }"> </div>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-profile-rep-agent.form.popup.email' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p *ngIf="!formGroup.isView">{{
              formGroup.get('email').value || '-'
            }}</p>
            <app-text-field
              size="LG"
              formControlName="email"
              maxLength="32"
              *ngIf="formGroup.isView"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
                "
                [oldValue]="vendorRepAgent.email"
                [newValue]="vendorRepresentativeAgentHistoryEdit.email"
              ></app-flag-new>
            </app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-profile-rep-agent.form.popup.phone' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p *ngIf="!formGroup.isView">{{ codeNumber || '-' }}</p>
            <app-phone
              size="LG"
              formControlName="appPhone"
              [optionList]="regionPhoneOptionList"
              maxLength="32"
              *ngIf="formGroup.isView"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
                "
                [oldValue]="vendorRepAgent.phoneNumber"
                [newValue]="vendorRepresentativeAgentHistoryEdit.phoneNumber"
              ></app-flag-new>
            </app-phone>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-profile-rep-agent.form.popup.address' | translate
          }}</label>
          <div
            class="text-left"
            [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
          >
            <p *ngIf="!formGroup.isView">{{
              formGroup.get('address').value || '-'
            }}</p>
            <app-text-area
              size="LG"
              formControlName="address"
              maxLength="512"
              *ngIf="formGroup.isView"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
                "
                [oldValue]="vendorRepAgent.address"
                [newValue]="vendorRepresentativeAgentHistoryEdit.address"
                [ngStye]="{ 'padding-left': '3px' }"
              ></app-flag-new
            ></app-text-area>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-profile-rep-agent.form.popup.coordinateLocation' | translate
          }}</label>
          <div
            class="text-left"
            [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
          >
            <div *ngIf="formGroup.get('coordinateLocation').value === null">
              <p>-</p>
            </div>
            <div *ngIf="formGroup.get('coordinateLocation').value !== null">
              <app-text-map size="LG" formControlName="coordinateLocation">
                <app-flag-new
                  *ngIf="
                    global.userSession.activeUserRole.role.type !==
                      global.appConstant.core.ROLE_TYPE_VENDOR &&
                    formGroup.isView &&
                    !isVendorList &&
                    (fromOutside === '/pages/on-boarding' ||
                      fromOutside === '/pages/approval-prcs') &&
                    vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
                  "
                  [oldValue]="vendorRepAgent.coordinateLocation"
                  [newValue]="
                    vendorRepresentativeAgentHistoryEdit.coordinateLocation
                  "
                ></app-flag-new>
              </app-text-map>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #blockTwo>
      <ng-container *ngIf="formGroup.get('dataSource').value === 2">
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'tab-profile-rep-agent.form.popup.companyName' | translate
          }}</label>
          <div
            class="text-left"
            [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
          >
            <app-text-field
              size="LG"
              formControlName="companyName"
              maxLength="255"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
                "
                [oldValue]="vendorRepAgent.companyName"
                [newValue]="vendorRepresentativeAgentHistoryEdit.companyName"
              ></app-flag-new>
            </app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'tab-profile-rep-agent.form.popup.taxNumber' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-npwp
              size="LG"
              formControlName="taxNumber"
              *ngIf="
                vendorType.code !==
                global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
              "
              (onChange)="onChangeTaxNumber()"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
                "
                [oldValue]="vendorRepAgent.taxNumber"
                [newValue]="vendorRepresentativeAgentHistoryEdit.taxNumber"
              ></app-flag-new>
            </app-npwp>
            <app-text-field
              size="LG"
              formControlName="taxNumber"
              maxLength="32"
              type="alphanumeric"
              *ngIf="
                vendorType.code ===
                global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
              "
              (onChange)="onChangeTaxNumber()"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
                "
                [oldValue]="vendorRepAgent.taxNumber"
                [newValue]="vendorRepresentativeAgentHistoryEdit.taxNumber"
              ></app-flag-new>
            </app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'tab-profile-rep-agent.form.popup.email' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field size="LG" formControlName="email" maxLength="32">
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
                "
                [oldValue]="vendorRepAgent.email"
                [newValue]="vendorRepresentativeAgentHistoryEdit.email"
              ></app-flag-new>
            </app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'tab-profile-rep-agent.form.popup.phone' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-phone
              size="LG"
              formControlName="appPhone"
              [optionList]="regionPhoneOptionList"
              maxLength="32"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
                "
                [oldValue]="vendorRepAgent.phoneNumber"
                [newValue]="vendorRepresentativeAgentHistoryEdit.phoneNumber"
              ></app-flag-new>
            </app-phone>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'tab-profile-rep-agent.form.popup.address' | translate
          }}</label>
          <div
            class="text-left"
            [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
          >
            <app-text-area size="LG" formControlName="address" maxLength="512">
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
                "
                [oldValue]="vendorRepAgent.address"
                [newValue]="vendorRepresentativeAgentHistoryEdit.address"
                [ngStyle]="{ 'padding-left': '3px' }"
              ></app-flag-new
            ></app-text-area>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-profile-rep-agent.form.popup.coordinateLocation' | translate
          }}</label>
          <div
            class="text-left"
            [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
          >
            <app-text-map size="LG" formControlName="coordinateLocation">
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
                "
                [oldValue]="vendorRepAgent.coordinateLocation"
                [newValue]="
                  vendorRepresentativeAgentHistoryEdit.coordinateLocation
                "
              ></app-flag-new>
            </app-text-map>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
      *ngIf="formGroup.get('dataSource').value"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-rep-agent.form.popup.information' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        <app-text-area size="LG" formControlName="information" maxLength="512">
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorRepresentativeAgentHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorRepAgent.information"
            [newValue]="vendorRepresentativeAgentHistoryEdit.information"
            [ngStyle]="{ 'padding-left': '3px' }"
          ></app-flag-new>
        </app-text-area>
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)"
          >{{ 'app.button.close' | translate }}
        </app-button>
        <app-button *ngIf="!formGroup.isView" (onClick)="doSave()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
