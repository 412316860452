import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { CounterEditAddComponent } from './counter-edit-add.component';
import { CounterComponent } from './counter.component';

export const routes = [
  { path: '', component: CounterComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: CounterEditAddComponent,
    data: { breadcrumb: 'counter.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: CounterEditAddComponent,
    data: { breadcrumb: 'counter.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), NgbModule],

  declarations: [CounterComponent, CounterEditAddComponent],

  providers: []
})
export class CounterModule {}
