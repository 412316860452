import { HttpClientService } from '../../../services/http-client.service';
import { TablePageModel } from '../../app-table/model/table-page-model';
import { TableRow } from '../domain/table-row';
import { TableState } from '../domain/table-state';
import { resolveTableRowChildrenList } from './resolve-table-row-children-list-helper';
import { searchTableRecords } from './search-table-records-helper';
import { sortTableRecord } from './sort-table-record-helper';
export function resolveTableRowList(
  state: TableState,
  isNoLimit?: boolean
): Promise<Array<TableRow>> {
  return new Promise(resolve => {
    let tableRecordList: Array<TableRow>;
    if (state.isServerSide) {
      const httpClientSvice = state.injector.get(HttpClientService);
      httpClientSvice
        .post<TablePageModel<Object>>(state.stringUrl, state.tableRequest)
        .subscribe((page: TablePageModel<Object>) => {
          state.pagination.setTotalRecords(page.totalRecords);
          tableRecordList = page.records.map(record => new TableRow(record));
          setExpandToAllRecord(state, tableRecordList);
          resolve(tableRecordList);
        });
    } else {
      let modelRecordsCopy = Array.from(state.model.getRecordList() || []);
      if (state.tableRequest.sortField) {
        modelRecordsCopy = sortTableRecord(state, modelRecordsCopy);
      }
      modelRecordsCopy = searchTableRecords(state, modelRecordsCopy);
      if (!isNoLimit) {
        tableRecordList = Array.from(modelRecordsCopy)
          .splice(
            (state.pagination.page - 1) * state.pagination.perPage,
            state.pagination.perPage
          )
          .map(record => new TableRow(record));
        state.pagination.setTotalRecords(modelRecordsCopy.length);
        // setExpandToAllRecord(state, tableRecordList);
        resolve(tableRecordList);
      } else {
        tableRecordList = Array.from(modelRecordsCopy || []).map(
          record => new TableRow(record)
        );
        // setExpandToAllRecord(state, tableRecordList);
        resolve(tableRecordList);
      }
    }
  });
}

const setExpandToAllRecord = (
  state: TableState,
  tableRowList: Array<TableRow>
): void => {
  if (
    state.model.options &&
    state.model.options.group &&
    state.model.options.group.isExpanded
  ) {
    tableRowList.forEach(tableRow => {
      tableRow.isExpanded = true;
      tableRow.setStateLoading();
      resolveTableRowChildrenList(state, tableRow).then(
        (value: { rowList: TableRow[]; totalRecord: number }) => {
          tableRow.childrenList = value.rowList;
          tableRow.totalChildrenList = value.totalRecord;
          tableRow.isShowButtonLoadMore =
            tableRow.childrenList.length < value.totalRecord;
          value.rowList.forEach(row => {
            if (
              state.selectedRow
                .getSelectedRecordList()
                .findIndex(
                  record =>
                    JSON.stringify(record) === JSON.stringify(row.record)
                ) !== -1
            ) {
              tableRow.selectedRow.addRowToSelectedRowList(row);
            }
          });
          if (
            tableRow.totalChildrenList ===
            tableRow.selectedRow.getSelectedRecordList().length
          ) {
            tableRow.selectedRow.isSelectAll = true;
          }
          tableRow.setStateReady();
        }
      );
    });
  }
};
