<app-popup header="scoring-template.popup.title">
  <div class="row main-row">
    <div class="col-12">
      <!-- variable !isEmpty dipastikan di .ts ada 
        <form class="form-horizontal" 
        [formGroup]="formGroup" *ngIf="!isEmpty">
            -->
      <form class="form-horizontal" [formGroup]="formGroup">
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'scoring-template.form.code' | translate
          }}</label>
          <div class="col-sm-7 text-left">
            <p>{{ scoringTemplate.code }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'scoring-template.form.name' | translate
          }}</label>
          <div class="col-sm-7 text-left">
            <p>{{ scoringTemplate.name }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'scoring-template.form.description' | translate
          }}</label>
          <div class="col-sm-7 text-left">
            <p class="text-break">{{ scoringTemplate.description }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'scoring-template.form.moduleScoringCriteria' | translate
          }}</label>
          <div class="col-sm-7 text-left">
            <p>{{ moduleScoringCriteriaName }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label
            *ngIf="moduleScoringCriteriaName === null"
            class="col-sm-3 control-label"
            >{{ 'scoring-template.form.typeOnly' | translate }}</label
          >
          <label
            *ngIf="moduleScoringCriteriaName === 'Registration'"
            class="col-sm-3 control-label"
            >{{ 'scoring-template.form.type' | translate }}</label
          >
          <label
            *ngIf="moduleScoringCriteriaName === 'Procurement'"
            class="col-sm-3 control-label"
            >{{ 'scoring-template.form.typeProcurement' | translate }}</label
          >
          <label
            *ngIf="moduleScoringCriteriaName === 'Fulfillment'"
            class="col-sm-3 control-label"
            >{{ 'scoring-template.form.objectName' | translate }}</label
          >

          <div class="col-sm-7 text-left">
            <p>{{ objectName }}</p>
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          *ngIf="moduleScoringCriteriaName === 'Fulfillment'"
        >
          <label class="col-sm-3 control-label">{{
            'scoring-template.form.objectType' | translate
          }}</label>

          <div class="col-sm-7 text-left">
            <p>{{ type }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'scoring-template.form.minimumQualified' | translate
          }}</label>
          <div class="col-sm-7 text-left">
            <p>{{ scoringTemplate.minimumQualified }}</p>
          </div>
        </div>

        <app-fieldset legend="Common">
          <ng-container>
            <div class="row text-sm-left">
              <div class="col-sm-2"></div>
              <label class="col-sm-6 control-label">{{
                'scoring-template.form.option' | translate
              }}</label>
              <label class="col-sm-4 control-label"
                >{{ 'scoring-template.form.weight' | translate }} (%)</label
              >
            </div>
            <div
              class="row text-sm-right"
              *ngFor="
                let scoringTemplateWeightListCommon of scoringTemplateWeightListCommon;
                let i = index
              "
            >
              <div class="col-sm-2 mr-4"></div>
              <div class="col-sm-10 col-lg-8 text-left">
                <div class="form-row">
                  <div class="row col-sm-10">
                    <div class="col text-left">
                      <p>{{
                        scoringTemplateWeightListCommon.scoringCriteriaGroup
                      }}</p>
                    </div>
                  </div>

                  <div class="row col-sm-2">
                    <div class="col text-left">
                      <p>{{ scoringTemplateWeightListCommon.weight }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </app-fieldset>

        <app-fieldset legend="Specific">
          <ng-container>
            <div class="row text-sm-left">
              <div class="col-sm-2"></div>
              <label class="col-sm-6 control-label">{{
                'scoring-template.form.option' | translate
              }}</label>
              <label class="col-sm-4 control-label"
                >{{ 'scoring-template.form.weight' | translate }} (%)</label
              >
            </div>
            <div
              class="row text-sm-right"
              *ngFor="
                let scoringTemplateWeightListSpesific of scoringTemplateWeightListSpesific;
                let i = index
              "
            >
              <div class="col-sm-2 mr-4"></div>
              <div class="col-sm-10 col-lg-8 text-left">
                <div class="form-row">
                  <div class="row col-sm-10">
                    <div class="col text-left">
                      <p>{{
                        scoringTemplateWeightListSpesific.scoringCriteriaGroup
                      }}</p>
                    </div>
                  </div>

                  <div class="row col-sm-2">
                    <div class="col text-left">
                      <p>{{ scoringTemplateWeightListSpesific.weight }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </app-fieldset>
      </form>
      <div class="row justify-content-center mt-5">
        <app-button
          color="SECONDARY"
          (onClick)="activeModal.dismiss()"
          class="mr-1"
        >
          {{ 'app.button.close' | translate }}
        </app-button>
        <app-button color="PRIMARY" (onClick)="doCopy(scoringTemplate.id)">
          {{ 'app.button.copyAndEdit' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</app-popup>
