<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="work-plan.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <div id="work-program-sync-info" class="alert alert-warning"  role="alert" *ngIf="(websocketWorkProgramService.message | async)">
          <strong class="mr-2"><em class="fa fa-info-circle mr-2"></em>{{ 'work-program.sync.info' | translate }}</strong>
          {{ (websocketWorkProgramService.message | async) }}
      </div>
      <div id="work-program-sync-info" class="alert alert-warning"  role="alert" *ngIf="(websocketBudgetService.message | async)">
          <strong class="mr-2"><em class="fa fa-info-circle mr-2"></em>{{ 'budget-allocation-bjb.sync.info' | translate }}</strong>
          {{ (websocketBudgetService.message | async) }}
      </div>
      <app-table
        [tableResponse]="tableResponse"
        [isServerSide]="true"
        stringUrl="/work-plan/index"
        customData="{{ customData }}"
      >
        <ng-template #headerButtons>
          <app-button (onClick)="doAdd()" [disabled]="(websocketBudgetService.message | async) || (websocketWorkProgramService.message | async)"
            ><em class="fas fa-plus"></em>
            {{ 'app.button.add' | translate }}</app-button
          >
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            *ngIf="
              (rowData.workPlan.workPlanStatus.code ==
              global.appConstant.esm.WORK_PLAN_STATUS_DRAFT &&
                rowData.workPlan.workflowPrcs === null) ||
              (rowData.workPlan.workPlanStatus.code ===
              global.appConstant.esm.WORK_PLAN_STATUS_REVISION)
            "
            (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}"
            [disabled]="(websocketBudgetService.message | async) || (websocketWorkProgramService.message | async)"
          >
            <em class="fas fa-pencil-alt"></em>
          </app-button>
          <app-button
            size="TN"
            color="DANGER"
            *ngIf="
              rowData.workPlan.workPlanStatus.code ==
              global.appConstant.esm.WORK_PLAN_STATUS_DRAFT &&
              rowData.workPlan.workflowPrcs === null
            "
            (onClick)="doDelete(rowData)"
            title="{{ 'app.tooltip.delete' | translate }}"
            [disabled]="(websocketBudgetService.message | async) || (websocketWorkProgramService.message | async)"
          >
            <em class="fas fa-trash"></em>
          </app-button>
          <!-- <app-button size="TN" 
          color="SUCCESS"  
          *ngIf="
          (rowData.workPlan.workPlanStatus.code == 
          global.appConstant.esm
          .WORK_PLAN_STATUS_PURCHASE_REQUISITION 
          || rowData.workPlan.workPlanStatus.code == 
          global.appConstant.esm
          .WORK_PLAN_STATUS_PROCUREMENT_PROCESS 
          || rowData.workPlan.workPlanStatus.code 
          == global.appConstant.esm
          .WORK_PLAN_STATUS_PURCHASE_ORDER_PROCESS 
          ||  rowData.workPlan.workPlanStatus.code
           == global.appConstant.esm
           .WORK_PLAN_STATUS_DELIVERY_RECEIVED_PROCESS)  
           && rowData.workPlan.workflowPrcs 
           !== null" 
           (onClick)="doEdit(rowData)" 
           title="{{ 'work-plan.tooltip.workPlan-realized' 
           | translate }}" >
           <em class="far fa-sticky-note">
             </em>
             </app-button> -->
          <app-button
            size="TN"
            (onClick)="doDetail(rowData)"
            title="{{ 'app.tooltip.view' | translate }}"
            [disabled]="(websocketBudgetService.message | async) || (websocketWorkProgramService.message | async)"
          >
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
