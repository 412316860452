import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { TableResponseModel } from '../../components/table/model/table-response-model';
import { WidgetService } from '../widgets.service';
import { WidgetProcurementMonitoringOverdueModel } from './app-widget-procurement-monitoring-overdue-model';
@Component({
  templateUrl: './app-widget-procurement-monitoring-overdue.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetProcurementMonitoringOverdueComponent extends BaseComponentComponent {
  public tableResponse: TableResponseModel<WidgetProcurementMonitoringOverdueModel>;

  constructor(public widgetService: WidgetService) {
    super('app-widget-procurement-monitoring-overdue');
  }

  public onInit(): void {
    this.buildTableResponse();
    this.getAndSetData();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementNumber',
        header: 'table.column.procurementNumber'
      },
      {
        field: 'requiredDate',
        header: 'table.column.requiredDate',
        plugins: [
          {
            name: 'date',
            format: 'short-date'
          }
        ]
      },
      {
        field: 'procurementName',
        header: 'table.column.procurementName'
      },
      {
        field: 'picName',
        header: 'table.column.picName'
      },
      {
        field: 'totalDays',
        header: 'table.column.days'
      },
      {
        field: 'status',
        header: 'table.column.status',
        plugins: [
          {
            name: 'badge',
            pill: true,
            colorMap: {
              Upcoming: 'INFO',
              Overdue: 'WARNING-LIGHT'
            }
          }
        ]
      }
    ]);
  }

  public getAndSetData(): void {
    this.httpClientService
      .get<WidgetProcurementMonitoringOverdueModel[]>(
        '/widget-procurement-monitoring-overdue/index'
      )
      .subscribe((modelList: WidgetProcurementMonitoringOverdueModel[]) => {
        this.tableResponse.setRecordList(modelList);
        this.tableResponse.reload();
        this.setStateReady();
      });
  }
}
