import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { DueDiligenceTemplateEditAddComponent } from './due-diligence-template-edit-add.component';
import { DueDiligenceTemplateComponent } from './due-diligence-template.component';
export const routes = [
  {
    path: '',
    component: DueDiligenceTemplateComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'add',
    component: DueDiligenceTemplateEditAddComponent,
    data: { breadcrumb: 'due-diligence-template.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: DueDiligenceTemplateEditAddComponent,
    data: { breadcrumb: 'due-diligence-template.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    DueDiligenceTemplateComponent,
    DueDiligenceTemplateEditAddComponent
  ],
  providers: []
})
export class DueDiligenceTemplateModule {}
