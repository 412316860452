import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementSchedule } from '../../core/bean/procurement-schedule';
import { QuotationItemReference } from '../../core/bean/quotation-item-reference';
import { RefutationReview } from '../../core/bean/refutation-review';
import { RefutationReviewStatus } from '../../core/bean/refutation-review-status';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { ToastService } from '../../core/components/app-toast/app-toast.service';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';
import { RefutationBuyerByItemView } from '../../core/view/entity/bean/refutation-buyer-by-item-view';
import { RefutationParticipantMultiWinnerView } from '../../core/view/entity/bean/refutation-participant-multi-winner-view';
import { RefutationParticipantMultiWinnerViewEntity } from '../../core/view/entity/refutation-participant-multi-winner-view.entity';
import { AppPopupVendorRefutationComponent } from './app-popup-vendor-refutation.component';
import { RefutationItemReviewResponse } from './refutation-item-review-response';
import { RefutationResponse } from './refutation-response';

@Component({
  templateUrl: './refutation-item-review.component.html'
})
export class RefutationItemReviewComponent extends BaseModuleComponent {
  public todo: string;
  public isUpdated: boolean;
  public objectId: number;
  public procurementId: number;
  public procurement: Procurement;
  public urlBackOutside: string;
  public approvalModelPrcsId: number;
  public backToUrl: string;
  public today: Date = new Date();
  public refutationResponse: RefutationResponse;
  public refutationItemReviewResponse: RefutationItemReviewResponse;
  public procurementSchedule: ProcurementSchedule;
  public refutationBuyerByItemView: RefutationBuyerByItemView;
  public refutationReviewStatusOptionList: OptionListModel<RefutationReviewStatus> =
    new OptionListModel(true);
  public tableResponseVendorParticipant: TableResponseModel<RefutationParticipantMultiWinnerViewEntity>;
  public tableResponse: TableResponseModel<QuotationItemReference>;
  public isMandatory = false;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public toastService: ToastService
  ) {
    super('refutation', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.doBuildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.procurementId = this.global.routerParams.get('procurementId');
    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.isUpdated = this.global.routerParams.get('isUpdated');
    this.refutationResponse =
      this.global.routerParams.get('refutationResponse');
    this.refutationBuyerByItemView = this.global.routerParams.get(
      'refutationBuyerByItemView'
    );
    this.backToUrl = this.global.routerParams.get('backToUrl');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      procurement: [null],
      procurementItem: [null],
      refutationDate: [null, Validators.required()],
      note: [null, Validators.required()],
      refutationReviewStatus: [null, Validators.required()]
    });
  }

  public setViewOnly(): void {
    this.formGroup.setIsView(true);
    this.isViewOnly = true;
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'quotationItem.quotation.procurementVendor.vendor.name',
        header: 'table.column.vendorName'
      },
      {
        field: 'quantity',
        header: 'table.column.qty',
        className: 'text-center',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'quotationItem.finalPrice',
        header: 'table.column.finalPrice',
        className: 'text-right',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      },
      {
        field: 'totalPrice',
        header: 'table.column.totalPrice',
        className: 'text-right',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      },
      {
        field: 'quotationItem.finalProfitLossAmount',
        header: 'table.column.saveLoss',
        className: 'd-flex justify-content-end',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): void => {
              if (tablePlugin.value) {
                tablePlugin.element.innerText =
                  this.global.converterService.convertMoney(tablePlugin.value) +
                  ' %';
              }
            }
          },
          {
            name: 'badge',
            field: 'quotationItem.finalProfitLossStatus.name',
            colorMap: {
              SAVE: 'SUCCESS',
              LOSS: 'DANGER'
            },
            pill: false,
            colorField: 'quotationItem.finalProfitLossStatus.code',
            className: 'badge-catalog ml-3'
          }
        ]
      }
    ]);

    this.tableResponseVendorParticipant = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'vendorName',
          header: 'table.column.vendorName',
          plugins: {
            name: 'hyperlink',
            onClick: this.doShowVendorRefutation.bind(this)
          }
        },
        {
          field: 'submittedStatusName',
          header: 'table.column.refutationStatus',
          plugins: {
            name: 'badge',
            colorField: 'submittedStatusCode',
            colorMap: {
              AVAILABLE: 'SUCCESS',
              UNAVAILABLE: 'SECONDARY',
              WAITING_RESPONSE: 'GOOD'
            }
          }
        }
      ]
    );
    this.tableResponseVendorParticipant.setRecordList(
      this.refutationItemReviewResponse.refutationParticipantMultiWinnerList
    );
    this.tableResponse.setRecordList(
      this.refutationItemReviewResponse.quotationItemReferenceList
    );
    this.tableResponse.reload();
    this.tableResponseVendorParticipant.reload();
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<RefutationItemReviewResponse>(
        '/refutation/get-item-review/' +
          this.refutationBuyerByItemView.procurementItem.id
      )
      .subscribe((response: RefutationItemReviewResponse) => {
        this.refutationItemReviewResponse = response;
        this.procurementSchedule = response.procurementSchedule;
        this.buildTableResponse();
        this.refutationReviewStatusOptionList.setRequestValues(
          this.refutationResponse.refutationReviewStatusList
        );
        this.procurement = this.refutationResponse.procurement;
        if (this.refutationBuyerByItemView.refutationReview) {
          const {
            id,
            procurement,
            procurementItem,
            refutationDate,
            note,
            refutationReviewStatus
          } = this.refutationBuyerByItemView.refutationReview;
          this.formGroup.patchValue({
            id,
            procurement,
            procurementItem,
            refutationDate,
            note,
            refutationReviewStatus
          });
        } else {
          if (
            this.procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_WORKLIST_STATUS_CLOSED_RESPONSE
          ) {
            if (
              !this.refutationItemReviewResponse.refutationParticipantMultiWinnerList.find(
                vendorParticipant =>
                  vendorParticipant.submittedStatusCode ===
                  this.global.appConstant.pm.SUBMITTED_STATUS_AVAILABLE
              )
            ) {
              this.formGroup.patchValue({
                refutationDate: this.today,
                refutationReviewStatus:
                  this.refutationResponse.refutationReviewStatusList.find(
                    status =>
                      status.code ===
                      this.global.appConstant.pm
                        .REFUTATION_REVIEW_STATUS_NOT_ACCEPTED
                  )
              });
            }
          }
        }
        this.setStateReady();
        if (this.todo === 'view') {
          this.setViewOnly();
        }
      });
  }

  public doShowVendorRefutation(
    refutationParticipantMultiWinnerView: RefutationParticipantMultiWinnerView
  ): void {
    if (
      refutationParticipantMultiWinnerView.submittedStatusCode ===
      this.global.appConstant.pm.SUBMITTED_STATUS_AVAILABLE
    ) {
      this.appPopupService.open(AppPopupVendorRefutationComponent, {
        refutationVendorId:
          refutationParticipantMultiWinnerView.refutationVendorId,
        vendor: refutationParticipantMultiWinnerView.vendorName
      });
    }
  }

  public doCancel(): void {
    this.global.routerParams.set('todo', this.todo);
    this.global.routerParams.set('procurementId', this.procurementId);
    this.global.routerParams.set('objectId', this.objectId);
    this.global.routerParams.set('urlBackOutside', this.urlBackOutside);
    this.global.routerParams.set(
      'approvalModelPrcsId',
      this.approvalModelPrcsId
    );
    this.global.routerParams.set('refutationResponse', this.refutationResponse);
    if (this.todo === 'view') {
      this.router.navigate(['/pages/refutation/detail']);
    } else {
      this.router.navigate(['/pages/refutation/edit']);
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      if (
        (!this.refutationBuyerByItemView.refutationReview ||
          (this.refutationBuyerByItemView.refutationReview &&
            this.refutationBuyerByItemView.refutationReview
              .refutationReviewStatus.code !==
              this.formGroup.value.refutationReviewStatus.code)) &&
        !this.isUpdated
      ) {
        this.isUpdated = true;
      }

      const refutationReview: RefutationReview =
        this.refutationBuyerByItemView.refutationReview ||
        new RefutationReview();
      refutationReview.procurement = this.procurement;
      refutationReview.procurementItem =
        this.refutationBuyerByItemView.procurementItem;
      refutationReview.note = this.formGroup.value.note;
      refutationReview.refutationDate = this.formGroup.value.refutationDate;
      refutationReview.refutationReviewStatus =
        this.formGroup.value.refutationReviewStatus;
      const index =
        this.refutationResponse.refutationBuyerByItemViewList.findIndex(
          refutationBuyerByItemViewTable =>
            refutationBuyerByItemViewTable.procurementItem.id ===
            this.refutationBuyerByItemView.procurementItem.id
        );
      this.refutationResponse.refutationBuyerByItemViewList[
        index
      ].refutationReview = refutationReview;

      this.global.routerParams.set('todo', this.todo);
      this.global.routerParams.set('procurementId', this.procurementId);
      this.global.routerParams.set('objectId', this.objectId);
      this.global.routerParams.set('urlBackOutside', this.urlBackOutside);
      this.global.routerParams.set(
        'approvalModelPrcsId',
        this.approvalModelPrcsId
      );
      this.global.routerParams.set('isUpdated', this.isUpdated);
      this.global.routerParams.set(
        'refutationResponse',
        this.refutationResponse
      );
      this.formGroup.reset();
      this.global.modalService.confirmation(
        'refutation.saveConfirmation.msg.yourWorkHasBeenSaved',
        'refutation.saveConfirmation.title.dataHasBeenSaved',
        null,
        null,
        'pvc pv-check-circle',
        null,
        { autoCloseInMs: 1500 }
      );

      if (this.todo === 'view') {
        this.router.navigate(['/pages/refutation/detail']);
      } else {
        this.router.navigate(['/pages/refutation/edit']);
      }
    } else {
      this.isMandatory = true;
    }
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }
}
