import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { File } from '../../core/bean/file';
import { Role } from '../../core/bean/role';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { UserManual } from './../../core/bean/user-manual';
import { UserManualRequest } from './user-manual-request';
@Component({
  templateUrl: './user-manual-edit-add.component.html'
})
export class UserManualEditAddComponent extends BaseModuleComponent {
  public role: Role = new Role();
  public fileUploaderList: FileUploader[] = new Array();
  public userManualRequest: UserManualRequest;
  constructor(translateService: TranslateService) {
    super('user-manual', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.role = this.global.routerParams.get('role');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      userManualList: this.formBuilder.array([])
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<UserManual[]>('/user-manual/edit', this.role)
      .subscribe(userManualList => {
        userManualList.forEach((userManual: UserManual) => {
          const { id, lang, role } = userManual;
          const formGroup = this.formBuilder.group({
            id: [id],
            lang: [lang],
            role: [role],
            file: [this.addUserManualFileToFileUploaderlist(userManual.file).fileObjectList, Validators.required()]
          });
          (this.formGroup.get('userManualList') as FormArray).push(formGroup);
        });
        this.setStateReady();
      });
  }

  public addUserManualFileToFileUploaderlist(file: File): FileUploader {
    const fileUploader: FileUploader = new FileUploader(
      '/user-manual/',
      ' ',
      this.global.appConstant.fileType.DOC_USER_MANUAL,
      true,
      1
    );
    fileUploader.setFile(file);
    fileUploader.titleRequired = false;
    this.fileUploaderList.push(fileUploader);
    return fileUploader;
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const userManualRequestList: Array<UserManualRequest> = new Array();
            this.formGroup.value.userManualList.forEach(
              (userManual: UserManual, index: number) => {
                userManual.file = null;
                this.userManualRequest = new UserManualRequest();
                this.userManualRequest.userManual = userManual;
                this.userManualRequest.fileObjectList = this.fileUploaderList[
                  index
                ].fileObjectList;
                userManualRequestList.push(this.userManualRequest);
              }
            );
            this.httpClientService
              .post<Response<UserManual>>(
                '/user-manual/update',
                userManualRequestList
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/user-manual']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/user-manual']);
  }

  public get formArrayUserManualList(): FormArray {
    return this.formGroup.get('userManualList') as FormArray;
  }
}
