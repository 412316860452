import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppAlertModule } from '../app-alert.module';
import { AppAlertTopComponent } from './app-alert-top.component';
@NgModule({
  imports: [VendorModule, AppAlertModule, AppButtonModule],
  declarations: [AppAlertTopComponent],
  entryComponents: [AppAlertTopComponent],
  exports: [AppAlertTopComponent]
})
export class AppAlertTopModule {}
