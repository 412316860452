import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupProcurementComponent } from './app-popup-procurement.component';
@Injectable()
export class AppPopupProcurementService {
  constructor(public appPopupService: AppPopupService) {}
  public open(procurementIdList?: number[]): Observable<any> {
    return this.appPopupService.open(AppPopupProcurementComponent, {
      procurementIdList
    });
  }
}
