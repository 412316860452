import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppDropDownModule } from '../../../../core/components/app-drop-down/app-drop-down.module';
import { PipesModule } from '../../../../core/pipe/pipe.module';
import { VendorModule } from '../../../../core/vendor/vendor.module';
import { NotificationComponent } from './notification.component';

@NgModule({
  imports: [
    VendorModule,
    AppDropDownModule,
    PipesModule,
    PerfectScrollbarModule
  ],
  declarations: [NotificationComponent],
  exports: [NotificationComponent]
})
export class NotificationModule {}
