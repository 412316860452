import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Coa } from '../../core/bean/coa';
import {
  TreeEvent,
  TreeItemModel,
  TreeModel,
  TreeProcessItemModel,
  TreeProcessModel
} from '../../core/components/app-tree';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './coa.component.html'
})
export class CoaComponent extends BaseModuleComponent {
  public isMultiYearsExist = false;
  public treeModel: TreeModel = new TreeModel(this.moduleCode, 'WRITE', null);
  constructor(translateService: TranslateService) {
    super('coa', translateService);
  }

  public onInit(): void {
    this.doBuildTreeFormGroup();
    this.setStateReady();
  }

  public doBuildTreeFormGroup(): void {
    this.treeModel.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      description: [null, Validators.maxLength(512)],
      isMultiYears: [false],
      parentId: [null],
      tempId: [null],
      hasChild: [null]
    });
  }

  public doOnChangeIsMultiYears(isMultiYears: boolean): void {
    if (isMultiYears) {
      this.global.modalService
        .confirm(
          this.translateService.instant('coa.confirmation.body'),
          this.translateService.instant('coa.confirmation.header')
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const indexOfIsMultiYears = this.treeModel.treeProcess.treeProcessItemList.findIndex(
              (treeProcessItem: TreeProcessItemModel) =>
                treeProcessItem.data.isMultiYears
            );
            if (indexOfIsMultiYears === -1) {
              this.httpClientService
                .post<Coa>('/coa/isMultiYears', '')
                .subscribe((coa: Coa) => {
                  if (coa !== null) {
                    if (indexOfIsMultiYears === -1) {
                      coa.isMultiYears = false;
                      const angularTree: TreeItemModel = new TreeItemModel(
                        coa as any
                      );
                      const indexOfAngularTree = this.treeModel.treeProcess.treeProcessItemList.findIndex(
                        (treeProcessItem: TreeProcessItemModel) =>
                          treeProcessItem.data.id === angularTree.id
                      );
                      if (indexOfAngularTree === -1) {
                        const treeProcessItem: TreeProcessItemModel = new TreeProcessItemModel(
                          angularTree,
                          'EDIT'
                        );
                        this.treeModel.treeProcess.addItemToTreeProcessItem(
                          treeProcessItem
                        );
                      }
                    } else {
                      this.treeModel.treeProcess.treeProcessItemList[
                        indexOfIsMultiYears
                      ].data.isMultiYears = false;
                    }
                  }
                });
            } else {
              this.treeModel.treeProcess.treeProcessItemList[
                indexOfIsMultiYears
              ].data.isMultiYears = false;
            }
          }
        });
    }
  }

  public doOnEventChange(treeEvent: TreeEvent): void {
    if (treeEvent.type === 'ON-SHOW-POPUP') {
      const id = this.formGroup.value.id;
      treeEvent.formGroup
        .get('code')
        .setAsyncValidators(
          Validators.existsAsync('/coa/isAlreadyExist', null, null, { id })
        );
    } else if (treeEvent.type === 'ON-CLOSE-POPUP') {
      treeEvent.formGroup.get('code').clearAsyncValidators();
    }
  }

  public doSave(): void {
    if (this.treeModel.treeProcess.treeProcessItemList.length > 0) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.httpClientService
              .post<Response<TreeProcessModel>>(
                '/coa/tree/update',
                this.treeModel.treeProcess.values
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSaving();
                  this.treeModel.reset();
                  this.onInit();
                } else {
                  this.treeModel.treeProcess.treeProcessItemList.splice(0);
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.treeModel.reset();
  }
}
