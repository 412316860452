import { BaseEntity } from '../base/base-entity';
import { Segmentation } from '../bean/segmentation';
import { VendorExperienceHistory } from '../bean/vendor-experience-history';

export class VendorExperienceSegmentationHistoryEntity extends BaseEntity {
    crudInfo: string;

    vendorExperienceHistory: VendorExperienceHistory;
    segmentation: Segmentation;
    createdDate: Date;
    vendorExperienceSegmentationId: number;
}
