<app-popup
  [header]="
    'tab-profile-company-organization.form.popup.header.organization.' + todo
  "
  [isLoading]="formLoading"
>
  <form [formGroup]="formGroup">
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-organization.form.popup.name' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          [autofocus]="true"
          size="LG"
          formControlName="name"
          maxLength="64"
          type="alphanumeric"
          include="-.\',"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              type === 3 &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorCompanyOrganizationHistoryEdit.vendorHistory !== null
            "
            [oldValue]="companyOrganizationShareHolder.name"
            [newValue]="vendorCompanyOrganizationHistoryEdit.name"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-organization.form.popup.idNumber' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="idNumber"
          maxLength="32"
          tooltip="{{
            'tab-profile-company-organization.tooltip.id' | translate
          }}"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              type === 3 &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorCompanyOrganizationHistoryEdit.vendorHistory !== null
            "
            [oldValue]="companyOrganizationShareHolder.idNumber"
            [newValue]="vendorCompanyOrganizationHistoryEdit.idNumber"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      *ngIf="type !== 3"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-organization.form.popup.uploadIdentity' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-upload
          size="LG"
          formControlName="imgFile"
          [fileUploader]="fileUploader"
        ></app-upload>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
      *ngIf="type !== 3"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-organization.form.popup.position' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="LG"
          formControlName="vendorPosition"
          [optionList]="vendorPositionOptionList"
        ></app-combo-box>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
      *ngIf="type === 3"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-organization.form.popup.type' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="LG"
          formControlName="shareholderType"
          [optionList]="shareHolderTypeOptionList"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              type === 3 &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorCompanyOrganizationHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              companyOrganizationShareHolder.shareholderType
                ? companyOrganizationShareHolder.shareholderType.name
                : null
            "
            [newValue]="
              vendorCompanyOrganizationHistoryEdit.shareholderType
                ? vendorCompanyOrganizationHistoryEdit.shareholderType.name
                : null
            "
          ></app-flag-new>
        </app-combo-box>
      </div>
    </div>

    <div class="form-group row text-sm-right" *ngIf="type !== 3">
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-organization.form.popup.DOB' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-date-picker
          size="MD"
          formControlName="birthDate"
          maxDate="currentDate"
        ></app-date-picker>
      </div>
    </div>

    <div class="form-group row text-sm-right" *ngIf="type === 3">
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-organization.form.popup.DOBorED' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-date-picker
          size="MD"
          formControlName="birthDate"
          maxDate="currentDate"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              type === 3 &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorCompanyOrganizationHistoryEdit.vendorHistory !== null
            "
            [oldValue]="birthDateActual"
            [newValue]="birthDateHistory"
          ></app-flag-new>
        </app-date-picker>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
      *ngIf="type === 3"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-organization.form.popup.shareValue' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="SM"
          type="decimal"
          formControlName="shareValue"
          symbolic="%"
          tooltip="{{
            'tab-profile-company-organization.tooltip.shareValue' | translate
          }}"
        ></app-text-field>
        <label *ngIf="formGroup.isView">&nbsp;%</label>
        <app-flag-new
          *ngIf="
            global.userSession.activeUserRole.role.type !==
              global.appConstant.core.ROLE_TYPE_VENDOR &&
            formGroup.isView &&
            !isVendorList &&
            type === 3 &&
            (fromOutside === '/pages/on-boarding' ||
              fromOutside === '/pages/approval-prcs') &&
            vendorCompanyOrganizationHistoryEdit.vendorHistory !== null
          "
          [oldValue]="companyOrganizationShareHolder.shareValueWithPercent"
          [newValue]="
            vendorCompanyOrganizationHistoryEdit.shareValueWithPercent
          "
          [ngStyle]="{ 'padding-left': '3px' }"
        ></app-flag-new>
        <small
          class="text-danger"
          *ngIf="
            isExceedMaxTotalShareValue && formGroup.get('shareValue').pristine
          "
        >
          {{
            'tab-profile-company-organization.form.popup.msg.maxTotalShareValue'
              | translate
          }}
        </small>
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <div class="button-group button-group-center">
          <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
            'app.button.close' | translate
          }}</app-button>
          <app-button *ngIf="!formGroup.isView" (onClick)="doSave()">{{
            'app.button.save' | translate
          }}</app-button>
        </div>
      </div>
    </ng-template>
  </form>
</app-popup>
