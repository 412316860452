import { TextUtils } from 'src/app/core/utils';
import { File as FileBean } from '../../../bean/file';
import { Uploader } from '../model/uploader';
import { UploaderFile } from '../model/uploader-file';
import { createUploaderFileObject } from './create-uploader-file-object.action';
import { validateUploaderFile } from './validate-uploader-file.action';
export function createUploaderFile(
  uploader: Uploader,
  fileOrigin: File
): UploaderFile {
  const uploaderFile: UploaderFile = new UploaderFile();
  const file: FileBean = createUploaderFileObject(fileOrigin);
  uploaderFile.id = TextUtils.generateRandomString();
  uploaderFile.parent = uploader;
  uploaderFile.file = file;
  uploaderFile.origin = fileOrigin;
  uploaderFile.setStatus('INQUEE');
  uploaderFile.isNew = true;
  return validateUploaderFile(uploaderFile);
}
