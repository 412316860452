import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Adjustment } from '../../core/bean/adjustment';
import { ApprovalModelPrcs } from '../../core/bean/approval-model-prcs';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { SlaCoreAdjustmentResponse } from './sla-core-adjustment-response';
@Component({
  templateUrl: './app-popup-adjustment-core.component.html'
})
export class AppPopupAdjustmentCoreComponent extends BaseModuleComponent {
  @Input() adjustmentPopup: Adjustment;
  @Input() public type: string;
  @Input() public historyType: string;

  public adjustment: Adjustment;
  public object: string;
  public nameAdjustment: string;
  public labelNameAdjustment: string;
  public stage: string;
  public totalRealizationBefore: string;
  public id: number;
  public maxDay: number;
  public maxHours: number;
  public maxMinutes: number;
  public isAdjustValidation: boolean;
  public todo: string;
  public approvalModelPrcsOptionList: OptionListModel<ApprovalModelPrcs> =
    new OptionListModel(true, 'approvedByUser.name');

  constructor(
    translateService: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    super('sla-core', translateService);
  }

  public onInit(): void {
    this.isAdjustValidation = false;
    this.setMaxValueOfTime();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setMaxValueOfTime(): void {
    if (this.historyType === 'procurement') {
      this.labelNameAdjustment = this.translateService.instant(
        'sla-core.form.popup.procurement.name'
      );
    }
    if (this.historyType === 'purchase request') {
      this.labelNameAdjustment = this.translateService.instant(
        'sla-core.form.popup.purchase-request.name'
      );
    }
    if (this.historyType === 'rfp') {
      this.labelNameAdjustment = this.translateService.instant(
        'sla-core.form.popup.rfp.name'
      );
    }
    if (this.historyType === 'rfq') {
      this.labelNameAdjustment = this.translateService.instant(
        'sla-core.form.popup.rfq.name'
      );
    }
    if (this.historyType === 'order process') {
      this.labelNameAdjustment = this.translateService.instant(
        'sla-core.form.popup.order-process.name'
      );
    }

    this.maxDay = this.adjustmentPopup.beforeAdjustmentWorkingInDays;
    if (this.maxDay === null || this.maxDay === 0) {
      this.maxHours = this.adjustmentPopup.beforeAdjustmentWorkingInHours;
      if (this.maxHours === null || this.maxHours === 0) {
        this.maxHours = 23; // temporary
        this.maxMinutes =
          this.adjustmentPopup.beforeAdjustmentWorkingInMinutes = 0
            ? 59
            : this.adjustmentPopup.beforeAdjustmentWorkingInMinutes;
        this.maxMinutes = this.maxMinutes === null ? 59 : this.maxMinutes;
      } else {
        this.maxMinutes = 59;
      }
    } else {
      this.maxHours = 23;
      this.maxMinutes = 59;
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      workflowName: [null],
      stage: [null],
      totalRealization: [null],
      days: [
        null,
        Validators.compose([
          Validators.maxLength(11),
          Validators.max(this.maxDay),
          Validators.min(0)
        ])
      ],
      maxHour: this.maxHours,
      hours: [
        null,
        Validators.compose([
          Validators.maxLength(11),
          Validators.max('maxHour')
        ])
      ],
      maxMinute: this.maxMinutes,
      minutes: [
        null,
        Validators.compose([
          Validators.maxLength(11),
          Validators.max('maxMinute'),
          Validators.min(0)
        ])
      ],
      description: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(512)])
      ],
      approvalModelPrcs: [null]
    });
  }

  public setFormGroup(): void {
    this.object = this.adjustmentPopup.workflowModelPrcs.object;
    this.nameAdjustment = this.adjustmentPopup.workflowModelPrcs.nameAdjustment;
    if (this.type === 'workflow') {
      this.totalRealizationBefore =
        this.adjustmentPopup.workflowModelPrcs.slaRealizedName;
      this.stage = this.adjustmentPopup.workflowModelPrcs.name;
      this.id = this.adjustmentPopup.workflowModelPrcs.id;
    } else {
      this.totalRealizationBefore =
        this.adjustmentPopup.approvalModelPrcs.slaRealizedName;
      this.stage =
        this.adjustmentPopup.approvalModelPrcs.approvalPrcs.workflowModelPrcs.name;
      this.id = this.adjustmentPopup.approvalModelPrcs.id;
    }

    if (
      this.adjustmentPopup.workflowModelPrcs.module.code === 'APPROVAL-PRCS'
    ) {
      this.formGroup
        .get('approvalModelPrcs')
        .setValidators([Validators.required()]);
      this.formGroup.get('approvalModelPrcs').updateValueAndValidity();
    }

    this.httpClientService
      .post<Adjustment>(
        '/sla-core/add-edit-adjustment',
        new RouteRequestModel(this.type, this.id)
      )
      .subscribe((response: SlaCoreAdjustmentResponse) => {
        this.adjustment = response.adjustment;
        if (response.adjustment == null) {
          this.todo = 'add';
          this.adjustmentPopup.id = null;
        } else {
          this.todo = 'edit';
          this.adjustmentPopup = this.adjustment;
        }
        this.approvalModelPrcsOptionList.setRequestValues(
          response.approvalModelPrcsList
        );
        this.setStateReady();
      });
  }

  public doClickIsValidationTime(): void {
    if (this.isAdjustValidation) {
      this.doClearValidationTime();
    }
  }

  public doOnChange(): void {
    this.doClearValidationTime();
    const mD = this.getDays().value;
    const mH = this.getHours().value;
    if (
      ((mD == null || mD === '' || mD === 0) &&
        (this.adjustmentPopup.beforeAdjustmentWorkingInDays === 0 ||
          this.adjustmentPopup.beforeAdjustmentWorkingInDays === null)) ||
      mD === this.adjustmentPopup.beforeAdjustmentWorkingInDays
    ) {
      if (this.adjustmentPopup.beforeAdjustmentWorkingInHours < 24) {
        this.maxHours = this.adjustmentPopup.beforeAdjustmentWorkingInHours = 0
          ? 23
          : this.adjustmentPopup.beforeAdjustmentWorkingInHours;
        this.formGroup
          .get('maxHour')
          .patchValue(this.maxHours == null ? 23 : this.maxHours); // temporary
      }
      if (
        (((mH == null || mH === '' || mH === 0) &&
          (this.adjustmentPopup.beforeAdjustmentWorkingInHours === 0 ||
            this.adjustmentPopup.beforeAdjustmentWorkingInHours == null)) ||
          mH === this.adjustmentPopup.beforeAdjustmentWorkingInHours) &&
        this.adjustmentPopup.beforeAdjustmentWorkingInMinutes < 60
      ) {
        this.maxMinutes =
          this.adjustmentPopup.beforeAdjustmentWorkingInMinutes = 0
            ? 59
            : this.adjustmentPopup.beforeAdjustmentWorkingInMinutes;
        this.formGroup
          .get('maxMinute')
          .patchValue(this.maxMinutes == null ? 59 : this.maxMinutes); // temporary
      }
    } else {
      this.maxDay = this.adjustmentPopup.beforeAdjustmentWorkingInDays;
      this.formGroup.get('maxHour').patchValue(23);
      this.formGroup.get('maxMinute').patchValue(59);
    }
  }

  public doClearValidationTime(): void {
    if (
      !(
        (this.getDays().value === null || this.getDays().value === '') &&
        (this.getHours().value === null || this.getHours().value === '') &&
        (this.getMinutes().value === null || this.getMinutes().value === '')
      )
    ) {
      this.isAdjustValidation = false;
      this.getDays().clearValidators();
      this.getHours().clearValidators();
      this.getMinutes().clearValidators();

      this.getDays().setValidators(
        Validators.compose([
          Validators.maxLength(11),
          Validators.max(this.maxDay),
          Validators.min(0)
        ])
      );
      this.getHours().setValidators(
        Validators.compose([
          Validators.maxLength(11),
          Validators.max('maxHour'),
          Validators.min(0)
        ])
      );
      this.getMinutes().setValidators(
        Validators.compose([
          Validators.maxLength(11),
          Validators.max('maxMinute'),
          Validators.min(0)
        ])
      );

      this.doUpdateValidationTime();
    }
  }

  public doUpdateValidationTime(): void {
    this.getDays().updateValueAndValidity();
    this.getHours().updateValueAndValidity();
    this.getMinutes().updateValueAndValidity();
  }

  public doSave(): void {
    if (
      (this.getDays().value === null || this.getDays().value === '') &&
      (this.getHours().value === null || this.getHours().value === '') &&
      (this.getMinutes().value === null || this.getMinutes().value === '')
    ) {
      this.isAdjustValidation = true;
      this.getDays().setValidators(Validators.required(' '));
      this.getHours().setValidators(Validators.required(' '));
      this.getMinutes().setValidators(Validators.required(' '));
      this.doUpdateValidationTime();
    } else {
      this.doClearValidationTime();
    }
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.adjustmentPopup.adjustmentInDays =
              this.getDays().value === null || this.getDays().value === ''
                ? 0
                : this.getDays().value;
            this.adjustmentPopup.adjustmentInHours =
              this.getHours().value === null || this.getHours().value === ''
                ? 0
                : this.getHours().value;
            this.adjustmentPopup.adjustmentInMinutes =
              this.getMinutes().value === null || this.getMinutes().value === ''
                ? 0
                : this.getMinutes().value;

            const description: string = this.formGroup.get('description').value;
            this.adjustmentPopup.description = description;
            this.adjustmentPopup.createdDate = new Date();
            if (
              this.adjustmentPopup.workflowModelPrcs.module.code ===
              'APPROVAL-PRCS'
            ) {
              this.adjustmentPopup.approvalModelPrcs =
                this.formGroup.get('approvalModelPrcs').value;
            }

            const url = '/sla-core/insert-adjustment';
            this.httpClientService
              .post<Response<Adjustment>>(url, this.adjustmentPopup)
              .subscribe((resp: Response<Adjustment>) => {
                if (resp.status === ResponseStatusModel.OK) {
                  this.activeModal.dismiss();
                  this.global.alertService.showSuccessSaving();
                } else {
                  this.global.alertService.showError(
                    resp.statusText,
                    '.main-row-popup'
                  );
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public getDays(): AbstractControl {
    return this.formGroup.get('days');
  }

  public getHours(): AbstractControl {
    return this.formGroup.get('hours');
  }

  public getMinutes(): AbstractControl {
    return this.formGroup.get('minutes');
  }
}
