import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../core/shared/shared.module';
import { AppCatalogItemCarouselModule } from '../components/app-catalog-item-carousel/app-catalog-item-carousel.module';
import { AppCatalogItemCategoriesModule } from '../components/app-catalog-item-categories/app-catalog-item-categories.module';
import { AppCatalogItemModule } from '../components/app-catalog-item/app-catalog-item.module';
import { AppCatalogSimilarProductModule } from '../components/app-catalog-similar-product/app-catalog-similar-product.module';
import { AppCatalogTabModule } from '../components/app-catalog-tab/app-catalog-tab.module';
import { AppTableCatalogModule } from '../components/app-table-catalog/app-table-catalog.module';
@NgModule({
  imports: [
    SharedModule,
    AppTableCatalogModule,
    AppCatalogItemCarouselModule,
    AppCatalogItemCategoriesModule,
    AppCatalogItemModule,
    AppCatalogSimilarProductModule,
    AppCatalogTabModule
  ],
  exports: [
    SharedModule,
    AppTableCatalogModule,
    AppCatalogItemCarouselModule,
    AppCatalogItemCategoriesModule,
    AppCatalogItemModule,
    AppCatalogSimilarProductModule,
    AppCatalogTabModule
  ]
})
export class SharedViewCatalogModule {}
