import { RoleDashboardWidget } from "../../bean/role-dashboard-widget";
import { UserDashboardWidgetEntity } from "../../entity/user-dashboard-widget.entity";
export class WidgetItemModel extends UserDashboardWidgetEntity {
  public value: UserDashboardWidgetEntity;
  public static create(userDashboardWidget: UserDashboardWidgetEntity): WidgetItemModel {
    const widgetItem: WidgetItemModel = new WidgetItemModel();
    Object.assign(widgetItem, userDashboardWidget);
    widgetItem.value = userDashboardWidget;
    return widgetItem;
  }

  public setSequence(sequence: number): void {
    this.sequence = sequence;
  }

  public setGroup(group: string): void {
    this.group = group.toUpperCase();
  }

  public setRoleDashboardWidget(roleDashboardWidget: RoleDashboardWidget): void {
    this.widget = roleDashboardWidget.widget;
  }
}
