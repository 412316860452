<div class="row main-row">
    <div class="col-12">
        <app-card header="cost-center.title" detail="app.action.{{ todo }}"
            [isLoading]="formLoading" [isSaving]="formSaving">
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>

                <div class="form-group row text-sm-right"
                [ngClass]="(!formGroup.controls.code.isView) ? 'required' : ''">
                    <label class="col-sm-3 control-label">{{
                        'cost-center.form.code' | translate }}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-field autofocus="true" formControlName="code"
                            type="code" maxLength="32"> </app-text-field>
                    </div>
                </div>

                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        'cost-center.form.name' | translate }}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-field formControlName="name" maxLength="100">
                        </app-text-field>
                    </div>
                </div>

                <div class="form-group row text-sm-right">
                    <label class="col-sm-3 control-label">{{
                        'cost-center.form.description' | translate}}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-area formControlName="description"
                            maxLength="512"></app-text-area>
                    </div>
                </div>
                <div class="button-group button-group-center mt-5">
                    <app-button type="BTN_SECONDARY" (onClick)="doCancel()"
                        color="SECONDARY" [disabled]='formSaving'>
                        {{ 'app.button.back' | translate }}
                    </app-button>
                    <app-button type="BTN_PRIMARY" (onClick)="doSave()"
                        [disabled]='formSaving'>
                        {{ 'app.button.save' | translate }}
                    </app-button>
                </div>
            </form>
        </app-card>
    </div>
</div>