import { BaseEntity } from '../base/base-entity';
import { Order } from '../bean/order';
import { OrderStatus } from '../bean/order-status';
import { User } from '../bean/user';

export class PrPoVerificationEntity extends BaseEntity {
  order: Order = new Order();
  orderStatus: OrderStatus = new OrderStatus();
  processedDate: Date;
  processedByUser: User;
  note: string;
}
