import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Vendor } from '../../core/bean/vendor';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';

@Component({
  templateUrl: './direct-purchase-popup-select-vendor.component.html'
})
export class DirectPurchasePopupSelectVendorComponent extends BaseComponentComponent {
  @Input() vendor: Vendor;
  @Input() todo: string;
  @Output() public onChange: EventEmitter<Vendor> = new EventEmitter();

  public vendorPurchaseTypeOptionList: OptionListModel<any> =
    new OptionListModel(false);
  public tableResponse: TableResponseModel<Vendor>;

  constructor(
    public translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('initiation');
  }

  onInit(): void {
    this.buildTableResponse();
    this.buildFormGroup();
    this.setOptionList();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.vendorName'
      },
      {
        field: 'isManual',
        header: 'table.column.vendorType',
        dataMap: '{"true": "One Time Vendor", "false": "Promise Vendor"}'
      },
      {
        field: 'address',
        header: 'table.column.address'
      }
    ]);

    if (this.vendor) {
      this.tableResponse.setSelectedRowList([this.vendor]);
      this.tableResponse.reload();
    }
  }
  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorPurchaseType: [null]
    });
  }

  public setOptionList(): void {
    const vendorPurchaseTypeList = [
      {
        name: 'Promise Vendor',
        value: 1
      },
      {
        name: 'One Time Vendor',
        value: 2
      }
    ];
    this.vendorPurchaseTypeOptionList.setRequestValues(vendorPurchaseTypeList);
  }

  public doAddNewVendor(): void {
    this.onChange.emit(null);
  }

  public doChoose(): void {
    const selectedVendor =
      this.tableResponse.selectedRow.getSelectedRecordList();
    if (selectedVendor.length > 0) {
      this.onChange.emit(selectedVendor[0]);
    } else {
      this.global.alertService.showError(
        'app.validation.chooseOneData',
        '.main-row-popup-choose-vendor'
      );
    }
  }

  public onChangeVendorPurchase(event): void {
    this.log.debug(event);
    this.tableResponse.setCustomData(event.value);
    this.tableResponse.reload();
  }
}
