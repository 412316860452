<app-popup header="manage-catalog.toc.title">
  <form [formGroup]="formGroup">
    <div class="row main-row">
      <div class="col-12">
        <div class="app-popup-manage-catalog-toc" [innerHTML]="termsAndCondition"></div>
      </div>
    </div>
  </form>
  <div class="button-group button-group-center mt-5">
    <button
      type="button"
      class="btn btn-primary"
      (click)="activeModal.close(true)"
      [disabled]="formSaving"
    >
      {{ 'app.button.ok' | translate }}
    </button>
  </div>
</app-popup>
