import { Pipe, PipeTransform } from '@angular/core';
import { UploaderFile } from '../model';

@Pipe({ name: 'resolveUploadProgressValue' })
export class ResolveUploadProgressValue implements PipeTransform {
  transform(
    uploaderFileList: UploaderFile[],
    totalNewUploaded: number,
    progress: number
  ): Promise<string> {
    return new Promise(resolve => {
      const newUploaderFileList = Array.from(uploaderFileList).splice(
        -totalNewUploaded
      );
      newUploaderFileList.forEach((uploaderFile: UploaderFile) => {
        uploaderFile.uploaderProgressChanges.subscribe(uploadProgress => {
          progress += uploadProgress / newUploaderFileList.length;
          if (progress === 100) {
            setTimeout(() => {
              progress = 0;
            }, 500);
          }
          resolve(progress + '%');
        });
      });
    });
  }
}
