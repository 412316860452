import { Action } from '../interfaces/action.interface';

export const SET_BUDGET_INFO = `[ ACTUAL PLAN BAR CHART ]: Set Budget Info`;
export const SET_WORK_PLAN_INFO = `[ ACTUAL PLAN BAR CHART ]: Set Work Plan Info`;

export class SetBudgetInfo implements Action {
  type = SET_BUDGET_INFO;
  constructor(public payload: any) {}
}

export class SetWorkPlanInfo implements Action {
  type = SET_WORK_PLAN_INFO;
  constructor(public payload: any) {}
}

export type All = SetBudgetInfo | SetWorkPlanInfo;
