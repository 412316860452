import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { BillingMethod } from 'src/app/core/bean/billing-method';
import { BillingTerm } from 'src/app/core/bean/billing-term';
import { Currency } from 'src/app/core/bean/currency';
import { PaymentTerm } from 'src/app/core/bean/payment-term';
import { PrItemReleased } from 'src/app/core/bean/pr-item-released';
import { Sow } from 'src/app/core/bean/sow';
import { SowPaymentTerm } from 'src/app/core/bean/sow-payment-term';
import { SowPaymentTermItem } from 'src/app/core/bean/sow-payment-term-item';
import { SowPaymentType } from 'src/app/core/bean/sow-payment-type';
import { StageOfWork } from 'src/app/core/bean/stage-of-work';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { TableColumn } from '../../table/domain/table-column';
import { TableRow } from '../../table/domain/table-row';
import { CountPlugin } from '../../table/interface/available-table-plugin';
import { TableResponseModel } from '../../table/model/table-response-model';
import { AppPopupSowModel } from './app-popup-sow.model';
import { AppPopupSowResponse } from './app-popup-sow.response';

@Component({
  templateUrl: './app-popup-sow.component.html',
  styleUrls: ['./app-popup-sow.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupSowComponent extends BaseComponentComponent {
  @Input() public todo: string;
  @Input() public availableAmountSow: number;
  @Input() public amount: number;
  @Input() public prItemReleasedList: PrItemReleased[];
  @Input() public sowPaymentTermLists: SowPaymentTerm[] = [];
  @Input() public sowList: Sow[] = [];
  @Input() public sowEdit: Sow = new Sow();
  @Input() public isBlanketOrder: boolean;
  @Input() public isPo: boolean;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public stageOfWorkOptionList: OptionListModel<StageOfWork> =
    new OptionListModel();
  public billingTermOptionList: OptionListModel<BillingTerm> =
    new OptionListModel();
  public paymentTermOptionList: OptionListModel<PaymentTerm> =
    new OptionListModel();
  public sowPaymentTypeOptionList: OptionListModel<SowPaymentType> =
    new OptionListModel();
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );
  public billingMethodOptionList: OptionListModel<BillingMethod> =
    new OptionListModel();
  public tableResponseList: Array<
    Array<TableResponseModel<SowPaymentTermItem>>
  > = new Array();

  public stageOfWorkList: StageOfWork[] = [];
  public paymentTermList: PaymentTerm[] = [];
  public billingTermList: BillingTerm[] = [];
  public sowPaymentTypeList: SowPaymentType[] = [];
  public currencyList: Currency[] = [];
  public billingMethodList: BillingMethod[] = [];
  public appPopupSowModelList: AppPopupSowModel[] = [];
  public remainingAmount: number;
  public currentDate: Date = new Date();
  public isAddMore = false;
  public isDisableAddMore = false;
  public quantityServiceTemp = 0;
  public quantityGoodsTemp = 0;

  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    super('app-popup-sow');
  }

  onInit(): void {
    this.setOptionListModel();
    this.buildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setOptionListModel(): void {
    this.httpClientService
      .post<AppPopupSowResponse>(
        '/app-popup-sow/add-edit',
        new RouteRequestModel(this.todo, this.sowEdit?.id)
      )
      .subscribe((popupSowResponse: AppPopupSowResponse) => {
        this.stageOfWorkList = popupSowResponse.stageOfWorkList;
        this.billingTermList = popupSowResponse.billingTermList;
        this.paymentTermList = popupSowResponse.paymentTermList;
        this.sowPaymentTypeList = popupSowResponse.sowPaymentTypeList;
        this.currencyList = popupSowResponse.currencyList;
        this.billingMethodList = popupSowResponse.billingMethodList;

        this.stageOfWorkOptionList.setRequestValues(this.stageOfWorkList);
        this.billingTermOptionList.setRequestValues(this.billingTermList);
        this.paymentTermOptionList.setRequestValues(this.paymentTermList);
        this.sowPaymentTypeOptionList.setRequestValues(this.sowPaymentTypeList);
        this.currencyOptionList.setRequestValues(this.currencyList);
        this.billingMethodOptionList.setRequestValues(this.billingMethodList);

        if (this.formGroup.isView && this.sowEdit?.id) {
          this.isBlanketOrder = popupSowResponse.isBlanketOrder;
          this.appPopupSowModelList = popupSowResponse.popupSowModelList;
        }
        this.setStateReady();
        this.setStageOfWorkList();
        this.setSowPaymentType();
        this.setPaymentTypeList();

        if (this.isBlanketOrder) {
          this.setConditionIsBlanketOrder();
        }
      });
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      stageOfWork: [null, Validators.compose([Validators.required()])],
      date: [null, Validators.compose([Validators.required()])],
      startDate: [null],
      endDate: [null],
      sowPaymentType: [null, Validators.compose([Validators.required()])],
      totalTermin: [null],
      sowPaymentTermList: this.formBuilder.array([]),
      currentDate: this.currentDate,
      endDateValidate: [null],
      amount: [null],
      billingMethod: [null, Validators.compose([Validators.required()])]
    });
    this.formGroup.patchValue({
      currentDate: this.currentDate
    });
    this.getRemainingAmount();
    if (this.todo === 'add' && !this.isBlanketOrder) {
      this.buildFormArray();
      this.buildsowPaymentTermItemList();
    }
  }

  public setFormGroup(): void {
    if (this.todo !== 'add') {
      const startDate: Date = new Date(this.sowEdit.startDate);
      const endDate: Date = new Date(this.sowEdit.endDate);

      this.formGroup.patchValue({
        id: this.sowEdit.id,
        stageOfWork: this.sowEdit.stageOfWork,
        date: {
          from: startDate,
          to: endDate
        },
        startDate,
        endDate,
        totalTermin: this.sowEdit['totalTermin'],
        currentDate: this.currentDate,
        sowPaymentType: this.sowEdit.sowPaymentType,
        billingMethod: this.isBlanketOrder
          ? null
          : this.sowEdit.sowPaymentTermList[0]?.billingMethod
      });

      if (this.todo !== 'view') {
        this.onChangeStage();
      }

      if (!this.isBlanketOrder) {
        this.setSowPaymentTermItemList();
        this.setPaymentTermList();
      }
    }
  }

  public buildFormArray(): void {
    this.sowPaymentTermList.push(this.newSowPaymentTerm());
  }

  public get sowPaymentTermList(): FormArray {
    return this.formGroup.get('sowPaymentTermList') as FormArray;
  }

  public newSowPaymentTerm(): FormGroup {
    this.getRemainingAmount();
    const formGroup = this.formBuilder.group({
      id: [null],
      paymentTerm: [null, Validators.required()],
      percentage: [
        null,
        Validators.compose([Validators.required(), Validators.max(100)])
      ],
      amount: [null, Validators.compose([Validators.required()])],
      remainingAmount: [this.remainingAmount],
      billingMethod: [
        this.formGroup.get('billingMethod').value,
        Validators.compose([Validators.required()])
      ],
      billingTermList: [null, Validators.required()],
      isAssessment: [false],
      description: [null],
      deliveryStatus: [null],
      sowPaymentTermItemList: this.formBuilder.array([]),
      sowPaymentTermServiceItemList: this.formBuilder.array([]),
      sowPaymentTermGoodsItemList: this.formBuilder.array([]),
      sowPaymentTermBillingList: this.formBuilder.array([])
    });

    if (this.isAddMore) {
      this.setPaymentTermList();
      this.addSowPaymentTermItem(
        formGroup.get('sowPaymentTermServiceItemList') as FormArray,
        formGroup.get('sowPaymentTermGoodsItemList') as FormArray
      );
    }
    const sowPaymentType = this.formGroup.get('sowPaymentType').value;
    if (sowPaymentType) {
      const amount = formGroup.get('amount');
      const percentage = formGroup.get('percentage');
      if (
        sowPaymentType.code !==
          this.global.appConstant.pm.SOW_PAYMENT_TYPE_FULL_PAYMENT &&
        formGroup.get('billingMethod')?.value?.code ===
          this.global.appConstant.pm.BILLING_METHOD_PARTIAL
      ) {
        amount.setIsView(true);
        percentage.setIsView(true);
        this.setIsAssessmentDisabled();
      }
    }

    return formGroup;
  }

  public setPaymentTermList(): void {
    const paymentTermList = this.paymentTermList.filter(paymentTerm =>
      this.sowPaymentTermList.value.every(
        sowPaymentTerm => paymentTerm.id !== sowPaymentTerm.paymentTerm?.id
      )
    );
    this.paymentTermOptionList.setRequestValues(paymentTermList);
    if (
      this.formGroup.value.sowPaymentType &&
      this.formGroup.value.sowPaymentType.code ===
        this.global.appConstant.pm.SOW_PAYMENT_TYPE_GRADUAL_PAYMENT
    ) {
      const paymentTermLists = paymentTermList.filter(
        paymentTerm =>
          paymentTerm.code !==
            this.global.appConstant.cm.PAYMENT_TERM_DOWN_PAYMENT &&
          paymentTerm.code !==
            this.global.appConstant.cm.PAYMENT_TERM_FULL_PAYMENT
      );

      this.paymentTermOptionList.setRequestValues(paymentTermLists);
    }
  }

  public setSowPaymentType(): void {
    if (
      this.sowList &&
      this.sowList.length > 0 &&
      this.formGroup.get('stageOfWork')?.value?.code !==
        this.global.appConstant.cm.STAGE_OF_WORK_STAGE_1
    ) {
      const paymentTypeList = this.sowPaymentTypeList.filter(
        paymentType =>
          paymentType.code !==
          this.global.appConstant.cm.PAYMENT_TERM_FULL_PAYMENT
      );
      this.sowPaymentTypeOptionList.setRequestValues(paymentTypeList);
    } else {
      this.sowPaymentTypeOptionList.setRequestValues(this.sowPaymentTypeList);
    }
  }

  public setStageOfWorkList(): void {
    if (this.todo === 'add') {
      const stageOfWorkList = this.stageOfWorkList.filter(
        (stageOfWork: StageOfWork) =>
          this.sowList.every(sow => sow.stageOfWork.id !== stageOfWork.id)
      );
      this.stageOfWorkOptionList.setRequestValues(stageOfWorkList);
    } else {
      const stageOfWorkList = this.stageOfWorkList.filter(
        (stageOfWork: StageOfWork) =>
          this.sowList.every(
            (sow: Sow) =>
              sow.stageOfWork.id !== stageOfWork.id ||
              sow.stageOfWork.id === this.sowEdit.stageOfWork.id
          )
      );
      this.stageOfWorkOptionList.setRequestValues(stageOfWorkList);
    }
  }

  public setPaymentTypeList(): void {
    if (!this.isBlanketOrder) {
      const sowPaymentTypeList = this.sowPaymentTypeList.filter(
        (sowPaymentType: SowPaymentType) =>
          sowPaymentType.code !==
          this.global.appConstant.pm.SOW_PAYMENT_TYPE_AS_PER_ORDER
      );
      this.sowPaymentTypeOptionList.setRequestValues(sowPaymentTypeList);
    } else {
      this.sowPaymentTypeOptionList.setRequestValues(this.sowPaymentTypeList);
    }
  }

  public addSowPaymentTerm(): void {
    this.isAddMore = true;
    this.sowPaymentTermList.push(this.newSowPaymentTerm());
  }

  public buildTableResponse(
    sowPaymentTermGoodsItemList: SowPaymentTermItem[],
    sowPaymentTermServiceItemList: SowPaymentTermItem[]
  ): void {
    const tableResponseGoods: TableResponseModel<SowPaymentTermItem> =
      new TableResponseModel(this.moduleCode, [
        {
          field: 'prItemReleased.prItem.item.name',
          header: 'table.column.item'
        },
        {
          field: 'prItemReleased.prItem.item.code',
          header: 'table.column.itemCode'
        },
        {
          field: 'prItemReleased.prItem.item.uom.name',
          header: 'table.column.uom',
          className: 'text-left'
        },
        {
          field: 'quantity',
          header: 'table.column.quantity',
          plugins: {
            name: 'count',
            onChange: this.onChangeGoodsQuantity.bind(
              this,
              this.tableResponseList.length
            ),
            onInput: this.onChangeGoodsQuantity.bind(
              this,
              this.tableResponseList.length
            ),
            min: 0,
            isView: this.formGroup.isView
          }
        },
        {
          field: 'remainingQuantity',
          header: 'table.column.remainingQuantity'
        }
      ]);
    const tableResponseService: TableResponseModel<SowPaymentTermItem> =
      new TableResponseModel(this.moduleCode, [
        {
          field: 'prItemReleased.prItem.item.name',
          header: 'table.column.item'
        },
        {
          field: 'prItemReleased.prItem.item.code',
          header: 'table.column.itemCode'
        },
        {
          field: 'prItemReleased.prItem.item.uom.name',
          header: 'table.column.uom',
          className: 'text-left'
        },
        {
          field: 'quantity',
          header: 'table.column.deliverible',
          plugins: {
            name: 'count',
            onChange: this.onChangeServiceQuantity.bind(
              this,
              this.tableResponseList.length
            ),
            onInput: this.onChangeServiceQuantity.bind(
              this,
              this.tableResponseList.length
            ),
            min: 0,
            isView: this.formGroup.isView
          }
        },
        {
          field: 'remainingQuantity',
          header: 'table.column.remainingQuantity'
        }
      ]);

    this.tableResponseList.push([tableResponseGoods, tableResponseService]);
    tableResponseGoods.setRecordList(sowPaymentTermGoodsItemList);
    tableResponseService.setRecordList(sowPaymentTermServiceItemList);
  }

  public onAfterRowCreated(tableRow: TableRow): void {
    if (!this.formGroup.isView) {
      if (tableRow && tableRow.columnList[3]) {
        const plugins = tableRow.columnList[3].column.plugins as CountPlugin;
        plugins.max =
          +tableRow.record.remainingQuantity +
          +tableRow.formGroup.get('quantity').value;
        tableRow.columnList[3].column.plugins = plugins;
      }
      tableRow.formGroup
        .get('quantity')
        .setValidators(
          Validators.max(
            +tableRow.record.remainingQuantity +
              +tableRow.formGroup.get('quantity').value
          )
        );
      tableRow.formGroup.get('quantity').updateValueAndValidity();
      tableRow.formGroup.get('quantity').markAsTouched();
    }
  }

  public buildsowPaymentTermItemList(): void {
    const index = this.sowPaymentTermList.value.length - 1;
    const sowPaymentTermServiceItemList = this.sowPaymentTermList.controls[
      index
    ].get('sowPaymentTermServiceItemList') as FormArray;
    const sowPaymentTermGoodsItemList = this.sowPaymentTermList.controls[
      index
    ].get('sowPaymentTermGoodsItemList') as FormArray;
    this.prItemReleasedList.forEach(prItemReleased => {
      if (
        prItemReleased.prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      ) {
        const sowPaymentTerm =
          this.sowPaymentTermLists &&
          this.sowPaymentTermLists['sowPaymentTermItemList'].find(
            sowPaymentTerm =>
              +sowPaymentTerm.prItemReleased.id === +prItemReleased.id
          );

        const formGroup = this.formBuilder.group({
          id: [null],
          quantity: [null, Validators.min(0)],
          prItemReleased,
          remainingQuantity: sowPaymentTerm
            ? sowPaymentTerm.remainingQuantity
            : prItemReleased.quantity,
          deliveryPercentage: [null],
          deliveryStatus: [null]
        });
        sowPaymentTermGoodsItemList.push(formGroup);
      }
      if (
        prItemReleased.prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
      ) {
        const sowPaymentTerm =
          this.sowPaymentTermLists &&
          this.sowPaymentTermLists['sowPaymentTermItemList'].find(
            sowPaymentTerm =>
              +sowPaymentTerm.prItemReleased.id === +prItemReleased.id
          );
        const formGroup = this.formBuilder.group({
          id: [null],
          prItemReleased,
          quantity: [null, Validators.min(0)],
          remainingQuantity: sowPaymentTerm
            ? sowPaymentTerm.remainingQuantity
            : +100,
          deliveryPercentage: [null],
          deliveryStatus: [null]
        });
        sowPaymentTermServiceItemList.push(formGroup);
      }
    });

    // this.buildTableResponse(
    //   sowPaymentTermGoodsItemList.value,
    //   sowPaymentTermServiceItemList.value
    // );
  }

  public addSowPaymentTermItem(
    sowPaymentTermServiceItemForm: FormArray,
    sowPaymentTermGoodsItemForm: FormArray
  ): void {
    const index = this.sowPaymentTermList.value.length - 1;
    const sowPaymentTermServiceItemList = this.sowPaymentTermList.controls[
      index
    ].get('sowPaymentTermServiceItemList') as FormArray;
    const sowPaymentTermGoodsItemList = this.sowPaymentTermList.controls[
      index
    ].get('sowPaymentTermGoodsItemList') as FormArray;
    sowPaymentTermGoodsItemList.controls.forEach(goodsItem => {
      const formGroup = this.formBuilder.group({
        id: [null],
        quantity: [null, Validators.min(0)],
        prItemReleased: goodsItem.value.prItemReleased,
        remainingQuantity: goodsItem.value.remainingQuantity,
        maxQuantity: goodsItem.value.remainingQuantity,
        deliveryPercentage: [null],
        deliveryStatus: [null]
      });
      sowPaymentTermGoodsItemForm.push(formGroup);
    });

    sowPaymentTermServiceItemList.controls.forEach(serviceItem => {
      const formGroup = this.formBuilder.group({
        id: [null],
        prItemReleased: serviceItem.value.prItemReleased,
        quantity: [null, Validators.min(0)],
        remainingQuantity: serviceItem.value.remainingQuantity,
        maxQuantity: serviceItem.value.remainingQuantity,
        deliveryPercentage: [null],
        deliveryStatus: [null]
      });
      sowPaymentTermServiceItemForm.push(formGroup);
    });
    // this.buildTableResponse(
    //   sowPaymentTermGoodsItemForm.value,
    //   sowPaymentTermServiceItemForm.value
    // );
  }

  public setSowPaymentTermItemList(): void {
    this.sowEdit.sowPaymentTermList.forEach((sowPaymentTerm, i) => {
      const billingTermList = [];
      sowPaymentTerm.sowPaymentTermBillingList.forEach(sowBilling => {
        billingTermList.push(sowBilling.billingTerm);
      });
      const billingTermLists = [];
      billingTermLists.push(billingTermList);
      const formGroup = this.formBuilder.group({
        id: [null],
        paymentTerm: [null, Validators.required()],
        percentage: [
          null,
          Validators.compose([Validators.required(), Validators.max(100)])
        ],
        amount: [null, Validators.compose([Validators.required()])],
        remainingAmount: [this.remainingAmount],
        billingMethod: [null, Validators.required()],
        billingTermList: [null, Validators.required()],
        isAssessment: [false],
        description: [null],
        deliveryStatus: [null],
        sowPaymentTermItemList: this.formBuilder.array([]),
        sowPaymentTermServiceItemList: this.formBuilder.array([]),
        sowPaymentTermGoodsItemList: this.formBuilder.array([]),
        sowPaymentTermBillingList: this.formBuilder.array([])
      });
      formGroup.patchValue({
        id: sowPaymentTerm.id,
        paymentTerm: sowPaymentTerm.paymentTerm,
        percentage: sowPaymentTerm.percentage,
        amount: {
          price: sowPaymentTerm.amount
        },
        remainingAmount: sowPaymentTerm.remainingAmount,
        billingMethod: sowPaymentTerm.billingMethod,
        isAssessment: sowPaymentTerm.isAssessment,
        billingTermList,
        description: sowPaymentTerm.description,
        deliveryStatus: sowPaymentTerm.deliveryStatus
      });

      if (this.todo === 'view') {
        formGroup.setIsView(true);
        const amount = formGroup.get('amount');
        const percentage = formGroup.get('percentage');
        amount.setIsView(true);
        percentage.setIsView(true);
      } else {
        const sowPaymentType = this.formGroup.get('sowPaymentType').value;
        if (sowPaymentType) {
          const amount = formGroup.get('amount');
          const percentage = formGroup.get('percentage');
          const paymentTerm = formGroup.get('paymentTerm');
          const billingMethod = this.formGroup.get('billingMethod');
          if (
            sowPaymentType.code ===
            this.global.appConstant.pm.SOW_PAYMENT_TYPE_FULL_PAYMENT
          ) {
            this.isDisableAddMore = true;
            amount.setIsView(true);
            percentage.setIsView(true);
            paymentTerm.setIsView(true);
            billingMethod.setIsView(true);
          } else if (
            sowPaymentType.code ===
            this.global.appConstant.pm.SOW_PAYMENT_TYPE_MONTHLY_PAYMENT
          ) {
            billingMethod.setIsView(true);

            this.isDisableAddMore = true;
          } else if (
            sowPaymentType.code ===
            this.global.appConstant.pm.SOW_PAYMENT_TYPE_GRADUAL_PAYMENT
          ) {
            billingMethod.setIsView(false);
            amount.setIsView(false);
            percentage.setIsView(false);
            paymentTerm.setIsView(false);
            this.isDisableAddMore = false;
            const paymentTermList = this.paymentTermList;
            const paymentTermLists = paymentTermList.filter(
              paymentTerm =>
                paymentTerm.code !==
                  this.global.appConstant.cm.PAYMENT_TERM_DOWN_PAYMENT &&
                paymentTerm.code !==
                  this.global.appConstant.cm.PAYMENT_TERM_FULL_PAYMENT
            );
            this.paymentTermOptionList.setRequestValues(paymentTermLists);
          } else if (
            sowPaymentType.code ===
            this.global.appConstant.pm.SOW_PAYMENT_TYPE_GRADUAL_PAYMENT_WITH_DP
          ) {
            billingMethod.setIsView(false);
            amount.setIsView(false);
            percentage.setIsView(false);
            paymentTerm.setIsView(true);
            this.paymentTermOptionList.setRequestValues(this.paymentTermList);
            this.isDisableAddMore = false;
          }

          if (
            sowPaymentType.code !==
              this.global.appConstant.pm.SOW_PAYMENT_TYPE_FULL_PAYMENT &&
            sowPaymentTerm.billingMethod.code ===
              this.global.appConstant.pm.BILLING_METHOD_PARTIAL
          ) {
            amount.setIsView(true);
            percentage.setIsView(true);
          }

          if (
            sowPaymentTerm.paymentTerm.code ===
            this.global.appConstant.cm.PAYMENT_TERM_DOWN_PAYMENT
          ) {
            billingMethod.setIsView(true);
            // formGroup.get('isAssessment').setIsView(true);
          }
        }
      }

      this.sowPaymentTermList.push(formGroup);

      const sowPaymentTermServiceItemList = this.sowPaymentTermList.controls[
        i
      ].get('sowPaymentTermServiceItemList') as FormArray;
      const sowPaymentTermGoodsItemList = this.sowPaymentTermList.controls[
        i
      ].get('sowPaymentTermGoodsItemList') as FormArray;
      const sowPaymentTermBillingList = this.sowPaymentTermList.controls[i].get(
        'sowPaymentTermBillingList'
      ) as FormArray;

      sowPaymentTerm.sowPaymentTermBillingList.forEach(sowBilling => {
        sowPaymentTermBillingList.push(
          this.formBuilder.group({
            id: sowBilling.id,
            billingTerm: sowBilling.billingTerm
          })
        );
      });

      sowPaymentTerm.sowPaymentTermItemList.forEach((sowPaymentTermItem, j) => {
        if (
          sowPaymentTermItem.prItemReleased.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
        ) {
          const formGroupGoods = this.formBuilder.group({
            id: sowPaymentTermItem.id,
            quantity: sowPaymentTermItem.quantity,
            prItemReleased: sowPaymentTermItem.prItemReleased,
            remainingQuantity: this.sowPaymentTermLists
              ? this.sowPaymentTermLists['sowPaymentTermItemList'][j]
                  .remainingQuantity
              : sowPaymentTermItem.prItemReleased.quantity,
            deliveryPercentage: [null],
            deliveryStatus: [null]
          });
          if (this.todo === 'view') {
            formGroupGoods.setIsView(true);
          }
          sowPaymentTermGoodsItemList.push(formGroupGoods);
        }

        if (
          sowPaymentTermItem.prItemReleased.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
        ) {
          const formGroupService = this.formBuilder.group({
            id: sowPaymentTermItem.id,
            prItemReleased: sowPaymentTermItem.prItemReleased,
            quantity: sowPaymentTermItem.quantity,
            remainingQuantity: this.sowPaymentTermLists
              ? this.sowPaymentTermLists['sowPaymentTermItemList'][j]
                  .remainingQuantity
              : +100,
            deliveryPercentage: [null],
            deliveryStatus: [null]
          });
          if (this.todo === 'view') {
            formGroupService.setIsView(true);
          }
          sowPaymentTermServiceItemList.push(formGroupService);
        }
      });

      // this.buildTableResponse(
      //   sowPaymentTermGoodsItemList.value,
      //   sowPaymentTermServiceItemList.value
      // );
    });
    this.getRemainingAmount();
    if (!this.formGroup.isView) {
      this.onChangePaymentTerm(0);
    }
  }

  public onChangeStage(): void {
    const stageOfWork = this.formGroup.value.stageOfWork;
    if (this.sowList) {
      this.sowList.forEach(procurementSow => {
        if (procurementSow.stageOfWork.id < stageOfWork.id) {
          // const date: Date = new Date(procurementSow.endDate);
          const date: Date = new Date(procurementSow.startDate);
          this.formGroup.patchValue({
            currentDate: date
          });
        }
        if (procurementSow.stageOfWork.id > stageOfWork.id) {
          // const date: Date = new Date(procurementSow.startDate);
          const date: Date = new Date(procurementSow.endDate);
          this.formGroup.patchValue({
            endDateValidate: date
          });
        }
      });
    }
  }

  public onChangePaymentType(): void {
    const sowPaymentType = this.formGroup.get('sowPaymentType').value;
    if (sowPaymentType) {
      const amount = this.sowPaymentTermList.controls[0].get('amount');
      const percentage = this.sowPaymentTermList.controls[0].get('percentage');
      const paymentTerm =
        this.sowPaymentTermList.controls[0].get('paymentTerm');
      const billingMethod = this.formGroup.get('billingMethod');

      if (
        sowPaymentType.code ===
        this.global.appConstant.pm.SOW_PAYMENT_TYPE_FULL_PAYMENT
      ) {
        this.isDisableAddMore = true;
        amount.setIsView(true);
        percentage.setIsView(true);
        paymentTerm.setIsView(true);
        billingMethod.setIsView(true);

        amount.patchValue({ price: this.amount });
        percentage.patchValue(100);
        this.getRemainingAmount();
        this.sowPaymentTermList.controls.forEach(sowPayment => {
          sowPayment.get('remainingAmount').patchValue(+this.remainingAmount);
        });
        paymentTerm.patchValue(
          this.paymentTermList.find(
            paymentTerm =>
              paymentTerm.code ===
              this.global.appConstant.cm.PAYMENT_TERM_FULL_PAYMENT
          )
        );
        billingMethod.patchValue(
          this.billingMethodList.find(
            billingMethod =>
              billingMethod.code ===
              this.global.appConstant.pm.BILLING_METHOD_NON_PARTIAL
          )
        );
      } else if (
        sowPaymentType.code ===
        this.global.appConstant.pm.SOW_PAYMENT_TYPE_MONTHLY_PAYMENT
      ) {
        amount.setIsView(false);
        percentage.setIsView(false);
        paymentTerm.setIsView(false);
        billingMethod.setIsView(true);

        this.isDisableAddMore = true;

        paymentTerm.patchValue(
          this.paymentTermList.find(
            paymentTerm =>
              paymentTerm.code ===
              this.global.appConstant.cm.PAYMENT_TERM_TERMIN_ONE
          )
        );

        const paymentTermLists = this.paymentTermList.filter(
          paymentTerm =>
            paymentTerm.code !==
            this.global.appConstant.cm.PAYMENT_TERM_FULL_PAYMENT
        );
        this.paymentTermOptionList.setRequestValues(paymentTermLists);

        amount.reset();
        percentage.reset();

        billingMethod.patchValue(
          this.billingMethodList.find(
            billingMethod =>
              billingMethod.code ===
              this.global.appConstant.pm.BILLING_METHOD_PARTIAL
          )
        );
        this.sowPaymentTermList.controls.forEach(sowPayment => {
          sowPayment
            .get('remainingAmount')
            .patchValue(+this.availableAmountSow);
        });
        paymentTerm.updateValueAndValidity();
        billingMethod.updateValueAndValidity();
      } else if (
        sowPaymentType.code ===
        this.global.appConstant.pm.SOW_PAYMENT_TYPE_GRADUAL_PAYMENT
      ) {
        billingMethod.setIsView(false);
        amount.setIsView(false);
        percentage.setIsView(false);
        paymentTerm.setIsView(false);
        this.isDisableAddMore = false;
        const paymentTermList = this.paymentTermList;
        const paymentTermLists = paymentTermList.filter(
          paymentTerm =>
            paymentTerm.code !==
              this.global.appConstant.cm.PAYMENT_TERM_DOWN_PAYMENT &&
            paymentTerm.code !==
              this.global.appConstant.cm.PAYMENT_TERM_FULL_PAYMENT
        );
        this.paymentTermOptionList.setRequestValues(paymentTermLists);
        amount.reset();
        percentage.reset();
        billingMethod.patchValue(null);
        paymentTerm.patchValue(null);
        this.sowPaymentTermList.controls.forEach(sowPayment => {
          sowPayment
            .get('remainingAmount')
            .patchValue(+this.availableAmountSow);
        });
        paymentTerm.updateValueAndValidity();
        billingMethod.updateValueAndValidity();
      } else if (
        sowPaymentType.code ===
        this.global.appConstant.pm.SOW_PAYMENT_TYPE_GRADUAL_PAYMENT_WITH_DP
      ) {
        billingMethod.setIsView(true);
        amount.setIsView(false);
        percentage.setIsView(false);
        paymentTerm.setIsView(true);
        this.paymentTermOptionList.setRequestValues(this.paymentTermList);
        paymentTerm.patchValue(
          this.paymentTermList.find(
            paymentTerm =>
              paymentTerm.code ===
              this.global.appConstant.cm.PAYMENT_TERM_DOWN_PAYMENT
          )
        );
        billingMethod.patchValue(
          this.billingMethodList.find(
            billingMethod =>
              billingMethod.code ===
              this.global.appConstant.pm.BILLING_METHOD_NON_PARTIAL
          )
        );
        this.isDisableAddMore = false;
        amount.reset();
        percentage.reset();
        this.sowPaymentTermList.controls.forEach(sowPayment => {
          sowPayment
            .get('remainingAmount')
            .patchValue(+this.availableAmountSow);
        });
        if (!this.formGroup.isView) {
          this.setIsAssessmentDisabled();
        }
      } else {
        amount.setIsView(false);
        percentage.setIsView(false);
        paymentTerm.setIsView(false);
      }

      this.doOnChangeBillingMethod();
      this.onChangePaymentTerm(0);
    }
  }

  public onChangePaymentTerm(i: number): void {
    console.log(i);

    let isContainDp = false;
    this.sowPaymentTermList.controls.forEach((sowPaymentTerm: FormGroup) => {
      if (
        sowPaymentTerm.value.paymentTerm?.code ===
        this.global.appConstant.cm.PAYMENT_TERM_DOWN_PAYMENT
      ) {
        isContainDp = true;
        return;
      }
    });

    if (isContainDp) {
      if (!this.formGroup.isView) {
        this.setIsAssessmentDisabled();
      }
    } else {
      const sowPaymentTerm = this.sowPaymentTermList.controls[0] as FormGroup;
      sowPaymentTerm.get('isAssessment').enable();
      sowPaymentTerm.get('isAssessment').setIsView(false);
    }
  }

  public doOnChangeBillingTermList(index: number, event: any): void {
    const sowPaymentTermListForm = this.formGroup.controls
      .sowPaymentTermList as FormGroup;
    const sowPaymentTermBillingList = sowPaymentTermListForm.controls[
      index
    ].get('sowPaymentTermBillingList') as FormArray;
    sowPaymentTermBillingList.clear();
    event.forEach(billingTerm => {
      sowPaymentTermBillingList.push(
        this.formBuilder.group({
          id: event.id,
          billingTerm
        })
      );
    });
  }

  public doDeletePaymentTerm(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const amount =
            this.sowPaymentTermList.controls[index].get('amount').value;
          const sowPaymentTermServiceItemList =
            this.sowPaymentTermList.controls[index].get(
              'sowPaymentTermServiceItemList'
            ) as FormArray;
          const sowPaymentTermGoodsItemList = this.sowPaymentTermList.controls[
            index
          ].get('sowPaymentTermGoodsItemList') as FormArray;
          this.remainingAmount = this.remainingAmount + +amount;

          sowPaymentTermServiceItemList.value.forEach(
            sowPaymentTermServiceItem => {
              this.sowPaymentTermList.controls.forEach(
                (paymentFormGroup: FormGroup) => {
                  (
                    paymentFormGroup.get(
                      'sowPaymentTermServiceItemList'
                    ) as FormArray
                  ).controls.forEach(sowService => {
                    const j = 0;
                    if (
                      sowPaymentTermServiceItem.prItemReleased.id ===
                        sowService.value.prItemReleased.id &&
                      sowPaymentTermServiceItem.id !== sowService.value.id
                    ) {
                      if (this.quantityServiceTemp === 0 && sowService.value.quantity !== 0) {
                        (
                        paymentFormGroup.get(
                          'sowPaymentTermServiceItemList'
                        ) as FormArray
                      ).controls[j]
                        .get('remainingQuantity')
                        .patchValue(
                          +sowService.value.remainingQuantity +
                            +sowPaymentTermServiceItem.quantity
                        );
                      } else {
                        (
                        paymentFormGroup.get(
                          'sowPaymentTermServiceItemList'
                        ) as FormArray
                      ).controls[j]
                        .get('remainingQuantity')
                        .patchValue(this.quantityServiceTemp);
                      }
                    } else if (
                      sowPaymentTermServiceItem.prItemReleased.id ===
                        sowService.value.prItemReleased.id &&
                      sowPaymentTermServiceItem.id === sowService.value.id
                    ) {
                      this.quantityServiceTemp += +sowPaymentTermServiceItem.quantity;
                      paymentFormGroup.get('amount').setValue(Number(null));
                      paymentFormGroup.get('percentage').setValue(Number(null));
                      paymentFormGroup.get('remainingAmount').setValue(Number(null));
                      (
                        paymentFormGroup.get(
                          'sowPaymentTermServiceItemList'
                        ) as FormArray
                      ).controls[j]
                        .get('quantity')
                        .setValue(Number(null));
                      ((paymentFormGroup.get('sowPaymentTermServiceItemList') as FormArray).controls[j].get('quantity') as FormControl).disable();
                      (paymentFormGroup.get('description') as FormControl).disable();
                    }
                  });
                }
              );
            }
          );

          sowPaymentTermGoodsItemList.value.forEach(sowPaymentTermGoodsItem => {
            this.sowPaymentTermList.controls.forEach(
              (paymentFormGroup: FormGroup) => {
                (
                  paymentFormGroup.get(
                    'sowPaymentTermGoodsItemList'
                  ) as FormArray
                ).controls.forEach(sowGoods => {
                  const j = 0;
                  if (
                    sowPaymentTermGoodsItem.prItemReleased.id ===
                      sowGoods.value.prItemReleased.id &&
                    sowPaymentTermGoodsItem.id !== sowGoods.value.id
                  ) {
                    if (this.quantityGoodsTemp === 0 && sowGoods.value.quantity !== 0) {
                      (
                        paymentFormGroup.get(
                          'sowPaymentTermGoodsItemList'
                        ) as FormArray
                      ).controls[j]
                        .get('remainingQuantity')
                        .patchValue(
                          +sowGoods.value.remainingQuantity +
                            +sowPaymentTermGoodsItem.quantity
                      );
                    } else {
                      (
                        paymentFormGroup.get(
                          'sowPaymentTermGoodsItemList'
                        ) as FormArray
                      ).controls[j]
                        .get('remainingQuantity')
                        .patchValue(this.quantityGoodsTemp);
                    }
                  } else if (
                    sowPaymentTermGoodsItem.prItemReleased.id ===
                      sowGoods.value.prItemReleased.id &&
                    sowPaymentTermGoodsItem.id === sowGoods.value.id
                  ) {
                    this.quantityGoodsTemp += +sowPaymentTermGoodsItem.quantity;
                    paymentFormGroup.get('amount').setValue(Number(null));
                    paymentFormGroup.get('percentage').setValue(Number(null));
                    paymentFormGroup.get('remainingAmount').setValue(Number(null));
                    (
                      paymentFormGroup.get(
                        'sowPaymentTermGoodsItemList'
                      ) as FormArray
                    ).controls[j]
                      .get('quantity')
                      .setValue(Number(null));
                    ((paymentFormGroup.get('sowPaymentTermGoodsItemList') as FormArray).controls[j].get('quantity') as FormControl).disable();
                    (paymentFormGroup.get('description') as FormControl).disable();
                  }
                });
              }
            );
          });
        }
      });
  }

  public onInputPercentage(payment: FormGroup, index: number): void {
    const amountForm = payment.controls.amount;
    const percentage =
      this.sowPaymentTermList.controls[index].get('percentage').value;
    const amount = +this.amount * (+percentage / 100);
    amountForm.patchValue({ price: +amount });
    this.getRemainingAmount();
    if (+this.remainingAmount.toFixed(2) < 0) {
      amountForm.setErrors({
        max: true,
        message: this.translateService.instant('app.validation.max')
      });
      amountForm.markAsDirty();
      amountForm.markAsTouched();
      this.remainingAmount = 0;
    }
    this.sowPaymentTermList.controls.forEach(sowPayment => {
      sowPayment.get('remainingAmount').patchValue(+this.remainingAmount);
    });
  }

  public onInputAmount(payment: FormGroup, index: number): void {
    const amount = this.sowPaymentTermList.controls[index].get('amount').value;
    const percentageValue =
      amount === null ? 0 : (+amount?.price / +this.amount) * 100;
    const percentage = percentageValue;
    const percentageForm = payment.controls.percentage;
    const amountForm = payment.controls.amount;
    percentageForm.patchValue(+percentage);
    this.getRemainingAmount();
    if (+this.remainingAmount.toFixed(2) < 0) {
      if (+percentage > 100) {
        percentageForm.setErrors({
          max: true,
          message: this.translateService.instant('app.validation.max')
        });
        percentageForm.markAsDirty();
        percentageForm.markAsTouched();
      }
      amountForm.setErrors({
        max: true,
        message: this.translateService.instant('app.validation.max')
      });
      amountForm.markAsDirty();
      amountForm.markAsTouched();
      this.remainingAmount = 0;
    }
    this.sowPaymentTermList.controls.forEach(sowPayment => {
      sowPayment.get('remainingAmount').patchValue(+this.remainingAmount);
    });
  }

  public getRemainingAmount(): void {
    let totalUsedAmount = 0;
    this.sowPaymentTermList.controls.forEach(sowPaymentTerm => {
      totalUsedAmount = totalUsedAmount + +sowPaymentTerm.value.amount?.price;
    });
    this.remainingAmount = +this.availableAmountSow - +totalUsedAmount;
  }

  public getRemainingPercentage(payment: FormGroup): number {
    const remainingAmount = payment.get('remainingAmount').value;
    const remainingPercentage =
      remainingAmount === null ? 0 : (+remainingAmount / +this.amount) * 100;
    return remainingPercentage;
  }

  public onChangeGoodsQuantity(i, record): void {
    console.log(record);
    const j = +this.tableResponseList[i][0].currentRowChange.row.position - 1;
    const tableRow = this.tableResponseList[i][0].currentRowChange.row;
    const quantity =
      this.tableResponseList[i][0].currentRowChange.row.formGroup.get(
        'quantity'
      );
    let quantityPrItem = this.sowPaymentTermLists
      ? this.sowPaymentTermLists['sowPaymentTermGoodsItemList'][j]
          .remainingQuantity
      : this.tableResponseList[i][0].currentRowChange.row.record.prItemReleased
          .quantity;
    let remainingValue =
      this.tableResponseList[i][0].currentRowChange.row.record
        .remainingQuantity;
    if (this.todo !== 'add' && !this.isAddMore) {
      remainingValue = this.sowEdit.sowPaymentTermList
        ? this.sowEdit.sowPaymentTermList[i]['sowPaymentTermGoodsItemList'][j]
            .remainingQuantity
        : remainingValue;
      let oldQuantity = 0;

      this.sowEdit.sowPaymentTermList.forEach(payment => {
        oldQuantity =
          oldQuantity + +payment['sowPaymentTermGoodsItemList'][j].quantity;
      });
      quantityPrItem = +oldQuantity + +remainingValue;
    } else if (this.todo !== 'add' && this.isAddMore) {
      const remainingValue =
        +this.sowPaymentTermLists['sowPaymentTermGoodsItemList'][j]
          .remainingQuantity;
      let oldQuantity = 0;
      this.sowEdit.sowPaymentTermList.forEach(payment => {
        oldQuantity =
          oldQuantity + +payment['sowPaymentTermGoodsItemList'][j].quantity;
      });
      quantityPrItem = +oldQuantity + +remainingValue;
    }
    const totalQuantity = this.getTotalGoodsQuantity(j);

    if (+totalQuantity > +quantityPrItem.toFixed(2)) {
      quantity.setValidators([
        Validators.max(
          +remainingValue === null ? +quantityPrItem : +remainingValue
        )
      ]);
      quantity.setErrors({
        message: this.translateService.instant('app.validation.max')
      });
      quantity.markAsTouched({ onlySelf: true });
      quantity.updateValueAndValidity();
    } else {
      this.sowPaymentTermList.controls.forEach(
        (paymentFormGroup: FormGroup) => {
          (
            paymentFormGroup.get('sowPaymentTermGoodsItemList') as FormArray
          ).controls[j]
            .get('quantity')
            .clearValidators();

          (
            paymentFormGroup.get('sowPaymentTermGoodsItemList') as FormArray
          ).controls[j]
            .get('quantity')
            .updateValueAndValidity();
        }
      );
      quantity.clearValidators();
      quantity.updateValueAndValidity();
    }
    quantity;
    const remainingQuantity = +quantityPrItem - +totalQuantity;
    if (remainingQuantity || remainingQuantity !== null) {
      const tableColumnRemaining: TableColumn =
        this.tableResponseList[i][0].currentRowChange.row.columnList[4];
      tableColumnRemaining.changeValue(remainingQuantity);
      tableColumnRemaining.reload();

      this.sowPaymentTermList.controls.forEach(
        (paymentFormGroup: FormGroup) => {
          (
            paymentFormGroup.get('sowPaymentTermGoodsItemList') as FormArray
          ).controls[j]
            .get('remainingQuantity')
            .patchValue(+remainingQuantity.toFixed(2));
        }
      );

      this.sowPaymentTermList.controls.forEach(
        (paymentFormGroup: FormGroup) => {
          (
            paymentFormGroup.get('sowPaymentTermGoodsItemList') as FormArray
          ).controls[j]
            .get('quantity')
            .patchValue(+quantity.value);
        }
      );
    } else if (+remainingQuantity === 0) {
      quantity.setValidators([
        Validators.max(
          +remainingValue === null ? +quantityPrItem : +remainingValue
        )
      ]);
      quantity.markAsTouched({ onlySelf: true });
      quantity.updateValueAndValidity();
    }

    const recordList = this.tableResponseList[i][0]
      .getRecordList()
      .map(record => {
        const indexOfR = this.tableResponseList[i][0]
          .getUpdatedRecordList()
          .findIndex(updateRecord => updateRecord.id === record.id);
        return indexOfR === -1
          ? record
          : this.tableResponseList[i][0].getUpdatedRecordList()[indexOfR];
      });

    tableRow.formGroup
      .get('quantity')
      .setValidators(
        Validators.compose([
          Validators.max(+remainingQuantity + +quantity.value)
        ])
      );
    tableRow.formGroup.get('quantity').updateValueAndValidity();

    const plugins = tableRow.columnList[3].column.plugins as CountPlugin;
    plugins.max = +remainingQuantity + +quantity.value;

    this.tableResponseList[i][0].setRecordList(recordList);
  }

  public getTotalGoodsQuantity(j: number): number {
    let totalQuantity = 0;
    this.tableResponseList.forEach(tableResponse => {
      console.log(tableResponse[0]);
      console.log(tableResponse[0].getUpdatedRecordList()[j]);
      totalQuantity =
        +totalQuantity + +tableResponse[0].getUpdatedRecordList()[j].quantity;
    });
    return totalQuantity;
  }

  public onChangeServiceQuantity(i, record): void {
    console.log(record);
    const j = +this.tableResponseList[i][1].currentRowChange.row.position - 1;
    const quantity =
      this.tableResponseList[i][1].currentRowChange.row.formGroup.get(
        'quantity'
      );
    let total = this.sowPaymentTermLists
      ? this.sowPaymentTermLists['sowPaymentTermServiceItemList'][j]
          .remainingQuantity
      : 100;
    let remainingValue =
      this.tableResponseList[i][1].currentRowChange.row.record
        .remainingQuantity;
    if (this.todo !== 'add' && !this.isAddMore) {
      remainingValue = this.sowEdit.sowPaymentTermList[i]
        ? this.sowEdit.sowPaymentTermList[i]['sowPaymentTermServiceItemList'][j]
            .remainingQuantity
        : remainingValue;

      let oldQuantity = 0;
      this.sowEdit.sowPaymentTermList.forEach(payment => {
        oldQuantity =
          oldQuantity + +payment['sowPaymentTermServiceItemList'][j].quantity;
      });
      total = +oldQuantity + +remainingValue;
    } else if (this.todo !== 'add' && this.isAddMore) {
      const remainingValue =
        +this.sowPaymentTermLists['sowPaymentTermServiceItemList'][j]
          .remainingQuantity;
      let oldQuantity = 0;
      this.sowEdit.sowPaymentTermList.forEach(payment => {
        oldQuantity =
          oldQuantity + +payment['sowPaymentTermServiceItemList'][j].quantity;
      });
      total = +oldQuantity + +remainingValue;
    }

    this.sowPaymentTermList.controls.forEach((paymentFormGroup: FormGroup) => {
      (
        paymentFormGroup.get('sowPaymentTermServiceItemList') as FormArray
      ).controls[j]
        .get('quantity')
        .patchValue(+quantity.value);
    });

    const totalQuantity = this.getTotalQuantityService(j);
    if (+totalQuantity > +total) {
      quantity.setValidators([Validators.max(+remainingValue)]);
      quantity.setErrors({
        max: true,
        message: this.translateService.instant('app.validation.max')
      });
      quantity.updateValueAndValidity();
    } else {
      this.sowPaymentTermList.controls.forEach(
        (paymentFormGroup: FormGroup) => {
          (
            paymentFormGroup.get('sowPaymentTermServiceItemList') as FormArray
          ).controls[j]
            .get('quantity')
            .clearValidators();

          (
            paymentFormGroup.get('sowPaymentTermServiceItemList') as FormArray
          ).controls[j]
            .get('quantity')
            .updateValueAndValidity();
        }
      );
      quantity.clearValidators();
      quantity.updateValueAndValidity();
    }
    const remainingQuantity = +total - +totalQuantity;

    const tableColumnRemaining: TableColumn =
      this.tableResponseList[i][1].currentRowChange.row.columnList[4];
    tableColumnRemaining.changeValue(remainingQuantity);
    tableColumnRemaining.reload();

    const recordList = this.tableResponseList[i][1]
      .getRecordList()
      .map(record => {
        const indexOfR = this.tableResponseList[i][1]
          .getUpdatedRecordList()
          .findIndex(updateRecord => updateRecord.id === record.id);
        return indexOfR === -1
          ? record
          : this.tableResponseList[i][1].getUpdatedRecordList()[indexOfR];
      });

    const tableRow = this.tableResponseList[i][1].currentRowChange.row;
    tableRow.formGroup
      .get('quantity')
      .setValidators(
        Validators.compose([
          Validators.max(+remainingQuantity + +quantity.value)
        ])
      );
    tableRow.formGroup.get('quantity').updateValueAndValidity();

    const plugins = tableRow.columnList[3].column.plugins as CountPlugin;
    plugins.max = +remainingQuantity + +quantity.value;

    this.tableResponseList[i][1].setRecordList(recordList);

    this.sowPaymentTermList.controls.forEach((paymentFormGroup: FormGroup) => {
      (
        paymentFormGroup.get('sowPaymentTermServiceItemList') as FormArray
      ).controls[j]
        .get('remainingQuantity')
        .patchValue(remainingQuantity.toFixed(2));
    });
  }

  public onInputGoodsQuantity(
    paymentGoodsItem: FormGroup,
    j: number,
    i: number,
    payment: FormGroup
  ): void {
    const quantity = paymentGoodsItem.controls.quantity; // quantity yang di inputkan
    let quantityPrItem = this.sowPaymentTermLists
      ? this.sowPaymentTermLists['sowPaymentTermGoodsItemList'][j]
          .remainingQuantity
      : paymentGoodsItem.controls.prItemReleased.value.quantity; // total pr item dari remaining kalau ada, kalau tidak dari quantity item released
    let remainingValue = paymentGoodsItem.get('remainingQuantity').value;
    if (this.todo !== 'add' && !this.isAddMore) {
      // ketika edit maka remaining value di ambil dari remaning quanitity item tsb
      remainingValue = this.sowEdit.sowPaymentTermList
        ? this.sowEdit.sowPaymentTermList[i]['sowPaymentTermGoodsItemList'][j]
            .remainingQuantity
        : remainingValue;
      let oldQuantity = 0;

      this.sowEdit.sowPaymentTermList.forEach(payment => {
        oldQuantity =
          oldQuantity + +payment['sowPaymentTermGoodsItemList'][j].quantity;
      }); // oldQuantity adalah quantity yang sebelum quantiy tsb di ubah
      quantityPrItem = +oldQuantity + +remainingValue;
    } else if (this.todo !== 'add' && this.isAddMore) {
      const remainingValue =
        +this.sowPaymentTermLists['sowPaymentTermGoodsItemList'][j]
          .remainingQuantity;
      // remaining value diambil dari hasil penjumlahan remaining sebelum di ubah2
      let oldQuantity = 0;
      this.sowEdit.sowPaymentTermList.forEach(payment => {
        oldQuantity =
          oldQuantity + +payment['sowPaymentTermGoodsItemList'][j].quantity;
      });
      quantityPrItem = +oldQuantity + +remainingValue;
      // oldQuantity adalah quantity yang sebelum quantiy tsb di ubah
    }
    const totalQuantity = this.getTotalQuantityProduct(j);
    if (+quantity.value < 0) {
      // kondisi saat button valuenya (-)
      quantity.setValidators([Validators.min(0)]);
      quantity.setErrors({
        message: this.translateService.instant('app.validation.min')
      });
      quantity.markAsTouched({ onlySelf: true });
      quantity.updateValueAndValidity();
    } else {
      if (+totalQuantity > +quantityPrItem.toFixed(2)) {
        // kondisi saat total quantity melebihi total quantity seharusnya
        quantity.setValidators([
          Validators.max(
            +remainingValue === null ? +quantityPrItem : +remainingValue
          )
        ]);
        quantity.setErrors({
          message: this.translateService.instant('app.validation.max')
        });
        quantity.markAsTouched({ onlySelf: true });
        quantity.updateValueAndValidity();
      } else if (+paymentGoodsItem.value.deliveredTotal > +quantity.value) {
        quantity.setValidators([
          Validators.min(+paymentGoodsItem.value.deliveredTotal),
          Validators.required()
        ]);
        quantity.setErrors({
          message: this.translateService.instant(
            'app-popup-sow.validation.minDelivered'
          )
        });
        quantity.markAsTouched({ onlySelf: true });
      } else {
        this.sowPaymentTermList.controls.forEach(
          (paymentFormGroup: FormGroup) => {
            (
              paymentFormGroup.get('sowPaymentTermGoodsItemList') as FormArray
            ).controls[j]
              .get('quantity')
              .clearValidators();

            (
              paymentFormGroup.get('sowPaymentTermGoodsItemList') as FormArray
            ).controls[j]
              .get('quantity')
              .updateValueAndValidity();
          }
        );
        quantity.clearValidators();
        quantity.updateValueAndValidity();
      }
      const remainingQuantity = +quantityPrItem - +totalQuantity;
      if (remainingQuantity || remainingQuantity !== null) {
        this.sowPaymentTermList.controls.forEach(
          (paymentFormGroup: FormGroup) => {
            (
              paymentFormGroup.get('sowPaymentTermGoodsItemList') as FormArray
            ).controls[j]
              .get('remainingQuantity')
              .patchValue(+remainingQuantity.toFixed(2));
          }
        );
      } else if (+remainingQuantity === 0) {
        quantity.setValidators([
          Validators.max(
            +remainingValue === null ? +quantityPrItem : +remainingValue
          )
        ]);
        quantity.markAsTouched({ onlySelf: true });
        quantity.updateValueAndValidity();
      }
    }
    const billingMethod = payment.controls.billingMethod.value;
    if (
      billingMethod?.code === this.global.appConstant.pm.BILLING_METHOD_PARTIAL
    ) {
      this.onInputAmountAndPercentage(payment);
    }
  }

  public getTotalQuantityProduct(j: number): number {
    let totalQuantity = 0;
    this.sowPaymentTermList.controls.forEach((paymentFormGroup: FormGroup) => {
      totalQuantity =
        totalQuantity +
        +(
          paymentFormGroup.get('sowPaymentTermGoodsItemList') as FormArray
        ).controls[j].get('quantity').value;
    });
    return totalQuantity;
  }

  public onInputQuantityService(
    paymentItemService: FormGroup,
    j: number,
    i: number,
    payment: FormGroup
  ): void {
    const quantity = paymentItemService.controls.quantity;
    let remainingValue = paymentItemService.get('remainingQuantity').value;
    let total = this.sowPaymentTermLists
      ? this.sowPaymentTermLists['sowPaymentTermServiceItemList'][j]
          .remainingQuantity
      : 100;
    if (this.todo !== 'add' && !this.isAddMore) {
      remainingValue = this.sowEdit.sowPaymentTermList[i]
        ? this.sowEdit.sowPaymentTermList[i]['sowPaymentTermServiceItemList'][j]
            .remainingQuantity
        : remainingValue;

      let oldQuantity = 0;
      this.sowEdit.sowPaymentTermList.forEach(payment => {
        oldQuantity =
          oldQuantity + +payment['sowPaymentTermServiceItemList'][j].quantity;
      });
      total = +oldQuantity + +remainingValue;
    } else if (this.todo !== 'add' && this.isAddMore) {
      const remainingValue =
        +this.sowPaymentTermLists['sowPaymentTermServiceItemList'][j]
          .remainingQuantity;
      let oldQuantity = 0;
      this.sowEdit.sowPaymentTermList.forEach(payment => {
        oldQuantity =
          oldQuantity + +payment['sowPaymentTermServiceItemList'][j].quantity;
      });
      total = +oldQuantity + +remainingValue;
    }
    const totalQuantity = this.getTotalQuantityService(j);
    if (+totalQuantity > +total) {
      quantity.setValidators([Validators.max(+remainingValue)]);
      quantity.setErrors({
        max: true,
        message: this.translateService.instant('app.validation.max')
      });
      quantity.updateValueAndValidity();
    } else {
      this.sowPaymentTermList.controls.forEach(
        (paymentFormGroup: FormGroup) => {
          (
            paymentFormGroup.get('sowPaymentTermServiceItemList') as FormArray
          ).controls[j]
            .get('quantity')
            .clearValidators();

          (
            paymentFormGroup.get('sowPaymentTermServiceItemList') as FormArray
          ).controls[j]
            .get('quantity')
            .updateValueAndValidity();
        }
      );
      quantity.clearValidators();
      quantity.updateValueAndValidity();
    }
    const remainingQuantity = +total - +totalQuantity;
    this.sowPaymentTermList.controls.forEach((paymentFormGroup: FormGroup) => {
      (
        paymentFormGroup.get('sowPaymentTermServiceItemList') as FormArray
      ).controls[j]
        .get('remainingQuantity')
        .patchValue(remainingQuantity.toFixed(2));
    });
    const billingMethod = payment.controls.billingMethod.value;
    if (
      billingMethod?.code === this.global.appConstant.pm.BILLING_METHOD_PARTIAL
    ) {
      this.onInputAmountAndPercentage(payment);
    }
  }

  public getTotalQuantityService(j: number): number {
    let totalQuantity = 0;
    this.sowPaymentTermList.controls.forEach((paymentFormGroup: FormGroup) => {
      totalQuantity =
        totalQuantity +
        +(
          paymentFormGroup.get('sowPaymentTermServiceItemList') as FormArray
        ).controls[j].get('quantity').value;
    });
    return totalQuantity;
  }

  public doSetprocurementSowPaymentItemList(): void {
    this.sowPaymentTermList.controls.forEach(sowPaymentTerm => {
      sowPaymentTerm.value.sowPaymentTermGoodsItemList.forEach(
        paymentTermGoods => {
          sowPaymentTerm.value.sowPaymentTermItemList.push(paymentTermGoods);
        }
      );
      sowPaymentTerm.value.sowPaymentTermServiceItemList.forEach(
        itemService => {
          sowPaymentTerm.value.sowPaymentTermItemList.push(itemService);
        }
      );
    });
  }

  public doSetRemainingQuantity(): void {
    const sowPaymentTermGoodsItemList =
      this.sowPaymentTermList.controls[0].value.sowPaymentTermGoodsItemList;
    const sowPaymentTermServiceItemList =
      this.sowPaymentTermList.controls[0].value.sowPaymentTermServiceItemList;
    const sowPaymentTermItemList =
      this.sowPaymentTermList.controls[0].value.sowPaymentTermItemList;
    if (this.sowList) {
      sowPaymentTermGoodsItemList.forEach(paymentProduct => {
        this.sowList.forEach(procurementSow => {
          procurementSow.sowPaymentTermList.forEach(sowPayment => {
            sowPayment['sowPaymentTermGoodsItemList'].forEach(
              paymentTermGoods => {
                if (
                  paymentProduct.prItemReleased.id ===
                  paymentTermGoods.prItemReleased.id
                ) {
                  paymentTermGoods.remainingQuantity =
                    paymentProduct.remainingQuantity;
                }
              }
            );
          });
        });
      });
      sowPaymentTermServiceItemList.forEach(paymentProduct => {
        this.sowList.forEach(procurementSow => {
          procurementSow.sowPaymentTermList.forEach(sowPayment => {
            sowPayment['sowPaymentTermServiceItemList'].forEach(itemService => {
              if (
                paymentProduct.prItemReleased.id ===
                itemService.prItemReleased.id
              ) {
                itemService.remainingQuantity =
                  paymentProduct.remainingQuantity;
              }
            });
          });
        });
      });
      sowPaymentTermItemList.forEach(paymentTermItem => {
        this.sowList.forEach(procurementSow => {
          procurementSow.sowPaymentTermList.forEach(sowPayment => {
            sowPayment.sowPaymentTermItemList.forEach(sowPaymentTermItem => {
              if (
                paymentTermItem.prItemReleased.id ===
                sowPaymentTermItem.prItemReleased.id
              ) {
                sowPaymentTermItem.remainingQuantity =
                  paymentTermItem.remainingQuantity;
              }
            });
          });
        });
      });
    }
  }

  public validatorPaymentType(): boolean {
    if (
      this.formGroup.get('sowPaymentType').value.code ===
      this.global.appConstant.pm.SOW_PAYMENT_TYPE_GRADUAL_PAYMENT_WITH_DP
    ) {
      if (
        this.sowPaymentTermList.value.findIndex(
          sowPaymentTerm =>
            sowPaymentTerm.paymentTerm.code ===
            this.global.appConstant.cm.PAYMENT_TERM_DOWN_PAYMENT
        ) === -1
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  public doUpdateRemaingAmount(): void {
    if (this.sowList) {
      this.sowList.forEach(sow => {
        sow.sowPaymentTermList.forEach(sowPaymentTerm => {
          sowPaymentTerm.remainingAmount = this.remainingAmount;
        });
      });
    }
  }

  public doSave(): void {
    this.formGroup.markAllAsTouched();
    this.formGroup.markAsDirty();
    this.sowPaymentTermList.markAsDirty();
    this.sowPaymentTermList.markAllAsTouched();
    this.getRemainingAmount();
    let isRemainingQuantity = false;
    let isQuantity = false;
    this.sowPaymentTermList.controls.forEach((paymentFormGroup: FormGroup) => {
      const billingMethod = paymentFormGroup?.value?.billingMethod?.code;
      (
        paymentFormGroup.get('sowPaymentTermGoodsItemList') as FormArray
      ).controls.forEach(paymentTermGoods => {
        if (
          !this.remainingAmount &&
          +paymentTermGoods.value.remainingQuantity
        ) {
          isRemainingQuantity = true;
        }
        if (
          billingMethod?.value?.code ===
            this.global.appConstant.pm.BILLING_METHOD_PARTIAL &&
          paymentTermGoods.value.quanitity > 0
        ) {
          isQuantity = true;
        }
      });
    });
    this.sowPaymentTermList.controls.forEach((paymentFormGroup: FormGroup) => {
      const billingMethod = paymentFormGroup?.value?.billingMethod?.code;
      (
        paymentFormGroup.get('sowPaymentTermServiceItemList') as FormArray
      ).controls.forEach(itemService => {
        if (!this.remainingAmount && +itemService.value.remainingQuantity) {
          isRemainingQuantity = true;
        }
        if (
          billingMethod?.value?.code ===
            this.global.appConstant.pm.BILLING_METHOD_PARTIAL &&
          itemService.value.quanitity > 0
        ) {
          isQuantity = true;
        }
      });
    });

    this.validate();
    if (
      this.formGroup.valid &&
      !isRemainingQuantity &&
      this.validatorPaymentType()
    ) {
      let amount = 0;
      this.sowPaymentTermList.value.forEach(sowPaymentTerm => {
        if (sowPaymentTerm.amount) {
          amount = +amount + +sowPaymentTerm.amount.price;
        }
        sowPaymentTerm.amount = +sowPaymentTerm.amount.price;
      });
      this.formGroup.patchValue({
        amount,
        totalTermin: this.sowPaymentTermList.value.length,
        startDate: this.formGroup.value.date.from,
        endDate: this.formGroup.value.date.to
      });
      this.doSetprocurementSowPaymentItemList();
      this.doSetRemainingQuantity();
      this.doUpdateRemaingAmount();
      this.onChange.emit(this.formGroup.value);
    } else if (isRemainingQuantity) {
      this.global.alertService.showInfo(
        this.global.translateService.instant(
          'app-popup-sow.validation.remainingQuantity'
        ),
        '.main-row-popup-sow'
      );
    } else if (!this.validatorPaymentType()) {
      this.global.alertService.showInfo(
        this.global.translateService.instant('app-popup-sow.validation.minDP'),
        '.main-row-popup-sow'
      );
    } else if (!isQuantity) {
      this.global.alertService.showInfo(
        this.global.translateService.instant(
          'app-popup-sow.validation.quantity'
        ),
        '.main-row-popup-sow'
      );
    }
  }

  // save untuk tipe blanket order
  public doSaveBlanketOrder(): void {
    this.formGroup.markAllAsTouched();
    this.formGroup.markAsDirty();
    this.sowPaymentTermList.clear();
    this.validate();
    if (this.formGroup.valid) {
      this.formGroup.patchValue({
        totalTermin: 0,
        startDate: this.formGroup.value.date.from,
        endDate: this.formGroup.value.date.to
      });
      this.onChange.emit(this.formGroup.value);
    }
  }

  public doOnChangeBillingMethod(): void {
    const billingMethod = this.formGroup.get('billingMethod').value;
    this.sowPaymentTermList.controls.forEach((sowPaymentTerm: FormGroup) => {
      const percentageForm = sowPaymentTerm.get('percentage');
      const amountForm = sowPaymentTerm.get('amount');
      sowPaymentTerm.get('billingMethod').patchValue(billingMethod);
      if (
        billingMethod?.code ===
        this.global.appConstant.pm.BILLING_METHOD_PARTIAL
      ) {
        this.onInputAmountAndPercentage(sowPaymentTerm);
        percentageForm.setIsView(true);
        amountForm.setIsView(true);
      } else if (
        this.formGroup.get('sowPaymentType').value !==
        this.global.appConstant.pm.SOW_PAYMENT_TYPE_FULL_PAYMENT
      ) {
        percentageForm.setIsView(false);
        amountForm.setIsView(false);
      }
    });
  }

  public onInputAmountAndPercentage(payment: FormGroup): void {
    let totalUsedAmount = 0;
    payment.controls.sowPaymentTermGoodsItemList?.value.forEach(
      sowPaymentTermGoodsItem => {
        totalUsedAmount =
          totalUsedAmount +
          +(
            sowPaymentTermGoodsItem.quantity *
            sowPaymentTermGoodsItem.prItemReleased.price
          );
      }
    );
    payment.controls.sowPaymentTermServiceItemList?.value.forEach(
      sowPaymentTermServiceItem => {
        totalUsedAmount =
          totalUsedAmount +
          +(
            (sowPaymentTermServiceItem.quantity / 100) *
            sowPaymentTermServiceItem.prItemReleased.totalPrice
          );
      }
    );
    const amount = totalUsedAmount;
    const percentageValue =
      amount === undefined || amount === null
        ? 0
        : (+amount / +this.amount) * 100;
    const percentage = percentageValue;
    const percentageForm = payment.controls.percentage;
    const amountForm = payment.controls.amount;
    percentageForm.patchValue(+percentage);
    amountForm.patchValue({ price: +totalUsedAmount });
    this.getRemainingAmount();
    if (+this.remainingAmount.toFixed(2) < 0) {
      amountForm.setErrors({
        max: true,
        message: this.translateService.instant('app.validation.max')
      });
      amountForm.markAsDirty();
      amountForm.markAsTouched();
      this.remainingAmount = 0;
    }
    this.sowPaymentTermList.controls.forEach(sowPayment => {
      sowPayment.get('remainingAmount').patchValue(+this.remainingAmount);
    });
  }

  public setIsAssessmentDisabled(): void {
    const sowPaymentTerm = this.sowPaymentTermList?.controls[0] as FormGroup;
    if (sowPaymentTerm) {
      sowPaymentTerm.get('isAssessment').patchValue(false);
      sowPaymentTerm.get('isAssessment').disable();
      sowPaymentTerm.get('isAssessment').setIsView(true);
    }
  }

  public getIsPaid(sowPaymentTerm: SowPaymentTerm): boolean {
    if (sowPaymentTerm.deliveryStatus) {
      const isPaid = this.appPopupSowModelList.find(
        model => model?.sowPaymentTermId === sowPaymentTerm?.id
      )?.isPaid;
      return isPaid;
    }
  }

  public setConditionIsBlanketOrder(): void {
    const stageOfWork = this.formGroup.get('stageOfWork');
    stageOfWork.patchValue(
      this.stageOfWorkList.find(
        stageOfWork =>
          stageOfWork.code === this.global.appConstant.cm.STAGE_OF_WORK_STAGE_1
      )
    );
    stageOfWork.setIsView(true);

    const sowPaymentType = this.formGroup.get('sowPaymentType');
    sowPaymentType.patchValue(
      this.sowPaymentTypeList.find(
        sowPaymentType =>
          sowPaymentType.code ===
          this.global.appConstant.pm.SOW_PAYMENT_TYPE_AS_PER_ORDER
      )
    );
    sowPaymentType.setIsView(true);

    this.formGroup.get('amount').patchValue(this.amount);
    this.formGroup.get('totalTermin').patchValue(0);

    this.formGroup.get('billingMethod').clearValidators();
    this.formGroup.get('billingMethod').updateValueAndValidity();
    this.formGroup.get('billingMethod').markAllAsTouched();
  }
}
