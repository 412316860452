<div class="portal-contact">
  <div class="promise-container promise-container-lg">
    <app-card header="portal.menu.contactUs" [isLoading]="formLoading">
      <div class="row mb-5">
        <div class="col-12 col-md-6 map-wrapper mb-4">
          <app-map [model]="mapModel"></app-map>
        </div>
        <div class="col-12 col-md-6">
          <div [innerHtml]="contact"></div>
          <ul class="social-links">
            <li class="social-link-item" *ngFor="let social of socialList">
              <a [attr.href]="social.value" [attr.title]="social.name">
                <em
                  class="fab"
                  [ngClass]="socialIconData[social.name | lowercase]"
                  *ngIf="socialIconData[social.name | lowercase]"
                ></em>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </app-card>
  </div>
</div>
