import { Procurement } from '../../core/bean/procurement';
import { ProcurementVendor } from '../../core/bean/procurement-vendor';
import { QuotationItemReference } from '../../core/bean/quotation-item-reference';
import { AppOfficialReportModel } from '../../core/components/app-official-report/app-official-report-model';

export class VendorReferenceRequest {
  public isSubmit: boolean;
  public isProcurementFailed: boolean;
  public procurement: Procurement;
  public procurementVendor: ProcurementVendor;
  public appOfficialReportModel: AppOfficialReportModel;
  public quotationItemReferenceList: QuotationItemReference[] = [];
}
