import { BaseEntity } from '../base/base-entity';
import { Catalog } from '../bean/catalog';
import { Region } from '../bean/region';


export class CatalogShippingCostEntity extends BaseEntity {
    catalog: Catalog;
	region: Region;
	amount: number;
	deliveryEstimateDayTotal: number;
}
