import { BaseEntity } from '../base/base-entity';
import { Currency } from '../bean/currency';
import { File } from '../bean/file';
import { Quotation } from '../bean/quotation';
import { FileObject } from '../components/upload';

export class QuotationAdministrationEntity extends BaseEntity {
  fileObjectList: FileObject[] = [];

  quotation: Quotation = new Quotation();
  currency: Currency = new Currency();
  docFile: File = new File();
  letterNumber: string;
  startDate: Date;
  endDate: Date;
}
