<app-popup header="tab-rep-agent.detail.title" [isLoading]="formLoading">
  <form [formGroup]="formGroup" class="app-popup-tab-rep-agent">
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-rep-agent.form.popup.companyStatus' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ companyStatus }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-rep-agent.form.popup.dataSource' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ dataSource }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-rep-agent.form.popup.companyName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          vendorRepresentativeAgentHistory.companyName !== null
            ? vendorRepresentativeAgentHistory.companyName
            : '-'
        }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-rep-agent.form.popup.taxNumber' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          vendorRepresentativeAgentHistory.taxNumber !== null
            ? vendorRepresentativeAgentHistory.taxNumber
            : '-'
        }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-rep-agent.form.popup.email' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          vendorRepresentativeAgentHistory.email !== null
            ? vendorRepresentativeAgentHistory.email
            : '-'
        }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-rep-agent.form.popup.phone' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          vendorRepresentativeAgentHistory.phone !== null
            ? vendorRepresentativeAgentHistory.phoneRegion.callingCode +
              vendorRepresentativeAgentHistory.phone
            : '-'
        }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-rep-agent.form.popup.address' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          vendorRepresentativeAgentHistory.address !== null
            ? vendorRepresentativeAgentHistory.address
            : '-'
        }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-rep-agent.form.popup.coordinateLocation' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        {{
          vendorRepresentativeAgentHistory.coordinateLocation !== null
            ? vendorRepresentativeAgentHistory.coordinateLocation
            : '-'
        }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-rep-agent.form.popup.information' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          vendorRepresentativeAgentHistory.information !== null
            ? vendorRepresentativeAgentHistory.information
            : '-'
        }}
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          class="mr-1"
          color="SECONDARY"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.close' | translate }}</app-button
        >
      </div>
    </ng-template>
  </form>
</app-popup>
