import { EventEmitter } from '@angular/core';
import { PaginationModel } from '../../app-pagination/model/pagination-model';
import { TablePageModel } from '../../app-table/model/table-page-model';
import { TableCatalogRequestModel } from './table-catalog-request-model';
export class TableCatalogResponseModel<T> {
  public page: TablePageModel<T> = new TablePageModel();
  stringUrl: string;
  isLoading: boolean;
  pagination: PaginationModel = new PaginationModel();
  tableRequest: TableCatalogRequestModel = new TableCatalogRequestModel();
  filterOptions: Map<string, any> = new Map();
  valueChanges: EventEmitter<T[]> = new EventEmitter();
  reloadChanges: EventEmitter<boolean> = new EventEmitter();
  constructor(
    stringUrl: string,
    perPage = 10
  ) {
    this.stringUrl = stringUrl;
    this.tableRequest.rows = perPage;
    this.pagination.page = 1;
    this.pagination.perPage = perPage;
    this.pagination.totalRecords = 100;
  }

  setPageRecords(records: T[]): void {
    this.page.records = records;
    this.page.totalRecords = records.length;
    this.valueChanges.emit(records);
  }

  getPageRecords(): T[] {
    return this.page.records;
  }

  setStateLoading(): void {
    this.isLoading = true;
  }

  setStateReady(): void {
    this.isLoading = false;
  }

  reload(): void {
    this.reloadChanges.emit(true);
  }

  setCustomData(customData: any): void {
    this.tableRequest.setCustomData(customData);
    // this.reload();
  }

  setSearchString(strSearch: string): void {
    this.tableRequest.globalFilter = strSearch;
    // this.reload();
  }

  setPerPage(perPage: number): void {
    this.tableRequest.rows = +perPage;
    this.pagination.perPage = +perPage;
    this.setCurrentPage(this.pagination.page);
  }

  setTotalRecords(totalRecords: number): void {
    this.pagination.totalRecords = totalRecords;
  }

  setSortField(sortField: string): void {
    this.tableRequest.sortField = sortField;
    // this.reload();
  }

  setCurrentPage(page: number): void {
    this.tableRequest.first = (page - 1) * this.pagination.perPage;
    this.reload();
  }
}
