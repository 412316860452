<div class="catalog-tab">
  <nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist" [ngStyle]="{ padding: '0 2rem' }">
      <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab"
        aria-controls="nav-home" aria-selected="true">{{ 'view-catalog.tab.general' | translate }}</a>
      <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab"
        aria-controls="nav-profile" aria-selected="false">{{ 'view-catalog.tab.specification' | translate }}</a>
      <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab"
        aria-controls="nav-contact" aria-selected="false">{{ 'view-catalog.tab.companyProfile' | translate }}</a>
    </div>
  </nav>
  <div class="tab-content border-top-0" id="nav-tabContent" [ngStyle]="{ border: '1px solid #e0e0e0' }">
    <!-- TAB GENERAL -->
    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
      <div class="tab-content-item">
        <div class="tab-content-item-label" [ngStyle]="{ 'min-width': '200px' }">{{ 'view-catalog.tabGeneral.name' |
          translate }}</div>
        <div class="tab-content-item-value">{{
          model.catalogBeans.catalog.name
          }}</div>
      </div>
      <div class="tab-content-item">
        <div class="tab-content-item-label" [ngStyle]="{ 'min-width': '200px' }">{{
          'view-catalog.tabGeneral.description' | translate }}</div>
        <div class="tab-content-item-value" [ngStyle]="{
            'white-space': 'normal',
            'max-width': '600px',
            'word-break': 'break-word'
          }" [innerHTML]="model.catalogBeans.catalog.description || '-'"></div>
      </div>
      <div class="tab-content-item">
        <div class="tab-content-item-label" [ngStyle]="{ 'min-width': '200px' }">{{ 'view-catalog.tabGeneral.category' |
          translate }}</div>
        <div class="tab-content-item-value">{{
          setCategories(model.catalogBeans.catalogCategoryList)
          }}</div>
      </div>
      <div class="tab-content-item">
        <div class="tab-content-item-label" [ngStyle]="{ 'min-width': '200px' }">{{ 'view-catalog.tabGeneral.uom' |
          translate }}</div>
        <div class="tab-content-item-value">{{
          model.catalogBeans.catalog.catalogUom?.name
          }}</div>
      </div>
    </div>

    <!-- TAB SPECIFICATION -->
    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
      <div class="tab-content-item" *ngFor="
          let data of model.catalogBeans.catalogSpesificationList;
          let i = index
        ">
        <div class="tab-content-item-label">{{
          'dynamic-attribute.' + data.catalogAttribute.translationKey.key
          | translate
          }}</div>
        <!-- WYSIWYG -->
        <div *ngIf="data.catalogAttribute.formObject.code === 'WYSIWYG'" [innerHTML]="doParseValue(data)" [ngStyle]="{
            'white-space': 'normal',
            'max-width': '600px',
            'word-break': 'break-word'
          }" class="tab-content-item-value"></div>
        <!-- DATE-PICKER -->
        <div *ngIf="data.catalogAttribute.formObject.code === 'DATE-PICKER'" class="tab-content-item-value">
          {{
          doConvertToDate(data.value)
          | date: global.config.parameterModel.dateFormatShort
          }}</div>
        <!-- TOGGLE-SWITCH -->
        <div *ngIf="data.catalogAttribute.formObject.code === 'TOGGLE-SWITCH'" class="tab-content-item-value">
          {{
          (data.value === 'true' ? 'view-catalog.yes' : 'view-catalog.no')
          | translate
          }}</div>
        <!-- ELSE -->
        <div *ngIf="
            data.catalogAttribute.formObject.code !== 'WYSIWYG' &&
            data.catalogAttribute.formObject.code !== 'DATE-PICKER' &&
            data.catalogAttribute.formObject.code !== 'TOGGLE-SWITCH'
          " class="tab-content-item-value" [ngStyle]="{
            'white-space': 'normal',
            'max-width': '600px',
            'word-break': 'break-word'
          }">{{ doParseValue(data) }}</div>
      </div>
    </div>

    <!-- TAB COMPANY PROFILE -->
    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
      <div class="tab-content-item">
        <div class="tab-content-item-label">{{
          'view-catalog.tabCompanyProfile.name' | translate
          }}</div>
        <div class="tab-content-item-value">{{
          model.catalogBeans.catalog.vendor?.vendorEntity.name +
          ' ' +
          model.catalogBeans.catalog.vendor?.name
          }}</div>
      </div>
      <div class="tab-content-item">
        <div class="tab-content-item-label">{{
          'view-catalog.tabCompanyProfile.location' | translate
          }}</div>
        <div class="tab-content-item-value">{{
          model.vendorAddress.countryRegion?.name +
          ' > ' +
          model.vendorAddress.provinceRegion?.name +
          ' > ' +
          model.vendorAddress.cityRegion?.name
          }}</div>
      </div>
      <div class="tab-content-item">
        <div class="tab-content-item-label">{{
          'view-catalog.tabCompanyProfile.segmentations' | translate
          }}</div>
        <div class="tab-content-item-value">{{
          setSegmentations(model.vendorSegmentationList)
          }}</div>
      </div>
      <div class="tab-content-item">
        <div class="tab-content-item-label">{{
          'view-catalog.tabCompanyProfile.fb' | translate
          }}</div>
        <div class="tab-content-item-value">{{
          model.catalogBeans.catalog.vendor?.facebook || '-'
          }}</div>
      </div>
      <div class="tab-content-item">
        <div class="tab-content-item-label">{{
          'view-catalog.tabCompanyProfile.ig' | translate
          }}</div>
        <div class="tab-content-item-value">{{
          model.catalogBeans.catalog.vendor?.ig || '-'
          }}</div>
      </div>
      <div class="tab-content-item">
        <div class="tab-content-item-label">{{
          'view-catalog.tabCompanyProfile.twitter' | translate
          }}</div>
        <div class="tab-content-item-value">{{
          model.catalogBeans.catalog.vendor?.twitter || '-'
          }}</div>
      </div>
      <div class="tab-content-item">
        <div class="tab-content-item-label">{{
          'view-catalog.tabCompanyProfile.website' | translate
          }}</div>
        <div class="tab-content-item-value">{{
          model.catalogBeans.catalog.vendor?.website || '-'
          }}</div>
      </div>
    </div>
  </div>
</div>