import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppApprovalPrcsXModule } from '../../core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppDeliverableItemModule } from '../../core/components/app-deliverable-item/app-deliverable-item.module';
import { AppPopupModule } from '../../core/components/app-popup/app-popup.module';
import { AppVendorPaymentInformationModule } from '../../core/components/app-vendor-payment-information/app-vendor-payment-information.module';
import { AppWorkflowStepInfoModule } from '../../core/components/app-workflow-step-info/app-workflow-step-info.module';
import { SharedModule } from '../../core/shared/shared.module';
import { PaymentVoucherEditComponent } from './payment-voucher-edit.component';
import { PaymentVoucherPopupOtherCostComponent } from './payment-voucher-popup-other-cost.component';
import { PaymentVoucherPopupTaxComponent } from './payment-voucher-popup-tax.component';
import { PaymentVoucherComponent } from './payment-voucher.component';

const routes: Routes = [
  {
    path: '',
    component: PaymentVoucherComponent,
    data: {
      breadcrumb: ''
    }
  },
  {
    path: 'detail',
    component: PaymentVoucherEditComponent,
    data: {
      breadcrumb: 'payment-voucher.breadcrumb.detail'
    }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppPopupModule,
    AppDeliverableItemModule,
    AppVendorPaymentInformationModule,
    AppWorkflowStepInfoModule,
    AppApprovalPrcsXModule
  ],
  declarations: [
    PaymentVoucherComponent,
    PaymentVoucherEditComponent,
    PaymentVoucherPopupTaxComponent,
    PaymentVoucherPopupOtherCostComponent
  ],
  entryComponents: [
    PaymentVoucherPopupTaxComponent,
    PaymentVoucherPopupOtherCostComponent
  ],
  exports: [
    PaymentVoucherPopupTaxComponent,
    PaymentVoucherPopupOtherCostComponent
  ]
})
export class PaymentVoucherModule {}
