import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppCardModule } from '../../core/components/app-card/app-card.module';
import { VendorModule } from '../../core/vendor/vendor.module';
import { AboutComponent } from './about.component';
export const routes = [
  { path: '', component: AboutComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [VendorModule, RouterModule.forChild(routes), AppCardModule],
  declarations: [AboutComponent]
})
export class AboutModule {}
