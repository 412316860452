import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupUserComponent } from './app-popup-user.component';
import { AppPopupUserService } from './app-popup-user.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppTableModule],
  declarations: [AppPopupUserComponent],
  entryComponents: [AppPopupUserComponent],
  providers: [AppPopupUserService],
  exports: [AppPopupUserComponent]
})
export class AppPopupUserModule {}
