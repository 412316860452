import { Uom } from 'src/app/core/bean/uom';
import { Item } from '../../core/bean/item';
import { ItemCategory } from '../../core/bean/item-category';
import { ItemType } from '../../core/bean/item-type';

export class ItemResponse {

    item: Item;
    itemTypeList: ItemType[];
    categoryList: ItemCategory[];
    uomList: Uom[];
    isNonActive: boolean;
}