import { Component, EventEmitter, Input, Output, } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";
import { BaseComponentComponent } from "src/app/core/base/angular/base-component.component";
import { Contract } from "src/app/core/bean/contract";
import { ContractCancelation } from "src/app/core/bean/contract-cancelation";
import { ContractCancelationDoc } from "src/app/core/bean/contract-cancelation-doc";
import { FileUploader } from "src/app/core/components/upload";
import { Response } from "src/app/core/model/response-model";
import { ResponseStatusModel } from "src/app/core/model/response-status-model";
import { Validators } from "src/app/core/validators";
import { ContractRequestXRequest } from "./contract-request-x.request";
import { ContractRequestXResponse } from "./contract-request-x.response";

@Component({
    templateUrl: './app-popup-contract-request-cancel-contract.component.html'
  })
export class AppPopupContractRequestCancelContractComponent extends BaseComponentComponent {

    @Input() public contract: Contract = new Contract();
    @Input() public contractCancelation: ContractCancelation = new ContractCancelation();
    @Input() public contractCancelationDoc: ContractCancelationDoc = new ContractCancelationDoc();
    @Output() public onChange: EventEmitter<any> = new EventEmitter();

    public currentDate: Date = new Date();
    public fileUploader: FileUploader = new FileUploader(
        '/contract-request/',
        'contract-request.title',
        this.global.appConstant.fileType.DOC_CONTRACT_CANCELED
      );
    public contractRequestRequest: ContractRequestXRequest =
    new ContractRequestXRequest();

    constructor(
        public translateService: TranslateService,
        public activeModal: NgbActiveModal
      ) {
        super('contract-request');
      }

      onInit(): void {
        this.buildFormGroup();
        this.setFormGroup();
      }
    
    
      public buildFormGroup(): void {
        this.formGroup = this.formBuilder.group({
          id: [null],
          fileDoc: [null, Validators.required()],
          note: [null, Validators.required()],
          cancelDate: this.currentDate,
          stage: this.contract.workflowModelPrcs.module.name
        });
      }
    
      public setFormGroup(): void {
        if (this.formGroup.isView) {
            if (this.contractCancelationDoc !== null) {
                this.fileUploader.setFile(
                  this.contractCancelationDoc.file
                );
            } 
            this.formGroup.patchValue({
                id: this.contractCancelation.id,
                fileDoc: this.fileUploader.fileObjectList,
                cancelDate: this.contractCancelation.cancelDate,
                stage: this.contractCancelation.module.name,
                note: this.contractCancelation.note
            });
        }
        this.setStateReady();
      }


      public doSave(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.markAsDirty();
        this.validate();
        if (this.formGroup.valid) {
            const prompt =
            '<div>' +
            this.translateService.instant(
              'contract-request.confirmation.cancelContract.prompt'
            ) +
            '</div></br>';
            this.global.modalService
            .confirmation(
                prompt,
                'contract-request.confirmation.cancelContract.title',
                'app.button.no',
                'app.button.yes',
                'pvc pv-file-circle-check-alt',
                false
              )
            .pipe(take(1))
            .subscribe(result => {
              if (result) {
                this.formGroup.disable();
                this.setStateProcessing();
                this.contractRequestRequest.contractCancelation =
                  this.formGroup.value;
                this.contractRequestRequest.contractCancelation.contract =
                  this.contract;
                this.contractRequestRequest.fileList =
                  this.formGroup.value.fileDoc;
                this.httpClientService
                  .post<Response<ContractRequestXResponse>>(
                    '/contract-request/cancelX',
                    this.contractRequestRequest
                  )
                  .subscribe(response => {
                    if (response.status === ResponseStatusModel.OK) {
                      this.activeModal.close();
                      this.global.alertService.showSuccessSavingOnNextRoute();
                      this.router.navigate(['/pages/contract-request/']);
                    } else {
                      this.global.alertService.showError(response.statusText);
                    }
                  });
              }
            });
        }
      }
}