import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { ActiveDropDown } from './active-drop-down.service';

@Component({
  selector: 'app-drop-down',
  templateUrl: './app-drop-down.component.html',
  styleUrls: ['./app-drop-down.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppDropDownComponent implements OnInit {
  @Input() dropDownContent: TemplateRef<any>;
  @Input() isShowArrow: boolean;
  @Input() parentElementWidth: number;

  private isClickInsideDropDown: boolean;

  constructor(
    private activeDropDown: ActiveDropDown,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    const embeddedViewRef = this.viewContainerRef.createEmbeddedView(
      this.dropDownContent
    );
    embeddedViewRef.detectChanges();
    this.elementRef.nativeElement.children
      .item(0)
      .children.item(0)
      .appendChild(embeddedViewRef.rootNodes[0]);
  }

  @HostListener('click')
  onClick(): void {
    this.isClickInsideDropDown = true;
  }

  @HostListener('window:click')
  onWindowClick(): void {
    if (!this.isClickInsideDropDown) {
      this.activeDropDown.close();
    }
    this.isClickInsideDropDown = false;
  }
}
