import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { TabModel } from '../../../core/model/tab/tab-model';
import { TabResponseModel } from '../../../core/model/tab/tab-response-model';
@Component({
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.scss']
})
export class EmployeeDetailComponent extends BaseModuleComponent {
  public tabs: Array<any>;
  public tabResponse: TabResponseModel;

  constructor(translate: TranslateService) {
    super('employee-detail', translate);
  }

  onInit(): void {
    this.setTabsData();
    this.buildTabResponse();
    this.setRouterParams();
  }

  public setTabsData(): void {
    this.tabs = [
      { code: 'TABONE', name: 'Employee', routeUrl: '' },
      { code: 'TABTWO', name: 'Addtional Information', routeUrl: 'addtional-information' },
      { code: 'TABTHREE', name: 'Review', routeUrl: 'review' }
    ];
  }

  public buildTabResponse(): void {
    this.tabResponse = TabResponseModel.create(this.tabs, this.router.url);
  }

  public setRouterParams(): void {
    this.global.routerParams.set('tabResponse', this.tabResponse);
  }

  public doNext(tab: TabModel): void {
    this.tabResponse.setIsSubmitted();
    if (this.tabResponse.valid) {
      this.tabResponse.setCurrentTab(tab);
      this.router.navigate([tab.routeUrl]);
    }
  }

  public navigateToEmployee(): void {
    this.router.navigate(['/pages/employee-detail']);
  }

  public doBack(tab: TabModel): void {
    this.tabResponse.setCurrentTab(tab);
    this.router.navigate([tab.routeUrl]);
  }

  public doSave(): void {
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.log.info('Successfully to save into database');
          this.log.info(this.global.routerParams['tabResponse']);
        }
      });
  }
}
