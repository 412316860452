import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { Currency } from '../../core/bean/currency';
import { ItemType } from '../../core/bean/item-type';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { ProcurementVendor } from '../../core/bean/procurement-vendor';
import { Quotation } from '../../core/bean/quotation';
import { QuotationCriteria } from '../../core/bean/quotation-criteria';
import { QuotationItem } from '../../core/bean/quotation-item';
import { QuotationStatus } from '../../core/bean/quotation-status';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { TabModel } from '../../core/model/tab/tab-model';
import { TabResponseModel } from '../../core/model/tab/tab-response-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { SubmitQuotationVendorPopupSelectItemComponent } from './submit-quotation-vendor-popup-select-item.component';
import { SubmitQuotationVendorRequest } from './submit-quotation-vendor.request';
import { SubmitQuotationVendorResponse } from './submit-quotation-vendor.response';

@Component({
  templateUrl: './submit-quotation-vendor-edit.component.html'
})
export class SubmitQuotationVendorEditComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  @ViewChild('selectorProposalCriteria')
  tableProposalCriteria: AppTableXComponent;
  @ViewChild('selectorVendor')
  tableVendor: AppTableXComponent;

  public stage: number;
  public procurementId: number;
  public procurementVendorId: number;
  public quotationId: number;
  public urlBackOutside: string;
  public CURRENCY_DIGITS_INFO: string;
  public currentDate: Date = new Date();
  public isShowBadgeStatus: boolean;
  public endDate: Date;

  public tabs: Array<any>;
  public tabModel: TabResponseModel;
  public quotation: Quotation = new Quotation();
  public procurement: Procurement = new Procurement();
  public quotationStatus: QuotationStatus = new QuotationStatus();
  public submitQuotationRequest: SubmitQuotationVendorRequest =
    new SubmitQuotationVendorRequest();
  public submitQuotationResponse: SubmitQuotationVendorResponse =
    new SubmitQuotationVendorResponse();
  public canceledOrFailedMessage: string;
  public procurementItemList: ProcurementItem[] = [];
  public isSubmit = false;
  public tableResponseItem: TableResponseModel<any>;
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    false
  );
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );

  public fileUploader: FileUploader = new FileUploader(
    '/rfp/',
    'rfp.form.file',
    'DOC_RFP_TEMPLATE',
    false,
    5
  );
  public fileUploaderResponse: FileUploader = new FileUploader(
    '/submit-quotation-vendor/',
    'submit-quotation-vendor.form.file',
    'DOC_RFP_RESPONSE',
    false,
    5
  );

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public currency: CurrencyPipe,
    public snackbarService: SnackbarService
  ) {
    super('submit-quotation-vendor', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setFormGroup();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public doSetDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementVendorId =
      localStorage.getItem('procurementVendorId') ||
      this.global.routerParams.get('procurementVendorId');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.quotationId =
      localStorage.getItem('quotationId') ||
      this.global.routerParams.get('quotationId');
    this.quotationStatus = this.global.routerParams.get('quotationStatus');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');

    // start set quotationStatus dari proc history
    let quotationStatusTemp = localStorage.getItem('quotationStatus') as Object;
    if (quotationStatusTemp) {
      quotationStatusTemp = JSON.parse(quotationStatusTemp.toString());
      this.quotationStatus = quotationStatusTemp as QuotationStatus;
      localStorage.removeItem('quotationStatus');
    }
    // end set doc quotationStatus dari proc history
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('procurementVendorId');
    localStorage.removeItem('urlBackOutside');
    localStorage.removeItem('quotationId');

    this.global.routerParams.set('todo', this.todo);
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.formGroup.get('amount').setIsView(true);
      this.formGroup.get('goodsAmount').setIsView(true);
      this.formGroup.get('serviceAmount').setIsView(true);
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      itemType: [null],
      amount: [null],
      goodsAmount: [null],
      serviceAmount: [null]
    });
    this.setIsViewOnlyFormGroup();
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<SubmitQuotationVendorResponse>(
        '/submit-quotation-vendor/add-edit',
        new RouteRequestModel(this.todo, this.procurementVendorId)
      )
      .subscribe((submitQuotationResponse: SubmitQuotationVendorResponse) => {
        this.endDate = new Date(
          submitQuotationResponse.procurementSchedule.endDate
        );
        this.submitQuotationResponse = submitQuotationResponse;
        this.quotation = submitQuotationResponse.quotation;
        this.procurement = submitQuotationResponse.procurement;

        this.global.routerParams.set(
          'submitQuotationResponse',
          this.submitQuotationResponse
        );

        if (
          this.procurement.documentSubmissionType?.code ===
          this.global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE
        ) {
          if (
            this.procurement.workflowModelPrcs.code.includes(
              'SUBMIT-QUOTATION-2'
            )
          ) {
            this.stage = 2;
          } else if (
            this.procurement.workflowModelPrcs.code.includes('SUBMIT-QUOTATION')
          ) {
            this.stage = 1;
          }
        }
        if (this.quotation) {
          const { amount, goodsAmount, serviceAmount } = this.quotation;
          this.formGroup.patchValue({
            amount,
            goodsAmount,
            serviceAmount
          });

          if (this.formGroup.value.amount) {
            const minBidBondValue =
              (+this.global.config.parameterModel.minBidBondValue *
                +this.formGroup.value.amount) /
              100;
            this.global.routerParams.set('minBidBondValue', minBidBondValue);
          }
          this.global.routerParams.set('amount', this.formGroup.value.amount);
        }

        if (this.submitQuotationResponse.quotationItemList) {
          this.submitQuotationResponse.quotationItemList.forEach(
            (quotationItem: any) => {
              const price = {
                price: quotationItem.price,
                currency: this.submitQuotationResponse.currencyList[0]
              };
              const totalPrice = {
                price: quotationItem.totalPrice,
                currency: this.submitQuotationResponse.currencyList[0]
              };
              quotationItem.totalPrice = totalPrice;
              quotationItem.price = price;
            }
          );
        }

        this.itemTypeOptionList.setRequestValues(
          this.submitQuotationResponse.itemTypeList
        );
        this.currencyOptionList.setRequestValues(
          this.submitQuotationResponse.currencyList
        );
        if (
          !(
            this.procurement.vendorSelectionType?.code === 'MULTI_WINNER' &&
            this.todo === 'add'
          ) &&
          !(
            this.procurement.documentSubmissionType?.code ==
              this.global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE &&
            this.stage === 1
          )
        ) {
          this.tableResponseItem.setRecordList(
            this.submitQuotationResponse.quotationItemList
          );
          this.submitQuotationRequest.quotationItemList =
            this.submitQuotationResponse.quotationItemList;
        }

        this.setTabsData();
        this.buildTabResponse();
        if (
          this.submitQuotationResponse.procurement?.documentSubmissionMethod
            ?.code ===
          this.global.appConstant.pm.DOCUMENT_SUBMISSION_METHOD_STAGE_2
        ) {
          this.tabModel.setCurrentTab(this.tabModel.getTabByCode('TABTHREE'));
          this.router.navigate([this.router.url + '/quotation']);
        }

        const statusList = [
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
        ];

        if (statusList.includes(this.procurement.procurementStatus.code)) {
          if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'submit-quotation-vendor.alert.msg.sorryWaitingCancelationApproval';
          } else if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'submit-quotation-vendor.alert.msg.sorryWaitingFailedApproval';
          } else {
            this.canceledOrFailedMessage =
              'submit-quotation-vendor.alert.msg.sorryFailedAssessment';
          }
        }
        this.setStateReady();
      });
  }

  public setTabsData(): void {
    if (
      this.procurement.documentSubmissionType.code !==
      this.global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE
    ) {
      this.tabs = [
        {
          code: 'TABONE',
          name: 'Administration Criteria',
          routeUrl: ''
        },
        {
          code: 'TABTWO',
          name: 'Technical Criteria',
          routeUrl: 'technical-criteria'
        },
        {
          code: 'TABTHREE',
          name: 'Quotation',
          routeUrl: 'quotation'
        }
      ];
    } else {
      if (this.stage === 1) {
        this.tabs = [
          {
            code: 'TABONE',
            name: 'Administration Criteria',
            routeUrl: ''
          },
          {
            code: 'TABTWO',
            name: 'Technical Criteria',
            routeUrl: 'technical-criteria'
          }
        ];
      } else {
        this.tabs = [
          {
            code: 'TABONE',
            name: 'Administration Criteria',
            routeUrl: ''
          },
          {
            code: 'TABTWO',
            name: 'Technical Criteria',
            routeUrl: 'technical-criteria'
          },
          {
            code: 'TABTHREE',
            name: 'Quotation',
            routeUrl: 'quotation'
          }
        ];
      }
    }
  }

  public buildTabResponse(): void {
    this.tabModel = TabResponseModel.create(this.tabs, this.router.url);
    this.global.routerParams.set('tabResponse', this.tabModel);
  }

  public doNext(tab: TabModel): void {
    this.tabModel.setCurrentTab(tab);
    this.router.navigate([tab.routeUrl]);
  }

  public buildTableResponse(): void {
    if (this.todo === 'view') {
      this.tableResponseItem = new TableResponseModel(this.moduleCode, [
        {
          field: 'procurementItem.prItem.pr.code',
          header: 'table.column.prNumber',
          plugins: { name: 'hyperlink', onClick: this.doViewItem.bind(this) }
        },
        {
          field: 'procurementItem.prItem.code',
          header: 'table.column.prLine'
        },
        {
          field: 'procurementItem.prItem.pr.requiredDate',
          header: 'table.column.requiredDate',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'procurementItem.prItem.item.name',
          header: 'table.column.itemName'
        },
        {
          field: 'procurementItem.prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'procurementItem.prItem.item.itemType.code'
          }
        },
        {
          field: 'procurementItem.prItem.quantity',
          header: 'table.column.quantity',
          className: 'text-right',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            isView: true
          }
        },
        {
          field: 'price',
          header: 'table.column.price',
          className: ' text-right',
          plugins: [
            {
              name: 'currency',
              optionList: this.currencyOptionList,
              isView: true
            }
          ]
        },
        {
          field: 'totalPrice',
          header: 'table.column.totalBidPrice',
          className: ' text-right',
          plugins: [
            {
              name: 'currency',
              optionList: this.currencyOptionList,
              isView: true
            }
          ]
        }
      ]);
    } else {
      this.tableResponseItem = new TableResponseModel(this.moduleCode, [
        {
          field: 'procurementItem.prItem.pr.code',
          header: 'table.column.prNumber',
          plugins: { name: 'hyperlink', onClick: this.doViewItem.bind(this) }
        },
        {
          field: 'procurementItem.prItem.code',
          header: 'table.column.prLine'
        },
        {
          field: 'procurementItem.prItem.pr.requiredDate',
          header: 'table.column.requiredDate',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'procurementItem.prItem.item.name',
          header: 'table.column.itemName'
        },
        {
          field: 'procurementItem.prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'procurementItem.prItem.item.itemType.code'
          }
        },
        {
          field: 'procurementItem.prItem.quantity',
          header: 'table.column.quantity',
          className: 'text-right',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            isView: true
          }
        },
        {
          field: 'price',
          header: 'table.column.price',
          className: ' text-right',
          plugins: [
            {
              name: 'currency',
              optionList: this.currencyOptionList,
              onInput: this.onInputPrice.bind(this),
              onChange: this.onInputPrice.bind(this),
              validators: Validators.required()
            }
          ]
        },
        {
          field: 'totalPrice',
          header: 'table.column.totalBidPrice',
          className: ' text-right',
          plugins: [
            {
              name: 'currency',
              optionList: this.currencyOptionList,
              isView: true
            }
          ]
        }
      ]);
    }
  }

  public doViewItem(quotationItem: QuotationItem): void {
    this.appPopupPrItemInfoService.open(
      quotationItem.procurementItem.prItem.id,
      false
    );
  }

  public onChangeItem(): void {
    const itemType = this.formGroup.value.itemType;
    if (itemType) {
      const quotationItemList = [];
      this.submitQuotationResponse.quotationItemList.forEach(
        (quotationItem: QuotationItem) => {
          if (
            quotationItem.procurementItem.prItem.item.itemType.id ===
            itemType.id
          ) {
            quotationItemList.push(quotationItem);
          }
        }
      );
      this.tableResponseItem.setRecordList(quotationItemList);
    } else {
      this.tableResponseItem.setRecordList(
        this.submitQuotationResponse.quotationItemList
      );
    }
    this.tableResponseItem.reload();
    this.doSetAmount();
  }

  public doSelectItem(): void {
    let procurementItemSelect = [];
    if (
      this.submitQuotationResponse.quotationItemList &&
      this.submitQuotationResponse.quotationItemList.length > 0
    ) {
      procurementItemSelect = (
        this.submitQuotationResponse.quotationItemList as Array<QuotationItem>
      ).map(quoItem => quoItem.procurementItem);
    }

    this.appPopupService
      .open(SubmitQuotationVendorPopupSelectItemComponent, {
        procurementItemSelect,
        procurementItemList: this.submitQuotationResponse.procurementItemList
      })
      .subscribe(response => {
        this.submitQuotationResponse.quotationItemList = [];
        response.forEach(procurementItem => {
          const quotationItem: QuotationItem = new QuotationItem();
          quotationItem.procurementItem = procurementItem;
          if (
            this.submitQuotationRequest.quotationItemList &&
            this.submitQuotationRequest.quotationItemList.length > 0
          ) {
            const oldQuotationItem =
              this.submitQuotationRequest.quotationItemList.find(
                element => element.procurementItem.id === procurementItem.id
              );
            if (oldQuotationItem) {
              quotationItem.price = oldQuotationItem.price;
              quotationItem.totalPrice = oldQuotationItem.totalPrice;
              quotationItem.id = oldQuotationItem.id;
            }
          }
          this.submitQuotationResponse.quotationItemList.push(quotationItem);
        });

        this.tableResponseItem.setRecordList(
          this.submitQuotationResponse.quotationItemList
        );
        this.submitQuotationRequest.quotationItemList =
          this.submitQuotationResponse.quotationItemList;
        this.tableResponseItem.reload();
        this.doSetAmount();
      });
  }

  public doDeleteItem(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach(record => {
            const indexOfRecord = this.tableResponseItem
              .getRecordList()
              .findIndex(
                r =>
                  (r.prItem ? r.prItem.id : r.procurementItem.prItem.id) ===
                  (record.prItem
                    ? record.prItem.id
                    : record.procurementItem.prItem.id)
              );
            this.tableResponseItem.getRecordList().splice(indexOfRecord, 1);
          });
          this.tableResponseItem.reload();
          this.doSetAmount();
        }
      });
  }

  public doSetAmount(): void {
    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;

    this.submitQuotationRequest.quotationItemList.forEach(
      (quotationItem: QuotationItem) => {
        const totalPrice =
          quotationItem.totalPrice && quotationItem.totalPrice['price']
            ? quotationItem.totalPrice['price']
            : 0;
        if (
          quotationItem.procurementItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
        ) {
          goodsAmountTotal = +goodsAmountTotal + +totalPrice;
        }
        if (
          quotationItem.procurementItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
        ) {
          serviceAmountTotal = +serviceAmountTotal + +totalPrice;
        }
      }
    );
    this.formGroup.patchValue({
      goodsAmount: goodsAmountTotal,
      serviceAmount: serviceAmountTotal,
      amount: +goodsAmountTotal + +serviceAmountTotal
    });

    if (this.formGroup.value.amount) {
      const minBidBondValue =
        (+this.global.config.parameterModel.minBidBondValue *
          +this.formGroup.value.amount) /
        100;
      this.global.routerParams.set('minBidBondValue', minBidBondValue);
    }
    this.global.routerParams.set('amount', this.formGroup.value.amount);
  }

  public onInputPrice(event): void {
    const currentRowChange = this.tableResponseItem.currentRowChange;
    this.submitQuotationRequest.quotationItemList.forEach((element: any) => {
      if (
        element.procurementItem.id ===
        currentRowChange.row.record.procurementItem.id
      ) {
        element.price = event;
        element.totalPrice = {
          price: element.price
            ? +element.procurementItem.prItem.quantity * +element.price.price
            : 0,
          currency: element.price
            ? element.price.currency
            : this.currencyOptionList.getRequestValues()[0]
        };
        currentRowChange.row.formGroup
          .get('totalPrice')
          .patchValue(element.totalPrice);
      }
    });

    this.doSetAmount();
  }

  public doEditQuotation(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('procurementId', this.procurementId);
    this.global.routerParams.set('quotationId', this.quotationId);
    this.global.routerParams.set(
      'procurementVendorId',
      this.procurementVendorId
    );
    this.global.routerParams.set('quotationStatus', this.quotationStatus);
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/submit-quotation-vendor'
    );
    this.router.navigate(['/pages/submit-quotation-vendor/edit']);
    this.global.routerParams.set('todo', 'edit');
  }

  public doViewDetailVendor(): void {
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set(
      'vendorId',
      this.submitQuotationResponse.vendorId
    );
    this.global.routerParams.set('urlBackOutside', this.router.url);
    this.global.routerParams.set('isVendorList', true);
    if (
      this.global.userSession.activeUserRole.role.type ===
      this.global.appConstant.core.ROLE_TYPE_VENDOR
    ) {
      this.global.routerParams.set('isFromVendor', true);
    }
    // let data = {};
    // data = JSON.stringify(this.temp);
    // localStorage.setItem('temp', data as string);
    // localStorage.setItem('todo', this.todo);
    this.router.navigate(['/pages/company-profile']);
  }

  public doSave(): void {
    this.validate();
    let isValidStage2 = true;
    let isValidItemRequest = true;
    if (
      this.stage === 2 ||
      this.procurement.documentSubmissionType?.code !==
        this.global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE
    ) {
      isValidStage2 =
        this.global.routerParams.get('quotationBidBond') ||
        this.submitQuotationResponse.quotationBidBond
          ? (this.global.routerParams.get('quotationBidBond') &&
              this.global.routerParams.get('isBidBondCompleted')) ||
            (this.submitQuotationResponse.quotationBidBond &&
              this.submitQuotationResponse.quotationBidBond.amount &&
              +this.global.routerParams.get('minBidBondValue') &&
              +this.submitQuotationResponse.quotationBidBond.amount >=
                +this.global.routerParams.get('minBidBondValue'))
          : true;

      isValidItemRequest =
        this.submitQuotationRequest.quotationItemList &&
        this.submitQuotationRequest.quotationItemList.length > 0 &&
        this.submitQuotationRequest.quotationItemList.filter(
          element =>
            !element.price || (element.price && !element.price['price'])
        ).length === 0;
    }
    if (isValidItemRequest) {
      if (this.formGroup.valid && isValidStage2) {
        this.submitQuotationRequest.fileObjectList =
          this.fileUploaderResponse.fileObjectList;
        this.submitQuotationRequest.isSubmit = false;
        this.submitQuotationRequest.quotationItemList.forEach(element => {
          element.price = element.price ? +element.price['price'] : 0;
          element.totalPrice = element.totalPrice
            ? element.totalPrice['price']
            : 0;
        });

        const admQuotationCriteriaList =
          this.global.routerParams.get('admQuotationCriteria') ||
          this.submitQuotationResponse.admQuotationCriteriaList;
        const tecQuotationCriteriaList =
          this.global.routerParams.get('tecQuotationCriteria') ||
          this.submitQuotationResponse.tecQuotationCriteriaList;

        const quotation = this.quotation || new Quotation();
        quotation.amount = this.formGroup.value.amount;
        quotation.goodsAmount = this.formGroup.value.goodsAmount;
        quotation.serviceAmount = this.formGroup.value.serviceAmount;
        quotation.procurementVendor = new ProcurementVendor();

        this.submitQuotationRequest.quotation = quotation;
        this.submitQuotationRequest.quotation.procurementVendor.id =
          this.procurementVendorId;
        this.submitQuotationRequest.quotationCriteriaList =
          admQuotationCriteriaList.concat(tecQuotationCriteriaList);
        this.submitQuotationRequest.quotationAdministration =
          this.global.routerParams.get('quotationAdministration');
        this.submitQuotationRequest.quotationBidBond =
          this.global.routerParams.get('quotationBidBond');

        this.setStateProcessing();
        let url =
          this.quotationStatus.code ===
            this.global.appConstant.pm.QUOTATION_STATUS_DRAFT ||
          this.quotationStatus.code ===
            this.global.appConstant.pm.QUOTATION_STATUS_SUBMITTED
            ? '/submit-quotation-vendor/update'
            : '/submit-quotation-vendor/insert';
        if (
          this.procurement.documentSubmissionType?.code ===
            this.global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE &&
          this.stage === 1
        ) {
          url =
            this.quotationStatus.code ===
              this.global.appConstant.pm.QUOTATION_STATUS_DRAFT ||
            this.quotationStatus.code ===
              this.global.appConstant.pm.QUOTATION_STATUS_SUBMITTED
              ? '/submit-quotation-vendor/update-stage-1'
              : '/submit-quotation-vendor/insert-stage-1';
        } else if (
          this.procurement.documentSubmissionType?.code ===
            this.global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE &&
          this.stage === 2
        ) {
          url =
            this.quotationStatus.code ===
              this.global.appConstant.pm.QUOTATION_STATUS_DRAFT ||
            this.quotationStatus.code ===
              this.global.appConstant.pm.QUOTATION_STATUS_SUBMITTED
              ? '/submit-quotation-vendor/update-stage-2'
              : '/submit-quotation-vendor/insert-stage-2';
        }
        this.httpClientService
          .post<Response<Quotation>>(url, this.submitQuotationRequest)
          .subscribe(response => {
            if (response.status === ResponseStatusModel.OK) {
              this.snackbarService.showWarning('app.msg.info.sucsessSave');
              this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => {
                  this.global.routerParams.clear();
                  this.global.routerParams.set(
                    'procurementId',
                    this.procurementId
                  );
                  this.global.routerParams.set(
                    'procurementVendorId',
                    this.procurementVendorId
                  );
                  this.global.routerParams.set('quotationId', response.body.id);
                  this.global.routerParams.set(
                    'quotationStatus',
                    response.body.quotationStatus
                  );
                  this.global.routerParams.set(
                    'urlBackOutside',
                    '/pages/submit-quotation-vendor'
                  );
                  this.global.routerParams.set('todo', 'edit');
                  this.router.navigate(['/pages/submit-quotation-vendor/edit']);
                });
            } else {
              this.global.alertService.showError(response.statusText);
            }
            this.setStateReady();
          });
      } else {
        this.global.alertService.showInfo(
          'app-fullfilled.message.info.required',
          '.procurement-criteria-card'
        );
      }
    } else {
      this.global.alertService.showInfo(
        'app-fullfilled.message.info.required',
        '.item-request-card'
      );
    }
  }

  public doSubmit(): void {
    this.isSubmit = true;
    let isValidStage2 = true;
    let isValidItemRequest = true;

    const admCriteriaList: QuotationCriteria[] =
      this.global.routerParams.get('admQuotationCriteria') ||
      this.submitQuotationResponse.admQuotationCriteriaList;
    const tecCriteriaList: QuotationCriteria[] =
      this.global.routerParams.get('tecQuotationCriteria') ||
      this.submitQuotationResponse.tecQuotationCriteriaList;
    const isValidAdminTec =
      admCriteriaList &&
      admCriteriaList.length > 0 &&
      tecCriteriaList &&
      tecCriteriaList.length > 0 &&
      admCriteriaList.filter(a => a.quotationCriteriaStatus.code === 'WAITING')
        .length === 0 &&
      tecCriteriaList.filter(a => a.quotationCriteriaStatus.code === 'WAITING')
        .length === 0;
    if (
      this.stage === 2 ||
      this.procurement.documentSubmissionType?.code !==
        this.global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE
    ) {
      isValidStage2 =
        ((this.global.routerParams.get('quotationBidBond') &&
          this.global.routerParams.get('isBidBondCompleted')) ||
          (this.submitQuotationResponse.quotationBidBond &&
            this.submitQuotationResponse.quotationBidBond.amount &&
            +this.global.routerParams.get('minBidBondValue') &&
            +this.submitQuotationResponse.quotationBidBond.amount >=
              +this.global.routerParams.get('minBidBondValue'))) &&
        ((this.global.routerParams.get('quotationAdministration') &&
          this.global.routerParams.get('isAdministrationCompleted')) ||
          this.submitQuotationResponse.quotationAdministration);
      isValidItemRequest =
        this.submitQuotationRequest.quotationItemList &&
        this.submitQuotationRequest.quotationItemList.length > 0 &&
        this.submitQuotationRequest.quotationItemList.filter(
          element =>
            !element.price || (element.price && !element.price['price'])
        ).length === 0;
    }
    this.validate();
    if (isValidItemRequest) {
      if (this.formGroup.valid && isValidStage2 && isValidAdminTec) {
        this.submitQuotationRequest.fileObjectList =
          this.fileUploaderResponse.fileObjectList;
        this.submitQuotationRequest.isSubmit = true;
        this.submitQuotationRequest.isRevision =
          this.quotationStatus.code ===
          this.global.appConstant.pm.QUOTATION_STATUS_SUBMITTED
            ? true
            : false;
        this.submitQuotationRequest.quotationItemList.forEach(element => {
          element.price = element.price ? +element.price['price'] : 0;
          element.totalPrice = element.totalPrice
            ? element.totalPrice['price']
            : 0;
        });

        const admQuotationCriteriaList =
          this.global.routerParams.get('admQuotationCriteria') ||
          this.submitQuotationResponse.admQuotationCriteriaList;
        const tecQuotationCriteriaList =
          this.global.routerParams.get('tecQuotationCriteria') ||
          this.submitQuotationResponse.tecQuotationCriteriaList;

        const quotation = this.quotation || new Quotation();
        quotation.amount = this.formGroup.value.amount;
        quotation.goodsAmount = this.formGroup.value.goodsAmount;
        quotation.serviceAmount = this.formGroup.value.serviceAmount;
        quotation.procurementVendor = new ProcurementVendor();

        this.submitQuotationRequest.quotation = quotation;
        this.submitQuotationRequest.quotation.procurementVendor.id =
          this.procurementVendorId;
        this.submitQuotationRequest.quotationCriteriaList =
          admQuotationCriteriaList.concat(tecQuotationCriteriaList);
        this.submitQuotationRequest.quotationItemList =
          this.tableResponseItem.getRecordList();
        this.submitQuotationRequest.quotationAdministration =
          this.global.routerParams.get('quotationAdministration');
        this.submitQuotationRequest.quotationBidBond =
          this.global.routerParams.get('quotationBidBond');

        this.global.modalService
          .submitConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              let url =
                this.quotationStatus.code ===
                  this.global.appConstant.pm.QUOTATION_STATUS_DRAFT ||
                this.quotationStatus.code ===
                  this.global.appConstant.pm.QUOTATION_STATUS_SUBMITTED
                  ? '/submit-quotation-vendor/update'
                  : '/submit-quotation-vendor/insert';
              if (
                this.procurement.documentSubmissionType?.code ===
                  this.global.appConstant.pm
                    .DOCUMENT_SUBMISSION_TYPE_TWO_STAGE &&
                this.stage === 1
              ) {
                url =
                  this.quotationStatus.code ===
                    this.global.appConstant.pm.QUOTATION_STATUS_DRAFT ||
                  this.quotationStatus.code ===
                    this.global.appConstant.pm.QUOTATION_STATUS_SUBMITTED
                    ? '/submit-quotation-vendor/update-stage-1'
                    : '/submit-quotation-vendor/insert-stage-1';
              } else if (
                this.procurement.documentSubmissionType?.code ===
                  this.global.appConstant.pm
                    .DOCUMENT_SUBMISSION_TYPE_TWO_STAGE &&
                this.stage === 2
              ) {
                url =
                  this.quotationStatus.code ===
                    this.global.appConstant.pm.QUOTATION_STATUS_DRAFT ||
                  this.quotationStatus.code ===
                    this.global.appConstant.pm.QUOTATION_STATUS_SUBMITTED
                    ? '/submit-quotation-vendor/update-stage-2'
                    : '/submit-quotation-vendor/insert-stage-2';
              }
              this.httpClientService
                .post<Response<Quotation>>(url, this.submitQuotationRequest)
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.global.modalService
                      .submitSuccess()
                      .subscribe(result => {
                        if (result) {
                          this.router.navigate([
                            '/pages/submit-quotation-vendor'
                          ]);
                        } else {
                          this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(() => {
                              this.global.routerParams.clear();
                              this.global.routerParams.set(
                                'procurementId',
                                this.procurementId
                              );
                              this.global.routerParams.set(
                                'procurementVendorId',
                                this.procurementVendorId
                              );
                              this.global.routerParams.set(
                                'quotationId',
                                response.body.id
                              );
                              this.global.routerParams.set(
                                'quotationStatus',
                                response.body.quotationStatus
                              );
                              this.global.routerParams.set(
                                'urlBackOutside',
                                '/pages/worklist-pm'
                              );
                              this.global.routerParams.set(
                                'urlBackOutside',
                                '/pages/submit-quotation-vendor'
                              );
                              this.router.navigate([
                                '/pages/submit-quotation-vendor/detail'
                              ]);
                              this.global.routerParams.set('todo', 'view');
                            });
                        }
                      });
                  } else {
                    this.global.alertService.showError(response.statusText);
                  }
                  this.setStateReady();
                });
            }
          });
      } else {
        this.global.alertService.showInfo(
          'app-fullfilled.message.info.required',
          '.procurement-criteria-card'
        );
      }
    } else {
      this.global.alertService.showInfo(
        'app-fullfilled.message.info.required',
        '.item-request-card'
      );
    }
  }

  public checkDisableButtonSubmit(): boolean {
    let valueAdmQuotationCriteria = true;
    let valueTecQuotationCriteria = true;
    let valueQuotation = true;
    let valueItemRequest = true;
    if (
      this.global.routerParams.get('admQuotationCriteria') ||
      this.submitQuotationResponse.admQuotationCriteriaList
    ) {
      const admQuotationCriteriaList: QuotationCriteria[] =
        this.global.routerParams.get('admQuotationCriteria') ||
        this.submitQuotationResponse.admQuotationCriteriaList;
      if (
        admQuotationCriteriaList.filter(
          element =>
            element.quotationCriteriaStatus.code === 'COMPLETED' &&
            (element.docFile ||
              (element.fileObjectList &&
                element.fileObjectList.length > 0 &&
                !element.fileObjectList[0].isDeleted))
        ).length > 0
      ) {
        valueAdmQuotationCriteria = false;
      }
    }
    if (
      this.global.routerParams.get('tecQuotationCriteria') ||
      this.submitQuotationResponse.tecQuotationCriteriaList
    ) {
      const tecQuotationCriteriaList: QuotationCriteria[] =
        this.global.routerParams.get('tecQuotationCriteria') ||
        this.submitQuotationResponse.tecQuotationCriteriaList;
      if (
        tecQuotationCriteriaList.filter(
          element =>
            element.quotationCriteriaStatus.code === 'COMPLETED' &&
            (element.docFile ||
              (element.fileObjectList &&
                element.fileObjectList.length > 0 &&
                !element.fileObjectList[0].isDeleted))
        ).length > 0
      ) {
        valueTecQuotationCriteria = false;
      }
    }
    if (
      this.stage === 2 ||
      this.procurement.documentSubmissionType?.code !==
        this.global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE
    ) {
      if (this.submitQuotationRequest.quotationItemList?.length > 0) {
        if (
          this.submitQuotationRequest.quotationItemList.filter(
            element =>
              element.price && element.price['price'] && element.totalPrice
          ).length > 0
        ) {
          valueItemRequest = false;
        }
      }
      if (
        this.global.routerParams.get('isBidBondCompleted') ||
        this.global.routerParams.get('isAdministrationCompleted')
      ) {
        valueQuotation = false;
      } else {
        if (
          this.submitQuotationResponse.quotationAdministration ||
          (this.submitQuotationResponse.quotationBidBond &&
            this.submitQuotationResponse.quotationBidBond.amount &&
            +this.global.routerParams.get('minBidBondValue') &&
            this.submitQuotationResponse.quotationBidBond.amount >=
              +this.global.routerParams.get('minBidBondValue'))
        ) {
          valueQuotation = false;
        }
      }
    }

    return this.procurement.documentSubmissionType?.code !==
      this.global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE
      ? valueAdmQuotationCriteria &&
          valueTecQuotationCriteria &&
          valueQuotation &&
          valueItemRequest
      : this.stage === 1
      ? valueAdmQuotationCriteria && valueTecQuotationCriteria
      : valueQuotation && valueItemRequest;
  }
}
