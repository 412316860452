import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorPositionEditAddComponent } from './vendor-position-edit-add.component';
import { VendorPositionComponent } from './vendor-position.component';
export const routes = [
  { path: '', component: VendorPositionComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: VendorPositionEditAddComponent,
    data: { breadcrumb: 'vendor-position.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: VendorPositionEditAddComponent,
    data: { breadcrumb: 'vendor-position.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [VendorPositionComponent, VendorPositionEditAddComponent],
  providers: []
})
export class VendorPositionModule {}
