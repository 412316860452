import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { Social } from '../../../core/bean/social';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { Validators } from '../../../core/validators';
import { ContactResponse } from './contact-response';
@Component({
  templateUrl: './contact-edit-add.component.html'
})
export class ContactEditAddComponent extends BaseModuleComponent {
  public socialList: Social[] = [];
  public contactResponse: ContactResponse = new ContactResponse();
  constructor(translateService: TranslateService) {
    super('contact-us', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      socialList: this.formBuilder.array([]),
      contactList: this.formBuilder.array([]),
      location: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<ContactResponse>('/contact/add-edit')
      .subscribe(contactResponse => {
        this.contactResponse = contactResponse;
        this.socialList = contactResponse.socialList;
        const controlSocial = this.formGroup.controls.socialList as FormArray;
        const socialInActive: number[] = [];
        contactResponse.socialList.forEach((social, index) => {
          const formGroup: FormGroup = this.formBuilder.group({
            id: social.id,
            name: social.name,
            value: [social.value, Validators.compose([Validators.required()])],
            isActive: social.isActive
          });
          controlSocial.push(formGroup);
          if (!social.isActive) {
            socialInActive.push(index);
          }
        });

        const location = this.contactResponse.coordinateLocation.value;
        this.formGroup.patchValue({ location });
        const controlContact = this.formGroup.controls.contactList as FormArray;
        contactResponse.contactList.forEach(contact => {
          const formGroup: FormGroup = this.formBuilder.group({
            id: contact.id,
            lang: contact.lang,
            code: contact.code,
            key: contact.key,
            description: contact.description,
            value: contact.value
          });
          controlContact.push(formGroup);
        });
        this.setStateReady();

        socialInActive.forEach(index => {
          controlSocial.controls[index].get('value').disable();
        });
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const newContactResponse = new ContactResponse();
            newContactResponse.contactList =
              this.formGroup.get('contactList').value;
            newContactResponse.coordinateLocation =
              this.contactResponse.coordinateLocation;
            newContactResponse.coordinateLocation.value =
              this.formGroup.get('location').value;
            newContactResponse.socialList =
              this.formGroup.get('socialList').value;
            this.httpClientService
              .post<Response<ContactResponse>>(
                '/contact/update',
                newContactResponse
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/content/']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/content/']);
  }

  public onChangeDisabled(index: number, event: any): void {
    const controlSocial = this.formGroup.controls.socialList as FormArray;
    if (event) {
      controlSocial.controls[index].get('value').enable();
    } else {
      controlSocial.controls[index].get('value').disable();
    }
  }

  public get formArraySocialList(): FormArray {
    return this.formGroup.get('socialList') as FormArray;
  }
}
