import { NgModule } from '@angular/core';
import { AppButtonModule } from '../../../../../core/components/app-button/app-button.module';
import { AppChartModule } from '../../../../../core/components/app-chart/app-chart.module';
import { AppDropdownSelectModule } from '../../../../../core/components/app-dropdown-select/app-dropdown-select.module';
import { PipesModule } from '../../../../../core/pipe/pipe.module';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetTotalContractComponent } from './widget-total-contract.component';

@NgModule({
  imports: [
    VendorModule,
    WidgetCardModule,
    AppChartModule,
    AppButtonModule,
    AppDropdownSelectModule,
    PipesModule
  ],
  declarations: [WidgetTotalContractComponent],
  entryComponents: [WidgetTotalContractComponent],
  exports: [WidgetTotalContractComponent]
})
export class WidgetTotalContractModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetTotalContractComponent.moduleCode,
      WidgetTotalContractComponent,
      'w-50'
    );
  }
}
