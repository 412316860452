import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProcurementScopeWork } from 'src/app/core/bean/procurement-scope-work';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { RecapPrintService } from 'src/app/core/services/recap-print.service';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './performance-fulfillment-detail.component.html'
})
export class PerformanceFulfillmentDetailComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) table: AppTableComponent;

  public readonly urlRouterNavigateToEditContractFulfillment =
    '/pages/performance-fulfillment/detail/edit';

  public vendorId: number;
  public tableResponse: TableResponseModel<ProcurementScopeWork>;
  public isLoading: boolean;

  constructor(
    translateService: TranslateService,
    public recapPrintService: RecapPrintService
  ) {
    super('performance-fulfillment', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setDataFromRouterParams();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.vendorId = this.global.routerParams.get('vendorId');
    this.tableResponse.setCustomData(this.vendorId);
  }

  public buildTableResponse(): void {
    /* 0 = psw.id */
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: '1',
        header: 'table.column.agreementNumber'
      },
      {
        field: '4',
        header: 'table.column.agreementType'
      },
      {
        field: '5',
        header: 'table.column.rating',
        format: FieldFormatEnum.Rating
      }
    ]);
  }

  public doView(object: object): void {
    this.global.routerParams.clear();
    const objectName = object[3];
    const objectId = object[2];
    if (objectName === 'ContractBean') {
      // contract, called only if contract_id is not null
      this.isProgressAddendum(objectId, objectName);
    } else {
      this.doNavigate(objectId, objectName);
    }
  }

  public doNavigate(objectId: number, objectName: string): void {
    this.global.routerParams.set('todo', 'viewContract');
    this.global.routerParams.set('vendorId', this.vendorId);
    this.global.routerParams.set('objectId', objectId);
    this.global.routerParams.set('objectName', objectName);
    this.router.navigate([
      '/pages/performance-fulfillment/detail/detail-termin'
    ]);
  }

  public doExport(): void {
    this.isLoading = true;
    this.recapPrintService
      .print(
        '/performance-fulfillment/recap-contract',
        this.tableResponse.request
      )
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  public isProgressAddendum(objectId: number, objectName: string): void {
    // called only if contract_id is not null
    this.httpClientService
      .get<boolean>(
        '/performance-fulfillment/is-progress-addendum/' + objectId,
        {}
      )
      .subscribe((response: boolean) => {
        if (response) {
          // is_progress_addendum = true -> can't continue process
          this.global.alertService.showInfo(
            this.global.translateService.instant(
              'performance-fulfillment.alert.msg.isProgressAddendum'
            )
          );
        } else {
          this.doNavigate(objectId, objectName);
        }
      });
  }

  public doBack(): void {
    this.global.routerParams.clear();
    this.router.navigate(['/pages/performance-fulfillment']);
  }
}
