import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './attendance-form.component.html'
})
export class AttendanceFormComponent extends BaseModuleComponent implements OnInit {
  public encryptAanwijzingId: string;

  constructor(translateService: TranslateService, public activatedRoute: ActivatedRoute) {
    super('attendance-form', translateService);
  }

  public onInit(): void {
    this.getRouterParam();
    this.buildFormGroup();
  }

  public buildFormGroup(): void {
      this.formGroup = this.formBuilder.group({
        name: [null, Validators.required()]
      });
  }

  public getRouterParam(): void {

    this.activatedRoute.paramMap.subscribe(param => {
      this.encryptAanwijzingId = param.get('id');
    });

    this.httpClientService
      .get<any>('/attendance-form/add/' + this.encryptAanwijzingId, {})
      .subscribe(response => {
        this.log.debug(response);



        this.setStateReady();
      });
  }

}
