import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { QuotationItem } from '../../core/bean/quotation-item';
import { VendorQuotationView } from '../../core/view/entity/bean/vendor-quotation-view';

@Component({
  templateUrl: './vendor-reference-popup-confirm.component.html',
  styleUrls: ['./vendor-reference-popup-confirm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VendorReferencePopupConfirmComponent extends BaseComponentComponent {
  @Input() vendorWinnerList: VendorQuotationView[];
  @Input() procurementItemList: ProcurementItem[];
  @Input() quotationItemList: QuotationItem[];
  @Input() vendorSelectionTypeCode: string;
  @Output() onChange: EventEmitter<boolean> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {
    super('vendor-reference');
  }

  onInit(): void {
    this.setStateReady();
  }

  public onScrollend(): void {}

  doSubmit(): void {
    this.onChange.emit(true);
  }
}
