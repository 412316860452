import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { Contract } from '../bean/contract';
import { ContractPreparation } from '../bean/contract-preparation';
import { ContractStatus } from '../bean/contract-status';
import { ContractType } from '../bean/contract-type';
import { ContractWorklistStatus } from '../bean/contract-worklist-status';
import { DealingAgreementType } from '../bean/dealing-agreement-type';
import { Dr } from '../bean/dr';
import { File } from '../bean/file';
import { Order } from '../bean/order';
import { ProcurementVendor } from '../bean/procurement-vendor';
import { PurchaseRequest } from '../bean/purchase-request';
import { Vendor } from '../bean/vendor';
import { FileObject } from '../components/upload';
export class ContractEntity extends BaseWorkflowObjectEntity {
  fileObjectList: FileObject[];
  fileList: File[];
  purchaseRequestList: PurchaseRequest[];
  createdDate: Date = new Date();
  isVendorApproved: boolean;
  isContractWillExpired: boolean;
  isContractGuaranteeWillExpired: boolean;
  isFinishAssessed: boolean;

  vendor: Vendor = new Vendor();
  procurementVendor: ProcurementVendor = new ProcurementVendor();
  contractType: ContractType = new ContractType();
  contractStatus: ContractStatus = new ContractStatus();
  dealingAgreementType: DealingAgreementType = new DealingAgreementType();
  fromContract: Contract;
  stage: string;
  contractDate: Date;
  number: string;
  requestNumber: string;
  description: string;
  startDate: Date;
  endDate: Date;
  finalizationDate: Date;
  name: string;
  isManual: boolean;
  isCanceled: boolean;
  isClosed: boolean;
  isTerminated: boolean;
  isFinalized: boolean;
  isProgressAddendum: boolean;
  sequenceAmandemen: number;
  sequenceAddendum: number;
  requestDate: Date;
  title: string;
  contractPreparation: ContractPreparation = new ContractPreparation();
  order: Order = new Order();
  isNextProcessExist: boolean;
  amount: number;
  goodsAmount: number;
  serviceAmount: number;
  dr: Dr = new Dr();
  contractWorklistStatus: ContractWorklistStatus = new ContractWorklistStatus();
}
