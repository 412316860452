import { Component, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ContractReviewer } from '../../bean/contract-reviewer';
import { AppTableComponent } from '../app-table/app-table.component';
import { TableResponseModel } from '../app-table/model/table-response-model';
import { AppContractDraftViewResponse } from './app-contract-draft-view-response';
@Component({
  selector: 'app-contract-draft-view',
  templateUrl: './app-contract-draft-view.component.html'
})
export class AppContractDraftViewComponent extends BaseComponentComponent {
  @Input() public contractId: number;
  @ViewChild('selectorDraft') tableDraft: AppTableComponent;

  public tableResponseDraft: TableResponseModel<ContractReviewer>;

  public availableAmountSow: number;
  public draftDocument: File;

  constructor(public translateService: TranslateService) {
    super('app-contract-draft-view');
  }

  public onInit(): void {
    this.buildTableResponse();
    this.getDraftInformation();
  }

  public buildTableResponse(): void {
    this.tableResponseDraft = new TableResponseModel(this.moduleCode, [
      { field: 'userType', header: 'table.column.user' },
      { field: 'email', header: 'table.column.email' },
      { field: 'name', header: 'table.column.name' },
      { field: 'position', header: 'table.column.position' }
    ]);
  }

  public getDraftInformation(): void {
    this.httpClientService
      .get('/app-contract-draft-view/index/' + this.contractId)
      .subscribe((appContractDraftViewResponse: AppContractDraftViewResponse) => {
        if (appContractDraftViewResponse !== null) {
          this.draftDocument = appContractDraftViewResponse.contractDraftDoc;
          if (
            appContractDraftViewResponse.contractCollabAccessList !== null &&
            appContractDraftViewResponse.contractCollabAccessList.length > 0
          ) {
            const contractCollabList = [];
            appContractDraftViewResponse.contractCollabAccessList.forEach(
              (contractCollabAccess: any) => {
                (contractCollabAccess.userType =
                  contractCollabAccess.userType === 1
                    ? 'Internal'
                    : contractCollabAccess.userType === 2
                      ? 'Eksternal'
                      : 'PIC Vendor'),
                  contractCollabList.push(contractCollabAccess);
              }
            );
            this.tableResponseDraft.page.records = contractCollabList;
            this.tableResponseDraft.page.totalRecords =
              contractCollabList.length;
            this.tableResponseDraft.reloadClient();
          }
          this.setStateReady();
        }
      });
  }
}
