<app-popup header="budget-allocation.popup.export.title">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
            <div class="col-sm-9 text-left">
                <app-radio formControlName="reportType"
                    [optionList]="reportOptionList" position="VERTICAL">
                </app-radio>
            </div>
        </div>

        <ng-template #modalFooter let-activeModal>
            <div class="col-12 text-center">
                <app-button type="BTN_SECONDARY"
                    (onClick)="activeModal.close(true)" [disabled]='formSaving'
                    color="SECONDARY">
                    {{ 'app.button.close' | translate }}
                </app-button>
                <app-button type="BTN_PRIMARY" (onClick)="doGenerate()"
                    [disabled]='formSaving'>
                    {{ 'budget-allocation.button.generate' | translate }}
                </app-button>
            </div>
        </ng-template>
    </form>
</app-popup>