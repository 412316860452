<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="response-rfi.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <!--Awal General Information-->
        <h5 class="separator">{{
          'response-rfi.generalInformation' | translate
        }}</h5>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'response-rfi.form.title' | translate
          }}</label>
          <div class="col-7 text-left">
            <p>{{ responseRfiDTO.rfi && responseRfiDTO.rfi.title }} </p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'response-rfi.form.projectAmount' | translate
          }}</label>
          <div
            class="col-7 text-left"
            *ngIf="responseRfiDTO.rfi && responseRfiDTO.rfi.currency"
          >
            <p
              >{{
                responseRfiDTO.rfi &&
                  responseRfiDTO.rfi.currency &&
                  responseRfiDTO.rfi.currency.code
              }}
              {{
                responseRfiDTO.rfi && responseRfiDTO.rfi.projectAmountFrom
                  | number
              }}
              {{ 'response-rfi.form.until' | translate }}
              {{
                responseRfiDTO.rfi &&
                  responseRfiDTO.rfi.currency &&
                  responseRfiDTO.rfi.currency.code
              }}
              {{
                responseRfiDTO.rfi && responseRfiDTO.rfi.projectAmountTo
                  | number
              }}</p
            >
          </div>
          <div
            class="col-7 text-left"
            *ngIf="responseRfiDTO.rfi && !responseRfiDTO.rfi.currency"
          >
            <p>{{ 'response-rfi.form.undisclosed' | translate }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'response-rfi.form.responseDeadlineDate' | translate
          }}</label>
          <div class="col-sm-9 col-lg-7 text-left">
            <p>{{
              responseRfiDTO.rfi?.responseDeadlineDate
                | date: global.config.parameterModel.dateFormatShort
            }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'response-rfi.form.publishDate' | translate
          }}</label>
          <div class="col-7 text-left">
            <p>{{
              responseRfiDTO.rfi?.publishDate
                | date: global.config.parameterModel.dateFormatShort
            }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'response-rfi.form.templateRfi' | translate
          }}</label>
          <div class="col-7 text-left">
            <p
              ><a
                href="#"
                (click)="
                  doDownload(
                    $event,
                    '/request-for-information/file/view/' +
                      uploadedFileNameRfi +
                      '/' +
                      global.appConstant.fileType.DOC_RFI_TEMPLATE,
                    fileNameRfi
                  )
                "
              >
                {{ fileNameRfi }}
              </a>
            </p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'response-rfi.form.note' | translate
          }}</label>
          <div class="col-7 text-left">
            <p tooltip="Please download template RFI first">
              {{ responseRfiDTO.rfi && responseRfiDTO.rfi.note }}
            </p>
          </div>
        </div>
        <!--Akhir General Information-->

        <!--Awal Item-->
        <h5 class="separator">{{ 'response-rfi.item' | translate }}</h5>
        <div
          class="table-responsive custom-table float-none"
          [ngStyle]="{ margin: '0 auto' }"
        >
          <table
            class="table table-striped box-shadow"
            aria-describedby="Table"
          >
            <thead>
              <tr class="text-center">
                <th scope="col">{{ 'app.table.column.no' | translate }} </th>
                <th scope="col">{{ 'response-rfi.itemNeeded' | translate }}</th>
                <th scope="col">{{
                  'response-rfi.itemDescription' | translate
                }}</th>
                <th scope="col">{{ 'response-rfi.quantity' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="rfiItemList && rfiItemList.length == 0">
                <td colspan="99" class="bg-white text-center">
                  {{ 'app.info.noData' | translate }}</td
                >
              </tr>
              <tr *ngFor="let data of rfiItemList; let i = index">
                <td class="text-center">{{ i + 1 }}.</td>
                <td class="text-center"> {{ data.item }}</td>
                <td
                  class="text-left"
                  [ngStyle]="{
                    'white-space': 'normal',
                    'word-break': 'break-word'
                  }"
                >
                  {{ data.description }}
                </td>
                <td class="text-center"
                  >{{ data.quantity | number: '1.2-2' }} {{ data.uom.name }}</td
                >
              </tr>
            </tbody>
          </table>
        </div>
        <!--Akhir Item-->

        <br />
        <!--Awal Vendor Information-->
        <h5 class="separator">{{
          'response-rfi.vendorInformation' | translate
        }}</h5>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'response-rfi.form.vendorName' | translate
          }}</label>
          <div class="col-7 text-left">
            <p>{{ vendorName }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'response-rfi.form.vendorEmail' | translate
          }}</label>
          <div class="col-7 text-left">
            <p>{{ vendorEmail }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'response-rfi.form.vendorPhone' | translate
          }}</label>
          <div class="col-sm-9 col-lg-7 text-left">
            <p>{{ phoneRegion }}{{ phoneNumber }} </p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'response-rfi.form.vendorAddress' | translate
          }}</label>
          <div class="col-sm-9 col-lg-7 text-left">
            <p>{{
              responseRfiDTO.vendorAddress &&
                responseRfiDTO.vendorAddress.address
            }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'response-rfi.form.documentRfi' | translate
          }}</label>
          <div class="col-7 text-left">
            <p
              ><a
                href="#"
                (click)="
                  doDownload(
                    $event,
                    '/response-rfi/file/view/' +
                      uploadedFileNameRfiVendor +
                      '/' +
                      global.appConstant.fileType.DOC_RFI,
                    fileNameRfiVendor
                  )
                "
              >
                {{ fileNameRfiVendor }}</a
              >
            </p>
          </div>
        </div>
        <!--Akhir Vendor Information-->

        <div class="row justify-content-center mt-5">
          <app-button
            mode="BYPASS"
            color="SECONDARY"
            class="mr-1"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button
            mode="BYPASS"
            color="PRIMARY"
            *ngIf="
              responseRfiDTO?.rfiQuestionList &&
              responseRfiDTO?.rfiQuestionList.length > 0
            "
            (onClick)="doNext()"
          >
            {{ 'app.button.next' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
