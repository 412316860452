import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
export class NewsEntity extends BaseEntity {
  createdDate: Date;
  title: string;
  publishDate: Date;
  body: string;
  isActive: boolean;
  viewCount: number;
  imgFile: File;
}
