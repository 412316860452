<app-popup header="app-map.popup.title">
    <div class="app-map-popup">
        <app-map [model]="model"></app-map>
    </div>
    <ng-template #modalFooter let-activeModal>
        <div class="col-12 text-center">
            <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
                'app.button.close' | translate }}</app-button>
            <app-button (onClick)="doSave()" *ngIf="!model.isViewOnly">{{
                'app.button.save' | translate }}</app-button>
        </div>
    </ng-template>
</app-popup>