import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { QuotationItem } from 'src/app/core/bean/quotation-item';
import { TablePluginData } from '../../table/interface/table-plugin-data';

@Component({
    templateUrl: './app-popup-final-price-detail-field.component.html',
    styleUrls: ['./app-popup-final-price-detail-field.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppPopupFinalPriceDetailFieldComponent extends BaseComponentComponent {
    @Input() public tablePluginData: TablePluginData;
    public quotationItem: QuotationItem;

    public onInit(): void {
        this.setField();
    }

    constructor(public translateService: TranslateService) {
        super('app-popup-final-price-detail');
    }

    public setField(): void {
        this.quotationItem = this.tablePluginData.row.record as QuotationItem;
    }
}
