import { VendorReactivationRequest } from './vendor-reactivation-request';
import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorHistory } from '../../core/bean/vendor-history';
import { VendorReactivation } from '../../core/bean/vendor-reactivation';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { VendorReactivationResponse } from './vendor-reactivation-response';
@Component({
  templateUrl: './vendor-reactivation-edit-add.component.html'
})
export class VendorReactivationEditAddComponent extends BaseModuleComponent {
  public vendorHistoryId: number;
  public vendorReactivationResponse: VendorReactivationResponse = new VendorReactivationResponse();
  public vendorReactivationRequest: VendorReactivationRequest = new VendorReactivationRequest();
  public vendorReactivation: VendorReactivation = new VendorReactivation();
  public vendorHistory: VendorHistory = new VendorHistory();

  public fileUploader: FileUploader = new FileUploader(
    '/vendor-reactivation/',
    'vendorreactivation',
    this.global.appConstant.fileType.DOC_VENDOR_REACTIVATION
  );

  constructor(translateService: TranslateService) {
    super('vendor-reactivation', translateService);
    Object.assign(this, this.global.routerParams);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorHistoryId = this.global.routerParams.get('vendorHistoryId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [null],
      registrationNumber: [null],
      reason: [null, Validators.compose([Validators.required()])],
      file: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<VendorReactivationResponse>(
        '/vendor-reactivation/add-edit',
        new RouteRequestModel(this.todo, this.vendorHistoryId)
      )
      .subscribe(vendorReactivationResponse => {
        this.vendorReactivationResponse = vendorReactivationResponse;

        const {
          name,
          registrationNumber } = this.vendorReactivationResponse.vendorHistory;

        if (
          vendorReactivationResponse.vendorReactivation != null &&
          vendorReactivationResponse.vendorHistory.vendor.vendorActivityStatus
            .code === 'REVISION_REACTIVATION'
        ) {
          // put doc single upload
          this.fileUploader.setFile(
            this.vendorReactivationResponse.vendorReactivation.file
          );
          const { reason } = this.vendorReactivationResponse.vendorReactivation;

          this.formGroup.patchValue({
            reason,
            file: this.fileUploader.fileObjectList
          });
        }
        this.formGroup.patchValue({ name, registrationNumber });

        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            this.vendorReactivation.reason = this.formGroup.get('reason').value;
            this.vendorReactivationRequest.fileList = this.formGroup.get(
              'file'
            ).value;
            if (
              this.vendorReactivationRequest.fileList &&
              this.vendorReactivationRequest.fileList.length !== 0 &&
              !this.vendorReactivationRequest.fileList[0].isUploaded
            ) {
              this.vendorReactivationRequest.fileList = [];
            }
            this.vendorHistory.id = this.vendorHistoryId;

            this.vendorReactivationRequest.vendorReactivation = this.vendorReactivation;
            this.vendorReactivationRequest.vendorHistory = this.vendorHistory;

            const url =
              this.todo === 'edit'
                ? '/vendor-reactivation/update'
                : '/vendor-reactivation/insert';
            this.httpClientService
              .post<Response<VendorReactivation>>(
                url,
                this.vendorReactivationRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/vendor-reactivation']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/vendor-reactivation']);
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
}
