import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Global } from '../../global/global';

@Directive({
  selector: '[appCurrency]'
})
export class CurrencyDirective {
  constructor(public ngControl: NgControl, public global: Global) {}

  @HostListener('input', ['$event'])
  onInputChange(event: any): void {
    event.target.value = event.target.value.replace(/[^0-9\.]/g, '');
    event.target.value = this.replaceLastIndexOfDotAndLeaveOne(
      event.target.value.toString()
    );
    this.ngControl.control.patchValue(event.target.value);
  }

  @HostListener('focusin', ['$event'])
  onFocus(event: any): void {
    event.target.value = this.global.converterService.uConvertMoney(
      event.target.value
    );
  }

  @HostListener('focusout', ['$event'])
  onBlur(event: any): void {
    event.target.value = this.global.converterService.convertMoney(
      event.target.value
    );
  }

  public replaceLastIndexOfDotAndLeaveOne(s: string): string {
    const dotCount = s.match(/\./g);
    if (dotCount && dotCount.length === 1) {
      return s;
    }
    const pos = s.lastIndexOf('.');
    if (pos >= 0) {
      return s.substring(0, pos) + '' + s.substring(pos + 1);
    }
    return s;
  }
}
