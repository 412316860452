import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppPopupModule } from '../app-popup/app-popup.module';
import { AppImageSlideshowComponent } from './app-image-slideshow.component';
import { AppPopupImageSlideshowComponent } from './app-popup-image-slideshow.component';

@NgModule({
  imports: [VendorModule, AppPopupModule, DirectivesModule, PipesModule],
  declarations: [AppImageSlideshowComponent, AppPopupImageSlideshowComponent],
  exports: [AppImageSlideshowComponent, AppPopupImageSlideshowComponent],
  entryComponents: [AppPopupImageSlideshowComponent]
})
export class AppImageSlideshowModule {}
