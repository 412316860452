import { BaseEntity } from '../base/base-entity';
import { Procurement } from '../bean/procurement';
import { Segmentation } from '../bean/segmentation';

export class ProcurementSegEntity extends BaseEntity {
    crudOperation: number;
    segmentationName: string;

    procurement: Procurement;
    segmentation: Segmentation;
}
