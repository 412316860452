import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ModuleDependencyEditAddComponent } from './module-dependency-edit-add.component';
import { ModuleDependencyComponent } from './module-dependency.component';
export const routes = [
  { path: '', component: ModuleDependencyComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: ModuleDependencyEditAddComponent,
    data: { todo: 'edit', breadcrumb: 'module-dependency.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [ModuleDependencyComponent, ModuleDependencyEditAddComponent],
  providers: []
})
export class ModuleDependencyModule {}
