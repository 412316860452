import { CatalogAttribute } from 'src/app/core/bean/catalog-attribute';
import { CatalogAttributeGroup } from 'src/app/core/bean/catalog-attribute-group';
import { CatalogGroupTemplate } from 'src/app/core/bean/catalog-group-template';
import { CatalogSpecification } from 'src/app/core/bean/catalog-specification';
import { CatalogType } from 'src/app/core/bean/catalog-type';
import { Currency } from 'src/app/core/bean/currency';

export class AppCatalogInfoResponse {
    catalogSpesificationList: CatalogSpecification[] = [];
    catalogTypeList: CatalogType[] = [];
    currencyList: Currency[] = [];
    catalogAttributeGroupList: CatalogAttributeGroup[] = [];
    catalogAttributeList: CatalogAttribute[] = [];
    catalogGroupTemplateList: CatalogGroupTemplate[] = [];
    catalogAttributeGroup: CatalogAttributeGroup;
    catalogAttribute: CatalogAttribute;
    defaultSearchLocationJson: string;
    lengthCatalogSpesificationOld: number;
    lengthCatalogSpesificationNew: number;
}
