export class WidgetService {
  public availableWidgets: Map<string, any> = new Map();
  public availableWidgetNameList: Array<string> = new Array();
  public copyWidgetNameList: Array<string> = new Array();
  public dataMapTemporary: Map<string, any> = new Map();
  public isEdit = false;
  public register(widgetSelector: string, widget: any): void {
    this.availableWidgetNameList.push(widgetSelector);
    this.availableWidgets.set(widgetSelector, widget);
  }

  public setData(key: string, data: any): void {
    this.dataMapTemporary.set(key, data);
  }

  public getData(key: string): any {
    return this.dataMapTemporary.get(key);
  }

  public getWidgets(): Map<string, any> {
    return this.availableWidgets;
  }

  public getWidget(selector: string): any {
    return this.availableWidgets.get(selector);
  }

  public getWidgetNameList(): Array<string> {
    return this.availableWidgetNameList;
  }

  public setCopy(widgets: Array<string>): void {
    this.copyWidgetNameList = widgets;
  }

  public getCopy(): Array<string> {
    return this.copyWidgetNameList;
  }

  public clearCopy(): void {
    this.copyWidgetNameList = [];
  }
}
