import { BaseEntity } from "../base/base-entity";
import { Claim } from "../bean/claim";
import { RoomType } from "../bean/room-type";


export class ClaimRequestAmountEntity extends BaseEntity {
  doctor: number;
  medicine: number;
  medicalTools: number;
  administration: number;
  room: number;
  totalRequest: number;
  totalP3k: number;
  totalAmbulance: number;
  roomType: RoomType;
  claim: Claim;
  totalCompensationRequest: number;
}