import { BaseEntity } from '../base/base-entity';
import { Currency } from '../bean/currency';
import { Procurement } from '../bean/procurement';
import { ProcurementRegBuyerStatus } from '../bean/procurement-reg-buyer-status';
import { ProcurementRegType } from '../bean/procurement-reg-type';
import { ProcurementRegVendorStatus } from '../bean/procurement-reg-vendor-status';
import { User } from '../bean/user';
import { Vendor } from '../bean/vendor';
import { VendorOffline } from '../bean/vendor-offline';
import { WorkflowModelPrcs } from '../bean/workflow-model-prcs';

export class ProcurementVendorEntity extends BaseEntity {
  crudOperation: number;
  isProgressAddendum: boolean;

  vendor: Vendor = new Vendor();
  vendorOffline: VendorOffline = new VendorOffline();
  procurement: Procurement = new Procurement();
  userDelegator: User = new User();
  currency: Currency = new Currency();

  isAdditional: boolean;
  procurementRegType: ProcurementRegType;
  workflowModelPrcs: WorkflowModelPrcs;
  procurementRegVendorStatus: ProcurementRegVendorStatus;
  procurementRegBuyerStatus: ProcurementRegBuyerStatus;

  amount: number;
  isWinner: boolean;
  appointmentWinnerNumber: string;
  appointmentWinnerDate: Date;
  delegatorContractId: number;
  percentageValue: number;

  isRegistered: boolean;
  registeredDate: Date;
  lastReminderDate: Date;
  isPaid: boolean;
  isReceived: boolean;
  note: string;
}
