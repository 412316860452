import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Payment } from 'src/app/core/bean/payment';
import { PaymentProof } from 'src/app/core/bean/payment-proof';
import { FileUploader } from 'src/app/core/components/upload';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';
import { PaymentMonitoringRequest } from './payment-monitoring.request';
import { PaymentMonitoringResponse } from './payment-monitoring.response';

@Component({
  templateUrl:
    './app-popup-payment-monitoring-upload-payment-proof.component.html'
})
export class AppPopupPaymentMonitoringUploadPaymentProofComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() payment: Payment;
  @Input() paymentProof: PaymentProof;
  @Input() paymentMonitoringResponse: PaymentMonitoringResponse;
  @Output() public onChange: EventEmitter<PaymentProof> = new EventEmitter();
  public paymentMonitoringRequest: PaymentMonitoringRequest =
    new PaymentMonitoringRequest();

  public fileUploader: FileUploader = new FileUploader(
    '/payment-monitoring/',
    '',
    this.global.appConstant.fileType.DOC_PAYMENT_PROOF
  );

  constructor() {
    super('payment-monitoring');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      paymentFile: [null, Validators.required()],
      description: [null, Validators.compose([Validators.required()])]
    });
  }

  public setFormGroup(): void {
    if (this.paymentProof) {
      this.fileUploader.setFile(this.paymentProof.file);
      this.formGroup.patchValue({
        id: this.paymentProof.id,
        paymentFile: this.fileUploader.fileObjectList,
        description: this.paymentProof.description
      });
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.paymentMonitoringRequest.paymentProof = this.formGroup.value;
            if (
              this.formGroup.value.paymentFile[0].isChange ||
              this.formGroup.value.paymentFile[0].isNew
            ) {
              this.paymentMonitoringRequest.fileObjectList =
                this.formGroup.value.paymentFile;
            }
            this.paymentMonitoringRequest.paymentProof.payment = this.payment;
            this.paymentMonitoringRequest.paymentTaxProof = null;
            this.httpClientService
              .post<Response<Payment>>(
                '/payment-monitoring/update',
                this.paymentMonitoringRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.onChange.emit(this.formGroup.value);
                  this.global.alertService.showSuccess(
                    this.global.translateService.instant(
                      'app.alert.msg.saveSuccess'
                    )
                  );
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }
}
