import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { BudgetSource } from '../../core/bean/budget-source';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: 'budget-source.component.html'
})
export class BudgetSourceComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<BudgetSource>;
  constructor(translateService: TranslateService) {
    super('budget-source', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.column.code' },
      { field: 'name', header: 'table.column.name' }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/budget-source/add']);
  }

  public doEdit(budgetSource: BudgetSource): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('budgetSourceId', budgetSource.id);
    this.router.navigate(['/pages/budget-source/edit']);
  }

  public doDelete(budgetSource: BudgetSource): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.deleteBudgetSource(budgetSource);
        }
      });
  }

  public deleteBudgetSource(budgetSource: BudgetSource): void {
    this.httpClientService
      .post<Response<BudgetSource>>('/budget-source/delete', budgetSource)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccessDelete();
          this.tableResponse.reload();
        } else {
          this.global.alertService.showError(response.statusText);
        }
      });
  }

  public doPrint(): void {
    // ... do some logic here
  }
}
