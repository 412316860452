import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from '../../core/shared/shared.module';
import { TabMarketingHistoryComponent } from './tab-marketing-history.component';
import { TabMarketingComponent } from './tab-marketing.component';
@NgModule({
  imports: [SharedModule],
  declarations: [TabMarketingComponent, TabMarketingHistoryComponent],
  entryComponents: [TabMarketingComponent, TabMarketingHistoryComponent],
  exports: [TabMarketingComponent, TabMarketingHistoryComponent]
})
export class TabMarketingModule {
  constructor(accordionService: AccordionService) {
    accordionService.register('tab-marketing', TabMarketingComponent);
    accordionService.register(
      'tab-marketing-history',
      TabMarketingHistoryComponent
    );
  }
}
