import { BaseEntity } from '../base/base-entity';
import { ReportRecap } from '../bean/report-recap';
import { User } from '../bean/user';

export class ReportBuilderEntity extends BaseEntity {
  public reportRecap: ReportRecap;
  public processedByUser: User;
  public name: string;
  public description: string;
  public generatedTableResponse: string;
}
