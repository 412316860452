<app-popup header="app-popup-analysis-single.title" [isLoading]="formLoading">
  <div *ngIf="!formLoading">
    <div class="form-group row text-left">
      <label class="col-sm-3 control-label">{{
        'app-popup-analysis-single.form.vendorName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ procurementVendor.vendor.name }}
      </div>
    </div>
    <div class="form-group row text-left">
      <label class="col-sm-3 control-label">{{
        'app-popup-analysis-single.form.procurementValue' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          global.converterService.convertMoney(
            procurementVendor.procurement.amount
          )
        }}
        IDR
      </div>
    </div>
  </div>
  <h4 class="separator"></h4>
  <app-table-xx
    *ngIf="!formLoading"
    [model]="tableResponse"
    stringUrl="/app-popup-analysis-single/index"
  >
  </app-table-xx>
</app-popup>
