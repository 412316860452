<app-popup header="{{ title }}">
  <p>{{ message }}</p>
  <input class="form-control" [formControl]="input" type="text"
    aria-label="modal-input" />
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-right">
      <app-button color="SECONDARY" (onClick)="activeModal.close()">
        {{ 'app.button.cancel' | translate }}
      </app-button>
      <app-button (onClick)="activeModal.close(input.value)"
        [disabled]="input.invalid">
        {{ 'app.button.ok' | translate }}
      </app-button>
    </div>
  </ng-template>
</app-popup>