import { Directive, ElementRef, Input } from '@angular/core';
import { AppConstant } from '../constant/app-constant';

@Directive({
  selector: '[maxLine]'
})
export class MaxLineDirective {
  public appConstant = new AppConstant();
  @Input() set maxLine(maxLine: number) {
    this.elementRef.nativeElement.classList.add('max-line');
    this.elementRef.nativeElement.style['-webkit-line-clamp'] =
      maxLine || this.appConstant.core.MAX_LINE_HTML_TEXT;
  }

  constructor(private elementRef: ElementRef) {}
}
