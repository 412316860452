import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AgreementType } from 'src/app/core/bean/agreement-type';
import { ItemType } from 'src/app/core/bean/item-type';
import { ProcurementItem } from 'src/app/core/bean/procurement-item';
import { Rfq } from 'src/app/core/bean/rfq';
import { RfqCancellation } from 'src/app/core/bean/rfq-cancellation';
import { RfqItem } from 'src/app/core/bean/rfq-item';
import { RfqVendor } from 'src/app/core/bean/rfq-vendor';
import { AppPopupChooseVendorService } from 'src/app/core/components/app-popup/app-popup-choose-vendor/app-popup-choose-vendor.service';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTipsComponent } from 'src/app/core/components/app-tips/app-tips.component';
import { AllTablePluginOptions } from 'src/app/core/components/table/interface/available-table-plugin';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { Validators } from 'src/app/core/validators';
import { RfqVendorView } from 'src/app/core/view/entity/bean/rfq-vendor-view';
import { RfqRequest } from './request/rfq.request';
import { RfqItemAndVendorResponse } from './response/rfq-item-and-vendor.response';
import { RfqResponse } from './response/rfq.response';
import { RfqPopupCancelComponent } from './rfq-popup-cancel.component';
import { RfqPopupVendorViewScoreComponent } from './rfq-popup-vendor-view-score.component';

@Component({
  templateUrl: './rfq-view.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RfqViewComponent extends BaseModuleComponent implements OnInit {
  @ViewChild('appTips') appTips: AppTipsComponent;
  public rfqId: number;
  public objectId: number; /** for worklist-approval */
  public approvalModelPrcsId: number;
  public rfqResponse: RfqResponse;
  public procurementItemList: ProcurementItem[] = [];
  public tableResponseVendorWR: TableResponseModel<RfqVendorView>;
  public tableResponseVendorPr: TableResponseModel<RfqVendorView>;
  public tableResponseVendorRfp: TableResponseModel<RfqVendorView>;
  public tableResponseItem: TableResponseModel<RfqItem>;
  public prIdList: number[] = [];
  public rfqItemList: RfqItem[] = [];
  public CURRENCY_DIGITS_INFO: string;
  public status: string;
  public isFromRfp: boolean;
  public isShowCloseButton = false;
  public rfq: Rfq = new Rfq();
  public token: string;
  public agreementTypeParam: AgreementType;
  public isAgreementTypeNull = null;
  public isShowInputControl = true;
  public urlBackOutside: string;

  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    false
  );
  public rfqPopupRfpResponse: RfqItemAndVendorResponse =
    new RfqItemAndVendorResponse();
  public fileUploader: FileUploader = new FileUploader(
    '/rfq/',
    '',
    this.global.appConstant.fileType.DOC_RFQ_TEMPLATE,
    false,
    this.global.config.parameterModel.maxFileRfq
  );

  public customPlugin: AllTablePluginOptions = {
    name: 'custom-plugin',
    before: (data: TablePluginData): HTMLElement | string => {
      const emELement = document.createElement('em');
      if (
        data.row.record.rfqVendor.isWinner &&
        !this.rfqResponse.rfq.workflowPrcs.code.includes('RFQ_SUBMISSION') &&
        this.rfqResponse.rfq.rfqStatus.code !==
          this.global.appConstant.pm.RFQ_STATUS_REVISION &&
        this.rfqResponse.rfq.rfqStatus.code !==
          this.global.appConstant.pm.RFQ_STATUS_CLOSED_RESPONSE
      ) {
        emELement.classList.add('pic', 'pi-trophy');
        emELement.style.fontSize = '1.75rem';
      }
      return emELement;
    }
  };
  public badgeColor = {
    DRAFT: 'SECONDARY',
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    WAITING_RESPONSE: 'GOOD',
    CLOSED_RESPONSE: 'DANGER',
    CANCELED: 'DANGER',
    COMPLETED: 'SUCCESS',
    DONE: 'SUCCESS'
  };

  constructor(
    translateService: TranslateService,
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public appPopupChooseVendorService: AppPopupChooseVendorService,
    public appPopupService: AppPopupService,
    public currency: CurrencyPipe,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute
  ) {
    super('rfq', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
    this.checkAgreementType();
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Rfq>('/rfq/notification/' + this.token)
      .subscribe((rfq: Rfq) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('rfqId', rfq.id);
        this.global.routerParams.set('status', rfq.rfqStatus.code);
        this.global.routerParams.set('urlBackOutside', '/pages/rfq');
        this.global.routerParams.set('rfq', rfq);
        this.global.routerParams.set('todo', 'view');
        this.router.navigate(['/pages/rfq/detail']);
        this.rfq.rfqStatus.code = rfq.rfqStatus.code;
      });
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public setDataFromRouterParams(): void {
    this.rfq = this.global.routerParams.get('rfq');
    this.status = this.global.routerParams.get('status');
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.rfqId =
      localStorage.getItem('rfqId') || this.global.routerParams.get('rfqId');
    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.rfqId = this.objectId;
    }
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );

    localStorage.removeItem('todo');
    localStorage.removeItem('rfqId');
    localStorage.removeItem('urlBackOutside');
  }

  public buildTableResponse(): void {
    this.tableResponseItem = new TableResponseModel(this.moduleCode, [
      {
        field: 'prItem.prItemImageList',
        header: 'table.column.thumbnail',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePluginData: TablePluginData): File => {
              if (tablePluginData.value && tablePluginData.value.length > 0) {
                return tablePluginData.value[0].file;
              }
              return null;
            }
          },
          {
            name: 'img',
            classNameMap: {
              MATERIAL: 'pvr pv-box',
              JASA: 'pvr pv-tools'
            },
            fieldClassName: 'prItem.item.itemType.code'
          }
        ],
        isSortable: false,
        isSearchTable: false
      },
      {
        field: 'prItem.pr.code',
        header: 'table.column.prNumber',
        plugins: { name: 'hyperlink', onClick: this.doViewItem.bind(this) }
      },
      {
        field: 'prItem.code',
        header: 'table.column.prLine'
      },
      {
        field: 'prItem.pr.requiredDate',
        header: 'table.column.requiredDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'prItem.pr.organization.name',
        header: 'table.column.departement'
      },
      {
        field: 'prItem.item.name',
        header: 'table.column.itemName'
      },

      {
        field: 'prItem.item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'prItem.item.itemType.code'
        }
      },
      {
        field: 'prItem.quantity',
        header: 'table.column.quantity',
        className: 'text-right',
        plugins: {
          name: 'custom-plugin',
          before: (data: TablePluginData): string => {
            return this.currency.transform(
              +data.value,
              '',
              '',
              this.CURRENCY_DIGITS_INFO
            );
          }
        }
      },
      {
        field: 'prItem.price',
        header: 'table.column.price',
        className: 'text-right',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      },
      {
        field: 'prItem.totalPrice',
        header: 'table.column.totalPrice',
        className: 'text-right',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      }
    ]);

    this.tableResponseVendorWR = new TableResponseModel(this.moduleCode, [
      {
        field: 'vendorName',
        header: 'table.column.vendorName',
        plugins: { name: 'hyperlink', onClick: this.onClickVendor.bind(this) }
      },
      {
        field: 'address',
        header: 'table.column.address'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      },
      {
        field: 'rfqVendor.submittedDate',
        header: 'table.column.responseDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'rfqVendor.submittedStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            UNAVAILABLE: 'SECONDARY',
            AVAILABLE: 'SUCCESS'
          },
          colorField: 'rfqVendor.submittedStatus.code'
        }
      }
    ]);
    this.tableResponseVendorPr = new TableResponseModel(this.moduleCode, [
      {
        field: '',
        header: '',
        className: 'text-center',
        plugins: this.customPlugin,
        isSortable: false
      },
      {
        field: 'vendorName',
        header: 'table.column.vendorName',
        plugins: { name: 'hyperlink', onClick: this.onClickVendor.bind(this) }
      },
      {
        field: 'address',
        header: 'table.column.address'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      },
      {
        field: 'rfqVendor.amount',
        header: 'table.column.totalBidPrice',
        className: 'text-right',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      }
    ]);
    this.tableResponseVendorRfp = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: '',
          header: '',
          className: 'text-center',
          plugins: this.customPlugin,
          isSortable: false
        },
        {
          field: 'vendorName',
          header: 'table.column.vendorName',
          plugins: [
            { name: 'hyperlink', onClick: this.onClickVendor.bind(this) },
            {
              name: 'icon',
              tooltip: 'rfq.vendor.tooltip.info',
              className: 'pir pi-info-circle',
              isShowIconFn: this.showIconInfo.bind(this),
              onClick: this.onClickVendor.bind(this)
            }
          ]
        },
        {
          field: 'address',
          header: 'table.column.address'
        },
        {
          field: 'phone',
          header: 'table.column.phone'
        },
        {
          field: 'rfqVendor.amount',
          header: 'table.column.totalBidPrice',
          className: 'text-right',
          plugins: {
            name: 'default',
            type: 'currency'
          }
        },
        {
          field: 'rfqVendor.totalScore',
          header: 'table.column.totalPoint',
          className: 'd-flex justify-content-end',
          plugins: [
            {
              name: 'text-field',
              type: 'decimal',
              isView: true
            },
            {
              name: 'icon',
              tooltip: 'rfq.vendor.tooltip.pencil',
              className: 'pir pi-pencil-alt text-primary',
              onClick: this.onClickVendor.bind(this),
              isShowIconFn: this.showIconPencil.bind(this)
            }
          ]
        }
      ],
      { checkBoxFn: this.checkVendorPass.bind(this) }
    );
  }

  public checkVendorPass(vendorView: RfqVendorView): boolean {
    let totalProposalCriteria = 0;
    vendorView.rfqVendor.rfqVendorScoringList.forEach(vendorScoring => {
      totalProposalCriteria += vendorScoring.weightScore;
    });
    if (
      ((this.status === this.global.appConstant.pm.RFQ_STATUS_CLOSED_RESPONSE ||
        this.status === this.global.appConstant.pm.RFQ_STATUS_REVISION) &&
        totalProposalCriteria <
          this.tableResponseItem.getRecordList()[0].rfpItem.rfp.minimumScore) ||
      this.status === this.global.appConstant.pm.RFQ_STATUS_WAITING_APPROVAL ||
      this.status === this.global.appConstant.pm.RFQ_STATUS_DONE ||
      this.status === this.global.appConstant.pm.RFQ_STATUS_CANCELED
    ) {
      return false;
    } else {
      return true;
    }
  }

  public showIconInfo(vendorView: RfqVendorView): boolean {
    return this.status ===
      this.global.appConstant.pm.RFQ_STATUS_WAITING_APPROVAL ||
      this.status === this.global.appConstant.pm.RFQ_STATUS_DONE ||
      this.status === this.global.appConstant.pm.RFQ_STATUS_CANCELED ||
      !vendorView.rfqVendor.totalScore
      ? false
      : !this.checkVendorPass(vendorView);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      title: [null, Validators.required()],
      responsePeriod: [null, Validators.required()],
      template: [null, Validators.required()],
      note: [null],
      itemType: null,
      amount: [null],
      goodsAmount: [null],
      serviceAmount: [null]
    });
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.formGroup.setIsView(true);
      this.formGroup.get('itemType').setIsView(false);
    }
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<RfqResponse>(
        '/rfq/add-edit?id=' +
          (this.rfqId ? this.rfqId : '') +
          '&todo=' +
          this.todo,
        {}
      )
      .subscribe((response: RfqResponse) => {
        this.rfqResponse = response;
        if (this.todo !== 'add') {
          this.status = this.status
            ? this.status
            : this.rfqResponse.rfq.rfqStatus.code;
          this.rfq = this.rfqResponse.rfq;
          this.fileUploader.setFileList(this.rfqResponse.fileList);
          this.formGroup.patchValue({
            id: this.rfqResponse.rfq.id,
            code: this.rfqResponse.rfq.code,
            title: this.rfqResponse.rfq.title,
            responsePeriod: {
              from: this.rfqResponse.rfq.startDate,
              to: this.rfqResponse.rfq.endDate
            },
            template: this.fileUploader.fileObjectList,
            note: this.rfqResponse.rfq.note,
            itemType: null,
            amount: this.rfqResponse.rfq.amount,
            goodsAmount: this.rfqResponse.rfq.goodsAmount,
            serviceAmount: this.rfqResponse.rfq.serviceAmount
          });
          this.agreementTypeParam = this.rfqResponse.rfq.agreementType;
          this.tableResponseItem.setRecordList(this.rfqResponse.rfqItemList);
          this.tableResponseItem.reload();
          this.isFromRfp =
            this.rfqResponse.rfqItemList[0].rfpItem &&
            this.rfqResponse.rfqItemList[0].rfpItem.id
              ? true
              : false;
          if (
            this.status ===
              this.global.appConstant.pm.RFQ_STATUS_WAITING_RESPONSE ||
            this.status === this.global.appConstant.pm.RFQ_STATUS_COMPLETED ||
            (this.status ===
              this.global.appConstant.pm.RFQ_STATUS_WAITING_APPROVAL &&
              this.rfq.workflowPrcs.code.includes('RFQ_SUBMISSION'))
          ) {
            this.tableResponseVendorWR.setRecordList(
              this.rfqResponse.rfqVendorViewList
            );
            this.tableResponseVendorWR.setSelectedRowList(
              this.rfqResponse.rfqVendorViewList.filter(
                rfqVendorView => rfqVendorView.rfqVendor.isWinner
              )
            );
            this.tableResponseVendorWR.reload();
            this.isShowCloseButton =
              this.rfqResponse.rfqVendorViewList.filter(
                rfqVendorView => rfqVendorView.rfqVendor.submittedDate
              ).length > 0
                ? true
                : false;
          } else {
            if (this.isFromRfp) {
              this.tableResponseVendorRfp.setRecordList(
                this.rfqResponse.rfqVendorViewList
              );
              this.tableResponseVendorRfp.setSelectedRowList(
                this.rfqResponse.rfqVendorViewList.filter(
                  rfqVendorView => rfqVendorView.rfqVendor.isWinner
                )
              );
              this.tableResponseVendorRfp.reload();
            } else {
              this.tableResponseVendorPr.setRecordList(
                this.rfqResponse.rfqVendorViewList
              );
              this.tableResponseVendorPr.setSelectedRowList(
                this.rfqResponse.rfqVendorViewList.filter(
                  rfqVendorView => rfqVendorView.rfqVendor.isWinner
                )
              );
              this.tableResponseVendorPr.reload();
            }
          }
        }
        this.itemTypeOptionList.setRequestValues(this.rfqResponse.itemTypeList);
        this.setStateReady();
      });
  }

  public setDecimal(field: number): string {
    console.log(
      this.currency.transform(+field, '', '', this.CURRENCY_DIGITS_INFO)
    );
    return this.currency.transform(+field, '', '', this.CURRENCY_DIGITS_INFO);
  }

  public doViewItem(rfqItem: RfqItem): void {
    this.appPopupPrItemInfoService.open(rfqItem.prItem.id, true);
  }

  public onChangeSelectRecord(): void {
    this.formGroup.markAsDirty();
  }

  public onChangeItemType(): void {
    const itemType = this.formGroup.value.itemType;
    if (itemType) {
      const rfqItemList = [];
      this.rfqResponse.rfqItemList.forEach((rfqItem: RfqItem) => {
        if (rfqItem.prItem && rfqItem.prItem.item.itemType.id === itemType.id) {
          rfqItemList.push(rfqItem);
        }
      });
      this.tableResponseItem.setRecordList(rfqItemList);
    } else {
      this.tableResponseItem.setRecordList(this.rfqResponse.rfqItemList);
    }
    this.tableResponseItem.reload();
    this.doSetAmount();
  }

  public doSetAmount(): void {
    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;
    this.tableResponseItem.getRecordList().forEach(rfqItem => {
      if (
        rfqItem.prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      ) {
        goodsAmountTotal = +goodsAmountTotal + +rfqItem.prItem.totalPrice;
      }
      if (
        rfqItem.prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
      ) {
        serviceAmountTotal = +serviceAmountTotal + +rfqItem.prItem.totalPrice;
      }
    });
    this.formGroup.patchValue({
      goodsAmount: goodsAmountTotal,
      serviceAmount: serviceAmountTotal,
      amount: +goodsAmountTotal + +serviceAmountTotal
    });
  }

  public showIconPencil(vendorView: RfqVendorView): boolean {
    if (
      !vendorView.rfqVendor.rfpVendor &&
      this.rfqResponse.rfq.rfqStatus.code ===
        this.global.appConstant.pm.RFQ_STATUS_CLOSED_RESPONSE
    ) {
      return true;
    } else {
      return false;
    }
  }

  public onClickVendor(rfqVendorView: RfqVendorView): void {
    if (
      this.rfqResponse.rfq.rfqStatus.code ===
        this.global.appConstant.pm.RFQ_STATUS_WAITING_RESPONSE &&
      rfqVendorView.rfqVendor.submittedStatus.code ===
        this.global.appConstant.pm.SUBMITTED_STATUS_UNAVAILABLE
    ) {
      this.global.modalService
        .confirmation(
          rfqVendorView.vendorName +
            ' ' +
            this.translateService.instant('rfq.confirm.msg.sendEmail'),
          'rfq.confirm.title.sendEmail',
          'app.button.close',
          'rfq.confirm.button.sendEmail',
          'pvc pv-envelope-reminder',
          false
        )
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<RfqVendorView>>(
                '/rfq/send-reminder-vendor',
                rfqVendorView
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.snackbarService.showWarning(
                    'app.msg.info.reminderEmailSent'
                  );
                  this.tableResponseVendorWR.reload();
                  this.setStateReady();
                }
              });
          }
        });
    } else {
      if (
        !(
          this.rfqResponse.rfq.rfqStatus.code ===
            this.global.appConstant.pm.RFQ_STATUS_WAITING_APPROVAL &&
          this.rfq.workflowPrcs.code.includes('RFQ_SUBMISSION')
        )
      ) {
        let rfpId;
        const rfqVendor = rfqVendorView.rfqVendor;
        let rfqVendorViewList: RfqVendorView[];
        if (
          this.tableResponseVendorWR &&
          this.tableResponseVendorWR.getRecordList() &&
          this.tableResponseVendorWR.getRecordList().length > 0
        ) {
          rfqVendorViewList = this.tableResponseVendorWR.getRecordList();
        } else if (
          this.tableResponseVendorRfp &&
          this.tableResponseVendorRfp.getRecordList() &&
          this.tableResponseVendorRfp.getRecordList().length > 0
        ) {
          if (
            !rfqVendor.rfpVendor ||
            (rfqVendor.rfpVendor && !rfqVendor.rfpVendor.id)
          ) {
            if (
              this.rfqResponse.rfq.rfqStatus.code ===
                this.global.appConstant.pm.RFQ_STATUS_CLOSED_RESPONSE ||
              this.rfqResponse.rfq.rfqStatus.code ===
                this.global.appConstant.pm.RFQ_STATUS_REVISION
            ) {
              this.formGroup.markAsDirty();
            }
          }
          rfqVendorViewList = this.tableResponseVendorRfp.getRecordList();
        } else if (
          this.tableResponseVendorPr &&
          this.tableResponseVendorPr.getRecordList() &&
          this.tableResponseVendorPr.getRecordList().length > 0
        ) {
          rfqVendorViewList = this.tableResponseVendorPr.getRecordList();
        }
        let minAmount = rfqVendorViewList[0].rfqVendor.amount;
        rfqVendorViewList.forEach((vendorView: RfqVendorView) => {
          if (minAmount >= vendorView.rfqVendor.amount) {
            minAmount = vendorView.rfqVendor.amount;
          }
        });
        if (this.isFromRfp) {
          rfpId = this.tableResponseItem.getRecordList()[0].rfpItem.rfp.id;
        }
        this.appPopupService
          .open(
            RfqPopupVendorViewScoreComponent,
            {
              rfpId,
              rfqVendor,
              minAmount
            },
            { size: 'xl' }
          )
          .subscribe((rfqVendor: RfqVendor) => {
            const i = rfqVendorViewList.indexOf(rfqVendorView);
            rfqVendorViewList[i].rfqVendor = rfqVendor;
            if (
              this.tableResponseVendorWR &&
              this.tableResponseVendorWR.getRecordList() &&
              this.tableResponseVendorWR.getRecordList().length > 0
            ) {
              this.tableResponseVendorWR.setRecordList(rfqVendorViewList);
              this.tableResponseVendorWR.reload();
            } else if (
              this.tableResponseVendorRfp &&
              this.tableResponseVendorRfp.getRecordList() &&
              this.tableResponseVendorRfp.getRecordList().length > 0
            ) {
              const rfqVendorViewSorted = rfqVendorViewList.sort(
                (rfqVendorViewA, rfVendorViewB) => {
                  if (
                    rfqVendorViewA.rfqVendor?.totalScore >=
                    rfVendorViewB?.rfqVendor?.totalScore
                  ) {
                    return -1;
                  }
                  if (
                    rfqVendorViewA.rfqVendor?.totalScore <
                    rfVendorViewB.rfqVendor?.totalScore
                  ) {
                    return 1;
                  }
                }
              );
              this.tableResponseVendorRfp.setRecordList(rfqVendorViewSorted);
              this.tableResponseVendorRfp.reload();
            } else if (
              this.tableResponseVendorPr &&
              this.tableResponseVendorPr.getRecordList() &&
              this.tableResponseVendorPr.getRecordList().length > 0
            ) {
              this.tableResponseVendorPr.setRecordList(rfqVendorViewList);
              this.tableResponseVendorPr.reload();
            }
          });
      }
    }
  }

  public doSave(isSubmit: boolean): void {
    this.validate();
    let isValid = false;
    if (
      (isSubmit &&
        ((this.tableResponseVendorPr.getRecordList().length > 0 &&
          this.tableResponseVendorPr.selectedRow.getSelectedRecordList()
            .length > 0) ||
          (this.tableResponseVendorRfp.getRecordList().length > 0 &&
            this.tableResponseVendorRfp.selectedRow.getSelectedRecordList()
              .length > 0))) ||
      !isSubmit
    ) {
      isValid = true;
      if (isSubmit && this.isFromRfp) {
        const checkVendorScoring = this.tableResponseVendorRfp
          .getRecordList()
          .filter(rfqVendorView => {
            return rfqVendorView.rfqVendor.rfqVendorScoringList &&
              rfqVendorView.rfqVendor.rfqVendorScoringList.length > 0
              ? rfqVendorView
              : null;
          });
        isValid =
          checkVendorScoring.length ===
          this.tableResponseVendorRfp.getRecordList().length
            ? true
            : false;
      }
    }
    if (
      this.rfq.agreementType === null ||
      this.rfq.agreementType === undefined
    ) {
      this.isAgreementTypeNull = true;
    } else if (this.formGroup.valid && isValid) {
      if (isSubmit) {
        this.global.modalService
          .confirmation(
            this.translateService.instant('rfq.modal.vendorWinner.msg') +
              (this.isFromRfp
                ? this.tableResponseVendorRfp.selectedRow.getSelectedRecordList()[0]
                    .rfqVendor.vendor.name
                : this.tableResponseVendorPr.selectedRow.getSelectedRecordList()[0]
                    .rfqVendor.vendor.name) +
              this.translateService.instant('rfq.modal.vendorWinner.asWinner'),
            'rfq.modal.vendorWinner.title',
            'rfq.modal.button.cancel',
            'rfq.modal.button.submit',
            'pvc pv-trophy',
            true
          )
          .subscribe(result => {
            if (result) {
              this.doSubmit(isSubmit);
            }
          });
      } else {
        this.doSubmit(isSubmit);
      }
    } else {
      this.global.alertService.showError(
        'rfq.validation.completeScoringAndChooseVendor'
      );
    }
  }

  public doSubmit(isSubmit: boolean): void {
    this.setStateProcessing();
    const rfqRequest: RfqRequest = new RfqRequest();
    rfqRequest.isSubmit = isSubmit;
    rfqRequest.fileObjectList = this.formGroup.get('template').value;
    rfqRequest.rfq = this.rfqResponse.rfq;
    rfqRequest.rfqItemList = this.rfqResponse.rfqItemList;
    rfqRequest.rfqVendorList = [];
    if (
      this.tableResponseVendorWR &&
      this.tableResponseVendorWR.getRecordList() &&
      this.tableResponseVendorWR.getRecordList().length > 0
    ) {
      this.tableResponseVendorWR.getRecordList().forEach(vendorView => {
        rfqRequest.rfqVendorList.push(vendorView.rfqVendor);
      });
    } else if (
      this.tableResponseVendorRfp &&
      this.tableResponseVendorRfp.getRecordList() &&
      this.tableResponseVendorRfp.getRecordList().length > 0
    ) {
      this.tableResponseVendorRfp.getRecordList().forEach(vendorView => {
        rfqRequest.rfqVendorList.push(vendorView.rfqVendor);
      });
      if (
        this.tableResponseVendorRfp.selectedRow &&
        this.tableResponseVendorRfp.selectedRow.getSelectedRecordList()[0]
      ) {
        rfqRequest.rfqVendorWinner =
          this.tableResponseVendorRfp.selectedRow.getSelectedRecordList()[0];
      }
    } else if (
      this.tableResponseVendorPr &&
      this.tableResponseVendorPr.getRecordList() &&
      this.tableResponseVendorPr.getRecordList().length > 0
    ) {
      this.tableResponseVendorPr.getRecordList().forEach(vendorView => {
        rfqRequest.rfqVendorList.push(vendorView.rfqVendor);
      });
      if (
        this.tableResponseVendorPr.selectedRow &&
        this.tableResponseVendorPr.selectedRow.getSelectedRecordList()[0]
      ) {
        rfqRequest.rfqVendorWinner =
          this.tableResponseVendorPr.selectedRow.getSelectedRecordList()[0];
      }
    }
    rfqRequest.isSubmit = isSubmit;
    const url = this.todo !== 'add' ? '/rfq/update' : '/rfq/insert';
    this.httpClientService
      .post<Response<RfqRequest>>(url, rfqRequest)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          if (!isSubmit) {
            this.snackbarService.showWarning('app.alert.msg.saveSuccess');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set('rfqId', response.body.id);
                this.global.routerParams.set(
                  'status',
                  response.body.rfqStatus.code
                );
                this.global.routerParams.set('urlBackOutside', '/pages/rfq');
                this.global.routerParams.set('todo', 'view');
                this.router.navigate(['/pages/rfq/detail']);
              });

            this.setStateReady();
          } else {
            this.global.modalService
              .submitSuccessCreateNew()
              .pipe(take(1))
              .subscribe(result => {
                if (result) {
                  this.global.routerParams.clear();
                  this.global.routerParams.set('todo', 'add');
                  this.router.navigate(['/pages/rfq/add']);
                  this.global.routerParams.set('urlBackOutside', '/pages/rfq');
                } else {
                  this.router.navigate(['/pages/rfq/']);
                }
              });
          }
        } else {
          this.global.alertService.showError(response.statusText);
        }
      });
  }

  public doCancel(): void {
    this.appPopupService
      .open(RfqPopupCancelComponent, {}, { size: 'SM' })
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          const rfqCancelation = new RfqCancellation();
          rfqCancelation.note = result;
          rfqCancelation.rfq = this.rfqResponse.rfq;
          this.httpClientService
            .post<Response<Rfq>>('/rfq/cancel', rfqCancelation)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.routerParams.clear();
                this.global.routerParams.set('rfqId', this.rfqResponse.rfq.id);
                this.global.routerParams.set(
                  'status',
                  this.global.appConstant.pm.RFQ_STATUS_CANCELED
                );
                this.global.routerParams.set('urlBackOutside', '/pages/rfq');
                this.global.routerParams.set('rfq', this.rfqResponse.rfq);
                this.global.routerParams.set('todo', 'view');
                this.router.navigate(['/pages/rfq/detail']);
                this.onInit();
              } else {
                this.global.alertService.showError(response.statusText);
                this.setStateReady();
              }
            });
        }
      });
  }

  public doClose(): void {
    const checkSubmit = this.tableResponseVendorWR
      .getRecordList()
      .filter(vendorWR => vendorWR.rfqVendor.submittedDate).length;
    if (checkSubmit > 0) {
      this.global.modalService
        .confirmation(
          'If you close reponse, invited vendor could not edit or update their response anymore',
          'Are you sure to close response?',
          'No',
          'Yes, I am Sure',
          null,
          false
        )
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<Rfq>>('/rfq/close', this.rfqResponse.rfq)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/rfq']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public onChangeAgreementType(event): void {
    this.rfq.agreementType = event;
    this.appTips.reload();
  }

  public checkAgreementType(): void {
    if (
      this.rfqResponse?.rfq?.rfqStatus.code === 'CLOSED_RESPONSE' ||
      this.rfq?.rfqStatus.code === 'CLOSED_RESPONSE' ||
      this.rfq?.rfqStatus.code === 'REVISION' ||
      this.rfqResponse?.rfq?.rfqStatus.code === 'REVISION'
    ) {
      this.isShowInputControl = true;
    } else {
      this.isShowInputControl = false;
    }
  }
}
