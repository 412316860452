import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { FileUploader } from '../../../core/components/upload';
import { OptionListModel } from '../../../core/model/option-list-model';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { RouteRequestModel } from '../../../core/model/route-request-model';
import { Validators } from '../../../core/validators';
import { HomeSlideDTO } from '../dto/home-slide.dto';
import { File } from './../../../core/bean/file';
import { HomeSlide } from './../../../core/bean/home-slide';
@Component({
  templateUrl: './home-slide-edit-add.component.html'
})
export class HomeSlideEditAddComponent extends BaseModuleComponent {
  public homeSlide: HomeSlide = new HomeSlide();
  public homeSlideId: number;
  public fileUploader: FileUploader = new FileUploader(
    '/home-slide/',
    '',
    this.global.appConstant.fileType.IMG_HOME_SLIDE
  );
  public homeSlideStatusOptionList: OptionListModel<any> =
    new OptionListModel();
  constructor(translateService: TranslateService) {
    super('home-slide', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doSetHomeSlideOptionList();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.homeSlideId = this.global.routerParams.get('homeSlideId');
  }

  public doSetHomeSlideOptionList(): void {
    const optionListValue = [
      { name: 'app.status.active', value: true },
      { name: 'app.status.inActive', value: false }
    ];
    this.homeSlideStatusOptionList.setRequestValues(optionListValue);
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      // file: [new File(), Validators.required()],
      file: [null, Validators.required()],
      title: [null, Validators.required()],
      body: [null, Validators.required()],
      referenceLink: [null],
      isActive: [null, Validators.required()],
      imgFile: [new File()]
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<HomeSlide>(
        '/home-slide/add-edit',
        new RouteRequestModel(this.todo, this.homeSlideId)
      )
      .subscribe(homeSlide => {
        if (homeSlide != null) {
          this.homeSlide = homeSlide;
          this.fileUploader.setFile(homeSlide.imgFile);
          const homeSlides = this.homeSlideStatusOptionList
            .getRequestValues()
            .filter(valueOption => valueOption.value === homeSlide.isActive)[0];
          this.formGroup.patchValue({
            id: homeSlide.id,
            file: this.fileUploader,
            title: homeSlide.title,
            body: homeSlide.body,
            referenceLink: homeSlide.referenceLink,
            isActive: homeSlides
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.homeSlide = this.global.copyFormAttributeToModel(
              this.homeSlide,
              this.formGroup
            );
            const url =
              this.todo === 'edit'
                ? '/home-slide/update'
                : '/home-slide/insert';
            const homeSlideDTO: HomeSlideDTO = new HomeSlideDTO();
            homeSlideDTO.fileObjectList = this.fileUploader.fileObjectList;
            this.homeSlide.isActive =
              this.formGroup.get('isActive').value.value;
            homeSlideDTO.homeSlide = this.homeSlide;
            this.httpClientService
              .post<Response<HomeSlide>>(url, homeSlideDTO)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/content/home-slide']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/content/home-slide']);
  }
}
