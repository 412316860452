import { PaymentBilling } from './../../core/bean/payment-billing';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
/*import { InternalPaymentRequestBillingView } from 'src/app/core/view/entity/bean/internal-payment-request-billing-view';*/
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';

@Component({
    templateUrl: './app-popup-internal-payment-request-choose-bill.component.html'
})
export class AppPopupInternalPaymentRequestChooseBillComponent extends BaseComponentComponent {
    @Input() public procurementSowPaymentTermId: number;
    @Input()
    public internalPaymentRequestBilling: PaymentBilling;

    @Output()
    onChange: EventEmitter<PaymentBilling> = new EventEmitter();
    public tableResponse: TableResponseModel<PaymentBilling>;
    public procurementVendorId: number;

    constructor() {
        super('internal-payment-request');
    }

    onInit(): void {
        this.buildTableResponse();
        this.setStateReady();
    }

    public buildTableResponse(): void {
        this.tableResponse = new TableResponseModel(this.moduleCode, [
            {
                field: 'code',
                header: 'table.column.orderNumber'
            },
            {
                field: 'requiredDate',
                header: 'table.column.orderDate',
                format: FieldFormatEnum.ShortDate
            },
            {
                field: 'drType.name',
                header: 'table.column.orderType'
            },
            {
                field: 'amount',
                header: 'table.column.bills',
                format: FieldFormatEnum.Currency
            },
            {
                field: 'sowPaymentTerm.paymentTerm.name',
                header: 'table.column.termin'
            },
            {
                field: 'sowPaymentTerm.sow.stageOfWork.name',
                header: 'table.column.stage'
            }
        ]);
    }

    public doCheckProcurementSowPaymentTerm(
        internalPaymentRequestBilling: PaymentBilling
    ): void {
        if (
            internalPaymentRequestBilling.sowPaymentTerm !== null
        ) {
            this.procurementSowPaymentTermId =
                internalPaymentRequestBilling.sowPaymentTerm.id;
        }
        this.internalPaymentRequestBilling = internalPaymentRequestBilling;
    }

    public doSave(): void {
        if (this.procurementSowPaymentTermId) {
            this.onChange.emit(this.internalPaymentRequestBilling);
        } else {
            this.global.alertService.showError(
                this.global.translateService.instant('app.validation.chooseOneData'),
                '.main-row-popup-choose-bill'
            );
        }
    }

    public doSort(sortField: string): void {
        this.tableResponse.setSortField(sortField);
        this.tableResponse.reload();
    }

    public onKeyUp(event: KeyboardEvent): void {
        event.preventDefault();
    }
}
