import { EventEmitter } from '@angular/core';
import { DatePickerModel } from '../interface/date-picker-model';
import { DateRangeValue } from '../interface/date-range-value';
import { DatePickerDropDownView } from '../type/date-picker-drop-down-view-type';
import { DatePickerValue } from '../type/date-picker-value-type';
import { DatePickerDate } from './date-picker-date-model';
import { DatePickerDateRange } from './date-picker-date-range-model';
import { DatePickerTime } from './date-picker-time-model';
export class DatePickerState {
  public dateList: Array<DatePickerDate>;
  public timeList: Array<DatePickerTime>;
  public currentDate: DatePickerDate;
  public currentTime: DatePickerTime;
  public currentDateValue: DatePickerDate;
  public currentDateRange: DatePickerDateRange;
  public view: DatePickerDropDownView;
  public monthList: Array<string>;
  public yearList: Array<number>;
  public value: DatePickerValue;
  public minDate: DatePickerDate;
  public maxDate: DatePickerDate;
  public valueChanges: EventEmitter<DatePickerValue> = new EventEmitter();
  constructor(public model: DatePickerModel) {}
  public setValue(value: DatePickerValue) {
    if (value) {
      if (this.model.range) {
        const newValue = value as DateRangeValue;
        this.value = {
          from: new Date(newValue.from),
          to: new Date(newValue.to)
        };
      } else {
        this.value = new Date(value as Date);
      }
    } else {
      this.value = value;
    }
    this.valueChanges.emit(this.value);
  }
}
