import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupUserTOCComponent } from './app-popup-user-toc.component';
import { DashboardResponse } from '../../../../pages/dashboard/dashboard-response';
@Injectable({ providedIn: 'root' })
export class AppPopUpUserTOCService {
  constructor(public appPopupService: AppPopupService) {}
  public open(dashboardResponse: DashboardResponse): Observable<any> {
    return this.appPopupService.open(
      AppPopupUserTOCComponent,
      { title: 'user-toc.title', dashboardResponse: dashboardResponse },
      { windowClass: 'app-popup-user-toc' }
    );
  }
}
