import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { PaymentInvoice } from 'src/app/core/bean/payment-invoice';
import { PaymentProof } from 'src/app/core/bean/payment-proof';
import { PaymentTaxProof } from 'src/app/core/bean/payment-tax-proof';
import { PaymentVoucher } from 'src/app/core/bean/payment-voucher';
import { PaymentVoucherOtherCost } from 'src/app/core/bean/payment-voucher-other-cost';
import { Vendor } from 'src/app/core/bean/vendor';
import { VendorBank } from 'src/app/core/bean/vendor-bank';
import { VendorBankOffline } from 'src/app/core/bean/vendor-bank-offline';
import { VendorOffline } from 'src/app/core/bean/vendor-offline';
import { PaymentInformationResponse } from './response/payment-information.response';

@Component({
  selector: 'app-payment-information-view',
  templateUrl: './payment-information.component.html'
})
export class AppPaymentInformationComponent extends BaseComponentComponent {
  @Input() public paymentId: number;
  public paymentInformationResponse: PaymentInformationResponse;
  public paymentVoucher: PaymentVoucher;
  public paymentInvoice: PaymentInvoice;
  public paymentProof: PaymentProof;
  public paymentTaxProof: PaymentTaxProof;
  public vendor: Vendor;
  public vendorBank: VendorBank;
  public vendorBankOffline: VendorBankOffline;
  public vendorOffline: VendorOffline;
  public paymentVoucherOtherCostList: PaymentVoucherOtherCost[] = [];

  constructor() {
    super('payment-history');
  }

  public onInit(): void {
    this.setFormGroup();
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<PaymentInformationResponse>('/payment-information/get-payment-information/' + this.paymentId)
      .subscribe((paymentInformationResponse: PaymentInformationResponse) => {
        this.paymentInformationResponse = paymentInformationResponse;
        this.paymentVoucher = paymentInformationResponse.paymentVoucher;
        this.paymentInvoice = paymentInformationResponse.paymentInvoice;
        this.paymentProof = paymentInformationResponse.paymentProof;
        this.paymentTaxProof = paymentInformationResponse.paymentTaxProof;
        this.vendor = paymentInformationResponse.vendor;
        this.vendorBank = paymentInformationResponse.vendorBank;
        this.vendorOffline = paymentInformationResponse.vendorOffline;
        this.vendorBankOffline = paymentInformationResponse.vendorBankOffline;
        this.paymentVoucherOtherCostList =
          paymentInformationResponse.paymentVoucherOtherCostList;
        this.setStateReady();
      });
  }
}
