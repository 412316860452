import { CatalogAttribute } from 'src/app/core/bean/catalog-attribute';
import { CatalogSpecification } from 'src/app/core/bean/catalog-specification';
import { CatalogType } from 'src/app/core/bean/catalog-type';
import { ItemCategory } from 'src/app/core/bean/item-category';

export class AppCatalogItemCustomDataRequest {
    attributeList: CatalogAttribute[] = [];
    itemCategory: ItemCategory;
    minimumPrice: number;
    maximumPrice: number;
    regionCodeList: string[] = [];
    valueList: string[] = [];
    catalogSpecificationList: CatalogSpecification[] = [];
    catalogType: CatalogType;
    vendorId: number;
    itemTypeCode: string;
}
