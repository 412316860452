import { Component } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorQualification } from '../../core/bean/vendor-qualification';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';

@Component({
  templateUrl: './vendor-qualification-edit-add.component.html'
})
export class VendorQualificationEditAddComponent extends BaseModuleComponent {
  public vendorQualification: VendorQualification = new VendorQualification();
  public vendorQualificationId: number;
  public readonly urlEditAddVendorQualification =
    '/vendor-qualification/add-edit';
  public readonly urlUpdateVendorQualification = '/vendor-qualification/update';
  public readonly urlInsertVendorQualification = '/vendor-qualification/insert';
  public readonly urlRouterNavigateToVendorQualification =
    '/pages/vendor-qualification';
  public submitted: boolean;
  constructor(translateService: TranslateService) {
    super('vendor-qualification', translateService);
  }

  onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorQualificationId = this.global.routerParams.get(
      'vendorQualificationId'
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [null, Validators.compose([Validators.required])],
      code: [null, Validators.compose([Validators.required])],
      description: [null]
    });
  }

  public setFormGroup(): void {
    this.getVendorQualificationByIdFromServer(
      this.urlEditAddVendorQualification,
      this.todo,
      this.vendorQualificationId
    ).subscribe(vendorQualification => {
      if (vendorQualification != null) {
        const { id, name, code, description } = vendorQualification;
        this.formGroup.patchValue({ id, name, code, description });
      }
      this.setStateReady();
    });
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.saveVendorQualification();
          }
        });
    }
  }

  public saveVendorQualification(): void {
    this.setStateProcessing();
    this.vendorQualification = this.global.copyFormAttributeToModel(
      this.vendorQualification,
      this.formGroup
    );
    this.saveVendorQualificationToServer(
      this.urlSaveVendorQualification,
      this.vendorQualification
    ).subscribe(response => {
      if (response.status === ResponseStatusModel.OK) {
        this.global.alertService.showSuccessSavingOnNextRoute();
        this.router.navigate([this.urlRouterNavigateToVendorQualification]);
      } else {
        this.global.alertService.showError(response.statusText);
        this.setStateReady();
      }
    });
  }

  public doCancel(): void {
    this.router.navigate([this.urlRouterNavigateToVendorQualification]);
  }

  public get urlSaveVendorQualification(): string {
    return this.todo === 'edit'
      ? this.urlUpdateVendorQualification
      : this.urlInsertVendorQualification;
  }

  public getVendorQualificationByIdFromServer(
    urlGetVendorQualificationById: string,
    todo: string,
    vendorQualificationId: number
  ): Observable<VendorQualification> {
    return this.httpClientService.post<VendorQualification>(
      urlGetVendorQualificationById,
      new RouteRequestModel(todo, vendorQualificationId)
    );
  }

  public saveVendorQualificationToServer(
    urlSaveVendorQualification: string,
    vendorQualification: VendorQualification
  ): Observable<Response<VendorQualification>> {
    return this.httpClientService.post<Response<VendorQualification>>(
      urlSaveVendorQualification,
      vendorQualification
    );
  }
}
