import {
  ApprovalPathItem,
  IApprovalPathMapper
} from '../../../../core/components/app-approval-path';
export class TeamProjectMapper<T> implements IApprovalPathMapper<T> {
  toModel(approverList: T[]): Array<ApprovalPathItem> {
    const currentPosition = approverList.findIndex(
      (approver: T) => (approver as any).currentSequence === true
    );
    return approverList.map((approver: T, index: number) => {
      const { position, user } = approver as any;
      const isCurrentPosition = currentPosition === index;
      const isApproved =
        currentPosition === -1 ? false : currentPosition < index;
      return new ApprovalPathItem(
        position.name,
        user.name,
        isCurrentPosition,
        isApproved
      );
    });
  }
}
