<app-popup header="billing-memo.popup.title" [isLoading]="formLoading">
    <form class="form-horizontal popup-biling-memo" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'billing-memo.popup.form.totalBilling' | translate
            }}</label>
            <div class="col-sm-9 text-left">
                {{
                  formGroup.value.totalBilling ?
                    global.converterService.convertMoney(
                        formGroup.value.totalBilling
                    ) : 0
                }} {{ currency.code }}
            </div>
        </div>

        <div class="text-right">
            <ng-container>
              <app-button (onClick)="doAddMore()"
                *ngIf="!isViewOnly"
                ><em class="fas fa-plus"></em>
                {{ 'app.button.addMore' | translate }}</app-button
              >
            </ng-container>
        </div>
        <ng-container formArrayName="billingMemoBudgetDetailList">
            <div class="form-group row text-sm-right">
              <div
                class="card border-0"
                [ngStyle]="{ 'margin': '1px', 'width': '-webkit-fill-available' }"
              >
                <div class="card-body">
                  <div
                    class="table-responsive custom-table float-none w-100"
                    [ngStyle]="{ 'margin': '0 auto' }"
                  >
                    <table
                      class="table table-striped box-shadow"
                      aria-describedby="Table"
                    >
                      <thead>
                        <tr class="text-center">
                          <th
                            scope="col"
                            >{{
                              'app.table.column.no'
                                | translate
                            }}
                          </th>
                          <th
                            scope="col"
                            >{{
                              'billing-memo.popup.table.column.coa'
                                | translate
                            }}
                          </th>
                          <th
                            scope="col"
                            >{{
                              'billing-memo.popup.table.column.costCenter'
                                | translate
                            }}
                          </th>
                          <th
                            scope="col"
                            >{{
                              'billing-memo.popup.table.column.taxPercentageValue'
                                | translate
                            }}
                          </th>
                          <th
                            scope="col"
                            >{{
                              'billing-memo.popup.table.column.taxBaseAmount'
                                | translate
                            }}
                          </th>
                          <th
                            scope="col"
                            >{{
                              'billing-memo.popup.table.column.vatAmount'
                                | translate
                            }}
                          </th>
                          <th
                            scope="col"
                            >{{
                              'billing-memo.popup.table.column.totalAmount'
                                | translate
                            }}
                          </th>
                          <th
                            scope="col"
                            *ngIf="!isViewOnly"
                            >{{
                              'app.table.column.action'
                                | translate
                            }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let memoItemDetail of formGroup.get('billingMemoBudgetDetailList')['controls'];
                            let i = index
                          "
                          [formGroupName]="i"
                        >
                          <td
                            class="text-center"
                          >
                            {{
                              i + 1
                            }}.
                          </td>
                          <td
                            class="text-left"
                          >
                            <app-auto-complete
                                size="XL"
                                optionView="code"
                                formControlName="coa"
                                stringUrl="/billing-memo/auto-complete-coa-list"
                                *ngIf="!budgetAllocation.coa.isMultiYears"
                            ></app-auto-complete>
                            <div 
                            class="input-group"
                            (click)="doChooseBudgetCost(i)"
                            *ngIf="budgetAllocation.coa.isMultiYears && !isViewOnly"
                          >
                            <input
                              type="text"
                              formControlName="coaCode"
                              class="form-control cursor-pointer"
                              aria-label="search"
                              disabled="true"
                            />
                            <div class="input-group-prepend">
                              <span class="input-group-text cursor-pointer"
                                ><em class="fas fa-search"></em
                              ></span>
                            </div>
                          </div>
                          <div *ngIf="budgetAllocation.coa.isMultiYears && isViewOnly">
                            {{
                              memoItemDetail.controls.coaCode.value ?
                              memoItemDetail.controls.coaCode.value : "-"
                            }}
                          </div>
                          </td>
                          <td
                            class="text-left"
                          >
                            <app-auto-complete
                                size="XL"
                                formControlName="costCenter"
                                stringUrl="/billing-memo/auto-complete-cost-center-list"
                                *ngIf="!budgetAllocation.coa.isMultiYears"
                            ></app-auto-complete>
                            <div
                              *ngIf="budgetAllocation.coa.isMultiYears"
                            >
                              {{
                                memoItemDetail.controls.costCenter.value ?
                                memoItemDetail.controls.costCenter.value.name : "-"
                              }}
                            </div>
                          </td>
                          <td
                            class="text-right"
                          >
                          {{
                            memoItemDetail.controls.taxPercentageValue.value
                          }}
                          </td>
                          <td
                            class="text-left"
                          >
                            <app-text-field size="XL" formControlName="taxBaseAmount" type="currency" (onChange)="onChangeTaxBase(i)"></app-text-field>
                          </td>
                          <td
                            class="text-right"
                          >
                            {{
                                memoItemDetail.controls.vatAmount.value ?
                                (memoItemDetail.controls.vatAmount.value | currency: ' ') :
                                0
                            }} {{ currency.code }}
                          </td>
                          <td
                            class="text-right"
                          >
                            {{
                              memoItemDetail.controls.totalAmount.value ?
                              ( memoItemDetail.controls.totalAmount.value | currency: ' ' ) :
                              0
                            }} {{ currency.code }}
                          </td>
                          <td
                            class="text-center"
                            *ngIf="!isViewOnly"
                          >
                            <app-button
                                size="TN"
                                color="DANGER"
                                (onClick)="doDelete(i)"
                                title="{{ 'app.tooltip.delete' | translate }}"
                                *ngIf="i !== 0"
                                >
                                <span class="fas fa-trash"></span>
                            </app-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <label
          *ngIf="!isViewOnly"
            >*)
            {{
            'billing-memo.popup.footnote.validationTotal' | translate
            }}</label>
        <ng-template #modalFooter let-activeModal>
            <div class="button-group button-group-center">
                <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
                  'app.button.close' | translate
                }}</app-button>
                <app-button
                  color="PRIMARY"
                  (onClick)="doSave()"
                  *ngIf="!isViewOnly"
                  >{{ 'app.button.save' | translate }}</app-button
                >
              </div>
        </ng-template>
    </form>
</app-popup>