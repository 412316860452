import { IconPlugin } from '../interfaces/available-table-plugin';
import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';

export const AppTableIconPlugin: TablePlugin = {
  name: 'icon',
  after: (data: TablePluginData): void => {
    const iconPlugin: IconPlugin = data.plugin as IconPlugin;
    if (
      !iconPlugin.fn ||
      (iconPlugin.fn &&
        iconPlugin.fn(
          data.record.record,
          data.element,
          data.state,
          data.indexI,
          data.indexJ
        ))
    ) {
      const emElement = document.createElement('em');
      iconPlugin.class &&
        emElement.classList.add(...iconPlugin.class.split(' '));
      data.value &&
        iconPlugin.classNameMap &&
        emElement.classList.add(
          ...iconPlugin.classNameMap[data.value].split(' ')
        );
      emElement.classList.add('cursor-pointer', 'align-middle', 'ml-2');
      if (iconPlugin.onClick) {
        emElement.addEventListener('click', (event: PointerEvent) => {
          event.stopPropagation();
          iconPlugin.onClick(data.record.record);
        });
      } else {
        emElement.addEventListener('click', (event: PointerEvent) => {
          event.stopPropagation();
          data.state.event.emit({
            type: 'CLICK-RECORD',
            data: {
              record: data.record.record
            }
          });
        });
      }
      data.element.appendChild(emElement);
    }
  }
};
