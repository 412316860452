import { HyperlinkPlugin } from '../interfaces/available-table-plugin';
import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';
export const AppTableHyperlinkPlugin: TablePlugin = {
  name: 'hyperlink',
  after: (data: TablePluginData): void => {
    const hyperlinkPlugin = data.plugin as HyperlinkPlugin;
    if (
      hyperlinkPlugin &&
      typeof hyperlinkPlugin === 'object' &&
      hyperlinkPlugin.isShowIcon
    ) {
      const emElement = document.createElement('em');
      emElement.classList.add('fas', 'fa-external-link-alt', 'ml-2');
      data.element.children.item(0).appendChild(emElement);
    }
    let spanTdText = data.element.querySelector('.td-text');
    if (
      hyperlinkPlugin &&
      typeof hyperlinkPlugin === 'object' &&
      hyperlinkPlugin.customText
    ) {
      if (!spanTdText) {
        spanTdText = document.createElement('span');
        spanTdText.classList.add('td-text');
        data.element.appendChild(spanTdText);
      }
      spanTdText.textContent = hyperlinkPlugin.customText;
    }
    spanTdText.classList.add('td-text-hyperlink');
    hyperlinkPlugin.className &&
      spanTdText.classList.add(...hyperlinkPlugin.className.split(' '));
    if (hyperlinkPlugin.onClick) {
      spanTdText.addEventListener('click', (event: PointerEvent) => {
        event.stopPropagation();
        hyperlinkPlugin.onClick(data.record.record);
      });
    } else {
      spanTdText.addEventListener('click', (event: PointerEvent) => {
        event.stopPropagation();
        data.state.event.emit({
          type: 'CLICK-RECORD',
          data: {
            record: data.record.record
          }
        });
      });
    }
  }
};
