<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="vendor-expired-document.title" [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup">
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-expired-document.form.vendorName' | translate }}</label>
          <div class="col-sm-4 text-left ">
            {{ vendorName }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-expired-document.form.vendorCode' | translate }}</label>
          <div class="col-sm-4 text-left ">
            {{ vendorCode }}
          </div>
        </div>
      </form>
      <br>
      <h5 class="separator">{{ 'vendor-expired-document.vendor-document' |
        translate }}</h5>
      <app-table #selectorVendorDoc [tableResponse]="tableResponseVendorDoc"
        [isServerSide]="false">
      </app-table>
      <br>
      <h5 class="separator">{{ 'vendor-expired-document.additional-document' |
        translate }}</h5>
      <app-table #selectorVendorAdditionalDoc
        [tableResponse]="tableResponseVendorAdditionalDoc"
        [isServerSide]="false">
      </app-table>

      <div class="row justify-content-center mt-5">
        <app-button mode="BYPASS" color="SECONDARY" (onClick)="doBack()">{{
          'app.button.back' | translate }}</app-button>&nbsp;
        <app-button (onClick)="doSendEmail()" [disabled]="formSaving">{{
          'vendor-expired-document.button.sendMail' | translate }}</app-button>
      </div>
    </app-card>
  </div>
</div>