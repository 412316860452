import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { NumberGeneratorDetailComponent } from './number-generator-detail.component';
import { NumberGeneratorEditAddComponent } from './number-generator-edit-add.component';
import { NumberGeneratorComponent } from './number-generator.component';
const routes: Routes = [
  { path: '', component: NumberGeneratorComponent, data: { breadcrumb: '' } },
  {
    path: 'detail',
    component: NumberGeneratorDetailComponent,
    data: { breadcrumb: 'number-generator.breadcrumb.detail' }
  },
  {
    path: 'edit',
    component: NumberGeneratorEditAddComponent,
    data: { todo: 'edit', breadcrumb: 'number-generator.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    NumberGeneratorComponent,
    NumberGeneratorDetailComponent,
    NumberGeneratorEditAddComponent
  ]
})
export class NumberGeneratorModule {}
