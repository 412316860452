<div class="row main-row form-registration m-0">
  <br /><br /><br />
  <div class="row">
    <div class="col-sm-12 text-center">
      <div style="font-size: 24px">
        <em
          *ngIf="validOrInvalid === 1"
          class="fas fa-check fa-5x"
          style="color: rgb(49, 175, 55)"
          [ngStyle]="{ color: 'rgb(49, 175, 55)' }"
        ></em>
        <em
          *ngIf="validOrInvalid === 2"
          class="far fa-frown-o fa-5x"
          style="color: black"
        ></em>
      </div>
      <h1 class="text-center" style="color: black">{{ title }}</h1>
      <br />
      <div
        class="text-center"
        style="margin-left: 15%; margin-right: 15%; color: black"
      >
        <h5>{{ paragraph }}</h5>
      </div>
    </div>
  </div>
  <br /><br /><br />
  <div class="button-group button-group-center">
    <app-button color="SECONDARY" (onClick)="doCancel()">{{
      'app.button.backToPortal' | translate
    }}</app-button>
  </div>
</div>
