import { AddressBook } from '../../core/bean/address-book';
import { BudgetType } from '../../core/bean/budget-type';
import { CostCenter } from '../../core/bean/cost-center';
import { Currency } from '../../core/bean/currency';
import { File } from '../../core/bean/file';
import { Organization } from '../../core/bean/organization';
import { ProcurementType } from '../../core/bean/procurement-type';
import { WorkPlan } from '../../core/bean/work-plan';
import { WorkPlanAddress } from '../../core/bean/work-plan-address';
import { WorkPlanItem } from '../../core/bean/work-plan-item';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path/model/approval-path-response-model';

export class WorkPlanResponse {
  workPlan: WorkPlan = new WorkPlan();
  workPlanItemList: WorkPlanItem[] = [];
  workPlanAddressList: WorkPlanAddress[] = [];
  organizationIdList: number[] = [];
  fileList: File[];
  approvalMemoDocList: File[];
  organizationList: Organization[] = [];
  addressBookList: AddressBook[] = [];
  moduleCode: string;
  isUserApproval: boolean;
  workPlanItemProductList: WorkPlanItem[] = [];
  workPlanItemServiceList: WorkPlanItem[] = [];
  budgetTypeList: BudgetType[] = [];
  costCenterList: CostCenter[] = [];
  currencyList: Currency[] = [];
  procurementTypeList: ProcurementType[] = [];
  approvalPathResponseModel: ApprovalPathResponseModel =
    new ApprovalPathResponseModel();
}
