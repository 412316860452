import {
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';

@Component({
  selector: 'app-attendance-form-card',
  templateUrl: './app-attendance-form-card.component.html',
  styleUrls: ['./app-attendance-form-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppAttendanceFormCardComponent
  extends BaseComponentComponent
  implements OnInit
{
  @Input() public header: string;
  @Input() public color: string;
  @ContentChild('customHeader') customHeaderTmpl: TemplateRef<any>;

  constructor() {
    super('attendance-form');
  }

  public onInit(): void {
    this.setStateReady();
  }
}
