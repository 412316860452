import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  UrlSegment
} from '@angular/router';
import { Subscription } from 'rxjs';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { Global } from '../../../../core/global/global';
import { PromiseThemeService } from '../../promise-theme.service';
@Component({
  selector: 'theme-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ThemeBreadcrumbComponent implements OnDestroy {
  public settings: Settings;
  public pageTitle: string;
  public breadcrumbs: {
    name: string;
    url: string;
  }[] = [];
  public dashboard: string;

  private subscription: Subscription;
  constructor(
    public appSettings: AppSettings,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public title: Title,
    public global: Global,
    public promiseThemeService: PromiseThemeService
  ) {
    this.settings = this.appSettings.settings;
    this.subscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.addNavigationToNavigationHistory(event);
        this.breadcrumbs = [];
        this.parseRoute(this.router.routerState.snapshot.root);
        this.pageTitle = '';
        this.breadcrumbs.forEach(breadcrumb => {
          this.pageTitle +=
            ' > ' + this.global.translateService.instant(breadcrumb.name);
        });
        this.title.setTitle(this.settings.name + this.pageTitle);

        if (this.global.alertService.nextModal) {
          this.global.alertService.showNextModal();
        }
        this.global.alertService.nextModal = false;
      }
    });
    this.dashboard = '/pages/dashboard';
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  addNavigationToNavigationHistory(event: NavigationEnd): void {
    if (
      this.global.navigationHistory.currentNavigation &&
      this.global.navigationHistory.currentNavigation.routerUrl === event.url
    ) {
      // kondisi untuk back dari level 3 atau back ke router lain belum bisa,
      // saat ini jika tidak dicomment akan membuat error null saat buka detail.
      // this.global.routerParams =
      //   this.global.navigationHistory.currentNavigation.routerParams;
    } else {
      this.global.navigationHistory.addNavigationRouter({
        routerUrl: event.url,
        routerParams: Object.assign(new Map(), this.global.routerParams)
      });
    }
  }

  parseRoute(node: ActivatedRouteSnapshot): void {
    if (node.data['breadcrumb'] && node.url.length) {
      let urlSegments: UrlSegment[] = [];
      node.pathFromRoot.forEach(routerState => {
        urlSegments = urlSegments.concat(routerState.url);
      });
      const url = urlSegments.map(urlSegment => urlSegment.path).join('/');
      this.breadcrumbs.push({
        name: node.data['breadcrumb'],
        url: '/' + url
      });
    }
    if (node.firstChild) {
      this.parseRoute(node.firstChild);
    }
  }

  // doClick(url: string): void {
  //   this.router.navigate([url]);
  // }
}
