import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { FinesMaster } from '../../core/bean/fines-master';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { FinesMonitoringView } from '../../core/view/entity/bean/fines-monitoring-view';
import { AppTableComponent } from './../../core/components/app-table/app-table.component';
import { FinesMonitoringResponse } from './response/fines-monitoring.response';
import { Response } from 'src/app/core/model/response-model';
@Component({
  templateUrl: './fines-monitoring.component.html'
})
export class FinesMonitoringComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<FinesMaster>;
  public agreementNumberOptionList: OptionListModel<any> = new OptionListModel(
    true,
    'number'
  );
  public vendorOptionList: OptionListModel<any> = new OptionListModel(
    true,
    'name'
  );

  public procurementOptionList: OptionListModel<any> = new OptionListModel(
    true,
    'name'
  );
  constructor(translateService: TranslateService) {
    super('fines-monitoring', translateService);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setOptionList();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      startDate: [null],
      endDate: [null],
      agreementList: [null],
      vendorList: [null],
      procurementList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
    .get<FinesMonitoringResponse>('/fines-monitoring/get-option-list')
      .subscribe(response => {
        const agreementList = [];
        const vendorList = [];
        const procurementList = [];

        for (let i = 0; i < response.agreementNumberList.length; i++) {
          const object = {
            id: i,
            number: response.agreementNumberList[i]
          };
          agreementList.push(object);
        }
        for (let i = 0; i < response.vendorNameList.length; i++) {
          const object = {
            id: i,
            name: response.vendorNameList[i]
          };
          vendorList.push(object);
        }
        for (let i = 0; i < response.procurementNameList.length; i++) {
          const object = {
            id: i,
            name: response.procurementNameList[i]
          };
          procurementList.push(object);
        }

        this.agreementNumberOptionList.setRequestValues(agreementList);
        this.vendorOptionList.setRequestValues(vendorList);
        this.procurementOptionList.setRequestValues(procurementList);
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'agreementNumber',
        header: 'table.column.agreementNumber',
        customClass: 'text-center'
      },
      {
        field: 'procurementName',
        header: 'table.column.procurementName'
      },
      {
        field: 'vendorName',
        header: 'table.column.vendorName'
      },
      {
        field: 'startDate',
        header: 'table.column.agreementStartDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.agreementEndDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      }
    ]);
  }

  public doApply(): void {
    this.global.routerParams.clear();
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }

  public doDetail(finesMonitoringView: FinesMonitoringView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set(
      'procurementScopeWorkId',
      finesMonitoringView.id
    );
    this.global.routerParams.set('finesMonitoringView', finesMonitoringView);
    this.router.navigate(['/pages/fines-monitoring/edit']);
  }

  public doSendEmail(finesMonitoringView: FinesMonitoringView): void  {
    this.global.modalService
      .confirm(this.translateService.instant('fines-monitoring.confirm.msg.sendEmail'),
        'fines-monitoring.confirm.title.sendEmail')
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.httpClientService
            .post<Response<FinesMonitoringView>>(
              '/fines-monitoring/send-email-fines-warning-contract',
              finesMonitoringView.id
            ).subscribe((response: Response<FinesMonitoringView>) => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccess(
                  this.translateService.instant(
                    'fines-monitoring.notification.msg.success.sendEmail'
                  )
                );
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });

        }
      });
  }
}
