<div class="text-center py-4" *ngIf="formLoading">
  <img src="assets/img/common/loader-small.gif" alt="Loading" />
</div>
<app-card *ngIf="!formLoading">
  <form>
    <app-table-xx
      *ngIf="!formLoading"
      header="app-deliverable-item.table.goods.title"
      [model]="tableResponseProduct"
      (onClick)="doDetail($event)"
    >
    </app-table-xx>
    <app-table-xx
      *ngIf="!formLoading"
      header="app-deliverable-item.table.service.title"
      [model]="tableResponseService"
      (onClick)="doDetail($event)"
    >
    </app-table-xx>
  </form>
</app-card>
