<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="registration-document.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'registration-document.form.upload' | translate
          }}</label>
          <div class="col-sm-9">
            <app-tab formArrayName="registrationDocumentList" [bordered]="true">
              <app-tab-item
                header="{{ registrationDocument.value.lang.name }}"
                *ngFor="
                  let registrationDocument of formArrayRegistDocList.controls;
                  let i = index
                "
                [formGroup]="registrationDocument"
              >
                <div class="row">
                  <div class="col-sm-12 card-body text-left text-break">
                    <app-upload
                      [fileUploader]="fileUploaderList[i]"
                      formControlName="file"
                    >
                    </app-upload>
                  </div>
                </div>
              </app-tab-item>
            </app-tab>
          </div>
          <div class="button-group button-group-center mt-5">
            <app-button
              color="SECONDARY"
              (onClick)="doCancel()"
              [disabled]="formSaving"
            >
              {{ 'app.button.back' | translate }}
            </app-button>
            <app-button (onClick)="doSave()" [disabled]="formSaving">
              {{ 'app.button.save' | translate }}
            </app-button>
          </div>
        </div>
      </form>
    </app-card>
  </div>
</div>
