import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Catalog } from '../../bean/catalog';
import { CatalogBulkPrice } from '../../bean/catalog-bulk-price';
import { CatalogContract } from '../../bean/catalog-contract';
import { CatalogRegionBulkPrice } from '../../bean/catalog-region-bulk-price';
import { CatalogSpecification } from '../../bean/catalog-specification';
import { CatalogView } from '../../view/entity/bean/catalog-view';
import { AppPopupService } from '../app-popup/app-popup.service';
import { AppCatalogDetailInfoResponse } from './app-catalog-detail-info.response';
import { AppPopupItemContractCatalogDetailInfoComponent } from './app-popup-item-contract-catalog-detail-info.component';

@Component({
  selector: 'app-catalog-detail-info',
  templateUrl: './app-catalog-detail-info.component.html'
})
export class AppCatalogDetailInfoComponent extends BaseComponentComponent {
  @Input() catalogId: number;
  @Input() mode = 'view';
  public appCatalogDetailInfoResponse: AppCatalogDetailInfoResponse;
  public catalog: Catalog;
  public catalogContract: CatalogContract;
  public catalogView: CatalogView;
  public catalogBulkPriceList: CatalogBulkPrice[] =
    []; /** specific by catalog contract */
  public catalogRegionBulkPriceList: CatalogRegionBulkPrice[] = [];
  @Output() onChange: EventEmitter<AppCatalogDetailInfoResponse> =
    new EventEmitter();

  constructor(
    public currencyPipe: CurrencyPipe,
    public translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('app-catalog-detail-info');
  }

  public onInit(): void {
    this.getDataFromServer();
  }

  public getDataFromServer(): void {
    this.httpClientService
      .get<AppCatalogDetailInfoResponse>(
        '/app-catalog-detail-info/get-by-catalog-id/' + this.catalogId
      )
      .subscribe(
        (appCatalogDetailInfoResponse: AppCatalogDetailInfoResponse) => {
          this.appCatalogDetailInfoResponse = appCatalogDetailInfoResponse;
          this.catalog = this.appCatalogDetailInfoResponse.catalog;
          this.catalogContract =
            this.appCatalogDetailInfoResponse.catalogContract;
          this.catalogView =
            this.appCatalogDetailInfoResponse.catalogView;
          /*this.catalogBulkPriceList = this.appCatalogDetailInfoResponse
            .catalogBulkPriceList
            ? this.appCatalogDetailInfoResponse.catalogBulkPriceList.filter(
                bulkPrice =>
                  bulkPrice?.catalogContract?.id === this.catalogContract?.id
              )
            : [];*/

          this.catalogRegionBulkPriceList = this.appCatalogDetailInfoResponse
            .catalogRegionBulkPriceList
            ? this.appCatalogDetailInfoResponse.catalogRegionBulkPriceList.filter(
                bulkPrice =>
                  bulkPrice?.catalogRegion?.catalogContract?.id === this.catalogView?.catalogRegion?.catalogContract?.id
              )
            : [];

          this.setStateReady();
          this.onChange.emit(appCatalogDetailInfoResponse);
        }
      );
  }

  public onClickVendor(): void {
    this.global.routerParams.set(
      'vendorId',
      this.appCatalogDetailInfoResponse.catalogView.vendorId
    );
    this.router.navigate([
      '/pages/' + this.global.activeModuleCode.toLowerCase() + '/vendor-detail'
    ]);
  }

  public doParseValue(catalogSpesification: CatalogSpecification): string {
    let value = catalogSpesification.value;
    const parsedValue: any = JSON.parse(value);
    if (parsedValue) {
      if (Array.isArray(parsedValue)) {
        value = '';
        parsedValue.forEach((valueObject, i) => {
          value += valueObject.name;
          if (i !== parsedValue.length - 1) {
            value += ', ';
          }
        });
      } else if (typeof parsedValue === 'string') {
        value = parsedValue;
      } else if (
        catalogSpesification.catalogAttribute.formObject.code === 'FIELD-PRICE'
      ) {
        const price = this.currencyPipe.transform(
          parsedValue.price,
          '',
          '',
          '1.2-2'
        );
        value = price + ' ' + parsedValue.currency.code;
      } else {
        value = parsedValue.name;
      }
    } else {
      value = '-';
    }

    return value;
  }

  public doConvertToDate(value: string): Date {
    const parsedValue = JSON.parse(value);
    return new Date(parsedValue);
  }

  public doViewItemContract(): void {
    this.appPopupService
      .open(
        AppPopupItemContractCatalogDetailInfoComponent,
        {
          catalogContractList:
            this.appCatalogDetailInfoResponse.catalogContractList,
          catalogBulkPriceList:
            this.appCatalogDetailInfoResponse.catalogRegionBulkPriceList, // all catalog bulk price from all catalog contract by catalog
          selectedCatalogContract: this.catalogContract
        },
        { size: 'xl' }
      )
      .subscribe((catalogContract: CatalogContract) => {
        this.catalogContract = catalogContract;
        this.appCatalogDetailInfoResponse.catalogContract =
          this.catalogContract;
        this.catalogBulkPriceList =
        this.catalogContract['catalogBulkPriceList'];
        this.catalogBulkPriceList =
        this.catalogContract['catalogBulkPriceList'];

        this.onChange.emit(this.appCatalogDetailInfoResponse);
      });
  }
}
