import { Component, ElementRef, Input, OnChanges } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import * as chartAction from './actions/chart.action';
import { ChartModel } from './models/chart-model';
import { ChartStoreService } from './services/chart-store.service';
@Component({
  selector: 'app-chart',
  templateUrl: './app-chart.component.html',
  styleUrls: ['./app-chart.component.scss'],
  providers: [ChartStoreService]
})
export class AppChartComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() model: ChartModel;
  @Input() height = '336px';
  constructor(
    public chartStoreService: ChartStoreService,
    public elementRef: ElementRef
  ) {
    super('app-chart');
  }

  public onInit(): void {
    if (this.model) {
      this.chartStoreService.setState(this.model);
      this.dispatchState();
      this.handleChartChanges();
    }
  }

  ngOnChanges(): void {
    if (this.model) {
      this.chartStoreService.setState(this.model);
      this.dispatchState();
      this.handleChartChanges();
    }
  }

  private handleChartChanges(): void {
    this.model.typeChanges.subscribe(() => this.dispatchState());
    this.model.reloadChanges.subscribe(() => this.dispatchState());
  }

  private dispatchState(): void {
    switch (this.model.type) {
      case 'line':
        this.chartStoreService.dispatch(new chartAction.SetLineChart());
        break;
      case 'bar':
        this.chartStoreService.dispatch(new chartAction.SetBarChart());
        break;
      case 'doughnut':
        this.chartStoreService.dispatch(new chartAction.SetDoughnutChart());
        break;
      case 'radar':
        this.chartStoreService.dispatch(new chartAction.SetRadarChart());
        break;
      case 'pie':
        this.chartStoreService.dispatch(new chartAction.SetPieChart());
        break;
      case 'polarArea':
        this.chartStoreService.dispatch(new chartAction.SetPolarAreaChart());
        break;
      case 'bubble':
        this.chartStoreService.dispatch(new chartAction.SetBubbleChart());
        break;
      case 'scatter':
        this.chartStoreService.dispatch(new chartAction.SetScatterChart());
        break;
      default:
        this.log.debug(`There's no chart type`);
        break;
    }
  }
}
