import { Injectable } from '@angular/core';
import { AppPopupService } from '../app-popup.service';
import { AppPopupAddressBookComponent } from './app-popup-address-book.component';

@Injectable()
export class AppPopupAddressBookService {
  constructor(public appPopupService: AppPopupService) {}

  public open() {
    return this.appPopupService.open(
      AppPopupAddressBookComponent,
      {},
      { size: 'lg', windowClass: 'modal-custom' }
    );
  }
}
