import { BaseEntity } from '../base/base-entity';
import { Currency } from '../bean/currency';
import { File } from '../bean/file';
import { Region } from '../bean/region';
import { VendorExperienceSegmentation } from '../bean/vendor-experience-segementation';
import { VendorExperienceSegmentationHistory } from '../bean/vendor-experience-segmentation-history';
import { VendorExperienceStatus } from '../bean/vendor-experience-status';
import { VendorExperienceTitle } from '../bean/vendor-experience-title';
import { VendorHistory } from '../bean/vendor-history';
import { FileObject } from '../components/upload';
export class VendorExperienceHistoryEntity extends BaseEntity {
  segmentationNames: string;
  segmentationNamesHistory: string;
  crudInfo: string;
  fileObjectList: FileObject[] = [];
  vendorExperienceSegmentationList: VendorExperienceSegmentation[];
  vendorExperienceSegmentationHistoryList: VendorExperienceSegmentationHistory[];

  vendorHistory: VendorHistory;
  countryRegion: Region;
  cityRegion: Region;
  vendorExperienceStatus: VendorExperienceStatus;
  file: File;
  currency: Currency;
  vendorExperienceTitle: VendorExperienceTitle;
  createdDate: Date;
  projectName: string;
  businessPartnerName: string;
  partnership: string;
  address: string;
  projectOwner: string;
  projectType: string;
  contractNumber: string;
  contractDate: Date;
  contractValue: number;
  coordinateLocation: string;
  partnershipStatus: string;
  vendorExperienceId: number;
}
