<div class="image-box-slideshow-main">
  <img
    class="image-main"
    [attr.src]="imageUrl | resolveImgSrc | async"
    [disabled]="true"
    alt="image"
    [ngClass]="isPopupEnable ? 'cursor-pointer' : ''"
    (click)="onClickShowImage()"
  />
</div>
<br />
<div
  id="myCarousel"
  class="carousel slide"
  data-interval="false"
  style="height: auto"
>
  <!-- Wrapper for slides -->
  <div class="carousel-inner">
    <div
      *ngFor="let tempList of urlList; let i = index"
      class="carousel-item"
      [ngClass]="activeCarousel === i ? 'active' : ''"
    >
      <div class="row">
        <div class="col-sm-1"></div>
        <div
          *ngFor="let url of tempList; let j = index"
          class="col image-box-slideshow"
        >
          <img
            *ngIf="url !== 'No Image'"
            [attr.src]="
              global.config.BACKEND_ADDRESS +
                '/' +
                parentModuleCode +
                '/thumbnail/view/' +
                url.uploadedFileName +
                '/' +
                url.fileType.code
                | resolveImgSrc
                | async
            "
            [disabled]="true"
            alt="image"
            class="cursor-pointer image-indicator"
            (click)="onClickImage(url, i, j)"
            [ngClass]="[activeIndex === 3 * i + j ? 'image-choosen' : '']"
          />
        </div>
        <div class="col-sm-1"></div>
      </div>
    </div>
  </div>

  <a class="carousel-control-prev control-left" (click)="onPrevious()">
    <em class="fas fa-chevron-circle-left fa-lg"></em>
  </a>
  <a class="carousel-control-next control-right" (click)="onNext()">
    <em class="fas fa-chevron-circle-right fa-lg"></em>
  </a>
</div>
