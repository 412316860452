import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupCompanyOrganizationComponent } from './popup/app-popup-company-organization.component';
import { TabProfileCompanyOrganizationComponent } from './tab-profile-company-organization.component';

export const routes = [
  {
    path: '',
    component: TabProfileCompanyOrganizationComponent,
    data: { breadcrumb: '' }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    TabProfileCompanyOrganizationComponent,
    AppPopupCompanyOrganizationComponent
  ],
  entryComponents: [
    AppPopupCompanyOrganizationComponent
  ],
  exports: [
    TabProfileCompanyOrganizationComponent,
    AppPopupCompanyOrganizationComponent
  ]
})

export class TabProfileCompanyOrganizationModule {}
