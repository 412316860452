<app-popup>
  <img
    src="assets/img/common/loader-small.gif"
    alt="Loading"
    height="11"
    width="40"
    *ngIf="!isShowCheckMark"
  />
  <ng-container *ngIf="isShowCheckMark">
    <svg
      class="checkmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
    >
      <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path
        class="checkmark__check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
  </ng-container>
  <span class="text-center d-block mt-2">{{ message | translate }}</span>
</app-popup>
