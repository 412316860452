import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { DistributionRegion } from 'src/app/core/bean/distribution-region';
import { Region } from 'src/app/core/bean/region';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { AddressBook } from './../../core/bean/address-book';
import { DistributionRegionRequest } from './distribution-region-request';
import { DistributionRegionResponse } from './distribution-region-response';
@Component({
  templateUrl: './distribution-region-edit-add.component.html'
})
export class DistributionRegionEditAddComponent extends BaseModuleComponent {
  public distributionRegionId: number;
  public distributionRegion: DistributionRegion = new DistributionRegion();
  public regionOptionList: OptionListModel<Region> = new OptionListModel(
    true,
    'name'
  );

  constructor(
    translateService: TranslateService
  ) {
    super('distribution-region', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.setOptionListAndFormGroup();
    this.doGetAndSetDistributionRegion();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.distributionRegionId = this.global.routerParams.get('distributionRegionId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        {
          validators: Validators.compose([
            Validators.required(),
            Validators.maxLength(64)
          ]),
          asyncValidators: [
            Validators.existsAsync('/distribution-region/check-exist', null, null, {
              id: this.distributionRegionId
            })
          ]
        }
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(128)])
      ],
      regionList: [
        null,
        Validators.compose([Validators.required()])
      ]
    });
  }

  public setOptionListAndFormGroup(): void {
    this.httpClientService
      .get('/distribution-region/get-region-list')
      .subscribe((region: Region[]) => {
        this.regionOptionList.setRequestValues(region);
        this.setStateReady();
      });
  }

  public doGetAndSetDistributionRegion(): void {
    this.httpClientService
      .post<DistributionRegionResponse>(
        '/distribution-region/add-edit',
        new RouteRequestModel(this.todo, this.distributionRegionId)
      )
      .subscribe((distributionRegionResponse: DistributionRegionResponse) => {
        if (distributionRegionResponse.distributionRegion) {
          const {
            id,
            code,
            name
          } = distributionRegionResponse.distributionRegion;
          this.formGroup.patchValue({
            id,
            code,
            name,
            regionList : distributionRegionResponse.regionList
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const distributionRegion = this.global.copyFormAttributeToModel(
                new DistributionRegion(),
                this.formGroup
              );
            const distributionRegionRequest: DistributionRegionRequest =
              new DistributionRegionRequest();
              distributionRegionRequest.distributionRegion = distributionRegion;
              distributionRegionRequest.regionList = this.formGroup.get('regionList').value;
              console.log(this.formGroup.get('regionList').value);
            const url =
              this.todo === 'edit'
                ? '/distribution-region/update'
                : '/distribution-region/insert';
            this.httpClientService
              .post<Response<AddressBook>>(url, distributionRegionRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/distribution-region']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doBack(): void {
    this.router.navigate(['/pages/distribution-region']);
  }
}
