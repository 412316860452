import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ResponseRfiQuestionDetailComponent } from './question/response-rfi-question-detail.component';
import { ResponseRfiQuestionComponent } from './question/response-rfi-question.component';
import { ResponseRfiDetailComponent } from './response-rfi-detail.component';
import { ResponseRfiEditComponent } from './response-rfi-edit.component';
import { ResponseRfiComponent } from './response-rfi.component';

export const routes = [
  { path: '', component: ResponseRfiComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: ResponseRfiEditComponent,
    data: { breadcrumb: 'response-rfi.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'question',
    component: ResponseRfiQuestionComponent,
    data: { breadcrumb: 'response-rfi.breadcrumb.question' }
  },
  {
    path: 'detail',
    component: ResponseRfiDetailComponent,
    data: { breadcrumb: 'response-rfi.breadcrumb.detail' }
  },
  {
    path: 'question-detail',
    component: ResponseRfiQuestionDetailComponent,
    data: { breadcrumb: 'response-rfi.breadcrumb.questionDetail' }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],

  declarations: [
    ResponseRfiComponent,
    ResponseRfiEditComponent,
    ResponseRfiQuestionComponent,
    ResponseRfiDetailComponent,
    ResponseRfiQuestionDetailComponent
  ],

  providers: []
})
export class ResponseRfiModule {}
