<dashboard-widget-card
  header="{{ 'dashboard-widget-total-contract.header' | translate }}"
  size="MD"
  [isLoading]="model.isLoading"
>
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    novalidate
    customClassHeader="align-items-center"
    *ngIf="!model.isLoading"
  >
    <ng-template #headerRight>
      <div class="d-flex">
        <div [ngStyle]="{ width: '150px' }" class="mr-2">
          <app-dropdown-select
            type="COMBOBOX"
            size="XL"
            container="body"
            formControlName="year"
            [optionList]="yearOptionList"
            (onChange)="onChangeFormGroup($event)"
            placeholder="dashboard-widget-total-contract.form.placeholder.year"
          >
          </app-dropdown-select>
        </div>
        <div [ngStyle]="{ width: '150px' }">
          <app-dropdown-select
            type="COMBOBOX"
            size="XL"
            container="body"
            formControlName="filterBy"
            [optionList]="filterOptionList"
            (onChange)="onChangeFormGroup($event)"
          >
          </app-dropdown-select>
        </div>
      </div>
    </ng-template>
  </form>
  <div class="d-flex flex-column justify-content-between h-100">
    <div style="max-width: 200px; margin-left: auto; margin-right: auto">
      <div class="d-block">
        <app-chart [model]="chartModel" height="200px"></app-chart>
      </div>
      <div
        class="chart-shadow bg-light d-block w-100 my-2"
        style="height: 26px; border-radius: 100%"
      ></div>
    </div>
    <ul class="list">
      <li
        class="list-item w-50 d-inline-flex align-items-center"
        *ngFor="let legend of legendList"
      >
        <span
          style="width: 20px; height: 20px"
          [ngStyle]="{ background: legend.color }"
          class="d-inline-block mr-2"
        ></span>
        {{ legend.percentage }} -
        <span class="text-secondary d-inline-block ml-2">
          {{ legend.label }}
        </span>
      </li>
    </ul>
  </div>
</dashboard-widget-card>
