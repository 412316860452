import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { QuotationEvaluation } from '../../core/bean/quotation-evaluation';
import { QuotationEvaluationDtl } from '../../core/bean/quotation-evaluation-dtl';
import { ScoringStatus } from '../../core/bean/scoring-status';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';
import { AdministrationEvaluationPopupRequest } from './administration-evaluation-popup-request';

@Component({
  templateUrl: './administration-evaluation-popup-detail.component.html'
})
export class AdministrationEvaluationPopupDetailComponent extends BaseComponentComponent {
  @Input() todo: string;
  @Input() quotationEvaluation: QuotationEvaluation;
  @Input() scoringStatusList: ScoringStatus[];
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public tableResponse: TableResponseModel<QuotationEvaluationDtl>;
  public scoringStatusOptionList: OptionListModel<ScoringStatus> =
    new OptionListModel();
  public isFullFill = false;
  public isSave = false;
  public statusResult: ScoringStatus;

  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    super('administration-evaluation');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setFormGroup();
  }

  public getColorAndIconConfirm = {
    PASSED: { color: 'text-success', icon: 'pic pi-thumbs-up-circle' },
    NOT_PASSED: { color: 'text-danger', icon: 'pic pi-times-circle' }
  };

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      recordList: this.tableResponse.formArray
    });
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setFormGroup(): void {
    this.statusResult = this.quotationEvaluation.scoringStatus;
    if (
      this.statusResult &&
      this.statusResult.code !==
        this.global.appConstant.pm.SCORING_STATUS_WAITING_SCORING
    ) {
      this.isFullFill = true;
    }
    if (
      this.quotationEvaluation.quotationEvaluationDtlList &&
      this.quotationEvaluation.quotationEvaluationDtlList.length > 0
    ) {
      this.setTableResponse(
        this.quotationEvaluation.quotationEvaluationDtlList
      );
    } else {
      const request: AdministrationEvaluationPopupRequest =
        new AdministrationEvaluationPopupRequest();
      request.quotationId = this.quotationEvaluation.quotation.id;
      request.quotationEvaluationId = this.quotationEvaluation.id;
      this.httpClientService
        .post(
          '/administration-evaluation/get-quotation-evaluation-dtl',
          request
        )
        .subscribe((response: QuotationEvaluationDtl[]) => {
          this.setTableResponse(response);
        });
    }
    this.scoringStatusOptionList.setRequestValues(this.scoringStatusList);
  }

  public setTableResponse(response: QuotationEvaluationDtl[]): void {
    this.tableResponse.setRecordList(response);
    this.tableResponse.reload();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field:
          'quotationCriteria.procurementCriteriaDetail.evaluationCriteria.name',
        header: 'table.column.administrationCriteria'
      },
      {
        field: 'quotationCriteria.note',
        header: 'table.column.noteFromVendor'
      },
      {
        field: 'quotationCriteria.docFile.fileName',
        header: 'table.column.value',
        plugins: [
          {
            name: 'hyperlink',
            onClick: this.doDownloadFile.bind(this),
            className: 'text-primary'
          },
          {
            name: 'custom-plugin',
            after: (tablePlugin: TablePluginData): void => {
              if (!tablePlugin.value) {
                tablePlugin.element.innerText =
                  tablePlugin.row.record.quotationCriteria.value;
              }
            }
          }
        ]
      },
      {
        field: 'scoringStatus',
        header: 'table.column.status',

        plugins: {
          name: 'drop-down-select',
          optionList: this.scoringStatusOptionList,
          validators: Validators.required(),
          placeholder:
            'administration-evaluation.table.column.placeholder.status',
          isView: !!(this.todo === 'view'),
          onChange: this.checkStatus.bind(this),
          customValue: (value: any): string => {
            if (value) {
              return this.getStringCustomValue(value);
            } else {
              return null;
            }
          },
          customOption: (option: any): string => {
            return this.translateService.instant(this.getTranslateKey(option));
          }
        }
      },
      {
        field: 'note',
        header: 'table.column.note',
        plugins: {
          name: 'text-field',
          isView: !!(this.todo === 'view'),
          onInput: this.checkStatus.bind(this),
          placeholder: this.translateService.instant(
            'administration-evaluation.table.column.placeholder.note'
          )
        }
      }
    ]);
    this.buildFormGroup();
  }

  public doDownloadFile(quotationEvaluationDtl: QuotationEvaluationDtl): void {
    if (quotationEvaluationDtl?.quotationCriteria?.docFile) {
      const urlFile =
        '/administration-evaluation/file/view/' +
        quotationEvaluationDtl.quotationCriteria.docFile.uploadedFileName +
        '/' +
        quotationEvaluationDtl.quotationCriteria.docFile.fileType.code;
      this.downloadService.download(
        urlFile,
        quotationEvaluationDtl.quotationCriteria.docFile.fileName
      );
    }
  }
  public checkStatus(): void {
    const status =
      this.tableResponse.currentRowChange.row.formGroup.get(
        'scoringStatus'
      ).value;
    const noteFormGroup =
      this.tableResponse.currentRowChange.row.formGroup.get('note');

    if (status?.code === this.global.appConstant.pm.SCORING_STATUS_NOT_PASSED) {
      noteFormGroup.setValidators(Validators.required());
      if (!noteFormGroup.value) {
        noteFormGroup.setErrors({
          message: this.translateService.instant('app.validation.required')
        });
      }
    } else {
      noteFormGroup.clearValidators();
      noteFormGroup.setErrors(null);
    }
    noteFormGroup.markAsTouched();
    noteFormGroup.updateValueAndValidity;
    this.isFullFill =
      this.tableResponse.formArray.value.length === 0 ||
      this.tableResponse.formArray.value.filter(data => !data.scoringStatus)
        .length > 0
        ? false
        : true;
    if (this.isFullFill) {
      const statusNotPassed =
        this.tableResponse.formArray.value.filter(
          data =>
            data.scoringStatus?.code ===
            this.global.appConstant.pm.SCORING_STATUS_NOT_PASSED
        ).length > 0;
      this.statusResult = statusNotPassed
        ? this.scoringStatusOptionList
            .getRequestValues()
            .filter(
              scoringStatus =>
                scoringStatus.code ===
                this.global.appConstant.pm.SCORING_STATUS_NOT_PASSED
            )[0]
        : this.scoringStatusOptionList
            .getRequestValues()
            .filter(
              scoringStatus =>
                scoringStatus.code ===
                this.global.appConstant.pm.SCORING_STATUS_PASSED
            )[0];
    }
    console.log(this.tableResponse);
  }

  public getStringCustomValue(value: any): string {
    const color = this.getColorAndIconConfirm[value.code].color;
    const icon = this.getColorAndIconConfirm[value.code].icon;
    const text = this.translateService.instant(this.getTranslateKey(value));
    return (
      '<div style="display: inline-flex"><em style="font-size: 1.5rem" class="' +
      icon +
      '"></em>' +
      '&nbsp;<span class="' +
      color +
      '">' +
      text +
      '</span></div>'
    );
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public goToProfile(): void {
    localStorage.setItem(
      'vendorId',
      this.quotationEvaluation.quotation.procurementVendor.vendor.id.toString()
    );
    localStorage.setItem('urlBackOutside', 'close-window');
    window.open('/pages/company-profile');
  }

  public doSave(): void {
    this.isSave = true;
    this.validate();
    if (this.isFullFill && this.formGroup.valid) {
      this.setStateProcessing();
      this.quotationEvaluation.evaluationDate = new Date();
      this.quotationEvaluation.quotationEvaluationDtlList =
        this.tableResponse.getRecordList();
      this.quotationEvaluation.quotationEvaluationDtlList.forEach(
        (quotationEvalDtl, index) => {
          quotationEvalDtl.scoringStatus =
            this.tableResponse.formArray.controls[index].value.scoringStatus;
          quotationEvalDtl.note =
            this.tableResponse.formArray.controls[index].value.note;
        }
      );
      this.quotationEvaluation.scoringStatus = this.statusResult;
      this.httpClientService
        .post('/administration-evaluation/save-popup', this.quotationEvaluation)
        .subscribe(response => {
          this.onChange.emit(response.body);
          this.setStateReady();
        });
    }
  }
}
