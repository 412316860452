import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../vendor/vendor.module';
import { AppButtonModule } from '../../../app-button/app-button.module';
import { AppPopupModule } from '../../../app-popup/app-popup.module';
import { AppSelectModule } from '../../../app-select/app-select.module';
import { AppPopupModifyTableComponent } from './app-popup-modify-table.component';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppButtonModule, AppSelectModule],
  declarations: [AppPopupModifyTableComponent],
  exports: [AppPopupModifyTableComponent]
})
export class AppPopupModifyTableModule {}
