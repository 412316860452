import { BaseEntity } from '../base/base-entity';
import { Module } from '../bean/module';
import { Vendor } from '../bean/vendor';

export class ContractPreparationEntity extends BaseEntity {
    title: string;
    orderDate: Date;
    vendor: Vendor;
    isContractProcessed: boolean;
    source: string;
    module: Module;
    amount: number;
    isNextProcessExist: boolean;
    objectId: number;
    objectName: string;
}
