<div class="row view-catalog-detail main-row">
  <div class="col-12">
    <app-card header="view-catalog.productDetail" [isLoading]="formLoading">
      <div class="border rounded p-3 mb-3">
        <div class="item-card">
          <div class="row">
            <div class="col-4" *ngIf="!formLoading">
              <app-catalog-item-carousel [imageList]="catalogImageList">
              </app-catalog-item-carousel>
            </div>
            <div class="col-5">
              <h2>{{ viewCatalogDetail.catalogBeans.catalog.name }}</h2>
              <h5>{{ (viewCatalogDetail.catalogBeans.catalog.price | currency:
                '':'') + ' ' +
                viewCatalogDetail.catalogBeans.catalog.currency?.code }}</h5>
              <div class="mt-5">
                <p>{{ 'view-catalog.shippingFrom' | translate }}:
                  {{
                  viewCatalogDetail.catalogBeans.catalogInventoryList[0]?.cityRegion?.name
                  ||
                  viewCatalogDetail.catalogBeans.catalogInventoryList[0]?.region.name
                  }}</p>
                <p>{{ 'view-catalog.estimatedDeliveryTime' | translate }}: {{
                  viewCatalogDetail.catalogBeans.catalogInventoryList[0]?.estimatedDeliveryTime
                  }}</p>
                <p>{{ 'view-catalog.stockAvailable' | translate }}:
                  {{
                  viewCatalogDetail.catalogBeans.catalogInventoryList[0]?.stock
                  | number }}</p>
                <app-button-compare
                  (click)="doAddToComparisonList(viewCatalogDetail.catalogBeans.catalog)"
                  (keyup)="onKeyUp($event)" (keydown)="onKeyDown($event)">
                  {{ 'app-table-catalog.compare' | translate }}
                </app-button-compare>
              </div>
            </div>
            <div class="col-3">
              <div class="card-vendor-detail">
                <h4 class="title">{{ 'view-catalog.detailVendor' | translate }}
                </h4>
                <div class="card-vendor-detail-content">
                  <div class="vendor-card-header">
                    <h4 class="vendor-name">{{
                      viewCatalogDetail.catalogBeans.catalog.vendor?.name }}
                    </h4>
                    <app-button size="SM"
                      (onClick)="doView(viewCatalogDetail.catalogBeans.catalog.vendor.id)">
                      {{ 'view-catalog.view' | translate }}</app-button>
                  </div>
                  <div class="vendor-card-body">
                    <p><strong>{{ 'view-catalog.address' | translate }}</strong>
                    </p>
                    <p> {{ viewCatalogDetail.vendorAddress.buildingName ?
                      viewCatalogDetail.vendorAddress.buildingName + ', ' : ''
                      }}
                      {{
                      viewCatalogDetail.vendorAddress.address +
                      ', ' +
                      viewCatalogDetail.vendorAddress.cityRegion?.name +
                      ', ' +
                      viewCatalogDetail.vendorAddress.provinceRegion?.name +
                      ' - ' +
                      viewCatalogDetail.vendorAddress.countryRegion?.name
                      }}
                    </p>
                    <p>{{ 'view-catalog.phone' | translate }}: {{
                      viewCatalogDetail.vendorAddress.phoneRegion?.callingCode +
                      viewCatalogDetail.vendorAddress.phone || '-' }}</p>
                    <p>{{ 'view-catalog.fax' | translate }}: {{
                      viewCatalogDetail.vendorAddress.fax || '-' }}</p>
                    <p>{{ 'view-catalog.email' | translate }}: {{
                      viewCatalogDetail.vendorAddress.vendor?.email }}</p>
                  </div>
                  <div class="vendor-card-footer">
                    <div class="icon-group">
                      <em class="far fa-envelope cursor-pointer"
                        (click)="doOpenPopupEmail()"
                        title="{{ 'view-catalog.composeEmail' | translate }}"
                        (keyup)="onKeyUp($event)"
                        (keydown)="onKeyDown($event)"></em>
                      <em class="far fa-map cursor-pointer"
                        (click)="doOpenPopupMap()"
                        title="{{ 'view-catalog.location' | translate }}"
                        (keyup)="onKeyUp($event)"
                        (keydown)="onKeyDown($event)"></em>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-catalog-tab [model]="viewCatalogDetail"></app-catalog-tab>
      <div *ngIf="!formLoading" class="border rounded px-3 pt-4 pb-2 my-3">
        <app-catalog-similar-product [catalogId]="catalogId">
        </app-catalog-similar-product>
      </div>
      <div class="button-group button-group-center mt-5">
        <app-button color="SECONDARY" (onClick)="doBack()">{{ 'app.button.back'
          | translate }}</app-button>
      </div>
    </app-card>
  </div>
</div>