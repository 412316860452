import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../../../../../core/base/angular/base-component.component';
@Component({
  selector: 'app-catalog-item-categories',
  templateUrl: './app-catalog-item-categories.component.html',
  styleUrls: ['./app-catalog-item-categories.component.scss']
})
export class AppCatalogItemCategoriesComponent extends BaseComponentComponent {
  @Input() nameList: string[];

  constructor() {
    super('app-catalog-item-categories');
  }

  public onInit(): void {
    this.setStateReady();
  }
}
