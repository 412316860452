import {
  Component,
  Input,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  selector: 'app-tooltip-wrapper',
  templateUrl: './app-tooltip-wrapper.component.html',
  styleUrls: ['./app-tooltip-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppTooltipWrapperComponent extends BaseComponentComponent {
  @Input() tooltip: string | TemplateRef<any>;
  @Input() position = 'top';
  @Input() symbolic: string;
  @Input() stringUrl: string;
  @Input() customData: string;
  @Input() isView: boolean;
  @Input() tooltipClass: string;
  public arrayPosition = ['top', 'right', 'bottom', 'left'];
  public isStringTooltip: boolean;
  @ViewChild('t') testingTooltip: NgbTooltip;
  constructor() {
    super('app-tooltip-wrapper');
  }

  onInit(): void {
    this.setPosition();
    this.setTooltipPosition();
    this.doSetIsStringTooltip();
  }

  public doSetIsStringTooltip(): void {
    this.isStringTooltip = typeof this.tooltip === 'string';
  }

  public setPosition(): void {
    this.position = !this.position ? 'top' : this.position;
  }

  public setTooltipPosition(): void {
    const indexOfPosition = this.arrayPosition.findIndex(
      position => position === this.position.toLowerCase()
    );
    if (indexOfPosition === -1) {
      this.position = 'top';
    } else {
      this.position = this.position.toString().toLowerCase();
    }
  }

  public handleMouseEnter(): void {
    this.httpClientService
      .post(this.stringUrl, this.customData)
      .subscribe((response: any) => {
        this.tooltip = response.statusText;
      });
  }
}
