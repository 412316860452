<app-popup
  [isLoading]="formLoading"
  header="compliance-test.popup.vendorWinnerInfo.header"
>
  <app-table-xx
    [isShowPagination]="false"
    [isShowSearch]="false"
    [model]="tableResponse"
    *ngIf="!formLoading"
    stringUrl="/compliance-test/table-vendor-winner"
  >
  </app-table-xx>
</app-popup>
