<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-x
        [model]="tableResponse"
        stringUrl="/pr/index"
        (onClick)="doClick($event)"
        header="{{ 'pr.table.header' | translate }}"
      >
        <ng-template #headerFilterGroup>
          <form
            class="form-horizontal pt-4"
            [formGroup]="formGroup"
            novalidate
            *ngIf="!formLoading"
          >
            <div class="form-group row text-sm-left">
              <label class="col-sm-1" style="margin-top: auto">{{
                'pr.table.column.requiredDate' | translate
              }}</label>
              <div class="col-sm-8 text-left" style="display: flex">
                <app-date-picker-x
                  size="XL"
                  formControlName="date"
                  [range]="true"
                  (onChange)="onChangeFilter()"
                  [ngStyle]="{
                    'margin-right': '7px',
                    width: 'inherit'
                  }"
                >
                </app-date-picker-x>
                <app-dropdown-select
                  *ngIf="!formLoading"
                  size="XL"
                  formControlName="organizationList"
                  (onChange)="onChangeFilter()"
                  placeholder="{{ 'pr.placeholder.department' | translate }}"
                  [optionList]="organizationOptionList"
                  type="CHOSEN"
                  [ngStyle]="{
                    'margin-right': '7px',
                    width: '467px'
                  }"
                  showLimitValue="1"
                >
                  <ng-template #selectAll>
                    <p> {{ 'pr.placeholder.allDepartment' | translate }} </p>
                  </ng-template></app-dropdown-select
                >
                <app-dropdown-select
                  size="LG"
                  formControlName="prStatusList"
                  placeholder="{{ 'pr.placeholder.status' | translate }}"
                  [optionList]="prStatusOptionList"
                  (onChange)="onChangeFilter()"
                  type="CHOSEN"
                  [ngStyle]="{
                    width: 'inherit'
                  }"
                  showLimitValue="1"
                >
                  <ng-template #selectAll>
                    <p> {{ 'pr.placeholder.allStatus' | translate }} </p>
                  </ng-template></app-dropdown-select
                >
              </div>
            </div>
          </form>
        </ng-template>
        <ng-template #headerButtons>
          <app-button
            (onClick)="doPrintReport()"
            mode="BYPASS"
            title="{{ 'app.button.print' | translate }}"
          >
            <em class="fas fa-print"></em>&nbsp;
            {{ 'app.button.printReport' | translate }}
          </app-button>
          <app-button
            (onClick)="doAdd()"
            title="{{ 'app.button.add' | translate }}"
          >
            <em class="pir pi-plus-circle"></em>
            {{ 'pr.index.placeholder.newRequest' | translate }}
          </app-button>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            (onClick)="doDelete(rowData)"
            title="{{ 'app.tooltip.delete' | translate }}"
          >
            <em class="pir pi-trash-alt"></em>
            {{ 'app.button.delete' | translate }}
          </app-button>
        </ng-template>
      </app-table-x>
    </div>
  </div>
</app-layout>
