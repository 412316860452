import { FileType } from '../../../bean/file-type';
import { AppConstant } from '../../../constant/app-constant';
import { Global } from '../../../global/global';
import { UploaderOptions } from '../interface/uploader-options';
import { Uploader } from '../model/uploader';
export function createModelOptions(
  uploader: Uploader,
  global: Global
): Uploader {
  const fileType: FileType = global.config.allowedFile.get(
    uploader.fileTypeCode
  );
  if (fileType) {
    const appConstant: AppConstant = new AppConstant();
    const options: UploaderOptions = {
      fileType,
      allowedExtension: fileType.fileExtension,
      maxSize: fileType.maxSize,
      stringUrl: uploader.stringUrl + 'file/upload',
      maxFileNameLength: appConstant.core.UPLOAD_MAX_LENGTH_FILE_NAME
    };
    uploader.options = { ...uploader.options, ...options };
  }
  return uploader;
}
