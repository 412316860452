import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalPrcsModule } from '../../core/components/app-approval-prcs/app-approval-prcs.module';
import { SharedModule } from '../../core/shared/shared.module';
import { PerformanceOnRegistrationDetailComponent } from './performance-on-registration-detail.component';
import { PerformanceOnRegistrationEditComponent } from './performance-on-registration-edit.component';
import { PerformanceOnRegistrationComponent } from './performance-on-registration.component';
const routes = [
  { path: '', component: PerformanceOnRegistrationComponent, data: { breadcrumb: '' } },
  { path: 'edit', component: PerformanceOnRegistrationEditComponent, data: { breadcrumb: 'performance-on-registration.breadcrumb.edit' } },
  { path: 'detail', component: PerformanceOnRegistrationDetailComponent, data: { breadcrumb: 'performance-on-registration.breadcrumb.detail' } }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), AppApprovalPrcsModule],
  declarations: [PerformanceOnRegistrationComponent, PerformanceOnRegistrationEditComponent, PerformanceOnRegistrationDetailComponent],
  providers: []
})
export class PerformanceOnRegistrationModule {}
