import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { User } from '../bean/user';
import { WorkPlanStatus } from '../bean/work-plan-status';
import { Workflow } from '../bean/workflow';
import { ProcurementType } from './../bean/procurement-type';

export class WorkPlanEntity extends BaseWorkflowObjectEntity {
  totalPriceItem: string;
  processDayTotal: number;
  workflow: Workflow;
  user: User = new User();
  workPlanStatus: WorkPlanStatus = new WorkPlanStatus();
  procurementType: ProcurementType = new ProcurementType();
  number: string;
  name: string;
  createdDate: Date = new Date();
  workTarget: Date = new Date();
  toc: string;
  description: string;
  status: string;
  generateMemoNumber: string;
}
