import { Injectable } from '@angular/core';
import { TextUtils } from '../utils';
@Injectable({
  providedIn: 'root'
})
export class TextService {
  public generateRandomString(length = 4, customCharacters?: string): string {
    return TextUtils.generateRandomString(length, customCharacters);
  }
}
