import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Counter } from '../../core/bean/counter';
import { ExecutorAssignment } from '../../core/bean/executor-assignment';
import { User } from '../../core/bean/user';
import { UserExecutor } from '../../core/bean/user-executor';
import { Verificator } from '../../core/bean/verificator';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';
import { ExecutorResponse } from './executor-response';

@Component({
  templateUrl: './app-popup-add-executor-assignment.component.html'
})
export class AppPopupAddExecutorAssignmentComponent extends BaseModuleComponent {
  @Input() header: string;
  @Input() verificator: Verificator;
  @Input() userExecutorList: UserExecutor[] = [];
  @Input() userList: User[] = [];
  @Input() executorAssignmentList: ExecutorAssignment[];
  @Input() executorResponseTemp: ExecutorResponse;
  @Input() counterList: Counter[];
  @Output() onChange: EventEmitter<ExecutorAssignment[]> = new EventEmitter();
  public userExecutorOptionList: OptionListModel<User> = new OptionListModel(
    true
  );
  public isNoCounterSelected = false;
  public tableResponseCounter: TableResponseModel<Counter>;
  public isCounterHaveBeenSelected = false;

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public activeModal: NgbActiveModal
  ) {
    super('executor', translate);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.setOptionList();
    this.doBuildTableResponse();
    this.setStateReady();
  }
  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      userExecutor: [null, Validators.required()]
    });
  }
  public setOptionList(): void {
    const userExecutorListTemp = this.userExecutorList.filter(userExecutor => {
      return userExecutor.crudOperation !== this.global.appConstant.core.CRUD_OPERATION_DELETE;
    });
    userExecutorListTemp.forEach(userExecutor => {
      this.userList.push(userExecutor.user);
    });
    this.userExecutorOptionList.setRequestValues(this.userList);
  }

  public doBuildTableResponse(): void {
    this.tableResponseCounter = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.counter-code'
      },
      {
        field: 'name',
        header: 'table.column.counter-name'
      }
    ]);
    this.setTableResponseCounterState();
  }
  private setTableResponseCounterState(): void {
    this.tableResponseCounter.setRecordList(this.counterList);
    this.tableResponseCounter.reload();
  }
  public doAddExecutorAssignment(): void {
    this.validate();
    if (this.formGroup.valid) {
      if (this.tableResponseCounter.selectedRow.selectedRowList.length !== 0) {
        const selectedUserExecutor = new UserExecutor();
        selectedUserExecutor.user = this.formGroup.get('userExecutor').value;
        selectedUserExecutor.verificator = this.verificator;
        const addedCounterList =
          this.tableResponseCounter.selectedRow.selectedRowList;
        addedCounterList.forEach(newCounter => {
          const counter = newCounter.record;
          const newExecutorAssignment = new ExecutorAssignment();
          newExecutorAssignment.counter = counter;
          newExecutorAssignment.userExecutor = selectedUserExecutor;
          newExecutorAssignment.crudOperation = this.global.appConstant.core.CRUD_OPERATION_INSERT;
          this.executorAssignmentList.push(newExecutorAssignment);
          const index = this.counterList.findIndex(
            i => JSON.stringify(i) === JSON.stringify(counter)
          );
          if (index !== -1) {
            this.counterList.splice(index, 1);
          }
        });
        this.isNoCounterSelected = false;
        this.onChange.emit(this.executorAssignmentList);
        this.activeModal.dismiss();
      } else {
        this.isNoCounterSelected = true;
      }
    } else if (this.tableResponseCounter.selectedRow.selectedRowList.length === 0) {
        this.isNoCounterSelected = true;
    }
  }

  public doValidate(): void {
    if (this.tableResponseCounter.selectedRow['isSelectAll'] !== undefined) {
      if (this.tableResponseCounter.selectedRow.selectedRowList.length === 0 && this.tableResponseCounter.selectedRow.unSelectedRowList.length === 0 ) {
        if (this.tableResponseCounter.selectedRow['isSelectAll'] === false) {
          this.isNoCounterSelected = true;
        } else if (this.tableResponseCounter.selectedRow['isSelectAll'] === true) {
          this.isNoCounterSelected = false;
        }
      } else {
        this.isNoCounterSelected = false;
      }
    } else if (this.tableResponseCounter.selectedRow.selectedRowList.length !== 0) {
      this.isNoCounterSelected = false;
      this.isCounterHaveBeenSelected = true;
    } else if (this.isCounterHaveBeenSelected) {
      this.isNoCounterSelected = true;
    }
  }
}
