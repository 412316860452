import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ActualPlanBarChartModel } from '../../components/app-actual-plan-bar-chart/model/actual-plan-bar-chart-model';
import { BudgetFilterModel } from '../../components/app-budget-filter/model/budget-filter-model';
import { BudgetFilterValuesModel } from '../../components/app-budget-filter/model/budget-filter-values-model';
import { PlanComparisonModel } from '../../components/app-plan-comparison/model/plan-comparison-model';
import { WebSocketBudgetService } from './../../../pages/budget-allocation-bjb/websocket-budget.service';
import { WidgetBudgetSpendItemModel } from './model/widget-budget-spend-item-model';
import { WidgetBudgetSpendModel } from './model/widget-budget-spend-model';

@Component({
  templateUrl: './app-widget-budget-spend.component.html',
  styleUrls: ['./app-widget-budget-spend.component.scss'],
  providers: [{ provide: WebSocketBudgetService }]
})
export class AppWidgetBudgetSpendComponent extends BaseComponentComponent {
  public actualPlanBarChartModel: ActualPlanBarChartModel = new ActualPlanBarChartModel(
    'budget-info'
  );

  public widgetBudgetSpend: WidgetBudgetSpendModel = new WidgetBudgetSpendModel();
  public isSyncRunning = false;
  public isShowMoreVisualButton = false;

  constructor(
    public websocketBudgetService: WebSocketBudgetService,
    public translateService: TranslateService
  ) {
    super('app-widget-budget-spend');
  }

  public onInit(): void {
    this.initializeWebSocketConnection();
    this.isSynchronizeRunning();
    this.httpClientService.completedRequestChanges.subscribe(() => {
      this.isShowMoreVisualButton = true;
    });
  }

  public isSynchronizeRunning(): void {
    this.httpClientService
      .get<Boolean>('/budget-allocation-bjb/is-budget-synchronize-running/onlyBudget')
      .subscribe((isSynchronizeRunning: boolean) => {
        this.isSyncRunning = isSynchronizeRunning;
        this.setStateReady();
        if (!this.isSyncRunning) {
          this.setInitializationState();
        } else {
          this.global.alertService.showInfo(
            this.translateService.instant(
              'app-widget-budget-spend.budgetIsSynchronizing'
            ),
            '#main-row-dashboard',
            {
              totalAlert: 1
            }
          );
          // this.websocketBudgetService.message.subscribe(message => {
          //   this.global.alertService.showInfo(message, '#main-row-dashboard', {
          //     totalAlert: 1
          //   });
          // });
        }
      });
  }

  public initializeWebSocketConnection(): void {
    this.websocketBudgetService.connect().then(result => {
      if (!this.isSyncRunning && result) {
        // this.tableResponse.reload();
      } else if (this.isSyncRunning) {
        this.isSyncRunning = false;
        this.setInitializationState();
      }
      this.initializeWebSocketConnection();
    });
  }

  private setInitializationState(): void {
    this.widgetBudgetSpend.addItem({
      acutalPlanBarchart: new ActualPlanBarChartModel('budget-info'),
      budgetFilter: new BudgetFilterModel({ view: 'Yearly' }),
      planComparison: new PlanComparisonModel(),
      defaultChart: 'PLAN-COMPARISON'
    });
  }

  public onChangeBudgetFilter(
    budgetFilterValue: BudgetFilterValuesModel,
    widgetBudgetSpendItem: WidgetBudgetSpendItemModel
  ): void {
    budgetFilterValue.view === 'Monthly'
      ? (widgetBudgetSpendItem.defaultChart = 'ACTUAL-PLAN-BAR-CHART')
      : (widgetBudgetSpendItem.defaultChart = 'PLAN-COMPARISON');

    widgetBudgetSpendItem.defaultChart === 'PLAN-COMPARISON'
      ? widgetBudgetSpendItem.planComparison.setRequestBody(budgetFilterValue)
      : widgetBudgetSpendItem.acutalPlanBarchart.setRequestBody(
          budgetFilterValue
        );
  }

  public doAddMoreVisual(): void {
    this.widgetBudgetSpend.isShowMoreVisual = true;
    this.widgetBudgetSpend.addItem({
      acutalPlanBarchart: new ActualPlanBarChartModel('budget-info'),
      budgetFilter: new BudgetFilterModel({ view: 'Monthly' }),
      planComparison: new PlanComparisonModel(),
      defaultChart: 'ACTUAL-PLAN-BAR-CHART'
    });
  }
}
