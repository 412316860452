import { BaseEntity } from '../base/base-entity';
import { ProcurementItem } from '../bean/procurement-item';
import { ProfitLossStatus } from '../bean/profit-loss-status';

export class DirectPurchaseRealizationEntity extends BaseEntity {
  procurementItem: ProcurementItem;
  profitLossStatus: ProfitLossStatus;
  profitLossAmount: number;
  price: number;
  totalPrice: number;
}
