import { BaseEntity } from '../base/base-entity';
import { ContractDraft } from '../bean/contract-draft';
import { User } from '../bean/user';
import { ContractTemplate } from '../bean/contract-template';
import { File } from '../bean/file';

export class ContractDraftHistoryEntity extends BaseEntity {
  contractDraft: ContractDraft;
  contractTemplate: ContractTemplate;
  generatedFile: File;
  draftFile: File;
  sequence: number;
  note: string;
  processedByUser: User;
  processedDate: Date;
}
