import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { AddressBook } from '../../core/bean/address-book';
import { OrderShippingItem } from '../../core/bean/order-shipping-item';
import { TableRow } from '../../core/components/table/domain/table-row';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';

@Component({
  templateUrl: './app-popup-order-response-view-item.component.html'
})
export class AppPopupOrderResponseViewItemComponent extends BaseComponentComponent {
  @Input() public address: AddressBook;
  @Input() public orderShippingItemList: OrderShippingItem[];
  @Input() public allOrderShippingItemList: OrderShippingItem[];
  public CURRENCY_DIGITS_INFO: string;
  public tableResponse: TableResponseModel<OrderShippingItem>;

  constructor(public currency: CurrencyPipe) {
    super('order-response');
  }
  public onInit(): void {
    this.doBuildTableResponse();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'orderItem.prItem.item.name',
        header: 'popup.table.column.itemName'
      },
      {
        field: 'orderItem.prItem.item.code',
        header: 'popup.table.column.itemCode'
      },
      {
        field: 'orderItem.prItem.item.uom.name',
        header: 'popup.table.column.uom'
      },
      {
        field: 'quantity',
        header: 'popup.table.column.quantity',
        className: 'text-right',
        plugins: {
          name: 'custom-plugin',
          before: (tablePlugin: TablePluginData): string => {
            return this.global.converterService.convertDecimal(
              tablePlugin.value
            );
          }
        }
      },
      {
        field: 'remainingQuantity',
        header: 'popup.table.column.maxQuantity',
        className: 'text-right',
        plugins: {
          name: 'custom-plugin',
          before: (tablePlugin: TablePluginData): string => {
            return this.global.converterService.convertDecimal(
              tablePlugin.value
            );
          }
        }
      }
    ]);
    this.tableResponse.setRecordList(this.orderShippingItemList);
  }

  public onAfterRowCreated(tableRow: TableRow): void {
    const index = this.orderShippingItemList.findIndex(
      orderShippingItem =>
        orderShippingItem.orderItem.prItem.id ===
        tableRow.record.orderItem.prItem.id
    );
    const remainingQuantity = this.getRemainingQuantityItem(tableRow);
    if (tableRow.record.quantity) {
      this.orderShippingItemList[index].remainingQuantity =
        remainingQuantity + tableRow.record.quantity;
      //  tableRow.formGroup
      //   .get('remainingQuantity')
      //   .patchValue(
      //     (
      //       remainingQuantity + +tableRow.formGroup.get('quantity').value
      //     ).toString()
      //   );
    } else {
      this.orderShippingItemList[index].remainingQuantity = remainingQuantity;
      this.orderShippingItemList[index].quantity = remainingQuantity;
      // tableRow.formGroup
      //   .get('quantity')
      //   .patchValue(remainingQuantity.toString());
      // tableRow.formGroup
      //   .get('remainingQuantity')
      //   .patchValue(remainingQuantity.toString());
    }
  }

  public getRemainingQuantityItem(tableRow: TableRow): number {
    const maxQuantity = tableRow.record.orderItem.prItem.quantity;
    let totalUsedQuantity = 0;
    if (
      this.allOrderShippingItemList &&
      this.allOrderShippingItemList.length > 0
    ) {
      const orderShipItemList = this.allOrderShippingItemList.filter(
        osi =>
          osi.orderItem.prItem.id ===
          tableRow.record.orderItem.prItem.id
      );
      orderShipItemList.forEach(oShipItem => {
        totalUsedQuantity += oShipItem.quantity;
      });
    }
    const remainingQuantity = maxQuantity - totalUsedQuantity;
    return remainingQuantity;
  }
}
