import {
  Component,
  ElementRef,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
@Component({
  selector: 'app-wysiwyg',
  templateUrl: './app-wysiwyg.component.html',
  styleUrls: ['./app-wysiwyg.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: makeProvider(AppWysiwygComponent)
})
export class AppWysiwygComponent extends BaseValueAccessorComponent<string> {
  public config = {
    extraAllowedContent: '*',
    allowedContent: true
  };
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super('app-wysiwyg', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.formControl.valueChanges.subscribe(value => this.onChange.emit(value));
  }
}
