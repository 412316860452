import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetContentSliderComponent } from './app-widget-content-slider.component';
@NgModule({
  imports: [VendorModule],
  declarations: [AppWidgetContentSliderComponent],
  entryComponents: [AppWidgetContentSliderComponent],
  exports: [AppWidgetContentSliderComponent]
})
export class AppWidgetContentSliderModule {}
