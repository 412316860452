<div class="app-widget-procurement-activity" *ngIf="!formLoading">
  <app-widget (click)="goToMoreDetail()">
    <div class="row">
      <div class="col-2 d-flex align-items-center">
        <div
          [ngStyle]="{
            background: 'var(--primary-dark)',
            'background-blend-mode': 'darken',
            'border-radius': '100%',
            'min-width': '16px',
            height: '16px'
          }"
        >
        </div>
      </div>
      <div class="row col-7 d-flex align-items-center">
        <h4>Procurement Activity</h4>
        <br />
        <h2>{{ total }}</h2>
      </div>
      <div class="col-4 d-flex align-items-center">
        <div
          class="pvc pv-auction"
          style="
            width: 40px;
            height: 40px;
            margin-left: auto;
            margin-right: 0px;
          "
        ></div>
      </div>
    </div>
  </app-widget>
</div>
