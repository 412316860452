<div
  class="notification-content"
  [appDropDown]="notification"
  [isShowArrow]="true"
  changePosition="on-scroll"
  [isFixed]="true"
  (onClick)="doShowNotification()"
>
  <em class="pir pi-bell"></em>
  <span
    class="notification-count"
    *ngIf="
      userNotificationWebsocketService.receivedMessage?.totalUnreadNotification
    "
  >
    {{
      userNotificationWebsocketService.receivedMessage?.totalUnreadNotification
    }}
  </span>
</div>

<ng-template #notification>
  <div class="notification-drop-down">
    <div class="notification-header">
      <div><h4 class="title mb-0">Notification</h4></div>
      <div>
        <a (click)="doSeeMore()">
          <strong>
            {{ 'user-notification.button.seeMore' | translate }}
          </strong>
        </a>
      </div>
    </div>
    <div class="notification-body">
      <ng-container
        *ngIf="isLoading; then isLoadingTemplate; else isReadyTemplate"
      ></ng-container>

      <ng-template #isLoadingTemplate>
        <div class="text-center pb-3">
          <img
            src="assets/img/common/loader-small.gif"
            alt="Loading"
            height="8"
            width="30"
          />
        </div>
      </ng-template>

      <ng-template #isReadyTemplate>
        <ng-container
          *ngIf="
            userNotificationWebsocketService.receivedMessage
              ?.userNotificationList?.length > 0;
            then notificationTemplate;
            else noNotificationTemplate
          "
        >
        </ng-container>

        <ng-template #notificationTemplate>
          <perfect-scrollbar>
            <div
              class="notification-content"
              *ngFor="
                let userNotification of userNotificationWebsocketService
                  .receivedMessage?.userNotificationList
              "
              (click)="doReadMore($event, userNotification)"
            >
              <div class="notification-content-box">
                <div class="content-left">
                  <div class="notification-icon">
                    <em [ngClass]="userNotification.icon.name"></em>
                  </div>
                </div>
                <div class="content-right">
                  <div class="notification-article">
                    <div class="notification-subject mb-1">
                      <div>
                        <strong>{{ userNotification.subject }}</strong>
                      </div>
                      <div *ngIf="!userNotification.readDate" class="new"></div>
                    </div>
                    <div
                      class="notification-text"
                      [innerHTML]="userNotification.body"
                    >
                    </div>
                    <div class="notification-date-time">
                      <div class="date">
                        <em class="far fa-calendar-alt"></em>
                        {{
                          userNotification.publishDate
                            | date: global.appConstant.core.FORMAT_SHORT_DATE
                        }}
                      </div>
                      <div class="time">
                        <em class="fas fa-clock"></em>
                        {{ userNotification.publishDate | date: 'HH:mm' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
        </ng-template>

        <ng-template #noNotificationTemplate>
          <span>No Notification</span>
        </ng-template>
      </ng-template>
    </div>
    <div
      class="notification-footer"
      *ngIf="
        userNotificationWebsocketService.receivedMessage?.userNotificationList
          ?.length > 0
      "
    >
      <!-- <button class="btn btn-primary" (click)="doSeeMore()">
        {{ 'user-notification.button.seeMore' | translate }}
      </button> -->
    </div>
  </div>
</ng-template>
