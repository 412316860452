import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from '../../../core/global/global';
import { AngularMenuModel } from '../../../core/model/angular-menu-model';

@Injectable()
export class MenuService {
  public activeMenu: any;
  constructor(private global: Global, private router: Router) {}

  public getMenu(): Array<any> {
    const menuList = this.doSetMenuList();
    this.setActiveAndExpandedMenu(this.activeMenu);
    return menuList;
  }

  public doSetMenuList(): Array<any> {
    const menuMap = this.global.userSession.menuMap;
    return this.doSetMenuParentList(Object.values(menuMap));
  }

  public doSetMenuParentList(menuList: Array<any>): Array<any> {
    const menuParentList = [];
    menuList.forEach((menuItem: AngularMenuModel) => {
      if (menuItem.parentId === 0) {
        menuParentList.push(menuItem);
        this.doSetMenuChildrenList(menuList, menuItem);
      }
      if (
        menuItem.routerLink &&
        menuItem.routerLink.trim() !== '' &&
        this.router.url.includes(menuItem.routerLink) &&
        !this.activeMenu
      ) {
        this.activeMenu = menuItem;
      }
      menuItem['isActive'] = false;
      menuItem['isExpanded'] = false;
    });
    return menuParentList;
  }

  private setActiveAndExpandedMenu(menuItem: any): void {
    if (menuItem) {
      menuItem.isActive = true;
      if (menuItem.routerLink) {
        this.global.activeModuleCode = (menuItem as any).moduleCode;
      }

      if (menuItem.parent) {
        menuItem.parent.isExpanded = true;
        this.setActiveAndExpandedMenu(menuItem.parent);
      }
    }
  }

  public doSetMenuChildrenList(menuList: Array<any>, menuItem: any): void {
    menuItem['childrens'] = [];
    menuList.forEach(menuListItem => {
      if (menuListItem.parentId === menuItem.id) {
        menuItem['childrens'].push(menuListItem);
        menuListItem['parent'] = menuItem;
        this.doSetMenuChildrenList(menuList, menuListItem);
      }
    });
  }

  public navigateTo(menu: any): void {
    if (!menu.hasSubMenu) {
      this.router.navigate([menu.routerLink]);
    }
  }

  public setActiveMenu(menuItem: any): void {
    menuItem.isActive = true;
    if (menuItem.parent) {
      this.setActiveMenu(menuItem.parent);
    }
  }

  public setExpandedMenu(menuItems: Array<any>): void {
    menuItems.forEach(m => {
      if (m.isExpanded) {
        m.isExpanded = false;
        if (m.childrens.length > 0) {
          this.setExpandedMenu(m.childrens);
        }
      }
    });
  }

  public setActiveMenuToUnactive(menuList: Array<any>): void {
    menuList.forEach(menuItem => {
      if (menuItem.isActive) {
        menuItem.isActive = false;
        if (menuItem.childrens.length > 0) {
          this.setActiveMenuToUnactive(menuItem.childrens);
        }
      }
    });
  }
}
