import { Claim } from "src/app/core/bean/claim";
import { AccidentVictim } from "../../core/bean/accident-victim";
import { AccidentVictimCondition } from "../../core/bean/accident-victim-condition";
import { AccidentVictimDiagnose } from "../../core/bean/accident-victim-diagnose";
import { AccidentVictimTreatment } from "../../core/bean/accident-victim-treatment";
import { AccidentVictimConditionDto } from "./accident-victim-condition-dto";

export class AccidentVictimRequest {
    claim: Claim;
    accidentVictimTreatmenntList: AccidentVictimTreatment[] = [];
    accidentVictim: AccidentVictim;
    accidentVictimTreatmenntOperationList: AccidentVictimTreatment[] = [];
    statusCode: string;
    accidentVictimId: number;
    accidentVictimConditionList: AccidentVictimCondition[] = [];
    accidentVictimDiagnoseSekunderList: AccidentVictimDiagnose[] = [];
    accidentVictimDiagnoseOtherList: AccidentVictimDiagnose[] = [];
    accidentVictimConditionDtoList: AccidentVictimConditionDto[] = [];
}