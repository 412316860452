export class ValidatorMessage {
  public readonly ERROR_MESSAGE_REQUIRED = 'app.validation.required';
  public readonly ERROR_MESSAGE_PASSWORD = 'app.validation.password';
  public readonly ERROR_MESSAGE_MATCHPASSWORD = 'app.validation.passwordNotMatch';
  public readonly ERROR_MESSAGE_MATCHEMAIL = 'app.validation.emailNotMatch';
  public readonly ERROR_MESSAGE_EMAIL = 'app.validation.email';
  public readonly ERROR_MESSAGE_MATCH = 'app.validation.match';
  public readonly ERROR_MESSAGE_MINLENGTH = 'app.validation.minLength';
  public readonly ERROR_MESSAGE_ACTUALLENGTH = 'app.validation.actualLength';
  public readonly ERROR_MESSAGE_MAXLENGTH = 'app.validation.maxLength';
  public readonly ERROR_MESSAGE_ISCHECKED = 'app.validation.isChecked';
  public readonly ERROR_MESSAGE_MIN = 'app.validation.min';
  public readonly ERROR_MESSAGE_MAX = 'app.validation.max';
  public readonly ERROR_MESSAGE_EXISTS = 'app.validation.exists';
  public readonly ERROR_MESSAGE_TERMSANDCONDITION = 'app.validation.termsAndCondition';
  public readonly ERROR_MESSAGE_NPWP = 'app.validation.npwp';
  public readonly ERROR_MESSAGE_URL = 'app.validation.url';
  public readonly ERROR_MESSAGE_PROCUREMENT_NUMBER = 'app.validation.procurementNumber';
}
