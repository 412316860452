import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardResponse } from '../../../../pages/dashboard/dashboard-response';
import { AppPopupService } from '../app-popup.service';
import { AppPopupTOCComponent } from './app-popup-toc.component';
@Injectable({ providedIn: 'root' })
export class AppPopUpTOCService {
  constructor(public appPopupService: AppPopupService) {}
  public open(dashboardResponse: DashboardResponse): Observable<any> {
    return this.appPopupService.open(
      AppPopupTOCComponent,
      { title: 'toc.title', dashboardResponse: dashboardResponse },
      { windowClass: 'app-popup-toc' }
    );
  }
}
