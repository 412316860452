<app-layout
  [isLoading]="formLoading"
  header="rfq.breadcrumb.index"
  [backTo]="urlBackOutside"
>
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge
      *ngIf="todo !== 'add'"
      class="cml-2"
      [color]="badgeColor[rfqResponse?.rfq?.rfqStatus?.code]"
    >
      {{ getTranslateKey(rfqResponse?.rfq?.rfqStatus) | translate }}
    </app-badge>
    <app-badge *ngIf="todo === 'add'" class="cml-2" color="INFO">
      {{ 'rfq.badge.status.new' | translate }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-card
          header="rfq.detail.rfqDetail.title"
          tips="rfq.tips.rfqDetails"
        >
          <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>
          <div
            class="form-group row text-sm-left"
            *ngIf="
              todo === 'add' && rfqResponse?.module?.isNumberGenerator === false
            "
          >
            <label class="col-sm-3">
              {{ 'rfq.form.code' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-generated
                formControlName="code"
                maxLength="64"
                [moduleCode]="moduleCode"
                numberGeneratorCode="NUMBER"
              ></app-text-generated>
            </div>
          </div>

          <div
            class="form-group row text-sm-left"
            *ngIf="
              todo === 'edit' &&
              rfqResponse?.rfq?.rfqStatus.code !==
                global.appConstant.pm.RFP_STATUS_DRAFT
            "
          >
            <label class="col-sm-3">
              {{ 'rfq.form.code' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              {{ formGroup.get('code').value }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'rfq.form.title' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="title"
                type="text"
                placeholder="{{ 'rfq.placeholder.inputText' | translate }}"
                maxLength="128"
              >
              </app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'rfq.form.responsePeriod' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-date-picker-x
                formControlName="responsePeriod"
                [range]="true"
                [minDate]="currentDate"
              >
              </app-date-picker-x>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'rfq.form.upload' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              <app-upload-x
                size="XL"
                formControlName="template"
                [fileUploader]="fileUploader"
              >
              </app-upload-x>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{ 'rfq.form.note' | translate }}</label>
            <div class="col-sm-6 text-left">
              <app-text-area formControlName="note" maxLength="512" size="XL">
              </app-text-area>
            </div>
          </div>
        </app-card>

        <app-card>
          <!-- <app-fullfilled *ngIf="procurementItemList.length === 0
                && !formGroup.isView"></app-fullfilled> -->
          <app-table-xx
            header="rfq.detail.itemRequest.title"
            tips="rfq.tips.rfqItemRequest"
            [model]="tableResponseItem"
            (onClick)="doViewItem($event)"
            [isShowCheckBox]="true"
            [isMultipleSelect]="true"
          >
            <ng-template #headerButtons>
              <app-button
                (onClick)="doAddItemList()"
                *ngIf="!formGroup.isView"
                title="{{ 'app.button.add' | translate }}"
              >
                <em class="pir pi-plus-circle"></em>
                {{ 'rfq.button.addList' | translate }}
              </app-button>
            </ng-template>
            <ng-template #headerFilter>
              <div class="app-table-filter-item" style="height: 34px">
                <div class="form-group row text-sm-left">
                  <div
                    class="col text-left"
                    [ngStyle]="{ 'min-width': '10rem' }"
                  >
                    <app-dropdown-select
                      size="XL"
                      formControlName="itemType"
                      type="COMBOBOX"
                      [optionList]="itemTypeOptionList"
                      (onChange)="onChangeItemType()"
                      container="body"
                      placeholder="rfq.placeholder.type"
                    ></app-dropdown-select>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-container *ngIf="tableResponseItem.getRecordList()?.length > 0">
              <ng-template #row>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'rfq.itemRequest.footer.goodsAmount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{
                        formGroup.value.goodsAmount | resolveNumberToCurrency
                      }}
                      IDR
                    </strong>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'rfq.itemRequest.footer.serviceAmount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{
                        formGroup.value.serviceAmount | resolveNumberToCurrency
                      }}
                      IDR
                    </strong>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'rfq.itemRequest.footer.amount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{ formGroup.value.amount | resolveNumberToCurrency }}
                      IDR
                    </strong>
                    <br />
                    <i>
                      ({{
                        formGroup.value.amount
                          | resolveNumberToWords: global.currentLang
                      }}
                      {{ 'Rupiah' }})
                    </i>
                  </td>
                </tr>
              </ng-template>
            </ng-container>
            <ng-container>
              <ng-template #actionButtons let-rowData>
                <app-button
                  size="TN"
                  (onClick)="doDeleteItem(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="pir pi-trash-alt"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>
              </ng-template>
            </ng-container>
          </app-table-xx>
        </app-card>

        <app-card>
          <app-table-xx
            header="rfq.detail.rfqVendor.title"
            tips="rfq.tips.rfqVendor"
            [model]="tableResponseVendor"
            (onClick)="onClickVendor($event)"
            [isShowCheckBox]="!formGroup.isView"
          >
            <ng-container *ngIf="!formGroup.isView">
              <ng-template #headerButtons>
                <app-button
                  (onClick)="doInviteVendor()"
                  [disabled]="rfqItemVendorResponse?.rfqItemList?.length === 0"
                >
                  <em class="pir pi-plus-circle"></em>
                  {{ 'rfq.detail.button.inviteVendor' | translate }}
                </app-button>
              </ng-template>

              <ng-template #actionButtons let-rowData>
                <app-button
                  size="TN"
                  (onClick)="doDeleteVendor(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="pir pi-trash-alt"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>
              </ng-template>
            </ng-container>
          </app-table-xx>
        </app-card>
      </form>

      <app-approval-monitoring
        *ngIf="
          !formLoading &&
          rfqResponse.approvalPathResponseModel &&
          !rfqResponse.isUserApproval
        "
        [workflowPrcs]="rfqResponse?.rfq?.workflowPrcs"
        [moduleCode]="moduleCode"
        [approvalPathResponseModel]="rfqResponse.approvalPathResponseModel"
      ></app-approval-monitoring>

      <!-- <app-card
        header="rfq.detail.changeHistory.title"
        *ngIf="
          response?.procurement?.procurementStatus?.code &&
          this.statusForApprovalList.includes(
            response?.procurement?.procurementStatus?.code
          )
        "
      >
        <app-change-history
          *ngIf="!formLoading"
          [moduleId]="response.module?.id"
          [objectId]="procurementId"
        ></app-change-history>
      </app-card> -->
      <div class="floating-button mt-5">
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button
                (onClick)="doSave(false)"
                color="SECONDARY"
                [outline]="true"
                [disabled]="
                  formLoading ||
                  formSaving ||
                  !formGroup.valid ||
                  rfqItemVendorResponse?.rfqItemList?.length === 0
                "
              >
                {{ 'app.button.save' | translate }}</app-button
              >
              <app-button
                (onClick)="doSave(true)"
                [disabled]="
                  formLoading ||
                  formSaving ||
                  !formGroup.valid ||
                  rfqItemVendorResponse?.rfqItemList?.length === 0
                "
              >
                {{ 'app.button.submit' | translate }}</app-button
              >
            </div>
          </div>
        </div>
      </div>

      <div class="floating-button mt-5" *ngIf="formGroup.isView">
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div
              class="button-group button-group-center"
              *ngIf="formGroup.isView"
            >
              <app-button
                *ngIf="
                  rfqResponse?.rfq?.rfqStatus?.code !==
                  global.appConstant.pm.RFQ_STATUS_CANCELED
                "
                color="DANGER"
                [outline]="true"
                (onClick)="doCancel()"
                >{{ 'rfq.button.cancelRfq' | translate }}
              </app-button>
              <app-button
                color="LIGHT"
                (onClick)="doClose()"
                [disabled]="formSaving"
                [outline]="true"
                >{{ 'rfq.button.closeResponse' | translate }}</app-button
              >
            </div>
          </div>
        </div>
      </div>

      <app-approval-prcs-x
        *ngIf="!formLoading && rfqResponse.isUserApproval"
        [workflowModelPrcs]="rfqResponse.rfq.workflowModelPrcs"
        [approvalModelPrcsId]="approvalModelPrcsId"
      >
      </app-approval-prcs-x>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
