<div
  class="pt-0"
  style="
    width: 100%;
    height: 100px;
    background: #00446b;
    border-radius: 5px 5px 0px 0px;
  "
>
  <div class="row" style="padding: 2%">
    <div
      class="align-items-center"
      style="
        width: 50px;
        height: 50px;
        background: white;
        border-radius: 50%;
        left: 30%;
      "
      ><em class="pir pi-dollar-sign-circle pi-4x" style="padding: 15%"></em
    ></div>
    <div
      class="col-sm-8"
      style="display: grid; padding-left: 1rem; color: white"
    >
      <span style="height: max-content"
        >{{
          'dashboard-widget-total-available-payment.header.totalBills'
            | translate
        }}
      </span>
      <h2 style="color: white" *ngIf="!model.isLoading">
        {{ remainingAmount | resolveNumberToCurrency }} IDR
      </h2>
    </div>
  </div>
</div>
<dashboard-widget-card size="MD" [isLoading]="model.isLoading" headerSize="LG">
  <app-table-xx
    header="dashboard-widget-total-available-payment.title"
    [model]="tableResponse"
    (onClick)="onClick($event)"
  ></app-table-xx>
</dashboard-widget-card>
