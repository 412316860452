import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';
import { Currency } from '../../../core/bean/currency';
import { VendorFinancialHistory } from '../../../core/bean/vendor-financial-history';
import { VendorFinancialStatus } from '../../../core/bean/vendor-financial-status';
import { FileUploader } from '../../../core/components/upload';
import { OptionListModel } from '../../../core/model/option-list-model';
import { Validators } from '../../../core/validators';
import { VendorPopupVendorFinancialDTO } from '../dto/vendor-popup-vendor-financial.dto';

@Component({
  templateUrl: './app-popup-financial-statement.component.html'
})
export class AppPopupFinancialStatementComponent extends BaseComponentComponent {
  @Input() todo: string;
  @Input() yearList: string[];
  @Input() vendorFinancialHistoryEdit: VendorFinancialHistory;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  public fileUploader: FileUploader = new FileUploader(
    '/company-profile/',
    'financial',
    this.global.appConstant.fileType.DOC_FINANCIAL_STATEMENT
  );
  public vendorPopupVendorFinancialDTO: VendorPopupVendorFinancialDTO =
    new VendorPopupVendorFinancialDTO();
  public currentRatio: number;
  public currentAsset: number;
  public currentLiability: number;
  public totalLiability: number;
  public equity: number;
  public der: number;
  public netIncome: number;
  public roe: number;
  public today = new Date();
  public maxYear: number;
  public minYear: number;
  public isVendorList: boolean;
  public fromOutside: string;

  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true,
    'code'
  );
  public vendorFinancialStatusOptionList: OptionListModel<VendorFinancialStatus> =
    new OptionListModel(true);

  constructor() {
    super('app-popup-vendor-financial');
  }

  public onInit(): void {
    this.maxYear = this.today.getFullYear() - 1;
    this.minYear = this.today.getFullYear() - 3;
    this.setParam();
    this.buildFormGroup();
    this.setFormGroup();
    if (this.todo === 'detail') {
      this.setViewOnly();
    }
  }

  public setParam(): void {
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      year: [
        null,
        {
          validators:
            this.todo === 'edit'
              ? [
                  Validators.required(),
                  Validators.max(this.maxYear),
                  Validators.min(this.minYear),
                  Validators.actualLength(
                    4,
                    'company-profile.validation.year.actualLength'
                  )
                ]
              : [
                  Validators.required(),
                  Validators.max(this.maxYear),
                  Validators.min(this.minYear),
                  Validators.actualLength(
                    4,
                    'tab-profile-financial-statement.validation.year.actualLength'
                  ),
                  Validators.exists(this.yearList)
                ]
        }
      ],
      currency: [null, Validators.required()],
      auditNumber: [null, Validators.required()],
      date: [null, Validators.required()],
      auditor: [null, Validators.required()],
      currentAsset: [null, Validators.required()],
      currentLiability: [null, Validators.compose([Validators.required()])],
      currentRatio: [null, Validators.required()],
      totalLiability: [null, Validators.required()],
      equity: [
        null,
        Validators.compose([Validators.required(), Validators.min(1)])
      ],
      deptEquityRatio: [null, Validators.required()],
      netIncome: [null, Validators.required()],
      roe: [null, Validators.compose([Validators.required()])],
      cash: [null, Validators.required()],
      totalActiva: [null, Validators.required()],
      vendorFinancialStatus: [null, Validators.required()],
      file: [null, Validators.required()],
      fileUploader: [null],
      id: [null],
      currentDate: this.today,
      vendorFinancialId: [null]
    });
  }

  public setFormGroup(): void {
    this.formGroup.get('roe').setIsView(true);
    this.getAppPopupVendorFinancialByIdFromServer(
      '/tab-profile-financial-statement/add-edit-popup-vendor-financial'
    ).subscribe(vendorPopupVendorFinancialDTO => {
      this.vendorPopupVendorFinancialDTO = vendorPopupVendorFinancialDTO;
      this.currencyOptionList.setRequestValues(
        vendorPopupVendorFinancialDTO.currencyList
      );
      this.vendorFinancialStatusOptionList.setRequestValues(
        vendorPopupVendorFinancialDTO.vendorFinancialStatusList
      );
      if (this.vendorFinancialHistoryEdit !== undefined) {
        const {
          id,
          year,
          auditNumber,
          auditor,
          cash,
          totalActiva,
          currency,
          vendorFinancialStatus,
          vendorFinancialId
        } = this.vendorFinancialHistoryEdit;
        this.currentAsset = this.vendorFinancialHistoryEdit.currentAsset;
        this.currentLiability =
          this.vendorFinancialHistoryEdit.currentLiability;
        this.currentRatio = this.vendorFinancialHistoryEdit.currentRatio;
        this.totalLiability = this.vendorFinancialHistoryEdit.totalLiability;
        this.equity = this.vendorFinancialHistoryEdit.equity;
        this.der = this.vendorFinancialHistoryEdit.deptEquityRatio;
        this.netIncome = this.vendorFinancialHistoryEdit.netIncome;
        this.roe = this.vendorFinancialHistoryEdit.roe;
        const date: Date = new Date(this.vendorFinancialHistoryEdit.date);
        this.fileUploader.setFile(this.vendorFinancialHistoryEdit.file);
        if (
          this.vendorFinancialHistoryEdit.fileObjectList &&
          this.vendorFinancialHistoryEdit.fileObjectList.length !== 0
        ) {
          this.fileUploader.fileObjectList =
            this.vendorFinancialHistoryEdit.fileObjectList;
        }
        this.formGroup.patchValue({
          id,
          year,
          auditNumber,
          auditor,
          currentAsset: this.currentAsset,
          currentLiability: this.currentLiability,
          currentRatio: this.currentRatio ? this.currentRatio : '-',
          totalLiability: this.totalLiability,
          equity: this.equity,
          file: this.fileUploader.fileObjectList,
          fileUploader: this.fileUploader,
          deptEquityRatio: this.der ? this.der : '-',
          currency,
          roe: this.roe,
          cash,
          totalActiva,
          date,
          netIncome: this.netIncome,
          vendorFinancialStatus,
          vendorFinancialId
        });
        /* Remove validasi saat data 0 */
        this.currentLiability === 0
          ? this.doCheckValue0('currentLiability', +this.currentLiability)
          : '';
        this.totalLiability === 0
          ? this.doCheckValue0('totalLiability', +this.totalLiability)
          : '';
        this.netIncome === 0
          ? this.doCheckValue0('netIncome', +this.netIncome)
          : '';
        this.roe === 0 ? this.doCheckValue0('roe', +this.roe) : '';
        this.vendorFinancialHistoryEdit.cash === 0
          ? this.doCheckValue0('cash', +this.vendorFinancialHistoryEdit.cash)
          : '';
      }
      this.setStateReady();
    });
  }

  public getAppPopupVendorFinancialByIdFromServer(
    urlVendorExperiencePopup: string
  ): Observable<VendorPopupVendorFinancialDTO> {
    return this.httpClientService.post<VendorPopupVendorFinancialDTO>(
      urlVendorExperiencePopup,
      null
    );
  }

  public doCheckValue0(formGroupName: string, value): void {
    if (+value === 0) {
      this.formGroup.get(formGroupName).clearValidators();
      this.formGroup.get(formGroupName).updateValueAndValidity();
    }
  }

  public updateValidator(formGroupName: string) {
    this.formGroup.get(formGroupName).setValidators(Validators.required());
    this.formGroup.get(formGroupName).updateValueAndValidity();
  }

  public onInputCurrentAsset(): void {
    const currentAssetNum = this.formGroup
      .get('currentAsset')
      .value.toString()
      .replace(/\$|\,/g, '');
    this.currentAsset = +currentAssetNum;
    if (
      this.currentRatio !== undefined &&
      this.currentLiability !== undefined &&
      this.currentLiability !== 0
    ) {
      this.onInputCurrentLiability();
    }
  }

  public onInputCurrentLiability(): void {
    if (this.formGroup.get('currentLiability').value) {
      const currentLiabilityNum = this.formGroup
        .get('currentLiability')
        .value.toString()
        .replace(/\$|\,/g, '');
      this.currentLiability = +currentLiabilityNum;
      this.doCheckValue0('currentLiability', this.currentLiability);
      let currentRatioString = '-';
      this.currentRatio = 0;
      if (this.currentLiability !== 0) {
        this.currentRatio = this.currentAsset / this.currentLiability;
        currentRatioString = this.global.converterService.convertNumber(
          this.currentRatio.toString()
        );
      }
      this.formGroup.patchValue({ currentRatio: currentRatioString });
    } else {
      this.updateValidator('currentLiability');
    }
  }

  public onInputTotalLiability(): void {
    const totalLiabilityNum = this.formGroup
      .get('totalLiability')
      .value.toString()
      .replace(/\$|\,/g, '');
    this.totalLiability = +totalLiabilityNum;
    if (this.formGroup.get('totalLiability').value) {
      this.doCheckValue0('totalLiability', this.totalLiability);
    } else {
      this.updateValidator('totalLiability');
    }
    if (
      this.der !== undefined &&
      this.equity !== undefined &&
      this.totalLiability !== 0
    ) {
      this.onInputEquity();
    } else if (this.der !== undefined) {
      this.formGroup.patchValue({ deptEquityRatio: '-' });
    }
  }

  public onInputEquity(): void {
    if (this.formGroup.get('equity').value) {
      const equityNum = this.formGroup
        .get('equity')
        .value.toString()
        .replace(/\$|\,/g, '');
      this.equity = +equityNum;
      let derString = '-';
      this.der = 0;
      if (this.totalLiability !== 0) {
        this.der = this.totalLiability / this.equity;
        derString = this.global.converterService.convertNumber(
          this.der.toString()
        );
      }
      this.formGroup.patchValue({ deptEquityRatio: derString });
      if (
        this.roe !== undefined &&
        this.netIncome !== undefined &&
        this.formGroup.get('netIncome').value
      ) {
        this.onInputNetIncome();
      }
    }
  }

  public onInputNetIncome(): void {
    if (this.formGroup.get('netIncome').value) {
      const netIncomeNum = this.formGroup
        .get('netIncome')
        .value.toString()
        .replace(/\$|\,/g, '');
      this.netIncome = +netIncomeNum;
      this.doCheckValue0('netIncome', this.netIncome);
      this.roe = (this.netIncome / this.equity) * 100;
      this.doCheckValue0('roe', this.roe);
      this.formGroup.patchValue({
        roe: parseFloat(
          this.global.converterService
            .convertNumber(this.roe.toString())
            .replace(',', '')
        )
      });
    } else {
      this.updateValidator('netIncome');
      this.updateValidator('roe');
    }
  }

  public onInputCash(): void {
    if (this.formGroup.get('cash').value) {
      const cash = +this.formGroup
        .get('cash')
        .value.toString()
        .replace(/\$|\,/g, '');
      +cash === 0 ? this.doCheckValue0('cash', +cash) : '';
    } else {
      this.updateValidator('cash');
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup.value);
    }
  }
}
