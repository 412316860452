<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="fines-monitoring.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate *ngIf="!formLoading">
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'fines-monitoring.form.agreementNumber' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ finesMonitoringView?.agreementNumber || '-' }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'fines-monitoring.form.procurementName' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ finesMonitoringView?.procurementName || '-' }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'fines-monitoring.form.effectiveDate' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ finesMonitoringView.startDate | date: 'dd/MM/yyyy' }}
            {{ 'fines-monitoring.form.until' | translate }}
            {{ finesMonitoringView.endDate | date: 'dd/MM/yyyy' }}
          </div>
        </div> 
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'fines-monitoring.form.procurementValue' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ finesMonitoringResponse.procurementVendor.amount ? global.converterService.convertMoney(finesMonitoringResponse.procurementVendor.amount): '-' }} {{ finesMonitoringResponse.procurementVendor.currency.code ? finesMonitoringResponse.procurementVendor.currency.code : ' ' }}
          </div>
        </div>   
      </form>
      <div class="row">
        <div class="col-12">
          <h5 class="separator">{{
            'fines-monitoring.separator.stageOfWork' | translate
          }}</h5>
          <app-table
            #selectorStageOfWork
            [model]="tableResponseStageOfWork"
            *ngIf="!formLoading">
            <ng-template #actionButtons let-rowData>
              <app-button
                size="TN"
                (onClick)="doDetailStageOfWork(rowData)"
                title="{{ 'app.tooltip.view' | translate }}">
                <em class="fa fa-search"></em>
              </app-button>
            </ng-template>
          </app-table>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h5 class="separator">{{
            'fines-monitoring.separator.fines' | translate
          }}</h5>
          <app-table #selectorFines [model]="tableResponseFines" *ngIf="!formLoading">
            <ng-template #actionButtons let-rowData>
              <app-button
                size="TN"
                (onClick)="doDetailAdjustment(rowData)"
                title="{{ 'app.tooltip.edit' | translate }}">
                <em class="fa fa-pencil-alt"></em>
              </app-button>
            </ng-template>
          </app-table>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h5 class="separator">{{
            'fines-monitoring.separator.historyAdjustment' | translate
          }}</h5>
          <app-table #selectorHistoryAdjustment [model]="tableResponseHistoryAdjustment" *ngIf="!formLoading">
          </app-table>
        </div>
      </div>
      <div
        class="button-group button-group-center">
        <app-button
          mode="BYPASS"
          color="SECONDARY"
          (onClick)="doCancel()"
          class="mr-1"
          [disabled]="formSaving"
          >{{ 'app.button.back' | translate }}
        </app-button>
        <app-button
          color="PRIMARY"
          (onClick)="doSave()"
          [disabled]="formSaving"
         >{{ 'app.button.save' | translate }}</app-button>
      </div>
    </app-card>
  </div>
</div>
