import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  Optional,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { TextUtils } from '../../utils';
@Component({
  selector: 'app-toggle-switch',
  templateUrl: 'app-toggle-switch.component.html',
  styleUrls: ['./app-toggle-switch.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: makeProvider(AppToggleSwitchComponent)
})
export class AppToggleSwitchComponent
  extends BaseValueAccessorComponent<boolean>
  implements AfterViewInit
{
  @Input() labelOn: string;
  @Input() labelOff: string;

  @ViewChild('labelOnElement') labelOnElement: ElementRef;
  @ViewChild('labelOffElement') labelOffElement: ElementRef;

  public genedatedId: string;

  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super('app-toggle-switch', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.genedatedId = TextUtils.generateRandomString();
    if (this.ISVIEW) {
      this.formControl.disable();
    }
  }

  ngAfterViewInit(): void {
    const labelOnWidth = this.labelOnElement.nativeElement.scrollWidth;
    const labelOffWidth = this.labelOffElement.nativeElement.scrollWidth;
    const isOnWidthGreaterThanOff = labelOnWidth > labelOffWidth;
    const minWidth = isOnWidthGreaterThanOff ? labelOnWidth : labelOffWidth;
    this.labelOnElement.nativeElement.style.width =
      minWidth + minWidth / 2 + 'px';
    this.labelOffElement.nativeElement.style.width =
      minWidth + minWidth / 2 + 'px';
  }

  public doClick(event: any): void {
    event.target.classList.add('change');
    setTimeout(() => event.target.classList.remove('change'), 1000);
  }
}
