<app-popup [header]="header">
  <div class="main-row-rfi-item">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <div class="form-group row text-sm-right required">
        <label class="col-sm-3 control-label">{{ 'app-popup-rfi-item.form.item' |
          translate }}</label>
        <div class="col-sm-9 text-left">
          <!-- <app-text-field autofocus="true" size="LG" formControlName="item" maxLength="128"></app-text-field> -->
          <app-auto-complete size="LG" formControlName="item"
            stringUrl="/request-for-information/get-item-category-list"
            (onChange)="autoCompleteValueChange($event)"></app-auto-complete>
        </div>
      </div>
      <div class="form-group row text-sm-right required">
        <label class="col-sm-3 control-label">{{
          'app-popup-rfi-item.form.description' | translate }}</label>
        <div class="col-sm-9 text-left">
          <app-text-area size="LG" formControlName="description" maxLength="512"></app-text-area>
        </div>
      </div>
      <div class="form-group row text-sm-right required">
        <label class="col-sm-3 control-label">{{
          'app-popup-rfi-item.form.quantity' | translate }}</label>
        <div class="col-sm-9 text-left">
          <div class="form-row">
            <div class="form-group row col-3">
              <div class="col text-left">
                <app-text-field size="XL" formControlName="quantity"
                  type="price" maxLength="11"></app-text-field>
              </div>
            </div>
            <div class="form-group row col-9">
              <div class="col text-left">
                <app-combo-box [optionList]="uomOptionList" formControlName="uom">
                </app-combo-box>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #modalFooter let-activeModal>
        <div class="col-12 text-center">
          <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
            'app.button.close' | translate }}</app-button>
          <app-button color="PRIMARY" (onClick)="doSave()">{{ 'app.button.save' |
            translate }}</app-button>
        </div>
      </ng-template>
    </form>
  </div>
</app-popup>