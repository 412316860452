import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContractWorklistStatus } from 'src/app/core/bean/contract-worklist-status';
import { Organization } from 'src/app/core/bean/organization';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Contract } from '../../core/bean/contract';
import { WorklistCMResponse } from './worklist-cm.response';
@Component({
  templateUrl: './worklist-cm.component.html'
})
export class WorklistCMComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Contract>;
  public statusOptionList: OptionListModel<ContractWorklistStatus> =
    new OptionListModel(true);
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);
  public module: string;
  public currentPosition: string;
  public customData = '';
  public headerTable = 'Total Contract ';

  constructor(translateService: TranslateService) {
    super('worklist-cm', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
    this.setStateReady();
    localStorage.removeItem('temp');
    localStorage.removeItem('todo');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      organizationList: [null],
      statusList: [null],
      moduleCode: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<WorklistCMResponse>('/worklist-cm/get-option-list')
      .subscribe(response => {
        if (response != null) {
          this.statusOptionList.setRequestValues(response.statusList);
          this.organizationOptionList.setRequestValues(
            response.organizationList
          );
        }
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'requestNumber',
        header: 'table.column.requestNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doEdit.bind(this)
        }
      },
      {
        field: 'requestDate',
        header: 'table.column.requestDate',
        plugins: {
          name: 'date'
        }
      },
      {
        field: 'title',
        header: 'table.column.title'
      },
      {
        field: 'organization.name',
        header: 'table.column.department'
      },
      { field: 'vendor.name', header: 'table.column.vendorName' },
      {
        field: 'workflowModelPrcs.name',
        header: 'table.column.stage'
      },
      {
        field: 'contractWorklistStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'contractWorklistStatus.code',
          colorMap: {
            NEW: 'INFO',
            DRAFT: 'SECONDARY',
            REVISION: 'FEEDBACK',
            REVISION_BY_VENDOR: 'FEEDBACK',
            WAITING_APPROVAL: 'WARNING',
            DONE: 'SUCCESS',
            WAITING_RESPONSE: 'GOOD',
            CLOSED_RESPONSE: 'DANGER',
            HOLD: 'DANGER',
            CANCELED: 'DANGER',
            ON_PROGRESS: 'PROGRESS',
            WAITING_CONFIRMATION: 'GOOD'
          }
        }
      }
    ]);
  }

  doEdit(contract: Contract): void {
    const prcsCode = contract.prcsCode;
    this.global.routerParams.clear();
    this.global.routerParams.set('contractId', contract.id);
    this.global.routerParams.set(
      'contractWorklistStatus',
      contract.contractWorklistStatus
    );
    if (
      contract.contractWorklistStatus?.code ===
        this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_NEW ||
      contract.contractWorklistStatus?.code ===
        this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_DRAFT ||
      contract.contractWorklistStatus?.code ===
        this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_REVISION ||
      contract.contractWorklistStatus?.code ===
        this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_REVISION_BY_VENDOR
    ) {
      this.global.routerParams.set('todo', 'edit');
      this.router.navigate([
        '/pages/' + prcsCode.replace('APPROVAL-', '').toLowerCase() + '/edit'
      ]);
    } else {
      this.global.routerParams.set('todo', 'view');
      this.router.navigate([
        '/pages/' + prcsCode.replace('APPROVAL-', '').toLowerCase() + '/detail'
      ]);
    }
    this.global.routerParams.set('urlBackOutside', '/pages/worklist-cm');
  }

  doClick(prcsCode: string): void {
    if (prcsCode === null) {
      prcsCode = '';
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.headerTable = 'Total Contract ';
    const module = prcsCode.replace('CONTRACT-', '');
    this.headerTable +=
      module.charAt(0).toUpperCase() + module.substr(1).toLowerCase();
    this.formGroup.patchValue({
      moduleCode: prcsCode
    });
    this.currentPosition = prcsCode;
    this.onChangeFilter();
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
    } else {
      this.formGroup.value.fromDate = null;
      this.formGroup.value.untilDate = null;
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
