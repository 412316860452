<ng-container *ngFor="let chart of chartStoreService.result">
  <div
    style="display: block"
    *ngIf="chart.type === 'bar'"
    [ngStyle]="{ height: height }"
  >
    <canvas
      baseChart
      [datasets]="chart.dataSets"
      [labels]="chart.labels"
      [options]="chart.options"
      [legend]="chart.legend"
      [chartType]="chart.type"
      [plugins]="chart.plugins"
      [colors]="chart.colors"
    >
    </canvas>
  </div>
  <div
    class="d-block w-100 position-relative"
    *ngIf="chart.type === 'doughnut'"
    [ngStyle]="{ height: height }"
  >
    <canvas
      baseChart
      [data]="chart.dataSets"
      [labels]="chart.labels"
      [chartType]="model.type"
      [options]="chart.options"
      [plugins]="chart.plugins"
      [colors]="chart.colors"
    >
    </canvas>
  </div>

  <div *ngIf="chart.type === 'pie'">
    <canvas
      baseChart
      [data]="chart.dataSets"
      [labels]="chart.labels"
      [chartType]="chart.type"
      [options]="chart.options"
      [colors]="chart.colors"
    >
    </canvas>
  </div>

  <div
    style="display: block"
    [ngStyle]="{ height: height }"
    *ngIf="chart.type === 'line'"
  >
    <canvas
      baseChart
      [datasets]="chart.dataSets"
      [labels]="chart.labels"
      [options]="chart.options"
      [legend]="chart.legend"
      [chartType]="chart.type"
      [plugins]="chart.plugins"
      [colors]="chart.colors"
    ></canvas>
  </div>

  <div style="display: block" *ngIf="chart.type === 'radar'">
    <canvas
      baseChart
      [datasets]="chart.dataSets"
      [options]="chart.options"
      [labels]="chart.labels"
      [chartType]="chart.type"
    >
    </canvas>
  </div>

  <div style="display: block" *ngIf="chart.type === 'polarArea'">
    <canvas
      baseChart
      [data]="chart.dataSets"
      [labels]="chart.labels"
      [chartType]="chart.type"
      [options]="chart.options"
    >
    </canvas>
  </div>
</ng-container>
