import { ChartOptions } from 'chart.js';
export const barChartOptions: ChartOptions = {
  layout: {
    padding: {
      top: 16
    }
  },
  title: {
    display: false
  },
  tooltips: {
    mode: 'nearest',
    intersect: true,
    callbacks: {
      label: tooltipItem =>
        tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    custom: tooltip => {
      if (tooltip) {
        tooltip.displayColors = false;
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: false,
    labels: {
      usePointStyle: true
    }
  },
  scales: {
    display: true,
    yAxes: [
      {
        display: false,
        gridLines: {
          display: true
        },
        ticks: {
          padding: 1000
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false
        },
        afterBuildTicks(scale) {
          scale.options.barPercentage = scale.maxIndex * 0.1;
          return this;
        }
      }
    ]
  },
  plugins: {
    datalabels: {
      display: true,
      align: 'center',
      anchor: 'center'
    }
  }
};
