import {
  Component,
  EventEmitter,
  Input,
  Output,
  Renderer2
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../../../../../core/base/angular/base-component.component';
import { Vendor } from '../../../../../../core/bean/vendor';
import { AppPopupEmailComponent } from '../../../../../../core/components/app-popup/app-popup-email/app-popup-email.component';
import { AppPopupService } from '../../../../../../core/components/app-popup/app-popup.service';
import { Response } from '../../../../../../core/model/response-model';
import { ResponseStatusModel } from '../../../../../../core/model/response-status-model';
import { AppPopupVendorComparisonComponent } from '../app-popup-vendor-comparison/app-popup-vendor-comparison.component';
import { AppTableVendorService } from './app-table-vendor.service';
import { TableVendorResponseModel } from './model/table-vendor-response-model';
@Component({
  selector: 'app-table-vendor',
  templateUrl: './app-table-vendor.component.html',
  styleUrls: ['./app-table-vendor.component.scss'],
  providers: [AppTableVendorService]
})
export class AppTableVendorComponent extends BaseComponentComponent {
  @Input() model: TableVendorResponseModel<any>;
  @Output() onChooseVendor: EventEmitter<number> = new EventEmitter();

  public vendorList: Vendor[] = [];
  public showOptionList: Array<number> = new Array();
  constructor(
    public appTableVendorService: AppTableVendorService,
    public appPopupService: AppPopupService,
    public translateService: TranslateService,
    public renderer2: Renderer2
  ) {
    super('app-table-vendor');
  }

  public onInit(): void {
    this.getDataFromRouterParams();
    this.setShowOptionList();
    this.handleReloadChanges();
    this.getAndSetCatalogs();
  }

  public getDataFromRouterParams(): void {
    if (this.global.routerParams.get('compareVendorList')) {
      this.vendorList = this.global.routerParams.get('compareVendorList');
    }
  }

  public setShowOptionList(): void {
    this.showOptionList = [10, 25, 50];
  }

  public getAndSetCatalogs(): void {
    this.setStateLoading();
    this.model.setStateLoading();
    this.appTableVendorService
      .getVendors(this.model.stringUrl, this.model.tableRequest)
      .subscribe((response: any) => {
        this.model.setPageRecords(response.records);
        this.model.setTotalRecords(response.totalRecords);
        this.model.setStateReady();
        this.setStateReady();
      });
  }

  public handleReloadChanges(): void {
    this.model.reloadChanges.subscribe(() => {
      this.getAndSetCatalogs();
    });
  }

  public doOpenPopupEmail(emailTo: string): void {
    const url = '/view-vendor/send-email';
    const to = emailTo;
    this.appPopupService
      .open(AppPopupEmailComponent, { url, to })
      .subscribe((response: Response<any>) => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccess(
            this.translateService.instant('view-vendor.alert.msg.sendSuccess')
          );
        } else {
          this.global.alertService.showError(
            this.translateService.instant('view-vendor.alert.msg.sendError')
          );
        }
      });
  }

  public doAddToComparisonList(vendor: Vendor): void {
    const checkVendors = this.vendorList.filter(
      thisVendor => thisVendor.id === vendor.id
    );
    if (!checkVendors || (checkVendors && checkVendors.length > 0)) {
      this.global.alertService.showError(
        'view-vendor.msg.error.cannotAddSameVendor'
      );
    } else {
      this.vendorList.push(vendor);
      this.global.routerParams.set('compareVendorList', this.vendorList);
      this.global.alertService.showSuccess(
        'view-vendor.msg.success.addToCompare'
      );
    }
  }

  public doShowPopupComparison(): void {
    const vendorList: Vendor[] = this.vendorList;
    this.appPopupService.open(
      AppPopupVendorComparisonComponent,
      { vendorList },
      { size: 'lg', backdrop: 'static' }
    );
  }

  public onLoadImage(event: any): void {
    this.renderer2.removeChild(
      event.target.parentElement,
      event.target.nextSibling
    );
  }

  public onErrorImage(event: any): void {
    event.target.classList.add('fa');
    event.target.classList.add('fa-image');
    this.renderer2.removeChild(
      event.target.parentElement,
      event.target.nextSibling
    );
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
