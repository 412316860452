import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorSegmentationHistory } from '../../core/bean/vendor-segmentation-history';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { AppPopupTabSegmentationComponent } from './app-popup-tab-segmentation.component';
@Component({
  templateUrl: './tab-segmentation.component.html'
})
export class TabSegmentationComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<VendorSegmentationHistory>;
  public tableResponse: TableResponseModel<VendorSegmentationHistory>;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('tab-segmentation', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setCustomData();
    this.model.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'segmentationName',
        header: 'table.column.segmentation',
        customClass: 'text-center'
      },
      {
        field: 'qualification.name',
        header: 'table.column.qualification',
        customClass: 'text-center'
      }
    ]);
  }

  public setCustomData(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponse.setCustomData(customData);
  }

  public doDetail(vendorSegmentationHistory: VendorSegmentationHistory): void {
    this.appPopupService.open(
      AppPopupTabSegmentationComponent,
      { vendorSegmentationHistory },
      { size: 'lg', backdrop: 'static' }
    );
  }
}
