import {
  Component,
  ElementRef,
  Input,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { take } from 'rxjs/operators';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { AppPopupTreeComponent } from '../app-popup/app-popup-tree/app-popup-tree.component';
import { AppPopupService } from '../app-popup/app-popup.service';
import { TreeModel } from '../app-tree/model/tree-model';
@Component({
  selector: 'app-text-tree',
  templateUrl: './app-text-tree.component.html',
  styleUrls: ['./app-text-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: makeProvider(AppTextTreeComponent)
})
export class AppTextTreeComponent extends BaseValueAccessorComponent<string> {
  @Input() id: any;
  @Input() isMultiple: boolean;
  @Input() header: string;
  @Input() onlyLastChild: boolean;
  @Input() limitLevel: number;
  treeModel: TreeModel;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public popupService: AppPopupService
  ) {
    super('app-text-tree', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    if (this.formControl) {
      this.setFormControlValue();
    }
    this.doBuildTreeModel();
  }

  setFormControlValue(): void {
    if (this.isMultiple && this.formControl.value === null && !this.ISVIEW) {
      this.formControl.patchValue([]);
    }
  }

  doBuildTreeModel(): void {
    this.treeModel = new TreeModel(
      this.stringUrl.slice(1),
      'READ',
      this.id,
      this.isMultiple
    );
    this.treeModel.onlyLastChild = this.onlyLastChild;
    this.treeModel.limitLevel = this.limitLevel;
  }

  doOpenPopupTree(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.treeModel.checkedTreeItemListTemp = Array.from(
      this.formControl.value || []
    );
    this.formControl.markAsTouched();
    this.popupService
      .open(AppPopupTreeComponent, { model: this.treeModel })
      .pipe(take(1))
      .subscribe((data: any) => {
        this.formControl.patchValue(data);
        this.onChange.emit(data);
      });
  }
}
