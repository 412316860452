<app-popup header="app-committee.popup.addCommittee">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-left">
      <label class="col-sm-3">{{
        'app-committee.form.position' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          [optionList]="positionOptionList"
          formControlName="position"
          size="LG"
        ></app-combo-box>
      </div>
    </div>

    <div class="form-group row text-left" *ngIf="!isUserNonPromise">
      <label class="col-sm-3">{{
        'app-committee.form.user' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-dropdown-select
          *ngIf="todo === 'add'"
          size="LG"
          formControlName="userList"
          type="CHOSEN"
          isMultiple="true"
          container="body"
          [optionList]="userOptionList"
        >
          <ng-container *ngIf="userOptionList.getRequestValues().length !== 0">
            <ng-template #footer>
              <div
                class="text-primary"
                (click)="doAddNew()"
                style="
                  cursor: pointer;
                  padding: 1rem;
                  background-color: #f0f0f0;
                  border-radius: 0.2rem;
                "
                ><strong
                  ><em class="pir pi-plus"></em>
                  {{ 'app-committee.form.addNew' | translate }}</strong
                ></div
              >
            </ng-template>
          </ng-container>
        </app-dropdown-select>
        <app-dropdown-select
          *ngIf="todo !== 'add'"
          size="LG"
          formControlName="user"
          type="AUTOCOMPLETE"
          [params]="organizationParam"
          stringUrl="/app-committee/auto-complete-user-list"
        >
        </app-dropdown-select>
      </div>
    </div>

    <ng-container *ngIf="isUserNonPromise">
      <div class="form-group row text-left">
        <label class="col-sm-3">{{
          'app-committee.form.name' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field size="LG" formControlName="name"> </app-text-field>
        </div>
      </div>
      <div class="form-group row text-left">
        <label class="col-sm-3">{{
          'app-committee.form.email' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-dropdown-select
            size="LG"
            formControlName="email"
            [isAddManual]="true"
            type="AUTOCOMPLETE"
            [params]="organizationParam"
            stringUrl="/app-committee/auto-complete-email-list"
            (onChange)="onChangeEmail()"
            showLimitValue="5"
          >
            <ng-template #value let-value>
              {{ value.email ? value.email : value.name }}
            </ng-template>
            <ng-template #option let-data>
              <div *ngIf="data.email"
                ><strong> {{ data.email }}</strong>
                <div class="text-secondary">{{ data.name }}</div></div
              >
            </ng-template>
          </app-dropdown-select>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'committee.form.organization' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-tree
            size="LG"
            formControlName="organizationList"
            stringUrl="/organization"
            isLazy="true"
          >
          </app-text-tree>
        </div>
      </div>
    </ng-container>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button color="LIGHT" (onClick)="activeModal.close(true)">{{
        'app.button.cancel' | translate
      }}</app-button>

      <app-button *ngIf="todo === 'add'" color="PRIMARY" (onClick)="doSave()">{{
        'app.button.add' | translate
      }}</app-button>
      <app-button
        *ngIf="todo !== 'add'"
        color="PRIMARY"
        (onClick)="doReplace()"
        >{{ 'app.button.add' | translate }}</app-button
      >
    </div>
  </ng-template>
</app-popup>
