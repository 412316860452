import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppBadgeModule } from '../app-badge/app-badge.module';
import { AppBadgeCatalogComponent } from './app-badge-catalog.component';

@NgModule({
    imports: [VendorModule, AppBadgeModule],
    declarations: [AppBadgeCatalogComponent],
    entryComponents: [AppBadgeCatalogComponent],
    exports: [AppBadgeCatalogComponent]
  })
export class AppBadgeCatalogModule {

}
