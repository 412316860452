import { BaseEntity } from '../base/base-entity';
import { VendorPerformance } from '../bean/vendor-performance';

export class VendorPerformanceSummaryEntity extends BaseEntity {
    scoringCriteriaGroupName: string;
    scoringCriteriaGroupCode: string;
    performance: VendorPerformance = new VendorPerformance();
    weight: number;
    result: number;
    total: number;
}
