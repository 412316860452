import { Organization } from '../../core/bean/organization';
import { PrStatus } from '../../core/bean/pr-status';

export class PrCustomRequest {
  statusCode: String;
  fromDate: String;
  untilDate: String;
  prStatusList: PrStatus[] = [];
  organizationList: Organization[] = [];
}
