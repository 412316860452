import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { OfficialReportEditAddComponent } from './official-report-edit.component';
import { OfficialReportComponent } from './official-report.component';

const routes: Routes = [
  {
    path: '',
    component: OfficialReportComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'edit',
    component: OfficialReportEditAddComponent,
    data: { breadcrumb: 'official-report.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [OfficialReportComponent, OfficialReportEditAddComponent]
})
export class OfficialReportModule {}
