<dashboard-widget-card
  [isLoading]="model.isLoading"
  [header]="moduleCode + '.title'"
  customClassHeader="align-items-center"
>
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    novalidate
    *ngIf="!model.isLoading"
  >
    <ng-template #headerRight>
      <div class="d-flex">
        <div class="order-type mr-2" [ngStyle]="{ width: '150px' }">
          <app-dropdown-select
            type="CHOSEN"
            size="XL"
            container="body"
            formControlName="orderType"
            [optionList]="orderTypeOptionList"
            (onChange)="onChangeOrderType($event)"
            placeholder="dashboard-widget-value-of-order.form.placeholder.type"
            showLimitValue="1"
            [isMultiple]="true"
          >
          </app-dropdown-select>
        </div>
        <div [ngStyle]="{ width: '150px' }">
          <app-dropdown-select
            type="COMBOBOX"
            size="XL"
            container="body"
            formControlName="year"
            [optionList]="yearOptionList"
            (onChange)="onChangeYear($event)"
            placeholder="dashboard-widget-value-of-order.form.placeholder.year"
          >
          </app-dropdown-select>
        </div>
      </div>
    </ng-template>
    <div *ngIf="!model.isLoading" class="dashboard-widget-value-of-order">
      <div class="widget-info row">
        <div class="col-sm-4 d-flex align-items-center">
          <h4 [ngStyle]="{ color: 'var(--primary-dark)' }">
            {{
              formGroup.value.orderType && formGroup.value.orderType.length > 0
                ? total || 0
                : 0
            }}
            IDR
          </h4>
        </div>
        <div
          class="col-sm-8 d-flex align-items-center"
          [ngStyle]="{
            'min-width': '180px',
            'max-width': '180px',
            'margin-left': 'auto',
            'margin-right': '0px'
          }"
        >
          <em
            *ngIf="widgetValueOfOrderResponse.growthFromPastYear > 0"
            class="pir pi-arrow-up"
            [ngStyle]="{ color: '#00B84D' }"
          >
          </em>
          <em
            *ngIf="widgetValueOfOrderResponse.growthFromPastYear < 0"
            class="pir pi-arrow-down"
            [ngStyle]="{ color: 'var(--danger)' }"
          >
          </em>
          <strong class="cml-1"
            >{{
              formGroup.value.orderType && formGroup.value.orderType.length > 0
                ? growthFromPastYear || 0
                : 0
            }}
          </strong>
          <div class="cml-1"
            >({{
              formGroup.value.orderType && formGroup.value.orderType.length > 0
                ? widgetValueOfOrderResponse.growthFromPastYearPercentage || 0
                : 0
            }}%)
          </div>
        </div>
      </div>

      <app-chart [model]="chartModel" height="210px"></app-chart>
      <ng-template #footer>
        <div
          class="row"
          *ngIf="
            formGroup.value.orderType &&
            formGroup.value.orderType.length > 0 &&
            formGroup.value.year
          "
        >
          <ng-container *ngFor="let model of modelList">
            <div class="cml-2 d-flex align-items-center">
              <div
                [ngStyle]="{
                  background: model.color,
                  width: '20px',
                  height: '20px'
                }"
              ></div>
            </div>
            <div
              class="cml-1 row col-sm-4 text-left align-items-center"
              style="display: grid"
            >
              <h6 class="font-weight-bold"> {{ model.name }}</h6>

              <h6> {{ model.total || 0.0 }} IDR</h6>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </form>
</dashboard-widget-card>
