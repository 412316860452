import { Injectable } from '@angular/core';
import { UserNotificationResponse } from '../../../pages/user-notification/user-notification-response';
import { Global } from '../../global/global';
import { WebSocketService } from './websocket.service';

@Injectable({ providedIn: 'root' })
export class UserNotificationWebsocketService extends WebSocketService<UserNotificationResponse> {
  constructor(global: Global) {
    super(`user-notification/${global.userSession.token}`);
  }

  protected onReceivedMessage(message: string): Promise<any> {
    return new Promise(resolve => {
      const userNotificationResponse = JSON.parse(message);
      if (userNotificationResponse) {
        resolve(this.setReceivedMessage(userNotificationResponse));
      }
    });
  }
}
