import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { WorkPlan } from '../../core/bean/work-plan';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { WebSocketBudgetService } from './../budget-allocation-bjb/websocket-budget.service';
import { WebSocketWorkProgramService } from './../work-program/websocket-work-program.service';
import { WorkPlanView } from 'src/app/core/bean/work-plan-view';

@Component({
  templateUrl: 'work-plan.component.html',
  providers: [
    { provide: WebSocketBudgetService },
    { provide: WebSocketWorkProgramService }
  ]
})
export class WorkPlanComponent extends BaseModuleComponent {
  tableResponse: TableResponseModel<WorkPlanView>;
  public isSyncRunning = false;
  public customData: string;

  @ViewChild(AppTableComponent) table: AppTableComponent;
  constructor(
    translateService: TranslateService,
    public websocketWorkProgramService: WebSocketWorkProgramService,
    public websocketBudgetService: WebSocketBudgetService
  ) {
    super('work-plan', translateService);
  }

  onInit(): void {
    this.setCustomData();
    this.initializeWebSocketWorkProgramConnection();
    this.initializeWebSocketBudgetConnection();
    this.buildTableResponse();
    this.setStateReady();
    this.global.routerParams.clear();
  }

  public setCustomData(): void {
    this.customData = this.global.routerParams.get('statusWorkplanFromWidget');
  }

  public initializeWebSocketWorkProgramConnection(): void {
    this.websocketWorkProgramService.connect().then();
  }
  public initializeWebSocketBudgetConnection(): void {
    this.websocketBudgetService.connect().then();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'workPlan.number',
        header: 'table.column.number',
        customClass: 'text-center',
        fn: this.generateNumber.bind(this)
      },
      { field: 'workPlan.name', header: 'table.column.name' },
      {
        field: 'workPlan.organization.name',
        header: 'table.column.organization',
        customClass: 'text-center'
      },
      {
        field: 'workPlan.workTarget',
        header: 'table.column.workTarget',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: 'status',
        header: 'table.column.status',
        customClass: 'text-center'
      }
    ]);
  }

  public generateNumber(number: string, workPlanView: WorkPlanView): string {
    return ( workPlanView.workPlan.generateMemoNumber ? number + workPlanView.workPlan.generateMemoNumber : number);
  }

  public doEdit(workPlanView: WorkPlanView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('workPlanId', workPlanView.workPlan.id);
    this.global.routerParams.set('isWorkflowProgress', false);
    this.router.navigate(['/pages/work-plan/edit']);
    if (
      workPlanView.workPlan.workPlanStatus.code ===
      this.global.appConstant.esm.WORK_PLAN_STATUS_PURCHASE_REQUISITION
    ) {
      this.global.routerParams.set('todo', 'view');
      this.router.navigate(['/pages/work-plan-realize/work-plan']);
    } else {
      this.router.navigate(['/pages/work-plan/edit']);
    }
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/work-plan/add']);
  }

  public doDetail(workPlanView: WorkPlanView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('workPlanId', workPlanView.workPlan.id);
    this.global.routerParams.set('isWorkflowProgress', false);
    this.router.navigate(['/pages/work-plan/detail']);
  }

  public doDelete(workPlanView: WorkPlanView): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<WorkPlan>>(
              '/work-plan/delete',
              workPlanView.workPlan
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
