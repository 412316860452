<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="approval-workflow.title"
      detail="Set Approval"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'approval-workflow.form.stage' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ workflowModel.module.name }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'approval-workflow.form.approval' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              autofocus="true"
              formControlName="approval"
              [optionList]="approvalOptionList"
              (onChange)="onChangeApproval($event)"
            ></app-combo-box>
          </div>
        </div>
        <div
          *ngIf="formGroup.value.approval != null"
          class="form-group row text-sm-left"
        >
          <label class="col-sm-3 control-label"></label>
          <div class="col text-left" [ngStyle]="{ padding: '0 0 0 5px' }">
            <app-approval-path [model]="approvalPath"></app-approval-path>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
