import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { UserMonitoringEditComponent } from './user-monitoring-edit.component';
import { UserMonitoringComponent } from './user-monitoring.component';
import { AppPopupAddUserCounterComponent } from './app-popup-add-user-counter.component';
const routes = [
  { path: '', component: UserMonitoringComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: UserMonitoringEditComponent,
    data: { breadcrumb: 'user-monitoring.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [UserMonitoringComponent, UserMonitoringEditComponent, AppPopupAddUserCounterComponent]
})
export class UserMonitoringModule { }
