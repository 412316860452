import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { DashboardResponse } from '../../../../pages/dashboard/dashboard-response';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { WebContent } from '../../../bean/web-content';
import { Response } from '../../../model/response-model';
import { ResponseStatusModel } from '../../../model/response-status-model';
import { AuthenticationService } from '../../../services/authentication.service';
import { Validators } from '../../../validators';
import { AppPopupChangePasswordService } from '../app-popup-change-password/app-popup-change-password.service';
import { AppPopUpTOCService } from '../app-popup-toc/app-popup-toc.service';
@Component({
  templateUrl: './app-popup-user-toc.component.html',
  styleUrls: ['./app-popup-user-toc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupUserTOCComponent extends BaseComponentComponent {
  readonly urlRouterNavigateToPortal = '/portal';
  termsAndCondition: any;
  @Input() dashboardResponse: DashboardResponse;
  // private appPopupChangePasswordService: AppPopupChangePasswordService;

  constructor(
    public activeModal: NgbActiveModal,
    public authenticationService: AuthenticationService,
    public sanitized: DomSanitizer,
    public appPopupChangePasswordService: AppPopupChangePasswordService,
    public appPopUpTOCService: AppPopUpTOCService
  ) {
    super('app-popup-user-toc');
  }

  onInit(): void {
    this.test();
    this.buildFormGroup();
    this.setStateReady();
  }

  test(): void {
    this.termsAndCondition = this.sanitized.bypassSecurityTrustHtml(
      this.dashboardResponse.contentUser.value.toString()
    );
  }

  buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      toc: [null],
      check: [
        null,
        Validators.isChecked(null, 'app-popup-user-toc.validation.isChecked')
      ]
    });
  }

  doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<WebContent>>('/dashboard/save-user-toc', {})
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.activeModal.dismiss();
                  if(this.dashboardResponse.contentChangePassword != null && this.dashboardResponse.contentVendor == null){
                    this.showPopupUserChangePassword(this.dashboardResponse);
                  } else  if (this.dashboardResponse.contentVendor != null){
                    this.showPopupTremAndCondition(this.dashboardResponse);
                  }
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }
  private showPopupTremAndCondition(
    dashboardResponse: DashboardResponse
  ): void {
    this.appPopUpTOCService.open(dashboardResponse);
  }


  private showPopupUserChangePassword(
    dashboardResponse: DashboardResponse
  ): void {
    this.appPopupChangePasswordService.open(dashboardResponse);
  }

  onClosePopup(): void {
    this.authenticationService.logout();
  }

  public downloadUserToc(): void {
    const url = '/dashboard/download-toc-document/';
    this.httpClientService
      .get<Response<File>>(url + this.global.currentLang)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          if (response.body !== null) {
            window.open(
              this.global.config.BACKEND_ADDRESS +
                '/registration' +
                '/file/view/' +
                response.body.uploadedFileName +
                '/' +
                this.global.appConstant.fileType.DOC_REGIST_VENDOR
            );
          } else {
            this.global.alertService.showError(
              this.global.translateService.instant(
                'registration-document.fileNotExist'
              )
            );
          }
        } else {
          this.global.alertService.showError(response.statusText);
        }
      });
  }
}
