import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Organization } from 'src/app/core/bean/organization';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ScoringCriteria } from '../../core/bean/scoring-criteria';
import { ScoringCriteriaGroup } from '../../core/bean/scoring-criteria-group';
import { ScoringTemplateWeight } from '../../core/bean/scoring-template-weight';
import { VendorPerformanceScore } from '../../core/bean/vendor-performance-score';
import { VendorPerformanceSummary } from '../../core/bean/vendor-performance-summary';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { PerformanceFulfillmentEditRequest } from './performance-fulfillment-edit-request';
import { PerformanceFulfillmentEditResponse } from './performance-fulfillment-edit.response';
import { PerformanceFulfillmentInsertRequest } from './performance-fulfillment-insert.request';
@Component({
  templateUrl: './performance-fulfillment-edit.component.html'
})
export class PerformanceFulfillmentEditComponent
  extends BaseModuleComponent
  implements OnInit
{
  public vendorId: number;
  public pFulfillmentEditResponse: PerformanceFulfillmentEditResponse =
    new PerformanceFulfillmentEditResponse();
  public performanceFulfillmentInsertRequest: PerformanceFulfillmentInsertRequest =
    new PerformanceFulfillmentInsertRequest();
  public scoringCriteriaList: ScoringCriteria[] = [];
  public scoringCriteriaGroupList: ScoringCriteriaGroup[] = [];
  public scoringTemplateWeightList: ScoringTemplateWeight[] = [];
  public performanceSummary: VendorPerformanceSummary =
    new VendorPerformanceSummary();
  public performanceScore: VendorPerformanceScore =
    new VendorPerformanceScore();
  public performanceScoreList: VendorPerformanceScore[] = [];
  public formReady = false;
  public total = 0;
  public result = 0;
  public value = 0;
  public totalScoring = 0;
  public isScoring = false;
  public procSubOrganization: Organization = null;
  public objectId: number;
  public objectName: string;
  public sowPaymentTermId: number;

  public fileUploader: FileUploader = new FileUploader(
    '/performance-fulfillment/',
    'performance-fulfillment',
    this.global.appConstant.fileType.DOC_PERFORMANCE
  );
  public readonly urlSavePerformance = '/performance-fulfillment/insert';

  constructor(translateService: TranslateService) {
    super('performance-fulfillment', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorId = this.global.routerParams.get('vendorId');
    this.objectId = this.global.routerParams.get('objectId');
    this.objectName = this.global.routerParams.get('objectName');
    this.sowPaymentTermId = this.global.routerParams.get('sowPaymentTermId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      file: [null],
      note: [null],
      rating: [null],
      scoringGroupTemplateCommon: this.formBuilder.array([]),
      scoringGroupTemplateSpecific: this.formBuilder.array([]),
      summaryList: this.formBuilder.array([])
    });
  }

  public setFormGroup(): void {
    const request = new PerformanceFulfillmentEditRequest();
    request.objectId = this.objectId;
    request.objectName = this.objectName;
    request.sowPaymentTermId = this.sowPaymentTermId;
    this.httpClientService
      .post<PerformanceFulfillmentEditResponse>(
        '/performance-fulfillment/edit',
        request
      )
      .subscribe(pFulfillmentEditResponse => {
        this.pFulfillmentEditResponse = pFulfillmentEditResponse;
        this.scoringTemplateWeightList =
          this.pFulfillmentEditResponse.scoringTemplateWeightList;

        this.formGroup.get('rating').setIsView(true);
        this.setFormArray();
        this.buildSummaryList();

        this.log.debug('formGroup.controls');
        this.log.debug(this.formGroup.controls);
        this.procSubOrganization =
          this.pFulfillmentEditResponse.procSubmissionOrganization;
        this.setStateReady();

        if (
          this.global.userSession.user.organization.id !==
          this.procSubOrganization.id
        ) {
          this.formGroup.get('file').setIsView(true);
          this.formGroup.get('note').setIsView(true);
        }
      });
  }

  public setFormArray(): void {
    this.pFulfillmentEditResponse.scoringCriteriaGroupByIsCommonList.forEach(
      scoringGroupTemplateCommon => {
        this.scoringGroupTemplateCommon.push(
          this.formBuilder.group({
            name: scoringGroupTemplateCommon.name,
            avgTotal: [null],
            scoringCriteriaList: this.formBuilder.array([]),
            scoringCriteriaGroup: scoringGroupTemplateCommon
          })
        );
      }
    );
    this.setScoringCriteriaCommon();

    this.pFulfillmentEditResponse.scoringCriteriaGroupByIsSpecificList.forEach(
      scoringGroupTemplateSpecific => {
        this.scoringGroupTemplateSpecific.push(
          this.formBuilder.group({
            name: scoringGroupTemplateSpecific.name,
            avgTotal: [null],
            scoringCriteriaList: this.formBuilder.array([]),
            scoringCriteriaGroup: scoringGroupTemplateSpecific
          })
        );
      }
    );

    this.setScoringCriteriaSpecific();

    this.formReady = true;
  }

  public setScoringCriteriaSpecific(): void {
    const scoringCriteriaGroupSpecificList = this.formGroup.controls
      .scoringGroupTemplateSpecific as FormArray;
    this.pFulfillmentEditResponse.scoringCriteriaGroupByIsSpecificList.forEach(
      (scoringCriteriaGroupSpecific, index) => {
        scoringCriteriaGroupSpecific.scoringCriteriaList.forEach(
          scoringCriteria => {
            let minInput = 0;
            let maxInput = 0;

            if (scoringCriteria.scoringCriteriaRangeList.length !== 0) {
              minInput = this.getMaxMinInput(scoringCriteria)[0];
              maxInput = this.getMaxMinInput(scoringCriteria)[1];
            }

            const scoringCriteriaSpecific = this.formBuilder.group({
              name: scoringCriteria.name,
              attribute: scoringCriteria.attribute,
              value: [
                scoringCriteria.value ? scoringCriteria.value : null,
                Validators.compose([
                  Validators.required(),
                  Validators.min(minInput),
                  Validators.max(maxInput)
                ])
              ],
              result: scoringCriteria.result ? scoringCriteria.result : [null],
              scoringCriteria
            });

            const scoringCriteriaSpecificList =
              scoringCriteriaGroupSpecificList.controls[index].get(
                'scoringCriteriaList'
              ) as FormArray;
            scoringCriteriaSpecificList.push(scoringCriteriaSpecific);
          }
        );
      }
    );
  }
  public setScoringCriteriaCommon(): void {
    const scoringCriteriaGroupCommonList = this.formGroup.controls
      .scoringGroupTemplateCommon as FormArray;
    this.pFulfillmentEditResponse.scoringCriteriaGroupByIsCommonList.forEach(
      (scoringCriteriaGroupCommon, index) => {
        scoringCriteriaGroupCommon.scoringCriteriaList.forEach(
          scoringCriteria => {
            let minInput = 0;
            let maxInput = 0;

            if (scoringCriteria.scoringCriteriaRangeList.length !== 0) {
              minInput = this.getMaxMinInput(scoringCriteria)[0];
              maxInput = this.getMaxMinInput(scoringCriteria)[1];
            }

            const scoringCriteriaCommon = this.formBuilder.group({
              name: scoringCriteria.name,
              attribute: scoringCriteria.attribute,
              value: [
                scoringCriteria.value ? scoringCriteria.value : null,
                Validators.compose([
                  Validators.required(),
                  Validators.min(minInput),
                  Validators.max(maxInput)
                ])
              ],
              result: scoringCriteria.result ? scoringCriteria.result : [null],
              scoringCriteria
            });

            const scoringCriteriaCommonList =
              scoringCriteriaGroupCommonList.controls[index].get(
                'scoringCriteriaList'
              ) as FormArray;
            scoringCriteriaCommonList.push(scoringCriteriaCommon);
          }
        );
      }
    );
  }

  // set Result for manual input
  public setResult(
    eventValue: any,
    data: FormGroup,
    scoringCriteria: ScoringCriteria
  ): void {
    eventValue = eventValue ? parseFloat(eventValue) : null;

    const rangeList = scoringCriteria.scoringCriteriaRangeList.filter(
      range => range.from <= eventValue && eventValue <= range.until
    );
    if (rangeList.length > 0) {
      data.value.result = rangeList[0].result;
    } else {
      const rangeListInfinite = scoringCriteria.scoringCriteriaRangeList.filter(
        range => range.from <= eventValue && range.isInfinity
      );
      data.value.result =
        rangeListInfinite.length > 0 ? rangeListInfinite[0].result : null;
    }

    data.patchValue({
      value: eventValue,
      result: data.value.result
    });

    this.log.debug('DATA: ' + scoringCriteria.name);
    this.log.debug(data.value);
  }

  public getMaxMinInput(scoringCriteria: ScoringCriteria): number[] {
    const lastIndex = scoringCriteria.scoringCriteriaRangeList.length - 1;
    const minInput = scoringCriteria.scoringCriteriaRangeList[0].from;
    const maxInput = scoringCriteria.scoringCriteriaRangeList[lastIndex].until;

    const inputList: number[] = [];
    inputList.push(minInput);
    inputList.push(maxInput);
    return inputList;
  }

  public doSetAvgTotal(
    criteriaList: FormArray,
    scoringCriteriaGroup: ScoringCriteriaGroup,
    itemCommonSpesific: FormGroup
  ): number {
    let avgTotal = 0; // perhitungan rata2
    criteriaList.controls.forEach(data => {
      avgTotal += data.value.result * 100;
    });
    avgTotal = Math.round(avgTotal) / 100 / criteriaList.controls.length;

    itemCommonSpesific.patchValue({
      avgTotal
    });

    // di set ke formArray summaryList
    this.totalScoring = 0;

    const summaryList = this.formGroup.get('summaryList') as FormArray;
    summaryList.controls.forEach((summaryControl, index) => {
      if (
        summaryControl.value.scoringCriteriaGroup.code ===
        scoringCriteriaGroup.code
      ) {
        summaryList.at(index).patchValue({
          result: avgTotal,
          total: avgTotal * summaryControl.value.weight * 0.01
        });
      }

      this.totalScoring +=
        summaryControl.value.weight * 0.01 * summaryControl.value.result * 100;
    });
    this.totalScoring = Math.round(this.totalScoring) / 100;
    this.formGroup.patchValue({ rating: this.totalScoring });
    this.formGroup.get('rating').setIsView(true);

    return avgTotal;
  }

  public buildSummaryList(): void {
    const parentForm = this.formGroup.controls.summaryList as FormArray;
    this.pFulfillmentEditResponse.scoringTemplateWeightList.forEach(
      scoringTempW => {
        const formGroup = this.formBuilder.group({
          scoringCriteriaGroup: scoringTempW.scoringCriteriaGroup,
          weight: scoringTempW.weight,
          result: [null],
          total: [null]
        });
        parentForm.push(formGroup);
      }
    );
  }

  public get scoringGroupTemplateCommon(): FormArray {
    return this.formGroup.get('scoringGroupTemplateCommon') as FormArray;
  }

  public get scoringGroupTemplateSpecific(): FormArray {
    return this.formGroup.get('scoringGroupTemplateSpecific') as FormArray;
  }

  public get summaryList(): FormArray {
    return this.formGroup.get('summaryList') as FormArray;
  }

  public doBack(): void {
    this.global.routerParams.set('vendorId', this.vendorId);
    this.global.routerParams.set('objectId', this.objectId);
    this.global.routerParams.set('objectName', this.objectName);
    this.router.navigate([
      '/pages/performance-fulfillment/detail/detail-termin'
    ]);
  }

  public setPerformanceSummary(): void {
    this.performanceFulfillmentInsertRequest.performanceSummaryList = [];

    this.summaryList.controls.forEach(summary => {
      const performanceSummary: VendorPerformanceSummary =
        new VendorPerformanceSummary();
      performanceSummary.weight = summary.value.weight;
      performanceSummary.result = Math.round(summary.value.result * 100) / 100;
      performanceSummary.total = Math.round(summary.value.total * 100) / 100;
      performanceSummary.scoringCriteriaGroupCode =
        summary.value.scoringCriteriaGroup.code;
      performanceSummary.scoringCriteriaGroupName =
        summary.value.scoringCriteriaGroup.name;
      this.performanceFulfillmentInsertRequest.performanceSummaryList.push(
        performanceSummary
      );
    });
  }

  public setPerformanceScore(): void {
    const scoringCriteriaGroupCommon =
      this.formGroup.controls.scoringGroupTemplateCommon.value;
    scoringCriteriaGroupCommon.forEach(scoringCriteriaGroup => {
      scoringCriteriaGroup.scoringCriteriaList.forEach(scoringCriteria => {
        this.performanceScore = new VendorPerformanceScore();
        this.performanceScore.scoringCriteriaGroupCode =
          scoringCriteriaGroup.scoringCriteriaGroup.code;
        this.performanceScore.scoringCriteriaGroupName =
          scoringCriteriaGroup.scoringCriteriaGroup.name;
        this.performanceScore.value = scoringCriteria.value;
        this.performanceScore.scoringCriteriaName =
          scoringCriteria.scoringCriteria.name;
        this.performanceScore.scoringCriteriaCode =
          scoringCriteria.scoringCriteria.code;
        this.performanceScore.result = scoringCriteria.result;
        this.performanceScoreList.push(this.performanceScore);
      });
    });

    const scoringCriteriaGroupSpecific =
      this.formGroup.controls.scoringGroupTemplateSpecific.value;
    scoringCriteriaGroupSpecific.forEach(scoringCriteriaGroup => {
      scoringCriteriaGroup.scoringCriteriaList.forEach(scoringCriteria => {
        this.performanceScore = new VendorPerformanceScore();
        this.performanceScore.scoringCriteriaGroupCode =
          scoringCriteriaGroup.scoringCriteriaGroup.code;
        this.performanceScore.scoringCriteriaGroupName =
          scoringCriteriaGroup.scoringCriteriaGroup.name;
        this.performanceScore.scoringCriteriaName =
          scoringCriteria.scoringCriteria.name;
        this.performanceScore.scoringCriteriaCode =
          scoringCriteria.scoringCriteria.code;
        this.performanceScore.value = scoringCriteria.value;
        this.performanceScore.result = scoringCriteria.result;
        this.performanceScoreList.push(this.performanceScore);
      });
    });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.savePerformance();
          }
        });
    }
  }

  public savePerformance(): void {
    this.setStateProcessing();

    this.setPerformanceScore();
    this.setPerformanceSummary();
    this.performanceFulfillmentInsertRequest.scoringTemplate =
      this.pFulfillmentEditResponse.scoringTemplate;
    this.performanceFulfillmentInsertRequest.vendor =
      this.pFulfillmentEditResponse.vendor;
    this.performanceFulfillmentInsertRequest.sowPaymentTermId =
      this.sowPaymentTermId;
    this.performanceFulfillmentInsertRequest.performance = this.formGroup.value;
    this.performanceFulfillmentInsertRequest.performance.file = null;
    this.performanceFulfillmentInsertRequest.filePerformance =
      this.fileUploader.fileObjectList;
    if (
      this.performanceFulfillmentInsertRequest.filePerformance.length !== 0 &&
      !this.performanceFulfillmentInsertRequest.filePerformance[0].isUploaded
    ) {
      this.performanceFulfillmentInsertRequest.filePerformance = [];
    }
    this.performanceFulfillmentInsertRequest.performanceScoreList =
      this.performanceScoreList;
    this.httpClientService
      .post<Response<PerformanceFulfillmentInsertRequest>>(
        this.urlSavePerformance,
        this.performanceFulfillmentInsertRequest
      )
      .subscribe(response => {
        this.global.routerParams.set('vendorId', this.vendorId);
        this.global.routerParams.set('objectId', this.objectId);
        this.global.routerParams.set('objectName', this.objectName);

        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccessSavingOnNextRoute();
          this.router.navigate([
            '/pages/performance-fulfillment/detail/detail-termin'
          ]);
        } else {
          this.global.alertService.showError(response.statusText);
          this.setStateReady();
        }
      });
  }
}
