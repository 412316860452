import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { PaginationModel } from './model/pagination-model';
@Component({
  selector: 'app-pagination',
  templateUrl: './app-pagination.component.html',
  styleUrls: ['./app-pagination.component.scss']
})
export class AppPaginationComponent extends BaseComponentComponent {
  @Input() model: PaginationModel;
  @Output() onChange: EventEmitter<number> = new EventEmitter();
  constructor() {
    super('app-pagination');
  }

  public onInit(): void {
    this.setStateReady();
  }

  public pageChanges(page: number): void {
    this.model.setCurrentPage(page);
    this.onChange.emit(page);
  }
}
