import { EventEmitter, Injectable } from '@angular/core';
import { BaseParticipantEntity } from 'src/app/core/base/base-participant-entity';
import { WebSocketService } from 'src/app/core/services/websocket/websocket.service';

@Injectable({ providedIn: 'root' })
export class ChatParticipantWebsocketService extends WebSocketService<BaseParticipantEntity> {
  constructor() {
    super(`chat-participant`);
  }
  public participant: EventEmitter<BaseParticipantEntity> = new EventEmitter();

  protected onReceivedMessage(message: string): Promise<any> {
    return new Promise(resolve => {

    const baseParticipant: BaseParticipantEntity = JSON.parse(message);
    if (baseParticipant) {
        resolve(this.setReceivedMessage(baseParticipant));
        this.participant.emit(baseParticipant);
      }
    });
  }
}
