import { AppConstant } from '../../../constant/app-constant';
import { ReadMorePlugin } from '../interfaces/available-table-plugin';
import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';
export const AppTableReadMorePlugin: TablePlugin = {
  name: 'read-more',
  after: (data: TablePluginData): void => {
    const readMorePlugin: ReadMorePlugin = data.column
      .plugins as ReadMorePlugin;
    const appConstant = new AppConstant();
    let element: HTMLElement;
    if (data.element.querySelector('span.td-text')) {
      element = data.element.querySelector('span.td-text') as HTMLElement;
    } else {
      element = data.element;
    }
    element.classList.add('max-line', 'd-block');
    element.style['-webkit-line-clamp'] =
      readMorePlugin.maxLine || appConstant.core.MAX_LINE_HTML_TEXT;

    const aElement = document.createElement('a');
    aElement.classList.add('text-primary', 'cursor-pointer');
    aElement.textContent = 'Show more';
    let isShowMore = true;
    aElement.addEventListener('click', event => {
      event.preventDefault();
      event.stopPropagation();
      isShowMore = !isShowMore;
      aElement.textContent = isShowMore ? 'Show more' : 'Show less';
      element.classList.toggle('white-space-normal');
    });
    data.element.appendChild(aElement);
  }
};
