import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';

interface TipItem {
  id: number;
  header: string;
  content: string;
  active: boolean;
  dataTarget: string;
}

@Component({
  selector: 'theme-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss']
})
export class TipsComponent implements OnInit, OnDestroy {
  public tipItemList: Array<TipItem> = new Array();

  public activeTipContent: Array<string> = new Array();
  private activeTip: TipItem;
  private currentScrollPosition = 0;
  constructor() {}

  ngOnInit(): void {
    const contentWrapperElement = document.getElementById('content-wrapper');
    contentWrapperElement
      .querySelectorAll('app-card')
      .forEach((element: Element, key: number) => {
        const cardId = 'card-tips' + key;
        element.setAttribute('id', cardId);
        const header =
          element.getAttribute('header') ||
          element.getAttribute('ng-reflect-header');
        const detail =
          element.getAttribute('detail') ||
          element.getAttribute('ng-reflect-detail');
        const tooltipItem: TipItem = {
          id: key,
          header: header,
          content: detail,
          active: key === 0,
          dataTarget: cardId
        };
        this.tipItemList.push(tooltipItem);

        if (key === 0) {
          this.activeTipContent.push(detail);
          this.activeTip = tooltipItem;
        }
      });
  }

  ngOnDestroy(): void {
    this.tipItemList.splice(0);
  }

  public doScrollToItem(tipItem): void {
    this.tipItemList.forEach(tooltipItem => {
      tooltipItem.active = false;
    });
    tipItem.active = true;

    const boundingClientRect = document
      .getElementById(tipItem.dataTarget)
      .getBoundingClientRect();
    const top = boundingClientRect.top + window.scrollY - 100;
    window.scrollTo({ top, behavior: 'smooth' });
    this.activeTipContent[0] = tipItem.content;
    this.activeTip = tipItem;
  }

  @HostListener('window:scroll')
  onScroll(): void {
    const toBottom = window.scrollY > this.currentScrollPosition;
    const activeElement = document.getElementById(this.activeTip.dataTarget);
    const boundingClientRect = activeElement.getBoundingClientRect();
    if (toBottom) {
      const top = boundingClientRect.top + window.scrollY - 100;
      const scrollHeight = boundingClientRect.height;
      if (window.scrollY > scrollHeight + top) {
        this.activeTip.active = false;
        const lastId = this.activeTip.id;
        const activeTip = this.tipItemList[lastId + 1];
        this.activeTip = activeTip;
        this.activeTip.active = true;
        this.activeTipContent[0] = this.activeTip.content;
      }
    } else {
      const screenInnerHeight = window.innerHeight;
      const top = boundingClientRect.top + window.scrollY;
      const isInvisible = screenInnerHeight + window.scrollY < top;
      if (isInvisible) {
        this.activeTip.active = false;
        const lastId = this.activeTip.id;
        const activeTip = this.tipItemList[lastId - 1];
        this.activeTip = activeTip;
        this.activeTip.active = true;
        this.activeTipContent[0] = this.activeTip.content;
      }
    }
    this.currentScrollPosition = window.scrollY;
  }
}
