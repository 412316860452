import { Component, Input, ViewChild } from '@angular/core';
import { BaseComponentComponent } from '../../../../../core/base/angular/base-component.component';
import { AppTableComponent } from '../../../../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../../../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../../../../core/components/app-table/model/table-response-model';
import { PaymentPaidStatusView } from '../../../../../core/view/entity/bean/payment-paid-status=view';
import { PaymentPendingStatusView } from '../../../../../core/view/entity/bean/payment-pending-status-view';

@Component({
  selector: 'app-popup-widget-payment-monitoring',
  templateUrl: './app-popup-widget-payment-monitoring.component.html'
})
export class AppPopupWidgetPaymentMonitoringComponent extends BaseComponentComponent {
  @ViewChild('pending') paymentPendingTable: AppTableComponent;
  @ViewChild('paid') paymentPaidTable: AppTableComponent;
  @Input() public statusCode: string;
  public pendingTableResponse: TableResponseModel<PaymentPendingStatusView>;
  public paidTableResponse: TableResponseModel<PaymentPaidStatusView>;

  constructor() {
    super('dashboard-widget-payment-monitoring');
  }

  onInit(): void {
    this.buildPendingTableResponse();
    this.buildPaidTableResponse();
  }

  public buildPendingTableResponse(): void {
    this.pendingTableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'procurementName', header: 'table.column.procurementName' },
      {
        field: 'agreementNumber',
        header: 'table.column.agreementNumber',
        customClass: 'text-center'
      },
      {
        field: 'totalAmount',
        header: 'table.column.amount',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'pendingPayment',
        header: 'table.column.pendingPayment',
        format: FieldFormatEnum.Currency
      }
    ]);
  }

  public buildPaidTableResponse(): void {
    this.paidTableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'procurementName', header: 'table.column.procurementName' },
      {
        field: 'agreementNumber',
        header: 'table.column.agreementNumber',
        customClass: 'text-center'
      },
      {
        field: 'totalAmount',
        header: 'table.column.amount',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'paid',
        header: 'table.column.paid',
        format: FieldFormatEnum.Currency
      }
    ]);
  }
}
