import {
  Component,
  ElementRef,
  Input,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
@Component({
  selector: 'app-month-picker',
  templateUrl: './app-month-picker.component.html',
  styleUrls: ['./app-month-picker.component.scss'],
  providers: makeProvider(AppMonthPickerComponent),
  encapsulation: ViewEncapsulation.None
})
export class AppMonthPickerComponent extends BaseValueAccessorComponent<number> {
  @Input() minMonth: number | string;
  @Input() maxMonth: number | string;
  @Input() monthStart: number;
  monthList: Array<string> = [];
  minMonthValue: number;
  maxMonthValue: number;
  monthStartValue: number;
  monthMapper: Map<string, Array<string>> = new Map();
  readonly CUSTOM_SELECT_HEIGHT: object = {
    SM: 'custom-select-sm',
    MD: 'custom-select-md',
    LG: 'custom-select-lg'
  };
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super('app-month-picker', controlContainer, elementRef);
  }

  public onInitBaseValueAccessor(): void {
    this.setMonthMapper();
    this.setMonthList();
    if (!this.ISVIEW) {
      this.setMonthStartValue();
      this.setMinMonthValue();
      this.setMaxMonthValue();
      this.setCustomSelectHeight();
    }
  }

  setMonthMapper(): void {
    const IDMonthList = [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember'
    ];
    const ENMonthList = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    this.monthMapper.set('id', IDMonthList);
    this.monthMapper.set('en', ENMonthList);
  }

  setMonthList(): void {
    this.monthList = this.monthMapper.get(this.global.currentLang);
  }

  setMonthStartValue(): void {
    this.monthStartValue = this.monthStart ? this.monthStart : 1;
  }

  setMinMonthValue(): void {
    if (this.minMonth) {
      if (typeof this.minMonth === 'number') {
        this.minMonthValue =
          (this.minMonth && this.minMonth < this.monthStartValue
            ? this.minMonth + 12
            : this.minMonth) || this.monthStartValue - 1;
      }

      if (typeof this.minMonth === 'string') {
        const formControlMinMonthValue = this.formGroup.get(
          this.minMonth
        ).value;
        this.minMonthValue =
          (formControlMinMonthValue &&
          formControlMinMonthValue < this.monthStartValue
            ? formControlMinMonthValue + 12
            : formControlMinMonthValue) || this.monthStartValue - 1;
        this.formGroup.get(this.minMonth).valueChanges.subscribe(value => {
          this.minMonthValue =
            (value && +value < this.monthStartValue ? +value + 12 : +value) ||
            this.monthStartValue - 1;
        });
      }
    }
  }

  setMaxMonthValue(): void {
    if (this.maxMonth) {
      if (typeof this.maxMonth === 'number') {
        this.maxMonthValue =
          this.maxMonth < this.monthStartValue
            ? this.maxMonth + 12
            : this.maxMonth;
      }

      if (typeof this.maxMonth === 'string') {
        const formControlMaxMonthValue =
          this.formGroup.get(this.maxMonth).value || this.monthStart + 12;
        this.maxMonthValue =
          formControlMaxMonthValue < this.monthStartValue
            ? formControlMaxMonthValue + 12
            : formControlMaxMonthValue;
        this.formGroup.get(this.maxMonth).valueChanges.subscribe(value => {
          this.maxMonthValue =
            +value < this.monthStartValue ? +value + 12 : +value;
        });
      }
    } else {
      this.maxMonthValue = this.monthStartValue + 12;
    }
  }

  setCustomSelectHeight(): void {
    this.elementHeightClassName =
      this.CUSTOM_SELECT_HEIGHT[this.height.toString()];
  }
}
