import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { AddressBook } from 'src/app/core/bean/address-book';
import { ContractShippingDetail } from 'src/app/core/bean/contract-shipping-detail';
import { TableRow } from 'src/app/core/components/table/domain/table-row';
import { CountPlugin } from 'src/app/core/components/table/interface/available-table-plugin';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './app-popup-contract-request-split-item.component.html'
})
export class AppPopupContractRequestSplitItemComponent extends BaseComponentComponent {
  @Input() public address: AddressBook;
  @Input() public contractShippingDetailList: ContractShippingDetail[];
  @Input() public allCShipItemList: ContractShippingDetail[];
  @Input() public isView = false;

  public CURRENCY_DIGITS_INFO: string;
  public tableResponse: TableResponseModel<ContractShippingDetail>;
  @Output() onChange: EventEmitter<ContractShippingDetail[]> =
    new EventEmitter();
  constructor(
    public currency: CurrencyPipe,
    public translateService: TranslateService
  ) {
    super('contract-request');
  }
  public onInit(): void {
    this.doBuildTableResponse();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'contractDetail.prItemReleased.prItem.item.name',
        header: 'popup.table.column.itemName'
      },
      {
        field: 'contractDetail.prItemReleased.prItem.item.code',
        header: 'popup.table.column.itemCode'
      },
      {
        field: 'contractDetail.prItemReleased.prItem.item.uom.name',
        header: 'popup.table.column.uom'
      },
      {
        field: 'quantity',
        header: 'popup.table.column.quantity',
        className: 'text-right',
        plugins: {
          name: 'count',
          min: 0,
          isView: this.isView,
          onChange: this.onChangeCount.bind(this),
          onInput: this.onChangeCount.bind(this)
        }
      },
      {
        field: 'remainingQuantity',
        header: 'popup.table.column.maxQuantity',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      }
    ]);
    this.contractShippingDetailList.sort(
      (contractShipping, contractShipping2) =>
        contractShipping2.remainingQuantity - contractShipping.remainingQuantity
    );
    this.tableResponse.setRecordList(this.contractShippingDetailList);
  }

  public onAfterRowCreated(tableRow: TableRow): void {
    const remainingQuantity = this.getRemainingQuantityItem(tableRow);
    if (tableRow.formGroup.get('quantity').value) {
      tableRow.formGroup
        .get('remainingQuantity')
        .patchValue(
          (
            remainingQuantity + +tableRow.formGroup.get('quantity').value
          ).toString()
        );
    } else {
      tableRow.formGroup
        .get('quantity')
        .patchValue(remainingQuantity.toString());
      tableRow.formGroup
        .get('remainingQuantity')
        .patchValue(remainingQuantity.toString());
    }

    const index = this.tableResponse
      .getRecordList()
      .findIndex(
        r =>
          r.contractDetail.prItemReleased.id ===
          tableRow.record.contractDetail.prItemReleased.id
      );
    const records = this.tableResponse.getRecordList();
    records[index].quantity = +tableRow.formGroup.get('quantity').value || 0;
    records[index].remainingQuantity =
      +tableRow.formGroup.get('remainingQuantity').value || 0;
    this.tableResponse.setRecordList(records);

    if (!this.isView) {
      if (tableRow && tableRow.columnList[3]) {
        const plugins = tableRow.columnList[3].column.plugins as CountPlugin;
        plugins.max = +tableRow.formGroup.get('remainingQuantity').value;
        tableRow.columnList[3].column.plugins = plugins;
      }
      tableRow.formGroup
        .get('quantity')
        .setValidators(
          Validators.max(+tableRow.formGroup.get('remainingQuantity').value)
        );
      tableRow.formGroup.get('quantity').updateValueAndValidity();
      tableRow.formGroup.get('quantity').markAsTouched();
    }
  }

  public onChangeCount(count: number): void {
    const tableRow = this.tableResponse.currentRowChange.row;
    if (tableRow && tableRow.columnList[3]) {
      const plugins = tableRow.columnList[3].column.plugins as CountPlugin;
      plugins.max = +tableRow.formGroup.get('remainingQuantity').value;
      tableRow.columnList[3].column.plugins = plugins;
    }
    if (
      tableRow.formGroup.get('remainingQuantity') &&
      +count > +tableRow.formGroup.get('remainingQuantity').value
    ) {
      tableRow.formGroup
        .get('quantity')
        .setValidators(
          Validators.compose([
            Validators.max(+tableRow.formGroup.get('remainingQuantity').value)
          ])
        );
    }
    tableRow.formGroup.get('quantity').updateValueAndValidity();
    tableRow.formGroup.get('quantity').markAsTouched();
    this.doCheckValue();
  }

  public doCheckValue(): void {
    this.tableResponse
      .getUpdatedRecordList()
      .forEach(
        r =>
          (r.remainingQuantity =
            r.contractDetail.prItemReleased.quantity - r.quantity)
      );
    const records = Array.from(this.tableResponse.getRecordList()).map(r => {
      const indexOfR = this.tableResponse
        .getUpdatedRecordList()
        .findIndex(
          updateRecord =>
            updateRecord.contractDetail.prItemReleased.id ===
            r.contractDetail.prItemReleased.id
        );
      return indexOfR === -1
        ? r
        : this.tableResponse.getUpdatedRecordList()[indexOfR];
    });
    this.tableResponse.setRecordList(records);
  }

  public setDecimal(field: number): string {
    return this.currency.transform(field, '', '', this.CURRENCY_DIGITS_INFO);
  }

  public doSave(): void {
    let totalQuantity = 0;
    this.tableResponse.getRecordList().forEach(contractShipDetail => {
      if (contractShipDetail.quantity !== null) {
        totalQuantity += contractShipDetail.quantity;
      }
    });
    if (totalQuantity > 0) {
      if (this.tableResponse.formArray.valid) {
        this.tableResponse.getRecordList().forEach(r => {
          r.quantity = +r.quantity;
        });
        this.onChange.emit(this.tableResponse.getRecordList());
      }
    } else {
      this.global.alertService.showError(
        this.translateService.instant('order.alert.msg.fillTheQuantity'),
        '.popup-order-split-item'
      );
    }
  }

  public getRemainingQuantityItem(tableRow: TableRow): number {
    let maxQuantity = 0;
    if (
      tableRow.record.contractDetail.prItemReleased.prItem.item.itemType.code ===
      this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
    ) {
      maxQuantity = 100;
    } else {
      maxQuantity = tableRow.record.contractDetail.prItemReleased.quantity;
    }
    let totalUsedQuantity = 0;
    if (this.allCShipItemList && this.allCShipItemList.length > 0) {
      const contractShipDetailList = this.allCShipItemList.filter(
        osi =>
          osi.contractDetail.prItemReleased.id ===
          tableRow.record.contractDetail.prItemReleased.id
      );
      contractShipDetailList.forEach(oShipItem => {
        totalUsedQuantity += oShipItem.quantity;
      });
    }
    const remainingQuantity = maxQuantity - totalUsedQuantity;
    return remainingQuantity;
  }
}
