import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../../directives/directives.module';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppCheckBoxModule } from '../../app-check-box/app-check-box.module';
import { AppComboBoxModule } from '../../app-combo-box/app-combo-box.module';
import { AppPopupModule } from '../../app-popup/app-popup.module';
import { AppTextAreaModule } from '../../app-text-area/app-text-area.module';
import { AppTextFieldModule } from '../../app-text-field/app-text-field.module';
import { AppTreePopupComponent } from './app-tree-popup.component';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppTextFieldModule, DirectivesModule, AppTextAreaModule, AppButtonModule, AppComboBoxModule, AppCheckBoxModule],
  declarations: [AppTreePopupComponent],
  entryComponents: [AppTreePopupComponent],
  exports: [AppTreePopupComponent]
})
export class AppTreePopupModule {}
