import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { FileObject } from 'src/app/core/components/upload';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Region } from '../../core/bean/region';
import { Vendor } from '../../core/bean/vendor';
import { VendorCompanyOrganizationHistory } from '../../core/bean/vendor-company-organization-history';
import { VendorEntity } from '../../core/bean/vendor-entity';
import { VendorType } from '../../core/bean/vendor-type';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { TabModel } from '../../core/model/tab/tab-model';
import { CompanyProfileServie } from '../company-profile/company-profile.service';
import { CompanyProfileDTO } from '../company-profile/dto/company-profile.dto';
import { TabProfileCompanyOrganizationDTO } from './dto/tab-profile-company-organization.dto';
import { AppPopupCompanyOrganizationComponent } from './popup/app-popup-company-organization.component';

@Component({
  templateUrl: './tab-profile-company-organization.component.html'
})
export class TabProfileCompanyOrganizationComponent extends BaseModuleComponent {
  @ViewChild('selectorCommissioner')
  public tableCommissioner: AppTableComponent;
  @ViewChild('selectorDirector') public tableDirector: AppTableComponent;
  @ViewChild('selectorShareHolder') public tableShareHolder: AppTableComponent;

  public userId: number;
  public companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public tabProfileCompanyOrganizationDTO: TabProfileCompanyOrganizationDTO =
    new TabProfileCompanyOrganizationDTO();
  public vendor: Vendor = new Vendor();
  public now = new Date().getTime();
  public sessionId = null;
  public vendorTypeList: VendorType[] = [];
  public regionList: Region[] = [];
  public vendorEntityList: VendorEntity[] = [];
  public tabCompanyOrganization: TabModel;
  public isView: boolean;
  public currentTotalShareValue = 0.0;
  public isDisableAddButton = false;
  public idNumberDirList: any[] = [];
  public idNumberComList: any[] = [];
  public idNumberShaList: any[] = [];
  public fromOutside: string;
  public isVendorList: boolean;

  public tableResponseCommissioner: TableResponseModel<VendorCompanyOrganizationHistory>;
  public tableResponseDirector: TableResponseModel<VendorCompanyOrganizationHistory>;
  public tableResponseShareHolders: TableResponseModel<VendorCompanyOrganizationHistory>;

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public companyProfileService: CompanyProfileServie
  ) {
    super('tab-profile-company-organization', translate);
  }

  onInit(): void {
    this.getDataFromRouterParam();
    if (
      this.tabCompanyOrganization.routerParams.get(
        'tabProfileCompanyOrganizationDTO'
      )
    ) {
      this.tabProfileCompanyOrganizationDTO =
        this.tabCompanyOrganization.routerParams.get(
          'tabProfileCompanyOrganizationDTO'
        );
      this.getAndSetVendorToFormGroup();
      this.buildTableResponse();
      this.checkTable();
    } else {
      this.getDataFromServer();
    }
  }

  public getDataFromServer(): void {
    // tslint:disable-next-line: max-line-length
    this.httpClientService
      .get<TabProfileCompanyOrganizationDTO>(
        this.global.userSession.activeUserRole.role.type !==
          this.global.appConstant.core.ROLE_TYPE_VENDOR
          ? '/tab-profile-company-organization/index?id=' +
              this.companyProfileDTO.vendorHistory.id +
              '&todo=' +
              this.todo
          : '/tab-profile-company-organization/index'
      )
      .subscribe(tabCompanyOrg => {
        this.tabProfileCompanyOrganizationDTO = tabCompanyOrg;
        this.tabCompanyOrganization.routerParams.set(
          'tabProfileCompanyOrganizationDTO',
          tabCompanyOrg
        );
        this.buildFormGroup();
        this.getAndSetVendorToFormGroup();
        this.buildTableResponse();
        this.checkTable();
      });
  }

  public getDataFromRouterParam(): void {
    this.tabCompanyOrganization = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_COMPANY_ORGANIZATION);
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
    this.isView = this.companyProfileService.getTabResponse().isView;
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.todo = this.global.routerParams.get('todo');
    this.companyProfileDTO = this.global.routerParams.get('companyProfileDTO');
  }

  public buildFormGroup(): void {
    if (!this.tabCompanyOrganization.isFormGroupReady) {
      this.tabCompanyOrganization.formGroup = this.formBuilder.group({
        isTableEmpty: [null],
        isTableDirectorEmpty: [null],
        changeTab: [null]
      });
    }
  }

  public getAndSetVendorToFormGroup(): void {
    this.tabProfileCompanyOrganizationDTO.companyOrganizationDirectorHistoryList.forEach(
      directorHistory => {
        this.idNumberDirList.push(directorHistory.idNumber);
      }
    );
    this.tabProfileCompanyOrganizationDTO.companyOrganizationCommissionerHistoryList.forEach(
      commisionerHistory => {
        this.idNumberComList.push(commisionerHistory.idNumber);
      }
    );
    this.tabProfileCompanyOrganizationDTO.companyOrganizationShareHolderHistoryList.forEach(
      shareHolderHistory => {
        this.idNumberShaList.push(shareHolderHistory.idNumber);
      }
    );
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponseCommissioner =
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR &&
      !this.isVendorList &&
      this.companyProfileDTO.vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE
        ? new TableResponseModel(this.moduleCode, [
            // form on boarding
            { field: 'name', header: 'table.column.name' },
            {
              field: 'idNumber',
              header: 'table.column.idNumber'
            },
            {
              field: 'file.fileName',
              header: 'table.column.file',
              format: FieldFormatEnum.DownloadableFile,
              urlFile: 'file.uploadedFileName',
              customClass: 'text-left'
            },
            {
              field: 'birthDate',
              header: 'table.column.DOB',
              format: FieldFormatEnum.ShortDate
            },
            {
              field: 'vendorPosition.name',
              header: 'table.column.position'
            },
            {
              field: 'crudInfo',
              header: 'table.column.status',
              format: FieldFormatEnum.Html,
              customClass: 'text-center'
            }
          ])
        : !this.isView
        ? new TableResponseModel(this.moduleCode, [
            // kalau dia bukan isView
            { field: 'name', header: 'table.column.name' },
            {
              field: 'idNumber',
              header: 'table.column.idNumber'
            },
            {
              field: 'file.fileName',
              header: 'table.column.file',
              format: FieldFormatEnum.DownloadableFile,
              urlFile: 'file.uploadedFileName',
              customClass: 'text-left'
            },
            {
              field: 'vendorPosition.name',
              header: 'table.column.position'
            }
          ])
        : new TableResponseModel(this.moduleCode, [
            // kalau isView
            { field: 'name', header: 'table.column.name' },
            {
              field: 'idNumber',
              header: 'table.column.idNumber'
            },
            {
              field: 'file.fileName',
              header: 'table.column.file',
              format: FieldFormatEnum.DownloadableFile,
              urlFile: 'file.uploadedFileName',
              customClass: 'text-left'
            },
            {
              field: 'birthDate',
              header: 'table.column.DOB',
              format: FieldFormatEnum.ShortDate
            },
            {
              field: 'vendorPosition.name',
              header: 'table.column.position'
            }
          ]);
    this.tableResponseCommissioner.setRecords(
      this.tabProfileCompanyOrganizationDTO
        .companyOrganizationCommissionerHistoryList
    );
    this.tableResponseCommissioner.setTotalRecords(
      this.tabProfileCompanyOrganizationDTO
        .companyOrganizationCommissionerHistoryList.length
    );

    this.tableResponseDirector =
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR &&
      !this.isVendorList &&
      this.companyProfileDTO.vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE
        ? new TableResponseModel(this.moduleCode, [
            // form on boarding
            { field: 'name', header: 'table.column.name' },
            {
              field: 'idNumber',
              header: 'table.column.idNumber'
            },
            {
              field: 'file.fileName',
              header: 'table.column.file',
              format: FieldFormatEnum.DownloadableFile,
              urlFile: 'file.uploadedFileName',
              customClass: 'text-left'
            },
            {
              field: 'birthDate',
              header: 'table.column.DOB',
              format: FieldFormatEnum.ShortDate
            },
            {
              field: 'vendorPosition.name',
              header: 'table.column.position'
            },
            {
              field: 'crudInfo',
              header: 'table.column.status',
              format: FieldFormatEnum.Html,
              customClass: 'text-center'
            }
          ])
        : !this.isView
        ? new TableResponseModel(this.moduleCode, [
            // kalau dia bukan isView
            { field: 'name', header: 'table.column.name' },
            {
              field: 'idNumber',
              header: 'table.column.idNumber'
            },
            {
              field: 'file.fileName',
              header: 'table.column.file',
              format: FieldFormatEnum.DownloadableFile,
              urlFile: 'file.uploadedFileName',
              customClass: 'text-left'
            },
            {
              field: 'vendorPosition.name',
              header: 'table.column.position'
            }
          ])
        : new TableResponseModel(this.moduleCode, [
            // kalau isView
            { field: 'name', header: 'table.column.name' },
            {
              field: 'idNumber',
              header: 'table.column.idNumber'
            },
            {
              field: 'file.fileName',
              header: 'table.column.file',
              format: FieldFormatEnum.DownloadableFile,
              urlFile: 'file.uploadedFileName',
              customClass: 'text-left'
            },
            {
              field: 'birthDate',
              header: 'table.column.DOB',
              format: FieldFormatEnum.ShortDate
            },
            {
              field: 'vendorPosition.name',
              header: 'table.column.position'
            }
          ]);
    this.tableResponseDirector.setRecords(
      this.tabProfileCompanyOrganizationDTO
        .companyOrganizationDirectorHistoryList
    );
    this.tableResponseDirector.setTotalRecords(
      this.tabProfileCompanyOrganizationDTO
        .companyOrganizationDirectorHistoryList.length
    );

    this.tableResponseShareHolders =
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR &&
      !this.isVendorList &&
      this.companyProfileDTO.vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE
        ? new TableResponseModel(this.moduleCode, [
            { field: 'name', header: 'table.column.name' },
            {
              field: 'idNumber',
              header: 'table.column.idNumber'
            },
            {
              field: 'shareValueWithPercent',
              header: 'table.column.shareValue',
              customClass: 'text-right'
            },
            {
              field: 'crudInfo',
              header: 'table.column.status',
              format: FieldFormatEnum.Html,
              customClass: 'text-center'
            }
          ])
        : new TableResponseModel(this.moduleCode, [
            { field: 'name', header: 'table.column.name' },
            {
              field: 'idNumber',
              header: 'table.column.idNumber'
            },
            {
              field: 'shareValueWithPercent',
              header: 'table.column.shareValue',
              customClass: 'text-right'
            }
          ]);
    this.tableResponseShareHolders.setRecords(
      this.tabProfileCompanyOrganizationDTO
        .companyOrganizationShareHolderHistoryList
    );
    this.tableResponseShareHolders.setTotalRecords(
      this.tabProfileCompanyOrganizationDTO
        .companyOrganizationShareHolderHistoryList.length
    );
  }

  public checkTable(): void {
    if (
      this.tabProfileCompanyOrganizationDTO
        .companyOrganizationDirectorHistoryList.length !== 0
    ) {
      this.tabCompanyOrganization.formGroup.patchValue({ isTableEmpty: false });
    } else {
      this.tabCompanyOrganization.formGroup.patchValue({ isTableEmpty: true });
    }
    if (
      this.tabProfileCompanyOrganizationDTO
        .companyOrganizationDirectorHistoryList.length !== 0
    ) {
      this.tabCompanyOrganization.formGroup.patchValue({
        isTableDirectorEmpty: false
      });
    } else {
      this.tabCompanyOrganization.formGroup.patchValue({
        isTableDirectorEmpty: true
      });
    }
  }

  public doAddCommisioner(): void {
    const type = GroupOfOrganization['COMMISSIONER'];
    const todo = 'addCommissioner';
    const idNumberList = this.idNumberComList;
    const vendorId =
      this.companyProfileDTO.vendorHistory !== null
        ? this.companyProfileDTO.vendorHistory.id
        : null;
    this.appPopupService
      .open(
        AppPopupCompanyOrganizationComponent,
        { type, todo, vendorId, idNumberList },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(commissionerHistory => {
        const commissioner: VendorCompanyOrganizationHistory =
          commissionerHistory;
        const file = commissionerHistory.imgFile;
        if (file !== null) {
          const fileObjectList: FileObject[] = [];
          fileObjectList.push(commissionerHistory.imgFile[0]);
          commissioner.file = file[0].isDeleted
            ? null
            : commissionerHistory.imgFile[0].file;
          commissioner.fileObjectList = fileObjectList;
        }
        this.idNumberComList.push(commissioner.idNumber);
        this.tabProfileCompanyOrganizationDTO.companyOrganizationCommissionerHistoryList.push(
          commissioner
        );
        this.tableResponseCommissioner.setRecords(
          this.tabProfileCompanyOrganizationDTO
            .companyOrganizationCommissionerHistoryList
        );
        this.tableResponseCommissioner.setTotalRecords(
          this.tabProfileCompanyOrganizationDTO
            .companyOrganizationCommissionerHistoryList.length
        );
        this.tableResponseCommissioner.reloadClient();
        this.checkTable();
      });
  }

  public doEditCommissioner(
    vendorCompanyOrganizationHistoryEdit: VendorCompanyOrganizationHistory
  ): void {
    const type = GroupOfOrganization['COMMISSIONER'];
    const todo = 'editCommissioner';
    const idNumberList = [];
    this.tabProfileCompanyOrganizationDTO.companyOrganizationCommissionerHistoryList.forEach(
      commissioner => {
        if (commissioner !== vendorCompanyOrganizationHistoryEdit) {
          idNumberList.push(commissioner.idNumber);
        }
      }
    );
    const vendorId =
      this.companyProfileDTO.vendorHistory !== null
        ? this.companyProfileDTO.vendorHistory.id
        : null;
    this.appPopupService
      .open(
        AppPopupCompanyOrganizationComponent,
        {
          type,
          todo,
          vendorCompanyOrganizationHistoryEdit,
          vendorId,
          idNumberList
        },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(commissionerHistory => {
        this.tabProfileCompanyOrganizationDTO.companyOrganizationCommissionerHistoryList.forEach(
          (companyOrganizationCommissionerHistory, index) => {
            if (
              companyOrganizationCommissionerHistory ===
              vendorCompanyOrganizationHistoryEdit
            ) {
              const commissioner: VendorCompanyOrganizationHistory =
                commissionerHistory;
              const file = commissionerHistory.imgFile;
              if (file !== null) {
                const fileObjectList: FileObject[] = [];
                if (file) {
                  fileObjectList.push(commissionerHistory.imgFile[0]);
                }
                commissioner.file =
                  file.length !== undefined
                    ? file[0].isDeleted
                      ? null
                      : commissionerHistory.imgFile[0].file
                    : commissionerHistory.imgFile;
                commissioner.fileObjectList =
                  file.length !== undefined
                    ? fileObjectList
                    : commissionerHistory.fileUploader.fileObjectList.length !==
                      0
                    ? commissionerHistory.fileUploader.fileObjectList
                    : [];
              }
              this.idNumberComList[index] = commissioner.idNumber;
              this.tabProfileCompanyOrganizationDTO.companyOrganizationCommissionerHistoryList[
                index
              ] = commissioner;
            }
            this.tableResponseCommissioner.setRecords(
              this.tabProfileCompanyOrganizationDTO
                .companyOrganizationCommissionerHistoryList
            );
            this.tableResponseCommissioner.setTotalRecords(
              this.tabProfileCompanyOrganizationDTO
                .companyOrganizationCommissionerHistoryList.length
            );
            this.tableResponseCommissioner.reloadClient();
          }
        );
      });
  }

  public doDeleteCommissioner(
    vendorCompanyOrganizationHistoryDelete: VendorCompanyOrganizationHistory
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tabProfileCompanyOrganizationDTO.companyOrganizationCommissionerHistoryList.forEach(
            (companyOrganizationCommissionerHistory, index) => {
              if (
                companyOrganizationCommissionerHistory ===
                vendorCompanyOrganizationHistoryDelete
              ) {
                this.tabProfileCompanyOrganizationDTO.companyOrganizationCommissionerHistoryList.splice(
                  index,
                  1
                );
                this.idNumberComList.splice(index, 1);
              }
              this.tableResponseCommissioner.page.records =
                this.tabProfileCompanyOrganizationDTO.companyOrganizationCommissionerHistoryList;
              this.tableResponseCommissioner.page.totalRecords =
                this.tabProfileCompanyOrganizationDTO.companyOrganizationCommissionerHistoryList.length;
              this.tableResponseCommissioner.reloadClient();
              this.checkTable();
            }
          );
        }
      });
  }

  public doAddDirector(): void {
    const type = GroupOfOrganization['DIRECTOR'];
    const todo = 'addDirector';
    const idNumberList = this.idNumberDirList;
    const vendorId = 0;
    this.appPopupService
      .open(
        AppPopupCompanyOrganizationComponent,
        { type, todo, idNumberList, vendorId },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(directorHistory => {
        const director: VendorCompanyOrganizationHistory = directorHistory;
        const file = directorHistory.imgFile;
        if (file !== null) {
          const fileObjectList: FileObject[] = [];
          fileObjectList.push(directorHistory.imgFile[0]);
          director.file = file[0].isDeleted
            ? null
            : directorHistory.imgFile[0].file;
          director.fileObjectList = fileObjectList;
        }
        this.idNumberDirList.push(director.idNumber);
        this.tabProfileCompanyOrganizationDTO.companyOrganizationDirectorHistoryList.push(
          director
        );
        this.tableResponseDirector.setRecords(
          this.tabProfileCompanyOrganizationDTO
            .companyOrganizationDirectorHistoryList
        );
        this.tableResponseDirector.setTotalRecords(
          this.tabProfileCompanyOrganizationDTO
            .companyOrganizationDirectorHistoryList.length
        );
        this.tableResponseDirector.reloadClient();
        this.checkTable();
      });
  }
  public doEditDirector(
    vendorCompanyOrganizationHistoryEdit: VendorCompanyOrganizationHistory
  ): void {
    const type = GroupOfOrganization['DIRECTOR'];
    const todo = 'editDirector';
    const idNumberList = [];
    this.tabProfileCompanyOrganizationDTO.companyOrganizationDirectorHistoryList.forEach(
      director => {
        if (director !== vendorCompanyOrganizationHistoryEdit) {
          idNumberList.push(director.idNumber);
        }
      }
    );
    const vendorId = this.companyProfileDTO.vendor.id;
    const vendorCompanyOrganizationId = vendorCompanyOrganizationHistoryEdit.id;
    this.appPopupService
      .open(
        AppPopupCompanyOrganizationComponent,
        {
          type,
          vendorCompanyOrganizationHistoryEdit,
          vendorCompanyOrganizationId,
          todo,
          idNumberList,
          vendorId
        },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(directorHistory => {
        this.tabProfileCompanyOrganizationDTO.companyOrganizationDirectorHistoryList.forEach(
          (companyOrganizationDirectorHistory, index) => {
            if (
              companyOrganizationDirectorHistory ===
              vendorCompanyOrganizationHistoryEdit
            ) {
              const director: VendorCompanyOrganizationHistory =
                directorHistory;
              const file = directorHistory.imgFile;
              if (file !== null) {
                const fileObjectList: FileObject[] = [];
                fileObjectList.push(directorHistory.imgFile[0]);
                director.file =
                  file.length !== undefined
                    ? file[0].isDeleted
                      ? null
                      : directorHistory.imgFile[0].file
                    : directorHistory.imgFile;
                director.fileObjectList =
                  file.length !== undefined
                    ? fileObjectList
                    : directorHistory.fileUploader.fileObjectList.length !== 0
                    ? directorHistory.fileUploader.fileObjectList
                    : [];
              }
              this.tabProfileCompanyOrganizationDTO.companyOrganizationDirectorHistoryList[
                index
              ] = director;
              this.idNumberDirList[index] = director.idNumber;
            }

            this.tableResponseDirector.setRecords(
              this.tabProfileCompanyOrganizationDTO
                .companyOrganizationDirectorHistoryList
            );
            this.tableResponseDirector.setTotalRecords(
              this.tabProfileCompanyOrganizationDTO
                .companyOrganizationDirectorHistoryList.length
            );
            this.tableResponseDirector.reloadClient();
          }
        );
      });
  }
  public doDeleteDirector(
    vendorCompanyOrganizationHistoryDelete: VendorCompanyOrganizationHistory
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tabProfileCompanyOrganizationDTO.companyOrganizationDirectorHistoryList.forEach(
            (companyOrganizationDirectorHistory, index) => {
              if (
                companyOrganizationDirectorHistory ===
                vendorCompanyOrganizationHistoryDelete
              ) {
                this.tabProfileCompanyOrganizationDTO.companyOrganizationDirectorHistoryList.splice(
                  index,
                  1
                );
                this.idNumberDirList.splice(index, 1);
              }
              this.tableResponseDirector.page.records =
                this.tabProfileCompanyOrganizationDTO.companyOrganizationDirectorHistoryList;
              this.tableResponseDirector.page.totalRecords =
                this.tabProfileCompanyOrganizationDTO.companyOrganizationDirectorHistoryList.length;
              this.tableResponseDirector.reloadClient();
              this.checkTable();
            }
          );
        }
      });
  }

  public doAddShareHolders(): void {
    const type = GroupOfOrganization['SHARE_HOLDER'];
    const todo = 'addShareholder';
    const idNumberList = this.idNumberShaList;
    const currentTotalShareValue = this.getCurrentTotalShareValue(
      this.tableResponseShareHolders.page.records
    );
    this.appPopupService
      .open(
        AppPopupCompanyOrganizationComponent,
        { todo, type, currentTotalShareValue, idNumberList },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(shareholderHistory => {
        shareholderHistory.shareValueWithPercent =
          shareholderHistory.shareValue.toString() + ' %';
        shareholderHistory.fileObjectList = [];
        this.tabProfileCompanyOrganizationDTO.companyOrganizationShareHolderHistoryList.push(
          shareholderHistory
        );
        this.idNumberShaList.push(shareholderHistory.idNumber);
        this.tableResponseShareHolders.setRecords(
          this.tabProfileCompanyOrganizationDTO
            .companyOrganizationShareHolderHistoryList
        );
        this.tableResponseShareHolders.setTotalRecords(
          this.tabProfileCompanyOrganizationDTO
            .companyOrganizationShareHolderHistoryList.length
        );
        this.tableResponseShareHolders.reloadClient();
        this.checkTable();
        this.disableOrEnableButtonAdd();
      });
  }
  public doEditShareHolders(
    vendorCompanyOrganizationHistoryEdit: VendorCompanyOrganizationHistory
  ): void {
    const type = GroupOfOrganization['SHARE_HOLDER'];
    const todo = 'editShareholder';
    const idNumberList = [];
    this.tabProfileCompanyOrganizationDTO.companyOrganizationShareHolderHistoryList.forEach(
      shareHolder => {
        if (shareHolder !== vendorCompanyOrganizationHistoryEdit) {
          idNumberList.push(shareHolder.idNumber);
        }
      }
    );
    const currentTotalShareValue = this.getCurrentTotalShareValue(
      this.tableResponseShareHolders.page.records
    );
    this.appPopupService
      .open(
        AppPopupCompanyOrganizationComponent,
        {
          todo,
          type,
          vendorCompanyOrganizationHistoryEdit,
          currentTotalShareValue,
          idNumberList
        },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(shareholderHistory => {
        this.tabProfileCompanyOrganizationDTO.companyOrganizationShareHolderHistoryList.forEach(
          (companyOrganizationShareHolderHistory, index) => {
            if (
              companyOrganizationShareHolderHistory ===
              vendorCompanyOrganizationHistoryEdit
            ) {
              shareholderHistory.shareValueWithPercent =
                shareholderHistory.shareValue.toString() + ' %';
              shareholderHistory.fileObjectList = [];
              this.idNumberShaList[index] = shareholderHistory.idNumber;
              this.tabProfileCompanyOrganizationDTO.companyOrganizationShareHolderHistoryList[
                index
              ] = shareholderHistory;
            }

            this.tableResponseShareHolders.setRecords(
              this.tabProfileCompanyOrganizationDTO
                .companyOrganizationShareHolderHistoryList
            );
            this.tableResponseShareHolders.setTotalRecords(
              this.tabProfileCompanyOrganizationDTO
                .companyOrganizationShareHolderHistoryList.length
            );
            this.tableResponseShareHolders.reloadClient();
          }
        );
        this.disableOrEnableButtonAdd();
      });
  }
  public doDeleteShareHolders(
    vendorCompanyOrganizationHistoryDelete: VendorCompanyOrganizationHistory
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tabProfileCompanyOrganizationDTO.companyOrganizationShareHolderHistoryList.forEach(
            (companyOrganizationShareHolderHistory, index) => {
              if (
                companyOrganizationShareHolderHistory ===
                vendorCompanyOrganizationHistoryDelete
              ) {
                this.tabProfileCompanyOrganizationDTO.companyOrganizationShareHolderHistoryList.splice(
                  index,
                  1
                );
                this.idNumberShaList.splice(index, 1);
              }
              this.tableResponseShareHolders.page.records =
                this.tabProfileCompanyOrganizationDTO.companyOrganizationShareHolderHistoryList;
              this.tableResponseShareHolders.page.totalRecords =
                this.tabProfileCompanyOrganizationDTO.companyOrganizationShareHolderHistoryList.length;
              this.tableResponseShareHolders.reloadClient();
              this.checkTable();
              this.currentTotalShareValue = this.getCurrentTotalShareValue(
                this.tableResponseShareHolders.page.records
              );
            }
          );
          this.disableOrEnableButtonAdd();
        }
      });
  }

  public doViewShareHolders(
    vendorCompanyOrganizationHistoryEdit: VendorCompanyOrganizationHistory
  ): void {
    const type = GroupOfOrganization['SHARE_HOLDER'];
    const todo = 'detailShareholder';
    const companyOrganizationShareHolderHistoryList =
      this.tabProfileCompanyOrganizationDTO
        .companyOrganizationShareHolderHistoryList;
    const companyOrganizationShareHolderList =
      this.tabProfileCompanyOrganizationDTO.companyOrganizationShareHolderList;
    this.appPopupService.open(
      AppPopupCompanyOrganizationComponent,
      {
        todo,
        type,
        vendorCompanyOrganizationHistoryEdit,
        companyOrganizationShareHolderHistoryList,
        companyOrganizationShareHolderList
      },
      { size: 'lg' }
    );
  }

  public getCurrentTotalShareValue(
    companyOrganizationShareHolderHistoryList: VendorCompanyOrganizationHistory[]
  ): number {
    this.currentTotalShareValue = 0.0;
    companyOrganizationShareHolderHistoryList.forEach(shareHolder => {
      const shareValue = parseFloat(shareHolder.shareValue) * 100;
      this.currentTotalShareValue += shareValue;
    });
    this.currentTotalShareValue /= 100;
    return this.currentTotalShareValue;
  }

  public disableOrEnableButtonAdd(): void {
    if (
      this.getCurrentTotalShareValue(this.tableResponseShareHolders.records) ===
      100
    ) {
      this.isDisableAddButton = true;
    } else {
      this.isDisableAddButton = false;
    }
  }
}

export enum GroupOfOrganization {
  COMMISSIONER = 1,
  DIRECTOR = 2,
  SHARE_HOLDER = 3
}
