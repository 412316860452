<app-popup
  header="submit-quotation.popup.submissionDetail"
  [isLoading]="formLoading"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <h4 class="separator"
      >{{ 'submit-quotation.vendorInformation' | translate }}
    </h4>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'submit-quotation.form.vendorName' | translate
      }}</label>
      <div class="col-sm-6 text-left">
        <span class="mt-3" (click)="doViewDetailVendor()">
          <a
            class="mr-2 text-primary"
            style="text-decoration-line: underline; font-weight: 500"
          >
            {{ vendorView.vendorName }}
            <span
              ><em
                class="pir pi-external-link ml-1"
                style="
                  top: auto;
                  left: auto;
                  font-size: 16px;
                  font-weight: 500;
                  color: var(--primary);
                "
                placement="top"
                ngbTooltip="{{
                  'submit-quotation.popup.tooltip.goToVendorDetails' | translate
                }}"
              ></em>
            </span>
          </a>
        </span>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'submit-quotation.form.submittedDate' | translate
      }}</label>
      <div class="col-sm-6 text-left">
        {{
          vendorView.submittedDate
            | date: global.config.parameterModel.datetimeFormatShort
        }}
      </div>
    </div>
    <h4 class="separator"
      >{{ 'submit-quotation.responseDocument' | translate }}
    </h4>
    <app-table-xx
      [model]="tableResponse"
      [isShowSearch]="false"
      [isShowPagination]="false"
      *ngIf="!formLoading"
    >
    </app-table-xx>
  </form>
</app-popup>
