import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalPrcsXModule } from '../../core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupBlacklistCriteriaComponent } from './app-popup-blacklist-criteria.component';
import { VendorBlacklistEditAddComponent } from './vendor-blacklist-edit-add.component';
import { VendorBlacklistComponent } from './vendor-blacklist.component';
export const routes = [
  {
    path: '',
    component: VendorBlacklistComponent,
    data: { breadcrumb: 'Vendor Maintanance' }
  },
  {
    path: 'add',
    component: VendorBlacklistEditAddComponent,
    data: { breadcrumb: 'vendor-blacklist.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: VendorBlacklistEditAddComponent,
    data: { breadcrumb: 'vendor-blacklist.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: VendorBlacklistEditAddComponent,
    data: { breadcrumb: 'vendor-blacklist.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    AppApprovalPrcsXModule
  ],
  declarations: [
    VendorBlacklistComponent,
    VendorBlacklistEditAddComponent,
    AppPopupBlacklistCriteriaComponent
  ],
  providers: [],
  entryComponents: [AppPopupBlacklistCriteriaComponent],
  exports: [AppPopupBlacklistCriteriaComponent]
})
export class VendorBlacklistModule {}
