import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CustomValidatorError } from '../../core/custom-validator-error';
export class ActualLengthValidator {
  public static actualLength(
    actualLength: number,
    message?: string
  ): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors => {
      const value = abstractControl.value;
      if (
        value &&
        ((Array.isArray(value) && value.length) ||
          value.toString().trim().length) !== actualLength
      ) {
        const defaultError = {
          actualLength: value.length,
          requiredLength: actualLength
        };
        return CustomValidatorError.create(
          'actualLength',
          message,
          defaultError
        );
      }
      return null;
    };
  }
}
