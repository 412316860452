import { Action } from '../interfaces/action.interface';

export const SET_LINE_CHART = `[ CHART ]: Set Line Chart`;
export const SET_BAR_CHART = `[ CHART ]: Set Bar Chart`;
export const SET_DOUGHNUT_CHART = `[ CHART ]: Set Doughnut Chart`;
export const SET_RADAR_CHART = `[ CHART ]: Set Radar Chart`;
export const SET_PIE_CHART = `[ CHART ]: Set Pie Chart`;
export const SET_POLAR_AREA_CHART = `[ CHART ]: Set Polar Area Chart`;
export const SET_BUBBLE_CHART = `[ CHART ]: Set Bubble Chart`;
export const SET_SCATTER_CHART = `[ CHART ]: Set Scatter Chart`;
export const SET_DYNAMIC_CHART = `[ CHART ]: Set Dynamic Chart`;
export const SET_FINANCIAL_CHART = `[ CHART ]: Set Financial Chart`;

export class SetLineChart implements Action {
  type = SET_LINE_CHART;
}

export class SetBarChart implements Action {
  type = SET_BAR_CHART;
}

export class SetDoughnutChart implements Action {
  type = SET_DOUGHNUT_CHART;
}

export class SetRadarChart implements Action {
  type = SET_RADAR_CHART;
}

export class SetPieChart implements Action {
  type = SET_PIE_CHART;
}

export class SetPolarAreaChart implements Action {
  type = SET_POLAR_AREA_CHART;
}

export class SetBubbleChart implements Action {
  type = SET_BUBBLE_CHART;
}

export class SetScatterChart implements Action {
  type = SET_SCATTER_CHART;
}

export class SetFinancialChart implements Action {
  type = SET_FINANCIAL_CHART;
}

export type All =
  | SetLineChart
  | SetBarChart
  | SetDoughnutChart
  | SetRadarChart
  | SetPieChart
  | SetPolarAreaChart
  | SetBubbleChart
  | SetScatterChart
  | SetFinancialChart;
