<ng-container *ngIf="ISVIEW; then isViewTmpl; else isNotViewTmpl">
</ng-container>
<ng-template #isNotViewTmpl>
  <div class="app-text-area p-0" [ngClass]="elementWidthClassName">
    <textarea
      [formControl]="formControl"
      [attr.class]="'form-control'"
      [attr.placeholder]="placeholder ? placeholder : ''"
      [attr.cols]="cols"
      [attr.rows]="rows"
      [attr.maxLength]="maxLength"
      [ngClass]="[
        elementHeightClassName,
        isInvalid ? 'is-invalid' : '',
        isValid ? 'is-valid' : ''
      ]"
      [ngStyle]="{ 'border-color': isInvalid ? '#dc3545' : '' }"
      (change)="onChange.emit($event.target.value)"
      (input)="onInput.emit($event.target.value)"
      (blur)="onBlur.emit($event.target.value)"
    ></textarea>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>

    <div class="text-right app-text-area-length">
      {{ (formControl.value?.length || 0) + '/' +  maxLength }}
    </div>
  </div>
  <small class="text-danger" *ngIf="isInvalid">
    {{ errors.message }}
  </small>
</ng-template>
<ng-template #isViewTmpl>
  <div class="position-relative d-inline-block">
    <span class="span-break">{{ formControl.value || '-' }}</span>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <span *ngIf="ISVIEW">
    <ng-container *ngTemplateOutlet="flagNewTmpl"></ng-container>
  </span>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-container *ngIf="!ISVIEW">
        <ng-container *ngTemplateOutlet="flagNewTmpl"></ng-container>
      </ng-container>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
<ng-template #flagNewTmpl>
  <ng-content select="app-flag-new"></ng-content>
</ng-template>
