import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { ResetEmailComponent } from './reset-email.component';
export const routes = [{ path: '', component: ResetEmailComponent, pathMatch: 'full' }];
@NgModule({
  imports: [SharedModule, FormsModule, ReactiveFormsModule, RouterModule.forChild(routes)],
  declarations: [ResetEmailComponent]
})
export class ResetEmailModule { }
