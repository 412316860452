import { WidgetTotalCostSavingModelResponse } from './widget-total-cost-saving-model-response';

export class WidgetTotalCostSavingResponse {
  totalCostIniciative: any;
  totalCostYearAvoidance: any;
  totalCostYearSelected: any;
  growthFromPastYearPercentage: any;
  growthFromPastYear: any;
  currency: string;
  widgetTotalCostSavingModelResponseList: WidgetTotalCostSavingModelResponse[];
}
