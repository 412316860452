import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/internal/operators/take';
import { ContractDraftParameterValue } from 'src/app/core/bean/contract-draft-parameter-value';
import { AppPopupVersionHistoryModel } from 'src/app/core/components/app-popup/app-popup-version-history/app-popup-version-history-model';
import { Validators } from 'src/app/core/validators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Contract } from '../../core/bean/contract';
import { ContractNegotiationHistory } from '../../core/bean/contract-negotiation-history';
import { ContractWorklistStatus } from '../../core/bean/contract-worklist-status';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { AppPopupVersionHistoryService } from '../../core/components/app-popup/app-popup-version-history/app-popup-version-history.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { ContractFinalizationRequest } from './contract-finalization-request';
import { ContractFinalizationResponse } from './contract-finalization-response';

@Component({
  templateUrl: './contract-finalization-edit-add-x.component.html'
})
export class ContractFinalizationEditAddXComponent extends BaseModuleComponent {
  public contractNegotiationHistory: ContractNegotiationHistory;
  public contract: Contract = new Contract();
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public response: ContractFinalizationResponse = new ContractFinalizationResponse();
  public request: ContractFinalizationRequest = new ContractFinalizationRequest();
  public model: AppPopupVersionHistoryModel;
  public currentDate: Date = new Date();
  public formGroupParameter: FormGroup = new FormGroup({});
  public fileUploader: FileUploader = new FileUploader(
    '/contract-finalization/',
    '',
    this.global.appConstant.fileType.DOC_CONTRACT,
    false,
    1
  );

  public urlBackOutside: string;
  public contractWorklistStatus: ContractWorklistStatus;
  public approvalModelPrcsId: number;
  public objectId: number;
  public contractId: number;
  public isRevision = false;
  public isParameterReady = false;
  public validateChange = true;
  public roleCode: string;

  public badgeColor = {
    NEW: 'INFO',
    REVISION: 'FEEDBACK',
    WAITING_CONFIRMATION: 'GOOD',
    ON_PROGRESS: 'PROGRESS',
    REVISION_BY_VENDOR: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING'
  };
  public getColorAndIconConfirm = {
    APPROVED: { color: 'text-success', icon: 'pic pi-thumbs-up-circle' },
    REVISION: { color: 'text-primary', icon: 'pic pi-pencil-circle' }
  };
  public APPROVED = 1;
  public REVISED = 2;

  constructor(
    translateService: TranslateService,
    public appPopupVersionHistoryService: AppPopupVersionHistoryService,
    public appPopupService: AppPopupService
  ) {
    super('contract-finalization', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    this.contract = this.global.routerParams.get('contract');
    this.contractId = this.global.routerParams.get('contractId');
    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId != null) {
      this.contractId = this.objectId;
    }
    this.contractWorklistStatus = this.global.routerParams.get(
      'contractWorklistStatus'
    );
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
  }

  public onClickVersionHistory(): void {
    this.appPopupVersionHistoryService.open(
      this.response.appPopupVersionHistoryModelList
    );
  }

  public setIsViewOnly(): void {
    if (
      this.todo === 'view' ||
      this.contractWorklistStatus.code ===
        this.global.appConstant.cm
          .CONTRACT_WORKLIST_STATUS_WAITING_CONFIRMATION ||
      this.contractWorklistStatus.code ===
        this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_ON_PROGRESS ||
      this.contractWorklistStatus.code ===
        this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_WAITING_APPROVAL
    ) {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      docFile: [null, Validators.required()],
      date: [null, Validators.compose([Validators.required()])],
      startDate: [null],
      endDate: [null],
      currentDate: this.currentDate,
      endDateValidate: [null],
      contractNumber: [null]
    });
    this.formGroupParameter = this.formBuilder.group({
      contractTemplateParamList: this.formBuilder.array([])
    });
  }

  public get contractTemplateParamList(): FormArray {
    return this.formGroupParameter.get('contractTemplateParamList') as FormArray;
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ContractFinalizationResponse>(
        '/contract-finalization/add-edit-x',
        new RouteRequestModel(this.todo, this.contractId)
      )
      .subscribe(contractFinalizationResponse => {
        this.roleCode = this.global.userSession.activeUserRole.role.code;
        this.response = contractFinalizationResponse;
        this.contract = this.response.contract;
        this.contractNegotiationHistory = this.response.contractNegotiationHistory;
        this.model = this.response.versionHistoryModel;
        this.fileUploader.setFile(this.contractNegotiationHistory.docFile);
        this.formGroup.patchValue({
          docFile: this.fileUploader.fileObjectList,
        });
        if (this.response.contract.startDate) {
          const startDate: Date = new Date(this.response.contract.startDate);
          const endDate: Date = new Date(this.response.contract.endDate);
          this.formGroup.patchValue({
            date: {
              from: startDate,
              to: endDate
            },
            contractNumber: this.response.contract.code
          });
        }else{
          if(this.response.startDate){
            const startDate: Date = new Date(this.response.startDate);
            const endDate: Date = new Date(this.response.endDate);
            this.formGroup.patchValue({
              date: {
                from: startDate,
                to: endDate
              },
              startDate: startDate,
              endDate: endDate,
              currentDate: startDate,
              endDateValidate: endDate
            });
          }
        }
        
        if (!this.contractWorklistStatus) {
          this.contractWorklistStatus =
            this.response.contract.contractWorklistStatus;
        }
        if (
          this.response.contractDraftParameterValueList &&
          this.response.contractDraftParameterValueList
            .length !== 0
        ) {
          this.response.contractDraftParameterValueList.forEach(
            contractDraftParamValue => {
              const formGroup: FormGroup = this.formBuilder.group({
                id: contractDraftParamValue.id,
                code: contractDraftParamValue.contractTemplateParameter
                  .contractParameter.code,
                name: contractDraftParamValue.contractTemplateParameter
                  .contractParameter.name,
                value: [
                  contractDraftParamValue.contractTemplateParameter
                    .contractParameter.valueType.code ===
                    'DATE_FORMAT_SHORT' ||
                  contractDraftParamValue.contractTemplateParameter
                    .contractParameter.valueType.code === 'DATE_FORMAT_LONG'
                    ? contractDraftParamValue.value
                      ? new Date(contractDraftParamValue.value)
                      : null
                    : this.contract.contractType.code ===
                        this.global.appConstant.cm
                          .CONTRACT_TYPE_ADDENDUM_CONTRACT &&
                      contractDraftParamValue.contractTemplateParameter
                        .contractParameter.code ===
                        this.global.appConstant.cm
                          .CONTRACT_PARAMETER_NOMOR_KONTRAK
                    ? this.contract.fromContract.number
                    : contractDraftParamValue.value,
                  !contractDraftParamValue.contractTemplateParameter.contractParameter.code.includes(
                    'PERUBAHAN'
                  )
                    ? Validators.required()
                    : null
                ],
                valueTypeCode:
                  contractDraftParamValue.contractTemplateParameter
                    .contractParameter.valueType.code,
                contractTemplateParameter:
                  contractDraftParamValue.contractTemplateParameter
              });
              if (this.todo === 'view' || this.todo === 'detail') {
                formGroup.setIsView(true);
              }
              if (
                this.contract.contractType.code ===
                  this.global.appConstant.cm
                    .CONTRACT_TYPE_ADDENDUM_CONTRACT &&
                contractDraftParamValue.contractTemplateParameter
                  .contractParameter.code ===
                  this.global.appConstant.cm.CONTRACT_PARAMETER_NOMOR_KONTRAK
              ) {
                formGroup.get('value').setIsView(true);
              }
              this.contractTemplateParamList.push(formGroup);
            }
          );
          this.isParameterReady = true;
        } else {
          this.isParameterReady = true;
        }
        this.setIsViewOnly();
        this.setStateReady();
      });
  }

  public onChangeValue(): void {
    const contractTemplateParamList =
      this.formGroupParameter.value.contractTemplateParamList;
    const contractDraftParamValueList: ContractDraftParameterValue[] = [];
    if (contractTemplateParamList) {
      contractTemplateParamList.forEach(parameter => {
        const contractDraftParameterValue: ContractDraftParameterValue =
          new ContractDraftParameterValue();
        contractDraftParameterValue.contractTemplateParameter =
          parameter.contractTemplateParameter;
        contractDraftParameterValue.value = parameter.value;
        if (
          parameter.code ===
          this.global.appConstant.cm.CONTRACT_PARAMETER_NOMOR_KONTRAK
        ) {
          this.contract.number = parameter.value;
        }
        contractDraftParamValueList.push(contractDraftParameterValue);
      });
    }
    this.response.contractDraftParameterValueList =
      contractDraftParamValueList;
    this.validateChange = false;
  }

  public doSubmit(): void {
    this.validate();
    if (this.formGroup.valid && this.formGroupParameter.valid) {
      const request = this.preparedData();
      this.global.modalService
        .newSubmitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<Contract>>(
                '/contract-finalization/submit',
                request
              )
              .subscribe(response => {
                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccess()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate([this.urlBackOutside]);
                      } else {
                        this.router
                          .navigateByUrl('/', {
                            skipLocationChange: true
                          })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set(
                              'urlBackOutside',
                              '/pages/worklist-cm'
                            );
                            this.global.routerParams.set(
                              'contract',
                              this.contract
                            );
                            this.global.routerParams.set(
                              'contractId',
                              this.contractId
                            );
                            this.global.routerParams.set('todo', 'view');
                            this.router.navigate([
                              '/pages/contract-finalization/detail'
                            ]);
                          });
                      }
                    });
                }else {
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public preparedData(): ContractFinalizationRequest {
    this.formGroup.patchValue({
      startDate: this.formGroup.value.date.from,
      endDate: this.formGroup.value.date.to
    });
    this.request.contract = this.response.contract;
    this.request.contract.code = this.formGroup.value.contractNumber;
    this.request.contract.startDate = this.formGroup.value.startDate;
    this.request.contract.endDate = this.formGroup.value.endDate;
    this.request.contractDraftParameterValueList = this.response.contractDraftParameterValueList;
    return this.request;
  }
}
