import { CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppPaginationModule } from '../app-pagination/app-pagination.module';
import { AppTableComponent } from './app-table.component';
import { ResolveColumnClassNamePipe } from './pipe/resolve-column-class-name.pipe';
import { ResolveColumnValuePipe } from './pipe/resolve-column-value.pipe';
import { AppTableCheckBoxService } from './services/app-table-checkbox.service';
@NgModule({
  imports: [
    VendorModule,
    AppPaginationModule,
    PipesModule,
    AppButtonModule,
    PerfectScrollbarModule
  ],
  declarations: [
    AppTableComponent,
    ResolveColumnValuePipe,
    ResolveColumnClassNamePipe
  ],
  providers: [
    AppTableCheckBoxService,
    DatePipe,
    CurrencyPipe,
    ResolveColumnClassNamePipe
  ],
  exports: [AppTableComponent, ResolveColumnValuePipe]
})
export class AppTableModule {}
