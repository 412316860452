import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { OrderTc } from 'src/app/core/bean/order-tc';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './popup-order-edit-condition.component.html'
})
export class PopupOrderEditConditionComponent extends BaseComponentComponent {

  @Input() orderTc: OrderTc;
  @Output() onChange: EventEmitter<OrderTc> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {
    super('order');
  }

  onInit(): void {
    this.buildAndSetFormGroup();
    this.setStateReady();
  }

  public buildAndSetFormGroup(): void {
    this.formGroup = this.formBuilder.group({
        condition: [null, Validators.required()]
    });

    this.formGroup.patchValue({
      condition: this.orderTc.condition
    });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.orderTc.condition = this.formGroup.value.condition;
      this.onChange.emit(this.orderTc);
    }
  }

}
