import { BaseEntity } from "../base/base-entity";
import { WorkPlan } from "../bean/work-plan";

export class OfficialMemoSubmissionEntity extends BaseEntity {
    workPlan: WorkPlan;
    number: string;
    createdDate: Date;
    description: string;
    sequence: number;
    subject: string;
}