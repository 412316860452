import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { DueDiligenceOTS } from '../../../core/bean/due-diligence-ots';
import { DueDiligenceRealization } from '../../../core/bean/due-diligence-realization';
import { File } from '../../../core/bean/file';
import { Vendor } from '../../../core/bean/vendor';
import { FileObject, FileUploader } from '../../../core/components/upload';
import { OptionListModel } from '../../../core/model/option-list-model';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { Validators } from '../../../core/validators';
import { OtsRequest } from './ots.request';
import { OtsResponse } from './ots.response';

@Component({
  templateUrl: './ots.component.html'
})
export class OTSComponent extends BaseModuleComponent {
  public otsResponse: OtsResponse = new OtsResponse();
  public otsResponseRouteParam: OtsResponse = null;
  public today: Date = new Date();
  public vendorId: number;

  public optionList: OptionListModel<any> = new OptionListModel();
  public isView = false;
  public otsRequest: OtsRequest = new OtsRequest();
  public vendorHistoryId: number;

  public fileUploaders: FileUploader = new FileUploader(
    '/on-boarding/',
    'on-boarding.ots',
    this.global.appConstant.fileType.DOC_OTS,
    false,
    100
  );

  constructor(translateService: TranslateService) {
    super('ots', translateService);
  }

  onInit(): void {
    this.vendorHistoryId = this.global.routerParams.get('vendorHistoryId');
    this.otsResponseRouteParam = this.global.routerParams.get('otsResponse');
    this.buildFormGroup();
    this.buildOptionList();
    this.getAndSetOnBoardingToFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null, Validators.required()],
      startTime: [
        null,
        Validators.compose([Validators.required(), Validators.max('endTime')])
      ],
      endTime: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.min('startTime')
        ])
      ],
      note: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(512)])
      ],
      credentialsOTS: this.formBuilder.array([]),
      filesOTS: [null, Validators.required()]
    });
  }

  public clearFormGroup(otsResponse: OtsResponse): void {
    if (
      otsResponse.isTemplateDifferent &&
      otsResponse.vendorHistory.vendor.vendorActivityStatus.code.toUpperCase() ===
      'OTS'
    ) {
      this.formGroup.get('date').reset();
      this.formGroup.get('startTime').reset();
      this.formGroup.get('endTime').reset();
      this.formGroup.get('note').reset();
      this.formGroup.get('filesOTS').reset();
    }
  }

  public buildOptionList(): void {
    const optionList = [
      { id: 0, name: 'No', value: false },
      { id: 1, name: 'Yes', value: true }
    ];
    this.optionList.setRequestValues(optionList);
  }

  public getAndSetOnBoardingToFormGroup(): void {
    if (this.otsResponseRouteParam) {
      this.setStateLoading();
      this.otsResponse = this.otsResponseRouteParam;
      this.setOnBoardingToFormGroup(this.otsResponse);
      this.setOTSisView();
      this.setStateReady();

    } else {
      this.httpClientService.get<OtsResponse>('/ots/index/' + this.vendorHistoryId)
        .subscribe((otsResponse: OtsResponse) => {
          this.otsResponse = otsResponse;
          this.setOnBoardingToFormGroup(this.otsResponse);
          this.setOTSisView();
          this.global.routerParams.set('otsResponse', this.otsResponse);
          this.setStateReady();
        });
    }

  }

  public setOnBoardingToFormGroup(otsResponse: OtsResponse): void {
    if (otsResponse.vendorHistory !== null) {
      this.otsResponse = otsResponse;

      if (this.otsResponse.dueDiligenceRealizationOTS !== null) {
        const fileOTSList: File[] = [];
        this.otsResponse.dueDiligenceDocList.forEach(docfile => {
          fileOTSList.push(docfile.file);
        });
        this.fileUploaders.setFileList(fileOTSList);

        this.formGroup.patchValue({
          date: new Date(this.otsResponse.dueDiligenceRealizationOTS.date),
          startTime: new Date(
            this.otsResponse.dueDiligenceRealizationOTS.startTime
          ),
          endTime: new Date(
            this.otsResponse.dueDiligenceRealizationOTS.endTime
          ),
          note: this.otsResponse.dueDiligenceRealizationOTS.note,
          filesOTS: this.fileUploaders.fileObjectList
        });
      }

      const isValidValue = this.optionList.getRequestValues();
      this.otsResponse.dueDiligenceTemplateOTSList.forEach(
        (templateOTS, index) => {
          this.inputListOTS.push(
            this.formBuilder.group({
              // attribute: templateOTS.attribute.translationKey.module.code.toLowerCase() + '.' + templateOTS.attribute.translationKey.key,
              attribute: templateOTS.attribute.description,

              isValid:
                this.otsResponse.dueDiligenceOTSList &&
                  this.otsResponse.dueDiligenceOTSList.length ===
                  this.otsResponse.dueDiligenceTemplateOTSList.length &&
                  (!this.otsResponse.isTemplateDifferent ||
                    this.otsResponse.vendorHistory.vendor.vendorActivityStatus.code
                    !== this.global.appConstant.vm.V_ACTIVITY_STATUS_OTS)
                  ? [
                    this.otsResponse.dueDiligenceOTSList &&
                      this.otsResponse.dueDiligenceOTSList[index] &&
                      this.otsResponse.dueDiligenceOTSList[index].isValid ?
                      isValidValue[1] : isValidValue[0],
                    Validators.required()
                  ]
                  : [null, Validators.required()],

              noteOTS:
                this.otsResponse.dueDiligenceOTSList &&
                  this.otsResponse.dueDiligenceOTSList.length ===
                  this.otsResponse.dueDiligenceTemplateOTSList.length &&
                  (!this.otsResponse.isTemplateDifferent ||
                    this.otsResponse.vendorHistory.vendor.vendorActivityStatus.code
                    !== this.global.appConstant.vm.V_ACTIVITY_STATUS_OTS)
                  ? [
                    (this.otsResponse.dueDiligenceOTSList &&
                      this.otsResponse.dueDiligenceOTSList[index] ?
                      this.otsResponse.dueDiligenceOTSList[index].note : null),
                    Validators.required()
                  ]
                  : [
                    null,
                    Validators.compose([
                      Validators.required(),
                      Validators.maxLength(512)
                    ])
                  ]
            })
          );
        }
      );

      this.clearFormGroup(otsResponse);
    }
    // this.global.routerParams['OTS'] = this.formGroup;
  }

  public setOTSisView(): void {
    if (
      this.otsResponse.vendorHistory.vendor.vendorActivityStatus.code !==
      'OTS' || this.otsResponse.isUserApproval
    ) {
      this.setViewOnly();
      this.isView = true;
    } else if (
      this.global.userSession.user.organization.id !==
      this.otsResponse.vendorHistory.vendor.onBoardingByOrganization.id
    ) {
      this.setViewOnly();
      this.isView = true;
    }
  }

  public setCredsOTS(otsResponse: OtsResponse): void {
    const isValidValue = this.optionList.getRequestValues();

    otsResponse.dueDiligenceOTSList.forEach(ots => {
      this.inputListOTS.push(
        this.formBuilder.group({
          // attribute: ots.dueDiligenceTemplateOTS.attribute.translationKey.module.code.toLowerCase() + '.' + ots.dueDiligenceTemplateOTS.attribute.translationKey.key,
          attribute: ots.dueDiligenceTemplateOTS.attribute.description,
          isValid: [
            ots.isValid ? isValidValue[1] : isValidValue[0],
            Validators.required()
          ],
          noteOTS: [
            ots.note,
            Validators.compose([
              Validators.required(),
              Validators.maxLength(512)
            ])
          ]
        })
      );
    });
  }

  public addCredsOTS(): void {
    this.otsResponse.dueDiligenceTemplateOTSList.forEach(templateOTS => {
      this.inputListOTS.push(
        this.formBuilder.group({
          // attribute: templateOTS.attribute.translationKey.module.code.toLowerCase() + '.' + templateOTS.attribute.translationKey.key,
          attribute: templateOTS.attribute.description,
          isValid: [null, Validators.required()],
          noteOTS: [
            null,
            Validators.compose([
              Validators.required(),
              Validators.maxLength(512)
            ])
          ]
        })
      );
    });
  }

  public buildConditionalCredsOTS(): void {
    const isValidValue = this.optionList.getRequestValues();

    this.otsResponse.dueDiligenceTemplateOTSList.forEach(
      (templateOTS, index) => {
        this.inputListOTS.push(
          this.formBuilder.group({
            // attribute: templateOTS.attribute.translationKey.module.code.toLowerCase() + '.' + templateOTS.attribute.translationKey.key,
            attribute: templateOTS.attribute.description,
            isValid:
              this.otsResponse.dueDiligenceOTSList &&
                this.otsResponse.dueDiligenceOTSList.length ===
                this.otsResponse.dueDiligenceTemplateOTSList.length
                ? this.otsResponse.dueDiligenceOTSList[index].isValid
                  ? isValidValue[1]
                  : isValidValue[0]
                : [null, Validators.required()],
            noteOTS:
              this.otsResponse.dueDiligenceOTSList &&
                this.otsResponse.dueDiligenceOTSList.length ===
                this.otsResponse.dueDiligenceTemplateOTSList.length
                ? this.otsResponse.dueDiligenceOTSList[index].note
                : [
                  null,
                  Validators.compose([
                    Validators.required(),
                    Validators.maxLength(512)
                  ])
                ]
          })
        );
      }
    );
  }

  public get inputListOTS(): FormArray {
    return this.formGroup.get('credentialsOTS') as FormArray;
  }

  public getHours(time): string {
    return new Date(time).toLocaleTimeString('en-GB').substring(0, 5);
  }

  public doSaveOTS(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.formSaving = true;
            this.loadingBlockService.showInfo(`app.msg.processing`);

            if (
              this.otsResponse.vendorHistory.vendor.vendorActivityStatus
                .code === 'OTS'
            ) {
              const dueDiligenceRealization: DueDiligenceRealization = this.global.copyFormAttributeToModel(
                new DueDiligenceRealization(),
                this.formGroup
              );
              dueDiligenceRealization.startTime = new Date(
                dueDiligenceRealization.startTime
              );
              dueDiligenceRealization.endTime = new Date(
                dueDiligenceRealization.endTime
              );
              const dueDiligenceOTSList: DueDiligenceOTS[] = [];
              this.formGroup.get('credentialsOTS').value.forEach(value => {
                const dueDiligenceOTS: DueDiligenceOTS = new DueDiligenceOTS();
                dueDiligenceOTS.isValid = value.isValid.value;
                dueDiligenceOTS.note = value.noteOTS;
                dueDiligenceOTSList.push(dueDiligenceOTS);
              });
              const fileOTSList: FileObject[] = [];
              this.formGroup.get('filesOTS').value.forEach(fileObject => {
                if (fileObject.id) {
                  // file lama (File, bukan FileObject) dari patchvalue yg lgsg submit tanpa re-upload
                  const fileObjectOldFile = new FileObject(fileObject, false); // isNew = false
                  fileOTSList.push(fileObjectOldFile);
                } else {
                  fileOTSList.push(fileObject);
                }
              });
              this.otsRequest.dueDiligenceRealizationOTS = dueDiligenceRealization;
              this.otsRequest.dueDiligenceOTSList = dueDiligenceOTSList;
              this.otsRequest.fileOTSList = fileOTSList;
            }

            this.otsRequest.dueDiligenceTemplateOTSList = this.otsResponse.dueDiligenceTemplateOTSList;
            this.otsRequest.vendorHistory = this.otsResponse.vendorHistory;
            this.httpClientService
              .post<Response<Vendor>>('/ots/update', this.otsRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.setStateReady();
                  this.router.navigate(['/pages/on-boarding']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    } else {
      // if formgroup not valid, uncompleted data
      this.global.alertService.showError(
        this.translateService.instant('ots.alert.completeData')
      );
    }
  }

  public doBackApproval(): void {
    this.router.navigate(['/pages/approval-prcs']);
  }

  public doBack(): void {
    this.router.navigate(['/pages/on-boarding']);
  }
}
