import { HttpRequest } from '@angular/common/http';
export function createHttpRequest(
  stringUrl: string,
  formData: FormData
): HttpRequest<any> {
  return new HttpRequest('POST', stringUrl, formData, {
    reportProgress: true,
    responseType: 'json'
  });
}
