import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupWorkflowComponent } from './app-popup-workflow.component';
import { AppPopupWorkflowService } from './app-popup-workflow.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppTableModule],
  declarations: [AppPopupWorkflowComponent],
  entryComponents: [AppPopupWorkflowComponent],
  providers: [AppPopupWorkflowService],
  exports: [AppPopupWorkflowComponent]
})
export class AppPopupWorkflowModule {}
