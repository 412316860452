import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Module } from '../../core/bean/module';
import { Translation } from '../../core/bean/translation';
import { TranslationKey } from '../../core/bean/translation-key';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { TranslationResponse } from './translation-response';
@Component({
  templateUrl: 'translation-edit.component.html'
})
export class TranslationEditComponent extends BaseModuleComponent {
  public translationResponse: TranslationResponse = new TranslationResponse();
  public module: Module;
  public editedRowId = -2;
  public isDisable: boolean;
  constructor(translateService: TranslateService) {
    super('translation', translateService);
  }

  public onInit(): void {
    this.doSetModuleFromRouterParams();
    this.doSetDefault();
    this.doGetAndSetTranslationResponse();
  }

  public doSetDefault(): void {
    this.isDisable = true;
    this.translationResponse.translationKeyList = [];
  }

  public doSetModuleFromRouterParams(): void {
    this.module = this.global.routerParams.get('module');
  }

  public doGetAndSetTranslationResponse(): void {
    this.httpClientService
      .post<TranslationResponse>('/translation/edit', this.module)
      .subscribe(translationResponse => {
        this.translationResponse = translationResponse;
        this.setStateReady();
      });
  }

  public doAdd(): void {
    const newKey: TranslationKey = new TranslationKey();
    newKey.id = -1;
    newKey.module = this.module;
    newKey.translationList = new Array();
    for (let a = 0; a < this.translationResponse.langList.length; a++) {
      const trans: Translation = new Translation();
      trans.lang = this.translationResponse.langList[a];
      trans.value = '';
      newKey.translationList[a] = trans;
    }
    this.translationResponse.translationKeyList.push(newKey);
    this.editedRowId = -1;
  }

  public doEditRow(id: number): void {
    if (this.editedRowId !== id && this.editedRowId !== -1) {
      this.editedRowId = id;
    }
  }

  public doSave(key: TranslationKey): void {
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.httpClientService
            .post<Response<TranslationKey>>('/translation/add-update', key)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.editedRowId = -2;
                key.id = response.body.id;
                key.translationList = response.body.translationList;
                this.global.alertService.showSuccessSaving();
                this.translateService.reloadLang(this.global.getCurrentLang());
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
          // this.setStateReady();
        }
      });
  }

  public doDelete(key: TranslationKey, keyIdx: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<TranslationKey>>('/translation/delete', key)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.translationResponse.translationKeyList.splice(keyIdx, 1);
                this.global.alertService.showSuccessDelete();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doCancelEditRow(): void {
    if (this.editedRowId === -1) {
      this.translationResponse.translationKeyList.splice(-1, 1);
    }
    this.editedRowId = -2;
  }

  public doCancel(): void {
    this.router.navigate(['/pages/translation']);
  }

  public doCheckField(translateList: Translation[]): void {
    let num = 0;
    this.isDisable = true;
    translateList.forEach(translate => {
      if (translate.value !== '') {
        num++;
      }
    });

    if (translateList.length === num) {
      this.isDisable = false;
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    this.log.debug(event);
  }

  public onKeyDown(event: KeyboardEvent): void {
    this.log.debug(event);
  }
}
