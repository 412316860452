import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';

@Component({
  selector: 'app-rating-icon',
  templateUrl: './app-rating-icon.component.html',
  styleUrls: ['./app-rating-icon.component.scss']
})
export class AppRatingIconComponent implements OnInit, OnChanges {
  @Input() iconId: number;
  @Input() rating: number;
  @Input() isViewOnly: boolean;
  @Input() icon = 'fa-star';
  @Input() disabled: boolean;

  @Output() onMouseLeave: EventEmitter<number> = new EventEmitter<number>();
  @Output() onMouseEnter: EventEmitter<number> = new EventEmitter<number>();
  @Output() onClick: EventEmitter<number> = new EventEmitter<number>();

  starClassName = 'star-rating-blank';

  constructor(public renderer2: Renderer2, public elementRef: ElementRef) {}

  ngOnInit(): void {
    this.buildRatingIcon();
  }

  ngOnChanges(): void {
    this.buildRatingIcon();
  }

  buildRatingIcon(): void {
    if (this.rating >= this.iconId) {
      this.starClassName = 'star-rating-filled';
    }

    this.resetIElementStyle();

    if (
      Math.floor(this.rating + 1) === this.iconId &&
      this.rating + 1 > this.iconId &&
      (this.isViewOnly || this.disabled)
    ) {
      this.setIElementStyle();
    }
  }

  resetIElementStyle(): void {
    const iElement = this.elementRef.nativeElement.children[0];
    this.renderer2.removeStyle(iElement, 'background');
    this.renderer2.removeStyle(iElement, '-webkit-background-clip');
    this.renderer2.removeStyle(iElement, '-webkit-text-fill-color');
  }

  setIElementStyle(): void {
    const iElement = this.elementRef.nativeElement.children[0];
    const percent = (this.rating + 1 - this.iconId) * 100;
    this.renderer2.setStyle(
      iElement,
      'background',
      `linear-gradient(90deg, gold ${percent}%, var(--gray-400) 0)`
    );
    this.renderer2.setStyle(iElement, '-webkit-background-clip', 'text');
    this.renderer2.setStyle(iElement, '-webkit-text-fill-color', 'transparent');
  }

  handleClick(): void {
    if (!this.disabled || !this.isViewOnly) {
      this.onClick.emit(this.iconId);
    }
  }

  handleMouseEnter(): void {
    if (!this.disabled || !this.isViewOnly) {
      this.onMouseEnter.emit(this.iconId);
    }
  }

  handleMouseLeave(): void {
    if (!this.disabled || !this.isViewOnly) {
      this.onMouseLeave.emit(this.iconId);
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
