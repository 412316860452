import { BaseDTO } from '../../../core/base/base-dto';
import { Region } from '../../../core/bean/region';
import { Vendor } from '../../../core/bean/vendor';
import { VendorAddress } from '../../../core/bean/vendor-address';
import { VendorAddressHistory } from '../../../core/bean/vendor-address-history';
import { VendorHistory } from '../../../core/bean/vendor-history';

export class TabProfileGeneralInformationDTO extends BaseDTO {
    vendorHistory: VendorHistory;
    vendor: Vendor;
    vendorAddressHistory: VendorAddressHistory;
    vendorAddress: VendorAddress;
    countryRegionList: Region[];
}
