import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from './../../core/shared/shared.module';
import { AppPopupTabSegmentationComponent } from './app-popup-tab-segmentation.component';
import { TabSegmentationComponent } from './tab-segmentation.component';
@NgModule({
  imports: [SharedModule],
  declarations: [TabSegmentationComponent, AppPopupTabSegmentationComponent],
  entryComponents: [AppPopupTabSegmentationComponent, TabSegmentationComponent],
  exports: [TabSegmentationComponent]
})
export class TabSegmentationModule {
  constructor(accordionService: AccordionService) {
    accordionService.register('tab-segmentation', TabSegmentationComponent);
  }
}
