import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CustomValidatorError } from '../../core/custom-validator-error';
export class MaxLengthValidator {
  public static maxLength(maxLength: number, message?: string): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors => {
      let value = abstractControl.value;
      if (value && typeof value === 'string') {
        const divElement = document.createElement('div');
        divElement.innerHTML = value;
        if (divElement.children.length > 0) {
          value = divElement.textContent || divElement.innerText;
        }
      }
      if (
        value &&
        ((Array.isArray(value) && value.length - 1) ||
          value.toString().length - 1) > maxLength
      ) {
        const defaultError = {
          actualLength: abstractControl.value.length - 1,
          requiredLength: maxLength
        };
        return CustomValidatorError.create('maxLength', message, defaultError);
      }
      return null;
    };
  }
}
