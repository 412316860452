import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ProcurementParticipantEditAddComponent } from './procurement-participant-edit-add.component';
import { ProcurementParticipantComponent } from './procurement-participant.component';
export const routes = [
  {
    path: '',
    component: ProcurementParticipantComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'edit',
    component: ProcurementParticipantEditAddComponent,
    data: { breadcrumb: 'procurement-participant.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    ProcurementParticipantComponent,
    ProcurementParticipantEditAddComponent
  ],
  providers: []
})
export class ProcurementParticipantModule {}
