import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Region } from '../../core/bean/region';
import { Vendor } from '../../core/bean/vendor';
import { VendorEntity } from '../../core/bean/vendor-entity';
import { VendorRepresentativeAgentHistory } from '../../core/bean/vendor-representative-agent-history';
import { VendorType } from '../../core/bean/vendor-type';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { TabModel } from '../../core/model/tab/tab-model';
import { CompanyProfileServie } from '../company-profile/company-profile.service';
import { CompanyProfileDTO } from '../company-profile/dto/company-profile.dto';
import { TabProfileRepAgentDTO } from './dto/tab-profile-rep-agent.dto';
import { AppPopupRepAgentComponent } from './popup/app-popup-rep-agent.component';

@Component({
  templateUrl: './tab-profile-rep-agent.component.html',
  styles: [
    '.width-company-name { min-width : 250px; white-space: normal ! important }',
    '.width-address { min-width : 300px; white-space: normal ! important }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class TabProfileRepAgentComponent extends BaseModuleComponent {
  @ViewChild('selectorVendorRepAgent') public tableRepAgent: AppTableComponent;
  public userId: number;
  public companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public tabProfileRepAgentDTO: TabProfileRepAgentDTO =
    new TabProfileRepAgentDTO();
  public vendor: Vendor = new Vendor();
  public now = new Date().getTime();
  public sessionId = null;
  public vendorTypeList: VendorType[] = [];
  public regionList: Region[] = [];
  public vendorEntityList: VendorEntity[] = [];
  public vendorRepAgentHistory: VendorRepresentativeAgentHistory;
  public location: string;
  public dataPhone: any;
  public isView: boolean;
  public taxNumberList: string[] = [];
  public isVendorList: boolean;
  public fromOutside: string;
  public companyNameList: string[] = [];

  public tabRepAgent: TabModel;
  public tableResponse: TableResponseModel<any>;

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public companyProfileService: CompanyProfileServie
  ) {
    super('tab-profile-rep-agent', translate);
  }

  onInit(): void {
    this.getDataFromRouterParams();
    if (this.tabRepAgent.routerParams.get('tabProfileRepAgentDTO')) {
      this.tabProfileRepAgentDTO = this.tabRepAgent.routerParams.get(
        'tabProfileRepAgentDTO'
      );
      this.buildTableResponse();
      this.getAndSetVendorToFormGroup();
    } else {
      this.getDataFromServer();
    }
  }

  public getDataFromServer(): void {
    // tslint:disable-next-line: max-line-length
    this.httpClientService
      .get<TabProfileRepAgentDTO>(
        this.global.userSession.activeUserRole.role.type !==
          this.global.appConstant.core.ROLE_TYPE_VENDOR
          ? '/tab-profile-rep-agent/index?id=' +
              this.companyProfileDTO.vendorHistory.id +
              '&todo=' +
              this.todo
          : '/tab-profile-rep-agent/index'
      )
      .subscribe(tabProfileRepAgentDTO => {
        this.tabProfileRepAgentDTO = tabProfileRepAgentDTO;
        this.tabRepAgent.routerParams.set(
          'tabProfileRepAgentDTO',
          tabProfileRepAgentDTO
        );
        this.buildFormGroup();
        this.buildTableResponse();
        this.getAndSetVendorToFormGroup();
      });
  }

  public buildTableResponse(): void {
    if (
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR &&
      !this.isVendorList &&
      this.companyProfileDTO.vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE
    ) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'companyName',
          header: 'table.column.companyName',
          customClass: 'width-company-name'
        },
        {
          field: 'phoneNumber',
          header: 'table.column.phone'
        },
        { field: 'email', header: 'table.column.email' },
        {
          field: 'address',
          header: 'table.column.address',
          customClass: 'width-address'
        },
        { field: 'information', header: 'table.column.information' },
        {
          field: 'companyStatus',
          header: 'table.column.companyStatus',
          datamap: '{"1" : "Holding" , "2" : "Not Holding" }'
        },
        {
          field: 'crudInfo',
          header: 'table.column.status',
          format: FieldFormatEnum.Html,
          customClass: 'text-center'
        }
      ]);
    } else {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'companyName',
          header: 'table.column.companyName',
          customClass: 'width-company-name'
        },
        {
          field: 'phoneNumber',
          header: 'table.column.phone'
        },
        { field: 'email', header: 'table.column.email' },
        {
          field: 'address',
          header: 'table.column.address',
          customClass: 'width-address'
        },
        { field: 'information', header: 'table.column.information' },
        {
          field: 'companyStatus',
          header: 'table.column.companyStatus',
          datamap: '{"1" : "Holding" , "2" : "Not Holding" }'
        }
      ]);
    }
    this.tableResponse.setRecords(
      this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList
    );
    this.tableResponse.setTotalRecords(
      this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList.length
    );
  }

  public checkTable(): void {
    if (
      this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList.length !==
      0
    ) {
      this.tabRepAgent.formGroup.patchValue({ isTableEmpty: false });
    } else {
      this.tabRepAgent.formGroup.patchValue({ isTableEmpty: true });
    }
  }

  public doAdd(): void {
    const vendorRepAgentHistoryList =
      this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList;
    const todo = 'add';
    const taxNumberList = this.taxNumberList;
    const vendorHistory = this.companyProfileDTO.vendorHistory;
    const vendorType = this.companyProfileDTO.vendorHistory.vendorType;
    const companyNameList = this.companyNameList;
    // tslint:disable-next-line: max-line-length
    this.appPopupService
      .open(
        AppPopupRepAgentComponent,
        {
          vendorRepAgentHistoryList,
          todo,
          vendorHistory,
          vendorType,
          taxNumberList,
          companyNameList
        },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(repAgent => {
        this.vendorRepAgentHistory = new VendorRepresentativeAgentHistory();
        this.vendorRepAgentHistory = repAgent;
        if (repAgent.dataSource === 2) {
          this.vendorRepAgentHistory.vendorChild = null;
          this.vendorRepAgentHistory.vendorParent = null;
          this.vendorRepAgentHistory.companyName = repAgent.companyName;
        } else {
          if (repAgent.companyStatus === 1) {
            this.vendor = repAgent.parentOrChild;
            this.vendorRepAgentHistory.vendorParent = this.vendor;
          } else {
            this.vendor = repAgent.parentOrChild;
            this.vendorRepAgentHistory.vendorChild = this.vendor;
          }
          this.vendorRepAgentHistory.companyName = repAgent.companyName.name;
        }
        this.vendorRepAgentHistory.phone = repAgent.appPhone.number;
        this.vendorRepAgentHistory.phoneRegion = repAgent.appPhone.country;
        this.vendorRepAgentHistory.phoneNumber =
          repAgent.appPhone.country.callingCode + repAgent.appPhone.number;
        this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList.push(
          this.vendorRepAgentHistory
        );
        this.taxNumberList.push(this.vendorRepAgentHistory.taxNumber);
        this.companyNameList.push(this.vendorRepAgentHistory.companyName);
        this.tableResponse.setRecords(
          this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList
        );
        this.tableResponse.setTotalRecords(
          this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList.length
        );
        this.tableResponse.reloadClient();
      });
  }

  public doEdit(
    vendorRepresentativeAgentHistoryEdit: VendorRepresentativeAgentHistory
  ): void {
    const vendorHistory = this.companyProfileDTO.vendorHistory;
    const vendorRepAgentHistoryList = [];
    this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList.forEach(
      vendorRepAgent => {
        if (vendorRepAgent !== vendorRepresentativeAgentHistoryEdit) {
          vendorRepAgentHistoryList.push(vendorRepAgent);
        }
      }
    );
    const todo = 'edit';
    const taxNumberList = [];
    const companyNameList = [];
    this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList.forEach(
      repAgent => {
        if (repAgent !== vendorRepresentativeAgentHistoryEdit) {
          taxNumberList.push(repAgent.taxNumber);
          companyNameList.push(repAgent.companyName);
        }
      }
    );
    const vendorType = this.companyProfileDTO.vendorHistory.vendorType;
    // tslint:disable-next-line: max-line-length
    this.appPopupService
      .open(
        AppPopupRepAgentComponent,
        {
          vendorType,
          vendorRepresentativeAgentHistoryEdit,
          todo,
          vendorHistory,
          vendorRepAgentHistoryList,
          taxNumberList,
          companyNameList
        },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(repAgentEdit => {
        this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList.forEach(
          (repAgent, index) => {
            if (repAgent === vendorRepresentativeAgentHistoryEdit) {
              this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList[
                index
              ] = repAgentEdit;
              if (repAgentEdit.dataSource === 2) {
                this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList[
                  index
                ].vendorChild = null;
                this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList[
                  index
                ].vendorParent = null;
              } else {
                if (repAgentEdit.companyStatus === 1) {
                  this.vendor = repAgentEdit.parentOrChild;
                  this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList[
                    index
                  ].vendorParent = this.vendor;
                } else {
                  this.vendor = repAgentEdit.parentOrChild;
                  this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList[
                    index
                  ].vendorChild = this.vendor;
                }
                this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList[
                  index
                ].companyName = repAgentEdit.companyName.name;
              }
              this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList[
                index
              ].phoneRegion = repAgentEdit.appPhone.country;
              this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList[
                index
              ].phone = repAgentEdit.appPhone.number;
              this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList[
                index
              ].phoneNumber =
                repAgentEdit.appPhone.country.callingCode +
                repAgentEdit.appPhone.number;
              this.taxNumberList[index] = repAgentEdit.taxNumber;
              this.companyNameList[index] = repAgentEdit.companyName;
            }
          }
        );
        this.tableResponse.setRecords(
          this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList
        );
        this.tableResponse.setTotalRecords(
          this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList.length
        );
        this.tableResponse.reloadClient();
      });
  }

  public doView(
    vendorRepresentativeAgentHistoryEdit: VendorRepresentativeAgentHistory
  ): void {
    const todo = 'detail';
    const vendorRepAgentHistoryList =
      this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList;
    const vendorRepAgentList =
      this.tabProfileRepAgentDTO.vendorRepresentativeAgentList;
    const vendorType = this.companyProfileDTO.vendorHistory.vendorType;
    this.appPopupService.open(
      AppPopupRepAgentComponent,
      {
        todo,
        vendorType,
        vendorRepresentativeAgentHistoryEdit,
        vendorRepAgentList,
        vendorRepAgentHistoryList
      },
      { size: 'lg' }
    );
  }

  public doDelete(
    vendorRepresentativeAgentHistoryDelete: VendorRepresentativeAgentHistory
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList.forEach(
            (vendorRepAgent, index) => {
              if (vendorRepAgent === vendorRepresentativeAgentHistoryDelete) {
                this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList.splice(
                  index,
                  1
                );
                this.taxNumberList.splice(index, 1);
                this.companyNameList.splice(index, 1);
              }
            }
          );
          this.tableResponse.page.records =
            this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList;
          this.tableResponse.page.totalRecords =
            this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList.length;
          this.tableResponse.reloadClient();
        }
      });
  }

  public getDataFromRouterParams(): void {
    this.tabRepAgent = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_REP_AGENT);
    this.isView = this.companyProfileService.getTabResponse().isView;
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.todo = this.global.routerParams.get('todo');
    this.companyProfileDTO = this.global.routerParams.get('companyProfileDTO');
  }

  public buildFormGroup(): void {
    this.tabRepAgent.formGroup = this.formBuilder.group({
      isTableEmpty: [null],
      changeTable: [null]
    });
  }

  public getAndSetVendorToFormGroup(): void {
    this.tabProfileRepAgentDTO.vendorRepresentativeAgentHistoryList.forEach(
      vendorRepAgent => {
        this.taxNumberList.push(vendorRepAgent.taxNumber);
        this.companyNameList.push(vendorRepAgent.companyName);
      }
    );
    this.setStateReady();
  }
}
