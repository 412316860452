<app-popup
  header="aanwijzing.popup.inviteParticipant.title"
  [isLoading]="formLoading"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <app-select
      formControlName="participantList"
      [optionList]="participantOptionList"
      selectAllText="{{ 'aanwijzing.inviteParticipant.selectAll' | translate }}"
      [isMultiple]="true"
      [isShowInputControl]="true"
      [search]="true"
    >
      <ng-template #headerFilterGroup>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label">
            {{ 'aanwijzing.form.role' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select
              size="XL"
              formControlName="participantRole"
              [optionList]="participantRoleOptionList"
            >
            </app-dropdown-select>
          </div>
        </div>

        <div
          *ngIf="
            formGroup.value.participantRole &&
            formGroup.value.participantRole.code ===
              this.global.appConstant.pm.PARTICIPANT_ROLE_INTERNAL
          "
          class="form-group row text-sm-left"
        >
          <label class="col-sm-3 control-label">
            {{ 'aanwijzing.form.department' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-combo-box-tree
              size="XL"
              formControlName="department"
              stringUrl="/organization"
            >
            </app-combo-box-tree>
          </div>
        </div>

        <div
          *ngIf="
            formGroup.value.participantRole &&
            formGroup.value.participantRole.code ===
              this.global.appConstant.pm.PARTICIPANT_ROLE_VENDOR
          "
          class="form-group row text-sm-left"
        >
          <label class="col-sm-3 control-label">
            {{ 'aanwijzing.form.company' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select
              size="XL"
              formControlName="vendor"
              [optionList]="vendorOptionList"
            >
            </app-dropdown-select>
          </div>
        </div>

        <div class="button-group button-group-right mt-5">
          <app-button
            mode="BYPASS"
            color="LIGHT"
            [outline]="false"
            (onClick)="doReset()"
          >
            {{ 'app.button.reset' | translate }}
          </app-button>
          <app-button
            mode="BYPASS"
            [outline]="true"
            color="LIGHT"
            (onClick)="doApply()"
          >
            {{ 'app.button.apply' | translate }}
          </app-button>
        </div>
      </ng-template>

      <ng-template #headerButtons>
        <app-button color="LIGHT" (onClick)="doAddNewParticipant()">
          <em class="pir pi-plus-circle"></em>
          {{ 'aanwijzing.button.newParticipant' | translate }}
        </app-button>
      </ng-template>
      <ng-template #content let-data>
        <!-- <div *ngIf="!data.user || (data.user && !data.user.imgFile)" class="pvc pv-user-circle text-secondary"></div>
                <div *ngIf="data.user?.imgFile" class="pvc pv-user-circle text-secondary"></div> -->

        <div style="margin: 0 auto; display: flex; position: relative">
          <div
            style="
              width: 50px;
              height: 50px;
              overflow: hidden;
              border-radius: 50px;
            "
            class="mr-3"
          >
            <img
              style="width: 100%"
              [src]="
                data.user
                  ? 'assets/' + data.user?.imgFile?.fileName
                  : global.appConstant.core.URL_DEFAULT_PROFILE_IMAGE
              "
              alt="user image"
              [default]="global.appConstant.core.URL_DEFAULT_PROFILE_IMAGE"
            />
          </div>

          <div class="d-inline-block">
            <ng-container
              *ngIf="
                data.participantRole?.code ===
                global.appConstant.pm.PARTICIPANT_ROLE_VENDOR
              "
            >
              <ng-container *ngIf="data.vendorPIC">
                <span class="d-inline-flex">
                  <h5 class="mr-1">{{ data.vendorPIC.name }}</h5>
                  <i class="text-secondary">( {{ data.vendorPIC.email }} )</i>
                </span>
              </ng-container>
              <ng-container *ngIf="data.user">
                <span class="d-inline-flex">
                  <h5 class="mr-1">{{ data.user.name }}</h5>
                  <i class="text-secondary">( {{ data.user.email }} )</i>
                </span>
              </ng-container>
              <ng-container *ngIf="!data.user && !data.vendorPIC">
                <span class="d-inline-flex">
                  <h5 class="mr-1">{{ data.email }}</h5>
                  <!-- <i class="text-secondary">( {{ data.email }} )</i> -->
                </span>
              </ng-container>

              <p class="text-secondary">{{
                data.vendor?.name ||
                  data.companyName ||
                  data.vendorPIC?.vendor?.name
              }}</p>
            </ng-container>
            <ng-container
              *ngIf="
                data.participantRole?.code ===
                global.appConstant.pm.PARTICIPANT_ROLE_INTERNAL
              "
            >
              <span class="d-inline-flex">
                <h5 class="mr-1">{{
                  data.user ? data.user?.name : data.name
                }}</h5>
                <i class="text-secondary"
                  >( {{ data.user ? data.user?.email : data.email }} )</i
                >
              </span>
              <p class="text-secondary">{{
                data.user
                  ? data.user?.organization.name
                  : data.organization.name
              }}</p>
            </ng-container>
            <ng-container
              *ngIf="
                data.participantRole?.code ===
                global.appConstant.pm.PARTICIPANT_ROLE_OTHERS
              "
            >
              <h5 class="mr-1">{{ data.email }}</h5>
              <p class="text-secondary">{{
                getTranslateKey(data.participantRole) | translate
              }}</p>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </app-select>

    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="LIGHT" (onClick)="activeModal.close(true)">
          {{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button (onClick)="doInvite()">
          {{ 'aanwijzing.button.invite' | translate }}</app-button
        >
      </div>
    </ng-template>
  </form>
</app-popup>
