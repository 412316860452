<app-popup
  header="executor.popup.user-executor-title"
  [isLoading]="formLoading"
>
  <small class="text-danger" *ngIf="isNoUserSelected">
    {{ 'app.validation.chooseOneData' | translate }}
  </small>
  <app-table-xx
    [model]="tableResponseUser"
    [isMultipleSelect]="true"
    [isShowCheckBox]="true"
    (onChange)="doValidate()"
  >
  </app-table-xx>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button
        color="SECONDARY"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button
        color="PRIMARY"
        (onClick)="doAddUserExecutor()"
        [disabled]="formLoading"
        >{{ 'app.button.add' | translate }}</app-button
      >
    </div>
  </ng-template>
</app-popup>
