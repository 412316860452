import { Injectable } from '@angular/core';
import { ToastService } from '../components/app-toast/app-toast.service';
import { ToastOptions } from '../components/app-toast/interface/toast-options';
import { ToastPositionType } from '../components/app-toast/type/toast-positon-type';
import { Global } from '../global/global';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  constructor(private toastService: ToastService, public global: Global) {}
  public showInfo(
    msg: string,
    width?: number,
    toastOptions?: ToastOptions
  ): void {
    const options: ToastOptions = toastOptions || {};
    options.color = 'INFO';
    options.message = msg;
    if (width) {
      options.width = width + 'px';
    }
    this.createSnackBar(options);
  }

  public showSuccess(
    msg: string,
    width?: number,
    toastOptions?: ToastOptions
  ): void {
    const options: ToastOptions = toastOptions || {};
    options.color = 'SUCCESS';
    options.message = msg;
    if (width) {
      options.width = width + 'px';
    }
    this.createSnackBar(options);
  }

  public showWarning(
    msg: string,
    width?: number,
    toastOptions?: ToastOptions
  ): void {
    const options: ToastOptions = toastOptions || {};
    options.color = 'WARNING';
    options.message = msg;
    if (width) {
      options.width = width + 'px';
    }
    this.createSnackBar(options);
  }

  public showError(
    msg: string,
    width?: number,
    toastOptions?: ToastOptions
  ): void {
    const options: ToastOptions = toastOptions || {};
    options.color = 'DANGER';
    options.message = msg;
    if (width) {
      options.width = width + 'px';
    }
    this.createSnackBar(options);
  }

  private createSnackBar(options: ToastOptions): void {
    options.position = options.position
      ? options.position
      : (this.global.config.parameterModel
          .snackbarDefaultPosition as ToastPositionType);
    options.isShowCloseButton =
      options.isShowCloseButton === true ? true : false;
    options.isShowIcon = options.isShowIcon === true ? true : false;
    options.isShowIndicator = options.isShowIndicator ? true : false;
    options.customClass = 'snackbar';
    this.toastService.show(options);
  }
}
