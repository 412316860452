import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PrItem } from '../../../bean/pr-item';
import { AppPopupService } from '../app-popup.service';
import { AppPopupChoosePrComponent } from './app-popup-choose-pr.component';

@Injectable()
export class AppPopupChoosePrService {
  constructor(public appPopupService: AppPopupService) {}
  public open(prItems?: PrItem[], baseModuleCode?: String): Observable<any> {
    return this.appPopupService.open(
      AppPopupChoosePrComponent,
      {
        prItems, baseModuleCode
      },
      { size: 'xl' }
    );
  }
}
