import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DirectivesModule } from '../../directives/directives.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppDropDownModule } from '../app-drop-down/app-drop-down.module';
import { AppDatePickerXComponent } from './app-date-picker-x.component';
import { ResolveDisabledTimePipe } from './pipe/resolve-disabled-time.pipe';
import { ResolveDatePickerClassNamePipe } from './resolve-date-picker-class-name.pipe';

@NgModule({
  imports: [
    VendorModule,
    AppDropDownModule,
    PerfectScrollbarModule,
    DirectivesModule
  ],
  declarations: [
    AppDatePickerXComponent,
    ResolveDatePickerClassNamePipe,
    ResolveDisabledTimePipe
  ],
  exports: [AppDatePickerXComponent]
})
export class AppDatePickerXModule {}
