<div class="app-month-year-picker p-0" [ngClass]="elementWidthClassName">
  <label
    type="text"
    class="form-control"
    [ngClass]="[
      elementHeightClassName,
      isInvalid && !formControl.disabled ? 'is-invalid' : '',
      isValid && !formControl.disabled ? 'is-valid' : '',
      formControl.disabled ? 'disabled' : ''
    ]"
    (click)="handleClickAppMonthYearPickerInput($event)"
  >
    {{ model.currentValue }}
  </label>
  <div
    class="app-month-year-picker-popup-wrapper"
    #appMonthYearPickerPopupWrapper
    (click)="handleClickMonthYearPickerPopupWrapper($event)"
  >
    <div class="popup-header">
      <em class="fas fa-chevron-left" (click)="doDecrement()"></em>
      <span class="text" (click)="doShowYearRange()">
        {{
          model.isShowYearRange
            ? model.startYearRange + '-' + model.endYearRange
            : model.currentYear
        }}
      </span>
      <em class="fas fa-chevron-right" (click)="doIncrement()"></em>
    </div>
    <div class="popup-body">
      <div
        class="app-month-picker-item"
        [ngClass]="[
          model.isShowYearRange ? 'year-view' : 'month-view',
          model.selectedMonth === item.value + 1 ? 'active' : ''
        ]"
        *ngFor="let item of monthYearPickerContent"
      >
        <span class="text" (click)="doSelectValue(item.value)">{{
          item.text
        }}</span>
      </div>
    </div>
  </div>
</div>
<small class="text-danger" *ngIf="isInvalid">
  {{ errors.message }}
</small>
