<ng-container *ngIf="ISVIEW; then isViewTmpl; else isNotViewTmpl">
</ng-container>
<ng-template #isNotViewTmpl>
  <div
    class="app-text-tagging form-control"
    [ngClass]="[
      elementWidthClassName,
      elementHeightClassName,
      isInvalid ? 'is-invalid' : '',
      isValid ? 'is-valid' : '',
      disabled ? 'disabled' : '',
      ISVIEW ? 'is-view' : ''
    ]"
    (click)="handleClick()"
    (keyup)="onKeyUp($event)"
    (keydown)="onKeyDown($event)"
  >
    <app-tagging [formControl]="formControl">
      <ul class="d-inline-block">
        <li class="d-inline-block">
          <input
            [attr.id]="generatedId"
            type="text"
            [placeholder]="!formControl.value ? (placeholder | translate) : ''"
            (input)="handleInput($event)"
            (keyup)="handleKeyUp($event)"
            (focusout)="handleFocusOut()"
          />
        </li>
      </ul>
    </app-tagging>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
  <small class="text-danger" *ngIf="isInvalid">
    {{ errors.message }}
  </small>
</ng-template>
<ng-template #isViewTmpl>
  <div class="d-inline-block position-relative">
    <app-tagging [formControl]="formControl"></app-tagging>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
