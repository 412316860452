import { BaseEntity } from '../base/base-entity';
import { Contract } from '../bean/contract';
import { File } from '../bean/file';
import { User } from '../bean/user';
import { WorkflowModelPrcs } from '../bean/workflow-model-prcs';

export class ContractDocEntity extends BaseEntity {
  contract: Contract;
  file: File;
  workflowModelPrcs: WorkflowModelPrcs;
  processedByUser: User;
  isFinal: boolean;
  isSigned: boolean;
  description: string;
}
