export class TabElement {
  public tabContentElement: Element;
  public tabNavElement: Element;
  constructor(tabElement: Element) {
    this.tabNavElement = tabElement.children.item(0).children.item(0);
    this.tabContentElement = tabElement.children.item(0).children.item(1);
  }

  public addTabContent(tabItemContentElement: Element): void {
    this.tabContentElement.appendChild(tabItemContentElement);
  }

  public removeTabContent(tabItemContentElement: Element): void {
    this.tabContentElement.removeChild(tabItemContentElement);
  }
}
