import { DueDiligenceInterview } from 'src/app/core/bean/due-diligence-interview';
import { DueDiligenceRealization } from 'src/app/core/bean/due-diligence-realization';
import { DueDiligenceSchedule } from 'src/app/core/bean/due-diligence-schedule';
import { DueDiligenceTemplateInterview } from 'src/app/core/bean/due-diligence-template-interview';
import { VendorHistory } from 'src/app/core/bean/vendor-history';

export class InterviewRequest {
    vendorHistory: VendorHistory = new VendorHistory();
    dueDiligenceScheduleInterview: DueDiligenceSchedule = new DueDiligenceSchedule();
    interviewQuestionList: DueDiligenceTemplateInterview[] = [];
    dueDiligenceRealizationInterview: DueDiligenceRealization;
    dueDiligenceInterviewList: DueDiligenceInterview[] = [];
}
