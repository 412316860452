import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { ContractReviewer } from 'src/app/core/bean/contract-reviewer';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { ArrayUtils } from 'src/app/core/utils';
import { Validators } from 'src/app/core/validators';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { User } from '../../core/bean/user';
import { OptionListModel } from '../../core/model/option-list-model';
import { AppPopupNewContractReviewerComponent } from './app-popup-new-contract-reviewer.component';

@Component({
  templateUrl: './app-popup-contract-reviewer.component.html'
})
export class AppPopupContractReviewerComponent extends BaseComponentComponent {
  @Input() public existingContractReviewerList: ContractReviewer[];
  @Input() public addContractReviewerList: ContractReviewer[];
  @Input() public user: User;
  @Output() public onChange: EventEmitter<ContractReviewer[]> = new EventEmitter();

  public contractReviewerOptionList: OptionListModel<ContractReviewer> = new OptionListModel(false);
  public optionTemp: Array<ContractReviewer> = [];
  public emailList: string[] = [];

  constructor(public ngbActiveModal: NgbActiveModal,
    public appPopupService: AppPopupService) {
    super('contract-draft');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      contractReviewerList: [null, Validators.required()],
      organization: [null]
    });
  }

  public setFormGroup(): void {
    if(this.existingContractReviewerList.length > 0){
      this.formGroup.patchValue({ contractReviewerList: this.existingContractReviewerList });
      this.existingContractReviewerList.forEach(
        contractReviewer => {
          this.addContractReviewerList.push(contractReviewer);
        }
      );
    }
    const indexOfUser = this.addContractReviewerList.findIndex(r => r.reviewByUser?.id === this.user.id);
    this.addContractReviewerList.splice(indexOfUser, 1);
    this.contractReviewerOptionList.setRequestValues([]);
    this.contractReviewerOptionList.searchFieldList = [
      'organization.name',
      'reviewByUser.name',
      'name',
      'position',
      'email'
    ];
    this.contractReviewerOptionList.setSortBy("userType");
    this.optionTemp = ArrayUtils.sortArray(
      this.addContractReviewerList,
      'userType',
      'DESC'
    );
    this.contractReviewerOptionList.setRequestValues(this.optionTemp);
    this.addContractReviewerList.forEach(
      contractReviewer => {
        this.emailList.push(contractReviewer.email);
      }
    );
    this.setStateReady();
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.organization) {
      const organizationList = this.formGroup.value.organization;
      const userFilteredList = this.addContractReviewerList.filter(
        u =>
          u.organization.id === organizationList[organizationList.length - 1].id
      );
      this.contractReviewerOptionList.setRequestValues(userFilteredList);
    } else {
      this.contractReviewerOptionList.setRequestValues(this.addContractReviewerList);
    }
  }

  public doAddNewReviewer(): void {
    this.appPopupService
      .open(
        AppPopupNewContractReviewerComponent,
        { emailList: this.emailList },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(data => {
        const contractCollabAccess: ContractReviewer =
          new ContractReviewer();
        contractCollabAccess.userType = 2;
        contractCollabAccess.email = data.email;
        contractCollabAccess.name = data.name;
        contractCollabAccess.position = data.position;
        this.emailList.push(data.email);
        this.addContractReviewerList.push(
          contractCollabAccess
        );
        this.contractReviewerOptionList.reset();
        this.contractReviewerOptionList.searchFieldList = [
          'organization.name',
          'reviewByUser.name',
          'name',
          'position',
          'email'
        ];
        this.contractReviewerOptionList.setSortBy("userType");
        this.optionTemp = ArrayUtils.sortArray(
          this.addContractReviewerList,
          'userType',
          'DESC'
        );
        this.contractReviewerOptionList.setRequestValues(this.optionTemp);
      });
  }

  public doCancel(): void {
    this.ngbActiveModal.close();
  }

  public doAdd(): void {
    this.existingContractReviewerList = this.formGroup.get('contractReviewerList').value;
    if (this.existingContractReviewerList && this.existingContractReviewerList.length !== 0) {
      this.onChange.emit(this.existingContractReviewerList);
      this.ngbActiveModal.close();
    } else {
      this.global.alertService.showInfo(
        this.global.translateService.instant(
          'contract-draft.validation.chooseUser'
        ),
        '.main-row-popup-reviewer'
      );
    }
  }

  public doDelete(): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const contractReviewerList = Array.from(
            this.formGroup.get('contractReviewerList').value
          );
          const contractReviewerOptionList =
            this.contractReviewerOptionList.getRequestValues();
            contractReviewerList.forEach((contractReviewer: ContractReviewer) => {
            const index = contractReviewerOptionList.findIndex(
              reviewer => reviewer.email === contractReviewer.email
            );
            if (index !== -1) {
              contractReviewerOptionList.splice(index, 1);
            }
          });
          this.contractReviewerOptionList.setRequestValues(
            contractReviewerOptionList
          );
          this.formGroup.patchValue({
            contractShipping: null
          });
          this.formGroup.markAsDirty();
        }
      });
  }
}
