<app-popup header="tab-profile-verification.popup.delegation">
  <div class="row main-row-delegation">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'tab-profile-verification.popup.organization' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              size="LG"
              formControlName="organizationList"
              stringUrl="/organization"
              isLazy="true"
              header="tab-profile-verification.popup.organization"
            >
            </app-text-tree>
          </div>
        </div>

        <ng-template #modalFooter let-activeModal>
          <div class="button-group button-group-center">
            <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
              'app.button.cancel' | translate
            }}</app-button>
            <app-button color="PRIMARY" (onClick)="doSave()">{{
              'app.button.save' | translate
            }}</app-button>
          </div>
        </ng-template>
      </form>
    </div>
  </div>
</app-popup>
