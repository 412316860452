<div class="row">
  <div style="width: 20%">
    <app-card [isLoading]="formLoading">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <label class="col pl-0 control-label"
          *ngIf="!vendorId">{{
          'app-catalog-info.form.category' | translate
        }}</label>
        <app-combo-box-tree
          *ngIf="!vendorId"
          size="XL"
          formControlName="itemCategory"
          stringUrl="/item-category"
          header="app-catalog-info.form.category"
          placeholder="{{
            'app-catalog-info.filter.placeholder.selectCategory' | translate
          }}"
        ></app-combo-box-tree>
        <br />

        <!-- <label class="col pl-0 control-label">{{
          'app-catalog-info.form.location' | translate
        }}</label>
        <app-check-box
          size="XL"
          position="VERTICAL"
          formControlName="locationList"
        ></app-check-box> -->
        <!-- <h6
          class="cursor-pointer mt-2 text-primary"
          [ngStyle]="{ 'font-weight': '500' }"
          (click)="doChooseLocation()"
        >
          {{ 'app-catalog-info.filter.form.viewAll' | translate }}
        </h6>
        <br /> -->

        <label class="col pl-0 control-label">{{
          'app-catalog-info.form.price' | translate
        }}</label>
        <app-currency
          size="XL"
          placeholder="{{
            'app-catalog-info.filter.placeholder.minimumPrice' | translate
          }}"
          [optionList]="currencyOptionList"
          formControlName="minimumPrice"
          (onChange)="validatePrice()"
        ></app-currency>
        <br />
        <app-currency
          placeholder="{{
            'app-catalog-info.filter.placeholder.maximumPrice' | translate
          }}"
          size="XL"
          [optionList]="currencyOptionList"
          formControlName="maximumPrice"
          (onChange)="validatePrice()"
        ></app-currency>
        <br />

        <label class="col pl-0 control-label">{{
          'app-catalog-info.form.catalogType' | translate
        }}</label>
        <app-dropdown-select
          size="XL"
          [optionList]="catalogTypeOptionList"
          formControlName="catalogType"
          placeholder=" {{
            'app-catalog-info.filter.placeholder.all' | translate
          }}"
          type="COMBOBOX"
        >
          <!-- [disabled]="catalogTypeIsFiltered" -->
          <ng-template #option let-option>
            <ng-container
              *ngTemplateOutlet="customContent; context: { $implicit: option }"
            >
            </ng-container> </ng-template
        ></app-dropdown-select>
        <br />

        <app-button
          minWidth="100%"
          (onClick)="doChooseSpecification()"
          color="LIGHT"
          [outline]="true"
        >
          {{
            'app-catalog-info.filter.button.spesificationFilter' | translate
          }}</app-button
        >
        <br />
        <br />

        <app-button minWidth="100%" (onClick)="doReset()">
          {{
            'app-catalog-info.filter.button.resetFilter' | translate
          }}</app-button
        >
      </form>
    </app-card>
  </div>

  <div class="col">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <div class="d-inline-block w-100">
        <div class="d-flex justify-content-between">
          <div class="custom-search custom-search-lg w-100">
            <em class="pir pi-search"></em>
            <input
              type="text"
              class="form-control"
              [attr.placeholder]="
                'app-catalog-info.filter.placeholder.searchProduct' | translate
              "
              formControlName="keyword"
              aria-label="search"
            />
            <em class="pir pi-times" (click)="doResetKeyword()"></em>
          </div>
          <div class="d-flex">
            <span class="mt-2 mr-3 ml-4 text-nowrap">{{
              'app-catalog-info.filter.sortBy' | translate
            }}</span>
            <div [ngStyle]="{ 'min-width': '200px' }">
              <app-dropdown-select
                size="XL"
                [optionList]="sortOptionList"
                formControlName="sortBy"
              ></app-dropdown-select>
            </div>
          </div>
        </div>
      </div>
      <ng-container>
        <app-tagging
          size="XL"
          formControlName="valueCatalogSpecificationList"
          (onChange)="handleAppTaggingChange($event)"
          [showLimitValue]="8"
        >
        </app-tagging>
      </ng-container>
    </form>
    <ng-template #customContent let-option>
      <span maxLine *ngIf="option.translationKey">
        {{
          (option.translationKey.module.code | lowercase) +
            '.' +
            option.translationKey.key | translate
        }}
      </span>
      <span maxLine *ngIf="!option.translationKey">
        {{ option.name }}
      </span>
    </ng-template>

    <app-catalog-item-x
      *ngIf="!formLoading"
      [customData]="customData"
      [itemPath]="itemPath"
    ></app-catalog-item-x>
  </div>
</div>
