import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppItemRequestModule } from '../../core/components/app-item-request/app-item-request.module';
import { AppProcurementInfoModule } from '../../core/components/app-procurement-info/app-procurement-info.module';
import { AppWorkflowStepInfoModule } from '../../core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { DocRetrievalResponseEditComponent } from './doc-retrieval-response-edit.component';
import { DocRetrievalResponseComponent } from './doc-retrieval-response.component';

export const routes = [
  {
    path: '',
    component: DocRetrievalResponseComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'detail',
    component: DocRetrievalResponseEditComponent,
    data: { breadcrumb: 'doc-retrieval-response.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: DocRetrievalResponseEditComponent,
    data: { breadcrumb: 'doc-retrieval-response.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    AppWorkflowStepInfoModule,
    AppProcurementInfoModule,
    AppItemRequestModule,
    AppBadgeCatalogModule
  ],
  declarations: [
    DocRetrievalResponseComponent,
    DocRetrievalResponseEditComponent
  ],
  providers: [],
  entryComponents: [],
  exports: []
})
export class DocRetrievalResponseModule {}
