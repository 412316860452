import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class LoaderService {
  public preLoaderElement: HTMLElement;
  private isShow: boolean;
  public showInfo(message: string, isShow = false): void {
    this.doSetPreLoaderElement(isShow);
    const preLoaderTextElement = document.getElementById('preloader-text');
    if (preLoaderTextElement.parentElement.classList.contains('text-danger')) {
      preLoaderTextElement.parentElement.classList.remove('text-danger');
    }
    preLoaderTextElement.textContent = message;
  }

  public showError(message: string): void {
    this.doSetPreLoaderElement();
    const preLoaderTextElement = document.getElementById('preloader-text');
    preLoaderTextElement.parentElement.classList.add('text-danger');
    preLoaderTextElement.textContent = message;
  }

  public close(): void {
    if (this.preLoaderElement) {
      this.preLoaderElement.style.display = 'none';
      this.isShow = false;
    }
  }

  private doSetPreLoaderElement(isShow = false): void {
    if (!this.preLoaderElement) {
      this.preLoaderElement = document.getElementById('preloader');
    } else {
      if (isShow || this.isShow) {
        this.preLoaderElement.style.display = 'block';
        this.isShow = isShow;
      }
    }
  }
}
