import { EventEmitter } from '@angular/core';
import { PlanComparisonOptionModel } from './plan-comparison-option-model';
export class PlanComparisonModel {
  public requestBodyChanges: EventEmitter<void> = new EventEmitter();
  public reloadChanges: EventEmitter<void> = new EventEmitter();
  constructor(
    public stringUrl?: string,
    public requestBody?: object,
    public options?: PlanComparisonOptionModel
  ) {}

  public setRequestBody(requestBody: object): void {
    this.requestBody = requestBody;
    this.requestBodyChanges.emit();
  }

  public reload(): void {
    this.reloadChanges.emit();
  }
}
