import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppLoadService } from './core/services/app-load.service';

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/translator/getlang/', '');
}

export function initApp(appLoadService: AppLoadService): object {
  return async () => await appLoadService.initializeApp();
}
