import { CurrencyPipe, DatePipe } from '@angular/common';
import { EventEmitter } from '@angular/core';
import { Global } from '../../../global/global';
import { ServiceLocator } from '../../../services/service-locator';
import { FieldFormatEnum } from './field-format.enum';
import { TableFieldModel } from './table-field-model';
import { TablePageModel } from './table-page-model';
import { TablePaginationModel } from './table-pagination-model';
import { TableRequestModel } from './table-request-model';
export class TableResponseModel<T> {
  public datePipe: DatePipe = ServiceLocator.injector.get(DatePipe);
  public currencyPipe: CurrencyPipe = ServiceLocator.injector.get(CurrencyPipe);
  public global: Global = ServiceLocator.injector.get(Global);
  moduleCode: string;
  page: TablePageModel<T> = new TablePageModel();
  pagination: TablePaginationModel = new TablePaginationModel();
  request: TableRequestModel;
  columns: Array<TableFieldModel> = new Array();
  records: Array<T> = new Array();
  sortOrder: string;
  selectFilterOption: Array<number>;
  reloadChanges: EventEmitter<boolean> = new EventEmitter();
  isLoading: boolean;
  isWaitingFromServer: boolean;
  loading: boolean; // temporary should be deleted

  public arrayDateFormat = [
    this.global.config.parameterModel.dateFormatShort,
    this.global.config.parameterModel.datetimeFormatShort,
    this.global.config.parameterModel.dateFormatLong,
    this.global.config.parameterModel.datetimeFormatLong
  ];

  constructor(
    moduleCode: string,
    columns: Array<TableFieldModel>,
    selectFilterOption: Array<number> = [10, 25, 50, 100]
  ) {
    this.moduleCode = moduleCode;
    this.columns = columns;
    this.selectFilterOption = selectFilterOption;
    this.request = new TableRequestModel(columns);
  }

  reload(): void {
    this.reloadChanges.emit(true);
  }

  reloadClient(): void {
    this.setStateLoading();
    this.copyPageRecordsToRecords();
    let records = Array.from(this.records);
    records = this.sortRecords(records);
    records = this.searchRecords(records);
    const pageRecords = Array.from(records).splice(
      (this.pagination.page - 1) * this.pagination.perPage,
      this.pagination.perPage
    );
    this.page.setRecords(pageRecords);
    this.setTotalRecords(records.length);
    this.reloadChanges.emit(true);
  }

  setPerPage(perPage: number): void {
    this.pagination.setPerPage(perPage);
    this.request.rows = perPage;
  }

  setSearchText(searchText: string): void {
    this.request.setSearchText(searchText);
  }

  setCurrentpage(currentPage: number): void {
    this.pagination.setCurrentPage(currentPage);
    const first = (currentPage - 1) * this.pagination.perPage;
    this.request.setFirst(first);
  }

  setSortField(sortField: string): void {
    if (sortField === this.request.sortField && this.sortOrder === 'DESC') {
      this.request.sortField = null;
      this.sortOrder = '';
    } else {
      this.sortOrder =
        this.sortOrder === 'ASC'
          ? this.request.sortField === sortField
            ? 'DESC'
            : 'ASC'
          : 'ASC';
      this.request.sortField = sortField;
      this.request.sortOrder =
        this.sortOrder === 'DESC'
          ? this.global.appConstant.core.SORT_ORDER_ASC
          : this.global.appConstant.core.SORT_ORDER_DESC;
    }
  }

  setRecords(records: Array<T>): void {
    this.records = records;
  }

  setTotalRecords(totalRecords: number): void {
    this.page.setTotalRecords(totalRecords);
    this.pagination.setTotalRecords(totalRecords);
  }

  setCustomData(customData: any): void {
    let newCustomData: any;
    if (!customData || customData === null) {
      newCustomData = null;
    } else {
      newCustomData =
        typeof customData === 'object' && Object.values(customData).length === 0
          ? null
          : typeof customData === 'string'
          ? customData
          : JSON.stringify(customData);
    }
    this.request.customData = newCustomData;
  }

  resetCustomData(): void {
    this.request.customData = null;
  }

  resetDataMap(): void {
    this.request.dataMap.clear();
  }

  resetRecords(): void {
    this.records.splice(0);
    this.page.records.splice(0);
  }

  setDataMap(data: object): void {
    Object.keys(data).forEach((key: string) => {
      this.request.dataMap.set(key, data[key]);
    });
  }

  setStateLoading(): void {
    this.isLoading = true;
  }

  setStateReady(): void {
    this.isLoading = false;
    this.isWaitingFromServer = false;
  }

  setStateWatingFromServer(): void {
    this.isWaitingFromServer = true;
  }

  public sortRecords(records: Array<T>): Array<T> {
    if (this.request.sortField) {
      records.sort((recordA: T, recordB: T) => {
        const valueA =
          this.resolveColumnValue(recordA, this.request.sortField) || '';
        const valueB =
          this.resolveColumnValue(recordB, this.request.sortField) || '';
        if (valueA > valueB) {
          return this.sortOrder === 'ASC'
            ? this.global.appConstant.core.SORT_ORDER_ASC
            : this.global.appConstant.core.SORT_ORDER_DESC;
        }
        if (valueA < valueB) {
          return this.sortOrder === 'ASC'
            ? this.global.appConstant.core.SORT_ORDER_ASC
            : this.global.appConstant.core.SORT_ORDER_DESC;
        }
        return 0;
      });
    }
    return records;
  }

  public searchRecords(records: Array<T>): Array<T> {
    if (this.request.globalFilter) {
      records = records.filter((record: any) => {
        let isMatch = false;
        this.columns.forEach((column: TableFieldModel) => {
          if (!isMatch) {
            const value: any = this.resolveColumnValue(
              record,
              column.field,
              column.datamap,
              column.format
            );
            if (
              !isMatch &&
              value &&
              (typeof value === 'number' ? value.toString() : value)
                .toLowerCase()
                .includes(this.request.globalFilter.toLowerCase())
            ) {
              isMatch = true;
            }
          }
        });
        if (isMatch) {
          return record;
        }
      });
    }
    return records;
  }

  public resolveColumnValue(
    record: any,
    field: string,
    dataMap?: string,
    format?: FieldFormatEnum
  ): string {
    const fieldSplit = field.split('.');
    let value = record;
    while (fieldSplit.length > 0) {
      const firstField = fieldSplit.shift();
      if (value[firstField] === null || value[firstField] === undefined) {
        fieldSplit.splice(0);
        value = null;
      } else {
        value = value[firstField];
      }
    }
    if (dataMap) {
      value = JSON.parse(dataMap)[value];
    }

    if (typeof format !== 'undefined') {
      value = this.resolveColumnValueWithFormat(value, format);
    }

    return value;
  }

  public resolveColumnValueWithFormat(
    value: any,
    format: FieldFormatEnum
  ): any {
    return format < 4
      ? this.datePipe.transform(value, this.arrayDateFormat[format])
      : value;
  }

  public copyPageRecordsToRecords(): void {
    // start: handle old version only.
    if (
      this.records.length === 0 &&
      this.page.records &&
      this.page.records.length > 0
    ) {
      this.records = this.page.records;
      this.setTotalRecords(this.records.length);
    }
  }

  public removeAt(index: number): void {
    this.records.splice(index, 1);
    this.page.records.splice(index, 1);
    this.reloadClient();
  }
}
