<button
  *ngIf="isWrite"
  class="btn"
  [ngClass]="[
    BTN_COLOR[color] ? BTN_COLOR[color] : '',
    BUTTON_SIZE[size] ? BUTTON_SIZE[size] : '',
    rounded ? 'rounded' : '',
    outline ? 'btn-outline' : '',
    borderLess ? 'border-0 px-0' : ''
  ]"
  [ngStyle]="{ 'min-width': minWidth ? minWidth : 'initial' }"
  [attr.type]="type"
  [disabled]="disabled"
  role="button"
  (click)="doClick($event)"
  (keyup)="onKeyUp($event)"
  (keydown)="onKeyDown($event)"
  (mousedown)="handleMouseDown($event)"
>
  <ng-content></ng-content>
</button>
<em
  *ngIf="isWrite && tooltip !== undefined"
  class="fas fa-info-circle"
  placement="top"
  [ngbTooltip]="tooltip"
  container="body"
></em>
