import { NgModule } from '@angular/core';
import { PipesModule } from '../../../pipe/pipe.module';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppComboBoxTreeModule } from '../../app-combo-box-tree/app-combo-box-tree.module';
import { AppSelectModule } from '../../app-select/app-select.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupAddUserComponent } from './app-popup-add-user.component';
import { AppPopupAddUserService } from './app-popup-add-user.service';
@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppSelectModule,
    AppComboBoxTreeModule,
    AppButtonModule,
    PipesModule
  ],
  declarations: [AppPopupAddUserComponent],
  entryComponents: [AppPopupAddUserComponent],
  providers: [AppPopupAddUserService],
  exports: [AppPopupAddUserComponent]
})
export class AppPopupAddUserModule {}
