import { createTableComponent } from '../../helper';
import { TablePlugin } from '../../interface/table-plugin';
import { TablePluginData } from '../../interface/table-plugin-data';
import { TableToggleSwitchPluginComponent } from './toggle-switch-plugin.component';

export const tableToggleSwitchPlugin: TablePlugin = {
  name: 'toggle-switch',
  before: (tablePluginData: TablePluginData): HTMLElement => {
    tablePluginData.element.addEventListener('click', (event: PointerEvent) =>
      event.stopPropagation()
    );
    return createTableComponent(
      tablePluginData,
      TableToggleSwitchPluginComponent,
      'toggle-switch-plugin-content'
    );
  }
};
