import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { FieldFormatEnum } from '../../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../../core/components/app-table/model/table-response-model';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { HomeSlide } from './../../../core/bean/home-slide';
@Component({
  templateUrl: './home-slide.component.html'
})
export class HomeSlideComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<HomeSlide>;
  constructor(translateService: TranslateService) {
    super('home-slide', translateService);
  }
  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    const active = this.translateService.instant('app.status.active');
    const inActive = this.translateService.instant('app.status.inActive');
    const dataMap = { false: inActive, true: active };
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'imgFile.uploadedFileName',
        header: 'table.column.imgFile',
        format: FieldFormatEnum.Img,
        urlImage: '/home-slide'
      },
      { field: 'text', header: 'table.column.text' },
      { field: 'referenceLink', header: 'table.column.referenceLink' },
      {
        field: 'isActive',
        header: 'table.column.isActive',
        datamap: JSON.stringify(dataMap)
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/content/home-slide/add']);
  }

  public doEdit(homeSlide: HomeSlide): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('homeSlideId', homeSlide.id);
    this.router.navigate(['/pages/content/home-slide/edit']);
  }

  public doDelete(homeSlide: HomeSlide): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<HomeSlide>>('/home-slide/delete', homeSlide)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/content']);
  }
}
