import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ContractParameter } from '../../core/bean/contract-parameter';
import { ValueType } from '../../core/bean/value-type';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';

@Component({
  templateUrl: './contract-parameter-edit-add.component.html'
})
export class ContractParameterEditAddComponent extends BaseModuleComponent {
  public contractParameterId: number;
  public valueTypeOptionList: OptionListModel<ValueType> = new OptionListModel(
    true
  );
  constructor(translateService: TranslateService) {
    super('contract-parameter', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.contractParameterId = this.global.routerParams.get(
      'contractParameterId'
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [
        null,
        {
          validators: [Validators.required(), Validators.maxLength(128)],
          asyncValidators: [
            Validators.existsAsync(
              '/contract-parameter/check-exist',
              null,
              null,
              { id: this.contractParameterId }
            )
          ]
        }
      ],
      code: [
        null,
        {
          validators: [Validators.required(), Validators.maxLength(32)],
          asyncValidators: [
            Validators.existsAsync(
              '/contract-parameter/check-exist',
              null,
              null,
              { id: this.contractParameterId }
            )
          ]
        }
      ],
      valueType: [null, Validators.required()],
      description: [null],
      isDefault: false
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ContractParameter>(
        '/contract-parameter/add-edit',
        new RouteRequestModel(this.todo, this.contractParameterId)
      )
      .subscribe(contractParameterResponse => {
        this.valueTypeOptionList.setRequestValues(
          contractParameterResponse.valueTypeList
        );
        if (contractParameterResponse.contractParameter != null) {
          this.formGroup.patchValue(contractParameterResponse.contractParameter);
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const contractParameter = this.global.copyFormAttributeToModel(
              new ContractParameter(),
              this.formGroup
            );
            const url =
              this.todo === 'edit'
                ? '/contract-parameter/update'
                : '/contract-parameter/insert';
            this.httpClientService
              .post<Response<ContractParameter>>(url, contractParameter)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/contract-parameter']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/contract-parameter']);
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
}
