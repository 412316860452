import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { AppPopupVersionHistoryModel } from 'src/app/core/components/app-popup/app-popup-version-history/app-popup-version-history-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';

@Component({
  templateUrl: './app-popup-contract-draft-version-history.component.html'
})
export class AppPopupContractDraftVersionHistoryComponent extends BaseComponentComponent {
  @Input() modelList: AppPopupVersionHistoryModel[];
  public modelOptionList: OptionListModel<AppPopupVersionHistoryModel> = new OptionListModel(false);

  constructor() {
    super('contract-draft');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
        modelList: [null]
      });
  }

  public setFormGroup(): void {
    this.modelOptionList.setRequestValues(this.modelList);
    this.modelOptionList.searchFieldList = [
      'uploaderName',
      'docFile.fileName'
    ];
    this.formGroup.patchValue({
      modelList: this.modelList
    });
    this.formGroup.setIsView(true);
    this.setStateReady();
  }

}
