<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="vendor-whitelist.title"
      detail="app.action.{{ todo === 'view' ? 'detail' : todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup">
        <h5 class="separator">Data Vendor</h5>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div *ngIf="isDetail">
              <p>{{ vendorWhitelistResponse.vendor.name }}</p>
            </div>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p *ngIf="isDetail">{{ vendorWhitelistResponse.vendor.code }}</p>
          </div>
        </div>
        <br />

        <h5 class="separator">{{
          'vendor-whitelist.form.violationData' | translate
        }}</h5>
        <div
          class="table-responsive custom-table float-none"
          [ngStyle]="{ margin: '0 auto' }"
        >
          <table
            class="table table-striped box-shadow"
            aria-describedby="Table"
          >
            <thead>
              <tr class="text-center">
                <th scope="col">{{ 'app.table.column.no' | translate }}</th>
                <th scope="col">
                  {{ 'vendor-whitelist.table.column.violation' | translate }}
                </th>
                <th scope="col">
                  {{ 'vendor-whitelist.table.column.duration' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngIf="vendorWhitelistResponse.vendorViolationList?.length == 0"
              >
                <td colspan="99" class="bg-white text-center">
                  {{ 'app.info.noData' | translate }}
                </td>
              </tr>
              <tr
                *ngFor="
                  let data of vendorWhitelistResponse.vendorViolationList;
                  let i = index
                "
              >
                <td class="text-center">{{ i + 1 }}.</td>
                <td class="text-center">
                  {{ data.blacklistCriteria.violation }}
                </td>
                <td class="text-center">
                  {{
                    global.converterService.convertMonthToYears(
                      data.blacklistCriteria.durationInMonth
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />

        <h5 class="separator">{{
          'vendor-whitelist.form.blacklistInformation' | translate
        }}</h5>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.startDate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p
              class="col-sm-7"
              *ngIf="vendorWhitelistResponse.vendorBlacklist == null"
            >
              -
            </p>
            <div *ngIf="isDetail">
              <p class="col-sm-7">
                {{
                  vendorWhitelistResponse.vendorBlacklist.startDate
                    | date: global.config.parameterModel.dateFormatShort
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.endDate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p
              class="col-sm-7"
              *ngIf="vendorWhitelistResponse.vendorBlacklist == null"
            >
              -
            </p>
            <div *ngIf="isDetail">
              <p class="col-sm-7">
                {{
                  vendorWhitelistResponse.vendorBlacklist.endDate
                    | date: global.config.parameterModel.dateFormatShort
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.blacklistduration' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p
              class="col-sm-7"
              *ngIf="vendorWhitelistResponse.vendorBlacklist == null"
            >
              -
            </p>
            <div *ngIf="isDetail">
              <p class="col-sm-7">
                {{
                  vendorWhitelistResponse.vendorBlacklist.blacklistDuration.name
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.note' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p
              class="col-sm-7"
              *ngIf="vendorWhitelistResponse.vendorBlacklist == null"
            >
              -
            </p>
            <div *ngIf="isDetail">
              <p class="col-sm-7">
                {{ vendorWhitelistResponse.vendorBlacklist.note }}
              </p>
            </div>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.reason' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p
              class="col-sm-7"
              *ngIf="vendorWhitelistResponse.vendorBlacklist == null"
            >
              -
            </p>
            <div *ngIf="isDetail">
              <p class="col-sm-7">
                {{ vendorWhitelistResponse.vendorBlacklist.reason }}
              </p>
            </div>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.violation-doc' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p
              class="col-sm-7"
              *ngIf="
                !vendorWhitelistResponse.vendorBlacklist ||
                !vendorWhitelistResponse.vendorBlacklist?.file
              "
            >
              -
            </p>
            <div
              *ngIf="
                vendorWhitelistResponse.vendorBlacklist != null &&
                vendorWhitelistResponse.vendorBlacklist.file != null
              "
            >
              <a
                href="#"
                (click)="
                  doDownload(
                    $event,
                    '/vendor-blacklist/file/view/' +
                      vendorWhitelistResponse.vendorBlacklist.file
                        .uploadedFileName +
                      '/' +
                      global.appConstant.fileType.DOC_VIOLATION_BLACKLIST,
                    vendorWhitelistResponse.vendorBlacklist.file.fileName
                  )
                "
              >
                <p class="col-sm-7">
                  {{ vendorWhitelistResponse.vendorBlacklist.file.fileName }}
                </p>
              </a>
            </div>
          </div>
        </div>
        <br />

        <h5 class="separator">{{
          'vendor-whitelist.form.whitelistInformation' | translate
        }}</h5>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.startDate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p
              *ngIf="vendorWhitelistResponse.vendorWhitelist == null"
              class="col-sm-7"
            >
              -
            </p>
            <p *ngIf="isDetail" class="col-sm-7">
              {{
                vendorWhitelistResponse.vendorWhitelist.startDate
                  | date: global.config.parameterModel.dateFormatShort
              }}
            </p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.note' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p
              *ngIf="vendorWhitelistResponse.vendorWhitelist == null"
              class="col-sm-7"
            >
              -
            </p>
            <p *ngIf="isDetail" class="col-sm-7">
              {{ vendorWhitelistResponse.vendorWhitelist.note }}
            </p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.reason' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p
              *ngIf="vendorWhitelistResponse.vendorWhitelist == null"
              class="col-sm-7"
            >
              -
            </p>
            <p *ngIf="isDetail" class="col-sm-7">
              {{ vendorWhitelistResponse.vendorWhitelist.reason }}
            </p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.upload' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p
              *ngIf="vendorWhitelistResponse.vendorWhitelist == null"
              class="col-sm-7"
            >
              -
            </p>
            <p
              *ngIf="vendorWhitelistResponse.vendorWhitelist.file == null"
              class="col-sm-7"
            >
              -
            </p>
            <a
              *ngIf="
                isDetail && vendorWhitelistResponse.vendorWhitelist.file != null
              "
              href="#"
              (click)="
                doDownload(
                  $event,
                  '/vendor-whitelist/file/view/' +
                    vendorWhitelistResponse.vendorWhitelist.file
                      .uploadedFileName +
                    '/' +
                    global.appConstant.fileType.DOC_VENDOR_WHITELIST,
                  vendorWhitelistResponse.vendorWhitelist.file.fileName
                )
              "
            >
              <p class="col-sm-7">
                {{ vendorWhitelistResponse.vendorWhitelist.file.fileName }}
              </p>
            </a>
          </div>
        </div>

        <div
          *ngIf="!vendorWhitelistResponse.isUserApproval"
          class="button-group button-group-center mt-5"
        >
          <app-button
            mode="BYPASS"
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
    <app-approval-prcs-x
      *ngIf="vendorWhitelistResponse.isUserApproval"
      [workflowModelPrcs]="workflowModelPrcs"
      [approvalModelPrcsId]="approvalModelPrcsId"
    >
    </app-approval-prcs-x>
  </div>
</div>
