<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-xx
        [model]="tableResponse"
        stringUrl="/vendor-invitation/index"
        (onClick)="doClick($event)"
        header="{{
          'vendor-invitation.table.header.procurementList' | translate
        }}"
        isShowEditTable="true"
        isShowButtonModifyTable="true"
      >
        <ng-template #headerFilterGroup>
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
            *ngIf="!formLoading"
          >
            <div class="form-group row text-sm-left">
              <div class="col-sm-12 text-left">
                <div class="form-row px-1" style="margin-bottom: -3rem">
                  <div class="form-group row px-4" style="align-items: center">
                    <label class="pr-3 pt-2">{{
                      'vendor-invitation.form.date' | translate
                    }}</label>
                    <div class="text-left">
                      <app-date-picker-x
                        size="XL"
                        formControlName="date"
                        [range]="true"
                        (onChange)="onChangeFilter()"
                      >
                      </app-date-picker-x>
                    </div>
                  </div>
                  <div class="form-group row ml-1 col-sm-2">
                    <app-dropdown-select
                      style="width: 100%"
                      type="CHOSEN"
                      size="XL"
                      formControlName="organizationList"
                      [optionList]="organizationOptionList"
                      (onChange)="onChangeFilter($event)"
                      showLimitValue="1"
                      placeholder="{{
                        'vendor-invitation.placeholder.department' | translate
                      }}"
                    >
                      <ng-template #selectAll>
                        <p>
                          {{
                            'vendor-invitation.placeholder.allDepartment'
                              | translate
                          }}
                        </p>
                      </ng-template>
                    </app-dropdown-select>
                  </div>
                  <div class="form-group row ml-4 col-sm-2">
                    <app-dropdown-select
                      style="width: 100%"
                      type="CHOSEN"
                      size="XL"
                      formControlName="statusList"
                      [optionList]="procurementWorklistStatusOptionList"
                      (onChange)="onChangeFilter($event)"
                      showLimitValue="1"
                      placeholder="{{
                        'vendor-invitation.placeholder.status' | translate
                      }}"
                    >
                      <ng-template #selectAll>
                        <p>
                          {{
                            'vendor-invitation.placeholder.allStatus'
                              | translate
                          }}
                        </p>
                      </ng-template>
                      <ng-template #option let-data>
                        <!-- <p>
                          {{
                            (data.translationKey
                              ? data.translationKey.module.code.toLowerCase() +
                                '.' +
                                data.translationKey.key
                              : data.name
                            ) | translate
                          }}
                        </p> -->
                        <p> {{ data?.name }} </p>
                      </ng-template>
                    </app-dropdown-select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </app-table-xx>
    </div>
  </div>
</app-layout>
