import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppTextFieldModule } from '../../core/components/app-text-field/app-text-field.module';
import { SharedModule } from '../../core/shared/shared.module';
import { AppProcurementAnnouncementModule } from '../../themes/promise-portal/components/app-procurement-announcement/app-procurement-announcement.module';
import { PromisePortalLayoutModule } from '../../themes/promise-portal/layout/promise-portal-layout.module';
import { HomePopupReadMoreComponent } from './home-popup-read-more.component';
import { HomeComponent } from './home.component';

export const routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    PromisePortalLayoutModule,
    AppTextFieldModule,
    AppProcurementAnnouncementModule
  ],
  declarations: [HomeComponent, HomePopupReadMoreComponent]
})
export class HomeModule {}
