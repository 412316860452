<div
  class="app-auto-complete p-0"
  [ngClass]="elementWidthClassName"
  *ngIf="!ISVIEW"
>
  <input
    type="text"
    class="form-control auto-complete-input"
    [ngClass]="[
      elementHeightClassName,
      isInvalid ? 'is-invalid' : '',
      isValid ? 'is-valid' : ''
    ]"
    (focus)="handleFocus($event)"
    (focusout)="handleFocusOut()"
    (input)="handleInput($event)"
    [value]="
      (value && optionView ? value[optionView] : value && value.name) || value
    "
    autofocus="false"
    [disabled]="formControl.disabled"
  />
  <div class="app-select-container"></div>
  <div *ngIf="isLoading" class="loading">
    <em class="fas fa-circle-notch fa-spin text-center"></em>
  </div>
  <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
  </ng-container>
</div>
<small class="text-danger" *ngIf="isInvalid">
  {{ errors.message | translate }}
</small>
<!-- </ng-template> -->
<ng-container *ngIf="ISVIEW">
  <div class="view-autocomplete">
    <span>{{
      (value && optionView ? value[optionView] : value && value.name) ||
        value ||
        '-'
    }}</span>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-container>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [symbolic]="symbolic"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
