import { Component, Input, OnChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ApprovalPrcsEditResponse } from '../../../pages/approval-prcs/approval-prcs-edit-response';
import { ApprovalPrcsUpdateRequest } from '../../../pages/approval-prcs/approval-prcs-update-request';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ApprovalModelPrcs } from '../../bean/approval-model-prcs';
import { File } from '../../bean/file';
import { WorkflowModelPrcs } from '../../bean/workflow-model-prcs';
import { Response } from '../../model/response-model';
import { ResponseStatusModel } from '../../model/response-status-model';
import { ApprovalPathModel } from '../app-approval-path';
import { FieldFormatEnum } from '../app-table/model/field-format.enum';
import { TableResponseModel } from '../app-table/model/table-response-model';
import { FileUploader } from '../upload/model/v1/file.uploader';
@Component({
  selector: 'app-approval-prcs',
  templateUrl: './app-approval-prcs.component.html'
})
export class AppApprovalPrcsComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() private workflowModelPrcs: WorkflowModelPrcs;

  public isShow: boolean;
  public isLoadingSection: boolean;
  public isShowButtonReject: boolean;
  public fileUploader: FileUploader;
  public approvalModelPrcs: ApprovalModelPrcs;
  public approvalPath: ApprovalPathModel<object>;
  public tableResponse: TableResponseModel<ApprovalModelPrcs>;

  private approvalModelPrcsList: ApprovalModelPrcs[];
  private statusList: string[];

  constructor(public translateService: TranslateService) {
    super('app-approval-prcs');
  }

  onInit(): void {
    this.setInitializationState();
    this.buildFormGroup();
    this.buildTableResponse();
    if (typeof this.workflowModelPrcs === 'undefined') {
      this.setStateLoading();
    }
    this.setIsShowButtonRejectState();
  }

  ngOnChanges(): void {
    if (
      this.workflowModelPrcs != null &&
      this.workflowModelPrcs.module.code === 'APPROVAL-PRCS'
    ) {
      this.getAndSetApprovalModelPrcs();
      this.isShow = true;
    }
  }

  private setInitializationState(): void {
    this.approvalPath = new ApprovalPathModel(this.moduleCode, 'READ');
    this.fileUploader = new FileUploader(
      '/approval-prcs/',
      '',
      this.global.appConstant.fileType.DOC_APPROVAL
    );
    this.statusList = ['New', 'Approve', 'Revision', 'Hold', 'Reject'];
  }

  private buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      requestedDate: [null, Validators.compose([Validators.required])],
      file: [new File()],
      note: [null],
      fileApproval: [null]
    });
    this.setStateReady();
  }

  private buildTableResponse(): void {
    this.tableResponse = new TableResponseModel('approval-prcs', [
      { field: 'position.name', header: 'table.column.position' },
      { field: 'processedByName', header: 'table.column.processedBy' },
      {
        field: 'processedDate',
        header: 'table.column.date',
        format: FieldFormatEnum.ShortDateAndTime,
        customClass: 'text-center'
      },
      { field: 'note', header: 'table.column.note' },
      {
        field: 'file.fileName',
        header: 'table.column.file',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName'
      },
      {
        field: 'statusText',
        header: 'table.column.status',
        customClass: 'text-center'
      }
    ]);
  }

  getAndSetApprovalModelPrcs(): void {
    this.isLoadingSection = true;
    this.httpClientService
      .post<ApprovalPrcsEditResponse>(
        '/approval-prcs/edit',
        this.workflowModelPrcs
      )
      .subscribe((approvalPrcsEditResponse: ApprovalPrcsEditResponse) => {
        if (approvalPrcsEditResponse !== null) {
          const { type, userApproverList } =
            approvalPrcsEditResponse.approvalPathResponseModel;
          this.fileUploader.setFile(
            approvalPrcsEditResponse.approvalModelPrcs.file
          );
          this.approvalModelPrcsList =
            approvalPrcsEditResponse.approvalModelPrcsList;
          this.approvalPath.setApproverList(userApproverList).setType(type);
          this.approvalModelPrcs = approvalPrcsEditResponse.approvalModelPrcs;
          this.formGroup.patchValue({
            id: approvalPrcsEditResponse.approvalModelPrcs.id,
            requestedDate:
              approvalPrcsEditResponse.approvalModelPrcs.approvalPrcs
                .requestedDate,
            file: approvalPrcsEditResponse.approvalModelPrcs.file,
            note: approvalPrcsEditResponse.approvalModelPrcs.note
          });
          this.setTableResponseState();
          this.isLoadingSection = false;
        }
      });
  }

  private setTableResponseState(): void {
    this.tableResponse.page.records = this.approvalModelPrcsList;
    this.tableResponse.page.totalRecords = this.approvalModelPrcsList.length;
    this.setStateReady();
  }

  public doSave(status: number): void {
    const statusText = this.statusList[status].toLowerCase();
    const title = `${this.moduleCode}.confirmation.${statusText}.title`;
    const message = `${this.moduleCode}.confirmation.${statusText}.msg`;
    this.global.modalService
      .confirm(
        this.translateService.instant(message),
        this.translateService.instant(title)
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          const approvalModelPrcs: ApprovalModelPrcs =
            this.global.copyFormAttributeToModel(
              this.approvalModelPrcs,
              this.formGroup
            );
          const approvalPrcsUpdateRequest: ApprovalPrcsUpdateRequest =
            new ApprovalPrcsUpdateRequest();
          approvalPrcsUpdateRequest.approvalModelPrcs = approvalModelPrcs;
          approvalPrcsUpdateRequest.approvalModelPrcs.status = status;
          approvalPrcsUpdateRequest.fileObjectList =
            this.formGroup.get('fileApproval').value;
          this.httpClientService
            .post<Response<ApprovalModelPrcs>>(
              '/approval-prcs/update',
              approvalPrcsUpdateRequest
            )
            .subscribe(response => {
              this.setStateReady();
              if (response.status === ResponseStatusModel.OK) {
                this.router.navigate(['/pages/approval-prcs']);
                this.global.alertService.showSuccessSavingOnNextRoute();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  private setIsShowButtonRejectState(): void {
    const workflowPrcsObjectName =
      this.workflowModelPrcs.workflowPrcs.objectName.toLowerCase();
    this.isShowButtonReject = !(
      workflowPrcsObjectName.includes('vendorperformance') ||
      workflowPrcsObjectName.includes('contract') ||
      workflowPrcsObjectName.includes('payment')
    );
  }

  public doBack(): void {
    this.router.navigate(['/pages/approval-prcs']);
  }
}
