import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AddressBook } from 'src/app/core/bean/address-book';
import { ItemType } from 'src/app/core/bean/item-type';
import { Order } from 'src/app/core/bean/order';
import { OrderActivityHistory } from 'src/app/core/bean/order-activity-history';
import { OrderCancellation } from 'src/app/core/bean/order-cancellation';
import { OrderItem } from 'src/app/core/bean/order-item';
import { OrderShipping } from 'src/app/core/bean/order-shipping';
import { OrderShippingItem } from 'src/app/core/bean/order-shipping-item';
import { OrderTc } from 'src/app/core/bean/order-tc';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PrItemReleased } from 'src/app/core/bean/pr-item-released';
import { PrShipping } from 'src/app/core/bean/pr-shipping';
import { ProcurementType } from 'src/app/core/bean/procurement-type';
import { Sow } from 'src/app/core/bean/sow';
import { Vendor } from 'src/app/core/bean/vendor';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { PaginationModel } from 'src/app/core/components/app-pagination/model/pagination-model';
import { AppPopupAddressService } from 'src/app/core/components/app-popup/app-popup-address/app-popup-address.service';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { Validators } from 'src/app/core/validators';
import { ValidatorMessage } from 'src/app/core/validators/core/error-message-constant';
import { AddressPic } from '../../core/bean/address-pic';
import { Contract } from '../../core/bean/contract';
import { OrderPreparation } from '../../core/bean/order-preparation';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OrderAddEditModel } from './model/order-add-edit.model';
import { OrderRequestValidation } from './model/order-request-validation.model';
import { OrderModel } from './model/order.model';
import { AppPopupPrItemDistributorComponent } from './popup/app-popup-pr-item-distributor.component';
import { AppPopupPrItemEditComponent } from './popup/app-popup-pr-item-edit.component';
import { PopupOrderCancelComponent } from './popup/popup-order-cancel.component';
import { OrderRequest } from './request/order.request';
import { OrderAddEditResponse } from './response/order-add-edit.response';
// import * as datePickerAction from 'src/app/core/components/app-date-picker-x/action/date-picker-action';
// import { AppDatePickerXService } from 'src/app/core/components/app-date-picker-x/app-date-picker-x.service';

@Component({
  templateUrl: './order-edit-add-final-xt.component.html'
})
export class OrderEditAddFinalXtComponent
  extends BaseModuleComponent
  implements OnInit {
  public token: string;
  public orderId: number;
  public objectId: number;
  public approvalModelPrcsId: number;
  public fileUploader: FileUploader = new FileUploader(
    '/order/',
    'order.form.headerImage',
    this.global.appConstant.fileType.DOC_ORDER,
    false,
    this.global.config.parameterModel.maxFileOrder
  );
  public response: OrderAddEditResponse = new OrderAddEditResponse();
  public today = new Date();
  public orderCancel: OrderCancellation;
  public orderShippingOptionList: OptionListModel<OrderShipping> =
    new OptionListModel(true, 'address.address'); // to keep data from popup
  public prShippingOptionList: OptionListModel<PrShipping> =
    new OptionListModel(true, 'address.address');
  public dataMap: Map<string, Object> = new Map<string, Object>();
  public changePage: boolean;
  public isLoading= false;

  public badgeColor = {
    DRAFT: 'SECONDARY',
    REVISION: 'FEEDBACK',
    VENDOR_REVISION: 'FEEDBACK',
    WAITING_CONFIRMATION: 'WARNING',
    WAITING_APPROVAL: 'WARNING',
    ON_PROGRESS: 'PROGRESS',
    DONE: 'SUCCESS',
    CLOSED: 'DANGER',
    CANCELED: 'DANGER'
  };

  public statusEditList = [
    this.global.appConstant.pm.ORDER_STATUS_DRAFT,
    this.global.appConstant.pm.ORDER_STATUS_REVISION,
    this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION,
    this.global.appConstant.pm.ORDER_STATUS_REVISION_VERIFICATION
  ];

  public statusForApprovalList = [
    this.global.appConstant.pm.ORDER_STATUS_WAITING_APPROVAL,
    this.global.appConstant.pm.ORDER_STATUS_REVISION,
    this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION,
    this.global.appConstant.pm.ORDER_STATUS_REJECTED,
    this.global.appConstant.pm.ORDER_STATUS_ON_PROGRESS,
    this.global.appConstant.pm.ORDER_STATUS_DONE,
    this.global.appConstant.pm.ORDER_STATUS_CLOSED,
    this.global.appConstant.pm.ORDER_STATUS_CANCELED
  ];

  public statusCancelList = [
    this.global.appConstant.pm.ORDER_STATUS_WAITING_CONFIRMATION,
    this.global.appConstant.pm.ORDER_STATUS_REVISION,
    this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION
  ];

  public amount: number;
  public goodsAmount: number;
  public serviceAmount: number;

  public tableResponseVendor: TableResponseModel<Vendor>;
  public tableResponseTc: TableResponseModel<OrderTc>;
  public tableResponseOrderActHistory: TableResponseModel<OrderActivityHistory>;
  public sowList: Sow[] = [];
  public prItemReleasedList: PrItemReleased[] = [];
  public itemTypeList: ItemType[] = [];
  public isCanceled: boolean;
  public orderShippingItemList: OrderShippingItem[] = [];
  public isRevisions: boolean;
  public orderRequest: OrderRequest = new OrderRequest();
  public isAppSowReady = false;
  public addressBookList: AddressBook[] = [];
  public vendorList: Vendor[] = [];
  public vendorListEdit: Vendor[] = [];
  public prShippingList: PrShipping[] = [];
  public orderShippingList: OrderShipping[] = [];
  public isSourceReady = false;
  public source: string;
  public orderPreparationId: number;
  public isSourceContractView: boolean;
  public contract: Contract;
  public appOfficialReportModel: AppOfficialReportModel;
  public orderShipping: OrderShipping;
  public prShipping: PrShipping;
  public isOfficialReportNull: boolean;
  public currentDate: Date = new Date();
  public urlBackOutside: string;
  public counterTemp: number = 0;
  //DIRECT PO

  public isItemValid: boolean = true;
  public isItemTypeValid: boolean = true;
  public isCategoryNull: boolean = false;
  public tableResponseItem: TableResponseModel<any>;
  public prItemList: PrItem[];
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    true
  );
  public isSubmit = false;
  public prItemBudgetEditList: Map<number, number> = new Map<number, number>(); // budget allocation id , prItemBudget Amount
  public delPrItemList: PrItem[] = [];
  public cartIdList: number[];
  public orderModel: OrderModel;

  // public isAddingItem = false;
  public currentPage = 1;
  public currentPageError: number;
  public shouldReload: boolean;

  public orderAddEdit: OrderAddEditModel;
  public orderAddEditModelMap: Map<number, OrderAddEditModel> = new Map<
    number,
    OrderAddEditModel
  >();
  public pagination: PaginationModel = new PaginationModel(true, 1);
  public orderIdList: number[];
  public isDisableGenerate: boolean = true;
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  @ViewChild('selectorItem') tableItem: AppTableXComponent;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupAddressService: AppPopupAddressService,
    public toastService: ToastService,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute,
    public appPopupPrItemInfoService: AppPopupPrItemInfoService
  ) {
    super('order', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Order>('/order/notification/' + this.token)
      .subscribe((order: Order) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('orderId', order.id);
        this.global.routerParams.set('orderStatus', order.orderStatus);
        this.global.routerParams.set('urlBackOutside', '/pages/order');
        if (order.orderStatus.code == this.global.appConstant.pm.ORDER_STATUS_ON_PROGRESS){
        this.global.routerParams.set('todo', 'view');
        } else {
          this.global.routerParams.set('todo', 'edit');
        }
        this.router.navigate(['/pages/order/edit']);
      });
  }

  public setInitializationState(): void {
    if (this.global.routerParams.get('orderAddEditModelMap')) {
      this.orderAddEditModelMap = this.global.routerParams.get(
        'orderAddEditModelMap'
      );
    }
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.doBuildOrderActHistoryTableresponse();

    if (this.orderIdList && this.orderIdList.length > 0) {
      this.orderAddEditModelMap = new Map<number, OrderAddEditModel>();
      this.pagination.setTotalRecords(this.orderIdList.length);
      this.setFormGroupMultiple();
    } else {
      if (this.orderAddEditModelMap && this.orderAddEditModelMap.size > 0) {
        if (this.orderAddEditModelMap.size > 1) {
          this.pagination.setTotalRecords(this.orderAddEditModelMap.size);
        }
        const request = this.orderAddEditModelMap.get(
          this.currentPage
        ).orderRequest;
        const response = this.orderAddEditModelMap.get(
          this.currentPage
        ).response;

        this.setFormGroupX(request, response);

        this.pagination.setTotalRecords(this.orderAddEditModelMap.size);
        this.pagination.setCurrentPage(this.currentPage);
      } else {
        this.setFormGroup();
      }
    }
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.orderId = this.global.routerParams.get('orderId');
    this.objectId =
      localStorage.getItem('objectId') ||
      this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.orderId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );

    this.orderIdList = this.global.routerParams.get('orderIdList');
    this.currentPage = this.global.routerParams.get('currentPage') || 1;
    this.cartIdList = this.global.routerParams.get('cartIdList');
    this.orderModel = this.global.routerParams.get('orderModel');

    this.global.routerParams.set('urlBackOutside', '/pages/order');
    this.urlBackOutside = '/pages/order';

    localStorage.removeItem('objectId');
    localStorage.removeItem('todo');
  }

  public setFormGroupX(
    orderRequest: OrderRequest,
    responses: OrderAddEditResponse
  ): void {
    this.response = responses;
    this.orderRequest = orderRequest;
    const response = this.response;
    this.orderId = response.order?.id;
    this.appOfficialReportModel = this.orderRequest.appOfficialReportModel.letterNumber
      && this.orderRequest.appOfficialReportModel.fileObjectList &&
      this.orderRequest.appOfficialReportModel.fileObjectList.length > 0 ? this.orderRequest.appOfficialReportModel : this.response.appOfficialReportModel;
    this.delPrItemList = this.orderRequest.delPrItemList;
    this.orderShippingOptionList.setRequestValues([]);
    // this.formGroup.get('requiredDate').reset();
    // if(false) {
    //   this.appDatePickerService.dispatch(new datePickerAction.ResetDatePicker()); 
    // }

    this.itemTypeList = response.itemTypeList;
    let category: ProcurementType[] = [];
    if (this.orderRequest?.order?.procurementType) {
      category.push(this.orderRequest?.order?.procurementType);
    } else {
      for (let j = 0; j < this.orderRequest.prItemList.length; j++) {
        if (
          this.orderRequest.prItemList[j].item.itemType.parentCode ==
          this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
        ) {
          category = this.response.procTypeMasterList.filter(
            procType =>
              procType.code ==
              this.global.appConstant.pm.PROCUREMENT_TYPE_NON_CONSTRUCTION
          );
        } else if (
          this.orderRequest.prItemList[j].item.itemType.parentCode ==
          this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
        ) {
          category = this.response.procTypeMasterList.filter(
            procType =>
              procType.code ==
              this.global.appConstant.pm.PROCUREMENT_TYPE_CONSTRUCTION
          );
        }
        break;
      }
    }

    this.formGroup.get('category').patchValue(category);

    this.orderShippingOptionList.setRequestValues(
      orderRequest.orderShippingList
    );
    this.orderShipping = orderRequest.orderShippingList?.[0] || null;

    const {
      id,
      code,
      title,
      orderStatus,
      requiredDate,
      description,
      procurementType,
      goodsAmount,
      serviceAmount,
      amount
    } = this.orderRequest.order;

    if (this.orderRequest.fileList) {
      this.fileUploader.setFileList(
        this.orderRequest.fileList.map(orderDoc => orderDoc.file)
      );
    }

    this.formGroup.patchValue({
      id,
      code,
      title,
      orderStatus,
      requiredDate,
      description,
      docList: this.fileUploader?.fileObjectList || [],
      orderShippingList: orderRequest.orderShippingList || [],
      procurementType,
      orderShipping: orderRequest.orderShippingList || [],
      goodsAmount,
      serviceAmount,
      amount
    });

    // if (orderRequest?.order?.requiredDate) {
    //   this.formGroup.get('requiredDate').setValue(orderRequest?.order?.requiredDate);

    // }


    this.amount = this.orderRequest?.order?.amount;
    this.goodsAmount = this.orderRequest?.order?.goodsAmount;
    this.serviceAmount = this.orderRequest.order?.serviceAmount;

    this.tableResponseOrderActHistory.setRecordList(
      response.orderActivityHistoryList || []
    );
    this.tableResponseOrderActHistory.reload();

    this.orderCancel = response.orderCancellation;

    if (
      response?.order?.orderStatus?.code ===
      this.global.appConstant.pm.ORDER_STATUS_CANCELED
    ) {
      this.isCanceled = true;
    }

    if (
      response.order?.orderStatus?.code.includes(
        this.global.appConstant.pm.ORDER_STATUS_REVISION
      )
    ) {
      /** revision approval, revision vendor */
      this.isRevisions = true;

      if (
        response.order?.orderStatus?.code ===
        this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION
      ) {
        this.formGroup
          .get('responseToConfirmNote')
          .setValidators([Validators.required()]);
        this.formGroup.get('responseToConfirmNote').updateValueAndValidity();
        this.orderRequest.responseToConfirmNote = this.formGroup.get('responseToConfirmNote').value;
      } else {
        this.formGroup.get('responseToConfirmNote').setIsView(true);
      }
    }

    // if (response.orderActivityHistoryList) {
    //   const oahResend = response.orderActivityHistoryList.find(
    //     oah =>
    //       oah.orderActivityStatus.code ===
    //       this.global.appConstant.pm.ORDER_ACTIVITY_STATUS_RESEND
    //   );
    //   if (oahResend) {
    //     this.formGroup.get('responseToConfirmNote').patchValue(oahResend.note);
    //   }
    // }

    this.source = response?.order?.source || 'ORDER';

    this.prItemList = this.orderRequest.prItemList;
    this.doSetAmount();

    if (this.changePage) {
      this.tableResponseItem.setRecordList(this.prItemList);
      this.tableResponseItem.reload();
    } else {
      this.buildTableResponseItem();
    }

    // if (this.cartIdList) {
    //   this.doSetPrLine();
    // }
    this.sowList = this.orderRequest.sowList || [];
    if (
      this.response.order &&
      this.response.order.orderStatus &&
      !this.statusEditList.includes(this.response.order.orderStatus.code)
    ) {
      // selain draft, revision, revision_vendor
      this.setViewOnly();
    }

    this.shouldReload = true;
    this.isAppSowReady = true;
    this.isSourceReady = true;
    setTimeout(() => {
      this.shouldReload = false;
      // this.isAddingItem = false; // ini haruse isChangePage buat sow
      this.setStateReady();
    }, 100);

  }

  public doBuildOrderActHistoryTableresponse(): void {
    this.tableResponseOrderActHistory = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'processedByUser.name',
          header: 'table.column.userName'
        },
        {
          field: 'processedDate',
          header: 'table.column.date',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'note',
          header: 'table.column.note'
        },
        {
          field: 'orderActivityStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            field: 'orderActivityStatus.code',
            colorMap: {
              SUBMITTED: 'SUCCESS',
              CONFIRMED: 'SUCCESS',
              REVISED: 'FEEDBACK',
              RESEND: 'DANGER',
              REJECTED: 'DANGER',
              CANCELED: 'DANGER',
              CLOSED: 'DANGER'
            }
          }
        }
      ]
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      title: [null, Validators.required()],
      orderStatus: [null],
      requiredDate: [null, Validators.required()],
      description: [null],
      goodsAmount: [null],
      serviceAmount: [null],
      amount: [null],
      orderShipping: [null],
      orderShippingList: [null],
      responseToConfirmNote: [
        null
      ] /** muncul jika status order = vendor_revision */,
      category: [null, Validators.required()]
    });
  }
  public setFormGroupMultiple(): void {
    this.httpClientService
      .get<OrderAddEditResponse[]>(
        '/order/add-edit-multiple?idList=' +
        (this.orderIdList ? this.orderIdList : '') +
        '&todo=' +
        this.todo,
        {}
      )
      .subscribe((response: OrderAddEditResponse[]) => {
        this.currentPage = 1;
        // response.forEach((response, index) => {
        //   let resp: OrderAddEditResponse = new OrderAddEditResponse();
        //   let req: OrderRequest = new OrderRequest();
        //   resp = response;
        //   req.appOfficialReportModel = resp.appOfficialReportModel;
        //   req.order = resp.order;
        //   req.orderShippingList = resp.orderShippingList;
        //   req.orderShippingItemList = resp.orderShippingItemList;
        //   req.sowList = resp.sowList;
        //   req.prItemList = resp.prItemList;
        //   req.organization = resp.organization;

        //   this.setFormGroupX(req, resp);
        //   const orderAddEditModel: OrderAddEditModel = new OrderAddEditModel();
        //   orderAddEditModel.orderRequest = req;
        //   orderAddEditModel.response = resp;
        //   orderAddEditModel.page = this.currentPage;
        //   this.orderAddEditModelMap.set(this.currentPage, orderAddEditModel);
        //   this.currentPage++;
        //   if (index == 0) {
        //     this.response = resp;
        //     this.orderRequest = req;
        //     this.buildTableResponseItem();
        //   }
        // });
        response.forEach((response) => {
          const orderAddEditModel: OrderAddEditModel = new OrderAddEditModel();

          orderAddEditModel.response = response;

          let req: OrderRequest = new OrderRequest();

          req.appOfficialReportModel = response.appOfficialReportModel;
          req.order = response.order;
          req.orderShippingList = response.orderShippingList;
          req.orderShippingItemList = response.orderShippingItemList;
          req.sowList = response.sowList;
          req.prItemList = response.prItemList;
          req.organization = response.organization;
          orderAddEditModel.orderRequest = req;
          this.orderAddEditModelMap.set(this.currentPage, orderAddEditModel);
          this.currentPage++;
        });
        setTimeout(() => {
          this.setFormNew(this.orderAddEditModelMap.get(1).response);
          this.currentPage = 1;
          // this.onChangePage(1);
        }, 1500);
      });
  }

  public setFormNew(response: OrderAddEditResponse): void {
    this.response = response;
    if (this.response?.order?.id) {
      this.isDisableGenerate = false;
    }
    this.appOfficialReportModel = response.appOfficialReportModel;
    this.orderShippingOptionList.setRequestValues([]);
    this.itemTypeList = response.itemTypeList;
    const category: ProcurementType[] = [];
    if (this.response?.order?.procurementType) {
      category.push(this.response.order.procurementType);
    }
    this.formGroup.get('category').patchValue(category);
    if (response.order) {
      const {
        id,
        code,
        title,
        orderStatus,
        requiredDate,
        description,
        goodsAmount,
        serviceAmount,
        amount,
        procurementType
      } = response.order;
      this.orderId = response?.order?.id
        ? response?.order?.id
        : this.orderId;
      this.fileUploader.setFileList(
        response.orderDocList.map(orderDoc => orderDoc.file)
      );

      this.isOfficialReportNull =
        !this.appOfficialReportModel?.fileObjectList?.find(
          fileObject => !fileObject?.isDeleted
        );

      this.formGroup.patchValue({
        id,
        code,
        title,
        orderStatus,
        requiredDate,
        description,
        goodsAmount,
        serviceAmount,
        amount,
        docList: this.fileUploader.fileObjectList,
        orderShippingList: response.orderShippingList,
        procurementType
      });

      this.orderShippingOptionList.setRequestValues(
        response.orderShippingList
      );
      this.orderShipping = response.orderShippingList?.[0];
      this.orderShippingItemList = this.response.orderShippingItemList;

      this.sowList = response.sowList;
      this.amount = response.order.amount;
      this.goodsAmount = response.order.goodsAmount;
      this.serviceAmount = response.order.serviceAmount;

      this.tableResponseOrderActHistory.setRecordList(
        response.orderActivityHistoryList
      );
      this.tableResponseOrderActHistory.reload();

      this.orderCancel = response.orderCancellation;

      if (
        response.order.orderStatus.code ===
        this.global.appConstant.pm.ORDER_STATUS_CANCELED
      ) {
        this.isCanceled = true;
      }

      if (
        response.order.orderStatus.code.includes(
          this.global.appConstant.pm.ORDER_STATUS_REVISION
        )
      ) {
        /** revision approval, revision vendor */
        this.isRevisions = true;

        if (
          response.order.orderStatus.code ===
          this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION
        ) {
          this.formGroup
            .get('responseToConfirmNote')
            .setValidators([Validators.required()]);
          this.formGroup
            .get('responseToConfirmNote')
            .updateValueAndValidity();
        } else {
          this.formGroup.get('responseToConfirmNote').setIsView(true);
        }
      }

      const oahResend = response.orderActivityHistoryList.find(
        oah =>
          oah.orderActivityStatus.code ===
          this.global.appConstant.pm.ORDER_ACTIVITY_STATUS_RESEND
      );
      if (oahResend) {
        this.formGroup
          .get('responseToConfirmNote')
          .patchValue(oahResend.note);
      }
      this.isAppSowReady = true;
      this.isSourceReady = true;
      this.source = response.order.source;
    }

    this.setStateReady();
    this.isAppSowReady = true;
    this.isSourceReady = true;

    this.prItemList = this.response.prItemList;
    this.doSetAmount();
    this.setStateReady();
    this.buildTableResponseItem();
    // if (this.cartIdList) {
    //   this.doSetPrLine();
    // }

    if (
      this.response.order &&
      !this.statusEditList.includes(this.response.order.orderStatus.code)
    ) {
      // selain draft, revision, revision_vendor
      this.setViewOnly();
    }

    if (
      this.response.order &&
      this.response.order.source ===
      this.global.appConstant.core.SOURCE_CONTRACT
    ) {
      this.isSourceContractView = true;
    }
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<OrderAddEditResponse>(
        '/order/add-edit?id=' +
        (this.orderId ? this.orderId : '') +
        '&todo=' +
        this.todo,
        {}
      )
      .subscribe((response: OrderAddEditResponse) => {
        this.pagination.setTotalRecords(1);
        this.response = response;
        if (this.response?.order?.id) {
          this.isDisableGenerate = false;
        }
        this.appOfficialReportModel = response.appOfficialReportModel;
        this.orderShippingOptionList.setRequestValues([]);
        this.itemTypeList = response.itemTypeList;
        const category: ProcurementType[] = [];
        if (this.response?.order?.procurementType) {
          category.push(this.response.order.procurementType);
        }
        this.formGroup.get('category').patchValue(category);
        if (response.order) {
          const {
            id,
            code,
            title,
            orderStatus,
            requiredDate,
            description,
            goodsAmount,
            serviceAmount,
            amount,
            procurementType
          } = response.order;
          this.orderId = response?.order?.id
            ? response?.order?.id
            : this.orderId;
          this.fileUploader.setFileList(
            response.orderDocList.map(orderDoc => orderDoc.file)
          );

          this.isOfficialReportNull =
            !this.appOfficialReportModel?.fileObjectList?.find(
              fileObject => !fileObject?.isDeleted
            );

          this.formGroup.patchValue({
            id,
            code,
            title,
            orderStatus,
            requiredDate,
            description,
            goodsAmount,
            serviceAmount,
            amount,
            docList: this.fileUploader.fileObjectList,
            orderShippingList: response.orderShippingList,
            procurementType
          });

          this.orderShippingOptionList.setRequestValues(
            response.orderShippingList
          );
          this.orderShipping = response.orderShippingList?.[0];
          this.orderShippingItemList = this.response.orderShippingItemList;

          this.sowList = response.sowList;
          this.amount = response.order.amount;
          this.goodsAmount = response.order.goodsAmount;
          this.serviceAmount = response.order.serviceAmount;

          this.tableResponseOrderActHistory.setRecordList(
            response.orderActivityHistoryList
          );
          this.tableResponseOrderActHistory.reload();

          this.orderCancel = response.orderCancellation;

          if (
            response.order.orderStatus.code ===
            this.global.appConstant.pm.ORDER_STATUS_CANCELED
          ) {
            this.isCanceled = true;
          }

          if (
            response.order.orderStatus.code.includes(
              this.global.appConstant.pm.ORDER_STATUS_REVISION
            )
          ) {
            /** revision approval, revision vendor */
            this.isRevisions = true;

            if (
              response.order.orderStatus.code ===
              this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION
            ) {
              this.formGroup
                .get('responseToConfirmNote')
                .setValidators([Validators.required()]);
              this.formGroup
                .get('responseToConfirmNote')
                .updateValueAndValidity();
            } else {
              this.formGroup.get('responseToConfirmNote').setIsView(true);
            }
          }

          const oahResend = response.orderActivityHistoryList.find(
            oah =>
              oah.orderActivityStatus.code ===
              this.global.appConstant.pm.ORDER_ACTIVITY_STATUS_RESEND
          );
          if (oahResend) {
            this.formGroup
              .get('responseToConfirmNote')
              .patchValue(oahResend.note);
          }
          this.isAppSowReady = true;
          this.isSourceReady = true;
          this.source = response.order.source;
        }

        this.setStateReady();
        this.isAppSowReady = true;
        this.isSourceReady = true;

        this.prItemList = this.response.prItemList;
        this.doSetAmount();
        this.setStateReady();
        this.buildTableResponseItem();
        // if (this.cartIdList) {
        //   this.doSetPrLine();
        // }

        if (
          this.response.order &&
          !this.statusEditList.includes(this.response.order.orderStatus.code)
        ) {
          // selain draft, revision, revision_vendor
          this.setViewOnly();
        }

        if (
          this.response.order &&
          this.response.order.source ===
          this.global.appConstant.core.SOURCE_CONTRACT
        ) {
          this.isSourceContractView = true;
        }
      });
  }

  public onChangeItemRequest(): void {
    this.source = 'ORDER'; // new
    const delOrderShipItemList = [];

    if (this.orderShippingItemList && this.orderShippingItemList.length > 0) {
      delOrderShipItemList.push(
        ...this.orderShippingItemList.filter(osi => {
          return !this.tableResponseItem
            .getRecordList()
            .map(prItem => prItem?.id || prItem?.catalog?.id)
            .includes(
              osi.orderItem.prItem?.id || osi.orderItem?.prItem?.catalog?.id
            );
        })
      );
    }

    this.prItemList = this.tableResponseItem.getRecordList();
    if (this.response.orderItemList && this.response.orderItemList.length > 0) {
      this.response.orderItemList
        .filter(oitem => {
          return !this.prItemList
            .map(prItem => prItem?.id || prItem?.catalog?.id)
            .includes(oitem.prItem?.id || oitem.prItem?.catalog?.id);
        })
        .forEach(deleteOrderItem => {
          if (
            deleteOrderItem.id &&
            !this.orderRequest.deleteOrderItemList.find(
              del => del.id === deleteOrderItem.id
            )
          ) {
            deleteOrderItem.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_DELETE;
            this.orderRequest.deleteOrderItemList.push(deleteOrderItem);
          }
        });
    }

    if (
      this.orderShippingItemList &&
      this.orderShippingItemList.length > 0 &&
      delOrderShipItemList &&
      delOrderShipItemList.length > 0
    ) {
      delOrderShipItemList.forEach(delOsi => {
        const indexDelOsi = this.orderShippingItemList.findIndex(
          osi =>
            (osi.orderItem.prItem?.id === delOsi.orderItem.prItem?.id ||
              osi.orderItem.prItem?.catalog?.id ===
              delOsi.orderItem.prItem?.catalog?.id) &&
            osi.orderShipping.address.id === delOsi.orderShipping.address.id
        );
        this.orderShippingItemList.splice(indexDelOsi, 1);
      });
    }

    /** reset sowList */
    if (this.sowList && this.sowList.length > 0) {
      this.sowList.forEach(sow => {
        sow.crudOperation = this.global.appConstant.core.CRUD_OPERATION_DELETE;
        if (
          sow.id &&
          ((this.orderRequest.deleteSowList.length > 0 &&
            this.orderRequest.deleteSowList.filter(
              deletedSow => deletedSow.id && deletedSow.id === sow.id
            ).length === 0) ||
            this.orderRequest.deleteSowList.length === 0)
        ) {
          const sowTemp = sow;
          sowTemp.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
          sow.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
          this.orderRequest.deleteSowList.push(sowTemp);
        }
      });
    }
    this.sowList = [];
    this.isSourceContractView = false;
    this.isSourceReady = true;

    setTimeout(() => {
      this.shouldReload = false;
      this.isAppSowReady = true;
    }, 50);
    this.setStateReady();
  }

  public getPrId(): number {
    if (this.todo !== 'add') {
      if (this.prItemReleasedList && this.prItemReleasedList.length > 0) {
        return this.prItemReleasedList[0].prItem.pr.id;
      }
    } else {
      return null; /** bisa memilih vendor lain */
    }
  }

  public doAddAddress(): void {
    const addressBookList = this.orderShippingOptionList.getRequestValues()
      ? (
        this.orderShippingOptionList.getRequestValues() as Array<OrderShipping>
      ).map(oship => oship.address)
      : [];

    this.appPopupAddressService
      .open(addressBookList, true)
      .subscribe(addrsList => {
        const orderShippingList: OrderShipping[] = [];
        this.addressBookList = addrsList;
        const orderShipping = new OrderShipping();
        orderShipping.address = addrsList;
        orderShippingList.push(orderShipping);

        this.orderShippingOptionList.setRequestValues(orderShippingList);
        this.orderShipping = orderShipping;
        console.log(orderShipping?.address.addressDetail);
        this.formGroup.markAsDirty();
        /** reset quantity shippingitem */
      });
  }

  public doDeleteShipping(): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const orderShippingList = Array.from(
            this.formGroup.get('orderShipping').value
          );
          const orderShippingOptionList =
            this.orderShippingOptionList.getRequestValues();
          orderShippingList.forEach((orderShipping: OrderShipping) => {
            const index = orderShippingOptionList.findIndex(
              oship => oship.address.id === orderShipping.address.id
            );
            const oship = orderShippingOptionList[index];

            if (index !== -1) {
              orderShippingOptionList.splice(index, 1);
              if (
                this.orderShippingItemList &&
                this.orderShippingItemList.length > 0
              ) {
                const delOsiList = this.orderShippingItemList.filter(
                  delOsi =>
                    delOsi.orderShipping.address.id === orderShipping.address.id
                );
                delOsiList.forEach(delOsi => {
                  const indexDelOsi = this.orderShippingItemList.findIndex(
                    osi =>
                      osi.orderItem.prItem.catalog.id ===
                      delOsi.orderItem.prItem.catalog.id &&
                      osi.orderShipping.address.id ===
                      delOsi.orderShipping.address.id
                  );
                  this.orderShippingItemList.splice(indexDelOsi, 1);
                });
              }

              if (
                oship.id &&
                !this.orderRequest.deleteOrderShippingList.find(
                  del => del.id === oship.id
                )
              ) {
                oship.crudOperation =
                  this.global.appConstant.core.CRUD_OPERATION_DELETE;
                this.orderRequest.deleteOrderShippingList.push(oship);
              }
            }
          });
          this.orderShippingOptionList.setRequestValues(
            orderShippingOptionList
          );
          this.formGroup.patchValue({
            orderShipping: null
          });
          this.formGroup.markAsDirty();
        }
      });
  }

  public onChangeOfficialReport(event: AppOfficialReportModel): void {
    this.appOfficialReportModel = event;

    const fileObjectList = this.appOfficialReportModel?.fileObjectList?.filter(
      fileObject => !fileObject?.isDeleted
    );
    this.appOfficialReportModel.fileObjectList = fileObjectList;
    this.isOfficialReportNull =
      !this.appOfficialReportModel?.fileObjectList?.find(
        fileObject => !fileObject?.isDeleted
      );
  }

  public onChangeSowList(event): void {
    this.sowList = event;

    if (this.response.sowList && this.response.sowList.length > 0) {
      this.response.sowList
        .filter(sow => {
          return !this.sowList.map(sw => sw.id).includes(sow.id);
        })
        .forEach(deleteSow => {
          if (
            deleteSow.id &&
            !this.orderRequest.deleteSowList.find(
              del => del.id === deleteSow.id
            )
          ) {
            deleteSow.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_DELETE;
            this.orderRequest.deleteSowList.push(deleteSow);
          }
        });
    }
  }

  public doCancel(): void {
    this.loadingBlockService.showInfo('order.msg.checkingData');

    const order = this.response.order;
    this.httpClientService
      .post<Order>('/order/validate-cancel', order)
      .subscribe((response: Response<Contract>) => {
        this.loadingBlockService.close();
        if (response.status === ResponseStatusModel.OK) {
          // popup cancel po
          this.appPopupService
            .open(PopupOrderCancelComponent, {})
            .subscribe((note: string) => {
              this.orderCancel = new OrderCancellation();
              this.orderCancel.order = this.response.order;
              this.orderCancel.cancelNote = note;

              this.setStateProcessing();
              this.httpClientService
                .post<Response<OrderCancellation>>(
                  '/order/cancel',
                  this.orderCancel
                )
                .subscribe((response: Response<OrderCancellation>) => {
                  this.setStateLoading();
                  if (response.status === ResponseStatusModel.OK) {
                    this.orderCancel = response.body;
                    this.response.order = this.orderCancel.order;

                    this.isCanceled = true;
                    this.isRevisions = false;
                  } else {
                    this.toastService.showError(response.statusText);
                  }

                  this.setStateReady();
                  this.isAppSowReady = true;
                  this.setViewOnly();
                });
            });
        } else if (response.status === ResponseStatusModel.ERROR) {
          if (response.statusText === 'REQUEST_CANCELATION') {
            // failed to cancel po
            const prompt =
              this.translateService.instant(
                'order.modal.validateCancel.prompt'
              ) +
              response.body.requestNumber +
              ' ' +
              this.translateService.instant('order.modal.msg.first');
            this.global.modalService
              .confirmation(
                prompt,
                'order.modal.validateCancel.title',
                'app.button.close',
                'order.button.requestCancelation',
                'pvc pv-exclamation-triangle',
                false
              )
              .subscribe(result => {
                if (result) {
                  // request cancelation
                  this.setStateProcessing();
                  const contract = response.body;
                  this.httpClientService
                    .post<Response<Contract>>(
                      '/order/request-cancelation',
                      contract
                    )
                    .subscribe(response => {
                      if (response.status === ResponseStatusModel.OK) {
                        this.snackbarService.showWarning(
                          'order.alert.msg.requestCancelationHasBeenSent'
                        );
                      }
                      this.loadingBlockService.close();
                    });
                }
              });
          } else if (
            response.statusText === 'FAILED_REQUEST_WAITING_APPROVAL'
          ) {
            // request failed, status kontrak = draft, waiting approval, revision
            this.global.modalService.confirmation(
              'order.modal.requestFailedWaitingApproval.prompt',
              'order.modal.requestFailedWaitingApproval.title',
              null,
              null,
              'pvc pv-exclamation-triangle',
              true
            );
          } else if (
            response.statusText ===
            'FAILED_REQUEST_WAITING_APPROVAL_TERMINATION'
          ) {
            this.global.modalService.confirmation(
              'order.modal.requestFailedWaitingApprovalTermination.prompt',
              'order.modal.requestFailedWaitingApproval.title',
              null,
              null,
              'pvc pv-exclamation-triangle',
              true
            );
          }
        }
      });
  }

  public doClose(): void {
    this.loadingBlockService.showInfo('order.msg.checkingData');
    const order = this.response.order;
    this.httpClientService
      .post<Order>('/order/validate-close', order)
      .subscribe((response: Response<Contract>) => {
        this.loadingBlockService.close();
        if (response.status === ResponseStatusModel.OK) {
          // popup close po
          this.global.modalService
            .confirm(
              this.global.translateService.instant(
                'order.alert.msg.areYouSureYouWantToClose'
              ),
              'order.alert.title.closeConfirmation'
            )
            .subscribe(result => {
              if (result) {
                this.setStateProcessing();
                this.httpClientService
                  .post<Response<Order>>('/order/close', this.response.order)
                  .subscribe((response: Response<Order>) => {
                    this.setStateLoading();
                    if (response.status === ResponseStatusModel.OK) {
                      this.response.order = response.body;
                    } else {
                      this.toastService.showError(response.statusText);
                    }

                    this.setStateReady();
                    this.isAppSowReady = true;
                    this.setViewOnly();
                  });
              }
            });
        } else if (response.status === ResponseStatusModel.ERROR) {
          // failed to close po
          const prompt =
            this.translateService.instant('order.modal.validateClose.prompt') +
            response.body.requestNumber +
            ' ' +
            this.translateService.instant('order.modal.msg.first');
          this.global.modalService
            .confirmation(
              prompt,
              'order.modal.validateClose.title',
              'app.button.close',
              'order.button.requestTermination',
              'pvc pv-exclamation-triangle',
              false
            )
            .subscribe(result => {
              if (result) {
                // request termination
                this.setStateProcessing();
                const contract = response.body;
                this.httpClientService
                  .post<Response<Contract>>(
                    '/order/request-termination',
                    contract
                  )
                  .subscribe(response => {
                    if (response.status === ResponseStatusModel.OK) {
                      this.snackbarService.showWarning(
                        'order.alert.msg.requestTerminationHasBeenSent'
                      );
                    }
                    this.loadingBlockService.close();
                  });
              }
            });
        }
      });
  }

  public goToDeliveryMonitoring(): void {
    localStorage.setItem('drId', this.response.order.dr.id.toString());
    if (
      this.global.userSession?.activeUserRole.role.code === 'VENDOR' ||
      this.response.order.dr.deliveryStatus.code === 'DONE'
    ) {
      localStorage.setItem('todo', 'view');
      window.open('/pages/dr/detail');
    } else {
      localStorage.setItem('todo', 'edit');
      window.open('/pages/dr/edit');
    }
  }

  public doSaveDraftMultiple(): void {
    this.getProcessedRequestMultiple(false)
      .then(responseFromAsync => {
        ;
        const orderRequestValidation: OrderRequestValidation[] =
          this.doValidateOrderModelMap(false);
        const isContainNotValid = orderRequestValidation.some(
          orderRequestValidation =>
            orderRequestValidation.isValidOrderModel == false
        );
        if (!isContainNotValid) {
          this.global.modalService.saveConfirmation({
            prompt: 'app.confirmation.save.msg',
            title: 'app.confirmation.save.title'
          }).subscribe(result => {
            if (result) {
          this.setStateProcessing();
          const url =
            this.todo === 'edit'
              ? '/order/update-multiple'
              : '/order/insert-multiple';
          this.httpClientService
            .post<Response<Order>[]>(url, responseFromAsync)
            .subscribe((response: Response<Order>[]) => {
              const allOK = response.every(
                respon => respon.status === ResponseStatusModel.OK
              );
              const errorResponse = response.find(
                item => item.status !== ResponseStatusModel.OK
              );
              if (allOK) {
                this.snackbarService.showWarning('app.alert.msg.saveSuccess');
                this.router
                  .navigateByUrl('/', { skipLocationChange: true })
                  .then(() => {
                    this.global.routerParams.clear();
                    this.global.routerParams.set('todo', 'edit');
                    this.global.routerParams.set('orderIdList', response.map(resp => resp.body.id))
                    this.router.navigate(['/pages/order/edit']);
                  });
              } else {
                this.toastService.showError(errorResponse.statusText);
              }
              this.setStateReady();
            });

        } else if (
          orderRequestValidation.some(
            orderRequestValidation =>
              !orderRequestValidation.prItemList ||
              orderRequestValidation.prItemList.length == 0
          )
        ) {
          this.toastService.showError(
            this.translateService.instant('order.alert.msg.minimumOneItem')
          );
        } else if (
          orderRequestValidation.some(
            orderRequestValidation =>
              orderRequestValidation.orderShippingList.length == 0
          )
        ) {
          this.toastService.showError(
            this.translateService.instant('order.alert.msg.minimumOneAddress')
          );
        } else if (
          orderRequestValidation.some(
            orderRequestValidation =>
              orderRequestValidation.indexItemAddressedFalse !== -1 ||
              orderRequestValidation.indexFullAdd !== -1
          )
        ) {
          this.toastService.showError(
            this.translateService.instant(
              'order.alert.msg.allItemsShouldBeAddressed'
            )
          );
        } else if (
          orderRequestValidation.some(
            orderRequestValidation =>
              !orderRequestValidation.sowList ||
              orderRequestValidation.sowList.length == 0
          )
        ) {
          this.toastService.showError(
            this.translateService.instant('order.alert.msg.minimumOneSow')
          );
        } else if (
          orderRequestValidation.some(
            orderRequestValidation => orderRequestValidation.remainingAmount !== 0
          )
        ) {
          this.toastService.showError(
            this.translateService.instant(
              'order.alert.msg.percentagePaymentNotOneHundredYet'
            )
          );
        }
      });
      }
    }
    )
      .catch(error => {
        console.error('Error:', error);
      });
  }

  public doSaveDraft(): void {
    this.validate();
    this.setIsItemExist();
    if (this.formGroup.valid) {
      this.setStateProcessing();

      const url = this.todo === 'edit' ? '/order/update' : '/order/insert';
      this.httpClientService
        .post<Response<OrderAddEditResponse>>(
          url,
          this.getProcessedRequest(false)
        )
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.snackbarService.showWarning('app.alert.msg.saveSuccess');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set('orderId', response.body.id);
                this.global.routerParams.set('urlBackOutside', '/pages/order');
                this.global.routerParams.set('todo', 'edit');
                this.router.navigate(['/pages/order/edit']);
              });
          } else {
            this.toastService.showError(response.statusText);
          }
          this.setStateReady();
        });
    }
  }

  public doSubmitMultiple(): void {
    this.getProcessedRequestMultiple(true)
      .then(responseFromAsync => {
        const orderRequestValidation: OrderRequestValidation[] =
          this.doValidateOrderModelMap(true);
        const isContainNotValid = orderRequestValidation.some(
          orderRequestValidation =>
            orderRequestValidation.isValidOrderModel == false
        );
        if (!isContainNotValid) {
          this.global.modalService
            .submitConfirmation()
            .pipe(take(1))
            .subscribe(result => {
              if (result) {
                this.setStateProcessing();

                const url =
                  this.todo === 'edit'
                    ? '/order/update-multiple'
                    : '/order/insert-multiple';
                this.httpClientService
                  .post<Response<Order>[]>(url, responseFromAsync)
                  .subscribe((response: Response<Order>[]) => {
                    const allOK = response.every(
                      respon => respon.status === ResponseStatusModel.OK
                    );
                    const errorResponse = response.find(
                      item => item.status !== ResponseStatusModel.OK
                    );
                    if (allOK) {
                      this.global.modalService
                        .submitSuccessCreateNew()
                        .subscribe(result => {
                          if (result) {
                            this.setStateLoading();
                            this.isAppSowReady = false;
                            setTimeout(() => {
                              this.router
                                .navigateByUrl('/', {
                                  skipLocationChange: true
                                })
                                .then(() => {
                                  this.global.routerParams.clear();
                                  this.global.routerParams.set('todo', 'add');
                                  this.router.navigate(['/pages/order/add']);
                                  this.global.routerParams.set(
                                    'urlBackOutside',
                                    '/pages/order'
                                  );
                                });

                              this.setStateReady();
                              this.isAppSowReady = true;
                            }, 50);
                          } else {
                            this.setStateReady();
                            this.router.navigate(['/pages/order']);
                          }
                        });
                    } else {
                      this.toastService.showError(errorResponse.statusText);
                    }
                    this.setStateReady();
                  });

              }
            });

        } else if (
          orderRequestValidation.some(
            orderRequestValidation =>
              !orderRequestValidation.prItemList ||
              orderRequestValidation.prItemList.length == 0
          )
        ) {
          this.toastService.showError(
            this.translateService.instant('order.alert.msg.minimumOneItem')
          );
        } else if (
          orderRequestValidation.some(
            orderRequestValidation =>
              orderRequestValidation.orderShippingList.length == 0
          )
        ) {
          this.toastService.showError(
            this.translateService.instant('order.alert.msg.minimumOneAddress')
          );
        } else if (
          orderRequestValidation.some(
            orderRequestValidation =>
              orderRequestValidation.indexItemAddressedFalse !== -1 ||
              orderRequestValidation.indexFullAdd !== -1
          )
        ) {
          this.toastService.showError(
            this.translateService.instant(
              'order.alert.msg.allItemsShouldBeAddressed'
            )
          );
        } else if (
          orderRequestValidation.some(
            orderRequestValidation =>
              !orderRequestValidation.sowList ||
              orderRequestValidation.sowList.length == 0
          )
        ) {
          this.toastService.showError(
            this.translateService.instant('order.alert.msg.minimumOneSow')
          );
          //     not valid to submit
        } else if (
          orderRequestValidation.some(
            orderRequestValidation => orderRequestValidation.remainingAmount !== 0
          )
        ) {
          this.toastService.showError(
            this.translateService.instant(
              'order.alert.msg.percentagePaymentNotOneHundredYet'
            )
          );
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  public doSubmit(): void {
    this.validate();
    const isItemAddressedList: boolean[] = [];
    this.prItemReleasedList.forEach(prItemRel => {
      if (
        prItemRel.quantity > 0 &&
        this.orderShippingOptionList.getRequestValues().length > 1
      ) {
        /** max quantity > 0 */
        let maxQuantity = 0;
        if (
          prItemRel.prItem.item.itemType.parentCode ===
          this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
        ) {
          maxQuantity = 100;
        } else {
          maxQuantity = prItemRel.quantity;
        }
        let totalUsedQuantity = 0;
        if (
          this.orderShippingItemList &&
          this.orderShippingItemList.length > 1
        ) {
          const orderShipItemList = this.orderShippingItemList.filter(
            osi => osi.orderItem.prItemReleased.id === prItemRel.id
          );
          orderShipItemList.forEach(oShipItem => {
            totalUsedQuantity += oShipItem.quantity;
          });
        }
        const remainingQuantity = maxQuantity - totalUsedQuantity;
        if (remainingQuantity === 0) {
          isItemAddressedList.push(true);
        } else {
          isItemAddressedList.push(false);
        }
      }
    });

    const indexItemAddressedFalse = isItemAddressedList.findIndex(
      isiadd => isiadd === false
    );

    const isFullyAddressed: boolean[] = [];
    if (
      this.orderShippingOptionList.getRequestValues().length > 1 &&
      this.todo !== 'add'
    ) {
      this.orderShippingOptionList.getRequestValues().forEach(os => {
        const osiList = this.orderShippingItemList.filter(
          osi => osi.orderShipping.address.id === os.address.id
        );
        if (
          osiList &&
          osiList.length > 0 &&
          this.prItemReleasedList &&
          this.prItemReleasedList.length > 0
        ) {
          let totalQuantity = 0;
          this.prItemReleasedList.forEach(pri => {
            const osi = osiList.find(
              osi => osi.orderItem.prItemReleased.id === pri.id
            );
            if (osi) {
              totalQuantity += osi.quantity;
            }
          });
          if (totalQuantity > 0) {
            isFullyAddressed.push(true);
          } else {
            isFullyAddressed.push(false);
          }
        } else {
          isFullyAddressed.push(false);
        }
      });
    }

    if (
      this.prShippingOptionList.getRequestValues().length > 1 &&
      this.todo === 'add'
    ) {
      this.prShippingOptionList.getRequestValues().forEach(os => {
        const osiList = this.orderShippingItemList.filter(
          osi => osi.orderShipping.address.id === os.address.id
        );
        if (
          osiList &&
          osiList.length > 0 &&
          this.prItemReleasedList &&
          this.prItemReleasedList.length > 0
        ) {
          let totalQuantity = 0;
          this.prItemReleasedList.forEach(pri => {
            const osi = osiList.find(
              osi => osi.orderItem.prItemReleased.id === pri.id
            );
            if (osi) {
              totalQuantity += osi.quantity;
            }
          });
          if (totalQuantity > 0) {
            isFullyAddressed.push(true);
          } else {
            isFullyAddressed.push(false);
          }
        } else {
          isFullyAddressed.push(false);
        }
      });
    }
    const indexFullAdd = isFullyAddressed.findIndex(isFull => isFull === false);

    const remainingAmount =
      this.sowList.length > 0
        ? this.sowList[this.sowList.length - 1].sowPaymentTermList[
          this.sowList[this.sowList.length - 1].sowPaymentTermList.length - 1
        ].remainingAmount
        : null;

    const isValid =
      this.formGroup.valid &&
      this.sowList &&
      this.sowList.length > 0 &&
      indexItemAddressedFalse === -1 &&
      indexFullAdd === -1 &&
      /*this.tableResponseTc.getRecordList().length > 0 &&*/
      ((this.orderShippingOptionList.getRequestValues().length > 0 &&
        this.todo !== 'add') ||
        (this.prShippingOptionList.getRequestValues.length > 0 &&
          this.todo === 'add')) &&
      remainingAmount === 0;

    if (isValid) {
      this.setIsItemExist();
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            const url =
              this.todo === 'edit' ? '/order/update' : '/order/insert';
            this.httpClientService
              .post<Response<OrderAddEditResponse>>(
                url,
                this.getProcessedRequest(true)
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccessCreateNew()
                    .subscribe(result => {
                      if (result) {
                        this.setStateLoading();
                        this.isAppSowReady = false;
                        setTimeout(() => {
                          this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(() => {
                              this.global.routerParams.clear();
                              this.global.routerParams.set('todo', 'add');
                              this.router.navigate(['/pages/order/add']);
                              this.global.routerParams.set(
                                'urlBackOutside',
                                '/pages/order'
                              );
                            });

                          this.setStateReady();
                          this.isAppSowReady = true;
                        }, 50);
                      } else {
                        this.router.navigate(['/pages/order']);
                      }
                    });
                } else {
                  this.setStateReady();
                  this.toastService.showError(response.statusText);
                }
              });
          }
        });
    } else if (
      !this.prItemReleasedList ||
      this.prItemReleasedList.length === 0
    ) {
      this.toastService.showError(
        this.translateService.instant('order.alert.msg.minimumOneItem')
      );
    } else if (
      this.orderShippingOptionList.getRequestValues().length === 0 &&
      this.prShippingOptionList.getRequestValues().length === 0
    ) {
      this.toastService.showError(
        this.translateService.instant('order.alert.msg.minimumOneAddress')
      );
    } else if (indexItemAddressedFalse !== -1 || indexFullAdd !== -1) {
      this.toastService.showError(
        this.translateService.instant(
          'order.alert.msg.allItemsShouldBeAddressed'
        )
      );
    } else if (!this.sowList || this.sowList.length === 0) {
      this.toastService.showError(
        this.translateService.instant('order.alert.msg.minimumOneSow')
      );
      //     not valid to submit
    } else if (remainingAmount !== 0) {
      this.toastService.showError(
        this.translateService.instant(
          'order.alert.msg.percentagePaymentNotOneHundredYet'
        )
      );
    }
  }

  public setIsItemExist(): void {
    this.sowList.forEach(sow => {
      sow.sowPaymentTermList.forEach(sowPaymentTerm => {
        let isItemExist = false;
        sowPaymentTerm.sowPaymentTermItemList.forEach(sowPaymentTermItem => {
          if (sowPaymentTermItem.quantity > 0) {
            isItemExist = true;
          }
        });
        sowPaymentTerm.isItemExist = isItemExist;
      });
    });
  }

  public setRequest(): void {
    this.orderRequest.order = this.formGroup.value;
  }

  public setRequestTemp(): void {
    this.orderAddEdit = new OrderAddEditModel();
    const orderRequest: OrderRequest = new OrderRequest();
    orderRequest.order = this.formGroup.value;
    orderRequest.order.procurementType =
      this.formGroup.get('category').value[0];
    orderRequest.order.requiredDate = this.formGroup.get('requiredDate').value;
    orderRequest.order.amount = this.amount;
    orderRequest.order.goodsAmount = this.goodsAmount;
    orderRequest.order.serviceAmount = this.serviceAmount;
    orderRequest.order.source = this.source;
    orderRequest.fileList = this.formGroup.value.docList;
    orderRequest.delPrItemList = this.delPrItemList;
    orderRequest.deleteSowList = this.orderRequest.deleteSowList;
    orderRequest.sowList = this.sowList;
    const orderShippingList =
      this.orderShippingOptionList.getRequestValues() as Array<OrderShipping>;
    orderRequest.orderShippingList = orderShippingList;
    orderRequest.appOfficialReportModel = this.appOfficialReportModel;
    if (
      orderRequest?.order &&
      orderRequest?.order?.orderStatus?.code ===
      this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION
    ) {
      orderRequest.responseToConfirmNote =
        this.formGroup.value.responseToConfirmNote;
    }
    //==---
    if (orderShippingList && orderShippingList.length === 1) {
      /** one Address */
      this.orderShippingItemList = [];

      (orderShippingList as Array<OrderShipping>).forEach(orderShip => {
        this.prItemList.forEach(prItem => {
          const orderShippingItem = new OrderShippingItem();
          orderShippingItem.orderShipping = orderShip;
          orderShippingItem.orderItem = new OrderItem();
          orderShippingItem.orderItem.prItem = prItem;
          orderShippingItem.quantity = prItem.quantity || 0; // max quantity
          orderShippingItem.remainingQuantity = 0; /** fully used */

          /** crud operation for revision */
          if (
            this.response.orderItemList &&
            this.response.orderItemList.length > 0
          ) {
            const existingOrderItem = this.response.orderItemList.find(
              oi => oi.prItem.id === orderShippingItem.orderItem.prItem.id
            );
            if (existingOrderItem) {
              /** existing */
              orderShippingItem.orderItem.id = existingOrderItem.id;
              orderShippingItem.orderItem.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_UPDATE;
            } else {
              orderShippingItem.orderItem.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
            }
          } else {
            orderShippingItem.orderItem.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }

          if (
            this.response.orderShippingList &&
            this.response.orderShippingList.length > 0
          ) {
            const existingOrderShip = this.response.orderShippingList.find(
              oship =>
                oship.address.id === orderShippingItem.orderShipping.address.id
            );
            if (existingOrderShip) {
              /** existing */
              orderShippingItem.orderShipping.id = existingOrderShip.id;
              orderShippingItem.orderShipping.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_UPDATE;
            } else {
              orderShippingItem.orderShipping.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
            }
          } else {
            orderShippingItem.orderShipping.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }

          this.orderShippingItemList.push(orderShippingItem);
        });
      });
    }

    orderRequest.orderShippingItemList = this.orderShippingItemList;

    // if (
    //   this.response.order &&
    //   this.response.order.orderStatus?.code ===
    //     this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION
    // ) {
    //   this.orderRequest.responseToConfirmNote =
    //     this.formGroup.value.responseToConfirmNote;
    // }
    this.prItemList.forEach(pritem => {
      this.orderAddEdit.vendorId = pritem.catalog.vendor.id;
    });
    orderRequest.prItemList = this.prItemList;
    this.response.prItemList = this.prItemList;
    this.orderAddEdit.orderRequest = orderRequest;
    this.orderAddEdit.response = this.response;
    this.orderAddEdit.page = this.currentPage;
    this.orderAddEditModelMap.set(this.currentPage, this.orderAddEdit);
    this.global.routerParams.set('orderAddEdit', this.orderAddEdit);
    this.global.routerParams.set('orderResponse', this.response);
    this.global.routerParams.set(
      'orderAddEditModelMap',
      this.orderAddEditModelMap
    );
    this.global.routerParams.set('currentPage', this.currentPage);
  }

  public onChangePage(currentPage: number): void {
    this.isAppSowReady = false;
    this.isSourceReady = false;
    this.changePage = true;
    this.shouldReload = true;
    this.setRequestTemp();

    const request = this.orderAddEditModelMap.get(currentPage).orderRequest;
    const response = this.orderAddEditModelMap.get(currentPage).response;
    this.currentPage = currentPage;
    // this.setStateLoading();
    // this.isAddingItem = true; // ini haruse isChangePage buat sow
    this.setFormGroupX(request, response);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  public getProcessedRequest(isSubmit: boolean): OrderRequest {
    this.orderRequest.order = this.formGroup.value;
    this.orderRequest.order.procurementType =
      this.formGroup.get('category').value[0];
    this.orderRequest.order.requiredDate = this.orderRequest.order.requiredDate;
    this.orderRequest.prItemList = this.prItemList;
    this.orderRequest.order.amount = this.amount;
    this.orderRequest.order.goodsAmount = this.goodsAmount;
    this.orderRequest.order.serviceAmount = this.serviceAmount;
    this.orderRequest.order.source = this.source;
    this.orderRequest.contract = this.contract;
    this.orderRequest.delPrItemList = this.delPrItemList;
    this.orderRequest.appOfficialReportModel = this.appOfficialReportModel;
    if (this.orderPreparationId) {
      this.orderRequest.order.orderPreparation = new OrderPreparation();
      this.orderRequest.order.orderPreparation.id = this.orderPreparationId;
    } else {
      this.orderRequest.order.orderPreparation = null;
    }

    this.orderRequest.fileList = this.formGroup.value.docList;

    const orderShippingList =
      this.orderShippingOptionList.getRequestValues() as Array<OrderShipping>;

    if (orderShippingList && orderShippingList.length === 1) {
      /** one Address */
      this.orderShippingItemList = [];

      (orderShippingList as Array<OrderShipping>).forEach(orderShip => {
        this.prItemList.forEach(prItem => {
          const orderShippingItem = new OrderShippingItem();
          orderShippingItem.orderShipping = orderShip;
          orderShippingItem.orderItem = new OrderItem();
          orderShippingItem.orderItem.prItem = prItem;
          orderShippingItem.quantity = prItem.quantity || 0; // max quantity
          orderShippingItem.remainingQuantity = 0; /** fully used */

          /** crud operation for revision */
          if (
            this.response.orderItemList &&
            this.response.orderItemList.length > 0
          ) {
            const existingOrderItem = this.response.orderItemList.find(
              oi =>
                oi.prItem.catalog.id ===
                orderShippingItem.orderItem.prItem.catalog.id
            );
            if (existingOrderItem) {
              /** existing */
              orderShippingItem.orderItem.id = existingOrderItem.id;
              orderShippingItem.orderItem.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_UPDATE;
            } else {
              orderShippingItem.orderItem.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
            }
          } else {
            orderShippingItem.orderItem.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }

          if (
            this.response.orderShippingList &&
            this.response.orderShippingList.length > 0
          ) {
            const existingOrderShip = this.response.orderShippingList.find(
              oship =>
                oship.address.id === orderShippingItem.orderShipping.address.id
            );
            if (existingOrderShip) {
              /** existing */
              orderShippingItem.orderShipping.id = existingOrderShip.id;
              orderShippingItem.orderShipping.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_UPDATE;
            } else {
              orderShippingItem.orderShipping.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
            }
          } else {
            orderShippingItem.orderShipping.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }

          this.orderShippingItemList.push(orderShippingItem);
        });
      });
    } else if (orderShippingList && orderShippingList.length > 1) {
      /** more than one address */
      this.orderShippingItemList.forEach(orderShippingItem => {
        /** crud operation for revision */
        if (
          this.response.orderItemList &&
          this.response.orderItemList.length > 0
        ) {
          const existingOrderItem = this.response.orderItemList.find(
            oi =>
              oi.prItem.catalog.id ===
              orderShippingItem.orderItem.prItem.catalog.id
          );
          if (existingOrderItem) {
            /** existing */
            orderShippingItem.orderItem.id = existingOrderItem.id;
            orderShippingItem.orderItem.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_UPDATE;
          } else {
            orderShippingItem.orderItem.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }
        } else {
          orderShippingItem.orderItem.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        }

        if (
          this.response.orderShippingList &&
          this.response.orderShippingList.length > 0
        ) {
          const existingOrderShip = this.response.orderShippingList.find(
            oship =>
              oship.address.id === orderShippingItem.orderShipping.address.id
          );
          if (existingOrderShip) {
            /** existing */
            orderShippingItem.orderShipping.id = existingOrderShip.id;
            orderShippingItem.orderShipping.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_UPDATE;
          } else {
            orderShippingItem.orderShipping.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }
        } else {
          orderShippingItem.orderShipping.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        }
      });
    }

    this.orderRequest.orderShippingItemList = this.orderShippingItemList;

    if (
      !this.orderShippingItemList ||
      (this.orderShippingItemList && this.orderShippingItemList.length === 0)
    ) {
      this.orderRequest.prItemList = this.prItemList;
    }

    this.orderRequest.sowList = this.sowList;

    /** crud operation for revision */
    if (this.response.sowList && this.response.sowList.length > 0) {
      this.orderRequest.sowList.forEach(sow => {
        if (this.response.sowList.find(sw => sw.id === sow.id)) {
          sow.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_UPDATE;
        } else {
          sow.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        }
      });
    } else {
      this.orderRequest.sowList.forEach(sow => {
        sow.crudOperation = this.global.appConstant.core.CRUD_OPERATION_INSERT;
      });
    }

    if (
      this.response.order &&
      this.response.order?.orderStatus?.code ===
      this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION
    ) {
      this.orderRequest.responseToConfirmNote =
        this.formGroup.value.responseToConfirmNote;
    }
    this.orderRequest.cartIdList = this.cartIdList;
    this.orderRequest.isSubmit = isSubmit;
    return this.orderRequest;
  }

  async getProcessedRequestMultiple(
    isSubmit: boolean
  ): Promise<OrderRequest[]> {
    return new Promise<OrderRequest[]>((resolve, reject) => {
      setTimeout(() => {
        try {
          this.setRequestTemp();
          const orderRequestList: OrderRequest[] = Array.from(
            this.orderAddEditModelMap.values()
          ).map((orderAddEditModel) => {
            this.setFormGroupX(
              orderAddEditModel.orderRequest,
              orderAddEditModel.response
            );
            return this.getProcessedRequest(isSubmit);
          });
          resolve(orderRequestList);
        } catch (error) {
          reject(error);
        }
      }, 100);
    });
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public getUserNameList(addressPicList: AddressPic[]): string {
    return addressPicList.map(a => a.user.name).join(', ');
  }

  public isShowChangeHistory(): boolean {
    return (
      this.response &&
      ((this.response.order &&
        this.response.order.orderStatus &&
        this.response.order.orderStatus.code &&
        this.statusForApprovalList.includes(
          this.response.order.orderStatus.code
        )) ||
        (this.response.orderActivityHistoryList &&
          this.response.orderActivityHistoryList.length > 0 &&
          this.response.orderActivityHistoryList[0].orderActivityStatus.code ===
          this.global.appConstant.pm.ORDER_ACTIVITY_STATUS_RESEND))
    );
  }

  public isRoleTypeCommittee(): boolean {
    return (
      this.global.userSession.activeUserRole.role.type ===
      this.global.appConstant.core.ROLE_TYPE_COMMITTEE
    );
  }

  public doEditItem(prItemEdit): void {
    const orderResponse = this.response;
    const prItemBudgetEditList = this.prItemBudgetEditList;
    this.appPopupService
      .open(
        AppPopupPrItemEditComponent,
        { prItemEdit, orderResponse, prItemBudgetEditList },
        { size: 'xl' }
      )
      .subscribe(prItemTemp => {
        prItemTemp.crudOperation =
          this.global.appConstant.core.CRUD_OPERATION_UPDATE;
        this.prItemList.forEach((prItem, index) => {
          if (JSON.stringify(prItem) === JSON.stringify(prItemEdit)) {
            if (prItem.quantity != prItemTemp.quantity && prItem.catalog.id == prItemTemp.catalog.id) {
              this.shouldReload = true;
              if (this.sowList && this.sowList.length > 0) {
                this.sowList.forEach(sow => {
                  this.orderRequest.deleteSowList.push(sow);
                })
              }
            }
            this.prItemList[index] = prItemTemp;
          }
        });
        this.response.prItemList = this.prItemList;
        this.orderRequest.prItemList = this.prItemList;
        this.tableResponseItem.setRecordList(this.prItemList);
        this.tableResponseItem.reload();
        this.onChangeItemRequest();
        this.doSetAmount();
        this.doSetPrLine();
      });
  }

  public doSetAmount(): void {
    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;
    this.prItemList.forEach(prItem => {
      if (
        prItem.item.itemType.parentCode ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      ) {
        goodsAmountTotal = +goodsAmountTotal + +prItem.totalPrice;
      }
      if (
        prItem.item.itemType.parentCode ===
        this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
      ) {
        serviceAmountTotal = +serviceAmountTotal + +prItem.totalPrice;
      }
    });
    this.formGroup.patchValue({
      goodsAmount: goodsAmountTotal,
      serviceAmount: serviceAmountTotal,
      amount: +goodsAmountTotal + +serviceAmountTotal
    });
    this.amount = +goodsAmountTotal + +serviceAmountTotal;
    this.goodsAmount = goodsAmountTotal;
    this.serviceAmount = serviceAmountTotal;
  }

  public doSetPrLine(): void {
    this.tableResponseItem.getRecordList().forEach((prItem, index) => {
      prItem.code = '00' + (+index + +1);
    });
  }

  public doViewItem(prItem: PrItem): void {
    this.appPopupPrItemInfoService.open(prItem.id, true);
  }

  public doRequestItemToDistributor(): void {
    if (
      this.response.organization.isDistributor &&
      this.global.userSession.user.organization.parentId !== null
    ) {
      this.appPopupService
        .open(AppPopupPrItemDistributorComponent)
        .subscribe(result => {
          if (result) {
            this.orderRequest.user = this.global.userSession.user;
            this.orderRequest.messageRequestItemToDistributor =
              result.messageRequestItemToDistributor;
            this.orderRequest.organization = this.response.organization;
            this.setStateProcessing();
            this.httpClientService
              .post<Response<OrderRequest>>(
                '/order/notification-pr-item-to-distributor',
                this.orderRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccessCreateNew()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router
                          .navigateByUrl('/', { skipLocationChange: true })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set('todo', 'add');
                            this.router.navigate(['/pages/pr/add']);
                            this.global.routerParams.set(
                              'urlBackOutside',
                              '/pages/pr'
                            );
                          });
                      } else {
                        this.router.navigate(['/pages/pr']);
                      }
                      this.setStateReady();
                    });
                } else {
                  this.toastService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    } else {
      this.global.alertService.showError(
        this.translateService.instant('pr.errorRequestItemToDistributor')
      );
    }
  }

  public buildTableResponseItem(): void {
    if (
      this.response?.order?.orderStatus?.code ==
      this.global.appConstant.pm.ORDER_STATUS_DRAFT ||
      this.todo === 'add' ||
      this.todo === 'edit'
    ) {
      this.tableResponseItem = new TableResponseModel('pr', [
        {
          field: 'prItemImageList',
          header: 'table.column.thumbnail',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): File => {
                if (tablePluginData.value && tablePluginData.value.length > 0) {
                  return tablePluginData.value[0].file;
                }
                return null;
              }
            },
            {
              name: 'img',
              classNameMap: {
                MATERIAL: 'pvr pv-box',
                JASA: 'pvr pv-tools'
              },
              fieldClassName: 'item.itemType.parentCode',
              onClick: !this.formGroup.isView
                ? this.doEditItem.bind(this)
                : this.doViewItem.bind(this)
            }
          ],
          isSortable: false,
          isSearchTable: false
        },
        {
          field: 'item.merk',
          header: 'table.column.itemName'
        },
        {
          field: 'code',
          header: 'table.column.prLine'
        },
        {
          field: 'catalog.catalogType.name',
          header: 'table.column.catalogType',
          plugins: {
            name: 'badge',
            className: 'badge-catalog',
            pill: false,
            colorMap: {
              ITEM: 'WARNING',
              VENDOR: 'INFO',
              CONTRACT: 'SUCCESS'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.row.record.catalog) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.' +
                    tablePluginData.row.record.catalog.catalogType
                      .translationKey.key
                  );
                } else {
                  return 'Item';
                }
              },
              color: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.row.record.catalog) {
                  if (
                    tablePluginData.row.record.catalog.catalogType.code ===
                    this.global.appConstant.vm.CATALOG_TYPE_VENDOR
                  ) {
                    return 'VENDOR';
                  } else if (
                    tablePluginData.row.record.catalog.catalogType.code ===
                    this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
                  ) {
                    return 'CONTRACT';
                  }
                } else {
                  return 'ITEM';
                }
              }
            }
          }
        },
        {
          field: 'item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'item.itemType.parentCode',
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (
                  tablePluginData.row.record.item.itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_OBAT
                ) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.good'
                  );
                } else if (tablePluginData.row.record.item.itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_ALKES) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.service'
                  );
                } else if (
                  tablePluginData.row.record.item.itemType.parentCode ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                ) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.good'
                  );
                } else {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.service'
                  );
                }
              }
            }
          },
          isSortable: false
        },
        {
          field: 'workflowModelPrcs.name',
          header: 'table.column.stage'
        },
        {
          field: 'quantity',
          header: 'table.column.quantity',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              return this.global.converterService.convertDecimal(
                tablePlugin.value
              );
            }
          },
          className: 'text-right'
        },
        {
          field: 'price',
          header: 'table.column.price',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: 'text-right'
        },
        {
          field: 'totalPrice',
          header: 'table.column.totalPrice',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: 'text-right'
        }
      ]);
    } else {
      this.tableResponseItem = new TableResponseModel('pr', [
        {
          field: 'prItemImageList',
          header: 'table.column.thumbnail',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): File => {
                if (tablePluginData.value && tablePluginData.value.length > 0) {
                  return tablePluginData.value[0].file;
                }
                return null;
              }
            },
            {
              name: 'img',
              classNameMap: {
                MATERIAL: 'pvr pv-box',
                JASA: 'pvr pv-tools'
              },
              fieldClassName: 'item.itemType.parentCode',
              onClick: this.doViewItem.bind(this)
            }
          ],
          isSortable: false,
          isSearchTable: false
        },
        {
          field: 'item.merk',
          header: 'table.column.itemName'
        },
        {
          field: 'code',
          header: 'table.column.prLine'
        },
        {
          field: 'catalog',
          header: 'table.column.catalogType',
          plugins: {
            name: 'badge',
            className: 'badge-catalog',
            pill: false,
            colorMap: {
              ITEM: 'WARNING',
              VENDOR: 'INFO',
              CONTRACT: 'SUCCESS'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.value !== null) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.' +
                    tablePluginData.value.catalogType.translationKey.key
                  );
                } else {
                  return 'Item';
                }
              },
              color: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.value !== null) {
                  if (
                    tablePluginData.value.catalogType.code ===
                    this.global.appConstant.vm.CATALOG_TYPE_VENDOR
                  ) {
                    return 'VENDOR';
                  } else if (
                    tablePluginData.value.catalogType.code ===
                    this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
                  ) {
                    return 'CONTRACT';
                  }
                } else {
                  return 'ITEM';
                }
              }
            }
          }
        },
        {
          field: 'item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            field: 'item.itemType.parentCode',
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (
                  tablePluginData.row.record.item.itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_OBAT
                ) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.good'
                  );
                } else if (tablePluginData.row.record.item.itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_ALKES) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.service'
                  );
                } else if (
                  tablePluginData.row.record.item.itemType.parentCode ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                ) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.good'
                  );
                } else {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.service'
                  );
                }
              }
            }
          },
          isSortable: false
        },
        {
          field: 'quantity',
          header: 'table.column.quantity',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              return this.global.converterService.convertDecimal(
                tablePlugin.value
              );
            }
          },
          className: 'text-right'
        },
        {
          field: 'price',
          header: 'table.column.price',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: 'text-right'
        },
        {
          field: 'totalPrice',
          header: 'table.column.totalPrice',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: 'text-right'
        }
      ]);
    }

    this.tableResponseItem.setRecordList(this.prItemList);
    this.tableResponseItem.reload();
    this.response.prItemList = this.prItemList;
  }

  public doAddItem(): void {
    this.setRequestTemp();
    const category = this.formGroup.get('category').value;

    if (category && category.length > 0) {
      this.isCategoryNull = false;
      this.response.prItemList = this.prItemList;
      this.response.pr = this.formGroup.value;
      this.response.prShippingList = this.response.prShippingList;
      this.response.procurementTypeList = this.formGroup.get('category').value;
      this.global.routerParams.set(
        'documentList',
        this.formGroup.value.documentList
      );
      if (this.isRevisions && this.prItemList && this.prItemList.length > 0) {
        this.global.routerParams.set('vendorId', this.prItemList[0].catalog.vendor.id);
      }
      this.global.routerParams.set('todo', this.todo);
      this.global.routerParams.set('orderResponse', this.response);
      this.global.routerParams.set('orderRequest', this.orderRequest);
      this.global.routerParams.set('backToUrl', this.router.url);
      this.global.routerParams.set('urlBackOutside', '/pages/order');
      this.global.routerParams.set('delPrItemList', this.delPrItemList);
      this.global.routerParams.set(
        'prItemBudgetEditList',
        this.prItemBudgetEditList
      );
      this.formGroup.reset();
      this.router.navigate(['/pages/order/add-item']);
    } else {
      this.isCategoryNull = true;
    }
  }

  public doDeleteItem(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach((record: PrItem) => {
            const indexOfRecord = this.tableResponseItem
              .getRecordList()
              .findIndex(r => r.code === record.code);
            if (indexOfRecord !== -1 && record.id) {
              const prItem = this.response.prItemList.find(
                prItems => prItems.code === record.code
              );
              prItem.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_DELETE;
              this.delPrItemList.push(prItem);
            }
            this.tableResponseItem.getRecordList().splice(indexOfRecord, 1);
          });

          this.shouldReload = true;


          this.tableResponseItem.reload();
          this.doSetAmount();
          this.doSetPrLine();
          this.onChangeItemRequest();
          this.formGroup.markAsDirty();
        }
      });
  }

  public doValidateOrderModelMap(isSubmit: boolean): OrderRequestValidation[] {
    const orderRequestValidationList: OrderRequestValidation[] = [];
    this.orderAddEditModelMap.forEach((orderModel, key) => {
      let isValidOrderModel = true;
      this.validate();
      const validatorMessage: ValidatorMessage = new ValidatorMessage();
      if (!orderModel.orderRequest.order.title) {
        this.formGroup.get('title').setErrors({
          message: validatorMessage.ERROR_MESSAGE_REQUIRED
        });
        this.currentPageError = key;
        isValidOrderModel = false;
      }
      if (!orderModel.orderRequest.order.requiredDate) {
        this.formGroup.get('requiredDate').setErrors({
          message: validatorMessage.ERROR_MESSAGE_REQUIRED
        });
        this.currentPageError = key;
        isValidOrderModel = false;
      }

      const isItemAddressedList: boolean[] = [];
      orderModel.orderRequest.prItemList.forEach(prItem => {
        if (
          prItem.quantity > 0 &&
          orderModel.orderRequest.orderShippingList.length > 1
        ) {
          /** max quantity > 0 */
          let maxQuantity = 0;
          if (
            prItem.item.itemType.code ===
            this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
          ) {
            maxQuantity = 100;
          } else {
            maxQuantity = prItem.quantity;
          }
          let totalUsedQuantity = 0;
          if (
            orderModel.orderRequest.orderShippingItemList &&
            orderModel.orderRequest.orderShippingItemList.length > 1
          ) {
            const orderShipItemList =
              orderModel.orderRequest.orderShippingItemList.filter(
                osi => osi.orderItem.prItem.catalog.id === prItem.catalog.id
              );
            orderShipItemList.forEach(oShipItem => {
              totalUsedQuantity += oShipItem.quantity;
            });
          }
          const remainingQuantity = maxQuantity - totalUsedQuantity;
          if (remainingQuantity === 0) {
            isItemAddressedList.push(true);
          } else {
            isItemAddressedList.push(false);
          }
        }
      });
      let indexItemAddressedFalse;
      if (isItemAddressedList.length > 0) {
        indexItemAddressedFalse = isItemAddressedList.findIndex(
          isiadd => isiadd === false
        );
      } else {
        indexItemAddressedFalse = -1;
      }

      const isFullyAddressed: boolean[] = [];
      if (orderModel.orderRequest.orderShippingList.length > 1) {
        orderModel.orderRequest.orderShippingList.forEach(os => {
          const osiList = orderModel.orderRequest.orderShippingItemList.filter(
            osi => osi.orderShipping.address.id == os.address.id
          );
          if (
            osiList &&
            osiList.length > 0 &&
            orderModel.orderRequest.prItemList &&
            orderModel.orderRequest.prItemList.length > 0
          ) {
            let totalQuantity = 0;
            orderModel.orderRequest.prItemList.forEach(pritem => {
              const osi = osiList.find(
                osi => osi.orderItem.prItem.catalog.id === pritem.catalog.id
              );
              if (osi) {
                totalQuantity += osi.quantity;
              }
            });
            if (totalQuantity > 0) {
              isFullyAddressed.push(true);
            } else {
              isFullyAddressed.push(false);
            }
          } else {
            isFullyAddressed.push(false);
          }
        });
      }
      let indexFullAdd;
      if (isFullyAddressed.length > 0) {
        indexFullAdd = isFullyAddressed.findIndex(isFull => isFull === false);
      } else {
        indexFullAdd = -1;
      }

      const remainingAmount =
        orderModel.orderRequest.sowList.length > 0
          ? orderModel.orderRequest.sowList[
            orderModel.orderRequest.sowList.length - 1
          ].sowPaymentTermList[
            orderModel.orderRequest.sowList[
              orderModel.orderRequest.sowList.length - 1
            ].sowPaymentTermList.length - 1
          ].remainingAmount
          : null;

      let isOfficialReportNull = false;
      if (
        isSubmit &&
        ((orderModel.orderRequest.appOfficialReportModel.fileObjectList &&
          orderModel.orderRequest.appOfficialReportModel.fileObjectList
            .length === 0) ||
          orderModel.orderRequest.appOfficialReportModel.letterDate === null ||
          orderModel.orderRequest.appOfficialReportModel.letterNumber === null ||
          orderModel.orderRequest.appOfficialReportModel.fileObjectList === null ||
          orderModel.orderRequest.appOfficialReportModel.fileObjectList === undefined)
      ) {
        this.isOfficialReportNull = true;
        isOfficialReportNull = true;
      }

      let isValidConfirmNote = true;
      if (
        orderModel?.orderRequest?.order &&
        orderModel?.orderRequest?.order?.orderStatus?.code ===
        this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION
      ) {
        orderModel.orderRequest.responseToConfirmNote =
          this.formGroup.value.responseToConfirmNote;
        if (this.formGroup.value == null || this.formGroup.value == '') {
          isValidConfirmNote = false;
        }
      }

      isValidOrderModel =
        isValidOrderModel &&
        orderModel.orderRequest.sowList &&
        orderModel.orderRequest.sowList.length > 0 &&
        indexItemAddressedFalse === -1 &&
        indexFullAdd === -1 &&
        orderModel.orderRequest.orderShippingList.length > 0 &&
        remainingAmount === 0 &&
        !isOfficialReportNull
        && isValidConfirmNote;

      const orderRequestValidation: OrderRequestValidation =
        new OrderRequestValidation();
      orderRequestValidation.indexFullAdd = indexFullAdd;
      orderRequestValidation.indexItemAddressedFalse = indexItemAddressedFalse;
      orderRequestValidation.orderShippingItemList =
        orderModel.orderRequest.orderShippingItemList;
      orderRequestValidation.orderShippingList =
        orderModel.orderRequest.orderShippingList;
      orderRequestValidation.sowList = orderModel.orderRequest.sowList;
      orderRequestValidation.remainingAmount = remainingAmount;
      orderRequestValidation.prItemList = orderModel.orderRequest.prItemList;
      orderRequestValidation.appOfficialReportModel =
        orderModel.orderRequest.appOfficialReportModel;
      orderRequestValidation.isValidOrderModel = isValidOrderModel;

      orderRequestValidationList.push(orderRequestValidation);

      if (!isValidOrderModel) {
        this.scrollIntoErrorViewModel();
      }
    });
    return orderRequestValidationList;
  }

  public scrollIntoErrorViewModel(): void {
    setTimeout(() => {
      const HEADER_HEIGHT = 250;
      const errorElement = document
        .querySelectorAll('small.text-danger')
        .item(0);
      if (errorElement) {
        const windowScrollY = window.scrollY;
        console.log(windowScrollY);
        if (window.scrollY) {
          window.scrollTo({
            top:
              windowScrollY -
              HEADER_HEIGHT +
              errorElement.getBoundingClientRect().top,
            behavior: 'smooth'
          });
        }
      }
    });
  }

  public onChangeDate(event): void {
    console.log(event);
  }
}
