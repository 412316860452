import { WorkPlan } from './../bean/work-plan';
import { BaseEntity } from './../base/base-entity';
import { Position } from '../bean/position';
import { User } from '../bean/user';

export class TeamProjectEntity extends BaseEntity {
    workPlan: WorkPlan;
    position: Position;
    user: User;
    type: number;
    sequence: number;
}
