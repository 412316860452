import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appAlphaNumeric]'
})
export class AlphanumericDirective {
  @Input() appAlphaNumeric: string;
  @Input() pattern: RegExp;

  public defaultPatternStr = '[^\\w\\s]';

  constructor(public ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  handleInput(event: any): void {
    const strRegex = this.appAlphaNumeric
      ? '[^\\w\\s' + this.appAlphaNumeric + ']'
      : this.defaultPatternStr;
    const regex = new RegExp(strRegex, 'gi');
    event.target.value = event.target.value.replace(
      this.pattern ? this.pattern : regex,
      ''
    );
    this.ngControl.control.patchValue(event.target.value);
  }
}
