import { OrderType } from '../../../../../../core/bean/order-type';
import { WidgetValueOfOrderModelResponse } from './widget-value-of-order-model-response';

export class WidgetValueOfOrderResponse {
  widgetValueOfOrderModelResponseList: WidgetValueOfOrderModelResponse[];
  total: number;
  growthFromPastYear: number;
  growthFromPastYearPercentage: number;
  orderTypeList: OrderType[];
}
