import { EventEmitter, Injectable } from '@angular/core';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { WebSocketService } from 'src/app/core/services/websocket/websocket.service';
import { Global } from '../../../global/global';
import { WebSocketResponseModel } from '../../../model/websocket-response-model';
import { AppPopupImportComponent } from './app-popup-import.component';

@Injectable({ providedIn: 'root' })
export class ImportFileWebsocketService extends WebSocketService<WebSocketResponseModel> {
  public appPopupImportComponent: AppPopupImportComponent;
  public onCloseModal: EventEmitter<boolean> = new EventEmitter();
  public message: EventEmitter<string> = new EventEmitter();

  constructor(public appPopupService: AppPopupService, global: Global) {
    super(`import-file/${global.userSession.token}`);
  }

  public onReceivedMessage(message: string): Promise<any> {
    return new Promise(resolve => {
      const websocketResponseModel: WebSocketResponseModel =
        JSON.parse(message);
      if (this.appPopupImportComponent) {
        this.appPopupImportComponent.isLoading = true;
        if (
          websocketResponseModel.status ===
            this.global.appConstant.core.WEBSOCKET_STATUS_FINISH ||
          websocketResponseModel.status ===
            this.global.appConstant.core.WEBSOCKET_STATUS_CANCEL
        ) {
          const result =
            websocketResponseModel.status ===
            this.global.appConstant.core.WEBSOCKET_STATUS_FINISH
              ? true
              : false;
          setTimeout(() => {
            this.appPopupImportComponent = null;
            this.onCloseModal.emit(true);
            resolve(result);
          }, 500);
        } else {
          this.appPopupImportComponent.model = websocketResponseModel;
          this.onCloseModal.emit(false);
        }
      }
    });
  }
}
