import { NgModule } from '@angular/core';
import { AppCarouselModule } from '../../../../../../core/components/app-carousel/app-carousel.module';
import { PipesModule } from '../../../../../../core/pipe/pipe.module';
import { VendorModule } from '../../../../../../core/vendor/vendor.module';
import { AppCatalogItemCarouselComponent } from './app-catalog-item-carousel.component';
@NgModule({
  imports: [VendorModule, AppCarouselModule, PipesModule],
  declarations: [AppCatalogItemCarouselComponent],
  exports: [AppCatalogItemCarouselComponent]
})
export class AppCatalogItemCarouselModule { }
