import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  Input,
  Optional,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { ResolveFieldDataPipe } from '../../pipe/resolve-field-data.pipe';
import { ArrayUtils, TextUtils } from '../../utils';
import { ActiveDropDown } from '../app-drop-down/active-drop-down.service';
import { AppDropdownSelectType } from './app-dropdown-select-type';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './app-dropdown-select.component.html',
  styleUrls: ['./app-dropdown-select.component.scss'],
  providers: makeProvider(AppDropdownSelectComponent)
})
export class AppDropdownSelectComponent
  extends BaseValueAccessorComponent<any>
  implements AfterViewInit
{
  @Input() type: AppDropdownSelectType = 'COMBOBOX';
  @Input() isMultiple =
    false; /**  false = combobox/ autocomplete single, true = chosen/autocomplete multiple */
  @Input() isAddManual =
    false; /* hanya utk autocomplete, berfungsi utk membuat keyword yg diketik bisa disimpan menjadi objek baru (jika tidak ada option yg sesuai dgn keyword) */
  @ContentChild('option') optionTmpl: TemplateRef<any>;
  @ContentChild('value') valueTmpl: TemplateRef<any>;
  @ContentChild('selectAll') selectAllTmpl: TemplateRef<any>;
  @ContentChild('footer') footerTmpl: TemplateRef<any>;

  @ViewChild('inputElement') inputElementRef: ElementRef<any>;

  params: Object;
  keywords: string;
  optionsCopy: Array<any> = [];
  selectElement: any;
  divSelectContainerElement: any;
  generatedId = TextUtils.generateRandomString();
  generateFormGroup: FormGroup = new FormGroup({});

  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public renderer2: Renderer2,
    public resolveFieldDataPipe: ResolveFieldDataPipe,
    private activeDropDown: ActiveDropDown
  ) {
    super('app-chosen', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setIsMultiple();
    this.placeholder = this.placeholder || 'app.chooseData';
    if (this.isAddManual) {
      this.buildFormGroup();
    }
    if (!this.ISVIEW) {
      if (this.isMultiple) {
        // this.setFormControlValue();
        this.setOptionsCopy();
      }
    } else {
      // this.formControl.disable();
    }
  }

  ngAfterViewInit(): void {
    if (!this.ISVIEW) {
      // this.setAppChosenElement();
      // this.setDisabledAppChosenLabelElement();
    }
  }

  public onClickInput(): void {
    if (this.type === 'AUTOCOMPLETE') {
      this.inputElementRef.nativeElement.focus();
    }
  }

  public setIsMultiple(): void {
    this.isMultiple = this.type === 'CHOSEN' ? true : this.isMultiple;
  }

  public onSelectChange(): void {
    if (this.type !== 'CHOSEN') {
      this.activeDropDown.close();

      if (this.type === 'AUTOCOMPLETE' && this.isAddManual) {
        this.generateFormGroup.patchValue(this.formControl.value);
      }
    }

    if (this.isMultiple && this.type !== 'CHOSEN') {
      this.inputElementRef.nativeElement.value = '';
      this.inputElementRef.nativeElement.size = 1;
      this.keywords = '';
    }

    if (
      this.formControl.value &&
      this.type === 'AUTOCOMPLETE' &&
      this.isMultiple
    ) {
      const idList: number[] = (this.formControl.value as Array<any>).map(
        val => val.id
      );
      this.params = {
        selectedIdList: idList.toString().replace('[', '').replace(']', '')
      };

      this.log.debug(idList);
    }
    this.log.debug('PARAMS for multi autocomplete');
    this.log.debug(this.params);

    this.onChange.emit(this.formControl.value);
  }

  buildFormGroup(): void {
    /** isAddManual true */
    if (Object.keys(this.generateFormGroup.controls).length === 0) {
      this.generateFormGroup = this.formBuilder.group({
        name: [null]
      });
    }

    if (this.formControl.value) {
      this.generateFormGroup.patchValue(this.formControl.value);
    }
  }

  onInputKeyword(event: any): void {
    this.keywords = event.target.value;
    event.target.size = this.keywords
      ? this.keywords.length
      : this.placeholder && !this.formControl.value
      ? this.placeholder.length + 5
      : 1;

    this.log.debug(this.formControl.value);
  }

  handleInputAutoCompleteSingle(event: any): void {
    this.keywords = event.target.value?.name || event.target.value || '';
    this.formControl.markAsDirty();
    if (!this.isAddManual) {
      this.formControl.reset();
    }

    this.value = event.target.value;

    this.log.debug('keywords');
    this.log.debug(this.keywords);
    // reload
    if (this.isAddManual) {
      if (
        this.formControl.value?.id &&
        this.keywords.length < (this.formControl.value?.name as string).length
      ) {
        /** sudah memilih option dr data existing (ada id-nya) lalu menghapus keyword utk cari option lain */
        this.keywords = '';
        this.formControl.reset();
        this.generateFormGroup.reset();
      }

      if (this.keywords && this.keywords.trim() !== '') {
        this.generateFormGroup.get('name').patchValue(this.keywords);
        this.formControl.patchValue(this.generateFormGroup.value);
        this.log.debug(this.formControl.value);
      } else {
        this.formControl.reset();
      }
    }
  }

  setFormControlValue(): void {
    if (!this.formControl.value || this.formControl.value === null) {
      this.formControl.patchValue([]);
    }
  }

  setOptionsCopy(): void {
    if (!this.disabled) {
      if (this.optionList) {
        if (!this.optionList.isWaitFromServer) {
          this.optionsCopy = ArrayUtils.sortArray(
            this.optionList.getRequestValues(),
            this.optionList.sortBy || String(this.optionList.viewPath)
          );
        }

        this.optionList.requestValueChanges.subscribe((values: any) => {
          this.optionsCopy = ArrayUtils.sortArray(
            values,
            this.optionList.sortBy || String(this.optionList.viewPath)
          );
        });
      }
    }
  }

  handleAppTaggingChange(eventTag): void {
    /** function for delete one tag */
    this.log.debug(eventTag);
    let valueList: Array<any> = this.formControl.value || [];

    valueList = valueList.filter(value => value.id !== eventTag.id);
    this.formControl.patchValue(valueList);

    this.onChange.emit(this.formControl.value);
  }

  public onKeyUp(event: KeyboardEvent): void {
    this.log.debug(event);
  }

  public onKeyDown(event: KeyboardEvent): void {
    this.log.debug(event);
  }
}
