<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="Detail Vendor" [isLoading]="formLoading">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-list.table.column.vendorType' | translate }}</label>
          <div class="col-sm-9 text-left">
            {{ vendor.vendorType?.name }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{ 'vendor-list.form.entity' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            {{ vendor.vendorEntity?.name }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-list.table.column.name' | translate }}</label>
          <div class="col-sm-6 text-left">
            {{ vendor.name }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{ 'vendor-list.form.name' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            {{ vendor.user?.name }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">Email</label>
          <div class="col-sm-9 text-left">
            {{ vendor.email || '-' }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{ 'vendor-list.form.phone' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-phone formControlName="phone"></app-phone>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{ 'vendor-list.form.reg' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            {{ vendor.organization?.name }}
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button mode="BYPASS" color="SECONDARY" (onClick)="doCancel()"
            [disabled]="formSaving">
            {{ 'app.button.back' | translate}}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>