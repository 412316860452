import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { File } from '../../core/bean/file';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementVendor } from '../../core/bean/procurement-vendor';
import { ReportService } from '../../core/services/report.service';

@Component({
  templateUrl: './app-popup-registered-letter.component.html',
  styleUrls: ['./app-popup-registered-letter.component.scss']
})
export class AppPopupRegisteredLetterComponent extends BaseComponentComponent {
  @Input() public procurement: Procurement;

  @Input() public logo: File;
  public isLoading: boolean;
  @Input() public companyLocation: string;
  @Input() public procurementVendor: ProcurementVendor;

  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal,
    public reportService: ReportService
  ) {
    super('procurement-reg-vendor');
  }

  @Output() public onClose: EventEmitter<void> = new EventEmitter();

  public onInit(): void {
    this.setStateReady();
  }

  public doPrint(): void {
    this.setStateProcessing();
    this.reportService
      .print('/procurement-reg-vendor/print', this.procurement)
      .subscribe(() => this.setStateReady());
  }
}
