import { Component } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { WidgetAdminOprWorklistModel } from '../../widgets/app-widget-admin-opr-worklist/model/widget-admin-opr-worklist.model';
import { WidgetService } from '../widgets.service';
@Component({
  templateUrl: './app-widget-admin-opr-worklist.component.html',
  styleUrls: ['./app-widget-admin-opr-worklist.component.scss']
})
export class AppWidgetAdminOprWorklistComponent extends BaseComponentComponent {
  public adminOperationWorklistList: Array<WidgetAdminOprWorklistModel> = new Array();
  constructor(public widgetService: WidgetService) {
    super('app-widget-admin-opr-worklist');
  }

  public onInit(): void {
    this.getAndSetDataFromWidgetService();
    this.getAndSetAdminOprWorklist();
  }

  public getAndSetDataFromWidgetService(): void {
    this.adminOperationWorklistList = this.widgetService.getData(this.moduleCode) || [];
  }

  public getAndSetAdminOprWorklist(): void {
    this.httpClientService.get('/widget-admin-opr-worklist/index').subscribe((response: Array<WidgetAdminOprWorklistModel>) => {
      this.widgetService.setData(this.moduleCode, response);
      this.adminOperationWorklistList = response;
      this.global.widgetLoadingStatus.set(this.moduleCode, true);
      this.setStateReady();
    });
  }

  public goToMoreDetail(url: string, code: string): void {
    this.global.routerParams.set('vendorActivityStatusCodeFromWidget', code);
    this.router.navigate([url]);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
