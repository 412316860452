<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="manage-catalog.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        *ngIf="isAllowed"
        [tableResponse]="tableResponse"
        [isServerSide]="true"
        stringUrl="/manage-catalog/index"
      >
        <ng-template #headerButtons>
          <!-- <app-button *ngIf="isOther " mode="BYPASS" (onClick)="doImport()"
            [disabled]="isLoading">
            <em class="fas"
              [ngClass]="!isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'"></em>{{
            'app.button.import' | translate }}
          </app-button> -->
          <app-button (onClick)="doAdd()" *ngIf="isVendor">
            <em class="fas fa-plus"></em> {{ 'app.button.add' | translate }}
          </app-button>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            *ngIf="(!isOther && rowData.catalogStatus.code !== 'VERIFICATION') || (isOther && 
            (rowData.catalogStatus.code !== 'REVISION' && rowData.catalogStatus.code !== 'VERIFICATION'))"
            (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}"
          >
            <em class="fas fa-pencil-alt"></em>
          </app-button>

          <app-button
            size="TN"
            color="PRIMARY"
            *ngIf="rowData.catalogStatus.code === 'VERIFICATION' 
            || (isOther && rowData.catalogStatus.code === 'REVISION') 
            || (!isOther && rowData.catalogStatus.code === 'ACTIVE' && !isVendor)"
            (onClick)="doView(rowData)"
            mode="BYPASS"
            title="{{ 'app.tooltip.view' | translate }}"
          >
            <em class="fas fa-search"></em>
          </app-button>
          <app-button
            size="TN"
            color="DANGER"
            *ngIf="
              rowData.catalogStatus.code !== 'VERIFICATION' &&
              rowData.catalogStatus.code !== 'REVISION' &&
              isOther
            "
            (onClick)="doDelete(rowData)"
            title="{{ 'app.tooltip.delete' | translate }}"
          >
            <em class="fas fa-trash"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
