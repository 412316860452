import { NgModule } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateParserFormatter,
  NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppDatePickerComponent } from './app-date-picker.component';
import { DateFormatter } from './date-formatter';
@NgModule({
  imports: [VendorModule, NgbModule, AppTooltipWrapperModule],
  declarations: [AppDatePickerComponent],
  exports: [AppDatePickerComponent],
  providers: [
    { provide: NgbDateParserFormatter, useValue: new DateFormatter() },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }
  ]
})
export class AppDatepickerModule {}
