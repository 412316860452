<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <div class="app-accordion-row">
      <app-workflow-progress-info
        *ngIf="
          !formLoading &&
          (!torSubmissionResponse.isUserApproval ||
            torSubmissionResponse.isUserApproval === null)
        "
        [workPlan]="workPlan"
        [currentPosition]="moduleCode"
      >
      </app-workflow-progress-info>
      <app-card [isLoading]="formLoading" [isSaving]="formSaving">
        <app-work-plan-info [workPlanId]="workPlanId"></app-work-plan-info>
        <app-accordion [isExpandAll]="true" [isLoading]="formLoading">
          <app-accordion-item header="tor-submission.torSubmission">
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>
              <div
                *ngIf="
                  !formLoading &&
                  (!torSubmissionResponse.isNumberGenerator ||
                    todo === 'view' ||
                    todo === 'detail')
                "
                class="form-group row text-sm-right"
                [ngClass]="
                  formGroup.get('number').isView || formGroup.isView
                    ? ''
                    : 'required'
                "
              >
                <label class="col-sm-3 control-label">{{
                  'tor-submission.form.number' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-text-generated
                    formControlName="number"
                    maxLength="64"
                    [moduleCode]="moduleCode"
                    numberGeneratorCode="NUMBER"
                    autofocus="true"
                  ></app-text-generated>
                </div>
              </div>
              <div
                class="form-group row text-sm-right"
                [ngClass]="!formGroup.isView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'tor-submission.form.date' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-date-picker
                    formControlName="createdDate"
                  >
                  </app-date-picker>
                </div>
              </div>
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'tor-submission.form.organization' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-text-tree
                    formControlName="organization"
                    stringUrl="/organization"
                    isLazy="true"
                    header="Organization"
                  >
                  </app-text-tree>
                </div>
              </div>
              <div
                class="form-group row text-sm-right"
                [ngClass]="!formGroup.isView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'tor-submission.form.subject' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-text-field formControlName="subject" maxLength="128">
                  </app-text-field>
                </div>
              </div>
              <div
                class="form-group row text-sm-right"
                [ngClass]="!formGroup.isView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'tor-submission.form.description' | translate
                }}</label>
                <div
                  class="text-left"
                  [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'"
                >
                  <app-text-area
                    heigth="LG"
                    formControlName="description"
                    maxLength="512"
                  ></app-text-area>
                </div>
              </div>
              <div
                class="form-group row text-sm-right"
                [ngClass]="!formGroup.isView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'tor-submission.form.uploadFile' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-upload-multiple
                    formControlName="uploadFile"
                    [fileUploader]="fileUploader"
                    (onChange)="onChangeFileUpload($event)"
                  >
                  </app-upload-multiple>
                </div>
              </div>
              <div class="row justify-content-center mt-5">
                <app-button
                  color="SECONDARY"
                  class="mr-2"
                  (onClick)="doBack()"
                  [disabled]="formSaving"
                  *ngIf="!torSubmissionResponse.isUserApproval"
                >
                  {{ 'app.button.back' | translate }}
                </app-button>
                <app-button
                  color="PRIMARY"
                  (onClick)="doSave()"
                  [disabled]="formSaving"
                  *ngIf="!formGroup.isView"
                >
                  {{ 'app.button.save' | translate }}
                </app-button>
              </div>
            </form>
          </app-accordion-item>
          <app-accordion-item
            header="tor-submission.monitoringApproval"
            [isHidden]="
              !(
                !formLoading &&
                torSubmissionResponse.approvalPathResponseModel !== null &&
                !torSubmissionResponse.isUserApproval &&
                todo !== 'edit' &&
                todo !== 'add'
              )
            "
          >
            <app-approval-path [model]="approvalPath">
              <ng-template #customText let-userApprover>
                <span class="d-block" [ngStyle]="{ color: '#343434' }">{{
                  userApprover.user?.name || '-'
                }}</span>
                <span
                  class="d-block"
                  [ngStyle]="{ color: '#343434' }"
                  *ngIf="userApprover.delegateUser"
                  >({{ userApprover.delegateUser?.name }})</span
                >
                <span class="d-block" [ngStyle]="{ color: '#343434' }">{{
                  userApprover.position?.name || '-'
                }}</span>
                <span class="d-block" [ngStyle]="{ color: '#343434' }">{{
                  userApprover.organization?.name || '-'
                }}</span>
              </ng-template>
            </app-approval-path>
          </app-accordion-item>
          <app-accordion-item
            header="tor-submission.logApproval"
            [isHidden]="
              !(
                !formLoading &&
                torSubmissionResponse.approvalPathResponseModel !== null &&
                !torSubmissionResponse.isUserApproval &&
                todo !== 'add'
              )
            "
          >
            <app-approval-log
              [moduleCode]="moduleCode"
              [workflowPrcs]="workflowPrcs"
            >
            </app-approval-log>
          </app-accordion-item>
        </app-accordion>
      </app-card>
    </div>
    <app-approval-prcs
      *ngIf="torSubmissionResponse.isUserApproval && !formLoading"
      [workflowModelPrcs]="workflowModelPrcs"
    >
    </app-approval-prcs>
  </div>
</div>
