import { EventEmitter } from '@angular/core';
import { AbstractControl } from '@angular/forms';

declare module '@angular/forms' {
  interface AbstractControl {
    isView: boolean; // this.formGroup.isView || this.formGroup.get('example').isView
    isSubmitted: boolean; // this.formGroup.isSubmitted || this.formGroup.get('example').isSubmitted
    isViewChanges: EventEmitter<boolean>;
    isSubmittedChanges: EventEmitter<boolean>;
    // isValid(): boolean;
    // isInvalid(): boolean;
    setIsView(isView: boolean): void;
    setStateSubmitted(): void;
    resetStateSubmitted(): void;
  }
}

// AbstractControl.prototype.isValid = function(): boolean {
//   return this && (this.touched || this.dirty) && this.valid;
// };

// AbstractControl.prototype.isInvalid = function(): boolean {
//   return this && (this.touched || this.dirty) && this.invalid;
// };

// this.formGroup.isViewChanges.subscribe(isView => console.log(isView))
// this.formGroup.get('example').isViewChanges.subscribe(isView => console.log(isView))
AbstractControl.prototype.isViewChanges = new EventEmitter();
// this.formGroup.isSubmittedChanges.subscribe(isView => console.log(isView))
// this.formGroup.get('example').isSubmittedChanges.subscribe(isView => console.log(isView))
AbstractControl.prototype.isSubmittedChanges = new EventEmitter();

// this.formGroup.setIsView(true|false)
// this.formGroup.get('example').setIsView(true|false)
AbstractControl.prototype.setIsView = function (isView: boolean): void {
  this.isView = isView;
  this.isViewChanges.emit(isView);
};

// this.formGroup.setStateSubmitted()
// this.formGroup.get('example').setStateSubmitted()
AbstractControl.prototype.setStateSubmitted = function (): void {
  this.isSubmitted = true;
  this.isSubmittedChanges.emit(true);
};

AbstractControl.prototype.resetStateSubmitted = function (): void {
  this.isSubmitted = false;
  this.isSubmittedChanges.emit(false);
};
