<app-popup
  header="app-popup-choose-pr.title"
  [isShowHeaderCloseButton]="false"
  [isLoading]="formLoading"
>
  <div class="col" *ngIf="!formLoading">
    <form
      class="form-horizontal popup-choose-pr"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <app-table-xx
        [model]="tableResponse"
        stringUrl="/app-popup-choose-pr/index"
        [isShowCheckBox]="true"
        [isShowButtonExpandCollapse]="true"
      >
        <ng-template #headerFilter>
          <div class="app-table-filter-item" style="min-width: fit-content">
            <div class="d-flex align-items-center">
              <label class="mr-3 mb-0">{{
                'Group By Column' | translate
              }}</label>
              <div
                class="col-sm-6 text-left"
                [ngStyle]="{ 'min-width': '12rem' }"
              >
                <app-dropdown-select
                  size="XL"
                  formControlName="groupBy"
                  type="COMBOBOX"
                  [optionList]="groupByOptionList"
                  (onChange)="doOnChangeGroupBy()"
                  container="body"
                  placeholder="rfp.placeholder.groupBy"
                ></app-dropdown-select>
              </div>
            </div>
          </div>
        </ng-template>
      </app-table-xx>
    </form>
  </div>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button
        class="mr-1"
        color="SECONDARY"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button (onClick)="doChoose()">{{
        'app.button.add' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
