import { HttpClientService } from 'src/app/core/services/http-client.service';
import { ChatState } from '../domain/chat-state';
import { ChatMenuModel } from '../model/chat-menu-model';
export const resolveChatMenu = (
  state: ChatState
): Promise<ChatMenuModel> => {
  return new Promise(resolve => {
    if (state.isServerSide) {
      // do some stuff at here
      const httpClientService = state.injector.get(HttpClientService);
      httpClientService
          .get<ChatMenuModel>(
            state.stringUrl + '/chat/menu/view?roomCode=' + state.roomCode, {}
          )
          .subscribe((menu: ChatMenuModel) => {
            state.menuModel.setMenuModel(menu);
            resolve(menu);
          });
    } else {
    //   resolve(state.model.getMessageList());
    }
  });
};
