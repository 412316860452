import { ThumbnailPlugin } from '../interfaces/available-table-plugin';
import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';
export const AppTableThumbnailPlugin: TablePlugin = {
  name: 'thumbnail',
  before: (data: TablePluginData): void => {
    const thumbnailPlugin: ThumbnailPlugin = data.column
      .plugins as ThumbnailPlugin;

    const imgElement = document.createElement('img');
    imgElement.classList.add('img-thumbnail', 'cursor-pointer');
    imgElement.width = 46;
    imgElement.height = 46;

    imgElement.addEventListener('click', () => {
      data.state.event.emit({
        type: 'CLICK-RECORD',
        data: {
          record: data.record.record
        }
      });
    });

    let tumbnailType =
      typeof thumbnailPlugin === 'string'
        ? 'ITEM-TYPE'
        : thumbnailPlugin.type || 'ITEM-TYPE';
    imgElement.src = `assets/component/table/thumbnail/${tumbnailType.toLowerCase()}-${(
      data.value as string
    ).toLowerCase()}.png`;

    data.element.appendChild(imgElement);
  }
};
