<app-layout
  [isLoading]="formLoading"
  header="evaluation-criteria.breadcrumb.index"
  [backTo]="urlBackOutside"
>
  <ng-template #additionalTitle *ngIf="!formLoading && isShowBadgeStatus">
    <app-badge class="cml-2" *ngIf="todo === 'add'" color="INFO">
      {{ 'evaluation-criteria.label.new' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        procurement.procurementWorklistStatus.code ===
          global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_WAITING_APPROVAL
      "
      color="WARNING"
    >
      {{
        'dynamic-master-attribute.worklistStatus.waitingApproval' | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        procurement.procurementWorklistStatus.code ===
          global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT
      "
      color="SECONDARY"
    >
      {{ 'dynamic-master-attribute.worklistStatus.draft' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        procurement?.procurementWorklistStatus?.code ===
          global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_HOLD
      "
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.worklistStatus.hold' | translate }}
    </app-badge>
    <app-badge-catalog
      class="cml-2"
      *ngIf="procurement?.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
  </ng-template>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-alert-x
          color="DANGER"
          *ngIf="
            !formLoading &&
            procurement.procurementStatus?.code ===
              global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
          "
        >
          {{
            'evaluation-criteria.alert.msg.sorryWaitingCancelationApproval'
              | translate
          }}
        </app-alert-x>
        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="evaluationCriteriaResponse.workflowStepInfoModel"
        >
        </app-workflow-step-info>
        <app-card
          header="evaluation-criteria.detail.general.title"
          tips="evaluation-criteria.detail.general.tips"
        >
          <app-procurement-info [procurementId]="procurement.id">
          </app-procurement-info>
        </app-card>

        <app-card>
          <app-alert-x
            color="DANGER"
            *ngIf="
              !formGroup.isView &&
              isSubmit &&
              admProcurementCriteriaDetailList.length === 0
            "
            >{{
              'evaluation-criteria.validation.criteria' | translate
            }}</app-alert-x
          >
          <app-table-xx
            header="evaluation-criteria.detail.administrationCriteria.title"
            tips="evaluation-criteria.detail.administrationCriteria.tips"
            [model]="tableResponseAdministration"
            [isShowSearch]="admProcurementCriteriaDetailList?.length !== 0"
            [isShowPagination]="false"
            *ngIf="!formLoading"
          >
            <ng-container
              *ngIf="
                admProcurementCriteriaDetailList.length === 0 &&
                !isSubmit &&
                todo !== 'view'
              "
            >
              <ng-template #alertWrapper>
                <app-fullfilled></app-fullfilled>
              </ng-template>
            </ng-container>

            <ng-template #headerButtons>
              <app-button
                *ngIf="!formGroup.isView"
                (onClick)="doAddAdministration()"
                ><em class="pir pi-plus-circle"></em>
                {{ 'evaluation-criteria.button.addCriteria' | translate }}
              </app-button>
            </ng-template>
            <ng-container *ngIf="!formGroup.isView">
              <ng-template #actionButtons let-rowData>
                <app-button
                  size="TN"
                  (onClick)="doDeleteAdministration(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="pir pi-trash-alt"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>
              </ng-template>
            </ng-container>
          </app-table-xx>
        </app-card>

        <app-card>
          <app-alert-x
            color="DANGER"
            *ngIf="
              !formGroup.isView &&
              isSubmit &&
              tecProcurementCriteriaDetailList?.length === 0 &&
              (procurement.biddingEvaluation.code ===
                global.appConstant.pm
                  .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM ||
                (procurement.biddingEvaluation.code !==
                  global.appConstant.pm
                    .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM &&
                  formGroup.value.total !== 100))
            "
            >{{
              'evaluation-criteria.validation.criteria' | translate
            }}</app-alert-x
          >
          <app-table-xx
            header="evaluation-criteria.detail.technicalCriteria.title"
            tips="evaluation-criteria.detail.technicalCriteria.tips"
            [model]="tableResponseTechnical"
            [isShowSearch]="tecProcurementCriteriaDetailList?.length !== 0"
            [isShowPagination]="false"
          >
            <ng-container
              *ngIf="
                tecProcurementCriteriaDetailList.length === 0 &&
                !isSubmit &&
                todo !== 'view'
              "
            >
              <ng-template #alertWrapper>
                <app-fullfilled></app-fullfilled>
              </ng-template>
            </ng-container>
            <ng-template #headerButtons>
              <app-button *ngIf="!formGroup.isView" (onClick)="doAddTechnical()"
                ><em class="pir pi-plus-circle"></em>
                {{ 'evaluation-criteria.button.addCriteria' | translate }}
              </app-button>
            </ng-template>
            <ng-container *ngIf="!formGroup.isView">
              <ng-template #actionButtons let-rowData>
                <app-button
                  size="TN"
                  (onClick)="doDeleteTechnical(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="pir pi-trash-alt"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>
              </ng-template>
            </ng-container>
            <div
              *ngIf="
                tableResponseTechnical.getRecordList().length > 0 &&
                procurement.biddingEvaluation.code !==
                  global.appConstant.pm
                    .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
              "
            >
              <ng-template #row>
                <tr>
                  <td *ngIf="todo !== 'view'"></td>
                  <td class="text-left">
                    {{
                      'evaluation-criteria.totalTechnicalCriteria' | translate
                    }}
                  </td>
                  <td>
                    <div class="td-content text-center">
                      <app-text-field
                        formControlName="total"
                        type="decimal"
                        size="XL"
                        disable="true"
                      ></app-text-field>
                      <p
                        ><small
                          class="text-danger"
                          *ngIf="totalTechCriteria && totalTechCriteria !== 100"
                          >Total must be 100</small
                        ></p
                      >
                    </div>
                  </td>
                </tr>
              </ng-template>
            </div>
          </app-table-xx>
          <div
            *ngIf="
              procurement.biddingEvaluation.code !==
              global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
            "
          >
            <br />
            <h4 class="separator"
              >{{ 'evaluation-criteria.form.scoringCriteria' | translate }}
            </h4>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'evaluation-criteria.form.appliedFinalScoring' | translate
              }}</label>
              <div
                *ngIf="
                  procurement.biddingEvaluation.code !==
                    global.appConstant.pm
                      .BIDDING_EVALUATION_CODE_PASSING_GRADE && todo !== 'view'
                "
                class="col-sm-9 text-left d-flex align-items-center"
              >
                <app-toggle-switch
                  *ngIf="
                    procurement.biddingEvaluation.code !==
                    global.appConstant.pm.BIDDING_EVALUATION_CODE_PASSING_GRADE
                  "
                  class="cml-2"
                  formControlName="isAppliedFinalScoring"
                  (onChange)="doOnChangeAppliedFinalScore()"
                ></app-toggle-switch>
              </div>
              <div
                class="col-sm-9 text-left"
                *ngIf="
                  procurement.biddingEvaluation.code ===
                    global.appConstant.pm
                      .BIDDING_EVALUATION_CODE_PASSING_GRADE || todo === 'view'
                "
              >
                {{
                  formGroup.value.isAppliedFinalScoring
                    ? ('evaluation-criteria.yes' | translate)
                    : ('evaluation-criteria.no' | translate)
                }}
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'evaluation-criteria.form.minimumScore' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  formControlName="minimumScore"
                  type="decimal"
                  (onChange)="doOnChangeAppliedFinalScore()"
                >
                </app-text-field>
              </div>
            </div>
          </div>
        </app-card>

        <app-card
          header="evaluation-criteria.detail.finalizationCriteria.title"
          tips="evaluation-criteria.detail.finalizationCriteria.tips"
        >
          <h4 class="separator">
            <div class="row">
              <div class="cml-2 d-flex align-items-center">
                {{ 'evaluation-criteria.isPricingThreshold' | translate }}
              </div>
              <div
                class="cml-2 d-flex align-items-center"
                *ngIf="todo !== 'view'"
              >
                <app-toggle-switch
                  formControlName="isPricingThreshold"
                  (onChange)="doOnChangePricingThreshold()"
                ></app-toggle-switch>
              </div>
            </div>
          </h4>
          <div *ngIf="formGroup.value.isPricingThreshold">
            <app-fullfilled *ngIf="todo !== 'view'"></app-fullfilled>

            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'evaluation-criteria.form.upperThreshold' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  formControlName="upperThreshold"
                  type="decimal"
                  groupText="%"
                  placeholder="{{
                    'evaluation-criteria.placeholder.percentageNumber'
                      | translate
                  }}"
                >
                </app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'evaluation-criteria.form.lowerThreshold' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  formControlName="lowerThreshold"
                  type="decimal"
                  groupText="%"
                  placeholder="{{
                    'evaluation-criteria.placeholder.percentageNumber'
                      | translate
                  }}"
                >
                </app-text-field>
              </div>
            </div>
          </div>

          <div
            *ngIf="
              procurement.biddingEvaluation.code ===
              global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
            "
          >
            <h4 class="separator"
              >{{ 'evaluation-criteria.meritPointScoringStrategy' | translate }}
            </h4>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'evaluation-criteria.form.technicalPercentage' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  formControlName="technicalPercentage"
                  type="decimal"
                  groupText="%"
                  (onChange)="doOnChangetechPricingPercentage()"
                >
                </app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'evaluation-criteria.form.pricingPercentage' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  formControlName="pricePercentage"
                  type="decimal"
                  groupText="%"
                  (onChange)="doOnChangetechPricingPercentage()"
                >
                </app-text-field>
              </div>
            </div>
          </div>
        </app-card>

        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            evaluationCriteriaResponse.approvalPathResponseModel &&
            !evaluationCriteriaResponse.isUserApproval &&
            procurement.procurementWorklistStatus.code !==
              global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT
          "
          [workflowPrcs]="procurement.workflowPrcs"
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="
            evaluationCriteriaResponse?.approvalPathResponseModel
          "
        ></app-approval-monitoring>

        <app-card
          header="evaluation-criteria.detail.changeHistory.title"
          tips="evaluation-criteria.detail.changeHistory.tips"
          *ngIf="
            procurement.procurementWorklistStatus.code !==
              global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT &&
            procurement.procurementWorklistStatus.code !==
              global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_NEW
          "
        >
          <app-change-history
            *ngIf="!formLoading"
            [moduleId]="module?.id"
            [objectId]="procurement.id"
          ></app-change-history>
        </app-card>

        <div class="button-group button-group-center" *ngIf="!formGroup.isView">
          <app-button
            color="LIGHT"
            [outline]="true"
            (onClick)="doSave()"
            [disabled]="
              formSaving ||
              (tecProcurementCriteriaDetailList.length === 0 &&
                admProcurementCriteriaDetailList.length === 0)
            "
            >{{ 'app.button.save' | translate }}
          </app-button>
          <app-button
            (onClick)="doSubmit()"
            [disabled]="
              formSaving ||
              (tecProcurementCriteriaDetailList.length === 0 &&
                admProcurementCriteriaDetailList.length === 0)
            "
            >{{ 'app.button.submit' | translate }}</app-button
          >
        </div>
        <app-approval-prcs-x
          *ngIf="!formLoading && evaluationCriteriaResponse.isUserApproval"
          [workflowModelPrcs]="procurement.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips *ngIf="!formLoading"></app-tips>
  </ng-template>
</app-layout>
