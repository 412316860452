<div class="row">
  <div class="col-12">
    <form [formGroup]="model.formGroup">
      <ng-container>
        <ng-container *ngFor="let option of values; let i = index">
          <div
            class="form-group row text-sm-right"
            [ngClass]="[
              option.catalogAttribute.function.includes('Mandatory') &&
              !model.isView && !isFilter
                ? 'required'
                : ''
            ]"
          >
            <label class="col-sm-3 control-label">{{
              'dynamic-attribute.' +
                values[i].catalogAttribute.translationKey.key | translate
            }}</label>
            <div
              class="col-sm-9 text-left"
              [ngClass]="model.isView ? 'custom-form-object' : ''"
            >
              <div
                *ngIf="option.catalogAttribute.formObject.code === 'TEXT-FIELD'"
              >
                <app-text-field
                  [formControlName]="'generated' + i"
                  maxLength="100"
                ></app-text-field>
              </div>

              <div
                *ngIf="
                  option.catalogAttribute.formObject.code === 'UPLOAD-SINGLE'
                "
              >
                <p>Upload Single</p>
              </div>

              <div
                *ngIf="option.catalogAttribute.formObject.code === 'COMBO-BOX'"
              >
                <app-combo-box
                  [formControlName]="'generated' + i"
                  [optionList]="model.mapperOptionsList.get('optionList' + i)"
                ></app-combo-box>
              </div>

              <div
                *ngIf="option.catalogAttribute.formObject.code === 'CHECK-BOX'"
              >
                <app-check-box
                  [formControlName]="'generated' + i"
                ></app-check-box>
              </div>

              <div
                *ngIf="
                  option.catalogAttribute.formObject.code === 'RADIO-BUTTON'
                "
              >
                <app-radio
                  [formControlName]="'generated' + i"
                  [optionList]="model.mapperOptionsList.get('optionList' + i)"
                  optionValue="value"
                ></app-radio>
              </div>

              <div
                *ngIf="option.catalogAttribute.formObject.code === 'TEXT-AREA'"
              >
                <app-text-area
                  [formControlName]="'generated' + i"
                ></app-text-area>
              </div>

              <div
                *ngIf="
                  option.catalogAttribute.formObject.code === 'DATE-PICKER'
                "
              >
                <app-date-picker
                  [formControlName]="'generated' + i"
                ></app-date-picker>
              </div>

              <div
                *ngIf="option.catalogAttribute.formObject.code === 'WYSIWYG'"
              >
                <app-wysiwyg
                  size="XL"
                  [formControlName]="'generated' + i"
                ></app-wysiwyg>
              </div>

              <div
                *ngIf="
                  option.catalogAttribute.formObject.code === 'AUTOCOMPLETE'
                "
              >
                <app-auto-complete
                  [formControlName]="'generated' + i"
                  stringUrl="/manage-catalog/auto-complete-list-of-value"
                  [params]="{ catalogAttributeId: option.catalogAttribute.id }"
                >
                </app-auto-complete>
              </div>

              <div *ngIf="option.catalogAttribute.formObject.code === 'CHOSEN'">
                <app-chosen
                  [formControlName]="'generated' + i"
                  [optionList]="model.mapperOptionsList.get('optionList' + i)"
                ></app-chosen>
              </div>

              <div
                *ngIf="
                  option.catalogAttribute.formObject.code === 'TOGGLE-SWITCH'
                "
              >
                <div *ngIf="model.isView">
                  <p>{{
                    (model.formGroup.get('generated' + i).value === true
                      ? 'app-form-object.yes'
                      : 'app-form-object.no'
                    ) | translate
                  }}</p>
                </div>
                <div *ngIf="!model.isView">
                  <app-toggle-switch
                    [formControlName]="'generated' + i"
                  ></app-toggle-switch>
                </div>
              </div>

              <div
                *ngIf="
                  option.catalogAttribute.formObject.code ===
                  'TEXT-FIELD-NUMERIC'
                "
              >
                <app-text-field
                  [formControlName]="'generated' + i"
                  type="integer"
                ></app-text-field>
              </div>

              <div
                *ngIf="
                  option.catalogAttribute.formObject.code === 'FIELD-PRICE'
                "
              >
                <app-currency
                  [formControlName]="'generated' + i"
                  [optionList]="currencyOptionList"
                ></app-currency>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </div>
</div>
