import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppTableModule } from '../table/components/app-table/app-table.module';
import { AppChangeHistoryComponent } from './app-change-history.component';

@NgModule({
  imports: [VendorModule, AppTableModule, AppCardModule],
  declarations: [AppChangeHistoryComponent],
  exports: [AppChangeHistoryComponent]
})
export class AppChangeHistoryModule {}
