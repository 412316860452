import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import {
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import { UserNotification } from '../../../../core/bean/user-notification';
import { ActiveDropDown } from '../../../../core/components/app-drop-down/active-drop-down.service';
import { Global } from '../../../../core/global/global';
import { Response } from '../../../../core/model/response-model';
import { ResponseStatusModel } from '../../../../core/model/response-status-model';
import { UserNotificationWebsocketService } from '../../../../core/services/websocket/user-notification-websocket.service';
import { UserNotificationResponse } from '../../../../pages/user-notification/user-notification-response';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@Component({
  selector: 'theme-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      multi: true
    }
  ]
})
export class NotificationComponent implements OnInit {
  public notificationList: Array<any> = new Array();
  public userNotificationList: EventEmitter<UserNotification[]> =
    new EventEmitter();
  public isLoading: boolean;
  public totalUnreadNotif: number;
  constructor(
    public global: Global,
    public router: Router,
    public datePipe: DatePipe,
    public userNotificationWebsocketService: UserNotificationWebsocketService,
    private activeDropDown: ActiveDropDown
  ) {}

  ngOnInit(): void {
    this.getTotalUnreadNotification();
    this.userNotificationWebsocketService.connect();
  }

  public getTotalUnreadNotification(): void {
    this.global.httpClientService
      .get('/user-notification/total-unread-notification')
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          const userNotificationResponse = new UserNotificationResponse();
          userNotificationResponse.totalUnreadNotification = response.body;
          this.userNotificationWebsocketService.setReceivedMessage(
            userNotificationResponse
          );
        }
      });
  }

  public doShowNotification(): void {
    this.isLoading = true;
    this.getUserNotification();
  }

  public getUserNotification(): void {
    this.isLoading = true;
    this.global.httpClientService
      .get<Response<UserNotificationResponse>>(
        '/user-notification/user-notification-list'
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.userNotificationWebsocketService.setReceivedMessage(
            response.body
          );
        }
        this.isLoading = false;
      });
  }

  public doReadMore(
    event: MouseEvent,
    userNotification: UserNotification
  ): void {
    event.preventDefault();
    event.stopPropagation();
    if (!userNotification.readDate) {
      this.global.httpClientService
        .post<Response<UserNotification>>(
          '/user-notification/read-notification',
          userNotification.id
        )
        .subscribe(() => {});
    }
    this.activeDropDown.close();
    this.setRouterNavigate(userNotification.detailUrl);
  }

  public setRouterNavigate(detailUrl: string): void {
    if (detailUrl) {
      if (detailUrl.includes('http')) {
        window.location.href = detailUrl;
      } else {
        this.router.navigate([detailUrl]);
      }
    }
  }

  public doMarkAsRead(): void {
    if (
      this.userNotificationWebsocketService.receivedMessage
        .totalUnreadNotification
    ) {
      this.global.httpClientService
        .post<Response<UserNotification>>('/user-notification/mark-as-read', {})
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.userNotificationWebsocketService.receivedMessage.totalUnreadNotification =
              null;
            this.userNotificationWebsocketService.setReceivedMessage(
              this.userNotificationWebsocketService.receivedMessage
            );
          }
        });
    }
    this.activeDropDown.close();
  }

  public doSeeMore(): void {
    this.activeDropDown.close();
    this.router.navigate(['/pages/user-notification']);
  }
}
