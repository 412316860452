import { BaseEntity } from './../base/base-entity';
import { File } from './../bean/file';
import { User } from './../bean/user';
export class UserDelegationEntity extends BaseEntity {
  user: User;
  delegatedUser: User;
  docFile: File;
  startDate: Date;
  endDate: Date;
  isActive: boolean;
  sequence: number;
}
