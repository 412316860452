<app-popup
  header="rfq.popupChooseRfqItem.title"
  [isShowHeaderCloseButton]="false"
  [isLoading]="formLoading"
>
  <form
    class="form-horizontal popup-rfq-item"
    [formGroup]="formGroup"
    novalidate
    *ngIf="!formLoading"
  >
    <div
      class="form-group row text-sm-right"
      [ngStyle]="{
        height: '64px',
        left: '32px',
        top: '87px',
        'margin-left': 'auto',
        'margin-right': 'auto',
        'align-content': 'center',
        background: 'var(--gray-100)',
        'border-radius': '5px'
      }"
    >
      <div class="col-sm-12 text-left">
        <app-radio
          size="MD"
          formControlName="rfqItemType"
          [optionList]="rfqItemTypeList"
          position="HORIZONTAL"
          (onChange)="onChangeType()"
        ></app-radio>
      </div>
    </div>
    <ng-container *ngIf="!isFromRfp">
      <div *ngIf="!formLoading">
        <app-table-xx
          [model]="tableResponsePrItem"
          stringUrl="/app-popup-choose-pr/index"
          [isShowCheckBox]="true"
          [isShowButtonExpandCollapse]="true"
        >
          >
          <ng-template #headerFilter>
            <div class="app-table-filter-item" style="min-width: fit-content">
              <div class="d-flex align-items-center">
                <label class="mr-4 mb-0">{{
                  'rfq.popupChooseRfqItem.groupBy' | translate
                }}</label>
                <app-dropdown-select
                  size="XL"
                  formControlName="groupBy"
                  type="COMBOBOX"
                  [optionList]="groupByOptionList"
                  (onChange)="doOnChangeGroupBy()"
                  container="body"
                  placeholder="rfq.placeholder.groupBy"
                ></app-dropdown-select>
              </div>
            </div>
          </ng-template>
        </app-table-xx>
      </div>
    </ng-container>
    <ng-container *ngIf="isFromRfp">
      <div *ngIf="!formLoading">
        <app-table-xx
          [model]="tableResponseRfpItem"
          (onClick)="doShowRfpDetail($event)"
          stringUrl="/rfq/index-popup-rfp"
          [isShowCheckBox]="true"
          [isMultipleSelect]="false"
        >
        </app-table-xx>
      </div>
    </ng-container>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button
        class="mr-1"
        color="SECONDARY"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button (onClick)="doChoose()">{{
        'app.button.add' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
