import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { OptionListModel } from '../../core/model/option-list-model';
import { WorkloadGenerateModel } from './workload-generate-model';

@Component({
  templateUrl: './app-popup-move-user.component.html'
})
export class AppPopupMoveUserComponent extends BaseComponentComponent {
  @Input() public workloadGenerateModelList: WorkloadGenerateModel[] = [];
  @Input() public conditionName: string;
  @Output() public onChange: EventEmitter<WorkloadGenerateModel[]> =
    new EventEmitter();

  public workloadGenerateModelOptionList: OptionListModel<WorkloadGenerateModel> =
    new OptionListModel(false, 'conditionName');

  public constructor(public ngbActiveModal: NgbActiveModal) {
    super('workload');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      workloadGenerateModel: [null]
    });
  }

  public setFormGroup(): void {
    const modelList = this.workloadGenerateModelList.filter(
      model => model.conditionName !== this.conditionName
    );
    this.workloadGenerateModelOptionList.setRequestValues(modelList);
    this.setStateReady();
  }

  public doCancel(): void {
    this.ngbActiveModal.close();
  }

  public doAdd(): void {
    if (this.formGroup.value.workloadGenerateModel) {
      this.onChange.emit(this.formGroup.value.workloadGenerateModel);
    } else {
      this.global.alertService.showError(
        'workload.popup.alert.moveUser',
        '.popup-move-user'
      );
    }
  }
}
