import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppToggleSwitchComponent } from './app-toggle-switch.component';
@NgModule({
  imports: [VendorModule, AppTooltipWrapperModule],
  declarations: [AppToggleSwitchComponent],
  exports: [AppToggleSwitchComponent]
})
export class AppToggleSwitchModule {}
