import { DocGroup } from './../bean/doc-group';
import { BaseEntity } from '../base/base-entity';
import { Module } from '../bean/module';
import { Translation } from '../bean/translation';

export class TranslationKeyEntity extends BaseEntity {
    module: Module;
    translationList: Translation[];
    key: string;
    docGroupList: DocGroup[];
}
