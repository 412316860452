import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { CatalogRegion } from 'src/app/core/bean/catalog-region';
import { CatalogRegionBulkPrice } from 'src/app/core/bean/catalog-region-bulk-price';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './app-popup-region-bulk-price-item.component.html',
  styles: [
    '.table th.fit{ white-space: nowrap; width: 200px; min-width: 200px;}'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PopupRegionBulkPriceItemComponent extends BaseModuleComponent {
  @Input() catalogRegion: CatalogRegion;
  @Input() isView: boolean;
  @Input() catalogRegionBulkPriceLists: CatalogRegionBulkPrice[] = []; //catalogRegionBulkPrice yang sudah ada (akan ada saat edit/view)
  @Output() public onChange: EventEmitter<CatalogRegionBulkPrice[]> = new EventEmitter();

  // public remainingOfminimumOrderTotalCanInput: number;
  public disableAddBulkPriceButton: boolean = false;

  constructor(translateService: TranslateService) {
    super('manage-catalog', translateService);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.isView) {
      this.setViewOnly();
    }
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      isBulkPrice: [this.catalogRegion.isBulkPrice],
      catalogRegionBulkPriceList: this.formBuilder.array([])
    });
  }

  public setFormGroup(): void {
    // this.remainingOfminimumOrderTotalCanInput = this.catalogRegion.remainingQuantity;
    if (this.catalogRegionBulkPriceLists && this.catalogRegionBulkPriceLists.length > 0) {
      this.catalogRegionBulkPriceLists.forEach(
        (catalogRegionBulkPrice: CatalogRegionBulkPrice) => {
          const formGroup = this.formBuilder.group({
            catalogRegion: [this.catalogRegion],
            id: catalogRegionBulkPrice.id,
            minimumOrderTotal: catalogRegionBulkPrice.minimumOrderTotal,
            discount: catalogRegionBulkPrice.discount,
            discountedPrice: catalogRegionBulkPrice.discountedPrice,
            crudOperation: this.global.appConstant.core.CRUD_OPERATION_UPDATE
          });

          if (this.isView) {
            formGroup.setIsView(true);
          }
          this.catalogRegionBulkPriceList.push(formGroup);
        }
      );
    } else if (!this.isView) {
      this.buildFormArray();
    }
    this.setStateReady();
  }

  public get catalogRegionBulkPriceList(): FormArray {
    return this.formGroup.get('catalogRegionBulkPriceList') as FormArray;
  }

  public buildFormArray(): void {
    this.catalogRegionBulkPriceList.push(this.newcatalogRegionBulkPrice());
  }

  public newcatalogRegionBulkPrice(): FormGroup {
    return this.formBuilder.group({
      catalogRegion: [this.catalogRegion],
      id: [null],
      minimumOrderTotal: [null, Validators.required()],
      discount: [null, Validators.required()],
      discountedPrice: [null, Validators.required()],
      crudOperation: this.global.appConstant.core.CRUD_OPERATION_INSERT
    });
  }

  public onInputDiscount(catalogRegionBulkPrice: FormGroup, index: number): void {
    const discountForm = catalogRegionBulkPrice.get('discount');
    const discountedPriceForm = catalogRegionBulkPrice.get('discountedPrice');
    const discountedPrice =
      (+discountForm.value * +this.catalogRegion.price) / 100;
    const discountBefore =
      this.catalogRegionBulkPriceList.controls[index - 1]?.get('discount').value;
    const discountAfter =
      this.catalogRegionBulkPriceList.controls[index + 1]?.get('discount').value;

    if (+discountBefore && +discountForm.value < +discountBefore) {
      discountForm.setValidators(
        Validators.compose([
          Validators.required(),
          Validators.min(+discountBefore)
        ])
      );

      discountForm.setErrors({
        message: this.translateService.instant('app.validation.min')
      });
      discountForm.markAsDirty();
      discountForm.markAsTouched();
    }

    if (+discountAfter && +discountAfter < +discountForm.value) {
      discountForm.setValidators(
        Validators.compose([
          Validators.required(),
          Validators.max(+discountAfter)
        ])
      );

      discountForm.setErrors({
        message: this.translateService.instant('app.validation.max')
      });
      discountForm.markAsDirty();
      discountForm.markAsTouched();
    }
    discountedPriceForm.patchValue(discountedPrice);
  }

  public onInputMinimumOrderTotal(
    catalogRegionBulkPrice: FormGroup,
    index: number
  ): void {
    const minimumOrderTotalForm = catalogRegionBulkPrice.get('minimumOrderTotal');
    const minimumOrderTotalBefore =
      this.catalogRegionBulkPriceList.controls[index - 1]?.get(
        'minimumOrderTotal'
      ).value;
    const minimumOrderTotalAfter =
      this.catalogRegionBulkPriceList.controls[index + 1]?.get(
        'minimumOrderTotal'
      ).value;
      
    // let sumOfminimumOrderTotalInputed: number = 0;
    // this.catalogRegionBulkPriceList.controls.forEach(catalogRegionBulkPriceListControl => {
    //   sumOfminimumOrderTotalInputed += catalogRegionBulkPriceListControl.value.minimumOrderTotal;
    // })
    // this.remainingOfminimumOrderTotalCanInput = this.remainingOfminimumOrderTotalCanInput - sumOfminimumOrderTotalInputed;
      
    if (
      +minimumOrderTotalBefore &&
      +minimumOrderTotalForm.value <= +minimumOrderTotalBefore
    ) {
      minimumOrderTotalForm.setValidators(
        Validators.compose([
          Validators.required(),
          Validators.min(+minimumOrderTotalBefore + 1)
        ])
      );
      minimumOrderTotalForm.setErrors({
        message: this.translateService.instant('app.validation.min')
      });
      minimumOrderTotalForm.markAsDirty();
      minimumOrderTotalForm.markAsTouched();
    }

    if (
      +minimumOrderTotalAfter &&
      +minimumOrderTotalAfter <= +minimumOrderTotalForm.value
    ) {
      minimumOrderTotalForm.setValidators(
        Validators.compose([
          Validators.required(),
          Validators.max(+minimumOrderTotalAfter - 1)
        ])
      );

      minimumOrderTotalForm.setErrors({
        message: this.translateService.instant('app.validation.max')
      });
      minimumOrderTotalForm.markAsDirty();
      minimumOrderTotalForm.markAsTouched();
    }

    // var quantity = 0;
    // this.catalogRegion.catalogRegionDetailList.forEach(catalogRegionDetail => {
    //   quantity = quantity + catalogRegionDetail.quantity;
    // });

    // if(+minimumOrderTotalForm.value > quantity){
    //   this.disableAddBulkPriceButton = true;
    //   minimumOrderTotalForm.setValidators(
    //     Validators.compose([
    //       Validators.required(),
    //       Validators.max(+minimumOrderTotalAfter - 1)
    //     ])
    //   );

    //   minimumOrderTotalForm.setErrors({
    //     message: this.translateService.instant('app.validation.max')
    //   });
    //   minimumOrderTotalForm.markAsDirty();
    //   minimumOrderTotalForm.markAsTouched();
    // }else{
      this.disableAddBulkPriceButton = false;
    // }
  }

  public doAdd(): void {
    this.catalogRegionBulkPriceList.push(this.newcatalogRegionBulkPrice());
  }

  public doDelete(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.delete(index);
        }
      });
  }

  public delete(index: number): void {
    if(this.catalogRegionBulkPriceList.length <= index){
      index = 0;
    }
    if(this.catalogRegionBulkPriceList.length < 2){
      this.catalogRegionBulkPriceList.controls[index].patchValue({
        crudOperation: this.global.appConstant.core.CRUD_OPERATION_DELETE
      });
      this.catalogRegionBulkPriceList.removeAt(index)
      this.doAdd();
    }else{
      const catalogRegionBulkPrice = this.catalogRegionBulkPriceList.controls[index].value;
      if (catalogRegionBulkPrice.id === null) {
        this.catalogRegionBulkPriceList.removeAt(index);
      } else {
        if (
          this.catalogRegionBulkPriceList.controls[index].value.crudOperation ===
          this.global.appConstant.core.CRUD_OPERATION_INSERT
        ) {
          this.catalogRegionBulkPriceList.removeAt(index);
        } else {
          this.catalogRegionBulkPriceList.controls[index].patchValue({
            crudOperation: this.global.appConstant.core.CRUD_OPERATION_DELETE
          });
          this.catalogRegionBulkPriceList.removeAt(index)
        }
      }
    } 
  }

  public resolveTableIndex(currentIndex: number, dataList: Array<any>): number {
    const dataListCopy = Array.from(dataList);
    const dataListWithHaveNocrudOperation = dataListCopy
      .splice(0, currentIndex)
      .filter(
        data =>
          data.value.crudOperation ===
          this.global.appConstant.core.CRUD_OPERATION_DELETE
      );
    return currentIndex + 1 - dataListWithHaveNocrudOperation.length;
  }

  public doSave(): void {
    this.validate();
    let isValid: boolean;
    if(this.formGroup.get('isBulkPrice').value === false){
      isValid = true;
    }else{
      isValid = this.formGroup.valid;
    }
    
    if (isValid) {
      // update isBulkPrice
      this.catalogRegionBulkPriceList.value.forEach(catalogRegionBulkPrice => {
        catalogRegionBulkPrice.catalogRegion.isBulkPrice =
          this.formGroup.get('isBulkPrice').value;
      });
      this.onChange.emit(this.catalogRegionBulkPriceList.value);
    }
  }

  public doBulkValidation(): void {
    var index = 0;
    this.catalogRegionBulkPriceList.value.forEach(catalogRegionBulk => {
      catalogRegionBulk.isBulkPrice = this.formGroup.get('isBulkPrice').value;
      if(this.formGroup.get('isBulkPrice').value === true){
        this.delete(index);
        index++;
      }
    });
  }
}
