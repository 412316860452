<app-card legend="Confirmation" header="registration.titleConfirmation">
  <form
    class="form-horizontal main-row"
    [formGroup]="formGroup"
    *ngIf="!isEmpty"
  >
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'registration.form.vendorType' | translate
      }}</label>
      <div class="col-sm-7 text-left">
        <p>{{ registrationVendorRequest.vendorType.name }}</p>
      </div>
    </div>

    <div *ngIf="setCompanyNameLabelActive">
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'registration.form.companyName' | translate
        }}</label>
        <div class="col-sm-7 text-left">
          <p
            >{{ registrationVendorRequest.companyName }},
            {{ registrationVendorRequest.vendorEntity.name }}</p
          >
        </div>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <div
        *ngIf="setCompanyNameLabelActive; then ifBlock; else elseBlock"
      ></div>
      <ng-template #ifBlock>
        <label class="col-sm-3 control-label">{{
          'registration.form.picName' | translate
        }}</label>
        <div class="col-sm-7 text-left">
          <p>{{ registrationVendorRequest.name }}</p>
        </div>
      </ng-template>
      <ng-template #elseBlock>
        <label class="col-sm-3 control-label">{{
          'registration.form.personName' | translate
        }}</label>
        <div class="col-sm-7 text-left">
          <p
            >{{ registrationVendorRequest.name }},
            {{ registrationVendorRequest.vendorEntity.name }}</p
          >
        </div>
      </ng-template>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'registration.form.email' | translate
      }}</label>
      <div class="col-sm-7 text-left">
        <p>{{ registrationVendorRequest.email }}</p>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'registration.phone' | translate
      }}</label>
      <div class="col-sm-7 text-left">
        <p>{{ phone }}</p>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'registration.form.registeredOn' | translate
      }}</label>
      <div class="col-sm-7 text-left">
        <p>{{ lastOrganization.name }}</p>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'registration.form.taxNumber' | translate
      }}</label>
      <div class="col-sm-7 text-left">
        <p>{{ taxNumber }}</p>
      </div>
    </div>

    <div class="form-group row">
      <p class="col-sm-10 text-left text-danger"
        >*) {{ 'registration.note.recheckData' | translate }}</p
      >
      <p class="col-sm-10 text-left text-danger"
        >*) {{ 'registration.note.dontDuplicateEntities' | translate }}</p
      >
    </div>
  </form>
  <div class="button-group button-group-center">
    <div *ngIf="dissappear">
      <app-button color="SECONDARY" (onClick)="doBack()" class="mr-1"
        >{{ 'app.button.back' | translate }}
      </app-button>
      <app-button color="PRIMARY" (onClick)="doSave()">{{
        'app.button.submit' | translate
      }}</app-button>
    </div>
    <div *ngIf="appear">
      <app-button color="PRIMARY" (onClick)="doPortal()">{{
        'app.button.ok' | translate
      }}</app-button>
    </div>
  </div>
</app-card>
