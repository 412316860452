<dashboard-widget-card
  [header]="
    (moduleCode + '.welcome' | translate) + ' ' + global.userSession?.user.name
  "
  [isLoading]="model.isLoading"
>
  <ng-container *ngIf="!model.isLoading">
    <p *ngIf="loginInformationVendorResponse == null">{{
      moduleCode + '.sorryYourRoleCannotUseThisWidget' | translate
    }}</p>
    <table
      *ngIf="loginInformationVendorResponse != null"
      class="table table-striped"
      aria-describedby="Table"
    >
      <tr>
        <th scope="col" class="border">{{
          moduleCode + '.activityStatus' | translate
        }}</th>
        <td class="border">{{
          loginInformationVendorResponse?.currentStatus
        }}</td>
      </tr>
      <tr>
        <th scope="col" class="border"
          >{{ moduleCode + '.certificateNumber' | translate }}
        </th>
        <td
          class="border"
          *ngIf="loginInformationVendorResponse?.vendorCertificate != null"
        >
          {{ certificateNumber || '-' }}
          <span
            *ngIf="
              loginInformationVendorResponse.vendorCertificate.certificateStatus
                .code === global.appConstant.vm.CERTIFICATE_STATUS_EXPIRED
            "
            [style.color]="'red'"
          >
            ({{
              loginInformationVendorResponse?.vendorCertificate
                ?.certificateStatus?.name || '-'
            }})
          </span>
          <span
            *ngIf="
              loginInformationVendorResponse.vendorCertificate.certificateStatus
                .code !== global.appConstant.vm.CERTIFICATE_STATUS_EXPIRED
            "
          >
            ({{
              loginInformationVendorResponse?.vendorCertificate
                ?.certificateStatus?.name || '-'
            }})
          </span>
          <em
            *ngIf="loginInformationVendorResponse?.vendorCertificate != null"
            class="fa cursor-pointer"
            [ngClass]="!isLoading ? 'fa-print' : 'fa-circle-notch fa-spin'"
            (click)="
              doPrintCertificate(
                loginInformationVendorResponse.vendorCertificate
              )
            "
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
          ></em>
        </td>
        <td
          class="border"
          *ngIf="loginInformationVendorResponse?.vendorCertificate == null"
          >-</td
        >
      </tr>
      <tr>
        <th scope="col" class="border">{{
          moduleCode + '.validThru' | translate
        }}</th>
        <td
          class="border"
          *ngIf="loginInformationVendorResponse?.vendorCertificate != null"
        >
          {{
            (releaseDate
              | date: global.config.parameterModel.dateFormatShort) || '-'
          }}
          {{ moduleCode + '.until' | translate }}
          {{
            (expiredDate
              | date: global.config.parameterModel.dateFormatShort) || '-'
          }}
        </td>
        <td
          class="border"
          *ngIf="loginInformationVendorResponse?.vendorCertificate == null"
          >-</td
        >
      </tr>
      <tr>
        <th scope="col" class="border">{{
          moduleCode + '.lastLogin' | translate
        }}</th>
        <td class="border"
          >{{
            loginInformationVendorResponse.lastLogin
              | date: global.config.parameterModel.datetimeFormatShort
          }}
        </td>
      </tr>
    </table>
  </ng-container>
</dashboard-widget-card>
