import { Component, ViewEncapsulation } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { DirectorsDecreeSubmission } from '../../core/bean/directors-decree-submission';
import { Organization } from '../../core/bean/organization';
import { WorkPlan } from '../../core/bean/work-plan';
import { ApprovalPathModel } from '../../core/components/app-approval-path';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { WorkflowModelPrcs } from './../../core/bean/workflow-model-prcs';
import { WorkflowPrcs } from './../../core/bean/workflow-prcs';
import { DirectorsDecreeSubmissionRequest } from './directors-decree-submission-request';
import { DirectorsDecreeSubmissionResponse } from './directors-decree-submission-response';

@Component({
  templateUrl: './directors-decree-submission-edit-add.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./directors-decree-submission-edit-add.component.scss']
})
export class DirectorsDecreeSubmissionEditAddComponent extends BaseModuleComponent {
  public directorsDecree: DirectorsDecreeSubmission =
    new DirectorsDecreeSubmission();
  public workPlan: WorkPlan = new WorkPlan();
  public url: string;
  public workPlanId: number;
  public workflowPrcs: WorkflowPrcs;
  public isAllUploaded = false;
  public objectId: number;
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public directorsDecreeResponse: DirectorsDecreeSubmissionResponse =
    new DirectorsDecreeSubmissionResponse();
  public directorsDecreeRequest: DirectorsDecreeSubmissionRequest =
    new DirectorsDecreeSubmissionRequest();
  public approvalPath: ApprovalPathModel<any> = new ApprovalPathModel(
    this.moduleCode,
    'READ'
  );
  public fileUploader: FileUploader = new FileUploader(
    '/directors-decree-submission/',
    '',
    this.global.appConstant.fileType.DOC_DIRECTORS_DECREE,
    false,
    5
  );

  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('directors-decree-submission', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.objectId = this.global.routerParams.get('objectId');
    this.workPlan = this.global.routerParams.get('workPlan');
    this.workPlanId = this.objectId;
    if (this.objectId == null) {
      this.workPlan = this.global.routerParams.get('workPlan');
      this.workPlanId = this.workPlan.id;
      this.workflowPrcs = this.workPlan.workflowPrcs;
      this.workflowModelPrcs = this.workPlan.workflowModelPrcs;
    }
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      today: [new Date()],
      number: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      createdDate: [null, Validators.required()],
      organization: [null, Validators.required()],
      subject: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(128)])
      ],
      description: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(512)])
      ],
      uploadFile: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<DirectorsDecreeSubmissionResponse>(
        '/directors-decree-submission/add-edit',
        new RouteRequestModel(
          this.todo,
          this.objectId != null ? this.objectId : this.workPlanId
        )
      )
      .subscribe(directorsDecreeResponse => {
        this.directorsDecreeResponse = directorsDecreeResponse;
        if (this.objectId != null) {
          this.workPlan = this.directorsDecreeResponse.workPlan;
          this.workPlanId = this.workPlan.id;
          this.workflowPrcs = this.workPlan.workflowPrcs;
          this.workflowModelPrcs = this.workPlan.workflowModelPrcs;
        }
        this.formGroup.patchValue({
          organization: this.directorsDecreeResponse.organizationList
        });
        if (this.directorsDecreeResponse.directorsDecree != null) {
          this.todo = this.todo !== 'view' ? 'edit' : 'view';
          if (this.directorsDecreeResponse.approvalPathResponseModel != null) {
            const { type, userApproverList } =
              this.directorsDecreeResponse.approvalPathResponseModel;
            this.approvalPath.setApproverList(userApproverList).setType(type);
          }
          this.fileUploader.setFileList(this.directorsDecreeResponse.fileList);
          const createdDate = new Date(
            this.directorsDecreeResponse.directorsDecree.createdDate
          );
          this.formGroup.patchValue({
            id: this.directorsDecreeResponse.directorsDecree.id,
            number: this.directorsDecreeResponse.directorsDecree.number,
            createdDate,
            name: this.directorsDecreeResponse.directorsDecree.name,
            subject: this.directorsDecreeResponse.directorsDecree.subject,
            description:
              this.directorsDecreeResponse.directorsDecree.description,
            uploadFile: this.fileUploader.fileObjectList
          });
        } else if (
          this.directorsDecreeResponse.directorsDecree == null &&
          this.todo !== 'add'
        ) {
          this.global.routerParams.clear();
          this.global.routerParams.set('todo', 'add');
          this.global.routerParams.set('workPlan', this.workPlan);
          this.router.navigate(['/pages/directors-decree-submission/add']);
        }
        this.setStateReady();
      });
    // tslint:disable-next-line:align
    this.formGroup.get('organization').setIsView(true);
  }

  onChangeFileUpload(fileUploader: FileUploader): void {
    let isAllUploaded = true;
    if (!fileUploader.isAllUploaded) {
      isAllUploaded = fileUploader.isAllUploaded;
    }
    this.isAllUploaded = isAllUploaded;
  }

  public doSave(): void {
    if (this.directorsDecreeResponse.isNumberGenerator) {
      this.formGroup.get('number').clearValidators();
      this.formGroup.get('number').updateValueAndValidity();
    }
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.directorsDecree = this.formGroup.value;
            this.directorsDecree.workPlan = this.workPlan;
            this.directorsDecree.workPlan.workflowModelPrcs =
              this.workPlan.workflowModelPrcs;
            this.directorsDecreeRequest.workPlan =
              this.directorsDecreeResponse.workPlan;
            this.directorsDecreeRequest.fileObjectList =
              this.fileUploader.fileObjectList;
            const organizationList: Organization[] =
              this.formGroup.get('organization').value;
            this.directorsDecree.organization =
              organizationList[organizationList.length - 1];
            this.directorsDecree = this.global.copyFormAttributeToModel(
              this.directorsDecree,
              this.formGroup
            );
            this.directorsDecreeRequest.directorsDecree = this.directorsDecree;
            if (this.directorsDecree.id !== null) {
              this.url = '/directors-decree-submission/update';
            } else {
              this.url = '/directors-decree-submission/insert';
            }
            this.httpClientService
              .post<Response<DirectorsDecreeSubmissionResponse>>(
                this.url,
                this.directorsDecreeRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/worklist-pra-procurement']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doBack(): void {
    this.router.navigate(['/pages/worklist-pra-procurement']);
  }
}
