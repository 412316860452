import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Contract } from 'src/app/core/bean/contract';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { RecapPrintService } from 'src/app/core/services/recap-print.service';
import { ReportService } from 'src/app/core/services/report.service';
import { ContractStatus } from '../../core/bean/contract-status';
import { ContractWorklistStatus } from '../../core/bean/contract-worklist-status';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { ContractHistoryOptionListResponse } from './contract-history-option-list.response';
@Component({
  templateUrl: './contract-history.component.html',
  styleUrls: ['./contract-history.component.scss']
})
export class ContractHistoryComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Contract>;
  public tableResponseMonitoring: TableResponseModel<Contract>;
  public tableResponseClosureTerminate: TableResponseModel<Contract>;
  public worklistStatusOptionList: OptionListModel<ContractWorklistStatus> =
    new OptionListModel(true);
  public contractStatusOptionList: OptionListModel<ContractStatus> =
    new OptionListModel(true);

  public historyType = 'drafting';

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public reportService: ReportService,
    public recapPrintService: RecapPrintService
  ) {
    super('contract-history', translateService);
  }

  public onInit(): void {
    this.setOptionList();
    this.buildFormGroup();
    this.buildTableResponse();
    this.doClickHistoryType(this.historyType);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      worklistStatusList: [null],
      contractStatusList: [null],
      historyType: [this.historyType]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<ContractHistoryOptionListResponse>(
        '/contract-history/get-option-list/' + this.historyType
      )
      .subscribe((response: ContractHistoryOptionListResponse) => {
        this.worklistStatusOptionList.setRequestValues(
          response.worklistStatusList
        );
        this.contractStatusOptionList.setRequestValues(
          response.contractStatusList
        );
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'requestNumber',
        header: 'table.column.requestNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doDetail.bind(this)
        }
      },
      {
        field: 'requestDate',
        header: 'table.column.requestDate',
        plugins: {
          name: 'date'
        }
      },
      {
        field: 'title',
        header: 'table.column.title'
      },
      { field: 'vendor.name', header: 'table.column.vendorName' },
      {
        field: 'contractType.name',
        header: 'table.column.contractType'
      },
      {
        field: 'workflowModelPrcs.name',
        header: 'table.column.stage'
      },
      {
        field: 'contractWorklistStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'contractWorklistStatus.code',
          colorMap: {
            NEW: 'INFO',
            DRAFT: 'SECONDARY',
            REVISION: 'FEEDBACK',
            REVISION_BY_VENDOR: 'FEEDBACK',
            WAITING_APPROVAL: 'WARNING',
            DONE: 'SUCCESS',
            WAITING_RESPONSE: 'GOOD',
            CLOSED_RESPONSE: 'DANGER',
            HOLD: 'DANGER',
            CANCELED: 'DANGER',
            ON_PROGRESS: 'PROGRESS',
            WAITING_CONFIRMATION: 'GOOD'
          }
        }
      }
    ]);

    this.tableResponseMonitoring = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.contractNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doDetail.bind(this)
        }
      },
      {
        header: 'table.column.contractPeriod',
        columnList: [
          {
            field: 'startDate',
            header: 'table.column.startDate',
            plugins: {
              name: 'date'
            }
          },
          {
            field: 'endDate',
            header: 'table.column.endDate',
            plugins: {
              name: 'date'
            }
          }
        ]
      },
      {
        field: 'title',
        header: 'table.column.title'
      },
      { field: 'vendor.name', header: 'table.column.vendorName' },
      {
        field: 'contractType.name',
        header: 'table.column.contractType'
      },
      {
        field: 'contractStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'contractStatus.code',
          colorMap: {
            WAITING_FOR_TERMINATION: 'WARNING',
            DONE: 'SUCCESS',
            EXPIRED: 'DANGER',
            CANCELED: 'DANGER',
            ON_PROGRESS: 'PROGRESS',
            TERMINATED: 'DANGER',
            CLOSED: 'DANGER'
          }
        }
      }
    ]);

    this.tableResponseClosureTerminate = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'code',
          header: 'table.column.contractNumber',
          plugins: {
            name: 'hyperlink',
            onClick: this.doDetail.bind(this)
          }
        },
        {
          header: 'table.column.contractPeriod',
          columnList: [
            {
              field: 'startDate',
              header: 'table.column.startDate',
              plugins: {
                name: 'date'
              }
            },
            {
              field: 'endDate',
              header: 'table.column.endDate',
              plugins: {
                name: 'date'
              }
            }
          ]
        },
        {
          field: 'title',
          header: 'table.column.title'
        },
        { field: 'vendor.name', header: 'table.column.vendorName' },
        {
          field: 'contractType.name',
          header: 'table.column.contractType'
        },
        {
          field: 'contractStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            colorField: 'contractStatus.code',
            colorMap: {
              WAITING_FOR_TERMINATION: 'WARNING',
              DONE: 'SUCCESS',
              EXPIRED: 'DANGER',
              CANCELED: 'DANGER',
              ON_PROGRESS: 'PROGRESS',
              TERMINATED: 'DANGER',
              CLOSED: 'DANGER'
            }
          }
        }
      ]
    );
  }

  public doClickHistoryType(event): void {
    this.historyType = event;
    this.formGroup.patchValue({
      historyType: this.historyType
    });
    this.buildTableResponse();
    this.formGroup.patchValue({
      date: null,
      formDate: null,
      untilDate: null,
      worklistStatusList: null,
      contractStatusList: null
    });
    if (this.historyType === 'drafting') {
      this.tableResponse.setCustomData(this.formGroup.value);
      this.tableResponse.reload();
    } else if (this.historyType === 'monitoring') {
      this.tableResponseMonitoring.setCustomData(this.formGroup.value);
      this.tableResponseMonitoring.reload();
    } else {
      this.tableResponseClosureTerminate.setCustomData(this.formGroup.value);
      this.tableResponseClosureTerminate.reload();
    }
    this.setOptionList();
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
    } else {
      this.formGroup.value.fromDate = null;
      this.formGroup.value.untilDate = null;
    }
    if (this.historyType === 'drafting') {
      this.tableResponse.setCustomData(this.formGroup.value);
      this.tableResponse.reload();
    } else if (this.historyType === 'monitoring') {
      this.tableResponseMonitoring.setCustomData(this.formGroup.value);
      this.tableResponseMonitoring.reload();
    } else {
      this.tableResponseClosureTerminate.setCustomData(this.formGroup.value);
      this.tableResponseClosureTerminate.reload();
    }
  }

  public doExport(): void {
    let customData: any;
    if (this.historyType === 'drafting') {
      customData = this.tableResponse.getCustomData();
      console.log(customData);
    } else if (this.historyType === 'monitoring') {
      customData = this.tableResponseMonitoring.getCustomData();
      console.log(customData);
    } else {
      customData = this.tableResponseClosureTerminate.getCustomData();
      console.log(customData);
    }
    this.setStateProcessing();
    this.recapPrintService
      .print('/contract-history/export', customData)
      .subscribe(() => {
        this.setStateReady();
      });
  }

  public doDetail(contract: Contract): void {
    console.log(contract);
    if (this.historyType === 'drafting') {
      const prcsCode = contract.prcsCode;
      this.global.routerParams.clear();
      this.global.routerParams.set('contractId', contract.id);
      this.global.routerParams.set(
        'contractWorklistStatus',
        contract.contractWorklistStatus
      );
      if (
        (contract.contractWorklistStatus?.code ===
          this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_NEW ||
          contract.contractWorklistStatus?.code ===
            this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_DRAFT ||
          contract.contractWorklistStatus?.code ===
            this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_REVISION ||
          contract.contractWorklistStatus?.code ===
            this.global.appConstant.cm
              .CONTRACT_WORKLIST_STATUS_REVISION_BY_VENDOR) &&
        this.global.userSession.activeUserRole.role.type !==
          this.global.appConstant.core.ROLE_TYPE_USER
      ) {
        this.global.routerParams.set('todo', 'edit');
        this.router.navigate([
          '/pages/' + prcsCode.replace('APPROVAL-', '').toLowerCase() + '/edit'
        ]);
      } else {
        this.global.routerParams.set('todo', 'view');
        this.router.navigate([
          '/pages/' +
            prcsCode.replace('APPROVAL-', '').toLowerCase() +
            '/detail'
        ]);
      }
      this.global.routerParams.set('urlBackOutside', '/pages/contract-history');
    } else {
      if (
        contract.contractStatus?.code ===
          this.global.appConstant.cm.CONTRACT_STATUS_TERMINATED ||
        contract.contractStatus?.code ===
          this.global.appConstant.cm.CONTRACT_STATUS_WAITING_FOR_TERMINATION
      ) {
        this.global.routerParams.clear();
        this.global.routerParams.set('todo', 'monitoring');
        this.global.routerParams.set('contractId', contract.id);
        this.global.routerParams.set(
          'contractWorklistStatus',
          contract.contractWorklistStatus
        );
        this.global.routerParams.set(
          'urlBackOutside',
          '/pages/contract-history'
        );
        this.router.navigate(['/pages/contract-termination/detail']);
      } else {
        // untuk selain terminated / waiting termination
        this.global.routerParams.clear();
        const todo =
          this.global.userSession.activeUserRole.role.type !==
            this.global.appConstant.core.ROLE_TYPE_USER &&
          contract.contractStatus.code !==
            this.global.appConstant.cm.CONTRACT_STATUS_EXPIRED &&
          contract.contractStatus.code !==
            this.global.appConstant.cm.CONTRACT_STATUS_CANCEL
            ? 'edit'
            : 'view';
        this.global.routerParams.set('todo', todo);
        let header = 'contract-history.detail.contractHistory.header';
        if (
          contract.contractStatus.code ===
            this.global.appConstant.cm.CONTRACT_STATUS_CLOSED ||
          contract.contractStatus.code ===
            this.global.appConstant.cm.CONTRACT_STATUS_CANCEL
        ) {
          header = 'contract-history.detail.contractClosure.header';
        }
        this.global.routerParams.set('header', header);
        this.global.routerParams.set('contractId', contract.id);
        this.global.routerParams.set(
          'urlBackOutside',
          '/pages/contract-history'
        );

        this.router.navigate(['/pages/contract-history/detail']);
      }
    }
  }
}
