<ng-container *ngIf="!ISVIEW; else elseIsViewTmpl">
  <div class="app-text-map d-flex p-0" [ngClass]="[elementWidthClassName]">
    <input
      [disabled]="disabled"
      [value]="textValue"
      [placeholder]="placeholder ? placeholder : ''"
      class="form-control"
      [ngClass]="[
        elementHeightClassName,
        isInvalid ? 'is-invalid' : '',
        isValid ? 'is-valid' : '',
        !disabled ? 'cursor-pointer' : ''
      ]"
      (click)="doOpenAppTextMapPopup($event)"
      [attr.title]="textValue"
      readonly
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    />
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
  <small class="text-danger" *ngIf="isInvalid">
    {{ errors.message }}
  </small>
</ng-container>
<ng-template #elseIsViewTmpl>
  <div class="position-relative d-inline-block">
    <span>{{ textValue || '-' }}</span>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [symbolic]="symbolic"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item position="first">
      <em
        class="far fa-map cursor-pointer btn-choose-map"
        title="{{ 'app-text-map.tooltip.chooseMap' | translate }}"
        (click)="doOpenAppTextMapPopup($event)"
        *ngIf="!ISVIEW"
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
      ></em>
    </app-tooltip-wrapper-item>
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
