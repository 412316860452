import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { IndustryEditAddComponent } from './industry-edit-add.component';
import { IndustryComponent } from './industry.component';
const routes: Routes = [
  { path: '', component: IndustryComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: IndustryEditAddComponent,
    data: { breadcrumb: 'industry.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: IndustryEditAddComponent,
    data: { breadcrumb: 'industry.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [IndustryComponent, IndustryEditAddComponent]
})
export class IndustryModule {}
