<app-popup
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
  header="compliance-test.popup.addUser.header"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <app-select
      formControlName="userList"
      [optionList]="userOptionList"
      selectAllText="{{
        'compliance-test.popup.addUser.selectAll' | translate
      }}"
      [isMultiple]="true"
      [isShowInputControl]="true"
      [isShowBorder]="true"
      [search]="true"
    >
      <ng-template #headerFilter>
        <app-combo-box-tree
          formControlName="organization"
          stringUrl="/organization"
          isLazy="true"
          size="XL"
          (onChange)="onChangeFilter()"
        >
        </app-combo-box-tree>
      </ng-template>
      <ng-template #content let-data>
        <!-- <div *ngIf="!data.user || (data.user && !data.user.imgFile)" class="pvc pv-user-circle text-secondary"></div>
                <div *ngIf="data.user?.imgFile" class="pvc pv-user-circle text-secondary"></div> -->

        <div style="margin: 0 auto; display: flex; position: relative">
          <div
            style="
              width: 50px;
              height: 50px;
              overflow: hidden;
              border-radius: 50px;
            "
            class="mr-3"
          >
            <img
              style="width: 100%"
              [src]="'assets/' + data.user?.imgFile?.fileName"
              alt="user image"
              [default]="global.appConstant.core.URL_DEFAULT_PROFILE_IMAGE"
            />
          </div>

          <div class="d-inline-block">
            <ng-container>
              <span class="d-inline-flex flex-column">
                <h5 class="mr-1">{{ data.name }}</h5>
                <i class="text-secondary">{{ data.organization.name }}</i>
              </span>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </app-select>
  </form>
  <div class="button-group button-group-center mt-5">
    <app-button [outline]="true" color="SECONDARY" (onClick)="doCancel()">{{
      'app.button.cancel' | translate
    }}</app-button>
    <app-button (onClick)="doAdd()">{{
      'app.button.add' | translate
    }}</app-button>
  </div>
</app-popup>
