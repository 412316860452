import { Procurement } from '../../core/bean/procurement';
import { ProcurementFilter } from '../../core/bean/procurement-filter';
import { ProcurementRegion } from '../../core/bean/procurement-region';
import { ProcurementVendor } from '../../core/bean/procurement-vendor';
import { AppOfficialReportModel } from '../../core/components/app-official-report/app-official-report-model';

export class VendorInvitationRequest {
  isSubmit: boolean;
  procurement: Procurement;
  procurementVendorList: ProcurementVendor[] = [];
  procurementFilter: ProcurementFilter;
  procurementRegionList: ProcurementRegion[] = [];
  appOfficialReportModel: AppOfficialReportModel;
}
