<div class="app-upload-images">
  <ul class="box-image-list" #ulElement>
    <li
      uploadDragAndDrop
      (onChange)="onDragAndDropChange($event)"
      class="box-image-item"
      *ngFor="let item of boxItemList; let i = index"
      (click)="inputFileChange.click()"
    >
      <ng-container
        *ngIf="
          model.uploaderFileList[item];
          then imageTemplate;
          else defaultTemplate
        "
      ></ng-container>
      <ng-template #defaultTemplate>
        <em class="pir pi-plus"></em>
      </ng-template>

      <ng-template #imageTemplate>
        <div class="view image-wrapper">
          <img
            [attr.src]="
              model.uploaderFileList[item].isNew ||
              model.uploaderFileList[item].isChange
                ? srcBase64Map.get(item)
                : (model.uploaderFileList[item] | resolveUploadSrcX | async)
            "
            [attr.alt]="model.uploaderFileList[item].file.fileName"
            class="img-thumbnail"
            width="0"
            height="0"
          />
          <div
            class="mask delete-action"
            (click)="doDeleteFile(i)"
            *ngIf="!ISVIEW && model.uploaderFileList[item]"
          >
            <em class="pir pi-trash-alt"></em>
            <span>{{ 'app.tooltip.delete' | translate }}</span>
          </div>
        </div>
      </ng-template>
      <input
        #inputFileChange
        type="file"
        (change)="onUploadChange($event, model.uploaderFileList[item] && item)"
        aria-label="choose-file"
        hidden
        [disabled]="
          disabled || (!ISVIEW && model.uploaderFileList[item]) || ISVIEW
        "
      />
    </li>
  </ul>
  <p>{{
    ('app-upload-images.msg.info' | translate) +
      ' ' +
      model.options.maxSize / 1000000 +
      'Mb'
  }}</p>
  <div *ngIf="model.error">
    <app-alert-x [isBold]="false" color="WARNING" [isShowCloseButton]="true">
      {{ model.error.message + ' ' + model.options.allowedExtension }}
    </app-alert-x>
  </div>
</div>
