<ng-container
  *ngIf="!ISVIEW; else elseIsViewTmpl"
  [formGroup]="generateFormGroup"
>
  <div
    class="app-phone form-control p-0 border-0"
    [ngClass]="[elementWidthClassName, elementHeightClassName]"
  >
    <ng-container *ngIf="optionList; else elseNoCurrency">
      <ng-container
        *ngIf="
          optionList.getRequestValues().length !== 1;
          else elseStaticCurrency
        "
      >
      <div class="input-group">
        <input
          #inputPrice
          class="form-control text-right"
          type="text"
          (input)="handleInput($event)"
          formControlName="price"
          [ngClass]="[
            (formControl.invalid &&
              generateFormGroup.get('price').touched &&
              generateFormGroup.get('price').invalid) ||
            isInvalid
              ? 'is-invalid'
              : '',
            (formControl.valid &&
              generateFormGroup.get('price').touched &&
              generateFormGroup.get('price').valid) ||
            isValid
              ? 'is-valid'
              : ''
          ]"
          (focusout)="handleFocusOut($event)"
          (focusin)="handleFocusIn($event)"
          maxlength="{{ maxLength }}"
          placeholder="{{ placeholder || '0.00' }}"
        />
        <div class="input-group-append">
          <div 
            class="input-group-text"
            [appDropDown]="currencyDropDown">
            <span *ngIf="!generateFormGroup.get('currency').value" class="button-text" maxLine>
              {{optionList.getRequestValues()[0].code}}
            </span>
            <span *ngIf="generateFormGroup.get('currency').value" class="button-text" maxLine>
              {{ generateFormGroup.get('currency').value.code }}
            </span> &nbsp;
            <em
              class="fas fa-chevron-down"></em>
          </div>
        </div>
      </div>
      </ng-container>
      <ng-template #elseStaticCurrency>
        <div class="input-group">
          <input
            #inputPrice
            class="form-control text-right"
            type="text"
            (input)="handleInput($event)"
            formControlName="price"
            [ngClass]="[
              (formControl.invalid &&
                generateFormGroup.get('price').touched &&
                generateFormGroup.get('price').invalid) ||
              isInvalid
                ? 'is-invalid'
                : '',
              (formControl.valid &&
                generateFormGroup.get('price').touched &&
                generateFormGroup.get('price').valid) ||
              isValid
                ? 'is-valid'
                : ''
            ]"
            (focusout)="handleFocusOut($event)"
            (focusin)="handleFocusIn($event)"
            maxlength="{{ maxLength }}"
            placeholder="{{ placeholder || '0.00' }}"
          />
          <div class="input-group-append">
            <span class="input-group-text">{{
              optionList.getRequestValues()[0].code
            }}</span>
          </div>
        </div>
      </ng-template>
      <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
      </ng-container>
    </ng-container>
    <ng-template #elseNoCurrency>
      <input
        #inputPrice
        class="form-control text-right"
        type="text"
        formControlName="price"
        [ngClass]="[
          (formControl.invalid &&
            generateFormGroup.get('price').touched &&
            generateFormGroup.get('price').invalid) ||
          isInvalid
            ? 'is-invalid'
            : '',
          (formControl.valid &&
            generateFormGroup.get('price').touched &&
            generateFormGroup.get('price').valid) ||
          isValid
            ? 'is-valid'
            : ''
        ]"
        (input)="handleInput($event)"
        (change)="onChange.emit($event.target.value)"
        (focusout)="handleFocusOut($event)"
        (focusin)="handleFocusIn($event)"
        maxlength="{{ maxLength }}"
        placeholder="{{ placeholder || '0.00' }}"
      />
      <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
      </ng-container>
    </ng-template>
  </div>
  <small
    class="text-danger"
    *ngIf="(formControl.invalid && formControl.touched) || isInvalid"
  >
    {{ errors.message }}
  </small>
</ng-container>
<ng-template #elseIsViewTmpl>
  <div class="d-inline-block position-relative">
    {{ numberValue || '-' }}
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [symbolic]="symbolic"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>

<ng-template #currencyDropDown [formGroup]="generateFormGroup">
  <div class="drop-down-currency">
    <app-select
      [optionList]="optionList"
      formControlName="currency"
      (onChange)="handleChange()"
      [isShowInputControl]="false"
    ></app-select>
  </div>
</ng-template>