import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { Global } from './../global/global';
import { HttpClientService } from './http-client.service';
@Injectable()
export class RecapPrintService {
    public subscription: Subscription = new Subscription();
    constructor(public global: Global, public httpClientService: HttpClientService) { }
    public print(url: string, requestTable: any): Observable<any> {
        const subject = new Subject<boolean>();
        this.subscription.unsubscribe();
        this.subscription = this.httpClientService.post(url, requestTable, { responseType: 'arraybuffer', observe: 'response' })
            .subscribe(data => {
                const contentDisposition = data.headers.get('content-disposition');
                const fileName = contentDisposition.split(';')[1].split('fileName')[1].split('=')[1].trim();
                const fileType = contentDisposition.split(';')[2].split('fileType')[1].split('=')[1].trim();
                const recapPrint = document.createElement('a');
                if (fileType === this.global.appConstant.core.PRINT_TYPE_XLSX) {
                    const blob = new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    recapPrint.href = URL.createObjectURL(blob);
                } else if (fileType === this.global.appConstant.core.PRINT_TYPE_DOC) {
                    const blob = new Blob([data.body], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
                    recapPrint.href = URL.createObjectURL(blob);
                } else {
                    const blob = new Blob([data.body], { type: 'application/x-pdf' });
                    recapPrint.href = URL.createObjectURL(blob);
                }
                recapPrint.download = fileName;
                document.body.appendChild(recapPrint);
                recapPrint.click();
                document.body.removeChild(recapPrint);
                subject.next(data);
            }, error => {
                subject.error(error);
            });
        return subject.asObservable();
    }
}
