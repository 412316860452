<app-popup
  [header]="'tab-profile-company-data.form.popup.header.pic.' + todo"
  [isLoading]="formLoading"
>
  <form [formGroup]="formGroup">
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.name' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          [autofocus]="true"
          size="LG"
          formControlName="name"
          maxLength="64"
          type="alphanumeric"
          include="-.\',"
        ></app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.position' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="LG"
          [optionList]="vendorPositionOptionList"
          formControlName="vendorPosition"
          tooltip="{{
            'tab-profile-company-data.tooltip.position' | translate
          }}"
        ></app-combo-box>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.phone' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-phone
          size="LG"
          formControlName="phone"
          [optionList]="countryRegionOptionList"
          maxLength="32"
        ></app-phone>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.email' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="email"
          maxLength="64"
        ></app-text-field>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label"></label>
      <div class="col-sm-9 text-left">
        <app-check-box
          size="LG"
          formControlName="isEmailNotification"
          position="HORIZONTAL"
          tooltip="{{
            'tab-profile-company-data.tooltip.emailNotification' | translate
          }}"
          ><label>{{
            'tab-profile-company-data.label.emailNotification' | translate
          }}</label></app-check-box
        >
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
        <app-button
          *ngIf="!formGroup.isView"
          color="PRIMARY"
          (onClick)="doSave()"
          >{{ 'app.button.save' | translate }}</app-button
        >
      </div>
    </ng-template>
  </form>
</app-popup>
