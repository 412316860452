import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { ReportBuilder } from 'src/app/core/bean/report-builder';
import { ReportRecap } from 'src/app/core/bean/report-recap';
import { ReportRecapIndexResponse } from './report-recap-index.response';

@Component({
  templateUrl: './report-recap.component.html',
  styleUrls: ['./report-recap.component.scss']
})
export class ReportRecapComponent extends BaseModuleComponent {
  public generalReportRecapList: ReportRecap[] = [];
  public esmReportRecapList: ReportRecap[] = [];
  public vmReportRecapList: ReportRecap[] = [];
  public pmReportRecapList: ReportRecap[] = [];
  public cmReportRecapList: ReportRecap[] = [];
  public reportBuilderList: ReportBuilder[] = [];
  public generalReportRecapTempList: ReportRecap[] = [];
  public esmReportRecapTempList: ReportRecap[] = [];
  public vmReportRecapTempList: ReportRecap[] = [];
  public pmReportRecapTempList: ReportRecap[] = [];
  public cmReportRecapTempList: ReportRecap[] = [];
  public reportBuilderTempList: ReportBuilder[] = [];
  public isFiltered = false;

  constructor(translateService: TranslateService) {
    super('report-recap', translateService);
  }

  public onInit(): void {
    this.getDataFromServer();
  }

  public getDataFromServer(): void {
    this.httpClientService
      .get<ReportRecapIndexResponse>('/report-recap/index')
      .subscribe((reportRecapIndexResponse: ReportRecapIndexResponse) => {
        if (reportRecapIndexResponse.generalReportRecapList.length > 0) {
          reportRecapIndexResponse.generalReportRecapList.forEach(
            report =>
              (report.name = this.translateService.instant(
                this.moduleCode + '.' + report.translationKey.key
              ))
          );
          this.generalReportRecapList =
            reportRecapIndexResponse.generalReportRecapList.sort(
              (reportA, reportB) => {
                if (reportA.name < reportB.name) {
                  return -1;
                } else {
                  return 1;
                }
              }
            );
        }

        this.generalReportRecapTempList = [...this.generalReportRecapList];

        if (reportRecapIndexResponse.esmReportRecapList.length > 0) {
          reportRecapIndexResponse.esmReportRecapList.forEach(
            report =>
              (report.name = this.translateService.instant(
                this.moduleCode + '.' + report.translationKey.key
              ))
          );
          this.esmReportRecapList =
            reportRecapIndexResponse.esmReportRecapList.sort(
              (reportA, reportB) => {
                if (reportA.name < reportB.name) {
                  return -1;
                } else {
                  return 1;
                }
              }
            );
        }
        this.esmReportRecapTempList = [...this.esmReportRecapList];

        if (reportRecapIndexResponse.vmReportRecapList.length > 0) {
          reportRecapIndexResponse.vmReportRecapList.forEach(
            report =>
              (report.name = this.translateService.instant(
                this.moduleCode + '.' + report.translationKey.key
              ))
          );
          this.vmReportRecapList =
            reportRecapIndexResponse.vmReportRecapList.sort(
              (reportA, reportB) => {
                if (reportA.name < reportB.name) {
                  return -1;
                } else {
                  return 1;
                }
              }
            );
        }
        this.vmReportRecapTempList = [...this.vmReportRecapList];

        if (reportRecapIndexResponse.pmReportRecapList.length > 0) {
          reportRecapIndexResponse.pmReportRecapList.forEach(
            report =>
              (report.name = this.translateService.instant(
                this.moduleCode + '.' + report.translationKey.key
              ))
          );
          this.pmReportRecapList =
            reportRecapIndexResponse.pmReportRecapList.sort(
              (reportA, reportB) => {
                if (reportA.name < reportB.name) {
                  return -1;
                } else {
                  return 1;
                }
              }
            );
        }
        this.pmReportRecapTempList = [...this.pmReportRecapList];

        if (reportRecapIndexResponse.cmReportRecapList.length > 0) {
          reportRecapIndexResponse.cmReportRecapList.forEach(
            report =>
              (report.name = this.translateService.instant(
                this.moduleCode + '.' + report.translationKey.key
              ))
          );
          this.cmReportRecapList =
            reportRecapIndexResponse.cmReportRecapList.sort(
              (reportA, reportB) => {
                if (reportA.name < reportB.name) {
                  return -1;
                } else {
                  return 1;
                }
              }
            );
        }
        this.cmReportRecapTempList = [...this.cmReportRecapList];
        this.reportBuilderList = reportRecapIndexResponse.reportBuilderList;
        this.reportBuilderTempList = reportRecapIndexResponse.reportBuilderList;
        this.setStateReady();
      });
  }

  public doClickReport(data: ReportRecap): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('reportRecapId', data.id);
    this.router.navigate([data.path]);
  }

  public doClickCustomReport(data: ReportBuilder): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('reportBuilderId', data.id);
    this.global.routerParams.set('reportRecapId', data.reportRecap.id);
    this.global.routerParams.set(
      'generatedTableResponse',
      data.generatedTableResponse
    );
    this.router.navigate([data.reportRecap.path]);
  }

  public doAddCustomReport(): void {
    this.global.routerParams.clear();
    this.router.navigate(['/pages/report-recap/add']);
  }

  public onInputSearch(value: string): void {
    if (value && value !== '') {
      this.generalReportRecapList =
        this.generalReportRecapTempList &&
        this.generalReportRecapTempList.filter(report =>
          report.name.toLowerCase().includes(value.toLowerCase())
        );
      this.esmReportRecapList =
        this.esmReportRecapTempList &&
        this.esmReportRecapTempList.filter(report =>
          report.name.toLowerCase().includes(value.toLowerCase())
        );
      this.cmReportRecapList =
        this.cmReportRecapTempList &&
        this.cmReportRecapTempList.filter(report =>
          report.name.toLowerCase().includes(value.toLowerCase())
        );
      this.vmReportRecapList =
        this.vmReportRecapTempList &&
        this.vmReportRecapTempList.filter(report =>
          report.name.toLowerCase().includes(value.toLowerCase())
        );
      this.pmReportRecapList =
        this.pmReportRecapTempList &&
        this.pmReportRecapTempList.filter(report =>
          report.name.toLowerCase().includes(value.toLowerCase())
        );
      this.reportBuilderList =
        this.reportBuilderTempList &&
        this.reportBuilderTempList.filter(report =>
          report.name.toLowerCase().includes(value.toLowerCase())
        );
      this.isFiltered = true;
    } else {
      this.reloadList();
    }
  }

  public doResetInputSearch(
    inputSearch: HTMLElement & { value: string }
  ): void {
    this.reloadList();
    inputSearch.value = '';
  }

  public reloadList(): void {
    this.generalReportRecapList = this.generalReportRecapTempList;
    this.pmReportRecapList = this.pmReportRecapTempList;
    this.cmReportRecapList = this.cmReportRecapTempList;
    this.vmReportRecapList = this.vmReportRecapTempList;
    this.esmReportRecapList = this.esmReportRecapTempList;
    this.reportBuilderList = this.reportBuilderTempList;
    this.isFiltered = false;
  }
}
