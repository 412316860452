import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Global } from '../../global/global';
import { HttpClientService } from '../../services/http-client.service';
import {
  createHttpRequest,
  dispatchUpload,
  handleUploadEvent,
  showUploaderError
} from './actions';
import { createFileFromFormControlValue } from './actions/create-file-from-form-control-value.action';
import { createModelOptions } from './actions/create-model-options.action';
import * as uploadFileAction from './actions/upload-file.action';
import { Uploader } from './model/uploader';
import { UploaderFile } from './model/uploader-file';
@Injectable()
export class UploadService {
  private uploader: Uploader;
  constructor(
    public httpClientService: HttpClientService,
    public global: Global
  ) {}

  public setUploader(
    uploader: Uploader,
    formControl: FormControl
  ): Observable<Uploader> {
    this.uploader = createFileFromFormControlValue(
      createModelOptions(uploader, this.global),
      formControl
    );
    this.handleListenerUploaderValueChanges();
    return this.uploader.valueChanges;
  }

  public dispatch(action: uploadFileAction.All): void {
    dispatchUpload(this.uploader, action);
    this.upload();
  }

  private handleListenerUploaderValueChanges(): void {
    this.uploader.valueChanges.subscribe((uploader: Uploader) => {
      uploader.totalUploaded = uploader.uploaderFileList.length;
    });
  }

  private upload(): void {
    !this.uploader.error
      ? this.uploader.uploaderFileList.forEach((uploaderFile: UploaderFile) =>
          this.execute(uploaderFile)
        )
      : showUploaderError(this.uploader, this.global);
  }

  private execute(uploaderFile: UploaderFile): void {
    if (uploaderFile.status === 'INQUEE') {
      uploaderFile.setStatus('ONPROGRESS');
      uploaderFile.subscription = this.httpClientService
        .request(
          createHttpRequest(
            this.uploader.options.stringUrl,
            uploaderFile.formData
          )
        )
        .subscribe(
          (event: HttpEvent<any>) => {
            handleUploadEvent(event, uploaderFile);
          },
          error => {
            uploaderFile.setError({ type: 'FAILED', message: error.message });
            uploaderFile.setUploaderProgress(0);
            uploaderFile.setStatus('FAILED');
            this.uploader.setError(uploaderFile.error);
            this.uploader.valueChanges.emit(this.uploader);
          }
        );
    }
  }
}
