<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-xx
        [model]="tableResponse"
        stringUrl="/oep/index"
        (onClick)="doClick($event)"
        header="{{ 'oep.table.header' | translate }}"
        [isShowButtonModifyTable]="true"
      >
        <ng-template #headerFilterGroup>
          <form
            class="form-horizontal pt-2"
            [formGroup]="formGroup"
            novalidate
            *ngIf="!formLoading"
          >
            <div class="form-group row text-sm-left mb-1">
              <label class="ml-3" style="margin-top: auto">{{
                'oep.form.date' | translate
              }}</label>
              <div class="col-sm-6 text-left" style="display: flex">
                <app-date-picker-x
                  size="XL"
                  formControlName="date"
                  [range]="true"
                  (onChange)="onChangeFilter()"
                  [ngStyle]="{
                    'margin-right': '7px',
                    width: 'inherit'
                  }"
                >
                </app-date-picker-x>
                <app-dropdown-select
                  size="XL"
                  formControlName="organizationList"
                  (onChange)="onChangeFilter()"
                  placeholder="{{ 'oep.placeholder.department' | translate }}"
                  [optionList]="organizationOptionList"
                  type="CHOSEN"
                  [ngStyle]="{
                    'margin-right': '7px',
                    width: '467px'
                  }"
                  showLimitValue="1"
                >
                  <ng-template #selectAll>
                    <p> {{ 'oep.placeholder.allDepartment' | translate }} </p>
                  </ng-template></app-dropdown-select
                >
                <app-dropdown-select
                  size="LG"
                  formControlName="oepStatusList"
                  placeholder="{{ 'oep.placeholder.status' | translate }}"
                  [optionList]="oepStatusOptionList"
                  (onChange)="onChangeFilter()"
                  type="CHOSEN"
                  [ngStyle]="{
                    width: 'inherit'
                  }"
                  showLimitValue="1"
                >
                  <ng-template #selectAll>
                    <p> {{ 'oep.placeholder.allStatus' | translate }} </p>
                  </ng-template></app-dropdown-select
                >
              </div>
            </div>
          </form>
        </ng-template>
      </app-table-xx>
    </div>
  </div>
</app-layout>
