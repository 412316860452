<app-layout [isLoading]="formLoading" [backTo]="urlBackOutside">
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge class="cml-2" *ngIf="todo === 'add'" color="INFO">
      {{ 'pr.label.new' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        prStatus?.code === global.appConstant.pm.PR_STATUS_DRAFT
      "
      color="SECONDARY"
    >
      {{
        'dynamic-master-attribute.' + prStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        prStatus?.code === global.appConstant.pm.PR_STATUS_WAITING_APPROVAL
      "
      color="WARNING"
    >
      {{
        'dynamic-master-attribute.' + prStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        prStatus?.code === global.appConstant.pm.PR_STATUS_ON_PROGRESS
      "
      color="PROGRESS"
    >
      {{
        'dynamic-master-attribute.' + prStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        prStatus?.code === global.appConstant.pm.PR_STATUS_VERIFIED
      "
      color="INFO"
    >
      {{
        'dynamic-master-attribute.' + prStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        prStatus?.code === global.appConstant.pm.PR_STATUS_HOLD
      "
      color="DANGER"
    >
      {{
        'dynamic-master-attribute.' + prStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        prStatus?.code === global.appConstant.pm.PR_STATUS_WAITING_VERIFICATION
      "
      color="WARNING"
    >
      {{
        'dynamic-master-attribute.' + prStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        prStatus?.code === global.appConstant.pm.PR_STATUS_REVISION
      "
      color="FEEDBACK"
    >
      {{
        'dynamic-master-attribute.' + prStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        prStatus?.code === global.appConstant.pm.PR_STATUS_CANCELED
      "
      color="DANGER"
    >
      {{
        'dynamic-master-attribute.' + prStatus.translationKey.key | translate
      }}
    </app-badge>
  </ng-template>

  <div class="row main-row" *ngIf="!formLoading">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-alert-x
          [isBold]="false"
          color="DANGER"
          *ngIf="prStatus?.code === global.appConstant.pm.PR_STATUS_CANCELED"
          >{{ 'pr.detail.cancel' | translate }}
          <strong> {{ prCancellation.cancelByUser.name }}</strong>
          {{ 'pr.detail.cancelAt' | translate }}
          <strong>{{
            prCancellation.cancelDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}</strong>
          {{ 'pr.detail.cancellationReson' | translate }}
          <strong>"{{ prCancellation.note }}"</strong></app-alert-x
        >
        <app-card
          header="pr.detail.general.title"
          tips="pr.detail.general.tips"
        >
          <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr.form.requestorDepartment' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.organization.name }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr.form.requestor' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.requestor.name }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr.form.requiredDate' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-date-picker-x
                formControlName="requiredDate"
                [minDate]="currentDate"
              >
              </app-date-picker-x>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{ 'pr.form.category' | translate }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box-tree
                formControlName="category"
                stringUrl="/procurement-type"
                header="{{ 'pr.header.category' | translate }}"
              ></app-combo-box-tree>
            </div>
          </div>
          <!-- <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{ 'pr.form.prType' | translate }}</label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                formControlName="prType"
                [optionList]="prTypeOptionList"
                [ngStyle]="{ 'min-width': '10rem' }"
              ></app-dropdown-select>
            </div>
          </div> -->
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr.form.description' | translate
            }}</label>
            <div
              [ngClass]="
                !formGroup.isView ? 'col-sm-9 text-left' : 'col-sm-6 text-left'
              "
            >
              <app-text-area formControlName="description" maxLength="255">
              </app-text-area>
            </div>
          </div>
        </app-card>

        <app-card>
          <app-alert-x
            color="DANGER"
            *ngIf="
              isItemValid === false"
            >{{
              'pr.detail.item.isNotValid' | translate
            }}
          </app-alert-x>

          <app-alert-x
            color="DANGER"
            *ngIf="
            isItemTypeValid === false"
            >{{
              'pr.detail.item.isNotValidItemType' | translate
            }}
          </app-alert-x>

          <app-alert-x
            color="DANGER"
            *ngIf="
            isCategoryNull === true"
            >{{
              'pr.detail.item.categoryIsNull' | translate
            }}
          </app-alert-x>
          
          <app-alert-x
            color="DANGER"
            *ngIf="
            prResponse.isValidatePr === true"
            >{{
              'pr.detail.item.isValidatePr' | translate
            }}
          </app-alert-x>

          <small
            class="text-danger"
            *ngIf="isSubmit && prResponse.prItemList.length === 0"
          >
            {{ 'app.validation.required' | translate }}
          </small>
          <app-table-xx
            #selectorItem
            [model]="tableResponseItem"
            (onClick)="
              !formGroup.isView ? doEditItem($event) : doViewItem($event)
            "
            *ngIf="!formLoading"
            [isShowSearch]="prItemList?.length !== 0"
            tips="pr.detail.itemRequest.tips"
            header="pr.detail.itemRequest.title"
          >
            <ng-template #headerFilter>
              <form
                class="form-horizontal"
                [formGroup]="formGroup"
                novalidate
                *ngIf="prItemList?.length !== 0"
              >
                <div class="form-group row text-sm-left">
                  <div
                    class="col-sm-9 text-left"
                    [ngStyle]="{ 'min-width': '10rem' }"
                  >
                    <app-dropdown-select
                      size="XL"
                      [formControl]="itemType"
                      [optionList]="itemTypeOptionList"
                      (onChange)="onChangeItem()"
                      container="body"
                      placeholder="{{
                        'pr-verification.placeholder.type' | translate
                      }}"
                      [ngStyle]="{ 'min-width': '10rem' }"
                    >
                      <ng-template #value let-value>
                        {{ getTranslateKey(value) | translate }}
                      </ng-template>
                      <ng-template #option let-data>
                        {{ getTranslateKey(data) | translate }}
                      </ng-template></app-dropdown-select
                    >
                  </div>
                </div>
              </form>
            </ng-template>
            <ng-template #headerButtons>
              <app-button
                [disabled]="formSaving"
                *ngIf="todo === 'add' || this.prResponse?.pr?.prStatus?.code === 
                    global.appConstant.pm.PR_STATUS_DRAFT"
                (onClick)="doRequestItemToDistributor()"
                ><em class="fas fa-paper-plane"></em>
                {{ 'pr.button.requestItemDistributor' | translate }}</app-button
              >
              <app-button
                [disabled]="formSaving"
                *ngIf="!formGroup.isView"
                (onClick)="doAddItem()"
                ><em class="pir pi-plus-circle"></em>
                {{ 'pr.button.addItem' | translate }}</app-button
              >
            </ng-template>
            <ng-container *ngIf="!formGroup.isView">
              <ng-template #actionButtons let-rowData>
                <app-button
                  size="TN"
                  (onClick)="doDeleteItem(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="pir pi-trash-alt"></em>
                  {{ 'app.tooltip.delete' | translate }}
                </app-button>
              </ng-template>
            </ng-container>

            <ng-template #row let-state>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right" colspan="3">
                  {{ 'pr.form.goodsAmount' | translate }}
                </td>
                <td colspan="99" class="text-right">
                  <strong>
                    {{ formGroup.value.goodsAmount | resolveNumberToCurrency }}
                    {{ prResponse?.companyCurrency.code }}
                  </strong>
                </td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right" colspan="3">
                  {{ 'pr.form.serviceAmount' | translate }}
                </td>
                <td colspan="99" class="text-right">
                  <strong>
                    {{
                      formGroup.value.serviceAmount | resolveNumberToCurrency
                    }}
                    {{ prResponse?.companyCurrency.code }}
                  </strong>
                </td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right" colspan="3">
                  {{ 'pr.form.amount' | translate }}
                </td>
                <td colspan="99" class="text-right">
                  <strong>
                    {{ formGroup.value.amount | resolveNumberToCurrency }}
                    {{ prResponse?.companyCurrency.code }}
                  </strong>
                  <br />
                  <i>
                    ({{
                      formGroup.value.amount
                        | resolveNumberToWords: global.currentLang
                    }}
                    {{ prResponse?.companyCurrency.name }})
                  </i>
                </td>
              </tr>
            </ng-template>
          </app-table-xx>
        </app-card>

        <app-card
          header="pr.detail.shippingAddress.title"
          tips="pr.detail.shippingAddress.tips"
        >
          <ng-template #headerRight>
            <app-button
              *ngIf="!formGroup.isView && prShippingOptionList.requestValues.length === 0"
              class="float-right"
              (onClick)="doAddShipping()"
              [ngStyle]="{ 'margin-bottom': '2%' }"
              [disabled]="prItemList.length === 0"
              ><em class="pir pi-plus-circle"></em>
              {{ 'pr.button.addAddress' | translate }}
            </app-button>
          </ng-template>
          <div>
            <app-select
              [optionList]="prShippingOptionList"
              [isMultiple]="true"
              formControlName="prShipping"
              selectAllText="{{ 'pr.detail.shipping.selectAll' | translate }}"
              maxHeight="max-content"
            >
              <ng-template #content let-data>
                <div
                  style="
                    border: 1px solid var(--gray-400);
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding: 1rem;
                  "
                >
                <p>{{ prShipping?.address?.organization?.name }}</p>
                <h5>{{ getUserNameList(prShipping?.address?.addressPicList) }}</h5>
                <p>{{ prShipping?.address?.addressDetail }}</p>
                <p>{{ prShipping?.address?.phone }}</p>
                  <p class="text-right"> </p>
                </div>
              </ng-template>

              <ng-template #actionButtons>
                <div
                  *ngIf="
                    !formGroup.isView &&
                    prShippingOptionList.requestValues.length !== 0
                  "
                  [ngStyle]="{ cursor: 'pointer', display: 'contents' }"
                >
                  <app-button
                    color="LIGHT"
                    [outline]="false"
                    (onClick)="doDeleteShipping()"
                    title="{{ 'app.tooltip.delete' | translate }}"
                  >
                    <em
                      class="pir pi-trash-alt mr-2"
                      style="font-size: 24px"
                    ></em>
                    <span style="font-size: 14px">{{
                      'app.button.delete' | translate
                    }}</span>
                  </app-button>
                </div>
              </ng-template>
            </app-select>
          </div>
        </app-card>

        <app-card
          header="pr.detail.officialReport.title"
          tips="pr.detail.officialReport.tips"
        >
          <app-alert-x color="WARNING" *ngIf="isOfficialReportNull && prId">{{
            'pr.detail.officialReport.alertInfo' | translate
          }}</app-alert-x>
          <app-alert-x
            color="WARNING"
            *ngIf="
              isOfficialReportNull === false &&
              !appOfficialReportModel.isGenerate
            "
            >{{
              'pr.detail.officialReport.regenerate' | translate
            }}</app-alert-x
          >
          <app-alert-x color="WARNING" *ngIf="!prId">{{
            'pr.detail.officialReport.saveDraftFirst' | translate
          }}</app-alert-x>

          <app-official-report
            [objectId]="prId"
            [templateCode]="global.appConstant.pm.ORT_PURCHASE_REQUEST_LETTER"
            [appOfficialReportModel]="appOfficialReportModel"
            [mode]="todo"
            numberGeneratorCode="NUMBER"
            (onChange)="onChangeOfficialReport($event)"
            [isShowButtonGenerate]="!formGroup.isView"
            [include]="['subject', 'priority', 'location']"
          ></app-official-report>
        </app-card>

        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            prResponse?.approvalPathResponseModel &&
            prResponse?.pr?.prStatus?.code &&
            prResponse?.pr?.prStatus?.code !==
              global.appConstant.pm.PR_STATUS_DRAFT &&
            !prResponse.isUserApproval
          "
          [workflowPrcs]="prResponse?.pr?.workflowPrcs"
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="prResponse.approvalPathResponseModel"
        ></app-approval-monitoring>

        <app-card
          header="pr.detail.changeHistory.title"
          tips="pr.detail.changeHistory.tips"
          *ngIf="
            prResponse?.pr?.prStatus?.code &&
            prResponse?.pr?.prStatus?.code !==
              global.appConstant.pm.PR_STATUS_DRAFT
          "
        >
          <app-change-history
            *ngIf="!formLoading"
            [moduleId]="prResponse?.module?.id"
            [objectId]="prId"
          ></app-change-history>
        </app-card>
        <div class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div
                class="button-group button-group-center"
                *ngIf="!formGroup.isView"
              >
                <app-button
                  color="PRIMARY"
                  [outline]="true"
                  (onClick)="doSave()"
                  [disabled]="formSaving || prItemList.length === 0"
                  >{{ 'app.button.save' | translate }}
                </app-button>
                <app-button
                  (onClick)="doSubmit()"
                  [disabled]="formSaving || prItemList.length === 0"
                  >{{ 'app.button.submit' | translate }}</app-button
                >
                <app-button 
                    *ngIf="
                    prStatus?.code === global.appConstant.pm.PR_STATUS_VERIFIED ||
                    prStatus?.code === global.appConstant.pm.PR_STATUS_REVISION
                  "
                  color="DANGER"
                  [outline]="true"
                  (onClick)="doCancel()"
                  [disabled]="formSaving || prItemList.length === 0"
                  >{{ 'app.button.cancel' | translate }}
                </app-button>
              </div>
            </div>
          </div>
        </div>
        <app-approval-prcs-x
          *ngIf="!formLoading && prResponse?.isUserApproval"
          [workflowModelPrcs]="prResponse?.pr?.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight *ngIf="!prResponse?.isUserApproval">
    <app-tips></app-tips>
  </ng-template>
</app-layout>
