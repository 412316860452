<app-widget [header]="moduleCode + '.title'" *ngIf="!formLoading">
  <p *ngIf="topPerformanceVendorList == null">{{ 'app-widget-top-vendor-performance.noData' | translate }}</p>
  <div class="row" *ngIf="topPerformanceVendorList != null">
    <div *ngFor="let topPerformanceVendor of topPerformanceVendorList; let i = index" class="widget-col">
      <app-widget-card>
        <div class="widget-card-header">
          <em class="fas fa-user-circle-o fa-3x"></em>
        </div>
        <ng-container *ngIf="i === 0">
          <p class="p-rating text-warning"><em class="fas fa-trophy"></em></p>
        </ng-container>
        <ng-container *ngIf="i === 1">
          <p class="p-rating">{{ i + 1 + 'nd' }}</p>
        </ng-container>
        <ng-container *ngIf="i > 1">
          <p class="p-rating">{{ i + 1 + 'rd' }}</p>
        </ng-container>
        <h6>{{ topPerformanceVendor.vendorName }}</h6>
        <div class="widget-card-footer">
          <app-rating [value]="topPerformanceVendor.rating" [isViewOnly]="true"></app-rating>
        </div>
      </app-widget-card>
    </div>
  </div>
</app-widget>