<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="sla.title" detail="app.action.{{ todo }}"
      [isLoading]="formLoading" [isSaving]="formSaving">
      <div class="row text-sm-right mb-2">
        <label class="col-sm-3 control-label">{{ 'sla.form.workflow' | translate
          }}</label>
        <div class="col-sm-4 text-left">
          {{ workflow.name }}
        </div>
      </div>

      <!-- Table Main -->
      <br>
      <h5 class="separator">{{ 'sla.form.main' | translate }}</h5>
      <app-table #selectorMain [tableResponse]="tableResponseMain"></app-table>

      <!-- Table Additional -->
      <br>
      <h5 class="separator">{{ 'sla.form.additional' | translate }}</h5>
      <app-table #selectorAdditional [tableResponse]="tableResponseAdditional">
      </app-table>

      <!-- Table Approval -->
      <br>
      <h5 class="separator">{{ 'sla.form.approval' | translate }}</h5>
      <app-table #selectorApproval [tableResponse]="tableResponseApproval">
      </app-table>

      <div class="button-group button-group-center mt-5">
        <app-button color="SECONDARY" (onClick)="doCancel()"
          [disabled]="formSaving">
          {{ 'app.button.back' | translate }}
        </app-button>
        <app-button (onClick)="doSave()" [disabled]="formSaving">
          {{ 'app.button.save' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>