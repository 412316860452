import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Vendor } from '../../core/bean/vendor';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { RecapPrintService } from '../../core/services/recap-print.service';
@Component({
  templateUrl: './performance-on-registration.component.html',
  styles: ['.width-company-name { min-width : 250px; white-space: normal ! important }'],
  encapsulation: ViewEncapsulation.None
})
export class PerformanceOnRegistrationComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Vendor>;
  public isLoading: boolean;
  constructor(translateService: TranslateService, public recapPrintService: RecapPrintService) {
    super('performance-on-registration', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: '0.name', header: 'table.column.vendorName', customClass: 'width-company-name' },
      { field: '0.code', header: 'table.column.vendorCode', customClass: 'text-center' },
      { field: '0.vendorType.name', header: 'table.column.vendorTypeName', customClass: 'text-center' },
      { field: '0.email', header: 'table.column.email' },
      { field: '1.code', header: 'table.column.performanceNumber', customClass: 'text-center' },
      { field: '1.createdDate', header: 'table.column.performanceDate', format: FieldFormatEnum.ShortDate },
      { field: '1.rating', header: 'table.column.rating', format: FieldFormatEnum.Rating, customClass: 'text-center' },
      { field: '1.workflowPrcs.workflowStatus.name', header: 'table.column.status', customClass: 'text-center' }
    ]);
    this.setStateReady();
  }

  public doEdit(data: Vendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('vendorId', data.id);
    this.global.routerParams.set('todo', 'edit');
    this.router.navigate(['/pages/performance-registration/edit']);
  }

  public doDetail(data: Vendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('vendorId', data.id);
    this.global.routerParams.set('todo', 'detail');
    this.router.navigate(['/pages/performance-registration/detail']);
  }

  public doExport(): void {
    this.isLoading = true;
    this.recapPrintService.print('/performance-on-registration/recap-performance-registration', this.tableResponse.request).subscribe(() => {
      this.isLoading = false;
    });
  }

}
