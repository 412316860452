import { BaseEntity } from '../base/base-entity';
import { Contract } from '../bean/contract';
import { User } from '../bean/user';

export class ContractReminderEntity extends BaseEntity {
  contract: Contract;
  user: User;
  reminderDate: Date;
  email: string;
  name: string;
  position: string;
}
