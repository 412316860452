<dashboard-widget-card
  [header]="('widget.widgetClaimVerification.name' | translate)"
  [isLoading]="model.isLoading"
  customClassHeader="border-bottom-0 pb-0"
  size="XL"
>
  <div class="table-responsive">
    <iframe 
    id="dashboardFrame"
    [src]="metabaseUrl | safeUrl"
    width="100%" 
    height="800px" 
    frameborder="0"
    allowfullscreen>
  </iframe>
  </div>
</dashboard-widget-card>
