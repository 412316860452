import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { File } from '../../core/bean/file';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementCancelation } from '../../core/bean/procurement-cancelation';
import { AppOfficialReportRequest } from '../../core/components/app-official-report/app-official-report.request';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { EncryptService } from '../../core/services/encrypt.service';
import { Validators } from '../../core/validators';
import { InitiationGenerateRequest } from './request/initiation-generate.request';
import { ProcurementCancelationRequest } from './request/procurement-cancelation.request';

@Component({
  templateUrl: 'initiation-popup-cancelation.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InitiationPopupCancelationComponent extends BaseComponentComponent {
  @Input() procurement: Procurement;
  @Output() public onChange: EventEmitter<ProcurementCancelationRequest> =
    new EventEmitter();

  public fileUploader: FileUploader = new FileUploader(
    '/initiation/',
    'initiation.form.headerImage',
    this.global.appConstant.fileType.DOC_CANCEL_PROCUREMENT,
    false,
    this.global.config.parameterModel.maxFileProcurementCancel
  );
  public minDate = new Date();
  public isDownloaded = false;
  public isSubmit = false;

  constructor(
    public translateService: TranslateService,
    public encryptService: EncryptService
  ) {
    super('initiation');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      letterNumber: [null],
      letterDate: [null, Validators.required()],
      stage: [null],
      note: [null, Validators.required()],
      docList: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.formGroup.patchValue({
      stage: this.procurement.workflowModelPrcs.name.replace('Approval - ', ''),
      letterDate: new Date()
    });
  }

  public doGenerate(event): void {
    this.formGroup.get('docList').clearValidators();
    this.formGroup.get('docList').updateValueAndValidity();

    this.validate();
    if (this.formGroup.valid) {
      const initiationGenerateReq = new InitiationGenerateRequest();
      initiationGenerateReq.moduleCode = 'PROCUREMENT-CANCELATION';

      const appOfficialReportRequest = new AppOfficialReportRequest();
      appOfficialReportRequest.auth = this.encryptService.encrypt([
        this.procurement.id.toString(),
        'ORT_PROCUREMENT_CANCELATION_LETTER',
        'NUMBER'
      ]);
      appOfficialReportRequest.letterDate = this.formGroup.value.letterDate;

      initiationGenerateReq.appOfficialReportRequest = appOfficialReportRequest;
      initiationGenerateReq.reason = this.formGroup.value.note || '-';

      this.loadingBlockService.showInfo(`app.msg.processing`);
      this.httpClientService
        .post<Response<File>>('/initiation/generate', initiationGenerateReq)
        .subscribe(response => {
          this.isDownloaded = true;
          if (response.status === ResponseStatusModel.OK) {
            const file: File = response.body;
            this.doDownload(
              event,
              '/initiation/file/view/' +
                file.uploadedFileName +
                '/' +
                file.fileType.code,
              file.fileName
            );
            this.loadingBlockService.close();
          }
        });
    }
  }

  public doSubmit(): void {
    this.formGroup.get('docList').setValidators([Validators.required()]);
    this.formGroup.get('docList').updateValueAndValidity();
    this.validate();
    this.isSubmit = true;

    if (this.formGroup.valid && this.isDownloaded) {
      const prompt = 'initiation.confirm.cancel.prompt';
      const title = 'initiation.confirm.cancel.title';
      const buttonLeft = 'app.button.no';
      const buttonRight = 'app.button.yesImSure';
      const icon = null;
      const isShowCloseButton = false;
      this.global.modalService
        .confirmation(
          prompt,
          title,
          buttonLeft,
          buttonRight,
          icon,
          isShowCloseButton
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const procurementCancelation = new ProcurementCancelation();
            procurementCancelation.note = this.formGroup.value.note;
            procurementCancelation.procurement = this.procurement;
            /** set module from backend by manipulate code workflowmodelprcs */
            procurementCancelation.cancelDate = this.formGroup.value.letterDate;

            const request = new ProcurementCancelationRequest();
            request.procurementCancelation = procurementCancelation;
            request.fileObjectList = this.fileUploader.fileObjectList;

            this.setStateProcessing();
            this.httpClientService
              .post<ProcurementCancelationRequest>(
                '/initiation/cancel-procurement',
                request
              )
              .subscribe(response => {
                this.setStateReady();
                this.onChange.emit({
                  procurementCancelation: response.body,
                  fileObjectList: this.fileUploader.fileObjectList
                });
              });
          }
        });
    } else {
      jQuery('.popup-cancelation')
        .get(0)
        .scrollIntoView({ behavior: 'smooth' });
    }
  }
}
