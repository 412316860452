import { ChartModel } from '../../app-chart/models/chart-model';
import { ActualPlanBarChartModel } from '../model/actual-plan-bar-chart-model';
import * as actualPlanBarchartAction from './actual-plan-bar-chart.action';
import { setBudgetInfo } from './set-budget-info.action';
import { setWorkPlanInfo } from './set-work-plan-info.action';
export function dispatchActualPlanBarChart(
  state: ActualPlanBarChartModel,
  action: actualPlanBarchartAction.All
): ChartModel {
  if (action.type === actualPlanBarchartAction.SET_BUDGET_INFO) {
    return setBudgetInfo(state, action.payload);
  } else if (action.type === actualPlanBarchartAction.SET_WORK_PLAN_INFO) {
    return setWorkPlanInfo(state, action.payload);
  } else {
    console.log(`There's no type`);
  }
}
