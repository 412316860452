import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { ModuleGroup } from './../../core/bean/module-group';
import { Workflow } from './../../core/bean/workflow';
@Component({
  templateUrl: './sla.component.html'
})
export class SlaComponent extends BaseModuleComponent {
  public moduleGroupList: ModuleGroup[];
  public tableResponse: TableResponseModel<Workflow>;
  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('sla', translateService);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.doGetAndSetModuleGroupList();
    this.setStateReady();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      organizationName: [null],
      organizationId: [null],
      moduleGroup: [null]
    });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      {
        field: 'slaUpdatedDate',
        header: 'table.column.update',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'totalSlaInDays',
        header: 'table.column.numberOfDays',
        customClass: 'text-center',
        fn: this.addUomDay.bind(this)
      }
    ]);
  }

  public doGetAndSetModuleGroupList(): void {
    this.httpClientService
      .get<ModuleGroup[]>('/sla/module-group-list', {})
      .subscribe(moduleGroupList => {
        this.moduleGroupList = moduleGroupList;
      });
  }

  public doEdit(workflow: Workflow): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('workflow', workflow);
    this.router.navigate(['/pages/sla/edit/']);
  }

  public doOnChooseOrganization(): void {
    this.appPopupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(organization => {
        const { name: organizationName, id: organizationId } =
          organization.pop();
        this.formGroup.patchValue({ organizationName, organizationId });
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
      });
  }

  public doOnChangeModuleGroup(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public addUomDay(slaInDays: number): string {
    if (slaInDays !== 0 && slaInDays !== null) {
      return slaInDays + ' ' + this.translateService.instant('sla.day');
    } else {
      return '-';
    }
  }
}
