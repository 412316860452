import { File } from '../../../../bean/file';
import { FileType } from '../../../../bean/file-type';
import { FileObject } from '../file-object';
export class FileUploader {
  fileObjectList: FileObject[] = new Array();
  status: string;
  fileType: FileType;
  fileTypeCode: string;
  url: string;
  title: string;
  maxFile: number;
  public constructorFile: File = new File();
  isRequired = false;
  titleRequired = false;
  isAllUploaded = false;
  totalUploaded: number;
  totalNotDeletedData: number;
  isSingle = true;
  constructor(
    url: string,
    title: string,
    fileTypeCode: string,
    isSingle = true,
    maxFile?: number
  ) {
    this.constructorFile.size = 0;
    this.url = url;
    this.title = title;
    this.maxFile = maxFile;
    this.fileTypeCode = fileTypeCode;
    this.totalUploaded = 0;
    this.totalNotDeletedData = 0;
    this.isSingle = isSingle;
  }

  public setFileList(fileList: File[]): void {
    this.fileObjectList = new Array();
    let count = 0;
    fileList.forEach(file => {
      const fileObject: FileObject = new FileObject(file, false);
      this.fileObjectList.push(fileObject);
      count++;
    });
    this.isAllUploaded = true;
    this.totalNotDeletedData = count;
    this.totalUploaded = count;
  }

  public setFile(file: File): void {
    if (file != null) {
      const fileObject: FileObject = new FileObject(file, false);
      this.fileObjectList = new Array();
      this.fileObjectList.push(fileObject);
      this.totalNotDeletedData = 1;
      this.totalUploaded = 1;
      this.isAllUploaded = true;
    }
  }
}
