<div class="row main-row">
  <div class="col-12">
    <app-card
      header="performance-fulfillment.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-fulfillment.form.vendorName' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ pFulfillmentEditResponse.vendor.name }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-fulfillment.form.address' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ pFulfillmentEditResponse.vendorAddress.address }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-fulfillment.form.agreementNumber' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ pFulfillmentEditResponse?.agreementNumber }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-fulfillment.form.termin' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ pFulfillmentEditResponse?.termin }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-fulfillment.form.agreementType' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ pFulfillmentEditResponse?.agreementType }}
          </div>
        </div>
        <app-fieldset legend="Common Scoring">
          <ng-container formArrayName="scoringGroupTemplateCommon">
            <div
              class="table-responsive custom-table float-none"
              [ngStyle]="{ margin: '0 auto' }"
            >
              <p
                *ngIf="
                  !scoringGroupTemplateCommon ||
                  scoringGroupTemplateCommon.length == 0
                "
                class="text-center"
              >
                <strong>{{ 'app.info.noData' | translate }}</strong>
              </p>
              <ng-container
                *ngFor="
                  let scoringGroupTemplate of scoringGroupTemplateCommon.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <label
                  ><strong>{{ scoringGroupTemplate.value.name }}</strong></label
                >
                <table
                  class="table table-striped box-shadow mb-5"
                  aria-describedby="Table"
                >
                  <thead>
                    <tr class="text-center">
                      <th scope="col"
                        >{{ 'app.table.column.no' | translate }}
                      </th>
                      <th scope="col">
                        {{
                          'performance-fulfillment.form.criteria' | translate
                        }}
                      </th>
                      <th scope="col">
                        {{ 'performance-fulfillment.form.value' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'performance-fulfillment.form.result' | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <tr *ngIf="scoringCriteriaList.controls.length == 0">
                        <td colspan="99" class="bg-white text-center">
                          {{ 'app.info.noData' | translate }}
                        </td>
                      </tr> -->
                    <ng-container formArrayName="scoringCriteriaList">
                      <tr
                        *ngFor="
                          let scoringCriteria of scoringGroupTemplate.controls
                            .scoringCriteriaList.controls;
                          let j = index
                        "
                        [formGroup]="scoringCriteria"
                      >
                        <td class="text-center">{{ j + 1 }}.</td>
                        <td class="text-center">
                          {{ scoringCriteria.value.name }}
                        </td>
                        <td class="text-center">
                          <div class="d-flex justify-content-center">
                            <div
                              *ngIf="
                                !scoringCriteria.value.scoringCriteria.value
                              "
                              class="col-sm-5 text-center"
                            >
                              <app-text-field
                                size="XL"
                                (onChange)="
                                  setResult(
                                    $event,
                                    scoringCriteria,
                                    scoringCriteria.value.scoringCriteria
                                  )
                                "
                                type="decimal"
                                formControlName="value"
                                [tooltip]="htmlContent"
                              ></app-text-field>
                              <ng-template #htmlContent>
                                <div [ngStyle]="{ width: '100px' }">
                                  <span class="d-block"
                                    >Min. input:
                                    {{
                                      getMaxMinInput(
                                        scoringCriteria.value.scoringCriteria
                                      )[0]
                                    }}</span
                                  >
                                  <span class="d-block"
                                    >Max. input:
                                    {{
                                      getMaxMinInput(
                                        scoringCriteria.value.scoringCriteria
                                      )[1] || ' - '
                                    }}</span
                                  >
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </td>
                        <td class="text-center">
                          {{ scoringCriteria.value.result }}
                        </td>
                      </tr>
                    </ng-container>
                    <tr class="text-center">
                      <td></td>
                      <td></td>
                      <td class="font-weight-bold">
                        {{ 'performance-fulfillment.form.total' | translate }}
                      </td>
                      <td class="font-weight-bold">{{
                        doSetAvgTotal(
                          scoringGroupTemplate.controls.scoringCriteriaList,
                          scoringGroupTemplate.controls.scoringCriteriaGroup
                            .value,
                          scoringGroupTemplate
                        ) | number: '1.2-2'
                      }}</td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </ng-container>
        </app-fieldset>

        <app-fieldset legend="Specific Scoring">
          <ng-container formArrayName="scoringGroupTemplateSpecific">
            <div
              class="table-responsive custom-table float-none"
              [ngStyle]="{ margin: '0 auto' }"
            >
              <p
                *ngIf="
                  !scoringGroupTemplateSpecific ||
                  scoringGroupTemplateSpecific.length == 0
                "
                class="text-center"
              >
                <strong>{{ 'app.info.noData' | translate }}</strong>
              </p>
              <ng-container
                *ngFor="
                  let scoringGroupTemplate of scoringGroupTemplateSpecific.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <label
                  ><strong>{{ scoringGroupTemplate.value.name }}</strong></label
                >
                <table
                  class="table table-striped box-shadow"
                  aria-describedby="Table"
                >
                  <thead>
                    <tr class="text-center">
                      <th scope="col"
                        >{{ 'app.table.column.no' | translate }}
                      </th>
                      <th scope="col">
                        {{
                          'performance-fulfillment.form.criteria' | translate
                        }}
                      </th>
                      <th scope="col">
                        {{ 'performance-fulfillment.form.value' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'performance-fulfillment.form.result' | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container formArrayName="scoringCriteriaList">
                      <tr
                        *ngFor="
                          let scoringCriteria of scoringGroupTemplate.controls
                            .scoringCriteriaList.controls;
                          let j = index
                        "
                        [formGroup]="scoringCriteria"
                      >
                        <td class="text-center">{{ j + 1 }}.</td>
                        <td class="text-center">
                          {{ scoringCriteria.value.name }}
                        </td>
                        <td class="text-center">
                          <div class="d-flex justify-content-center">
                            <div
                              *ngIf="
                                !scoringCriteria.value.scoringCriteria.value
                              "
                              class="col-sm-5 text-center"
                            >
                              <app-text-field
                                size="XL"
                                (onChange)="
                                  setResult(
                                    $event,
                                    scoringCriteria,
                                    scoringCriteria.value.scoringCriteria
                                  )
                                "
                                type="decimal"
                                formControlName="value"
                                [tooltip]="htmlContent"
                              ></app-text-field>
                              <ng-template #htmlContent>
                                <div [ngStyle]="{ width: '100px' }">
                                  <span class="d-block"
                                    >Min. input:
                                    {{
                                      getMaxMinInput(
                                        scoringCriteria.value.scoringCriteria
                                      )[0]
                                    }}</span
                                  >
                                  <span class="d-block"
                                    >Max. input:
                                    {{
                                      getMaxMinInput(
                                        scoringCriteria.value.scoringCriteria
                                      )[1] || ' - '
                                    }}</span
                                  >
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </td>
                        <td class="text-center">
                          {{ scoringCriteria.value.result }}
                        </td>
                      </tr>
                    </ng-container>
                    <tr class="text-center">
                      <td></td>
                      <td></td>
                      <td class="font-weight-bold">
                        {{ 'performance-fulfillment.form.total' | translate }}
                      </td>
                      <td class="font-weight-bold">{{
                        doSetAvgTotal(
                          scoringGroupTemplate.controls.scoringCriteriaList,
                          scoringGroupTemplate.controls.scoringCriteriaGroup
                            .value,
                          scoringGroupTemplate
                        ) | number: '1.2-2'
                      }}</td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </ng-container>
        </app-fieldset>

        <ng-container>
          <app-fieldset legend="Scoring Summary">
            <div
              class="table-responsive custom-table float-none"
              [ngStyle]="{ margin: '0 auto' }"
            >
              <table
                class="table table-striped box-shadow"
                aria-describedby="Table"
              >
                <thead>
                  <tr class="text-center">
                    <th scope="col">{{ 'app.table.column.no' | translate }}</th>
                    <th scope="col">
                      {{
                        'performance-fulfillment.form.criteriaGroup' | translate
                      }}
                    </th>
                    <th scope="col">
                      {{ 'performance-fulfillment.form.weight' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'performance-fulfillment.form.result' | translate }}
                    </th>
                    <th scope="col">
                      {{ 'performance-fulfillment.form.total' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container formArrayName="summaryList">
                    <tr
                      *ngFor="let data of summaryList.controls; let j = index"
                      [formGroupName]="j"
                    >
                      <td class="text-center">{{ j + 1 }}.</td>
                      <td class="text-center">{{
                        data.value.scoringCriteriaGroup.name
                      }}</td>
                      <td class="text-center">{{ data.value.weight }} %</td>
                      <td class="text-center">
                        {{ data.value.result | number: '1.2-2' }}
                      </td>
                      <td class="text-center">
                        {{
                          data.value.weight * 0.01 * data.value.result
                            | number: '1.2-2'
                        }}
                      </td>
                    </tr>
                  </ng-container>
                  <tr class="text-center">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="font-weight-bold">
                      {{
                        'performance-fulfillment.form.totalScoring' | translate
                      }}
                    </td>
                    <td class="font-weight-bold">{{ totalScoring }}</td>
                  </tr>
                  <tr class="text-center">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="font-weight-bold">
                      {{ 'performance-fulfillment.form.rating' | translate }}
                    </td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <div class="col-sm-9 text-center">
                          <app-rating
                            formControlName="rating"
                            [isViewOnly]="true"
                          ></app-rating>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </app-fieldset>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'performance-fulfillment.form.upload' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-upload formControlName="file" [fileUploader]="fileUploader">
              </app-upload>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'performance-fulfillment.form.note' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-area formControlName="note"></app-text-area>
            </div>
          </div>
        </ng-container>

        <div class="row justify-content-center mt-5">
          <app-button color="SECONDARY" (onClick)="doBack()" class="mr-1"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button color="PRIMARY" (onClick)="doSave()" class="mr-1"
            >{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
