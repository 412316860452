import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetAccordionListModule } from '../../components/widget-accordion-list/widget-accordion-list.module';
import { WidgetService } from '../../widget.service';
import { WidgetRfxTotalTransactionComponent } from './widget-rfx-total-transaction.component';
@NgModule({
  imports: [VendorModule, WidgetAccordionListModule],
  declarations: [WidgetRfxTotalTransactionComponent],
  exports: [WidgetRfxTotalTransactionComponent]
})
export class WidgetRfxTotalTransactionModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetRfxTotalTransactionComponent.moduleCode,
      WidgetRfxTotalTransactionComponent,
      'w-25'
    );
  }
}
