import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAccordionModule } from '../app-accordion/app-accordion.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppFieldsetModule } from '../app-fieldset/app-fieldset.module';
import { AppPopupModule } from '../app-popup/app-popup.module';
import { AppTableModule } from '../app-table/app-table.module';
import { AppContractRequestInfoComponent } from './app-contract-request-info.component';
import { AppPopupPrintContractRequestInfoComponent } from './app-popup-print-contract-request-info.component';

@NgModule({
  imports: [
    VendorModule,
    AppTableModule,
    AppCardModule,
    AppAccordionModule,
    AppFieldsetModule,
    AppButtonModule,
    NgbModule,
    AppPopupModule
  ],
  declarations: [
    AppContractRequestInfoComponent,
    AppPopupPrintContractRequestInfoComponent
  ],
  entryComponents: [
    AppContractRequestInfoComponent,
    AppPopupPrintContractRequestInfoComponent
  ],
  exports: [
    AppContractRequestInfoComponent,
    AppPopupPrintContractRequestInfoComponent
  ]
})
export class AppContractRequestInfoModule {}
