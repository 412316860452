import { BaseEntity } from '../base/base-entity';
import { ModuleGroup } from '../bean/module-group';
import { ValueType } from '../bean/value-type';

export class ParameterEntity extends BaseEntity {
  valueType: ValueType = new ValueType();
  moduleGroup: ModuleGroup = new ModuleGroup();

  name: string;
  value: string;
  description: string;
  type: string;
  isSensitive: boolean;
}
