import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ProcurementRegType } from '../../core/bean/procurement-reg-type';
import { ProcurementRegVendorStatus } from '../../core/bean/procurement-reg-vendor-status';
import { AppTableComponent } from '../../core/components/table/components/app-table/app-table.component';
import { TableRow } from '../../core/components/table/domain/table-row';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { ProcurementRegVendorView } from '../../core/view/entity/bean/procurement-reg-vendor-view';
import { ProcurementRegVendorOptionsResponse } from './procurement-reg-vendor-options-response';
@Component({
  templateUrl: './procurement-reg-vendor.component.html'
})
export class ProcurementRegVendorComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<ProcurementRegVendorView>;
  public procRegTypeOptionList: OptionListModel<ProcurementRegType> =
    new OptionListModel();
  public procRegVendorStatusOptionList: OptionListModel<ProcurementRegVendorStatus> =
    new OptionListModel();
  public procurementRegTypeCode: string;
  constructor(translateService: TranslateService) {
    super('procurement-reg-vendor', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.setOptionList();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.procurementRegTypeCode = this.global.routerParams.get(
      'procurementRegTypeCode'
    );
    this.global.routerParams.clear();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      startDate: [null],
      endDate: [null],
      startDateFrom: [null],
      startDateTo: [null],
      endDateFrom: [null],
      endDateTo: [null],
      procurementRegVendorStatusList: [null],
      procurementRegTypeList: [null],
      procurementRegTypeCode: [this.procurementRegTypeCode]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<ProcurementRegVendorOptionsResponse>(
        '/procurement-reg-vendor/get-option-list'
      )
      .subscribe(
        (
          procurementRegVendorOptionsResponse: ProcurementRegVendorOptionsResponse
        ) => {
          if (
            procurementRegVendorOptionsResponse.procurementRegTypeList &&
            procurementRegVendorOptionsResponse.procurementRegTypeList.length >
              0
          ) {
            this.procRegTypeOptionList.setRequestValues(
              procurementRegVendorOptionsResponse.procurementRegTypeList
            );
          }
          if (
            procurementRegVendorOptionsResponse.procurementRegVendorStatusList &&
            procurementRegVendorOptionsResponse.procurementRegVendorStatusList
              .length > 0
          ) {
            this.procRegVendorStatusOptionList.setRequestValues(
              procurementRegVendorOptionsResponse.procurementRegVendorStatusList
            );
          }
        }
      );
  }

  public buildTableResponse(): void {
    const RE_HELD: string = this.translateService.instant(
      'dynamic-master-attribute.procurementFlag.reHeld'
    );
    // this.tableResponse.
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.number',
        plugins: {
          name: 'hyperlink',
          className: 'white-space-normal',
          onClick: this.doClick.bind(this)
        }
      },
      {
        field: 'procurementDate',
        header: 'table.column.procurementDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'name',
        header: 'table.column.name',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'badge',
            field: 'isRepeat',
            pill: false,
            className: 'badge-catalog badge-danger ml-3',
            colorMap: {
              RE_HELD: 'DANGER'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.row.record.isRepeat) {
                  return RE_HELD;
                }
              }
            }
          },
          {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData): void => {
              if (!tablePluginData.row.record.isRepeat) {
                tablePluginData.element.children[1].remove();
              }
            }
          }
        ]
      },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'procurementMethodName',
        header: 'table.column.procurementMethod'
      },
      {
        field: 'procurementRegTypeName',
        header: 'table.column.procurementRegType'
      },
      {
        field: 'isRegistered',
        header: 'table.column.registrationStatus',
        dataMap:
          '{"false" : "' +
          this.translateService.instant(
            'procurement-reg-vendor.regStatus.notRegistered'
          ) +
          '" , "true" : "' +
          this.translateService.instant(
            'procurement-reg-vendor.regSstatus.registered'
          ) +
          '" }'
      },
      {
        field: 'procurementRegVendorStatusName',
        header: 'table.column.procurementRegVendorStatus',
        plugins: {
          name: 'badge',
          colorMap: {
            NEW: 'INFO',
            DRAFT: 'SECONDARY',
            VERIFICATION: 'WARNING',
            REVISION: 'FEEDBACK',
            ACCEPTED: 'SUCCESS',
            DECLINED: 'DANGER',
            EXPIRED: 'DANGER',
            HOLD: 'DANGER',
            CANCELED: 'DANGER',
            FAILED: 'DANGER'
          },
          colorField: 'procurementRegVendorStatusCode'
        }
      }
    ]);
    this.tableResponse.setCustomData(this.formGroup.value);
  }

  public doClick(procurementRegVendorView: ProcurementRegVendorView): void {
    if (procurementRegVendorView.isSchedule) {
      if (
        procurementRegVendorView.startDate &&
        procurementRegVendorView.endDate
      ) {
        const currentDate = new Date().getTime();
        const startDate = new Date(
          procurementRegVendorView.startDate
        ).getTime();
        if (
          (procurementRegVendorView.procurementRegVendorStatusCode ===
            this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_NEW ||
            procurementRegVendorView.procurementRegVendorStatusCode ===
              this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_DRAFT ||
            procurementRegVendorView.procurementRegVendorStatusCode ===
              this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_REVISION) &&
          currentDate < startDate
        ) {
          this.global.modalService.scheduleMismatch();
        } else {
          this.setRouterParamsAndNavigate(procurementRegVendorView);
        }
      } else {
        this.global.modalService.scheduleMismatch();
      }
    } else {
      this.setRouterParamsAndNavigate(procurementRegVendorView);
    }
  }

  public setRouterParamsAndNavigate(
    procurementRegVendorView: ProcurementRegVendorView
  ): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('procurementId', procurementRegVendorView.id);
    this.global.routerParams.set(
      'procurementRegVendorStatusCode',
      procurementRegVendorView.procurementRegVendorStatusCode
    );
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/procurement-reg-vendor'
    );

    if (
      procurementRegVendorView.procurementRegVendorStatusCode !==
        this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_NEW &&
      procurementRegVendorView.procurementRegVendorStatusCode !==
        this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_DRAFT &&
      procurementRegVendorView.procurementRegVendorStatusCode !==
        this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_REVISION
    ) {
      this.global.routerParams.set('todo', 'view');
    } else {
      this.global.routerParams.set('todo', 'edit');
    }
    this.router.navigate(['/pages/procurement-reg-vendor/detail']);
  }

  public onAfterRowCreated(tableRow: TableRow): void {
    if (tableRow.record.endDate) {
      const minimumDate =
        new Date().getTime() +
        24 *
          60 *
          60 *
          1000 *
          this.global.config.parameterModel.minDateProcurementRegist;
      if (
        new Date(tableRow.record.endDate).getTime() <= minimumDate &&
        (tableRow.record.procurementRegVendorStatusCode ===
          this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_NEW ||
          tableRow.record.procurementRegVendorStatusCode ===
            this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_DRAFT ||
          tableRow.record.procurementRegVendorStatusCode ===
            this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_REVISION)
      ) {
        tableRow.columnList[4].element.classList.add('text-danger');
      }
    }
  }

  public onChangeFilter(): void {
    this.formGroup.patchValue({
      startDateFrom: this.formGroup.value.startDate?.from,
      startDateTo: this.formGroup.value.startDate?.to,
      endDateFrom: this.formGroup.value.endDate?.from,
      endDateTo: this.formGroup.value.endDate?.to
    });
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
