<app-popup>
  <div class="text-center">
    <br />
    <div
      class="pvc pv-trophy"
      style="width: 100px; height: 100px; margin: 0 auto"
    ></div>
    <br />
    <h3 class="title">{{ 'Submit Vendor Reference' | translate }}</h3
    ><br />
    <div
      class="description"
      innerHTML="{{
        'You can not undo this process after submit. Are you sure to propose this vendor bellow to be the winner of this procurement?'
          | translate
      }}"
    ></div>
  </div>

  <div class="vendor-winner">
    <app-lazy-load
      (onScrollEnd)="onScrollend()"
      [isLoading]="formLoading"
      maxHeight="150px"
    >
      <ul>
        <ng-container>
          <li class="list-vendor" *ngFor="let vendorWinner of vendorWinnerList">
            <div class="px-3 font-weight-bold">
              {{
                vendorWinner?.vendorName ||
                  vendorWinner?.quotation.procurementVendor?.vendor?.name
              }}
            </div>
            <div
              class="list-item px-4 text-left"
              style="margin: 5px"
              *ngFor="let procurementItem of procurementItemList"
            >
              {{ procurementItem?.prItem?.item?.name }}
            </div>
            <div *ngFor="let quotationItem of quotationItemList">
              <div
                class="list-item px-4 text-left"
                *ngIf="
                  quotationItem.quotation.id === vendorWinner?.quotation?.id
                "
              >
                {{ quotationItem?.procurementItem?.prItem?.item?.name }}
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </app-lazy-load>
  </div>

  <!-- <ul *ngFor=" let vendorWinner of vendorWinnerList;" [ngStyle]="{
          'list-style-type': 'disc'
      }">
    <h4 class="separator"></h4>
    <h4>{{ vendorWinner?.vendorName || vendorWinner?.quotation.procurementVendor?.vendor?.name }}</h4>
    <li class="description" *ngFor="let quotationItem of quotationItemList;">
      <div *ngIf="quotationItem.quotation.id === vendorWinner?.quotation?.id">
        {{ quotationItem?.procurementItem?.prItem?.item?.name }}
      </div>
    </li>
    <li class="description" *ngFor="let procurementItem of procurementItemList;">
      {{ procurementItem?.prItem?.item?.name }}
    </li>
  </ul> -->

  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center">
      <app-button
        color="LIGHT"
        [outline]="true"
        minWidth="130px"
        (onClick)="activeModal.close(false)"
      >
        {{ 'app.button.cancel' | translate }}
      </app-button>
      <app-button (onClick)="doSubmit()" minWidth="130px">
        {{ 'Yes I am Sure' | translate }}
      </app-button>
    </div>
  </ng-template>
</app-popup>
