import { DirectorsDecreeSubmission } from '../../core/bean/directors-decree-submission';
import { File } from '../../core/bean/file';
import { Organization } from '../../core/bean/organization';
import { WorkPlan } from '../../core/bean/work-plan';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path';

export class DirectorsDecreeSubmissionResponse {
  directorsDecree: DirectorsDecreeSubmission;
  isNumberGenerator: boolean;
  workPlan: WorkPlan;
  fileList: File[];
  organizationList: Organization[];
  isUserApproval: boolean;
  approvalPathResponseModel: ApprovalPathResponseModel;
}
