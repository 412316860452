<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="reporting-sla-work-plan.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label text-right">{{
            'reporting-sla-work-plan.form.workPlan.number' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ formGroup.value.numberWorkPlan }}
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label text-right">{{
            'reporting-sla-work-plan.form.workPlan.createdDate' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ formGroup.value.createdDate | date: 'dd/MM/yyyy' }}
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label text-right">{{
            'reporting-sla-work-plan.form.workPlan.finishDate' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ formGroup.value.finishDate | date: 'dd/MM/yyyy' }}
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label text-right">{{
            'reporting-sla-work-plan.form.workPlan.createdBy' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ formGroup.value.userCreatedName }}
          </div>
        </div>
      </form>
    </app-card>
  </div>
</div>

<div class="row">
  <div class="col-lg-12 mb-4">
    <app-card header="" [isLoading]="formLoading" [isSaving]="formSaving">
      <app-table
        [tableResponse]="tableResponseWorkflowModelPrcs"
        [isServerSide]="true"
        stringUrl="/reporting-sla-work-plan/list-workflow-model-prcs"
        customData="{{ workflowPrcsId }}"
      >
        <ng-template #headerButtons> </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            color="PRIMARY"
            size="TN"
            (onClick)="doAddJustification(rowData)"
          >
            <em class="fas fa-plus"></em>
            {{ 'reporting-sla-work-plan.button.justification' | translate }}
          </app-button>
        </ng-template>
      </app-table>
      <div class="row justify-content-center mt-5">
        <app-button
          mode="BYPASS"
          color="SECONDARY"
          (onClick)="doCancel()"
          class="mr-1"
          [disabled]="formSaving"
        >
          {{ 'app.button.back' | translate }}
        </app-button>
        <app-button
          mode="BYPASS"
          (onClick)="doExport()"
          [disabled]="formGroup.invalid"
        >
          <em class="fa fa-cloud-download"></em>
          {{ 'app.button.export' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>

<div class="row">
  <div class="col-lg-12 mb-4">
    <app-card header="" [isLoading]="formLoading" [isSaving]="formSaving">
      <app-table
        [tableResponse]="tableResponseSlaJustificationLog"
        [isServerSide]="true"
        stringUrl="/reporting-sla-work-plan/list-sla-justification-log"
        customData="{{ workPlanId }}"
      >
        <ng-template #headerButtons> </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
