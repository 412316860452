<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="view-vendor.breadcrumb.comparison">
      <div
        class="table-responsive custom-table-comparison float-none"
        [ngStyle]="{ margin: '0 auto' }"
      >
        <table
          [ngStyle]="{ 'table-layout': 'fixed' }"
          class="table table-striped box-shadow"
          aria-describedby="Table"
        >
          <tr>
            <td></td>
            <td
              class="catalog-item"
              [ngStyle]="{ 'max-height': '200px' }"
              *ngFor="
                let vendorComparisonModel of viewVendorComparisonModelList;
                let i = index
              "
            >
              <div class="d-flex justify-content-end">
                <em
                  class="fas fa-times text-danger"
                  (click)="doDelete(i)"
                  (keyup)="onKeyUp($event)"
                  (keydown)="onKeyDown($event)"
                ></em>
              </div>
              <h5>{{
                vendorComparisonModel.vendorAddress.vendor.vendorEntity.name +
                  ' ' +
                  vendorComparisonModel.vendorAddress.vendor.name
              }}</h5>
              <div class="catalog-item-image">
                <img
                  [attr.src]="
                    global.config.BACKEND_ADDRESS +
                      '/view-vendor/file/view/' +
                      vendorComparisonModel.vendorAddress.vendor.logoFile
                        ?.uploadedFileName +
                      '/' + global.appConstant.fileType.IMG_LOGO_VENDOR
                      | resolveImgSrc
                      | async
                  "
                  [attr.alt]="vendorComparisonModel.vendorAddress.vendor.name"
                  width="auto"
                  height="auto"
                />
              </div>
              <app-rating
                [value]="vendorComparisonModel.rating"
                [isViewOnly]="true"
              ></app-rating>
              <div
                class="d-flex justify-content-center"
                [ngStyle]="{ 'margin-top': '20px' }"
              >
                <p
                  ><em
                    class="far fa-envelope mr-3 cursor-pointer"
                    (click)="
                      doOpenPopupEmail(vendorComparisonModel.vendorPICEmails)
                    "
                    (keyup)="onKeyUp($event)"
                    (keydown)="onKeyDown($event)"
                  ></em>
                  {{ 'view-vendor.table.contact' | translate }}
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="col">{{ 'view-vendor.table.address' | translate }}</th>
            <td
              *ngFor="
                let vendorComparisonModel of viewVendorComparisonModelList;
                let j = index
              "
            >
              {{
                vendorComparisonModel.vendorAddress.buildingName
                  ? vendorComparisonModel.vendorAddress.buildingName + ', '
                  : ''
              }}
              {{
                vendorComparisonModel.vendorAddress.address +
                  ', ' +
                  vendorComparisonModel.vendorAddress.cityRegion.name +
                  ', ' +
                  vendorComparisonModel.vendorAddress.provinceRegion.name +
                  ' - ' +
                  vendorComparisonModel.vendorAddress.countryRegion.name
              }}
            </td>
          </tr>
          <tr>
            <th scope="col">{{
              'view-vendor.table.segmentation' | translate
            }}</th>
            <td
              *ngFor="
                let vendorComparisonModel of viewVendorComparisonModelList;
                let j = index
              "
            >
              {{ vendorComparisonModel.segmentationName }}
            </td>
          </tr>
          <tr>
            <th scope="col">{{
              'view-vendor.table.joinProcurement' | translate
            }}</th>
            <td
              *ngFor="
                let vendorComparisonModel of viewVendorComparisonModelList;
                let j = index
              "
            >
              {{ vendorComparisonModel.joinProcurement }}
            </td>
          </tr>
          <tr>
            <th scope="col">{{
              'view-vendor.table.winProcurement' | translate
            }}</th>
            <td
              *ngFor="
                let vendorComparisonModel of viewVendorComparisonModelList;
                let j = index
              "
            >
              {{ vendorComparisonModel.winProcurement }}
            </td>
          </tr>
          <tr>
            <th scope="col">{{ 'view-vendor.table.contract' | translate }}</th>
            <td
              *ngFor="
                let vendorComparisonModel of viewVendorComparisonModelList;
                let j = index
              "
            >
              {{ vendorComparisonModel.contract }}
            </td>
          </tr>
          <tr>
            <th scope="col">{{
              'view-vendor.table.productQty' | translate
            }}</th>
            <td
              *ngFor="
                let vendorComparisonModel of viewVendorComparisonModelList;
                let j = index
              "
            >
              {{ vendorComparisonModel.productQty }}
            </td>
          </tr>
        </table>
      </div>
      <div class="button-group button-group-center mt-5">
        <app-button mode="BYPASS" color="SECONDARY" (onClick)="doBack()">
          {{ 'app.button.back' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>
