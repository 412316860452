import { BaseEntity } from '../base/base-entity';
import { Pr } from '../bean/pr';
import { PrStatus } from '../bean/pr-status';
import { User } from '../bean/user';

export class PrVerificationEntity extends BaseEntity {
  pr: Pr = new Pr();
  prStatus: PrStatus = new PrStatus();
  processedDate: Date;
  processedByUser: User;
  note: string;
}
