<app-popup
  [isLoading]="formLoading"
  header="workload-monitoring.popup.header.definitionLevel"
>
  {{ user.name }} {{ 'workload-monitoring.popup.msg.first' | translate }}
  <span
    [ngClass]="
      workloadLevel.code === global.appConstant.core.WORKLOAD_LEVEL_HIGH
        ? 'text-danger'
        : workloadLevel.code === global.appConstant.core.WORKLOAD_LEVEL_MEDIUM
        ? 'text-warning'
        : 'text-primary'
    "
    >{{
      workloadLevel.translationKey.module.code.toLowerCase() +
        '.' +
        workloadLevel.translationKey.key
    }}</span
  >
  {{ 'workload-monitoring.popup.msg.last' | translate }} {{ levelThreshold }}
</app-popup>
