import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { User } from '../../core/bean/user';
import { WorkloadLevel } from '../../core/bean/workload-level';

@Component({
  templateUrl: './app-popup-definition-level.component.html'
})
export class AppPopupDefinitionLevelComponent extends BaseComponentComponent {
  @Input() public levelThreshold: string;
  @Input() public workloadLevel: WorkloadLevel;
  @Input() public user: User;

  public constructor(public ngbActiveModal: NgbActiveModal) {
    super('workload');
  }

  public onInit(): void {
    this.setStateReady();
  }
}
