import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Role } from '../../core/bean/role';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: 'role-menu.component.html',
})
export class RoleMenuComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Role>;
  constructor(translateService: TranslateService) {
    super('role-menu', translateService);
  }

  onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.role', customClass: 'text-center' }
    ]);
  }

  public doEdit(role: Role): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('role', role);
    this.router.navigate(['/pages/role-menu/edit']);
  }
}
