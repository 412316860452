<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <form class="form-horizontal">
            <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{ 'tab-login.username' | translate }}</label>
                <div class="col-sm-9 text-left">
                    {{ vendor.user?.name !== null ? vendor.user.name : '-' }}
                </div>
            </div>
            <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{ 'tab-login.email' | translate }}</label>
                <div class="col-sm-9 text-left">
                    {{ vendor.user?.email !== null ? vendor.user.email : '-'}}
                </div>
            </div>
        </form>
    </div>
</div>