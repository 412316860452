import { BaseEntity } from '../base/base-entity';
import { Bank } from '../bean/bank';
import { Region } from '../bean/region';
import { Vendor } from '../bean/vendor';
import { Currency } from './../bean/currency';
export class VendorBankEntity extends BaseEntity {
  bank: Bank;
  cityRegion: Region;
  countryRegion: Region;
  currency: Currency;
  vendor: Vendor;
  isPriority: boolean;
  coordinateLocation: string;
  branch: string;
  address: string;
  accountNumber: string;
  accountHolder: string;
}
