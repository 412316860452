import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { OptionListModel } from '../../model/option-list-model';
import { AutoCompleteModel } from './model/auto-complete-model';
@Component({
  selector: 'app-auto-complete',
  templateUrl: './app-auto-complete.component.html',
  styleUrls: ['./app-auto-complete.component.scss'],
  providers: makeProvider(AppAutocompleteComponent),
  encapsulation: ViewEncapsulation.None
})
export class AppAutocompleteComponent
  extends BaseValueAccessorComponent<any>
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() customData: string;
  @Input() params: Object;
  @Input() container: 'body';
  strSearch: string;
  selectElement: any;
  divSelectContainerElement: HTMLElement;
  divInputElement: any;
  divAppAutoCompleteElement: HTMLElement;
  isFocus = false;
  selectSize = 5;
  isLoading: boolean;
  previousTextValue: string;
  httpRequest: Subscription;
  private urlParams: string;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public renderer2: Renderer2
  ) {
    super('app-auto-complete', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setStateUrlParams();
  }

  ngAfterViewInit(): void {
    this.setElement();
    if (!this.ISVIEW) {
      this.setOptionList();
      this.handleOptionListRequestValueChanges();
      this.setInputDisabled();
    }
  }

  ngOnChanges(value: SimpleChanges): void {
    if (
      value &&
      value.params &&
      value.params.currentValue !== value.params.previousValue
    ) {
      this.setStateUrlParams();
    }
  }

  ngOnDestroy(): void {
    if (this.container === 'body') {
      this.removeSelectElement();
    }
  }

  private setStateUrlParams(): void {
    this.urlParams = this.params
      ? '&' +
        Object.keys(this.params)
          .map(
            key =>
              this.params[key] &&
              `${key}=${JSON.stringify(this.params[key])
                .trim()
                .replace(/"/g, '')}`
          )
          .filter(val => val)
          .join('&')
      : '';
  }

  setInputDisabled(): void {
    if (this.disabled) {
      this.divInputElement.disabled = this.disabled;
    }
  }

  setOptionList(): void {
    this.optionList = new OptionListModel(true);
  }

  setElement(): void {
    this.divAppAutoCompleteElement = this.elementRef.nativeElement.children[0];
    this.divInputElement = this.divAppAutoCompleteElement.children[0];
    if (!this.container) {
      this.divSelectContainerElement = this.divAppAutoCompleteElement
        .children[1] as HTMLElement;
    }
  }

  handleOptionListRequestValueChanges(): void {
    this.optionList.requestValueChanges.subscribe((values: Array<any>) => {
      this.selectSize =
        values.length > 5 ? 5 : values.length < 2 ? 2 : values.length;
      this.builderSelectElement();
    });
  }

  builderSelectElement(): void {
    this.removeSelectElement();
    this.selectElement = this.renderer2.createElement('select');
    this.selectElement.size = this.selectSize;
    this.renderer2.addClass(this.selectElement, 'custom-select');
    this.builderSelectOption();
    if (this.container === 'body') {
      this.divSelectContainerElement = document.createElement('div');
      this.divSelectContainerElement.classList.add('app-select-container');
      document.body.appendChild(this.divSelectContainerElement);
      this.renderer2.appendChild(
        this.divSelectContainerElement,
        this.selectElement
      );
      this.setDivSelectContainerPosition();
    } else {
      this.renderer2.appendChild(
        this.divSelectContainerElement,
        this.selectElement
      );
    }
  }

  setDivSelectContainerPosition(): void {
    if (this.divSelectContainerElement) {
      const boundingClientRect =
        this.divAppAutoCompleteElement.getBoundingClientRect();
      const offsetLeft = boundingClientRect.left + window.scrollX;
      const offsetTop = boundingClientRect.top + window.scrollY;
      this.divSelectContainerElement.style.transform = `translate(${offsetLeft}px, ${
        offsetTop + boundingClientRect.height + 5
      }px)`;
    }
  }

  builderSelectOption(): void {
    this.selectElement.size = this.selectSize;
    const optionsValues = this.optionList.getRequestValues(5);
    if (optionsValues.length > 0) {
      this.renderer2.removeClass(this.selectElement, 'not-found');
      optionsValues.forEach((option: any) => {
        const selectOption = this.renderer2.createElement('option');
        const optionValue = this.optionList.valuePath
          ? option[this.optionList.valuePath]
          : option.id || option;
        if (this.formControl.value) {
          const selectedValue = this.optionList.valuePath
            ? this.formControl.value[this.optionList.valuePath]
            : this.formControl.value.id || this.formControl.value;
          selectOption.selected =
            typeof selectedValue === 'object'
              ? JSON.stringify(selectedValue) === JSON.stringify(optionValue)
              : selectedValue === optionValue;
        }
        selectOption.value = optionValue;
        const text = this.optionList.viewPath
          ? option[String(this.optionList.viewPath)]
          : option.name || option;
        const selectOptionText = this.renderer2.createText(text);
        this.renderer2.listen(selectOption, 'click', (event: MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
          event.stopImmediatePropagation();
          this.isFocus = true;
          this.handleChangeSelect(option);
          this.removeSelectElement();
        });
        this.renderer2.appendChild(selectOption, selectOptionText);
        this.renderer2.appendChild(this.selectElement, selectOption);
      });
    } else {
      const selectOption = this.renderer2.createElement('option');
      const selectOptionText = this.renderer2.createText(
        this.global.translateService.instant('app.info.notFound')
      );
      this.renderer2.appendChild(selectOption, selectOptionText);
      this.renderer2.addClass(this.selectElement, 'not-found');
      this.renderer2.appendChild(this.selectElement, selectOption);
    }
  }

  reload(): void {
    this.isLoading = true;
    this.setOptionListRequestValue();
  }

  handleFocus(event: any): void {
    this.strSearch = event.target.value || '';
    this.reload();
  }

  handleFocusOut(): void {
    this.formControl.markAsTouched();
    this.value = this.value && this.value.id ? this.value : null;
  }

  handleInput(event: any): void {
    this.strSearch = event.target.value || '';
    this.formControl.markAsDirty();
    this.formControl.reset();
    this.value = event.target.value;
    this.reload();
  }

  setOptionListRequestValue(): void {
    if (!(this.strSearch.length > 0 && this.strSearch.trim().length === 0)) {
      if (this.httpRequest) {
        this.httpRequest.unsubscribe();
      }
      const encodedValue = encodeURIComponent(this.strSearch.trim());
      const stringUrl = `${this.stringUrl}?keyword=${encodedValue}${this.urlParams}`;
      this.httpRequest = this.httpClientService
        .get<AutoCompleteModel[]>(stringUrl)
        .subscribe((values: Array<object>) => {
          this.optionList.setViewPath(
            this.optionView ? this.optionView : 'name'
          );
          this.optionList.setRequestValues(values);
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
  }

  handleChangeSelect(option: any): void {
    this.divSelectContainerElement.classList.toggle('show');
    const optionListValues = this.optionList.getRequestValues();
    this.value = optionListValues.filter(
      (o: object) => JSON.stringify(o) === JSON.stringify(option)
    )[0];
    this.formControl.patchValue(this.value);
    this.optionList.setResponseValues(this.value);
    this.onChange.emit(this.value);
    this.isFocus = false;
  }

  removeSelectElement(): void {
    if (this.container === 'body') {
      if (this.divSelectContainerElement) {
        document.body.removeChild(this.divSelectContainerElement);
        this.divSelectContainerElement = null;
      }
    } else {
      if (this.selectElement) {
        this.renderer2.removeChild(
          this.divSelectContainerElement,
          this.selectElement
        );
        this.selectElement = null;
      }
    }
  }

  @HostListener('document:click')
  handleDocumentClick(): void {
    if (!this.isFocus && this.selectElement) {
      this.removeSelectElement();
      this.isFocus = false;
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    if (this.container === 'body') {
      this.setDivSelectContainerPosition();
    }
  }
}
