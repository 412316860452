<app-popup
  header="executor.popup.executor-assignment-title"
  [isLoading]="formLoading"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3 control-label required">
        {{ 'executor.popup.form.user-executor' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-dropdown-select
          formControlName="userExecutor"
          type="AUTOCOMPLETE"
          container="body"
          [optionList]="userExecutorOptionList"
        ></app-dropdown-select>
      </div>
    </div>
  </form>
  <small class="text-danger" *ngIf="isNoCounterSelected">
    {{ 'app.validation.chooseOneData' | translate }}
  </small>
  <app-table-xx
    [model]="tableResponseCounter"
    [isMultipleSelect]="true"
    [isShowCheckBox]="true"
    (onChange)="doValidate()"
  >
  </app-table-xx>
  <div class="row justify-content-center mt-5">
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          [disabled]="formSaving"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button
          color="PRIMARY"
          (onClick)="doAddExecutorAssignment()"
          [disabled]="formLoading"
          >{{ 'app.button.add' | translate }}</app-button
        >
      </div>
    </ng-template>
  </div>
</app-popup>
