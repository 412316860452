import { EventEmitter, Injectable } from '@angular/core';
import { BroadcastMessage } from '../../bean/broadcast-message';
import { AppAlertTopComponent } from '../../components/app-alert/app-alert-top/app-alert-top.component';
import { AppDynamicComponentService } from '../app-dynamic-component.service';
import { TextService } from '../text.service';
import { ActiveAlertTop } from './interface/active-alert-top';
import { AlertTopServiceOptions } from './interface/alert-top-service-options';
@Injectable({
  providedIn: 'root'
})
export class AlertTopService {
  private generatedId: string;
  public currentElement: any;
  private close: EventEmitter<boolean> = new EventEmitter();
  private activeAlertTop: ActiveAlertTop<any>;
  constructor(
    private appDynamicComponentService: AppDynamicComponentService,
    textService: TextService
  ) {
    this.generatedId = textService.generateRandomString();
  }

  public showInfo(
    broadcastMessage: BroadcastMessage,
    options: AlertTopServiceOptions = {}
  ): void {
    this.createAlertTop(broadcastMessage, options);
  }

  public showSuccess(
    broadcastMessage: BroadcastMessage,
    options: AlertTopServiceOptions = {}
  ): void {
    this.createAlertTop(broadcastMessage, options);
  }

  public showWarning(
    broadcastMessage: BroadcastMessage,
    options: AlertTopServiceOptions = {}
  ): void {
    this.createAlertTop(broadcastMessage, options);
  }

  public showError(
    broadcastMessage: BroadcastMessage,
    options: AlertTopServiceOptions = {}
  ): void {
    this.createAlertTop(broadcastMessage, options);
  }

  private createAlertTop(
    broadcastMessage: BroadcastMessage,
    options: AlertTopServiceOptions
  ): void {
    if (this.activeAlertTop) {
      this.activeAlertTop.close();
    }
    options.static = options.static || true;
    const result: EventEmitter<any> = new EventEmitter();
    const alertWrapperElement = this.createAlertWrapper();
    const alertWrapperContent = document.createElement('div');
    const alertContentId = Date.now().toString();
    alertWrapperContent.id = alertContentId;
    alertWrapperContent.classList.add('alert-wrapper-content');
    alertWrapperElement.appendChild(alertWrapperContent);
    const removeAlert = () =>
      this.doRemoveAlert(alertWrapperContent, alertWrapperElement, options);
    this.activeAlertTop = {
      close: () => removeAlert(),
      result: (props: any) => {
        removeAlert();
        result.emit(props);
      },
      resetFixedElement: () =>
        this.setFixedElementFromTop(alertWrapperElement, options)
    };
    this.close.subscribe(() => {
      removeAlert();
    });
    const props: object =
      typeof broadcastMessage === 'undefined'
        ? {}
        : typeof broadcastMessage === 'object'
        ? { broadcastMessage }
        : broadcastMessage;
    this.appDynamicComponentService.create(
      AppAlertTopComponent,
      alertWrapperContent,
      { activeAlertTop: this.activeAlertTop, ...props }
    );
    this.setFixedElementFromTop(alertWrapperElement, options);
    this.removeAlert(alertWrapperContent, alertWrapperElement, options);
    this.currentElement = alertWrapperContent;
  }

  private removeAlert(
    alertWrapperContent: any,
    alertWrapperElement: any,
    options?: AlertTopServiceOptions
  ): void {
    if (!options || (options && !options.static)) {
      jQuery('#' + alertWrapperContent.id)
        .hide()
        .fadeIn(400)
        .delay(5000)
        .fadeOut(500, () => {
          this.doRemoveAlert(alertWrapperContent, alertWrapperElement, options);
        });
    }
  }

  private doRemoveAlert(
    alertWrapperContent: any,
    alertWrapperElement: any,
    options?: AlertTopServiceOptions
  ): void {
    const alertElement = document.getElementById(alertWrapperContent.id);
    jQuery(alertElement).remove();
    this.setFixedElementFromTop(alertWrapperElement, options);
    this.doRemoveParentElement(alertWrapperElement);
  }

  private createAlertWrapper(): any {
    let alertWrapperElement = document.getElementById(this.generatedId);
    if (!alertWrapperElement) {
      alertWrapperElement = document.createElement('div');
      alertWrapperElement.classList.add('alert-wrapper');
      alertWrapperElement.id = this.generatedId;
    }
    jQuery(alertWrapperElement).insertBefore('app-root');
    return alertWrapperElement;
  }

  private setFixedElementFromTop(
    alertWrapperElement: any,
    options: AlertTopServiceOptions
  ): void {
    const fromTop = alertWrapperElement.scrollHeight + 'px';
    const fixedSelectorList = (options && options.fixedClassList) || [
      'fixed-top',
      'sidebar-outer'
    ];
    const fromTopClassList = (options && options.fromTopClassList) || ['app'];
    fixedSelectorList.forEach(className => {
      jQuery('.' + className).css({
        top: fromTop
      });
    });
    fromTopClassList.forEach(className => {
      jQuery('.' + className).css({
        paddingTop: fromTop
      });
    });
  }

  private doRemoveParentElement(alertWrapperElement: any): void {
    if (alertWrapperElement.children.length === 0) {
      jQuery(alertWrapperElement).remove();
    }
  }
}
