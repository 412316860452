import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppApprovalPrcsXModule } from '../../core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppItemRequestModule } from '../../core/components/app-item-request/app-item-request.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { RfpEditAddComponent } from './rfp-edit-add.component';
import { RfpPopupCancelComponent } from './rfp-popup-cancel.component';
import { RfpPopupChooseProposalCriteriaComponent } from './rfp-popup-choose-proposal-criteria.component';
import { RfpPopupResponseComponent } from './rfp-popup-response.component';
import { RfpComponent } from './rfp.component';

export const routes = [
  { path: '', component: RfpComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: RfpEditAddComponent,
    data: { breadcrumb: 'rfp.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: RfpEditAddComponent,
    data: { breadcrumb: 'rfp.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: RfpEditAddComponent,
    data: { breadcrumb: 'rfp.breadcrumb.detail' }
  },
  {
    path: 'detail/:token',
    component: RfpEditAddComponent,
    data: { breadcrumb: '' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppApprovalPrcsXModule,
    AppItemRequestModule
  ],
  declarations: [
    RfpComponent,
    RfpEditAddComponent,
    RfpPopupChooseProposalCriteriaComponent,
    RfpPopupCancelComponent,
    RfpPopupResponseComponent
  ]
})
export class RfpModule {}
