<div class="alert-icon lmb-2">
  <div class="alert-icon-content">
    <div class="alert alert-dismissible show" role="alert" [ngClass]="color">
      <div class="content-icon pvc" [ngClass]="icon"></div>
      <div class="content-message">
        <ng-content></ng-content>
      </div>
      <div class="button-close" *ngIf="isShowCloseButton">
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          ><em class="pir pi-times"></em>
        </button>
      </div>
    </div>
  </div>
</div>
