import { BaseDTO } from '../../../core/base/base-dto';
import { VendorCompanyOrganization } from '../../../core/bean/vendor-company-organization';
import { VendorCompanyOrganizationHistory } from '../../../core/bean/vendor-company-organization-history';

export class TabProfileCompanyOrganizationDTO extends BaseDTO {
    companyOrganizationCommissionerHistoryList: VendorCompanyOrganizationHistory[];
    companyOrganizationDirectorHistoryList: VendorCompanyOrganizationHistory[];
    companyOrganizationShareHolderHistoryList: VendorCompanyOrganizationHistory[];

    companyOrganizationCommissionerList: VendorCompanyOrganization[];
    companyOrganizationDirectorList: VendorCompanyOrganization[];
    companyOrganizationShareHolderList: VendorCompanyOrganization[];
}
