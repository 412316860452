import { FileObject } from 'src/app/core/components/upload';
import { DeliveryItem } from '../../../core/bean/delivery-item';
import { ProcurementResult } from '../../../core/bean/procurement-result';
import { ProcurementScopeWork } from '../../../core/bean/procurement-scope-work';
import { ProcurementSow } from '../../../core/bean/procurement-sow';
import { ProcurementSowPaymentTerm } from '../../../core/bean/procurement-sow-payment-term';
import { ProcurementSowPaymentTermItem } from '../../../core/bean/procurement-sow-payment-term-item';

export class DeliveryMonitoringRequest {
  fileObjectList: FileObject[];
  deliveryItem: DeliveryItem;
  procurementScopeWork: ProcurementScopeWork;
  procurementSow: ProcurementSow;
  procurementSowPaymentTermItem: ProcurementSowPaymentTermItem;
  procurementSowPaymentTerm: ProcurementSowPaymentTerm;
  procurementResult: ProcurementResult;
}
