import { CertificatePeriod } from '../bean/certificate-period';
import { User } from '../bean/user';
import { Vendor } from '../bean/vendor';
import { VendorHistory } from '../bean/vendor-history';
import { BaseEntity } from './../base/base-entity';
import { CertificateStatus } from './../bean/certificate-status';
export class VendorCertificateEntity extends BaseEntity {
  isPrint: boolean;
  certificateStatus: CertificateStatus = new CertificateStatus();
  certificatePeriod: CertificatePeriod = new CertificatePeriod();
  verifiedByUser: User;
  approvedByUser: User;
  vendor: Vendor = new Vendor();
  vendorHistory: VendorHistory = new VendorHistory();
  certificateNumber: string;
  expiredDate: Date;
  sequence: number;
  releaseDate: Date;
  periodName: string;
}
