import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';
import { Region } from '../../../core/bean/region';
import { VendorPICHistory } from '../../../core/bean/vendor-pic-history';
import { VendorPosition } from '../../../core/bean/vendor-position';
import { OptionListModel } from '../../../core/model/option-list-model';
import { Validators } from '../../../core/validators';
import { VendorPopupVendorPICDTO } from '../dto/vendor-popup-vendor-pic.dto';

@Component({
  templateUrl: './app-popup-pic.component.html'
})
export class AppPopupPICComponent extends BaseComponentComponent {
  @Input() todo: string;
  @Input() vendorPICHistoryEdit: VendorPICHistory;
  @Input() vendorPICHistoryId: number;
  @Input() vendorId: number;
  @Input() emailList: string[];
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  public vendorPopupVendorPICDTO: VendorPopupVendorPICDTO =
    new VendorPopupVendorPICDTO();
  public vendorPositionOptionList: OptionListModel<VendorPosition> =
    new OptionListModel();
  public countryRegionOptionList: OptionListModel<Region> =
    new OptionListModel();
  constructor() {
    super('tab-profile-company-data');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    if (this.todo === 'detail') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required()],
      vendorPosition: [null, Validators.required()],
      email: [
        null,
        {
          validators:
            this.todo === 'edit'
              ? [Validators.email(), Validators.required()]
              : [
                  Validators.email(),
                  Validators.required(),
                  Validators.exists(this.emailList)
                ],
          asyncValidators: [
            Validators.existsAsync(
              '/tab-profile-company-data/check-vendor-pic-exist',
              null,
              null,
              { id: this.vendorPICHistoryId }
            )
          ]
        }
      ],
      isEmailNotification: [null],
      phone: [
        null,
        {
          validators: [Validators.required()],
          asyncValidators: [
            Validators.existsAsync(
              '/tab-profile-company-data/check-vendor-pic-exist',
              null,
              null,
              { id: this.vendorPICHistoryId }
            )
          ]
        }
      ],
      vendorPICId: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<VendorPopupVendorPICDTO>(
        '/tab-profile-company-data/add-edit-popup-vendor-pic',
        null
      )
      .subscribe(vendorPopupVendorPICDTO => {
        this.vendorPopupVendorPICDTO = vendorPopupVendorPICDTO;
        this.vendorPositionOptionList.setRequestValues(
          vendorPopupVendorPICDTO.vendorPositionList
        );
        this.countryRegionOptionList.stringUrl = '/resource/';
        this.countryRegionOptionList.fileTypeCode =
          this.global.appConstant.fileType.IMG_ICON;
        this.countryRegionOptionList.setRequestValues(
          vendorPopupVendorPICDTO.countryRegionList
        );
        if (this.vendorPICHistoryEdit != null) {
          this.formGroup.patchValue(this.vendorPICHistoryEdit);
          const phoneNumber = this.vendorPICHistoryEdit.phone;
          const country = this.vendorPICHistoryEdit.phoneRegion;
          this.formGroup.patchValue({
            phone: { country, number: phoneNumber }
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup);
    }
  }
}
