import { Contract } from 'src/app/core/bean/contract';
import { ContractCancelation } from 'src/app/core/bean/contract-cancelation';
import { ContractShipping } from 'src/app/core/bean/contract-shipping';
import { ContractShippingDetail } from 'src/app/core/bean/contract-shipping-detail';
import { Fines } from 'src/app/core/bean/fines';
import { Guarantee } from 'src/app/core/bean/guarantee';
import { PrItemReleased } from 'src/app/core/bean/pr-item-released';
import { Sow } from 'src/app/core/bean/sow';
import { FileObject } from 'src/app/core/components/upload';

export class ContractRequestXRequest {
  isSubmit = false;
  contract: Contract;
  fileList: FileObject[] = [];
  contractShippingDetailList: ContractShippingDetail[] = [];
  sowList: Sow[] = [];
  finesList: Fines[] = [];
  guaranteeList: Guarantee[] = [];
  prItemReleasedList: PrItemReleased[] = [];

  responseToConfirmNote: string;

  deleteSowList: Sow[] = [];
  deleteContractShippingList: ContractShipping[] = [];
  contractCancelation: ContractCancelation = new ContractCancelation();
  contractShippingList: ContractShipping[] = [];
}
