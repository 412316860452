<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="user.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-table [model]="tableResponse" [isServerSide]="true"
        stringUrl="/user/index">
        <ng-template #headerButtons>
          <!-- <app-button (onClick)="doImport()">
            <em class="far fa-file"></em>{{ 'app.button.import' | translate }}
          </app-button> -->
          <app-button mode="BYPASS" (onClick)="doExport()"
            [disabled]="isLoading">
            <em class="fas"
              [ngClass]="!isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'"></em>{{
            'app.button.export' | translate }}
          </app-button>
          <app-button (onClick)="doAdd()">
            <em class="fas fa-plus"></em>{{ 'app.button.add' | translate }}
          </app-button>
        </ng-template>
        <ng-template #headerFilterTable>
          <div class="app-table-filter-item" [formGroup]="formGroup">
            <label for="organizationName">Organisasi</label>
            <app-choose size="XL" formControlName="organizationName"
              (onClick)="onChooseOrganization($event)"></app-choose>
          </div>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button size="TN" type="button" (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}">
            <em class="fas fa-pencil-alt"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>