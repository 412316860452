import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from '../../../../../../core/services/http-client.service';
import { TableVendorRequestModel } from './model/table-vendor-request-model';

@Injectable()
export class AppTableVendorService {
  constructor(public httpClientService: HttpClientService) {}
  getVendors(stringUrl: string, tableRequest: TableVendorRequestModel): Observable<Array<any>> {
    return this.httpClientService.post<Array<any>>(stringUrl, tableRequest);
  }
}
