import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppProcurementInformationViewModule } from 'src/app/core/components/app-procurement-information-view/app-procurement-information-view.module';
import { AppVendorPaymentInformationModule } from 'src/app/core/components/app-vendor-payment-information/app-vendor-payment-information.module';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { ProcurementScopeWorkEditComponent } from './procurement-scope-work-edit.component';
import { ProcurementScopeWorkComponent } from './procurement-scope-work.component';

export const routes = [
  {
    path: '',
    component: ProcurementScopeWorkComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'edit',
    component: ProcurementScopeWorkEditComponent,
    data: { breadcrumb: 'procurement-scope-work.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: ProcurementScopeWorkEditComponent,
    data: { breadcrumb: 'procurement-scope-work.breadcrumb.view' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppProcurementInformationViewModule,
    AppVendorPaymentInformationModule
  ],
  declarations: [
    ProcurementScopeWorkComponent,
    ProcurementScopeWorkEditComponent
  ]
})
export class ProcurementScopeWorkModule {}
