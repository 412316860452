import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Contract } from 'src/app/core/bean/contract';
import { ContractTermination } from 'src/app/core/bean/contract-termination';
import { File } from 'src/app/core/bean/file';
import { WorkflowModelPrcs } from 'src/app/core/bean/workflow-model-prcs';
import { WorkflowPrcs } from 'src/app/core/bean/workflow-prcs';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { FileUploader } from 'src/app/core/components/upload';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { AppPopupContractTerminationChooseContractComponent } from './app-popup-contract-termination-choose-contract.component';
import { ContractTerminationEditAddResponse } from './contract-termination-edit-add-response';
import { ContractTerminationInsertRequest } from './contract-termination-insert-request';
import { ContractTerminationUpdateRequest } from './contract-termination-update-request';

@Component({
  templateUrl: './contract-termination-edit-add.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None
})
export class ContractTerminationEditAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  public contractId: number;
  public contract: Contract = new Contract();
  public contractTermination: ContractTermination = new ContractTermination();
  public contractTerminationEditAddResponse: ContractTerminationEditAddResponse =
    new ContractTerminationEditAddResponse();
  public contractTerminationInsertRequest: ContractTerminationInsertRequest =
    new ContractTerminationInsertRequest();
  public contractTerminationUpdateRequest: ContractTerminationUpdateRequest =
    new ContractTerminationUpdateRequest();
  public objectId: number;
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public workflowPrcs: WorkflowPrcs;
  public urlBack: string;
  public currentDate: Date = new Date();
  public fileUploader: FileUploader = new FileUploader(
    '/contract-termination/',
    '',
    this.global.appConstant.fileType.DOC_CONTRACT_TERMINATION
  );
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('contract-termination', translateService);
  }

  public onInit(): void {
    this.setInitializationState();
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    const todoLocalStorage = localStorage.getItem('todo');
    if (todoLocalStorage) {
      this.todo = todoLocalStorage;
    } else {
      this.todo = this.global.routerParams.get('todo');
    }
    this.contractId = this.global.routerParams.get('contractId');
    this.contract = this.global.routerParams.get('contract');
    this.urlBack = this.global.routerParams.get('urlBackOutside');
    this.objectId = this.global.routerParams.get('objectId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      date: [null, Validators.required()],
      terminationFile: [null, Validators.required()],
      file: [new File()],
      description: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(512)])
      ]
    });
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view' || this.todo === 'viewContractTermination') {
      this.setViewOnly();
    }
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ContractTerminationEditAddResponse>(
        '/contract-termination/add-edit',
        new RouteRequestModel(this.todo, this.objectId || this.contractId)
      )
      .subscribe(contractTerminationEditAddResponse => {
        this.contractTerminationEditAddResponse =
          contractTerminationEditAddResponse;
        if (this.objectId != null) {
          this.contractTermination =
            this.contractTerminationEditAddResponse.contractTermination;
          this.workflowPrcs = this.contractTermination.workflowPrcs;
          this.workflowModelPrcs = this.contractTermination.workflowModelPrcs;
        }
        if (contractTerminationEditAddResponse.contractTermination !== null) {
          const date: Date = new Date(
            contractTerminationEditAddResponse.contractTermination.date
          );
          this.fileUploader.setFile(
            contractTerminationEditAddResponse.contractTermination.file
          );
          this.formGroup.patchValue({
            id: contractTerminationEditAddResponse.contractTermination.id,
            date,
            terminationFile: this.fileUploader,
            description:
              contractTerminationEditAddResponse.contractTermination.description
          });
          this.workflowPrcs =
            this.contractTerminationEditAddResponse.contractTermination.workflowPrcs;
          this.workflowModelPrcs =
            this.contractTerminationEditAddResponse.contractTermination.workflowModelPrcs;
        }
        let data = localStorage.getItem('temp') as any;
        if (data) {
          data = JSON.parse(data.toString());
          if (this.todo === 'add') {
            this.fileUploader.setFile(data.terminationFile ? data.terminationFile[0].file : null);
          } else if (this.todo === 'edit') {
            this.fileUploader.setFile(data.terminationFile.fileObjectList[0].file);
          }
          this.formGroup.patchValue(data);
          this.formGroup.patchValue({
            date: new Date(data.date),
            terminationFile: this.fileUploader
          });
          localStorage.removeItem('temp');
          localStorage.removeItem('todo');
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      if (this.todo === 'add') {
        this.doInsertContractTermination();
      } else if (this.todo === 'editContractTermination') {
        this.doUpdateContractTermination();
      }
    }
  }

  public doInsertContractTermination(): void {
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.contractTermination = this.formGroup.value;
          this.contractTerminationInsertRequest.contractTermination =
            this.contractTermination;
          this.contractTerminationInsertRequest.contract = this.contract;
          this.contractTerminationInsertRequest.fileObjectList =
            this.fileUploader.fileObjectList;
          this.contractTermination = this.global.copyFormAttributeToModel(
            this.contractTermination,
            this.formGroup
          );
          const url = '/contract-termination/insert';
          this.httpClientService
            .post<Response<ContractTermination>>(
              url,
              this.contractTerminationInsertRequest
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.router.navigate(['/pages/contract-termination']);
                this.global.alertService.showSuccessSavingOnNextRoute();
              } else {
                this.setStateReady();
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doUpdateContractTermination(): void {
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.contractTermination = this.formGroup.value;
          this.contractTerminationUpdateRequest.contractTermination =
            this.contractTermination;
          this.contractTerminationUpdateRequest.contract = this.contract;
          this.contractTerminationUpdateRequest.fileObjectList =
            this.fileUploader.fileObjectList;
          this.contractTermination = this.global.copyFormAttributeToModel(
            this.contractTermination,
            this.formGroup
          );
          const url = '/contract-termination/update';
          this.httpClientService
            .post<Response<ContractTermination>>(
              url,
              this.contractTerminationUpdateRequest
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.router.navigate(['/pages/contract-termination']);
                this.global.alertService.showSuccessSavingOnNextRoute();
              } else {
                this.setStateReady();
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doChangeContract(): void {
    this.global.modalService
      .confirm(
        this.translateService.instant('contract-termination.confirmation.body'),
        this.translateService.instant(
          'contract-termination.confirmation.header'
        )
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.appPopupService
            .open(
              AppPopupContractTerminationChooseContractComponent,
              {
                contractId: this.contract.id,
                contract: this.contract
              },
              { size: 'xl' }
            )
            .subscribe(contract => {
              this.log.debug(contract);
              this.setStateLoading();
              this.global.routerParams.clear();
              this.global.routerParams.set('todo', 'add');
              this.global.routerParams.set('contractId', contract.id);
              this.global.routerParams.set('contract', contract);
              this.global.routerParams.set(
                'urlBackOutside',
                '/pages/contract-termination'
              );
              this.setInitializationState();
            });
        }
      });
  }

  public doBack(): void {
    this.router.navigate(['/pages/contract-termination']);
  }
}
