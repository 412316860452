import { Region } from '../../../core/bean/region';
import { Segmentation } from '../../../core/bean/segmentation';
import { VendorCategory } from '../../../core/bean/vendor-category';
import { VendorType } from '../../../core/bean/vendor-type';

export class RfiPopupVendorDTO {

    vendorTypeList: VendorType[] = [];
    vendorCategoryList: VendorCategory[] = [];
    regionList: Region[] = [];
    segmentationList: Segmentation[] = [];
    ratingList: number[] = [];
    keywordList: string[] = [];
    vendorIdExcludeList: number[] = [];
}
