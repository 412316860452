<app-popup header="Print Vendor History">
  <div class="vendor-history-detail-popup-print">
    <app-table [tableResponse]="tableResponse">
      <ng-template #actionHeader>
        <input
          type="checkbox"
          (click)="doCheckAll($event)"
          [checked]="accordionItemListTemp.length === accordionItemList.length"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
          aria-label="Check All"
        />
      </ng-template>
      <ng-template #actionButtons let-rowData>
        <input
          type="checkbox"
          (click)="doCheck(rowData)"
          [checked]="accordionItemListTemp.indexOf(rowData) !== -1"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
          aria-label="Check"
        />
      </ng-template>
    </app-table>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center button-group-icon-text">
        <app-button
          mode="BYPASS"
          color="SECONDARY"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button mode="BYPASS" (onClick)="doPrint()">
          <em class="fas fa-print"></em>{{ 'app.button.print' | translate }}
        </app-button>
      </div>
    </ng-template>
  </div>
</app-popup>
