import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorRepresentativeAgentHistory } from '../../core/bean/vendor-representative-agent-history';
@Component({
  templateUrl: './app-popup-tab-rep-agent.component.html'
})
export class AppPopupTabRepAgentComponent extends BaseModuleComponent {
    @Input() vendorRepresentativeAgentHistory: VendorRepresentativeAgentHistory;
    public dataSource: string;
    public companyStatus: string;
    constructor(translateService: TranslateService) {
        super('tab-rep-agent', translateService);
    }

    public onInit(): void {
        this.setFormGroup();
        this.buildFormGroup();
        this.setStateReady();
    }

    public buildFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            companyStatus: [null],
            dataSource: [null],
            companyName: [null],
            taxNumber: [null],
            email: [null],
            appPhone: [null],
            address: [null],
            information: [null],
            coordinateLocation: [null],
            id: [null],
            parentOrChild: [null],
            vendorRepresentativeAgentId: [null]
        });
    }


    public setFormGroup(): void {
        if (this.vendorRepresentativeAgentHistory !== undefined) {
            if (this.vendorRepresentativeAgentHistory.id !== undefined) {
              if (this.vendorRepresentativeAgentHistory.vendorChild === null && this.vendorRepresentativeAgentHistory.vendorParent === null) {
                this.dataSource = 'Input Manual';
              } else {
                this.dataSource = 'Vendor Promise';
              }
              this.companyStatus = this.vendorRepresentativeAgentHistory.companyStatus === 1 ? 'Holding' : 'Not Holding';
            } else {
              this.dataSource = '-';
              this.companyStatus = '-';
            }
        }

    }

}
