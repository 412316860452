import { Contract } from 'src/app/core/bean/contract';
import { ContractDraftParameterValue } from 'src/app/core/bean/contract-draft-parameter-value';
import { ContractTermination } from 'src/app/core/bean/contract-termination';
import { FileObject } from 'src/app/core/components/upload';

export class ContractTerminationInsertRequest {
  public contractTermination: ContractTermination;
  public fileObjectList: FileObject[] = [];
  public contract: Contract;
  public isProcessed: boolean;
  public contractDraftParameterValueList: ContractDraftParameterValue[];
}
