import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { BiddingEvaluation } from 'src/app/core/bean/bidding-evaluation';
import { BiddingSubmissionType } from 'src/app/core/bean/bidding-submission-type';
import { CurrencyType } from 'src/app/core/bean/currency-type';
import { DocumentSubmissionType } from 'src/app/core/bean/document-submission-type';
import { InitiationType } from 'src/app/core/bean/initiation-type';
import { NegotiationType } from 'src/app/core/bean/negotiation-type';
import { Pq } from 'src/app/core/bean/pq';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PriceEvaluationType } from 'src/app/core/bean/price-evaluation-type';
import { ProcurementFilter } from 'src/app/core/bean/procurement-filter';
import { ProcurementItem } from 'src/app/core/bean/procurement-item';
import { ProcurementMatrix } from 'src/app/core/bean/procurement-matrix';
import { ProcurementMethod } from 'src/app/core/bean/procurement-method';
import { ProcurementPq } from 'src/app/core/bean/procurement-pq';
import { ProcurementRegion } from 'src/app/core/bean/procurement-region';
import { ProcurementSegQualification } from 'src/app/core/bean/procurement-seg-qualification';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { VendorSelectionType } from 'src/app/core/bean/vendor-selection-type';
import { BinaryTreeModel } from 'src/app/core/components/app-committee/model/binary-tree-model';
import { AppItemRequestInitiationCatalogModel } from 'src/app/core/components/app-item-request/app-item-request-initiation-catalog-model';
import { AppPopupChooseVendorService } from 'src/app/core/components/app-popup/app-popup-choose-vendor/app-popup-choose-vendor.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { TableColumn } from 'src/app/core/components/table/domain/table-column';
import { SelectedTableRecordModel } from 'src/app/core/components/table/model/selected-table-record-model';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { Validators } from 'src/app/core/validators';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { AddressBook } from '../../core/bean/address-book';
import { AddressPic } from '../../core/bean/address-pic';
import { Committee } from '../../core/bean/committee';
import { Currency } from '../../core/bean/currency';
import { DirectPurchaseDelivery } from '../../core/bean/direct-purchase-delivery';
import { DirectPurchaseRealization } from '../../core/bean/direct-purchase-realization';
import { DirectPurchaseShipping } from '../../core/bean/direct-purchase-shipping';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementResult } from '../../core/bean/procurement-result';
import { Vendor } from '../../core/bean/vendor';
import { VendorAddress } from '../../core/bean/vendor-address';
import { VendorPIC } from '../../core/bean/vendor-pic';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { AppOfficialReportModel } from '../../core/components/app-official-report/app-official-report-model';
import { AppPopupAddressService } from '../../core/components/app-popup/app-popup-address/app-popup-address.service';
import { AppTipsComponent } from '../../core/components/app-tips/app-tips.component';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { FileUploader } from '../../core/components/upload';
import { DirectPurchasePopupAddVendorComponent } from './direct-purchase-popup-add-vendor.component';
import { DirectPurchasePopupSelectVendorComponent } from './direct-purchase-popup-select-vendor.component';
import { InitiationPopupCancelationComponent } from './initiation-popup-cancelation.component';
import { InitiationPopupChoosePqComponent } from './initiation-popup-choose-pq.component';
import { InitiationPopupDeliveryProcessComponent } from './initiation-popup-delivery-process.component';
import { InitiationPopupFailedComponent } from './initiation-popup-failed.component';
import { InitiationPopupSegmentationComponent } from './initiation-popup-segmentation.component';
import { InitiationDirectPurchaseVendorModel } from './model/initiation-direct-purchase-vendor.model';
import { InitiationRequest } from './request/initiation.request';
import { ProcurementCancelationRequest } from './request/procurement-cancelation.request';
import { ProcurementFailedRequest } from './request/procurement-failed.request';
import { InitiationAddEditResponse } from './response/initiation-add-edit.response';
import { ProcurementCancelationValidateResponse } from './response/procurement-cancelation-validate.response';

@Component({
  templateUrl: './initiation-edit-add.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InitiationEditAddComponent
  extends BaseModuleComponent
  implements OnInit, AfterViewInit
{
  public procurementId: number;
  public objectId: number; /** for worklist-approval */
  public approvalModelPrcsId: number;
  public initiationTypeCode: string;
  public initiationType: InitiationType;
  public prResponse: AppItemRequestInitiationCatalogModel =
    new AppItemRequestInitiationCatalogModel();
  public prItemList: PrItem[] = [];
  public delPrItemList: PrItem[] = [];

  public procurementMethodOptionList: OptionListModel<ProcurementMethod> =
    new OptionListModel(true);
  public documentSubmissionTypeOptionList: OptionListModel<DocumentSubmissionType> =
    new OptionListModel(true);
  public negotiationTypeOptionList: OptionListModel<NegotiationType> =
    new OptionListModel(true);
  public priceEvaluationTypeOptionList: OptionListModel<PriceEvaluationType> =
    new OptionListModel(true);
  public biddingEvaluationOptionList: OptionListModel<BiddingEvaluation> =
    new OptionListModel(true);
  public biddingSubmissionTypeOptionList: OptionListModel<BiddingSubmissionType> =
    new OptionListModel(true);
  public vendorSelectionTypeOptionList: OptionListModel<VendorSelectionType> =
    new OptionListModel(true);
  public currencyTypeOptionList: OptionListModel<CurrencyType> =
    new OptionListModel(true);
  public directPurchaseShippingOptionList: OptionListModel<DirectPurchaseShipping> =
    new OptionListModel(false, 'address.address');
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true,
    'code'
  );

  public procurementItemList: ProcurementItem[] = [];
  public response: InitiationAddEditResponse = new InitiationAddEditResponse();

  public tableResponsePq: TableResponseModel<Pq>;
  public tableResponseVendor: TableResponseModel<any>;
  public tableResponseSeg: TableResponseModel<ProcurementSegQualification>;
  public binaryTreeModel: BinaryTreeModel = new BinaryTreeModel(true, false);
  public prIdList: number[] = [];
  public updatedPrIdList: Map<number, number> = new Map();
  public amountsModel = {
    amount: null,
    goodsAmount: null,
    serviceAmount: null
  };
  public filterVendor: ProcurementFilter = null;

  public badgeColor = {
    DRAFT: 'SECONDARY',
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    ON_PROGRESS: 'PROGRESS',
    DONE: 'SUCCESS',
    FAILED: 'DANGER',
    FAILED_ASSESSMENT: 'SECONDARY',
    WAITING_CANCEL_APPROVAL: 'WARNING',
    CANCELED: 'DANGER',
    WAITING_FAILED_APPROVAL: 'WARNING',
    SAVE: 'SUCCESS',
    LOSS: 'DANGER'
  };
  public isSubmit: boolean;
  public procurementMatrix: ProcurementMatrix = null;
  public statusForApprovalList = [
    this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_APPROVAL,
    this.global.appConstant.pm.PROCUREMENT_STATUS_REVISION,
    this.global.appConstant.pm.PROCUREMENT_STATUS_DONE,
    this.global.appConstant.pm.PROCUREMENT_STATUS_ON_PROGRESS
  ];
  public procCancelFailedStatusList = [
    this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL,
    this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
    this.global.appConstant.pm.PROCUREMENT_STATUS_CANCELED,
    this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED
  ];
  public minDate: Date;
  public token: string;
  public objectName: string;
  public fileUploaderCancel: FileUploader = new FileUploader(
    '/initiation/',
    'initiation.form.headerImage',
    this.global.appConstant.fileType.DOC_CANCEL_PROCUREMENT,
    false,
    this.global.config.parameterModel.maxFileProcurementCancel
  );
  public isCanceled: boolean;
  public fileUploaderFailed: FileUploader = new FileUploader(
    '/initiation/',
    'initiation.form.headerImage',
    this.global.appConstant.fileType.DOC_FAILED_PROCUREMENT,
    false,
    this.global.config.parameterModel.maxFileProcurementFailed
  );
  public isFailed: boolean;
  public tableResponsePurchaseVendor: TableResponseModel<InitiationDirectPurchaseVendorModel>;
  public tableResponseGoods: TableResponseModel<DirectPurchaseDelivery>;
  public tableResponseService: TableResponseModel<DirectPurchaseDelivery>;
  public tableResponseRealization: TableResponseModel<DirectPurchaseRealization>;
  public prGoodsPrice = 0;
  public prServicePrice = 0;
  public prGrandPrice = 0;
  @ViewChild('appTips') appTips: AppTipsComponent;
  public appOfficialReportModel: AppOfficialReportModel;
  public isUpdated: boolean;
  public isOfficialReportNull: boolean;
  public committee: Committee;
  public urlBackOutside: string;
  public isShowVendorResult = true;

  constructor(
    translateService: TranslateService,
    public appPopupChooseVendorService: AppPopupChooseVendorService,
    public appPopupService: AppPopupService,
    public toastService: ToastService,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute,
    public datePipe: DatePipe,
    public appPopupAddressService: AppPopupAddressService,
    private cdref: ChangeDetectorRef
  ) {
    super('initiation', translateService);
  }

  ngAfterViewInit(): void {
    this.log.debug('ng after view init:');
    this.log.debug(this.formGroup);
    this.cdref.detectChanges();
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Procurement>('/initiation/notification/' + this.token)
      .subscribe((procurement: Procurement) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('procurementId', procurement.id);
        this.global.routerParams.set('urlBackOutside', '/pages/initiation');
        this.global.routerParams.set('initiationType', this.initiationTypeCode);
        if (this.initiationTypeCode === 'BLANKET_ORDER') {
          const prResponseTemp = this.global.routerParams.get('prResponse');
          if (prResponseTemp !== undefined) {
            this.prResponse = this.global.routerParams.get('prResponse');
          }
          this.prItemList = null;
          this.delPrItemList = null;
        } else {
          this.prResponse = null;
          this.prItemList = null;
          this.delPrItemList = null;
        }
        if (
          ![
            this.global.appConstant.pm.PROCUREMENT_STATUS_DRAFT,
            this.global.appConstant.pm.PROCUREMENT_STATUS_REVISION
          ].includes(procurement.procurementStatus.code)
        ) {
          this.global.routerParams.set('todo', 'view');
          this.router.navigate(['/pages/initiation/detail']);
        } else {
          this.global.routerParams.set('todo', 'edit');
          this.router.navigate(['/pages/initiation/edit']);
        }
      });
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.buildTableResponsePq();
    this.buildTableResponseVendor();
    this.buildTableResponsePurchaseVendor();
    this.buildTableResponseSeg();
    this.buildTableResponseGoods();
    this.buildTableResponseService();
    this.buildTableRealization();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setInitiationValue(): void {
    if (this.prResponse !== null) {
      const initiationRequestTemp: InitiationRequest =
        this.getInitiationRequest();
      this.prResponse.initiationRequest = initiationRequestTemp;
      this.global.routerParams.set('prResponse', this.prResponse);
    }
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.initiationTypeCode = this.global.routerParams.get('initiationType');
    if (this.initiationTypeCode === 'BLANKET_ORDER') {
      const prResponseTemp = this.global.routerParams.get('prResponse');
      if (prResponseTemp) {
        this.prResponse = this.global.routerParams.get('prResponse');
      }
      this.prItemList = null;
      this.delPrItemList = null;
    } else {
      this.prResponse = null;
      this.prItemList = null;
      this.delPrItemList = null;
    }
    this.objectId = this.global.routerParams.get('objectId');
    this.objectName = this.global.routerParams.get('objectName');
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');

    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public buildTableResponsePq(): void {
    this.tableResponsePq = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.procurementRequirement'
      } // should by tkey/translate service
    ]);
  }

  public buildTableResponseVendor(): void {
    this.tableResponseVendor = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.vendorName',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'icon',
            className: 'pic pi-exclamation-triangle',
            onClick: this.onClickVendor.bind(this),
            isShowIconFn: this.showIcon.bind(this)
          }
        ]
      },
      {
        field: 'address',
        header: 'table.column.address'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      },
      {
        field: 'userRefer',
        header: 'table.column.userRefer',
        dataMap:
          '{"true":"' +
          this.global.translateService.instant(
            'initiation.detail.tableVendor.userRefer.yes'
          ) +
          '","false":"-"}'
      },
      {
        field: 'averageRatingOverall',
        header: 'table.column.performance',
        plugins: {
          name: 'rating',
          isView: true
        },
        className: 'text-center'
      }
    ]);
  }

  public buildTableResponseSeg(): void {
    this.tableResponseSeg = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementSeg.segmentationName',
        header: 'table.column.segmentation'
      },
      {
        field: 'termQualification.name',
        header: 'table.column.termQualification'
      }, // translate
      {
        field: 'qualification.name',
        header: 'table.column.qualification'
      }
    ]);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      name: [null, Validators.required()],
      procurementDate: [null, Validators.required()],
      description: [null, Validators.required()],
      procurementMethod: [null, Validators.required()],
      procurementType: [null, Validators.required()],
      procurementStatus: [null],
      fromProcurement: [null],

      announcementNumber: [null],
      documentSubmissionType: [null],
      negotiationType: [null],
      priceEvaluationType: [null],
      biddingEvaluation: [null],
      biddingSubmissionType: [null],
      vendorSelectionType: [null],
      currencyType: [null],
      isPq: [null],

      documentList: [null, Validators.required()],
      cancelDocList: [null],
      failedDocList: [null],

      directPurchaseShippingList: [null],
      realizationRecordList: this.tableResponseRealization.formArray,
      goodsAmountReal: [null],
      serviceAmountReal: [null],
      amountReal: [null],
      goodsProfitLoss: [null],
      serviceProfitLoss: [null],
      amountProfitLoss: [null],

      workflowModelPrcs: [null],
      workflowPrcs: [null],
      prcsCode: [null],
      userRequester: [null]
    });
  }

  public setFormGroup(): void {
    const url =
      '/initiation/add-edit?id=' +
      (this.procurementId ? this.procurementId : '') +
      '&todo=' +
      this.todo +
      (this.objectName ? '&objectName=' + this.objectName : '') +
      '&initiationTypeCode=' +
      this.initiationTypeCode;

    this.httpClientService
      .get<InitiationAddEditResponse>(url, {})
      .subscribe((response: InitiationAddEditResponse) => {
        this.response = response;

        this.procurementMethodOptionList.setRequestValues(
          response.procurementMethodList
        );
        this.documentSubmissionTypeOptionList.setRequestValues(
          response.documentSubmissionTypeList
        );
        this.negotiationTypeOptionList.setRequestValues(
          response.negotiationTypeList
        );
        this.priceEvaluationTypeOptionList.setRequestValues(
          response.priceEvaluationTypeList
        );
        this.biddingEvaluationOptionList.setRequestValues(
          response.biddingEvaluationList
        );
        this.biddingSubmissionTypeOptionList.setRequestValues(
          response.biddingSubmissionTypeList
        );
        this.vendorSelectionTypeOptionList.setRequestValues(
          response.vendorSelectionTypeList
        );
        this.currencyTypeOptionList.setRequestValues(response.currencyTypeList);
        this.currencyOptionList.setRequestValues(response.currencyList);

        if (this.prResponse !== null) {
          this.prResponse.currencyList = response.currencyList;
          this.prResponse.companyCurrency = response.companyCurrency;
          this.prResponse.catalogType = response.catalogType;

          if (this.todo !== 'add') {
            const prItemListTemp: PrItem[] = [];
            if (
              this.prResponse?.prItemList &&
              this.prResponse?.prItemList?.length < 1
            ) {
              response.procurementItemList?.forEach(procurementItem => {
                prItemListTemp.push(procurementItem.prItem);
              });
              if (prItemListTemp) {
                this.prResponse.prItemList = prItemListTemp;
              }
            }
          }
        }

        this.initiationType =
          response.procurement !== null
            ? response.procurement.initiationType
            : response.initiationType;
        if (this.initiationType) {
          this.global.routerParams.set(
            'initiationTypeObject',
            this.initiationType
          );
        } else {
          this.global.routerParams.get('initiationTypeObject');
        }

        if (this.todo !== 'add' && !this.prResponse?.initiationRequest) {
          this.isUpdated = true;
          const {
            id,
            code,
            name,
            procurementDate,
            description,
            procurementMethod,
            procurementType,
            procurementStatus,
            announcementNumber,
            documentSubmissionType,
            negotiationType,
            priceEvaluationType,
            biddingEvaluation,
            biddingSubmissionType,
            vendorSelectionType,
            currencyType,
            isPq,
            fromProcurement,
            workflowModelPrcs,
            workflowPrcs,
            prcsCode,
            userRequester
          } = response.procurement;

          this.formGroup.patchValue({
            id,
            code,
            name,
            procurementDate: new Date(procurementDate),
            description,
            procurementMethod,
            procurementType: procurementType ? [procurementType] : null,
            procurementStatus,
            announcementNumber,
            documentSubmissionType,
            negotiationType,
            priceEvaluationType,
            biddingEvaluation,
            biddingSubmissionType,
            vendorSelectionType,
            currencyType,
            isPq,
            fromProcurement,
            documentList: this.response.appUploadDocumentTableModelList,
            workflowModelPrcs,
            workflowPrcs,
            prcsCode,
            userRequester
          });

          this.committee = this.response.procurement.committee;

          this.binaryTreeModel.setTreeList(response.committeeParticipantList);
          this.binaryTreeModel.setRecursiveList();
          this.procurementItemList = [];
          this.procurementItemList.push(...response.procurementItemList);
          this.prIdList = this.procurementItemList.map(
            procurementItem => procurementItem.prItem.pr?.id
          );

          this.tableResponsePq.setRecordList(
            response.procurementPqList.map(procPq => procPq.pq)
          );
          this.tableResponsePq.reload();

          this.tableResponseSeg.setRecordList(
            response.procurementSegQualificationList
          );
          this.tableResponseSeg.reload();

          // set isShowVendorResult
          if (procurementMethod) {
            const procurementMatrix = this.response.procurementMatrixList.find(
              pm => pm.procurementMethod.id === procurementMethod.id
            );
            this.isShowVendorResult = procurementMatrix?.isShowVendorResult; // default true
          } else {
            this.isShowVendorResult = true;
          }

          if (this.isShowVendorResult) {
            this.tableResponseVendor.setRecordList(
              response.popupVendorViewList
            );
            this.tableResponseVendor.reload();
          }
          /** cancelation */
          if (this.response.procurementCancelationDocList) {
            this.fileUploaderCancel.setFileList(
              this.response.procurementCancelationDocList.map(doc => doc.file)
            );
            this.formGroup
              .get('cancelDocList')
              .patchValue(this.fileUploaderCancel.fileObjectList);
          }

          if (
            this.response.procurement?.procurementStatus.code ===
            this.global.appConstant.pm.PROCUREMENT_STATUS_CANCELED
          ) {
            this.isCanceled = true;
          }

          /** failed */
          if (this.response.procurementFailedDocList) {
            this.fileUploaderFailed.setFileList(
              this.response.procurementFailedDocList.map(doc => doc.file)
            );
            this.formGroup
              .get('failedDocList')
              .patchValue(this.fileUploaderFailed.fileObjectList);
          }

          if (
            this.response.procurement?.procurementStatus.code ===
            this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED
          ) {
            this.isFailed = true;
          } else {
            this.isFailed = false;
          }

          if (this.isDirectPurchase()) {
            const vendorModel = new InitiationDirectPurchaseVendorModel();
            vendorModel.directPurchasePayment =
              this.response.directPurchasePayment;

            if (this.response.procurementVendor) {
              vendorModel.procurementVendor = this.response.procurementVendor;
              vendorModel.procurementVendor.vendor['address'] =
                this.response.vendorView.address;
              vendorModel.procurementVendor.vendor['picName'] =
                this.response.vendorPIC?.name;

              vendorModel.vendorBankList = this.response.vendorBank
                ? [this.response.vendorBank]
                : [];
              vendorModel.directPurchasePaymentDocList =
                this.response.directPurchasePaymentDocList;

              this.tableResponsePurchaseVendor.setRecordList([vendorModel]);
              this.tableResponsePurchaseVendor.reload();
            }

            this.directPurchaseShippingOptionList.setRequestValues(
              this.response.directPurchaseShippingList
            );

            if (this.response.directPurchaseDeliveryList) {
              this.tableResponseGoods.setRecordList(
                this.response.directPurchaseDeliveryList.filter(
                  delivery =>
                    delivery.procurementItem.prItem.item.itemType.code ===
                    this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                )
              );
              this.tableResponseGoods.reload();

              this.tableResponseService.setRecordList(
                this.response.directPurchaseDeliveryList.filter(
                  delivery =>
                    delivery.procurementItem.prItem.item.itemType.code ===
                    this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
                )
              );
              this.tableResponseService.reload();
            } else {
              /** tetap di-set agar jika buyer ubah is received jadi true maka lgsg muncul item direct purchase delivery nya */
              /** direct purchase delivery */
              const directPurchaseDeliveryList: DirectPurchaseDelivery[] = [];
              this.procurementItemList.forEach(procurementItem => {
                const directPurchaseDelivery = new DirectPurchaseDelivery();
                directPurchaseDelivery.procurementItem = procurementItem;
                directPurchaseDelivery.directPurchaseDeliveryStatus =
                  this.response.directPurchaseDeliveryStatusList.find(
                    status =>
                      status.code ===
                      this.global.appConstant.pm
                        .DIRECT_PURCHASE_DELIVERY_STATUS_WAITING_REVIEW
                  );
                directPurchaseDelivery.percentage = 100;
                directPurchaseDelivery.receivedQuantity =
                  procurementItem.prItem.quantity;
                directPurchaseDelivery.remainingQuantity = 0;
                directPurchaseDeliveryList.push(directPurchaseDelivery);
              });

              this.tableResponseGoods.setRecordList(
                directPurchaseDeliveryList.filter(
                  data =>
                    data.procurementItem.prItem.item.itemType.code ===
                    this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                )
              );

              this.tableResponseService.setRecordList(
                directPurchaseDeliveryList.filter(
                  data =>
                    data.procurementItem.prItem.item.itemType.code ===
                    this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
                )
              );
              this.tableResponseGoods.reload();
              this.tableResponseService.reload();
            }

            const goodsRealizationList =
              this.response.directPurchaseRealizationList.filter(
                realization =>
                  realization.procurementItem.prItem.item.itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
              );

            const serviceRealizationList =
              this.response.directPurchaseRealizationList.filter(
                realization =>
                  realization.procurementItem.prItem.item.itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
              );

            const goodsAmountReal = goodsRealizationList
              .map(realization => realization.totalPrice)
              .reduce((a, b) => a + b, 0);
            const serviceAmountReal = serviceRealizationList
              .map(realization => realization.totalPrice)
              .reduce((a, b) => a + b, 0);
            const amountReal = goodsAmountReal + serviceAmountReal;

            this.procurementItemList.forEach(procurementItem => {
              if (
                procurementItem.prItem.item.itemType.code ===
                this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
              ) {
                this.prGoodsPrice =
                  +this.prGoodsPrice + +procurementItem.prItem.totalPrice;
              } else if (
                procurementItem.prItem.item.itemType.code ===
                this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
              ) {
                this.prServicePrice =
                  +this.prServicePrice + +procurementItem.prItem.totalPrice;
              }
            });
            this.prGrandPrice = this.prGoodsPrice + this.prServicePrice;

            this.formGroup.patchValue({
              goodsAmountReal,
              serviceAmountReal,
              amountReal,
              goodsProfitLoss: goodsAmountReal
                ? 100 - (goodsAmountReal / this.prGoodsPrice) * 100
                : 0,
              serviceProfitLoss: serviceAmountReal
                ? 100 - (serviceAmountReal / this.prServicePrice) * 100
                : 0,
              amountProfitLoss:
                +goodsAmountReal + +serviceAmountReal
                  ? 100 -
                    ((+goodsAmountReal + +serviceAmountReal) /
                      this.prGrandPrice) *
                      100
                  : 0
            });

            this.response.directPurchaseRealizationList.forEach(
              (realization: any) => {
                realization.price = {
                  price: realization.price,
                  currency: this.response.currencyList[0]
                };

                realization.totalPrice = {
                  price: realization.totalPrice,
                  currency: this.response.currencyList[0]
                };
              }
            );
            this.tableResponseRealization.setRecordList(
              this.response.directPurchaseRealizationList
            );

            this.tableResponseRealization.reload();
            this.setInitiationValue();
            // this.log.debug(this.tableResponseRealization);
          } /** end Direct Purchase */
        } else if (this.prResponse?.initiationRequest) {
          this.isUpdated = true;
          const {
            id,
            code,
            name,
            procurementDate,
            description,
            procurementMethod,
            procurementType,
            procurementStatus,
            announcementNumber,
            documentSubmissionType,
            negotiationType,
            priceEvaluationType,
            biddingEvaluation,
            biddingSubmissionType,
            vendorSelectionType,
            currencyType,
            isPq,
            fromProcurement,
            workflowModelPrcs,
            workflowPrcs,
            prcsCode,
            userRequester
          } = this.prResponse?.initiationRequest?.procurement;

          this.formGroup.patchValue({
            id,
            code,
            name,
            procurementDate: new Date(procurementDate),
            description,
            procurementMethod,
            procurementType: procurementType ? [procurementType] : null,
            procurementStatus,
            announcementNumber,
            documentSubmissionType,
            negotiationType,
            priceEvaluationType,
            biddingEvaluation,
            biddingSubmissionType,
            vendorSelectionType,
            currencyType,
            isPq,
            fromProcurement,
            documentList: this.response.appUploadDocumentTableModelList,
            workflowModelPrcs,
            workflowPrcs,
            prcsCode,
            userRequester
          });

          this.committee =
            this.prResponse?.initiationRequest?.procurement?.committee;

          this.binaryTreeModel.setTreeList(response?.committeeParticipantList);
          this.binaryTreeModel.setRecursiveList();
          this.procurementItemList = [];
          this.procurementItemList.push(
            ...this.prResponse?.initiationRequest?.procurementItemList
          );
          this.prIdList = this.procurementItemList.map(
            procurementItem => procurementItem.prItem.pr?.id
          );

          this.tableResponsePq.setRecordList(
            this.prResponse?.initiationRequest?.procurementPqList.map(
              procPq => procPq.pq
            )
          );
          this.tableResponsePq.reload();

          this.tableResponseSeg.setRecordList(
            this.prResponse?.initiationRequest?.procurementSegQualificationList
          );
          this.tableResponseSeg.reload();

          this.tableResponseVendor.setRecordList(response.popupVendorViewList);
          this.tableResponseVendor.reload();

          /** cancelation */
          if (this.response.procurementCancelationDocList) {
            this.fileUploaderCancel.setFileList(
              this.response.procurementCancelationDocList.map(doc => doc.file)
            );
            this.formGroup
              .get('cancelDocList')
              .patchValue(this.fileUploaderCancel.fileObjectList);
          }

          if (
            this.response.procurement?.procurementStatus.code ===
            this.global.appConstant.pm.PROCUREMENT_STATUS_CANCELED
          ) {
            this.isCanceled = true;
          }

          /** failed */
          if (this.response.procurementFailedDocList) {
            this.fileUploaderFailed.setFileList(
              this.response.procurementFailedDocList.map(doc => doc.file)
            );
            this.formGroup
              .get('failedDocList')
              .patchValue(this.fileUploaderFailed.fileObjectList);
          }

          if (
            this.response.procurement?.procurementStatus.code ===
            this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED
          ) {
            this.isFailed = true;
          } else {
            this.isFailed = false;
          }

          if (this.isDirectPurchase()) {
            const vendorModel = new InitiationDirectPurchaseVendorModel();
            vendorModel.directPurchasePayment =
              this.prResponse?.initiationRequest?.directPurchasePayment;

            if (this.prResponse?.initiationRequest?.procurementVendor) {
              vendorModel.procurementVendor =
                this.prResponse?.initiationRequest?.procurementVendor;
              vendorModel.procurementVendor.vendor['address'] =
                this.response.vendorView.address;
              vendorModel.procurementVendor.vendor['picName'] =
                this.prResponse?.initiationRequest?.vendorPIC?.name;

              vendorModel.vendorBankList = this.prResponse?.initiationRequest
                ?.vendorBank
                ? [this.prResponse?.initiationRequest?.vendorBank]
                : [];
              vendorModel.directPurchasePaymentDocList =
                this.response.directPurchasePaymentDocList;

              this.tableResponsePurchaseVendor.setRecordList([vendorModel]);
              this.tableResponsePurchaseVendor.reload();
            }

            this.directPurchaseShippingOptionList.setRequestValues(
              this.prResponse?.initiationRequest?.directPurchaseShippingList
            );

            if (
              this.prResponse?.initiationRequest?.directPurchaseDeliveryList
            ) {
              this.tableResponseGoods.setRecordList(
                this.prResponse?.initiationRequest?.directPurchaseDeliveryList.filter(
                  delivery =>
                    delivery.procurementItem.prItem.item.itemType.code ===
                    this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                )
              );
              this.tableResponseGoods.reload();

              this.tableResponseService.setRecordList(
                this.prResponse?.initiationRequest?.directPurchaseDeliveryList.filter(
                  delivery =>
                    delivery.procurementItem.prItem.item.itemType.code ===
                    this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
                )
              );
              this.tableResponseService.reload();
            } else {
              /** tetap di-set agar jika buyer ubah is received jadi true maka lgsg muncul item direct purchase delivery nya */
              /** direct purchase delivery */
              const directPurchaseDeliveryList: DirectPurchaseDelivery[] = [];
              this.procurementItemList.forEach(procurementItem => {
                const directPurchaseDelivery = new DirectPurchaseDelivery();
                directPurchaseDelivery.procurementItem = procurementItem;
                directPurchaseDelivery.directPurchaseDeliveryStatus =
                  this.response.directPurchaseDeliveryStatusList.find(
                    status =>
                      status.code ===
                      this.global.appConstant.pm
                        .DIRECT_PURCHASE_DELIVERY_STATUS_WAITING_REVIEW
                  );
                directPurchaseDelivery.percentage = 100;
                directPurchaseDelivery.receivedQuantity =
                  procurementItem.prItem.quantity;
                directPurchaseDelivery.remainingQuantity = 0;
                directPurchaseDeliveryList.push(directPurchaseDelivery);
              });

              this.tableResponseGoods.setRecordList(
                directPurchaseDeliveryList.filter(
                  data =>
                    data.procurementItem.prItem.item.itemType.code ===
                    this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                )
              );

              this.tableResponseService.setRecordList(
                directPurchaseDeliveryList.filter(
                  data =>
                    data.procurementItem.prItem.item.itemType.code ===
                    this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
                )
              );
              this.tableResponseGoods.reload();
              this.tableResponseService.reload();
            }

            const goodsRealizationList =
              this.prResponse?.initiationRequest?.directPurchaseRealizationList.filter(
                realization =>
                  realization.procurementItem.prItem.item.itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
              );

            const serviceRealizationList =
              this.prResponse?.initiationRequest?.directPurchaseRealizationList.filter(
                realization =>
                  realization.procurementItem.prItem.item.itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
              );

            const goodsAmountReal = goodsRealizationList
              .map(realization => realization.totalPrice)
              .reduce((a, b) => a + b, 0);
            const serviceAmountReal = serviceRealizationList
              .map(realization => realization.totalPrice)
              .reduce((a, b) => a + b, 0);
            const amountReal = goodsAmountReal + serviceAmountReal;

            this.procurementItemList.forEach(procurementItem => {
              if (
                procurementItem.prItem.item.itemType.code ===
                this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
              ) {
                this.prGoodsPrice =
                  +this.prGoodsPrice + +procurementItem.prItem.totalPrice;
              } else if (
                procurementItem.prItem.item.itemType.code ===
                this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
              ) {
                this.prServicePrice =
                  +this.prServicePrice + +procurementItem.prItem.totalPrice;
              }
            });
            this.prGrandPrice = this.prGoodsPrice + this.prServicePrice;

            this.formGroup.patchValue({
              goodsAmountReal,
              serviceAmountReal,
              amountReal,
              goodsProfitLoss: goodsAmountReal
                ? 100 - (goodsAmountReal / this.prGoodsPrice) * 100
                : 0,
              serviceProfitLoss: serviceAmountReal
                ? 100 - (serviceAmountReal / this.prServicePrice) * 100
                : 0,
              amountProfitLoss:
                +goodsAmountReal + +serviceAmountReal
                  ? 100 -
                    ((+goodsAmountReal + +serviceAmountReal) /
                      this.prGrandPrice) *
                      100
                  : 0
            });

            this.prResponse?.initiationRequest?.directPurchaseRealizationList.forEach(
              (realization: any) => {
                realization.price = {
                  price: realization.price,
                  currency: this.response.currencyList[0]
                };

                realization.totalPrice = {
                  price: realization.totalPrice,
                  currency: this.response.currencyList[0]
                };
              }
            );
            this.tableResponseRealization.setRecordList(
              this.prResponse?.initiationRequest?.directPurchaseRealizationList
            );

            this.tableResponseRealization.reload();
          }
        }
        this.appOfficialReportModel = this.response.appOfficialReportModel;
        this.appOfficialReportModel.isGenerate = this.isUpdated;
        this.isOfficialReportNull =
          !this.appOfficialReportModel?.fileObjectList?.find(
            fileObject => !fileObject?.isDeleted
          );

        if (
          this.response.procurement &&
          this.response.procurement.procurementDate
        ) {
          this.minDate = new Date(this.response.procurement.procurementDate);
        } else {
          this.minDate = new Date();
        }
        this.formGroup.get('procurementDate').updateValueAndValidity();

        this.setStateReady();

        if (
          this.response.procurement &&
          ![
            this.global.appConstant.pm.PROCUREMENT_STATUS_DRAFT,
            this.global.appConstant.pm.PROCUREMENT_STATUS_REVISION
          ].includes(this.response.procurement.procurementStatus.code)
        ) {
          this.setViewOnly();
          this.todo = 'view'; /** for app doc table */
          this.binaryTreeModel.setViewOnly();
        }

        if (this.response.procurementCancelationDocList) {
          this.formGroup.get('cancelDocList').setIsView(true);
        }

        if (this.response.procurement?.isRepeat && this.todo === 'edit') {
          /** re-held */
          const formControlList = [
            'code',
            'name',
            'description',
            'procurementMethod',
            'procurementType',
            'announcementNumber',
            'documentSubmissionType',
            'negotiationType',
            'priceEvaluationType',
            'biddingEvaluation',
            'biddingSubmissionType',
            'vendorSelectionType',
            'currencyType',
            'isPq'
          ];

          formControlList.forEach(formControl => {
            this.formGroup.get(formControl).setIsView(true);
          });

          // this.log.debug(this.formGroup);
        }

        if (!this.formGroup.isView || this.todo !== 'view') {
          this.setAccessBuyer();
        }

        this.log.debug('cek amount form group');
        this.log.debug(this.formGroup);
      });
  }

  public findThresholdRangeIdByCondition(conditionCode: string): number {
    return this.response.thresholdRangeList.find(
      thRange =>
        thRange.threshold.condition.code === conditionCode &&
        ((thRange.from <= this.amountsModel.amount &&
          this.amountsModel.amount <= thRange.to) ||
          (thRange.from <= this.amountsModel.amount &&
            thRange.isInfinite === true))
    )?.id;
  }

  public onChangeItemRequest(event): void {
    this.formGroup.markAsDirty();
    // this.log.debug(event);
    // this.log.debug(this.procurementItemList);
    this.procurementItemList = event.procurementItemList;
    this.amountsModel = event.amountValue;
    const thresholdRangeId = this.response.thresholdRangeList.find(
      thRange =>
        (thRange.from <= this.amountsModel.amount &&
          this.amountsModel.amount <= thRange.to) ||
        (thRange.from <= this.amountsModel.amount &&
          thRange.isInfinite === true)
    )?.id;

    this.procurementMatrix = this.response.procurementMatrixList.find(
      matrix => matrix.thresholdRange.id === thresholdRangeId
    ); // thresholdRange.threshold.condition

    if (this.procurementMatrix && !this.response.procurement?.isRepeat) {
      /** recommendation */
      const {
        biddingSubmissionType,
        vendorSelectionType,
        currencyType,
        procurementMethod,
        priceEvaluationType,
        negotiationType,
        isPq,
        documentSubmissionType,
        biddingEvaluation,
        isShowVendorResult
      } = this.procurementMatrix;

      this.isShowVendorResult = isShowVendorResult;

      this.formGroup.patchValue({
        biddingSubmissionType,
        vendorSelectionType,
        currencyType,
        procurementMethod,
        priceEvaluationType,
        negotiationType,
        isPq,
        documentSubmissionType,
        biddingEvaluation
      });
    }

    const currentprIdList = (
      event.procurementItemList as Array<ProcurementItem>
    ).map(procItem => procItem.prItem.pr?.id);
    const deletedPrIdList = this.prIdList.filter(
      prId => !currentprIdList.includes(prId)
    );
    const newPrIdList = currentprIdList.filter(
      prId => !this.prIdList.includes(prId)
    );

    this.prIdList = currentprIdList;

    if (
      (deletedPrIdList && deletedPrIdList.length > 0) ||
      (newPrIdList && newPrIdList.length > 0)
    ) {
      this.updatedPrIdList = new Map();
      deletedPrIdList.forEach(prId => {
        this.updatedPrIdList.set(
          prId,
          this.global.appConstant.core.CRUD_OPERATION_DELETE
        );
      });

      newPrIdList.forEach(prId => {
        this.updatedPrIdList.set(
          prId,
          this.global.appConstant.core.CRUD_OPERATION_INSERT
        );
      });
    }

    /** direct purchase delivery */
    const directPurchaseDeliveryList: DirectPurchaseDelivery[] = [];
    this.procurementItemList.forEach(procurementItem => {
      const directPurchaseDelivery = new DirectPurchaseDelivery();
      directPurchaseDelivery.procurementItem = procurementItem;
      directPurchaseDelivery.directPurchaseDeliveryStatus =
        this.response.directPurchaseDeliveryStatusList.find(
          status =>
            status.code ===
            this.global.appConstant.pm
              .DIRECT_PURCHASE_DELIVERY_STATUS_WAITING_REVIEW
        );
      directPurchaseDelivery.percentage = 100;
      directPurchaseDelivery.receivedQuantity = procurementItem.prItem.quantity;
      directPurchaseDelivery.remainingQuantity = 0;
      directPurchaseDeliveryList.push(directPurchaseDelivery);
    });

    this.tableResponseGoods.setRecordList(
      directPurchaseDeliveryList.filter(
        data =>
          data.procurementItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      )
    );

    this.tableResponseService.setRecordList(
      directPurchaseDeliveryList.filter(
        data =>
          data.procurementItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
      )
    );
    this.tableResponseGoods.reload();
    this.tableResponseService.reload();
    this.appTips?.reload();

    /** direct purchase realization */
    const directPurchaseRealizationList: DirectPurchaseRealization[] = [];
    this.procurementItemList.forEach(procurementItem => {
      const directPurchaseRealization = new DirectPurchaseRealization();
      directPurchaseRealization.procurementItem = procurementItem;
      directPurchaseRealizationList.push(directPurchaseRealization);

      if (
        procurementItem.prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      ) {
        this.prGoodsPrice =
          +this.prGoodsPrice + +procurementItem.prItem.totalPrice;
      } else if (
        procurementItem.prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
      ) {
        this.prServicePrice =
          +this.prServicePrice + +procurementItem.prItem.totalPrice;
      }
    });
    this.prGrandPrice = this.prGoodsPrice + this.prServicePrice;
    this.tableResponseRealization.setRecordList(directPurchaseRealizationList);
    this.tableResponseRealization.reload();
    this.setInitiationValue();
  }

  public onChangeItemRequestBlanketOrder(event): void {
    this.formGroup.markAsDirty();
    // this.log.debug(event);
    // this.log.debug(this.procurementItemList);
    this.procurementItemList = event.procurementItemList;
    this.amountsModel = event.amountValue;

    /** direct purchase delivery */
    const directPurchaseDeliveryList: DirectPurchaseDelivery[] = [];
    this.procurementItemList.forEach(procurementItem => {
      const directPurchaseDelivery = new DirectPurchaseDelivery();
      directPurchaseDelivery.procurementItem = procurementItem;
      directPurchaseDelivery.directPurchaseDeliveryStatus =
        this.response.directPurchaseDeliveryStatusList.find(
          status =>
            status.code ===
            this.global.appConstant.pm
              .DIRECT_PURCHASE_DELIVERY_STATUS_WAITING_REVIEW
        );
      directPurchaseDelivery.percentage = 100;
      directPurchaseDelivery.receivedQuantity = procurementItem.prItem.quantity;
      directPurchaseDelivery.remainingQuantity = 0;
      directPurchaseDeliveryList.push(directPurchaseDelivery);
    });

    this.tableResponseGoods.setRecordList(
      directPurchaseDeliveryList.filter(
        data =>
          data.procurementItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      )
    );

    this.tableResponseService.setRecordList(
      directPurchaseDeliveryList.filter(
        data =>
          data.procurementItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
      )
    );
    this.tableResponseGoods.reload();
    this.tableResponseService.reload();
    this.appTips?.reload();

    /** direct purchase realization */
    const directPurchaseRealizationList: DirectPurchaseRealization[] = [];
    this.procurementItemList.forEach(procurementItem => {
      const directPurchaseRealization = new DirectPurchaseRealization();
      directPurchaseRealization.procurementItem = procurementItem;
      directPurchaseRealizationList.push(directPurchaseRealization);

      if (
        procurementItem.prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      ) {
        this.prGoodsPrice =
          +this.prGoodsPrice + +procurementItem.prItem.totalPrice;
      } else if (
        procurementItem.prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
      ) {
        this.prServicePrice =
          +this.prServicePrice + +procurementItem.prItem.totalPrice;
      }
    });
    this.prGrandPrice = this.prGoodsPrice + this.prServicePrice;
    this.tableResponseRealization.setRecordList(directPurchaseRealizationList);
    this.tableResponseRealization.reload();
    this.setInitiationValue();
  }

  public doChoosePq(): void {
    const allPqList = this.response.pqList;
    this.appPopupService
      .open(InitiationPopupChoosePqComponent, { allPqList })
      .subscribe(data => {
        this.tableResponsePq.setRecordList(data.pqList);
        this.tableResponsePq.reload();
      });
    this.setInitiationValue();
  }

  public onChangeProcMethod(procMethod: ProcurementMethod): void {
    // this.log.debug(procMethod);
    this.appTips?.reload();

    this.procurementMatrix = procMethod
      ? this.response.procurementMatrixList.find(
          pm => pm.procurementMethod.id === procMethod.id
        ) || new ProcurementMatrix()
      : new ProcurementMatrix();

    const {
      isPq,
      biddingEvaluation,
      biddingSubmissionType,
      currencyType,
      documentSubmissionType,
      negotiationType,
      priceEvaluationType,
      thresholdRange,
      vendorSelectionType,
      isShowVendorResult
    } = this.procurementMatrix;

    this.isShowVendorResult = isShowVendorResult;

    const procurementMethod =
      this.procurementMatrix.procurementMethod || procMethod;

    this.formGroup.patchValue({
      isPq,
      biddingEvaluation,
      biddingSubmissionType,
      currencyType,
      documentSubmissionType,
      negotiationType,
      priceEvaluationType,
      procurementMethod,
      thresholdRange,
      vendorSelectionType
    });
    this.setInitiationValue();
  }

  public showIcon(vendor: PopupVendorView): boolean {
    if (vendor.blacklistRangeYear || vendor.sameCompanyOrgVendorName) {
      return true;
    } else {
      return false;
    }
  }

  public isWarningAlert(): boolean {
    return this.tableResponseVendor
      .getRecordList()
      .find(v => v.blacklistRangeYear || v.sameCompanyOrgVendorName)
      ? true
      : false;
  }

  public onClickVendor(vendor: PopupVendorView): void {
    // this.log.debug(vendor);
    if (vendor.blacklistRangeYear || vendor.sameCompanyOrgVendorName) {
      let msg = '<ol class="text-left ml-4">';

      const tkeyBlacklist: string = this.global.translateService.instant(
        'initiation.popup.warning.msg.blacklist'
      );
      const tkeyCommissioner: string = this.global.translateService.instant(
        'initiation.popup.warning.msg.commissioner'
      );
      if (vendor.blacklistRangeYear) {
        msg +=
          '<li>' +
          vendor.name +
          ' ' +
          tkeyBlacklist +
          ' ' +
          vendor.blacklistRangeYear +
          '</li>';
      }

      if (vendor.sameCompanyOrgVendorName) {
        let vendorNameList: string[] =
          vendor.sameCompanyOrgVendorName.split(',');
        vendorNameList = [...new Set(vendorNameList)];

        msg +=
          '<li>' +
          vendor.name +
          ' ' +
          tkeyCommissioner +
          ' ' +
          vendorNameList.toString().replace('[', '').replace(']', '') +
          '</li>';
      }
      msg += '</ol>';

      this.global.modalService.confirmation(
        msg,
        'initiation.modal.vendorWarning.title',
        null,
        null,
        'pvc pv-exclamation-triangle',
        true
      );
    }
    this.setInitiationValue();
  }

  public doAddSeg(): void {
    const termQualificationList = this.response.termQualificationList;
    const qualificationList = this.response.qualificationList;
    const procSegQualificationList = this.tableResponseSeg.getRecordList();

    this.appPopupService
      .open(InitiationPopupSegmentationComponent, {
        termQualificationList,
        qualificationList,
        procSegQualificationList
      })
      .subscribe(
        (procurementSegQualificationList: ProcurementSegQualification[]) => {
          // this.log.debug(procurementSegQualificationList);

          const recList = this.tableResponseSeg.getRecordList();
          recList.push(...procurementSegQualificationList);
          this.tableResponseSeg.setRecordList(recList);
          this.tableResponseSeg.reload();

          this.tableResponseVendor.setRecordList([]); // reset vendor because of new filter seg
          this.tableResponseVendor.reload();
          this.formGroup.markAsDirty();
          this.setInitiationValue();
        }
      );
  }

  public doAddVendor(): void {
    const vendorList: PopupVendorView[] =
      this.tableResponseVendor.getRecordList();
    const prIdList: number[] = this.procurementItemList.map(
      procItem => procItem?.prItem?.pr?.id
    );

    const procurementSegQualificationList: ProcurementSegQualification[] =
      this.tableResponseSeg.getRecordList();
    // this.log.debug(procurementSegQualificationList);

    const procurementFilter =
      this.filterVendor || this.response.procurementFilter;
    const regionList =
      this.filterVendor && this.filterVendor['regionList']
        ? this.filterVendor['regionList']
        : this.response.procurementRegionList
        ? this.response.procurementRegionList.map(procReg => procReg.region)
        : [];
    let isShowCheckBox = true;

    if (this.formGroup.value.procurementMethod) {
      isShowCheckBox =
        (this.formGroup.value.procurementMethod as ProcurementMethod)
          .procurementRegType.code ===
        this.global.appConstant.pm.PROC_REG_TYPE_INVITATION;
    }
    const procurementMethodId: number =
      this.formGroup.get('procurementMethod').value?.id;
    // this.log.debug('procurementMethodId = ' + procurementMethodId);

    this.appPopupChooseVendorService
      .openProcurement(
        vendorList,
        prIdList,
        [],
        procurementSegQualificationList,
        procurementFilter,
        regionList,
        isShowCheckBox,
        procurementMethodId
      )
      .subscribe(data => {
        if (
          this.formGroup.value.procurementMethod?.procurementRegType?.code ===
            this.global.appConstant.pm.PROC_REG_TYPE_INVITATION ||
          !this.formGroup.value.procurementMethod
        ) {
          this.tableResponseVendor.setRecordList(data.vendorList);
          this.tableResponseVendor.reload();
        } else if (
          this.formGroup.value.procurementMethod?.procurementRegType?.code ===
          this.global.appConstant.pm.PROC_REG_TYPE_ANNOUNCEMENT
        ) {
          this.httpClientService
            .post<Response<InitiationAddEditResponse>>(
              '/initiation/get-vendor-list',
              data.filterList
            )
            .subscribe(vendorList => {
              this.tableResponseVendor.setRecordList(vendorList);
              this.tableResponseVendor.reload();
            });
        }
        this.filterVendor = new ProcurementFilter();
        this.filterVendor.contractNum = data.filterList.totalContract;
        this.filterVendor.contractAmount = data.filterList.contractValue;
        this.filterVendor.minRating = data.filterList.rating;
        this.filterVendor.fromContractDate = data.filterList.startContractDate;
        this.filterVendor.toContractDate = data.filterList.endContractDate;
        this.filterVendor['regionList'] = data.filterList.regionList;
        this.formGroup.markAsDirty();
        this.setInitiationValue();
      });
  }

  public doDeletePq(event: SelectedTableRecordModel): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          // this.log.debug(event.selectedRecordList);
          const checkedIdList: number[] = (
            event.selectedRecordList as Array<Pq>
          ).map(record => record.id);

          this.tableResponsePq.setRecordList(
            this.tableResponsePq
              .getRecordList()
              .filter(pq => !checkedIdList.includes(pq.id))
          );
          this.tableResponsePq.reload();
          this.setInitiationValue();
        }
      });
  }

  public doDeleteSeg(event: SelectedTableRecordModel): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          // this.log.debug(event.selectedRecordList);

          const checkedSegIdList: number[] = (
            event.selectedRecordList as Array<ProcurementSegQualification>
          ).map(record => record.procurementSeg.segmentation.id);

          const checkedTermQualifIdList: number[] = (
            event.selectedRecordList as Array<ProcurementSegQualification>
          ).map(record => record.termQualification.id);

          const checkedQualifIdList: number[] = (
            event.selectedRecordList as Array<ProcurementSegQualification>
          ).map(record => record.qualification.id);

          this.tableResponseSeg.setRecordList(
            this.tableResponseSeg
              .getRecordList()
              .filter(
                procSegQ =>
                  !checkedSegIdList.includes(
                    procSegQ.procurementSeg.segmentation.id
                  ) ||
                  !checkedTermQualifIdList.includes(
                    procSegQ.termQualification.id
                  ) ||
                  !checkedQualifIdList.includes(procSegQ.qualification.id)
              )
          );

          this.tableResponseSeg.reload();

          this.tableResponseVendor
            .getRecordList()
            .splice(0, this.tableResponseVendor.getRecordList().length);
          this.tableResponseVendor.reload();
          this.setInitiationValue();
        }
      });
  }

  public doDeleteVendor(event: SelectedTableRecordModel): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          // this.log.debug(event.selectedRecordList);
          const checkedIdList: number[] = (
            event.selectedRecordList as Array<PopupVendorView>
          ).map(record => record.id);

          this.tableResponseVendor.setRecordList(
            this.tableResponseVendor
              .getRecordList()
              .filter(vendor => !checkedIdList.includes(vendor.id))
          );
          this.setInitiationValue();
          this.tableResponseVendor.reload();
        }
      });
  }

  public doSaveDraft(): void {
    this.validate();
    const initiationRequestDraft: InitiationRequest =
      this.getProcessedRequest(false);

    let saveDraftIsValid = true;
    if (this.initiationTypeCode === 'BLANKET_ORDER') {
      if (!initiationRequestDraft.procurement.description) {
        saveDraftIsValid = false;
      }
      if (!initiationRequestDraft.procurement.procurementType) {
        saveDraftIsValid = false;
      }
    }
    this.setInitiationValue();
    if (saveDraftIsValid) {
      // this.isOfficialReportNull = false;
      this.loadingBlockService.showInfo('app.msg.processing');
      const url =
        this.todo === 'edit' ? '/initiation/update' : '/initiation/insert';
      this.httpClientService
        .post<Response<InitiationAddEditResponse>>(url, initiationRequestDraft)
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.formGroup.markAsPristine();
            this.isUpdated = true;
            this.snackbarService.showWarning('app.alert.msg.saveSuccess');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set('procurementId', response.body.id);
                this.global.routerParams.set(
                  'urlBackOutside',
                  '/pages/initiation'
                );
                this.global.routerParams.set('todo', 'edit');
                this.global.routerParams.set(
                  'initiationType',
                  this.initiationTypeCode
                );
                this.global.routerParams.set('prResponse', this.prResponse);
                if (this.initiationTypeCode === 'BLANKET_ORDER') {
                  this.global.routerParams.delete('prResponse');
                }
                this.router.navigate(['/pages/initiation/edit']);
              });
          } else {
            this.toastService.showError(response.statusText);
          }
          this.setStateReady();
        });
    }
  }

  public doSubmit(): void {
    this.appOfficialReportModel.isGenerate = true;

    this.formGroup.get('documentList').updateValueAndValidity();
    this.validate();

    const minParticipant = this.response.procurementParticipantList.find(
      ppart =>
        ppart.module.code.toUpperCase() === this.moduleCode.toUpperCase() &&
        ppart.procurementMethod.id ===
          this.formGroup.value.procurementMethod?.id
    )?.minimumParticipant;

    const maxParticipant = this.response.procurementParticipantList.find(
      ppart =>
        ppart.module.code.toUpperCase() === this.moduleCode.toUpperCase() &&
        ppart.procurementMethod.id ===
          this.formGroup.value.procurementMethod?.id
    )?.maximumParticipant;

    const procurementVendor =
      this.tableResponsePurchaseVendor.getRecordList()[0]?.procurementVendor;

    const isAllGoodsReviewed =
      (procurementVendor?.isReceived &&
        this.tableResponseGoods.getRecordList().length > 0 &&
        !this.isWaitingReview(this.tableResponseGoods.getRecordList())) ||
      this.tableResponseGoods.getRecordList().length === 0 ||
      !procurementVendor?.isReceived;
    const isAllServiceReviewed =
      (procurementVendor?.isReceived &&
        this.tableResponseService.getRecordList().length > 0 &&
        !this.isWaitingReview(this.tableResponseService.getRecordList())) ||
      this.tableResponseService.getRecordList().length === 0 ||
      !procurementVendor?.isReceived;

    const isDirectPurchaseValid =
      !this.isDirectPurchase() ||
      (this.isDirectPurchase &&
        isAllGoodsReviewed &&
        isAllServiceReviewed &&
        this.tableResponsePurchaseVendor.getRecordList().length > 0 &&
        this.directPurchaseShippingOptionList.getRequestValues().length > 0 &&
        this.tableResponseRealization.getRecordList().length > 0 &&
        this.appOfficialReportModel.isGenerate);

    const prItemAmount =
      this.amountsModel.amount ||
      this.procurementItemList
        ?.map(procItem => procItem.prItem.totalPrice)
        .reduce((a, b) => a + b, 0) ||
      0;

    const isDirectPurchaseTotalPriceValid =
      !this.isDirectPurchase() ||
      (this.isDirectPurchase() &&
        this.formGroup.get('amountReal').value <= prItemAmount);

    const isLastRepeatValid =
      !this.response.procurement ||
      this.isDirectPurchase() ||
      !this.response.procurement?.isRepeat ||
      this.response.procurement.maxRepeatable > 0 ||
      (this.response.procurement &&
        this.response.procurement.isRepeat &&
        !this.isDirectPurchase() &&
        this.response.procurement.maxRepeatable === 0 &&
        this.tableResponseVendor.getRecordList().length >= 1);

    const isDirectPurchaseOffReportValid =
      !this.isDirectPurchase() ||
      (this.isDirectPurchase() && !this.isOfficialReportNull);

    // isVendorResultValid: is_show_vendor_result true > ada procVendor list, false > tdk ada procVendor list > menuju VENDOR-INVITATION
    const isVendorResultValid =
      (this.isShowVendorResult &&
        minParticipant &&
        this.tableResponseVendor.getRecordList().length >= minParticipant &&
        maxParticipant &&
        this.tableResponseVendor.getRecordList().length <= maxParticipant) ||
      !this.isShowVendorResult;

    const isValid =
      this.formGroup.valid &&
      this.procurementItemList.length > 0 &&
      ((isVendorResultValid &&
        ((this.tableResponsePq.getRecordList().length > 0 &&
          this.formGroup.value.isPq) ||
          !this.formGroup.value.isPq) &&
        this.tableResponseSeg.getRecordList().length > 0) ||
        this.isDirectPurchase()) &&
      this.committee &&
      isDirectPurchaseValid &&
      isLastRepeatValid &&
      isDirectPurchaseTotalPriceValid &&
      this.isVendorSelectionBiddingSubmissionValid();

    if (isValid) {
      if (this.isDirectPurchase()) {
        if (
          this.appOfficialReportModel.fileObjectList.length === 0 ||
          this.appOfficialReportModel.fileObjectList.filter(
            fileObject => fileObject.isUploaded
          ).length === 0 ||
          this.appOfficialReportModel.letterDate === null ||
          this.appOfficialReportModel.letterNumber === null
        ) {
          this.isOfficialReportNull = true;
        } else {
          this.isOfficialReportNull = false;
        }
      }

      if (isDirectPurchaseOffReportValid) {
        /** sudah generate dan sudah upload */
        this.global.modalService
          .submitConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();

              const url =
                this.todo === 'edit'
                  ? '/initiation/update'
                  : '/initiation/insert';
              this.httpClientService
                .post<Response<InitiationAddEditResponse>>(
                  url,
                  this.getProcessedRequest(true)
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.isUpdated = true;
                    this.setStateLoading();
                    this.global.modalService
                      .submitSuccessCreateNew()
                      .subscribe(result => {
                        this.formGroup.markAsPristine();
                        if (result) {
                          this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(() => {
                              this.global.routerParams.clear();
                              this.global.routerParams.set('todo', 'add');
                              this.router.navigate(['/pages/initiation/add']);
                              this.global.routerParams.set(
                                'urlBackOutside',
                                '/pages/initiation'
                              );
                            });
                        } else {
                          this.router.navigate(['/pages/initiation']);
                        }
                        this.setStateReady();
                      });
                  } else {
                    this.toastService.showError(response.statusText);
                  }
                  this.setStateReady();
                });
            }
          });
      } else {
        /** sudah generate tapi belum upload */

        this.toastService.showError('initiation.alert.msg.officialReportInfo');
      }
    } else if (this.procurementItemList.length === 0) {
      this.toastService.showError(
        'initiation.alert.msg.minimumOneProductService'
      );
      // Minimum one of Product/Service must be added in Procurement Requisition
    } else if (
      (!minParticipant ||
        this.tableResponseVendor.getRecordList().length < minParticipant) &&
      !this.isDirectPurchase() &&
      (!this.response.procurement ||
        this.response.procurement.maxRepeatable > 0)
    ) {
      this.toastService.showError(
        'initiation.alert.msg.procurementParticipant'
      );
      // Minimal participant harus sesuai konfigurasi master procurement participant
    } else if (
      (!maxParticipant ||
        this.tableResponseVendor.getRecordList().length > maxParticipant) &&
      !this.isDirectPurchase()
    ) {
      const maxPartMsg =
        this.translateService.instant(
          'initiation.alert.msg.reachedMaximumParticipant'
        ) +
        ' ' +
        maxParticipant +
        ' ' +
        this.translateService.instant(
          'initiation.alert.msg.vendorAllowedToInvite'
        );
      this.toastService.showError(maxPartMsg);
      // Maximum participant harus sesuai konfigurasi master procurement participant
    } else if (!this.committee) {
      this.toastService.showError('initiation.alert.msg.committee');
      // Minimal ada satu level committee yang ditambahkan atau tidak boleh kosong
    } else if (
      this.isDirectPurchase() &&
      !this.appOfficialReportModel.isGenerate
    ) {
      // Belum generate off report
      this.toastService.showError('initiation.alert.msg.officialReportInfo');
    } else if (!isLastRepeatValid) {
      /** pengadaan ulang: di kesempatan terakhir (max repeatable = 0) dan jmlh participant masih 0 */
      this.toastService.showError(
        'initiation.alert.msg.reHeldMinimumOneParticipant'
      );
    } else if (!isDirectPurchaseTotalPriceValid) {
      this.toastService.showError(
        'initiation.alert.msg.directPurchaseRealizationExceedItemRequest'
      );
    } else if (!this.isVendorSelectionBiddingSubmissionValid()) {
      this.onChangeVendorSelectionBiddingSubmission(null);
    } else {
      this.toastService.showError(
        'initiation.alert.msg.completeAllRequiredData'
      );
      this.setStateReady();
    }
  }

  public getProcessedRequest(isSubmit: boolean): InitiationRequest {
    const initiationRequest = new InitiationRequest();
    initiationRequest.isShowVendorResult = this.isShowVendorResult;
    initiationRequest.isSubmit = isSubmit || false;
    initiationRequest.procurement = this.formGroup.value;
    initiationRequest.procurement.name = this.formGroup.value.name || '';
    initiationRequest.procurement.isPq = this.formGroup.value.isPq
      ? true
      : false;
    initiationRequest.procurement.amount =
      this.amountsModel.amount || this.response.procurement?.amount || 0;
    initiationRequest.procurement.goodsAmount =
      this.amountsModel.goodsAmount ||
      this.response.procurement?.goodsAmount ||
      0;
    initiationRequest.procurement.serviceAmount =
      this.amountsModel.serviceAmount ||
      this.response.procurement?.serviceAmount ||
      0;
    initiationRequest.procurement.procurementDate = initiationRequest
      .procurement.procurementDate
      ? new Date(initiationRequest.procurement.procurementDate)
      : new Date();
    initiationRequest.procurement.procurementType = Array.isArray(
      this.formGroup.value.procurementType
    )
      ? this.formGroup.value.procurementType[
          this.formGroup.value.procurementType.length - 1
        ]
      : this.formGroup.value.procurementType;

    initiationRequest.procurement.committee = this.committee || null;

    /* proc vendor */
    if (
      !this.isDirectPurchase() &&
      initiationRequest.procurement.procurementMethod &&
      initiationRequest.procurement.procurementMethod.procurementRegType
        .code === this.global.appConstant.pm.PROC_REG_TYPE_INVITATION &&
      this.isShowVendorResult
    ) {
      if (this.todo === 'add') {
        this.tableResponseVendor.getRecordList().forEach(vendor => {
          const procVendor = new ProcurementVendor();
          procVendor.vendor.id = vendor.id;
          initiationRequest.procurementVendorList.push(procVendor);
        });
      } else {
        const existVendorIdList = this.response.procurementVendorList.map(
          pv => pv.vendor.id
        );
        const newVendorList = this.tableResponseVendor
          .getRecordList()
          .filter(v => !existVendorIdList.includes(v.id));

        if (newVendorList.length > 0) {
          newVendorList.forEach(vendor => {
            const procVendor = new ProcurementVendor();
            procVendor.vendor.id = vendor.id;
            procVendor.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
            initiationRequest.procurementVendorList.push(procVendor);
          });
        }

        const vendorIdList = this.tableResponseVendor
          .getRecordList()
          .map(v => v.id);
        const deleteProcVList = this.response.procurementVendorList.filter(
          pv => !vendorIdList.includes(pv.vendor.id)
        );
        deleteProcVList.forEach(procV => {
          procV.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
        });
        initiationRequest.procurementVendorList.push(...deleteProcVList);
      }
    }

    /** proc item */
    if (this.todo === 'add') {
      initiationRequest.procurementItemList = this.procurementItemList;
    } else {
      const existPrItemIdList = this.response.procurementItemList.map(
        procItem => procItem.prItem.id
      );
      const newProcItemList = this.procurementItemList.filter(
        pi => !existPrItemIdList.includes(pi.prItem.id)
      );

      if (newProcItemList.length > 0) {
        newProcItemList.forEach(procItem => {
          procItem.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        });
        initiationRequest.procurementItemList.push(...newProcItemList);
      }

      const prItemIdList = this.procurementItemList.map(
        procItem => procItem.prItem.id
      );
      const deleteProcItemList = this.response.procurementItemList.filter(
        procItem => !prItemIdList.includes(procItem.prItem.id)
      );
      deleteProcItemList.forEach(procItem => {
        procItem.crudOperation =
          this.global.appConstant.core.CRUD_OPERATION_DELETE;
      });

      initiationRequest.procurementItemList.push(...deleteProcItemList);
    }

    /** proc pq */
    if (this.formGroup.value.isPq) {
      if (this.todo === 'add') {
        this.tableResponsePq.getRecordList().forEach(pq => {
          const procurementPq = new ProcurementPq();
          procurementPq.pq = pq;
          initiationRequest.procurementPqList.push(procurementPq);
        });
      } else {
        const existPqIdList = this.response.procurementPqList.map(
          ppq => ppq.pq.id
        );
        const newPqList = this.tableResponsePq
          .getRecordList()
          .filter(pq => !existPqIdList.includes(pq.id));

        if (newPqList.length > 0) {
          newPqList.forEach(pq => {
            const procurementPq = new ProcurementPq();
            procurementPq.pq = pq;
            procurementPq.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
            initiationRequest.procurementPqList.push(procurementPq);
          });
        }

        const pqIdList = this.tableResponsePq.getRecordList().map(pq => pq.id);
        const deleteProcPqList = this.response.procurementPqList.filter(
          ppq => !pqIdList.includes(ppq.pq.id)
        );
        deleteProcPqList.forEach(procPq => {
          procPq.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
        });
        initiationRequest.procurementPqList.push(...deleteProcPqList);

        if (!initiationRequest.procurement.isPq) {
          this.response.procurementPqList.forEach(ppq => {
            ppq.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_DELETE;
          });
          initiationRequest.procurementPqList.push(
            ...this.response.procurementPqList
          );
        }
      }
    }

    /** proc seg & proc seg qualif */
    if (this.todo === 'add') {
      initiationRequest.procurementSegQualificationList =
        this.tableResponseSeg.getRecordList();
    } else {
      const segmentIdList = this.tableResponseSeg
        .getRecordList()
        .map(psegq => psegq.procurementSeg.segmentation.id);
      const qualificationIdList = this.tableResponseSeg
        .getRecordList()
        .map(psegq => psegq.qualification.id);
      const deleteProcSegQualList =
        this.response.procurementSegQualificationList.filter(
          psegQ =>
            !segmentIdList.includes(psegQ.procurementSeg.segmentation.id) ||
            !qualificationIdList.includes(psegQ.qualification.id)
        );
      deleteProcSegQualList.forEach(psegq => {
        psegq.crudOperation =
          this.global.appConstant.core.CRUD_OPERATION_DELETE;

        if (!segmentIdList.includes(psegq.procurementSeg.segmentation.id)) {
          psegq.procurementSeg.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
        }
        initiationRequest.procurementSegQualificationList.push(psegq);
      });

      const oldSegmentIdList =
        this.response.procurementSegQualificationList.map(
          psegq => psegq.procurementSeg.segmentation.id
        );
      const oldQualificationIdList =
        this.response.procurementSegQualificationList.map(
          psegq => psegq.qualification.id
        );
      const newProcSegQualList = this.tableResponseSeg
        .getRecordList()
        .filter(
          rec =>
            !oldSegmentIdList.includes(rec.procurementSeg.segmentation.id) ||
            !oldQualificationIdList.includes(rec.qualification.id)
        );
      newProcSegQualList.forEach(psegq => {
        psegq.crudOperation =
          this.global.appConstant.core.CRUD_OPERATION_INSERT;

        if (!oldSegmentIdList.includes(psegq.procurementSeg.segmentation.id)) {
          psegq.procurementSeg.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        }
        initiationRequest.procurementSegQualificationList.push(psegq);
      });
    }

    /** proc filter */
    let procurementFilter = null;
    if (this.isShowVendorResult) {
      if (!this.response.procurementFilter) {
        procurementFilter = new ProcurementFilter();
      } else {
        procurementFilter = this.response.procurementFilter;

        if (this.filterVendor) {
          procurementFilter.contractAmount = this.filterVendor.contractAmount;
          procurementFilter.contractNum = this.filterVendor.contractNum;
          procurementFilter.fromContractDate = this.filterVendor
            .fromContractDate
            ? new Date(this.filterVendor.fromContractDate)
            : null;
          procurementFilter.toContractDate = this.filterVendor.toContractDate
            ? new Date(this.filterVendor.toContractDate)
            : null;
          procurementFilter.minRating = this.filterVendor.minRating;
        }
      }
    }

    initiationRequest.procurementFilter = procurementFilter;

    /** proc_region */
    if (
      this.filterVendor &&
      this.filterVendor['regionList'] &&
      this.filterVendor['regionList'].length > 0 &&
      this.isShowVendorResult
    ) {
      if (this.todo === 'add') {
        this.filterVendor['regionList'].forEach(region => {
          const procurementRegion = new ProcurementRegion();
          procurementRegion.region = region;
          initiationRequest.procurementRegionList.push(procurementRegion);
        });
      } else {
        const existRegIdList = this.response.procurementRegionList.map(
          preg => preg.region.id
        );

        const newRegList = this.filterVendor['regionList'].filter(
          reg => !existRegIdList.includes(reg.id)
        );
        newRegList.forEach(reg => {
          const procurementRegion = new ProcurementRegion();
          procurementRegion.region = reg;
          procurementRegion.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
          initiationRequest.procurementRegionList.push(procurementRegion);
        });

        const regIdList = this.filterVendor['regionList'].map(reg => reg.id);
        const deleteProcRegList = this.response.procurementRegionList.filter(
          preg => !regIdList.includes(preg.region.id)
        );
        deleteProcRegList.forEach(procReg => {
          procReg.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
        });
        initiationRequest.procurementRegionList.push(...deleteProcRegList);
      }
    }

    initiationRequest.appUploadDocumentTableModelList =
      this.formGroup.value.documentList;

    /** DIRECT PURCHASE */
    if (
      initiationRequest.procurement.procurementMethod &&
      (initiationRequest.procurement.procurementMethod.code ===
        this.global.appConstant.pm.PROCUREMENT_METHOD_DIRECT_PURCHASE ||
        initiationRequest.procurement.procurementMethod.code ===
          this.global.appConstant.pm.PROCUREMENT_METHOD_DIRECT_PROCUREMENT)
    ) {
      initiationRequest.procurement.biddingEvaluation = null;
      initiationRequest.procurement.currencyType = null;
      initiationRequest.procurement.documentSubmissionType = null;
      initiationRequest.procurement.negotiationType = null;
      initiationRequest.procurement.biddingSubmissionType = null;
      initiationRequest.procurement.priceEvaluationType = null;
      initiationRequest.procurement.vendorSelectionType = null;

      const vendor =
        this.tableResponsePurchaseVendor.getRecordList()[0]?.procurementVendor
          ?.vendor;
      const procurementVendor =
        this.tableResponsePurchaseVendor.getRecordList()[0]?.procurementVendor;
      const vendorBank =
        this.tableResponsePurchaseVendor.getRecordList()[0]
          ?.directPurchasePayment?.vendorBank;
      const directPurchasePayment =
        this.tableResponsePurchaseVendor.getRecordList()[0]
          ?.directPurchasePayment;

      if (vendor?.isManual) {
        if (vendor['address']) {
          const vendorAddress = new VendorAddress();
          vendorAddress.address = vendor['address'];
          vendorAddress.isMain = true;
          vendorAddress.id = !vendor.id
            ? null
            : this.response.vendorAddress?.id; // jika vendor.id ada maka ambil vendor address id dari response
          initiationRequest.vendorAddress = vendorAddress;
        }

        if (vendor['picName']) {
          const vendorPIC = new VendorPIC();
          vendorPIC.name = vendor['picName'];
          vendorPIC.id = !vendor.id ? null : this.response.vendorPIC?.id; // jika vendor.id ada maka ambil vendor pic id dari response
          initiationRequest.vendorPIC = vendorPIC;
        }

        if (vendorBank) {
          vendorBank.id = !vendor.id ? null : this.response.vendorBank?.id; // jika vendor.id ada maka ambil vendor bank id dari response
        }
      }

      if (procurementVendor?.isReceived) {
        const directPurchaseDeliveryList: DirectPurchaseDelivery[] = [];
        directPurchaseDeliveryList.push(
          ...this.tableResponseGoods.getRecordList()
        );
        directPurchaseDeliveryList.push(
          ...this.tableResponseService.getRecordList()
        );

        directPurchaseDeliveryList.forEach(delivery => {
          delivery.performanceValue = delivery.performanceValue || 0;
          delivery.receivedDate = delivery.receivedDate || new Date();
        });

        initiationRequest.directPurchaseDeliveryList =
          directPurchaseDeliveryList;
      }
      const directPurchaseShippingList: DirectPurchaseShipping[] =
        this.directPurchaseShippingOptionList.getRequestValues();
      initiationRequest.directPurchaseShippingList = directPurchaseShippingList;

      const directPurchaseRealizationList: DirectPurchaseRealization[] =
        this.tableResponseRealization.getRecordList();

      const procurementResultList: ProcurementResult[] = [];
      directPurchaseRealizationList.forEach(realization => {
        realization.price =
          typeof realization.price === 'object'
            ? realization.price['price']
            : realization.price;
        if (!realization.price) {
          realization.price = 0;
        }

        realization.totalPrice =
          typeof realization.totalPrice === 'object'
            ? realization.totalPrice['price']
            : realization.totalPrice;
        if (!realization.totalPrice) {
          realization.totalPrice = 0;
        }

        const procurementResult = new ProcurementResult();
        procurementResult.procurementItem = realization.procurementItem;
        procurementResult.price = realization.price;
        procurementResult.totalPrice = realization.totalPrice;
        procurementResultList.push(procurementResult);
      });

      initiationRequest.vendor = vendor;
      if (procurementVendor) {
        procurementVendor.id = this.response.procurementVendor?.id;
        procurementVendor.amount = this.formGroup.get('amountReal').value;
        procurementVendor.isRegistered = true;
        procurementVendor.procurementRegType =
          initiationRequest.procurement.procurementMethod?.procurementRegType;
      }

      initiationRequest.procurementVendor = procurementVendor;
      initiationRequest.vendorBank = vendorBank;

      initiationRequest.directPurchasePayment = procurementVendor?.isPaid
        ? directPurchasePayment
        : null;

      initiationRequest.fileObjectList =
        this.tableResponsePurchaseVendor.getRecordList()[0]?.fileObjectList;
      initiationRequest.directPurchaseRealizationList =
        directPurchaseRealizationList;
      initiationRequest.procurementResultList = procurementResultList;

      initiationRequest.appOfficialReportModel = this.appOfficialReportModel;
    } else {
      initiationRequest.appOfficialReportModel = null;
    }

    initiationRequest.prItemList = this.prResponse?.prItemList;
    initiationRequest.initiationTypeCode = this.initiationTypeCode;

    return initiationRequest;
  }

  public getInitiationRequest(): InitiationRequest {
    const initiationRequest = new InitiationRequest();
    initiationRequest.procurement = this.formGroup.value;
    initiationRequest.procurement.name = this.formGroup.value.name || '';
    initiationRequest.procurement.isPq = this.formGroup.value.isPq
      ? true
      : false;
    initiationRequest.procurement.amount =
      this.amountsModel.amount || this.response.procurement?.amount || 0;
    initiationRequest.procurement.goodsAmount =
      this.amountsModel.goodsAmount ||
      this.response.procurement?.goodsAmount ||
      0;
    initiationRequest.procurement.serviceAmount =
      this.amountsModel.serviceAmount ||
      this.response.procurement?.serviceAmount ||
      0;
    initiationRequest.procurement.procurementDate = initiationRequest
      .procurement.procurementDate
      ? new Date(initiationRequest.procurement.procurementDate)
      : new Date();
    initiationRequest.procurement.procurementType = Array.isArray(
      this.formGroup.value.procurementType
    )
      ? this.formGroup.value.procurementType[
          this.formGroup.value.procurementType.length - 1
        ]
      : this.formGroup.value.procurementType;

    initiationRequest.procurement.committee = this.committee || null;

    /* proc vendor */
    if (
      !this.isDirectPurchase() &&
      initiationRequest.procurement.procurementMethod &&
      initiationRequest.procurement.procurementMethod.procurementRegType
        .code === this.global.appConstant.pm.PROC_REG_TYPE_INVITATION
    ) {
      if (this.todo === 'add') {
        this.tableResponseVendor.getRecordList().forEach(vendor => {
          const procVendor = new ProcurementVendor();
          procVendor.vendor.id = vendor.id;
          initiationRequest.procurementVendorList.push(procVendor);
        });
      } else {
        const existVendorIdList = this.response.procurementVendorList.map(
          pv => pv.vendor.id
        );
        const newVendorList = this.tableResponseVendor
          .getRecordList()
          .filter(v => !existVendorIdList.includes(v.id));

        if (newVendorList.length > 0) {
          newVendorList.forEach(vendor => {
            const procVendor = new ProcurementVendor();
            procVendor.vendor.id = vendor.id;
            procVendor.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
            initiationRequest.procurementVendorList.push(procVendor);
          });
        }

        const vendorIdList = this.tableResponseVendor
          .getRecordList()
          .map(v => v.id);
        const deleteProcVList = this.response.procurementVendorList.filter(
          pv => !vendorIdList.includes(pv.vendor.id)
        );
        deleteProcVList.forEach(procV => {
          procV.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
        });
        initiationRequest.procurementVendorList.push(...deleteProcVList);
      }
    }

    /** proc item */
    if (this.todo === 'add') {
      initiationRequest.procurementItemList = this.procurementItemList;
    } else {
      const existPrItemIdList = this.response.procurementItemList.map(
        procItem => procItem.prItem.id
      );
      const newProcItemList = this.procurementItemList.filter(
        pi => !existPrItemIdList.includes(pi.prItem.id)
      );

      if (newProcItemList.length > 0) {
        newProcItemList.forEach(procItem => {
          procItem.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        });
        initiationRequest.procurementItemList.push(...newProcItemList);
      }

      const prItemIdList = this.procurementItemList.map(
        procItem => procItem.prItem.id
      );
      const deleteProcItemList = this.response.procurementItemList.filter(
        procItem => !prItemIdList.includes(procItem.prItem.id)
      );
      deleteProcItemList.forEach(procItem => {
        procItem.crudOperation =
          this.global.appConstant.core.CRUD_OPERATION_DELETE;
      });

      initiationRequest.procurementItemList.push(...deleteProcItemList);
    }

    /** proc pq */
    if (this.formGroup.value.isPq) {
      if (this.todo === 'add') {
        this.tableResponsePq.getRecordList().forEach(pq => {
          const procurementPq = new ProcurementPq();
          procurementPq.pq = pq;
          initiationRequest.procurementPqList.push(procurementPq);
        });
      } else {
        const existPqIdList = this.response.procurementPqList.map(
          ppq => ppq.pq.id
        );
        const newPqList = this.tableResponsePq
          .getRecordList()
          .filter(pq => !existPqIdList.includes(pq.id));

        if (newPqList.length > 0) {
          newPqList.forEach(pq => {
            const procurementPq = new ProcurementPq();
            procurementPq.pq = pq;
            procurementPq.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
            initiationRequest.procurementPqList.push(procurementPq);
          });
        }

        const pqIdList = this.tableResponsePq.getRecordList().map(pq => pq.id);
        const deleteProcPqList = this.response.procurementPqList.filter(
          ppq => !pqIdList.includes(ppq.pq.id)
        );
        deleteProcPqList.forEach(procPq => {
          procPq.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
        });
        initiationRequest.procurementPqList.push(...deleteProcPqList);

        if (!initiationRequest.procurement.isPq) {
          this.response.procurementPqList.forEach(ppq => {
            ppq.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_DELETE;
          });
          initiationRequest.procurementPqList.push(
            ...this.response.procurementPqList
          );
        }
      }
    }

    /** proc seg & proc seg qualif */
    if (this.todo === 'add') {
      initiationRequest.procurementSegQualificationList =
        this.tableResponseSeg.getRecordList();
    } else {
      const segmentIdList = this.tableResponseSeg
        .getRecordList()
        .map(psegq => psegq.procurementSeg.segmentation.id);
      const qualificationIdList = this.tableResponseSeg
        .getRecordList()
        .map(psegq => psegq.qualification.id);
      const deleteProcSegQualList =
        this.response.procurementSegQualificationList.filter(
          psegQ =>
            !segmentIdList.includes(psegQ.procurementSeg.segmentation.id) ||
            !qualificationIdList.includes(psegQ.qualification.id)
        );
      deleteProcSegQualList.forEach(psegq => {
        psegq.crudOperation =
          this.global.appConstant.core.CRUD_OPERATION_DELETE;

        if (!segmentIdList.includes(psegq.procurementSeg.segmentation.id)) {
          psegq.procurementSeg.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
        }
        initiationRequest.procurementSegQualificationList.push(psegq);
      });

      const oldSegmentIdList =
        this.response.procurementSegQualificationList.map(
          psegq => psegq.procurementSeg.segmentation.id
        );
      const oldQualificationIdList =
        this.response.procurementSegQualificationList.map(
          psegq => psegq.qualification.id
        );
      const newProcSegQualList = this.tableResponseSeg
        .getRecordList()
        .filter(
          rec =>
            !oldSegmentIdList.includes(rec.procurementSeg.segmentation.id) ||
            !oldQualificationIdList.includes(rec.qualification.id)
        );
      newProcSegQualList.forEach(psegq => {
        psegq.crudOperation =
          this.global.appConstant.core.CRUD_OPERATION_INSERT;

        if (!oldSegmentIdList.includes(psegq.procurementSeg.segmentation.id)) {
          psegq.procurementSeg.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        }
        initiationRequest.procurementSegQualificationList.push(psegq);
      });
    }

    /** proc filter */
    let procurementFilter = null;
    if (!this.response.procurementFilter) {
      procurementFilter = new ProcurementFilter();
    } else {
      procurementFilter = this.response.procurementFilter;
    }
    if (this.filterVendor) {
      procurementFilter.contractAmount = this.filterVendor.contractAmount;
      procurementFilter.contractNum = this.filterVendor.contractNum;
      procurementFilter.fromContractDate = this.filterVendor.fromContractDate
        ? new Date(this.filterVendor.fromContractDate)
        : null;
      procurementFilter.toContractDate = this.filterVendor.toContractDate
        ? new Date(this.filterVendor.toContractDate)
        : null;
      procurementFilter.minRating = this.filterVendor.minRating;
    }

    initiationRequest.procurementFilter = procurementFilter;

    /** proc_region */
    if (
      this.filterVendor &&
      this.filterVendor['regionList'] &&
      this.filterVendor['regionList'].length > 0
    ) {
      if (this.todo === 'add') {
        this.filterVendor['regionList'].forEach(region => {
          const procurementRegion = new ProcurementRegion();
          procurementRegion.region = region;
          initiationRequest.procurementRegionList.push(procurementRegion);
        });
      } else {
        const existRegIdList = this.response.procurementRegionList.map(
          preg => preg.region.id
        );

        const newRegList = this.filterVendor['regionList'].filter(
          reg => !existRegIdList.includes(reg.id)
        );
        newRegList.forEach(reg => {
          const procurementRegion = new ProcurementRegion();
          procurementRegion.region = reg;
          procurementRegion.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
          initiationRequest.procurementRegionList.push(procurementRegion);
        });

        const regIdList = this.filterVendor['regionList'].map(reg => reg.id);
        const deleteProcRegList = this.response.procurementRegionList.filter(
          preg => !regIdList.includes(preg.region.id)
        );
        deleteProcRegList.forEach(procReg => {
          procReg.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
        });
        initiationRequest.procurementRegionList.push(...deleteProcRegList);
      }
    }

    initiationRequest.appUploadDocumentTableModelList =
      this.formGroup.value.documentList;

    /** DIRECT PURCHASE */
    if (initiationRequest.procurement.procurementMethod !== null) {
      if (initiationRequest.procurement.procurementMethod !== undefined) {
        if (
          initiationRequest.procurement.procurementMethod.code ===
            this.global.appConstant.pm.PROCUREMENT_METHOD_DIRECT_PURCHASE ||
          initiationRequest.procurement.procurementMethod.code ===
            this.global.appConstant.pm.PROCUREMENT_METHOD_DIRECT_PROCUREMENT
        ) {
          initiationRequest.procurement.biddingEvaluation = null;
          initiationRequest.procurement.currencyType = null;
          initiationRequest.procurement.documentSubmissionType = null;
          initiationRequest.procurement.negotiationType = null;
          initiationRequest.procurement.biddingSubmissionType = null;
          initiationRequest.procurement.priceEvaluationType = null;
          initiationRequest.procurement.vendorSelectionType = null;

          const vendor =
            this.tableResponsePurchaseVendor.getRecordList()[0]
              ?.procurementVendor?.vendor;
          const procurementVendor =
            this.tableResponsePurchaseVendor.getRecordList()[0]
              ?.procurementVendor;
          const vendorBank =
            this.tableResponsePurchaseVendor.getRecordList()[0]
              ?.directPurchasePayment?.vendorBank;
          const directPurchasePayment =
            this.tableResponsePurchaseVendor.getRecordList()[0]
              ?.directPurchasePayment;

          if (vendor?.isManual) {
            if (vendor['address']) {
              const vendorAddress = new VendorAddress();
              vendorAddress.address = vendor['address'];
              vendorAddress.isMain = true;
              vendorAddress.id = !vendor.id
                ? null
                : this.response.vendorAddress?.id; // jika vendor.id ada maka ambil vendor address id dari response
              initiationRequest.vendorAddress = vendorAddress;
            }

            if (vendor['picName']) {
              const vendorPIC = new VendorPIC();
              vendorPIC.name = vendor['picName'];
              vendorPIC.id = !vendor.id ? null : this.response.vendorPIC?.id; // jika vendor.id ada maka ambil vendor pic id dari response
              initiationRequest.vendorPIC = vendorPIC;
            }

            if (vendorBank) {
              vendorBank.id = !vendor.id ? null : this.response.vendorBank?.id; // jika vendor.id ada maka ambil vendor bank id dari response
            }
          }

          if (procurementVendor?.isReceived) {
            const directPurchaseDeliveryList: DirectPurchaseDelivery[] = [];
            directPurchaseDeliveryList.push(
              ...this.tableResponseGoods.getRecordList()
            );
            directPurchaseDeliveryList.push(
              ...this.tableResponseService.getRecordList()
            );

            directPurchaseDeliveryList.forEach(delivery => {
              delivery.performanceValue = delivery.performanceValue || 0;
              delivery.receivedDate = delivery.receivedDate || new Date();
            });

            initiationRequest.directPurchaseDeliveryList =
              directPurchaseDeliveryList;
          }
          const directPurchaseShippingList: DirectPurchaseShipping[] =
            this.directPurchaseShippingOptionList.getRequestValues();
          initiationRequest.directPurchaseShippingList =
            directPurchaseShippingList;

          const directPurchaseRealizationList: DirectPurchaseRealization[] =
            this.tableResponseRealization.getRecordList();

          const procurementResultList: ProcurementResult[] = [];
          directPurchaseRealizationList.forEach(realization => {
            realization.price =
              typeof realization.price === 'object'
                ? realization.price['price']
                : realization.price;
            if (!realization.price) {
              realization.price = 0;
            }

            realization.totalPrice =
              typeof realization.totalPrice === 'object'
                ? realization.totalPrice['price']
                : realization.totalPrice;
            if (!realization.totalPrice) {
              realization.totalPrice = 0;
            }

            const procurementResult = new ProcurementResult();
            procurementResult.procurementItem = realization.procurementItem;
            procurementResult.price = realization.price;
            procurementResult.totalPrice = realization.totalPrice;
            procurementResultList.push(procurementResult);
          });

          initiationRequest.vendor = vendor;
          if (procurementVendor) {
            procurementVendor.id = this.response.procurementVendor?.id;
            procurementVendor.amount = this.formGroup.get('amountReal').value;
            procurementVendor.isRegistered = true;
            procurementVendor.procurementRegType =
              initiationRequest.procurement.procurementMethod?.procurementRegType;
          }

          initiationRequest.procurementVendor = procurementVendor;
          initiationRequest.vendorBank = vendorBank;

          initiationRequest.directPurchasePayment = procurementVendor?.isPaid
            ? directPurchasePayment
            : null;

          initiationRequest.fileObjectList =
            this.tableResponsePurchaseVendor.getRecordList()[0]?.fileObjectList;
          initiationRequest.directPurchaseRealizationList =
            directPurchaseRealizationList;
          initiationRequest.procurementResultList = procurementResultList;

          initiationRequest.appOfficialReportModel =
            this.appOfficialReportModel;
        } else {
          initiationRequest.appOfficialReportModel = null;
        }
      }
    }

    initiationRequest.prItemList = this.prResponse.prItemList;
    initiationRequest.initiationTypeCode = this.initiationTypeCode;

    return initiationRequest;
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
          '.' +
          data.translationKey.key || '-'
      );
    } else {
      if (this.todo === 'view') {
        return data?.name || '-';
      } else {
        return data?.name;
      }
    }
  }

  public doCancel(): void {
    const procurement = this.response.procurement;
    this.loadingBlockService.showInfo('initiation.loading.msg.checkingData');
    this.httpClientService
      .get<number>(
        `/initiation/validate-cancelation?procurementId=${procurement.id}`,
        {}
      )
      .subscribe((response: ProcurementCancelationValidateResponse) => {
        this.setStateReady();
        if (response.isWaitingApproval) {
          this.global.modalService.confirmation(
            `initiation.modal.currentStageWaitingApproval.prompt`,
            `initiation.modal.currentStageWaitingApproval.title`,
            null,
            null,
            'pvc pv-exclamation-circle',
            true
          );
        } else if (response.isOnProgress) {
          this.global.modalService.confirmation(
            `initiation.modal.sessionOnProgress.prompt`,
            `initiation.modal.sessionOnProgress.title`,
            null,
            null,
            'pvc pv-exclamation-circle',
            true
          );
        } else {
          this.appPopupService
            .open(
              InitiationPopupCancelationComponent,
              { procurement },
              { size: 'lg' }
            )
            .subscribe((data: ProcurementCancelationRequest) => {
              this.response.procurementCancelation =
                data.procurementCancelation;
              this.response.procurement =
                data.procurementCancelation.procurement;

              this.fileUploaderCancel.fileObjectList = data.fileObjectList;
              this.formGroup
                .get('cancelDocList')
                .patchValue(data.fileObjectList);
              this.formGroup.get('cancelDocList').setIsView(true);

              /** tanpa approval, lgsg canceled */
              if (
                this.response.procurement.procurementStatus.code ===
                this.global.appConstant.pm.PROCUREMENT_STATUS_CANCELED
              ) {
                this.isCanceled = true;
              }

              const date = this.datePipe.transform(
                this.response.procurementCancelation.cancelDate,
                this.global.config.parameterModel.datetimeFormatLong
              );
              const prompt =
                this.translateService.instant(
                  'initiation.confirmation.cancelSuccess.prompt'
                ) +
                ' ' +
                date;
              this.global.modalService
                .confirmation(
                  prompt,
                  'initiation.confirmation.cancelSuccess.title',
                  'app.button.stayOnThisPage',
                  'app.button.backToList',
                  'pvc pv-check-circle',
                  false
                )
                .subscribe(result => {
                  if (result) {
                    this.router.navigate(['/pages/initiation']);
                  }
                });
            });
        }
        this.setInitiationValue();
      });
  }

  public doConfirmFailed(): void {
    const procurement = this.response.procurement;
    const failedReason = this.response.failedReason;
    this.appPopupService
      .open(
        InitiationPopupFailedComponent,
        { procurement, failedReason },
        { size: 'lg' }
      )
      .subscribe((data: ProcurementFailedRequest) => {
        // this.log.debug(data);
        this.response.procurementFailed = data.procurementFailed;
        this.response.procurement = data.procurementFailed.procurement; // debug disini

        this.fileUploaderCancel.fileObjectList = data.fileObjectList;
        this.formGroup.get('failedDocList').patchValue(data.fileObjectList);
        this.formGroup.get('failedDocList').setIsView(true);

        /** tanpa approval, lgsg failed */
        if (
          this.response.procurement.procurementStatus.code ===
          this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED
        ) {
          this.isFailed = true;
        }
      });
    this.setInitiationValue();
  }

  public getWorkflowModelPrcs(): WorkflowModelPrcs {
    let workflowModelPrcs = null;
    if (
      [
        this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL,
        this.global.appConstant.pm.PROCUREMENT_STATUS_CANCELED
      ].includes(this.response?.procurement?.procurementStatus?.code)
    ) {
      workflowModelPrcs =
        this.response?.procurementCancelation?.workflowModelPrcs;
    } else if (
      [
        this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
        this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED
      ].includes(this.response?.procurement?.procurementStatus?.code)
    ) {
      workflowModelPrcs = this.response?.procurementFailed?.workflowModelPrcs;
    } else {
      workflowModelPrcs = this.response?.procurement?.workflowModelPrcs;
    }
    return workflowModelPrcs;
  }

  public getModuleCode(): string {
    let moduleCode = null;
    if (
      [
        this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL,
        this.global.appConstant.pm.PROCUREMENT_STATUS_CANCELED
      ].includes(this.response?.procurement?.procurementStatus?.code)
    ) {
      moduleCode = 'PROCUREMENT-CANCELATION';
    } else if (
      [
        this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
        this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED
      ].includes(this.response?.procurement?.procurementStatus?.code)
    ) {
      moduleCode = 'PROCUREMENT-FAILED';
    } else {
      moduleCode = 'INITIATION';
    }
    return moduleCode;
  }

  public doReHeld(): void {
    const procurementId = this.response.procurement.id;
    this.loadingBlockService.showInfo('initiation.msg.checkingReheldData');
    this.httpClientService
      .get<boolean>(
        `/initiation/is-reheld-created?procurementId=${procurementId}`,
        {}
      )
      .subscribe((isReheldCreated: boolean) => {
        this.loadingBlockService.close();
        if (isReheldCreated) {
          const title = 'initiation.modal.reheldCreated.title';
          const prompt = 'initiation.modal.reheldCreated.prompt';
          this.global.modalService.confirmation(
            prompt,
            title,
            null,
            null,
            'pvc pv-exclamation-triangle',
            true
          );
        } else {
          const title = 'initiation.confirm.reheld.title';
          const prompt = 'initiation.confirm.reheld.prompt';
          const buttonLeft = 'app.button.cancel';
          const buttonRight = 'initiation.button.yesImSure';
          this.global.modalService
            .confirmation(prompt, title, buttonLeft, buttonRight, null, true)
            .pipe(take(1))
            .subscribe(result => {
              if (result) {
                this.setStateLoading();
                this.loadingBlockService.showInfo('app.msg.processing');
                this.todo = 'add';
                this.doDuplicate();
              }
            });
        }
      });
    this.setInitiationValue();
  }

  public isDirectPurchase(): boolean {
    return (
      this.formGroup.get('procurementMethod').value?.code ===
        this.global.appConstant.pm.PROCUREMENT_METHOD_DIRECT_PURCHASE ||
      this.formGroup.get('procurementMethod').value?.code ===
        this.global.appConstant.pm.PROCUREMENT_METHOD_DIRECT_PROCUREMENT
    );
  }

  public buildTableResponsePurchaseVendor(): void {
    this.tableResponsePurchaseVendor = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementVendor.vendor.name',
        header: 'table.column.vendorName',
        plugins: {
          name: 'hyperlink',
          className: 'white-space-normal',
          onClick: this.onClickPurchaseVendor.bind(this)
        }
      },
      {
        field: 'procurementVendor.vendor.isManual',
        header: 'table.column.vendorType',
        dataMap: '{"true": "One Time Vendor", "false": "Promise Vendor"}'
      },
      {
        field: 'procurementVendor.vendor.phone',
        header: 'table.column.phone',
        plugins: {
          name: 'custom-plugin',
          after: (tablePluginData: TablePluginData): void => {
            if (tablePluginData.value) {
              tablePluginData.element.innerText =
                tablePluginData.row.record.procurementVendor.vendor.phoneRegion
                  .callingCode + tablePluginData.value;
            }
          }
        }
      } /** tableplugin: calling code+phone */,
      {
        field: 'procurementVendor.vendor.address',
        header: 'table.column.address'
      } /** vendor view? */,
      {
        field: 'procurementVendor.isReceived',
        header: 'table.column.received',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            true: 'SUCCESS',
            false: 'DANGER'
          },
          colorField: 'procurementVendor.isReceived',
          callbacks: {
            text: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.value) {
                return this.global.translateService.instant(
                  'initiation.detail.tablePurchaseVendor.received.yes'
                );
              } else {
                return this.global.translateService.instant(
                  'initiation.detail.tablePurchaseVendor.received.no'
                );
              }
            }
          }
        }
      },
      {
        field: 'procurementVendor.isPaid',
        header: 'table.column.paid',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            true: 'SUCCESS',
            false: 'DANGER'
          },
          colorField: 'procurementVendor.isPaid',
          callbacks: {
            text: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.value) {
                return this.global.translateService.instant(
                  'initiation.detail.tablePurchaseVendor.paid.yes'
                );
              } else {
                return this.global.translateService.instant(
                  'initiation.detail.tablePurchaseVendor.paid.no'
                );
              }
            }
          }
        }
      }
    ]);
  }

  public onClickPurchaseVendor(
    model: InitiationDirectPurchaseVendorModel
  ): void {
    // this.log.debug(model);
    /** VIEW data vendor purchase in table, containing
     * proc vendor (is paid, is received, note)
     * + vendor data offline (vendor+pic+address+bank)
     * + directPurchasePayment,
     * khusus fileObjectList di request body
     * arahkan ke Popup AddVendor juga
     * */
    this.appPopupService
      .open(DirectPurchasePopupAddVendorComponent, {
        vendorView:
          model.procurementVendor
            .vendor /* atau vendorview jika abis save draft(di body response) */,
        model,
        isView: this.formGroup.isView,
        regionList: this.response.regionList,
        currencyList: this.response.currencyList,
        paymentMethodList: this.response.paymentMethodList
      })
      .subscribe(model => {
        // this.log.debug(model);
        const modelList = [];
        modelList.push(model);
        this.tableResponsePurchaseVendor.setRecordList(modelList);
        this.tableResponsePurchaseVendor.reload();
        this.appTips?.reload();
        this.setInitiationValue();
        this.setStateReady();

        this.global.modalService.confirmation(
          'app.confirmation.saveSuccess.prompt',
          'app.confirmation.saveSuccess.title',
          null,
          null,
          'pvc pv-check-circle',
          false,
          {
            autoCloseInMs: 2000
          }
        );
      });
  }

  public doSelectPurchaseVendor(): void {
    /** Add a vendor */
    const vendor =
      this.tableResponsePurchaseVendor.getRecordList()[0]?.procurementVendor
        ?.vendor; /** agar vendor yg telah dipilih terchecklist di popup */
    this.appPopupService
      .open(
        DirectPurchasePopupSelectVendorComponent,
        { vendor },
        { size: 'xl' }
      )
      .subscribe((vendor: Vendor) => {
        // this.log.debug('vendor');
        // this.log.debug(vendor);
        this.doAddPurchaseVendor(vendor);
      });
  }

  public doAddPurchaseVendor(vendor): void {
    this.appPopupService
      .open(DirectPurchasePopupAddVendorComponent, {
        vendorView: vendor,
        isView: this.formGroup.isView,
        regionList: this.response.regionList,
        currencyList: this.response.currencyList,
        paymentMethodList: this.response.paymentMethodList
      })
      .subscribe(vendor => {
        // this.log.debug(vendor);
        const vendorPurchaseList = [];
        vendorPurchaseList.push(vendor);
        this.tableResponsePurchaseVendor.setRecordList(vendorPurchaseList);
        this.tableResponsePurchaseVendor.reload();
        this.appTips?.reload();
        this.setInitiationValue();
        this.setStateReady();
        this.formGroup.markAsDirty();

        this.global.modalService.confirmation(
          'app.confirmation.saveSuccess.prompt',
          'app.confirmation.saveSuccess.title',
          null,
          null,
          'pvc pv-check-circle',
          false,
          {
            autoCloseInMs: 2000
          }
        );
      });
  }

  public doDeletePurchaseVendor(event: SelectedTableRecordModel): void {
    // this.log.debug(event.selectedRecordList);

    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const checkedIdList: number[] = event.selectedRecordList.map(
            record => record.procurementVendor.vendor.id
          );

          this.tableResponsePurchaseVendor.setRecordList(
            this.tableResponsePurchaseVendor
              .getRecordList()
              .filter(
                data =>
                  !checkedIdList.includes(data.procurementVendor.vendor.id)
              )
          );
          this.tableResponsePurchaseVendor.reload();
          this.setInitiationValue();
        }
      });
  }

  public getUserNameList(addressPicList: AddressPic[]): string {
    return addressPicList.map(a => a?.user?.name).join(', ');
  }

  public doAddAddress(): void {
    const addressBookList = this.directPurchaseShippingOptionList
      .getRequestValues()
      .map(shipping => shipping.address);
    const isMultiple = false;
    this.appPopupAddressService
      .open(addressBookList, isMultiple)
      .subscribe((address: AddressBook) => {
        // this.log.debug(address);

        const directPurchaseShippingList = [];
        const directPurchaseShipping = new DirectPurchaseShipping();
        directPurchaseShipping.procurement = this.response.procurement;
        directPurchaseShipping.address = address;
        directPurchaseShippingList.push(directPurchaseShipping);

        this.directPurchaseShippingOptionList.setRequestValues(
          directPurchaseShippingList
        );
        // this.formGroup.patchValue({
        //   addressList
        // })
        this.setInitiationValue();
        this.formGroup.markAsDirty();
      });
  }

  public doDeleteShipping(): void {
    const directPurchaseShippingList = this.formGroup.get(
      'directPurchaseShippingList'
    ).value as Array<DirectPurchaseShipping>;
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          directPurchaseShippingList.forEach(shipping => {
            const removeIndex = this.directPurchaseShippingOptionList
              .getRequestValues()
              .findIndex(option => option.address.id === shipping.address.id);
            this.directPurchaseShippingOptionList.removeOption(removeIndex);
            directPurchaseShippingList.splice(removeIndex, 1);
            this.formGroup.patchValue({
              directPurchaseShippingList:
                this.directPurchaseShippingOptionList.getRequestValues()
            });

            // this.log.debug(this.formGroup.value.directPurchaseShippingList);
          });

          this.directPurchaseShippingOptionList.setRequestValues([]);
          this.setInitiationValue();
        }
      });
  }

  public buildTableResponseGoods(): void {
    this.tableResponseGoods = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementItem.prItem.item.name',
        header: 'table.column.itemName',
        plugins: {
          name: 'hyperlink',
          className: 'white-space-normal',
          onClick: this.onClickGoodsService.bind(this)
        }
      },
      {
        field: 'procurementItem.prItem.quantity',
        header: 'table.column.qty',

        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'receivedQuantity',
        header: 'table.column.received',

        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'remainingQuantity',
        header: 'table.column.remaining',
        className: 'text-right',
        plugins: {
          name: 'custom-plugin',
          before: (tablePlugin: TablePluginData): string => {
            return this.global.converterService.convertDecimal(
              tablePlugin.value
            );
          }
        }
      },
      {
        field: 'percentage',
        header: 'table.column.percentageOfWork',
        className: 'text-right',
        plugins: [
          {
            name: 'text-field',
            type: 'decimal',
            isView: true
          },
          {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData): void => {
              if (tablePluginData.value) {
                tablePluginData.element.innerText += '%';
              }
            }
          }
        ]
      },
      {
        field: 'directPurchaseDeliveryStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            DONE: 'SUCCESS',
            WAITING_REVIEW: 'WARNING'
          },
          colorField: 'directPurchaseDeliveryStatus.code'
        }
      }
    ]);
  }

  public onClickGoodsService(
    directPurchaseDelivery: DirectPurchaseDelivery
  ): void {
    // this.log.debug(directPurchaseDelivery);
    const index = this.tableResponseGoods
      .getRecordList()
      .findIndex(
        record =>
          record.procurementItem.prItem.id ===
          directPurchaseDelivery.procurementItem.prItem.id
      );
    this.appPopupService
      .open(InitiationPopupDeliveryProcessComponent, {
        directPurchaseDelivery,
        isView: this.formGroup.isView
      })
      .subscribe((directPurchaseDelivery: DirectPurchaseDelivery) => {
        directPurchaseDelivery.directPurchaseDeliveryStatus =
          this.response.directPurchaseDeliveryStatusList.find(
            status =>
              status.code ===
              this.global.appConstant.pm.DIRECT_PURCHASE_DELIVERY_STATUS_DONE
          );
        // this.log.debug(directPurchaseDelivery);
        if (
          directPurchaseDelivery.procurementItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
        ) {
          this.tableResponseGoods.getRecordList()[index] =
            directPurchaseDelivery;
          this.tableResponseGoods.reload();
        } else {
          /** SERVICE */
          this.tableResponseService.getRecordList()[index] =
            directPurchaseDelivery;
          this.tableResponseService.reload();
        }
        this.formGroup.markAsDirty();
        this.setInitiationValue();
      });
  }

  public buildTableResponseService(): void {
    this.tableResponseService = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementItem.prItem.item.name',
        header: 'table.column.itemName',
        plugins: {
          name: 'hyperlink',
          className: 'white-space-normal',
          onClick: this.onClickGoodsService.bind(this)
        }
      },
      {
        field: 'procurementItem.prItem.quantity',
        header: 'table.column.qty',
        className: 'text-right',
        plugins: [
          {
            name: 'text-field',
            type: 'decimal',
            isView: true
          }
        ]
      },
      {
        field: 'receivedQuantity',
        header: 'table.column.received',
        className: 'text-right',
        plugins: [
          {
            name: 'text-field',
            type: 'decimal',
            isView: true
          }
        ]
      },
      {
        field: 'remainingQuantity',
        header: 'table.column.remaining',
        className: 'text-right',
        plugins: {
          name: 'custom-plugin',
          before: (tablePlugin: TablePluginData): string => {
            return this.global.converterService.convertDecimal(
              tablePlugin.value
            );
          }
        }
      },
      {
        field: 'percentage',
        header: 'table.column.percentageOfWork',
        className: 'text-right',
        plugins: [
          {
            name: 'text-field',
            type: 'decimal',
            isView: true
          },
          {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData): void => {
              if (tablePluginData.value) {
                tablePluginData.element.innerText += '%';
              }
            }
          }
        ]
      },
      {
        field: 'directPurchaseDeliveryStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            DONE: 'SUCCESS',
            WAITING_REVIEW: 'WARNING'
          },
          colorField: 'directPurchaseDeliveryStatus.code'
        }
      }
    ]);
  }

  public isWaitingReview(
    directPurchaseDeliveryList: DirectPurchaseDelivery[]
  ): boolean {
    return !!directPurchaseDeliveryList.find(
      delivery =>
        delivery.directPurchaseDeliveryStatus.code ===
        this.global.appConstant.pm
          .DIRECT_PURCHASE_DELIVERY_STATUS_WAITING_REVIEW
    );
  }

  public buildTableRealization(): void {
    this.tableResponseRealization = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementItem.prItem.pr.code',
        header: 'table.column.prNumber'
      },
      {
        field: 'procurementItem.prItem.item.code',
        header: 'table.column.itemCode'
      },
      {
        field: 'procurementItem.prItem.item.name',
        header: 'table.column.itemName'
      },

      {
        field: 'procurementItem.prItem.item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'procurementItem.prItem.item.itemType.code'
        },
        isSortable: false
      },
      {
        field: 'procurementItem.prItem.quantity',
        header: 'table.column.quantity',
        className: 'text-center',
        plugins: {
          name: 'custom-plugin',
          before: (tablePlugin: TablePluginData): string => {
            return this.global.converterService.convertDecimal(
              tablePlugin.value
            );
          }
        }
      },
      {
        field: 'price',
        header: 'table.column.price',
        className: 'text-right',
        plugins: [
          {
            name: 'currency',
            optionList: this.currencyOptionList,
            isView: this.todo === 'view',
            validators: Validators.required(),
            onChange: this.onInputPrice.bind(this),
            onInput: this.onInputPrice.bind(this)
          }
        ]
      },
      {
        field: 'totalPrice',
        header: 'table.column.totalPrice',
        className: 'text-right',
        plugins: [
          {
            name: 'currency',
            optionList: this.currencyOptionList,
            isView: true
          }
        ]
      },
      {
        field: 'profitLossAmount',
        header: 'table.column.saveLoss',
        className: 'text-right',
        plugins: [
          {
            name: 'default',
            callbacks: {
              value: (record): string => {
                return record.profitLossAmount
                  ? this.global.converterService.convertDecimal(
                      record.profitLossAmount
                    ) + '%'
                  : null;
              }
            }
          },
          {
            name: 'badge',
            field: 'profitLossStatus.name',
            colorMap: {
              SAVE: 'SUCCESS',
              LOSS: 'DANGER'
            },
            pill: false,
            colorField: 'profitLossStatus.code',
            className: 'badge-catalog ml-3'
          }
        ]
      }
    ]);
  }

  /** method for table direct purchase realization */
  public onInputPrice(data): void {
    this.appOfficialReportModel.isGenerate =
      false; /** agar perlu generate lg jika ubah price */
    // this.isPriceUpdated = true;
    /* Set totalPrice */
    const currentRowChange = this.tableResponseRealization.currentRowChange;
    const index = currentRowChange.row.position - 1;

    /* Set Save/Loss */
    const profitLossStatusSave = this.response.profitLossStatusList.find(
      status =>
        status.code === this.global.appConstant.pm.PROFIT_LOSS_STATUS_SAVE
    );
    const profitLossStatusLoss = this.response.profitLossStatusList.find(
      status =>
        status.code === this.global.appConstant.pm.PROFIT_LOSS_STATUS_LOSS
    );
    const record = Array.from(this.tableResponseRealization.getRecordList())[
      index
    ];
    this.log.debug('OLD RECORD');
    const oldRecord = new DirectPurchaseRealization();
    Object.assign(oldRecord, record);
    this.log.debug(oldRecord);

    record.profitLossAmount = data
      ? 100 - (data.price / record.procurementItem.prItem.price) * 100
      : 0;
    record.profitLossAmount = +this.global.converterService.convertDecimal(
      record.profitLossAmount
    );
    record.profitLossStatus =
      record.profitLossAmount > 0
        ? profitLossStatusSave
        : record.profitLossAmount < 0
        ? profitLossStatusLoss
        : null;
    record.price = data;
    const totalPrice = {
      price: data
        ? +currentRowChange.row.record.procurementItem.prItem.quantity *
          +data.price
        : 0,
      currency: this.response.currencyList[0]
    };
    record.totalPrice = totalPrice as any;

    const tableColumnPercentage: TableColumn =
      currentRowChange.row.columnList[7];
    tableColumnPercentage.changeValue({
      field: 'profitLossAmount',
      value: data
        ? 100 -
          (data.price /
            currentRowChange.row.record.procurementItem.prItem.price) *
            100
        : 0
    });
    tableColumnPercentage.changeValue({
      field: 'profitLossStatus',
      value:
        currentRowChange.row.record.profitLossAmount > 0
          ? profitLossStatusSave
          : currentRowChange.row.record.profitLossAmount < 0
          ? profitLossStatusLoss
          : null
    });
    tableColumnPercentage.reload();

    const tableColumnTotalPrice: TableColumn =
      currentRowChange.row.columnList[6];
    const totalAmount = data
      ? +currentRowChange.row.record.procurementItem.prItem.quantity *
        +data.price
      : 0;
    const objTotalAmount = {
      price: +totalAmount,
      currency: this.response.currencyList[0]
    };
    tableColumnTotalPrice.changeValue(objTotalAmount);
    tableColumnTotalPrice.reload();
    this.doSetAmount();
  }

  public doSetAmount(): void {
    this.log.debug('FORMGROUP:');
    this.log.debug(this.formGroup);

    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;
    this.tableResponseRealization
      .getRecordList()
      .forEach((directPurchaseRealization: any) => {
        this.log.debug(
          'totalPrice of item: ' +
            directPurchaseRealization.procurementItem.prItem.item.name
        );
        this.log.debug(directPurchaseRealization.totalPrice);

        const totalPrice =
          typeof directPurchaseRealization.totalPrice === 'object'
            ? +directPurchaseRealization.totalPrice['price']
            : +directPurchaseRealization.totalPrice;
        if (totalPrice) {
          if (
            directPurchaseRealization.procurementItem.prItem.item.itemType
              .code === this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
          ) {
            goodsAmountTotal = +goodsAmountTotal + +totalPrice;
          }
          if (
            directPurchaseRealization.procurementItem.prItem.item.itemType
              .code === this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
          ) {
            serviceAmountTotal = +serviceAmountTotal + +totalPrice;
          }
        }
      });
    this.formGroup.patchValue({
      goodsAmountReal: +goodsAmountTotal,
      serviceAmountReal: +serviceAmountTotal,
      amountReal: +goodsAmountTotal + +serviceAmountTotal,
      goodsProfitLoss: goodsAmountTotal
        ? 100 - (goodsAmountTotal / this.prGoodsPrice) * 100
        : 0,
      serviceProfitLoss: serviceAmountTotal
        ? 100 - (serviceAmountTotal / this.prServicePrice) * 100
        : 0,
      amountProfitLoss:
        +goodsAmountTotal + +serviceAmountTotal
          ? 100 -
            ((+goodsAmountTotal + +serviceAmountTotal) / this.prGrandPrice) *
              100
          : 0
    });
    this.setInitiationValue();
  }

  public onChangeOfficialReport(event: AppOfficialReportModel): void {
    this.appOfficialReportModel = event;
    this.isOfficialReportNull =
      !this.appOfficialReportModel?.fileObjectList?.find(
        fileObject => !fileObject?.isDeleted
      );
    this.setInitiationValue();
  }

  public doDuplicate(): void {
    const oldFormGroup = {};
    Object.assign(oldFormGroup, this.formGroup);

    this.formGroup.setIsView(false);
    this.isFailed = false;

    this.procurementItemList.forEach(data => {
      data.id = null;
      data.procurement = null;
    });

    const oldProcurement = new Procurement();
    Object.assign(oldProcurement, this.response.procurement);

    this.response.procurement.fromProcurement = oldProcurement;

    this.response.procurement.id = null;

    this.formGroup.patchValue({
      id: null,
      procurementStatus: this.response.draftProcurementStatus,
      fromProcurement: oldProcurement,
      workflowModelPrcs: null,
      workflowPrcs: null,
      prcsCode: null,
      userRequester: null
    });
    this.response.procurement.procurementStatus =
      this.response.draftProcurementStatus;
    this.response.procurementPqList.forEach(data => {
      data.id = null;
      data.procurement = null;
    });
    this.response.procurementVendorList.forEach(data => {
      data.id = null;
      data.procurement = null;
    });
    this.response.procurementSegQualificationList.forEach(data => {
      data.id = null;
      data.procurementSeg.id = null;
      data.procurementSeg.procurement = null;
    });
    this.response.procurementRegionList.forEach(data => {
      data.id = null;
      data.procurement = null;
    });
    this.response.procurementFilter.procurement = null;
    this.response.appUploadDocumentTableModelList.forEach(model => {
      model.id = null;
      model.crudOperation = this.global.appConstant.core.CRUD_OPERATION_INSERT;
    });

    this.tableResponseSeg.setRecordList(
      this.response.procurementSegQualificationList
    );
    this.tableResponseSeg.reload();
    this.tableResponseVendor.reload();

    this.doSaveDraft();
    this.setInitiationValue();
  }

  public onChangeVendorSelectionBiddingSubmission(
    event: VendorSelectionType | BiddingSubmissionType
  ): void {
    this.log.debug(event);
    const biddingSubmissionType = this.formGroup.get(
      'biddingSubmissionType'
    ).value;
    const vendorSelectionType = this.formGroup.get('vendorSelectionType').value;

    if (!this.isVendorSelectionBiddingSubmissionValid()) {
      let alertMsg = this.translateService.instant(
        'initiation.alert.msg.cantSelect'
      );
      alertMsg +=
        ' ' +
        this.translateService.instant('initiation.alert.msg.vendorSelection') +
        ' ' +
        this.getTranslateKey(vendorSelectionType) +
        ' ' +
        this.translateService.instant('initiation.alert.msg.and') +
        ' ' +
        this.translateService.instant(
          'initiation.alert.msg.biddingSubmissionType'
        ) +
        ' ' +
        this.getTranslateKey(biddingSubmissionType);

      this.toastService.showError(alertMsg);
    }
    this.setInitiationValue();
  }

  public isVendorSelectionBiddingSubmissionValid(): boolean {
    const biddingSubmissionTypeCode = this.formGroup.get(
      'biddingSubmissionType'
    ).value?.code;
    const vendorSelectionTypeCode = this.formGroup.get('vendorSelectionType')
      .value?.code;
    return !(
      (vendorSelectionTypeCode ===
        this.global.appConstant.pm.VENDOR_SELECTION_TYPE_MULTI_WINNER &&
        biddingSubmissionTypeCode ===
          this.global.appConstant.pm.BIDDING_SUBMISSION_TYPE_TOTAL_PRICE) ||
      (vendorSelectionTypeCode ===
        this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER &&
        biddingSubmissionTypeCode ===
          this.global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE)
    );
  }

  public setAccessBuyer(): void {
    if (
      this.global.userSession.activeUserRole.role.code !==
      this.global.appConstant.ROLE_CODE_BUYER
    ) {
      this.setViewOnly();
      this.todo = 'view';
      this.binaryTreeModel.setViewOnly();
      this.setInitiationValue();
    }
  }

  public onChangeCommittee(committee: Committee): void {
    this.committee = committee;
    this.formGroup.markAsDirty();
    this.setInitiationValue();
  }
}
