import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupImportQuantityComponent } from './app-popup-import-quantity.component';
import { AppPopupImportQuantityService } from './app-popup-import-quantity.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppButtonModule],
  declarations: [AppPopupImportQuantityComponent],
  entryComponents: [AppPopupImportQuantityComponent],
  providers: [AppPopupImportQuantityService],
  exports: [AppPopupImportQuantityComponent]
})
export class AppPopupImportQuantityModule {}
