import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Organization } from 'src/app/core/bean/organization';
import { Procurement } from 'src/app/core/bean/procurement';
import { ScheduleStatus } from 'src/app/core/bean/schedule-status';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { ProcurementScheduleOptionResponse } from './procurement-schedule-option.response';
@Component({
  templateUrl: './procurement-schedule.component.html'
})
export class ProcurementScheduleComponent
  extends BaseModuleComponent
  implements OnInit
{
  public tableResponse: TableResponseModel<Procurement>;
  public scheduleStatusOptionList: OptionListModel<ScheduleStatus> =
    new OptionListModel();
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);

  constructor(translateService: TranslateService) {
    super('procurement-schedule', translateService);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setOptionList();
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      scheduleStatusList: [null],
      organizationList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<ProcurementScheduleOptionResponse>(
        '/procurement-schedule/get-option-list'
      )
      .subscribe(
        (
          procurementScheduleOptionResponse: ProcurementScheduleOptionResponse
        ) => {
          this.scheduleStatusOptionList.setRequestValues(
            procurementScheduleOptionResponse.scheduleStatusList || []
          );
          this.organizationOptionList.setRequestValues(
            procurementScheduleOptionResponse.organizationList
          );
        }
      );
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.procurementNumber',
        plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
      },
      {
        field: 'procurementDate',
        header: 'table.column.procurementDate',
        plugins: 'date'
      },
      {
        field: 'name',
        header: 'table.column.procurementName',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'badge',
            field: 'isRepeat',
            pill: false,
            className: 'badge-catalog badge-danger ml-3',
            colorMap: {
              RE_HELD: 'DANGER'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.row.record.isRepeat) {
                  const RE_HELD: string = this.translateService.instant(
                    'dynamic-master-attribute.procurementFlag.reHeld'
                  );
                  return RE_HELD;
                }
              }
            }
          },
          {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData): void => {
              if (!tablePluginData.row.record.isRepeat) {
                tablePluginData.element.children[1].remove();
              }
            }
          }
        ]
      },
      {
        field: 'organization.name',
        header: 'table.column.department'
      },
      {
        field: 'scheduleStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorMap: {
            DRAFT: 'SECONDARY',
            NEW: 'INFO',
            REVISION: 'FEEDBACK',
            WAITING_APPROVAL: 'WARNING',
            ON_PROGRESS: 'PROGRESS',
            DONE: 'SUCCESS'
          },
          colorField: 'scheduleStatus.code'
        }
      }
    ]);
  }

  public doClick(procurement: Procurement): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('procurementId', procurement.id);
    this.global.routerParams.set(
      'procurementStatus',
      procurement.procurementStatus
    );
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/procurement-schedule'
    );
    procurement.procurementStatus.code ===
      this.global.appConstant.pm.PROCUREMENT_STATUS_ON_PROGRESS &&
    procurement.scheduleStatus.code !==
      this.global.appConstant.pm.SCHEDULE_STATUS_WAITING_APPROVAL
      ? this.global.routerParams.set('todo', 'edit')
      : this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('scheduleStatus', procurement.scheduleStatus);
    this.router.navigate(['/pages/procurement-schedule/edit']);
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
    this.formGroup.get('fromDate').reset();
    this.formGroup.get('untilDate').reset();
  }
}
