import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppCheckBoxComponent } from './app-check-box.component';
@NgModule({
  imports: [VendorModule, AppTooltipWrapperModule],
  declarations: [AppCheckBoxComponent],
  exports: [AppCheckBoxComponent]
})
export class AppCheckBoxModule {}
