import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-popup',
  templateUrl: './app-popup.component.html',
  styleUrls: ['./app-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupComponent implements OnInit {
  @Input() public isShowHeaderCloseButton: boolean;
  @Input() public header: string;
  @Input() public isLoading: boolean;
  @Input() public isSaving: boolean;
  @Output() public onClose: EventEmitter<boolean> = new EventEmitter();
  @ContentChild('modalFooter') modalFooterTmpl: TemplateRef<any>;
  constructor(
    public ngbModal: NgbModal,
    public ngbActiveModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.isShowHeaderCloseButton =
      typeof this.isShowHeaderCloseButton === 'undefined' ||
      this.isShowHeaderCloseButton;
  }
  public doClosePopup(): void {
    this.onClose.emit(true);
    this.ngbActiveModal.close();
  }
}
