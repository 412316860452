import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { AppPopupService } from '../app-popup.service';
import { AppPopupChooseBillComponent } from './app-popup-choose-bill.component';

@Injectable()
export class AppPopupChooseBillService {
  constructor(public appPopupService: AppPopupService) {}
  public open(
    isInternal: boolean,
    paymentBilling?: PaymentBilling
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupChooseBillComponent,
      {
        isInternal,
        paymentBilling
      },
      { size: 'xl' }
    );
  }
}
