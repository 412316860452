import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';
import { TableResponseModel } from '../../../core/components/app-table/model/table-response-model';
import { SnackbarService } from '../../../core/services/snackbar.services';
@Component({
  templateUrl: './employee-popup.component.html',
  styleUrls: ['./employee-popup.component.scss']
})
export class EmployeePopupComponent extends BaseComponentComponent {
  public tableResponse: TableResponseModel<any>;
  public isCheckedAll = false;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private snackbarService: SnackbarService,
    private activeModal: NgbActiveModal,
    private elementRef: ElementRef
  ) {
    super('employee-popup');
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setTableResponse();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'name' },
      { field: 'address', header: 'address' }
    ]);
  }

  public setTableResponse(): void {
    const records = [
      { name: 'John Doe', address: 'Pluto', isChecked: false },
      { name: 'Jane Doe', address: 'Mars', isChecked: true },
      { name: 'Marie Doe', address: 'Earth', isChecked: false }
    ];
    this.tableResponse.page.records = records;
  }

  public doCheckAll(isCheckedAll: boolean): void {
    this.tableResponse.page.records.forEach(record => {
      record.isChecked = isCheckedAll;
    });
  }

  public doSave(): void {
    // const isChecked = this.tableResponse.page.records.filter(
    //   record => record.isChecked && record
    // );
    // this.onChange.emit(isChecked);
    this.snackbarService.showWarning(
      'Email has been sent',
      this.elementRef.nativeElement.scrollWidth,
      { isShowIcon: true }
    );
  }

  public doCancel(): void {
    this.activeModal.dismiss();
  }
}
