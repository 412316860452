import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { Vendor } from '../../../core/bean/vendor';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { Validators } from '../../../core/validators';
import { InterviewRequest } from './interview.request';
import { InterviewResponse } from './interview.response';

@Component({
  templateUrl: './interview.component.html'
})
export class InterviewComponent extends BaseModuleComponent {
  public interviewResponse: InterviewResponse = new InterviewResponse();
  public interviewResponseRouteParam: InterviewResponse = null;
  public today: Date = new Date();
  public vendorId: number;
  public vendor: Vendor = new Vendor();
  public isView = false;
  public interviewRequest: InterviewRequest = new InterviewRequest();
  public vendorHistoryId: number;

  constructor(translateService: TranslateService) {
    super('interview', translateService);
  }

  onInit(): void {
    this.vendorHistoryId = this.global.routerParams.get('vendorHistoryId');
    this.interviewResponseRouteParam = this.global.routerParams.get('interviewResponse');
    this.buildFormGroup();
    this.getAndSetOnBoardingToFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null, Validators.required()],
      startTime: [
        null,
        Validators.compose([Validators.required(), Validators.max('endTime')])
      ],
      endTime: [
        null,
        Validators.compose([Validators.required(), Validators.min('startTime')])
      ],
      note: [null, Validators.required()],
      questionList: this.formBuilder.array([])
    });
  }

  public clearFormGroup(interviewResponse: InterviewResponse): void {
    if (
      interviewResponse.isTemplateDifferent &&
      interviewResponse.vendorHistory.vendor.vendorActivityStatus.code.toUpperCase() ===
      'INTERVIEW'
    ) {
      this.formGroup.get('date').reset();
      this.formGroup.get('startTime').reset();
      this.formGroup.get('endTime').reset();
      this.formGroup.get('note').reset();
    }
  }

  public get questionList(): FormArray {
    return this.formGroup.get('questionList') as FormArray;
  }

  public getAndSetOnBoardingToFormGroup(): void {
    if (this.interviewResponseRouteParam) {
      this.setStateLoading();
      this.interviewResponse = this.interviewResponseRouteParam;
      this.setIsViewInterview();
      this.setOnBoardingToFormGroup();
      this.setStateReady();

    } else {
      this.httpClientService.get<InterviewResponse>('/interview/index/' + this.vendorHistoryId)
        .subscribe((interviewResponse: InterviewResponse) => {
          this.interviewResponse = interviewResponse;
          this.setIsViewInterview();
          this.setOnBoardingToFormGroup();
          this.global.routerParams.set('interviewResponse', this.interviewResponse);
          this.setStateReady();
        });
    }

  }

  public getHours(time): string {
    return new Date(time).toLocaleTimeString('en-GB').substring(0, 5) + ' ';
  }

  public setOnBoardingToFormGroup(): void {
    if (this.interviewResponse.dueDiligenceRealizationInterview !== null) {
      const date = new Date(
        this.interviewResponse.dueDiligenceRealizationInterview.date
      );
      const startTime = new Date(
        this.interviewResponse.dueDiligenceRealizationInterview.startTime
      );
      const endTime = new Date(
        this.interviewResponse.dueDiligenceRealizationInterview.endTime
      );
      this.formGroup.patchValue(
        this.interviewResponse.dueDiligenceRealizationInterview
      );
      this.formGroup.patchValue({ date, startTime, endTime });
    }
    this.interviewResponse.interviewQuestionList.forEach(
      (interviewQuestion, index) => {
        const formGroup = this.formBuilder.group({
          question: interviewQuestion.question.name,
          result:
            this.interviewResponse.dueDiligenceInterviewList &&
              this.interviewResponse.dueDiligenceInterviewList.length ===
              this.interviewResponse.interviewQuestionList.length &&
              (!this.interviewResponse.isTemplateDifferent ||
                this.interviewResponse.vendorHistory.vendor.vendorActivityStatus.code
                !== this.global.appConstant.vm.V_ACTIVITY_STATUS_INTERVIEW)
              ? [
                (this.interviewResponse.dueDiligenceInterviewList &&
                  this.interviewResponse.dueDiligenceInterviewList[index] ?
                  this.interviewResponse.dueDiligenceInterviewList[index].result : null),
                Validators.required()
              ]
              : [null, Validators.required()]
        });
        this.questionList.push(formGroup);
        formGroup.setIsView(this.isView);
      }
    );

    if (this.interviewResponse.vendorHistory) {
      this.clearFormGroup(this.interviewResponse);
    }
  }

  public setIsViewInterview(): void {
    if (
      this.interviewResponse.vendorHistory.vendor.vendorActivityStatus.code !==
      'INTERVIEW' || this.interviewResponse.isUserApproval
    ) {
      this.isView = true;
      this.setViewOnly();
    } else if (
      this.global.userSession.user.organization.id !==
      this.interviewResponse.vendorHistory.vendor.onBoardingByOrganization.id
    ) {
      this.setViewOnly();
      this.isView = true;
    }
  }

  public doBackApproval(): void {
    this.router.navigate(['/pages/approval-prcs']);
  }

  public doBack(): void {
    this.router.navigate(['/pages/on-boarding']);
  }

  public doSaveInterview(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.formSaving = true;
            this.loadingBlockService.showInfo(`app.msg.processing`);

            if (
              this.interviewResponse.vendorHistory.vendor.vendorActivityStatus
                .code === 'INTERVIEW'
            ) {
              const dueDiligenceRealization = this.formGroup.value;
              dueDiligenceRealization.startTime = new Date(
                dueDiligenceRealization.startTime
              );
              dueDiligenceRealization.endTime = new Date(
                dueDiligenceRealization.endTime
              );
              const dueDiligenceInterviewList = this.formGroup.get(
                'questionList'
              ).value;
              this.interviewRequest.dueDiligenceRealizationInterview = dueDiligenceRealization;
              this.interviewRequest.dueDiligenceInterviewList = dueDiligenceInterviewList;
            }

            this.interviewRequest.dueDiligenceScheduleInterview = this.interviewResponse.dueDiligenceScheduleInterview;
            this.interviewRequest.interviewQuestionList = this.interviewResponse.interviewQuestionList;
            this.interviewRequest.vendorHistory = this.interviewResponse.vendorHistory;
            this.httpClientService
              .post<Response<Vendor>>('/interview/update', this.interviewRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.setStateReady();
                  this.router.navigate(['/pages/on-boarding']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    } else {
      this.global.alertService.showError(
        this.translateService.instant('interview.alert.completeData')
      );
    }
  }

  public get formArrayQuestionList(): FormArray {
    return this.formGroup.get('questionList') as FormArray;
  }
}
