import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { WebContent } from '../../../core/bean/web-content';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
@Component({
  templateUrl: './vendor-policy-edit-add.component.html'
})
export class VendorPolicyEditAddComponent extends BaseModuleComponent {
  constructor(translateService: TranslateService) {
    super('vendor-policy', translateService);
  }

  public onInit(): void {
    this.doSetTodoFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetTodoFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorPolicyList: this.formBuilder.array([])
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<Array<WebContent>>('/vendor-policy/add-edit', {})
      .subscribe(vendorPolicyList => {
        const control = this.formGroup.controls.vendorPolicyList as FormArray;
        vendorPolicyList.forEach((vendorPolicy: WebContent) => {
          control.push(
            this.formBuilder.group({
              id: vendorPolicy.id,
              lang: vendorPolicy.lang,
              code: vendorPolicy.code,
              key: vendorPolicy.key,
              description: vendorPolicy.description,
              value: vendorPolicy.value
            })
          );
        });
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const vendorPolicyList = (
              this.formGroup.controls.vendorPolicyList as FormArray
            ).value as Array<WebContent>;
            this.httpClientService
              .post<Response<WebContent>>(
                '/vendor-policy/update',
                vendorPolicyList
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/content/']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/content/']);
  }

  public get formArrayVendorPolicyList(): FormArray {
    return this.formGroup.get('vendorPolicyList') as FormArray;
  }
}
