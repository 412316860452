import { BaseEntity } from '../base/base-entity';
import { Counter } from '../bean/counter';
import { User } from '../bean/user';


export class UserCounterEntity extends BaseEntity {
    user: User;
    counter: Counter;
    crudOperation: number;
}
