import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { ModuleGroup } from '../../core/bean/module-group';
import { PushNotification } from '../../core/bean/push-notification';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';

@Component({
  templateUrl: './push-notification.component.html'
})
export class PushNotificationComponent
  extends BaseModuleComponent
  implements OnInit
{
  public moduleOptionList: OptionListModel<ModuleGroup> = new OptionListModel(
    true,
    'code'
  );

  public onInit(): void {
    this.buildFormGroup();
    this.doBuildTableResponse();
    this.setStateReady();
    this.setOptionList();
  }

  constructor(translateService: TranslateService) {
    super('push-notification', translateService);
  }
  public tableResponse: TableResponseModel<PushNotification>;

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      moduleList: [null]
    });
  }
  public setOptionList(): void {
    this.httpClientService
      .get<ModuleGroup[]>('/push-notification/get-module-list')
      .subscribe((moduleGroup: ModuleGroup[]) => {
        this.moduleOptionList.setRequestValues(moduleGroup);
      });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.templatecode',
        plugins: { name: 'hyperlink', onClick: this.doEdit.bind(this) },
        className: 'white-space-normal'
      },
      {
        field: 'name',
        header: 'table.column.template.notification'
      },
      {
        field: 'description',
        header: 'table.column.description'
      },
      {
        field: 'module.name',
        header: 'table.column.modulename'
      },
      {
        field: 'module.moduleGroupCode',
        header: 'table.column.modulegrup'
      },
      {
        field: 'isActive',
        header: 'table.column.status',
        plugins: {
          name: 'default',
          callbacks: {
            value: (record: any): string => {
              if (record.isActive) {
                return this.translateService.instant(
                  'push-notification.status.active'
                );
              } else {
                return this.translateService.instant(
                  'push-notification.status.notActive'
                );
              }
            }
          }
        }
      }
    ]);
  }

  public doEdit(data: any): void {
    const pushNotification: PushNotification = data;
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('pushNotificationId', pushNotification.id);
    this.router.navigate(['/pages/push-notification/edit']);
  }

  public onChangeFilter(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
