import { BaseEntity } from '../base/base-entity';
import { RfpProposalCriteria } from '../bean/rfp-proposal-criteria';
import { RfqVendor } from '../bean/rfq-vendor';

export class RfqVendorScoringEntity extends BaseEntity {
  rfpProposalCriteria: RfpProposalCriteria = new RfpProposalCriteria();
  rfqVendor: RfqVendor = new RfqVendor();
  weightScore: number;
  score: number;
}
