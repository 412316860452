import { Component, ViewChild } from '@angular/core';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { WorkPlan } from '../../core/bean/work-plan';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: 'tor-submission.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class TorSubmissionComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public tableResponse: TableResponseModel<WorkPlan>;

  constructor(translateService: TranslateService) {
    super('tor-submission', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      { field: 'number', header: 'table.column.number' }
    ]);
  }

  public doEdit(workPlan: WorkPlan) {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('workPlanId', workPlan.id);
    this.router.navigate(['/pages/tor-submission/edit']);
  }

  public doView(workPlan: WorkPlan) {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'detail');
    this.global.routerParams.set('workPlanId', workPlan.id);
    this.router.navigate(['/pages/tor-submission/detail']);
  }
}
