import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from './../../core/shared/shared.module';
import { AppPopupTabExperienceComponent } from './app-popup-tab-experience.component';
import { TabExperienceHistoryComponent } from './tab-experience-history.component';
import { TabExperienceComponent } from './tab-experience.component';
@NgModule({
  imports: [SharedModule],
  declarations: [
    TabExperienceComponent,
    TabExperienceHistoryComponent,
    AppPopupTabExperienceComponent
  ],
  entryComponents: [
    TabExperienceComponent,
    TabExperienceHistoryComponent,
    AppPopupTabExperienceComponent
  ],
  exports: [
    TabExperienceComponent,
    TabExperienceHistoryComponent,
    AppPopupTabExperienceComponent
  ]
})
export class TabExperienceModule {
  constructor(accordionService: AccordionService) {
    accordionService.register('tab-experience', TabExperienceComponent);
    accordionService.register(
      'tab-experience-history',
      TabExperienceHistoryComponent
    );
  }
}
