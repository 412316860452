import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Contract } from '../../bean/contract';
import { WidgetService } from '../widgets.service';
import { WidgetContractResponse } from './widget-contract.response';
@Component({
  templateUrl: './app-widget-contract.component.html',
  styleUrls: ['./app-widget-contract.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetContractComponent extends BaseComponentComponent {
  public totalContractRequest: number;
  public contractDataList: Array<any>;
  public contractDataIcon = {
    totalContractRequest: 'fas fa-hand-pointer',
    totalDraft: 'fas fa-file',
    totalNegotiation: 'fas fa-hands-helping',
    totalFinalization: 'fas fa-check-circle',
    totalCancel: 'fas fa-power-off',
    totalOnProgress: 'fas fa-clock',
    totalClose: 'fas fa-times-circle',
    totalTermination: 'fas fa-exclamation-circle'
  };
  constructor(public widgetService: WidgetService) {
    super('app-widget-contract');
  }

  public onInit(): void {
    this.getAndSetContractStatusInformation();
    this.getAndSetTotalContractRequest();
  }

  public getAndSetContractStatusInformation(): void {
    this.httpClientService
      .get<WidgetContractResponse>('/widget-contract/index', {})
      .subscribe(response => {
        this.contractDataList = Object.keys(response).map((key: string) => {
          let strKey = key.slice(5);
          return {
            value: response[key],
            icon: this.contractDataIcon[key],
            translationKey:
              this.moduleCode +
              '.' +
              strKey.charAt(0).toLowerCase() +
              strKey.slice(1)
          };
        });
        this.setStateReady();
      });
  }

  public getAndSetTotalContractRequest(): void {
    this.httpClientService
      .get<Contract[]>('/widget-contract/index-total-contract-request', {})
      .subscribe(response => {
        this.totalContractRequest = response.length;
        this.setStateReady();
      });
  }

  public goToMoreDetail(): void {
    this.router.navigate(['/pages/contract-request/']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
