import { Catalog } from 'src/app/core/bean/catalog';
import { Order } from 'src/app/core/bean/order';
import { PrPoVerification } from 'src/app/core/bean/pr-po-verification';

export class PrPoVerificationRequest {
  prPoVerification: PrPoVerification = new PrPoVerification();
  order: Order = new Order();
  isSubmit: boolean;
  catalogList: Catalog[] = [];
}
