import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { VendorReactivationResponse } from './vendor-reactivation-response';
@Component({
  templateUrl: './vendor-reactivation-detail.component.html'
})
export class VendorReactivationDetailComponent extends BaseModuleComponent {
  public vendorHistoryId: number;
  public vendorId: number;
  public vendorReactivationResponse: VendorReactivationResponse = new VendorReactivationResponse();
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public isApproval = false;
  public objectId: number;

  constructor(translateService: TranslateService) {
    super('vendor-reactivation', translateService);
    Object.assign(this, this.global.routerParams);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorHistoryId = this.global.routerParams.get('vendorHistoryId');
    this.vendorId = this.global.routerParams.get('vendorId');
    this.isApproval = this.global.routerParams.get('isApproval');
    this.objectId = this.global.routerParams.get('objectId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [null],
      registrationNumber: [null],
      reason: [null],
      file: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<VendorReactivationResponse>(
        '/vendor-reactivation/detail',
        new RouteRequestModel(
          this.todo,
          this.objectId != null ? this.objectId : this.vendorId
        )
      )
      .subscribe(vendorReactivationResponse => {
        this.vendorReactivationResponse = vendorReactivationResponse;
        this.workflowModelPrcs = this.vendorReactivationResponse.vendorReactivation
          ? this.vendorReactivationResponse.vendorReactivation.workflowModelPrcs
          : null;

        this.setStateReady();
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/vendor-reactivation']);
  }
}
