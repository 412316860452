<app-layout [isLoading]="formLoading" [header]="header" [backTo]="urlBack">
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge class="cml-2" [color]="badgeColor[contractStatus.code]">
      {{
        contractStatus.translationKey
          ? (contractStatus.translationKey.module.code.toLowerCase() +
              '.' +
              contractStatus.translationKey.key | translate)
          : contractStatus.name
      }}
    </app-badge>
  </ng-template>
  <app-alert-x
    color="WARNING"
    *ngIf="
      !formLoading &&
      contractStatus.code === global.appConstant.cm.CONTRACT_STATUS_EXPIRED
    "
  >
    {{ 'contract-history.detail.alert.contractExpired' | translate }}&nbsp;
    {{ contract.endDate | date: global.appConstant.core.FORMAT_LONG_DATE }}
  </app-alert-x>
  <app-alert-x
    [isShowIcon]="false"
    color="DANGER"
    *ngIf="
      !formLoading &&
      contractHistoryDetailResponse.daysContractExpiredSoon &&
      contractStatus.code !== global.appConstant.cm.CONTRACT_STATUS_CLOSED &&
      contractStatus.code !== global.appConstant.cm.CONTRACT_STATUS_CANCEL &&
      global.userSession.activeUserRole.role.type !==
        global.appConstant.core.ROLE_TYPE_USER
    "
  >
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <div class="pvc pv-clipboard-x-red mr-3"></div>
        <label class="mb-0 text-danger"
          >{{
            'contract-history.detail.alert.contractExpireSoon.prefix'
              | translate
          }}&nbsp;{{
            contractHistoryDetailResponse.daysContractExpiredSoon
          }}&nbsp;{{
            'contract-history.detail.alert.contractExpireSoon.suffix'
              | translate
          }}</label
        >
      </div>
      <app-button color="DANGER" (onClick)="doSendReminderContract()">
        {{ 'contract-history.detail.alert.button.sendReminder' | translate }}
      </app-button>
    </div>
  </app-alert-x>
  <app-alert-x
    [isShowIcon]="false"
    color="DANGER"
    *ngIf="
      !formLoading &&
      contractHistoryDetailResponse.guaranteeList.length &&
      contractStatus.code !== global.appConstant.cm.CONTRACT_STATUS_CLOSED &&
      contractStatus.code !== global.appConstant.cm.CONTRACT_STATUS_EXPIRED &&
      contractStatus.code !== global.appConstant.cm.CONTRACT_STATUS_CANCEL &&
      global.userSession.activeUserRole.role.type !==
        global.appConstant.core.ROLE_TYPE_USER
    "
  >
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <div class="pvc pv-shield-red mr-3"></div>
        <label class="mb-0 text-danger"
          >{{
            'contract-history.detail.alert.guaranteeExpireSoonPrefix'
              | translate
          }}&nbsp;
          {{ contractHistoryDetailResponse.guaranteeList.length }}&nbsp;
          {{
            'contract-history.detail.alert.guaranteeExpireSoonSuffix'
              | translate
          }}</label
        >
      </div>
      <app-button color="DANGER" (onClick)="doSendReminderGuarantee()">
        {{ 'contract-history.detail.alert.button.sendReminder' | translate }}
      </app-button>
    </div>
  </app-alert-x>
  <app-alert-x
    [isShowIcon]="false"
    *ngIf="
      contract.dr &&
      !formLoading &&
      contractStatus.code !== global.appConstant.cm.CONTRACT_STATUS_EXPIRED &&
      contractStatus.code !== global.appConstant.cm.CONTRACT_STATUS_CANCEL
    "
  >
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <div class="pvc pv-box-delivery mr-3"></div>
        <label class="mb-0 text-primary">{{
          'contract-history.detail.alert.monitoringDR' | translate
        }}</label>
      </div>
      <app-button (onClick)="doViewDetailDR()">
        {{ 'contract-history.detail.alert.button.viewDetail' | translate }}
      </app-button>
    </div>
  </app-alert-x>
  <form *ngIf="!formLoading" [formGroup]="formGroup" novalidate>
    <app-contract-request-info
      *ngIf="!formLoading"
      [contractId]="contractId"
      [isShowPrintBtn]="
        contractStatus.code === global.appConstant.cm.CONTRACT_STATUS_CLOSED
      "
    ></app-contract-request-info>
    <app-item-request-released
      *ngIf="
        !formLoading &&
        contractStatus.code === global.appConstant.cm.CONTRACT_STATUS_CANCEL
      "
      [objectList]="prItemReleasedList"
      [isShowPrice]="true"
      [isView]="true"
      [itemTypeList]="contractHistoryDetailResponse.itemTypeList"
      [vendorId]="contract.vendor.id"
    ></app-item-request-released>
    <app-vendor-information
      *ngIf="
        !formLoading &&
        contractStatus.code === global.appConstant.cm.CONTRACT_STATUS_CANCEL
      "
      [vendorId]="contract.vendor.id"
    ></app-vendor-information>
    <app-card
      *ngIf="
        !formLoading &&
        contractStatus.code === global.appConstant.cm.CONTRACT_STATUS_CANCEL
      "
      header="contract-history.detail.card.shippingAddress.title"
      tips="contract-history.detail.card.shippingAddress.tips"
    >
      <app-select
        formControlName="contractShipping"
        [optionList]="contractShippingOptionList"
        selectAllText="{{
          'contract-history.detail.card.shippingAddress.selectAll' | translate
        }}"
        [isMultiple]="true"
        maxHeight="max-content"
        [isShowHover]="false"
      >
        <ng-template #content let-data>
          <div
            style="
              border: 1px solid var(--gray-400);
              border-radius: 5px;
              box-sizing: border-box;
              padding: 1rem;
            "
          >
            <p>{{ data.address.organization.name }}</p>
            <h5>{{ getUserNameList(data.address.addressPicList) }}</h5>
            <p>{{ data.address.addressDetail }}</p>
            <p>{{ data.address.phone }}</p>

            <div class="py-1" style="border-top: 1px dashed var(--gray-400)">
            </div>
            <div class="button-group button-group-right">
              <app-button (onClick)="doViewItem(data)">
                {{ 'contract-history.button.viewItem' | translate }}
              </app-button>
            </div>
          </div>
        </ng-template>
      </app-select>
    </app-card>
    <app-work-definition [contractId]="contractId"></app-work-definition>
    <app-card
      *ngIf="
        !formLoading &&
        contractStatus.code !== global.appConstant.cm.CONTRACT_STATUS_CANCEL
      "
      header="contract-history.detail.card.contractFinalization.title"
      tips="contract-history.detail.card.contractFinalization.tips"
    >
      <form [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'contract-history.detail.form.finalContract' | translate }}
          </label>
          <div class="col-sm-7 text-left">
            <div
              style="
                margin: 0 auto;
                display: flex;
                position: relative;
                border-radius: 5px;
                box-sizing: border-box;
                height: 78px;
                border: 1px solid var(--gray-400);
              "
            >
              <div
                style="
                  width: 60px;
                  height: 60px;
                  overflow: hidden;
                  border-radius: 60px;
                  display: contents;
                "
                class="mr-3"
              >
                <img
                  [attr.src]="
                    '/assets/component/fileuploader/logo-icon/' +
                    model.docFile.extension.toLowerCase() +
                    '.png'
                  "
                  [attr.alt]="model.docFile.fileName"
                  [default]="global.appConstant.core.URL_DEFAULT_ICON"
                  class="img-thumbnail"
                  style="width: 60px; height: 60px; border: none"
                />
              </div>

              <div
                style="flex: 1; padding: 0.25rem 1rem"
                class="d-inline-block"
              >
                <a
                  class="item-content-name"
                  href="#"
                  (click)="
                    doDownload(
                      $event,
                      '/contract-history/file/view/' +
                        model.docFile.uploadedFileName +
                        '/' +
                        model.docFile.fileType.code,
                      model.docFile.fileName
                    )
                  "
                  maxLine
                  [attr.title]="model.docFile.fileName"
                >
                  {{ model.docFile.fileName }}
                </a>
                <ng-container>
                  <span class="d-inline-flex">
                    <strong class="mr-1">{{ model.version }}</strong>
                    <span class="item-content-size">
                      {{ model.docFile.size / 1000000 | number: '1.2-2' }} MB
                    </span>
                  </span>
                  <p class="text-secondary">
                    {{
                      'app-popup-version-history.form.uploadedBy' | translate
                    }}
                    {{ model.uploaderName }}
                    {{ 'app-popup-version-history.form.at' | translate }}
                    {{
                      model.lastModifiedDate
                        | date
                          : global.appConstant.core.FORMAT_SHORT_DATE_AND_TIME
                    }}
                  </p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'contract-history.detail.form.documentVersion' | translate }}
          </label>
          <div class="col-sm-6 text-left">
            <label>{{ model.version }}</label
            >&nbsp;
            <span
              class="hyperlink-text cursor-pointer"
              (click)="doClickVersionHistory()"
            >
              <strong>
                {{
                  'contract-history.detail.from.viewVersionHistory' | translate
                }}
              </strong>
            </span>
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'contract-history.detail.form.contractNumber' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            {{ contract.number }}
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'contract-history.detail.form.contractPeriod' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-date-picker-x size="XL" formControlName="date" [range]="true">
            </app-date-picker-x>
          </div>
        </div>
        <div
          class="form-group row text-sm-left"
          *ngIf="contractDoc?.processedByUser && todo === 'view'"
        >
          <label class="col-sm-3">
            {{
              'contract-history.detail.form.internalContractSignator'
                | translate
            }}
          </label>
          <div class="col-sm-9 text-left">
            {{ contractDoc?.processedByUser?.name || '-' }}
          </div>
        </div>
      </form>
    </app-card>
    <app-contract-document
      *ngIf="
        !formLoading &&
        contractStatus.code !== global.appConstant.cm.CONTRACT_STATUS_CANCEL
      "
      [mode]="todo"
      [object]="contractDoc"
      (onChange)="onChangeContractDoc($event)"
    ></app-contract-document>
    <app-vendor-information
      *ngIf="
        !formLoading &&
        contractStatus.code !== global.appConstant.cm.CONTRACT_STATUS_CANCEL
      "
      [vendorId]="contract.vendor.id"
    ></app-vendor-information>
    <app-card
      *ngIf="
        !formLoading &&
        contractStatus.code === global.appConstant.cm.CONTRACT_STATUS_CLOSED
      "
      header="contract-history.detail.card.contractClosure.title"
      tips="contract-history.detail.card.contractClosure.tips"
    >
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-history.detail.form.closureDate' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{
            contractHistoryDetailResponse.contractClosed.processedDate
              | date: global.appConstant.core.FORMAT_SHORT_DATE
          }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-history.detail.form.description' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{ contractHistoryDetailResponse.contractClosed.description }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-history.detail.form.attachmentFile' | translate }}
        </label>
        <div class="col-sm-6 text-left">
          <app-upload-x
            formControlName="file"
            size="LG"
            [fileUploader]="fileUploader"
          ></app-upload-x>
        </div>
      </div>
    </app-card>
    <app-card
      *ngIf="
        !formLoading &&
        contractStatus.code !== global.appConstant.cm.CONTRACT_STATUS_CANCEL
      "
      header="contract-history.detail.card.activityHistory.title"
      tips="contract-history.detail.card.activityHistory.tips"
    >
      <app-activity-history-view
        [contractId]="contractId"
        [activityType]="1"
      ></app-activity-history-view>
    </app-card>
    <app-card
      *ngIf="
        !formLoading &&
        contractStatus.code === global.appConstant.cm.CONTRACT_STATUS_CANCEL
      "
      header="contract-history.detail.card.contractCancel.title"
      tips="contract-history.detail.card.contractCancel.tips"
    >
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-history.detail.form.contractRequestNumber' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{ contractHistoryDetailResponse.contract.requestNumber }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-history.detail.form.cancelationDate' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{
            contractHistoryDetailResponse.contractCancelation.cancelDate
              | date: date:global.appConstant.core.FORMAT_SHORT_DATE
          }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-history.detail.form.stage' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{
            contractHistoryDetailResponse.contract.workflowModelPrcs.name.replace(
              'Approval - ',
              ''
            )
          }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-history.detail.form.cancelationReason' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{ contractHistoryDetailResponse.contractCancelation.note }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-history.detail.form.attachmentFile' | translate }}
        </label>
        <div class="col-sm-6 text-left">
          <app-upload-x
            formControlName="fileCancel"
            size="LG"
            [fileUploader]="fileUploaderCancel"
          ></app-upload-x>
        </div>
      </div>
    </app-card>
  </form>
  <app-approval-monitoring
    *ngIf="
      !formLoading &&
      contractStatus.code === global.appConstant.cm.CONTRACT_STATUS_CANCEL
    "
    [workflowPrcs]="contract?.workflowPrcs"
    [moduleCode]="global.appConstant.core.MCODE_CONTRACT_REQUEST"
    [approvalPathResponseModel]="
      contractHistoryDetailResponse.approvalPathResponseModel
    "
  ></app-approval-monitoring>

  <div
    class="floating-button mt-5"
    *ngIf="
      !formLoading &&
      contractStatus.code === global.appConstant.cm.CONTRACT_STATUS_DONE &&
      global.userSession.activeUserRole.role.type !==
        global.appConstant.core.ROLE_TYPE_USER
    "
  >
    <div class="floating-button-wrapper">
      <div class="floating-button-content">
        <div class="button-group button-group-center">
          <app-button
            [outline]="true"
            (onClick)="doClose()"
            [disabled]="formSaving"
            >{{ 'app.button.close' | translate }}</app-button
          >
        </div>
      </div>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips *ngIf="!formLoading"></app-tips>
  </ng-template>
</app-layout>
