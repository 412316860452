import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppApprovalPrcsModule } from '../../../core/components/app-approval-prcs/app-approval-prcs.module';
import { SharedModule } from '../../../core/shared/shared.module';
import { VerificationComponent } from './verification.component';
const routes: Routes = [
  {
    path: '',
    component: VerificationComponent,
    data: { breadcrumb: '' },
    children: [{ path: '', loadChildren: () => import('src/app/pages/company-profile/company-profile.module').then(m => m.CompanyProfileModule), data: { breadcrumb: 'on-boarding.breadcrumb.companyProfile' } }]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), AppApprovalPrcsModule],
  declarations: [VerificationComponent],
  providers: []
})
export class VerificationModule {}
