import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Response } from 'src/app/core/model/response-model';
import { UploadFileModel } from '../interface';
import { UploaderFile } from '../model/uploader-file';
export function handleUploadEvent(
  event: HttpEvent<any>,
  uploaderFile: UploaderFile
): void {
  switch (event.type) {
    case HttpEventType.UploadProgress:
      uploaderFile.setUploaderProgress(event);
      break;
    case HttpEventType.ResponseHeader:
      break;
    case HttpEventType.Response:
      handleUploadEventResponse(event, uploaderFile);
      break;
    case HttpEventType.Sent:
      break;
    default:
      uploaderFile.setUploaderProgress(0);
      uploaderFile.setStatus('FAILED');
      break;
  }
}

function handleUploadEventResponse(
  event: HttpResponse<Response<UploadFileModel>>,
  uploaderFile: UploaderFile
): void {
  if (event.status === 200 && event.body !== null) {
    const uploadedFileModel: UploadFileModel = event.body.body;
    patchUploaderFile(uploaderFile, uploadedFileModel);
  } else {
    uploaderFile.setUploaderProgress(0);
    uploaderFile.setStatus('FAILED');
  }
}

function patchUploaderFile(
  uploaderFile: UploaderFile,
  uploadedFileModel: UploadFileModel
): void {
  if (uploadedFileModel.errorMessage) {
    uploaderFile.setUploaderProgress(0);
    uploaderFile.setStatus('FAILED');
    const isExtensionError = uploadedFileModel.errorMessage
      .toLowerCase()
      .includes('extension');
    uploaderFile.setError({
      type: isExtensionError ? 'EXTENSION_ERROR' : 'MAX_SIZE_ERROR',
      message: uploadedFileModel.errorMessage
    });
  } else {
    uploaderFile.setFileWithResponse(uploadedFileModel);
    uploaderFile.setUploaderProgress(100);
    uploaderFile.setStatus('UPLOADED');
    uploaderFile.parent.valueChanges.emit(uploaderFile.parent);
  }
}
