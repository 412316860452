import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { FileObject } from 'src/app/core/components/upload';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Region } from '../../core/bean/region';
import { Vendor } from '../../core/bean/vendor';
import { VendorEntity } from '../../core/bean/vendor-entity';
import { VendorSegmentationHistory } from '../../core/bean/vendor-segmentation-history';
import { VendorType } from '../../core/bean/vendor-type';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { TabModel } from '../../core/model/tab/tab-model';
import { CompanyProfileServie } from '../company-profile/company-profile.service';
import { CompanyProfileDTO } from '../company-profile/dto/company-profile.dto';
import { TabProfileSegmentationDTO } from './dto/tab-profile-segmentation.dto';
import { AppPopupSegmentationVendorComponent } from './popup/app-popup-segmentation-vendor.component';

@Component({
  templateUrl: './tab-profile-segmentation.component.html'
})
export class TabProfileSegmentationComponent extends BaseModuleComponent {
  @ViewChild('selectorVendorSegmentation')
  public tableVendorSegmentation: AppTableComponent;

  public userId: number;
  public companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public tabProfileSegmentationDTO: TabProfileSegmentationDTO =
    new TabProfileSegmentationDTO();
  public vendor: Vendor = new Vendor();
  public now = new Date().getTime();
  public sessionId = null;
  public vendorTypeList: VendorType[] = [];
  public regionList: Region[] = [];
  public vendorEntityList: VendorEntity[] = [];
  public tabSegmentation: TabModel;
  public vendorSegmentationHistory: VendorSegmentationHistory;
  public certificateList: any[] = [];
  public isView: boolean;
  public segmentation: string;
  public isMaxSegmentation = false;
  public isVendorList: boolean;
  public fromOutside: string;

  public tableResponse: TableResponseModel<any>;

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public companyProfileService: CompanyProfileServie
  ) {
    super('tab-profile-segmentation', translate);
  }

  onInit(): void {
    this.getDataFromRouterParam();
    if (this.tabSegmentation.routerParams.get('tabProfileSegmentationDTO')) {
      this.tabProfileSegmentationDTO = this.tabSegmentation.routerParams.get(
        'tabProfileSegmentationDTO'
      );
      this.buildTableResponse();
      this.getAndSetVendorToFormGroup();
      this.checkTable();
    } else {
      this.getDataFromServer();
    }
  }

  public getDataFromServer(): void {
    // tslint:disable-next-line: max-line-length
    this.httpClientService
      .get<TabProfileSegmentationDTO>(
        this.global.userSession.activeUserRole.role.type !==
          this.global.appConstant.core.ROLE_TYPE_VENDOR
          ? '/tab-profile-segmentation/index?id=' +
              this.companyProfileDTO.vendorHistory.id +
              '&todo=' +
              this.todo
          : '/tab-profile-segmentation/index'
      )
      .subscribe(tabProfileSegmentationDTO => {
        this.tabProfileSegmentationDTO = tabProfileSegmentationDTO;
        this.tabSegmentation.routerParams.set(
          'tabProfileSegmentationDTO',
          tabProfileSegmentationDTO
        );
        this.buildFormGroup();
        this.buildTableResponse();
        this.getAndSetVendorToFormGroup();
        this.checkTable();
      });
  }

  public buildFormGroup(): void {
    if (!this.tabSegmentation.isFormGroupReady) {
      this.tabSegmentation.formGroup = this.formBuilder.group({
        isTableEmpty: [null],
        changeTab: [null]
      });
    }
  }

  public buildTableResponse(): void {
    if (
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR &&
      !this.isVendorList &&
      this.companyProfileDTO.vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE
    ) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'segmentationName',
          header: 'table.column.segmentation'
        },
        {
          field: 'qualification.name',
          header: 'table.column.qualification'
        },
        {
          field: 'crudInfo',
          header: 'table.column.status',
          format: FieldFormatEnum.Html,
          customClass: 'text-center'
        }
      ]);
    } else {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'segmentationName',
          header: 'table.column.segmentation'
        },
        {
          field: 'qualification.name',
          header: 'table.column.qualification'
        }
      ]);
    }
    this.tableResponse.setRecords(
      this.tabProfileSegmentationDTO.vendorSegmentationHistoryList
    );
    this.tableResponse.setTotalRecords(
      this.tabProfileSegmentationDTO.vendorSegmentationHistoryList.length
    );
  }

  public checkTable(): void {
    if (
      this.tabProfileSegmentationDTO.vendorSegmentationHistoryList.length !== 0
    ) {
      this.tabSegmentation.formGroup.patchValue({ isTableEmpty: false });
    } else {
      this.tabSegmentation.formGroup.patchValue({ isTableEmpty: true });
    }
  }

  public doAdd(): void {
    if (this.isMaxSegmentation) {
      this.global.alertService.showError(
        'tab-profile-segmentation.msg.error.maxSegmentation'
      );
    } else {
      const todo = 'add';
      const existSegmentationList = [];
      this.tabProfileSegmentationDTO.vendorSegmentationHistoryList.forEach(
        vendorSegmentation => {
          existSegmentationList.push(vendorSegmentation.segmentation);
        }
      );
      this.appPopupService
        .open(
          AppPopupSegmentationVendorComponent,
          { todo, existSegmentationList },
          { size: 'lg', backdrop: 'static' }
        )
        .subscribe(segmentationVendor => {
          this.vendorSegmentationHistory = new VendorSegmentationHistory();
          this.vendorSegmentationHistory.qualification =
            segmentationVendor.qualification;
          this.vendorSegmentationHistory.segmentationList =
            segmentationVendor.segmentation;
          this.vendorSegmentationHistory.vendorSegmentationId =
            segmentationVendor.vendorSegmentationId;
          this.vendorSegmentationHistory.segmentation =
            segmentationVendor.segmentation[
              segmentationVendor.segmentation.length - 1
            ];
          this.segmentation = '';
          segmentationVendor.segmentation.forEach((element, index) => {
            if (index !== segmentationVendor.segmentation.length - 1) {
              this.segmentation += element.name + ' > ';
            } else {
              this.segmentation += element.name;
            }
          });
          this.vendorSegmentationHistory.segmentationName = this.segmentation;
          this.certificateList = segmentationVendor.associationList;
          this.vendorSegmentationHistory.vendorAssociationHistoryList =
            segmentationVendor.associationList;
          this.certificateList.forEach((certificate, index2) => {
            const file = certificate.fileCertificate;
            if (file !== null && file.length !== 0) {
              const fileObjectList: FileObject[] = [];
              fileObjectList.push(certificate.fileCertificate[0]);
              const dataFile = fileObjectList;
              this.vendorSegmentationHistory.vendorAssociationHistoryList[
                index2
              ].file = file[0].isDeleted
                ? null
                : certificate.fileCertificate[0].file;
              this.vendorSegmentationHistory.vendorAssociationHistoryList[
                index2
              ].fileObjectList = !file[0].isDeleted
                ? dataFile
                : file[0].file.id
                ? dataFile
                : [];
            } else {
              this.vendorSegmentationHistory.vendorAssociationHistoryList[
                index2
              ].file = null;
              this.vendorSegmentationHistory.vendorAssociationHistoryList[
                index2
              ].fileObjectList = [];
            }
          });
          this.tabProfileSegmentationDTO.vendorSegmentationHistoryList.push(
            this.vendorSegmentationHistory
          );
          this.tableResponse.setRecords(
            this.tabProfileSegmentationDTO.vendorSegmentationHistoryList
          );
          this.tableResponse.setTotalRecords(
            this.tabProfileSegmentationDTO.vendorSegmentationHistoryList.length
          );
          this.validationAddButton();
          this.tableResponse.reloadClient();
          this.checkTable();

          this.tabSegmentation.routerParams.set(
            'tabProfileSegmentationDTO',
            this.tabProfileSegmentationDTO
          );
        });
    }
  }

  public doEdit(
    vendorSegmentationHistoryEdit: VendorSegmentationHistory
  ): void {
    const todo = 'edit';
    const existSegmentationList = [];
    this.tabProfileSegmentationDTO.vendorSegmentationHistoryList.forEach(
      vendorSegmentation => {
        if (vendorSegmentation !== vendorSegmentationHistoryEdit) {
          existSegmentationList.push(vendorSegmentation.segmentation);
        }
      }
    );
    this.appPopupService
      .open(
        AppPopupSegmentationVendorComponent,
        { todo, existSegmentationList, vendorSegmentationHistoryEdit },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(segmentationVendor => {
        this.tabProfileSegmentationDTO.vendorSegmentationHistoryList.forEach(
          (vendorSegmentation, index) => {
            if (vendorSegmentation === vendorSegmentationHistoryEdit) {
              this.segmentation = '';
              this.tabProfileSegmentationDTO.vendorSegmentationHistoryList[
                index
              ].segmentationList = segmentationVendor.segmentation;
              segmentationVendor.segmentation.forEach((element, index1) => {
                if (index1 !== segmentationVendor.segmentation.length - 1) {
                  this.segmentation += element.name + ' > ';
                } else {
                  this.segmentation += element.name;
                }
                if (index1 === segmentationVendor.segmentation.length - 1) {
                  this.tabProfileSegmentationDTO.vendorSegmentationHistoryList[
                    index
                  ].segmentation = element;
                }
              });
              this.certificateList = segmentationVendor.associationList;
              this.tabProfileSegmentationDTO.vendorSegmentationHistoryList[
                index
              ].segmentationName = this.segmentation;
              this.tabProfileSegmentationDTO.vendorSegmentationHistoryList[
                index
              ].qualification = segmentationVendor.qualification;
              this.tabProfileSegmentationDTO.vendorSegmentationHistoryList[
                index
              ].vendorAssociationHistoryList =
                segmentationVendor.associationList;
              this.tabProfileSegmentationDTO.vendorSegmentationHistoryList[
                index
              ].vendorSegmentationId = segmentationVendor.vendorSegmentationId;
              this.certificateList.forEach((certificate, index2) => {
                const file = certificate.fileCertificate;
                if (file !== null && file.length !== 0) {
                  const fileObjectList: FileObject[] = [];
                  fileObjectList.push(certificate.fileCertificate[0]);
                  const dataFile = fileObjectList;
                  this.tabProfileSegmentationDTO.vendorSegmentationHistoryList[
                    index
                  ].vendorAssociationHistoryList[index2].file =
                    file.length !== undefined
                      ? file[0].isDeleted
                        ? null
                        : certificate.fileCertificate[0].file
                      : certificate.fileCertificate;
                  this.tabProfileSegmentationDTO.vendorSegmentationHistoryList[
                    index
                  ].vendorAssociationHistoryList[index2].fileObjectList =
                    file.length !== undefined
                      ? !file[0].isDeleted
                        ? dataFile
                        : file[0].file.id
                        ? dataFile
                        : []
                      : certificate.fileUploader.length !== 0
                      ? certificate.fileUploader.fileObjectList
                      : [];
                } else {
                  this.tabProfileSegmentationDTO.vendorSegmentationHistoryList[
                    index
                  ].vendorAssociationHistoryList[index2].file = null;
                  this.tabProfileSegmentationDTO.vendorSegmentationHistoryList[
                    index
                  ].vendorAssociationHistoryList[index2].fileObjectList = [];
                }
              });
            }
          }
        );
        this.tableResponse.setRecords(
          this.tabProfileSegmentationDTO.vendorSegmentationHistoryList
        );
        this.tableResponse.setTotalRecords(
          this.tabProfileSegmentationDTO.vendorSegmentationHistoryList.length
        );
        this.tableResponse.reloadClient();

        this.tabSegmentation.routerParams.set(
          'tabProfileSegmentationDTO',
          this.tabProfileSegmentationDTO
        );
      });
  }

  public doView(
    vendorSegmentationHistoryEdit: VendorSegmentationHistory
  ): void {
    const todo = 'detail';
    const vendorSegmentationHistoryList =
      this.tabProfileSegmentationDTO.vendorSegmentationHistoryList;
    const vendorSegmentationList =
      this.tabProfileSegmentationDTO.vendorSegmentationList;
    // tslint:disable-next-line: max-line-length
    this.appPopupService.open(
      AppPopupSegmentationVendorComponent,
      {
        todo,
        vendorSegmentationHistoryEdit,
        vendorSegmentationHistoryList,
        vendorSegmentationList
      },
      { size: 'lg' }
    );
  }

  public doDelete(
    vendorSegmetationHistoryDelete: VendorSegmentationHistory
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tabProfileSegmentationDTO.vendorSegmentationHistoryList.forEach(
            (vendorSegmentation, index) => {
              if (vendorSegmentation === vendorSegmetationHistoryDelete) {
                this.tabProfileSegmentationDTO.vendorSegmentationHistoryList.splice(
                  index,
                  1
                );
              }
            }
          );
          this.tableResponse.page.records =
            this.tabProfileSegmentationDTO.vendorSegmentationHistoryList;
          this.tableResponse.page.totalRecords =
            this.tabProfileSegmentationDTO.vendorSegmentationHistoryList.length;
          this.validationAddButton();
          this.tableResponse.reloadClient();
          this.checkTable();

          this.tabSegmentation.routerParams.set(
            'tabProfileSegmentationDTO',
            this.tabProfileSegmentationDTO
          );
        }
      });
  }

  public getDataFromRouterParam(): void {
    this.tabSegmentation = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_SEGMENTATION);
    this.isView = this.companyProfileService.getTabResponse().isView;
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.todo = this.global.routerParams.get('todo');
    this.companyProfileDTO = this.global.routerParams.get('companyProfileDTO');
  }

  public validationAddButton(): void {
    if (this.tabProfileSegmentationDTO.maxSegmentation !== null) {
      if (
        this.tabProfileSegmentationDTO.vendorSegmentationHistoryList.length ===
        this.tabProfileSegmentationDTO.maxSegmentation
      ) {
        this.isMaxSegmentation = true;
      } else {
        this.isMaxSegmentation = false;
      }
    }
  }

  public getAndSetVendorToFormGroup(): void {
    this.tabSegmentation.formGroup.patchValue(this.tabProfileSegmentationDTO);
    this.validationAddButton();
    this.setStateReady();
  }
}
