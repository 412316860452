<app-popup
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
  header="contract-draft.popup.addUser.header"
>
  <form class="form-horizontal main-row-popup-reviewer" [formGroup]="formGroup" novalidate>
    <app-select
      formControlName="contractReviewerList"
      [optionList]="contractReviewerOptionList"
      selectAllText="{{
        'contract-draft.popup.addUser.selectAll' | translate
      }}"
      [isMultiple]="true"
      isShowBorder="true"
      [search]="true"
    >
      <ng-template #headerFilterGroup>
          <div class="col-sm-5 text-left">
            <app-combo-box-tree
              formControlName="organization"
              stringUrl="/organization"
              size="XL"
              (onChange)="onChangeFilter()"
              placeholder="contract-draft.placeholder.department"
            >
            </app-combo-box-tree>
          </div>
      </ng-template>
      <ng-template *ngIf="!formLoading && !formGroup.isView" #headerButtons>
        <div class="col-sm-5 text-left">
          <app-button 
            color="PRIMARY"
            [outline]="true" 
            (onClick)="doAddNewReviewer()"
          >
            <em class="pir pi-plus-circle"></em>
            {{ 'contract-draft.button.newReviewer' | translate }}
          </app-button>
        </div>
      </ng-template>
      <ng-template #content let-data>
        <div style="margin: 0 auto; display: flex; position: relative">
          <div
            *ngIf="data.reviewByUser?.imgFile"
            style="
              width: 50px;
              height: 50px;
              overflow: hidden;
              border-radius: 50px;
            "
            class="mr-3"
          >
            <img
              style="width: 100%"
              [attr.src]="
                global.config.BACKEND_ADDRESS +
                '/' + moduleCode + '/file/view/' +
                data.reviewByUser?.imgFile?.uploadedFileName +
                '/' +
                data.reviewByUser?.imgFile?.fileType?.code | resolveImgSrc | async
              "
              alt="user image"
              [default]="global.appConstant.core.URL_DEFAULT_PROFILE_IMAGE"
            />
          </div>
          <div
            *ngIf="!data.reviewByUser?.imgFile"
            style="
              width: 50px;
              height: 50px;
              overflow: hidden;
              border-radius: 50px;
            "
            class="mr-3"
          >
            <img
              style="width: 100%"
              [src]="'assets/' + data.reviewByUser?.imgFile?.fileName"
              alt="user image"
              [default]="global.appConstant.core.URL_DEFAULT_PROFILE_IMAGE"
            />
          </div>

          <div class="d-inline-block">
            <ng-container>
              <span class="d-inline-flex">
                <h5 class="mr-1">{{ data.name }}</h5>
                <i class="text-secondary">( {{ data.email }} )</i>
            </span>
            <p class="text-secondary">{{ data.position }}</p>
            </ng-container>
          </div>
        </div>
      </ng-template>
      <!-- <ng-template #actionButtons>
        <div
          *ngIf="!formGroup.isView"
          [ngStyle]="{ cursor: 'pointer', display: 'contents' }"
        >
          <em
            class="pir pi-trash-alt mr-2"
            (click)="doDelete()"
            style="font-size: 24px"
          ></em>
          <span (click)="doDelete()">
            {{ 'app.button.delete' | translate }}
          </span>
        </div>
      </ng-template> -->
    </app-select>
  </form>
  <div class="button-group button-group-center mt-5">
    <app-button [outline]="true" color="SECONDARY" (onClick)="doCancel()">{{
      'app.button.cancel' | translate
    }}</app-button>
    <app-button (onClick)="doAdd()">{{
      'app.button.add' | translate
    }}</app-button>
  </div>
</app-popup>
