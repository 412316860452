import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { AddressBook } from '../../core/bean/address-book';
import { AddressPic } from '../../core/bean/address-pic';
import { Dr } from '../../core/bean/dr';
import { DrShipping } from '../../core/bean/dr-shipping';
import { Organization } from '../../core/bean/organization';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { AppPopupUserService } from '../../core/components/app-popup/app-popup-user/app-popup-user.service';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';

@Component({
  templateUrl: './dr-popup-add-more-address.component.html'
})
export class DrPopupAddMoreAddressComponent extends BaseComponentComponent {
  public addressBook: AddressBook = new AddressBook();
  public addressBookOptionList: OptionListModel<any> = new OptionListModel(
    true,
    'addressDetail'
  );
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);
  public isAddressChecked = false;
  public isLoadingFilter = false;
  @Input() dr: Dr;
  @Input() addressBookList: AddressBook[] = [];
  @Output() onChange: EventEmitter<DrShipping[]> = new EventEmitter();

  constructor(
    public appPopupUserService: AppPopupUserService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService,
    public activeModal: NgbActiveModal
  ) {
    super('dr');
  }

  onInit(): void {
    this.buildFormGroup();
    this.getOptionList();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      organization: [null],
      addressBook: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    const addressBookIdList: number[] = [];
    const organizationIdList: number[] = [];
    if(this.addressBookList != null){
      this.addressBookList.map(addressBook => addressBookIdList.push(addressBook.id))
    }
    this.httpClientService
      .post<AddressBook[]>('/app-popup-address/index', 
        JSON.stringify({
        addressBookIdList: addressBookIdList,
        organizationIdList: organizationIdList
          })
        )
      .subscribe((adressBookList: AddressBook[]) => {
        if (this.addressBookList && this.addressBookList.length !== 0) {
          this.addressBookList.forEach(address => {
            const index = adressBookList.findIndex(x => x.id === address.id);
            if (index > -1) {
              adressBookList.splice(index, 1);
            }
          });
        }

        if (this.global.userSession?.activeUserRole.role.code === 'DELIVERY') {
          adressBookList = adressBookList.filter(
            x => x.user.id === this.global.userSession?.user?.id
          );
        }

        this.addressBookOptionList.setRequestValues(adressBookList);
        this.addressBookOptionList.searchFieldList = [
          'organization.name',
          'user.name',
          'addressDetail',
          'phone'
        ];
        this.setStateReady();
      });
  }

  public getOptionList(): void {
    this.httpClientService
      .get<Organization[]>('/app-popup-address/get-option-list')
      .subscribe(response => {
        if (response != null) {
          this.organizationOptionList.setRequestValues(response);
        }
        this.setStateReady();
      });
  }

  public doOnChange(event: any): void {
    this.isLoadingFilter = true;
    const organizationIdList: number[] = [];
    if (event && event.length > 0) {
      event.map(organization => organizationIdList.push(organization.id));
    }
    const addressBookIdList: number[] = [];
    if(this.addressBookList != null){
      this.addressBookList.map(addressBook => addressBookIdList.push(addressBook.id))
    }
    this.httpClientService
      .post<AddressBook[]>('/app-popup-address/index', 
        JSON.stringify({
        addressBookIdList: addressBookIdList,
        organizationIdList: organizationIdList
          })
        )
      .subscribe(adressBookList => {
        if (this.addressBookList && this.addressBookList.length !== 0) {
          this.addressBookList.forEach(address => {
            const index = adressBookList.findIndex(x => x.id === address.id);
            if (index > -1) {
              adressBookList.splice(index, 1);
            }
          });
        }
        if (this.global.userSession?.activeUserRole.role.code === 'DELIVERY') {
          adressBookList = adressBookList.filter(
            x => x.user.id === this.global.userSession?.user?.id
          );
        }
        this.addressBookOptionList.setRequestValues(adressBookList);
        this.isLoadingFilter = false;
      });
  }

  doChooseOrganization(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(data => {
        this.formGroup.patchValue({
          organization: data[0],
          organizationName: data[0].name
        });
      });
  }

  doSubmit(): void {
    const drShippingList: DrShipping[] = [];
    this.addressBookList = this.formGroup.get('addressBook').value;
    if (this.addressBookList && this.addressBookList.length !== 0) {
      this.addressBookList.forEach(addrs => {
        const drShipping = new DrShipping();
        drShipping.address = addrs;
        drShipping.dr = this.dr;
        drShipping.isFromDr = true;
        drShippingList.push(drShipping);
      });
      this.setStateProcessing();
      this.httpClientService
        .post<Response<AddressBook[]>>('/dr/insert-dr-address', drShippingList)
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.onChange.emit(response.body);
            this.setStateReady();
            this.global.alertService.showSuccess(
              this.global.translateService.instant('app.alert.msg.saveSuccess')
            );
          } else {
            this.onChange.emit(drShippingList);
            this.setStateReady();
            this.global.alertService.showError(response.statusText);
          }
        });
    } else {
      this.global.alertService.showError(
        'dr.validation.chooseAddress',
        '.popup-address'
      );
    }
  }

  public getUserNameList(addressPicList: AddressPic[]): string {
    return addressPicList.map(a => a.user.name).join(', ');
  }
}
