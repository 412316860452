import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppTextFieldModule } from '../app-text-field/app-text-field.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppCountComponent } from './app-count.component';
@NgModule({
  imports: [
    VendorModule,
    AppTooltipWrapperModule,
    AppButtonModule,
    AppTextFieldModule,
    PipesModule
  ],
  declarations: [AppCountComponent],
  exports: [AppCountComponent]
})
export class AppCountModule { }
