import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { WidgetService } from '../widgets.service';
import { WidgetTotalSlaResponse } from './app-widget-total-sla.response';
@Component({
  templateUrl: './app-widget-total-sla.component.html',
  styleUrls: ['./app-widget-total-sla.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetTotalSlaComponent extends BaseComponentComponent {
  public total: number;

  constructor(public widgetService: WidgetService) {
    super('app-widget-total-sla');
  }

  public onInit(): void {
    this.getAndSetTotalSlaInformation();
  }

  public getAndSetTotalSlaInformation(): void {
    this.httpClientService
      .get<WidgetTotalSlaResponse>('/widget-total-sla/index')
      .subscribe(response => {
        if (response.total) {
          this.total = response.total;
        }
        this.setStateReady();
      });
  }

  public goToMoreDetail(): void {
    this.router.navigate(['/pages/procurement-sla']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
