import { BaseEntity } from '../base/base-entity';
import { Menu } from '../bean/menu';
import { ModuleGroup } from '../bean/module-group';

export class MVCGeneratorEntity extends BaseEntity {
    tableName: string;
    fileName: string;
    parentMenu: Menu;
    moduleGroup: ModuleGroup;
}
