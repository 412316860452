import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppAssignmentDelegationModule } from 'src/app/core/components/app-assignment-delegation/app-assignment-delegation.module';
import { AppOfficialReportModule } from 'src/app/core/components/app-official-report/app-official-report.module';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { AppUploadDocumentTableXModule } from '../../core/components/app-upload-document-table-x/app-upload-document-table-x.module';
import { SharedModule } from '../../core/shared/shared.module';
import { PrVerificationEditComponent } from './pr-verification-edit.component';
import { PrVerificationComponent } from './pr-verification.component';
const routes: Routes = [
  { path: '', component: PrVerificationComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: PrVerificationEditComponent,
    data: { breadcrumb: 'pr-verification.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: PrVerificationEditComponent,
    data: { breadcrumb: 'pr-verification.breadcrumb.detail' }
  },
  {
    path: 'detail/:token',
    component: PrVerificationEditComponent,
    data: { breadcrumb: 'pr-verification.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppUploadDocumentTableXModule,
    AppAssignmentDelegationModule,
    AppOfficialReportModule,
    AppApprovalPrcsXModule
  ],
  declarations: [PrVerificationComponent, PrVerificationEditComponent]
})
export class PrVerificationModule {}
