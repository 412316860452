import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { File } from '../../core/bean/file';
import { VendorCategory } from '../../core/bean/vendor-category';
import { AppPopupImportService } from '../../core/components/app-popup/app-popup-import/app-popup-import.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';

@Component({
  templateUrl: './vendor-category.component.html'
})
export class VendorCategoryComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) table: AppTableComponent;

  public tableResponse: TableResponseModel<VendorCategory>;
  public readonly urlDeleteVendorCategory = '/vendor-category/delete';
  public readonly urlImportExcelVendorCategory = 'vendor-category/import-excel';

  constructor(
    translateService: TranslateService,
    public appPopupImportService: AppPopupImportService
  ) {
    super('vendor-category', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      imgFile: [new File()]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      {
        field: 'vendorType.name',
        header: 'table.column.vendorType',
        customClass: 'text-center'
      },
      {
        field: 'name',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/vendor-category/add']);
  }

  public doEdit(vendorCategory: VendorCategory): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('vendorCategoryId', vendorCategory.id);
    this.router.navigate(['/pages/vendor-category/edit']);
  }

  public doDelete(vendorCategory: VendorCategory): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.deleteVendorCategory(vendorCategory);
        }
      });
  }

  public deleteVendorCategory(vendorCategory: VendorCategory): void {
    this.deleteVendorCategoryFromServer(
      this.urlDeleteVendorCategory,
      vendorCategory
    ).subscribe(response => {
      this.showAlertMessage('delete', response);
    });
  }

  public showAlertMessage(
    command: string,
    response: Response<VendorCategory>
  ): void {
    if (response.status === ResponseStatusModel.OK) {
      if (command === 'delete') {
        this.global.alertService.showSuccessDelete();
      }
      if (command === 'save') {
        this.global.alertService.showSuccessSaving();
      }
      this.table.reload();
    } else {
      this.global.alertService.showError(response.statusText);
    }
  }

  public deleteVendorCategoryFromServer(
    urlDeleteVendorCategory: string,
    vendorCategory: VendorCategory
  ): Observable<Response<VendorCategory>> {
    return this.httpClientService.post<Response<VendorCategory>>(
      urlDeleteVendorCategory,
      vendorCategory
    );
  }
}
