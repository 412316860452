<app-layout [isLoading]="formLoading" header="rfq.title">
  <app-table-xx
    [model]="tableResponse"
    stringUrl="/rfq/index"
    header="{{ 'rfq.table.header' | translate }}"
    (onClick)="doClick($event)"
    isShowEditTable="true"
  >
    <ng-template #headerFilterGroup>
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <div class="form-group row text-sm-left">
          <div class="col-lg-12 row" style="margin-bottom: -1.5rem">
            <label
              class="cml-3 d-flex align-items-center"
              style="margin-top: auto"
              >{{ 'rfq.form.date' | translate }}</label
            >
            <div class="cml-3 text-left">
              <app-date-picker-x
                size="XL"
                formControlName="date"
                [range]="true"
                (onChange)="onChangeFilter()"
              >
              </app-date-picker-x>
            </div>
            <label
              class="cml-5 d-flex align-items-center"
              style="margin-top: auto"
              >{{ 'rfq.form.dueDate' | translate }}</label
            >
            <div class="cml-3 text-left">
              <app-date-picker-x
                size="XL"
                formControlName="dueDate"
                [range]="true"
                (onChange)="onChangeFilter()"
              >
              </app-date-picker-x>
            </div>
            <div class="cml-3 col-sm-2 text-left">
              <app-dropdown-select
                type="CHOSEN"
                size="XL"
                formControlName="organizationList"
                [optionList]="organizationOptionList"
                (onChange)="onChangeFilter($event)"
                showLimitValue="1"
                placeholder="rfq.placeholder.department"
              >
                <ng-template #selectAll>
                  <p> {{ 'rfq.placeholder.allDepartment' | translate }} </p>
                </ng-template>
              </app-dropdown-select>
            </div>
            <div class="col-sm-2 text-left">
              <app-dropdown-select
                type="CHOSEN"
                size="XL"
                formControlName="statusList"
                [optionList]="rfqStatusOptionList"
                (onChange)="onChangeFilter($event)"
                showLimitValue="1"
                placeholder="rfq.placeholder.status"
              >
                <ng-template #selectAll>
                  <p> {{ 'rfq.placeholder.allStatus' | translate }} </p>
                </ng-template>
                <ng-template #option let-data>
                  <!-- <p> {{ (data.translationKey ? (data.translationKey.module.code.toLowerCase() 
                                + '.'
                                + data.translationKey.key) : data.name ) | translate }} </p> -->
                  <p> {{ data?.name }} </p>
                </ng-template>
              </app-dropdown-select>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </form>
    </ng-template>

    <ng-template #headerButtons>
      <app-button (onClick)="doAdd()">
        <em class="pir pi-plus-circle"></em>
        {{ 'rfq.index.button.newRfq' | translate }}
      </app-button>
    </ng-template>
    <ng-template #actionButtons let-rowData>
      <app-button
        size="TN"
        (onClick)="doDelete(rowData)"
        title="{{ 'app.tooltip.delete' | translate }}"
      >
        <em class="pir pi-trash-alt"></em>
        {{ 'app.button.delete' | translate }}
      </app-button>
    </ng-template>
  </app-table-xx>
</app-layout>
