import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { ModuleGroup } from './../../core/bean/module-group';
import { WorkflowCondition } from './../../core/bean/workflow-condition';
import { WorkflowConditionView } from './../../core/view/entity/bean/workflow-condition-view';
@Component({
  templateUrl: './workflow-condition.component.html'
})
export class WorkflowConditionComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<WorkflowConditionView>;
  public moduleGroupList: ModuleGroup[];
  constructor(translateService: TranslateService) {
    super('workflow-condition', translateService);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.doSetModuleGroupList();
    this.setStateReady();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      moduleGroup: [null]
    });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'workflow.name',
        header: 'table.column.workflow',
        customClass: 'text-center'
      },
      {
        field: 'moduleGroup.name',
        header: 'table.column.moduleGroup',
        customClass: 'text-center'
      },
      {
        field: 'workflowConditionDetailText',
        header: 'table.column.workflowConditionDetail',
        format: FieldFormatEnum.Html,
        customClass: 'white-space-normal'
      }
    ]);
  }

  public doSetModuleGroupList(): void {
    this.httpClientService
      .get<ModuleGroup[]>('/workflow-condition/module-group-list', {})
      .subscribe(moduleGroupList => {
        this.moduleGroupList = moduleGroupList;
      });
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/workflow-condition/add']);
  }

  public doEdit(workflowConditionView: WorkflowConditionView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.httpClientService
      .get<WorkflowCondition>(
        workflowConditionView.id
          ? '/workflow-condition/get-workflow-condition?id=' +
              workflowConditionView.id
          : '/workflow-condition/get-workflow-condition'
      )
      .subscribe(workflowCondition => {
        this.global.routerParams.set(
          'workflowConditionId',
          workflowCondition.id
        );
        this.global.routerParams.set(
          'workflowId',
          workflowCondition.workflow.id
        );
        this.router.navigate(['/pages/workflow-condition/edit']);
        this.setStateReady();
      });
  }

  public doDelete(workflowConditionView: WorkflowConditionView): void {
    this.httpClientService
      .get<WorkflowCondition>(
        workflowConditionView.id
          ? '/workflow-condition/get-workflow-condition?id=' +
              workflowConditionView.id
          : '/workflow-condition/get-workflow-condition'
      )
      .subscribe(workflowCondition => {
        this.global.modalService
          .deleteConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.httpClientService
                .post<Response<WorkflowCondition>>(
                  '/workflow-condition/delete',
                  workflowCondition
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.global.alertService.showSuccessDelete();
                    this.tableResponse.reload();
                  } else {
                    this.global.alertService.showError(response.status);
                  }
                });
            }
          });
        this.setStateReady();
      });
  }

  public onChangeModuleGroup(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
