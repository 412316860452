import { BaseEntity } from '../base/base-entity';
import { Contract } from '../bean/contract';
import { ContractWorklistStatus } from '../bean/contract-worklist-status';
import { File } from '../bean/file';

export class ContractNegotiationEntity extends BaseEntity {
  contract: Contract;
  docFile: File;
  vendorNegotiationStatus: ContractWorklistStatus;
  userNegotiationStatus: ContractWorklistStatus;
  isVendorRevised: boolean;
  processedDate: Date;
  sequence: number;
}
