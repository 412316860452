import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppAccordionModule } from '../../app-accordion/app-accordion.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppChosenModule } from '../../app-chosen/app-chosen.module';
import { AppComboBoxModule } from '../../app-combo-box/app-combo-box.module';
import { AppDatepickerModule } from '../../app-date-picker/app-date-picker.module';
import { AppRadioModule } from '../../app-radio/app-radio.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppTextAreaModule } from '../../app-text-area/app-text-area.module';
import { AppTextFieldModule } from '../../app-text-field/app-text-field.module';
import { AppToggleSwitchModule } from '../../app-toggle-switch/app-toggle-switch.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupProcurementScopeWorkComponent } from './app-popup-procurement-scope-work.component';
import { AppPopupProcurementScopeWorkService } from './app-popup-procurement-scope-work.service';
@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppChosenModule,
    AppTextFieldModule,
    AppTextAreaModule,
    AppButtonModule,
    AppComboBoxModule,
    AppRadioModule,
    AppDatepickerModule,
    AppToggleSwitchModule,
    AppAccordionModule,
    AppTableModule
  ],
  declarations: [AppPopupProcurementScopeWorkComponent],
  entryComponents: [AppPopupProcurementScopeWorkComponent],
  providers: [AppPopupProcurementScopeWorkService],
  exports: [AppPopupProcurementScopeWorkComponent]
})
export class AppPopupProcurementScopeWorkModule {}
