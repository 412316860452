import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Currency } from '../../core/bean/currency';
import { RefutationSubmission } from '../../core/bean/refutation-submission';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { RefutationVendorRefutationResponse } from './refutation-vendor-refutation-response';

@Component({
  templateUrl: './app-popup-vendor-refutation.component.html'
})
export class AppPopupVendorRefutationComponent extends BaseModuleComponent {
  @Input() refutationVendorId: number;
  @Input() vendor: string;

  public refutationSubmission: RefutationSubmission;
  public fileUploader: FileUploader = new FileUploader(
    '/refutation/',
    'refutation.form.refutationEvidence',
    this.global.appConstant.fileType.DOC_REFUTATION,
    false,
    this.global.config.parameterModel.maxFileRefutation
  );
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );

  constructor(translateService: TranslateService) {
    super('refutation', translateService);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      amount: [null],
      date: [null],
      fileList: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<RefutationVendorRefutationResponse>(
        '/refutation/get-refutation-vendor/' + this.refutationVendorId
      )
      .subscribe((response: RefutationVendorRefutationResponse) => {
        this.refutationSubmission = response.refutationSubmission;
        this.currencyOptionList.setRequestValues(response.currencyList);
        const { amount, startDate, endDate } = response.refutationSubmission;
        this.fileUploader.setFileList(
          response.refutationSubmissionDocList.map(
            refutationSubmissionDoc => refutationSubmissionDoc.file
          )
        );
        this.formGroup.patchValue({
          amount: { price: amount },
          date: { from: startDate, to: endDate },
          fileList: this.fileUploader.fileObjectList
        });
        this.setStateReady();
        this.setViewOnly();
      });
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }
}
