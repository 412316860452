import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Period } from '../../core/bean/period';
import { VendorPerformanceOverall } from '../../core/bean/vendor-performance-overall';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { RecapPrintService } from '../../core/services/recap-print.service';
@Component({
  templateUrl: './all-performance.component.html',
  styles: ['.width-company-name { min-width : 250px; white-space: normal ! important }'],
  encapsulation: ViewEncapsulation.None
})
export class AllPerformanceComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<VendorPerformanceOverall>;
  public periodList: OptionListModel<Period> = new OptionListModel();
  public yearList: OptionListModel<any> = new OptionListModel(false);
  public isLoading: boolean;
  constructor(translateService: TranslateService, public recapPrintService: RecapPrintService) {
    super('all-performance', translateService);
  }

  public onInit(): void {
    this.setOptionList();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
      this.formGroup = this.formBuilder.group({
        vendorCode: [null],
        vendorName: [null],
        period: [null],
        year: [null]
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'vendor.name', header: 'table.column.vendorName', customClass: 'width-company-name' },
      { field: 'vendor.code', header: 'table.column.vendorCode', customClass: 'text-center' },
      { field: 'vendor.vendorType.name', header: 'table.column.vendorType', customClass: 'text-center' },
      { field: 'vendor.email', header: 'table.column.email' },
      { field: 'period.name', header: 'table.column.period', customClass: 'text-center'},
      { field: 'startYear', header: 'table.column.year', customClass: 'text-center'},
      { field: 'rating', header: 'table.column.rating',
        format: FieldFormatEnum.Rating, customClass: 'text-center'  }
    ]);
  }

  public doView(vendorPerformanceOverall: VendorPerformanceOverall): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('vendorPerformanceOverallId', vendorPerformanceOverall.id);
    this.router.navigate(['/pages/all-performance/detail']);
  }

  public doApply(): void {
    const customData = {
        vendorName: this.formGroup.value.vendorName,
        vendorCode: this.formGroup.value.vendorCode,
        period: this.formGroup.value.period,
        year: this.formGroup.value.year ? this.formGroup.value.year.value : null
    };
    this.tableResponse.setCustomData(customData);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }

  public doGenerate(): void {
    this.global.routerParams.clear();
    this.router.navigate(['/pages/all-performance/generate']);
  }

  public setOptionList(): void {
      this.httpClientService.get('/all-performance/get-period-list').subscribe((periodList: Period[]) => {
        this.periodList.setRequestValues(periodList);
      });

      const yearList = [
          { name: new Date().getFullYear().toString() , value: new Date().getFullYear() },
          { name: (new Date().getFullYear() - 1).toString() , value: new Date().getFullYear() - 1 },
          { name: (new Date().getFullYear() - 2).toString() , value: new Date().getFullYear() - 2 },
          { name: (new Date().getFullYear() - 3).toString() , value: new Date().getFullYear() - 3 },
          { name: (new Date().getFullYear() - 4).toString() , value: new Date().getFullYear() - 4 },
          { name: (new Date().getFullYear() - 5).toString() , value: new Date().getFullYear() - 5 }
      ];
      this.yearList.setRequestValues(yearList);
  }

  public doExport(): void {
    this.isLoading = true;
    this.recapPrintService.print('/all-performance/recap', this.tableResponse.request).subscribe(() => {
      this.isLoading = false;
    });
  }
}
