import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { CoaBjbEditAddComponent } from './coa-bjb-edit-add.component';
import { CoaBjbComponent } from './coa-bjb.component';

export const routes = [
  { path: '', component: CoaBjbComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: CoaBjbEditAddComponent,
    data: { breadcrumb: 'coa-bjb.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: CoaBjbEditAddComponent,
    data: { breadcrumb: 'coa-bjb.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [CoaBjbComponent, CoaBjbEditAddComponent],
  providers: []
})
export class CoaBJBModule {}
