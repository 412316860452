import { DatePickerDate } from '../model';
export const createDatePickerDate = (date: Date): DatePickerDate => {
  const datePickerItem = new DatePickerDate();
  const newDate = new Date(date);
  const dateNumber = newDate.getDate();
  const minutes = newDate.getMinutes();
  const hours = newDate.getHours();
  datePickerItem.date = new Date(newDate);
  datePickerItem.dateString = createDateString(newDate);
  datePickerItem.timeString = `${hours}/${minutes}`;
  datePickerItem.dateNumber = dateNumber;
  datePickerItem.isCurrentDate =
    datePickerItem.dateString === createDateString(new Date());
  datePickerItem.year = newDate.getFullYear();
  datePickerItem.month = newDate.getMonth();
  datePickerItem.dateTime = newDate.getTime();
  datePickerItem.day = newDate.getDay();
  return datePickerItem;
};

export const createDateString = (dateString: Date | string): number => {
  const date = new Date(dateString);
  const month = date.getMonth();
  const dateNumber = date.getDate();
  return +`${date.getFullYear()}${month < 10 ? '0' + month : month}${
    dateNumber < 10 ? '0' + dateNumber : dateNumber
  }`;
};
