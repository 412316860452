import { BaseEntity } from '../base/base-entity';
import { Threshold } from './../bean/threshold';

export class ThresholdRangeEntity extends BaseEntity {
  isDeletable: boolean; //transient

  threshold: Threshold;
  from: number;
  to: number;
  isInfinite: boolean;
}
