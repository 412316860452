import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppFullfilledModule } from '../../core/components/app-fullfilled/app-fullfilled.module';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupComplianceTestMasterComponent } from './app-popup-compliance-test-master.component';
import { ComplianceTestMasterEditComponent } from './compliance-test-master-edit.component';
import { ComplianceTestMasterComponent } from './compliance-test-master.component';

const routes = [
  {
    path: '',
    component: ComplianceTestMasterComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'detail',
    component: ComplianceTestMasterEditComponent,
    data: { breadcrumb: 'compliance-test-master.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), AppFullfilledModule],
  declarations: [
    ComplianceTestMasterComponent,
    ComplianceTestMasterEditComponent,
    AppPopupComplianceTestMasterComponent
  ],
  entryComponents: [AppPopupComplianceTestMasterComponent]
})
export class ComplianceTestMasterModule {}
