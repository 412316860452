import { NgModule } from '@angular/core';
import { AppBadgeModule } from '../../../../../core/components/app-badge/app-badge.module';
import { AppButtonModule } from '../../../../../core/components/app-button/app-button.module';
import { AppChartModule } from '../../../../../core/components/app-chart/app-chart.module';
import { AppCheckBoxModule } from '../../../../../core/components/app-check-box/app-check-box.module';
import { AppDropdownSelectModule } from '../../../../../core/components/app-dropdown-select/app-dropdown-select.module';
import { AppPopupModule } from '../../../../../core/components/app-popup/app-popup.module';
import { PipesModule } from '../../../../../core/pipe/pipe.module';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetSavingAndSpendingByTopItemPopupComponent } from './widget-saving-and-spending-by-top-item-popup.component';
import { WidgetSavingAndSpendingByTopItemComponent } from './widget-saving-and-spending-by-top-item.component';

@NgModule({
  imports: [
    VendorModule,
    WidgetCardModule,
    AppChartModule,
    AppButtonModule,
    AppDropdownSelectModule,
    AppCheckBoxModule,
    PipesModule,
    AppPopupModule,
    AppBadgeModule
  ],
  declarations: [
    WidgetSavingAndSpendingByTopItemComponent,
    WidgetSavingAndSpendingByTopItemPopupComponent
  ],
  entryComponents: [WidgetSavingAndSpendingByTopItemComponent],
  exports: [WidgetSavingAndSpendingByTopItemComponent]
})
export class WidgetSavingAndSpendingByTopItemModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetSavingAndSpendingByTopItemComponent.moduleCode,
      WidgetSavingAndSpendingByTopItemComponent,
      'w-50'
    );
  }
}
