import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ItemType } from '../../bean/item-type';
import { PrItem } from '../../bean/pr-item';
import { ProcurementItem } from '../../bean/procurement-item';
import { OptionListModel } from '../../model/option-list-model';
import { AppPopupChoosePrService } from '../app-popup/app-popup-choose-pr/app-popup-choose-pr.service';
import { AppPopupPrItemInfoService } from '../app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { AppPopupService } from '../app-popup/app-popup.service';
import { TablePluginData } from '../table/interface/table-plugin-data';
import { TableResponseModel } from '../table/model/table-response-model';
import { AppItemRequestInitiationCatalogModel } from './app-item-request-initiation-catalog-model';
import { AppPopupItemEditComponent } from './app-popup-item-edit.component';

@Component({
  selector: 'app-item-request',
  templateUrl: './app-item-request.component.html'
})
export class AppItemRequestComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() public objectList: ProcurementItem[] = [];
  @Input() public isShowPrice: boolean;
  @Input() public isView: boolean;
  @Input() public itemTypeList: ItemType[] = [];
  @Input() public isShowRequired = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() public baseModuleCode: String;
  @Input() public initiationType: String;

  // need these input if initiationType is BLANKET_ORDER
  @Input() public todo: String;
  @Input() public prResponse: AppItemRequestInitiationCatalogModel;
  @Input() public initiationFormGroup: any;

  public parentList: PrItem[] = [];
  public tableResponse: TableResponseModel<ProcurementItem>;
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    false
  );

  constructor(
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public appPopupChoosePrService: AppPopupChoosePrService,
    public translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('app-item-request');
  }

  public onInit(): void {
    this.setOptionList();
    this.buildFormGroup();
    if (this.initiationType === undefined || this.initiationType === null) {
      this.initiationType = this.global.routerParams.get('initiationType');
    }
    if (this.initiationType === 'BLANKET_ORDER') {
      this.buildTableResponseBlanketOrder();
      if (!(this.prResponse === undefined || this.prResponse === null)) {
        if (this.prResponse.prItemList.length > 0) {
          this.setItemBlanketOrder();
        }
      }
    } else {
      this.buildTableResponse();
    }
    this.doSetAmount();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.initiationFormGroup);
  }

  public buildTableResponse(): void {
    if (this.isShowPrice) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'prItem.prItemImageList',
          header: 'table.column.thumbnail',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): File => {
                if (tablePluginData.value && tablePluginData.value.length > 0) {
                  return tablePluginData.value[0].file;
                }
                return null;
              }
            },
            {
              name: 'img',
              classNameMap: {
                MATERIAL: 'pvr pv-box',
                JASA: 'pvr pv-tools'
              },
              fieldClassName: 'prItem.item.itemType.code',
              onClick: this.doViewItem.bind(this)
            }
          ],
          isSortable: false,
          isSearchTable: false
        },
        {
          field: 'prItem.pr.code',
          header: 'table.column.prNumber',
          plugins: { name: 'hyperlink', onClick: this.doViewItem.bind(this) }
        },
        {
          field: 'prItem.code',
          header: 'table.column.prLine'
        },
        {
          field: 'prItem.pr.requiredDate',
          header: 'table.column.requiredDate',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'prItem.pr.organization.name',
          header: 'table.column.departement'
        },
        {
          field: 'prItem.item.name',
          header: 'table.column.itemName'
        },
        {
          field: 'prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'prItem.item.itemType.code',
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (
                  tablePluginData.row.record.prItem.item.itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                ) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.good'
                  );
                } else {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.service'
                  );
                }
              }
            }
          }
        },
        {
          field: 'prItem.quantity',
          header: 'table.column.quantity',
          className: 'text-right',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            isView: true
          }
        },
        {
          field: 'prItem.price',
          header: 'table.column.price',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: ' text-right'
        },
        {
          field: 'prItem.totalPrice',
          header: 'table.column.totalPrice',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: 'text-right'
        }
      ]);
    } else if (!this.isShowPrice) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'prItem.prItemImageList',
          header: 'table.column.thumbnail',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): File => {
                if (tablePluginData.value && tablePluginData.value.length > 0) {
                  return tablePluginData.value[0].file;
                }
                return null;
              }
            },
            {
              name: 'img',
              classNameMap: {
                MATERIAL: 'pvr pv-box',
                JASA: 'pvr pv-tools'
              },
              fieldClassName: 'item.itemType.code',
              onClick: this.doViewItem.bind(this)
            }
          ],
          isSortable: false,
          isSearchTable: false
        },
        {
          field: 'prItem.pr.requiredDate',
          header: 'table.column.requiredDate',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'prItem.item.name',
          header: 'table.column.itemName'
        },
        {
          field: 'prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'prItem.item.itemType.code',
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (
                  tablePluginData.row.record.prItem.item.itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                ) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.good'
                  );
                } else {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.service'
                  );
                }
              }
            }
          }
        },
        {
          field: 'prItem.quantity',
          header: 'table.column.quantity',
          className: 'text-right',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              return this.global.converterService.convertDecimal(
                tablePlugin.value
              );
            }
          }
        },
        {
          field: 'prItem.item.uom.name',
          header: 'table.column.uom'
        }
      ]);
    }
    this.tableResponse.setRecordList(this.objectList);
    this.tableResponse.reload();
  }

  public buildTableResponseBlanketOrder(): void {
    if (this.isShowPrice) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'prItem.prItemImageList',
          header: 'table.column.thumbnail',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): File => {
                if (tablePluginData.value && tablePluginData.value.length > 0) {
                  return tablePluginData.value[0].file;
                }
                return null;
              }
            },
            {
              name: 'img',
              classNameMap: {
                MATERIAL: 'pvr pv-box',
                JASA: 'pvr pv-tools'
              },
              fieldClassName: 'prItem.item.itemType.code',
              onClick: this.doViewItem.bind(this)
            }
          ],
          isSortable: false,
          isSearchTable: false
        },
        {
          field: 'prItem.code',
          header: 'table.column.itemLine'
        },
        {
          field: 'prItem.item.name',
          header: 'table.column.itemName'
        },
        {
          field: 'prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'prItem.item.itemType.code',
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (
                  tablePluginData.row.record.prItem.item.itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                ) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.good'
                  );
                } else {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.service'
                  );
                }
              }
            }
          }
        },
        {
          field: 'prItem.quantity',
          header: 'table.column.quantity',
          className: 'text-right',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            isView: true
          }
        },
        {
          field: 'prItem.price',
          header: 'table.column.price',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: ' text-right'
        },
        {
          field: 'prItem.totalPrice',
          header: 'table.column.totalPrice',
          plugins: {
            name: 'default',
            type: 'currency'
          },
          className: 'text-right'
        }
      ]);
    } else if (!this.isShowPrice) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'prItem.prItemImageList',
          header: 'table.column.thumbnail',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): File => {
                if (tablePluginData.value && tablePluginData.value.length > 0) {
                  return tablePluginData.value[0].file;
                }
                return null;
              }
            },
            {
              name: 'img',
              classNameMap: {
                MATERIAL: 'pvr pv-box',
                JASA: 'pvr pv-tools'
              },
              fieldClassName: 'item.itemType.code',
              onClick: this.doViewItem.bind(this)
            }
          ],
          isSortable: false,
          isSearchTable: false
        },
        {
          field: 'prItem.item.name',
          header: 'table.column.itemName'
        },
        {
          field: 'prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'prItem.item.itemType.code',
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (
                  tablePluginData.row.record.prItem.item.itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                ) {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.good'
                  );
                } else {
                  return this.translateService.instant(
                    'dynamic-master-attribute.itemType.service'
                  );
                }
              }
            }
          }
        },
        {
          field: 'prItem.quantity',
          header: 'table.column.quantity',
          className: 'text-right',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              return this.global.converterService.convertDecimal(
                tablePlugin.value
              );
            }
          }
        },
        {
          field: 'prItem.item.uom.name',
          header: 'table.column.uom'
        }
      ]);
    }
    this.tableResponse.setRecordList(this.objectList);
    this.tableResponse.reload();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      itemType: [null],
      amount: [null],
      goodsAmount: [null],
      serviceAmount: [null]
    });
  }

  public setOptionList(): void {
    this.itemTypeOptionList.setRequestValues(this.itemTypeList);
  }

  public setItemBlanketOrder(): void {
    this.objectList = [];
    // this.parentList = response.parentList;
    this.prResponse.prItemList.forEach(prItem => {
      const procurementItem: ProcurementItem = new ProcurementItem();
      procurementItem.prItem = prItem;
      this.objectList.push(procurementItem);
    });
    if (this.objectList && this.objectList.length > 1) {
      this.objectList = this.objectList.sort(
        (procurementItemA, procurementItemB) => {
          if (
            procurementItemA?.prItem?.pr?.code >
              procurementItemB?.prItem?.pr?.code ||
            (procurementItemA?.prItem?.pr?.id ===
              procurementItemB?.prItem?.pr?.id &&
              procurementItemA?.prItem?.code >= procurementItemB?.prItem?.code)
          ) {
            return 1;
          }
          if (
            procurementItemA?.prItem?.pr?.code <
              procurementItemB?.prItem?.pr?.code ||
            (procurementItemA?.prItem?.pr?.id ===
              procurementItemB?.prItem?.pr?.id &&
              procurementItemA?.prItem?.code <= procurementItemB?.prItem?.code)
          ) {
            return -1;
          }
        }
      );
    }
    this.tableResponse.setRecordList(this.objectList);
    this.tableResponse.reload();
    this.doSetAmount();
    this.onChange.emit({
      procurementItemList: this.tableResponse.getRecordList(),
      amountValue: this.formGroup.value
    });
  }

  public onChangeItem(): void {
    const itemType = this.formGroup.value.itemType;
    if (itemType) {
      const procItemList = [];
      this.objectList.forEach((procItem: ProcurementItem) => {
        if (procItem.prItem.item.itemType.id === itemType.id) {
          procItemList.push(procItem);
        }
      });
      this.tableResponse.setRecordList(procItemList);
    } else {
      this.tableResponse.setRecordList(this.objectList);
    }
    this.tableResponse.reload();
    this.doSetAmount();
  }

  public doSetAmount(): void {
    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;
    this.tableResponse
      .getRecordList()
      .forEach((procurementItem: ProcurementItem) => {
        if (
          procurementItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
        ) {
          goodsAmountTotal =
            +goodsAmountTotal + +procurementItem.prItem.totalPrice;
        }
        if (
          procurementItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
        ) {
          serviceAmountTotal =
            +serviceAmountTotal + +procurementItem.prItem.totalPrice;
        }
      });
    this.formGroup.patchValue({
      goodsAmount: goodsAmountTotal,
      serviceAmount: serviceAmountTotal,
      amount: +goodsAmountTotal + +serviceAmountTotal
    });
  }

  public doViewItem(procurementItem: ProcurementItem): void {
    if (this.initiationType !== 'BLANKET_ORDER') {
      this.appPopupPrItemInfoService.open(
        procurementItem.prItem.id,
        this.isShowPrice
      );
    } else {
      this.appPopupService
        .open(
          AppPopupItemEditComponent,
          { procurementItem, isView: this.isView },
          { size: 'xl' }
        )
        .subscribe((procurementItem: ProcurementItem) => {
          console.log(procurementItem);
          const indexOfRecord = this.objectList.findIndex(
            r => r.prItem.code === procurementItem.prItem.code
          );
          this.objectList[indexOfRecord] = procurementItem;

          this.tableResponse.setRecordList(this.objectList);
          this.tableResponse.reload();
          this.doSetAmount();
        });
    }
  }

  public doAddItem(): void {
    if (this.initiationType === 'BLANKET_ORDER') {
      this.doAddItemCatalog();
    } else {
      this.doAddListPr();
    }
  }

  public doAddListPr(): void {
    const prItems = [];
    if (this.objectList && this.objectList.length > 0) {
      this.objectList.forEach(procItem => {
        prItems.push(procItem.prItem);
      });
    }
    this.appPopupChoosePrService
      .open(this.parentList, this.baseModuleCode)
      .subscribe(response => {
        this.objectList = [];
        this.parentList = response.parentList;
        response.prItemList.forEach(prItem => {
          const procurementItem: ProcurementItem = new ProcurementItem();
          procurementItem.prItem = prItem;
          this.objectList.push(procurementItem);
        });
        if (this.objectList && this.objectList.length > 1) {
          this.objectList = this.objectList.sort(
            (procurementItemA, procurementItemB) => {
              if (
                procurementItemA?.prItem?.pr.code >
                  procurementItemB?.prItem?.pr.code ||
                (procurementItemA?.prItem?.pr.id ===
                  procurementItemB?.prItem?.pr.id &&
                  procurementItemA?.prItem.code >= procurementItemB.prItem.code)
              ) {
                return 1;
              }
              if (
                procurementItemA?.prItem?.pr.code <
                  procurementItemB?.prItem?.pr.code ||
                (procurementItemA?.prItem?.pr.id ===
                  procurementItemB?.prItem?.pr.id &&
                  procurementItemA?.prItem.code <=
                    procurementItemB?.prItem.code)
              ) {
                return -1;
              }
            }
          );
        }
        this.tableResponse.setRecordList(this.objectList);
        this.tableResponse.reload();
        this.doSetAmount();
        this.onChange.emit({
          procurementItemList: this.tableResponse.getRecordList(),
          amountValue: this.formGroup.value
        });
      });
  }

  public doAddItemCatalog(): void {
    this.global.routerParams.set(
      'documentList',
      this.formGroup.value.documentList
    );
    this.global.routerParams.set('todo', this.todo);
    this.global.routerParams.set('prResponse', this.prResponse);
    this.global.routerParams.set('backToUrl', this.router.url);
    this.global.routerParams.set('urlBackOutside', '/pages/initiation');
    this.initiationFormGroup.reset();
    this.router.navigate(['/pages/initiation/add-item']);
  }

  public doDeleteItem(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach((record: ProcurementItem) => {
            const indexOfRecord = this.tableResponse
              .getRecordList()
              .findIndex(r => r.prItem.code === record.prItem.code);
            this.tableResponse.getRecordList().splice(indexOfRecord, 1);

            const indexOfPrItem = this.prResponse.prItemList.findIndex(
              prI => prI.code === record.prItem.code
            );
            if (indexOfPrItem !== -1) {
              this.prResponse.prItemList.splice(indexOfPrItem, 1);
            }
          });
          this.tableResponse.reload();
          this.doSetAmount();
          this.onChange.emit({
            procurementItemList: this.tableResponse.getRecordList(),
            amountValue: this.formGroup.value
          });
          this.parentList = this.tableResponse
            .getRecordList()
            .map((procurementItem: ProcurementItem) => procurementItem.prItem);
        }
      });
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
          '.' +
          data.translationKey.key || '-'
      );
    } else {
      return data?.name;
    }
  }
}
