import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class WindowPrintService {

    public print(): any {
        // tslint:disable-next-line: only-arrow-functions
        const PrintElements = (function(): any {
            'use strict';

            const hideFromPrintClass = 'pe-no-print';
            const preservePrintClass = 'pe-preserve-print';
            const preserveAncestorClass = 'pe-preserve-ancestor';
            const bodyElementName = 'BODY';

            // tslint:disable-next-line: only-arrow-functions
            const hide = function(element): any {
                if (!element.classList.contains(preservePrintClass)) {
                    element.classList.add(hideFromPrintClass);
                }
            };

            // tslint:disable-next-line: only-arrow-functions
            const preserve = function(element, isStartingElement) {
                element.classList.remove(hideFromPrintClass);
                element.classList.add(preservePrintClass);
                if (!isStartingElement) {
                    element.classList.add(preserveAncestorClass);
                }
            };

            // tslint:disable-next-line: only-arrow-functions
            const clean = function(element) {
                element.classList.remove(hideFromPrintClass);
                element.classList.remove(preservePrintClass);
                element.classList.remove(preserveAncestorClass);
            };

            // tslint:disable-next-line: only-arrow-functions
            const walkSiblings = function(element, callback) {
                let sibling = element.previousElementSibling;
                while (sibling) {
                    callback(sibling);
                    sibling = sibling.previousElementSibling;
                }
                sibling = element.nextElementSibling;
                while (sibling) {
                    callback(sibling);
                    sibling = sibling.nextElementSibling;
                }
            };

            // tslint:disable-next-line: only-arrow-functions
            const attachPrintClasses = function(element, isStartingElement) {
                preserve(element, isStartingElement);
                walkSiblings(element, hide);
            };

            // tslint:disable-next-line: only-arrow-functions
            const cleanup = function(element) {
                clean(element);
                walkSiblings(element, clean);
            };

            const walkTree = function(element, callback) {
                let currentElement = element;
                callback(currentElement, true);
                currentElement = currentElement.parentElement;
                while (currentElement && currentElement.nodeName !== bodyElementName) {
                    callback(currentElement, false);
                    currentElement = currentElement.parentElement;
                }
            };

            // tslint:disable-next-line: only-arrow-functions
            const print = function(elements) {
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < elements.length; i++) {
                    walkTree(elements[i], attachPrintClasses);
                }
                window.print();
                // tslint:disable-next-line: prefer-for-of
                for (let i = 0; i < elements.length; i++) {
                    walkTree(elements[i], cleanup);
                }
            };

            return {
                print
            };
        })();

        return PrintElements;
    }
}
