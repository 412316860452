<ng-container *ngIf="tablePluginData.indexJ === 0">
  <!--field item name-->
  <h5 maxLine="1" [title]="quotationItem?.procurementItem?.prItem.item.name">
    {{ quotationItem?.procurementItem?.prItem.item.name }}
  </h5>
  <p>{{ quotationItem?.procurementItem?.prItem.item.code }}</p>
  <p>{{ quotationItem?.procurementItem?.prItem.quantity }}
    {{ quotationItem?.procurementItem?.prItem.item.uom.name }}
  </p>
</ng-container>

<ng-container *ngIf="tablePluginData.state.columnList.length > 4 && tablePluginData.indexJ === 3">
  <!-- OEP item amount -->
  <div class="d-flex">
    <ng-container *ngIf="quotationItem?.oepItemAmount">
      <p>{{ quotationItem?.oepItemAmount | resolveNumberToCurrency }}</p>
      <h5>IDR</h5>
    </ng-container>
    <ng-container *ngIf="!quotationItem?.oepItemAmount">
      <p>-</p>
    </ng-container>
  </div>
</ng-container>

<ng-container
  *ngIf="(tablePluginData.state.columnList.length <= 4 && tablePluginData.indexJ === 2) || (tablePluginData.state.columnList.length > 4 && tablePluginData.indexJ === 4) ">
  <!-- field Bid price -->
  <div class="ap-final-price-detail-field">
    <p>{{ 'app-popup-final-price-detail.detail.bidPrice' | translate }}</p>
    <h5>{{ quotationItem?.price | resolveNumberToCurrency }} IDR</h5>
  </div>

  <div class="ap-final-price-detail-field">
    <p>{{ 'app-popup-final-price-detail.detail.totalBidPrice' | translate }}</p>
    <h5>{{ quotationItem?.totalPrice | resolveNumberToCurrency }} IDR</h5>
  </div>

  <div class="ap-final-price-detail-field">
    <p>{{ 'app-popup-final-price-detail.detail.saveOrLoss' | translate }}</p>

    <div class="d-flex" *ngIf="quotationItem?.profitLossStatus">
      <h5 class="mr-2">{{ quotationItem?.profitLossAmount | resolveNumberToDecimal }} %</h5>
      <app-badge-catalog [color]="
          quotationItem?.profitLossStatus?.code ===
          global.appConstant.pm.PROFIT_LOSS_STATUS_SAVE
            ? 'SUCCESS'
            : 'DANGER'
        ">
        {{ quotationItem?.profitLossStatus.name }}
      </app-badge-catalog>
    </div>
    <div *ngIf="quotationItem?.profitLossStatus === null"> - </div>
  </div>
</ng-container>

<ng-container
  *ngIf="(tablePluginData.state.columnList.length <= 4 && tablePluginData.indexJ === 3) || (tablePluginData.state.columnList.length > 4 && tablePluginData.indexJ === 5)">
  <!-- field Final price-->
  <div class="ap-final-price-detail-field">
    <p>{{ 'app-popup-final-price-detail.detail.finalPrice' | translate }}</p>
    <h5>{{ quotationItem?.finalPrice | resolveNumberToCurrency }} IDR</h5>
  </div>

  <div class="ap-final-price-detail-field">
    <p>{{
      'app-popup-final-price-detail.detail.totalFinalPrice' | translate
      }}</p>
    <h5>{{ quotationItem?.totalFinalPrice | resolveNumberToCurrency }} IDR</h5>
  </div>

  <div class="ap-final-price-detail-field">
    <p>{{ 'app-popup-final-price-detail.detail.saveOrLoss' | translate }}</p>

    <div class="d-flex" *ngIf="quotationItem?.finalProfitLossStatus">
      <h5 class="mr-2">{{
        quotationItem?.finalProfitLossAmount | resolveNumberToDecimal
        }}%</h5>
      <app-badge-catalog [color]="
          quotationItem?.finalProfitLossStatus?.code ===
          global.appConstant.pm.PROFIT_LOSS_STATUS_SAVE
            ? 'SUCCESS'
            : 'DANGER'
        ">
        {{ quotationItem?.finalProfitLossStatus?.name }}
      </app-badge-catalog>
    </div>
    <div *ngIf="quotationItem?.finalProfitLossStatus === null"> - </div>
  </div>
</ng-container>