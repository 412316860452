import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Procurement } from '../../core/bean/procurement';
import { Vendor } from '../../core/bean/vendor';
import {
  TableHeaderPluginData,
  TablePluginData
} from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { VendorQuotationView } from '../../core/view/entity/bean/vendor-quotation-view';
import { VendorReferenceResponse } from './vendor-reference.response';

@Component({
  templateUrl: './vendor-reference-popup-change-winning-vendor.component.html'
})
export class VendorReferencePopupChangeWinningVendorComponent extends BaseModuleComponent {
  @Input() vendorReferenceResponse: VendorReferenceResponse;
  @Input() procurement: Procurement;
  @Input() vendorQuotationView: VendorQuotationView;
  @Output() onChange = new EventEmitter();

  public isThingsToConsider: boolean;
  public isMustFullFill = false;
  public tableResponse: TableResponseModel<any>;

  constructor(translateService: TranslateService) {
    super('vendor-reference', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.buildFormGroup();
    this.setSelectedRow();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      recordList: this.tableResponse.formArray
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      this.procurement.biddingEvaluation.code !==
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
        ? [
            {
              field: 'vendorName',
              header: 'table.column.vendorName',
              plugins: [
                {
                  name: 'default'
                },
                {
                  name: 'icon',
                  className: 'pic pi-exclamation-triangle',
                  onClick: this.onClickIconConsider.bind(this),
                  isShowIconFn: this.showIcon.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pis pi-exclamation-triangle text-danger',
                  onClick: this.onClickIconDanger.bind(this),
                  isShowIconFn: this.showIconDanger.bind(this)
                }
              ]
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScoreStatus.name',
                  header: 'table.column.technical',
                  plugins: {
                    name: 'badge',
                    colorField: 'technicalScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                this.procurement.biddingEvaluation.code ===
                this.global.appConstant.pm
                  .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
                  ? {
                      field: 'finalPriceScoreStatus.name',
                      header: 'table.column.price',
                      plugins: {
                        name: 'badge',
                        colorField: 'finalPriceScoreStatus.code',
                        colorMap: {
                          PASSED: 'SUCCESS',
                          NOT_PASSED: 'DANGER'
                        }
                      }
                    }
                  : {
                      field: 'finalPriceScore',
                      header: 'table.column.price',
                      className: 'text-right',
                      plugins: {
                        name: 'text-field',
                        type: 'decimal',
                        isView: true
                      }
                    }
              ]
            },
            {
              field: 'finalPrice',
              header: 'table.column.finalPrice',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: 'text-right'
            },
            {
              field: 'finalProfitLossAmount',
              header: 'table.column.savingLoss',
              className: 'text-right',
              plugins: [
                {
                  name: 'custom-plugin',
                  before: (tablePlugin: TablePluginData): void => {
                    if (tablePlugin.value) {
                      tablePlugin.element.innerText =
                        this.global.converterService.convertMoney(
                          tablePlugin.value
                        ) + ' %';
                    }
                  }
                },
                {
                  name: 'badge',
                  field: 'finalProfitLossStatus.name',
                  colorMap: {
                    SAVE: 'SUCCESS',
                    LOSS: 'DANGER'
                  },
                  pill: false,
                  colorField: 'finalProfitLossStatus.code',
                  className: 'badge-catalog ml-3'
                }
              ]
            }
          ]
        : [
            {
              field: 'vendorName',
              header: 'table.column.vendorName',
              plugins: [
                {
                  name: 'default'
                },
                {
                  name: 'icon',
                  className: 'pic pi-exclamation-triangle',
                  onClick: this.onClickIconConsider.bind(this),
                  isShowIconFn: this.showIcon.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pis pi-exclamation-triangle text-danger',
                  onClick: this.onClickIconDanger.bind(this),
                  isShowIconFn: this.showIconDanger.bind(this)
                }
              ]
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScore',
                  header: {
                    key: 'table.column.technical',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' +
                            (this.vendorReferenceResponse.technicalPercentage ||
                              ' - ') +
                            '%)';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                },
                {
                  field: 'finalPriceScore',
                  header: {
                    key: 'table.column.price',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' +
                            (this.vendorReferenceResponse.pricePercentage ||
                              ' - ') +
                            '%)';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                },
                {
                  field: 'totalFinalEvaluationScore',
                  header: 'table.column.total',
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                }
              ]
            },
            {
              field: 'finalPrice',
              header: 'table.column.finalPrice',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: 'text-right'
            },
            {
              field: 'finalProfitLossAmount',
              header: 'table.column.savingLoss',
              className: 'text-right',
              plugins: [
                {
                  name: 'custom-plugin',
                  before: (tablePlugin: TablePluginData): void => {
                    if (tablePlugin.value) {
                      tablePlugin.element.innerText =
                        this.global.converterService.convertMoney(
                          tablePlugin.value
                        ) + ' %';
                    }
                  }
                },
                {
                  name: 'badge',
                  field: 'finalProfitLossStatus.name',
                  colorMap: {
                    SAVE: 'SUCCESS',
                    LOSS: 'DANGER'
                  },
                  pill: false,
                  colorField: 'finalProfitLossStatus.code',
                  className: 'badge-catalog ml-3'
                }
              ]
            }
          ],
      { checkBoxFn: this.isShowCheckBox.bind(this) }
    );

    // this.tableResponse.setCustomData({ procurementId: this.procurement.id });
  }

  public isShowCheckBox(data): boolean {
    const vendor: Vendor = data.procurementVendor
      ? data.procurementVendor.vendor
      : data.quotation.procurementVendor.vendor;

    if (
      data.isExpiredCertificate ||
      data.isExpiredDoc ||
      (vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
        vendor.vendorStatus.code !==
          this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) ||
      data.isCatalogContract
    ) {
      this.isMustFullFill = true;
      return false;
    } else {
      return true;
    }
  }

  public onClickIconConsider(data): void {
    const vendorName = data.vendorName
      ? data.vendorName
      : data.quotation.procurementVendor.vendor.name;
    let msg = '<ol class="text-left ml-4">';
    if (data.sameCompanyOrgVendorName) {
      let vendorNameList: string[] = data.sameCompanyOrgVendorName.split(',');
      vendorNameList = [...new Set(vendorNameList)];

      msg +=
        '<li>' +
        vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.sameCompanyOrg'
        ) +
        ' ' +
        vendorNameList.toString().replace('[', '').replace(']', '') +
        '</li>';
    }
    if (!data.isFinancialReport) {
      msg +=
        '<li>' +
        vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isFinancialReport'
        ) +
        '</li>';
    }
    if (data.isBlacklist) {
      msg +=
        '<li>' +
        vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isBlacklist'
        ) +
        '</li>';
    }
    if (data.isCertificateWillBeExpired) {
      msg +=
        '<li>' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.certificate'
        ) +
        ' ' +
        vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isCertificateWillBeExpired'
        ) +
        '</li>';
    }

    if (data.isDer) {
      msg +=
        '<li>' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.derRatio'
        ) +
        ' ' +
        vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isDer'
        ) +
        '</li>';
    }

    if (data.isCatalogContract) {
      msg +=
        '<li>' +
        vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isCatalogContract'
        ) +
        ' ' +
        data.catalogName +
        '</li>';
    }
    msg += '</ol>';
    const title = 'vendor-reference.popup.warning.thingsToConsider.title';
    this.doShowPopup(msg, title);
  }

  public onClickIconDanger(data): void {
    const vendor = data.procurementVendor
      ? data.procurementVendor.vendor
      : data.quotation.procurementVendor.vendor;
    let msg = '<ol class="text-left ml-4">';
    if (
      vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
      vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST
    ) {
      msg +=
        '<li>' +
        vendor.name +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isNotActive'
        ) +
        '</li>';
    }

    if (data.isExpiredCertificate) {
      msg +=
        '<li>' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.certificate'
        ) +
        ' ' +
        vendor.name +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isExpiredCertificate'
        ) +
        '</li>';
    }

    if (data.isExpiredDoc) {
      msg +=
        '<li>' +
        vendor.name +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isExpiredDoc'
        ) +
        '</li>';
    }

    if (data.isCatalogContract) {
      msg +=
        '<li>' +
        vendor.name +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isCatalogContract'
        ) +
        ' ' +
        data.catalogName +
        '</li>';
    }
    const title = 'vendor-reference.popup.warning.mustFullFill.title';
    this.doShowPopup(msg, title);
  }

  public doShowPopup(msg: string, title: string): void {
    this.global.modalService.confirmation(
      msg,
      title,
      null,
      null,
      'pvc pv-exclamation-triangle',
      true
    );
  }

  public showIcon(data): boolean {
    const vendor: Vendor = data.procurementVendor
      ? data.procurementVendor.vendor
      : data.quotation.procurementVendor.vendor;

    if (
      !(
        data.isExpiredCertificate ||
        data.isExpiredDoc ||
        (vendor.vendorStatus.code !==
          this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
          vendor.vendorStatus.code !==
            this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) ||
        data.isCatalogContract
      ) &&
      (data.sameCompanyOrgVendorName ||
        !data.isFinancialReport ||
        data.isBlacklist ||
        data.isExpiredDoc ||
        data.isCertificateWillBeExpired ||
        data.isDer ||
        data.isCatalogContract)
    ) {
      this.isThingsToConsider = true;
      return true;
    } else {
      return false;
    }
  }

  public showIconDanger(data): boolean {
    const vendor: Vendor = data.procurementVendor
      ? data.procurementVendor.vendor
      : data.quotation.procurementVendor.vendor;

    if (
      data.isExpiredCertificate ||
      data.isExpiredDoc ||
      (vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
        vendor.vendorStatus.code !==
          this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) ||
      data.isCatalogContract
    ) {
      this.isMustFullFill = true;
      return true;
    } else {
      return false;
    }
  }

  public setSelectedRow(): void {
    if (
      this.procurement.priceEvaluationMethod.code.includes(
        'DEAL_WITH_THIS_PRICE'
      )
    ) {
      this.tableResponse.setRecordList(
        this.vendorReferenceResponse.vendorQuotationViewList
      );
      const winningVendor =
        this.vendorReferenceResponse.vendorQuotationViewList.filter(
          x => x.isReference === true
        );
      this.tableResponse.setSelectedRowList(winningVendor);
    } else {
      this.tableResponse.setRecordList(
        this.vendorReferenceResponse.vendorQuotationFinalViewList
      );
      const winningVendor =
        this.vendorReferenceResponse.vendorQuotationFinalViewList.filter(
          x => x.isReference === true
        );
      this.tableResponse.setSelectedRowList(winningVendor);
    }
  }

  public doOnChange(): void {
    const recordList = this.tableResponse.getRecordList();
    console.log(this.tableResponse.selectedRow.getSelectedRecordList());
    console.log('Record List', recordList);
    console.log(
      this.tableResponse.selectedRow.getSelectedRecordList()[0] ===
        this.tableResponse.selectedRow.getSelectedRecordList()[1]
    );
  }

  public doEmitData(): void {
    this.setStateProcessing();
    this.httpClientService
      .post(
        '/vendor-reference/update-single-winner',
        this.tableResponse.selectedRow.getSelectedRecordList()[0]
          .procurementVendor
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.onChange.emit(
            this.tableResponse.selectedRow.getSelectedRecordList()
          );
          this.setStateReady();
        }
      });
  }
}
