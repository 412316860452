<app-card header="view-vendor.title" [isLoading]="isLoading">
  <app-fieldset legend="Filter">
    <form [formGroup]="formGroup">
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{ 'view-vendor.vendorName' |
          translate }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field autofocus="true" formControlName="vendorName">
          </app-text-field>
        </div>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{ 'view-vendor.segmentation' |
          translate }}</label>
        <div class="col-sm-9 text-left">
          <app-text-tree formControlName="segmentationList"
            stringUrl="/segmentation" header="Segmentation" isLazy="true"></app-text-tree>
        </div>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{ 'view-vendor.performance' |
          translate }}</label>
        <div class="col-sm-9 text-left">
          <ng-container formArrayName="performanceRatingList">
            <p class="m-0 p-0"
              *ngFor="let data of formGroup | resolveFormArray : 'performanceRatingList'; let i = index"
              [formGroupName]="i">
              <app-check-box formControlName="isChecked">
                <app-rating [maxIcon]="data.value.value"
                  [value]="data.value.value" [isViewOnly]="true"></app-rating>
              </app-check-box>
            </p>
          </ng-container>
        </div>
      </div>
      <div class="button-group button-group-center mt-5 mb-4">
        <app-button mode="BYPASS" size="SM" (onClick)="doReset()"
          color="SECONDARY">{{ 'app.button.reset' | translate }}</app-button>
        <app-button mode="BYPASS" size="SM" (onClick)="doApply()">{{
          'app.button.apply' | translate }}</app-button>
      </div>
    </form>
  </app-fieldset>
</app-card>