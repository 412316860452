import { BaseEntity } from '../base/base-entity';
import { ProcurementSeg } from '../bean/procurement-seg';
import { Qualification } from '../bean/qualification';
import { TermQualification } from '../bean/term-qualification';

export class ProcurementSegQualificationEntity extends BaseEntity {
    crudOperation: number;

    procurementSeg: ProcurementSeg;
    qualification: Qualification;
    termQualification: TermQualification;
}
