import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { WorklistPMView } from 'src/app/core/view/entity/bean/worklist-pm-view';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Organization } from '../../core/bean/organization';
import { ProcurementWorklistStatus } from '../../core/bean/procurement-worklist-status';
import { AppTableXComponent } from '../../core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { WorklistPMResponse } from './worklist-pm.response';
@Component({
  templateUrl: './worklist-pm.component.html'
})
export class WorklistPMComponent extends BaseModuleComponent {
  @ViewChild(AppTableXComponent) table: AppTableXComponent;
  public tableResponse: TableResponseModel<WorklistPMView>;
  public statusOptionList: OptionListModel<ProcurementWorklistStatus> =
    new OptionListModel(true);
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);
  public module: string;
  public currentPosition: string;
  public customData = '';
  public headerTable = 'Total Worklist';
  constructor(translateService: TranslateService) {
    super('worklist-pm', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      organizationList: [null],
      statusList: [null],
      moduleCode: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<WorklistPMResponse>('/worklist-pm/get-option-list')
      .subscribe(response => {
        if (response != null) {
          this.statusOptionList.setRequestValues(response.statusList);
          this.organizationOptionList.setRequestValues(
            response.organizationList
          );
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurement.code',
        header: 'table.column.procurementNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doEdit.bind(this)
        }
      },
      {
        field: 'procurementDate',
        header: 'table.column.procurementDate',
        plugins: 'date'
      },
      {
        field: 'procurement.name',
        header: 'table.column.procurementName',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'badge',
            field: 'isRepeat',
            pill: false,
            className: 'badge-catalog badge-danger ml-3',
            colorMap: {
              RE_HELD: 'DANGER'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.row.record.procurement.isRepeat) {
                  const RE_HELD: string = this.translateService.instant(
                    'dynamic-master-attribute.procurementFlag.reHeld'
                  );
                  return RE_HELD;
                }
              },
              color: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.row.record.procurement.isRepeat) {
                  return 'DANGER';
                }
              }
            }
          },
          {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData): void => {
              if (!tablePluginData.row.record.procurement.isRepeat) {
                tablePluginData.element.children[1].remove();
              }
            }
          },
          {
            name: 'badge',
            field: 'procurement.documentSubmissionMethod.name',
            colorMap: {
              ENVELOPE_1: 'INFO',
              ENVELOPE_2: 'INFO',
              STAGE_1: 'SUCCESS',
              STAGE_2: 'SUCCESS'
            },
            pill: false,
            colorField: 'procurement.documentSubmissionMethod.code',
            className: 'badge-catalog ml-3'
          },
          {
            name: 'custom-plugin',
            after: (tablePlugin: TablePluginData): void => {
              if (
                tablePlugin.row.record.procurement.prcsCode.includes(
                  'SUBMIT-QUOTATION'
                ) ||
                tablePlugin.row.record.procurement.prcsCode.includes(
                  'OPEN-QUOTATION'
                )
              ) {
                if (
                  tablePlugin.row.record.procurement.documentSubmissionType
                    .code ===
                  this.global.appConstant.pm
                    .DOCUMENT_SUBMISSION_TYPE_ONE_ENVELOPE
                ) {
                  if (tablePlugin.element.children.length > 2) {
                    tablePlugin.element.children[2].remove();
                  } else {
                    tablePlugin.element.children[1].remove();
                  }
                }
              } else {
                if (tablePlugin.element.children.length > 2) {
                  tablePlugin.element.children[2].remove();
                } else {
                  tablePlugin.element.children[1].remove();
                }
              }
            }
          }
        ],
        className: 'd-flex'
      },
      {
        field: 'procurement.documentSubmissionType.name',
        header: 'table.column.docSubmissionType'
      },
      {
        field: 'procurement.organization.name',
        header: 'table.column.department'
      },
      {
        field: 'procurement.procurementMethod.name',
        header: 'table.column.method'
      },
      {
        field: 'procurement.workflowModelPrcs.name',
        header: 'table.column.stage'
      },
      {
        field: 'procurement.procurementWorklistStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'procurement.procurementWorklistStatus.code',
          colorMap: {
            NEW: 'INFO',
            DRAFT: 'SECONDARY',
            REVISION: 'FEEDBACK',
            WAITING_APPROVAL: 'WARNING',
            DONE: 'SUCCESS',
            WAITING_RESPONSE: 'GOOD',
            CLOSED_RESPONSE: 'DANGER',
            HOLD: 'DANGER',
            CANCELED: 'DANGER',
            ON_PROGRESS: 'PROGRESS'
          }
        }
      }
    ]);
  }

  doEdit(worklistPMView: WorklistPMView): void {
    const isSchedule = this.validateSchedule(worklistPMView);

    if (isSchedule) {
      const prcsCode = worklistPMView.procurement.prcsCode;
      this.global.routerParams.clear();
      this.global.routerParams.set('procurement', worklistPMView.procurement);
      this.global.routerParams.set(
        'procurementId',
        worklistPMView.procurement.id
      );
      this.global.routerParams.set(
        'procurementWorklistStatusCode',
        worklistPMView.procurement.procurementWorklistStatus.code
      );
      this.global.routerParams.set('urlBackOutside', '/pages/worklist-pm');
      if (worklistPMView.picUser.id === worklistPMView.participantUser.id) {
        if (
          worklistPMView.procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_WORKLIST_STATUS_WAITING_APPROVAL ||
          worklistPMView.procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_HOLD
        ) {
          this.router.navigate([
            '/pages/' +
              prcsCode.replace('APPROVAL-', '').toLowerCase() +
              '/detail'
          ]);
          this.global.routerParams.set('todo', 'view');
        } else if (
          worklistPMView.procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT ||
          worklistPMView.procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION ||
          worklistPMView.procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DONE ||
          worklistPMView.procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION
        ) {
          this.router.navigate([
            '/pages/' +
              prcsCode.replace('APPROVAL-', '').toLowerCase() +
              '/edit'
          ]);
          this.global.routerParams.set('todo', 'edit');
        } else if (
          worklistPMView.procurement.procurementWorklistStatus.code ===
          this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_ON_PROGRESS
        ) {
          this.router.navigate([
            '/pages/' +
              prcsCode.replace('APPROVAL-', '').toLowerCase() +
              '/edit'
          ]);
          this.global.routerParams.set('todo', 'progress');
        } else if (
          worklistPMView.procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_NEW &&
          worklistPMView.procurement.workflowModelPrcs.module.code ===
            'REFUTATION'
        ) {
          this.doShowScheduleMismatchConfirmation(
            worklistPMView.procurement.id
          );
        } else if (
          worklistPMView.procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_WORKLIST_STATUS_WAITING_RESPONSE &&
          worklistPMView.procurement.workflowModelPrcs.module.code ===
            'REFUTATION'
        ) {
          this.global.routerParams.set('todo', 'view');
          this.router.navigate([
            '/pages/' +
              prcsCode.replace('APPROVAL-', '').toLowerCase() +
              '/detail'
          ]);
        } else if (
          worklistPMView.procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_WORKLIST_STATUS_CLOSED_RESPONSE &&
          worklistPMView.procurement.workflowModelPrcs.module.code ===
            'REFUTATION'
        ) {
          this.global.routerParams.set('todo', 'edit');
          this.router.navigate([
            '/pages/' +
              prcsCode.replace('APPROVAL-', '').toLowerCase() +
              '/edit'
          ]);
        } else {
          this.router.navigate([
            '/pages/' + prcsCode.replace('APPROVAL-', '').toLowerCase() + '/add'
          ]);
          this.global.routerParams.set('todo', 'add');
        }
      } else {
        this.router.navigate([
          '/pages/' +
            prcsCode.replace('APPROVAL-', '').toLowerCase() +
            '/detail'
        ]);
        this.global.routerParams.set('todo', 'view');
      }
    } else {
      if (
        worklistPMView.procurement.workflowModelPrcs.module.isSchedule &&
        !worklistPMView.startDate &&
        !worklistPMView.endDate
      ) {
        this.doShowSetScheduleConfirmation(worklistPMView.procurement.id);
      } else {
        this.doShowScheduleMismatchConfirmation(worklistPMView.procurement.id);
      }
    }
  }

  public doShowScheduleMismatchConfirmation(procurementId: number): void {
    this.global.modalService
      .scheduleMismatchConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.global.routerParams.clear();
          this.global.routerParams.set('procurementId', procurementId);
          this.global.routerParams.set('urlBackOutside', '/pages/worklist-pm');
          this.global.routerParams.set('todo', 'edit');
          this.global.routerParams.set('isShowButtonSave', false);
          this.router.navigate(['/pages/procurement-schedule/edit']);
        }
      });
  }

  public doShowSetScheduleConfirmation(procurementId: number): void {
    this.global.modalService
      .confirmation(
        'app.confirmation.setScheduleConfirmation.prompt',
        'app.confirmation.setScheduleConfirmation.title',
        'app.button.later',
        'app.button.settingSchedule',
        'pvc pv-calendar-alt',
        false
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.global.routerParams.clear();
          this.global.routerParams.set('procurementId', procurementId);
          this.global.routerParams.set('urlBackOutside', '/pages/worklist-pm');
          this.global.routerParams.set('todo', 'edit');
          this.router.navigate(['/pages/procurement-schedule/edit']);
        }
      });
  }

  public validateSchedule(worklistPMView: WorklistPMView): boolean {
    if (
      worklistPMView.procurement.workflowModelPrcs.module.isSchedule &&
      worklistPMView.procurement.workflowModelPrcs.module.code !==
        'AANWIJZING' &&
      worklistPMView.procurement.workflowModelPrcs.module.code !==
        'PRICE-EVALUATION'
    ) {
      if (worklistPMView.startDate && worklistPMView.endDate) {
        // khusus status new/draft/revision ketika melewati end date tidak bisa dibuka kembali
        if (
          !worklistPMView.procurement.workflowModelPrcs.module
            .isVendorResponse &&
          (worklistPMView.procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_NEW ||
            worklistPMView.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT ||
            worklistPMView.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION ||
            worklistPMView.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm
                .PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION)
        ) {
          return (
            new Date(worklistPMView.startDate).getTime() <=
              new Date().getTime() &&
            new Date(worklistPMView.endDate).getTime() >= new Date().getTime()
          );
        } else {
          return (
            new Date(worklistPMView.startDate).getTime() <= new Date().getTime()
          );
        }
      } else if (worklistPMView.startDate && !worklistPMView.endDate) {
        return (
          new Date(worklistPMView.startDate).getTime() <= new Date().getTime()
        );
      } else if (!worklistPMView.startDate && !worklistPMView.endDate) {
        return false;
      }
    } else if (
      worklistPMView.procurement.workflowModelPrcs.module.code ===
      'PRICE-EVALUATION'
    ) {
      return new Date(worklistPMView.endDate).getTime() >= new Date().getTime();
    } else {
      return true;
    }
  }

  doClick(prcsCode): void {
    if (prcsCode === null) {
      prcsCode = '';
    }
    if (prcsCode === 'PROCUREMENT-REG-BUYER') {
      this.headerTable = 'Total Worklist Procurement Registration';
    } else if (prcsCode === 'EVALUATION-CRITERIA') {
      this.headerTable = 'Total Worklist Evaluation Criteria';
    } else if (prcsCode === 'OEP') {
      this.headerTable = 'Total Worklist Owner Estimation Price';
    } else if (prcsCode === 'DOC-RETRIEVAL') {
      this.headerTable = 'Total Worklist Document Retrieval';
    } else if (prcsCode === 'UPLOAD-DOCUMENT') {
      this.headerTable = 'Total Worklist Upload Document';
    } else if (
      prcsCode === 'OPEN-QUOTATION' ||
      prcsCode === 'OPEN-QUOTATION-2ND'
    ) {
      this.headerTable = 'Total Worklist Open Quotation';
    } else if (
      prcsCode === 'SUBMIT-QUOTATION' ||
      prcsCode === 'SUBMIT-QUOTATION-2'
    ) {
      this.headerTable = 'Total Worklist Submit Quotation';
    } else if (prcsCode === 'AANWIJZING') {
      this.headerTable = 'Total Worklist Aanwijzing';
    } else if (prcsCode === 'ADMINISTRATION-EVALUATION') {
      this.headerTable = 'Total Worklist Administration Evaluation';
    } else if (prcsCode === 'TECHNICAL-EVALUATION') {
      this.headerTable = 'Total Worklist Technical Evaluation';
    } else if (prcsCode === 'PRICE-EVALUATION') {
      this.headerTable = 'Total Worklist Price Evaluation';
    } else if (prcsCode === 'VENDOR-DESIGNATION') {
      this.headerTable = 'Total Worklist Vendor Designation';
    } else if (prcsCode === 'REFUTATION') {
      this.headerTable = 'Total Worklist Refutation';
    } else if (prcsCode === 'VENDOR-REFERENCE') {
      this.headerTable = 'Total Worklist Vendor Reference';
    } else {
      this.headerTable = 'Total Worklist';
    }
    this.formGroup.patchValue({
      moduleCode: prcsCode
    });
    this.currentPosition = prcsCode;
    this.onChangeFilter();
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
    } else {
      this.formGroup.value.fromDate = null;
      this.formGroup.value.untilDate = null;
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
