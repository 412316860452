import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppAutoCompleteModule } from '../../app-auto-complete/app-auto-complete.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppCheckBoxModule } from '../../app-check-box/app-check-box.module';
import { AppComboBoxModule } from '../../app-combo-box/app-combo-box.module';
import { AppFieldsetModule } from '../../app-fieldset/app-fieldset.module';
import { AppRadioModule } from '../../app-radio/app-radio.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppTextFieldModule } from '../../app-text-field/app-text-field.module';
import { AppTextTreeModule } from '../../app-text-tree/app-text-tree.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupChooseBudgetComponent } from './app-popup-choose-budget.component';
import { AppPopupChooseBudgetService } from './app-popup-choose-budget.service';

@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppTableModule,
    AppButtonModule,
    AppTextFieldModule,
    AppCheckBoxModule,
    AppRadioModule,
    AppFieldsetModule,
    AppComboBoxModule,
    AppTextTreeModule,
    AppAutoCompleteModule
  ],
  declarations: [AppPopupChooseBudgetComponent],
  entryComponents: [AppPopupChooseBudgetComponent],
  providers: [AppPopupChooseBudgetService],
  exports: [AppPopupChooseBudgetComponent]
})
export class AppPopupChooseBudgetModule {}
