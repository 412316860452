import { resolveTableRecords } from '../helpers/resolve-table-records.helper';
import { TableState } from '../model/table-state';

export const selectAllTableRecord = (
  state: TableState,
  payload: { isChecked: boolean }
): void => {
  state.checkedRecord.isCheckedAll = payload.isChecked;
  if (payload.isChecked) {
    state.checkedRecord.customData = state.tableRequest.customData;
    state.checkedRecord.keywords = state.tableRequest.globalFilter;
    setCheckedRecordList(state);
  } else {
    state.checkedRecord.reset();
    state.model.checkedRecord = state.checkedRecord;
    state.records.forEach(tableRecord => tableRecord.selectedRecord.reset());
    state.setStateReady();
  }
};

const setCheckedRecordList = (state: TableState): void => {
  if (state.isServerSide) {
    state.checkedRecord.checkedRecordList = [];
    state.model.checkedRecord = state.checkedRecord;
    state.setStateReady();
  } else {
    resolveTableRecords(state, true).then(recordList => {
      state.checkedRecord.checkedRecordList = recordList;
      state.model.checkedRecord = state.checkedRecord;
      state.setStateReady();
    });
  }
};
