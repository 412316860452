<div class="row">
  <div class="col-lg-12">
    <app-card header="interview.title" [isSaving]="formSaving" [isLoading]="formLoading">
      <div *ngIf="!formLoading">
      <form class="form-horizontal" [formGroup]="formGroup">
        <div class="form-group row text-sm-right" [ngStyle]="{ 'margin-left': '1px' }">
          <label class="col-sm-3 control-label">{{
            'interview.form.companyName' | translate
            }}</label>
          <div class="col-sm-9 col-lg-7 text-left">
            {{ interviewResponse.dueDiligenceScheduleInterview.vendorHistory.name }}
          </div>
        </div>

        <div class="form-group row text-sm-right" [ngStyle]="{ 'margin-left': '1px' }">
          <label class="col-sm-3 control-label">{{
            'interview.form.regisNum' | translate
            }}</label>
          <div class="col-sm-9 col-lg-7 text-left">
            {{
            interviewResponse.dueDiligenceScheduleInterview.vendorHistory
            .registrationNumber
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right" [ngStyle]="{ 'margin-left': '1px' }">
          <label class="col-sm-3 control-label">{{
            'interview.form.dueDiligenceNum' | translate
            }}</label>
          <div class="col-sm-9 col-lg-7 text-left">
            {{ interviewResponse.dueDiligenceScheduleInterview.dueDiligenceNumber }}
          </div>
        </div>

        <!-- Schedule Plan -->
        <div class="card border-0">
          <div class="card-header bg-transparent" id="heading1" [ngStyle]="{ padding: '1rem 0 0.4rem' }">
            <h5 class="d-inline-block mb-0">{{
              'interview.form.schedulePlan' | translate
              }}</h5>
            <em class="fas fa-chevron-circle-down float-right cursor-pointer" data-toggle="collapse"
              [attr.data-target]="'#collapseVendorAttr1'" aria-expanded="true"
              [attr.aria-controls]="'collapseVendorAttr1'" [ngStyle]="{ 'font-size': '1.3rem' }"></em>
          </div>
          <div id="collapseVendorAttr1" class="collapse show" [attr.aria-labelledby]="'heading1'">
            <div class="card-body">
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'interview.form.address' | translate
                  }}</label>
                <div class="col-sm-5 text-left">
                  {{ interviewResponse.dueDiligenceScheduleInterview.address }}
                </div>
              </div>

              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'interview.form.date' | translate
                  }}</label>
                <div class="col-sm-9 col-lg-7 text-left">
                  {{
                  interviewResponse.dueDiligenceScheduleInterview.date
                  | date: global.config.parameterModel.dateFormatShort
                  }}
                </div>
              </div>

              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'interview.form.time' | translate
                  }}</label>
                <div class="col-sm-9 text-left">
                  <strong>{{ 'interview.form.from' | translate }}</strong>
                  {{
                  getHours(
                  interviewResponse.dueDiligenceScheduleInterview.startTime
                  )
                  }}
                  WIB
                  <strong>{{ 'interview.form.until' | translate }}</strong>
                  {{
                  getHours(
                  interviewResponse.dueDiligenceScheduleInterview.endTime
                  )
                  }}
                  WIB
                </div>
              </div>

              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'interview.form.objective' | translate
                  }}</label>
                <div class="col-sm-5 text-left">
                  {{ interviewResponse.dueDiligenceScheduleInterview.objective }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Task Giver -->
        <div class="card border-0">
          <div class="card-header bg-transparent" id="heading2" [ngStyle]="{ padding: '1rem 0 0.4rem' }">
            <h5 class="d-inline-block mb-0">{{
              'interview.form.taskGiver' | translate
              }}</h5>
            <em class="fas fa-chevron-circle-down float-right cursor-pointer" data-toggle="collapse"
              [attr.data-target]="'#collapseVendorAttr2'" aria-expanded="true"
              [attr.aria-controls]="'collapseVendorAttr2'" [ngStyle]="{ 'font-size': '1.3rem' }"></em>
          </div>
          <div id="collapseVendorAttr2" class="collapse show" [attr.aria-labelledby]="'heading2'">
            <div class="card-body">
              <div class="table-responsive custom-table float-none" [ngStyle]="{ margin: '0 auto' }">
                <table class="table table-striped box-shadow" aria-describedby="Table">
                  <thead>
                    <tr>
                      <th scope="col" [ngStyle]="{ width: '250px' }" class="text-center">
                        {{ 'app.table.column.no' | translate }}
                      </th>
                      <th scope="col" [ngStyle]="{ width: '400px' }">{{
                        'interview.table.column.name' | translate
                        }}</th>
                      <th scope="col">{{
                        'interview.table.column.position' | translate
                        }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="
                        interviewResponse.dueDiligenceAssignerTaskGiverList
                          .length == 0 ||
                        interviewResponse.dueDiligenceAssignerTaskGiverList == null
                      ">
                      <td colspan="99" class="bg-white text-center">
                        {{ 'app.info.noData' | translate }}</td>
                    </tr>
                    <tr *ngFor="
                        let data of interviewResponse.dueDiligenceAssignerTaskGiverList;
                        let i = index
                      ">
                      <td class="text-center">{{ i + 1 }}.</td>
                      <td>{{ data.user.name }}</td>
                      <td *ngIf="data.position">{{ data.position }}</td>
                      <td *ngIf="!data.position"> - </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Acknowledge By -->
        <div class="card border-0">
          <div class="card-header bg-transparent" id="heading3" [ngStyle]="{ padding: '1rem 0 0.4rem' }">
            <h5 class="d-inline-block mb-0">{{
              'interview.form.acknowledgedBy' | translate
              }}</h5>
            <em class="fas fa-chevron-circle-down float-right cursor-pointer" data-toggle="collapse"
              [attr.data-target]="'#collapseVendorAttr3'" aria-expanded="true"
              [attr.aria-controls]="'collapseVendorAttr3'" [ngStyle]="{ 'font-size': '1.3rem' }"></em>
          </div>
          <div id="collapseVendorAttr3" class="collapse show" [attr.aria-labelledby]="'heading3'">
            <div class="card-body">
              <div class="table-responsive custom-table float-none" [ngStyle]="{ margin: '0 auto' }">
                <table class="table table-striped box-shadow" aria-describedby="Table">
                  <thead>
                    <tr>
                      <th scope="col" [ngStyle]="{ width: '250px' }" class="text-center">
                        {{ 'app.table.column.no' | translate }}
                      </th>
                      <th scope="col" [ngStyle]="{ width: '400px' }">{{
                        'interview.table.column.name' | translate
                        }}</th>
                      <th scope="col">{{
                        'interview.table.column.position' | translate
                        }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="
                        interviewResponse.dueDiligenceAssignerAcknowledgedByList
                          .length == 0 ||
                        interviewResponse.dueDiligenceAssignerAcknowledgedByList ==
                          null
                      ">
                      <td colspan="99" class="bg-white text-center">
                        {{ 'app.info.noData' | translate }}</td>
                    </tr>
                    <tr *ngFor="
                        let data of interviewResponse.dueDiligenceAssignerAcknowledgedByList;
                        let i = index
                      ">
                      <td class="text-center">{{ i + 1 }}.</td>
                      <td>{{ data.user.name }}</td>
                      <td *ngIf="data.position">{{ data.position }}</td>
                      <td *ngIf="!data.position"> - </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Executor -->
        <div class="card border-0">
          <div class="card-header bg-transparent" id="heading4" [ngStyle]="{ padding: '1rem 0 0.4rem' }">
            <h5 class="d-inline-block mb-0">{{
              'interview.form.executor' | translate
              }}</h5>
            <em class="fas fa-chevron-circle-down float-right cursor-pointer" data-toggle="collapse"
              [attr.data-target]="'#collapseVendorAttr4'" aria-expanded="true"
              [attr.aria-controls]="'collapseVendorAttr4'" [ngStyle]="{ 'font-size': '1.3rem' }"></em>
          </div>
          <div id="collapseVendorAttr4" class="collapse show" [attr.aria-labelledby]="'heading4'">
            <div class="card-body">
              <div class="table-responsive custom-table float-none" [ngStyle]="{ margin: '0 auto' }">
                <table class="table table-striped box-shadow" aria-describedby="Table">
                  <thead>
                    <tr>
                      <th scope="col" [ngStyle]="{ width: '250px' }" class="text-center">
                        {{ 'app.table.column.no' | translate }}
                      </th>
                      <th scope="col" [ngStyle]="{ width: '400px' }">{{
                        'interview.table.column.name' | translate
                        }}</th>
                      <th scope="col">{{
                        'interview.table.column.position' | translate
                        }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="
                        let data of interviewResponse.dueDiligenceExecutorInterviewList;
                        let i = index
                      ">
                      <td class="text-center">{{ i + 1 }}.</td>
                      <td>{{ data.name }}</td>
                      <td>{{
                        data.position && data.position !== ''
                        ? data.position
                        : '-'
                        }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Realization -->
        <div class="card border-0">
          <div class="card-header bg-transparent" id="heading5" [ngStyle]="{ padding: '1rem 0 0.4rem' }">
            <h5 class="d-inline-block mb-0">{{
              'interview.form.realization' | translate
              }}</h5>
            <em class="fas fa-chevron-circle-down float-right cursor-pointer" data-toggle="collapse"
              [attr.data-target]="'#collapseVendorAttr5'" aria-expanded="true"
              [attr.aria-controls]="'collapseVendorAttr5'" [ngStyle]="{ 'font-size': '1.3rem' }"></em>
          </div>
          <div id="collapseVendorAttr5" class="collapse show" [attr.aria-labelledby]="'heading5'">
            <div class="card-body">
              <div class="form-group row text-sm-right" [ngClass]="!formGroup.isView ? 'required' : ''">
                <label class="col-sm-3 control-label">{{
                  'interview.form.date' | translate
                  }}</label>
                <div class="col-sm-9 col-lg-7 text-left">
                  <app-date-picker formControlName="date"></app-date-picker>
                </div>
              </div>

              <!-- time if isView false -->
              <div *ngIf="!formGroup.isView" class="form-group row text-sm-right">
                <div class="col-sm-3 mb-3 pr-1 align-items-center" [ngStyle]="{
                    display: 'grid',
                    'padding-right': '0.4rem',
                    height: '72px'
                  }">
                  <label class="control-label">
                    {{ 'interview.form.time' | translate
                    }}<span *ngIf="!formGroup.isView" [ngStyle]="{ color: 'red' }">
                      *</span>
                  </label>
                </div>
                <div class="form-group row col-sm-9 text-left">
                  <label class="px-3 align-items-center" [ngStyle]="{ display: 'grid', height: '72px' }"><strong>{{
                      'interview.form.from' | translate
                      }}</strong></label>
                  <div [ngStyle]="{ 'max-width': '135px' }">
                    <app-time formControlName="startTime"></app-time>
                  </div>
                  <label class="ml-3 mr-3 align-items-center" [ngStyle]="{ display: 'grid', height: '72px' }"><strong>{{
                      'interview.form.until' | translate
                      }}</strong></label>
                  <div [ngStyle]="{ 'max-width': '135px' }">
                    <app-time formControlName="endTime"></app-time>
                  </div>
                  <label class="ml-3 align-items-center"
                    [ngStyle]="{ display: 'grid', height: '72px' }"><strong>WIB</strong></label>
                </div>
              </div>

              <div *ngIf="formGroup.isView" class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'interview.form.time' | translate
                  }}</label>
                <div class="col-sm-9 text-left">
                  <strong>{{ 'interview.form.from' | translate }}</strong>
                  {{
                  getHours(
                  interviewResponse.dueDiligenceRealizationInterview?.startTime
                  )
                  }}
                  WIB
                  <strong>{{ 'interview.form.until' | translate }}</strong>
                  {{
                  getHours(
                  interviewResponse.dueDiligenceRealizationInterview?.endTime
                  )
                  }}
                  WIB
                </div>
              </div>

              <div class="form-group row text-sm-right" [ngClass]="!formGroup.isView ? 'required' : ''">
                <label class="col-sm-3 control-label">{{
                  'interview.form.note' | translate
                  }}</label>
                <div class="text-left" [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'">
                  <app-text-area formControlName="note" maxLength="512">
                  </app-text-area>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Company Data -->
        <div class="card border-0">
          <div class="card-header bg-transparent" id="heading6" [ngStyle]="{ padding: '1rem 0 0.4rem' }">
            <h5 class="d-inline-block mb-0">{{
              'interview.form.companyData' | translate
              }}</h5>
            <em class="fas fa-chevron-circle-down float-right cursor-pointer" data-toggle="collapse"
              [attr.data-target]="'#collapseVendorAttr6'" aria-expanded="true"
              [attr.aria-controls]="'collapseVendorAttr6'" [ngStyle]="{ 'font-size': '1.3rem' }"></em>
          </div>
          <div id="collapseVendorAttr6" class="collapse show" [attr.aria-labelledby]="'heading6'">
            <div class="card-body">
              <ng-container formArrayName="questionList">
                <ng-container *ngFor="
                    let item of formArrayQuestionList.controls;
                    let i = index
                  " [formGroup]="item">
                  <div class="form-group row">
                    <div class="col-sm-9">
                      <label class="control-label text-break text-justify">
                        {{ item.controls.question.value }}
                        <span *ngIf="!formGroup.isView" [ngStyle]="{ color: 'red' }">
                          *</span></label>
                      <app-text-area *ngIf="!isView" size="XL" formControlName="result" maxLength="512">
                      </app-text-area>
                      <div *ngIf="isView" class="form-group row text-sm-right"
                        [ngClass]="!formGroup.isView ? 'required' : ''">
                        <label class="col-sm-2">{{ 'interview.form.answer' | translate }} :</label>
                        <div class="col-sm-9 text-left">
                          <app-text-area size="XL" formControlName="result" maxLength="512"></app-text-area>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br *ngIf="i + 1 !== formGroup.get('questionList').value.length" />
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </form>

      <div class="button-group button-group-center">
        <app-button [disabled]="formSaving" *ngIf="!interviewResponse.isUserApproval" color="SECONDARY" (onClick)="doBack()"
          mode="BYPASS">{{ 'app.button.back' | translate }}
        </app-button>
        <app-button [disabled]="formSaving" *ngIf="!formGroup.isView" color="PRIMARY" (onClick)="doSaveInterview()">
          {{ 'app.button.save' | translate }}</app-button>

        <app-button *ngIf="interviewResponse.isUserApproval"
        color="SECONDARY"
        (onClick)="doBackApproval()"
        [disabled]="formSaving"
        mode="BYPASS"
        >{{ 'app.button.back' | translate }}</app-button>
      </div>
      </div> <!--!formLoading-->
    </app-card>
  </div>
</div>