import { EventEmitter, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { ArrayUtils } from '../../../utils';
import { TableState } from '../model/table-state';
@Pipe({
  name: 'resolveTableRecord'
})
export class ResolveTableRecordsPipe implements PipeTransform {
  private result: EventEmitter<Array<any>> = new EventEmitter();
  transform(records: Array<any>, state: TableState): Observable<Array<any>> {
    state.requestReload.subscribe(() => {
      this.result.emit(this.getRecords(records, state));
      console.log('Info: I am in');
    });
    return this.result;
  }

  public getRecords(records: Array<any>, state: TableState): Array<any> {
    const { tableRequest, sortOrder } = state;
    const { perPage, page } = state.pagination;
    const startPage = perPage * (page - 1);
    let recordsTemp = Array.from(records || []);

    if (!state.isServerSide) {
      recordsTemp = ArrayUtils.sortArray(
        recordsTemp,
        tableRequest.sortField,
        sortOrder
      );
      console.log(startPage);
      console.log(perPage);
      console.log(
        Array.from(recordsTemp).splice(startPage, startPage + perPage)
      );
    }

    return recordsTemp.splice(startPage, startPage + perPage);
  }
}
