import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Bank } from '../../core/bean/bank';
import { Currency } from '../../core/bean/currency';
import { DirectPurchasePayment } from '../../core/bean/direct-purchase-payment';
import { PaymentMethod } from '../../core/bean/payment-method';
import { ProcurementVendor } from '../../core/bean/procurement-vendor';
import { Region } from '../../core/bean/region';
import { Vendor } from '../../core/bean/vendor';
import { VendorBank } from '../../core/bean/vendor-bank';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';
import { VendorView } from '../../core/view/entity/bean/vendor-view';
import { InitiationDirectPurchaseVendorModel } from './model/initiation-direct-purchase-vendor.model';
import { DirectPurchasePaymentResponse } from './response/direct-purchase-payment.response';

@Component({
  templateUrl: './direct-purchase-popup-add-vendor.component.html'
})
export class DirectPurchasePopupAddVendorComponent extends BaseComponentComponent {
  @Input() vendorView: VendorView;
  @Input() model: InitiationDirectPurchaseVendorModel;
  @Input() isView = false;
  @Input() regionList: Region[] = [];
  @Input() currencyList: Currency[] = [];
  @Input() paymentMethodList: PaymentMethod[] = [];
  @Output() public onChange: EventEmitter<InitiationDirectPurchaseVendorModel> =
    new EventEmitter();

  public regionOptionList: OptionListModel<Region> = new OptionListModel(false);
  public isContract: boolean;
  public isContractView: boolean;
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );
  public bankOptionList: OptionListModel<Bank> = new OptionListModel(
    true
  ); /** vendor existing dari vendor_bank, new dari master (autocomplete) */
  public paymentMethodOptionList: OptionListModel<PaymentMethod> =
    new OptionListModel(false);
  public fileUploader: FileUploader = new FileUploader(
    '/initiation/',
    'initiation.form.headerImage',
    this.global.appConstant.fileType.DOC_PAYMENT_PROOF,
    false,
    1
  );
  public vendorBankList: VendorBank[] = [];
  public selectedVendorBank: VendorBank;

  constructor(public translateService: TranslateService) {
    super('initiation');
  }

  onInit(): void {
    this.buildFormGroup();
    this.doSetFormGroup();
    this.setContinueDirectPurchaseToContract();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [null, Validators.required()],
      email: [null, Validators.email()],
      phone: [null],
      picName: [null],
      address: [null],
      npwp: [null],
      isReceived: [false],
      isPaid: [false],
      isContract: [false],
      invoiceNumber: [null],
      invoiceDate: [null],
      billAmount: [null],
      paymentMethod: [null],
      bank: [null],
      accountHolder: [null],
      accountNumber: [null],
      creditCardNumber: [null],
      proofDocList: [null],
      note: [null]
    });
  }

  public doSetFormGroup(): void {
    this.regionOptionList.setRequestValues(this.regionList);
    this.currencyOptionList.setRequestValues(this.currencyList);
    this.paymentMethodOptionList.setRequestValues(this.paymentMethodList);

    if (this.vendorView) {
      /** existing vendor: promise/one time */
      this.formGroup.patchValue({
        name: this.vendorView.name,
        email: this.vendorView.email,
        phone: {
          country: this.vendorView.phoneRegion,
          number: this.vendorView.phone
        },
        picName: this.vendorView.picName,
        address: this.vendorView.address,
        npwp: this.vendorView.taxNumber
      });

      if (!this.model) {
        /** todo = add */
        this.httpClientService
          .get<DirectPurchasePaymentResponse>(
            '/initiation/get-direct-purchase-payment?vendorId=' +
              this.vendorView.id
          )
          .subscribe((response: DirectPurchasePaymentResponse) => {
            const vendorBankList = response.vendorBankList;
            const previousPaymentMethod = response.paymentMethod;

            this.bankOptionList.setRequestValues(
              vendorBankList.map(vendorBank => vendorBank.bank)
            );
            this.vendorBankList = vendorBankList;
            this.formGroup.patchValue({
              paymentMethod: previousPaymentMethod
            });
            this.setStateReady();

            if (this.vendorView && !this.vendorView.isManual) {
              /** promise vendor */
              const formControlNameList = [
                'name',
                'email',
                'phone',
                'picName',
                'address',
                'npwp',
                'paymentMethod',
                'accountHolder',
                'accountNumber'
              ];
              formControlNameList.forEach(controlName => {
                this.formGroup.get(controlName).setIsView(true);
              });
            }
          });
      } else {
        /** todo = edit, model sudah terisi */
        this.vendorBankList = this.model.vendorBankList;

        if (this.vendorBankList) {
          this.bankOptionList.setRequestValues(
            this.vendorBankList.map(vendorBank => vendorBank?.bank)
          );
        }

        this.fileUploader.fileObjectList = this.model.fileObjectList;
        if (this.model.directPurchasePaymentDocList) {
          this.fileUploader.setFileList(
            this.model.directPurchasePaymentDocList.map(doc => doc.file)
          );
        }

        this.isContract =
          this.model.procurementVendor.procurement.agreementType?.code ===
          'CONTRACT'
            ? true
            : false;

        this.formGroup.patchValue({
          isReceived: this.model.procurementVendor.isReceived,
          isPaid: this.model.procurementVendor.isPaid,
          invoiceNumber:
            this.model.directPurchasePayment?.invoiceNumber || null,
          invoiceDate: this.model.directPurchasePayment?.invoiceDate || null,
          billAmount: {
            price: this.model.directPurchasePayment?.billAmount || null,
            currency: this.currencyList.find(c => c.code === 'IDR')
          },
          paymentMethod:
            this.model.directPurchasePayment?.paymentMethod || null,
          bank: this.model.directPurchasePayment?.vendorBank?.bank || null,
          accountHolder:
            this.model.directPurchasePayment?.vendorBank?.accountHolder || null,
          accountNumber:
            this.model.directPurchasePayment?.vendorBank?.accountNumber || null,
          creditCardNumber:
            this.model.directPurchasePayment?.creditCardNumber || null,
          note: this.model.procurementVendor.note,
          proofDocList: this.fileUploader.fileObjectList,
          isContract: this.isContract
        });

        this.selectedVendorBank = this.model.directPurchasePayment?.vendorBank;

        /** jagain tampilan field ketika sudah dari awal berada di kondisi paid true/payment method tertentu */
        this.onChangeIsPaid(this.formGroup.value.isPaid);
        if (this.formGroup.value.paymentMethod) {
          this.onChangePaymentMethod(this.formGroup.value.paymentMethod);
        }
        this.setStateReady();

        if (this.vendorView && !this.vendorView.isManual) {
          /** promise vendor */
          const formControlNameList = [
            'name',
            'email',
            'phone',
            'picName',
            'address',
            'npwp',
            'paymentMethod',
            'accountHolder',
            'accountNumber'
          ];
          formControlNameList.forEach(controlName => {
            this.formGroup.get(controlName).setIsView(true);
          });
        }
      }
    } else {
      /** new vendor */
      this.setStateReady();
    }

    if (this.isView) {
      this.setViewOnly();
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const vendor: Vendor = new Vendor();
            vendor.id = this.vendorView?.id;
            vendor.registrationNumber = this.vendorView?.registrationNumber;
            vendor.name = this.formGroup.get('name').value;
            vendor.email = this.formGroup.get('email').value;
            vendor.phone = this.formGroup.get('phone').value?.number;
            vendor.phoneRegion = this.formGroup.get('phone').value?.country;
            vendor['picName'] = this.formGroup.get('picName').value;
            vendor['address'] = this.formGroup.get('address').value;
            vendor.taxNumber = this.formGroup.get('npwp').value;
            vendor.isManual =
              this.vendorView && !this.vendorView.isManual ? false : true;

            const procurementVendor = new ProcurementVendor();
            procurementVendor.vendor = vendor;
            procurementVendor.isRegistered = true;
            procurementVendor.isPaid = this.formGroup.value.isPaid;
            procurementVendor.isReceived = this.formGroup.value.isReceived;
            procurementVendor.note = this.formGroup.value.note;
            if (this.formGroup.get('isContract').value) {
              procurementVendor.procurement.agreementType.code = 'CONTRACT';
            } else {
              procurementVendor.procurement.agreementType = null;
            }

            let directPurchasePayment: DirectPurchasePayment =
              new DirectPurchasePayment();
            const fileObjectList = this.fileUploader.fileObjectList;
            if (this.formGroup.value.isPaid) {
              /** isPaid true */
              const vendorBank = new VendorBank();
              vendorBank.id =
                this.selectedVendorBank?.id; /** null jika vendor bank existing blm ada */
              vendorBank.isPriority = this.selectedVendorBank
                ? this.selectedVendorBank.isPriority
                : true;
              vendorBank.bank = this.formGroup.value.bank;
              vendorBank.accountHolder = this.formGroup.value.accountHolder;
              vendorBank.accountNumber = this.formGroup.value.accountNumber;

              directPurchasePayment.paymentMethod =
                this.formGroup.value.paymentMethod;
              directPurchasePayment.vendorBank =
                directPurchasePayment.paymentMethod.code ===
                this.global.appConstant.cm.PAYMENT_METHOD_BANK_TRANSFER
                  ? vendorBank
                  : null;
              directPurchasePayment.creditCardNumber =
                directPurchasePayment.paymentMethod.code ===
                this.global.appConstant.cm.PAYMENT_METHOD_COMPANY_CREDIT_CARD
                  ? this.formGroup.value.creditCardNumber
                  : null;
              directPurchasePayment.invoiceNumber =
                this.formGroup.value.invoiceNumber;
              directPurchasePayment.invoiceDate =
                this.formGroup.value.invoiceDate;
              directPurchasePayment.billAmount =
                this.formGroup.value.billAmount.price;
            } else {
              directPurchasePayment = null;
            }

            const vendorModel: InitiationDirectPurchaseVendorModel = {
              procurementVendor,
              directPurchasePayment,
              fileObjectList,
              vendorBankList: this.vendorBankList,
              directPurchasePaymentDocList: null
            };

            this.log.debug('emit vendorModel from popup add vendor');
            this.log.debug(vendorModel);
            this.onChange.emit(vendorModel);
          }
        });
    }
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public onChangeIsPaid(isPaid: boolean): void {
    const formControlNameList = [
      'invoiceNumber',
      'invoiceDate',
      'billAmount',
      'paymentMethod',
      'bank',
      'accountHolder',
      'accountNumber',
      'proofDocList'
    ];
    if (isPaid) {
      formControlNameList.forEach(controlName => {
        this.formGroup.get(controlName).setValidators([Validators.required()]);
        this.formGroup.get(controlName).updateValueAndValidity();
      });

      if (this.vendorView && !this.vendorView.isManual) {
        this.formGroup.patchValue({
          paymentMethod: this.paymentMethodList.find(
            pmethod =>
              pmethod.code ===
              this.global.appConstant.cm.PAYMENT_METHOD_BANK_TRANSFER
          )
        });
        this.formGroup.get('paymentMethod').setIsView(true);
        this.formGroup.get('accountHolder').setIsView(true);
        this.formGroup.get('accountNumber').setIsView(true);
      }

      if (this.vendorBankList[0]) {
        this.formGroup.patchValue({
          bank: this.vendorBankList[0].bank,
          accountHolder: this.vendorBankList[0].accountHolder,
          accountNumber: this.vendorBankList[0].accountNumber
        });
        this.selectedVendorBank = this.vendorBankList[0];
      }
    } else {
      formControlNameList.forEach(controlName => {
        this.formGroup.get(controlName).clearValidators();
        this.formGroup.get(controlName).updateValueAndValidity();
      });
    }
    this.setContinueDirectPurchaseToContract();
  }

  public onChangePaymentMethod(paymentMethod: PaymentMethod): void {
    this.log.debug(paymentMethod);

    if (
      paymentMethod.code ===
      this.global.appConstant.cm.PAYMENT_METHOD_COMPANY_CREDIT_CARD
    ) {
      this.formGroup.get('bank').clearValidators();
      this.formGroup.get('accountHolder').clearValidators();
      this.formGroup.get('accountNumber').clearValidators();
      this.formGroup
        .get('creditCardNumber')
        .setValidators([Validators.required()]);
    } else if (
      paymentMethod.code === this.global.appConstant.cm.PAYMENT_METHOD_CASHIER
    ) {
      this.formGroup.get('bank').clearValidators();
      this.formGroup.get('accountHolder').clearValidators();
      this.formGroup.get('accountNumber').clearValidators();
      this.formGroup.get('creditCardNumber').clearValidators();
    } else {
      this.formGroup.get('bank').setValidators([Validators.required()]);
      this.formGroup
        .get('accountHolder')
        .setValidators([Validators.required()]);
      this.formGroup
        .get('accountNumber')
        .setValidators([Validators.required()]);
      this.formGroup.get('creditCardNumber').clearValidators();
    }

    this.formGroup.get('bank').updateValueAndValidity();
    this.formGroup.get('accountHolder').updateValueAndValidity();
    this.formGroup.get('accountNumber').updateValueAndValidity();
    this.formGroup.get('creditCardNumber').updateValueAndValidity();
  }

  public onChangeBank(bank: Bank): void {
    const vendorBank = this.vendorBankList?.find(
      vendorBank => vendorBank?.bank.id === bank?.id
    );
    this.selectedVendorBank =
      vendorBank; /** ada selected utk jagain id vendor bank jika milih existing atau mau insert vendor bank baru */
    if (vendorBank) {
      this.formGroup.patchValue({
        accountHolder: vendorBank.accountHolder,
        accountNumber: vendorBank.accountNumber
      });
    }
  }

  public setContinueDirectPurchaseToContract(): void {
    const isManual =
      this.vendorView === null || this.vendorView === undefined
        ? true
        : this.vendorView.isManual;
    if (
      isManual ||
      this.formGroup.value.isPaid ||
      this.formGroup.value.isReceived
    ) {
      this.isContractView = false;
      this.isContract = false;
      this.formGroup.get('isContract').patchValue(false);
    } else {
      this.isContractView = true;
      this.isContract =
        !this.model ||
        this.model.procurementVendor.procurement.agreementType?.code ===
          'CONTRACT'
          ? true
          : false;
      this.formGroup.get('isContract').patchValue(this.isContract);
    }
  }
}
