import { ArrayUtils } from 'src/app/core/utils/array.utils';
import { HrefPlugin } from '../interfaces/available-table-plugin';
import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';

export const appTableHrefPlugin: TablePlugin = {
  name: 'href',
  before: (data: TablePluginData): void => {
    const hrefPlugin = data.column.plugins as HrefPlugin;
    const url = hrefPlugin.url; 

    const uuid = hrefPlugin.field
    ? ArrayUtils.resolveFieldData(data.record.record, hrefPlugin.field)
    : data.value;
    
    const anchorElement = document.createElement('a');
    anchorElement.href = url + uuid;
    anchorElement.target = '_blank'; 
    anchorElement.classList.add('td-text-hyperlink');

    if (hrefPlugin && hrefPlugin.customText) {
      anchorElement.textContent = hrefPlugin.customText;
    } else {
      anchorElement.textContent = data.value;
    }

    data.element.innerHTML = ''; 
    data.element.appendChild(anchorElement);

  }
};
