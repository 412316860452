<app-layout
  header="report-recap.header.reportBuilder"
  [isLoading]="formLoading"
>
  <app-card>
    <h3 class="separator">{{
      'report-recap.section.header.reportInformation' | translate
    }}</h3>
    <app-fullfilled *ngIf="!isAllValid"></app-fullfilled>
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'report-recap.form.reportName' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="LG"
            formControlName="name"
            maxLength="64"
            placeholder="{{
              'report-recap.form.placeholder.reportName' | translate
            }}"
            (onChange)="doChangeName()"
          ></app-text-field>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'report-recap.form.selectModule' | translate
        }}</label>
        <div class="col-sm-9 text-left" [ngStyle]="{ display: 'flex' }">
          <div
            class="radio-card cursor-pointer"
            *ngFor="let moduleGroup of reportModuleGroupList"
            [ngClass]="
              activeModuleId && activeModuleId === moduleGroup.id
                ? 'active-card'
                : 'non-active-card'
            "
            (click)="doChooseModule(moduleGroup)"
          >
            <div class="radio-card-item">
              <em
                *ngIf="
                  moduleGroup.code ===
                  global.appConstant.pm.REPORT_MODULE_GROUP_GENERAL
                "
                class="pir pi-file-alt pi-3x icon-card-module"
                [ngStyle]="{ 'background-color': '#009DC1' }"
              ></em>
              <em
                *ngIf="
                  moduleGroup.code ===
                  global.appConstant.pm.REPORT_MODULE_GROUP_ESM
                "
                class="pir pi-dollar-sign-circle pi-3x icon-card-module"
                [ngStyle]="{ 'background-color': 'var(--progress)' }"
              ></em>
              <em
                *ngIf="
                  moduleGroup.code ===
                  global.appConstant.pm.REPORT_MODULE_GROUP_VM
                "
                class="pir pi-user pi-3x icon-card-module"
                [ngStyle]="{ 'background-color': 'var(--service)' }"
              ></em>
              <em
                *ngIf="
                  moduleGroup.code ===
                  global.appConstant.pm.REPORT_MODULE_GROUP_PM
                "
                class="pir pi-clipboard pi-3x icon-card-module"
                [ngStyle]="{ 'background-color': 'var(--feedback)' }"
              ></em>
              <em
                *ngIf="
                  moduleGroup.code ===
                  global.appConstant.pm.REPORT_MODULE_GROUP_CM
                "
                class="pir pi-user-cog pi-3x icon-card-module"
                [ngStyle]="{ 'background-color': 'var(--info)' }"
              ></em>
              <h5>{{ moduleGroup.name }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'report-recap.form.selectReport' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-dropdown-select
            size="LG"
            type="COMBOBOX"
            formControlName="report"
            [optionList]="reportRecapOptionList"
            (onChange)="doChooseReport()"
            container="body"
          ></app-dropdown-select>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'report-recap.form.reportDescription' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-area
            size="LG"
            formControlName="description"
            maxLength="100"
            placeholder="{{
              'report-recap.form.placeholder.description' | translate
            }}"
            (onChange)="doChangeDesc()"
          ></app-text-area>
        </div>
      </div>
      <div *ngIf="isPickListReady">
        <h3 class="separator">{{
          'report-recap.section.header.selectColumn' | translate
        }}</h3>
        <app-alert-x *ngIf="!isChose">
          {{ 'report-recap.message.info.minChoose' | translate }}
        </app-alert-x>
        <app-pick-list
          [model]="pickListModel"
          searchSourcePlaceholder="report-recap.pickList.search.placeHolder"
          targetTitle="report-recap.pickList.targetTitle"
          [isShowButtonMoveAllItem]="false"
        >
        </app-pick-list>
      </div>
    </form>
  </app-card>
  <div class="button-group button-group-center">
    <app-button
      *ngIf="reportBuilderId"
      color="DANGER"
      [outline]="true"
      (onClick)="doDelete()"
      [disabled]="formSaving"
      >{{ 'report-recap.button.deleteCustomReport' | translate }}
    </app-button>
    <app-button
      (onClick)="doSave()"
      [disabled]="
        formSaving ||
        !isPickListReady ||
        pickListModel.targetItemList.length < 3
      "
      >{{ 'report-recap.button.saveCustomReport' | translate }}
    </app-button>
  </div>
</app-layout>
