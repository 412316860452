import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppChooseModule } from '../app-choose/app-choose.module';
import { AppDropDownModule } from '../app-drop-down/app-drop-down.module';
import { AppTaggingModule } from '../app-tagging/app-tagging.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppTreeXModule } from '../app-tree-x';
import { AppComboBoxTreeComponent } from './app-combo-box-tree.component';

@NgModule({
  imports: [
    VendorModule,
    AppChooseModule,
    AppTooltipWrapperModule,
    AppDropDownModule,
    AppTreeXModule,
    AppTaggingModule,
    AppButtonModule
  ],
  declarations: [AppComboBoxTreeComponent],
  exports: [AppComboBoxTreeComponent]
})
export class AppComboBoxTreeModule {}
