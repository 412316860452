<app-popup
  header="procurement-reg-buyer.popup.docPqCheckList"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="todo === 'view'"
>
  <div class="col popup-pra-qualification-requirement" *ngIf="!formLoading">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      *ngIf="!formLoading"
      novalidate
    >
      <h5 class="separator"
        >{{ 'procurement-reg-buyer.popup.separator.vendorInfo' | translate }}
      </h5>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'procurement-reg-buyer.popup.vendorInfo.vendorName' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <a
            (click)="goToProfile()"
            class="text-primary"
            style="text-decoration: underline"
            >{{ procurementVendor.vendor.name }}
          </a>
          <span class="ml-1" (click)="goToProfile()"
            ><em
              class="pir pi-external-link"
              style="color: var(--primary)"
              placement="top"
              ngbTooltip="{{
                'procurement-reg-buyer.popup.tooltip.goToProfile' | translate
              }}"
            ></em
          ></span>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'procurement-reg-buyer.popup.vendorInfo.responseDate' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{
            procurementVendor.registeredDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}
        </div>
      </div>
      <br />
      <div *ngIf="procurementVendor.procurement.isPq">
        <h5 class="separator">
          {{
            'procurement-reg-buyer.popup.separator.documentCheck' | translate
          }}
        </h5>
        <app-table-xx
          [model]="tableResponse"
          [isView]="todo === 'view'"
          [isShowSearch]="false"
          [isShowPagination]="false"
        >
        </app-table-xx>

        <div style="display: flex" *ngIf="isAllVerified && statusDoc">
          <span>
            <span
              >{{
                'procurement-reg-buyer.popup.msg.verificationResult'
                  | translate
              }}{{ ' ' }}</span
            ><span>{{ procurementVendor.vendor.name }}</span>

            <span
              *ngIf="
                statusDoc.code ===
                global.appConstant.pm.PROC_PQ_DOC_BUYER_STATUS_ACCEPTED
              "
            >
              <span class="ml-1">{{
                ('procurement-reg-buyer.popup.msg.was' | translate) + ' '
              }}</span
              ><span class="text-success">{{
                ('procurement-reg-buyer.popup.status.accepted' | translate) +
                  ' '
              }}</span
              ><span>{{
                'procurement-reg-buyer.popup.msg.oneOfParticipant' | translate
              }}</span>
            </span>

            <span
              *ngIf="
                statusDoc.code ===
                global.appConstant.pm.PROC_PQ_DOC_BUYER_STATUS_REVISION
              "
            >
              <span class="ml-1">{{
                ('procurement-reg-buyer.popup.msg.hasTo' | translate) + ' '
              }}</span
              ><span class="text-primary">{{
                ('procurement-reg-buyer.popup.status.revision' | translate) +
                  ' '
              }}</span
              ><span>{{
                'procurement-reg-buyer.popup.msg.docToAccepted' | translate
              }}</span>
            </span>

            <span
              *ngIf="
                statusDoc.code ===
                global.appConstant.pm.PROC_PQ_DOC_BUYER_STATUS_DECLINED
              "
            >
              <span class="ml-1">{{
                ('procurement-reg-buyer.popup.msg.was' | translate) + ' '
              }}</span
              ><span class="text-danger">{{
                ('procurement-reg-buyer.popup.status.declined' | translate) +
                  ' '
              }}</span
              ><span>{{
                'procurement-reg-buyer.popup.msg.oneOfParticipant' | translate
              }}</span>
            </span>
          </span>
        </div>
      </div>

      <ng-template #modalFooter let-activeModal>
        <div class="col-12 text-center">
          <app-button
            color="SECONDARY"
            [outline]="true"
            (onClick)="activeModal.close(true)"
            >{{ 'app.button.close' | translate }}</app-button
          >
          <app-button
            *ngIf="procurementVendor.procurement.isPq"
            color="PRIMARY"
            (onClick)="doSave()"
            [disabled]="todo === 'view'"
            >{{ 'app.button.save' | translate }}</app-button
          >
        </div>
      </ng-template>
    </form>
  </div>
</app-popup>
