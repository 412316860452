<div class="row main-row m-0 p-0">
  <div class="col-lg-12 mb-4 m-0 p-0">
    <app-card
      header="attribute.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'attribute.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              size="SM"
              formControlName="code"
              type="code"
              tooltip="{{ 'attribute.tooltip.code' | translate }}"
              maxLength="32"
            ></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right mb-1">
          <label class="col-sm-3 control-label text-right">{{
            'attribute.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <ng-container formArrayName="translationList">
              <div
                class="form-group row"
                *ngFor="
                  let translation of translationList.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <div [ngStyle]="{ 'padding-left': '1%' }"></div>
                <label class="col-sm-0 text-left control-label required">{{
                  translationListLabel[i]
                }}</label>
                <div class="col-sm-4 text-left">
                  <app-text-field
                    size="XL"
                    formControlName="value"
                    maxLength="64"
                  ></app-text-field>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'attribute.form.className' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="className" maxLength="128">
            </app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required mb-2">
          <label class="col-sm-3 control-label">{{
            'attribute.form.objectType' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              [optionList]="formObjectOptionList"
              formControlName="formObject"
              (onChange)="showFormOption()"
              tooltip="{{ 'attribute.tooltip.typeObject' | translate }}"
            >
            </app-combo-box>
          </div>
        </div>

        <ng-container *ngIf="formObjectValue" formArrayName="credentials">
          <div
            class="form-group row text-sm-right"
            *ngFor="let creds of inputList.controls; let i = index"
            [formGroupName]="i"
          >
            <label class="col-sm-3 control-label">{{
              'attribute.form.option' | translate
            }}</label>
            <div class="col-sm-8 col-lg-7 text-left">
              <div class="form-row">
                <div class="form-group row col-sm-4">
                  <div class="col text-left">
                    <app-text-field
                      size="XL"
                      formControlName="name"
                      placeholder="Name"
                    ></app-text-field>
                  </div>
                </div>
                <div class="form-group row col-sm-4">
                  <div class="col text-left">
                    <app-text-field
                      size="XL"
                      formControlName="value"
                      placeholder="Value"
                    ></app-text-field>
                  </div>
                </div>
                <div class="form-group row col-sm-4">
                  <div class="col text-left">
                    <button
                      class="btn btn-secondary btn-sm mr-2"
                      type="button"
                      (click)="doRemove(i)"
                    >
                      <span class="fa fas fa-trash"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="text-center" *ngIf="formObjectValue">
          <button class="btn btn-primary" (click)="addCreds()"
            ><em class="fas fa-plus"></em>
            {{ 'app.button.add' | translate }}</button
          >
        </div>

        <br />

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'attribute.form.description' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area
              formControlName="description"
              maxLength="512"
              appAlphaNumeric
            ></app-text-area>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button color="SECONDARY" class="mr-1" (onClick)="doCancel()">
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button color="PRIMARY" (onClick)="doSave()">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
