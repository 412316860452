import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Order } from 'src/app/core/bean/order';
import { OrderStatus } from 'src/app/core/bean/order-status';
import { Organization } from 'src/app/core/bean/organization';
import { TableResponseModel } from 'src/app/core/components/app-table-x/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { FieldFormatEnum } from 'src/app/core/components/app-table-x/model/field-format.enum';
import { OrderOptionListResponse } from './order-option-list.response';

@Component({
  templateUrl: './pr-po-verification.component.html'
})
export class PrPoVerificationComponent extends BaseModuleComponent implements OnInit {
  public filter: string;
  public tableResponse: TableResponseModel<Order>;
  public statusOptionList: OptionListModel<OrderStatus> = new OptionListModel(
    true
  );
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);
  public postDateFrom: String;
  public postDateTo: String;
  public requiredDateFrom: String;
  public requiredDateTo: String;

  constructor(translateService: TranslateService) {
    super('pr-po-verification', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.doBuildTableResponse();
    this.buildSetFormGroup();
    this.doSetCustomData();
  }

  public setDataFromRouterParams(): void {
    this.filter = this.global.routerParams.get('filter');
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      'order',
      [
        {
          field: 'code',
          header: 'table.column.orderNumber',
          plugins: {
            name: 'hyperlink',
            className: 'white-space-normal'
          }
        },
        {
          field: 'orderType.name',
          header: 'table.column.orderType'
        },
        {
          field: 'postDate',
          header: 'table.column.postDate',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'requiredDate',
          header: 'table.column.requiredDate',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'title',
          header: 'table.column.title'
        },
        {
          field: 'organization.name',
          header: 'table.column.department'
        },
        {
          field: 'vendor.name',
          header: 'table.column.vendorName',
          sortable: false
        },
        {
          field: 'amount',
          header: 'table.column.totalCost',
          format: FieldFormatEnum.Currency
        },
        {
          field: 'orderStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              DRAFT: 'SECONDARY',
              REVISION: 'FEEDBACK',
              WAITING_APPROVAL: 'WARNING',
              ON_PROGRESS: 'PROGRESS',
              DONE: 'SUCCESS',
              CLOSED: 'DANGER',
              CANCELED: 'DANGER',
              WAITING_CONFIRMATION: 'WARNING',
              VENDOR_REVISION: 'FEEDBACK',
              REJECTED: 'DANGER'
            },
            field: 'orderStatus.code'
          }
        }
      ],
      { checkBoxFn: this.isShowCheckBox.bind(this) }
    );
  }

  public isShowCheckBox(record: Order): boolean {
    let isStatusDraft = false;
    if (
      record.orderStatus.code !== this.global.appConstant.pm.ORDER_STATUS_DRAFT
    ) {
      isStatusDraft = false;
    } else {
      isStatusDraft = true;
    }
    return isStatusDraft;
  }

  public buildSetFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      postDate: [null],
      requiredDate: [null],
      organizationList: [null],
      statusList: [null]
    });

    this.httpClientService
      .get<OrderOptionListResponse>('/pr-po-verification/get-option-list', {})
      .subscribe((response: OrderOptionListResponse) => {
        this.statusOptionList.setRequestValues(response.statusList);
        this.organizationOptionList.setRequestValues(response.organizationList);

        this.setStateReady();
      });
  }

  public doSetCustomData(): void {
    if (this.filter) {
      const customData = {
        statusCode: this.filter
      };
      this.global.routerParams.clear();
      this.tableResponse.setCustomData(customData);
      this.tableResponse.reload();
    }
  }

  public onChangeFilter(event: any): void {
    this.log.debug(event);
    const customData = this.formGroup.value;
    customData.postDateFrom = this.formGroup.value.postDate?.from || null;
    this.postDateFrom = customData.postDateFrom;
    customData.postDateTo = this.formGroup.value.postDate?.to || null;
    this.postDateTo = customData.postDateTo;
    customData.requiredDateFrom =
      this.formGroup.value.requiredDate?.from || null;
    this.requiredDateFrom = customData.requiredDateFrom;
    customData.requiredDateTo = this.formGroup.value.requiredDate?.to || null;
    this.requiredDateTo = customData.requiredDateTo;

    this.tableResponse.setCustomData(customData);
    this.tableResponse.reload();
  }

  public doClick(order: Order): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('orderId', order.id);
    this.global.routerParams.set('orderStatus', order.orderStatus);
    this.global.routerParams.set('urlBackOutside', '/pages/pr-po-verification');
    if (
      order.orderStatus.code === this.global.appConstant.pm.ORDER_STATUS_REVISION_VERIFICATION ||
      order.orderStatus.code === this.global.appConstant.pm.ORDER_STATUS_WAITING_APPROVAL
    ) {
      this.global.routerParams.set('todo', 'view');
      this.router.navigate(['/pages/pr-po-verification/detail']);
    } else {
      this.global.routerParams.set('todo', 'edit');
      this.router.navigate(['/pages/pr-po-verification/edit']);
    }
  }

}
