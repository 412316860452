import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';
import { Currency } from '../../../core/bean/currency';
import { Region } from '../../../core/bean/region';
import { Segmentation } from '../../../core/bean/segmentation';
import { VendorExperience } from '../../../core/bean/vendor-experience';
import { VendorExperienceHistory } from '../../../core/bean/vendor-experience-history';
import { VendorExperienceSegmentationHistory } from '../../../core/bean/vendor-experience-segmentation-history';
import { VendorExperienceStatus } from '../../../core/bean/vendor-experience-status';
import { FileUploader } from '../../../core/components/upload';
import { OptionListModel } from '../../../core/model/option-list-model';
import { Validators } from '../../../core/validators';
import { VendorPopupVendorPartnershipDTO } from '../dto/vendor-popup-vendor-partnership.dto';

@Component({
  templateUrl: './app-popup-partnership.component.html'
})
export class AppPopupPartnershipComponent extends BaseComponentComponent {
  @Input() public todo: string;
  @Input() public vendorPartnershipHistoryEdit: VendorExperienceHistory;
  @Input() public vendorPartnershipHistoryList: VendorExperienceHistory[];
  @Input() public vendorPartnership: VendorExperience;
  @Input() public vendorPartnershipList: VendorExperience[];
  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();

  public cityList: Region[] = [];
  public valueCurrency = '';
  public valueCurrencyActual = '';
  public isVendorList: boolean;
  public fromOutside: string;
  public fileUploader: FileUploader = new FileUploader(
    '/tab-profile-partnership/',
    'partnership',
    this.global.appConstant.fileType.DOC_PROOF_CONTRACT
  );
  public vendorPopupVendorPartnershipDTO: VendorPopupVendorPartnershipDTO =
    new VendorPopupVendorPartnershipDTO();
  public segmentationList: Segmentation[] = [];
  public experienceSegmentationHistoryList: VendorExperienceSegmentationHistory[] =
    [];
  public date: string;
  public dateHistory: string;
  public countryRegionId: Object;

  public countryRegionOptionList: OptionListModel<Region> = new OptionListModel(
    true
  );
  public cityRegionOptionList: OptionListModel<Region> = new OptionListModel(
    true
  );
  public vendorExperienceStatusOptionList: OptionListModel<VendorExperienceStatus> =
    new OptionListModel(true);
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );

  constructor(public datePipe: DatePipe, public activeModal: NgbActiveModal) {
    super('tab-profile-partnership');
  }

  public onInit(): void {
    this.setParam();
    this.getVendorPartnership();
    this.buildFormGroup();
    this.setFormGroup();
    if (this.todo === 'detail') {
      this.setViewOnly();
    }
  }

  public setParam(): void {
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
  }

  public buildFormGroup(): void {
    const newDate: Date = new Date();
    this.formGroup = this.formBuilder.group({
      partnership: [null, Validators.required()],
      countryRegion: [null, Validators.required()],
      cityRegion: [null],
      address: [null, Validators.maxLength(512)],
      projectOwner: [null, Validators.required()],
      projectType: [null, Validators.required()],
      segmentationList: [null, Validators.required()],
      vendorExperienceStatus: [null, Validators.required()],
      contractNumber: [null, Validators.required()],
      contractDate: [null, Validators.required()],
      currencyValue: [null],
      coordinateLocation: [null],
      contractFile: [null],
      fileUploader: [null],
      id: [null],
      partnershipStatus: [null],
      newDate,
      vendorExperienceId: [null],
      experienceSegmentationHistoryList: [null]
    });
  }

  public setFormGroup(): void {
    this.getAppPopupVendorAddressByIdFromServer(
      '/tab-profile-partnership/add-edit-popup-vendor-partnership'
    ).subscribe(vendorPopupVendorPartnershipDTO => {
      this.vendorPopupVendorPartnershipDTO = vendorPopupVendorPartnershipDTO;
      this.setOptionModelList();
      if (this.vendorPartnershipHistoryEdit !== undefined) {
        const {
          id,
          partnership,
          countryRegion,
          cityRegion,
          address,
          projectOwner,
          projectType,
          vendorExperienceStatus,
          partnershipStatus,
          contractNumber,
          vendorExperienceId,
          file
        } = this.vendorPartnershipHistoryEdit;
        this.countryRegionId = {
          countryRegionId: this.vendorPartnershipHistoryEdit.countryRegion.id
        };
        this.fileUploader.setFile(file);
        if (
          this.vendorPartnershipHistoryEdit.fileObjectList &&
          this.vendorPartnershipHistoryEdit.fileObjectList.length !== 0
        ) {
          this.fileUploader.fileObjectList =
            this.vendorPartnershipHistoryEdit.fileObjectList;
        }
        const coordinateLocation =
          this.vendorPartnershipHistoryEdit.coordinateLocation;
        let contractDate: any;
        if (this.vendorPartnershipHistoryEdit.contractDate !== null) {
          contractDate = new Date(
            this.vendorPartnershipHistoryEdit.contractDate
          );
        } else {
          contractDate = null;
        }
        if (
          this.vendorPartnershipHistoryEdit.currency !== null &&
          this.formGroup.isView
        ) {
          this.formGroup.patchValue({
            currencyValue: {
              currency: this.vendorPartnershipHistoryEdit.currency,
              price: this.global.converterService.convertMoney(
                this.vendorPartnershipHistoryEdit.contractValue.toString()
              )
            }
          });
        } else if (this.vendorPartnershipHistoryEdit.currency !== null) {
          this.formGroup.patchValue({
            currencyValue: {
              currency: this.vendorPartnershipHistoryEdit.currency,
              price: this.vendorPartnershipHistoryEdit.contractValue
            }
          });
        }
        this.vendorPartnershipHistoryEdit.vendorExperienceSegmentationHistoryList.forEach(
          experienceSegmentationHistory => {
            if (experienceSegmentationHistory.segmentation !== null) {
              this.segmentationList.push(
                experienceSegmentationHistory.segmentation
              );
            }
          }
        );
        const segmentationList = this.segmentationList;
        this.formGroup.patchValue({
          id,
          partnership,
          address,
          cityRegion,
          vendorExperienceStatus,
          projectOwner,
          projectType,
          contractNumber,
          contractFile: this.fileUploader.fileObjectList,
          fileUploader: this.fileUploader,
          countryRegion,
          segmentationList,
          contractDate,
          coordinateLocation,
          partnershipStatus,
          vendorExperienceId,
          experienceSegmentationHistoryList:
            this.vendorPartnershipHistoryEdit
              .vendorExperienceSegmentationHistoryList
        });
      }
      this.setStateReady();
    });
  }

  public setOptionModelList(): void {
    this.countryRegionOptionList.setRequestValues(
      this.vendorPopupVendorPartnershipDTO.countryRegionList
    );
    this.vendorExperienceStatusOptionList.setRequestValues(
      this.vendorPopupVendorPartnershipDTO.vendorExperienceStatusList
    );
    this.currencyOptionList.setRequestValues(
      this.vendorPopupVendorPartnershipDTO.currencyList
    );
  }

  public doChangeCountryRegion(): void {
    this.formGroup.patchValue({
      cityRegion: null
    });
    this.countryRegionId = {
      countryRegionId: this.formGroup.value.countryRegion.id
    };
  }

  public getVendorPartnership(): void {
    if (this.vendorPartnershipList) {
      this.vendorPartnershipHistoryList.forEach(experienceHistory => {
        if (experienceHistory === this.vendorPartnershipHistoryEdit) {
          this.vendorPartnershipList.forEach(partnership => {
            if (partnership.id === experienceHistory.vendorExperienceId) {
              this.vendorPartnership = partnership;
              if (this.vendorPartnership.contractDate !== null) {
                this.date = this.datePipe.transform(
                  new Date(this.vendorPartnership.contractDate),
                  this.global.config.parameterModel.dateFormatShort
                );
              }
              if (this.vendorPartnership.currency !== null) {
                this.valueCurrencyActual =
                  this.global.converterService.convertMoney(
                    this.vendorPartnership.contractValue.toString()
                  ) +
                  ' ' +
                  this.vendorPartnership.currency.code;
              }
            }
          });
        }
      });
      if (this.vendorPartnership === undefined) {
        this.vendorPartnership = new VendorExperience();
      }
    }
    if (this.vendorPartnershipHistoryEdit !== undefined) {
      this.dateHistory = this.datePipe.transform(
        new Date(this.vendorPartnershipHistoryEdit.contractDate),
        this.global.config.parameterModel.dateFormatShort
      );
      this.valueCurrency =
        this.vendorPartnershipHistoryEdit.contractValue !== null
          ? this.global.converterService.convertMoney(
              this.vendorPartnershipHistoryEdit.contractValue.toString()
            ) +
            ' ' +
            this.vendorPartnershipHistoryEdit.currency.code
          : null;
    }
  }

  public getAppPopupVendorAddressByIdFromServer(
    urlVendorExperiencePopup: string
  ): Observable<VendorPopupVendorPartnershipDTO> {
    // tslint:disable-next-line: max-line-length
    return this.httpClientService.post<VendorPopupVendorPartnershipDTO>(
      urlVendorExperiencePopup,
      this.vendorPartnershipHistoryEdit != null
        ? this.vendorPartnershipHistoryEdit
        : new VendorExperienceHistory()
    );
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.value.experienceSegmentationHistoryList !== null) {
      const segmentationList: Segmentation[] =
        this.formGroup.value.segmentationList;
      const experienceSegmentationHistoryList: VendorExperienceSegmentationHistory[] =
        this.formGroup.value.experienceSegmentationHistoryList;
      segmentationList.forEach(segmentation => {
        experienceSegmentationHistoryList.forEach(expSegHistory => {
          if (segmentation.code === expSegHistory.segmentation.code) {
            this.experienceSegmentationHistoryList.push(expSegHistory);
          }
        });
      });
      this.formGroup.patchValue({
        experienceSegmentationHistoryList:
          this.experienceSegmentationHistoryList
      });
    }
    if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup.value);
      this.activeModal.dismiss();
    }
  }
}
