<input
  #inputFileAdd
  type="file"
  (change)="onUploadChange($event)"
  multiple
  hidden
  aria-label="choose-file"
/>
<div class="app-upload-multiple p-0" [ngClass]="elementWidthClassName">
  <div
    class="button-group button-group-right button-group-icon-text"
    *ngIf="!ISVIEW"
  >
    <app-button
      size="SM"
      (onClick)="inputFileAdd.click()"
      *ngIf="model.totalUploaded < model.options.maxFile"
      [attr.title]="
        ('app-upload.title.fileExtension' | translate) +
        model.options.allowedExtension +
        '\n' +
        ('app-upload.title.maxSize' | translate) +
        (model.options.maxSize / 1000000 | number: '1.2-2') +
        'MB'
      "
    >
      <em class="fas fa-plus"></em>Browse
    </app-button>
    <app-button size="SM" color="DANGER" (onClick)="doDeleteFile()">
      <em class="fas fa-trash"></em>Delete
    </app-button>
  </div>
  <ul class="upload-content">
    <li
      class="upload-item header"
      [ngClass]="[
        disabled ? 'bg-secondary' : isInvalid ? 'bg-danger' : 'bg-primary'
      ]"
    >
      <div class="item-content">File</div>
      <div class="item-action" *ngIf="!ISVIEW">Action</div>
      <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
      </ng-container>
    </li>
    <li
      uploadDragAndDrop
      (onChange)="onDragAndDropChange($event)"
      class="upload-item no-data cursor-pointer"
      [ngClass]="isInvalid ? 'is-invalid' : ''"
      (click)="inputFileAdd.click()"
      *ngIf="!ISVIEW && model.uploaderFileList.length === 0"
      [attr.title]="
        ('app-upload.title.fileExtension' | translate) +
        model.options.allowedExtension +
        '\n' +
        ('app-upload.title.maxSize' | translate) +
        (model.options.maxSize / 1000000 | number: '1.2-2') +
        'MB'
      "
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    >
      <div class="item-icon">
        <em
          class="fas fa-2x mb-2"
          [ngClass]="
            isDragOver
              ? 'fa-cloud-download-alt text-primary'
              : 'fa-cloud-upload-alt'
          "
        ></em>
      </div>
      <p class="drag-and-drop-text m-0">
        <ng-container *ngIf="!isDragOver">Drag files here</ng-container>
        <ng-container *ngIf="isDragOver">Drop files here</ng-container>
      </p>
      <p class="browser-files m-0">Click to browse files</p>
    </li>
    <li
      class="upload-item"
      *ngFor="let uploaderFile of model.uploaderFileList; let i = index"
    >
      <div class="item-content">
        <div class="item-content-image">
          <img
            [attr.src]="
              uploaderFile.isNew || uploaderFile.isChange
                ? uploaderFile.src
                : (uploaderFile | resolveUploadSrc | async)
            "
            [attr.alt]="uploaderFile.file.fileName"
            class="img-thumbnail"
            width="0"
            height="0"
          />
        </div>
        <span class="item-content-text">
          <ng-container
            *ngIf="
              uploaderFile.status === 'UPLOADED';
              then uploadedTextTmpl;
              else notUploadedTextTmpl
            "
          >
          </ng-container>
          <ng-template #uploadedTextTmpl>
            <a
              [attr.href]="uploaderFile | resolveUploadHref"
              (click)="doDownloadFile($event, uploaderFile)"
              maxLine
              [attr.title]="
                uploaderFile.file.fileName +
                ' ' +
                (uploaderFile.file.size / 1000000 | number: '1.2-2') +
                'MB'
              "
            >
              <ng-container *ngTemplateOutlet="uploadedText"> </ng-container>
            </a>
          </ng-template>
          <ng-template #notUploadedTextTmpl>
            <a
              maxLine
              [attr.title]="
                uploaderFile.file.fileName +
                ' ' +
                (uploaderFile.file.size / 1000000 | number: '1.2-2') +
                'MB'
              "
            >
              <ng-container *ngTemplateOutlet="uploadedText"> </ng-container>
            </a>
          </ng-template>
          <ng-template #uploadedText>
            {{ uploaderFile.file.fileName }} -
            <small
              >{{
                uploaderFile.file.size / 1000000 | number: '1.2-2'
              }}
              MB</small
            >
          </ng-template>
          <div class="d-block">
            <span
              class="badge"
              [ngClass]="uploadStatusColor[uploaderFile.status]"
              *ngIf="uploaderFile.isNew"
              >{{ uploaderFile.status }}</span
            >
          </div>
        </span>
      </div>
      <div class="item-action" *ngIf="!ISVIEW">
        <div
          class="button-group"
          [ngClass]="
            uploaderFile.status === 'UPLOADED'
              ? 'button-group-right'
              : 'button-group-center'
          "
        >
          <input
            #inputFileChange
            type="file"
            (change)="onUploadChange($event, i)"
            hidden
            aria-label="change-file"
          />
          <ng-container
            *ngIf="
              uploaderFile.status === 'CANCELED' ||
              uploaderFile.status === 'FAILED'
            "
          >
            <app-button size="TN" (onClick)="doReupload(uploaderFile)">
              <em class="fas fa-redo-alt"></em>
            </app-button>
          </ng-container>
          <ng-container
            *ngIf="
              uploaderFile.status === 'UPLOADED' ||
              uploaderFile.status === 'FAILED' ||
              uploaderFile.status === 'CANCELED'
            "
          >
            <app-button
              size="TN"
              (onClick)="inputFileChange.click()"
              [attr.title]="
                ('app-upload.title.fileExtension' | translate) +
                model.options.allowedExtension +
                '\n' +
                ('app-upload.title.maxSize' | translate) +
                (model.options.maxSize / 1000000 | number: '1.2-2') +
                'MB'
              "
            >
              <em class="fas fa-folder-open"></em>
            </app-button>
            <app-button
              size="TN"
              color="DANGER"
              [attr.title]="'app.tooltip.delete' | translate"
              (onClick)="doDeleteFile(i)"
            >
              <em class="fas fa-trash"></em>
            </app-button>
          </ng-container>
          <ng-container *ngIf="uploaderFile.status === 'ONPROGRESS'">
            <app-button
              color="WARNING"
              size="TN"
              (onClick)="doCancelUpload(uploaderFile)"
            >
              <em class="fas fa-times"></em>
            </app-button>
          </ng-container>
        </div>
      </div>
    </li>
    <li
      class="upload-item no-data"
      *ngIf="ISVIEW && model.uploaderFileList.length === 0"
    >
      {{ 'app.info.noData' | translate }}
    </li>
    <li class="upload-item footer" *ngIf="!ISVIEW">
      <div class="item-content" *ngIf="!isInvalid">
        {{ model.totalUploaded }} of {{ model.options.maxFile }} was uploaded
      </div>
      <div class="item-content" *ngIf="isInvalid">
        <small class="text-danger">{{ errors.message }}</small>
      </div>
    </li>
  </ul>
</div>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [symbolic]="symbolic"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
