import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppBadgeComponent } from './app-badge.component';

@NgModule({
    imports: [VendorModule],
    declarations: [AppBadgeComponent],
    entryComponents: [AppBadgeComponent],
    exports: [AppBadgeComponent]
  })
export class AppBadgeModule {

}
