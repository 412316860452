import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { RfpVendorDoc } from '../../core/bean/rfp-vendor-doc';
import { RfpVendorScoring } from '../../core/bean/rfp-vendor-scoring';
import { ScoringStatus } from '../../core/bean/scoring-status';
import { TableColumn } from '../../core/components/table/domain/table-column';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { Validators } from '../../core/validators';
import { RfpVendorView } from '../../core/view/entity/bean/rfp-vendor-view';
import { RfpRequest } from './rfp.request';

@Component({
  templateUrl: './rfp-popup-response.component.html'
})
export class RfpPopupResponseComponent extends BaseComponentComponent {
  @Input() rfpVendorScoringList: RfpVendorScoring[];
  @Input() rfpVendorView: RfpVendorView;
  @Input() isScoring: boolean;
  @Input() todo: string;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  public totalProposalCriteria: number;
  public totalProposalPoint: number;
  public tableResponseProposalCriteria: TableResponseModel<any>;
  public fileUploader: FileUploader = new FileUploader(
    '/rfp/',
    'rfp.form.file',
    'DOC_RFP_TEMPLATE'
  );
  constructor(public activeModal: NgbActiveModal) {
    super('rfp');
  }

  onInit(): void {
    this.buildTableResponse();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      file: [null],
      recordList: this.tableResponseProposalCriteria.formArray
    });
  }

  public setFormGroup(): void {
    const fileList = (
      this.rfpVendorView.rfpVendorDocList as Array<RfpVendorDoc>
    ).map(vendorDoc => vendorDoc.file);
    this.fileUploader.setFileList(fileList);
    this.formGroup.patchValue({
      file: this.fileUploader.fileObjectList
    });
    this.formGroup.get('file').setIsView(true);
    this.tableResponseProposalCriteria.setRecordList(this.rfpVendorScoringList);
    this.tableResponseProposalCriteria.setUpdatedRecordList(
      this.rfpVendorScoringList
    );
    if (
      this.rfpVendorView.rfpVendor.scoringStatus.code !==
      this.global.appConstant.pm.SCORING_STATUS_WAITING_SCORING
    ) {
      this.totalProposalCriteria = 0;
      this.totalProposalPoint = 0;
      this.rfpVendorScoringList.forEach(element => {
        this.totalProposalCriteria += +element.weightScore;
      });
      this.totalProposalPoint =
        (+this.totalProposalCriteria *
          +this.rfpVendorView.rfpVendor.rfp.proposalPercentage) /
        100;
    }
    this.setStateReady();
  }

  public buildTableResponse(): void {
    if (this.todo === 'edit') {
      this.tableResponseProposalCriteria = new TableResponseModel(
        this.moduleCode,
        [
          {
            field: 'rfpProposalCriteria.proposalCriteria.enName',
            header: 'table.column.proposalCriteria'
          },
          {
            field: 'rfpProposalCriteria.weightPercentage',
            header: 'table.column.weightPercentage',
            className: 'text-right',
            plugins: {
              name: 'custom-plugin',
              before: (tablePlugin: TablePluginData): string => {
                return tablePlugin.value + ' %';
              }
            }
          },
          {
            field: 'score',
            header: 'table.column.score',
            className: 'text-right',
            plugins: {
              name: 'text-field',
              type: 'decimal',
              onInput: this.doOnInputScore.bind(this),
              formControl: new FormControl(
                null,
                Validators.compose([Validators.required(), Validators.max(100)])
              )
            }
          },
          {
            field: 'weightScore',
            header: 'table.column.weightedScore',
            className: 'text-right',
            plugins: {
              name: 'text-field',
              type: 'decimal',
              isView: true
            }
          }
        ]
      );
    } else {
      this.tableResponseProposalCriteria = new TableResponseModel(
        this.moduleCode,
        [
          {
            field: 'rfpProposalCriteria.proposalCriteria.enName',
            header: 'table.column.proposalCriteria'
          },
          {
            field: 'rfpProposalCriteria.weightPercentage',
            header: 'table.column.weightPercentage',
            className: 'text-right'
          },
          {
            field: 'score',
            header: 'table.column.score',
            className: 'text-right',
            plugins: {
              name: 'text-field',
              type: 'decimal',
              isView: true
            }
          },
          {
            field: 'weightScore',
            header: 'table.column.weightedScore',
            className: 'text-right',
            plugins: {
              name: 'text-field',
              type: 'decimal',
              isView: true
            }
          }
        ]
      );
    }
  }

  public doOnInputScore(): void {
    this.totalProposalCriteria = 0;
    this.totalProposalPoint = 0;

    const currentRowChange =
      this.tableResponseProposalCriteria.currentRowChange;
    const score = this.tableResponseProposalCriteria
      .getUpdatedRecordList()
      .find(
        updateRecord =>
          updateRecord.rfpProposalCriteria.id ===
          currentRowChange.row.record.rfpProposalCriteria.id
      ).score;
    const tableColumnWeightScore: TableColumn =
      currentRowChange.row.columnList[3];
    const tableColumnWeightPercentage: TableColumn =
      currentRowChange.row.columnList[1];
    const weightPercentage = tableColumnWeightPercentage.value.split(' ', 1);
    const weightScore = (+weightPercentage * +score) / 100;
    tableColumnWeightScore.changeValue(weightScore);
    tableColumnWeightScore.reload();

    const recordList = this.tableResponseProposalCriteria
      .getRecordList()
      .map(record => {
        const indexOfR = this.tableResponseProposalCriteria
          .getUpdatedRecordList()
          .findIndex(
            updateRecord =>
              updateRecord.rfpProposalCriteria.id ===
              record.rfpProposalCriteria.id
          );
        return indexOfR === -1
          ? record
          : this.tableResponseProposalCriteria.getUpdatedRecordList()[indexOfR];
      });
    this.tableResponseProposalCriteria.setRecordList(recordList);
    this.tableResponseProposalCriteria.getRecordList().forEach(element => {
      element.score = element.score ? element.score : 0;
      element.weightScore =
        (+element.score * +element.rfpProposalCriteria.weightPercentage) / 100;
      this.totalProposalCriteria += +element.weightScore;
    });

    if (this.totalProposalCriteria) {
      this.totalProposalPoint =
        (+this.totalProposalCriteria *
          +this.rfpVendorView.rfpVendor.rfp.proposalPercentage) /
        100;
    } else {
      this.totalProposalPoint = 0;
    }
  }

  doSubmit(): void {
    const rfpRequest = new RfpRequest();
    rfpRequest.rfp = this.rfpVendorView.rfpVendor.rfp;
    rfpRequest.totalProposalPoint = this.totalProposalPoint;
    rfpRequest.totalProposalCriteria = this.totalProposalCriteria;
    rfpRequest.rfpVendorScoringList =
      this.tableResponseProposalCriteria.getRecordList();
    rfpRequest.rfpVendorScoringList.forEach(element => {
      element.rfpVendor.totalProposalScore = this.totalProposalPoint;
      element.rfpVendor.totalScore = this.totalProposalCriteria;
    });
    if (
      this.totalProposalCriteria < this.rfpVendorView.rfpVendor.rfp.minimumScore
    ) {
      const scoringStatus = new ScoringStatus();
      scoringStatus.code = this.global.appConstant.pm.SCORING_STATUS_NOT_PASSED;
      scoringStatus.name = 'Not Passed';
      this.rfpVendorView.rfpVendor.scoringStatus = scoringStatus;
    } else if (
      this.totalProposalCriteria >=
      this.rfpVendorView.rfpVendor.rfp.minimumScore
    ) {
      const scoringStatus = new ScoringStatus();
      scoringStatus.code = this.global.appConstant.pm.SCORING_STATUS_PASSED;
      scoringStatus.name = 'Passed';
      this.rfpVendorView.rfpVendor.scoringStatus = scoringStatus;
    }
    this.rfpVendorView.evaluator = this.global.userSession.user.name;
    this.validate();
    if (
      this.formGroup.valid &&
      rfpRequest.rfpVendorScoringList &&
      rfpRequest.rfpVendorScoringList.length !== 0
    ) {
      this.onChange.emit({ rfpRequest, rfpVendorView: this.rfpVendorView });
    }
  }
}
