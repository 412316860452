import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { FileType } from '../../../bean/file-type';
import { WebSocketResponseModel } from '../../../model/websocket-response-model';
import { ImportFileWebsocketService } from './import-file-websocket.service';
@Component({
  templateUrl: './app-popup-import.component.html',
  styleUrls: ['./app-popup-import.component.scss']
})
export class AppPopupImportComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() urlSave: string;
  @Input() isLoading: boolean;
  @Input() model: WebSocketResponseModel;
  @Input() onCloseModal: EventEmitter<boolean>;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  fileType: FileType = this.global.config.allowedFile.get('IMPORT_EXCEL');
  isSaving: boolean;
  uploadedFileName: string;
  formdata: FormData = new FormData();
  errors: any = [];
  file: any = [];
  urlModuleCode: string;
  isTemplateExists = false;

  constructor(
    public activeModal: NgbActiveModal,
    public importFileWebsocketService: ImportFileWebsocketService
  ) {
    super('import-file');
  }

  ngOnChanges(value): void {
    if (value && +value.progressPercentage.currentValue === 100.0) {
      this.onChange.emit();
    }
  }

  onInit(): void {
    this.onCloseModal.subscribe((close: boolean) => {
      if (close) {
        this.activeModal.close();
      }
    });
    this.urlModuleCode = this.urlSave.split('/')[1];
    this.httpClientService
      .get('/' + this.urlModuleCode + '/template/view', {
        responseType: 'arraybuffer',
        observe: 'response'
      })
      .subscribe(response => {
        if (response.body.byteLength !== 0) {
          this.isTemplateExists = true;
        }
      });
  }

  doUpload(event: any): void {
    this.file = event.target.files[0];
    if (this.isValid) {
      this.errors = [];
    }
    this.uploadedFileName = event.target.files[0].name;
    this.formdata.append('file', event.target.files[0]);
  }

  get fileExtension(): any {
    return this.file.name.substring(this.file.name.lastIndexOf('.') + 1);
  }

  get isValid(): boolean {
    if (this.file.length === 0) {
      this.errors['label'] = 'Peringatan:';
      this.errors['text'] = 'Tidak ada file dipilih';
      return false;
    }

    if (
      !this.fileType.fileExtension
        .toLowerCase()
        .includes(this.fileExtension.toLowerCase())
    ) {
      this.errors['label'] = 'app.acceptable.extension';
      this.errors['text'] = this.fileType.fileExtension;
      return false;
    }

    if (this.fileType.maxSize < this.file.size) {
      this.errors['label'] = 'app.acceptable.size';
      this.errors['text'] = this.fileType.maxSize / 1000000 + 'MB';
      return false;
    }

    return true;
  }

  doSave(): void {
    if (this.isValid) {
      this.isSaving = true;
      this.httpClientService.post(this.urlSave, this.formdata).subscribe((result) => {
        if (result.status === ResponseStatusModel.OK) {
          this.importFileWebsocketService.connect().then(data => {
            this.onChange.emit(data);
            this.importFileWebsocketService.disconnect();
          });
        } else {
          this.global.alertService.showError(
            result.statusText
          );
          this.onChange.emit(null);
          this.setStateReady();
        }
      });
      this.isLoading = false;
    }
  }

  public doCancel(): void {
    if (this.model.progressValue || this.model.message) {
      this.httpClientService.post('/import-file/cancel', {}).subscribe();
      this.importFileWebsocketService.disconnect();
      this.activeModal.dismiss();
    }
  }
}
