<div class="row main-row app-accordion-row">
  <div class="col-12">
    <app-card
      header="procurement-scope-work.title"
      detail="procurement-scope-work.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-accordion [isLoading]="formLoading">
        <app-accordion-item
          header="procurement-scope-work.accordion.workDefinitionForm"
          [isExpanded]="true"
        >
          <h5 class="separator"
            >{{ 'procurement-scope-work.separator.stageOfWork' | translate
            }}<span *ngIf="!formGroup.isView" class="control-label text-danger">
              *</span
            ></h5
          >
          <small
            class="text-danger"
            *ngIf="isSave && procurementSowList.length === 0"
          >
            {{ 'app.validation.required' | translate }}
          </small>
          <app-table
            #selectorStageOfWork
            [model]="tableResponseStageOfWork"
            *ngIf="!formLoading"
          >
            <ng-template #headerButtons>
              <app-button
                [disabled]="
                  formSaving || (availableAmountSow && availableAmountSow === 0)
                "
                *ngIf="!formGroup.isView"
                (onClick)="doAddStageOfWork()"
                ><em class="fas fa-plus"></em>
                {{ 'app.button.add' | translate }}</app-button
              >
            </ng-template>
            <ng-template #actionButtons let-rowData let-i="index">
              <app-button
                *ngIf="!formGroup.isView"
                [disabled]="formSaving"
                size="TN"
                (onClick)="doEditStageOfWork(rowData)"
                title="{{ 'app.tooltip.edit' | translate }}"
              >
                <em class="fas fa-pencil-alt"></em>
              </app-button>
              <app-button
                *ngIf="formGroup.isView"
                [disabled]="formSaving"
                size="TN"
                (onClick)="doDetailStageOfWork(rowData)"
                title="{{ 'app.tooltip.view' | translate }}"
              >
                <em class="fas fa-search"></em>
              </app-button>
              <app-button
                *ngIf="
                  !formGroup.isView &&
                  tableResponseStageOfWork.page.totalRecords === i + 1 &&
                  i !== 0
                "
                [disabled]="formSaving"
                size="TN"
                color="DANGER"
                (onClick)="doDeleteStageOfWork(rowData)"
                title="{{ 'app.tooltip.delete' | translate }}"
              >
                <em class="fas fa-trash"></em>
              </app-button>
            </ng-template>
          </app-table>
          <h5 class="separator">{{
            'procurement-scope-work.separator.guarantee' | translate
          }}</h5>
          <app-table
            #selectorGuarantee
            [model]="tableResponseGuarantee"
            *ngIf="!formLoading"
          >
            <ng-template #headerButtons>
              <app-button
                [disabled]="formSaving"
                *ngIf="!formGroup.isView"
                (onClick)="doAddGuarantee()"
                ><em class="fas fa-plus"></em>
                {{ 'app.button.add' | translate }}</app-button
              >
            </ng-template>
            <ng-template #actionButtons let-rowData *ngIf="!formGroup.isView">
              <app-button
                *ngIf="!formGroup.isView"
                [disabled]="formSaving"
                size="TN"
                (onClick)="doEditGuarantee(rowData)"
                title="{{ 'app.tooltip.edit' | translate }}"
              >
                <em class="fas fa-pencil-alt"></em>
              </app-button>
              <app-button
                *ngIf="!formGroup.isView"
                [disabled]="formSaving"
                size="TN"
                color="DANGER"
                (onClick)="doDeleteGuarantee(rowData)"
                title="{{ 'app.tooltip.delete' | translate }}"
              >
                <em class="fas fa-trash"></em>
              </app-button>
            </ng-template>
          </app-table>
          <h5 class="separator"
            >{{ 'procurement-scope-work.separator.fines' | translate }}
          </h5>
          <app-table
            #selectorFines
            [model]="tableResponseFines"
            *ngIf="!formLoading"
          >
            <ng-template #headerButtons>
              <app-button
                [disabled]="formSaving"
                *ngIf="!formGroup.isView"
                (onClick)="doAddFines()"
                ><em class="fas fa-plus"></em>
                {{ 'app.button.add' | translate }}</app-button
              >
            </ng-template>
            <ng-template #actionButtons let-rowData *ngIf="!formGroup.isView">
              <app-button
                *ngIf="!formGroup.isView"
                [disabled]="formSaving"
                size="TN"
                (onClick)="doEditFines(rowData)"
                title="{{ 'app.tooltip.edit' | translate }}"
              >
                <em class="fas fa-pencil-alt"></em>
              </app-button>
              <app-button
                *ngIf="!formGroup.isView"
                [disabled]="formSaving"
                size="TN"
                color="DANGER"
                (onClick)="doDeleteFines(rowData)"
                title="{{ 'app.tooltip.delete' | translate }}"
              >
                <em class="fas fa-trash"></em>
              </app-button>
            </ng-template>
          </app-table>
        </app-accordion-item>
        <app-accordion-item
          header="procurement-scope-work.accordion.procurementInformation"
        >
          <app-procurement-information-view
            [procurementVendorId]="procurementVendorId"
          >
          </app-procurement-information-view>
        </app-accordion-item>
        <app-accordion-item
          header="procurement-scope-work.accordion.vendorInformation"
        >
          <app-vendor-payment-information
            [procurementVendorId]="procurementVendorId"
            [temp]="procurementScopeWorkResponse"
            [todo]="todo"
          ></app-vendor-payment-information>
        </app-accordion-item>
      </app-accordion>
      <div class="floating-button mt-5">
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button
                mode="BYPASS"
                color="SECONDARY"
                (onClick)="doCancel()"
                class="mr-1"
                [disabled]="formSaving"
                >{{ 'app.button.back' | translate }}
              </app-button>
              <app-button
                *ngIf="
                  (!formGroup.isView &&
                    !procurementScopeWork.scopeWorkStatus) ||
                  (!formGroup.isView &&
                    procurementScopeWork.scopeWorkStatus &&
                    procurementScopeWork.scopeWorkStatus.code !==
                      global.appConstant.cm
                        .SCOPE_WORK_STATUS_PROCUREMENT_PROCESS)
                "
                color="PRIMARY"
                (onClick)="doSaveDraft()"
                [disabled]="formSaving"
                class="mr-1"
                >{{ 'app.button.saveDraft' | translate }}</app-button
              >
              <app-button
                *ngIf="!formGroup.isView"
                color="PRIMARY"
                (onClick)="doSave()"
                [disabled]="formSaving"
                >{{ 'app.button.save' | translate }}</app-button
              >
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
