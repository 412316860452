import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Procurement } from '../../core/bean/procurement';
import { AppUploadDocumentTableModel } from '../../core/components/app-upload-document-table-x/app-upload-document-table-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { UploadDocumentResponse } from './upload-document-reponse';
import { UploadDocumentRequest } from './upload-document-request';

@Component({
  templateUrl: './upload-document-edit.component.html'
})
export class UploadDocumentEditComponent
  extends BaseModuleComponent
  implements OnInit
{
  public procurementId: number;
  public procurement: Procurement;
  public urlBackOutside: string;
  public objectId: number;
  public approvalModelPrcsId: number;
  public uploadDocumentResponse: UploadDocumentResponse;
  public canceledOrFailedMessage: string;
  public isShowBadgeStatus: boolean;

  public badgeColor = {
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    NEW: 'INFO',
    DRAFT: 'SECONDARY',
    ON_PROGRESS: 'PROGRESS',
    NOT_STARTED: 'SECONDARY',
    CLOSED: 'DANGER',
    DONE: 'SUCCESS',
    EXTENDED: 'INFO',
    HOLD: 'DANGER',
    CANCELED: 'DANGER'
  };

  constructor(
    translateService: TranslateService,
    public snackbarService: SnackbarService
  ) {
    super('upload-document', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.procurement = this.global.routerParams.get('procurement');
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.objectId = this.global.routerParams.get('objectId');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      documentList: [null, Validators.required()]
    });
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<UploadDocumentResponse>(
        '/upload-document/edit',
        new RouteRequestModel(this.todo, this.procurementId)
      )
      .subscribe((response: UploadDocumentResponse) => {
        this.uploadDocumentResponse = response;
        if (!this.procurement) {
          this.procurement = response.procurement;
        }

        const statusList = [
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
        ];

        if (statusList.includes(this.procurement.procurementStatus.code)) {
          if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'upload-document.alert.msg.sorryWaitingCancelationApproval';
          } else if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'upload-document.alert.msg.sorryWaitingFailedApproval';
          } else {
            this.canceledOrFailedMessage =
              'upload-document.alert.msg.sorryFailedAssessment';
          }
        }

        this.formGroup
          .get('documentList')
          .patchValue(
            this.uploadDocumentResponse.appUploadDocumentTableModelList
          );
        this.setStateReady();
      });
  }

  public doSubmit(isSubmit: boolean) {
    this.validate();

    if (this.formGroup.valid) {
      if (!isSubmit) {
        this.doSubmitUploadDocument(isSubmit);
      } else {
        this.global.modalService
          .submitConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.doSubmitUploadDocument(isSubmit);
            }
          });
      }
    }
  }

  public doSubmitUploadDocument(isSubmit: boolean) {
    this.setStateProcessing();
    const uploadDocumentRequest: UploadDocumentRequest =
      new UploadDocumentRequest();
    uploadDocumentRequest.appUploadDocumentTableModelList =
      this.formGroup.get('documentList').value;
    uploadDocumentRequest.isSubmit = isSubmit;
    uploadDocumentRequest.procurementId = this.procurementId;
    this.httpClientService
      .post<Response<AppUploadDocumentTableModel>>(
        '/upload-document/update',
        uploadDocumentRequest
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          if (!isSubmit) {
            this.snackbarService.showWarning('app.msg.info.sucsessSave');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set('procurement', response.body);
                this.global.routerParams.set('procurementId', response.body.id);
                this.global.routerParams.set(
                  'urlBackOutside',
                  '/pages/worklist-pm'
                );

                this.global.routerParams.set('todo', 'edit');
                this.router.navigate(['/pages/upload-document/edit']);
              });
            this.setStateReady();
          } else {
            this.global.modalService
              .submitSuccess()
              .pipe(take(1))
              .subscribe(result => {
                if (result) {
                  this.router.navigate(['/pages/worklist-pm/']);
                } else {
                  this.router
                    .navigateByUrl('/', { skipLocationChange: true })
                    .then(() => {
                      this.global.routerParams.clear();
                      this.global.routerParams.set(
                        'procurement',
                        response.body
                      );
                      this.global.routerParams.set(
                        'procurementId',
                        response.body.id
                      );
                      this.global.routerParams.set(
                        'urlBackOutside',
                        '/pages/worklist-pm'
                      );

                      this.global.routerParams.set('todo', 'view');
                      this.router.navigate(['/pages/upload-document/detail']);
                    });
                }
                this.setStateReady();
              });
          }
        } else {
          this.global.alertService.showError(response.statusText);
        }
        this.setStateReady();
      });
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }
}
