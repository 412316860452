import { createDatePickerDate } from '../helper/create-date-picker-date-helper';
import { DatePickerState } from '../model';
import { DatePickerDateRange } from '../model/date-picker-date-range-model';
import { DatePickerTime } from '../model/date-picker-time-model';
export const resetDatePicker = (state: DatePickerState): void => {
  state.currentDate = createDatePickerDate(new Date());
  state.currentDateValue = null;
  state.currentTime = new DatePickerTime();
  state.currentDateRange = new DatePickerDateRange();
  state.setValue(null);
};
