import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ProcurementMethod } from '../../core/bean/procurement-method';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';

@Component({
  templateUrl: './compliance-test-master.component.html'
})
export class ComplianceTestMasterComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<ProcurementMethod>;

  public constructor(translateService: TranslateService) {
    super('compliance-test-master', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.header.procurementMethod',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClickMethod.bind(this)
        }
      }
    ]);
  }

  public doClickMethod(procMethod: ProcurementMethod): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('procurementMethod', procMethod);
    this.router.navigate(['/pages/compliance-test-master/detail']);
  }
}
