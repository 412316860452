import { EventEmitter, Injectable } from '@angular/core';
import * as Stomp from 'stompjs';
import { Global } from '../../global/global';
import { Log } from '../logger';
import { ServiceLocator } from '../service-locator';
@Injectable({ providedIn: 'root' })
export abstract class WebSocketService<T> {
  public receivedMessageChanges: EventEmitter<T> = new EventEmitter();
  public global: Global;
  public receivedMessage: T;
  private log: Log = new Log(this);
  private stompClient: Stomp.Client;
  protected abstract onReceivedMessage(message: string): Promise<string>;
  constructor(private broadcastUrl: string) {
    this.global = ServiceLocator.injector.get(Global);
  }

  public connect(): Promise<string> {
    return new Promise(resolve => {
      const channelUrl = `/channel/${this.broadcastUrl}`;
      this.log.debug('open channel : ' + channelUrl);
      const websocketURL = this.global.config.WEBSOCKET_ADDRESS.replace(
        'http',
        'ws'
      );
      const websocket = new WebSocket(websocketURL);
      this.stompClient = Stomp.over(websocket);
      this.stompClient.connect(
        {},
        () => {
          this.stompClient.subscribe(channelUrl, message => {
            resolve(this.onReceivedMessage(message.body));
          });
        },
        this.errorCallBack
      );
      // this.stompClient.debug = null;
    });
  }

  public errorCallBack(error: any): void {
    console.log('errorCallBack -> ' + error);
    setTimeout(() => {
      if (this) {
        this.connect();
      }
    }, 5000);
  }

  public setReceivedMessage(receivedMessage: T): void {
    this.receivedMessageChanges.emit(receivedMessage);
    this.receivedMessage = receivedMessage;
  }

  public disconnect(): void {
    if (this.stompClient) {
      this.stompClient.disconnect(() => {});
    }
  }
}
