<div class="row">
  <div class="col-12 pb-3" *ngFor="let widgetBudgetSpendItem of widgetBudgetSpend.items">
    <app-budget-filter [model]="widgetBudgetSpendItem.budgetFilter"
      (onChange)="onChangeBudgetFilter($event, widgetBudgetSpendItem)">
    </app-budget-filter>

    <ng-container
      *ngIf="widgetBudgetSpendItem.defaultChart === 'PLAN-COMPARISON'; then planComparisonChartTmpl; else actualPlanBarChartTmpl">
    </ng-container>

    <ng-template #planComparisonChartTmpl>
      <app-plan-comparison [model]="widgetBudgetSpendItem.planComparison">
      </app-plan-comparison>
    </ng-template>

    <ng-template #actualPlanBarChartTmpl>
      <app-actual-plan-bar-chart [model]="widgetBudgetSpendItem.acutalPlanBarchart">
      </app-actual-plan-bar-chart>
    </ng-template>
  </div>

  <div class="col text-center" *ngIf="!widgetBudgetSpend.isShowMoreVisual && !formLoading && isShowMoreVisualButton && !this.isSyncRunning">
    <app-button color="SECONDARY" (onClick)="doAddMoreVisual()" [disabled]="(websocketBudgetService.message | async)">
      <em class="fas fa-plus-circle"></em>
      {{ 'app-widget-budget-spend.showMoreVisual' | translate }}
    </app-button>
  </div>
</div>