import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { DrShippingItem } from 'src/app/core/bean/dr-shipping-item';
import { FileUploader } from 'src/app/core/components/upload';
import { Validators } from 'src/app/core/validators';
import { SowPaymentTermItemView } from 'src/app/core/view/entity/bean/sow-payment-term-item-view';
import { Dr } from '../../core/bean/dr';
import { DrDetailHistory } from '../../core/bean/dr-detail-history';
import { DrShipping } from '../../core/bean/dr-shipping';
import { Sow } from '../../core/bean/sow';
import { SowPaymentTerm } from '../../core/bean/sow-payment-term';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { DrPopupAddDrAddressComponent } from './dr-popup-add-dr-address.component';
import { DrRequest } from './request/dr.request';

@Component({
  templateUrl: './dr-popup-delivery-process.component.html'
})
export class DrPopupDeliveryProcessComponent extends BaseComponentComponent {
  @Input() todo: string;
  @Input() dr: Dr;
  @Input() sow: Sow;
  @Input() sowPaymentTerm: SowPaymentTerm;
  @Input() sowPaymentTermItem: SowPaymentTermItemView;
  @Input() drShippingList: DrShipping[];
  @Input() drShippingItemList: DrShippingItem[];
  @Output()
  public onChange: EventEmitter<any> = new EventEmitter();
  public onCloseAll: EventEmitter<any> = new EventEmitter();

  public isAllUploaded = false;
  public isPassQuantityTrue = true;
  public isRejectQuantityTrue = true;
  public isDeliverableTrue = true;
  public deliveryMonitoringRequest: DrRequest = new DrRequest();
  public tableResponseGoods: TableResponseModel<DrDetailHistory>;
  public tableResponseService: TableResponseModel<DrDetailHistory>;
  public fileUploader: FileUploader = new FileUploader(
    '/dr/',
    'dr.form.file',
    'DOC_DR',
    false,
    this.global.config.parameterModel.maxFileDr
  );
  public result: number;

  constructor(
    public translateService: TranslateService,
    public currency: CurrencyPipe,
    public appPopupService: AppPopupService
  ) {
    super('dr');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.setIsViewOnly();
    this.buildTableResponse();
    this.setStateReady();
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    if (
      this.sowPaymentTermItem.prItem.item.itemType.parentCode ===
      this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
    ) {
      this.formGroup = this.formBuilder.group({
        id: [null],
        passQuantity: [null],
        rejectQuantity: [null],
        receivedQuantity: [null, Validators.required()],
        receivedDate: [new Date(), Validators.required()],
        //performanceValue: [null, Validators.required()],
        address: [null, Validators.required()],
        drShipping: [null],
        uploadFile: [null],
        note: [null]
      });
    } else {
      this.formGroup = this.formBuilder.group({
        id: [null],
        receivedDate: [new Date(), Validators.required()],
        passPercentageValue: [
          null,
          Validators.compose([Validators.required(), Validators.min(1), Validators.max(100)])
        ],
       // performanceValue: [null, Validators.required()],
        address: [null, Validators.required()],
        drShipping: [null],
        uploadFile: [null],
        note: [null]
      });
    }
  }

  public setFormGroup(): void {
    if (this.sowPaymentTermItem) {
      if (
        this.sowPaymentTermItem.prItem.item.itemType.parentCode ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      ) {
        this.formGroup.patchValue({
          passQuantity: +this.sowPaymentTermItem.remainingQuantity,
          receivedQuantity: +this.sowPaymentTermItem.remainingQuantity,
          rejectQuantity: 0
        });
        this.formGroup.patchValue({
          rejectQuantity:
            this.formGroup.get('receivedQuantity').value -
            this.formGroup.get('passQuantity').value
        });
        this.formGroup
          .get('receivedQuantity')
          .setValidators(
            Validators.compose([
              Validators.required(),
              Validators.max(this.sowPaymentTermItem.remainingQuantity)
            ])
          );
      } else {
        this.formGroup.patchValue({
          passPercentageValue: +(
            100 - +this.sowPaymentTermItem.deliveryPercentage
          )
        });
      }
    }
    let drShippingByUserList: DrShipping[] = [];

    if (
      this.global.userSession.activeUserRole.role.code ===
      this.global.appConstant.ROLE_CODE_BUYER
    ) {
      drShippingByUserList = this.drShippingList;
    } else {
      this.drShippingList.forEach(drShipping => {
        drShipping.address.addressPicList.forEach(addressPic => {
          if (addressPic.user.id === this.global.userSession.user.id) {
            drShippingByUserList.push(drShipping);
          }
        });
      });
    }

    if (drShippingByUserList?.length === 1 || this.drShippingList) {
      this.formGroup.patchValue({
        drShipping: this.drShippingList[0],
        address: this.drShippingList[0].address.addressDetail
      });
    }
  }

  public buildTableResponse(): void {
    this.tableResponseGoods = new TableResponseModel(this.moduleCode, [
      {
        field: 'processedByUser.name',
        header: 'table.column.user'
      },
      {
        header: 'table.column.date',
        columnList: [
          {
            field: 'processedDate',
            header: 'table.column.processedDate',
            plugins: {
              name: 'date',
              format: 'short-date'
            }
          },
          {
            field: 'receivedDate',
            header: 'table.column.receivedDate',
            plugins: {
              name: 'date',
              format: 'short-date'
            }
          }
        ]
      },
      {
        header: 'table.column.total',
        columnList: [
          {
            field: 'receivedQuantity',
            className: 'text-right',
            header: 'table.column.received',
            plugins: {
              name: 'custom-plugin',
              before: (tablePlugin: TablePluginData): string => {
                return (
                  this.global.converterService.convertDecimal(
                    tablePlugin.value
                  ) +
                  ' ' +
                  tablePlugin?.row?.record?.drDetail?.sowPaymentTermItem
                    ?.prItem?.item?.uom?.name
                );
              }
            }
          },
          {
            field: 'passQuantity',
            className: 'text-right',
            header: 'table.column.pass',
            plugins: {
              name: 'custom-plugin',
              before: (tablePlugin: TablePluginData): string => {
                return (
                  this.global.converterService.convertDecimal(
                    tablePlugin.value
                  ) +
                  ' ' +
                  tablePlugin?.row?.record?.drDetail?.sowPaymentTermItem
                    ?.prItem?.item?.uom?.name
                );
              }
            }
          },
          {
            field: 'rejectQuantity',
            className: 'text-right',
            header: 'table.column.reject',
            plugins: {
              name: 'custom-plugin',
              before: (tablePlugin: TablePluginData): string => {
                return (
                  this.global.converterService.convertDecimal(
                    tablePlugin.value
                  ) +
                  ' ' +
                  tablePlugin?.row?.record?.drDetail?.sowPaymentTermItem
                    ?.prItem?.item?.uom?.name
                );
              }
            }
          }
        ]
      },
      /*{
        field: 'performanceValue',
        header: 'table.column.deliveryPerformance',
        plugins: {
          name: 'rating',
          isView: true
        }
      },*/
      {
        field: 'drShipping.address.addressDetail',
        header: 'table.column.address'
      },
      {
        field: 'note',
        header: 'table.column.note'
      },
      {
        field: 'fileList',
        header: 'table.column.attachment',
        plugins: [
          {
            name: 'upload',
            fileUploader: new FileUploader(
              '/rfp/',
              'rfp.form.file',
              'DOC_RFP_TEMPLATE',
              false,
              this.global.config.parameterModel.maxFileRfp
            ),
            isView: true
          }
        ]
      }
    ]);

    this.tableResponseService = new TableResponseModel(this.moduleCode, [
      {
        field: 'processedByUser.name',
        header: 'table.column.user'
      },
      {
        header: 'table.column.date',
        columnList: [
          {
            field: 'processedDate',
            header: 'table.column.processedDate',
            plugins: {
              name: 'date',
              format: 'short-date'
            }
          },
          {
            field: 'receivedDate',
            header: 'table.column.receivedDate',
            plugins: {
              name: 'date',
              format: 'short-date'
            }
          }
        ]
      },
      {
        field: 'passPercentageValue',
        className: 'text-right',
        header: 'table.column.totalReceived',
        plugins: {
          name: 'custom-plugin',
          before: (tablePlugin: TablePluginData): string => {
            return (
              this.global.converterService.convertDecimal(tablePlugin.value) +
              ' %'
            );
          }
        }
      },
      /*{
        field: 'performanceValue',
        header: 'table.column.deliveryPerformance',
        plugins: {
          name: 'rating',
          isView: true
        }
      },*/
      {
        field: 'drShipping.address.addressDetail',
        header: 'table.column.address'
      },
      {
        field: 'note',
        header: 'table.column.note'
      },
      {
        field: 'fileList',
        header: 'table.column.attachment',
        plugins: [
          {
            name: 'upload',
            fileUploader: new FileUploader(
              '/rfp/',
              'rfp.form.file',
              'DOC_RFP_TEMPLATE',
              false,
              this.global.config.parameterModel.maxFileRfp
            ),
            isView: true
          }
        ]
      }
    ]);

    this.tableResponseGoods.setCustomData({ id: this.sowPaymentTermItem.id });
    this.tableResponseService.setCustomData({ id: this.sowPaymentTermItem.id });
  }

  public doOpenPopupAddress(): void {
    this.appPopupService
      .open(DrPopupAddDrAddressComponent, { dr: this.dr }, { size: 'lg' })
      .subscribe((drShipping: DrShipping) => {
        this.formGroup.patchValue({
          drShipping,
          address: drShipping.address.addressDetail
        });
      });
  }

  public doCheckPassQuantity(deliveryItem): boolean {
    let lastPassTotal = null;
    let quantity = null;

    lastPassTotal = this.sowPaymentTermItem.receivedQuantity;
    quantity = this.sowPaymentTermItem.quantity;
    if (
      +deliveryItem.passQuantity + +lastPassTotal > quantity ||
      +deliveryItem.receivedQuantity >
        +this.sowPaymentTermItem.remainingQuantity ||
      +deliveryItem.passQuantity > +deliveryItem.receivedQuantity ||
      +deliveryItem.rejectQuantity > +deliveryItem.receivedQuantity
    ) {
      return false;
    } else {
      return true;
    }
  }

  public doCheckRejectQuantity(deliveryItem): boolean {
    if (
      this.sowPaymentTermItem.prItem.item.itemType.parentCode ===
      this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
    ) {
      if (
        +deliveryItem.rejectQuantity + +deliveryItem.passQuantity !==
        +deliveryItem.receivedQuantity
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  public doCheckDrShippingItem(): DrShippingItem {
    const qtyItem = this.drShippingItemList.find(
      (drShippingItem: DrShippingItem) =>
        +drShippingItem.drItem.prItem.id ===
          +this.sowPaymentTermItem.prItem.id &&
        +this.formGroup.value.drShipping.id === +drShippingItem.drShipping.id
    );
    return qtyItem;
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      const drDetail = this.formGroup.value;
      if (drDetail.rejectQuantity === null) {
        drDetail.rejectQuantity = 0;
      }
      if (drDetail.passQuantity === null) {
        drDetail.passQuantity = 0;
      }
      this.isPassQuantityTrue = this.doCheckPassQuantity(drDetail);
      this.isRejectQuantityTrue = this.doCheckRejectQuantity(drDetail);
      //this.isDeliverableTrue = this.doCheckDeliverable();

      if (this.isPassQuantityTrue && this.isRejectQuantityTrue) {
        this.global.modalService
          .saveConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              drDetail.sowPaymentTermItem = this.sowPaymentTermItem;
              drDetail.dr = this.dr;
              this.deliveryMonitoringRequest.drDetail = drDetail;
              this.deliveryMonitoringRequest.fileObjectList =
                this.fileUploader.fileObjectList;
              this.deliveryMonitoringRequest.sowPaymentTermItem =
                this.sowPaymentTermItem;
              this.httpClientService
                .post<Response<any>>(
                  '/dr/insert',
                  this.deliveryMonitoringRequest
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    response['drShipping'] = drDetail.drShipping;
                    this.onChange.emit(response);
                    this.onCloseAll.emit(response);
                  } else {
                    this.setStateReady();
                    this.onCloseAll.emit(response);
                    this.global.alertService.showError(response.statusText);
                  }
                });
            }
          });
      }
    }
  }

  public onChangePassTotal(): void {
    const passQuantity = this.formGroup.value.passQuantity;
    const receivedQuantity = this.formGroup.value.receivedQuantity;
    const value = +receivedQuantity - +passQuantity;
    this.formGroup.patchValue({
      rejectQuantity: value
    });
  }

  public onChangeRejectTotal(): void {
    const rejectQuantity = this.formGroup.value.rejectQuantity;
    const receivedQuantity = this.formGroup.value.receivedQuantity;
    const value = +receivedQuantity - +rejectQuantity;
    this.formGroup.patchValue({
      passQuantity: value
    });
  }

  public isLessThenDrItemShipping(): boolean {
    const drDetail = this.formGroup.value;
    let isLessThenDrItemShipping;
    if (drDetail.drShipping) {
      const drShippingItem = this.doCheckDrShippingItem();
      isLessThenDrItemShipping =
        (+drDetail.receivedQuantity || +drDetail.passPercentageValue) <=
        +drShippingItem?.quantity
          ? true
          : false;
    }
    return isLessThenDrItemShipping;
  }

  public doCheckDeliverable(): boolean {
    this.isDeliverableTrue = true;
    const passPercentageValue = +(this.formGroup.value.passPercentageValue);
    const result = this.sowPaymentTermItem.deliveryPercentage
              ? 100 - this.sowPaymentTermItem.deliveryPercentage
              : 0
    if (this.sowPaymentTermItem.deliveryPercentage > 0) {
      if ((passPercentageValue + result) > 0 && (passPercentageValue + result) <= 100) {
        return true;
      } else {
        this.isDeliverableTrue = false;
        return false;
      }
    } else {
      return true;
    }
  }
}
