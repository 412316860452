<dashboard-widget-card [isLoading]="model.isLoading" [header]="moduleCode + '.title'"
  customClassHeader="align-items-center">
  <ng-template #headerRight>
    <app-button (onClick)="doChangeFilter()" [disabled]="model.isLoading">
      <em class="pir pi-filter"></em>
      {{
      'dashboard-widget-best-spending-by-division.button.filter' | translate
      }}
    </app-button>
  </ng-template>
  <div *ngIf="!model.isLoading" class="dashboard-widget-best-spending-by-division">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate *ngIf="!model.isLoading">
      <app-chart [model]="chartModel" height="245px"></app-chart><br />
      <ng-template #footer>
        <div class="row">
          <ng-container *ngFor="let model of modelDefaultList">
            <div class="cml-2 d-flex align-items-center">
              <div [ngStyle]="{
                  background: model.color,
                  width: '20px',
                  height: '20px'
                }"></div>
            </div>
            <div class="cml-1 row col-sm-4 text-left align-items-center" style="display: grid">
              <h6 class="font-weight-bold"> {{ model.code }}</h6>

              <h6> {{ model.total || 0.0 }}{{ ' ' + (widgetBestSpendingByDivisionResponse.currencyCode || 'IDR')}}</h6>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </form>
  </div>
</dashboard-widget-card>