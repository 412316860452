import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../../core/guard/confirm.guard';
import { SharedModule } from '../../../core/shared/shared.module';
import { CompanyIdentityEditAddComponent } from './company-identity-edit-add.component';
const routes = [
  {
    path: '',
    component: CompanyIdentityEditAddComponent,
    data: { breadcrumb: 'company-identity.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [CompanyIdentityEditAddComponent]
})
export class CompanyIdentityModule {}
