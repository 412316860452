import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppItemRequestModule } from 'src/app/core/components/app-item-request/app-item-request.module';
import { AppProcurementInfoModule } from 'src/app/core/components/app-procurement-info/app-procurement-info.module';
import { AppWorkflowStepInfoModule } from 'src/app/core/components/app-workflow-step-info/app-workflow-step-info.module';
import { AppTableModule } from 'src/app/core/components/table/components/app-table/app-table.module';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupOpenQuotationItemComponent } from './app-popup-open-quotation-item.component';
import { OpenQuotationEditComponent } from './open-quotation-edit.component';
import { OpenQuotationVendorComponent } from './open-quotation-vendor.component';
const routeChildrens: Routes = [
  {
    path: 'quotation-detail',
    component: OpenQuotationVendorComponent,
    data: { breadcrumb: 'open-quotation.breadcrumb.quotation-detail' }
  }
];
const routes: Routes = [
  {
    path: 'add',
    component: OpenQuotationEditComponent,
    data: { breadcrumb: 'open-quotation.breadcrumb.detail' },
    children: routeChildrens
  },
  {
    path: 'edit',
    component: OpenQuotationEditComponent,
    data: { breadcrumb: 'open-quotation.breadcrumb.detail' },
    children: routeChildrens
  },
  {
    path: 'detail',
    component: OpenQuotationEditComponent,
    data: { breadcrumb: 'open-quotation.breadcrumb.detail' },
    children: routeChildrens
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppProcurementInfoModule,
    AppWorkflowStepInfoModule,
    AppItemRequestModule,
    AppTableModule,
    AppApprovalPrcsXModule,
    AppBadgeCatalogModule
  ],
  declarations: [
    OpenQuotationEditComponent,
    OpenQuotationVendorComponent,
    AppPopupOpenQuotationItemComponent
  ],
  entryComponents: [AppPopupOpenQuotationItemComponent],
  exports: [AppPopupOpenQuotationItemComponent]
})
export class OpenQuotationModule {}
