import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
@Component({
    templateUrl: './vendor-history-detail-history.component.html'
})
export class VendorHistoryDetailHistoryComponent extends BaseModuleComponent {
    public accordionModelResponse: any;
    constructor(translate: TranslateService) {
        super('vendor-history', translate);
    }

    public onInit(): void {
        this.doSetAccordionModelResponseFromRouterParams();
    }

    public doSetAccordionModelResponseFromRouterParams(): void {
        const selector = this.global.routerParams.get('selector');
        const componentInstance = this.global.routerParams.get('componentInstance');
        this.accordionModelResponse = { selector, componentInstance };
    }
}
