<app-popup header="{{ 'app-popup-attribute.title' | translate }}">
    <app-table [tableResponse]="tableResponse" [isServerSide]="true"
        stringUrl="{{urlCustom}}" customData="{{ attributeCustomData }}">
        <ng-template #actionButtons let-rowData>
            <button type="button" class="btn btn-primary ml-2"
                (click)="doChoose(rowData)">
                {{ 'app.button.select' | translate }}
            </button>
        </ng-template>
    </app-table>
    <div class="form-group row text-sm-center">
        <app-button color="SECONDARY" size="MD" class="col-sm-12 text-center"
            (onClick)="activeModal.dismiss()">
            {{ 'app.button.close' | translate}}
        </app-button>
    </div>
</app-popup>