import { BaseEntity } from '../base/base-entity';
import { Module } from '../bean/module';
import { ModuleGroup } from './../bean/module-group';
import { WorkflowStatus } from './../bean/workflow-status';
export class WorkflowPrcsEntity extends BaseEntity {
    totalSlaRealizedName: string;
    module: Module;
    moduleGroup: ModuleGroup;
    workflowStatus: WorkflowStatus;
    totalSlaRealizedInMinutes: number;
    totalSlaInDays: number;
    name: string;
    objectName: string;
    objectId: number;
    totalRealizedWorkingInDays: number;
    totalRealizedWorkingInHours: number;
    totalRealizedWorkingInMinutes: number;
    slaRealizedStatus: number;
}
