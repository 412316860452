import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { MonthYearPickerModel } from './model/month-year-picker-model';
@Component({
  selector: 'app-month-year-picker',
  templateUrl: './app-month-year-picker.component.html',
  styleUrls: ['./app-month-year-picker.component.scss'],
  providers: makeProvider(AppMonthYearPickerComponent)
})
export class AppMonthYearPickerComponent extends BaseValueAccessorComponent<Date> {
  @ViewChild('appMonthYearPickerPopupWrapper')
  appMonthYearPickerPopupWrapper: ElementRef;

  public model: MonthYearPickerModel = new MonthYearPickerModel();

  public monthYearPickerContent: Array<{
    text: string;
    value: number;
  }>;

  constructor(controlContainer: ControlContainer, elementRef: ElementRef) {
    super('app-month-year-picker', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setInitializationState();
    this.handleFormControlValueChanges();
  }

  private handleFormControlValueChanges(): void {
    this.formControl.valueChanges.subscribe(() => {
      this.setInitializationState();
    });
  }

  private setInitializationState(): void {
    if (this.formControl.value) {
      this.setModelState(new Date(this.formControl.value));
    } else {
      this.model = new MonthYearPickerModel();
    }
  }

  private setModelState(date: Date): void {
    this.model.currentYear = +date.getFullYear();
    this.model.selectedMonth = date.getMonth() + 1;
    this.model.startYearRange = this.model.currentYear - 7;
    this.model.endYearRange = this.model.currentYear + 8;
    this.model.currentValue = `${
      this.model.selectedMonth
    }/${date.getFullYear()}`;
    this.model.isShowYearRange = false;
    this.setMonthYearPickerContentState();
  }

  private setMonthYearPickerContentState(): void {
    if (!this.model.isShowYearRange) {
      const monthList = [
        'app.monthNameLong.january',
        'app.monthNameLong.february',
        'app.monthNameLong.march',
        'app.monthNameLong.april',
        'app.monthNameLong.may',
        'app.monthNameLong.june',
        'app.monthNameLong.july',
        'app.monthNameLong.august',
        'app.monthNameLong.september',
        'app.monthNameLong.october',
        'app.monthNameLong.november',
        'app.monthNameLong.december'
      ];
      this.monthYearPickerContent = monthList.map(
        (monthName: string, index: number) => {
          return {
            text: this.global.translateService.instant(monthName),
            value: index
          };
        }
      );
    } else {
      this.monthYearPickerContent = Array(
        this.model.endYearRange - this.model.startYearRange + 1
      )
        .fill(0)
        .map((fill: number, index: number) => {
          const year = this.model.startYearRange + fill + index;
          return {
            text: year.toString(),
            value: year
          };
        });
    }
  }

  public doDecrement(): void {
    if (this.model.isShowYearRange) {
      this.model.endYearRange = this.model.startYearRange - 1;
      this.model.startYearRange = this.model.endYearRange - 15;
      this.setMonthYearPickerContentState();
    } else {
      this.model.currentYear -= 1;
    }
  }

  public doIncrement(): void {
    if (this.model.isShowYearRange) {
      this.model.startYearRange = this.model.endYearRange + 1;
      this.model.endYearRange = this.model.startYearRange + 15;
      this.setMonthYearPickerContentState();
    } else {
      this.model.currentYear += 1;
    }
  }

  public doSelectValue(value: number): void {
    if (this.model.isShowYearRange) {
      this.model.currentYear = value;
      this.model.isShowYearRange = false;
      this.setMonthYearPickerContentState();
    } else {
      this.model.selectedMonth = value + 1;
      const date = this.model.selectedMonth !== 12 ? '01' : '31';
      this.formControl.patchValue(
        new Date(
          `${this.model.selectedMonth}-${date}-${this.model.currentYear}`
        )
      );
      this.appMonthYearPickerPopupWrapper.nativeElement.classList.remove(
        'show'
      );
      this.onChange.emit(this.formControl.value);
    }
  }

  public doShowYearRange(): void {
    this.model.isShowYearRange = true;
    this.setMonthYearPickerContentState();
  }

  public handleClickAppMonthYearPickerInput(event: PointerEvent): void {
    if (!this.formControl.disabled) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.formControl.value) {
        const date = new Date();
        this.model.currentYear = +date.getFullYear();
        this.model.startYearRange = this.model.currentYear - 7;
        this.model.endYearRange = this.model.currentYear + 8;
        this.model.isShowYearRange = false;
        this.setMonthYearPickerContentState();
      }
      this.appMonthYearPickerPopupWrapper.nativeElement.classList.add('show');
      const appMonthYearPickerPopupWrapperList =
        document.getElementsByClassName('app-month-year-picker-popup-wrapper');
      for (let index in appMonthYearPickerPopupWrapperList) {
        if (
          appMonthYearPickerPopupWrapperList.item(+index) !==
          this.appMonthYearPickerPopupWrapper.nativeElement
        ) {
          appMonthYearPickerPopupWrapperList
            .item(+index)
            .classList.remove('show');
        }
      }
      this.formControl.markAsTouched();
    }
  }

  handleClickMonthYearPickerPopupWrapper(event: PointerEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('document:click')
  onDocumentClick(): void {
    if (
      this.appMonthYearPickerPopupWrapper.nativeElement.classList.contains(
        'show'
      )
    ) {
      this.appMonthYearPickerPopupWrapper.nativeElement.classList.remove(
        'show'
      );
    }
  }
}
