import { BaseEntity } from '../base/base-entity';
import { Module } from '../bean/module';
import { User } from '../bean/user';
import { WorkflowPrcs } from './../bean/workflow-prcs';
export class WorkflowModelPrcsEntity extends BaseEntity {
    slaRealizedName: string;
    workflowPrcs: WorkflowPrcs;
    module: Module;
    user: User;
    type: number;
    sequence: number;
    name: string;
    slaInDays: number;
    startDate: Date;
    endDate: Date;
    processedDate: Date;
    slaRealizedInMinutes: number;
    slaRealizedStatus: number;
    slaRealizedWorkingInDays: number;
    slaRealizedWorkingInHours: number;
    slaRealizedWorkingInMinutes: number;
    object: string;
    nameAdjustment: string;
}
