import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AppPopupProcurementScopeWorkService } from 'src/app/core/components/app-popup/app-popup-procurement-scope-work/app-popup-procurement-scope-work.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { Contract } from '../../core/bean/contract';
import { FinesAdjustment } from '../../core/bean/fines-adjustment';
import { FinesAdjustmentHistory } from '../../core/bean/fines-adjustment-history';
import { ProcurementSow } from '../../core/bean/procurement-sow';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { FileObject } from '../../core/components/upload';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { FinesMonitoringView } from '../../core/view/entity/bean/fines-monitoring-view';
import { AppPopupFinesMonitoringAdjustmentComponent } from './app-popup-fines-monitoring-adjustment.component';
import { FinesMonitoringRequest } from './request/fines-monitoring.request';
import { FinesMonitoringResponse } from './response/fines-monitoring.response';

@Component({
  templateUrl: './fines-monitoring-edit-add.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None
})
export class FinesMonitoringEditAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild('selectorFines') tableFines: AppTableComponent;
  @ViewChild('selectorStageOfWork') tableStageOfWork: AppTableComponent;
  @ViewChild('selectorHistoryAdjustment')
  tableHistoryAdjustment: AppTableComponent;

  public tableResponseFines: TableResponseModel<FinesAdjustment>;
  public tableResponseStageOfWork: TableResponseModel<ProcurementSow>;
  public tableResponseHistoryAdjustment: TableResponseModel<FinesAdjustmentHistory>;

  public urlBack: string;
  public isLoading: boolean;
  public isAdjusted: boolean;
  public procurementVendorId: number;
  public procurementScopeWorkId: number;
  public availableAmountSow: number;
  public procurementSowList = [];
  public fileObjectList: FileObject[] = [];
  public finesAdjustmentList: FinesAdjustment[] = [];
  public finesAdjustmentHistoryList: FinesAdjustmentHistory[] = [];
  public finesMonitoringView: FinesMonitoringView = new FinesMonitoringView();
  public finesMonitoringRequest: FinesMonitoringRequest =
    new FinesMonitoringRequest();
  public finesMonitoringResponse: FinesMonitoringResponse =
    new FinesMonitoringResponse();
  public CURRENCY_DIGITS_INFO: string;

  constructor(
    public translateService: TranslateService,
    public appPopupService: AppPopupService,
    public currency: CurrencyPipe,
    public appPopupProcurementScopeWorkService: AppPopupProcurementScopeWorkService
  ) {
    super('fines-monitoring', translateService);
  }

  public onInit(): void {
    this.isAdjusted = false;
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
    this.setDataFromRouterParams();
    this.getData();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.procurementScopeWorkId = this.global.routerParams.get(
      'procurementScopeWorkId'
    );
    this.finesMonitoringView = this.global.routerParams.get(
      'finesMonitoringView'
    );
    this.urlBack = this.global.routerParams.get('urlBackOutside');
  }

  public getData(): void {
    this.httpClientService
      .get<FinesMonitoringResponse>(
        '/fines-monitoring/edit/' + this.procurementScopeWorkId
      )
      .subscribe((response: FinesMonitoringResponse) => {
        this.finesMonitoringResponse = response;
        this.procurementVendorId = response.procurementVendor.id;
        this.finesAdjustmentList = response.finesAdjustmentList;
        this.finesAdjustmentHistoryList = response.finesAdjustmentHistoryList;
        this.buildTableResponse();
        this.doSetProcurementSowList(response.procurementSowList);
        this.setTableResponse();
      });
  }

  public buildTableResponse(): void {
    this.tableResponseStageOfWork = new TableResponseModel(this.moduleCode, [
      {
        field: 'stageOfWork.name',
        header: 'table.column.stageOfWork',
        customClass: 'text-center'
      },
      {
        field: 'totalTermin',
        header: 'table.column.totalTermin',
        customClass: 'text-center'
      },
      {
        field: 'amount',
        header: 'table.column.value',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        format: FieldFormatEnum.ShortDate
      }
    ]);

    this.tableResponseFines = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementFines.fines.name',
        header: 'table.column.type',
        customClass: 'text-center'
      },
      {
        field: 'duration',
        header: 'table.column.duration',
        customClass: 'text-center',
        fn: this.setDurationDay.bind(this)
      },
      {
        field: 'percentageValue',
        header: 'table.column.percentageValue',
        customClass: 'text-right',
        fn: this.setDecimal.bind(this)
      },
      {
        field: 'amount',
        header: 'table.column.value',
        format: FieldFormatEnum.Currency,
        customClass: 'text-right'
      },
      {
        field: 'file.fileName',
        header: 'table.column.file',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName',
        customClass: 'width-file'
      },
      {
        field: 'description',
        header: 'table.column.description',
        customClass: 'white-space-normal'
      },
      {
        field: 'isWorn',
        header: 'table.column.fined',
        customClass: 'text-center',
        datamap:
          '{' +
          '"true" : "' +
          this.global.translateService.instant('fines-monitoring.yes') +
          '"' +
          ', "false" : "' +
          this.global.translateService.instant('fines-monitoring.no') +
          '"}'
      }
    ]);

    this.tableResponseHistoryAdjustment = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'date',
          header: 'table.column.adjustmentDate',
          customClass: 'text-center',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'finesAdjustment.procurementFines.fines.name',
          header: 'table.column.type',
          customClass: 'white-space-normal'
        },
        {
          field: 'duration',
          header: 'table.column.duration',
          customClass: 'text-center',
          fn: this.setDurationDay.bind(this)
        },
        {
          field: 'percentageValue',
          header: 'table.column.percentageValue',
          customClass: 'text-right',
          fn: this.setDecimal.bind(this)
        },
        {
          field: 'amount',
          header: 'table.column.amount',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right'
        },
        {
          field: 'file.fileName',
          header: 'table.column.file',
          format: FieldFormatEnum.DownloadableFile,
          urlFile: 'file.uploadedFileName',
          customClass: 'width-file'
        },
        {
          field: 'description',
          header: 'table.column.description',
          customClass: 'white-space-normal'
        },
        {
          field: 'user.name',
          header: 'table.column.user'
        }
      ]
    );
  }

  public setTableResponse(): void {
    this.tableResponseStageOfWork.page.records = this.procurementSowList;
    this.tableResponseStageOfWork.page.totalRecords =
      this.procurementSowList.length;
    this.tableResponseStageOfWork.reloadClient();

    this.tableResponseFines.page.records = this.finesAdjustmentList;
    this.tableResponseFines.page.totalRecords = this.finesAdjustmentList.length;
    this.tableResponseFines.reloadClient();

    this.tableResponseHistoryAdjustment.page.records =
      this.finesAdjustmentHistoryList;
    this.tableResponseHistoryAdjustment.page.totalRecords =
      this.finesAdjustmentHistoryList.length;
    this.tableResponseHistoryAdjustment.reloadClient();
    this.setStateReady();
  }

  public doSetProcurementSowList(proSowList): void {
    if (proSowList !== null) {
      proSowList.forEach((procurementSow: any) => {
        const procurementSowPaymentTermList = [];
        procurementSowPaymentTermList.push(
          procurementSow.procurementSowPaymentTermList
        );
        let amount = 0;
        procurementSow.procurementSowPaymentTermList.forEach(
          (procurementSowPaymentTerm: any) => {
            procurementSowPaymentTerm.procurementSowPaymentTermItemProductList =
              [];
            procurementSowPaymentTerm.procurementSowPaymentTermItemServiceList =
              [];
            amount = amount + +procurementSowPaymentTerm.amount;
            procurementSowPaymentTerm.procurementSowPaymentTermItemList.forEach(
              procurementSowItem => {
                if (
                  procurementSowItem.procurementResult.procurementItem.item
                    .itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                ) {
                  procurementSowPaymentTerm.procurementSowPaymentTermItemProductList.push(
                    {
                      procurementResult: procurementSowItem.procurementResult,
                      quantity: procurementSowItem.quantity,
                      remainingQuantity: procurementSowItem.remainingQuantity
                    }
                  );
                }
                if (
                  procurementSowItem.procurementResult.procurementItem.item
                    .itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
                ) {
                  procurementSowPaymentTerm.procurementSowPaymentTermItemServiceList.push(
                    {
                      procurementResult: procurementSowItem.procurementResult,
                      quantity: procurementSowItem.quantity,
                      remainingQuantity: procurementSowItem.remainingQuantity
                    }
                  );
                }
              }
            );
          }
        );
        const sow = {
          procurementScopeWork: procurementSow.procurementScopeWork,
          stageOfWork: procurementSow.stageOfWork,
          procurementSowPaymentTermList:
            procurementSow.procurementSowPaymentTermList,
          startDate: procurementSow.startDate,
          endDate: procurementSow.endDate,
          totalTermin: procurementSow.procurementSowPaymentTermList.length,
          amount,
          periodType: procurementSow.periodType,
          duration: procurementSow.duration,
          durationType: procurementSow.durationType,
          isAssessment: procurementSow.isAssessment,
          status: procurementSow.status
        };
        this.procurementSowList.push(sow);
      });
    }
  }

  public doSetTotalAmountSow(): void {
    const amount = this.finesMonitoringResponse.procurementVendor.amount
      ? this.finesMonitoringResponse.procurementVendor.amount
      : 0;
    let totalAmountSow = 0;
    this.procurementSowList.forEach(procurementSow => {
      totalAmountSow = totalAmountSow + +procurementSow.amount;
    });
    this.availableAmountSow = +amount - +totalAmountSow;
  }

  public doDetailStageOfWork(procurementSowEdit): void {
    this.doSetTotalAmountSow();
    this.httpClientService
      .get<Contract>(
        '/app-contract-request-view/detail-sow/' + this.procurementVendorId
      )
      .subscribe(contractRequestViewResponse => {
        const todo = 'view';
        const header = this.translateService.instant(
          'fines-monitoring.popup.title.viewStageOfWork'
        );
        const stageOfWorkList =
          contractRequestViewResponse.stageOfWorkList.filter(stageOfWork =>
            this.procurementSowList.every(
              procurementSow =>
                procurementSow.stageOfWork.id !== stageOfWork.id ||
                procurementSow.stageOfWork.id === procurementSow.stageOfWork.id
            )
          );
        const paymentTermList = contractRequestViewResponse.paymentTermList;
        const billingTermList = contractRequestViewResponse.billingTermList;
        const contract = contractRequestViewResponse.contract;
        const procurementVendor =
          this.finesMonitoringResponse.procurementVendor;
        const procurementResultProductList =
          contractRequestViewResponse.procurementResultProductList;
        const procurementResultServiceList =
          contractRequestViewResponse.procurementResultServiceList;
        const availableAmountSow =
          this.availableAmountSow + +procurementSowEdit.amount;
        let procurementSowPaymentList: any;
        if (this.procurementSowList.length > 0) {
          procurementSowPaymentList =
            this.procurementSowList[this.procurementSowList.length - 1]
              .procurementSowPaymentTermList[0];
        }
        const procurementSowList = this.procurementSowList;

        this.appPopupProcurementScopeWorkService.open(
          todo,
          header,
          procurementSowEdit,
          stageOfWorkList,
          paymentTermList,
          billingTermList,
          procurementResultServiceList,
          procurementResultProductList,
          contract,
          procurementVendor,
          availableAmountSow,
          null,
          procurementSowList,
          procurementSowPaymentList,
          null,
          null,
          contract ? false : true
        );
      });
  }

  public doDetailAdjustment(finesAdjustment): void {
    const todo = 'edit';
    const header = this.translateService.instant(
      'fines-monitoring.popup.title.adjustment'
    );
    this.appPopupService
      .open(
        AppPopupFinesMonitoringAdjustmentComponent,
        {
          todo,
          header,
          finesAdjustment
        },
        { size: 'lg' }
      )
      .subscribe(finesMonitoringRequest => {
        if (finesMonitoringRequest) {
          this.isAdjusted = true;
          this.fileObjectList = finesMonitoringRequest.fileObjectList;
          this.finesAdjustmentList.forEach(fineAdjustment => {
            if (
              finesMonitoringRequest.finesAdjustmentList[0].procurementFines
                .id === fineAdjustment.procurementFines.id
            ) {
              fineAdjustment = finesMonitoringRequest.finesAdjustmentList[0];
            }
          });

          this.setTableResponse();
        }
      });
  }

  public doSave(): void {
    const finesAdjustmentList: FinesAdjustment[] = [];
    this.finesAdjustmentList.forEach(finesAdjustment => {
      if (finesAdjustment.isWorn != null && finesAdjustment.isAdjusted) {
        finesAdjustmentList.push(finesAdjustment);
      }
    });
    if (finesAdjustmentList.length === 0 || !this.isAdjusted) {
      this.global.alertService.showInfo(
        this.translateService.instant('fines-monitoring.noDataTobeAdjusted')
      );
    } else {
      this.finesMonitoringRequest.finesAdjustmentList = finesAdjustmentList;
      this.finesMonitoringRequest.finesAdjustmentHistoryList =
        this.finesAdjustmentHistoryList;
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<FinesMonitoringRequest>(
                '/fines-monitoring/insert',
                this.finesMonitoringRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.setStateReady();
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/fines-monitoring/']);
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }
  public setDecimal(field: number): string {
    field = field ? field : 0;
    return (
      this.currency.transform(field, '', '', this.CURRENCY_DIGITS_INFO) + ' %'
    );
  }

  public setDurationDay(field: number): string {
    field = field ? field : 0;
    return field + ' ' + this.translateService.instant('fines-monitoring.day');
  }

  public doCancel(): void {
    this.router.navigate(['/pages/fines-monitoring']);
  }
}
