<div class="row main-row">
  <div class="col-12">
    <app-fieldset legend="Employee">
      <form class="form-horizontal">
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">Name</label>
          <div class="col-sm-7 text-left">
            <p>{{ (employee && employee.name) || '-' }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">Salary</label>
          <div class="col-sm-7 text-left">
            {{ (employee && employee.salary) || '-' }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">Country</label>
          <div class="col-sm-7 text-left">
            <p>{{ (employee && employee.region && employee.region[0].name) || '' }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">About</label>
          <div class="col-sm-7 text-left">
            <p>{{ (addtionalInformation && addtionalInformation.about) || '-' }}</p>
          </div>
        </div>
      </form>
    </app-fieldset>
  </div>
</div>
