import { NgModule } from '@angular/core';
import { AppChooseComponent } from './app-choose.component';
import { AppTaggingModule } from '../app-tagging/app-tagging.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { VendorModule } from '../../vendor/vendor.module';
@NgModule({
  imports: [
    VendorModule,
    AppTaggingModule,
    AppTooltipWrapperModule
  ],
  declarations: [
    AppChooseComponent
  ],
  exports: [
    AppChooseComponent
  ]
})

export class AppChooseModule { }
