import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorCategory } from '../../core/bean/vendor-category';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { VendorType } from './../../core/bean/vendor-type';
import { VendorCategoryResponse } from './vendor-category-response';

@Component({
  templateUrl: './vendor-category-edit-add.component.html'
})
export class VendorCategoryEditAddComponent extends BaseModuleComponent {
  public vendorCategoryId: number;
  public vendorTypeOptionList: OptionListModel<VendorType> = new OptionListModel(
    true
  );
  public readonly urlEditAddVendorCategory = '/vendor-category/add-edit';
  public readonly urlUpdateVendorCategory = '/vendor-category/update';
  public readonly urlInsertVendorCategory = '/vendor-category/insert';
  public readonly urlRouterNavigateToVendorCategory = '/pages/vendor-category/';

  constructor(translateService: TranslateService) {
    super('vendor-category', translateService);
  }

  onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.getAndSetVendorCategoryToFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorCategoryId = this.global.routerParams.get('vendorCategoryId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      description: [null, Validators.maxLength(512)],
      vendorType: [null, Validators.required()]
    });
  }

  public getAndSetVendorCategoryToFormGroup(): void {
    this.getVendorCategoryByIdFromServer(
      this.urlEditAddVendorCategory,
      this.todo,
      this.vendorCategoryId
    ).subscribe(vendorCategoryResponse => {
      this.setFormGroup(vendorCategoryResponse);
      this.setStateReady();
    });
  }

  public setFormGroup(vendorCategoryResponse: VendorCategoryResponse): void {
    this.vendorTypeOptionList.setRequestValues(
      vendorCategoryResponse.vendorTypeList
    );
    if (vendorCategoryResponse.vendorCategory != null) {
      const {
        id,
        name,
        code,
        description,
        vendorType } = vendorCategoryResponse.vendorCategory;
      this.formGroup.patchValue({ id, name, code, description, vendorType });
    }
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.saveVendorCategory();
          }
        });
    }
  }

  public saveVendorCategory(): void {
    this.setStateProcessing();
    this.saveVendorCategoryToServer(
      this.urlSaveVendorCategory,
      this.vendorCategory
    ).subscribe(response => {
      this.showAlertMessage(response);
      this.setStateReady();
    });
  }

  public showAlertMessage(response: Response<VendorCategory>): void {
    if (response.status === ResponseStatusModel.OK) {
      this.global.alertService.showSuccessSavingOnNextRoute();
      this.router.navigate([this.urlRouterNavigateToVendorCategory]);
    } else {
      this.global.alertService.showError(response.statusText);
    }
  }

  public doCancel(): void {
    this.router.navigate([this.urlRouterNavigateToVendorCategory]);
  }

  public get urlSaveVendorCategory(): string {
    return this.todo === 'edit'
      ? this.urlUpdateVendorCategory
      : this.urlInsertVendorCategory;
  }

  public get vendorCategory(): VendorCategory {
    return this.global.copyFormAttributeToModel(
      new VendorCategory(),
      this.formGroup
    );
  }

  public getVendorCategoryByIdFromServer(
    urlGetVendorCategoryById: string,
    todo: string,
    vendorCategoryId: number
  ): Observable<VendorCategoryResponse> {
    return this.httpClientService.post<VendorCategoryResponse>(
      urlGetVendorCategoryById,
      new RouteRequestModel(todo, vendorCategoryId)
    );
  }

  public saveVendorCategoryToServer(
    urlSaveVendorCategory: string,
    vendorCategory: VendorCategory
  ): Observable<Response<VendorCategory>> {
    return this.httpClientService.post<Response<VendorCategory>>(
      urlSaveVendorCategory,
      vendorCategory
    );
  }
}
