import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { VendorAddress } from 'src/app/core/bean/vendor-address';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { TabModel } from '../../../core/model/tab/tab-model';
import { Validators } from '../../../core/validators';
import { ManageCatalogDTO } from '../dto/manage-catalog.dto';
@Component({
  templateUrl: './inventory.component.html'
})
export class InventoryComponent extends BaseModuleComponent {
  userId = this.global.userSession.user.id;
  public manageCatalogDTO: ManageCatalogDTO = new ManageCatalogDTO();
  public inventoryTab: TabModel;
  public isTodoView;
  public isVendor: boolean;
  public isOther: boolean;
  public vendorAddressOptionList: OptionListModel<VendorAddress> = new OptionListModel(
    false,
    'address'
  );
  constructor(translateService: TranslateService) {
    super('manage-catalog', translateService);
  }

  onInit(): void {
    this.isVendor = this.global.userSession.vendor? true: false;
    this.isOther = this.isVendor? false : this.global.userSession.activeUserRole.role.type 
    === this.global.appConstant.core.ROLE_TYPE_OTHER? true: false;
    this.setCatalog();
    this.buildFormGroup();
    this.setOptionListModel();
    this.isOther ? this.inventoryTab.setIsView(true)
    :this.inventoryTab.setIsView(this.isTodoView);
    if (
      !this.inventoryList.controls ||
      this.inventoryList.controls.length === 0
    ) {
      this.addMoreInventory();
    }
    this.setStateReady();
  }

  public setCatalog(): void {
    this.inventoryTab = this.global.routerParams
      .get('tabResponse')
      .tabs.get('INVENTORY');
    this.isTodoView = this.global.routerParams.get('tabResponse').isView;
    this.manageCatalogDTO = this.global.routerParams.get('manageCatalogDTO');
  }

  public setOptionListModel(): void {
    this.vendorAddressOptionList.setRequestValues(
      this.manageCatalogDTO.vendorAddressList
    );
  }

  public buildFormGroup(): void {
    if (!this.inventoryTab.isFormGroupReady) {
      this.inventoryTab.formGroup = this.formBuilder.group({
        inventoryList: this.formBuilder.array([])
      });

      if (this.manageCatalogDTO.catalogInventoryList != null) {
        this.manageCatalogDTO.catalogInventoryList.forEach(catalogInventory => {
          const formGroup = this.formBuilder.group({
            vendorAddress: [
              this.isTodoView ? catalogInventory.vendorAddress.address : 
                (this.isOther ?  catalogInventory.vendorAddress.address : catalogInventory.vendorAddress),
              Validators.compose([Validators.required()])
            ],
            stock: [
              catalogInventory.stock
            ],
            supplyAbility: [
              catalogInventory.supplyAbility,
              Validators.min(1)
            ],
            vendorAddressAutoComplete: [
              this.isTodoView ? catalogInventory.vendorAddress : 
                (this.isOther ?  catalogInventory.vendorAddress : null),
            ],
          });
          this.inventoryList.push(formGroup);
          this.isOther ? formGroup.setIsView(true)
          :formGroup.setIsView(this.isTodoView);
        });
      }
    }
  }

  public doDeleteInventory(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.inventoryList.removeAt(index);
        }
      });
  }

  public addMoreInventory(): void {
    this.inventoryList.push(
      this.formBuilder.group({
        vendorAddress: [
          null,
          Validators.compose([Validators.required()])
        ],
        stock: [
          null
        ],
        supplyAbility: [
          null
        ],
      })
    );
  }

  public addNoMoreInventory(): void {
    this.global.alertService.showError(
      this.translateService.instant('manage-catalog.alert.msg.maxInput')
    );
  }

  public get inventoryList(): FormArray {
    return this.inventoryTab.formGroup.get('inventoryList') as FormArray;
  }

  public get formArrayInventoryList(): FormArray {
    return this.inventoryTab.formGroup.get('inventoryList') as FormArray;
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
