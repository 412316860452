import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppActivityHistoryViewModule } from 'src/app/core/components/app-activity-history-view/app-activity-history-view.module';
import { AppDeliverableItemModule } from 'src/app/core/components/app-deliverable-item/app-deliverable-item.module';
import { AppPaymentRequestInformationViewModule } from 'src/app/core/components/app-payment-request-information-view/app-payment-request-information-view.module';
import { AppProcurementInformationViewModule } from 'src/app/core/components/app-procurement-information-view/app-procurement-information-view.module';
import { AppVendorPaymentInformationModule } from 'src/app/core/components/app-vendor-payment-information/app-vendor-payment-information.module';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AppBillingMemoInformationViewModule } from '../../core/components/app-billing-memo-information-view/app-billing-memo-information-view.module';
import { AppPopupPaymentHistoryPrintComponent } from './app-popup-payment-history-print.component';
import { PaymentHistoryDetailComponent } from './payment-history-detail.component';
import { PaymentHistoryComponent } from './payment-history.component';
import { AppPaymentInformationModule } from './payment-information/payment-information.module';

export const routes = [
  {
    path: '',
    component: PaymentHistoryComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'detail',
    component: PaymentHistoryDetailComponent,
    data: { breadcrumb: 'payment-history.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppProcurementInformationViewModule,
    AppVendorPaymentInformationModule,
    AppDeliverableItemModule,
    AppPaymentRequestInformationViewModule,
    AppActivityHistoryViewModule,
    AppBillingMemoInformationViewModule,
    AppPaymentInformationModule
  ],
  declarations: [
    PaymentHistoryComponent,
    PaymentHistoryDetailComponent,
    AppPopupPaymentHistoryPrintComponent
  ],
  entryComponents: [AppPopupPaymentHistoryPrintComponent],
  providers: [AppPopupPaymentHistoryPrintComponent]
})
export class PaymentHistoryModule {}
