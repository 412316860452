<div class="promise-loading" *ngIf="formLoading">
  <div class="loading"></div>
</div>
<div class="row" *ngIf="!formLoading">
  <div class="col-sm-4" style="margin-top: 10px">
    <app-image-slideshow
      [fileList]="appCatalogDetailInfoResponse.imageList"
      [isPopupEnable]="true"
      [popupTitle]="appCatalogDetailInfoResponse.catalog.name"
      [parentModuleCode]="moduleCode"
    ></app-image-slideshow>
  </div>
  <div class="col-sm-8">
    <h4 style="padding-top: 10px">{{
      appCatalogDetailInfoResponse.catalogView.merk
    }}</h4>
    <hr />
    <h3
      >{{
        appCatalogDetailInfoResponse.catalogView.price
          | currency: ' '
      }}
      {{ appCatalogDetailInfoResponse.catalogView.currencyCode }}</h3
    >
    <p></p>
    <div>
      <table>
        <tr class="cursor-pointer" (click)="onClickVendor()">
          <td class="text-center text-secondary">
            <em class="fas fa-store"></em><p></p>
          </td>
          <td style="width: 8px"></td>
          <td class="text-left text-secondary">
            {{ appCatalogDetailInfoResponse.catalogView.produsenName }}<p></p>
          </td>
        </tr>
        <tr>
          <td class="text-center text-secondary">
            <em class="fas fa-map-marker-alt"></em><p></p>
          </td>
          <td></td>
          <td class="text-left text-secondary">
            {{ appCatalogDetailInfoResponse.catalogView.vendorCityRegionName }}
            <p></p
          ></td>
        </tr>
        <tr>
          <td class="text-center text-warning">
            <em class="pic pi-star"></em>
          </td>
          <td></td>
          <td class="text-left text-secondary">
            {{ 'app-catalog-detail-info.vendorRating' | translate }}&nbsp;{{
              appCatalogDetailInfoResponse.catalogView.rating
            }}&nbsp;|&nbsp;
            <app-badge-catalog
              style="position: absolute"
              [color]="
                appCatalogDetailInfoResponse.catalogView.catalogType.code ===
                global.appConstant.vm.CATALOG_TYPE_VENDOR
                  ? 'INFO'
                  : 'SUCCESS'
              "
              >{{
                appCatalogDetailInfoResponse.catalogView
                  .catalogTypeTranslationKey | translate
              }}</app-badge-catalog
            >
          </td>
        </tr>
      </table>
    </div>
    <ng-container
      *ngIf="
        catalog.catalogType.code === global.appConstant.vm.CATALOG_TYPE_CONTRACT
      "
    >
      <br />
      <div
        style="
          border: 1px solid var(--gray-400);
          border-radius: 5px;
          box-sizing: border-box;
          padding: 1rem;
        "
      >
        <label class="control-label">{{
          'app-catalog-detail-info.form.itemContractDetail' | translate
        }}</label>
        <div>
          <table>
            <!-- <tr>
              <td class="text-left text-secondary"
                >{{ 'app-catalog-detail-info.form.contractNumber' | translate
                }}<p></p
              ></td>
              <td></td>
              <td class="text-left text-secondary">
                : {{ catalogContract.contract.code }} <p></p
              ></td>
            </tr> -->
            <tr>
              <td class="text-left text-secondary"
                >{{ 'app-catalog-detail-info.form.contractPeriode' | translate
                }}<p></p
              ></td>
              <td></td>
              <!-- <td class="text-left text-secondary">
                : {{ 'app-catalog-detail-info.form.from' | translate }}
                {{
                  catalogContract.contract.startDate
                    | date: global.config.parameterModel.dateFormatShort
                }}
                {{ 'app-catalog-detail-info.form.until' | translate }}
                {{
                  catalogContract.contract.endDate
                    | date: global.config.parameterModel.dateFormatShort
                }}
                <p></p>
              </td> -->
            </tr>
            <!-- <tr>
              <td class="text-left text-secondary"
                >{{
                  'app-catalog-detail-info.form.agreementQuantity' | translate
                }}<p></p
              ></td>
              <td></td>
              <td class="text-left text-secondary">
                :
                {{ appCatalogDetailInfoResponse.catalogView.quantity | resolveNumberToDecimal
                }}<p></p>
              </td>
            </tr> -->
            <tr>
              <td class="text-left text-secondary"
                >{{
                  'app-catalog-detail-info.form.quantityInPackage' | translate
                }}<p></p
              ></td>
              <td></td>
              <td class="text-left text-secondary">
                :
                {{ appCatalogDetailInfoResponse.catalogView.quantityInPackage | resolveNumberToDecimal
                }}<p></p>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <ng-container *ngIf="catalogView.catalogRegion.isBulkPrice">
        <div
          style="
            border: 1px solid var(--gray-400);
            border-radius: 5px;
            box-sizing: border-box;
            padding: 1rem;
          "
        >
          <div class="form-group row"
            ><div class="col-sm-1 pvc pv-bulk"></div>
            <div class="col" style="padding-top: 2%">
              <strong>
                {{
                  'app-catalog-detail-info.form.bulkPriceAvailable' | translate
                }}</strong
              >
            </div></div
          >
          <div>
            <span class="text-secondary">
              {{ 'app-catalog-detail-info.form.startsFrom' | translate }}
              {{
                catalogRegionBulkPriceList[0].discountedPrice
                  | resolveNumberToCurrency
              }}
              {{ appCatalogDetailInfoResponse.catalogView.currencyCode }}
              {{ 'app-catalog-detail-info.form.forMinimumOrder' | translate }}
              {{
                catalogRegionBulkPriceList[0].minimumOrderTotal
                  | resolveNumberToDecimal
              }}
              pcs
              <app-tooltip
                position="BOTTOM"
                style="position: absolute; margin-left: 1%; margin-top: 0.3%"
              >
                <em class="pir pi-chevron-down"></em>
                <ng-template #tooltipContent>
                  <div
                    *ngFor="let data of catalogRegionBulkPriceList; let i = index"
                    style="min-width: max-content"
                    [ngStyle]="
                      i + 1 !== catalogRegionBulkPriceList.length
                        ? {
                            'border-bottom': '1px solid var(--gray-400)',
                            padding: '2% 2px 2% 2px'
                          }
                        : { padding: '2% 2px 0 2px' }
                    "
                  >
                    <div class="text-dark">
                      <strong
                        >{{ data.discountedPrice | resolveNumberToCurrency }}
                        {{
                          appCatalogDetailInfoResponse.catalogView.currencyCode
                        }}</strong
                      >
                    </div>

                    <div style="font-size: 12px" class="text-secondary">
                      {{ 'app-catalog-detail-info.form.disc' | translate }}
                      {{ data.discount | resolveNumberToDecimal }}%
                      {{
                        'app-catalog-detail-info.form.forMinimumOrder'
                          | translate
                      }}
                      {{ data.minimumOrderTotal | resolveNumberToDecimal }}
                      pcs
                    </div>
                  </div>
                </ng-template>
              </app-tooltip>
            </span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="mode === 'edit'">
        <div
          style="
            border: 1px solid var(--gray-400);
            border-radius: 5px;
            box-sizing: border-box;
            padding: 1rem;
            background: var(--alert-info);
          "
        >
          <div class="form-group row mt-4"
            ><div class="col-sm-1 pvc pv-file-contract"></div>
            <div class="col" style="padding-top: 2%">
              <strong
                ><a
                  style="text-decoration: underline"
                  class="text-primary"
                  (click)="doViewItemContract()"
                  >{{
                    'app-catalog-detail-info.form.viewItemContractList'
                      | translate
                  }}</a
                >
              </strong>
            </div></div
          >
        </div>
      </ng-container>
    </ng-container>
    <br />
    <label
      ><b>{{ 'app-catalog-detail-info.specification' | translate }}</b></label
    >
    <div>
      <table>
        <tr
          *ngFor="
            let data of appCatalogDetailInfoResponse.catalogSpecificationList;
            let i = index
          "
        >
          <td
            class="text-left white-space-normal text-secondary"
            style="width: 100px"
          >
            {{
              data.catalogAttribute.translationKey.module.code.toLowerCase() +
                '.' +
                data.catalogAttribute.translationKey.key | translate
            }}
          </td>
          <td
            class="text-center text-secondary"
            style="width: 30px; vertical-align: baseline"
            >:</td
          >
          <td class="text-left" style="vertical-align: baseline">
            <div
              *ngIf="data.catalogAttribute.formObject.code === 'WYSIWYG'"
              [innerHTML]="doParseValue(data)"
              [ngStyle]="{
                'white-space': 'normal',
                'max-width': '600px',
                'word-break': 'break-word'
              }"
            ></div>
            <!-- DATE-PICKER -->
            <div
              *ngIf="data.catalogAttribute.formObject.code === 'DATE-PICKER'"
            >
              {{
                doConvertToDate(data.value)
                  | date: global.config.parameterModel.dateFormatShort
              }}</div
            >
            <!-- TOGGLE-SWITCH -->
            <div
              *ngIf="data.catalogAttribute.formObject.code === 'TOGGLE-SWITCH'"
            >
              {{
                (data.value === 'true'
                  ? 'app-catalog-detail-info.label.yes'
                  : 'app-catalog-detail-info.label.no'
                ) | translate
              }}</div
            >
            <!-- ELSE -->
            <div
              *ngIf="
                data.catalogAttribute.formObject.code !== 'WYSIWYG' &&
                data.catalogAttribute.formObject.code !== 'DATE-PICKER' &&
                data.catalogAttribute.formObject.code !== 'TOGGLE-SWITCH'
              "
              [ngStyle]="{
                'white-space': 'normal',
                'max-width': '600px',
                'word-break': 'break-word'
              }"
              >{{ doParseValue(data) }}</div
            >
          </td>
        </tr>
      </table>
    </div>
    <br />
    <label
      ><b>{{ 'app-catalog-detail-info.description' | translate }}</b></label
    >
    <div
      readMore
      [ngStyle]="{
        'white-space': 'normal',
        'max-width': '600px',
        'word-break': 'break-word'
      }"
      [innerHTML]="appCatalogDetailInfoResponse.catalog.description || '-'"
    ></div>
  </div>
</div>
