import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedViewVendorModule } from './core/shared/shared-view-vendor.module';
import { ViewVendorComparisonComponent } from './view-vendor-comparison.component';
import { ViewVendorDetailComponent } from './view-vendor-detail.component';
import { ViewVendorComponent } from './view-vendor.component';
const routes: Routes = [
  {
    path: '',
    component: ViewVendorComponent,
    data: { breadcrumb: 'View Vendor' }
  },
  {
    path: 'detail',
    component: ViewVendorDetailComponent,
    data: { breadcrumb: 'view-vendor.breadcrumb.detail' }
  },
  {
    path: 'comparison',
    component: ViewVendorComparisonComponent,
    data: { breadcrumb: 'view-vendor.breadcrumb.comparison' }
  }
];

@NgModule({
  imports: [SharedViewVendorModule, RouterModule.forChild(routes)],
  declarations: [
    ViewVendorComponent,
    ViewVendorDetailComponent,
    ViewVendorComparisonComponent
  ]
})
export class ViewVendorModule {}
