import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Industry } from '../../core/bean/industry';
import { ItemCategory } from '../../core/bean/item-category';
import { Region } from '../../core/bean/region';
import { Vendor } from '../../core/bean/vendor';
import { VendorAddressHistory } from '../../core/bean/vendor-address-history';
import { VendorEntity } from '../../core/bean/vendor-entity';
import { VendorOwnership } from '../../core/bean/vendor-ownership';
import { VendorPICHistory } from '../../core/bean/vendor-pic-history';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { TabModel } from '../../core/model/tab/tab-model';
import { Validators } from '../../core/validators';
import { CompanyProfileServie } from '../company-profile/company-profile.service';
import { CompanyProfileDTO } from '../company-profile/dto/company-profile.dto';
import { TabProfileCompanyDataDTO } from './dto/tab-profile-company-data.dto';
import { AppPopupPICComponent } from './popup/app-popup-pic.component';
import { AppPopupVendorAddressComponent } from './popup/app-popup-vendor-address.component';

@Component({
  templateUrl: './tab-profile-company-data.component.html'
})
export class TabProfileCompanyDataComponent
  extends BaseModuleComponent
  implements AfterViewChecked
{
  @ViewChild('selectorVendorAddress')
  public tableVendorAddress: AppTableComponent;
  @ViewChild('selectorVendorPIC') public tableVendorPIC: AppTableComponent;

  public userId = this.global.userSession.user.id;
  public companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public tabProfileCompanyDataDTO: TabProfileCompanyDataDTO =
    new TabProfileCompanyDataDTO();
  public dataReady = false;
  public vendor: Vendor = new Vendor();
  public now = new Date().getTime();
  public sessionId = null;
  public tabCompanyData: TabModel; // this.global.routerParams['tabResponse'].tabs[1];
  public objectId: number;
  public tableAddressResponse: TableResponseModel<any>;
  public tablePICResponse: TableResponseModel<VendorPICHistory>;
  public vendorProductServiceCategoryHistoryList: ItemCategory[] = [];
  public vendorIndustryHistoryList: Industry[] = [];
  public vendorProductLocationHistoryList: Region[] = [];
  public isPkpDisable = false;
  public isView: boolean;
  public countryCode: any;
  public vendorHistoryId: number;
  public keyword: string[] = [];
  public nowYear = new Date().getFullYear();
  public isVendorList: boolean;
  public emailList: string[] = [];
  public fromOutside: string;
  public phoneNumber: string;
  public phoneNumberHistory: string;
  public roleType: number;
  public activityStatus: string;

  public industryOptionList: OptionListModel<Industry> = new OptionListModel();
  public taxNumberTypeOptionList: OptionListModel<any> = new OptionListModel();
  public vendorOwnershipOptionList: OptionListModel<VendorOwnership> =
    new OptionListModel();
  public vendorEntityOptionList: OptionListModel<VendorEntity> =
    new OptionListModel();
  public vendorLocationOptionList: OptionListModel<any> = new OptionListModel();
  public qualificationOptionList: OptionListModel<any> = new OptionListModel();
  public countryRegionOptionList: OptionListModel<Region> =
    new OptionListModel();

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public cdf: ChangeDetectorRef,
    public companyProfileService: CompanyProfileServie
  ) {
    super('tab-profile-company-data', translate);
  }
  public onInit(): void {
    this.roleType = this.global.userSession.activeUserRole.role.type;
    this.getDataFromRouterParam();
    if (this.tabCompanyData.routerParams.get('tabProfileCompanyDataDTO')) {
      this.tabProfileCompanyDataDTO = this.tabCompanyData.routerParams.get(
        'tabProfileCompanyDataDTO'
      );
      this.setOptionList();
      this.getAndSetVendorToFormGroup();
      this.buildTableAddressResponse();
      this.buildTablePICResponse();
      this.checkTable();
    } else {
      this.getDataFromServer();
    }
  }

  ngAfterViewChecked(): void {
    this.cdf.detectChanges();
  }

  public getDataFromServer(): void {
    // tslint:disable-next-line: max-line-length
    this.httpClientService
      .get<TabProfileCompanyDataDTO>(
        this.global.userSession.activeUserRole.role.type !==
          this.global.appConstant.core.ROLE_TYPE_VENDOR
          ? '/tab-profile-company-data/index?id=' +
              this.companyProfileDTO.vendorHistory.id +
              '&todo=' +
              this.todo
          : '/tab-profile-company-data/index'
      )
      .subscribe(tabProfileCompanyDataDTO => {
        this.tabProfileCompanyDataDTO = tabProfileCompanyDataDTO;
        this.tabCompanyData.routerParams.set(
          'tabProfileCompanyDataDTO',
          this.tabProfileCompanyDataDTO
        );
        this.buildFormGroup();
        this.getAndSetVendorToFormGroup();
        this.setOptionList();
        this.buildTableAddressResponse();
        this.buildTablePICResponse();
        this.checkTable();
      });
  }

  public getDataFromRouterParam(): void {
    this.tabCompanyData = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_COMPANY_DATA);
    this.todo = this.global.routerParams.get('todo');
    this.isView = this.companyProfileService.getTabResponse().isView;
    this.companyProfileDTO = this.global.routerParams.get('companyProfileDTO');
    this.vendorHistoryId =
      this.companyProfileDTO.vendorHistory !== null
        ? this.companyProfileDTO.vendorHistory.id
        : null;
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
    if (this.companyProfileDTO.vendor.phoneRegion) {
      this.phoneNumber =
        this.companyProfileDTO.vendor.phoneRegion.callingCode +
        this.companyProfileDTO.vendor.phone;
    }
    if (this.companyProfileDTO.vendorHistory.phoneRegion) {
      this.phoneNumberHistory =
        this.companyProfileDTO.vendorHistory.phoneRegion.callingCode +
        this.companyProfileDTO.vendorHistory.phone;
    }
    this.activityStatus = this.global.routerParams.get('activityStatus');
  }

  public buildFormGroup(): void {
    if (!this.tabCompanyData.isFormGroupReady) {
      this.tabCompanyData.formGroup = this.formBuilder.group({
        id: [null],
        name: [null, Validators.required()],
        abbreviation: [null],
        trademarkName: [null],
        taxNumber: [
          null,
          {
            validators:
              this.companyProfileDTO.vendorHistory.vendorType.code !==
              this.global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
                ? [Validators.required(), Validators.npwp()]
                : [Validators.required()],
            // tslint:disable-next-line: max-line-length
            asyncValidators: [
              Validators.existsAsync(
                '/company-profile/check-vendor-history-exist',
                null,
                null,
                { id: this.vendorHistoryId }
              )
            ]
          }
        ],
        isPkp: [null],
        pkpNumber:
          this.companyProfileDTO.vendorHistory.vendorType.code !==
          this.global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
            ? [
                null,
                {
                  validators: [Validators.required()],
                  asyncValidators: [
                    Validators.existsAsync(
                      '/company-profile/check-vendor-history-exist',
                      null,
                      null,
                      { id: this.vendorHistoryId }
                    )
                  ]
                }
              ]
            : [
                null,
                {
                  asyncValidators: [
                    Validators.existsAsync(
                      '/company-profile/check-vendor-history-exist',
                      null,
                      null,
                      { id: this.vendorHistoryId }
                    )
                  ]
                }
              ],
        certificateOfEstablishment: [null, Validators.required()],
        registeredInUnit: [null],
        email: [
          null,
          {
            validators: [Validators.email(), Validators.required()],
            asyncValidators: [
              Validators.existsAsync(
                '/company-profile/check-vendor-history-exist',
                null,
                null,
                { id: this.vendorHistoryId }
              )
            ]
          }
        ],
        phone: [
          null,
          {
            validators: [Validators.required()],
            asyncValidators: [
              Validators.existsAsync(
                '/company-profile/check-vendor-history-exist',
                null,
                null,
                { id: this.vendorHistoryId }
              )
            ]
          }
        ],
        vendorOwnership: [null, Validators.required()],
        vendorLocation: [null, Validators.required()],
        qualification: [null, Validators.required()],
        establishmentYear: [
          null,
          Validators.compose([
            Validators.required(),
            Validators.actualLength(
              4,
              'tab-profile-company-data.validation.year.actualLength'
            ),
            Validators.max(this.nowYear)
          ])
        ],
        totalAdmEmployee: [null],
        totalOperationEmployee: [null],
        totalMarketingEmployee: [null],
        totalEmployee: [null],
        website: [null],
        vendorProductServiceCategoryHistoryList: [null],
        vendorProductLocationHistoryList: [null],
        keyword: [null],
        vendorType: [null],
        user: [null],
        vendorEntity: [null, Validators.required()],
        vendorIndustryHistoryList: [null],
        isSubmit: [null],
        registrationNumber: [null],
        createdDate: [null],
        facebook: [null],
        twitter: [null],
        ig: [null],
        description: [null],
        linkedin: [null],
        bannerFile: [null],
        logoFile: [null],
        sequence: [null],
        statusApproval: [null],
        companyProfileFile: [null],
        vendor: [null],
        isTableEmpty: [null],
        isTablePICEmpty: [null],
        isTableAddressEmpty: [null],
        changeTab: [null]
      });
    }
  }

  public buildTableAddressResponse(): void {
    if (
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR &&
      !this.isVendorList &&
      this.companyProfileDTO.vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE
    ) {
      this.tableAddressResponse = new TableResponseModel(this.moduleCode, [
        { field: 'buildingName', header: 'table.column.buildingName' },
        {
          field: 'buildingType.name',
          header: 'table.column.buildingType'
        },
        {
          field: 'resourceOwnership.name',
          header: 'table.column.resourceOwnership'
        },
        {
          field: 'sizeUom',
          header: 'table.column.size',
          customClass: 'text-right'
        },
        {
          field: 'countryRegion.name',
          header: 'table.column.country'
        },
        {
          field: 'provinceRegion.name',
          header: 'table.column.province'
        },
        {
          field: 'phoneNumber',
          header: 'table.column.phone'
        },
        {
          field: 'crudInfo',
          header: 'table.column.status',
          format: FieldFormatEnum.Html,
          customClass: 'text-center'
        }
      ]);
    } else {
      this.tableAddressResponse = new TableResponseModel(this.moduleCode, [
        { field: 'buildingName', header: 'table.column.buildingName' },
        {
          field: 'buildingType.name',
          header: 'table.column.buildingType'
        },
        {
          field: 'resourceOwnership.name',
          header: 'table.column.resourceOwnership'
        },
        {
          field: 'sizeUom',
          header: 'table.column.size',
          customClass: 'text-right'
        },
        {
          field: 'countryRegion.name',
          header: 'table.column.country'
        },
        {
          field: 'provinceRegion.name',
          header: 'table.column.province'
        },
        {
          field: 'phoneNumber',
          header: 'table.column.phone'
        }
      ]);
    }
    this.tableAddressResponse.setRecords(
      this.tabProfileCompanyDataDTO.vendorAddressHistoryList
    );
    this.tableAddressResponse.setTotalRecords(
      this.tabProfileCompanyDataDTO.vendorAddressHistoryList.length
    );
  }

  public buildTablePICResponse(): void {
    if (
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR &&
      !this.isVendorList &&
      this.companyProfileDTO.vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE
    ) {
      this.tablePICResponse = new TableResponseModel(this.moduleCode, [
        { field: 'name', header: 'table.column.picName' },
        {
          field: 'phoneNumber',
          header: 'table.column.picPhoneNumber'
        },
        { field: 'email', header: 'table.column.picEmail' },
        {
          field: 'vendorPosition.name',
          header: 'table.column.picPosition'
        },
        {
          field: 'crudInfo',
          header: 'table.column.status',
          format: FieldFormatEnum.Html,
          customClass: 'text-center'
        }
      ]);
    } else {
      this.tablePICResponse = new TableResponseModel(this.moduleCode, [
        { field: 'name', header: 'table.column.picName' },
        {
          field: 'phoneNumber',
          header: 'table.column.picPhoneNumber'
        },
        { field: 'email', header: 'table.column.picEmail' },
        {
          field: 'vendorPosition.name',
          header: 'table.column.picPosition'
        }
      ]);
    }
    this.tablePICResponse.setRecords(
      this.tabProfileCompanyDataDTO.vendorPICHistoryList
    );
    this.tablePICResponse.setTotalRecords(
      this.tabProfileCompanyDataDTO.vendorPICHistoryList.length
    );
  }

  public getAndSetVendorToFormGroup(): void {
    if (
      Object.keys(this.tabCompanyData.formGroup.controls).length === 0 ||
      this.tabCompanyData.formGroup.controls.id.value === null
    ) {
      this.tabCompanyData.setIsView(this.isView);
      this.isViewOnly = this.isView;
      this.tabCompanyData.formGroup.patchValue(
        this.tabProfileCompanyDataDTO.vendorHistory
      );
      this.tabProfileCompanyDataDTO.vendorHistory.vendorProductServiceCategoryHistoryList.forEach(
        vendorProductCategoryHistory => {
          this.vendorProductServiceCategoryHistoryList.push(
            vendorProductCategoryHistory.itemCategory
          );
        }
      );
      this.tabProfileCompanyDataDTO.vendorHistory.vendorIndustryHistoryList.forEach(
        vendorIndustryHistory => {
          this.vendorIndustryHistoryList.push(vendorIndustryHistory.industry);
        }
      );
      this.tabProfileCompanyDataDTO.vendorHistory.vendorProductLocationHistoryList.forEach(
        vendorProductLocationHistory => {
          this.vendorProductLocationHistoryList.push(
            vendorProductLocationHistory.region
          );
        }
      );
      const organization: any[] = [];
      organization.push(
        this.tabProfileCompanyDataDTO.vendorHistory.organization
      );
      if (this.tabProfileCompanyDataDTO.vendorHistory.keyword !== null) {
        this.keyword =
          this.tabProfileCompanyDataDTO.vendorHistory.keyword.split(',');
      }
      this.tabCompanyData.formGroup.patchValue({
        keyword: !this.isView
          ? this.keyword
          : this.keyword.length !== 0
          ? this.keyword
          : null,
        registeredInUnit: organization,
        vendorProductServiceCategoryHistoryList:
          this.vendorProductServiceCategoryHistoryList.length !== 0
            ? this.vendorProductServiceCategoryHistoryList
            : null,
        vendorIndustryHistoryList:
          this.vendorIndustryHistoryList.length !== 0
            ? this.vendorIndustryHistoryList
            : null,
        vendorProductLocationHistoryList:
          this.vendorProductLocationHistoryList.length !== 0
            ? this.vendorProductLocationHistoryList
            : null,
        phone: {
          country: this.tabProfileCompanyDataDTO.vendorHistory.phoneRegion,
          number: this.tabProfileCompanyDataDTO.vendorHistory.phone
        }
      });
      if (this.isView) {
        this.tabCompanyData.formGroup.get('keyword').setIsView(true);
      }
      setTimeout(() => {
        if (!this.tabProfileCompanyDataDTO.vendorHistory.isPkp) {
          this.tabCompanyData.formGroup.get('pkpNumber')['disable']();
        }
      }, 0);
      if (this.tabProfileCompanyDataDTO.vendorHistory.isPkp) {
        // set value radio button as PKP
        this.tabCompanyData.formGroup.patchValue({
          isPkp: 2
        });
      } else {
        const isPkp = 1;
        this.tabCompanyData.formGroup.patchValue({ isPkp });
      }
      this.tabProfileCompanyDataDTO.vendorPICHistoryList.forEach(
        vendorPICHistory => {
          this.emailList.push(vendorPICHistory.email);
        }
      );
    }
    setTimeout(() => {
      if (this.tabCompanyData.formGroup.get('isPkp').value === 1) {
        this.tabCompanyData.formGroup.get('pkpNumber')['disable']();
      }
    }, 0);
    if (
      this.global.userSession.user.organization.id ===
        this.companyProfileDTO.vendorHistory.vendor.onBoardingByOrganization
          .id &&
      this.activityStatus &&
      this.activityStatus.includes('VERIFICATION')
    ) {
      this.tabCompanyData.formGroup.get('name').setIsView(false);
    }
    this.setStateReady();
  }

  public checkTable(): void {
    if (
      this.tabProfileCompanyDataDTO.vendorPICHistoryList.length !== 0 &&
      this.tabProfileCompanyDataDTO.vendorAddressHistoryList.length !== 0
    ) {
      this.tabCompanyData.formGroup.patchValue({ isTableEmpty: false });
    } else {
      this.tabCompanyData.formGroup.patchValue({ isTableEmpty: true });
    }
    if (this.tabProfileCompanyDataDTO.vendorPICHistoryList.length !== 0) {
      this.tabCompanyData.formGroup.patchValue({ isTablePICEmpty: false });
    } else {
      this.tabCompanyData.formGroup.patchValue({ isTablePICEmpty: true });
    }
    if (this.tabProfileCompanyDataDTO.vendorAddressHistoryList.length !== 0) {
      this.tabCompanyData.formGroup.patchValue({ isTableAddressEmpty: false });
    } else {
      this.tabCompanyData.formGroup.patchValue({ isTableAddressEmpty: true });
    }
  }

  public setOptionList(): void {
    const taxNumberTypeList: Array<object> = [
      { id: 1, name: 'NON-PKP' },
      { id: 2, name: 'PKP' }
    ];
    this.taxNumberTypeOptionList.setRequestValues(taxNumberTypeList);
    this.vendorOwnershipOptionList.setRequestValues(
      this.tabProfileCompanyDataDTO.vendorOwnershipList
    );
    this.vendorEntityOptionList.setRequestValues(
      this.tabProfileCompanyDataDTO.vendorEntityList
    );
    this.vendorLocationOptionList.setRequestValues(
      this.tabProfileCompanyDataDTO.vendorLocationList
    );
    this.qualificationOptionList.setRequestValues(
      this.tabProfileCompanyDataDTO.qualificationList
    );
    this.countryRegionOptionList.stringUrl = '/resource/';
    this.countryRegionOptionList.fileTypeCode =
      this.global.appConstant.fileType.IMG_ICON;
    this.countryRegionOptionList.setRequestValues(
      this.tabProfileCompanyDataDTO.countryRegionList
    );
    this.industryOptionList.setRequestValues(
      this.tabProfileCompanyDataDTO.industryList
    );
  }

  public doAddVendorAddress(): void {
    const todo = 'add';
    const vendorAddressHistoryList =
      this.tabProfileCompanyDataDTO.vendorAddressHistoryList;
    const vendorId = null;
    this.appPopupService
      .open(
        AppPopupVendorAddressComponent,
        {
          todo,
          vendorId,
          vendorAddressHistoryList,
          vendorTypeCode: this.companyProfileDTO.vendorHistory.vendorType.code
        },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(vendorAddress => {
        if (vendorAddress.value.isMain === null) {
          vendorAddress.value.isMain = false;
        }
        if (vendorAddress.value.isAgree !== null) {
          if (vendorAddress.value.isAgree) {
            this.tabProfileCompanyDataDTO.vendorAddressHistoryList[
              vendorAddress.value.indexAddress
            ].isMain = false;
          } else {
            vendorAddress.value.isMain = false;
          }
        }
        const vendorAddressHistory: VendorAddressHistory =
          this.setValueVendorAddressHistoryFromPoup(vendorAddress);
        this.tabProfileCompanyDataDTO.vendorAddressHistoryList.push(
          vendorAddressHistory
        );
        this.tableAddressResponse.setRecords(
          this.tabProfileCompanyDataDTO.vendorAddressHistoryList
        );
        this.tableAddressResponse.setTotalRecords(
          this.tabProfileCompanyDataDTO.vendorAddressHistoryList.length
        );
        this.tableAddressResponse.reloadClient();
        this.checkTable();
      });
  }

  public doEditVendorAddress(
    vendorAddressHistoryEdit: VendorAddressHistory
  ): void {
    const todo = 'edit';
    const vendorAddressHistoryList =
      this.tabProfileCompanyDataDTO.vendorAddressHistoryList;
    const vendorId = vendorAddressHistoryEdit.id;
    // tslint:disable-next-line: max-line-length
    this.appPopupService
      .open(
        AppPopupVendorAddressComponent,
        {
          todo,
          vendorId,
          vendorAddressHistoryEdit,
          vendorAddressHistoryList,
          vendorTypeCode: this.companyProfileDTO.vendorHistory.vendorType.code
        },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(vendorAddressTemp => {
        if (vendorAddressTemp.value.isAgree !== null) {
          if (vendorAddressTemp.value.isAgree) {
            this.tabProfileCompanyDataDTO.vendorAddressHistoryList[
              vendorAddressTemp.value.indexAddress
            ].isMain = false;
          } else {
            vendorAddressTemp.value.isMain = false;
          }
        }
        this.tabProfileCompanyDataDTO.vendorAddressHistoryList.forEach(
          (vendorAddressHistory, index) => {
            if (vendorAddressHistory === vendorAddressHistoryEdit) {
              this.tabProfileCompanyDataDTO.vendorAddressHistoryList[index] =
                this.setValueVendorAddressHistoryFromPoup(vendorAddressTemp);
            }
          }
        );
        this.tableAddressResponse.setRecords(
          this.tabProfileCompanyDataDTO.vendorAddressHistoryList
        );
        this.tableAddressResponse.setTotalRecords(
          this.tabProfileCompanyDataDTO.vendorAddressHistoryList.length
        );
        this.tableAddressResponse.reloadClient();
      });
  }

  public setValueVendorAddressHistoryFromPoup(
    formGroup: FormGroup
  ): VendorAddressHistory {
    const vendorAddress: VendorAddressHistory = formGroup.value;
    vendorAddress.phoneNumber =
      formGroup.get('phone').value.country.callingCode +
      formGroup.get('phone').value.number;
    vendorAddress.phoneRegion = formGroup.get('phone').value.country;
    vendorAddress.phone = formGroup.get('phone').value.number;
    if (formGroup.get('fax').value !== null) {
      vendorAddress.fax = formGroup.get('fax').value.number;
      vendorAddress.faxRegion = formGroup.get('fax').value.country;
    }
    vendorAddress.sizeUom = formGroup.get('size').value + '  m\u00b2';
    return vendorAddress;
  }

  public doDeleteVendorAddress(
    vendorAdressHistoryDelete: VendorAddressHistory
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tabProfileCompanyDataDTO.vendorAddressHistoryList.forEach(
            (vendorAddressHistory, index) => {
              if (vendorAddressHistory === vendorAdressHistoryDelete) {
                this.tabProfileCompanyDataDTO.vendorAddressHistoryList.splice(
                  index,
                  1
                );
              }
            }
          );
          this.tableAddressResponse.page.records =
            this.tabProfileCompanyDataDTO.vendorAddressHistoryList;
          this.tableAddressResponse.page.totalRecords =
            this.tabProfileCompanyDataDTO.vendorAddressHistoryList.length;
          this.tableAddressResponse.reloadClient();
          this.checkTable();
        }
      });
  }

  public doViewVendorAddress(
    vendorAddressHistoryEdit: VendorAddressHistory
  ): void {
    const todo = 'detail';
    const vendorAddressList = this.tabProfileCompanyDataDTO.vendorAddressList;
    const vendorAddressHistoryList =
      this.tabProfileCompanyDataDTO.vendorAddressHistoryList;
    this.appPopupService.open(
      AppPopupVendorAddressComponent,
      {
        todo,
        vendorAddressHistoryEdit,
        vendorAddressHistoryList,
        vendorAddressList,
        vendorTypeCode: this.companyProfileDTO.vendorHistory.vendorType.code
      },
      { size: 'lg' }
    );
  }

  public doAddPIC(): void {
    const todo = 'add';
    const emailList = this.emailList;
    const vendorId = 0;
    this.appPopupService
      .open(
        AppPopupPICComponent,
        { todo, emailList, vendorId },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(vendorPIC => {
        const vendorPICHistory: VendorPICHistory =
          this.setValueVendorPICHistoryFromPopup(vendorPIC);
        emailList.push(vendorPICHistory.email);
        this.tabProfileCompanyDataDTO.vendorPICHistoryList.push(
          vendorPICHistory
        );
        this.tablePICResponse.setRecords(
          this.tabProfileCompanyDataDTO.vendorPICHistoryList
        );
        this.tablePICResponse.setTotalRecords(
          this.tabProfileCompanyDataDTO.vendorPICHistoryList.length
        );
        this.tablePICResponse.reloadClient();
        this.emailList.push(vendorPICHistory.email);
        this.checkTable();
      });
  }

  public doEditPIC(vendorPICHistoryEdit: VendorPICHistory): void {
    const todo = 'edit';
    const vendorPICHistoryId = vendorPICHistoryEdit.id;
    const emailList = this.emailList;
    this.tabProfileCompanyDataDTO.vendorPICHistoryList.forEach(
      vendorPICHistory => {
        if (vendorPICHistory !== vendorPICHistoryEdit) {
          emailList.push(vendorPICHistory.email);
        }
      }
    );
    const vendorId = this.companyProfileDTO.vendor.id;
    this.appPopupService
      .open(
        AppPopupPICComponent,
        { todo, vendorPICHistoryEdit, vendorPICHistoryId, vendorId, emailList },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(vendorPICResponse => {
        this.tabProfileCompanyDataDTO.vendorPICHistoryList.forEach(
          (vendorPICHistory, index) => {
            if (vendorPICHistory === vendorPICHistoryEdit) {
              this.tabProfileCompanyDataDTO.vendorPICHistoryList[index] =
                this.setValueVendorPICHistoryFromPopup(vendorPICResponse);
              this.emailList[index] =
                this.tabProfileCompanyDataDTO.vendorPICHistoryList[index].email;
            }
          }
        );
        this.tablePICResponse.setRecords(
          this.tabProfileCompanyDataDTO.vendorPICHistoryList
        );
        this.tablePICResponse.setTotalRecords(
          this.tabProfileCompanyDataDTO.vendorPICHistoryList.length
        );
        this.tablePICResponse.reloadClient();
      });
  }

  public doDeletePIC(vendorPICHistoryDelete: VendorPICHistory): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tabProfileCompanyDataDTO.vendorPICHistoryList.forEach(
            (vendorPICHistory, index) => {
              if (vendorPICHistory === vendorPICHistoryDelete) {
                this.tabProfileCompanyDataDTO.vendorPICHistoryList.splice(
                  index,
                  1
                );
                this.emailList.splice(index, 1);
              }
            }
          );
          this.tablePICResponse.page.records =
            this.tabProfileCompanyDataDTO.vendorPICHistoryList;
          this.tablePICResponse.page.totalRecords =
            this.tabProfileCompanyDataDTO.vendorPICHistoryList.length;
          this.tablePICResponse.reloadClient();
          this.checkTable();
        }
      });
  }

  public setValueVendorPICHistoryFromPopup(
    formGroup: FormGroup
  ): VendorPICHistory {
    const vendorPic: VendorPICHistory = formGroup.value;
    vendorPic.phoneNumber =
      formGroup.get('phone').value.country.callingCode +
      formGroup.get('phone').value.number;
    vendorPic.phone = formGroup.get('phone').value.number;
    vendorPic.phoneRegion = formGroup.get('phone').value.country;
    vendorPic.isEmailNotification =
      vendorPic.isEmailNotification === null
        ? false
        : vendorPic.isEmailNotification;
    return vendorPic;
  }

  public changePKP(): void {
    const isPkp = this.tabCompanyData.formGroup.value.isPkp;
    this.tabCompanyData.formGroup
      .get('pkpNumber')
      [isPkp === 1 ? 'disable' : 'enable']();
    this.tabCompanyData.formGroup.get('pkpNumber').reset();
  }
}
