<div *ngIf="!formLoading" class="app-widget-contract-report-statistical">
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    novalidate
    *ngIf="!formLoading"
  >
    <app-widget>
      <h3 class="separator">
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            {{ moduleCode + '.title' | translate }}
          </div>
          <div class="row col-8" [ngStyle]="{ padding: '0' }">
            <div
              [ngStyle]="{
                'min-width': '150px',
                'max-width': '150px',
                'margin-left': 'auto',
                'margin-right': '18px'
              }"
            >
              <app-dropdown-select
                type="CHOSEN"
                size="XL"
                formControlName="orderTypeList"
                [optionList]="orderTypeOptionList"
                (onChange)="onChangeFilter($event)"
                showLimitValue="1"
                placeholder="Order Type"
              >
              </app-dropdown-select>
            </div>
            <div
              [ngStyle]="{
                'min-width': '150px',
                'max-width': '150px',
                'margin-right': '0px'
              }"
            >
              <app-dropdown-select
                type="COMBOBOX"
                size="XL"
                container="body"
                formControlName="year"
                [optionList]="yearOptionList"
                (onChange)="onChangeFilter($event)"
                placeholder="Year"
              >
              </app-dropdown-select>
            </div>
            <!-- </div>
                    <div class="col-3" [ngStyle]="{ 'min-width': '10rem' }"> -->
          </div>
        </div>
      </h3>
      <app-chart [model]="chartModel" height="505px"></app-chart>
    </app-widget>
  </form>
</div>
