import { BaseEntity } from '../base/base-entity';
import { Contract } from '../bean/contract';
import { ProcurementVendor } from '../bean/procurement-vendor';
import { ScopeWorkStatus } from '../bean/scope-work-status';

export class ProcurementScopeWorkEntity extends BaseEntity {
  averageRating: number; /* avg rating performance fulfillment */

  procurementVendor: ProcurementVendor = new ProcurementVendor();
  scopeWorkStatus: ScopeWorkStatus = new ScopeWorkStatus();
  contract: Contract = new Contract();
  workPercentageValue: number;
  deliveryStatus: number;
  agreementType: string;
}
