import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ContractDoc } from '../../bean/contract-doc';
import { User } from '../../bean/user';
import { AppPopupService } from '../app-popup/app-popup.service';
import { TablePluginData } from '../table/interface/table-plugin-data';
import { TableResponseModel } from '../table/model/table-response-model';
import { FileUploader } from '../upload';
import { AppPopupAddContractDocumentComponent } from './app-popup-add-contract-document.component';
import { AppPopupReviewerContractDocumentComponent } from './app-popup-reviewer-contract-document.component';

@Component({
  selector: 'app-contract-document',
  templateUrl: './app-contract-document.component.html',
  styleUrls: ['./app-contract-document.component.scss']
  // encapsulation: ViewEncapsulation.None
})
export class AppContractDocumentComponent extends BaseComponentComponent {
  @Input() mode = 'view' || 'edit';
  @Input() object: ContractDoc;
  @Output() onChange: EventEmitter<ContractDoc> = new EventEmitter();

  public tableResponse: TableResponseModel<ContractDoc>;

  constructor(public appPopupService: AppPopupService) {
    super('app-contract-document');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setInitData();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'file',
        header: 'table.column.file',
        plugins: {
          name: 'upload',
          fileUploader: new FileUploader(
            '/app-contract-document/',
            '',
            this.global.appConstant.fileType.DOC_CONTRACT,
            true
          ),
          isView: true
        },
        isSearchTable: false
      },
      {
        field: 'processedByUser',
        header: 'table.column.uploaderRole',
        plugins: {
          name: 'custom-plugin',
          after: (tablePluginData: TablePluginData) => {
            if (tablePluginData.value) {
              tablePluginData.element.innerText =
                (tablePluginData.value as User).userRoleList?.find(
                  userRole =>
                    userRole.id === this.global.userSession.activeUserRole.id
                )?.role?.name ||
                tablePluginData.value.userRoleList?.filter(
                  userRole => userRole.isDefault
                )[0]?.role?.name;
            }
          }
        }
      },
      {
        field: 'description',
        header: 'table.column.note'
      }
    ]);
  }

  public setInitData(): void {
    if (this.object.id) {
      this.tableResponse.setRecordList([this.object]);
    }
  }

  public doAdd(): void {
    this.appPopupService
      .open(AppPopupAddContractDocumentComponent, {
        contractDoc: this.object
      })
      .subscribe((contractDoc: ContractDoc) => {
        this.log.debug(contractDoc);
        this.object = contractDoc;
        this.tableResponse.setRecordList([this.object]);
        this.tableResponse.reload();
        this.onChange.emit(this.object);
      });
  }

  public doViewReviewer(): void {
    this.appPopupService.open(AppPopupReviewerContractDocumentComponent, {
      contractId: this.object.contract.id
    });
  }
}
