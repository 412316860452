<app-popup header="order.popup.splitItem" [isShowHeaderCloseButton]="isView">
    <div class="alert alert-dismissible show alert-info" role="alert">
      <div>
        <em
          style="font-size: 2rem; top: 5px; left: auto; font-weight: 500"
          class="pir pi-map-marker-alt mr-2 text-primary"
        ></em>
        <span
          class="d-block text-primary"
          style="margin-left: 3rem; font-weight: 500"
          maxLine
          >{{ address.addressDetail }}</span
        >
      </div>
    </div>
    <div class="popup-order-split-item">
      <app-table-xx
        [model]="tableResponse"
        (onChange)="doCheckValue()"
        (onAfterRowCreated)="onAfterRowCreated($event)"
        [isShowPagination]="false"
      >
      </app-table-xx>
  
      <ng-template #modalFooter let-activeModal *ngIf="!isView">
        <div class="col-12 text-center">
          <app-button
            color="SECONDARY"
            [outline]="true"
            (onClick)="activeModal.close(true)"
            >{{ 'app.button.close' | translate }}</app-button
          >
          <app-button color="PRIMARY" (onClick)="doSave()">{{
            'app.button.save' | translate
          }}</app-button>
        </div>
      </ng-template>
    </div>
  </app-popup>
  