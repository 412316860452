import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { QuotationItem } from 'src/app/core/bean/quotation-item';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';

@Component({
  templateUrl: './app-popup-open-quotation-item.component.html'
})
export class AppPopupOpenQuotationItemComponent extends BaseComponentComponent {
  @Input() public itemName: string;
  @Input() public procurementItemId: number;
  @Input() public procurementId: number;

  public tableResponseQuotationItem: TableResponseModel<QuotationItem>;

  public constructor() {
    super('open-quotation');
  }
  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponseQuotationItem = new TableResponseModel(this.moduleCode, [
      {
        field: 'quotation.procurementVendor.vendor.name',
        header: 'table.column.vendorName'
      },
      {
        field: 'price',
        header: 'table.column.bidPrice',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      {
        field: 'profitLossAmount',
        header: 'table.column.potensialSavingLoss',
        className: 'd-flex justify-content-end',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): void => {
              if (tablePlugin.value) {
                tablePlugin.element.innerText =
                  this.global.converterService.convertMoney(tablePlugin.value) +
                  ' %';
              }
            }
          },
          {
            name: 'badge',
            field: 'profitLossStatus.name',
            colorMap: {
              SAVE: 'SUCCESS',
              LOSS: 'DANGER'
            },
            pill: false,
            colorField: 'profitLossStatus.code',
            className: 'badge-catalog ml-3'
          }
        ]
      }
    ]);
    this.tableResponseQuotationItem.setCustomData({
      procurementItemId: this.procurementItemId,
      procurementId: this.procurementId
    });
  }
}
