import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppButtonModule } from '../../../core/components/app-button/app-button.module';
import { VendorModule } from '../../../core/vendor/vendor.module';
import { ErrorPageComponent } from './error-page.component';

const routes: Routes = [
  { path: '', component: ErrorPageComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [VendorModule, AppButtonModule, RouterModule.forChild(routes)],
  declarations: [ErrorPageComponent]
})
export class ErrorPageModule {}
