<div class="text-center py-4" *ngIf="formLoading">
  <img src="assets/img/common/loader-small.gif" alt="Loading" />
</div>
<form *ngIf="!formLoading">
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.memoNumber' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ paymentVoucher.code || '-' }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.invoiceNumber' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ paymentInvoice.code }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.requestDate' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{
        paymentInformationResponse.requestDatePayment
          | date: global.config.parameterModel.dateFormatShort
      }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.vendorName' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ vendor ? vendor.name : vendorOffline.name }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.taxNumber' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ (vendor ? vendor.taxNumber : vendorOffline.taxNumber) || '-' }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.bankName' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorBank ? vendorBank.bank.name : vendorBankOffline.bankName }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.accountNumber' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{
        vendorBank
          ? vendorBank.accountNumber
          : vendorBankOffline.accountNumber || '-'
      }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.accountHolder' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{
        vendorBank ? vendorBank.accountHolder : vendorBankOffline.accountHolder
      }}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <div class="card border-0" [ngStyle]="{ margin: '1.5%', width: '70%' }">
      <div class="card-body">
        <div
          class="table-responsive custom-table float-none w-100"
          [ngStyle]="{ margin: '0 auto' }"
        >
          <table
            class="table table-striped box-shadow"
            aria-describedby="Table"
          >
            <thead>
              <tr>
                <th class="text-center" scope="col"
                  >{{ 'app.table.column.no' | translate }}
                </th>
                <th class="text-left" scope="col"
                  >{{ 'payment-history.table.column.costName' | translate }}
                </th>
                <th class="text-right" scope="col"
                  >{{ 'payment-history.table.column.value' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center"> 1. </td>
                <td class="text-left">
                  {{ 'payment-history.table.column.taxBaseTotal' | translate }}
                </td>
                <td class="text-right">
                  {{
                    paymentInformationResponse.taxBaseTotal
                      ? (paymentInformationResponse.taxBaseTotal
                        | currency: ' ')
                      : 0
                  }}
                  {{
                    paymentInformationResponse.paymentVoucher.payment.currency
                      ?.code
                  }}
                </td>
              </tr>
              <tr>
                <td class="text-center"> 2. </td>
                <td class="text-left">
                  {{ 'payment-history.table.column.vatTotal' | translate }}
                </td>
                <td class="text-right">
                  {{
                    paymentInformationResponse.vatTotal
                      ? (paymentInformationResponse.vatTotal | currency: ' ')
                      : 0
                  }}
                  {{
                    paymentInformationResponse.paymentVoucher.payment.currency
                      ?.code
                  }}
                </td>
              </tr>
              <tr
                *ngFor="let cost of paymentVoucherOtherCostList; let i = index"
              >
                <td class="text-center">
                  {{ i + 3 }}
                </td>
                <td class="text-left">
                  {{ cost.name }}
                </td>
                <td class="text-right">
                  {{ cost.balanceType === 2 ? '-' : '' }}
                  {{ cost.amount ? (cost.amount | currency: ' ') : 0 }}
                  {{
                    paymentInformationResponse.paymentVoucher.payment.currency
                      ?.code
                  }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td class="text-left">
                  <b>
                    {{ 'payment-history.table.column.subTotal' | translate }}
                  </b>
                  <br />
                  <br />
                  <b>
                    {{
                      'payment-history.table.column.incomeTaxTotal' | translate
                    }}
                  </b>
                </td>
                <td class="text-right">
                  <b>
                    {{
                      paymentInformationResponse.subTotal
                        ? (paymentInformationResponse.subTotal | currency: ' ')
                        : 0
                    }}
                    {{
                      paymentInformationResponse.paymentVoucher.payment.currency
                        ?.code
                    }}
                  </b>
                  <br />
                  <br />
                  <b>
                    -
                    {{
                      paymentInformationResponse.incomeTaxTotal
                        ? (paymentInformationResponse.incomeTaxTotal
                          | currency: ' ')
                        : 0
                    }}
                    {{
                      paymentInformationResponse.paymentVoucher.payment.currency
                        ?.code
                    }}
                  </b>
                </td>
              </tr>
              <tr>
                <td></td>
                <td class="text-left">
                  <b>
                    {{
                      'payment-history.table.column.totalPaidToVendor'
                        | translate
                    }}
                  </b>
                </td>
                <td class="text-right">
                  <b>
                    {{
                      paymentVoucher.totalVoucherAmount
                        ? (paymentVoucher.totalVoucherAmount | currency: ' ')
                        : 0
                    }}
                    {{
                      paymentInformationResponse.paymentVoucher.payment.currency
                        ?.code
                    }}
                  </b>
                </td>
              </tr>
              <tr>
                <td></td>
                <td class="text-left">
                  <b>
                    {{ 'payment-history.table.column.say' | translate }}
                  </b>
                </td>
                <td class="text-right">
                  <b> {{ paymentVoucher.sayVoucherAmount }} Rupiah </b>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>

  <h6 class="separator">{{
    'payment-history.separator.proofOfPayment' | translate
  }}</h6>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.file' | translate
    }}</label>
    <div class="col">
      <div class="col-12 text-left p-0">
        <a
          href="#"
          (click)="
            doDownload(
              $event,
              '/payment-history/file/view/' +
                paymentProof?.file.uploadedFileName +
                '/' +
                paymentProof?.file.fileType.code,
              paymentProof.file.fileName
            )
          "
        >
          {{ paymentProof?.file.fileName ? paymentProof.file.fileName : '-' }}
        </a>
      </div>
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.description' | translate
    }}</label>
    <div class="col-sm-6 text-left">
      {{ paymentProof?.description || '-' }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.uploadBy' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ paymentInformationResponse.paymentProofUploadBy || '-' }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.uploadDate' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{
        (paymentInformationResponse.paymentProofCreatedAt
          | date: global.config.parameterModel.datetimeFormatShort) || '-'
      }}</div
    >
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.version' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ paymentProof?.version || '-' }}
    </div>
  </div>

  <h6 class="separator">{{
    'payment-history.separator.proofOfTax' | translate
  }}</h6>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.file' | translate
    }}</label>
    <div class="col">
      <div class="col-12 text-left p-0">
        <a
          href="#"
          (click)="
            doDownload(
              $event,
              '/payment-history/file/view/' +
                paymentTaxProof?.file.uploadedFileName +
                '/' +
                paymentTaxProof?.file.fileType.code,
              paymentTaxProof?.file.fileName
            )
          "
        >
          {{
            paymentTaxProof?.file.fileName ? paymentTaxProof.file.fileName : '-'
          }}
        </a>
      </div>
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.description' | translate
    }}</label>
    <div class="col-sm-6 text-left">
      {{ paymentTaxProof?.description || '-' }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.uploadBy' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ paymentInformationResponse.paymentTaxProofUploadBy || '-' }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.uploadDate' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{
        (paymentInformationResponse.paymentTaxProofCreatedAt
          | date: global.config.parameterModel.datetimeFormatShort) || '-'
      }}</div
    >
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'payment-history.form.version' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ paymentTaxProof?.version || '-' }}
    </div>
  </div>
</form>
