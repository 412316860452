import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Currency } from 'src/app/core/bean/currency';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { ItemType } from '../../../bean/item-type';
import { ProcurementItem } from '../../../bean/procurement-item';
import { PopupAllocatedItemDetailView } from '../../../view/entity/bean/popup-allocated-item-detail-view';
import { TablePluginData } from '../../table/interface/table-plugin-data';
import { TableResponseModel } from '../../table/model/table-response-model';

@Component({
  templateUrl: './app-popup-allocated-item-detail.component.html'
})
export class AppPopupAllocatedItemDetailComponent extends BaseModuleComponent {
  @Input() procurementVendorId: number;
  @Input() biddingEvaluationCode: string;
  @Output() onChange = new EventEmitter();

  public isView = false;
  public reminingQuantity: number;
  public procurementItem: ProcurementItem;
  public currencyList: Currency[];
  public CURRENCY_DIGITS_INFO: string;
  public popupAllocatedItemDetailViewList: PopupAllocatedItemDetailView[] = [];
  public tableResponse: TableResponseModel<PopupAllocatedItemDetailView>;
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    false
  );

  constructor(
    translateService: TranslateService,
    public currencyPipe: CurrencyPipe
  ) {
    super('app-popup-choose-vendor-multi-winner', translateService);
  }

  public onInit(): void {
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
    this.buildFormGroup();
    this.setIsView();
    this.setFormGroup();
  }

  public setIsView(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }
  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      itemType: [null],
      amount: [null],
      goodsAmount: [null],
      serviceAmount: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<ItemType[]>(
        '/app-popup-allocated-item-detail/index/' + this.procurementVendorId
      )
      .subscribe(response => {
        if (response != null) {
          this.popupAllocatedItemDetailViewList =
            response.popupAllocatedItemDetailViewList;
          this.itemTypeOptionList.setRequestValues(response.itemTypeList);
          if (this.popupAllocatedItemDetailViewList && this.popupAllocatedItemDetailViewList.length > 0) {
            this.biddingEvaluationCode = this.popupAllocatedItemDetailViewList[0].quotationItemReference.quotationItem.procurementItem.procurement.biddingEvaluation.code;
            this.popupAllocatedItemDetailViewList.forEach(popupAllocatedItemDetailView => {
              popupAllocatedItemDetailView.quotationItemReference.quantity = popupAllocatedItemDetailView.quotationItemReference.quantity ? popupAllocatedItemDetailView.quotationItemReference.quantity : popupAllocatedItemDetailView.quotationItemReference.tempQuantity ?
                popupAllocatedItemDetailView.quotationItemReference.tempQuantity : popupAllocatedItemDetailView.quotationItemReference.tempQuantityNego;
            });
          }
          this.buildTableResponse();
          this.tableResponse.setRecordList(
            this.popupAllocatedItemDetailViewList
          );
          this.tableResponse.reload();
          this.doSetAmount();
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      this.biddingEvaluationCode ===
        this.global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
        ? [
          {
            field:
              'quotationItemReference.quotationItem.procurementItem.prItem.prItemImageList',
            header: 'table.column.thumbnail',
            plugins: [
              {
                name: 'custom-plugin',
                before: (tablePluginData: TablePluginData): File => {
                  if (
                    tablePluginData.value &&
                    tablePluginData.value.length > 0
                  ) {
                    return tablePluginData.value[0].file;
                  }
                  return null;
                }
              },
              {
                name: 'img',
                classNameMap: {
                  MATERIAL: 'pvr pv-box',
                  JASA: 'pvr pv-tools'
                },
                fieldClassName:
                  'quotationItemReference.quotationItem.procurementItem.prItem.item.itemType.code'
              }
            ],
            isSortable: false,
            isSearchTable: false
          },
          {
            field:
              'quotationItemReference.quotationItem.procurementItem.prItem.item.name',
            header: 'table.column.itemName'
          },
          {
            field:
              'quotationItemReference.quotationItem.procurementItem.prItem.pr.code',
            header: 'table.column.itemCode'
          },
          {
            field:
              'quotationItemReference.quotationItem.procurementItem.prItem.code',
            header: 'table.column.prLine'
          },
          {
            field:
              'quotationItemReference.quotationItem.procurementItem.prItem.item.itemType.name',
            header: 'table.column.type',
            plugins: {
              name: 'badge',
              pill: true,
              colorMap: {
                MATERIAL: 'GOOD',
                JASA: 'SERVICE'
              },
              colorField:
                'quotationItemReference.quotationItem.procurementItem.prItem.item.itemType.code'
            }
          },
          {
            field: 'quotationItemReference.quantity',
            header: 'table.column.qty',
            className: 'text-right',
            plugins: {
              name: 'text-field',
              type: 'decimal',
              isView: true
            }
          },
          {
            field: 'finalPriceScoreStatus.name',
            header: 'table.column.price',
            plugins: {
              name: 'badge',
              colorField: 'finalPriceScoreStatus.code',
              colorMap: {
                PASSED: 'SUCCESS',
                NOT_PASSED: 'DANGER'
              }
            }
          },
          {
            field: 'quotationItemReference.quotationItem.finalPrice',
            header: 'table.column.finalPrice',
            className: 'text-right',
            plugins: {
              name: 'default',
              type: 'currency'
            }
          },
          {
            field: 'quotationItemReference.totalPrice',
            header: 'table.column.totalPrice',
            className: 'text-right',
            plugins: {
              name: 'default',
              type: 'currency'
            }
          },
          {
            field:
              'quotationItemReference.quotationItem.finalProfitLossAmount',
            header: 'table.column.saveLoss',
            className: 'text-right',
            plugins: [
              {
                name: 'custom-plugin',
                before: (tablePlugin: TablePluginData): void => {
                  if (tablePlugin.value) {
                    tablePlugin.element.innerText =
                      this.currencyPipe.transform(
                        +tablePlugin.value,
                        '',
                        '',
                        this.CURRENCY_DIGITS_INFO
                      ) + ' %';
                  }
                }
              },
              {
                name: 'badge',
                field:
                  'quotationItemReference.quotationItem.finalProfitLossStatus.name',
                colorMap: {
                  SAVE: 'SUCCESS',
                  LOSS: 'DANGER'
                },
                pill: false,
                colorField:
                  'quotationItemReference.quotationItem.finalProfitLossStatus.code',
                className: 'badge-catalog ml-3'
              }
            ]
          }
        ]
        : [
          {
            field:
              'quotationItemReference.quotationItem.procurementItem.prItem.prItemImageList',
            header: 'table.column.thumbnail',
            plugins: [
              {
                name: 'custom-plugin',
                before: (tablePluginData: TablePluginData): File => {
                  if (
                    tablePluginData.value &&
                    tablePluginData.value.length > 0
                  ) {
                    return tablePluginData.value[0].file;
                  }
                  return null;
                }
              },
              {
                name: 'img',
                classNameMap: {
                  MATERIAL: 'pvr pv-box',
                  JASA: 'pvr pv-tools'
                },
                fieldClassName:
                  'quotationItemReference.quotationItem.procurementItem.prItem.item.itemType.code'
              }
            ],
            isSortable: false,
            isSearchTable: false
          },
          {
            field:
              'quotationItemReference.quotationItem.procurementItem.prItem.item.name',
            header: 'table.column.itemName'
          },
          {
            field:
              'quotationItemReference.quotationItem.procurementItem.prItem.pr.code',
            header: 'table.column.itemCode'
          },
          {
            field:
              'quotationItemReference.quotationItem.procurementItem.prItem.code',
            header: 'table.column.prLine'
          },
          {
            field:
              'quotationItemReference.quotationItem.procurementItem.prItem.item.itemType.name',
            header: 'table.column.type',
            plugins: {
              name: 'badge',
              pill: true,
              colorMap: {
                MATERIAL: 'GOOD',
                JASA: 'SERVICE'
              },
              colorField:
                'quotationItemReference.quotationItem.procurementItem.prItem.item.itemType.code'
            }
          },
          {
            field: 'quotationItemReference.quantity',
            header: 'table.column.qty',
            className: 'text-right',
            plugins: {
              name: 'text-field',
              type: 'decimal',
              isView: true
            }
          },
          {
            field: 'finalPriceScore',
            header: 'table.column.evaluationPrice',
            className: 'text-right',
            plugins: {
              name: 'text-field',
              type: 'decimal',
              isView: true
            }
          },
          {
            field: 'quotationItemReference.quotationItem.finalPrice',
            header: 'table.column.finalPrice',
            className: 'text-right',
            plugins: {
              name: 'default',
              type: 'currency'
            }
          },
          {
            field: 'quotationItemReference.totalPrice',
            header: 'table.column.totalPrice',
            className: 'text-right',
            plugins: {
              name: 'default',
              type: 'currency'
            }
          },
          {
            field:
              'quotationItemReference.quotationItem.finalProfitLossAmount',
            header: 'table.column.saveLoss',
            className: 'text-right',
            plugins: [
              {
                name: 'custom-plugin',
                before: (tablePlugin: TablePluginData): void => {
                  if (tablePlugin.value) {
                    tablePlugin.element.innerText =
                      this.currencyPipe.transform(
                        +tablePlugin.value,
                        '',
                        '',
                        this.CURRENCY_DIGITS_INFO
                      ) + ' %';
                  }
                }
              },
              {
                name: 'badge',
                field:
                  'quotationItemReference.quotationItem.finalProfitLossStatus.name',
                colorMap: {
                  SAVE: 'SUCCESS',
                  LOSS: 'DANGER'
                },
                pill: false,
                colorField:
                  'quotationItemReference.quotationItem.finalProfitLossStatus.code',
                className: 'badge-catalog ml-3'
              }
            ]
          }
        ]
    );
  }

  public onChangeItem(): void {
    const itemType = this.formGroup.value.itemType;
    if (itemType) {
      const list = [];
      this.tableResponse
        .getRecordList()
        .forEach(
          (popupAllocatedItemDetailView: PopupAllocatedItemDetailView) => {
            if (
              popupAllocatedItemDetailView.quotationItemReference.quotationItem
                .procurementItem.prItem.item.itemType.id === itemType.id
            ) {
              list.push(popupAllocatedItemDetailView);
            }
          }
        );
      this.tableResponse.setRecordList(list);
    } else {
      this.tableResponse.setRecordList(this.popupAllocatedItemDetailViewList);
    }
    this.tableResponse.reload();
    this.doSetAmount();
  }

  public doSetAmount(): void {
    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;
    this.tableResponse
      .getRecordList()
      .forEach((popupAllocatedItemDetailView: PopupAllocatedItemDetailView) => {
        if (
          popupAllocatedItemDetailView.quotationItemReference.quotationItem
            .procurementItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
        ) {
          goodsAmountTotal =
            +goodsAmountTotal +
            +popupAllocatedItemDetailView.quotationItemReference.totalPrice;
        }
        if (
          popupAllocatedItemDetailView.quotationItemReference.quotationItem
            .procurementItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
        ) {
          serviceAmountTotal =
            +serviceAmountTotal +
            +popupAllocatedItemDetailView.quotationItemReference.totalPrice;
        }
      });
    this.formGroup.patchValue({
      goodsAmount: goodsAmountTotal,
      serviceAmount: serviceAmountTotal,
      amount: +goodsAmountTotal + +serviceAmountTotal
    });
  }
}
