import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CustomValidatorError } from '../../core/custom-validator-error';
export class UrlValidator {
  public static url(message?: string): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors => {
      const value = abstractControl.value;
      if (value) {
        // Regex source: Stackoverflow Answer ("Ant P") - https://stackoverflow.com/a/18568275/8012192
        const regex =
          /^http(s)?:\/\/(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        const isValidUrl = regex.test(value);
        if (!isValidUrl) {
          return CustomValidatorError.create('url', message);
        }
      }
      return null;
    };
  }
}
