import { BaseEntity } from '../base/base-entity';
import { ContractDetail } from '../bean/contract-detail';
import { ContractShipping } from '../bean/contract-shipping';

export class ContractShippingDetailEntity extends BaseEntity {
    contractShipping: ContractShipping;
    contractDetail: ContractDetail;
    quantity: number;
    remainingQuantity: number;
}
