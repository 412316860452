import { Component, ViewEncapsulation } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Organization } from '../../core/bean/organization';
import { TorSubmission } from '../../core/bean/tor-submission';
import { WorkPlan } from '../../core/bean/work-plan';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { ApprovalPathModel } from '../../core/components/app-approval-path';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { WorkflowPrcs } from './../../core/bean/workflow-prcs';
import { TorSubmissionRequest } from './tor-submission-request';
import { TorSubmissionResponse } from './tor-submission-response';

@Component({
  templateUrl: './tor-submission-edit-add.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./tor-submission-edit-add.component.scss']
})
export class TorSubmissionEditAddComponent extends BaseModuleComponent {
  public torSubmission: TorSubmission = new TorSubmission();
  public workPlan: WorkPlan = new WorkPlan();
  public url: string;
  public workPlanId: number;
  public workflowPrcs: WorkflowPrcs;
  public isAllUploaded = false;
  public objectId: number;
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public torSubmissionResponse: TorSubmissionResponse =
    new TorSubmissionResponse();
  public torSubmissionRequest: TorSubmissionRequest =
    new TorSubmissionRequest();
  public approvalPath: ApprovalPathModel<any> = new ApprovalPathModel(
    this.moduleCode,
    'READ'
  );
  public fileUploader: FileUploader = new FileUploader(
    '/tor-submission/',
    '',
    this.global.appConstant.fileType.DOC_TOR,
    false,
    5
  );

  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('tor-submission', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.objectId = this.global.routerParams.get('objectId');
    this.workPlan = this.global.routerParams.get('workPlan');
    this.workPlanId = this.objectId;
    if (this.objectId == null) {
      this.workPlan = this.global.routerParams.get('workPlan');
      this.workPlanId = this.workPlan.id;
    }
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      today: [new Date()],
      number: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      createdDate: [null, Validators.required()],
      organization: [null, Validators.required()],
      subject: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(128)])
      ],
      description: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(512)])
      ],
      uploadFile: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<TorSubmissionResponse>(
        '/tor-submission/add-edit',
        new RouteRequestModel(
          this.todo,
          this.objectId != null ? this.objectId : this.workPlanId
        )
      )
      .subscribe(torResponse => {
        this.torSubmissionResponse = torResponse;
        this.workPlan = this.torSubmissionResponse.workPlan;
        if (this.objectId != null) {
          this.workPlanId = this.workPlan.id;
        }
        this.workflowPrcs = this.workPlan.workflowPrcs;
        this.workflowModelPrcs = this.workPlan.workflowModelPrcs;
        this.formGroup.patchValue({
          organization: this.torSubmissionResponse.organizationList
        });
        if (this.torSubmissionResponse.tor != null) {
          this.todo = this.todo !== 'view' ? 'edit' : 'view';
          if (this.torSubmissionResponse.approvalPathResponseModel != null) {
            const { type, userApproverList } =
              this.torSubmissionResponse.approvalPathResponseModel;
            this.approvalPath.setApproverList(userApproverList).setType(type);
          }
          this.fileUploader.setFileList(this.torSubmissionResponse.fileList);
          const createdDate = new Date(
            this.torSubmissionResponse.tor.createdDate
          );
          this.formGroup.patchValue({
            id: this.torSubmissionResponse.tor.id,
            number: this.torSubmissionResponse.tor.number,
            createdDate,
            subject: this.torSubmissionResponse.tor.subject,
            description: this.torSubmissionResponse.tor.description,
            uploadFile: this.fileUploader.fileObjectList
          });
        } else if (
          this.torSubmissionResponse.tor == null &&
          this.todo !== 'add'
        ) {
          this.global.routerParams.clear();
          this.global.routerParams.set('todo', 'add');
          this.global.routerParams.set('workPlan', this.workPlan);
          this.router.navigate(['/pages/tor-submission/add']);
        }
        this.setStateReady();
      });
    // tslint:disable-next-line:align
    this.formGroup.get('organization').setIsView(true);
  }

  public doSave(): void {
    if (this.torSubmissionResponse.isNumberGenerator) {
      this.formGroup.get('number').clearValidators();
      this.formGroup.get('number').updateValueAndValidity();
    }
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.torSubmission = this.formGroup.value;
            this.torSubmission.workPlan = this.workPlan;
            this.torSubmission.workPlan.workflowModelPrcs =
              this.workPlan.workflowModelPrcs;
            this.torSubmissionRequest.workPlan =
              this.torSubmissionResponse.workPlan;
            this.torSubmissionRequest.fileObjectList =
              this.fileUploader.fileObjectList;
            this.torSubmission = this.global.copyFormAttributeToModel(
              this.torSubmission,
              this.formGroup
            );
            const organizationList: Organization[] =
              this.formGroup.get('organization').value;
            this.torSubmission.organization =
              organizationList[organizationList.length - 1];
            this.torSubmissionRequest.tor = this.torSubmission;
            if (this.torSubmission.id !== null) {
              this.url = '/tor-submission/update';
            } else {
              this.url = '/tor-submission/insert';
            }
            this.httpClientService
              .post<Response<TorSubmission>>(
                this.url,
                this.torSubmissionRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/worklist-pra-procurement']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public onChangeFileUpload(fileUploader: FileUploader): void {
    let isAllUploaded = true;
    if (!fileUploader.isAllUploaded) {
      isAllUploaded = fileUploader.isAllUploaded;
    }
    this.isAllUploaded = isAllUploaded;
  }
  public doBack(): void {
    this.router.navigate(['/pages/worklist-pra-procurement/']);
  }
}
