import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppChartModule } from '../../components/app-chart/app-chart.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetContractRequestByTypeComponent } from './app-widget-contract-request-by-type.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule, AppChartModule],
  declarations: [AppWidgetContractRequestByTypeComponent],
  entryComponents: [AppWidgetContractRequestByTypeComponent],
  exports: [AppWidgetContractRequestByTypeComponent]
})
export class AppWidgetContractRequestByTypeModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-contract-request-by-type', AppWidgetContractRequestByTypeComponent);
  }
}
