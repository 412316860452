import { BaseEntity } from '../base/base-entity';
import { Vendor } from '../bean/vendor';
import { VendorHistory } from '../bean/vendor-history';

export class DueDiligenceScheduleEntity extends BaseEntity {
    vendorHistory: VendorHistory = new VendorHistory();
    vendor: Vendor = new Vendor();
    address: string;
    date: Date;
    startTime: Date;
    endTime: Date;
    objective: string;
    groupName: string;
    sequence: number;
    dueDiligenceNumber: string;

}
