<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-workflow-progress-info
      [contract]="contract"
      [currentPosition]="moduleCode"
      *ngIf="
        !formLoading &&
        (!contractDraftAddEditResponse.isUserApproval ||
          contractDraftAddEditResponse.isUserApproval === null)
      "
    >
    </app-workflow-progress-info>
    <div class="app-accordion-row">
      <app-card
        [isLoading]="formLoading"
        [isSaving]="formSaving"
        header="contract-draft.title"
      >
        <app-accordion [isLoading]="formLoading">
          <app-accordion-item
            header="contract-draft.accordion.header.draftDataForm"
            [isExpanded]="true"
          >
            <div class="text-center py-4" *ngIf="!isParameterReady">
              <img src="assets/img/common/loader-small.gif" alt="Loading" />
            </div>
            <div *ngIf="isParameterReady">
              <form class="form-horizontal" [formGroup]="formGroup" novalidate>
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'contract-draft.form.requestNumber' | translate
                  }}</label>
                  <div class="col-sm-9 text-left">
                    <label class="col-sm-3 control-label">{{
                      formGroup.get('requestNumber').value
                    }}</label>
                  </div>
                </div>
                <div
                  class="form-group row text-sm-right"
                  [ngClass]="!formGroup.isView ? 'required' : ''"
                >
                  <label class="col-sm-3 control-label">{{
                    'contract-draft.form.template' | translate
                  }}</label>
                  <div class="col-sm-9 text-left">
                    <app-combo-box
                      formControlName="contractTemplateList"
                      (onChange)="onChangeTemplateList($event)"
                      [optionList]="contractTemplateList"
                    ></app-combo-box>
                  </div>
                </div>
                <div
                  class="text-center py-4"
                  *ngIf="!showTables && contractTemplate"
                >
                  <img src="assets/img/common/loader-small.gif" alt="Loading" />
                </div>
                <div *ngIf="showTables">
                  <h5 class="separator mt-3">{{
                    'contract-draft.form-group.parameter' | translate
                  }}</h5>
                  <div class="removeSearchText app-table-search">
                    <ng-container formArrayName="contractTemplateParamList">
                      <div class="form-group row text-sm-right">
                        <div
                          class="card border-0"
                          [ngStyle]="{
                            margin: '0 1.5%',
                            width: '-webkit-fill-available'
                          }"
                        >
                          <div class="card-body">
                            <div
                              class="table-responsive custom-table float-none w-100"
                              [ngStyle]="{ margin: '0 auto' }"
                            >
                              <table
                                class="table table-striped box-shadow"
                                aria-describedby="Table"
                              >
                                <thead>
                                  <tr class="text-center">
                                    <th class="tableNo" colspan="1" scope="col">
                                      {{ 'app-table.column.no' | translate }}
                                    </th>
                                    <th scope="col" class="text-left">{{
                                      'contract-draft.table.column.name'
                                        | translate
                                    }}</th>
                                    <th scope="col" class="text-left">{{
                                      'contract-draft.table.column.value'
                                        | translate
                                    }}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    *ngFor="
                                      let contractTemplateParam of contractTemplateParamList.controls;
                                      let i = index
                                    "
                                    [formGroupName]="i"
                                  >
                                    <td class="text-center" colspan="1">
                                      {{ i + 1 }}
                                    </td>
                                    <td
                                      class="text-left"
                                      [ngStyle]="{
                                        'max-width': '240px',
                                        'white-space': 'normal',
                                        'min-width': '200px'
                                      }"
                                    >
                                      {{
                                        contractTemplateParam.controls.name
                                          .value
                                      }}
                                      <span
                                        class="font-weight-bold text-danger"
                                        *ngIf="
                                          (!formGroup.isView &&
                                            !contractTemplateParam.controls.code.value.includes(
                                              'PERUBAHAN'
                                            ) &&
                                            contractTemplateParam.controls.code
                                              .value !==
                                              global.appConstant.cm
                                                .CONTRACT_PARAMETER_NOMOR_KONTRAK) ||
                                          (!formGroup.isView &&
                                            contract &&
                                            contract.contractType.code !==
                                              global.appConstant.cm
                                                .CONTRACT_TYPE_ADDENDUM_CONTRACT &&
                                            contractTemplateParam.controls.code
                                              .value ===
                                              global.appConstant.cm
                                                .CONTRACT_PARAMETER_NOMOR_KONTRAK)
                                        "
                                        >*
                                      </span>
                                    </td>
                                    <td class="text-left">
                                      <div
                                        *ngIf="
                                          contractTemplateParam.controls
                                            .valueTypeCode.value ===
                                          global.appConstant.core
                                            .VALUE_TYPE_STRING
                                        "
                                      >
                                        <app-text-field
                                          size="LG"
                                          formControlName="value"
                                          (onChange)="onChangeValue()"
                                        ></app-text-field>
                                      </div>
                                      <div
                                        *ngIf="
                                          contractTemplateParam.controls
                                            .valueTypeCode.value ===
                                          global.appConstant.core
                                            .VALUE_TYPE_NUMBER
                                        "
                                      >
                                        <app-text-field
                                          formControlName="value"
                                          type="integer"
                                          (onChange)="onChangeValue()"
                                        ></app-text-field>
                                      </div>
                                      <div
                                        *ngIf="
                                          contractTemplateParam.controls
                                            .valueTypeCode.value ===
                                            global.appConstant.core
                                              .VALUE_TYPE_DATE_FORMAT_SHORT ||
                                          contractTemplateParam.controls
                                            .valueTypeCode.value ===
                                            global.appConstant.core
                                              .VALUE_TYPE_DATE_FORMAT_LONG
                                        "
                                      >
                                        <app-date-picker
                                          formControlName="value"
                                          (onChange)="onChangeValue()"
                                        >
                                        </app-date-picker>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </form>
              <div class="button-group button-group-center">
                <app-button
                  (onClick)="doSaveDraft()"
                  [disabled]="formSaving"
                  *ngIf="!isViewOnly"
                >
                  {{ 'app.button.saveDraft' | translate }}
                </app-button>
                <app-button
                  (onClick)="doNext()"
                  [disabled]="formSaving"
                  *ngIf="!isViewOnly"
                >
                  {{ 'app.button.next' | translate }}
                </app-button>
              </div>
            </div>
          </app-accordion-item>
          <app-accordion-item
            header="contract-draft.accordion.header.collabaccess"
            [isExpanded]="isNextAccordion"
          >
            <form
              class="form-horizontal"
              [formGroup]="formGroupCollab"
              novalidate
              *ngIf="!formLoading"
            >
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'contract-draft.form.file' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <ng-container
                    *ngIf="
                      contractDraftAddEditResponse.contractDraftHistory !==
                        null;
                      then valueImpl;
                      else nullImpl
                    "
                  ></ng-container>
                  <ng-template #valueImpl>
                    <a
                      href="#"
                      (click)="
                        doDownload(
                          $event,
                          '/contract-request/file/view/' +
                            formGroupCollab.value.file.uploadedFileName +
                            '/' +
                            formGroupCollab.value.file.fileType.code,
                          formGroupCollab.value.file.fileName
                        )
                      "
                    >
                      <p>{{
                        formGroupCollab.value.file
                          ? formGroupCollab.value.file.fileName
                          : '-'
                      }}</p>
                    </a>
                  </ng-template>
                  <ng-template #nullImpl> - </ng-template>
                </div>
              </div>

              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'contract-draft.form.version' | translate
                }}</label>
                <div class="col-sm-9 text-left d-flex">
                  <ng-container
                    *ngIf="
                      contractDraftAddEditResponse.contractDraftHistory !==
                        null;
                      then versionImpl;
                      else nullVersionImpl
                    "
                  ></ng-container>
                  <ng-template #versionImpl>
                    <p>{{
                      formGroupCollab.value.version !== null
                        ? formGroupCollab.value.version
                        : '-'
                    }}</p>
                  </ng-template>
                  <ng-template #nullVersionImpl> - </ng-template>
                  <app-button
                    class="pl-3"
                    (onClick)="doVersions()"
                    [disabled]="formSaving"
                  >
                    {{ 'app.button.version' | translate }}
                  </app-button>
                </div>
              </div>

              <div class="form-group row text-sm-right" *ngIf="!isViewOnly">
                <label class="col-sm-3 control-label">{{
                  'contract-draft.form.uploadFile' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-upload
                    formControlName="uploadFile"
                    [fileUploader]="fileUploader"
                    tooltip="{{
                      'contract-draft.tooltip.uploadFile' | translate
                    }}"
                    (onChange)="onChangeValue()"
                  >
                  </app-upload>
                </div>
              </div>

              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'contract-draft.form.description' | translate
                }}</label>
                <div
                  class="text-left"
                  [ngClass]="!isViewOnly ? 'col-sm-9' : 'col-sm-6'"
                >
                  <app-text-area
                    formControlName="note"
                    type="alphanumeric"
                    maxLength="512"
                    (onChange)="onChangeValue()"
                  ></app-text-area>
                </div>
              </div>
            </form>
            <h5 class="separator mt-3">{{
              'contract-draft.form-group.participantReviewers' | translate
            }}</h5>
            <app-table
              #selectorCollab
              [model]="tableResponseCollab"
              *ngIf="!formLoading"
            >
              <ng-template #headerButtons>
                <app-button
                  (onClick)="doAdd()"
                  [disabled]="formSaving"
                  *ngIf="!isViewOnly"
                  ><span class="fas fa-plus"></span>&nbsp;
                  {{ 'app.button.add' | translate }}</app-button
                >
              </ng-template>
              <ng-template #actionButtons let-rowData *ngIf="!isViewOnly">
                <app-button
                  size="TN"
                  (onClick)="doEdit(rowData)"
                  title="{{ 'app.tooltip.edit' | translate }}"
                  [disabled]="formSaving"
                >
                  <span class="fas fa-pencil-alt"></span>
                </app-button>
                <app-button
                  size="TN"
                  color="DANGER"
                  (onClick)="doDelete(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                  [disabled]="formSaving"
                >
                  <span class="fas fa-trash"></span>
                </app-button>
              </ng-template>
            </app-table>
            <div class="button-group button-group-center">
              <app-button
                (onClick)="doSaveDraft()"
                [disabled]="formSaving"
                *ngIf="!isViewOnly"
              >
                {{ 'app.button.saveDraft' | translate }}
              </app-button>
              <app-button
                (onClick)="doSave()"
                [disabled]="formSaving"
                *ngIf="!isViewOnly"
              >
                {{ 'app.button.save' | translate }}
              </app-button>
            </div>
          </app-accordion-item>
          <app-accordion-item
            header="contract-draft.accordion.header.contractInformation"
            [isHidden]="
              !(
                ((contract &&
                  contract.contractType.code ===
                    global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT) ||
                  (contract &&
                    contract.contractType.code ===
                      global.appConstant.cm.CONTRACT_TYPE_REPEAT_ORDER)) &&
                !formLoading
              )
            "
          >
            <app-procurement-information-view
              [contractId]="
                contract.fromContract ? contract.fromContract.id : contract.id
              "
            ></app-procurement-information-view>
          </app-accordion-item>
          <app-accordion-item
            header="contract-draft.accordion.header.procurementInformation"
          >
            <app-procurement-information-view
              [procurementVendorId]="contract.procurementVendor.id"
            >
            </app-procurement-information-view>
          </app-accordion-item>
          <app-accordion-item header="contract-draft.header.requestInformation">
            <app-contract-request-view [contractId]="contract.id">
            </app-contract-request-view>
          </app-accordion-item>
          <app-accordion-item
            header="contract-draft.accordion.vendorInformation"
          >
            <app-vendor-payment-information
              [procurementVendorId]="contract.procurementVendor.id"
              [todo]="todo"
              [temp]="contractDraftAddEditResponse"
            ></app-vendor-payment-information>
          </app-accordion-item>
          <app-accordion-item
            header="contract-draft.accordion.header.historyApproval"
          >
            <app-approval-log
              [moduleCode]="moduleCode"
              [workflowPrcs]="workflowPrcs"
            >
            </app-approval-log>
          </app-accordion-item>
          <app-accordion-item
            header="contract-draft.accordion.header.historyReviewContract"
          >
            <app-contract-review-log
              [contractId]="contract.id"
              [code]="global.appConstant.cm.REVIEW_CONTRACT"
            >
            </app-contract-review-log>
          </app-accordion-item>
        </app-accordion>

        <div
          class="floating-button mt-5"
          *ngIf="!contractDraftAddEditResponse.isUserApproval"
        >
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center">
                <app-button
                  color="SECONDARY"
                  (onClick)="doBack()"
                  [disabled]="formSaving"
                  >{{ 'app.button.back' | translate }}</app-button
                >
              </div>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</div>
<app-approval-prcs
  *ngIf="contractDraftAddEditResponse.isUserApproval && !formLoading"
  [workflowModelPrcs]="contract.workflowModelPrcs"
>
</app-approval-prcs>
