import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from '../../../../../../core/base/angular/base-component.component';
import { PaginationModel } from '../../../../../../core/components/app-pagination/model/pagination-model';
import { TablePageModel } from '../../../../../../core/components/app-table/model/table-page-model';
import { TableCatalogRequestModel } from '../app-table-catalog/model/table-catalog-request-model';
@Component({
  selector: 'app-catalog-similar-product',
  templateUrl: './app-catalog-similar-product.component.html',
  styleUrls: ['./app-catalog-similar-product.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppCatalogSimilarProductComponent extends BaseComponentComponent {
  @Input() public catalogId: number;
  public similarProductList: Array<number> = new Array();
  public paginationModel: PaginationModel = new PaginationModel(false);
  public tableRequest: TableCatalogRequestModel = new TableCatalogRequestModel();
  constructor() {
    super('app-catalog-similar-product');
  }

  public onInit(): void {
    this.setCustomData();
    this.reload();
    this.setPagination();
  }

  public setCustomData(): void {
    this.tableRequest.customData = this.catalogId.toString();
  }

  public reload(): void {
    this.setStateProcessing();
    this.httpClientService.post('/view-catalog/show-similar-products', this.tableRequest).subscribe((response: TablePageModel<any>) => {
      this.similarProductList = response.records;
      this.paginationModel.totalRecords = response.totalRecords;
      this.setStateReady();
    });
  }

  public setPagination(): void {
    this.paginationModel.page = 1;
    this.paginationModel.perPage = 4;
  }

  public onPageChange(page: number): void {
    this.tableRequest.first = (page - 1) * this.paginationModel.perPage;
    this.reload();
  }

  public onChooseCatalogItem(catalogId: number): void {
    this.router.navigate([`/pages/view-catalog/detail/${catalogId}`]);
  }
}
