import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { RecapPrintService } from '../../core/services/recap-print.service';
import { SchedulerLog } from './../../core/bean/scheduler-log';
@Component({
  templateUrl: './scheduler-log.component.html'
})
export class SchedulerLogComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<SchedulerLog>;
  public isLoading: boolean;
  constructor(translateService: TranslateService, public recapPrintService: RecapPrintService) {
    super('scheduler-log', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.doBuildFormGroup();
    setTimeout(() => {
      this.setStateReady();
    }, 1000);
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'scheduler.name', header: 'table.column.activity', customClass: 'text-center' },
      { field: 'logDate', header: 'table.column.logdate', format: FieldFormatEnum.ShortDateAndTime },
      { field: 'logMsg', header: 'table.column.status', customClass: 'text-center' }
    ]);
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      logDateFrom: [null],
      logDateTo: [null]
    });
  }

  public doApply(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }

  public doExport(): void {
    this.isLoading = true;
    this.recapPrintService.print('/scheduler-log/recap-scheduler-log', this.tableResponse.request).subscribe(() => {
      this.isLoading = false;
    });
  }
}
