import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AppTableComponent } from 'src/app/core/components/table/components/app-table/app-table.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
    templateUrl: './app-popup-vendor-payment-request-choose-bill.component.html'
})
export class AppPopupVendorPaymentRequestChooseBillComponent extends BaseModuleComponent {
    @ViewChild(AppTableComponent) public table: AppTableComponent;
    @Input() public sowPaymentTermId: number;
    @Input() public paymentBilling: PaymentBilling;
    @Output() onChange: EventEmitter<PaymentBilling> =
        new EventEmitter();
    public tableResponse: TableResponseModel<PaymentBilling>;
    public procurementVendorId: number;
    public paymentBillingList: PaymentBilling[] = [];

    constructor(translateService: TranslateService) {
        super('vendor-payment-request', translateService);
    }

    onInit(): void {
        this.buildTableResponse();
        this.setStateReady();
    }

    public buildTableResponse(): void {
        this.tableResponse = new TableResponseModel(this.moduleCode, [
            {
                field: 'code',
                header: 'table.column.orderNumber'
            },
            {
                field: 'requiredDate',
                header: 'table.column.orderDate',
                format: FieldFormatEnum.ShortDate
            },
            {
                field: 'drType.name',
                header: 'table.column.orderType'
            },
            {
                field: 'amount',
                header: 'table.column.bills',
                format: FieldFormatEnum.Currency
            },
            {
                field: 'sowPaymentTerm.paymentTerm.name',
                header: 'table.column.termin'
            },
            {
                field: 'sowPaymentTerm.sow.stageOfWork.name',
                header: 'table.column.stage'
            }
        ]);
    }

    public doCheckProcurementSowPaymentTerm(
        paymentBilling: PaymentBilling
    ): void {
        if (paymentBilling.sowPaymentTerm !== null) {
            this.sowPaymentTermId =
                paymentBilling.sowPaymentTerm.id;
        }
        this.paymentBilling = paymentBilling;
    }

    public doSave(): void {
        if (this.sowPaymentTermId) {
            /*(if (this.paymentBilling.isProgressAddendum) {
                this.global.alertService.showInfo(
                    this.global.translateService.instant(
                        'vendor-payment-request.alert.isProgressAddendum'
                    ),
                    '.main-row-popup-choose-bill'
                );
            } else {
                this.onChange.emit(this.paymentRequestBillingView);
            }*/
            this.onChange.emit(this.paymentBilling);
        } else {
            this.global.alertService.showError(
                this.global.translateService.instant('app.validation.chooseOneData'),
                '.main-row-popup-choose-bill'
            );
        }
    }

    public doSort(sortField: string): void {
        this.tableResponse.setSortField(sortField);
        this.tableResponse.reload();
    }

    public onKeyUp(event: KeyboardEvent): void {
        event.preventDefault();
    }

    public setDataTable(): void {
        this.httpClientService
            .get<PaymentBilling>(
                '/vendor-payment-request/get-choose-bill-list'
            )
            .subscribe(paymentBillingList => {
                this.paymentBillingList = paymentBillingList;
                this.tableResponse.setRecords(this.paymentBillingList);
                this.tableResponse.setTotalRecords(this.paymentBillingList.length);
                this.tableResponse.reload();
                this.setStateReady();
            });
    }
}
