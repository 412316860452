<ng-container *ngIf="!ISVIEW; then notIsViewTmpl; else isViewTmpl">
</ng-container>
<ng-template #notIsViewTmpl>
  <div class="app-count" [formGroup]="formGroup">
    <div
      class="d-flex justify-content-between align-items-center font-weight-bold"
      [ngStyle]="{ width: '136px' }"
    >
      <app-button
        *ngIf="!formControl.disabled"
        color="PRIMARY"
        minWidth="30px"
        (onClick)="doDecrement()"
        [disabled]="disableButtonMinus"
      >
        <em class="fas fa-minus"></em>
      </app-button>
      &nbsp;
      <app-text-field
        size="XL"
        [formControlName]="formControlName"
        type="decimal"
        placeholder="0.00"
        [max]="max"
        [min]="min"
        isHideValid="true"
        (onChange)="onChangeTextField()"
        (onInput)="onInputTextField()"
      ></app-text-field>
      &nbsp;
      <app-button
        *ngIf="!formControl.disabled"
        color="PRIMARY"
        minWidth="30px"
        (onClick)="doIncrement()"
        [disabled]="disableButtonPlus"
      >
        <em class="fas fa-plus"></em>
      </app-button>
    </div>
    <small class="text-danger" *ngIf="isInvalid">
      {{ errors.message }}
    </small>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-template>
<ng-template #isViewTmpl>
  <div class="d-inline-block position-relative">
    <span>{{ (formControl.value | resolveNumberToDecimal) || '-' }}</span>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [symbolic]="symbolic"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
