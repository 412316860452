import { BaseEntity } from '../base/base-entity';
import { Catalog } from '../bean/catalog';
import { CatalogAttribute } from '../bean/catalog-attribute';
import { CatalogAttributeGroup } from '../bean/catalog-attribute-group';
export class CatalogSpecificationEntity extends BaseEntity {
  catalog: Catalog;
  catalogAttribute: CatalogAttribute;
  value: string;
  catalogAttributeGroup: CatalogAttributeGroup;
  name: string;
  isChoosen: boolean;
  isArray: boolean;
}
