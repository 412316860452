<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="response-rfi.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div formArrayName="answerList">
          <div *ngIf="answerList.length === 0">
            <app-fieldset>
              <p class="text-center"
                ><strong>{{ 'app.info.noData' | translate }}</strong></p
              >
            </app-fieldset>
          </div>

          <div
            *ngFor="
              let dataQuestionAnswer of answerList.controls;
              let i = index
            "
            [formGroup]="dataQuestionAnswer"
          >
            <app-fieldset>
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'response-rfi.question' | translate
                }}</label>
                <div class="col-sm-7 text-left">
                  <p>{{ dataQuestionAnswer.value.question }}</p>
                </div>
              </div>

              <div
                *ngIf="
                  dataQuestionAnswer.get('formObjectCode').value === 'COMBO-BOX'
                "
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'response-rfi.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p>{{ dataQuestionAnswer.value.answer.name }}</p>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  dataQuestionAnswer.get('formObjectCode').value ===
                    'CHECK-BOX' ||
                  dataQuestionAnswer.get('formObjectCode').value === 'CHOSEN'
                "
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'response-rfi.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <ng-container
                      *ngIf="isArray(dataQuestionAnswer.value.answer)"
                    >
                      <div
                        *ngFor="
                          let answer of dataQuestionAnswer.value.answer;
                          let i = index
                        "
                      >
                        <p>{{ answer.name }}, </p>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="!isArray(dataQuestionAnswer.value.answer)"
                    >
                      <p>{{ dataQuestionAnswer.value.answer }}</p>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  dataQuestionAnswer.get('formObjectCode').value ===
                  'DATE-PICKER'
                "
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'response-rfi.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p
                      >{{
                        dataQuestionAnswer.value.answer
                          | date: global.config.parameterModel.dateFormatShort
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  dataQuestionAnswer.get('formObjectCode').value ===
                  'TOGGLE-SWITCH'
                "
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'response-rfi.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p
                      >{{
                        (dataQuestionAnswer.value.answer === true
                          ? 'response-rfi.yes'
                          : 'response-rfi.no'
                        ) | translate
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  dataQuestionAnswer.get('formObjectCode').value ===
                  'AUTOCOMPLETE'
                "
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'response-rfi.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p>{{ dataQuestionAnswer.value.answer.name }} </p>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  dataQuestionAnswer.get('formObjectCode').value ===
                  'FIELD-PRICE'
                "
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'response-rfi.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p
                      >{{ dataQuestionAnswer.value.answer.currency.code }}
                      {{
                        dataQuestionAnswer.value.answer.price | number: '1.2-2'
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  dataQuestionAnswer.get('formObjectCode').value === 'WYSIWYG'
                "
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'response-rfi.answer' | translate
                  }}</label>
                  <div
                    class="col-7 text-left"
                    [innerHTML]="dataQuestionAnswer.value.answer"
                  >
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  dataQuestionAnswer.get('formObjectCode').value ===
                  'UPLOAD-SINGLE'
                "
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'response-rfi.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p>
                      <a
                        href="#"
                        (click)="
                          doDownload(
                            $event,
                            '/response-rfi/file/view/' +
                              dataQuestionAnswer.value.answer[0].file
                                .uploadedFileName +
                              '/' +
                              global.appConstant.fileType.DOC_RFI,
                            dataQuestionAnswer.value.answer[0].file.fileName
                          )
                        "
                      >
                        {{
                          dataQuestionAnswer.value.answer[0].file.fileName
                        }}</a
                      >
                    </p>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  dataQuestionAnswer.get('formObjectCode').value ===
                  'TEXT-FIELD-NUMERIC'
                "
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'response-rfi.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p>{{
                      dataQuestionAnswer.value.answer | number: '1.2-2'
                    }}</p>
                  </div>
                </div>
              </div>

              <!-- text field, text area, radio button -->
              <div
                *ngIf="
                  dataQuestionAnswer.get('formObjectCode').value ===
                    'TEXT-FIELD' ||
                  dataQuestionAnswer.get('formObjectCode').value ===
                    'TEXT-AREA' ||
                  dataQuestionAnswer.get('formObjectCode').value ===
                    'RADIO-BUTTON'
                "
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'response-rfi.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p>{{ dataQuestionAnswer.value.answer }}</p>
                  </div>
                </div>
              </div>
            </app-fieldset>
          </div>
        </div>

        <div class="button-group button-group-center mt-5">
          <app-button
            mode="BYPASS"
            color="SECONDARY"
            (onClick)="doPrevious()"
            [disabled]="formSaving"
            >{{ 'app.button.previous' | translate }}</app-button
          >
          <app-button
            mode="BYPASS"
            color="SECONDARY"
            (onClick)="doBack()"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}</app-button
          >
        </div>
      </form>
    </app-card>
  </div>
</div>
