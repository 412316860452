<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="scoring-template.setting" [isLoading]="formLoading"
            [isSaving]="formSaving">
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>

                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        "scoring-template.form.registrationWeight" | translate
                        }}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-field autofocus="true" size="MD"
                            type="decimal" maxLength="5"
                            formControlName="registrationWeight" symbolic="%">
                        </app-text-field>
                    </div>
                </div>

                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        "scoring-template.form.procurementWeight" | translate
                        }}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-field size="MD" type="decimal" maxLength="5"
                            formControlName="procurementWeight" symbolic="%">
                        </app-text-field>
                    </div>
                </div>

                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        "scoring-template.form.fulfillmentWeight" | translate
                        }}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-field size="MD" type="decimal" maxLength="5"
                            formControlName="fulfillmentWeight" symbolic="%">
                        </app-text-field>
                    </div>
                </div>

                <div class="row justify-content-center mt-5">
                    <app-button class="mr-1" color="SECONDARY"
                        (onClick)="doCancel()" [disabled]="formSaving">
                        {{ 'app.button.back' | translate }}
                    </app-button>
                    <app-button color="PRIMARY" (onClick)="doSave()">
                        {{ 'app.button.save' | translate }}
                    </app-button>
                </div>
            </form>
        </app-card>
    </div>
</div>