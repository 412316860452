<app-popup header="app-popup-toc.title" (onClose)="onClosePopup()">
  <form [formGroup]="formGroup">
    <div class="row main-row">
      <div class="col-12">
        <div class="app-popup-toc" [innerHTML]="termsAndCondition"></div>
      </div>
    </div>
    <br />
    <app-check-box formControlName="check" position="HORIZONTAL">{{
      'app-popup-toc.confirmation' | translate
    }}</app-check-box>
  </form>
  <div class="button-group button-group-center mt-5">
    <button
      type="button"
      class="btn btn-primary"
      (click)="doSave()"
      [disabled]="formSaving"
    >
      {{ 'app.button.save' | translate }}
    </button>
  </div>
</app-popup>
