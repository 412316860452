import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AppTableComponent } from 'src/app/core/components/table/components/app-table/app-table.component';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { WorkflowPrcs } from 'src/app/core/bean/workflow-prcs';
import { Workflow } from 'src/app/core/bean/workflow';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { SlaCoreResponse } from './sla-core-response';
import { SlaCoreRequest } from './sla-core-request';

@Component({
    templateUrl: './sla-core.component.html',
    styleUrls: ['./sla-core.component.scss']
})
export class SlaCoreComponent
    extends BaseModuleComponent
    implements OnInit {
    @ViewChild(AppTableComponent) public table: AppTableComponent;
    public tableResponseItem: TableResponseModel<WorkflowPrcs>;
    public workflowList: OptionListModel<Workflow> = new OptionListModel(true);
    public workflowPrcs: WorkflowPrcs[] = [];
    public workflowBeanList: Workflow[] = [];
    public workflowNameList: Workflow[] = [];
    public getWorkflowName: Workflow;
    public setWorkflowName: Workflow;
    public tableResponse: TableResponseModel<WorkflowPrcs>;
    public historyType = 'item';
    public roleCode: string;
    public SlaCoreResponse: SlaCoreResponse = new SlaCoreResponse();
    public SlaCoreRequest: SlaCoreRequest = new SlaCoreRequest();
    constructor(translateService: TranslateService) {
        super('sla-core', translateService);
    }

    public onInit(): void {
        this.setTabList();
        this.buildFormGroup();
        this.buildTableResponse();
        this.setStateReady();
    }

    public setTabList(): void {
        this.roleCode = this.global.userSession.activeUserRole.role.code;

        if (this.roleCode === this.global.appConstant.ROLE_CODE_USER) {
            this.historyType = 'item';
        }
    }

    public setWorkflowNameList(): void {
        const moduleGroupCode = 'CORE';

        this.httpClientService
            .get<Workflow[]>('/workflow/workflow-list-history-type/' + moduleGroupCode + '/' + this.historyType)
            .subscribe((workflowList: Workflow[]) => {
                this.workflowList.setRequestValues(workflowList);
            });
    }

    public buildFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            date: [null],
            fromDate: [null],
            untilDate: [null],
            workflowName: [null],
            historyType: [this.historyType],
            getWorkflowName: [null]
        });
    }

    public addUomDay(slaInDays: number): string {
        if (slaInDays !== 0 && slaInDays !== null) {
            return slaInDays + ' ' + this.translateService.instant('sla-vendor.day');
        } else {
            return '-';
        }
    }

    public buildTableResponse(): void {
        this.setWorkflowNameList();
        this.formGroup.get('workflowName').reset();
        if (this.historyType === 'item') {
            this.tableResponseItem = new TableResponseModel(this.moduleCode, [
                {
                    field: 'codeItem',
                    header: 'table.column.code',
                    plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
                },
                {
                    field: 'dateItem',
                    header: 'table.column.date',
                    plugins: 'date'
                },
                {
                    field: 'itemName',
                    header: 'table.column.title'
                },
                {
                    field: 'name',
                    header: 'table.column.workflowName',
                    className: 'white-space-normal'
                },
                {
                    field: 'totalSlaInDays',
                    header: 'table.column.totalSla',
                    plugins: {
                        name: 'custom-plugin',
                        before: (tablePluginData: TablePluginData): string => {
                            if (tablePluginData.value !== 0 && tablePluginData.value !== null) {
                                return tablePluginData.value + ' ' + this.translateService.instant('sla-vendor.day');
                            } else {
                                return '-';
                            }
                        }
                    }
                },
                {
                    field: 'totalSlaRealizedName',
                    header: 'table.column.totalRealization'
                }
            ]);
            this.tableResponseItem.setCustomData(this.formGroup.value);
        }
    }

    // tslint:disable-next-line:typedef
    public doClickHistoryType(event) {
        this.historyType = event;
        this.formGroup.patchValue({
            historyType: this.historyType
        });
        this.buildTableResponse();
        this.tableResponseItem?.setCustomData(this.formGroup.value);
        this.tableResponseItem?.reload();
    }

    public onChangeFilter(): void {
        if (this.formGroup.value.date) {
            this.formGroup.patchValue({
                fromDate: this.formGroup.value.date.from,
                untilDate: this.formGroup.value.date.to
            });
        }
        if (this.formGroup.value.workflowName != null) {
            this.workflowBeanList = this.formGroup.value.workflowName;
            this.workflowBeanList.forEach(value => {
                value.slaUpdatedDate = new Date(value.slaUpdatedDate);
            });
        }
        this.tableResponseItem?.setCustomData(this.formGroup.value);
        this.tableResponseItem?.reload();
        this.formGroup.get('fromDate').reset();
        this.formGroup.get('untilDate').reset();
    }

    public doClick(workflowPrcs: WorkflowPrcs): void {
        this.global.routerParams.clear();
        this.global.routerParams.set(
            'workflowPrcsId',
            workflowPrcs.id
        );
        this.global.routerParams.set(
            'historyType',
            this.historyType
        );
        this.router.navigate([
            '/pages/sla-core/detail'
        ]);
    }
}
