import { BillingMemo } from 'src/app/core/bean/billing-memo';
import { BillingMemoBudget } from 'src/app/core/bean/billing-memo-budget';
import { BillingMemoOtherCost } from 'src/app/core/bean/billing-memo-other-cost';
import { BillingMemoTax } from 'src/app/core/bean/billing-memo-tax';
import { VendorBank } from 'src/app/core/bean/vendor-bank';
import { VendorBankOffline } from 'src/app/core/bean/vendor-bank-offline';
import { SignerHCSView } from 'src/app/core/view/entity/bean/signer-hcs-view';

export class BillingMemoUpdateRequest {
    public billingMemo: BillingMemo;
    public billingMemoBudgetList: BillingMemoBudget[] = [];
    public billingMemoTaxList: BillingMemoTax[] = [];
    public billingMemoOtherCostList: BillingMemoOtherCost[] = [];
    public vendorBank: VendorBank;
    public vendorBankOffline: VendorBankOffline;
    public firstSigner: SignerHCSView;
    public secondSigner: SignerHCSView;
    public paymentId: number;
    public isSave: boolean;
}
