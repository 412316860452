import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { PaymentBillingDoc } from '../../bean/payment-billing-doc';
import { PaymentInvoice } from '../../bean/payment-invoice';
import { PaymentRequestView } from '../../view/entity/bean/payment-request-view';
import { FieldFormatEnum } from '../app-table/model/field-format.enum';
import { TableResponseModel } from '../app-table/model/table-response-model';

@Component({
  selector: 'app-payment-request-information-view',
  templateUrl: './app-payment-request-information-view.component.html'
})
export class AppPaymentRequestInformationViewComponent extends BaseComponentComponent {
  @Input() public paymentInvoiceId: number;
  @Input() public paymentRequestView: PaymentRequestView;
  public tableResponse: TableResponseModel<PaymentBillingDoc>;
  public paymentInvoice: PaymentInvoice;

  constructor() {
    super('app-payment-request-information-view');
  }

  public onInit(): void {
    this.setFormGroup();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementSowPaymentTermBilling.billingTerm.name',
        header: 'table.column.billingTerm',
        customClass: 'text-left'
      },
      {
        field: 'number',
        header: 'table.column.documentNumber',
        customClass: 'text-center'
      },
      {
        field: 'date',
        header: 'table.column.documentDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'file.fileName',
        header: 'table.column.uploadFile',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName'
      }
    ]);
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<PaymentInvoice>(
        '/app-payment-request-information-view/index/' + this.paymentInvoiceId
      )
      .subscribe(paymentInvoice => {
        this.paymentInvoice = paymentInvoice;
        this.setStateReady();
        this.buildTableResponse();
        this.setCustomData();
      });
  }

  public setCustomData(): void {
    this.tableResponse.setCustomData(this.paymentInvoice.payment.id);
  }
}
