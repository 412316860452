<app-table-xx
  [model]="tableResponse"
  (onClick)="doClick($event)"
  header="{{ 'app-sow.table.header' | translate }}"
>
  <ng-template #headerButtons>
    <app-button
      *ngIf="!isView && !(isBlanketOrder && sowLists?.value?.length > 0)"
      [disabled]="formSaving || isDisabled || totalPercentage === 100"
      (onClick)="doAdd()"
      ><em class="pir pi-plus-circle"></em>
      {{ 'app-sow.button.addStage' | translate }}</app-button
    >
  </ng-template>
  <ng-template #alertWrapper *ngIf="!isView">
    <app-fullfilled></app-fullfilled>
  </ng-template>

  <ng-container *ngIf="!isView && !isBlanketOrder">
    <ng-template #actionButtons let-rowData>
      <div class="ml-2">
        <app-button
          size="TN"
          [disabled]="formSaving"
          (onClick)="doDelete(rowData)"
          title="{{ 'app.tooltip.delete' | translate }}"
        >
          <em class="pir pi-trash-alt"></em>
          {{ 'app.tooltip.delete' | translate }}
        </app-button>
      </div>
    </ng-template>
  </ng-container>
</app-table-xx>
