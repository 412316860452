<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="account-setting.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        AUTOCOMPLETE="OFF"
      >
        <div class="form-group row text-sm-right pb-4">
          <label class="col-sm-3 control-label"></label>
          <div class="col-sm-9 text-left">
            <div [ngStyle]="{ width: '210px' }">
              <app-user-profile-card
                *ngIf="!isPhotoChanged && isEdit"
                formControlName="imgFile"
                (onChange)="doDisableButton($event)"
                [showChangeButton]="true"
              >
              </app-user-profile-card>
              <app-user-profile-card
                *ngIf="!isPhotoChanged && !isEdit"
                formControlName="imgFile"
                [showChangeButton]="false"
              >
              </app-user-profile-card>
              <div
                class="text-center"
                [ngStyle]="{ margin: '10vh 0' }"
                *ngIf="isPhotoChanged"
              >
                <img src="assets/img/common/loader-small.gif" alt="Loading" />
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!isEdit" class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'account-setting.userName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div>{{ formGroup.controls.name.value }}</div>
          </div>
        </div>

        <div *ngIf="!isEdit" class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'account-setting.form.email' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ formGroup.controls.email.value }}
            <div
              *ngIf="accountSettingResponse?.user?.proposeType === 'EMAIL'"
              class="alert alert-info col-md-6 col-sm-11 alert-dismissible fade show mt-2 small-alert"
              role="alert"
            >
              {{ 'account-setting.form.alertConfirmEmail' | translate }}
              <strong>{{ accountSettingResponse.user.proposeValue }}</strong>
            </div>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          *ngIf="
            !isEdit &&
            accountSettingResponse?.user?.validUserRoleList.length > 1
          "
        >
          <label class="col-sm-3 control-label">{{
            'account-setting.role' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div>{{ roleDefault.role.name }}</div>
          </div>
        </div>

        <div *ngIf="!isEdit" class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'account-setting.lang' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div>{{ accountSettingResponse?.user?.lang?.name }}</div>
          </div>
        </div>

        <div
          *ngIf="
            !isEdit &&
            global.userSession?.activeUserRole.role.code !==
              global.appConstant.ROLE_CODE_VENDOR
          "
          class="form-group row text-sm-right"
        >
          <label class="col-sm-3 control-label">{{
            'account-setting.notifEnable' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div>{{
              accountSettingResponse?.userPreferenceNotif
                ? accountSettingResponse?.userPreferenceNotif.value == 'TRUE'
                  ? 'Non Aktif'
                  : 'Aktif'
                : 'Aktif'
            }}</div>
          </div>
        </div>

        <div *ngIf="isEdit" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'account-setting.userName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              formControlName="name"
              type="alphanumeric"
              include="-.\',"
              maxLength="255"
            >
            </app-text-field>
          </div>
        </div>

        <div
          *ngIf="isEdit"
          [hidden]="isPasswordBlock"
          class="form-group row text-sm-right"
        >
          <label *ngIf="!isPasswordBlock" class="col-sm-3 control-label">{{
            'account-setting.password' | translate
          }}</label>
          <div
            [ngClass]="isPasswordBlock ? 'offset-sm-3' : ''"
            class="col-sm-9 text-left btn-change-password"
          >
            <div style="display: inline-block; margin-right: 10px">
              **********
            </div>
            <app-button size="SM" (onClick)="doChangePassword()">
              {{ 'app.button.edit' | translate }}
            </app-button>
          </div>
        </div>

        <div id="password-block" [hidden]="!isPasswordBlock">
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'account-setting.form.oldPassword' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="oldPassword"
                type="password"
                maxLength="32"
              ></app-text-field>
            </div>
            <div class="col-sm-2"></div>
          </div>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'account-setting.form.newPassword' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="newPassword"
                type="password"
                maxLength="32"
              ></app-text-field>
            </div>
            <div class="col-sm-1"></div>
          </div>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'account-setting.form.newPasswordConfirm' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="confirmNewPassword"
                type="password"
                maxLength="32"
              ></app-text-field>
            </div>
          </div>
        </div>

        <div *ngIf="isEdit" class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'account-setting.form.email' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div style="display: inline-block; margin-right: 10px">
              {{ formGroup.controls.email.value }}
            </div>
            <app-button
              [hidden]="isEmailBlock"
              size="SM"
              (onClick)="doChangeEmail()"
              >{{ 'app.button.edit' | translate }}
            </app-button>
            <div
              *ngIf="accountSettingResponse?.user?.proposeType === 'EMAIL'"
              class="alert alert-info col-md-6 col-sm-11 alert-dismissible fade show mt-2 small-alert"
              role="alert"
            >
              {{ 'account-setting.form.alertConfirmEmail' | translate }}
              <strong>{{ accountSettingResponse.user.proposeValue }}</strong>
            </div>
          </div>
        </div>

        <div [hidden]="!isEmailBlock">
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'account-setting.form.newEmail' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                autofocus="true"
                formControlName="newEmail"
                maxLength="64"
              ></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'account-setting.form.newEmailConfirm' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="confirmEmail" maxLength="64">
              </app-text-field>
            </div>
          </div>
        </div>

        <div
          class="form-group row text-sm-right required"
          *ngIf="
            isEdit && accountSettingResponse?.user?.validUserRoleList.length > 1
          "
        >
          <label class="col-sm-3 control-label">{{
            'account-setting.role' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              formControlName="userRole"
              [optionList]="roleOptionList"
            ></app-combo-box>
          </div>
        </div>

        <!-- Request BJB view only untuk default role -->
        <div
          class="form-group row text-sm-right"
          *ngIf="accountSettingResponse?.user?.validUserRoleList.length === 1"
        >
          <label class="col-sm-3 control-label">{{
            'account-setting.role' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div>{{ global.userSession?.activeUserRole.role.name }}</div>
          </div>
        </div>

        <div *ngIf="isEdit" class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'account-setting.lang' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              formControlName="lang"
              [optionList]="languageOptionList"
            ></app-combo-box>
          </div>
        </div>

        <div
          *ngIf="
            isEdit &&
            global.userSession.activeUserRole.role.code !==
              global.appConstant.ROLE_CODE_VENDOR
          "
          class="form-group row text-sm-right"
        >
          <label class="col-sm-3 control-label">{{
            'account-setting.notifEnable' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <app-toggle-switch
              formControlName="isNotifEnable"
            ></app-toggle-switch>
          </div>
        </div>

        <div class="button-group button-group-center mt-5">
          <app-button
            *ngIf="isEdit"
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving || isDisable"
          >
            {{ 'app.button.cancel' | translate }}</app-button
          >
          <app-button
            *ngIf="isEdit"
            (onClick)="doSave()"
            [disabled]="formSaving || isDisable"
            >{{ 'app.button.save' | translate }}
          </app-button>
          <app-button
            *ngIf="!isEdit"
            (onClick)="doEdit()"
            [disabled]="formSaving || isDisable"
            >{{ 'app.button.edit' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>

    <app-card
      *ngIf="
        global.userSession && global.userSession?.activeUserRole.role.type != 3
      "
      header="user-role-log.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <div class="row">
        <div class="col-lg-12 mb-4">
          <app-table
            [tableResponse]="tableResponse"
            [isServerSide]="true"
            stringUrl="/account-setting/user-role-log"
          >
          </app-table>
        </div>
      </div>
    </app-card>
  </div>
</div>
