import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Bank } from '../../../bean/bank';
import { AppPopupService } from '../app-popup.service';
import { AppPopupBankComponent } from './app-popup-bank.component';
@Injectable()
export class AppPopupBankService {
  constructor(public appPopupService: AppPopupService) {}
  public open(bank?: Bank): Observable<any> {
    return this.appPopupService.open(
      AppPopupBankComponent,
      { bank },
      { centered: false }
    );
  }
}
