/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'app-modal-confirm-dialog',
  templateUrl: './app-modal-confirm-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppModalConfirmDialogComponent implements OnInit {
  title: string;
  prompt: string;
  icon: string;
  buttonLeft: string;
  buttonRight: string;
  isShowCloseButton: boolean;
  isSingleButton: boolean;
  @Input() public isAutoClose: boolean;

  @Output() public onClose: EventEmitter<void> = new EventEmitter();

  ngOnInit(): void {
    if (this.isAutoClose) {
      setTimeout(() => {
        this.onClose.emit();
      }, 2500);
    }
  }
  public doClosePopup(): void {
    this.onClose.emit();
  }
}
