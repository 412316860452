<div class="app-rating-product" [formGroup]="formGroup">
  <h5>Rating Product</h5>
  <div class="row my-4">
    <div class="col-2 text-center">
      <h1>4.78</h1>
    </div>
    <div class="col-10 rating">
      <div class="d-inline-block">
        <app-rating formControlName="rating" [isViewOnly]="true"></app-rating>
        <h5 class="text-center">( 100 Ulasan )</h5>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <app-rating-detail [ratings]="model.ratings"></app-rating-detail>
    </div>
    <div class="col-4 text-center">
      <strong>Transaction</strong>
      <h5>300</h5>
    </div>
    <div class="col-4 text-center">
      <strong>Product</strong>
      <h5>1234</h5>
    </div>
  </div>
</div>
