import { Aanwijzing } from 'src/app/core/bean/aanwijzing';
import { AanwijzingCancellation } from 'src/app/core/bean/aanwijzing-cancellation';
import { AanwijzingChat } from 'src/app/core/bean/aanwijzing-chat';
import { AanwijzingParticipant } from 'src/app/core/bean/aanwijzing-participant';
import { AanwijzingSummary } from 'src/app/core/bean/aanwijzing-summary';
import { AanwijzingSummaryDoc } from 'src/app/core/bean/aanwijzing-summary-doc';
import { Region } from 'src/app/core/bean/region';

export class AanwijzingOthersResponse {

    regionList: Region[] = [];
    aanwijzing: Aanwijzing;
    aanwijzingParticipant: AanwijzingParticipant; /** itself */
    existAanwijzingParticipantList: AanwijzingParticipant[] = [];

    aanwijzingSummary: AanwijzingSummary;
    aanwijzingSummaryDocList: AanwijzingSummaryDoc[] = [];

    aanwijzingChatList: AanwijzingChat[] = [];
    isSessionStart: boolean;
    isSessionExpired: boolean;
    aanwijzingCancellation: AanwijzingCancellation;
}
