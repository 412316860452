import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupShareHoldersComponent } from './app-popup-share-holders.component';
import { TabCompanyOrganizationComponent } from './tab-company-organization.component';
@NgModule({
  imports: [SharedModule],
  declarations: [
    TabCompanyOrganizationComponent,
    AppPopupShareHoldersComponent
  ],
  entryComponents: [
    TabCompanyOrganizationComponent,
    AppPopupShareHoldersComponent
  ],
  exports: [TabCompanyOrganizationComponent, AppPopupShareHoldersComponent]
})
export class TabCompanyOrganizationModule {
  constructor(accordionService: AccordionService) {
    accordionService.register(
      'tab-company-organization',
      TabCompanyOrganizationComponent
    );
  }
}
