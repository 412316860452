import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';

@Component({
  selector: 'app-lazy-load',
  templateUrl: './app-lazy-load.component.html',
  styleUrls: ['./app-lazy-load.component.scss']
})
export class AppLazyLoadComponent extends BaseComponentComponent {
  @Input() isLoading: boolean;
  @Input() target: string | 'body';
  @Input() maxHeight: string;
  @Input() loadingText: string;

  @Output() onScrollEnd: EventEmitter<boolean> = new EventEmitter();
  constructor(private elementRef: ElementRef) {
    super('app-lazy-load');
  }

  onInit(): void {
    this.setInitializationListenerState();
  }

  private setInitializationListenerState(): void {
    if (this.target === 'body') {
      document.addEventListener('scroll', () => {
        this.emitOnScrollEnd(
          document.documentElement.scrollHeight,
          document.documentElement.scrollTop +
            document.documentElement.clientHeight
        );
      });
    } else if (this.target && this.target !== 'body') {
      const elementList = document.querySelectorAll(this.target);
      if (elementList) {
        elementList.forEach(element => {
          this.handleScrollEnd(element as HTMLElement);
        });
      }
    } else {
      const appLazyLoadElement: HTMLElement =
        this.elementRef.nativeElement.children.item(0);
      this.handleScrollEnd(appLazyLoadElement);
    }
  }

  private handleScrollEnd(element: HTMLElement): void {
    element.style.maxHeight = this.maxHeight || '200px';
    element.addEventListener(
      'scroll',
      (event: Event & { target: HTMLElement }) => {
        this.emitOnScrollEnd(
          event.target.scrollHeight,
          event.target.scrollTop + event.target.clientHeight
        );
      }
    );
  }

  private emitOnScrollEnd(scrollHeight: number, scrollPosition: number): void {
    if (!this.isLoading) {
      if (Math.abs(scrollHeight - scrollPosition) < 1) {
        this.onScrollEnd.emit(true);
      }
    }
  }
}
