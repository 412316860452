import { EmployeeDataComponent } from './employee-data.component';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { WebSocketService } from 'src/app/core/services/websocket/websocket.service';
import { WebSocketResponseModel } from './../../core/model/websocket-response-model';
import { AppPopupSyncEmployeeDataComponent } from './app-popup-sync-employee-data.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class WebSocketEmployeeDataService extends WebSocketService<string> {
    public router: Router;
    public appPopupSyncEmployeeDataComponent: AppPopupSyncEmployeeDataComponent;
    public onCloseModal: EventEmitter<boolean> = new EventEmitter();
    public message: EventEmitter<string> = new EventEmitter();
    public employeeData: EmployeeDataComponent;

    constructor(public appPopupService: AppPopupService, public translateService: TranslateService) {
        super('synchronize/employee-data');
    }

    public onReceivedMessage(message: string): Promise<any> {
        return new Promise(resolve => {
            const webSocketResponseModel: WebSocketResponseModel =
                JSON.parse(message);
            if (this.appPopupSyncEmployeeDataComponent) {
                if (
                    webSocketResponseModel &&
                    webSocketResponseModel.status !==
                    this.global.appConstant.core.WEBSOCKET_STATUS_ON_PROGRESS
                ) {
                    const result =
                        webSocketResponseModel.status ===
                            this.global.appConstant.core.WEBSOCKET_STATUS_FINISH
                            ? true
                            : false;
                    setTimeout(() => {
                        this.appPopupSyncEmployeeDataComponent.isLoadingSync = false;
                        this.appPopupSyncEmployeeDataComponent = null;
                        this.onCloseModal.emit(true);
                        if (result) {
                            this.global.alertService.showSuccess(
                                this.translateService.instant(
                                    'employee-data.msg.info.successSynchronize'
                                )
                            );
                        }
                        resolve(result);
                    }, 500);
                } else {
                    this.appPopupSyncEmployeeDataComponent.isLoadingSync = true;
                    this.appPopupSyncEmployeeDataComponent.webSocketResponseModel =
                        webSocketResponseModel;
                }
            } else {
                let bodyMessage;
                if (
                    webSocketResponseModel &&
                    webSocketResponseModel.status ===
                    this.global.appConstant.core.WEBSOCKET_STATUS_ON_PROGRESS
                ) {
                    bodyMessage =
                        webSocketResponseModel.progressValue +
                        ' ' +
                        webSocketResponseModel.progressUnit;
                    this.message.emit(bodyMessage);
                } else {
                    const result =
                        webSocketResponseModel.status ===
                            this.global.appConstant.core.WEBSOCKET_STATUS_FINISH
                            ? true
                            : false;
                    setTimeout(() => {
                        this.message.emit(null);
                        resolve(result);
                    }, 500);
                }
            }
        });
    }
}
