<ng-container
  *ngIf="!ISVIEW; else elseIsViewTmpl"
  [formGroup]="generateFormGroup"
>
  <div
    class="app-phone form-control p-0 border-0"
    [ngClass]="[
      elementWidthClassName,
      elementHeightClassName,
      (isInvalid && !generateFormGroup.get('country').valid) ||
      (formControl.invalid &&
        generateFormGroup.touched &&
        generateFormGroup.invalid)
        ? 'is-invalid'
        : '',
      generateFormGroup.get('country').valid ? 'is-valid' : ''
    ]"
  >
    <ng-container>
      <app-combo-box
        size="XL"
        [optionList]="optionList"
        formControlName="country"
        (onChange)="handleChange()"
      >
      </app-combo-box>
    </ng-container>
    &nbsp;
    <input
      class="form-control"
      type="text"
      (input)="handleChange()"
      formControlName="number"
      [attr.placeholder]="placeholder ? placeholder : ''"
      [ngClass]="[
        (isInvalid ||
          (formControl.invalid && generateFormGroup.get('number').touched)) &&
        generateFormGroup.get('number').invalid
          ? 'is-invalid'
          : '',
        generateFormGroup.get('number').touched &&
        generateFormGroup.get('number').valid
          ? 'is-valid'
          : ''
      ]"
      maxlength="{{ maxLength }}"
      [attr.aria-label]="formControlName"
      appInteger
    />
    <ng-container
      *ngTemplateOutlet="appTooltipWrapperTemplateOutlet"
    ></ng-container>
  </div>
  <small
    class="text-danger"
    *ngIf="
      (isInvalid && generateFormGroup.invalid) ||
      (generateFormGroup.touched && generateFormGroup.invalid)
    "
  >
    {{ errors.message }}
  </small>
</ng-container>
<ng-template #elseIsViewTmpl>
  <div class="position-relative d-inline-block">
    <span>{{ numberValue || '-' }}</span>
    <ng-container
      *ngTemplateOutlet="appTooltipWrapperTemplateOutlet"
    ></ng-container>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
