import { AppConstant } from '../../../constant/app-constant';
import { resolveTableRecords } from '../helpers/resolve-table-records.helper';
import { TableFieldModel } from '../model/table-field-model';
import { TableState } from '../model/table-state';

export const sortTable = (
  state: TableState,
  payload: { column: TableFieldModel }
) => {
  const { tableRequest } = state;
  const appConstant = new AppConstant();
  if (
    payload.column.field === tableRequest.sortField &&
    state.sortOrder === 'DESC'
  ) {
    tableRequest.sortField = null;
    state.sortOrder = '';
  } else {
    state.sortOrder =
      state.sortOrder === 'ASC'
        ? state.tableRequest.sortField === payload.column.field
          ? 'DESC'
          : 'ASC'
        : 'ASC';
    tableRequest.sortField = payload.column.field;
    state.tableRequest.sortOrder =
      state.sortOrder === 'DESC'
        ? appConstant.core.SORT_ORDER_DESC
        : appConstant.core.SORT_ORDER_ASC;
  }
  resolveTableRecords(state).then((records: Array<any>) => {
    state.setRecords(records);
    state.setStateReady();
  });
};
