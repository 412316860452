import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Validators } from 'src/app/core/validators';
import { AanwijzingParticipant } from '../../core/bean/aanwijzing-participant';
import { ParticipantRole } from '../../core/bean/participant-role';
import { Region } from '../../core/bean/region';
import { Vendor } from '../../core/bean/vendor';
import { SignatureModel } from '../../core/components/app-signature/signature.model';
import { OptionListModel } from '../../core/model/option-list-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { AttendanceFormRequest } from './attendance-form-request';
import { AttendanceFormResponse } from './attendance-form-response';

@Component({
  templateUrl: './attendance-form.component.html',
  styleUrls: ['./attendance-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttendanceFormComponent
  extends BaseModuleComponent
  implements OnInit
{
  public encryptAanwijzingId: string;
  public attendanceFormResponse: AttendanceFormResponse;
  public participantRoleOptionList: OptionListModel<ParticipantRole> =
    new OptionListModel(false);
  public vendorOptionList: OptionListModel<Vendor> = new OptionListModel(false);
  public regionOptionList: OptionListModel<Region> = new OptionListModel(false);
  public model: SignatureModel = new SignatureModel();
  public isFormPage = false;
  public isShowList = false;
  public isBlank: boolean;
  constructor(
    translateService: TranslateService,
    public activatedRoute: ActivatedRoute,
    public datePipe: DatePipe
  ) {
    super('attendance-form', translateService);
  }

  public onInit(): void {
    this.getRouterParam();
    this.buildFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      participant: [null, Validators.required()],
      name: [null, Validators.required()],
      email: [
        null,
        Validators.compose([Validators.required(), Validators.email()])
      ],
      companyName: [null, Validators.required()],
      positionName: [null, Validators.required()],
      departmentName: [null, Validators.required()],
      phone: [null, Validators.required()],
      signatureImg: [null, Validators.required()],
      isCheck: [null, Validators.required()]
    });
  }

  public getRouterParam(): void {
    this.activatedRoute.paramMap.subscribe(param => {
      this.encryptAanwijzingId = param.get('id');
    });

    this.httpClientService
      .get<any>('/attendance-form/add/' + this.encryptAanwijzingId, {})
      .subscribe((response: AttendanceFormResponse) => {
        this.attendanceFormResponse = response;
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        const periodEndDate = new Date(
          this.attendanceFormResponse.aanwijzing.periodEndDate
        );
        periodEndDate.setHours(0, 0, 0, 0);
        if (
          periodEndDate.getTime() < date.getTime() ||
          (this.attendanceFormResponse.aanwijzing.aanwijzingStatus.code !==
            this.global.appConstant.pm.AANWIJZING_STATUS_ON_PROGRESS &&
            this.attendanceFormResponse.aanwijzing.aanwijzingStatus.code !==
              this.global.appConstant.pm.AANWIJZING_STATUS_NOT_STARTED)
        ) {
          this.global.routerParams.clear();
          this.global.routerParams.set(
            'aanwijzingId',
            this.attendanceFormResponse.aanwijzing.id
          );
          this.global.routerParams.set('icon', 'pvc pv-connection-triangle');
          this.global.routerParams.set('isSuccessSubmit', false);
          this.global.routerParams.set(
            'message',
            this.translateService.instant(
              'attendance-form.information.message.expiredSession'
            )
          );
          this.router.navigate([
            '/portal/aanwijzing-attendance-form/information'
          ]);
        } else {
          this.participantRoleOptionList
            .setRequestValues(this.attendanceFormResponse.participantRoleList)
            .setValuePath('code');
          this.vendorOptionList.setRequestValues(
            this.attendanceFormResponse.vendorList
          );
          this.regionOptionList.setRequestValues(
            this.attendanceFormResponse.regionList
          );
        }
        this.setStateReady();
      });
  }

  public doSubmit(): void {
    this.setStateProcessing();
    this.model.requestUpload().subscribe(() => {
      this.setStateReady();
      this.validate();
      if (this.formGroup.valid) {
        this.global.modalService
          .confirm(
            'attendance-form.popup.confirm.regist.message',
            null,
            'app.button.close',
            'attendance-form.confirm.button.regist'
          )
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              const attendanceFormRequest: AttendanceFormRequest =
                new AttendanceFormRequest();
              const aanwijzingParticipant: AanwijzingParticipant =
                new AanwijzingParticipant();
              aanwijzingParticipant.aanwijzing =
                this.attendanceFormResponse.aanwijzing;
              aanwijzingParticipant.participantRole =
                this.participantRoleOptionList
                  .getRequestValues()
                  .filter(
                    participantRole =>
                      participantRole.code ===
                      this.formGroup.get('participant').value
                  )[0];
              aanwijzingParticipant.phoneRegion =
                this.formGroup.get('phone').value.country;
              aanwijzingParticipant.phone =
                this.formGroup.get('phone').value.number;
              aanwijzingParticipant.name = this.formGroup.get('name').value;
              aanwijzingParticipant.email = this.formGroup.get('email').value;
              aanwijzingParticipant.positionName =
                this.formGroup.get('positionName').value;
              if (
                this.formGroup.get('participant').value !==
                this.global.appConstant.pm.PARTICIPANT_ROLE_VENDOR
              ) {
                aanwijzingParticipant.companyName =
                  this.formGroup.get('companyName').value;
              } else {
                aanwijzingParticipant.companyName =
                  this.formGroup.get('companyName').value.name;
              }

              aanwijzingParticipant.departmentName =
                this.formGroup.get('departmentName').value;
              attendanceFormRequest.aanwijzingParticipant =
                aanwijzingParticipant;
              attendanceFormRequest.fileList =
                this.formGroup.get('signatureImg').value;
              this.httpClientService
                .post<any>('/attendance-form/insert/', attendanceFormRequest)
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.global.routerParams.clear();
                    this.global.routerParams.set(
                      'aanwijzingId',
                      this.attendanceFormResponse.aanwijzing.id
                    );
                    this.global.routerParams.set('icon', 'pvc pv-check-circle');
                    this.global.routerParams.set('isSuccessSubmit', true);
                    this.global.routerParams.set(
                      'message',
                      this.translateService.instant(
                        'attendance-form.information.message.successSubmit'
                      )
                    );
                    this.router.navigate([
                      '/portal/aanwijzing-attendance-form/information'
                    ]);
                    this.setStateReady();
                  } else {
                    let messageError = '';
                    if (response.statusText === 'Email Exist') {
                      messageError = 'attendance-form.popup.emailExist.message';
                    } else {
                      messageError =
                        'attendance-form.popup.emailNotRegistered.message';
                    }
                    this.setStateReady();
                    this.global.modalService
                      .confirm(
                        messageError,
                        null,
                        'app.button.close',
                        'attendance-form.button.showList'
                      )
                      .subscribe(result => {
                        if (result) {
                          this.global.routerParams.clear();
                          this.global.routerParams.set(
                            'aanwijzingId',
                            this.attendanceFormResponse.aanwijzing.id
                          );
                          this.router.navigate([
                            '/portal/aanwijzing-attendance-form/participant-list'
                          ]);
                        }
                      });
                  }
                });
            }
          });
      }
    });
  }

  public doNext(): void {
    if (
      this.formGroup.get('participant').value ===
      this.global.appConstant.pm.PARTICIPANT_ROLE_INTERNAL
    ) {
      this.formGroup
        .get('companyName')
        .patchValue(this.attendanceFormResponse.aanwijzing.organization.name);
      this.formGroup.get('companyName').setIsView(true);
      this.formGroup.get('positionName').clearValidators();
      this.formGroup.get('positionName').updateValueAndValidity();
    } else {
      this.formGroup.get('departmentName').clearValidators();
      this.formGroup.get('departmentName').updateValueAndValidity();
    }
    this.formGroup.get('phone').patchValue({
      country: this.regionOptionList
        .getRequestValues()
        .filter(region => region.code === 'ID')[0],
      number: null
    });
    this.isFormPage = true;
  }

  public doBack(): void {
    this.formGroup.get('companyName').patchValue(null);
    this.formGroup.get('companyName').setIsView(false);
    this.formGroup.get('positionName').setValidators(Validators.required());
    this.formGroup.get('positionName').updateValueAndValidity();
    this.formGroup.get('departmentName').setValidators(Validators.required());
    this.formGroup.get('departmentName').updateValueAndValidity();
    this.isFormPage = false;
    this.formGroup.reset();
  }
  public onChangeParticipatRole(): void {}

  public onChangeBlank(event): void {
    this.isBlank = event.isBlank;
  }
}
