import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ResourceOwnershipEditAddComponent } from './resource-ownership-edit-add.component';
import { ResourceOwnershipComponent } from './resource-ownership.component';
export const routes = [
  { path: '', component: ResourceOwnershipComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: ResourceOwnershipEditAddComponent,
    data: { breadcrumb: 'resource-ownership.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: ResourceOwnershipEditAddComponent,
    data: { breadcrumb: 'resource-ownership.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ResourceOwnershipComponent, ResourceOwnershipEditAddComponent],
  providers: []
})
export class ResourceOwnershipModule {}
