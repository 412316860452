<app-popup header="app-popup-version-history.header.title">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <app-select
      formControlName="modelList"
      [optionList]="modelOptionList"
      [isMultiple]="true"
      [isShowHover]="false"
      isShowBorder="true"
      [search]="true"
      maxHeight="max-content"
    >
      <ng-template #content let-data>
        <div style="margin: 0 auto; display: flex; position: relative">
          <div
            style="
              width: 60px;
              height: 60px;
              overflow: hidden;
              border-radius: 60px;
              display: contents;
            "
            class="mr-3"
          >
            <img
              [attr.src]="
                '/assets/component/fileuploader/logo-icon/' +
                data.docFile.extension.toLowerCase() +
                '.png'
              "
              [attr.alt]="data.docFile.fileName"
              [default]="global.appConstant.core.URL_DEFAULT_ICON"
              class="img-thumbnail"
              style="width: 60px; height: 60px; border: none"
            />
          </div>

          <div style="flex: 1; padding: 0.25rem 1rem" class="d-inline-block">
            <a
              class="item-content-name"
              href="#"
              (click)="
                doDownload(
                  $event,
                  '/contract-draft/file/view/' +
                    data.docFile.uploadedFileName +
                    '/' +
                    data.docFile.fileType.code,
                  data.docFile.fileName
                )
              "
              maxLine
              [attr.title]="data.docFile.fileName"
            >
              {{ data.docFile.fileName }} </a
            ><br />
            <ng-container>
              <span class="d-inline-flex">
                <strong class="mr-1">{{ data.version }}</strong>
                <span class="item-content-size">
                  {{ data.docFile.size / 1000000 | number: '1.2-2' }} MB
                </span>
              </span>
              <p class="text-secondary">
                {{ 'app-popup-version-history.form.uploadedBy' | translate }}
                {{ data.uploaderName }}
                {{ 'app-popup-version-history.form.at' | translate }}
                {{
                  data.lastModifiedDate
                    | date: global.appConstant.core.FORMAT_SHORT_DATE_AND_TIME
                }}
              </p>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </app-select>
  </form>
</app-popup>
