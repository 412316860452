import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Counter } from '../../core/bean/counter';
import { User } from '../../core/bean/user';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { AppPopupAddUserCounterComponent } from './app-popup-add-user-counter.component';
import { UserMonitoringRequest } from './user-monitoring-request';
import { UserMonitoringResponse } from './user-monitoring-response';
import { UserCounter } from 'src/app/core/bean/user-counter';

@Component({
  templateUrl: './user-monitoring-edit.component.html'
})
export class UserMonitoringEditComponent extends BaseModuleComponent {
  public tableResponseUserCounter: TableResponseModel<UserCounter>;
  public user: User = new User();
  public userCounterList: UserCounter[] = [];
  public counterList: Counter[] = [];
  public userMonitoringResponseTemp: UserMonitoringResponse;
  public userMonitoringRequest: UserMonitoringRequest;
  public urlBackOutside: string;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('user-monitoring', translateService);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setDataFromRouterParams();
    this.doBuildTableResponse();
    this.setFormGroup();
    this.setStateReady();
  }
  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      user: [null],
      userCounterList: [null]
    });
  }
  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.user = this.global.routerParams.get('user');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
  }
  public doBuildTableResponse(): void {
    this.tableResponseUserCounter = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'user.name',
          header: 'table.column.user.name'
        },
        {
          field: 'counter.name',
          header: 'table.column.counter.name'
        }
      ]
    );
  }
  public setFormGroup(): void {
    this.httpClientService
      .post<UserMonitoringResponse>(
        '/user-monitoring/edit',
        new RouteRequestModel(this.todo, this.user.id)
      )
      .subscribe((userMonitoringResponse: UserMonitoringResponse) => {
        this.userMonitoringResponseTemp = userMonitoringResponse;
        if (userMonitoringResponse !== null) {
          this.user = userMonitoringResponse.user;
          this.userCounterList = userMonitoringResponse.userCounterList;
          this.counterList = userMonitoringResponse.counterList;
          this.formGroup.patchValue({
            user: userMonitoringResponse.user,
            counterList: userMonitoringResponse.counterList
          });
          this.setTableResponseUserCounterState(this.userCounterList);
          this.tableResponseUserCounter.reloadClient();
        }
      });
  }

  private setTableResponseUserCounterState(userCounterList: UserCounter[]): void {
    this.tableResponseUserCounter.setRecords(
      userCounterList
    );
    this.tableResponseUserCounter.setTotalRecords(
      userCounterList.length
    );
  }
  public doAddUserCounter(): void {
    this.counterList = this.counterList.filter((counter, index) => {
      return this.counterList.indexOf(counter) === index;
    });
    this.appPopupService
      .open(
        AppPopupAddUserCounterComponent,
        {
          counterList: this.counterList,
          user: this.user,
          userCounterList: this.userCounterList,
          userMonitoringResponseTemp: this.userMonitoringResponseTemp
        },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe((userCounter: UserCounter[]) => {
        this.userCounterList = userCounter;
        const userCounterListTemp = this.userCounterList.filter(userCounter => {
          return userCounter.crudOperation !== this.global.appConstant.core.CRUD_OPERATION_DELETE;
        });
        this.tableResponseUserCounter.setRecords(userCounterListTemp);
        this.tableResponseUserCounter.setTotalRecords(
          userCounterListTemp.length
        );
        this.tableResponseUserCounter.reloadClient();
      });
  }

  public doDeleteUserCounter(rowData): void {
    const index = this.userCounterList.findIndex(
      i => JSON.stringify(i) === JSON.stringify(rowData)
    );
    if (index !== -1) {
      this.global.modalService
        .deleteConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.userCounterList[index].crudOperation = this.global.appConstant.core.CRUD_OPERATION_DELETE;
            this.counterList.push(rowData.counter);
          }
          const userCounterListTemp = this.userCounterList.filter(userCounter => {
            return userCounter.crudOperation !== this.global.appConstant.core.CRUD_OPERATION_DELETE;
          });
          if (userCounterListTemp.length === 0) {
            this.tableResponseUserCounter.page.records =
              userCounterListTemp;
            this.tableResponseUserCounter.page.totalRecords =
              userCounterListTemp.length;
            this.tableResponseUserCounter.reload();
          }
          this.setTableResponseUserCounterState(userCounterListTemp);
          this.tableResponseUserCounter.reloadClient();

          this.setStateReady();
        });
    }
  }
  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const userMonitoringRequest = new UserMonitoringRequest();
            const userCounterChangedList = this.userCounterList.filter(userCounter => {
              return (userCounter.crudOperation ===
                this.global.appConstant.core.CRUD_OPERATION_INSERT || userCounter.crudOperation === this.global.appConstant.core.CRUD_OPERATION_DELETE);
            });
            userMonitoringRequest.user = this.user;
            userMonitoringRequest.userCounterList = userCounterChangedList;
            this.setStateProcessing();
            this.httpClientService
              .post<Response<UserMonitoringRequest>>(
                '/user-monitoring/update',
                userMonitoringRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/user-monitoring/']);
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/user-monitoring/']);
  }
}
