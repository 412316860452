import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Renderer2
} from '@angular/core';
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[readMore]'
})
export class ReadMoreDirective implements AfterViewInit {
  @Input() maxRow: number;
  el: any;

  constructor(el: ElementRef, public renderer2: Renderer2) {
    this.el = el.nativeElement;
    this.maxRow = this.maxRow ? this.maxRow : 5;
  }

  ngAfterViewInit(): void {
    if (this.el.textContent.length > 400) {
      this.renderer2.setStyle(this.el, 'overflow', 'hidden');
      this.renderer2.setStyle(this.el, 'display', '-webkit-box');
      this.renderer2.setStyle(this.el, '-webkit-box-orient', 'vertical');
      this.renderer2.setStyle(
        this.el,
        '-webkit-line-clamp',
        this.maxRow.toString()
      );
      const readMoreElement = this.renderer2.createElement('p');
      const readLessElement = this.renderer2.createElement('p');
      const textMore = this.renderer2.createText('Lihat selengkapnya');
      const textLess = this.renderer2.createText('Lihat lebih sedikit');
      this.renderer2.addClass(readMoreElement, 'cursor-pointer');
      this.renderer2.setStyle(readMoreElement, 'color', 'steelblue');
      this.renderer2.addClass(readLessElement, 'cursor-pointer');
      this.renderer2.setStyle(readLessElement, 'color', 'steelblue');
      this.renderer2.appendChild(readMoreElement, textMore);
      this.renderer2.listen(readMoreElement, 'click', () => {
        this.renderer2.removeStyle(this.el, 'overflow');
        this.renderer2.removeStyle(this.el, 'display');
        this.renderer2.removeStyle(this.el, '-webkit-box-orient');
        this.renderer2.removeStyle(this.el, '-webkit-line-clamp');
        this.renderer2.removeChild(readMoreElement, textMore);
        this.renderer2.removeChild(this.el.parentElement, readMoreElement);
        this.renderer2.appendChild(readLessElement, textLess);
        this.renderer2.appendChild(this.el.parentElement, readLessElement);
      });
      this.renderer2.listen(readLessElement, 'click', () => {
        this.renderer2.setStyle(this.el, 'overflow', 'hidden');
        this.renderer2.setStyle(this.el, 'display', '-webkit-box');
        this.renderer2.setStyle(this.el, '-webkit-box-orient', 'vertical');
        this.renderer2.setStyle(
          this.el,
          '-webkit-line-clamp',
          this.maxRow.toString()
        );
        this.renderer2.removeChild(readLessElement, textLess);
        this.renderer2.removeChild(this.el.parentElement, readLessElement);
        this.renderer2.appendChild(readMoreElement, textMore);
        this.renderer2.appendChild(this.el.parentElement, readMoreElement);
      });
      this.renderer2.appendChild(this.el.parentElement, readMoreElement);
    }
  }
}
