import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { BudgetAllocation } from 'src/app/core/bean/budget-allocation';
import { Currency } from 'src/app/core/bean/currency';
import { PrItem } from 'src/app/core/bean/pr-item';
import { AppPopupChooseBudgetXService } from 'src/app/core/components/app-popup/app-popup-choose-budget-x/app-popup-choose-budget-x.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableComponent } from 'src/app/core/components/table/components/app-table/app-table.component';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { FileObject, FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { TabModel } from 'src/app/core/model/tab/tab-model';
import { TabResponseModel } from 'src/app/core/model/tab/tab-response-model';
import { Validators } from 'src/app/core/validators';
import { AppPopupPrAddBudgetComponent } from './app-popup-pr-add-budget.component';
import { PrResponse } from './pr.response';

@Component({
  templateUrl: './pr-item-catalog-add.component.html',
  styleUrls: ['./app-popup-pr-item-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrItemCatalogAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  public prResponse: PrResponse = new PrResponse();
  @ViewChild('selectorItemBudget') tableItemBudget: AppTableComponent;
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel();
  public prItem: PrItem = new PrItem();
  public autoCompleteValue: any;
  public isBudgetValue: boolean;
  public fileUploader: FileUploader = new FileUploader(
    '/pr/',
    '',
    this.global.appConstant.fileType.IMG_ITEM_PR
  );
  public tabResponse: TabResponseModel;
  public tabTwo: TabModel;
  public backToUrl: string;
  public tableResponseBudgetList: TableResponseModel<BudgetAllocation>[] = [];
  public budgetAllocationTempList: Array<BudgetAllocation> = new Array();
  public usedBudgetList: Map<number, number> = new Map<number, number>(); // budgetAllocationId, prItemBugetAmount
  public prItemBudgetEditList: Map<number, number> = new Map<number, number>(); // budgetAllocationId, prItemBugetAmount

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupChooseBudgetService: AppPopupChooseBudgetXService
  ) {
    super('pr', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setOptionModel();
    this.setUsedBudgetList();
    this.buildFormGroup();
    this.setStateReady();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.prResponse = this.global.routerParams.get('prResponse');
    this.tabResponse = this.global.routerParams.get('tabResponse');
    this.backToUrl = this.global.routerParams.get('backToUrl');
    this.tabTwo = this.tabResponse.currentTab;
    this.prItemBudgetEditList = this.global.routerParams.get(
      'prItemBudgetEditList'
    );
  }

  public setOptionModel(): void {
    this.currencyOptionList.setRequestValues(this.prResponse.currencyList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      prItemList: this.formBuilder.array([])
    });
    this.buildFormArray();
  }

  public get prItemList(): FormArray {
    return this.formGroup.get('prItemList') as FormArray;
  }

  public newPrItem(): FormGroup {
    const formGroup = this.formBuilder.group({
      id: [null],
      pr: [null],
      item: [null, Validators.required()],
      prices: [null],
      price: [null, Validators.required()],
      totalPrice: [null, Validators.required()],
      quantity: [
        null,
        Validators.compose([Validators.required(), Validators.min(1)])
      ],
      specification: [null, Validators.required()],
      itemImages: [null],
      currency: [this.prResponse.companyCurrency],
      totalBudget: [null],
      catalog: [null],
      prItemBudgetList: this.formBuilder.array([]),
      budgetAllocationList: this.formBuilder.array([]),
      prItemImageList: this.formBuilder.array([])
    });
    return formGroup;
  }

  public buildTableResponse(budgetAllocationList: BudgetAllocation[]): void {
    const tableResponse = new TableResponseModel<BudgetAllocation>(
      this.moduleCode,
      [
        {
          field: 'organization.name',
          header: 'table.column.department'
        },
        {
          field: 'activityPeriod',
          header: 'table.column.activityPeriod'
        },
        {
          field: 'costCenter.code',
          header: 'table.column.costCenter'
        },
        {
          field: 'coa.code',
          header: 'table.column.coa'
        },
        {
          field: 'budgetType.name',
          header: 'table.column.budgetType'
        },
        {
          field: 'availableBudget',
          header: 'table.column.availableBudget',
          className: 'text-right',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePlugin: TablePluginData): number => {
                if (tablePlugin.value) {
                  const available = this.prItemBudgetEditList.get(
                    tablePlugin.row.record.id
                  )
                    ? tablePlugin.value -
                      tablePlugin.row.record.tempBookedBudget -
                      +(
                        this.usedBudgetList.get(tablePlugin.row.record.id) || 0
                      ) +
                      this.prItemBudgetEditList.get(tablePlugin.row.record.id)
                    : this.usedBudgetList.get(tablePlugin.row.record.id)
                    ? tablePlugin.value -
                      tablePlugin.row.record.tempBookedBudget -
                      this.usedBudgetList.get(tablePlugin.row.record.id)
                    : tablePlugin.value -
                      +tablePlugin.row.record.tempBookedBudget;

                  return available;
                }
              }
            },
            {
              name: 'default',
              type: 'currency'
            }
          ]
        },
        {
          field: 'budgetValue',
          header: 'table.column.budgetValue',
          className: 'text-right',
          plugins: [
            {
              name: 'currency',
              optionList: this.currencyOptionList,
              isView: !!(this.todo === 'view'),
              validators: Validators.required(),
              onInput: this.onChangeBudgetValue.bind(this)
            }
          ]
        }
      ]
    );
    tableResponse.setRecordList(budgetAllocationList);
    this.tableResponseBudgetList.push(tableResponse);
  }

  public buildFormArray(): void {
    this.prItemList.push(this.newPrItem());
    this.prItemList.controls.forEach(prItem => {
      this.buildTableResponse(prItem.get('budgetAllocationList').value);
    });
  }
  public autoCompleteValueChange(event: any): void {
    this.autoCompleteValue = event;
  }

  public doDeletePrItem(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.prItemList.removeAt(index);
          this.tableResponseBudgetList.splice(index, 1);
          console.log(this.tableResponseBudgetList);
        }
      });
  }
  public addPrItem(): void {
    this.prItemList.push(this.newPrItem());
    const formGroup = this.newPrItem();
    this.buildTableResponse(formGroup.get('budgetAllocationList').value);
  }

  public onChangeQuantity(i: number): void {
    const prices =
      this.prItemList.value[i].prices && this.prItemList.value[i].prices.price;

    const quantity = this.prItemList.value[i].quantity;

    let totalPrice = 0;
    if (prices && quantity) {
      totalPrice = +prices * +quantity;
      this.prItemList.controls[i].patchValue({
        prices: {
          price: prices
        }
      });

      this.prItemList.controls[i].patchValue({
        price: prices
      });
    }
    this.prItemList.controls[i].patchValue({
      totalPrice
    });
  }

  public doAddBudget(prItem: FormGroup, i: number): void {
    const prResponse = this.prResponse;
    const todo = 'add';
    const budgetAllocationLists = this.prItemList.controls[i].get(
      'budgetAllocationList'
    ) as FormArray;
    const prItemBudgetList = this.prItemList.controls[i].get(
      'prItemBudgetList'
    ) as FormArray;
    this.log.info(prItem);
    const usedBudgetList = this.usedBudgetList;
    this.appPopupService
      .open(AppPopupPrAddBudgetComponent, { prResponse, todo, usedBudgetList })
      .subscribe(budgetAllocation => {
        const formGroup = this.formBuilder.group({
          id: budgetAllocation.id,
          organization: budgetAllocation.organization,
          costCenter: budgetAllocation.costCenter,
          coa: budgetAllocation.coa,
          activityPeriod: budgetAllocation.activityPeriod,
          availableBudget: budgetAllocation.totalBudget,
          currency: budgetAllocation.currency,
          budgetValue: [budgetAllocation.totalBudget, Validators.required()],
          totalBudget: budgetAllocation.totalBudget,
          budgetType: budgetAllocation.budgetType,
          tempBookedBudget: budgetAllocation.tempBookedBudget
        });
        budgetAllocationLists.push(formGroup);
        const formGroupItem = this.formBuilder.group({
          prItem: null,
          budgetAllocation,
          bookedAmount: budgetAllocation.totalBudget
        });
        prItemBudgetList.push(formGroupItem);
        this.prItemList.controls[i].patchValue({
          totalBudget: this.getTotalBudget(i)
        });

        this.tableResponseBudgetList[i].setRecordList(
          budgetAllocationLists.value
        );
      });
  }
  public doEditBudget(
    budgetAllocationEdit,
    prItem: FormGroup,
    i: number
  ): void {
    const prResponse = this.prResponse;
    const todo = 'edit';
    const budgetAllocationLists = this.prItemList.controls[i].get(
      'budgetAllocationList'
    ) as FormArray;
    const prItemBudgetList = this.prItemList.controls[i].get(
      'prItemBudgetList'
    ) as FormArray;
    this.log.info(prItem);
    const usedBudgetList = this.usedBudgetList;
    this.appPopupService
      .open(AppPopupPrAddBudgetComponent, {
        prResponse,
        todo,
        budgetAllocationEdit,
        usedBudgetList
      })
      .subscribe(budgetAllocation => {
        const formGroup = this.formBuilder.group({
          id: budgetAllocation.id,
          organization: budgetAllocation.organization,
          costCenter: budgetAllocation.costCenter,
          coa: budgetAllocation.coa,
          activityPeriod: budgetAllocation.activityPeriod,
          availableBudget: budgetAllocation.availableBudget,
          currency: budgetAllocation.currency,
          budgetValue: [budgetAllocation.budgetValue, Validators.required()],
          budgetType: budgetAllocation.budgetType,
          tempBookedBudget: budgetAllocation.tempBookedBudget
        });
        budgetAllocationLists.push(formGroup);
        const formGroupItem = this.formBuilder.group({
          prItem: null,
          budgetAllocation,
          bookedAmount: budgetAllocation.budgetValue
        });
        prItemBudgetList.push(formGroupItem);

        this.tableResponseBudgetList[i].setRecordList(
          budgetAllocationLists.value
        );
        this.tableResponseBudgetList[i].reload();
      });
  }

  public doChooseBudget(prItem: FormGroup, i: number): void {
    console.log(prItem);
    const budgetAllocationList: BudgetAllocation[] = this.prItemList.controls[i]
      .get('prItemBudgetList')
      .value.map(prItemBudget => prItemBudget.budgetAllocation);

    const budgetAllocationIdList: number[] = budgetAllocationList.map(
      budget => budget.id
    );
    const budgetAllocationExistList = this.prItemList.controls[i].get(
      'budgetAllocationList'
    ).value;
    const usedBudgetList = this.usedBudgetList;
    this.appPopupChooseBudgetService
      .open(
        true,
        budgetAllocationList,
        usedBudgetList,
        this.prItemBudgetEditList
      )
      .subscribe(budgetAllocationListTemp => {
        const budgetAllocationLists = this.prItemList.controls[i].get(
          'budgetAllocationList'
        ) as FormArray;
        budgetAllocationLists.clear();
        const prItemBudgetList = this.prItemList.controls[i].get(
          'prItemBudgetList'
        ) as FormArray;
        prItemBudgetList.clear();

        budgetAllocationListTemp.forEach(budgetAllocation => {
          let budgetValue = null;
          if (budgetAllocationIdList.includes(budgetAllocation.id)) {
            budgetValue = budgetAllocationExistList.filter(
              budget => budget.id === budgetAllocation.id
            )[0]['budgetValue'];
          }
          if (budgetAllocationListTemp.length === 1) {
            const available = this.prItemBudgetEditList.get(budgetAllocation.id)
              ? budgetAllocation.availableBudget -
                budgetAllocation.tempBookedBudget -
                +(this.usedBudgetList.get(budgetAllocation.id) || 0) +
                this.prItemBudgetEditList.get(budgetAllocation.id)
              : this.usedBudgetList.get(budgetAllocation.id)
              ? budgetAllocation.availableBudget -
                budgetAllocation.tempBookedBudget -
                this.usedBudgetList.get(budgetAllocation.id)
              : budgetAllocation.availableBudget -
                +budgetAllocation.tempBookedBudget;

            const priceBudgetValue =
              +prItem.get('totalPrice').value > +available
                ? +available
                : +prItem.get('totalPrice').value;

            budgetValue = {
              currency: {},
              price: priceBudgetValue
            };
          }
          const formGroup = this.formBuilder.group({
            id: budgetAllocation.id,
            organization: budgetAllocation.organization,
            costCenter: budgetAllocation.costCenter,
            budgetType: budgetAllocation.budgetType,
            coa: budgetAllocation.coa,
            activityPeriod: budgetAllocation.activityPeriod,
            availableBudget: budgetAllocation.availableBudget,
            currency: budgetAllocation.currency,
            tempBookedBudget: budgetAllocation.tempBookedBudget,
            budgetValue
          });
          budgetAllocationLists.push(formGroup);
          const formGroupItem = this.formBuilder.group({
            prItem: null,
            budgetAllocation,
            bookedAmount: budgetValue ? +budgetValue.price : budgetValue
          });
          prItemBudgetList.push(formGroupItem);
        });

        this.tableResponseBudgetList[i].setRecordList(
          budgetAllocationLists.value
        );
        this.tableResponseBudgetList[i].reload();

        this.prItemList.controls[i].patchValue({
          totalBudget: this.getTotalBudget(i)
        });
      });
  }

  public onChangeBudgetValue(amount): void {
    this.tableResponseBudgetList.forEach((tableResponse, i) => {
      if (tableResponse.currentRowChange) {
        const currentRowChange = tableResponse.currentRowChange;
        const budgetValue = amount.price;
        const recordList = tableResponse.getRecordList().map(record => {
          const indexOfR = tableResponse
            .getUpdatedRecordList()
            .findIndex(updateRecord => updateRecord.id === record.id);
          return indexOfR === -1
            ? record
            : tableResponse.getUpdatedRecordList()[indexOfR];
        });

        tableResponse.setRecordList(recordList);

        const totalBudgetValue = this.getTotalBudgetValue(
          currentRowChange.row.record
        );

        const available = this.prItemBudgetEditList.get(
          +currentRowChange.row.record.id
        )
          ? +currentRowChange.row.record.availableBudget -
            +currentRowChange.row.record.tempBookedBudget -
            +(this.usedBudgetList.get(+currentRowChange.row.record.id) || 0) +
            this.prItemBudgetEditList.get(+currentRowChange.row.record.id)
          : this.usedBudgetList.get(currentRowChange.row.record.id)
          ? +currentRowChange.row.record.availableBudget -
            +currentRowChange.row.record.tempBookedBudget -
            +this.usedBudgetList.get(currentRowChange.row.record.id)
          : +currentRowChange.row.record.availableBudget -
            +currentRowChange.row.record.tempBookedBudget;

        const remainingValue = +available - (+totalBudgetValue - +budgetValue);

        const amountForm = currentRowChange.row.formGroup.get('budgetValue');
        if (+totalBudgetValue > +available) {
          amountForm.setValidators([Validators.max(+remainingValue)]);
          amountForm.setErrors({
            message: this.translateService.instant('app.validation.max')
          });
          amountForm.markAsTouched({ onlySelf: true });
        } else if (!+amount.price) {
          amountForm.setValidators([Validators.required()]);
          amountForm.setErrors({
            message: this.translateService.instant('app.validation.required')
          });
          amountForm.markAsTouched({ onlySelf: true });
        } else {
          const prItemBudgetList = this.prItemList.controls[i].get(
            'prItemBudgetList'
          ) as FormArray;
          const budgetAllocationList = this.prItemList.controls[i].get(
            'budgetAllocationList'
          ) as FormArray;

          tableResponse.formArray.controls.forEach(
            (budgetAllocation: FormGroup) => {
              budgetAllocation.get('budgetValue').clearValidators();

              budgetAllocation.get('budgetValue').updateValueAndValidity();
            }
          );
          amountForm.clearValidators();
          amountForm.updateValueAndValidity();

          prItemBudgetList.controls.forEach(prItemBudget => {
            if (
              +prItemBudget.value.budgetAllocation.id ===
              currentRowChange.row.record.id
            ) {
              prItemBudget.patchValue({
                bookedAmount: +budgetValue
              });
            }
          });

          budgetAllocationList.controls.forEach(budget => {
            if (+budget.value.id === currentRowChange.row.record.id) {
              budget.patchValue({
                budgetValue: amount
              });
            }
          });

          this.prItemList.controls[i].patchValue({
            totalBudget: this.getTotalBudget(i)
          });
        }
      }
    });
  }

  public getTotalBudgetValue(budgetAllocation): number {
    let totalBudgetValue = 0;
    if (budgetAllocation && budgetAllocation.id !== null) {
      this.tableResponseBudgetList.forEach(tableResponse => {
        tableResponse.getRecordList().forEach(budget => {
          if (+budgetAllocation.id === +budget.id && budget['budgetValue']) {
            totalBudgetValue = +totalBudgetValue + +budget['budgetValue'].price;
          }
        });
      });
    } else {
      totalBudgetValue =
        budgetAllocation && budgetAllocation['budgetValue'].price;
    }
    return totalBudgetValue;
  }

  public getTotalBudget(i): number {
    let totalBudgetValue = 0;
    this.tableResponseBudgetList[i].getRecordList().forEach(budget => {
      if (budget['budgetValue']) {
        totalBudgetValue = +totalBudgetValue + +budget['budgetValue'].price;
      }
    });
    return totalBudgetValue;
  }

  public doDeleteBudget(event, i): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach((record: PrItem) => {
            const indexOfRecord = this.tableResponseBudgetList[i]
              .getRecordList()
              .findIndex(budget => budget.id === record.id);
            this.tableResponseBudgetList[i]
              .getRecordList()
              .splice(indexOfRecord, 1);
            this.tableResponseBudgetList[i].reload();
            const budgetAllocationList = this.prItemList.controls[i].get(
              'budgetAllocationList'
            ) as FormArray;
            const prItemBudgetExistList = this.prItemList.controls[i].get(
              'prItemBudgetList'
            ) as FormArray;

            budgetAllocationList.removeAt(indexOfRecord);
            prItemBudgetExistList.removeAt(indexOfRecord);
          });

          this.prItemList.controls[i].patchValue({
            totalBudget: this.getTotalBudget(i)
          });
        }
      });
  }

  public validatorBudget(): void {
    this.prItemList.controls.forEach((prItem, index) => {
      const totalBudget = this.getTotalBudget(index);
      if (+totalBudget !== prItem.get('totalPrice').value) {
        this.isBudgetValue = false;
        return;
      } else {
        this.isBudgetValue = true;
      }
    });
  }

  public isCatalogContractExist(): boolean {
    const prItemContractList = this.prResponse.prItemList.filter(
      prItem =>
        prItem.catalog &&
        prItem.catalog.catalogType.code ===
          this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
    );
    if (prItemContractList && prItemContractList.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public doPurchaseRequest(): void {
    this.validate();
    this.validatorBudget();
    if (
      this.formGroup.valid &&
      this.isBudgetValue &&
      !this.isCatalogContractExist()
    ) {
      this.prItemList.controls.forEach(prItem => {
        prItem.value.item.name = prItem.value.item.name.split(' - ')[1];
        const prItemImageList = prItem.get('prItemImageList') as FormArray;
        // tslint:disable-next-line:no-unused-expression
        prItem.get('itemImages').value &&
          prItem.get('itemImages').value.forEach(itemImage => {
            const fileObjectList: FileObject[] = [];
            fileObjectList.push(itemImage);
            const formGroupItem = this.formBuilder.group({
              fileObjectList: [fileObjectList],
              file: itemImage.file
            });
            prItemImageList.push(formGroupItem);
          });
      });
      this.prItemList.value.forEach(prItem => {
        prItem.crudOperation =
          this.global.appConstant.core.CRUD_OPERATION_INSERT;
        this.prResponse.prItemList.push(prItem);
      });
      this.prResponse.prItemList.forEach((prItem, index) => {
        prItem.code = '00' + (+index + +1);
      });
      this.global.routerParams.set('prResponse', this.prResponse);
      this.router.navigate(['/pages/pr/add']);
    } else if (!this.isBudgetValue) {
      this.global.alertService.showWarning(
        'pr.alert.budgetValue',
        '.table-budget'
      );
    } else if (this.isCatalogContractExist()) {
      this.global.alertService.showWarning(
        'pr.alert.contractExist',
        '.table-budget'
      );
    }
  }

  public doCart(): void {
    this.validate();
    this.validatorBudget();
    if (this.formGroup.valid && this.isBudgetValue) {
      let prItemList: PrItem[] = [];
      this.prItemList.controls.forEach(prItem => {
        const prItemImageList = prItem.get('prItemImageList') as FormArray;
        // tslint:disable-next-line:no-unused-expression
        prItem.get('itemImages').value &&
          prItem.get('itemImages').value.forEach(itemImage => {
            const fileObjectList: FileObject[] = [];
            fileObjectList.push(itemImage);
            const formGroupItem = this.formBuilder.group({
              fileObjectList: [fileObjectList],
              file: itemImage.file
            });
            prItemImageList.push(formGroupItem);
          });
      });
      prItemList = this.prItemList.value;
      prItemList.forEach((prItem, index) => {
        prItem.code = '00' + index + 1;
      });
      this.global.modalService
        .confirmation(
          'pr.confirmation.addCart.prompt',
          'pr.confirmation.addCart.title',
          'pr.confirmation.addCart.button.cancel',
          'pr.confirmation.addCart.button.yes',
          'pvc pv-shopping-cart',
          false
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<PrItem[]>>('/pr/insert-cart', prItemList)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.global.routerParams.set('todo', this.todo);
                  this.global.routerParams.set('prResponse', this.prResponse);
                  this.global.routerParams.set('backToUrl', this.backToUrl);
                  this.router.navigate(['/pages/cart/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    } else if (!this.isBudgetValue) {
      this.global.alertService.showWarning(
        'pr.alert.budgetValue',
        '.table-budget'
      );
    }
  }

  public setUsedBudgetList(): void {
    // usedBudgetList dari item2 yang sudah di tambahkan
    this.prResponse.prItemList.forEach(prItem => {
      prItem.prItemBudgetList.forEach(prItemBudget => {
        if (this.usedBudgetList.get(prItemBudget.budgetAllocation.id)) {
          this.usedBudgetList.set(
            prItemBudget.budgetAllocation.id,
            +prItemBudget.bookedAmount +
              +this.usedBudgetList.get(prItemBudget.budgetAllocation.id)
          );
        } else {
          this.usedBudgetList.set(
            prItemBudget.budgetAllocation.id,
            +prItemBudget.bookedAmount
          );
        }
      });
    });
  }
}
