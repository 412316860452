<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card *ngIf="isSyncRunning">
      <div id="work-program-sync-info" class="alert alert-warning"  role="alert" *ngIf="(websocketBudgetService.message | async)">
        <strong class="mr-2"><em class="fa fa-info-circle mr-2"></em>{{ 'budget-allocation-bjb.sync.info' | translate }}</strong>
        {{ (websocketBudgetService.message | async) }}
      </div>
  </app-card>
    <app-card
      header="coa-bjb.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
      *ngIf="!isSyncRunning"
    >
      <div *ngIf="!formLoading">
        <div id="work-program-sync-info" class="alert alert-warning"  role="alert" *ngIf="(websocketBudgetService.message | async)">
          <strong class="mr-2"><em class="fa fa-info-circle mr-2"></em>{{ 'budget-allocation-bjb.sync.info' | translate }}</strong>
          {{ (websocketBudgetService.message | async) }}
        </div>
        <app-table
          [tableResponse]="tableResponse"
          [isServerSide]="true"
          stringUrl="/coa-bjb/index"
          *ngIf="!formLoading"
        >
          <ng-template #headerButtons>
            <app-button (onClick)="doAdd()"
              [disabled]="(websocketBudgetService.message | async)" 
              ><em class="fas fa-plus"></em>
              {{ 'app.button.add' | translate }}</app-button
            >
          </ng-template>
          <ng-template #actionButtons let-rowData>
            <app-button
              size="TN"
              (onClick)="doEdit(rowData)"
              title="{{ 'app.tooltip.edit' | translate }}"
              [disabled]="(websocketBudgetService.message | async)" 
              ><em class="fas fa-pencil-alt"></em
            ></app-button>
            <app-button
              size="TN"
              color="DANGER"
              (onClick)="doDelete(rowData)"
              title="{{ 'app.tooltip.delete' | translate }}"
              [disabled]="(websocketBudgetService.message | async)" 
              ><em class="fas fa-trash"></em
            ></app-button>
          </ng-template>
        </app-table>
      </div>
    </app-card>
  </div>
</div>
