import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupTabRepAgentComponent } from './../tab-rep-agent/app-popup-tab-rep-agent.component';
import { TabRepAgentHistoryComponent } from './tab-rep-agent-history.component';
import { TabRepAgentComponent } from './tab-rep-agent.component';
@NgModule({
  imports: [SharedModule],
  declarations: [
    TabRepAgentComponent,
    AppPopupTabRepAgentComponent,
    TabRepAgentHistoryComponent
  ],
  entryComponents: [
    AppPopupTabRepAgentComponent,
    TabRepAgentComponent,
    TabRepAgentHistoryComponent
  ],
  exports: [TabRepAgentComponent, TabRepAgentHistoryComponent]
})
export class TabRepAgentModule {
  constructor(accordionService: AccordionService) {
    accordionService.register('tab-rep-agent', TabRepAgentComponent);
    accordionService.register(
      'tab-rep-agent-history',
      TabRepAgentHistoryComponent
    );
  }
}
