import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { OptionListModel } from '../../../core/model/option-list-model';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { Sample } from '../../../core/bean/sample';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { RouteRequestModel } from '../../../core/model/route-request-model';
import { Validators } from '../../../core/validators';
@Component({
  templateUrl: './sample-edit-add.component.html'
})
export class SampleEditAddComponent extends BaseModuleComponent {
  public sampleId: number;
  public vehicleOptionList: OptionListModel<any> = new OptionListModel();
  public currencySingleOptionList: OptionListModel<any> = new OptionListModel();
  public currencyOptionList: OptionListModel<any> = new OptionListModel();
  constructor(translateService: TranslateService) {
    super('sample', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
    this.setOptionListRequestValue();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.sampleId = this.global.routerParams.get(
      'sampleId'
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(100)])
      ],
      description: [null, Validators.maxLength(512)],
      vehicleCombo: [null],
      vehicleComboMultipleView: [null],
      vehicleMultipleChosen: [null],
      vehicleMultipleChosenView: [null],
      autoCompleteSingle:[null],
      staticPrice:[null],
      chosenPrice:[null],
      quantity:[]
    });
  }

  public setOptionListRequestValue(): void {
    const VehicleList = [
      { id: 1, name: 'Agya', category: 'Mobil' },
      { id: 2, name: 'Vario', category: 'Motor' },
      { id: 3, name: 'Beat', category: 'Motor' },
      { id: 4, name: 'Civic', category: 'Mobil' },
      { id: 5, name: 'Mio', category: 'Motor' },
      { id: 6, name: 'March', category: 'Mobil' },
      { id: 7, name: 'Alphard', category: 'Mobil'}
    ];
    const CurrencySingleList = [
      {organization: null, id: 1, code: "IDR", name: "Rupiah", isDefault: false, exchangeRateInIdr: 1}
    ];
    const CurrencyList = [
      {organization: null, id: 1, code: "IDR", name: "Rupiah", isDefault: false, exchangeRateInIdr: 1},
      {organization: null, id: 2, code: "USD", name: "Dollar", isDefault: false, exchangeRateInIdr: 1}
    ];

    this.vehicleOptionList.setRequestValues(VehicleList);
    this.currencySingleOptionList.setRequestValues(CurrencySingleList);
    this.currencyOptionList.setRequestValues(CurrencyList);
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<Sample>(
        '/sample/add-edit',
        new RouteRequestModel(this.todo, this.sampleId)
      )
      .subscribe(sample => {
        if (sample != null) {
          const { id, name, description } = sample;
          this.formGroup.patchValue({ id, name, description });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const sample: Sample = this.global.copyFormAttributeToModel(
              new Sample(),
              this.formGroup
            );
            const url =
              this.todo === 'edit'
                ? '/sample/update'
                : '/sample/insert';
            this.httpClientService
              .post<Response<Sample>>(
                url,
                sample
              )
              .subscribe((response: Response<Sample>) => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/sample/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/sample/']);
  }
}
