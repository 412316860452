import { Component, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ScoringCriteria } from '../../core/bean/scoring-criteria';
import { ScoringTemplate } from '../../core/bean/scoring-template';
import { VendorPerformanceScore } from '../../core/bean/vendor-performance-score';
import { VendorPerformanceSummary } from '../../core/bean/vendor-performance-summary';
import { FileUploader } from '../../core/components/upload';
import { PerformanceFulfillmentDetailResponse } from './performance-fulfillment-detail.response';
import { PerformanceFulfillmentEditRequest } from './performance-fulfillment-edit-request';
@Component({
  templateUrl: './performance-fulfillment-detail-contract.component.html'
})
export class PerformanceFulfillmentDetailContractComponent
  extends BaseModuleComponent
  implements OnInit
{
  public vendorId: number;
  public procurementSowPaymentTermId: number;
  public pFulfillmentDetailResponse: PerformanceFulfillmentDetailResponse =
    new PerformanceFulfillmentDetailResponse();
  public scoringCriteriaList: ScoringCriteria[] = [];
  public scoringTemplateList: ScoringTemplate[] = [];
  public performanceScoreList: VendorPerformanceScore[] = [];
  public performanceSummaryList: VendorPerformanceSummary[] = [];
  public formReady = false;
  public total = 0;
  public result = 0;
  public value = 0;
  public totalScoring = 0;
  public isScoring = false;
  public procurementScopeWorkId: number;
  public objectId: number;
  public objectName: string;
  public sowPaymentTermId: number;

  public fileUploader: FileUploader = new FileUploader(
    '/performance-fulfillment/',
    'performance-fulfillment',
    this.global.appConstant.fileType.DOC_PERFORMANCE
  );
  public readonly urlSavePerformance = '/performance-fulfillment/insert';

  constructor(translateService: TranslateService) {
    super('performance-fulfillment', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.procurementSowPaymentTermId = this.global.routerParams.get(
      'procurementSowPaymentTermId'
    );
    this.vendorId = this.global.routerParams.get('vendorId');
    this.procurementScopeWorkId = this.global.routerParams.get(
      'procurementScopeWorkId'
    );
    this.objectId = this.global.routerParams.get('objectId');
    this.objectName = this.global.routerParams.get('objectName');
    this.sowPaymentTermId = this.global.routerParams.get('sowPaymentTermId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      file: [null],
      rating: [null],
      performanceScoreSpecificList: this.formBuilder.array([]),
      performanceScoreCommonList: this.formBuilder.array([])
    });
  }

  public setFormGroup(): void {
    const request = new PerformanceFulfillmentEditRequest();
    request.objectId = this.objectId;
    request.objectName = this.objectName;
    request.sowPaymentTermId = this.sowPaymentTermId;
    this.httpClientService
      .post<PerformanceFulfillmentDetailResponse>(
        '/performance-fulfillment/detail',
        request
      )
      .subscribe(pFulfillmentDetailResponse => {
        this.pFulfillmentDetailResponse = pFulfillmentDetailResponse;
        // this.scoringTemplateList = this.pFulfillmentDetailResponse.scoringTemplateList;
        this.performanceScoreList =
          this.pFulfillmentDetailResponse.performanceScoreList;
        this.performanceSummaryList =
          this.pFulfillmentDetailResponse.performanceSummaryList;

        this.performanceSummaryList.forEach(performanceSummary => {
          this.totalScoring += performanceSummary.total * 100;
        });
        this.totalScoring = this.totalScoring / 100;
        this.fileUploader.setFile(
          this.pFulfillmentDetailResponse.performance.file
        );
        this.formGroup.patchValue({
          rating: this.totalScoring,
          file: this.fileUploader.fileObjectList
        });
        this.formGroup.get('rating').setIsView(true);
        this.setViewOnly();
        this.setFormArray();

        this.log.debug('formGroup.controls');
        this.log.debug(this.formGroup.controls);
        this.setStateReady();
      });
  }

  public setFormArray(): void {
    this.pFulfillmentDetailResponse.scoringCriteriaGroupByIsCommonList.forEach(
      scoringCriteriaGroup => {
        const performanceScoreList: VendorPerformanceScore[] = [];
        this.pFulfillmentDetailResponse.performanceScoreList.forEach(
          performanceScore => {
            if (
              performanceScore.scoringCriteriaGroupCode ===
              scoringCriteriaGroup.code
            ) {
              performanceScoreList.push(performanceScore);
            }
          }
        );

        const result =
          this.pFulfillmentDetailResponse.performanceSummaryList.filter(
            performSummary =>
              performSummary.scoringCriteriaGroupCode ===
              scoringCriteriaGroup.code
          )[0].result;

        this.performanceScoreCommonList.push(
          this.formBuilder.group({
            name: scoringCriteriaGroup.name,
            scoringCriteriaGroup,
            totalResult: result, // performance summary yg code group nya sama, ambil result
            scoringCriteriaList:
              this.formGroupService.builder(performanceScoreList)
          })
        );
      }
    );

    this.pFulfillmentDetailResponse.scoringCriteriaGroupByIsSpecificList.forEach(
      scoringCriteriaGroup => {
        const performanceScoreList: VendorPerformanceScore[] = [];

        this.pFulfillmentDetailResponse.performanceScoreList.forEach(
          performanceScore => {
            if (
              performanceScore.scoringCriteriaGroupCode ===
              scoringCriteriaGroup.code
            ) {
              performanceScoreList.push(performanceScore);
            }
          }
        );

        const result =
          this.pFulfillmentDetailResponse.performanceSummaryList.filter(
            performSummary =>
              performSummary.scoringCriteriaGroupCode ===
              scoringCriteriaGroup.code
          )[0].result;

        this.performanceScoreSpecificList.push(
          this.formBuilder.group({
            name: scoringCriteriaGroup.name,
            scoringCriteriaGroup,
            totalResult: result,
            scoringCriteriaList:
              this.formGroupService.builder(performanceScoreList)
          })
        );
      }
    );

    // const performanceScoreCommonList =  this.formGroup.controls.performanceScoreCommonList as FormArray;
    // const scoringCriteriaSpecificList =  performanceScoreCommonList.controls[0].get('scoringCriteriaList') as FormArray;
    this.formReady = true;
  }

  public get performanceScoreCommonList(): FormArray {
    return this.formGroup.get('performanceScoreCommonList') as FormArray;
  }

  public get performanceScoreSpecificList(): FormArray {
    return this.formGroup.get('performanceScoreSpecificList') as FormArray;
  }

  public doNoScoring(): void {
    this.global.alertService.showError('Please make a scoring first');
  }

  public doBack(): void {
    this.global.routerParams.set('vendorId', this.vendorId);
    this.global.routerParams.set('objectId', this.objectId);
    this.global.routerParams.set('objectName', this.objectName);
    this.router.navigate([
      '/pages/performance-fulfillment/detail/detail-termin'
    ]);
  }
}
