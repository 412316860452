import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { OfficerDetailComponent } from './officer-detail.component';
import { OfficerEditAddComponent } from './officer-edit-add.component';
import { OfficerComponent } from './officer.component';
const routes: Routes = [
  { path: '', component: OfficerComponent, data: { breadcrumb: '' } },
  {
    path: 'detail',
    component: OfficerDetailComponent,
    data: { breadcrumb: 'officer.breadcrumb.detail' }
  },
  {
    path: 'edit',
    component: OfficerEditAddComponent,
    data: { breadcrumb: 'officer.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: OfficerEditAddComponent,
    data: { breadcrumb: 'officer.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    OfficerComponent,
    OfficerEditAddComponent,
    OfficerDetailComponent
  ]
})
export class OfficerModule {}
