<app-popup header="registration.title">
  <div class="row main-row">
    <div class="col-12">
      <div class="app-popup-registration-toc" [innerHTML]="termsAndCondition"></div>
    </div>
  </div>
  <div class="row justify-content-center mt-5">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()" [disabled]="formSaving">
      {{ 'app.button.ok' | translate }}
    </button>
  </div>
</app-popup>