<div class="app-attendance-form-card">
  <div
    *ngIf="header || customHeaderTmpl"
    class="form-card-header d-flex"
    [ngClass]="color ? (color | lowercase) : ''"
  >
    <h6 class="title" *ngIf="header"> {{ header | translate }}</h6>
    <ng-container *ngTemplateOutlet="customHeaderTmpl"> </ng-container>
  </div>
  <div class="form-card-body">
    <ng-content> </ng-content>
  </div>
</div>
