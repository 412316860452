import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkPlan } from '../../../bean/work-plan';
import { AppPopupService } from '../app-popup.service';
import { AppPopupWorkPlanItemInfoComponent } from './app-popup-work-plan-item-info.component';
@Injectable()
export class AppPopupWorkPlanItemInfoService {
  constructor(public appPopupService: AppPopupService) {}
  public open(workPlan?: WorkPlan): Observable<any> {
    return this.appPopupService.open(
      AppPopupWorkPlanItemInfoComponent,
      { workPlan },
      { size: 'lg', windowClass: 'modal-custom' }
    );
  }
}
