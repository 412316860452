import { PriceEvaluationMethod } from 'src/app/core/bean/price-evaluation-method';
import { Procurement } from 'src/app/core/bean/procurement';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';

export class PriceEvaluationUpdateRequest {
  public procurement: Procurement;
  public priceEvaluationMethod: PriceEvaluationMethod;
  public appOfficialReportModel: AppOfficialReportModel;
  public isProcurementFailed: boolean;
  public isSubmit: boolean;
}
