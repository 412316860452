import {
  AfterViewChecked,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { AngularTreeModel } from '../../model/angular-tree-model';
import { Response } from '../../model/response-model';
import { ResponseStatusModel } from '../../model/response-status-model';
import { TextUtils } from '../../utils';
import { AppPopupService } from '../app-popup/app-popup.service';
import { TreeEvent } from './events/tree-event';
import { TreeItem } from './interface/tree-item';
import { TreeItemModel } from './model/tree-item-model';
import { TreeModel } from './model/tree-model';
import { TreeProcessItemModel } from './model/tree-process-item-model';
import { TreeProcessModel } from './model/tree-process-model';
import { TreeRequestModel } from './model/tree-request-model';
import {
  AppTreePopupComponent,
  TreePopupResponseModel
} from './popup/app-tree-popup.component';
import { TreeActionType } from './type/tree-action-type';
import { TreeEventType } from './type/tree-event-type';
@Component({
  selector: 'app-tree-x',
  templateUrl: './app-tree-x.component.html',
  styleUrls: ['./app-tree-x.component.scss']
})
export class AppTreeXComponent
  extends BaseComponentComponent
  implements AfterViewChecked, OnDestroy
{
  @Input() public model: TreeModel;
  @Input() public isLazy: boolean;
  @Output() onEventChange: EventEmitter<TreeEvent> = new EventEmitter();
  @Output() onChange: EventEmitter<TreeItemModel | Array<TreeItemModel>> =
    new EventEmitter();
  @ContentChild('treePopupContent') treePopupContentTmpl: TemplateRef<any>;
  @ContentChild('footerButton') footerButtonTmpl: TemplateRef<any>;
  @ContentChild('customText') customTextTmpl: TemplateRef<any>;
  public treePopupResponse: TreePopupResponseModel;
  public generatedId: string = TextUtils.generateRandomString();
  public httpClientRequest: Subscription;
  public previousClickItemTime: number = +Date.now - 1001;
  public originTemp: any;
  timeOut: any;
  readonly SEARCH_INTERVAL = 500; // 0.5s
  public keyword = new FormControl();
  public first = 0; /** for lazy load similar to pagination */
  public rows = 10; /** for lazy load similar to pagination */

  constructor(public appPopupService: AppPopupService) {
    super('app-tree-x');
  }

  ngAfterViewChecked(): void {
    this.handleAppTreeControl();
  }

  ngOnDestroy(): void {
    this.httpClientRequest.unsubscribe();
  }

  public onInit(): void {
    this.handleModelRequestReset();
    this.doLoadTree(
      this.model.parentId ? this.model.parentId : null,
      0,
      null,
      true
    );
  }

  public doLoadTree(
    id: number,
    index: number,
    tempId: number,
    isRoot: boolean
  ): void {
    id === null && tempId !== null
      ? this.doReloadTreeDataTemp(index, tempId)
      : this.doReloadTreeData(id, index, isRoot);
  }

  public doReloadTreeData(
    parentId: number,
    index: number,
    isRoot: boolean
  ): void {
    this.model.removeAt(index);
    this.model.buildTreeItemList();
    this.model.setStateLoading();
    this.first = this.isLazy ? this.first : null;
    this.rows = this.isLazy ? this.rows : null;
    this.httpClientRequest = this.httpClientService
      .post<any[]>(
        '/' + this.model.moduleCode + '/tree/view-child',
        new TreeRequestModel(parentId, null, isRoot, this.first, this.rows)
      )
      .subscribe((angularTreeList: TreeItem[]) => {
        this.doSetItemList(angularTreeList, parentId);
        this.model.setStateReady();
        this.doScrollToRight();
      });
  }

  public onScrollEnd(index: number, event: any): void {
    if (this.isLazy) {
      this.log.debug(event);
      /** index = level */
      let parentId = null;
      if (index !== 0) {
        if (
          this.model.parentId !==
          this.model.treeList[index - 1].getSelectedItem().id
        ) {
          parentId = this.model.treeList[index - 1].getSelectedItem().id;
          this.model.removeAt(index + 1);
          this.model.clearSelectedItemByIndex(index);

          this.first = this.model.treeList[index].treeItemList.length;
        } else {
          this.rows += 10;
        }
      } else {
        this.first += this.rows;
      }

      this.httpClientRequest = this.httpClientService
        .post<any[]>(
          '/' + this.model.moduleCode + '/tree/view-child',
          new TreeRequestModel(
            parentId || this.model.parentId,
            null,
            false,
            this.first,
            this.rows
          )
        )
        .subscribe((angularTreeList: TreeItem[]) => {
          const treeItemList: Array<TreeItemModel> = new Array();
          angularTreeList.forEach((item: TreeItem) => {
            const treeItem: TreeItemModel = new TreeItemModel(item);
            this.model.setIsChecked(treeItem);
            treeItemList.push(treeItem);
          });

            const allTreeItemList = this.model.treeList[index].treeItemList;

            /** prevent duplicate children */
            const childIdList = treeItemList.map(treeItem => treeItem.id);
            childIdList.forEach(childId => {
              const isChildIdExist = !!allTreeItemList
                .map(treeItem => treeItem.id)
                .includes(childId);

              if (!isChildIdExist) {
                allTreeItemList.push(
                  treeItemList.find(item => item.id === childId)
                );
              }
            });

          if (angularTreeList.length === 0) {
            this.first = this.model.treeList[index]?.treeItemList.length;
          }
        });
    }
  }

  public doReloadTreeDataTemp(index: number, tempId: number): void {
    this.model.removeAt(index);
    this.model.setStateLoading();
    this.model.buildTreeItemList();
    const angularTreeList = this.model.treeProcess.treeProcessItemList
      .map((treeProcess: TreeProcessItemModel) => treeProcess.data)
      .filter((data: any) => data.parentId === tempId);
    this.model.setItemList(angularTreeList);
    this.model.setStateReady();
    this.doScrollToRight();
  }

  public doSearch(searchText: string, index: number): void {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.model.removeAt(index + 1);
      this.model.treeList[index].isLoading = true;
      this.model.checkedTreeItemListTemp.splice(index);
      const parentId =
        index === 0
          ? null
          : this.model.treeList[index - 1].getSelectedItem().id;
      this.httpClientRequest = this.httpClientService
        .get<AngularTreeModel[]>(
          '/' +
            this.model.moduleCode +
            '/tree/search-global?keyword=' +
            searchText,
          new TreeRequestModel(parentId, searchText, null)
        )
        .subscribe((angularTreeList: Array<TreeItem>) => {
          this.doSetItemList(angularTreeList, parentId, searchText);
        });
    }, this.SEARCH_INTERVAL);
  }

  public onSearchGlobal(searchText: string): void {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.model.removeAt(1);
      this.model.setStateLoading();
      this.model.treeList[this.model.treeList.length - 1].isLoading = true;
      this.model.checkedTreeItemListTemp.splice(0);
      this.httpClientRequest = this.httpClientService
        .get<AngularTreeModel[]>(
          '/' +
            this.model.moduleCode +
            '/tree/search-global?keyword=' +
            searchText,
          new TreeRequestModel(this.model.treeList.length - 1, searchText, null)
        )
        .subscribe((angularTreeList: Array<TreeItem>) => {
          this.doSetItemList(angularTreeList, this.model.treeList.length - 1, searchText);
          this.model.setStateReady();
        });
    }, this.SEARCH_INTERVAL);
  }

  public doResetKeyword(): void {
    this.keyword.setValue("");
    this.onSearchGlobal(this.keyword.value);
  }

  public doSetItemList(
    angularTreeList: Array<TreeItem>,
    parentId: number,
    searchText = ''
  ): void {
    const angularTreeListOfProcessList = Array.from(
      this.model.treeProcess.treeProcessItemList
    )
      .map((treeProcessItem: TreeProcessItemModel) => treeProcessItem.data)
      .filter(
        (data: any) =>
          data.parentId === parentId &&
          data.name.toLowerCase().includes(searchText.toLocaleLowerCase())
      );
    const idListOfProcessListWithTypeDeleted =
      this.model.treeProcess.treeProcessItemList
        .filter(
          (treeProcessItem: TreeProcessItemModel) =>
            treeProcessItem.type === 'DELETE' &&
            treeProcessItem.data.parentId === parentId &&
            treeProcessItem.data.id
        )
        .map((treeProcess: TreeProcessItemModel) => treeProcess.data.id);
    let combineAngularTreeList = [
      ...angularTreeList,
      ...angularTreeListOfProcessList
    ];
    if (idListOfProcessListWithTypeDeleted.length > 0) {
      combineAngularTreeList = combineAngularTreeList.filter(
        (treeItem: TreeItemModel) =>
          idListOfProcessListWithTypeDeleted.indexOf(treeItem.id) === -1
      );
    }
    this.model.setItemList(combineAngularTreeList);
  }

  public doChecked(event: any, treeItem: TreeItemModel): void {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    if (
      (this.model.onlyLastChild && !treeItem.hasChild) ||
      !this.model.onlyLastChild
    ) {
      treeItem.isChecked = !treeItem.isChecked;
      if (treeItem.isChecked) {
        this.model.checkedTreeItemListTemp.push(treeItem);
      } else {
        const indexOfTreeItem =
          this.model.checkedTreeItemListTemp.indexOf(treeItem);
        this.model.checkedTreeItemListTemp.splice(indexOfTreeItem, 1);
      }
    }
  }

  public doClickTreeItem(data: TreeItemModel, index: number): void {
    this.first = 0;
    const currentTime = Date.now();
    if (
      this.model.mode === 'READ' &&
      !this.model.isMultiple &&
      ((this.model.onlyLastChild && !data.hasChild) ||
        (this.model.onlyLastChild &&
          data.hasChild &&
          this.model.limitLevel &&
          index === this.model.limitLevel) ||
        !this.model.onlyLastChild) &&
      currentTime - this.previousClickItemTime < 1000
    ) {
      this.doSave();
    } else {
      if (
        this.model.mode === 'READ' &&
        this.model.onlyLastChild &&
        data.hasChild &&
        ((this.model.limitLevel && index < this.model.limitLevel) ||
          !this.model.limitLevel) &&
        currentTime - this.previousClickItemTime < 1000
      ) {
        this.global.alertService.showError(
          'app.validation.chooseLastLevel',
          '.app-tree',
          { totalAlert: 1 }
        );
      } else {
        if (
          !this.model.limitLevel ||
          (this.model.limitLevel && index < this.model.limitLevel)
        ) {
          if (data.hasChild) {
            this.doLoadTree(data.id, index + 1, data.tempId, false);
          }
        }
        this.model.clearSelectedItemByIndex(index);
        data.setStateSelected();
        this.previousClickItemTime = currentTime;
      }
    }
  }

  public doAdd(index: number): void {
    this.model.formGroup.reset();
    this.model.setCurrentIndex(index);
    const type = this.global.translateService.instant('app.action.add');
    const header =
      this.global.translateService.instant(this.model.moduleCode + '.title') +
      ' - ' +
      type;
    this.treePopupResponse = new TreePopupResponseModel(
      'ADD',
      this.model.formGroup,
      header,
      this.treePopupContentTmpl
    );
    this.doEmitEvent('ON-SHOW-POPUP', 'ADD');
    this.doShowPopup();
    this.treePopupResponse.setStateReady();
  }

  public doEdit(
    event: MouseEvent,
    data: TreeItemModel,
    currentIndexTree: number,
    currentIndexItemInTree: number
  ): void {
    event.stopPropagation();
    event.preventDefault();
    this.originTemp = data;
    this.model.setCurrentIndex(currentIndexTree, currentIndexItemInTree);
    this.model.formGroup.reset();
    const header =
      this.global.translateService.instant(this.model.moduleCode + '.title') +
      ' - ' +
      this.global.translateService.instant('app.action.edit');
    this.treePopupResponse = new TreePopupResponseModel(
      'EDIT',
      this.model.formGroup,
      header,
      this.treePopupContentTmpl
    );
    data.id === null && data.tempId !== null
      ? this.doEditAngularTreeItemTemp()
      : this.doEditAngularTreeItem();
    this.doShowPopup();
  }

  public doEditAngularTreeItemTemp(): void {
    const currentAngularTreeItem = this.model.getCurrentTreeItem();
    this.model.formGroup.patchValue(currentAngularTreeItem);
    this.doEmitEvent('ON-SHOW-POPUP', 'EDIT', currentAngularTreeItem);
    this.treePopupResponse.setStateReady();
  }

  public doEditAngularTreeItem(): void {
    const currentAngularTreeItem = this.model.getCurrentTreeItem();
    this.httpClientRequest = this.httpClientService
      .post<AngularTreeModel>(
        '/' + this.model.moduleCode + '/tree/edit',
        new TreeRequestModel(currentAngularTreeItem.id, null, null)
      )
      .subscribe((angularTree: AngularTreeModel) => {
        const indexOfAngularTree =
          this.model.treeProcess.treeProcessItemList.findIndex(
            (treeProcess: TreeProcessItemModel) =>
              angularTree.id === treeProcess.data.id
          );
        const newAngularTree =
          indexOfAngularTree !== -1
            ? this.model.treeProcess.treeProcessItemList[indexOfAngularTree]
                .data
            : angularTree;
        this.model.formGroup.patchValue(newAngularTree);
        this.doEmitEvent('ON-SHOW-POPUP', 'EDIT', angularTree);
        this.treePopupResponse.setStateReady();
      });
  }

  public doShowPopup(): void {
    this.appPopupService
      .open(
        AppTreePopupComponent,
        { model: this.treePopupResponse },
        { windowClass: 'app-tree-popup', size: null, centered: false }
      )
      .subscribe((angularTree: AngularTreeModel) => {
        this.doEmitEvent('ON-CLOSE-POPUP', 'CLOSE', angularTree);
        this.doSaveModal();
      });
  }

  public doSaveModal(): void {
    const { value } = this.model.formGroup;
    if (this.treePopupResponse.type === 'ADD') {
      const { currentIndexTree } = this.model;
      const tempId = +TextUtils.generateRandomString(8, '1234567890');
      const angularTree: TreeItemModel = new TreeItemModel({
        ...value,
        level: this.model.currentIndexTree,
        tempId
      });
      const indexOfCurrentParentItem = this.model.getIndexOfCurrentParentItem();
      if (indexOfCurrentParentItem !== -1) {
        const parentItem =
          this.model.treeList[currentIndexTree - 1].treeItemList[
            indexOfCurrentParentItem
          ];
        this.model.treeList[currentIndexTree - 1].treeItemList[
          indexOfCurrentParentItem
        ].setHasChild(true);
        angularTree.setParentId(
          parentItem.id ? parentItem.id : parentItem.tempId
        );
      }
      const treeProcessItem: TreeProcessItemModel = new TreeProcessItemModel(
        angularTree,
        'ADD'
      );
      this.model.treeProcess.addItemToTreeProcessItem(treeProcessItem);
      this.model.addItemToTreeItemList(treeProcessItem.data);
    } else if (this.treePopupResponse.type === 'EDIT') {
      const { currentIndexTree, currentIndexItemInTree } = this.model;
      const angularTree: TreeItemModel = new TreeItemModel({
        ...value,
        level: this.model.currentIndexTree
      });
      const field = value.id !== null ? 'id' : 'tempId';
      const indexOfAngularTree =
        this.model.treeProcess.treeProcessItemList.findIndex(
          (treeProcess: TreeProcessItemModel) =>
            treeProcess.data[field] === value[field]
        );
      angularTree.hasChild =
        this.model.treeList[currentIndexTree].treeItemList[
          currentIndexItemInTree
        ].hasChild;
      angularTree.parentId =
        this.model.treeList[currentIndexTree].treeItemList[
          currentIndexItemInTree
        ].parentId;
      this.model.treeList[currentIndexTree].replace(
        angularTree,
        currentIndexItemInTree
      );
      if (indexOfAngularTree !== -1) {
        this.model.treeProcess.treeProcessItemList[indexOfAngularTree].data =
          value;
      } else {
        const treeProcessItem: TreeProcessItemModel = new TreeProcessItemModel(
          angularTree,
          'EDIT'
        );
        if (angularTree.id) {
          treeProcessItem.origin = this.originTemp;
        }
        this.model.treeProcess.addItemToTreeProcessItem(treeProcessItem);
      }
    }
  }

  public doDelete(
    event: MouseEvent,
    data: any,
    currentIndexTree: number,
    currentIndexItemInTree: number
  ): void {
    event.preventDefault();
    event.stopPropagation();
    this.model.setCurrentIndex(currentIndexTree, currentIndexItemInTree);
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (data.id !== null) {
            const indexOfDataInTreeProcess =
              this.model.treeProcess.treeProcessItemList.findIndex(
                (treeProcess: TreeProcessItemModel) =>
                  treeProcess.data.id === data.id
              );
            if (indexOfDataInTreeProcess !== -1) {
              this.model.treeProcess.treeProcessItemList[
                indexOfDataInTreeProcess
              ].data = data;
              this.model.treeProcess.treeProcessItemList[
                indexOfDataInTreeProcess
              ].type = 'DELETE';
            } else {
              const treeProcessItem: TreeProcessItemModel =
                new TreeProcessItemModel(data, 'DELETE');
              treeProcessItem.origin = data;
              this.model.treeProcess.addItemToTreeProcessItem(treeProcessItem);
            }
            const parentId = this.model.getCurrentTreeItem().parentId;
            this.model.removeCurrentItem();
            if (this.model.treeList[currentIndexTree].length === 0) {
              const indexOfParent =
                this.model.treeList[
                  currentIndexTree - 1
                ].getIndexOfTreeItemById(parentId);
              this.model.treeList[currentIndexTree - 1].treeItemList[
                indexOfParent
              ].setHasChild(false);
              this.model.treeList.splice(currentIndexTree);
            }
          } else {
            const indexOfData = this.model.treeProcess.getIndexOfData(data);
            this.model.treeProcess.removeAt(indexOfData);
            this.model.removeCurrentItem();
            if (this.model.treeList[currentIndexTree].length === 0) {
              const indexOfCurrentParentItem =
                this.model.getIndexOfCurrentParentItem();
              this.model.treeList[currentIndexTree - 1].treeItemList[
                indexOfCurrentParentItem
              ].setHasChild(false);
              this.model.treeList.splice(currentIndexTree);
            }
          }
        }
      });
  }

  public doCancel(): void {
    this.model.reset();
  }

  public doSave(): void {
    if (this.model.mode === 'WRITE') {
      if (this.model.treeProcess.treeProcessItemList.length > 0) {
        this.global.modalService
          .saveConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.httpClientRequest = this.httpClientService
                .post<Response<TreeProcessModel>>(
                  '/' + this.model.moduleCode + '/tree/update',
                  this.model.treeProcess.values
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.global.alertService.showSuccessSaving();
                    this.model.reset();
                    this.onInit();
                  } else {
                    this.model.treeProcess.treeProcessItemList.splice(0);
                    this.global.alertService.showError(response.statusText);
                  }
                });
            }
          });
      }
    } else {
      if (this.model.value.length > 0) {
        this.onChange.emit(this.model.value);
      }
    }
  }

  public doEmitEvent(
    eventType: TreeEventType,
    action: TreeActionType,
    angularTree?: AngularTreeModel
  ): void {
    this.onEventChange.emit({
      type: eventType,
      angularTree,
      formGroup: this.model.formGroup,
      treeProcess: this.model.treeProcess,
      action
    });
  }

  public doScrollToLeft(): void {
    const appTreeBodyElement = document.getElementById(this.generatedId);
    if (appTreeBodyElement) {
      const childrenElement = appTreeBodyElement.children.item(0);
      appTreeBodyElement.scrollTo(
        -(childrenElement.scrollWidth + appTreeBodyElement.scrollLeft),
        0
      );
    }
  }

  public doScrollToRight(): void {
    const appTreeBodyElement = document.getElementById(this.generatedId);
    if (appTreeBodyElement) {
      const childrenElement = appTreeBodyElement.children.item(0);
      appTreeBodyElement.scrollTo(
        childrenElement.scrollWidth + appTreeBodyElement.scrollLeft,
        0
      );
    }
  }

  public handleAppTreeControl(): void {
    const appTreeBodyElement = document.getElementById(this.generatedId);
    const controlLeft = document.getElementById(
      'control-left' + this.generatedId
    );
    const controlRight = document.getElementById(
      'control-right' + this.generatedId
    );
    const maxTreeInTreeBody =
      appTreeBodyElement.offsetWidth /
      appTreeBodyElement.children.item(0).scrollWidth;
    if (appTreeBodyElement.children.length > maxTreeInTreeBody) {
      controlLeft.removeAttribute('style');
      controlRight.removeAttribute('style');
    } else {
      controlLeft.style.display = 'none';
      controlRight.style.display = 'none';
    }
  }

  private handleModelRequestReset(): void {
    this.model.requestReload.subscribe(() => {
      this.doLoadTree(
        this.model.parentId ? this.model.parentId : null,
        0,
        null,
        true
      );
    });
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
