import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTaggingModule } from '../app-tagging/app-tagging.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppChosenComponent } from './app-chosen.component';
@NgModule({
  imports: [
    VendorModule,
    AppTaggingModule,
    AppTooltipWrapperModule,
    PipesModule
  ],
  declarations: [AppChosenComponent],
  exports: [AppChosenComponent]
})
export class AppChosenModule {}
