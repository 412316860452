import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Pq } from '../../core/bean/pq';
import { Translation } from '../../core/bean/translation';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { PqRequest } from './pq-request';
import { PqResponse } from './pq-response';
@Component({
  templateUrl: './pq-edit-add.component.html'
})
export class PqEditAddComponent extends BaseModuleComponent {
  public pqId: number;
  public langList = [];
  public translationListLabel = [];
  public pqResponse: PqResponse = new PqResponse();
  public pqRequest: PqRequest = new PqRequest();
  constructor(translateService: TranslateService) {
    super('pq', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.pqId = this.global.routerParams.get('pqId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      langList: this.formBuilder.array([]),
      translationList: this.formBuilder.array([]),
      translationKey: [null],
      description: [null, Validators.maxLength(512)]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<PqResponse>(
        '/pq/add-edit',
        new RouteRequestModel(this.todo, this.pqId)
      )
      .subscribe((pqResponse: PqResponse) => {
        this.langList = pqResponse.langList;
        this.setTranslation(pqResponse);
        if (pqResponse.pq != null) {
          const { id, code, description, translationKey } = pqResponse.pq;
          this.formGroup.patchValue({
            id,
            code,
            description,
            translationKey
          });
        }
        this.setStateReady();
      });
  }

  public setTranslation(pqResponse: PqResponse): void {
    if (pqResponse.translationList.length > 0) {
      pqResponse.translationList.forEach(translation => {
        this.translationListLabel.push(translation.lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: translation.value
          })
        );
      });
    } else {
      pqResponse.langList.forEach(lang => {
        this.translationListLabel.push(lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: [null, Validators.required()]
          })
        );
      });
    }
  }

  public get translationList(): FormArray {
    return this.formGroup.get('translationList') as FormArray;
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const pq: Pq = this.global.copyFormAttributeToModel(
              new Pq(),
              this.formGroup
            );
            this.pqRequest.pq = pq;
            this.pqRequest.translation = this.formGroup
              .get('translationList')
              .value.map((a: Translation) => a.value);
            const url = this.todo === 'edit' ? '/pq/update' : '/pq/insert';
            this.httpClientService
              .post<Response<PqRequest>>(url, this.pqRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/pq/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/pq/']);
  }
}
