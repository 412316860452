import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { User } from '../../core/bean/user';
import { ToastService } from '../../core/components/app-toast/app-toast.service';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent extends BaseModuleComponent {
  strToken: string;
  errorMsg = null;
  user: User = new User();

  constructor(
    translateService: TranslateService,
    public toastService: ToastService
  ) {
    super('reset-password-confirmation', translateService);
  }

  onInit(): void {
    this.formGroup = this.formBuilder.group({
      email: [
        null,
        Validators.compose([Validators.required(), Validators.email()])
      ]
    });
  }

  doCancel(): void {
    this.router.navigate(['/portal/login/']);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .confirm(
          this.translateService.instant(
            'reset-password-confirmation.msg.sendEmail'
          ),
          'Email Confirmation'
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.user = this.global.copyFormAttributeToModel(
              this.user,
              this.formGroup
            );
            this.httpClientService
              .post<Response<User>>('/reset-password/send', this.user)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.toastService.showSuccess(
                    this.translateService.instant(
                      'reset-password-confirmation.msg.success.sendEmail'
                    )
                  );
                  this.router.navigate(['/portal/login']);
                } else if (response.statusText === 'emailExist') {
                  this.toastService.showError(
                    this.translateService.instant(
                      'reset-password-confirmation.msg.error.emailExist'
                    )
                  );
                } else if (response.statusText === 'emailHasSent') {
                  this.toastService.showError(
                    this.translateService.instant(
                      'reset-password-confirmation.msg.error.emailHasSent'
                    )
                  );
                } else {
                  this.toastService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }
}
