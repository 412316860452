<app-popup
  header="initiation.popup.addNewVendor.title"
  [isLoading]="formLoading"
>
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    *ngIf="!formLoading"
    novalidate
  >
    <app-fullfilled *ngIf="!isView" [isOptional]="true"></app-fullfilled>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'initiation.form.vendorName' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-text-field
          formControlName="name"
          maxLength="255"
          placeholder="{{
            'initiation.placeholder.inputVendorName' | translate
          }}"
          size="LG"
        >
        </app-text-field>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3" *ngIf="vendorView && !vendorView?.isManual">
        {{ 'initiation.form.email' | translate }}
      </label>
      <label
        class="col-sm-3 optional"
        *ngIf="!vendorView || vendorView?.isManual"
      >
        {{ 'initiation.form.email' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-text-field
          formControlName="email"
          maxLength="64"
          size="LG"
          placeholder="{{ 'initiation.placeholder.inputEmail' | translate }}"
        >
        </app-text-field>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3" *ngIf="vendorView && !vendorView?.isManual">
        {{ 'initiation.form.phone' | translate }}
      </label>
      <label
        class="col-sm-3 optional"
        *ngIf="!vendorView || vendorView?.isManual"
      >
        {{ 'initiation.form.phone' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-phone
          size="LG"
          formControlName="phone"
          [optionList]="regionOptionList"
          maxLength="32"
          placeholder="{{
            'initiation.placeholder.inputPhoneNumber' | translate
          }}"
        ></app-phone>
      </div>
    </div>

    <div class="form-group row text-sm-left">
      <label class="col-sm-3" *ngIf="vendorView && !vendorView?.isManual">
        {{ 'initiation.form.pic' | translate }}
      </label>
      <label
        class="col-sm-3 optional"
        *ngIf="!vendorView || vendorView?.isManual"
      >
        {{ 'initiation.form.pic' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="picName"
          maxLength="64"
          placeholder="{{ 'initiation.placeholder.inputName' | translate }}"
        >
        </app-text-field>
      </div>
    </div>

    <div class="form-group row text-sm-left">
      <label class="col-sm-3" *ngIf="vendorView && !vendorView?.isManual">
        {{ 'initiation.form.address' | translate }}
      </label>
      <label
        class="col-sm-3 optional"
        *ngIf="!vendorView || vendorView?.isManual"
      >
        {{ 'initiation.form.address' | translate }}
      </label>
      <div class="col-sm-6 text-left">
        <app-text-area
          formControlName="address"
          maxLength="512"
          size="XL"
          placeholder="{{ 'initiation.placeholder.inputAddress' | translate }}"
        >
        </app-text-area>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3" *ngIf="vendorView && !vendorView?.isManual">
        {{ 'initiation.form.npwp' | translate }}
      </label>
      <label
        class="col-sm-3 optional"
        *ngIf="!vendorView || vendorView?.isManual"
      >
        {{ 'initiation.form.npwp' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-npwp
          size="LG"
          formControlName="npwp"
          placeholder="{{ 'initiation.placeholder.inputNPWP' | translate }}"
        >
        </app-npwp>
      </div>
    </div>

    <h5 class="separator">{{
      'initiation.separator.directPurchaseInfo' | translate
    }}</h5>

    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'initiation.form.alreadyReceived' | translate }}
      </label>
      <div class="col-sm-1 text-left">
        <app-toggle-switch
          formControlName="isReceived"
          (onChange)="setContinueDirectPurchaseToContract()"
          tooltip="{{ 'initiation.tooltip.isReceived' | translate }}"
        ></app-toggle-switch>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'initiation.form.alreadyPaid' | translate }}
      </label>
      <div class="col-sm-1 text-left">
        <app-toggle-switch
          formControlName="isPaid"
          (onChange)="onChangeIsPaid($event)"
          tooltip="{{ 'initiation.tooltip.isPaid' | translate }}"
        ></app-toggle-switch>
      </div>
    </div>
    <div class="form-group row text-sm-left" *ngIf="isContractView">
      <label class="col-sm-3">
        {{ 'initiation.form.isContract' | translate }}
      </label>
      <div class="col-sm-1 text-left">
        <app-toggle-switch
          formControlName="isContract"
          tooltip="{{ 'initiation.tooltip.isContract' | translate }}"
        ></app-toggle-switch>
      </div>
    </div>

    <ng-container *ngIf="formGroup.value.isPaid">
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'initiation.form.invoiceNumber' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="LG"
            formControlName="invoiceNumber"
            placeholder="{{
              'initiation.placeholder.inputInvoiceNumber' | translate
            }}"
            maxLength="32"
          >
          </app-text-field>
        </div>
      </div>

      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'initiation.form.invoiceDate' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          <app-date-picker-x
            size="LG"
            formControlName="invoiceDate"
          ></app-date-picker-x>
        </div>
      </div>

      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'initiation.form.totalBill' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          <app-currency
            size="LG"
            [optionList]="currencyOptionList"
            formControlName="billAmount"
            placeholder="{{ 'initiation.placeholder.inputPrice' | translate }}"
          ></app-currency>
        </div>
      </div>

      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'initiation.form.paymentMethod' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          <app-dropdown-select
            size="LG"
            formControlName="paymentMethod"
            [optionList]="paymentMethodOptionList"
            placeholder="initiation.placeholder.chooseOne"
            (onChange)="onChangePaymentMethod($event)"
          >
            <ng-template #value let-value>
              {{ getTranslateKey(value) | translate }}
            </ng-template>
            <ng-template #option let-data>
              {{ getTranslateKey(data) | translate }}
            </ng-template>
          </app-dropdown-select>
        </div>
      </div>

      <!-- selain CASHIER -->
      <ng-container
        *ngIf="
          !formGroup.value.paymentMethod ||
          formGroup.value.paymentMethod?.code !==
            this.global.appConstant.cm.PAYMENT_METHOD_CASHIER
        "
      >
        <div
          class="form-group row text-sm-left"
          *ngIf="
            !formGroup.value.paymentMethod ||
            formGroup.value.paymentMethod?.code ===
              this.global.appConstant.cm.PAYMENT_METHOD_BANK_TRANSFER
          "
        >
          <label class="col-sm-3">
            {{ 'initiation.form.bankName' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select
              *ngIf="
                vendorBankList &&
                vendorBankList.length > 0 &&
                vendorView &&
                !vendorView.isManual
              "
              size="LG"
              formControlName="bank"
              [optionList]="bankOptionList"
              placeholder="initiation.placeholder.chooseOne"
              (onChange)="onChangeBank($event)"
            >
              <ng-template #value let-value>
                {{ getTranslateKey(value) | translate }}
              </ng-template>
              <ng-template #option let-data>
                {{ getTranslateKey(data) | translate }}
              </ng-template>
            </app-dropdown-select>

            <!-- <app-dropdown-select
              *ngIf="!vendorBankList || vendorBankList.length === 0"
              type="AUTOCOMPLETE"
              formControlName="bank"
              stringUrl="/initiation/auto-complete-bank-list"
            >
            </app-dropdown-select> -->
            <app-auto-complete
              *ngIf="
                !vendorBankList ||
                vendorBankList.length === 0 ||
                (vendorView && vendorView.isManual)
              "
              size="LG"
              formControlName="bank"
              stringUrl="/initiation/auto-complete-bank-list"
            ></app-auto-complete>
          </div>
        </div>

        <div
          class="form-group row text-sm-left"
          *ngIf="
            !formGroup.value.paymentMethod ||
            formGroup.value.paymentMethod?.code ===
              this.global.appConstant.cm.PAYMENT_METHOD_BANK_TRANSFER
          "
        >
          <label class="col-sm-3">
            {{ 'initiation.form.bankAccountName' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-field
              size="LG"
              formControlName="accountHolder"
              maxLength="64"
              placeholder="{{
                'initiation.placeholder.inputBankAccountName' | translate
              }}"
            >
            </app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-left"
          *ngIf="
            !formGroup.value.paymentMethod ||
            formGroup.value.paymentMethod?.code ===
              this.global.appConstant.cm.PAYMENT_METHOD_BANK_TRANSFER
          "
        >
          <label class="col-sm-3">
            {{ 'initiation.form.bankAccountNumber' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-field
              size="LG"
              formControlName="accountNumber"
              maxLength="32"
              placeholder="{{
                'initiation.placeholder.inputBankAccountNumber' | translate
              }}"
            >
            </app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-left"
          *ngIf="
            formGroup.value.paymentMethod?.code ===
            this.global.appConstant.cm.PAYMENT_METHOD_COMPANY_CREDIT_CARD
          "
        >
          <label class="col-sm-3">
            {{ 'initiation.form.creditCardNumber' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-field
              size="LG"
              formControlName="creditCardNumber"
              maxLength="64"
            >
            </app-text-field>
          </div>
        </div>
      </ng-container>

      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'initiation.form.proofOfPayment' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          <app-upload-x
            formControlName="proofDocList"
            [fileUploader]="fileUploader"
            size="XL"
          ></app-upload-x>
        </div>
      </div>
    </ng-container>

    <div class="form-group row text-sm-left">
      <label class="col-sm-3 optional">
        {{ 'initiation.form.note' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-text-area
          formControlName="note"
          size="LG"
          maxLength="512"
        ></app-text-area>
      </div>
    </div>

    <ng-template #modalFooter let-activeModal *ngIf="!isView">
      <div class="col-12 text-center">
        <app-button
          *ngIf="isView"
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.close' | translate }}</app-button
        >
        <app-button
          *ngIf="!isView"
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button *ngIf="!isView" color="PRIMARY" (onClick)="doSave()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
