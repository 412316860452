import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { BudgetSourceEditAddComponent } from './budget-source-edit-add.component';
import { BudgetSourceComponent } from './budget-source.component';

export const routes = [
  { path: '', component: BudgetSourceComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: BudgetSourceEditAddComponent,
    data: { todo: 'edit', breadcrumb: 'budget-source.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: BudgetSourceEditAddComponent,
    data: { todo: 'add', breadcrumb: 'budget-source.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [BudgetSourceComponent, BudgetSourceEditAddComponent],
  providers: []
})
export class BugdetSourceModule {}
