import { Injectable } from '@angular/core';
import * as accordionAction from './actions/accordion-action';
import { dispatchAccordion } from './actions/dispatch-accordion-action';
import { AccordionState } from './domain/accordion-state';
import { AccordionResponseModel } from './model/accordion-response-model';
@Injectable()
export class AppAccordionService {
  private state: AccordionState;
  public setState(
    isShowMultiple: boolean,
    isShowButtonExpandCollapse: boolean,
    isExpandAll: boolean,
    model: AccordionResponseModel<any>,
    isView: boolean
  ): AccordionState {
    this.state = AccordionState.create(
      isShowMultiple,
      isShowButtonExpandCollapse,
      isExpandAll,
      model,
      isView
    );
    return this.state;
  }

  public dispatch(action: accordionAction.AllAccordionAction): void {
    dispatchAccordion(this.state, action);
  }
}
