import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { BudgetAllocation } from 'src/app/core/bean/budget-allocation';
import { BudgetType } from 'src/app/core/bean/budget-type';
import { Coa } from 'src/app/core/bean/coa';
import { CostCenter } from 'src/app/core/bean/cost-center';
import { Currency } from 'src/app/core/bean/currency';
import { Organization } from 'src/app/core/bean/organization';
import { WorkProgram } from 'src/app/core/bean/work-program';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';
import { OrderAddEditResponse } from '../response/order-add-edit.response';

@Component({
  templateUrl: './app-popup-pr-add-budget.component.html'
})
export class AppPopupPrAddBudgetComponent extends BaseComponentComponent {
  @Input() public orderResponse: OrderAddEditResponse;
  @Input() budgetAllocationEdit: any;
  @Input() todo: string;
  @Output() onChange: EventEmitter<BudgetAllocation> = new EventEmitter();
  public activityPeriod: number;
  public budgetTypeOptionList: OptionListModel<BudgetType> =
    new OptionListModel();
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );
  public costCenterOptionList: OptionListModel<CostCenter> =
    new OptionListModel();
  public workProgramOptionList: OptionListModel<WorkProgram> =
    new OptionListModel();
  public coaOptionList: OptionListModel<Coa> = new OptionListModel();

  constructor() {
    super('pr');
  }

  onInit(): void {
    this.setOptionModel();
    this.buildFormGroup();
    this.setFormGroup();
    this.setStateReady();
    this.doDisableCurrency();
  }

  public setOptionModel(): void {
    this.budgetTypeOptionList.setRequestValues(this.orderResponse.budgetTypeList);
    this.costCenterOptionList.setRequestValues(this.orderResponse.costCenterList);
    this.currencyOptionList.setRequestValues(this.orderResponse.currencyList);
    this.workProgramOptionList.setRequestValues(
      this.orderResponse.workProgramList
    );
    this.coaOptionList.setRequestValues(this.orderResponse.coaList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      organizationName: [null, Validators.required()],
      workProgram: [null, Validators.required()],
      budgetType: [null, Validators.required()],
      costCenter: [null, Validators.required()],
      coa: [null, Validators.required()],
      activityPeriod: [null],
      currency: [this.orderResponse.companyCurrency],
      availableBudget: [null],
      organization: [null],
      totalBudget: [null, Validators.required()],
      bookedBudget: [null],
      usedBudget: [null],
      paidOffBudget: [null]
    });
  }

  public setFormGroup(): void {
    if (this.todo !== 'add') {
      this.formGroup.patchValue({
        id: this.budgetAllocationEdit.id,
        organizationName: this.budgetAllocationEdit.organizationName,
        workProgram: this.budgetAllocationEdit.workProgram,
        budgetType: this.budgetAllocationEdit.budgetType,
        costCenter: this.budgetAllocationEdit.costCenter,
        coa: this.budgetAllocationEdit.coa,
        activityPeriod: this.budgetAllocationEdit.activityPeriod,
        currency: this.budgetAllocationEdit.currency,
        availableBudget: this.global.converterService.convertMoney(
          this.budgetAllocationEdit.availableBudget.toString()
        ),
        totalBudget: this.budgetAllocationEdit.totalBudget,
        bookedBudget: this.budgetAllocationEdit.bookedBudget,
        usedBudget: this.budgetAllocationEdit.usedBudget,
        paidOffBudget: this.budgetAllocationEdit.paidOffBudget,
        note: null
      });
    }
  }

  public doDisableCurrency(): void {
    this.formGroup.get('currency').disable();
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      const organizationList: Organization[] =
        this.formGroup.get('organizationName').value;
      this.formGroup
        .get('organization')
        .patchValue(organizationList[organizationList.length - 1]);
      this.formGroup
        .get('activityPeriod')
        .patchValue(this.formGroup.value.workProgram.activityPeriod);
      this.formGroup
        .get('availableBudget')
        .patchValue(this.formGroup.get('totalBudget').value);
      this.onChange.emit(this.formGroup.value);
    }
  }
}
