import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../core/shared/shared.module';
import { EmployeeDetailComponent } from './employee-detail.component';
import { ExampleTabOneComponent } from './tab-one/tab-one.component';
import { ExampleTabThreeComponent } from './tab-three/tab-three.component';
import { ExampleTabTwoComponent } from './tab-two/tab-two.component';

export const routes = [
  {
    path: '',
    component: EmployeeDetailComponent,
    children: [
      { path: '', component: ExampleTabOneComponent },
      {
        path: 'addtional-information',
        component: ExampleTabTwoComponent,
        data: { breadcrumb: 'employee-detail.breadcrumb.additionalInformation' }
      },
      {
        path: 'review',
        component: ExampleTabThreeComponent,
        data: { breadcrumb: 'employee-detail.breadcrumb.review' }
      }
    ]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    EmployeeDetailComponent,
    ExampleTabOneComponent,
    ExampleTabTwoComponent,
    ExampleTabThreeComponent
  ],
  providers: []
})
export class EmployeeDetailModule {}
