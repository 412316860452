import { BaseDTO } from '../../../../core/base/base-dto';
import { CatalogAttribute } from '../../../../core/bean/catalog-attribute';
import { CatalogBeans } from '../../../../core/bean/catalog-beans';
import { VendorAddress } from '../../../../core/bean/vendor-address';
import { VendorSegmentation } from '../../../../core/bean/vendor-segmentation';

export class ViewCatalogDetailDTO extends BaseDTO {

    catalogBeans: CatalogBeans = new CatalogBeans();
    vendorAddress: VendorAddress = new VendorAddress();
    vendorSegmentationList: VendorSegmentation[] = [];
    catalogAttributeList: CatalogAttribute[] = [];
    vendorPICEmails: string;

}
