import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Pq } from 'src/app/core/bean/pq';
import { OptionListModel } from 'src/app/core/model/option-list-model';

@Component({
  templateUrl: 'initiation-popup-choose-pq.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InitiationPopupChoosePqComponent extends BaseComponentComponent {
  @Input() public allPqList: Pq[];
  @Input() public oldPqList: Pq[];
  public pqOptionList: OptionListModel<Pq> = new OptionListModel(true);
  @Output() public onChange: EventEmitter<Pq[]> = new EventEmitter();

  constructor(public translateService: TranslateService) {
    super('initiation');
  }

  public onInit(): void {
    this.pqOptionList.setRequestValues(this.allPqList);

    this.formGroup = this.formBuilder.group({
      pqList: [null]
    });

    this.formGroup.patchValue({
      pqList: this.oldPqList || []
    });

    this.setStateReady();
  }

  public doAddPq(): void {
    this.onChange.emit(this.formGroup.value);
  }
}
