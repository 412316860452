import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from './../../core/shared/shared.module';
import { TabCertificateTDRHistoryComponent } from './tab-certificate-tdr-history.component';
@NgModule({
  imports: [SharedModule],
  declarations: [TabCertificateTDRHistoryComponent],
  entryComponents: [TabCertificateTDRHistoryComponent],
  exports: [TabCertificateTDRHistoryComponent]
})
export class TabCertificateTDRHistoryModule {
  constructor(accordionService: AccordionService) {
    accordionService.register(
      'tab-certificate-tdr-history',
      TabCertificateTDRHistoryComponent
    );
  }
}
