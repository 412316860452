import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Aanwijzing } from 'src/app/core/bean/aanwijzing';
import { Procurement } from 'src/app/core/bean/procurement';
import { ProcurementSchedule } from 'src/app/core/bean/procurement-schedule';
import { TransactionType } from 'src/app/core/bean/transaction-type';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { Validators } from 'src/app/core/validators';
import { AanwijzingSessionRequest } from './request/aanwijzing-session.request';

@Component({
  templateUrl: './aanwijzing-popup-create-session.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AanwijzingPopupCreateSessionComponent extends BaseComponentComponent {
  @Input() public transactionTypeList: TransactionType[];
  @Input() procurement: Procurement;
  @Input() public procurementSchedule: ProcurementSchedule;
  @Input() previousAanwijzing: Aanwijzing;

  @Output() onChange: EventEmitter<Aanwijzing> = new EventEmitter();
  public transactionTypeOptionList: OptionListModel<TransactionType> =
    new OptionListModel(true);
  public maxStartDate: Date;
  public minStartDate: Date;
  public maxEndDate: Date;
  public minEndDate: Date;

  constructor(
    public translateService: TranslateService,
    public toastService: ToastService
  ) {
    super('aanwijzing');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.transactionTypeOptionList.setRequestValues(this.transactionTypeList);
    this.maxStartDate = new Date(this.procurementSchedule?.endDate);
    this.minStartDate = new Date(this.procurementSchedule?.startDate);
    this.maxEndDate = new Date(this.procurementSchedule?.endDate);
    this.minEndDate = new Date(this.procurementSchedule?.startDate);

    this.formGroup = this.formBuilder.group({
      transactionType: [null, Validators.required()],
      periodStartDate: [null, Validators.required()],
      periodEndDate: [null, Validators.required()],
      subject: [null, Validators.required()],
      location: [null] /** if offline then location mandatory */,
      isVendorMandatory: [false],
      isExtendSession: [false],
      maxStartDate: [this.maxStartDate],
      minStartDate: [this.minStartDate],
      maxEndDate: [this.maxEndDate],
      minEndDate: [this.minEndDate]
    });

    if (this.previousAanwijzing) {
      this.minStartDate = new Date(this.previousAanwijzing.periodEndDate);
      this.minEndDate = this.minStartDate;
      this.formGroup.patchValue({
        minStartDate: this.minStartDate,
        isVendorMandatory: this.previousAanwijzing.isVendorMandatory,
        minEndDate: this.minEndDate
      });
    }

    if (this.procurementSchedule?.startDate || this.previousAanwijzing) {
      this.formGroup.patchValue({
        periodStartDate: this.minStartDate,
        minStartDate: this.minStartDate,
        minEndDate: this.minEndDate
      });
    }

    if (this.minStartDate.getTime() < new Date().getTime()) {
      this.minStartDate = new Date();
      this.formGroup.patchValue({
        periodStartDate: this.minStartDate,
        minStartDate: this.minStartDate,
        minEndDate: this.minStartDate
      });
    }
  }

  public onChangeStartDate(startDate: Date): void {
    this.log.debug(startDate);
    this.minEndDate = startDate;
    this.formGroup.patchValue({
      minEndDate: startDate
    });
  }

  public onChangeEndDate(endDate: Date): void {
    this.log.debug(endDate);
    this.maxStartDate = endDate;
    this.formGroup.patchValue({
      maxStartDate: endDate
    });
  }

  public onChangeType(type: TransactionType): void {
    if (
      type &&
      type.code === this.global.appConstant.core.TRANSACTION_TYPE_OFFLINE
    ) {
      this.formGroup.get('location').setValidators([Validators.required()]);
      this.formGroup.get('location').updateValueAndValidity();
    } else {
      this.formGroup.get('location').clearValidators();
      this.formGroup.get('location').updateValueAndValidity();
    }
  }

  public onChangeExtend(event): void {
    this.log.debug(event);
    if (this.formGroup.value.isExtendSession) {
      this.formGroup.patchValue({
        subject: this.previousAanwijzing.subject
      });
    } else {
      this.formGroup.get('subject').reset();
    }
  }

  public doCreate(): void {
    this.validate();
    const periodStartTime = (
      this.formGroup.get('periodStartDate').value as Date
    )?.getTime();
    const periodEndTime = (
      this.formGroup.get('periodEndDate').value as Date
    )?.getTime();

    const isPeriodStartDateBackTime =
      periodStartTime < this.minStartDate.getTime();
    const isPeriodEndDateExceedTime = periodEndTime > this.maxEndDate.getTime();
    const isValid =
      !isPeriodStartDateBackTime &&
      !isPeriodEndDateExceedTime &&
      periodStartTime <= periodEndTime;

    this.log.debug('isPeriodStartDateBackTime: ' + isPeriodStartDateBackTime);
    this.log.debug('isPeriodEndDateExceedTime: ' + isPeriodEndDateExceedTime);

    if (this.formGroup.valid && isValid) {
      const title = 'aanwijzing.confirm.createSession.title';
      const prompt = 'aanwijzing.confirm.createSession.prompt';
      const buttonLeft = 'app.button.cancel';
      const buttonRight = 'aanwijzing.button.createSession';

      this.global.modalService
        .confirmation(prompt, title, buttonLeft, buttonRight, null, true)
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            const aanwijzing: Aanwijzing = this.formGroup.value;
            aanwijzing.procurement = this.procurement;
            aanwijzing.isVendorMandatory =
              aanwijzing.isVendorMandatory || false;

            const aanwijzingRequest = new AanwijzingSessionRequest();
            aanwijzingRequest.aanwijzing = aanwijzing;
            aanwijzingRequest.previousAanwijzing = this.formGroup.get(
              'isExtendSession'
            ).value
              ? this.previousAanwijzing
              : null;

            this.setStateProcessing();
            this.httpClientService
              .post<AanwijzingSessionRequest>(
                '/aanwijzing/insert-session',
                aanwijzingRequest
              )
              .subscribe((response: Response<Aanwijzing>) => {
                this.log.debug(response);

                this.global.routerParams.set('aanwijzing', response.body);
                this.global.routerParams.set('aanwijzingId', response.body.id);
                this.router.navigate(['/pages/aanwijzing/session-room']);
                this.onChange.emit(aanwijzing);
                this.setStateReady();
              });
          }
        });
    } else if (!isValid) {
      this.toastService.showError(
        'aanwijzing.alert.msg.startTimeEndTimeValidate'
      );
      /** Start Time cannot be less than schedule range/previous session, End Time cannot exceed schedule range */
    }
  }
}
