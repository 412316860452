import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Officer } from './../../core/bean/officer';
import { Organization } from './../../core/bean/organization';
@Component({
  templateUrl: './officer-detail.component.html'
})
export class OfficerDetailComponent extends BaseModuleComponent {
  public organization: Organization;
  public tableResponse: TableResponseModel<Officer>;
  constructor(translateService: TranslateService) {
    super('officer', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.doSetOrganizationFromRouterParams();
    this.setStateReady();
  }

  public doSetOrganizationFromRouterParams(): void {
    this.organization = this.global.routerParams.get('organization');
    this.tableResponse.setCustomData(this.organization.id);
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'position.name',
        header: 'table.column.name',
        customClass: 'text-center'
      },
      {
        field: 'user.name',
        header: 'table.column.user',
        customClass: 'text-center'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.global.routerParams.set('organization', this.organization);
    this.router.navigate(['/pages/officer/add']);
  }

  public doEdit(officer: Officer): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('organization', this.organization);
    this.global.routerParams.set('officerId', officer.id);
    this.router.navigate(['/pages/officer/edit']);
  }

  public doDelete(officer: Officer): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<Officer>>('/officer/delete', officer)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/officer']);
  }
}
