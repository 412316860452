<app-layout
  [isLoading]="formLoading"
  header="initiation.catalogDetail.title"
  [isShowBreadcrumbs]="false"
>
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    novalidate
    *ngIf="!formLoading"
  >
    <div class="row main-row">
      <div class="col-12">
        <div class="form-group text-sm-left">
          <span *ngFor="let itemCategory of itemCategoryList; let i = index">
            <span [hidden]="i === itemCategoryList.length - 1">
              {{ itemCategory.name }} <em class="pir pi-chevron-right"></em>
            </span>
            <span
              [hidden]="i !== itemCategoryList.length - 1"
              style="color: 'var(--primary-dark)'"
            >
              {{ itemCategory.name }}
            </span>
          </span>
        </div>
        <app-card [isLoading]="formLoading" [isSaving]="formSaving">
          <app-catalog-detail-info
            [catalogId]="catalogId"
            (onChange)="doChangeCatalog($event)"
          >
          </app-catalog-detail-info>
        </app-card>
      </div>
    </div>
    <ng-template #contentSidebarRight>
      <div
        class="row"
        *ngIf="!formLoading"
        style="display: flex; place-content: center; margin: 15px 0 20px"
      >
        <div class="col-sm-12" style="top: 30px">
          <app-card>
            <h5>{{ 'initiation.label.requestQuantiy' | translate }}</h5>
            <br />
            <div class="form-group row text-sm-left">
              <div class="col-sm-12 text-left">
                <app-count
                  formControlName="quantity"
                  maxLength="6"
                  (onChange)="onChangeQuantity()"
                  (input)="onChangeQuantity()"
                  [min]="1"
                  size="XL"
                ></app-count>
              </div>
            </div>
            <div class="row">
              <div
                class="col-sm-2 text-left"
                style="color: var(--gray-500); font-size: 12px"
              >
                <label>{{ 'initiation.form.subTotal' | translate }}</label>
              </div>
              <div class="col-sm-10 text-right">
                <h6>
                  {{
                    formGroup.value.totalPrice
                      ? global.converterService.convertMoney(
                          formGroup.value.totalPrice
                        )
                      : '0'
                  }}
                  {{ prResponse.companyCurrency.code }}</h6
                >
              </div></div
            >
            <div class="row">
              <div class="col-sm-12 button-process-to-pr">
                <app-button
                  (onClick)="addToItemRequest()"
                  [disabled]="formSaving"
                  >
                  <span class="white-space-normal">
                    {{ 'initiation.button.addItemRequest' | translate }}
                  </span>
                </app-button>
              </div>
            </div>
          </app-card>
        </div>
      </div>
    </ng-template>
  </form>
  <app-similar-product [catalogId]="catalogId"></app-similar-product>
  <ng-container *ngIf="model && model.getPageRecords().length > 0">
    <div *ngIf="model && model.getPageRecords().length > 0" class="mt-5">
      <h4>{{ 'initiation.lastPurchase.title' | translate }}</h4>
      <div class="float-right mb-3">
        <app-button
          color="SECONDARY"
          size="TN"
          (onClick)="onPageChangeLeft($event.target.value)"
          [ngStyle]="{ 'padding-right': '10px' }"
          [disabled]="+model.pagination.page === 1"
        >
          <em class="pir pi-chevron-left"></em>
        </app-button>
        <app-button
          color="PRIMARY"
          size="TN"
          (onClick)="onPageChangeRight($event.target.value)"
          [ngStyle]="{ 'padding-right': '10px' }"
          [disabled]="formGroup.get('isMaxPage').value"
        >
          <em class="pir pi-chevron-right"></em>
        </app-button>
      </div>
    </div>
    <app-catalog-item-x [customModel]="model"></app-catalog-item-x>
  </ng-container>
</app-layout>
