<app-popup
  header="procurement-reg-vendor.popup.criteriaProcurement"
  [isLoading]="formLoading"
>
  <form
    class="form-horizontal popup-choose-budget"
    [formGroup]="formGroup"
    novalidate
    *ngIf="!formLoading"
  >
    <app-alert-x [isBold]="false">{{
      'procurement-reg-vendor.popup.alert.info' | translate
    }}</app-alert-x>

    <div class="popup-criteria-filter">
      <div class="form-group row text-sm-left">
        <label class="col-sm-4 row">
          <div class="col-sm-2 text-center icon-criteria">
            <em
              class="pir pi-check-circle"
              *ngIf="checkedValue.isRegionMeet"
            ></em>
          </div>
          <div class="col-sm-10 text-left">
            {{ 'procurement-reg-vendor.popup.form.vendorLocation' | translate }}
          </div>
        </label>
        <div class="col-sm-8 text-left">
          <app-chosen
            *ngIf="regionList.length !== 0"
            formControlName="regionList"
            [optionList]="regionOptionList"
            container="body"
            size="XL"
          ></app-chosen>
          <p *ngIf="regionList.length === 0">{{ '-' }}</p>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-4 row">
          <div class="col-sm-2 text-center icon-criteria">
            <em class="pir pi-check-circle" *ngIf="checkedValue.isRatingMeet">
            </em>
          </div>
          <div class="col-sm-10 text-left">
            {{ 'procurement-reg-vendor.popup.form.performance' | translate }}
          </div>
        </label>
        <div class="col-sm-8 text-left">
          <app-rating
            formControlName="rating"
            [isViewOnly]="true"
            size="LG"
          ></app-rating>
        </div>
      </div>

      <div class="form-group row text-sm-left">
        <label class="col-sm-4 row">
          <div class="col-sm-2 text-center icon-criteria">
            <em
              class="pir pi-check-circle"
              *ngIf="checkedValue.isContractValueMeet"
            >
            </em>
          </div>
          <div class="col-sm-10 text-left">
            {{
              'procurement-reg-vendor.popup.form.minimumContractValue'
                | translate
            }}
          </div>
        </label>
        <div class="col-sm-8 text-left">
          <app-currency
            formControlName="contractValue"
            [optionList]="currencyOptionList"
            size="LG"
          ></app-currency>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-4 row">
          <div class="col-sm-2 text-center icon-criteria">
            <em
              class="pir pi-check-circle"
              *ngIf="checkedValue.isTotalContractMeet"
            >
            </em>
          </div>
          <div class="col-sm-10 text-left">
            {{ 'procurement-reg-vendor.popup.form.totalContract' | translate }}
          </div>
        </label>
        <div class="col-sm-8 text-left">
          {{ formGroup.controls.totalContract.value }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-4 row">
          <div class="col-sm-2 text-center icon-criteria">
            <em
              class="pir pi-check-circle"
              *ngIf="checkedValue.isContractDateMeet"
            >
            </em>
          </div>
          <div class="col-sm-10 text-left">
            {{
              'procurement-reg-vendor.popup.form.vendorRangeExperience'
                | translate
            }}
          </div>
        </label>
        <div class="col-sm-8 text-left">
          <p
            *ngIf="
              formGroup.controls.contractDate.value.from &&
              formGroup.controls.contractDate.value.from
            "
            >{{
              (formGroup.controls.contractDate.value.from
                | date: 'dd/MM/YYYY') +
                ' - ' +
                (formGroup.controls.contractDate.value.to | date: 'dd/MM/YYYY')
            }}</p
          >
          <p
            *ngIf="
              !formGroup.controls.contractDate.value.from ||
              !formGroup.controls.contractDate.value.from
            "
            >{{ '-' }}</p
          >
        </div>
      </div>
    </div>
  </form>
  <app-table-xx
    [model]="tableResponse"
    [isView]="true"
    [isShowSearch]="false"
    [isShowPagination]="false"
  >
  </app-table-xx>

  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button color="PRIMARY" (onClick)="goToProfile()">{{
        'procurement-reg-vendor.popup.button.goToProfile' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
