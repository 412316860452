import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { BankAccount } from '../../core/bean/bank-account';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { AppTableComponent } from './../../core/components/app-table/app-table.component';
import { Response } from './../../core/model/response-model';
import { ResponseStatusModel } from './../../core/model/response-status-model';

@Component({
  templateUrl: './bank-account.component.html'
})
export class BankAccountComponent
  extends BaseModuleComponent
  implements OnInit {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<BankAccount>;
  constructor(translateService: TranslateService) {
    super('bank-account', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.column.code' },
      { field: 'bank.name', header: 'table.column.bank' },
      {
        field: 'accountNumber',
        header: 'table.column.accountNumber',
        customClass: 'text-center'
      },
      { field: 'accountHolder', header: 'table.column.accountHolder' },
      { field: 'branch', header: 'table.column.branch' },
      {
        field: 'description',
        header: 'table.column.description',
        customClass: 'white-space-normal'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/bank-account/add']);
  }

  public doEdit(bankAccount: BankAccount): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('bankAccountId', bankAccount.id);
    this.router.navigate(['/pages/bank-account/edit']);
  }

  public doDelete(bankAccount: BankAccount): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<BankAccount>>('/bank-account/delete', bankAccount)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
