import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Qualification } from '../../core/bean/qualification';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { QualificationResponse } from './qualification-response';
@Component({
  templateUrl: './qualification-edit-add.component.html'
})
export class QualificationEditAddComponent extends BaseModuleComponent {
  public qualificationResponse: QualificationResponse = new QualificationResponse();
  public qualificationId: number;
  public valueList: OptionListModel<any> = new OptionListModel(false);

  constructor(translateService: TranslateService) {
    super('qualification', translateService);
  }

  public onInit(): void {
    this.setValueList();
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.qualificationId = this.global.routerParams.get('qualificationId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      value: [null, Validators.required()],
      level: [null, Validators.required()],
      description: [null, Validators.maxLength(512)]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<QualificationResponse>(
        '/qualification/add-edit',
        new RouteRequestModel(this.todo, this.qualificationId)
      )
      .subscribe(qualificationResponse => {
        this.qualificationResponse = qualificationResponse;
        if (this.qualificationResponse.qualification != null) {
          const {
            id,
            code,
            name,
            value,
            level,
            description
          } = this.qualificationResponse.qualification;
          this.formGroup.patchValue({
            id,
            code,
            name,
            value: this.valueList
              .getRequestValues()
              .filter(valueOption => valueOption.value === value)[0],
            level,
            description
          });
        }
        this.setStateReady();
      });
  }

  public setValueList(): void {
    const valueList: any[] = [
      { name: '0', value: 0 },
      { name: '1', value: 1 },
      { name: '2', value: 2 },
      { name: '3', value: 3 },
      { name: '4', value: 4 },
      { name: '5', value: 5 }
    ];

    this.valueList.setRequestValues(valueList);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const qualification: Qualification = this.global.copyFormAttributeToModel(
              new Qualification(),
              this.formGroup
            );
            qualification.value = this.formGroup.value.value.value;

            const url =
              this.todo === 'edit'
                ? '/qualification/update'
                : '/qualification/insert';
            this.httpClientService
              .post<Response<Qualification>>(url, qualification)
              .subscribe((response: Response<Qualification>) => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/qualification']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/qualification']);
  }
}
