import { BaseDTO } from "../../core/base/base-dto";
import { Vendor } from "../../core/bean/vendor";
import { VendorType } from "../../core/bean/vendor-type";
import { FileType } from "../../core/bean/file-type";
import { VendorDoc } from "../../core/bean/vendor-doc";
import { VendorAdditionalDoc } from "../../core/bean/vendor-additional-doc";

export class VendorExpiredDocumentResponse extends BaseDTO {
    vendor: Vendor;
    vendorTypeList: VendorType[];
    docList: FileType[];
    vendorDocList: VendorDoc[];
    vendorAdditionalList: VendorAdditionalDoc[];
}