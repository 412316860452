<app-popup 
  header="rfp.popup.addProposalCriteria" 
  [isLoading]="formLoading"     
  [isShowHeaderCloseButton]="false"
>
  <form 
    class="form-horizontal popup-proposal-criteria" 
    [formGroup]="formGroup" 
    novalidate
  >
    <app-select
      [optionList]="proposalCriteriaOptionList"
      [isMultiple]="true"
      [search]="true"
      formControlName="proposalCriteria"
      isShowBorder="true"
      selectAllText="{{'rfp.checkAll' | translate }}"
    >
    </app-select>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button color="LIGHT" (onClick)="activeModal.close(true)">{{
          'app.button.cancel' | translate }}</app-button>
        <app-button color="PRIMARY" (onClick)="doSubmit()">{{ 'app.button.add'
          | translate }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>