<app-popup header="tab-bank.popup.title" [isLoading]="formLoading">
  <form class="form-horizontal">
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-bank.form.popup.bankName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorBankHistory.bank.name || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-bank.form.popup.country' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorBankHistory.countryRegion?.name || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-bank.form.popup.branch' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorBankHistory.branch || '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-bank.form.popup.address' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        {{ vendorBankHistory.address || '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-bank.form.popup.coordinateLocation' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        {{ vendorBankHistory.coordinateLocation || '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-bank.form.popup.city' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorBankHistory.cityRegion?.name || '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-bank.form.popup.accountNumber' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorBankHistory.accountNumber || '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-bank.form.popup.accountHolder' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorBankHistory.accountHolder || '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-bank.form.popup.currency' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ vendorBankHistory.currency.code || '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-bank.form.popup.priority' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ isPriority }}
      </div>
    </div>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
