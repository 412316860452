import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../../core/guard/confirm.guard';
import { SharedModule } from '../../../core/shared/shared.module';
import { RegistrationDocumentEditAddComponent } from './registration-document-edit-add.component';
const routes = [
  {
    path: '',
    component: RegistrationDocumentEditAddComponent,
    data: { breadcrumb: 'registration-document.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [RegistrationDocumentEditAddComponent]
})
export class RegistrationDocumentModule {}
