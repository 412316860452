import { Component, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Bank } from '../../core/bean/bank';
import { VendorBankHistory } from '../../core/bean/vendor-bank-history';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { AppPopupTabBankComponent } from './app-popup-tab-bank.component';
@Component({
  templateUrl: './tab-bank.component.html'
})
export class TabBankComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<Bank>;
  public tableResponse: TableResponseModel<VendorBankHistory>;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('tab-bank', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setCustomData();
    this.model.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'bank.name', header: 'table.column.bankName' },
      { field: 'branch', header: 'table.column.branch' },
      {
        field: 'accountNumber',
        header: 'table.column.accNo',
        customClass: 'text-center'
      },
      { field: 'accountHolder', header: 'table.column.accHolder' },
      {
        field: 'currency.code',
        header: 'table.column.currency',
        customClass: 'text-center'
      },
      {
        field: 'isPriority',
        header: 'table.column.paymentPriority',
        datamap: '{"true" : "Yes" , "false" : "No" }',
        customClass: 'text-center'
      }
    ]);
  }

  public setCustomData(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponse.setCustomData(customData);
  }

  public doDetail(vendorBankHistory: VendorBankHistory): void {
    this.appPopupService.open(
      AppPopupTabBankComponent,
      { vendorBankHistory },
      { size: 'lg', backdrop: 'static' }
    );
  }
}
