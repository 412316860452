import { BaseEntity } from "../base/base-entity";
import { AccidentVictimStatus } from "../bean/accident-victim-status";
import { Claim } from "../bean/claim";
import { Gender } from "../bean/gender";
import { VictimCondition } from "../bean/victim-condition";
import { VictimDiagnose } from "../bean/victim-diagnose";
import { VictimInjuries } from "../bean/victim-injuries";


export class AccidentVictimEntity extends BaseEntity{
  claim : Claim;
  doctorName : string;
  name: string;
  description: string;
  age : number;
  gender : Gender;
  address : string;
  victimCondition : VictimCondition;
  victimDiagnose: VictimDiagnose;
  diagnoseOther : string;
  diagnoseOtherDiseases: string;
  accidentVictimStatus : AccidentVictimStatus;
  victimInjuriesCategory : VictimInjuries;
}