import { BaseEntity } from '../base/base-entity';
import { Claim } from '../bean/claim';
import { ClaimStatus } from '../bean/claim-status';
import { User } from '../bean/user';

export class ClaimVerificationEntity extends BaseEntity {
  claim: Claim;
  processedDate: Date;
  processedByUser: User;
  claimStatus: ClaimStatus;
  note: string;
}
