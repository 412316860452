import { FormGroup } from '@angular/forms';
import { TabResponseModel } from './tab-response-model';
export class TabModel {
  public get isFormGroupReady(): boolean {
    return Object.keys(this.formGroup.controls).length > 0;
  }

  public get value(): any {
    return this.formGroup.value;
  }
  name: any;
  routeUrl: string;
  nextTab: TabModel;
  nextUrl: string;
  previousTab: TabModel;
  previousUrl: string;
  code: string;
  baseUrl: string;
  routerParams: Map<string, any> = new Map();
  formGroup: FormGroup = new FormGroup({});
  isFirstTab = false;
  isLastTab = false;
  position: number;
  source: any;
  parent: TabResponseModel;
  disabled = false;
  public static create(
    parent: TabResponseModel,
    tab: any,
    position: number
  ): TabModel {
    const tabModel = new TabModel(parent, tab, position);
    tabModel.setTabName();
    tabModel.setIsFirstTab();
    tabModel.setIsLastTab();
    tabModel.setBaseUrl();
    tabModel.setRouteUrl(tab.routeUrl);
    tabModel.setTabCode(tab.code);
    return tabModel;
  }

  constructor(parent: TabResponseModel, tab: any, position: number) {
    this.parent = parent;
    this.source = tab;
    this.position = position;
  }

  public setTabName(): void {
    this.name = this.source[this.parent.namePath];
    if (
      this.name.id !== undefined &&
      this.parent.namePath === 'translationKey'
    ) {
      const code: string = this.name.module.code;
      this.name = code.toLowerCase() + '.' + this.name.key;
    }
  }

  public setIsFirstTab(): void {
    if (this.position - 1 === 0) {
      this.isFirstTab = true;
    }
  }

  public setIsLastTab(): void {
    const tabsLength = this.parent.source.length;
    if (this.position - 1 === tabsLength - 1) {
      this.isLastTab = true;
    }
  }

  public setTabCode(tabCode: string): void {
    this.code = tabCode;
  }

  public setBaseUrl(): void {
    this.baseUrl = this.parent.baseUrl;
  }

  public setRouteUrl(routeUrl: string): void {
    this.routeUrl = this.baseUrl + (routeUrl ? '/' + routeUrl : '');
  }

  public setPreviousTabRouterUrl(
    tabModelList: Map<string, TabModel>,
    tabs: any,
    currentIndex: number
  ): void {
    if (currentIndex > 0) {
      const previousTab: TabModel = tabModelList.get(
        tabs[currentIndex - 1].code
      );
      this.previousTab = previousTab;
      this.previousUrl = previousTab.routeUrl;
    }
  }

  public setNextTabRouterUrl(
    tabModelList: Map<string, TabModel>,
    tabs: any,
    currentIndex: number
  ): void {
    const tabsLength: number = tabs.length;
    if (currentIndex < tabsLength - 1) {
      const nextTab: TabModel = tabModelList.get(tabs[currentIndex + 1].code);
      this.nextTab = nextTab;
      this.nextUrl = nextTab.routeUrl;
    }
  }

  public setIsView(isView: boolean): void {
    this.formGroup.setIsView(isView);
  }

  public disable(): void {
    this.disabled = true;
  }

  public enable(): void {
    this.disabled = false;
  }

  public setIsDisabled(disabled: boolean): void {
    disabled ? this.formGroup.disable() : this.formGroup.enable();
  }
}
