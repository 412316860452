import { BaseEntity } from '../base/base-entity';
import { Rfi } from '../bean/rfi';
import { Uom } from '../bean/uom';

export class RfiItemEntity extends BaseEntity {
    quantityUom: string;

    uom: Uom;
    rfi: Rfi;
    item: string;
    description: string;
    quantity: number;
}
