<div
  class="row registration-confirmation form-registration m-4"
  style="font-family: Roboto; font-size: 14px"
>
  <app-card [isLoading]="formLoading">
    <ng-container
      *ngIf="
        aanwijzingParticipant?.crudOperation !==
          global.appConstant.core.CRUD_OPERATION_DELETE &&
        aanwijzing?.aanwijzingStatus?.code ===
          this.global.appConstant.pm.AANWIJZING_STATUS_NOT_STARTED
      "
    >
      <!-- sesi belum start -->
      <div
        class="text-center pvc pv-sandglass"
        style="margin: 0 auto; width: 150px; height: 400px"
      ></div>
      <h3 class="text-center">{{
        'aanwijzing-others.page.title.pleaseWaitSessionWillStartAt' | translate
      }}</h3>
      <div class="text-center">
        {{
          'aanwijzing-others.page.msg.pleaseWaitSessionWillStartAt' | translate
        }}
        {{
          aanwijzing?.periodStartDate
            | date: global.config.parameterModel.datetimeFormatShort
        }}
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        aanwijzingParticipant?.isBlocked ||
        !aanwijzingParticipant?.isOnline ||
        !aanwijzingParticipant ||
        aanwijzingParticipant?.crudOperation ===
          global.appConstant.core.CRUD_OPERATION_DELETE ||
        aanwijzing.aanwijzingStatus?.code ===
          global.appConstant.pm.AANWIJZING_STATUS_CLOSED ||
        aanwijzing.aanwijzingStatus?.code ===
          global.appConstant.pm.AANWIJZING_STATUS_DONE ||
        aanwijzing.aanwijzingStatus?.code ===
          global.appConstant.pm.AANWIJZING_STATUS_EXTENDED
      "
    >
      <!-- sesi expired (block/kick/deleted/ session closed) -->
      <div
        class="text-center pvc pv-connection-triangle"
        style="margin: 0 auto; width: 150px; height: 400px"
      ></div>
      <h3 class="text-center">{{
        'aanwijzing-others.page.title.expiredSession' | translate
      }}</h3>
      <div class="text-center">
        {{ 'aanwijzing-others.page.msg.expiredSession' | translate }}
      </div>
      <div class="button-group button-group-center mt-3">
        <app-button (onClick)="doGoToHome()">{{
          'aanwijzing-others.button.goToHomePage' | translate
        }}</app-button>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        aanwijzing?.aanwijzingStatus?.code ===
        this.global.appConstant.pm.AANWIJZING_STATUS_CANCELED
      "
    >
      <!-- sesi canceled -->
      <div
        class="text-center pvc pv-clock-times"
        style="margin: 0 auto; width: 150px; height: 400px"
      ></div>
      <h3 class="text-center">{{
        'aanwijzing-others.page.title.canceledSession' | translate
      }}</h3>
      <div class="text-center">
        {{ 'aanwijzing-others.page.msg.canceledSession' | translate }}
      </div>
      <div class="button-group button-group-center mt-3">
        <app-button (onClick)="doGoToHome()">{{
          'aanwijzing-others.button.goToHomePage' | translate
        }}</app-button>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        showStatusList.includes(aanwijzing?.aanwijzingStatus?.code) &&
        !aanwijzingParticipant?.isBlocked &&
        aanwijzingParticipant?.isOnline
      "
    >
      <h2 class="separator"
        >{{ 'aanwijzing.breadcrumb.index' | translate }}
        <div class="text-secondary">{{ aanwijzing?.code }}</div>
      </h2>

      <div class="row main-row">
        <div class="col-lg-10">
          <app-card *ngIf="!formLoading">
            <h3 class="separator">{{
              'aanwijzing.details.title' | translate
            }}</h3>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{ 'aanwijzing.form.sessionNumber' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                {{ aanwijzing?.code }}
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{ 'aanwijzing.form.aanwijzingType' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                {{ getTranslateKey(aanwijzing?.transactionType) | translate }}
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{ 'aanwijzing.form.sessionPeriod' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                {{
                  aanwijzing?.periodStartDate
                    | date: global.config.parameterModel.datetimeFormatShort
                }}
                {{ 'aanwijzing.form.until' | translate }}
                {{
                  aanwijzing?.periodEndDate
                    | date: global.config.parameterModel.datetimeFormatShort
                }}
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{ 'aanwijzing.form.subject' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                {{ aanwijzing?.subject }}
              </div>
            </div>
          </app-card>
        </div>

        <div class="col-lg-2">
          <app-card>
            <app-session-timer
              [isShowActionButton]="false"
              [startDate]="aanwijzing?.sessionStartTime"
              [endDate]="aanwijzing?.sessionEndTime"
            >
            </app-session-timer>
          </app-card>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <app-card>
            <app-chat
              [roomCode]="aanwijzing?.roomCode"
              stringUrl="/aanwijzing-others"
              [currentParticipant]="aanwijzingParticipant"
              [sessionStatus]="aanwijzing?.aanwijzingStatus?.code"
            ></app-chat>
          </app-card>
        </div>
      </div>
    </ng-container>
  </app-card>
</div>
