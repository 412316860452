import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppTextFieldComponent } from './app-text-field.component';
import { AppTextFieldDirective } from './app-text-field.directive';
@NgModule({
  imports: [VendorModule, AppTooltipWrapperModule],
  declarations: [AppTextFieldComponent, AppTextFieldDirective],
  entryComponents: [AppTextFieldComponent],
  exports: [AppTextFieldComponent, AppTextFieldDirective]
})
export class AppTextFieldModule {}
