<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-x
        [model]="tableResponse"
        stringUrl="/order-response/index"
        (onClick)="doClick($event)"
        header="order-response.table.header"
      >
        <ng-template #headerFilterGroup>
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            *ngIf="!formLoading"
            novalidate
          >
            <div class="form-group row text-sm-left">
              <div class="col-sm-12 text-left">
                <div class="form-row px-1">
                  <div class="form-group row px-4" style="align-items: center">
                    <label class="pr-4" style="margin-top: auto">{{
                      'order-response.form.filter.postDate' | translate
                    }}</label>
                    <div class="text-left">
                      <app-date-picker-x
                        size="XL"
                        formControlName="postDate"
                        [range]="true"
                        (onChange)="onChangeFilter()"
                      >
                      </app-date-picker-x>
                    </div>
                  </div>
                  <div class="form-group row px-4" style="align-items: center">
                    <label class="pr-4" style="margin-top: auto">{{
                      'order-response.form.filter.requiredDate' | translate
                    }}</label>
                    <div class="text-left">
                      <app-date-picker-x
                        size="XL"
                        formControlName="requiredDate"
                        [range]="true"
                        (onChange)="onChangeFilter()"
                      >
                      </app-date-picker-x>
                    </div>
                  </div>
                  <div class="form-group row px-4 col-sm-3">
                    <app-dropdown-select
                      style="width: 100%"
                      size="XL"
                      [optionList]="orderStatusOptionList"
                      formControlName="statusList"
                      showLimitValue="2"
                      type="CHOSEN"
                      placeholder="{{
                        'order-response.form.placeholder.status' | translate
                      }}
                        "
                      (onChange)="onChangeFilter()"
                    >
                      <ng-template #selectAll>
                        {{
                          'order-response.form.placeholder.allStatus'
                            | translate
                        }}
                      </ng-template>
                    </app-dropdown-select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            (onClick)="doExport(rowData)"
            title="{{ 'app.tooltip.export' | translate }}"
            *ngIf="!isNotEmpty"
          >
            <em class="fas"
            [ngClass]="
              !formLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'
            "></em>
            {{ 'app.button.export' | translate }}
          </app-button>
        </ng-template>
      </app-table-x>
    </div>
  </div>
</app-layout>
