<app-popup
  [isShowHeaderCloseButton]="
    (!isLoading && !isSaving) || (model && model.message)
  "
  header="app-popup-import.title"
>
  <form>
    <div class="form-group">
      <div class="main-upload">
        <input
          type="file"
          class="form-control"
          placeholder="Choose a file..."
          aria-label="uploadedFileName"
          (change)="doUpload($event)"
          [disabled]="(isLoading || isSaving) && (!model || !model.message)"
        />
      </div>
      <br>
      <small id="templateFile" class="form-text text-muted">
        <a
          *ngIf="isTemplateExists; else templateIsNotExists"
          href="#"
          (click)="
            doDownload(
              $event,
              global.config.BACKEND_ADDRESS +
                '/' +
                urlModuleCode +
                '/template/view',
              urlModuleCode[0].toUpperCase() +
                urlModuleCode.substr(1) +
                ' Import Template.xlsx'
            )
          "
          >
          <br>{{ 'app-popup-import.label.downloadTemplate' | translate }} : {{ 'app-popup-import.msg.downloadTemplate' | translate }}</a
        >
        <ng-template #templateIsNotExists>
          <span class="text-danger">{{
            'app-popup-import.msg.noTemplate' | translate
          }}</span>
        </ng-template>
        <span
          *ngIf="errors"
          class="text-danger"
          [ngStyle]="{ 'margin-left': '10px' }"
          >{{ errors.label | translate }} {{ errors.text }}</span
        >
      </small>
    </div>
  </form>

  <div class="row" *ngIf="isLoading && !model.message">
    <div class="col-sm-12 text-center"
      >{{ 'app-popup-import.sync.pleaseWait' | translate }}
    </div>
  </div>
  <div class="row" *ngIf="isLoading && model.message">
    <div class="col-sm-12 text-center text-danger">
      {{ model.message }}
    </div>
  </div>

  <div class="row" *ngIf="isSaving && !isLoading">
    <div class="col-sm-12 text-center">
      <img
        src="assets/img/common/loader-small.gif"
        alt="Loading"
        height="8"
        width="30"
      />
    </div>
  </div>

  <div class="w-50 mx-auto">
    <ngb-progressbar
      type="success"
      textType="white"
      [value]="model.progressValue"
      [showValue]="true"
      *ngIf="isLoading && model.progressValue"
    >
    </ngb-progressbar>
  </div>

  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center">
      <app-button
        *ngIf="!isLoading && !isSaving"
        mode="BYPASS"
        color="SECONDARY"
        (onClick)="activeModal.close(true)"
        [disabled]="isLoading && !model.message"
      >
        {{ 'app.button.back' | translate }}
      </app-button>
      <app-button
        mode="BYPASS"
        color="SECONDARY"
        (onClick)="doCancel()"
        *ngIf="isLoading || isSaving"
        [disabled]="isSaving && !isLoading"
      >
        {{ 'app.button.cancel' | translate }}
      </app-button>
      <app-button
        mode="BYPASS"
        color="PRIMARY"
        (onClick)="doSave()"
        [disabled]="errors.label || file.length === 0 || isLoading || isSaving"
      >
        {{ 'app.button.save' | translate }}
      </app-button>
    </div>
  </ng-template>
</app-popup>
