import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { GuaranteeBondTypeEditAddComponent } from './guarantee-bond-type-edit-add.component';
import { GuaranteeBondTypeComponent } from './guarantee-bond-type.component';
const routes = [
  { path: '', component: GuaranteeBondTypeComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: GuaranteeBondTypeEditAddComponent,
    data: { breadcrumb: 'guarantee-bond-type.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: GuaranteeBondTypeEditAddComponent,
    data: { breadcrumb: 'guarantee-bond-type.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [GuaranteeBondTypeComponent, GuaranteeBondTypeEditAddComponent]
})
export class GuaranteeBondTypeModule {}
