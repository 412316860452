<app-popup header="payment-history.popup.print">
  <small
    class="text-danger"
    *ngIf="isPrint && accordionItemListTemp.length === 0"
  >
    {{ 'app.validation.isChecked' | translate }}
  </small>
  <app-table [tableResponse]="tableResponse">
    <ng-template #actionHeader>
      <input
        type="checkbox"
        (click)="doCheckAll($event)"
        [checked]="accordionItemListTemp.length === accordionItemList.length"
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
        aria-label="Check All"
      />
    </ng-template>
    <ng-template #actionButtons let-rowData>
      <input
        type="checkbox"
        (click)="doCheck(rowData)"
        [checked]="accordionItemListTemp.indexOf(rowData) !== -1"
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
        aria-label="Check"
      />
    </ng-template>
  </app-table>
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center button-group-icon-text">
      <app-button
        mode="BYPASS"
        color="SECONDARY"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.close' | translate }}</app-button
      >
      <app-button mode="BYPASS" (onClick)="doPrint()">
        <em class="fas fa-print"></em>{{ 'app.button.print' | translate }}
      </app-button>
    </div>
  </ng-template>
</app-popup>
