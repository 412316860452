import {
  AfterViewInit,
  Component,
  ElementRef,
  Optional,
  Renderer2
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { TextUtils } from '../../utils';
@Component({
  selector: 'app-text-tagging',
  templateUrl: './app-text-tagging.component.html',
  styleUrls: ['./app-text-tagging.component.scss'],
  providers: makeProvider(AppTextTaggingComponent)
})
export class AppTextTaggingComponent
  extends BaseValueAccessorComponent<Array<string>>
  implements AfterViewInit
{
  generatedId: string = TextUtils.generateRandomString();
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public renderer2: Renderer2
  ) {
    super('app-text-tagging', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    if (this.ISVIEW) {
      this.formControl.disable();
    }
  }

  ngAfterViewInit(): void {
    this.setInputDisabled();
  }

  public setInputDisabled(): void {
    if (!this.ISVIEW && this.disabled) {
      const inputElement = document.getElementById(this.generatedId);
      this.renderer2.setProperty(inputElement, 'disabled', this.disabled);
    }
  }

  public handleInput(event: any): void {
    event.target.size = event.target.value.length + 1;
  }

  public handleClick(): void {
    const inputElement = document.getElementById(this.generatedId);
    inputElement.focus();
  }

  public handleFocusOut(): void {
    this.formControl.markAsTouched();
  }

  public handleKeyUp(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    if (event.keyCode === 13) {
      const { value } = event.target;
      event.target.size = 1;
      if (value && value.trim() !== '') {
        this.setFormControlValue(value);
      }
      event.target.value = null;
    }
  }

  public setFormControlValue(value: any): void {
    if (!this.formControl.value) {
      this.formControl.patchValue([value]);
    } else {
      this.formControl.patchValue([...this.formControl.value, value]);
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    this.log.debug(event);
  }

  public onKeyDown(event: KeyboardEvent): void {
    this.log.debug(event);
  }
}
