import {
  ChangeDetectorRef,
  Component,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { AppPopupComponent } from '../../core/components/app-popup/app-popup.component';
@Component({
  templateUrl: './app-login-form-popup.component.html',
  styleUrls: ['./app-login-form-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppLoginFormPopupComponent extends BaseComponentComponent {
  @ViewChild(AppPopupComponent) appPopup: AppPopupComponent;
  constructor(public cdf: ChangeDetectorRef) {
    super('form-login-popup');
  }

  public onInit(): void {
    this.setStateReady();
  }

  public onClosePopupLoginForm(): void {
    this.appPopup.ngbActiveModal.close();
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
