import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { OptionListModel } from '../../model/option-list-model';
import { AppCatalogItemCustomDataRequest } from '../app-catalog-item-x/request/app-catalog-item-custom-data.request';
import { AppVendorCatalogInfoResponse } from './response/app-vendor-catalog-info.response';

@Component({
    selector: 'app-vendor-catalog-info',
    templateUrl: './app-vendor-catalog-info.component.html',
    styleUrls: ['./app-vendor-catalog-info.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
export class AppVendorCatalogInfoComponent extends BaseComponentComponent {
    @Input() vendorId: number;
    @Input() itemPath = '';
    public response: AppVendorCatalogInfoResponse = new AppVendorCatalogInfoResponse();
    public sortOptionList: OptionListModel<object> = new OptionListModel(false);

    public request: AppCatalogItemCustomDataRequest = new AppCatalogItemCustomDataRequest();
    public customData: string;

    constructor(public translateService: TranslateService) {
        super('app-vendor-catalog-info');
    }

    public onInit(): void {
        this.setSortOptionList();
        this.buildFormGroup();
        this.setFormGroup();
        this.setViewOnly();
        this.formGroup.get('keyword').setIsView(false);
        this.formGroup.get('sortBy').setIsView(false);
        this.setCustomData();
    }

    public buildFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            vendorId: [null],
            phone: [null],
            rating: [null],
            keyword: [null],
            sortBy: [null]
        });

        this.formGroup.get('keyword').disable();
        this.formGroup.get('sortBy').disable();
    }

    public setCustomData(): void {
        this.formGroup.valueChanges.subscribe(value => {
            this.log.debug('formGroup');
            this.log.debug(value);

            this.request = this.global.copyFormAttributeToModel(this.request, this.formGroup);
            this.customData = JSON.stringify(this.request);
            this.log.debug(this.customData);
        });
    }

    public setFormGroup(): void {
       this.httpClientService
      .get('/app-vendor-catalog-info/get-by-vendor-id/' + this.vendorId)
      .subscribe((response: AppVendorCatalogInfoResponse) => {
          this.response = response;

          this.formGroup.patchValue({
              vendorId: this.vendorId,
              phone: { country: this.response.vendor.phoneRegion, number: this.response.vendor.phone },
              rating: this.response.rating,
              sortBy: this.sortOptionList.getRequestValues()[2]
          });

          this.setStateReady();
      });
    }

    public setSortOptionList(): void {
        const sortList = [
          { name: this.translateService.instant('app-vendor-catalog-info.sort.highestPrice'), value: 'HIGHEST_PRICE'},
          { name: this.translateService.instant('app-vendor-catalog-info.sort.lowestPrice'), value: 'LOWEST_PRICE'},
          { name: this.translateService.instant('app-vendor-catalog-info.sort.newProduct'), value: 'NEW_PRODUCT'}
        ];

        this.sortOptionList.setRequestValues(sortList);
      }

}
