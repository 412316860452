import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { UploadDragAndDropEvent } from '../events/upload-drag-and-drop-event';
const selector = '[uploadDragAndDrop]';
@Directive({ selector })
export class UploadDragAndDropDirective {
  @Output() onChange: EventEmitter<UploadDragAndDropEvent> = new EventEmitter();
  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.onChange.emit({
      type: 'ON-DRAGOVER'
    });
  }
  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.onChange.emit({
      type: 'ON-DRAGLEAVE'
    });
  }
  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.onChange.emit({
      type: 'ON-DROP',
      fileList: event.dataTransfer.files
    });
  }
}
