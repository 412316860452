import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { FooterService } from './footer.service';

@NgModule({
  imports: [CommonModule],
  declarations: [FooterComponent],
  providers: [FooterService],
  exports: [FooterComponent]
})
export class FooterModule {
  public static forRoot(): ModuleWithProviders<FooterModule> {
    return {
      ngModule: FooterModule,
      providers: [FooterService]
    };
  }

  // public static forChild(options?: object): ModuleWithProviders<FooterModule> {
  //   return {
  //     ngModule: FooterModule,
  //     providers: [FooterService],
  //   };
  // }
}
