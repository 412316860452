<app-layout
  [isLoading]="formLoading"
  header="pr.catalogDetail.title"
  [isShowBreadcrumbs]="false"
>
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    novalidate
    *ngIf="!formLoading"
  >
    <div class="row main-row">
      <div class="col-12">
        <div class="form-group text-sm-left">
          <span *ngFor="let itemCategory of itemCategoryList; let i = index">
            <span [hidden]="i === itemCategoryList.length - 1">
              {{ itemCategory.name }} <em class="pir pi-chevron-right"></em>
            </span>
            <span
              [hidden]="i !== itemCategoryList.length - 1"
              style="color: 'var(--primary-dark)'"
            >
              {{ itemCategory.name }}
            </span>
          </span>
        </div>
        <div>
          <app-alert-x color="WARNING" *ngIf="isQuantityInPackage">{{
            'manage-catalog.alert.isActive' | translate
          }}</app-alert-x>
        </div>
        <app-card [isLoading]="formLoading" [isSaving]="formSaving">
          <app-catalog-detail-info
            [catalogId]="catalogId"
            (onChange)="doChangeCatalog($event)"
            [mode]="formGroup.isView ? 'view' : 'edit'"
          >
          </app-catalog-detail-info>
          <app-table-xx
            header="pr.catalogDetail.budgetItem"
            [model]="tableResponse"
            [isShowCheckBox]="true"
            [isMultipleSelect]="true"
            *ngIf="!formLoading && prResponse.isModuleGroupESMActive"
          >
            <ng-template #headerButtons>
              <app-button
                *ngIf="!formGroup.isView"
                class="float-right"
                (onClick)="doChooseBudget(prItem, i)"
                [ngStyle]="{ 'margin-bottom': '2%' }"
                ><em class="pir pi-plus-circle"></em>
                {{ 'pr.button.chooseBudget' | translate }}</app-button
              >
            </ng-template>
            <ng-container>
              <ng-template #row>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>
                      {{ 'pr.table.column.budgetTotal' | translate }}
                    </b>
                  </td>
                  <td class="text-right">
                    {{
                      formGroup.value.totalBudget
                        ? global.converterService.convertMoney(
                            formGroup.value.totalBudget
                          )
                        : 0
                    }}
                    {{ prResponse.companyCurrency.code }}
                  </td>
                </tr>
              </ng-template>
            </ng-container>
            <ng-container>
              <ng-template #actionButtons let-rowData>
                <app-button
                  size="TN"
                  (onClick)="doDeleteBudget(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="pir pi-trash-alt"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>
              </ng-template>
            </ng-container>
          </app-table-xx>
        </app-card>
      </div>
    </div>
    <ng-template #contentSidebarRight>
      <div
        class="row"
        *ngIf="!formLoading"
        style="display: flex; place-content: center; margin: 15px 0 20px"
      >
        <div class="col-sm-12" style="top: 30px">
          <app-card>
            <h5>{{ 'pr.label.requestQuantiy' | translate }}</h5>
            <br />
            <div class="form-group row text-sm-left">
              <div class="col-sm-12 text-left">
                <app-count
                  formControlName="quantity"
                  maxLength="6"
                  (onChange)="onChangeQuantity()"
                  (input)="onChangeQuantity()"
                  [min]="1"
                  size="XL"
                  appInteger
                ></app-count>
                <!-- <small
                  *ngIf="
                    catalog?.catalogType?.code ===
                    global.appConstant.vm.CATALOG_TYPE_CONTRACT
                  "
                  >{{ 'pr.msg.remainingStock' | translate }}
                  {{
                    formGroup.get('remainingQuantity').value
                      | resolveNumberToDecimal
                  }}</small
                > -->
              </div>
            </div>
            <div
              class="row"
              *ngIf="
                catalog?.catalogType?.code ===
                  global.appConstant.vm.CATALOG_TYPE_CONTRACT && isShowDiscount
              "
            >
              <div
                class="col-sm-2 text-left"
                style="color: var(--gray-500); font-size: 12px"
              >
                <label>{{ 'pr.form.discount' | translate }}</label>
              </div>
              <div class="col-sm-10 text-right" style="color: #dc3d31">
                <h6>
                  -
                  {{
                    formGroup.value.discount
                      ? global.converterService.convertMoney(
                          formGroup.value.discount
                        )
                      : '0'
                  }}
                  {{ prResponse.companyCurrency.code }}</h6
                >
              </div></div
            >
            <div class="row">
              <div
                class="col-sm-2 text-left"
                style="color: var(--gray-500); font-size: 12px"
              >
                <label>{{ 'pr.form.subTotal' | translate }}</label>
              </div>
              <div class="col-sm-10 text-right">
                <h6>
                  {{
                    formGroup.value.totalPrice
                      ? global.converterService.convertMoney(
                          formGroup.value.totalPrice
                        )
                      : '0'
                  }}
                  {{ prResponse.companyCurrency.code }}</h6
                >
              </div></div
            >
            <div class="row">
              <div class="col-sm-12 button-process-to-pr">
                <app-button
                  (onClick)="doCart()"
                >
                  <em class="pir pi-plus-circle"></em>
                  {{ 'pr.button.cart' | translate }}</app-button
                >
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-sm-12 button-process-to-pr">
                <app-button
                  color="LIGHT"
                  [outline]="true"
                  (onClick)="doPurchaseRequest()"
                  >{{ 'pr.button.processPr' | translate }}
                </app-button>
              </div>
            </div>
          </app-card>
        </div>
      </div>
    </ng-template>
  </form>
  <app-similar-product [catalogId]="catalogId"></app-similar-product>
  <ng-container *ngIf="model && model.getPageRecords().length > 0">
    <div *ngIf="model && model.getPageRecords().length > 0" class="mt-5">
      <h4>{{ 'pr.lastPurchase.title' | translate }}</h4>
      <div class="float-right mb-3">
        <app-button
          color="SECONDARY"
          size="TN"
          (onClick)="onPageChangeLeft($event.target.value)"
          [ngStyle]="{ 'padding-right': '10px' }"
          [disabled]="+model.pagination.page === 1"
        >
          <em class="pir pi-chevron-left"></em>
        </app-button>
        <app-button
          color="PRIMARY"
          size="TN"
          (onClick)="onPageChangeRight($event.target.value)"
          [ngStyle]="{ 'padding-right': '10px' }"
          [disabled]="formGroup.get('isMaxPage').value"
        >
          <em class="pir pi-chevron-right"></em>
        </app-button>
      </div>
    </div>
    <app-catalog-item-x [customModel]="model"></app-catalog-item-x>
  </ng-container>
</app-layout>
