<div class="row">
  <div class="col-lg-12 mb-4">
    <div class="text-center py-4" *ngIf="formLoading">
      <img src="assets/img/common/loader-small.gif" alt="Loading" />
    </div>
    <form
      class="form-horizontal"
      [formGroup]="tabMarketing.formGroup"
      *ngIf="!formLoading"
    >
      <h5 class="separator mt-3">{{
        'tab-profile-marketing.form-group.companyProfile' | translate
      }}</h5>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-marketing.form.logo' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-upload
            formControlName="logoFile"
            [fileUploader]="fileUploaderLogo"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabMarketing.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileMarketingDTO.vendorHistory.vendor.logoFile
                  ? tabProfileMarketingDTO.vendorHistory.vendor.logoFile.id
                  : null
              "
              [newValue]="
                tabProfileMarketingDTO.vendorHistory.logoFile
                  ? tabProfileMarketingDTO.vendorHistory.logoFile.id
                  : null
              "
              [hoverValue]="
                tabProfileMarketingDTO.vendorHistory.vendor.logoFile
                  ? tabProfileMarketingDTO.vendorHistory.vendor.logoFile
                      .fileName
                  : null
              "
            ></app-flag-new>
          </app-upload>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-marketing.form.banner' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-upload
            formControlName="bannerFile"
            [fileUploader]="fileUploaderBanner"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabMarketing.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileMarketingDTO.vendorHistory.vendor.bannerFile
                  ? tabProfileMarketingDTO.vendorHistory.vendor.bannerFile.id
                  : null
              "
              [newValue]="
                tabProfileMarketingDTO.vendorHistory.bannerFile
                  ? tabProfileMarketingDTO.vendorHistory.bannerFile.id
                  : null
              "
              [hoverValue]="
                tabProfileMarketingDTO.vendorHistory.vendor.bannerFile
                  ? tabProfileMarketingDTO.vendorHistory.vendor.bannerFile
                      .fileName
                  : null
              "
            ></app-flag-new>
          </app-upload>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-marketing.form.description' | translate
        }}</label>
        <div
          class="text-left"
          [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
        >
          <app-text-area
            autofocus="true"
            formControlName="description"
            maxLength="512"
            tooltip="{{
              'tab-profile-marketing.tooltip.description' | translate
            }}"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabMarketing.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileMarketingDTO.vendorHistory.vendor.description
              "
              [newValue]="tabProfileMarketingDTO.vendorHistory.description"
              [ngStyle]="{ 'padding-left': '3px' }"
            ></app-flag-new>
          </app-text-area>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-marketing.form.facebook' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field formControlName="facebook" maxLength="32">
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabMarketing.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileMarketingDTO.vendorHistory.vendor.facebook"
              [newValue]="tabProfileMarketingDTO.vendorHistory.facebook"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-marketing.form.linkedin' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field formControlName="linkedin" maxLength="32">
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabMarketing.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileMarketingDTO.vendorHistory.vendor.linkedin"
              [newValue]="tabProfileMarketingDTO.vendorHistory.linkedin"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-marketing.form.instagram' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field formControlName="ig" maxLength="32">
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabMarketing.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileMarketingDTO.vendorHistory.vendor.ig"
              [newValue]="tabProfileMarketingDTO.vendorHistory.ig"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-marketing.form.twitter' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field formControlName="twitter" maxLength="32">
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabMarketing.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileMarketingDTO.vendorHistory.vendor.twitter"
              [newValue]="tabProfileMarketingDTO.vendorHistory.twitter"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label" *ngIf="!isView">{{
          'tab-profile-marketing.form.uploadCompanyProfile' | translate
        }}</label>
        <label class="col-sm-3 control-label" *ngIf="isView">{{
          'tab-profile-marketing.form.file' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-upload
            formControlName="companyProfileFile"
            [fileUploader]="fileUploaderCompanyProfile"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabMarketing.formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileMarketingDTO.vendorHistory.vendor.companyProfileFile
                  ? tabProfileMarketingDTO.vendorHistory.vendor
                      .companyProfileFile.id
                  : null
              "
              [newValue]="
                tabProfileMarketingDTO.vendorHistory.companyProfileFile
                  ? tabProfileMarketingDTO.vendorHistory.companyProfileFile.id
                  : null
              "
              [hoverValue]="
                tabProfileMarketingDTO.vendorHistory.vendor.companyProfileFile
                  ? tabProfileMarketingDTO.vendorHistory.vendor
                      .companyProfileFile.fileName
                  : null
              "
            ></app-flag-new>
          </app-upload>
        </div>
      </div>
    </form>
  </div>
</div>
