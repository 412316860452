import { DatePickerState } from '../model';
export const setDatePickerTimeHtmlText = (
  state: DatePickerState,
  payload: {
    event: InputEvent & { target: HTMLElement };
    type: 'HOURS' | 'MINUTES';
  }
): void => {
  const { target } = payload.event;
  if (target.innerText.trim() !== '') {
    if (payload.type === 'HOURS') {
      if (isNaN(+target.innerText)) {
        target.innerText = state.currentTime.hoursString;
      } else {
        const hours = target.innerText.replace(/\+-/g, '');
        target.innerText = +hours > 23 ? '23' : (+hours).toString();
      }
    } else {
      if (isNaN(+target.innerText)) {
        target.innerText = state.currentTime.minutesString;
      } else {
        const minutes = target.innerText.replace(/\+-/g, '');
        target.innerText = +minutes > 59 ? '59' : (+minutes).toString();
      }
    }
  }
};
