import { BaseEntity } from '../base/base-entity';
import { Catalog } from '../bean/catalog';
import { CatalogContract } from '../bean/catalog-contract';
import { CatalogRegionDetail } from '../bean/catalog-region-detail';
import { Item } from '../bean/item';
import { Pr } from '../bean/pr';
import { PrItemBudget } from '../bean/pr-item-budget';
import { PrItemImage } from '../bean/pr-item-image';
import { WorkflowModelPrcs } from '../bean/workflow-model-prcs';

export class PrItemEntity extends BaseEntity {
  crudOperation: number;
  prItemImageList: PrItemImage[];
  prItemBudgetList: PrItemBudget[];
  pr: Pr = new Pr();
  item: Item = new Item();
  catalog: Catalog = new Catalog();
  workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  catalogContract: CatalogContract;
  catalogRegionDetail: CatalogRegionDetail;
  price: number;
  totalPrice: number;
  quantity: number;
  isUsed: boolean;
  specification: string;
  vendorId: number;
}
