<ng-container
  *ngIf="ISVIEW; then isViewTmpl; else isNotViewTmpl"
  [formGroup]="generateFormGroup"
>
</ng-container>
<ng-template #isNotViewTmpl>
  <div
    class="app-month-picker p-0"
    [formGroup]="formGroup"
    [ngClass]="elementWidthClassName"
  >
    <select
      class="custom-select"
      [ngClass]="[isInvalid ? 'is-invalid' : '', isValid ? 'is-valid' : '']"
      [formControl]="formControl"
    >
      <option value="null">Select Month</option>
      <option
        *ngFor="let monthName of monthList; let i = index"
        [value]="i + 1"
        [disabled]="
          (i + 1 < monthStartValue ? 13 + i : i + 1) <= minMonthValue ||
          (i + 1 < monthStartValue ? 13 + i : i + 1) >= maxMonthValue
        "
      >
        {{ monthName }}
      </option>
    </select>
    <ng-container
      *ngTemplateOutlet="appTooltipWrapperTemplateOutlet"
    ></ng-container>
  </div>
  <small class="text-danger" *ngIf="isInvalid">
    {{ errors.message }}
  </small>
</ng-template>
<ng-template #isViewTmpl>
  <div class="position-relative d-inline-block">
    <span>{{
      (formControl.value && monthList[formControl.value - 1]) || '-'
    }}</span>
    <ng-container
      *ngTemplateOutlet="appTooltipWrapperTemplateOutlet"
    ></ng-container>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [symbolic]="symbolic"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
