<app-layout header="rfp.title" [isLoading]="formLoading">
  <app-table-xx
    header="Request List"
    [model]="tableResponse"
    stringUrl="/rfp/index"
    (onClick)="doEdit($event)"
    isShowEditTable="true"
    isShowButtonModifyTable="true"
  >
    <ng-template #headerFilterGroup>
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <div class="form-group row text-sm-left">
          <div class="col-lg-12 row" style="margin-bottom: -1.5rem">
            <label
              class="cml-3 d-flex align-items-center"
              style="margin-top: auto"
              >{{ 'rfp.form.rfpDate' | translate }}</label
            >
            <div class="cml-3 text-left">
              <app-date-picker-x
                size="XL"
                formControlName="rfpDate"
                [range]="true"
                (onChange)="onChangeFilter()"
              >
              </app-date-picker-x>
            </div>
            <label
              class="cml-5 d-flex align-items-center"
              style="margin-top: auto"
              >{{ 'rfp.form.dueDate' | translate }}</label
            >
            <div class="cml-3 text-left">
              <app-date-picker-x
                size="XL"
                formControlName="dueDate"
                [range]="true"
                (onChange)="onChangeFilter()"
              >
              </app-date-picker-x>
            </div>
            <div class="cml-3 col-sm-2 text-left">
              <app-dropdown-select
                size="XL"
                formControlName="statusList"
                (onChange)="onChangeFilter()"
                showLimitValue="1"
                type="CHOSEN"
                [optionList]="statusOptionList"
                placeholder="rfp-response.placeholder.status"
              >
              </app-dropdown-select>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template #headerButtons>
      <app-button
        (onClick)="doAdd()"
        title="{{ 'app.button.add' | translate }}"
      >
        <em class="pir pi-plus-circle"></em>
        {{ 'rfp.index.placeholder.newRequest' | translate }}
      </app-button>
    </ng-template>
    <ng-template #actionButtons let-rowData>
      <app-button
        size="TN"
        (onClick)="doDelete(rowData)"
        title="{{ 'app.tooltip.delete' | translate }}"
      >
        <em class="pir pi-trash-alt"></em>
        {{ 'app.button.delete' | translate }}
      </app-button>
    </ng-template>
  </app-table-xx>
</app-layout>
