<div class="row main-row">
  <div class="col-12">
    <app-card
      header="rfi.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <h5 class="separator">{{ 'rfi.additionalQuestion' | translate }}</h5>
        <ng-container formArrayName="parents">
          <ng-container
            *ngFor="
              let parent of parents.controls;
              let i = index
            "
            [formGroup]="parent"
          >
            <app-card [isLoading]="formLoading">
              <div class="d-flex justify-content-end"
                ><em
                  class="fas fa-times text-danger cursor-pointer"
                  (click)="doDeleteQuestion(i)"
                  title="{{ 'app.button.delete' | translate }}"
                  (keyup)="onKeyUp($event)"
                  (keydown)="onKeyDown($event)"
                ></em>
              </div>
              <div class="form-group row text-sm-right required">
                <label class="col-sm-3 control-label">{{
                  'rfi.form.question' | translate
                }}</label>
                <div class="col-sm-9 col-lg-7 text-left">
                  <app-text-field
                    autofocus="true"
                    size="LG"
                    formControlName="question"
                    maxLength="128"
                  ></app-text-field>
                </div>
              </div>

              <div class="form-group row text-sm-right required">
                <label class="col-sm-3 control-label">{{
                  'rfi.form.answerType' | translate
                }}</label>
                <div class="col-sm-9 col-lg-7 text-left">
                  <app-combo-box
                    [optionList]="formObjectOptionList"
                    formControlName="formObject"
                    (onChange)="showFormOption(i, parent)"
                    tooltip="{{ 'rfi.tooltip.answerType' | translate }}"
                    size="LG"
                  ></app-combo-box>
                </div>
              </div>

              <ng-container *ngIf="formObjectValue" formArrayName="listOfValue">
                <div
                  class="form-group row text-sm-right required"
                  *ngFor="
                    let listOfValue of getFormArray(parent, 'listOfValue')
                      .controls;
                    let j = index
                  "
                  [formGroup]="listOfValue">
                  
                  <label class="col-sm-3 control-label">{{
                    'rfi.form.option' | translate
                  }}</label>
                  <div class="col-sm-9 col-lg-7 text-left">
                    <div class="form-row">
                      <div class="form-group row col-4 required">
                        <div class="col text-left">
                          <app-text-field
                            size="XL"
                            formControlName="name"
                            placeholder="{{
                              'rfi.placeholder.name' | translate
                            }}"
                          ></app-text-field>
                        </div>
                      </div>
                      <div class="form-group row col-4 required">
                        <div class="col text-left">
                          <app-text-field
                            size="XL"
                            formControlName="value"
                            placeholder="{{
                              'rfi.placeholder.value' | translate
                            }}"
                          ></app-text-field>
                        </div>
                      </div>
                      <div class="form-group row col-4">
                        <div class="col text-left">
                          <app-button
                            color="SECONDARY"
                            size="SM"
                            [disabled]="formSaving"
                            (onClick)="doRemove(parent, j)"
                            title="{{ 'app.tooltip.delete' | translate }}"
                            *ngIf="j !== 0"
                          >
                            <em class="fa fas fa-trash"></em
                          ></app-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="text-center" *ngIf="formObjectValue[i] || (parent.get('formObject').value && parent.get('formObject').value.isValue)">
                <app-button
                  color="PRIMARY"
                  (onClick)="addValue(parent)"
                  [disabled]="formSaving"
                  ><em class="fas fa-plus"></em>
                  {{ 'rfi.button.addMoreOption' | translate }}</app-button
                >
              </div>

              <br />
            </app-card>
          </ng-container>
        </ng-container>

        <div class="text-center" *ngIf="parents.length < 10">
          <app-button
            color="PRIMARY"
            (onClick)="addMoreQuestion()"
            [disabled]="formSaving"
            ><em class="fas fa-plus"></em>
            {{ 'rfi.button.addMore' | translate }}
          </app-button>
        </div>

        <div class="text-center" *ngIf="parents.length >= 10">
          <app-button
            color="PRIMARY"
            (onClick)="addNoMoreQuestion()"
            [disabled]="formSaving"
            ><em class="fas fa-plus"></em>
            {{ 'rfi.button.addMore' | translate }}
          </app-button>
        </div>

        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doBack()"
            mode="BYPASS"
            [disabled]="formSaving || isDisabled"
            >{{ 'app.button.previous' | translate }}
          </app-button>
          <app-button
            color="PRIMARY"
            (onClick)="doSave()"
            [disabled]="formSaving || isDisabled"
            >{{ 'app.button.save' | translate }}</app-button
          >
        </div>
      </form>
    </app-card>
  </div>
</div>
