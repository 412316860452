<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="vendor-history.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-fieldset legend="Filter">
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-history.form.vendorType' | translate }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box autofocus="true" formControlName="vendorType"
                [optionList]="vendorTypeOptionList"></app-combo-box>
            </div>
          </div>
          <div class="row justify-content-center mt-5">
            <app-button mode="BYPASS" color="SECONDARY" (onClick)="doReset()"
              class="mr-1"><em class="fas fa-sync-alt"></em> {{
              'app.button.reset' | translate }} </app-button>
            <app-button mode="BYPASS" color="PRIMARY" (onClick)="doApply()"
              class="mr-1"><em class="fas fa-check"></em> {{ 'app.button.apply'
              | translate }} </app-button>
          </div>
        </app-fieldset>
        <app-table [model]="tableResponse" [isServerSide]="true"
          stringUrl="/vendor-history/index">
          <ng-template #actionButtons let-rowData>
            <app-button mode="BYPASS" size="TN" (onClick)="doDetail(rowData)"
              title="{{ 'app.tooltip.detail' | translate }}">
              <em class="fas fa-search"></em>
            </app-button>
          </ng-template>
        </app-table>
      </form>
    </app-card>
  </div>
</div>