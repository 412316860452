import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../../core/guard/confirm.guard';
import { SharedModule } from '../../../core/shared/shared.module';
import { WatermarkReportEditAddComponent } from './watermark-report-edit-add.component';
const routes = [
  {
    path: '',
    component: WatermarkReportEditAddComponent,
    data: { breadcrumb: 'watermark-report.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
  // { path: 'watermark-report-reload', loadChildren: () => import('src/app/app.module').then(m => m.AppModule), data: { breadcrumb: 'Watermark Report Reload' } },
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [WatermarkReportEditAddComponent],
  providers: []
})
export class WatermarkReportModule {}
