import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Contract } from 'src/app/core/bean/contract';
import { ContractDraft } from 'src/app/core/bean/contract-draft';
import { ContractTemplate } from 'src/app/core/bean/contract-template';
import { ContractTemplateParameter } from 'src/app/core/bean/contract-template-parameter';
import { Fines } from 'src/app/core/bean/fines';
import { Guarantee } from 'src/app/core/bean/guarantee';
import { PrItemReleased } from 'src/app/core/bean/pr-item-released';
import { Sow } from 'src/app/core/bean/sow';
import { Vendor } from 'src/app/core/bean/vendor';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { Validators } from 'src/app/core/validators';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { ContractDraftAddEditResponse } from './contract-draft-add-edit.response';
import { ContractDraftUpdateRequest } from './contract-draft-update.request';
import { ContractDraftGenerateRequest } from './contract-draft-generate.request';
import { ContractDraftGenerateResponse } from './contract-draft-generate.response';
import { ContractDraftParameterValue } from 'src/app/core/bean/contract-draft-parameter-value';
import { AppPopupContractReviewerComponent } from './app-popup-contract-reviewer.component';
import { ContractDraftHistory } from 'src/app/core/bean/contract-draft-history';
import { ContractReviewer } from 'src/app/core/bean/contract-reviewer';
import { File } from 'src/app/core/bean/file';
import { AppPopupContractDraftVersionHistoryComponent } from './app-popup-contract-draft-version-history.component';

@Component({
  templateUrl: './contract-draft-edit-add-x.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./contract-draft-edit-add.component.scss']
})
export class ContractDraftEditAddXComponent
  extends BaseModuleComponent
  implements OnInit
{
  public token: string;
  public contractId: number;
  public objectId: number;
  public contract: Contract;
  public approvalModelPrcsId: number;
  public contractTemplateList: OptionListModel<ContractTemplate> = new OptionListModel(true);
  public reviewerOptionList: OptionListModel<ContractReviewer> = new OptionListModel(false);
  public response: ContractDraftAddEditResponse = new ContractDraftAddEditResponse();
  public formGroupVersion: FormGroup = new FormGroup({});
  public formGroupReviewer: FormGroup = new FormGroup({});
  public today = new Date();
  public contractTemplate: ContractTemplate;
  public amount: number;
  public tableResponseVendor: TableResponseModel<Vendor>;
  public tableResponseReviewer: TableResponseModel<ContractReviewer>;
  public sowList: Sow[] = [];
  public finesList: Fines[] = [];
  public guaranteeList: Guarantee[] = [];
  public prItemReleasedList: PrItemReleased[] = [];
  public contractTemplateParameterList: ContractTemplateParameter[] = [];
  public existingContractReviewerList: ContractReviewer[] = [];
  public isCanceled: boolean;
  public isRevisions: boolean;
  public request: ContractDraftUpdateRequest = new ContractDraftUpdateRequest();
  public contractDraftGenerateRequest: ContractDraftGenerateRequest =
  new ContractDraftGenerateRequest();
  public isSourceReady = false;
  public isParamValueNull = false;
  public isParameterReady = false;
  public showTables = false;
  public validateGenerate = false;
  public fileUploader: FileUploader = new FileUploader(
    '/contract-draft/',
    'contract-draft.title',
    this.global.appConstant.fileType.DOC_CONTRACT
  );

  public badgeColor = {
    NEW: 'INFO',
    DRAFT: 'SECONDARY',
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    ON_PROGRESS: 'PROGRESS',
    APPROVED: 'SUCCESS',
    REVISION_BY_VENDOR: 'FEEDBACK',
    WAITING_CONFIRMATION: 'WARNING',
    CLOSED: 'DANGER',
    CANCELED: 'DANGER'
  };

  public statusForApprovalList = [
    this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_WAITING_APPROVAL,
    this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_ON_PROGRESS,
    this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_WAITING_CONFIRMATION,
    this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_APPROVED
  ];

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public toastService: ToastService,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute
  ) {
    super('contract-draft', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Contract>('/contract-draft/notification/' + this.token)
      .subscribe((contract: Contract) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('contractId', contract.id);
        this.global.routerParams.set('urlBackOutside', '/pages/worklist-cm');
        this.global.routerParams.set('todo', 'edit');
        this.router.navigate(['/pages/contract-draft/edit']);
      });
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.buildTableResponseVendor();
    this.setFormGroup();
    this.setIsViewOnlyFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.contractId = this.global.routerParams.get('contractId');
    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.contractId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
      this.formGroup.setIsView(true);
      this.formGroupVersion.setIsView(true);
      this.formGroupReviewer.setIsView(true);
    } else if (this.todo === 'detail') {
      this.setViewOnly();
      this.formGroup.setIsView(true);
      this.formGroupVersion.setIsView(true);
      this.formGroupReviewer.setIsView(true);
    }
  }

  public buildTableResponseVendor(): void {
    this.tableResponseVendor = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.vendorName'
      },
      {
        field: 'email',
        header: 'table.column.email'
      },
      {
        field: 'address',
        header: 'table.column.address'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      },
      {
        field: 'pic',
        header: 'table.column.pic'
      }
    ]);

    this.tableResponseReviewer = new TableResponseModel<ContractReviewer>(
        this.moduleCode,
        [
          {
            field: 'reviewByUser.imgFile',
            plugins: 'img'
          },
          {
            field: 'name',
            header: 'table.column.user'
          },
          {
            field: 'email',
            header: 'table.column.email'
          },
          {
            field: 'organization.name',
            header: 'table.column.department'
          }
        ]
      );
  }



  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
        id: [null],
        contractTemplate: [null, Validators.required()],
        contractTemplateParamList: this.formBuilder.array([])
      });
    this.formGroupVersion = this.formBuilder.group({
        id: [null],
        uploadFile: [null, Validators.required()],
        note: [null],
        file: [null, Validators.required()],
        version: [null]
    });
    this.formGroupReviewer = this.formBuilder.group({
      reviewerList: [null]
    });
    
  }

  public get contractTemplateParamList(): FormArray {
    return this.formGroup.get('contractTemplateParamList') as FormArray;
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ContractDraftAddEditResponse>(
        '/contract-draft/add-edit-x',
        new RouteRequestModel(
          this.todo,
          this.objectId ? this.objectId : this.contractId
        )
      )
      .subscribe((response: ContractDraftAddEditResponse) => {
        this.response = response;
        this.contract = response.contract;
        response.contract.vendor.phone =
          response.contract.vendor.phoneRegion?.callingCode +
          response.contract.vendor.phone;
        response.contract.vendor['address'] = response.vendorAddress.address;
        response.contract.vendor['pic'] = response.vendorPic?.name;
        this.tableResponseVendor.setRecordList([response.contract.vendor]);
        this.tableResponseVendor.reload();
        this.prItemReleasedList = response.contractDetailList.map(
          detail => detail.prItemReleased
        );
        this.contractTemplateList.setRequestValues(
          response.contractTemplateList
        );
        this.sowList = response.sowList;
        this.finesList = response.finesList;
        this.guaranteeList = response.guaranteeList;
        this.amount = response.contract.amount;

        if (this.response.contractDraftHistory) {
          this.formGroupVersion.patchValue({
            id: this.response.contractDraftHistory.id,
            note: this.response.contractDraftHistory.note,
            file: this.response.contractDraftHistory.generatedFile,
            version: this.response.contractDraftHistory.sequence
          });

          if (this.response.contractDraftHistory.draftFile) {
              this.fileUploader.setFile(
                this.response.contractDraftHistory.draftFile
              );
              this.formGroupVersion.patchValue({
                uploadFile: this.fileUploader.fileObjectList
              });
          }
          this.validateGenerate = true;
        }
        if (
          this.response.contractDraftParameterValueList &&
          this.response.contractDraftParameterValueList
            .length !== 0
        ) {
          this.response.contractDraftParameterValueList.forEach(
            contractDraftParamValue => {
              const formGroup: FormGroup = this.formBuilder.group({
                id: contractDraftParamValue.id,
                code: contractDraftParamValue.contractTemplateParameter
                  .contractParameter.code,
                name: contractDraftParamValue.contractTemplateParameter
                  .contractParameter.name,
                value: [
                  contractDraftParamValue.contractTemplateParameter
                    .contractParameter.valueType.code ===
                    'DATE_FORMAT_SHORT' ||
                  contractDraftParamValue.contractTemplateParameter
                    .contractParameter.valueType.code === 'DATE_FORMAT_LONG'
                    ? contractDraftParamValue.value
                      ? new Date(contractDraftParamValue.value)
                      : null
                    : this.contract.contractType.code ===
                        this.global.appConstant.cm
                          .CONTRACT_TYPE_ADDENDUM_CONTRACT &&
                      contractDraftParamValue.contractTemplateParameter
                        .contractParameter.code ===
                        this.global.appConstant.cm
                          .CONTRACT_PARAMETER_NOMOR_KONTRAK
                    ? this.contract.fromContract.number
                    : contractDraftParamValue.value,
                  !contractDraftParamValue.contractTemplateParameter.contractParameter.code.includes(
                    'PERUBAHAN'
                  )
                    ? Validators.required()
                    : null
                ],
                valueTypeCode:
                  contractDraftParamValue.contractTemplateParameter
                    .contractParameter.valueType.code,
                contractTemplateParameter:
                  contractDraftParamValue.contractTemplateParameter
              });
              if (this.todo === 'view' || this.todo === 'detail') {
                formGroup.setIsView(true);
              }
              if (
                this.contract.contractType.code ===
                  this.global.appConstant.cm
                    .CONTRACT_TYPE_ADDENDUM_CONTRACT &&
                contractDraftParamValue.contractTemplateParameter
                  .contractParameter.code ===
                  this.global.appConstant.cm.CONTRACT_PARAMETER_NOMOR_KONTRAK
              ) {
                formGroup.get('value').setIsView(true);
              }
              this.contractTemplateParamList.push(formGroup);
            }
          );
          this.showTables = true;
          this.isParameterReady = true;
        } else {
          this.isParameterReady = true;
        }

        if (this.response.existingContractReviewerList) {
          this.formGroupReviewer.patchValue({
            reviewerList: this.response.existingContractReviewerList
          });
          this.reviewerOptionList.setRequestValues(this.response.existingContractReviewerList);
          this.tableResponseReviewer.setRecordList(this.response.existingContractReviewerList);
          this.tableResponseReviewer.reload();
        }
        this.formGroupReviewer.setIsView(true);
        if (response.contractDraft) {
          this.formGroup.patchValue({
            contractTemplate: this.response
              .contractDraft
              ? this.response.contractDraft.contractTemplate
              : null
          });
        }
        if(this.prItemReleasedList.length > 0){
            this.isSourceReady = true;
        }
        this.setStateReady();
      });
  }

  public onChangeTemplateList(contractTemplate: ContractTemplate): void {
    this.isParamValueNull = false;
    this.checkParameterValue();
    if (contractTemplate && this.isParamValueNull) {
      this.changeTemplate(contractTemplate);
    } else if (contractTemplate) {
      this.showTables = false;
      const prompt = this.translateService.instant(
        'contract-draft.confirm.changeTemp.msg'
      );
      const title = this.translateService.instant(
        'contract-draft.confirm.changeTemp.title'
      );
      this.global.modalService
        .confirm(prompt, title)
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.isParamValueNull = true;
            this.changeTemplate(contractTemplate);
          } else {
            this.formGroup.patchValue({
              contractTemplate: this.response
                .contractDraft
                ? this.response.contractDraft
                    .contractTemplate
                : null
            });
          }
        });
    } else {
      this.showTables = false;
      this.contractTemplate = contractTemplate;
      this.contractTemplateParamList.clear();
    }
  }

  public checkParameterValue(): void {
    const contractTemplateParamList =
      this.formGroup.value.contractTemplateParamList;
    if (this.contractTemplateParamList.length !== 0) {
      let parameterNull = true;
      contractTemplateParamList.forEach(parameter => {
        if (parameter.value) {
          parameterNull = false;
        }
      });
      if (!parameterNull) {
        this.isParamValueNull = false;
      } else {
        this.isParamValueNull = true;
      }
    } else {
      this.isParamValueNull = true;
    }
  }

  public changeTemplate(contractTemplate: ContractTemplate): void {
    this.showTables = false;
    this.contractTemplate = contractTemplate;
    this.httpClientService
      .get<ContractTemplateParameter[]>(
        '/contract-draft/contract-parameter/' + contractTemplate.id
      )
      .subscribe(
        (contractTemplateParameterList: ContractTemplateParameter[]) => {
          if (this.response.contractDraft) {
            this.response.contractDraft.contractTemplate =
              contractTemplate;
          } else {
            const contractDraft: ContractDraft = new ContractDraft();
            contractDraft.contractTemplate = contractTemplate;
            this.response.contractDraft = contractDraft;
          }
          this.contractTemplateParamList.clear();
          this.contractTemplateParameterList = contractTemplateParameterList;
          this.contractTemplateParameterList.forEach(contractTemplateParam => {
            const formGroup = this.formBuilder.group({
              id: [null],
              code: contractTemplateParam.contractParameter.code,
              name: contractTemplateParam.contractParameter.name,
              value: [
                this.contract.contractType.code ===
                  this.global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT &&
                contractTemplateParam.contractParameter.code ===
                  this.global.appConstant.cm.CONTRACT_PARAMETER_NOMOR_KONTRAK
                  ? this.contract.fromContract.number
                  : contractTemplateParam.contractParameter.code ===
                    this.global.appConstant.cm.CONTRACT_PARAMETER_NOMOR_KONTRAK
                  ? this.contract.number
                  : null,
                !contractTemplateParam.contractParameter.code.includes(
                  'PERUBAHAN'
                )
                  ? Validators.required()
                  : null
              ],
              valueTypeCode:
                contractTemplateParam.contractParameter.valueType.code,
              contractTemplateParameter: contractTemplateParam
            });
            if (this.todo === 'view' || this.todo === 'detail') {
              formGroup.setIsView(true);
            }
            if (
              this.contract.contractType.code ===
                this.global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT &&
              contractTemplateParam.contractParameter.code ===
                this.global.appConstant.cm.CONTRACT_PARAMETER_NOMOR_KONTRAK
            ) {
              formGroup.get('value').setIsView(true);
            }
            this.contractTemplateParamList.push(formGroup);
          });
          this.showTables = true;
          this.isParameterReady = true;
          this.validateGenerate = false;
        }
      );
  }

  public onChangeValue(): void {
    const contractTemplateParamList =
      this.formGroup.value.contractTemplateParamList;
    const contractDraftParamValueList: ContractDraftParameterValue[] = [];
    if (contractTemplateParamList) {
      contractTemplateParamList.forEach(parameter => {
        const contractDraftParameterValue: ContractDraftParameterValue =
          new ContractDraftParameterValue();
        contractDraftParameterValue.contractTemplateParameter =
          parameter.contractTemplateParameter;
        contractDraftParameterValue.value = parameter.value;
        if (parameter.value) {
          this.isParamValueNull = false;
        }
        if (
          parameter.code ===
          this.global.appConstant.cm.CONTRACT_PARAMETER_NOMOR_KONTRAK
        ) {
          this.contract.number = parameter.value;
        }
        contractDraftParamValueList.push(contractDraftParameterValue);
      });
    }
    this.response.contractDraftParameterValueList =
      contractDraftParamValueList;
    this.validateGenerate = false;
  }

  public doGenerate(event: any): void {
    this.validate();
    if (this.formGroup.valid) {
        this.setStateProcessing();
        this.formGroupVersion.disable();
        const contractTemplateParamList =
          this.formGroup.value.contractTemplateParamList;
        const contractDraftParamValueList: ContractDraftParameterValue[] =
          [];
        if (contractTemplateParamList) {
          contractTemplateParamList.forEach(parameter => {
            const contractDraftParameterValue: ContractDraftParameterValue =
              new ContractDraftParameterValue();
            contractDraftParameterValue.id = parameter.id;
            contractDraftParameterValue.contractTemplateParameter =
              parameter.contractTemplateParameter;
            contractDraftParameterValue.value = parameter.value;
            contractDraftParamValueList.push(contractDraftParameterValue);
          });
        }
        this.contractDraftGenerateRequest.contract =
          this.response.contract;
        this.contractDraftGenerateRequest.contractTemplate =
          this.formGroup.value.contractTemplate;
        this.contractDraftGenerateRequest.contractDraftParameterValueList =
          contractDraftParamValueList;
        const url = '/contract-draft/generate';
        this.httpClientService
          .post<Response<ContractDraftGenerateResponse>>(
            url,
            this.contractDraftGenerateRequest
          )
          .subscribe(response => {
            this.setStateReady();
            this.formGroupVersion.enable();
            if (response.status === ResponseStatusModel.OK) {
              this.global.alertService.showSuccess(
                'contract-draft.generate.success'
              );
              const contractDraftGenerateResponse: ContractDraftGenerateResponse =
                response.body;
              this.response.contractDraft =
                contractDraftGenerateResponse.contractDraft;
              const file: File = contractDraftGenerateResponse.contractDraftHistory.generatedFile;
              this.doDownload(
                event,
                '/contract-draft/file/view/' +
                  file.uploadedFileName +
                  '/' +
                  file.fileType.code,
                file.fileName
              );
              contractDraftGenerateResponse.contractDraftParameterValueList.forEach(
                (paramValue, index) => {
                  this.contractTemplateParamList
                    .at(index)
                    .get('id')
                    .setValue(paramValue.id);
                  this.contractTemplateParamList
                    .at(index)
                    .get('id')
                    .updateValueAndValidity();
                }
              );
              this.validateGenerate = true;
              this.formGroupVersion.patchValue({
                file: contractDraftGenerateResponse.contractDraftHistory
                  .generatedFile
              });
            } else {
              this.global.alertService.showError(response.statusText);
            }
          });
    }
  }

  public validateFormVersion(): void {
    this.formGroupVersion['submitted'] = true;
    this.formGroupService.validateAllFormFields(this.formGroupVersion);
    if (!this.formGroupVersion.valid) {
      this.formGroupVersion['submitted'] = false;
    }
  }

  public viewVersionHistory(): void {
    this.appPopupService.open(
      AppPopupContractDraftVersionHistoryComponent,
      { modelList: this.response.appPopupVersionHistoryModelList },
      { size: 'lg', backdrop: 'static' }
    );
  }

  public doAddUser(): void {
    this.appPopupService
      .open(
        AppPopupContractReviewerComponent,
        {
          user: this.response.user,
          existingContractReviewerList: this.response.existingContractReviewerList,
          addContractReviewerList: this.response.addContractReviewerList
        },
        { size: 'lg' }
      )
      .subscribe((contractReviewerList: ContractReviewer[]) => {
        this.response.contractCollabAccessList = contractReviewerList;
        this.response.existingContractReviewerList = contractReviewerList;
        this.formGroupReviewer.patchValue({
          reviewerList: contractReviewerList
        });
        this.reviewerOptionList.setRequestValues(contractReviewerList);
        this.formGroupReviewer.setIsView(true);
        this.formGroupReviewer.disable();
        this.tableResponseReviewer.setRecordList(contractReviewerList);
        this.tableResponseReviewer.reload();
      });
  }

  public prepareContractDraftParameterValue(): void {
    const contractTemplateList: ContractTemplate[] = [];
    if (this.formGroup.value.contractTemplate) {
      contractTemplateList.push(this.formGroup.value.contractTemplate);
    }
    this.request.contractTemplateList = contractTemplateList;

    const contractDraftHistory: ContractDraftHistory =
      new ContractDraftHistory();
    contractDraftHistory.note = this.formGroupVersion.value.note;
    contractDraftHistory.id = this.formGroupVersion.value.id;
    contractDraftHistory.generatedFile = this.formGroupVersion.value.file;
    const fileObjectList = this.formGroupVersion.value.uploadFile;
    if (fileObjectList && typeof fileObjectList.length !== 'undefined') {
      if (fileObjectList.length !== 0) {
        contractDraftHistory.draftFile = fileObjectList[0].isDeleted
          ? null
          : fileObjectList[0].file;
        this.request.fileObjectList = fileObjectList;
      } else {
        contractDraftHistory.draftFile = null;
        this.request.fileObjectList = [];
      }
    } else {
      contractDraftHistory.draftFile = this.formGroupVersion.value.uploadFile;
      this.request.fileObjectList = [];
    }
    this.request.contractDraftHistory = contractDraftHistory;

    const contractTemplateParamList =
      this.formGroup.value.contractTemplateParamList;
    const contractDraftParamValueList: ContractDraftParameterValue[] = [];
    if (contractTemplateParamList) {
      contractTemplateParamList.forEach(parameter => {
        const contractDraftParameterValue: ContractDraftParameterValue =
          new ContractDraftParameterValue();
        contractDraftParameterValue.id = parameter.id;
        contractDraftParameterValue.contractTemplateParameter =
          parameter.contractTemplateParameter;
        contractDraftParameterValue.value = parameter.value;
        if (parameter.value) {
          this.isParamValueNull = false;
        }
        contractDraftParamValueList.push(contractDraftParameterValue);
      });
    }
    this.request.contractDraft =
      this.response.contractDraft;
    this.request.contract = this.contract;
    this.request.contractDraftParameterValueList =
      contractDraftParamValueList;
    this.request.contractCollabAccessList =
      this.response.contractCollabAccessList;
  }

  public doSaveDraft(): void {
    this.validate();
    if (this.formGroup.valid) {
          this.setStateProcessing();
          this.formGroupVersion.disable();
          this.request.isSave = false;
          this.prepareContractDraftParameterValue();
          const url = '/contract-draft/update';
          this.httpClientService
            .post<Response<ContractDraft>>(url, this.request)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.snackbarService.showWarning('app.alert.msg.saveSuccess');
                this.router
                  .navigateByUrl('/', { skipLocationChange: true })
                  .then(() => {
                    this.global.routerParams.clear();
                    this.global.routerParams.set('contractId', response.body.id);
                    this.global.routerParams.set('urlBackOutside', '/pages/worklist-cm');
                    this.global.routerParams.set('todo', 'edit');
                    this.router.navigate(['/pages/contract-draft/edit']);
                  });
              } else {
                this.toastService.showError(response.statusText);
              }
              this.setStateReady();
              this.formGroupVersion.enable();
            });
        }
  }

  public doSave(): void {
    this.validate();
    this.validateFormVersion();
    if (this.validateGenerate) {
      if(this.formGroupVersion.valid && this.formGroupVersion.value.uploadFile){
          this.global.modalService
          .newSubmitConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              this.formGroupVersion.disable();
              this.request.isSave = true;
              this.prepareContractDraftParameterValue();
              const url = '/contract-draft/update';
              this.httpClientService
                .post<Response<ContractDraft>>(
                  url,
                  this.request
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                      this.global.modalService
                        .submitSuccess()
                        .subscribe(result => {
                          if (result) {
                            this.router.navigate(['/pages/worklist-cm']);
                          } else {
                            this.setStateLoading();
                            setTimeout(() => {
                              this.router
                                .navigateByUrl('/', { skipLocationChange: true })
                                .then(() => {
                                  this.global.routerParams.clear();
                                  this.global.routerParams.set('todo', 'view');
                                  this.global.routerParams.set('contractId', this.contractId);
                                  this.router.navigate(['/pages/contract-draft/detail']);
                                  this.global.routerParams.set(
                                    'urlBackOutside',
                                    '/pages/worklist-cm'
                                  );
                                });

                              this.setStateReady();
                            }, 50);
                          }
                        });
                  } else {
                    this.global.alertService.showError(response.statusText);
                  }
                  this.setStateReady();
                  this.formGroupVersion.enable();
                });
            }
          });
      }else{
        this.toastService.showError(
          this.translateService.instant('contract-draft.validation.uploadFile')
        );
      }
    } else {
      this.toastService.showError(
        this.translateService.instant('contract-draft.validation.generateDocument')
      );
    }
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }
 
}
