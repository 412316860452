<app-popup
  header="app-popup-fines.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="formGroup.isView"
>
  <div class="main-row-popup-fines">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-fines.form.type' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-dropdown-select
            size="XL"
            [optionList]="finesMasterOptionList"
            formControlName="finesMaster"
            (onChange)="onChangeFines()"
          ></app-dropdown-select>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-fines.form.percentageValue' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="XL"
            type="decimal"
            formControlName="percentageValue"
            maxLength="5"
            groupText="%"
            (onInput)="onInputPercentage()"
          >
          </app-text-field>
          <label *ngIf="formGroup.isView">%</label>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-fines.form.amount' | translate
        }}</label>
        <div class="col-sm-9 text-left">
            {{ amount | resolveNumberToCurrency }}
            <strong>IDR</strong>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-fines.form.description' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-area
            size="XL"
            formControlName="description"
            maxLength="2000"
          ></app-text-area>
        </div>
      </div>

      <ng-template *ngIf="!formGroup.isView" #modalFooter let-activeModal>
        <div class="button-group button-group-center">
          <app-button
            color="SECONDARY"
            [outline]="true"
            (onClick)="activeModal.close(true)"
            >{{ 'app.button.cancel' | translate }}</app-button
          >
          <app-button color="PRIMARY" (onClick)="doSave()">{{
            'app.button.add' | translate
          }}</app-button>
        </div>
      </ng-template>
    </form>
  </div>
</app-popup>
