<div class="signature-card" style="width: 450px">
  <div class="app-signature-box">
    <div class="app-signature-head" [hidden]="ISVIEW">
      <em
        class="pir pi-pencil-alt pi-3x cursor-pointer"
        (click)="doDraw()"
        style="margin-right: 28px"
        [ngStyle]="{
          color: isDraw ? 'var(--primary)' : ''
        }"
      ></em>
      <em
        class="pir pi-eraser pi-3x cursor-pointer"
        (click)="doErase()"
        style="margin-right: 13.5px"
        [ngStyle]="{
          color: !isDraw ? 'var(--primary)' : ''
        }"
      ></em>
    </div>
    <div class="app-signature-body" [hidden]="ISVIEW">
      <canvas
        #canvas
        [width]="customWidth ? customWidth : widthCavas"
        [height]="heightCavas"
        [ngStyle]="{ 'border-top': '1px solid var(--gray-400)' }"
      ></canvas>
    </div>
    <div [hidden]="!ISVIEW">
      <img [src]="urlSrc" />
    </div>
  </div>
  <label
    [ngStyle]="{ 'margin-top': '12px', color: 'var(--primary)' }"
    *ngIf="!ISVIEW"
    >{{ 'app-signature.information' | translate }}</label
  >
</div>
