import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BudgetItemView } from '../../../view/entity/bean/budget-item-view';
import { AppPopupService } from '../app-popup.service';
import { AppPopupAddBudgetItemComponent } from './app-popup-add-budget-item.component';

@Injectable()
export class AppPopupAddBudgetItemService {
  constructor(public appPopupService: AppPopupService) {}
  public open(
    isMultiple?: boolean,
    budgetItemViewList?: BudgetItemView[],
    usedBudgetList?: Map<number, number>,
    prItemBudgetEditList?: Map<number, number>,
    paymentId?: number,
    paymentBillingIdList?: number[]
  ): Observable<any> {
    const isMultipleValue = isMultiple ? isMultiple : false;
    return this.appPopupService.open(
      AppPopupAddBudgetItemComponent,
      {
        isMultiple: isMultipleValue,
        budgetItemViewList,
        usedBudgetList,
        prItemBudgetEditList,
        paymentId,
        paymentBillingIdList
      },
      { size: 'xl' }
    );
  }
}
