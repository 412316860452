export const createTableCustomData = (customData: object | string): string => {
  let customDataString: any;
  if (!customData || customData === null) {
    customDataString = null;
  } else {
    customDataString =
      typeof customData === 'object' && Object.values(customData).length === 0
        ? null
        : typeof customData === 'string'
        ? customData
        : JSON.stringify(customData);
  }
  return customDataString;
};
