<ng-container *ngIf="ISVIEW; then isViewTmpl; else isNotViewTmpl">
</ng-container>
<ng-template #isNotViewTmpl>
  <div
    class="app-text-tree p-0"
    [ngClass]="[isInvalid ? 'is-invalid' : '', isValid ? 'is-valid' : '']"
  >
    <app-choose
      (onClick)="disabled ? '' : doOpenPopupTree($event)"
      [formControl]="formControl"
      [placeholder]="placeholder"
      [isMultiple]="isMultiple"
      [tooltip]="tooltip"
      [tooltipPosition]="tooltipPosition"
      [size]="size"
      [height]="height"
    >
      <ng-template #tooltipContent>
        <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
        </ng-container>
      </ng-template>
    </app-choose>
  </div>
</ng-template>
<ng-template #isViewTmpl>
  <div class="d-inline-block position-relative">
    <ng-container *ngIf="formControl.value">
      <ul class="app-text-tree-list" *ngIf="isMultiple">
        <li *ngFor="let val of formControl.value">{{ val.name }}</li>
      </ul>
      <ul class="ul-nested-organization" *ngIf="!isMultiple">
        <li *ngFor="let val of formControl.value">{{ val.name }}</li>
      </ul>
    </ng-container>
    <span *ngIf="!formControl.value">-</span>
    <app-tooltip-wrapper
      [tooltip]="tooltip"
      [position]="tooltipPosition"
      [symbolic]="symbolic"
      [isView]="ISVIEW"
    >
      <app-tooltip-wrapper-item>
        <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
        </ng-container>
      </app-tooltip-wrapper-item>
    </app-tooltip-wrapper>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <ng-content select="app-flag-new"></ng-content>
</ng-template>
