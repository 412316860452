import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Currency } from 'src/app/core/bean/currency';
import { Region } from 'src/app/core/bean/region';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { TabModel } from '../../../core/model/tab/tab-model';
import { Validators } from '../../../core/validators';
import { ManageCatalogDTO } from '../dto/manage-catalog.dto';
@Component({
  templateUrl: './shipping-cost.component.html'
})
export class ShippingCostComponent extends BaseModuleComponent {
  userId = this.global.userSession.user.id;
  public manageCatalogDTO: ManageCatalogDTO = new ManageCatalogDTO();
  public shippingCostTab: TabModel;
  public isTodoView;
  public shippingCostListIsNotShowing = true;
  public isVendor: boolean;
  public isOther: boolean;
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );
  public regionShippingCostOptionList: OptionListModel<Region> = new OptionListModel();
  
  constructor(translateService: TranslateService) {
    super('manage-catalog', translateService);
  }

  onInit(): void {
    this.isVendor = this.global.userSession.vendor? true: false;
    this.isOther = this.isVendor? false : this.global.userSession.activeUserRole.role.type 
    === this.global.appConstant.core.ROLE_TYPE_OTHER? true: false;
    this.setCatalog();
    this.buildFormGroup();
    this.setOptionListModel();
    this.isOther ? this.shippingCostTab.setIsView(true)
    :this.shippingCostTab.setIsView(this.isTodoView);
    if (
      !this.shippingCostList.controls ||
      this.shippingCostList.controls.length === 0
    ) {
      this.addMoreShippingCost();
    }
    this.setStateReady();
  }

  public setCatalog(): void {
    this.shippingCostTab = this.global.routerParams
      .get('tabResponse')
      .tabs.get('SHIPPING_COST');
    this.isTodoView = this.global.routerParams.get('tabResponse').isView;
    this.manageCatalogDTO = this.global.routerParams.get('manageCatalogDTO');
    if(this.isTodoView === false){
      this.shippingCostListIsNotShowing = false;
    }else{
      if (this.manageCatalogDTO.catalogShippingCostList) {
        if(this.manageCatalogDTO?.catalogShippingCostList[0]?.region != null 
        || this.manageCatalogDTO?.catalogShippingCostList[0]?.amount != null 
        || this.manageCatalogDTO?.catalogShippingCostList[0]?.deliveryEstimateDayTotal != null){
            this.shippingCostListIsNotShowing = false;
          }
        }
    }

  }

  public setOptionListModel(): void {
    this.currencyOptionList.setRequestValues(
      this.manageCatalogDTO.currencyList
    );
    this.regionShippingCostOptionList.setRequestValues(
      this.manageCatalogDTO.regionShippingCostList
    );
  }

  public buildFormGroup(): void {
    if (!this.shippingCostTab.isFormGroupReady) {
      this.shippingCostTab.formGroup = this.formBuilder.group({
        shippingCostList: this.formBuilder.array([])
      });

      if (this.manageCatalogDTO.catalogShippingCostList != null) {
          this.manageCatalogDTO.catalogShippingCostList.forEach(catalogShippingCost => {
            const amountTemp = {
              price: catalogShippingCost.amount,
              currency: this.manageCatalogDTO.currencyList[0]
            }
            const formGroup = this.formBuilder.group({
              region: [
                catalogShippingCost?.region,
                Validators.maxLength(128)
              ],
              amount: [
                amountTemp,
                Validators.maxLength(16)
              ],
              deliveryEstimateDayTotal: [
                catalogShippingCost.deliveryEstimateDayTotal,
                Validators.maxLength(11)
              ]
            });
            this.shippingCostList.push(formGroup);
            this.isOther ? formGroup.setIsView(true)
            :formGroup.setIsView(this.isTodoView);
          });
      }
    }
  }

  public doDeleteShippingCost(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.shippingCostList.removeAt(index);
        }
      });
  }

  public addMoreShippingCost(): void {
    this.shippingCostList.push(
      this.formBuilder.group({
        region: [null, Validators.maxLength(128)],
        amount: [null, Validators.maxLength(16)],
        deliveryEstimateDayTotal: [null, Validators.maxLength(11)],
      })
    );
  }

  public get shippingCostList(): FormArray {
    return this.shippingCostTab.formGroup.get('shippingCostList') as FormArray;
  }

  public get formArrayShippingCostList(): FormArray {
    return this.shippingCostTab.formGroup.get('shippingCostList') as FormArray;
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
