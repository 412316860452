<app-popup
  [isLoading]="formLoading"
  header="workload.popup.moveUser.header"
  [isShowHeaderCloseButton]="false"
>
  <div class="popup-move-user"></div>
  <form [formGroup]="formGroup">
    <app-select
      [isMultiple]="true"
      formControlName="workloadGenerateModel"
      [optionList]="workloadGenerateModelOptionList"
      maxHeight="400px"
      [isShowBorder]="true"
      [search]="true"
      selectAllText="{{ 'workload.popup.form.selectAll' | translate }}"
    >
    </app-select>
  </form>
  <div class="button-group button-group-center mt-5">
    <app-button [outline]="true" color="SECONDARY" (onClick)="doCancel()">{{
      'app.button.cancel' | translate
    }}</app-button>
    <app-button (onClick)="doAdd()">{{
      'app.button.add' | translate
    }}</app-button>
  </div>
</app-popup>
