import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  selector: 'app-fieldset',
  templateUrl: './app-fieldset.component.html',
  styleUrls: ['./app-fieldset.component.scss']
})
export class AppFieldsetComponent extends BaseComponentComponent {
  @Input() public legend: string;
  @Input() public required: boolean;

  constructor() {
    super('app-fieldset');
  }

  onInit(): void {
    this.setStateReady();
  }
}
