<app-popup
  header="app-popup-choose-bill.title"
  [isShowHeaderCloseButton]="false"
  [isLoading]="formLoading"
>
  <div class="main-row-popup-choose-bill">
    <form
      class="form-horizontal popup-choose-bill"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <app-table-xx
        [model]="tableResponse"
        stringUrl="/app-popup-choose-bill/index"
        [isShowCheckBox]="true"
        [isMultipleSelect]="false"
        [isShowButtonExpandCollapse]="false"
      >
      </app-table-xx>
    </form>
    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          class="mr-1"
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button (onClick)="doChoose()">{{
          'app.button.choose' | translate
        }}</app-button>
      </div>
    </ng-template>
  </div>
</app-popup>
