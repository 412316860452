import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../base/angular/base-module.component';
import { WorkPlan } from '../../../bean/work-plan';
import { FieldFormatEnum } from '../../app-table/model/field-format.enum';
import { TableResponseModel } from '../../app-table/model/table-response-model';
@Component({
  templateUrl: './app-popup-work-plan.component.html'
})
export class AppPopupWorkPlanComponent extends BaseModuleComponent {
  @Input() workPlanId: number;
  @Input() workPlanIdCustomData: any;
  @Output() onChange: EventEmitter<WorkPlan> = new EventEmitter();

  tableResponse: TableResponseModel<WorkPlan>;

  constructor(translateService: TranslateService) {
    super('app-popup-work-plan', translateService);
  }

  onInit(): void {
    this.setWorkPlanIdCustomData();
    this.buildTableResponse();
  }

  setWorkPlanIdCustomData(): void {
    this.workPlanIdCustomData = JSON.stringify(this.workPlanId);
  }

  buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'number', header: 'table.column.number' },
      { field: 'name', header: 'table.column.name' },
      { field: 'organization.name', header: 'table.column.organization' },
      {
        field: 'totalPriceItem',
        header: 'table.column.totalPriceItem',
        sortable: false,
        format: FieldFormatEnum.Currency
      }
    ]);
  }

  public doChoose(workPlan: WorkPlan): void {
    this.onChange.emit(workPlan);
  }
}
