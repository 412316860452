import { NgModule } from '@angular/core';
import { AppCardModule } from '../components/app-card/app-card.module';
import { VendorModule } from '../vendor/vendor.module';
import { AppWidgetCardComponent } from './app-widget-card.component';
import { AppWidgetComponent } from './app-widget.component';
@NgModule({
  imports: [VendorModule, AppCardModule],
  declarations: [AppWidgetComponent, AppWidgetCardComponent],
  exports: [AppWidgetComponent, AppWidgetCardComponent],
})
export class AppWidgetModule {}
