<div class="row main-row">
  <div class="col-12">
    <app-card header="catalog-attribute-group.title"
      detail="app.action.{{todo}}" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'catalog-attribute-group.form.name' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field autofocus="true" formControlName="name"
              maxLength="32"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'catalog-attribute-group.form.description' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" type="alphanumeric"
              maxLength="512"></app-text-area>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'catalog-attribute-group.form.status' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box [optionList]="statusOptionList"
              formControlName="isActive"></app-combo-box>
          </div>
        </div>

        <h5 class="separator mt-3">{{ 'catalog-attribute-group.additional' |
          translate }}</h5>
        <div class="removeSearchText app-table-search">
          <app-table #selectorCatalogAttribute [tableResponse]="tableResponse"
            [isServerSide]="false" *ngIf="tableReady">
            <ng-template #headerButtons>
              <button class="btn btn-primary ml-2" (click)="doAdd()"><em
                  class="fas fa-plus"></em>&nbsp;
                {{ 'app.button.add' | translate }}</button>
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <button class="btn btn-danger btn-sm mr-2" type="button"
                (click)="doDelete(rowData)"
                title="{{ 'app.tooltip.delete' | translate }}">
                <span class="fa fas fa-trash"></span>
              </button>
            </ng-template>
          </app-table>
        </div>
      </form>
      <div class="row justify-content-center mt-5">
        <div class="col-12 text-center">
          <app-button color="SECONDARY" (onClick)="doCancel()" class="mr-1">{{
            'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()">{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </div>
    </app-card>
  </div>
</div>