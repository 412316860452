import { ChartDataSets, ChartTooltipItem } from 'chart.js';
import { Global } from '../../../global/global';
import { Log } from '../../../services/logger';
import { ServiceLocator } from '../../../services/service-locator';
import { ChartModel } from '../../app-chart/models/chart-model';
import { ActualPlanBarChartWorkPlanInfoDTO } from '../dto/actual-plan-bar-chart-work-plan-Info.dto';
import { ActualPlanBarChartInfoModel } from '../model/actual-plan-bar-chart-info-model';
import { ActualPlanBarChartModel } from '../model/actual-plan-bar-chart-model';
export function setWorkPlanInfo(
  state: ActualPlanBarChartModel,
  payload: ActualPlanBarChartWorkPlanInfoDTO
): ChartModel {
  const log = new Log(this);
  log.debug(state);
  const global: Global = ServiceLocator.injector.get(Global);
  const {
    perYearPlanWorkPlanTotal,
    realizedWorkPlanTotal,
    actualPlanBarChartInfoModelList
  } = payload;
  const labels: Array<string> = new Array();
  const dataSets: Array<ChartDataSets> = new Array();

  const planWorkPlanList: Array<number> = new Array();
  const realizedWorkPlanPlanList: Array<number> = new Array();

  const tooltipContentList: Array<{
    month: number;
    value: Array<{ title: string; value: string }>;
  }> = [];
  let tooltipList: Array<{ title: string; value: string }> = [];
  const title = [
    global.translateService.instant(
      'app-actual-plan-bar-chart-work-plan-info.workPlanTotal'
    ),
    global.translateService.instant(
      'app-actual-plan-bar-chart-work-plan-info.realizedWorkPlan'
    )
  ];

  actualPlanBarChartInfoModelList.forEach(
    (
      actualPlanBarChartInfoModel: ActualPlanBarChartInfoModel,
      index: number
    ) => {
      labels.push(actualPlanBarChartInfoModel.month);
      planWorkPlanList.push(actualPlanBarChartInfoModel.planWorkPlan);
      realizedWorkPlanPlanList.push(
        actualPlanBarChartInfoModel.realizedWorkPlan
      );
      const value = [
        actualPlanBarChartInfoModel.planWorkPlan,
        actualPlanBarChartInfoModel.realizedWorkPlan
      ];
      tooltipList = [];
      for (let i = 0; i < 2; i++) {
        tooltipList.push({
          title: title[i],
          value: value[i].toString()
        });
      }
      tooltipContentList.push({ month: index, value: tooltipList });
    }
  );

  dataSets.push({
    data: planWorkPlanList
  });

  dataSets.push({
    data: realizedWorkPlanPlanList
  });

  const chartModel = new ChartModel(
    'bar',
    labels,
    dataSets,
    [
      global.translateService.instant(
        'app-actual-plan-bar-chart-work-plan-info.workPlanTotal'
      ) +
        ': ' +
        perYearPlanWorkPlanTotal,
      global.translateService.instant(
        'app-actual-plan-bar-chart-work-plan-info.realizedWorkPlan'
      ) +
        ': ' +
        realizedWorkPlanTotal
    ],
    {
      legend: {
        display: true,
        position: 'top',
        labels: {
          usePointStyle: true
        },
        align: 'start'
      },
      defaultColor: ['#204d95 ', '#782095', '#3d9520'],
      tooltips: {
        callbacks: {
          label: (item: ChartTooltipItem, data: Chart.ChartData) => {
            log.debug(data);
            const tooltipData =
              tooltipContentList[item.index].value[item.datasetIndex];
            return tooltipData.title + ': ' + tooltipData.value;
          }
        }
      }
    }
  );
  return chartModel;
}
