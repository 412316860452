import { BillingTerm } from 'src/app/core/bean/billing-term';
import { ContractCancelationDoc } from 'src/app/core/bean/contract-cancelation-doc';
import { ContractType } from 'src/app/core/bean/contract-type';
import { FinesMaster } from 'src/app/core/bean/fines-master';
import { GuaranteeBondType } from 'src/app/core/bean/guarantee-bond-type';
import { Module } from 'src/app/core/bean/module';
import { PaymentTerm } from 'src/app/core/bean/payment-term';
import { ProcurementResult } from 'src/app/core/bean/procurement-result';
import { StageOfWork } from 'src/app/core/bean/stage-of-work';
import { Contract } from '../../core/bean/contract';
import { ContractCancelation } from '../../core/bean/contract-cancelation';
import { ProcurementFines } from '../../core/bean/procurement-fines';
import { ProcurementGuarantee } from '../../core/bean/procurement-guarantee';
import { ProcurementSow } from '../../core/bean/procurement-sow';

export class ContractRequestResponse {
  contract: Contract = new Contract();
  isUserApproval: boolean;
  procurementSowList: ProcurementSow[] = [];
  procurementFinesList: ProcurementFines[] = [];
  procurementGuaranteeList: ProcurementGuarantee[] = [];
  finesMasterList: FinesMaster[] = [];
  guaranteeBondTypeList: GuaranteeBondType[] = [];
  paymentTermList: PaymentTerm[] = [];
  stageOfWorkList: StageOfWork[] = [];
  contractTypeList: ContractType[] = [];
  billingTermList: BillingTerm[] = [];
  procurementResultProductList: ProcurementResult[] = [];
  procurementResultServiceList: ProcurementResult[] = [];
  contractCanceled: ContractCancelation = new ContractCancelation();
  contractCanceledDate: Date = new Date();
  module: Module;
  contractCancelationDoc: ContractCancelationDoc = new ContractCancelationDoc();
}
