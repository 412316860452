<div class="catalog-similar-product">
  <div class="catalog-similar-product-header">
    <h5 class="similar-product-title">{{ 'view-catalog.similarProductFromOtherVendor' | translate }}</h5>
  </div>
  <div class="catalog-similar-product-content">
    <div class="d-block text-center" *ngIf="similarProductList && similarProductList.length === 0">
      {{ 'app.info.noData' | translate }}
    </div>
    <div class="similar-product-item" *ngFor="let item of similarProductList">
      <app-catalog-item [model]="item" (onChooseItem)="onChooseCatalogItem($event)"></app-catalog-item>
    </div>
  </div>
  <div class="catalog-similar-product-footer" *ngIf="similarProductList && similarProductList.length > 0">
    <app-pagination [model]="paginationModel" (onChange)="onPageChange($event)"></app-pagination>
  </div>
</div>
