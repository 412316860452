import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { BillingMemo } from 'src/app/core/bean/billing-memo';
import { BillingMemoBudget } from 'src/app/core/bean/billing-memo-budget';
import { BillingMemoOtherCost } from 'src/app/core/bean/billing-memo-other-cost';
import { BillingMemoPriority } from 'src/app/core/bean/billing-memo-priority';
import { BillingMemoTax } from 'src/app/core/bean/billing-memo-tax';
import { BudgetAllocation } from 'src/app/core/bean/budget-allocation';
import { Currency } from 'src/app/core/bean/currency';
import { PaymentMethod } from 'src/app/core/bean/payment-method';
import { PaymentStatus } from 'src/app/core/bean/payment-status';
import { Vendor } from 'src/app/core/bean/vendor';
import { VendorBank } from 'src/app/core/bean/vendor-bank';
import { VendorBankOffline } from 'src/app/core/bean/vendor-bank-offline';
import { VendorOffline } from 'src/app/core/bean/vendor-offline';
import { AppPopupBillingMemoBudgetDetailService } from 'src/app/core/components/app-popup/app-popup-billing-memo-budget-detail/app-popup-billing-memo-budget-detail.service';
import { AppPopupChooseBudgetService } from 'src/app/core/components/app-popup/app-popup-choose-budget/app-popup-choose-budget.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';
import { AppPopupBillingMemoItemDetailComponent } from './app-popup-billing-memo-item-detail.component';
import { BillingMemoEditResponse } from './billing-memo-edit.reponse';
import { BillingMemoEditRequest } from './billing-memo-edit.request';
import { BillingMemoUpdateRequest } from './billing-memo-update.request';

@Component({
  templateUrl: 'billing-memo-edit-add.component.html'
})
export class BillingMemoEditAddComponent extends BaseModuleComponent {
  public typeOptionList: OptionListModel<any> = new OptionListModel(false);
  public priorityList: OptionListModel<BillingMemoPriority> =
    new OptionListModel(false);
  public paymentMethodList: OptionListModel<PaymentMethod> =
    new OptionListModel(false);
  public billingMemoEditResponse: BillingMemoEditResponse =
    new BillingMemoEditResponse();
  public billingMemoUpdateRequest: BillingMemoUpdateRequest =
    new BillingMemoUpdateRequest();
  public vendor: Vendor;
  public vendorOffline: VendorOffline;
  public vendorBank: VendorBank;
  public vendorBankOffline: VendorBankOffline;
  public currency: Currency;
  public paymentStatus: PaymentStatus;
  public billingAmount: number;
  public paymentId: number;
  public paymentInvoiceId: number;
  public procurementVendorId: number;
  public totalTaxBase: number;
  public totalVAT: number;
  public totalProcurementValueTotal: number;
  public totalIncomeTax: number;
  public totalPaidToVendor: number;
  public subTotal: number;
  public isBudgetValid: boolean;
  public budgetNotValid: BudgetAllocation;
  public budgetMap: Map<number, number> = new Map();
  public isValid = true;

  constructor(
    translate: TranslateService,
    public appPopupChooseBudgetService: AppPopupChooseBudgetService,
    public appPopupService: AppPopupService,
    public appPopupBillingMemoBudgetDetailService: AppPopupBillingMemoBudgetDetailService
  ) {
    super('billing-memo', translate);
  }

  public onInit(): void {
    this.getDataFromRouterParams();
    this.buildFormGroup();
    this.setOptionList();
    this.setIsViewOnly();
    this.setFormGroup();
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setOptionList(): void {
    const typeList = [
      {
        name: this.global.translateService.instant('billing-memo.status.debit'),
        value: 1
      },
      {
        name: this.global.translateService.instant(
          'billing-memo.status.credit'
        ),
        value: 2
      }
    ];
    this.typeOptionList.setRequestValues(typeList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      number: [null, Validators.required()],
      date: [null, Validators.required()],
      priority: [null, Validators.required()],
      subject: [null, Validators.required()],
      background: [null, Validators.required()],
      base: [null, Validators.required()],
      closing: [null, Validators.required()],
      city: [null, Validators.required()],
      firstSigner: [null, Validators.required()],
      secondSigner: [null],
      paymentType: [null, Validators.required()],
      paymentMethod: [null, Validators.required()],
      isIncludeVAT: [null],
      taxList: this.formBuilder.array([]),
      budgetList: this.formBuilder.array([]),
      otherCostList: this.formBuilder.array([])
    });
  }

  public setFormGroup(): void {
    const billingMemoEditRequest: BillingMemoEditRequest =
      new BillingMemoEditRequest();
    billingMemoEditRequest.paymentId = this.paymentId;
    billingMemoEditRequest.procurementVendorId = this.procurementVendorId;
    this.httpClientService
      .get<BillingMemoEditResponse>(
        '/billing-memo/add-edit/' +
          this.paymentId +
          '/' +
          this.procurementVendorId
      )
      .subscribe((billingMemoEditResponse: BillingMemoEditResponse) => {
        this.billingMemoEditResponse = billingMemoEditResponse;
        this.vendor = this.billingMemoEditResponse.vendor;
        this.vendorBank = this.billingMemoEditResponse.vendorBank;
        this.vendorOffline = this.billingMemoEditResponse.vendorOffline;
        this.vendorBankOffline = this.billingMemoEditResponse.vendorBankOffline;

        this.priorityList.setRequestValues(
          this.billingMemoEditResponse.billingMemoPriorityList
        );
        this.paymentMethodList.setRequestValues(
          this.billingMemoEditResponse.paymentMethodList
        );

        if (this.billingMemoEditResponse.billingMemo) {
          this.formGroup.patchValue({
            id: this.billingMemoEditResponse.billingMemo.id,
            number: this.billingMemoEditResponse.billingMemo.number,
            date: this.billingMemoEditResponse.billingMemo.date
              ? new Date(this.billingMemoEditResponse.billingMemo.date)
              : null,
            isIncludeVAT: this.billingMemoEditResponse.billingMemo.isIncludeVAT,
            priority:
              this.billingMemoEditResponse.billingMemo.billingMemoPriority,
            subject: this.billingMemoEditResponse.billingMemo.subject,
            background: this.billingMemoEditResponse.billingMemo.background,
            base: this.billingMemoEditResponse.billingMemo.base,
            closing: this.billingMemoEditResponse.billingMemo.closing,
            city: this.billingMemoEditResponse.regionList,
            firstSigner: this.billingMemoEditResponse.firstSigner,
            secondSigner: this.billingMemoEditResponse.secondSigner,
            paymentType: this.billingMemoEditResponse.paymentTypeList,
            paymentMethod:
              this.billingMemoEditResponse.billingMemo.paymentMethod
          });

          this.billingMemoEditResponse.budgetAllocationList.forEach(
            budgetAllocation => {
              const formGroup = this.formBuilder.group({
                id: [null],
                coa: budgetAllocation.coa.code,
                costCenter: budgetAllocation.costCenter.name,
                taxPercentage: this.billingMemoEditResponse.companyPPN,
                taxBase: [null],
                vat: [null],
                total: [null],
                budgetAllocation,
                billingMemoBudgetDetailList: [null]
              });

              this.billingMemoEditResponse.billingMemoBudgetList.forEach(
                item => {
                  if (budgetAllocation.id === item.budgetAllocation.id) {
                    formGroup.patchValue({
                      id: item.id,
                      coa: item.budgetAllocation
                        ? item.budgetAllocation.coa.code
                        : null,
                      costCenter: item.budgetAllocation
                        ? item.budgetAllocation.costCenter.name
                        : null,
                      taxPercentage: item.taxPercentageValue,
                      taxBase: item.taxBaseAmount,
                      vat: item.vatAmount,
                      total: item.totalAmount,
                      budgetAllocation: item.budgetAllocation
                    });
                  }
                }
              );

              if (this.todo === 'view') {
                formGroup.setIsView(true);
              }
              this.budgetList.push(formGroup);
            }
          );

          this.billingMemoEditResponse.taxList.forEach(response => {
            const formGroup = this.formBuilder.group({
              id: [null],
              taxName: response.name,
              coa: response.coa,
              costCenter: [null],
              taxPercentage: response.amount,
              taxBase: [null],
              incomeTax: [null]
            });
            this.billingMemoEditResponse.billingMemoTaxList.forEach(
              billingMemoTax => {
                if (response.name === billingMemoTax.tax.name) {
                  formGroup.patchValue({
                    id: billingMemoTax.id,
                    coa: billingMemoTax.tax.coa,
                    costCenter: billingMemoTax.costCenter,
                    taxPercentage: billingMemoTax.taxPercentageValue,
                    taxBase: billingMemoTax.taxBaseAmount,
                    incomeTax: billingMemoTax.incomeTaxAmount
                  });
                  if (
                    !billingMemoTax.costCenter &&
                    billingMemoTax.taxBaseAmount
                  ) {
                    formGroup
                      .get('costCenter')
                      .setValidators(Validators.required());
                    formGroup.get('costCenter').updateValueAndValidity();
                  }
                  if (
                    !billingMemoTax.taxBaseAmount &&
                    billingMemoTax.costCenter
                  ) {
                    formGroup
                      .get('taxBase')
                      .setValidators(Validators.required());
                    formGroup.get('taxBase').updateValueAndValidity();
                  }
                }
              }
            );
            if (this.todo === 'view') {
              formGroup.setIsView(true);
            }
            this.taxList.push(formGroup);
          });

          this.billingMemoEditResponse.billingMemoOtherCostList.forEach(
            otherCost => {
              const type = otherCost.transactionType
                ? otherCost.transactionType === 1
                  ? {
                      name: this.global.translateService.instant(
                        'billing-memo.status.debit'
                      ),
                      value: otherCost.transactionType
                    }
                  : {
                      name: this.global.translateService.instant(
                        'billing-memo.status.credit'
                      ),
                      value: otherCost.transactionType
                    }
                : null;
              const formGroup = this.formBuilder.group({
                id: otherCost.id,
                itemName: [
                  otherCost.name,
                  !otherCost.name &&
                  (otherCost.amount || otherCost.budgetAllocation || type)
                    ? Validators.required()
                    : null
                ],
                coa: otherCost.budgetAllocation
                  ? otherCost.budgetAllocation.coa.code
                  : null,
                costCenter: otherCost.budgetAllocation
                  ? otherCost.budgetAllocation.costCenter.name
                  : null,
                valueCost: [
                  otherCost.amount,
                  !otherCost.amount &&
                  (otherCost.name || otherCost.budgetAllocation || type)
                    ? Validators.required()
                    : null
                ],
                type: [
                  type,
                  !type &&
                  (otherCost.name ||
                    otherCost.budgetAllocation ||
                    otherCost.amount)
                    ? Validators.required()
                    : null
                ],
                isDefault: otherCost.isDefault,
                budgetAllocation: otherCost.budgetAllocation
              });
              if (otherCost.isDefault) {
                formGroup.get('itemName').setIsView(true);
              }
              if (this.todo === 'view') {
                formGroup.setIsView(true);
              }
              this.otherCostList.push(formGroup);
            }
          );
        } else {
          this.formGroup.patchValue({ isIncludeVAT: true });

          this.billingMemoEditResponse.taxList.forEach(response => {
            const formGroup = this.formBuilder.group({
              id: [null],
              taxName: response.name,
              coa: response.coa,
              costCenter: [null],
              taxPercentage: response.amount,
              taxBase: [null],
              incomeTax: [null]
            });
            if (this.todo === 'view') {
              formGroup.setIsView(true);
            }
            this.taxList.push(formGroup);
          });

          this.billingMemoEditResponse.budgetAllocationList.forEach(
            budgetAllocation => {
              const formGroup = this.formBuilder.group({
                id: [null],
                coa: budgetAllocation.coa.code,
                costCenter: budgetAllocation.costCenter.name,
                taxPercentage: this.billingMemoEditResponse.companyPPN,
                taxBase: [null],
                vat: [null],
                total: [null],
                budgetAllocation,
                billingMemoBudgetDetailList: [null]
              });
              if (this.todo === 'view') {
                formGroup.setIsView(true);
              }
              this.budgetList.push(formGroup);
            }
          );
          this.setTotalData();

          this.billingMemoEditResponse.otherCostList.forEach(otherCost => {
            let moreThanTotal = true;
            if (
              otherCost.code ===
                this.global.appConstant.cm.OTHER_COST_MATERAI &&
              this.billingAmount < 5000000
            ) {
              moreThanTotal = false;
            }

            if (
              (moreThanTotal &&
                otherCost.code ===
                  this.global.appConstant.cm.OTHER_COST_MATERAI) ||
              otherCost.code !== this.global.appConstant.cm.OTHER_COST_MATERAI
            ) {
              const formGroup = this.formBuilder.group({
                id: [null],
                itemName: otherCost.name,
                coa: [null, Validators.required()],
                costCenter: [null],
                valueCost: otherCost.amount,
                type: [null, Validators.required()],
                isDefault: true,
                budgetAllocation: [null]
              });
              formGroup.get('itemName').setIsView(true);
              if (this.todo === 'view') {
                formGroup.setIsView(true);
              }
              this.otherCostList.push(formGroup);
            }
          });
        }
        this.setTotalData();
        this.setStateReady();
        this.otherCostList.controls.forEach(otherCost => {
          otherCost.get('coa').disable();
        });
      });
  }

  public getDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.paymentId = this.global.routerParams.get('paymentId');
    this.procurementVendorId = this.global.routerParams.get(
      'procurementVendorId'
    );
    this.currency = this.global.routerParams.get('currency');
    this.paymentInvoiceId = this.global.routerParams.get('paymentInvoiceId');
    this.paymentStatus = this.global.routerParams.get('paymentStatus');
    this.billingAmount = this.global.routerParams.get('billingAmount');
  }

  public setTotalData(): void {
    this.totalTaxBase = 0;
    this.totalVAT = 0;
    this.totalProcurementValueTotal = 0;
    this.totalIncomeTax = 0;
    this.totalPaidToVendor = 0;

    this.budgetList.value.forEach(tax => {
      this.totalTaxBase += tax.taxBase ? +tax.taxBase : 0;
      this.totalVAT += tax.vat ? +tax.vat : 0;
      this.totalProcurementValueTotal += tax.total ? +tax.total : 0;
    });

    this.taxList.value.forEach(item => {
      this.totalIncomeTax += item.incomeTax ? +item.incomeTax : 0;
    });
    if (this.billingMemoEditResponse.isWAPU) {
      this.totalPaidToVendor = this.totalTaxBase - this.totalIncomeTax;
      this.subTotal = this.totalTaxBase;
    } else {
      this.totalPaidToVendor =
        this.totalTaxBase + this.totalVAT - this.totalIncomeTax;
      this.subTotal = this.totalTaxBase + this.totalVAT;
    }
    this.otherCostList.value.forEach(cost => {
      if (cost.valueCost && cost.type) {
        this.totalPaidToVendor =
          cost.type.value === 1
            ? this.totalPaidToVendor + +cost.valueCost
            : this.totalPaidToVendor - +cost.valueCost;
        this.subTotal =
          cost.type.value === 1
            ? this.subTotal + +cost.valueCost
            : this.subTotal - +cost.valueCost;
      }
    });
  }

  public onChangeIncludeVAT(): void {
    const budgetList = this.budgetList.value;
    this.budgetList.clear();
    if (this.formGroup.value.isIncludeVAT) {
      if (this.billingMemoEditResponse.billingMemo) {
        const billingMemoBudgetList =
          this.billingMemoEditResponse.billingMemoBudgetList;
        budgetList.forEach((budget, index) => {
          const total = budget.taxBase;
          const taxBase = (
            total /
            (1 + this.billingMemoEditResponse.companyPPN * 0.01)
          ).toFixed(2);
          const vat = (
            +taxBase *
            this.billingMemoEditResponse.companyPPN *
            0.01
          ).toFixed(2);
          const formGroup = this.formBuilder.group({
            id: billingMemoBudgetList[index].id,
            coa: billingMemoBudgetList[index].budgetAllocation.coa.code,
            costCenter:
              billingMemoBudgetList[index].budgetAllocation.costCenter.name,
            taxPercentage: budget.taxPercentage,
            taxBase: +taxBase,
            vat: +vat,
            total,
            budgetAllocation: billingMemoBudgetList[index].budgetAllocation,
            billingMemoBudgetDetailList: [null]
          });
          this.budgetList.push(formGroup);
        });
      } else {
        budgetList.forEach(budget => {
          const total = budget.taxBase;
          const taxBase = (
            total /
            (1 + this.billingMemoEditResponse.companyPPN * 0.01)
          ).toFixed(2);
          const vat = (
            +taxBase *
            this.billingMemoEditResponse.companyPPN *
            0.01
          ).toFixed(2);
          const formGroup = this.formBuilder.group({
            id: [null],
            coa: budget.budgetAllocation.coa.code,
            costCenter: budget.budgetAllocation.costCenter.name,
            taxPercentage: budget.taxPercentage,
            taxBase: +taxBase,
            vat: +vat,
            total: total ? total : [null],
            budgetAllocation: budget.budgetAllocation,
            billingMemoBudgetDetailList: [null]
          });
          this.budgetList.push(formGroup);
        });
      }
      this.setTotalData();
    } else {
      if (this.billingMemoEditResponse.billingMemo) {
        const billingMemoBudgetList =
          this.billingMemoEditResponse.billingMemoBudgetList;
        budgetList.forEach((budget, index) => {
          const taxBase = budget.total;
          const vat = (
            +taxBase *
            this.billingMemoEditResponse.companyPPN *
            0.01
          ).toFixed(2);
          const total = +taxBase + +vat;
          const formGroup = this.formBuilder.group({
            id: billingMemoBudgetList[index].id,
            coa: billingMemoBudgetList[index].budgetAllocation.coa.code,
            costCenter:
              billingMemoBudgetList[index].budgetAllocation.costCenter.name,
            taxPercentage: budget.taxPercentage,
            taxBase,
            vat: +vat,
            total: +total,
            budgetAllocation: billingMemoBudgetList[index].budgetAllocation,
            billingMemoBudgetDetailList: [null]
          });
          this.budgetList.push(formGroup);
        });
      } else {
        budgetList.forEach(budget => {
          const taxBase = budget.total;
          const vat = (
            +taxBase *
            this.billingMemoEditResponse.companyPPN *
            0.01
          ).toFixed(2);
          const total = +taxBase + +vat;
          const formGroup = this.formBuilder.group({
            id: [null],
            coa: budget.budgetAllocation.coa.code,
            costCenter: budget.budgetAllocation.costCenter.name,
            taxPercentage: budget.taxPercentage,
            taxBase: taxBase ? taxBase : [null],
            vat: +vat,
            total: +total,
            budgetAllocation: budget.budgetAllocation,
            billingMemoBudgetDetailList: [null]
          });
          this.budgetList.push(formGroup);
        });
      }
      this.setTotalData();
    }
  }

  public onChangeTaxPercentage(index: number): void {
    const taxPercentage = this.taxList.at(index).get('taxPercentage').value;
    const taxBase = this.taxList.at(index).get('taxBase').value;
    if (taxPercentage && taxBase) {
      const incomeTax = (taxBase * taxPercentage * 0.01).toFixed(2);
      this.taxList
        .at(index)
        .get('incomeTax')
        .setValue(+incomeTax);
      this.taxList.at(index).get('incomeTax').updateValueAndValidity();
    }
    this.setTotalData();
  }

  public onChangeItemName(event: any, index: number): void {
    if (event && event !== '') {
      this.otherCostList
        .at(index)
        .get('itemName')
        .setValidators([Validators.required()]);
      this.otherCostList
        .at(index)
        .get('valueCost')
        .setValidators([Validators.required()]);
      this.otherCostList
        .at(index)
        .get('type')
        .setValidators([Validators.required()]);
      this.otherCostList.at(index).get('itemName').updateValueAndValidity();
      this.otherCostList.at(index).get('valueCost').updateValueAndValidity();
      this.otherCostList.at(index).get('type').updateValueAndValidity();
    } else {
      const valueCost = this.otherCostList.at(index).get('valueCost').value;
      const type = this.otherCostList.at(index).get('type').value;
      if ((!valueCost || (valueCost && valueCost === '')) && !type) {
        this.otherCostList.at(index).get('itemName').clearValidators();
        this.otherCostList.at(index).get('valueCost').clearValidators();
        this.otherCostList.at(index).get('type').clearValidators();
        this.otherCostList.at(index).get('itemName').updateValueAndValidity();
        this.otherCostList.at(index).get('valueCost').updateValueAndValidity();
        this.otherCostList.at(index).get('type').updateValueAndValidity();
      }
    }
  }

  public onChangeType(event: any, index: number): void {
    if (event && event !== '') {
      this.otherCostList
        .at(index)
        .get('itemName')
        .setValidators([Validators.required()]);
      this.otherCostList
        .at(index)
        .get('valueCost')
        .setValidators([Validators.required()]);
      this.otherCostList
        .at(index)
        .get('type')
        .setValidators([Validators.required()]);
      this.otherCostList.at(index).get('itemName').updateValueAndValidity();
      this.otherCostList.at(index).get('valueCost').updateValueAndValidity();
      this.otherCostList.at(index).get('type').updateValueAndValidity();
      this.setTotalData();
    } else {
      const valueCost = this.otherCostList.at(index).get('valueCost').value;
      const itemName = this.otherCostList.at(index).get('itemName').value;
      if (
        (!valueCost || (valueCost && valueCost === '')) &&
        (!itemName || (itemName && itemName === ''))
      ) {
        this.otherCostList.at(index).get('itemName').clearValidators();
        this.otherCostList.at(index).get('valueCost').clearValidators();
        this.otherCostList.at(index).get('type').clearValidators();
        this.otherCostList.at(index).get('itemName').updateValueAndValidity();
        this.otherCostList.at(index).get('valueCost').updateValueAndValidity();
        this.otherCostList.at(index).get('type').updateValueAndValidity();
      }
    }
  }

  public onChangeValueCost(event: any, index: number): void {
    const valueCost = this.otherCostList.at(index).get('valueCost').value;
    const type = this.otherCostList.at(index).get('type').value;
    if (valueCost && type) {
      this.setTotalData();
    }

    if (event && event !== '') {
      this.otherCostList
        .at(index)
        .get('itemName')
        .setValidators([Validators.required()]);
      this.otherCostList
        .at(index)
        .get('valueCost')
        .setValidators([Validators.required()]);
      this.otherCostList
        .at(index)
        .get('type')
        .setValidators([Validators.required()]);
      this.otherCostList.at(index).get('itemName').updateValueAndValidity();
      this.otherCostList.at(index).get('valueCost').updateValueAndValidity();
      this.otherCostList.at(index).get('type').updateValueAndValidity();
    } else {
      const valueCost = this.otherCostList.at(index).get('valueCost').value;
      const type = this.otherCostList.at(index).get('type').value;
      if ((!valueCost || (valueCost && valueCost === '')) && !type) {
        this.otherCostList.at(index).get('itemName').clearValidators();
        this.otherCostList.at(index).get('valueCost').clearValidators();
        this.otherCostList.at(index).get('type').clearValidators();
        this.otherCostList.at(index).get('itemName').updateValueAndValidity();
        this.otherCostList.at(index).get('valueCost').updateValueAndValidity();
        this.otherCostList.at(index).get('type').updateValueAndValidity();
      }
    }
  }

  public onChangeTaxPercentageItem(index: number): void {
    const taxPercentage = this.budgetList.at(index).get('taxPercentage').value;
    const total = this.budgetList.at(index).get('total').value;
    if (taxPercentage) {
      if (this.formGroup.value.isIncludeVAT) {
        const totalTemp = +total;
        const taxBaseTemp = (totalTemp / (1 + taxPercentage * 0.01)).toFixed(2);
        const vatTemp = (+taxBaseTemp * taxPercentage * 0.01).toFixed(2);
        this.budgetList.at(index).patchValue({
          taxBase: +taxBaseTemp,
          vat: +vatTemp,
          total: totalTemp
        });
      } else {
        const taxBase = this.budgetList.at(index).get('taxBase').value;
        const vat = (+taxBase * taxPercentage * 0.01).toFixed(2);
        const total = +taxBase + +vat;
        this.budgetList.at(index).patchValue({
          taxBase: +taxBase,
          vat: +vat,
          total: +total
        });
      }
    }
    this.setTotalData();
  }

  public onChangeTaxBaseBudget(index: number): void {
    const taxBase = this.budgetList.at(index).get('taxBase').value;
    const vat = +taxBase * this.billingMemoEditResponse.companyPPN * 0.01;
    const vataFixed = +vat.toFixed(2);
    const total = (+taxBase + +vataFixed).toFixed(2);
    this.budgetList
      .at(index)
      .get('total')
      .setValue(+total);
    this.budgetList.at(index).get('total').updateValueAndValidity();
    this.budgetList
      .at(index)
      .get('vat')
      .setValue(+vataFixed);
    this.budgetList.at(index).get('vat').updateValueAndValidity();
    this.setTotalData();
  }

  public onChangeTotalBudget(index: number): void {
    const total = this.budgetList.at(index).get('total').value;
    const taxBase =
      total / (1 + this.billingMemoEditResponse.companyPPN * 0.01);
    const taxBaseFixed = (
      total /
      (1 + this.billingMemoEditResponse.companyPPN * 0.01)
    ).toFixed(2);
    const vat = (
      +taxBase *
      this.billingMemoEditResponse.companyPPN *
      0.01
    ).toFixed(2);
    this.budgetList
      .at(index)
      .get('taxBase')
      .setValue(+taxBaseFixed);
    this.budgetList.at(index).get('taxBase').updateValueAndValidity();
    this.budgetList
      .at(index)
      .get('vat')
      .setValue(+vat);
    this.budgetList.at(index).get('vat').updateValueAndValidity();
    this.setTotalData();
  }

  public onChangeTaxBase(index: number): void {
    const taxPercentage = this.taxList.at(index).get('taxPercentage').value;
    const taxBase = this.taxList.at(index).get('taxBase').value;
    if (taxPercentage && taxBase) {
      const incomeTax = (taxBase * taxPercentage * 0.01).toFixed(2);
      this.taxList
        .at(index)
        .get('incomeTax')
        .setValue(+incomeTax);
      this.taxList.at(index).get('incomeTax').updateValueAndValidity();
      this.taxList
        .at(index)
        .get('taxBase')
        .setValidators(Validators.required());
      this.taxList.at(index).get('taxBase').updateValueAndValidity();
      this.taxList
        .at(index)
        .get('costCenter')
        .setValidators(Validators.required());
      this.taxList.at(index).get('costCenter').updateValueAndValidity();
      this.taxList
        .at(index)
        .get('taxPercentage')
        .setValidators(Validators.required());
      this.taxList.at(index).get('taxPercentage').updateValueAndValidity();
    } else {
      if (
        !this.taxList.at(index).get('costCenter').value ||
        this.taxList.at(index).get('costCenter').value === ''
      ) {
        this.taxList.at(index).get('costCenter').clearValidators();
        this.taxList.at(index).get('costCenter').updateValueAndValidity();
        this.taxList.at(index).get('taxBase').clearValidators();
        this.taxList.at(index).get('taxBase').updateValueAndValidity();
      }
      this.taxList.at(index).get('incomeTax').setValue(null);
      this.taxList.at(index).get('incomeTax').updateValueAndValidity();
    }
    this.setTotalData();
  }

  public onChangeCostCenter(index: number): void {
    const costCenter = this.taxList.at(index).get('costCenter').value;
    if (costCenter) {
      this.taxList
        .at(index)
        .get('taxBase')
        .setValidators(Validators.required());
      this.taxList.at(index).get('taxBase').updateValueAndValidity();
      this.taxList
        .at(index)
        .get('costCenter')
        .setValidators(Validators.required());
      this.taxList.at(index).get('costCenter').updateValueAndValidity();
      this.taxList
        .at(index)
        .get('taxPercentage')
        .setValidators(Validators.required());
      this.taxList.at(index).get('taxPercentage').updateValueAndValidity();
    } else {
      if (
        !this.taxList.at(index).get('taxBase').value ||
        this.taxList.at(index).get('taxBase').value === ''
      ) {
        this.taxList.at(index).get('costCenter').clearValidators();
        this.taxList.at(index).get('costCenter').updateValueAndValidity();
        this.taxList.at(index).get('taxBase').clearValidators();
        this.taxList.at(index).get('taxBase').updateValueAndValidity();
      }
    }
  }

  public doChooseBudgetCost(index: number): void {
    const budgetAllocation: BudgetAllocation = this.otherCostList
      .at(index)
      .get('budgetAllocation').value;
    this.appPopupChooseBudgetService
      .open(false, budgetAllocation)
      .subscribe((budgetAllocationList: BudgetAllocation[]) => {
        budgetAllocationList.forEach(budgetAllocation => {
          this.otherCostList
            .at(index)
            .get('coa')
            .setValue(budgetAllocation.coa.code);
          this.otherCostList
            .at(index)
            .get('costCenter')
            .setValue(budgetAllocation.costCenter.name);
          this.otherCostList
            .at(index)
            .get('budgetAllocation')
            .setValue(budgetAllocation);
          this.otherCostList.at(index).get('coa').updateValueAndValidity();
          this.otherCostList
            .at(index)
            .get('costCenter')
            .updateValueAndValidity();
          this.otherCostList
            .at(index)
            .get('budgetAllocation')
            .updateValueAndValidity();

          this.otherCostList
            .at(index)
            .get('itemName')
            .setValidators([Validators.required()]);
          this.otherCostList
            .at(index)
            .get('valueCost')
            .setValidators([Validators.required()]);
          this.otherCostList
            .at(index)
            .get('type')
            .setValidators([Validators.required()]);
          this.otherCostList.at(index).get('itemName').updateValueAndValidity();
          this.otherCostList
            .at(index)
            .get('valueCost')
            .updateValueAndValidity();
          this.otherCostList.at(index).get('type').updateValueAndValidity();
        });
      });
  }

  public doAddMore(): void {
    const formGroup = this.formBuilder.group({
      id: [null],
      itemName: [null],
      coa: [null],
      costCenter: [null],
      valueCost: [null],
      type: [null],
      isDefault: false,
      budgetAllocation: [null]
    });
    formGroup.get('coa').disable();
    this.otherCostList.push(formGroup);
  }

  public doDelete(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.otherCostList.removeAt(index);
        }
      });
  }

  public doSaveDraft(): void {
    const prompt = this.translateService.instant('billing-memo.save-draft.msg');
    const title = this.translateService.instant(
      'billing-memo.save-draft.title'
    );
    this.global.modalService
      .confirm(prompt, title)
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.setDataFromFormGroup(false);
          this.httpClientService
            .post<Response<BillingMemo>>(
              '/billing-memo/update',
              this.billingMemoUpdateRequest
            )
            .subscribe(response => {
              this.setStateReady();
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessSavingOnNextRoute();
                this.router.navigate(['/pages/billing-memo']);
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doSave(): void {
    this.validate();
    this.checkValidation();
    this.checkAvailableBudget();
    const isTotalBudgetEquals =
      this.billingAmount === +this.totalProcurementValueTotal;
    if (this.formGroup.valid && this.isValid) {
      if (this.isBudgetValid && isTotalBudgetEquals) {
        this.global.modalService
          .saveConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              this.setDataFromFormGroup(true);
              this.httpClientService
                .post<Response<BillingMemo>>(
                  '/billing-memo/update',
                  this.billingMemoUpdateRequest
                )
                .subscribe(response => {
                  this.setStateReady();
                  if (response.status === ResponseStatusModel.OK) {
                    this.global.alertService.showSuccessOnNextRoute(
                      response.statusText
                    );
                    this.router.navigate(['/pages/billing-memo']);
                  } else {
                    this.global.alertService.showError(response.statusText);
                  }
                });
            }
          });
      } else if (!isTotalBudgetEquals) {
        const messageValidation =
          this.billingAmount < +this.totalProcurementValueTotal
            ? 'billing-memo.validation.totalBudgetMore'
            : 'billing-memo.validation.totalBudgetLess';
        this.global.alertService.showError(
          this.global.translateService.instant(messageValidation) +
            ' ' +
            this.global.converterService.convertMoney(this.billingAmount) +
            ' ' +
            this.currency.code +
            ')'
        );
      } else if (!this.isBudgetValid) {
        this.global.alertService.showError(
          this.global.translateService.instant(
            'billing-memo.validation.availableBudget1'
          ) +
            ' ' +
            this.budgetNotValid.coa.code +
            ' ' +
            this.global.translateService.instant(
              'billing-memo.validation.availableBudget2'
            )
        );
      }
    }
  }

  public checkValidation(): void {
    this.isValid = true;

    this.taxList.value.forEach(tax => {
      if (tax.costCenter || tax.taxBase) {
        if (
          !tax.costCenter ||
          tax.costCenter === '' ||
          !tax.taxBase ||
          tax.taxBase === ''
        ) {
          this.isValid = false;
        }
      }
    });

    this.otherCostList.value.forEach(otherCost => {
      if (otherCost.isDefault) {
        if (
          !otherCost.valueCost ||
          otherCost.valueCost === '' ||
          !otherCost.type
        ) {
          this.isValid = false;
        }
      } else {
        if (otherCost.itemName || otherCost.valueCost || otherCost.type) {
          if (
            !otherCost.itemName ||
            otherCost.itemName === '' ||
            !otherCost.valueCost ||
            otherCost.valueCost === '' ||
            !otherCost.type
          ) {
            this.isValid = false;
          }
        }
      }
    });
  }

  public checkAvailableBudget(): void {
    this.isBudgetValid = true;
    const budgetList: BudgetAllocation[] = [];
    this.otherCostList.value.forEach(otherCost => {
      if (otherCost.budgetAllocation) {
        let exist = false;
        budgetList.forEach(budget => {
          if (budget.id === otherCost.budgetAllocation.id) {
            exist = true;
          }
        });
        if (!exist) {
          budgetList.push(otherCost.budgetAllocation);
          this.budgetMap.set(
            otherCost.budgetAllocation.id,
            +otherCost.valueCost
          );
        } else {
          const costTemp = this.budgetMap.get(otherCost.budgetAllocation.id);
          const newCost = costTemp + +otherCost.valueCost;
          this.budgetMap.set(otherCost.budgetAllocation.id, newCost);
        }
      }
    });

    this.budgetList.value.forEach(item => {
      if (item.budgetAllocation) {
        let exist = false;
        budgetList.forEach(budget => {
          if (budget.id === item.budgetAllocation.id) {
            exist = true;
          }
        });
        if (!exist) {
          budgetList.push(item.budgetAllocation);
          this.budgetMap.set(item.budgetAllocation.id, +item.total);
        } else {
          const costTemp = this.budgetMap.get(item.budgetAllocation.id);
          const newCost = costTemp + +item.total;
          this.budgetMap.set(item.budgetAllocation.id, newCost);
        }
      }

      if (item.billingMemoBudgetDetailList) {
        item.billingMemoBudgetDetailList.forEach(budgetDetail => {
          if (budgetDetail && budgetDetail.budgetAllocation) {
            let exist = false;
            budgetList.forEach(budget => {
              if (budget.id === item.budgetAllocation.id) {
                exist = true;
              }
            });
            if (!exist) {
              budgetList.push(budgetDetail.budgetAllocation);
              this.budgetMap.set(
                budgetDetail.budgetAllocation.id,
                +budgetDetail.totalAmount
              );
            } else {
              const costTemp = this.budgetMap.get(
                budgetDetail.budgetAllocation.id
              );
              const newCost = costTemp + +budgetDetail.totalAmount;
              this.budgetMap.set(budgetDetail.budgetAllocation.id, newCost);
            }
          }
        });
      } else {
        this.billingMemoEditResponse.billingMemoBudgetList.forEach(
          memoBudget => {
            if (memoBudget.budgetAllocation.id === item.budgetAllocation.id) {
              memoBudget.billingMemoBudgetDetailList.forEach(
                memoBudgetDetail => {
                  if (memoBudgetDetail.budgetAllocation) {
                    let exist = false;
                    budgetList.forEach(budget => {
                      if (budget.id === item.budgetAllocation.id) {
                        exist = true;
                      }
                    });
                    if (!exist) {
                      budgetList.push(memoBudgetDetail.budgetAllocation);
                      this.budgetMap.set(
                        memoBudgetDetail.budgetAllocation.id,
                        +memoBudgetDetail.totalAmount
                      );
                    } else {
                      const costTemp = this.budgetMap.get(
                        memoBudgetDetail.budgetAllocation.id
                      );
                      const newCost = costTemp + +memoBudgetDetail.totalAmount;
                      this.budgetMap.set(
                        memoBudgetDetail.budgetAllocation.id,
                        newCost
                      );
                    }
                  }
                }
              );
            }
          }
        );
      }
    });
    budgetList.forEach(budget => {
      const costTotal = this.budgetMap.get(budget.id);
      if (budget.availableBudget < costTotal) {
        this.isBudgetValid = false;
        this.budgetNotValid = budget;
      }
    });
  }

  public setDataFromFormGroup(isSave: boolean): void {
    const billingMemoItemList: BillingMemoBudget[] = [];
    const billingMemoTaxList: BillingMemoTax[] = [];
    const billingMemoOtherCostList: BillingMemoOtherCost[] = [];
    let billingMemo: BillingMemo = new BillingMemo();
    const firstSigner = this.formGroup.value.firstSigner;
    const secondSigner = this.formGroup.value.secondSigner;
    billingMemo = this.formGroup.value;
    billingMemo.firstSigner = null;
    billingMemo.secondSigner = null;
    billingMemo.isIncludeVAT = this.formGroup.value.isIncludeVAT;
    billingMemo.amount = this.totalPaidToVendor;
    billingMemo.region =
      this.formGroup.value.city[this.formGroup.value.city.length - 1];
    billingMemo.billingMemoPriority = this.formGroup.value.priority;
    billingMemo.paymentType =
      this.formGroup.value.paymentType[
        this.formGroup.value.paymentType.length - 1
      ];

    this.budgetList.value.forEach(item => {
      if (item.total) {
        const billingMemoItem: BillingMemoBudget = new BillingMemoBudget();
        billingMemoItem.id = item.id;
        billingMemoItem.taxBaseAmount = item.taxBase;
        billingMemoItem.vatAmount = item.vat;
        billingMemoItem.totalAmount = item.total;
        billingMemoItem.taxPercentageValue = item.taxPercentage;
        billingMemoItem.budgetAllocation = item.budgetAllocation;
        billingMemoItem.billingMemoBudgetDetailList =
          item.billingMemoBudgetDetailList;
        billingMemoItemList.push(billingMemoItem);
      }
    });

    this.taxList.value.forEach((tax, index) => {
      const billingMemoTax: BillingMemoTax = new BillingMemoTax();
      if (isSave && tax.taxBase && tax.taxBase !== '') {
        billingMemoTax.id = tax.id;
        billingMemoTax.tax = this.billingMemoEditResponse.taxList[index];
        billingMemoTax.costCenter = tax.costCenter;
        billingMemoTax.taxBaseAmount = +tax.taxBase;
        billingMemoTax.taxPercentageValue = tax.taxPercentage;
        billingMemoTax.incomeTaxAmount = tax.incomeTax;
        billingMemoTaxList.push(billingMemoTax);
      } else if (!isSave) {
        billingMemoTax.id = tax.id;
        billingMemoTax.tax = this.billingMemoEditResponse.taxList[index];
        billingMemoTax.costCenter = tax.costCenter;
        billingMemoTax.taxBaseAmount =
          tax.taxBase && tax.taxBase !== '' ? +tax.taxBase : null;
        billingMemoTax.taxPercentageValue = tax.taxPercentage;
        billingMemoTax.incomeTaxAmount = tax.incomeTax;
        billingMemoTaxList.push(billingMemoTax);
      }
    });

    this.otherCostList.value.forEach(otherCost => {
      const billingMemoOtherCost: BillingMemoOtherCost =
        new BillingMemoOtherCost();
      if (isSave && otherCost.valueCost && otherCost.valueCost !== '') {
        billingMemoOtherCost.id = otherCost.id;
        billingMemoOtherCost.transactionType = otherCost.type
          ? otherCost.type.value
          : null;
        billingMemoOtherCost.amount = +otherCost.valueCost;
        billingMemoOtherCost.budgetAllocation = otherCost.budgetAllocation;
        billingMemoOtherCost.name = otherCost.itemName;
        billingMemoOtherCost.isDefault = otherCost.isDefault;
        billingMemoOtherCostList.push(billingMemoOtherCost);
      } else if (!isSave) {
        billingMemoOtherCost.id = otherCost.id;
        billingMemoOtherCost.transactionType = otherCost.type
          ? otherCost.type.value
          : null;
        billingMemoOtherCost.amount =
          otherCost.valueCost && otherCost.otherValueCost !== ''
            ? +otherCost.valueCost
            : null;
        billingMemoOtherCost.budgetAllocation = otherCost.budgetAllocation;
        billingMemoOtherCost.name = otherCost.itemName;
        billingMemoOtherCost.isDefault = otherCost.isDefault;
        billingMemoOtherCostList.push(billingMemoOtherCost);
      }
    });

    this.billingMemoUpdateRequest.billingMemo = billingMemo;
    this.billingMemoUpdateRequest.billingMemoBudgetList = billingMemoItemList;
    this.billingMemoUpdateRequest.billingMemoOtherCostList =
      billingMemoOtherCostList;
    this.billingMemoUpdateRequest.billingMemoTaxList = billingMemoTaxList;
    this.billingMemoUpdateRequest.paymentId = this.paymentId;
    this.billingMemoUpdateRequest.isSave = isSave;
    this.billingMemoUpdateRequest.vendorBank = this.vendorBank;
    this.billingMemoUpdateRequest.vendorBankOffline = this.vendorBankOffline;
    this.billingMemoUpdateRequest.firstSigner = firstSigner;
    this.billingMemoUpdateRequest.secondSigner = secondSigner;
  }

  public doDetailBudget(index: number): void {
    const budgetAllocation = this.budgetList
      .at(index)
      .get('budgetAllocation').value;
    this.appPopupBillingMemoBudgetDetailService
      .open(budgetAllocation)
      .subscribe();
  }

  public doEditItem(item: any, index: number): void {
    const billingMemoItemId = item.controls.id.value;
    const billingMemoBudgetDetailList =
      item.controls.billingMemoBudgetDetailList.value;
    const totalBilling = item.controls.total.value;
    const taxPercentage = item.controls.taxPercentage.value;
    const budgetAllocation = item.controls.budgetAllocation.value;
    const currency = this.currency;
    const todo = 'edit';
    this.appPopupService
      .open(
        AppPopupBillingMemoItemDetailComponent,
        {
          billingMemoItemId,
          billingMemoBudgetDetailList,
          totalBilling,
          taxPercentage,
          currency,
          budgetAllocation,
          todo
        },
        { size: 'xl' }
      )
      .subscribe(memoItemDetailList => {
        if (totalBilling && totalBilling !== 0) {
          this.budgetList
            .at(index)
            .get('billingMemoBudgetDetailList')
            .setValue(memoItemDetailList);
          this.budgetList
            .at(index)
            .get('billingMemoBudgetDetailList')
            .updateValueAndValidity();
        }
      });
  }

  public doViewItem(item: any, index: number): void {
    const billingMemoItemId = item.controls.id.value;
    const billingMemoBudgetDetailList =
      item.controls.billingMemoBudgetDetailList.value;
    const totalBilling = item.controls.total.value;
    const taxPercentage = item.controls.taxPercentage.value;
    const budgetAllocation = item.controls.budgetAllocation.value;
    const currency = this.currency;
    const todo = 'view';
    this.appPopupService
      .open(
        AppPopupBillingMemoItemDetailComponent,
        {
          billingMemoItemId,
          billingMemoBudgetDetailList,
          totalBilling,
          taxPercentage,
          currency,
          budgetAllocation,
          todo
        },
        { size: 'xl' }
      )
      .subscribe(memoItemDetailList => {
        this.budgetList
          .at(index)
          .get('billingMemoBudgetDetailList')
          .setValue(memoItemDetailList);
        this.budgetList
          .at(index)
          .get('billingMemoBudgetDetailList')
          .updateValueAndValidity();
      });
  }

  public doBack(): void {
    this.router.navigate(['/pages/billing-memo']);
  }

  public get taxList(): FormArray {
    return this.formGroup.get('taxList') as FormArray;
  }

  public get budgetList(): FormArray {
    return this.formGroup.get('budgetList') as FormArray;
  }

  public get otherCostList(): FormArray {
    return this.formGroup.get('otherCostList') as FormArray;
  }
}
