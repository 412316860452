<div class="app-workflow-info">
  <app-widget-content-slider>
    <ng-template #contentOuterLeft>
      <div
        class="widget-card"
        [ngClass]="!position ? 'active' : ''"
        (click)="onChange.emit(null)"
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
      >
        <h5>{{ 'app-workflow-info.worklistTotal' | translate }}</h5>
        <span>{{ totalRecords }}</span>
      </div>
    </ng-template>
    <div
      *ngFor="let data of dataList; let i = index"
      class="widget-card"
      [ngClass]="data.code === position ? 'active' : ''"
      (click)="onChange.emit(data.code)"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    >
      <h5>{{ data.name }}</h5>
      <span>{{ data.totalRecords }}</span>
    </div>
  </app-widget-content-slider>
</div>

<!-- [ngStyle]="{ 'min-width': 'calc((' + 100 / (dataList.length < 5 ?
dataList.length : 5) + '%) - 0.44rem)' }" -->
