import { WorkProgram } from './../../core/bean/work-program';
import { BudgetType } from '../../core/bean/budget-type';
import { Coa } from '../../core/bean/coa';
import { CostCenter } from '../../core/bean/cost-center';
import { Currency } from '../../core/bean/currency';
import { Organization } from '../../core/bean/organization';

export class WorkPlanItemModel {
  isCheckedAll = false;
  recordPageMap: Map<number, ChangeBudget> = new Map();
}
export interface ChangeBudget {
  id: number;
  isChecked: boolean;
  plannedBudget: number;
  organization: Organization;
  costCenter: CostCenter;
  coa: Coa;
  budgetType: BudgetType;
  currency: Currency;
  workProgram: WorkProgram;
}
