import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { UserNotification } from '../../core/bean/user-notification';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: 'user-notification.component.html'
})
export class UserNotificationComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<UserNotification>;
  constructor(translateService: TranslateService) {
    super('user-notification', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'userNotificationType.code',
        header: 'table.column.userNotificationType',
        customClass: 'text-center'
      },
      {
        field: 'publishDate',
        header: 'table.column.publishDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'subject',
        header: 'table.column.subject',
        customClass: 'white-space-normal'
      }
    ]);
  }

  public doView(userNotification: UserNotification): void {
    if (!userNotification.readDate) {
      this.httpClientService
        .post<Response<UserNotification>>(
          '/user-notification/read-notification',
          userNotification.id
        )
        .subscribe(response => {
          if (response.status !== ResponseStatusModel.OK) {
            this.global.alertService.showError(response.statusText);
          }
          this.setStateReady();
        });
    }
    if (userNotification.detailUrl) {
      if (userNotification.detailUrl.includes('http')) {
        window.location.href = userNotification.detailUrl;
      } else {
        this.router.navigate([userNotification.detailUrl]);
      }
    }
  }
}
