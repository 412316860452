import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RecapPrintService } from 'src/app/core/services/recap-print.service';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { Package } from 'src/app/core/bean/package';
@Component({
  templateUrl: './diagnose-package.component.html'
})
export class DiagnosePackageComponent extends BaseModuleComponent implements OnInit {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Package>;
  public isLoading: boolean;
  constructor(translateService: TranslateService, public recapPrintService: RecapPrintService,) {
    super('diagnose-package', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      {
        field: 'name',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      },
      {
        field: 'victimDiagnose.name',
        header: 'table.column.victimDiagnose',
        customClass: 'white-space-normal'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/diagnose-package/add']);
  }

  public doEdit(paket: Package): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('packageId', paket.id);
    this.router.navigate(['/pages/diagnose-package/edit']);
  }


  public doDelete(paket: Package): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<Package>>('/diagnose-package/delete', paket)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
