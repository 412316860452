<app-popup [header]="header" [isLoading]="formLoading">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <h5 class="separator">{{
      'contract-monitoring.separator.contractData' | translate
    }}</h5>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'contract-monitoring.form.contractNumber' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ contract.number }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'contract-monitoring.form.contractDate' | translate
      }}</label>
      <div class="col-sm-9 text-left" style="display: flex">
        <p class="mr-3 control-label">{{
          'contract-monitoring.form.from' | translate
        }}</p>
        {{
          contract.startDate
            | date: global.config.parameterModel.dateFormatShort
        }}
        <p class="ml-3 mr-3 control-label">{{
          'contract-monitoring.form.until' | translate
        }}</p>
        {{
          contract.endDate | date: global.config.parameterModel.dateFormatShort
        }}
      </div>
    </div>
    <h5 class="separator">{{
      'contract-monitoring.separator.uploadContractDocument' | translate
    }}</h5>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'contract-monitoring.form.upload' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-upload
          size="LG"
          formControlName="contractFile"
          [fileUploader]="fileUploader"
        >
        </app-upload
      ></div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'contract-monitoring.form.description' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-area size="LG" formControlName="description" maxLength="512">
        </app-text-area
      ></div>
    </div>
  </form>

  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
        'app.button.close' | translate
      }}</app-button>
      <app-button
        *ngIf="!formGroup.isView"
        color="PRIMARY"
        (onClick)="doSave()"
        >{{ 'app.button.save' | translate }}</app-button
      >
    </div>
  </ng-template>
</app-popup>
