import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { WorkloadThresholdObject } from '../../core/bean/workload-threshold-object';
import { OptionListModel } from '../../core/model/option-list-model';

@Component({
  templateUrl: './app-popup-change-definition.component.html'
})
export class AppPopupChangeDefinitionComponent extends BaseComponentComponent {
  @Input() public workloadThresholdObject: WorkloadThresholdObject;
  @Input() public workloadThresholdObjectList: WorkloadThresholdObject[] = [];
  @Output() public onChange: EventEmitter<WorkloadThresholdObject> =
    new EventEmitter();

  public workloadThresholdObjectOptionList: OptionListModel<WorkloadThresholdObject> =
    new OptionListModel(false);

  public constructor(public ngbActiveModal: NgbActiveModal) {
    super('workload');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      workloadThresholdObject: [null]
    });
  }

  public setFormGroup(): void {
    this.workloadThresholdObjectOptionList.setRequestValues(
      this.workloadThresholdObjectList
    );
    if (this.workloadThresholdObject) {
      this.formGroup.patchValue({
        workloadThresholdObject: this.workloadThresholdObject
      });
    }
    this.setStateReady();
  }

  public doCancel(): void {
    this.ngbActiveModal.close();
  }

  public doAdd(): void {
    if (this.formGroup.value.workloadThresholdObject) {
      this.onChange.emit(this.formGroup.value.workloadThresholdObject);
    } else {
      this.global.alertService.showError(
        'workload.popup.validation.changeDefinition.msg',
        '.popup-chage-definition'
      );
    }
  }
}
