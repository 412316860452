import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
@Component({
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationComponent extends BaseModuleComponent {
  public logoUrl: string;
  constructor(translateService: TranslateService) {
    super('', translateService);
  }

  public onInit(): void {
    this.setLogoUrl();
  }

  public setLogoUrl(): void {
    this.logoUrl = './assets/img/logo/logo_1.png';
  }
}
