import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Procurement } from '../../bean/procurement';
import { ProcurementMatrix } from '../../bean/procurement-matrix';
import { ProcurementPq } from '../../bean/procurement-pq';
import { TableResponseModel } from '../table/model/table-response-model';
import { AppProcurementInfoResponse } from './app-procurement-info.response';

@Component({
  selector: 'app-procurement-info',
  templateUrl: './app-procurement-info.component.html'
})
export class AppProcurementInfoComponent extends BaseComponentComponent {
  @Input() public procurementId: number;
  public procurement: Procurement;
  public procurementPqList: ProcurementPq[];
  public procurementMatrix: ProcurementMatrix;
  public tableResponse: TableResponseModel<ProcurementPq>;

  constructor() {
    super('app-procurement-info');
  }

  public onInit(): void {
    this.getAndsetProcurementInfoData();
  }

  public getAndsetProcurementInfoData(): void {
    this.httpClientService
      .get<AppProcurementInfoResponse>(
        '/app-procurement-info/index/' + this.procurementId
      )
      .subscribe((procurementInfoResponse: AppProcurementInfoResponse) => {
        this.procurement = procurementInfoResponse.procurement;
        this.procurementPqList = procurementInfoResponse.procurementPqList;
        this.procurementMatrix = procurementInfoResponse.procurementMatrix;
        this.setStateReady();
        this.buildTableResponse();
        this.global.appConstant.pm.PROC_REG_TYPE_ANNOUNCEMENT
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'pq.name',
        header: 'table.column.procurementRequirement'
      }
    ]);
    this.tableResponse.setRecordList(this.procurementPqList);
    this.tableResponse.reload();
  }
}
