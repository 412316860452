import { BaseEntity } from '../base/base-entity';
import { Order } from '../bean/order';
import { PrItemReleased } from '../bean/pr-item-released';

export class OrderItemEntity extends BaseEntity {

  crudOperation: number;
  order: Order;
  prItemReleased: PrItemReleased;
}
