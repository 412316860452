import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupVendorComponent } from './app-popup-vendor.component';
import { AppPopupVendorService } from './app-popup-vendor.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppTableModule, AppButtonModule],
  declarations: [AppPopupVendorComponent],
  entryComponents: [AppPopupVendorComponent],
  providers: [AppPopupVendorService],
  exports: [AppPopupVendorComponent]
})
export class AppPopupVendorModule {}
