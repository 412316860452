import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Bank } from '../../bean/bank';
import { Vendor } from '../../bean/vendor';
import { VendorAddress } from '../../bean/vendor-address';
import { VendorBank } from '../../bean/vendor-bank';
import { VendorBankOffline } from '../../bean/vendor-bank-offline';
import { VendorOffline } from '../../bean/vendor-offline';
import { OptionListModel } from '../../model/option-list-model';
import { Validators } from '../../validators';
import { AppVendorPaymentInformationResponse } from './app-vendor-payment-information.response';

@Component({
  selector: 'app-vendor-payment-information',
  templateUrl: './app-vendor-payment-information.component.html'
})
export class AppVendorPaymentInformationComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() public procurementVendorId: number;
  @Input() public vendorId: number;
  @Input() public paymentId: number;
  @Input() public temp;
  @Input() public todo: string;
  @Input() public isSubmit: boolean;
  @Output() onChange: EventEmitter<VendorBank> = new EventEmitter();
  public vendor: Vendor = new Vendor();
  public vendorAddress: VendorAddress = new VendorAddress();
  public vendorBank: VendorBank = new VendorBank();
  public vendorOffline: VendorOffline;
  public vendorBankOffline: VendorBankOffline;
  public vendorInfoResponse: AppVendorPaymentInformationResponse;

  public bankOptionList: OptionListModel<Bank> = new OptionListModel(true);
  public vendorBankOptionList: OptionListModel<VendorBank> =
    new OptionListModel(true, 'accountNumber');

  constructor() {
    super('app-vendor-payment-information');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setIsView();
    this.getVendorInformationData();
  }

  public setIsView(): void {
    if (this.todo === 'view') {
      this.formGroup.setIsView(true);
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.isSubmit);
    if (this.isSubmit) {
      this.validate();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      bank: [null, Validators.required()],
      vendorBank: [null, Validators.required()],
      accountNumber: [null, Validators.required()],
      accountHolder: [null, Validators.required()],
      vendor: [null]
    });
  }

  public getVendorInformationData(): void {
    this.httpClientService
      .get<AppVendorPaymentInformationResponse>(
        this.paymentId
          ? '/app-vendor-payment-information/index?paymentId=' + this.paymentId
          : '/app-vendor-payment-information/index?vendorId=' + this.vendorId
      )
      .subscribe((response: AppVendorPaymentInformationResponse) => {
        this.vendorInfoResponse = response;
        this.vendor = response.vendor;
        this.vendorAddress = response.vendorAddress;
        this.vendorBank = response.vendorBank;
        this.vendorOffline = response.vendorOffline;
        this.vendorBankOffline = response.vendorBankOffline;
        if (response.bankList) {
          this.bankOptionList.setRequestValues(response.bankList);
        }
        this.formGroup.patchValue({
          vendorBank: this.vendorBank,
          vendor: this.vendor
        });

        if (this.vendorBank) {
          if (this.vendorBank) {
            this.formGroup.patchValue({
              id: this.vendorBank.id,
              bank: this.vendorBank.bank,
              accountNumber: this.vendorBank.accountNumber,
              accountHolder: this.vendorBank.accountHolder,
              vendor: this.vendor
            });
          }
        } else if (
          response.vendorBankList &&
          response.vendorBankList.length > 0 &&
          this.vendor.isManual
        ) {
          this.formGroup.patchValue({
            id: response.vendorBankList[0].id,
            bank: response.vendorBankList[0].bank,
            accountNumber: response.vendorBankList[0].accountNumber,
            accountHolder: response.vendorBankList[0].accountHolder,
            vendor: this.vendor,
            vendorBank: response.vendorBankList[0]
          });
          this.onChange.emit(response.vendorBankList[0]);
        }
        this.setStateReady();
      });
  }
  public doViewDetailVendor(): void {
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('vendorId', this.vendor.id);
    this.global.routerParams.set('urlBackOutside', this.router.url);
    this.global.routerParams.set('isVendorList', true);
    if (
      this.global.userSession.activeUserRole.role.type ===
      this.global.appConstant.core.ROLE_TYPE_VENDOR
    ) {
      this.global.routerParams.set('isFromVendor', true);
    } else {
      localStorage.setItem('vendorId', this.vendor.id.toString());
      localStorage.setItem('urlBackOutside', 'close-window');
      localStorage.setItem('isVendorList', 'true');
      localStorage.setItem('todo', 'view');
    }
    window.open('/pages/company-profile');
  }

  public onChangeBank(): void {
    const bank = this.formGroup.value.bank;
    if (bank && this.vendorInfoResponse.vendorBankList) {
      const vendorBankList = this.vendorInfoResponse.vendorBankList.filter(
        vBank => vBank.bank.id === bank.id
      );
      this.vendorBankOptionList.setRequestValues(vendorBankList);
    } else {
      this.vendorBankOptionList.setRequestValues([]);
    }
    this.formGroup.get('vendorBank').patchValue(null);
    this.vendorBank = null;
    this.onChange.emit(this.vendorBank);
  }

  public onChangeAccountNumber(): void {
    this.vendorBank = this.vendor.isManual
      ? this.formGroup.value
      : this.formGroup.value.vendorBank;
    this.onChange.emit(this.vendorBank);
  }

  public onChangeBankInternal(): void {
    const bank = this.formGroup.value.bank;
    if (bank && this.vendorInfoResponse.vendorBankList) {
      const vendorBankList = this.vendorInfoResponse.vendorBankList.filter(
        vBank => vBank.bank.id === bank.id
      );
      if (vendorBankList && vendorBankList.length > 0) {
        this.formGroup.patchValue({
          id: vendorBankList[0].id,
          bank: vendorBankList[0].bank,
          accountNumber: vendorBankList[0].accountNumber,
          accountHolder: vendorBankList[0].accountHolder,
          vendor: this.vendor,
          vendorBank: vendorBankList[0]
        });
      } else {
        this.formGroup.get('accountNumber').reset();
        this.formGroup.get('accountHolder').reset();
      }
    } else {
      this.formGroup.get('accountNumber').reset();
      this.formGroup.get('accountHolder').reset();
    }
    this.vendorBank = this.vendor.isManual
      ? this.formGroup.value
      : this.formGroup.value.vendorBank;
    this.onChange.emit(this.vendorBank);
    this.onChange.emit(this.vendorBank);
  }
}
