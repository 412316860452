import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorAddressHistory } from '../../core/bean/vendor-address-history';
@Component({
    templateUrl: './app-popup-tab-company-data-address.component.html'
})
export class AppPopupTabCompanyDataAddressComponent extends BaseModuleComponent {
    @Input() vendorAddressHistory: VendorAddressHistory;
    constructor(translateService: TranslateService) {
        super('tab-company-data', translateService);
    }

    public onInit(): void {
        this.setStateReady();
    }
}
