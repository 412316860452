import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { ProcurementItem } from 'src/app/core/bean/procurement-item';
import {
  TableHeaderPluginData,
  TablePluginData
} from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { VendorQuotationItemView } from '../../core/view/entity/bean/vendor-quotation-item-view';
import { PopupPriceEvaluationResponse } from './popup-price-evaluation.response';

@Component({
  templateUrl: './app-popup-price-evaluation.component.html'
})
export class AppPopupPriceEvaluationComponent extends BaseComponentComponent {
  @Input() public procurementId: number;
  @Input() public procurementItem: ProcurementItem;
  @Input() public isFinal: boolean;
  @Input() public isHasOep: boolean;

  public popupPriceEvalResponse: PopupPriceEvaluationResponse;
  public tableResponse: TableResponseModel<VendorQuotationItemView>;

  public constructor() {
    super('price-evaluation');
  }

  public onInit(): void {
    this.buildTableResponse();
    this.getDataFromServer();
  }

  public getDataFromServer(): void {
    this.httpClientService
      .get<PopupPriceEvaluationResponse>(
        '/price-evaluation/popup-price-evaluation/' +
          this.procurementId +
          '/' +
          this.procurementItem.id
      )
      .subscribe(response => {
        this.popupPriceEvalResponse = response;
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      this.procurementItem.procurement.biddingEvaluation.code !==
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
        ? [
            {
              field: 'vendorName',
              header: 'table.column.vendorName'
            },
            this.isFinal
              ? {
                  field: 'quotationItem.finalPrice',
                  header: 'table.column.finalPrice',
                  plugins: {
                    name: 'default',
                    type: 'currency'
                  },
                  className: 'text-right'
                }
              : {
                  field: 'quotationItem.price',
                  header: 'table.column.bidPrice',
                  plugins: {
                    name: 'default',
                    type: 'currency'
                  },
                  className: 'text-right'
                },
            this.isFinal
              ? {
                  field: 'quotationItem.finalProfitLossAmount',
                  header: 'table.column.potensialSavingLoss',
                  className: 'text-right',
                  plugins: [
                    {
                      name: 'custom-plugin',
                      before: (tablePlugin: TablePluginData): void => {
                        if (tablePlugin.value) {
                          tablePlugin.element.innerText =
                            this.global.converterService.convertMoney(
                              tablePlugin.value
                            ) + '%';
                        }
                      }
                    },
                    {
                      name: 'badge',
                      field: 'quotationItem.finalProfitLossStatus.name',
                      colorMap: {
                        SAVE: 'SUCCESS',
                        LOSS: 'DANGER'
                      },
                      pill: false,
                      colorField: 'quotationItem.finalProfitLossStatus.code',
                      className: 'badge-catalog ml-3'
                    }
                  ]
                }
              : {
                  field: 'quotationItem.profitLossAmount',
                  header: 'table.column.potensialSavingLoss',
                  className: 'text-right',
                  plugins: [
                    {
                      name: 'custom-plugin',
                      before: (tablePlugin: TablePluginData): void => {
                        if (tablePlugin.value) {
                          tablePlugin.element.innerText =
                            this.global.converterService.convertMoney(
                              tablePlugin.value
                            ) + '%';
                        }
                      }
                    },
                    {
                      name: 'badge',
                      field: 'quotationItem.profitLossStatus.name',
                      colorMap: {
                        SAVE: 'SUCCESS',
                        LOSS: 'DANGER'
                      },
                      pill: false,
                      colorField: 'quotationItem.profitLossStatus.code',
                      className: 'badge-catalog ml-3'
                    }
                  ]
                }
          ]
        : [
            {
              field: 'vendorName',
              header: 'table.column.vendorName'
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScore',
                  header: {
                    key: 'table.column.technical',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' +
                            (this.popupPriceEvalResponse.technicalPercentage ||
                              ' - ') +
                            '%)';
                          tableHeaderPlugin.element.className = 'text-right';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: [
                    {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    },
                    {
                      name: 'custom-plugin',
                      after: (tablePlugin: TablePluginData): void => {
                        console.log(tablePlugin.value);
                        if (!tablePlugin.value) {
                          tablePlugin.element.innerText = '0';
                        }
                      }
                    }
                  ]
                },
                this.isFinal
                  ? {
                      field: 'quotationItem.finalEvaluationScore',
                      header: {
                        key: 'table.column.price',
                        plugins: {
                          name: 'custom-plugin',
                          after: (
                            tableHeaderPlugin: TableHeaderPluginData
                          ): void => {
                            if (tableHeaderPlugin) {
                              tableHeaderPlugin.element.innerText +=
                                ' (' +
                                (this.popupPriceEvalResponse.pricePercentage ||
                                  ' - ') +
                                '%)';
                              tableHeaderPlugin.element.className =
                                'text-right';
                            }
                          }
                        }
                      },
                      className: 'text-right',
                      plugins: [
                        {
                          name: 'text-field',
                          type: 'decimal',
                          isView: true
                        },
                        {
                          name: 'custom-plugin',
                          after: (tablePlugin: TablePluginData): void => {
                            console.log(tablePlugin.value);
                            if (!tablePlugin.value) {
                              tablePlugin.element.innerText = '0';
                            }
                          }
                        }
                      ]
                    }
                  : {
                      field: 'quotationItem.evaluationScore',
                      header: {
                        key: 'table.column.price',
                        plugins: {
                          name: 'custom-plugin',
                          after: (
                            tableHeaderPlugin: TableHeaderPluginData
                          ): void => {
                            if (tableHeaderPlugin) {
                              tableHeaderPlugin.element.innerText +=
                                ' (' +
                                (this.popupPriceEvalResponse.pricePercentage ||
                                  ' - ') +
                                '%)';
                              tableHeaderPlugin.element.className =
                                'text-right';
                            }
                          }
                        }
                      },
                      className: 'text-right',
                      plugins: [
                        {
                          name: 'text-field',
                          type: 'decimal',
                          isView: true
                        },
                        {
                          name: 'custom-plugin',
                          after: (tablePlugin: TablePluginData): void => {
                            console.log(tablePlugin.value);
                            if (!tablePlugin.value) {
                              tablePlugin.element.innerText = '0';
                            }
                          }
                        }
                      ]
                    },
                this.isFinal
                  ? {
                      field: 'totalFinalEvaluationScore',
                      header: 'table.column.total',
                      className: 'text-right',
                      plugins: [
                        {
                          name: 'text-field',
                          type: 'decimal',
                          isView: true
                        },
                        {
                          name: 'custom-plugin',
                          after: (tablePlugin: TablePluginData): void => {
                            console.log(tablePlugin.value);
                            if (!tablePlugin.value) {
                              tablePlugin.element.innerText = '0';
                            }
                          }
                        }
                      ]
                    }
                  : {
                      field: 'totalEvaluationScore',
                      header: 'table.column.total',
                      className: 'text-right',
                      plugins: [
                        {
                          name: 'text-field',
                          type: 'decimal',
                          isView: true
                        },
                        {
                          name: 'custom-plugin',
                          after: (tablePlugin: TablePluginData): void => {
                            console.log(tablePlugin.value);
                            if (!tablePlugin.value) {
                              tablePlugin.element.innerText = '0';
                            }
                          }
                        }
                      ]
                    }
              ]
            },
            this.isFinal
              ? {
                  field: 'quotationItem.finalPrice',
                  header: 'table.column.finalPrice',
                  plugins: {
                    name: 'default',
                    type: 'currency'
                  },
                  className: 'text-right'
                }
              : {
                  field: 'quotationItem.price',
                  header: 'table.column.bidPrice',
                  plugins: {
                    name: 'default',
                    type: 'currency'
                  },
                  className: 'text-right'
                },
            {
              field: 'quotationItem.profitLossAmount',
              header: 'table.column.potensialSavingLoss',
              className: 'text-right',
              plugins: [
                {
                  name: 'custom-plugin',
                  before: (tablePlugin: TablePluginData): void => {
                    if (tablePlugin.value) {
                      tablePlugin.element.innerText =
                        this.global.converterService.convertMoney(
                          tablePlugin.value
                        ) + '%';
                    }
                  }
                },
                {
                  name: 'badge',
                  field: 'quotationItem.profitLossStatus.name',
                  colorMap: {
                    SAVE: 'SUCCESS',
                    LOSS: 'DANGER'
                  },
                  pill: false,
                  colorField: 'quotationItem.profitLossStatus.code',
                  className: 'badge-catalog ml-3'
                }
              ]
            }
          ]
    );

    this.tableResponse.setCustomData({
      procurementId: this.procurementId,
      procurementItemId: this.procurementItem.id,
      isFinal: this.isFinal
    });
  }
}
