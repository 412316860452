<app-popup header="app-popup-choose-budget.title" [isLoading]="formLoading">
    <app-fieldset legend="Filter">
      <form
        class="form-horizontal popup-choose-budget"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'app-popup-choose-budget.form.activityPeriod' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            formControlName="activityPeriod"
            [optionList]="activityPeriodOptionList"
          ></app-combo-box>
        </div>
      </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'app-popup-choose-budget.form.organization' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              formControlName="organization"
              stringUrl="/organization"
              isLazy="true"
              header="{{
                'app-popup-choose-budget.header.organization' | translate
              }}"
              (onChange)="onChangeOrganization()"
            ></app-text-tree>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'app-popup-choose-budget.form.workProgram' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-auto-complete
              formControlName="workProgram"
              stringUrl="/app-popup-choose-budget/auto-complete-work-program-list"
              [params]="organizationIdParams"
            ></app-auto-complete>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'app-popup-choose-budget.form.budgetType' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              formControlName="budgetType"
              [optionList]="budgetTypeOptionList"
            ></app-combo-box>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'app-popup-choose-budget.form.costCenter' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-auto-complete
              formControlName="costCenter"
              stringUrl="/app-popup-choose-budget/auto-complete-cost-center-list"
            ></app-auto-complete>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'app-popup-choose-budget.form.branch' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-auto-complete
              formControlName="branchOffice"
              stringUrl="/app-popup-choose-budget/auto-complete-branch-office-list"
              optionView="code"
            ></app-auto-complete>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'app-popup-choose-budget.form.coa' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              formControlName="coa"
              stringUrl="/coa"
              header="{{
                'app-popup-choose-budget.header.organization' | translate
              }}"
            ></app-text-tree>
          </div>
        </div>

        <div class="button-group button-group-center mt-5">
          <app-button mode="BYPASS" color="SECONDARY" (onClick)="doReset()">
            <em class="fas fa-sync-alt"></em>
            {{ 'app.button.reset' | translate }}
          </app-button>
          <app-button
            mode="BYPASS"
            color="PRIMARY"
            (onClick)="doApply()"
            [disabled]="formSaving"
            ><em class="fas fa-check"></em>
            {{ 'app.button.apply' | translate }}
          </app-button>
        </div>
      </form>
    </app-fieldset>
    <app-table [model]="tableResponse" [isServerSide]="true"
        stringUrl="/app-popup-choose-budget/index">
        <ng-template #thead let-model>
            <thead>
              <tr>
                <th scope="col" class="tableNo" colspan="1">{{
                  'app-table.column.no' | translate
                }}</th>
                <th
                  scope="col"
                  *ngFor="let column of model.columns; let i = index"
                  (click)="column.sortable !== false && doSort(column.field)"
                  [ngClass]="[
                    column.sortable !== false ? 'sort' : '',
                    model.request.sortField === column.field ? 'bg-light ' : '',
                    model.request.sortField === column.field &&
                    model.sortOrder === 'ASC'
                      ? 'sort-asc'
                      : '',
                    model.request.sortField === column.field &&
                    model.sortOrder === 'DESC'
                      ? 'sort-desc'
                      : '',
                    column.format === 5 || column.format === 5
                      ? 'text-right'
                      : 'text-center'
                  ]"
                  [hidden]="column.isChecked === false"
                  colspan="99"
                  (keyup)="onKeyUp($event)"
                  (keydown)="onKeyDown($event)"
                >
                  {{ model.moduleCode + '.' + column.header | translate }}
                </th>
                <th scope="col"> 
                    {{ 'app-popup-choose-budget.table.column.select' | translate }}
                </th>
              </tr>
            </thead>
          </ng-template>
          <ng-template #tbody let-model>
            <tbody>
              <tr *ngIf="model.page.totalRecords === 0">
                <td colspan="9999999" class="bg-white text-center">{{
                  'app.info.noData' | translate
                }}</td>
              </tr>
              <tr *ngFor="let record of model.page.records; let i = index">
                <td class="text-center" colspan="1">{{
                  i + 1 + model.request.first
                }}</td>
                <td
                  *ngFor="let column of model.columns"
                  [hidden]="column.isChecked === false"
                  colspan="99"
                  #tdElement
                >
                  {{ record | resolveColumnValue: column:model:tdElement }}
                </td>
                <td class="text-center">
                  <div class="custom-control custom-radio">
                    <input
                      type="radio"
                      class="custom-control-input"
                      [attr.id]="'tbody' + i"
                      [attr.name]="'tbody'"
                      (click)="doCheck($event, record)"
                      [checked]="
                        appPopupChooseBudgetModel.recordPageMap.get(record.id)?.isChecked
                      "
                      (keyup)="onKeyUp($event)"
                      (keydown)="onKeyDown($event)"
                      aria-label="Checked"
                    />
                    <label
                      class="custom-control-label"
                      [attr.for]="'tbody' + i"
                    ></label>
                  </div>
                </td>
              </tr>
            </tbody>
          </ng-template>
    </app-table>
    <ng-template #modalFooter let-activeModal>
        <div class="col-12 text-center">
          <app-button
            class="mr-1"
            color="SECONDARY"
            (onClick)="activeModal.close(true)"
            >{{ 'app.button.close' | translate }}</app-button
          >
          <app-button (onClick)="doChoose()">{{
            'app.button.choose' | translate
          }}</app-button>
        </div>
      </ng-template>
</app-popup>