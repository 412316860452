import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorSegmentationHistory } from '../../core/bean/vendor-segmentation-history';
import { VendorAssociationHistory } from './../../core/bean/vendor-association-history';
@Component({
  templateUrl: './app-popup-tab-segmentation.component.html'
})
export class AppPopupTabSegmentationComponent extends BaseModuleComponent {
  @Input() vendorSegmentationHistory: VendorSegmentationHistory;
  public vendorAssociationExist: boolean;

  public vendorAssociationHistoryList: VendorAssociationHistory[] = [];
  constructor(translateService: TranslateService) {
    super('tab-segmentation', translateService);
  }

  public onInit(): void {
    this.vendorAssociationExist = true;
    this.setFormGroup();
    this.buildFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      segmentation: [null],
      qualification: [null],
      associationList: this.formBuilder.array([]),
      vendorSegmentationId: [null]
    });
  }

  public setFormGroup(): void {
    this.vendorAssociationHistoryList = this.vendorSegmentationHistory.vendorAssociationHistoryList;
    this.vendorAssociationHistoryList.forEach(vendorAssociationHistory => {
      const startDate: Date = vendorAssociationHistory.startDate;
      vendorAssociationHistory.startDate = startDate;
    });
    if (this.vendorAssociationHistoryList !== null) {
      this.vendorAssociationExist = false;
    }
  }
}
