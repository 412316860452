<app-popup-comparison
  header="view-catalog.popup.comparison.title"
  [itemList]="catalogList"
  (onChange)="doGetCheckedList($event)"
>
  <ng-template #content let-rowData>
    <div class="row">
      <div class="col-12 col-md-4">
        <div
          class="comparison-image w-100 overflow-hidden"
          [ngStyle]="{ 'border-radius': '0.2rem' }"
        >
          <img
            [attr.src]="
              global.config.BACKEND_ADDRESS +
                '/view-catalog/file/view/' +
                rowData.fileImageList[0].uploadedFileName +
                '/' + global.appConstant.fileType.IMG_ITEM_CATALOG
                | resolveImgSrc
                | async
            "
            class="w-100"
            [attr.alt]="rowData.name"
            width="auto"
            height="auto"
          />
        </div>
      </div>
      <div class="col-12 col-md-8">
        <h4>{{ rowData.name }}</h4>
        <p>{{
          rowData.vendor.vendorEntity.name + ' ' + rowData.vendor.name
        }}</p>
      </div>
    </div>
  </ng-template>
</app-popup-comparison>
