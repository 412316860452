import { BaseEntity } from '../base/base-entity';
import { AddressPic } from '../bean/address-pic';
import { Region } from '../bean/region';
import { User } from './../bean/user';

export class AddressBookEntity extends BaseEntity {
  addressPicList: AddressPic[];
  address: string;
  phone: string;
  addressDetail: string;
  isDisabled: boolean;
  region: Region;
  
  /* old entity */
  user: User;
}
