import { BaseEntity } from '../base/base-entity';
import { Tc } from '../bean/tc';
import { TermType } from '../bean/term-type';

export class TcDetailEntity extends BaseEntity {
  crudOperation: number;
  termType: TermType = new TermType();
  tc: Tc;
  condition: string;
}
