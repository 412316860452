<app-popup header="tab-company-data.popup.address.title"
  [isLoading]="formLoading">
  <form class="form-horizontal">
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-data.form.popup.buildingName' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.buildingName !== null ?
        vendorAddressHistory.buildingName : '-'}}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-data.form.popup.buildingType' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.buildingType !== null ?
        vendorAddressHistory.buildingType.name : '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-data.form.popup.resourceOwnership' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.resourceOwnership !== null ?
        vendorAddressHistory.resourceOwnership.name : '-'}}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-data.form.popup.buildingLocation' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.buildingLocation !== null ?
        vendorAddressHistory.buildingLocation.name : '-'}}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-data.form.popup.size' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.size !== null ? vendorAddressHistory.size :
        '-'}}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.address' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.address !== null ? vendorAddressHistory.address
        : '-'}}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-data.form.popup.coordinateLocation' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.coordinateLocation !== null ?
        vendorAddressHistory.coordinateLocation : '-'}}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-data.form.popup.mainAddress' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.isMain == true ? 'Yes' : 'No'}}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-data.form.popup.country' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.countryRegion !== null ?
        vendorAddressHistory.countryRegion.name : '-'}}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-data.form.popup.province' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.provinceRegion !== null ?
        vendorAddressHistory.provinceRegion.name : '-'}}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-data.form.popup.city' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.cityRegion !== null ?
        vendorAddressHistory.cityRegion.name : '-'}}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-data.form.popup.district' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.districtRegion !== null ?
        vendorAddressHistory.districtRegion.name : '-'}}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-data.form.popup.postCode' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.postCode !== null ?
        vendorAddressHistory.postCode : '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-data.form.popup.poBox' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.poBox !== null ? vendorAddressHistory.poBox :
        '-'}}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-data.form.popup.phone' | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.phone !== null ?
        vendorAddressHistory.phoneRegion.callingCode +
        vendorAddressHistory.phone : '-'}}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{ 'tab-company-data.form.popup.fax'
        | translate }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorAddressHistory.fax !== null ?
        vendorAddressHistory.faxRegion.callingCode + vendorAddressHistory.fax :
        '-' }}
      </div>
    </div>


    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button class="mr-1" color="SECONDARY"
          (onClick)="activeModal.close(true)">{{ 'app.button.close' | translate
          }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>