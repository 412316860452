import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BiddingEvaluation } from 'src/app/core/bean/bidding-evaluation';
import { VendorSelectionType } from 'src/app/core/bean/vendor-selection-type';
import { AppPopupService } from '../app-popup.service';
import { AppPopupFinalPriceDetailComponent } from './app-popup-final-price-detail.component';

@Injectable()
export class AppPopupFinalPriceDetailService {
  constructor(public appPopupService: AppPopupService) {}

  public open(
    procurementVendorId: number,
    biddingEvaluation: BiddingEvaluation,
    vendorSelectionType: VendorSelectionType,
    fromModuleCode: string
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupFinalPriceDetailComponent,
      {
        procurementVendorId,
        biddingEvaluation,
        vendorSelectionType,
        fromModuleCode
      },
      { size: 'xl' }
    );
  }
}
