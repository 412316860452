<br />
<app-lazy-load
  [isLoading]="formLoading"
  target="body"
  loadingText="{{ 'app-catalog-item.msg.loading' | translate }}"
  (onScrollEnd)="onScrollEnd($event)"
>
  <div *ngIf="!formLoading">
    <div
      class="d-block text-center"
      *ngIf="model.getPageRecords() && model.getPageRecords().length === 0"
    >
      {{ 'app.info.noData' | translate }}
    </div>

    <div class="table-catalog grid">
      <div
        class="catalog-item text-center"
        style="border-radius: 5px 5px 0 0"
        *ngFor="let catalogView of model.getPageRecords()"
      >
        <div
          class="catalog-item-image"
          style="border-radius: 5px 5px 0 0"
          (click)="onClickCatalog(catalogView.id)"
        >
          <img
            width="100%"
            [attr.src]="
              global.config.BACKEND_ADDRESS +
                '/app-catalog-item/thumbnail/view/' +
                catalogView.uploadedFileName +
                '/' +
                global.appConstant.fileType.IMG_ITEM_CATALOG
                | resolveImgSrc
                | async
            "
            attr.alt="image"
            [disabled]="true"
          />
        </div>

        <ng-container>
          <div
            [ngStyle]="{ 'padding-left': '0.3rem', 'padding-top': '0.2rem' }"
            (click)="onClickCatalog(catalogView.id)"
          >
            <div class="text-left">
              <div
                [ngStyle]="{
                  height: '30px',
                  color: 'var(--gray-500)',
                  'margin-bottom': '0.5rem',
                  'font-size': '12px',
                  'font-weight': '400'
                }"
              >
                <span
                  maxLine="2"
                  class="cursor-pointer"
                  [title]="catalogView.merk"
                  >{{ catalogView.merk }}
                </span>
              </div>
              <strong style="font-size: 12px"
                >{{
                  (catalogView.price
                    | currency: '':'') +
                    ' ' +
                    catalogView.currencyCode
                }}
              </strong>

              <div
                class="row"
                style="
                  font-size: 10px;
                  height: 18px;
                  color: var(--gray-500);
                  margin-top: 0.5rem;
                "
              >
                <div class="col-sm-1">
                  <em class="fas fa-shipping-fast"></em>
                </div>
                <p
                  maxLine="1"
                  class="col-sm-10 pl-2 text-left"
                  [title]="catalogView.distributorName"
                >
                  {{ catalogView.distributorName }}
                </p>
              </div>

              <div
                class="row"
                style="
                  font-size: 10px;
                  color: var(--gray-500);
                "
              >
                <div class="col-sm-1">
                  <em class="fas fa-store"></em>
                </div>
                <p
                  maxLine="1"
                  class="col-sm-10 pl-2 text-left"
                  [title]="catalogView.produsenName"
                >
                  {{ catalogView.produsenName }}
                </p>
              </div>

              <div class="row" style="font-size: 10px; color: var(--gray-500)">
                <div class="col-sm-1">
                  <em class="pic pi-star"></em>
                </div>
                <p class="col-sm-6 pl-2 pr-0 text-left">
                  {{ 'app-catalog-item.vendorRating' | translate }}
                  {{ (catalogView.rating | number: '1.2-2') || '-' }}
                </p>
                <app-badge-catalog
                  class="col-sm-3 pl-0"
                  [color]="
                    catalogView.catalogTypeCode ===
                    global.appConstant.vm.CATALOG_TYPE_VENDOR
                      ? 'INFO'
                      : 'SUCCESS'
                  "
                  >{{ catalogView.catalogTypeTranslationKey | translate }}
                </app-badge-catalog>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</app-lazy-load>
