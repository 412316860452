<app-popup
  header="administration-evaluation.popup.administrationEvaluation"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="todo !== 'view' ? false : true"
>
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    *ngIf="!formLoading"
    novalidate
  >
    <h5 class="separator"
      >{{
        'administration-evaluation.popup.vendorInformation.title' | translate
      }}
    </h5>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'administration-evaluation.popup.vendorInformation.vendorName'
          | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <a
          (click)="goToProfile()"
          class="text-primary"
          style="text-decoration: underline"
          >{{ quotationEvaluation.quotation.procurementVendor.vendor.name }}
        </a>
        <span class="ml-1" (click)="goToProfile()"
          ><em
            class="pir pi-external-link"
            style="color: var(--primary)"
            placement="top"
            ngbTooltip="{{
              'administration-evaluation.goToProfile' | translate
            }}"
          ></em
        ></span>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'administration-evaluation.popup.vendorInformation.responseDate'
          | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          quotationEvaluation.quotation.submittedDate
            | date: global.config.parameterModel.datetimeFormatShort
        }}
      </div>
    </div>
    <br />
    <div *ngIf="!formLoading">
      <app-alert-x color="WARNING" *ngIf="!isFullFill && isSave">{{
        'administration-evaluation.popup.criteriaEvaluation.alertInfo'
          | translate
      }}</app-alert-x>
      <app-table-xx
        [model]="tableResponse"
        [isView]="todo === 'view'"
        header="administration-evaluation.popup.criteriaEvaluation.title"
        [isShowSearch]="false"
        [isShowPagination]="false"
      >
      </app-table-xx>

      <div style="display: flex" *ngIf="isFullFill">
        {{
          ('administration-evaluation.popup.criteriaEvaluation.labelResult'
            | translate) + ' '
        }}
        {{ quotationEvaluation.quotation.procurementVendor.vendor.name + ' ' }}
        <span
          class="text-center font-weight-bold mx-1"
          [ngClass]="
            statusResult.code ===
            global.appConstant.pm.SCORING_STATUS_NOT_PASSED
              ? 'text-danger'
              : 'text-success'
          "
          >{{ (getTranslateKey(statusResult) | translate) + ' ' }}
        </span>
        {{
          ('administration-evaluation.popup.criteriaEvaluation.adminEval'
            | translate) + ' '
        }}
      </div>
    </div>

    <ng-template #modalFooter let-activeModal *ngIf="todo !== 'view'">
      <div class="col-12 text-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button color="PRIMARY" (onClick)="doSave()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
