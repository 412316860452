import { ItemEntity } from '../entity/item.entity';
import { ItemStatus } from './item-status';

export class Item extends ItemEntity {
  minPrice: number;
  maxPrice: number;
  dose: string;
  itemStatus: ItemStatus;
  isActive: boolean;
  averagePrice: number;
}
