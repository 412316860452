import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { CostCenterEditAddComponent } from './cost-center-edit-add.component';
import { CostCenterComponent } from './cost-center.component';
import { AppPopupSyncCostCenterComponent } from './app-popup-sync-cost-center.component';
export const routes = [
    { path: '', component: CostCenterComponent, data: { breadcrumb: '' } },
    {
        path: 'edit',
        component: CostCenterEditAddComponent,
        data: { breadcrumb: 'cost-center.breadcrumb.edit' },
        canDeactivate: [ConfirmGuard]
    },
    {
        path: 'add',
        component: CostCenterEditAddComponent,
        data: { breadcrumb: 'cost-center.breadcrumb.add' },
        canDeactivate: [ConfirmGuard]
    }
];

@NgModule({
    imports: [SharedModule, RouterModule.forChild(routes)],
    declarations: [CostCenterComponent, CostCenterEditAddComponent, AppPopupSyncCostCenterComponent],
    providers: [],
    entryComponents: [AppPopupSyncCostCenterComponent],
    exports: [AppPopupSyncCostCenterComponent]
})
export class CostCenterModule { }
