import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { TableModule } from '../../table/table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupAnalysisMultipleComponent } from './app-popup-analysis-multiple.component';
import { AppPopupAnalysisMultipleService } from './app-popup-analysis-multiple.service';

@NgModule({
  imports: [VendorModule, AppPopupModule, TableModule],
  declarations: [AppPopupAnalysisMultipleComponent],
  entryComponents: [AppPopupAnalysisMultipleComponent],
  providers: [AppPopupAnalysisMultipleService],
  exports: [AppPopupAnalysisMultipleComponent]
})
export class AppPopupAnalysisMultipleModule {}
