import { Currency } from '../../bean/currency';
import { ReportRecap } from '../../bean/report-recap';
import { ReportRecapColumn } from '../../bean/report-recap-column';
import { ReportRecapFilter } from '../../bean/report-recap-filter';
import { ReportFilterGroup } from '../../bean/report-recap-filter-group';

export class ReportRecapBudgetUsedResponse {
  reportRecap: ReportRecap;
  reportRecapColumnList: ReportRecapColumn[];
  reportFilterGroupList: ReportFilterGroup[];
  reportRecapFilterList: ReportRecapFilter[];
  periodProgramList: Number[];
  currencyList: Currency[];
}
