import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppApprovalLogModule } from '../app-approval-log/app-approval-log.module';
import { AppApprovalPathXModule } from '../app-approval-path-x';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppDropdownSelectModule } from '../app-dropdown-select/app-dropdown-select.module';
import { AppTableXModule } from '../app-table-x/components/app-table-x/app-table-x.module';
import { AppTextAreaModule } from '../app-text-area/app-text-area.module';
import { AppUploadXModule } from '../upload/components/app-upload-x/app-upload-x.module';
import { AppApprovalPrcsXComponent } from './app-approval-prcs-x.component';
@NgModule({
  imports: [
    VendorModule,
    AppTableXModule,
    AppApprovalPathXModule,
    AppUploadXModule,
    AppTextAreaModule,
    AppButtonModule,
    AppCardModule,
    AppDropdownSelectModule,
    AppApprovalLogModule
  ],
  declarations: [AppApprovalPrcsXComponent],
  exports: [AppApprovalPrcsXComponent]
})
export class AppApprovalPrcsXModule {}
