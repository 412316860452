import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppApprovalPrcsXModule } from "src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module";
import { ConfirmGuard } from "src/app/core/guard/confirm.guard";
import { SharedModule } from "src/app/core/shared/shared.module";
import { ClaimEditAddComponent } from "./claim-edit-add.component";
import { ClaimComponent } from "./claim-component";


const routes: Routes = [
  { path: '', component: ClaimComponent, data: { breadcrumb: '' } },
  {
    path: 'detail',
    component: ClaimEditAddComponent,
    data: { breadcrumb: 'claim.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: ClaimEditAddComponent,
    data: { breadcrumb: 'claim.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }

];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes), AppApprovalPrcsXModule
  ],
  declarations: [
    ClaimComponent,
    ClaimEditAddComponent
  ],
  providers: []
})
export class ClaimModule { }