import { UserPreference } from 'src/app/core/bean/user-preference';
import { Lang } from '../../core/bean/lang';
import { User } from '../../core/bean/user';
import { Vendor } from '../../core/bean/vendor';

export class AccountSettingResponse {
    vendor: Vendor = new Vendor();
    langList: Lang[];
    user: User;
    userPreferenceNotif: UserPreference;
}
