import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Project } from '../../core/bean/project';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { FieldFormatEnum } from './../../core/components/app-table/model/field-format.enum';
import { Response } from './../../core/model/response-model';
@Component({
  templateUrl: './project.component.html'
})
export class ProjectComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Project>;
  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('project', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      organizationName: [null],
      organizationId: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      { field: 'organization.name', header: 'table.column.organization' },
      {
        field: 'workPlanBudgetTotal',
        header: 'table.column.workPlanBudgetTotal',
        format: FieldFormatEnum.Currency
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/project/add']);
  }

  public doEdit(project: Project): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('projectId', project.id);
    this.router.navigate(['/pages/project/edit']);
  }

  public doDetail(project: Project): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'detail');
    this.global.routerParams.set('projectId', project.id);
    this.router.navigate(['/pages/project/detail']);
  }

  public doDelete(project: Project): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<Project>>('/project/delete', project)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public onChooseOrganization(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(data => {
        this.formGroup.patchValue({
          organizationName: data[0].name,
          organizationId: data[0].id
        });
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
      });
  }
}
