import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaymentWorklistStatus } from 'src/app/core/bean/payment-worklist-status';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { PaymentVoucherView } from '../../core/view/entity/bean/payment-voucher-view';

@Component({
  templateUrl: './payment-voucher.component.html'
})
export class PaymentVoucherComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<PaymentVoucherView>;

  public statusOptionList: OptionListModel<PaymentWorklistStatus> =
    new OptionListModel(true);

  constructor(translate: TranslateService) {
    super('payment-voucher', translate);
  }
  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      statusList: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<PaymentWorklistStatus>('/payment-voucher/get-option-list')
      .subscribe(statusList => {
        if (statusList != null) {
          this.statusOptionList.setRequestValues(statusList);
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'invoiceNumber',
        header: 'table.column.invoiceNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClick.bind(this)
        }
      },
      {
        field: 'invoiceDate',
        header: 'table.column.invoiceDate',
        plugins: 'date'
      },
      { field: 'title', header: 'table.column.title' },
      { field: 'vendor.name', header: 'table.column.vendorName' },
      {
        field: 'amount',
        header: 'table.column.requestedBills',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      {
        field: 'paymentWorklistStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'paymentWorklistStatus.code',
          colorMap: {
            NEW: 'INFO',
            DRAFT: 'SECONDARY',
            REVISION: 'FEEDBACK',
            WAITING_APPROVAL: 'WARNING'
          }
        }
      }
    ]);
  }

  public doClick(paymentVoucherView: PaymentVoucherView): void {
    this.global.routerParams.clear();
    if (
      paymentVoucherView.paymentWorklistStatus.code !==
      this.global.appConstant.cm.PAYMENT_VOUCHER_STATUS_WAITING_APPROVAL
    ) {
      this.global.routerParams.set('todo', 'edit');
    } else {
      this.global.routerParams.set('todo', 'view');
    }
    this.global.routerParams.set('paymentId', paymentVoucherView.id);
    this.global.routerParams.set(
      'paymentWorklistStatus',
      paymentVoucherView.paymentWorklistStatus
    );
    this.global.routerParams.set('urlBackOutside', '/pages/payment-voucher');
    this.router.navigate(['/pages/payment-voucher/detail']);
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
    } else {
      this.formGroup.value.fromDate = null;
      this.formGroup.value.untilDate = null;
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
