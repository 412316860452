import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationVendorResponse } from '../../../../portal/registration/registration-vendor-response';
import { RegistrationComponent } from '../../../../portal/registration/registration.component';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
@Component({
  templateUrl: './app-popup-registration-term-condition.component.html',
  styleUrls: ['./app-popup-registration-term-condition.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupRegistrationTermConditionComponent extends BaseComponentComponent {
  readonly urlRouterNavigateToRegistration = '/registration';
  public registrationComponent: RegistrationComponent;
  termsAndCondition: any;
  @Input() registrationVendorResponse: RegistrationVendorResponse;

  constructor(
    public activeModal: NgbActiveModal,
    public sanitized: DomSanitizer
  ) {
    super('app-popup-registration-term-condition');
  }

  onInit(): void {
    this.setStateReady();
    this.test();
  }

  public test(): void {
    const index =
      this.registrationVendorResponse.contentVendorTOCList.findIndex(
        toc =>
          toc.lang.code.toUpperCase() === this.global.currentLang.toUpperCase()
      );
    this.termsAndCondition = this.sanitized.bypassSecurityTrustHtml(
      this.registrationVendorResponse.contentVendorTOCList[
        index
      ].value.toString()
    );
  }

  doBack(): void {
    this.router.navigate([this.urlRouterNavigateToRegistration]);
  }
}
