<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="vendor-qualification.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'vendor-qualification.form.code' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <input
              class="form-control validation-field"
              type="text"
              formControlName="code"
              minlength="2"
              maxlength="32"
              [ngClass]="{
                'is-invalid': submitted && formGroupControls.code.errors
              }"
              aria-label="Code"
            />
            <small
              class="text-danger"
              *ngIf="
                (submitted && formGroupControls.code.errors) ||
                (formGroupControls.code.touched &&
                  formGroupControls.code.errors)
              "
              >{{ 'app.validation.required' | translate }}</small
            >
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'vendor-qualification.form.name' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <input
              appAlphaNumeric
              class="form-control validation-field"
              type="text"
              formControlName="name"
              maxlength="32"
              [ngClass]="{
                'is-invalid': submitted && formGroupControls.name.errors
              }"
              aria-label="Name"
            />
            <small
              class="text-danger"
              *ngIf="
                (submitted && formGroupControls.name.errors) ||
                (formGroupControls.name.touched &&
                  formGroupControls.name.errors)
              "
              >{{ 'app.validation.required' | translate }}</small
            >
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-qualification.form.description' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <textarea
              class="form-control"
              formControlName="description"
              maxlength="512"
              rows="3"
              aria-label="Description"
            ></textarea>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <button
            type="button "
            class="btn btn-secondary"
            (click)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </button>
          <button
            type="button "
            class="btn btn-primary ml-2"
            (click)="doSave()"
          >
            {{ 'app.button.save' | translate }}
          </button>
        </div>
      </form>
    </app-card>
  </div>
</div>
