import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AgreementType } from 'src/app/core/bean/agreement-type';
import { AppTipsComponent } from 'src/app/core/components/app-tips/app-tips.component';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementVendor } from '../../core/bean/procurement-vendor';
import { QuotationItemReference } from '../../core/bean/quotation-item-reference';
import { Vendor } from '../../core/bean/vendor';
import { AppOfficialReportModel } from '../../core/components/app-official-report/app-official-report-model';
import { AppPopupAllocatedItemDetailService } from '../../core/components/app-popup/app-popup-allocated-item-detail/app-popup-allocated-item-detail.service';
import { AppPopupAnalysisMultipleService } from '../../core/components/app-popup/app-popup-analysis-multiple/app-popup-analysis-multiple.service';
import { AppPopupAnalysisSingleService } from '../../core/components/app-popup/app-popup-analysis-single/app-popup-analysis-single.service';
import { AppPopupChooseVendorMultiWinnerService } from '../../core/components/app-popup/app-popup-choose-vendor-multi-winner/app-popup-choose-vendor-multi-winner.service';
import { AppPopupFinalPriceDetailService } from '../../core/components/app-popup/app-popup-final-price-detail/app-popup-final-price-detail.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { ToastService } from '../../core/components/app-toast/app-toast.service';
import {
  TableHeaderPluginData,
  TablePluginData
} from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { VendorDesignationSelectedVendorView } from '../../core/view/entity/bean/vendor-designation-selected-vendor-view';
import { VendorQuotationByItemView } from '../../core/view/entity/bean/vendor-quotation-by-item-view';
import { VendorQuotationView } from '../../core/view/entity/bean/vendor-quotation-view';
import { VendorDesignationPopupChangeWinnerComponent } from './vendor-designation-popup-change-winner.component';
import { VendorDesignationPopupWinnerComponent } from './vendor-designation-popup-winner-confirmation.component';
import { VendorDesignationRequest } from './vendor-designation-request';
import { VendorDesignationResponse } from './vendor-designation-response';
@Component({
  templateUrl: './vendor-designation-edit.component.html'
})
export class VendorDesignationEditComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild('appTips') appTips: AppTipsComponent;
  public procurementId: number;
  public procurement: Procurement;
  public urlBackOutside: string;
  public objectId: number;
  public approvalModelPrcsId: number;
  public vendorDesignationResponse: VendorDesignationResponse;
  public tableResponseFinalEval: TableResponseModel<VendorQuotationView>;
  public tableResponseAllocatedItem: TableResponseModel<VendorQuotationByItemView>;
  public tableResponseSelectedVendor: TableResponseModel<VendorDesignationSelectedVendorView>;
  public quotationItemReferenceFinalList: QuotationItemReference[] = [];
  public appOfficialReportModel: AppOfficialReportModel;
  public isOfficialReportNull = null;
  public isAgreementTypeNull = null;
  public isThingsToConsider = false;
  public isMustFullFill = false;
  public CURRENCY_DIGITS_INFO: string;
  public canceledOrFailedMessage: string;
  public isShowBadgeStatus: boolean;
  public agreementTypeParam: AgreementType;
  public isShowInputControl = true;

  public badgeColor = {
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    NEW: 'INFO',
    HOLD: 'DANGER',
    CANCELED: 'DANGER',
    FAILED: 'DANGER',
    DRAFT: 'SECONDARY'
  };

  constructor(
    translateService: TranslateService,
    public currencyPipe: CurrencyPipe,
    public snackbarService: SnackbarService,
    public appPopupFinalPriceDetailService: AppPopupFinalPriceDetailService,
    public appPopupService: AppPopupService,
    public appPopupChooseVendorMultiWinnerService: AppPopupChooseVendorMultiWinnerService,
    public appPopupAllocatedItemDetailService: AppPopupAllocatedItemDetailService,
    public toastService: ToastService,
    public appPopupAnalysisSingleService: AppPopupAnalysisSingleService,
    public appPopupAnalysisMultipleService: AppPopupAnalysisMultipleService
  ) {
    super('vendor-designation', translateService);
  }

  public onInit(): void {
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
    this.doSetDataFromRouterParams();
    // this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
    this.checkAgreementType();
  }

  public doSetDataFromRouterParams(): void {
    this.procurement = this.global.routerParams.get('procurement');
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.objectId = this.global.routerParams.get('objectId');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<VendorDesignationResponse>(
        '/vendor-designation/edit',
        new RouteRequestModel(this.todo, this.procurementId)
      )
      .subscribe((response: VendorDesignationResponse) => {
        this.vendorDesignationResponse = response;
        this.procurement = response.procurement;
        this.appOfficialReportModel = response.appOfficialReportModel;
        this.quotationItemReferenceFinalList =
          response.quotationItemReferenceFinalList;
        this.agreementTypeParam = response.procurement.agreementType;
        if (
          this.procurement.vendorSelectionType.code ===
          this.global.appConstant.pm.VENDOR_SELECTION_TYPE_MULTI_WINNER
        ) {
          this.buildTableQuotationItem();
          this.buildTableSelectedVendor();
        } else {
          this.buildTableResponse();
        }
        const statusList = [
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
        ];

        if (statusList.includes(this.procurement.procurementStatus.code)) {
          if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'vendor-designation.alert.msg.sorryWaitingCancelationApproval';
          } else if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'vendor-designation.alert.msg.sorryWaitingFailedApproval';
          } else {
            this.canceledOrFailedMessage =
              'vendor-designation.alert.msg.sorryFailedAssessment';
          }
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponseFinalEval = new TableResponseModel(
      this.moduleCode,
      this.procurement.biddingEvaluation.code !==
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
        ? [
            {
              field: 'vendorName',
              header: 'table.column.vendorName',
              plugins: [
                {
                  name: 'hyperlink',
                  onClick: this.doViewQuotation.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pic pi-exclamation-triangle',
                  onClick: this.onClickIconConsider.bind(this),
                  isShowIconFn: this.showIcon.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pis pi-exclamation-triangle text-danger',
                  onClick: this.onClickIconDanger.bind(this),
                  isShowIconFn: this.showIconDanger.bind(this)
                }
              ]
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScoreStatus.name',
                  header: 'table.column.technical',
                  plugins: {
                    name: 'badge',
                    colorField: 'technicalScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                this.procurement.biddingEvaluation.code ===
                this.global.appConstant.pm
                  .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
                  ? {
                      field: 'finalPriceScoreStatus.name',
                      header: 'table.column.price',
                      plugins: {
                        name: 'badge',
                        colorField: 'finalPriceScoreStatus.code',
                        colorMap: {
                          PASSED: 'SUCCESS',
                          NOT_PASSED: 'DANGER'
                        }
                      }
                    }
                  : {
                      field: 'finalPriceScore',
                      header: 'table.column.price',
                      className: 'text-right',
                      plugins: {
                        name: 'text-field',
                        type: 'decimal',
                        isView: true
                      }
                    }
              ]
            },
            {
              field: 'finalPrice',
              header: 'table.column.finalPrice',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: 'text-right'
            },
            {
              field: 'finalProfitLossAmount',
              header: 'table.column.savingLoss',
              className: 'text-right',
              plugins: [
                {
                  name: 'custom-plugin',
                  before: (tablePlugin: TablePluginData): void => {
                    if (tablePlugin.value) {
                      tablePlugin.element.innerText =
                        this.global.converterService.convertMoney(
                          tablePlugin.value
                        ) + ' %';
                    }
                  }
                },
                {
                  name: 'badge',
                  field: 'finalProfitLossStatus.name',
                  colorMap: {
                    SAVE: 'SUCCESS',
                    LOSS: 'DANGER'
                  },
                  pill: false,
                  colorField: 'finalProfitLossStatus.code',
                  className: 'badge-catalog ml-3'
                }
              ]
            },
            {
              field: 'vendorName',
              header: 'table.column.resultAnalysis',
              plugins: [
                {
                  name: 'hyperlink',
                  onClick: this.doSeeDetailsAnalysisSingle.bind(this),
                  customText: this.translateService.instant(
                    'vendor-designation.table.value.seeDetails'
                  )
                }
              ]
            }
          ]
        : [
            {
              field: 'vendorName',
              header: 'table.column.vendorName',
              plugins: [
                {
                  name: 'hyperlink',
                  onClick: this.doViewQuotation.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pic pi-exclamation-triangle',
                  onClick: this.onClickIconConsider.bind(this),
                  isShowIconFn: this.showIcon.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pis pi-exclamation-triangle text-danger',
                  onClick: this.onClickIconDanger.bind(this),
                  isShowIconFn: this.showIconDanger.bind(this)
                }
              ]
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScore',
                  header: {
                    key: 'table.column.technical',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' +
                            (this.vendorDesignationResponse
                              .technicalPercentage || ' - ') +
                            '%)';
                          tableHeaderPlugin.element.className = 'text-right';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                },
                {
                  field: 'finalPriceScore',
                  header: {
                    key: 'table.column.price',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' +
                            (this.vendorDesignationResponse.pricePercentage ||
                              ' - ') +
                            '%)';
                          tableHeaderPlugin.element.className = 'text-right';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                },
                {
                  field: 'totalFinalEvaluationScore',
                  header: 'table.column.total',
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                }
              ]
            },
            {
              field: 'finalPrice',
              header: 'table.column.finalPrice',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: 'text-right'
            },
            {
              field: 'finalProfitLossAmount',
              header: 'table.column.savingLoss',
              className: 'text-right',
              plugins: [
                {
                  name: 'custom-plugin',
                  before: (tablePlugin: TablePluginData): void => {
                    if (tablePlugin.value) {
                      tablePlugin.element.innerText =
                        this.global.converterService.convertMoney(
                          tablePlugin.value
                        ) + ' %';
                    }
                  }
                },
                {
                  name: 'badge',
                  field: 'finalProfitLossStatus.name',
                  colorMap: {
                    SAVE: 'SUCCESS',
                    LOSS: 'DANGER'
                  },
                  pill: false,
                  colorField: 'finalProfitLossStatus.code',
                  className: 'badge-catalog ml-3'
                }
              ]
            },
            {
              field: 'vendorName',
              header: 'table.column.resultAnalysis',
              plugins: [
                {
                  name: 'hyperlink',
                  onClick: this.doSeeDetailsAnalysisSingle.bind(this),
                  customText: this.translateService.instant(
                    'vendor-designation.table.value.seeDetails'
                  )
                }
              ]
            }
          ]
    );

    this.tableResponseFinalEval.setRecordList(
      this.vendorDesignationResponse.vendorQuotationViewList
    );
  }

  public buildTableQuotationItem(): void {
    this.tableResponseAllocatedItem = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementItem.prItem.pr.code',
        header: 'table.column.prNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doChooseQuotationVendor.bind(this)
        }
      },
      {
        field: 'procurementItem.prItem.item.code',
        header: 'table.column.itemCode'
      },
      {
        field: 'procurementItem.prItem.code',
        header: 'table.column.prLine'
      },
      {
        field: 'procurementItem.prItem.item.name',
        header: 'table.column.itemName'
      },
      {
        field: 'procurementItem.prItem.item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'procurementItem.prItem.item.itemType.code'
        }
      },
      {
        field: 'procurementItem.prItem.quantity',
        header: 'table.column.quantity',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'procurementItem.prItem.item.uom.name',
        header: 'table.column.unit'
      },
      {
        field: 'procurementItem.isRefute',
        header: 'table.column.itemStatus',
        plugins: {
          name: 'badge',
          colorField: 'procurementItem.isAllocated',
          colorMap: {
            true: 'SUCCESS',
            false: 'DANGER',
            refute: 'DANGER'
          },
          callbacks: {
            text: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.row.record.procurementItem.isRefute) {
                return 'Refute';
              } else if (
                tablePluginData.row.record.procurementItem.isAllocated
              ) {
                return 'Allocated';
              } else {
                return 'Not Allocated';
              }
            },
            color: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.row.record.procurementItem.isRefute) {
                return 'refute';
              } else if (
                tablePluginData.row.record.procurementItem.isAllocated
              ) {
                return 'true';
              } else {
                return 'false';
              }
            }
          }
        }
      },
      {
        field: 'procurementItem.prItem.pr.code',
        header: 'table.column.resultAnalysis',
        plugins: [
          {
            name: 'hyperlink',
            onClick: this.doSeeDetailsAnalysisMultiple.bind(this),
            customText: this.translateService.instant(
              'vendor-designation.table.value.seeDetails'
            )
          }
        ]
      }
    ]);

    this.tableResponseAllocatedItem.setRecordList(
      this.vendorDesignationResponse.vendorQuotationByItemViewList
    );
  }

  public buildTableSelectedVendor(): void {
    this.tableResponseSelectedVendor = new TableResponseModel(this.moduleCode, [
      {
        field: 'quotation.procurementVendor.vendor.name',
        header: 'table.column.vendorName',
        plugins: [
          {
            name: 'hyperlink',
            onClick: this.doShowItem.bind(this)
          },
          {
            name: 'icon',
            className: 'pic pi-exclamation-triangle',
            onClick: this.onClickIconConsider.bind(this),
            isShowIconFn: this.showIcon.bind(this)
          },
          {
            name: 'icon',
            className: 'pis pi-exclamation-triangle text-danger',
            onClick: this.onClickIconDanger.bind(this),
            isShowIconFn: this.showIconDanger.bind(this)
          }
        ]
      },
      this.procurement.biddingEvaluation.code !==
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
        ? {
            header: 'table.column.evaluation',
            columnList: [
              {
                field: 'administrationScoreStatus.name',
                header: 'table.column.administration',
                plugins: {
                  name: 'badge',
                  colorField: 'administrationScoreStatus.code',
                  colorMap: {
                    PASSED: 'SUCCESS',
                    NOT_PASSED: 'DANGER'
                  }
                }
              },
              {
                field: 'technicalScoreStatus.name',
                header: 'table.column.technical',
                plugins: {
                  name: 'badge',
                  colorField: 'technicalScoreStatus.code',
                  colorMap: {
                    PASSED: 'SUCCESS',
                    NOT_PASSED: 'DANGER'
                  }
                }
              }
            ]
          }
        : {
            header: 'table.column.evaluation',
            columnList: [
              {
                field: 'administrationScoreStatus.name',
                header: 'table.column.administration',
                plugins: {
                  name: 'badge',
                  colorField: 'administrationScoreStatus.code',
                  colorMap: {
                    PASSED: 'SUCCESS',
                    NOT_PASSED: 'DANGER'
                  }
                }
              },
              {
                field: 'technicalScore',
                header: {
                  key: 'table.column.technical',
                  plugins: {
                    name: 'custom-plugin',
                    after: (tableHeaderPlugin: TableHeaderPluginData): void => {
                      if (tableHeaderPlugin) {
                        tableHeaderPlugin.element.innerText +=
                          ' (' +
                          (this.vendorDesignationResponse.technicalPercentage ||
                            ' - ') +
                          '%)';
                        tableHeaderPlugin.element.className = 'text-right';
                      }
                    }
                  }
                },
                className: 'text-right',
                plugins: {
                  name: 'text-field',
                  type: 'decimal',
                  isView: true
                }
              },
              {
                field: 'totalFinalEvaluationScore',
                header: 'table.column.total',
                className: 'text-right',
                plugins: {
                  name: 'text-field',
                  type: 'decimal',
                  isView: true
                }
              }
            ]
          },
      {
        header: 'table.column.allocatedItem',
        columnList: [
          {
            field: 'goodsSelectedItem',
            header: 'table.column.goodsItem',
            className: 'text-right'
          },
          {
            field: 'serviceSelectedItem',
            header: 'table.column.serviceItem',
            className: 'text-right'
          }
        ]
      }
    ]);

    // this.tableResponseSelectedVendor.setCustomData({
    //   procurementId: this.procurementId,
    //   todo: this.todo
    // });
    if (this.todo === 'view') {
      this.vendorDesignationResponse.vendorDesignationSelectedVendorViewList =
        this.vendorDesignationResponse.vendorDesignationSelectedVendorViewList.filter(
          selectedVendor => {
            const totalGoods = selectedVendor.goodsSelectedItem.split('/')[0];
            const totalService =
              selectedVendor.serviceSelectedItem.split('/')[0];
            if (+totalGoods > 0 || +totalService > 0) {
              return true;
            } else {
              return false;
            }
          }
        );
    }
    this.tableResponseSelectedVendor.setRecordList(
      this.vendorDesignationResponse.vendorDesignationSelectedVendorViewList
    );
  }

  public onClickIconConsider(data): void {
    const vendor: Vendor = data.procurementVendor
      ? data.procurementVendor.vendor
      : data.quotation.procurementVendor.vendor;

    if (
      !data.isExpiredDoc &&
      !data.isExpiredCertificate &&
      (vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE ||
        vendor.vendorStatus.code ===
          this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) &&
      !data.isCatalogContract
    ) {
      const vendorName = data.vendorName
        ? data.vendorName
        : data.quotation.procurementVendor.vendor.name;
      let msg = '<ol class="text-left ml-4">';
      if (data.sameCompanyOrgVendorName) {
        let vendorNameList: string[] = data.sameCompanyOrgVendorName.split(',');
        vendorNameList = [...new Set(vendorNameList)];

        msg +=
          '<li>' +
          vendorName +
          ' ' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.sameCompanyOrg'
          ) +
          ' ' +
          vendorNameList.toString().replace('[', '').replace(']', '') +
          '</li>';
      }
      if (!data.isFinancialReport) {
        msg +=
          '<li>' +
          vendorName +
          ' ' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.isFinancialReport'
          ) +
          '</li>';
      }
      if (data.isBlacklist) {
        msg +=
          '<li>' +
          vendorName +
          ' ' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.isBlacklist'
          ) +
          '</li>';
      }
      if (data.isCertificateWillBeExpired) {
        msg +=
          '<li>' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.certificate'
          ) +
          ' ' +
          vendorName +
          ' ' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.isCertificateWillBeExpired'
          ) +
          '</li>';
      }

      if (data.isDer) {
        msg +=
          '<li>' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.derRatio'
          ) +
          ' ' +
          vendorName +
          ' ' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.isDer'
          ) +
          '</li>';
      }

      if (data.isCatalogContract) {
        msg +=
          '<li>' +
          vendorName +
          ' ' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.isCatalogContract'
          ) +
          ' ' +
          data.catalogName +
          '</li>';
      }
      const title = 'vendor-designation.popup.warning.thingsToConsider.title';
      this.doShowPopup(msg, title);
    }
  }

  public showIcon(data): boolean {
    const vendor: Vendor = data.procurementVendor
      ? data.procurementVendor.vendor
      : data.quotation.procurementVendor.vendor;
    if (
      !data.isExpiredCertificate &&
      !data.isExpiredDoc &&
      (vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE ||
        vendor.vendorStatus.code ===
          this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) &&
      (data.sameCompanyOrgVendorName ||
        !data.isFinancialReport ||
        data.isBlacklist ||
        data.isCertificateWillBeExpired ||
        data.isDer) &&
      !data.isCatalogContract
    ) {
      this.isThingsToConsider = true;
      return true;
    } else {
      return false;
    }
  }

  public showIconDanger(data): boolean {
    const vendor: Vendor = data.procurementVendor
      ? data.procurementVendor.vendor
      : data.quotation.procurementVendor.vendor;
    if (
      data.isExpiredCertificate ||
      data.isExpiredDoc ||
      (vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
        vendor.vendorStatus.code !==
          this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) ||
      data.isCatalogContract
    ) {
      this.isMustFullFill = true;
      return true;
    } else {
      return false;
    }
  }

  public onClickIconDanger(data): void {
    const vendor: Vendor = data.procurementVendor
      ? data.procurementVendor.vendor
      : data.quotation.procurementVendor.vendor;
    let msg = '';
    if (
      vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
      vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST
    ) {
      msg +=
        '<li>' +
        vendor.name +
        ' ' +
        this.global.translateService.instant(
          'vendor-designation.popup.warning.msg.isNotActive'
        ) +
        '</li>';
    }

    if (data.isExpiredCertificate) {
      msg +=
        '<li>' +
        this.global.translateService.instant(
          'vendor-designation.popup.warning.msg.certificate'
        ) +
        ' ' +
        vendor.name +
        ' ' +
        this.global.translateService.instant(
          'vendor-designation.popup.warning.msg.isExpiredCertificate'
        ) +
        '</li>';
    }

    if (data.isExpiredDoc) {
      msg +=
        '<li>' +
        vendor.name +
        ' ' +
        this.global.translateService.instant(
          'vendor-designation.popup.warning.msg.isExpiredDoc'
        ) +
        '</li>';
    }
    if (data.isCatalogContract) {
      msg +=
        '<li>' +
        vendor.name +
        ' ' +
        this.global.translateService.instant(
          'vendor-designation.popup.warning.msg.isCatalogContract'
        ) +
        ' ' +
        data.catalogName +
        '</li>';
    }
    const title = 'vendor-designation.popup.warning.mustFullFill.title';
    this.doShowPopup(msg, title);
  }

  public doShowPopup(msg: string, title: string): void {
    this.global.modalService.confirmation(
      msg,
      title,
      null,
      null,
      'pvc pv-exclamation-triangle',
      true
    );
  }

  public doViewQuotation(vendorQuotationView: VendorQuotationView): void {
    this.appPopupFinalPriceDetailService
      .open(
        vendorQuotationView.procurementVendor.id,
        this.procurement.biddingEvaluation,
        this.procurement.vendorSelectionType,
        this.moduleCode
      )
      .subscribe();
  }

  public doChangeWinningVendor(): void {
    this.appPopupService
      .open(
        VendorDesignationPopupChangeWinnerComponent,
        {
          procurement: this.procurement,
          technicalPercentage:
            this.vendorDesignationResponse.technicalPercentage,
          pricePercentage: this.vendorDesignationResponse.pricePercentage,
          vendorWinner: this.tableResponseFinalEval.getRecordList()
        },
        { size: 'xl' }
      )
      .subscribe((response: VendorQuotationView[]) => {
        this.formGroup.markAsDirty();
        if (response && response.length > 0) {
          this.global.modalService
            .confirmation(
              this.translateService.instant(
                'vendor-designation.modal.saveSuccess.msg'
              ),
              'vendor-designation.modal.saveSuccess.title',
              null,
              null,
              'pvc pv-check-circle',
              false,
              {
                autoCloseInMs: 1000
              }
            )
            .subscribe();
          const winnerOld = this.tableResponseFinalEval.getRecordList()[0];
          if (winnerOld.id !== response[0].id) {
            this.tableResponseFinalEval.setRecordList(response);
            this.tableResponseFinalEval.reload();
            this.appOfficialReportModel.isGenerate = this.appOfficialReportModel
              .isGenerate
              ? false
              : false;
            if (
              response[0].isExpiredCertificate ||
              response[0].isExpiredDoc ||
              (response[0].procurementVendor.vendor.vendorStatus.code !==
                this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
                response[0].procurementVendor.vendor.vendorStatus.code !==
                  this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) ||
              response[0].isCatalogContract
            ) {
              this.isMustFullFill = true;
            } else {
              this.isMustFullFill = false;
            }
          }
        }
      });
  }

  public doShowItem(
    vendorDesignationSelectedVendorView: VendorDesignationSelectedVendorView
  ): void {
    this.appPopupAllocatedItemDetailService
      .open(vendorDesignationSelectedVendorView.quotation.procurementVendor.id)
      .subscribe();
  }

  public doChooseQuotationVendor(
    vendorQuotationByItemView: VendorQuotationByItemView
  ): void {
    this.appPopupChooseVendorMultiWinnerService
      .open(
        vendorQuotationByItemView.procurementItem.id,
        'view',
        !vendorQuotationByItemView.procurementItem.isRefute &&
          this.todo !== 'view',
        null,
        vendorQuotationByItemView.procurementItem.isRefute
      )
      .subscribe(response => {
        this.formGroup.markAsDirty();
        if (response) {
          this.global.modalService
            .confirmation(
              this.translateService.instant(
                'vendor-designation.modal.saveSuccess.msg'
              ),
              'vendor-designation.modal.saveSuccess.title',
              null,
              null,
              'pvc pv-check-circle',
              false,
              {
                autoCloseInMs: 1000
              }
            )
            .subscribe();
        }
        if (
          this.quotationItemReferenceFinalList !==
          response.quotationItemReferenceFinalList
        ) {
          this.appOfficialReportModel.isGenerate = this.appOfficialReportModel
            .isGenerate
            ? false
            : false;
          this.quotationItemReferenceFinalList =
            response.quotationItemReferenceFinalList;
          this.isMustFullFill = false;
          const selectedVendorList =
            this.tableResponseSelectedVendor.getRecordList();
          selectedVendorList.forEach(selectedVendor => {
            const itemVendorGoods = this.quotationItemReferenceFinalList.filter(
              quotationItemRef =>
                quotationItemRef.quotationItem.quotation.id ===
                  selectedVendor.quotation.id &&
                quotationItemRef.quotationItem.procurementItem.prItem.item
                  .itemType.code ===
                  this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
            ).length;
            const itemVendorService =
              this.quotationItemReferenceFinalList.filter(
                quotationItemRef =>
                  quotationItemRef.quotationItem.quotation.id ===
                    selectedVendor.quotation.id &&
                  quotationItemRef.quotationItem.procurementItem.prItem.item
                    .itemType.code ===
                    this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
              ).length;
            const totalGoods = selectedVendor.goodsSelectedItem.split('/')[1];
            const totalService =
              selectedVendor.serviceSelectedItem.split('/')[1];
            selectedVendor.goodsSelectedItem =
              itemVendorGoods + '/' + totalGoods;
            selectedVendor.serviceSelectedItem =
              itemVendorService + '/' + totalService;
          });
          this.tableResponseSelectedVendor.setRecordList(selectedVendorList);
          this.tableResponseSelectedVendor.reload();
        }
      });
  }

  public doOnChange(event: AppOfficialReportModel): void {
    this.appOfficialReportModel = event;
  }

  public doSeeDetailsAnalysisSingle(
    vendorQuotationView: VendorQuotationView
  ): void {
    this.appPopupAnalysisSingleService.open(
      vendorQuotationView.procurementVendor.id,
      this.moduleCode
    );
  }

  public doSeeDetailsAnalysisMultiple(
    vendorQuotationByItemView: VendorQuotationByItemView
  ): void {
    this.appPopupAnalysisMultipleService.open(
      vendorQuotationByItemView.procurementItem.procurement.id,
      vendorQuotationByItemView.procurementItem.prItem.id,
      this.moduleCode
    );
  }

  public doSubmit(isSubmit: boolean): void {
    if (
      this.procurement.agreementType === null ||
      this.procurement.agreementType === undefined
    ) {
      this.isAgreementTypeNull = true;
    } else if (
      this.appOfficialReportModel.fileObjectList.length === 0 ||
      this.appOfficialReportModel.fileObjectList.filter(
        fileObject => fileObject.isUploaded
      ).length === 0 ||
      this.appOfficialReportModel.letterDate === null ||
      this.appOfficialReportModel.letterNumber === null
    ) {
      this.isAgreementTypeNull = false;
      this.isOfficialReportNull = true;
    } else {
      this.isAgreementTypeNull = false;
      this.isOfficialReportNull = false;
      this.validate();
      if (this.formGroup.valid && this.appOfficialReportModel.isGenerate) {
        if (!isSubmit) {
          this.doSubmitVendorDesignation(isSubmit);
        } else {
          let procurementVendorList: ProcurementVendor[] = [];
          if (
            this.procurement.vendorSelectionType.code ===
            this.global.appConstant.pm.VENDOR_SELECTION_TYPE_MULTI_WINNER
          ) {
            this.quotationItemReferenceFinalList.forEach(
              quotationItemReference => {
                if (
                  procurementVendorList.length === 0 ||
                  (procurementVendorList.length > 0 &&
                    !procurementVendorList
                      .map(procurementVendor => procurementVendor.id)
                      .includes(
                        quotationItemReference.quotationItem.quotation
                          .procurementVendor.id
                      ))
                ) {
                  procurementVendorList.push(
                    quotationItemReference.quotationItem.quotation
                      .procurementVendor
                  );
                }
              }
            );

            procurementVendorList.forEach(procurementVendor => {
              procurementVendor['itemList'] =
                this.quotationItemReferenceFinalList
                  .filter(
                    quotationItemReference =>
                      quotationItemReference.quotationItem.quotation
                        .procurementVendor.id === procurementVendor.id
                  )
                  .map(
                    quotationItemReference =>
                      quotationItemReference.quotationItem.procurementItem
                        .prItem.item.name
                  );
            });
          } else {
            procurementVendorList = this.tableResponseFinalEval
              .getRecordList()
              .map(
                vendorQuotationView => vendorQuotationView.procurementVendor
              );
            procurementVendorList[0]['itemList'] =
              this.vendorDesignationResponse.procurementItemList.map(
                procurementItem => procurementItem.prItem.item.name
              );
          }
          this.appPopupService
            .open(
              VendorDesignationPopupWinnerComponent,
              {
                procurementVendorList,
                vendorSelectionTypeCode:
                  this.procurement.vendorSelectionType.code
              },
              {
                size: 'SM'
              }
            )
            .subscribe(result => {
              if (result) {
                this.doSubmitVendorDesignation(isSubmit);
              }
            });
        }
      }
    }
  }

  public doSubmitVendorDesignation(isSubmit: boolean): void {
    this.setStateProcessing();
    const vendorDesignationRequest: VendorDesignationRequest =
      new VendorDesignationRequest();
    vendorDesignationRequest.appOfficialReportModel =
      this.appOfficialReportModel;
    vendorDesignationRequest.isSubmit = isSubmit;
    vendorDesignationRequest.procurement = this.procurement;
    this.httpClientService
      .post<Response<Procurement>>(
        '/vendor-designation/update',
        vendorDesignationRequest
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          if (!isSubmit) {
            this.snackbarService.showWarning('app.msg.info.sucsessSave');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set('procurement', response.body);
                this.global.routerParams.set('procurementId', response.body.id);
                this.global.routerParams.set(
                  'urlBackOutside',
                  '/pages/worklist-pm'
                );

                this.global.routerParams.set('todo', 'edit');
                this.router.navigate(['/pages/vendor-designation/edit']);
              });
            this.setStateReady();
          } else {
            this.global.modalService
              .submitSuccess()
              .pipe(take(1))
              .subscribe(result => {
                if (result) {
                  this.router.navigate(['/pages/worklist-pm/']);
                } else {
                  this.router
                    .navigateByUrl('/', { skipLocationChange: true })
                    .then(() => {
                      this.global.routerParams.clear();
                      this.global.routerParams.set(
                        'procurement',
                        response.body
                      );
                      this.global.routerParams.set(
                        'procurementId',
                        response.body.id
                      );
                      this.global.routerParams.set(
                        'urlBackOutside',
                        '/pages/worklist-pm'
                      );

                      this.global.routerParams.set('todo', 'view');
                      this.router.navigate([
                        '/pages/vendor-designation/detail'
                      ]);
                    });
                }
                this.setStateReady();
              });
          }
        } else {
          this.global.alertService.showError(response.statusText);
        }
        this.setStateReady();
      });
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public onChangeAgreementType(event): void {
    this.procurement.agreementType = event;
    this.appTips.reload();
  }

  public checkAgreementType(): void {
    if (
      this.todo === 'add' ||
      this.procurement?.procurementWorklistStatus?.code === 'REVISION'
    ) {
      this.isShowInputControl = true;
    } else {
      this.isShowInputControl = false;
    }
  }
}
