import { Currency } from 'src/app/core/bean/currency';
import { BaseDTO } from '../../../../core/base/base-dto';
import { Catalog } from '../../../../core/bean/catalog';
import { CatalogAttributeGroup } from '../../../../core/bean/catalog-attribute-group';
import { CatalogCategory } from '../../../../core/bean/catalog-category';
import { CatalogImage } from '../../../../core/bean/catalog-image';
import { ItemCategory } from '../../../../core/bean/item-category';
import { Region } from '../../../../core/bean/region';
import { TablePageModel } from '../../../../core/components/app-table/model/table-page-model';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { PrItemBudget } from 'src/app/core/bean/pr-item-budget';
import { BudgetType } from 'src/app/core/bean/budget-type';
import { CostCenter } from 'src/app/core/bean/cost-center';
import { WorkProgram } from 'src/app/core/bean/work-program';
import { Coa } from 'src/app/core/bean/coa';

export class ViewCatalogDTO extends BaseDTO {

    vendorName: string;
    catalog: Catalog = new Catalog();
    categoryList: ItemCategory[] = [];
    catalogAttributeGroupList: CatalogAttributeGroup[] = [];
    catalogAttributeGroup: CatalogAttributeGroup = new CatalogAttributeGroup();
    regionList: Region[] = [];
    vendorId: number;

    page: TablePageModel<any>;
    catalogCategoryList: CatalogCategory[] = [];
    catalogImageList: CatalogImage[] = [];

    //PENYESUAIAN BY PASS PR DI VIEW CATALOG
    companyCurrency: Currency;
    prItemList: PrItem[] = [];
    currencyList: Currency[] = [];
    vendorViewList: PopupVendorView[] = [];
    isModuleGroupESMActive: boolean;
    prItemBudgetList: PrItemBudget[] = [];

    //popup budget
    budgetTypeList: BudgetType[] = [];
    costCenterList: CostCenter[] = [];
    workProgramList: WorkProgram[] = [];
    coaList: Coa[] = [];
}
