import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorQualificationEditAddComponent } from './vendor-qualification-edit-add.component';
import { VendorQualificationComponent } from './vendor-qualification.component';
const routes: Routes = [
  {
    path: '',
    component: VendorQualificationComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'add',
    component: VendorQualificationEditAddComponent,
    data: { breadcrumb: 'vendor-qualification.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: VendorQualificationEditAddComponent,
    data: { breadcrumb: 'vendor-qualification.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    VendorQualificationComponent,
    VendorQualificationEditAddComponent
  ]
})
export class VendorQualificationModule {}
