import { BaseEntity } from '../base/base-entity';
import { Aanwijzing } from '../bean/aanwijzing';
import { AanwijzingStatus } from '../bean/aanwijzing-status';
import { Procurement } from '../bean/procurement';
import { TransactionType } from '../bean/transaction-type';

export class AanwijzingEntity extends BaseEntity {
    crudOperation: number;

    procurement: Procurement;
    fromAanwijzing: Aanwijzing;
    transactionType: TransactionType;
    aanwijzingStatus: AanwijzingStatus;
    subject: string;
    periodStartDate: Date;
    periodEndDate: Date;
    location: string;
    qrCodeUrl: string;
    roomCode: string;
    sessionStartTime: Date;
    sessionEndTime: Date;
    sessionTime: Date;
    isVendorMandatory: boolean;
}
