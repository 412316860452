import { BaseEntity } from '../base/base-entity';
import { Procurement } from '../bean/procurement';
import { Region } from '../bean/region';

export class ProcurementRegionEntity extends BaseEntity {
  crudOperation: number;

  procurement: Procurement = new Procurement();
  region: Region = new Region();
}
