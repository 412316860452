<div class="attendance-form">
  <div
    class="mb-4"
    [ngStyle]="{
      'max-width': '576px',
      margin: 'auto'
    }"
  >
    <app-attendance-form-card *ngIf="formLoading">
      <div class="promise-loading">
        <div class="loading"></div>
      </div>
    </app-attendance-form-card>
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <app-attendance-form-card
        header="attendance-form.form.title"
        color="PRIMARY"
      >
        <span class="py-1">
          {{ attendanceFormResponse.aanwijzing.code }}
        </span>
        <span class="py-1">
          {{
            attendanceFormResponse.aanwijzing.periodStartDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}
          -
          {{
            attendanceFormResponse.aanwijzing.periodEndDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}
        </span>
        <span class="py-1">
          {{ attendanceFormResponse.aanwijzing.location }}
        </span>
        <span class="py-1 text-primary font-weight-bold">
          {{ attendanceFormResponse.aanwijzing.subject }}
        </span>
      </app-attendance-form-card>
      <!-- </div> -->
      <app-attendance-form-card *ngIf="!isFormPage">
        <label for="participant">
          {{ 'attendance-form.form.pleaseChooseRole' | translate }}
        </label>
        <app-radio
          size="MD"
          formControlName="participant"
          [optionList]="participantRoleOptionList"
          position="VERTICAL"
        ></app-radio>
      </app-attendance-form-card>
      <div class="maxwidth">
        <app-button
          *ngIf="!isFormPage"
          (onClick)="doNext()"
          [disabled]="!formGroup.value.participant"
          >{{ 'app.button.next' | translate }}</app-button
        >
      </div>

      <ng-container *ngIf="isFormPage">
        <!-- </div> -->
        <app-attendance-form-card>
          <label for="name">
            {{ 'attendance-form.form.fullName' | translate }}
          </label>
          <app-text-field
            size="XL"
            formControlName="name"
            placeholder="{{
              'attendance-form.form.placeholder.name' | translate
            }}"
          ></app-text-field>
          <small for="name" class="text-primary pt-2">
            {{ 'attendance-form.form.fullNameInfo' | translate }}
          </small>
        </app-attendance-form-card>

        <app-attendance-form-card>
          {{ 'attendance-form.form.email' | translate }}
          <label for="email"> </label>
          <app-text-field
            size="XL"
            formControlName="email"
            placeholder="{{
              'attendance-form.form.placeholder.email' | translate
            }}"
          ></app-text-field>
        </app-attendance-form-card>

        <app-attendance-form-card
          *ngIf="
            formGroup.value.participant &&
            formGroup.value.participant !== 'VENDOR'
          "
        >
          <label for="companyName">
            {{ 'attendance-form.form.companyName' | translate }}
          </label>
          <app-text-field
            size="XL"
            formControlName="companyName"
            placeholder="{{
              'attendance-form.form.placeholder.companyName' | translate
            }}"
          ></app-text-field>
        </app-attendance-form-card>

        <app-attendance-form-card
          *ngIf="
            formGroup.value.participant &&
            formGroup.value.participant === 'VENDOR'
          "
        >
          <label for="companyName">
            {{ 'attendance-form.form.companyName' | translate }}
          </label>
          <app-dropdown-select
            size="XL"
            formControlName="companyName"
            type="COMBOBOX"
            [optionList]="vendorOptionList"
            container="body"
            placeholder="attendance-form.form.placeholder.companyName"
          ></app-dropdown-select>
        </app-attendance-form-card>

        <app-attendance-form-card
          *ngIf="
            formGroup.value.participant &&
            formGroup.value.participant !== 'INTERNAL'
          "
        >
          <label for="positionName">
            {{ 'attendance-form.form.position' | translate }}
          </label>
          <app-text-field
            size="XL"
            formControlName="positionName"
            placeholder="{{
              'attendance-form.form.placeholder.position' | translate
            }}"
          ></app-text-field>
        </app-attendance-form-card>

        <app-attendance-form-card
          *ngIf="
            formGroup.value.participant &&
            formGroup.value.participant === 'INTERNAL'
          "
        >
          <label for="departmentName">
            {{ 'attendance-form.form.department' | translate }}
          </label>
          <app-text-field
            size="XL"
            formControlName="departmentName"
            placeholder="{{
              'attendance-form.form.placeholder.department' | translate
            }}"
          ></app-text-field>
        </app-attendance-form-card>
        <app-attendance-form-card>
          <label for="phone">
            {{ 'attendance-form.form.phone' | translate }}
          </label>
          <app-phone
            size="XL"
            formControlName="phone"
            [optionList]="regionOptionList"
            maxLength="32"
            placeholder="{{
              'attendance-form.form.placeholder.phone' | translate
            }}"
          ></app-phone>
        </app-attendance-form-card>

        <app-attendance-form-card>
          <label for="signatureImg">
            {{ 'attendance-form.form.signature' | translate }}
          </label>
          <app-signature
            size="TN"
            customWidth="296"
            formControlName="signatureImg"
            [model]="model"
            (onChange)="onChangeBlank($event)"
          ></app-signature>
          <div class="check-box-signature d-flex">
            <app-check-box formControlName="isCheck"></app-check-box>
            <label class="white-space-normal"
              >{{
                'attendance-form.form.information.msg.signature' | translate
              }}&nbsp;{{ stages }}</label
            >
          </div>
        </app-attendance-form-card>
        <div class="halfwidth">
          <app-button (onClick)="doBack()" color="SECONDARY" [outline]="true">{{
            'app.button.back' | translate
          }}</app-button>

          <app-button
            (onClick)="doSubmit()"
            [disabled]="!formGroup.value.isCheck || isBlank"
            >{{ 'app.button.submit' | translate }}</app-button
          >
        </div>
      </ng-container>
    </form>
  </div>
</div>
