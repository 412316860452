import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { UserDelegationEditAddComponent } from './user-delegation-edit-add.component';
import { UserDelegationComponent } from './user-delegation.component';
export const routes: Routes = [
  { path: '', component: UserDelegationComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: UserDelegationEditAddComponent,
    data: { breadcrumb: 'delegation.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [UserDelegationComponent, UserDelegationEditAddComponent]
})
export class UserDelegationModule {}
