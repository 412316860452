import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { ScheduleStages } from './../../core/bean/schedule-stages';
import { Response } from './../../core/model/response-model';
import { ResponseStatusModel } from './../../core/model/response-status-model';
@Component({
  templateUrl: 'schedule-stages-edit-add.component.html'
})
export class ScheduleStagesEditAddComponent extends BaseModuleComponent {
  public scheduleStagesId: number;
  public scheduleStages: ScheduleStages = new ScheduleStages();
  public moduleName: string;
  public moduleDescription: string;
  public checkOptionList = [
    {
      name: 'Schedule',
      isChecked: this.scheduleStages.isSchedule,
      disabled: false
    },
    {
      name: 'Location',
      isChecked: this.scheduleStages.isLocation,
      disabled: false
    },
    {
      name: 'Start Date',
      isChecked: this.scheduleStages.isStartDate,
      disabled: false
    },
    {
      name: 'End Date',
      isChecked: this.scheduleStages.isEndDate,
      disabled: false
    },
    {
      name: 'Start Time',
      isChecked: this.scheduleStages.isStartTime,
      disabled: false
    },
    {
      name: 'End Time',
      isChecked: this.scheduleStages.isEndTime,
      disabled: false
    }
  ];
  constructor(translateService: TranslateService) {
    super('schedule-stages', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.scheduleStagesId = this.global.routerParams.get('scheduleStagesId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      check: this.formBuilder.array([])
    });
  }

  public get check(): FormArray {
    return this.formGroup.get('check') as FormArray;
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ScheduleStages>(
        '/schedule-stages/edit',
        new RouteRequestModel(this.todo, this.scheduleStagesId)
      )
      .subscribe(scheduleStages => {
        this.checkOptionList[0].isChecked = scheduleStages.isSchedule;
        this.checkOptionList[1].isChecked = scheduleStages.isLocation;
        this.checkOptionList[2].isChecked = scheduleStages.isStartDate;
        this.checkOptionList[3].isChecked = scheduleStages.isEndDate;
        this.checkOptionList[4].isChecked = scheduleStages.isStartTime;
        this.checkOptionList[5].isChecked = scheduleStages.isEndTime;

        this.checkOptionList.forEach((checkOption: any) => {
          const formGroup: FormGroup = this.formBuilder.group({
            name: [checkOption.name],
            isChecked: [checkOption.isChecked]
          });
          this.check.push(formGroup);
        });

        this.scheduleStages.module = scheduleStages.module;

        if (scheduleStages != null) {
          this.formGroup.patchValue(scheduleStages);
          this.moduleName = scheduleStages.module.name;
          this.moduleDescription = scheduleStages.module.description;
        }
        this.setStateReady();
        this.setCheckEnabledOrDisabled(this.checkOptionList);
      });
  }

  public doChecklist(): void {
    for (let i = 0; i < this.checkOptionList.length; i++) {
      this.checkOptionList[i].isChecked = this.check.controls[
        i
      ].value.isChecked;
    }
    if (!this.formGroup.get('check').value[0].isChecked) {
      for (let index = 0; index < this.checkOptionList.length - 1; index++) {
        this.checkOptionList[index + 1].isChecked = false;
        this.checkOptionList[index + 1].disabled = true;
      }
    } else {
      for (let index = 0; index < this.checkOptionList.length - 1; index++) {
        this.checkOptionList[index + 1].disabled = false;
      }
    }
    this.formGroup.get('check').patchValue(this.checkOptionList);
    this.setStateReady();
    this.setCheckEnabledOrDisabled(this.checkOptionList);
  }

  public setCheckEnabledOrDisabled(checkOptionList: Array<any>): void {
    checkOptionList.forEach((checkOption: any, index: number) => {
      if (checkOption.disabled) {
        this.check.controls[index].disable();
      } else {
        this.check.controls[index].enable();
      }
    });
  }

  public doSave(): void {
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          const scheduleStages = this.global.copyFormAttributeToModel(
            new ScheduleStages(),
            this.formGroup
          );
          scheduleStages.isSchedule = this.formGroup.get(
            'check'
          ).value[0].isChecked;
          scheduleStages.isLocation = this.formGroup.get(
            'check'
          ).value[1].isChecked;
          scheduleStages.isStartDate = this.formGroup.get(
            'check'
          ).value[2].isChecked;
          scheduleStages.isEndDate = this.formGroup.get(
            'check'
          ).value[3].isChecked;
          scheduleStages.isStartTime = this.formGroup.get(
            'check'
          ).value[4].isChecked;
          scheduleStages.isEndTime = this.formGroup.get(
            'check'
          ).value[5].isChecked;
          scheduleStages.module = this.scheduleStages.module;
          const url = this.todo === 'edit' ? '/schedule-stages/update' : '';
          this.httpClientService
            .post<Response<ScheduleStages>>(url, scheduleStages)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessSavingOnNextRoute();
                this.router.navigate(['/pages/schedule-stages']);
              } else {
                this.global.alertService.showError(response.statusText);
                this.setStateReady();
              }
            });
        }
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/schedule-stages']);
  }
}
