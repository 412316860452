import { FormGroupService } from '../../services/form-group.service';
import { ServiceLocator } from '../../services/service-locator';
import { TabModel } from './tab-model';
export class TabResponseModel {
  tabs: Map<string, TabModel> = new Map();
  isView: boolean;
  currentTab: TabModel;
  tabList: Array<TabModel> = [];
  source: Array<any> = [];
  public static create(
    tabs: Array<any>,
    baseUrl: string,
    namePath?: string,
    isView?: boolean
  ): TabResponseModel {
    const tabResponseModel: TabResponseModel = new TabResponseModel(
      baseUrl,
      namePath
    );
    tabResponseModel.setIsView(isView);
    tabResponseModel.source = tabs;
    tabs.forEach((tab: any, index: number) => {
      tabResponseModel.addTab(tab, index + 1);
    });
    tabResponseModel.currentTab = tabResponseModel.tabs.get(
      tabResponseModel.source[0].code
    );
    tabResponseModel.source.forEach((tab: any, index: number) => {
      tabResponseModel.tabs
        .get(tab.code)
        .setPreviousTabRouterUrl(
          tabResponseModel.tabs,
          tabResponseModel.source,
          index
        );
      tabResponseModel.tabs
        .get(tab.code)
        .setNextTabRouterUrl(
          tabResponseModel.tabs,
          tabResponseModel.source,
          index
        );
      tabResponseModel.tabList.push(tabResponseModel.tabs.get(tab.code));
    });
    return tabResponseModel;
  }

  constructor(public baseUrl: string, public namePath = 'name') {}

  public setCurrentTab(currentTab: TabModel): void {
    this.currentTab = currentTab;
  }

  public addTab(tab: any, position: number): void {
    const tabModel: TabModel = TabModel.create(this, tab, position);
    this.tabs.set(tab.code, tabModel);
  }

  public setIsView(isView = false): void {
    this.isView = isView;
  }

  public get valid(): boolean {
    return this.currentTab.formGroup.valid;
  }

  public getTabByCode(tabCode: string): any {
    return this.tabList.find(x => x.code === tabCode);
  }

  public get value(): Array<any> {
    const values: Array<any> = new Array();
    this.tabList.forEach((tab: TabModel) => {
      values.push(tab.formGroup.value);
    });
    return values;
  }

  public getValueByCode(tabCode: string): any {
    return this.tabs.get(tabCode).value;
  }

  public getValueByPosition(tabPosition: number): any {
    return this.value[tabPosition - 1];
  }

  public setIsSubmitted(): void {
    const formGroupService = ServiceLocator.injector.get(FormGroupService);
    formGroupService.validateAllFormFields(this.currentTab.formGroup);
  }

  public disableTab(): void {
    this.tabList.forEach(tab => {
      tab.disable();
    });
  }
}
