import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Menu } from '../../core/bean/menu';
import { ModuleGroup } from '../../core/bean/module-group';
import { MVCGenerator } from '../../core/bean/mvc-generator';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { MVCGeneratorDTO } from './dto/mvc-generator.dto';
@Component({
  templateUrl: './mvc-generator.component.html'
})
export class MVCGeneratorComponent extends BaseModuleComponent {
  mvcGenerator: MVCGenerator = new MVCGenerator();
  parentMenuList: OptionListModel<Menu> = new OptionListModel();
  moduleGroupList: OptionListModel<ModuleGroup> = new OptionListModel();
  url = '/mvc-generator/insert';

  constructor(translateService: TranslateService) {
    super('mvc-generator', translateService);
  }

  onInit(): void {
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      tableName: [null, Validators.required()],
      fileName: [null, Validators.required()],
      parentMenu: [null, Validators.required()],
      moduleGroup: [null, Validators.required()]
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .get<MVCGeneratorDTO>('/mvc-generator/index')
      .subscribe(mvcGeneratorDTO => {
        if (mvcGeneratorDTO != null) {
          this.parentMenuList.setRequestValues(mvcGeneratorDTO.menuList);
          this.moduleGroupList.setRequestValues(
            mvcGeneratorDTO.moduleGroupList
          );
          this.setStateReady();
        }
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.mvcGenerator = this.global.copyFormAttributeToModel(
              this.mvcGenerator,
              this.formGroup
            );

            const parentMenuList = [this.formGroup.get('parentMenu').value];
            parentMenuList.forEach(parentMenu => {
              this.mvcGenerator.parentMenu = parentMenu;
            });

            const moduleGroupList = [this.formGroup.get('moduleGroup').value];
            moduleGroupList.forEach(moduleGroup => {
              this.mvcGenerator.moduleGroup = moduleGroup;
            });

            this.httpClientService
              .post<Response<MVCGenerator>>(this.url, this.mvcGenerator)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  // this.setStateReady();
                  // this.global.alertService.showSuccessSaving();
                  this.router.navigate(['/pages/mvc-generator']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/mvc-generator']);
  }
}
