import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ApprovalWorkflowDetailComponent } from './approval-workflow-detail.component';
import { ApprovalWorkflowEditAddComponent } from './approval-workflow-edit-add.component';
import { ApprovalWorkflowComponent } from './approval-workflow.component';
const routes: Routes = [
  { path: '', component: ApprovalWorkflowComponent, data: { breadcrumb: '' } },
  {
    path: 'detail',
    component: ApprovalWorkflowDetailComponent,
    data: { breadcrumb: 'approval-workflow.breadcrumb.detail' }
  },
  {
    path: 'add',
    component: ApprovalWorkflowEditAddComponent,
    data: { breadcrumb: 'approval-workflow.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    ApprovalWorkflowComponent,
    ApprovalWorkflowDetailComponent,
    ApprovalWorkflowEditAddComponent
  ]
})
export class ApprovalWorkflowModule {}
