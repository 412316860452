import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseWidgetComponent } from '../../base/base-widget.component';

@Component({
  templateUrl: './widget-total-procurement-win.component.html'
})
export class WidgetTotalProcurementWinComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-total-procurement-win';
  public totalProcurementWin: number;

  constructor(public translateService: TranslateService) {
    super(WidgetTotalProcurementWinComponent.moduleCode);
  }

  public onInit(): void {
    this.getTotalProcurementWinInformation();
  }

  public getTotalProcurementWinInformation(): void {
    this.httpClientService
      .get<number>('/widget-total-procurement-win/index')
      .subscribe(response => {
        if (response) {
          this.totalProcurementWin = response;
        }
        this.setStateReady();
      });
  }

  public doNavigateToProcHistory(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('historyType', 'result');
    this.global.routerParams.set('isWin', 1);
    this.router.navigate(['/pages/procurement-history-vendor']);
  }
}
