import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { UserView } from '../../core/view/entity/bean/user-view';
@Component({
  templateUrl: './popup-choose-address-pic.component.html'
})
export class PopupChooseAddressPicComponent extends BaseComponentComponent {
  @Input() selectedUserList: UserView[];
  @Input() filteredUserList: UserView[];
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  public tableResponse: TableResponseModel<UserView>;

  constructor() {
    super('address-book');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      { field: 'email', header: 'table.column.email' },
      {
        field: 'roleText',
        header: 'table.column.role'
      },
    ]);

    this.tableResponse.setRecordList(this.filteredUserList);

    const selectedRowList = this.filteredUserList.filter(user =>
      this.selectedUserList.some(selectedUser => selectedUser.id === user.id)
    );

    this.tableResponse.setSelectedRowList(selectedRowList);
    this.tableResponse.reload();
  }

  public doChoose(): void {
    if (this.tableResponse.selectedRow.selectedRowList.length > 0) {
      this.onChange.emit(
        this.tableResponse.selectedRow.getSelectedRecordList()
      );
    }
  }
}
