<app-layout [isLoading]="formLoading" [backTo]="urlBackOutside">
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge-catalog
      class="cml-2"
      *ngIf="procurement.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge
      *ngIf="
        scheduleStatus?.code ===
        global.appConstant.pm.SCHEDULE_STATUS_WAITING_APPROVAL
      "
      class="cml-2"
      color="WARNING"
    >
      {{
        'dynamic-master-attribute.' + scheduleStatus.translationKey.key
          | translate
      }}
    </app-badge>
    <app-badge
      *ngIf="
        scheduleStatus?.code === global.appConstant.pm.SCHEDULE_STATUS_REVISION
      "
      class="cml-2"
      color="FEEDBACK"
    >
      {{
        'dynamic-master-attribute.' + scheduleStatus.translationKey.key
          | translate
      }}
    </app-badge>
    <app-badge
      *ngIf="
        scheduleStatus?.code === global.appConstant.pm.SCHEDULE_STATUS_DONE
      "
      class="cml-2"
      color="SUCCESS"
    >
      {{
        'dynamic-master-attribute.' + scheduleStatus.translationKey.key
          | translate
      }}
    </app-badge>
    <app-badge
      *ngIf="
        !scheduleStatus ||
        scheduleStatus?.code === global.appConstant.pm.SCHEDULE_STATUS_NEW
      "
      class="cml-2"
      color="INFO"
    >
      {{ 'dynamic-master-attribute.scheduleStatus.new' | translate }}
    </app-badge>
    <app-badge
      *ngIf="
        scheduleStatus?.code === global.appConstant.pm.SCHEDULE_STATUS_DRAFT
      "
      class="cml-2"
      color="SECONDARY"
    >
      {{
        'dynamic-master-attribute.' + scheduleStatus.translationKey.key
          | translate
      }}
    </app-badge>
  </ng-template>
  <div class="row main-row">
    <div class="col-lg-12">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <!-- <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="procurementScheduleResponse.workflowStepInfoModel"
        >
        </app-workflow-step-info> -->
        <app-card
          header="procurement-schedule.detail.procurementDetails.title"
          tips="procurement-schedule.detail.procurementDetails.tips"
        >
          <app-procurement-info
            [isLoading]="formLoading"
            [procurementId]="procurementId"
          ></app-procurement-info>
        </app-card>

        <app-item-request
          [objectList]="procurementScheduleResponse.procurementItemList"
          [isShowPrice]="true"
          [itemTypeList]="procurementScheduleResponse.itemTypeList"
          [isView]="true"
          *ngIf="!formLoading"
        ></app-item-request>

        <app-card
          header="procurement-schedule.detail.procurementSchedule.title"
          tips="procurement-schedule.detail.procurementSchedule.tips"
        >
          <app-alert-x *ngIf="!formGroup.isView" color="INFO">{{
            'procurement-schedule.detail.procurementSchedule.alert' | translate
          }}</app-alert-x>
          <app-calendar
            [model]="calendarResponseModel"
            stringUrl="/procurement-schedule/generate-schedule"
            [isShowGenerate]="
              !formGroup.isView &&
              (!calendarResponseModel.recordList[0].startDate ||
                startDate.getTime() > currentDate.getTime())
            "
            [isView]="formGroup.isView"
          ></app-calendar>
        </app-card>
        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            procurementScheduleResponse?.approvalPathResponseModel &&
            !procurementScheduleResponse.isUserApproval &&
            scheduleStatus?.code !==
              global.appConstant.pm.SCHEDULE_STATUS_NEW &&
            scheduleStatus?.code !== global.appConstant.pm.SCHEDULE_STATUS_DRAFT
          "
          [workflowPrcs]="procurement.workflowPrcs"
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="
            procurementScheduleResponse?.approvalPathResponseModel
          "
        ></app-approval-monitoring>

        <app-card
          *ngIf="
            scheduleStatus?.code !==
              global.appConstant.pm.SCHEDULE_STATUS_NEW &&
            scheduleStatus?.code !== global.appConstant.pm.SCHEDULE_STATUS_DRAFT
          "
          header="procurement-schedule.detail.changeHistory.title"
          tips="procurement-schedule.detail.changeHistory.tips"
        >
          <app-table-xx
            *ngIf="!formLoading"
            [model]="tableResponseChangeHistory"
            stringUrl="/procurement-schedule/get-schedule-history"
          ></app-table-xx>
        </app-card>

        <div
          class="floating-button mt-5"
          *ngIf="
            !procurementScheduleResponse.isUserApproval &&
            scheduleStatus?.code !==
              global.appConstant.pm.SCHEDULE_STATUS_WAITING_APPROVAL
          "
        >
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center">
                <app-button
                  *ngIf="!formGroup.isView"
                  color="PRIMARY"
                  [outline]="true"
                  (onClick)="doSave()"
                  [disabled]="
                    formSaving ||
                    !calendarResponseModel.recordList[0].startDateTemp
                  "
                  >{{ 'app.button.save' | translate }}
                </app-button>

                <app-button
                  (onClick)="doSubmit()"
                  [disabled]="
                    formSaving ||
                    !calendarResponseModel.recordList[0].startDateTemp
                  "
                  *ngIf="!formGroup.isView"
                  >{{ 'app.button.submit' | translate }}</app-button
                >
              </div>
            </div>
          </div>
        </div>
        <app-approval-prcs-x
          *ngIf="!formLoading && procurementScheduleResponse.isUserApproval"
          [workflowModelPrcs]="procurementScheduleResponse.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips
      *ngIf="!formLoading"
      activeTipHeader="procurement-schedule.detail.procurementSchedule.title"
    ></app-tips>
  </ng-template>
</app-layout>
