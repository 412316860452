import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorPerformanceOverall } from '../../core/bean/vendor-performance-overall';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { AppPopupTabPerformanceHistoryDetailComponent } from './app-popup-tab-performance-history-detail.component';
@Component({
  templateUrl: './tab-performance-history.component.html'
})
export class TabPerformanceHistoryComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<VendorPerformanceOverall>;
  public tableResponse: TableResponseModel<VendorPerformanceOverall>;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('tab-performance-history', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setCustomData();
    this.model.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'period.name', header: 'table.column.period', customClass: 'text-center' },
      { field: 'startYear', header: 'table.column.year', customClass: 'text-center' },
      {
        field: 'createdDate',
        header: 'table.column.date',
        format: FieldFormatEnum.ShortDateAndTime
      },
      {
        field: 'rating',
        header: 'table.column.rating',
        format: FieldFormatEnum.Rating,
        customClass: 'text-center'
      }
    ]);
  }

  public setCustomData(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponse.setCustomData(customData);
  }

  public doView(vendorPerformanceOverall: VendorPerformanceOverall): void {
    const todo = 'view';
    const vendorPerformanceOverallId = vendorPerformanceOverall.id;
    this.appPopupService.open(
      AppPopupTabPerformanceHistoryDetailComponent,
      { todo, vendorPerformanceOverallId },
      { size: 'xl' }
    );
  }
}
