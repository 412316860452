import { CurrencyPipe } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartTooltipItem } from 'chart.js';
import { BudgetType } from '../../../../../core/bean/budget-type';
import { ChartModel } from '../../../../../core/components/app-chart/models/chart-model';
import { OptionListModel } from '../../../../../core/model/option-list-model';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetBudgetSpendingTypeRequest } from './model/widget-budget-spending-type-request';
import { WidgetBudgetSpendingTypeResponse } from './model/widget-budget-spending-type-response';

@Component({
  selector: 'dashboard-widget-budget-spending-type',
  templateUrl: './widget-budget-spending-type.component.html',
  styleUrls: ['./widget-budget-spending-type.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetBudgetSpendingTypeComponent extends BaseWidgetComponent {
  public yearOptionList: OptionListModel<number> = new OptionListModel(true);
  public budgetTypeOptionList: OptionListModel<BudgetType> =
    new OptionListModel(true);
  public moneyFormatTranslateKeyList: Array<string>;
  public chartModel: ChartModel;
  public widgetBudgetSpendingTypeRequest: WidgetBudgetSpendingTypeRequest =
    new WidgetBudgetSpendingTypeRequest();
  public widgetBudgetSpendingTypeResponse: WidgetBudgetSpendingTypeResponse =
    new WidgetBudgetSpendingTypeResponse();
  public totalCapex: string;
  public totalOpex: string;
  public modelList: any[];
  public modelDefaultList: any[];
  public emptyValue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  constructor(
    public translateService: TranslateService,
    public currencyPipe: CurrencyPipe
  ) {
    super('dashboard-widget-budget-spending-type');
  }

  public onInit(): void {
    this.setStateMoneyFormatTranslateKeyList();
    this.buildFormGroup();
    this.setFormGroup();
  }

  private setStateMoneyFormatTranslateKeyList(): void {
    this.moneyFormatTranslateKeyList = [
      this.global.translateService.instant('app.moneyFormat.thousand'),
      this.global.translateService.instant('app.moneyFormat.million'),
      this.global.translateService.instant('app.moneyFormat.billion'),
      this.global.translateService.instant('app.moneyFormat.trillion'),
      this.global.translateService.instant('app.moneyFormat.quadrillion'),
      this.global.translateService.instant('app.moneyFormat.quantillion'),
      this.global.translateService.instant('app.moneyFormat.sextillion')
    ];
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      year: [new Date().getFullYear()],
      budgetType: [null]
    });
    const currentYear = new Date().getFullYear();
    this.yearOptionList.setRequestValues(
      Array(3)
        .fill(0)
        .map((fill: number, index: number) => currentYear - 2 + fill + index)
    );
    this.widgetBudgetSpendingTypeRequest.year = this.formGroup.value.year;
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<WidgetBudgetSpendingTypeResponse>(
        '/widget-budget-spending-type/index',
        this.widgetBudgetSpendingTypeRequest
      )
      .subscribe((response: WidgetBudgetSpendingTypeResponse) => {
        if (response) {
          this.widgetBudgetSpendingTypeResponse = response;
          if (response.budgetTypeList) {
            this.widgetBudgetSpendingTypeRequest.budgetTypeList =
              response.budgetTypeList;
            this.budgetTypeOptionList.setRequestValues(response.budgetTypeList);
            this.formGroup
              .get('budgetType')
              .patchValue(this.widgetBudgetSpendingTypeRequest.budgetTypeList);
          }
          this.setChartModel();
        }
        this.setStateReady();
      });
  }

  private reload(): void {
    this.totalCapex = this.global.converterService.convertToShortMoney(
      this.widgetBudgetSpendingTypeResponse.totalCapex || 0,
      this.moneyFormatTranslateKeyList
    ) as string;
    this.totalOpex = this.global.converterService.convertToShortMoney(
      this.widgetBudgetSpendingTypeResponse.totalOpex || 0,
      this.moneyFormatTranslateKeyList
    ) as string;
  }

  public setModel(): void {
    this.modelDefaultList = [
      {
        code: this.translateService.instant('dashboard-widget-budget-spending-type.label.opex'),
        total: this.totalOpex,
        color: '#14B1AB'
      },
      {
        code: this.translateService.instant('dashboard-widget-budget-spending-type.label.capex'),
        total: this.totalCapex,
        color: '#0772B6'
      }
    ];
  }

  public setChartModel(): void {
    this.reload();
    this.setModel();
    let labels = [];
    const dataSetLabels = [];
    const dataSets = [];
    const budgetTypeCodeRequestList =
      this.widgetBudgetSpendingTypeRequest.budgetTypeList.map(budgetType =>
        budgetType.code.toLowerCase()
      );
    this.modelList = (!budgetTypeCodeRequestList || budgetTypeCodeRequestList.length === 0) ? this.modelDefaultList : this.modelDefaultList.filter(model =>
      budgetTypeCodeRequestList.includes(model.code.toLowerCase())
    );
    const widgetBudgetSpendingTypeModelResponseList = (!budgetTypeCodeRequestList || budgetTypeCodeRequestList.length === 0) ? this.widgetBudgetSpendingTypeResponse.widgetBudgetSpendingTypeModelResponseList :
      this.widgetBudgetSpendingTypeResponse.widgetBudgetSpendingTypeModelResponseList.filter(
        response =>
          budgetTypeCodeRequestList.includes(response.label.toLowerCase())
      );
    widgetBudgetSpendingTypeModelResponseList.forEach(
      widgetBudgetSpendingTypeModelResponse => {
        if (labels.length === 0) {
          labels = [
            ...widgetBudgetSpendingTypeModelResponse.widgetBudgetSpendingTypeModelList
          ].map(data => data.month);
        }
        dataSets.push({
          data: !this.formGroup.value?.year ? [] : (!budgetTypeCodeRequestList || budgetTypeCodeRequestList.length === 0) ? this.emptyValue : [
            ...widgetBudgetSpendingTypeModelResponse.widgetBudgetSpendingTypeModelList
          ].map(data => data.total)
        });
        dataSetLabels.push(
          this.translateService.instant(
            'dashboard-widget-budget-spending-type.' +
            widgetBudgetSpendingTypeModelResponse.label
              .toLowerCase()
              .replace(/\s/g, '')
              .trim()
          )
        );
      }
    );

    const CURRENCY_PRECISSION_SCALE =
      this.global.appConstant.core.CURRENCY_PRECISSION_SCALE;
    const CURRENCY_DIGITS_INFO = `0.${CURRENCY_PRECISSION_SCALE}-${CURRENCY_PRECISSION_SCALE}`;
    this.chartModel = new ChartModel('bar', labels, dataSets, dataSetLabels, {
      legend: {
        display: false
      },
      defaultColor: this.modelList.map(model => model.color),
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 500000000,
              callback: (value): string => {
                return this.global.converterService.convertToShortMoneyChart(
                  value
                ) as string;
              }
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              display: false
            }
          }
        ]
      },
      tooltips: {
        mode: 'nearest',
        xPadding: 20,
        yPadding: 20,
        titleFontStyle: 'font-weight: normal; padding: 20px',
        bodyFontStyle: 'font-weight: bold',
        footerFontStyle: 'font-weight: normal',
        callbacks: {
          title: (item: ChartTooltipItem[], data: Chart.ChartData): string => {
            return this.global.translateService.instant(
              data.datasets[item[0].datasetIndex].label.toUpperCase()
            );
          },
          label: () => null,
          footer: (item: Chart.ChartTooltipItem[]): string => {
            const savingLossPercentage =
              widgetBudgetSpendingTypeModelResponseList[item[0].datasetIndex]
                .widgetBudgetSpendingTypeModelList[item[0].index]
                .savingLossPercentage;
            return savingLossPercentage
              ? savingLossPercentage > 0
                ? 'Increase ' +
                Math.abs(savingLossPercentage) +
                '% from last month'
                : 'Decrease ' +
                Math.abs(savingLossPercentage) +
                '% from last month'
              : null;
          },
          afterBody: (item: Chart.ChartTooltipItem[]): string => {
            const data =
              widgetBudgetSpendingTypeModelResponseList[item[0].datasetIndex]
                .widgetBudgetSpendingTypeModelList[item[0].index];
            return `${this.currencyPipe.transform(
              data.total,
              '',
              '',
              CURRENCY_DIGITS_INFO
            )} ${data.currency || 'IDR'}`;
          }
        }
      }
    });
  }

  public onChangeBudgetType(): void {
    if (this.formGroup.value?.budgetType) {
      this.widgetBudgetSpendingTypeRequest.budgetTypeList =
        this.formGroup.value.budgetType;
    } else {
      this.widgetBudgetSpendingTypeRequest.budgetTypeList = [];
    }
    this.setChartModel();
  }
  public onChangeYear(): void {
    if (this.formGroup.value?.year) {
      this.widgetBudgetSpendingTypeRequest.year = this.formGroup.value.year;
      this.setStateLoading();
      this.setFormGroup();
    } else {
      this.setChartModel();
    }
  }
}
