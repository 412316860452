import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Currency } from 'src/app/core/bean/currency';
import { OepDocHistory } from 'src/app/core/bean/oep-doc-history';
import { OepItemHistory } from 'src/app/core/bean/oep-item-history';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';

@Component({
  templateUrl: './app-popup-oep-detail-history.component.html'
})
export class AppPopupDetailHistoryComponent extends BaseModuleComponent {
  @Input() oepHistoryId: any;
  @Input() grandTotal: number;
  @Input() currency: Currency;

  public tableResponse: TableResponseModel<OepItemHistory>;
  public fileUploader: FileUploader = new FileUploader(
    '/oep/',
    'oep.form.headerImage',
    this.global.appConstant.fileType.DOC_OEP,
    false,
    5
  );

  constructor(translateService: TranslateService) {
    super('oep', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
    this.buildTableResponse();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'oepItem.procurementItem.prItem.pr.code',
        header: 'table.column.prNumber'
      },

      {
        field: 'oepItem.procurementItem.prItem.item.name',
        header: 'table.column.itemName'
      },
      {
        field: 'oepItem.procurementItem.prItem.item.code',
        header: 'table.column.itemCode'
      },
      {
        field: 'oepItem.procurementItem.prItem.quantity',
        header: 'table.column.qty',
        plugins: { name: 'text-field', type: 'decimal', isView: true },
        className: 'text-right'
      },
      {
        field: 'oepItem.procurementItem.prItem.item.uom.name',
        header: 'table.column.uom'
      },

      {
        field: 'amount',
        header: 'table.column.price',
        plugins: { name: 'default', type: 'currency' },
        className: 'text-right'
      },
      {
        field: 'totalAmount',
        header: 'table.column.totalPrice',
        plugins: { name: 'default', type: 'currency' },
        className: 'text-right'
      }
    ]);
    this.tableResponse.setCustomData(this.oepHistoryId);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      fileList: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<OepDocHistory[]>('/oep/get-oep-doc-history/' + this.oepHistoryId)
      .subscribe((oepDocHistoryList: OepDocHistory[]) => {
        const fileList = [];
        oepDocHistoryList.forEach((oepDocHistory: OepDocHistory) => {
          fileList.push(oepDocHistory.oepDoc.file);
        });
        this.fileUploader.setFileList(fileList);
        this.formGroup.patchValue({
          fileList: this.fileUploader.fileObjectList
        });
        this.setStateReady();
      });
  }

  public setIsViewOnly(): void {
    this.setViewOnly();
  }
}
