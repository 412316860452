<ng-container *ngIf="!ISVIEW; then isNotViewTmpl; else isViewTmpl"></ng-container>
<ng-template #isNotViewTmpl>
  <div class="app-radio d-inline-block" [ngClass]="[isInvalid ? 'is-invalid' : '', isValid ? 'is-valid' : '']"
    [formGroup]="formGroup">
    <ng-container *ngIf="optionsCopy.length > 0; else elseBlock">
      <div class="custom-control custom-radio radio-primary" *ngFor="let option of optionsCopy; index as i"
        [ngClass]="positionClassName">
        <input type="radio" id="{{ generatedId + i }}" [value]="
            (optionValue ? option[optionValue] : option.id) || option.value
          " class="custom-control-input cursor-pointer" [checked]="
            (optionValue && value === option[optionValue]) ||
            value === option.id ||
            value === option.value
          " [ngClass]="disabled ? 'radio-secondary' : 'radio-primary'" (change)="onChange.emit($event)"
          [formControlName]="formControlName" />
        <label class="custom-control-label cursor-pointer" for="{{ generatedId + i }}">{{ option | resolveFieldData:
          optionView | translate }}
        </label>
      </div>
      <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet"></ng-container>
    </ng-container>
    <ng-template #elseBlock>
      <div class="custom-control custom-radio">
        <input type="radio" id="{{ generatedId }}" class="custom-control-input cursor-pointer" [checked]="value"
          [ngClass]="disabled ? 'radio-secondary' : 'radio-primary'" [formControlName]="formControlName"
          (change)="onChange.emit($event)" />
        <label class="custom-control-label cursor-pointer" for="{{ generatedId }}"></label>
      </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet"></ng-container>
  </div>
  <small class="row px-4 text-danger" *ngIf="isInvalid">
    {{ errors.message }}
  </small>
</ng-template>
<ng-template #isViewTmpl>
  <div class="position-relative d-inline-block">
    <span>{{
      (viewValue && (optionView ? viewValue[optionView] : viewValue.name)) ||
      viewValue ||
      '-'
      }}</span>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet"></ng-container>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper [tooltip]="tooltip" [position]="tooltipPosition" [isView]="ISVIEW">
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>