import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupImportComponent } from './app-popup-import.component';
import { ImportFileWebsocketService } from './import-file-websocket.service';

@Injectable({ providedIn: 'root' })
export class AppPopupImportService {
  constructor(
    public appPopupService: AppPopupService,
    public importFileWebsocketService: ImportFileWebsocketService
  ) {}
  onCloseModal: EventEmitter<boolean> = new EventEmitter();
  public open(urlSave: string): Observable<boolean> {
    return this.appPopupService.open(
      AppPopupImportComponent,
      {
        urlSave,
        title: 'Import File',
        onCloseModal: this.importFileWebsocketService.onCloseModal
      },
      {
        backdrop: 'static',
        size: 'lg'
      }
    );
  }
}
