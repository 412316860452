import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Vendor } from '../../core/bean/vendor';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';

@Component({
  templateUrl: './tab-login.component.html'
})
export class TabLoginComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<Vendor>;
  public vendor: Vendor = new Vendor();
  constructor(translateService: TranslateService) {
    super('tab-login', translateService);
  }

  onInit(): void {
    this.doGetAndSetVendor();
  }

  public doGetAndSetVendor(): void {
    this.httpClientService.get<Vendor>('/tab-login/index/' + this.vendorHistoryId)
      .subscribe(vendor => {
        this.vendor = vendor;
        this.model.setStateReady();
      });
  }
}
