import { BaseEntity } from '../base/base-entity';
import { Contract } from '../bean/contract';
import { ContractTemplate } from '../bean/contract-template';
import { ContractDraftParameterValue } from '../bean/contract-draft-parameter-value';

export class ContractDraftEntity extends BaseEntity {
  contract: Contract;
  contractTemplate: ContractTemplate;
  contractDraftParameterValue: ContractDraftParameterValue;
}
