import { AppPopupSyncEmployeeDataComponent } from './app-popup-sync-employee-data.component';
import { WebSocketEmployeeDataService } from './websocket-employee-data.service';
import { EmployeeData } from './../../core/bean/employee-data';
import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
@Component({
    templateUrl: 'employee-data.component.html',
    providers: [
        { provide: WebSocketEmployeeDataService },
        { provide: AppPopupService }
    ]
})
export class EmployeeDataComponent extends BaseModuleComponent {
    public tableResponse: TableResponseModel<EmployeeData>;
    public isLoadingSync: boolean;
    public isSyncRunning = false;
    public isAllowAccess = false;

    @ViewChild(AppTableComponent) table: AppTableComponent;
    constructor(
        translateService: TranslateService,
        public appPopupService: AppPopupService,
        public websocketEmployeeDataService: WebSocketEmployeeDataService
    ) {
        super('employee-data', translateService);
    }

    public onInit(): void {
        this.initializeWebSocketEmployeeDataConnection();
        this.isSynchronizeRunning();
    }

    public initializeWebSocketEmployeeDataConnection(): void {
        this.websocketEmployeeDataService.connect().then(result => {
            if (this.isSyncRunning) {
                this.isSyncRunning = false;
                this.doBuildTableResponse();
            } else if (!this.isSyncRunning && result) {
                this.tableResponse.reload();
            }
            this.websocketEmployeeDataService.disconnect();
            this.initializeWebSocketEmployeeDataConnection();
            this.isLoadingSync = false;
        });
    }

    public doBuildTableResponse(): void {
        this.tableResponse = new TableResponseModel(this.moduleCode, [
            {
                field: 'userId',
                header: 'table.column.userId',
                customClass: 'text-center'
            },
            {
                field: 'nip',
                header: 'table.column.nip',
                customClass: 'text-center'
            },
            {
                field: 'name',
                header: 'table.column.name'
            },
            {
                field: 'workUnit',
                header: 'table.column.workUnit',
                customClass: 'text-center'
            },
            {
                field: 'position',
                header: 'table.column.position',
                customClass: 'text-center'
            }
        ]);
    }

    public doSync(): void {
        this.appPopupService
            .open(
                AppPopupSyncEmployeeDataComponent,
                { onCloseModal: this.websocketEmployeeDataService.onCloseModal },
                {
                    backdrop: 'static',
                    size: 'lg'
                    // container: '#work-program',
                    // backdropClass: 'work-program-backdrop',
                    // windowClass: 'work-program-window'
                }
            )
            .subscribe();
        this.websocketEmployeeDataService.appPopupSyncEmployeeDataComponent =
            this.appPopupService.componentInstance;

    }

    public isSynchronizeRunning(): void {
        this.httpClientService
            .get<Boolean>('/employee-data/is-employee-data-synchronize-running')
            .subscribe((isSynchronizeRunning: boolean) => {
                this.isSyncRunning = isSynchronizeRunning;
                this.setStateReady();
                if (!this.isSyncRunning) {
                    this.doBuildTableResponse();
                }
            });
    }
}
