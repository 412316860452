<app-popup
  header="dashboard-widget-workload-monitoring.popup.detail"
  [isLoading]="formLoading"
>
  <div
    class="form-group row"
    *ngIf="!formLoading"
    style="
      height: 60px;
      margin-left: 5px;
      margin-right: 5px;
      border: 1px solid var(--gray-400);
      box-sizing: border-box;
      border-radius: 10px;
    "
  >
    <div class="col-sm-1 pt-3 fw-500 pl-auto pr-0 text-secondary">
      # {{ workloadMonitoringModel.rankNumber }}
    </div>
    <div class="pt-2">
      <img
        style="width: 46px; height: 46px"
        width="36"
        height="36"
        style="border-radius: 36px; margin: auto; height: 36px"
        [attr.src]="
          workloadMonitoringModel.picUser.imgFile
            ? (global.config.BACKEND_ADDRESS +
                '/widget-workload-monitoring/thumbnail/view/' +
                workloadMonitoringModel.picUser.imgFile.uploadedFileName +
                '/' +
                workloadMonitoringModel.picUser.imgFile.fileType.code
              | resolveImgSrc
              | async)
            : 'assets/img/app/no-image.png'
        "
        [attr.alt]="workloadMonitoringModel.picUser.imgFile.uploadedFileName"
      />
    </div>
    <div class="col-sm-3 pt-2">
      <strong>{{ workloadMonitoringModel.picUser.name }}</strong> <br />
      {{ workloadMonitoringModel.picUser.organization.name }}
    </div>
    <div
      style="
        border-right: 1px solid var(--gray-400);
        margin-top: 1%;
        margin-bottom: 1%;
      "
    ></div>

    <div class="col-sm-4 text-center pt-3">
      {{ workloadMonitoringModel.totalTask }}
      {{ 'dashboard-widget-workload-monitoring.detail.totalTask' | translate }}
    </div>
    <div
      style="
        border-right: 1px solid var(--gray-400);
        margin-top: 1%;
        margin-bottom: 1%;
      "
    ></div>
    <div class="col-sm-3 text-center pt-3">
      {{ workloadMonitoringModel.totalAverageSLA | resolveNumberToDecimal }} %
      {{ 'dashboard-widget-workload-monitoring.detail.averageSLA' | translate }}
    </div>
  </div>

  <app-table-xx
    header="dashboard-widget-workload-monitoring.detail.procurementMethod.title"
    [model]="tableResponseProcurementMethod"
    *ngIf="!formLoading"
    [isShowSearch]="false"
    [isShowPagination]="false"
  >
  </app-table-xx>

  <app-table-xx
    header="dashboard-widget-workload-monitoring.detail.status.title"
    [model]="tableResponseStatus"
    *ngIf="!formLoading"
    [isShowSearch]="false"
    [isShowPagination]="false"
  >
  </app-table-xx>
</app-popup>
