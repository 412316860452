import { Component, OnInit } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Notification } from '../../core/bean/notification';
import { Reminder } from '../../core/bean/reminder';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';

@Component({
  templateUrl: 'reminder-edit-add.component.html'
})
export class ReminderEditAddComponent
  extends BaseModuleComponent
  implements OnInit {
  public reminder: Reminder = new Reminder();
  public notification: Notification = new Notification();
  public submitted: boolean;
  constructor(translateService: TranslateService) {
    super('reminder', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.notification = this.global.routerParams.get('notification');
    this.todo = this.global.routerParams.get('todo');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [null],
      code: [null],
      description: [null],
      time: [null, Validators.compose([Validators.required])],
      isRepeat: [null, Validators.compose([Validators.required])],
      period: [null, Validators.compose([Validators.required])],
      isActive: [null, Validators.compose([Validators.required])]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<Notification>(
        '/reminder/add-edit',
        new RouteRequestModel(this.todo, this.notification.id)
      )
      .subscribe(notification => {
        this.notification = notification;
        if (this.notification.reminder != null) {
          this.formGroup.patchValue(this.notification.reminder);
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.reminder = this.global.copyFormAttributeToModel(
              this.reminder,
              this.formGroup
            );
            this.notification.reminder = this.reminder;
            const url = this.todo === 'edit' ? '/reminder/update' : '';
            this.httpClientService
              .post<Response<Notification>>(url, this.notification)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/reminder/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/reminder/']);
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
}
