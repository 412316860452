import { TextUtils } from 'src/app/core/utils';
import { File } from '../../../bean/file';
import { Uploader } from '../model/uploader';
import { UploaderFile } from '../model/uploader-file';
export function createUploaderFileFromFileBean(
  uploader: Uploader,
  file: File
): UploaderFile {
  const uploaderFile: UploaderFile = new UploaderFile();
  uploaderFile.id = TextUtils.generateRandomString();
  uploaderFile.file = file;
  uploaderFile.src = file.uploadedFileName;
  uploaderFile.isNew = false;
  uploaderFile.parent = uploader;
  uploaderFile.setStatus('UPLOADED');
  return uploaderFile;
}
