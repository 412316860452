<app-layout>
    <div class="row main-row">
      <div class="col-lg-12 mb-4">
          <app-table-xx
            [model]="tableResponse"
            stringUrl="/aanwijzing/index"
            (onClick)="onClick($event)"
            header="{{ 'aanwijzing.table.header' | translate }}"
            [isShowButtonModifyTable]="true"
          >
  
          <ng-template #headerFilterGroup>
              <form
                class="form-horizontal"
                [formGroup]="formGroup"
                novalidate
                *ngIf="!formLoading"
              >
                <div class="form-group row text-sm-left">
                  <label class="col-sm-1 control-label">{{
                    'aanwijzing.form.date' | translate
                  }}</label>
                    
                      <div class="col-sm-3 text-left">
                        <app-date-picker-x
                          size="XL"
                          formControlName="date"
                          [range]="true"
                          (onChange)="onChangeFilter()"
                        >
                        </app-date-picker-x>
                      </div>
                      <div class="col-2">
                        <app-dropdown-select
                          type="CHOSEN"
                          size="XL"
                          formControlName="organizationList"
                          [optionList]="organizationOptionList"
                          (onChange)="onChangeFilter($event)"
                          showLimitValue="1"
                          placeholder="{{
                            'aanwijzing.placeholder.department' | translate
                          }}"
                        >
                          <ng-template #selectAll>
                              <p> {{ 'aanwijzing.placeholder.allDepartment' | translate }} </p>
                          </ng-template>
                        </app-dropdown-select>
                      </div>
                      <div class="col-2">
                        <app-dropdown-select
                          type="CHOSEN"
                          size="XL"
                          formControlName="statusList"
                          [optionList]="procurementWorklistStatusOptionList"
                          (onChange)="onChangeFilter($event)"
                          showLimitValue="1"
                        >
                          <ng-template #selectAll>
                              <p> {{ 'aanwijzing.placeholder.allStatus' | translate }} </p>
                          </ng-template>
                          <ng-template #option let-data>
                              <!-- <p> {{ (data.translationKey ? (data.translationKey.module.code.toLowerCase() 
                                  + '.'
                                  + data.translationKey.key) : data.name ) | translate }} </p> -->
                                  <p> {{ data?.name }} </p>
                          </ng-template>
  
                      </app-dropdown-select>
                      </div>
                    <!-- </div> -->
                </div>
              </form>
            </ng-template>
  
          </app-table-xx>
      </div>
    </div>
  </app-layout>