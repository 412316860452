<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="vendor-expired-document.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-fieldset legend="Filter">
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-expired-document.form.vendorName' | translate }}</label>
            <div class="col-sm-3 text-left">
              <app-text-field autofocus="true" size="XL"
                formControlName="vendorName"></app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-expired-document.form.vendorType' | translate }}</label>
            <div class="col-sm-3 text-left">
              <app-combo-box size="XL" [optionList]="vendorTypeList"
                formControlName="vendorType">
              </app-combo-box>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-expired-document.form.fileType' | translate }}</label>
            <div class="col-sm-3 text-left">
              <app-chosen size="XL" [optionList]="docList"
                formControlName="docList">
              </app-chosen>
            </div>
          </div>

          <div class="row justify-content-center mt-5">
            <app-button mode="BYPASS" color="SECONDARY" (onClick)="doReset()">
              <em class="fas fa-sync-alt"></em>
              {{ 'app.button.reset' | translate }}
            </app-button>&nbsp;
            <app-button mode="BYPASS" color="PRIMARY" (onClick)="doApply()"
              [disabled]="formSaving"><em class="fas fa-check"></em>
              {{ 'app.button.apply' | translate }}
            </app-button>
          </div>
        </form>

      </app-fieldset>
      <app-table [model]="tableResponse" [isServerSide]="true"
        stringUrl="/vendor-expired-document/index">
        <ng-template #actionButtons let-rowData>
          <app-button mode="BYPASS" size="TN"
            title="{{ 'app.tooltip.view' | translate }}"
            (onClick)="doViewDetail(rowData)">
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>