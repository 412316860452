<app-popup
  header="{{
    'dashboard-widget-best-spending-by-division.popup.title' | translate
  }}"
>
  <div class="row main-row">
    <div class="col-lg-12">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{
              'dashboard-widget-best-spending-by-division.form.sortBy'
                | translate
            }}
          </label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select
              type="COMBOBOX"
              size="XL"
              container="body"
              formControlName="sortBy"
              [optionList]="sortByOptionList"
              placeholder="dashboard-widget-best-spending-by-division.form.placeholder.sortBy"
            >
            </app-dropdown-select>
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{
              'dashboard-widget-best-spending-by-division.form.topDivision'
                | translate
            }}
          </label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select
              type="COMBOBOX"
              size="XL"
              container="body"
              formControlName="maxResult"
              [optionList]="maxResultOptionList"
              placeholder="dashboard-widget-best-spending-by-division.form.placeholder.maxResult"
            >
            </app-dropdown-select>
          </div>
        </div>

        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{
              'dashboard-widget-best-spending-by-division.form.year' | translate
            }}
          </label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select
              type="COMBOBOX"
              size="XL"
              container="body"
              formControlName="year"
              [optionList]="yearOptionList"
              placeholder="dashboard-widget-best-spending-by-division.form.placeholder.year"
            >
            </app-dropdown-select>
          </div>
        </div>

        <div class="form-group row text-sm-left pl-3">
          <app-check-box formControlName="ignoreZero"></app-check-box>
          <label class="white-space-normal"
            >{{
              'dashboard-widget-best-spending-by-division.form.ignoreZero'
                | translate
            }}&nbsp;{{ stages }}</label
          >
        </div>
      </form>
    </div>
  </div>
  <div class="button-group button-group-center">
    <app-button color="SECONDARY" (onClick)="activeModal.close()">
      {{ 'app.button.cancel' | translate }}
    </app-button>
    <app-button (onClick)="doApply()">
      {{ 'app.button.apply' | translate }}
    </app-button>
  </div>
</app-popup>
