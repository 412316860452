import { NgModule } from '@angular/core';
import { AppCheckBoxModule } from 'src/app/core/components/app-check-box/app-check-box.module';
import { AppPopupModule } from 'src/app/core/components/app-popup/app-popup.module';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppPopupManageCatalogTOCComponent} from './app-popup-manage-catalog-toc.component';
import { AppPopupManageCatalogTOCService } from './app-popup-manage-catalog-toc.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppCheckBoxModule],
  declarations: [AppPopupManageCatalogTOCComponent],
  entryComponents: [AppPopupManageCatalogTOCComponent],
  providers: [AppPopupManageCatalogTOCService],
  exports: [AppPopupManageCatalogTOCComponent]
})
export class AppPopupManageCatalogTOCModule {}
