<app-popup-comparison
  [itemList]="vendorList"
  (onChange)="doGetCheckedList($event)"
>
  <ng-template #content let-rowData>
    <div class="row">
      <div class="col-12 col-md-4">
        <div
          class="vendor-image w-100 overflow-hidden"
          [ngClass]="!rowData.logoFile ? 'no-image' : ''"
          [ngStyle]="{ 'border-radius': '0.2rem' }"
        >
          <img
            [attr.src]="
              global.config.BACKEND_ADDRESS +
                '/view-vendor/file/view/' +
                rowData.logoFile?.uploadedFileName +
                '/' + global.appConstant.fileType.IMG_LOGO_VENDOR
                | resolveImgSrc
                | async
            "
            *ngIf="rowData.logoFile"
            [attr.alt]="rowData.name"
            class="w-100"
          />
        </div>
      </div>
      <div class="col-12 col-md-8">
        <h4>{{ rowData.vendorEntity.name + ' ' + rowData.name }}</h4>
        <app-rating
          [value]="rowData.ratingPerformance"
          [isViewOnly]="true"
        ></app-rating>
      </div>
    </div>
  </ng-template>
</app-popup-comparison>
