import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorHistory } from '../../core/bean/vendor-history';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './vendor-reactivation.component.html',
  styles: [
    '.width-company-name { min-width : 250px; white-space: normal ! important }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class VendorReactivationComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<VendorHistory>;
  constructor(translateService: TranslateService) {
    super('vendor-reactivation', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'vendor.name',
        header: 'table.column.name',
        customClass: 'text-left'
      },
      {
        field: 'vendor.registrationNumber',
        header: 'table.column.registNo',
        customClass: 'text-center'
      },
      { field: 'vendor.vendorType.name', header: 'table.column.vendorType' },
      { field: 'vendor.email', header: 'table.column.email' },
      {
        field: 'vendor.vendorActivityStatus.name',
        header: 'table.column.status',
        customClass: 'text-center'
      }
    ]);
  }

  public doEdit(vendorHistory: VendorHistory): void {
    this.global.routerParams.clear();
    let todo = '';
    if (
      vendorHistory.vendor.vendorActivityStatus.code === 'REJECT_REGISTRATION'
    ) {
      todo = 'add';
    } else {
      todo = 'edit';
    }
    this.global.routerParams.set('todo', todo);
    this.global.routerParams.set('vendorHistoryId', vendorHistory.id);
    this.router.navigate(['/pages/vendor-reactivation/edit']);
  }

  public doView(vendorHistory: VendorHistory): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'detail');
    this.global.routerParams.set('vendorHistoryId', vendorHistory.id);
    this.global.routerParams.set('vendorId', vendorHistory.vendor.id);
    this.router.navigate(['/pages/vendor-reactivation/detail']);
  }

  public doViewVendor(vendorHistory: VendorHistory): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('vendorId', vendorHistory.vendor.id);
    this.router.navigate(['/pages/vendor-reactivation/detail-vendor']);
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/vendor-reactivation'
    );
  }
}
