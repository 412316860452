import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { AppWidgetModule } from '../../../../../core/widgets/app-widget.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetLoginInformationVendorComponent } from './widget-login-information-vendor.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule, WidgetCardModule],
  declarations: [WidgetLoginInformationVendorComponent],
  entryComponents: [WidgetLoginInformationVendorComponent],
  exports: [WidgetLoginInformationVendorComponent]
})
export class WidgetLoginInformationVendorModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetLoginInformationVendorComponent.moduleCode,
      WidgetLoginInformationVendorComponent,
      'w-100'
    );
  }
}
