import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ScoringCriteria } from '../../core/bean/scoring-criteria';
import { ScoringCriteriaGroup } from '../../core/bean/scoring-criteria-group';
import { PickListModel } from '../../core/components/app-pick-list/model/pick-list-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { ScoringCriteriaGroupResponse } from './scoring-criteria-group-response';
import { ScoringCriteriaGroupRequest } from './scoring-criteria-group-request';

@Component({
  templateUrl: './scoring-criteria-group-edit-add.component.html'
})
export class ScoringCriteriaGroupEditAddComponent extends BaseModuleComponent {
  public scoringCriteriaGroup: ScoringCriteriaGroup = new ScoringCriteriaGroup();
  public scoringCriteriaGroupId: number;
  public moduleScoringCriteriaList = [];
  public scoringCriteriaGroupResponse: ScoringCriteriaGroupResponse = new ScoringCriteriaGroupResponse();
  public scoringCriteriaGroupRequest: ScoringCriteriaGroupRequest = new ScoringCriteriaGroupRequest();

  public typeOptionList: OptionListModel<any> = new OptionListModel();
  public moduleOptionList: OptionListModel<any> = new OptionListModel(true);
  public scoringCriteriaPickListModel: PickListModel<ScoringCriteria>;
  constructor(translateService: TranslateService) {
    super('scoring-criteria-group', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
    this.setOptionsValue();
  }

  public setOptionsValue(): void {
    const typeOptionList = [
      { id: 1, name: 'Common' },
      { id: 2, name: 'Spesific' }
    ];
    this.typeOptionList.setRequestValues(typeOptionList);
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.scoringCriteriaGroupId = this.global.routerParams.get(
      'scoringCriteriaGroupId'
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      type: [null, Validators.compose([Validators.required()])],
      description: [null, Validators.maxLength(512)],
      moduleScoringCriteria: [
        null,
        Validators.compose([Validators.required()])
      ],
      isCriteriaEmpty: [null, Validators.compose([Validators.required()])]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ScoringCriteriaGroupResponse>(
        '/scoring-criteria-group/add-edit',
        new RouteRequestModel(this.todo, this.scoringCriteriaGroupId)
      )
      .subscribe(scoringCriteriaGroupResponse => {
        const {
          scoringCriteriaListNotByScoringCriteriaGroup,
          scoringCriteriaListByScoringCriteriaGroup
        } = scoringCriteriaGroupResponse;
        this.scoringCriteriaPickListModel = new PickListModel(
          this.moduleCode,
          scoringCriteriaListNotByScoringCriteriaGroup,
          scoringCriteriaListByScoringCriteriaGroup
        );
        this.setModuleOption(scoringCriteriaGroupResponse);
        this.moduleScoringCriteriaList =
          scoringCriteriaGroupResponse.moduleScoringCriteriaList;
        if (scoringCriteriaGroupResponse.scoringCriteriaGroup != null) {
          const {
            id,
            name,
            code,
            description,
            type
          } = scoringCriteriaGroupResponse.scoringCriteriaGroup;
          this.formGroup.patchValue({ id, name, code, description, type });
        }
        this.setStateReady();
      });
  }

  public setModuleOption(
    scoringCriteriaGroupResponse: ScoringCriteriaGroupResponse
  ): void {
    const moduleOptionList = [];
    scoringCriteriaGroupResponse.moduleScoringCriteriaList.forEach(
      moduleScoringCriteria => {
        moduleOptionList.push({
          id: moduleScoringCriteria.id,
          name: moduleScoringCriteria.name
        });
        if (
          scoringCriteriaGroupResponse.scoringCriteriaGroup !== null &&
          moduleScoringCriteria.id ===
          scoringCriteriaGroupResponse.scoringCriteriaGroup.moduleScoringCriteria
            .id
        ) {
          this.formGroup
            .get('moduleScoringCriteria')
            .patchValue(moduleScoringCriteria.id);
        }
      }
    );
    this.moduleOptionList.setRequestValues(moduleOptionList);
  }

  public checkCriteria(): void {
    if (this.scoringCriteriaPickListModel.targetItemList.length !== 0) {
      this.formGroup.patchValue({
        isCriteriaEmpty: true
      });
    } else {
      this.formGroup.patchValue({
        isCriteriaEmpty: false
      });
    }
  }

  public doSave(): void {
    this.validate();
    this.checkCriteria();
    if (this.formGroup.valid && this.formGroup.value.isCriteriaEmpty === true) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.moduleScoringCriteriaList.forEach(moduleScoringCriteria => {
              if (
                moduleScoringCriteria.id ===
                this.formGroup.get('moduleScoringCriteria').value
              ) {
                this.formGroup
                  .get('moduleScoringCriteria')
                  .patchValue(moduleScoringCriteria);
              }
            });

            this.scoringCriteriaGroupRequest.scoringCriteriaGroup = this.formGroup.value;
            this.scoringCriteriaGroupRequest.scoringCriteriaListByScoringCriteriaGroup = this.scoringCriteriaPickListModel.targetItemList;
            this.scoringCriteriaGroupRequest.scoringCriteriaListNotByScoringCriteriaGroup = this.scoringCriteriaPickListModel.sourceItemList;
            const url: string =
              this.todo === 'edit'
                ? '/scoring-criteria-group/update'
                : '/scoring-criteria-group/insert';
            this.httpClientService
              .post<Response<ScoringCriteriaGroupRequest>>(
                url,
                this.scoringCriteriaGroupRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/scoring-criteria-group/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/scoring-criteria-group/']);
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
}
