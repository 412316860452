import { Component, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Procurement } from 'src/app/core/bean/procurement';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ScoringCriteria } from '../../core/bean/scoring-criteria';
import { ScoringCriteriaGroup } from '../../core/bean/scoring-criteria-group';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { AllPerformanceDTO } from './dto/all-performance.dto';
@Component({
  templateUrl: './all-performance-detail-result.component.html'
})
export class AllPerformanceDetailResultComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<ScoringCriteria>;
  public vendorPerformanceId: number;
  public vendorPerformanceOverallId: number;
  public allPerformanceDTO: AllPerformanceDTO = new AllPerformanceDTO();
  public isSummary = false;
  public isView = false;
  public totalScoring = 0;
  public totalScoring1 = 0;
  public totalResultCommon: number[] = [];
  public totalResultSpecific: number[] = [];
  public resultList: number[] = [];
  public scoringCriteriaGroupList: ScoringCriteriaGroup[] = [];
  public procurement: Procurement = new Procurement();

  public fileUploader: FileUploader = new FileUploader(
    '/performance-on-registration/',
    'performance-on-registration.form.headerImage',
    this.global.appConstant.fileType.DOC_PERFORMANCE
  );

  constructor(translateService: TranslateService) {
    super('all-performance', translateService);
  }

  onInit(): void {
    this.getFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public getFromRouterParams(): void {
    this.vendorPerformanceId = this.global.routerParams.get(
      'vendorPerformanceId'
    );
    this.todo = this.global.routerParams.get('todo');
    this.vendorPerformanceOverallId = this.global.routerParams.get(
      'vendorPerformanceOverallId'
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorName: [null],
      address: [null],
      vendorType: [null],
      uploadFile: [null],
      recommendationNote: [null],
      commonList: this.formBuilder.array([]),
      specificList: this.formBuilder.array([]),
      summaryList: this.formBuilder.array([]),
      performanceUser: [null],
      rating: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<AllPerformanceDTO>(
        '/all-performance/detail',
        new RouteRequestModel(this.todo, this.vendorPerformanceId)
      )
      .subscribe(allPerformanceDTO => {
        this.allPerformanceDTO = allPerformanceDTO;
        this.allPerformanceDTO.scoringCriteriaGroupCommonList.forEach(
          common => {
            this.scoringCriteriaGroupList.push(common);
          }
        );
        this.allPerformanceDTO.scoringCriteriaGroupSpesificList.forEach(
          specific => {
            this.scoringCriteriaGroupList.push(specific);
          }
        );
        this.fileUploader.setFile(this.allPerformanceDTO.performance.file);
        if (this.allPerformanceDTO.vendor !== null) {
          this.formGroup.patchValue({
            vendorName: this.allPerformanceDTO.vendor.name,
            vendorType: this.allPerformanceDTO.vendor.vendorType.name,
            address: this.allPerformanceDTO.vendorAddress.address,
            performanceUser: this.allPerformanceDTO.performance.verifiedByUser.name,
            recommendationNote: this.allPerformanceDTO.performance.note,
            uploadFile: this.allPerformanceDTO.performance.file
          });

          if (this.allPerformanceDTO.performance.moduleScoringCriteria.code
              === this.global.appConstant.vm.M_SCORING_CRITERIA_PROCUREMENT) {
            this.procurement = this.allPerformanceDTO.performance.procurement;
          }

        }
        this.formGroup.patchValue({ rating: this.totalScoring1 });
        this.formGroup.get('rating').setIsView(true);
        this.buildCommonList();
        this.buildSpecificList();
        this.buildSummaryList();
        this.setViewOnly();

        this.setStateReady();
      });
  }

  public buildCommonList(): void {
    const parentForm = this.formGroup.controls.commonList as FormArray;
    this.allPerformanceDTO.scoringCriteriaGroupCommonList.forEach(common => {
      const formGroup = this.formBuilder.group({
        scoringCriteriaGroup: common,
        scoringCriteriaList: this.formBuilder.array([])
      });
      parentForm.push(formGroup);
    });
    this.buildScoringCriteriaCommonList();
  }

  public buildSpecificList(): void {
    const parentForm = this.formGroup.controls.specificList as FormArray;
    this.allPerformanceDTO.scoringCriteriaGroupSpesificList.forEach(
      specific => {
        const formGroup = this.formBuilder.group({
          scoringCriteriaGroup: specific,
          scoringCriteriaList: this.formBuilder.array([])
        });
        parentForm.push(formGroup);
      }
    );
    this.buildScoringCriteriaSpecificList();
  }

  public buildSummaryList(): void {
    const parentForm = this.formGroup.controls.summaryList as FormArray;
    this.allPerformanceDTO.performanceSummaryList.forEach(summary => {
      this.totalScoring =
        Math.round((this.totalScoring + summary.total) * 100) / 100;
      const formGroup = this.formBuilder.group({
        scoringCriteriaGroup: summary.scoringCriteriaGroupName,
        weight: summary.weight,
        result: summary.result,
        total: summary.total
      });
      parentForm.push(formGroup);
    });
    this.formGroup.patchValue({ rating: this.totalScoring });
    this.formGroup.get('rating').setIsView(true);
  }

  public buildScoringCriteriaCommonList(): void {
    const parentForm = this.formGroup.controls.commonList as FormArray;
    this.allPerformanceDTO.scoringCriteriaGroupCommonList.forEach(
      (common, index) => {
        let total = 0;
        let numberTemp = 0;
        this.allPerformanceDTO.performanceScoreCommonList.forEach(score => {
          if (common.code === score.scoringCriteriaGroupCode) {
            numberTemp += 1;
            const formGroup = this.formBuilder.group({
              scoringCriteria: score.scoringCriteriaName,
              value: score.value,
              result: score.result
            });
            total = total + score.result;
            const scoringCriteriaListForm = parentForm.controls[index].get(
              'scoringCriteriaList'
            ) as FormArray;
            scoringCriteriaListForm.push(formGroup);
          }
        });
        total = Math.round((total / numberTemp) * 100) / 100;
        this.totalResultCommon.push(total);
      }
    );
  }

  public buildScoringCriteriaSpecificList(): void {
    const parentForm = this.formGroup.controls.specificList as FormArray;
    this.allPerformanceDTO.scoringCriteriaGroupSpesificList.forEach(
      (specific, index) => {
        let total = 0;
        let numberTemp = 0;
        this.allPerformanceDTO.performanceScoreSpecificList.forEach(score => {
          if (specific.code === score.scoringCriteriaGroupCode) {
            numberTemp += 1;
            const formGroup = this.formBuilder.group({
              scoringCriteria: score.scoringCriteriaName,
              value: score.value,
              result: score.result
            });
            total = total + score.result;
            const scoringCriteriaListForm = parentForm.controls[index].get(
              'scoringCriteriaList'
            ) as FormArray;
            scoringCriteriaListForm.push(formGroup);
          }
        });
        total = Math.round((total / numberTemp) * 100) / 100;
        this.totalResultSpecific.push(total);
      }
    );
  }

  // public getallPerformanceDTOFromServer(urlGetDTO: string, todo: string, vendorId: number) {
  //     return this.httpClient.post<allPerformanceDTO>(urlGetDTO, new RequestByIdModel(todo, vendorId));
  // }

  // public doScoring(): void {
  //     this.isSummary = true;
  //     this.buildSummaryList();
  // }

  public doBack(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set(
      'vendorPerformanceOverallId',
      this.vendorPerformanceOverallId
    );
    this.router.navigate(['/pages/all-performance/detail']);
  }

  public doPrint(): void {}

  public getFormArrayWithFormControlName(
    formGroup: FormGroup,
    formControlName: string
  ): FormArray {
    return formGroup.get(formControlName) as FormArray;
  }
}
