import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { ItemType } from '../../core/bean/item-type';
import { PriceEvaluationMethod } from '../../core/bean/price-evaluation-method';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { QuotationItemReference } from '../../core/bean/quotation-item-reference';
import { Vendor } from '../../core/bean/vendor';
import { AppOfficialReportModel } from '../../core/components/app-official-report/app-official-report-model';
import { AppPopupAllocatedItemDetailService } from '../../core/components/app-popup/app-popup-allocated-item-detail/app-popup-allocated-item-detail.service';
import { AppPopupAnalysisMultipleService } from '../../core/components/app-popup/app-popup-analysis-multiple/app-popup-analysis-multiple.service';
import { AppPopupAnalysisSingleService } from '../../core/components/app-popup/app-popup-analysis-single/app-popup-analysis-single.service';
import { AppPopupChooseVendorMultiWinnerService } from '../../core/components/app-popup/app-popup-choose-vendor-multi-winner/app-popup-choose-vendor-multi-winner.service';
import { AppPopupFinalPriceDetailService } from '../../core/components/app-popup/app-popup-final-price-detail/app-popup-final-price-detail.service';
import { ToastService } from '../../core/components/app-toast/app-toast.service';
import { AllTablePluginOptions } from '../../core/components/table/interface/available-table-plugin';
import {
  TableHeaderPluginData,
  TablePluginData
} from '../../core/components/table/interface/table-plugin-data';
import { OptionListModel } from '../../core/model/option-list-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { VendorQuotationByItemView } from '../../core/view/entity/bean/vendor-quotation-by-item-view';
import { VendorQuotationFinalReNegoView } from '../../core/view/entity/bean/vendor-quotation-final-re-nego-view';
import { VendorQuotationView } from '../../core/view/entity/bean/vendor-quotation-view';
import { VendorReferenceSelectedVendorView } from '../../core/view/entity/bean/vendor-reference-selected-vendor-view';
import { VendorReferencePopupChangeWinningVendorComponent } from './vendor-reference-popup-change-winning-vendor.component';
import { VendorReferencePopupChooseVendorMultiWinnerComponent } from './vendor-reference-popup-choose-vendor-multi-winner.component';
import { VendorReferencePopupConfirmComponent } from './vendor-reference-popup-confirm.component';
import { VendorReferenceRequest } from './vendor-reference.request';
import { VendorReferenceResponse } from './vendor-reference.response';

@Component({
  templateUrl: './vendor-reference-edit.component.html'
})
export class VendorReferenceEditComponent
  extends BaseModuleComponent
  implements OnInit
{
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public appPopupFinalPriceDetailService: AppPopupFinalPriceDetailService,
    public appPopupChooseVendorMultiWinner: AppPopupChooseVendorMultiWinnerService,
    public appPopupAllocatedItemDetail: AppPopupAllocatedItemDetailService,
    public currency: CurrencyPipe,
    public snackbarService: SnackbarService,
    public datePipe: DatePipe,
    public activatedRoute: ActivatedRoute,
    public toastService: ToastService,
    public appPopupAnalysisSingleService: AppPopupAnalysisSingleService,
    public appPopupAnalysisMultipleService: AppPopupAnalysisMultipleService
  ) {
    super('vendor-reference', translateService);
  }
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  @ViewChild('selectorProposalCriteria')
  tableProposalCriteria: AppTableXComponent;
  @ViewChild('selectorVendor')
  tableVendor: AppTableXComponent;

  public token: string;
  public stage: number;
  public objectId: number;
  public procurementId: number;
  public approvalModelPrcsId: number;
  public procurementVendorId: number;
  public urlBackOutside: string;
  public todoOfficialReport: string;
  public isThingsToConsider: boolean;
  public isMustFullFill = false;
  public isOfficialReportValid: boolean;
  public isInvalidAllocatedItem: boolean;
  public isInvalidChooseVendorSingleWinner: boolean;
  public CURRENCY_DIGITS_INFO: string;
  public currentDate: Date = new Date();

  public tabs: Array<any>;
  public procurement: Procurement = new Procurement();
  public vendorQuotationView: VendorQuotationView = new VendorQuotationView();
  public vendorReferenceRequest: VendorReferenceRequest =
    new VendorReferenceRequest();
  public vendorReferenceResponse: VendorReferenceResponse =
    new VendorReferenceResponse();

  public procurementItemList: ProcurementItem[] = [];
  public quotationItemReferenceList: QuotationItemReference[] = [];
  public isSubmit = false;
  public tableResponse: TableResponseModel<any>;
  public tableResponseReNego: TableResponseModel<VendorQuotationFinalReNegoView>;
  public tableResponseQuotationItem: TableResponseModel<VendorQuotationByItemView>;
  public tableResponseQuotationVendor: TableResponseModel<VendorReferenceSelectedVendorView>;
  public tableResponseQuotationItemReNego: TableResponseModel<VendorQuotationByItemView>;
  public tableResponseQuotationVendorReNego: TableResponseModel<VendorReferenceSelectedVendorView>;
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    false
  );
  public evaluationMethodOptionList: OptionListModel<PriceEvaluationMethod> =
    new OptionListModel(true, 'name');
  public canceledOrFailedMessage: string;
  public isShowBadgeStatus: boolean;
  public badgeColor = {
    REVISION: 'FEEDBACK',
    RE_NEGOTIATION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    NEW: 'INFO',
    HOLD: 'DANGER',
    CANCELED: 'DANGER',
    FAILED: 'DANGER',
    DRAFT: 'SECONDARY'
  };

  public customPlugin: AllTablePluginOptions = {
    name: 'custom-plugin',
    before: (data: TablePluginData): HTMLElement | string => {
      const emELement = document.createElement('em');
      let vendorWinner;
      if (
        this.procurement.priceEvaluationMethod.code.includes(
          'DEAL_WITH_THIS_PRICE'
        )
      ) {
        vendorWinner =
          this.vendorReferenceResponse.vendorQuotationViewList.filter(
            vendorQuotationView => vendorQuotationView.isReference
          );
      } else {
        vendorWinner =
          this.vendorReferenceResponse.vendorQuotationFinalViewList.filter(
            vendorQuotationView => vendorQuotationView.isReference
          );
      }
      if (
        vendorWinner &&
        vendorWinner.length > 0 &&
        data.row.record.procurementVendor.id ===
          vendorWinner[0].procurementVendor.id
      ) {
        emELement.classList.add('pic', 'pi-trophy');
        emELement.style.fontSize = '1.75rem';
        this.vendorQuotationView = data.row.record;
      }
      return emELement;
    }
  };

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Procurement>('/vendor-reference/notification/' + this.token)
      .subscribe((procurement: Procurement) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('procurement', procurement);
        this.global.routerParams.set('procurementId', procurement.id);
        this.global.routerParams.set('urlBackOutside', '/pages/worklist-pm');
        if (
          procurement.procurementWorklistStatus.code ===
          this.global.appConstant.pm
            .PROCUREMENT_WORKLIST_STATUS_WAITING_APPROVAL
        ) {
          this.router.navigate(['/pages/vendor-reference/detail']);
          this.global.routerParams.set('todo', 'view');
        } else if (
          procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT ||
          procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION
        ) {
          this.router.navigate(['/pages/vendor-reference/edit']);
          this.global.routerParams.set('todo', 'edit');
        } else {
          this.router.navigate(['/pages/vendor-reference/add']);
          this.global.routerParams.set('todo', 'add');
        }
      });
  }

  public setInitializationState(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public doSetDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementVendorId = this.global.routerParams.get(
      'procurementVendorId'
    );
    this.procurement = this.global.routerParams.get('procurement');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.todoOfficialReport = this.todo;
    if (
      this.global.routerParams.get('isChangeWinner') !== null &&
      this.global.routerParams.get('isChangeWinner') !== undefined
    ) {
      this.isOfficialReportValid =
        this.global.routerParams.get('isChangeWinner');
    }
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
      this.isOfficialReportValid = true;
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      itemType: [null],
      amount: [null],
      goodsAmount: [null],
      serviceAmount: [null],
      evaluationMethod: [null],
      isReportValid: [true, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<VendorReferenceResponse>(
        '/vendor-reference/edit',
        new RouteRequestModel(this.todo, this.procurementId)
      )
      .subscribe((vendorReferenceResponse: VendorReferenceResponse) => {
        this.vendorReferenceResponse = vendorReferenceResponse;
        this.procurement = vendorReferenceResponse.procurement;
        this.buildTableResponse();
        this.buildTableQuotationItem();
        this.buildTableQuotationVendor();
        this.buildTableResponseReNego();
        this.setSelectedRecordList();
        this.quotationItemReferenceList =
          vendorReferenceResponse.quotationItemReferenceList;
        this.global.routerParams.set(
          'vendorReferenceResponse',
          this.vendorReferenceResponse
        );
        this.itemTypeOptionList.setRequestValues(
          this.vendorReferenceResponse.itemTypeList
        );
        this.evaluationMethodOptionList.setRequestValues(
          this.vendorReferenceResponse.priceEvaluationMethodList
        );
        this.tableResponseQuotationItem.setRecordList(
          vendorReferenceResponse.vendorQuotationByItemViewList
        );
        this.tableResponseQuotationVendor.setRecordList(
          vendorReferenceResponse.vendorQuotationByVendorViewList
        );
        this.tableResponseQuotationItemReNego.setRecordList(
          vendorReferenceResponse.vendorQuotationByItemViewList
        );
        this.tableResponseQuotationVendorReNego.setRecordList(
          vendorReferenceResponse.vendorQuotationByVendorViewList
        );
        if (
          this.procurement.vendorSelectionType.code ===
          this.global.appConstant.pm.VENDOR_SELECTION_TYPE_MULTI_WINNER
        ) {
          if (vendorReferenceResponse.isHasFinalReNego) {
            this.getVendorQuotation(true);
          }
          this.getVendorQuotation(false);
        }
        this.formGroup.get('evaluationMethod').setIsView(true);
        const statusList = [
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
        ];

        if (statusList.includes(this.procurement.procurementStatus.code)) {
          if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'vendor-reference.alert.msg.sorryWaitingCancelationApproval';
          } else if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'vendor-reference.alert.msg.sorryWaitingFailedApproval';
          } else {
            this.canceledOrFailedMessage =
              'vendor-reference.alert.msg.sorryFailedAssessment';
          }
        }
        this.setStateReady();
      });
  }

  public getVendorQuotation(isRenego: boolean): void {
    if (!isRenego) {
      this.setStateProcessing();
      this.httpClientService
        .get<VendorQuotationView>(
          '/vendor-reference/table-selected-vendor/' + this.procurement.id
        )
        .subscribe((response: VendorReferenceSelectedVendorView[]) => {
          if (
            !['VENDOR-REFERENCE', 'APPROVAL-VENDOR-REFERENCE'].includes(
              this.procurement.workflowModelPrcs.code
            )
          ) {
            response = response.filter(
              vendorView =>
                +vendorView.goodsSelectedItem.split('/')[0] > 0 ||
                +vendorView.serviceSelectedItem.split('/')[0] > 0
            );
          }
          this.tableResponseQuotationVendor.setRecordList(response);
          this.tableResponseQuotationVendor.reload();
          this.setStateReady();
        });
    } else {
      this.setStateProcessing();
      this.httpClientService
        .get<VendorQuotationView>(
          '/vendor-reference/table-selected-vendor-re-nego/' +
            this.procurement.id
        )
        .subscribe(response => {
          if (
            !['VENDOR-REFERENCE', 'APPROVAL-VENDOR-REFERENCE'].includes(
              this.procurement.workflowModelPrcs.code
            )
          ) {
            response = response.filter(
              vendorView =>
                vendorView.goodsSelectedItem.split('/')[0] ||
                vendorView.serviceSelectedItem.split('/')[0]
            );
          }
          this.tableResponseQuotationVendorReNego.setRecordList(response);
          this.tableResponseQuotationVendorReNego.reload();
          this.setStateReady();
        });
    }
  }

  public setSelectedRecordList(): void {
    if (
      this.procurement.priceEvaluationMethod.code.includes(
        'DEAL_WITH_THIS_PRICE'
      )
    ) {
      this.tableResponse.setRecordList(
        this.vendorReferenceResponse.vendorQuotationViewList
      );
      const winningVendor =
        this.vendorReferenceResponse.vendorQuotationViewList.filter(
          x => x.isReference === true
        );
      if (winningVendor && winningVendor.length > 0) {
        this.tableResponse.setSelectedRowList(winningVendor);
      } else {
        this.tableResponse.setSelectedRowList([
          this.tableResponse.getRecordList()[0]
        ]);
      }
    } else {
      this.tableResponse.setRecordList(
        this.vendorReferenceResponse.vendorQuotationFinalViewList
      );
      const winningVendor =
        this.vendorReferenceResponse.vendorQuotationFinalViewList.filter(
          x => x.isReference === true
        );
      if (winningVendor && winningVendor.length > 0) {
        this.tableResponse.setSelectedRowList(winningVendor);
      } else {
        this.tableResponse.setSelectedRowList([
          this.tableResponse.getRecordList()[0]
        ]);
      }
    }
    if (
      this.vendorReferenceResponse.isHasFinalReNego &&
      this.procurement.vendorSelectionType.code ===
        this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
    ) {
      this.tableResponseReNego.setRecordList(
        this.vendorReferenceResponse.vendorQuotationFinalReNegoViewList
      );
      const winningVendor =
        this.vendorReferenceResponse.vendorQuotationFinalReNegoViewList.filter(
          x => x.isReference === true
        );
      if (winningVendor && winningVendor.length > 0) {
        this.tableResponseReNego.setSelectedRowList(winningVendor);
      } else {
        this.tableResponseReNego.setSelectedRowList([
          this.tableResponseReNego.getRecordList()[0]
        ]);
      }
    }
  }

  public buildTableResponse(): void {
    if (this.todo === 'view') {
      this.tableResponse = new TableResponseModel(
        this.moduleCode,
        this.procurement.biddingEvaluation.code !==
        this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
          ? [
              {
                field: '',
                header: '',
                className: 'text-center',
                plugins: this.customPlugin,
                isSortable: false
              },
              {
                field: 'vendorName',
                header: 'table.column.vendorName',
                plugins: [
                  {
                    name: 'hyperlink',
                    onClick: this.doViewFinalPriceDetail.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pic pi-exclamation-triangle',
                    onClick: this.onClickIconConsider.bind(this),
                    isShowIconFn: this.showIcon.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pis pi-exclamation-triangle text-danger',
                    onClick: this.onClickIconDanger.bind(this),
                    isShowIconFn: this.showIconDanger.bind(this)
                  }
                ]
              },
              {
                header: 'table.column.evaluation',
                columnList: [
                  {
                    field: 'administrationScoreStatus.name',
                    header: 'table.column.administration',
                    plugins: {
                      name: 'badge',
                      colorField: 'administrationScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  {
                    field: 'technicalScoreStatus.name',
                    header: 'table.column.technical',
                    plugins: {
                      name: 'badge',
                      colorField: 'technicalScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  this.procurement.biddingEvaluation.code ===
                  this.global.appConstant.pm
                    .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
                    ? {
                        field: 'finalPriceScoreStatus.name',
                        header: 'table.column.price',
                        plugins: {
                          name: 'badge',
                          colorField: 'finalPriceScoreStatus.code',
                          colorMap: {
                            PASSED: 'SUCCESS',
                            NOT_PASSED: 'DANGER'
                          }
                        }
                      }
                    : {
                        field: 'finalPriceScore',
                        header: 'table.column.price',
                        className: 'text-right',
                        plugins: {
                          name: 'text-field',
                          type: 'decimal',
                          isView: true
                        }
                      }
                ]
              },
              {
                field: 'finalPrice',
                header: 'table.column.finalPrice',
                plugins: {
                  name: 'default',
                  type: 'currency'
                },
                className: 'text-right'
              },
              {
                field: 'finalProfitLossAmount',
                header: 'table.column.savingLoss',
                className: 'text-right',
                plugins: [
                  {
                    name: 'custom-plugin',
                    before: (tablePlugin: TablePluginData): void => {
                      if (tablePlugin.value) {
                        tablePlugin.element.innerText =
                          this.global.converterService.convertMoney(
                            tablePlugin.value
                          ) + ' %';
                      }
                    }
                  },
                  {
                    name: 'badge',
                    field: 'finalProfitLossStatus.name',
                    colorMap: {
                      SAVE: 'SUCCESS',
                      LOSS: 'DANGER'
                    },
                    pill: false,
                    colorField: 'finalProfitLossStatus.code',
                    className: 'badge-catalog ml-3'
                  }
                ]
              },
              {
                field: 'vendorName',
                header: 'table.column.resultAnalysis',
                plugins: [
                  {
                    name: 'hyperlink',
                    onClick: this.doSeeDetailsAnalysisSingle.bind(this),
                    customText: this.translateService.instant(
                      'vendor-reference.table.value.seeDetails'
                    )
                  }
                ]
              }
            ]
          : [
              {
                field: '',
                header: '',
                className: 'text-center',
                plugins: this.customPlugin,
                isSortable: false
              },
              {
                field: 'vendorName',
                header: 'table.column.vendorName',
                plugins: [
                  {
                    name: 'hyperlink',
                    onClick: this.doViewFinalPriceDetail.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pic pi-exclamation-triangle',
                    onClick: this.onClickIconConsider.bind(this),
                    isShowIconFn: this.showIcon.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pis pi-exclamation-triangle text-danger',
                    onClick: this.onClickIconDanger.bind(this),
                    isShowIconFn: this.showIconDanger.bind(this)
                  }
                ]
              },
              {
                header: 'table.column.evaluation',
                columnList: [
                  {
                    field: 'administrationScoreStatus.name',
                    header: 'table.column.administration',
                    plugins: {
                      name: 'badge',
                      colorField: 'administrationScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  {
                    field: 'technicalScore',
                    header: {
                      key: 'table.column.technical',
                      plugins: {
                        name: 'custom-plugin',
                        after: (
                          tableHeaderPlugin: TableHeaderPluginData
                        ): void => {
                          if (tableHeaderPlugin) {
                            tableHeaderPlugin.element.innerText +=
                              ' (' +
                              (this.vendorReferenceResponse
                                .technicalPercentage || ' - ') +
                              '%)';
                          }
                        }
                      }
                    },
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  },
                  {
                    field: 'finalPriceScore',
                    header: {
                      key: 'table.column.price',
                      plugins: {
                        name: 'custom-plugin',
                        after: (
                          tableHeaderPlugin: TableHeaderPluginData
                        ): void => {
                          if (tableHeaderPlugin) {
                            tableHeaderPlugin.element.innerText +=
                              ' (' +
                              (this.vendorReferenceResponse.pricePercentage ||
                                ' - ') +
                              '%)';
                          }
                        }
                      }
                    },
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  },
                  {
                    field: 'totalFinalEvaluationScore',
                    header: 'table.column.total',
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  }
                ]
              },
              {
                field: 'finalPrice',
                header: 'table.column.finalPrice',
                plugins: {
                  name: 'default',
                  type: 'currency'
                },
                className: 'text-right'
              },
              {
                field: 'finalProfitLossAmount',
                header: 'table.column.savingLoss',
                className: 'text-right',
                plugins: [
                  {
                    name: 'custom-plugin',
                    before: (tablePlugin: TablePluginData): void => {
                      if (tablePlugin.value) {
                        tablePlugin.element.innerText =
                          this.global.converterService.convertMoney(
                            tablePlugin.value
                          ) + ' %';
                      }
                    }
                  },
                  {
                    name: 'badge',
                    field: 'finalProfitLossStatus.name',
                    colorMap: {
                      SAVE: 'SUCCESS',
                      LOSS: 'DANGER'
                    },
                    pill: false,
                    colorField: 'finalProfitLossStatus.code',
                    className: 'badge-catalog ml-3'
                  }
                ]
              },
              {
                field: 'vendorName',
                header: 'table.column.resultAnalysis',
                plugins: [
                  {
                    name: 'hyperlink',
                    onClick: this.doSeeDetailsAnalysisSingle.bind(this),
                    customText: this.translateService.instant(
                      'vendor-reference.table.value.seeDetails'
                    )
                  }
                ]
              }
            ],
        { checkBoxFn: this.isShowCheckBox.bind(this) }
      );
    } else {
      this.tableResponse = new TableResponseModel(
        this.moduleCode,
        this.procurement.biddingEvaluation.code !==
        this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
          ? [
              {
                field: 'vendorName',
                header: 'table.column.vendorName',
                plugins: [
                  {
                    name: 'hyperlink',
                    onClick: this.doViewFinalPriceDetail.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pic pi-exclamation-triangle',
                    onClick: this.onClickIconConsider.bind(this),
                    isShowIconFn: this.showIcon.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pis pi-exclamation-triangle text-danger',
                    onClick: this.onClickIconDanger.bind(this),
                    isShowIconFn: this.showIconDanger.bind(this)
                  }
                ]
              },
              {
                header: 'table.column.evaluation',
                columnList: [
                  {
                    field: 'administrationScoreStatus.name',
                    header: 'table.column.administration',
                    plugins: {
                      name: 'badge',
                      colorField: 'administrationScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  {
                    field: 'technicalScoreStatus.name',
                    header: 'table.column.technical',
                    plugins: {
                      name: 'badge',
                      colorField: 'technicalScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  this.procurement.biddingEvaluation.code ===
                  this.global.appConstant.pm
                    .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
                    ? {
                        field: 'finalPriceScoreStatus.name',
                        header: 'table.column.price',
                        plugins: {
                          name: 'badge',
                          colorField: 'finalPriceScoreStatus.code',
                          colorMap: {
                            PASSED: 'SUCCESS',
                            NOT_PASSED: 'DANGER'
                          }
                        }
                      }
                    : {
                        field: 'finalPriceScore',
                        header: 'table.column.price',
                        className: 'text-right',
                        plugins: {
                          name: 'text-field',
                          type: 'decimal',
                          isView: true
                        }
                      }
                ]
              },
              {
                field: 'finalPrice',
                header: 'table.column.finalPrice',
                plugins: {
                  name: 'default',
                  type: 'currency'
                },
                className: 'text-right'
              },
              {
                field: 'finalProfitLossAmount',
                header: 'table.column.savingLoss',
                className: 'text-right',
                plugins: [
                  {
                    name: 'custom-plugin',
                    before: (tablePlugin: TablePluginData): void => {
                      if (tablePlugin.value) {
                        tablePlugin.element.innerText =
                          this.global.converterService.convertMoney(
                            tablePlugin.value
                          ) + ' %';
                      }
                    }
                  },
                  {
                    name: 'badge',
                    field: 'finalProfitLossStatus.name',
                    colorMap: {
                      SAVE: 'SUCCESS',
                      LOSS: 'DANGER'
                    },
                    pill: false,
                    colorField: 'finalProfitLossStatus.code',
                    className: 'badge-catalog ml-3'
                  }
                ]
              },
              {
                field: 'vendorName',
                header: 'table.column.resultAnalysis',
                plugins: [
                  {
                    name: 'hyperlink',
                    onClick: this.doSeeDetailsAnalysisSingle.bind(this),
                    customText: this.translateService.instant(
                      'vendor-reference.table.value.seeDetails'
                    )
                  }
                ]
              }
            ]
          : [
              {
                field: 'vendorName',
                header: 'table.column.vendorName',
                plugins: [
                  {
                    name: 'hyperlink',
                    onClick: this.doViewFinalPriceDetail.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pic pi-exclamation-triangle',
                    onClick: this.onClickIconConsider.bind(this),
                    isShowIconFn: this.showIcon.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pis pi-exclamation-triangle text-danger',
                    onClick: this.onClickIconDanger.bind(this),
                    isShowIconFn: this.showIconDanger.bind(this)
                  }
                ]
              },
              {
                header: 'table.column.evaluation',
                columnList: [
                  {
                    field: 'administrationScoreStatus.name',
                    header: 'table.column.administration',
                    plugins: {
                      name: 'badge',
                      colorField: 'administrationScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  {
                    field: 'technicalScore',
                    header: {
                      key: 'table.column.technical',
                      plugins: {
                        name: 'custom-plugin',
                        after: (
                          tableHeaderPlugin: TableHeaderPluginData
                        ): void => {
                          if (tableHeaderPlugin) {
                            tableHeaderPlugin.element.innerText +=
                              ' (' +
                              (this.vendorReferenceResponse
                                .technicalPercentage || ' - ') +
                              '%)';
                          }
                        }
                      }
                    },
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  },
                  {
                    field: 'finalPriceScore',
                    header: {
                      key: 'table.column.price',
                      plugins: {
                        name: 'custom-plugin',
                        after: (
                          tableHeaderPlugin: TableHeaderPluginData
                        ): void => {
                          if (tableHeaderPlugin) {
                            tableHeaderPlugin.element.innerText +=
                              ' (' +
                              (this.vendorReferenceResponse.pricePercentage ||
                                ' - ') +
                              '%)';
                          }
                        }
                      }
                    },
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  },
                  {
                    field: 'totalFinalEvaluationScore',
                    header: 'table.column.total',
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  }
                ]
              },
              {
                field: 'finalPrice',
                header: 'table.column.finalPrice',
                plugins: {
                  name: 'default',
                  type: 'currency'
                },
                className: 'text-right'
              },
              {
                field: 'finalProfitLossAmount',
                header: 'table.column.savingLoss',
                className: 'text-right',
                plugins: [
                  {
                    name: 'custom-plugin',
                    before: (tablePlugin: TablePluginData): void => {
                      if (tablePlugin.value) {
                        tablePlugin.element.innerText =
                          this.global.converterService.convertMoney(
                            tablePlugin.value
                          ) + ' %';
                      }
                    }
                  },
                  {
                    name: 'badge',
                    field: 'finalProfitLossStatus.name',
                    colorMap: {
                      SAVE: 'SUCCESS',
                      LOSS: 'DANGER'
                    },
                    pill: false,
                    colorField: 'finalProfitLossStatus.code',
                    className: 'badge-catalog ml-3'
                  }
                ]
              },
              {
                field: 'vendorName',
                header: 'table.column.resultAnalysis',
                plugins: [
                  {
                    name: 'hyperlink',
                    onClick: this.doSeeDetailsAnalysisSingle.bind(this),
                    customText: this.translateService.instant(
                      'vendor-reference.table.value.seeDetails'
                    )
                  }
                ]
              }
            ],
        { checkBoxFn: this.isShowCheckBox.bind(this) }
      );
    }

    this.tableResponse.setCustomData({ procurementId: this.procurementId });
  }

  public isShowCheckBox(data): boolean {
    if (
      ['VENDOR-REFERENCE', 'APPROVAL-VENDOR-REFERENCE'].includes(
        this.procurement.workflowModelPrcs.code
      )
    ) {
      const vendor: Vendor = data.procurementVendor
        ? data.procurementVendor.vendor
        : data.quotation.procurementVendor.vendor;

      if (
        data.isExpiredCertificate ||
        data.isExpiredDoc ||
        (vendor.vendorStatus.code !==
          this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
          vendor.vendorStatus.code !==
            this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) ||
        data.isCatalogContract
      ) {
        this.isMustFullFill = true;
        return false;
      } else {
        return true;
      }
    }
  }

  public buildTableResponseReNego(): void {
    if (this.todo === 'view') {
      this.tableResponseReNego = new TableResponseModel(
        this.moduleCode,
        this.procurement.biddingEvaluation.code !==
        this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
          ? [
              {
                field: '',
                header: '',
                className: 'text-center',
                plugins: this.customPlugin,
                isSortable: false
              },
              {
                field: 'vendorName',
                header: 'table.column.vendorName',
                plugins: [
                  {
                    name: 'hyperlink',
                    onClick: this.doViewFinalPriceDetail.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pic pi-exclamation-triangle',
                    onClick: this.onClickIconConsider.bind(this),
                    isShowIconFn: this.showIcon.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pis pi-exclamation-triangle text-danger',
                    onClick: this.onClickIconDanger.bind(this),
                    isShowIconFn: this.showIconDanger.bind(this)
                  }
                ]
              },
              {
                header: 'table.column.evaluation',
                columnList: [
                  {
                    field: 'administrationScoreStatus.name',
                    header: 'table.column.administration',
                    plugins: {
                      name: 'badge',
                      colorField: 'administrationScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  {
                    field: 'technicalScoreStatus.name',
                    header: 'table.column.technical',
                    plugins: {
                      name: 'badge',
                      colorField: 'technicalScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  this.procurement.biddingEvaluation.code ===
                  this.global.appConstant.pm
                    .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
                    ? {
                        field: 'finalPriceScoreStatus.name',
                        header: 'table.column.price',
                        plugins: {
                          name: 'badge',
                          colorField: 'finalPriceScoreStatus.code',
                          colorMap: {
                            PASSED: 'SUCCESS',
                            NOT_PASSED: 'DANGER'
                          }
                        }
                      }
                    : {
                        field: 'finalPriceScore',
                        header: 'table.column.price',
                        className: 'text-right',
                        plugins: {
                          name: 'text-field',
                          type: 'decimal',
                          isView: true
                        }
                      }
                ]
              },
              {
                field: 'finalPrice',
                header: 'table.column.finalPrice',
                plugins: {
                  name: 'default',
                  type: 'currency'
                },
                className: 'text-right'
              },
              {
                field: 'finalProfitLossAmount',
                header: 'table.column.savingLoss',
                className: 'text-right',
                plugins: [
                  {
                    name: 'custom-plugin',
                    before: (tablePlugin: TablePluginData): void => {
                      if (tablePlugin.value) {
                        tablePlugin.element.innerText =
                          tablePlugin.value + ' %';
                      }
                    }
                  },
                  {
                    name: 'badge',
                    field: 'finalProfitLossStatus.name',
                    colorMap: {
                      SAVE: 'SUCCESS',
                      LOSS: 'DANGER'
                    },
                    pill: false,
                    colorField: 'finalProfitLossStatus.code',
                    className: 'badge-catalog ml-3'
                  }
                ]
              }
            ]
          : [
              {
                field: '',
                header: '',
                className: 'text-center',
                plugins: this.customPlugin,
                isSortable: false
              },
              {
                field: 'vendorName',
                header: 'table.column.vendorName',
                plugins: [
                  {
                    name: 'hyperlink',
                    onClick: this.doViewFinalPriceDetail.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pic pi-exclamation-triangle',
                    onClick: this.onClickIconConsider.bind(this),
                    isShowIconFn: this.showIcon.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pis pi-exclamation-triangle text-danger',
                    onClick: this.onClickIconDanger.bind(this),
                    isShowIconFn: this.showIconDanger.bind(this)
                  }
                ]
              },
              {
                header: 'table.column.evaluation',
                columnList: [
                  {
                    field: 'administrationScoreStatus.name',
                    header: 'table.column.administration',
                    plugins: {
                      name: 'badge',
                      colorField: 'administrationScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  {
                    field: 'technicalScore',
                    header: {
                      key: 'table.column.technical',
                      plugins: {
                        name: 'custom-plugin',
                        after: (
                          tableHeaderPlugin: TableHeaderPluginData
                        ): void => {
                          if (tableHeaderPlugin) {
                            tableHeaderPlugin.element.innerText +=
                              ' (' +
                              (this.vendorReferenceResponse
                                .technicalPercentage || ' - ') +
                              '%)';
                          }
                        }
                      }
                    },
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  },
                  {
                    field: 'finalPriceScore',
                    header: {
                      key: 'table.column.price',
                      plugins: {
                        name: 'custom-plugin',
                        after: (
                          tableHeaderPlugin: TableHeaderPluginData
                        ): void => {
                          if (tableHeaderPlugin) {
                            tableHeaderPlugin.element.innerText +=
                              ' (' +
                              (this.vendorReferenceResponse.pricePercentage ||
                                ' - ') +
                              '%)';
                          }
                        }
                      }
                    },
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  },
                  {
                    field: 'totalFinalEvaluationScore',
                    header: 'table.column.total',
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  }
                ]
              },
              {
                field: 'finalPrice',
                header: 'table.column.finalPrice',
                plugins: {
                  name: 'default',
                  type: 'currency'
                },
                className: 'text-right'
              },
              {
                field: 'finalProfitLossAmount',
                header: 'table.column.savingLoss',
                className: 'text-right',
                plugins: [
                  {
                    name: 'custom-plugin',
                    before: (tablePlugin: TablePluginData): void => {
                      if (tablePlugin.value) {
                        tablePlugin.element.innerText =
                          this.global.converterService.convertMoney(
                            tablePlugin.value
                          ) + ' %';
                      }
                    }
                  },
                  {
                    name: 'badge',
                    field: 'finalProfitLossStatus.name',
                    colorMap: {
                      SAVE: 'SUCCESS',
                      LOSS: 'DANGER'
                    },
                    pill: false,
                    colorField: 'finalProfitLossStatus.code',
                    className: 'badge-catalog ml-3'
                  }
                ]
              }
            ]
      );
    } else {
      this.tableResponseReNego = new TableResponseModel(
        this.moduleCode,
        this.procurement.biddingEvaluation.code !==
        this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
          ? [
              {
                field: 'vendorName',
                header: 'table.column.vendorName',
                plugins: [
                  {
                    name: 'hyperlink',
                    onClick: this.doViewFinalPriceDetail.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pic pi-exclamation-triangle',
                    onClick: this.onClickIconConsider.bind(this),
                    isShowIconFn: this.showIcon.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pis pi-exclamation-triangle text-danger',
                    onClick: this.onClickIconDanger.bind(this),
                    isShowIconFn: this.showIconDanger.bind(this)
                  }
                ]
              },
              {
                header: 'table.column.evaluation',
                columnList: [
                  {
                    field: 'administrationScoreStatus.name',
                    header: 'table.column.administration',
                    plugins: {
                      name: 'badge',
                      colorField: 'administrationScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  {
                    field: 'technicalScoreStatus.name',
                    header: 'table.column.technical',
                    plugins: {
                      name: 'badge',
                      colorField: 'technicalScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  this.procurement.biddingEvaluation.code ===
                  this.global.appConstant.pm
                    .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
                    ? {
                        field: 'finalPriceScoreStatus.name',
                        header: 'table.column.price',
                        plugins: {
                          name: 'badge',
                          colorField: 'finalPriceScoreStatus.code',
                          colorMap: {
                            PASSED: 'SUCCESS',
                            NOT_PASSED: 'DANGER'
                          }
                        }
                      }
                    : {
                        field: 'finalPriceScore',
                        header: 'table.column.price',
                        className: 'text-right',
                        plugins: {
                          name: 'text-field',
                          type: 'decimal',
                          isView: true
                        }
                      }
                ]
              },
              {
                field: 'finalPrice',
                header: 'table.column.finalPrice',
                plugins: {
                  name: 'default',
                  type: 'currency'
                },
                className: 'text-right'
              },
              {
                field: 'finalProfitLossAmount',
                header: 'table.column.savingLoss',
                className: 'text-right',
                plugins: [
                  {
                    name: 'custom-plugin',
                    before: (tablePlugin: TablePluginData): void => {
                      if (tablePlugin.value) {
                        tablePlugin.element.innerText =
                          this.global.converterService.convertMoney(
                            tablePlugin.value
                          ) + ' %';
                      }
                    }
                  },
                  {
                    name: 'badge',
                    field: 'finalProfitLossStatus.name',
                    colorMap: {
                      SAVE: 'SUCCESS',
                      LOSS: 'DANGER'
                    },
                    pill: false,
                    colorField: 'finalProfitLossStatus.code',
                    className: 'badge-catalog ml-3'
                  }
                ]
              }
            ]
          : [
              {
                field: 'vendorName',
                header: 'table.column.vendorName',
                plugins: [
                  {
                    name: 'hyperlink',
                    onClick: this.doViewFinalPriceDetail.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pic pi-exclamation-triangle',
                    onClick: this.onClickIconConsider.bind(this),
                    isShowIconFn: this.showIcon.bind(this)
                  },
                  {
                    name: 'icon',
                    className: 'pis pi-exclamation-triangle text-danger',
                    onClick: this.onClickIconDanger.bind(this),
                    isShowIconFn: this.showIconDanger.bind(this)
                  }
                ]
              },
              {
                header: 'table.column.evaluation',
                columnList: [
                  {
                    field: 'administrationScoreStatus.name',
                    header: 'table.column.administration',
                    plugins: {
                      name: 'badge',
                      colorField: 'administrationScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  {
                    field: 'technicalScore',
                    header: {
                      key: 'table.column.technical',
                      plugins: {
                        name: 'custom-plugin',
                        after: (
                          tableHeaderPlugin: TableHeaderPluginData
                        ): void => {
                          if (tableHeaderPlugin) {
                            tableHeaderPlugin.element.innerText +=
                              ' (' +
                              (this.vendorReferenceResponse
                                .technicalPercentage || ' - ') +
                              '%)';
                          }
                        }
                      }
                    },
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  },
                  {
                    field: 'finalPriceScore',
                    header: {
                      key: 'table.column.price',
                      plugins: {
                        name: 'custom-plugin',
                        after: (
                          tableHeaderPlugin: TableHeaderPluginData
                        ): void => {
                          if (tableHeaderPlugin) {
                            tableHeaderPlugin.element.innerText +=
                              ' (' +
                              (this.vendorReferenceResponse.pricePercentage ||
                                ' - ') +
                              '%)';
                          }
                        }
                      }
                    },
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  },
                  {
                    field: 'totalFinalEvaluationScore',
                    header: 'table.column.total',
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  }
                ]
              },
              {
                field: 'finalPrice',
                header: 'table.column.finalPrice',
                plugins: {
                  name: 'default',
                  type: 'currency'
                },
                className: 'text-right'
              },
              {
                field: 'finalProfitLossAmount',
                header: 'table.column.savingLoss',
                className: 'text-right',
                plugins: [
                  {
                    name: 'custom-plugin',
                    before: (tablePlugin: TablePluginData): void => {
                      if (tablePlugin.value) {
                        tablePlugin.element.innerText =
                          this.global.converterService.convertMoney(
                            tablePlugin.value
                          ) + ' %';
                      }
                    }
                  },
                  {
                    name: 'badge',
                    field: 'finalProfitLossStatus.name',
                    colorMap: {
                      SAVE: 'SUCCESS',
                      LOSS: 'DANGER'
                    },
                    pill: false,
                    colorField: 'finalProfitLossStatus.code',
                    className: 'badge-catalog ml-3'
                  }
                ]
              }
            ]
      );
    }

    this.tableResponseReNego.setCustomData({
      procurementId: this.procurementId
    });
  }

  public buildTableQuotationItem(): void {
    this.tableResponseQuotationItem = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementItem.prItem.pr.code',
        header: 'table.column.prNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClickQuotationItem.bind(this)
        }
      },
      {
        field: 'procurementItem.prItem.item.code',
        header: 'table.column.itemCode'
      },
      {
        field: 'procurementItem.prItem.code',
        header: 'table.column.prLine'
      },
      {
        field: 'procurementItem.prItem.item.name',
        header: 'table.column.itemName'
      },
      {
        field: 'procurementItem.prItem.item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'procurementItem.prItem.item.itemType.code'
        }
      },
      {
        field: 'procurementItem.prItem.quantity',
        header: 'table.column.quantity',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'procurementItem.prItem.item.uom.name',
        header: 'table.column.uom'
      },
      {
        field: 'procurementItem.isAllocated',
        header: 'table.column.itemStatus',
        plugins: {
          name: 'badge',
          colorField: 'procurementItem.isAllocated',
          colorMap: {
            true: 'SUCCESS',
            false: 'WARNING'
          },
          callbacks: {
            text: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.value) {
                return 'Allocated';
              } else {
                return 'Not Allocated';
              }
            },
            color: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.value) {
                return 'true';
              } else {
                return 'false';
              }
            }
          }
        }
      },
      {
        field: 'procurementItem.prItem.pr.code',
        header: 'table.column.resultAnalysis',
        plugins: [
          {
            name: 'hyperlink',
            onClick: this.doSeeDetailsAnalysisMultiple.bind(this),
            customText: this.translateService.instant(
              'vendor-reference.table.value.seeDetails'
            )
          }
        ]
      }
    ]);
    this.tableResponseQuotationItemReNego = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'procurementItem.prItem.pr.code',
          header: 'table.column.prNumber',
          plugins: {
            name: 'hyperlink',
            onClick: this.doClickQuotationItemReNego.bind(this)
          }
        },
        {
          field: 'procurementItem.prItem.item.code',
          header: 'table.column.itemCode'
        },
        {
          field: 'procurementItem.prItem.code',
          header: 'table.column.prLine'
        },
        {
          field: 'procurementItem.prItem.item.name',
          header: 'table.column.itemName'
        },
        {
          field: 'procurementItem.prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'procurementItem.prItem.item.itemType.code'
          }
        },
        {
          field: 'procurementItem.prItem.quantity',
          header: 'table.column.quantity',
          className: 'text-right',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            isView: true
          }
        },
        {
          field: 'procurementItem.prItem.item.uom.name',
          header: 'table.column.uom'
        },
        {
          field: 'procurementItem.isAllocated',
          header: 'table.column.itemStatus',
          plugins: {
            name: 'badge',
            colorField: 'procurementItem.isAllocated',
            colorMap: {
              true: 'SUCCESS',
              false: 'WARNING'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.value) {
                  return 'Allocated';
                } else {
                  return 'Not Allocated';
                }
              },
              color: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.value) {
                  return 'true';
                } else {
                  return 'false';
                }
              }
            }
          }
        }
      ]
    );
  }

  public buildTableQuotationVendor(): void {
    this.tableResponseQuotationVendor = new TableResponseModel(
      this.moduleCode,
      this.procurement.biddingEvaluation.code !==
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
        ? [
            {
              field: 'quotation.procurementVendor.vendor.name',
              header: 'table.column.vendorName',
              plugins: [
                {
                  name: 'hyperlink',
                  onClick: this.doClickQuotationVendor.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pic pi-exclamation-triangle',
                  onClick: this.onClickIconConsider.bind(this),
                  isShowIconFn: this.showIcon.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pis pi-exclamation-triangle text-danger',
                  onClick: this.onClickIconDanger.bind(this),
                  isShowIconFn: this.showIconDanger.bind(this)
                }
              ]
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScoreStatus.name',
                  header: 'table.column.technical',
                  plugins: {
                    name: 'badge',
                    colorField: 'technicalScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                }
              ]
            },
            {
              header: 'table.column.selectedItem',
              columnList: [
                {
                  field: 'goodsSelectedItem',
                  header: 'table.column.goods',
                  className: 'text-center'
                },
                {
                  field: 'serviceSelectedItem',
                  header: 'table.column.service',
                  className: 'text-center'
                }
              ]
            }
          ]
        : [
            {
              field: 'quotation.procurementVendor.vendor.name',
              header: 'table.column.vendorName',
              plugins: [
                {
                  name: 'hyperlink',
                  onClick: this.doClickQuotationVendor.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pic pi-exclamation-triangle',
                  onClick: this.onClickIconConsider.bind(this),
                  isShowIconFn: this.showIcon.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pis pi-exclamation-triangle text-danger',
                  onClick: this.onClickIconDanger.bind(this),
                  isShowIconFn: this.showIconDanger.bind(this)
                }
              ]
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScore',
                  header: {
                    key: 'table.column.technical',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' +
                            (this.vendorReferenceResponse.technicalPercentage ||
                              ' - ') +
                            '%)';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                }
              ]
            },
            {
              header: 'table.column.selectedItem',
              columnList: [
                {
                  field: 'goodsSelectedItem',
                  header: 'table.column.goods',
                  className: 'text-center'
                },
                {
                  field: 'serviceSelectedItem',
                  header: 'table.column.service',
                  className: 'text-center'
                }
              ]
            }
          ]
    );
    this.tableResponseQuotationVendorReNego = new TableResponseModel(
      this.moduleCode,
      this.procurement.biddingEvaluation.code !==
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
        ? [
            {
              field: 'quotation.procurementVendor.vendor.name',
              header: 'table.column.vendorName',
              plugins: [
                {
                  name: 'hyperlink',
                  onClick: this.doClickQuotationVendor.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pic pi-exclamation-triangle',
                  onClick: this.onClickIconConsider.bind(this),
                  isShowIconFn: this.showIcon.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pis pi-exclamation-triangle text-danger',
                  onClick: this.onClickIconDanger.bind(this),
                  isShowIconFn: this.showIconDanger.bind(this)
                }
              ]
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScoreStatus.name',
                  header: 'table.column.technical',
                  plugins: {
                    name: 'badge',
                    colorField: 'technicalScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                }
              ]
            },
            {
              header: 'table.column.selectedItem',
              columnList: [
                {
                  field: 'goodsSelectedItem',
                  header: 'table.column.goods',
                  className: 'text-center'
                },
                {
                  field: 'serviceSelectedItem',
                  header: 'table.column.service',
                  className: 'text-center'
                }
              ]
            }
          ]
        : [
            {
              field: 'quotation.procurementVendor.vendor.name',
              header: 'table.column.vendorName',
              plugins: [
                {
                  name: 'hyperlink',
                  onClick: this.doClickQuotationVendor.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pic pi-exclamation-triangle',
                  onClick: this.onClickIconConsider.bind(this),
                  isShowIconFn: this.showIcon.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pis pi-exclamation-triangle text-danger',
                  onClick: this.onClickIconDanger.bind(this),
                  isShowIconFn: this.showIconDanger.bind(this)
                }
              ]
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScore',
                  header: {
                    key: 'table.column.technical',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' +
                            (this.vendorReferenceResponse.technicalPercentage ||
                              ' - ') +
                            '%)';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                }
              ]
            },
            {
              header: 'table.column.selectedItem',
              columnList: [
                {
                  field: 'goodsSelectedItem',
                  header: 'table.column.goods',
                  className: 'text-center'
                },
                {
                  field: 'serviceSelectedItem',
                  header: 'table.column.service',
                  className: 'text-center'
                }
              ]
            }
          ]
    );
    this.tableResponseQuotationVendor.setCustomData({
      procurementId: this.procurementId
    });
    this.tableResponseQuotationVendorReNego.setCustomData({
      procurementId: this.procurementId
    });
  }

  public onClickIconConsider(data): void {
    const vendorName = data.vendorName
      ? data.vendorName
      : data.quotation.procurementVendor.vendor.name;
    let msg = '<ol class="text-left ml-4">';
    if (data.sameCompanyOrgVendorName) {
      let vendorNameList: string[] = data.sameCompanyOrgVendorName.split(',');
      vendorNameList = [...new Set(vendorNameList)];

      msg +=
        '<li>' +
        vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.sameCompanyOrg'
        ) +
        ' ' +
        vendorNameList.toString().replace('[', '').replace(']', '') +
        '</li>';
    }
    if (!data.isFinancialReport) {
      msg +=
        '<li>' +
        vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isFinancialReport'
        ) +
        '</li>';
    }
    if (data.isBlacklist) {
      msg +=
        '<li>' +
        vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isBlacklist'
        ) +
        '</li>';
    }
    if (data.isCertificateWillBeExpired) {
      msg +=
        '<li>' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.certificate'
        ) +
        ' ' +
        vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isCertificateWillBeExpired'
        ) +
        '</li>';
    }

    if (data.isDer) {
      msg +=
        '<li>' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.derRatio'
        ) +
        ' ' +
        vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isDer'
        ) +
        '</li>';
    }

    if (data.isCatalogContract) {
      msg +=
        '<li>' +
        vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isCatalogContract'
        ) +
        ' ' +
        data.catalogName +
        '</li>';
    }

    const title = 'vendor-reference.popup.warning.thingsToConsider.title';
    this.doShowPopup(msg, title);
  }

  public showIcon(data): boolean {
    if (
      ['VENDOR-REFERENCE', 'APPROVAL-VENDOR-REFERENCE'].includes(
        this.procurement.workflowModelPrcs.code
      )
    ) {
      const vendor: Vendor = data.procurementVendor
        ? data.procurementVendor.vendor
        : data.quotation.procurementVendor.vendor;

      if (
        !(
          data.isExpiredCertificate ||
          data.isExpiredDoc ||
          (vendor.vendorStatus.code !==
            this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
            vendor.vendorStatus.code !==
              this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) ||
          data.isCatalogContract
        ) &&
        (data.sameCompanyOrgVendorName ||
          !data.isFinancialReport ||
          data.isBlacklist ||
          data.isExpiredDoc ||
          data.isCertificateWillBeExpired ||
          data.isDer ||
          data.isCatalogContract)
      ) {
        this.isThingsToConsider = true;
        return true;
      } else {
        return false;
      }
    }
  }

  public showIconDanger(data): boolean {
    if (
      ['VENDOR-REFERENCE', 'APPROVAL-VENDOR-REFERENCE'].includes(
        this.procurement.workflowModelPrcs.code
      )
    ) {
      const vendor: Vendor = data.procurementVendor
        ? data.procurementVendor.vendor
        : data.quotation.procurementVendor.vendor;

      if (
        data.isExpiredCertificate ||
        data.isExpiredDoc ||
        (vendor.vendorStatus.code !==
          this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
          vendor.vendorStatus.code !==
            this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) ||
        data.isCatalogContract
      ) {
        this.isMustFullFill = true;
        return true;
      } else {
        return false;
      }
    }
  }

  public onClickIconDanger(data): void {
    const vendor: Vendor = data.procurementVendor
      ? data.procurementVendor.vendor
      : data.quotation.procurementVendor.vendor;
    let msg = '';
    if (
      vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
      vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST
    ) {
      msg +=
        '<li>' +
        vendor.name +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isNotActive'
        ) +
        '</li>';
    }

    if (data.isExpiredCertificate) {
      msg +=
        '<li>' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.certificate'
        ) +
        ' ' +
        vendor.name +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isExpiredCertificate'
        ) +
        '</li>';
    }

    if (data.isExpiredDoc) {
      msg +=
        '<li>' +
        vendor.name +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isExpiredDoc'
        ) +
        '</li>';
    }

    if (data.isCatalogContract) {
      msg +=
        '<li>' +
        vendor.name +
        ' ' +
        this.global.translateService.instant(
          'vendor-reference.popup.warning.msg.isCatalogContract'
        ) +
        ' ' +
        data.catalogName +
        '</li>';
    }
    const title = 'vendor-reference.popup.warning.mustFullFill.title';
    this.doShowPopup(msg, title);
  }

  public doShowPopup(msg: string, title: string): void {
    this.global.modalService.confirmation(
      msg,
      title,
      null,
      null,
      'pvc pv-exclamation-triangle',
      true
    );
  }

  public doOnChangeTableResponse(): void {
    this.isOfficialReportValid = false;
  }

  public doViewFinalPriceDetail(
    vendorQuotationView: VendorQuotationView
  ): void {
    this.appPopupFinalPriceDetailService.open(
      vendorQuotationView.procurementVendor.id,
      this.procurement.biddingEvaluation,
      this.procurement.vendorSelectionType,
      this.moduleCode
    );
  }

  public doClickQuotationItem(event): void {
    const procurementItemId = event.procurementItem.id;
    const todo =
      this.vendorReferenceResponse.isUserApproval ||
      this.todo === 'view' ||
      this.procurement.procurementWorklistStatus.code ===
        this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION
        ? 'view'
        : 'edit';
    const isShowChangeWinningVendor =
      this.vendorReferenceResponse.isUserApproval &&
      !this.vendorReferenceResponse.isUserApprovalViewOnly
        ? true
        : false;
    const isReferenceNego =
      this.procurement.procurementWorklistStatus.code ===
      this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION
        ? true
        : false;

    this.appPopupService
      .open(
        VendorReferencePopupChooseVendorMultiWinnerComponent,
        { procurementItemId, todo, isShowChangeWinningVendor, isReferenceNego },
        {
          size: 'xl'
        }
      )
      .subscribe(response => {
        this.quotationItemReferenceList =
          response.quotationItemReferenceFinalList;

        this.todoOfficialReport = 'edit';
        this.formGroup.get('isReportValid').patchValue(null);

        if (response?.quotationItemReferenceFinalList?.length > 0) {
          let count = 0;
          this.tableResponseQuotationItem.getRecordList().forEach(element => {
            if (element.procurementItem.id === event.procurementItem.id) {
              element.procurementItem.isAllocated = true;
            }
            if (element.procurementItem.isAllocated) {
              count += 1;
            }
          });
          this.tableResponseQuotationItem.reload();
          this.getVendorQuotation(false);
          if (
            count === this.tableResponseQuotationItem.getRecordList().length
          ) {
            this.isInvalidAllocatedItem = false;
          }
        }

        this.isOfficialReportValid = false;
        this.global.modalService.confirmation(
          this.translateService.instant(
            'vendor-reference.modal.saveSuccess.msg'
          ),
          'vendor-reference.modal.saveSuccess.title',
          null,
          null,
          'pvc pv-check-circle',
          true,
          {
            autoCloseInMs: 1000
          }
        );
      });
  }

  public doClickQuotationItemReNego(event): void {
    const procurementItemId = event.procurementItem.id;
    const todo = 'edit';
    const isShowChangeWinningVendor = false;
    const isFinalNego = true;

    this.appPopupService
      .open(
        VendorReferencePopupChooseVendorMultiWinnerComponent,
        { procurementItemId, todo, isShowChangeWinningVendor, isFinalNego },
        {
          size: 'xl'
        }
      )
      .subscribe(response => {
        this.quotationItemReferenceList =
          response.quotationItemReferenceFinalList;
        this.todoOfficialReport = 'edit';
        this.formGroup.get('isReportValid').patchValue(null);

        if (response?.quotationItemReferenceFinalList?.length > 0) {
          this.tableResponseQuotationItemReNego
            .getRecordList()
            .forEach(element => {
              if (element.procurementItem.id === event.procurementItem.id) {
                element.procurementItem.isAllocated = true;
              }
            });
          this.tableResponseQuotationItemReNego.reload();
          this.getVendorQuotation(true);
        }
        this.isOfficialReportValid = false;
        this.global.modalService.confirmation(
          this.translateService.instant(
            'vendor-reference.modal.saveSuccess.msg'
          ),
          'vendor-reference.modal.saveSuccess.title',
          null,
          null,
          'pvc pv-check-circle',
          true,
          {
            autoCloseInMs: 1000
          }
        );
      });
  }

  public doClickQuotationVendor(event): void {
    const quotationItemReferenceList = this.quotationItemReferenceList.filter(
      x =>
        x.quotationItem.quotation.id === event.quotation.id &&
        x.quotationItem.quotation.isReference === true
    );
    this.appPopupAllocatedItemDetail.open(
      event.quotation.procurementVendor.id,
      quotationItemReferenceList
    );
  }

  public doChangeReferenceVendor(): void {
    const procurement = this.procurement;
    const vendorReferenceResponse = this.vendorReferenceResponse;
    const vendorQuotationView = this.vendorQuotationView;
    this.appPopupService
      .open(
        VendorReferencePopupChangeWinningVendorComponent,
        { procurement, vendorReferenceResponse, vendorQuotationView },
        {
          size: 'xl'
        }
      )
      .subscribe(response => {
        this.formGroup.get('isReportValid').patchValue(null);
        this.todoOfficialReport = 'edit';
        if (
          this.procurement.priceEvaluationMethod.code.includes(
            'DEAL_WITH_THIS_PRICE'
          )
        ) {
          this.vendorReferenceResponse.vendorQuotationViewList.forEach(
            vendorQuotationView => {
              if (
                vendorQuotationView.procurementVendor.id ===
                response[0].procurementVendor.id
              ) {
                vendorQuotationView.isReference = true;
              } else {
                vendorQuotationView.isReference = false;
              }
            }
          );
          this.tableResponse.setRecordList(
            this.vendorReferenceResponse.vendorQuotationViewList
          );
        } else {
          this.vendorReferenceResponse.vendorQuotationFinalViewList.forEach(
            vendorQuotationView => {
              if (
                vendorQuotationView.procurementVendor.id ===
                response[0].procurementVendor.id
              ) {
                vendorQuotationView.isReference = true;
              } else {
                vendorQuotationView.isReference = false;
              }
            }
          );
          this.tableResponse.setRecordList(
            this.vendorReferenceResponse.vendorQuotationFinalViewList
          );
        }
        this.tableResponse.reload();
        this.global.modalService.confirmation(
          this.translateService.instant(
            'vendor-reference.modal.saveSuccess.msg'
          ),
          'vendor-reference.modal.saveSuccess.title',
          null,
          null,
          'pvc pv-check-circle',
          true,
          {
            autoCloseInMs: 1000
          }
        );
      });
    this.isOfficialReportValid = false;
    this.formGroup.invalid;
  }

  public doOnChange(event: AppOfficialReportModel): void {
    let isAnyValue = false;
    event.fileObjectList.forEach(fileObject => {
      if (!fileObject.isDeleted) {
        isAnyValue = true;
      }
    });
    if (
      event.letterDate &&
      event.letterNumber &&
      event.fileObjectList &&
      isAnyValue &&
      event.isGenerate
    ) {
      if (
        this.global.routerParams.get('isChangeWinner') !== null &&
        this.global.routerParams.get('isChangeWinner') !== undefined
      ) {
        this.isOfficialReportValid =
          this.global.routerParams.get('isChangeWinner');
        this.global.routerParams.set('isChangeWinner', null);
      } else {
        this.isOfficialReportValid = true;
        this.formGroup.valid;
      }
      this.formGroup.get('isReportValid').patchValue(true);
    }

    if (
      new Date(event.letterDate).getTime() !==
        new Date(
          this.vendorReferenceResponse.appOfficialReportModel.letterDate
        ).getTime() ||
      event.letterNumber !==
        this.vendorReferenceResponse.appOfficialReportModel.letterNumber
    ) {
      this.isOfficialReportValid = false;
      this.formGroup.invalid;
    }

    this.vendorReferenceResponse.appOfficialReportModel = event;
  }

  public approveFn(approveStatus: boolean): void {
    if (approveStatus) {
      const request = new VendorReferenceRequest();
      request.isSubmit = false;
      request.isProcurementFailed = false;
      request.procurement = this.procurement;
      request.appOfficialReportModel =
        this.vendorReferenceResponse.appOfficialReportModel;

      const url = '/vendor-reference/update';
      this.httpClientService
        .post<Response<QuotationItemReference>>(url, request)
        .subscribe(response => {
          if (response.status !== ResponseStatusModel.OK) {
            this.global.alertService.showError(response.statusText);
          }
        });
    }
  }

  public doViewDetail(): void {
    window.location.href =
      this.global.config.parameterModel.promiseAuctionAddressUrl +
      '/initiation/form/' +
      this.procurement.promiseAuctionCode;
  }

  public doSeeDetailsAnalysisSingle(
    vendorQuotationView: VendorQuotationView
  ): void {
    this.appPopupAnalysisSingleService.open(
      vendorQuotationView.procurementVendor.id,
      this.moduleCode
    );
  }

  public doSeeDetailsAnalysisMultiple(
    vendorQuotationByItemView: VendorQuotationByItemView
  ): void {
    this.appPopupAnalysisMultipleService.open(
      vendorQuotationByItemView.procurementItem.procurement.id,
      vendorQuotationByItemView.procurementItem.prItem.id,
      this.moduleCode
    );
  }

  public doSave(): void {
    this.isInvalidAllocatedItem = false;
    this.isInvalidChooseVendorSingleWinner = false;
    if (
      this.procurement.vendorSelectionType.code ===
        this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER &&
      ((this.procurement.procurementWorklistStatus.code !==
        this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION &&
        this.tableResponse.selectedRow?.getSelectedRecordList()?.length > 0) ||
        (this.procurement.procurementWorklistStatus.code ===
          this.global.appConstant.pm
            .PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION &&
          this.tableResponseReNego.selectedRow?.getSelectedRecordList()
            ?.length > 0))
    ) {
      this.isInvalidChooseVendorSingleWinner = true;
    }
    const request = new VendorReferenceRequest();
    request.isSubmit = false;
    request.isProcurementFailed = false;
    request.procurement = this.procurement;
    request.appOfficialReportModel =
      this.vendorReferenceResponse.appOfficialReportModel;
    if (
      this.procurement.vendorSelectionType.code ===
      this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
    ) {
      if (
        this.procurement.procurementWorklistStatus.code ===
        this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION
      ) {
        request.procurementVendor =
          this.tableResponseReNego.selectedRow?.getSelectedRecordList()[0]?.procurementVendor;
      } else {
        request.procurementVendor =
          this.tableResponse.selectedRow?.getSelectedRecordList()[0]?.procurementVendor;
      }
    }
    this.setStateProcessing();
    const url =
      this.todo === 'add'
        ? '/vendor-reference/insert'
        : '/vendor-reference/update';
    this.httpClientService
      .post<Response<QuotationItemReference>>(url, request)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.snackbarService.showWarning('app.msg.info.sucsessSave');
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.global.routerParams.clear();
              this.global.routerParams.set('procurement', response.body);
              this.global.routerParams.set('procurementId', response.body.id);
              this.global.routerParams.set(
                'urlBackOutside',
                '/pages/worklist-pm'
              );

              this.global.routerParams.set('todo', 'edit');
              this.global.routerParams.set('isChangeWinner', false);
              this.router.navigate(['/pages/vendor-reference/edit']);
            });
        }
        this.setStateReady();
      });
  }

  public doSubmit(): void {
    this.isInvalidAllocatedItem = false;
    this.isInvalidChooseVendorSingleWinner = false;
    if (
      this.procurement.vendorSelectionType.code ===
        this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER &&
      ((this.procurement.procurementWorklistStatus.code !==
        this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION &&
        this.tableResponse.selectedRow?.getSelectedRecordList()?.length ===
          0) ||
        (this.procurement.procurementWorklistStatus.code ===
          this.global.appConstant.pm
            .PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION &&
          this.tableResponseReNego.selectedRow?.getSelectedRecordList()
            ?.length === 0))
    ) {
      this.isInvalidChooseVendorSingleWinner = true;
    }
    if (
      this.procurement.vendorSelectionType.code ===
      this.global.appConstant.pm.VENDOR_SELECTION_TYPE_MULTI_WINNER
    ) {
      this.tableResponseQuotationItem.getRecordList().forEach(element => {
        if (!element.procurementItem.isAllocated) {
          this.isInvalidAllocatedItem = true;
        }
      });
    }
    let isEndedReNego = true;
    if (
      (this.procurement.procurementWorklistStatus.code ===
        this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION &&
        !this.vendorReferenceResponse
          .isHasFinalReNego) /* Uncoment Jika sudah ada penyesuaian di auction */ ||
      (this.vendorReferenceResponse.isEndedReNego !== null &&
        !this.vendorReferenceResponse.isEndedReNego)
    ) {
      this.global.modalService.confirmation(
        'vendor-reference.confirmation.finalOnProgress.prompt',
        'vendor-reference.confirmation.finalOnProgress.title',
        null,
        null,
        'pvc pv-exclamation-circle',
        true
      );
      isEndedReNego = false;
    }

    if (
      isEndedReNego &&
      this.isOfficialReportValid &&
      !this.isInvalidChooseVendorSingleWinner &&
      !this.isInvalidAllocatedItem
    ) {
      const request = new VendorReferenceRequest();
      request.isSubmit = true;
      request.isProcurementFailed = false;
      request.procurement = this.procurement;
      request.quotationItemReferenceList = this.quotationItemReferenceList;
      request.appOfficialReportModel =
        this.vendorReferenceResponse.appOfficialReportModel;
      let vendorWinnerList = [];
      let procurementItemList = null;
      let quotationItemList = null;
      const vendorSelectionTypeCode = this.procurement.vendorSelectionType.code;
      if (
        this.procurement.vendorSelectionType.code ===
        this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
      ) {
        if (
          this.procurement.procurementWorklistStatus.code ===
          this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION
        ) {
          request.procurementVendor =
            this.tableResponseReNego.selectedRow?.getSelectedRecordList()[0]?.procurementVendor;
          vendorWinnerList =
            this.tableResponseReNego.selectedRow?.getSelectedRecordList();
        } else {
          request.procurementVendor =
            this.tableResponse.selectedRow?.getSelectedRecordList()[0]?.procurementVendor;
          vendorWinnerList =
            this.tableResponse.selectedRow?.getSelectedRecordList();
        }
        procurementItemList = this.vendorReferenceResponse.procurementItemList;
      } else {
        if (
          this.procurement.procurementWorklistStatus.code ===
          this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION
        ) {
          vendorWinnerList = this.tableResponseQuotationVendorReNego
            .getRecordList()
            .filter(x => x.quotation.isReference === true);
          const quotItemWinnerList = this.quotationItemReferenceList.filter(
            x =>
              x.id &&
              x.quotationItem.quotation.isReference === true &&
              x.tempQuantityNego !== null &&
              x.tempQuantityNego > 0
          );
          quotationItemList = (
            quotItemWinnerList as Array<QuotationItemReference>
          ).map(quotItemRef => quotItemRef.quotationItem);
        } else {
          vendorWinnerList = this.tableResponseQuotationVendor
            .getRecordList()
            .filter(x => x.quotation.isReference === true);
          const quotItemWinnerList = this.quotationItemReferenceList.filter(
            x => x.id && x.quotationItem.quotation.isReference === true
          );
          quotationItemList = (
            quotItemWinnerList as Array<QuotationItemReference>
          ).map(quotItemRef => quotItemRef.quotationItem);
        }
      }

      this.appPopupService
        .open(
          VendorReferencePopupConfirmComponent,
          {
            vendorWinnerList,
            procurementItemList,
            quotationItemList,
            vendorSelectionTypeCode
          },
          {
            size: 'SM'
          }
        )
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const url =
              this.todo === 'add'
                ? '/vendor-reference/insert'
                : '/vendor-reference/update';
            this.httpClientService
              .post<Response<QuotationItemReference>>(url, request)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService.submitSuccess().subscribe(result => {
                    if (result) {
                      this.router.navigate(['/pages/worklist-pm']);
                    } else {
                      this.router
                        .navigateByUrl('/', { skipLocationChange: true })
                        .then(() => {
                          this.global.routerParams.clear();
                          this.global.routerParams.set(
                            'procurement',
                            response.body
                          );
                          this.global.routerParams.set(
                            'procurementId',
                            response.body.id
                          );
                          this.global.routerParams.set(
                            'urlBackOutside',
                            '/pages/worklist-pm'
                          );

                          this.global.routerParams.set('todo', 'view');
                          this.router.navigate([
                            '/pages/vendor-reference/detail'
                          ]);
                        });
                    }
                  });
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }
}
