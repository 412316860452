import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupContractComponent } from './app-popup-contract.component';
@Injectable()
export class AppPopupContractService {
  constructor(public appPopupService: AppPopupService) {}
  public open(contractIdList?: number[]): Observable<any> {
    return this.appPopupService.open(AppPopupContractComponent, {
      contractIdList
    });
  }
}
