import { BaseEntity } from '../base/base-entity';
import { ProcurementRegType } from '../bean/procurement-reg-type';
import { TransactionType } from '../bean/transaction-type';
import { TranslationKey } from '../bean/translation-key';

export class ProcurementMethodEntity extends BaseEntity {
  name: string;
  description: string;
  translationKey: TranslationKey;
  transactionType: TransactionType;
  procurementRegType: ProcurementRegType;
}
