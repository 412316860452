import { EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { OptionListModel } from '../../../model/option-list-model';
import { Validators } from '../../../validators';
import { FileUploader } from '../../upload/model/v1/file.uploader';
export class FormObjectModel<T> {
  public data: T[];
  public formGroup: FormGroup = new FormGroup({});
  public responseValueChanges: EventEmitter<any> = new EventEmitter();
  public formObjectList: Array<any> = [];
  public mapperFileUploader: Map<string, FileUploader> = new Map();
  public mapperOptionsList: Map<string, OptionListModel<any>> = new Map();
  public isView: boolean;
  public static create(data: any): FormObjectModel<any> {
    return new FormObjectModel(data);
  }

  constructor(data: T[]) {
    this.data = data;
  }

  public getRequestValues(): T[] {
    return this.data;
  }

  public buildFormObjectList(): this {
    this.data.forEach((model: any, index: number) => {
      model.catalogAttribute.formObject.value = null;
      if (model.catalogAttribute.listOfValue) {
        const optionList = new OptionListModel(false);
        optionList.setRequestValues(model.catalogAttribute.listOfValue || []);
        this.mapperOptionsList.set('optionList' + index, optionList);
      }

      if (model.catalogAttribute.formObject.code === 'UPLOAD-SINGLE') {
        const fileUploader: FileUploader = new FileUploader(
          '/employee/',
          'employee.form.headerImage',
          'DOC_EMPLOYEE_ALL'
        );
        this.mapperFileUploader.set('fileUploader' + index, fileUploader);
      }
    });
    return this;
  }

  public buildFormGroup(formBuilder: FormBuilder): this {
    const formGroupObject = {};
    this.data.forEach((data: any, index: number) => {
      Object.assign(formGroupObject, {
        ['generated' + index.toString()]: [null, this.validatorBuilder(data)]
      });
    });
    this.formGroup = formBuilder.group(formGroupObject);
    this.formGroup.setIsView(this.isView);
    return this;
  }

  public validatorBuilder(data: any): ValidatorFn {
    if (
      data.catalogAttribute.function.includes('Mandatory') &&
      data.catalogAttribute.formObject.code === 'CHECK-BOX'
    ) {
      return Validators.compose([Validators.isChecked()]);
    } else if (data.catalogAttribute.formObject.code === 'TOGGLE-SWITCH') {
      return null;
    } else if (data.catalogAttribute.function.includes('Mandatory')) {
      return Validators.compose([Validators.required()]);
    } else {
      return null;
    }
  }

  public handleFormGroupValueChanges(): void {
    this.formGroup.valueChanges.subscribe(() => {
      this.data.forEach((model: any, index: number) => {
        model.catalogAttribute.formObject.value = this.formGroup.get(
          'generated' + index
        ).value;
      });
      this.responseValueChanges.emit(this.data);
    });
  }

  public setFormGroup(): this {
    this.data.forEach((data: any, index: number) => {
      const formControl = this.formGroup.get('generated' + index.toString());
      const formObjectCode = data.catalogAttribute.formObject.code;
      if (formObjectCode === 'CHECK-BOX') {
        formControl.patchValue(
          (data.value && JSON.parse(data.value)) ||
            data.catalogAttribute.listOfValue
        );
      } else if (formObjectCode === 'DATE-PICKER') {
        formControl.patchValue(data.value && new Date(JSON.parse(data.value)));
      } else {
        formControl.patchValue((data.value && JSON.parse(data.value)) || null);
      }
    });
    return this;
  }

  public setIsView(isView: boolean): this {
    this.isView = isView;
    return this;
  }
}
