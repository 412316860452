import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Module } from 'src/app/core/bean/module';
import { TranslationKey } from 'src/app/core/bean/translation-key';
import { Vendor } from 'src/app/core/bean/vendor';
import { VendorAdditionalDocHistory } from 'src/app/core/bean/vendor-additional-doc-history';
import { VendorAddressHistory } from 'src/app/core/bean/vendor-address-history';
import { VendorDocHistory } from 'src/app/core/bean/vendor-doc-history';
import { VendorDocMaster } from 'src/app/core/bean/vendor-doc-master';
import { VendorHistory } from 'src/app/core/bean/vendor-history';
import { VendorIndustryHistory } from 'src/app/core/bean/vendor-industry-history';
import { VendorProductLocationHistory } from 'src/app/core/bean/vendor-product-location-history';
import { VendorProductServiceCategoryHistory } from 'src/app/core/bean/vendor-product-service-category-history';
import { VendorTab } from 'src/app/core/bean/vendor-tab';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { TabModel } from 'src/app/core/model/tab/tab-model';
import { TabResponseModel } from 'src/app/core/model/tab/tab-response-model';
import { AppPopupDelegationComponent } from '../tab-profile-verification/popup/app-popup-delegation.component';
import { CompanyProfileServie } from './company-profile.service';
import { CompanyProfileDTO } from './dto/company-profile.dto';
@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanyProfileComponent extends BaseModuleComponent {
  public companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public tabResponse: TabResponseModel;
  public vendorHistory: VendorHistory = new VendorHistory();
  public vendorAddressHistory: VendorAddressHistory =
    new VendorAddressHistory();
  public vendorHistoryId: number;
  public urlBack: string;
  public isFromVendorList: boolean;
  public vendorId: number;
  public vendorStatus: string;
  public isView: boolean;
  public isSubmit: boolean;
  public header: string;
  public info: string;
  public id: number;
  public style: string;
  public tabInvalidList: TabModel[] = [];
  public tabDoc: TabModel;
  public tabSegmentation: TabModel;
  public dataMarketing: any = null;
  public isAnyMainAddress = false;
  public isValid = true;
  public next = true;
  public isMandatoryFCSIUJK = false;
  public isMandatoryFCIUPJTK = false;
  public isAllowed: boolean;
  public isShowButtonDelegation = false;
  public isFromVendor: boolean;
  public roleType: number;
  public activeTabCode: string;
  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public companyProfileService: CompanyProfileServie
  ) {
    super('company-profile', translate);
  }

  public onInit(): void {
    this.isAllowed = true;
    this.roleType = this.global.userSession.activeUserRole.role.type;
    this.urlBack =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    if (
      !this.urlBack &&
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR
    ) {
      this.isAllowed = false;
      this.global.alertService.showErrorOnNextRoute(
        this.translateService.instant('company-profile.msg.error.access')
      );
      window.history.go(-1);
    } else {
      this.setDataFromRouterParams();
      this.buildTabResponse();
    }
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.vendorHistoryId = this.global.routerParams.get('vendorHistoryId');
    this.urlBack =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.isFromVendorList =
      localStorage.getItem('isVendorList') ||
      this.global.routerParams.get('isVendorList');
    this.vendorId =
      localStorage.getItem('vendorId') ||
      this.global.routerParams.get('vendorId');
    this.vendorStatus = this.global.routerParams.get('vendorStatus');
    this.isFromVendor = this.global.routerParams.get('isFromVendor');
    this.activeTabCode = this.global.routerParams.get('activeTabCode');
    this.global.routerParams.delete('activeTabCode');

    localStorage.removeItem('vendorId');
    localStorage.removeItem('urlBackOutside');
    localStorage.removeItem('todo');
    localStorage.removeItem('isVendorList');
  }

  public buildTabResponse(): void {
    if (
      this.global.userSession &&
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR
    ) {
      this.isView = true;
    }
    this.header = this.translateService.instant('company-profile.title');
    this.info = this.translateService.instant(
      'company-profile.information.new'
    );
    this.style = 'class="company-profile-card-information-new"';
    if (
      typeof this.urlBack !== 'undefined' &&
      this.vendorHistoryId &&
      this.global.userSession &&
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR &&
      (this.urlBack === '/pages/on-boarding' ||
        this.urlBack === '/pages/approval-prcs')
    ) {
      this.id = this.vendorHistoryId;
      this.todo = 'crud-info';
      this.global.routerParams.set('todo', this.todo);
    } else if (
      this.urlBack !== undefined &&
      this.global.userSession &&
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR
    ) {
      this.id = this.vendorId;
      this.todo = 'view-vendor';
      this.global.routerParams.set('todo', this.todo);
    }

    this.httpClientService
      .get<CompanyProfileDTO>(
        this.id
          ? '/company-profile/index?id=' + this.id + '&todo=' + this.todo
          : '/company-profile/index'
      )
      .subscribe(companyProfileDTO => {
        this.companyProfileDTO = companyProfileDTO;
        if (!this.isView) {
          if (
            this.companyProfileDTO.vendor.vendorActivityStatus.code !==
              this.global.appConstant.vm.V_ACTIVITY_STATUS_PROFILE_COMPLETION &&
            this.companyProfileDTO.vendor.vendorActivityStatus.code !==
              this.global.appConstant.vm.V_ACTIVITY_STATUS_VENDOR_ACTIVE &&
            this.companyProfileDTO.vendor.vendorActivityStatus.code !==
              this.global.appConstant.vm.V_ACTIVITY_STATUS_CERTIFICATE_EXPIRED
          ) {
            this.isView = true;
          }
        }

        if (
          this.companyProfileDTO.vendorHistory !== null &&
          this.companyProfileDTO.vendorHistory.user.id !==
            this.global.userSession.user.id &&
          this.urlBack !== undefined
        ) {
          if (
            this.urlBack === '/pages/on-boarding' ||
            this.urlBack === '/pages/approval-prcs'
          ) {
            const vendorTab: VendorTab = new VendorTab();
            vendorTab.code = 'CHECKLIST';
            vendorTab.description = 'Checklist';
            vendorTab.routeUrl = 'verification';
            const translationKey: TranslationKey = new TranslationKey();
            const module: Module = new Module();
            module.code = 'DYNAMIC-ATTRIBUTE';
            translationKey.id = null;
            translationKey.key = 'vendorTab.checklist';
            translationKey.module = module;
            vendorTab.translationKey = translationKey;
            this.companyProfileDTO.vendorTabList.push(vendorTab);
          }
        }

        this.global.routerParams.set(
          'companyProfileDTO',
          this.companyProfileDTO
        );
        this.tabResponse = TabResponseModel.create(
          this.companyProfileDTO.vendorTabList,
          this.router.url,
          'translationKey',
          this.isView
        );
        if (this.activeTabCode) {
          const currentTab = this.tabResponse.tabList.find(
            tab => tab.code === this.activeTabCode
          );
          this.tabResponse.setCurrentTab(currentTab);
        }
        this.companyProfileService.setTabResponse(this.tabResponse);
        if (
          (this.router.url.includes('/pages/company-profile') &&
            this.router.url !== '/pages/company-profile') ||
          (this.router.url.includes('/pages/vendor-list/detail-vendor') &&
            this.router.url !== '/pages/vendor-list/detail-vendor') ||
          (this.urlBack === '/pages/approval-prcs' &&
            this.router.url !== '/pages/on-boarding/edit/verification' &&
            this.router.url !== '/pages/company-profile')
        ) {
          this.router.navigate([this.router.url]);
        } else {
          this.router.navigate([
            this.router.url +
              '/' +
              this.companyProfileDTO.vendorTabList[0].routeUrl
          ]);
        }
        this.enableButtonDelegation();
        this.setStateReady();
      });
  }

  public checkValidateTable(tab: any): void {
    let tabValid = true;
    if (
      tab.code === this.global.appConstant.vm.VENDOR_TAB_COMPANY_DATA &&
      this.companyProfileDTO.tabProfileCompanyDataDTO
    ) {
      if (
        this.companyProfileDTO.tabProfileCompanyDataDTO.vendorAddressHistoryList
          .length === 0 ||
        this.companyProfileDTO.tabProfileCompanyDataDTO.vendorPICHistoryList
          .length === 0
      ) {
        tabValid = false;
      } else {
        this.isAnyMainAddress = false;
        this.companyProfileDTO.tabProfileCompanyDataDTO.vendorAddressHistoryList.forEach(
          addressHistory => {
            if (addressHistory.isMain) {
              this.isAnyMainAddress = true;
            }
            if (!this.isAnyMainAddress) {
              tabValid = false;
            } else {
              tabValid = true;
            }
          }
        );
      }
      this.validForm(tab);
      if (!tabValid || !this.isValid) {
        this.validTab(tab);
        this.tabInvalidList.push(tab);
      }
    } else if (
      tab.code === this.global.appConstant.vm.VENDOR_TAB_COMPANY_ORGANIZATION &&
      this.companyProfileDTO.tabProfileCompanyOrganizationDTO
    ) {
      if (
        this.companyProfileDTO.tabProfileCompanyOrganizationDTO
          .companyOrganizationDirectorHistoryList.length === 0
      ) {
        tabValid = false;
      }
      if (!tabValid) {
        this.validTab(tab);
        this.tabInvalidList.push(tab);
      }
    } else if (
      tab.code === this.global.appConstant.vm.VENDOR_TAB_BANK &&
      this.companyProfileDTO.tabProfileBankDTO
    ) {
      if (
        this.companyProfileDTO.tabProfileBankDTO.vendorBankHistoryList
          .length === 0
      ) {
        tabValid = false;
      }
      if (!tabValid) {
        this.validTab(tab);
        this.tabInvalidList.push(tab);
      }
    } else if (
      tab.code === this.global.appConstant.vm.VENDOR_TAB_SEGMENTATION &&
      this.companyProfileDTO.tabProfileSegmentationDTO
    ) {
      if (
        this.companyProfileDTO.tabProfileSegmentationDTO
          .vendorSegmentationHistoryList.length === 0
      ) {
        tabValid = false;
      }
      if (!tabValid) {
        this.validTab(tab);
        this.tabInvalidList.push(tab);
      }
    } else if (
      tab.code === this.global.appConstant.vm.VENDOR_TAB_DOCUMENT &&
      this.companyProfileDTO.tabProfileDocumentDTO
    ) {
      let isNIB = false;
      this.tabDoc = tab;
      if (
        this.companyProfileDTO.tabProfileDocumentDTO.indexDocMasterNIB !== null
      ) {
        if (
          this.companyProfileDTO.tabProfileDocumentDTO.vendorDocGroupList[
            this.companyProfileDTO.tabProfileDocumentDTO.indexDocGroupNIB
          ].vendorDocMasterList[
            this.companyProfileDTO.tabProfileDocumentDTO.indexDocMasterNIB
          ].vendorDocHistory !== null
        ) {
          isNIB =
            this.companyProfileDTO.tabProfileDocumentDTO.vendorDocGroupList[
              this.companyProfileDTO.tabProfileDocumentDTO.indexDocGroupNIB
            ].vendorDocMasterList[
              this.companyProfileDTO.tabProfileDocumentDTO.indexDocMasterNIB
            ].vendorDocHistory.file !== null;
        }
      }
      this.companyProfileDTO.tabProfileDocumentDTO.vendorDocGroupList.forEach(
        vendorDocGroup => {
          vendorDocGroup.vendorDocMasterList.forEach(vendorDocMaster => {
            if (vendorDocMaster.vendorAttribute.isMandatory) {
              if (
                vendorDocMaster.fileType.code ===
                  this.global.appConstant.fileType.DOC_SIUP ||
                vendorDocMaster.fileType.code ===
                  this.global.appConstant.fileType.DOC_TDP
              ) {
                if (!isNIB) {
                  tabValid = this.checkDocumentMandatory(
                    vendorDocMaster,
                    tabValid
                  );
                }
              } else {
                tabValid = this.checkDocumentMandatory(
                  vendorDocMaster,
                  tabValid
                );
              }
            } else {
              if (this.isMandatoryFCSIUJK) {
                if (
                  vendorDocMaster.fileType.code ===
                  this.global.appConstant.fileType.DOC_SIUJK
                ) {
                  tabValid = this.checkDocumentMandatory(
                    vendorDocMaster,
                    tabValid
                  );
                }
              }
              if (this.isMandatoryFCIUPJTK) {
                if (
                  vendorDocMaster.fileType.code ===
                  this.global.appConstant.fileType.DOC_IUPJTK
                ) {
                  tabValid = this.checkDocumentMandatory(
                    vendorDocMaster,
                    tabValid
                  );
                }
              }
              if (vendorDocMaster.vendorDocHistory) {
                if (
                  vendorDocMaster.vendorDocHistory.docNumber ||
                  vendorDocMaster.vendorDocHistory.docPublisher ||
                  vendorDocMaster.vendorDocHistory.startDate ||
                  vendorDocMaster.vendorDocHistory.endDate ||
                  vendorDocMaster.vendorDocHistory.file
                ) {
                  tabValid = this.checkDocumentMandatory(
                    vendorDocMaster,
                    tabValid
                  );
                }
              }
            }
          });
        }
      );
      this.companyProfileDTO.tabProfileDocumentDTO.vendorAdditionalDocHistoryList.forEach(
        additionalDocHistory => {
          if (additionalDocHistory) {
            if (
              additionalDocHistory.docNumber ||
              additionalDocHistory.name ||
              additionalDocHistory.docPublisher ||
              additionalDocHistory.startDate ||
              additionalDocHistory.endDate ||
              additionalDocHistory.file
            ) {
              tabValid = this.checkDocumentAdditionalMandatory(
                additionalDocHistory,
                tabValid
              );
            }
          }
        }
      );
      if (!this.isFromVendorList && this.urlBack === undefined) {
        this.validForm(tab);
      }
      if (!tabValid || !this.isValid) {
        this.tabInvalidList.push(tab);
      }
    } else if (
      tab.code === this.global.appConstant.vm.VENDOR_TAB_GENERAL_INFORMATION &&
      this.companyProfileDTO.tabProfileGeneralInformationDTO
    ) {
      this.validForm(tab);
      if (!this.isValid) {
        this.tabInvalidList.push(tab);
      }
    }
  }

  public doNext(tab: TabModel): void {
    if (
      tab.code === 'CHECKLIST' &&
      this.global.userSession.user.organization.id ===
        this.companyProfileDTO.vendorHistory.vendor.onBoardingByOrganization.id
    ) {
      this.tabInvalidList = [];
      this.setCompanyProfileDTORouterParam(false);
      this.checkMandatorySIUJK();
      this.checkValidateTable(
        this.tabResponse.tabs.get(
          this.global.appConstant.vm.VENDOR_TAB_DOCUMENT
        )
      );
      if (this.tabInvalidList.length === 0) {
        this.nextTab(tab);
      } else {
        this.global.routerParams.set('isCheckDocument', true);
        if (
          this.tabResponse.currentTab.code ===
          this.global.appConstant.vm.VENDOR_TAB_DOCUMENT
        ) {
          this.global.alertService.showError(
            'company-profile.validation.documentVerified'
          );
          this.formGroupService.validateAllFormFields(this.tabDoc.formGroup);
        } else {
          this.tabResponse.setCurrentTab(this.tabDoc);
          this.global.alertService.showErrorOnNextRoute(
            'company-profile.validation.documentVerified'
          );
          this.router.navigate([this.tabDoc.routeUrl]);
        }
      }
    } else {
      this.nextTab(tab);
    }
  }

  public checkMandatorySIUJK(): void {
    if (this.companyProfileDTO.tabProfileSegmentationDTO) {
      this.companyProfileDTO.tabProfileSegmentationDTO.vendorSegmentationHistoryList.forEach(
        vendorSegmentationHistory => {
          if (
            vendorSegmentationHistory.segmentationList[0].code ===
            this.companyProfileDTO.mandatoryDocSIUJK
          ) {
            this.isMandatoryFCSIUJK = true;
          }
          this.companyProfileDTO.mandatoryDocFCIUPJTK.forEach(
            segmentationCode => {
              if (
                vendorSegmentationHistory.segmentation.code === segmentationCode
              ) {
                this.isMandatoryFCIUPJTK = true;
              }
            }
          );
        }
      );
    } else {
      this.isMandatoryFCSIUJK = this.companyProfileDTO.isSIUJKMandatory;
      this.isMandatoryFCIUPJTK = this.companyProfileDTO.isFCIUPJTKMandatory;
    }
  }

  public validForm(tab: TabModel): void {
    this.formGroupService.validateAllFormFields(tab.formGroup);
    if (!tab.formGroup.valid) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }
  }

  public validTab(tab: any): void {
    if (tab.formGroup !== undefined) {
      tab.formGroup.patchValue({ changeTab: true, isTableEmpty: true });
    }
  }

  public checkDocumentMandatory(
    vendorDocMaster: VendorDocMaster,
    tabValid: boolean
  ): boolean {
    if (
      this.global.userSession.activeUserRole.role.type ===
      this.global.appConstant.core.ROLE_TYPE_VENDOR
    ) {
      if (vendorDocMaster.vendorDocHistory === null) {
        tabValid = false;
      } else {
        if (
          vendorDocMaster.vendorDocHistory.docNumber === null ||
          vendorDocMaster.vendorDocHistory.docPublisher === null ||
          vendorDocMaster.vendorDocHistory.docNumber === '' ||
          vendorDocMaster.vendorDocHistory.docPublisher === ''
        ) {
          tabValid = false;
        } else if (vendorDocMaster.isStart) {
          if (vendorDocMaster.vendorDocHistory.startDate === null) {
            tabValid = false;
          }
        } else if (vendorDocMaster.isExpiry) {
          if (vendorDocMaster.vendorDocHistory.endDate === null) {
            tabValid = false;
          }
        } else if (vendorDocMaster.vendorDocHistory.file === null) {
          tabValid = false;
        }
      }
    } else {
      if (vendorDocMaster.vendorDocHistory) {
        if (
          vendorDocMaster.vendorDocHistory.isVerified === null &&
          vendorDocMaster.vendorDocHistory.file
        ) {
          tabValid = false;
        }
      }
    }
    return tabValid;
  }

  public checkDocumentAdditionalMandatory(
    vendorAdditionalDocHistory: VendorAdditionalDocHistory,
    tabValid: boolean
  ): boolean {
    if (
      this.global.userSession.activeUserRole.role.type ===
      this.global.appConstant.core.ROLE_TYPE_VENDOR
    ) {
      if (
        vendorAdditionalDocHistory.name === null ||
        vendorAdditionalDocHistory.name === '' ||
        vendorAdditionalDocHistory.docNumber === null ||
        vendorAdditionalDocHistory.docPublisher === null ||
        vendorAdditionalDocHistory.docNumber === '' ||
        vendorAdditionalDocHistory.docPublisher === '' ||
        vendorAdditionalDocHistory.startDate === null ||
        vendorAdditionalDocHistory.endDate === null ||
        vendorAdditionalDocHistory.file === null
      ) {
        tabValid = false;
      }
    } else {
      if (
        vendorAdditionalDocHistory.file &&
        vendorAdditionalDocHistory.isVerified === null
      ) {
        tabValid = false;
      }
    }
    return tabValid;
  }

  public nextTab(tab: TabModel): void {
    this.tabResponse.setCurrentTab(tab);
    if (
      this.tabResponse.currentTab.code ===
      this.global.appConstant.vm.VENDOR_TAB_DOCUMENT
    ) {
      this.companyProfileDTO = this.checkTabDocument(
        this.companyProfileDTO,
        this.tabResponse.currentTab
      );
    }
    this.companyProfileService.setTabResponse(this.tabResponse);
    this.global.routerParams.set('companyProfile', this.companyProfileDTO);
    this.global.routerParams.set('isVendorList', this.isFromVendorList);
    this.global.routerParams.set('urlBackOutside', this.urlBack);
    this.router.navigate([tab.routeUrl]);
  }

  public doPrevious(tab: TabModel): void {
    this.tabResponse.setCurrentTab(tab);
    if (
      this.tabResponse.currentTab.code ===
      this.global.appConstant.vm.VENDOR_TAB_DOCUMENT
    ) {
      this.companyProfileDTO = this.checkTabDocument(
        this.companyProfileDTO,
        this.tabResponse.currentTab
      );
    }
    this.companyProfileService.setTabResponse(this.tabResponse);
    this.global.routerParams.set('companyProfile', this.companyProfileDTO);
    this.global.routerParams.set('isVendorList', this.isFromVendorList);
    this.global.routerParams.set('urlBackOutside', this.urlBack);
    this.router.navigate([tab.routeUrl]);
  }

  public doBack(): void {
    if (this.urlBack === 'close-window') {
      window.close();
    } else {
      this.router.navigate([this.urlBack]);
      this.global.routerParams.set('isFromVendor', false);
      this.global.routerParams.set('id', this.companyProfileDTO.vendor.id);
      this.global.routerParams.set('name', this.companyProfileDTO.vendor.name);
      this.global.routerParams.set('code', this.companyProfileDTO.vendor.code);
    }
  }

  public doSaveDraft(): void {
    const prompt = this.global.translateService.instant(
      'company-profile.save-draft.message'
    );
    const title = this.global.translateService.instant(
      'company-profile.save-draft.title'
    );
    this.global.modalService
      .confirm(prompt, title)
      .pipe(take(1))
      .subscribe(response => {
        if (response) {
          this.setStateProcessing();
          this.isSubmit = false;
          this.setCompanyProfileDTORouterParam(this.isSubmit);
          this.httpClientService
            .post<Response<CompanyProfileDTO>>(
              '/company-profile/update',
              this.companyProfileDTO,
              null,
              true
            )
            .subscribe(response => {
              this.setStateReady();
              this.showAlertMessage(response);
            });
        }
      });
  }

  public doSave(): void {
    this.isSubmit = true;
    this.setCompanyProfileDTORouterParam(this.isSubmit);
    this.tabResponse.setIsSubmitted();
    this.tabInvalidList = [];
    this.isValid = true;
    this.next = true;
    this.checkMandatorySIUJK();
    this.tabResponse.tabs.forEach(tab => {
      this.checkValidateTable(tab);
    });
    this.companyProfileDTO.vendorHistory.isSubmit = this.isSubmit;
    if (this.tabInvalidList.length === 0) {
      this.next = true;
    } else {
      this.next = false;
    }
    if (this.next && this.tabResponse.valid && this.isValid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<CompanyProfileDTO>>(
                '/company-profile/update',
                this.companyProfileDTO,
                null,
                true
              )
              .subscribe(response => {
                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  this.showAlertMessage(response);
                } else {
                  if (response.body) {
                    if (
                      response.body === 'company-profile.segmentationIsExist'
                    ) {
                      this.global.alertService.showInfo(
                        this.translateService.instant(
                          'company-profile.segmentationIsExist'
                        ) +
                          ' ' +
                          response.statusText
                      );
                    } else {
                      this.global.alertService.showErrorOnNextRoute(
                        response.statusText
                      );
                      const tab: TabModel = this.tabResponse.tabs.get(
                        response.body
                      );
                      this.tabResponse.setCurrentTab(tab);
                      this.formGroupService.validateAllFormFields(
                        tab.formGroup
                      );
                      this.router.navigate([tab.routeUrl]);
                      this.global.routerParams.set('isSubmitProfiling', true);
                    }
                  } else {
                    this.showAlertMessage(response);
                  }
                }
              });
          }
        });
    } else {
      this.setStateReady();
      if (
        this.tabInvalidList[0].code ===
          this.global.appConstant.vm.VENDOR_TAB_COMPANY_DATA &&
        !this.isAnyMainAddress &&
        this.isValid
      ) {
        this.validForm(this.tabInvalidList[0]);
        if (
          this.companyProfileDTO.tabProfileCompanyDataDTO
            .vendorAddressHistoryList.length === 0 ||
          this.companyProfileDTO.tabProfileCompanyDataDTO.vendorPICHistoryList
            .length === 0
        ) {
          this.global.alertService.showErrorOnNextRoute(
            'company-profile.validation.tab'
          );
        } else {
          this.global.alertService.showErrorOnNextRoute(
            'company-profile.validation.mainAddress'
          );
        }
      } else {
        this.global.alertService.showErrorOnNextRoute(
          'company-profile.validation.tab'
        );
      }
      this.tabResponse.setCurrentTab(this.tabInvalidList[0]);
      this.formGroupService.validateAllFormFields(
        this.tabInvalidList[0].formGroup
      );
      this.router.navigate([this.tabInvalidList[0].routeUrl]);
      this.global.routerParams.set('isSubmitProfiling', true);
    }
  }

  public showAlertMessage(response: Response<CompanyProfileDTO>): void {
    if (response.status === ResponseStatusModel.OK) {
      if (this.isSubmit) {
        this.global.alertService.showSuccessSavingOnNextRoute();
        this.router.navigate(['/pages/dashboard']);
      } else {
        this.global.alertService.showSuccessSaving();
      }
    } else {
      this.global.alertService.showError(response.statusText);
    }
  }

  public setCompanyProfileDTORouterParam(isSubmit: boolean): void {
    this.tabResponse.tabs.forEach(tabs => {
      if (
        tabs.code === this.global.appConstant.vm.VENDOR_TAB_GENERAL_INFORMATION
      ) {
        this.companyProfileDTO.vendorHistory =
          this.global.copyFormAttributeToModel(
            this.vendorHistory,
            tabs.formGroup
          );
        const dataGeneral = tabs.formGroup.value;
        if (dataGeneral) {
          this.companyProfileDTO.vendorHistory.organization =
            dataGeneral.organization;
          this.companyProfileDTO.tabProfileGeneralInformationDTO =
            tabs.routerParams.get('tabProfileGeneralInformationDTO');
          if (dataGeneral.phone) {
            this.companyProfileDTO.vendorHistory.phone =
              dataGeneral.phone.number;
            this.companyProfileDTO.vendorHistory.phoneRegion =
              dataGeneral.phone.country;
            this.companyProfileDTO.vendorHistory.isSubmit = isSubmit;
            this.vendorAddressHistory.phone = dataGeneral.phone.number;
            this.vendorAddressHistory.phoneRegion = dataGeneral.phone.country;
          }
          this.vendorAddressHistory.address = dataGeneral.address;
          this.vendorAddressHistory.cityRegion = dataGeneral.cityRegion;
          this.vendorAddressHistory.coordinateLocation =
            dataGeneral.coordinateLocation;
          this.vendorAddressHistory.countryRegion = dataGeneral.countryRegion;
          this.vendorAddressHistory.districtRegion = dataGeneral.districtRegion;
          this.vendorAddressHistory.postCode = dataGeneral.postCode;
          this.vendorAddressHistory.provinceRegion = dataGeneral.provinceRegion;
          this.vendorAddressHistory.vendorAddressId =
            dataGeneral.vendorAddressId;
          this.vendorAddressHistory.isMain = true;
          this.companyProfileDTO.tabProfileGeneralInformationDTO.vendorAddressHistory =
            this.vendorAddressHistory;
          tabs.routerParams.set(
            'tabProfileGeneralInformationDTO',
            this.companyProfileDTO.tabProfileGeneralInformationDTO
          );
        }
      } else if (
        tabs.code === this.global.appConstant.vm.VENDOR_TAB_COMPANY_DATA
      ) {
        if (tabs.formGroup) {
          this.companyProfileDTO.vendorHistory =
            this.global.copyFormAttributeToModel(
              this.vendorHistory,
              tabs.formGroup
            );
        }
        if (tabs.formGroup.value.isPkp === 1) {
          this.companyProfileDTO.vendorHistory.isPkp = false;
        } else {
          this.companyProfileDTO.vendorHistory.isPkp = true;
        }
        if (tabs.formGroup.value.keyword) {
          if (tabs.formGroup.value.keyword.length !== 0) {
            this.companyProfileDTO.vendorHistory.keyword =
              tabs.formGroup.value.keyword.toString();
          } else {
            this.companyProfileDTO.vendorHistory.keyword = null;
          }
        }
        if (tabs.formGroup.value.phone) {
          this.companyProfileDTO.vendorHistory.phone =
            tabs.formGroup.get('phone').value.number;
          this.companyProfileDTO.vendorHistory.phoneRegion =
            tabs.formGroup.get('phone').value.country;
        }
        const organization = tabs.formGroup.value.registeredInUnit;
        if (organization) {
          organization.forEach((element, index) => {
            if (index === organization.length - 1) {
              this.companyProfileDTO.vendorHistory.organization = element;
            }
          });
        }
        this.companyProfileDTO.vendorHistory.isSubmit = isSubmit;
        const tabProfileCompanyDataDTO = tabs.routerParams.get(
          'tabProfileCompanyDataDTO'
        );
        if (tabProfileCompanyDataDTO) {
          this.companyProfileDTO.tabProfileCompanyDataDTO =
            tabProfileCompanyDataDTO;
        }
        this.companyProfileDTO.vendorHistory.vendorProductServiceCategoryHistoryList =
          [];
        if (tabs.formGroup.value.vendorProductServiceCategoryHistoryList) {
          tabs.formGroup.value.vendorProductServiceCategoryHistoryList.forEach(
            category => {
              const vendorProductServiceCategoryHistory: VendorProductServiceCategoryHistory =
                new VendorProductServiceCategoryHistory();
              vendorProductServiceCategoryHistory.itemCategory = category;
              this.companyProfileDTO.vendorHistory.vendorProductServiceCategoryHistoryList.push(
                vendorProductServiceCategoryHistory
              );
            }
          );
        }
        this.companyProfileDTO.vendorHistory.vendorIndustryHistoryList = [];
        if (tabs.formGroup.value.vendorIndustryHistoryList) {
          tabs.formGroup.value.vendorIndustryHistoryList.forEach(industry => {
            const vendorIndustryHistory: VendorIndustryHistory =
              new VendorIndustryHistory();
            vendorIndustryHistory.industry = industry;
            this.companyProfileDTO.vendorHistory.vendorIndustryHistoryList.push(
              vendorIndustryHistory
            );
          });
        }
        this.companyProfileDTO.vendorHistory.vendorProductLocationHistoryList =
          [];
        if (tabs.formGroup.value.vendorProductLocationHistoryList) {
          tabs.formGroup.value.vendorProductLocationHistoryList.forEach(
            region => {
              const vendorProductLocationHistory: VendorProductLocationHistory =
                new VendorProductLocationHistory();
              vendorProductLocationHistory.region = region;
              this.companyProfileDTO.vendorHistory.vendorProductLocationHistoryList.push(
                vendorProductLocationHistory
              );
            }
          );
        }
        this.companyProfileDTO.vendorHistory.establishmentYear =
          tabs.formGroup.value.establishmentYear !== ''
            ? tabs.formGroup.value.establishmentYear
            : null;
        this.companyProfileDTO.vendorHistory.totalEmployee =
          tabs.formGroup.value.totalEmployee !== ''
            ? tabs.formGroup.value.totalEmployee
            : null;
        tabs.routerParams.set(
          'tabProfileCompanyDataDTO',
          this.companyProfileDTO.tabProfileCompanyDataDTO
        );
      } else if (
        tabs.code ===
          this.global.appConstant.vm.VENDOR_TAB_COMPANY_ORGANIZATION &&
        !this.isView
      ) {
        this.companyProfileDTO.tabProfileCompanyOrganizationDTO =
          tabs.routerParams.get('tabProfileCompanyOrganizationDTO');
      } else if (
        tabs.code === this.global.appConstant.vm.VENDOR_TAB_BANK &&
        !this.isView
      ) {
        this.companyProfileDTO.tabProfileBankDTO =
          tabs.routerParams.get('tabProfileBankDTO');
      } else if (
        tabs.code === this.global.appConstant.vm.VENDOR_TAB_SEGMENTATION &&
        !this.isView
      ) {
        this.tabSegmentation = tabs.routerParams.get(
          'tabProfileSegmentationDTO'
        );
        this.companyProfileDTO.tabProfileSegmentationDTO =
          tabs.routerParams.get('tabProfileSegmentationDTO');
      } else if (
        tabs.code ===
          this.global.appConstant.vm.VENDOR_TAB_EXPERTISE_EQUIPMENT &&
        !this.isView
      ) {
        this.companyProfileDTO.tabProfileExpertAndEquipmentDTO =
          tabs.routerParams.get('tabProfileExpertAndEquipmentDTO');
      } else if (
        tabs.code === this.global.appConstant.vm.VENDOR_TAB_MARKETING &&
        tabs.formGroup.value.description !== undefined &&
        !this.isView
      ) {
        this.dataMarketing = tabs.formGroup.value;
        if (this.dataMarketing) {
          this.companyProfileDTO.tabProfileMarketingDTO = tabs.routerParams.get(
            'tabProfileMarketingDTO'
          );
          if (this.dataMarketing.logoFile !== null) {
            if (
              this.dataMarketing.logoFile.length &&
              this.dataMarketing.logoFile.length !== 0
            ) {
              const dataFile = this.dataMarketing.logoFile;
              this.companyProfileDTO.vendorHistory.logoFile = this.dataMarketing
                .logoFile[0].isDeleted
                ? null
                : this.dataMarketing.logoFile[0].file;
              this.companyProfileDTO.tabProfileMarketingDTO.logoFileObjectList =
                dataFile;
            } else {
              this.companyProfileDTO.vendorHistory.logoFile =
                this.dataMarketing.logoFile.length !== undefined
                  ? null
                  : this.dataMarketing.logoFile;
            }
          } else {
            this.companyProfileDTO.vendorHistory.logoFile = null;
          }
          if (this.dataMarketing.bannerFile !== null) {
            if (
              this.dataMarketing.bannerFile.length &&
              this.dataMarketing.bannerFile.length !== 0
            ) {
              const dataFile = this.dataMarketing.bannerFile;
              this.companyProfileDTO.vendorHistory.bannerFile = this
                .dataMarketing.bannerFile[0].isDeleted
                ? null
                : this.dataMarketing.bannerFile[0].file;
              this.companyProfileDTO.tabProfileMarketingDTO.bannerFileObjectList =
                dataFile;
            } else {
              this.companyProfileDTO.vendorHistory.bannerFile =
                this.dataMarketing.bannerFile.length !== undefined
                  ? null
                  : this.dataMarketing.bannerFile;
            }
          } else {
            this.companyProfileDTO.vendorHistory.bannerFile = null;
          }
          if (this.dataMarketing.companyProfileFile !== null) {
            if (
              this.dataMarketing.companyProfileFile.length &&
              this.dataMarketing.companyProfileFile.length !== 0
            ) {
              const dataFile = this.dataMarketing.companyProfileFile;
              this.companyProfileDTO.vendorHistory.companyProfileFile = this
                .dataMarketing.companyProfileFile[0].isDeleted
                ? null
                : this.dataMarketing.companyProfileFile[0].file;
              this.companyProfileDTO.tabProfileMarketingDTO.companyFileObjectList =
                dataFile;
            } else {
              this.companyProfileDTO.vendorHistory.companyProfileFile =
                this.dataMarketing.companyProfileFile.length !== undefined
                  ? null
                  : this.dataMarketing.companyProfileFile;
            }
          } else {
            this.companyProfileDTO.vendorHistory.companyProfileFile = null;
          }
          this.companyProfileDTO.vendorHistory.facebook =
            this.dataMarketing.facebook;
          this.companyProfileDTO.vendorHistory.ig = this.dataMarketing.ig;
          this.companyProfileDTO.vendorHistory.linkedin =
            this.dataMarketing.linkedin;
          this.companyProfileDTO.vendorHistory.description =
            this.dataMarketing.description;
          this.companyProfileDTO.vendorHistory.twitter =
            this.dataMarketing.twitter;
        }
      } else if (
        tabs.code === this.global.appConstant.vm.VENDOR_TAB_DOCUMENT &&
        tabs.formGroup.controls.vendorDocGroupList !== undefined
      ) {
        this.companyProfileDTO = this.checkTabDocument(
          this.companyProfileDTO,
          tabs
        );
      } else if (
        tabs.code === this.global.appConstant.vm.VENDOR_TAB_EXPERIENCE &&
        !this.isView
      ) {
        this.companyProfileDTO.tabProfileExperienceDTO = tabs.routerParams.get(
          'tabProfileExperienceDTO'
        );
      } else if (
        tabs.code === this.global.appConstant.vm.VENDOR_TAB_PARTNERSHIP &&
        !this.isView
      ) {
        this.companyProfileDTO.tabProfilePartnershipDTO = tabs.routerParams.get(
          'tabProfilePartnershipDTO'
        );
      } else if (
        tabs.code ===
          this.global.appConstant.vm.VENDOR_TAB_FINANCIAL_STATEMENT &&
        !this.isView
      ) {
        this.companyProfileDTO.tabProfileFinancialStatementDTO =
          tabs.routerParams.get('tabProfileFinancialStatementDTO');
      } else if (
        tabs.code === this.global.appConstant.vm.VENDOR_TAB_REP_AGENT &&
        !this.isView
      ) {
        this.companyProfileDTO.tabProfileRepAgentDTO = tabs.routerParams.get(
          'tabProfileRepAgentDTO'
        );
      }
    });
    this.global.routerParams.set('companyProfileDTO', this.companyProfileDTO);
  }

  public checkTabDocument(
    companyProfileDTO: CompanyProfileDTO,
    tab: TabModel
  ): CompanyProfileDTO {
    this.companyProfileDTO.tabProfileDocumentDTO = tab.routerParams.get(
      'tabProfileDocumentDTO'
    );
    const dataVendorDocGroupList = tab.formGroup.value.vendorDocGroupList;
    const dataAdditionalDocList = tab.formGroup.value.additionalDocList;
    if (dataVendorDocGroupList) {
      companyProfileDTO.tabProfileDocumentDTO.vendorDocGroupList.forEach(
        (vendorDocGroup, i) => {
          vendorDocGroup.vendorDocMasterList.forEach((vendorDocMaster, j) => {
            const vendorDocHistory: VendorDocHistory = dataVendorDocGroupList[i]
              .vendorDocMasterList[j].vendorDocHistory
              ? dataVendorDocGroupList[i].vendorDocMasterList[j]
                  .vendorDocHistory
              : new VendorDocHistory();
            if (
              dataVendorDocGroupList[i].vendorDocMasterList[j].isVerified !==
              null
            ) {
              const verification: boolean =
                dataVendorDocGroupList[i].vendorDocMasterList[j].isVerified
                  .value;
              if (verification !== undefined) {
                vendorDocHistory.isVerified = verification;
              } else {
                dataVendorDocGroupList[i].vendorDocMasterList[j].isVerified =
                  null;
                vendorDocHistory.isVerified = null;
              }
            }
            if (!this.isView) {
              vendorDocHistory.endDate =
                dataVendorDocGroupList[i].vendorDocMasterList[j].endDate;
              vendorDocHistory.startDate =
                dataVendorDocGroupList[i].vendorDocMasterList[j].startDate;
              vendorDocHistory.docNumber =
                dataVendorDocGroupList[i].vendorDocMasterList[j].docNumber;
              vendorDocHistory.docPublisher =
                dataVendorDocGroupList[i].vendorDocMasterList[j].docPublisher;
            }
            vendorDocHistory.verifiedDate =
              dataVendorDocGroupList[i].vendorDocMasterList[j].verifiedDate !==
              null
                ? dataVendorDocGroupList[i].vendorDocMasterList[j].verifiedDate
                : null;
            if (
              dataVendorDocGroupList[i].vendorDocMasterList[j].file !== null
            ) {
              if (
                dataVendorDocGroupList[i].vendorDocMasterList[j].file.length &&
                dataVendorDocGroupList[i].vendorDocMasterList[j].file.length !==
                  0
              ) {
                const dataFile =
                  dataVendorDocGroupList[i].vendorDocMasterList[j].file;
                dataVendorDocGroupList[i].vendorDocMasterList[j].file =
                  dataVendorDocGroupList[i].vendorDocMasterList[j].file
                    ? dataVendorDocGroupList[i].vendorDocMasterList[j].file[0]
                        .isDeleted
                      ? null
                      : dataVendorDocGroupList[i].vendorDocMasterList[j].file[0]
                          .file
                    : null;
                vendorDocHistory.file =
                  dataVendorDocGroupList[i].vendorDocMasterList[j].file;
                vendorDocMaster.fileObjectList = dataFile;
              } else {
                vendorDocHistory.file =
                  dataVendorDocGroupList[i].vendorDocMasterList[j].file
                    .length !== undefined
                    ? null
                    : dataVendorDocGroupList[i].vendorDocMasterList[j].file;
              }
            }
            vendorDocMaster.vendorDocHistory = vendorDocHistory;
          });
        }
      );
    }

    if (dataAdditionalDocList) {
      dataAdditionalDocList.forEach((vendorAdditionalDoc, index) => {
        if (vendorAdditionalDoc.isVerified !== null) {
          const verification: any = vendorAdditionalDoc.isVerified;
          if (verification.value !== undefined) {
            vendorAdditionalDoc.isVerified = verification.value;
          }
        }
        if (vendorAdditionalDoc.file) {
          if (
            vendorAdditionalDoc.file.length &&
            vendorAdditionalDoc.file.length !== 0
          ) {
            const dataFile = vendorAdditionalDoc.file;
            vendorAdditionalDoc.file = vendorAdditionalDoc.file
              ? vendorAdditionalDoc.file[0].isDeleted
                ? null
                : vendorAdditionalDoc.file[0].file
              : null;
            vendorAdditionalDoc.fileObjectList = dataFile;
          } else {
            vendorAdditionalDoc.file =
              vendorAdditionalDoc.file.length !== undefined
                ? null
                : vendorAdditionalDoc.file?.file;
            vendorAdditionalDoc.fileObjectList = this.companyProfileDTO
              .tabProfileDocumentDTO.vendorAdditionalDocHistoryList[index]
              ? this.companyProfileDTO.tabProfileDocumentDTO
                  .vendorAdditionalDocHistoryList[index].fileObjectList
              : [];
          }
        } else {
          vendorAdditionalDoc.fileObjectList = [];
        }
      });
      companyProfileDTO.tabProfileDocumentDTO.vendorAdditionalDocHistoryList =
        dataAdditionalDocList;
    }

    return companyProfileDTO;
  }

  public doDelegate(): void {
    const vendor: Vendor = this.companyProfileDTO.vendorHistory.vendor;
    this.appPopupService.open(
      AppPopupDelegationComponent,
      { vendor },
      { size: 'lg', backdrop: 'static' }
    );
  }

  public enableButtonDelegation(): void {
    if (
      this.router.url.toLowerCase().includes('on-boarding') &&
      this.companyProfileDTO.vendorHistory.vendor.vendorActivityStatus.code
        .toUpperCase()
        .includes('VERIFICATION') &&
      this.companyProfileDTO.vendorHistory.vendor.organization.id ===
        this.companyProfileDTO.vendorHistory.vendor.onBoardingByOrganization
          .id &&
      this.global.userSession.user.organization.id ===
        this.companyProfileDTO.vendorHistory.vendor.organization.id
    ) {
      this.isShowButtonDelegation = true;
    }
  }
}
