<app-card
  header="work-plan-realize-procurement.title"
  [isLoading]="formLoading"
  [isSaving]="formSaving"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div formArrayName="procurementList">
      <app-fieldset
        class="mb-2"
        legend="{{
          'work-plan-realize-procurement.fieldset.procurement' | translate
        }} {{ i + 1 }}"
        *ngFor="
          let procurement of formArrayProcurementList.controls;
          let i = index
        "
        [formGroup]="procurement"
      >
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'work-plan-realize-procurement.form.number' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            <input
              class="form-control validation-field"
              type="text"
              formControlName="number"
              maxlength="32"
              (keyup)="checkProcurementNumber(i)"
              [ngClass]="{
                'is-invalid': submitted && procurement.get('number').errors
              }"
              aria-label="Number"
            />
            <small
              class="text-danger"
              *ngIf="
                (submitted && procurement.get('number').errors) ||
                (procurement.get('number').touched &&
                  procurement.get('number').errors)
              "
              >{{ 'app.validation.required' | translate }}</small
            >
            <small class="text-danger" *ngIf="isProcurementNumberAlreadyUser[i]"
              >{{ procurementNumber }}
              {{
                'work-plan-realize-procurement.msg.error.alreadyUsed'
                  | translate
              }}</small
            >
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'work-plan-realize-procurement.form.organization' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            {{ organizationName }}
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'work-plan-realize-procurement.form.name' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            <input
              appAlphaNumeric
              class="form-control validation-field"
              type="text"
              formControlName="name"
              maxlength="32"
              [ngClass]="{
                'is-invalid': submitted && procurement.controls['name'].errors
              }"
              aria-label="Name"
            />
            <small
              class="text-danger"
              *ngIf="
                (submitted && procurement.controls['name'].errors) ||
                (procurement.controls['name'].touched &&
                  procurement.controls['name'].errors)
              "
              >{{ 'app.validation.required' | translate }}</small
            >
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'work-plan-realize-procurement.form.createdDate' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            <app-date-picker formControlName="procurementDate">
            </app-date-picker>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'work-plan-realize-procurement.form.description' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            <textarea
              class="form-control"
              formControlName="description"
              maxlength="512"
              rows="4"
              [ngClass]="{
                'is-invalid':
                  submitted && procurement.controls['description'].errors
              }"
              aria-label="Description"
            ></textarea>
            <small
              class="text-danger"
              *ngIf="
                (submitted && procurement.controls['description'].errors) ||
                (procurement.controls['description'].touched &&
                  procurement.controls['description'].errors)
              "
              >{{ 'app.validation.required' | translate }}</small
            >
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'work-plan-realize-procurement.form.uploadFile' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            <app-upload-multiple
              formControlName="file"
              [fileUploader]="fileUploaderList[i]"
              (onChange)="onChangeFileUpload($event)"
            >
            </app-upload-multiple>
            <small
              class="text-danger"
              *ngIf="submitted && !fileUploaderList[i].isAllUploaded"
              >{{ 'app.validation.required' | translate }}
            </small>
          </div>
        </div>

        <div formArrayName="purchaseRequestList" class="mb-4">
          <div
            class="mb-4"
            [formGroupName]="j"
            *ngFor="
              let purchaseRequestObj of procurement.get('purchaseRequestList')
                .controls;
              let j = index
            "
          >
            <app-fieldset
              legend="{{
                'work-plan-realize-procurement.fieldset.purchaseRequest'
                  | translate
              }} {{ j + 1 }}"
            >
              <div class="form-group row text-sm-right required">
                <label class="col-sm-3 control-label">{{
                  'work-plan-realize-procurement.form.purchaseRequest'
                    | translate
                }}</label>
                <div class="col-sm-6 text-left">
                  <select
                    (change)="doOnChangePurchaseRequest(purchaseRequestObj)"
                    class="form-control validation-field"
                    required
                    formControlName="purchaseRequest"
                    [ngClass]="{
                      'is-invalid':
                        submitted &&
                        purchaseRequestObj.controls['purchaseRequest'].errors
                    }"
                    aria-label="Purchase Request"
                  >
                    <option
                      *ngFor="
                        let purchaseRequest of workPlanRealizeProcurementDTO.purchaseRequestList
                      "
                      [ngValue]="purchaseRequest"
                      [hidden]="purchaseRequest.isDisabled"
                    >
                      {{ purchaseRequest.name }}
                    </option>
                  </select>
                  <small
                    class="text-danger"
                    *ngIf="
                      (submitted &&
                        purchaseRequestObj.controls['purchaseRequest']
                          .errors) ||
                      (purchaseRequestObj.controls['purchaseRequest'].touched &&
                        purchaseRequestObj.controls['purchaseRequest'].errors)
                    "
                    >{{ 'app.validation.required' | translate }}</small
                  >
                </div>
              </div>
              <div
                class="table-responsive custom-table mb-4 float-none"
                [ngStyle]="{ margin: '0 auto' }"
              >
                <table
                  class="table table-striped box-shadow"
                  aria-describedby="Table"
                >
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{{
                        'work-plan-realize-procurement.table.column.codeItem'
                          | translate
                      }}</th>
                      <th scope="col">{{
                        'work-plan-realize-procurement.table.column.nameItem'
                          | translate
                      }}</th>
                      <th scope="col">{{
                        'work-plan-realize-procurement.table.column.unit'
                          | translate
                      }}</th>
                      <th scope="col">{{
                        'work-plan-realize-procurement.table.column.category'
                          | translate
                      }}</th>
                      <th scope="col" class="text-sm-right">{{
                        'work-plan-realize-procurement.table.column.quantity'
                          | translate
                      }}</th>
                      <th scope="col" class="text-sm-right">{{
                        'work-plan-realize-procurement.table.column.price'
                          | translate
                      }}</th>
                      <th scope="col" class="text-sm-right">{{
                        'work-plan-realize-procurement.table.column.totalPrice'
                          | translate
                      }}</th>
                      <th scope="col" class="text-sm-right asterisk">{{
                        'work-plan-realize-procurement.table.column.priceRealize'
                          | translate
                      }}</th>
                      <th scope="col" class="text-sm-right">{{
                        'work-plan-realize-procurement.table.column.totalRealize'
                          | translate
                      }}</th>
                      <th scope="col">{{
                        'work-plan-realize-procurement.table.column.codeBudget'
                          | translate
                      }}</th>
                      <th scope="col" class="asterisk">{{
                        'work-plan-realize-procurement.table.column.vendor'
                          | translate
                      }}</th>
                    </tr>
                  </thead>
                  <tbody formArrayName="purchaseRequestItemList">
                    <tr
                      *ngIf="
                        purchaseRequestObj.value.purchaseRequestItemList
                          .length == 0
                      "
                    >
                      <td colspan="99" class="bg-white text-center">
                        {{ 'app.info.noData' | translate }}
                      </td>
                    </tr>
                    <tr
                      [formGroupName]="x"
                      *ngFor="
                        let purchaseRequestItem of purchaseRequestObj.get(
                          'purchaseRequestItemList'
                        ).controls;
                        let x = index
                      "
                    >
                      <td class="text-center">{{ x + 1 }}.</td>
                      <td
                        >{{ purchaseRequestItem.value.workPlanItem.item.code }}
                      </td>
                      <td
                        >{{ purchaseRequestItem.value.workPlanItem.item.name }}
                      </td>
                      <td
                        >{{
                          purchaseRequestItem.value.workPlanItem.item.uom.name
                        }}
                      </td>
                      <td>{{
                        purchaseRequestItem.value.workPlanItem.item.itemCategory
                          .name
                      }}</td>
                      <td class="text-sm-right">
                        {{ purchaseRequestItem.value.quantity }}
                      </td>
                      <td class="text-sm-right">{{
                        purchaseRequestItem.value.convertUnitPrice
                      }}</td>
                      <td class="text-sm-right">{{
                        purchaseRequestItem.value.convertTotalPrice
                      }}</td>
                      <td>
                        <input
                          appCurrency
                          class="form-control validation-field text-right px-2"
                          formControlName="unitPriceRealize"
                          (change)="
                            onChangeUnitPriceRealize(
                              purchaseRequestItem,
                              purchaseRequestObj.get('purchaseRequestItemList')
                                .controls,
                              purchaseRequestObj
                            )
                          "
                          [ngClass]="{
                            'is-invalid':
                              submitted &&
                              purchaseRequestItem.controls['unitPriceRealize']
                                .errors
                          }"
                          [ngStyle]="{ width: '150px' }"
                          aria-label="Unit Price Realize"
                        />
                        <small
                          class="text-danger"
                          *ngIf="
                            (submitted &&
                              purchaseRequestItem.controls['unitPriceRealize']
                                .errors) ||
                            (purchaseRequestItem.controls['unitPriceRealize']
                              .touched &&
                              purchaseRequestItem.controls['unitPriceRealize']
                                .errors)
                          "
                          >{{ 'app.validation.required' | translate }}</small
                        >
                      </td>
                      <td class="text-right"
                        >{{
                          purchaseRequestItem.value.convertTotalPriceRealize
                        }}
                      </td>
                      <td
                        >{{
                          purchaseRequestItem.value.workPlanItem.budget.number
                        }}
                      </td>
                      <td>
                        <input
                          appAlphaNumeric
                          class="form-control validation-field"
                          formControlName="vendor"
                          [ngClass]="{
                            'is-invalid':
                              submitted &&
                              purchaseRequestItem.controls['vendor'].errors
                          }"
                          [ngStyle]="{ width: '150px' }"
                          maxlength="32"
                          aria-label="Vendor"
                        />
                        <small
                          class="text-danger"
                          *ngIf="
                            (submitted &&
                              purchaseRequestItem.controls['vendor'].errors) ||
                            (purchaseRequestItem.controls['vendor'].touched &&
                              purchaseRequestItem.controls['vendor'].errors)
                          "
                          >{{ 'app.validation.required' | translate }}</small
                        >
                      </td>
                    </tr>
                    <tr
                      *ngIf="
                        purchaseRequestObj.value.purchaseRequestItemList
                          .length > 0
                      "
                    >
                      <td colspan="6"></td>
                      <td class="text-sm-right">
                        <strong>{{
                          'work-plan-realize-procurement.table.column.total'
                            | translate
                        }}</strong>
                      </td>
                      <td class="text-sm-right">
                        <strong>{{
                          purchaseRequestObj.value.convertTotalAnggaran
                        }}</strong>
                      </td>
                      <td></td>
                      <td class="text-sm-right">
                        <strong>{{
                          purchaseRequestObj.value.convertTotalRealisasi
                        }}</strong>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="form-group row text-sm-right">
                <div class="col-sm-12">
                  <div class="row justify-content-center pt-2">
                    <button
                      type="button "
                      *ngIf="procurement.get('purchaseRequestList').length > 1"
                      class="btn btn-danger ml-2"
                      (click)="
                        onClickDeletePurchaseRequest(
                          j,
                          procurement.get('purchaseRequestList')
                        )
                      "
                    >
                      {{
                        'work-plan-realize-procurement.button.deletePurchaseRequest'
                          | translate
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </app-fieldset>
          </div>
          <div class="form-group row text-sm-right">
            <div class="col-sm-12">
              <div class="row justify-content-center pt-2">
                <span placement="top" [ngbTooltip]="msgInfo">
                  <button
                    type="button "
                    class="btn btn-info"
                    (click)="onClickAddPurchaseRequest(i)"
                    [disabled]="buttonAddPuchaseRequestIsDisable"
                  >
                    <em class="fas fa-plus"></em>&nbsp;{{
                      'work-plan-realize-procurement.button.addPurhaseRequest'
                        | translate
                    }}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </app-fieldset>
      <div class="form-group row text-sm-right">
        <div class="col-sm-12">
          <div class="row justify-content-center pt-2">
            <button
              type="button "
              *ngIf="formGroup.get('procurementList').value.length > 1"
              class="btn btn-danger ml-2"
              (click)="onClickDeleteProcurement()"
            >
              {{ 'work-plan-realize-procurement.button.delete' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="form-group row text-sm-right">
        <div class="col-sm-12">
          <div class="row justify-content-center mt-5">
            <span placement="top" [ngbTooltip]="msgInfo">
              <!--  [disabled]="buttonAddPuchaseRequestIsDisable" variable tidak ada di html -->
              <button
                type="button "
                class="btn btn-info ml-2"
                (click)="onClickAddProcurement()"
              >
                <em class="fas fa-plus"></em>&nbsp;{{
                  'work-plan-realize-procurement.button.add' | translate
                }}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <button type="button " class="btn btn-primary ml-2" (click)="doSave()">
        {{ 'app.button.save' | translate }}
      </button>
    </div>
  </form>

  <div class="row justify-content-center mt-5">
    <button
      type="button "
      class="btn btn-secondary"
      (click)="doPrevious()"
      [disabled]="formSaving"
    >
      {{ 'app.button.previous' | translate }}
    </button>
    <button
      type="button "
      class="btn btn-primary ml-2"
      (click)="doNext()"
      [disabled]="!isHaveProcurement"
    >
      {{ 'app.button.next' | translate }}
    </button>
  </div>
</app-card>
