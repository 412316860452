<app-popup
  [header]="'tab-profile-company-data.form.popup.header.address.' + todo"
  [isLoading]="formLoading"
>
  <form [formGroup]="formGroup">
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.buildingName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          [autofocus]="true"
          size="LG"
          formControlName="buildingName"
          maxLength="32"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorAddressHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorAddress.buildingName"
            [newValue]="vendorAddressHistoryEdit.buildingName"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.buildingType' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="LG"
          [optionList]="buildingTypeOptionList"
          formControlName="buildingType"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorAddressHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorAddress.buildingType
                ? vendorAddress.buildingType.name
                : null
            "
            [newValue]="
              vendorAddressHistoryEdit.buildingType
                ? vendorAddressHistoryEdit.buildingType.name
                : null
            "
          ></app-flag-new>
        </app-combo-box>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.resourceOwnership' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="LG"
          [optionList]="resourceOwnershipOptionList"
          formControlName="resourceOwnership"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorAddressHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorAddress.resourceOwnership
                ? vendorAddress.resourceOwnership.name
                : null
            "
            [newValue]="
              vendorAddressHistoryEdit.resourceOwnership
                ? vendorAddressHistoryEdit.resourceOwnership.name
                : null
            "
          ></app-flag-new>
        </app-combo-box>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.buildingLocation' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="LG"
          [optionList]="buildingLocationOptionList"
          formControlName="buildingLocation"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorAddressHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorAddress.buildingLocation
                ? vendorAddress.buildingLocation.name
                : null
            "
            [newValue]="
              vendorAddressHistoryEdit.buildingLocation
                ? vendorAddressHistoryEdit.buildingLocation.name
                : null
            "
          ></app-flag-new>
        </app-combo-box>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.size' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="SM"
          formControlName="size"
          symbolic="m<sup>2</sup>"
          tooltip="{{ 'tab-profile-company-data.tooltip.size' | translate }}"
          type="decimal"
          maxLength="6"
        ></app-text-field>
        <app-flag-new
          *ngIf="
            global.userSession.activeUserRole.role.type !==
              global.appConstant.core.ROLE_TYPE_VENDOR &&
            formGroup.isView &&
            !isVendorList &&
            (fromOutside === '/pages/on-boarding' ||
              fromOutside === '/pages/approval-prcs') &&
            vendorAddressHistoryEdit.vendorHistory !== null
          "
          [oldValue]="vendorAddress.size"
          [newValue]="vendorAddressHistoryEdit.size"
          [ngStyle]="{ 'padding-left': '25px' }"
        ></app-flag-new>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.address' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        <app-text-area
          size="LG"
          formControlName="address"
          maxLength="512"
          [ngStyle]="{ height: '135px' }"
          placeholder="{{ 'tab-profile-company-data.placeholder.address' | translate }}"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorAddressHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorAddress.address"
            [newValue]="vendorAddressHistoryEdit.address"
            [ngStyle]="{ 'padding-left': '3px' }"
          ></app-flag-new>
        </app-text-area>
      </div>
    </div>

    <div class="form-group row text-sm-right" *ngIf="!formLoading">
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.coordinateLocation' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        <app-text-map size="LG" formControlName="coordinateLocation">
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorAddressHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorAddress.coordinateLocation"
            [newValue]="vendorAddressHistoryEdit.coordinateLocation"
          ></app-flag-new>
        </app-text-map>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.mainAddress' | translate
      }}</label>
      <div class="col-sm-9 text-left" *ngIf="!formGroup.isView">
        <app-check-box
          formControlName="isMain"
          tooltip="{{
            'tab-profile-company-data.tooltip.mainAddress' | translate
          }}"
          (change)="onChangeMainAddress()"
          *ngIf="!formLoading"
        ></app-check-box>
      </div>
      <div class="col-sm-9 text-left" *ngIf="formGroup.isView">
        {{ formGroup.controls.isMain.value ? 'Yes' : 'No' }}
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label" tooltip="Country">{{
        'tab-profile-company-data.form.popup.country' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="LG"
          [optionList]="countryRegionOptionList"
          formControlName="countryRegion"
          (onChange)="doChangeCountryRegion()"
          tooltip="{{ 'tab-profile-company-data.tooltip.country' | translate }}"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorAddressHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorAddress.countryRegion
                ? vendorAddress.countryRegion.name
                : null
            "
            [newValue]="
              vendorAddressHistoryEdit.countryRegion
                ? vendorAddressHistoryEdit.countryRegion.name
                : null
            "
          ></app-flag-new>
        </app-combo-box>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView && vendorTypeCode !==
      global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.province' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="LG"
          [optionList]="provinceRegionOptionList"
          formControlName="provinceRegion"
          (onChange)="doChangeProvinceRegion()"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorAddressHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorAddress.provinceRegion
                ? vendorAddress.provinceRegion.name
                : null
            "
            [newValue]="
              vendorAddressHistoryEdit.provinceRegion
                ? vendorAddressHistoryEdit.provinceRegion.name
                : null
            "
          ></app-flag-new>
        </app-combo-box>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView && vendorTypeCode !==
      global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.city' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="LG"
          [optionList]="cityRegionOptionList"
          formControlName="cityRegion"
          (onChange)="doChangeCityRegion()"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorAddressHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorAddress.cityRegion ? vendorAddress.cityRegion.name : null
            "
            [newValue]="
              vendorAddressHistoryEdit.cityRegion
                ? vendorAddressHistoryEdit.cityRegion.name
                : null
            "
          ></app-flag-new>
        </app-combo-box>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView && vendorTypeCode !==
      global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.district' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="LG"
          [optionList]="districtRegionOptionList"
          formControlName="districtRegion"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorAddressHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorAddress.districtRegion
                ? vendorAddress.districtRegion.name
                : null
            "
            [newValue]="
              vendorAddressHistoryEdit.districtRegion
                ? vendorAddressHistoryEdit.districtRegion.name
                : null
            "
          ></app-flag-new>
        </app-combo-box>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.postCode' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="SM"
          formControlName="postCode"
          type="integer"
          maxLength="5"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorAddressHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorAddress.postCode"
            [newValue]="vendorAddressHistoryEdit.postCode"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.poBox' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="SM"
          formControlName="poBox"
          type="integer"
          maxLength="5"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorAddressHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorAddress.poBox"
            [newValue]="vendorAddressHistoryEdit.poBox"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.phone' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-phone
          size="LG"
          formControlName="phone"
          [optionList]="regionPhoneOptionList"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorAddressHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorAddress.phoneNumber"
            [newValue]="vendorAddressHistoryEdit.phoneNumber"
          ></app-flag-new>
        </app-phone>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-company-data.form.popup.fax' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-phone
          size="LG"
          formControlName="fax"
          [optionList]="regionPhoneOptionList"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorAddressHistoryEdit.vendorHistory !== null
            "
            [oldValue]="faxNumber"
            [newValue]="faxNumberHistory"
          ></app-flag-new>
        </app-phone>
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
        <app-button *ngIf="!formGroup.isView" (onClick)="doSave()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
