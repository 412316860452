import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupRepAgentComponent } from './popup/app-popup-rep-agent.component';
import { TabProfileRepAgentComponent } from './tab-profile-rep-agent.component';

export const routes = [
  {
    path: '',
    component: TabProfileRepAgentComponent,
    data: { breadcrumb: '' }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    TabProfileRepAgentComponent,
    AppPopupRepAgentComponent
  ],
  entryComponents: [
    AppPopupRepAgentComponent
  ],
  exports: [
    TabProfileRepAgentComponent,
    AppPopupRepAgentComponent
  ]
})

export class TabProfileRepAgentModule {}
