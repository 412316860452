import { Organization } from 'src/app/core/bean/organization';
import { ScoringCriteriaGroup } from 'src/app/core/bean/scoring-criteria-group';
import { ScoringTemplate } from 'src/app/core/bean/scoring-template';
import { ScoringTemplateWeight } from 'src/app/core/bean/scoring-template-weight';
import { Vendor } from 'src/app/core/bean/vendor';
import { VendorAddress } from 'src/app/core/bean/vendor-address';

export class PerformanceFulfillmentEditResponse {
  vendor: Vendor = new Vendor();
  vendorAddress: VendorAddress = new VendorAddress();
  agreementNumber: string;
  agreementType: string;
  termin: string;
  procSubmissionOrganization: Organization;

  scoringTemplate: ScoringTemplate = new ScoringTemplate();
  scoringCriteriaGroupByIsCommonList: ScoringCriteriaGroup[] = [];
  scoringCriteriaGroupByIsSpecificList: ScoringCriteriaGroup[] = [];
  scoringTemplateWeightList: ScoringTemplateWeight[] = [];
  prOrganizationList: Organization[];
}
