import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Response } from 'src/app/core/model/response-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Condition } from '../../core/bean/condition';
import { Currency } from '../../core/bean/currency';
import { Module } from '../../core/bean/module';
import { User } from '../../core/bean/user';
import { Workload } from '../../core/bean/workload';
import { WorkloadThreshold } from '../../core/bean/workload-threshold';
import { WorkloadThresholdObject } from '../../core/bean/workload-threshold-object';
import { WorkloadType } from '../../core/bean/workload-type';
import { AppPopupAddUserService } from '../../core/components/app-popup/app-popup-add-user/app-popup-add-user.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTipsComponent } from '../../core/components/app-tips/app-tips.component';
import { TableRow } from '../../core/components/table/domain/table-row';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { ModalService } from '../../core/services/modal.service';
import { Validators } from '../../core/validators';
import { AppPopupChangeDefinitionComponent } from './app-popup-change-definition.component';
import { AppPopupMoveUserComponent } from './app-popup-move-user.component';
import { WorkloadEditResponse } from './workload-edit-response';
import { WorkloadGenerateModel } from './workload-generate-model';
import { WorkloadGenerateRequest } from './workload-generate-request';
import { WorkloadUpdateRequest } from './workload-update-request';

@Component({
  templateUrl: './workload-edit.component.html'
})
export class WorkloadEditComponent extends BaseModuleComponent {
  public workloadEditResponse: WorkloadEditResponse;
  public workload: Workload;
  public module: Module;
  public workloadThresholdObject: WorkloadThresholdObject;
  public isActivateCondition = false;
  public userList: User[] = [];
  public delUserList: User[] = [];
  public userTempList: User[] = [];
  public workloadGenerateModelList: WorkloadGenerateModel[];
  public isUserChoosen: boolean;
  public isTableLevelValid: boolean;

  public tableResponseLevel: TableResponseModel<WorkloadThreshold>;
  public tableResponseUser: TableResponseModel<User>;
  public tableResponseUserConditionList: TableResponseModel<User>[] = [];
  public conditionOptionList: OptionListModel<Condition> = new OptionListModel(
    true
  );
  public workloadTypeOptionList: OptionListModel<WorkloadType> =
    new OptionListModel(true);
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );

  @ViewChild('appTips') appTips: AppTipsComponent;

  public constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupAddUserService: AppPopupAddUserService,
    public modalService: ModalService
  ) {
    super('workload', translateService);
  }

  public onInit(): void {
    this.getDataFromRouterParam();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public getDataFromRouterParam(): void {
    this.workload = this.global.routerParams.get('workload');
    this.module = this.global.routerParams.get('module');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      workloadBalancingType: [null],
      isActivateCondition: [null],
      conditionList: [null],
      recordList: this.tableResponseLevel.formArray
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<WorkloadEditResponse>('/workload/edit/' + this.module.id)
      .subscribe((response: WorkloadEditResponse) => {
        this.workloadEditResponse = response;
        this.workloadThresholdObject =
          this.workloadEditResponse.workloadThresholdList[0].workloadThresholdObject;
        this.buildTableResponseLevel();
        this.buildFormGroup();
        if (
          this.module.delegation ===
          this.global.appConstant.core.DELEGATION_AUTO_DELEGATION
        ) {
          this.formGroup
            .get('workloadBalancingType')
            .setValidators(Validators.required());
        }
        if (this.workload) {
          this.formGroup.patchValue({
            workloadBalancingType: this.workload.workloadType,
            isActivateCondition: this.workloadEditResponse.isActivateCondition,
            conditionList: this.workloadEditResponse.conditionTempList
          });
          this.isActivateCondition =
            this.workloadEditResponse.isActivateCondition;
          if (
            this.workloadThresholdObject.type ===
            this.global.appConstant.core.WOKRLOAD_THRESHOLD_OBJECT_TYPE_CURRENCY
          ) {
            this.workloadEditResponse.workloadThresholdList.forEach(
              (data: any) => {
                const fromAmount = {
                  price: data.fromAmount,
                  currency: this.workloadEditResponse.currencyList[0]
                };
                const toAmount = {
                  price: data.toAmount,
                  currency: this.workloadEditResponse.currencyList[0]
                };

                data.fromAmount = fromAmount;
                data.toAmount = toAmount;
              }
            );
          }
          this.tableResponseLevel.setRecordList(
            this.workloadEditResponse.workloadThresholdList
          );

          if (this.workloadEditResponse.workloadGenerateModelList) {
            this.workloadGenerateModelList =
              this.workloadEditResponse.workloadGenerateModelList;
            this.buildTableUserCondition(this.workloadGenerateModelList);
          }
        }
        this.userList = response.userList;
        this.userTempList = response.userTempList;
        this.workloadTypeOptionList.setRequestValues(response.workloadTypeList);
        this.currencyOptionList.setRequestValues(response.currencyList);
        this.conditionOptionList.setRequestValues(response.conditionList);
        this.tableResponseUser.setRecordList(this.userList);
        this.setStateReady();
      });
  }

  public buildTableResponseLevel(): void {
    this.tableResponseLevel = new TableResponseModel(this.moduleCode, [
      {
        field: 'workloadLevel.name',
        header: 'table.column.status',
        isSortable: false
      },
      {
        field: 'fromAmount',
        header: 'table.column.from',
        plugins:
          this.workloadThresholdObject &&
          this.workloadThresholdObject.type ===
            this.global.appConstant.core.WOKRLOAD_THRESHOLD_OBJECT_TYPE_CURRENCY
            ? {
                name: 'currency',
                optionList: this.currencyOptionList,
                isDisable: true,
                validators: Validators.required()
              }
            : {
                name: 'count',
                min: 0,
                isDisable: true,
                validators: Validators.required()
              },
        isSortable: false
      },
      {
        field: 'toAmount',
        header: 'table.column.to',
        plugins: [
          this.workloadThresholdObject &&
          this.workloadThresholdObject.type ===
            this.global.appConstant.core.WOKRLOAD_THRESHOLD_OBJECT_TYPE_CURRENCY
            ? {
                name: 'currency',
                optionList: this.currencyOptionList,
                onInput: this.onInputTo.bind(this),
                onChange: this.onInputTo.bind(this),
                validators: Validators.required()
              }
            : {
                name: 'count',
                min: 0,
                onInput: this.onInputTo.bind(this),
                onChange: this.onInputTo.bind(this),
                validators: Validators.required()
              },
          {
            name: 'custom-plugin',
            after: (tablePlugin: TablePluginData): void => {
              if (tablePlugin.row.record.isInfinite) {
                tablePlugin.row.formGroup.get('toAmount').disable();
              }
            }
          }
        ],
        isSortable: false
      },
      {
        field: 'isInfinite',
        header: 'table.column.infinite',
        plugins: [
          {
            name: 'toggle-switch',
            onChange: this.onChangeInfinite.bind(this)
          },
          {
            name: 'custom-plugin',
            after: (tablePlugin: TablePluginData): void => {
              if (
                tablePlugin.row.record.workloadLevel.code !==
                this.global.appConstant.core.WORKLOAD_LEVEL_HIGH
              ) {
                tablePlugin.element.innerText = '-';
              }
            }
          }
        ],
        isSortable: false
      }
    ]);
  }

  public buildTableResponse(): void {
    this.tableResponseUser = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.user'
      },
      {
        field: 'organization.name',
        header: 'table.column.department'
      }
    ]);
  }

  public onChangeActivateCondition(value: any): void {
    this.isActivateCondition = value;
    if (value) {
      this.formGroup.get('conditionList').setValidators(Validators.required());
    } else {
      this.formGroup.get('conditionList').clearValidators();
    }
    this.formGroup.get('conditionList').updateValueAndValidity();
  }

  public doChangeDefinition(): void {
    this.appPopupService
      .open(
        AppPopupChangeDefinitionComponent,
        {
          workloadThresholdObject: this.workloadThresholdObject,
          workloadThresholdObjectList:
            this.workloadEditResponse.workloadThresholdObjectList
        },
        { size: 'lg' }
      )
      .subscribe(workloadThresholdObject => {
        this.workloadThresholdObject = workloadThresholdObject;
        this.buildTableResponseLevel();
        this.workloadEditResponse.workloadThresholdList.forEach((data: any) => {
          if (
            data.fromAmount &&
            data.toAmount &&
            data.fromAmount.price !== undefined &&
            data.toAmount.price !== undefined &&
            this.workloadThresholdObject.type ===
              this.global.appConstant.core
                .WOKRLOAD_THRESHOLD_OBJECT_TYPE_QUANTITY
          ) {
            data.fromAmount = data.fromAmount.price;
            data.toAmount = data.toAmount.price;
          } else if (data.fromAmount && data.toAmount) {
            const fromAmount = {
              price: data.fromAmount,
              currency: this.workloadEditResponse.currencyList[0]
            };
            const toAmount = {
              price: data.toAmount,
              currency: this.workloadEditResponse.currencyList[0]
            };

            data.fromAmount = fromAmount;
            data.toAmount = toAmount;
          }
        });
        this.tableResponseLevel.setRecordList(
          this.workloadEditResponse.workloadThresholdList
        );
      });
  }

  public onInputTo(): void {
    if (
      this.workloadThresholdObject.type ===
      this.global.appConstant.core.WOKRLOAD_THRESHOLD_OBJECT_TYPE_CURRENCY
    ) {
      const toValue =
        this.tableResponseLevel.currentRowChange.row.formGroup.get('toAmount')
          .value?.price;
      const currency =
        this.tableResponseLevel.currentRowChange.row.formGroup.get('toAmount')
          .value?.currency;
      const fromValue =
        this.tableResponseLevel.currentRowChange.row.formGroup.get('fromAmount')
          .value?.price;
      if (toValue && fromValue && +toValue < +fromValue) {
        const toAmountForm =
          this.tableResponseLevel.currentRowChange.row.formGroup.get(
            'toAmount'
          );
        toAmountForm.setValidators([Validators.min(+fromValue)]);
        toAmountForm.setErrors({
          message: this.translateService.instant('app.validation.min')
        });
        toAmountForm.markAsTouched({ onlySelf: true });
      }
      const nextPosition =
        this.tableResponseLevel.currentRowChange.row.position;
      if (
        toValue &&
        nextPosition < this.tableResponseLevel.getRecordList().length
      ) {
        this.tableResponseLevel.formArray.controls[nextPosition].patchValue({
          fromAmount: { price: +toValue + 1, currency }
        });
      }
    } else {
      const toValue =
        this.tableResponseLevel.currentRowChange.row.formGroup.get(
          'toAmount'
        ).value;
      const fromValue =
        this.tableResponseLevel.currentRowChange.row.formGroup.get(
          'fromAmount'
        ).value;
      if (fromValue && +toValue < +fromValue) {
        const toAmountForm =
          this.tableResponseLevel.currentRowChange.row.formGroup.get(
            'toAmount'
          );
        toAmountForm.setValidators([Validators.min(+fromValue)]);
        toAmountForm.setErrors({
          message: this.translateService.instant('app.validation.min')
        });
        toAmountForm.markAsTouched({ onlySelf: true });
      }
      const nextPosition =
        this.tableResponseLevel.currentRowChange.row.position;
      if (nextPosition < this.tableResponseLevel.getRecordList().length) {
        this.tableResponseLevel.formArray.controls[nextPosition].patchValue({
          fromAmount: +toValue + 1
        });
      }
    }
  }

  public onChangeInfinite(data): void {
    if (!data) {
      const toAmountForm =
        this.tableResponseLevel.currentRowChange.row.formGroup.get('toAmount');
      if (
        this.workloadThresholdObject.type ===
        this.global.appConstant.core.WOKRLOAD_THRESHOLD_OBJECT_TYPE_CURRENCY
      ) {
        const fromValue =
          this.tableResponseLevel.currentRowChange.row.formGroup.get(
            'fromAmount'
          ).value?.price;
        toAmountForm.setValidators([Validators.min(+fromValue)]);
        toAmountForm.setErrors({
          message: this.translateService.instant('app.validation.min')
        });
        toAmountForm.markAsTouched({ onlySelf: true });
      } else {
        const fromValue =
          this.tableResponseLevel.currentRowChange.row.formGroup.get(
            'fromAmount'
          ).value;
        toAmountForm.setValidators([Validators.min(+fromValue)]);
        toAmountForm.setErrors({
          message: this.translateService.instant('app.validation.min')
        });
        toAmountForm.markAsTouched({ onlySelf: true });
      }
      toAmountForm.enable();
      toAmountForm.updateValueAndValidity();
    } else {
      const toAmountForm =
        this.tableResponseLevel.currentRowChange.row.formGroup.get('toAmount');
      toAmountForm.disable();
      toAmountForm.patchValue(null);
      toAmountForm.clearValidators();
      toAmountForm.updateValueAndValidity();
    }
  }

  public onAfterRowCreated(tableRow: TableRow): void {
    if (tableRow.record.isInfinite) {
      tableRow.formGroup.get('toAmount').disable();
    }
  }

  public doGenerateCondition(): void {
    this.setStateProcessing();
    const request = new WorkloadGenerateRequest();
    request.conditionList = this.formGroup.value.conditionList;
    request.workloadGenerateModelList = this.workloadGenerateModelList;
    console.log(request);
    this.httpClientService
      .post<WorkloadGenerateModel[]>('/workload/generate-condition', request)
      .subscribe(modelList => {
        this.workloadGenerateModelList = modelList;
        this.buildTableUserCondition(modelList);
        this.appTips.reload();
        this.setStateReady();
      });
  }

  public buildTableUserCondition(modelList: WorkloadGenerateModel[]): void {
    this.tableResponseUserConditionList = [];
    modelList.forEach(model => {
      const tableResponse = new TableResponseModel<User>(this.moduleCode, [
        {
          field: 'name',
          header: 'table.column.user'
        },
        {
          field: 'organization.name',
          header: 'table.column.department'
        }
      ]);

      tableResponse.setRecordList(model.userList);
      this.tableResponseUserConditionList.push(tableResponse);
    });
  }

  public doAddUserCondition(index: number): void {
    const userList =
      this.workloadGenerateModelList[index].userList &&
      this.workloadGenerateModelList[index].userList.length !== 0
        ? this.workloadGenerateModelList[index].userList
        : null;
    this.appPopupAddUserService
      .open(userList, this.userTempList)
      .subscribe(userList => {
        this.workloadGenerateModelList[index].userList = userList;
        this.tableResponseUserConditionList[index].setRecordList(userList);
        this.tableResponseUserConditionList[index].reload();
      });
  }

  public doDeleteUserCondition(data, index: number): void {
    this.global.modalService
      .newDeleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          data.selectedRecordList.forEach((record: User) => {
            const userList = this.workloadGenerateModelList[index].userList;
            const indexOfRecord = userList.findIndex(r => r.id === record.id);
            this.workloadGenerateModelList[index].userList.splice(
              indexOfRecord,
              1
            );
          });
          this.tableResponseUserConditionList[index].setRecordList(
            this.workloadGenerateModelList[index].userList
          );
          this.tableResponseUserConditionList[index].reload();
        }
      });
  }

  public doMoveUserCondition(data, index: number): void {
    const modelList = this.workloadGenerateModelList;
    const model = this.workloadGenerateModelList[index];
    this.appPopupService
      .open(
        AppPopupMoveUserComponent,
        {
          workloadGenerateModelList: modelList,
          conditionName: model.conditionName
        },
        { size: 'lg' }
      )
      .subscribe(generateModelList => {
        generateModelList.forEach(model => {
          const indexModel = this.workloadGenerateModelList.findIndex(
            generateMode => generateMode.conditionName === model.conditionName
          );
          data.selectedRecordList.forEach((user: User) => {
            this.workloadGenerateModelList[indexModel].userList.push(user);
          });

          this.tableResponseUserConditionList[indexModel].setRecordList(
            this.workloadGenerateModelList[indexModel].userList
          );
          this.tableResponseUserConditionList[indexModel].reload();
        });
        data.selectedRecordList.forEach((record: User) => {
          const userList = this.workloadGenerateModelList[index].userList;
          const indexOfRecord = userList.findIndex(r => r.id === record.id);
          this.workloadGenerateModelList[index].userList.splice(
            indexOfRecord,
            1
          );
        });
        this.tableResponseUserConditionList[index].setRecordList(
          this.workloadGenerateModelList[index].userList
        );
        this.tableResponseUserConditionList[index].reload();
      });
  }

  public doAddUser(): void {
    this.appPopupAddUserService
      .open(this.userList, this.userTempList)
      .subscribe(userList => {
        this.userList = userList;
        this.tableResponseUser.setRecordList(this.userList);
        this.tableResponseUser.reload();
      });
  }

  public doDeleteUser(event): void {
    this.global.modalService
      .newDeleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach((record: User) => {
            const indexOfRecord = this.userList.findIndex(
              r => r.id === record.id
            );
            this.userList.splice(indexOfRecord, 1);
          });
          this.tableResponseUser.setRecordList(this.userList);
          this.tableResponseUser.reload();
        }
      });
  }

  public doSave(): void {
    this.validate();
    this.validateAmount();
    this.validateUser();
    if (this.formGroup.valid && this.isUserChoosen && this.isTableLevelValid) {
      this.modalService
        .newSaveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const request = this.preparedDataForSaving();
            this.httpClientService
              .post<Response<Workload>>('/workload/update', request)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.setStateReady();
                  this.modalService
                    .saveSuccess()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate(['/pages/workload']);
                      }
                    });
                }
              });
          }
        });
    }
  }

  public validateUser(): void {
    if (!this.formGroup.value.isActivateCondition) {
      this.isUserChoosen = this.userList && this.userList.length !== 0;
    } else if (this.workloadGenerateModelList) {
      this.isUserChoosen = true;
      this.workloadGenerateModelList.forEach(model => {
        if (this.isUserChoosen) {
          this.isUserChoosen = model.userList && model.userList.length !== 0;
        }
      });
    }
  }

  public validateAmount(): void {
    this.tableResponseLevel.formArray.controls.forEach(thresholdLevel => {
      const toAmountForm = thresholdLevel.get('toAmount');
      if (
        this.workloadThresholdObject.type ===
        this.global.appConstant.core.WOKRLOAD_THRESHOLD_OBJECT_TYPE_CURRENCY
      ) {
        if (!+toAmountForm.value?.price) {
          toAmountForm.setValidators([Validators.required()]);
          toAmountForm.setErrors({
            message: this.translateService.instant('app.validation.required')
          });
          toAmountForm.markAsTouched({ onlySelf: true });
        }
      } else {
        if (!+toAmountForm.value) {
          toAmountForm.setValidators([Validators.required()]);
          toAmountForm.setErrors({
            message: this.translateService.instant('app.validation.required')
          });
          toAmountForm.markAsTouched({ onlySelf: true });
        }
      }
    });

    this.isTableLevelValid = this.tableResponseLevel.formArray.valid;
  }

  public preparedDataForSaving(): WorkloadUpdateRequest {
    const request: WorkloadUpdateRequest = new WorkloadUpdateRequest();
    if (!this.workload) {
      this.workload = new Workload();
    }
    this.workload.workloadType = this.formGroup.value.workloadBalancingType;
    this.workload.module = this.module;
    request.workload = this.workload;
    if (!this.formGroup.value.isActivateCondition) {
      request.userList = this.userList;
    }
    const workloadThresholdList: WorkloadThreshold[] = [];
    this.tableResponseLevel.getRecordList().forEach((record, index) => {
      const workloadThreshold = record;
      workloadThreshold.workloadLevel = record.workloadLevel;
      workloadThreshold.workloadThresholdObject = this.workloadThresholdObject;
      workloadThreshold.fromAmount = this.tableResponseLevel.formArray.value[
        index
      ].fromAmount
        ? this.workloadThresholdObject.type ===
          this.global.appConstant.core.WOKRLOAD_THRESHOLD_OBJECT_TYPE_CURRENCY
          ? this.tableResponseLevel.formArray.value[index].fromAmount.price
          : this.tableResponseLevel.formArray.value[index].fromAmount
        : 0;
      workloadThreshold.toAmount =
        this.workloadThresholdObject.type ===
        this.global.appConstant.core.WOKRLOAD_THRESHOLD_OBJECT_TYPE_CURRENCY
          ? this.tableResponseLevel.formArray.value[index].toAmount?.price
          : this.tableResponseLevel.formArray.value[index].toAmount;
      workloadThreshold.isInfinite =
        this.tableResponseLevel.formArray.value[index].isInfinite;
      workloadThresholdList.push(workloadThreshold);
    });
    request.workloadThresholdList = workloadThresholdList;
    request.isActivateCondition = this.isActivateCondition;
    request.workloadGenerateModelList = this.workloadGenerateModelList;
    return request;
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }
}
