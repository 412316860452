import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Contract } from 'src/app/core/bean/contract';
import { ContractClosed } from 'src/app/core/bean/contract-closed';
import { User } from 'src/app/core/bean/user';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { FileUploader } from 'src/app/core/components/upload';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { ContractMonitoringRequest } from './contract-monitoring.request';
import { ContractMonitoringResponse } from './contract-monitoring.response';

@Component({
  templateUrl: './contract-monitoring-contract-closed.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None
})
export class ContractMonitoringContractClosedComponent
  extends BaseModuleComponent
  implements OnInit
{
  public contractId: number;
  public urlBack: string;
  public isLoading: boolean;
  public contract: Contract = new Contract();
  public contractClosed: ContractClosed = new ContractClosed();
  public contractMonitoringResponse: ContractMonitoringResponse =
    new ContractMonitoringResponse();
  public contractMonitoringRequest: ContractMonitoringRequest =
    new ContractMonitoringRequest();
  public endDate: Date;
  public requestBy: User;
  public fileUploader: FileUploader = new FileUploader(
    '/contract-monitoring/',
    '',
    this.global.appConstant.fileType.DOC_CLOSED_CONTRACT
  );
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('contract-monitoring', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    const todoTemp = localStorage.getItem('todo');
    if (todoTemp) {
      this.todo = todoTemp;
      localStorage.removeItem('todo');
    } else {
      this.todo = this.global.routerParams.get('todo');
    }
    this.contractId = this.global.routerParams.get('contractId');
    this.contract = this.global.routerParams.get('contract');
    this.urlBack = this.global.routerParams.get('urlBackOutside');
    this.endDate = new Date(this.contract.endDate);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      contract: [null],
      contractFile: [null],
      description: [null],
      date: [
        null,
        Validators.compose([Validators.required(), Validators.max('endDate')])
      ],
      endDate: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ContractMonitoringResponse>(
        '/contract-monitoring/detail',
        new RouteRequestModel('', this.contractId)
      )
      .subscribe((contractMonitoringResponse: ContractMonitoringResponse) => {
        this.contractMonitoringResponse = contractMonitoringResponse;
        this.contractClosed = this.contractMonitoringResponse.contractClosed;
        if (this.contractMonitoringResponse.contractClosed != null) {
          // this.fileUploader.setFile(
          //   this.contractMonitoringResponse.contractClosed.file
          // );

          this.formGroup.patchValue({
            description:
              this.contractMonitoringResponse.contractClosed.description,
            contractFile: this.fileUploader.fileObjectList,
            id: this.contractMonitoringResponse.contractClosed.id
          });
          this.formGroup.patchValue({
            endDate: this.endDate
          });
          // if (this.contractMonitoringResponse.contractClosed.date) {
          //   const date: Date = new Date(
          //     this.contractMonitoringResponse.contractClosed.date
          //   );
          //   this.formGroup.patchValue({
          //     date
          //   });
          // }
          this.requestBy = this.contractMonitoringResponse.user;
          this.formGroup.get('contractFile').isView = true;
          this.formGroup.get('description').isView = true;
        }
        let data = localStorage.getItem('temp') as any;
        if (data) {
          data = JSON.parse(data.toString()) as ContractClosed;
          const dateTemp = new Date(data.date);
          if (data.contractFile) {
            this.fileUploader.setFile(data.contractFile[0].file);
          }
          this.formGroup.patchValue(data);
          this.formGroup.patchValue({
            date: dateTemp,
            contractFile: this.fileUploader.fileObjectList
          });
          localStorage.removeItem('temp');
          localStorage.removeItem('todo');
          if (this.contractClosed?.id != null) {
            this.formGroup.get('contractFile').isView = true;
            this.formGroup.get('description').isView = true;
          }
        }

        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    this.formGroup.patchValue({
      contract: this.contract
    });
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.contractMonitoringRequest.contractClosed =
              this.formGroup.value;
            this.contractMonitoringRequest.fileObjectList =
              this.formGroup.value.contractFile;

            this.httpClientService
              .post<Response<ContractMonitoringResponse>>(
                '/contract-monitoring/insert-contract-closed',
                this.contractMonitoringRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/contract-monitoring']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/contract-monitoring']);
  }
}
