import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../../../../../core/base/angular/base-component.component';
import { CarouselResponseModel } from '../../../../../../core/components/app-carousel/model/carousel-response-model';
@Component({
  selector: 'app-catalog-item-carousel',
  templateUrl: './app-catalog-item-carousel.component.html',
  styleUrls: ['./app-catalog-item-carousel.component.scss']
})
export class AppCatalogItemCarouselComponent extends BaseComponentComponent {
  @Input() imageList: Array<any>;
  public carouselResponseModel: CarouselResponseModel = new CarouselResponseModel();
  constructor() {
    super('app-product-carousel');
  }

  public onInit(): void {
    this.handleReloadChanges();
    this.setCarouselImages();
  }

  public setCarouselImages(): void {
    this.carouselResponseModel.images = this.imageList;
    this.carouselResponseModel.setStringUrl('view-catalog');
    this.setStateReady();
  }

  public handleReloadChanges(): void {
    this.carouselResponseModel.reloadChanges.subscribe(() => {
      this.setCarouselImages();
    });
  }
}
