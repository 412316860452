import { Organization } from '../bean/organization';
import { BaseTreeEntity } from './../base/base-tree-entity';

export class OrganizationEntity extends BaseTreeEntity {
  childOrganizationList: Organization[];
  letterNumberCode: string;
  hierarchyLevel: number;
  mainLogoFile: File;
  smallLogoFile: File;
  reportLogoFile: File;
}
