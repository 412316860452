<div class="promise-header-wrapper" [attr.style]="'z-index: 4'" id="headerWrapper"
  [@shadowOnScroll]="isOnScroll ? 'shadow' : 'default'">

  <!-- broadcast alert-->
  <div *ngIf="global.internalShortBroadcastMsg!=null" class="alert alert-success alert-dismissible mb-0">

    <i class="fas fa-fw fa-info-circle mr-3 mt-1"></i>
    <strong>Informasi</strong>, <span [innerHTML]=global.internalShortBroadcastMsg></span>...
    <a *ngIf="global.internalFullBroadcastMsg!=null" data-toggle="modal" data-target="#broadcastModal">
      Read more...
    </a>
    <button type="button" class="close" data-dismiss="alert">&times;</button>
  </div>
  <!-- broadcast alert-->


  <header id="header" class="lpx-3">
    <div class="logo-content">
      <div class="logo">
        <img *ngIf="this.global.mainLogoFileName != null"  [attr.src]="
              global.config.BACKEND_ADDRESS +
              '/' +
              'portal/file/view/' +
              this.global.mainLogoFileName +
              '/IMG_MAIN_LOGO'
              | resolveImgSrc
              | async
              " alt="logo" />
      </div>
    </div>
    <span class="current-date">
      {{
      currentTime
      | date: 'EEEE, ' + global.config.parameterModel.datetimeFormatLong
      }}
    </span>
    <nav class="header-navigation">
      <ul class="header-menu">
        <li class="header-menu-item" *ngIf="isShowCart">
          <theme-shopping-cart></theme-shopping-cart>
        </li>
        <li class="header-menu-item" *ngIf="isShowUserNotif">
          <theme-notification></theme-notification>
        </li>
        <li class="header-menu-item">
          <theme-help></theme-help>
        </li>
        <li class="header-menu-item">
          <theme-profile></theme-profile>
        </li>
      </ul>
    </nav>
  </header>
</div>



<!--broadcast popup-->
<div class="modal fade" id="broadcastModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body p-4">
        <div class="p-4" [innerHTML]=global.internalFullBroadcastMsg>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Ok</button>
      </div>
    </div>
  </div>
</div>
<!--broadcast popup-->