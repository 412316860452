import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppItemRequestModule } from '../../core/components/app-item-request/app-item-request.module';
import { AppProcurementInfoModule } from '../../core/components/app-procurement-info/app-procurement-info.module';
import { AppWorkflowStepInfoModule } from '../../core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { SubmitQuotationVendorAdministrationCriteriaComponent } from './submit-quotation-vendor-administration-criteria.component';
import { SubmitQuotationVendorEditComponent } from './submit-quotation-vendor-edit.component';
import { SubmitQuotationVendorPopupSelectItemComponent } from './submit-quotation-vendor-popup-select-item.component';
import { SubmitQuotationVendorQuotationComponent } from './submit-quotation-vendor-quotation.component';
import { SubmitQuotationVendorTechnicalCriteriaComponent } from './submit-quotation-vendor-technical-criteria.component';
import { SubmitQuotationVendorComponent } from './submit-quotation-vendor.component';

export const routes = [
  {
    path: '',
    component: SubmitQuotationVendorComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'add',
    component: SubmitQuotationVendorEditComponent,
    data: { breadcrumb: 'submit-quotation-vendor.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard],
    children: [
      {
        path: '',
        component: SubmitQuotationVendorAdministrationCriteriaComponent
      },
      {
        path: 'technical-criteria',
        component: SubmitQuotationVendorTechnicalCriteriaComponent
      },
      {
        path: 'quotation',
        component: SubmitQuotationVendorQuotationComponent
      }
    ]
  },
  {
    path: 'edit',
    component: SubmitQuotationVendorEditComponent,
    data: { breadcrumb: 'submit-quotation-vendor.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard],
    children: [
      {
        path: '',
        component: SubmitQuotationVendorAdministrationCriteriaComponent
      },
      {
        path: 'technical-criteria',
        component: SubmitQuotationVendorTechnicalCriteriaComponent
      },
      {
        path: 'quotation',
        component: SubmitQuotationVendorQuotationComponent
      }
    ]
  },
  {
    path: 'detail',
    component: SubmitQuotationVendorEditComponent,
    data: { breadcrumb: 'submit-quotation-vendor.breadcrumb.detail' },
    children: [
      {
        path: '',
        component: SubmitQuotationVendorAdministrationCriteriaComponent
      },
      {
        path: 'technical-criteria',
        component: SubmitQuotationVendorTechnicalCriteriaComponent
      },
      {
        path: 'quotation',
        component: SubmitQuotationVendorQuotationComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppItemRequestModule,
    AppProcurementInfoModule,
    AppWorkflowStepInfoModule,
    AppBadgeCatalogModule
  ],
  declarations: [
    SubmitQuotationVendorComponent,
    SubmitQuotationVendorEditComponent,
    SubmitQuotationVendorAdministrationCriteriaComponent,
    SubmitQuotationVendorTechnicalCriteriaComponent,
    SubmitQuotationVendorQuotationComponent,
    SubmitQuotationVendorPopupSelectItemComponent
  ]
})
export class SubmitQuotationVendorModule {}
