<div class="row">
  <div class="col-lg-12">
    <div class="text-center py-4" *ngIf="formLoading">
      <img src="assets/img/common/loader-small.gif" alt="Loading" />
    </div>
    <form
      class="form-horizontal"
      [formGroup]="tabVerification.formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <h5 class="separator">Admin Checklist</h5>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabVerification.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-verification.form.recommendation' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            [optionList]="recommendationVerificationOptionList"
            formControlName="recommendationVerification"
            (onChange)="showFormOption($event)"
          ></app-combo-box>
        </div>
      </div>

      <div
        *ngIf="isRecommended"
        class="form-group row text-sm-right"
        [ngClass]="!tabVerification.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-verification.form.vendorCategory' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box 
            [optionList]="vendorCategoryOptionList"
            formControlName="multipleVendorCategory"
            (onChange)="changeVendorCategory()">
          </app-combo-box>
        </div>
      </div>

      <div *ngIf="isRecommended" class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-verification.form.certificateNumber' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <p *ngIf="certificateNumber === null">-</p>
          <p *ngIf="certificateNumber !== null">{{ certificateNumber }}</p>
        </div>
      </div>

      <div
        *ngIf="isRecommended"
        class="form-group row text-sm-right"
        [ngClass]="!tabVerification.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-verification.form.certificatePeriod' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            [optionList]="certificatePeriodOptionList"
            formControlName="certificatePeriod"
          ></app-combo-box>
        </div>
      </div>

      <div
        *ngIf="showNoteVendor"
        class="form-group row text-sm-right"
        [ngClass]="!tabVerification.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-verification.form.noteForVendor' | translate
        }}</label>
        <div
          class="text-left"
          [ngClass]="
            !tabVerification.formGroup.isView ? 'col-sm-9' : 'col-sm-6'
          "
        >
          <app-text-area
            formControlName="noteForVendor"
            maxLength="1024"
          ></app-text-area>
        </div>
      </div>

      <div
        *ngIf="isRecommended"
        class="form-group row text-sm-right"
        [ngClass]="!tabVerification.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-verification.form.adminNote' | translate
        }}</label>
        <div
          class="text-left"
          [ngClass]="
            !tabVerification.formGroup.isView ? 'col-sm-9' : 'col-sm-6'
          "
        >
          <app-text-area
            formControlName="adminNote"
            maxLength="512"
          ></app-text-area>
        </div>
      </div>

      <div *ngIf="isRecommended" class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-verification.form.dueDiligence' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-toggle-switch
            *ngIf="!tabVerification.formGroup.isView && !isShowApprovalActivity"
            formControlName="isDueDiligence"
            (onChange)="showDueDiligenceTemplate($event)"
          ></app-toggle-switch>
          <ng-container
            *ngIf="tabVerification.formGroup.isView || isShowApprovalActivity"
          >
            <p *ngIf="tabVerification.formGroup.get('isDueDiligence').value">{{
              'tab-profile-verification.form.yes' | translate
            }}</p>
            <p *ngIf="!tabVerification.formGroup.get('isDueDiligence').value">{{
              'tab-profile-verification.form.no' | translate
            }}</p>
          </ng-container>
        </div>
      </div>

      <div
        *ngIf="isShowDueDiligenceTemplate && isRecommended"
        class="form-group row text-sm-right"
        [ngClass]="!tabVerification.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-verification.form.dueDiligenceTemplate' | translate
        }}</label>
        <div class="col-sm-8 text-left">
          <div class="form-row">
            <div class="form-group row col-sm-5">
              <div class="col text-left">
                <app-combo-box
                  size="XL"
                  [optionList]="dueDiligenceTemplateOptionList"
                  formControlName="dueDiligenceTemplate"
                  (onChange)="showChangeTooltip()"
                ></app-combo-box>
              </div>
            </div>
            <div class="form-group row col-sm-3">
              <div
                *ngIf="!tabVerification.formGroup.isView"
                class="col text-left"
              >
                <app-tooltip position="TOP_RIGHT">
                  <em class="fas fa-eye"></em>
                  <ng-template #tooltipContent>
                    <table
                      *ngIf="isLoadingTooltip"
                      class="table table-striped"
                      aria-describedby="TableLoading"
                    >
                      <thead>
                        <tr>
                          <th scope="col">
                            <div
                              class="text-center"
                              [ngStyle]="{ margin: '10vh 0' }"
                            >
                              <img
                                src="assets/img/common/loader-small.gif"
                                alt="Loading"
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                    </table>

                    <table
                      *ngIf="
                        !isLoadingTooltip &&
                        !dueDiligenceTemplateResponse.dueDiligenceTemplate?.id
                      "
                      class="table table-striped"
                      aria-describedby="TableNoData"
                    >
                      <thead>
                        <tr>
                          <th scope="col">
                            {{ 'app.info.noData' | translate }}
                          </th>
                        </tr>
                      </thead>
                    </table>

                    <table
                      *ngIf="
                        dueDiligenceTemplateResponse.dueDiligenceTemplate?.id &&
                        !isLoadingTooltip
                      "
                      class="table table-striped"
                      aria-describedby="Table"
                    >
                      <thead>
                        <tr>
                          <th scope="col">{{
                            'tab-profile-verification.form.dueDiligenceTemplate'
                              | translate
                          }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{
                            'tab-profile-verification.form.code' | translate
                          }}</td>
                          <td>{{
                            dueDiligenceTemplateResponse.dueDiligenceTemplate
                              .code
                          }}</td>
                        </tr>
                        <tr>
                          <td>{{
                            'tab-profile-verification.tooltip.name' | translate
                          }}</td>
                          <td>{{
                            dueDiligenceTemplateResponse.dueDiligenceTemplate
                              .name
                          }}</td>
                        </tr>
                        <tr
                          *ngIf="
                            dueDiligenceTemplateResponse
                              .attributeListByDueDiligenceTemplate.length != 0
                          "
                        >
                          <td>OTS</td>
                          <td>
                            <p
                              *ngFor="
                                let data of dueDiligenceTemplateResponse.attributeListByDueDiligenceTemplate;
                                let i = index
                              "
                            >
                              {{ data.description }}
                            </p>
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            dueDiligenceTemplateResponse
                              .attributeListByDueDiligenceTemplate.length == 0
                          "
                        >
                          <td>OTS</td>
                          <td>-</td>
                        </tr>
                        <tr
                          *ngIf="
                            dueDiligenceTemplateResponse
                              .questionListByDueDiligenceTemplate.length != 0
                          "
                        >
                          <td>Interview</td>
                          <td>
                            <p
                              *ngFor="
                                let data of dueDiligenceTemplateResponse.questionListByDueDiligenceTemplate;
                                let i = index
                              "
                            >
                              {{ data.name }}
                            </p>
                          </td>
                        </tr>
                        <tr
                          *ngIf="
                            dueDiligenceTemplateResponse
                              .questionListByDueDiligenceTemplate.length == 0
                          "
                        >
                          <td>Interview</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-template>
                </app-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <h5 *ngIf="isShowApprovalActivity" class="separator">{{
        'tab-profile-verification.form.approvalNote' | translate
      }}</h5>

      <div *ngIf="isShowApprovalActivity" class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-verification.form.approvalNote' | translate
        }}</label>
        <div class="col-sm-9 col-lg-5 text-left">
          <app-text-area formControlName="approvalNote"></app-text-area>
        </div>
      </div>
      <br />

      <!-- History Activity -->
      <h5 *ngIf="isShowApprovalActivity" class="separator">{{
        'tab-profile-verification.form.historyActivity' | translate
      }}</h5>
      <div
        *ngIf="isShowApprovalActivity"
        class="table-responsive custom-table float-none"
        [ngStyle]="{ margin: '0 auto' }"
      >
        <!---->
        <table class="table table-striped box-shadow" aria-describedby="Table">
          <thead>
            <tr class="text-left">
              <th scope="col">{{ 'app.table.column.no' | translate }} </th>
              <th scope="col" [ngStyle]="{ width: '150px' }">{{
                'tab-profile-verification.table.column.admin' | translate
              }}</th>
              <th scope="col" [ngStyle]="{ width: '200px' }">{{
                'tab-profile-verification.form.noteForVendor' | translate
              }}</th>
              <th scope="col" [ngStyle]="{ width: '200px' }">{{
                'tab-profile-verification.form.adminNote' | translate
              }}</th>
              <th scope="col" [ngStyle]="{ width: '150px' }">{{
                'tab-profile-verification.table.column.approver' | translate
              }}</th>
              <th scope="col" [ngStyle]="{ width: '200px' }">{{
                'tab-profile-verification.form.approvalNote' | translate
              }}</th>
              <th scope="col" class="text-center">{{
                'tab-profile-verification.table.column.recommendationDate'
                  | translate
              }}</th>
              <th scope="col" class="text-center">{{
                'tab-profile-verification.table.column.status' | translate
              }}</th>
              <th scope="col" class="text-center">{{
                'tab-profile-verification.table.column.file' | translate
              }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngIf="
                tabProfileVerificationDTO.vendorVerificationList.length == 0 ||
                tabProfileVerificationDTO.vendorVerificationList == null
              "
            >
              <td colspan="99" class="bg-white text-center">
                {{ 'app.info.noData' | translate }}</td
              >
            </tr>
            <tr
              *ngFor="
                let data of tabProfileVerificationDTO.vendorVerificationList;
                let i = index
              "
              class="text-left"
            >
              <td class="text-center">{{ i + 1 }}.</td>
              <td [ngStyle]="{ 'white-space': 'normal' }">{{
                data.verifiedByUser?.name
              }}</td>
              <td
                class="text-break"
                [ngStyle]="{ 'max-width': '300px', 'white-space': 'normal' }"
                >{{ data.vendorNote }}</td
              >
              <td
                class="text-break"
                [ngStyle]="{ 'max-width': '300px', 'white-space': 'normal' }"
                *ngIf="data.adminNote != null"
                >{{ data.adminNote }}</td
              >
              <td *ngIf="data.adminNote == null"> - </td>
              <td
                [ngStyle]="{ 'white-space': 'normal' }"
                *ngIf="data.approvedByUser != null"
                >{{ data.approvedByUser.name }}</td
              >
              <td *ngIf="data.approvedByUser == null"> - </td>
              <td
                class="text-break"
                [ngStyle]="{ 'max-width': '300px', 'white-space': 'normal' }"
                *ngIf="data.approvalNote != null"
                >{{ data.approvalNote }}</td
              >
              <td *ngIf="data.approvalNote == null"> - </td>
              <td class="text-center">{{
                data.recommendationDate
                  | date: global.config.parameterModel.dateFormatShort
              }}</td>
              <td class="text-center" *ngIf="data.isRecommended"
                >Recommended</td
              >
              <td class="text-center" *ngIf="!data.isRecommended"
                >Not Recommended</td
              >
              <td
                *ngIf="data.approvalFile"
                class="text-center"
                [ngStyle]="{ 'white-space': 'normal' }"
              >
                <a
                  href="#"
                  (click)="
                    doDownload(
                      $event,
                      '/on-boarding/file/view/' +
                        data.approvalFile.uploadedFileName +
                        '/' +
                        global.appConstant.fileType.DOC_APPROVAL,
                      data.approvalFile.fileName
                    )
                  "
                >
                  {{ data.approvalFile.fileName }}</a
                >
              </td>
              <td *ngIf="!data.approvalFile" class="text-center"> - </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <br />
    </form>
    <app-approval-prcs
      *ngIf="tabProfileVerificationDTO.isUserApproval"
      [workflowModelPrcs]="workflowModelPrcs"
    ></app-approval-prcs>
  </div>
  <!--*ngIf="tabProfileVerificationDTO.isUserApproval"-->
  <div class="button-group button-group-center">
    <app-button
      *ngIf="!tabVerification.formGroup.isView"
      color="SECONDARY"
      (onClick)="doPrevious()"
      [disabled]="formSaving"
      >{{ 'app.button.previous' | translate }}</app-button
    >
    <app-button
      *ngIf="!tabVerification.formGroup.isView"
      (onClick)="doSave()"
      [disabled]="formSaving"
      >{{ 'app.button.save' | translate }}</app-button
    >
  </div>
</div>
