<app-popup header="contract-draft.popup.header.participant.{{ todo }}">
  <form
    class="form-horizontal participant-reviewer"
    [formGroup]="formGroup"
    novalidate
  >
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'contract-draft.form.user' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          formControlName="userType"
          autofocus="true"
          size="LG"
          [optionList]="userOptionList"
          (onChange)="onChangeUserType($event)"
        ></app-combo-box>
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'contract-draft.form.email' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <ng-container
          *ngIf="isInternal; then internalImpl; else eksternalImpl"
        ></ng-container>
        <ng-template #internalImpl>
          <app-auto-complete
            formControlName="email"
            maxLength="512"
            (onChange)="autoCompleChanged($event)"
            stringUrl="/contract-draft/auto-complete-user-list"
            optionView="email"
            size="LG"
          ></app-auto-complete>
        </ng-template>
        <ng-template #eksternalImpl>
          <app-text-field
            formControlName="email"
            maxLength="512"
            size="LG"
            (onChange)="onChangeEmailEksternal()"
          ></app-text-field>
        </ng-template>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="isInternal ? '' : 'required'"
    >
      <label class="col-sm-3 control-label">{{
        'contract-draft.form.name' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          formControlName="name"
          maxLength="512"
          size="LG"
        ></app-text-field>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="isInternal ? '' : 'required'"
    >
      <label class="col-sm-3 control-label">{{
        'contract-draft.form.position' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          formControlName="position"
          maxLength="512"
          size="LG"
        ></app-text-field>
      </div>
    </div>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">
        {{ 'app.button.close' | translate }}
      </app-button>
      <app-button (onClick)="doSave()">
        {{ 'app.button.save' | translate }}
      </app-button>
    </div>
  </ng-template>
</app-popup>
