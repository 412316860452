<app-widget *ngIf="!formLoading" header="{{ 'app-widget-login-information.welcome' | translate }} {{ global.userSession?.user.name  }}">
  <!-- <p>Jakarta, {{ currentDate | date: global.config.constantMap['DATETIME_FORMAT_SHORT'] }}</p> -->
  <div class="table-responsive">
    <table class="table table-striped" aria-describedby="Table">
      <tbody>
        <tr>
          <th scope="col" class="border">
            {{ "app-widget-login-information.role" | translate }}
          </th>
          <td class="border">
            {{ global.userSession?.activeUserRole.role.name }}
          </td>
        </tr>
        <tr>
          <th scope="col" class="border">
            {{ "app-widget-login-information.organization" | translate }}
          </th>
          <td class="border">{{ organizationName || "-" }}</td>
        </tr>
        <tr>
          <th scope="col" class="border">
            {{ "app-widget-login-information.lastLogin" | translate }}
          </th>
          <td class="border">
            {{ lastLogin | date: global.config.parameterModel.datetimeFormatShort }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-widget>