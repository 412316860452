import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { SensitiveDataService } from 'src/app/core/services/sensitive-data.service';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Parameter } from '../../core/bean/parameter';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { AppLoadService } from '../../core/services/app-load.service';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './parameter-edit-add.component.html'
})
export class ParameterEditAddComponent extends BaseModuleComponent {
  public parameter: Parameter = new Parameter();
  public parameterId: number;
  public valueTypeOptionList: OptionListModel<any> = new OptionListModel();
  constructor(
    translateService: TranslateService,
    public appLoadService: AppLoadService,
    public sensitiveDataService: SensitiveDataService
  ) {
    super('parameter', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.parameterId = this.global.routerParams.get('parameterId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      groupName: [null],
      moduleGroupCode: [null],
      type: [null],
      valueTypeCode: [null],
      value: [null, Validators.compose([Validators.required()])],
      description: [null],
      rolesOwnerCode: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<Parameter>(
        '/parameter/edit',
        new RouteRequestModel(this.todo, this.parameterId)
      )
      .subscribe(parameter => {
        if (parameter != null) {
          this.parameter = parameter;
          this.formGroup.patchValue(parameter);
          this.formGroup.patchValue({
            groupName: this.parameter.moduleGroup.name,
            moduleGroupCode: this.parameter.moduleGroup.code,
            valueTypeCode: this.parameter.valueType.code,
            type: this.parameter.valueType.name
          });
          this.doSetValidators();
          this.doSetValueTypeOptionList();
          this.doSetValueTypeTime();
        }
        this.setStateReady();
      });
  }

  public doSetValidators(): void {
    const valueFormControl = this.formGroup.get('value');
    const { code } = this.parameter.valueType;
    if (code === 'STRING') {
      valueFormControl.setValidators(
        Validators.compose([Validators.required(), Validators.maxLength(128)])
      );
    } else if (code === 'NUMBER') {
      valueFormControl.setValidators(
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      );
    }
  }

  public doSetValueTypeOptionList(): void {
    if (this.parameter.valueType.code === 'BOOLEAN') {
      const booleanOptionList = [
        { view: 'True', value: 'TRUE' },
        { view: 'False', value: 'FALSE' }
      ];
      this.valueTypeOptionList
        .setRequestValues(booleanOptionList)
        .setViewPath('view')
        .setValuePath('value');
    }
  }

  public doSetValueTypeTime(): void {
    if (this.parameter.valueType.code === 'TIME') {
      const dateTime = '01-01-1970' + ' ' + this.formGroup.get('value').value;
      this.formGroup.get('value').patchValue(new Date(dateTime));
    }
  }
  public addZero(time): void {
    if (time < 10) {
      time = '0' + time;
    }
    return time;
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const parameter: Parameter = this.global.copyFormAttributeToModel(
              new Parameter(),
              this.formGroup
            );
            if (this.formGroup.get('valueTypeCode').value === 'TIME') {
              const valueHour = this.addZero(
                this.formGroup.get('value').value.getHours()
              );
              const valueMinutes = this.addZero(
                this.formGroup.get('value').value.getMinutes()
              );
              parameter.value = valueHour + ':' + valueMinutes;
            }
            parameter.moduleGroup.code = this.formGroup.get(
              'moduleGroupCode'
            ).value;
            parameter.valueType.code = this.formGroup.get(
              'valueTypeCode'
            ).value;

            if (this.parameter.isSensitive) {
              parameter.value = this.sensitiveDataService.encrypt([parameter.value]);
            }

            this.httpClientService
              .post<Response<Parameter>>('/parameter/update', parameter)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.appLoadService.getBackendConfig();
                  this.router.navigate(['/pages/parameter']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/parameter']);
  }
}
