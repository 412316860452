import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorBankHistory } from '../../core/bean/vendor-bank-history';
@Component({
  templateUrl: './app-popup-tab-bank.component.html'
})
export class AppPopupTabBankComponent extends BaseModuleComponent {
  @Input() header: string;
  @Input() vendorBankHistory: VendorBankHistory;
  public isPriority: string;
  constructor(translate: TranslateService) {
    super('app-popup-tab-bank', translate);
  }

  public onInit(): void {
    this.isPriority = this.vendorBankHistory.isPriority ? 'Yes' : 'No';
    this.setStateReady();
  }
}
