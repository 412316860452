import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseModuleComponent } from '../../base/angular/base-module.component';
import { Currency } from '../../bean/currency';
import { ReportRecap } from '../../bean/report-recap';
import { ReportRecapColumn } from '../../bean/report-recap-column';
import { ReportRecapFilter } from '../../bean/report-recap-filter';
import { ReportFilterGroup } from '../../bean/report-recap-filter-group';
import { AppPopupProgressBarDownloadComponent } from '../../components/app-popup/app-popup-progress-bar-download/app-popup-progress-bar-download.component';
import { ProgressBarDownloadModel } from '../../components/app-popup/app-popup-progress-bar-download/progress-bar-download-model';
import { AppPopupService } from '../../components/app-popup/app-popup.service';
import { ToastService } from '../../components/app-toast/app-toast.service';
import { TableColumnModel } from '../../components/table/model/table-column-model';
import { TableResponseModel } from '../../components/table/model/table-response-model';
import { OptionListModel } from '../../model/option-list-model';
import { RecapPrintService } from '../../services/recap-print.service';
import { ReportRecapBudgetUsedRequest } from './report-recap-budget-used-request';
import { ReportRecapBudgetUsedResponse } from './report-recap-budget-used-response';
import { ReportRecapBudgetUsedPopupChooseColumnComponent } from './report-recap-budget-used-popup-choose-column.component';
import { ReportRecapBudgetUsedPopupFullViewComponent } from './report-recap-budget-used-popup-full-view.component';
import { BudgetUsedRecapView } from '../../view/entity/bean/budget-used-recap-view';

@Component({
  templateUrl: './report-recap-budget-used.component.html'
})
export class ReportRecapBudgetUsedComponent extends BaseModuleComponent {
  public reportRecapId: number;
  public reportBuilderId: number;
  public reportRecapBudgetUsedResponse: ReportRecapBudgetUsedResponse =
    new ReportRecapBudgetUsedResponse();
  public reportRecapColumnList: ReportRecapColumn[];
  public reportRecapFilterList: ReportRecapFilter[];
  public reportFilterGroupList: ReportFilterGroup[];
  public reportRecap: ReportRecap;
  public tableResponse: TableResponseModel<BudgetUsedRecapView>;
  public periodProgramList: OptionListModel<Number> = new OptionListModel(
    true
  );
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true,
    'code'
  );
  public progressBarDownload = new ProgressBarDownloadModel();
  public httpRequest: Subscription;
  public generatedTableResponse: string;

  public isShowPopupModifyTable = false;
  public isFullViewTable = false;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public recapPrintService: RecapPrintService,
    public toastService: ToastService
  ) {
    super('report-recap-budget-used', translateService);
  }
  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setInitializationState();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      activityPeriodList: [null],
      organizationList: [null],
      costCenterCode: [null],
      coaCode: [null],
      requestor: [null],
      prNumber: [null],
      procurementNumber: [null],
      minimumAmount: [null],
      maximumAmount: [null]
    });
  }

  public buildTableResponse(): void {
    const arrayColumn: Array<TableColumnModel> = this.generatedTableResponse
      ? JSON.parse(this.generatedTableResponse)
      : null;
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      this.reportBuilderId
        ? arrayColumn
        : [
            {
              field: 'budgetOwner',
              header: 'table.column.budgetOwner'
            },
            {
              field: 'costCenter',
              header: 'table.column.costCenter'
            },
            {
              field: 'costCenterName',
              header: 'table.column.costCenterName'
            },
            {
              field: 'coa',
              header: 'table.column.coa'
            },
            {
              field: 'coaName',
              header: 'table.column.coaName'
            },
            {
              field: 'workProgram',
              header: 'table.column.workProgram'
            },
            {
              field: 'periodProgram',
              header: 'table.column.periodProgram',
            },
            {
              field: 'totalBudget',
              header: 'table.column.totalBudget',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: ' text-right'
            },
            {
              field: 'budgetUsed',
              header: 'table.column.budgetUsed',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: ' text-right'
            },
            {
              field: 'restBudget',
              header: 'table.column.restBugdet',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: ' text-right'
            },
            {
              field: 'prNumber',
              header: 'table.column.prNumber'
            },
            {
              field: 'prDate',
              header: 'table.column.prDate',
              plugins: {
                name: 'date',
                format: 'short-date'
              }
            },
            {
              field: 'procurementNumber',
              header: 'table.column.procurementNumber'
            },
            {
              field: 'procurementDate',
              header: 'table.column.procurementDate',
              plugins: {
                name: 'date',
                format: 'short-date'
              }
            },
            {
              field: 'procurementBudget',
              header: 'table.column.procurementBudget',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: ' text-right'
            },
            {
              field: 'procurementStage',
              header: 'table.column.procurementStage'
            },
            {
              field: 'requestor',
              header: 'table.column.requestor'
            },
            {
              field: 'buyer',
              header: 'table.column.buyer'
            }
          ]
    );
  }

  public setInitializationState(): void {
    this.httpClientService
      .get<ReportRecapBudgetUsedResponse>(
        '/report-recap-budget-used/edit?reportRecapId=' +
          this.reportRecapId +
          (this.reportBuilderId
            ? '&reportBuilderId=' + this.reportBuilderId
            : '')
      )
      .subscribe((response: ReportRecapBudgetUsedResponse) => {
        this.reportRecapBudgetUsedResponse = response;
        this.reportRecapColumnList = response.reportRecapColumnList;
        this.reportRecapFilterList = response.reportRecapFilterList;
        this.reportFilterGroupList = response.reportFilterGroupList;
        this.reportRecap = response.reportRecap;
        this.currencyOptionList.setRequestValues(response.currencyList);
        this.periodProgramList.setRequestValues(response.periodProgramList);
        this.setStateReady();
      });
  }

  public setDataFromRouterParams(): void {
    this.reportBuilderId = this.global.routerParams.get('reportBuilderId');
    this.reportRecapId = this.global.routerParams.get('reportRecapId');
    this.generatedTableResponse = this.global.routerParams.get(
      'generatedTableResponse'
    );
  }

  public doGenerate(): void {
    this.formGroup.patchValue({
      activityPeriodList: this.formGroup.value.activityPeriodList,
      costCenterCode: this.formGroup.value.costCenterCode,
      coaCode: this.formGroup.value.coaCode,
      requestor: this.formGroup.value.requestor,
      prNumber: this.formGroup.value.prNumber,
      procurementNumber: this.formGroup.value.procurementNumber
    });
    if (this.formGroup.value.minimumAmount) {
      this.formGroup.value.minimumAmount =
        this.formGroup.value.minimumAmount.price;
    }
    if (this.formGroup.value.maximumAmount) {
      this.formGroup.value.maximumAmount =
        this.formGroup.value.maximumAmount.price;
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.setCustomData(null);
    this.tableResponse.reload();
  }

  public doExport(): void {
    if (this.reportBuilderId) {
      this.appPopupService
        .open(AppPopupProgressBarDownloadComponent, {
          model: this.progressBarDownload
        })
        .subscribe(isCancel => {
          if (isCancel) {
            this.httpClientService.unsubscribe();
          }
        });
      const request = new ReportRecapBudgetUsedRequest();
      request.reportRecapColumnList = this.reportRecapColumnList;
      request.customData = JSON.stringify(this.tableResponse.getCustomData());

      this.recapPrintService
        .print('/report-recap-budget-used/print', request)
        .subscribe(
          response => {
            if (response) {
              this.progressBarDownload.setProgress(100);
              setTimeout(() => {
                this.global.modalService.downloadSuccess();
              }, 500);
            }
          },
          error => {
            error.error.text().then(text => this.toastService.showError(text));
          }
        );
    } else {
      this.appPopupService.open(
        ReportRecapBudgetUsedPopupChooseColumnComponent,
        {
          customData: this.tableResponse.getCustomData(),
          reportRecapColumnList: this.reportRecapColumnList
        }
      );
    }
  }

  public doFullView(): void {
    this.appPopupService.open(
      ReportRecapBudgetUsedPopupFullViewComponent,
      {
        customData: this.tableResponse.getCustomData(),
        reportRecapColumnList: this.reportRecapColumnList,
        reportBuilderId: this.reportBuilderId
      },
      { size: 'xl' }
    );
  }

  public doCustomSetting(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('reportBuilderId', this.reportBuilderId);
    this.router.navigate(['/pages/report-recap/edit']);
  }
}
