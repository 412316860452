import { IApprovalPathMapper } from '../interface/approval-path-mapper';
import { ApprovalPathItem } from '../model/approval-path-item';
export class ApprovalPathMapper<T> implements IApprovalPathMapper<T> {
  toModel(approverList: T[]): Array<ApprovalPathItem> {
    const currentPosition = approverList.findIndex(
      (approver: T) => (approver as any).currentSequence === true
    );
    return approverList.map((approver: T, index: number) => {
      const { position, organization, isApproved } = approver as any;
      const isCurrentPosition = currentPosition === index;
      return new ApprovalPathItem(
        position ? position.name : '-',
        organization ? organization.name : '-',
        isCurrentPosition,
        isApproved
      );
    });
  }
}
