import { BaseEntity } from '../base/base-entity';
import { Order } from '../bean/order';
import { TcDetail } from '../bean/tc-detail';

export class OrderTcEntity extends BaseEntity {

  crudOperation: number;
  order: Order;
  tcDetail: TcDetail;
  condition: string;
}
