<app-popup
  header="technical-evaluation.popup.technicalEvaluation"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="todo !== 'view' ? false : true"
>
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    *ngIf="!formLoading"
    novalidate
  >
    <h5 class="separator"
      >{{ 'technical-evaluation.popup.vendorInformation.title' | translate }}
    </h5>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'technical-evaluation.popup.vendorInformation.vendorName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <a
          (click)="goToProfile()"
          class="text-primary"
          style="text-decoration: underline"
          >{{ quotationEvaluation.quotation.procurementVendor.vendor.name }}
        </a>
        <span class="ml-1" (click)="goToProfile()"
          ><em
            class="pir pi-external-link"
            style="color: var(--primary)"
            placement="top"
            ngbTooltip="{{ 'technical-evaluation.goToProfile' | translate }}"
          ></em
        ></span>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'technical-evaluation.popup.vendorInformation.responseDate' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          quotationEvaluation.quotation.submittedDate
            | date: global.config.parameterModel.datetimeFormatShort
        }}
      </div>
    </div>
    <br />
    <div
      *ngIf="
        procurementCriteria.procurement.biddingEvaluation.code !==
        global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
      "
    >
      <h5 class="separator"
        >{{ 'technical-evaluation.popup.scoringCriteria.title' | translate }}
      </h5>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'technical-evaluation.popup.scoringCriteria.appliedFinalScore'
            | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{
            procurementCriteria.isAppliedFinalScoring
              ? ('technical-evaluation.popup.scoringCriteria.yes' | translate)
              : ('technical-evaluation.popup.scoringCriteria.no' | translate)
          }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'technical-evaluation.popup.scoringCriteria.minimumScore' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ procurementCriteria.minimumScore | resolveNumberToDecimal }}
        </div>
      </div>
      <div
        *ngIf="
          procurementCriteria.procurement.biddingEvaluation.code ===
            global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT &&
          procurementCriteria.isAppliedFinalScoring
        "
      >
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">{{
            'technical-evaluation.popup.scoringCriteria.technicalPercentage'
              | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              procurementFinalizationCriteria.technicalPercentage
                | resolveNumberToDecimal
            }}%
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">{{
            'technical-evaluation.popup.scoringCriteria.pricePercentage'
              | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              procurementFinalizationCriteria.pricePercentage
                | resolveNumberToDecimal
            }}%
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!formLoading">
      <app-alert-x color="WARNING" *ngIf="!isFullFill && isSave">{{
        'technical-evaluation.popup.criteriaEvaluation.alertInfo' | translate
      }}</app-alert-x>
      <app-table-xx
        [model]="tableResponse"
        [isView]="todo === 'view'"
        header="technical-evaluation.popup.criteriaEvaluation.title"
        [isShowSearch]="false"
        [isShowPagination]="false"
      >
        <ng-template
          #row
          *ngIf="
            procurementCriteria.procurement.biddingEvaluation.code !==
            global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
          "
        >
          <tr>
            <td></td>
            <td></td>
            <td class="text-right" colspan="3">
              {{
                'technical-evaluation.popup.criteriaEvaluation.totalScore'
                  | translate
              }}
            </td>
            <td class="text-right" colspan="3">
              <strong>
                {{ quotationEvaluation.score | resolveNumberToDecimal }}
              </strong>
            </td>
          </tr>
        </ng-template>
      </app-table-xx>

      <div
        style="display: flex"
        *ngIf="
          isFullFill &&
          procurementCriteria.procurement.biddingEvaluation.code ===
            global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
        "
      >
        {{
          ('technical-evaluation.popup.criteriaEvaluation.labelResult'
            | translate) + ' '
        }}
        {{ quotationEvaluation.quotation.procurementVendor.vendor.name + ' ' }}
        <span
          class="text-center font-weight-bold mx-1"
          [ngClass]="
            statusResult.code ===
            global.appConstant.pm.SCORING_STATUS_NOT_PASSED
              ? 'text-danger'
              : 'text-success'
          "
          >{{ (getTranslateKey(statusResult) | translate) + ' ' }}
        </span>
        {{
          ('technical-evaluation.popup.criteriaEvaluation.technicalEvaluation'
            | translate) + ' '
        }}
      </div>
      <div
        style="display: flex"
        *ngIf="
          isFullFill &&
          procurementCriteria.procurement.biddingEvaluation.code !==
            global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
        "
      >
        {{
          ('technical-evaluation.popup.criteriaEvaluation.labelResult'
            | translate) + ' '
        }}
        {{ quotationEvaluation.quotation.procurementVendor.vendor.name + ' ' }}
        {{
          ('technical-evaluation.popup.criteriaEvaluation.scoreIs'
            | translate) + ' '
        }}
        <span class="text-center font-weight-bold mx-1">
          {{ quotationEvaluation.score | resolveNumberToDecimal }}
        </span>
        {{
          ('technical-evaluation.popup.criteriaEvaluation.and' | translate) +
            ' '
        }}
        <span
          class="text-center font-weight-bold mx-1"
          [ngClass]="
            statusResult.code ===
            global.appConstant.pm.SCORING_STATUS_NOT_PASSED
              ? 'text-danger'
              : 'text-success'
          "
          >{{ (getTranslateKey(statusResult) | translate) + ' ' }}
        </span>
        {{
          ('technical-evaluation.popup.criteriaEvaluation.minimumScore'
            | translate) + ' '
        }}
        <span
          class="text-center font-weight-bold mx-1"
          [ngClass]="
            statusResult.code ===
            global.appConstant.pm.SCORING_STATUS_NOT_PASSED
              ? 'text-danger'
              : 'text-success'
          "
          >{{ procurementCriteria.minimumScore | resolveNumberToDecimal }}
        </span>
        {{
          ('technical-evaluation.popup.criteriaEvaluation.ofTechnicalEvaluation'
            | translate) + ' '
        }}
      </div>
    </div>

    <ng-template #modalFooter let-activeModal *ngIf="todo !== 'view'">
      <div class="col-12 text-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button color="PRIMARY" (onClick)="doSave()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
