<app-card header="{{ 'work-plan-payment.title' | translate }}" [isLoading]="formLoading" [isSaving]="formSaving">
  <app-fieldset *ngFor="
      let workPlanPaymentDTO of workPlanPaymentDTOList;
      let DTOIndex = index
    ">
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'work-plan-payment.form.purchaseOrder.number' | translate
        }}</label>
      <div class="col-sm-4 text-left">
        {{ workPlanPaymentDTO.purchaseOrder.number }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'work-plan-payment.form.purchaseOrder.date' | translate
        }}</label>
      <div class="col-sm-4 text-left">
        {{
        workPlanPaymentDTO.purchaseOrder.createdDate
        | date: global.config.parameterModel.dateFormatShort
        }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'work-plan-payment.form.vendorName' | translate
        }}</label>
      <div class="col-sm-4 text-left">
        {{ workPlanPaymentDTO.purchaseOrder.purchaseOrderItemList[0].vendor }}
      </div>
    </div>

    <app-fieldset legend="{{ 'work-plan-payment.form.paymentDetail' | translate }}">
      <div class="table-responsive custom-table mb-4 float-none" [ngStyle]="{ margin: '0 auto' }">
        <table class="table table-striped box-shadow" aria-describedby="Table">
          <thead>
            <tr>
              <th scope="col" class="text-sm-center">#</th>
              <th scope="col" class="text-sm-left">{{
                'work-plan-payment.table.column.item' | translate
                }}</th>
              <th scope="col" class="text-sm-left">{{
                'work-plan-payment.table.column.uom' | translate
                }}</th>
              <th scope="col" class="text-sm-right">{{
                'work-plan-payment.table.column.quantity' | translate
                }}</th>
              <th scope="col" class="text-sm-right">{{
                'work-plan-payment.table.column.paymentTotal' | translate
                }}</th>
              <th scope="col" class="text-sm-right">{{
                'work-plan-payment.table.column.paidTotal' | translate
                }}</th>
              <th scope="col" class="text-sm-right">{{
                'work-plan-payment.table.column.remainingPayment' | translate
                }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let purchaseOrderItem of workPlanPaymentDTO.purchaseOrder
                  .purchaseOrderItemList;
                let itemIndex = index
              ">
              <td class="text-center">{{ itemIndex + 1 }}.</td>
              <td class="text-left">{{
                purchaseOrderItem.purchaseRequestItem.workPlanItem.item.name
                }}</td>
              <td class="text-left">{{
                purchaseOrderItem.purchaseRequestItem.workPlanItem.item.uom.name
                }}</td>
              <td class="text-right">{{
                purchaseOrderItem.purchaseRequestItem.quantity
                }}</td>
              <td class="text-right">{{
                purchaseOrderItem.purchaseRequestItem.totalPriceRealize
                | currency: ' '
                }}</td>
              <td class="text-right">{{
                purchaseOrderItem.paymentAmount | currency: ' '
                }}</td>
              <td class="text-right">{{
                purchaseOrderItem.purchaseRequestItem.totalPriceRealize -
                purchaseOrderItem.paymentAmount | currency: ' '
                }}</td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td class="text-sm-right"><strong>{{
                  'work-plan-payment.table.column.total' | translate
                  }}</strong>
              </td>
              <td class="text-sm-right"><strong>{{
                  totalPriceList[DTOIndex] | currency: ' '
                  }}</strong></td>
              <td class="text-sm-right"><strong>{{
                  totalPaidList[DTOIndex] | currency: ' '
                  }}</strong></td>
              <td class="text-sm-right"><strong>{{
                  remainingPaymentList[DTOIndex] | currency: ' '
                  }}</strong></td>
            </tr>
          </tbody>
        </table>
      </div>
    </app-fieldset>
    <app-fieldset legend="{{ 'work-plan-payment.fieldset.title' | translate }}">
      <div class="form-group row text-sm-right required">
        <div class="col-sm-12 text-right">
          <button class="btn btn-primary btn-sm pull-right" type="button"
            [disabled]="remainingPaymentList[DTOIndex] === 0"
            (click)="doAddEditPurchaseOrderPaymentDetail(null, DTOIndex)">
            <span class="fas fa-plus"></span>
            <span>
              {{ 'work-plan-payment.button.addPayement' | translate }}</span>
          </button>
        </div>
      </div>

      <div class="table-responsive custom-table mb-4 float-none" [ngStyle]="{ margin: '0 auto' }">
        <table class="table table-striped box-shadow" aria-describedby="Table">
          <thead>
            <tr>
              <th scope="col" class="text-sm-left">#</th>
              <th scope="col" class="text-sm-left">{{
                'work-plan-payment.table.column.payment' | translate
                }}</th>
              <th scope="col" class="text-sm-right">{{
                'work-plan-payment.table.column.paymentAmount' | translate
                }}</th>
              <th scope="col" class="text-sm-left">{{
                'work-plan-payment.table.column.paymentDate' | translate
                }}</th>
              <th scope="col" class="text-sm-left">{{
                'work-plan-payment.table.column.invoice' | translate
                }}</th>
              <th scope="col">{{
                'work-plan-payment.table.column.action' | translate
                }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let purchaseOrderPayment of workPlanPaymentDTO.purchaseOrderPaymentList;
                let paymentIndex = index
              ">
              <td class="text-left">{{ paymentIndex + 1 }}.</td>
              <td>{{ purchaseOrderPayment.terminName }}</td>

              <td class="text-right">{{
                purchaseOrderPayment.paymentAmount | currency: ' '
                }}</td>
              <td>{{
                purchaseOrderPayment.paymentDate
                | date: global.config.parameterModel.dateFormatShort
                }}</td>
              <td>{{ purchaseOrderPayment.file.fileName }} </td>
              <td>
                <button class="btn btn-primary btn-sm mr-2" type="button" (click)="
                    doAddEditPurchaseOrderPaymentDetail(
                      purchaseOrderPayment,
                      DTOIndex,
                      paymentIndex
                    )
                  " title="{{ 'app.tooltip.edit' | translate }}">
                  <span class="fas fa-pencil-alt"></span>
                </button>
                <button class="btn btn-danger btn-sm mr-2" type="button" (click)="doDelete(DTOIndex, paymentIndex)"
                  title="{{ 'app.tooltip.delete' | translate }}">
                  <span class="fa fas fa-trash"></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </app-fieldset>
  </app-fieldset>
  <div class="row justify-content-center mt-5">
    <button class="btn btn-primary ml-2" type="button" (click)="doSave()">
      {{ 'app.button.save' | translate }}
    </button>
  </div>
  <div class="row justify-content-center mt-5">
    <button type="button " class="btn btn-secondary" (click)="doPrevious()" [disabled]="formSaving">
      {{ 'app.button.previous' | translate }}
    </button>
  </div>
</app-card>