import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { RfiStatus } from 'src/app/core/bean/rfi-status';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { VendorView } from 'src/app/core/view/entity/bean/vendor-view';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Currency } from '../../core/bean/currency';
import { Rfi } from '../../core/bean/rfi';
import { RfiItem } from '../../core/bean/rfi-item';
import { RfiVendor } from '../../core/bean/rfi-vendor';
import { RfiVendorNonPromise } from '../../core/bean/rfi-vendor-non-promise';
import { Vendor } from '../../core/bean/vendor';
import { VendorHistory } from '../../core/bean/vendor-history';
import { AppPopupVendorService } from '../../core/components/app-popup/app-popup-vendor/app-popup-vendor.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { AppPopupRfiItemComponent } from './app-popup-item.component';
import { AppPopupVendorNonPromiseComponent } from './app-popup-vendor-non-promise.component';
import { AppPopupVendorComponent } from './app-popup-vendor.component';
import { RfiDTO } from './dto/rfi.dto';

@Component({
  templateUrl: './rfi-edit-add.component.html'
})
export class RfiEditAddComponent extends BaseModuleComponent implements OnInit {
  @ViewChild('selectorItem') tableRfiItem: AppTableComponent;
  @ViewChild('selectorVendor') tableVendor: AppTableComponent;
  @ViewChild('selectorVendorNonPromise')
  tableVendorNonPromise: AppTableComponent;

  public vendorList: Vendor[] = [];
  public rfiVendorList: RfiVendor[] = [];
  public rfiVendor: RfiVendor;
  public rfiList: Rfi[] = [];
  public rfiItemList: RfiItem[] = [];
  public rfiDTO: RfiDTO = new RfiDTO();
  public rfiId: number;
  public rfiItem: RfiItem = new RfiItem();
  public currencyList: Currency[] = [];
  public vendorIdExcludeList: number[] = [];

  public tableResponseRfiItem: TableResponseModel<RfiItem>;
  public tableResponseVendor: TableResponseModel<RfiVendor>;
  public tableResponseVendorNonPromise: TableResponseModel<RfiVendorNonPromise>;

  public readonly urlRouterAddRfiQuestion =
    '/pages/request-for-information/question';
  public readonly urlRouterAddVendor =
    '/pages/request-for-information/add-vendor';
  public readonly urlRouterNavigateToRfi = '/pages/request-for-information';

  public fileUploader: FileUploader = new FileUploader(
    '/request-for-information/',
    'request-for-information.title',
    this.global.appConstant.fileType.DOC_RFI_TEMPLATE
  );

  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupVendorService: AppPopupVendorService,
    public toastService: ToastService
  ) {
    super('rfi', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setRfiDTO();
    this.doSetMinDateResponse();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.rfiId = this.global.routerParams.get('rfiId');
    this.rfiDTO = this.global.routerParams.get('rfiDTO');
  }

  public buildFormGroup(): void {
    if (Object.keys(this.formGroup.controls).length === 0) {
      const newDate: Date = new Date();
      this.formGroup = this.formBuilder.group({
        id: [null],
        title: [null, Validators.required()],
        currency: [null, Validators.required()],
        projectAmountFrom: [null, Validators.required()],
        projectAmountTo: [null, Validators.required()],
        responseDeadlineDate: [
          null,
          Validators.compose([Validators.required(), Validators.min('newDate')])
        ],
        publishDate: [null, Validators.required()],
        file: [null, Validators.required()],
        note: [null, Validators.required()],
        createdDate: [null],
        undisclosed: [null],
        minDateResponse: [null],
        newDate
      });
    }
  }

  public doDisableProjectAmount(): void {
    if (this.todo === 'add' && this.formGroup.get('undisclosed').value) {
      const formControlAmounts: string[] = [
        'currency',
        'projectAmountFrom',
        'projectAmountTo'
      ];
      formControlAmounts.forEach(formControl => {
        if (this.formGroup.get(formControl)) {
          this.formGroup.get(formControl).disable();
        }
      });
    }
  }

  public setRfiDTO(): void {
    if (this.rfiDTO) {
      this.currencyOptionList.setRequestValues(this.rfiDTO.currencyList);
      this.setFormGroup();
      this.buildTableResponseItem();
      this.buildTableResponseVendor();
      this.setStateReady();

      this.projectAmount();
    }

    if (this.todo === 'edit') {
      const formControlList: string[] = [
        'title',
        'publishDate',
        'responseDeadlineDate',
        'file'
      ];
      formControlList.forEach(formControl => {
        this.formGroup.get(formControl).setIsView(true);
      });
    }

    this.httpClientService
      .post<RfiDTO>(
        '/request-for-information/add-edit',
        new RouteRequestModel(this.todo, this.rfiId)
      )
      .subscribe(rfiDTO => {
        /* jika baru buka form, bukan back dari page question */
        if (
          !this.rfiDTO ||
          !this.rfiDTO.rfiItemList ||
          this.rfiDTO.rfiItemList.length === 0
        ) {
          this.rfiDTO = rfiDTO;
        }

        this.currencyOptionList.setRequestValues(rfiDTO.currencyList);
        this.setFormGroup();
        this.buildTableResponseItem();
        this.buildTableResponseVendor();
        this.projectAmount();

        this.setStateReady();
        this.doDisableProjectAmount();
      });
  }

  public projectAmount(): void {
    if (this.rfiDTO.rfi != null) {
      const rfiDTO = this.rfiDTO;
      if (
        (rfiDTO.rfi.projectAmountFrom === null &&
          rfiDTO.rfi.projectAmountTo === null &&
          rfiDTO.rfi.currency === null) ||
        rfiDTO.rfi.projectAmountFrom === undefined
      ) {
        const isUndisclosed = true;
        this.formGroup.patchValue({
          undisclosed: isUndisclosed
        });
        this.formGroup.get('projectAmountFrom')
          ? this.formGroup.get('projectAmountFrom').disable()
          : this.log.debug('projectAmountFrom null');

        this.formGroup.get('projectAmountTo')
          ? this.formGroup.get('projectAmountTo').disable()
          : this.log.debug('projectAmountTo null');

        this.formGroup.get('currency')
          ? this.formGroup.get('currency').disable()
          : this.log.debug('currency null');
      } else {
        this.formGroup.patchValue({
          undisclosed: false,
          currency: rfiDTO.rfi.currency,
          projectAmountFrom: rfiDTO.rfi.projectAmountFrom,
          projectAmountTo: rfiDTO.rfi.projectAmountTo
        });
      }
    }
  }

  public setFormGroup(): void {
    if (this.rfiDTO.rfi != null) {
      this.fileUploader.setFile(this.rfiDTO.rfi.file);

      const responseDeadlineDate: Date = new Date(
        this.rfiDTO.rfi.responseDeadlineDate
      );
      const publishDate: Date = new Date(this.rfiDTO.rfi.publishDate);
      const createdDate: Date = new Date(this.rfiDTO.rfi.createdDate);

      const rfiDTO = this.rfiDTO;
      this.formGroup.patchValue({
        id: rfiDTO.rfi.id,
        title: rfiDTO.rfi.title,
        responseDeadlineDate,
        publishDate,
        file: this.fileUploader.fileObjectList,
        note: rfiDTO.rfi.note,
        createdDate
      });
    }
  }

  public buildTableResponseItem(): void {
    this.tableResponseRfiItem = new TableResponseModel(this.moduleCode, [
      {
        field: 'item',
        header: 'table.column.item',
        customClass: 'white-space-normal'
      },
      {
        field: 'description',
        header: 'table.column.description',
        customClass: 'white-space-normal'
      },
      {
        field: 'quantityUom',
        header: 'table.column.quantity',
        customClass: 'text-center'
      }
    ]);

    this.tableResponseRfiItem.page.records = this.rfiDTO.rfiItemList;
    this.tableResponseRfiItem.page.totalRecords =
      this.rfiDTO.rfiItemList.length;
  }

  public buildTableResponseVendor(): void {
    this.tableResponseVendor = new TableResponseModel(this.moduleCode, [
      {
        field: 'vendorHistory.vendor.name',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      },
      {
        field: 'vendorHistory.vendor.vendorType.name',
        header: 'table.column.vendorType',
        customClass: 'text-center'
      },
      { field: 'vendorHistory.vendor.email', header: 'table.column.email' },
      {
        field: 'vendorHistory.address',
        header: 'table.column.location',
        customClass: 'white-space-normal'
      }
    ]);

    this.tableResponseVendor.page.records = this.rfiDTO.rfiVendorList;
    this.tableResponseVendor.page.totalRecords =
      this.rfiDTO.rfiVendorList.length;

    /* vendor non-promise di-hide dulu
    this.tableResponseVendorNonPromise = new TableResponseModel(this.moduleCode, [
      { field: 'vendor.name', header: 'table.column.name' },
      { field: 'vendor.email', header: 'table.column.email' },
      { field: 'vendor.phoneNumber', header: 'table.column.phone', customClass: 'text-center' },
      { field: 'address', header: 'table.column.address' },
    ]);

    this.tableResponseVendorNonPromise.page.records = this.rfiDTO.rfiVendorNonPromiseList;
    this.tableResponseVendorNonPromise.page.totalRecords = this.rfiDTO.rfiVendorNonPromiseList.length;
    */
  }

  public isNearDeadline(rfiVendor: RfiVendor): boolean {
    const today = new Date();
    const nearDeadlineCount =
      new Date(rfiVendor.rfi.responseDeadlineDate).getDate() +
      this.rfiDTO.parameterResponseInDays;

    /* mungkin seharusnya disesuaikan menjadi getTime()
    agar tgl awal bulan baru lebih besar drpd tgl akhir bulan lama */
    if (today.getDate() >= new Date(rfiVendor.rfi.publishDate).getDate()) {
      if (
        today.getDate() > new Date(rfiVendor.rfi.responseDeadlineDate).getDate()
      ) {
        return false;
      } else if (today.getDate() >= nearDeadlineCount) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public doSetMinDateResponse(publishDate?: Date): void {
    if (
      this.formGroup.get('publishDate') &&
      this.formGroup.get('publishDate').value
    ) {
      const publishDatee = publishDate
        ? publishDate
        : this.formGroup.get('publishDate').value;
      this.log.debug(publishDatee);
      const minDateResponse: Date = new Date();
      minDateResponse.setDate(publishDatee.getDate());
      minDateResponse.setMonth(publishDatee.getMonth());
      minDateResponse.setFullYear(publishDatee.getFullYear());
      minDateResponse.setHours(11);
      minDateResponse.setMinutes(59);
      minDateResponse.setSeconds(59);
      this.log.debug(minDateResponse);
      this.formGroup.patchValue({
        minDateResponse
      });

      if (
        this.formGroup.get('responseDeadlineDate').value <=
        this.formGroup.get('publishDate').value
      ) {
        this.formGroup.get('responseDeadlineDate').reset();
      }

      this.formGroup
        .get('responseDeadlineDate')
        .setValidators(
          Validators.compose([
            Validators.required(),
            Validators.min('minDateResponse', 'rfi.form.error.responseDeadline')
          ])
        );
    }
  }

  public doSendEmail(rfiVendor: RfiVendor): void {
    this.global.modalService
      .confirm(this.translateService.instant('rfi.confirmation.send.msg'))
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<RfiVendor>>(
              '/request-for-information/send-email',
              rfiVendor
            )
            .subscribe((response: Response<RfiVendor>) => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccess(
                  this.translateService.instant('app.alert.emailSuccess')
                );
              } else {
                this.global.alertService.showError(
                  this.translateService.instant('app.alert.emailError')
                );
              }
            });
        }
      });
  }

  public doAddItem(): void {
    const todo = 'add';
    const header = 'rfi.addItem';
    const rfiItemList = this.rfiDTO.rfiItemList;
    this.appPopupService
      .open(AppPopupRfiItemComponent, { todo, rfiItemList, header })
      .subscribe(rfiItem => {
        rfiItem.quantityUom = rfiItem.quantity + ' ' + rfiItem.uom.name;
        this.rfiDTO.rfiItemList.push(rfiItem);

        this.tableResponseRfiItem.setRecords(this.rfiDTO.rfiItemList);
        this.tableResponseRfiItem.setTotalRecords(
          this.rfiDTO.rfiItemList.length
        );
        this.tableResponseRfiItem.reloadClient();
        this.tableResponseRfiItem.setStateReady();
      });
  }

  public doEditItem(rfiItemEdit: RfiItem): void {
    const todo = 'edit';
    const header = 'rfi.editItem';
    const rfiItemList = this.rfiDTO.rfiItemList;
    this.appPopupService
      .open(AppPopupRfiItemComponent, {
        todo,
        rfiItemList,
        header,
        rfiItemEdit
      })
      .subscribe(rfiItemTemp => {
        this.rfiDTO.rfiItemList.forEach((rfiItem, index) => {
          if (rfiItem === rfiItemEdit) {
            rfiItemTemp.quantityUom =
              rfiItemTemp.quantity + ' ' + rfiItemTemp.uom.name;
            rfiItemTemp.id = rfiItemEdit.id;
            this.rfiDTO.rfiItemList[index] = rfiItemTemp;
          }
        });

        this.tableResponseRfiItem.setRecords(this.rfiDTO.rfiItemList);
        this.tableResponseRfiItem.setTotalRecords(
          this.rfiDTO.rfiItemList.length
        );
        this.tableResponseRfiItem.reloadClient();
        this.tableResponseRfiItem.setStateReady();
      });
  }

  public doDeleteItem(rfiItemDelete: RfiItem): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.rfiDTO.rfiItemList.forEach((rfiItem, index) => {
            if (rfiItem === rfiItemDelete) {
              this.rfiDTO.rfiItemList.splice(index, 1);
            }
          });

          this.tableResponseRfiItem.page.records = this.rfiDTO.rfiItemList;
          this.tableResponseRfiItem.page.totalRecords =
            this.rfiDTO.rfiItemList.length;
          this.tableResponseRfiItem.reloadClient();
        }
      });
  }

  public doAddVendor(): void {
    this.vendorIdExcludeList = this.rfiDTO.rfiVendorList.map(
      (rfiVendor: RfiVendor) => rfiVendor.vendorHistory.vendor.id
    );
    const vendorIdExcludeList = this.vendorIdExcludeList;
    this.appPopupService
      .open(AppPopupVendorComponent, { vendorIdExcludeList }, { size: 'xl' })
      .subscribe((vendorViewList: VendorView[]) => {
        const vendorIdList = [];
        vendorViewList.forEach((vendorView: VendorView) => {
          vendorIdList.push(vendorView.id);
          vendorIdExcludeList.push(vendorView.id);
        });

        this.tableResponseVendor.setStateLoading();
        this.httpClientService
          .post<Response<VendorHistory[]>>(
            '/request-for-information/get-last-vendor-history',
            JSON.stringify(vendorIdList)
          )
          .subscribe((vendorHistoryList: VendorHistory[]) => {
            vendorHistoryList.forEach(vendorHistory => {
              const rfiVendor = new RfiVendor();
              rfiVendor.vendorHistory = vendorHistory;
              rfiVendor.vendorHistory.address = vendorViewList.filter(
                vendorView => (vendorView.id = vendorHistory.vendor.id)
              )[0].location;
              this.rfiDTO.rfiVendorList.push(rfiVendor);
            });

            this.tableResponseVendor.setRecords(this.rfiDTO.rfiVendorList);
            this.tableResponseVendor.setTotalRecords(
              this.rfiDTO.rfiVendorList.length
            );
            this.tableResponseVendor.reloadClient();
            this.tableResponseVendor.setStateReady();
          });
      });
  }

  public doDeleteVendor(rfiVendorDelete: RfiVendor): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.rfiDTO.rfiVendorList.forEach((rfiVendor, index) => {
            if (rfiVendor === rfiVendorDelete) {
              this.rfiDTO.rfiVendorList.splice(index, 1);
              this.vendorIdExcludeList.splice(index, 1);
            }
          });

          this.tableResponseVendor.page.records = this.rfiDTO.rfiVendorList;
          this.tableResponseVendor.page.totalRecords =
            this.rfiDTO.rfiVendorList.length;
          this.tableResponseVendor.reloadClient();
        }
      });
  }

  public doViewResponseVendor(rfiVendor: RfiVendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', this.todo);
    this.global.routerParams.set('rfiId', this.rfiDTO.rfi.id);
    this.global.routerParams.set('rfiDTO', this.rfiDTO);
    this.global.routerParams.set('rfiVendorId', rfiVendor.id);
    this.router.navigate(['/pages/request-for-information/detail/response']);
  }

  public doAddVendorNonPromise(): void {
    const todo = 'Add';
    const header = 'Add Vendor Non Promise';
    this.appPopupService
      .open(AppPopupVendorNonPromiseComponent, { todo, header })
      .subscribe(rfiVendorNonPromise => {
        const rfiVendorNonPromiseTemp: RfiVendorNonPromise =
          rfiVendorNonPromise;
        rfiVendorNonPromiseTemp.phoneNumber =
          rfiVendorNonPromise.phone.country.callingCode +
          rfiVendorNonPromise.phone.number;
        rfiVendorNonPromiseTemp.phoneRegion = rfiVendorNonPromise.phone.country;
        rfiVendorNonPromiseTemp.phone = rfiVendorNonPromise.phone.number;

        this.rfiDTO.rfiVendorNonPromiseList.push(rfiVendorNonPromiseTemp);

        this.tableResponseVendorNonPromise.page.records =
          this.rfiDTO.rfiVendorNonPromiseList;
        this.tableResponseVendorNonPromise.page.totalRecords =
          this.rfiDTO.rfiVendorNonPromiseList.length;
        this.tableVendorNonPromise.reloadClient();
      });
  }

  public doEditVendorNonPromise(
    rfiVendorNonPromiseEdit: RfiVendorNonPromise
  ): void {
    const todo = 'Edit';
    const header = 'Edit Vendor Non Promise';
    this.appPopupService
      .open(AppPopupVendorNonPromiseComponent, {
        todo,
        rfiVendorNonPromiseEdit,
        header
      })
      .subscribe(rfiVendorNonPromiseTemp => {
        this.rfiDTO.rfiVendorNonPromiseList.forEach(
          (rfiVendorNonPromise, index) => {
            if (rfiVendorNonPromise === rfiVendorNonPromiseEdit) {
              rfiVendorNonPromiseEdit = rfiVendorNonPromiseTemp;

              rfiVendorNonPromiseEdit.phoneNumber =
                rfiVendorNonPromiseTemp.phone.country.callingCode +
                rfiVendorNonPromiseTemp.phone.number;
              rfiVendorNonPromiseEdit.phoneRegion =
                rfiVendorNonPromiseTemp.phone.country;
              rfiVendorNonPromiseEdit.phone =
                rfiVendorNonPromiseTemp.phone.number;
              rfiVendorNonPromiseEdit.name = rfiVendorNonPromiseTemp.name;
              rfiVendorNonPromiseEdit.address = rfiVendorNonPromiseTemp.address;
              rfiVendorNonPromiseEdit.email = rfiVendorNonPromiseTemp.email;
              this.rfiDTO.rfiVendorNonPromiseList[index] =
                rfiVendorNonPromiseEdit;
            }
          }
        );

        this.tableResponseVendorNonPromise.page.records =
          this.rfiDTO.rfiVendorNonPromiseList;
        this.tableResponseVendorNonPromise.page.totalRecords =
          this.rfiDTO.rfiVendorNonPromiseList.length;
        this.tableVendorNonPromise.reloadClient();
      });
  }

  public doDeleteVendorNonPromise(
    rfiVendorNonPromiseDelete: RfiVendorNonPromise
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.rfiDTO.rfiVendorNonPromiseList.forEach(
            (rfiVendorNonPromise, index) => {
              if (rfiVendorNonPromise === rfiVendorNonPromiseDelete) {
                this.rfiDTO.rfiVendorNonPromiseList.splice(index, 1);
              }
            }
          );
          this.tableResponseVendorNonPromise.page.records =
            this.rfiDTO.rfiVendorNonPromiseList;
          this.tableResponseVendorNonPromise.page.totalRecords =
            this.rfiDTO.rfiVendorNonPromiseList.length;
          this.tableVendorNonPromise.reloadClient();
        }
      });
  }

  public changeProjectAmount(): void {
    const isUndisclosed = this.formGroup.value.undisclosed;
    /* this.formGroup
      .get('projectAmountFrom')
      [isUndisclosed === true ? 'disable' : 'enable']();
    this.formGroup
      .get('projectAmountTo')
      [isUndisclosed === true ? 'disable' : 'enable']();
    this.formGroup
      .get('currency')
      [isUndisclosed === true ? 'disable' : 'enable'](); */

    const formControlAmounts: string[] = [
      'currency',
      'projectAmountFrom',
      'projectAmountTo'
    ];
    if (isUndisclosed === true) {
      formControlAmounts.forEach(formControl => {
        this.formGroup.get(formControl).disable();
      });

      /* this.formGroup.patchValue({
        projectAmountFrom: null,
        projectAmountTo: null,
        currency: null
      }); */

      this.formGroupService.bulkResetFormControl(
        this.formGroup,
        formControlAmounts
      );
    } else {
      formControlAmounts.forEach(formControl => {
        this.formGroup.get(formControl).enable();
      });
    }
  }

  public changeAmount(event: any): void {
    if (event !== null || event.trim() !== '') {
      if (
        +event > +this.formGroup.get('projectAmountTo').value ||
        this.formGroup.get('projectAmountTo').invalid
      ) {
        /* invalid = To < From */
        this.formGroup.get('projectAmountTo').reset();
      }
      this.formGroup
        .get('projectAmountTo')
        .setValidators(Validators.min(+event + 0.01));
    }
  }

  public doNext(): void {
    if (this.todo === 'edit' && !this.rfiDTO.rfi.projectAmountFrom) {
      /* edit & undisclosed */
      this.formGroupService.bulkRemoveFormControl(this.formGroup, [
        'projectAmountFrom',
        'projectAmountTo',
        'currency'
      ]);
    }

    this.validate();
    if (
      (this.todo === 'add' &&
        this.formGroup.valid &&
        this.rfiDTO.rfiItemList &&
        this.rfiDTO.rfiItemList.length > 0 &&
        this.rfiDTO.rfiVendorList &&
        this.rfiDTO.rfiVendorList.length > 0) ||
      (this.todo !== 'add' && this.formGroup.get('note').value)
    ) {
      this.rfiDTO.templateRfiList = this.fileUploader.fileObjectList;
      const rfiStatus: RfiStatus = this.rfiDTO.rfi
        ? this.rfiDTO.rfi.rfiStatus
        : null;
      this.rfiDTO.rfi = this.formGroup.value;
      this.rfiDTO.rfi.rfiStatus = rfiStatus;

      if (this.fileUploader.fileObjectList.length !== 0) {
        this.rfiDTO.rfi.file = this.fileUploader.fileObjectList[0].file;
      }
      this.global.routerParams.set('todo', this.todo);
      this.global.routerParams.set('rfiDTO', this.rfiDTO);
      this.router.navigate([this.urlRouterAddRfiQuestion]);
    } else {
      /*this.global.alertService.showError(
        this.translateService.instant('rfi.msg.alert.dataItemVendor')
      );*/

      this.toastService.showError(
        this.translateService.instant('rfi.msg.alert.dataItemVendor')
      );
    }
  }

  /* save old data without questions */
  public doSave(): void {
    this.validate();
    this.rfiDTO.templateRfiList = this.fileUploader.fileObjectList;
    this.rfiDTO.rfi = this.formGroup.value;
    if (this.fileUploader.fileObjectList.length !== 0) {
      this.rfiDTO.rfi.file = this.fileUploader.fileObjectList[0].file;
    }
    if (
      (this.todo === 'add' && this.formGroup.valid) ||
      (this.todo !== 'add' &&
        this.formGroup.get('note').value &&
        this.rfiDTO.rfiQuestionList.length === 0)
    ) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            this.rfiDTO.rfi.publishDate.setHours(0);
            this.rfiDTO.rfi.responseDeadlineDate.setHours(23);
            this.rfiDTO.rfi.responseDeadlineDate.setMinutes(59);
            this.rfiDTO.rfi.responseDeadlineDate.setSeconds(59);
            this.log.debug(
              this.rfiDTO.rfi.publishDate +
                ', ' +
                this.rfiDTO.rfi.responseDeadlineDate
            );

            const urlSave =
              this.todo === 'edit'
                ? '/request-for-information/update'
                : '/request-for-information/insert';
            this.httpClientService
              .post<Response<RfiDTO>>(urlSave, this.rfiDTO)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate([this.urlRouterNavigateToRfi]);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate([this.urlRouterNavigateToRfi]);
  }
}
