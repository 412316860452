import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contract } from '../../../bean/contract';
import { FinesMaster } from '../../../bean/fines-master';
import { ProcurementFines } from '../../../bean/procurement-fines';
import { ProcurementVendor } from '../../../bean/procurement-vendor';
import { AppPopupService } from '../app-popup.service';
import { AppPopupProcurementFinesComponent } from './app-popup-procurement-fines.component';

@Injectable()
export class AppPopupProcurementFinesService {
  constructor(public appPopupService: AppPopupService) {}
  public open(
    todo: string,
    header: string,
    contractFinesEdit: ProcurementFines,
    finesList: FinesMaster[] = [],
    contract: Contract,
    procurementVendor: ProcurementVendor
  ): Observable<any> {
    return this.appPopupService.open(AppPopupProcurementFinesComponent, {
      todo,
      header,
      contractFinesEdit,
      finesList,
      contract,
      procurementVendor
    });
  }
}
