import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppRadioComponent } from './app-radio.component';
@NgModule({
  imports: [VendorModule, AppTooltipWrapperModule, PipesModule],
  declarations: [AppRadioComponent],
  exports: [AppRadioComponent]
})
export class AppRadioModule {}
