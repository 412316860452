<app-popup
  header="tab-experience.popup.experience.title"
  [isLoading]="formLoading"
>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-experience.form.projectName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorExperienceHistory.projectName !== null
                ? vendorExperienceHistory.projectName
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-experience.form.businessPartnerName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorExperienceHistory.businessPartnerName !== null
                ? vendorExperienceHistory.businessPartnerName
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-experience.form.country' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorExperienceHistory.countryRegion !== null
                ? vendorExperienceHistory.countryRegion.name
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-experience.form.city' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorExperienceHistory.cityRegion !== null
                ? vendorExperienceHistory.cityRegion.name
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-experience.form.address' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            {{
              vendorExperienceHistory.address !== null
                ? vendorExperienceHistory.address
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right" *ngIf="!formLoading">
          <label class="col-sm-3 control-label">{{
            'tab-experience.form.coordinateLocation' | translate
          }}</label>
          <div class="col-sm-7 text-left">
            {{
              vendorExperienceHistory.coordinateLocation !== null
                ? vendorExperienceHistory.coordinateLocation
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-experience.form.projectOwner' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorExperienceHistory.projectOwner !== null
                ? vendorExperienceHistory.projectOwner
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-experience.form.projectType' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorExperienceHistory.projectType !== null
                ? vendorExperienceHistory.projectType
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-experience.form.segmentation' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ segmentationList !== null ? segmentationList : '-' }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-experience.form.status' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorExperienceHistory.vendorExperienceStatus !== null
                ? vendorExperienceHistory.vendorExperienceStatus.name
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-experience.form.contractNumber' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorExperienceHistory.contractNumber !== null
                ? vendorExperienceHistory.contractNumber
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-experience.form.contractDate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorExperienceHistory.contractDate === null
                ? '-'
                : (vendorExperienceHistory.contractDate | date: 'dd/MM/yyyy')
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-experience.form.contractValue' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ convertContract ? convertContract : '-' }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-experience.form.proofOfContract' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div *ngIf="vendorExperienceHistory.file == null"> - </div>
            <div *ngIf="vendorExperienceHistory.file != null">
              <a
                href="#"
                (click)="
                  doDownload(
                    $event,
                    '/company-profile/file/view/' +
                      vendorExperienceHistory.file.uploadedFileName +
                      '/' +
                      global.appConstant.fileType.DOC_PROOF_CONTRACT,
                    vendorExperienceHistory.file.fileName
                  )
                "
              >
                {{ vendorExperienceHistory.file.fileName }}
              </a>
            </div>
          </div>
        </div>
        <ng-template #modalFooter let-activeModal>
          <div class="button-group button-group-center">
            <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
              'app.button.close' | translate
            }}</app-button>
          </div>
        </ng-template>
      </form>
    </div>
  </div>
</app-popup>
