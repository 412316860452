import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppChartModule } from '../../../../../core/components/app-chart/app-chart.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetContractPerStatusComponent } from './widget-contract-per-status.component';
@NgModule({
  imports: [VendorModule, AppChartModule, WidgetCardModule],
  declarations: [WidgetContractPerStatusComponent],
  entryComponents: [WidgetContractPerStatusComponent],
  exports: [WidgetContractPerStatusComponent]
})
export class WidgetContractPerStatusModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetContractPerStatusComponent.moduleCode,
      WidgetContractPerStatusComponent,
      'w-50'
    );
  }
}
