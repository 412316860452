<app-popup>
  <app-login-form (onClosePopupLoginForm)="onClosePopupLoginForm()">
    <em
      [ngStyle]="{ right: '1.5rem', top: '1.5rem' }"
      class="fas fa-times cursor-pointer position-absolute"
      (click)="appPopup.ngbActiveModal.close()"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    ></em>
  </app-login-form>
</app-popup>
