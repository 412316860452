import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppPopupComponent } from './app-popup.component';
import { AppPopupService } from './app-popup.service';
@NgModule({
  imports: [VendorModule, DirectivesModule],
  declarations: [AppPopupComponent],
  entryComponents: [AppPopupComponent],
  providers: [AppPopupService],
  exports: [AppPopupComponent]
})
export class AppPopupModule {}
