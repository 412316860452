<div
  class="drag-and-drop-wrapper"
  id="{{ dragDropId }}"
  cdkDropList
  [cdkDropListConnectedTo]="connectedTo"
  [cdkDropListData]="dataList"
  (cdkDropListDropped)="onDrop($event)"
>
  <div
    class="item"
    *ngFor="let data of dataList; let i = index"
    cdkDrag
    [cdkDragDisabled]="disabled"
  >
    <ng-container
      *ngTemplateOutlet="
        dragDropItemTmpl;
        context: { $implicit: data.widget.module.code, index: i }
      "
    >
    </ng-container>
  </div>
  <ng-content></ng-content>
</div>
