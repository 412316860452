import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipe/pipe.module';
import { DirectivesModule } from '../../../../core/directives/directives.module';
import { HelpModule } from '../help/help.module';
import { NotificationModule } from '../notification/notification.module';
import { ProfileModule } from '../profile/profile.module';
import { ShoppingCartModule } from '../shopping-cart/shopping-cart.module';
import { HeaderComponent } from './header.component';
import { HeaderService } from './header.service';

@NgModule({
  imports: [
    CommonModule,
    NotificationModule,
    ProfileModule,
    ShoppingCartModule,
    HelpModule,
    DirectivesModule,
    PipesModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule {
  public static forRoot(): ModuleWithProviders<HeaderModule> {
    return {
      ngModule: HeaderModule,
      providers: [HeaderService]
    };
  }

  // public static forChild(options?: object): ModuleWithProviders<HeaderModule> {
  //   return {
  //     ngModule: HeaderModule,
  //     providers: [HeaderService],
  //   };
  // }
}
