import { Contract } from '../../../core/bean/contract';
import { BaseDTO } from '../../../core/base/base-dto';
import { PurchaseRequest } from '../../../core/bean/purchase-request';

export class WorkPlanContractDTO extends BaseDTO {
    purchaseRequestList: PurchaseRequest[];
    contractList: Contract[];
    organizationName: string;
    purchaseRequestListIsSelected: PurchaseRequest[];
    workPlanRealizeId: number;

}
