import { NgModule } from '@angular/core';
import { AppButtonModule } from '../../../core/components/app-button/app-button.module';
import { AppPopupModule } from '../../../core/components/app-popup/app-popup.module';
import { AppTextFieldModule } from '../../../core/components/app-text-field/app-text-field.module';
import { VendorModule } from '../../../core/vendor/vendor.module';
import { AppPopupChangeEmailComponent } from './app-popup-change-email.component';
@NgModule({
    imports: [VendorModule, AppPopupModule, AppTextFieldModule, AppButtonModule],
    declarations: [AppPopupChangeEmailComponent],
    entryComponents: [AppPopupChangeEmailComponent],
    exports: [AppPopupChangeEmailComponent]
})
export class AppPopupChangeEmailModule { }
