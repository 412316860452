import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ItemType } from '../../core/bean/item-type';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { RefutationReview } from '../../core/bean/refutation-review';
import { RefutationReviewStatus } from '../../core/bean/refutation-review-status';
import { AppOfficialReportModel } from '../../core/components/app-official-report/app-official-report-model';
import { AppPopupFinalPriceDetailService } from '../../core/components/app-popup/app-popup-final-price-detail/app-popup-final-price-detail.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { ToastService } from '../../core/components/app-toast/app-toast.service';
import { WorkflowStepInfoModel } from '../../core/components/app-workflow-step-info/model/workflow-step-info-model';
import {
  TableHeaderPluginData,
  TablePluginData
} from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { Validators } from '../../core/validators';
import { RefutationBuyerByItemView } from '../../core/view/entity/bean/refutation-buyer-by-item-view';
import { RefutationParticipantSingleWinnerView } from '../../core/view/entity/bean/refutation-participant-single-winner-view';
import { VendorQuotationView } from '../../core/view/entity/bean/vendor-quotation-view';
import { VendorReferenceSelectedVendorView } from '../../core/view/entity/bean/vendor-reference-selected-vendor-view';
import { AppPopupVendorRefutationComponent } from './app-popup-vendor-refutation.component';
import { RefutationRequest } from './refutation-request';
import { RefutationResponse } from './refutation-response';
@Component({
  templateUrl: './refutation-edit.component.html'
})
export class RefutationEditComponent extends BaseModuleComponent {
  public objectId: number;
  public isUpdated: boolean;
  public procurementId: number;
  public urlBackOutside: string;
  public approvalModelPrcsId: number;
  public procurement: Procurement;
  public procurementItemList: ProcurementItem[];
  public today: Date = new Date();
  public itemTypeList: ItemType[];
  public workflowStepInfoModel: WorkflowStepInfoModel;
  public refutationResponse: RefutationResponse;
  public refutationReviewList: RefutationReview[] = [];
  public refutationBuyerByItemOriginList: RefutationBuyerByItemView[];
  public tableResponseWinningCandidateVendor: TableResponseModel<VendorQuotationView>;
  public tableResponseReferenceByItem: TableResponseModel<RefutationBuyerByItemView>;
  public tableResponseReferenceByVendor: TableResponseModel<VendorReferenceSelectedVendorView>;
  public tableResponseVendorParticipant: TableResponseModel<RefutationParticipantSingleWinnerView>;
  public refutationReviewStatusOptionList: OptionListModel<RefutationReviewStatus> =
    new OptionListModel(true);
  public appOfficialReportModel: AppOfficialReportModel;
  public isOfficialReportNull = true;
  public templateCode: string;
  public isMandatory = false;
  public canceledOrFailedMessage: string;
  public isShowBadgeStatus: boolean;

  public badgeColor = {
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    NEW: 'INFO',
    DRAFT: 'SECONDARY',
    WAITING_RESPONSE: 'GOOD',
    CLOSED_RESPONSE: 'DANGER',
    HOLD: 'DANGER',
    CANCELED: 'DANGER',
    FAILED: 'DANGER'
  };

  constructor(
    translateService: TranslateService,
    public toastService: ToastService,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute,
    public appPopupService: AppPopupService,
    public appPopupFinalPriceDetailService: AppPopupFinalPriceDetailService
  ) {
    super('refutation', translateService);
  }
  public onInit(): void {
    this.setDataFromRouterParams();
    this.setFormGroup();
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.procurement = this.global.routerParams.get('procurement');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.refutationResponse =
      this.global.routerParams.get('refutationResponse');
    this.isUpdated = this.global.routerParams.get('isUpdated');
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      procurement: [null],
      procurementItem: [null],
      refutationDate: [null, Validators.required()],
      note: [null, Validators.required()],
      refutationReviewStatus: [null, Validators.required()]
    });
  }

  public setFormGroup() {
    this.httpClientService
      .post<RefutationResponse>(
        '/refutation/edit',
        new RouteRequestModel(
          this.todo,
          this.procurementId || this.procurement.id
        )
      )
      .subscribe((response: RefutationResponse) => {
        this.refutationResponse = this.refutationResponse || response;
        this.refutationBuyerByItemOriginList =
          response.refutationBuyerByItemViewList;
        this.procurement = this.refutationResponse.procurement;
        this.procurementItemList = this.refutationResponse.procurementItemList;
        this.itemTypeList = this.refutationResponse.itemTypeList;
        this.workflowStepInfoModel =
          this.refutationResponse.workflowStepInfoModel;
        this.appOfficialReportModel = response.appOfficialReportModel;
        this.isOfficialReportNull = response.appOfficialReportModel.id
          ? false
          : true;
        this.doBuildTableResponse();
        if (
          this.procurement.vendorSelectionType.code ===
          this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
        ) {
          this.doBuildFormGroup();
          this.refutationReviewStatusOptionList.setRequestValues(
            this.refutationResponse.refutationReviewStatusList
          );

          if (this.refutationResponse.refutationReview) {
            const {
              id,
              procurement,
              procurementItem,
              refutationDate,
              note,
              refutationReviewStatus
            } = this.refutationResponse.refutationReview;
            this.formGroup.patchValue({
              id,
              procurement,
              procurementItem,
              refutationDate,
              note,
              refutationReviewStatus
            });
          } else {
            if (
              this.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm
                .PROCUREMENT_WORKLIST_STATUS_CLOSED_RESPONSE
            ) {
              if (
                !this.refutationResponse.refutationParticipantSingleWinnerViewList.find(
                  vendorParticipant =>
                    vendorParticipant.submittedStatus.code ===
                    this.global.appConstant.pm.SUBMITTED_STATUS_AVAILABLE
                )
              ) {
                this.formGroup.patchValue({
                  refutationDate: this.today,
                  refutationReviewStatus:
                    this.refutationResponse.refutationReviewStatusList.find(
                      status =>
                        status.code ===
                        this.global.appConstant.pm
                          .REFUTATION_REVIEW_STATUS_NOT_ACCEPTED
                    )
                });
              }
            }
          }
        }

        this.templateCode =
          this.procurement.vendorSelectionType.code ===
          this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
            ? this.global.appConstant.pm
                .OFFICIAL_REPORT_CODE_ORT_REFUTATION_SINGLE_WINNER_LETTER
            : this.global.appConstant.pm
                .OFFICIAL_REPORT_CODE_ORT_REFUTATION_MULTI_WINNER_LETTER;

        const statusList = [
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
        ];

        if (statusList.includes(this.procurement.procurementStatus.code)) {
          if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'refutation.alert.msg.sorryWaitingCancelationApproval';
          } else if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'refutation.alert.msg.sorryWaitingFailedApproval';
          } else {
            this.canceledOrFailedMessage =
              'refutation.alert.msg.sorryFailedAssessment';
          }
        }
        if (this.isUpdated) {
          this.appOfficialReportModel.isGenerate = this.appOfficialReportModel
            .isGenerate
            ? false
            : false;
          this.isOfficialReportNull = true;
        }
        this.setStateReady();
        if (this.todo === 'view') {
          this.setIsViewOnly();
        }
      });
  }

  public doBuildTableResponse(): void {
    if (
      this.procurement.vendorSelectionType.code ===
      this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
    ) {
      this.tableResponseWinningCandidateVendor = new TableResponseModel(
        this.moduleCode,
        this.procurement.biddingEvaluation.code !==
        this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
          ? [
              {
                field: 'vendorName',
                header: 'table.column.vendorName',
                plugins: {
                  name: 'hyperlink'
                }
              },
              {
                header: 'table.column.evaluation',
                columnList: [
                  {
                    field: 'administrationScoreStatus.name',
                    header: 'table.column.administration',
                    plugins: {
                      name: 'badge',
                      colorField: 'administrationScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  {
                    field: 'technicalScoreStatus.name',
                    header: 'table.column.technical',
                    plugins: {
                      name: 'badge',
                      colorField: 'technicalScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  this.procurement.biddingEvaluation.code ===
                  this.global.appConstant.pm
                    .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
                    ? {
                        field: 'finalPriceScoreStatus.name',
                        header: 'table.column.price',
                        plugins: {
                          name: 'badge',
                          colorField: 'finalPriceScoreStatus.code',
                          colorMap: {
                            PASSED: 'SUCCESS',
                            NOT_PASSED: 'DANGER'
                          }
                        }
                      }
                    : {
                        field: 'finalPriceScore',
                        header: 'table.column.price',
                        className: 'text-right',
                        plugins: {
                          name: 'text-field',
                          type: 'decimal',
                          isView: true
                        }
                      }
                ]
              },
              {
                field: 'finalPrice',
                header: 'table.column.finalPrice',
                plugins: {
                  name: 'default',
                  type: 'currency'
                },
                className: 'text-right'
              },
              {
                field: 'finalProfitLossAmount',
                header: 'table.column.savingLoss',
                className: 'text-right',
                plugins: [
                  {
                    name: 'custom-plugin',
                    before: (tablePlugin: TablePluginData): void => {
                      if (tablePlugin.value) {
                        tablePlugin.element.innerText =
                          this.global.converterService.convertMoney(
                            tablePlugin.value
                          ) + ' %';
                      }
                    }
                  },
                  {
                    name: 'badge',
                    field: 'finalProfitLossStatus.name',
                    colorMap: {
                      SAVE: 'SUCCESS',
                      LOSS: 'DANGER'
                    },
                    pill: false,
                    colorField: 'finalProfitLossStatus.code',
                    className: 'badge-catalog ml-3'
                  }
                ]
              }
            ]
          : [
              {
                field: 'vendorName',
                header: 'table.column.vendorName',
                plugins: {
                  name: 'hyperlink'
                }
              },
              {
                header: 'table.column.evaluation',
                columnList: [
                  {
                    field: 'administrationScoreStatus.name',
                    header: 'table.column.administration',
                    plugins: {
                      name: 'badge',
                      colorField: 'administrationScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  {
                    field: 'technicalScore',
                    header: {
                      key: 'table.column.technical',
                      plugins: {
                        name: 'custom-plugin',
                        after: (
                          tableHeaderPlugin: TableHeaderPluginData
                        ): void => {
                          if (tableHeaderPlugin) {
                            tableHeaderPlugin.element.innerText +=
                              ' (' +
                              (this.refutationResponse
                                .procurementFinalizationCriteria
                                .technicalPercentage || ' - ') +
                              '%)';
                          }
                        }
                      }
                    },
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  },
                  {
                    field: 'finalPriceScore',
                    header: {
                      key: 'table.column.price',
                      plugins: {
                        name: 'custom-plugin',
                        after: (
                          tableHeaderPlugin: TableHeaderPluginData
                        ): void => {
                          if (tableHeaderPlugin) {
                            tableHeaderPlugin.element.innerText +=
                              ' (' +
                              (this.refutationResponse
                                .procurementFinalizationCriteria
                                .pricePercentage || ' - ') +
                              '%)';
                          }
                        }
                      }
                    },
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  },
                  {
                    field: 'totalFinalEvaluationScore',
                    header: 'table.column.total',
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  }
                ]
              },
              {
                field: 'finalPrice',
                header: 'table.column.finalPrice',
                plugins: {
                  name: 'default',
                  type: 'currency'
                },
                className: 'text-right'
              },
              {
                field: 'finalProfitLossAmount',
                header: 'table.column.savingLoss',
                className: 'text-right',
                plugins: [
                  {
                    name: 'custom-plugin',
                    before: (tablePlugin: TablePluginData): void => {
                      if (tablePlugin.value) {
                        tablePlugin.element.innerText =
                          this.global.converterService.convertMoney(
                            tablePlugin.value
                          ) + ' %';
                      }
                    }
                  },
                  {
                    name: 'badge',
                    field: 'finalProfitLossStatus.name',
                    colorMap: {
                      SAVE: 'SUCCESS',
                      LOSS: 'DANGER'
                    },
                    pill: false,
                    colorField: 'finalProfitLossStatus.code',
                    className: 'badge-catalog ml-3'
                  }
                ]
              }
            ]
      );
      this.tableResponseWinningCandidateVendor.setRecordList(
        this.refutationResponse.vendorQuotationViewList
      );
      this.tableResponseWinningCandidateVendor.reload();

      this.tableResponseVendorParticipant = new TableResponseModel(
        this.moduleCode,
        [
          {
            field: 'vendor.name',
            header: 'table.column.vendorName',
            plugins: {
              name: 'hyperlink',
              onClick: this.doShowVendorRefutation.bind(this)
            }
          },
          {
            field: 'submittedStatus.name',
            header: 'table.column.refutationStatus',
            plugins: {
              name: 'badge',
              colorField: 'submittedStatus.code',
              colorMap: {
                AVAILABLE: 'SUCCESS',
                UNAVAILABLE: 'SECONDARY',
                WAITING_RESPONSE: 'GOOD'
              }
            }
          }
        ]
      );
      this.tableResponseVendorParticipant.setRecordList(
        this.refutationResponse.refutationParticipantSingleWinnerViewList
      );
      this.tableResponseVendorParticipant.reload();
    } else {
      this.buildTableReferenceByItem();
      this.buildTableReferenceByVendor();
    }
  }

  public buildTableReferenceByItem(): void {
    this.tableResponseReferenceByItem = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'procurementItem.prItem.item.name',
          header: 'table.column.itemName',
          plugins: {
            name: 'hyperlink',
            onClick: this.doClickRefutationItem.bind(this)
          }
        },
        {
          field: 'procurementItem.prItem.pr.code',
          header: 'table.column.prNumber'
        },
        {
          field: 'procurementItem.prItem.code',
          header: 'table.column.prLine'
        },
        {
          field: 'procurementItem.prItem.item.code',
          header: 'table.column.itemCode'
        },
        {
          field: 'procurementItem.prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'procurementItem.prItem.item.itemType.code'
          }
        },
        {
          field: 'procurementItem.prItem.quantity',
          header: 'table.column.quantity',
          className: 'text-right',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            isView: true
          }
        },
        {
          field: 'procurementItem.prItem.item.uom.name',
          header: 'table.column.unit'
        },
        {
          field: 'winningVendor',
          header: 'table.column.winningVendor'
        },
        {
          field: 'submittedStatus.name',
          header: 'table.column.refutationStatus',
          plugins: [
            {
              name: 'badge',
              colorField: 'submittedStatus.code',
              colorMap: {
                AVAILABLE: 'SUCCESS',
                UNAVAILABLE: 'SECONDARY',
                WAITING_RESPONSE: 'GOOD',
                ACCEPTED: 'SUCCESS',
                NOT_ACCEPTED: 'DANGER'
              },
              callbacks: {
                text: (tablePluginData: TablePluginData): string => {
                  if (tablePluginData.row.record.refutationReview) {
                    return tablePluginData.row.record.refutationReview
                      .refutationReviewStatus.name;
                  } else {
                    return tablePluginData.row.record.submittedStatus.name;
                  }
                },
                color: (tablePluginData: TablePluginData): string => {
                  if (tablePluginData.row.record.refutationReview) {
                    return tablePluginData.row.record.refutationReview
                      .refutationReviewStatus.code;
                  } else {
                    return tablePluginData.row.record.submittedStatus.code;
                  }
                }
              }
            }
          ]
        }
      ]
    );
    this.tableResponseReferenceByItem.setRecordList(
      this.refutationResponse.refutationBuyerByItemViewList
    );
    this.tableResponseReferenceByItem.reload();
  }

  public buildTableReferenceByVendor(): void {
    this.tableResponseReferenceByVendor = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'quotation.procurementVendor.vendor.name',
          header: 'table.column.vendorName',
          plugins: {
            name: 'hyperlink',
            onClick: this.doViewQuotation.bind(this)
          }
        },
        this.procurement.biddingEvaluation.code !==
        this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
          ? {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScoreStatus.name',
                  header: 'table.column.technical',
                  plugins: {
                    name: 'badge',
                    colorField: 'technicalScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                }
              ]
            }
          : {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScore',
                  header: {
                    key: 'table.column.technical',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' +
                            (this.refutationResponse
                              .procurementFinalizationCriteria
                              .technicalPercentage || ' - ') +
                            '%)';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                },
                {
                  field: 'totalFinalEvaluationScore',
                  header: 'table.column.total',
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                }
              ]
            },
        {
          header: 'table.column.allocatedItem',
          columnList: [
            {
              field: 'goodsSelectedItem',
              header: 'table.column.goodsItem',
              className: 'text-center'
            },
            {
              field: 'serviceSelectedItem',
              header: 'table.column.serviceItem',
              className: 'text-center'
            }
          ]
        }
      ]
    );
    this.tableResponseReferenceByVendor.setRecordList(
      this.refutationResponse.vendorDesignationSelectedVendorViewList
    );
    this.tableResponseReferenceByVendor.reload();
  }

  public doViewQuotation(
    vendorReferenceSelectedVendorView: VendorReferenceSelectedVendorView
  ) {
    this.appPopupFinalPriceDetailService
      .open(
        vendorReferenceSelectedVendorView.quotation.procurementVendor.id,
        this.procurement.biddingEvaluation,
        this.procurement.vendorSelectionType,
        this.moduleCode
      )
      .subscribe();
  }

  public doShowVendorRefutation(
    refutationVendorParticipantView: RefutationParticipantSingleWinnerView
  ): void {
    if (
      refutationVendorParticipantView.submittedStatus.code ===
      this.global.appConstant.pm.SUBMITTED_STATUS_AVAILABLE
    ) {
      this.appPopupService.open(AppPopupVendorRefutationComponent, {
        refutationVendorId: refutationVendorParticipantView.refutationVendor.id,
        vendor: refutationVendorParticipantView.vendor.name
      });
    }
  }

  public doClickRefutationItem(
    refutationBuyerByItemView: RefutationBuyerByItemView
  ): void {
    this.global.routerParams.set('todo', this.todo);
    this.global.routerParams.set('procurementId', this.procurementId);
    this.global.routerParams.set('objectId', this.objectId);
    this.global.routerParams.set('urlBackOutside', this.urlBackOutside);
    this.global.routerParams.set(
      'approvalModelPrcsId',
      this.approvalModelPrcsId
    );
    this.global.routerParams.set(
      'refutationBuyerByItemView',
      refutationBuyerByItemView
    );
    this.global.routerParams.set('isUpdated', this.isUpdated);
    this.global.routerParams.set('refutationResponse', this.refutationResponse);
    this.global.routerParams.set('backToUrl', this.router.url);
    this.formGroup.reset();
    this.router.navigate(['/pages/refutation/allocated-item/details']);
  }

  public doSave(): void {
    this.validate();
    this.isOfficialReportNull = false;
    if (this.formGroup.valid) {
      this.setStateProcessing();
      const isProcurementFailed = false;
      if (
        this.procurement.vendorSelectionType.code ===
        this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
      ) {
        const refutationReview: RefutationReview =
          this.refutationResponse.refutationReview || new RefutationReview();
        refutationReview.procurement = this.procurement;
        refutationReview.refutationDate = this.formGroup.value.refutationDate;
        refutationReview.note = this.formGroup.value.note;
        refutationReview.refutationReviewStatus =
          this.formGroup.value.refutationReviewStatus;
        refutationReview.procurementItem = null;
        this.refutationReviewList.push(refutationReview);
      } else {
        this.refutationResponse.refutationBuyerByItemViewList.forEach(
          refutationBuyerByItemView => {
            if (refutationBuyerByItemView.refutationReview) {
              this.refutationReviewList.push(
                refutationBuyerByItemView.refutationReview
              );
            }
          }
        );
      }

      const refutationRequest: RefutationRequest = new RefutationRequest();
      refutationRequest.procurement = this.procurement;
      refutationRequest.isSubmit = false;
      refutationRequest.isProcurementFailed = isProcurementFailed;
      refutationRequest.refutationReviewList = this.refutationReviewList;
      refutationRequest.appOfficialReportModel = this.appOfficialReportModel;

      this.httpClientService
        .post<Response<RefutationRequest>>(
          '/refutation/update',
          refutationRequest
        )
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.snackbarService.showWarning('app.alert.msg.saveSuccess');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set(
                  'procurementId',
                  this.procurementId
                );
                this.global.routerParams.set(
                  'urlBackOutside',
                  '/pages/worklist-pm'
                );
                this.global.routerParams.set('todo', 'edit');
                this.router.navigate(['/pages/refutation/edit']);
              });
          } else {
            this.toastService.showError(response.statusText);
          }
          this.setStateReady();
        });
    } else {
      this.isMandatory = true;
    }
  }

  public isFormValid(): boolean {
    if (
      this.procurement.vendorSelectionType.code ===
      this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
    ) {
      return this.formGroup.valid;
    } else {
      return (
        this.refutationResponse.refutationBuyerByItemViewList.filter(
          refutationBuyerByItemView =>
            !refutationBuyerByItemView.refutationReview
        ).length === 0
      );
    }
  }

  public isProcurementFailed(): boolean {
    if (
      this.procurement.vendorSelectionType.code ===
      this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
    ) {
      return (
        this.formGroup.value.refutationReviewStatus.code ===
        this.global.appConstant.pm.REFUTATION_REVIEW_STATUS_ACCEPTED
      );
    } else {
      return (
        this.refutationResponse.refutationBuyerByItemViewList.filter(
          refutationBuyerByItemView =>
            refutationBuyerByItemView.refutationReview.refutationReviewStatus
              .code ===
            this.global.appConstant.pm.REFUTATION_REVIEW_STATUS_ACCEPTED
        ).length > 0
      );
    }
  }

  public doSubmit(): void {
    this.validate();
    if (this.isFormValid()) {
      if (
        this.procurement.procurementWorklistStatus.code !==
        this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_CLOSED_RESPONSE
      ) {
        if (!this.isUpdated) {
          if (
            this.appOfficialReportModel.fileObjectList.length === 0 ||
            (this.appOfficialReportModel.fileObjectList.filter(
              fileObject => fileObject.isUploaded
            ).length === 0 &&
              this.appOfficialReportModel.isGenerate) ||
            this.appOfficialReportModel.letterDate === null ||
            this.appOfficialReportModel.letterNumber === null
          ) {
            this.isOfficialReportNull = true;
          } else {
            this.isOfficialReportNull = false;
            const isProcurementFailed = this.isProcurementFailed();
            if (!isProcurementFailed) {
              this.global.modalService
                .submitConfirmation()
                .pipe(take(1))
                .subscribe(result => {
                  if (result) {
                    this.doSubmitProcess(isProcurementFailed);
                  }
                });
            } else {
              if (
                this.procurement.vendorSelectionType.code ===
                this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
              ) {
                this.global.modalService
                  .confirmation(
                    'refutation.potensial.procurementFailed.msg',
                    'refutation.potensial.procurementFailed.title',
                    'app.button.cancel',
                    'app.button.submitAnyway',
                    'pvc pv-exclamation-triangle',
                    false
                  )
                  .pipe(take(1))
                  .subscribe(result => {
                    if (result) {
                      this.doSubmitProcess(isProcurementFailed);
                    }
                  });
              } else {
                this.global.modalService
                  .confirmation(
                    'refutation.potensial.unselectedItem.msg',
                    'refutation.potensial.unselectedItem.title',
                    'app.button.cancel',
                    'app.button.submitAnyway',
                    'pvc pv-exclamation-triangle',
                    false
                  )
                  .pipe(take(1))
                  .subscribe(result => {
                    if (result) {
                      this.doSubmitProcess(isProcurementFailed);
                    }
                  });
              }
            }
          }
        } else {
          this.toastService.showError(
            this.translateService.instant('refutation.saveDraftFirst.msg.error')
          );
        }
      } else {
        this.toastService.showError(
          this.translateService.instant('refutation.saveDraftFirst.msg.error')
        );
      }
    } else if (!this.formGroup.valid) {
      this.isMandatory = true;
    } else {
      this.toastService.showError(
        this.translateService.instant('refutation.validation.msg.error')
      );
    }
  }

  public doSubmitProcess(isProcurementFailed: boolean): void {
    this.setStateProcessing();
    if (
      this.procurement.vendorSelectionType.code ===
      this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
    ) {
      const refutationReview: RefutationReview =
        this.refutationResponse.refutationReview || new RefutationReview();
      refutationReview.procurement = this.procurement;
      refutationReview.refutationDate = this.formGroup.value.refutationDate;
      refutationReview.note = this.formGroup.value.note;
      refutationReview.refutationReviewStatus =
        this.formGroup.value.refutationReviewStatus;
      refutationReview.procurementItem = null;
      this.refutationReviewList.push(refutationReview);
    } else {
      this.refutationResponse.refutationBuyerByItemViewList.forEach(
        refutationBuyerByItemView => {
          if (refutationBuyerByItemView.refutationReview) {
            this.refutationReviewList.push(
              refutationBuyerByItemView.refutationReview
            );
          }
        }
      );
    }

    const refutationRequest: RefutationRequest = new RefutationRequest();
    refutationRequest.procurement = this.procurement;
    refutationRequest.isSubmit = true;
    refutationRequest.isProcurementFailed =
      this.procurement.vendorSelectionType.code ===
      this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
        ? isProcurementFailed
        : false;
    refutationRequest.refutationReviewList = this.refutationReviewList;
    refutationRequest.appOfficialReportModel = this.appOfficialReportModel;

    this.httpClientService
      .post<Response<RefutationRequest>>(
        '/refutation/update',
        refutationRequest
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.modalService.submitSuccess().subscribe(result => {
            if (result) {
              this.router.navigate(['/pages/worklist-pm/']);
            } else {
              this.router
                .navigateByUrl('/', {
                  skipLocationChange: true
                })
                .then(() => {
                  this.global.routerParams.clear();
                  this.global.routerParams.set(
                    'procurementId',
                    this.procurementId
                  );
                  this.global.routerParams.set(
                    'urlBackOutside',
                    '/pages/worklist-pm'
                  );
                  this.global.routerParams.set('todo', 'view');
                  this.router.navigate(['/pages/refutation/detail']);
                });
            }
          });
        } else {
          this.appOfficialReportModel.isGenerate = false;
          this.global.alertService.showError(response.statusText);
        }
        this.setStateReady();
      });
  }

  public doOnChange(event: AppOfficialReportModel): void {
    this.appOfficialReportModel = event;
    if (this.isUpdated && this.appOfficialReportModel.isGenerate) {
      this.isUpdated = false;
      this.isOfficialReportNull = false;
    }
  }

  public onChangeStatus(event): void {
    if (
      !this.refutationResponse.refutationReview ||
      (this.refutationResponse.refutationReview &&
        this.refutationResponse.refutationReview.refutationReviewStatus.code !==
          event.code)
    ) {
      this.isUpdated = true;
      this.appOfficialReportModel.isGenerate = this.appOfficialReportModel
        .isGenerate
        ? false
        : false;
      this.isOfficialReportNull = true;
    }
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }
}
