import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RecapPrintService } from '../../core/services/recap-print.service';
import { CertificateStatus } from './../../core/bean/certificate-status';
import { VendorCertificate } from './../../core/bean/vendor-certificate';
import { OptionListModel } from './../../core/model/option-list-model';
@Component({
  templateUrl: './vendor-certificate.component.html',
  styles: [
    '.width-company-name { min-width : 250px; white-space: normal ! important }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class VendorCertificateComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<VendorCertificate>;
  public isLoading: boolean;
  public certificateStatusList: OptionListModel<CertificateStatus> = new OptionListModel(
    true
  );

  constructor(
    translateService: TranslateService,
    public recapPrintService: RecapPrintService
  ) {
    super('vendor-certificate', translateService);
  }

  public onInit(): void {
    this.setCertificateStatusList();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public setCertificateStatusList(): void {
    this.httpClientService
      .get<CertificateStatus[]>('/certificate-status/certificate-status-list')
      .subscribe((certificateStatusList: CertificateStatus[]) => {
        this.certificateStatusList.setRequestValues(certificateStatusList);
      });
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorCode: [null],
      vendorName: [null],
      releaseDateFrom: [null],
      releaseDateTo: [null],
      certificateStatus: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'vendor.name',
        header: 'table.col.vendorName',
        customClass: 'width-company-name'
      },
      {
        field: 'vendor.code',
        header: 'table.col.vendorCode',
        customClass: 'text-center'
      },
      {
        field: 'vendor.vendorType.name',
        header: 'table.col.vendorType',
        customClass: 'text-center'
      },
      { field: 'vendor.email', header: 'table.col.email' },
      {
        field: 'certificateStatus.name',
        header: 'table.col.certificateStatus',
        customClass: 'text-center'
      },
      {
        field: 'certificateNumber',
        header: 'table.col.certificateNumber',
        customClass: 'text-center'
      },
      { field: 'verifiedByUser.name', header: 'table.col.picName', customClass: 'text-center' },
      {
        field: 'releaseDate',
        header: 'table.col.releaseDate',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: 'expiredDate',
        header: 'table.col.expiredDate',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      }
    ]);
  }

  public doApply(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }

  public doViewCertificate(vendorCertificate: VendorCertificate): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('id', vendorCertificate.vendor.id);
    this.global.routerParams.set('name', vendorCertificate.vendor.name);
    this.global.routerParams.set('code', vendorCertificate.vendor.code);
    this.global.routerParams.set('isGuest', true);
    this.router.navigate(['/pages/vendor-certificate/detail']);
  }

  public doSendEmail(vendorCertificate: VendorCertificate): void {
    this.global.modalService
      .confirm(this.translateService.instant('vendor-certificate.confirm.msg.sendEmail'),
        'vendor-certificate.confirm.title.sendEmail')
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<VendorCertificate>>(
              '/vendor-certificate/send-email',
              vendorCertificate.id
            ).subscribe((response: Response<VendorCertificate>) => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccess(
                  this.translateService.instant(
                    'vendor-certificate.notif.send-email'
                  ) +
                  ' ' +
                  vendorCertificate.vendor.name
                );
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });

        }
      });
  }

  public doExport(): void {
    this.isLoading = true;
    this.recapPrintService
      .print(
        '/vendor-certificate/recap-vendor-certificate',
        this.tableResponse.request
      )
      .subscribe(() => {
        this.isLoading = false;
      });
  }
}
