import { Component, ContentChild, Input, Renderer2, TemplateRef } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { TextUtils } from '../../utils';
type TooltipPosition = 'TOP' | 'RIGHT' | 'BOTTOM' | 'LEFT' | 'TOP_LEFT' | 'TOP_RIGHT' | 'BOTTOM_LEFT' | 'BOTTOM_RIGHT';
@Component({
  selector: 'app-tooltip',
  templateUrl: './app-tooltip.component.html',
  styleUrls: ['./app-tooltip.component.scss']
})
export class AppTooltipComponent extends BaseComponentComponent {
  @Input() position: TooltipPosition;
  @ContentChild('tooltipContent') public tooltipContentTmpl: TemplateRef<any>;

  public generatedId: string = TextUtils.generateRandomString();
  public previousIElement: string;

  constructor(public renderer2: Renderer2) {
    super('app-tooltip');
  }

  public onInit(): void {
    this.setStateReady();
  }

  handleMouseEnter(): void {
    let iElement: any;
    const element = document.getElementById(this.generatedId);
    this.removePreviousIElement(element);
    if (this.position === 'TOP' || this.position === 'RIGHT' || this.position === 'BOTTOM' || this.position === 'LEFT') {
      iElement = this.renderer2.createElement('em');
      this.renderer2.addClass(iElement, 'fa');
      this.renderer2.addClass(iElement, 'fa-sort-asc');
      this.renderer2.addClass(iElement, 'tooltip-chevron');
      this.renderer2.appendChild(element.children[0], iElement);
    }
    this.setTooltipElement(element, iElement);
  }

  public removePreviousIElement(element: any): void {
    if (this.previousIElement) {
      this.renderer2.removeChild(element.children[0], this.previousIElement);
    }
  }

  public setTooltipElement(element: any, iElement: any): void {
    if (this.position === 'TOP_RIGHT') {
      element.style.bottom = '100%';
      element.style.left = '100%';
    } else if (this.position === 'TOP_LEFT') {
      element.style.bottom = '100%';
      element.style.right = '100%';
    } else if (this.position === 'BOTTOM_RIGHT') {
      element.style.top = '100%';
      element.style.right = '100%';
    } else if (this.position === 'BOTTOM_LEFT') {
      element.style.top = '100%';
      element.style.left = '100%';
    } else if (this.position === 'TOP') {
      element.style.bottom = '132%';
      element.style.left = '-' + element.offsetWidth / 2 + 'px';
      iElement.style.transform = 'rotate(180deg)';
      iElement.style.bottom = '-26px';
      iElement.style.left = '50%';
    } else if (this.position === 'RIGHT') {
      element.style.top = '-' + element.offsetHeight / 2 + 'px';
      element.style.left = '200%';
      iElement.style.left = '-6px';
      iElement.style.top = (element.offsetHeight - 5) / 2 + 'px';
      iElement.style.transform = 'rotate(-90deg)';
    } else if (this.position === 'BOTTOM') {
      element.style.top = '164%';
      element.style.left = '-' + element.offsetWidth / 2 + 'px';
      iElement.style.top = '-13px';
      iElement.style.left = (element.offsetWidth - 5) / 2 + 'px';
    } else if (this.position === 'LEFT') {
      element.style.top = '-' + element.offsetHeight / 2 + 'px';
      element.style.right = '200%';
      iElement.style.right = '-6px';
      iElement.style.top = (element.offsetHeight - 5) / 2 + 'px';
      iElement.style.transform = 'rotate(90deg)';
    } else {
      element.style.bottom = '132%';
      element.style.left = '-' + element.offsetWidth / 2 + 'px';
      iElement.style.transform = 'rotate(180deg)';
      iElement.style.bottom = '-26px';
      iElement.style.left = (element.offsetWidth - 5) / 2 + 'px';
    }
    this.previousIElement = iElement;
  }
}
