import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Payment } from 'src/app/core/bean/payment';
import { PaymentHistory } from 'src/app/core/bean/payment-history';
import { PaymentInvoice } from 'src/app/core/bean/payment-invoice';
import { PaymentProof } from 'src/app/core/bean/payment-proof';
import { PaymentTaxProof } from 'src/app/core/bean/payment-tax-proof';
import { Vendor } from 'src/app/core/bean/vendor';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { PaymentBillingDoc } from '../../core/bean/payment-billing-doc';
import { PaymentView } from './../../core/view/entity/bean/payment-view';
import { AppPopupPaymentMonitoringUploadPaymentProofComponent } from './app-popup-payment-monitoring-upload-payment-proof.component';
import { AppPopupPaymentMonitoringUploadPaymentTaxProofComponent } from './app-popup-payment-monitoring-upload-payment-tax-proof.component';
import { PaymentMonitoringResponse } from './payment-monitoring.response';

@Component({
  templateUrl: './payment-monitoring-edit.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None
})
export class PaymentMonitoringEditComponent
  extends BaseModuleComponent
  implements OnInit
{
  public paymentId: number;
  public procurementVendorId: number;
  public paymentHistory: PaymentHistory = new PaymentHistory();
  public vendor: Vendor = new Vendor();
  public paymentInvoice: PaymentInvoice = new PaymentInvoice();
  public paymentView: PaymentView = new PaymentView();
  public paymentMonitoringResponse: PaymentMonitoringResponse =
    new PaymentMonitoringResponse();
  public payment: Payment;
  public paymentTaxProof: PaymentTaxProof = new PaymentTaxProof();
  public paymentProof: PaymentProof = new PaymentProof();
  public isLoading: boolean;
  public isLoadingProof: boolean;
  public billingMemoId: number;
  public tableResponse: TableResponseModel<PaymentBillingDoc>;
  public fileUploader: FileUploader = new FileUploader(
    '/payment-monitoring/',
    '',
    this.global.appConstant.fileType.DOC_PAYMENT_PROOF
  );
  public totalTaxBase: number;
  public totalVAT: number;
  public totalIncomeTax: number;
  public totalPaidToVendor: number;
  public subTotal: number;
  public isHasTax: boolean;
  public urlBack: string;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('payment-monitoring', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.paymentId = this.global.routerParams.get('paymentId');
    this.paymentInvoice = this.global.routerParams.get('paymentInvoice');
    this.paymentView = this.global.routerParams.get('paymentView');
    this.vendor = this.global.routerParams.get('vendor');
    this.urlBack = this.global.routerParams.get('urlBackOutside');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      paymentTaxFile: [null],
      paymentTaxDescription: [null],
      paymentTaxUploadBy: [null],
      paymentTaxUploadDate: [null],
      paymentTaxVersion: [null],
      paymentProofFile: [null],
      paymentProofDescription: [null],
      paymentProofUploadBy: [null],
      paymentProofUploadDate: [null],
      paymentProofVersion: [null]
    });
    this.setViewOnly();
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<PaymentMonitoringResponse>(
        '/payment-monitoring/edit',
        new RouteRequestModel('', this.paymentId)
      )
      .subscribe((paymentMonitoringResponse: PaymentMonitoringResponse) => {
        this.paymentMonitoringResponse = paymentMonitoringResponse;
        this.paymentTaxProof = this.paymentMonitoringResponse.paymentTaxProof;
        this.paymentProof = this.paymentMonitoringResponse.paymentProof;
        this.payment = this.paymentMonitoringResponse.payment;
        this.totalTaxBase = 0;
        this.totalVAT = 0;
        this.totalIncomeTax = 0;
        this.isHasTax = this.paymentMonitoringResponse.isHasTax;
        if (this.paymentMonitoringResponse.billingMemoItemList) {
          this.paymentMonitoringResponse.billingMemoItemList.forEach(
            billingMemoItem => {
              this.totalTaxBase += billingMemoItem.taxBaseAmount;
              this.totalVAT += billingMemoItem.vatAmount;
            }
          );
        }
        if (this.paymentMonitoringResponse.billingMemoTaxList) {
          this.paymentMonitoringResponse.billingMemoTaxList.forEach(
            billingMemoTax => {
              this.totalIncomeTax += billingMemoTax.incomeTaxAmount;
            }
          );
        }

        if (this.paymentMonitoringResponse.isWapu) {
          this.totalPaidToVendor = this.totalTaxBase - this.totalIncomeTax;
          this.subTotal = this.totalTaxBase;
        } else {
          this.totalPaidToVendor =
            this.totalTaxBase + this.totalVAT - this.totalIncomeTax;
          this.subTotal = this.totalTaxBase + this.totalVAT;
        }
        if (this.paymentMonitoringResponse.billingMemoOtherCostList) {
          this.paymentMonitoringResponse.billingMemoOtherCostList.forEach(
            billingMemoOtherCost => {
              this.totalPaidToVendor =
                billingMemoOtherCost.transactionType === 1
                  ? this.totalPaidToVendor + billingMemoOtherCost.amount
                  : this.totalPaidToVendor - billingMemoOtherCost.amount;
              this.subTotal =
                billingMemoOtherCost.transactionType === 1
                  ? this.subTotal + billingMemoOtherCost.amount
                  : this.subTotal - billingMemoOtherCost.amount;
            }
          );
        }

        if (this.paymentMonitoringResponse.paymentTaxProof != null) {
          this.fileUploader.setFile(
            this.paymentMonitoringResponse.paymentTaxProof.file
          );
          const paymentTaxUploadDate: Date = new Date(
            this.paymentMonitoringResponse.paymentTaxProofCreatedAt
          );
          this.formGroup.patchValue({
            paymentTaxFile: this.fileUploader.fileObjectList,
            paymentTaxDescription:
              this.paymentMonitoringResponse.paymentTaxProof.description,
            paymentTaxUploadBy:
              this.paymentMonitoringResponse.paymentTaxProofUploadBy.name,
            paymentTaxUploadDate,
            paymentTaxVersion:
              this.paymentMonitoringResponse.paymentTaxProof.version
          });
          this.setStateReady();
        }
        if (this.paymentMonitoringResponse.paymentProof != null) {
          this.fileUploader.setFile(
            this.paymentMonitoringResponse.paymentProof.file
          );
          const paymentProofUploadDate: Date = new Date(
            this.paymentMonitoringResponse.paymentProofCreatedAt
          );
          this.formGroup.patchValue({
            paymentProofFile: this.fileUploader.fileObjectList,
            paymentProofDescription:
              this.paymentMonitoringResponse.paymentProof.description,
            paymentProofUploadBy:
              this.paymentMonitoringResponse.paymentProofUploadBy.name,
            paymentProofUploadDate,
            paymentProofVersion:
              this.paymentMonitoringResponse.paymentProof.version
          });
          this.setStateReady();
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'contractSowPaymentTermBilling.billingTerm.name',
        header: 'table.column.billingTerm',
        customClass: 'text-center'
      },
      {
        field: 'number',
        header: 'table.column.documentNumber',
        customClass: 'text-center'
      },
      {
        field: 'date',
        header: 'table.column.documentDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'file.fileName',
        header: 'table.column.uploadFile',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName'
      }
    ]);
  }
  public doAddDocumentTaxProof(): void {
    const header = this.translateService.instant(
      'payment-monitoring.popup.title.uploadTaxProof'
    );
    const paymentTaxProof = this.paymentMonitoringResponse.paymentTaxProof;
    const paymentMonitoringResponse = this.paymentMonitoringResponse;
    const payment = this.payment;
    this.appPopupService
      .open(AppPopupPaymentMonitoringUploadPaymentTaxProofComponent, {
        header,
        paymentTaxProof,
        paymentMonitoringResponse,
        payment
      })
      .subscribe(paymentTaxProof => {
        this.setStateReady();
        this.isLoading = true;
        this.log.debug(paymentTaxProof);
        this.httpClientService
          .post<PaymentMonitoringResponse>(
            '/payment-monitoring/edit',
            new RouteRequestModel('', this.paymentId)
          )
          .subscribe((paymentMonitoringResponse: PaymentMonitoringResponse) => {
            this.paymentMonitoringResponse = paymentMonitoringResponse;
            this.paymentTaxProof =
              this.paymentMonitoringResponse.paymentTaxProof;
            this.payment = this.paymentMonitoringResponse.payment;
            if (this.paymentMonitoringResponse.paymentTaxProof != null) {
              this.fileUploader.setFile(
                this.paymentMonitoringResponse.paymentTaxProof.file
              );
              const paymentTaxUploadDate: Date = new Date(
                this.paymentMonitoringResponse.paymentTaxProofCreatedAt
              );
              this.formGroup.patchValue({
                paymentTaxFile: this.fileUploader.fileObjectList,
                paymentTaxDescription:
                  this.paymentMonitoringResponse.paymentTaxProof.description,
                paymentTaxUploadBy:
                  this.paymentMonitoringResponse.paymentTaxProofUploadBy.name,
                paymentTaxUploadDate,
                paymentTaxVersion:
                  this.paymentMonitoringResponse.paymentTaxProof.version
              });
              this.setStateReady();
            }
            this.isLoading = false;
          });
      });
  }

  public doAddDocumentProof(): void {
    const header = this.translateService.instant(
      'payment-monitoring.popup.title.uploadProof'
    );
    const paymentProof = this.paymentMonitoringResponse.paymentProof;
    const paymentMonitoringResponse = this.paymentMonitoringResponse;
    const payment = this.payment;
    this.appPopupService
      .open(AppPopupPaymentMonitoringUploadPaymentProofComponent, {
        header,
        paymentProof,
        paymentMonitoringResponse,
        payment
      })
      .subscribe(paymentProof => {
        this.setStateReady();
        this.isLoadingProof = true;
        this.log.debug(paymentProof);
        this.httpClientService
          .post<PaymentMonitoringResponse>(
            '/payment-monitoring/edit',
            new RouteRequestModel('', this.paymentId)
          )
          .subscribe((paymentMonitoringResponse: PaymentMonitoringResponse) => {
            this.paymentMonitoringResponse = paymentMonitoringResponse;
            this.paymentProof = this.paymentMonitoringResponse.paymentProof;
            this.payment = this.paymentMonitoringResponse.payment;
            if (this.paymentMonitoringResponse.paymentProof != null) {
              this.fileUploader.setFile(
                this.paymentMonitoringResponse.paymentProof.file
              );
              const paymentProofUploadDate: Date = new Date(
                this.paymentMonitoringResponse.paymentProofCreatedAt
              );
              this.formGroup.patchValue({
                paymentProofFile: this.fileUploader.fileObjectList,
                paymentProofDescription:
                  this.paymentMonitoringResponse.paymentProof.description,
                paymentProofUploadBy:
                  this.paymentMonitoringResponse.paymentProofUploadBy.name,
                paymentProofUploadDate,
                paymentProofVersion:
                  this.paymentMonitoringResponse.paymentProof.version
              });
              this.setStateReady();
            }
            this.isLoadingProof = false;
          });
      });
  }

  public doClose(): void {
    if (
      (this.isHasTax
        ? this.paymentTaxProof !== null && this.paymentTaxProof !== undefined
        : !this.isHasTax) &&
      this.paymentProof !== null &&
      this.paymentProof != undefined
    ) {
      this.global.modalService
        .confirm(
          this.translateService.instant('payment-monitoring.confirmation.body'),
          this.translateService.instant(
            'payment-monitoring.confirmation.header'
          )
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<Payment>>(
                '/payment-monitoring/close-payment',
                this.payment
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/payment-monitoring/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    } else {
      if (
        (this.paymentTaxProof === null || this.paymentTaxProof === undefined) &&
        (this.paymentProof === null || this.paymentProof == undefined)
      ) {
        this.global.modalService.message(
          this.translateService.instant('payment-monitoring.warning.note'),
          this.translateService.instant('payment-monitoring.warning.header')
        );
      } else {
        if (
          this.paymentTaxProof === null ||
          this.paymentTaxProof === undefined
        ) {
          this.global.modalService.message(
            this.translateService.instant(
              'payment-monitoring.warning.paymentTaxProof'
            ),
            this.translateService.instant('payment-monitoring.warning.header')
          );
        } else {
          this.global.modalService.message(
            this.translateService.instant(
              'payment-monitoring.warning.paymentProof'
            ),
            this.translateService.instant('payment-monitoring.warning.header')
          );
        }
      }
    }
  }

  public doCancel(): void {
    this.router.navigate([this.urlBack]);
  }
}
