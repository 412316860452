import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppRatingModule } from '../../app-rating/app-rating.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupStatistikVendorComponent } from './app-popup-statistik-vendor.component';
import { AppPopupStatistikVendorService } from './app-popup-statistik-vendor.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppRatingModule],
  declarations: [AppPopupStatistikVendorComponent],
  entryComponents: [AppPopupStatistikVendorComponent],
  providers: [AppPopupStatistikVendorService],
  exports: [AppPopupStatistikVendorComponent]
})
export class AppPopupStatistikVendorModule {}
