import { Quotation } from 'src/app/core/bean/quotation';
import { QuotationItem } from 'src/app/core/bean/quotation-item';
import { ProcurementItem } from 'src/app/core/bean/procurement-item';
import { ItemType } from 'src/app/core/bean/item-type';
import { ApprovalPathResponseModel } from 'src/app/core/components/app-approval-path-x';
import { WorkflowStepInfoModel } from 'src/app/core/components/app-workflow-step-info/model/workflow-step-info-model';
import { Procurement } from 'src/app/core/bean/procurement';
import { ProcurementSchedule } from 'src/app/core/bean/procurement-schedule';
import { ProcurementParticipant } from 'src/app/core/bean/procurement-participant';
import { OpenQuotationView } from 'src/app/core/view/entity/bean/open-quotation-view';

export class OpenQuotationResponse {
  public procurement: Procurement;
  public procurementSchedule: ProcurementSchedule;
  public procurementParticipant: ProcurementParticipant;
  public workflowStepInfoModel: WorkflowStepInfoModel;
  public approvalPathResponseModel: ApprovalPathResponseModel;
  public quotationItemList: QuotationItem[];
  public quotationList: Quotation[];
  public procurementItemList: ProcurementItem[];
  public itemTypeList: ItemType[];
  public isUserApproval: boolean;
  public openQuotationViewList: OpenQuotationView[];
}
