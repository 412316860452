<app-popup header="aanwijzing.popup.inviteNewParticipant.title" [isLoading]="formLoading">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        
        <div 
            class="form-group row text-sm-left">
            <label class="col-sm-3">
                {{ 'aanwijzing.form.role' | translate }}
            </label>
            <div class="col">
                <app-dropdown-select
                    size="XL"
                    formControlName="participantRole"
                    [optionList]="participantRoleOptionList"
                    (onChange)="doChangeRole($event)">
                </app-dropdown-select>
            </div>
        </div>
        <div *ngIf="formGroup.value.participantRole &&
         formGroup.value.participantRole?.code === global.appConstant.pm.PARTICIPANT_ROLE_VENDOR"
            class="form-group row text-sm-left">
            <label class="col-sm-3">
                {{ 'aanwijzing.form.company' | translate }}
            </label>
            <div class="col">
                <app-dropdown-select
                    size="XL"
                    formControlName="vendor"
                    [optionList]="vendorOptionList"
                    >
                </app-dropdown-select>
            </div>
        </div>
        <div 
            class="form-group row text-sm-left">
            <label class="col-sm-3">
                {{ 'aanwijzing.form.email' | translate }}
            </label>
            <div class="col">
                <app-text-field size="XL"
                 formControlName="email" maxLength="128"></app-text-field>
            </div>
        </div>

        <ng-template #modalFooter let-activeModal>
            <div class="button-group button-group-center">
                <app-button color="LIGHT" (onClick)="activeModal.close(true)">
                    {{ 'app.button.cancel' | translate }}</app-button>
                <app-button (onClick)="doAdd()">
                    {{ 'app.button.add' | translate }}</app-button>
            </div>
        </ng-template>
    </form>
</app-popup>