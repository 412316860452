import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { CustomValidatorError } from '../../core/custom-validator-error';
export class MinValidator {
  public static min(min: number | string, message?: string): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors => {
      const value = abstractControl.value;
      if (typeof min === 'number') {
        if (value && value < min) {
          return CustomValidatorError.create('min', message);
        }
      } else {
        const ACParent = abstractControl.parent as FormGroup;
        if (
          ACParent &&
          ACParent.get(min) instanceof AbstractControl &&
          abstractControl.value &&
          ACParent.get(min).value
        ) {
          if (value && +value < +ACParent.get(min).value) {
            return CustomValidatorError.create('min', message);
          }
          ACParent.get(min).valueChanges.subscribe((val: any) => {
            if (value && !abstractControl.valid && +value >= +val) {
              abstractControl.updateValueAndValidity();
            }
          });
        }
      }
    };
  }
}
