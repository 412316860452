<div class="login-card">
  <div class="login-title p-3 mb-3">
    <h2>{{ 'portal.login.title' | translate }}</h2>
    <ng-content select="em"></ng-content>
  </div>
  <form class="login-form px-4" [formGroup]="formGroup" autocomplete="" (keydown)="onEnterSubmit($event)">
    <div class="text-center" *ngIf="formSaving">
      <img src="assets/img/common/loader-small.gif" alt="Loading" height="11" width="40" />
    </div>
    <div class="form-group mt-4 error-section">
      <app-text-field autofocus="true" size="XL" formControlName="email" maxLength="64"
        placeholder="{{ 'portal.login.email' | translate }}">
      </app-text-field>
    </div>
    <div class="form-group mt-4">
      <app-text-field size="XL" formControlName="password" type="password" maxLength="64"
        placeholder="{{ 'portal.login.password' | translate }}">
      </app-text-field>
    </div>
    <div class="form-group mt-4">
      <app-captcha *ngIf="global.config.parameterModel.captchaEnable" formControlName="captcha" size="XL"></app-captcha>
    </div>
    <div class="form-group mt-4 d-flex justify-content-between">
      <app-check-box formControlName="isKeepMeSignIn">{{
        'portal.login.keepMeSignIn' | translate
        }}</app-check-box>
      <a class="text-right text-primary" (click)="doResetPassword()">{{
        'portal.login.forgotPassword' | translate
        }}</a>
    </div>
  </form>
  <div class="login-footer px-4 mt-4">
    <app-button color="WARNING" (onClick)="doSubmit()" [disabled]="!formGroup.valid || formSaving">{{
      'portal.login.button.signIn' | translate }}</app-button>
  </div>
  <div class="line-text mx-4 mt-4">
    <p class="text">{{ 'portal.login.separatorOr' | translate }}</p>
  </div>
  <p class="p-4 text-center">
    {{ 'portal.login.dontHaveAnAccount' | translate }}
    <a class="text-primary font-weight-bold" (click)="doSignUp()" id="portal-button-signUp">{{ 'portal.button.signUp' |
      translate }}</a>
  </p>
  <p class="px-4 py-3 text-justify m-0" [ngStyle]="{
      'border-top': '1px solid #f0f0f0',
      'background-color': '#fafafa'
    }">
    {{ 'portal.login.userInternalInfo' | translate }}
  </p>
</div>