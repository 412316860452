import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalLogModule } from '../../core/components/app-approval-log/app-approval-log.module';
import { AppApprovalPrcsXModule } from '../../core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupWorkPlanItemProductComponent } from './app-popup-work-plan-item-product.component';
import { AppPopupWorkPlanItemServiceComponent } from './app-popup-work-plan-item-service.component';
import { AppPopupWorkPlanLocationComponent } from './app-popup-work-plan-location.component';
import { WorkPlanEditAddComponent } from './work-plan-edit-add.component';
import { WorkPlanComponent } from './work-plan.component';
const routes = [
  { path: '', component: WorkPlanComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: WorkPlanEditAddComponent,
    data: { breadcrumb: 'work-plan.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: WorkPlanEditAddComponent,
    data: { breadcrumb: 'work-plan.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: WorkPlanEditAddComponent,
    data: { breadcrumb: 'work-plan.breadcrumb.detail' }
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppApprovalPrcsXModule,
    AppApprovalLogModule
  ],
  declarations: [
    WorkPlanComponent,
    WorkPlanEditAddComponent,
    AppPopupWorkPlanItemProductComponent,
    AppPopupWorkPlanItemServiceComponent,
    AppPopupWorkPlanLocationComponent
  ],
  entryComponents: [
    AppPopupWorkPlanItemProductComponent,
    AppPopupWorkPlanItemServiceComponent,
    AppPopupWorkPlanLocationComponent
  ],
  exports: [
    AppPopupWorkPlanItemProductComponent,
    AppPopupWorkPlanItemServiceComponent,
    AppPopupWorkPlanLocationComponent,
    WorkPlanEditAddComponent
  ]
})
export class WorkPlanModule {}
