import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Attribute } from '../../core/bean/attribute';
import { DueDiligenceTemplate } from '../../core/bean/due-diligence-template';
import { Question } from '../../core/bean/question';
import { PickListModel } from '../../core/components/app-pick-list/model/pick-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { DueDiligenceTemplateResponse } from './due-diligence-template-response';
import { DueDiligenceTemplateRequest } from './due-diligence-template-request';

@Component({
  templateUrl: './due-diligence-template-edit-add.component.html'
})
export class DueDiligenceTemplateEditAddComponent extends BaseModuleComponent {
  public dueDiligenceTemplate: DueDiligenceTemplate = new DueDiligenceTemplate();
  public dueDiligenceTemplateId: number;
  public sourceQuestion: Question[];
  public targetQuestion: Question[];
  public sourceAttribute: Attribute[];
  public targetAttribute: Attribute[];
  public dueDiligenceList = [];
  public ots: boolean;
  public interview: boolean;
  public dueDiligenceTemplateResponse: DueDiligenceTemplateResponse = new DueDiligenceTemplateResponse();
  public dueDiligenceTemplateRequest: DueDiligenceTemplateRequest = new DueDiligenceTemplateRequest();
  public checkList = [];
  public questionPickListModel: PickListModel<Question>;
  public attributePickListModel: PickListModel<Attribute>;
  constructor(translateService: TranslateService) {
    super('due-diligence-template', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.dueDiligenceTemplateId = this.global.routerParams.get(
      'dueDiligenceTemplateId'
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      dueDiligence: [null, Validators.isChecked()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<DueDiligenceTemplateResponse>(
        '/due-diligence-template/add-edit',
        new RouteRequestModel(this.todo, this.dueDiligenceTemplateId)
      )
      .subscribe((dueDiligenceTemplateResponse: DueDiligenceTemplateResponse) => {
        const {
          questionListNotByDueDiligenceTemplate,
          questionListByDueDiligenceTemplate,
          attributeListNotByDueDiligenceTemplate,
          attributeListByDueDiligenceTemplate
        } = dueDiligenceTemplateResponse;
        this.attributePickListModel = new PickListModel(
          this.moduleCode,
          attributeListNotByDueDiligenceTemplate,
          attributeListByDueDiligenceTemplate,
          'description'
        );
        this.questionPickListModel = new PickListModel(
          this.moduleCode,
          questionListNotByDueDiligenceTemplate,
          questionListByDueDiligenceTemplate
        );
        if (dueDiligenceTemplateResponse.dueDiligenceTemplate !== null) {
          const {
            id,
            name,
            code
          } = dueDiligenceTemplateResponse.dueDiligenceTemplate;
          this.formGroup.patchValue({ id, name, code });
        }
        this.setGroupOfDueDiligence(dueDiligenceTemplateResponse);
        this.setStateReady();
      });
  }

  public setGroupOfDueDiligence(
    dueDiligenceTemplateResponse: DueDiligenceTemplateResponse
  ): void {
    if (dueDiligenceTemplateResponse.dueDiligenceTemplate !== null) {
      const splitDueDiligence = dueDiligenceTemplateResponse.dueDiligenceTemplate.dueDiligence.split(
        ','
      );
      if (
        splitDueDiligence[0] === 'OTS' &&
        splitDueDiligence[1] === 'Interview'
      ) {
        this.ots = true;
        this.interview = true;
      } else if (splitDueDiligence[0] === 'Interview') {
        this.interview = true;
      } else if (splitDueDiligence[0] === 'OTS') {
        this.ots = true;
      } else {
        this.ots = false;
        this.interview = false;
      }
    }
    this.dueDiligenceList = [
      { name: 'On The Spot', isChecked: this.ots },
      { name: 'Interview', isChecked: this.interview }
    ];
    this.formGroup.get('dueDiligence').patchValue(this.dueDiligenceList);
  }

  public doSave(): void {
    this.validate();

    let isOTS = false;
    let isInterview = false;
    if (
      this.formGroup.get('dueDiligence').value[0].isChecked &&
      this.attributePickListModel.targetItemList.length > 0 &&
      this.formGroup.get('dueDiligence').value[1].isChecked &&
      this.questionPickListModel.targetItemList.length > 0
    ) {
      isOTS = true;
      isInterview = true;
    } else if (
      this.formGroup.get('dueDiligence').value[0].isChecked &&
      !this.formGroup.get('dueDiligence').value[1].isChecked &&
      this.attributePickListModel.targetItemList.length > 0
    ) {
      isOTS = true;
    } else if (
      this.formGroup.get('dueDiligence').value[1].isChecked &&
      !this.formGroup.get('dueDiligence').value[0].isChecked &&
      this.questionPickListModel.targetItemList.length > 0
    ) {
      isInterview = true;
    }

    if (this.formGroup.valid && (isOTS || isInterview)) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.dueDiligenceTemplateRequest.dueDiligenceTemplate = this.formGroup.value;
            this.dueDiligenceTemplateRequest.questionListByDueDiligenceTemplate = this.questionPickListModel.targetItemList;
            this.dueDiligenceTemplateRequest.attributeListByDueDiligenceTemplate = this.attributePickListModel.targetItemList;
            if (
              typeof this.formGroup.get('dueDiligence').value[0].isChecked ===
              'undefined' &&
              typeof this.formGroup.get('dueDiligence').value[1].isChecked ===
              'undefined'
            ) {
              this.dueDiligenceTemplateRequest.dueDiligenceTemplate.dueDiligence =
                '';
            } else if (
              this.formGroup.get('dueDiligence').value[0].isChecked &&
              this.formGroup.get('dueDiligence').value[1].isChecked
            ) {
              this.dueDiligenceTemplateRequest.dueDiligenceTemplate.dueDiligence =
                'OTS,Interview';
            } else if (this.formGroup.get('dueDiligence').value[1].isChecked) {
              this.dueDiligenceTemplateRequest.dueDiligenceTemplate.dueDiligence =
                'Interview';
            } else if (this.formGroup.get('dueDiligence').value[0].isChecked) {
              this.dueDiligenceTemplateRequest.dueDiligenceTemplate.dueDiligence =
                'OTS';
            } else {
              this.dueDiligenceTemplateRequest.dueDiligenceTemplate.dueDiligence =
                '';
            }

            const url: string =
              this.todo === 'edit'
                ? '/due-diligence-template/update'
                : '/due-diligence-template/insert';
            this.httpClientService
              .post<Response<DueDiligenceTemplateRequest>>(
                url,
                this.dueDiligenceTemplateRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/due-diligence-template/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    } else {
      this.global.alertService.showError(
        this.translateService.instant(
          'due-diligence-template.alert.msg.insertData'
        )
      );
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/due-diligence-template/']);
  }

  public get formGroupControls(): any {
    return this.formGroup.controls;
  }
}
