import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'theme-sidebar[position=right]',
  templateUrl: './sidebar-right.component.html',
  styleUrls: ['./sidebar-right.component.scss'],
})
export class SidebarRightComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
