import { NgModule } from '@angular/core';
import { AppPopupModule } from '../../components/app-popup/app-popup.module';
import { AppRatingModule } from '../../components/app-rating/app-rating.module';
import { AppTableModule } from '../../components/app-table/app-table.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetContentSliderModule } from '../app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppPopupDocumentExpiredComponent } from './app-popup-document-expired.component';
import { AppWidgetVendorInformationComponent } from './app-widget-vendor-information.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule, AppWidgetContentSliderModule, AppRatingModule, AppTableModule, AppPopupModule],
  declarations: [AppWidgetVendorInformationComponent, AppPopupDocumentExpiredComponent],
  entryComponents: [AppWidgetVendorInformationComponent, AppPopupDocumentExpiredComponent
  ],
  exports: [AppWidgetVendorInformationComponent]
})
export class AppWidgetVendorInformationModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-vendor-information', AppWidgetVendorInformationComponent);
  }
}
