<app-popup [header]="header" [isLoading]="formLoading">
  <form [formGroup]="formGroup">
    <app-table-xx
      [model]="tableResponseQuotationItem"
      [isShowPagination]="true"
      *ngIf="!formLoading"
    >
      <ng-template #headerFilter>
        <div class="form-group row text-sm-left">
          <div class="col text-left" [ngStyle]="{ 'min-width': '10rem' }">
            <app-dropdown-select
              size="XL"
              formControlName="itemType"
              type="COMBOBOX"
              [optionList]="itemTypeOptionList"
              (onChange)="onChangeItem()"
              container="body"
              placeholder="price-evaluation-vendor.filter.placeholder.type"
            ></app-dropdown-select>
          </div>
        </div>
      </ng-template>
      <ng-container
        *ngIf="tableResponseQuotationItem.getRecordList().length > 0"
      >
        <ng-template #row>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right" colspan="5">
              {{
                'price-evaluation-vendor.table.footer.goodsAmount' | translate
              }}
            </td>
            <td colspan="99" class="text-right">
              <strong>
                {{
                  isFinal
                    ? (finalGoodsAmount
                      | resolveNumberToCurrency)
                    : (popupPriceVendorResponse.quotation.goodsAmount
                      | resolveNumberToCurrency)
                }}
                IDR
              </strong>
            </td>
          </tr>

          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right" colspan="5">
              {{
                'price-evaluation-vendor.table.footer.serviceAmount' | translate
              }}
            </td>
            <td colspan="99" class="text-right">
              <strong>
                {{
                  isFinal
                    ? (finalServiceAmount
                      | resolveNumberToCurrency)
                    : (popupPriceVendorResponse.quotation.serviceAmount
                      | resolveNumberToCurrency)
                }}
                IDR
              </strong>
            </td>
          </tr>

          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right" colspan="5">
              {{ 'price-evaluation-vendor.table.footer.amount' | translate }}
            </td>
            <td colspan="99" class="text-right">
              <strong>
                {{
                  isFinal
                    ? (popupPriceVendorResponse.quotation.finalAmount
                      | resolveNumberToCurrency)
                    : (popupPriceVendorResponse.quotation.amount
                      | resolveNumberToCurrency)
                }}
                IDR
              </strong>
              <br />
              <i>
                ({{
                  isFinal
                    ? (popupPriceVendorResponse.quotation.finalAmount
                      | resolveNumberToWords: global.currentLang)
                    : (popupPriceVendorResponse.quotation.amount
                      | resolveNumberToWords: global.currentLang)
                }}
                {{ 'Rupiah' }})
              </i>
            </td>
          </tr>
        </ng-template>
      </ng-container>
    </app-table-xx>
  </form>
</app-popup>
