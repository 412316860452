import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Contract } from 'src/app/core/bean/contract';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Wanprestasi } from '../../core/bean/wanprestasi';
import { AppPopupContractService } from '../../core/components/app-popup/app-popup-contract/app-popup-contract.service';
import { FileUploader } from '../../core/components/upload';
import { Validators } from '../../core/validators';
import { WanprestasiResponse } from './wanprestasi-response';
@Component({
  selector: 'app-popup-contract-activity',
  templateUrl: './app-popup-contract-activity.component.html'
})
export class AppPopupContractActivityComponent extends BaseComponentComponent {
  @Input() public todo: string;
  @Input() public contractIdList: number[];
  @Input() public wanprestasiEditView: Wanprestasi;
  @Input() public wanprestasiList: Wanprestasi[];
  @Output() public onChange: EventEmitter<Wanprestasi> = new EventEmitter();

  public wanprestasiResponse: WanprestasiResponse = new WanprestasiResponse();
  public vendorId: number;
  public stages: string = null;
  public contractNumber: string = null;
  public contractId: number;

  public fileUploader: FileUploader = new FileUploader(
    '/wanprestasi/',
    'wanprestasi',
    this.global.appConstant.fileType.DOC_MEMO_WANPRESTASI
  );

  constructor(
    public appPopupContractService: AppPopupContractService,
    public activeModal: NgbActiveModal
  ) {
    super('wanprestasi');
  }

  onInit(): void {
    this.setVendorIdFromRouterParams();
    this.buildFormGroup();
    this.getAndSetWanprestasiToFormGroup();
    this.setStateReady();
  }

  public setVendorIdFromRouterParams(): void {
    this.vendorId = this.global.routerParams.get('vendorId');
  }

  public buildFormGroup(): void {
    const id: number = null;
    this.formGroup = this.formBuilder.group({
      id: [null], // id wanprestasi
      nameContract: [null, Validators.required()],
      wanprestasiDate: [null, Validators.required()],
      wanprestasiDetail: [null, Validators.required()],
      memoNumber: [
        null,
        {
          validators: [Validators.required()],
          asyncValidators: [
            Validators.existsAsync('/wanprestasi/check-exist', null, null, {
              id: null
            })
          ]
        }
      ],
      file: [null, Validators.required()],
      note: [null],
      contractDate: [null],
      contractNumber: [null],
      stage: [null],
      vendor: {
        id
      },
      contract: {
        id,
        name: null,
        stage: null,
        contractDate: null,
        number: null
      }
    });
  }

  public getAndSetWanprestasiToFormGroup(): void {
    if (this.wanprestasiEditView != null) {
      this.stages = this.wanprestasiEditView.contract.stage;
      this.contractNumber = this.wanprestasiEditView.contract.number;
      if (this.todo === 'edit') {
        this.fileUploader.setFile(this.wanprestasiEditView.file[0].file);
      } else if (this.todo === 'view') {
        this.fileUploader.setFile(this.wanprestasiEditView.file);
      }

      this.formGroup.patchValue({
        id: this.wanprestasiEditView.id,
        contractId: this.wanprestasiEditView.contract.id,
        nameContract: this.wanprestasiEditView.contract.name,
        wanprestasiDate: this.wanprestasiEditView.wanprestasiDate,
        wanprestasiDetail: this.wanprestasiEditView.wanprestasiDetail,
        memoNumber: this.wanprestasiEditView.memoNumber,
        file: this.fileUploader.fileObjectList,
        note: this.wanprestasiEditView.note,
        contractDate: this.wanprestasiEditView.contract.contractDate,
        contractNumber: this.wanprestasiEditView.contract.number,
        stage: this.wanprestasiEditView.contract.stage,
        contract: {
          id: this.wanprestasiEditView.contract.id,
          name: this.wanprestasiEditView.contract.name,
          stage: this.wanprestasiEditView.contract.stage,
          contractDate: this.wanprestasiEditView.contract.contractDate,
          number: this.wanprestasiEditView.contract.number
        },
        vendor: {
          id: this.wanprestasiEditView.vendor.id
        }
      });

      if (this.todo === 'view') {
        this.setViewOnly();
        this.formGroup.patchValue({
          nameContract: this.wanprestasiEditView.contract.name
        });
        this.stages = this.wanprestasiEditView.contract.stage;
        this.contractNumber = this.wanprestasiEditView.contract.number;
      }
    }
  }

  public doChooseContract(): void {
    this.appPopupContractService
      .open(this.contractIdList)
      .subscribe((dataContract: Contract) => {
        this.contractId = dataContract.id;

        const nameContract: string = dataContract.name;
        const contractDate: Date = dataContract.contractDate;
        const contractNumber: string = dataContract.number;
        const stage: string = dataContract.stage;

        const contract = {
          id: dataContract.id,
          name: dataContract.name,
          stage: dataContract.stage,
          contractDate: dataContract.contractDate,
          number: dataContract.number
        };

        const vendor = {
          id: this.vendorId
        };

        this.stages = dataContract.stage;
        this.contractNumber = dataContract.number;
        this.formGroup.patchValue({
          nameContract,
          contractDate,
          contractNumber,
          stage,
          contract,
          vendor
        });
      });
  }

  public doSaveWanprestasiContract(): void {
    this.validate();

    const memoNumberList: string[] = [];
    this.wanprestasiList.forEach(wanprestasi => {
      memoNumberList.push(wanprestasi.memoNumber);
    });

    const mNumber = new Set(memoNumberList);
    if (
      mNumber.size !== memoNumberList.length ||
      (this.todo !== 'edit' &&
        memoNumberList.length > 0 &&
        memoNumberList[0] === this.formGroup.get('memoNumber').value)
    ) {
      this.global.alertService.showError(
        'wanprestasi.msg.error.memoNumberExist',
        '.main-row-popup'
      );
    } else {
      if (this.formGroup.valid) {
        this.onChange.emit(this.formGroup.value);
        this.activeModal.dismiss();
      }
    }
  }
}
