import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppChartModule } from '../../components/app-chart/app-chart.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetContractPerCategoryComponent } from './app-widget-contract-per-category.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule, AppChartModule],
  declarations: [AppWidgetContractPerCategoryComponent],
  entryComponents: [AppWidgetContractPerCategoryComponent],
  exports: [AppWidgetContractPerCategoryComponent]
})
export class AppWidgetContractPerCategoryModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-contract-per-category', AppWidgetContractPerCategoryComponent);
  }
}
