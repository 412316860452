import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppPopupModule } from '../app-popup/app-popup.module';
import { AppModalConfirmDialogComponent } from './app-modal-confirm-dialog/app-modal-confirm-dialog.component';
import { AppModalInputDialogComponent } from './app-modal-input-dialog/app-modal-input-dialog.component';
import { AppModalMessageDialogComponent } from './app-modal-message-dialog/app-modal-message-dialog.component';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppButtonModule],
  declarations: [
    AppModalConfirmDialogComponent,
    AppModalInputDialogComponent,
    AppModalMessageDialogComponent
  ],
  entryComponents: [
    AppModalConfirmDialogComponent,
    AppModalInputDialogComponent,
    AppModalMessageDialogComponent
  ]
})
export class AppModalModule {}
