import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Contract } from './../../core/bean/contract';
import { FieldFormatEnum } from './../../core/components/app-table/model/field-format.enum';
@Component({
  templateUrl: './tab-contract.component.html'
})
export class TabContractComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<Contract>;
  public tableResponse: TableResponseModel<Contract>;
  constructor(translateService: TranslateService) {
    super('tab-contract-history', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setCustomData();
    this.model.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'number',
        header: 'table.column.contractNumber',
        customClass: 'text-center'
      },
      { field: 'name', header: 'table.column.contractName' },
      {
        field: 'procurementVendor.amount',
        header: 'table.column.amount',
        customClass: 'text-right',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: 'contractStatus.name',
        header: 'table.column.status',
        customClass: 'text-center'
      }
    ]);
  }

  public setCustomData(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponse.setCustomData(customData);
  }

  public doViewHistory(): void {
    this.global.routerParams.set('selector', 'tab-contract-history');
    this.global.routerParams.set('componentInstance', {
      id: 1,
      name: 'Terserah'
    });
    this.router.navigate(['/pages/vendor-history/detail/history']);
  }
}
