import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Currency } from 'src/app/core/bean/currency';
import { Oep } from 'src/app/core/bean/oep';
import { OepHistory } from 'src/app/core/bean/oep-history';
import { OepItem } from 'src/app/core/bean/oep-item';
import { OepStatus } from 'src/app/core/bean/oep-status';
import { Procurement } from 'src/app/core/bean/procurement';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { TableColumn } from 'src/app/core/components/table/domain/table-column';
import { TableRow } from 'src/app/core/components/table/domain/table-row';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { Validators } from 'src/app/core/validators';
import { AppPopupDetailHistoryComponent } from './app-popup-oep-detail-history.component';
import { AppPopupPriceReferenceComponent } from './app-popup-oep-price-reference.component';
import { OepRequest } from './oep.request';
import { OepResponse } from './oep.response';

@Component({
  templateUrl: './oep-edit-add.component.html'
})
export class OepEditAddComponent extends BaseModuleComponent {
  public procurementId: number;
  public objectId: number;
  public approvalModelPrcsId: number;
  public oepStatus: OepStatus = new OepStatus();
  public oepResponse: OepResponse = new OepResponse();
  public tableResponse: TableResponseModel<OepItem>;
  public tableResponseChangeHistory: TableResponseModel<OepHistory>;

  public procurement: Procurement = new Procurement();
  public oepItemList: OepItem[] = [];
  public oepItemTempList: OepItem[] = [];
  public oep: Oep = new Oep();
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );
  public urlBackOutside: string;
  public fileUploader: FileUploader = new FileUploader(
    '/oep/',
    'oep.form.headerImage',
    this.global.appConstant.fileType.DOC_OEP,
    false,
    5
  );
  readonly SEARCH_INTERVAL = 500;
  timeOut: any;
  public isShowButton = false;
  public isShowButtonChange = false;
  public isApprovalViewOnly = true;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public snackbarService: SnackbarService
  ) {
    super('oep', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setIsViewOnly();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.procurementId = this.global.routerParams.get('procurementId');
    this.procurement = this.global.routerParams.get('procurement');
    this.oepStatus = this.global.routerParams.get('oepStatus');
    this.objectId = this.global.routerParams.get('objectId');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    this.isApprovalViewOnly =
      this.global.routerParams.get('isApprovalViewOnly');
    this.isApprovalViewOnly =
      typeof this.isApprovalViewOnly === 'undefined'
        ? true
        : this.isApprovalViewOnly;
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view' && this.isApprovalViewOnly) {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      amount: [null],
      oepStatus: [null],
      procurement: [null],
      fileList: [null, Validators.required()],
      recordList: this.tableResponse.formArray,
      isViewAmount: [!!(this.todo === 'view')],
      fileApprovalList: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<OepResponse>(
        '/oep/add-edit',
        new RouteRequestModel(this.todo, this.procurementId)
      )
      .subscribe((oepResponse: OepResponse) => {
        this.oepResponse = oepResponse;
        this.procurement = oepResponse.procurement;
        this.currencyOptionList.setRequestValues(oepResponse.currencyList);
        this.oepItemList = oepResponse.oepItemList;
        this.oep = oepResponse.oep;
        if (oepResponse.isUserApproval) {
          this.formGroup.get('fileList').setIsView(true);
        }
        if (oepResponse.oep) {
          this.fileUploader.setFileList(oepResponse.fileList);
          this.formGroup.patchValue({
            id: this.oep.id,
            amount: this.oep.amount,
            oepStatus: this.oep.oepStatus,
            procurement: this.oep.procurement,
            fileList: this.fileUploader.fileObjectList
          });
          this.oepStatus = this.oep.oepStatus;
        }
        this.setStateReady();
        this.oepItemList.forEach((oepItem: any) => {
          if (oepItem.amount) {
            const amount = {
              price: oepItem.amount,
              currency: this.oepResponse.currencyList[0]
            };
            const totalAmount = {
              price: oepItem.totalAmount,
              currency: this.oepResponse.currencyList[0]
            };
            oepItem.amount = amount;
            oepItem.totalAmount = totalAmount;
          }
        });
        this.tableResponseChangeHistory.setCustomData(this.formGroup.value.id);
        this.tableResponse.setRecordList(this.oepItemList);
        this.tableResponse.reload();
        this.setIshowButton();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementItem.prItem.pr.code',
        header: 'table.column.prNumber'
      },

      {
        field: 'procurementItem.prItem.item.name',
        header: 'table.column.itemName'
      },
      {
        field: 'procurementItem.prItem.item.code',
        header: 'table.column.itemCode'
      },

      {
        field: 'procurementItem.prItem.item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'procurementItem.prItem.item.itemType.code'
        }
      },
      {
        field: 'procurementItem.prItem.quantity',
        header: 'table.column.qty',
        plugins: { name: 'text-field', type: 'decimal', isView: true },
        className: 'text-right'
      },
      {
        field: 'procurementItem.prItem.item.uom.name',
        header: 'table.column.uom'
      },

      {
        field: 'amount',
        header: 'table.column.price',
        plugins: [
          {
            name: 'currency',
            optionList: this.currencyOptionList,
            isView: !!(this.todo === 'view'),
            validators: Validators.required(),
            onInput: this.onInput.bind(this),
            onChange: this.onInput.bind(this)
          },
          {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): HTMLElement => {
              const spanElement = document.createElement('span');
              spanElement.textContent = ' Price Reference';
              spanElement.style.textDecoration = 'underline';
              spanElement.className =
                'd-block text-primary text-right py-1 text-hyperlink';
              spanElement.style.cursor = 'pointer';

              spanElement.addEventListener('click', () => {
                this.onClick(tablePlugin);
              });

              return spanElement;
            }
          }
        ]
      },
      {
        field: 'totalAmount',
        header: 'table.column.totalPrice',
        plugins: {
          name: 'currency',
          optionList: this.currencyOptionList,
          isView: true,
          validators: Validators.required()
        },
        className: 'text-right'
      }
    ]);

    this.tableResponseChangeHistory = new TableResponseModel(this.moduleCode, [
      {
        field: 'user.name',
        header: 'table.column.user',
        plugins: {
          name: 'hyperlink',
          onClick: this.onClickChangeHistory.bind(this)
        }
      },

      {
        field: 'createdDate',
        header: 'table.column.createdDate',
        plugins: 'date'
      },
      {
        field: 'amount',
        header: 'table.column.totalOEP',
        plugins: { name: 'default', type: 'currency' },
        className: 'text-right'
      }
    ]);
  }

  public onAfterRowCreated(tableRow: TableRow): void {
    if (this.isShowButtonChange) {
      tableRow.formGroup.setIsView(false);
    } else {
      tableRow.formGroup.setIsView(true);
    }
  }

  public onInput(amount): void {
    const currentRowChange = this.tableResponse.currentRowChange;
    if (
      +amount.price > +currentRowChange.row.record.procurementItem.prItem.price
    ) {
      const amountForm = currentRowChange.row.formGroup.get('amount');
      amountForm.setValidators([
        Validators.max(
          +currentRowChange.row.record.procurementItem.prItem.price
        )
      ]);
      amountForm.setErrors({
        message: this.translateService.instant('app.validation.max')
      });
      amountForm.markAsTouched({ onlySelf: true });
    } else if (!+amount.price) {
      const amountForm = currentRowChange.row.formGroup.get('amount');
      amountForm.setValidators([Validators.required()]);
      amountForm.setErrors({
        message: this.translateService.instant('app.validation.required')
      });
      amountForm.markAsTouched({ onlySelf: true });
    }
    const tableColumnTotalAmount: TableColumn =
      currentRowChange.row.columnList[7];
    const totalAmount =
      +amount.price *
      currentRowChange.row.record.procurementItem.prItem.quantity;
    const objTotalAmount = {
      price: totalAmount,
      currency: this.oepResponse.currencyList[0]
    };
    tableColumnTotalAmount.changeValue(objTotalAmount);
    tableColumnTotalAmount.reload();

    const recordList = this.tableResponse.getRecordList().map(record => {
      const indexOfR = this.tableResponse
        .getUpdatedRecordList()
        .findIndex(
          updateRecord =>
            updateRecord.procurementItem.id === record.procurementItem.id
        );
      return indexOfR === -1
        ? record
        : this.tableResponse.getUpdatedRecordList()[indexOfR];
    });

    this.tableResponse.setRecordList(recordList);
    this.doSetAmount();
    this.formGroup.markAsDirty();
  }

  public doSetAmount(): void {
    let totalAmount = 0;
    this.tableResponse.getRecordList().forEach((oepItem: any) => {
      if (oepItem.totalAmount) {
        totalAmount = +totalAmount + oepItem.totalAmount['price'];
      }
    });
    this.formGroup.patchValue({
      amount: totalAmount
    });
  }

  public onClick(tablePluginData: TablePluginData): void {
    if (!this.formGroup.get('isViewAmount').value) {
      const itemCode =
        tablePluginData.row.record.procurementItem.prItem.item.code;
      const itemName =
        tablePluginData.row.record.procurementItem.prItem.item.name;

      this.appPopupService
        .open(
          AppPopupPriceReferenceComponent,
          { itemCode, itemName },
          { size: 'xl' }
        )
        .subscribe(priceReference => {
          this.tableResponse.currentRowChange = {
            column: tablePluginData.column,
            row: tablePluginData.row,
            state: tablePluginData.state
          };
          const currentRowChange = this.tableResponse.currentRowChange;
          const amount = {
            price: priceReference[0].price,
            currency: this.oepResponse.currencyList[0]
          };
          const amountForm = currentRowChange.row.formGroup.get('amount');
          const tableColumnTotalAmount: TableColumn =
            currentRowChange.row.columnList[7];
          const totalAmount =
            +amount.price *
            currentRowChange.row.record.procurementItem.prItem.quantity;
          const objTotalAmount = {
            price: totalAmount,
            currency: this.oepResponse.currencyList[0]
          };
          tableColumnTotalAmount.changeValue(objTotalAmount);
          tableColumnTotalAmount.reload();

          const tableColumnAmount: TableColumn =
            currentRowChange.row.columnList[6];
          tableColumnAmount.changeValue(amount);
          tableColumnAmount.reload();

          if (
            +amount.price >
            +currentRowChange.row.record.procurementItem.prItem.price
          ) {
            amountForm.setValidators([
              Validators.max(
                +currentRowChange.row.record.procurementItem.prItem.pric
              )
            ]);
            amountForm.setErrors({
              message: this.translateService.instant('app.validation.max')
            });
            amountForm.markAsTouched({ onlySelf: true });
          } else if (!+amount.price) {
            amountForm.setValidators([Validators.required()]);
            amountForm.setErrors({
              message: this.translateService.instant('app.validation.required')
            });
            amountForm.markAsTouched({ onlySelf: true });
          }

          const recordList = this.tableResponse.getRecordList().map(record => {
            const indexOfR = this.tableResponse
              .getUpdatedRecordList()
              .findIndex(
                updateRecord =>
                  updateRecord.procurementItem.id === record.procurementItem.id
              );
            return indexOfR === -1
              ? record
              : this.tableResponse.getUpdatedRecordList()[indexOfR];
          });

          this.tableResponse.setRecordList(recordList);
          this.doSetAmount();
        });
    }
  }

  public setValidateAmount(): void {
    this.tableResponse.formArray.controls.forEach(oepItem => {
      const amountForm = oepItem.get('amount');
      if (!+amountForm.value?.price) {
        amountForm.setValidators([Validators.required()]);
        amountForm.setErrors({
          message: this.translateService.instant('app.validation.required')
        });
        amountForm.markAsTouched({ onlySelf: true });
      }
    });
  }

  public getValidateButton(): boolean {
    let dissableButton = false;
    this.tableResponse.formArray.controls.forEach(oepItem => {
      const amountForm = oepItem.get('amount');
      if (!+amountForm.value?.price) {
        dissableButton = true;
      } else {
        dissableButton = false;
      }
    });
    return dissableButton;
  }

  public doSubmit(): void {
    this.setValidateAmount();
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const oepRequest: OepRequest = new OepRequest();
            const oep: Oep = this.formGroup.value;
            oepRequest.oep = oep;
            oepRequest.procurementId = this.procurementId;
            this.tableResponse.getRecordList().forEach((oepItem: OepItem) => {
              oepItem.amount = oepItem.amount['price'];
              oepItem.totalAmount = oepItem.totalAmount['price'];
            });
            oepRequest.oepItemList = this.tableResponse.getRecordList();
            oepRequest.fileList = this.formGroup.value.fileList;
            oepRequest.fileApprovalList = this.formGroup.value.fileApprovalList;
            oepRequest.isSubmit = true;

            const url = this.oep ? '/oep/update' : '/oep/insert';
            this.httpClientService
              .post<Response<OepRequest>>(url, oepRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccess()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate([this.urlBackOutside]);
                      } else {
                        this.router
                          .navigateByUrl('/', { skipLocationChange: true })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set(
                              'procurementId',
                              this.procurementId
                            );
                            this.global.routerParams.set(
                              'oepStatus',
                              this.oepStatus
                            );
                            this.global.routerParams.set(
                              'urlBackOutside',
                              '/pages/oep'
                            );
                            this.global.routerParams.set('todo', 'view');
                            this.router.navigate(['/pages/oep/detail']);
                          });
                      }
                    });
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doSave(): void {
    this.formGroup.get('fileList').clearValidators();
    this.formGroup.get('fileList').updateValueAndValidity();
    this.setValidateAmount();

    this.validate();
    if (this.formGroup.valid) {
      this.setStateProcessing();
      const oepRequest: OepRequest = new OepRequest();
      const oep: Oep = this.formGroup.value;
      oepRequest.oep = oep;
      oepRequest.procurementId = this.procurementId;
      this.tableResponse.getRecordList().forEach((oepItem: OepItem) => {
        oepItem.amount = oepItem.amount['price'];
        oepItem.totalAmount = oepItem.totalAmount['price'];
      });
      oepRequest.oepItemList = this.tableResponse.getRecordList();
      oepRequest.fileApprovalList = this.formGroup.value.fileApprovalList;
      oepRequest.fileList = this.formGroup.value.fileList;
      oepRequest.isSubmit = false;

      const url = this.oep ? '/oep/update' : '/oep/insert';
      this.httpClientService
        .post<Response<OepRequest>>(url, oepRequest)
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.snackbarService.showWarning('app.msg.info.sucsessSave');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set(
                  'procurementId',
                  this.procurementId
                );
                this.global.routerParams.set('oepStatus', this.oepStatus);
                this.global.routerParams.set('urlBackOutside', '/pages/oep');
                this.global.routerParams.set('todo', 'edit');
                this.router.navigate(['/pages/oep/edit']);
              });
          } else {
            this.global.alertService.showError(response.statusText);
          }
          this.setStateReady();
        });
    }
  }

  public approveFn(approveStatus: boolean): void {
    this.validate();
    if (approveStatus && this.formGroup.valid) {
      const oepRequest: OepRequest = new OepRequest();
      const oep: Oep = this.formGroup.value;
      oepRequest.oep = oep;
      oepRequest.procurementId = this.procurementId;
      this.tableResponse.getRecordList().forEach((oepItem: OepItem) => {
        oepItem.amount = oepItem.amount['price'];
        oepItem.totalAmount = oepItem.totalAmount['price'];
      });
      oepRequest.oepItemList = this.tableResponse.getRecordList();
      oepRequest.fileList = this.formGroup.value.fileList;
      oepRequest.fileApprovalList = this.formGroup.value.fileApprovalList;
      oepRequest.isSubmit = false;
      const url = this.oep ? '/oep/update' : '/oep/insert';
      this.httpClientService
        .post<Response<OepRequest>>(url, oepRequest)
        .subscribe(response => {
          if (response.status !== ResponseStatusModel.OK) {
            this.global.alertService.showError(response.statusText);
          }
        });
    }
  }

  public onClickChangeHistory(oepHistory: OepHistory): void {
    const oepHistoryId = oepHistory.id;
    const grandTotal = oepHistory.amount;
    const currency = this.oepResponse.currencyList[0];
    this.appPopupService
      .open(
        AppPopupDetailHistoryComponent,
        { oepHistoryId, grandTotal, currency },
        { size: 'xl' }
      )
      .subscribe();
  }

  public doChangeOep(): void {
    this.isShowButton = true;
    this.isShowButtonChange = false;
    this.formGroup.get('isViewAmount').patchValue(false);
    this.tableResponse.formArray.controls.forEach(oepItem => {
      oepItem.get('amount').setIsView(false);
    });
    this.oepItemTempList = this.tableResponse.getRecordList();
  }

  public setIshowButton(): void {
    if (this.oepResponse.isUserApproval) {
      this.isShowButton = false;
      if (this.isApprovalViewOnly) {
        this.isShowButtonChange = false;
        this.formGroup.get('isViewAmount').patchValue(true);
        this.tableResponse.formArray.controls.forEach(oepItem => {
          oepItem.get('amount').setIsView(true);
        });
      } else {
        this.isShowButtonChange = true;
        this.formGroup.get('isViewAmount').patchValue(true);
        this.tableResponse.formArray.controls.forEach(oepItem => {
          oepItem.get('amount').setIsView(true);
        });
      }
    } else {
      this.isShowButton = false;
      this.isShowButtonChange = false;
      this.tableResponse.formArray.controls.forEach(oepItem => {
        oepItem.get('amount').setIsView(false);
      });
    }
  }

  public doSaveOep(): void {
    this.isShowButtonChange = true;
    this.isShowButton = false;
    this.formGroup.get('isViewAmount').patchValue(true);
    this.tableResponse.formArray.controls.forEach(oepItem => {
      oepItem.get('amount').setIsView(true);
    });
    if (this.oepResponse.isUserApproval) {
      this.formGroup
        .get('fileApprovalList')
        .setValidators([Validators.required()]);
      this.formGroup.get('fileApprovalList').updateValueAndValidity();
    }
  }

  public doCancelOep(): void {
    this.isShowButtonChange = true;
    this.isShowButton = false;
    this.formGroup.get('isViewAmount').patchValue(true);
    this.tableResponse.formArray.controls.forEach(oepItem => {
      oepItem.get('amount').setIsView(true);
    });
    this.tableResponse.formArray.patchValue(this.oepItemTempList);
    this.tableResponse.setRecordList(this.oepItemTempList);
    this.doSetAmount();
  }
}
