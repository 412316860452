import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../guard/confirm.guard';
import { SharedModule } from '../../shared/shared.module';
import { ReportRecapBudgetUsedPopupChooseColumnComponent } from './report-recap-budget-used-popup-choose-column.component';
import { ReportRecapBudgetUsedPopupFullViewComponent } from './report-recap-budget-used-popup-full-view.component';
import { ReportRecapBudgetUsedComponent } from './report-recap-budget-used.component';

export const routes = [
  {
    path: '',
    component: ReportRecapBudgetUsedComponent,
    data: { breadcrumb: 'report-recap-budget-used.breadcrumb.index' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [
    ReportRecapBudgetUsedComponent,
    ReportRecapBudgetUsedPopupChooseColumnComponent,
    ReportRecapBudgetUsedPopupFullViewComponent
  ],
  providers: []
})
export class ReportRecapBudgetUsedModule {}
