import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { BudgetAllocation } from 'src/app/core/bean/budget-allocation';
import { BudgetType } from 'src/app/core/bean/budget-type';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { FieldFormatEnum } from '../../app-table/model/field-format.enum';
import { TableResponseModel } from '../../app-table/model/table-response-model';
import { AppPopupChooseBudgetCustomDataResponse } from './app-popup-choose-budget-custom-data.response';
import {
  AppPopupChooseBudgetModel,
  ChangeBudget
} from './app-popup-choose-budget.model';

@Component({
  templateUrl: './app-popup-choose-budget.component.html'
})
export class AppPopupChooseBudgetComponent extends BaseModuleComponent {
  @Input() isMultiple: boolean;
  @Input() budgetAllocation: BudgetAllocation;
  @Output() onChange: EventEmitter<BudgetAllocation[]> = new EventEmitter();

  public tableResponse: TableResponseModel<BudgetAllocation>;
  public activityPeriodOptionList: OptionListModel<number> =
    new OptionListModel(false);
  public budgetTypeOptionList: OptionListModel<BudgetType> =
    new OptionListModel(false);
  public appPopupChooseBudgetModel: AppPopupChooseBudgetModel =
    new AppPopupChooseBudgetModel();
  public errorMapper: Map<number, string> = new Map();
  public budgetAllocationTempList: BudgetAllocation[] = [];
  public isBudgetChecked = false;
  public organizationIdParams: Object;

  constructor(translateService: TranslateService) {
    super('app-popup-choose-budget', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.getOptionList();
    this.setBudgetAllocation();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      activityPeriod: [null],
      organization: [null],
      workProgram: [null],
      budgetType: [null],
      costCenter: [null],
      branchOffice: [null],
      coa: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'organization.name',
        header: 'table.column.organization',
        customClass: 'text-center'
      },
      {
        field: 'costCenter.code',
        header: 'table.column.costCenterCode',
        customClass: 'text-center'
      },
      {
        field: 'costCenter.name',
        header: 'table.column.costCenterName',
        customClass: 'text-center'
      },
      {
        field: 'coa.code',
        header: 'table.column.coaCode',
        customClass: 'text-center'
      },
      {
        field: 'coa.name',
        header: 'table.column.coaName',
        customClass: 'text-center'
      },
      {
        field: 'budgetType.name',
        header: 'table.column.budgetType',
        customClass: 'text-center'
      },
      {
        field: 'availableBudget',
        header: 'table.column.availableBudget',
        format: FieldFormatEnum.Currency
      }
    ]);
  }

  public getOptionList(): void {
    this.httpClientService
      .get<AppPopupChooseBudgetCustomDataResponse>(
        '/app-popup-choose-budget/get-option-list'
      )
      .subscribe((response: AppPopupChooseBudgetCustomDataResponse) => {
        this.activityPeriodOptionList.setRequestValues(
          response.activityPeriodList
        );
        this.budgetTypeOptionList.setRequestValues(response.budgetTypeList);
        this.setStateReady();
      });
  }

  public setBudgetAllocation(): void {
    if (this.budgetAllocation) {
      let bookedBudget: ChangeBudget =
        this.appPopupChooseBudgetModel.recordPageMap.get(
          this.budgetAllocation.id
        );
      if (!bookedBudget) {
          bookedBudget = {
            id: this.budgetAllocation.id,
            isChecked: true,
            costCenter: this.budgetAllocation.costCenter,
            coa: this.budgetAllocation.coa,
            availableBudget: this.budgetAllocation.availableBudget
          };
        }
      this.appPopupChooseBudgetModel.recordPageMap.set(
        this.budgetAllocation.id,
        bookedBudget
      );
    }
  }

  public doCheck(event: any, budgetAllocation: BudgetAllocation): void {
    this.appPopupChooseBudgetModel.recordPageMap.clear(); // Custom BJB
    let bookedBudget: ChangeBudget =
      this.appPopupChooseBudgetModel.recordPageMap.get(budgetAllocation.id);
    if (!bookedBudget) {
      bookedBudget = {
        id: budgetAllocation.id,
        isChecked: event.target.checked,
        costCenter: budgetAllocation.costCenter,
        coa: budgetAllocation.coa,
        availableBudget: budgetAllocation.availableBudget
      };
    }

    if (this.appPopupChooseBudgetModel.isCheckedAll && !event.target.checked) {
      this.appPopupChooseBudgetModel.isCheckedAll = false;
      bookedBudget.isChecked = event.target.checked;
    } else {
      bookedBudget.isChecked = event.target.checked;
    }
    this.appPopupChooseBudgetModel.recordPageMap.set(
      budgetAllocation.id,
      bookedBudget
    );

    if (!event.target.checked) {
      this.appPopupChooseBudgetModel.recordPageMap.delete(budgetAllocation.id);
    }
  }

  public doSort(sortField: string): void {
    this.tableResponse.setSortField(sortField);
    this.tableResponse.reload();
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public get budgetAllocationList(): FormArray {
    return this.formGroup.get('budgetAllocationList') as FormArray;
  }

  public onChangeOrganization(): void {
    this.organizationIdParams = {
      organizationId:
        this.formGroup.get('organization').value[
          this.formGroup.get('organization').value.length - 1
        ].id
    };
    this.formGroup.get('workProgram').patchValue(null);
  }

  public setWorkPlanItemBudgetList(): void {
    this.appPopupChooseBudgetModel.recordPageMap.forEach(
      budgetAllocationModel => {
        if (budgetAllocationModel.isChecked) {
          this.isBudgetChecked = true;
          const budgetAllocationTemp: BudgetAllocation = new BudgetAllocation();
          budgetAllocationTemp.id = budgetAllocationModel.id;
          budgetAllocationTemp.coa = budgetAllocationModel.coa;
          budgetAllocationTemp.costCenter = budgetAllocationModel.costCenter;
          budgetAllocationTemp.availableBudget = budgetAllocationModel.availableBudget;
          this.budgetAllocationTempList.push(budgetAllocationTemp);
        }
      }
    );
  }

  public doChoose(): void {
    this.setWorkPlanItemBudgetList();
    if (this.isBudgetChecked) {
      this.onChange.emit(this.budgetAllocationTempList);
    } else {
      this.global.alertService.showError(
        'app-popup-choose-budget.validation.chooseBudget',
        '.popup-choose-budget'
      );
    }
  }

  public doApply(): void {
    this.global.routerParams.clear();
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }
}
