import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Contract } from '../../bean/contract';
import { ItemType } from '../../bean/item-type';
import { OrderPreparation } from '../../bean/order-preparation';
import { PrItem } from '../../bean/pr-item';
import { PrItemReleased } from '../../bean/pr-item-released';
import { Sow } from '../../bean/sow';
import { OptionListModel } from '../../model/option-list-model';
import { AppPopupChoosePrReleasedService } from '../app-popup/app-popup-choose-pr-released/app-popup-choose-pr-released.service';
import { AppPopupPrItemInfoService } from '../app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { ToastService } from '../app-toast/app-toast.service';
import { TablePluginData } from '../table/interface/table-plugin-data';
import { TableResponseModel } from '../table/model/table-response-model';

@Component({
  selector: 'app-item-request-released',
  templateUrl: './app-item-request-released.component.html'
})
export class AppItemRequestReleasedComponent extends BaseComponentComponent {
  @Input() public objectList: PrItemReleased[] = [];
  @Input() public isShowPrice: boolean;
  @Input() public isShowBudget = true;
  @Input() public isView: boolean;
  @Input() public itemTypeList: ItemType[] = [];
  @Input() public vendorId: number = null;
  @Input() public prId: number = null;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() baseModuleCode: String;

  public parentList: PrItemReleased[] = [];
  public tableResponse: TableResponseModel<PrItemReleased>;
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    false
  );
  public orderPreparationList: OrderPreparation[] = [];
  public contractBeanList: Contract[] = [];
  public source: String;
  public sowList: Sow[] = [];

  constructor(
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public appPopupChoosePrReleasedService: AppPopupChoosePrReleasedService,
    public currency: CurrencyPipe,
    public toastService: ToastService
  ) {
    super('app-item-request-released');
  }

  public onInit(): void {
    this.setOptionList();
    this.buildFormGroup();
    this.buildTableResponse();
    this.doSetAmount();
    this.getDataDetail();
    this.setStateReady();
  }

  ngOnChanges(): void {
    this.getDataDetail();
  }

  public buildTableResponse(): void {
    if (this.isShowPrice) {
      this.tableResponse = new TableResponseModel('app-item-request', [
        {
          field: 'prItem.prItemImageList',
          header: 'table.column.thumbnail',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): File => {
                if (tablePluginData.value && tablePluginData.value.length > 0) {
                  return tablePluginData.value[0].file;
                }
                return null;
              }
            },
            {
              name: 'img',
              classNameMap: {
                MATERIAL: 'pvr pv-box',
                JASA: 'pvr pv-tools'
              },
              fieldClassName: 'prItem.item.itemType.code',
              onClick: this.doViewItem.bind(this)
            }
          ],
          isSortable: false,
          isSearchTable: false
        },
        {
          field: 'prItem.pr.code',
          header: 'table.column.prNumber',
          plugins: { name: 'hyperlink', onClick: this.doViewItem.bind(this) }
        },
        {
          field: 'prItem.code',
          header: 'table.column.prLine'
        },
        {
          field: 'prItem.pr.requiredDate',
          header: 'table.column.requiredDate',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'prItem.pr.organization.name',
          header: 'table.column.departement'
        },
        {
          field: 'prItem.item.name',
          header: 'table.column.itemName'
        },

        {
          field: 'prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'prItem.item.itemType.code'
          }
        },
        {
          field: 'quantity',
          header: 'table.column.quantity',
          className: 'text-right',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              return this.global.converterService.convertDecimal(
                tablePlugin.value
              );
            }
          }
        },
        {
          field: 'price',
          header: 'table.column.price',
          className: 'text-right',
          plugins: {
            name: 'default',
            type: 'currency'
          }
        },
        {
          field: 'totalPrice',
          header: 'table.column.totalPrice',
          className: 'text-right',
          plugins: {
            name: 'default',
            type: 'currency'
          }
        }
      ]);
    } else if (!this.isShowPrice) {
      this.tableResponse = new TableResponseModel('app-item-request', [
        {
          field: 'prItem.prItemImageList',
          header: 'table.column.thumbnail',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): File => {
                if (tablePluginData.value && tablePluginData.value.length > 0) {
                  return tablePluginData.value[0].file;
                }
                return null;
              }
            },
            {
              name: 'img',
              classNameMap: {
                MATERIAL: 'pvr pv-box',
                JASA: 'pvr pv-tools'
              },
              fieldClassName: 'item.itemType.code',
              onClick: this.doViewItem.bind(this)
            }
          ],
          isSortable: false,
          isSearchTable: false
        },
        {
          field: 'prItem.pr.requiredDate',
          header: 'table.column.requiredDate',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'prItem.item.name',
          header: 'table.column.itemName'
        },
        {
          field: 'prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'prItem.item.itemType.code'
          }
        },
        {
          field: 'quantity',
          header: 'table.column.quantity',
          className: 'text-right',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              return this.global.converterService.convertDecimal(
                tablePlugin.value
              );
            }
          }
        },
        {
          field: 'prItem.item.uom.name',
          header: 'table.column.uom'
        }
      ]);
    }
    this.tableResponse.setRecordList(this.objectList);
    this.tableResponse.reload();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      itemType: [null],
      amount: [null],
      goodsAmount: [null],
      serviceAmount: [null]
    });
  }

  public setOptionList(): void {
    this.itemTypeOptionList.setRequestValues(this.itemTypeList);
  }

  public onChangeItem(): void {
    const itemType = this.formGroup.value.itemType;
    if (itemType) {
      const prItemRelList = [];
      this.objectList.forEach((prItemRel: PrItemReleased) => {
        if (prItemRel.prItem.item.itemType.id === itemType.id) {
          prItemRelList.push(prItemRel);
        }
      });
      this.tableResponse.setRecordList(prItemRelList);
    } else {
      this.tableResponse.setRecordList(this.objectList);
    }
    this.tableResponse.reload();
    this.doSetAmount();
  }

  public doSetAmount(): void {
    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;
    this.tableResponse.getRecordList().forEach((prItemRel: PrItemReleased) => {
      if (
        prItemRel.prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      ) {
        goodsAmountTotal = +goodsAmountTotal + +prItemRel.totalPrice;
      }
      if (
        prItemRel.prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
      ) {
        serviceAmountTotal = +serviceAmountTotal + +prItemRel.totalPrice;
      }
    });
    this.formGroup.patchValue({
      goodsAmount: goodsAmountTotal,
      serviceAmount: serviceAmountTotal,
      amount: +goodsAmountTotal + +serviceAmountTotal
    });
  }

  public doViewItem(prItemRel: PrItemReleased): void {
    this.appPopupPrItemInfoService.open(
      prItemRel.prItem.id,
      this.isShowBudget,
      prItemRel.price,
      prItemRel.totalPrice
    );
  }

  public getDataDetail(): void {
    if (this.tableResponse?.getRecordList().length > 0) {
      const prItemReleasedIdList = [];
      this.tableResponse.getRecordList().forEach(prItemReleased => {
        prItemReleasedIdList.push(prItemReleased.id);
      });
      this.httpClientService
        .get<OrderPreparation[]>(
          '/app-popup-choose-pr-released/get-order-preparation-list?prItemReleasedIdList=' +
            prItemReleasedIdList
        )
        .subscribe((response: OrderPreparation[]) => {
          this.orderPreparationList = response;
        });
      this.httpClientService
        .get<Contract[]>(
          '/app-popup-choose-pr-released/get-contract-bean-list?prItemReleasedIdList=' +
            prItemReleasedIdList
        )
        .subscribe((response: Contract[]) => {
          this.contractBeanList = response;
        });
    } else {
      this.orderPreparationList = null;
      this.contractBeanList = null;
    }
  }

  public doAddList(): void {
    const prItemRels = [];
    if (this.objectList && this.objectList.length > 0) {
      this.objectList.forEach(prItemRel => {
        prItemRels.push(prItemRel);
      });
    }

    this.appPopupChoosePrReleasedService
      .open(
        this.tableResponse.getRecordList(),
        this.vendorId,
        this.prId,
        this.baseModuleCode,
        this.orderPreparationList,
        this.contractBeanList
      )
      .subscribe(response => {
        this.objectList = [];
        this.parentList = response.parentList;
        (response.prItemReleasedList as Array<PrItemReleased>).forEach(
          prItemReleased => {
            this.objectList.push(prItemReleased);
          }
        );
        this.tableResponse.setRecordList(this.objectList);
        this.tableResponse.reload();
        this.doSetAmount();
        this.source = response.source;
        if (response.source === this.global.appConstant.core.SOURCE_CONTRACT) {
          const contract = response.contract;
          const prItemReleasedIdList = [];
          this.tableResponse.getRecordList().forEach(prItemReleased => {
            prItemReleasedIdList.push(prItemReleased.id);
          });
          this.httpClientService
            .post<Sow[]>('/order/get-sow-list-contract', prItemReleasedIdList)
            .subscribe(response => {
              this.onChange.emit({
                prItemList: this.tableResponse.getRecordList(),
                amountValue: this.formGroup.value,
                prItemReleasedList: this.tableResponse.getRecordList(), // prItemReleasedList this.tableResponse.records?
                source: this.source,
                sowList: response,
                contract
              });
              this.setStateReady();
            });
        } else {
          this.onChange.emit({
            prItemList: this.tableResponse.getRecordList(),
            amountValue: this.formGroup.value,
            prItemReleasedList: this.tableResponse.getRecordList(), // prItemReleasedList this.tableResponse.records?
            source: this.source,
            orderPreparationId: response.orderPreparationId
          });
        }
      });
  }

  public doDeleteItem(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (event.selectedRecordList.length !== this.tableResponse.getRecordList().length) {
            this.toastService.showError('app-item-request-released.validate.item');
          } else {
            event.selectedRecordList.forEach((record: PrItem) => {
              const indexOfRecord = this.tableResponse
                .getRecordList()
                .findIndex(r => r.id === record.id);
              this.tableResponse.getRecordList().splice(indexOfRecord, 1);
            });
            this.tableResponse.reload();
            this.getDataDetail();
            this.doSetAmount();
            this.onChange.emit({
              amountValue: this.formGroup.value,
              prItemReleasedList: this.tableResponse.getRecordList() // prItemReleasedList
            });
          }
        }
      });
  }
}
