<app-table-vendor-filter
  [isLoading]="formLoading"
  (onChange)="model.setCustomData($event)"
></app-table-vendor-filter>
<app-card>
  <div class="d-flex">
    <div class="input-group d-inline-block">
      <label class="mr-2" for="filter-table"
        >{{ 'app-table-vendor.show' | translate }}:
      </label>
      <select
        class="form-control custom-select"
        (change)="model.setPerPage($event.target.value)"
        id="filter-table"
        [ngStyle]="{ width: '56px' }"
        aria-label="show"
      >
        <option *ngFor="let option of showOptionList" [ngValue]="option">{{
          option
        }}</option>
      </select>
    </div>
    <div class="ml-2 d-inline-block" [ngStyle]="{ width: '300px' }">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><em class="fas fa-search"></em></span>
        </div>
        <input
          type="text"
          name="search"
          class="form-control"
          placeholder="{{ 'app.table.search' | translate }}"
          (input)="model.setSearchString($event.target.value)"
          aria-label="Search Table"
        />
      </div>
    </div>
    <div class="ml-2 d-inline-block" [ngStyle]="{ width: '250px' }">
      <select
        class="form-control"
        (change)="model.setSortField($event.target.value)"
        aria-label="Sort Table"
      >
        <option value="">{{ 'app-table-vendor.sortBy' | translate }}</option>
        <option value="Name"
          >{{ 'app-table-vendor.nameAtoZ' | translate }}
        </option>
        <option value="Performance">{{
          'app-table-vendor.performance' | translate
        }}</option>
        <option value="Location"
          >{{ 'app-table-vendor.location' | translate }}
        </option>
      </select>
    </div>
    <div
      class="ml-2 d-flex align-items-center justify-content-around"
      [ngStyle]="{ width: '100px' }"
    >
      <div class="view-button-list">
        <em
          class="fas fa-list-ul cursor-pointer"
          [ngStyle]="{ 'font-size': '1.5rem' }"
          (click)="model.setViewTipe('LIST')"
          [ngClass]="{ 'text-warning': model.viewType === 'LIST' }"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        ></em>
      </div>
      <div class="view-button-grid">
        <em
          class="fas fa-th cursor-pointer"
          [ngStyle]="{ 'font-size': '1.5rem' }"
          (click)="model.setViewTipe('GRID')"
          [ngClass]="{ 'text-warning': model.viewType === 'GRID' }"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        ></em>
      </div>
    </div>
    <div class="ml-2 d-flex align-items-center justify-content-center">
      <app-button-compare
        tooltip="app-table-vendor.showComparisonList"
        totalCompare="{{ vendorList.length }}"
        (click)="doShowPopupComparison()"
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
      >
      </app-button-compare>
    </div>
  </div>
</app-card>
<app-card [isLoading]="formLoading">
  <div
    class="d-block text-center"
    *ngIf="model.page.records && model.page.records.length === 0"
  >
    {{ 'app.info.noData' | translate }}
  </div>
  <div
    class="table-vendor"
    *ngIf="!model.isLoading"
    [ngClass]="model.viewType === 'GRID' ? 'grid' : ''"
  >
    <div
      class="vendor"
      *ngFor="let record of model.page.records"
      [ngClass]="model.viewType === 'GRID' ? 'text-center' : ''"
    >
      <div
        (click)="onChooseVendor.emit(record.vendor.id)"
        class="vendor-image cursor-pointer"
        [ngClass]="!record.vendor.logoFile ? 'no-image' : ''"
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
      >
        <img
          [attr.src]="
            global.config.BACKEND_ADDRESS +
              '/view-vendor/file/view/' +
              record.vendor.logoFile?.uploadedFileName +
              '/' + global.appConstant.fileType.IMG_LOGO_VENDOR
              | resolveImgSrc
              | async
          "
          *ngIf="record.vendor.logoFile"
          alt="Vendor Image"
          width="auto"
          height="auto"
        />
        <!-- <div class="loading-image">
          <em class="fas fa-circle-notch fa-pulse fa-3x fa-fw" *ngIf="record.vendor.logoFile?.uploadedFileName"></em>
        </div> -->
      </div>
      <ng-container *ngIf="model.viewType === 'LIST'">
        <div class="vendor-details">
          <h5
            class="vendor-name cursor-pointer"
            (click)="onChooseVendor.emit(record.vendor.id)"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
          >
            {{ record.vendor.vendorEntity.name + ' ' + record.vendor.name }}
          </h5>
          <p class="vendor-rating">
            <app-rating
              [value]="record.vendor.ratingPerformance"
              [isViewOnly]="true"
            ></app-rating>
          </p>
          <p class="vendor-location">
            <em class="fas fa-building mr-2"></em
            ><strong>{{ 'app-table-vendor.location' | translate }}: </strong
            >{{
              record.countryRegion.name +
                ', ' +
                record.provinceRegion.name +
                ', ' +
                record.cityRegion.name
            }}
          </p>
          <p class="vendor-segmentation"
            ><em class="fas fa-map-marker mr-2"></em
            ><strong
              >{{ 'app-table-vendor.segmentation' | translate }}:
            </strong>
            {{ record.vendor.segmentationName }}</p
          >
          <p
            class="vendor-contact cursor-pointer"
            (click)="doOpenPopupEmail(record.vendor.email)"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
          >
            <em
              class="far fa-envelope mr-2 cursor-pointer"
              title="{{ 'view-vendor.composeEmail' | translate }}"
            ></em
            >{{ 'app-table-vendor.contact' | translate }}
          </p>
        </div>
        <div class="vendor-compare">
          <app-button-compare
            tooltip="app-table-vendor.compare"
            (click)="doAddToComparisonList(record.vendor)"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
            >{{ 'app-table-vendor.compare' | translate }}</app-button-compare
          >
        </div>
      </ng-container>

      <ng-container *ngIf="model.viewType === 'GRID'">
        <div class="vendor-details text-center">
          <p class="vendor-rating">
            <app-rating
              [value]="record.vendor.ratingPerformance"
              [isViewOnly]="true"
            ></app-rating>
          </p>
          <h5
            class="vendor-name cursor-pointer"
            (click)="onChooseVendor.emit(record.vendor.id)"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
            >{{
              record.vendor.vendorEntity.name + ' ' + record.vendor.name
            }}</h5
          >
          <p class="vendor-location">
            <em class="fas fa-building mr-2"></em
            ><strong>{{ 'app-table-vendor.location' | translate }}: </strong
            >{{
              record.countryRegion.name +
                ', ' +
                record.provinceRegion.name +
                ', ' +
                record.cityRegion.name
            }}
          </p>
          <p class="vendor-segmentation"
            ><strong>{{ 'app-table-vendor.segmentation' | translate }}:</strong>
            {{ record.vendor.segmentationName }}</p
          >
        </div>
        <div
          class="vendor-footer d-flex justify-content-end align-items-center"
        >
          <em
            class="far fa-envelope mr-2 fa-2x cursor-pointer"
            title="{{ 'view-vendor.composeEmail' | translate }}"
            (click)="doOpenPopupEmail(record.vendor.email)"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
          ></em>
          <app-button-compare
            tooltip="app-table-vendor.compare"
            (click)="doAddToComparisonList(record.vendor)"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
          >
          </app-button-compare>
        </div>
      </ng-container>
    </div>
  </div>
  <app-pagination
    [model]="model.pagination"
    (onChange)="model.setCurrentPage($event)"
    *ngIf="model.page.records && model.page.records.length > 0"
  >
  </app-pagination>
</app-card>
