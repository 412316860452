import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppApprovalPathModule } from '../app-approval-path';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppTableModule } from '../app-table/app-table.module';
import { AppTextAreaModule } from '../app-text-area/app-text-area.module';
import { UploadModule } from '../upload';
import { AppApprovalPrcsComponent } from './app-approval-prcs.component';
@NgModule({
  imports: [
    VendorModule,
    AppTableModule,
    AppApprovalPathModule,
    UploadModule,
    AppTextAreaModule,
    AppButtonModule,
    AppCardModule
  ],
  declarations: [AppApprovalPrcsComponent],
  exports: [AppApprovalPrcsComponent]
})
export class AppApprovalPrcsModule {}
