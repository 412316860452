<app-popup header="lang.chooseFlag">
  <app-table [model]="tableResponse">
    <ng-template #actionButtons let-rowData>
      <app-button size="TN" (onClick)="doChoose(rowData)">
        {{ 'app.button.select' | translate }}
      </app-button>
    </ng-template>
  </app-table>
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close()">
        {{ 'app.button.cancel' | translate }}
      </app-button>
    </div>
  </ng-template>
</app-popup>