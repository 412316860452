import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppDropDownModule } from '../app-drop-down/app-drop-down.module';
import { AppSelectModule } from '../app-select/app-select.module';
import { AppTaggingModule } from '../app-tagging/app-tagging.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppDropdownSelectComponent } from './app-dropdown-select.component';

@NgModule({
    imports: [
      VendorModule,
      AppTaggingModule,
      AppTooltipWrapperModule,
      AppSelectModule,
      AppDropDownModule,
      PipesModule
    ],
    declarations: [AppDropdownSelectComponent],
    exports: [AppDropdownSelectComponent]
  })
  export class AppDropdownSelectModule {}
