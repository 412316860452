import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAlertXComponent } from './app-alert-x.component';

@NgModule({
  imports: [VendorModule],
  declarations: [AppAlertXComponent],
  entryComponents: [AppAlertXComponent],
  exports: [AppAlertXComponent]
})
export class AppAlertXModule {}
