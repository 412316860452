<div class="row main-row">
  <div class="col-12">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <app-card
        header="employee.title"
        [isLoading]="formLoading"
        [isSaving]="formSaving"
      >
        <app-fullfilled></app-fullfilled>

        <br/>
        <app-badge color="SUCCESS">Success</app-badge>
        <app-badge [pill]="false">Default without pill</app-badge>
        <app-badge-catalog color="INFO">Vendor</app-badge-catalog>
        <app-badge-catalog color="WARNING">Item</app-badge-catalog>
        <br/>
        <br/>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Name</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              formControlName="name"
              tooltip="Example name tooltip"
              type="alphabet"
            >
              <app-flag-new oldValue="12345" newValue="123456"></app-flag-new>
            </app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Gender</label>
          <div class="col-sm-9 text-left">
            <app-radio formControlName="gender" [optionList]="genderOptionList">
            </app-radio>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">Zodiac</label>
          <div class="col-sm-9 text-left">
            <app-radio
              formControlName="zodiac"
              position="HORIZONTAL"
              [optionList]="zodiacOptionList"
            ></app-radio>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">Address</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="address"></app-text-area>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">Location</label>
          <div class="col-sm-9 text-left">
            <app-text-map formControlName="location"></app-text-map>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Salary</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="salary" type="currency">
            </app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Donation</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              size="TN"
              formControlName="donation"
              type="integer"
              symbolic="<strong>%</strong>"
              tooltip="For Donation Purpose"
            >
            </app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Country</label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              formControlName="region"
              stringUrl="/region"
              header="Country"
              isLazy="true"
            ></app-text-tree>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Multiple Country</label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              formControlName="multipleRegion"
              stringUrl="/region"
              [isMultiple]="true"
              header="Multiple Country"
              isLazy="true"
            ></app-text-tree>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Email</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="email" type="email">
            </app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">Password</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="password" type="password">
            </app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">About</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="about"></app-text-area>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Birth Date</label>
          <div class="col-sm-9 text-left">
            <app-date-picker
              formControlName="birthDate"
              [maxDate]="maxBirthDate"
            ></app-date-picker>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Year Of Birth</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              size="TN"
              formControlName="yearOfBirth"
              type="integer"
            ></app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Vendor Category</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              [optionList]="vendorCategoryOptionList"
              formControlName="vendorCategory"
            ></app-combo-box>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Interest</label>
          <div class="col-sm-9 text-left">
            <app-chosen
              formControlName="interest"
              [optionList]="interestOptionList"
            ></app-chosen>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Tags</label>
          <div class="col-sm-9 text-left">
            <app-text-tagging formControlName="tags"></app-text-tagging>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Satuan</label>
          <div class="col-sm-9 text-left">
            <app-auto-complete
              formControlName="uom"
              stringUrl="/item/get-uom-list"
            ></app-auto-complete>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Phone</label>
          <div class="col-sm-9 text-left">
            <app-phone
              formControlName="phone"
              [optionList]="countryCodeOptionList"
            ></app-phone>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">NPWP</label>
          <div class="col-sm-9 text-left">
            <app-npwp formControlName="npwp"></app-npwp>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">Employee</label>
          <div class="col-sm-9 text-left">
            <div class="form-row">
              <div class="form-group row col-4">
                <label class="control-label px-3">Administration</label>
                <div class="col text-left">
                  <app-text-field size="XL" formControlName="administration">
                  </app-text-field>
                </div>
              </div>
              <div class="form-group row col-4">
                <label class="control-label px-3">Operator</label>
                <div class="col text-left">
                  <app-text-field size="XL" formControlName="operator">
                  </app-text-field>
                </div>
              </div>
              <div class="form-group row col-4">
                <label class="control-label px-3">Managerial</label>
                <div class="col text-left">
                  <app-text-field size="XL" formControlName="managerial">
                  </app-text-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">Is Has Pet?</label>
          <div class="col-sm-9 text-left">
            <app-toggle-switch formControlName="isHasPet"></app-toggle-switch>
          </div>
        </div>

        <app-fieldset legend="Login Information">
          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView ? 'required' : ''"
          >
            <label class="col-sm-3 control-label">Start Date</label>
            <div class="col-sm-9 text-left">
              <app-date-picker formControlName="startDate" maxDate="endDate">
              </app-date-picker>
            </div>
          </div>

          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView ? 'required' : ''"
          >
            <label class="col-sm-3 control-label">End Date</label>
            <div class="col-sm-9 text-left">
              <app-date-picker formControlName="endDate" minDate="startDate">
              </app-date-picker>
            </div>
          </div>
        </app-fieldset>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Are you sure?</label>
          <div class="col-sm-9 text-left">
            <app-check-box formControlName="makeSure"></app-check-box>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">Skills</label>
          <div class="col-sm-9 text-left">
            <app-check-box position="HORIZONTAL" formControlName="skills">
            </app-check-box>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label"
            >Best Programming Language</label
          >
          <div class="col-sm-9 text-left">
            <app-check-box formControlName="bestProgrammingLanguage">
            </app-check-box>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">Rating</label>
          <div class="col-sm-9 text-left">
            <app-rating formControlName="rating"></app-rating>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">Deadline</label>
          <div class="col-sm-9 text-left">
            <app-time formControlName="deadline"></app-time>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">Custom Tooltip</label>
          <div class="col-sm-9 text-left">
            <app-tooltip position="TOP_RIGHT">
              <em class="fas fa-eye"></em>
              <ng-template #tooltipContent>
                <table class="table table-striped" aria-describedby="Table">
                  <thead>
                    <tr>
                      <th scope="col">Code</th>
                      <th scope="col">: FIN01</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>: Finance Analysis</td>
                    </tr>
                    <tr>
                      <td>Type</td>
                      <td>: Common</td>
                    </tr>
                    <tr>
                      <td>Criteria</td>
                      <td
                        >: Liquidity Ratio, Solvabiliti Ratio, Profability
                        Ratio</td
                      >
                    </tr>
                  </tbody>
                </table>
              </ng-template>
            </app-tooltip>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">Date</label>
          <div class="col-sm-9 text-left d-flex">
            <div class="col-2 p-0">
              <app-month-picker size="XL" formControlName="monthFrom">
              </app-month-picker>
            </div>
            <span class="col-1 p-0 text-center">To</span>
            <div class="col-2 p-0">
              <app-month-picker size="XL" formControlName="monthTo">
              </app-month-picker>
            </div>
          </div>
        </div>

        <app-fieldset legend="Upload Section">
          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView ? 'required' : ''"
          >
            <label class="col-sm-3 control-label">Npwp</label>
            <div class="col-sm-9 text-left">
              <app-upload
                formControlName="dokumenKTP"
                [fileUploader]="fileUploader"
              ></app-upload>
            </div>
          </div>

          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView ? 'required' : ''"
          >
            <label class="col-sm-3 control-label">Dokumen Pengadaan</label>
            <div class="col-sm-9 text-left">
              <app-upload-multiple
                formControlName="dokumenPengadaan"
                [fileUploader]="fileUploaders"
              ></app-upload-multiple>
            </div>
          </div>
        </app-fieldset>

        <div class="row justify-content-center mt-5">
          <app-button color="SECONDARY" (onClick)="doBack()" class="mr-1">{{
            'app.button.cancel' | translate
          }}</app-button>
          <app-button color="PRIMARY" (onClick)="doSave()">{{
            'app.button.save' | translate
          }}</app-button>
        </div>
      </app-card>

      <app-card
        header="Document"
        [isLoading]="formLoading"
        [isSaving]="formSaving"
      >
        <app-alert-x color="WARNING" [isShowCloseButton]="true"
          >Lorem Ipsum Information afeaerges freagaeik</app-alert-x
        >
        <app-alert-icon
          color="INFO"
          icon="CONFETTI"
          class="alert-icon-center alert-icon"
          [isShowCloseButton]="true"
        >
          <strong style="display: grid">
            <span class="pb-3"
              >Congratulation you are passing the criteria for this procurement.
            </span>
            <span>Please wait for further information.</span>
          </strong>
        </app-alert-icon>

        <app-alert-icon color="INFO" icon="CONFETTI">
          <strong style="display: grid">
            <span class="pb-3"
              >Congratulation you are passing the criteria for this procurement.
            </span>
            <span>Please wait for further information.</span>
          </strong>
        </app-alert-icon>

        <app-upload-x
          formControlName="docA"
          [fileUploader]="fileUploaders"
        ></app-upload-x>

        <div class="form-group row">
          <div class="col-sm-3">
            <app-upload-x
              formControlName="docB"
              [fileUploader]="fileUploader"
              isSingle="true"
            >
            </app-upload-x>
          </div>
        </div>
      </app-card>
    </form>
  </div>
</div>
