import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupTabCompanyDataAddressComponent } from './app-popup-tab-company-data-address.component';
import { TabCompanyDataComponent } from './tab-company-data.component';
@NgModule({
  imports: [SharedModule],
  declarations: [
    TabCompanyDataComponent,
    AppPopupTabCompanyDataAddressComponent
  ],
  entryComponents: [
    TabCompanyDataComponent,
    AppPopupTabCompanyDataAddressComponent
  ],
  exports: [TabCompanyDataComponent]
})
export class TabCompanyDataModule {
  constructor(accordionService: AccordionService) {
    accordionService.register('tab-company-data', TabCompanyDataComponent);
  }
}
