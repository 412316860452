import { CurrencyPipe } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartTooltipItem } from 'chart.js';
import { ChartModel } from '../../../../../core/components/app-chart/models/chart-model';
import { OptionListModel } from '../../../../../core/model/option-list-model';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetTotalCostSavingRequest } from './model/widget-total-cost-saving-request';
import { WidgetTotalCostSavingResponse } from './model/widget-total-cost-saving-response';

import Chart = require('chart.js');
@Component({
  selector: 'dashboard-widget-total-cost-saving',
  templateUrl: './widget-total-cost-saving.component.html',
  styleUrls: ['./widget-total-cost-saving.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetTotalCostSavingComponent extends BaseWidgetComponent {
  public growth: number;
  public chartModel: ChartModel;
  public widgetTotalCostSavingRequest: WidgetTotalCostSavingRequest =
    new WidgetTotalCostSavingRequest();
  public widgetTotalCostSavingResponse: WidgetTotalCostSavingResponse =
    new WidgetTotalCostSavingResponse();
  public moneyFormatTranslateKeyList: Array<string>;
  public yearOptionList: OptionListModel<number> = new OptionListModel(true);
  public typeOptionList: OptionListModel<any> =
    new OptionListModel(true);
  public modelDefaultList: any[] = [];
  public modelList: any[] = [];
  public emptyValue = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  constructor(
    public translateService: TranslateService,
    private currencyPipe: CurrencyPipe
  ) {
    super('dashboard-widget-total-cost-saving');
  }

  public onInit(): void {
    this.setStateMoneyFormatTranslateKeyList();
    this.buildSetFormGroup();
    this.setFormGroup();
  }

  public buildSetFormGroup(): void {
    const typeList = [
      {
        id: 1,
        value: this.translateService.instant(
          this.moduleCode + '.label.costAvoidance'
        ),
        name: this.translateService.instant(
          this.moduleCode + '.filter.costAvoidance'
        )
      },
      {
        id: 2,
        value: this.translateService.instant(
          this.moduleCode + '.label.costIniciative'
        ),
        name: this.translateService.instant(
          this.moduleCode + '.filter.costIniciative'
        )
      }
    ];
    this.typeOptionList.setRequestValues(typeList);

    const currentYear = new Date().getFullYear();
    this.yearOptionList.setRequestValues(
      Array(5)
        .fill(0)
        .map((fill: number, index: number) => currentYear - 4 + fill + index)
    );

    this.formGroup = this.formBuilder.group({
      type: [typeList],
      year: [new Date().getFullYear()]
    });
    this.widgetTotalCostSavingRequest.year = this.formGroup.value.year;
  }

  private setStateMoneyFormatTranslateKeyList(): void {
    this.moneyFormatTranslateKeyList = [
      this.global.translateService.instant('app.moneyFormat.thousand'),
      this.global.translateService.instant('app.moneyFormat.million'),
      this.global.translateService.instant('app.moneyFormat.billion'),
      this.global.translateService.instant('app.moneyFormat.trillion'),
      this.global.translateService.instant('app.moneyFormat.quadrillion'),
      this.global.translateService.instant('app.moneyFormat.quantillion'),
      this.global.translateService.instant('app.moneyFormat.sextillion')
    ];
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<WidgetTotalCostSavingResponse>(
        '/widget-total-cost-saving/index',
        this.widgetTotalCostSavingRequest
      )
      .subscribe((response: WidgetTotalCostSavingResponse) => {
        if (response) {
          this.widgetTotalCostSavingResponse = response;
          this.growth =
            this.widgetTotalCostSavingResponse.growthFromPastYear || 0;
          this.setChartModel();
        }
        this.setStateReady();
      });
  }

  private reload(): void {
    this.widgetTotalCostSavingResponse.totalCostYearSelected =
      this.global.converterService.convertToShortMoney(
        this.widgetTotalCostSavingResponse.totalCostYearSelected || 0,
        this.moneyFormatTranslateKeyList
      ) as string;
    this.widgetTotalCostSavingResponse.growthFromPastYear =
      this.global.converterService.convertToShortMoney(
        this.widgetTotalCostSavingResponse.growthFromPastYear || 0,
        this.moneyFormatTranslateKeyList
      ) as string;
    this.widgetTotalCostSavingResponse.totalCostIniciative =
      this.global.converterService.convertToShortMoney(
        this.widgetTotalCostSavingResponse.totalCostIniciative || 0,
        this.moneyFormatTranslateKeyList
      ) as string;
    this.widgetTotalCostSavingResponse.totalCostYearAvoidance =
      this.global.converterService.convertToShortMoney(
        this.widgetTotalCostSavingResponse.totalCostYearAvoidance || 0,
        this.moneyFormatTranslateKeyList
      ) as string;
  }

  public setModel(): void {
    this.modelDefaultList = [
      {
        code: this.translateService.instant(
          this.moduleCode + '.label.costIniciative'
        ),
        total: this.widgetTotalCostSavingResponse.totalCostIniciative,
        color: '#14b1ab'
      },
      {
        code: this.translateService.instant(
          this.moduleCode + '.label.costAvoidance'
        ),
        total: this.widgetTotalCostSavingResponse.totalCostYearAvoidance,
        color: '#0772B6'
      }
    ];
  }
  public setChartModel(): void {
    this.reload();
    this.setModel();
    let labels = [];
    const dataSetLabels = [];
    const dataSets = [];
    const typeRequestList = this.formGroup.get('type').value?.map(val => val.value);
    this.modelList = (!typeRequestList || typeRequestList.length === 0) ? this.modelDefaultList : this.modelDefaultList.filter(model => typeRequestList.includes(model.code)
    );
    const widgetTotalCostSavingModelResponseList = (!typeRequestList || typeRequestList.length === 0) ? this.widgetTotalCostSavingResponse.widgetTotalCostSavingModelResponseList : this.widgetTotalCostSavingResponse.widgetTotalCostSavingModelResponseList.filter(response => typeRequestList.includes(response.label));
    widgetTotalCostSavingModelResponseList.forEach(
      widgetTotalCostSavingModelResponse => {
        if (labels.length === 0) {
          labels = [...widgetTotalCostSavingModelResponse.data].map(
            data => data.month
          );
        }
        dataSets.push({
          data: !this.formGroup.value?.year ? [] : (!typeRequestList || typeRequestList.length === 0) ? this.emptyValue : [...widgetTotalCostSavingModelResponse.data].map(
            data => data.total
          )
        });
        dataSetLabels.push(
          widgetTotalCostSavingModelResponse.label
        );
      }
    );

    const CURRENCY_PRECISSION_SCALE =
      this.global.appConstant.core.CURRENCY_PRECISSION_SCALE;
    const CURRENCY_DIGITS_INFO = `0.${CURRENCY_PRECISSION_SCALE}-${CURRENCY_PRECISSION_SCALE}`;
    this.chartModel = new ChartModel(
      'bar',
      labels,
      dataSets,
      dataSetLabels,
      {
        legend: {
          display: false
        },
        defaultColor: this.modelList.map(model => model.color),
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 500000000,
                callback: (value): string => {
                  return this.global.converterService.convertToShortMoneyChart(
                    value
                  ) as string;
                }
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ]
        },
        tooltips: {
          mode: 'nearest',
          xPadding: 20,
          yPadding: 20,
          titleFontStyle: 'font-weight: normal; padding: 20px',
          bodyFontStyle: 'font-weight: bold',
          footerFontStyle: 'font-weight: normal',
          callbacks: {
            title: (
              item: ChartTooltipItem[],
              data: Chart.ChartData
            ): string => {
              return this.global.translateService.instant(
                data.datasets[item[0].datasetIndex].label
              );
            },
            label: () => null,
            footer: (item: Chart.ChartTooltipItem[]): string => {
              const savingPercentage =
                widgetTotalCostSavingModelResponseList[
                  item[0].datasetIndex
                ].data[item[0].index].savingPercentage;
              const label = this.translateService.instant('dashboard-widget-total-cost-saving.tooltip.saving') + ' ' + this.global.converterService.convertDecimal(savingPercentage) + '% ' + this.translateService.instant('dashboard-widget-total-cost-saving.tooltip.fromIniciative');
              return savingPercentage
                ? label
                : null;
            },
            afterBody: (item: Chart.ChartTooltipItem[]): string => {
              const data =
                widgetTotalCostSavingModelResponseList[
                  item[0].datasetIndex
                ].data[item[0].index];
              return `${this.currencyPipe.transform(
                data.total,
                '',
                '',
                CURRENCY_DIGITS_INFO
              )} ${data.currency || 'IDR'}`;
            }
          }
        }
      }
    );
  }

  public onChangeType(): void {
    // if (this.formGroup.value.type && this.formGroup.value.type.length > 0) {
    this.setChartModel();
    // } else {
    //   this.typeOptionList
    // }
  }
  public onChangeYear(): void {
    if (this.formGroup.value.year) {
      this.widgetTotalCostSavingRequest.year = this.formGroup.value.year;
      this.setStateLoading();
      this.setFormGroup();
    } else {
      this.setChartModel();
    }
  }
}
