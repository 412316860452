import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { RfqStatus } from '../../core/bean/rfq-status';
import { RfqVendor } from '../../core/bean/rfq-vendor';
import { AppTableXComponent } from '../../core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
@Component({
  templateUrl: './rfq-response.component.html'
})
export class RfqResponseComponent extends BaseModuleComponent {
  @ViewChild(AppTableXComponent) table: AppTableXComponent;
  public tableResponse: TableResponseModel<RfqVendor>;
  public statusOptionList: OptionListModel<RfqStatus> = new OptionListModel(
    true
  );
  public customData = '';
  constructor(translateService: TranslateService) {
    super('rfq-response', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      rfqDate: [null],
      rfqFromDate: [null],
      rfqUntilDate: [null],
      dueDate: [null],
      dueFromDate: [null],
      dueUntilDate: [null],
      statusList: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<RfqStatus[]>('/rfq-response/get-option-list')
      .subscribe(response => {
        if (response != null) {
          this.statusOptionList.setRequestValues(response);
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'rfq.code',
        header: 'table.column.rfqNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doEdit.bind(this)
        }
      },
      {
        field: 'rfq.title',
        header: 'table.column.title'
      },
      {
        field: 'submittedDate',
        header: 'table.column.submittedDate',
        plugins: 'date'
      },
      {
        field: 'rfq.endDate',
        header: 'table.column.responseDueDate',
        plugins: 'date'
      },
      {
        field: 'responseRfqStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'responseRfqStatus.code',
          colorMap: {
            DRAFT: 'SECONDARY',
            REVISION: 'GOOD',
            WAITING_APPROVAL: 'WARNING',
            WAITING_RESPONSE: 'GOOD',
            CANCELED: 'DANGER',
            CLOSED_RESPONSE: 'DANGER',
            DONE: 'SUCCESS',
            SUBMITTED: 'SUCCESS'
          }
        }
      }
    ]);
    const isFromWidget = this.global.routerParams.get('isFromWidget');
    if (isFromWidget) {
      this.tableResponse.setCustomData({ isFromWidget: true });
      this.global.routerParams.clear();
    }
  }

  doEdit(rfqVendor: RfqVendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('rfqVendor', rfqVendor);
    this.global.routerParams.set('rfqVendorId', rfqVendor.id);
    this.global.routerParams.set('urlBackOutside', '/pages/rfq-response');
    if (
      rfqVendor.responseRfqStatus.code ===
        this.global.appConstant.pm.RFQ_STATUS_WAITING_RESPONSE ||
      rfqVendor.responseRfqStatus.code ===
        this.global.appConstant.pm.RFQ_STATUS_DRAFT
    ) {
      this.router.navigate(['/pages/rfq-response/edit']);
      this.global.routerParams.set('todo', 'edit');
    } else {
      this.router.navigate(['/pages/rfq-response/detail']);
      this.global.routerParams.set('todo', 'view');
    }
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.rfqDate) {
      this.formGroup.patchValue({
        rfqFromDate: this.formGroup.value.rfqDate.from,
        rfqUntilDate: this.formGroup.value.rfqDate.to
      });
    } else {
      this.formGroup.patchValue({
        rfqFromDate: null,
        rfqUntilDate: null
      });
    }
    if (this.formGroup.value.dueDate) {
      this.formGroup.patchValue({
        dueFromDate: this.formGroup.value.dueDate.from,
        dueUntilDate: this.formGroup.value.dueDate.to
      });
    } else {
      this.formGroup.patchValue({
        dueFromDate: null,
        dueUntilDate: null
      });
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
