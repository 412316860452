import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { Contract } from '../bean/contract';
import { User } from '../bean/user';
import { ContractStatus } from '../bean/contract-status';

export class ContractTerminationEntity extends BaseWorkflowObjectEntity {
  contract: Contract;
  terminationDate: Date;
  description: string;
  terminatedByUser: User;
  terminationStatus: ContractStatus = new ContractStatus();
}
