import { Component, ViewEncapsulation } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Cart } from 'src/app/core/bean/cart';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PrItemBudget } from 'src/app/core/bean/pr-item-budget';
import { PrItemImage } from 'src/app/core/bean/pr-item-image';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { CartWebsocketService } from 'src/app/core/services/websocket/cart-websocket.service';
import { CartView } from 'src/app/core/view/entity/bean/cart-view';
import { CatalogView } from '../../core/view/entity/bean/catalog-view';
import { PrResponse } from '../pr/pr.response';
import { CartResponse } from './cart.response';
import { OrderAddEditModel } from '../order/model/order-add-edit.model';
import { OrderAddEditResponse } from '../order/response/order-add-edit.response';
import { OrderRequest } from '../order/request/order.request';
import { Order } from 'src/app/core/bean/order';

@Component({
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CartComponent extends BaseModuleComponent {
  public cartViewList: CartView[];
  public prItemList: PrItem[];
  public cartIdList: number[] = [];
  public indexCheckedList: any[];
  public currencyCode: string;
  public totalItem = 0;
  public totalQuantity = 0;
  public totalAmount = 0;
  public backToUrl: string;
  public todo: string;
  public prResponse: PrResponse;
  public catalogView: CatalogView;
  public isContractExist = false;
  public orderAddEditModelMap: Map<number, OrderAddEditModel> = new Map<
    number,
    OrderAddEditModel
  >();
  public orderResponse: OrderAddEditResponse = new OrderAddEditResponse();

  constructor(
    public translationService: TranslateService,
    public cartWebsocketService: CartWebsocketService
  ) {
    super('cart', translationService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.getDataFromServer();
    this.cartWebsocketService.connect();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.prResponse = this.global.routerParams.get('prResponse');
    this.catalogView = this.global.routerParams.get('catalogView');
    this.cartIdList = this.global.routerParams.get('cartIdList');
    this.prItemList = this.global.routerParams.get('prItemList');
    this.backToUrl = this.global.routerParams.get('backToUrl');
    this.global.routerParams.delete('cartIdList');
    this.orderAddEditModelMap = this.global.routerParams.get(
      'orderAddEditModelMap'
    );
    this.orderResponse = this.global.routerParams.get('orderResponse');
  }

  public getDataFromServer(): void {
    this.httpClientService
      .get<CartResponse>('/cart/index')
      .subscribe((cartResponse: CartResponse) => {
        this.cartViewList = cartResponse.cartViewList;
        this.currencyCode = cartResponse.companyCurrency;
        let cartViewTemp = [];
        if (this.cartIdList && this.cartIdList.length > 0) {
          cartViewTemp = this.cartViewList.filter(cartView =>
            this.cartIdList.every(cartId => cartId !== cartView.id)
          );
        } else {
          cartViewTemp = cartResponse.cartViewList;
        }
        this.setFormGroup(cartViewTemp);
      });
  }

  public setFormGroup(cartViewList: CartView[]): void {
    const companyList = [];
    cartViewList.forEach((cartView: CartView) => {
      if (cartView.catalog && !companyList.includes(cartView.produsenName)) {
        companyList.push(cartView.produsenName);
        const formGroupCart = this.formBuilder.group({
          id: cartView.id,
          isChecked: [null],
          cartView,
          img: cartView.imgFile,
          name: cartView.merk,
          price: cartView.price,
          quantity: cartView.quantity,
          totalPrice: cartView.totalPrice
        });
        const formGroup = this.formBuilder.group({
          isCheckedGroup: [null],
          isIndeterminateGroup: false,
          isFromCatalog: true,
          companyName: cartView.produsenName,
          companyId: cartView.catalog.vendor.id,
          companyLocation: cartView.vendorCityRegion,
          cartList: this.formBuilder.array([formGroupCart])
        });
        this.cartGroupList.push(formGroup);
      } else if (
        !cartView.catalog &&
        !companyList.includes(this.global.config.parameterModel.companyName)
      ) {
        companyList.push(this.global.config.parameterModel.companyName);
        const formGroupCart = this.formBuilder.group({
          id: cartView.id,
          isChecked: [null],
          cartView,
          img: cartView.imgFile,
          name: cartView.merk,
          price: cartView.price,
          quantity: cartView.quantity,
          totalPrice: cartView.totalPrice
        });
        const formGroup = this.formBuilder.group({
          isCheckedGroup: [null],
          isIndeterminateGroup: false,
          isFromCatalog: false,
          companyName: this.global.config.parameterModel.companyName,
          companyLocation: this.global.config.parameterModel.companyLocation,
          cartList: this.formBuilder.array([formGroupCart])
        });
        this.cartGroupList.push(formGroup);
      } else {
        const index = cartView.catalog
          ? companyList.indexOf(cartView.produsenName)
          : companyList.indexOf(this.global.config.parameterModel.companyName);
        const cartList = this.cartGroupList
          .at(index)
          .get('cartList') as FormArray;
        const formGroupCart = this.formBuilder.group({
          id: cartView.id,
          isChecked: [null],
          cartView,
          img: cartView.imgFile,
          name: cartView.merk,
          price: cartView.price,
          quantity: cartView.quantity,
          totalPrice: cartView.totalPrice
        });
        cartList.push(formGroupCart);
      }
    });
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      isCheckedAll: [null],
      isIndeterminateAll: false,
      cartGroupList: this.formBuilder.array([])
    });
  }

  public doClickVendor(isFromCatalog: boolean, companyId: number): void {
    if (isFromCatalog) {
      this.global.routerParams.set('todo', 'view');
      this.global.routerParams.set('vendorId', companyId);
      this.router.navigate(['/pages/pr/vendor-detail']);
    }
  }

  public doClickCatalog(isFromCatalog: boolean, catalogId: number): void {
    if (isFromCatalog) {
      this.global.routerParams.set('catalogId', catalogId);
      this.router.navigate(['/pages/pr/add-item-detail']);
    }
  }

  public isCatalogContractExist(): boolean {
    const prItemContractExistList = this.prResponse?.prItemList.filter(
      prItem =>
        prItem.catalog &&
        prItem.catalog.catalogType.code ===
          this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
    );
    const prItemContractList = this.prItemList.filter(
      prItem =>
        prItem.catalog &&
        prItem.catalog.catalogType.code ===
          this.global.appConstant.vm.CATALOG_TYPE_CONTRACT
    );
    const prItemNotContractList = this.prItemList.filter(
      prItem =>
        !prItem.catalog ||
        (prItem.catalog &&
          prItem.catalog.catalogType.code ===
            this.global.appConstant.vm.CATALOG_TYPE_VENDOR)
    );
    const prItemNotContractExistList = this.prResponse?.prItemList.filter(
      prItem =>
        !prItem.catalog ||
        (prItem.catalog &&
          prItem.catalog.catalogType.code ===
            this.global.appConstant.vm.CATALOG_TYPE_VENDOR)
    );

    if (
      ((prItemContractExistList && prItemContractExistList.length > 0) ||
        (prItemContractList && prItemContractList.length > 0)) &&
      ((prItemNotContractList && prItemNotContractList.length > 0) ||
        (prItemNotContractExistList && prItemNotContractExistList.length > 0))
    ) {
      this.isContractExist = true;
      return true;
    } else {
      this.isContractExist = false;
      return false;
    }
  }

  // public doProcessToPr(): void {
  //   this.processedData();
  //   if (
  //     this.cartIdList.length !== 0 &&
  //     this.indexCheckedList.length !== 0 &&
  //     !this.isCatalogContractExist()
  //   ) {
  //     if (this.backToUrl && this.backToUrl !== '/pages/pr/add') {
  //       this.global.routerParams.set('todo', this.todo);
  //       this.global.routerParams.set('cartIdList', this.cartIdList);
  //       this.global.routerParams.set('prItemList', this.prItemList);
  //       this.prResponse.prItemList.push(...this.prItemList);
  //       this.global.routerParams.set('prResponse', this.prResponse);
  //       this.router.navigate([this.backToUrl]);
  //     } else {
  //       this.global.routerParams.clear();
  //       this.global.routerParams.set('todo', 'add');
  //       this.global.routerParams.set('cartIdList', this.cartIdList);
  //       this.global.routerParams.set('prItemList', this.prItemList);
  //       this.router.navigate(['/pages/pr/add']);
  //     }
  //   } else if (
  //     this.cartIdList.length === 0 &&
  //     this.indexCheckedList.length === 0
  //   ) {
  //     this.global.alertService.showInfo('cart.alert.chooseOne');
  //   }
  // }

  public async doProcessToPr(): Promise<void> {
    this.processedData();
    if (this.cartIdList.length !== 0 && this.indexCheckedList.length !== 0) {
      this.global.routerParams.clear();
      this.global.routerParams.set('todo', 'add');
      this.global.routerParams.set('cartIdList', this.cartIdList);
      await this.setMapOrderModel();
      // this.global.routerParams.set('orderAddEditModelMap', orderModel);
      // // this.router.navigate(['/pages/order/add']);
    } else if (
      this.cartIdList.length === 0 &&
      this.indexCheckedList.length === 0
    ) {
      this.global.alertService.showInfo('cart.alert.chooseOne');
    }
  }

  

  // public setMapOrderModel(): Map<number, OrderAddEditModel> {
  //   // Group by vendor
  //   const groupedByVendor = this.prItemList.reduce((result, prItem) => {
  //     const vendorId = prItem.catalog.vendor.id;

  //     if (!result.has(vendorId)) {
  //       result.set(vendorId, []);
  //     }

  //     result.get(vendorId).push(prItem);

  //     return result;
  //   }, new Map<number, PrItem[]>());
  //                                         // vendorId , itemType PARENTcODE, PrItem[]
  //   const groupedByVendorAndItem = new Map<number, Map<string, PrItem[]>>();
  //   groupedByVendor.forEach((prItems, vendorId) => {
  //     const innerMap = prItems.reduce((result, prItem) => {
  //       const itemTypeParentCode = prItem.item.itemType.parentCode;

  //       if (!result.has(itemTypeParentCode)) {
  //         result.set(itemTypeParentCode, []);
  //       }

  //       result.get(itemTypeParentCode).push(prItem);

  //       return result;
  //     }, new Map<string, PrItem[]>());

  //     groupedByVendorAndItem.set(vendorId, innerMap);
  //   });

  //   const orderAddEditModelMap: Map<number, OrderAddEditModel> = new Map<
  //   number,
  //   OrderAddEditModel
  // >();

  //   let indexNumber = 1;
  //   // let responses: OrderAddEditResponse = new OrderAddEditResponse();
  //   this.httpClientService
  //   .get<OrderAddEditResponse>(
  //     '/order/add-edit?id=' +
  //       ('') +
  //       '&todo=' +
  //       'add',
  //     {}
  //   ).subscribe((response: OrderAddEditResponse) => {
  //     groupedByVendorAndItem.forEach(value => {
  //       value.forEach(prItemList => {
  //         prItemList.forEach((prItem, index) => {
  //           prItem.code = '00' + (+index + +1);
  //         });

  //         const request: OrderRequest = new OrderRequest();
  //         const orderEditAddModel: OrderAddEditModel = new OrderAddEditModel();
  //         orderEditAddModel.response = response;
  //         orderEditAddModel.response.prItemList = prItemList;
  //         const order:Order = new Order();
  //         order.orderStatus = response?.order?.orderStatus;
  //         order.code = response?.order?.code;
  //         request.order = order;
  //         request.prItemList = prItemList;
  //         request.appOfficialReportModel = response.appOfficialReportModel;
  //         orderEditAddModel.orderRequest = request;
  //         orderAddEditModelMap.set(indexNumber, orderEditAddModel);
  //         indexNumber++;
  //       })
  //     })
  //   });
  //   // groupedByVendorAndItem.forEach(value => {
  //   //   value.forEach(prItemList => {
  //   //     prItemList.forEach((prItem, index) => {
  //   //       prItem.code = '00' + (+index + +1);
  //   //     });

  //   //     const request: OrderRequest = new OrderRequest();
  //   //     const orderEditAddModel: OrderAddEditModel = new OrderAddEditModel();
  //   //     orderEditAddModel.response = responses;
  //   //     orderEditAddModel.response.prItemList = prItemList;
  //   //     const order:Order = new Order();
  //   //     order.orderStatus = responses?.order?.orderStatus;
  //   //     order.code = responses?.order?.code;
  //   //     request.order = order;
  //   //     request.prItemList = prItemList;
  //   //     request.appOfficialReportModel = responses.appOfficialReportModel;
  //   //     orderEditAddModel.orderRequest = request;
  //   //     orderAddEditModelMap.set(indexNumber, orderEditAddModel);
  //   //     indexNumber++;
  //   //   })
  //   // })

  //   return orderAddEditModelMap;

  // }

  public async setMapOrderModel(): Promise<Map<number, OrderAddEditModel>> {
    return new Promise(resolve => {
      // Your asynchronous logic here
      setTimeout(() => {
        // Group by vendor
        const groupedByVendor = this.prItemList.reduce((result, prItem) => {
          const vendorId = prItem.catalog.vendor.id;

          if (!result.has(vendorId)) {
            result.set(vendorId, []);
          }

          result.get(vendorId).push(prItem);

          return result;
        }, new Map<number, PrItem[]>());
        // vendorId , itemType PARENTcODE, PrItem[]
        const groupedByVendorAndItem = new Map<number, Map<string, PrItem[]>>();
        groupedByVendor.forEach((prItems, vendorId) => {
          const innerMap = prItems.reduce((result, prItem) => {
            const itemTypeParentCode = prItem.item.itemType.code;

            if (!result.has(itemTypeParentCode)) {
              result.set(itemTypeParentCode, []);
            }

            result.get(itemTypeParentCode).push(prItem);

            return result;
          }, new Map<string, PrItem[]>());

          groupedByVendorAndItem.set(vendorId, innerMap);
        });

        const orderAddEditModelMap: Map<number, OrderAddEditModel> = new Map<
          number,
          OrderAddEditModel
        >();

        let indexNumber = 1;
        // let responses: OrderAddEditResponse = new OrderAddEditResponse();
        this.httpClientService
          .get<OrderAddEditResponse>(
            '/order/add-edit?id=' + '' + '&todo=' + 'add',
            {}
          )
          .subscribe((response: OrderAddEditResponse) => {
            groupedByVendorAndItem.forEach(value => {
              value.forEach(prItemList => {
                prItemList.forEach((prItem, index) => {
                  prItem.code = '00' + (+index + +1);
                });

                const request: OrderRequest = new OrderRequest();
                const orderEditAddModel: OrderAddEditModel =
                  new OrderAddEditModel();
                orderEditAddModel.response = response;
                orderEditAddModel.response.prItemList = prItemList;
                const order: Order = new Order();
                order.orderStatus = response?.order?.orderStatus;
                order.code = response?.order?.code;
                request.order = order;
                request.prItemList = prItemList;
                request.appOfficialReportModel =
                  response.appOfficialReportModel;
                orderEditAddModel.orderRequest = request;
                orderAddEditModelMap.set(indexNumber, orderEditAddModel);
                indexNumber++;
              });
            });
            this.global.routerParams.set('orderAddEditModelMap', orderAddEditModelMap);
            this.router.navigate(['/pages/order/add']);
          });
        resolve(orderAddEditModelMap); // Replace with the actual result
      }, 500); // Replace with the actual time-consuming operation
    });
  }

  public doDelete(): void {
    this.processedData();
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.httpClientService
            .post<Response<Cart>>('/cart/delete', this.cartIdList)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.setStateReady();
                this.indexCheckedList.reverse();
                this.indexCheckedList.forEach(indexChecked => {
                  const cartList = this.cartGroupList
                    .at(indexChecked.indexCartGroup)
                    .get('cartList') as FormArray;
                  cartList.controls.splice(indexChecked.indexCart, 1);
                  if (cartList.controls.length === 0) {
                    this.cartGroupList.controls.splice(
                      indexChecked.indexCartGroup,
                      1
                    );
                  }
                });
                this.cartIdList.forEach(() => {
                  this.cartViewList.splice(0, 1);
                });
                this.totalItem = 0;
                this.totalQuantity = 0;
                this.totalAmount = 0;
                this.formGroup.get('isIndeterminateAll').setValue(false);
                this.formGroup
                  .get('isIndeterminateAll')
                  .updateValueAndValidity();
                this.cartGroupList.controls.forEach(cartGroup => {
                  cartGroup.get('isIndeterminateGroup').setValue(false);
                  cartGroup
                    .get('isIndeterminateGroup')
                    .updateValueAndValidity();
                });
                this.global.alertService.showSuccessDelete();
              }
            });
        }
      });
  }

  public processedData(): void {
    this.cartIdList = [];
    this.indexCheckedList = [];
    this.prItemList = [];
    const cartViewList = [];
    const cartGroupList = this.formGroup.value.cartGroupList;
    cartGroupList.forEach((cartGroup, indexCartGroup) => {
      cartGroup.cartList.forEach((cart, indexCart) => {
        if (cart.isChecked) {
          const indexChecked = { indexCartGroup, indexCart };
          this.indexCheckedList.push(indexChecked);
          this.cartIdList.push(cart.id);
          cartViewList.push(cart.cartView);
        }
      });
    });

    cartViewList.forEach((cartView: CartView) => {
      const prItem: PrItem = new PrItem();
      const prItemImageList = [];
      const prItemBudgetList = [];
      prItem.catalog = cartView.catalog;
      prItem.item = cartView.item;
      prItem.price = cartView.price;
      prItem.quantity = cartView.quantity;
      prItem.specification = cartView.specification;
      prItem.totalPrice = cartView.totalPrice;
      prItem.crudOperation = this.global.appConstant.core.CRUD_OPERATION_INSERT;
      prItem.catalogContract = cartView.catalogContract;
      prItem.workflowModelPrcs = null;

      const catalogRegionDetail =
        cartView.catalogRegion.catalogRegionDetailList.find(
          catalogRegionDetail =>
            catalogRegionDetail.distributionRegionDetail.region.id ===
            cartView.region.id
        );
      prItem.catalogRegionDetail = catalogRegionDetail;
      cartView.cartImageList.forEach(cartImage => {
        const prItemImage: PrItemImage = new PrItemImage();
        prItemImage.file = cartImage.file;
        prItemImageList.push(prItemImage);
      });
      cartView.cartItemBudgetList.forEach(cartItemBudget => {
        const prItemBudget: PrItemBudget = new PrItemBudget();
        prItemBudget.bookedAmount = cartItemBudget.amount;
        prItemBudget.budgetAllocation = cartItemBudget.budgetAllocation;
        prItemBudgetList.push(prItemBudget);
      });
      prItem.prItemImageList = prItemImageList;
      prItem.prItemBudgetList = prItemBudgetList;
      this.prItemList.push(prItem);
    });
  }

  public doChecked(
    isChecked: boolean,
    indexGroup: number,
    indexData: number
  ): void {
    let totalIemTemp = 0;
    let totalQuantityTemp = 0;
    let totalAmountTemp = 0;
    if (indexData || indexData === 0) {
      const cartList = this.cartGroupList
        .at(indexGroup)
        .get('cartList') as FormArray;
      const cartView: CartView = cartList.at(indexData).get('cartView').value;
      totalIemTemp += 1;
      totalQuantityTemp += cartView.quantity;
      totalAmountTemp += cartView.totalPrice;
    } else if (indexGroup || indexGroup === 0) {
      let totalCheckedItem = 0;
      let totalCheckedQuantity = 0;
      let totalCheckedAmount = 0;
      const cartList = this.cartGroupList
        .at(indexGroup)
        .get('cartList') as FormArray;
      cartList.value.forEach(data => {
        totalIemTemp += 1;
        totalQuantityTemp += data.cartView.quantity;
        totalAmountTemp += data.cartView.totalPrice;
        if (data.isChecked) {
          totalCheckedItem += 1;
          totalCheckedQuantity += data.cartView.quantity;
          totalCheckedAmount += data.cartView.totalPrice;
        }
      });
      if (isChecked) {
        this.totalItem -= totalCheckedItem;
        this.totalQuantity -= totalCheckedQuantity;
        this.totalAmount -= totalCheckedAmount;
      }
    } else {
      this.cartGroupList.value.forEach(cartGroup => {
        cartGroup.cartList.forEach(data => {
          totalIemTemp += 1;
          totalQuantityTemp += data.cartView.quantity;
          totalAmountTemp += data.cartView.totalPrice;
        });
      });
      if (isChecked) {
        this.totalItem = 0;
        this.totalQuantity = 0;
        this.totalAmount = 0;
      }
    }
    if (isChecked) {
      this.totalItem += totalIemTemp;
      this.totalQuantity += totalQuantityTemp;
      this.totalAmount += totalAmountTemp;
    } else {
      this.totalItem -= totalIemTemp;
      this.totalQuantity -= totalQuantityTemp;
      this.totalAmount -= totalAmountTemp;
    }
    this.checkIsChecked(isChecked, indexGroup, indexData);
  }

  public checkIsChecked(
    isChecked: boolean,
    indexGroup: number,
    indexData: number
  ): void {
    if (indexData || indexData === 0) {
      let isAllGroupChecked = true;
      const listCheckedCart = [];
      let totalCart = 0;
      this.cartGroupList.controls.forEach(cartGroup => {
        const cartList = cartGroup.get('cartList') as FormArray;
        let isAllCartChecked = true;
        let isAnyCartChecked = false;
        cartList.controls.forEach(cart => {
          totalCart += 1;
          if (cart.get('isChecked').value) {
            listCheckedCart.push(cart);
            isAnyCartChecked = true;
          } else {
            isAllCartChecked = false;
          }
        });
        cartGroup
          .get('isCheckedGroup')
          .setValue(isAllCartChecked ? true : false);
        cartGroup.get('isCheckedGroup').updateValueAndValidity();
        if (!cartGroup.get('isCheckedGroup').value) {
          isAllGroupChecked = false;
        }
        cartGroup
          .get('isIndeterminateGroup')
          .setValue(isAnyCartChecked && !isAllCartChecked ? true : false);
        cartGroup.get('isIndeterminateGroup').updateValueAndValidity();
      });
      this.formGroup
        .get('isCheckedAll')
        .setValue(isAllGroupChecked ? true : false);
      this.formGroup.get('isCheckedAll').updateValueAndValidity();
      this.formGroup
        .get('isIndeterminateAll')
        .setValue(
          listCheckedCart.length > 0 && listCheckedCart.length !== totalCart
            ? true
            : false
        );
      this.formGroup.get('isIndeterminateAll').updateValueAndValidity();
    } else if (indexGroup || indexGroup === 0) {
      let isAllGroupChecked = true;
      let isAnyGroupChecked = false;
      const cartList = this.cartGroupList
        .at(indexGroup)
        .get('cartList') as FormArray;
      cartList.controls.forEach(cart => {
        cart.get('isChecked').setValue(isChecked ? true : false);
        cart.get('isChecked').updateValueAndValidity();
      });
      this.cartGroupList.controls.forEach(cartGroup => {
        if (cartGroup.get('isCheckedGroup').value) {
          isAnyGroupChecked = true;
        } else {
          isAllGroupChecked = false;
        }
      });
      this.formGroup
        .get('isCheckedAll')
        .setValue(isAllGroupChecked ? true : null);
      this.formGroup.get('isCheckedAll').updateValueAndValidity();
      this.formGroup
        .get('isIndeterminateAll')
        .setValue(isAnyGroupChecked && !isAllGroupChecked ? true : false);
      this.formGroup.get('isIndeterminateAll').updateValueAndValidity();
    } else {
      this.cartGroupList.controls.forEach(cartGroup => {
        cartGroup.get('isCheckedGroup').setValue(isChecked ? true : false);
        cartGroup.get('isCheckedGroup').updateValueAndValidity();
        const cartList = cartGroup.get('cartList') as FormArray;
        cartList.controls.forEach(cart => {
          cart.get('isChecked').setValue(isChecked ? true : false);
          cart.get('isChecked').updateValueAndValidity();
        });

        cartGroup
          .get('isIndeterminateGroup')
          .setValue(isChecked ? true : false);
        cartGroup.get('isIndeterminateGroup').updateValueAndValidity();
      });
    }
  }

  public get cartGroupList(): FormArray {
    return this.formGroup.get('cartGroupList') as FormArray;
  }
}
