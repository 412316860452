import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { CatalogAttributeEditAddComponent } from './catalog-attribute-edit-add.component';
import { CatalogAttributeComponent } from './catalog-attribute.component';
export const routes = [
  { path: '', component: CatalogAttributeComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: CatalogAttributeEditAddComponent,
    data: { breadcrumb: 'catalog-attribute.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: CatalogAttributeEditAddComponent,
    data: { breadcrumb: 'catalog-attribute.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [CatalogAttributeComponent, CatalogAttributeEditAddComponent]
})
export class CatalogAttributeModule {}
