<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <form class="form-horizontal" [formGroup]="formGroup">
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'catalog-verification.form.vendorName' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ formGroup.get('vendorName').value }}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'catalog-verification.form.name' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ formGroup.get('name').value }}
        </div>
      </div>

      <!-- <div
        class="form-group row text-sm-right"
        [ngClass]="
          catalog.catalogType.code ===
          global.appConstant.vm.CATALOG_TYPE_CONTRACT
            ? ''
            : 'required'
        "
      >
        <label class="col-sm-3 control-label">{{
          'catalog-verification.form.itemReference' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-auto-complete
            formControlName="item"
            stringUrl="/catalog-verification/auto-complete-item-list"
          >
          </app-auto-complete>
        </div>
      </div> -->

      <div class="form-group row text-sm-right required">
        <label class="col-sm-3 control-label">{{
          'catalog-verification.form.status' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            [optionList]="statusOptionList"
            formControlName="status"
          >
          </app-combo-box>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'catalog-verification.form.note' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-area formControlName="note" maxLength="512"></app-text-area>
        </div>
      </div>

      <br />
      <h5 class="separator">{{
        'catalog-verification.form.historyActivity' | translate
      }}</h5>
      <app-table [model]="tableResponse" [isServerSide]="false"></app-table>
    </form>
    <div class="button-group button-group-center mt-5">
      <app-button
        color="SECONDARY"
        (onClick)="doPrevious()"
        [disabled]="formSaving"
        >{{ 'app.button.previous' | translate }}
      </app-button>
      <app-button (onClick)="doSave()" [disabled]="formSaving">{{
        'app.button.save' | translate
      }}</app-button>
    </div>
  </div>
</div>
