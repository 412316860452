import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../vendor/vendor.module';
import { AppToggleSwitchModule } from '../../../app-toggle-switch/app-toggle-switch.module';
import { TableToggleSwitchPluginComponent } from './toggle-switch-plugin.component';

@NgModule({
  imports: [VendorModule, AppToggleSwitchModule],
  declarations: [TableToggleSwitchPluginComponent],
  exports: [TableToggleSwitchPluginComponent]
})
export class TableToggleSwitchPluginModule {}
