import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from './../../core/shared/shared.module';
import { TaxEditAddComponent } from './tax-edit-add.component';
import { TaxComponent } from './tax.component';
export const routes = [
  { path: '', component: TaxComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: TaxEditAddComponent,
    data: { breadcrumb: 'tax.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: TaxEditAddComponent,
    data: { breadcrumb: 'tax.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, NgbModule],
  declarations: [TaxComponent, TaxEditAddComponent]
})
export class TaxModule {}
