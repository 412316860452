import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Procurement } from '../../core/bean/procurement';
import { AppPopupAnalysisSingleService } from '../../core/components/app-popup/app-popup-analysis-single/app-popup-analysis-single.service';
import {
  TableHeaderPluginData,
  TablePluginData
} from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { VendorQuotationView } from '../../core/view/entity/bean/vendor-quotation-view';
import { VendorDesignationPopupRequest } from './vendor-designation-popup-request';

@Component({
  templateUrl: './vendor-designation-popup-change-winner.component.html'
})
export class VendorDesignationPopupChangeWinnerComponent extends BaseComponentComponent {
  @Input() procurement: Procurement;
  @Input() technicalPercentage: number;
  @Input() pricePercentage: number;
  @Input() vendorWinner: VendorQuotationView[];
  @Output() onChange: EventEmitter<VendorQuotationView[]> = new EventEmitter();
  public tableResponse: TableResponseModel<VendorQuotationView>;
  public isThingsToConsider = false;
  public CURRENCY_DIGITS_INFO: string;
  public isMustFullFill = false;

  constructor(
    public currencyPipe: CurrencyPipe,
    public translateService: TranslateService,
    public appPopupAnalysisSingleService: AppPopupAnalysisSingleService
  ) {
    super('vendor-designation');
  }

  onInit(): void {
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
    this.buildTableResponse();
    this.getListVendorForTable();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      this.procurement.biddingEvaluation.code !==
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
        ? [
            {
              field: 'vendorName',
              header: 'table.column.vendorName',
              plugins: [
                {
                  name: 'default'
                },
                {
                  name: 'icon',
                  className: 'pic pi-exclamation-triangle',
                  onClick: this.onClickIconConsider.bind(this),
                  isShowIconFn: this.showIcon.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pis pi-exclamation-triangle text-danger',
                  onClick: this.onClickIconDanger.bind(this),
                  isShowIconFn: this.showIconDanger.bind(this)
                }
              ]
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScoreStatus.name',
                  header: 'table.column.technical',
                  plugins: {
                    name: 'badge',
                    colorField: 'technicalScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                this.procurement.biddingEvaluation.code ===
                this.global.appConstant.pm
                  .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
                  ? {
                      field: 'finalPriceScoreStatus.name',
                      header: 'table.column.price',
                      plugins: {
                        name: 'badge',
                        colorField: 'finalPriceScoreStatus.code',
                        colorMap: {
                          PASSED: 'SUCCESS',
                          NOT_PASSED: 'DANGER'
                        }
                      }
                    }
                  : {
                      field: 'finalPriceScore',
                      header: 'table.column.price',
                      className: 'text-right',
                      plugins: {
                        name: 'text-field',
                        type: 'decimal',
                        isView: true
                      }
                    }
              ]
            },
            {
              field: 'finalPrice',
              header: 'table.column.finalPrice',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: 'text-right'
            },
            {
              field: 'finalProfitLossAmount',
              header: 'table.column.savingLoss',
              className: 'text-right',
              plugins: [
                {
                  name: 'custom-plugin',
                  before: (tablePlugin: TablePluginData): void => {
                    if (tablePlugin.value) {
                      tablePlugin.element.innerText =
                        this.currencyPipe.transform(
                          +tablePlugin.value,
                          '',
                          '',
                          this.CURRENCY_DIGITS_INFO
                        ) + ' %';
                    }
                  }
                },
                {
                  name: 'badge',
                  field: 'finalProfitLossStatus.name',
                  colorMap: {
                    SAVE: 'SUCCESS',
                    LOSS: 'DANGER'
                  },
                  pill: false,
                  colorField: 'finalProfitLossStatus.code',
                  className: 'badge-catalog ml-3'
                }
              ]
            },
            {
              field: 'vendorName',
              header: 'table.column.resultAnalysis',
              plugins: [
                {
                  name: 'hyperlink',
                  onClick: this.doSeeDetailsAnalysisSingle.bind(this),
                  customText: this.translateService.instant(
                    'vendor-designation.table.value.seeDetails'
                  )
                }
              ]
            }
          ]
        : [
            {
              field: 'vendorName',
              header: 'table.column.vendorName',
              plugins: [
                {
                  name: 'default'
                },
                {
                  name: 'icon',
                  className: 'pic pi-exclamation-triangle',
                  onClick: this.onClickIconConsider.bind(this),
                  isShowIconFn: this.showIcon.bind(this)
                },
                {
                  name: 'icon',
                  className: 'pis pi-exclamation-triangle text-danger',
                  onClick: this.onClickIconDanger.bind(this),
                  isShowIconFn: this.showIconDanger.bind(this)
                }
              ]
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScore',
                  header: {
                    key: 'table.column.technical',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' + (this.technicalPercentage || ' - ') + '%)';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                },
                {
                  field: 'finalPriceScore',
                  header: {
                    key: 'table.column.price',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' + (this.pricePercentage || ' - ') + '%)';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                },
                {
                  field: 'totalFinalEvaluationScore',
                  header: 'table.column.total',
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                }
              ]
            },
            {
              field: 'finalPrice',
              header: 'table.column.finalPrice',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: 'text-right'
            },
            {
              field: 'finalProfitLossAmount',
              header: 'table.column.savingLoss',
              className: 'text-right',
              plugins: [
                {
                  name: 'custom-plugin',
                  before: (tablePlugin: TablePluginData): void => {
                    if (tablePlugin.value) {
                      tablePlugin.element.innerText =
                        this.currencyPipe.transform(
                          +tablePlugin.value,
                          '',
                          '',
                          this.CURRENCY_DIGITS_INFO
                        ) + ' %';
                    }
                  }
                },
                {
                  name: 'badge',
                  field: 'finalProfitLossStatus.name',
                  colorMap: {
                    SAVE: 'SUCCESS',
                    LOSS: 'DANGER'
                  },
                  pill: false,
                  colorField: 'finalProfitLossStatus.code',
                  className: 'badge-catalog ml-3'
                }
              ]
            },
            {
              field: 'vendorName',
              header: 'table.column.resultAnalysis',
              plugins: [
                {
                  name: 'hyperlink',
                  onClick: this.doSeeDetailsAnalysisSingle.bind(this),
                  customText: this.translateService.instant(
                    'vendor-designation.table.value.seeDetails'
                  )
                }
              ]
            }
          ],
      { checkBoxFn: this.isShowCheckBox.bind(this) }
    );
  }

  public isShowCheckBox(vendorView): boolean {
    if (
      vendorView.isExpiredDoc ||
      vendorView.isExpiredCertificate ||
      (vendorView.procurementVendor.vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
        vendorView.procurementVendor.vendor.vendorStatus.code !==
          this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) ||
      vendorView.isCatalogContract
    ) {
      this.isMustFullFill = true;
      return false;
    } else {
      return true;
    }
  }

  public doSeeDetailsAnalysisSingle(
    vendorQuotationView: VendorQuotationView
  ): void {
    this.appPopupAnalysisSingleService.open(
      vendorQuotationView.procurementVendor.id,
      this.moduleCode
    );
  }

  public getListVendorForTable(): void {
    this.httpClientService
      .get<VendorQuotationView>(
        '/vendor-designation/index-popup-single-winner/' + this.procurement.id
      )
      .subscribe((response: VendorQuotationView[]) => {
        const selectedVendor = response.filter(
          vendorQuotationView =>
            vendorQuotationView.procurementVendor.id ===
            this.vendorWinner[0].procurementVendor.id
        );
        this.tableResponse.setRecordList(response);
        this.tableResponse.setSelectedRowList(selectedVendor);
        this.tableResponse.reload();
        this.setStateReady();
      });
  }

  public onClickIconConsider(vendorView: VendorQuotationView): void {
    if (
      !(
        vendorView.isExpiredDoc ||
        vendorView.isExpiredCertificate ||
        (vendorView.procurementVendor.vendor.vendorStatus.code !==
          this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
          vendorView.procurementVendor.vendor.vendorStatus.code !==
            this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) ||
        vendorView.isCatalogContract
      )
    ) {
      let msg = '<ol class="text-left ml-4">';
      if (vendorView.sameCompanyOrgVendorName) {
        let vendorNameList: string[] =
          vendorView.sameCompanyOrgVendorName.split(',');
        vendorNameList = [...new Set(vendorNameList)];

        msg +=
          '<li>' +
          vendorView.vendorName +
          ' ' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.sameCompanyOrg'
          ) +
          ' ' +
          vendorNameList.toString().replace('[', '').replace(']', '') +
          '</li>';
      }
      if (!vendorView.isFinancialReport) {
        msg +=
          '<li>' +
          vendorView.vendorName +
          ' ' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.isFinancialReport'
          ) +
          '</li>';
      }
      if (vendorView.isBlacklist) {
        msg +=
          '<li>' +
          vendorView.vendorName +
          ' ' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.isBlacklist'
          ) +
          '</li>';
      }
      if (vendorView.isCertificateWillBeExpired) {
        msg +=
          '<li>' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.certificate'
          ) +
          ' ' +
          vendorView.vendorName +
          ' ' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.isCertificateWillBeExpired'
          ) +
          '</li>';
      }

      if (vendorView.isDer) {
        msg +=
          '<li>' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.derRatio'
          ) +
          ' ' +
          vendorView.vendorName +
          ' ' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.isDer'
          ) +
          '</li>';
      }

      if (vendorView.isCatalogContract) {
        msg +=
          '<li>' +
          vendorView.vendorName +
          ' ' +
          this.global.translateService.instant(
            'vendor-designation.popup.warning.msg.isCatalogContract'
          ) +
          ' ' +
          vendorView.catalogName +
          '</li>';
      }
      const title = 'vendor-designation.popup.warning.thingsToConsider.title';
      this.doShowPopup(msg, title);
    }
  }

  public showIcon(vendor: VendorQuotationView): boolean {
    if (
      !(
        vendor.isExpiredDoc ||
        vendor.isExpiredCertificate ||
        (vendor.procurementVendor.vendor.vendorStatus.code !==
          this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
          vendor.procurementVendor.vendor.vendorStatus.code !==
            this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) ||
        vendor.isCatalogContract
      ) &&
      (vendor.sameCompanyOrgVendorName ||
        !vendor.isFinancialReport ||
        vendor.isBlacklist ||
        vendor.isCertificateWillBeExpired ||
        vendor.isDer)
    ) {
      this.isThingsToConsider = true;
      return true;
    } else {
      return false;
    }
  }

  public showIconDanger(vendorView: VendorQuotationView): boolean {
    if (
      vendorView.isExpiredDoc ||
      vendorView.isExpiredCertificate ||
      (vendorView.procurementVendor.vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
        vendorView.procurementVendor.vendor.vendorStatus.code !==
          this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) ||
      vendorView.isCatalogContract
    ) {
      this.isMustFullFill = true;
      return true;
    } else {
      return false;
    }
  }

  public onClickIconDanger(vendorView: VendorQuotationView): void {
    let msg = '';
    if (
      vendorView.procurementVendor.vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
      vendorView.procurementVendor.vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST
    ) {
      msg +=
        '<li>' +
        vendorView.vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-designation.popup.warning.msg.isNotActive'
        ) +
        '</li>';
    }

    if (vendorView.isExpiredCertificate) {
      msg +=
        '<li>' +
        this.global.translateService.instant(
          'vendor-designation.popup.warning.msg.certificate'
        ) +
        ' ' +
        vendorView.vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-designation.popup.warning.msg.isExpiredCertificate'
        ) +
        '</li>';
    }

    if (vendorView.isExpiredDoc) {
      msg +=
        '<li>' +
        vendorView.vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-designation.popup.warning.msg.isExpiredDoc'
        ) +
        '</li>';
    }

    if (vendorView.isCatalogContract) {
      msg +=
        '<li>' +
        vendorView.vendorName +
        ' ' +
        this.global.translateService.instant(
          'vendor-designation.popup.warning.msg.isCatalogContract'
        ) +
        ' ' +
        vendorView.catalogName +
        '</li>';
    }

    const title = 'vendor-designation.popup.warning.mustFullFill.title';
    this.doShowPopup(msg, title);
  }

  public doShowPopup(msg: string, title: string): void {
    this.global.modalService.confirmation(
      msg,
      title,
      null,
      null,
      'pvc pv-exclamation-triangle',
      true
    );
  }

  public doSave(): void {
    console.log(JSON.stringify(this.vendorWinner[0]));
    console.log(
      JSON.stringify(
        this.tableResponse
          .getRecordList()
          .filter(record => record.id === this.vendorWinner[0].id)
      )
    );
    console.log(
      JSON.stringify(this.vendorWinner[0]) ===
        JSON.stringify(
          this.tableResponse
            .getRecordList()
            .filter(record => record.id === this.vendorWinner[0].id)
        )
    );
    if (this.tableResponse.selectedRow.getSelectedRecordList()?.length > 0) {
      const winner = this.tableResponse.selectedRow.getSelectedRecordList()[0];
      if (
        winner.procurementVendor.id !==
        this.vendorWinner[0].procurementVendor.id
      ) {
        this.setStateProcessing();
        const vendorDesignationPopupRequest: VendorDesignationPopupRequest =
          new VendorDesignationPopupRequest();
        vendorDesignationPopupRequest.vendorQuotationNonWinner =
          this.vendorWinner[0];
        vendorDesignationPopupRequest.vendorQuotationWinner =
          this.tableResponse.selectedRow.getSelectedRecordList()[0];
        this.httpClientService
          .post(
            '/vendor-designation/save-single-winner',
            vendorDesignationPopupRequest
          )
          .subscribe(response => {
            if (response.status === ResponseStatusModel.OK) {
              this.onChange.emit(
                this.tableResponse.selectedRow.getSelectedRecordList()
              );
              this.setStateReady();
            }
          });
      } else {
        this.onChange.emit(
          this.tableResponse.selectedRow.getSelectedRecordList()
        );
        this.setStateReady();
      }
    }
  }
}
