import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../base/angular/base-module.component';
import { Organization } from '../../bean/organization';
import { OrganizationPlanInfoModel } from './model/organization-plan-info-model';
@Component({
  templateUrl: './app-organization-rk-info-popup.component.html',
  styleUrls: ['./app-organization-rk-info-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppOrganizationRkInfoPopupComponent extends BaseModuleComponent {
  @Input() organizationPlanInfoModelList: OrganizationPlanInfoModel[];
  @Output() onChange: EventEmitter<Organization> = new EventEmitter();

  constructor(translateService: TranslateService) {
    super('', translateService);
  }

  onInit(): void {}
}
