import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { InitiationType } from 'src/app/core/bean/initiation-type';
import { Organization } from 'src/app/core/bean/organization';
import { Procurement } from 'src/app/core/bean/procurement';
import { ProcurementStatus } from 'src/app/core/bean/procurement-status';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { SelectedTableRecordModel } from 'src/app/core/components/table/model/selected-table-record-model';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { InitiationPopupSelectInitiationTypeComponent } from './initiation-popup-select-initiation-type.component';
import { InitiationOptionListResponse } from './response/initiation-option-list.response';

@Component({
  templateUrl: './initiation.component.html'
})
export class InitiationComponent extends BaseModuleComponent implements OnInit {
  public tableResponse: TableResponseModel<Procurement>;
  public initiationTypeList: InitiationType[] = [];
  public procurementStatusOptionList: OptionListModel<ProcurementStatus> =
    new OptionListModel(true);
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('initiation', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.buildSetFormGroup();
  }

  public doBuildTableResponse(): void {
    const RE_HELD: string = this.translateService.instant(
      'dynamic-master-attribute.procurementFlag.reHeld'
    );
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'code',
          header: 'table.column.procurementNumber',
          plugins: {
            name: 'hyperlink',
            className: 'white-space-normal',
            onClick: this.doClick.bind(this)
          }
        },
        {
          field: 'procurementDate',
          header: 'table.column.procurementDate',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'name',
          header: 'table.column.procurementName',
          plugins: [
            {
              name: 'default'
            },
            {
              name: 'badge',
              field: 'isRepeat',
              pill: false,
              className: 'badge-catalog badge-danger ml-3',
              colorMap: {
                RE_HELD: 'DANGER'
              },
              callbacks: {
                text: (tablePluginData: TablePluginData): string => {
                  const procurement = tablePluginData.row.record as Procurement;
                  if (procurement.isRepeat) {
                    return RE_HELD;
                  }
                }
              }
            },
            {
              name: 'custom-plugin',
              after: (tablePluginData: TablePluginData): void => {
                const procurement = tablePluginData.row.record as Procurement;
                if (!procurement.isRepeat) {
                  tablePluginData.element.children[1].remove();
                }
              }
            }
          ]
        },
        {
          field: 'organization.name',
          header: 'table.column.department'
        },
        {
          field: 'procurementMethod.name',
          header: 'table.column.procurementMethod'
        },
        {
          field: 'initiationType.name',
          header: 'table.column.initiationType'
        },
        {
          field: 'workflowModelPrcs.name',
          header: 'table.column.stages'
        },
        {
          field: 'procurementStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              DRAFT: 'SECONDARY',
              REVISION: 'FEEDBACK',
              WAITING_APPROVAL: 'WARNING',
              ON_PROGRESS: 'PROGRESS',
              DONE: 'SUCCESS',
              FAILED: 'DANGER',
              FAILED_ASSESSMENT: 'SECONDARY',
              WAITING_CANCEL_APPROVAL: 'WARNING',
              CANCELED: 'DANGER',
              WAITING_FAILED_APPROVAL: 'WARNING'
            },
            colorField: 'procurementStatus.code'
          }
        }
      ],
      { checkBoxFn: this.isShowCheckBox.bind(this) }
    );
  }

  public isShowCheckBox(record: Procurement): boolean {
    let isStatusDraft = false;
    if (
      record.procurementStatus?.code !==
      this.global.appConstant.pm.PROCUREMENT_STATUS_DRAFT
    ) {
      isStatusDraft = false;
    } else {
      isStatusDraft = true;
    }
    return isStatusDraft;
  }

  public buildSetFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      organizationList: [null],
      statusList: [null]
    });

    this.httpClientService
      .get<InitiationOptionListResponse>('/initiation/get-option-list', {})
      .subscribe((response: InitiationOptionListResponse) => {
        this.procurementStatusOptionList.setRequestValues(
          response.procurementStatusList
        );
        this.organizationOptionList.setRequestValues(response.organizationList);
        this.initiationTypeList = response.initiationTypeList;
        this.setStateReady();
      });
  }

  public onChangeFilter(event: any): void {
    this.log.debug(event);
    const customData = this.formGroup.value;
    customData.dateFrom = this.formGroup.value.date?.from || null;
    customData.dateTo = this.formGroup.value.date?.to || null;

    this.tableResponse.setCustomData(customData);
    this.tableResponse.reload();
  }

  public doClick(procurement: Procurement): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('procurementId', procurement.id);
    this.global.routerParams.set(
      'initiationType',
      procurement.initiationType.code
    );
    this.global.routerParams.set('urlBackOutside', '/pages/initiation');

    if (
      ![
        this.global.appConstant.pm.PROCUREMENT_STATUS_DRAFT,
        this.global.appConstant.pm.PROCUREMENT_STATUS_REVISION
      ].includes(procurement.procurementStatus.code)
    ) {
      this.global.routerParams.set('todo', 'view');
      this.router.navigate(['/pages/initiation/detail']);
    } else {
      this.global.routerParams.set('todo', 'edit');
      this.router.navigate(['/pages/initiation/edit']);
    }
  }

  public doAdd(): void {
    const initiationTypeList = this.initiationTypeList;
    this.appPopupService
      .open(InitiationPopupSelectInitiationTypeComponent, {
        initiationTypeList
      })
      .subscribe(data => {
        this.global.routerParams.clear();
        this.global.routerParams.set('todo', 'add');
        this.global.routerParams.set(
          'initiationType',
          data.initiationTypeList.code
        );
        this.router.navigate(['/pages/initiation/add']);
        this.global.routerParams.set('urlBackOutside', '/pages/initiation');
      });
  }

  public doDelete(event: SelectedTableRecordModel): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<Procurement[]>>(
              '/initiation/delete',
              event.selectedRecordList
            )
            .subscribe((response: Response<Procurement[]>) => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }
}
