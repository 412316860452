<app-layout [isLoading]="formLoading" [backTo]="urlBackOutside">
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge
      class="cml-2"
      *ngIf="
        (claimStatus?.code === global.appConstant.pm.CLAIM_STATUS_NEW) === 'new'
      "
      color="INFO"
    >
      {{ 'claim-verification.label.new' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        claimStatus?.code ===
        global.appConstant.pm.CLAIM_STATUS_WAITING_VERIFICATION
      "
      color="WARNING"
    >
      {{
        'dynamic-master-attribute.' + claimStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="claimStatus?.code === global.appConstant.pm.CLAIM_STATUS_REVISION"
      color="FEEDBACK"
    >
      {{
        'dynamic-master-attribute.' + claimStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        claimStatus?.code === global.appConstant.pm.CLAIM_STATUS_RESEND_FILE
      "
      color="DANGER"
    >
      {{
        'dynamic-master-attribute.' + claimStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        claimStatus?.code ===
        global.appConstant.pm.CLAIM_STATUS_RESEND_INFORMATION
      "
      color="DANGER"
    >
      {{
        'dynamic-master-attribute.' + claimStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="claimStatus?.code === global.appConstant.pm.CLAIM_STATUS_DONE"
      color="SUCCESS"
    >
      {{
        'dynamic-master-attribute.' + claimStatus.translationKey.key | translate
      }}
    </app-badge>
  </ng-template>

  <div class="row main-row" *ngIf="!formLoading">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div>
          <app-alert-x color="WARNING" *ngIf="!isUserAvailable">{{
            'claim-verification.alert.isUserAvailable' | translate
          }}</app-alert-x>
        </div>

        <app-card
          header="claim-verification.detail.general.title"
          tips="claim-verification.detail.general.tips"
        >
          <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim-verification.form.hospitalCode' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.hospitalCode || '-' }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim-verification.form.hospitalName' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.hospitalName || '-' }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim-verification.form.glCode' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.glNumber || '-' }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim-verification.form.accidentVictim' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.accidentVictim || '-' }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim-verification.form.verificator' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.verificator || '-' }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim-verification.form.counter' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.counter || '-' }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim-verification.form.glStatus' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.glStatus || '-' }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim-verification.form.description' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ formGroup.value.description || '-' }}
            </div>
          </div>
        </app-card>
        <app-card
          header="claim-verification.detail.requestAmount.title"
          tips="claim-verification.detail.requestAmount.tips"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim-verification.form.totalRequest' | translate
            }}</label>
            <div
              class="col-sm-3"
              [ngClass]="{
                'text-right': formGroup.value.totalRequest !== null,
                'text-left': formGroup.value.totalRequest === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              {{
                formGroup.value.totalRequest ||
                formGroup.value.totalRequest === 0
                  ? global.converterService.convertMoney(
                      formGroup.value.totalRequest || 0
                    ) + ' IDR'
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim-verification.form.doctor' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.doctor !== null,
                'text-left': formGroup.value.doctor === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              {{
                formGroup.value.doctor || formGroup.value.doctor === 0
                  ? global.converterService.convertMoney(
                      formGroup.value.doctor || 0
                    ) + ' IDR'
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim-verification.form.medicine' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.medicine !== null,
                'text-left': formGroup.value.medicine === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              {{
                formGroup.value.medicine || formGroup.value.medicine === 0
                  ? global.converterService.convertMoney(
                      formGroup.value.medicine || 0
                    ) + ' IDR'
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim-verification.form.medicalTools' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.medicalTools !== null,
                'text-left': formGroup.value.medicalTools === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              {{
                formGroup.value.medicalTools ||
                formGroup.value.medicalTools === 0
                  ? global.converterService.convertMoney(
                      formGroup.value.medicalTools || 0
                    ) + ' IDR'
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim-verification.form.administration' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.administration !== null,
                'text-left': formGroup.value.administration === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              {{
                formGroup.value.administration ||
                formGroup.value.administration === 0
                  ? global.converterService.convertMoney(
                      formGroup.value.administration || 0
                    ) + ' IDR'
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim.form.room' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.room !== null,
                'text-left': formGroup.value.room === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              {{
                formGroup.value.room || formGroup.value.room === 0
                  ? global.converterService.convertMoney(
                      formGroup.value.room || 0
                    ) + ' IDR'
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3" style="padding-left: 55px">{{
              'claim-verification.form.roomType' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.roomType !== null,
                'text-left': formGroup.value.roomType === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              {{ formGroup.value.roomType || '-' }}
            </div>
          </div>
          <br />
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim-verification.form.totalP3k' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.totalP3k !== null,
                'text-left': formGroup.value.totalP3k === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              {{
                formGroup.value.totalP3k || formGroup.value.totalP3k === 0
                  ? global.converterService.convertMoney(
                      formGroup.value.totalP3k || 0
                    ) + ' IDR'
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim-verification.form.totalAmbulance' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.totalAmbulance !== null,
                'text-left': formGroup.value.totalAmbulance === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              {{
                formGroup.value.totalAmbulance ||
                formGroup.value.totalAmbulance === 0
                  ? global.converterService.convertMoney(
                      formGroup.value.totalAmbulance || 0
                    ) + ' IDR'
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'claim-verification.form.totalCompensationRequest' | translate
            }}</label>
            <div
              [ngClass]="{
                'text-right': formGroup.value.totalCompensationRequest !== null,
                'text-left': formGroup.value.totalCompensationRequest === null
              }"
              class="col-sm-3"
              style="padding-right: 100px"
            >
              <ng-container
                *ngIf="
                  formGroup.value.totalCompensationRequest ||
                    formGroup.value.totalCompensationRequest === 0;
                  else defaultText
                "
              >
                <span style="font-weight: bold">
                  {{
                    global.converterService.convertMoney(
                      formGroup.value.totalCompensationRequest || 0
                    ) + ' IDR'
                  }}
                </span>
              </ng-container>
              <ng-template #defaultText>
                <span>-</span>
              </ng-template>
            </div>
          </div>
        </app-card>
        <app-card
          header="claim-verification.detail.document.title"
          tips="claim-verification.detail.document.tips"
        >
          <div class="table-responsive custom-table">
            <table
              class="table table-striped"
              [formGroup]="formGroup"
              aria-describedby="Table"
            >
              <thead> </thead>
              <tbody formArrayName="docClaimList">
                <tr
                  *ngFor="
                    let docClaim of formArrayDocClaimList.controls;
                    let i = index
                  "
                  [formGroup]="docClaim"
                >
                  <td>
                    {{
                      'claim.' + docClaim.value.translationKey?.key | translate
                    }}
                  </td>
                  <td>
                    <app-upload
                      formControlName="file"
                      [fileUploader]="fileUploaderList[i]"
                      size="XL"
                    >
                    </app-upload>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div class="col-sm-6 control-label text-left">
            <label>
              {{ 'claim.docOther' | translate }}
            </label>
          </div>
          <app-upload-x
            formControlName="fileObjectList"
            [fileUploader]="fileUploader"
          ></app-upload-x>
        </app-card>

        <app-card
          header="claim-verification.detail.approval.log.title"
          tips="claim-verification.detail.approval.log.tips"
        >
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'claim-verification.form.claimStatus' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box
                [optionList]="statusOptionList"
                formControlName="claimStatus"
              >
              </app-combo-box>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'claim-verification.form.note' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-area
                formControlName="note"
                maxLength="1024"
              ></app-text-area>
            </div>
          </div>
        </app-card>

        <app-card
          header="claim-verification.detail.history.activity.title"
          tips="claim-verification.detail.history.activity.tips"
        >
          <app-table [model]="tableResponse" [isServerSide]="false"></app-table>
        </app-card>

        <div class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center">
                <app-button
                  color="SECONDARY"
                  (onClick)="doBack()"
                  [disabled]="formSaving"
                  >{{ 'app.button.back' | translate }}
                </app-button>
                <app-button (onClick)="doSubmit()" *ngIf="todo !== 'view'"
                  >{{ 'app.button.submit' | translate }}
                </app-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <ng-template
    #contentSidebarRight
    *ngIf="!claimVerificationResponse?.isUserApproval"
  >
    <app-tips></app-tips>
  </ng-template>
</app-layout>
