import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Tax } from '../../core/bean/tax';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { AppTableComponent } from './../../core/components/app-table/app-table.component';
import { Response } from './../../core/model/response-model';
import { ResponseStatusModel } from './../../core/model/response-status-model';
@Component({
  templateUrl: './tax.component.html'
})
export class TaxComponent extends BaseModuleComponent implements OnInit {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Tax>;

  constructor(translateService: TranslateService) {
    super('tax', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code'
      },
      { field: 'name', header: 'table.column.name' },
      { field: 'taxType.name', header: 'table.column.taxType' },
      { field: 'taxMethod.name', header: 'table.column.taxMethod' },
      {
        field: 'amount',
        header: 'table.column.amount',
        customClass: 'text-right',
        fn: this.setDecimal.bind(this)
      }
    ]);
    this.setStateReady();
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/tax/add']);
  }

  public doEdit(tax: Tax): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('taxId', tax.id);
    this.router.navigate(['/pages/tax/edit']);
  }

  public doView(tax: Tax): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('taxId', tax.id);
    this.router.navigate(['/pages/tax/edit']);
  }

  public doDelete(tax: Tax): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<Tax>>('/tax/delete', tax)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  public setDecimal(field: number): string {
    return this.global.converterService.convertDecimal(field);
  }
}
