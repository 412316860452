import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { File } from '../../core/bean/file';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementFailed } from '../../core/bean/procurement-failed';
import { AppOfficialReportRequest } from '../../core/components/app-official-report/app-official-report.request';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { EncryptService } from '../../core/services/encrypt.service';
import { Validators } from '../../core/validators';
import { InitiationGenerateRequest } from './request/initiation-generate.request';
import { ProcurementFailedRequest } from './request/procurement-failed.request';

@Component({
  templateUrl: 'initiation-popup-failed.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InitiationPopupFailedComponent extends BaseComponentComponent {
  @Input() procurement: Procurement;
  @Input() failedReason: string;
  @Output() public onChange: EventEmitter<ProcurementFailedRequest> =
    new EventEmitter();

  public fileUploader: FileUploader = new FileUploader(
    '/initiation/',
    'initiation.form.headerImage',
    this.global.appConstant.fileType.DOC_FAILED_PROCUREMENT,
    false,
    this.global.config.parameterModel.maxFileProcurementFailed
  );
  public minDate = new Date();
  public isDownloaded = false;
  public isSubmit = false;

  constructor(
    public translateService: TranslateService,
    public encryptService: EncryptService
  ) {
    super('initiation');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      letterNumber: [null],
      letterDate: [null, Validators.required()],
      docList: [null, Validators.required()]
    });

    this.formGroup.patchValue({
      letterDate: new Date()
    });
  }

  public doGenerate(event): void {
    this.formGroup.get('docList').clearValidators();
    this.formGroup.get('docList').updateValueAndValidity();

    this.validate();
    if (this.formGroup.valid && this.failedReason) {
      const initiationGenerateReq = new InitiationGenerateRequest();
      initiationGenerateReq.moduleCode = 'PROCUREMENT-FAILED';

      const appOfficialReportRequest = new AppOfficialReportRequest();
      appOfficialReportRequest.auth = this.encryptService.encrypt([
        this.procurement.id.toString(),
        'ORT_PROCUREMENT_FAILED_LETTER',
        'NUMBER'
      ]);
      appOfficialReportRequest.letterDate = this.formGroup.value.letterDate;

      initiationGenerateReq.appOfficialReportRequest = appOfficialReportRequest;
      initiationGenerateReq.reason = this.failedReason || '-';

      this.loadingBlockService.showInfo(`app.msg.processing`);
      this.httpClientService
        .post<Response<File>>('/initiation/generate', initiationGenerateReq)
        .subscribe(response => {
          this.isDownloaded = true;
          if (response.status === ResponseStatusModel.OK) {
            const file: File = response.body;
            this.doDownload(
              event,
              '/initiation/file/view/' +
                file.uploadedFileName +
                '/' +
                file.fileType.code,
              file.fileName
            );
            this.loadingBlockService.close();
          }
        });
    } else {
      this.setStateReady();
    }
  }

  public doSubmit(): void {
    this.formGroup.get('docList').setValidators([Validators.required()]);
    this.formGroup.get('docList').updateValueAndValidity();
    this.validate();
    this.isSubmit = true;

    if (this.formGroup.valid && this.isDownloaded) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const request = new ProcurementFailedRequest();
            request.procurementFailed = new ProcurementFailed();
            request.procurementFailed.procurement = this.procurement;
            request.procurementFailed.note = this.failedReason;
            request.procurementFailed.failedDate =
              this.formGroup.value.letterDate;
            request.fileObjectList = this.fileUploader.fileObjectList;

            this.setStateProcessing();
            this.httpClientService
              .post<ProcurementFailedRequest>(
                '/initiation/fail-procurement',
                request
              )
              .subscribe(response => {
                this.setStateReady();
                this.onChange.emit({
                  procurementFailed: response.body,
                  fileObjectList: this.fileUploader.fileObjectList
                });
              });
          }
        });
    } else {
      jQuery('.popup-failed').get(0).scrollIntoView({ behavior: 'smooth' });
    }
  }
}
