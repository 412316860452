<app-popup
  header="app-popup-address.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <form
    class="form-horizontal popup-address"
    [formGroup]="formGroup"
    novalidate
  >
    <div
      *ngIf="isLoadingFilter"
      [ngStyle]="{
        'min-height': '30vh',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
      }"
    >
      <img
        src="assets/img/common/loader-small.gif"
        alt="Loading"
        height="11"
        width="40"
      />
    </div>
    <app-select
      *ngIf="!isLoadingFilter"
      [optionList]="addressBookOptionList"
      [isMultiple]="false"
      [search]="true"
      isShowBorder="true"
      formControlName="addressBook"
    >
      <ng-template #content let-data>
        <p>{{ data.organization.name }}</p>
        <h5>{{ getUserNameList(data.addressPicList) }}</h5>
        <p>{{ data.addressDetail }}</p>
        <p>{{ data.phone }}</p>
      </ng-template>
    </app-select>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button color="PRIMARY" (onClick)="doSubmit()">{{
          'app.button.add' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
