import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppChooseModule } from '../../app-choose/app-choose.module';
import { AppTextFieldModule } from '../../app-text-field/app-text-field.module';
import { AppPopupModule } from '../app-popup.module';
import { AppTextAreaModule } from './../../app-text-area/app-text-area.module';
import { AppPopupWorkPlanItemComponent } from './app-popup-work-plan-item.component';
import { AppPopupWorkPlanItemService } from './app-popup-work-plan-item.service';
@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppChooseModule,
    AppTextFieldModule,
    AppTextAreaModule,
    AppButtonModule
  ],
  declarations: [AppPopupWorkPlanItemComponent],
  entryComponents: [AppPopupWorkPlanItemComponent],
  providers: [AppPopupWorkPlanItemService],
  exports: [AppPopupWorkPlanItemComponent]
})
export class AppPopupWorkPlanItemModule {}
