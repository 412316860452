import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ComplianceTest } from '../../core/bean/compliance-test';
import { ComplianceTestStatus } from '../../core/bean/compliance-test-status';
import { Organization } from '../../core/bean/organization';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { ComplianceTestOptionResponse } from './compliance-test-option.response';
@Component({
  templateUrl: './compliance-test.component.html'
})
export class ComplianceTestComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<ComplianceTest>;
  public complianceTestStatusOptionList: OptionListModel<ComplianceTestStatus> =
    new OptionListModel(true);
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('compliance-test', translateService);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setOptionList();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      complianceTestStatusList: [null],
      organizationList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<ComplianceTestOptionResponse>('/compliance-test/get-option-list')
      .subscribe((response: ComplianceTestOptionResponse) => {
        this.complianceTestStatusOptionList.setRequestValues(
          response.complianceTestStatusList
        );
        this.organizationOptionList.setRequestValues(response.organizationList);
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurement.code',
        header: 'table.column.procurementNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClickProcurement.bind(this)
        }
      },
      {
        field: 'procurement.procurementDate',
        header: 'table.column.procurementDate',
        plugins: 'date'
      },
      {
        field: 'procurement.name',
        header: 'table.column.procurementName',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'badge',
            field: 'procurement.isRepeat',
            pill: false,
            className: 'badge-catalog badge-danger ml-3',
            colorMap: {
              RE_HELD: 'DANGER'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.row.record.procurement.isRepeat) {
                  const RE_HELD: string = this.translateService.instant(
                    'dynamic-master-attribute.procurementFlag.reHeld'
                  );
                  return RE_HELD;
                }
              }
            }
          },
          {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData): void => {
              if (!tablePluginData.row.record.procurement.isRepeat) {
                tablePluginData.element.children[1].remove();
              }
            }
          }
        ]
      },
      {
        field: 'procurement.organization.name',
        header: 'table.column.department'
      },
      {
        field: 'procurement.procurementMethod.name',
        header: 'table.column.procureemntMethod'
      },
      {
        field: 'complianceTestStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'complianceTestStatus.code',
          colorMap: {
            NEW: 'INFO',
            DRAFT: 'SECONDARY',
            WAITING_REVIEW: 'WARNING',
            WAITING_APPROVAL: 'WARNING',
            REVISION: 'FEEDBACK',
            REVISION_BY_COMPLIANCE: 'FEEDBACK',
            DONE: 'SUCCESS'
          }
        }
      }
    ]);
  }

  public doClickProcurement(complianceTest: ComplianceTest): void {
    this.global.routerParams.clear();
    this.global.routerParams.set(
      'procurementId',
      complianceTest.procurement.id
    );
    this.global.routerParams.set('procurement', complianceTest.procurement);
    if (
      complianceTest.complianceTestStatus.code ===
      this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW
    ) {
      this.todo = 'add';
    } else if (
      complianceTest.complianceTestStatus.code ===
        this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT ||
      complianceTest.complianceTestStatus.code ===
        this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION
    ) {
      this.todo = 'edit';
    } else {
      this.todo = 'view';
    }
    this.global.routerParams.set('todo', this.todo);
    this.router.navigate([
      this.todo === 'view'
        ? '/pages/compliance-test/detail'
        : '/pages/compliance-test/' + this.todo
    ]);
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
    this.formGroup.get('fromDate').reset();
    this.formGroup.get('untilDate').reset();
  }
}
