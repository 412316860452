import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppOfficialReportModule } from 'src/app/core/components/app-official-report/app-official-report.module';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppChooseModule } from '../../core/components/app-choose/app-choose.module';
import { SharedModule } from '../../core/shared/shared.module';
import { DrEditAddComponent } from './dr-edit-add.component';
import { DrPopupAddDrAddressComponent } from './dr-popup-add-dr-address.component';
import { DrPopupAddMoreAddressComponent } from './dr-popup-add-more-address.component';
import { DrPopupDeliveryProcessComponent } from './dr-popup-delivery-process.component';
import { DrPopupShippedItemComponent } from './dr-popup-shipped-item.component';
import { DrComponent } from './dr.component';

export const routes = [
  {
    path: '',
    component: DrComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'edit',
    component: DrEditAddComponent,
    data: { breadcrumb: 'dr.breadcrumb.edit' }
  },
  {
    path: 'detail',
    component: DrEditAddComponent,
    data: { breadcrumb: 'dr.breadcrumb.detail' }
  },
  {
    path: 'detail/:token',
    component: DrEditAddComponent,
    data: { breadcrumb: 'pr.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppChooseModule,
    AppBadgeCatalogModule,
    AppOfficialReportModule
  ],
  declarations: [
    DrComponent,
    DrEditAddComponent,
    DrPopupShippedItemComponent,
    DrPopupAddDrAddressComponent,
    DrPopupAddMoreAddressComponent,
    DrPopupDeliveryProcessComponent
    // DeliveryMonitoringEditAddComponent,
    // AppPopupDeliveryMonitoringComponent,
    // AppPopupRequestClosedContractComponent
  ]
})
export class DrModule {}
