<div class="app-card mt-1">
  <div widget class="card border-0 box-shadow lmb-4" [ngClass]="customClass">
    <div
      *ngIf="!customLoadingTmpl && (isLoading || isSaving)"
      class="form-loader"
    ></div>
    <div
      class="
        card-header
        transparent
        border-0
        d-flex
        align-items-center
        justify-content-between
        cmt-3
      "
      *ngIf="header"
      [ngClass]="customClassHeader ? customClassHeader : ''"
    >
      <div class="mr-2 d-flex align-items-center" *ngIf="headerLeftTmpl">
        <ng-container *ngTemplateOutlet="headerLeftTmpl"></ng-container>
      </div>
      <div class="flex-1">
        <h3
          class="d-inline-block fw-500"
          innerHtml="{{ header | translate }}"
        ></h3>
        <h3 class="d-inline-block ml-2" *ngIf="detail">
          <span>- {{ detail | translate }}</span>
        </h3>
      </div>
      <div class="ml-2 d-flex-align-items-center" *ngIf="headerRightTmpl">
        <ng-container *ngTemplateOutlet="headerRightTmpl"></ng-container>
      </div>
    </div>
    <div class="widget-controls" *ngIf="header">
      <a data-widgster="expand" href="#" class="transition">
        <em class="fas fa-chevron-down"></em>
      </a>
      <a data-widgster="collapse" href="#" class="transition">
        <em class="fas fa-chevron-up"></em>
      </a>
      <a data-widgster="fullscreen" href="#" class="transition">
        <em class="fas fa-expand-alt"></em>
      </a>
      <a data-widgster="restore" href="#" class="transition">
        <em class="fas fa-compress-alt"></em>
      </a>
    </div>
    <div
      class="card-body widget-body"
      [ngClass]="[
        header ? '' : 'border-top-0',
        customClassBody ? customClassBody : ''
      ]"
    >
      <ng-container *ngIf="customLoadingTmpl && isLoading">
        <ng-container *ngTemplateOutlet="customLoadingTmpl"></ng-container>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <ng-content></ng-content>
      </ng-container>
    </div>
  </div>
</div>
