import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './../../core/shared/shared.module';
import { ProcurementTypeComponent } from './procurement-type.component';
const routes = [
  { path: '', component: ProcurementTypeComponent, data: { breadcrumb: '' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, NgbModule],
  declarations: [ProcurementTypeComponent],
  providers: []
})
export class ProcurementTypeModule {}
