<div class="widget-progress-complete-profile">
  <dashboard-widget-card
    header="{{
      'dashboard-widget-progress-complete-profile.title' | translate
    }}"
    customClassBody="mt-0"
    customClassHeader="border-bottom-0 pb-1"
    size="SM"
    [isLoading]="model.isLoading"
  >
    <ng-container *ngIf="isWrongRole">
      <div
        class="
          d-flex
          justify-content-center
          h-100
          w-100
          align-items-center
          text-secondary
        "
      >
        {{
          'dashboard-widget-progress-complete-profile.sorryYourRoleCannotUseThisWidget'
            | translate
        }}
      </div>
    </ng-container>
    <ng-container *ngIf="!isWrongRole">
      <h4 class="text-secondary fw-500 d-flex justify-content-between">
        <span class="d-inline-block">
          {{
            'dashboard-widget-progress-complete-profile.text.profileStrengthLevel'
              | translate
          }}
          <span class="text-primary-dark fw-700">
            {{
              'dashboard-widget-progress-complete-profile.text.strengthLevel.' +
                profileStrengthLevel | translate
            }}
          </span>
        </span>
        <span class="d-inline-block">
          ({{ progressCompleteProfileResponse?.progressAttribute }}/{{
            progressCompleteProfileResponse?.totalAttribute
          }})
        </span>
      </h4>

      <div class="promise-bar-outer">
        <div class="promise-bar bg-white">
          <span
            class="pb-progress text-right text-white px-3"
            [ngStyle]="{
              width: progressCompleteProfileResponse?.percentage + '%'
            }"
          >
            {{ progressCompleteProfileResponse?.percentage }} %
          </span>
        </div>
        <div class="line">
          <div
            [ngClass]="
              progressCompleteProfileResponse?.percentage > 33 ? 'border-0' : ''
            "
          ></div>
          <div
            [ngClass]="
              progressCompleteProfileResponse?.percentage > 66 ? 'border-0' : ''
            "
          ></div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="text-secondary">
          <span *ngIf="profileStrengthLevel === 'weak'">
            {{
              ('dashboard-widget-progress-complete-profile.text.completeMinimum'
                | translate) +
                ' ' +
                getMinimumField() +
                ' ' +
                ('dashboard-widget-progress-complete-profile.text.weak.detail'
                  | translate)
            }}
          </span>
          <span *ngIf="profileStrengthLevel === 'strong'">
            {{
              ('dashboard-widget-progress-complete-profile.text.completeMinimum'
                | translate) +
                ' ' +
                getMinimumField() +
                ' ' +
                ('dashboard-widget-progress-complete-profile.text.strong.detail'
                  | translate)
            }}
          </span>
          <span *ngIf="profileStrengthLevel === 'expert'">
            {{
              'dashboard-widget-progress-complete-profile.text.expert.detail'
                | translate
            }}
          </span>
        </h4>
        <app-button (onClick)="goToCompanyProfile()">
          {{
            'dashboard-widget-progress-complete-profile.button.completeProfile'
              | translate
          }}
        </app-button>
      </div>
    </ng-container>
  </dashboard-widget-card>
</div>
