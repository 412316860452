import { Contract } from 'src/app/core/bean/contract';
import { GuaranteeBondType } from 'src/app/core/bean/guarantee-bond-type';
import { ProcurementFines } from 'src/app/core/bean/procurement-fines';
import { ProcurementGuarantee } from 'src/app/core/bean/procurement-guarantee';
import { ProcurementSow } from 'src/app/core/bean/procurement-sow';

export class AppContractRequestViewResponse {
  contract: Contract = new Contract();
  procurementSowList: ProcurementSow[] = [];
  procurementFinesList: ProcurementFines[] = [];
  procurementGuaranteeList: ProcurementGuarantee[] = [];
  guaranteeBondTypeList: GuaranteeBondType[] = [];
}
