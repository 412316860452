import { ApprovalModel } from '../bean/approval-model';
import { BaseEntity } from './../base/base-entity';
import { ModuleGroup } from './../bean/module-group';

export class ApprovalEntity extends BaseEntity {
    moduleGroup: ModuleGroup;
    approvalModelList: ApprovalModel[];

    name: string;
    type: number;
    description: string;
}
