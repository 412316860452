import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { BaseComponentComponent } from '../../../../base/angular/base-component.component';

@Component({
  templateUrl: './app-popup-upload-images.component.html',
  styleUrls: ['./app-popup-upload-images.component.scss']
})
export class AppPopupUploadImagesComponent extends BaseComponentComponent {
  @Input() public imageFile: any;
  @Input() isMaintainAspectRatio = true;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  croppedImage: any = '';
  cropperPosition: any = '';

  constructor(
    public translate: TranslateService,
    private activeModal: NgbActiveModal
  ) {
    super('app-upload-images');
  }

  public onInit(): void {}

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  public doSave(): void {
    this.croppedImage = this.imageCropper.crop();
    this.onChange.emit(this.croppedImage);
  }

  public doCancel(): void {
    this.activeModal.close();
  }
}
