import { Contract } from '../bean/contract';
import { BaseEntity } from '../base/base-entity';
import { PrItemReleased } from '../bean/pr-item-released';

export class ContractDetailEntity extends BaseEntity {
    contract: Contract;
    quantity: number;
    price: number;
    totalPrice: number;
    prItemReleased: PrItemReleased;
    crudOperation: number;
}
