<app-popup header="app-popup-work-plan-item-info.title">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'app-popup-work-plan-item-info.label.numberWorkplan' | translate
        }}</label>
        <div class="col-sm-4 text-left">
          {{ workPlan.number }}
        </div>
      </div>
    </div>
  </div>
  <app-table
    [tableResponse]="tableResponse"
    [isServerSide]="true"
    stringUrl="/app-popup-work-plan-item-info/index"
    customData="{{ workPlan.id }}"
  >
  </app-table>
</app-popup>
