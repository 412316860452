import { BaseDTO } from '../../../core/base/base-dto';
import { VendorEquipment } from '../../../core/bean/vendor-equipment';
import { VendorEquipmentHistory } from '../../../core/bean/vendor-equipment-history';
import { VendorExpertise } from '../../../core/bean/vendor-expertise';
import { VendorExpertiseHistory } from '../../../core/bean/vendor-expertise-history';

export class TabProfileExpertAndEquipmentDTO extends BaseDTO {
    vendorExpertiseHistoryList: VendorExpertiseHistory[];
    vendorEquipmentHistoryList: VendorEquipmentHistory[];

    vendorExpertiseList: VendorExpertise[];
    vendorEquipmentList: VendorEquipment[];
}
