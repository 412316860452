import { BillingMemoBudget } from 'src/app/core/bean/billing-memo-budget';
import { BillingMemoOtherCost } from 'src/app/core/bean/billing-memo-other-cost';
import { BillingMemoStatus } from 'src/app/core/bean/billing-memo-status';
import { BillingMemoTax } from 'src/app/core/bean/billing-memo-tax';
import { Payment } from 'src/app/core/bean/payment';
import { PaymentInvoice } from 'src/app/core/bean/payment-invoice';
import { PaymentProof } from 'src/app/core/bean/payment-proof';
import { PaymentStatus } from 'src/app/core/bean/payment-status';
import { User } from 'src/app/core/bean/user';
import { VendorBank } from 'src/app/core/bean/vendor-bank';
import { VendorBankOffline } from 'src/app/core/bean/vendor-bank-offline';
import { FileObject } from 'src/app/core/components/upload';

export class PaymentMonitoringResponse {
  startDate: string;
  endDate: string;
  procurementList: string[];
  agreementList: string[];
  paymentList: Payment[] = [];
  vendorList: string[];
  paymentStatusList: PaymentStatus[] = [];
  paymentInvoiceList: PaymentInvoice[] = [];
  billingMemoStatusList: BillingMemoStatus[] = [];
  billingMemoItemList: BillingMemoBudget[];
  billingMemoTaxList: BillingMemoTax[];
  billingMemoOtherCostList: BillingMemoOtherCost[];
  paymentProof: PaymentProof;
  paymentTaxProof: PaymentProof;
  payment: Payment = new Payment();
  fileObjectList: FileObject[] = [];
  paymentProofUploadBy: User = new User();
  paymentProofCreatedAt: Date = new Date();
  paymentTaxProofUploadBy: User = new User();
  paymentTaxProofCreatedAt: Date = new Date();
  vendorBank: VendorBank = new VendorBank();
  isWapu: boolean;
  vendorBankOffline: VendorBankOffline;
  isHasTax: boolean;
}
