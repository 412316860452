<app-popup header="tab-expert-and-equipment.popup.header.equipment.title" [isLoading]="formLoading">
  <form [formGroup]="formGroup" class="app-popup-equipment">
    <h5 class="separator">{{
      'company-profile.form-group.popup.equipment' | translate
      }}</h5>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.type' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorEquipmentHistory.equipmentType?.name || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.quantity' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorEquipmentHistory.quantity || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.capacity' | translate
        }}</label>
      <div class="col-sm-9 text-left d-flex">
        {{ vendorEquipmentHistory.capacityUom || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.brand' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorEquipmentHistory.brand || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.condition' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorEquipmentHistory.equipmentCondition?.name || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.location' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorEquipmentHistory.regionNameList || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.ownership' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorEquipmentHistory.resourceOwnership?.name || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.year' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorEquipmentHistory.year || '-' }}
      </div>
    </div>

    <h5 class="separator">{{
      'company-profile.form-group.popup.ownershipDocument' | translate
      }}</h5>
    <ng-container *ngFor="
        let ownershipDocument of vendorEquipmentHistory.vendorEquipmentDocHistoryList;
        let i = index
      ">
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'company-profile.form.popup.certificationNumber' | translate
          }}</label>
        <div class="col-sm-9 text-left">
          {{ ownershipDocument?.certificateNumber || '-' }}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'company-profile.form.popup.validationDate' | translate
          }}</label>
        <div class="col-sm-9 text-left d-flex">
          <label class="mr-3 control-label">{{
            'company-profile.form.popup.from' | translate
            }}</label>
          {{
          (ownershipDocument?.startDate
          | date: global.config.parameterModel.dateFormatShort) || '-'
          }}
          <label class="ml-3 mr-3 control-label">{{
            'company-profile.form.popup.until' | translate
            }}</label>
          {{
          (ownershipDocument?.endDate
          | date: global.config.parameterModel.dateFormatShort) || '-'
          }}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'company-profile.form.popup.ownershipDoc' | translate
          }}</label>
        <div class="col-sm-9 text-left">
          {{ ownershipDocument.file?.fileName || '-' }}
        </div>
      </div>
    </ng-container>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
          }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>