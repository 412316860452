import { EventEmitter } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TableOptions } from '../types/table-options';
import { CheckedRecord } from './checked-record';
import { TableFieldModel } from './table-field-model';
import { TableRecord } from './table-record';
export class TableResponseModel<T> {
  public records: Array<T> = new Array();
  public updatedRecordList: Array<T> = new Array();
  public customData: object;
  public requestReload: EventEmitter<void> = new EventEmitter();
  public requestDelete: EventEmitter<CheckedRecord> = new EventEmitter();
  public checkedRecord: CheckedRecord = new CheckedRecord();
  public formArray = new FormArray([]);
  public lastChangeForm: { index: number; field: string };
  constructor(
    public moduleCode: string,
    public columns: Array<TableFieldModel>,
    public options?: TableOptions
  ) {}

  public reload(): void {
    this.requestReload.emit();
  }

  public getRecords(): Array<T> {
    return this.records || [];
  }

  public setRecords(records: Array<T>): void {
    this.records = records;
  }

  public addRecord(record: T): void {
    this.records.push(record);
  }

  public deleteRecords(): void {
    this.requestDelete.emit();
  }

  public removeAt(index: number): void {
    this.records.splice(index, 1);
  }

  public setCustomData(customData: object): void {
    this.customData = customData;
  }

  public setCheckedRecordList(recordList: Array<T>): void {
    this.checkedRecord.checkedRecordList = Array.from(recordList).map(
      r => new TableRecord(r)
    );
  }

  public validate(): void {
    this.formArray.markAllAsTouched();
  }
}
