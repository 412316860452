import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserPreference } from '../../bean/user-preference';
import { ValueType } from '../../bean/value-type';
import { Global } from '../../global/global';
import { Response } from '../../model/response-model';
import { ResponseStatusModel } from '../../model/response-status-model';

interface TipItem {
  id: number;
  header: string;
  content: string;
  active: boolean;
  dataTarget: string;
  isShowTip: boolean;
}

@Component({
  selector: 'app-tips',
  templateUrl: './app-tips.component.html',
  styleUrls: ['./app-tips.component.scss']
})
export class AppTipsComponent implements OnInit, AfterViewInit {
  @Input() parent: string;
  @Input() activeTipHeader: string;
  public tipItemList: Array<TipItem> = new Array();
  public userPreference: UserPreference = new UserPreference();
  public activeTipContent: Array<string> = new Array();
  public activeTip: TipItem;
  private currentScrollPosition = 0;
  private isMouseEnter: boolean;
  constructor(
    public global: Global,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.doSetShowTips();
  }

  ngAfterViewInit(): void {
    this.createTips();
    this.cdr.detectChanges();
  }

  public reload(): void {
    setTimeout(() => {
      this.createTips();
    });
  }

  public createTips(): void {
    this.tipItemList = [];
    this.activeTipContent = [];
    this.activeTip = null;
    const contentWrapperElement = document.querySelector(
      this.parent || '#contentWrapper'
    );
    if (contentWrapperElement) {
      let tipsElementList = contentWrapperElement.querySelectorAll('[tips]');
      tipsElementList =
        tipsElementList.length > 0
          ? tipsElementList
          : contentWrapperElement.querySelectorAll('[ng-reflect-tips]');
      tipsElementList.forEach((element: HTMLElement, key: number) => {
        const header =
          element.getAttribute('header') ||
          element.getAttribute('ng-reflect-header');
        const detail =
          element.getAttribute('tips') ||
          element.getAttribute('ng-reflect-tips');
        if (header) {
          const cardId = 'card-tips' + key;
          element.setAttribute('id', cardId);
          const tipItem: TipItem = {
            id: key,
            header: header,
            content: detail,
            active: key === 0,
            dataTarget: cardId,
            isShowTip: true
          };
          this.tipItemList.push(tipItem);
          element.addEventListener('mouseenter', () => {
            if (this.activeTip) {
              this.activeTip.active = false;
              this.activeTip = tipItem;
              this.activeTip.active = true;
              this.activeTipContent[0] = detail;
              this.isMouseEnter = true;
            }
          });
          element.addEventListener('mouseleave', () => {
            this.isMouseEnter = false;
          });
        }
      });
      if (this.tipItemList.length > 0) {
        const tipItem = this.tipItemList[0];
        this.activeTipContent.push(tipItem.content);
        this.activeTip = tipItem;
      }
      if (this.activeTipHeader) {
        const tipItem = this.tipItemList.find(
          tipItems => tipItems.header === this.activeTipHeader
        );
        setTimeout(() => {
          this.doScrollToItem(tipItem);
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.tipItemList.splice(0);
  }

  public doScrollToItem(tipItem): void {
    this.tipItemList.forEach(tooltipItem => {
      tooltipItem.active = false;
    });
    tipItem.active = true;

    const boundingClientRect = document
      .getElementById(tipItem.dataTarget)
      .getBoundingClientRect();
    const top = boundingClientRect.top + window.scrollY - 100;
    window.scrollTo({ top, behavior: 'smooth' });
    this.activeTipContent[0] = tipItem.content;
    this.activeTip = tipItem;
  }

  @HostListener('window:scroll')
  onScroll(): void {
    if (this.activeTip && !this.isMouseEnter) {
      const toBottom = window.scrollY > this.currentScrollPosition;
      const activeElement = document.getElementById(this.activeTip.dataTarget);
      const boundingClientRect = activeElement.getBoundingClientRect();
      if (toBottom) {
        const top = boundingClientRect.top + window.scrollY;
        const scrollHeight = boundingClientRect.height;
        if (window.scrollY > scrollHeight + top) {
          if (this.activeTip) {
            const lastId = this.activeTip.id;
            const activeTip = this.tipItemList[lastId + 1];
            if (activeTip) {
              this.activeTip.active = false;
              this.activeTip = activeTip;
              this.activeTip.active = true;
              this.activeTipContent[0] = this.activeTip.content;
            }
          }
        }
      } else {
        const screenInnerHeight = window.innerHeight;
        const top = boundingClientRect.top + window.scrollY;
        const isInvisible = screenInnerHeight + window.scrollY < top;
        if (isInvisible) {
          if (this.activeTip) {
            const lastId = this.activeTip.id;
            const activeTip = this.tipItemList[lastId - 1];
            if (activeTip) {
              this.activeTip.active = false;
              this.activeTip = activeTip;
              this.activeTip.active = true;
              this.activeTipContent[0] = this.activeTip.content;
            }
          }
        }
      }
      this.currentScrollPosition = window.scrollY;
    }
  }

  public doSetShowTips(): void {
    this.userPreference.name = 'IS_SHOW_TIPS';
    this.userPreference.value =
      this.global.userSession.userPreferenceMap[this.userPreference.name];
  }

  public doClose(): void {
    const url = '/config/update-user-preference';
    const userPreference = this.userPreference;
    userPreference.value = 'FALSE';
    userPreference.valueType = new ValueType();
    userPreference.valueType.code = 'BOOLEAN';
    this.global.httpClientService
      .post<Response<UserPreference>>(url, userPreference)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.userSession.userPreferenceMap[userPreference.name] =
            userPreference.value;
          this.userPreference.value = userPreference.value;
        }
      });
  }

  public doOpen(): void {
    const url = '/config/update-user-preference';
    const userPreference = this.userPreference;
    userPreference.value = 'TRUE';
    userPreference.valueType = new ValueType();
    userPreference.valueType.code = 'BOOLEAN';
    this.global.httpClientService
      .post<Response<UserPreference>>(url, userPreference)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.userSession.userPreferenceMap[userPreference.name] =
            userPreference.value;
          this.userPreference.value = userPreference.value;
        }
      });
  }

  public doHideTips(tipId: number): void {
    const index = this.tipItemList.findIndex(tipItem => tipItem.id === tipId);
    this.tipItemList[index].isShowTip = false;
    this.activeTip.isShowTip = false;
  }
}
