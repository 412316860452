import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorCertificate } from '../../core/bean/vendor-certificate';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './tab-certificate-tdr-history.component.html'
})
export class TabCertificateTDRHistoryComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<VendorCertificate>;
  public tableResponse: TableResponseModel<VendorCertificate>;

  constructor(translateService: TranslateService) {
    super('tab-certificate-tdr-history', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setCustomData();
    this.model.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
        { field: 'certificateNumber', header: 'table.col.certificateNumber', customClass: 'text-center' },
        { field: 'certificateStatus.name', header: 'table.col.certificateStatus', customClass: 'text-center' },
        { field: 'releaseDate', header: 'table.col.releaseDate', format: FieldFormatEnum.ShortDate, customClass: 'text-center' },
        { field: 'expiredDate', header: 'table.col.expiredDate', format: FieldFormatEnum.ShortDate, customClass: 'text-center' }
    ]);
  }

  public setCustomData(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponse.setCustomData(customData);
  }
}
