<div class="row main-row form-registration m-0 p-0">
  <div class="col-lg-12 mb-4 m-0 p-0">
    <app-card
      header="document-type.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'document-type.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div *ngIf="!isReadOnly">
              <app-text-field
                autofocus="true"
                size="SM"
                formControlName="code"
                type="code"
                tooltip="{{ 'document-type.tooltip.code' | translate }}"
                maxLength="32"
              ></app-text-field>
            </div>
            <div *ngIf="isReadOnly">
              {{ formGroup.controls.code.value }}
            </div>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label text-right required">{{
            'document-type.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <ng-container formArrayName="translationList">
              <!-- <div class="form-group row col-12 p-0" 
              *ngFor="let translation of 
              translationList.controls; let i = index" -->
              <div
                class="form-group row"
                *ngFor="
                  let translation of translationList.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <div [ngStyle]="{ 'padding-left': '1%' }"></div>
                <label class="col-sm-0 text-left control-label required">{{
                  translationListLabel[i]
                }}</label>
                <div class="col-sm-4 text-left">
                  <app-text-field
                    size="XL"
                    formControlName="value"
                    maxLength="128"
                  ></app-text-field>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'document-type.form.maxSize' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              size="SM"
              type="integer"
              formControlName="maxSize"
              symbolic="<strong>MB</strong>"
              maxLength="20"
            >
            </app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'document-type.form.extension' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-chosen
              formControlName="extension"
              [optionList]="extensionOptionList"
            ></app-chosen>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'document-type.form.description' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxLength="512">
            </app-text-area>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button color="SECONDARY" class="mr-1" (onClick)="doCancel()">
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button color="PRIMARY" (onClick)="doSave()">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
