import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { QuotationSubmissionVendorView } from '../../core/view/entity/bean/quotation-submission-vendor-view';

@Component({
  templateUrl: './submit-quotation-popup-submission-detail.component.html'
})
export class SubmitQuotationPopupSubmissionDetailComponent extends BaseComponentComponent {
  @Input() vendorView: QuotationSubmissionVendorView;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  public tableResponse: TableResponseModel<any>;

  constructor(public activeModal: NgbActiveModal) {
    super('submit-quotation');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setRecordList();
    this.setStateReady();
  }

  public setRecordList(): void {
    this.tableResponse.setRecordList(this.vendorView.quotationItemList);
    this.tableResponse.reload();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementItem.prItem.pr.code',
        header: 'table.column.prNumber'
      },
      {
        field: 'procurementItem.prItem.item.name',
        header: 'table.column.itemName'
      },
      {
        field: 'procurementItem.prItem.quantity',
        header: 'table.column.quantity',
        className: 'text-center',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'procurementItem.prItem.item.uom.name',
        header: 'table.column.uom',
        className: 'text-center'
      },
      {
        field: 'submittedStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'submittedStatus.code',
          colorMap: {
            UNAVAILABLE: 'SECONDARY',
            AVAILABLE: 'SUCCESS'
          }
        },
        className: 'text-center'
      }
    ]);
  }

  public doViewDetailVendor(): void {
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set(
      'vendorId',
      this.vendorView.procurementVendor.vendor.id
    );
    this.global.routerParams.set('urlBackOutside', this.router.url);
    this.global.routerParams.set('isVendorList', true);
    if (
      this.global.userSession.activeUserRole.role.type ===
      this.global.appConstant.core.ROLE_TYPE_VENDOR
    ) {
      this.global.routerParams.set('isFromVendor', true);
    }
    // let data = {};
    // data = JSON.stringify(this.temp);
    // localStorage.setItem('temp', data as string);
    // localStorage.setItem('todo', this.todo);
    this.router.navigate(['/pages/company-profile']);
    this.onChange.emit();
  }
}
