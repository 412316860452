import { AppDynamicComponentService } from '../../../services/app-dynamic-component.service';
import { AppTextFieldComponent } from '../../app-text-field/app-text-field.component';
import { createPluginFormcontrol } from '../helpers';
import { addPluginFormControlToFormGroup } from '../helpers/add-plugin-formcontrol-to-formgroup.helper';
import { setPluginFormcontrolIsView } from '../helpers/set-plugin-formcontrol-is-view.helper';
import { setTableRecordFormValue } from '../helpers/set-table-record-form-value.helper';
import { TextFieldPlugin } from '../interfaces/available-table-plugin';
import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';
export const AppTableTextFieldPlugin: TablePlugin = {
  name: 'text-field',
  before: (data: TablePluginData) => {
    data.element.addEventListener('click', (event: PointerEvent) =>
      event.stopPropagation()
    );
    const textFieldPlugin: TextFieldPlugin = data.column
      .plugins as TextFieldPlugin;
    const appDynamicComponent = data.state.injector.get(
      AppDynamicComponentService
    );
    let config: any = {};
    if (typeof textFieldPlugin !== 'string' && textFieldPlugin) {
      config = textFieldPlugin;
    }
    const formControl = createPluginFormcontrol(data);
    setPluginFormcontrolIsView(textFieldPlugin, formControl, data.state.isView);
    addPluginFormControlToFormGroup(data, formControl);
    appDynamicComponent
      .create(AppTextFieldComponent, data.element, {
        ...config,
        formControl: formControl,
        size: config.size || 'XL'
      })
      .onChange.subscribe(
        setTableRecordFormValue.bind(
          this,
          data.state,
          data.record,
          data.column,
          data.indexI
        )
      );
  }
};
