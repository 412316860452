<app-popup [isLoading]="formLoading"[isShowHeaderCloseButton]="false">
  <app-table-xx
    header="report-recap-rfp.detail.result.title"
    [model]="tableResponse"
    stringUrl="/report-recap-rfp/index"
    [isShowPagination]="false">
    <ng-template #headerButtons>
      <span class="mt-3" (click)="doMinimize()">
        <a
          class="mr-2"
          style="text-decoration-line: underline; font-weight: 500;">
          <span>
            <em
              class="pir pi-compress-alt ml-1"
              style="
                top: auto;
                left: auto;
                font-size: 16px;
                font-weight: 500;"
            ></em>
          </span>
           Minimize
        </a>
       </span>
      <span class="mt-3" (click)="doExport()">
        <a
          class="mr-2"
          style="text-decoration-line: underline; font-weight: 500;">
          <span>
            <em
              class="pir pi-file-download ml-1"
              style="
                top: auto;
                left: auto;
                font-size: 16px;
                font-weight: 500;"
            ></em>
          </span>
           Download Report
        </a>
       </span>
    </ng-template>
  </app-table-xx>
</app-popup>