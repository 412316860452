<div class="row main-row app-accordion-row">
  <div class="col-12">
    <app-card
      header="payment-monitoring.title"
      detail="payment-monitoring.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-accordion [isLoading]="formLoading">
        <app-accordion-item
          header="payment-monitoring.accordion.paymentInformation"
          [isExpanded]="true"
        >
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-monitoring.form.voucherNumber' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ paymentView.paymentVoucher.code || '-' }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-monitoring.form.invoiceNumber' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ paymentInvoice.code }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-monitoring.form.invoiceDate' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{
                paymentInvoice.invoiceDate
                  | date: global.config.parameterModel.dateFormatShort
              }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-monitoring.form.vendorName' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{
                paymentView.paymentBilling.vendor
                  ? paymentView.paymentBilling.vendor.name
                  : paymentMonitoringResponse.vendorBankOffline.vendorOffline
                      .name
              }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-monitoring.form.bankAccount' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{
                paymentView.vendorBank
                  ? paymentView.vendorBank.bank.name
                  : paymentView.vendorBank.bank.name
              }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-monitoring.form.bankNumber' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{
                paymentView.vendorBank
                  ? paymentView.vendorBank.accountNumber
                  : paymentMonitoringResponse.vendorBankOffline.accountNumber
              }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-monitoring.form.requestedBill' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{
                global.converterService.convertMoney(
                  paymentView.paymentBilling.amount
                )
              }}
              {{ 'IDR' }}
            </div>
          </div>
          <h6 class="separator" *ngIf="todo !== 'view'">{{
            'payment-monitoring.separator.proofOfPayment' | translate
          }}</h6>
          <ng-container *ngIf="todo !== 'view'">
            <div class="col-12 text-right mb-3" *ngIf="!paymentProof">
              <app-button
                size="SM"
                color="PRIMARY"
                (onClick)="doAddDocumentProof()"
              >
                <em class="fas fa-upload"></em>
                {{ 'app.button.upload' | translate }}
              </app-button>
            </div>
            <div class="col-12 text-right mb-3" *ngIf="paymentProof">
              <app-button
                size="SM"
                color="PRIMARY"
                (onClick)="doAddDocumentProof()"
              >
                <em class="fas fa-upload"></em>
                {{ 'app.button.update' | translate }}
              </app-button>
            </div>
          </ng-container>
          <div class="text-center py-4" *ngIf="formLoading || isLoadingProof">
            <img src="assets/img/common/loader-small.gif" alt="Loading" />
          </div>
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
            *ngIf="paymentProof && !formLoading && !isLoadingProof"
          >
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'payment-monitoring.form.file' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-upload
                  formControlName="paymentProofFile"
                  [fileUploader]="fileUploader"
                >
                </app-upload>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'payment-monitoring.form.description' | translate
              }}</label>
              <div class="col-sm-6 text-left">
                <app-text-area formControlName="paymentProofDescription">
                </app-text-area>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'payment-monitoring.form.uploadBy' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field formControlName="paymentProofUploadBy">
                </app-text-field>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'payment-monitoring.form.uploadDate' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{
                  formGroup.controls.paymentProofUploadDate.value
                    | date: global.config.parameterModel.datetimeFormatShort
                }}</div
              >
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'payment-monitoring.form.version' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field formControlName="paymentProofVersion">
                </app-text-field>
              </div>
            </div>
          </form>

          <h6 class="separator" *ngIf="todo !== 'view'">{{
            'payment-monitoring.separator.proofOfTax' | translate
          }}</h6>
          <ng-container *ngIf="todo !== 'view'">
            <div class="col-12 text-right mb-3" *ngIf="!paymentTaxProof">
              <app-button
                size="SM"
                color="PRIMARY"
                (onClick)="doAddDocumentTaxProof()"
              >
                <em class="fas fa-upload"></em>
                {{ 'app.button.upload' | translate }}
              </app-button>
            </div>
            <div class="col-12 text-right mb-3" *ngIf="paymentTaxProof">
              <app-button
                size="SM"
                color="PRIMARY"
                (onClick)="doAddDocumentTaxProof()"
              >
                <em class="fas fa-upload"></em>
                {{ 'app.button.update' | translate }}
              </app-button>
            </div>
          </ng-container>
          <div class="text-center py-4" *ngIf="formLoading || isLoading">
            <img src="assets/img/common/loader-small.gif" alt="Loading" />
          </div>
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
            *ngIf="paymentTaxProof && !formLoading && !isLoading"
          >
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'payment-monitoring.form.file' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-upload
                  formControlName="paymentTaxFile"
                  [fileUploader]="fileUploader"
                >
                </app-upload>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'payment-monitoring.form.description' | translate
              }}</label>
              <div class="col-sm-6 text-left">
                <app-text-area formControlName="paymentTaxDescription">
                </app-text-area>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'payment-monitoring.form.uploadBy' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field formControlName="paymentTaxUploadBy">
                </app-text-field>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'payment-monitoring.form.uploadDate' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{
                  formGroup.controls.paymentTaxUploadDate.value
                    | date: global.config.parameterModel.datetimeFormatShort
                }}</div
              >
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'payment-monitoring.form.version' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field formControlName="paymentTaxVersion">
                </app-text-field>
              </div>
            </div>
          </form>
        </app-accordion-item>
        <app-accordion-item header="payment-history.accordion.activityHistory">
          <app-activity-history-view
            [paymentId]="paymentId"
            [activityType]="2"
          ></app-activity-history-view>
        </app-accordion-item>
      </app-accordion>
      <div class="floating-button mt-5">
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button
                mode="BYPASS"
                color="SECONDARY"
                (onClick)="doCancel()"
                class="mr-1"
                [disabled]="formSaving"
                >{{ 'app.button.back' | translate }}
              </app-button>
              <app-button
                *ngIf="
                  (this.isHasTax
                    ? this.paymentTaxProof !== null &&
                      this.paymentTaxProof !== undefined
                    : !this.isHasTax) &&
                  this.paymentProof !== null &&
                  this.paymentProof != undefined
                "
                mode="BYPASS"
                color="PRIMARY"
                (onClick)="doClose()"
                class="mr-1"
                [disabled]="formSaving"
                >{{ 'payment-monitoring.button.closePayment' | translate }}
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
