import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../../core/guard/confirm.guard';
import { SharedModule } from '../../../core/shared/shared.module';
import { HomeSlideEditAddComponent } from './home-slide-edit-add.component';
import { HomeSlideComponent } from './home-slide.component';
const routes = [
  { path: '', component: HomeSlideComponent, data: { breadcrumb: 'index' } },
  {
    path: 'edit',
    component: HomeSlideEditAddComponent,
    data: { todo: 'edit', breadcrumb: 'home-slide.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: HomeSlideEditAddComponent,
    data: { todo: 'add', breadcrumb: 'home-slide.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [HomeSlideComponent, HomeSlideEditAddComponent]
})
export class HomeSlideModule {}
