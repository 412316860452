<app-popup [header]="header" [isLoading]="formLoading">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'contract-monitoring.form.contractNumber' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ contract.number }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'contract-monitoring.form.endDate' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          contract.endDate | date: global.config.parameterModel.dateFormatShort
        }}
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'contract-monitoring.form.email' | translate
      }}</label>
      <div class="col-sm-5 text-left">
        <app-text-field size="XL" formControlName="vendorEmail" type="email">
        </app-text-field
      ></div>
    </div>
    <div class="form-group row text-sm-right">
      <ng-container formArrayName="picEmailList">
        <ng-container
          *ngFor="let picEmail of picEmailList.controls; let i = index"
          [formGroupName]="i"
        >
          <label *ngIf="i === 0" class="col-sm-3 control-label">{{
            'contract-monitoring.form.picEmail' | translate
          }}</label>
          <label *ngIf="i !== 0" class="col-sm-3 control-label"></label>
          <div class="col-sm-5 text-left mb-3">
            <app-text-field size="XL" formControlName="picEmail" type="email">
            </app-text-field>
          </div>
          <div class="col-sm-3 text-left">
            <app-button
              *ngIf="i !== 0"
              color="SECONDARY"
              title="{{ 'app.tooltip.delete' | translate }}"
              size="SM"
              (onClick)="doRemovePIC(i)"
              ><em class="fa fas fa-trash"></em>
            </app-button>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="text-center">
      <app-button size="SM" (onClick)="addPicEmail()">
        <em class="fas fa-plus"></em>
        {{ 'app.button.addMore' | translate }}
      </app-button>
    </div>
  </form>

  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
        'app.button.close' | translate
      }}</app-button>
      <app-button
        *ngIf="!formGroup.isView"
        color="PRIMARY"
        (onClick)="doSendEmail()"
        >{{ 'contract-monitoring.button.sendEmail' | translate }}</app-button
      >
    </div>
  </ng-template>
</app-popup>
