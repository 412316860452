import { Component, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Organization } from '../../core/bean/organization';
import { Project } from '../../core/bean/project';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { AppPopupWorkPlanService } from '../../core/components/app-popup/app-popup-work-plan/app-popup-work-plan.service';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { WorkPlan } from './../../core/bean/work-plan';
import { ProjectRequest } from './project-request';
import { ProjectResponse } from './project-response';

@Component({
  templateUrl: './project-edit-add.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None
})
export class ProjectEditAddComponent extends BaseModuleComponent {
  project: Project = new Project();
  url: string;
  projectId: number;
  organization: Organization = new Organization();
  organizationId: number;
  projectResponse: ProjectResponse = new ProjectResponse();
  projectRequest: ProjectRequest = new ProjectRequest();
  workPlanList: WorkPlan[] = [];
  submitted: boolean;
  constructor(
    translateService: TranslateService,
    public appPopupWorkPlanService: AppPopupWorkPlanService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('project', translateService);
  }

  onInit(): void {
    this.todo = this.global.routerParams.get('todo');
    this.projectId = this.global.routerParams.get('projectId');
    this.organizationId = this.global.routerParams.get('organizationId');
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [null, Validators.required()],
      organization: [null],
      organizationName: [null, Validators.required()],
      description: [null, Validators.required()],
      workPlanList: this.formBuilder.array([], Validators.required())
    });

    this.httpClientService
      .post<ProjectResponse>(
        '/project/add-edit',
        new RouteRequestModel(this.todo, this.projectId)
      )
      .subscribe(projectResponse => {
        this.projectResponse = projectResponse;
        if (this.projectResponse.project != null) {
          this.workPlanList = this.projectResponse.workPlanList;
          this.formGroup.patchValue({
            id: projectResponse.project.id,
            name: projectResponse.project.name,
            organization: projectResponse.project.organization,
            organizationName: projectResponse.project.organization.name,
            description: projectResponse.project.description
          });
        }

        if (this.todo === 'edit') {
          this.buildForm(this.workPlanList);
        }

        this.setStateReady();
      });
  }

  public buildForm(workPlanList: WorkPlan[]): void {
    const control = this.formGroup.controls.workPlanList as FormArray;
    workPlanList.forEach(workPlan => {
      control.push(
        this.formBuilder.group({
          id: workPlan.id,
          number: workPlan.number,
          name: workPlan.name,
          organizationName: workPlan.organization.name,
          totalPriceItem: workPlan.totalPriceItem,
          convertTotalPriceItem: this.global.converterService.convertMoney(
            workPlan.totalPriceItem.toString()
          )
        })
      );
    });
  }

  get form(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.projectRequest.workPlanList =
              this.formGroup.value.workPlanList;
            this.projectRequest.project = this.global.copyFormAttributeToModel(
              this.project,
              this.formGroup
            );
            if (this.todo === 'edit') {
              this.url = '/project/update';
            } else {
              this.url = '/project/insert';
            }

            this.httpClientService
              .post<Response<Project>>(this.url, this.projectRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/project']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/project']);
  }

  public doChooseOrganization(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(data => {
        this.formGroup.patchValue({
          organization: data[0],
          organizationName: data[0].name
        });
      });
  }

  public doAddWorkPlan(): void {
    this.appPopupWorkPlanService.open().subscribe(workPlan => {
      const control = this.formGroup.controls.workPlanList as FormArray;
      control.push(
        this.formBuilder.group({
          id: workPlan.id,
          number: workPlan.number,
          name: workPlan.name,
          organization: workPlan.organization,
          organizationName: workPlan.organization.name,
          totalPriceItem: workPlan.totalPriceItem,
          convertTotalPriceItem: this.global.converterService.convertMoney(
            workPlan.totalPriceItem.toString()
          )
        })
      );
    });
  }

  public doDeleteWorkPlan(index: number): void {
    const control = this.formGroup.controls.workPlanList as FormArray;
    control.removeAt(index);
  }

  public get formArrayWorkPlanList(): FormArray {
    return this.formGroup.get('workPlanList') as FormArray;
  }
}
