import { BaseEntity } from '../base/base-entity';
import { Qualification } from '../bean/qualification';
import { Segmentation } from '../bean/segmentation';
import { VendorAssociation } from '../bean/vendor-association';
import { VendorAssociationHistory } from '../bean/vendor-association-history';
import { VendorHistory } from '../bean/vendor-history';
export class VendorSegmentationHistoryEntity extends BaseEntity {
    segmentationList: Segmentation[];
    segmentationName: string;
    crudInfo: string;
    vendorAssociationList: VendorAssociation[];
    vendorAssociationHistoryList: VendorAssociationHistory[];
    vendorHistory: VendorHistory;
    segmentation: Segmentation;
    qualification: Qualification;
    createdDate: Date;
    vendorSegmentationId: number;
}
