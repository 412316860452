import { Component, Input, ViewChild } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { VendorAdditionalDoc } from '../../bean/vendor-additional-doc';
import { VendorDoc } from '../../bean/vendor-doc';
import { AppTableComponent } from '../../components/app-table/app-table.component';
import { FieldFormatEnum } from '../../components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../components/app-table/model/table-response-model';

@Component({
  selector: 'app-popup-document-expired',
  templateUrl: './app-popup-document-expired.component.html'
})
export class AppPopupDocumentExpiredComponent extends BaseComponentComponent {
  @ViewChild('vendorDoc') vendorDocTable: AppTableComponent;
  @ViewChild('vendorAdditionalDoc') vendorAddDocTable: AppTableComponent;
  @Input() public vendorId: number;
  public vendorDocTableResponse: TableResponseModel<VendorDoc>;
  public vendorAddDocTableResponse: TableResponseModel<VendorAdditionalDoc>;

  constructor() {
    super('app-popup-document');
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildVendorDocTableResponse();
    this.buildVendorAddDocTableResponse();
    this.getAndSetVendorDocTableResponse();
    this.getAndSetVendorAddDocTableResponse();
  }

  public setDataFromRouterParams(): void {
    this.vendorId = this.global.routerParams.get('vendorId');
  }

  public buildVendorDocTableResponse(): void {
    this.vendorDocTableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'file.fileType.nameTranslated', header: 'table.column.nameTranslated' },
      { field: 'endDate', header: 'table.column.endDate', format: FieldFormatEnum.ShortDate, customClass: 'text-center' },
      { field: 'documentInformation', header: 'table.column.status', customClass: 'text-center' }
    ]);
  }

  public buildVendorAddDocTableResponse(): void {
    this.vendorAddDocTableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.nameTranslated' },
      { field: 'endDate', header: 'table.column.endDate', format: FieldFormatEnum.ShortDate, customClass: 'text-center' },
      { field: 'additionalDocumentInformation', header: 'table.column.status', customClass: 'text-center' }
    ]);
  }

  public getAndSetVendorDocTableResponse(): void {
    this.vendorDocTableResponse.setStateWatingFromServer();
    this.httpClientService.get<VendorDoc[]>('/vendor-expired-document/popup-vendor-doc/index/' + this.vendorId)
      .subscribe(response => {
        if (response !== null) {
          this.vendorDocTableResponse.page.records = response;
          this.vendorDocTableResponse.page.totalRecords = response.length;
          this.vendorDocTable.reloadClient();
        }
      });
  }

  public getAndSetVendorAddDocTableResponse(): void {
    this.vendorAddDocTableResponse.setStateWatingFromServer();
    this.httpClientService.get<VendorAdditionalDoc[]>('/vendor-expired-document/popup-vendor-additional-doc/index/' + this.vendorId)
      .subscribe(response => {
        if (response !== null) {
          this.vendorAddDocTableResponse.page.records = response;
          this.vendorAddDocTableResponse.page.totalRecords = response.length;
          this.vendorAddDocTable.reloadClient();
        }
      });
  }
}
