import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'theme-promise',
  templateUrl: './promise-theme.component.html',
  styleUrls: ['./promise-theme.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PromiseThemeComponent implements OnInit, OnDestroy {
  ngOnInit(): void {
    document
      .getElementsByTagName('html')
      .item(0)
      .setAttribute('data-theme', 'promise sidebar-small-width');
  }

  ngOnDestroy(): void {
    document.getElementsByTagName('html').item(0).removeAttribute('data-theme');
  }
}
