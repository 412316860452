import { ErrorHandler } from '@angular/core';
import { Global } from '../global/global';
import { Log } from '../services/logger';
import { ServiceLocator } from '../services/service-locator';
export class AppErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error: Error): void {
    const global: Global = ServiceLocator.injector.get(Global);
    const log = new Log(this);
    log.error(error.message);
    log.error(error);
    if (
      !global.blockMsg.active &&
      global.config.parameterModel &&
      global.config.parameterModel.isConsoleBrowser
    ) {
      global.blockUI('error', error.message);
    }
  }
}
