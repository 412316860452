import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { BaseParticipantEntity } from 'src/app/core/base/base-participant-entity';
import { Aanwijzing } from 'src/app/core/bean/aanwijzing';
import { AanwijzingCancellation } from 'src/app/core/bean/aanwijzing-cancellation';
import { AanwijzingChat } from 'src/app/core/bean/aanwijzing-chat';
import { AanwijzingParticipant } from 'src/app/core/bean/aanwijzing-participant';
import { Region } from 'src/app/core/bean/region';
import { ChatResponseModel } from 'src/app/core/components/app-chat/model/chat-response-model';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { ChatMenuWebsocketService } from 'src/app/pages/aanwijzing/chat-menu-websocket.service';
import { ChatParticipantWebsocketService } from 'src/app/pages/aanwijzing/chat-participant-websocket.service';
import { ChatWebsocketService } from 'src/app/pages/aanwijzing/chat-websocket.service';
import { SessionRoomWebsocketService } from 'src/app/pages/aanwijzing/session-room-websocket.service';
import { AanwijzingOthersPopupSessionComponent } from './aanwijzing-others-popup-session.component';
import { AanwijzingOthersResponse } from './aanwijzing-others.response';

@Component({
  templateUrl: './aanwijzing-others-session.component.html',
  styles: [
    '.form-registration { min-height: 100%; background: #ffffff; display: block }'
  ]
})
export class AanwijzingOthersSessionComponent
  extends BaseModuleComponent
  implements OnInit, OnDestroy
{
  public response: AanwijzingOthersResponse = new AanwijzingOthersResponse();
  public aanwijzingParticipant: AanwijzingParticipant;
  public aanwijzing: Aanwijzing;
  public aanwijzingCancellation: AanwijzingCancellation;
  public isCanceled = false;
  public chatModel: ChatResponseModel<AanwijzingParticipant, AanwijzingChat> =
    new ChatResponseModel([], []);
  public showStatusList: string[] = [
    this.global.appConstant.pm.AANWIJZING_STATUS_ON_PROGRESS
  ];

  constructor(
    translateService: TranslateService,
    public activatedRoute: ActivatedRoute,
    public appPopupService: AppPopupService,
    public chatWebsocketService: ChatWebsocketService,
    public chatParticipantWebsocketService: ChatParticipantWebsocketService,
    public chatMenuWebsocketService: ChatMenuWebsocketService,
    public sessionRoomWebsocketService: SessionRoomWebsocketService
  ) {
    super('aanwijzing-others', translateService);
  }

  public onInit(): void {
    if (this.global.userSession) {
      this.router.navigate(['/pages/aanwijzing/session-room']);
    } else {
      this.setInitState();
      this.chatWebsocketService.connect();
      this.chatParticipantWebsocketService.connect();
      this.chatMenuWebsocketService.connect();
      this.sessionRoomWebsocketService.connect();

      this.loadingBlockService.showInfo('app.msg.processing');
      this.sessionRoomWebsocketService.session.subscribe(
        (aanwijzing: Aanwijzing) => {
          this.aanwijzing = aanwijzing;
          this.aanwijzingParticipant.aanwijzing = this.aanwijzing;
          this.global.routerParams.set(
            'aanwijzingParticipant',
            this.aanwijzingParticipant
          );

          /** show popup join when just started */
          if (
            this.aanwijzingParticipant &&
            this.aanwijzing.aanwijzingStatus.code !==
              this.global.appConstant.pm.AANWIJZING_STATUS_NOT_STARTED &&
            this.aanwijzing.aanwijzingStatus.code !==
              this.global.appConstant.pm.AANWIJZING_STATUS_CANCELED &&
            !this.aanwijzingParticipant.isJoin &&
            !this.aanwijzingParticipant.isBlocked &&
            this.aanwijzingParticipant.isOnline
          ) {
            const aanwijzingParticipant = this.aanwijzingParticipant;
            const regionList: Region[] = this.response.regionList;

            this.appPopupService
              .open(AanwijzingOthersPopupSessionComponent, {
                regionList,
                aanwijzingParticipant
              })
              .subscribe(() => {
                this.setStateReady();
                this.global.routerParams.set(
                  'aanwijzingParticipant',
                  aanwijzingParticipant
                );
              });
          } else {
            this.setStateReady();
          }
        }
      );

      this.chatParticipantWebsocketService.participant.subscribe(
        (participant: BaseParticipantEntity) => {
          if (
            this.aanwijzingParticipant &&
            participant &&
            participant.id === this.aanwijzingParticipant.id
          ) {
            this.aanwijzingParticipant.isBlocked = participant.isBlocked;
            this.aanwijzingParticipant.crudOperation =
              participant.crudOperation;
            this.aanwijzingParticipant.isOnline = participant.isOnline;
            this.aanwijzingParticipant.isJoin = participant.isJoin;
          }
        }
      );
    }
  }

  public ngOnDestroy(): void {
    if (this.aanwijzingParticipant) {
      this.aanwijzingParticipant.isOnline = false;
      this.updateSocketParticipant(this.aanwijzingParticipant);
    }
  }

  @HostListener('window:beforeunload')
  onBeforeUnload(): void {
    if (this.aanwijzingParticipant) {
      this.aanwijzingParticipant.isOnline = false;
      this.updateSocketParticipant(this.aanwijzingParticipant);
    }
  }

  public updateSocketParticipant(participant: AanwijzingParticipant): void {
    if (
      ![
        this.global.appConstant.pm.AANWIJZING_STATUS_CLOSED,
        this.global.appConstant.pm.AANWIJZING_STATUS_DONE,
        this.global.appConstant.pm.AANWIJZING_STATUS_EXTENDED
      ].includes(this.aanwijzing.aanwijzingStatus.code)
    ) {
      /** agar ketika session ended tidak send chat has joined/left lagi */
      this.httpClientService
        .post<any>('/aanwijzing/chat-participant/update', participant)
        .subscribe((data: AanwijzingParticipant) => {
          this.log.debug(data);
        });
    }
  }

  public setInitState(): void {
    this.aanwijzingParticipant = this.global.routerParams.get(
      'aanwijzingParticipant'
    );
    if (this.aanwijzingParticipant) {
      this.aanwijzingParticipant.isOnline =
        true; /** agar tidak muncul halaman kick */
      this.aanwijzing = this.aanwijzingParticipant.aanwijzing;

      this.httpClientService
        .get<any>('/aanwijzing-others/session-room/' + this.aanwijzing.id, {})
        .subscribe((response: AanwijzingOthersResponse) => {
          const regionList: Region[] = response.regionList;
          const aanwijzingParticipant = this.aanwijzingParticipant;
          this.aanwijzingCancellation = response.aanwijzingCancellation;
          this.chatModel.setParticipantList(
            response.existAanwijzingParticipantList
          );
          this.chatModel.setMessageList(response.aanwijzingChatList);

          this.response = response;

          if (this.aanwijzingCancellation) {
            this.isCanceled = true;
          }

          if (
            aanwijzingParticipant &&
            this.aanwijzing.aanwijzingStatus.code !==
              this.global.appConstant.pm.AANWIJZING_STATUS_NOT_STARTED &&
            this.aanwijzing.aanwijzingStatus.code !==
              this.global.appConstant.pm.AANWIJZING_STATUS_CANCELED &&
            !aanwijzingParticipant.isJoin &&
            !aanwijzingParticipant.isBlocked &&
            aanwijzingParticipant.isOnline
          ) {
            this.appPopupService
              .open(AanwijzingOthersPopupSessionComponent, {
                regionList,
                aanwijzingParticipant
              })
              .subscribe(() => {
                this.setStateReady();
                this.global.routerParams.set(
                  'aanwijzingParticipant',
                  aanwijzingParticipant
                );
              });
          } else {
            this.setStateReady();
            aanwijzingParticipant.isOnline = !aanwijzingParticipant.isBlocked
              ? true
              : false;
            this.updateSocketParticipant(aanwijzingParticipant);
            this.global.routerParams.set(
              'aanwijzingParticipant',
              aanwijzingParticipant
            );
          }
        });
    }
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public doGoToHome(): void {
    this.router.navigate(['/portal/home']);
  }
}
