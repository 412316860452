import { File } from '../../../bean/file';
export class FileObject {
  isUploaded = false;
  isChange = false;
  isNew = false;
  isDeleted = false;
  file: File;
  urlIconImg: string;

  constructor(file: File, isNew: boolean) {
    this.file = file;
    this.isNew = isNew;
    if (file.extension != null) {
      this.getIconImg();
    }
  }

  public getIconImg(): void {
    if (!this.file.isImage) {
      const myImage = new Image();
      myImage.src =
        '/assets/component/fileuploader/logo-icon/' +
        this.file.extension.toLowerCase() +
        '.png';
      myImage.onload = () => {
        this.urlIconImg = myImage.src;
      };
      myImage.onerror = () => {
        myImage.src = '/assets/component/fileuploader/logo-icon/Default.png';
        this.urlIconImg = myImage.src;
      };
    }
  }
}
