<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="notification-blast.title"
      detail="app.action.{{ todo === 'view' ? 'detail' : todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-fieldset>
          <div *ngIf="!formLoading" class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'notification-blast.form.keyword' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ keyword }}
            </div>
          </div>
          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView ? 'required' : ''"
          >
            <label class="col-sm-3 control-label">{{
              'notification-blast.form.subject' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                size="LG"
                autofocus="true"
                formControlName="subject"
                maxLength="128"
              >
              </app-text-field>
            </div>
          </div>
          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView ? 'required' : ''"
          >
            <label class="col-sm-3 control-label">{{
              'notification-blast.form.content' | translate
            }}</label>
            <div
              class="text-left text-break"
              [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'"
              *ngIf="!formLoading"
              [ngStyle]="{ 'white-space': 'normal' }"
            >
              <app-wysiwyg size="LG" formControlName="content"></app-wysiwyg>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'notification-blast.form.cc' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                size="LG"
                formControlName="cc"
                [optionList]="ccOptionList"
              >
              </app-chosen>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'notification-blast.form.attachment' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-upload-multiple
                formControlName="attachment"
                [fileUploader]="fileUploader"
              ></app-upload-multiple>
            </div>
          </div>
        </app-fieldset>
        <app-fieldset>
          <app-fieldset legend="Filter">
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'notification-blast.form.vendorStatus' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-chosen
                  formControlName="vendorStatusCode"
                  [optionList]="vendorStatusOptionList"
                ></app-chosen>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'notification-blast.form.vendorType' | translate
              }}</label>
              <div *ngIf="!formGroup.isView" class="col-sm-9 text-left">
                <app-check-box
                  position="HORIZONTAL"
                  formControlName="vendorTypeCode"
                ></app-check-box>
              </div>
              <div
                *ngIf="formGroup.isView && !formLoading"
                class="col-sm-9 text-left"
              >
                {{ vendorTypeNameList ? vendorTypeNameList : '-' }}
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'notification-blast.form.vendorCategory' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-chosen
                  formControlName="vendorCategoryCode"
                  [optionList]="vendorCategoryOptionList"
                ></app-chosen>
              </div>
            </div>
            <div
              *ngIf="!formGroup.isView"
              class="row justify-content-center mt-5"
            >
              <app-button color="SECONDARY" (onClick)="doReset()" class="mr-1">
                <em class="fas fa-sync-alt"></em>
                {{ 'app.button.reset' | translate }}
              </app-button>
              <app-button color="PRIMARY" (onClick)="doApply()" class="mr-1"
                ><em class="fas fa-check"></em>
                {{ 'app.button.apply' | translate }}
              </app-button>
            </div>
          </app-fieldset>
          <small
            class="text-danger"
            *ngIf="!formGroup.isView && isSave && dataRecord.length === 0"
          >
            {{ 'app.validation.required' | translate }}
          </small>
          <app-table
            *ngIf="!formGroup.isView"
            [model]="tableResponse"
          >
            <ng-template #actionHeader>
              <input
                type="checkbox"
                class="form-check-input"
                (click)="doCheckAll($event)"
                aria-label="Check All"
                [checked]="dataRecord.length === tableResponse.records.length 
                && dataRecord.length > 0"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
              />
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <input
                type="checkbox"
                class="form-check-input"
                (click)="doCheck(rowData)"
                [checked]="dataRecord.indexOf(rowData) !== -1"
                aria-label="Check"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
              />
            </ng-template>
          </app-table>
          <app-table
            *ngIf="formGroup.isView && tableReady"
            [model]="tableResponseNotificationBlastUser"
          >
          </app-table>
        </app-fieldset>
        <div class="row justify-content-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doBack()"
            class="mr-1"
            [disabled]="formSaving"
            mode="BYPASS"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button
            *ngIf="!formGroup.isView"
            color="PRIMARY"
            (onClick)="doSend()"
            class="mr-1"
            [disabled]="formSaving"
            ><em class="fas fa-paper-plane"></em>
            {{ 'notification-blast.button.send' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
