<app-popup header="approval.form.chooseOfficer" [isLoading]="formLoading">
  <app-table [model]="tableResponse" [isServerSide]="true"
    stringUrl="/app-popup-officer/index">
    <ng-template #headerFilterTable>
      <div class="app-table-filter-item" [formGroup]="formGroup">
        <label for="organizationName">{{ 'approval.filter.organization' |
          translate }}</label>
        <app-choose size="XL" formControlName="organizationName"
          (onClick)="onChooseOrganization($event)"></app-choose>
      </div>
    </ng-template>
    <ng-template #actionButtons let-rowData>
      <app-button (onClick)="doChoose(rowData)">
        {{ 'app.button.select' | translate }}
      </app-button>
    </ng-template>
  </app-table>
</app-popup>