export class TableVendorRequestModel {
  rows: number;
  first: number;
  sortField: string;
  sortOrder: number;
  customData: string;
  globalFilter: string;
  constructor() {
    this.first = 0;
    this.sortOrder = 1;
    this.globalFilter = '';
  }

  setFirst(page: number): void {
    this.first = page * this.rows;
  }

  setCustomData(customData: any): void {
    this.customData = (customData && JSON.stringify(customData)) || customData;
  }
}
