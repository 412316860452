import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ContractReviewer } from '../../core/bean/contract-reviewer';
import { OptionListModel } from '../../core/model/option-list-model';

@Component({
  templateUrl: './contract-termination-popup-contract-reviewer.component.html'
})
export class ContractTerminationPopupContractReviewerComponent extends BaseModuleComponent {
  @Input() public contractReviewerList: ContractReviewer[];

  public contractReviewerOptionList: OptionListModel<ContractReviewer> =
    new OptionListModel(false);

  constructor(translateService: TranslateService) {
    super('contract-termination', translateService);
  }

  public onInit(): void {
    this.contractReviewerOptionList.setRequestValues(this.contractReviewerList);
    this.buildFormGroup();
    this.setViewOnly();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      contractReviewerList: this.contractReviewerList
    });
  }
}
