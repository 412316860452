<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-xx
        header="procurement-reg-vendor.index.title"
        [model]="tableResponse"
        stringUrl="/procurement-reg-vendor/index"
        [isShowButtonModifyTable]="true"
        [isShowCheckBox]="false"
        (onClick)="doClick($event)"
        (onAfterRowCreated)="onAfterRowCreated($event)"
      >
        <ng-template #headerFilterGroup>
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
            *ngIf="!formLoading"
          >
            <div class="form-group row text-sm-left">
              <div class="col-sm-12 text-left">
                <div class="form-row px-1" style="margin-bottom: -3rem">
                  <div class="form-group row px-4" style="align-items: center">
                    <label class="pr-4 pt-2">{{
                      'procurement-reg-vendor.form.filter.startDate' | translate
                    }}</label>
                    <div class="text-left">
                      <app-date-picker-x
                        size="XL"
                        formControlName="startDate"
                        [range]="true"
                        (onChange)="onChangeFilter()"
                      >
                      </app-date-picker-x>
                    </div>
                  </div>
                  <div class="form-group row px-4" style="align-items: center">
                    <label class="pr-4 pt-2">{{
                      'procurement-reg-vendor.form.filter.endDate' | translate
                    }}</label>
                    <div class="text-left">
                      <app-date-picker-x
                        size="XL"
                        formControlName="endDate"
                        [range]="true"
                        (onChange)="onChangeFilter()"
                      >
                      </app-date-picker-x>
                    </div>
                  </div>
                  <div class="form-group row ml-1 col-sm-2">
                    <app-dropdown-select
                      style="width: 100%"
                      size="XL"
                      [optionList]="procRegTypeOptionList"
                      formControlName="procurementRegTypeList"
                      showLimitValue="1"
                      type="CHOSEN"
                      placeholder="procurement-reg-vendor.placeholder.regType"
                      (onChange)="onChangeFilter()"
                    >
                    </app-dropdown-select>
                  </div>
                  <div class="form-group row ml-4 col-sm-2">
                    <app-dropdown-select
                      style="width: 100%"
                      size="XL"
                      [optionList]="procRegVendorStatusOptionList"
                      formControlName="procurementRegVendorStatusList"
                      showLimitValue="1"
                      type="CHOSEN"
                      placeholder="procurement-reg-vendor.placeholder.regStatus"
                      (onChange)="onChangeFilter()"
                    >
                    </app-dropdown-select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </app-table-xx>
    </div>
  </div>
</app-layout>
