import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Region } from '../../core/bean/region';
import { Vendor } from '../../core/bean/vendor';
import { VendorBank } from '../../core/bean/vendor-bank';
import { VendorBankHistory } from '../../core/bean/vendor-bank-history';
import { VendorEntity } from '../../core/bean/vendor-entity';
import { VendorType } from '../../core/bean/vendor-type';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { TabModel } from '../../core/model/tab/tab-model';
import { CompanyProfileServie } from '../company-profile/company-profile.service';
import { CompanyProfileDTO } from '../company-profile/dto/company-profile.dto';
import { TabProfileBankDTO } from './dto/tab-profile-bank.dto';
import { AppPopupVendorBankComponent } from './popup/app-popup-vendor-bank.component';

@Component({
  templateUrl: './tab-profile-bank.component.html'
})
export class TabProfileBankComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public userId: number;
  public companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public tabProfileBankDTO: TabProfileBankDTO = new TabProfileBankDTO();
  public vendor: Vendor = new Vendor();
  public now = new Date().getTime();
  public sessionId = null;
  public vendorTypeList: VendorType[] = [];
  public regionList: Region[] = [];
  public vendorEntityList: VendorEntity[] = [];
  public tabBank: TabModel;
  public tableResponse: TableResponseModel<VendorBankHistory>;
  public vendorBank: VendorBank;
  public isView: boolean;
  public fromOutside: string;
  public isVendorList: boolean;
  public accountNumberList = [];

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public companyProfileService: CompanyProfileServie
  ) {
    super('tab-profile-bank', translate);
  }

  public onInit(): void {
    this.getDataFromRouterParams();
    if (this.tabBank.routerParams.get('tabProfileBankDTO')) {
      this.tabProfileBankDTO =
        this.tabBank.routerParams.get('tabProfileBankDTO');
      this.getAndSetVendorToFormGroup();
      this.buildTableResponse();
      this.checkTable();
    } else {
      this.getDataFromServer();
    }
  }

  public getDataFromServer(): void {
    // tslint:disable-next-line: max-line-length
    this.httpClientService
      .get<TabProfileBankDTO>(
        this.global.userSession.activeUserRole.role.type !==
          this.global.appConstant.core.ROLE_TYPE_VENDOR
          ? '/tab-profile-bank/index?id=' +
              this.companyProfileDTO.vendorHistory.id +
              '&todo=' +
              this.todo
          : '/tab-profile-bank/index'
      )
      .subscribe(tabProfileBankDTO => {
        this.tabProfileBankDTO = tabProfileBankDTO;
        this.tabBank.routerParams.set('tabProfileBankDTO', tabProfileBankDTO);
        this.buildFormGroup();
        this.getAndSetVendorToFormGroup();
        this.buildTableResponse();
        this.checkTable();
      });
  }

  public getDataFromRouterParams(): void {
    this.tabBank = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_BANK);
    this.isView = this.companyProfileService.getTabResponse().isView;
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.todo = this.global.routerParams.get('todo');
    this.companyProfileDTO = this.global.routerParams.get('companyProfileDTO');
  }

  public buildFormGroup(): void {
    if (!this.tabBank.isFormGroupReady) {
      this.tabBank.formGroup = this.formBuilder.group({
        changeTab: [null],
        isTableEmpty: [null],
        vendorBankHistoryList: [null]
      });
    }
  }

  public getAndSetVendorToFormGroup(): void {
    this.tabBank.formGroup.patchValue(this.tabProfileBankDTO);
    this.tabProfileBankDTO.vendorBankHistoryList.forEach(vendorBankHistory => {
      this.accountNumberList.push(vendorBankHistory.accountNumber);
    });
    this.setStateReady();
  }

  public buildTableResponse(): void {
    if (
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR &&
      !this.isVendorList &&
      this.companyProfileDTO.vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE
    ) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        { field: 'bank.name', header: 'table.column.bankName' },
        { field: 'branch', header: 'table.column.branch' },
        {
          field: 'accountNumber',
          header: 'table.column.accNo'
        },
        { field: 'accountHolder', header: 'table.column.accHolder' },
        {
          field: 'currency.code',
          header: 'table.column.currency'
        },
        // tslint:disable-next-line: max-line-length
        {
          field: 'isPriority',
          header: 'table.column.paymentPriority',
          datamap: '{"true" : "Yes" , "false" : "No" }'
        },
        {
          field: 'crudInfo',
          header: 'table.column.status',
          format: FieldFormatEnum.Html,
          customClass: 'text-center'
        }
      ]);
    } else {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        { field: 'bank.name', header: 'table.column.bankName' },
        { field: 'branch', header: 'table.column.branch' },
        {
          field: 'accountNumber',
          header: 'table.column.accNo'
        },
        { field: 'accountHolder', header: 'table.column.accHolder' },
        {
          field: 'currency.code',
          header: 'table.column.currency'
        },
        // tslint:disable-next-line: max-line-length
        {
          field: 'isPriority',
          header: 'table.column.paymentPriority',
          datamap: '{"true" : "Yes" , "false" : "No" }'
        }
      ]);
    }
    this.tableResponse.setRecords(this.tabProfileBankDTO.vendorBankHistoryList);
    this.tableResponse.setTotalRecords(
      this.tabProfileBankDTO.vendorBankHistoryList.length
    );
  }

  public checkTable(): void {
    if (this.tabProfileBankDTO.vendorBankHistoryList.length !== 0) {
      this.tabBank.formGroup.patchValue({ isTableEmpty: false });
    } else {
      this.tabBank.formGroup.patchValue({ isTableEmpty: true });
    }
  }

  public doDelete(vendorBankHistoryDelete: VendorBankHistory): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tabProfileBankDTO.vendorBankHistoryList.forEach(
            (vendorBankHistory, index) => {
              if (vendorBankHistory === vendorBankHistoryDelete) {
                this.tabProfileBankDTO.vendorBankHistoryList.splice(index, 1);
              }
            }
          );
          this.tableResponse.page.records =
            this.tabProfileBankDTO.vendorBankHistoryList;
          this.tableResponse.page.totalRecords =
            this.tabProfileBankDTO.vendorBankHistoryList.length;
          this.tableResponse.reloadClient();
          this.checkTable();
        }
      });
  }

  public doAdd(): void {
    const todo = 'add';
    const vendorBankHistoryList = this.tabProfileBankDTO.vendorBankHistoryList;
    const defaultBank = this.tabProfileBankDTO.defaultBank;
    const accountNumberList: string[] = (vendorBankHistoryList).map(
      bank => bank.accountNumber
    );
    // tslint:disable-next-line: max-line-length
    this.appPopupService
      .open(
        AppPopupVendorBankComponent,
        { todo, vendorBankHistoryList, defaultBank, accountNumberList },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(vendorBankHistory => {
        if (vendorBankHistory.value.isPriority === null) {
          vendorBankHistory.value.isPriority = false;
        }
        if (vendorBankHistory.value.isAgree === true) {
          this.tabProfileBankDTO.vendorBankHistoryList[
            vendorBankHistory.value.indexBank
          ].isPriority = false;
        } else if (vendorBankHistory.value.isAgree === false) {
          vendorBankHistory.value.isPriority = false;
        }
        if (
          vendorBankHistory.value.cityRegion !== null &&
          vendorBankHistory.value.cityRegion.id === undefined
        ) {
          vendorBankHistory.value.cityRegion = null;
        }
        this.tabProfileBankDTO.vendorBankHistoryList.push(
          vendorBankHistory.value
        );
        this.tabBank.formGroup.patchValue({
          vendorBankHistoryList: this.tabProfileBankDTO.vendorBankHistoryList
        });
        this.tableResponse.setRecords(
          this.tabProfileBankDTO.vendorBankHistoryList
        );
        this.tableResponse.setTotalRecords(
          this.tabProfileBankDTO.vendorBankHistoryList.length
        );
        this.tableResponse.reloadClient();
        this.checkTable();
      });
  }

  public doEdit(vendorBankHistoryEdit: VendorBankHistory): void {
    const todo = 'edit';
    const vendorBankHistoryList = this.tabProfileBankDTO.vendorBankHistoryList;
    const bankId = vendorBankHistoryEdit.id;
    const accountNumberList = [];
    this.tabProfileBankDTO.vendorBankHistoryList.forEach(vendorBankHistory => {
      if (vendorBankHistory !== vendorBankHistoryEdit) {
        accountNumberList.push(vendorBankHistory.accountNumber);
      }
    });
    // tslint:disable-next-line: max-line-length
    this.appPopupService
      .open(
        AppPopupVendorBankComponent,
        {
          todo,
          bankId,
          vendorBankHistoryEdit,
          vendorBankHistoryList,
          accountNumberList
        },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(vendorBankTemp => {
        if (vendorBankTemp.value.isPriority === null) {
          vendorBankTemp.value.isPriority = false;
        }
        if (vendorBankTemp.value.isAgree === true) {
          this.tabProfileBankDTO.vendorBankHistoryList[
            vendorBankTemp.value.indexBank
          ].isPriority = false;
        } else if (vendorBankTemp.value.isAgree === false) {
          vendorBankTemp.value.isPriority = false;
        }
        if (vendorBankTemp.value.cityRegion !== null) {
          if (vendorBankTemp.value.cityRegion.id === undefined) {
            vendorBankTemp.value.cityRegion = null;
          }
        }
        this.tabProfileBankDTO.vendorBankHistoryList.forEach(
          (vendorBank, index) => {
            if (vendorBank === vendorBankHistoryEdit) {
              this.tabProfileBankDTO.vendorBankHistoryList[index] =
                vendorBankTemp.value;
            }
          }
        );
        this.tabBank.formGroup.patchValue({
          vendorBankHistoryList: this.tabProfileBankDTO.vendorBankHistoryList
        });
        this.tableResponse.setRecords(
          this.tabProfileBankDTO.vendorBankHistoryList
        );
        this.tableResponse.setTotalRecords(
          this.tabProfileBankDTO.vendorBankHistoryList.length
        );
        this.tableResponse.reloadClient();
      });
  }

  public doView(vendorBankHistoryEdit: VendorBankHistory): void {
    const todo = 'detail';
    const header = 'Detail Bank';
    const vendorBankHistoryList = this.tabProfileBankDTO.vendorBankHistoryList;
    const vendorBankList = this.tabProfileBankDTO.vendorBankList;
    // tslint:disable-next-line: max-line-length
    this.appPopupService.open(
      AppPopupVendorBankComponent,
      {
        todo,
        vendorBankHistoryEdit,
        vendorBankList,
        vendorBankHistoryList,
        header
      },
      { size: 'lg' }
    );
  }
}
