import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetTotalAvailablePaymentResponse } from './widget-total-available-payment.response';

@Component({
  templateUrl: './widget-total-available-payment.component.html'
})
export class WidgetTotalAvailablePaymentComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-total-available-payment';
  public tableResponse: TableResponseModel<PaymentBilling>;
  public remainingAmount: number;

  constructor(public translateService: TranslateService) {
    super(WidgetTotalAvailablePaymentComponent.moduleCode);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setFormGroup();
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<WidgetTotalAvailablePaymentResponse>(
        '/widget-total-available-payment/index',
        {}
      )
      .subscribe((response: WidgetTotalAvailablePaymentResponse) => {
        this.remainingAmount = response.totalRemainingAmount;
        this.tableResponse.setRecordList(response.paymentBillingList);
        this.tableResponse.reload();

        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'title',
          header: 'table.column.title',
          plugins: { name: 'hyperlink', onClick: this.onClick.bind(this) }
        },
        {
          field: 'stageOfWork.name',
          header: 'table.column.stage'
        },
        {
          field: 'sowPaymentTerm.paymentTerm.name',
          header: 'table.column.termin'
        },
        {
          field: 'sowPaymentTerm.amount',
          header: 'table.column.amount',
          plugins: { name: 'default', type: 'currency' },
          className: 'text-right'
        },
        {
          field: 'paidAmount',
          header: 'table.column.paid',
          plugins: { name: 'default', type: 'currency' },
          className: 'text-right'
        },
        {
          field: 'remainingAmount',
          header: 'table.column.bills',
          plugins: { name: 'default', type: 'currency' },
          className: 'text-right'
        },
        {
          field: 'isSubmitted',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            colorMap: {
              NOT_REQUESTED: 'SECONDARY',
              ON_PROGRESS: 'PROGRESS'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.value) {
                  return this.translateService.instant(
                    this.moduleCode + '.status.onProgress'
                  );
                } else {
                  return this.translateService.instant(
                    this.moduleCode + '.status.notRequested'
                  );
                }
              },
              color: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.value) {
                  return 'ON_PROGRESS';
                } else {
                  return 'NOT_REQUESTED';
                }
              }
            }
          }
        }
      ],
      { recordPerPage: 5 }
    );
  }

  public onClick(paymentBilling: PaymentBilling): void {
    console.log(paymentBilling);
    this.router.navigate(['/pages/vendor-payment-request/']);
  }
}
