<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-x
        [model]="tableResponse"
        stringUrl="/pq/index"
        (onClick)="doEdit($event)"
      >
        <ng-template #headerButtons>
          <app-button (onClick)="doImport()">
            <em class="far fa-file"></em>&nbsp;
            {{ 'app.button.import' | translate }}
          </app-button>
          <app-button
            (onClick)="doAdd()"
            title="{{ 'app.button.add' | translate }}"
            ><em class="fas fa-plus"></em
            >{{ 'app.button.add' | translate }}</app-button
          >
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <!-- <app-button
            size="TN"
            (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}"
          >
            <em class="fas fa-pencil-alt"></em>
            {{ 'app.button.edit' | translate }}
          </app-button> -->
          <app-button
            size="TN"
            (onClick)="doDelete(rowData)"
            title="{{ 'app.tooltip.delete' | translate }}"
          >
            <em class="pir pi-trash-alt"></em>
            {{ 'app.button.delete' | translate }}
          </app-button>
        </ng-template>
      </app-table-x>
    </div>
  </div>
</app-layout>
