import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../../../../../core/base/angular/base-component.component';
import { Catalog } from '../../../../../../core/bean/catalog';
import { Response } from '../../../../../../core/model/response-model';
import { ResponseStatusModel } from '../../../../../../core/model/response-status-model';
import { ViewCatalogComparisonDTO } from '../../../dto/view-catalog-comparison.dto';
@Component({
  selector: 'app-popup-catalog-comparison',
  templateUrl: './app-popup-catalog-comparison.component.html'
})
export class AppPopupCatalogComparisonComponent extends BaseComponentComponent {
  @Input() public catalogList: Catalog[];
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  constructor() {
    super('app-popup-catalog-comparison');
  }

  onInit(): void {}

  public doGetCheckedList(checkedCatalogList: Catalog[]): void {
    this.setStateProcessing();
    this.httpClientService
      .post<Response<ViewCatalogComparisonDTO>>(
        '/view-catalog/compare',
        checkedCatalogList
      )
      .subscribe((response: Response<ViewCatalogComparisonDTO>) => {
        checkedCatalogList = [];
        if (response.status === ResponseStatusModel.OK) {
          this.global.routerParams.set('responseComparison', response);
          this.onChange.emit(response);
          this.router.navigate(['/pages/view-catalog/comparison']);
        } else {
          this.global.alertService.showError(
            response.statusText,
            '.app-popup-comparison'
          );
          this.setStateReady();
        }
      });
  }
}
