<app-popup header="wanprestasi.popup.procurement">
  <div class="row main-row-popup">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'wanprestasi.form.procurement.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div *ngIf="todo !== 'view'">
              <app-choose
                size="LG"
                formControlName="nameProcurement"
                (onClick)="doChooseProcurement()"
              ></app-choose>
            </div>
            <div *ngIf="todo === 'view'">{{
              wanprestasiEditView.procurement.name
            }}</div>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'wanprestasi.stages' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p *ngIf="stages !== null">{{ stages }}</p>
            <p *ngIf="stages === null">-</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'wanprestasi.form.procurement.type' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p *ngIf="procurementType !== null">{{ procurementType }}</p>
            <p *ngIf="procurementType === null">-</p>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'wanprestasi.form.date' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-date-picker formControlName="wanprestasiDate">
            </app-date-picker>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'wanprestasi.form.detail' | translate
          }}</label>
          <div
            class="text-left"
            [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'"
          >
            <app-text-area
              maxLength="512"
              size="LG"
              formControlName="wanprestasiDetail"
            ></app-text-area>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'wanprestasi.form.memoNumber' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              size="LG"
              maxLength="32"
              formControlName="memoNumber"
            ></app-text-field>
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'wanprestasi.form.upload' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-upload
              size="LG"
              formControlName="file"
              [fileUploader]="fileUploader"
            ></app-upload>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'wanprestasi.form.note' | translate
          }}</label>
          <div
            class="text-left"
            [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'"
          >
            <app-text-area maxLength="512" size="LG" formControlName="note">
            </app-text-area>
          </div>
        </div>

        <ng-template #modalFooter let-activeModal>
          <div class="button-group button-group-center">
            <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
              'app.button.close' | translate
            }}</app-button>
            <app-button
              *ngIf="todo === 'add' || todo === 'edit'"
              color="PRIMARY"
              (onClick)="doSaveWanprestasiProcurement()"
              >{{ 'app.button.save' | translate }}</app-button
            >
          </div>
        </ng-template>
      </form>
    </div>
  </div>
</app-popup>
