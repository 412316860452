import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from './../../core/shared/shared.module';
import { FinesEditAddComponent } from './fines-edit-add.component';
import { FinesComponent } from './fines.component';
export const routes = [
  { path: '', component: FinesComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: FinesEditAddComponent,
    data: { breadcrumb: 'fines.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: FinesEditAddComponent,
    data: { breadcrumb: 'fines.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [FinesComponent, FinesEditAddComponent]
})
export class FinesModule {}
