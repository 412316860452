import { User } from '../bean/user';
import { WorkflowModelPrcs } from '../bean/workflow-model-prcs';
import { WorkflowPrcs } from '../bean/workflow-prcs';
import { BaseDelegatorEntity } from './base-delegator.entity';

export class BaseWorkflowObjectEntity extends BaseDelegatorEntity {
  name: string;
  prcsCode: string;
  workflowPrcs: WorkflowPrcs;
  workflowModelPrcs: WorkflowModelPrcs;
  userRequester: User;
}
