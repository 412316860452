import { Refutation } from '../../core/bean/refutation';
import { RefutationSubmission } from '../../core/bean/refutation-submission';
import { RefutationVendor } from '../../core/bean/refutation-vendor';

export class RefutationVendorRequest {
  isSubmit: boolean;
  refutation: Refutation;
  refutationVendorList: RefutationVendor[];
  refutationSubmissionList: RefutationSubmission[];
}
