import { BaseEntity } from '../base/base-entity';
import { BillingMemo } from '../bean/billing-memo';
import { BudgetAllocation } from '../bean/budget-allocation';

export class BillingMemoOtherCostEntity extends BaseEntity {
    billingMemo: BillingMemo;
    budgetAllocation: BudgetAllocation;
    amount: number;
    name: string;
    transactionType: number;
    isDefault: boolean;
}
