<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-xx
        [model]="tableResponse"
        stringUrl="/sla-contract/index"
        (onClick)="doClick($event)"
      >
        <ng-template #headerFilterGroup>
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
            *ngIf="!formLoading"
          >
            <div class="form-group row text-sm-left">
              <div class="col-lg-12 row" style="margin-bottom: -1.5rem">
                <div class="cml-3 text-left">
                  <app-dropdown-select
                    size="XL"
                    formControlName="workflowList"
                    (onChange)="onChangeFilter()"
                    showLimitValue="1"
                    type="CHOSEN"
                    isMultiple="true"
                    placeholder="sla-contract.placeholder.workflowName"
                    [optionList]="workflowList"
                  >
                    <ng-template #selectAll>
                      <p>
                        {{
                          'sla-contract.placeholder.allWorkflowName' | translate
                        }}
                      </p>
                    </ng-template>
                  </app-dropdown-select>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </app-table-xx>
    </div>
  </div>
</app-layout>
