<app-layout
  [isLoading]="formLoading"
  [backTo]="urlBackOutside"
  header="price-evaluation.breadcrumb.index"
>
  <ng-template #additionalTitle *ngIf="!formLoading && isShowBadgeStatus">
    <app-badge-catalog
      class="cml-2"
      *ngIf="priceEvaluationResponse.procurement?.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge
      class="cml-2"
      [color]="
        badgeColor[
          priceEvaluationResponse.procurement.procurementWorklistStatus.code
        ]
      "
    >
      {{
        translateLabel(
          priceEvaluationResponse.procurement.procurementWorklistStatus.code
        ) | translate
      }}
    </app-badge>
  </ng-template>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-alert-x
        *ngIf="isCancelation && !formLoading"
        color="DANGER"
        [isBold]="false"
      >
        {{ 'price-evaluation.alert.msg.cancelationApproval' | translate }}
      </app-alert-x>

      <app-alert-x
        *ngIf="isFailedAssessment && !formLoading"
        color="DANGER"
        [isBold]="false"
      >
        {{ 'price-evaluation.alert.msg.failApproval' | translate }}
      </app-alert-x>
      <form [formGroup]="formGroup">
        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="priceEvaluationResponse.workflowStepInfoModel"
          *ngIf="!formLoading"
        >
        </app-workflow-step-info>
        <app-card
          header="price-evaluation.detail.procurementDetail.title"
          tips="price-evaluation.detail.procurementDetail.tips"
        >
          <app-procurement-info [procurementId]="procurementId">
          </app-procurement-info>
        </app-card>
        <app-item-request
          [objectList]="priceEvaluationResponse.procurementItemList"
          [isShowPrice]="true"
          [itemTypeList]="priceEvaluationResponse.itemTypeList"
          [isView]="true"
          *ngIf="!formLoading"
        ></app-item-request>
        <app-card
          *ngIf="
            !formLoading &&
            procurement.biddingSubmissionType.code ===
              global.appConstant.pm.BIDDING_SUBMISSION_TYPE_TOTAL_PRICE
          "
        >
          <app-table-xx
            header="price-evaluation.detail.priceEvaluation.title"
            tips="price-evaluation.detail.priceEvaluation.tips"
            [model]="tableResponsePriceEval"
            *ngIf="!formLoading"
            stringUrl="/price-evaluation/table-price-evaluation"
          >
            <ng-template
              #headerButtons
              *ngIf="priceEvaluationResponse.isHasOep"
            >
              <div class="d-flex justify-content-end">
                <app-button
                  (onClick)="doViewEstimationPrice()"
                  [disabled]="formSaving"
                  >{{
                    'price-evaluation.button.viewEstimationPrice' | translate
                  }}</app-button
                >
              </div>
            </ng-template>
          </app-table-xx>
        </app-card>
        <app-card
          *ngIf="
            !formLoading &&
            procurement.biddingSubmissionType.code ===
              global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
          "
          header="price-evaluation.detail.priceEvaluation.title"
          tips="price-evaluation.detail.priceEvaluation.tips"
        >
          <ng-template #headerRight *ngIf="priceEvaluationResponse.isHasOep">
            <app-button
              (onClick)="doViewEstimationPrice()"
              [disabled]="formSaving"
              >{{
                'price-evaluation.button.viewEstimationPrice' | translate
              }}</app-button
            >
          </ng-template>
          <app-tab [bordered]="true" *ngIf="!formLoading">
            <app-tab-item
              header="{{ 'price-evaluation.tab.header.byItem' | translate }}"
            >
              <app-table-xx
                [model]="tableResponseQuotationItem"
                *ngIf="!formLoading"
                stringUrl="/price-evaluation/table-quotation-item"
                (onClick)="doClickQuotationItem($event)"
              ></app-table-xx>
            </app-tab-item>
            <app-tab-item
              header="{{ 'price-evaluation.tab.header.byVendor' | translate }}"
            >
              <app-table-xx
                [model]="tableResponseQuotationVendor"
                *ngIf="!formLoading"
                stringUrl="/price-evaluation/table-quotation-vendor"
                (onClick)="doClickQuotationVendor($event)"
              ></app-table-xx>
            </app-tab-item>
          </app-tab>
        </app-card>
        <app-card
          *ngIf="!formLoading"
          header="price-evaluation.detail.priceEvaluationMethod.title"
          tips="price-evaluation.detail.priceEvaluationMethod.tips"
        >
          <app-alert-x [isBold]="false" *ngIf="!priceEvaluationMethod">
            {{ 'price-evaluation.alert.infoSelectMethod' | translate }}
          </app-alert-x>
          <app-select
            [isMultiple]="false"
            formControlName="evaluationMethod"
            [optionList]="evaluationMethodOptionList"
            maxHeight="max-content"
            [isShowBorder]="true"
            (onChange)="doChangeMethod($event)"
          >
            <ng-template #content let-data>
              <div *ngIf="!formGroup.isView">
                <h4>{{
                  data.nameTranslationKey.module.code.toLowerCase() +
                    '.' +
                    data.nameTranslationKey.key | translate
                }}</h4>
                <p>{{
                  data.descTranslationKey.module.code.toLowerCase() +
                    '.' +
                    data.descTranslationKey.key | translate
                }}</p>
              </div>
              <div *ngIf="formGroup.isView">
                <div class="row">
                  <div class="col-sm-8">
                    <h4>{{
                      data.nameTranslationKey.module.code.toLowerCase() +
                        '.' +
                        data.nameTranslationKey.key | translate
                    }}</h4>
                    <p>{{
                      data.descTranslationKey.module.code.toLowerCase() +
                        '.' +
                        data.descTranslationKey.key | translate
                    }}</p>
                  </div>
                  <div class="col-sm-4 text-right">
                    <app-button
                      *ngIf="
                        priceEvaluationMethod &&
                        priceEvaluationMethod.code !==
                          global.appConstant.pm
                            .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE &&
                        priceEvaluationMethod.code !==
                          global.appConstant.pm
                            .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION
                      "
                      (onClick)="doViewDetail()"
                      [disabled]="formSaving"
                      >{{
                        'price-evaluation.button.viewDetail' | translate
                      }}</app-button
                    >
                  </div>
                </div>
              </div>
            </ng-template>
          </app-select>
        </app-card>
        <app-card
          *ngIf="
            !formLoading &&
            (isContinue || priceEvaluationResponse.isHasFinal) &&
            priceEvaluationMethod &&
            ((priceEvaluationMethod.code !==
              global.appConstant.pm
                .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE &&
              priceEvaluationMethod.code !==
                global.appConstant.pm
                  .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION) ||
              ((priceEvaluationMethod.code ===
                global.appConstant.pm
                  .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE ||
                priceEvaluationMethod.code ===
                  global.appConstant.pm
                    .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION) &&
                procurementWorklistStatusCode ===
                  global.appConstant.pm
                    .PROCUREMENT_WORKLIST_STATUS_REVISION)) &&
            procurement.biddingSubmissionType.code ===
              global.appConstant.pm.BIDDING_SUBMISSION_TYPE_TOTAL_PRICE &&
            priceEvaluationResponse.procurement.procurementWorklistStatus
              .code !== global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT
          "
          header="price-evaluation.detail.finalPriceEvaluation.title"
          tips="price-evaluation.detail.finalPriceEvaluation.tips"
        >
          <app-table-xx
            [model]="tableResponseFinalPriceEval"
            *ngIf="!formLoading"
            stringUrl="/price-evaluation/table-final-price-evaluation"
          >
          </app-table-xx>
        </app-card>
        <app-card
          *ngIf="
            !formLoading &&
            (isContinue || priceEvaluationResponse.isHasFinal) &&
            priceEvaluationMethod &&
            ((priceEvaluationMethod.code !==
              global.appConstant.pm
                .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE &&
              priceEvaluationMethod.code !==
                global.appConstant.pm
                  .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION) ||
              ((priceEvaluationMethod.code ===
                global.appConstant.pm
                  .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE ||
                priceEvaluationMethod.code ===
                  global.appConstant.pm
                    .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION) &&
                procurementWorklistStatusCode ===
                  global.appConstant.pm
                    .PROCUREMENT_WORKLIST_STATUS_REVISION)) &&
            procurement.biddingSubmissionType.code ===
              global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
          "
          header="price-evaluation.detail.finalPriceEvaluation.title"
          tips="price-evaluation.detail.finalPriceEvaluation.tips"
        >
          <div
            class="no-data-wrapper"
            *ngIf="
              priceEvaluationResponse.procurement.procurementWorklistStatus
                .code !==
                global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DONE &&
              !priceEvaluationResponse.isHasFinal
            "
          >
            <div class="book-wrapper pvc pv-file-list"></div>
            <div class="text-no-data">
              <span class="text-no-data">
                {{ 'price-evaluation-vendor.noPriceEvalMethod' | translate }}
              </span>
            </div>
          </div>
          <app-tab
            [bordered]="true"
            *ngIf="
              !formLoading &&
              (priceEvaluationResponse.procurement.procurementWorklistStatus
                .code ===
                global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DONE ||
                priceEvaluationResponse.isHasFinal)
            "
          >
            <app-tab-item
              header="{{ 'price-evaluation.tab.header.byItem' | translate }}"
            >
              <app-table-xx
                [model]="tableResponseFinalQuotationItem"
                *ngIf="!formLoading"
                stringUrl="/price-evaluation/table-final-quotation-item"
                (onClick)="doClickFinalQuotationItem($event)"
              ></app-table-xx>
            </app-tab-item>
            <app-tab-item
              header="{{ 'price-evaluation.tab.header.byVendor' | translate }}"
            >
              <app-table-xx
                [model]="tableResponseFinalQuotationVendor"
                *ngIf="!formLoading"
                stringUrl="/price-evaluation/table-quotation-vendor"
                (onClick)="doClickFinalQuotationVendor($event)"
              ></app-table-xx>
            </app-tab-item>
          </app-tab>
        </app-card>
        <app-card
          *ngIf="
            !formLoading &&
            isContinue &&
            ((priceEvaluationResponse.isHasFinal &&
              priceEvaluationMethod &&
              priceEvaluationMethod.code !==
                global.appConstant.pm
                  .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE &&
              priceEvaluationMethod.code !==
                global.appConstant.pm
                  .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION &&
              priceEvaluationResponse.procurementAuction &&
              priceEvaluationResponse.procurementAuction.isEnded) ||
              (priceEvaluationMethod &&
                priceEvaluationMethod.code ===
                  global.appConstant.pm
                    .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE) ||
              (priceEvaluationMethod &&
                priceEvaluationMethod.code ===
                  global.appConstant.pm
                    .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION))
          "
          header="price-evaluation.detail.officialReport.title"
          tips="price-evaluation.detail.officialReport.tips"
        >
          <app-alert-x
            color="WARNING"
            *ngIf="
              !isOfficialReportValid &&
              procurementWorklistStatusCode !==
                global.appConstant.pm
                  .PROCUREMENT_WORKLIST_STATUS_WAITING_APPROVAL &&
              !priceEvaluationResponse.isUserApproval
            "
            >{{
              'price-evaluation.detail.officialReport.alertInfo' | translate
            }}</app-alert-x
          >
          <app-official-report
            [objectId]="procurementId"
            [templateCode]="
              procurement?.biddingSubmissionType.code ===
              global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
                ? procurement?.biddingEvaluation.code ===
                  global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
                  ? global.appConstant.pm
                      .OFFICIAL_REPORT_CODE_ORT_PRICE_EVALUATION_MERIT_ITEM_LETTER
                  : global.appConstant.pm
                      .OFFICIAL_REPORT_CODE_ORT_PRICE_EVALUATION_NON_MERIT_ITEM_LETTER
                : procurement?.biddingEvaluation.code ===
                  global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
                ? global.appConstant.pm
                    .OFFICIAL_REPORT_CODE_ORT_PRICE_EVALUATION_MERIT_BULK_LETTER
                : global.appConstant.pm
                    .OFFICIAL_REPORT_CODE_ORT_PRICE_EVALUATION_NON_MERIT_BULK_LETTER
            "
            [appOfficialReportModel]="appOfficialReportModel"
            [mode]="todo"
            (onChange)="doOnChange($event)"
            numberGeneratorCode="NUMBER"
          ></app-official-report>
        </app-card>

        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            ((todo === 'view' &&
              priceEvaluationResponse &&
              priceEvaluationResponse.approvalPathResponseModel &&
              !priceEvaluationResponse.isUserApproval) ||
              procurementWorklistStatusCode ===
                global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION)
          "
          [workflowPrcs]="procurement?.workflowPrcs"
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="
            priceEvaluationResponse.approvalPathResponseModel
          "
        >
        </app-approval-monitoring>

        <div
          class="floating-button mt-5"
          *ngIf="
            !formLoading &&
            !priceEvaluationResponse.isUserApproval &&
            todo !== 'view' &&
            ((priceEvaluationResponse.isHasFinal &&
              priceEvaluationMethod &&
              priceEvaluationMethod.code !==
                global.appConstant.pm
                  .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE &&
              priceEvaluationMethod.code !==
                global.appConstant.pm
                  .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION &&
              priceEvaluationResponse.procurementAuction &&
              priceEvaluationResponse.procurementAuction.isEnded) ||
              (priceEvaluationMethod &&
                priceEvaluationMethod.code ===
                  global.appConstant.pm
                    .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE) ||
              (priceEvaluationMethod &&
                priceEvaluationMethod.code ===
                  global.appConstant.pm
                    .PRICE_EVALUATION_METHOD_DEAL_WITH_THIS_PRICE_AUCTION) ||
              !isContinue ||
              procurementWorklistStatusCode ===
                global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION)
          "
        >
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center" *ngIf="isContinue">
                <app-button
                  [outline]="true"
                  (onClick)="doSave()"
                  [disabled]="formSaving"
                  >{{ 'app.button.save' | translate }}</app-button
                >
                <app-button (onClick)="doSubmit()" [disabled]="formSaving">{{
                  'app.button.submit' | translate
                }}</app-button>
              </div>
              <div class="button-group button-group-center" *ngIf="!isContinue">
                <app-button (onClick)="doContinue()" [disabled]="formSaving">{{
                  'app.button.continue' | translate
                }}</app-button>
              </div>
            </div>
          </div>
        </div>
        <app-approval-prcs-x
          *ngIf="!formLoading && priceEvaluationResponse.isUserApproval"
          [workflowModelPrcs]="procurement?.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
