import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { VendorExperienceHistory } from '../../core/bean/vendor-experience-history';
import { VendorExperienceSegmentationHistory } from './../../core/bean/vendor-experience-segmentation-history';
@Component({
  templateUrl: './app-popup-tab-experience.component.html'
})
export class AppPopupTabExperienceComponent extends BaseComponentComponent {
  @Input() vendorExperienceHistory: VendorExperienceHistory;
  public segmentationList: string;
  public convertContract: string;

  constructor(public currency: CurrencyPipe) {
    super('app-popup-tab-experience');
  }

  public onInit(): void {
    this.setSegementaion();
    this.convertContractValue();
    this.setStateReady();
  }

  public convertContractValue(): void {
    if (this.vendorExperienceHistory.contractValue) {
      const contractValue = this.global.converterService.uConvertMoney(
        this.vendorExperienceHistory.contractValue.toString()
      );
      this.convertContract =
        this.global.converterService.convertMoney(contractValue) +
        ' ' +
        this.vendorExperienceHistory.currency.code;
    }
  }

  public setSegementaion(): void {
    if (
      this.vendorExperienceHistory.vendorExperienceSegmentationHistoryList
        .length > 0
    ) {
      const experienceSegmentation = this.vendorExperienceHistory.vendorExperienceSegmentationHistoryList
        .map((i: VendorExperienceSegmentationHistory) => i.segmentation.name)
        .join(', ');
      this.segmentationList = experienceSegmentation.trim();
    } else {
      this.segmentationList = null;
    }
  }
}
