<div class="promise-loading" *ngIf="formLoading">
  <div class="loading"></div>
</div>
<form class="form-horizontal" *ngIf="!formLoading">
  <h5 class="separator">{{
    'app-procurement-info.separator.generalInformation' | translate
  }}</h5>
  <div class="form-group row text-sm-left">
    <label class="col-sm-3">{{
      'app-procurement-info.form.procurementNumber' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{ procurement.code ? procurement.code : '-' }}
    </div>
  </div>
  <div class="form-group row text-sm-left">
    <label class="col-sm-3">{{
      'app-procurement-info.form.procurementName' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{ procurement.name ? procurement.name : '-' }}
    </div>
  </div>
  <div class="form-group row text-sm-left">
    <label class="col-sm-3">{{
      'app-procurement-info.form.procurementDate' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{
        procurement.procurementDate
          | date: global.appConstant.core.FORMAT_SHORT_DATE
      }}
    </div>
  </div>
  <div class="form-group row text-sm-left">
    <label class="col-sm-3">{{
      'app-procurement-info.form.procurementType' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{
        procurement.procurementType.name
          ? procurement.procurementType.name
          : '-'
      }}
    </div>
  </div>
  <div class="form-group row text-sm-left">
    <label class="col-sm-3">{{
      'app-procurement-info.form.transactionType' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{
        procurement.procurementMethod?.transactionType
          ? procurement.procurementMethod?.transactionType?.name
          : '-'
      }}
    </div>
  </div>
  <div class="form-group row text-sm-left">
    <label class="col-sm-3">{{
      'app-procurement-info.form.initiationType' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{
        procurement.initiationType.name
          ? procurement.initiationType.name
          : '-'
      }}
    </div>
  </div>
  <div class="form-group row text-sm-left">
    <label class="col-sm-3">{{
      'app-procurement-info.form.procurementMethod' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{
        procurement.procurementMethod ? procurement.procurementMethod.name : '-'
      }}
    </div>
  </div>
  <h5
    class="separator"
    *ngIf="
      !procurementMatrix ||
      procurement?.procurementMethod?.procurementRegType?.code ===
        global.appConstant.pm.PROC_REG_TYPE_ANNOUNCEMENT ||
      procurementMatrix.documentSubmissionType ||
      procurementMatrix.negotiationType ||
      procurementMatrix.priceEvaluationType ||
      procurementMatrix.biddingEvaluation ||
      procurementMatrix.biddingSubmissionType ||
      procurementMatrix.vendorSelectionType ||
      procurementMatrix.currencyType ||
      procurementMatrix.isPq
    "
    >{{ 'app-procurement-info.separator.evaluationMethod' | translate }}</h5
  >
  <div
    class="form-group row text-sm-left"
    *ngIf="
      procurement?.procurementMethod?.procurementRegType?.code ===
      global.appConstant.pm.PROC_REG_TYPE_ANNOUNCEMENT
    "
  >
    <label class="col-sm-3">{{
      'app-procurement-info.form.announcementNumber' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{
        procurement.announcementNumber ? procurement.announcementNumber : '-'
      }}
    </div>
  </div>
  <div
    class="form-group row text-sm-left"
    *ngIf="!procurementMatrix || procurementMatrix.documentSubmissionType"
  >
    <label class="col-sm-3">{{
      'app-procurement-info.form.documentSubmissionType' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{
        procurement.documentSubmissionType
          ? procurement.documentSubmissionType.name
          : '-'
      }}
    </div>
  </div>
  <div
    class="form-group row text-sm-left"
    *ngIf="!procurementMatrix || procurementMatrix.negotiationType"
  >
    <label class="col-sm-3">{{
      'app-procurement-info.form.negotiationType' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{ procurement.negotiationType ? procurement.negotiationType.name : '-' }}
    </div>
  </div>
  <div
    class="form-group row text-sm-left"
    *ngIf="!procurementMatrix || procurementMatrix.priceEvaluationType"
  >
    <label class="col-sm-3">{{
      'app-procurement-info.form.priceEvaluationType' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{
        procurement.priceEvaluationType
          ? procurement.priceEvaluationType.name
          : '-'
      }}
    </div>
  </div>
  <div
    class="form-group row text-sm-left"
    *ngIf="!procurementMatrix || procurementMatrix.biddingEvaluation"
  >
    <label class="col-sm-3">{{
      'app-procurement-info.form.biddingEvaluation' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{
        procurement.biddingEvaluation ? procurement.biddingEvaluation.name : '-'
      }}
    </div>
  </div>
  <div
    class="form-group row text-sm-left"
    *ngIf="!procurementMatrix || procurementMatrix.biddingSubmissionType"
  >
    <label class="col-sm-3">{{
      'app-procurement-info.form.biddingSubmissionType' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{
        procurement.biddingSubmissionType
          ? procurement.biddingSubmissionType.name
          : '-'
      }}
    </div>
  </div>
  <div
    class="form-group row text-sm-left"
    *ngIf="!procurementMatrix || procurementMatrix.vendorSelectionType"
  >
    <label class="col-sm-3">{{
      'app-procurement-info.form.vendorSelectionType' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{
        procurement.vendorSelectionType
          ? procurement.vendorSelectionType.name
          : '-'
      }}
    </div>
  </div>
  <div
    class="form-group row text-sm-left"
    *ngIf="!procurementMatrix || procurementMatrix.currencyType"
  >
    <label class="col-sm-3">{{
      'app-procurement-info.form.currencyType' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{ procurement.currencyType ? procurement.currencyType.name : '-' }}
    </div>
  </div>
  <div
    class="form-group row text-sm-left"
    *ngIf="!procurementMatrix || procurementMatrix.isPq"
  >
    <label class="col-sm-3">{{
      'app-procurement-info.form.isPraQualification' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{
        procurement.isPq
          ? global.translateService.instant(
              'app-procurement-info.isPraQualification.yes'
            )
          : global.translateService.instant(
              'app-procurement-info.isPraQualification.no'
            )
      }}
    </div>
  </div>
  <div class="form-group row text-sm-left" *ngIf="procurement?.isPq">
    <label class="col-sm-3">{{
      'app-procurement-info.form.praQualificationItem' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      <app-table-xx
        [model]="tableResponse"
        [isShowSearch]="false"
        [isShowPagination]="false"
      >
      </app-table-xx>
    </div>
  </div>
  <div class="form-group row text-sm-left">
    <label class="col-sm-3">{{
      'app-procurement-info.form.description' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{ procurement.description ? procurement.description : '-' }}
    </div>
  </div>
</form>
