import { BaseEntity } from '../base/base-entity';
import { OrderItem } from '../bean/order-item';
import { OrderShipping } from '../bean/order-shipping';

export class OrderShippingItemEntity extends BaseEntity {
  orderItem: OrderItem;
  orderShipping: OrderShipping;
  quantity: number;
  remainingQuantity: number;
}
