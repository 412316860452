import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { LoginLogComponent } from './login-log.component';
const routes: Routes = [
  { path: '', component: LoginLogComponent, data: { breadcrumb: '' } }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [LoginLogComponent]
})
export class LoginLogModule { }
