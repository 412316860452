import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';

@Component({
  templateUrl: './submit-quotation-vendor-popup-select-item.component.html'
})
export class SubmitQuotationVendorPopupSelectItemComponent extends BaseModuleComponent {
  @Input() procurementItemList: ProcurementItem[] = [];
  @Input() procurementItemSelect: ProcurementItem[] = [];
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  public tableResponse: TableResponseModel<ProcurementItem>;

  constructor(
    translateService: TranslateService,
    public currency: CurrencyPipe
  ) {
    super('submit-quotation-vendor', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setPrItem();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'prItem.prItemImageList',
        header: 'table.column.thumbnail',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePluginData: TablePluginData): File => {
              if (tablePluginData.value && tablePluginData.value.length > 0) {
                return tablePluginData.value[0].file;
              }
              return null;
            }
          },
          {
            name: 'img',
            classNameMap: {
              MATERIAL: 'pvr pv-box',
              JASA: 'pvr pv-tools'
            },
            fieldClassName: 'prItem.item.itemType.code'
          }
        ],
        isSortable: false,
        isSearchTable: false
      },
      {
        field: 'prItem.pr.requiredDate',
        header: 'table.column.requiredDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'prItem.item.name',
        header: 'table.column.itemName'
      },
      {
        field: 'prItem.item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'prItem.item.itemType.code'
        }
      },
      {
        field: 'prItem.quantity',
        header: 'table.column.quantity',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'prItem.item.uom.name',
        header: 'table.column.uom',
        className: 'text-center'
      }
    ]);
  }

  public setPrItem(): void {
    this.tableResponse.setRecordList(this.procurementItemList);
    if (this.procurementItemSelect && this.procurementItemSelect.length !== 0) {
      this.tableResponse.setSelectedRowList(this.procurementItemSelect);
    }
  }

  public doChoose(): void {
    // this.setBudgetList();
    if (this.tableResponse.selectedRow.selectedRowList.length !== 0) {
      this.onChange.emit(
        this.tableResponse.selectedRow.getSelectedRecordList()
      );
    } else {
      this.global.alertService.showError(
        'submit-quotation-vendor.validation.selectItem',
        '.popup-select-item'
      );
    }
  }
}
