<app-popup header="app-committee.popup.chooseCommittee" [isLoading]="formLoading">
  <form class="form-horizontal popup-committee" [formGroup]="formGroup" novalidate>
    <app-select
      [optionList]="committeeOptionList"
      [isMultiple]="false"
      [search]="true"
      formControlName="committee"
    >
    </app-select>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button color="LIGHT" (onClick)="activeModal.close(true)">{{
          'app.button.cancel' | translate }}</app-button>
        <app-button color="PRIMARY" (onClick)="doSubmit()">{{ 'app.button.choose'
          | translate }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>