import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import {
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { UserPreference } from '../../bean/user-preference';
import { ValueType } from '../../bean/value-type';
import { Response } from '../../model/response-model';
import { ResponseStatusModel } from '../../model/response-status-model';
import { AppPopupEditTableComponent } from '../app-popup/app-popup-edit-table/app-popup-edit-table.component';
import { AppPopupService } from '../app-popup/app-popup.service';
import { AppTableConstant } from './app-table-constant';
import { TablePageModel } from './model/table-page-model';
import { TableRequestModel } from './model/table-request-model';
import { TableResponseModel } from './model/table-response-model';
import { AppTableCheckBoxService } from './services/app-table-checkbox.service';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollY: true
};
@Component({
  selector: 'app-table',
  templateUrl: './app-table.component.html',
  styleUrls: ['./app-table.component.scss'],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppTableComponent extends BaseComponentComponent {
  @Input() model: TableResponseModel<any>;
  @Input() tableResponse: TableResponseModel<any>; // temporary: Should be deleted - old version
  @Input() stringUrl: string;
  @Input() customData: any; // temporary: Should be deleted - old version
  @Input() requestTable: TableRequestModel = new TableRequestModel(); // temporary: Should be deleted - old version
  @Input() isServerSide: boolean; // temporary: Should be deleted - old version
  @Input() isShowEditTable: boolean;
  @Input() key: string;

  @Input() isHidePagination: boolean;
  @Input() isHideShowFilter: boolean;

  @ContentChild('headerButtons') headerButtonsTmpl: TemplateRef<any>;
  @ContentChild('actionButtons') actionButtonsTmpl: TemplateRef<any>;
  @ContentChild('status') statusTmpl: TemplateRef<any>;
  @ContentChild('headerFilterTable') headerFilterTableTmpl: TemplateRef<any>;
  @ContentChild('actionHeader') actionHeaderTmpl: TemplateRef<any>;
  @ContentChild('tableFooter') tableFooterTmpl: TemplateRef<any>;
  @ContentChild('body') bodyTmpl: TemplateRef<any>;
  @ContentChild('thead') theadTmpl: TemplateRef<any>;
  @ContentChild('tbody') tbodyTmpl: TemplateRef<any>;
  @ContentChild('row') rowTmpl: TemplateRef<any>;

  @Output() onChange: EventEmitter<any> = new EventEmitter();
  readonly SEARCH_INTERVAL = 500; // 0.5s
  timeOut: any;
  isOldVersion: boolean;
  userPreference: UserPreference;
  public columnFieldList: Array<string> = new Array();
  private modelTemp: TableResponseModel<any>;

  public appTableConstant: AppTableConstant = new AppTableConstant();

  constructor(
    public appTableCheckBoxService: AppTableCheckBoxService,
    public appPopupService: AppPopupService
  ) {
    super('app-table');
  }

  public onInit(): void {
    this.modelTemp = Object.assign({}, this.model);
    this.doReset();
    this.doSetTableModel();
    this.reloadChangesListener();
    this.appTableCheckBoxListener();
    this.doSetEditTable();
    this.reloadTable();
  }

  public doSetEditTable(): void {
    this.userPreference = new UserPreference();
    if (this.isShowEditTable) {
      const name =
        'DATA-TABLE-' +
        this.model.moduleCode.toUpperCase() +
        (this.key ? '-' + this.key.toUpperCase() : '');
      this.userPreference.value =
        this.global.userSession.userPreferenceMap[name];
      if (this.userPreference.value) {
        this.columnFieldList = JSON.parse(this.userPreference.value);
        this.doSetTableColumn();
      }
    }
  }

  private doSetTableColumn(): void {
    this.model.columns = this.modelTemp.columns.filter(
      column => this.columnFieldList.indexOf(column.field) !== -1
    );
  }

  public doReset(): void {
    this.appTableCheckBoxService.checkedItems.splice(0);
  }

  public reloadClient(): void {
    this.model.reloadClient();
  }

  public reload(): void {
    this.model.reload();
  }

  public doSetTableModel(): void {
    // for handle old version only
    if (!this.model && this.tableResponse) {
      this.isOldVersion = true;
      this.model = this.tableResponse;
    }
  }

  public reloadTable(): void {
    if (this.stringUrl) {
      this.reload();
    } else {
      if (!this.model.isWaitingFromServer) {
        this.reloadClient();
      }
    }
  }

  public onInputSearch(searchText: string): void {
    this.model.setSearchText(searchText);
    if (this.stringUrl) {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.reloadTable();
      }, this.SEARCH_INTERVAL);
    } else {
      this.reloadTable();
    }
  }

  public onChangeFilter(perPage: number): void {
    this.model.setPerPage(perPage);
    this.reloadTable();
  }

  public onChangePage(currentPage: number): void {
    this.model.setCurrentpage(currentPage);
    this.reloadTable();
  }

  public doSort(sortField: string): void {
    this.model.setSortField(sortField);
    this.reloadTable();
  }

  // handle old version of app-table: 'customData' and 'requestTable.customData'
  public doSetCustomData(): void {
    if (this.isOldVersion) {
      if (this.customData !== this.model.request.customData) {
        this.model.setCustomData(this.customData);
      }
      this.doSetCustomDataFromRequestTable();
      this.requestTable = this.model.request;
    }
  }

  // handle old version of app-table: 'customData' and 'requestTable.customData'
  public doSetCustomDataFromRequestTable(): void {
    const { customData } = this.requestTable;
    if (customData && customData !== this.model.request.customData) {
      this.model.setCustomData(customData);
    }
  }

  public reloadChangesListener(): void {
    this.model.reloadChanges.subscribe(() => {
      this.model.setStateLoading();
      if (this.stringUrl) {
        this.doSetCustomData();
        this.httpClientService
          .post<TablePageModel<Object>>(this.stringUrl, this.model.request)
          .subscribe((page: TablePageModel<Object>) => {
            this.model.page.setRecords(page.records);
            this.model.setTotalRecords(page.totalRecords);
            this.model.setStateReady();
          });
      } else {
        setTimeout(() => this.model.setStateReady());
      }
    });
  }

  public appTableCheckBoxListener(): void {
    this.appTableCheckBoxService.onChange.subscribe((tableChecked: any) => {
      this.onChange.emit(tableChecked);
    });
  }

  public doShowPopupEditTable(): void {
    this.appPopupService
      .open(
        AppPopupEditTableComponent,
        {
          tableParentResponse: this.modelTemp,
          columnFieldList: this.columnFieldList
        },
        { windowClass: 'app-popup-modify-table' }
      )
      .subscribe((columnFieldList: Array<any>) => {
        this.columnFieldList = columnFieldList;
        this.doSetTableColumn();
        const url = '/config/update-user-preference';
        const userPreference = this.userPreference;
        userPreference.name =
          'DATA-TABLE-' +
          this.model.moduleCode.toUpperCase() +
          (this.key ? '-' + this.key.toUpperCase() : '');
        userPreference.value = JSON.stringify(columnFieldList);
        userPreference.valueType = new ValueType();
        userPreference.valueType.code = 'STRING';
        this.httpClientService
          .post<Response<UserPreference>>(url, userPreference)
          .subscribe(response => {
            if (response.status === ResponseStatusModel.OK) {
              this.global.userSession.userPreferenceMap[userPreference.name] =
                userPreference.value;
              this.userPreference.value = userPreference.value;
            } else {
              this.model.columns = this.modelTemp.columns;
            }
          });
      });
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
