export class PickItemEvent {
  currentId: number;
  previousDate: number;
  constructor(public timeRange: number = 1000) {
    this.currentId = -1;
    this.previousDate = Date.now();
  }

  public get isPick(): boolean {
    const currentDate = Date.now();
    const isPick = currentDate - this.previousDate < this.timeRange;
    this.previousDate = isPick ? currentDate - 2001 : currentDate;
    return isPick;
  }
}
