<!-- <app-widget
  *ngIf="!model.isLoading"
  [header]="moduleCode + '.title'"
  class="app-widget-vendor-information"
>
  <p *ngIf="vendorInformationResponse == null">{{
    'app-widget-top-vendor-performance.sorryYourRoleCannotUseThisWidget'
      | translate
  }}</p>
  <app-widget-content-slider
    [isShowControl]="isShowSliderControl"
    *ngIf="vendorInformationResponse != null"
  >
    <app-widget-card
      *ngIf="isPerformanceShow"
      header="{{ moduleCode + '.performanceOverall' | translate }}"
    >
      <app-rating
        [value]="vendorInformationResponse.vendorPerformance"
        [isViewOnly]="true"
      ></app-rating>
      <div class="widget-card-footer mt-2">
        <p>{{ '' || '.' }}</p>
      </div>
    </app-widget-card>
    <app-widget-card
      *ngIf="isDocumentShow"
      header="{{ moduleCode + '.docWillExp' | translate }}"
    >
      <h4>{{ vendorInformationResponse.numberOfExpiredDoc }}</h4>
      <div class="widget-card-footer mt-2">
        <p
          class="m-0 cursor-pointer more-detail"
          (click)="goToMoreDetail(vendorId)"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        >
          {{ moduleCode + '.moreDetail' | translate }}
          <em class="fas fa-chevron-circle-right"></em>
        </p>
      </div>
    </app-widget-card>
    <app-widget-card
      *ngIf="isRfiShow"
      header="{{ moduleCode + '.rfiRequest' | translate }}"
    >
      <h4>{{ vendorInformationResponse.numberOfRfi }}</h4>
      <div class="widget-card-footer mt-2">
        <p>{{ '' || '.' }}</p>
      </div>
    </app-widget-card>
  </app-widget-content-slider>
</app-widget> -->

<dashboard-widget-card
  size="SM"
  customClassHeader="border-bottom-0 pb-0"
  [isLoading]="model.isLoading"
>
  <p *ngIf="vendorInformationResponse == null">{{
    'app-widget-top-vendor-performance.sorryYourRoleCannotUseThisWidget'
      | translate
  }}</p>
  <app-content-slider
    [isLoading]="model.isLoading"
    customClass="mb-0 mt-3"
    [header]="moduleCode + '.title'"
    *ngIf="vendorInformationResponse != null"
  >
    <app-content-slider-item minWidth="200px" *ngIf="isPerformanceShow">
      <div
        class="
          d-flex
          flex-column
          justify-content-between
          h-100
          p-3
          align-items-center
        "
      >
        <div class="d-block">
          <h5 class="text-center mb-3" maxLine>
            {{ moduleCode + '.performanceOverall' | translate }}
          </h5>
        </div>
        <app-rating
          [value]="vendorInformationResponse.vendorPerformance"
          [isViewOnly]="true"
        ></app-rating>
        <div class="widget-card-footer mt-2">
          <p>{{ '' || '.' }}</p>
        </div>
      </div>
    </app-content-slider-item>
    <app-content-slider-item
      minWidth="200px"
      *ngIf="isDocumentShow"
      (onClick)="goToMoreDetail(vendorId)"
    >
      <div
        class="
          d-flex
          flex-column
          justify-content-between
          h-100
          p-3
          align-items-center
        "
      >
        <div class="d-block">
          <h5 class="text-center mb-2" maxLine>
            {{ moduleCode + '.docWillExp' | translate }}
          </h5>
        </div>
        <h4>{{ vendorInformationResponse.numberOfExpiredDoc }}</h4>
        <div class="widget-card-footer mt-2">
          <p
            class="mb-0 text-secondary text-hover-underline more-detail"
            (click)="goToMoreDetail(vendorId)"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
          >
            {{ moduleCode + '.moreDetail' | translate }}
            <!-- <em class="fas fa-chevron-circle-right"></em> -->
          </p>
        </div>
      </div>
    </app-content-slider-item>
    <app-content-slider-item minWidth="200px" *ngIf="isRfiShow">
      <div
        class="
          d-flex
          flex-column
          justify-content-between
          h-100
          p-3
          align-items-center
        "
      >
        <div class="d-block">
          <h5 class="text-center mb-2" maxLine>
            {{ moduleCode + '.rfiRequest' | translate }}
          </h5>
        </div>
        <h4>{{ vendorInformationResponse.numberOfRfi }}</h4>
        <div class="widget-card-footer mt-2">
          <p>{{ '' || '.' }}</p>
        </div>
      </div>
    </app-content-slider-item>
  </app-content-slider>
</dashboard-widget-card>
