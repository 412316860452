import { NgModule } from '@angular/core';
import { ThemeBreadcrumbModule } from '../components/breadcrumb/breadcrumb.module';
import { FooterModule } from '../components/footer/footer.module';
import { HeaderModule } from '../components/header/header.module';
import { SidebarLeftModule } from '../components/sidebar-left/sidebar-left.module';
import { SidebarRightModule } from '../components/sidebar-right/sidebar-right.module';
import { TipsModule } from '../components/tips/tips.module';
import { PromiseThemeVendorModule } from '../vendor/promise-theme-vendor.module';
@NgModule({
  exports: [
    PromiseThemeVendorModule,
    HeaderModule,
    SidebarLeftModule,
    SidebarRightModule,
    FooterModule,
    ThemeBreadcrumbModule,
    TipsModule
  ]
})
export class PromiseThemeSharedModule {}
