import { CroppedPoints } from '../interface/cropped-points';
import { UploaderFile } from '../model/uploader-file';

export const ADD_FILE = `[ UPLOAD ]: Add File`;
export const CANCEL_UPLOAD = `[ UPLOAD ]: Cancel Upload File`;
export const RE_UPLOAD = `[ UPLOAD ]: Re-Upload File`;
export const CHANGE_FILE = `[ UPLOAD ]: Change File`;
export const DELETE_FILE = `[ UPLOAD ]: Delete File`;
export const BULK_DELETE_FILE = `[ UPLOAD ]: Bulk Delete File`;
export const RESET = `[ UPLOAD ]: Reset Upload`;

export class AddFile implements Action {
  readonly type = ADD_FILE;
  constructor(
    public payload: {
      fileList: FileList;
      croppedPoints?: CroppedPoints;
    }
  ) {}
}

export class CancelUpload implements Action {
  readonly type = CANCEL_UPLOAD;
  constructor(public payload: { uploaderFile: UploaderFile }) {}
}

export class ReUpload implements Action {
  readonly type = RE_UPLOAD;
  constructor(public payload: { uploaderFile: UploaderFile }) {}
}

export class ChangeFile implements Action {
  readonly type = CHANGE_FILE;
  constructor(public payload: { fileList: FileList; index: number }) {}
}

export class DeleteFile implements Action {
  readonly type = DELETE_FILE;
  constructor(public payload: { index: number }) {}
}

export class BulkDeleteFile implements Action {
  readonly type = BULK_DELETE_FILE;
}

export class Reset implements Action {
  readonly type = RESET;
}

interface Action {
  type: any;
  payload?: any;
}

export type All =
  | AddFile
  | CancelUpload
  | ReUpload
  | ChangeFile
  | DeleteFile
  | BulkDeleteFile
  | Reset;
