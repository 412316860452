import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { UserRole } from '../bean/user-role';
import { Lang } from './../bean/lang';

export class UserEntity extends BaseEntity {
  roleText: string;
  status: any;
  userRoleList: UserRole[];
  validUserRoleList: UserRole[];
  imgFile: File;
  lang: Lang;
  name: string;
  email: string;
  password: string;
  isActive: boolean;
  isLocked: boolean;
  loginAttempt: number;
  proposeValue: string;
  proposeType: string;
  proposeToken: string;
  proposeDate: string;
  isDelegation: boolean;
  nip: string;
}
