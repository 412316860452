<div class="pages-lost-connection">
  <!-- <img src="src/assets/svg/pv-sattelite-antenna.svg" /> -->
  <div
    class="pvc pv-sattelite-antenna"
    style="margin: 0 auto; width: 281.84px; height: 300px; margin-bottom: 38px"
  ></div>
  <!--src/assets/svg/pv-sattelite-antenna.svg-->
  <h2 class="card-title">{{
    this.global.routerParams.get('lostConnectionTitle')
  }}</h2>
  <h3 class="card-text text-secondary"
    >{{ this.global.routerParams.get('lostConnectionBody') }}
  </h3>
</div>
