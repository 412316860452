<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="response-rfi.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [tableResponse]="tableResponse"
        stringUrl="/response-rfi/index"
        [customData]="isFromWidget ? '1' : '0'"
      >
        <ng-template #actionButtons let-rowData>
          <div *ngIf="rowData.isSubmitted === true">
            <app-button
              mode="BYPASS"
              size="TN"
              (onClick)="doEdit(rowData)"
              title="{{ 'app.tooltip.view' | translate }}"
            >
              <em class="fas fa-search"></em>
            </app-button>
          </div>
          <div *ngIf="rowData.isSubmitted === false">
            <app-button
              size="TN"
              (onClick)="doEdit(rowData)"
              title="{{ 'app.tooltip.edit' | translate }}"
            >
              <em class="fas fa-pencil-alt"></em>
            </app-button>
          </div>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
