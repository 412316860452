import { NgModule } from '@angular/core';
import { AppButtonModule } from '../../../../../core/components/app-button/app-button.module';
import { AppChartModule } from '../../../../../core/components/app-chart/app-chart.module';
import { AppDropdownSelectModule } from '../../../../../core/components/app-dropdown-select/app-dropdown-select.module';
import { PipesModule } from '../../../../../core/pipe/pipe.module';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetBudgetIndicatorComponent } from './widget-budget-indicator.component';

@NgModule({
  imports: [
    VendorModule,
    WidgetCardModule,
    AppChartModule,
    AppButtonModule,
    AppDropdownSelectModule,
    PipesModule
  ],
  declarations: [WidgetBudgetIndicatorComponent],
  entryComponents: [WidgetBudgetIndicatorComponent],
  exports: [WidgetBudgetIndicatorComponent]
})
export class WidgetBudgetIndicatorModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetBudgetIndicatorComponent.moduleCode,
      WidgetBudgetIndicatorComponent,
      'w-50'
    );
  }
}
