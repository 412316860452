import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupTabBlacklistHistoryComponent } from './app-popup-tab-blacklist-history.component';
import { TabBlacklistHistoryComponent } from './tab-blacklist-history.component';
@NgModule({
  imports: [SharedModule],
  declarations: [
    TabBlacklistHistoryComponent,
    AppPopupTabBlacklistHistoryComponent
  ],
  entryComponents: [
    TabBlacklistHistoryComponent,
    AppPopupTabBlacklistHistoryComponent
  ],
  exports: [TabBlacklistHistoryComponent, AppPopupTabBlacklistHistoryComponent]
})
export class TabBlacklistHistoryModule {
  constructor(accordionService: AccordionService) {
    accordionService.register(
      'tab-blacklist-history',
      TabBlacklistHistoryComponent
    );
  }
}
