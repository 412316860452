<promise-portal-layout type="magazine">
  <ng-container
    *ngTemplateOutlet="formLoading ? loadingTemplate : contentTemplate"
  >
  </ng-container>

  <ng-template #loadingTemplate>
    <div
      class="promise-container d-flex align-items-center justify-content-center"
      style="min-height: 95vh"
    >
      <div class="promise-loading">
        <div class="loading"></div>
      </div>
    </div>
  </ng-template>

  <ng-template #contentTemplate>
    <div class="portal-home">
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div
            *ngFor="let data of homeSlideList; let i = index"
            [ngClass]="i === 0 ? 'carousel-item active' : 'carousel-item'"
          >
            <img
              class="d-block img-fluid w-100"
              *ngIf="data.imgFile !== null"
              [src]="
                global.config.BACKEND_ADDRESS +
                  '/home-slide/file/view/' +
                  data.imgFile.uploadedFileName +
                  '/' +
                  data.imgFile.fileType.code
                  | resolveImgSrc
                  | async
              "
              alt="First slide"
              [disabled]="true"
            />
            <div class="carousel-content">
              <h2
                [ngStyle]="{
                  'white-space': 'normal',
                  'max-width': '800px',
                  'word-break': 'break-word'
                }"
                [innerHTML]="data.title"
              >
              </h2>
              <p
                [ngStyle]="{
                  'white-space': 'normal',
                  'max-width': '800px',
                  'word-break': 'break-word'
                }"
                [innerHTML]="data.body"
                [maxLine]="2"
              ></p>
              <app-button (onClick)="doReadMore(data)">
                {{ 'app.button.readMore' | translate }}
              </app-button>
            </div>
          </div>
        </div>
        <div class="carousel-footer">
          <a href="#carouselExampleIndicators" role="button" data-slide="prev">
            <em class="pir pi-chevron-left"></em>
          </a>
          <a href="#carouselExampleIndicators" role="button" data-slide="next">
            <em class="pir pi-chevron-right"></em>
          </a>
        </div>
      </div>
      <!--<div class="promise-container p-0 bg-white">
        <app-card header="Procurement Announcement">
          <app-procurement-announcement
            [isShowPagination]="false"
            [modelList]="modelList"
          >
            <ng-template #footer let-rowList>
              <div
                class="button-group button-group-center"
                *ngIf="rowList.length > 0"
              >
                <app-button size="LG" (onClick)="doViewMoreAnnouncement()">
                  {{ 'procurement-announcement.button.viewMoreAnnouncement' }}
                </app-button>
              </div>
            </ng-template>
          </app-procurement-announcement>
        </app-card>
      </div>-->
      <div class="ph-footer">
        <div class="promise-container">
          <div class="phf-content">
            <h1 class="phf-title">More Information</h1>
            <div class="phf-card-list">
              <div
                class="phf-card"
                *ngFor="let item of cardItemList"
                (click)="doNavigate(item.routerUrl)"
              >
                <div [ngClass]="item.vector"></div>
                <div class="phfc-body">
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.text }}</p>
                </div>
                <div class="phfc-footer">
                  <span>Read More</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</promise-portal-layout>
