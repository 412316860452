import { Component } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';

@Component({
  templateUrl: './app-widget-work-plan-spend.component.html'
})
export class AppWidgetWorkPlanSpendComponent extends BaseComponentComponent {
  public isSyncRunning = false;

  constructor() {
    super('app-widget-work-plan-spend');
  }

  onInit(): void {
    this.global.widgetLoadingStatus.set(this.moduleCode, true);
    this.setStateReady();
  }

}
