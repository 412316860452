<app-popup
  header="vendor-designation.popup.title.winningVendor"
  [isLoading]="formLoading"
>
  <app-alert-x color="WARNING" *ngIf="!isMustFullFill && isThingsToConsider">{{
    'vendor-designation.popup.alertInfo.thingsToConsider' | translate
  }}</app-alert-x>
  <app-alert-x color="DANGER" *ngIf="isMustFullFill">{{
    'vendor-designation.popup.alertInfo.mustBeFullfilled' | translate
  }}</app-alert-x>
  <app-table-xx
    [model]="tableResponse"
    *ngIf="!formLoading"
    [isShowCheckBox]="true"
    [isMultipleSelect]="false"
  >
  </app-table-xx>
  <ng-container>
    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button
          color="PRIMARY"
          (onClick)="doSave()"
          [disabled]="formLoading"
          >{{ 'app.button.save' | translate }}</app-button
        >
      </div>
    </ng-template>
  </ng-container>
</app-popup>
