import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Currency } from '../../core/bean/currency';
import { AppPopupImportService } from '../../core/components/app-popup/app-popup-import/app-popup-import.service';
import { ImportFileWebsocketService } from '../../core/components/app-popup/app-popup-import/import-file-websocket.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: 'currency.component.html'
})
export class CurrencyComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Currency>;
  constructor(
    translateService: TranslateService,
    public appPopupImportService: AppPopupImportService,
    public importFileWebsocketService: ImportFileWebsocketService
  ) {
    super('currency', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code'
      },
      {
        field: 'name',
        header: 'table.column.name'
      },
      {
        field: 'isDefault',
        header: 'table.column.isDefault',
        customClass: 'text-center',
        datamap: '{"false" : "No" , "true" : "Yes" }'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/currency/add']);
  }

  public doEdit(currency: Currency): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('currencyId', currency.id);
    this.router.navigate(['/pages/currency/edit']);
  }

  public doDelete(currency: Currency): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<Currency>>('/currency/delete', currency)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  public doImport(): void {
    this.appPopupImportService
      .open('/currency/import-excel')
      .subscribe(result => {
        if (result) {
          this.tableResponse.reload();
        }
      });
    this.importFileWebsocketService.appPopupImportComponent =
      this.appPopupImportService.appPopupService.componentInstance;
  }
}
