<app-layout [isLoading]="formLoading">
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge-catalog
      class="cml-2"
      *ngIf="procurement.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge
      *ngIf="
        todo !== 'add' &&
        oepStatus &&
        oepStatus?.code === global.appConstant.pm.OEP_STATUS_WAITING_APPROVAL
      "
      class="cml-2"
      color="WARNING"
    >
      {{
        'dynamic-master-attribute.' + oepStatus?.translationKey.key | translate
      }}
    </app-badge>
    <app-badge *ngIf="todo === 'add'" class="cml-2" color="INFO">
      {{
        'dynamic-master-attribute.' +
          (oepStatus
            ? oepStatus?.translationKey.key
            : procurement?.procurementWorklistStatus.translationKey.key)
          | translate
      }}
    </app-badge>
    <app-badge
      *ngIf="
        todo !== 'add' &&
        oepStatus &&
        oepStatus?.code === global.appConstant.pm.OEP_STATUS_REVISION
      "
      class="cml-2"
      color="FEEDBACK"
    >
      {{
        'dynamic-master-attribute.' + oepStatus?.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      *ngIf="
        todo !== 'add' &&
        oepStatus &&
        oepStatus?.code === global.appConstant.pm.OEP_STATUS_APPROVED
      "
      class="cml-2"
      color="SUCCESS"
    >
      {{
        'dynamic-master-attribute.' + oepStatus?.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      *ngIf="
        todo !== 'add' &&
        oepStatus &&
        oepStatus?.code === global.appConstant.pm.OEP_STATUS_NEW
      "
      class="cml-2"
      color="INFO"
    >
      {{
        'dynamic-master-attribute.' + oepStatus?.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      *ngIf="
        todo !== 'add' &&
        oepStatus &&
        oepStatus?.code === global.appConstant.pm.OEP_STATUS_DRAFT
      "
      class="cml-2"
      color="SECONDARY"
    >
      {{
        'dynamic-master-attribute.' + oepStatus?.translationKey.key | translate
      }}
    </app-badge>
  </ng-template>
  <div class="row main-row">
    <div class="col-lg-12">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-card
          header="oep.detail.procurementDetails.title"
          tips="oep.detail.procurementDetails.tips"
        >
          <app-procurement-info
            *ngIf="!formLoading"
            [procurementId]="procurementId"
          ></app-procurement-info>
        </app-card>

        <app-item-request
          [objectList]="oepResponse.procurementItemList"
          [isShowPrice]="true"
          [itemTypeList]="oepResponse.itemTypeList"
          [isView]="true"
          *ngIf="!formLoading"
        ></app-item-request>
        <app-card
          header="oep.detail.documentBuyer.title"
          tips="oep.detail.documentBuyer.tips"
          *ngIf="oepResponse.isUserApproval"
        >
          <app-upload-x
            formControlName="fileList"
            [fileUploader]="fileUploader"
          ></app-upload-x>
        </app-card>
        <app-card>
          <br />
          <app-table-xx
            header="oep.detail.oep.title"
            tips="oep.detail.oep.tips"
            [model]="tableResponse"
            [isShowSearch]="oepResponse.isUserApproval ? false : true"
            [isShowPagination]="false"
            *ngIf="!formLoading"
          >
            <ng-template #headerButtons>
              <app-button
                *ngIf="!formLoading && isShowButtonChange"
                (onClick)="doChangeOep()"
                title="{{ 'oep.button.change' | translate }}"
              >
                {{ 'oep.placeholder.changeOep' | translate }}
              </app-button>

              <app-button
                *ngIf="!formLoading && isShowButton"
                color="SECONDARY"
                [outline]="true"
                (onClick)="doCancelOep()"
                title="{{ 'app.button.cancel' | translate }}"
              >
                {{ 'app.button.cancel' | translate }}
              </app-button>
              <app-button
                *ngIf="!formLoading && isShowButton"
                color="LIGHT"
                [outline]="true"
                (onClick)="doSaveOep()"
                title="{{ 'app.button.save' | translate }}"
              >
                {{ 'app.button.save' | translate }}
              </app-button>
            </ng-template>
            <ng-template #row let-state>
              <tr *ngIf="state.rowList.length > 0">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ 'oep.table.column.grandTotal' | translate }}</td>
                <td class="text-right">
                  :
                  <strong
                    >{{
                      formGroup.value.amount | resolveNumberToCurrency
                    }}
                    IDR</strong
                  >
                </td>
              </tr>
            </ng-template>
          </app-table-xx>
        </app-card>

        <app-card
          header="oep.detail.document.title"
          tips="oep.detail.document.tips"
        >
          <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>
          <app-upload-x
            *ngIf="!oepResponse.isUserApproval"
            formControlName="fileList"
            [fileUploader]="fileUploader"
          ></app-upload-x>
          <app-upload-x
            *ngIf="oepResponse.isUserApproval"
            formControlName="fileApprovalList"
            [fileUploader]="fileUploader"
          ></app-upload-x>
        </app-card>
        <app-card
          header="oep.detail.changeHistory.title"
          tips="oep.detail.changeHistory.tips"
          *ngIf="
            !formLoading &&
            oepStatus &&
            oepStatus.code !== global.appConstant.pm.OEP_STATUS_NEW &&
            oepStatus.code !== global.appConstant.pm.OEP_STATUS_DRAFT
          "
        >
          <app-table-xx
            [model]="tableResponseChangeHistory"
            stringUrl="/oep/get-oep-history"
            *ngIf="!formLoading"
          >
          </app-table-xx>
        </app-card>
        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            oepResponse?.approvalPathResponseModel &&
            oepStatus?.code !== global.appConstant.pm.OEP_STATUS_DRAFT &&
            oepStatus?.code !== global.appConstant.pm.OEP_STATUS_NEW &&
            !oepResponse.isUserApproval
          "
          [workflowPrcs]="procurement.workflowPrcs"
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="oepResponse?.approvalPathResponseModel"
        ></app-approval-monitoring>
        <div class="floating-button mt-5" *ngIf="!oepResponse.isUserApproval">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center">
                <app-button
                  *ngIf="!formGroup.isView"
                  color="PRIMARY"
                  [outline]="true"
                  (onClick)="doSave()"
                  [disabled]="formSaving || getValidateButton()"
                  >{{ 'app.button.save' | translate }}
                </app-button>

                <app-button
                  (onClick)="doSubmit()"
                  [disabled]="formSaving || getValidateButton()"
                  *ngIf="!formGroup.isView"
                  >{{ 'app.button.submit' | translate }}</app-button
                >
              </div>
            </div>
          </div>
        </div>
        <app-approval-prcs-x
          *ngIf="!formLoading && oepResponse.isUserApproval"
          [workflowModelPrcs]="oepResponse.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
          (approveFn)="approveFn($event)"
          [moduleFormGroup]="formGroup"
        >
        </app-approval-prcs-x>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips *ngIf="!formLoading"></app-tips>
  </ng-template>
</app-layout>
