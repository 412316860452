import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  Optional,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { fromEvent, merge } from 'rxjs';
import { pairwise, switchMap, takeUntil } from 'rxjs/operators';
import * as uploadAction from '../../../core/components/upload/actions/upload-file.action';
import { BaseValueAccessorComponent } from '../../base/angular/base-value-accessor.component';
import {
  makeUploaderProvider,
  Uploader,
  UploadMapper,
  UploadService
} from '../upload';
import { SignatureModel } from './signature.model';

@Component({
  selector: 'app-signature',
  templateUrl: './app-signature.component.html',
  styleUrls: ['./app-signature.component.scss'],
  providers: [...makeUploaderProvider(AppSignatureComponent)],
  encapsulation: ViewEncapsulation.None
})
export class AppSignatureComponent
  extends BaseValueAccessorComponent<any>
  implements AfterViewInit
{
  @Input() public model: SignatureModel;
  @Input() public customWidth: number;
  @ViewChild('canvas') public canvas: ElementRef;

  public uploader: Uploader = new Uploader(
    '/app-signature/',
    this.global.appConstant.fileType.IMG_SIGNATURE
  );
  public widthCavas = 448;
  public heightCavas = 134;
  public color = '#454545';
  public lineWidth = 2;
  public canvasEl: HTMLCanvasElement;
  public imageOn = false;
  public urlSrc: string;
  public isDraw = true;
  private cx: CanvasRenderingContext2D;
  private dataTransfer = new DataTransfer();

  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public uploadService: UploadService
  ) {
    super('app-signature', controlContainer, elementRef);
  }

  public onInitBaseValueAccessor(): void {
    this.uploadService
      .setUploader(this.uploader, this.formControl)
      .subscribe((uploader: Uploader) => {
        this.formControl.patchValue(UploadMapper.toValues(uploader));
        const valueList = [];
        valueList.push(this.formControl.value[0]);
        this.onChange.emit(valueList);
        this.model.uploadChanges.emit();
      });
    this.requestUploadListener();
  }

  public requestUploadListener(): void {
    this.model.requestUploadChanges.subscribe(() => {
      this.urlSrc = this.canvasEl.toDataURL();
      const byteString = atob(this.urlSrc.split(',')[1]);

      // separate out the mime component
      const mimeString = this.urlSrc.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to an ArrayBuffer
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }

      const dataView = new DataView(arrayBuffer);
      const blob = new Blob([dataView], { type: mimeString });
      const file = new File([blob], 'signature.png', { type: 'image/png' });
      this.dataTransfer.items.add(file);
      this.uploader.totalUploaded > 0
        ? this.uploadService.dispatch(
            new uploadAction.ChangeFile({
              fileList: this.dataTransfer.files,
              index: 0
            })
          )
        : this.uploadService.dispatch(
            new uploadAction.AddFile({ fileList: this.dataTransfer.files })
          );
    });
  }

  public ngAfterViewInit(): void {
    this.canvasEl = this.canvas.nativeElement;
    this.cx = this.canvasEl.getContext('2d');
    this.cx.fillStyle = '#FFFFFF';
    this.cx.fillRect(
      0,
      0,
      this.customWidth || this.widthCavas,
      this.heightCavas
    );
    this.captureEvents(this.canvasEl);
    if (this.ISVIEW) {
    }
    this.setIsBlank();
    this.setStateReady();
  }

  private captureEvents(canvasEl: HTMLCanvasElement): void {
    const mouseDownEvent = fromEvent(canvasEl, 'mousedown');
    const touchStartEvent = fromEvent(canvasEl, 'touchstart');
    const mouseMoveEvent = fromEvent(canvasEl, 'mousemove');
    const touchMoveEvent = fromEvent(canvasEl, 'touchmove');
    const mouseUpEvent = fromEvent(canvasEl, 'mouseup');
    const touchEndEvent = fromEvent(canvasEl, 'touchend');
    const mouseLaeveEvent = fromEvent(canvasEl, 'mouseleave');
    const touchCancelEvent = fromEvent(canvasEl, 'touchcancel');
    merge(mouseDownEvent, touchStartEvent)
      .pipe(
        switchMap((e: MouseEvent | TouchEvent) => {
          e.preventDefault();
          return merge(mouseMoveEvent, touchMoveEvent).pipe(
            takeUntil(merge(mouseUpEvent, touchEndEvent)),
            takeUntil(merge(mouseLaeveEvent, touchCancelEvent)),
            pairwise()
          );
        })
      )
      .subscribe((res: [MouseEvent | TouchEvent, MouseEvent | TouchEvent]) => {
        const rect = canvasEl.getBoundingClientRect();
        const prevPos = {
          x:
            ((res[0] as MouseEvent).clientX ||
              (res[0] as TouchEvent).touches.item(0).clientX) - rect.left,
          y:
            ((res[0] as MouseEvent).clientY ||
              (res[0] as TouchEvent).touches.item(0).clientY) - rect.top
        };

        const currentPos = {
          x:
            ((res[1] as MouseEvent).clientX ||
              (res[1] as TouchEvent).touches.item(0).clientX) - rect.left,
          y:
            ((res[1] as MouseEvent).clientY ||
              (res[1] as TouchEvent).touches.item(0).clientY) - rect.top
        };

        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  private drawOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ): void {
    if (!this.cx) {
      return;
    }

    this.cx.beginPath();
    this.cx.lineWidth = this.lineWidth;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = this.color;

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
    }

    this.setIsBlank();
  }

  public setIsBlank(): void {
    const pixelBuffer = new Uint32Array(
      this.cx.getImageData(
        0,
        0,
        this.canvasEl.width,
        this.canvasEl.height
      ).data.buffer
    );

    /** check if there's no pixel other than pure white */
    const isBlank = !pixelBuffer.find(
      buffer => !buffer.toString(16).includes('ffffff')
    );

    this.onChange.emit({
      isBlank
    });

    this.log.debug('isBlank ' + isBlank);
  }

  @HostListener('mouseup' || 'mouseleave' || 'touchend' || 'touchcancel')
  onMouseDown(): void {
    this.urlSrc = this.canvasEl.toDataURL();
  }

  public doErase(): void {
    this.color = '#FFFFFF';
    this.lineWidth = 10;
    this.isDraw = false;
  }

  public doDraw(): void {
    this.color = '#454545';
    this.lineWidth = 2;
    this.isDraw = true;
  }
}
