import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { WorkPlan } from '../../core/bean/work-plan';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './worklist-pra-procurement.component.html'
})
export class WorklistPraProcurementComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public tableResponse: TableResponseModel<WorkPlan>;
  public module: string;
  public currentPosition: string;
  public customData = '';
  constructor(translateService: TranslateService) {
    super('worklist-pra-procurement', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      organizationName: [null],
      organizationId: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'number', header: 'table.column.number', customClass: 'text-center', fn: this.generateNumber.bind(this) },
      { field: 'name', header: 'table.column.name' },
      {
        field: 'createdDate',
        header: 'table.column.createdDate',
        format: FieldFormatEnum.ShortDate
      },
      { field: 'organization.name', header: 'table.column.organization', customClass: 'text-center' },
      { field: 'workflowModelPrcs.name', header: 'table.column.stage', customClass: 'text-center' },
      {
        field: 'workflowPrcs.workflowStatus.name',
        header: 'table.column.status',
        customClass: 'text-center'
      }
    ]);
  }

  public generateNumber(number: string, workPlan: WorkPlan): string {
    return ( workPlan.generateMemoNumber ? number + workPlan.generateMemoNumber : number);
  }

  doEdit(workPlan: WorkPlan): void {
    const prcsCode = workPlan.prcsCode;
    this.global.routerParams.clear();
    this.global.routerParams.set('workPlan', workPlan);
    this.global.routerParams.set('workPlanId', workPlan.id);
    if (
      workPlan.workflowPrcs !== null &&
      workPlan.workflowPrcs.workflowStatus.code ===
        this.global.appConstant.core.WORKFLOW_CODE_STATUS_REVISION
    ) {
      this.global.routerParams.set('todo', 'edit');
      this.router.navigate([
        '/pages/' + prcsCode.replace('APPROVAL-', '').toLowerCase() + '/edit'
      ]);
    } else {
      this.global.routerParams.set('todo', 'add');
      this.router.navigate([
        '/pages/' + prcsCode.replace('APPROVAL-', '').toLowerCase() + '/add'
      ]);
    }
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/worklist-pra-procurement'
    );
  }

  doView(workPlan: WorkPlan): void {
    const prcsCode = workPlan.prcsCode;
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('workPlan', workPlan);
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/worklist-pra-procurement'
    );
    this.router.navigate([
      '/pages/' + prcsCode.replace('APPROVAL-', '').toLowerCase() + '/detail'
    ]);
  }
  doClick(prcsCode): void {
    if (prcsCode === null) {
      prcsCode = '';
    }
    this.tableResponse.setCustomData(prcsCode);
    this.currentPosition = prcsCode;
    this.tableResponse.reload();
  }
}
