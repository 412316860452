<app-popup header="Description">
  <h4
    [ngStyle]="{
    'white-space': 'normal',
    'max-width': '800px',
    'word-break': 'break-word' }"
    [innerHTML]="data.title">
  </h4><br>
  <p
    readMore
    [ngStyle]="{
    'white-space': 'normal',
    'max-width': '800px',
    'word-break': 'break-word' }"
    [innerHTML]="data.body">
  </p>
</app-popup>