import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppBadgeCatalogModule } from 'src/app/core/components/app-badge-catalog/app-badge-catalog.module';
import { AppApprovalPrcsModule } from '../../../core/components/app-approval-prcs/app-approval-prcs.module';
import { ConfirmGuard } from '../../../core/guard/confirm.guard';
import { SharedModule } from '../../../core/shared/shared.module';
import { EmployeeEditAddComponent } from './employee-edit-add.component';
import { EmployeePopupComponent } from './employee-popup.component';
import { EmployeeComponent } from './employee.component';
export const routes = [
  { path: '', component: EmployeeComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: EmployeeEditAddComponent,
    data: { breadcrumb: 'employee.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: EmployeeEditAddComponent,
    data: { breadcrumb: 'employee.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), AppApprovalPrcsModule, AppBadgeCatalogModule],
  declarations: [
    EmployeeComponent,
    EmployeeEditAddComponent,
    EmployeePopupComponent
  ],
  entryComponents: [EmployeePopupComponent],
  providers: []
})
export class EmployeeModule {}
