import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../../../directives/directives.module';
import { AppButtonModule } from '../../../app-button/app-button.module';
import { UploadSharedModule } from '../../shared/upload-shared.module';
import { AppUploadMultipleComponent } from './app-upload-multiple.component';
@NgModule({
  imports: [UploadSharedModule, AppButtonModule, DirectivesModule],
  declarations: [AppUploadMultipleComponent],
  exports: [AppUploadMultipleComponent]
})
export class AppUploadMultipleModule {}
