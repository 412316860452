import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Position } from '../../core/bean/position';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './position-edit-add.component.html'
})
export class PositionEditAddComponent extends BaseModuleComponent {
  public positionId: number;
  constructor(translateService: TranslateService) {
    super('position', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.positionId = this.global.routerParams.get('positionId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required()])
      ]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<Position>(
        '/position/add-edit',
        new RouteRequestModel(this.todo, this.positionId)
      )
      .subscribe(position => {
        if (position != null) {
          this.formGroup.patchValue(position);
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const position: Position = this.global.copyFormAttributeToModel(
              new Position(),
              this.formGroup
            );
            const url =
              this.todo === 'edit' ? '/position/update' : '/position/insert';
            this.httpClientService
              .post<Response<Position>>(url, position)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/position/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/position/']);
  }
}
