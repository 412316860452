import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppPopupPaymentHistoryPrintComponent } from './app-popup-payment-history-print.component';
import { PaymentView } from 'src/app/core/view/entity/bean/payment-view';

@Component({
    templateUrl: './payment-history-detail.component.html'
})
export class PaymentHistoryDetailComponent
    extends BaseModuleComponent
    implements OnInit {
    public paymentId: number;
    public paymentInvoiceId: number;
    public paymentVoucherId: number;
    public paymentView: PaymentView;

    constructor(
        translateService: TranslateService,
        public appPopupService: AppPopupService
    ) {
        super('payment-history', translateService);
    }

    public onInit(): void {
        this.setDataFromRouterParams();
        this.setStateReady();
    }

    public setDataFromRouterParams(): void {
        this.todo = this.global.routerParams.get('todo');
        this.paymentId = this.global.routerParams.get('paymentId');
        this.paymentInvoiceId = this.global.routerParams.get('paymentInvoiceId');
        this.paymentVoucherId = this.global.routerParams.get('paymentVoucherId');
        this.paymentView = this.global.routerParams.get(
            'paymentView'
        );
    }
    public doOpenPopupPrint(): void {
        this.appPopupService.open(AppPopupPaymentHistoryPrintComponent, {
            paymentId: this.paymentId
        });
    }
    public doCancel(): void {
        this.router.navigate(['/pages/payment-history']);
    }
}
