import { Component } from '@angular/core';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetContractStatusResponse } from './widget-contract-status.response';
@Component({
  templateUrl: './widget-contract-status.component.html'
})
export class WidgetContractStatusComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-contract-status';
  public totalContract: number;
  public contractDataList: Array<any>;

  constructor() {
    super(WidgetContractStatusComponent.moduleCode);
  }

  protected onInit(): void {
    this.httpClientService
      .get<WidgetContractStatusResponse>('/widget-contract-status/index')
      .subscribe((response: WidgetContractStatusResponse) => {
        if (response.totalContract) {
          this.totalContract = response.totalContract;
        }
        this.contractDataList = Object.keys(response)
          .map((key: string) => {
            if (key != 'totalContract') {
              const strKey = key.slice(5);
              return {
                value: response[key],
                translationKey:
                  this.moduleCode +
                  '.' +
                  strKey.charAt(0).toLowerCase() +
                  strKey.slice(1)
              };
            }
          })
          .filter(val => val);
        this.setStateReady();
      });
  }
}
