import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AppPopupUserService } from 'src/app/core/components/app-popup/app-popup-user/app-popup-user.service';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { DueDiligenceExecutor } from '../../../core/bean/due-diligence-executor';
import { DueDiligenceSchedule } from '../../../core/bean/due-diligence-schedule';
import { DueDiligenceTemplate } from '../../../core/bean/due-diligence-template';
import { Officer } from '../../../core/bean/officer';
import { User } from '../../../core/bean/user';
import { Vendor } from '../../../core/bean/vendor';
import { VendorHistory } from '../../../core/bean/vendor-history';
import { OptionListModel } from '../../../core/model/option-list-model';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { RouteRequestModel } from '../../../core/model/route-request-model';
import { ReportService } from '../../../core/services/report.service';
import { Validators } from '../../../core/validators';
import { OnBoardingDueDiligenceTemplateResponse } from '../response/on-boarding-due-diligence-template.response';
import { DueDiligenceRequest } from './due-diligence.request';
import { DueDiligenceResponse } from './due-diligence.response';

@Component({
  templateUrl: './due-diligence.component.html'
})
export class DueDiligenceComponent extends BaseModuleComponent {
  public dueDiligenceResponse: DueDiligenceResponse =
    new DueDiligenceResponse();
  public dueDiligenceResponseRouteParam: DueDiligenceResponse = null;
  public today: Date = new Date();
  public vendorId: number;
  public vendorHistory: VendorHistory = new VendorHistory();
  public onBoardingDueDiligenceTemplateResponse: OnBoardingDueDiligenceTemplateResponse =
    new OnBoardingDueDiligenceTemplateResponse();
  public isDisabled: boolean;
  public showScheduleOTS = false;
  public showScheduleInterview = false;
  public userIdListTaskgiver: number[] = [];
  public userIdListAcknowledged: number[] = [];
  public dueDiligenceTemplateOptionList: OptionListModel<DueDiligenceTemplate> =
    new OptionListModel();
  public isOTS = false;
  public isInterview = false;
  public isApproval: boolean;
  public userIdExecutorOTSList: number[] = [];
  public userIdExecutorInterviewList: number[] = [];
  public isLoadingOTS: boolean;
  public isLoadingInterview: boolean;
  public isLoadingTooltip = false;
  public dueDiligenceRequest: DueDiligenceRequest = new DueDiligenceRequest();
  public vendorHistoryId: number;

  constructor(
    translateService: TranslateService,
    public appPopupUserService: AppPopupUserService,
    public reportService: ReportService
  ) {
    super('due-diligence', translateService);
  }

  onInit(): void {
    this.vendorHistoryId = this.global.routerParams.get('vendorHistoryId');
    this.dueDiligenceResponseRouteParam = this.global.routerParams.get(
      'dueDiligenceResponse'
    );
    this.getAndSetOnBoardingToFormGroup();
  }

  public setIsViewToDueDiligenceTemplate(): void {
    this.formGroup.get('dueDiligenceTemplate').setIsView(true);
  }
  public setMinDate(): void {
    if (
      this.isOTS &&
      this.formGroup.get('dateOTS').value &&
      this.formGroup.get('dateOTS').value < new Date()
    ) {
      this.formGroup.patchValue({
        currentDateOTS: this.formGroup.get('dateOTS').value
      });
    }

    if (
      this.isInterview &&
      this.formGroup.get('dateInterview').value &&
      this.formGroup.get('dateInterview').value < new Date()
    ) {
      this.formGroup.patchValue({
        currentDateInterview: this.formGroup.get('dateInterview').value
      });
    }
  }

  public buildFormGroup(): void {
    let formGroup: any = {
      isDueDiligence: [null],
      dueDiligenceTemplate: [null],
      credentials: this.formBuilder.array([]),
      credentialsAcknowledged: this.formBuilder.array([]),
      currentDateOTS: new Date(),
      currentDateInterview: new Date(),
      isSendEmailDueDiligence: [false]
    };
    const ots = {
      addressOTS: [null, Validators.required()],
      dateOTS: [null, Validators.required()],
      startTimeOTS: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.max('endTimeOTS')
        ])
      ],
      endTimeOTS: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.min('startTimeOTS')
        ])
      ],
      objectivesOTS: [null, Validators.required()],
      credentialsOTS: this.formBuilder.array([])
    };

    const interview = {
      addressInterview: [null, Validators.required()],
      dateInterview: [null, Validators.required()],
      startTimeInterview: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.max('endTimeInterview')
        ])
      ],
      endTimeInterview: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.min('startTimeInterview')
        ])
      ],
      objectivesInterview: [null, Validators.required()],
      credentialsInterview: this.formBuilder.array([])
    };
    if (
      this.dueDiligenceResponse.vendorVerification.dueDiligenceTemplate.dueDiligence
        .toUpperCase()
        .includes('OTS') &&
      this.dueDiligenceResponse.vendorVerification.dueDiligenceTemplate.dueDiligence
        .toUpperCase()
        .includes('INTERVIEW')
    ) {
      formGroup = { ...formGroup, ...ots, ...interview };
      this.isOTS = true;
      this.isInterview = true;
    } else if (
      this.dueDiligenceResponse.vendorVerification.dueDiligenceTemplate.dueDiligence
        .toUpperCase()
        .includes('OTS')
    ) {
      formGroup = { ...formGroup, ...ots };
      this.isOTS = true;
    } else if (
      this.dueDiligenceResponse.vendorVerification.dueDiligenceTemplate.dueDiligence
        .toUpperCase()
        .includes('INTERVIEW')
    ) {
      formGroup = { ...formGroup, ...interview };
      this.isInterview = true;
    }

    this.formGroup = this.formBuilder.group(formGroup);
  }

  public getAndSetOnBoardingToFormGroup(): void {
    if (this.dueDiligenceResponseRouteParam) {
      this.setStateLoading();
      this.dueDiligenceResponse = this.dueDiligenceResponseRouteParam;
      this.buildAndSetFormGroup();
      this.setStateReady();
    } else {
      this.httpClientService
        .get<DueDiligenceResponse>(
          '/due-diligence/index/' + this.vendorHistoryId
        )
        .subscribe((dueDiligenceResponse: DueDiligenceResponse) => {
          this.dueDiligenceResponse = dueDiligenceResponse;
          this.buildAndSetFormGroup();
          this.global.routerParams.set(
            'dueDiligenceResponse',
            this.dueDiligenceResponse
          );
          this.setStateReady();
        });
    }
  }

  public buildAndSetFormGroup(): void {
    this.buildFormGroup();
    this.setOnBoardingToFormGroup(this.dueDiligenceResponse);
    this.setIsViewToDueDiligenceTemplate();
    this.setMinDate();
  }

  public setOnBoardingToFormGroup(
    dueDiligenceResponse: DueDiligenceResponse
  ): void {
    const templateList: DueDiligenceTemplate[] = [];
    templateList.push(
      this.dueDiligenceResponse.vendorVerification.dueDiligenceTemplate
    );

    this.dueDiligenceTemplateOptionList.setRequestValues(templateList);
    if (dueDiligenceResponse.vendorHistory !== null) {
      this.dueDiligenceResponse = dueDiligenceResponse;

      this.formGroup.patchValue({
        dueDiligenceTemplate:
          this.dueDiligenceResponse.vendorVerification.dueDiligenceTemplate
      });

      if (
        this.dueDiligenceResponse.vendorVerification.dueDiligenceTemplate.dueDiligence
          .toUpperCase()
          .includes('OTS')
      ) {
        if (this.dueDiligenceResponse.dueDiligenceScheduleOTS !== null) {
          this.formGroup.patchValue({
            addressOTS:
              this.dueDiligenceResponse.dueDiligenceScheduleOTS.address,
            dateOTS: new Date(
              this.dueDiligenceResponse.dueDiligenceScheduleOTS.date
            ),
            startTimeOTS: new Date(
              this.dueDiligenceResponse.dueDiligenceScheduleOTS.startTime
            ),
            endTimeOTS: new Date(
              this.dueDiligenceResponse.dueDiligenceScheduleOTS.endTime
            ),
            objectivesOTS:
              this.dueDiligenceResponse.dueDiligenceScheduleOTS.objective
          });
          this.setCredsOTS(this.dueDiligenceResponse);
        } else {
          this.formGroup.patchValue({
            addressOTS: this.dueDiligenceResponse.vendorAddress.address
          });
          this.addCredsOTS();
        }
      }

      if (
        this.dueDiligenceResponse.vendorVerification.dueDiligenceTemplate.dueDiligence
          .toUpperCase()
          .includes('INTERVIEW')
      ) {
        if (this.dueDiligenceResponse.dueDiligenceScheduleInterview !== null) {
          this.formGroup.patchValue({
            addressInterview:
              this.dueDiligenceResponse.dueDiligenceScheduleInterview.address,
            dateInterview: new Date(
              this.dueDiligenceResponse.dueDiligenceScheduleInterview.date
            ),
            startTimeInterview: new Date(
              this.dueDiligenceResponse.dueDiligenceScheduleInterview.startTime
            ),
            endTimeInterview: new Date(
              this.dueDiligenceResponse.dueDiligenceScheduleInterview.endTime
            ),
            objectivesInterview:
              this.dueDiligenceResponse.dueDiligenceScheduleInterview.objective
          });
          this.setCredsInterview(this.dueDiligenceResponse);
        } else {
          this.formGroup.patchValue({
            addressInterview: this.dueDiligenceResponse.vendorAddress.address
          });
          this.addCredsInterview();
        }
      }

      if (
        this.dueDiligenceResponse.vendorVerification.dueDiligenceTemplate.dueDiligence
          .toUpperCase()
          .includes('OTS') &&
        this.dueDiligenceResponse.vendorVerification.dueDiligenceTemplate.dueDiligence
          .toUpperCase()
          .includes('INTERVIEW')
      ) {
        this.showScheduleOTS = true;
        this.showScheduleInterview = true;
      } else if (
        this.dueDiligenceResponse.vendorVerification.dueDiligenceTemplate.dueDiligence
          .toUpperCase()
          .includes('OTS')
      ) {
        this.showScheduleOTS = true;
      } else if (
        this.dueDiligenceResponse.vendorVerification.dueDiligenceTemplate.dueDiligence
          .toUpperCase()
          .includes('INTERVIEW')
      ) {
        this.showScheduleInterview = true;
      }

      if (
        this.dueDiligenceResponse.dueDiligenceAssignerTaskGiverList.length !== 0
      ) {
        this.setCredsTaskgiver(this.dueDiligenceResponse);
      } else {
        this.addCreds();
      }

      if (
        this.dueDiligenceResponse.dueDiligenceAssignerAcknowledgedByList
          .length !== 0
      ) {
        this.setCredsAcknowledged(this.dueDiligenceResponse);
      } else {
        this.addCredsAcknowledged();
      }

      this.showDueDiligenceTemplateTooltip();
    }
    this.setDueDiligenceIsView();
    this.global.routerParams['DUE_DILIGENCE'] = this.formGroup;
  }

  public setDueDiligenceIsView(): void {
    if (
      this.dueDiligenceResponse.vendorHistory.vendor.vendorActivityStatus
        .code !== 'DUE_DILIGENCE' ||
      this.dueDiligenceResponse.isUserApproval
    ) {
      this.setViewOnly();
      this.formGroup.get('dueDiligenceTemplate').setIsView(true);
    } else if (
      this.global.userSession.user.organization.id !==
      this.dueDiligenceResponse.vendorHistory.vendor.onBoardingByOrganization.id
    ) {
      this.setViewOnly();
      this.formGroup.get('dueDiligenceTemplate').setIsView(true);
    }
  }

  public showDueDiligenceTemplateTooltip(): void {
    if (this.formGroup.get('dueDiligenceTemplate').value !== null) {
      this.isLoadingTooltip = true;
      const dueDiligenceTemplateId = this.formGroup.get('dueDiligenceTemplate')
        .value.id;
      this.httpClientService
        .get<OnBoardingDueDiligenceTemplateResponse>(
          '/on-boarding/get-due-diligence-template/' + dueDiligenceTemplateId
        )
        .subscribe(
          (
            onBoardingDueDiligenceTemplateResponse: OnBoardingDueDiligenceTemplateResponse
          ) => {
            this.onBoardingDueDiligenceTemplateResponse =
              onBoardingDueDiligenceTemplateResponse; // utk table tooltip
            this.setStateReady();
            this.isLoadingTooltip = false;
          }
        );
    }
  }

  public addCreds(): void {
    this.inputList.push(
      this.formBuilder.group({
        id: [null, Validators.required()],
        name: [null, Validators.required()],
        position: [null]
      })
    );
  }

  public addCredsAcknowledged(): void {
    this.inputListAcknowledged.push(
      this.formBuilder.group({
        id: [null, Validators.required()],
        name: [null, Validators.required()],
        position: [null]
      })
    );
  }

  public addCredsOTS(): void {
    this.inputListOTS.push(
      this.formBuilder.group({
        name: [null, Validators.required()],
        user: [null],
        position: [null]
      })
    );
  }

  public addCredsInterview(): void {
    this.inputListInterview.push(
      this.formBuilder.group({
        name: [null, Validators.required()],
        user: [null],
        position: [null]
      })
    );
  }

  public setCredsTaskgiver(dueDiligenceResponse: DueDiligenceResponse): void {
    if (dueDiligenceResponse.dueDiligenceAssignerTaskGiverList !== []) {
      dueDiligenceResponse.dueDiligenceAssignerTaskGiverList.forEach(
        assigner => {
          this.inputList.push(
            this.formBuilder.group({
              id: assigner.user.id,
              name: assigner.user.name,
              position: assigner.position
            })
          );
        }
      );
    }
  }

  public setCredsAcknowledged(
    dueDiligenceResponse: DueDiligenceResponse
  ): void {
    if (dueDiligenceResponse.dueDiligenceAssignerAcknowledgedByList !== []) {
      dueDiligenceResponse.dueDiligenceAssignerAcknowledgedByList.forEach(
        assigner => {
          this.inputListAcknowledged.push(
            this.formBuilder.group({
              id: assigner.user.id,
              name: assigner.user.name,
              position: assigner.position
            })
          );
        }
      );
    }
  }

  public setCredsOTS(dueDiligenceResponse: DueDiligenceResponse): void {
    if (dueDiligenceResponse.dueDiligenceExecutorOTSList !== []) {
      dueDiligenceResponse.dueDiligenceExecutorOTSList.forEach(executor => {
        this.inputListOTS.push(
          this.formBuilder.group({
            name: executor.user ? executor.user.name : null,
            user: executor.user,
            position: executor.position
          })
        );
      });
    }
  }

  public setCredsInterview(dueDiligenceResponse: DueDiligenceResponse): void {
    if (dueDiligenceResponse.dueDiligenceExecutorInterviewList !== []) {
      dueDiligenceResponse.dueDiligenceExecutorInterviewList.forEach(
        executor => {
          this.inputListInterview.push(
            this.formBuilder.group({
              name: executor.user ? executor.user.name : null,
              user: executor.user,
              position: executor.position
            })
          );
        }
      );
    }
  }

  public get inputList(): FormArray {
    return this.formGroup.get('credentials') as FormArray;
  }

  public get inputListAcknowledged(): FormArray {
    return this.formGroup.get('credentialsAcknowledged') as FormArray;
  }

  public get inputListOTS(): FormArray {
    return this.formGroup.get('credentialsOTS') as FormArray;
  }

  public get inputListInterview(): FormArray {
    return this.formGroup.get('credentialsInterview') as FormArray;
  }

  public doRemove(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.userIdListTaskgiver.forEach((userId, indexUserId) => {
            if (userId === this.inputList.at(index).value.id) {
              this.userIdListTaskgiver.splice(indexUserId, 1);
            }
          });

          this.inputList.removeAt(index);
          this.isDisabled = false;
        }
      });
  }

  public doRemoveAcknowledged(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.userIdListAcknowledged.forEach((userId, indexUserId) => {
            if (userId === this.inputListAcknowledged.at(index).value.id) {
              this.userIdListAcknowledged.splice(indexUserId, 1);
            }
          });

          this.inputListAcknowledged.removeAt(index);
          this.isDisabled = false;
        }
      });
  }

  public doRemoveOTS(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.userIdExecutorOTSList.forEach((userId, indexUserId) => {
            if (
              this.inputListOTS.at(index).value.user &&
              userId === this.inputListOTS.at(index).value.user.id
            ) {
              this.userIdExecutorOTSList.splice(indexUserId, 1);
            }
          });

          this.inputListOTS.removeAt(index);
          this.isDisabled = false;
        }
      });
  }

  public doRemoveInterview(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.userIdExecutorInterviewList.forEach((userId, indexUserId) => {
            if (
              this.inputListInterview.at(index).value.user &&
              userId === this.inputListInterview.at(index).value.user.id
            ) {
              this.userIdExecutorInterviewList.splice(indexUserId, 1);
            }
          });

          this.inputListInterview.removeAt(index);
          this.isDisabled = false;
        }
      });
  }

  public doChooseUser(index: number): void {
    // TaskGiver
    const userIdList = this.userIdListTaskgiver;
    this.appPopupUserService
      .open(userIdList, [this.global.appConstant.ROLE_CODE_VENDOR], [this.global.appConstant.ROLE_CODE_USER_MVE])
      .subscribe(user => {
        this.userIdListTaskgiver.forEach((userId, indexUserId) => {
          if (
            this.inputList.at(index).value !== null &&
            userId === this.inputList.at(index).value.id
          ) {
            this.userIdListTaskgiver.splice(indexUserId, 1);
          }
        });
        this.getPositionByUserId(user.id, index, user.name);
        this.userIdListTaskgiver.push(user.id);
      });
  }

  public doChooseUserAcknowledged(index: number): void {
    //  ['VENDOR'], ['GH_MVE']
    const userIdList = this.userIdListAcknowledged;
    this.appPopupUserService
      .open(userIdList, [this.global.appConstant.ROLE_CODE_VENDOR], [this.global.appConstant.ROLE_CODE_GH_MVE])
      .subscribe(user => {
        this.userIdListAcknowledged.forEach((userId, indexUserId) => {
          if (
            this.inputListAcknowledged.at(index).value !== null &&
            userId === this.inputListAcknowledged.at(index).value.id
          ) {
            this.userIdListAcknowledged.splice(indexUserId, 1);
          }
        });
        this.getPositionByUserIdAcknowledged(user.id, index, user.name);
        this.userIdListAcknowledged.push(user.id);
        this.userIdListAcknowledged = this.userIdListAcknowledged.filter(
          (userId, i) => this.userIdListAcknowledged.indexOf(userId) === i
        );
      });
  }

  // Executor OTS
  public doChooseUserExecutorOTS(index: number): void {
    // ['VENDOR'], ['MVE']
    const userIdList = this.userIdExecutorOTSList;
    this.appPopupUserService
      .open(userIdList, [this.global.appConstant.ROLE_CODE_VENDOR], [this.global.appConstant.ROLE_CODE_USER_MVE])
      .subscribe(user => {
        this.userIdExecutorOTSList.forEach((userId, indexUserId) => {
          if (
            this.inputListOTS.at(index).value &&
            this.inputListOTS.at(index).value.user &&
            userId === this.inputListOTS.at(index).value.user.id
          ) {
            this.userIdExecutorOTSList.splice(indexUserId, 1);
          }
        });
        this.getPositionByUserExecutorOTS(user, index);
        this.userIdExecutorOTSList.push(user.id);
      });
  }

  // Executor Interview
  public doChooseUserExecutorInterview(index: number): void {
    const userIdList = this.userIdExecutorInterviewList;
    this.appPopupUserService
      .open(userIdList, [this.global.appConstant.ROLE_CODE_VENDOR], [this.global.appConstant.ROLE_CODE_USER_MVE])
      .subscribe(user => {
        this.userIdExecutorInterviewList.forEach((userId, indexUserId) => {
          if (
            this.inputListInterview.at(index).value &&
            this.inputListInterview.at(index).value.user &&
            userId === this.inputListInterview.at(index).value.user.id
          ) {
            this.userIdExecutorInterviewList.splice(indexUserId, 1);
          }
        });
        this.getPositionByUserExecutorInterview(user, index);
        this.userIdExecutorInterviewList.push(user.id);
      });
  }

  public getPositionByUserExecutorOTS(user: User, index: number): void {
    this.httpClientService
      .post<Officer[]>(
        '/due-diligence/get-position',
        new RouteRequestModel(this.todo, user.id)
      )
      .subscribe(officerList => {
        let positionNames = '';
        // tslint:disable-next-line: no-shadowed-variable
        officerList.forEach((officer, index) => {
          positionNames += officer.position.name;
          if (index !== officerList.length - 1) {
            positionNames += ', ';
          }
        });

        this.inputListOTS[index] = this.formBuilder.group({
          name: user.name,
          user,
          position: positionNames
        });

        this.inputListOTS.at(index).patchValue({
          name: user.name,
          user,
          position: positionNames
        });
      });
  }

  public getPositionByUserExecutorInterview(user: User, index: number): void {
    this.httpClientService
      .post<Officer[]>(
        '/due-diligence/get-position',
        new RouteRequestModel(this.todo, user.id)
      )
      .subscribe(officerList => {
        let positionNames = '';
        // tslint:disable-next-line: no-shadowed-variable
        officerList.forEach((officer, index) => {
          positionNames += officer.position.name;
          if (index !== officerList.length - 1) {
            positionNames += ', ';
          }
        });

        this.inputListInterview[index] = this.formBuilder.group({
          name: user.name,
          user,
          position: positionNames
        });

        this.inputListInterview.at(index).patchValue({
          name: user.name,
          user,
          position: positionNames
        });
      });
  }

  public getPositionByUserId(
    userId: number,
    index: number,
    userName: string
  ): void {
    this.httpClientService
      .post<Officer[]>(
        '/due-diligence/get-position',
        new RouteRequestModel(this.todo, userId)
      )
      .subscribe(officerList => {
        let positionNames = '';
        // tslint:disable-next-line: no-shadowed-variable
        officerList.forEach((officer, index) => {
          positionNames += officer.position.name;
          if (index !== officerList.length - 1) {
            positionNames += ', ';
          }
        });

        this.inputList[index] = this.formBuilder.group({
          id: userId,
          name: userName,
          position: positionNames
        });

        this.inputList.at(index).patchValue({
          id: userId,
          name: userName,
          position: positionNames
        });
        this.setStateReady();
      });
  }

  public getPositionByUserIdAcknowledged(
    userId: number,
    index: number,
    userName: string
  ): void {
    this.httpClientService
      .post<Officer[]>(
        '/due-diligence/get-position',
        new RouteRequestModel(this.todo, userId)
      )
      .subscribe(officerList => {
        let positionNames = '';
        // tslint:disable-next-line: no-shadowed-variable
        officerList.forEach((officer, index) => {
          positionNames += officer.position.name;
          if (index !== officerList.length - 1) {
            positionNames += ', ';
          }
        });

        this.inputListAcknowledged[index] = this.formBuilder.group({
          id: userId,
          name: userName,
          position: positionNames
        });

        this.inputListAcknowledged.at(index).patchValue({
          id: userId,
          name: userName,
          position: positionNames
        });
        this.setStateReady();
      });
  }

  public doPrint(dueDiligenceSchedule: DueDiligenceSchedule): void {
    this.isLoadingOTS = dueDiligenceSchedule.groupName.includes('OTS')
      ? true
      : false;
    this.isLoadingInterview = dueDiligenceSchedule.groupName.includes(
      'Interview'
    )
      ? true
      : false;
    this.loadingBlockService.showInfo('due-diligence.msg.processing');
    this.reportService
      .print('/due-diligence/print/schedule', dueDiligenceSchedule)
      .subscribe(() => {
        this.isLoadingOTS = false;
        this.isLoadingInterview = false;
        this.loadingBlockService.showCompleteAndClose(
          'due-diligence.msg.successDownload'
        );
      });
  }

  public doSaveDueDiligence(): void {
    this.validate();

    if (
      this.formGroup.get('credentials').value.length === 0 ||
      this.formGroup.get('credentialsAcknowledged').value.length === 0 ||
      (this.formGroup.get('credentialsOTS') &&
        this.formGroup.get('credentialsOTS').value.length === 0) ||
      (this.formGroup.get('credentialsInterview') &&
        this.formGroup.get('credentialsInterview').value.length === 0)
    ) {
      this.global.alertService.showError(
        this.translateService.instant('due-diligence.alert.completeData')
      );
    } else if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.formSaving = true;
            this.loadingBlockService.showInfo(`app.msg.processing`);

            if (
              this.dueDiligenceResponse.vendorHistory.vendor
                .vendorActivityStatus.code ===
              this.global.appConstant.vm.V_ACTIVITY_STATUS_DUE_DILIGENCE
            ) {
              if (
                this.formGroup.get('addressOTS') !== null &&
                this.formGroup.get('addressInterview') !== null
              ) {
                const dueDiligenceExecutorOTS: DueDiligenceExecutor[] =
                  this.formGroup.get('credentialsOTS').value;
                const dueDiligenceExecutorInterview: DueDiligenceExecutor[] =
                  this.formGroup.get('credentialsInterview').value;
                this.dueDiligenceRequest.dueDiligenceExecutorOTSList =
                  dueDiligenceExecutorOTS;
                this.dueDiligenceRequest.dueDiligenceExecutorInterviewList =
                  dueDiligenceExecutorInterview;
                this.dueDiligenceRequest.dueDiligenceScheduleOTS =
                  this.getDueDiligenceScheduleOTS();
                this.dueDiligenceRequest.dueDiligenceScheduleInterview =
                  this.getDueDiligenceScheduleInterview();
              } else if (this.formGroup.get('addressOTS') !== null) {
                const dueDiligenceExecutorOTS: DueDiligenceExecutor[] =
                  this.formGroup.get('credentialsOTS').value;
                this.dueDiligenceRequest.dueDiligenceExecutorOTSList =
                  dueDiligenceExecutorOTS;
                this.dueDiligenceRequest.dueDiligenceScheduleOTS =
                  this.getDueDiligenceScheduleOTS();
              } else if (this.formGroup.get('addressInterview') !== null) {
                const dueDiligenceExecutorInterview: DueDiligenceExecutor[] =
                  this.formGroup.get('credentialsInterview').value;
                this.dueDiligenceRequest.dueDiligenceExecutorInterviewList =
                  dueDiligenceExecutorInterview;
                this.dueDiligenceRequest.dueDiligenceScheduleInterview =
                  this.getDueDiligenceScheduleInterview();
              }

              const userIdTaskGiver = [];
              const userIdAcknowledgedBy = [];
              this.formGroup.get('credentials').value.forEach(value => {
                userIdTaskGiver.push(value.id);
              });
              this.formGroup
                .get('credentialsAcknowledged')
                .value.forEach(value => {
                  userIdAcknowledgedBy.push(value.id);
                });
              this.dueDiligenceRequest.userIdTaskGiverList = userIdTaskGiver;
              this.dueDiligenceRequest.userIdAcknowledgedByList =
                userIdAcknowledgedBy;

              this.dueDiligenceRequest.isSendEmailDueDiligence =
                this.formGroup.get('isSendEmailDueDiligence').value;
            }

            this.dueDiligenceRequest.vendorHistory =
              this.dueDiligenceResponse.vendorHistory;
            this.dueDiligenceRequest.isTemplateDifferent =
              this.dueDiligenceResponse.isTemplateDifferent;
            this.httpClientService
              .post<Response<Vendor>>(
                '/due-diligence/update',
                this.dueDiligenceRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/on-boarding']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public getDueDiligenceScheduleOTS(): DueDiligenceSchedule {
    const dueDiligenceScheduleOTS: DueDiligenceSchedule =
      new DueDiligenceSchedule();
    dueDiligenceScheduleOTS.address = this.formGroup.get('addressOTS').value;
    dueDiligenceScheduleOTS.date = this.formGroup.get('dateOTS').value;
    dueDiligenceScheduleOTS.startTime = new Date(
      this.formGroup.get('startTimeOTS').value
    );
    dueDiligenceScheduleOTS.endTime = new Date(
      this.formGroup.get('endTimeOTS').value
    );
    dueDiligenceScheduleOTS.objective =
      this.formGroup.get('objectivesOTS').value;
    dueDiligenceScheduleOTS.vendorHistory =
      this.dueDiligenceResponse.vendorHistory;
    dueDiligenceScheduleOTS.vendor =
      this.dueDiligenceResponse.vendorHistory.vendor;

    return dueDiligenceScheduleOTS;
  }

  public getDueDiligenceScheduleInterview(): DueDiligenceSchedule {
    const dueDiligenceScheduleInterview: DueDiligenceSchedule =
      new DueDiligenceSchedule();
    dueDiligenceScheduleInterview.address =
      this.formGroup.get('addressInterview').value;
    dueDiligenceScheduleInterview.date =
      this.formGroup.get('dateInterview').value;
    dueDiligenceScheduleInterview.startTime = new Date(
      this.formGroup.get('startTimeInterview').value
    );
    dueDiligenceScheduleInterview.endTime = new Date(
      this.formGroup.get('endTimeInterview').value
    );
    dueDiligenceScheduleInterview.objective = this.formGroup.get(
      'objectivesInterview'
    ).value;
    dueDiligenceScheduleInterview.vendorHistory =
      this.dueDiligenceResponse.vendorHistory;
    dueDiligenceScheduleInterview.vendor =
      this.dueDiligenceResponse.vendorHistory.vendor;

    return dueDiligenceScheduleInterview;
  }

  public doBack(): void {
    this.router.navigate(['/pages/on-boarding']);
  }

  public doBackApproval(): void {
    this.router.navigate(['/pages/approval-prcs']);
  }

  public getHours(time): string {
    return new Date(time).toLocaleTimeString('en-GB').substring(0, 5) + ' ';
  }
}
