import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { SharedModule } from '../../shared/shared.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppCatalogItemXModule } from '../app-catalog-item-x/app-catalog-item-x.module';
import { AppCheckBoxModule } from '../app-check-box/app-check-box.module';
import { AppComboBoxTreeModule } from '../app-combo-box-tree/app-combo-box-tree.module';
import { AppComboBoxModule } from '../app-combo-box/app-combo-box.module';
import { AppCurrencyModule } from '../app-currency/app-currency.module';
import { AppDropdownSelectModule } from '../app-dropdown-select/app-dropdown-select.module';
import { AppPopupModule } from '../app-popup/app-popup.module';
import { AppTextFieldModule } from '../app-text-field/app-text-field.module';
import { AppCatalogInfoComponent } from './app-catalog-info.component';
import { AppPopupCatalogInfoLocationComponent } from './popup/app-popup-catalog-info-location.component';
import { AppPopupCatalogInfoSpecificationComponent } from './popup/app-popup-catalog-info-specification.component';

@NgModule({
  imports: [
    VendorModule,
    AppComboBoxModule,
    AppCatalogItemXModule,
    AppCardModule,
    AppTextFieldModule,
    AppCheckBoxModule,
    AppCurrencyModule,
    AppComboBoxTreeModule,
    AppPopupModule,
    AppButtonModule,
    DirectivesModule,
    AppDropdownSelectModule,
    SharedModule
  ],
  declarations: [AppCatalogInfoComponent, AppPopupCatalogInfoLocationComponent, AppPopupCatalogInfoSpecificationComponent],
  entryComponents: [
    AppCatalogInfoComponent,
    AppPopupCatalogInfoLocationComponent,
    AppPopupCatalogInfoSpecificationComponent
  ],
  exports: [AppCatalogInfoComponent, AppPopupCatalogInfoLocationComponent, AppPopupCatalogInfoSpecificationComponent]
})
export class AppCatalogInfoModule {}
