<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="address-book.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'address-book.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              formControlName="code"
              type="code"
              maxLength="32"
            ></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'address-book.form.organization' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-choose
              formControlName="organizationName"
              (onClick)="doChooseOrganization($event)"
            ></app-choose>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label"
            >{{ 'address-book.form.addressName' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="address" maxLength="32">
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'address-book.form.location' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-choose
              formControlName="regionName"
              (onClick)="doChooseLocation($event)"
            ></app-choose>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'address-book.form.user' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-choose
              formControlName="userViewList"
              [isMultiple]="true"
              optionView="name"
              (onClick)="doChooseUser($event)"
            ></app-choose>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label"
            >{{ 'address-book.form.phone' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-field
              formControlName="phone"
              type="integer-left"
              maxLength="32"
            ></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'address-book.form.detailAddress' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="addressDetail" maxLength="512">
            </app-text-area>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">{{
            'app.button.save' | translate
          }}</app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
