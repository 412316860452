import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { TabModel } from 'src/app/core/model/tab/tab-model';
import { TabResponseModel } from 'src/app/core/model/tab/tab-response-model';
import { PrResponse } from './pr.response';

@Component({
  templateUrl: './pr-catalog-add.component.html'
})
export class PrCatalogAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  constructor(translateService: TranslateService) {
    super('pr', translateService);
  }
  public prResponse: PrResponse = new PrResponse();
  public tabResponse: TabResponseModel;
  public tabOne: TabModel;
  public itemTypeCode: string;

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setStateReady();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.prResponse = this.global.routerParams.get('prResponse');
    this.tabResponse = this.global.routerParams.get('tabResponse');
    this.tabOne = this.tabResponse.currentTab;
    if(this.prResponse.procurementTypeList[0].code === 
      this.global.appConstant.pm.PROCUREMENT_TYPE_NON_CONSTRUCTION){
      this.itemTypeCode = this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL;
    }else if(this.prResponse.procurementTypeList[0].code === 
      this.global.appConstant.pm.PROCUREMENT_TYPE_CONSTRUCTION){
      this.itemTypeCode = this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE;
    }
  }
}
