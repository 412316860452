import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartModel } from '../../../../../core/components/app-chart/models/chart-model';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetContractRequestByTypeResponse } from './model/widget-contract-request-by-type-response';
@Component({
  selector: 'dashboard-widget-contract-request-by-type',
  templateUrl: './widget-contract-request-by-type.component.html',
  encapsulation: ViewEncapsulation.None
})
export class WidgetContractRequestByTypeComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-contract-request-by-type'
  public chartModel: ChartModel;
  constructor(
    public translateService: TranslateService
  ) {
    super(WidgetContractRequestByTypeComponent.moduleCode);
  }

  public onInit(): void {
    this.getAndSetContractInformation();
  }

  public getAndSetContractInformation(): void {
    this.httpClientService
      .get<WidgetContractRequestByTypeResponse>('/widget-contract-request-by-type/index').subscribe(
        (
          widgetContractRequestByTypeResponse: WidgetContractRequestByTypeResponse
        ) => {
          if (widgetContractRequestByTypeResponse) {
            const labels = [];
            const dataSets = [];
            widgetContractRequestByTypeResponse.widgetContractRequestByTypeModelList.forEach(
              widgetContractRequestByType => {
                labels.push(
                  this.translateService.instant(
                    this.moduleCode + '.' +
                    widgetContractRequestByType.name
                      .toLowerCase()
                      .replace(/\s/g, '')
                      .trim()
                  )
                );
                dataSets.push(widgetContractRequestByType.total);
              }
            );
            this.chartModel = new ChartModel('pie', labels, dataSets, null, {
              defaultColor: ['#204d95 ', '#782095', '#3d9520', '#334d4d']
            });
          }
          this.setStateReady();
        }
      );
  }
}
