<app-popup
  [header]="'tab-profile-bank.form.popup.header.bank.' + todo"
  [isLoading]="formLoading"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-bank.form.popup.bankName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-auto-complete
          size="LG"
          formControlName="bankName"
          (onChange)="autoCompleChanged($event)"
          stringUrl="/tab-profile-bank/auto-complete-bank-list"
        >
          <app-flag-new
            *ngIf="
            global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorBankHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorBank.bank ? vendorBank.bank.name : null"
            [newValue]="
              vendorBankHistoryEdit.bank
                ? vendorBankHistoryEdit.bank.name
                : null
            "
          ></app-flag-new>
        </app-auto-complete>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-bank.form.popup.country' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="LG"
          [optionList]="countryRegionOptionList"
          formControlName="countryRegion"
          (onChange)="doChangeCountryRegion()"
        >
          <app-flag-new
            *ngIf="
            global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorBankHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorBank.countryRegion ? vendorBank.countryRegion.name : null
            "
            [newValue]="
              vendorBankHistoryEdit.countryRegion
                ? vendorBankHistoryEdit.countryRegion.name
                : null
            "
          ></app-flag-new>
        </app-combo-box>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-bank.form.popup.branch' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          autofocus="true"
          formControlName="branch"
          type="alphabet"
          maxLength="32"
        >
          <app-flag-new
            *ngIf="
            global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorBankHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorBank.branch"
            [newValue]="vendorBankHistoryEdit.branch"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-bank.form.popup.address' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        <app-text-area size="LG" formControlName="address" maxLength="512">
          <app-flag-new
            *ngIf="
            global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorBankHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorBank.address"
            [newValue]="vendorBankHistoryEdit.address"
            [ngStyle]="{ 'padding-left': '3px' }"
          ></app-flag-new>
        </app-text-area>
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-bank.form.popup.coordinateLocation' | translate
      }}</label>
      <div
        class="text-left"
        [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
      >
        <app-text-map size="LG" formControlName="coordinateLocation">
          <app-flag-new
            *ngIf="
            global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorBankHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorBank.coordinateLocation"
            [newValue]="vendorBankHistoryEdit.coordinateLocation"
          ></app-flag-new>
        </app-text-map>
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-bank.form.popup.city' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-auto-complete
          size="LG"
          formControlName="cityRegion"
          stringUrl="/company-profile/auto-complete-city-region-list"
          [params]="countryRegionId"
          tooltip="{{ 'tab-profile-bank.tooltip.city' | translate }}"
        >
          <app-flag-new
            *ngIf="
            global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorBankHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorBank.cityRegion ? vendorBank.cityRegion.name : null
            "
            [newValue]="
              vendorBankHistoryEdit.cityRegion
                ? vendorBankHistoryEdit.cityRegion.name
                : null
            "
          ></app-flag-new>
        </app-auto-complete>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-bank.form.popup.accountNumber' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="accountNumber"
          type="integer"
          maxLength="20"
        >
          <app-flag-new
            *ngIf="
            global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorBankHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorBank.accountNumber"
            [newValue]="vendorBankHistoryEdit.accountNumber"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-bank.form.popup.accountHolder' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="accountHolder"
          maxLength="64"
          type="alphanumeric"
          include="-.\',"
        >
          <app-flag-new
            *ngIf="
            global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorBankHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorBank.accountHolder"
            [newValue]="vendorBankHistoryEdit.accountHolder"
          ></app-flag-new>
        </app-text-field>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-bank.form.popup.currency' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        <app-combo-box
          size="LG"
          [optionList]="currencyOptionList"
          formControlName="currency"
        >
          <app-flag-new
            *ngIf="
            global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorBankHistoryEdit.vendorHistory !== null
            "
            [oldValue]="vendorBank.currency ? vendorBank.currency.code : null"
            [newValue]="
              vendorBankHistoryEdit.currency
                ? vendorBankHistoryEdit.currency.code
                : null
            "
          ></app-flag-new>
        </app-combo-box>
      </div>
    </div>
    <div class="form-group row text-sm-right" *ngIf="!formGroup.isView">
      <label class="col-sm-3 control-label">{{
        'tab-profile-bank.form.popup.priority' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-check-box
          size="LG"
          formControlName="isPriority"
          tooltip="{{ 'tab-profile-bank.tooltip.priority' | translate }}"
          (change)="onChangePriority()"
        ></app-check-box>
      </div>
    </div>
    <div class="form-group row text-sm-right" *ngIf="formGroup.isView">
      <label class="col-sm-3 control-label">{{
        'tab-profile-bank.form.popup.priority' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ isPriorityHistory }}
        <app-flag-new
          *ngIf="
          global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
            formGroup.isView &&
            !isVendorList &&
            (fromOutside === '/pages/on-boarding' ||
              fromOutside === '/pages/approval-prcs') &&
            vendorBankHistoryEdit.vendorHistory !== null
          "
          [oldValue]="isPriority"
          [newValue]="isPriorityHistory"
        ></app-flag-new>
      </div>
    </div>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
        <app-button
          *ngIf="!formGroup.isView"
          color="PRIMARY"
          (onClick)="doSubmit()"
          >{{ 'app.button.save' | translate }}</app-button
        >
      </div>
    </ng-template>
  </form>
</app-popup>
