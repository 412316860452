import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetVendorContractComponent } from './app-widget-vendor-contract.component';
@NgModule({
  imports: [VendorModule],
  declarations: [AppWidgetVendorContractComponent],
  entryComponents: [AppWidgetVendorContractComponent],
  exports: [AppWidgetVendorContractComponent]
})
export class AppWidgetVendorContractModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-vendor-contracted', AppWidgetVendorContractComponent);
  }
}
