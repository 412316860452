import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupEmailComponent } from './app-popup-email.component';
@Injectable()
export class AppPopupEmailService {
  constructor(public appApopupService: AppPopupService) {}
  public open(data?: any, config?: NgbModalOptions): Observable<any> {
    const defaultConfig: NgbModalOptions = { size: 'lg', backdrop: 'static' };
    const newConfig = { ...defaultConfig, ...config };
    return this.appApopupService.open(
      AppPopupEmailComponent,
      { mailer: data },
      newConfig
    );
  }
}
