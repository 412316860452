import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppBadgeModule } from '../../../../../core/components/app-badge/app-badge.module';
import { AppWidgetContentSliderModule } from '../../../../../core/widgets/app-widget-content-slider/app-widget-content-slider.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetPrStatusComponent } from './widget-pr-status.component';
@NgModule({
  imports: [
    VendorModule,
    AppWidgetContentSliderModule,
    AppBadgeModule,
    WidgetCardModule
  ],
  declarations: [WidgetPrStatusComponent],
  entryComponents: [WidgetPrStatusComponent],
  exports: [WidgetPrStatusComponent]
})
export class WidgetPrStatusModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetPrStatusComponent.moduleCode,
      WidgetPrStatusComponent,
      'w-100'
    );
  }
}
