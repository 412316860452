import { HttpClient } from '@angular/common/http';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({ name: 'resolveImgSrc' })
@Injectable({ providedIn: 'root' })
export class ResolveImgSrc implements PipeTransform {
  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer
  ) {}
  transform(imgSrc: string, isByPassNotFoundImg?: boolean): Promise<any> {
    return new Promise(resolve => {
      this.httpClient.get(imgSrc, { responseType: 'blob' }).subscribe(
        response => {
          const reader = new FileReader();
          reader.onload = e =>
            resolve(
              this.sanitizer.bypassSecurityTrustUrl(e.target.result as string)
            );
          reader.readAsDataURL(response);
        },
        () => {
          resolve(isByPassNotFoundImg ? '#' : 'assets/img/app/no-image.png');
        }
      );
    });
  }
}
