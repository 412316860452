import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { RfiItem } from '../../core/bean/rfi-item';
import { Uom } from '../../core/bean/uom';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';
import { RfiDTO } from './dto/rfi.dto';
@Component({
  selector: 'app-popup-rfi-item',
  templateUrl: './app-popup-item.component.html'
})
export class AppPopupRfiItemComponent extends BaseComponentComponent {
  @Input() public todo: string;
  @Input() public rfiItemList: RfiItem[];
  @Input() public header: string;
  @Input() public rfiItemEdit: RfiItem;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public rfiDTO: RfiDTO = new RfiDTO();
  public uomOptionList: OptionListModel<Uom> = new OptionListModel(true);

  constructor(public translateService: TranslateService) {
    super('app-popup-rfi-item');
  }

  onInit(): void {
    this.buildFormGroup();
    this.getAndSetItemToFormGroup();
    this.setStateReady();
    this.log.debug(this.rfiItemList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      item: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(128)])
      ],
      description: [null, Validators.compose([Validators.required()])],
      quantity: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(11)])
      ],
      uom: [null, Validators.compose([Validators.required()])]
    });
  }

  public getAndSetItemToFormGroup(): void {
    this.setStateProcessing();
    this.httpClientService
      .post<RfiDTO>('/request-for-information/popup-rfi-item', null)
      .subscribe(rfiDTO => {
        this.rfiDTO = rfiDTO;
        this.uomOptionList.setRequestValues(rfiDTO.uomList);
        if (this.rfiItemEdit != null) {
          this.formGroup.patchValue({
            item: this.rfiItemEdit.item,
            description: this.rfiItemEdit.description,
            quantity: this.rfiItemEdit.quantity,
            uom: this.rfiItemEdit.uom
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    if (
      typeof this.formGroup.get('item').value !== 'string' &&
      this.formGroup.get('item').value
    ) {
      this.formGroup.patchValue({
        item: this.formGroup.get('item').value.name
      });
    }

    const duplicateItem = this.rfiItemList.filter(
      rfiItem => rfiItem.item === this.formGroup.get('item').value
    );
    const isDuplicate =
      this.todo === 'add' && duplicateItem && duplicateItem.length > 0;

    this.validate();
    if (isDuplicate) {
      this.global.alertService.showError(
        this.translateService.instant('rfi.alert.msg.duplicateItem'),
        '.main-row-rfi-item'
      );
    } else if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup.value);
    }
  }

  public autoCompleteValueChange(item: any): void {
    this.log.debug(item);
  }
}
