import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedViewCatalogModule } from './core/shared/shared-view-catalog.module';
import { ViewCatalogComparisonComponent } from './view-catalog-comparison.component';
import { ViewCatalogDetailComponent } from './view-catalog-detail.component';
import { ViewCatalogComponent } from './view-catalog.component';

const routes: Routes = [
  { path: '', component: ViewCatalogComponent, data: { breadcrumb: '' } },
  { path: 'comparison', component: ViewCatalogComparisonComponent, data: { breadcrumb: 'view-catalog.breadcrumb.comparison'} },
  {
    path: 'detail/:id',
    component: ViewCatalogDetailComponent,
    data: { breadcrumb: 'view-catalog.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [SharedViewCatalogModule, RouterModule.forChild(routes)],
  declarations: [ViewCatalogComponent, ViewCatalogDetailComponent, ViewCatalogComparisonComponent]
})
export class ViewCatalogModule {}
