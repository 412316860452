import { ModuleGroup } from './../bean/module-group';
import { BaseEntity } from '../base/base-entity';
import { Threshold } from '../bean/threshold';

export class ConditionEntity extends BaseEntity {
    moduleGroupList: ModuleGroup[];
    threshold: Threshold;

    moduleGroup: ModuleGroup;
    name: string;
    description: string;
    type: string;
    className: string;
    query: string;
}
