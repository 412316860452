import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { BiddingEvaluation } from 'src/app/core/bean/bidding-evaluation';
import { BiddingSubmissionType } from 'src/app/core/bean/bidding-submission-type';
import { Condition } from 'src/app/core/bean/condition';
import { CurrencyType } from 'src/app/core/bean/currency-type';
import { DocumentSubmissionType } from 'src/app/core/bean/document-submission-type';
import { NegotiationType } from 'src/app/core/bean/negotiation-type';
import { PriceEvaluationType } from 'src/app/core/bean/price-evaluation-type';
import { ProcurementMatrix } from 'src/app/core/bean/procurement-matrix';
import { ProcurementMethod } from 'src/app/core/bean/procurement-method';
import { ThresholdRange } from 'src/app/core/bean/threshold-range';
import { VendorSelectionType } from 'src/app/core/bean/vendor-selection-type';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { ProcurementMatrixResponse } from './response/procurement-matrix.response';

@Component({
  templateUrl: './procurement-matrix-edit-add.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ProcurementMatrixEditAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  public procurementMatrixId: number;
  public procurementValueOptionList: OptionListModel<ThresholdRange> =
    new OptionListModel(true);
  public procurementMethodOptionList: OptionListModel<ProcurementMethod> =
    new OptionListModel(true);
  public conditionOptionList: OptionListModel<Condition> = new OptionListModel(
    true
  );
  public documentSubmissionTypeOptionList: OptionListModel<DocumentSubmissionType> =
    new OptionListModel(true);
  public negotiationTypeOptionList: OptionListModel<NegotiationType> =
    new OptionListModel(true);
  public priceEvaluationTypeOptionList: OptionListModel<PriceEvaluationType> =
    new OptionListModel(true);
  public biddingEvaluationOptionList: OptionListModel<BiddingEvaluation> =
    new OptionListModel(true);
  public biddingSubmissionTypeOptionList: OptionListModel<BiddingSubmissionType> =
    new OptionListModel(true);
  public vendorSelectionTypeOptionList: OptionListModel<VendorSelectionType> =
    new OptionListModel(true);
  public currencyTypeOptionList: OptionListModel<CurrencyType> =
    new OptionListModel(true);
  public isProcMethodInMatrix = true;
  public procurementMatrix = new ProcurementMatrix();
  public isOptional = false;

  constructor(translateService: TranslateService) {
    super('procurement-matrix', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.procurementMatrixId = this.global.routerParams.get(
      'procurementMatrixId'
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        {
          validators: [Validators.required()],
          asyncValidators: [
            Validators.existsAsync('/procurement-matrix/is-exist', null, null, {
              id: this.procurementMatrixId
            })
          ]
        }
      ],

      name: [
        null,
        {
          validators: [Validators.required()],
          asyncValidators: [
            Validators.existsAsync('/procurement-matrix/is-exist', null, null, {
              id: this.procurementMatrixId
            })
          ]
        }
      ],

      description: [null],
      procurementMethod: [null, Validators.required()],
      thresholdRange: [null, Validators.required()],

      documentSubmissionType: [null],
      negotiationType: [null],
      priceEvaluationType: [null],
      biddingEvaluation: [null],
      biddingSubmissionType: [null],
      vendorSelectionType: [null],
      currencyType: [null],
      isPq: [null],
      isShowVendorResult: [true]
    });
  }

  public translateOption(optionValue): string {
    const translateOptionValue = this.global.translateService.instant(
      optionValue.translationKey.module.code.toLowerCase() +
        '.' +
        optionValue.translationKey.key
    );
    return translateOptionValue;
  }

  public onChangeProcMethod(procMethod: ProcurementMethod): void {
    const formList: string[] = [
      'documentSubmissionType',
      'negotiationType',
      'priceEvaluationType',
      'biddingEvaluation',
      'biddingSubmissionType',
      'vendorSelectionType',
      'currencyType'
    ];

    if (procMethod) {
      if (
        procMethod.transactionType.code ===
        this.global.appConstant.core.TRANSACTION_TYPE_ONLINE
      ) {
        formList.forEach(formControlName => {
          this.formGroup
            .get(formControlName)
            .setValidators([Validators.required()]);
          this.formGroup.get(formControlName).updateValueAndValidity();
        });
        this.isOptional = false;
      } else {
        /** OFFLINE */
        formList.forEach(formControlName => {
          this.formGroup.get(formControlName).clearValidators();
          this.formGroup.get(formControlName).updateValueAndValidity();
        });
        this.isOptional = true;
      }
    } else {
      formList.forEach(formControlName => {
        this.formGroup.get(formControlName).clearValidators();
        this.formGroup.get(formControlName).updateValueAndValidity();
      });
      this.isOptional = false;
    }
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ProcurementMatrixResponse>(
        '/procurement-matrix/add-edit',
        new RouteRequestModel(this.todo, this.procurementMatrixId)
      )
      .subscribe((response: ProcurementMatrixResponse) => {
        this.procurementValueOptionList.setRequestValues(
          response.thresholdRangeList
        );
        this.procurementMethodOptionList.setRequestValues(
          response.procurementMethodList
        );
        this.conditionOptionList.setRequestValues(response.conditionList);
        this.documentSubmissionTypeOptionList.setRequestValues(
          response.documentSubmissionTypeList
        );
        this.negotiationTypeOptionList.setRequestValues(
          response.negotiationTypeList
        );
        this.priceEvaluationTypeOptionList.setRequestValues(
          response.priceEvaluationTypeList
        );
        this.biddingEvaluationOptionList.setRequestValues(
          response.biddingEvaluationList
        );
        this.biddingSubmissionTypeOptionList.setRequestValues(
          response.biddingSubmissionTypeList
        );
        this.vendorSelectionTypeOptionList.setRequestValues(
          response.vendorSelectionTypeList
        );
        this.currencyTypeOptionList.setRequestValues(response.currencyTypeList);

        this.procurementMatrix = response.procurementMatrix;
        const procurementMatrix = response.procurementMatrix;

        if (procurementMatrix) {
          const conditionList: Condition[] = [];

          if (procurementMatrix.thresholdRange) {
            const procValueCondition = response.conditionList.find(
              c =>
                c.code ===
                this.global.appConstant.pm.CONDITION_PROCUREMENT_VALUE
            );
            conditionList.push(procValueCondition);
          }

          if (procurementMatrix.procurementMethod) {
            const procMethodCondition = response.conditionList.find(
              c =>
                c.code ===
                this.global.appConstant.pm.CONDITION_PROCUREMENT_METHOD
            );
            conditionList.push(procMethodCondition);
          }

          this.formGroup.patchValue({
            id: procurementMatrix.id,
            code: procurementMatrix.code,
            name: procurementMatrix.name,
            description: procurementMatrix.description,
            procurementMethod: procurementMatrix.procurementMethod,
            thresholdRange: procurementMatrix.thresholdRange,

            documentSubmissionType: procurementMatrix.documentSubmissionType,
            negotiationType: procurementMatrix.negotiationType,
            priceEvaluationType: procurementMatrix.priceEvaluationType,
            biddingEvaluation: procurementMatrix.biddingEvaluation,
            biddingSubmissionType: procurementMatrix.biddingSubmissionType,
            vendorSelectionType: procurementMatrix.vendorSelectionType,
            currencyType: procurementMatrix.currencyType,
            isPq: procurementMatrix.isPq,
            isShowVendorResult: procurementMatrix.isShowVendorResult
          });
        }

        this.setStateReady();
      });
  }

  public onChangeCondition(conditionList: Condition[]): void {
    if (conditionList) {
      const findProcMethodIndex = conditionList.findIndex(
        condition =>
          condition.code ===
          this.global.appConstant.pm.CONDITION_PROCUREMENT_METHOD
      );
      this.isProcMethodInMatrix = findProcMethodIndex !== -1 ? false : true;

      const findProcValueIndex = conditionList.findIndex(
        condition =>
          condition.code ===
          this.global.appConstant.pm.CONDITION_PROCUREMENT_VALUE
      );
      if (findProcValueIndex !== -1) {
        this.formGroup
          .get('thresholdRange')
          .setValidators([Validators.required()]);
        this.formGroup.get('thresholdRange').updateValueAndValidity();
      } else {
        this.formGroup.get('thresholdRange').clearValidators();
        this.formGroup.get('thresholdRange').updateValueAndValidity();
      }
    }
  }

  public isShowFormControlCondition(): boolean {
    if (this.formGroup.get('conditionList').value) {
      const findConditionIndex: number = (
        this.formGroup.get('conditionList').value as Array<Condition>
      ).findIndex(
        condition =>
          condition.code ===
          this.global.appConstant.pm.CONDITION_PROCUREMENT_VALUE
      );
      return findConditionIndex !== -1 ? true : false;
    } else {
      return false;
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      const procurementMatrix: ProcurementMatrix =
        this.global.copyFormAttributeToModel(
          new ProcurementMatrix(),
          this.formGroup
        );
      procurementMatrix.isPq = procurementMatrix.isPq ? true : false;
      procurementMatrix.isShowVendorResult =
        procurementMatrix.isShowVendorResult ? true : false;

      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            if (
              procurementMatrix.procurementMethod.procurementRegType.code ===
              this.global.appConstant.pm.PROC_REG_TYPE_ANNOUNCEMENT
            ) {
              procurementMatrix.isShowVendorResult = true;
            }

            const url =
              this.todo === 'edit'
                ? '/procurement-matrix/update'
                : '/procurement-matrix/insert';
            this.httpClientService
              .post<Response<ProcurementMatrix>>(url, procurementMatrix)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccessCreateNew()
                    .subscribe(result => {
                      if (result) {
                        this.global.routerParams.clear();
                        this.global.routerParams.set('todo', 'add');
                        this.router.navigate(['/pages/procurement-matrix/add']);
                      } else {
                        this.router.navigate(['/pages/procurement-matrix']);
                      }
                    });
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }
}
