<app-layout [isLoading]="formLoading">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        *ngIf="!formLoading"
        novalidate
      >
        <app-card
          header="tc.detail.general.title"
          tips="tc.detail.general.tips"
        >
          <app-fullfilled></app-fullfilled>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label">{{
              'tc.form.code' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                autofocus="true"
                formControlName="code"
                maxLength="32"
                type="code"
              ></app-text-field>
            </div>
          </div>

          <ng-container formArrayName="translationList">
            <div
              class="form-group row text-sm-left"
              *ngFor="
                let translation of translationList.controls;
                let i = index
              "
              [formGroupName]="i"
            >
              <label *ngIf="i === 0" class="col-sm-3 control-label text-left">{{
                'tc.form.name' | translate
              }}</label>
              <label
                *ngIf="i !== 0"
                class="col-sm-3 control-label text-left"
              ></label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  formControlName="value"
                  maxLength="128"
                  tooltip="translationListLabel[i] === 'ID'? 'tc.form.tooltip.id.name' : 'tc.form.tooltip.en.name'"
                ></app-text-field>
                <small
                  *ngIf="translationListLabel[i] === 'ID'"
                  style="color: var(--gray-500)"
                  >{{ 'tc.form.info.id.name' || translate }}</small
                >
                <small
                  *ngIf="translationListLabel[i] === 'EN'"
                  style="color: var(--gray-500)"
                  >{{ 'tc.form.info.en.name' || translate }}</small
                >
              </div>
            </div>
          </ng-container>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label optional">{{
              'tc.form.description' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-area formControlName="description" maxLength="512">
              </app-text-area>
            </div>
          </div>
        </app-card>

        <app-card
          header="tc.termCondition.general.title"
          tips="tc.termCondition.general.tips"
        >
          <app-table-x [model]="tableResponse" (onChange)="doCheckValue()">
            <ng-template #headerButtons>
              <app-button (onClick)="doAddTcDetail()"
                ><em class="fas fa-plus"></em>
                {{ 'tc.button.addTcDetailList' | translate }}
              </app-button>
            </ng-template>
            <ng-container>
              <ng-template #actionButtons let-rowData>
                <app-button
                  size="TN"
                  (onClick)="doDelete(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="pir pi-trash-alt"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>
              </ng-template>
            </ng-container>
          </app-table-x>
        </app-card>

        <div class="row justify-content-center mt-5">
          <app-button color="PRIMARY" (onClick)="doSave()">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
