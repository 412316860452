import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { OrderCancellation } from 'src/app/core/bean/order-cancellation';
import { OrderType } from 'src/app/core/bean/order-type';
import { Sow } from 'src/app/core/bean/sow';
import { Vendor } from 'src/app/core/bean/vendor';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { AppPopupAddressService } from 'src/app/core/components/app-popup/app-popup-address/app-popup-address.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { Validators } from 'src/app/core/validators';
import { DrShippingItemView } from 'src/app/core/view/entity/bean/dr-shipping-item-view';
import { SowPaymentTermItemView } from 'src/app/core/view/entity/bean/sow-payment-term-item-view';
import { AddressPic } from '../../core/bean/address-pic';
import { Dr } from '../../core/bean/dr';
import { DrShipping } from '../../core/bean/dr-shipping';
import { DrTc } from '../../core/bean/dr-tc';
import { SowPaymentTerm } from '../../core/bean/sow-payment-term';
// import { SowPaymentTermItem } from '../../core/bean/sow-payment-term-item';
import { SowPaymentTermItem } from 'src/app/core/bean/sow-payment-term-item';
import { Tc } from '../../core/bean/tc';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { DrPopupAddMoreAddressComponent } from './dr-popup-add-more-address.component';
import { DrPopupDeliveryProcessComponent } from './dr-popup-delivery-process.component';
import { DrPopupShippedItemComponent } from './dr-popup-shipped-item.component';
import { DrRequest } from './request/dr.request';
import { DrAddEditResponse } from './response/dr-add-edit.response';

@Component({
  templateUrl: './dr-edit-add.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DrEditAddComponent extends BaseModuleComponent implements OnInit {
  public token: string;
  public drId: number;
  public objectId: number;
  public approvalModelPrcsId: number;
  public sowTouchedId: number;
  public dr: Dr = new Dr();
  public orderTypeOptionList: OptionListModel<OrderType> = new OptionListModel(
    true
  );
  public fileUploader: FileUploader = new FileUploader(
    '/order/',
    'order.form.headerImage',
    this.global.appConstant.fileType.DOC_ORDER,
    false,
    this.global.config.parameterModel.maxFileOrder
  );
  public response: DrAddEditResponse = new DrAddEditResponse();
  public today = new Date();
  public orderCancel: OrderCancellation;
  public drShippingOptionList: OptionListModel<DrShipping> =
    new OptionListModel(true, 'address.address'); // to keep data from popup
  public appOfficialReportModel: AppOfficialReportModel;
  public badgeColor = {
    NOT_STARTED: 'SECONDARY',
    ON_PROGRESS: 'PROGRESS',
    DONE: 'SUCCESS',
    CANCELED: 'DANGER',
    CLOSED: 'DANGER'
  };

  public iconList = {
    NOT_STARTED: 'pir pi-dot-circle',
    ON_PROGRESS: 'pir pi-clock text-progress',
    DONE: 'pir pi-check-circle text-success',
    CANCELED: 'pir pi-times-circle text-danger',
    CLOSED: 'pir pi-times-circle text-danger'
  };

  public tableResponseTc: TableResponseModel<DrTc>;
  public tableResponseVendor: TableResponseModel<Vendor>;
  public tableResponseGoodsItem: TableResponseModel<SowPaymentTermItemView>;
  public tableResponseServiceItem: TableResponseModel<SowPaymentTermItem>;
  public tableItemList: any[][] = [];
  public sowList: Sow[] = [];
  public drShippingItemList: DrShippingItemView[] = [];
  public isRevisions: boolean;
  public isAppSowReady = false;
  public tcList: Tc[] = [];
  public vendorListEdit: Vendor[] =[];
  public urlBackOutside: string;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupAddressService: AppPopupAddressService,
    public toastService: ToastService,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute
  ) {
    super('dr', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Dr>('/dr/notification/' + this.token)
      .subscribe((dr: Dr) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('drId', dr.id);
        this.global.routerParams.set('urlBackOutside', '/pages/dr');
        this.global.routerParams.set('todo', 'edit');
        this.router.navigate(['/pages/dr/edit']);
      });
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.buildTableResponseVendor();
    this.buildTableResponseTc();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.drId =
      +localStorage.getItem('drId') || this.global.routerParams.get('drId');
    this.dr = this.global.routerParams.get('dr');
    this.objectId =
      +localStorage.getItem('objectId') ||
      this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.drId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    localStorage.removeItem('todo');
    localStorage.removeItem('drId');
    localStorage.removeItem('objectId');
    localStorage.removeItem('urlBackOutside');
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildTableResponseVendor(): void {
    this.tableResponseVendor = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.vendorName'
      },
      {
        field: 'email',
        header: 'table.column.email'
      },
      {
        field: 'address',
        header: 'table.column.address'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      }
    ]);
  }

  public buildTableResponseTc(): void {
    this.tableResponseTc = new TableResponseModel(this.moduleCode, [
      {
        field: 'tcDetail.termType.name',
        header: 'table.column.term'
      },
      {
        field: 'condition',
        header: 'table.column.condition',
        plugins: {
          name: 'html'
        }
      }
    ]);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      title: [null, Validators.required()],
      orderType: [null, Validators.required()],
      orderStatus: [null],
      requiredDate: [null, Validators.required()],
      description: [null],
      docList: [null, Validators.required()],
      drShipping: [null],
      drShippingList: [null],
      sowList: [null],
      drType: [null],
      drStatus: [null]
    });
    this.formGroup.get('drShipping').setIsView(true);
    this.formGroup.get('requiredDate').setIsView(true);
    this.formGroup.get('docList').setIsView(true);
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<DrAddEditResponse>(
        '/dr/add-edit?id=' +
          (this.drId ? this.drId : '') +
          '&todo=' +
          this.todo,
        {}
      )
      .subscribe((response: DrAddEditResponse) => {
        this.response = response;
        this.appOfficialReportModel = response.appOfficialReportModel;
        this.dr = response.dr;
        this.drShippingOptionList.setRequestValues([]);
        this.tcList = response.tcList;
        this.buildTableResponseItem();
        if (response.order) {
          const {
            id,
            code,
            title,
            orderType,
            orderStatus,
            requiredDate,
            description
          } = response.order;

          this.fileUploader.setFileList(
            response.orderDocList.map(orderDoc => orderDoc.file)
          );
          this.formGroup.patchValue({
            id,
            code,
            title,
            orderType,
            orderStatus,
            requiredDate: new Date(requiredDate),
            description,
            docList: this.fileUploader.fileObjectList,
            drShippingList: response.drShippingList,
            sowList: response.sowList
          });
        } else {
          const { id, code, title, requiredDate, drType, description } =
            response.dr;

          this.fileUploader.setFileList(
            response.contractDocList.map(contratDoc => contratDoc.file)
          );
          this.formGroup.patchValue({
            id,
            code,
            title,
            drType,
            description,
            requiredDate: new Date(requiredDate),
            docList: this.fileUploader.fileObjectList,
            drShippingList: response.drShippingList,
            sowList: response.sowList
          });
        }
        
        //this.dr.vendor['address'] = response.vendorAddress?.address;
        this.tableResponseVendor.setRecordList(this.vendorListEdit);
        this.tableResponseVendor.reload();

        this.drShippingOptionList.setRequestValues(response.drShippingList);
        this.drShippingItemList = this.response.drShippingItemViewList;

        this.tableResponseTc.setRecordList(response.drTcList);
        this.tableResponseTc.reload();

        this.sowList = response.sowList;
        this.isAppSowReady = true;

        this.setStateReady();
        this.isAppSowReady = true;
      });
  }

  public buildTableResponseItem(): void {
    if (this.response.sowList) {
      let i = 0;
      this.response.sowList.forEach(sow => {
        this.tableItemList[i] = [];

        sow.sowPaymentTermList.forEach((sowPaymentTerm: SowPaymentTerm) => {
          this.tableResponseGoodsItem = this.createTableResponseItem(
            this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
          );
          this.tableResponseServiceItem = this.createTableResponseItem(
            this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
          );
          const isGoodsItemEmpty = sowPaymentTerm.sowPaymentTermItemList.find(
            x =>
              x.prItem.item.itemType.parentCode ===
                this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL &&
              x.quantity > 0
          );
          const isServiceItemEmpty = sowPaymentTerm.sowPaymentTermItemList.find(
            x =>
              x.prItem.item.itemType.parentCode ===
                this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE &&
              x.quantity > 0
          );
          this.tableResponseGoodsItem.setCustomData({ id: sowPaymentTerm.id });
          this.tableResponseServiceItem.setCustomData({
            id: sowPaymentTerm.id
          });
          const tableItem = {
            isGoodsItemEmpty,
            isServiceItemEmpty,
            sowPaymentTermId: sowPaymentTerm.id,
            tableResponseGoods: this.tableResponseGoodsItem,
            tableResponseService: this.tableResponseServiceItem
          };
          this.tableItemList[i].push(tableItem);
        });
        i++;
      });
    }
  }

  public createTableResponseItem(
    itemType: string
  ): TableResponseModel<SowPaymentTermItemView> {
    if (itemType === this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL) {
      return new TableResponseModel(this.moduleCode, [
        {
          field: 'merk',
          header: 'table.column.itemMerk',
          plugins: {
            name: 'hyperlink',
            onClick: this.doDeliveryProcess.bind(this),
            className: 'white-space-normal'
          }
        },
        {
          field: 'quantity',
          header: 'table.column.qty',
          className: 'text-right',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              this.log.debug(tablePlugin);
              if (
                itemType ===
                this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
              ) {
                return (
                  this.global.converterService.convertDecimal(
                    tablePlugin.value
                  ) +
                  ' ' +
                  tablePlugin?.row?.record?.prItem?.item?.uom
                    ?.name
                );
              } else {
                return (
                  this.global.converterService.convertDecimal(
                    tablePlugin.value
                  ) + ' %'
                );
              }
            }
          }
        },
        {
          field: 'receivedQuantity',
          header: 'table.column.received',
          className: 'text-right',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              if (
                itemType ===
                this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
              ) {
                return (
                  this.global.converterService.convertDecimal(
                    tablePlugin.value
                  ) +
                  ' ' +
                  tablePlugin?.row?.record?.prItem?.item?.uom
                    ?.name
                );
              } else {
                return (
                  this.global.converterService.convertDecimal(
                    tablePlugin.value
                  ) + ' %'
                );
              }
            }
          }
        },
        {
          field: 'remainingQuantity',
          header: 'table.column.remaining',
          className: 'text-right',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              if (
                itemType ===
                this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
              ) {
                return (
                  this.global.converterService.convertDecimal(
                    tablePlugin.value
                  ) +
                  ' ' +
                  tablePlugin?.row?.record?.prItem?.item?.uom
                    ?.name
                );
              } else {
                return (
                  this.global.converterService.convertDecimal(
                    tablePlugin.value
                  ) + ' %'
                );
              }
            }
          }
        },
        {
          field: 'deliveryPercentage',
          header: 'table.column.percentage',
          className: 'text-right',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              return (
                this.global.converterService.convertDecimal(tablePlugin.value) +
                ' %'
              );
            }
          }
        },
        {
          field: 'deliveryStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              NOT_STARTED: 'SECONDARY',
              ON_PROGRESS: 'PROGRESS',
              DONE: 'SUCCESS',
              CLOSED: 'DANGER',
              CANCELED: 'DANGER'
            },
            field: 'deliveryStatus.code'
          }
        }
      ]);
    } else if (
      itemType === this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
    ) {
      return new TableResponseModel(this.moduleCode, [
        {
          field: 'prItem.item.name',
          header: 'table.column.itemName',
          plugins: {
            name: 'hyperlink',
            onClick: this.doDeliveryProcess.bind(this),
            className: 'white-space-normal'
          }
        },
        {
          field: 'deliveryPercentage',
          header: 'table.column.percentage',
          className: 'text-right',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              return (
                this.global.converterService.convertDecimal(tablePlugin.value) +
                ' %'
              );
            }
          }
        },
        {
          field: 'deliveryStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              NOT_STARTED: 'SECONDARY',
              ON_PROGRESS: 'PROGRESS',
              DONE: 'SUCCESS',
              CLOSED: 'DANGER',
              CANCELED: 'DANGER'
            },
            field: 'deliveryStatus.code'
          }
        }
      ]);
    }
  }

  public isTableResponseEmpty(i: number, j: number, itemType: string): boolean {
    const recordList = this.response.sowList[i].sowPaymentTermList[
      j
    ].sowPaymentTermItemList.filter(
      x => x.prItem.item.itemType.code === itemType
    );
    if (recordList && recordList.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  public getNumberOfDoneTerm(sowPaymentTermList: SowPaymentTerm[]): number {
    let number = 0;
    sowPaymentTermList.forEach(term => {
      if (term.deliveryStatus.code === 'DONE') {
        number++;
      }
    });
    return number;
  }

  public doAddMoreAddress(): void {
    const addressBookList = this.formGroup.get('drShippingList').value
      ? (this.formGroup.get('drShippingList').value as Array<DrShipping>).map(
          oship => oship.address
        )
      : [];

    this.appPopupService
      .open(
        DrPopupAddMoreAddressComponent,
        {
          dr: this.dr,
          addressBookList
        },
        { size: 'lg' }
      )
      .subscribe((drShippingList: DrShipping[]) => {
        this.response.drShippingList =
          this.response.drShippingList.concat(drShippingList);
        this.drShippingOptionList.setRequestValues(
          this.response.drShippingList
        );
        this.formGroup.patchValue({
          drShippingList: this.response.drShippingList
        });
        this.formGroup.markAsDirty();
        this.global.modalService.confirmation(
          this.translateService.instant('dr.modal.saveSuccess.msg'),
          'dr.modal.saveSuccess.title',
          null,
          null,
          'pvc pv-check-circle',
          true,
          {
            autoCloseInMs: 1000
          }
        );
      });
  }

  public doDeliveryProcess(sowPaymentTermItem: SowPaymentTermItemView): void {
    const todo =
      sowPaymentTermItem.deliveryStatus?.code ===
      this.global.appConstant.pm.DELIVERY_STATUS_DONE
        ? 'view'
        : this.todo;
    let sow = new Sow();
    let sowPaymentTerm = new SowPaymentTerm();
    let indexSow = 0;
    let indexTerm = 0;
    this.sowList.forEach((sow1, index1) => {
      sow1.sowPaymentTermList.forEach((term, index2) => {
        const item = term.sowPaymentTermItemList.find(
          x => x.id === sowPaymentTermItem.id
        );
        if (item) {
          indexSow = index1;
          indexTerm = index2;
          sow = sow1;
          sowPaymentTerm = term;
          this.sowTouchedId = sow1.id;
        }
      });
    });

    if (
      sowPaymentTermItem.deliveryStatus.code ===
        this.global.appConstant.pm.DELIVERY_STATUS_ON_PROGRESS ||
      sowPaymentTermItem.deliveryStatus.code ===
        this.global.appConstant.pm.DELIVERY_STATUS_DONE ||
      (sowPaymentTermItem.deliveryStatus.code ===
        this.global.appConstant.pm.DELIVERY_STATUS_CLOSED &&
        sowPaymentTermItem.deliveryPercentage)
    ) {
      this.appPopupService
        .open(
          DrPopupDeliveryProcessComponent,
          {
            todo,
            dr: this.dr,
            drShippingList: this.response.drShippingList,
            sow,
            sowPaymentTerm,
            sowPaymentTermItem,
            drShippingItemList: this.drShippingItemList
          },
          { size: 'xl' }
        )
        .subscribe(response => {
          console.log(response);
          if (response?.body) {
            this.sowList = response.body;
            this.formGroup.patchValue({ sowList: this.sowList });
            if (
              this.sowList[this.sowList.length - 1].deliveryStatus.code ===
              this.global.appConstant.pm.DELIVERY_STATUS_DONE
            ) {
              this.dr.deliveryStatus =
                this.sowList[this.sowList.length - 1].deliveryStatus;
              this.formGroup.setIsView(true);
            }
          }
          if (response['drShipping']) {
            this.response.drShippingList.forEach(drShipping => {
              if (drShipping.id === response['drShipping'].id) {
                drShipping.isFromDr = false;
                return;
              }
            });
            this.drShippingOptionList.setRequestValues(
              this.response.drShippingList
            );
            this.formGroup.patchValue({
              drShippingList: this.response.drShippingList
            });
          }
          this.global.modalService.confirmation(
            this.translateService.instant('dr.modal.saveSuccess.msg'),
            'dr.modal.saveSuccess.title',
            null,
            null,
            'pvc pv-check-circle',
            true,
            {
              autoCloseInMs: 1000
            }
          );

          const tableResponseGoods =
            this.tableItemList[indexSow][indexTerm].tableResponseGoods;
          const tableResponseService =
            this.tableItemList[indexSow][indexTerm].tableResponseService;
          tableResponseGoods.reload();
          tableResponseService.reload();
          this.setStateReady();
        });
    } else if (
      sowPaymentTermItem.deliveryStatus.code ===
      this.global.appConstant.pm.DELIVERY_STATUS_NOT_STARTED
    ) {
      this.global.alertService.showWarning(
        'dr.alert.notStarted',
        '.table-item-' +
          sowPaymentTermItem.prItem.item.itemType.code +
          '-' +
          +sowPaymentTerm.id,
        { static: false, totalAlert: 1 }
      );
    } else if (
      sowPaymentTermItem.deliveryStatus.code ===
      this.global.appConstant.pm.DELIVERY_STATUS_CANCELED
    ) {
      this.global.alertService.showWarning(
        'dr.alert.canceled',
        '.table-item-' +
          sowPaymentTermItem.prItem.item.itemType.code +
          '-' +
          +sowPaymentTerm.id,
        { static: false, totalAlert: 1 }
      );
    } else if (
      sowPaymentTermItem.deliveryStatus.code ===
        this.global.appConstant.pm.DELIVERY_STATUS_CLOSED &&
      !sowPaymentTermItem.deliveryPercentage
    ) {
      this.global.alertService.showWarning(
        'dr.alert.closed',
        '.table-item-' +
          sowPaymentTermItem.prItem.item.itemType.code +
          '-' +
          sowPaymentTerm.id,
        { static: false, totalAlert: 1 }
      );
    }
  }

  public doViewItem(drShip: DrShipping): void {
    const drShippingItemList = this.drShippingItemList.filter(
      osi => osi.drShipping.id === drShip.id
    );
    const address = drShip.address;
    const isView = true;

    this.appPopupService.open(
      DrPopupShippedItemComponent,
      {
        address,
        drShippingItemList,
        isView,
        allOShipItemList: this.drShippingItemList
      },
      { size: 'xl' }
    );
  }

  public doRequestPayment(sowPaymentTerm: SowPaymentTerm): void {
    const drRequest = new DrRequest();
    drRequest.dr = this.dr;
    drRequest.sowPaymentTerm = sowPaymentTerm;
    const paymentBilling = this.response.paymentBillingList.find(
      x => x.sowPaymentTerm.id === sowPaymentTerm.id && !x.isSubmitted
    );
    this.global.modalService
      .confirmation(
        'dr.confirmation.requestPayment.prompt',
        'dr.confirmation.requestPayment.title',
        'dr.confirmation.requestPayment.button.cancel',
        'dr.confirmation.requestPayment.button.yes',
        'pvc pv-wallet',
        false
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.global.routerParams.set('todo', 'add');
          this.global.routerParams.set('paymentBillingId', paymentBilling.id);
          this.router.navigate(['/pages/vendor-payment-request/add']);
        }
      });
  }

  public checkDisableReqPaymentBtn(sowPaymentTerm: SowPaymentTerm): boolean {
    return !(
      sowPaymentTerm.isEnableRequestBill &&
      ((sowPaymentTerm.billingMethod.code ===
        this.global.appConstant.pm.BILLING_METHOD_NON_PARTIAL &&
        sowPaymentTerm.deliveryStatus.code ===
          this.global.appConstant.pm.DELIVERY_STATUS_DONE) ||
        (sowPaymentTerm.billingMethod.code ===
          this.global.appConstant.pm.BILLING_METHOD_PARTIAL &&
          (sowPaymentTerm.deliveryStatus.code ===
            this.global.appConstant.pm.DELIVERY_STATUS_ON_PROGRESS ||
            sowPaymentTerm.deliveryStatus.code ===
              this.global.appConstant.pm.DELIVERY_STATUS_DONE)))
    );
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public getUserNameList(addressPicList: AddressPic[]): string {
    return addressPicList.map(a => a.user.name).join(', ');
  }

  public doDeleteShipping(drShipping: DrShipping) {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.httpClientService
            .post<Response<DrShipping>>('/dr/delete-dr-address', drShipping)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                const indexOfRecord = this.response.drShippingList.findIndex(
                  drShip => drShip.id === drShipping.id
                );

                this.response.drShippingList.splice(indexOfRecord, 1);
                this.drShippingOptionList.setRequestValues(
                  this.response.drShippingList
                );
                this.formGroup.patchValue({
                  drShippingList: this.response.drShippingList
                });

                this.setStateReady();
                this.global.alertService.showSuccess(
                  this.global.translateService.instant(
                    'app.alert.msg.saveSuccess'
                  )
                );
              } else {
                this.setStateReady();
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doGetRealizationDate(sow: Sow): string {
    const realizationDate = new Date(sow.realizationDate);
    const endDate = new Date(sow.endDate);

    const time = realizationDate.getTime() - endDate.getTime();
    let realizationDays = time / (1000 * 3600 * 24);
    realizationDays = Math.round(
      +this.global.converterService.convertDecimal(+realizationDays)
    );
    if (realizationDays > 0) {
      return (
        Math.abs(realizationDays) +
        ' ' +
        this.translateService.instant('dr.detail.realizationDelayed')
      );
    } else if (realizationDays < 0) {
      return (
        Math.abs(realizationDays) +
        ' ' +
        this.translateService.instant('dr.detail.realizationFaster')
      );
    } else {
      return this.translateService.instant('dr.detail.realizationOnTime');
    }
  }
}
