<app-layout
  [isLoading]="formLoading"
  [backTo]="urlBackOutside"
  header="refutation.breadcrumb.index"
>
  <ng-template #additionalTitle *ngIf="!formLoading && isShowBadgeStatus">
    <app-badge-catalog
      class="cml-2"
      *ngIf="procurement.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge
      *ngIf="todo !== 'add'"
      class="cml-2"
      [color]="badgeColor[procurement.procurementWorklistStatus.code]"
    >
      {{ getTranslateKey(procurement.procurementWorklistStatus) | translate }}
    </app-badge>
    <app-badge *ngIf="todo === 'add'" color="INFO" class="cml-2">
      {{ getTranslateKey(procurement.procurementWorklistStatus) | translate }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          (procurement?.procurementStatus?.code ===
            global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm
                .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT)
        "
      >
        {{ canceledOrFailedMessage | translate }}
      </app-alert-x>
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        *ngIf="!formLoading"
        novalidate
      >
        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="workflowStepInfoModel"
        >
        </app-workflow-step-info>
        <app-card
          header="refutation.procDetail.general.title"
          tips="refutation.procDetail.general.tips"
        >
          <app-procurement-info [procurementId]="procurementId">
          </app-procurement-info>
        </app-card>

        <app-item-request
          [objectList]="procurementItemList"
          [isView]="true"
          [isShowPrice]="true"
          [itemTypeList]="itemTypeList"
        >
        </app-item-request>

        <app-card
          header="refutation.vendorRefutation.general.title"
          tips="refutation.vendorRefutation.general.tips"
        >
          <div
            *ngIf="
              !formLoading &&
              procurement.vendorSelectionType.code ===
                global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
            "
          >
            <h4 class="separator mb-4">{{
              'refutation.winningVendorCandidate.general.title' | translate
            }}</h4>
            <app-table-xx
              [model]="tableResponseWinningCandidateVendor"
              *ngIf="!formLoading"
              [isShowSearch]="false"
              [isShowPagination]="false"
            >
            </app-table-xx>
            <h4 class="separator mb-4">{{
              'refutation.vendorParticipant.general.title' | translate
            }}</h4>
            <app-table-xx
              [model]="tableResponseVendorParticipant"
              *ngIf="!formLoading"
              [isShowSearch]="false"
              [isShowPagination]="false"
              (onClick)="doShowVendorRefutation($event)"
            >
            </app-table-xx>
            <div
              *ngIf="
                !formLoading &&
                procurement.procurementWorklistStatus.code !==
                  global.appConstant.pm
                    .PROCUREMENT_WORKLIST_STATUS_WAITING_RESPONSE
              "
            >
              <h4 class="separator mb-4">{{
                'refutation.refutationReview.general.title' | translate
              }}</h4>
              <app-fullfilled *ngIf="isMandatory"></app-fullfilled>
              <div class="form-group row text-sm-left">
                <label class="col-sm-3">
                  {{ 'refutation.form.refutationStatus' | translate }}
                </label>
                <div class="col-sm-9 text-left">
                  <app-dropdown-select
                    formControlName="refutationReviewStatus"
                    [optionList]="refutationReviewStatusOptionList"
                    placeholder="refutation.placeholder.chooseStatus"
                    (onChange)="onChangeStatus($event)"
                  >
                    <ng-template #value let-value>
                      <div
                        *ngIf="
                          value &&
                          value.code ===
                            global.appConstant.pm
                              .REFUTATION_REVIEW_STATUS_ACCEPTED
                        "
                      >
                        <em class="pic pi-thumbs-up-circle"></em>
                        <span style="color: 'var(--success)'">
                          {{ getTranslateKey(value) | translate }}
                        </span>
                      </div>

                      <div
                        *ngIf="
                          value &&
                          value.code ===
                            global.appConstant.pm
                              .REFUTATION_REVIEW_STATUS_NOT_ACCEPTED
                        "
                      >
                        <em class="pic pi-times-circle"></em>
                        <span style="color: 'var(--danger)'">
                          {{ getTranslateKey(value) | translate }}
                        </span>
                      </div>
                    </ng-template>
                    <ng-template #option let-data>
                      {{ getTranslateKey(data) | translate }}
                    </ng-template>
                  </app-dropdown-select>
                </div>
              </div>

              <div class="form-group row text-sm-left">
                <label class="col-sm-3">
                  {{ 'refutation.form.refutationDate' | translate }}
                </label>
                <div class="col-sm-9 text-left">
                  <app-date-picker-x
                    formControlName="refutationDate"
                    [minDate]="today"
                  ></app-date-picker-x>
                </div>
              </div>

              <div class="form-group row text-sm-left">
                <label class="col-sm-3">
                  {{ 'refutation.form.notes' | translate }}
                </label>
                <div class="col-sm-6 text-left">
                  <app-text-area
                    formControlName="note"
                    maxLength="512"
                    size="XL"
                  >
                  </app-text-area>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="
              !formLoading &&
              procurement.vendorSelectionType.code ===
                global.appConstant.pm.VENDOR_SELECTION_TYPE_MULTI_WINNER
            "
          >
            <app-tab [bordered]="true" *ngIf="!formLoading">
              <app-tab-item
                header="{{ 'refutation.tab.header.byItem' | translate }}"
              >
                <app-table-xx
                  [model]="tableResponseReferenceByItem"
                  *ngIf="!formLoading"
                  (onClick)="doClickRefutationItem($event)"
                ></app-table-xx>
              </app-tab-item>
              <app-tab-item
                header="{{ 'refutation.tab.header.byVendor' | translate }}"
              >
                <app-table-xx
                  [model]="tableResponseReferenceByVendor"
                  *ngIf="!formLoading"
                  (onClick)="doViewQuotation($event)"
                ></app-table-xx>
              </app-tab-item>
            </app-tab>
          </div>
        </app-card>

        <app-card
          header="refutation.officialReport.general.title"
          tips="refutation.officialReport.general.tips"
          *ngIf="
            !formLoading &&
            procurement.procurementWorklistStatus.code !==
              global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_WAITING_RESPONSE
          "
        >
          <app-alert-x
            color="WARNING"
            *ngIf="
              isOfficialReportNull &&
              procurement.procurementWorklistStatus.code !==
                global.appConstant.pm
                  .PROCUREMENT_WORKLIST_STATUS_CLOSED_RESPONSE
            "
            >{{
              'refutation.detail.officialReport.alertInfo' | translate
            }}</app-alert-x
          >
          <app-alert-x
            color="WARNING"
            *ngIf="
              procurement.procurementWorklistStatus.code ===
                global.appConstant.pm
                  .PROCUREMENT_WORKLIST_STATUS_CLOSED_RESPONSE &&
              todo !== 'view'
            "
            >{{
              'refutation.saveDraftFirst.msg.error' | translate
            }}</app-alert-x
          >
          <app-official-report
            [objectId]="procurementId"
            [templateCode]="templateCode"
            [appOfficialReportModel]="appOfficialReportModel"
            [mode]="todo"
            numberGeneratorCode="NUMBER"
            (onChange)="doOnChange($event)"
            [isShowButtonGenerate]="todo !== 'view'"
          ></app-official-report>
        </app-card>

        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            refutationResponse.approvalPathResponseModel &&
            !refutationResponse.isUserApproval &&
            (procurement.workflowModelPrcs.code !== 'REFUTATION' ||
              (procurement.workflowModelPrcs.code === 'REFUTATION' &&
                procurement.workflowPrcs?.workflowStatus?.code ===
                  global.appConstant.core.WORKFLOW_CODE_STATUS_REVISION))
          "
          [workflowPrcs]="refutationResponse?.procurement?.workflowPrcs"
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="
            refutationResponse.approvalPathResponseModel
          "
        >
        </app-approval-monitoring>
        <app-approval-prcs-x
          *ngIf="!formLoading && refutationResponse.isUserApproval"
          [workflowModelPrcs]="refutationResponse.procurement.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>

        <div class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div
                class="button-group button-group-center cmt-4"
                *ngIf="todo !== 'view'"
              >
                <app-button
                  color="PRIMARY"
                  [outline]="true"
                  (onClick)="doSave()"
                  [disabled]="formSaving || formGroup.isView"
                  >{{ 'app.button.save' | translate }}
                </app-button>
                <app-button
                  (onClick)="doSubmit()"
                  [disabled]="formSaving || formGroup.isView"
                  >{{ 'app.button.submit' | translate }}</app-button
                >
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
