import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { AanwijzingParticipant } from 'src/app/core/bean/aanwijzing-participant';
import { ParticipantRole } from 'src/app/core/bean/participant-role';
import { Vendor } from 'src/app/core/bean/vendor';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';

@Component({
    templateUrl: './aanwijzing-popup-invite-participant-new.component.html'
})
export class AanwijzingPopupInviteParticipantNewComponent extends BaseComponentComponent {

    @Input() participantRoleList: ParticipantRole[];
    @Input() vendorList: Vendor[];
    @Input() participantList: AanwijzingParticipant[];
    @Output() public onChange: EventEmitter<AanwijzingParticipant> = new EventEmitter();
    public participantRoleOptionList: OptionListModel<ParticipantRole> = new OptionListModel(true);
    public vendorOptionList: OptionListModel<Vendor> = new OptionListModel(true);
    constructor(public translateService: TranslateService,
                public appPopupService: AppPopupService,
                public toastService: ToastService) {
        super('aanwijzing');
    }

    public onInit(): void {
        this.buildFormGroup();
        this.setFormGroup();
    }

    public buildFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            participantRole: [null, Validators.required()],
            vendor: [null],
            email: [null, Validators.compose([Validators.required(), Validators.email()])]
        });
    }

    public setFormGroup(): void {
        this.participantRoleOptionList.setRequestValues(this.participantRoleList);
        this.vendorOptionList.setRequestValues(this.vendorList);
        this.setStateReady();
    }

    public doChangeRole(prole: ParticipantRole): void {
        if (prole && prole.code === this.global.appConstant.pm.PARTICIPANT_ROLE_VENDOR) {
            this.formGroup.get('vendor').setValidators([Validators.required()]);
            this.formGroup.get('vendor').updateValueAndValidity();
        } else {
            this.formGroup.get('vendor').clearValidators();
            this.formGroup.get('vendor').updateValueAndValidity();
        }
    }

    public doAdd(): void {
        this.validate();
        const email: string = this.formGroup.value.email;
        const isEmailExist = !!this.participantList.find(p => p.email === email || p.user?.email === email || p.vendorPIC?.email === email);
        if (this.formGroup.valid && !isEmailExist) {
            this.onChange.emit(this.formGroup.value);
        } else if (isEmailExist) {
            this.toastService.showError('aanwijzing.alert.msg.emailParticipantExist');
        }
    }

    public getTranslateKey(data): string {
        if (data?.translationKey) {
            return data.translationKey.module.code.toLowerCase() + '.' + data.translationKey.key;
        } else {
            return data?.name;
        }
    }

}
