import { Injectable } from '@angular/core';
import { dispatchTable } from './action/dispatch-table-action';
import * as tableAction from './action/table-action';
import { TableResponseModel } from './model/table-response-model';
import { TableState } from './model/table-state';
@Injectable()
export class TableService {
  private state: TableState;
  constructor() {}

  public setState(
    model: TableResponseModel<any>,
    stringUrl: string,
    isView: boolean,
    isMultipleSelect: boolean,
    isShowPagination: boolean
  ): TableState {
    this.state = TableState.create(
      model,
      stringUrl,
      isView,
      isMultipleSelect,
      isShowPagination
    );
    return this.state;
  }

  public dispatch(action: tableAction.AllTableAction): void {
    this.state.setStateLoading();
    dispatchTable(this.state, action);
  }

  public reload(): void {
    this.state.requestReload.emit();
  }
}
