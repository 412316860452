<app-card
  header="work-plan-contract.title"
  [isLoading]="formLoading"
  [isSaving]="formSaving"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div formArrayName="contractList">
      <app-fieldset
        legend="{{ 'work-plan-contract.fieldset.contract' | translate }} {{
          i + 1
        }}"
        *ngFor="let contract of formArrayContractList.controls; let i = index"
        [formGroup]="contract"
      >
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'work-plan-contract.form.number' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            <input
              class="form-control validation-field"
              type="text"
              formControlName="contractNumber"
              (keyup)="checkContractNumber(i)"
              [ngClass]="{
                'is-invalid': submitted && contract.get('contractNumber').errors
              }"
              aria-label="Contract Number"
            />
            <small
              class="text-danger"
              *ngIf="
                (submitted && contract.get('contractNumber').errors) ||
                (contract.get('contractNumber').touched &&
                  contract.get('contractNumber').errors)
              "
            >
              {{ 'app.validation.required' | translate }}</small
            >
            <small class="text-danger" *ngIf="!isContractValidList[i]"
              >{{ sameContractNumberList[i] }}
              {{
                'work-plan-purchase-request.msg.error.used' | translate
              }}</small
            >
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'work-plan-contract.form.department' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <label class="control-label">{{
              workPlanContractDTO.organizationName
            }}</label>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'work-plan-contract.form.startDate' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <app-date-picker formControlName="startDate"> </app-date-picker>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'work-plan-contract.form.endDate' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <app-date-picker formControlName="endDate" minDate="startDate">
            </app-date-picker>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'work-plan-contract.form.description' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <textarea
              class="form-control"
              formControlName="description"
              maxlength="512"
              rows="4"
              [ngClass]="{
                'is-invalid':
                  submitted && contract.controls['description'].errors
              }"
              aria-label="Description"
            ></textarea>
            <!-- <small class="text-danger" *ngIf="(submitted && contract.controls['description'].errors) || (contract.controls['description'].touched && contract.controls['description'].errors)"> -->
            <!-- {{ 'app.validation.required' | translate }}</small> -->
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'work-plan-contract.form.uploadFile' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            <app-upload-multiple
              formControlName="file"
              [fileUploader]="fileUploaderList[i]"
              (onChange)="onChangeFileUpload()"
            >
            </app-upload-multiple>
          </div>
        </div>

        <div formArrayName="purchaseRequestList" class="mb-4">
          <div
            [formGroupName]="j"
            *ngFor="
              let purchaseRequestObj of contract.get('purchaseRequestList')
                .controls;
              let j = index
            "
          >
            <app-fieldset
              legend="{{
                'work-plan-contract.fieldset.purchaseRequisition' | translate
              }} {{ j + 1 }}"
            >
              <div class="form-group row text-sm-right required">
                <label class="col-sm-3 control-label">{{
                  'work-plan-contract.form.purchaseRequestNumber' | translate
                }}</label>
                <div class="col-sm-6 text-left">
                  <select
                    (change)="doOnChangePurchaseRequest(purchaseRequestObj)"
                    class="form-control validation-field"
                    required
                    formControlName="purchaseRequest"
                    aria-label="Purchase Request"
                  >
                    <option
                      *ngFor="
                        let purchaseRequest of workPlanContractDTO.purchaseRequestList
                      "
                      [ngValue]="purchaseRequest"
                      [hidden]="purchaseRequest.isDisabled"
                    >
                      {{ purchaseRequest.name }}
                    </option>
                  </select>
                  <small
                    class="text-danger"
                    *ngIf="
                      purchaseRequestObj.controls['purchaseRequest'].invalid &&
                      purchaseRequestObj.controls['purchaseRequest'].touched
                    "
                    >{{ 'app.validation.required' | translate }}</small
                  >
                </div>
              </div>
              <div
                class="table-responsive custom-table mb-4 float-none"
                [ngStyle]="{ margin: '0 auto' }"
              >
                <table
                  class="table table-striped box-shadow"
                  aria-describedby="Table"
                >
                  <thead>
                    <tr>
                      <th scope="col"
                        >{{
                          'work-plan-contract.table.column.number' | translate
                        }}
                      </th>
                      <th scope="col">{{
                        'work-plan-contract.table.column.codeItem' | translate
                      }}</th>
                      <th scope="col">{{
                        'work-plan-contract.table.column.nameItem' | translate
                      }}</th>
                      <th scope="col">{{
                        'work-plan-contract.table.column.unit' | translate
                      }}</th>
                      <th scope="col">{{
                        'work-plan-contract.table.column.category' | translate
                      }}</th>
                      <th scope="col" class="text-sm-right"
                        >{{ 'work-plan-contract.table.column.qty' | translate }}
                      </th>

                      <th scope="col" class="text-sm-right">{{
                        'work-plan-contract.table.column.realizationPrice'
                          | translate
                      }}</th>
                      <th scope="col" class="text-sm-right">{{
                        'work-plan-contract.table.column.realizationTotal'
                          | translate
                      }}</th>
                      <th scope="col">{{
                        'work-plan-contract.table.column.budgetCode' | translate
                      }}</th>
                    </tr>
                  </thead>
                  <tbody formArrayName="purchaseRequestItemList">
                    <tr
                      *ngIf="
                        purchaseRequestObj.value.purchaseRequestItemList
                          .length == 0
                      "
                    >
                      <td colspan="99" class="bg-white text-center">
                        {{ 'app.info.noData' | translate }}
                      </td>
                    </tr>
                    <tr
                      [formGroupName]="x"
                      *ngFor="
                        let purchaseRequestItem of purchaseRequestObj.get(
                          'purchaseRequestItemList'
                        ).controls;
                        let x = index
                      "
                    >
                      <td class="text-center">{{ x + 1 }}.</td>
                      <td
                        >{{ purchaseRequestItem.value.workPlanItem.item.code }}
                      </td>
                      <td
                        >{{ purchaseRequestItem.value.workPlanItem.item.name }}
                      </td>
                      <td
                        >{{
                          purchaseRequestItem.value.workPlanItem.item.uom.name
                        }}
                      </td>
                      <td>{{
                        purchaseRequestItem.value.workPlanItem.item.itemCategory
                          .name
                      }}</td>
                      <td class="text-sm-right">{{
                        purchaseRequestItem.value.quantity
                      }}</td>
                      <td class="text-sm-right"
                        >{{ purchaseRequestItem.value.convertUnitPriceRealize }}
                      </td>
                      <td class="text-sm-right"
                        >{{
                          purchaseRequestItem.value.convertTotalPriceRealize
                        }}
                      </td>
                      <td
                        >{{
                          purchaseRequestItem.value.workPlanItem.budget.number
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6"></td>
                      <td class="text-sm-right">
                        <strong>{{
                          'work-plan-contract.form.totalRealize' | translate
                        }}</strong>
                      </td>
                      <td class="text-sm-right">
                        <strong>{{
                          purchaseRequestObj.value.totalRealisasi
                        }}</strong>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="form-group row text-sm-right required">
                <label class="col-sm-3 control-label">{{
                  'work-plan-contract.form.vendor' | translate
                }}</label>
                <div class="col-sm-6 text-left">
                  <input
                    class="form-control validation-field"
                    type="text"
                    formControlName="vendor"
                    [ngClass]="{
                      'is-invalid':
                        submitted && purchaseRequestObj.get('vendor').errors
                    }"
                    aria-label="Vendor"
                  />
                  <small
                    class="text-danger"
                    *ngIf="
                      (submitted && purchaseRequestObj.get('vendor').errors) ||
                      (purchaseRequestObj.get('vendor').touched &&
                        purchaseRequestObj.get('vendor').errors)
                    "
                    >{{ 'app.validation.required' | translate }}</small
                  >
                </div>
              </div>

              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label"></label>
                <div class="col-sm-6 text-center">
                  <button
                    *ngIf="contract.get('purchaseRequestList').length > 1"
                    type="button "
                    class="btn btn-danger ml-2"
                    (click)="
                      doDeletePurchaseRequest(
                        j,
                        i,
                        contract.get('purchaseRequestList')
                      )
                    "
                  >
                    {{
                      'work-plan-contract.button.deletePurchaseRequest'
                        | translate
                    }}
                  </button>
                </div>
              </div>
            </app-fieldset>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <span [ngbTooltip]="msgInfo" placement="top">
            <button
              type="button "
              class="btn btn-info ml-2"
              (click)="onClickAddPurchaseRequest(i)"
              [disabled]="isDisabledAddMore || formGroup.invalid"
            >
              <span class="fas fa-plus"></span>
              <span>
                {{
                  'work-plan-contract.button.addPurchaseRequest' | translate
                }}</span
              >
            </button>
          </span>
        </div>

        <div class="row justify-content-center mt-5">
          <button
            *ngIf="formGroup.get('contractList').length > 1"
            type="button "
            class="btn btn-danger ml-2"
            (click)="doDeleteContract(i)"
          >
            {{ 'work-plan-contract.button.deleteContract' | translate }}
          </button>
        </div>
      </app-fieldset>
      <div class="row justify-content-center mt-5">
        <span [ngbTooltip]="msgInfo" placement="top">
          <button
            type="button "
            class="btn btn-info ml-2"
            (click)="onClickAddContract()"
            [disabled]="isDisabledAddMore || formGroup.invalid"
          >
            <span class="fas fa-plus"></span>
            <span>
              {{ 'work-plan-contract.button.addContract' | translate }}</span
            >
          </button>
        </span>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <button type="button " class="btn btn-primary ml-2" (click)="doSave()">
        {{ 'app.button.save' | translate }}
      </button>
    </div>
  </form>

  <div class="row justify-content-center mt-5">
    <button
      type="button "
      class="btn btn-secondary"
      (click)="doPrevious()"
      [disabled]="formSaving"
    >
      {{ 'app.button.previous' | translate }}
    </button>
    <button
      type="button "
      class="btn btn-primary ml-2"
      (click)="doNext()"
      [disabled]="!isHaveContract"
    >
      {{ 'app.button.next' | translate }}
    </button>
  </div>
</app-card>
