import { CurrencyPipe } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Contract } from '../../bean/contract';
import { ProcurementFines } from '../../bean/procurement-fines';
import { ProcurementGuarantee } from '../../bean/procurement-guarantee';
import { ProcurementSow } from '../../bean/procurement-sow';
import { AppPopupProcurementGuaranteeService } from '../app-popup/app-popup-procurement-guarantee/app-popup-procurement-guarantee.service';
import { AppPopupProcurementScopeWorkService } from '../app-popup/app-popup-procurement-scope-work/app-popup-procurement-scope-work.service';
import { AppTableComponent } from '../app-table/app-table.component';
import { FieldFormatEnum } from '../app-table/model/field-format.enum';
import { TableResponseModel } from '../app-table/model/table-response-model';
import { AppContractRequestViewResponse } from './app-contract-request-view-response';
@Component({
  selector: 'app-contract-request-view',
  templateUrl: './app-contract-request-view.component.html'
})
export class AppContractRequestViewComponent extends BaseComponentComponent {
  @Input() public contractId: number;
  @Input() public tableResponse;
  @ViewChild('selectorStageOfWork') tableStageOfWork: AppTableComponent;
  @ViewChild('selectorGuarantee') tableGuarantee: AppTableComponent;
  @ViewChild('selectorFines') tableFines: AppTableComponent;

  public tableResponseStageOfWork: TableResponseModel<ProcurementSow>;
  public tableResponseGuarantee: TableResponseModel<ProcurementGuarantee>;
  public tableResponseFines: TableResponseModel<ProcurementFines>;

  public availableAmountSow: number;
  public procurementFinesList: ProcurementFines[] = [];
  public procurementGuaranteeList: ProcurementGuarantee[] = [];
  public appContractRequestViewResponse: AppContractRequestViewResponse =
    new AppContractRequestViewResponse();
  public CURRENCY_DIGITS_INFO: string;
  public isMaintenanceGuaranteeExist = false;

  constructor(
    public translateService: TranslateService,
    public appPopupProcurementScopeWorkService: AppPopupProcurementScopeWorkService,
    public appPopupProcurementGuaranteeService: AppPopupProcurementGuaranteeService,
    public currency: CurrencyPipe
  ) {
    super('app-contract-request-view');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.getData();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public buildTableResponse(): void {
    if (this.appContractRequestViewResponse.contract.isFinalized) {
      this.tableResponseStageOfWork = new TableResponseModel(this.moduleCode, [
        {
          field: 'stageOfWork.name',
          header: 'table.column.stageOfWork'
        },
        {
          field: 'totalTermin',
          header: 'table.column.totalTermin',
          customClass: 'text-center'
        },
        {
          field: 'amount',
          header: 'table.column.amount',
          format: FieldFormatEnum.Currency
        },
        {
          field: 'startDate',
          header: 'table.column.startDate',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'endDate',
          header: 'table.column.endDate',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'status',
          header: 'table.column.status',
          customClass: 'text-center',
          datamap:
            '{"1" : "' +
            this.global.translateService.instant(
              'app-contract-request-view.status.new'
            ) +
            '", "2" : "' +
            this.global.translateService.instant(
              'app-contract-request-view.status.onProgress'
            ) +
            '", "3" : "' +
            this.global.translateService.instant(
              'app-contract-request-view.status.closed'
            ) +
            '"}',
          fn: this.handleStatusTextColor.bind(this)
        }
      ]);
    } else {
      this.tableResponseStageOfWork = new TableResponseModel(this.moduleCode, [
        {
          field: 'stageOfWork.name',
          header: 'table.column.stageOfWork'
        },
        {
          field: 'totalTermin',
          header: 'table.column.totalTermin',
          customClass: 'text-center'
        },
        {
          field: 'amount',
          header: 'table.column.amount',
          format: FieldFormatEnum.Currency
        },
        {
          field: 'startDate',
          header: 'table.column.startDate',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'endDate',
          header: 'table.column.endDate',
          format: FieldFormatEnum.ShortDate
        }
      ]);
    }

    this.tableResponseGuarantee = new TableResponseModel(this.moduleCode, [
      {
        field: 'guaranteeBondType.name',
        header: 'table.column.type',
        customClass: 'text-center'
      },
      {
        field: 'name',
        header: 'table.column.name'
      },
      {
        field: 'percentageValue',
        header: 'table.column.percentageValue',
        customClass: 'text-right',
        fn: this.setDecimal.bind(this)
      },
      {
        field: 'amount',
        header: 'table.column.amount',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'submissionDate',
        header: 'table.column.submissionDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'description',
        header: 'table.column.description',
        customClass: 'white-space-normal'
      }
    ]);

    this.tableResponseFines = new TableResponseModel(this.moduleCode, [
      {
        field: 'fines.name',
        header: 'table.column.type',
        customClass: 'text-center'
      },
      {
        field: 'percentageValue',
        header: 'table.column.percentageValue',
        customClass: 'text-right',
        fn: this.setDecimal.bind(this)
      },
      {
        field: 'amount',
        header: 'table.column.amount',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'description',
        header: 'table.column.description',
        customClass: 'white-space-normal'
      }
    ]);
  }

  public getData(): void {
    this.httpClientService
      .get<AppContractRequestViewResponse>(
        '/app-contract-request-view/index/' + this.contractId
      )
      .subscribe(response => {
        this.appContractRequestViewResponse = response;
        this.procurementGuaranteeList = response.procurementGuaranteeList;
        this.procurementFinesList = response.procurementFinesList;
        this.checkMaintenanceGuarantee();
        this.buildTableResponse();
        this.doSetProcurementSowList();
        this.setTableResponse();
        this.setStateReady();
      });
  }

  public checkMaintenanceGuarantee(): void {
    this.procurementGuaranteeList.forEach(guarantee => {
      if (guarantee.guaranteeBondType.code === this.global.appConstant.cm.GUARANTEE_BOND_TYPE_JAMINAN_PEMELIHARAAN) {
        this.isMaintenanceGuaranteeExist = true;
      }
    });
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      procurementSowList: this.formBuilder.array([])
    });
  }
  public get procurementSowList(): FormArray {
    return this.formGroup.get('procurementSowList') as FormArray;
  }
  public setTableResponse(): void {
    this.tableResponseStageOfWork.page.records = this.procurementSowList.value;
    this.tableResponseStageOfWork.page.totalRecords =
      this.procurementSowList.length;
    this.tableResponseStageOfWork.reloadClient();

    this.tableResponseGuarantee.page.records = this.procurementGuaranteeList;
    this.tableResponseGuarantee.page.totalRecords =
      this.procurementGuaranteeList.length;
    this.tableResponseGuarantee.reloadClient();

    this.tableResponseFines.page.records = this.procurementFinesList;
    this.tableResponseFines.page.totalRecords =
      this.procurementFinesList.length;
    this.tableResponseFines.reloadClient();
  }

  public doSetProcurementSowList(): void {
    if (this.appContractRequestViewResponse.procurementSowList !== null) {
      this.appContractRequestViewResponse.procurementSowList.forEach(
        procurementSow => {
          const procurementSowPaymentTermList = [];
          procurementSowPaymentTermList.push(
            procurementSow.procurementSowPaymentTermList
          );
          let amount = 0;
          procurementSow.procurementSowPaymentTermList.forEach(
            (procurementSowPaymentTerm: any) => {
              procurementSowPaymentTerm.procurementSowPaymentTermItemProductList =
                [];
              procurementSowPaymentTerm.procurementSowPaymentTermItemServiceList =
                [];
              amount = amount + +procurementSowPaymentTerm.amount;
              procurementSowPaymentTerm.procurementSowPaymentTermItemList.forEach(
                procurementSowItem => {
                  if (
                    procurementSowItem.procurementResult.procurementItem.item
                      .itemType.code ===
                    this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                  ) {
                    procurementSowPaymentTerm.procurementSowPaymentTermItemProductList.push(
                      {
                        procurementResult: procurementSowItem.procurementResult,
                        quantity: procurementSowItem.quantity,
                        remainingQuantity: procurementSowItem.remainingQuantity
                      }
                    );
                  }
                  if (
                    procurementSowItem.procurementResult.procurementItem.item
                      .itemType.code ===
                    this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
                  ) {
                    procurementSowPaymentTerm.procurementSowPaymentTermItemServiceList.push(
                      {
                        procurementResult: procurementSowItem.procurementResult,
                        quantity: procurementSowItem.quantity,
                        remainingQuantity: procurementSowItem.remainingQuantity
                      }
                    );
                  }
                }
              );
            }
          );
          const sow = this.formBuilder.group({
            procurementScopeWork: procurementSow.procurementScopeWork,
            stageOfWork: procurementSow.stageOfWork,
            procurementSowPaymentTermList,
            startDate: procurementSow.startDate,
            endDate: procurementSow.endDate,
            totalTermin: procurementSow.procurementSowPaymentTermList.length,
            amount,
            periodType: procurementSow.periodType,
            duration: procurementSow.duration,
            durationType: procurementSow.durationType,
            status: procurementSow.status,
            id: procurementSow.id
          });
          this.procurementSowList.push(sow);
        }
      );
    }
  }

  public doSetTotalAmountSow(): void {
    const amount =
      this.appContractRequestViewResponse.contract.procurementVendor.amount;
    let totalAmountSow = 0;
    this.procurementSowList.value.forEach(procurementSow => {
      totalAmountSow = totalAmountSow + +procurementSow.amount;
    });
    this.availableAmountSow = +amount - +totalAmountSow;
  }

  public doDetailStageOfWork(procurementSowEdit): void {
    this.doSetTotalAmountSow();
    this.httpClientService
      .get<Contract>(
        '/app-contract-request-view/detail-sow/' +
          this.appContractRequestViewResponse.contract.procurementVendor.id
      )
      .subscribe(appContractRequestViewResponse => {
        const todo = 'view';
        const header = this.translateService.instant(
          'app-popup-procurement-scope-work.popup.title.viewStageOfWork'
        );
        const stageOfWorkList =
          appContractRequestViewResponse.stageOfWorkList.filter(stageOfWork =>
            this.procurementSowList.value.every(
              procurementSow =>
                procurementSow.stageOfWork.id !== stageOfWork.id ||
                procurementSow.stageOfWork.id === procurementSow.stageOfWork.id
            )
          );
        const paymentTermList = appContractRequestViewResponse.paymentTermList;
        const billingTermList = appContractRequestViewResponse.billingTermList;
        const contract = this.appContractRequestViewResponse.contract;
        const procurementVendor = this.formGroup.value.procurementVendor;
        const procurementResultProductList =
          appContractRequestViewResponse.procurementResultProductList;
        const procurementResultServiceList =
          appContractRequestViewResponse.procurementResultServiceList;
        const availableAmountSow =
          this.availableAmountSow + +procurementSowEdit.amount;
        let procurementSowPaymentList: any;
        if (this.procurementSowList.length > 0) {
          procurementSowPaymentList =
            this.procurementSowList.value[this.procurementSowList.length - 1]
              .procurementSowPaymentTermList[0];
        }
        let procurementPaymentItemList: any;
        if (this.procurementSowList.length > 0) {
          procurementPaymentItemList =
            this.procurementSowList.value[this.procurementSowList.length - 1]
              .procurementSowPaymentTermList[0];
        }
        const procurementSowList = this.procurementSowList;
        const contractType = contract.contractType;
        const dealingAgreementType = contract.dealingAgreementType;

        this.appPopupProcurementScopeWorkService.open(
          todo,
          header,
          procurementSowEdit,
          stageOfWorkList,
          paymentTermList,
          billingTermList,
          procurementResultServiceList,
          procurementResultProductList,
          contract,
          procurementVendor,
          availableAmountSow,
          procurementPaymentItemList,
          procurementSowList,
          procurementSowPaymentList,
          contractType,
          dealingAgreementType,
          false
        );
      });
  }

  public handleStatusTextColor(value: string, record: any): HTMLElement {
    this.log.debug(value);
    const spanElement = document.createElement('span');
    if (record.status === 1) {
      spanElement.style.color = 'var(--black)';
      spanElement.textContent = this.global.translateService.instant(
        'app-contract-request-view.status.new'
      );
    } else if (record.status === 2) {
      spanElement.style.color = 'var(--primary)';
      spanElement.textContent = this.global.translateService.instant(
        'app-contract-request-view.status.onProgress'
      );
    } else if (record.status === 3) {
      spanElement.style.color = 'var(--success)';
      spanElement.textContent = this.global.translateService.instant(
        'app-contract-request-view.status.closed'
      );
    }
    return spanElement;
  }

  public doEditGuarantee(procurementGuaranteeEdit: ProcurementGuarantee): void {
    const todo = 'edit';
    const header = this.translateService.instant(
      'procurement-scope-work.popup.title.editGuarantee'
    );
    const guaranteeList =
      this.appContractRequestViewResponse.guaranteeBondTypeList.filter(
        guarantee =>
          this.procurementGuaranteeList.every(
            procurementGuarantee =>
              procurementGuarantee.guaranteeBondType.id !== guarantee.id ||
              procurementGuarantee.guaranteeBondType.id ===
                procurementGuaranteeEdit.guaranteeBondType.id
          )
      );
    const guaranteeBondTypeList = guaranteeList;
    const contract = this.appContractRequestViewResponse.contract;

    this.appPopupProcurementGuaranteeService
      .open(
        todo,
        header,
        procurementGuaranteeEdit,
        guaranteeBondTypeList,
        contract,
        null
      )
      .subscribe(procurementGuaranteeTemp => {
        this.procurementGuaranteeList.forEach((procurementGuarantee, index) => {
          if (procurementGuarantee === procurementGuaranteeEdit) {
            this.procurementGuaranteeList[index] = procurementGuaranteeTemp;
          }
        });

        this.tableResponseGuarantee.page.records =
          this.procurementGuaranteeList;
        this.tableResponseGuarantee.page.totalRecords =
          this.procurementGuaranteeList.length;
        this.tableGuarantee.reloadClient();
        this.tableResponse.reloadClient();
      });
  }

  public setDecimal(field: number): string {
    return this.currency.transform(field, '', '', this.CURRENCY_DIGITS_INFO);
  }

  public doEditStageOfWork(procurementSowEdit: any): void {
    this.doSetTotalAmountSow();
    this.httpClientService
      .get<Contract>(
        '/app-contract-request-view/detail-sow/' +
          this.appContractRequestViewResponse.contract.procurementVendor.id
      )
      .subscribe(appContractRequestViewResponse => {
        const todo = 'edit';
        const header = this.translateService.instant(
          'app-popup-procurement-scope-work.popup.title.editStageOfWork'
        );
        const stageOfWorkList =
          appContractRequestViewResponse.stageOfWorkList.filter(stageOfWork =>
            this.procurementSowList.value.every(
              procurementSow =>
                procurementSow.stageOfWork.id !== stageOfWork.id ||
                procurementSow.stageOfWork.id === procurementSow.stageOfWork.id
            )
          );
        const paymentTermList = appContractRequestViewResponse.paymentTermList;
        const billingTermList = appContractRequestViewResponse.billingTermList;
        const contract = this.appContractRequestViewResponse.contract;
        const procurementVendor = this.formGroup.value.procurementVendor;
        const procurementResultProductList =
          appContractRequestViewResponse.procurementResultProductList;
        const procurementResultServiceList =
          appContractRequestViewResponse.procurementResultServiceList;
        const availableAmountSow =
          this.availableAmountSow + +procurementSowEdit.amount;
        let procurementSowPaymentList: any;
        if (this.procurementSowList.length > 0) {
          procurementSowPaymentList =
            this.procurementSowList.value[this.procurementSowList.length - 1]
              .procurementSowPaymentTermList[0];
        }
        let procurementPaymentItemList: any;
        if (this.procurementSowList.length > 0) {
          procurementPaymentItemList =
            this.procurementSowList.value[this.procurementSowList.length - 1]
              .procurementSowPaymentTermList[0];
        }
        const procurementSowList = this.procurementSowList;
        const contractType = contract.contractType;
        const dealingAgreementType = contract.dealingAgreementType;
        this.appPopupProcurementScopeWorkService
          .open(
            todo,
            header,
            procurementSowEdit,
            stageOfWorkList,
            paymentTermList,
            billingTermList,
            procurementResultServiceList,
            procurementResultProductList,
            contract,
            procurementVendor,
            availableAmountSow,
            procurementPaymentItemList,
            procurementSowList,
            procurementSowPaymentList,
            contractType,
            dealingAgreementType,
            false
          )
          .subscribe(procurementSowTemp => {
            this.procurementSowList.value.forEach((procurementSow, index) => {
              if (
                JSON.stringify(procurementSow) ===
                JSON.stringify(procurementSowEdit)
              ) {
                this.procurementSowList.value[index].endDate =
                  procurementSowTemp.endDate;
                this.procurementSowList.value[index].startDate =
                  procurementSowTemp.startDate;
                this.procurementSowList.value[index].duration =
                  procurementSowTemp.duration;
              }
            });
            this.appContractRequestViewResponse.procurementSowList =
              this.procurementSowList.value;
            this.tableResponseStageOfWork.page.records =
              this.procurementSowList.value;
            this.tableResponseStageOfWork.page.totalRecords =
              this.procurementSowList.length;
            this.tableResponseStageOfWork.sortRecords(
              this.procurementSowList.value
            );
            this.tableResponseStageOfWork.sortOrder = 'DESC';
            this.tableResponseStageOfWork.setSortField('stageOfWork.name');
            this.tableResponseStageOfWork.reload();
            this.doSetTotalAmountSow();
            this.tableResponse.reloadClient();
          });
      });
  }
}
