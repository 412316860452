import { BaseEntity } from '../base/base-entity';
import { NotificationBlast } from '../bean/notification-blast';
import { NotificationType } from './../bean/notification-type';

export class NotificationJobEntity extends BaseEntity {
  notificationType: NotificationType;
  notificationBlast: NotificationBlast;
  subject: string;
  body: string;
  receiver: string;
  sender: string;
  cc: string;
  status: string;
  counter: string;
  logDate: Date;
}
