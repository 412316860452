import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalPrcsModule } from '../../core/components/app-approval-prcs/app-approval-prcs.module';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupDelegationComponent } from './popup/app-popup-delegation.component';
import { TabProfileVerificationComponent } from './tab-profile-verification.component';

export const routes = [
  {
    path: '',
    component: TabProfileVerificationComponent,
    data: { breadcrumb: '' }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppApprovalPrcsModule
  ],
  declarations: [
    TabProfileVerificationComponent,
    AppPopupDelegationComponent
  ],
  entryComponents: [
    AppPopupDelegationComponent
  ],
  exports: [
    TabProfileVerificationComponent,
    AppPopupDelegationComponent
  ]
})

export class TabProfileVerificationModule {}
