import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  Output,
  Provider,
  TemplateRef
} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { OptionListModel } from '../../model/option-list-model';
import { BaseComponentComponent } from './base-component.component';
export function makeProvider(typeComponentClass: object): Array<Provider> {
  return [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => typeComponentClass),
      multi: true
    }
  ];
}
@Component({
  template: ''
})
export abstract class BaseValueAccessorComponent<T>
  extends BaseComponentComponent
  implements ControlValueAccessor
{
  @Input() isDisabled = false;
  @Input() placeholder: string;
  @Input() size = 'MD';
  @Input() height = 'MD';
  @Input() tooltip: string | TemplateRef<object>;
  @Input() tooltipPosition: string;
  @Input() tooltipClass: string;
  @Input() stringUrl: string;
  @Input() optionList: OptionListModel<object>;
  @Input() optionView: string;
  @Input() optionValue: string;
  @Input() maxLength: number;
  @Input() value: T;
  @Input() formControlName: string;
  @Input() autofocus: boolean;
  @Input() isView: boolean;
  @Input() formControl: FormControl;
  @Input() valueAlignment: 'left' | 'right';
  @Input() showLimitValue: number;

  @Output() onChange: EventEmitter<
    string | number | boolean | object | Array<object>
  > = new EventEmitter();
  @Output() onInput: EventEmitter<string | number> = new EventEmitter();
  @Output() onBlur: EventEmitter<string | number> = new EventEmitter();
  @Output() onTouch: EventEmitter<string | number> = new EventEmitter();

  onBaseChange: () => void;
  onBaseTouched: () => void;

  public disabled = false;

  public readonly INPUT_SIZE: object = {
    TN: 'col-sm-1',
    SM: 'col-sm-2',
    MD: 'col-sm-4',
    LG: 'col-sm-7',
    XL: 'col-12'
  };

  public readonly INPUT_HEIGHT: object = {
    SM: 'form-control-sm',
    MD: 'form-control-md',
    LG: 'form-control-lg'
  };

  public ISVIEW = false;
  public parentElement: any;
  public isReactiveForm: boolean;
  public elementWidthClassName: string;
  public elementHeightClassName: string;
  public valueAlignmentClassName: string;
  public abstract onInitBaseValueAccessor(): void;
  constructor(
    @Inject(String) moduleCode: string,
    public controlContainer: ControlContainer,
    public elementRef: ElementRef
  ) {
    super(moduleCode);
  }

  public onInit(): void {
    this.setParentElement();
    this.setReactiveForm();
    this.setElementHeightClassName();
    this.setElementWidthClassName();
    this.setValue();
    this.setDisabled();
    this.setIsWrite();
    this.setComponentIsView();
    this.setStateValueAlignmentClassName();
    this.onInitBaseValueAccessor();
  }

  public setParentElement(): void {
    this.parentElement = this.elementRef.nativeElement;
  }

  public setReactiveForm(): void {
    if (this.formControlName) {
      this.setBaseFormGroup();
      this.setBaseFormControl();
      this.isReactiveForm = true;
    } else if (this.formControl) {
      // do some stuff...
    } else {
      this.isReactiveForm = false;
    }
  }

  public setIsWrite(): void {
    if (this.global.userSession) {
      if (this.global.activeModuleCode != null) {
        const moduleCode = this.global.activeModuleCode.toUpperCase();
        const menu = this.global.userSession.menuMap[moduleCode];
        if (typeof menu !== 'undefined') {
          this.disabled =
            typeof this.disabled !== 'undefined' ? this.disabled : !menu.write;
        }
      } else {
        this.disabled = false;
      }
    }
  }

  public setComponentIsView(): void {
    if (
      (this.formGroup && this.formGroup.isView) ||
      (this.formControl && this.formControl.isView)
    ) {
      this.ISVIEW =
        typeof this.formControl.isView !== 'undefined'
          ? this.formControl.isView
          : this.formGroup.isView;
    }
    if (this.formControl) {
      this.formControl.isViewChanges.subscribe(() => {
        const formControlIsView = this.formControl.isView;
        this.ISVIEW =
          typeof formControlIsView !== 'undefined'
            ? formControlIsView
            : this.formGroup.isView;
      });
    }
    if (this.formGroup) {
      this.formGroup.isViewChanges.subscribe(() => {
        this.ISVIEW =
          typeof this.formGroup.isView !== 'undefined'
            ? this.formGroup.isView
            : this.formControl
            ? this.formControl.isView
            : false;
      });
    }
  }

  writeValue(value: T): void {
    this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onBaseChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onBaseTouched = fn;
  }

  setDisabledState(): void {}

  public setValue(): void {
    if (this.isReactiveForm) {
      this.value = this.formControl.value;
    }
  }

  public setDisabled(): void {
    this.disabled = this.isDisabled
      ? this.isDisabled
      : this.isReactiveForm
      ? this.formControl.disabled
      : this.disabled;
  }

  public setBaseFormControl(): void {
    if (!this.formControl) {
      this.formControl = this.formGroup.get(
        this.formControlName
      ) as FormControl;
    }
  }

  public setBaseFormGroup(): void {
    this.formGroup =
      this.formControl && this.formControl.parent
        ? (this.formControl.parent as FormGroup)
        : (this.controlContainer.control as FormGroup);
  }

  public setElementWidthClassName(): void {
    this.elementWidthClassName = this.INPUT_SIZE[this.size.toString()];
  }

  public get isValid(): boolean {
    return (
      this.formControl && this.formControl.touched && !this.formControl.invalid
    );
  }

  public get isInvalid(): boolean {
    return (
      (this.formControl &&
        this.formControl.touched &&
        this.formControl.invalid) ||
      (this.formControl && this.formControl.dirty && this.formControl.invalid)
    );
  }

  public get errors(): object {
    return this.formControl.errors;
  }

  public get isTinySize(): boolean {
    return this.size === 'TN';
  }

  public get isSmallSize(): boolean {
    return this.size === 'SM';
  }

  public get isMediumSize(): boolean {
    return this.size === 'MD';
  }

  public get isLongSize(): boolean {
    return this.size === 'LG';
  }

  public get isNoSize(): boolean {
    return this.size === 'XL' || this.size === null || this.size.trim() === '';
  }

  public get isServerSide(): boolean {
    return !(
      typeof this.stringUrl !== 'undefined' ||
      this.stringUrl !== '' ||
      this.stringUrl !== null
    );
  }

  public setElementHeightClassName(): void {
    this.elementHeightClassName = this.INPUT_HEIGHT[this.height.toString()];
  }

  public setStateValueAlignmentClassName(): void {
    const valueAlignment =
      this.valueAlignment === 'left' || this.valueAlignment === 'right'
        ? this.valueAlignment
        : 'left';
    this.valueAlignmentClassName = `text-${valueAlignment}`;
  }
}
