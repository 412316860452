import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { CatalogAttribute } from './../../core/bean/catalog-attribute';
@Component({
  templateUrl: './catalog-attribute.component.html'
})
export class CatalogAttributeComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  catalogAttribute: CatalogAttribute;

  public tableResponse: TableResponseModel<CatalogAttribute>;
  public readonly urlRouterNavigateToAddCatalogAttribute =
    '/pages/catalog-attribute/add';
  public readonly urlRouterNavigateToEditCatalogAttribute =
    '/pages/catalog-attribute/edit';
  public readonly urlRouterNavigateToDeleteCatalogAttribute =
    '/catalog-attribute/delete';

  constructor(translateService: TranslateService) {
    super('catalog-attribute', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: '0.code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      {
        field: '1',
        header: 'table.column.translationKey',
        customClass: 'white-space-normal'
      }, // fieldName
      {
        field: '0.formObject.name',
        header: 'table.column.formObject',
        customClass: 'text-center'
      },
      {
        field: '0.function',
        header: 'table.column.function',
        customClass: 'text-center'
      },
      {
        field: '2',
        header: 'table.column.isActive',
        customClass: 'text-center'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/catalog-attribute/add']);
  }

  public doEdit(rowData: any): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('catalogAttributeId', rowData[0].id);
    this.router.navigate(['/pages/catalog-attribute/edit']);
  }

  public doDelete(catalogAttribute: CatalogAttribute): void {
    this.catalogAttribute = catalogAttribute[0];
    this.catalogAttribute.fieldName = catalogAttribute[1];
    this.catalogAttribute.status = catalogAttribute[2];
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.deleteCatalogAttribute(this.catalogAttribute);
        }
      });
  }

  public deleteCatalogAttribute(catalogAttribute: CatalogAttribute): void {
    this.deleteCatalogAttributeFromServer(
      this.urlRouterNavigateToDeleteCatalogAttribute,
      catalogAttribute
    ).subscribe(response => {
      this.showAlertMessage(response);
    });
  }

  public showAlertMessage(response: Response<CatalogAttribute>): void {
    if (response.status === ResponseStatusModel.OK) {
      this.global.alertService.showSuccessDelete();
      this.table.reload();
    } else {
      this.global.alertService.showError(response.statusText);
    }
  }

  public deleteCatalogAttributeFromServer(
    urlDeleteFileType: string,
    catalogAttribute: CatalogAttribute
  ): Observable<Response<CatalogAttribute>> {
    return this.httpClientService.post<Response<CatalogAttribute>>(
      urlDeleteFileType,
      catalogAttribute
    );
  }
}
