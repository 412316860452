import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AccidentVictimCondition } from 'src/app/core/bean/accident-victim-condition';
import { AccidentVictimDiagnose } from 'src/app/core/bean/accident-victim-diagnose';
import { Claim } from 'src/app/core/bean/claim';
import { File } from 'src/app/core/bean/file';
import { VictimDiagnose } from 'src/app/core/bean/victim-diagnose';
import { VictimDiagnoseInjuriesCategory } from 'src/app/core/bean/victim-diagnose-injuries-category';
import { VictimInjuries } from 'src/app/core/bean/victim-injuries';
import { Response } from 'src/app/core/model/response-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { AccidentVictim } from '../../core/bean/accident-victim';
import { AccidentVictimTreatment } from '../../core/bean/accident-victim-treatment';
import { VictimSurgery } from '../../core/bean/victim-surgery';
import { AppOfficialReportRequest } from '../../core/components/app-official-report/app-official-report.request';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { EncryptService } from '../../core/services/encrypt.service';
import { AccidentVictimRequest } from './accident-victim.request';
import { AccidentVictimResponse } from './accident-victim.response';
import { AppDatePickerComponent } from 'src/app/core/components/app-date-picker/app-date-picker.component';

@Component({
  styleUrls: ['./accident-victim-edit-add.component.scss'],
  templateUrl: './accident-victim-edit-add.component.html'
})
export class AccidentVictimEditAddComponent extends BaseModuleComponent implements OnInit {

  @ViewChildren(AppDatePickerComponent) datePickers!: QueryList<AppDatePickerComponent>;

  public claimId: number;
  public accidentVictimId: number;
  public addressHospital: string;
  public description: string;
  public accidentVictimResponse: AccidentVictimResponse = new AccidentVictimResponse();
  public urlBackOutside: string;
  public victimDiagnoseList: OptionListModel<any> = new OptionListModel();
  public victimConditionList: OptionListModel<any> = new OptionListModel();
  public victimDiagnoseSekunderOtherList: OptionListModel<any> = new OptionListModel();
  public genderList: OptionListModel<any> = new OptionListModel();
  public victimInjuriesList: OptionListModel<any> = new OptionListModel();
  public victimActionList: OptionListModel<any> = new OptionListModel();
  public victimSurgeryList: OptionListModel<any> = new OptionListModel();
  public victimInformantCategoryList: OptionListModel<any> = new OptionListModel();
  public victimInjuriesDiagnoseSekunderList: OptionListModel<any> = new OptionListModel();
  public isDiagnoseOther = false;
  public isDisable = false;
  public accidentVictinRequest: AccidentVictimRequest = new AccidentVictimRequest();
  public accidentVictim: AccidentVictim = new AccidentVictim();
  public claim: Claim = new Claim();
  public victimInjuriesCategoryList: VictimInjuries[];
  public isTemplateDownloaded: boolean;
  public isFormValid = true;
  public minTreatmentDate: Date;
  public maxTreatmentDate: Date;
  public statusCode: string;
  public startDate: Date;
  public endDate: Date;
  public victimInjuriesCategoryPrimer: VictimInjuries;
  public isDiagnosePrimerExist = false;
  public victimDiagnoseInjuriesCategorySekunderList: VictimDiagnoseInjuriesCategory[];
  public victimDiagnoseSekunderList: VictimDiagnose[];
  public isVictimActionExist: boolean;
  public isVictimLocationExist: boolean;
  public isVictimSurgeryExist: boolean;
  public fileUploader: FileUploader = new FileUploader(
    '/accident-victim/',
    '',
    'IMG_ACCIDENT_VICTIM_CONDITION'
  );

  constructor(translateService: TranslateService,
    public encryptService: EncryptService) {
    super('accident-victim', translateService);
  }
  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.setFormGroup();
    this.setIsViewOnlyFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.claimId = this.global.routerParams.get('claimId');
    this.addressHospital = this.global.routerParams.get('addressHospital');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    this.statusCode = this.global.routerParams.get('statusCode');
    if (this.statusCode) {
      if (this.statusCode !== this.global.appConstant.pm.ACCIDENT_VICTIM_STATUS_DRAFT) {
        this.todo = 'view';
      } else {
        this.todo = 'detail';
      }
    }
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      doctorsName: [null, Validators.required()],
      hospitalName: [null],
      addressHospital: [null],
      description: [this.translateService.instant('accident-victim.form.descriptionPlaceHolder')],
      accidentVictimName: [null],
      age: [null, Validators.required()],
      gender: [null, Validators.required()],
      addresVictim: [null, Validators.required()],
      victimDiagnose: [null, Validators.required()],
      diagnoseOther: [null],
      diseasesOther: [null],
      currentDate: new Date(),
      accidentVictimTreatmenntList: this.formBuilder.array([]),
      startDate: [Validators.required()],
      endDate: [null],
      action: [null],
      accidentVictimTreatmenntOperationList: this.formBuilder.array([]),
      victimInjuries: [null],
      accidentVictimDiagnoseSekunderList: this.formBuilder.array([]),
      accidentVictimDiagnoseOtherList: this.formBuilder.array([]),
      accidentVictimConditionList: this.formBuilder.array([]),
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<AccidentVictimResponse>(
        '/accident-victim/edit',
        new RouteRequestModel(this.todo, this.claimId)
      )
      .subscribe((accidentVictimResponse: AccidentVictimResponse) => {
        this.accidentVictimResponse = accidentVictimResponse;
        this.claim = accidentVictimResponse.claim;
        this.victimDiagnoseList.setRequestValues(accidentVictimResponse.victimDiagnoseList);
        this.victimConditionList.setRequestValues(accidentVictimResponse.victimConditionList);
        this.genderList.setRequestValues(accidentVictimResponse.genderList);
        this.victimInjuriesList.setRequestValues(accidentVictimResponse.victimInjuriesCategoryList);
        this.victimActionList.setRequestValues(accidentVictimResponse.victimActionList)
        this.victimSurgeryList.setRequestValues(accidentVictimResponse.victimSurgeryList)
        this.victimInformantCategoryList.setRequestValues(accidentVictimResponse.victimInformantCategoryList);
        this.victimInjuriesCategoryList = accidentVictimResponse.victimInjuriesCategoryList;

        if (accidentVictimResponse.accidentVictim) {
          this.accidentVictimId = accidentVictimResponse.accidentVictim.id;
          this.formGroup.patchValue({
            id: accidentVictimResponse.accidentVictim.id,
            doctorsName: accidentVictimResponse.accidentVictim.doctorName,
            description: accidentVictimResponse.accidentVictim.description,
            age: accidentVictimResponse.accidentVictim.age,
            addresVictim: accidentVictimResponse.accidentVictim.address,
            diagnoseOther: accidentVictimResponse.accidentVictim.diagnoseOther,
            diseasesOther: accidentVictimResponse.accidentVictim.diagnoseOtherDiseases,
            victimDiagnose: accidentVictimResponse.accidentVictim.victimDiagnose ? this.victimDiagnoseList.getRequestValues().filter(
              valueOption => valueOption.id === accidentVictimResponse.accidentVictim.victimDiagnose.id)[0] : null,
            gender: accidentVictimResponse.accidentVictim.gender ? this.genderList.getRequestValues().filter(valueOption => valueOption.id === accidentVictimResponse.accidentVictim.gender.id)[0] : null,
            victimInjuries: accidentVictimResponse.accidentVictim.victimInjuriesCategory ? this.victimInjuriesList.getRequestValues().filter(
              valueOption => valueOption.id === accidentVictimResponse.accidentVictim.victimInjuriesCategory.id)[0] : null,
          });
          this.victimDiagnoseInjuriesCategorySekunderList = accidentVictimResponse.accidentVictimDiagnoseSekunderList;
          this.victimDiagnoseSekunderList = accidentVictimResponse.victimDiagnoseSekunderList;

          if (accidentVictimResponse.accidentVictim.victimDiagnose) {
            this.diagnoseOther(accidentVictimResponse.accidentVictim.victimDiagnose);
            this.victimInjuriesCategoryPrimer = this.accidentVictimResponse.accidentVictim.victimInjuriesCategory;
          }

          if (accidentVictimResponse.accidentVictim.victimDiagnose != null) {
            this.buildAccidentVictimDiagnose(this.global.appConstant.pm.ACCIDENT_VICTIM_DIAGNOSE_TYPE_SEKUNDER, accidentVictimResponse.accidentVictimDiagnoseSekunderList);
            this.buildAccidentVictimDiagnose(this.global.appConstant.pm.ACCIDENT_VICTIM_DIAGNOSE_TYPE_OTHER, accidentVictimResponse.accidentVictimDiagnoseOtherList);
          }
        }
        if (accidentVictimResponse.claim) {
          this.formGroup.patchValue({
            hospitalName: accidentVictimResponse.claim.hospitalName,
            addressHospital: this.addressHospital,
            accidentVictimName: accidentVictimResponse.claim.accidentVictim,
          });
        }
        this.buildAccidentVictimTreatmennt(this.global.appConstant.pm.ACCIDENT_VICTIM_TYPE_ACTION_OPTION, accidentVictimResponse.accidentVictimTreatmenntOperationList);
        this.buildAccidentVictimTreatmennt(this.global.appConstant.pm.ACCIDENT_VICTIM_TYPE_ACTION_SUGERY, accidentVictimResponse.accidentVictimTreatmenntList);

        this.buildAccidentVictimCondition(accidentVictimResponse.accidentVictimConditionList);

        this.setStateReady();
      });
  }

  public buildAccidentVictimTreatmennt(type: number, accidentVictimTreatmentList: AccidentVictimTreatment[]): void {
    if (type === this.global.appConstant.pm.ACCIDENT_VICTIM_TYPE_ACTION_SUGERY) {
      const list = this.formGroup.controls.accidentVictimTreatmenntList as FormArray;
      accidentVictimTreatmentList.forEach(
        (accidentVictimTreatment: AccidentVictimTreatment) => {
          this.startDate = accidentVictimTreatment.startDate ? new Date(accidentVictimTreatment.startDate) : null;
          this.endDate = accidentVictimTreatment.endDate ? new Date(accidentVictimTreatment.endDate) : null;
          list.push(
            this.formBuilder.group({
              id: accidentVictimTreatment.id,
              victimSurgery: accidentVictimTreatment.victimSurgery ? this.victimSurgeryList
                .getRequestValues()
                .filter(
                  valueOption => valueOption.id === accidentVictimTreatment.victimSurgery.id
                )[0] : null,
              startDate: [this.global.convertToCurrentTimeZone(this.startDate), Validators.required()],
              endDate: this.global.convertToCurrentTimeZone(this.endDate),
              description: accidentVictimTreatment.description,
              isDisable: this.todo !== 'view' ? false : true,
              purpose: accidentVictimTreatment.purpose,
              result: accidentVictimTreatment.result,
              currentDate: new Date(),
              isUnableOther: accidentVictimTreatment.victimSurgery.code !== this.global.appConstant.pm.VICTIM_SURGERY_NO_SURGERY ? true : false,
            })
          );
        }
      );
    }

    if (type === this.global.appConstant.pm.ACCIDENT_VICTIM_TYPE_ACTION_OPTION) {
      const control = this.formGroup.controls.accidentVictimTreatmenntOperationList as FormArray;
      accidentVictimTreatmentList.forEach(
        (accidentVictimTreatment: AccidentVictimTreatment) => {
          this.startDate = accidentVictimTreatment.startDate ? new Date(accidentVictimTreatment.startDate) : null;
          this.endDate = accidentVictimTreatment.endDate ? new Date(accidentVictimTreatment.endDate) : null;

          if (accidentVictimTreatment.startDate !== null && accidentVictimTreatment.action == 'RAWAT INAP') {
            this.minTreatmentDate = new Date(accidentVictimTreatment.startDate);
            this.maxTreatmentDate = new Date(accidentVictimTreatment.endDate);
          }

          control.push(
            this.formBuilder.group({
              id: accidentVictimTreatment.id,
              action: accidentVictimTreatment.action,
              startDate: this.global.convertToCurrentTimeZone(this.startDate),
              endDate: this.global.convertToCurrentTimeZone(this.endDate),
              description: accidentVictimTreatment.description,
              isDisable: this.todo !== 'view' ? false : true,
              currentDate: new Date(),
              type: accidentVictimTreatment.type,
              victimAction: accidentVictimTreatment.victimAction
            })
          );
        }
      );
    }
    this.setIsViewArray();
  }

  public buildAccidentVictimCondition(accidentVictimConditionList: AccidentVictimCondition[]): void {
    const list = this.formGroup.controls.accidentVictimConditionList as FormArray;
    accidentVictimConditionList.forEach(
      (accidentVictimCondition: AccidentVictimCondition) => {
        let fileUploader: FileUploader = new FileUploader(
          '/accident-victim/',
          '',
          'IMG_ACCIDENT_VICTIM_CONDITION'
        );
        fileUploader.setFile(accidentVictimCondition.file);
        list.push(
          this.formBuilder.group({
            id: accidentVictimCondition.id,
            victimLocationName: accidentVictimCondition.victimLocation.name,
            victimLocation: accidentVictimCondition.victimLocation,
            victimCondition: [accidentVictimCondition.victimCondition ? this.victimConditionList
              .getRequestValues()
              .filter(
                valueOption => valueOption.id === accidentVictimCondition.victimCondition.id
              )[0] : null],
            informantName: accidentVictimCondition.informantName,
            victimInformantCategory: accidentVictimCondition.victimInformantCategory ? this.victimInformantCategoryList
              .getRequestValues()
              .filter(
                valueOption => valueOption.id === accidentVictimCondition.victimInformantCategory.id
              )[0] : null,
            informantContact: accidentVictimCondition.informantContact,
            file: fileUploader.fileObjectList.length > 0 ? fileUploader : [],
            isDisable: this.todo !== 'view' ? false : true,
            currentDate: new Date(),
            fileUploader: fileUploader,
            fileObjectList: fileUploader?.fileObjectList.length > 0 ? fileUploader?.fileObjectList : []
          })
        );
      }
    );
    this.setIsViewArray();
  }

  public buildAccidentVictimDiagnose(type: number, accidentVictimDiagnoseList: AccidentVictimDiagnose[]): void {

    this.log.error("start");
    if (type === this.global.appConstant.pm.ACCIDENT_VICTIM_DIAGNOSE_TYPE_SEKUNDER) {
      this.log.error("sekunder");
      const list = this.formGroup.controls.accidentVictimDiagnoseSekunderList as FormArray;
      this.victimDiagnoseSekunderOtherList.setRequestValues(this.victimDiagnoseSekunderList);
      accidentVictimDiagnoseList.forEach(
        (accidentVictimDiagnoseSekunder: AccidentVictimDiagnose) => {
          let victimInjuriesCategoryList = [];
          let victimInjuriesOptionList: OptionListModel<any> = new OptionListModel();
          if (accidentVictimDiagnoseSekunder.victimDiagnose.code === this.global.appConstant.pm.VICTIM_DIAGNOSE_OTHER) {
            victimInjuriesOptionList.setRequestValues(this.victimInjuriesCategoryList);
          } else {
            this.victimDiagnoseInjuriesCategorySekunderList.forEach(
              (victimDiagnoseInjuriesCategorySekunder: AccidentVictimDiagnose) => {
                if (victimDiagnoseInjuriesCategorySekunder.victimDiagnose.code === accidentVictimDiagnoseSekunder.victimDiagnose.code) {
                  victimInjuriesCategoryList.push(victimDiagnoseInjuriesCategorySekunder.victimInjuriesCategory)
                }
              });
            if (victimInjuriesCategoryList.length > 0) {
              victimInjuriesOptionList.setRequestValues(victimInjuriesCategoryList);
            } else {
              victimInjuriesCategoryList.push(this.victimInjuriesCategoryPrimer);
              victimInjuriesOptionList.setRequestValues(victimInjuriesCategoryList);
            }

          }
          list.push(
            this.formBuilder.group({
              id: accidentVictimDiagnoseSekunder.id,
              victimDiagnose: accidentVictimDiagnoseSekunder.victimDiagnose ? this.victimDiagnoseSekunderOtherList
                .getRequestValues()
                .filter(
                  valueOption => valueOption.id === accidentVictimDiagnoseSekunder.victimDiagnose.id
                )[0] : null,
              victimInjuriesCategory: accidentVictimDiagnoseSekunder.victimInjuriesCategory ? victimInjuriesOptionList
                .getRequestValues()
                .filter(
                  valueOption => valueOption.id === accidentVictimDiagnoseSekunder.victimInjuriesCategory.id
                )[0] : null,
              victimDiagnoseText: accidentVictimDiagnoseSekunder.victimDiagnoseText,
              isDisable: this.todo !== 'view' ? false : true,
              isUnableOther: accidentVictimDiagnoseSekunder.victimDiagnose.code !== this.global.appConstant.pm.VICTIM_DIAGNOSE_OTHER ? false : true,
              victimInjuriesCategoryList: victimInjuriesOptionList,
              type: accidentVictimDiagnoseSekunder.type,
            })
          );
        }
      );
    }

    if (type === this.global.appConstant.pm.ACCIDENT_VICTIM_DIAGNOSE_TYPE_OTHER) {
      const list = this.formGroup.controls.accidentVictimDiagnoseOtherList as FormArray;
      accidentVictimDiagnoseList.forEach(
        (accidentVictimDiagnoseOther: AccidentVictimDiagnose) => {
          list.push(
            this.formBuilder.group({
              id: accidentVictimDiagnoseOther.id,
              victimDiagnoseText: accidentVictimDiagnoseOther.victimDiagnoseText,
              description: accidentVictimDiagnoseOther.description,
              isDisable: this.todo !== 'view' ? false : true,
              type: accidentVictimDiagnoseOther.type,
            })
          );
        }
      );
    }
    this.setIsViewArray();
  }

  public list: VictimSurgery[] = [];
  getSurgery(data, surgeryList) {
    surgeryList.forEach((sugery) => {
      if (sugery.value.victimSurgery.id !== data.id) {
        this.list.push(sugery);
      }
    });
    this.victimSurgeryList.setRequestValues(this.list)
  }

  public setIsViewArray(): void {
    if (this.todo === 'view') {
      this.formArrayAccidentVictimTreatmentList.controls.forEach(formGroup => {
        formGroup.setIsView(true);
      });

      this.formArrayAccidentVictimTreatmentOptionList.controls.forEach(formGroup => {
        formGroup.setIsView(true);
      });

      this.formArrayAccidentVictimDiagnoseSekunderList.controls.forEach(formGroup => {
        formGroup.setIsView(true);
      });

      this.formArrayAccidentVictimDiagnoseOtherList.controls.forEach(formGroup => {
        formGroup.setIsView(true);
      });

      this.formArrayAccidentVictimConditionList.controls.forEach(formGroup => {
        formGroup.setIsView(true);
      });
    }
  }

  public doGenerate(event: any): void {
    const appOfficialReportRequest = new AppOfficialReportRequest();
    appOfficialReportRequest.letterDate = new Date();
    appOfficialReportRequest.auth = this.encryptService.encrypt([
      this.accidentVictimResponse.accidentVictim.id.toString(),
      'ORT_ACCIDENT_VICTIM_LETTER',
      'NUMBER'
    ]);

    this.loadingBlockService.showInfo(`app.msg.processing`);
    this.httpClientService
      .post<Response<File>>('/accident-victim/generate', appOfficialReportRequest)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          const file: File = response.body;
          this.doDownload(
            event,
            '/accident-victim/file/view/' +
            file.uploadedFileName +
            '/' +
            file.fileType.code,
            file.fileName
          );
          this.isTemplateDownloaded = true;
          this.loadingBlockService.close();
        }
      });
  }

  public diagnoseOther(event: any): void {
    if (event.code === this.global.appConstant.pm.VICTIM_DIAGNOSE_OTHER) {
      this.isDiagnoseOther = true;
      this.victimDiagnoseSekunderOtherList = this.victimDiagnoseList;
      if (this.formGroup.get('victimInjuries').value) {
        this.isDiagnosePrimerExist = true;
      } else {
        this.isDiagnosePrimerExist = false;
      }

    } else {
      this.httpClientService
        .get<VictimDiagnose[]>(
          '/accident-victim/get-victim-diagnose-list/' + event.id
        )
        .subscribe(victimDiagnoseList => {
          this.victimDiagnoseSekunderOtherList.setRequestValues(victimDiagnoseList);
        });

      this.httpClientService
        .get<VictimInjuries>(
          '/accident-victim/get-victim-injuries-primer/' + event.id
        )
        .subscribe(victimInjuries => {
          this.victimInjuriesCategoryPrimer = victimInjuries?.victimInjuriesCategory;
        });
      this.isDiagnoseOther = false;
      this.isDiagnosePrimerExist = true;
    }
    this.formArrayAccidentVictimDiagnoseSekunderList.clear();
    this.formArrayAccidentVictimDiagnoseOtherList.clear();
  }

  public victimInjuriesPrimer(event: any): void {
    this.victimInjuriesCategoryPrimer = event;
    if (this.victimInjuriesCategoryPrimer) {
      this.isDiagnosePrimerExist = true;
    } else {
      this.isDiagnosePrimerExist = false;
    }
    this.formArrayAccidentVictimDiagnoseSekunderList.clear();
  }

  public diagnoseSekunder(i: number): void {
    let victimInjuriesCategoryList = [];
    let victimInjuriesOptionList: OptionListModel<any> = new OptionListModel();
    const list = this.formGroup.get('accidentVictimDiagnoseSekunderList').value;
    const isUnableOtherControl = this.formArrayAccidentVictimDiagnoseSekunderList.controls[i].get('isUnableOther');
    const victimInjuriesListControl = this.formArrayAccidentVictimDiagnoseSekunderList.controls[i].get('victimInjuriesCategoryList');
    if (list[i].victimDiagnose.code == this.global.appConstant.pm.VICTIM_DIAGNOSE_OTHER) {
      victimInjuriesOptionList.setRequestValues(this.victimInjuriesCategoryList);
      list[i].isUnableOther = true;
      isUnableOtherControl.setValue(true);
      list[i].victimInjuriesCategoryList = victimInjuriesOptionList;
      victimInjuriesListControl.setValue(victimInjuriesOptionList);
    } else {
      if (this.victimInjuriesCategoryPrimer != null) {
        this.httpClientService
          .get<VictimInjuries[]>(
            '/accident-victim/get-victim-injuries-list/' + list[i].victimDiagnose.id + '/' + this.victimInjuriesCategoryPrimer.sequence
          )
          .subscribe(victimInjuriesList => {
            if (victimInjuriesList.length > 0) {
              victimInjuriesList.forEach(victimInjuries => {
                victimInjuriesCategoryList.push(victimInjuries.victimInjuriesCategory);
              })
              victimInjuriesOptionList.setRequestValues(victimInjuriesCategoryList);
              list[i].victimInjuriesCategoryList = victimInjuriesOptionList;
              victimInjuriesListControl.setValue(victimInjuriesOptionList);
            } else {
              victimInjuriesCategoryList.push(this.victimInjuriesCategoryPrimer);
              victimInjuriesOptionList.setRequestValues(victimInjuriesCategoryList);
              list[i].victimInjuriesCategoryList = victimInjuriesOptionList;
              victimInjuriesListControl.setValue(victimInjuriesOptionList);
            }

          });
      }
      list[i].isUnableOther = false;
      isUnableOtherControl.setValue(false);
    }
  }

  public onchangeDiagnoseOther(event: any): void {
    this.description = "";
    if (event && event.length > 0) {
      event.map(val => this.description += val.name + ", ");
    }
  }

  public onChangeVictimSurgery(i: number): void {
    const list = this.formGroup.get('accidentVictimTreatmenntList').value;
    const isUnableOtherControl = this.formArrayAccidentVictimTreatmentList.controls[i].get('isUnableOther');
    if (list[i].victimSurgery.code == this.global.appConstant.pm.VICTIM_SURGERY_NO_SURGERY) {
      list[i].isUnableOther = false;
      isUnableOtherControl.setValue(false);
    } else {
      list[i].isUnableOther = true;
      isUnableOtherControl.setValue(true);
    }
  }

  public get formArrayAccidentVictimTreatmentList(): FormArray {
    return this.formGroup.get('accidentVictimTreatmenntList') as FormArray;
  }

  public get formArrayAccidentVictimTreatmentOptionList(): FormArray {
    return this.formGroup.get('accidentVictimTreatmenntOperationList') as FormArray;
  }

  public get formArrayAccidentVictimDiagnoseSekunderList(): FormArray {
    return this.formGroup.get('accidentVictimDiagnoseSekunderList') as FormArray;
  }

  public get formArrayAccidentVictimDiagnoseOtherList(): FormArray {
    return this.formGroup.get('accidentVictimDiagnoseOtherList') as FormArray;
  }

  public get formArrayAccidentVictimConditionList(): FormArray {
    return this.formGroup.get('accidentVictimConditionList') as FormArray;
  }

  public doAdd(): void {
    const list = this.formGroup.get('accidentVictimTreatmenntList').value;
    if (list.length === '5' || list.length === 5) {
      this.global.modalService.message(
        this.translateService.instant('accident-victim.info.information')
      );
    } else {
      this.formArrayAccidentVictimTreatmentList.push(this.addAccidentVictimTreatmennt())
    }

  }

  public doAddDiagnoseSekunder(): void {
    const list = this.formGroup.get('accidentVictimDiagnoseSekunderList').value;
    if (list.length === '5' || list.length === 5) {
      this.global.modalService.message(
        this.translateService.instant('accident-victim.info.information')
      );
    } else {
      this.formArrayAccidentVictimDiagnoseSekunderList.push(this.addDiagnoseSekunder())
    }

  }

  public doAddDiagnoseOther(): void {
    const list = this.formGroup.get('accidentVictimDiagnoseOtherList').value;
    if (list.length === '5' || list.length === 5) {
      this.global.modalService.message(
        this.translateService.instant('accident-victim.info.information')
      );
    } else {
      this.formArrayAccidentVictimDiagnoseOtherList.push(this.addDiagnoseOther())
    }

  }

  public addAccidentVictimTreatmennt(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      action: [null],
      victimSurgery: [null],
      endDate: [null],
      startDate: [null, Validators.required()],
      description: [null],
      purpose: [null],
      result: [null],
      currentDate: new Date(),
      isDisable: false,
      type: this.global.appConstant.pm.ACCIDENT_VICTIM_TYPE_ACTION_SUGERY,
      isUnableOther: false,
    });
  }

  public addDiagnoseSekunder(): FormGroup {
    let victimInjuriesOptionList: OptionListModel<any> = new OptionListModel();
    victimInjuriesOptionList.setRequestValues([]);
    return this.formBuilder.group({
      id: [null],
      victimDiagnose: [null, Validators.required()],
      victimDiagnoseText: [null],
      victimInjuriesCategory: [null, Validators.required()],
      isDisable: false,
      isUnableOther: false,
      type: this.global.appConstant.pm.ACCIDENT_VICTIM_DIAGNOSE_TYPE_SEKUNDER,
      victimInjuriesCategoryList: victimInjuriesOptionList
    });
  }

  public addDiagnoseOther(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      victimDiagnoseText: [null],
      description: [null],
      isDisable: false,
      type: this.global.appConstant.pm.ACCIDENT_VICTIM_DIAGNOSE_TYPE_OTHER,
    });
  }

  public doBack(): void {
    this.router.navigate(['/pages/accident-victim']);
  }

  public doSaveAccidentVictimTreatmennt(i: number): void {
    const list = this.formGroup.get('accidentVictimTreatmenntList').value;
    list[i].isDisable = true;
  }

  public doEdit(i: number): void {
    const list = this.formGroup.get('accidentVictimTreatmenntList').value;
    list[i].isDisable = false;
  }
  public doDelete(value: any): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.formArrayAccidentVictimTreatmentList.removeAt(value);
        }
      });
  }

  public doSaveAccidentDiagnoseSekunder(i: number): void {
    const list = this.formGroup.get('accidentVictimDiagnoseSekunderList').value;
    list[i].isDisable = true;
  }
  public doEditDiagnoseSekunder(i: number): void {
    const list = this.formGroup.get('accidentVictimDiagnoseSekunderList').value;
    list[i].isDisable = false;
  }
  public doDeleteDiagnoseSekunder(value: any): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.formArrayAccidentVictimDiagnoseSekunderList.removeAt(value);
        }
      });
  }

  public doSaveAccidentDiagnoseOther(i: number): void {
    const list = this.formGroup.get('accidentVictimDiagnoseOtherList').value;
    list[i].isDisable = true;
  }
  public doEditDiagnoseOther(i: number): void {
    const list = this.formGroup.get('accidentVictimDiagnoseOtherList').value;
    list[i].isDisable = false;
  }
  public doDeleteDiagnoseOther(value: any): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.formArrayAccidentVictimDiagnoseOtherList.removeAt(value);
        }
      });
  }

  public doSubmit(code: string): void {
    this.doUpdateValidattor(code);
    this.validate();
    if (code === this.global.appConstant.pm.ACCIDENT_VICTIM_STATUS_DRAFT && this.formGroup.valid) {
      const value = this.formGroup.value;
      this.accidentVictinRequest.claim = this.accidentVictimResponse.claim;
      this.accidentVictim.doctorName = value.doctorsName;
      this.accidentVictim.description = value.description;
      this.accidentVictim.age = value.age;
      this.accidentVictim.id = value.id;
      this.accidentVictim.gender = value.gender;
      this.accidentVictim.address = value.addresVictim;
      this.accidentVictim.victimDiagnose = value.victimDiagnose;
      this.accidentVictim.diagnoseOtherDiseases = value.diseasesOther;
      this.accidentVictinRequest.accidentVictim = this.accidentVictim;
      if (this.accidentVictim.victimDiagnose != null) {
        if (this.accidentVictim.victimDiagnose?.code === this.global.appConstant.pm.VICTIM_DIAGNOSE_OTHER) {
          this.accidentVictim.victimInjuriesCategory = value.victimInjuries;
          this.accidentVictim.diagnoseOther = value.diagnoseOther;
        } else {
          this.accidentVictim.victimInjuriesCategory = this.victimInjuriesCategoryPrimer;
          this.accidentVictim.diagnoseOther = this.accidentVictim.victimDiagnose?.name;
        }

      } else {
        this.accidentVictim.victimInjuriesCategory = null;
        this.accidentVictim.diagnoseOther = null;
      }

      this.accidentVictinRequest.accidentVictimId = this.accidentVictimId;
      const list = this.formGroup.get('accidentVictimTreatmenntList').value;
      for (let i = 0; i < list.length; i++) {
        list[i].action = list[i].victimSurgery ? list[i].victimSurgery.name : null;
        const adjustedStartDate = this.global.convertToWIB(list[i].startDate);
        const adjustedEndDate = this.global.convertToWIB(list[i].endDate);
        list[i].startDate = adjustedStartDate;
        list[i].endDate = adjustedEndDate;
        if (!list[i].victimSurgery) {
          list.splice(i, 1);
          i--;
        } else {
          delete list[i].isDisable;
          delete list[i].currentDate;
          delete list[i].isUnableOther;
        }
      }

      this.accidentVictinRequest.accidentVictimTreatmenntList = list;
      this.accidentVictinRequest.statusCode = code;
      const control = this.formGroup.get('accidentVictimTreatmenntOperationList').value;
      for (let i = 0; i < control.length; i++) {
        delete control[i].isDisable;
        delete control[i].currentDate;
        const adjustedStartDate = this.global.convertToWIB(control[i].startDate);
        const adjustedEndDate = this.global.convertToWIB(control[i].endDate);
        control[i].startDate = adjustedStartDate;
        control[i].endDate = adjustedEndDate;
      }

      const control2 = this.formGroup.get('accidentVictimConditionList').value;
      for (let i = 0; i < control2.length; i++) {
        control2[i].fileObjectList = control2[i].fileUploader?.fileObjectList
        delete control2[i].file;
        delete control2[i].isDisable;
        delete control2[i].victimLocationName;
      }

      const control3 = this.formGroup.get('accidentVictimDiagnoseSekunderList').value;
      for (let i = 0; i < control3.length; i++) {
        if (control3[i].victimDiagnose?.code !== this.global.appConstant.pm.VICTIM_DIAGNOSE_OTHER) {
          control3[i].victimDiagnoseText = control3[i].victimDiagnose?.name;
        }
        if (!control3[i].victimDiagnose || !control3[i].victimInjuriesCategory) {
          control3.splice(i, 1);
          i--;
        } else {
          delete control3[i].isDisable;
          delete control3[i].victimInjuriesCategoryList;
          delete control3[i].isUnableOther;
        }
      }

      const control4 = this.formGroup.get('accidentVictimDiagnoseOtherList').value;
      for (let i = 0; i < control4.length; i++) {
        if (!control4[i].victimDiagnoseText) {
          control4.splice(i, 1);
          i--;
        } else {
          delete control4[i].isDisable;
        }
      }

      this.accidentVictinRequest.accidentVictimTreatmenntOperationList = control;
      this.accidentVictinRequest.accidentVictimConditionDtoList = control2;
      if (this.accidentVictim.victimDiagnose != null) {
        this.accidentVictinRequest.accidentVictimDiagnoseSekunderList = control3;
        this.accidentVictinRequest.accidentVictimDiagnoseOtherList = control4;
      }
      this.setStateProcessing();
      this.httpClientService
        .post<Response<AccidentVictim>>('/accident-victim/insert', this.accidentVictinRequest)
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.global.modalService
              .submitSuccess()
              .pipe(take(1))
              .subscribe(result => {
                if (result) {
                  this.router.navigate(['/pages/accident-victim/']);
                } else {
                  this.router
                    .navigateByUrl('/', { skipLocationChange: true })
                    .then(() => {
                      this.global.routerParams.clear();
                      this.global.routerParams.set('claimId', this.claimId);
                      this.global.routerParams.set('statusCode', code);
                      this.global.routerParams.set('addressHospital', this.addressHospital);
                      this.router.navigate(['/pages/accident-victim/detail']);
                    });
                }
              });
          } else {
            this.global.alertService.showError(
              response.statusText
            );
            this.setStateReady();
          }
        });
    } else if (this.formGroup.valid && this.isVictimActionExist && this.isVictimLocationExist && this.isVictimSurgeryExist) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const value = this.formGroup.value;
            this.accidentVictinRequest.claim = this.accidentVictimResponse.claim;
            this.accidentVictim.doctorName = value.doctorsName;
            this.accidentVictim.description = value.description;
            this.accidentVictim.age = value.age;
            this.accidentVictim.id = value.id;
            this.accidentVictim.gender = value.gender;
            this.accidentVictim.address = value.addresVictim;
            this.accidentVictim.victimDiagnose = value.victimDiagnose;
            this.accidentVictim.diagnoseOtherDiseases = value.diseasesOther;
            this.accidentVictinRequest.accidentVictim = this.accidentVictim;
            if (this.accidentVictim.victimDiagnose?.code === this.global.appConstant.pm.VICTIM_DIAGNOSE_OTHER) {
              this.accidentVictim.victimInjuriesCategory = value.victimInjuries;
              this.accidentVictim.diagnoseOther = value.diagnoseOther;
            } else {
              this.accidentVictim.victimInjuriesCategory = this.victimInjuriesCategoryPrimer;
              this.accidentVictim.diagnoseOther = this.accidentVictim.victimDiagnose?.name;
            }
            this.accidentVictinRequest.accidentVictimId = this.accidentVictimId;
            const list = this.formGroup.get('accidentVictimTreatmenntList').value;
            for (let i = 0; i < list.length; i++) {
              delete list[i].isDisable;
              delete list[i].currentDate;
              delete list[i].isUnableOther;
              list[i].action = list[i].victimSurgery ? list[i].victimSurgery.name : null;
              const adjustedStartDate = this.global.convertToWIB(list[i].startDate);
              const adjustedEndDate = this.global.convertToWIB(list[i].endDate);
              list[i].startDate = adjustedStartDate;
              list[i].endDate = adjustedEndDate;
            }

            this.accidentVictinRequest.accidentVictimTreatmenntList = list;
            this.accidentVictinRequest.statusCode = code;
            const control = this.formGroup.get('accidentVictimTreatmenntOperationList').value;
            for (let i = 0; i < control.length; i++) {
              delete control[i].isDisable;
              delete control[i].currentDate;
              const adjustedStartDate = this.global.convertToWIB(control[i].startDate);
              const adjustedEndDate = this.global.convertToWIB(control[i].endDate);
              control[i].startDate = adjustedStartDate;
              control[i].endDate = adjustedEndDate;
            }

            const control2 = this.formGroup.get('accidentVictimConditionList').value;
            for (let i = 0; i < control2.length; i++) {
              control2[i].fileObjectList = control2[i].fileUploader?.fileObjectList
              delete control2[i].file;
              delete control2[i].isDisable;
              delete control2[i].victimLocationName;
            }

            const control3 = this.formGroup.get('accidentVictimDiagnoseSekunderList').value;
            for (let i = 0; i < control3.length; i++) {
              if (control3[i].victimDiagnose?.code !== this.global.appConstant.pm.VICTIM_DIAGNOSE_OTHER) {
                control3[i].victimDiagnoseText = control3[i].victimDiagnose?.name;
              }
              delete control3[i].isDisable;
              delete control3[i].victimInjuriesCategoryList;
              delete control3[i].isUnableOther;
            }

            const control4 = this.formGroup.get('accidentVictimDiagnoseOtherList').value;
            for (let i = 0; i < control4.length; i++) {
              delete control4[i].isDisable;
            }

            this.accidentVictinRequest.accidentVictimTreatmenntOperationList = control;
            this.accidentVictinRequest.accidentVictimConditionDtoList = control2;
            this.accidentVictinRequest.accidentVictimDiagnoseSekunderList = control3;
            this.accidentVictinRequest.accidentVictimDiagnoseOtherList = control4;
            this.setStateProcessing();
            this.httpClientService
              .post<Response<AccidentVictim>>('/accident-victim/insert', this.accidentVictinRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccess()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate(['/pages/accident-victim/']);
                      } else {
                        this.router
                          .navigateByUrl('/', { skipLocationChange: true })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set('claimId', this.claimId);
                            this.global.routerParams.set('statusCode', code);
                            this.global.routerParams.set('addressHospital', this.addressHospital);
                            this.router.navigate(['/pages/accident-victim/detail']);
                          });
                      }
                    });
                } else {
                  this.global.alertService.showError(
                    response.statusText
                  );
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doUpdateValidattor(code: string) {
    if (code === this.global.appConstant.pm.ACCIDENT_VICTIM_STATUS_DRAFT) {
      this.formGroup.get('age').clearValidators();
      this.formGroup.get('gender').clearValidators();
      this.formGroup.get('addresVictim').clearValidators();
      this.formGroup.get('victimDiagnose').clearValidators();
      this.formGroup.get('victimInjuries').clearValidators();
      this.formGroup.get('diagnoseOther').clearValidators();

      const accidentVictimTreatmenntList = this.formGroup.get('accidentVictimTreatmenntList').value;
      for (let i = 0; i < accidentVictimTreatmenntList.length; i++) {
        this.formArrayAccidentVictimTreatmentList.controls[i].get('startDate').clearValidators();
        this.formArrayAccidentVictimTreatmentList.controls[i].get('startDate').updateValueAndValidity();
        this.formArrayAccidentVictimTreatmentList.controls[i].get('victimSurgery').clearValidators();
        this.formArrayAccidentVictimTreatmentList.controls[i].get('victimSurgery').updateValueAndValidity();
        if (accidentVictimTreatmenntList[i].victimSurgery?.code == this.global.appConstant.pm.VICTIM_SURGERY_NO_SURGERY) {
          this.formArrayAccidentVictimTreatmentList.controls[i].get('startDate').setValue(null);
          this.formArrayAccidentVictimTreatmentList.controls[i].get('endDate').setValue(null);
          this.formArrayAccidentVictimTreatmentList.controls[i].get('description').setValue(null);
          this.formArrayAccidentVictimTreatmentList.controls[i].get('purpose').setValue(null);
          this.formArrayAccidentVictimTreatmentList.controls[i].get('result').setValue(null);
          this.formArrayAccidentVictimTreatmentList.controls[i].get('startDate').updateValueAndValidity();
          this.formArrayAccidentVictimTreatmentList.controls[i].get('endDate').updateValueAndValidity();
          this.formArrayAccidentVictimTreatmentList.controls[i].get('description').updateValueAndValidity();
          this.formArrayAccidentVictimTreatmentList.controls[i].get('purpose').updateValueAndValidity();
          this.formArrayAccidentVictimTreatmentList.controls[i].get('result').updateValueAndValidity();
        }
      }

      const accidentVictimTreatmenntOperationList = this.formGroup.get('accidentVictimTreatmenntOperationList').value;
      for (let i = 0; i < accidentVictimTreatmenntOperationList.length; i++) {
      }

      const accidentVictimDiagnoseSekunderList = this.formGroup.get('accidentVictimDiagnoseSekunderList').value;
      for (let i = 0; i < accidentVictimDiagnoseSekunderList.length; i++) {
        this.formArrayAccidentVictimDiagnoseSekunderList.controls[i].get('victimDiagnose').clearValidators();
        this.formArrayAccidentVictimDiagnoseSekunderList.controls[i].get('victimDiagnose').updateValueAndValidity();
        this.formArrayAccidentVictimDiagnoseSekunderList.controls[i].get('victimInjuriesCategory').clearValidators();
        this.formArrayAccidentVictimDiagnoseSekunderList.controls[i].get('victimInjuriesCategory').updateValueAndValidity();

      }

      const accidentVictimDiagnoseOtherList = this.formGroup.get('accidentVictimDiagnoseOtherList').value;
      for (let i = 0; i < accidentVictimDiagnoseOtherList.length; i++) {
        this.formArrayAccidentVictimDiagnoseOtherList.controls[i].get('victimDiagnoseText').clearValidators();
        this.formArrayAccidentVictimDiagnoseOtherList.controls[i].get('victimDiagnoseText').updateValueAndValidity();
      }

      this.isVictimActionExist = true;
      this.isVictimLocationExist = true;
      this.isVictimSurgeryExist = true;
    } else {
      this.isVictimActionExist = false;
      this.isVictimLocationExist = false;
      this.isVictimSurgeryExist = false;
      this.formGroup.get('age').setValidators(Validators.required());
      this.formGroup.get('gender').setValidators(Validators.required());
      this.formGroup.get('addresVictim').setValidators(Validators.required());
      this.formGroup.get('victimDiagnose').setValidators(Validators.required());
      if (this.formGroup.value.victimDiagnose) {
        if (this.formGroup.value.victimDiagnose.code == this.global.appConstant.pm.VICTIM_DIAGNOSE_OTHER) {
          this.formGroup.get('victimInjuries').setValidators(Validators.required());
          this.formGroup.get('diagnoseOther').setValidators(Validators.required());
        } else {
          this.formGroup.get('victimInjuries').clearValidators();
          this.formGroup.get('diagnoseOther').clearValidators();
        }
      }

      const accidentVictimTreatmenntList = this.formGroup.get('accidentVictimTreatmenntList').value;
      for (let i = 0; i < accidentVictimTreatmenntList.length; i++) {
        this.formArrayAccidentVictimTreatmentList.controls[i].get('startDate').setValidators(Validators.required());
        this.formArrayAccidentVictimTreatmentList.controls[i].get('startDate').updateValueAndValidity();
        this.formArrayAccidentVictimTreatmentList.controls[i].get('victimSurgery').setValidators(Validators.required());
        this.formArrayAccidentVictimTreatmentList.controls[i].get('victimSurgery').updateValueAndValidity();
        this.isVictimSurgeryExist = true;
        if (accidentVictimTreatmenntList[i].victimSurgery?.code == this.global.appConstant.pm.VICTIM_SURGERY_NO_SURGERY) {
          this.formArrayAccidentVictimTreatmentList.controls[i].get('startDate').clearValidators();
          this.formArrayAccidentVictimTreatmentList.controls[i].get('startDate').setValue(null);
          this.formArrayAccidentVictimTreatmentList.controls[i].get('endDate').setValue(null);
          this.formArrayAccidentVictimTreatmentList.controls[i].get('description').setValue(null);
          this.formArrayAccidentVictimTreatmentList.controls[i].get('purpose').setValue(null);
          this.formArrayAccidentVictimTreatmentList.controls[i].get('result').setValue(null);
          this.formArrayAccidentVictimTreatmentList.controls[i].get('startDate').updateValueAndValidity();
          this.formArrayAccidentVictimTreatmentList.controls[i].get('endDate').updateValueAndValidity();
          this.formArrayAccidentVictimTreatmentList.controls[i].get('description').updateValueAndValidity();
          this.formArrayAccidentVictimTreatmentList.controls[i].get('purpose').updateValueAndValidity();
          this.formArrayAccidentVictimTreatmentList.controls[i].get('result').updateValueAndValidity();
        }
      }
      const accidentVictimTreatmenntOperationList = this.formGroup.get('accidentVictimTreatmenntOperationList').value;
      for (let i = 0; i < accidentVictimTreatmenntOperationList.length; i++) {
        if (accidentVictimTreatmenntOperationList[i].startDate != null) {
          this.isVictimActionExist = true;
        }
      }

      const accidentVictimConditionList = this.formGroup.get('accidentVictimConditionList').value;
      for (let i = 0; i < accidentVictimConditionList.length; i++) {
        if (accidentVictimConditionList[i].victimCondition != null) {
          this.isVictimLocationExist = true;
        }
      }

      const accidentVictimDiagnoseSekunderList = this.formGroup.get('accidentVictimDiagnoseSekunderList').value;
      for (let i = 0; i < accidentVictimDiagnoseSekunderList.length; i++) {
        this.formArrayAccidentVictimDiagnoseSekunderList.controls[i].get('victimDiagnose').setValidators(Validators.required());
        this.formArrayAccidentVictimDiagnoseSekunderList.controls[i].get('victimDiagnose').updateValueAndValidity();
        this.formArrayAccidentVictimDiagnoseSekunderList.controls[i].get('victimInjuriesCategory').setValidators(Validators.required());
        this.formArrayAccidentVictimDiagnoseSekunderList.controls[i].get('victimInjuriesCategory').updateValueAndValidity();
      }

      const accidentVictimDiagnoseOtherList = this.formGroup.get('accidentVictimDiagnoseOtherList').value;
      for (let i = 0; i < accidentVictimDiagnoseOtherList.length; i++) {
        this.formArrayAccidentVictimDiagnoseOtherList.controls[i].get('victimDiagnoseText').setValidators(Validators.required());
        this.formArrayAccidentVictimDiagnoseOtherList.controls[i].get('victimDiagnoseText').updateValueAndValidity();
      }

    }
    this.formGroup.get('startDate').updateValueAndValidity();
    this.formGroup.get('endDate').updateValueAndValidity();
    this.formGroup.get('age').updateValueAndValidity();
    this.formGroup.get('gender').updateValueAndValidity();
    this.formGroup.get('addresVictim').updateValueAndValidity();
    this.formGroup.get('victimDiagnose').updateValueAndValidity();
    this.formGroup.get('victimInjuries').updateValueAndValidity();
    this.formGroup.get('diagnoseOther').updateValueAndValidity();
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public setMinMaxDate(treatment: any, code: string, date: Date): void {
    if (treatment?.action === 'RAWAT INAP') {
      if (code === "minDate") {
        this.minTreatmentDate = new Date(date.getTime());
      } else if (code === "maxDate") {
        this.maxTreatmentDate = new Date(date.getTime());
      }

      this.datePickers.forEach((datePicker) => {

        if (datePicker.formGroup.value?.victimSurgery === null || datePicker.formGroup.value?.victimSurgery) {
          if (datePicker.formControlName === 'startDate') {
            datePicker.minDate = this.minTreatmentDate;
            datePicker.maxDate = this.maxTreatmentDate;

            datePicker.onInitBaseValueAccessor();
          }
        }

      });

      const accidentVictimTreatmenntList = this.formGroup.get('accidentVictimTreatmenntList').value;
      for (let i = 0; i < accidentVictimTreatmenntList.length; i++) {
        const startDateControl = this.formArrayAccidentVictimTreatmentList.controls[i].get('startDate');

        startDateControl.setValue(null);
        startDateControl.updateValueAndValidity();
      }
    }
  }



}
