import { ScoringCriteria } from '../../core/bean/scoring-criteria';
import { ScoringCriteriaRange } from '../../core/bean/scoring-criteria-range';

export class ScoringCriteriaResponse {
    // attributeListIsScoreOneAndNotHaveDataSource: Attribute[];
    // attributeListIsScoreOneAndHaveDataSource: Attribute[];
    scoringCriteriaRangeList: ScoringCriteriaRange[];
    // attribute: Attribute;
    scoringCriteria: ScoringCriteria;
    // scoringCriteriaRange: ScoringCriteriaRange;
    // totalResult: number;
}
