import { HttpClientService } from 'src/app/core/services/http-client.service';
import { ArrayUtils } from 'src/app/core/utils';
import { TablePageModel } from '../../app-table/model/table-page-model';
import { TableRecord } from '../model/table-record';
import { TableState } from '../model/table-state';
import { resolveTableRecordChildrens } from './resolve-table-record-childrens-helper';
import { searchTableRecords } from './search-table-records.helper';
export function resolveTableRecords(
  state: TableState,
  isNoLimit?: boolean
): Promise<Array<TableRecord>> {
  return new Promise(resolve => {
    let tableRecordList: Array<TableRecord>;
    if (state.isServerSide) {
      const httpClientSvice = state.injector.get(HttpClientService);
      httpClientSvice
        .post<TablePageModel<Object>>(state.stringUrl, state.tableRequest)
        .subscribe((page: TablePageModel<Object>) => {
          state.pagination.setTotalRecords(page.totalRecords);
          tableRecordList = page.records.map(record => new TableRecord(record));
          setExpandToAllRecord(state, tableRecordList);
          resolve(tableRecordList);
        });
    } else {
      let modelRecordsCopy = Array.from(state.model.records || []);
      if (state.tableRequest.sortField) {
        modelRecordsCopy = ArrayUtils.sortArray(
          modelRecordsCopy,
          state.tableRequest.sortField,
          state.sortOrder
        );
      }
      modelRecordsCopy = searchTableRecords(state, modelRecordsCopy);
      if (!isNoLimit) {
        tableRecordList = Array.from(modelRecordsCopy)
          .splice(
            (state.pagination.page - 1) * state.pagination.perPage,
            state.pagination.perPage
          )
          .map(record => new TableRecord(record));
        state.pagination.setTotalRecords(modelRecordsCopy.length);
        setExpandToAllRecord(state, tableRecordList);
        resolve(tableRecordList);
      } else {
        tableRecordList = Array.from(modelRecordsCopy || []).map(
          record => new TableRecord(record)
        );
        setExpandToAllRecord(state, tableRecordList);
        resolve(tableRecordList);
      }
    }
  });
}

const setExpandToAllRecord = (
  state: TableState,
  tableRecordList: Array<TableRecord>
): void => {
  if (
    state.model.options &&
    state.model.options.group &&
    state.model.options.group.isExpanded
  ) {
    tableRecordList.forEach(tableRecord => {
      tableRecord.isExpanded = true;
      tableRecord.setStateLoading();
      resolveTableRecordChildrens(state, tableRecord).then(
        (value: { recordList: TableRecord[]; totalRecord: number }) => {
          tableRecord.childrens = value.recordList;
          tableRecord.isShowButtonLoadMore =
            tableRecord.childrens.length < value.totalRecord;
          tableRecord.setStateReady();
        }
      );
    });
  }
};
