<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="contract-parameter.title" detail="app.action.{{todo}}"
            [isLoading]="formLoading" [isSaving]="formSaving">
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>
                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        'contract-parameter.form.code' | translate}}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-field autofocus="true" formControlName="code"
                            type="code" include="/.$" maxLength="64">
                        </app-text-field>
                    </div>
                </div>
                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        'contract-parameter.form.name' | translate}}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-field formControlName="name"
                            type="alphanumeric" maxLength="128">
                        </app-text-field>
                    </div>
                </div>
                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        'contract-parameter.form.valueType' | translate
                        }}</label>
                    <div class="col-sm-9 text-left">
                        <app-combo-box [optionList]="valueTypeOptionList"
                            formControlName="valueType"></app-combo-box>
                    </div>
                </div>
                <div class="form-group row text-sm-right">
                    <label class="col-sm-3 control-label">{{
                        'contract-parameter.form.description' |
                        translate}}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-area formControlName="description"
                            maxLength="512"></app-text-area>
                    </div>
                </div>
                <div class="row justify-content-center mt-5">
                    <app-button color="SECONDARY" (onClick)="doCancel()"
                        class="mr-1" [disabled]="formSaving">{{
                        'app.button.back' | translate }}</app-button>
                    <app-button color="PRIMARY" (onClick)="doSave()"
                        [disabled]="formSaving">{{ 'app.button.save' | translate
                        }}</app-button>
                </div>
            </form>
        </app-card>
    </div>
</div>