<div class="row">
  <div class="col-lg-12 mb-4">
    <div class="text-center py-4" *ngIf="formLoading">
      <img src="assets/img/common/loader-small.gif" alt="Loading" />
    </div>
    <form
      class="form-horizontal"
      [formGroup]="tabCompanyOrganization.formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <div class="form-group row" *ngIf="!isView">
        <div class="col-lg-12">
          <h5 class="separator"
            >{{
              'tab-profile-company-organization.form-group.commisioner'
                | translate
            }}
            <span class="control-label"></span
          ></h5>
          <app-table #selectorCommissioner [model]="tableResponseCommissioner">
            <ng-template #headerButtons>
              <ng-container *ngIf="!isView">
                <app-button (onClick)="doAddCommisioner()"
                  ><em class="fas fa-plus"></em
                  >{{ 'app.button.add' | translate }}</app-button
                >
              </ng-container>
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <ng-container *ngIf="!isView">
                <app-button
                  size="TN"
                  (onClick)="doEditCommissioner(rowData)"
                  title="{{ 'app.tooltip.edit' | translate }}"
                >
                  <span class="fas fa-pencil-alt"></span>
                </app-button>
                <app-button
                  size="TN"
                  color="DANGER"
                  (onClick)="doDeleteCommissioner(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <span class="fas fa-trash"></span>
                </app-button>
              </ng-container>
            </ng-template>
          </app-table>
        </div>
      </div>

      <div class="form-group row" *ngIf="isView">
        <div class="col-lg-12">
          <h5 class="separator"
            >{{
              'tab-profile-company-organization.form-group.commisioner'
                | translate
            }}
            <span class="control-label"></span
          ></h5>
          <app-table
            #selectorCommissioner
            [model]="tableResponseCommissioner"
          ></app-table>
        </div>
      </div>

      <div
        class="form-group row"
        [ngClass]="!isView ? 'required' : ''"
        *ngIf="!isView"
      >
        <div class="col-lg-12">
          <h5 class="separator"
            >{{
              'tab-profile-company-organization.form-group.director' | translate
            }}
            <span class="control-label"></span
          ></h5>
          <small
            class="text-danger"
            *ngIf="
              tabCompanyOrganization.formGroup.value.isTableDirectorEmpty &&
              tabCompanyOrganization.formGroup.value.changeTab
            "
          >
            {{ 'app.validation.required' | translate }}
          </small>
          <app-table #selectorDirector [model]="tableResponseDirector">
            <ng-template #headerButtons>
              <ng-container *ngIf="!isView">
                <app-button (onClick)="doAddDirector(rowData)"
                  ><em class="fas fa-plus"></em
                  >{{ 'app.button.add' | translate }}</app-button
                >
              </ng-container>
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <ng-container *ngIf="!isView">
                <app-button
                  size="TN"
                  (onClick)="doEditDirector(rowData)"
                  title="{{ 'app.tooltip.edit' | translate }}"
                >
                  <span class="fas fa-pencil-alt"></span>
                </app-button>
                <app-button
                  size="TN"
                  color="DANGER"
                  (onClick)="doDeleteDirector(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <span class="fas fa-trash"></span>
                </app-button>
              </ng-container>
            </ng-template>
          </app-table>
        </div>
      </div>

      <div
        class="form-group row"
        [ngClass]="!isView ? 'required' : ''"
        *ngIf="isView"
      >
        <div class="col-lg-12">
          <h5 class="separator"
            >{{
              'tab-profile-company-organization.form-group.director' | translate
            }}
            <span class="control-label"></span
          ></h5>
          <small
            class="text-danger"
            *ngIf="
              tabCompanyOrganization.formGroup.value.isTableDirectorEmpty &&
              tabCompanyOrganization.formGroup.value.changeTab
            "
          >
            {{ 'app.validation.required' | translate }}
          </small>
          <app-table
            #selectorDirector
            [model]="tableResponseDirector"
          ></app-table>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-lg-12">
          <h5 class="separator"
            >{{
              'tab-profile-company-organization.form-group.shareHolder'
                | translate
            }}
            <span class="control-label"></span
          ></h5>
          <app-table #selectorShareHolder [model]="tableResponseShareHolders">
            <ng-template #headerButtons>
              <ng-container *ngIf="!isView">
                <app-button
                  [disabled]="isDisableAddButton"
                  (onClick)="doAddShareHolders(rowData)"
                  ><em class="fas fa-plus"></em
                  >{{ 'app.button.add' | translate }}</app-button
                >
              </ng-container>
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <ng-container *ngIf="!isView">
                <app-button
                  size="TN"
                  (onClick)="doEditShareHolders(rowData)"
                  title="{{ 'app.tooltip.edit' | translate }}"
                >
                  <span class="fas fa-pencil-alt"></span>
                </app-button>
                <app-button
                  size="TN"
                  color="DANGER"
                  (onClick)="doDeleteShareHolders(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <span class="fas fa-trash"></span>
                </app-button>
              </ng-container>
              <ng-container *ngIf="isView">
                <app-button
                  size="TN"
                  (onClick)="doViewShareHolders(rowData)"
                  title="{{ 'app.tooltip.detail' | translate }}"
                >
                  <span class="fas fa-search"></span>
                </app-button>
              </ng-container>
            </ng-template>
          </app-table>
        </div>
      </div>
    </form>
  </div>
</div>
