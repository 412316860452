import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { ManageCatalogComponent } from './manage-catalog.component';
const routes = [
  { path: '', component: ManageCatalogComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    loadChildren: () =>
      import('./manage-catalog-tab.module').then(m => m.ManageCatalogTabModule),
    data: { breadcrumb: 'manage-catalog.breadcrumb.add' }
  },
  {
    path: 'edit',
    loadChildren: () =>
      import('./manage-catalog-tab.module').then(m => m.ManageCatalogTabModule),
    data: { breadcrumb: 'manage-catalog.breadcrumb.edit' }
  },
  {
    path: 'detail',
    loadChildren: () =>
      import('./manage-catalog-tab.module').then(m => m.ManageCatalogTabModule),
    data: { breadcrumb: 'manage-catalog.breadcrumb.detail' }
  },
  {
    path: 'detail/:token',
    loadChildren: () =>
      import('./manage-catalog-tab.module').then(m => m.ManageCatalogTabModule),
    data: { breadcrumb: 'manage-catalog.breadcrumb.detail' }
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ManageCatalogComponent]
})
export class ManageCatalogModule {}
