import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from './../../core/shared/shared.module';
import { ScheduleStagesEditAddComponent } from './schedule-stages-edit-add.component';
import { ScheduleStagesComponent } from './schedule-stages.component';
const routes = [
  { path: '', component: ScheduleStagesComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: ScheduleStagesEditAddComponent,
    data: { breadcrumb: 'schedule-stages.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
  // { path: 'add', component: SchedulerEditAddComponent, data: { breadcrumb: 'Add' } }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ScheduleStagesComponent, ScheduleStagesEditAddComponent],
  providers: []
})
export class ScheduleStagesModule {}
