<app-popup header="workflow.form.additionalStage">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{ 'workflow.form.stage' |
                translate }}</label>
            <div class=" col-sm-9 text-left">
                <app-combo-box formControlName="module" autofocus="true"
                    [optionList]="sourceModuleOptionList"></app-combo-box>
            </div>
        </div>
        <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{ 'workflow.form.afterStage'
                | translate }}</label>
            <div class=" col-sm-9 text-left ">
                <app-combo-box formControlName="afterModule"
                    [optionList]="targetModuleOptionList"
                    (onChange)="doOnChangeAfterModule()"></app-combo-box>
            </div>
        </div>
        <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{ 'workflow.form.beforeStage'
                | translate }}</label>
            <div class="col-sm-9 text-left ">
                <app-combo-box formControlName="beforeModule"
                    [optionList]="targetModuleTempOptionList"></app-combo-box>
            </div>
        </div>
        <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{ 'workflow.form.mandatory' |
                translate }}</label>
            <div class="col-sm-9 text-left">
                <app-check-box formControlName="isMandatory"></app-check-box>
            </div>
        </div>
    </form>
    <ng-template #modalFooter let-activeModal>
        <div class="button-group button-group-center">
            <app-button color="SECONDARY" (onClick)="activeModal.close(true)"
                [disabled]="formSaving">
                {{ 'app.button.cancel' | translate}}
            </app-button>
            <app-button (onClick)="doSave() " [disabled]="formSaving">
                {{ 'app.button.save' | translate}}
            </app-button>
        </div>
    </ng-template>
</app-popup>