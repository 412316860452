import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { ProcurementType } from '../../core/bean/procurement-type';
import { AppPopupCoaBjbService } from '../../core/components/app-popup/app-popup-coa-bjb/app-popup-coa-bjb.service';
import { OptionListModel } from '../../core/model/option-list-model';
import { BudgetAllocation } from './../../core/bean/budget-allocation';
import { BudgetType } from './../../core/bean/budget-type';
import { Coa } from './../../core/bean/coa';
import { CostCenter } from './../../core/bean/cost-center';
import { Currency } from './../../core/bean/currency';
import { Organization } from './../../core/bean/organization';
import { PurchaseRequestBudget } from './../../core/bean/purchase-request-budget';
import { TableResponseModel } from './../../core/components/app-table/model/table-response-model';
import { Validators } from './../../core/validators/index';
import { ChangeBudget } from './../work-plan/work-plan-item.model';
import { PurchaseRequestBudgetModel } from './purchase-request-budget.model';

@Component({
  selector: 'app-popup-choose-budget-by-filter',
  templateUrl: './app-popup-choose-budget-by-filter.component.html'
})
export class AppPopupChooseBudgetByFilterComponent extends BaseComponentComponent {
  @Input() public todo: string;
  @Input() public purchaseRequestBudgetEdit: PurchaseRequestBudget;
  @Input() public budgetAllocationId: number;
  @Input() public budgetTypeList: BudgetType[] = [];
  @Input() public costCenterList: CostCenter[] = [];
  @Input() public currencyList: Currency[] = [];
  @Input() public budgetIdList: number[] = [];
  @Output() public onChange: EventEmitter<PurchaseRequestBudget[]> =
    new EventEmitter();

  public tableResponse: TableResponseModel<BudgetAllocation>;
  public procurementTypeOptionList: OptionListModel<ProcurementType> =
    new OptionListModel(false);
  public budgetTypeOptionList: OptionListModel<BudgetType> =
    new OptionListModel(false);
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );
  public periodOptionList: OptionListModel<any> = new OptionListModel(false);
  public autoCompleteValue: any;
  public totalBudget: number;
  public purchaseRequestBudgetModel: PurchaseRequestBudgetModel =
    new PurchaseRequestBudgetModel();
  public isGenerateTableBudget: boolean = null;
  public errorMapper: Map<number, string> = new Map();
  public purchaseRequestBudgetList: PurchaseRequestBudget[] = [];
  public organizationIdParams: Object;

  constructor(public appPopupCoaBjbService: AppPopupCoaBjbService) {
    super('procurement-offline-submission');
  }

  onInit(): void {
    this.setOptionModel();
    this.buildformGroup();
    this.setTableBudget();
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setTableBudget(): void {
    if (this.todo === 'edit') {
      this.buildTableResponse();
      const budgetList: number[] = [];
      budgetList.push(this.purchaseRequestBudgetEdit.budgetAllocation.id);
      this.tableResponse.setCustomData({
        budgetAllocationId: budgetList
      });
      const plannedBudget: ChangeBudget = {
        id: this.purchaseRequestBudgetEdit.budgetAllocation.id,
        isChecked: true,
        plannedBudget: this.purchaseRequestBudgetEdit.value,
        organization:
          this.purchaseRequestBudgetEdit.budgetAllocation.organization,
        costCenter: this.purchaseRequestBudgetEdit.budgetAllocation.costCenter,
        coa: this.purchaseRequestBudgetEdit.budgetAllocation.coa,
        budgetType: this.purchaseRequestBudgetEdit.budgetAllocation.budgetType,
        currency: this.purchaseRequestBudgetEdit.budgetAllocation.currency,
        workProgram: this.purchaseRequestBudgetEdit.budgetAllocation.workProgram
      };
      this.purchaseRequestBudgetModel.recordPageMap.set(
        this.purchaseRequestBudgetEdit.budgetAllocation.id,
        plannedBudget
      );
      this.totalBudget = this.purchaseRequestBudgetEdit.value;
      this.buildTableResponse();
      const budgetId: number[] = [];
      budgetId.push(this.purchaseRequestBudgetEdit.budgetAllocation.id);
      this.tableResponse.setCustomData({
        budgetAllocationId: budgetId
      });
      this.isGenerateTableBudget = true;
      this.setError(this.purchaseRequestBudgetEdit.budgetAllocation);
    }
    this.setStateReady();
  }

  public setOptionModel(): void {
    this.budgetTypeOptionList.setRequestValues(this.budgetTypeList);
    this.currencyOptionList.setRequestValues(this.currencyList);
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const periodOptionList = [
      { id: 1, name: currentYear },
      { id: 2, name: nextYear }
    ];
    this.periodOptionList.setRequestValues(periodOptionList);
  }

  public buildformGroup(): void {
    this.formGroup = this.formBuilder.group({
      budgetAllocationId: [null],
      organization: [null, Validators.compose([Validators.required()])],
      workProgram: [null, Validators.compose([Validators.required()])],
      budgetType: [null],
      costCenter: [null],
      coa: [null],
      coaName: [null],
      activityPeriod: [null]
    });
  }

  public doChooseCoa(): void {
    this.appPopupCoaBjbService.open().subscribe((coa: Coa) => {
      this.formGroup.patchValue({
        coa,
        coaName: coa.name
      });
    });
  }

  public onChangeOrganization(): void {
    this.organizationIdParams = {
      organizationId:
        this.formGroup.get('organization').value[
          this.formGroup.get('organization').value.length - 1
        ].id
    };
    this.formGroup.get('workProgram').patchValue(null);
  }

  public autoCompleteValueChange(event: any): void {
    this.autoCompleteValue = event;
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'organization.name',
        header: 'table.column.organization',
        customClass: 'text-center'
      },
      { field: 'workProgram.name', header: 'table.column.workProgram' },
      {
        field: 'costCenter.code',
        header: 'table.column.costCenterCode',
        customClass: 'text-center'
      },
      {
        field: 'costCenter.name',
        header: 'table.column.costCenterName'
      },
      {
        field: 'coa.code',
        header: 'table.column.coaCode',
        customClass: 'text-center'
      },
      { field: 'coa.name', header: 'table.column.coaName' },
      { field: 'budgetType.name', header: 'table.column.budgetType' },
      {
        field: 'totalBudget',
        header: 'table.column.availableBudget',
        customClass: 'text-right',
        fn: this.calculateAvailable.bind(this)
      }
    ]);
  }

  public calculateAvailable(
    totalBudget: number,
    budget: BudgetAllocation
  ): string {
    let available = this.global.converterService.convertMoney(
      totalBudget - +budget.plannedBudget
    );
    available = available === undefined ? '0 ' : available + ' ';
    available += budget.currency.code;
    return available;
  }

  public doApply(): void {
    this.validate();
    if (this.formGroup.valid) {
      /* PRODUCT */
      /* const coaList: Coa[] = this.formGroup.get('coa').value; */
      const organizationList: Organization[] =
        this.formGroup.get('organization').value;
      const coa: Coa = this.formGroup.get('coa').value;
      if (coa !== null) {
        this.formGroup.patchValue({
          coa,
          coaName: coa.name
        });
      } else {
        this.formGroup.patchValue({
          coa: null,
          coaName: null
        });
      }
      if (organizationList !== null && organizationList.length > 0) {
        this.formGroup.patchValue({
          organization: organizationList[organizationList.length - 1]
        });
      } else {
        this.formGroup.patchValue({
          organization: null
        });
      }
      if (this.formGroup.get('activityPeriod').value) {
        this.formGroup.patchValue({
          activityPeriod: this.formGroup.get('activityPeriod').value.name
        });
      }

      if (this.todo === 'edit') {
        this.budgetIdList = this.budgetIdList.filter(
          budgetId =>
            budgetId !== this.purchaseRequestBudgetEdit.budgetAllocation.id
        );
      }
      this.formGroup.get('budgetAllocationId').patchValue(this.budgetIdList);
      if (this.isGenerateTableBudget === null) {
        this.buildTableResponse();
        this.tableResponse.setCustomData(this.formGroup.value);
        this.log.debug(this.purchaseRequestBudgetModel);
      } else {
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
      }
      this.isGenerateTableBudget = true;
      this.formGroup.patchValue({
        coa,
        organization: organizationList
      });
      this.setOptionModel();
    }
  }

  public doReset(): void {
    this.formGroup.reset();
    this.purchaseRequestBudgetModel.recordPageMap.clear();
    if (this.isGenerateTableBudget !== null && this.todo === 'add') {
      this.isGenerateTableBudget = false;
      this.tableResponse.resetCustomData();
    } else if (this.isGenerateTableBudget && this.todo !== 'add') {
      const plannedBudget: ChangeBudget = {
        id: this.purchaseRequestBudgetEdit.budgetAllocation.id,
        isChecked: true,
        plannedBudget: this.purchaseRequestBudgetEdit.value,
        organization:
          this.purchaseRequestBudgetEdit.budgetAllocation.organization,
        costCenter: this.purchaseRequestBudgetEdit.budgetAllocation.costCenter,
        coa: this.purchaseRequestBudgetEdit.budgetAllocation.coa,
        budgetType: this.purchaseRequestBudgetEdit.budgetAllocation.budgetType,
        currency: this.purchaseRequestBudgetEdit.budgetAllocation.currency,
        workProgram: this.purchaseRequestBudgetEdit.budgetAllocation.workProgram
      };
      this.purchaseRequestBudgetModel.recordPageMap.set(
        this.purchaseRequestBudgetEdit.budgetAllocation.id,
        plannedBudget
      );
      const budget: number[] = [];
      budget.push(this.purchaseRequestBudgetEdit.budgetAllocation.id);
      this.tableResponse.setCustomData({
        budgetAllocationId: budget
      });
      this.tableResponse.reload();
    }
  }

  public handleFocusOut(event: any): void {
    if (!isNaN(+event.target.value)) {
      const uConvertMoneyValue =
        event.target.value === ''
          ? '0'
          : this.global.converterService.uConvertMoney(event.target.value);
      event.target.value =
        this.global.converterService.convertMoney(uConvertMoneyValue);
    }
  }

  public handleFocusIn(event: any): void {
    const uConvertMoneyValue = this.global.converterService.uConvertMoney(
      event.target.value
    );
    event.target.value = uConvertMoneyValue;
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public doSort(sortField: string): void {
    this.tableResponse.setSortField(sortField);
    this.tableResponse.reload();
  }

  public doCheckAll(event: any): void {
    this.log.info(event.target.checked);
    this.purchaseRequestBudgetModel.isCheckedAll = event.target.checked;
    this.tableResponse.page.records.forEach(budgetAllocation => {
      let plannedBudget: ChangeBudget =
        this.purchaseRequestBudgetModel.recordPageMap.get(budgetAllocation.id);
      if (!plannedBudget) {
        plannedBudget = {
          id: budgetAllocation.id,
          isChecked: event.target.checked,
          plannedBudget: 0,
          organization: budgetAllocation.organization,
          costCenter: budgetAllocation.costCenter,
          coa: budgetAllocation.coa,
          budgetType: budgetAllocation.budgetType,
          currency: budgetAllocation.currency,
          workProgram: budgetAllocation.workProgram
        };
      }
      plannedBudget.isChecked = event.target.checked;
      this.purchaseRequestBudgetModel.recordPageMap.set(
        budgetAllocation.id,
        plannedBudget
      );
      this.setError(budgetAllocation, event.target.checked);
    });

    this.totalBudget = 0;

    this.purchaseRequestBudgetModel.recordPageMap.forEach(changeBudget => {
      changeBudget.isChecked = event.target.checked;
      if (!event.target.checked) {
        this.purchaseRequestBudgetModel.recordPageMap.delete(changeBudget.id);
      } else {
        this.totalBudget = this.totalBudget + +changeBudget.plannedBudget;
      }
    });
  }

  public doCheck(event: any, budgetAllocation: BudgetAllocation): void {
    let plannedBudget: ChangeBudget =
      this.purchaseRequestBudgetModel.recordPageMap.get(budgetAllocation.id);
    if (!plannedBudget) {
      plannedBudget = {
        id: budgetAllocation.id,
        isChecked: event.target.checked,
        plannedBudget: 0,
        organization: budgetAllocation.organization,
        costCenter: budgetAllocation.costCenter,
        coa: budgetAllocation.coa,
        budgetType: budgetAllocation.budgetType,
        currency: budgetAllocation.currency,
        workProgram: budgetAllocation.workProgram
      };
    }

    if (this.purchaseRequestBudgetModel.isCheckedAll && !event.target.checked) {
      this.purchaseRequestBudgetModel.isCheckedAll = false;
      plannedBudget.isChecked = event.target.checked;
    } else {
      plannedBudget.isChecked = event.target.checked;
    }
    this.purchaseRequestBudgetModel.recordPageMap.set(
      budgetAllocation.id,
      plannedBudget
    );
    this.setError(budgetAllocation);
    this.totalBudget = 0;
    this.purchaseRequestBudgetModel.recordPageMap.forEach(
      purchaseRequestBudgetModel => {
        this.totalBudget =
          this.totalBudget + +purchaseRequestBudgetModel.plannedBudget;
      }
    );

    if (!event.target.checked) {
      this.totalBudget =
        this.totalBudget -
        this.purchaseRequestBudgetModel.recordPageMap.get(budgetAllocation.id)
          .plannedBudget;
      this.purchaseRequestBudgetModel.recordPageMap.delete(budgetAllocation.id);
    }
  }

  public doOnInput(budgetAllocation: BudgetAllocation, ngModel: NgModel): void {
    if (ngModel.value && !isNaN(+ngModel.value)) {
      let plannedBudget: ChangeBudget =
        this.purchaseRequestBudgetModel.recordPageMap.get(budgetAllocation.id);
      if (!plannedBudget) {
        plannedBudget = {
          id: budgetAllocation.id,
          isChecked: !!this.purchaseRequestBudgetModel.isCheckedAll,
          plannedBudget: +(+ngModel.value).toFixed(
            this.global.appConstant.core.CURRENCY_PRECISSION_SCALE
          ),
          organization: budgetAllocation.organization,
          costCenter: budgetAllocation.costCenter,
          coa: budgetAllocation.coa,
          budgetType: budgetAllocation.budgetType,
          currency: budgetAllocation.currency,
          workProgram: budgetAllocation.workProgram
        };
      }
      plannedBudget.plannedBudget = +(+ngModel.value).toFixed(
        this.global.appConstant.core.CURRENCY_PRECISSION_SCALE
      );
      this.purchaseRequestBudgetModel.recordPageMap.set(
        plannedBudget.id,
        plannedBudget
      );
      this.setError(budgetAllocation);
      if (plannedBudget.isChecked) {
        this.totalBudget = 0;
        this.purchaseRequestBudgetModel.recordPageMap.forEach(
          purchaseBudgetModel => {
            this.totalBudget += +purchaseBudgetModel.plannedBudget;
          }
        );
      }
    } else {
      this.setError(budgetAllocation);
    }
  }

  public setError(
    budgetAllocation: BudgetAllocation,
    isCheckedDefault = false
  ): void {
    if (
      !isNaN(
        +this.purchaseRequestBudgetModel.recordPageMap.get(budgetAllocation.id)
          .plannedBudget
      )
    ) {
      let availablePlanned: number;
      const { plannedBudget: plannedBudget, isChecked } =
        this.purchaseRequestBudgetModel.recordPageMap.get(budgetAllocation.id);

      availablePlanned =
        budgetAllocation.totalBudget - budgetAllocation.plannedBudget;

      if (plannedBudget > availablePlanned && (isCheckedDefault || isChecked)) {
        this.errorMapper.set(
          budgetAllocation.id,
          this.global.translateService.instant('app.validation.max')
        );
      } else if (plannedBudget <= 0 && (isCheckedDefault || isChecked)) {
        this.errorMapper.set(
          budgetAllocation.id,
          this.global.translateService.instant('app.validation.min')
        );
      } else {
        this.errorMapper.delete(budgetAllocation.id);
      }
    } else {
      this.errorMapper.set(
        budgetAllocation.id,
        this.global.translateService.instant(
          'procurement-offline-submission.msg.error.isNotNumber'
        )
      );
    }
  }

  public doSubmit(): void {
    if (this.purchaseRequestBudgetModel.recordPageMap.size > 0) {
      if (this.errorMapper.size === 0) {
        this.purchaseRequestBudgetModel.recordPageMap.forEach(
          budgetAllocation => {
            const formGroup = this.formBuilder.group({
              budgetAllocation,
              value: budgetAllocation.plannedBudget
            });
            this.purchaseRequestBudgetList.push(formGroup.value);
          }
        );
        this.onChange.emit(this.purchaseRequestBudgetList);
        this.log.debug(this.purchaseRequestBudgetList);
      }
    } else {
      this.global.alertService.showError(
        this.global.translateService.instant(
          'procurement-offline-submission.popup.msg.error.listBudgetEmpty'
        ),
        '.main-row-popup'
      );
    }
  }
}
