import { Component, Input, ViewChild } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { BillingMemo } from 'src/app/core/bean/billing-memo';
import { BillingMemoTax } from 'src/app/core/bean/billing-memo-tax';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { BillingMemoBudget } from '../../bean/billing-memo-budget';
import { BillingMemoOtherCost } from '../../bean/billing-memo-other-cost';
import { BudgetAllocation } from '../../bean/budget-allocation';
import { AppPopupBillingMemoBudgetDetailService } from '../app-popup/app-popup-billing-memo-budget-detail/app-popup-billing-memo-budget-detail.service';
import { AppPopupService } from '../app-popup/app-popup.service';
import { AppBillingMemoInformationViewResponse } from './app-billing-memo-information-view.response';
import { AppPopupBillingMemoInfoViewBudgetDetailComponent } from './app-popup-billing-memo-info-view-budget-detail.component';

@Component({
  selector: 'app-billing-memo-information-view',
  templateUrl: './app-billing-memo-information-view.component.html'
})
export class AppBillingMemoInformationViewComponent extends BaseComponentComponent {
  @Input() public billingMemoId: number;
  public billingMemo: BillingMemo;
  public tableResponseItem: TableResponseModel<BillingMemoBudget>;
  public tableResponseTax: TableResponseModel<BillingMemoTax>;
  public tableResponseOtherCost: TableResponseModel<BillingMemoOtherCost>;
  public appBillingMemoInformationViewResponse: AppBillingMemoInformationViewResponse;
  public billingMemoOtherCostList: BillingMemoOtherCost[] = [];
  public billingMemoItemList: BillingMemoBudget[] = [];
  public billingMemoTaxList: BillingMemoTax[] = [];
  public totalAmountItem: number;
  public totalAmountTax: number;
  public transactionType: string;

  @ViewChild('selectorItem') public tableItem: AppTableComponent;
  @ViewChild('selectorTax') public tableTax: AppTableComponent;
  @ViewChild('selectorOtherCost') public tableOtherCost: AppTableComponent;

  constructor(
    public appPopupService: AppPopupService,
    public appPopupBudgetDetailService: AppPopupBillingMemoBudgetDetailService
  ) {
    super('app-billing-memo-information-view');
  }

  public onInit(): void {
    this.setFormGroup();
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<AppBillingMemoInformationViewResponse>(
        '/app-billing-memo-information-view/get-billing-memo/' +
          this.billingMemoId
      )
      .subscribe(appBillingMemoInformationViewResponse => {
        this.appBillingMemoInformationViewResponse =
          appBillingMemoInformationViewResponse;
        this.billingMemo = appBillingMemoInformationViewResponse.billingMemo;
        this.billingMemoItemList =
          appBillingMemoInformationViewResponse.billingMemoItemList;
        this.billingMemoTaxList =
          appBillingMemoInformationViewResponse.billingMemoTaxList;
        this.billingMemoOtherCostList =
          appBillingMemoInformationViewResponse.billingMemoOtherCostList;
        this.setStateReady();
      });
  }

  public doDetailBudget(budgetAllocation: BudgetAllocation): void {
    this.appPopupBudgetDetailService.open(budgetAllocation).subscribe();
  }

  public doViewBudget(billingMemoBudget: BillingMemoBudget): void {
    const billingMemoBudgetDetailList =
      billingMemoBudget.billingMemoBudgetDetailList;
    const totalBilling = billingMemoBudget.totalAmount;
    const taxPercentage = billingMemoBudget.taxPercentageValue;
    const budgetAllocation = billingMemoBudget.budgetAllocation;
    const currency = this.billingMemo.payment.currency;
    this.appPopupService
      .open(
        AppPopupBillingMemoInfoViewBudgetDetailComponent,
        {
          billingMemoBudgetDetailList,
          totalBilling,
          taxPercentage,
          budgetAllocation,
          currency
        },
        { size: 'xl' }
      )
      .subscribe();
  }
}
