import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { ItemType } from '../../core/bean/item-type';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { Quotation } from '../../core/bean/quotation';
import { OptionListModel } from '../../core/model/option-list-model';
import { ProcurementNotifResponseModel } from '../../core/model/procurement-notif-response-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { QuotationSubmissionVendorView } from '../../core/view/entity/bean/quotation-submission-vendor-view';
import { WorklistPMService } from '../worklist-pm/worklist-pm.service';
import { SubmitQuotationPopupSubmissionDetailComponent } from './submit-quotation-popup-submission-detail.component';
import { SubmitQuotationRequest } from './submit-quotation.request';
import { SubmitQuotationResponse } from './submit-quotation.response';

@Component({
  templateUrl: './submit-quotation-edit.component.html'
})
export class SubmitQuotationEditComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  @ViewChild('selectorProposalCriteria')
  tableProposalCriteria: AppTableXComponent;
  @ViewChild('selectorVendor')
  tableVendor: AppTableXComponent;

  public token: string;
  public stage: number;
  public objectId: number;
  public procurementId: number;
  public approvalModelPrcsId: number;
  public procurementVendorId: number;
  public urlBackOutside: string;
  public CURRENCY_DIGITS_INFO: string;
  public currentDate: Date = new Date();

  public tabs: Array<any>;
  public procurement: Procurement = new Procurement();
  public submitQuotationRequest: SubmitQuotationRequest =
    new SubmitQuotationRequest();
  public submitQuotationResponse: SubmitQuotationResponse =
    new SubmitQuotationResponse();

  public procurementItemList: ProcurementItem[] = [];
  public isSubmit = false;
  public tableResponse: TableResponseModel<any>;
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    false
  );
  public isShowBadgeStatus: boolean;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public currency: CurrencyPipe,
    public snackbarService: SnackbarService,
    public datePipe: DatePipe,
    public activatedRoute: ActivatedRoute,
    public worklistPMService: WorklistPMService
  ) {
    super('submit-quotation', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<ProcurementNotifResponseModel>(
        '/submit-quotation/notification/' + this.token
      )
      .subscribe((response: ProcurementNotifResponseModel) => {
        this.worklistPMService.doNavigateDetail(response, this.moduleCode);
      });
  }

  public setInitializationState(): void {
    this.doSetDataFromRouterParams();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setFormGroup();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public doSetDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementVendorId = this.global.routerParams.get(
      'procurementVendorId'
    );
    this.procurement = this.global.routerParams.get('procurement');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      itemType: [null],
      amount: [null],
      goodsAmount: [null],
      serviceAmount: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<SubmitQuotationResponse>(
        '/submit-quotation/add-edit',
        new RouteRequestModel(this.todo, this.procurementId)
      )
      .subscribe((submitQuotationResponse: SubmitQuotationResponse) => {
        this.submitQuotationResponse = submitQuotationResponse;
        this.procurement = submitQuotationResponse.procurement;
        this.todo = this.procurement.isRepeat ? 'edit' : this.todo;
        this.global.routerParams.set(
          'submitQuotationResponse',
          this.submitQuotationResponse
        );
        this.itemTypeOptionList.setRequestValues(
          this.submitQuotationResponse.itemTypeList
        );
        this.tableResponse.setRecordList(
          this.submitQuotationResponse.quotationSubmissionVendorViewList
        );
        this.setIsViewOnlyFormGroup();
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'vendorName',
        header: 'table.column.vendorName',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClickVendor.bind(this)
        }
      },
      {
        field: 'email',
        header: 'table.column.email'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      },
      {
        field: 'picName',
        header: 'table.column.picVendor'
      },
      {
        field: 'submittedDate',
        header: 'table.column.submittedDate',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'submittedStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'submittedStatus.code',
          colorMap: {
            UNAVAILABLE: 'SECONDARY',
            AVAILABLE: 'SUCCESS',
            PARTLY_AVAILABLE: 'SUCCESS'
          }
        },
        className: 'text-center'
      }
    ]);
  }

  public doClickVendor(vendorView: QuotationSubmissionVendorView): void {
    if (
      vendorView.submittedStatus.code ===
        this.global.appConstant.pm.SUBMITTED_STATUS_UNAVAILABLE &&
      this.todo !== 'view'
    ) {
      this.global.modalService
        .confirmation(
          vendorView.vendorName +
            ' ' +
            this.translateService.instant(
              'submit-quotation.confirm.msg.sendEmail'
            ),
          'submit-quotation.confirm.title.sendEmail',
          'app.button.close',
          'submit-quotation.confirm.button.sendEmail',
          'pvc pv-envelope-reminder',
          false
        )
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<QuotationSubmissionVendorView>>(
                '/submit-quotation/send-reminder-vendor',
                vendorView
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.snackbarService.showWarning(
                    'app.msg.info.reminderEmailSent'
                  );
                  this.tableResponse.reload();
                  this.setStateReady();
                }
              });
          }
        });
    } else if (
      vendorView.submittedStatus.code !==
      this.global.appConstant.pm.SUBMITTED_STATUS_UNAVAILABLE
    ) {
      if (
        vendorView.procurementVendor.workflowModelPrcs.code !==
          'SUBMIT-QUOTATION' ||
        this.procurement.documentSubmissionType.code !==
          this.global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE
      ) {
        this.appPopupService.open(
          SubmitQuotationPopupSubmissionDetailComponent,
          {
            vendorView
          }
        );
      }
    }
  }

  public doSubmit(): void {
    let totalAvailable = 0;
    this.submitQuotationResponse.quotationSubmissionVendorViewList.forEach(
      (vendorView: QuotationSubmissionVendorView) => {
        if (
          vendorView.submittedStatus.code !==
          this.global.appConstant.pm.SUBMITTED_STATUS_UNAVAILABLE
        ) {
          totalAvailable += 1;
        }
      }
    );
    const currentDate = new Date().getTime();
    const endDate =
      this.submitQuotationResponse.procurementSchedule &&
      this.submitQuotationResponse.procurementSchedule.endDate
        ? new Date(
            this.submitQuotationResponse.procurementSchedule.endDate
          ).getTime()
        : null;
    if (endDate && currentDate < endDate) {
      const date = this.datePipe.transform(
        this.submitQuotationResponse.procurementSchedule.endDate,
        this.global.config.parameterModel.datetimeFormatShort
      );
      this.global.modalService.confirmation(
        this.translateService.instant(
          'submit-quotation.dueDateAvailable.prompt'
        ) +
          ' ' +
          date,
        this.translateService.instant(
          'submit-quotation.dueDateAvailable.title'
        ),
        null,
        null,
        'pvc pv-sandglass',
        true
      );
    } else if (
      totalAvailable < this.submitQuotationResponse.minimumParticipant
    ) {
      this.global.modalService
        .confirmation(
          this.translateService.instant(
            'submit-quotation.confirmation.minParticipant.prompt'
          ),
          this.translateService.instant(
            'submit-quotation.submitConfirmation.title'
          ),
          'app.button.cancel',
          'submit-quotation.button.yesIamSure',
          null,
          false
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const url = '/submit-quotation/procurement-failed';
            this.httpClientService
              .post<Response<Quotation>>(url, this.procurement)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService.submitSuccess().subscribe(result => {
                    if (result) {
                      this.router.navigate(['/pages/worklist-pm']);
                    } else {
                      this.todo = 'view';
                      this.procurement.procurementWorklistStatus.code =
                        this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_HOLD;
                    }
                  });
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    } else if (
      totalAvailable !==
      this.submitQuotationResponse.quotationSubmissionVendorViewList.length
    ) {
      this.global.modalService
        .confirmation(
          this.translateService.instant(
            'submit-quotation.submitConfirmation.prompt'
          ),
          this.translateService.instant(
            'submit-quotation.submitConfirmation.title'
          ),
          'app.button.cancel',
          'submit-quotation.button.yesIamSure',
          null,
          false
        )
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const url = '/submit-quotation/insert';
            this.httpClientService
              .post<Response<Quotation>>(url, this.procurement)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService.submitSuccess().subscribe(result => {
                    if (result) {
                      this.router.navigate(['/pages/worklist-pm']);
                    } else {
                      this.todo = 'view';
                      this.procurement.procurementWorklistStatus.code =
                        this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_WAITING_APPROVAL;
                    }
                  });
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    } else {
      this.isSubmit = true;
      this.validate();
      if (this.formGroup.valid) {
        this.global.modalService
          .submitConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              const url = '/submit-quotation/insert';
              this.httpClientService
                .post<Response<Quotation>>(url, this.procurement)
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.global.modalService
                      .submitSuccess()
                      .subscribe(result => {
                        if (result) {
                          this.router.navigate(['/pages/worklist-pm']);
                        } else {
                          this.todo = 'view';
                          this.procurement.procurementWorklistStatus.code =
                            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_WAITING_APPROVAL;
                        }
                      });
                  } else {
                    this.global.alertService.showError(response.statusText);
                  }
                  this.setStateReady();
                });
            }
          });
      }
    }
  }
}
