import { NgModule } from '@angular/core';
import { AppButtonModule } from '../../../../../core/components/app-button/app-button.module';
import { AppChartModule } from '../../../../../core/components/app-chart/app-chart.module';
import { AppCheckBoxModule } from '../../../../../core/components/app-check-box/app-check-box.module';
import { AppDropdownSelectModule } from '../../../../../core/components/app-dropdown-select/app-dropdown-select.module';
import { AppPopupModule } from '../../../../../core/components/app-popup/app-popup.module';
import { PipesModule } from '../../../../../core/pipe/pipe.module';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetBestSpendingByDivisionPopupComponent } from './widget-best-spending-by-division-popup.component';
import { WidgetBestSpendingByDivisionComponent } from './widget-best-spending-by-division.component';

@NgModule({
  imports: [
    VendorModule,
    WidgetCardModule,
    AppChartModule,
    AppButtonModule,
    AppDropdownSelectModule,
    AppCheckBoxModule,
    PipesModule,
    AppPopupModule
  ],
  declarations: [
    WidgetBestSpendingByDivisionComponent,
    WidgetBestSpendingByDivisionPopupComponent
  ],
  entryComponents: [WidgetBestSpendingByDivisionComponent],
  exports: [WidgetBestSpendingByDivisionComponent]
})
export class WidgetBestSpendingByDivisionModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetBestSpendingByDivisionComponent.moduleCode,
      WidgetBestSpendingByDivisionComponent,
      'w-50'
    );
  }
}
