<div class="promise-loading" *ngIf="formLoading">
  <div class="loading"></div>
</div>
<app-card
  header="app-vendor-information.card.title"
  tips="app-vendor-information.card.tips"
>
  <app-table-xx
    [model]="tableResponse"
    stringUrl="/app-vendor-information/index"
  >
  </app-table-xx>
</app-card>
