import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { AddressBook } from '../../../bean/address-book';
import { Validators } from '../../../validators';
import { AppPopupTreeOrganizationService } from '../app-popup-tree/app-popup-tree-organization.service';
import { AppPopupUserService } from '../app-popup-user/app-popup-user.service';
import { AddresBookResponse } from './response/address-book-response';

@Component({
  templateUrl: './app-popup-address-book.component.html'
})
export class AppPopupAddressBookComponent extends BaseComponentComponent {
  addressBook: AddressBook = new AddressBook();
  @Output() onChange: EventEmitter<AddressBook> = new EventEmitter();

  constructor(
    public appPopupUserService: AppPopupUserService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService,
    public activeModal: NgbActiveModal
  ) {
    super('app-popup-address-book');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setStateReady();
  }

  buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      code: [null, Validators.required()],
      address: [null, Validators.required()],
      user: [null, Validators.required()],
      userName: [null, Validators.required()],
      organization: [null, Validators.required()],
      organizationName: [null, Validators.required()],
      phone: [null, Validators.required()],
      addressDetail: [null, Validators.required()]
    });
  }

  doChooseOrganization(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(data => {
        this.formGroup.patchValue({
          organization: data[0],
          organizationName: data[0].name
        });
      });
  }

  doChooseUser(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupUserService.open().subscribe(user => {
      this.formGroup.patchValue({
        user,
        userName: user.name
      });
    });
  }

  doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.addressBook = this.global.copyFormAttributeToModel(
              this.addressBook,
              this.formGroup
            );
            this.httpClientService
              .post<AddresBookResponse>(
                '/app-popup-address-book/insert',
                this.addressBook
              )
              .subscribe(addressBookResponse => {
                if (addressBookResponse.errorMessage !== null) {
                  this.setStateReady();
                  this.global.alertService.showError(
                    addressBookResponse.errorMessage,
                    '.modal-body'
                  );
                } else {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.onChange.emit(addressBookResponse.addressBook);
                }
              });
          }
        });
    }
  }
}
