import { BaseEntity } from '../base/base-entity';
import { AddressBook } from '../bean/address-book';
import { Pr } from '../bean/pr';

export class PrShippingEntity extends BaseEntity {
  crudOperation: number;

  pr: Pr = new Pr();
  address: AddressBook = new AddressBook();
}
