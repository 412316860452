import { Uploader } from '../model/uploader';
import { UploaderFile } from '../model/uploader-file';
import { createUploaderFileSrc } from './create-uploader-file-src.action';
import { createUploaderFile } from './create-uploader-file.action';
import { validateUploaderFile } from './validate-uploader-file.action';
export function changeFile(
  state: Uploader,
  payload: { fileList: FileList; index: number }
): void {
  const { fileList, index } = payload;
  if (fileList && fileList.length > 0) {
    state.setError(null);
    const uploaderFile: UploaderFile = createUploaderFileSrc(
      createUploaderFile(state, fileList.item(0))
    );
    const uploaderFileWithError = validateUploaderFile(uploaderFile);
    if (uploaderFileWithError.error) {
      state.setError(uploaderFileWithError.error);
    } else {
      const previousUploaderFile = Object.assign(
        {},
        state.uploaderFileList[index]
      );
      uploaderFile.setFileChange({ index, uploaderFile: previousUploaderFile });
      state.uploaderFileList[index] = uploaderFile;
    }
  }
}
