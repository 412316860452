import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ContractTemplate } from '../../core/bean/contract-template';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: 'contract-template.component.html'
})
export class ContractTemplateComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<ContractTemplate>;
  constructor(translateService: TranslateService) {
    super('contract-template', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.column.code' },
      { field: 'name', header: 'table.column.name' },
      { field: 'description', header: 'table.column.description' }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/contract-template/add']);
  }

  public doEdit(contractTemplate: ContractTemplate): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('contractTemplateId', contractTemplate.id);
    this.router.navigate(['/pages/contract-template/edit']);
  }

  public doDelete(contractTemplate: ContractTemplate): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<ContractTemplate>>(
              '/contract-template/delete',
              contractTemplate.id
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
