import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Contract } from 'src/app/core/bean/contract';
import { GuaranteeBondType } from 'src/app/core/bean/guarantee-bond-type';
import { ProcurementGuarantee } from 'src/app/core/bean/procurement-guarantee';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './app-popup-procurement-guarantee.component.html'
})
export class AppPopupProcurementGuaranteeComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() public todo: string;
  @Input() public procurementGuaranteeEdit: ProcurementGuarantee;
  @Input() public contract: Contract;
  @Input() public procurementVendor: ProcurementVendor;
  @Input() public guaranteeBondTypeList: GuaranteeBondType[] = [];
  @Output()
  public onChange: EventEmitter<ProcurementGuarantee> = new EventEmitter();

  public guaranteeBondTypeOptionList: OptionListModel<GuaranteeBondType> =
    new OptionListModel();
  public currentDate: Date = new Date();

  constructor() {
    super('app-popup-procurement-guarantee');
  }

  onInit(): void {
    this.buildFormGroup();
    this.guaranteeBondTypeOptionList.setRequestValues(
      this.guaranteeBondTypeList
    );
    this.setFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      guaranteeBondType: [null, Validators.compose([Validators.required()])],
      name: [null, Validators.compose([Validators.required()])],
      percentageValue: [
        null,
        Validators.compose([Validators.required(), Validators.max(100)])
      ],
      amount: [null],
      startDate: [null],
      endDate: [null, Validators.min('startDate')],
      submissionDate: [
        null,
        Validators.compose([Validators.required(), Validators.max('endDate')])
      ],
      description: [null],
      currency: [null],
      percentage: [null]
    });

    if (this.router.url === '/pages/contract-monitoring/detail') {
      this.formGroup.get('endDate').clearValidators();
      this.formGroup
        .get('endDate')
        .setValidators([Validators.required(), Validators.min('startDate')]);
      this.formGroup.get('endDate').updateValueAndValidity();
      this.formGroup.get('startDate').clearValidators();
      this.formGroup.get('startDate').setValidators([Validators.required()]);
      this.formGroup.get('startDate').updateValueAndValidity();
    }
  }

  public setFormGroup(): void {
    if (this.todo !== 'add') {
      const startDate: Date = this.procurementGuaranteeEdit.startDate
        ? new Date(this.procurementGuaranteeEdit.startDate)
        : null;
      const endDate: Date = this.procurementGuaranteeEdit.endDate
        ? new Date(this.procurementGuaranteeEdit.endDate)
        : null;
      const submissionDate: Date = new Date(
        this.procurementGuaranteeEdit.submissionDate
      );
      this.formGroup.patchValue({
        id: this.procurementGuaranteeEdit.id,
        guaranteeBondType: this.procurementGuaranteeEdit.guaranteeBondType,
        name: this.procurementGuaranteeEdit.name,
        percentageValue: this.procurementGuaranteeEdit.percentageValue,
        amount: this.procurementGuaranteeEdit.amount,
        startDate,
        endDate,
        submissionDate,
        description: this.procurementGuaranteeEdit.description,
        currency: this.procurementGuaranteeEdit.currency,
        percentage:
          this.procurementGuaranteeEdit.guaranteeBondType.percentageValue
      });
      this.currentDate = this.procurementGuaranteeEdit.startDate
        ? new Date(this.procurementGuaranteeEdit.startDate)
        : null;
      this.onChangeGuaranteeBondType();
    }

    if (this.router.url === '/pages/contract-monitoring/detail') {
      this.formGroup.get('guaranteeBondType').setIsView(true);
      this.formGroup.get('name').setIsView(true);
      this.formGroup.get('percentageValue').setIsView(true);
      this.formGroup.get('amount').setIsView(true);
      this.formGroup.get('description').setIsView(true);
      this.formGroup.get('currency').setIsView(true);
    }
  }
  public onChangePercentageValue(): void {
    const hpsAmount = this.contract
      ? this.contract.procurementVendor.procurement.hpsAmount
      : this.procurementVendor.procurement.hpsAmount;
    const percentageValue = this.formGroup.value.percentageValue;
    const amount = this.contract
      ? this.contract.procurementVendor.amount
      : this.procurementVendor.amount;
    const hpsAmounts = (80 / 100) * hpsAmount;
    let value: number;
    if (+amount >= +hpsAmounts) {
      value = +amount * (+percentageValue / 100);
    } else {
      value = +hpsAmount * (+percentageValue / 100);
    }
    this.formGroup.patchValue({
      amount: value,
      currency: this.contract
        ? this.contract.procurementVendor.currency
        : this.procurementVendor.currency
    });
  }

  public onChangeGuaranteeBondType(): void {
    const guaranteeBondType = this.formGroup.value.guaranteeBondType;
    if (guaranteeBondType) {
      this.formGroup.patchValue({
        percentageValue: guaranteeBondType.percentageValue
      });
      this.onChangePercentageValue();
      this.formGroup.patchValue({
        percentage: guaranteeBondType.percentageValue
      });
      if (guaranteeBondType.percentageRule === 1) {
        this.formGroup.get('percentageValue').setIsView(true);
      } else if (guaranteeBondType.percentageRule === 2) {
        this.formGroup.get('percentageValue').setIsView(false);
        this.formGroup.get('percentageValue').clearValidators();
        this.formGroup
          .get('percentageValue')
          .setValidators([Validators.required(), Validators.min('percentage')]);
        this.formGroup.get('percentageValue').updateValueAndValidity();
      } else if (guaranteeBondType.percentageRule === 3) {
        this.formGroup.get('percentageValue').setIsView(false);
        this.formGroup.get('percentageValue').clearValidators();
        this.formGroup
          .get('percentageValue')
          .setValidators([Validators.required(), Validators.max('percentage')]);
        this.formGroup.get('percentageValue').updateValueAndValidity();
      }
    }
  }
  public doSave(): void {
    this.validate();
    if (this.router.url === '/pages/contract-monitoring/detail') {
      if (this.formGroup.valid) {
        this.global.modalService
          .saveConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              const procurementGuarantee: ProcurementGuarantee =
                this.formGroup.value;
              procurementGuarantee.id = this.procurementGuaranteeEdit.id;
              procurementGuarantee.procurementScopeWork =
                this.procurementGuaranteeEdit.procurementScopeWork;
              this.procurementGuaranteeEdit = this.formGroup.value;
              this.httpClientService
                .post<Response<ProcurementGuarantee>>(
                  '/contract-monitoring/update-procurement-guarantee',
                  procurementGuarantee
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.onChange.emit(this.formGroup.value);
                    this.global.alertService.showSuccess(
                      this.global.translateService.instant(
                        'app.alert.msg.saveSuccess'
                      )
                    );
                  } else {
                    this.setStateReady();
                    this.global.alertService.showError(response.statusText);
                  }
                  this.setStateReady();
                });
            }
          });
      }
    } else {
      if (this.formGroup.valid) {
        this.onChange.emit(this.formGroup.value);
      }
    }
  }
}
