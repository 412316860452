<app-layout>
    <div class="row main-row">
      <div class="col-lg-12 mb-4">
        <app-table-x
          [model]="tableResponse"
          stringUrl="/claim-verification/index"
          (onClick)="doClick($event)"
          header="{{ 'claim-verification.table.header' | translate }}"
        >
          <ng-template #headerFilterGroup>
            <form
              class="form-horizontal pt-4"
              [formGroup]="formGroup"
              novalidate
              *ngIf="!formLoading"
            >
              <div class="form-group row text-sm-left">
                <label class="col-sm-1" style="margin-top: auto">{{
                  'claim-verification.form.date' | translate
                }}</label>
                <div class="col-sm-8 text-left" style="display: flex">
                  <app-date-picker-x
                    size="XL"
                    formControlName="date"
                    [range]="true"
                    (onChange)="onChangeFilter()"
                    [ngStyle]="{
                      'margin-right': '7px',
                      width: 'inherit'
                    }"
                  >
                  </app-date-picker-x>
                  <!-- <app-dropdown-select
                    *ngIf="!formLoading"
                    size="XL"
                    formControlName="hospitalCodeList"
                    (onChange)="onChangeFilter()"
                    placeholder="{{ 'claim-verification.placeholder.hospitalName' | translate }}"
                    [optionList]="hospitalNameOptionList"
                    type="CHOSEN"
                    [ngStyle]="{
                      'margin-right': '7px',
                      width: '467px'
                    }"
                    showLimitValue="1"
                  >
                    <ng-template #selectAll>
                      <p> {{ 'claim-verification.placeholder.allHospitalName' | translate }} </p>
                    </ng-template></app-dropdown-select
                  > -->
                  <app-dropdown-select
                    size="LG"
                    formControlName="claimStatusList"
                    placeholder="{{ 'claim-verification.placeholder.status' | translate }}"
                    [optionList]="claimStatusOptionList"
                    (onChange)="onChangeFilter()"
                    type="CHOSEN"
                    [ngStyle]="{
                      width: 'inherit'
                    }"
                    showLimitValue="1"
                  >
                    <ng-template #selectAll>
                      <p> {{ 'claim-verification.placeholder.allStatus' | translate }} </p>
                    </ng-template></app-dropdown-select
                  >
                </div>
              </div>
            </form>
          </ng-template>
        </app-table-x>
      </div>
    </div>
  </app-layout>
  