<app-popup [isLoading]="formLoading">
  <div class="text-center popup-successfuly">
    <br />
    <div
      class="pvc pv-check-circle"
      style="width: 100px; height: 100px; margin: 0 auto"
    ></div>
    <br />
    <h3>{{ title | translate }}</h3
    ><br />
    <div>{{ prompt | translate }}</div>
  </div>
  <br />
  <ng-template #modalFooter>
    <div class="button-group button-group-center">
      <app-button
        [outline]="true"
        (onClick)="doStayInPage()"
        [disabled]="formSaving"
        >{{ 'app.button.stayOnThisPage' | translate }}</app-button
      >
      <app-button (onClick)="doGoToAuction()" [disabled]="formSaving">{{
        'price-evaluation.button.goToAuction' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
