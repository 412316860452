<div class="widget-task-activity">
  <app-widget [header]="moduleCode + '.title'">
    <app-widget-content-slider>
      <app-widget-card
        *ngFor="let task of taskActivityList"
        header="{{ task.title | translate }}"
      >
        <h4>{{ task.content }}</h4>
        <div class="widget-card-footer mt-2">
          <ng-container
            *ngIf="
              task.code === TASK_CODE_REMAINING_PAYMENT ||
                task.code === TASK_CODE_PROC_ANNOUNCEMENT ||
                task.code === TASK_CODE_PROC_INVITATION;
              then moreDetailTemp;
              else defaultTemp
            "
          ></ng-container>
          <ng-template #moreDetailTemp>
            <p
              class="m-0 cursor-pointer more-detail"
              (click)="goToMoreDetail(task)"
              (keyup)="onKeyUp($event)"
              (keydown)="onKeyDown($event)"
            >
              {{ 'app-widget-task-activity.moreDetail' | translate }}
            </p>
          </ng-template>
          <ng-template #defaultTemp>
            <div class="widget-card-footer mt-2">
              <br />
            </div>
          </ng-template>
        </div>
      </app-widget-card>
    </app-widget-content-slider>
  </app-widget>
</div>
