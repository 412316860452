import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';

@Component({
  templateUrl: './app-popup-contract-request-choose-procurement.component.html'
})
export class AppPopupContractRequestChooseProcurementComponent extends BaseComponentComponent {
  @Input() isRepeatOrder: number;
  @Input() contractId: number;
  @Input() procurementVendor: ProcurementVendor;
  @Output() public onChange: EventEmitter<ProcurementVendor> =
    new EventEmitter();
  public tableResponseProcurement: TableResponseModel<ProcurementVendor>;

  constructor(public translateService: TranslateService) {
    super('contract-request');
  }

  onInit(): void {
    this.buildTableResponse();
    this.buildFormGroup();
    this.setCustomData();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponseProcurement = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurement.name',
        header: 'table.column.name',
        customClass: 'text-left'
      },
      {
        field: 'procurement.number',
        header: 'table.column.procurementNumber',
        customClass: 'text-center'
      },
      {
        field: 'procurement.procurementDate',
        header: 'table.column.date',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'organization.name',
        header: 'table.column.organization',
        customClass: 'text-left'
      },
      {
        field: 'vendor.name',
        header: 'table.column.vendor',
        customClass: 'white-space-normal text-left'
      }
    ]);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      isRepeatOrder: null,
      contractId: null,
      procurementVendor: null
    });
  }

  public setCustomData(): void {
    this.formGroup.patchValue({
      isRepeatOrder: this.isRepeatOrder,
      contractId: this.contractId || null
    });
    this.tableResponseProcurement.setCustomData(this.formGroup.value);
    this.formGroup.patchValue({
      procurementVendor: this.procurementVendor
    });
  }

  public doCheckProcurement(procurementVendor: ProcurementVendor): void {
    this.procurementVendor = procurementVendor;
  }

  public doSave(): void {
    if (this.procurementVendor) {
      if (
        this.formGroup.value.procurementVendor &&
        this.formGroup.value.procurementVendor.id !== this.procurementVendor.id
      ) {
        this.global.modalService
          .confirm(
            this.translateService.instant('contract-request.confirmation.body'),
            this.translateService.instant(
              'contract-request.confirmation.header'
            )
          )
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.onChange.emit(this.procurementVendor);
            }
          });
      } else {
        this.onChange.emit(this.procurementVendor);
      }
    } else {
      this.global.alertService.showError(
        this.global.translateService.instant('app.validation.chooseOneData'),
        '.main-row-popup-choose-procurement'
      );
    }
  }

  public doSortProcurement(sortField: string): void {
    this.tableResponseProcurement.setSortField(sortField);
    this.tableResponseProcurement.reload();
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
