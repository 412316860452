import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { TableResponseModel } from 'src/app/core/components/app-table-x/model/table-response-model';
import { HospitalStock } from 'src/app/core/bean/hospital-stock';

@Component({
  templateUrl: './hospital-stock-monitoring.component.html'
})
export class HospitalStockMonitoringComponent extends BaseModuleComponent implements OnInit {
  public tableResponse: TableResponseModel<HospitalStock>;

  constructor(translateService: TranslateService) {
    super('hospital-stock', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'item.code',
          header: 'table.column.code',
          plugins: {
            name: 'hyperlink',
            className: 'white-space-normal'
          }
        },
        {
          field: 'item.name',
          header: 'table.column.namaGenerik'
        },
        {
          field: 'item.dose',
          header: 'table.column.dose'
        },
        {
          field: 'item.uom.name',
          header: 'table.column.uom'
        },
        {
          field: 'stock',
          header: 'table.column.stock'
        }
      ]
    );
  }

  public doClick(hospitalStock: HospitalStock): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('hospitalStock', hospitalStock);
    this.global.routerParams.set('urlBackOutside', '/pages/hospital-stock');
    this.global.routerParams.set('todo', 'view');
    this.router.navigate(['/pages/hospital-stock/detail']);
  }

}
