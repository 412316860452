<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="performance-procurement.form.detailProcurement"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/performance-procurement/detail-procurement"
      >
        <ng-template #headerButtons>
          <app-button
            mode="BYPASS"
            (onClick)="doExport()"
            [disabled]="isLoading"
            ><em
              class="fas"
              [ngClass]="
                !isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'
              "
            >
            </em
            >{{ 'app.button.export' | translate }}
          </app-button>
          <app-button (onClick)="doAdd()"
            ><em class="fas fa-plus"></em
            >{{ 'app.button.add' | translate }}</app-button
          >
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <ng-container
            *ngIf="
              (rowData[1] === null ||
                (rowData[1] &&
                  rowData[1].workflowPrcs &&
                  rowData[1].workflowPrcs.workflowStatus.code.includes(
                    'REVISI'
                  ))) &&
                ((rowData[0].procurement.procurementSubmission &&
                  global.userSession.user.organization.id ===
                    rowData[0].procurement.procurementSubmission.organization
                      .id) ||
                  global.userSession.user.organization.id ===
                    rowData[0].procurement.organization.id);
              then editButton;
              else detailButton
            "
          ></ng-container>
          <ng-template #editButton>
            <app-button
              size="TN"
              (onClick)="doEdit(rowData[0])"
              title="{{ 'app.tooltip.edit' | translate }}"
            >
              <span class="fas fa-pencil-alt"></span>
            </app-button>
          </ng-template>
          <ng-template #detailButton>
            <app-button
              mode="BYPASS"
              size="TN"
              (onClick)="doView(rowData[0])"
              title="{{ 'app.tooltip.view' | translate }}"
            >
              <span class="fas fa-search"></span>
            </app-button>
          </ng-template>
        </ng-template>
      </app-table>

      <div class="button-group button-group-center">
        <app-button mode="BYPASS" color="SECONDARY" (onClick)="doBack()">{{
          'app.button.back' | translate
        }}</app-button>
      </div>
    </app-card>
  </div>
</div>
