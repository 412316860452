import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { PopupVendorView } from '../../view/entity/bean/popup-vendor-view';
import { TableResponseModel } from '../table/model/table-response-model';

@Component({
  selector: 'app-vendor-information',
  templateUrl: './app-vendor-information.component.html'
})
export class AppVendorInformationComponent extends BaseComponentComponent {
  @Input() public vendorId: number;
  public tableResponse: TableResponseModel<PopupVendorView>;

  constructor() {
    super('app-vendor-information');
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse() {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.vendorName'
      },
      {
        field: 'email',
        header: 'table.column.email'
      },
      {
        field: 'address',
        header: 'table.column.address'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      },
      {
        field: 'picName',
        header: 'table.column.picVendor'
      }
    ]);

    this.tableResponse.setCustomData({ id: this.vendorId });
  }
}
