import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppPickListComponent } from './app-pick-list.component';
@NgModule({
  imports: [VendorModule, DragDropModule, PipesModule, AppButtonModule],
  declarations: [AppPickListComponent],
  exports: [AppPickListComponent]
})
export class AppPickListModule {}
