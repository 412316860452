<div class="row main-row app-accordion-row">
  <div class="col-12">
    <app-card
      header="vendor-payment-request.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-accordion
        [expandAll]="false"
        [isShowControls]="false"
        *ngIf="!formLoading"
      >
        <app-accordion-item
          header="vendor-payment-request.accordion.paymentRequest"
          [active]="true"
          [isExpanded]="true"
        >
          <div class="col-12 text-right mb-3">
            <app-button
              *ngIf="todo === 'add'"
              color="PRIMARY"
              (onClick)="doChangeBill()"
            >
              {{ 'vendor-payment-request.button.changeBill' | translate }}
            </app-button>
          </div>
          <form class="form-horizontal" [formGroup]="formGroup" novalidate>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'vendor-payment-request.form.title' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{ paymentBilling.title }}
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'vendor-payment-request.form.orderNumber' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{ paymentBilling.code }}
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'vendor-payment-request.form.stage' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{ stageOfWork?.name }}
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'vendor-payment-request.form.terms' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{ paymentBilling.sowPaymentTerm.paymentTerm?.name }}
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'vendor-payment-request.form.amount' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{
                  global.converterService.convertMoney(paymentBilling.amount)
                }}
                {{ 'IDR' }}
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'vendor-payment-request.form.remainingAmount' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{
                  global.converterService.convertMoney(
                    paymentBilling.remainingAmount
                  )
                }}
                {{ 'IDR' }}
              </div>
            </div>
            <br />
            <h5 class="separator">
              {{
                'vendor-payment-request.separator.paymentRequestDataForm'
                  | translate
              }}
            </h5>
            <h6 class="separator">
              {{ 'vendor-payment-request.separator.invoice' | translate
              }}<span
                *ngIf="!formGroup.isView"
                class="control-label text-danger"
              >
                *</span
              >
            </h6>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'vendor-payment-request.form.invoiceNumber' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  autofocus="true"
                  formControlName="number"
                  maxLength="32"
                >
                </app-text-field>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'vendor-payment-request.form.invoiceDate' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-date-picker
                  formControlName="invoiceDate"
                ></app-date-picker>
              </div>
            </div>

            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'vendor-payment-request.form.requestedBill' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{
                  global.converterService.convertMoney(paymentBilling.amount)
                }}
                {{ 'IDR' }}
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label" *ngIf="todo !== 'view'">{{
                'vendor-payment-request.form.uploadInvoice' | translate
              }}</label>
              <label class="col-sm-3 control-label" *ngIf="todo === 'view'">{{
                'vendor-payment-request.form.invoice' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-upload
                  formControlName="invoiceFile"
                  [fileUploader]="fileUploader"
                  tooltip="vendor-payment-request.tooltip.uploadInvoice"
                ></app-upload>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'vendor-payment-request.form.description' | translate
              }}</label>
              <div
                class="text-left"
                [ngClass]="formGroup.isView ? 'col-sm-6' : 'col-sm-9'"
              >
                <app-text-area formControlName="description"></app-text-area>
              </div>
            </div>

            <br />
            <h6 class="separator">
              {{ 'vendor-payment-request.separator.billingTerms' | translate
              }}<span
                *ngIf="!formGroup.isView"
                class="control-label text-danger"
              >
                *</span
              >
            </h6>
            <ng-container
              formArrayName="sowPaymentTermBillingList"
              *ngIf="sowPaymentTermBillingList !== []"
            >
              <div class="form-group row text-sm-right">
                <div
                  class="card border-0"
                  [ngStyle]="{
                    margin: '15px',
                    width: '-webkit-fill-available'
                  }"
                >
                  <div class="card-body">
                    <div
                      class="table-responsive custom-table float-none w-100"
                      [ngStyle]="{ margin: '0 auto' }"
                    >
                      <table
                        class="table table-striped box-shadow"
                        aria-describedby="Table"
                      >
                        <thead>
                          <tr class="text-left">
                            <th scope="col">
                              <p>No</p>
                            </th>
                            <th class="text-left" scope="col">{{
                              'vendor-payment-request.table.column.billingTerm'
                                | translate
                            }}</th>
                            <th scope="col">{{
                              'vendor-payment-request.table.column.documentNumber'
                                | translate
                            }}</th>
                            <th scope="col">{{
                              'vendor-payment-request.table.column.documentDate'
                                | translate
                            }}</th>
                            <th scope="col" *ngIf="todo !== 'view'">{{
                              'vendor-payment-request.table.column.uploadFile'
                                | translate
                            }}</th>
                            <th scope="col" *ngIf="todo === 'view'">{{
                              'vendor-payment-request.table.column.file'
                                | translate
                            }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let billingTerm of formGroup.controls
                                .sowPaymentTermBillingList.controls;
                              let j = index
                            "
                            [formGroupName]="j"
                          >
                            <td
                              class="text-left"
                              [ngStyle]="{
                                'max-width': '70px',
                                'white-space': 'normal',
                                'min-width': '50px'
                              }"
                            >
                              {{ j + 1 }}
                            </td>
                            <td
                              class="text-left"
                              [ngStyle]="{
                                'max-width': '200px',
                                'white-space': 'normal',
                                'min-width': '160px'
                              }"
                            >
                              {{ billingTerm.controls.billingTermName.value }}
                            </td>
                            <td class="text-left">
                              <app-text-field
                                size="XL"
                                formControlName="billingTermCode"
                                maxLength="32"
                              >
                              </app-text-field>
                            </td>
                            <td
                              class="text-left"
                              [ngStyle]="{ 'min-width': '140px' }"
                            >
                              <app-date-picker
                                size="XL"
                                formControlName="billingTermDate"
                              >
                              </app-date-picker>
                            </td>
                            <td
                              class="text-left"
                              [ngStyle]="{
                                'min-width': '180px',
                                'max-width': !formGroup.isView ? null : '300px'
                              }"
                            >
                              <app-upload
                                size="XL"
                                formControlName="file"
                                [fileUploader]="fileUploaderBillingTermList[j]"
                              >
                              </app-upload>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </form>

          <br />
          <div *ngIf="todo !== 'add'">
            <h6 class="separator">
              {{
                'vendor-payment-request.separator.historyReviewPayment'
                  | translate
              }}
            </h6>
            <app-table
              #selectorHistoryReviewPayment
              [model]="tableResponseHistoryPaymentReview"
            >
            </app-table>
          </div>
        </app-accordion-item>

        <app-accordion-item
          header="internal-payment-request.accordion.deliverableItems"
          *ngIf="isDeliverableItem"
        >
          <app-deliverable-item
            *ngIf="!formLoading && todo === 'add' && isDeliverableItem"
            [paymentBillingId]="paymentBilling.id"
          >
          </app-deliverable-item>
          <app-deliverable-item
            *ngIf="
              !formLoading &&
              (todo === 'edit' || todo === 'view') &&
              isDeliverableItem
            "
            [paymentId]="paymentId"
          >
          </app-deliverable-item>
        </app-accordion-item>
        <app-accordion-item
          header="vendor-payment-request.accordion.vendorPaymentInformation"
          [isExpanded]="isExpandedVendorInfo"
        >
          <app-vendor-payment-information
            *ngIf="!paymentId || (payment && !payment.vendorBank)"
            [todo]="todo"
            [vendorId]="paymentBilling.vendor.id"
            [isSubmit]="formGroup.get('isSubmit').value"
            (onChange)="onChangeVendor($event)"
          >
          </app-vendor-payment-information>

          <app-vendor-payment-information
            *ngIf="!formSaving && paymentId && payment.vendorBank"
            [todo]="todo"
            [paymentId]="paymentId"
            [isSubmit]="formGroup.get('isSubmit').value"
          >
          </app-vendor-payment-information>
        </app-accordion-item>
      </app-accordion>
      <div
        class="floating-button mt-5"
        *ngIf="!vendorPaymentRequestEditAddResponse.isUserApproval"
      >
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button
                mode="BYPASS"
                color="SECONDARY"
                (onClick)="doBack()"
                class="mr-1"
                [disabled]="formSaving"
                >{{ 'app.button.back' | translate }}
              </app-button>
              <app-button
                color="PRIMARY"
                (onClick)="doSave()"
                [disabled]="formSaving"
                *ngIf="!formGroup.isView"
                >{{ 'app.button.save' | translate }}</app-button
              >
              <app-button
                color="PRIMARY"
                (onClick)="doSubmit()"
                [disabled]="formSaving"
                *ngIf="!formGroup.isView"
                >{{ 'app.button.submit' | translate }}</app-button
              >
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
<app-approval-prcs-x
  *ngIf="vendorPaymentRequestEditAddResponse.isUserApproval && !formLoading"
  [workflowModelPrcs]="workflowModelPrcs"
  [approvalModelPrcsId]="approvalModelPrcsId"
>
</app-approval-prcs-x>
