/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */

import { Injector } from '@angular/core';
export class ServiceLocator {
    public static injector: Injector;
}
