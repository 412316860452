import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from '../../../core/global/global';
@Component({
  selector: 'app-lost-connection-page',
  templateUrl: './lost-connection-page.component.html',
  styleUrls: ['./lost-connection-page.component.scss']
})
export class LostConnectionPageComponent {
  constructor(public global: Global, private router: Router) {}

  doBack(): void {
    const lastActiveRouterlistIndex =
      this.global.lastActiveRouterList.length - 1;
    this.router.navigate([
      this.global.lastActiveRouterList[lastActiveRouterlistIndex]
    ]);
  }
}
