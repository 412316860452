import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { EquipmentType } from '../../core/bean/equipment-type';
import { Uom } from '../../core/bean/uom';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { OptionListModel } from './../../core/model/option-list-model';
import { EquipmentTypeResponse } from './equipment-type-response';

@Component({
  templateUrl: './equipment-type-edit-add.component.html'
})
export class EquipmentTypeEditAddComponent extends BaseModuleComponent {
  public equipmentTypeId: number;
  public equipmentTypeResponse: EquipmentTypeResponse = new EquipmentTypeResponse();
  public equipmentType: EquipmentType = new EquipmentType();
  public uomOptionList: OptionListModel<Uom> = new OptionListModel(true);
  public uomArrayList: any[] = new Array();
  public uomUpdateList: Uom[] = [];
  constructor(translateService: TranslateService) {
    super('equipment-type', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.equipmentTypeId = this.global.routerParams.get('equipmentTypeId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      uom: [null, Validators.required()],
      description: [null, Validators.maxLength(512)]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<EquipmentTypeResponse>(
        '/equipment-type/add-edit',
        new RouteRequestModel(this.todo, this.equipmentTypeId)
      )
      .subscribe((equipmentTypeResponse: EquipmentTypeResponse) => {
        this.uomOptionList.setRequestValues(equipmentTypeResponse.uomList);
        this.equipmentTypeResponse = equipmentTypeResponse;
        if (this.equipmentTypeResponse.equipmentType != null) {
          this.equipmentType = equipmentTypeResponse.equipmentType;
          this.equipmentType.uomCode.split(',').forEach(code => {
            this.equipmentTypeResponse.uomList.forEach(uom => {
              if (code === uom.code) {
                this.uomUpdateList.push(uom);
              }
            });
          });

          this.formGroup.patchValue({
            id: this.equipmentType.id,
            code: this.equipmentType.code,
            name: this.equipmentType.name,
            uom: this.uomUpdateList,
            description: this.equipmentType.description
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const equipmentType: EquipmentType = this.global.copyFormAttributeToModel(
              new EquipmentType(),
              this.formGroup
            );

            //  mendapatkan uom by code sebelum disimpan dalam bentuk string
            this.formGroup.get('uom').value.forEach(uom => {
              this.uomArrayList.push(uom.code);
            });
            equipmentType.uomCode = this.uomArrayList.toString();

            const url: string =
              this.todo === 'edit'
                ? '/equipment-type/update'
                : '/equipment-type/insert';
            this.httpClientService
              .post<Response<EquipmentType>>(url, equipmentType)
              .subscribe((response: Response<EquipmentType>) => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/equipment-type/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/equipment-type/']);
  }
}
