import { BaseEntity } from '../base/base-entity';
import { BillingMethod } from '../bean/billing-method';
import { DeliveryStatus } from '../bean/delivery-status';
import { PaymentTerm } from '../bean/payment-term';
import { Sow } from '../bean/sow';
import { SowPaymentTerm } from '../bean/sow-payment-term';
import { SowPaymentTermBilling } from '../bean/sow-payment-term-billing';
import { SowPaymentTermItem } from '../bean/sow-payment-term-item';

export class SowPaymentTermEntity extends BaseEntity {
  sowPaymentTermItemList: SowPaymentTermItem[] = [];
  sowPaymentTermBillingList: SowPaymentTermBilling[] = [];
  paymentTerm: PaymentTerm = new PaymentTerm();
  sow: Sow = new Sow();
  billingMethod: BillingMethod = new BillingMethod();
  deliveryStatus: DeliveryStatus = new DeliveryStatus();
  amount: number;
  remainingAmount: number;
  percentage: number;
  isAssessment: boolean;
  isFinishAssessed: boolean;
  description: string;
  deliveryPercentage: number;
  fromSowPaymentTerm: SowPaymentTerm;
  isReadyAssessed: boolean;
  isItemExist: boolean;
  isEnableRequestBill: boolean;
}
