import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RfiVendor } from 'src/app/core/bean/rfi-vendor';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { RfiDTO } from './dto/rfi.dto';
@Component({
  templateUrl: './rfi-question-detail.component.html'
})
export class RfiQuestionDetailComponent extends BaseModuleComponent {
  public rfiDTO: RfiDTO;
  public rfiVendorId: number;
  public rfiVendorNonPromiseId: number;
  public rfiVendor: RfiVendor = new RfiVendor();
  public dataReady = false;
  public isNoQuestions = false;

  constructor(translateService: TranslateService) {
    super('rfi', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormArray();
    this.setFileVendor();
    this.setStateReady();
    this.log.debug('formgroup value');
    this.log.debug(this.formGroup.value);
  }

  public setFileVendor(): void {
    this.rfiVendor = this.rfiDTO.rfiVendorList.filter((rfiVendorr => rfiVendorr.id === this.rfiVendorId))[0];
    if (!(this.parents && this.parents.length > 0)) {
      this.isNoQuestions = true;
    }
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.rfiVendorId = this.global.routerParams.get('rfiVendorId');
    this.rfiVendorNonPromiseId = this.global.routerParams.get('rfiVendorNonPromiseId');
    this.rfiDTO = this.global.routerParams.get('rfiDTO');
  }
  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      parents: this.formBuilder.array([]),
      responseRfi: this.formBuilder.array([])
    });
  }

  public isArray(answer): boolean {
    if (Array.isArray(answer)) {
      return true;
    } else {
      return false;
    }
  }

  public setFormArray(): void {
    this.rfiDTO.responseRfiList.forEach(parent => {
      if (parent.rfiVendor !== null && this.rfiVendorId === parent.rfiVendor.id) {
        const formObjectCode = parent.rfiQuestion.formObject.code;
        let answer = parent.answer && JSON.parse(parent.answer);
        if (formObjectCode === 'CHECK-BOX') {
          if (Array.isArray(answer)) {
            answer = answer.filter((a: any) => a.isChecked);
            if (answer.length === 0) {
              answer = parent.answer && JSON.parse(parent.answer);
            }
          }
        }
        this.parents.push(
          this.formBuilder.group({
            question: parent.rfiQuestion.question,
            answer: [answer],
            formObjectCode: parent.rfiQuestion.formObject.code,
            fileVendor: parent.rfiVendor.file
          })
        );
      }
      if (parent.rfiVendorNonPromise !== null && this.rfiVendorNonPromiseId === parent.rfiVendorNonPromise.id) {
        const formObjectCode = parent.rfiQuestion.formObject.code;
        let answer = parent.answer && JSON.parse(parent.answer);
        if (formObjectCode === 'CHECK-BOX') { // yg tadinya chosen trus diganti jadi checkbox gimana, kan ga ada isChecked
          if (Array.isArray(answer)) {
            answer = answer.filter((a: any) => a.isChecked);
            if (answer.length === 0) {
              answer = parent.answer && JSON.parse(parent.answer);
            }
          }
        }
        this.parents.push(
          this.formBuilder.group({
            question: parent.rfiQuestion.question,
            answer: [answer],
            formObjectCode: parent.rfiQuestion.formObject.code,
            fileVendorNonPromise: parent.rfiVendorNonPromise.file
          })
        );
      }
    });
  }

  public get parents(): FormArray {
    return this.formGroup.get('parents') as FormArray;
  }

  public get responseRfi(): FormArray {
    return this.formGroup.get('responseRfi') as FormArray;
  }

  public doBack(): void {
    this.global.routerParams.set('rfiDTO', this.rfiDTO);
    if (this.rfiDTO.rfi.rfiStatus.code === 'CLOSE') {
      this.router.navigate(['/pages/request-for-information/detail']);
    } else { /* selain CLOSE masih bisa edit rfi */
      this.router.navigate(['/pages/request-for-information/edit']);
    }
  }

  public get formArrayParents(): FormArray {
    return this.formGroup.get('parents') as FormArray;
  }
}
