<app-popup header="submit-quotation-vendor.popup-select-item.title" 
[isShowHeaderCloseButton]="false" [isLoading]="formLoading">
  <div class="col" *ngIf="!formLoading">
    <form
          class="form-horizontal popup-select-item"
          [formGroup]="formGroup"
          novalidate
          *ngIf="!formLoading">
      <app-table-xx
        [model]="tableResponse"
        [isShowCheckBox]="true">
      </app-table-xx>
    </form>
  </div>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button
        class="mr-1"
        color="LIGHT"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button (onClick)="doChoose()">{{
        'app.button.select' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
