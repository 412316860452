import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { UserRole } from '../../core/bean/user-role';
import { OptionListModel } from '../../core/model/option-list-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Validators } from '../../core/validators';
@Component({
  selector: 'app-select-role',
  templateUrl: './select-role.component.html',
  styleUrls: ['./select-role.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectRoleComponent extends BaseModuleComponent {
  public selectedUserRole: UserRole = null;
  public userRoleOptionList: OptionListModel<UserRole> = new OptionListModel(
    false,
    'role.name',
    'role.code'
  );
  constructor(
    public authenticationService: AuthenticationService,
    translateService: TranslateService
  ) {
    super('select-role', translateService);
  }

  onInit(): void {
    this.doCheckValidUserRoleList();
    this.buildFormGroup();
    this.doSetUserRoleOptionList();
  }

  public doCheckValidUserRoleList(): void {
    if (this.global.routerParams.get('validUserRoleList') === null) {
      this.router.navigate(['/portal/login']);
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      email: [this.global.routerParams.get('email')],
      password: [this.global.routerParams.get('password')],
      userRoleCode: [null, Validators.required()],
      isDontAskMeAgain: [false]
    });
  }

  public doSetUserRoleOptionList(): void {
    const validUserRoleList = this.global.routerParams.get('validUserRoleList');
    this.userRoleOptionList.setRequestValues(validUserRoleList);
  }

  public doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.setStateProcessing();
      const {
        email,
        password,
        userRoleCode,
        isDontAskMeAgain
      } = this.formGroup.value;
      this.authenticationService
        .getAuth(email, password, userRoleCode, isDontAskMeAgain)
        .subscribe(response => {
          this.formSaving = false;
          this.formGroup.enable();
          if (response.status === ResponseStatusModel.OK) {
            const authenticationResponse = response.body;
            if (authenticationResponse.user !== null) {
              this.authenticationService.logingIn(authenticationResponse);
            }
          } else {
            if (response.statusText === 'LOGIN_NO_DEFAULT_ROLE') {
              this.global.alertService.showError(
                'No Default Role!',
                '.select-role-error'
              );
            } else if (response.statusText === 'LOGIN_NO_ROLE_FOUND') {
              this.global.alertService.showError(
                'No Role Found!',
                '.select-role-error'
              );
            }
          }
          this.setStateReady();
        });
    }
  }

  public doBack(): void {
    this.router.navigate(['/portal/login']);
  }
}
