<div class="row main-row">
  <div class="col-12">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <app-accordion formArrayName="prItemList" [isLoading]="formLoading">
        <app-accordion-item
          header="{{ 'pr.accordion.itemRequest' | translate }} {{ i + 1 }}"
          *ngFor="
            let prItem of formGroup.controls.prItemList.controls;
            let i = index
          "
          [formGroupName]="i"
          [isExpanded]="i === prItemList.length - 1"
        >
          <ng-container *ngIf="!prItem.value.catalog">
            <app-fullfilled></app-fullfilled>
            <ng-template #headerRight *ngIf="!formGroup.isView">
              <em
                *ngIf="prItemList.value.length > 1"
                class="pir pi-trash-alt float-right"
                [ngStyle]="{ 'padding-right': '10px' }"
                (click)="doDeletePrItem(i)"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
              ></em>
            </ng-template>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'pr.form.itemName' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-auto-complete
                  formControlName="item"
                  stringUrl="/pr/auto-complete-item-list"
                  (onChange)="autoCompleteValueChange($event)"
                ></app-auto-complete>
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{ 'pr.form.uom' | translate }}</label>
              <div class="col-sm-9 text-left">
                {{ prItem.value.item ? prItem.value.item?.uom.name : '-' }}
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'pr.form.quantity' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                <app-count
                  *ngIf="!formGroup.isView"
                  formControlName="quantity"
                  maxLength="32"
                  [min]="1"
                  (onChange)="onChangeQuantity(i)"
                  (input)="onChangeQuantity(i)"
                  size="LG"
                ></app-count>
              </div>
            </div>
            <div class="form-group row text-sm-left" *ngIf="todo !== 'view'">
              <label class="col-sm-3">{{ 'pr.form.price' | translate }}</label>
              <div class="col-sm-9 text-left">
                <app-currency
                  formControlName="prices"
                  [optionList]="currencyOptionList"
                  (onChange)="onChangeQuantity(i)"
                ></app-currency>
              </div>
            </div>
            <div class="form-group row text-sm-left" *ngIf="!formLoading">
              <label class="col-sm-3">{{
                'pr.form.totalPrice' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{
                  prItem.value.totalPrice
                    ? global.converterService.convertMoney(
                        prItem.value.totalPrice
                      )
                    : '0'
                }}
                {{ prResponse.companyCurrency.code }}
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'pr.form.specification' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-wysiwyg
                  size="LG"
                  formControlName="specification"
                  maxLength="32"
                ></app-wysiwyg>
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'pr.form.itemImages' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-upload-images
                  formControlName="itemImages"
                  [fileUploader]="fileUploader"
                ></app-upload-images>
              </div>
            </div>
            <app-table-xx
              [model]="tableResponseBudgetList[i]"
              [isShowPagination]="false"
              [isShowSearch]="false"
              header="pr.separator.budgetItem"
              *ngIf="!formLoading"
            >
              <div style="margin-bottom: 15px">
                <ng-template #alertWrapper>
                  <div
                    class="lmb-2"
                    style="
                      margin-top: 0;
                      margin-left: 0;
                      margin-right: 0;
                      display: flex;
                      align-items: center;
                      min-height: 46px;
                      background-color: var(--white);
                    "
                  >
                    <div style="flex: 1">
                      <div
                        class="alert-info"
                        role="alert"
                        style="
                          display: flex;
                          padding: 20px;
                          border-radius: 5px;
                          margin-bottom: 10px;
                        "
                      >
                        <div
                          class="pvc pv-wallet"
                          style="width: 60px; height: 60px"
                        ></div>
                        <div style="display: grid; padding-left: 1rem">
                          <span
                            >{{ 'pr.alert.msg.quantity' | translate }}
                            {{
                              +prItem.value.quantity | resolveNumberToDecimal
                            }}
                            {{
                              prItem.value.item
                                ? prItem.value.item?.uom.name
                                : '-'
                            }}
                          </span>
                          <h2 style="color: var(--primary)">
                            {{
                              prItem.value.totalPrice
                                ? global.converterService.convertMoney(
                                    prItem.value.totalPrice
                                  )
                                : '0'
                            }}
                            {{ prResponse.companyCurrency.code }}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-budget" style="margin-bottom: 15px"> </div>
                </ng-template>
              </div>
              <ng-template #headerButtons>
                <app-button
                  *ngIf="
                    !formGroup.isView && !prResponse.isModuleGroupESMActive
                  "
                  (onClick)="doAddBudget(prItem, i)"
                  ><em class="pir pi-plus-circle"></em>
                  {{ 'app.button.add' | translate }}</app-button
                >
                <app-button
                  *ngIf="!formGroup.isView && prResponse.isModuleGroupESMActive"
                  (onClick)="doChooseBudget(prItem, i)"
                  ><em class="pir pi-plus-circle"></em>
                  {{ 'pr.button.chooseBudget' | translate }}</app-button
                >
              </ng-template>
              <ng-template #actionButtons let-rowData>
                <app-button
                  size="TN"
                  (onClick)="doDeleteBudget(rowData, i)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="pir pi-trash-alt"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>
              </ng-template>
              <ng-template #row>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>
                      {{ 'pr.table.column.budgetTotal' | translate }}
                    </b>
                  </td>
                  <td class="text-right">
                    {{
                      prItem.value.totalBudget
                        ? global.converterService.convertMoney(
                            prItem.value.totalBudget
                          )
                        : 0
                    }}
                    {{ prResponse.companyCurrency.code }}
                  </td>
                </tr>
              </ng-template>
            </app-table-xx>
          </ng-container>
        </app-accordion-item>
      </app-accordion>
      <div
        class="col-12 mt-5 text-center button-add-more-item"
        *ngIf="!formGroup.isView"
      >
        <app-button (onClick)="addPrItem()" [disabled]="!formGroup.valid"
          ><em class="pir pi-plus-circle"></em>
          {{ 'pr.button.addOtherItem' | translate }}</app-button
        >
      </div>
      <div class="button-group button-group-center mt-5">
        <app-button
          color="LIGHT"
          [outline]="true"
          (onClick)="doPurchaseRequest()"
          [disabled]="formSaving"
          >{{ 'pr.button.processPr' | translate }}
        </app-button>
        <app-button (onClick)="doCart()" [disabled]="formSaving">{{
          'pr.button.cart' | translate
        }}</app-button>
      </div>
    </form>
  </div>
</div>
