import { EventEmitter, Injectable } from '@angular/core';
import { AanwijzingOnlineMenu } from 'src/app/core/bean/aanwijzing-online-menu';
import { WebSocketService } from 'src/app/core/services/websocket/websocket.service';

@Injectable({ providedIn: 'root' })
export class ChatMenuWebsocketService extends WebSocketService<AanwijzingOnlineMenu> {
  constructor() {
    super(`chat-menu`);
  }
  public menu: EventEmitter<AanwijzingOnlineMenu> = new EventEmitter();

  protected onReceivedMessage(message: string): Promise<any> {
    return new Promise(resolve => {

    const onlineMenu: AanwijzingOnlineMenu = JSON.parse(message);
    if (onlineMenu) {
        resolve(this.setReceivedMessage(onlineMenu));
        this.menu.emit(onlineMenu);
      }
    });
  }
}
