import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { Module } from '../bean/module';
import { ReviseCondition } from '../bean/revise-condition';
import { User } from '../bean/user';
import { WorkflowPrcs } from './../bean/workflow-prcs';

export class TransactionLogEntity extends BaseEntity {
  isDelegated: boolean;
  slaRealizedName: string; /* Transient */
  statusText: string;

  workflowPrcs: WorkflowPrcs;
  module: Module;
  approvalFile: File;
  approvedByUser: User;
  processedByUser: User;
  reviseCondition: ReviseCondition;
  sequence: number;
  name: string;
  approvalTypeId: number;
  approvalSequence: number;
  approvalNote: string;
  approvalPositionText: string;
  approvalStatus: number;
  processedDate: Date;
  slaInDays: number;
  startDate: Date;
  endDate: Date;
  slaRealizedInMinutes: number;
  slaRealizedStatus: number;
  slaRealizedWorkingInDays: number;
  slaRealizedWorkingInHours: number;
  slaRealizedWorkingInMinutes: number;
}
