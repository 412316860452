export class AppTableConstant {
  public FIELD_FORMAT_SHORT_DATE: number = 0;
  public FIELD_FORMAT_SHORT_DATE_AND_TIME: number = 1;
  public FIELD_FORMAT_LONG_DATE: number = 2;
  public FIELD_FORMAT_LONG_DATE_AND_TIME: number = 3;
  public FIELD_FORMAT_CURRENCY: number = 4;
  public FIELD_FORMAT_INTEGER: number = 5;
  public FIELD_FORMAT_HTML: number = 6;
  public FIELD_FORMAT_IMG: number = 7;
  public FIELD_FORMAT_DOWNLOADABLE_FILE: number = 8;
  public FIELD_FORMAT_COLOR_STATUS: number = 9;
  public FIELD_FORMAT_RATING: number = 10;
  public FIELD_FORMAT_EDIT_COLS: number = 11;
  public FIELD_FORMAT_CHECK_BOX: number = 12;
  public FIELD_FORMAT_INPUT_NUMBER: number = 13;
  public FIELD_FORMAT_RADIO: number = 14;
}
