<app-popup
  header="pr.popup.editprItemEdit"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <div class="main-row-popup-pr-item-edit">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <div>
        <app-alert-x color="DANGER" *ngIf="validateQuantity">{{
          'pr.alert.validateQuantity' | translate
        }}</app-alert-x>
      </div>
      <div class="row" *ngIf="!formLoading">
        <div class="col-sm-4">
          <app-image-slideshow
            [fileList]="fileList"
            [parentModuleCode]="moduleCode"
          >
          </app-image-slideshow>
        </div>
        <div class="col-sm-8">
          <h5 class="separator">{{ catalogView?.merk }}</h5>
          <h4
            >{{ catalogView?.catalogRegion?.price | resolveNumberToCurrency }}
            {{ orderResponse?.companyCurrency.code }}</h4
          >
          <p></p>

          <div
            *ngIf="
              prItemEdit.catalog !== null &&
              prItemEdit.catalog.catalogType.code ===
                global.appConstant.vm.CATALOG_TYPE_CONTRACT
            "
          >
            <!-- <div>
            <app-alert-x color="WARNING" *ngIf="isQuantityInPackage">{{
              'manage-catalog.alert.isActive' | translate
            }}</app-alert-x>
          </div> -->
            <table>
              <tr>
                <td class="text-center text-secondary">
                  <em class="fas fa-store"></em>
                </td>
                <td style="width: 8px"></td>
                <td class="text-left text-secondary">
                  {{ catalogView?.produsenName }}
                </td>
              </tr>
              <tr>
                <td class="text-center text-secondary">
                  <em class="fas fa-map-marker-alt"></em>
                </td>
                <td></td>
                <td class="text-left text-secondary">
                  {{ catalogView?.vendorCityRegionName }}</td
                >
              </tr>
              <tr>
                <td class="text-center text-warning">
                  <em class="pic pi-star"></em>
                </td>
                <td></td>
                <td class="text-left text-secondary">
                  {{ 'pr.form.vendorRating' | translate }}&nbsp;{{
                    catalogView.rating
                  }}&nbsp;|&nbsp;
                  <app-badge-catalog
                    style="position: absolute"
                    color="SUCCESS"
                    >{{
                      catalogView?.catalogTypeTranslationKey | translate
                    }}</app-badge-catalog
                  >
                </td>
              </tr>
            </table>
          </div>
          <br />

          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'pr.form.itemCode' | translate }}</div
            >
            <div class="tab-content-item-value">
              {{ prItemEdit.item?.code || '-' }}
            </div>
          </div>
          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'pr.form.uom' | translate }}</div
            >
            <div class="tab-content-item-value">
              {{ prItemEdit.item?.uom?.name || '-' }}
            </div>
          </div>
          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'pr.form.quantity' | translate }}</div
            >
            <div class="tab-content-item-value">
              <app-count
                *ngIf="!formGroup.isView"
                formControlName="quantity"
                type="decimal"
                maxLength="32"
                (onChange)="onChangeQuantity()"
                (input)="onChangeQuantity()"
                size="LG"
                [min]="1"
                appInteger
              ></app-count>
            </div>
          </div>
          <div class="tab-content-item" *ngIf="isShowDiscount">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'pr.form.discount' | translate }}</div
            >
            <div
              class="tab-content-item-value"
              style="color: #dc3d31; text-decoration-line: line-through"
            >
              {{ formGroup.get('discount').value | resolveNumberToCurrency }}
              {{ orderResponse.companyCurrency.code }}
            </div>
          </div>
          <div class="tab-content-item" *ngIf="!formLoading">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'pr.form.subTotal' | translate }}</div
            >
            <div class="tab-content-item-value">
              {{
                formGroup.value.totalPrice
                  ? global.converterService.convertMoney(
                      formGroup.value.totalPrice
                    )
                  : '0'
              }}
              {{ orderResponse.companyCurrency.code }}
            </div>
          </div>
          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'pr.form.type' | translate }}</div
            >
            <div class="tab-content-item-value">
              <app-badge
                style="position: absolute"
                [color]="
                  prItemEdit.item?.itemType?.code ===
                  global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                    ? 'GOOD'
                    : 'SERVICE'
                "
              >
                {{ prItemEdit.item?.itemType?.name || '-' }}
              </app-badge>
            </div>
          </div>
          <div
            class="tab-content-item"
            *ngIf="
              prItemEdit.catalog &&
              prItemEdit.catalog.catalogType.code !==
                global.appConstant.vm.CATALOG_TYPE_CONTRACT
            "
          >
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'pr.form.catalog' | translate }}</div
            >
            <div
              class="tab-content-item-value"
              *ngIf="prItemEdit.catalog !== null"
            >
              <app-badge-catalog
                style="position: absolute"
                [color]="
                  prItemEdit.catalog?.catalogType.code ===
                  global.appConstant.vm.CATALOG_TYPE_VENDOR
                    ? 'INFO'
                    : 'SUCCESS'
                "
              >
                {{
                  prItemEdit.catalog.catalogType.translationKey.module.code.toLowerCase() +
                    '.' +
                    prItemEdit.catalog.catalogType.translationKey.key
                    | translate
                }}
              </app-badge-catalog>
            </div>
            <div
              class="tab-content-item-value"
              *ngIf="prItemEdit.catalog === null"
            >
              <app-badge-catalog style="position: absolute" color="WARNING">
                {{ 'pr.form.itemCatalog' | translate }}
              </app-badge-catalog>
            </div>
          </div>
          <ng-container
            *ngIf="
              prItemEdit.catalog !== null &&
              prItemEdit.catalog.catalogType.code ===
                global.appConstant.vm.CATALOG_TYPE_CONTRACT
            "
          >
            <br />
            <label class="control-label">{{
              'pr.form.itemContractDetail' | translate
            }}</label>
            <table>
              <!--<tr>
                <td class="text-left text-secondary"
                  >{{ 'pr.form.contractNumber' | translate }}<p></p
                ></td>
                <td></td>
                <td class="text-left text-secondary">
                  : {{ catalogContract?.contract?.code }} <p></p
                ></td>
              </tr>
              <tr>
                <td class="text-left text-secondary"
                  >{{ 'pr.form.contractPeriode' | translate }}<p></p
                ></td>
                <td></td>
                <td class="text-left text-secondary">
                  : {{ 'pr.form.from' | translate }}
                  {{
                    catalogContract?.contract?.startDate
                      | date: global.config.parameterModel.dateFormatShort
                  }}
                  {{ 'pr.form.until' | translate }}
                  {{
                    catalogContract?.contract?.endDate
                      | date: global.config.parameterModel.dateFormatShort
                  }}
                  <p></p>
                </td>
              </tr>-->
              <tr>
                <td class="text-left text-secondary"
                  >{{ 'pr.form.agreementQuantity' | translate }} <p></p>
                </td>
                <td></td>
                <td class="text-left text-secondary">
                  :
                  {{ catalogContract?.quantity | resolveNumberToDecimal
                  }}<p></p>
                </td>
              </tr>
              <tr>
                <td class="text-left text-secondary"
                  >{{
                    'app-catalog-detail-info.form.quantityInPackage'
                      | translate
                  }}<p></p
                ></td>
                <td></td>
                <td class="text-left text-secondary">
                  :
                  {{
                    catalogView.catalogRegion.quantityInPackage
                      | resolveNumberToDecimal
                  }}<p></p>
                </td>
              </tr>
            </table>
            <br />

            <ng-container *ngIf="catalogContract?.isBulkPrice">
              <div class="form-group row"
                ><div class="col-sm-1 pvc pv-bulk"></div>
                <div class="col" style="padding-top: 2%">
                  <strong>
                    {{ 'pr.form.bulkPriceAvailable' | translate }}</strong
                  >
                </div></div
              >
              <div>
                <span class="text-secondary">
                  {{ 'pr.form.startFrom' | translate }}
                  {{
                    catalogBulkPriceList[0]?.discountedPrice
                      | resolveNumberToCurrency
                  }}
                  IDR
                  {{ 'pr.form.forMinimumOrder' | translate }}
                  {{
                    catalogBulkPriceList[0]?.minimumOrderTotal
                      | resolveNumberToDecimal
                  }}
                  pcs
                  <app-tooltip
                    position="BOTTOM"
                    style="
                      position: absolute;
                      margin-left: 1%;
                      margin-top: 0.3%;
                    "
                  >
                    <em class="pir pi-chevron-down"></em>
                    <ng-template #tooltipContent>
                      <div
                        *ngFor="let data of catalogBulkPriceList; let i = index"
                        style="min-width: max-content"
                        [ngStyle]="
                          i + 1 !== catalogBulkPriceList.length
                            ? {
                                'border-bottom': '1px solid var(--gray-400)',
                                padding: '2% 2px 2% 2px'
                              }
                            : { padding: '2% 2px 0 2px' }
                        "
                      >
                        <div class="text-dark">
                          <strong
                            >{{
                              data.discountedPrice | resolveNumberToCurrency
                            }}
                            {{ catalogView.currencyCode }}</strong
                          >
                        </div>

                        <div style="font-size: 12px" class="text-secondary">
                          {{ 'pr.form.disc' | translate }}
                          {{ data.discount | resolveNumberToDecimal }}%
                          {{ 'pr.form.forMinimumOrder' | translate }}
                          {{ data.minimumOrderTotal | resolveNumberToDecimal }}
                          pcs
                        </div>
                      </div>
                    </ng-template>
                  </app-tooltip>
                </span>
              </div>
            </ng-container>

            <ng-container *ngIf="!formGroup.isView">
              <div class="form-group row mt-4"
                ><div class="col-sm-1 pvc pv-file-contract"></div>
                <div class="col" style="padding-top: 2%">
                  <strong
                    ><a
                      style="text-decoration: underline"
                      class="text-primary"
                      (click)="doViewItemContract()"
                      >{{ 'pr.form.viewItemContractList' | translate }}</a
                    >
                  </strong>
                </div></div
              >
            </ng-container>
          </ng-container>

          <br />
          <label
            ><b>{{ 'pr.form.specification' | translate }}</b></label
          >
          <div *ngIf="prItemEdit.catalog !== null">
            <div class="tab-content-item">
              <div
                class="tab-content-item-label"
                [ngStyle]="{ 'min-width': '175px' }"
                >{{ 'pr.form.weight' | translate }}</div
              >
              <div class="tab-content-item-value">
                {{ prItemEdit.catalog?.weight || '-' }}
              </div>
            </div>
            <div
              class="tab-content-item"
              *ngFor="let data of catalogSpecificationList; let i = index"
            >
              <div class="tab-content-item-label"
                >{{
                  data.catalogAttribute.translationKey.module.code.toLowerCase() +
                    '.' +
                    data.catalogAttribute.translationKey.key | translate
                }}
              </div>
              <!-- WYSIWYG-->
              <div
                *ngIf="data.catalogAttribute.formObject.code === 'WYSIWYG'"
                [innerHTML]="doParseValue(data)"
                [ngStyle]="{
                  'white-space': 'normal',
                  'max-width': '600px',
                  'word-break': 'break-word'
                }"
              ></div>
              <!-- DATE-PICKER -->
              <div
                *ngIf="data.catalogAttribute.formObject.code === 'DATE-PICKER'"
              >
                {{
                  doConvertToDate(data.value)
                    | date : global.config.parameterModel.dateFormatShort
                }}</div
              >
              <!-- TOGGLE-SWITCH -->
              <div
                *ngIf="
                  data.catalogAttribute.formObject.code === 'TOGGLE-SWITCH'
                "
              >
                {{
                  (data.value === 'true'
                    ? 'view-catalog.yes'
                    : 'view-catalog.no'
                  ) | translate
                }}</div
              >
              <!-- ELSE -->
              <div
                *ngIf="
                  data.catalogAttribute.formObject.code !== 'WYSIWYG' &&
                  data.catalogAttribute.formObject.code !== 'DATE-PICKER' &&
                  data.catalogAttribute.formObject.code !== 'TOGGLE-SWITCH'
                "
                class="tab-content-item-value"
                [ngStyle]="{
                  'white-space': 'normal',
                  'max-width': '600px',
                  'word-break': 'break-word'
                }"
                >{{ doParseValue(data) }}</div
              >
            </div>
          </div>
          <div *ngIf="prItemEdit.catalog === null" class="tab-content-item">
            <app-wysiwyg
              size="LG"
              formControlName="specification"
              maxLength="32"
            ></app-wysiwyg>
          </div>

          <br />
          <div *ngIf="prItemEdit.catalog !== null">
            <label
              ><b>{{ 'pr.form.description' | translate }}</b></label
            >
            <div
              *ngIf="!formLoading"
              readMore
              [ngStyle]="{
                'white-space': 'normal',
                'max-width': '600px',
                'word-break': 'break-word'
              }"
              [innerHTML]="prItemEdit.catalog?.description || '-'"
            ></div>
          </div>
        </div>
      </div>
    </form>
    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          mode="BYPASS"
          >{{ 'app.button.close' | translate }}</app-button
        >

        <app-button color="PRIMARY" (onClick)="doSave()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </div>
</app-popup>
