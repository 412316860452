import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { User } from '../../../bean/user';
import { UserView } from '../../../view/entity/bean/user-view';
import { TableResponseModel } from '../../app-table/model/table-response-model';
@Component({
  templateUrl: './app-popup-user.component.html'
})
export class AppPopupUserComponent extends BaseComponentComponent {
  @Input() userIdList: number[];
  @Input() roleCodeExcludeList: string[];
  @Input() roleCodeIncludeList: string[];
  @Input() organizationId: number;
  @Input() hierarchyType: number;
  @Output() onChange: EventEmitter<User> = new EventEmitter();
  public userCustomData: any;

  public tableResponse: TableResponseModel<UserView>;

  constructor() {
    super('app-popup-user');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setUserCustomData();
    this.buildTableResponse();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      userIdList: [null],
      roleCodeExcludeList: [null],
      roleCodeIncludeList: [null],
      organizationId: [null],
      hierarchyType: [null]
    });
  }

  public setUserCustomData(): void {
    this.formGroup.patchValue({
      userIdList: this.userIdList,
      roleCodeExcludeList: this.roleCodeExcludeList,
      roleCodeIncludeList: this.roleCodeIncludeList,
      organizationId: this.organizationId,
      hierarchyType: this.hierarchyType
    });
    this.userCustomData = JSON.stringify(this.formGroup.value);
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      { field: 'email', header: 'table.column.email' },
      {
        field: 'roleText',
        header: 'table.column.role'
      }
    ]);
  }

  public doChoose(userView: UserView): void {
    this.httpClientService
      .get<User>('/user/get-user/' + userView.id)
      .subscribe(user => {
        this.onChange.emit(user);
      });
  }
}
