import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TabModel } from '../../core/model/tab/tab-model';
import { TabResponseModel } from '../../core/model/tab/tab-response-model';
@Component({
    templateUrl: './work-plan-realize.component.html'
})
export class WorkPlanRealizeComponent extends BaseModuleComponent {
    public tabList: Array<any> = new Array();
    public tabResponse: TabResponseModel;
    constructor(translate: TranslateService) {
        super('work-plan-realize', translate);
    }

    public onInit(): void {
        this.setTabList();
        this.buildTabResponse();
        this.doSetEnabledAndDisabledTab(1);
        this.doSetCurrentTabInRouterParams();
    }

    public setTabList(): void {
        this.tabList = [
            { code: 'WORK-PLAN', name: 'work-plan.title', routeUrl: 'work-plan' },
            { code: 'PURCHASE-REQUEST', name: 'work-plan-purchase-request.title', routeUrl: 'purchase-request' },
            { code: 'PROCUREMENT', name: 'work-plan-realize-procurement.title', routeUrl: 'procurement' },
            { code: 'PAYMENT', name: 'work-plan-payment.title', routeUrl: 'payment' },
            { code: 'CONTRACT', name: 'work-plan-contract.title', routeUrl: 'contract' },
            { code: 'DELIVERY-RECEIVED', name: 'Delivery Received', routeUrl: 'delivery-received' },
            { code: 'PURCHASE-ORDER', name: 'Purchase Order', routeUrl: 'purchase-order' }
          ];
    }

    public buildTabResponse(): void {
        this.tabResponse = TabResponseModel.create(this.tabList, '/pages/work-plan-realize');
    }

    public doSetCurrentTabInRouterParams(): void {
        this.global.routerParams.set('tabModel', this.tabResponse.currentTab);
    }

    public doSetEnabledAndDisabledTab(tabPosition: number): void {
        this.tabResponse.tabList.forEach((tab: TabModel, index: number) =>  index <= tabPosition ? tab.enable() : tab.disable());
    }

    public doOnChangeTab(tab: TabModel): void {
        this.tabResponse.setCurrentTab(tab);
        this.doSetEnabledAndDisabledTab(tab.position);
        this.doSetCurrentTabInRouterParams();
        this.router.navigate([tab.routeUrl]);
    }
}
