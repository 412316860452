<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="view-catalog.breadcrumb.comparison">
      <div
        class="table-responsive custom-table-comparison float-none"
        [ngStyle]="{ margin: '0 auto' }"
      >
        <table
          class="table table-striped box-shadow"
          [ngStyle]="{ 'table-layout': 'fixed' }"
          aria-describedby="Table"
        >
          <tr>
            <td></td>
            <td
              class="catalog-item"
              [ngStyle]="{ 'max-height': '200px' }"
              *ngFor="let catalogBeans of catalogBeansList; let k = index"
            >
              <div class="d-flex justify-content-end">
                <em
                  class="fas fa-times text-danger cursor-pointer"
                  (click)="doDelete(k)"
                  title="{{ 'app.button.delete' | translate }}"
                  (keyup)="onKeyUp($event)"
                  (keydown)="onKeyDown($event)"
                ></em>
              </div>
              <h5>{{ catalogBeans.catalog.name }}</h5>
              <div class="catalog-item-image">
                <img
                  [attr.src]="
                    global.config.BACKEND_ADDRESS +
                      '/view-catalog/file/view/' +
                      catalogBeans.catalogImageList[0].file?.uploadedFileName +
                      '/' + global.appConstant.fileType.IMG_ITEM_CATALOG
                      | resolveImgSrc
                      | async
                  "
                  [attr.alt]="catalogBeans.catalog.name"
                  width="auto"
                  height="auto"
                />
              </div>
              <h5 [ngStyle]="{ 'margin-bottom': '0.44rem' }">{{
                catalogBeans.catalog.currency.code +
                  ' ' +
                  (catalogBeans.catalog.price | currency: '':'')
              }}</h5>
              <p
                >{{ 'view-catalog.stockAvailable' | translate }}:
                {{ catalogBeans.catalogInventoryList[0].stock | number }}</p
              >
              <p>{{
                catalogBeans.catalog.vendor.vendorEntity.name +
                  ' ' +
                  catalogBeans.catalog.vendor.name
              }}</p>
              <p>{{
                catalogBeans.catalogInventoryList[0].cityRegion?.name ||
                  catalogBeans.catalogInventoryList[0].region.name
              }}</p>
            </td>
          </tr>
          <tr
            class="text-break"
            *ngFor="let catalogAttribute of catalogAttributeList; let i = index"
          >
            <th scope="col"
              >{{
                'dynamic-attribute.' + catalogAttribute.translationKey.key
                  | translate
              }}
            </th>
            <td *ngFor="let catalogBeans of catalogBeansList; let j = index">
              {{
                doGetSpecification(
                  catalogAttribute,
                  catalogBeans.catalogSpesificationList
                ) || '-'
              }}
            </td>
          </tr>
        </table>
      </div>
      <div class="button-group button-group-center mt-5">
        <app-button mode="BYPASS" color="SECONDARY" (onClick)="doBack()">
          {{ 'app.button.back' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>
