<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <form
      class="form-horizontal"
      [formGroup]="inventoryTab.formGroup"
      novalidate
    >
      <ng-container formArrayName="inventoryList">
        <ng-container
          *ngFor="
            let inventoryLists of formArrayInventoryList.controls;
            let i = index
          "
          [formGroup]="inventoryLists"
        >
          <app-card>
            <div
              class="d-flex justify-content-end"
              *ngIf="!inventoryTab.formGroup.isView"
            >
              <em
                class="fas fa-times text-danger cursor-pointer"
                (click)="doDeleteInventory(i)"
                title="{{ 'app.button.delete' | translate }}"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
              ></em>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!inventoryTab.formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'manage-catalog.form.location' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="AUTOCOMPLETE"
                  formControlName="vendorAddress"
                  [optionList]="vendorAddressOptionList"
                >
                </app-dropdown-select>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
            >
              <label class="col-sm-3 control-label">{{
                'manage-catalog.form.qtyStock' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  formControlName="stock"
                  type="integer"
                  maxLength="11"
                >
                </app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'manage-catalog.form.supplyAbility' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  formControlName="supplyAbility"
                  type="integer"
                  maxLength="11"
                ></app-text-field>               
              </div>
            </div>
          </app-card>
        </ng-container>
      </ng-container>

      <div
        class="text-center"
        *ngIf="inventoryList.length < 5 && !inventoryTab.formGroup.isView"
      >
        <app-button color="PRIMARY" (onClick)="addMoreInventory()"
          ><em class="fas fa-plus"></em>
          {{ 'manage-catalog.button.addMore' | translate }}</app-button
        >
      </div>

      <div
        class="text-center"
        *ngIf="inventoryList.length >= 5 && !inventoryTab.formGroup.isView"
      >
        <app-button color="PRIMARY" (onClick)="addNoMoreInventory()"
          ><em class="fas fa-plus"></em>
          {{ 'manage-catalog.button.addMore' | translate }}</app-button
        >
      </div>
    </form>
  </div>
</div>
