<div class="main-row">
  <div class="row">
    <div class="col-lg-12 mb-4">
      <app-card header="workflow-condition.title.main"
        detail="app.action.{{ todo }}" [isLoading]="formLoading"
        [isSaving]="formSaving">
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'workflow-condition.form.moduleGroup' | translate }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box autofocus="true" formControlName="moduleGroup"
                [optionList]="moduleGroupOptionList"
                (onChange)="onChangeModuleGroup()"></app-combo-box>
            </div>
          </div>
          <br />
        </form>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'workflow-condition.form.objectCondition' | translate }}</label>
        </div>
        <div class="form-group row text-sm-right">
          <div class="col-sm-7">
            <div
              *ngFor="let workflowConditionDetail of workflowConditionDetailList; let i = index"
              class="form-group row text-sm-right">
              <label class="col-sm-4 control-label">{{
                workflowConditionDetail.condition.name }}</label>
              <div class="col-sm-6 text-left">
                <div *ngIf="workflowConditionDetail.condition.type === 'LIST'">
                  <select
                    (change)="onChooseConditionView(workflowConditionDetail)"
                    [(ngModel)]="workflowConditionDetail.objectId"
                    class="form-control" aria-label="Choose Condition View"
                    required>
                    <option value=null>{{ 'app.chooseData' | translate }}
                    </option>
                    <option hidden [selected]="object"></option>
                    <option
                      *ngFor="let object of workflowConditionDetail.listOfView"
                      [ngValue]="object.id">
                      {{ object.name }}
                    </option>
                  </select>
                </div>
                <div *ngIf="workflowConditionDetail.condition.type === 'TREE'">
                  <input
                    (click)="doChooseTreeObject($event, workflowConditionDetail)"
                    class="form-control" type="text"
                    [(ngModel)]="workflowConditionDetail.name"
                    (keyup)="onKeyUp($event)" (keydown)="onKeyDown($event)"
                    aria-label="Choose Tree Object" />
                </div>
              </div>
              <app-button color="DANGER" size="SM"
                (onClick)="doDeleteCondition(i, workflowConditionDetail)"
                title="{{ 'app.tooltip.delete' | translate }}">
                <em class="fas fa-trash"></em>
              </app-button>
            </div>
          </div>
          <div *ngIf="showConditionList " class="col-sm-5">
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'workflow-condition.button.addObject' | translate }}</label>
              <div class="col-sm-6 text-left">
                <select (change)="onChooseCondition(condition)"
                  class="form-control" [(ngModel)]="condition"
                  aria-label="Choose Condition">
                  <option value=null>{{ 'app.chooseData' | translate }}</option>
                  <option [disabled]="c.selected"
                    *ngFor="let c of conditionList" [ngValue]="c"
                    [selected]="c.selected"> <span
                      *ngIf="c.selected">&#x2713;</span> {{ c.name }} </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
          <div class="form-group row text-sm-right" *ngIf="showValidate">
            <!--todo != 'edit' && -->
            <label class="col-sm-3 control-label"></label>
            <div class="col-sm-4 text-left">
              <app-button (onClick)="validateWorkflowConditon()">
                {{ 'workflow-condition.button.validateCondition' | translate }}
              </app-button>
            </div>
          </div>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'workflow-condition.form.workflow' | translate }}</label>
            <div class="col-sm-4 text-left">
              <app-choose size="XL" formControlName="workflowName"
                (onClick)="chooseWorkflow($event)"></app-choose>
            </div>
          </div>
          <div class="button-group button-group-center mt-5">
            <app-button color="SECONDARY" (onClick)="doCancel()"
              [disabled]="formSaving">
              {{ 'app.button.back' | translate }}
            </app-button>
            <app-button (onClick)="doSave()" [disabled]="formSaving">
              {{ 'app.button.save' | translate }}
            </app-button>
          </div>
        </form>
      </app-card>

      <app-card header="workflow-condition.title.workflow">
        <app-table [model]="tableResponseWorkflowModel"></app-table>
      </app-card>

      <app-card header="workflow-condition.title.workflowAdditional">
        <app-table [model]="tableResponseWorkflowAdditional"></app-table>
      </app-card>
    </div>
  </div>
</div>