<div class="promise-loading" *ngIf="formLoading">
  <div class="loading"></div>
</div>
<form class="form-horizontal" *ngIf="!formLoading">
  <app-card
    header="app-contract-request-info.card.title"
    tips="app-contract-request-info.card.tips"
  >
    <ng-template #headerRight>
      <div class="button-group button-group-right">
        <app-button
          *ngIf="isShowPrintBtn"
          [outline]="true"
          (onClick)="doPrint()"
        >
          {{ 'app-contract-request-info.printReport' | translate }}
        </app-button>
        <app-button *ngIf="isShowDetail" (onClick)="viewDetail()">
          {{ 'app-contract-request-info.viewMoreDetails' | translate }}
        </app-button>
      </div>
    </ng-template>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'app-contract-request-info.form.contractRequestNumber' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ contract.requestNumber ? contract.requestNumber : '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'app-contract-request-info.form.requestDate' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{
          contract.requestDate | date: global.appConstant.core.FORMAT_SHORT_DATE
        }}
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'app-contract-request-info.form.requestor' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ contract.userRequester.name ? contract.userRequester.name : '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'app-contract-request-info.form.contractType' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ contract.contractType.name ? contract.contractType.name : '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'app-contract-request-info.form.dealingAgreementType' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{
          contract.dealingAgreementType
            ? contract.dealingAgreementType.name
            : '-'
        }}
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'app-contract-request-info.form.description' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ contract.description ? contract.description : '-' }}
      </div>
    </div>
    <h3 class="separator">{{
      'app-contract-request-info.separator.generalInformation' | translate
    }}</h3>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'app-contract-request-info.form.object' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{
          contract.contractPreparation ? contract.contractPreparation.code : '-'
        }}
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'app-contract-request-info.form.date' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{
          contract.contractPreparation.orderDate
            | date: global.appConstant.core.FORMAT_SHORT_DATE
        }}
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'app-contract-request-info.form.title' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{
          contract.contractPreparation
            ? contract.contractPreparation.title
            : '-'
        }}
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'app-contract-request-info.form.department' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{
          contract.contractPreparation.organization
            ? contract.contractPreparation.organization.name
            : '-'
        }}
      </div>
    </div>
  </app-card>
</form>
