import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetContentSliderModule } from '../../widgets/app-widget-content-slider/app-widget-content-slider.module';
import { AppWorkflowInfoComponent } from './app-workflow-info.component';
@NgModule({
  imports: [VendorModule, AppWidgetContentSliderModule],
  declarations: [AppWorkflowInfoComponent],
  exports: [AppWorkflowInfoComponent]
})
export class AppWorkflowInfoModule {}
