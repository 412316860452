<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="blacklist-criteria.title" detail="app.action.{{ todo }}"
      [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'blacklist-criteria.form.code' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field autofocus="true" size="SM" formControlName="code"
              type="code"
              tooltip="{{ 'blacklist-criteria.tooltip.code' | translate }}"
              maxLength="32"></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'blacklist-criteria.form.violation' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="violation" maxLength="512">
            </app-text-area>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'blacklist-criteria.form.duration' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field size="TN" formControlName="durationInMonth"
              type="integer" maxLength="4"
              symbolic="{{ 'blacklist-criteria.symbolic.months' | translate }}">
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'blacklist-criteria.form.description' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxLength="512">
            </app-text-area>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <app-button color="SECONDARY" class="mr-1" (onClick)="doCancel()">
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button color="PRIMARY" (onClick)="doSave()">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>