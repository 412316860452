<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="budget-allocation.title" [isLoading]="formLoading"
            [isSaving]="formSaving">
            <app-table [model]="tableResponse" [isServerSide]="true"
                key="by-organization-detail-index"
                stringUrl="/budget-allocation/organization/detail/index"
                isShowEditTable="true">
                <!-- Product -->
                <!-- <ng-template #actionButtons let-rowData>
                    <app-button size="TN" (onClick)="doEdit(rowData)"
                        title="{{ 'app.tooltip.edit' | translate }}">
                        <em class="fas fa-pencil-alt"></em>
                    </app-button>
                    <app-button size="TN" color="DANGER"
                        (onClick)="doDelete(rowData)"
                        title="{{ 'app.tooltip.delete' | translate }}">
                        <em class="fa fas fa-trash"></em>
                    </app-button>
                </ng-template> -->
            </app-table>
            <div class="row justify-content-center mt-5">
                <app-button mode="BYPASS" color="SECONDARY"
                    (onClick)="doCancel()" class="mr-1" [disabled]='formSaving'>
                    {{ 'app.button.back' | translate }}</app-button>
            </div>
        </app-card>
    </div>
</div>