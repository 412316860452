<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="vendor-qualification.title" [isLoading]="formLoading" [isSaving]="formSaving">
            <app-table [tableResponse]="tableResponse" [isServerSide]="true" stringUrl="/vendor-qualification/index">
                <ng-template #headerButtons>
                    <button class="btn btn-primary " (click)="doImport()">
                        <em class="far fa-file"></em>&nbsp; {{ 'app.button.import' | translate }}
                    </button>
                    <button class="btn btn-primary ml-2" (click)="doAdd()">
                        <em class="fas fa-plus"></em>&nbsp; {{ 'app.button.add' | translate }}
                    </button>
                </ng-template>
                <ng-template #actionButtons let-rowData>
                    <button class="btn btn-primary btn-sm mr-2" type="button" (click)="doEdit(rowData)"
                        title="{{ 'app.tooltip.edit' | translate }}">
                        <span class="fas fa-pencil-alt"></span>
                    </button>
                    <button class="btn btn-danger btn-sm mr-2" type="button" (click)="doDelete(rowData)"
                        title="{{ 'app.tooltip.delete' | translate }}">
                        <span class="fa fas fa-trash"></span>
                    </button>
                </ng-template>
            </app-table>
        </app-card>
    </div>
</div>