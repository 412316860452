import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Procurement } from '../../core/bean/procurement';
import { QuotationStatus } from '../../core/bean/quotation-status';
import { AppTableXComponent } from '../../core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { SubmitQuotationVendorView } from '../../core/view/entity/bean/submit-quotation-vendor-view';
@Component({
  templateUrl: './submit-quotation-vendor.component.html'
})
export class SubmitQuotationVendorComponent extends BaseModuleComponent {
  @ViewChild(AppTableXComponent) table: AppTableXComponent;
  public tableResponse: TableResponseModel<SubmitQuotationVendorView>;
  public statusOptionList: OptionListModel<QuotationStatus> =
    new OptionListModel(false, 'name');
  public customData = '';
  constructor(translateService: TranslateService) {
    super('submit-quotation-vendor', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      statusList: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<QuotationStatus[]>('/submit-quotation-vendor/get-option-list')
      .subscribe(response => {
        if (response != null) {
          this.statusOptionList.setRequestValues(response);
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    const RE_HELD: string = this.translateService.instant(
      'dynamic-master-attribute.procurementFlag.reHeld'
    );
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurement.code',
        header: 'table.column.procurementNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doEdit.bind(this)
        }
      },
      {
        field: 'procurement.name',
        header: 'table.column.procurementName',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'badge',
            field: 'isRepeat',
            pill: false,
            className: 'badge-catalog badge-danger ml-3',
            colorMap: {
              RE_HELD: 'DANGER'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                const procurement = tablePluginData.row.record as Procurement;
                if (procurement.isRepeat) {
                  return RE_HELD;
                }
              }
            }
          },
          {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData): void => {
              const procurement = tablePluginData.row.record as Procurement;
              if (!procurement.isRepeat) {
                tablePluginData.element.children[1].remove();
              }
            }
          }
        ]
      },
      {
        field: 'documentSubmissionType',
        header: 'table.column.documentSubmissionType'
      },
      {
        field: 'lastDateSubmission',
        header: 'table.column.lastDateSubmission',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'quotation.submittedDate',
        header: 'table.column.submittedDate',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'quotationStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'quotationStatus.code',
          colorMap: {
            NEW: 'INFO',
            DRAFT: 'SECONDARY',
            ON_PROGRESS: 'PROGRESS',
            WAITING_APPROVAL: 'WARNING',
            WAITING_RESPONSE: 'GOOD',
            CANCELED: 'DANGER',
            CLOSED_RESPONSE: 'DANGER',
            DONE: 'SUCCESS',
            COMPLETED: 'SUCCESS',
            SUBMITTED: 'SUCCESS',
            HOLD: 'DANGER'
          }
        }
      }
    ]);
  }

  doEdit(data: SubmitQuotationVendorView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('procurementId', data.procurement.id);
    this.global.routerParams.set('quotationId', data.quotation?.id);
    this.global.routerParams.set(
      'procurementVendorId',
      data.procurementVendor.id
    );
    this.global.routerParams.set('quotationStatus', data.quotationStatus);
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/submit-quotation-vendor'
    );
    if (
      data.quotationStatus.code ===
        this.global.appConstant.pm.QUOTATION_STATUS_DRAFT ||
      data.procurement.isRepeat
    ) {
      this.router.navigate(['/pages/submit-quotation-vendor/edit']);
      this.global.routerParams.set('todo', 'edit');
    } else if (
      data.quotationStatus.code ===
      this.global.appConstant.pm.QUOTATION_STATUS_NEW
    ) {
      this.router.navigate(['/pages/submit-quotation-vendor/add']);
      this.global.routerParams.set('todo', 'add');
    } else {
      this.router.navigate(['/pages/submit-quotation-vendor/detail']);
      this.global.routerParams.set('todo', 'view');
    }
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
    } else {
      this.formGroup.patchValue({
        fromDate: null,
        untilDate: null
      });
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
