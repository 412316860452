import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Procurement } from '../../core/bean/procurement';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { AlertService } from '../../core/services/alert.service';

@Component({
  templateUrl: './app-popup-successfuly-create.component.html'
})
export class AppPopupSuccessfulyCreateComponent extends BaseComponentComponent {
  @Input() public procurement: Procurement;
  @Input() public procurementWorklistStatusCode: string;
  @Input() public title: string;
  @Input() public prompt: string;

  public constructor(
    private ngbActiveModal: NgbActiveModal,
    private alertService: AlertService
  ) {
    super('price-evaluation');
  }

  public onInit(): void {
    this.setStateReady();
  }

  public doStayInPage(): void {
    this.ngbActiveModal.close();
    this.router
      .navigateByUrl('/', {
        skipLocationChange: true
      })
      .then(() => {
        this.global.routerParams.clear();
        this.global.routerParams.set('procurement', this.procurement);
        this.global.routerParams.set('urlBackOutside', '/pages/worklist-pm');
        this.global.routerParams.set(
          'procurementWorklistStatusCode',
          this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_ON_PROGRESS
        );
        this.global.routerParams.set('todo', 'edit');
        this.router.navigate(['/pages/price-evaluation/edit']);
      });
  }

  public doGoToAuction(): void {
    this.setStateProcessing();
    this.httpClientService
      .get<Response<string>>(
        '/price-evaluation/getAuctionProcurementId/' + this.procurement.id
      )
      .subscribe(response => {
        this.setStateReady();
        if (response.status === ResponseStatusModel.OK && response.body) {
          window.location.href =
            this.global.config.parameterModel.promiseAuctionAddressUrl +
            '/initiation/form/' +
            response.body;
        } else {
          this.alertService.showError(
            'prive-evaluation.popup.validation.actionCode',
            '.popup-successfuly'
          );
        }
      });
  }
}
