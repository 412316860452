<app-popup header="{{ 'catalog-attribute-group.popup.header' | translate }}">
  <div class="main-row-popup-catalog-attribute">
    <app-table
      [tableResponse]="tableResponse"
      [isServerSide]="true"
      stringUrl="/catalog-attribute-group/app-popup-catalog-attribute"
      customData="{{ catalogAttributeIdCustomData }}"
      (onChange)="onChangeTableRecords($event)"
    >
    </app-table>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center mt-5">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
        <app-button (onClick)="doSubmit()"
          >{{ 'app.button.save' | translate }}
        </app-button>
      </div>
    </ng-template>
  </div>
</app-popup>
