import { BaseEntity } from '../../base/base-entity';
import { Region } from '../../bean/region';
import { VendorActivityStatus } from '../../bean/vendor-activity-status';
import { VendorStatus } from '../../bean/vendor-status';
import { VendorType } from '../../bean/vendor-type';

export class PopupVendorViewEntity extends BaseEntity {
  vendorStatus: VendorStatus = new VendorStatus();
  vendorActivityStatus: VendorActivityStatus = new VendorActivityStatus();
  vendorType: VendorType = new VendorType();
  region: Region = new Region();
  name: string;
  averageRatingOverall: number;
  email: string;
  address: string;
  phone: string;
  userRefer: boolean;
  keyword: string;
  blacklistRangeYear: string;
  sameCompanyOrgVendorName: string;
  picName: string;
  procurementTotal: number;
  procurementWinTotal: number;
}
