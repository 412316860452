import { Module } from 'src/app/core/bean/module';
import { ProcurementSchedule } from 'src/app/core/bean/procurement-schedule';
import { WorkflowModelPrcs } from 'src/app/core/bean/workflow-model-prcs';
import { ApprovalPathResponseModel } from 'src/app/core/components/app-approval-path-x';
import { ItemType } from '../../core/bean/item-type';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { WorkflowStepInfoModel } from '../../core/components/app-workflow-step-info/model/workflow-step-info-model';

export class ProcurementScheduleResponse {
  procurement: Procurement = new Procurement();
  workflowStepInfoModel: WorkflowStepInfoModel = new WorkflowStepInfoModel();
  itemTypeList: ItemType[] = [];
  procurementItemList: ProcurementItem[] = [];
  approvalPathResponseModel: ApprovalPathResponseModel;
  isUserApproval: boolean;
  procurementScheduleList: ProcurementSchedule[] = [];
  module: Module = new Module();
  workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  isShowGenerate: boolean;
}
