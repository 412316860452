import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAccordionModule } from '../app-accordion/app-accordion.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppFieldsetModule } from '../app-fieldset/app-fieldset.module';
import { AppTableModule } from '../table/components/app-table/app-table.module';
import { AppSlaInfoComponent } from './app-sla-info.component';

@NgModule({
    imports: [
        VendorModule,
        AppTableModule,
        AppCardModule,
        AppAccordionModule,
        AppFieldsetModule
    ],
    declarations: [AppSlaInfoComponent],
    exports: [AppSlaInfoComponent]
})
export class AppSlaInfoModule { }
