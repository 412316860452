import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { File } from '../../core/bean/file';
import { Organization } from '../../core/bean/organization';
import { Region } from '../../core/bean/region';
import { Vendor } from '../../core/bean/vendor';
import { VendorType } from '../../core/bean/vendor-type';
import { CaptchaModel } from '../../core/components/app-captcha/model/captcha-model';
import { AppPopupRegistrationTermConditionService } from '../../core/components/app-popup/app-popup-registration-term-condition/app-popup-registration-term-condition.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { VendorEntity } from '../../core/entity/vendor.entity';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { PopupLoginComponent } from '../../themes/promise-portal/components/popup-login/popup-login.component';
import { RegistrationVendorRequest } from './registration-vendor-request';
import { RegistrationVendorResponse } from './registration-vendor-response';
@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html'
})
export class RegistrationFormComponent extends BaseModuleComponent {
  public data: any;
  public registrationId: number;
  public now = new Date().getTime();
  public registrationVendorRequest: RegistrationVendorRequest =
    new RegistrationVendorRequest();
  public registrationVendorResponse: RegistrationVendorResponse =
    new RegistrationVendorResponse();
  public regionOptionList: OptionListModel<Region> = new OptionListModel(true);
  public vendorTypeOptionList: OptionListModel<VendorType> =
    new OptionListModel(true);
  public vendorEntityOptionList: OptionListModel<VendorEntity> =
    new OptionListModel(true);
  public setCompanyNameLabelActive: boolean;
  public defaultOrganizationList: Organization[] = [];
  public captchaModel: CaptchaModel = new CaptchaModel();
  public vendorEntityTooltip = this.translateService.instant(
    'registration.tooltip.vendorEntityNonCompany'
  );
  public emailTooltip = this.translateService.instant(
    'registration.tooltip.emailNonCompany'
  );
  public vendorTypeCode: string;
  constructor(
    translateService: TranslateService,
    public appPopupRegistrationTermConditionService: AppPopupRegistrationTermConditionService,
    private appPopupService: AppPopupService
  ) {
    super('registration', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.getAndSetRegistrationToFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.data = this.global.routerParams.get('data');
    this.setCompanyNameLabelActive = this.global.routerParams.get(
      'setCompanyNameLabelActive'
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      vendorType: [null, Validators.required()],
      vendorEntity: [null, Validators.required()],
      companyName: [null],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(255)])
      ],
      email: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.email(),
          Validators.maxLength(64)
        ])
      ],
      retypeEmail: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.matchEmail(),
          Validators.maxLength(64)
        ])
      ],
      password: [
        null,
        Validators.compose([Validators.required(), Validators.password()])
      ],
      retypePassword: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.matchPassword(),
          Validators.maxLength(64)
        ])
      ],
      phone: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      organizationList: [null, Validators.compose([Validators.required()])],
      termCondition: [
        false,
        Validators.compose([
          Validators.required(),
          Validators.isChecked(1, 'registration.validation.termsAndCondition')
        ])
      ],
      taxNumber: [
        null,
        {
          validators: [Validators.required(), Validators.npwp()],
          asyncValidators: [
            Validators.existsAsync(
              '/registration/check-tax-number-exist/' + this.global.currentLang,
              null,
              null,
              { id: null }
            )
          ]
        }
      ]
    });
  }

  public getAndSetRegistrationToFormGroup(): void {
    this.httpClientService
      .get<RegistrationVendorResponse>('/registration/add-edit')
      .subscribe(registrationVendorResponse => {
        this.registrationVendorResponse = registrationVendorResponse;
        this.regionOptionList.stringUrl = '/resource/';
        this.regionOptionList.fileTypeCode =
          this.global.appConstant.fileType.IMG_ICON;

        const regionList = this.registrationVendorResponse.regionList;
        const idReg = regionList.find(region => region.code === 'ID');
        const indexIdReg = regionList.findIndex(region => region.code === 'ID');

        if (indexIdReg !== -1) {
          regionList.splice(indexIdReg, 1);
          regionList.unshift(idReg);
        }

        this.regionOptionList.setRequestValues(regionList);
        this.vendorTypeOptionList.setRequestValues(
          registrationVendorResponse.vendorTypeList
        );
        this.setFormGroup(registrationVendorResponse);
        this.setStateReady();
      });
  }

  public changeLabelAndVendorEntity(event: any): void {
    this.vendorTypeCode = event?.code;
    if (
      this.vendorTypeCode !==
      this.global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
    ) {
      this.formGroup
        .get('taxNumber')
        .setValidators(
          Validators.compose([Validators.required(), Validators.npwp()])
        );
    } else {
      this.formGroup
        .get('taxNumber')
        .setValidators(Validators.compose([Validators.required()]));
    }
    this.formGroup.get('taxNumber').updateValueAndValidity();

    if (!event?.isCompany) {
      this.setCompanyNameLabelActive = false;
      this.formGroup.get('companyName').clearValidators();
      this.formGroup
        .get('companyName')
        .setValidators([Validators.maxLength(255)]);
      this.formGroup.get('companyName').updateValueAndValidity();
      this.vendorEntityTooltip = this.translateService.instant(
        'registration.tooltip.vendorEntityNonCompany'
      );
      this.emailTooltip = this.translateService.instant(
        'registration.tooltip.emailNonCompany'
      );
    } else {
      this.setCompanyNameLabelActive = true;
      this.formGroup
        .get('companyName')
        .setValidators(
          Validators.compose([Validators.required(), Validators.maxLength(255)])
        );
      this.formGroup.get('companyName').updateValueAndValidity();
      this.vendorEntityTooltip = this.translateService.instant(
        'registration.tooltip.vendorEntityCompany'
      );
      this.emailTooltip = this.translateService.instant(
        'registration.tooltip.emailCompany'
      );
    }

    if (event) {
      this.httpClientService
        .get<VendorEntity[]>(
          '/registration/change-vendor-entity-list/' + event.id
        )
        .subscribe(vendorEntityList => {
          this.vendorEntityOptionList.setRequestValues(vendorEntityList);
          this.formGroup.get('vendorEntity').setValue(null);
        });
    }
  }

  public setFormGroup(
    registrationVendorResponse: RegistrationVendorResponse
  ): void {
    if (registrationVendorResponse.defaultOrganization !== null) {
      this.defaultOrganizationList.push(
        registrationVendorResponse.defaultOrganization
      );
      this.formGroup
        .get('organizationList')
        .patchValue(this.defaultOrganizationList);
    }

    if (
      this.global.routerParams.get('isBackFromRegistConfirm') &&
      typeof this.data !== 'undefined'
    ) {
      const vendorType = this.data.vendorType;
      const vendorEntity = this.data.vendorEntity;
      this.formGroup
        .get('organizationList')
        .patchValue(this.data.organizationList);
      const {
        id,
        companyName,
        name,
        email,
        retypeEmail,
        password,
        retypePassword,
        phone,
        taxNumber
      } = this.data;
      this.formGroup.patchValue({
        id,
        companyName,
        name,
        email,
        retypeEmail,
        password,
        retypePassword,
        vendorType,
        vendorEntity,
        phone,
        taxNumber
      });
    }
    this.global.routerParams.set('isBackFromRegistConfirm', false);
  }

  public doDisplayTermCondition(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupRegistrationTermConditionService.open(
      this.registrationVendorResponse
    );
  }

  public doNext(): void {
    const phone = this.formGroup.get('phone').value;
    const termCondition = this.formGroup.get('termCondition').value;
    this.validate();
    // const registrationVendorRequest: RegistrationVendorRequest =
    //   new RegistrationVendorRequest();
    // registrationVendorRequest.phoneNumber = phone.number;
    // registrationVendorRequest.email = this.formGroup.get('email').value;
    // registrationVendorRequest.lang = this.global.currentLang;

    if (this.formGroup.valid) {
      this.httpClientService
        .get<Response<Vendor>>(
          '/registration/check-email-phone?email=' +
            this.formGroup.get('email').value +
            '&phoneNumber=' +
            phone.number +
            '&langCode=' +
            this.global.currentLang
        )
        .subscribe(response => {
          if (response.status !== ResponseStatusModel.ERROR && termCondition) {
            this.global.routerParams.set('todo', 'confirmation');
            this.global.routerParams.set('data', this.formGroup.value);
            this.global.routerParams.set(
              'setCompanyNameLabelActive',
              this.setCompanyNameLabelActive
            );
            this.router.navigate(['/portal/registration/confirmation']);
          } else if (response.status === ResponseStatusModel.ERROR) {
            this.global.alertService.showError(response.statusText);
          }
        });
    }
  }

  public doCancel(): void {
    if (this.global.config.parameterModel.isInterfacing) {
      window.location.href = this.global.config.BACKEND_ADDRESS.replace(
        '/api',
        ''
      );
    } else {
      this.router.navigate(['/portal/']);
    }
  }

  public downloadManualBook(): void {
    const url = '/registration/download-regist-document/';
    this.httpClientService
      .get<Response<File>>(url + this.global.currentLang)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          if (response.body !== null) {
            window.open(
              this.global.config.BACKEND_ADDRESS +
                '/registration' +
                '/file/view/' +
                response.body.uploadedFileName +
                '/' +
                this.global.appConstant.fileType.DOC_REGIST_VENDOR
            );
          } else {
            this.global.alertService.showError(
              this.global.translateService.instant(
                'registration-document.fileNotExist'
              )
            );
          }
        } else {
          this.global.alertService.showError(response.statusText);
        }
      });
  }

  public doLogin(event: PointerEvent): void {
    event.preventDefault();
    this.appPopupService.open(PopupLoginComponent, null, {
      windowClass: 'promise-portal-popup-login'
    });
  }
}
