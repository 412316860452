<app-popup header="app-billing-memo-information-view.popup.budgetDetailTitle">
  <form
    class="form-horizontal popup-biling-memo"
    [formGroup]="formGroup"
    novalidate
  >
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-billing-memo-information-view.popup.form.totalBilling' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          totalBilling ? global.converterService.convertMoney(totalBilling) : 0
        }}
        {{ currency.code }}
      </div>
    </div>
  </form>
  <app-table [model]="tableResponse" [isServerSide]="false"> </app-table>
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
        'app.button.close' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
