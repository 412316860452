import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
@Component({
    templateUrl: './tab-login-history.component.html'
})
export class TabLoginHistoryComponent extends BaseModuleComponent {
    constructor(translate: TranslateService) {
        super('tab-login-history', translate);
    }

    public onInit(): void { }

    public doBack(): void {
        this.router.navigate(['/pages/vendor-history/detail']);
    }
}
