<app-layout
  [isLoading]="formLoading"
  [isShowBreadcrumbs]="false"
  header="pr.vendorDetail.title"
>
  <div class="row main-row">
    <div class="col-12">
      <app-card [isLoading]="formLoading" [isSaving]="formSaving">
        <app-vendor-catalog-info
          [vendorId]="vendorId"
          itemPath="pr/add-item-detail"
        ></app-vendor-catalog-info>
      </app-card>
    </div>
  </div>
</app-layout>
