import { EventEmitter, Injectable } from '@angular/core';
import { Aanwijzing } from 'src/app/core/bean/aanwijzing';
import { WebSocketService } from '../../core/services/websocket/websocket.service';

@Injectable({ providedIn: 'root' })
export class SessionRoomWebsocketService extends WebSocketService<Aanwijzing> {
  constructor() {
    super(`session-room`);
  }
  public session: EventEmitter<Aanwijzing> = new EventEmitter();

  protected onReceivedMessage(message: string): Promise<any> {
    return new Promise(resolve => {

    const aanwijzing: Aanwijzing = JSON.parse(message);
    if (aanwijzing) {
        resolve(this.setReceivedMessage(aanwijzing));
        this.session.emit(aanwijzing);
      }
    });
  }
}
