import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { OrderAddEditResponse } from './response/order-add-edit.response';

@Component({
  templateUrl: './pr-vendor-detail.component.html'
})
export class PrVendorDetailComponent
  extends BaseModuleComponent
  implements OnInit
{
  public orderResponse: OrderAddEditResponse = new OrderAddEditResponse();
  public vendorId: number;
  constructor(translateService: TranslateService) {
    super('pr', translateService);
  }
  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setStateReady();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorId = this.global.routerParams.get('vendorId');
    this.orderResponse = this.global.routerParams.get('orderResponse');
  }
}
