import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Pr } from 'src/app/core/bean/pr';
import { PrStatus } from 'src/app/core/bean/pr-status';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Organization } from '../../core/bean/organization';
import { PrVerificationOptionListResponse } from './pr-verification-option-list.response';
@Component({
  templateUrl: './pr-verification.component.html'
})
export class PrVerificationComponent
  extends BaseModuleComponent
  implements OnInit
{
  public filter: string;
  public tableResponse: TableResponseModel<Pr>;
  public prStatusOptionList: OptionListModel<PrStatus> = new OptionListModel();
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel();
  constructor(translateService: TranslateService) {
    super('pr-verification', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setOptionList();
    this.doBuildTableResponse();
    this.doSetCustomData();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.filter = this.global.routerParams.get('filter');
  }

  public doSetCustomData(): void {
    if (this.filter) {
      const customData = {
        statusCode: this.filter
      };
      this.global.routerParams.clear();
      this.tableResponse.setCustomData(customData);
      this.tableResponse.reload();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      prStatusList: [null],
      organizationList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<PrVerificationOptionListResponse>('/pr-verification/get-option-list')
      .subscribe((response: PrVerificationOptionListResponse) => {
        if (response.prStatusList.length > 0) {
          this.prStatusOptionList.setRequestValues(response.prStatusList);
        }
        if (response.organizationList.length > 0) {
          this.organizationOptionList.setRequestValues(
            response.organizationList
          );
        }
      });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.prNumber',
        plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
      },
      {
        field: 'requiredDate',
        header: 'table.column.requiredDate',
        plugins: 'date'
      },
      {
        field: 'organization.name',
        header: 'table.column.department'
      },
      {
        field: 'description',
        header: 'table.column.description'
      },
      {
        field: 'amount',
        header: 'table.column.totalCost',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      },
      {
        field: 'prStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          field: 'prStatus.code',
          colorMap: {
            DRAFT: 'SECONDARY',
            REVISION: 'FEEDBACK',
            WAITING_APPROVAL: 'WARNING',
            DONE: 'SUCCESS',
            ON_PROGRESS: 'PROGRESS',
            HOLD: 'DANGER',
            VERIFIED: 'INFO',
            WAITING_VERIFICATION: 'WARNING'
          }
        }
      }
    ]);
  }

  public doClick(pr: Pr): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('prId', pr.id);
    this.global.routerParams.set('prStatus', pr.prStatus);
    this.global.routerParams.set('urlBackOutside', '/pages/pr-verification');
    if (
      pr.prStatus.code === this.global.appConstant.pm.PR_STATUS_REVISION ||
      pr.prStatus.code === this.global.appConstant.pm.PR_STATUS_WAITING_APPROVAL
    ) {
      this.global.routerParams.set('todo', 'view');
      this.router.navigate(['/pages/pr-verification/detail']);
    } else {
      this.global.routerParams.set('todo', 'edit');
      this.router.navigate(['/pages/pr-verification/edit']);
    }
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
    this.formGroup.get('fromDate').reset();
    this.formGroup.get('untilDate').reset();
  }
}
