import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { TabProfileDocumentComponent } from './tab-profile-document.component';

export const routes = [
  {
    path: '',
    component: TabProfileDocumentComponent,
    data: { breadcrumb: '' }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    TabProfileDocumentComponent
  ],
  exports: [
    TabProfileDocumentComponent
  ]
})

export class TabProfileDocumentModule {}
