import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { CatalogBulkPrice } from '../../bean/catalog-bulk-price';
import { CatalogContract } from '../../bean/catalog-contract';
import { CatalogSpecification } from '../../bean/catalog-specification';
import { File } from '../../bean/file';
import { ProcurementItem } from '../../bean/procurement-item';
import { Validators } from '../../validators';
import { CatalogView } from '../../view/entity/bean/catalog-view';
import { AppPopupItemResponse } from './app-popup-item-response';

@Component({
  templateUrl: './app-popup-item-edit.component.html',
  styleUrls: ['./app-popup-item-edit.component.scss']
})
export class AppPopupItemEditComponent extends BaseComponentComponent {
  @Input() public procurementItem: ProcurementItem;
  @Input() public isView: boolean;
  @Output() onChange: EventEmitter<ProcurementItem> = new EventEmitter();

  public catalogContract: CatalogContract;
  public fileList: File[] = [];
  public catalogSpecificationList: CatalogSpecification[] = [];
  public catalogView: CatalogView = new CatalogView();
  public popupItemResponse: AppPopupItemResponse = new AppPopupItemResponse();
  public catalogBulkPriceList: CatalogBulkPrice[] = [];

  constructor(
    public translateService: TranslateService,
    public currencyPipe: CurrencyPipe
  ) {
    super('app-item-request');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.getDataCatalogSpecification();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      price: [null],
      totalPrice: [null],
      quantity: [
        null,
        Validators.compose([Validators.required(), Validators.min(1)])
      ],
      specification: [null],
      prItemImageList: this.formBuilder.array([])
    });
  }

  public getDataCatalogSpecification(): void {
    if (this.procurementItem.prItem.catalog) {
      this.httpClientService
        .get<CatalogSpecification[]>(
          '/app-item-request/get-catalog-specification/' +
            this.procurementItem.prItem.catalog.id
        )
        .subscribe(response => {
          this.catalogSpecificationList = response;
          this.setFormGroup();
        });
    } else {
      this.setFormGroup();
    }
  }

  public setFormGroup(): void {
    this.formGroup.patchValue({
      price: this.procurementItem.prItem.price,
      totalPrice: this.procurementItem.prItem.totalPrice,
      quantity: this.procurementItem.prItem.quantity,
      specification: this.procurementItem.prItem.specification
    });
    const prItemImageList = this.formGroup.get('prItemImageList') as FormArray;
    this.procurementItem.prItem.prItemImageList.forEach(prItemImage => {
      this.fileList.push(prItemImage.file);
      prItemImageList.push(
        this.formBuilder.group({
          fileObjectList: prItemImage.fileObjectList,
          file: prItemImage.file
        })
      );
    });
    this.formGroup.get('specification').setIsView(true);
    if (this.isView) {
      this.formGroup.setIsView(true);
    }
    this.setStateReady();
  }

  public onChangeQuantity(): void {
    const prices = this.formGroup.value.price;
    const quantity = this.formGroup.value.quantity;
    let totalPrice = null;
    if (prices && +quantity) {
      totalPrice = +(+prices * +quantity);
      this.formGroup.patchValue({
        price: prices
      });
    }
    this.formGroup.patchValue({
      totalPrice
    });
  }

  public doConvertToDate(value: string): Date {
    const parsedValue = JSON.parse(value);
    return new Date(parsedValue);
  }

  public doParseValue(catalogSpesification: CatalogSpecification): string {
    let value = catalogSpesification.value;
    const parsedValue: any = JSON.parse(value);
    if (parsedValue) {
      if (Array.isArray(parsedValue)) {
        value = '';
        parsedValue.forEach((valueObject, i) => {
          value += valueObject.name;
          if (i !== parsedValue.length - 1) {
            value += ', ';
          }
        });
      } else if (typeof parsedValue === 'string') {
        value = parsedValue;
      } else if (
        catalogSpesification.catalogAttribute.formObject.code === 'FIELD-PRICE'
      ) {
        const price = this.currencyPipe.transform(
          parsedValue.price,
          '',
          '',
          '1.2-2'
        );
        value = price + ' ' + parsedValue.currency.code;
      } else {
        value = parsedValue.name;
      }
    } else {
      value = '-';
    }

    return value;
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.procurementItem.prItem.price = this.formGroup.value.price;
      this.procurementItem.prItem.totalPrice = this.formGroup.value.totalPrice;
      this.procurementItem.prItem.quantity = this.formGroup.value.quantity;
      this.onChange.emit(this.procurementItem);
    }
  }
}
