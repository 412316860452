<app-card
  header="app-vendor-payment-information.title"
  tips="app-vendor-payment-information.tips"
>
  <ng-template #headerRight>
    <app-button (onClick)="doViewDetailVendor()" *ngIf="!vendor.isManual">{{
      'app-vendor-payment-information.button.viewProfile' | translate
    }}</app-button>
  </ng-template>
  <div class="text-center py-4" *ngIf="formLoading">
    <img src="assets/img/common/loader-small.gif" alt="Loading" />
  </div>
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    novalidate
    *ngIf="!formLoading"
  >
    <div class="form-group row">
      <label class="col-sm-3 control-label">{{
        'app-vendor-payment-information.form.companyName' | translate
      }}</label>
      <div class="col-sm-9">
        {{ vendor ? vendor.name : vendorOffline?.name }}
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3 control-label">{{
        'app-vendor-payment-information.form.address' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          vendorAddress ? vendorAddress.address : vendorOffline?.address || '-'
        }}
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3 control-label">{{
        'app-vendor-payment-information.form.email' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ vendor?.email ? vendor.email : vendorOffline?.email || '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3 control-label">{{
        'app-vendor-payment-information.form.phoneNumber' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          vendor?.phoneRegion
            ? vendor.phoneRegion.callingCode + vendor.phone
            : vendorOffline?.phone || '-'
        }}
      </div>
    </div>
    <ng-container *ngIf="!vendor.isManual">
      <div class="form-group row text-sm-left">
        <label class="col-sm-3 control-label">{{
          'app-vendor-payment-information.form.bankName' | translate
        }}</label>
        <div class="col-sm-9 text-left" *ngIf="todo === 'view'">
          {{
            vendorBank
              ? vendorBank.bank.name
              : vendorBankOffline?.bankName || '-'
          }}
        </div>
        <div class="col-sm-9 text-left" *ngIf="todo !== 'view'">
          <app-dropdown-select
            size="MD"
            formControlName="bank"
            type="COMBOBOX"
            [optionList]="bankOptionList"
            (onChange)="onChangeBank()"
            container="body"
            placeholder="app-vendor-payment-information.form.placeholder.chooseBank"
          ></app-dropdown-select>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3 control-label">{{
          'app-vendor-payment-information.form.bankAccountNumber' | translate
        }}</label>
        <div class="col-sm-9 text-left" *ngIf="todo === 'view'">
          {{
            vendorBank
              ? vendorBank.accountNumber
              : vendorBankOffline?.accountNumber || '-'
          }}
        </div>
        <div class="col-sm-9 text-left" *ngIf="todo !== 'view'">
          <app-dropdown-select
            size="MD"
            formControlName="vendorBank"
            type="COMBOBOX"
            [optionList]="vendorBankOptionList"
            (onChange)="onChangeAccountNumber()"
            container="body"
            placeholder="app-vendor-payment-information.form.placeholder.chooseAccountNumber"
          ></app-dropdown-select>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3 control-label">{{
          'app-vendor-payment-information.form.bankAccountName' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ vendorBank?.accountHolder || '-' }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!formLoading && vendor.isManual">
      <div class="form-group row text-sm-left">
        <label class="col-sm-3 control-label">
          {{ 'app-vendor-payment-information.form.bankName' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          <app-auto-complete
            formControlName="bank"
            stringUrl="/app-vendor-payment-information/auto-complete-bank-list"
            (onChange)="onChangeBankInternal()"
          ></app-auto-complete>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3 control-label">
          {{
            'app-vendor-payment-information.form.bankAccountNumber' | translate
          }}
        </label>
        <div class="col-sm-9 text-left">
          <app-text-field
            formControlName="accountNumber"
            maxLength="32"
            placeholder="{{
              'app-vendor-payment-information.placeholder.inputBankAccountNumber'
                | translate
            }}"
            (onChange)="onChangeAccountNumber()"
          >
          </app-text-field>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3 control-label">
          {{
            'app-vendor-payment-information.form.bankAccountName' | translate
          }}
        </label>
        <div class="col-sm-9 text-left">
          <app-text-field
            formControlName="accountHolder"
            maxLength="64"
            placeholder="{{
              'app-vendor-payment-information.placeholder.inputBankAccountName'
                | translate
            }}"
            (onChange)="onChangeAccountNumber()"
          >
          </app-text-field>
        </div>
      </div>
    </ng-container>
  </form>
</app-card>
