<dashboard-widget-card
  [isLoading]="model.isLoading"
  [header]="moduleCode + '.title'"
  customClassHeader="align-items-center"
>
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    novalidate
    *ngIf="!model.isLoading"
  >
    <ng-template #headerRight>
      <div class="d-flex">
        <div class="budget-type mr-2" [ngStyle]="{ width: '150px' }">
          <app-dropdown-select
            type="CHOSEN"
            size="XL"
            container="body"
            formControlName="budgetType"
            [optionList]="budgetTypeOptionList"
            (onChange)="onChangeBudgetType($event)"
            placeholder="dashboard-widget-budget-spending-type.form.placeholder.budgetType"
            showLimitValue="1"
            [isMultiple]="true"
          >
          </app-dropdown-select>
        </div>
        <div [ngStyle]="{ width: '150px' }">
          <app-dropdown-select
            type="COMBOBOX"
            size="XL"
            container="body"
            formControlName="year"
            [optionList]="yearOptionList"
            (onChange)="onChangeYear($event)"
            placeholder="dashboard-widget-budget-spending-type.form.placeholder.year"
          >
          </app-dropdown-select>
        </div>
      </div>
    </ng-template>
    <div *ngIf="!model.isLoading" class="dashboard-widget-budget-spending-type">
      <app-chart [model]="chartModel" height="265px"></app-chart>
      <ng-template #footer>
        <div
          class="row"
          *ngIf="
            formGroup.value.budgetType &&
            formGroup.value.budgetType.length > 0 &&
            formGroup.value.year
          "
        >
          <ng-container *ngFor="let model of modelList">
            <div class="cml-2 d-flex align-items-center">
              <div
                [ngStyle]="{
                  background: model.color,
                  width: '20px',
                  height: '20px'
                }"
              ></div>
            </div>
            <div
              class="cml-1 row col-sm-4 text-left align-items-center"
              style="display: grid"
            >
              <h6 class="font-weight-bold"> {{ model.code.toUpperCase() }}</h6>

              <h6> {{ model.total || 0.0 }} IDR</h6>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </form>
</dashboard-widget-card>
