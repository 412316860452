import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppPopupModule } from '../app-popup/app-popup.module';
import { AppMapPopupComponent } from './app-map-popup.component';
import { AppMapComponent } from './app-map.component';
import { AppMapService } from './app-map.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppButtonModule],
  declarations: [AppMapComponent, AppMapPopupComponent],
  entryComponents: [AppMapPopupComponent],
  exports: [AppMapComponent, AppMapPopupComponent],
  providers: [AppMapService]
})
export class AppMapModule {}
