import { TabState } from '../interfaces/tab-state.interface';
export function changeTabItemDisabled(
  tabState: TabState,
  payload: {
    id: string;
    disabled: boolean;
  }
): void {
  tabState.tabDisabled[payload.id] = payload.disabled;
}
