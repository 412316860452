import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupPrItemInfoComponent } from './app-popup-pr-item-info.component';

@Injectable()
export class AppPopupPrItemInfoService {
  constructor(public appPopupService: AppPopupService) {}
  public open(
    prItemId?: number,
    isShowBudget?: boolean,
    priceRealization?: number,
    totalPriceRealization?: number
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupPrItemInfoComponent,
      {
        prItemId,
        isShowBudget,
        priceRealization,
        totalPriceRealization
      },
      { size: 'xl' }
    );
  }
}
