<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="budget-allocation.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-fieldset legend="Filter">
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'budget-allocation.form.coa' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="coaList"
                [optionList]="coaOptionList"
              ></app-chosen>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'budget-allocation.form.type' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="budgetTypeList"
                [optionList]="budgetTypeOptionList"
              ></app-chosen>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'budget-allocation.form.organization' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-tree
                formControlName="organizationList"
                stringUrl="/organization"
                isLazy="true"
                header="Organization"
                [isMultiple]="true"
              >
              </app-text-tree>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'budget-allocation.form.currency' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box
                [optionList]="currencyOptionList"
                formControlName="currency"
              ></app-combo-box>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'budget-allocation.form.periodFrom' | translate
            }}</label>
            <div class="col-sm-9 row text-right">
              <app-combo-box
                size="XL"
                class="ml-3"
                [optionList]="budgetAllocationOptionList"
                formControlName="fromPeriod"
              ></app-combo-box>
              <label class="ml-3 mr-3 control-label">{{
                'budget-allocation.form.until' | translate
              }}</label>
              <app-combo-box
                size="XL"
                [optionList]="budgetAllocationOptionList"
                formControlName="untilPeriod"
              >
              </app-combo-box>
            </div>
          </div>

          <div class="button-group button-group-center mt-5">
            <app-button mode="BYPASS" color="SECONDARY" (onClick)="doReset()">
              <em class="fas fa-sync-alt"></em>
              {{ 'app.button.reset' | translate }}
            </app-button>
            <app-button
              mode="BYPASS"
              color="PRIMARY"
              (onClick)="doApply()"
              [disabled]="formSaving"
              ><em class="fas fa-check"></em>
              {{ 'app.button.apply' | translate }}
            </app-button>
          </div>
        </form>
      </app-fieldset>

      <app-card
        header="Resume"
        [isLoading]="formLoading"
        [isSaving]="formSaving"
        *ngIf="isLoading"
      >
        <form
          class="form-horizontal"
          [formGroup]="budgetInfoFormGroup"
          novalidate
        >
          <app-widget-content-slider>
            <app-widget-card>
              <h4 class="widget-card-header">{{
                'budget-allocation.widgetCard.totalBudget' | translate
              }}</h4>
              <h5
                >{{ budgetInfoFormGroup.value.totalBudgetResume }}
                {{ currencyCode == null ? 'IDR' : currencyCode }}</h5
              >
            </app-widget-card>
            <app-widget-card>
              <h4 class="widget-card-header">{{
                'budget-allocation.widgetCard.bookedBudget' | translate
              }}</h4>
              <h5
                >{{ budgetInfoFormGroup.value.bookedBudgetResume }}
                {{ currencyCode == null ? 'IDR' : currencyCode }}</h5
              >
            </app-widget-card>
            <app-widget-card>
              <h4 class="widget-card-header">{{
                'budget-allocation.widgetCard.usedBudget' | translate
              }}</h4>
              <h5
                >{{ budgetInfoFormGroup.value.usedBudgetResume }}
                {{ currencyCode == null ? 'IDR' : currencyCode }}</h5
              >
            </app-widget-card>
            <app-widget-card>
              <h4 class="widget-card-header">{{
                'budget-allocation.widgetCard.paidOffBudget' | translate
              }}</h4>
              <h5
                >{{ budgetInfoFormGroup.value.paidOffBudgetResume }}
                {{ currencyCode == null ? 'IDR' : currencyCode }}</h5
              >
            </app-widget-card>
            <app-widget-card>
              <h4 class="widget-card-header"
                >{{
                  'budget-allocation.widgetCard.availableBudget' | translate
                }}
              </h4>
              <h5
                >{{ budgetInfoFormGroup.value.availableBudgetResume }}
                {{ currencyCode == null ? 'IDR' : currencyCode }}</h5
              >
            </app-widget-card>
          </app-widget-content-slider>
        </form>
      </app-card>
      <app-card
        header="budget-allocation.title"
        [isLoading]="formLoading"
        [isSaving]="formSaving"
      >
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
          <app-tab>
            <app-tab-item header="budget-allocation.byOrganization">
              <app-table
                [model]="tableResponseOrganization"
                [isServerSide]="true"
                key="by-organization-index"
                stringUrl="/budget-allocation/organization/index"
                isShowEditTable="true"
              >
                <ng-template #headerButtons>
                  <!-- BJB -->
                  <app-button (onClick)="doSync()"
                    ><em class="fas fa-sync-alt"></em
                    >{{
                      'budget-allocation.button.sync' | translate
                    }}</app-button
                  >
                  <app-button
                    mode="BYPASS"
                    (onClick)="doExport()"
                    [disabled]="formLoading || isLoadingReport"
                    ><em
                      class="fas"
                      [ngClass]="
                        !isLoadingReport
                          ? 'fa-cloud-download'
                          : 'fa-circle-notch fa-spin'
                      "
                    ></em
                    >{{
                      'budget-allocation.button.report' | translate
                    }}</app-button
                  >
                  <!-- <app-button mode="BYPASS" 
                  (onClick)="doImport()">
                  <em class="fas fa-cloud-upload"></em>
                  {{ 'app.button.import' | translate }}
                  </app-button> -->
                  <app-button (onClick)="doAdd()"
                    ><em class="fas fa-plus"></em
                    >{{ 'app.button.add' | translate }}</app-button
                  >
                </ng-template>
                <ng-template #actionButtons let-rowData>
                  <app-button
                    size="TN"
                    class="mr-2"
                    (onClick)="doDetailOrganization(rowData)"
                  >
                    <em class="fas fa-search"></em>
                  </app-button>
                </ng-template>
              </app-table>
            </app-tab-item>
            <app-tab-item header="budget-allocation.byCoa">
              <app-table
                [model]="tableResponseCoa"
                [isServerSide]="true"
                key="by-coa-index"
                stringUrl="/budget-allocation/coa/index"
                isShowEditTable="true"
              >
                <ng-template #headerButtons>
                  <!-- BJB -->
                  <app-button
                    (onClick)="doSync()"
                    [disabled]="formLoading || isLoadingSync || isLoadingReport"
                    ><em
                      class="fas"
                      [ngClass]="
                        !isLoadingSync ? 'fa-sync-alt' : 'fa-circle-notch fa-spin'
                      "
                    ></em
                    >{{
                      'budget-allocation.button.sync' | translate
                    }}</app-button
                  >
                  <app-button
                    mode="BYPASS"
                    (onClick)="doExport()"
                    [disabled]="formLoading || isLoadingReport || isLoadingSync"
                    ><em
                    class="fas"
                    [ngClass]="
                      !isLoadingReport
                        ? 'fa-cloud-download'
                        : 'fa-circle-notch fa-spin'
                    "
                    ></em
                    >{{
                      'budget-allocation-bjb.button.report' | translate
                    }}</app-button
                  >
                  <!-- <app-button mode="BYPASS" 
                  (onClick)="doImport()">
                  <em class="fas fa-cloud-upload"></em>{{
                  'app.button.import' | translate }}
                  </app-button> -->
                  <app-button (onClick)="doAdd()"
                    ><em class="fas fa-plus"></em
                    >{{ 'app.button.add' | translate }}</app-button
                  >
                </ng-template>
                <ng-template #actionButtons let-rowData>
                  <app-button
                    mode="BYPASS"
                    *ngIf="rowData.coa.isHasChild"
                    size="TN"
                    class="mr-2"
                    (onClick)="doDetailCoa(rowData)"
                  >
                    <em class="fas fa-search"></em>
                  </app-button>
                </ng-template>
              </app-table>
            </app-tab-item>
          </app-tab>
        </form>
      </app-card>
    </app-card>
  </div>
</div>
