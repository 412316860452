import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { AppPopupEmailComponent } from '../../../core/components/app-popup/app-popup-email/app-popup-email.component';
import { AppPopupService } from '../../../core/components/app-popup/app-popup.service';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { ViewVendorComparisonDTO } from './dto/view-vendor-comparison.dto';
import { ViewVendorComparisonModel } from './model/view-vendor-comparison-model';
@Component({
  templateUrl: 'view-vendor-comparison.component.html',
  styleUrls: ['./view-vendor-comparison.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewVendorComparisonComponent extends BaseModuleComponent {
  public viewVendorComparisonModelList: ViewVendorComparisonModel[] = [];

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('view-vendor', translateService);
  }

  public onInit(): void {
    this.getAndSetComparison();
  }

  public getAndSetComparison(): void {
    const responseComparison: Response<ViewVendorComparisonDTO> =
      this.global.routerParams.get('responseComparison');
    this.viewVendorComparisonModelList =
      responseComparison.body.viewVendorComparisonModelList;
  }

  public doDelete(index: number): void {
    this.viewVendorComparisonModelList.splice(index, 1);
  }

  public doOpenPopupEmail(vendorPICEmails: string): void {
    const url = '/view-vendor/send-email';
    const to = vendorPICEmails;
    this.appPopupService
      .open(AppPopupEmailComponent, { url, to })
      .subscribe((response: Response<any>) => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccess(
            this.translateService.instant('view-vendor.alert.msg.sendSuccess')
          );
        } else {
          this.global.alertService.showError(
            this.translateService.instant('view-vendor.alert.msg.sendError')
          );
        }
      });
  }

  public doBack(): void {
    this.router.navigate(['/pages/view-vendor']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
