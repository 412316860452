import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { CommitteeEditAddComponent } from './committee-edit-add.component';
import { CommitteeComponent } from './committee.component';

const routes = [
  {
    path: '',
    component: CommitteeComponent,
    data: { breadcrumb: '' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: CommitteeEditAddComponent,
    data: { breadcrumb: 'pr.breadcrumb.add' }
  },
  {
    path: 'edit',
    component: CommitteeEditAddComponent,
    data: { breadcrumb: 'pr.breadcrumb.detail' }
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [CommitteeComponent, CommitteeEditAddComponent]
})
export class CommitteeModule {}
