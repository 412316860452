import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { CertificateStatus } from '../../core/bean/certificate-status';
import { Qualification } from '../../core/bean/qualification';
import { Vendor } from '../../core/bean/vendor';
import { VendorActivityStatus } from '../../core/bean/vendor-activity-status';
import { VendorCategory } from '../../core/bean/vendor-category';
import { VendorStatus } from '../../core/bean/vendor-status';
import { VendorType } from '../../core/bean/vendor-type';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { RecapPrintService } from '../../core/services/recap-print.service';
import { VendorListResponse } from './vendor-list-response';
@Component({
  templateUrl: './vendor-list.component.html',
  styles: [
    '.width-company-name { min-width : 250px; white-space: normal ! important }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class VendorListComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Vendor>;
  public customData: string = null;
  public isLoading: boolean;
  public vendorTypeOptionList: OptionListModel<VendorType> = new OptionListModel(
    true
  );
  public vendorStatusOptionList: OptionListModel<VendorStatus> = new OptionListModel(
    true
  );
  public certificateStatusOptionList: OptionListModel<CertificateStatus> = new OptionListModel(
    true
  );
  public vendorCategoryOptionList: OptionListModel<VendorCategory> = new OptionListModel(
    true
  );
  public qualificationOptionList: OptionListModel<Qualification> = new OptionListModel(
    true
  );
  public vendorActivityStatusOptionList: OptionListModel<VendorActivityStatus> = new OptionListModel(
    true
  );
  constructor(
    translateService: TranslateService,
    public activatedRoute: ActivatedRoute,
    public recapPrintService: RecapPrintService
  ) {
    super('vendor-list', translateService);
  }

  public onInit(): void {
    this.setOptionList();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setCustomDataFromWidget();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorTypeList: [null],
      vendorStatus: [null],
      certificateStatusList: [null],
      segmentationList: [null],
      vendorCategoryList: [null],
      regionList: [null],
      qualificationList: [null],
      vendorActivityStatusList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<VendorListResponse>(
        '/vendor-list/get-option-list',
        {}
      )
      .subscribe((vendorListResponse: VendorListResponse) => {
        const vendorStatus: VendorStatus = new VendorStatus();
        vendorStatus.id = null;
        vendorStatus.name = this.translateService.instant(
          'vendor-list.filter.all'
        );

        let vendorStatusList: VendorStatus[] = [];
        vendorStatusList.push(vendorStatus);
        vendorStatusList = vendorStatusList.concat(
          vendorListResponse.vendorStatusList
        );

        vendorListResponse.vendorCategoryList.forEach(vendorCategory => {
          vendorCategory.name =
            vendorCategory.name + ' (' + vendorCategory.vendorType.name + ')';
        });

        this.vendorStatusOptionList.setRequestValues(vendorStatusList);
        this.vendorTypeOptionList.setRequestValues(
          vendorListResponse.vendorTypeList
        );
        this.certificateStatusOptionList.setRequestValues(
          vendorListResponse.certificateStatusList
        );
        this.vendorCategoryOptionList.setRequestValues(
          vendorListResponse.vendorCategoryList
        );
        this.qualificationOptionList.setRequestValues(
          vendorListResponse.qualificationList
        );
        this.vendorActivityStatusOptionList.setRequestValues(
          vendorListResponse.vendorActivityStatusList
        );
      });
  }

  public doApply(): void {
    this.global.routerParams.clear();
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }

  public setCustomDataFromWidget(): void {
    const customData = {
      statusCode: this.global.routerParams.get('vendorStatusCodeFromWidget')
    };

    this.tableResponse.setCustomData(customData);
    this.global.routerParams.clear();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name', customClass: 'text-left' },
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      { field: 'vendorType.name', header: 'table.column.vendorType' },
      { field: 'email', header: 'table.column.email' },
      {
        field: 'vendorActivityStatus.name',
        header: 'table.column.vendorActivityStatus',
        customClass: 'text-center'
      },
      {
        field: 'vendorStatus.name',
        header: 'table.column.status',
        customClass: 'text-center'
      }
    ]);
  }

  public doView(vendor: Vendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('vendorId', vendor.id);
    this.router.navigate(['/pages/vendor-list/detail']);
  }

  public doViewVendor(vendor: Vendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('vendorId', vendor.id);
    this.global.routerParams.set('isGuest', true);
    this.global.routerParams.set('isVendorList', true);
    this.global.routerParams.set('urlBackOutside', '/pages/vendor-list');
    this.router.navigate(['/pages/vendor-list/detail-vendor']);
  }

  public doExport(): void {
    this.isLoading = true;
    this.recapPrintService
      .print('/vendor-list/recap-vendor-list', this.tableResponse.request)
      .subscribe(() => {
        this.isLoading = false;
      });
  }
}
