import { Catalog } from 'src/app/core/bean/catalog';
import { Pr } from 'src/app/core/bean/pr';
import { PrVerification } from 'src/app/core/bean/pr-verification';

export class PrVerificationRequest {
  prVerification: PrVerification = new PrVerification();
  pr: Pr = new Pr();
  isSubmit: boolean;
  catalogList: Catalog[] = [];
}
