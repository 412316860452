import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Contract } from 'src/app/core/bean/contract';
import { PaymentStatus } from 'src/app/core/bean/payment-status';
import { Procurement } from 'src/app/core/bean/procurement';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { AppPopupService } from './../../core/components/app-popup/app-popup.service';
import { Response } from './../../core/model/response-model';
import { PaymentBillingView } from './../../core/view/entity/bean/payment-billing-view';

@Component({
  templateUrl: './payment-request-review.component.html'
})
export class PaymentRequestReviewComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<PaymentBillingView>;

  public procurementOptionList: OptionListModel<Procurement> =
    new OptionListModel(true);
  public agreementOptionList: OptionListModel<Contract> = new OptionListModel(
    true,
    'number'
  );
  public paymentStatusOptionList: OptionListModel<PaymentStatus> =
    new OptionListModel(true);

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('payment-request-review', translateService);
  }

  public onInit(): void {
    this.setOptionList();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
    localStorage.removeItem('temp');
    localStorage.removeItem('todo');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      startDate: [null],
      endDate: [null],
      agreementList: [null],
      procurementList: [null],
      paymentStatusList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<PaymentStatus>('/payment-request-review/get-option-list')
      .subscribe((response: PaymentStatus[]) => {
        this.paymentStatusOptionList.setRequestValues(response);

        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'paymentInvoice.code',
        header: 'table.column.invoiceNumber'
      },
      {
        field: 'paymentInvoice.invoiceDate',
        header: 'table.column.invoiceDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'vendor.name',
        header: 'table.column.vendorName'
      },
      {
        field: 'code',
        header: 'table.column.orderNumber'
      },
      {
        field: 'title',
        header: 'table.column.title'
      },
      {
        field: 'amount',
        header: 'table.column.amount',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'payment.paymentStatus.name',
        header: 'table.column.paymentStatus'
      }
    ]);
  }

  public doApply(): void {
    this.global.routerParams.clear();
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }

  public doClick(paymentBillingView: PaymentBillingView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('paymentId', paymentBillingView.payment.id);
    this.global.routerParams.set('payment', paymentBillingView.payment);
    this.global.routerParams.set('vendor', paymentBillingView.vendor);
    this.global.routerParams.set('paymentBillingView', paymentBillingView);
    this.global.routerParams.set(
      'paymentInvoice',
      paymentBillingView.paymentInvoice
    );
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/payment-request-review'
    );
    if (
      paymentBillingView.payment.paymentWorklistStatus.code !==
      this.global.appConstant.cm.PAYMENT_WORKLIST_STATUS_WAITING_APPROVAL
    ) {
      this.global.routerParams.set('todo', 'edit');
      this.router.navigate(['/pages/payment-request-review/edit']);
    } else {
      this.global.routerParams.set('todo', 'view');
      this.router.navigate(['/pages/payment-request-review/detail']);
    }
  }

  public doEdit(paymentBillingView: PaymentBillingView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('paymentId', paymentBillingView.payment.id);
    this.global.routerParams.set('payment', paymentBillingView.payment);
    this.global.routerParams.set('vendor', paymentBillingView.vendor);
    this.global.routerParams.set('paymentBillingView', paymentBillingView);
    this.global.routerParams.set(
      'paymentInvoice',
      paymentBillingView.paymentInvoice
    );
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/payment-request-review'
    );
    this.router.navigate(['/pages/payment-request-review/edit']);
  }

  public doView(paymentBillingView: PaymentBillingView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('paymentId', paymentBillingView.payment.id);
    this.global.routerParams.set('payment', paymentBillingView.payment);
    this.global.routerParams.set('vendor', paymentBillingView.vendor);
    this.global.routerParams.set('paymentBillingView', paymentBillingView);
    this.global.routerParams.set(
      'paymentInvoice',
      paymentBillingView.paymentInvoice
    );
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/payment-request-review'
    );
    this.router.navigate(['/pages/payment-request-review/detail']);
  }

  public doDelete(paymentBillingView: PaymentBillingView): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<PaymentBillingView>>(
              '/payment-request-review/delete',
              paymentBillingView
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
