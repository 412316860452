import { AppDynamicComponentService } from '../../../services/app-dynamic-component.service';
import { ArrayUtils } from '../../../utils';
import { AppBadgeComponent } from '../../app-badge/app-badge.component';
import { BadgePlugin } from '../interfaces/available-table-plugin';
import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';
export const AppTableBadgePlugin: TablePlugin = {
  name: 'badge',
  before: (data: TablePluginData): void => {
    const badgePlugin: BadgePlugin = data.column.plugins as BadgePlugin;
    const appDynamicComponent = data.state.injector.get(
      AppDynamicComponentService
    );
    const colorValue = badgePlugin.field
      ? ArrayUtils.resolveFieldData(data.record.record, badgePlugin.field)
      : data.value;
    appDynamicComponent.create(AppBadgeComponent, data.element, {
      customClass: badgePlugin.className || '',
      color: badgePlugin.colorMap[colorValue],
      text: data.value
    });
  }
};
