<app-popup
  header="app-contract-document.popup.uploadSignedContract.title"
  [isShowHeaderCloseButton]="false"
  [isLoading]="formLoading"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-left">
      <label class="col-sm-3"
        >{{ 'app-contract-document.form.signedContract' | translate
        }}<span class="ml-2">
          <app-tooltip
            position="BOTTOM"
            style="position: absolute; margin-top: 0.3%"
          >
            <em
              class="pir pi-info-circle float-right"
              style="color: 'var(--primary)'"
            ></em>
            <ng-template #tooltipContent>
              <div style="width: 150px; padding: 5px">
                {{ 'app-contract-document.tooltip.signedContract' | translate }}
              </div>
            </ng-template>
          </app-tooltip>
        </span>
      </label>

      <div class="col-sm-9 text-left">
        <app-upload-x
          formControlName="file"
          size="LG"
          [fileUploader]="fileUploader"
        ></app-upload-x>
      </div>
    </div>

    <div class="form-group row text-left">
      <label class="col-sm-3 optional">{{
        'app-contract-document.form.note' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-area
          formControlName="description"
          maxLength="512"
          size="XL"
        ></app-text-area>
      </div>
    </div>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center">
      <app-button
        [outline]="true"
        color="SECONDARY"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.cancel' | translate }}</app-button
      >

      <app-button color="PRIMARY" (onClick)="doSave()">{{
        'app.button.save' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
