import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupTabWanprestasiHistoryComponent } from './app-popup-tab-wanprestasi-history.component';
import { TabWanprestasiHistoryComponent } from './tab-wanprestasi-history.component';
@NgModule({
  imports: [SharedModule],
  declarations: [
    TabWanprestasiHistoryComponent,
    AppPopupTabWanprestasiHistoryComponent
  ],
  entryComponents: [
    TabWanprestasiHistoryComponent,
    AppPopupTabWanprestasiHistoryComponent
  ],
  exports: [
    TabWanprestasiHistoryComponent,
    AppPopupTabWanprestasiHistoryComponent
  ]
})
export class TabWanprestasiHistoryModule {
  constructor(accordionService: AccordionService) {
    accordionService.register(
      'tab-wanprestasi-history',
      TabWanprestasiHistoryComponent
    );
  }
}
