import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Aanwijzing } from 'src/app/core/bean/aanwijzing';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { WindowPrintService } from 'src/app/core/services/window-print.service';

@Component({
    templateUrl: './aanwijzing-popup-show-qr.component.html',
    styleUrls: ['./aanwijzing-popup-show-qr.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AanwijzingPopupShowQRComponent extends BaseComponentComponent {

    @Input() public aanwijzing: Aanwijzing;
    constructor(public translateService: TranslateService,
                public appPopupService: AppPopupService,
                public windowPrintService: WindowPrintService) {
        super('aanwijzing');
    }

    public onInit(): void {
        this.setStateReady();
    }

    public doPrint(): void {
        this.windowPrintService.print().print(document.getElementsByClassName('print-tutorial'));
    }

    public getTranslateKey(data): string {
        if (data?.translationKey) {
            return data.translationKey.module.code.toLowerCase() + '.' + data.translationKey.key;
        } else {
            return data?.name;
        }
    }

}
