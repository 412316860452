import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupAdjustmentSpendingComponent } from './app-popup-adjustment-spending.component';
import { SlaSpendingDetailComponent } from './sla-spending-detail.component';
import { SlaSpendingComponent } from './sla-spending.component';

export const routes = [
  { path: '', component: SlaSpendingComponent, data: { breadcrumb: '' } },
  {
    path: 'detail',
    component: SlaSpendingDetailComponent,
    data: { breadcrumb: 'sla-spending.breadcrumb.detail' }
  },
  {
    path: 'app-popup-adjustment-spending',
    component: AppPopupAdjustmentSpendingComponent,
    data: { breadcrumb: 'sla-spending.breadcrumb.adjustment' }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    SlaSpendingComponent,
    SlaSpendingDetailComponent,
    AppPopupAdjustmentSpendingComponent
  ],
  providers: []
})
export class SlaSpendingModule {}
