<div class="catalog-item">
  <div class="catalog-item-image">
    <img
      [attr.src]="
        global.config.BACKEND_ADDRESS +
          '/view-catalog/file/view/' +
          model[2].file?.uploadedFileName +
          '/' + global.appConstant.fileType.IMG_ITEM_CATALOG
          | resolveImgSrc
          | async
      "
      (load)="onLoadImage($event)"
      [attr.alt]="model[0].name"
      width="auto"
      height="auto"
    />
  </div>
  <h5
    class="catalog-item-name cursor-pointer my-3"
    (click)="onChooseItem.emit(model[0].id)"
    (keyup)="onKeyUp($event)"
    (keydown)="onKeyDown($event)"
    >{{ model[0].name }}</h5
  >
  <p class="catalog-item-location">{{
    model[1].cityRegion?.name
      ? model[1].provinceRegion?.name + ', ' + model[1].cityRegion?.name
      : model[1].region?.name
  }}</p>
  <p class="catalog-item-vendor-name">{{
    model[0].vendor?.vendorEntity.name + '
    ' + model[0].vendor?.name
  }}</p>
  <p class="catalog-item-price">{{
    (model[0].price | currency: '':'') + ' ' + model[0].catalogRegion?.currency?.code
  }}</p>
  <div class="button-group button-group-center mt-3">
    <app-button size="SM" (onClick)="onChooseItem.emit(model[0].id)">{{
      'view-catalog.viewProduct' | translate
    }}</app-button>
  </div>
</div>
