<div class="text-center py-4" *ngIf="formLoading">
  <img src="assets/img/common/loader-small.gif" alt="Loading" />
</div>
<form
  class="form-horizontal"
  [formGroup]="formGroup"
  novalidate
  *ngIf="!formLoading"
>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'app-billing-memo-information-view.form.number' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{ billingMemo.number }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'app-billing-memo-information-view.form.date' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{ billingMemo.date | date: global.appConstant.core.FORMAT_SHORT_DATE }}
    </div>
  </div>

  <h5 class="separator">{{
    'app-billing-memo-information-view.separator.procurementValue' | translate
  }}</h5>

  <div class="form-group row text-sm-left required">
    <div class="col-sm-6">
      <!-- <app-check-box formControlName="isIncludeVAT" position="HORIZONTAL"> -->
      <label *ngIf="billingMemo.isIncludeVAT">{{
        'app-billing-memo-information-view.form.procurementValue' | translate
      }}</label>
      <!-- </app-check-box> -->
      <label *ngIf="!billingMemo.isIncludeVAT">{{
        'app-billing-memo-information-view.form.notProcurementValue' | translate
      }}</label>
    </div>
  </div>
  <h6 class="separator">{{
    'app-billing-memo-information-view.separator.procurementValue' | translate
  }}</h6>

  <div
    class="table-responsive custom-table float-none"
    [ngStyle]="{ margin: '0 auto' }"
  >
    <table class="table table-striped box-shadow" aria-describedby="Table">
      <thead>
        <tr class="text-center">
          <th scope="col">{{ 'app.table.column.no' | translate }}</th>
          <th scope="col">
            {{
              'app-billing-memo-information-view.table.column.coa' | translate
            }}
          </th>
          <th scope="col">
            {{
              'app-billing-memo-information-view.table.column.costCenter'
                | translate
            }}
          </th>
          <th scope="col" class="text-right">
            {{
              'app-billing-memo-information-view.table.column.taxPercentageValue'
                | translate
            }}
          </th>
          <th scope="col" class="text-right">
            {{
              'app-billing-memo-information-view.table.column.taxBaseAmount'
                | translate
            }}
          </th>
          <th scope="col" class="text-right">
            {{
              'app-billing-memo-information-view.table.column.vatAmount'
                | translate
            }}
          </th>
          <th scope="col" class="text-right">
            {{
              'app-billing-memo-information-view.table.column.totalAmount'
                | translate
            }}
          </th>
          <th scope="col">{{ 'app.table.column.action' | translate }} </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="billingMemoItemList.length == 0">
          <td colspan="99" class="bg-white text-center">
            {{ 'app.info.noData' | translate }}
          </td>
        </tr>
        <tr *ngFor="let billingMemo of billingMemoItemList; let i = index">
          <td class="text-center">{{ i + 1 }}.</td>
          <td class="text-center">
            {{ billingMemo.budgetAllocation?.coa.code }}
            &nbsp;<em
              class="fas fa-eye cursor-pointer"
              (click)="doDetailBudget(billingMemo.budgetAllocation)"
            ></em>
          </td>
          <td class="text-center">
            {{ billingMemo.budgetAllocation?.costCenter.name || '-' }}
          </td>
          <td class="text-right">
            {{ billingMemo.taxPercentageValue | currency: ' ' }}
          </td>
          <td class="text-right">
            {{
              billingMemo.taxBaseAmount
                ? global.converterService.convertMoney(
                    billingMemo.taxBaseAmount
                  )
                : 0
            }}
            IDR
          </td>
          <td class="text-right">
            {{
              billingMemo.vatAmount
                ? global.converterService.convertMoney(billingMemo.vatAmount)
                : 0
            }}
            IDR
          </td>
          <td class="text-right">
            {{
              billingMemo.totalAmount
                ? global.converterService.convertMoney(billingMemo.totalAmount)
                : 0
            }}
            IDR
          </td>
          <td class="text-center">
            <app-button
              size="TN"
              (onClick)="doViewBudget(billingMemo)"
              title="{{ 'app.tooltip.detail' | translate }}"
            >
              <em class="fas fa-search"></em>
            </app-button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-center">
            <strong>
              {{
                'app-billing-memo-information-view.table.column.totalAmountItem'
                  | translate
              }}</strong
            >
          </td>
          <td class="text-right">
            <strong>
              {{
                appBillingMemoInformationViewResponse.totalAmountItem
                  ? global.converterService.convertMoney(
                      appBillingMemoInformationViewResponse.totalAmountItem
                    )
                  : 0
              }}
              IDR</strong
            >
          </td>
        </tr>
      </tfoot>
    </table>
  </div>

  <h6 class="separator">{{
    'app-billing-memo-information-view.separator.incomeTax' | translate
  }}</h6>
  <div
    class="table-responsive custom-table float-none"
    [ngStyle]="{ margin: '0 auto' }"
  >
    <table class="table table-striped box-shadow" aria-describedby="Table">
      <thead>
        <tr class="text-center">
          <th scope="col">{{ 'app.table.column.no' | translate }}</th>
          <th scope="col" class="text-left">
            {{
              'app-billing-memo-information-view.table.column.tax' | translate
            }}
          </th>
          <th scope="col">
            {{
              'app-billing-memo-information-view.table.column.coa' | translate
            }}
          </th>
          <th scope="col">
            {{
              'app-billing-memo-information-view.table.column.costCenter'
                | translate
            }}
          </th>
          <th scope="col" class="text-right">
            {{
              'app-billing-memo-information-view.table.column.taxPercentageValue'
                | translate
            }}
          </th>
          <th scope="col" class="text-right">
            {{
              'app-billing-memo-information-view.table.column.taxBaseAmount'
                | translate
            }}
          </th>
          <th scope="col" class="text-right">
            {{
              'app-billing-memo-information-view.table.column.incomeTaxAmount'
                | translate
            }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="billingMemoTaxList.length == 0">
          <td colspan="99" class="bg-white text-center">
            {{ 'app.info.noData' | translate }}
          </td>
        </tr>
        <tr *ngFor="let billingMemoTax of billingMemoTaxList; let i = index">
          <td class="text-center">{{ i + 1 }}.</td>
          <td>
            {{ billingMemoTax.tax.name }}
          </td>
          <td class="text-center">
            {{ billingMemoTax.tax.coaCode }}
          </td>
          <td class="text-center">
            {{ billingMemoTax.costCenter?.name || '-' }}
          </td>
          <td class="text-right">
            {{ billingMemoTax.taxPercentageValue | currency: ' ' }}
          </td>
          <td class="text-right">
            {{
              billingMemoTax.taxBaseAmount
                ? global.converterService.convertMoney(
                    billingMemoTax.taxBaseAmount
                  )
                : 0
            }}
            IDR
          </td>
          <td class="text-right">
            {{
              billingMemoTax.incomeTaxAmount
                ? global.converterService.convertMoney(
                    billingMemoTax.incomeTaxAmount
                  )
                : 0
            }}
            IDR
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-center">
            <strong>
              {{
                'app-billing-memo-information-view.table.column.total'
                  | translate
              }}</strong
            >
          </td>
          <td class="text-right">
            <strong>
              {{
                appBillingMemoInformationViewResponse.incomeTaxTotal
                  ? global.converterService.convertMoney(
                      appBillingMemoInformationViewResponse.incomeTaxTotal
                    )
                  : 0
              }}
              IDR</strong
            >
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <h6 class="separator">{{
    'app-billing-memo-information-view.separator.otherCost' | translate
  }}</h6>
  <div
    class="table-responsive custom-table float-none"
    [ngStyle]="{ margin: '0 auto' }"
  >
    <table class="table table-striped box-shadow" aria-describedby="Table">
      <thead>
        <tr class="text-center">
          <th scope="col">{{ 'app.table.column.no' | translate }}</th>
          <th scope="col" class="text-left">
            {{
              'app-billing-memo-information-view.table.column.name' | translate
            }}
          </th>
          <th scope="col">
            {{
              'app-billing-memo-information-view.table.column.coa' | translate
            }}
          </th>
          <th scope="col">
            {{
              'app-billing-memo-information-view.table.column.costCenter'
                | translate
            }}
          </th>
          <th scope="col" class="text-right">
            {{
              'app-billing-memo-information-view.table.column.amount'
                | translate
            }}
          </th>
          <th scope="col">
            {{
              'app-billing-memo-information-view.table.column.transactionType'
                | translate
            }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="billingMemoOtherCostList.length == 0">
          <td colspan="99" class="bg-white text-center">
            {{ 'app.info.noData' | translate }}
          </td>
        </tr>
        <tr
          *ngFor="
            let billingMemoOtherCost of billingMemoOtherCostList;
            let i = index
          "
        >
          <td class="text-center">{{ i + 1 }}.</td>
          <td class="text-left">
            {{ billingMemoOtherCost.name }}
          </td>
          <td class="text-center">
            {{ billingMemoOtherCost.budgetAllocation?.coa.code || '-' }}
          </td>
          <td class="text-center">
            {{ billingMemoOtherCost.budgetAllocation?.costCenter.name || '-' }}
          </td>
          <td class="text-right">
            {{
              billingMemoOtherCost.amount
                ? global.converterService.convertMoney(
                    billingMemoOtherCost.amount
                  )
                : 0
            }}
            IDR
          </td>
          <td class="text-center">
            {{
              billingMemoOtherCost.transactionType === 1
                ? global.translateService.instant('billing-memo.status.debit')
                : global.translateService.instant('billing-memo.status.credit')
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</form>
