import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';
import { CommitteeParticipant } from '../../bean/committee-participant';
import { CommitteePosition } from '../../bean/committee-position';
import { User } from '../../bean/user';
import { AppCommitteeResponse } from './app-committee-response';

@Component({
  templateUrl: './app-popup-add-committee.component.html'
})
export class AppPopupAddCommitteeComponent extends BaseComponentComponent {
  @Input() public treeList: CommitteeParticipant[];
  @Input() public appCommitteeResponse: AppCommitteeResponse;
  @Input() public committeeParticipantEdit: CommitteeParticipant;
  @Input() todo: string;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  public positionOptionList: OptionListModel<CommitteePosition> =
    new OptionListModel();
  public userOptionList: OptionListModel<User> = new OptionListModel();
  public isUserNonPromise = false;

  constructor(public ngbActiveModal: NgbActiveModal) {
    super('app-committee');
  }

  onInit(): void {
    this.setOptionModel();
    this.buildFormGroup();
    this.setFormGroup();
    this.setStateReady();
  }

  public setOptionModel(): void {
    if (this.treeList && this.treeList.length > 0) {
      const headIndex = this.treeList.findIndex(
        x => x.committeePosition.level === 1
      );
      const viceHeadIndex = this.treeList.findIndex(
        x => x.committeePosition.level === 2
      );
      if (headIndex !== -1) {
        const positionList = [];
        this.appCommitteeResponse.committeePositionList.forEach(position => {
          positionList.push(position);
        });
        positionList.splice(headIndex, 1);
        this.positionOptionList.setRequestValues(positionList);
      }
      if (viceHeadIndex !== -1) {
        const positionList = [];
        this.appCommitteeResponse.committeePositionList.forEach(position => {
          positionList.push(position);
        });
        positionList.splice(viceHeadIndex, 1);
        this.positionOptionList.setRequestValues(positionList);
      }

      const userList = this.appCommitteeResponse.userList;
      this.treeList.forEach(committeeParticipant => {
        const index = userList.findIndex(x => x === committeeParticipant.user);
        userList.splice(index, 1);
      });
      this.userOptionList.setRequestValues(userList);
    } else {
      this.positionOptionList.setRequestValues(
        this.appCommitteeResponse.committeePositionList
      );
      this.userOptionList.setRequestValues(this.appCommitteeResponse.userList);
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      position: [null, Validators.required()],
      user: [null],
      userList: [null],
      name: [null],
      email: [null],
      organization: [null],
      organizationList: [null]
    });
  }

  public setFormGroup(): void {
    const user = this.formGroup.get('user');
    const userList = this.formGroup.get('userList');

    if (this.todo !== 'add') {
      const organizationList = [];
      organizationList.push(this.committeeParticipantEdit.organization);
      this.formGroup.patchValue({
        id: this.committeeParticipantEdit.id,
        position: this.committeeParticipantEdit.committeePosition,
        user: this.committeeParticipantEdit.user,
        name: this.committeeParticipantEdit.name,
        email: { name: this.committeeParticipantEdit.email },
        organization: this.committeeParticipantEdit.organization,
        organizationList:
          this.appCommitteeResponse.organizationList || organizationList
      });

      if (!this.committeeParticipantEdit.user) {
        this.isUserNonPromise = true;
        user.clearValidators();
        user.updateValueAndValidity();
      } else {
        user.setValidators(Validators.required());
        user.updateValueAndValidity();
      }
    } else {
      userList.setValidators(Validators.required());
      userList.updateValueAndValidity();
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      const committeeParticipantList = [];
      const committeePosition = this.formGroup.get('position').value;
      const userList = this.formGroup.get('userList').value;
      if (userList && userList.length > 0) {
        userList.forEach(user => {
          const committeeParticipant: CommitteeParticipant =
            new CommitteeParticipant();
          committeeParticipant.committeePosition = committeePosition;
          committeeParticipant.user = user;
          committeeParticipantList.push(committeeParticipant);
        });
      } else if (!this.formGroup.get('user').value) {
        const committeeParticipant: CommitteeParticipant =
          new CommitteeParticipant();
        committeeParticipant.committeePosition = committeePosition;
        committeeParticipant.name = this.formGroup.get('name').value;
        committeeParticipant.email = this.formGroup.get('email').value.name;
        committeeParticipant.organization =
          this.formGroup.get('organizationList').value[
            this.formGroup.get('organizationList').value.length - 1
          ];
        committeeParticipantList.push(committeeParticipant);
        this.appCommitteeResponse.organizationList =
          this.formGroup.get('organizationList').value;
      } else {
        const committeeParticipant: CommitteeParticipant =
          new CommitteeParticipant();
        committeeParticipant.committeePosition = committeePosition;
        committeeParticipant.user = this.formGroup.get('user').value;
        committeeParticipantList.push(committeeParticipant);
      }
      this.onChange.emit(committeeParticipantList);
      this.ngbActiveModal.close();
    }
  }

  public doReplace(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.committeeParticipantEdit.committeePosition =
        this.formGroup.get('position').value;
      this.committeeParticipantEdit.user = this.formGroup.get('user').value;
      if (!this.formGroup.get('user').value) {
        this.committeeParticipantEdit.name = this.formGroup.get('name').value;
        this.committeeParticipantEdit.email =
          this.formGroup.get('email').value.name;
        this.committeeParticipantEdit.organization =
          this.formGroup.get('organizationList').value[
            this.formGroup.get('organizationList').value.length - 1
          ];
        this.appCommitteeResponse.organizationList =
          this.formGroup.get('organizationList').value;
      }
      this.onChange.emit(this.committeeParticipantEdit);
      this.ngbActiveModal.close();
    }
  }

  public doAddNew(): void {
    this.isUserNonPromise = true;
    const user = this.formGroup.get('user');
    const organization = this.formGroup.get('organizationList');
    const name = this.formGroup.get('name');
    const email = this.formGroup.get('email');
    const userList = this.formGroup.get('userList');

    userList.clearValidators();
    user.clearValidators();
    organization.setValidators(Validators.required());
    name.setValidators(Validators.required());
    email.setValidators(Validators.required());
    userList.updateValueAndValidity();
    user.updateValueAndValidity();
    organization.updateValueAndValidity();
    name.updateValueAndValidity();
    email.updateValueAndValidity();
  }

  public onChangeEmail(): void {
    const user = this.formGroup.get('user');
    const organization = this.formGroup.get('organizationList');
    const name = this.formGroup.get('name');
    const email = this.formGroup.get('email');
    const userList = this.formGroup.get('userList');

    if (email.value?.id) {
      userList.clearValidators();
      user.clearValidators();
      organization.setValidators(Validators.required());
      name.setValidators(Validators.required());
      email.setValidators(Validators.required());
      userList.updateValueAndValidity();
      user.updateValueAndValidity();
      organization.updateValueAndValidity();
      name.updateValueAndValidity();
      email.updateValueAndValidity();

      user.patchValue(this.formGroup.get('email').value);
      name.patchValue(user.value.name);
      name.setIsView(true);
      const organizationList = [];
      if (user.value.organization) {
        organizationList.push(user.value.organization);
      }
      organization.patchValue(organizationList);
      organization.setIsView(true);
      email.patchValue({ name: email.value.email });
      email.updateValueAndValidity();
    } else {
      if (this.todo === 'add') {
        userList.setValidators(Validators.required());
        userList.updateValueAndValidity();
      }
      user.setValidators(Validators.required());
      organization.clearValidators();
      name.clearValidators();
      email.clearValidators();
      user.updateValueAndValidity();
      organization.updateValueAndValidity();
      name.updateValueAndValidity();
      email.updateValueAndValidity();

      user.setIsView(false);
      name.setIsView(false);
      organization.setIsView(false);
    }
  }
}
