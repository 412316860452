import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Optional,
  ViewChild
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { take } from 'rxjs/operators';
import * as uploadAction from '../../actions/upload-file.action';
import {
  BaseUploadComponent,
  makeUploaderProvider
} from '../../base/base-upload.component';
import { UploadDragAndDropEvent } from '../../events';
import { UploaderHelpers } from '../../helpers/upload-helpers';
import { UploadMapper } from '../../mapper/upload-mapper';
import { UploaderFile } from '../../model';
import { Uploader } from '../../model/uploader';
import { UploadService } from '../../upload.service';
@Component({
  selector: 'app-upload-x',
  templateUrl: './app-upload-x.component.html',
  styleUrls: ['./app-upload-x.component.scss'],
  providers: [...makeUploaderProvider(AppUploadXComponent)]
})
export class AppUploadXComponent
  extends BaseUploadComponent
  implements AfterViewChecked
{
  @Input() public isSingle: boolean;
  public isUploading = false;
  public progressValue = 0;
  public isDragOver: boolean;
  public totalNewUploaded: number;
  @ViewChild('iconCloudUploadElement') iconCloudUploadElement: ElementRef;

  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    uploadService: UploadService,
    private cdf: ChangeDetectorRef
  ) {
    super('app-upload', controlContainer, elementRef, uploadService);
  }

  ngAfterViewChecked(): void {
    this.cdf.detectChanges();
  }

  public onInitBaseUpload(): void {
    this.doSetModelOptions();
    this.uploadService
      .setUploader(this.model, this.formControl)
      .subscribe((uploader: Uploader) => {
        const values = UploadMapper.toValues(uploader);
        this.formControl.patchValue(values);
        this.doSetFileListToFileUploader();
        this.doSetIsUploading(uploader);
        this.onChange.emit(values);
      });
  }

  private doSetModelOptions(): void {
    this.model.isHandleError = false;
    this.isSingle =
      typeof this.isSingle === 'undefined' ? false : this.isSingle;
    if (this.isSingle) {
      this.model.options = { maxFile: 1 };
    } else {
      const maxFile = this.model.options.maxFile || 1;
      this.model.options = { maxFile };
    }
  }

  private doSetIsUploading(uploader: Uploader): void {
    let isOnProgress = false;
    uploader.uploaderFileList.forEach((uploaderFile: UploaderFile) => {
      if (!isOnProgress && uploaderFile.status === 'ONPROGRESS') {
        isOnProgress = true;
      }
    });
    this.isUploading = this.isUploading ? isOnProgress : this.isUploading;
  }

  public onUploadChange(event: any): void {
    this.progressValue = 0;
    this.model.error = null;
    this.isUploading = true;
    this.totalNewUploaded = event.target.files.length;
    this.uploadService.dispatch(
      new uploadAction.AddFile({ fileList: event.target.files })
    );
    event.target.value = null;
  }

  public doDeleteFile(index?: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          typeof index !== 'undefined'
            ? this.uploadService.dispatch(
                new uploadAction.DeleteFile({ index })
              )
            : this.uploadService.dispatch(new uploadAction.BulkDeleteFile());
        }
      });
  }

  public doReupload(uploaderFile: UploaderFile): void {
    this.uploadService.dispatch(new uploadAction.ReUpload({ uploaderFile }));
  }

  public doCancelUpload(uploaderFile: UploaderFile): void {
    this.uploadService.dispatch(
      new uploadAction.CancelUpload({ uploaderFile })
    );
  }

  public doDownloadFile(event: MouseEvent, uploaderFile: UploaderFile): void {
    if (uploaderFile.file) {
      event.preventDefault();
      event.stopPropagation();
      this.downloadService.download(
        UploaderHelpers.getFileUrlWithFileUploader(this.global, uploaderFile),
        uploaderFile.file.fileName
      );
    }
  }

  public onClick(): void {
    this.isUploading = false;
    this.model.error = null;
  }

  public onDragAndDropChange(
    uploadDragAndDropEvent: UploadDragAndDropEvent
  ): void {
    switch (uploadDragAndDropEvent.type) {
      case 'ON-DRAGOVER':
        this.isDragOver = true;
        break;
      case 'ON-DRAGLEAVE':
        this.isDragOver = false;
        break;
      case 'ON-DROP':
        this.progressValue = 0;
        this.isDragOver = false;
        this.totalNewUploaded = uploadDragAndDropEvent.fileList.length;
        this.uploadService.dispatch(
          new uploadAction.AddFile({
            fileList: uploadDragAndDropEvent.fileList
          })
        );
        break;
      default:
        break;
    }
  }
}
