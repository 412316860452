import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appInteger]'
})
export class AppIntegerDirective {
  @Input() appInteger: string;
  @Input() pattern: string;

  constructor(public ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  handleInput(event: any): void {
    const strRegex = this.appInteger
      ? '[^0-9' + this.appInteger + ']'
      : '[^0-9]';
    const regex = new RegExp(strRegex, 'g');
    event.target.value = event.target.value.replace(
      this.pattern ? this.pattern : regex,
      ''
    );
    this.ngControl.control.patchValue(event.target.value);
  }
}
