<app-popup header="Example Popup">
  <app-table [tableResponse]="tableResponse">
    <ng-template #actionHeader>
      <input
        type="checkbox"
        [(ngModel)]="isCheckedAll"
        (change)="doCheckAll(isCheckedAll)"
        aria-label="Check All"
      />
    </ng-template>
    <ng-template #actionButtons let-rowData>
      <input
        type="checkbox"
        [(ngModel)]="rowData.isChecked"
        [checked]="rowData.isChecked"
        aria-label="Check Item"
      />
    </ng-template>
  </app-table>
  <div class="button-group button-group-center">
    <app-button color="SECONDARY" class="mr-2" (onClick)="doCancel()"
      >{{ 'app.button.cancel' }}
    </app-button>
    <app-button (onClick)="doSave()">{{ 'app.button.save' }} </app-button>
  </div>
</app-popup>
