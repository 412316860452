<app-popup [header]="header" [isLoading]="formLoading" *ngIf="formReady">
  <div class="row main-row-popup">
    <div class="col-12">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'work-plan.form.organization' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              size="LG"
              formControlName="organizationList"
              stringUrl="/organization"
              isLazy="true"
              header="Organization"
              (onChange)="doChangeOrganization()"
            ></app-text-tree>
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'work-plan.form.addressBook.address' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              formControlName="addressBook"
              [optionList]="addressBookOptionList"
              (onChange)="doOnChangeAddress($event)"
            ></app-combo-box>
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'work-plan.form.addressBook.pic' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              size="LG"
              formControlName="picName"
            ></app-text-field>
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'work-plan.form.addressBook.picNumber' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              size="LG"
              formControlName="picPhone"
            ></app-text-field>
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'work-plan.form.addressBook.addressDetail' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area size="LG" formControlName="addressDetail" rows="5">
            </app-text-area>
          </div>
        </div>
        <h5 class="separator"
          >{{ 'work-plan.separator.itemDetail' | translate }}
        </h5>
        <app-table
        [model]="tableResponse"
        [isServerSide]="false">
        <ng-template #thead let-model>
          <thead>
            <tr>
              <th scope="col" class="tableNo" colspan="1">{{
                'app-table.column.no' | translate
              }}</th>
              <th
                scope="col"
                *ngFor="let column of model.columns; let i = index"
                (click)="column.sortable !== false && doSort(column.field)"
                [ngClass]="[
                  column.sortable !== false ? 'sort' : '',
                  model.request.sortField === column.field ? 'bg-light ' : '',
                  model.request.sortField === column.field &&
                  model.sortOrder === 'ASC'
                    ? 'sort-asc'
                    : '',
                  model.request.sortField === column.field &&
                  model.sortOrder === 'DESC'
                    ? 'sort-desc'
                    : '',
                  column.format === 5 || column.format === 5
                    ? 'text-right'
                    : 'text-center'
                ]"
                [hidden]="column.isChecked === false"
                colspan="99"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
              >
                {{ model.moduleCode + '.' + column.header | translate }}
              </th>
              <th scope="col">
                {{
                  'work-plan.table.column.quantity'
                    | translate
                }}</th
              >
            </tr>
          </thead>
        </ng-template>
        <ng-template #tbody let-model>
          <tbody formArrayName="workPlanAddressItemList">
            <tr *ngIf="model.page.totalRecords === 0">
              <td colspan="9999999" class="bg-white text-center">{{
                'app.info.noData' | translate
              }}</td>
            </tr>
            <tr *ngFor="let record of model.page.records; let i = index"
            [formGroupName]="i">
              <td class="text-center" colspan="1">{{
                i + 1 + model.request.first
              }}</td>
              <td
                *ngFor="let column of model.columns"
                [hidden]="column.isChecked === false"
                colspan="99"
                #tdElement
              >
                {{ record | resolveColumnValue: column:model:tdElement }}
              </td>
              <td>
                <app-text-field
                size="LG"
                type="decimal"
                formControlName="quantity"
              ></app-text-field>
                /
                {{
                  itemQuantityWorkPlanAddress.get(record.workPlanItem.item.id)
                  ? (usedItemAddress.get(
                    record.workPlanItem.item.id)
                    ? (record.workPlanItem.quantity
                      - usedItemAddress.get(record.workPlanItem.item.id)
                      + itemQuantityWorkPlanAddress.get(record.workPlanItem.item.id))
                    : (record.workPlanItem.quantity
                      + itemQuantityWorkPlanAddress.get(record.workPlanItem.item.id)))
                  : (usedItemAddress.get(
                    record.workPlanItem.item.id)
                    ? (record.workPlanItem.quantity
                      - usedItemAddress.get(record.workPlanItem.item.id))
                    : (record.workPlanItem.quantity))

                }}
                <!-- <small *ngIf="errorMapper.get(record.id)" class="text-danger">
                  {{ errorMapper.get(record.id) }}
                </small> -->
              </td>
   
            </tr>
          </tbody>
        </ng-template>
        </app-table>
        
      </form>
      <ng-template #modalFooter let-activeModal>
        <div class="col-12 text-center">
          <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
            'app.button.close' | translate
          }}</app-button>
          <app-button color="PRIMARY" (onClick)="doSubmit()">{{
            'app.button.save' | translate
          }}</app-button>
        </div>
      </ng-template>
    </div>
  </div>
</app-popup>
