import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { CoreConstant } from '../../constant/core-constant';
import { ResponseStatusModel } from '../../model/response-status-model';
import { AppPopupService } from '../app-popup/app-popup.service';
import { Organization } from './../../bean/organization';
import { AppBudgetFilterPopupComponent } from './app-budget-filter-popup.component';
import { BudgetFilterDTO } from './dto/app-budget-filter.dto';
import { BudgetFilterModel } from './model/budget-filter-model';
import { BudgetFilterTemplateData } from './model/budget-filter-template-data-model';
import { BudgetFilterValuesModel } from './model/budget-filter-values-model';
@Component({
  selector: 'app-budget-filter',
  templateUrl: './app-budget-filter.component.html',
  styleUrls: ['./app-budget-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppBudgetFilterComponent extends BaseComponentComponent {
  @Input() model: BudgetFilterModel;

  @Output()
  onChange: EventEmitter<BudgetFilterValuesModel> = new EventEmitter();

  public budgetFilterTemplateDataList: Array<BudgetFilterTemplateData> =
    new Array();
  public moneyFormatTranslateKeyList: Array<string>;
  private budgetFilterDTO: BudgetFilterDTO;

  public tagTitleKey = {
    organization: this.global.translateService.instant(
      'app-budget-filter.filter.organization'
    ),
    dateFrom: this.global.translateService.instant(
      'app-budget-filter.filter.dateFrom'
    ),
    dateTo: this.global.translateService.instant(
      'app-budget-filter.filter.dateTo'
    ),
    periode: this.global.translateService.instant(
      'app-budget-filter.filter.periode'
    ),
    view: this.global.translateService.instant('app-budget-filter.filter.view')
  };

  public tagList: Array<{
    title: string;
    value: string;
    key: string;
  }> = new Array();

  constructor(private appPopupService: AppPopupService) {
    super('app-budget-filter');
  }

  public onInit(): void {
    this.setStateMoneyFormatTranslateKeyList();
    this.setInitializationState(null);
    this.setStateTagList();
    this.global.translateService.onLangChange.subscribe(() => {
      if (this.budgetFilterDTO) {
        this.setStateMoneyFormatTranslateKeyList();
        this.reload();
      }
    });
    this.setStateReady();
  }

  private setStateMoneyFormatTranslateKeyList(): void {
    this.moneyFormatTranslateKeyList = [
      this.global.translateService.instant('app.moneyFormat.thousand'),
      this.global.translateService.instant('app.moneyFormat.million'),
      this.global.translateService.instant('app.moneyFormat.billion'),
      this.global.translateService.instant('app.moneyFormat.trillion'),
      this.global.translateService.instant('app.moneyFormat.quadrillion'),
      this.global.translateService.instant('app.moneyFormat.quantillion'),
      this.global.translateService.instant('app.moneyFormat.sextillion')
    ];
  }

  private setInitializationState(response: BudgetFilterValuesModel): void {
    this.httpClientService
      .post<BudgetFilterDTO>(
        '/app-budget-filter/index',
        response
          ? JSON.stringify({
              organization: response.organization,
              periode: response.periode,
              dateTo: response.dateTo,
              dateFrom: response.dateFrom
            })
          : { response }
      )
      .subscribe((budgetFilterDTO: BudgetFilterDTO) => {
        this.budgetFilterDTO = budgetFilterDTO;
        this.reload();
      });
  }

  private reload(): void {
    const budgetFilterTemplateData = new BudgetFilterTemplateData();
    budgetFilterTemplateData.periodeOptionList.setRequestValues(
      this.budgetFilterDTO.periodeModelList
    );
    budgetFilterTemplateData.viewOptionList.setRequestValues(
      this.budgetFilterDTO.viewList
    );

    budgetFilterTemplateData.budgetTotal =
      this.global.converterService.convertMoney(
        this.budgetFilterDTO.budgetTotal || 0
      ) || '0';

    budgetFilterTemplateData.capexRealizedBudgetTotal = {
      total: this.global.converterService.convertToShortMoney(
        this.budgetFilterDTO.capexRealizedBudgetTotal || 0,
        this.moneyFormatTranslateKeyList
      ) as string,
      width: this.setCapexAndOpexTemplateWidth(
        this.budgetFilterDTO.capexRealizedBudgetTotal || 0,
        this.budgetFilterDTO.capexBudgetTotal || 0
      )
    };

    budgetFilterTemplateData.opexRealizedBudgetTotal = {
      total: this.global.converterService.convertToShortMoney(
        this.budgetFilterDTO.opexRealizedBudgetTotal || 0,
        this.moneyFormatTranslateKeyList
      ) as string,
      width: this.setCapexAndOpexTemplateWidth(
        this.budgetFilterDTO.opexRealizedBudgetTotal || 0,
        this.budgetFilterDTO.opexBudgetTotal || 0
      )
    };

    budgetFilterTemplateData.capexBudgetTotal =
      this.global.converterService.convertToShortMoney(
        this.budgetFilterDTO.capexBudgetTotal || 0,
        this.moneyFormatTranslateKeyList
      ) as string;

    budgetFilterTemplateData.opexBudgetTotal =
      this.global.converterService.convertToShortMoney(
        this.budgetFilterDTO.opexBudgetTotal || 0,
        this.moneyFormatTranslateKeyList
      ) as string;

    budgetFilterTemplateData.capex = {
      total: this.global.converterService.convertToShortMoney(
        this.budgetFilterDTO.capexBudgetTotal || 0,
        this.moneyFormatTranslateKeyList
      ) as string,
      width: this.setBudgetTemplateWidth(
        this.budgetFilterDTO.capexBudgetTotal || 0,
        this.budgetFilterDTO.opexBudgetTotal || 0,
        this.budgetFilterDTO.budgetTotal
      )
    };

    budgetFilterTemplateData.opex = {
      total: this.global.converterService.convertToShortMoney(
        this.budgetFilterDTO.opexBudgetTotal || 0,
        this.moneyFormatTranslateKeyList
      ) as string,
      width: this.setBudgetTemplateWidth(
        this.budgetFilterDTO.opexBudgetTotal || 0,
        this.budgetFilterDTO.capexBudgetTotal || 0,
        this.budgetFilterDTO.budgetTotal
      )
    };
    this.budgetFilterTemplateDataList = [budgetFilterTemplateData];
  }

  private setStateTagList(): void {
    this.tagList.splice(0);
    const filterValue = this.model.filterValue;
    Object.keys(filterValue).forEach((filterKey: string) => {
      if (filterValue[filterKey] && filterValue[filterKey].length !== 0) {
        let value =
          (Array.isArray(filterValue[filterKey]) &&
            filterValue[filterKey][filterValue[filterKey].length - 1].name) ||
          filterValue[filterKey].name ||
          filterValue[filterKey];
        if (value && (filterKey === 'dateFrom' || filterKey === 'dateTo')) {
          const coreConstant = new CoreConstant();
          const monthNameList = Array(12)
            .fill(0)
            .map((val: number, index: number) =>
              this.global.translateService.instant(
                `app.monthNameLong.${coreConstant.MONTH_NAME_LIST[
                  index + val
                ].toLowerCase()}`
              )
            );
          const date = new Date(value);
          value = monthNameList[date.getMonth()] + ' ' + date.getFullYear();
        }
        this.tagList.push({
          title: this.tagTitleKey[filterKey],
          value,
          key: filterKey
        });
      }
    });
  }

  public doOpenPopupFilter(): void {
    this.appPopupService
      .open(
        AppBudgetFilterPopupComponent,
        {
          budgetFilterTemplateData: this.budgetFilterTemplateDataList[0],
          budgetFilter: this.model
        },
        {
          windowClass: 'app-budget-filter-popup'
        }
      )
      .subscribe(response => {
        this.setStateTagList();
        const organization: Organization[] = response.organization;
        if (organization != null && organization.length > 0) {
          response.organization = organization[organization.length - 1];
        } else {
          response.organization = null;
        }
        if (response && response.organization) {
          this.loadingBlockService.showInfo(`app.msg.processing`);
          this.httpClientService
          .get<Response>(
            '/app-budget-filter/check-allowed-access/' 
             + response.organization.id,
              
          ).subscribe(responseAccess => {
            if (responseAccess.status === ResponseStatusModel.OK) {
              this.setInitializationState(response);
              this.onChange.emit(response);
            } else {
              this.loadingBlockService.close();
              this.global.alertService.showError(this.global.translateService.instant('app-budget-filter.alert.msg.noOrganizationAccess'))
            }
          });
        } else {
          this.loadingBlockService.showInfo(`app.msg.processing`);
          this.setInitializationState(response);
          this.onChange.emit(response);
        }
      }); 
  }

  public onKeyUp(event: KeyboardEvent): void {
    this.log.debug(event);
  }

  setBudgetTemplateWidth(data: number, data2: number, total: number): number {
    return total === 0 ||
      total === null ||
      (data / total === 0 && data2 / total === 0)
      ? 50
      : (data / total) * 100;
  }

  setCapexAndOpexTemplateWidth(data: number, total: number): number {
    return total === 0 ? 0 : (data / total) * 100;
  }

  public doDeleteTag(key: string): void {
    if (this.model.defaultFilterValue[key]) {
      this.model.filterValue[key] = this.model.defaultFilterValue[key];
    } else {
      delete this.model.filterValue[key];
    }
    this.model.reload();
    this.setStateTagList();

    const filter: BudgetFilterValuesModel = new BudgetFilterValuesModel();
    const keys = Object.keys(this.model.filterValue);
    for (const key of keys) {
      const value =
        Array.isArray(this.model.filterValue[key]) &&
        this.model.filterValue[key][this.model.filterValue[key].length - 1];
      if (key === 'organization') {
        filter[key] = value;
      } else {
        filter[key] = this.model.filterValue[key];
      }
    }
    this.setInitializationState(filter);
    this.onChange.emit(filter);
  }
}
