<app-popup
  [header]="header"
  [isShowHeaderCloseButton]="false"
  [isLoading]="formLoading"
>
  <div class="main-row-popup-price-reference">
    <app-table-xx
      headerAction="oep.headerAction.priceReference"
      [model]="tableResponse"
      stringUrl="/oep/get-price-reference-list"
      [isMultipleSelect]="false"
      [isShowCheckBox]="true"
      [isShowSearch]="false"
      (onClick)="onClickShowImage($event)"
    >
      <ng-template #headerFilter>
        <form
          class="form-horizontal"
          [formGroup]="formGroup"
          novalidate
          *ngIf="!formLoading"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-2">{{ 'oep.form.date' | translate }}</label>
            <div class="col-sm-10 text-left" style="display: flex">
              <app-date-picker-x
                size="XL"
                formControlName="date"
                [range]="true"
                (onChange)="onChangeFilter()"
                [ngStyle]="{
                  width: 'inherit'
                }"
              >
              </app-date-picker-x>
            </div>
          </div>
        </form>
      </ng-template>
    </app-table-xx>
    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          class="mr-1"
          color="LIGHT"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button (onClick)="doChoose()">{{
          'app.button.choose' | translate
        }}</app-button>
      </div>
    </ng-template>
  </div>
</app-popup>
