import { VendorAttribute } from '../../core/bean/vendor-attribute';
import { VendorTab } from '../../core/bean/vendor-tab';
import { VendorType } from '../../core/bean/vendor-type';

export class VendorAttributeRequest {

    vendorAttributeGroupId: number;
    vendorTabListByVendorType: VendorTab[];
    vendorAttributeListByVendorGroup: VendorAttribute[];
    vendorType: VendorType;
}