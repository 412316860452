<app-card
  header="work-plan-delivery-received.title"
  [isLoading]="formLoading"
  [isSaving]="formSaving"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div formArrayName="purchaseOrderList">
      <app-fieldset
        legend="Purchase Order {{ i + 1 }}"
        *ngFor="
          let purchaseOrder of formArrayPurchaseOrderList.controls;
          let i = index
        "
        [formGroup]="purchaseOrder"
      >
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'work-plan-delivery-received.table.column.number' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            {{ purchaseOrder.controls.number.value }}
          </div>
        </div>
        <table class="table table-striped box-shadow" aria-describedby="Table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col" class="fit">{{
                'work-plan-delivery-received.table.column.itemCode' | translate
              }}</th>
              <th scope="col" class="fit">{{
                'work-plan-delivery-received.table.column.itemName' | translate
              }}</th>
              <th scope="col" class="text-sm-right">{{
                'work-plan-delivery-received.table.column.quantity' | translate
              }}</th>
              <th scope="col" class="asterisk">{{
                'work-plan-delivery-received.table.column.receivedDate'
                  | translate
              }}</th>
              <th scope="col" class="asterisk">{{
                'work-plan-delivery-received.table.column.file' | translate
              }}</th>
            </tr>
          </thead>
          <tbody formArrayName="purchaseOrderItemList">
            <tr
              *ngFor="
                let purchaseOrderItem of formArrayPurchaseOrderItemList.controls;
                let j = index
              "
              [formGroup]="purchaseOrderItem"
            >
              <td>{{ j + 1 }}.</td>
              <td>{{
                purchaseOrderItem.value.purchaseRequestItem.workPlanItem.item
                  .code
              }}</td>
              <td>{{
                purchaseOrderItem.value.purchaseRequestItem.workPlanItem.item
                  .name
              }}</td>
              <td class="text-sm-right">
                {{ purchaseOrderItem.value.purchaseRequestItem.quantity }}
              </td>
              <td>
                <app-date-picker formControlName="receivedDate">
                </app-date-picker>
              </td>
              <td>
                <div class="col">
                  <app-upload-multiple
                    formControlName="file"
                    [fileUploader]="fileUploaderList[i][j]"
                    (onChange)="onChangeFileUpload()"
                  ></app-upload-multiple>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </app-fieldset>
    </div>

    <div class="row justify-content-center mt-5">
      <button
        type="button "
        class="btn btn-primary ml-2"
        (click)="doSave()"
        [disabled]="!isValidUpload || !formGroup.valid"
      >
        {{ 'app.button.save' | translate }}
      </button>
    </div>
  </form>
  <div class="row justify-content-center mt-5">
    <button
      type="button "
      class="btn btn-secondary"
      (click)="doPrevious()"
      [disabled]="formSaving"
    >
      {{ 'app.button.previous' | translate }}
    </button>
    <button
      type="button "
      class="btn btn-primary ml-2"
      (click)="doNext()"
      [disabled]="!isHaveDR"
    >
      {{ 'app.button.next' | translate }}
    </button>
  </div>
</app-card>
