import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { WebSocketService } from 'src/app/core/services/websocket/websocket.service';
import { AppPopupSyncBillingMemoComponent } from './app-popup-sync-billing-memo.component';
import { BillingMemoComponent } from './billing-memo.component';

@Injectable()
export class WebSocketBillingMemoService extends WebSocketService<string> {
  // static isRunning;
  public router: Router;
  public appPopupSyncBillingMemoComponent: AppPopupSyncBillingMemoComponent;
  public onCloseModal: EventEmitter<boolean> = new EventEmitter();
  public message: EventEmitter<string> = new EventEmitter();
  public billingMemo: BillingMemoComponent;

  constructor(public appPopupService: AppPopupService) {
    super('synchronize/payment-status');
  }

  public onReceivedMessage(message: string): Promise<any> {
    return new Promise(resolve => {
      if (this.appPopupSyncBillingMemoComponent) {
        this.appPopupSyncBillingMemoComponent.isLoadingSync = true;
        this.appPopupSyncBillingMemoComponent.syncProgressMessage =
          typeof +message === 'number' && +message;
        if (
          message !== 'cancel' &&
          message !== 'finish' &&
          !(typeof +message === 'number' && +message)
        ) {
          this.appPopupSyncBillingMemoComponent.gettingDataMessage = message;
        } else {
          this.appPopupSyncBillingMemoComponent.gettingDataMessage = null;
        }
        if (message === 'finish' || message === 'cancel') {
          const result = message === 'finish' ? true : false;
          setTimeout(() => {
            this.appPopupSyncBillingMemoComponent = null;
            this.onCloseModal.emit(true);
            resolve(result);
          }, 500);
        }
      } else {
        let bodyMessage;
        if (message === 'finish' || message === 'cancel') {
          const result = message === 'finish' ? true : false;
          setTimeout(() => {
            this.message.emit(null);
            resolve(result);
          }, 500);
        } else {
          bodyMessage =
            typeof +message === 'number' && +message
              ? JSON.parse(message) + ' %'
              : message;
          this.message.emit(bodyMessage);
        }
      }
    });
  }
}
