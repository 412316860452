import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { File } from '../../../core/bean/file';
import { WebContent } from '../../../core/bean/web-content';
import { FileUploader } from '../../../core/components/upload';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { Validators } from '../../../core/validators';
import { RegistrationDocumentModel } from './registration-document-model';
import { RegistrationDocumentRequest } from './registration-document-request';
@Component({
  templateUrl: './registration-document-edit-add.component.html'
})
export class RegistrationDocumentEditAddComponent extends BaseModuleComponent {
  public fileUploaderList: FileUploader[] = new Array();
  public registrationDocumentRequest: RegistrationDocumentRequest;
  constructor(translateService: TranslateService) {
    super('registration-document', translateService);
  }

  public onInit(): void {
    this.doSetTodoFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetTodoFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      registrationDocumentList: this.formBuilder.array([])
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .get<Array<RegistrationDocumentModel>>('/registration-document/add-edit')
      .subscribe(
        (registrationDocumentModelList: Array<RegistrationDocumentModel>) => {
          registrationDocumentModelList.forEach(
            (registrationDocumentModel, index) => {
              const { id, code, lang, key, description } =
                registrationDocumentModel.registrationDocument;
              this.addRegistDocFileToFileUploaderlist(
                registrationDocumentModel.file
              );
              const formGroup = this.formBuilder.group({
                id: [id],
                code: [code],
                lang: [lang],
                key: [key],
                description: [description],
                file: [this.fileUploaderList[index], Validators.required()]
              });

              (
                this.formGroup.get('registrationDocumentList') as FormArray
              ).push(formGroup);
            }
          );
          this.setStateReady();
        }
      );
  }

  public addRegistDocFileToFileUploaderlist(file: File): void {
    const fileUploader: FileUploader = new FileUploader(
      '/registration-document/',
      ' ',
      this.global.appConstant.fileType.DOC_REGIST_VENDOR,
      true,
      1
    );
    fileUploader.setFile(file);
    fileUploader.titleRequired = false;
    this.fileUploaderList.push(fileUploader);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const registDocRequestList: Array<RegistrationDocumentRequest> =
              new Array();
            this.formGroup.value.registrationDocumentList.forEach(
              (registrationDocument: WebContent, index: number) => {
                registrationDocument.value = null;
                this.registrationDocumentRequest =
                  new RegistrationDocumentRequest();
                this.registrationDocumentRequest.registrationDocument =
                  registrationDocument;
                this.registrationDocumentRequest.fileObjectList =
                  this.fileUploaderList[index].fileObjectList;
                registDocRequestList.push(this.registrationDocumentRequest);
              }
            );
            this.httpClientService
              .post<Response<WebContent>>(
                '/registration-document/update',
                registDocRequestList
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/vendor-content-management/']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/vendor-content-management/']);
  }

  public get formArrayRegistDocList(): FormArray {
    return this.formGroup.get('registrationDocumentList') as FormArray;
  }
}
