import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ModuleGroup } from '../../core/bean/module-group';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './module-group.component.html'
})
export class ModuleGroupComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<ModuleGroup>;
  constructor(translateService: TranslateService) {
    super('module-group', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    const active = this.translateService.instant('module-group.status.active');
    const inactive = this.translateService.instant(
      'module-group.status.notActive'
    );
    const dataMap = { false: inactive, true: active };
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      { field: 'name', header: 'table.column.name' },
      {
        field: 'isActive',
        header: 'table.column.status',
        datamap: JSON.stringify(dataMap),
        customClass: 'text-center'
      }
    ]);
  }
}
