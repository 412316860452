import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { SlaEditAddComponent } from './sla-edit-add.component';
import { SlaComponent } from './sla.component';
const routes = [
  { path: '', component: SlaComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: SlaEditAddComponent,
    data: { breadcrumb: 'sla.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [SlaComponent, SlaEditAddComponent]
})
export class SlaModule {}
