import { BaseEntity } from '../base/base-entity';
import { Currency } from '../bean/currency';
import { File } from '../bean/file';
import { RfiStatus } from '../bean/rfi-status';

export class RfiEntity extends BaseEntity {
  status: string;
  currency: Currency;
  file: File;
  rfiStatus: RfiStatus;
  title: string;
  projectAmountFrom: number;
  projectAmountTo: number;
  responseDeadlineDate: Date;
  publishDate: Date;
  note: string;
  createdDate: Date;
  type: string;
}
