import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { CurrencyEditAddComponent } from './currency-edit-add.component';
import { CurrencyComponent } from './currency.component';
const routes: Routes = [
  { path: '', component: CurrencyComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: CurrencyEditAddComponent,
    data: { breadcrumb: 'currency.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: CurrencyEditAddComponent,
    data: { breadcrumb: 'currency.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [CurrencyComponent, CurrencyEditAddComponent]})
export class CurrencyModule {}
