import { createDatePickerDate } from '../helper/create-date-picker-date-helper';
import { createDatePickerDateList } from '../helper/create-date-picker-date-list-helper';
import { DatePickerState } from '../model';
export const setDatePickerYear = (
  state: DatePickerState,
  payload: { year: number }
): void => {
  state.view = 'DATE';
  const date = new Date(state.currentDate.date);
  date.setFullYear(payload.year);
  const datePickerItem = createDatePickerDate(date);
  state.dateList = createDatePickerDateList(date);
  state.currentDate = datePickerItem;
};
