import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './app-popup-export-budget-allocation-bjb.component.html'
})
export class AppPopupExportBudgetBjbComponent extends BaseComponentComponent {
  @Input() isAllowAccess: boolean;
  @Output() onChange: EventEmitter<string> = new EventEmitter();
  public reportOptionList: OptionListModel<any> = new OptionListModel();

  constructor(public translateService: TranslateService) {
    super('budget-allocation-bjb');
  }

  onInit(): void {
    if (!this.isAllowAccess) {
      this.doGenerate();
    } else {
      this.doBuildFormGroup();
      this.doSetParameterTypeOptionList();
      this.setStateReady();
    }
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      reportType: [null, Validators.required()]
    });
  }

  public doSetParameterTypeOptionList(): void {
    const reportList = [
      {
        view: this.translateService.instant(
          'budget-allocation-bjb.report.projection'
        ),
        value: 'projection'
      },
      {
        view: this.translateService.instant(
          'budget-allocation-bjb.report.realization'
        ),
        value: 'realization'
      }
    ];
    this.reportOptionList
      .setRequestValues(reportList)
      .setViewPath('view')
      .setValuePath('value');
  }

  public doGenerate(): void {
    if (this.isAllowAccess) {
      this.validate();
      if (this.formGroup.valid) {
        const type = this.formGroup.get('reportType').value;
        this.onChange.emit(type);
      }
    } else {
      this.onChange.emit('realization');
    }
  }
}
