import { DirectPurchasePayment } from '../../../core/bean/direct-purchase-payment';
import { DirectPurchasePaymentDoc } from '../../../core/bean/direct-purchase-payment-doc';
import { ProcurementVendor } from '../../../core/bean/procurement-vendor';
import { VendorBank } from '../../../core/bean/vendor-bank';
import { FileObject } from '../../../core/components/upload';

export class InitiationDirectPurchaseVendorModel {
  procurementVendor: ProcurementVendor;
  directPurchasePayment: DirectPurchasePayment;
  fileObjectList: FileObject[] = [];
  vendorBankList: VendorBank[] = [];
  directPurchasePaymentDocList: DirectPurchasePaymentDoc[] = [];
}
