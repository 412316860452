import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Adjustment } from '../../core/bean/adjustment';
import { ApprovalModelPrcs } from '../../core/bean/approval-model-prcs';
import { TransactionLog } from '../../core/bean/transaction-log';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { WorkflowPrcs } from '../../core/bean/workflow-prcs';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import {
  TableHeaderPluginData,
  TablePluginData
} from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { AppPopupAdjustmentVendorComponent } from './app-popup-adjustment-vendor.component';
import { SLAVendorResponse } from './sla-vendor-response';
@Component({
  templateUrl: './sla-vendor-detail.component.html'
})
export class SlaVendorDetailComponent extends BaseModuleComponent {
  public workflowPrcsId: number;
  public workflowName: string;
  public slaReportResponse: SLAVendorResponse = new SLAVendorResponse();
  public type: string;
  public adjustmentPopup: Adjustment = new Adjustment();
  public totalAdditional: string;
  public totalApproval: string;
  public maximumAdjustment: number;
  public error: string;

  public tableResponseSlaReport: TableResponseModel<WorkflowModelPrcs>;
  public workflowModelPrcsMain: WorkflowModelPrcs[] = [];

  public tableResponseHistorySla: TableResponseModel<TransactionLog>;
  public transactionLog: TransactionLog[] = [];

  public tableResponseHistoryAdjustment: TableResponseModel<Adjustment>;
  public adjustment: Adjustment[] = [];
  public workflowTypeOptionList: OptionListModel<string> = new OptionListModel(
    true
  );

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('sla-vendor', translateService);
  }

  onInit(): void {
    this.todo = this.global.routerParams.get('todo');
    this.workflowPrcsId = this.global.routerParams.get('workflowPrcsId');
    this.workflowName = this.global.routerParams.get('workflowName');
    this.buildFormGroup();
    this.buildTableResponse();
    this.setDataTable();
  }

  public buildFormGroup(): void {
    this.workflowTypeOptionList.setRequestValues([
      'Main Workflow',
      'Approval Workflow',
      'Additional Workflow'
    ]);

    this.formGroup = this.formBuilder.group({
      workflowTypeList: [null]
    });
  }

  public onChangeFilter(): void {
    const workflowTypeList: string[] = this.formGroup.value.workflowTypeList;
    if (workflowTypeList && workflowTypeList.length > 0) {
      const workflowModelPrcsList =
        this.slaReportResponse.workflowModelPrcsMainList;

      this.tableResponseSlaReport.setRecordList(
        workflowModelPrcsList.filter(workflowModelPrcs =>
          workflowTypeList.includes(workflowModelPrcs['moduleCode'])
        )
      );
    } else {
      this.tableResponseSlaReport.setRecordList(
        this.slaReportResponse.workflowModelPrcsMainList
      );
    }

    this.tableResponseSlaReport.reload();
  }

  public buildTableResponse(): void {
    this.tableResponseSlaReport = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.mainWorkflow.column.stage',
        plugins: {
          name: 'hyperlink',
          onClick: this.doAdjustmentWorkflow.bind(this)
        }
      },
      {
        field: 'pic',
        header: {
          key: 'table.mainWorkflow.column.pic',
          plugins: {
            name: 'custom-plugin',
            after: (data: TableHeaderPluginData) => {
              data.element.style.maxWidth = '150px';
            }
          }
        },
        plugins: {
          name: 'custom-plugin',
          after: (data: TablePluginData) => {
            data.element.style.maxWidth = '150px';
          }
        }
      },
      { field: 'moduleCode', header: 'table.mainWorkflow.column.workflow' },
      {
        header: 'table.column.date',
        columnList: [
          {
            field: 'startDate',
            header: 'table.mainWorkflow.column.startDate',
            plugins: {
              name: 'date',
              format: 'short-date-and-time'
            }
          },
          {
            field: 'endDate',
            header: 'table.mainWorkflow.column.endDate',
            plugins: {
              name: 'date',
              format: 'short-date-and-time'
            }
          },
          {
            field: 'processedDate',
            header: 'table.mainWorkflow.column.realizationDate',
            plugins: {
              name: 'date',
              format: 'short-date-and-time'
            }
          }
        ]
      },
      {
        field: 'slaInDays',
        header: 'table.mainWorkflow.column.sla',
        plugins: {
          name: 'custom-plugin',
          before: (tablePluginData: TablePluginData): string => {
            if (tablePluginData.value !== 0 && tablePluginData.value !== null) {
              return (
                tablePluginData.value +
                ' ' +
                this.translateService.instant('sla-vendor.day')
              );
            } else {
              return '-';
            }
          }
        }
      },
      {
        field: 'slaRealizedName',
        header: 'table.mainWorkflow.column.realization'
      },
      {
        field: 'slaRealizedStatusName',
        header: 'table.column.statusSla',
        className: 'text-center',
        plugins: {
          name: 'badge',
          colorField: 'slaRealizedStatus',
          colorMap: {
            0: 'SUCCESS',
            '-1': 'DANGER',
            1: 'PRIMARY',
            null: 'WARNING'
          }
        }
      }
    ]);

    const DELEGATED = this.translateService.instant(
      'dynamic-master-attribute.sla.historySla.flag.delegated'
    );
    this.tableResponseHistorySla = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.history.column.stage' },
      {
        field: 'processedByUser.name',
        header: 'table.history.column.pic',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'badge',
            field: 'isDelegated',
            pill: false,
            className: 'badge-catalog badge-danger ml-3',
            colorMap: {
              DELEGATED: 'DANGER'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.row.record.isDelegated) {
                  return DELEGATED;
                }
              }
            }
          },
          {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData): void => {
              if (!tablePluginData.row.record.isDelegated) {
                tablePluginData.element.children[1].remove();
              }
            }
          }
        ]
      },
      {
        header: 'table.column.date',
        columnList: [
          {
            field: 'startDate',
            header: 'table.mainWorkflow.column.startDate',
            plugins: {
              name: 'date',
              format: 'short-date-and-time'
            }
          },
          {
            field: 'endDate',
            header: 'table.mainWorkflow.column.endDate',
            plugins: {
              name: 'date',
              format: 'short-date-and-time'
            }
          },
          {
            field: 'processedDate',
            header: 'table.mainWorkflow.column.realizationDate',
            plugins: {
              name: 'date',
              format: 'short-date-and-time'
            }
          }
        ]
      },
      {
        field: 'slaInDays',
        header: 'table.mainWorkflow.column.sla',
        plugins: {
          name: 'custom-plugin',
          before: (tablePluginData: TablePluginData): string => {
            if (tablePluginData.value !== 0 && tablePluginData.value !== null) {
              return (
                tablePluginData.value +
                ' ' +
                this.translateService.instant('sla-vendor.day')
              );
            } else {
              return '-';
            }
          }
        }
      },
      {
        field: 'slaRealizedName',
        header: 'table.history.column.realization'
      }
    ]);

    this.tableResponseHistoryAdjustment = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'adjustmentBy',
          header: 'table.adjustment.column.adjustmentBy'
        },
        {
          field: 'createdDate',
          header: 'table.adjustment.column.adjustmentDate',
          plugins: {
            name: 'date',
            format: 'short-date-and-time'
          }
        },
        {
          field: 'stageName',
          header: 'table.adjustment.column.stage'
        },
        {
          header: 'table.column.adjustment',
          columnList: [
            {
              field: 'beforeAdjustmentName',
              header: 'table.adjustment.column.beforeAdjustment',
              className: 'text-center'
            },
            {
              field: 'totalAdjustmentName',
              header: 'table.adjustment.column.totalAdjustment',
              className: 'text-center'
            },
            {
              field: 'afterAdjustmentName',
              header: 'table.adjustment.column.afterAdjustment',
              className: 'text-center'
            }
          ]
        },
        {
          field: 'description',
          header: 'table.adjustment.column.description'
        }
      ]
    );
  }

  public setDataTable(): void {
    this.httpClientService
      .post<SLAVendorResponse>(
        '/sla-vendor/detail',
        new RouteRequestModel(this.todo, this.workflowPrcsId)
      )
      .subscribe(slaReportResponse => {
        this.slaReportResponse = slaReportResponse;

        this.tableResponseSlaReport.setRecordList(
          this.slaReportResponse.workflowModelPrcsMainList
        );
        this.tableResponseSlaReport.reload();

        // Table SLA History
        this.transactionLog = this.slaReportResponse.transactionLogList;
        this.transactionLog.forEach(transactionLog => {
          if (
            transactionLog.approvalSequence !== null &&
            transactionLog.approvalSequence
          ) {
            transactionLog.name =
              transactionLog.name + ' Lv ' + transactionLog.approvalSequence;
          }
        });
        this.tableResponseHistorySla.setRecordList(this.transactionLog);
        this.tableResponseHistorySla.reload();

        // Table Adjustment
        this.adjustment = this.slaReportResponse.adjustmentList;
        this.adjustment.forEach(adjustment => {
          if (adjustment !== null) {
            let name = '';
            if (adjustment.approvalModelPrcs !== null) {
              name =
                adjustment.approvalModelPrcs.approvalPrcs.workflowModelPrcs
                  .name;
              if (
                adjustment.approvalModelPrcs.sequence !== 0 &&
                adjustment.approvalModelPrcs.sequence !== null
              ) {
                name += ' Lv ' + adjustment.approvalModelPrcs.sequence;
              }
              adjustment.stageName = name;
            } else {
              adjustment.stageName = adjustment.workflowModelPrcs.name;
            }
          }
        });
        this.tableResponseHistoryAdjustment.setRecordList(this.adjustment);
        this.tableResponseHistoryAdjustment.reload();
        this.setStateReady();
      });
  }

  public doAdjustmentWorkflow(workflowModelPrcs: WorkflowModelPrcs): void {
    if (workflowModelPrcs.slaRealizedStatus != null) {
      this.type = 'workflow';
      this.adjustmentPopup.workflowModelPrcs = workflowModelPrcs;
      this.adjustmentPopup.workflowPrcs = workflowModelPrcs.workflowPrcs;
      this.adjustmentPopup.slaRealizedBeforeAdjustmentInMinutes =
        workflowModelPrcs.slaRealizedInMinutes;
      this.adjustmentPopup.beforeAdjustmentWorkingInDays =
        workflowModelPrcs.slaRealizedWorkingInDays;
      this.adjustmentPopup.beforeAdjustmentWorkingInHours =
        workflowModelPrcs.slaRealizedWorkingInHours;
      this.adjustmentPopup.beforeAdjustmentWorkingInMinutes =
        workflowModelPrcs.slaRealizedWorkingInMinutes;

      this.appPopupService
        .open(
          AppPopupAdjustmentVendorComponent,
          { adjustmentPopup: this.adjustmentPopup, type: this.type },
          { size: 'lg', backdrop: 'static' }
        )
        .subscribe(() => {
          this.doGetListAdjustment(workflowModelPrcs.workflowPrcs);
        });
      this.adjustmentPopup = new Adjustment();
    }
  }

  public doAdjustmentApproval(approvalModelPrcs: ApprovalModelPrcs): void {
    this.type = 'approval';
    this.adjustmentPopup.approvalModelPrcs = approvalModelPrcs;
    this.adjustmentPopup.workflowModelPrcs =
      approvalModelPrcs.approvalPrcs.workflowModelPrcs;
    this.adjustmentPopup.workflowPrcs =
      approvalModelPrcs.approvalPrcs.workflowModelPrcs.workflowPrcs;
    this.adjustmentPopup.slaRealizedBeforeAdjustmentInMinutes =
      approvalModelPrcs.slaRealizedInMinutes;
    this.adjustmentPopup.beforeAdjustmentWorkingInDays =
      approvalModelPrcs.slaRealizedWorkingInDays;
    this.adjustmentPopup.beforeAdjustmentWorkingInHours =
      approvalModelPrcs.slaRealizedWorkingInHours;
    this.adjustmentPopup.beforeAdjustmentWorkingInMinutes =
      approvalModelPrcs.slaRealizedWorkingInMinutes;

    this.appPopupService
      .open(
        AppPopupAdjustmentVendorComponent,
        { adjustmentPopup: this.adjustmentPopup, type: this.type },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(() => {
        this.doGetListAdjustment(
          approvalModelPrcs.approvalPrcs.workflowModelPrcs.workflowPrcs
        );
      });
    this.adjustmentPopup = new Adjustment();
  }

  public doGetListAdjustment(workflowPrcs: WorkflowPrcs): void {
    this.httpClientService
      .post<Adjustment[]>('/sla-vendor/get-list-adjustment', workflowPrcs)
      .subscribe((adjustmentList: Adjustment[]) => {
        this.adjustment = adjustmentList;
        this.adjustment.forEach((adjustment: Adjustment) => {
          if (adjustment !== null) {
            let name = '';
            if (adjustment.approvalModelPrcs !== null) {
              name =
                adjustment.approvalModelPrcs.approvalPrcs.workflowModelPrcs
                  .name;
              if (
                adjustment.approvalModelPrcs.sequence !== 0 &&
                adjustment.approvalModelPrcs.sequence !== null
              ) {
                name += ' Lv ' + adjustment.approvalModelPrcs.sequence;
              }
              adjustment.stageName = name;
            } else {
              adjustment.stageName = adjustment.workflowModelPrcs.name;
            }
          }
        });
        this.tableResponseHistoryAdjustment.setRecordList(this.adjustment);
        this.tableResponseHistoryAdjustment.reload();
      });
  }

  public addUomDay(slaInDays: number): string {
    if (slaInDays !== 0 && slaInDays !== null) {
      return slaInDays + ' ' + this.translateService.instant('sla-vendor.day');
    } else {
      return '-';
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/sla-vendor']);
  }
}
