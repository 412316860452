import { FormControl, ValidatorFn } from '@angular/forms';
import { AvailableTablePlugin } from '../interface/available-table-plugin';
import { TablePluginData } from '../interface/table-plugin-data';
import { TableAllPluginOptionType } from '../types/table-all-plugin-option-type';
export const createPluginFormcontrol = (
  data: TablePluginData,
  value?: any
): FormControl => {
  if (typeof data.column.column.plugins === 'string') {
    return new FormControl(value || data.value);
  } else {
    const plugins = data.column.column.plugins as {
      name: AvailableTablePlugin;
    } & TableAllPluginOptionType;
    if (plugins.formControl) {
      return new FormControl(
        value || data.value,
        [plugins.formControl.validator],
        plugins.formControl.asyncValidator
      );
    } else {
      return new FormControl(
        value || data.value,
        createFormControlValidators(
          plugins.validators,
          plugins.customValidatorFn,
          data.row.record
        ),
        plugins.asyncValidators
      );
    }
  }
};

const createFormControlValidators = (
  validators: ValidatorFn | ValidatorFn[],
  customValidators: ValidatorFn | ValidatorFn[],
  record: any
): ValidatorFn[] => {
  const type = getValidatorType(validators);
  const customValidatorsResult: ValidatorFn[] = createCustomValidatorFn(
    customValidators,
    record
  );
  switch (type) {
    case 'VALIDATOR-INSIDE-ARRAY':
      return [...(validators as ValidatorFn[]), ...customValidatorsResult];
    case 'VALIDATOR-INSIDE-COMPOSE':
      return [...(validators as ValidatorFn[]), ...customValidatorsResult];
    case 'SINGLE-VALIDATOR':
      return [validators as ValidatorFn, ...customValidatorsResult];
    default:
      return customValidatorsResult;
  }
};

const createCustomValidatorFn = (
  customValidators: ValidatorFn | ValidatorFn[],
  record: any
): ValidatorFn[] => {
  const type = getValidatorType(customValidators);
  const validators: ValidatorFn[] =
    type === 'NO-VALIDATOR'
      ? []
      : type === 'SINGLE-VALIDATOR'
      ? [customValidators as ValidatorFn]
      : (customValidators as ValidatorFn[]);
  return validators.map(customValidator =>
    customValidator.bind(this, record)()
  );
};

type ValidatorType =
  | 'VALIDATOR-INSIDE-COMPOSE'
  | 'VALIDATOR-INSIDE-ARRAY'
  | 'SINGLE-VALIDATOR'
  | 'NO-VALIDATOR';

const getValidatorType = (
  validators: ValidatorFn | ValidatorFn[]
): ValidatorType => {
  return validators
    ? Array.isArray(validators)
      ? 'VALIDATOR-INSIDE-ARRAY'
      : validators.toString().includes('mergeErrors')
      ? 'VALIDATOR-INSIDE-COMPOSE'
      : 'SINGLE-VALIDATOR'
    : 'NO-VALIDATOR';
};
