<div class="toast-icon" *ngIf="activeToast.options.isShowIcon !== false">
  <em class="fas" [ngClass]="toastIconObject[activeToast.options.color]"></em>
</div>
<div
  class="toast-content"
  [ngClass]="activeToast.options.isShowCloseButton === false ? 'px-0' : ''"
>
  <h5
    class="toast-title m-0"
    *ngIf="activeToast.options.title"
    [ngClass]="activeToast.options.isShowCloseButton === false ? 'px-3' : ''"
    >{{ activeToast.options.title | translate }}</h5
  >
  <p
    class="toast-text"
    [ngClass]="activeToast.options.isShowCloseButton === false ? 'px-3' : ''"
  >
    {{ activeToast.options.message | translate }}
  </p>
</div>
<div
  class="toast-action"
  (click)="activeToast.close()"
  (keyup)="onKeyUp($event)"
  (keydown)="onKeyDown($event)"
  *ngIf="activeToast.options.isShowCloseButton !== false"
>
  <em class="fas fa-times"></em>
</div>
<div
  class="toast-indicator"
  *ngIf="
    !activeToast.options.static && activeToast.options.isShowIndicator != false
  "
>
  <div
    class="indicator-progress"
    [ngStyle]="{
      animation:
        'toastIndicator ' + activeToast.options.interval + 'ms normal forwards'
    }"
  >
  </div>
</div>
