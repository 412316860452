import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Currency } from '../../core/bean/currency';
import { Rfi } from '../../core/bean/rfi';
import { RfiItem } from '../../core/bean/rfi-item';
import { RfiVendor } from '../../core/bean/rfi-vendor';
import { RfiVendorNonPromise } from '../../core/bean/rfi-vendor-non-promise';
import { VendorHistory } from '../../core/bean/vendor-history';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { RfiDTO } from './dto/rfi.dto';
@Component({
  templateUrl: './rfi-detail.component.html'
})
export class RfiDetailComponent extends BaseModuleComponent {
  @ViewChild('selectorItem') tableRfiItem: AppTableComponent;
  @ViewChild('selectorVendor') tableVendor: AppTableComponent;
  @ViewChild('selectorVendorNonPromise') tableVendorNonPromise: AppTableComponent;

  public vendorList: VendorHistory[] = [];
  public rfiVendorList: RfiVendor[] = [];
  public rfiVendor: RfiVendor = new RfiVendor();
  public rfiList: Rfi[] = [];
  public rfiItemList: RfiItem[] = [];
  public rfiDTO: RfiDTO;
  public rfiId: number;
  public rfiItem: RfiItem = new RfiItem();
  public currencyList: Currency[] = [];
  public vendorIdList: number[] = [];
  public dataReady = false;
  public tableReady = false;
  public projectAmountFrom: string;
  public projectAmountTo: string;

  public tableResponseRfiItem: TableResponseModel<RfiItem>;
  public tableResponseVendor: TableResponseModel<RfiVendor>;
  public tableResponseVendorNonPromise: TableResponseModel<RfiVendorNonPromise>;

  public readonly urlRouterDetailRfiQuestion = '/pages/request-for-information/detail/response';
  public readonly urlRouterNavigateToRfi = '/pages/request-for-information';

  constructor(translateService: TranslateService) {
    super('rfi', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.rfiId = this.global.routerParams.get('rfiId');
    this.rfiDTO = this.global.routerParams.get('rfiDTO');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      title: [null, Validators.compose([Validators.required()])],
      currency: [null, Validators.compose([Validators.required()])],
      projectAmountFrom: [null, Validators.compose([Validators.required()])],
      projectAmountTo: [null, Validators.compose([Validators.required()])],
      responseDeadlineDate: [null, Validators.compose([Validators.required()])],
      publishDate: [null, Validators.compose([Validators.required()])],
      templateRfi: [null, Validators.compose([Validators.required()])],
      note: [null, Validators.compose([Validators.required()])],
      createdDate: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService.post<RfiDTO>('/request-for-information/detail', new RouteRequestModel(this.todo, this.rfiId)).subscribe(rfiDTO => {
      this.rfiDTO = rfiDTO;

      if (this.rfiDTO.rfi != null) {
        const responseDeadlineDate: Date = new Date(this.rfiDTO.rfi.responseDeadlineDate);
        const publishDate: Date = new Date(this.rfiDTO.rfi.publishDate);
        const createdDate: Date = new Date(this.rfiDTO.rfi.createdDate);

        this.formGroup.patchValue({
          id: rfiDTO.rfi.id,
          title: rfiDTO.rfi.title,
          responseDeadlineDate,
          publishDate,
          templateRfi: rfiDTO.rfi.file.uploadedFileName,
          note: rfiDTO.rfi.note,
          createdDate,
          file: this.rfiDTO.rfi.file.fileName
        });

        if (this.rfiDTO.rfi.currency !== null || this.rfiDTO.rfi.projectAmountTo !== null || this.rfiDTO.rfi.projectAmountFrom !== null) {
          this.formGroup.patchValue({
            currency: rfiDTO.rfi.currency.code,
            projectAmountFrom: this.global.converterService.convertMoney(rfiDTO.rfi.projectAmountFrom.toString()),
            projectAmountTo: this.global.converterService.convertMoney(rfiDTO.rfi.projectAmountTo.toString())
          });
        }
      }

      this.buildTableResponseItem();
      this.buildTableResponseVendor();

      this.setStateReady();
      this.tableReady = true;
      this.dataReady = true;
    });
  }

  public buildTableResponseItem(): void {
    this.tableResponseRfiItem = new TableResponseModel(this.moduleCode, [
      { field: 'item', header: 'table.column.item', customClass: 'white-space-normal' },
      { field: 'description', header: 'table.column.description', customClass: 'white-space-normal' },
      { field: 'quantityUom', header: 'table.column.quantity', customClass: 'text-center' }
    ]);

    this.tableResponseRfiItem.page.records = this.rfiDTO.rfiItemList;
    this.tableResponseRfiItem.page.totalRecords = this.rfiDTO.rfiItemList.length;

    this.setStateReady();
  }

  public buildTableResponseVendor(): void {
    this.tableResponseVendor = new TableResponseModel(this.moduleCode, [
      { field: 'vendorHistory.vendor.name', header: 'table.column.name', customClass: 'white-space-normal' },
      { field: 'vendorHistory.vendor.vendorType.name', header: 'table.column.vendorType', customClass: 'text-center' },
      { field: 'vendorHistory.vendor.email', header: 'table.column.email' },
      { field: 'vendorHistory.address', header: 'table.column.location', customClass: 'white-space-normal' },
      { field: 'statusVendor', header: 'table.column.status', customClass: 'text-center' },
      { field: 'rfi.responseDeadlineDate', header: 'table.column.responseDeadlineDate', customClass: 'text-center', format: FieldFormatEnum.ShortDate }
    ]);

    this.tableResponseVendor.page.records = this.rfiDTO.rfiVendorList;
    this.tableResponseVendor.page.totalRecords = this.rfiDTO.rfiVendorList.length;

    /*this.tableResponseVendorNonPromise = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      { field: 'email', header: 'table.column.email' },
      { field: 'phoneNumber', header: 'table.column.phone', customClass: 'text-center' },
      { field: 'address', header: 'table.column.address' },
      { field: 'statusVendor', header: 'table.column.status', customClass: 'text-center' },
      { field: 'rfi.responseDeadlineDate', header: 'table.column.responseDeadlineDate', customClass: 'text-center', format: FieldFormatEnum.ShortDate },
    ]);

    this.tableResponseVendorNonPromise.page.records = this.rfiDTO.rfiVendorNonPromiseList;
    this.tableResponseVendorNonPromise.page.totalRecords = this.rfiDTO.rfiVendorNonPromiseList.length;*/

    this.setStateReady();
  }

  public doViewResponseVendor(rfiVendor: RfiVendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', this.todo);
    this.global.routerParams.set('rfiId', this.rfiDTO.rfi.id);
    this.global.routerParams.set('rfiDTO', this.rfiDTO);
    this.global.routerParams.set('rfiVendorId', rfiVendor.id);
    this.router.navigate([this.urlRouterDetailRfiQuestion]);
  }

  public doViewResponseVendorNonPromise(rfiVendorNonPromise: RfiVendorNonPromise): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', this.todo);
    this.global.routerParams.set('rfiId', this.rfiDTO.rfi.id);
    this.global.routerParams.set('rfiDTO', this.rfiDTO);
    this.global.routerParams.set('rfiVendorNonPromiseId', rfiVendorNonPromise.id);
    this.router.navigate([this.urlRouterDetailRfiQuestion]);
  }

  public doCancel(): void {
    this.router.navigate([this.urlRouterNavigateToRfi]);
  }
}
