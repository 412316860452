import {
  Component,
  EventEmitter,
  Input,
  Output,
  Renderer2
} from '@angular/core';
import { BaseComponentComponent } from '../../../../../../core/base/angular/base-component.component';
import { Vendor } from '../../../../../../core/bean/vendor';
import { Response } from '../../../../../../core/model/response-model';
import { ResponseStatusModel } from '../../../../../../core/model/response-status-model';
import { ViewVendorComparisonDTO } from '../../../dto/view-vendor-comparison.dto';
@Component({
  selector: 'app-popup-vendor-comparison',
  templateUrl: './app-popup-vendor-comparison.component.html'
})
export class AppPopupVendorComparisonComponent extends BaseComponentComponent {
  @Input() vendorList: Vendor[] = [];
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  constructor(public renderer2: Renderer2) {
    super('app-popup-vendor-comparison');
  }

  onInit(): void {}

  public doGetCheckedList(checkedVendorList: Vendor[]): void {
    this.setStateProcessing();
    this.httpClientService
      .post<Response<ViewVendorComparisonDTO>>(
        '/view-vendor/compare',
        checkedVendorList
      )
      .subscribe((response: Response<ViewVendorComparisonDTO>) => {
        checkedVendorList = [];
        if (response.status === ResponseStatusModel.OK) {
          this.global.routerParams.set('responseComparison', response);
          this.onChange.emit(response);
          this.router.navigate(['/pages/view-vendor/comparison']);
        } else {
          this.global.alertService.showError(
            response.statusText,
            '.app-popup-comparison'
          );
          this.setStateReady();
        }
      });
  }

  public onLoadImage(event: any): void {
    this.renderer2.removeChild(
      event.target.parentElement,
      event.target.nextSibling
    );
  }

  public onErrorImage(event: any): void {
    event.target.classList.add('fa');
    event.target.classList.add('fa-image');
    this.renderer2.removeChild(
      event.target.parentElement,
      event.target.nextSibling
    );
  }
}
