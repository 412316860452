import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppItemRequestModule } from '../../core/components/app-item-request/app-item-request.module';
import { AppProcurementInfoModule } from '../../core/components/app-procurement-info/app-procurement-info.module';
import { AppWorkflowStepInfoModule } from '../../core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupCriteriaProcurementComponent } from './app-popup-criteria-procurement.component';
import { AppPopupRegisteredLetterComponent } from './app-popup-registered-letter.component';
import { ProcurementRegVendorEditComponent } from './procurement-reg-vendor-edit.component';
import { ProcurementRegVendorComponent } from './procurement-reg-vendor.component';
export const routes = [
  {
    path: '',
    component: ProcurementRegVendorComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'detail',
    component: ProcurementRegVendorEditComponent,
    data: { breadcrumb: 'procurement-reg-vendor.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: ProcurementRegVendorEditComponent,
    data: { breadcrumb: 'procurement-reg-vendor.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    AppWorkflowStepInfoModule,
    AppProcurementInfoModule,
    AppItemRequestModule,
    AppBadgeCatalogModule
  ],
  declarations: [
    ProcurementRegVendorComponent,
    ProcurementRegVendorEditComponent,
    AppPopupCriteriaProcurementComponent,
    AppPopupRegisteredLetterComponent
  ],
  providers: [],
  entryComponents: [
    AppPopupCriteriaProcurementComponent,
    AppPopupRegisteredLetterComponent
  ],
  exports: [
    AppPopupCriteriaProcurementComponent,
    AppPopupRegisteredLetterComponent
  ]
})
export class ProcurementRegVendorModule {}
