import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { BroadcastMessage } from '../../core/bean/broadcast-message';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './admin-panel.component.html'
})
export class AdminPanelComponent extends BaseModuleComponent {
  public isLoading = false;

  public tableResponse: TableResponseModel<BroadcastMessage>;
  constructor(
    translate: TranslateService
  ) {
    super('admin-panel', translate);
  }

  onInit(): void {
    this.doBuildFormGroup();
    this.doBuildTableResponse();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      message: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(512)])
      ],
      isActive: [null]
    });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'message',
        header: 'table.column.message',
        customClass: 'white-space-normal'
      }
    ]);
  }

  public doReloadAction(): void {
    this.global.modalService
      .confirm(
        this.translateService.instant(
          'admin-panel.confirmation.reloadAction.msg'
        ),
        this.translateService.instant(
          'admin-panel.confirmation.reloadAction.title'
        )
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.isLoading = true;
          this.httpClientService
            .post('/admin-panel/reload', null)
            .subscribe(response => {
              this.isLoading = false;
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccess(
                  this.translateService.instant(
                    'admin-panel.alert.msg.successReloadAction'
                  )
                );
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doClearCache(): void {
    this.global.modalService
      .confirm(
        this.translateService.instant(
          'admin-panel.confirmation.clearCache.msg'
        ),
        this.translateService.instant(
          'admin-panel.confirmation.clearCache.title'
        )
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.isLoading = true;
          this.httpClientService
            .post('/admin-panel/clear-cache', null)
            .subscribe(response => {
              this.isLoading = false;
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccess(
                  this.translateService.instant(
                    'admin-panel.alert.msg.successClearCache'
                  )
                );
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doClearSpringCache(): void {
    this.global.modalService
      .confirm(
        this.translateService.instant(
          'admin-panel.confirmation.clearSpringCache.msg'
        ),
        this.translateService.instant(
          'admin-panel.confirmation.clearSpringCache.title'
        )
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.isLoading = true;
          this.httpClientService
            .post('/admin-panel/clear-spring-cache', null)
            .subscribe(response => {
              this.isLoading = false;
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccess(
                  this.translateService.instant(
                    'admin-panel.alert.msg.successClearSpringCache'
                  )
                );
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doLogoutAll(): void {
    this.httpClientService
      .get('/admin-panel/total-active-user')
      .subscribe(total => {
        if (total > 0) {
          this.global.modalService
            .confirm(
              this.translateService.instant(
                'admin-panel.confirmation.logoutAll.msg'
              ) +
                ' ' +
                total +
                ' ' +
                this.translateService.instant(
                  'admin-panel.confirmation.logoutAll.user'
                ),
              this.translateService.instant(
                'admin-panel.confirmation.logoutAll.title'
              )
            )
            .pipe(take(1))
            .subscribe(result => {
              if (result) {
                this.isLoading = true;
                this.httpClientService
                  .post('/admin-panel/logout-all', null)
                  .subscribe(response => {
                    this.isLoading = false;
                    if (response.status === ResponseStatusModel.OK) {
                      this.global.alertService.showSuccess(
                        this.translateService.instant(
                          'admin-panel.alert.msg.successLogoutAll'
                        )
                      );
                    } else {
                      this.global.alertService.showError(response.statusText);
                    }
                  });
              }
            });
        } else {
          this.global.alertService.showError(
            this.translateService.instant('admin-panel.alert.msg.noActiveUser')
          );
        }
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const broadcastMessage = this.global.copyFormAttributeToModel(
              new BroadcastMessage(),
              this.formGroup
            );
            const url = '/admin-panel/insert-broadcast-message';
            this.httpClientService
              .post<Response<BroadcastMessage>>(url, broadcastMessage)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSaving();
                  this.formGroup.reset();
                  this.tableResponse.reload();
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doUpdate(bm: BroadcastMessage): void {
    this.global.modalService
      .confirm(
        this.translateService.instant('admin-panel.confirmation.update.msg'),
        this.translateService.instant('admin-panel.confirmation.update.title')
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          const broadcastMessage = bm;
          broadcastMessage.isActive = broadcastMessage.isActive ? false : true;
          const url = '/admin-panel/update-broadcast-message';
          this.httpClientService
            .post<Response<BroadcastMessage>>(url, broadcastMessage)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccess(
                  this.translateService.instant(
                    'admin-panel.alert.msg.successUpdate'
                  )
                );
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
