import { PrItem } from 'src/app/core/bean/pr-item';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { Rfp } from '../../core/bean/rfp';
import { RfpProposalCriteria } from '../../core/bean/rfp-proposal-criteria';
import { RfpVendorScoring } from '../../core/bean/rfp-vendor-scoring';
import { FileObject } from '../../core/components/upload';

export class RfpRequest {
  totalProposalPoint: number;
  totalProposalCriteria: number;
  isSubmit: boolean;
  rfp: Rfp = new Rfp();
  prItemList: PrItem[] = [];
  fileObjectList: FileObject[] = [];
  vendorViewList: PopupVendorView[] = [];
  rfpVendorScoringList: RfpVendorScoring[] = [];
  rfpProposalCriteriaList: RfpProposalCriteria[] = [];
}
