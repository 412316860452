import { BaseEntity } from '../../base/base-entity';
import { CatalogRegion } from '../../bean/catalog-region';
import { CatalogRegionDetail } from '../../bean/catalog-region-detail';
import { CatalogType } from '../../bean/catalog-type';
import { Item } from '../../bean/item';
import { Region } from '../../bean/region';

export class CatalogViewEntity extends BaseEntity {
  public catalogContractPrice: number;

  public item: Item;
  public catalogType: CatalogType;
  public name: string;
  public vendorId: number;
  public vendorName: string;
  public rating: number;
  public catalogTypeTranslationKey: string;
  public currencyCode: string;
  public fileName: string;
  public uploadedFileName: string;
  public price: number;
  public priceInIdr: number;
  public vendorCityRegionName: string;
  public vendorCityRegionCode: string;
  public catalogStatusCode: string;
  public activeDate: Date;
  public region: Region;
  public remainingQuantity: number;
  public quantityInPackage: number;
  public catalogRegion: CatalogRegion;
  public quantity: number;
  public catalogRegionDetail: CatalogRegionDetail;
  public merk: string;
  public produsenName: string;
}
