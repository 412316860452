import { DatePickerMonthPosition } from '../type';
export class DatePickerDate {
  date: Date;
  monthPosition: DatePickerMonthPosition;
  dateString: number;
  timeString: string;
  dateNumber: number;
  day: number;
  isCurrentDate: boolean;
  year: number;
  month: number;
  dateTime: number;
}
