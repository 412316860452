import { UploaderFile } from '../model/uploader-file';
export function createUploaderFileSrc(
  uploaderFile: UploaderFile
): UploaderFile {
  if (uploaderFile.file.isImage) {
    uploaderFile = createUploadImageFileSrc(uploaderFile);
  } else {
    uploaderFile = createUploadNotAnImageFileSrc(uploaderFile);
  }
  return uploaderFile;
}

function createUploadImageFileSrc(uploaderFile): UploaderFile {
  if (uploaderFile.isNew) {
    const fileReader: FileReader = new FileReader();
    fileReader.onload = () => (uploaderFile.src = fileReader.result);
    fileReader.readAsDataURL(uploaderFile.origin);
  } else {
    uploaderFile.src = uploaderFile.file.uploadedFileName;
  }
  return uploaderFile;
}

function createUploadNotAnImageFileSrc(
  uploaderFile: UploaderFile
): UploaderFile {
  const myImage = new Image();
  myImage.src =
    '/assets/component/fileuploader/logo-icon/' +
    uploaderFile.file.extension.toLowerCase() +
    '.png';
  myImage.onload = () => {
    uploaderFile.src = myImage.src;
  };
  myImage.onerror = () => {
    uploaderFile.src = '/assets/component/fileuploader/logo-icon/Default.png';
  };
  return uploaderFile;
}
