<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="procurement-offline-submission.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/procurement-offline-submission/index"
      >
        <ng-template #headerButtons>
          <app-button (onClick)="doAdd()" [disabled]="formLoading"
            ><em class="fas fa-plus"></em
            >{{ 'app.button.add' | translate }}</app-button
          >
        </ng-template>
        <ng-template #actionButtons let-rowData let-i="index">
          <app-button
            *ngIf = "rowData[2].code !== global.appConstant.esm.PROCUREMENT_STATUS_CLOSED"
            size="TN"
            (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}"
          >
            <em class="fas fa-pencil-alt"></em>
          </app-button>
          <app-button
            size="TN"
            (onClick)="doDetail(rowData)"
            title="{{ 'app.tooltip.view' | translate }}"
          >
            <em class="fas fa-search"></em>
          </app-button>
          <app-button
            *ngIf = "rowData[2].code === global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST"
            size="TN"
            color="DANGER"
            (onClick)="doDelete(rowData, i)"
            title="{{ 'app.tooltip.delete' | translate }}"
          >
            <em class="fas fa-trash"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
