import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { BillingMemoStatus } from 'src/app/core/bean/billing-memo-status';
import { PaymentInvoice } from 'src/app/core/bean/payment-invoice';
import { PaymentStatus } from 'src/app/core/bean/payment-status';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RecapPrintService } from 'src/app/core/services/recap-print.service';
import { ReportService } from 'src/app/core/services/report.service';
import { PaymentRequestView } from 'src/app/core/view/entity/bean/payment-request-view';
import { PaymentView } from './../../core/view/entity/bean/payment-view';
import { AppPopupSyncPaymentMonitoringComponent } from './app-popup-sync-payment-monitoring.component';
import { PaymentMonitoringReSyncRequest } from './payment-monitoring-re-sync.request';
import { PaymentMonitoringResponse } from './payment-monitoring.response';
import { WebSocketPaymentMonitoringService } from './websocket-payment-monitoring.service';
@Component({
  templateUrl: './payment-monitoring.component.html',
  providers: [
    { provide: WebSocketPaymentMonitoringService },
    { provide: AppPopupService }
  ]
})
export class PaymentMonitoringComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<PaymentView>;

  public procurementOptionList: OptionListModel<String> = new OptionListModel(
    false
  );
  public agreementOptionList: OptionListModel<String> = new OptionListModel(
    false
  );
  public vendorOptionList: OptionListModel<String> = new OptionListModel(false);
  public paymentInvoiceOptionList: OptionListModel<PaymentInvoice> =
    new OptionListModel(false, 'number');
  public paymentStatusOptionList: OptionListModel<PaymentStatus> =
    new OptionListModel(true);
  public billingMemoStatusOptionList: OptionListModel<BillingMemoStatus> =
    new OptionListModel(true);
  public isLoadingSync: boolean;
  public isSyncRunning = false;
  public isAllowAccess = false;
  public sowPaymentTermId: number;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public reportService: ReportService,
    public recapPrintService: RecapPrintService,
    public websocketPaymentMonitoringService: WebSocketPaymentMonitoringService
  ) {
    super('payment-monitoring', translateService);
  }

  public onInit(): void {
    /*this.initializeWebSocketBillingMemoConnection();
        this.chekedUserAccessButton();
        this.isSynchronizeRunning();*/
    this.sowPaymentTermId = this.global.routerParams.get('sowPaymentTermId');
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
    this.global.routerParams.clear();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      startDate: [null],
      endDate: [null],
      agreementList: [null],
      procurementList: [null],
      paymentInvoiceList: [null],
      vendorList: [null],
      paymentStatusList: [null],
      billingMemoStatusList: [null],
      sowPaymentTermId: [this.sowPaymentTermId]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<PaymentMonitoringResponse>('/payment-monitoring/get-option-list')
      .subscribe((paymentMonitoringResponse: PaymentMonitoringResponse) => {
        this.procurementOptionList.setRequestValues(
          paymentMonitoringResponse.procurementList
        );
        this.agreementOptionList.setRequestValues(
          paymentMonitoringResponse.agreementList
        );
        this.paymentInvoiceOptionList.setRequestValues(
          paymentMonitoringResponse.paymentInvoiceList
        );
        this.vendorOptionList.setRequestValues(
          paymentMonitoringResponse.vendorList
        );
        this.paymentStatusOptionList.setRequestValues(
          paymentMonitoringResponse.paymentStatusList
        );
        this.billingMemoStatusOptionList.setRequestValues(
          paymentMonitoringResponse.billingMemoStatusList
        );
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'paymentInvoice.code',
        header: 'table.column.invoiceNumber'
      },
      {
        field: 'paymentInvoice.invoiceDate',
        header: 'table.column.invoiceDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'paymentVoucher.code',
        header: 'table.column.voucherNumber'
      },
      {
        field: 'paymentBilling.vendor.name',
        header: 'table.column.vendorName'
      },
      {
        field: 'paymentBilling.title',
        header: 'table.column.title'
      },
      {
        field: 'workflowModelPrcsName',
        header: 'table.column.stage'
      },
      {
        field: 'paymentStatus.name',
        header: 'table.column.status'
      }
    ]);

    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doApply(): void {
    this.global.routerParams.clear();
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }

  public doEdit(paymentView: PaymentView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('paymentId', paymentView.id);
    this.global.routerParams.set('vendor', paymentView.vendor);
    this.global.routerParams.set('paymentView', paymentView);
    this.global.routerParams.set('paymentInvoice', paymentView.paymentInvoice);
    this.global.routerParams.set('urlBackOutside', '/pages/payment-monitoring');

    this.router.navigate(['/pages/payment-monitoring/edit']);
  }

  public doDetail(paymentView: PaymentView): void {
    const prcsCode = paymentView.prcsCode;

    this.global.routerParams.clear();
    this.global.routerParams.set('paymentId', paymentView.id);
    this.global.routerParams.set('vendor', paymentView.vendor);
    this.global.routerParams.set('paymentView', paymentView);
    this.global.routerParams.set('paymentInvoice', paymentView.paymentInvoice);
    this.global.routerParams.set(
      'paymentBillingId',
      paymentView.paymentBilling.id
    );
    this.global.routerParams.set('urlBackOutside', '/pages/payment-monitoring');

    if (
      paymentView.paymentStatus.code !==
        this.global.appConstant.cm.PAYMENT_STATUS_WAITING_APPROVAL &&
      paymentView.paymentStatus.code !==
        this.global.appConstant.cm.PAYMENT_STATUS_WAITING_CONFIRMATION
    ) {
      if (
        prcsCode === 'payment-monitoring' &&
        paymentView.paymentBilling.isInternal &&
        this.global.userSession.activeUserRole.code ===
          this.global.appConstant.ROLE_CODE_PAYMENT
      ) {
        this.global.routerParams.set('todo', 'edit');
      } else if (
        prcsCode === 'VENDOR-PAYMENT-REQUEST' &&
        !paymentView.paymentBilling.isInternal &&
        paymentView.vendor.id === this.global.userSession.vendor?.id
      ) {
        this.global.routerParams.set('todo', 'edit');
      } else if (
        this.global.userSession.activeUserRole.role.code ===
          this.global.appConstant.ROLE_CODE_PAYMENT &&
        prcsCode !== 'VENDOR-PAYMENT-REQUEST' &&
        !prcsCode.includes('APPROVAL-')
      ) {
        this.global.routerParams.set('todo', 'edit');
      } else {
        this.global.routerParams.set('todo', 'view');
      }

      if (
        prcsCode.replace('APPROVAL-', '') === 'PAYMENT-VOUCHER' ||
        this.global.routerParams.get('todo') === 'view'
      ) {
        this.router.navigate([
          '/pages/' +
            prcsCode.replace('APPROVAL-', '').toLowerCase() +
            '/detail'
        ]);
      } else {
        this.router.navigate([
          '/pages/' + prcsCode.replace('APPROVAL-', '').toLowerCase() + '/edit'
        ]);
      }
    } else {
      this.global.routerParams.set('todo', 'view');
      this.router.navigate([
        '/pages/' + prcsCode.replace('APPROVAL-', '').toLowerCase() + '/detail'
      ]);
    }
  }

  public doExport(): void {
    this.loadingBlockService.showInfo(
      this.translateService.instant('payment-monitoring.info.preparingData')
    );
    this.recapPrintService
      .print('/payment-monitoring/recap-payment', this.tableResponse.request)
      .subscribe(() => {
        this.loadingBlockService.showCompleteAndClose(
          this.translateService.instant(
            'payment-monitoring.info.successImported'
          )
        );
      });
  }

  public doUpdateStatusAndBudget(paymentRequestView: PaymentRequestView): void {
    const prompt = this.translateService.instant(
      'payment-monitoring.updateStatusAndBudget.msg'
    );
    const title = this.translateService.instant(
      'payment-monitoring.updateStatusAndBudget.title'
    );
    this.global.modalService
      .confirm(prompt, title)
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.httpClientService
            .post<Response>(
              '/payment-monitoring/update-status-and-budget',
              paymentRequestView.billingMemo.id
            )
            .subscribe(response => {
              this.setStateReady();
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccess(
                  this.translateService.instant(
                    'payment-monitoring.alert.msg.succesUpdateBudget'
                  )
                );
                this.tableResponse.reload();
              }
            });
        }
      });
  }

  public doReSynchronize(paymentRequestView: PaymentRequestView): void {
    const request: PaymentMonitoringReSyncRequest =
      new PaymentMonitoringReSyncRequest();
    request.billingMemoId = paymentRequestView.billingMemo.id;
    request.procurementVendorId = paymentRequestView.procurementVendor.id;
    this.setStateProcessing();
    this.httpClientService
      .post<Response>('/payment-monitoring/re-sync-bjb-gift', request)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccess(
            this.translateService.instant(
              'payment-monitoring.alert.msg.successSync'
            )
          );
        } else {
          this.global.alertService.showError(
            response.statusText
              ? response.statusText
              : this.translateService.instant(
                  'payment-monitoring.alert.msg.failedSync'
                )
          );
        }
        this.setStateReady();
      });
  }

  public doSynchronize(): void {
    this.httpClientService
      .post<Response>('/billing-memo/bjb-gift-sync-check', {})
      .subscribe(response => {
        this.loadingBlockService.showInfo(
          this.translateService.instant('billing-memo.info.preparing')
        );
        if (response && response.length !== 0) {
          this.appPopupService
            .open(
              AppPopupSyncPaymentMonitoringComponent,
              {
                onCloseModal:
                  this.websocketPaymentMonitoringService.onCloseModal
              },
              {
                backdrop: 'static',
                size: 'lg'
              }
            )
            .subscribe();
          this.websocketPaymentMonitoringService.appPopupSyncPaymentMonitoringComponent =
            this.appPopupService.componentInstance;
        } else {
          this.loadingBlockService.showCompleteAndClose(
            this.translateService.instant('billing-memo.info.successSync')
          );
        }
      });
  }

  public initializeWebSocketBillingMemoConnection(): void {
    this.websocketPaymentMonitoringService.connect().then(result => {
      if (this.isSyncRunning) {
        this.isSyncRunning = false;
        this.buildFormGroup();
        this.buildTableResponse();
        /* this.setOptionList();*/
      } else if (!this.isSyncRunning && result) {
        this.tableResponse.reload();
      }
      this.websocketPaymentMonitoringService.disconnect();
      this.initializeWebSocketBillingMemoConnection();
      this.isLoadingSync = false;
    });
  }

  public isSynchronizeRunning(): void {
    this.httpClientService
      .post<Boolean>(
        '/payment-monitoring/is-payment-status-synchronize-running',
        {
          data: null
        }
      )
      .subscribe((isSynchronizeRunning: boolean) => {
        this.isSyncRunning = isSynchronizeRunning;
        if (!this.isSyncRunning) {
          this.buildFormGroup();
          this.buildTableResponse();
        }
      });
  }

  public chekedUserAccessButton(): void {
    if (
      this.global.config.parameterModel.bjbGiftEnableSyncDataOnUnit.includes(
        this.global.userSession.user.organization.code
      ) ||
      this.global.userSession.user.organization.parentId === null
    ) {
      this.isAllowAccess = true;
    }
  }

  public isShowButtonEdit(paymentView: PaymentView): boolean {
    const prcsCode = paymentView.prcsCode;

    if (
      paymentView.paymentStatus.code !==
        this.global.appConstant.cm.PAYMENT_STATUS_WAITING_APPROVAL &&
      paymentView.paymentStatus.code !==
        this.global.appConstant.cm.PAYMENT_STATUS_WAITING_CONFIRMATION
    ) {
      if (
        prcsCode === 'INTERNAL-PAYMENT-REQUEST' &&
        paymentView.paymentBilling.isInternal &&
        this.global.userSession.activeUserRole.code ===
          this.global.appConstant.ROLE_CODE_PAYMENT
      ) {
        return true;
      } else if (
        prcsCode === 'VENDOR-PAYMENT-REQUEST' &&
        !paymentView.paymentBilling.isInternal &&
        paymentView.vendor.id === this.global.userSession.vendor?.id
      ) {
        return true;
      } else if (
        this.global.userSession.activeUserRole.role.code ===
          this.global.appConstant.ROLE_CODE_PAYMENT &&
        prcsCode !== 'VENDOR-PAYMENT-REQUEST' &&
        !prcsCode?.includes('APPROVAL-')
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
