<app-popup
  header="app-popup-final-price-detail.title"
  [isLoading]="formLoading"
>
  <app-card>
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <div
        class="row"
        *ngIf="!formLoading"
        style="
          border: 1px solid;
          border-color: var(--gray-400);
          box-sizing: border-box;
          border-radius: 10px;
          padding: 18px 0px;
          margin: 18px 0px;
        "
      >
        <div class="col-sm-5 pr-0">
          <div class="form-group row text-sm-left ml-1">
            <h4>{{
              'app-popup-final-price-detail.finalPriceSummary' | translate
            }}</h4>
          </div>
          <div class="form-group row text-sm-left ml-1">
            <h3>{{ quotation?.procurementVendor.vendor.name }}</h3>
          </div>
        </div>

        <ng-container *ngIf="isSingleWinner">
          <!-- bid price summary -->
          <div class="col-sm-3 ml-4 mr-3">
            <div class="row" style="justify-content: space-between">
              <p>{{
                'app-popup-final-price-detail.summary.saveOrLoss' | translate
              }}</p>
              <div class="d-flex" *ngIf="quotation?.profitLossStatus">
                <strong class="mr-2"
                  >{{
                    quotation?.profitLossAmount | resolveNumberToDecimal
                  }}%</strong
                >
                <app-badge-catalog
                  [color]="
                    quotation?.profitLossStatus?.code ===
                    global.appConstant.pm.PROFIT_LOSS_STATUS_SAVE
                      ? 'SUCCESS'
                      : 'DANGER'
                  "
                >
                  {{ quotation?.profitLossStatus?.name }}
                </app-badge-catalog>
              </div>
              <div *ngIf="quotation?.profitLossStatus === null"> - </div>
            </div>
            <div class="row" style="justify-content: space-between">
              <p>{{
                'app-popup-final-price-detail.summary.grandTotalBidPrice'
                  | translate
              }}</p>
              <strong
                >{{ quotation?.amount | resolveNumberToCurrency }} IDR</strong
              >
            </div>
            <div class="row text-right d-block">
              <i class="text-secondary"
                >({{
                  quotation?.amount | resolveNumberToWords: global.currentLang
                }}
                Rupiah)</i
              >
            </div>
          </div>
          <div style="border: 1px solid var(--gray-400)"></div>

          <!-- final price summary -->
          <div class="col-sm-3 ml-3">
            <div class="row" style="justify-content: space-between">
              <p>{{
                'app-popup-final-price-detail.summary.saveOrLoss' | translate
              }}</p>
              <div class="d-flex" *ngIf="quotation?.finalProfitLossStatus">
                <strong class="mr-2 text-primary"
                  >{{
                    quotation?.finalProfitLossAmount | resolveNumberToDecimal
                  }}%</strong
                >
                <app-badge-catalog
                  [color]="
                    quotation?.finalProfitLossStatus?.code ===
                    global.appConstant.pm.PROFIT_LOSS_STATUS_SAVE
                      ? 'SUCCESS'
                      : 'DANGER'
                  "
                >
                  {{ quotation?.finalProfitLossStatus?.name }}
                </app-badge-catalog>
              </div>
              <div *ngIf="quotation?.finalProfitLossStatus === null"> - </div>
            </div>
            <div class="row" style="justify-content: space-between">
              <p>{{
                'app-popup-final-price-detail.summary.grandTotalFinalPrice'
                  | translate
              }}</p>
              <strong class="text-primary"
                >{{
                  quotation?.finalAmount | resolveNumberToCurrency
                }}
                IDR</strong
              >
            </div>
            <div class="row text-right d-block">
              <i class="text-secondary"
                >({{
                  quotation?.finalAmount
                    | resolveNumberToWords: global.currentLang
                }}
                Rupiah)</i
              >
            </div>
          </div>
        </ng-container>
      </div>

      <app-accordion
        [isExpandAll]="true"
        [isLoading]="formLoading"
        *ngIf="!formLoading"
      >
        <app-accordion-item
          header="{{ 'app-popup-final-price-detail.itemList' | translate }}"
        >
          <app-table-xx
            [model]="tableResponse"
            stringUrl="/app-popup-final-price-detail/index"
            [isShowSearch]="false"
          >
          </app-table-xx>
        </app-accordion-item>
      </app-accordion>
    </form>
  </app-card>
</app-popup>
