import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { File } from '../../core/bean/file';
import { NotificationBlastUser } from '../../core/bean/notification-blast-user';
import { User } from '../../core/bean/user';
import { Vendor } from '../../core/bean/vendor';
import { VendorCategory } from '../../core/bean/vendor-category';
import { VendorStatus } from '../../core/bean/vendor-status';
import { VendorType } from '../../core/bean/vendor-type';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { NotificationBlastResponse } from './notification-blast-response';
@Component({
  templateUrl: 'notification-blast-add.component.html'
})
export class NotificationBlastAddComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public notificationBlastId: number;
  public notificationBlastResponse: NotificationBlastResponse = new NotificationBlastResponse();
  public dataRecord: Vendor[] = [];
  public vendorTypeList: any[] = [];
  public vendorType = '';
  public fileList: File[] = [];
  public tableReady = false;
  public vendorCategoryIdList: number[] = [];
  public vendorStatusIdList: number[] = [];
  public vendorTypeIdList: number[] = [];
  public vendorTypeNameList: string;
  public readonly vendorList: Vendor[] = [];
  public keyword = '$USERNAME, $CURRENT_DATE, $COMPANY_NAME, $VENDOR_ADDRESS, $VENDOR_PHONE, $USER_EMAIL';
  public isSave = false;
  public ccOptionList: OptionListModel<User> = new OptionListModel(
    false,
    'email'
  );
  public bccOptionList: OptionListModel<User> = new OptionListModel(
    false,
    'email'
  );
  public vendorStatusOptionList: OptionListModel<VendorStatus> = new OptionListModel();
  public vendorCategoryOptionList: OptionListModel<VendorCategory> = new OptionListModel();
  public vendorTypeOptionList: OptionListModel<VendorType> = new OptionListModel();
  public readonly urlUpdateNotificationBlast = '/notification-blast/update';
  public readonly urlInsertNotificationBlast = '/notification-blast/insert';

  public fileUploader: FileUploader = new FileUploader(
    '/notification-blast/',
    'notification-blast.title',
    this.global.appConstant.fileType.DOC_EMAIL_ATTACHMENT,
    false,
    5
  );
  public tableResponse: TableResponseModel<Vendor>;
  public tableResponseNotificationBlastUser: TableResponseModel<NotificationBlastUser>;
  constructor(translateService: TranslateService) {
    super('notification-blast', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setNotifBlastView();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.notificationBlastId = this.global.routerParams.get(
      'notificationBlastId'
    );
  }

  public setNotifBlastView(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      subject: [null, Validators.required()],
      content: [null, Validators.required()],
      cc: [null],
      attachment: [null],
      vendorStatusCode: [null],
      vendorTypeCode: [null],
      vendorCategoryCode: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<NotificationBlastResponse>(
        '/notification-blast/add',
        new RouteRequestModel(this.todo, this.notificationBlastId)
      )
      .subscribe((notificationBlastResponse: NotificationBlastResponse) => {
        this.notificationBlastResponse = notificationBlastResponse;

        this.ccOptionList.setRequestValues(this.notificationBlastResponse.userList);
        this.bccOptionList.setRequestValues(this.notificationBlastResponse.userList);
        this.vendorStatusOptionList.setRequestValues(
          this.notificationBlastResponse.vendorStatusList
        );

        if (this.notificationBlastResponse.vendorCategoryList) {
          this.notificationBlastResponse.vendorCategoryList.forEach(
            vendorCategory => {
              vendorCategory.name =
                vendorCategory.name +
                ' (' +
                vendorCategory.vendorType.name +
                ')';
            }
          );
        }

        this.vendorCategoryOptionList.setRequestValues(
          this.notificationBlastResponse.vendorCategoryList
        );
        this.vendorTypeOptionList.setRequestValues(
          this.notificationBlastResponse.vendorTypeList
        );

        this.formGroup
          .get('vendorTypeCode')
          .patchValue(this.notificationBlastResponse.vendorTypeList);

        if (
          this.notificationBlastResponse.notificationBlastAttachmentList &&
          this.notificationBlastResponse.notificationBlastAttachmentList.length !== 0
        ) {
          this.notificationBlastResponse.notificationBlastAttachmentList.forEach(
            notificationBlastAttachment => {
              this.fileList.push(notificationBlastAttachment.file);
            }
          );
          this.fileUploader.setFileList(this.fileList);
        }

        if (this.notificationBlastResponse.notificationBlast != null) {
          this.formGroup.patchValue({
            subject: this.notificationBlastResponse.notificationBlast.subject,
            content: this.notificationBlastResponse.notificationBlast.content,
            cc: this.notificationBlastResponse.userList,
            vendorStatusCode: this.notificationBlastResponse.vendorStatusList,
            vendorTypeCode: this.notificationBlastResponse.vendorTypeList,
            vendorCategoryCode: this.notificationBlastResponse.vendorCategoryList
          });

          if (
            this.notificationBlastResponse.vendorTypeList &&
            this.notificationBlastResponse.vendorTypeList.length > 0
          ) {
            const vendorType = this.notificationBlastResponse.vendorTypeList
              .map((i: VendorType) => i.name)
              .join(', ');
            this.vendorTypeNameList = vendorType.trim();
          }

          if (
            this.notificationBlastResponse.notificationBlastAttachmentList.length !==
            0
          ) {
            this.formGroup.patchValue({
              attachment: this.fileUploader.fileObjectList
            });
          }

          this.buildTableResponseView();
          this.tableReady = true;
        }

        this.tableResponse.setStateLoading();
        this.httpClientService
          .post<NotificationBlastResponse>('/notification-blast/index-vendor', new NotificationBlastResponse())
          .subscribe((vendorList: Vendor[]) => {
            this.vendorList.push(...vendorList);
            this.tableResponse.setRecords(vendorList);
            this.tableResponse.setTotalRecords(vendorList.length);
            this.tableResponse.reloadClient();
            this.tableResponse.setStateReady();
          });
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      },
      { field: 'email', header: 'table.column.email' }
    ]);
  }

  public buildTableResponseView(): void {
    this.tableResponseNotificationBlastUser = new TableResponseModel(
      this.moduleCode,
      [
        { field: 'user.name', header: 'table.column.name' },
        { field: 'user.email', header: 'table.column.email' }
      ]
    );
    this.tableResponseNotificationBlastUser.setRecords(this.notificationBlastResponse.notificationBlastUserList);
    this.tableResponseNotificationBlastUser.setTotalRecords(this.notificationBlastResponse.notificationBlastUserList.length);
  }

  public doCheckAll(event: any): void {
    if (event.target.checked) {
      this.dataRecord.splice(0);
      const isSearching = (this.tableResponse.request.globalFilter &&
        this.tableResponse.request.globalFilter !== '');

      const dataRecordFilter = this.tableResponse.searchRecords(this.tableResponse.records);

      if (this.tableResponse.records !== this.vendorList && !isSearching) {
        /* setelah filter pasti all records table beda */
        this.dataRecord.push(...this.tableResponse.records);

      } else if (!isSearching) {
        this.dataRecord.push(...this.vendorList);
      } else { /* globalFilter ada isinya */
        this.dataRecord.push(...dataRecordFilter);
      }

    } else {
      this.dataRecord.splice(0);
    }
  }

  public doCheck(vendor: Vendor): void {
    const indexOfVendor: number = this.dataRecord.indexOf(vendor);

    if (indexOfVendor === -1) {
      this.dataRecord.push(vendor);
      const indexVendorTable: number = this.tableResponse.records.indexOf(vendor);
      this.tableResponse.records.splice(indexVendorTable, 1);
      this.tableResponse.records.unshift(vendor);
    } else {
      this.dataRecord.splice(indexOfVendor, 1);
    }
  }

  public doSend(): void {
    this.isSave = true;
    this.validate();
    if (this.formGroup.valid && this.dataRecord && this.dataRecord.length > 0) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.saveNotificationBlast();
          }
        });
    }
  }

  public saveNotificationBlast(): void {
    this.setStateProcessing();
    this.notificationBlastResponse.attachmentList = this.formGroup.get(
      'attachment'
    ).value;
    this.notificationBlastResponse.notificationBlast = this.formGroup.value;
    this.notificationBlastResponse.vendorList = this.dataRecord;

    const ccList: Array<string> = this.formGroup.get('cc').value;
    if (ccList.length > 0) {
      const cc = ccList.map((i: any) => i.email).join(', ');
      this.notificationBlastResponse.notificationBlast.cc = cc.trim();
    } else {
      this.notificationBlastResponse.notificationBlast.cc = null;
    }
    const vendorCategoryList: Array<string> = this.formGroup.get(
      'vendorCategoryCode'
    ).value;
    if (vendorCategoryList && vendorCategoryList.length > 0) {
      const vendorCategory = vendorCategoryList
        .map((i: any) => i.code)
        .join(', ');
      this.notificationBlastResponse.notificationBlast.vendorCategoryCode = vendorCategory.trim();
    } else {
      this.notificationBlastResponse.notificationBlast.vendorCategoryCode = null;
    }
    const vendorStatusList: Array<string> = this.formGroup.get(
      'vendorStatusCode'
    ).value;
    if (vendorStatusList && vendorStatusList.length > 0) {
      const vendorStatus = vendorStatusList.map((i: any) => i.code).join(', ');
      this.notificationBlastResponse.notificationBlast.vendorStatusCode = vendorStatus.trim();
    } else {
      this.notificationBlastResponse.notificationBlast.vendorStatusCode = null;
    }
    this.vendorTypeList = this.formGroup.get('vendorTypeCode').value;
    this.vendorTypeList.forEach((element, index) => {
      if (element.isChecked) {
        if (index === this.vendorTypeList.length || this.vendorType === '') {
          this.vendorType = this.vendorType + element.code;
        } else {
          this.vendorType = this.vendorType + ', ' + element.code;
        }
      }
    });
    this.notificationBlastResponse.notificationBlast.vendorTypeCode =
      this.vendorType === '' ? null : this.vendorType;
    this.saveNotificationBlastToServer(
      this.urlSaveNotificationBlast,
      this.notificationBlastResponse
    ).subscribe(response => {
      if (response.status === ResponseStatusModel.OK) {
        this.global.alertService.showSuccessSavingOnNextRoute();
        this.router.navigate(['/pages/notification-blast']);
      } else {
        this.global.alertService.showError(response.statusText);
      }
      this.setStateReady();
    });
  }

  public get urlSaveNotificationBlast(): string {
    return this.todo === 'edit'
      ? this.urlUpdateNotificationBlast
      : this.urlInsertNotificationBlast;
  }

  public saveNotificationBlastToServer(
    urlSaveNotificationBlast: string,
    notificationBlastResponse: NotificationBlastResponse
  ): Observable<Response<NotificationBlastResponse>> {
    return this.httpClientService.post<Response<NotificationBlastResponse>>(
      urlSaveNotificationBlast,
      notificationBlastResponse
    );
  }

  public doBack(): void {
    this.router.navigate(['/pages/notification-blast']);
  }

  public doApply(): void {
    const formGroupValue = this.formGroup.value;
    if (formGroupValue.vendorStatusCode != null) {
      formGroupValue.vendorStatusCode.forEach(vendorStatus => {
        this.vendorStatusIdList.push(vendorStatus.id);
      });
    }
    if (formGroupValue.vendorCategoryCode != null) {
      formGroupValue.vendorCategoryCode.forEach(vendorCategory => {
        this.vendorCategoryIdList.push(vendorCategory.id);
      });
    }
    if (formGroupValue.vendorTypeCode != null) {
      formGroupValue.vendorTypeCode.forEach(vendorType => {
        if (vendorType.isChecked) {
          this.vendorTypeIdList.push(vendorType.id);
        }
      });
    }

    const formGroup = {
      vendorStatusIdList: this.vendorStatusIdList,
      vendorCategoryIdList: this.vendorCategoryIdList,
      vendorTypeIdList: this.vendorTypeIdList
    };

    this.tableResponse.setStateLoading();
    this.httpClientService
      .post<NotificationBlastResponse>('/notification-blast/index-vendor', formGroup)
      .subscribe((vendorList: Vendor[]) => {
        this.tableResponse.setRecords(vendorList);
        this.tableResponse.setTotalRecords(vendorList.length);
        if (vendorList.length === 0) {
          this.tableResponse.page.setRecords(vendorList);
          this.tableResponse.page.setTotalRecords(vendorList.length);
        }
        this.tableResponse.reloadClient();
        this.tableResponse.setStateReady();
      });

    this.vendorStatusIdList = [];
    this.vendorCategoryIdList = [];
    this.vendorTypeIdList = [];
  }
  public doReset(): void {
    this.dataRecord.splice(0, this.dataRecord.length);

    this.formGroup.get('vendorStatusCode').reset();
    this.formGroup.get('vendorTypeCode').reset();
    this.formGroup.get('vendorCategoryCode').reset();

    this.tableResponse.setRecords(this.vendorList ? this.vendorList : []);
    this.tableResponse.setTotalRecords(this.vendorList ? this.vendorList.length : 0);
    this.tableResponse.reloadClient();
    this.tableResponse.setStateReady();

    this.vendorStatusOptionList.setRequestValues(
      this.notificationBlastResponse.vendorStatusList
    );
    this.vendorCategoryOptionList.setRequestValues(
      this.notificationBlastResponse.vendorCategoryList
    );
    this.formGroup
      .get('vendorTypeCode')
      .patchValue(this.notificationBlastResponse.vendorTypeList);
    this.formGroup.get('vendorTypeCode').value.forEach(vendorType => {
      if (vendorType.isChecked) {
        vendorType.isChecked = false;
      }
    });
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
