<app-popup
  header="app-upload-images.popup.cropper"
  isShowHeaderCloseButton="false"
>
  <image-cropper
    [imageFile]="imageFile"
    [maintainAspectRatio]="isMaintainAspectRatio"
    [aspectRatio]="4 / 4"
    format="png"
    autoCrop="false"
    (imageCropped)="imageCropped($event)"
  ></image-cropper>
  <div class="button-group button-group-center py-3">
    <app-button color="SECONDARY" class="mr-2" (onClick)="doCancel()"
      >{{ 'app.button.cancel' | translate }}
    </app-button>
    <app-button (onClick)="doSave()"
      >{{ 'app.button.save' | translate }}
    </app-button>
  </div>
</app-popup>
