import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ContractType } from 'src/app/core/bean/contract-type';
import { DealingAgreementType } from 'src/app/core/bean/dealing-agreement-type';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { PickListModel } from '../../core/components/app-pick-list/model/pick-list-model';
import { FileUploader } from '../../core/components/upload/model/v1/file.uploader';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { ContractParameter } from './../../core/bean/contract-parameter';
import { ContractTemplate } from './../../core/bean/contract-template';
import { ContractTemplateParameter } from './../../core/bean/contract-template-parameter';
import { ContractTemplateEditAddRequest } from './contract-template-edit-add.request';
import { ContractTemplateResponse } from './contract-template-response';
@Component({
  templateUrl: './contract-template-edit-add.component.html'
})
export class ContractTemplateEditAddComponent extends BaseModuleComponent {
  public contractTemplateId: number;
  public contractTypeList: OptionListModel<any> = new OptionListModel();
  public dealingAgreementTypeList: OptionListModel<any> = new OptionListModel();
  public keywordPickListModel: PickListModel<ContractParameter>;
  public parameterPickListModel: PickListModel<ContractParameter>;
  public fileUploader: FileUploader = new FileUploader(
    '/contract-template/',
    'news.form.headerImage',
    this.global.appConstant.fileType.DOC_CONTRACT_TEMPLATE,
    false,
    1
  );

  constructor(translateService: TranslateService) {
    super('contract-template', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.contractTemplateId = this.global.routerParams.get(
      'contractTemplateId'
    );
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        {
          validators: [Validators.required()],
          asyncValidators: [
            Validators.existsAsync(
              '/contract-template/check-data-exist',
              null,
              'contract-template.msg.validation.codeExist',
              { id: this.contractTemplateId }
            )
          ]
        }
      ],
      name: [
        null,
        {
          validators: [Validators.required()],
          asyncValidators: [
            Validators.existsAsync(
              '/contract-template/check-data-exist',
              null,
              null,
              { id: this.contractTemplateId }
            )
          ]
        }
      ],
      templateGroup: [null, Validators.required()],
      contractType: [null, Validators.required()],
      dealingAgreementType: [null, Validators.required()],
      description: [null, Validators.compose([Validators.maxLength(512)])],
      file: [null, Validators.required()],
      organization: [null, Validators.required()]
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<ContractTemplateResponse>(
        '/contract-template/add-edit',
        new RouteRequestModel(this.todo, this.contractTemplateId)
      )
      .subscribe((contractTemplateResponse: ContractTemplateResponse) => {
        const {
          keywordListNotByCriteriaGroup,
          keywordListByCriteriaGroup,
          contractParameterListByCriteriaGroup,
          contractParameterNotByCriteriaGroup
        } = contractTemplateResponse;
        this.keywordPickListModel = new PickListModel(
          this.moduleCode,
          keywordListNotByCriteriaGroup,
          keywordListByCriteriaGroup
        );
        this.parameterPickListModel = new PickListModel(
          this.moduleCode,
          contractParameterNotByCriteriaGroup,
          contractParameterListByCriteriaGroup
        );
        this.contractTypeList.setRequestValues(contractTemplateResponse.contractTypeList);
        if (contractTemplateResponse.contractTemplate != null) {
          this.onChangeContractType(contractTemplateResponse.dealingAgreementTypeList[0].contractType);
          const {
            id,
            code,
            name,
            description
          } = contractTemplateResponse.contractTemplate;
          const file = [];
          file.push(contractTemplateResponse.contractTemplate.file);
          this.fileUploader.setFileList(file);
          this.formGroup.patchValue({
            id,
            code,
            name,
            description,
            organization: contractTemplateResponse.organizationList,
            file: this.fileUploader.fileObjectList,
            templateGroup: contractTemplateResponse.templateGroupList,
            contractType: contractTemplateResponse.dealingAgreementTypeList[0].contractType,
            dealingAgreementType: contractTemplateResponse.dealingAgreementTypeList
          });
        }
        this.setStateReady();
      });
  }

  public onChangeContractType(contractType: ContractType): void {
    if (contractType) {
      this.httpClientService
        .get<DealingAgreementType[]>
        ('/contract-template/get-dealing-agreement-list/' + contractType.id)
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.dealingAgreementTypeList.setRequestValues(response.body);
          }
        });
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const contractTemplateEditAddRequest = this.prepareContractTemplateResponse();
            const url =
              this.todo === 'edit'
                ? '/contract-template/update'
                : '/contract-template/insert';
            this.httpClientService
              .post<Response<ContractTemplate>>(url, contractTemplateEditAddRequest)
              .subscribe(response => {
                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/contract-template']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public prepareContractTemplateResponse(): ContractTemplateEditAddRequest {
    const contractTemplateEditAddRequest: ContractTemplateEditAddRequest = new ContractTemplateEditAddRequest();
    const contractTemplateParameterList = [];
    const contractTemplate: ContractTemplate = new ContractTemplate();
    const templateGroupFlag = this.formGroup.value.templateGroup.length - 1;
    contractTemplate.id = this.formGroup.value.id;
    contractTemplate.code = this.formGroup.value.code;
    contractTemplate.name = this.formGroup.value.name;
    contractTemplate.organization = this.formGroup.value.organization[this.formGroup.value.organization.length - 1];
    contractTemplate.templateGroup = this.formGroup.value.templateGroup[
      templateGroupFlag
    ];
    const dealingAgreementTypeCodeList: string[] = [];
    this.formGroup.value.dealingAgreementType.forEach((dealingAgreementType: DealingAgreementType) => {
      dealingAgreementTypeCodeList.push(dealingAgreementType.code);
    });

    contractTemplate.description = this.formGroup.value.description;

    this.keywordPickListModel.targetItemList.forEach(element => {
      const contractTemplateParameter: ContractTemplateParameter = new ContractTemplateParameter();
      contractTemplateParameter.contractParameter = element;
      contractTemplateParameter.contractTemplate = contractTemplate;
      contractTemplateParameterList.push(contractTemplateParameter);
    });

    this.parameterPickListModel.targetItemList.forEach(element => {
      const contractTemplateParameter: ContractTemplateParameter = new ContractTemplateParameter();
      contractTemplateParameter.contractParameter = element;
      contractTemplateParameter.contractTemplate = contractTemplate;
      contractTemplateParameterList.push(contractTemplateParameter);
    });

    contractTemplateEditAddRequest.contractTemplate = contractTemplate;
    contractTemplateEditAddRequest.fileObjectList = this.formGroup.value.file;
    contractTemplateEditAddRequest.dealingAgreementTypeCodeList = dealingAgreementTypeCodeList;
    contractTemplateEditAddRequest.contractTemplateParameterList = contractTemplateParameterList;

    return contractTemplateEditAddRequest;
  }

  public doCancel(): void {
    this.router.navigate(['/pages/contract-template']);
  }
}
