<app-popup
  header="app-contract-document.popup.contractReviewer.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="true"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <app-select
      formControlName="contractReviewerList"
      [optionList]="contractReviewerOptionList"
      [isShowBorder]="true"
      [isShowHover]="false"
    >
      <ng-template #content let-data>
        <div style="margin: 0 auto; display: flex; position: relative">
          <div
            style="
              width: 50px;
              height: 50px;
              overflow: hidden;
              border-radius: 50px;
            "
            class="mr-3"
          >
            <img
              style="width: 100%"
              [src]="
                global.userImageProfile('user', data.reviewByUser)
                  | resolveImgSrc
                  | async
              "
              height="auto"
              alt="user image"
              [default]="global.appConstant.core.URL_DEFAULT_PROFILE_IMAGE"
            />
          </div>

          <div class="d-inline-block">
            <ng-container>
              <span class="d-inline-flex">
                <h5 class="mr-1">{{ data.name }}</h5>
                <i class="text-secondary">( {{ data.email }} )</i>
              </span>
              <p class="text-secondary">{{ data.position }}</p>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </app-select>
  </form>
</app-popup>
