import { BaseEntity } from '../base/base-entity';
import { WorkPlan } from '../bean/work-plan';

export class PrinciplePermitEntity extends BaseEntity {
    workPlan: WorkPlan;
    number: string;
    createdDate: Date;
    description: string;
    name: string;
    sequence: number;
    subject: string;
}
