import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BudgetAllocation } from '../../../bean/budget-allocation';
import { AppPopupService } from '../app-popup.service';
import { AppPopupChooseBudgetComponent } from './app-popup-choose-budget.component';

@Injectable()
export class AppPopupChooseBudgetService {
  constructor(public appPopupService: AppPopupService) {}
  public open(
    isMultiple?: boolean,
    budgetAllocation?: BudgetAllocation
  ): Observable<any> {
    const isMultipleValue = isMultiple ? isMultiple : false;
    return this.appPopupService.open(
      AppPopupChooseBudgetComponent,
      {
        isMultiple: isMultipleValue,
        budgetAllocation
      },
      { size: 'xl' }
    );
  }
}
