<app-layout
  [isLoading]="formLoading"
  [backTo]="urlBackOutside"
  header="submit-quotation-vendor.breadcrumb.index"
>
  <ng-template #additionalTitle *ngIf="isShowBadgeStatus">
    <app-badge class="cml-2" *ngIf="todo === 'add'" color="INFO">
      {{ 'dynamic-master-attribute.quotationStatus.new' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        quotationStatus?.code ===
        global.appConstant.pm.QUOTATION_STATUS_SUBMITTED
      "
      color="SUCCESS"
    >
      {{ 'dynamic-master-attribute.quotationStatus.submitted' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        quotationStatus?.code === global.appConstant.pm.QUOTATION_STATUS_DRAFT
      "
      color="SECONDARY"
    >
      {{ 'dynamic-master-attribute.quotationStatus.draft' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        quotationStatus?.code ===
          global.appConstant.pm.QUOTATION_STATUS_CLOSED_RESPONSE
      "
      color="DANGER"
    >
      {{
        'dynamic-master-attribute.quotationStatus.closedResponse' | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        procurement?.procurementWorklistStatus?.code ===
          global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_HOLD
      "
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.worklistStatus.hold' | translate }}
    </app-badge>
    <app-badge-catalog
      class="cml-2"
      *ngIf="procurement?.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge-catalog
      *ngIf="
        procurement?.documentSubmissionType?.code ===
        global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE
      "
      class="cml-2"
      [color]="'SUCCESS'"
    >
      {{ procurement?.documentSubmissionMethod?.name }}
    </app-badge-catalog>
  </ng-template>

  <div class="row main-row submit-quotation-vendor-detail">
    <div class="col-lg-12 mb-4">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-alert-x
          color="DANGER"
          *ngIf="
            !formLoading &&
            (procurement?.procurementStatus?.code ===
              global.appConstant.pm
                .PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL ||
              procurement?.procurementStatus?.code ===
                global.appConstant.pm
                  .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL ||
              procurement?.procurementStatus?.code ===
                global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT)
          "
        >
          {{ canceledOrFailedMessage | translate }}
        </app-alert-x>
        <app-alert-icon
          color="INFO"
          icon="FILES_ALT_CIRCLE_CHECK"
          *ngIf="
            quotationStatus?.code ===
            global.appConstant.pm.QUOTATION_STATUS_SUBMITTED
          "
        >
          <div style="display: grid">
            <span
              >Your company has submitted for this quotation on
              {{
                quotation.submittedDate
                  | date: global.config.parameterModel.datetimeFormatShort
              }}
            </span>
            <span
              class="mt-3"
              (click)="doEditQuotation()"
              *ngIf="endDate.getTime() >= currentDate.getTime()"
            >
              <a
                class="mr-2"
                style="text-decoration-line: underline; font-weight: 500"
              >
                {{ 'procurement-reg-vendor.alert.msg.clickHere' | translate }}
                <span
                  ><em
                    class="pir pi-pencil-alt ml-1"
                    style="
                      top: auto;
                      left: auto;
                      font-size: 16px;
                      font-weight: 500;
                    "
                  ></em>
                </span>
              </a>
              <span class="ml-3"
                >to edit your quotation before due date on
                {{
                  submitQuotationResponse.procurementSchedule.endDate
                    | date: global.config.parameterModel.datetimeFormatShort
                }}</span
              >
            </span>
          </div>
        </app-alert-icon>
        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="submitQuotationResponse.workflowStepInfoModel"
        >
        </app-workflow-step-info>
        <app-card
          header="submit-quotation-vendor.detail.general.title"
          tips="submit-quotation-vendor.detail.general.tips"
        >
          <app-procurement-info [procurementId]="procurementId">
          </app-procurement-info>
        </app-card>

        <app-card
          header="submit-quotation-vendor.detail.procurementCriteria.title"
          tips="submit-quotation-vendor.detail.procurementCriteria.tips"
        >
          <ng-template #headerRight>
            <div class="button-group button-group-right">
              <app-button
                *ngIf="
                  !formLoading &&
                  !(
                    procurement.procurementWorklistStatus?.code === 'HOLD' &&
                    !quotation
                  ) &&
                  todo !== 'view'
                "
                (onClick)="doViewDetailVendor()"
                class="float-right"
                >{{
                  'submit-quotation-vendor.button.changeInProfile' | translate
                }}</app-button
              >
            </div>
          </ng-template>
          <div class="procurement-criteria-card">
            <div
              *ngIf="
                procurement.procurementWorklistStatus?.code === 'HOLD' &&
                !quotation
              "
              ><br /><br />
              <div
                class="pvc pv-file-list"
                style="width: 70px; height: 70px; margin: 0 auto"
              ></div
              ><br />
              <div class="text-center">
                <span class="ndtwc-text">
                  {{ 'app-table.noDataAvailable' | translate }} </span
                ><br />
                <span class="ndtwc-text">
                  {{ 'app-table.letsTryToAddList' | translate }}
                </span> </div
              ><br />
            </div>
            <div
              *ngIf="
                !(
                  procurement.procurementWorklistStatus?.code === 'HOLD' &&
                  !quotation
                )
              "
            >
              <br />
              <app-tab
                [model]="tabModel"
                position="VERTICAL"
                (onChangeTab)="doNext($event)"
              >
                <router-outlet></router-outlet>
              </app-tab>
            </div>
          </div>
        </app-card>

        <app-card
          header="submit-quotation-vendor.detail.itemRequest.title"
          tips="submit-quotation-vendor.detail.itemRequest.tips"
          *ngIf="
            procurement.documentSubmissionType?.code !==
              global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE ||
            stage === 2
          "
        >
          <ng-template #headerRight>
            <div class="button-group button-group-right">
              <app-button
                *ngIf="
                  procurement.vendorSelectionType?.code === 'MULTI_WINNER' &&
                  todo !== 'view'
                "
                (onClick)="doSelectItem()"
                title="{{ 'app.button.add' | translate }}"
              >
                <em class="pir pi-plus-circle"></em>
                {{ 'submit-quotation-vendor.button.addList' | translate }}
              </app-button>
            </div>
          </ng-template>
          <div class="item-request-card">
            <app-table-xx
              [model]="tableResponseItem"
              (onClick)="doViewItem($event)"
            >
              <ng-template #headerFilter>
                <div class="form-group row text-sm-left">
                  <div
                    class="col text-left"
                    [ngStyle]="{ 'min-width': '10rem' }"
                  >
                    <app-dropdown-select
                      size="XL"
                      formControlName="itemType"
                      type="COMBOBOX"
                      [optionList]="itemTypeOptionList"
                      (onChange)="onChangeItem()"
                      container="body"
                      placeholder="{{
                        'submit-quotation-vendor.placeholder.type' | translate
                      }}"
                    ></app-dropdown-select>
                  </div>
                </div>
              </ng-template>
              <ng-container>
                <ng-template #row>
                  <tr>
                    <td
                      *ngIf="
                        todo !== 'view' &&
                        procurement.vendorSelectionType?.code === 'MULTI_WINNER'
                      "
                    ></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-right" colspan="3">
                      {{
                        'submit-quotation-vendor.footer.goodsAmount' | translate
                      }}
                    </td>
                    <td colspan="99" class="text-right">
                      <strong>
                        {{
                          formGroup.value.goodsAmount | resolveNumberToCurrency
                        }}
                        IDR
                      </strong>
                    </td>
                  </tr>

                  <tr>
                    <td
                      *ngIf="
                        todo !== 'view' &&
                        procurement.vendorSelectionType?.code === 'MULTI_WINNER'
                      "
                    ></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-right" colspan="3">
                      {{
                        'submit-quotation-vendor.footer.serviceAmount'
                          | translate
                      }}
                    </td>
                    <td colspan="99" class="text-right">
                      <strong>
                        {{
                          formGroup.value.serviceAmount
                            | resolveNumberToCurrency
                        }}
                        IDR
                      </strong>
                    </td>
                  </tr>

                  <tr>
                    <td
                      *ngIf="
                        todo !== 'view' &&
                        procurement.vendorSelectionType?.code === 'MULTI_WINNER'
                      "
                    ></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-right" colspan="3">
                      {{ 'submit-quotation-vendor.footer.amount' | translate }}
                    </td>
                    <td colspan="99" class="text-right">
                      <strong>
                        {{ formGroup.value.amount | resolveNumberToCurrency }}
                        IDR
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      *ngIf="
                        todo !== 'view' &&
                        procurement.vendorSelectionType?.code === 'MULTI_WINNER'
                      "
                    ></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="4" class="text-right">
                      <i>
                        ({{
                          formGroup.value.amount || 0
                            | resolveNumberToWords: global.currentLang
                        }}
                        {{ 'Rupiah' }})
                      </i>
                    </td>
                  </tr>
                </ng-template>
              </ng-container>
              <ng-container
                *ngIf="
                  todo !== 'view' &&
                  procurement.vendorSelectionType?.code === 'MULTI_WINNER'
                "
              >
                <ng-template #actionButtons let-rowData>
                  <app-button
                    size="TN"
                    (onClick)="doDeleteItem(rowData)"
                    title="{{ 'app.tooltip.delete' | translate }}"
                  >
                    <em class="pir pi-trash-alt"></em>
                    {{ 'app.button.delete' | translate }}
                  </app-button>
                </ng-template>
              </ng-container>
            </app-table-xx>
          </div>
        </app-card>
        <div class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div
                class="button-group button-group-center"
                *ngIf="todo !== 'view'"
              >
                <app-button
                  *ngIf="
                    quotationStatus?.code !==
                    global.appConstant.pm.QUOTATION_STATUS_SUBMITTED
                  "
                  color="SECONDARY"
                  [outline]="true"
                  (onClick)="doSave()"
                  [disabled]="formSaving || checkDisableButtonSubmit()"
                  >{{ 'app.button.save' | translate }}
                </app-button>
                <app-button
                  (onClick)="doSubmit()"
                  [disabled]="formSaving || checkDisableButtonSubmit()"
                  >{{ 'app.button.submit' | translate }}</app-button
                >
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips
      *ngIf="todo !== 'view'"
      activeTipHeader="submit-quotation-vendor.detail.procurementCriteria.title"
    >
    </app-tips>
    <app-tips *ngIf="todo === 'view'"> </app-tips>
  </ng-template>
</app-layout>
