import { BudgetType } from '../../../../../../core/bean/budget-type';
import { WidgetBudgetSpendingTypeModelRespone } from './widget-budget-spending-type-model-response';

export class WidgetBudgetSpendingTypeResponse {
  widgetBudgetSpendingTypeModelResponseList: WidgetBudgetSpendingTypeModelRespone[];
  totalCapex: number;
  totalOpex: number;
  currency: string;
  budgetTypeList: BudgetType[];
}
