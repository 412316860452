<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card [isLoading]="formLoading" [isSaving]="formSaving">
      <h5 class="separator"
        >{{ 'vendor-certificate.vendor-data' | translate }}
      </h5>
      <form class="form-horizontal" [formGroup]="formGroup">
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-certificate.form.vendorName' | translate
          }}</label>
          <div class="col-sm-3 text-left">
            {{ vendorName }}
          </div>
          <div class="col-sm-4 text-left">
            <app-button mode="BYPASS" (onClick)="doViewDetailVendor()">{{
              'vendor-certificate.profile' | translate
            }}</app-button>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-certificate.form.vendorCode' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ vendorCode }}
          </div>
        </div>
      </form>
      <br />

      <h5 class="separator">{{
        'vendor-certificate.certificate-history' | translate
      }}</h5>
      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/vendor-certificate/detail"
      >
        <ng-template #actionButtons let-rowData>
          <app-button
            mode="BYPASS"
            size="TN"
            (onClick)="doReport(rowData)"
            *ngIf="rowData.isPrint"
            [disabled]="isLoading && rowData.id === vendorCertificateId"
            title="{{ 'vendor-certificate.vc.tooltip.print' | translate }}"
          >
            <em
              class="fas"
              [ngClass]="
                isLoading && rowData.id === vendorCertificateId
                  ? 'fa-circle-notch fa-spin'
                  : 'fa-print'
              "
            ></em>
          </app-button>
        </ng-template>
      </app-table>
      <div class="button-group button-group-center mt-5">
        <app-button mode="BYPASS" color="SECONDARY" (onClick)="doBack()">
          {{ 'app.button.back' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>
