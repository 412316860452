import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Validators } from 'src/app/core/validators';
import { SignatureModel } from '../../app-signature/signature.model';

@Component({
  templateUrl: './app-popup-signature.component.html',
  styleUrls: ['./app-popup-signature.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupSignatureComponent extends BaseComponentComponent {
  @Input() public stages: string;
  @Input() public header: string;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public model: SignatureModel = new SignatureModel();
  public isBlank: boolean;

  constructor(public translateService: TranslateService) {
    super('app-popup-signature');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      signatureImg: [null, Validators.required()],
      isCheck: [null]
    });
  }

  public onChangeBlank(event): void {
    this.isBlank = event.isBlank;
  }

  public doSubmit(): void {
    this.setStateProcessing();
    this.model.requestUpload().subscribe(() => {
      this.setStateReady();
      this.validate();
      if (this.formGroup.valid) {
        this.onChange.emit(this.formGroup.value.signatureImg);
      }
    });
  }
}
