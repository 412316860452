<app-popup header="Vendor Document-Add">
  <app-table
    [tableResponse]="tableResponse"
    [isServerSide]="true"
    stringUrl="/vendor-doc-master/file-type/index"
    customData="{{ fileTypeIdCustomData }}"
  >
    <ng-template #actionButtons let-rowData>
      <button
        type="button "
        class="btn btn-primary ml-2"
        (click)="doChoose(rowData)"
      >
        {{ 'app.button.select' | translate }}
      </button>
    </ng-template>
  </app-table>
</app-popup>
