<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="item.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
    <app-alert-x color="WARNING" *ngIf="ItemResponse.isNonActive">{{
      'item.alert.isNonActive' | translate
    }}</app-alert-x>
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.controls.code.isView ? (!formGroup.isView ? 'required' : ''): ''"
        >
          <label class="col-sm-3 control-label"
            >{{ 'item.code' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              formControlName="code"
              minlength="2"
              maxlength="32"
            ></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right" 
        [ngClass]="!formGroup.controls.name.isView ? (!formGroup.isView ? 'required' : ''): ''">
          <label class="col-sm-3 control-label"
            >{{ 'item.name' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-field
              formControlName="name"
              maxLength="512"
              type="text"
            ></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right" 
        [ngClass]="!formGroup.controls.itemType.isView ? (!formGroup.isView ? 'required' : ''): ''">
          <label class="col-sm-3 control-label">{{
            'item.item-type' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select
              [optionList]="itemTypeOptionList"
              formControlName="itemType"
              type="COMBOBOX"
            ></app-dropdown-select>
          </div>
        </div>
        <div class="form-group row text-sm-right" 
        [ngClass]="!formGroup.controls.uom.isView ? (!formGroup.isView ? 'required' : ''): ''">
          <label class="col-sm-3 control-label"
            >{{ 'item.unit' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select
                formControlName="uom"
                [optionList]="uomOptionList"
                type="AUTOCOMPLETE"
                (onChange)="autoCompleteValueChange($event)"
              >
            </app-dropdown-select>
          </div>
        </div>
        <div class="form-group row text-sm-right" 
        [ngClass]="!formGroup.controls.category.isView ? (!formGroup.isView ? 'required' : ''): ''">
          <label class="col-sm-3 control-label"
            >{{ 'item.category' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              formControlName="category"
              stringUrl="/item-category"
              header="Category"
              [onlyLastChild]="true"
            ></app-text-tree>
          </div>
        </div>
        <div class="form-group row text-sm-right" 
        [ngClass]="!formGroup.controls.dose.isView ? (!formGroup.isView ? 'required' : ''): ''">
          <label class="col-sm-3 control-label"
            >{{ 'item.dose' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-field
              formControlName="dose"
              maxlength="512"
            ></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label"
            >{{ 'item.minPrice' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-field
              formControlName="minPrice"
              type="currency"
              maxLength="16"
              (onChange)="doChooseMinPrice()"
            ></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label"
            >{{ 'item.maxPrice' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-field
              formControlName="maxPrice"
              type="currency"
              maxLength="16"
              (onChange)="doChooseMaxPrice()"
            ></app-text-field>
          </div>
        </div><div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label"
            >{{ 'item.averagePrice' | translate }} 
          </label>
          <div class="col-sm-9 text-left">
              {{
                global.converterService.convertMoney(itemAveragePrice)
              }}
              {{ 'IDR' }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'item.description' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description"></app-text-area>
          </div>
        </div>
        <div class="form-group row text-sm-right"
        *ngIf="itemStatus === this.global.appConstant.core.ITEM_STATUS_DONE">
          <label class="col-sm-3 control-label">{{
            'item.isActive' | translate
            }}</label>
          <div class="col-sm-4 text-left">
            <app-toggle-switch formControlName="isActive"
            (onChange)="onClickisActive($event)">
            </app-toggle-switch>
          </div>
        </div>
        
        <app-approval-prcs-x
          *ngIf="!formLoading && activityStatus === 'APPROVAL'"
          [workflowModelPrcs]="workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>

        <div class="col-lg-12" *ngIf="
          !formLoading && activityStatus !== 'APPROVAL' && workflowModelPrcs?.workflowPrcs">
          <h5 class="separator">{{
            'item.separator.logApproval' | translate
          }}</h5>
          <app-approval-log
            [moduleCode]="moduleCode"
            [workflowPrcs]="workflowModelPrcs.workflowPrcs"
          >
          </app-approval-log>
        </div>

        <div class="button-group button-group-center mt-5" *ngIf="activityStatus !== 'APPROVAL'">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button
            *ngIf="todo !== 'view'"
            color="PRIMARY"
            (onClick)="doSave()"
            [disabled]="formSaving"
            >{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
