import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppAutocompleteComponent } from './app-auto-complete.component';
@NgModule({
  imports: [VendorModule, AppTooltipWrapperModule],
  declarations: [AppAutocompleteComponent],
  exports: [AppAutocompleteComponent]
})
export class AppAutoCompleteModule {}
