<div class="row main-row">
  <div class="col-12">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <app-accordion [isLoading]="formLoading">
        <app-accordion-item
          header="{{
            'submit-quotation-vendor.accordion.quotationAdministration'
              | translate
          }}"
          [isExpanded]="true"
        >
          <ng-template #headerLeft>
            <div *ngIf="!isAdministrationCompleted">
              <em class="pir pi-clock" style="color: 'var(--warning)'"> </em>
            </div>
            <div *ngIf="isAdministrationCompleted">
              <em
                class="pir pi-check-circle"
                style="color: 'var(--success)'"
              ></em>
            </div>
          </ng-template>
          <ng-container>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3 text-left">{{
                'submit-quotation-vendor.form.letterNumber' | translate
              }}</label>
              <div class="col-sm-7 text-left">
                <app-text-field
                  formControlName="letterNumber"
                  size="XL"
                  (onChange)="onChange()"
                >
                </app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3 text-left">{{
                'submit-quotation-vendor.form.letterDate' | translate
              }}</label>
              <div class="col-sm-7 text-left">
                <app-date-picker-x
                  size="XL"
                  formControlName="letterDate"
                  [range]="true"
                  (onChange)="onChange()"
                >
                </app-date-picker-x>
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3 text-left">{{
                'submit-quotation-vendor.form.currency' | translate
              }}</label>
              <div class="col-sm-7 text-left">
                <app-dropdown-select
                  size="XL"
                  formControlName="currency"
                  type="COMBOBOX"
                  [optionList]="currencyOptionList"
                  (onChange)="onChange()"
                >
                </app-dropdown-select>
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3 text-left">{{
                'submit-quotation-vendor.form.letterOfQuotation' | translate
              }}</label>
              <div class="col-sm-8 text-left">
                <app-upload-x
                  size="XL"
                  formControlName="letterOfQuotation"
                  [fileUploader]="fileUploaderAdministration"
                  (onChange)="onChange()"
                >
                </app-upload-x>
              </div>
            </div>
          </ng-container>
        </app-accordion-item>
        <app-accordion-item
          header="{{ 'submit-quotation-vendor.accordion.bidBond' | translate }}"
          [isExpanded]="true"
        >
          <ng-template #headerLeft>
            <div *ngIf="!isBidBondCompleted">
              <em class="pir pi-clock" style="color: 'var(--warning)'"> </em>
            </div>
            <div *ngIf="isBidBondCompleted">
              <em
                class="pir pi-check-circle"
                style="color: 'var(--success)'"
              ></em>
            </div>
          </ng-template>
          <ng-container>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3 text-left">{{
                'submit-quotation-vendor.form.bidBondType' | translate
              }}</label>
              <div class="col-sm-7 text-left">
                <app-dropdown-select
                  size="XL"
                  formControlName="bondType"
                  type="COMBOBOX"
                  [optionList]="bondTypeOptionList"
                  (onChange)="onChange()"
                >
                </app-dropdown-select>
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3 text-left">{{
                'submit-quotation-vendor.form.bidBondNumber' | translate
              }}</label>
              <div class="col-sm-7 text-left">
                <app-text-field
                  formControlName="bidBondNumber"
                  size="XL"
                  (onChange)="onChange()"
                >
                </app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3 text-left">{{
                'submit-quotation-vendor.form.bidBondValue' | translate
              }}</label>
              <div class="col-sm-7 text-left">
                <app-currency
                  size="XL"
                  placeholder="{{
                    'submit-quotation-vendor.placeholder.bidBondValue'
                      | translate
                  }}"
                  [optionList]="currencyOptionList"
                  formControlName="amount"
                  (onChange)="onChange()"
                ></app-currency>
                <p *ngIf="todo !== 'view' && !checkValidityBidBond()">
                  <small class="text-danger"
                    >Minimum bid bond value is
                    {{
                      global.routerParams.get('minBidBondValue') || 0
                        | resolveNumberToCurrency
                    }}</small
                  ></p
                >
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3 text-left">{{
                'submit-quotation-vendor.form.validityPeriod' | translate
              }}</label>
              <div class="col-sm-7 text-left">
                <app-date-picker-x
                  size="XL"
                  formControlName="validityPeriod"
                  [range]="true"
                  (onChange)="onChange()"
                >
                </app-date-picker-x>
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3 text-left">{{
                'submit-quotation-vendor.form.bidBondAttachment' | translate
              }}</label>
              <div class="col-sm-8 text-left">
                <app-upload-x
                  size="XL"
                  formControlName="bidBondFile"
                  [fileUploader]="fileUploaderBidBond"
                  (onChange)="onChange()"
                >
                </app-upload-x>
              </div>
            </div>
          </ng-container>
        </app-accordion-item>
      </app-accordion>
    </form>
  </div>
</div>
