import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupWorkPlanComponent } from './app-popup-work-plan.component';
import { AppPopupWorkPlanService } from './app-popup-work-plan.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppTableModule],
  declarations: [AppPopupWorkPlanComponent],
  entryComponents: [AppPopupWorkPlanComponent],
  providers: [AppPopupWorkPlanService],
  exports: [AppPopupWorkPlanComponent]
})
export class AppPopupWorkPlanModule {}
