import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../../../../core/base/angular/base-component.component';
import { OptionListModel } from '../../../../../core/model/option-list-model';
import { WidgetSavingAndSpendingByTopItemRequest } from './widget-saving-and-spending-by-top-item-request';

@Component({
  selector: 'widget-saving-and-spending-by-top-item-popup',
  templateUrl: './widget-saving-and-spending-by-top-item-popup.component.html'
})
export class WidgetSavingAndSpendingByTopItemPopupComponent extends BaseComponentComponent {
  @Input() request: WidgetSavingAndSpendingByTopItemRequest;

  public yearOptionList: OptionListModel<number> = new OptionListModel(true);
  public maxResultOptionList: OptionListModel<number> = new OptionListModel(
    true
  );
  public sortByOptionList: OptionListModel<any> = new OptionListModel(true);

  @Output() onChange: EventEmitter<object> = new EventEmitter();
  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    super('dashboard-widget-saving-and-spending-by-top-item');
  }

  onInit(): void {
    this.setOptionList();
    this.buildAndSetFormGroup();
    this.setStateReady();
  }

  private setOptionList(): void {
    const sortByList = [
      {
        value: 'savingAmount DESC',
        name: this.translateService.instant(
          'dashboard-widget-saving-and-spending-by-top-item.label.filter.topSaving'
        )
      },
      {
        value: 'savingAmount ASC',
        name: this.translateService.instant(
          'dashboard-widget-saving-and-spending-by-top-item.label.filter.lowestSaving'
        )
      },
      {
        value: 'spendingAmount DESC',
        name: this.translateService.instant(
          'dashboard-widget-saving-and-spending-by-top-item.label.filter.topSpending'
        )
      },
      {
        value: 'spendingAmount ASC',
        name: this.translateService.instant(
          'dashboard-widget-saving-and-spending-by-top-item.label.filter.lowestSpending'
        )
      }
    ];
    this.sortByOptionList.setRequestValues(sortByList);
    this.maxResultOptionList.setRequestValues([5, 10]);
    const currentYear = new Date().getFullYear();
    this.yearOptionList.setRequestValues(
      Array(5)
        .fill(0)
        .map((fill: number, index: number) => currentYear - 4 + fill + index)
    );
  }

  private buildAndSetFormGroup(): void {
    const sortBy = this.sortByOptionList
      .getRequestValues()
      .filter(sortBy => sortBy.value === this.request.sortBy);
    this.formGroup = this.formBuilder.group({
      sortBy: [
        sortBy && sortBy.length > 0
          ? sortBy[0]
          : this.sortByOptionList.getRequestValues()[0]
      ],
      maxResult: [this.request?.maxResult ? this.request?.maxResult : 5],
      year: [
        this.request?.year ? this.request?.year : new Date().getFullYear()
      ],
      ignoreZero: [this.request?.ignoreZero]
    });
  }

  public doApply(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.request.sortBy = this.formGroup.get('sortBy').value
        ? this.formGroup.get('sortBy').value.value
        : 'savingAmount DESC';
      this.request.maxResult = this.formGroup.get('maxResult').value
        ? this.formGroup.get('maxResult').value
        : 5;
      this.request.year = this.formGroup.get('year').value
        ? this.formGroup.get('year').value
        : new Date().getFullYear();
      this.request.ignoreZero = this.formGroup.get('ignoreZero').value;
      this.onChange.emit(this.request);
    }
  }
}
