import { Component } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  templateUrl: './app-widget-login-information.component.html',
  styleUrls: ['./app-widget-login-information.component.scss']
})
export class AppWidgetLoginInformationComponent extends BaseComponentComponent {
  public lastLogin: Date;
  public organizationName: string;
  public currentDate: Date = new Date();
  constructor() {
    super('app-widget-login-information');
  }

  public onInit(): void {
    this.getAndSetLastLogin();
  }

  public getAndSetLastLogin(): void {
    this.httpClientService.get('/widget-login-information/index').subscribe((response: any) => {
      this.lastLogin = new Date(response.lastLogin);
      this.organizationName = response.organizationName;
      this.global.widgetLoadingStatus.set(this.moduleCode, true);
      this.setStateReady();
    });
  }
}
