import { CMConstant } from './cm-constant';
import { CoreConstant } from './core-constant';
import { ESMConstant } from './esm-constant';
import { FileTypeConstant } from './file-type-constant';
import { PMConstant } from './pm-constant';
import { VMConstant } from './vm-constant';

export class AppConstant {
  /* t3_role */
  public ROLE_CODE_SUPER_ADMIN = 'SUPER_ADMIN';
  public ROLE_CODE_VENDOR = 'VENDOR';
  public ROLE_CODE_USER_MVE = 'MVE';
  public ROLE_CODE_USER_PPA = 'FNC';
  public ROLE_CODE_GH_MVE = 'GH_MVE'; /* previous: pejabat_vm */
  public ROLE_CODE_PETUGAS_PENGADAAN_PUSAT = 'ANL';
  public ROLE_CODE_PBJ_UMUM = 'PGNA';
  public ROLE_CODE_PBJ_PUSAT = 'PBJ_PUSAT';
  public ROLE_CODE_PBJ_KANWIL = 'PBJ_KANWIL';
  public ROLE_CODE_PBJ_CABANG = 'PBJ_CABANG';
  public ROLE_CODE_USER_PEMOHON = 'TKNS';
  public ROLE_CODE_PLANNER = 'PLANNER';
  public ROLE_CODE_BUYER = 'BUYER';
  public ROLE_CODE_OFFICER_LEGAL = 'LGL_PGDN';
  public ROLE_CODE_MANAGER = 'MANAGER';
  public ROLE_CODE_USER = 'USER';
  public ROLE_CODE_ADM_JRC = 'ADM_JRC';
  public ROLE_CODE_ADMIN_MONITORING = 'ADMIN_MONITORING';
  public ROLE_CODE_SUPPORT = 'SUPPORT';
  public ROLE_CODE_STAFF_LEGAL = 'STAFF_LEGAL';
  public ROLE_CODE_PAYMENT = 'PAYMENT';
  public ROLE_CODE_DELIVERY = 'DELIVERY';
  public ROLE_CODE_PJB_PBJ_PUSAT = 'PJB_PBJ_PUSAT';
  public ROLE_CODE_EXECUTOR = 'EXECUTOR';
  public ROLE_CODE_APPROVER_CATALOG = 'APPROVER_CATALOG';

  /*t1_billing_memo_status untuk bjb gift*/
  public BILLING_MEMO_STATUS_FAILED_SYNCHRONIZE = 'FAILED_SYNCHRONIZE';

  public core: CoreConstant = new CoreConstant();
  public cm: CMConstant = new CMConstant();
  public esm: ESMConstant = new ESMConstant();
  public vm: VMConstant = new VMConstant();
  public fileType: FileTypeConstant = new FileTypeConstant();
  public pm: PMConstant = new PMConstant();
}
