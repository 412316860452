import { ApprovalModelPrcs } from '../../core/bean/approval-model-prcs';
import { TransactionLog } from '../../core/bean/transaction-log';
import { Vendor } from '../../core/bean/vendor';
import { VendorBlacklist } from '../../core/bean/vendor-blacklist';
import { VendorViolation } from '../../core/bean/vendor-violation';
import { VendorWhitelist } from '../../core/bean/vendor-whitelist';

export class VendorWhitelistResponse {
  vendorWhitelist: VendorWhitelist = new VendorWhitelist();
  vendorBlacklist: VendorBlacklist;
  vendor: Vendor = new Vendor();
  vendorViolationList: VendorViolation[] = [];
  isUserApproval: boolean;
  approvalModelPrcs: ApprovalModelPrcs = new ApprovalModelPrcs();
  transactionLog: TransactionLog = new TransactionLog();
}
