import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormControl,
  FormGroup
} from '@angular/forms';
import { take } from 'rxjs/operators';
import { Accessibility } from '../../bean/accessibility';
import { File } from '../../bean/file';
import { ProcurementDocType } from '../../bean/procurement-doc-type';
import { ProcurementType } from '../../bean/procurement-type';
import { AppConstant } from '../../constant/app-constant';
import { OptionListModel } from '../../model/option-list-model';
import { Validators } from '../../validators';
import {
  BaseUploadComponent,
  createUploaderModel,
  FileUploader,
  makeUploaderProvider,
  UploadDragAndDropEvent,
  Uploader,
  UploaderFile,
  UploadMapper,
  UploadService
} from '../upload';
import * as uploadAction from '../upload/actions/upload-file.action';
import { UploaderHelpers } from '../upload/helpers/upload-helpers';
import { AppUploadDocumentTableModel } from './app-upload-document-table-model';
import { AppUploadDocumentTableResponse } from './app-upload-document-table-response';
@Component({
  selector: 'app-upload-document-table-x',
  templateUrl: './app-upload-document-table-x.component.html',
  styleUrls: ['./app-upload-document-table-x.component.scss'],
  providers: [...makeUploaderProvider(AppUploadDocumentTableXComponent)],
  encapsulation: ViewEncapsulation.None
})
export class AppUploadDocumentTableXComponent
  extends BaseUploadComponent
  implements OnChanges
{
  @Input() public mode: string;
  @Input() public fromModuleCode: string;
  @Input() public objIdList: Map<number, number> = new Map();
  @Input() public defaultAccessibility: Accessibility;

  public isDragOver: boolean;
  public appUploadDocumentTableModelList: AppUploadDocumentTableModel[] = [];
  public appUploadDocDeleteList: AppUploadDocumentTableModel[] = [];
  public accessibilityOptionList: OptionListModel<ProcurementType> =
    new OptionListModel(false, 'name');
  public procurementDocTypeOptionList: OptionListModel<ProcurementDocType> =
    new OptionListModel(false, 'code');
  public uploadDocOptionList: OptionListModel<any> = new OptionListModel(
    false,
    'name'
  );
  public appUploadDocumentTableResponse: AppUploadDocumentTableResponse =
    new AppUploadDocumentTableResponse();
  public readonly DELETE = this.global.appConstant.core.CRUD_OPERATION_DELETE;
  public readonly UPDATE = this.global.appConstant.core.CRUD_OPERATION_UPDATE;
  public readonly INSERT = this.global.appConstant.core.CRUD_OPERATION_INSERT;
  public formControlTemp: FormControl = new FormControl();
  public formGroupUploadDoc: FormGroup = new FormGroup({});
  public isUploading = false;
  public progressValue = 0;
  public errorMessage = null;
  public isValidFormControl = true;

  public formControlMap: Map<string, FormControl> = new Map();
  public appConstant: AppConstant = new AppConstant();

  public badgeColor = {
    ALL: 'SUCCESS',
    PROCUREMENT_OFFICER: 'INFO',
    VENDOR: 'INFO'
  };

  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    uploadService: UploadService
  ) {
    super(
      'app-upload-document-table',
      controlContainer,
      elementRef,
      uploadService
    );
  }

  onInitBaseUpload(): void {
    this.formGroup.isSubmittedChanges.subscribe(() => {
      this.formGroupUploadDoc.markAllAsTouched();

      this.isValidFormControl = this.formGroupUploadDoc.valid;

      !this.isValidFormControl &&
        this.uploadDocList.value.length > 0 &&
        this.formControl.setErrors({ message: '' });
      if (!this.isValidFormControl) {
        const message = this.global.translateService.instant(
          'app-upload-document-table.msg.error.completeValue'
        );
        this.formControl.setErrors({ message: message });
        setTimeout(() => {
          this.isValidFormControl = true;
        }, 2000);
      }
    });
    this.global.translateService.onLangChange.subscribe(() => {
      this.procurementDocTypeOptionList.setRequestValues(
        this.appUploadDocumentTableResponse.procurementDocTypeList
      );
    });
    this.formGroupUploadDoc = this.formBuilder.group({
      uploadDocList: this.formBuilder.array([]),
      uploadDocSelect: null
    });
    this.setFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.objIdList &&
      this.objIdList.size > 0 &&
      changes.objIdList &&
      changes.objIdList.currentValue !== changes.objIdList.previousValue
    ) {
      const deletedObjectId: number[] = [];
      const newObjectId: number[] = [];
      this.objIdList.forEach((val, key) => {
        if (val === this.global.appConstant.core.CRUD_OPERATION_DELETE) {
          deletedObjectId.push(key);
        } else if (val === this.global.appConstant.core.CRUD_OPERATION_INSERT) {
          newObjectId.push(key);
        }
      });

      if (deletedObjectId && deletedObjectId.length > 0) {
        this.getDocList(
          deletedObjectId,
          this.global.appConstant.core.CRUD_OPERATION_DELETE
        );
      }
      if (newObjectId && newObjectId.length > 0) {
        this.getDocList(
          newObjectId,
          this.global.appConstant.core.CRUD_OPERATION_INSERT
        );
      }
    }
    if (
      this.mode &&
      changes.mode &&
      changes.mode.currentValue !== changes.mode.previousValue
    ) {
      if (this.mode === 'view') {
        this.formGroupUploadDoc.setIsView(true);
      }
    }
  }

  public getDocList(objIdList: number[], crudOperation: number) {
    this.setStateProcessing();
    this.httpClientService
      .get<AppUploadDocumentTableResponse>(
        '/app-upload-document-table/get-list-of-doc/' +
          this.fromModuleCode +
          '/' +
          objIdList.toString()
      )
      .subscribe(
        (appUploadDocumentTableModelList: AppUploadDocumentTableModel[]) => {
          if (
            appUploadDocumentTableModelList &&
            appUploadDocumentTableModelList.length > 0
          ) {
            if (
              crudOperation ===
              this.global.appConstant.core.CRUD_OPERATION_INSERT
            ) {
              this.setUploadDocListFromBackend(appUploadDocumentTableModelList);
              this.doCheckValue();
            } else if (
              crudOperation ===
              this.global.appConstant.core.CRUD_OPERATION_DELETE
            ) {
              const fileIdList = appUploadDocumentTableModelList.map(
                model => model.fileObject.file.id
              );
              const uploadDocSelectList = this.uploadDocList.value.filter(
                uploadDoc =>
                  fileIdList.includes(uploadDoc.fileObject[0].file.id)
              );
              if (uploadDocSelectList && uploadDocSelectList.length > 0) {
                this.deleteDocument(uploadDocSelectList);
              }
            }
          }
          this.setStateReady();
        }
      );
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<AppUploadDocumentTableResponse>('/app-upload-document-table/index')
      .subscribe(
        (appUploadDocumentTableResponse: AppUploadDocumentTableResponse) => {
          this.appUploadDocumentTableResponse = appUploadDocumentTableResponse;
          this.setUploader();
          this.procurementDocTypeOptionList.setRequestValues(
            appUploadDocumentTableResponse.procurementDocTypeList
          );
          this.accessibilityOptionList.setRequestValues(
            appUploadDocumentTableResponse.accessibilityList
          );
          if (this.formControl.value && this.formControl.value.length > 0) {
            this.setUploadDocListFromBackend(this.formControl.value);
          }
          this.setStateReady();
        }
      );
  }

  public getTranslation(data): string {
    if (data?.translationKey) {
      return this.global.translateService.instant(
        data.translationKey.module.code.toLowerCase() +
          '.' +
          data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public setUploadDocListFromBackend(value): void {
    this.appUploadDocumentTableModelList = [
      ...this.appUploadDocumentTableModelList,
      ...value
    ];

    let listOfFileId = [];
    if (this.uploadDocList && this.uploadDocList.value.length > 0) {
      this.uploadDocList.value.forEach(uploadDoc => {
        listOfFileId.push(uploadDoc.fileObject[0].file.id);
      });
    }
    this.appUploadDocumentTableModelList.forEach(record => {
      if (
        !listOfFileId ||
        (listOfFileId && listOfFileId.length === 0) ||
        (listOfFileId &&
          listOfFileId.length > 0 &&
          !listOfFileId.includes(record.fileObject.file.id))
      ) {
        let procDocTypeList: ProcurementDocType[] = [];
        let procDocTypeIdList = this.appUploadDocumentTableModelList
          .filter(
            uploadFile =>
              uploadFile.fileObject.file.id === record.fileObject.file.id
          )
          .map(uploadFile => uploadFile.procurementDocType)
          .filter(procDoc => procDoc)
          .map(procDocId => procDocId.id);
        if (procDocTypeIdList && procDocTypeIdList.length > 0) {
          this.procurementDocTypeOptionList
            .getRequestValues()
            .forEach(procDocType => {
              if (procDocTypeIdList.includes(procDocType.id)) {
                procDocTypeList.push(procDocType);
              }
            });
        } else {
          procDocTypeList = null;
        }
        this.appUploadDocumentTableResponse.procurementDocTypeList;
        this.fileUploader.setFileList([record.fileObject.file]);
        const formGroup = this.formBuilder.group({
          id: record.id,
          name: [record.fileObject.file.fileName, Validators.required()],
          fileObject: [this.fileUploader.fileObjectList, Validators.required()],
          accessibility: [record.accessibility, Validators.required()],
          procDocType: [
            procDocTypeList ? procDocTypeList : null,
            Validators.required()
          ],
          crudOperation: record.crudOperation
        });
        this.mode !== 'view'
          ? formGroup.get('fileObject').setIsView(true)
          : formGroup.setIsView(true);
        this.defaultAccessibility
          ? formGroup.get('accessibility').setIsView(true)
          : '';
        this.uploadDocList.push(formGroup);
        listOfFileId.push(record.fileObject.file.id);
      }
    });
    this.mode === 'view' ? this.formGroupUploadDoc.setIsView(true) : '';
    this.uploadDocOptionList.setRequestValues(this.uploadDocList.value);
    const fileList = [
      ...this.uploadDocList.value.map(uploadDoc => uploadDoc.fileObject[0].file)
    ];
    this.fileUploader.setFileList(fileList);
    this.formControlTemp.patchValue(this.fileUploader.fileObjectList);
    this.setUploader();
  }

  public get uploadDocList(): FormArray {
    return this.formGroupUploadDoc.get('uploadDocList') as FormArray;
  }

  public setUploader() {
    /* get fileType and fileExt gabungan */
    let fileTypeCode = '';
    let fileExtension = '';
    let maxSize = 0;
    this.appUploadDocumentTableResponse.procurementDocTypeList.forEach(
      (procDocType, index: number) => {
        maxSize =
          maxSize === 0 || maxSize > procDocType.fileType.maxSize
            ? procDocType.fileType.maxSize
            : maxSize;
        fileTypeCode += procDocType.fileType.code.toLowerCase();
        fileTypeCode =
          index !==
          this.appUploadDocumentTableResponse.procurementDocTypeList.length - 1
            ? fileTypeCode + ','
            : fileTypeCode;
        const fileExtensionList = procDocType.fileType.fileExtension
          .split(',')
          .filter(extension => !fileExtension.includes(extension));
        fileExtension +=
          fileExtension && fileExtensionList.length > 0
            ? (fileExtension.trim() !== '' && ',') + fileExtensionList.join(',')
            : fileExtensionList.join(',');
      }
    );
    this.fileUploader = new FileUploader(
      '/app-upload-document-table/',
      '',
      fileTypeCode,
      false,
      this.global.config.parameterModel.maxFilePr
    );
    this.model = createUploaderModel(this.model, this.fileUploader, {
      fileTypeCode,
      stringUrl: this.stringUrl,
      maxFile: this.global.config.parameterModel.maxFilePr || 1
    });
    this.model.options = {
      maxFile: this.global.config.parameterModel.maxFilePr,
      fileType: null,
      allowedExtension: fileExtension,
      maxSize,
      stringUrl: this.model.stringUrl + 'file/upload-temp',
      maxFileNameLength: this.appConstant.core.UPLOAD_MAX_LENGTH_FILE_NAME
    };
    this.model.isHandleError = false;
    this.uploadService
      .setUploader(this.model, this.formControlTemp)
      .subscribe((uploader: Uploader) => {
        this.formControlTemp.patchValue(null);
        this.formControlTemp.patchValue(UploadMapper.toValues(uploader));
        let status = false;
        let progress = 0;
        uploader.uploaderFileList.forEach((uploaderFile: UploaderFile) => {
          if (!status && uploaderFile.status === 'ONPROGRESS') {
            status = true;
          }
          if (uploaderFile.uploaderProgress) {
            progress += uploaderFile.uploaderProgress;
          } else if (uploaderFile.status === 'UPLOADED') {
            progress += 100;
          }
        });
        this.progressValue = progress / uploader.uploaderFileList.length;
        this.isUploading = this.isUploading ? status : this.isUploading;
        const formControlTemp = this.formControlTemp.value.filter(
          formControl => !formControl.isDeleted
        );
        formControlTemp.forEach((formControl, index) => {
          if (index >= this.uploadDocList.length && !formControl.isDeleted) {
            const formGroup = this.formBuilder.group({
              id: null,
              name: formControl.file.fileName,
              fileObject: [[formControl]],
              accessibility: [
                this.defaultAccessibility ? this.defaultAccessibility : null,
                Validators.required()
              ],
              procDocType: [null, Validators.required()],
              crudOperation: this.INSERT
            });
            this.defaultAccessibility
              ? formGroup.get('accessibility').setIsView(true)
              : '';
            formGroup.get('fileObject').setIsView(true);
            this.uploadDocList.push(formGroup);
          }
        });
        this.uploadDocOptionList.setRequestValues(this.uploadDocList.value);
        this.doCheckValue();
        this.fileUploader.isAllUploaded = true;
        this.fileUploader.totalNotDeletedData = this.uploadDocList.value.length;
        this.fileUploader.totalUploaded = this.uploadDocList.value.length;
      });
  }

  public translateProcurementDocType(
    procurementDocType: ProcurementDocType
  ): string {
    if (procurementDocType) {
      return this.global.translateService.instant(
        procurementDocType.translationKey.module.code.toLowerCase() +
          '.' +
          procurementDocType.translationKey.key
      );
    }
  }

  public calculateSize(size: number): string {
    return (
      (+size / 1000000).toFixed(
        this.global.appConstant.core.CURRENCY_PRECISSION_SCALE
      ) + ' MB'
    );
  }

  public onClick(): void {
    this.isUploading = false;
    this.model.error = null;
  }

  public onUploadChange(event: any): void {
    this.progressValue = 0;
    this.model.error = null;
    this.isUploading = true;
    this.uploadService.dispatch(
      new uploadAction.AddFile({ fileList: event.target.files })
    );
    event.target.value = null;
  }

  public onChangeProcDocTypeOrAccessibility(i: number, event?): string {
    if (event) {
      this.uploadDocList.value[i];
      const file: File = this.uploadDocList.value[i].fileObject[0].file;
      const value: ProcurementDocType = event;
      if (value) {
        if (file.size > value.fileType.maxSize) {
          return (this.errorMessage =
            'app-upload-document-table.validation.maxSize');
        } else if (
          !value.fileType.fileExtension
            .toLowerCase()
            .includes(file.extension.toLowerCase())
        ) {
          return (this.errorMessage =
            'app-upload-document-table.validation.fileExtension');
        }
      } else {
        return null;
      }
    }
    this.doCheckValue();
  }

  public doCheckValue(): void {
    const isHasNull =
      this.uploadDocList.value.filter(
        r =>
          !r.accessibility ||
          (r.accessibility && r.accessibility.length === 0) ||
          !r.procDocType ||
          (r.procDocType && r.procDocType.length === 0)
      ).length > 0;

    if (!isHasNull && this.uploadDocList && this.uploadDocList.length > 0) {
      let appUploadDocModelOutput: AppUploadDocumentTableModel[] = [];
      this.uploadDocList.value.forEach(uploadDoc => {
        let appUploadDocExist: AppUploadDocumentTableModel[] = [];
        if (uploadDoc.id) {
          appUploadDocExist = this.appUploadDocumentTableModelList.filter(
            appUploadDocExist =>
              appUploadDocExist.crudOperation !== this.DELETE &&
              uploadDoc.fileObject[0].file.id &&
              uploadDoc.fileObject[0].file.id ===
                appUploadDocExist.fileObject.file.id
          );
        }
        let isExist = false;
        if (appUploadDocExist && appUploadDocExist.length > 0) {
          if (
            appUploadDocExist[0].accessibility.id !== uploadDoc.accessibility.id
          ) {
            appUploadDocExist.forEach(appUploadDoc => {
              appUploadDoc.accessibility = uploadDoc.accessibility;
              appUploadDoc.crudOperation = this.UPDATE;
            });
          }
          isExist = true;
        }
        const procDocTypeList: ProcurementDocType[] = uploadDoc.procDocType;

        /* Set fileType in file  */
        const fileTypeList = procDocTypeList.map(procDoc => procDoc.fileType);
        uploadDoc.fileObject[0].file;
        if (
          !uploadDoc.fileObject[0].file.fileType ||
          (uploadDoc.fileObject[0].file.fileType &&
            !fileTypeList.includes(uploadDoc.fileObject[0].file.fileType))
        ) {
          const length =
            uploadDoc.fileObject[0].file.fileName.split('.').length;
          const extension =
            uploadDoc.fileObject[0].file.fileName.split('.')[length - 1];

          /* set fileType dengan fileType yang ada di procDocType yang di ceklis */
          fileTypeList.forEach(fileType => {
            if (fileType.fileExtension.includes(extension)) {
              uploadDoc.fileObject[0].file.fileType = fileType;
            }
          });

          /* set fileType dengan procDocTypeOptionList yang memiliki extensi yang sama dengan file */
          if (!uploadDoc.fileObject[0].file.fileType) {
            const fileType = this.procurementDocTypeOptionList
              .getRequestValues()
              .map(procDocType => procDocType.fileType)
              .filter(fileType => fileType.fileExtension.includes(extension));
            if (fileType && fileType.length > 0) {
              uploadDoc.fileObject[0].file.fileType = fileType[0];
            }
          }

          /* update appUploadDocModelExist */
          if (isExist) {
            appUploadDocExist.forEach(appUploadDoc => {
              appUploadDoc.fileObject.file.fileType =
                uploadDoc.fileObject[0].file.fileType;
              if (appUploadDoc.id) {
                appUploadDoc.crudOperation = this.UPDATE;
              }
            });
          }
        }

        procDocTypeList.forEach(procDocType => {
          if (isExist) {
            const uploadDocExist = appUploadDocExist.filter(
              appUploadDoc =>
                appUploadDoc.procurementDocType.id === procDocType.id
            );
            if (
              !uploadDocExist ||
              (uploadDocExist && uploadDocExist.length === 0)
            ) {
              /* proc_doc_type yang baru dipilih */
              const appUploadDoc: AppUploadDocumentTableModel =
                new AppUploadDocumentTableModel();
              appUploadDoc.crudOperation = this.INSERT;
              appUploadDoc.id = null;
              appUploadDoc.procurementDocType = procDocType;
              appUploadDoc.fileObject = appUploadDocExist[0].fileObject;
              appUploadDoc.accessibility = appUploadDocExist[0].accessibility;
              appUploadDocExist.push(appUploadDoc);
            }
          } else {
            const appUploadDocModelNew: AppUploadDocumentTableModel =
              new AppUploadDocumentTableModel();
            appUploadDocModelNew.accessibility = uploadDoc.accessibility;
            appUploadDocModelNew.crudOperation = this.INSERT;
            appUploadDocModelNew.fileObject = uploadDoc.fileObject[0];
            appUploadDocModelNew.id = null;
            appUploadDocModelNew.procurementDocType = procDocType;
            appUploadDocModelOutput.push(appUploadDocModelNew);
          }
        });
        /* proc_doc_type yang sudah tidak di pilih lagi */
        let deletedUploadDocFromProcDocType: AppUploadDocumentTableModel[] = [];
        if (isExist) {
          deletedUploadDocFromProcDocType = appUploadDocExist.filter(
            appUploadDoc =>
              !procDocTypeList.includes(
                this.procurementDocTypeOptionList
                  .getRequestValues()
                  .filter(
                    procDocType =>
                      procDocType.id === appUploadDoc.procurementDocType.id
                  )[0]
              )
          );
          deletedUploadDocFromProcDocType.forEach(deletedUpload => {
            deletedUpload.crudOperation = this.DELETE;
          });
        }
        appUploadDocModelOutput = [
          ...appUploadDocModelOutput,
          ...appUploadDocExist,
          ...deletedUploadDocFromProcDocType
        ];
      });
      const deleteAppUploadModel = this.appUploadDocumentTableModelList.filter(
        appUploadDoc =>
          appUploadDoc.id && appUploadDoc.crudOperation === this.DELETE
      );
      appUploadDocModelOutput = [
        ...appUploadDocModelOutput,
        ...deleteAppUploadModel
      ];
      /* SetValue for output */
      this.formGroupUploadDoc.setStateSubmitted();
      this.formControlTemp.setStateSubmitted();
      this.formControl.patchValue(appUploadDocModelOutput);
      this.formControl.setErrors(null);
      this.onChange.emit(appUploadDocModelOutput);
    } else {
      this.formControl.patchValue(null);
    }
  }

  public getProcDocTypeView(procDocTypeList: ProcurementDocType[]): string {
    let procDocTypeView = '';
    procDocTypeList.forEach((procDocType, index) => {
      this.getTranslation(procDocType);
      procDocTypeView += this.getTranslation(procDocType);
      index !== procDocTypeList.length - 1 ? (procDocTypeView += ', ') : '';
    });

    return procDocTypeView;
  }

  public doCancelUpload(uploaderFile: UploaderFile): void {
    this.uploadService.dispatch(
      new uploadAction.CancelUpload({ uploaderFile })
    );
  }

  public doReupload(uploaderFile: UploaderFile): void {
    this.uploadService.dispatch(new uploadAction.ReUpload({ uploaderFile }));
  }

  public onDragAndDropChange(
    uploadDragAndDropEvent: UploadDragAndDropEvent
  ): void {
    switch (uploadDragAndDropEvent.type) {
      case 'ON-DRAGOVER':
        this.isDragOver = true;
        break;
      case 'ON-DRAGLEAVE':
        this.isDragOver = false;
        break;
      case 'ON-DROP':
        this.isDragOver = false;
        this.uploadService.dispatch(
          new uploadAction.AddFile({
            fileList: uploadDragAndDropEvent.fileList
          })
        );
        break;
      default:
        break;
    }
  }

  public doDownloadFile(record: AppUploadDocumentTableModel): void {
    if (record.fileObject?.file) {
      const uploaderFile: UploaderFile = this.model.uploaderFileList.filter(
        uploaderFile =>
          uploaderFile.file?.uploadedFileName ===
          record.fileObject.file.uploadedFileName
      )[0];

      this.downloadService.download(
        UploaderHelpers.getFileUrlWithFileUploader(this.global, uploaderFile),
        uploaderFile.file.fileName
      );
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public doDelete(): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const uploadDocSelectList =
            this.formGroupUploadDoc.get('uploadDocSelect').value;
          this.deleteDocument(uploadDocSelectList);
        }
      });
  }

  public deleteDocument(uploadDocSelectList): void {
    uploadDocSelectList.forEach(selectList => {
      this.log.debug(selectList);
      const indexOfRecord = this.uploadDocList.value.findIndex(
        uploadDoc =>
          uploadDoc.fileObject[0].file.uploadedFileName ===
          selectList.fileObject[0].file.uploadedFileName
      );
      if (indexOfRecord !== -1) {
        if (this.uploadDocList.value[indexOfRecord].id) {
          this.uploadDocList.value[indexOfRecord].crudOperation = this.DELETE;
          this.appUploadDocumentTableModelList
            .filter(
              appUploadDoc =>
                appUploadDoc.fileObject.file.id &&
                appUploadDoc.fileObject.file.id ===
                  this.uploadDocList.value[indexOfRecord].fileObject[0].file.id
            )
            .forEach(
              appUploadDoc => (appUploadDoc.crudOperation = this.DELETE)
            );
        } else {
          this.appUploadDocumentTableModelList =
            this.appUploadDocumentTableModelList.filter(appUploadDoc => {
              appUploadDoc.fileObject.file.id &&
                +appUploadDoc.fileObject.file.id !==
                  +this.uploadDocList.value[indexOfRecord].fileObject[0].file
                    .id;
            });
        }
        this.uploadDocList.removeAt(indexOfRecord);
        this.uploadService.dispatch(
          new uploadAction.DeleteFile({
            index: indexOfRecord
          })
        );
      }
      this.uploadDocOptionList.setRequestValues(this.uploadDocList.value);
    });
    this.formGroup.markAsDirty();
  }
}
