import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartData, ChartTooltipItem } from 'chart.js';
import { BaseModuleComponent } from '../../base/angular/base-module.component';
import { ChartModel } from '../app-chart/models/chart-model';
import { BudgetFilterValuesModel } from './../app-budget-filter/model/budget-filter-values-model';
import { PlanComparisonInfoModel } from './model/plan-comparison-info-model';
import { PlanComparisonModel } from './model/plan-comparison-model';
import { PlanComparisonTemplateDataModel } from './model/plan-comparison-template-data-model';

@Component({
  selector: 'app-plan-comparison',
  templateUrl: './app-plan-comparison.component.html',
  styleUrls: ['./app-plan-comparison.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPlanComparisonComponent extends BaseModuleComponent {
  @Input() model: PlanComparisonModel;
  @Input() dataObject: any;
  @Input() dataObjectTarget: string;

  public moneyFormatTranslateKeyList: Array<string>;

  public templateDataList: Array<Array<PlanComparisonTemplateDataModel>> =
    new Array();

  private stringUrl: string;
  private colorSchemaList: Array<string>;
  private requestBody: object;

  private planComparisonInfoModelList: Array<PlanComparisonInfoModel>;

  constructor(translateService: TranslateService) {
    super('app-plan-comparison', translateService);
  }

  onInit(): void {
    this.setInitializationState();
    this.setStateMoneyFormatTranslateKeyList();
    this.dispatchTemplateDataModelList();
    this.handleModelListenerChanges();
    this.translateService.onLangChange.subscribe(() => {
      if (this.planComparisonInfoModelList) {
        this.setStateMoneyFormatTranslateKeyList();
        this.reload();
      }
    });
  }

  private setInitializationState(): void {
    this.stringUrl = this.model.stringUrl || '/app-plan-comparison-info/index';
    this.colorSchemaList = (this.model.options &&
      this.model.options.colorShemaList) || ['#204d95 ', '#782095', '#3d9520'];
    this.requestBody = this.model.requestBody || { id: 0, todo: 'string' };
  }

  private setStateMoneyFormatTranslateKeyList(): void {
    this.moneyFormatTranslateKeyList = [
      this.translateService.instant('app.moneyFormat.thousand'),
      this.translateService.instant('app.moneyFormat.million'),
      this.translateService.instant('app.moneyFormat.billion'),
      this.translateService.instant('app.moneyFormat.trillion'),
      this.translateService.instant('app.moneyFormat.quadrillion'),
      this.translateService.instant('app.moneyFormat.quantillion'),
      this.translateService.instant('app.moneyFormat.sextillion')
    ];
  }

  dispatchTemplateDataModelList(): void {
    this.httpClientService
      .post<PlanComparisonInfoModel[]>(this.stringUrl, this.requestBody)
      .subscribe((planComparisonModelList: PlanComparisonInfoModel[]) => {
        this.planComparisonInfoModelList = planComparisonModelList;
        this.reload();
      });
  }

  private reload(): void {
    this.templateDataList.splice(0);
    const bookedPlanComparisonTemplateDataModelList: Array<PlanComparisonTemplateDataModel> =
      new Array();
    const usedPlanComparisonTemplateDataModelList: Array<PlanComparisonTemplateDataModel> =
      new Array();
    const paidPlanComparisonTemplateDataModelList: Array<PlanComparisonTemplateDataModel> =
      new Array();
    this.templateDataList = [
      bookedPlanComparisonTemplateDataModelList,
      usedPlanComparisonTemplateDataModelList,
      paidPlanComparisonTemplateDataModelList
    ];
    this.planComparisonInfoModelList.forEach(
      (planComparisonInfoModel: PlanComparisonInfoModel, index: number) => {
        const bookedPlanComparisonTemplateDataModel =
          this.createPlanComparisonTemplateDataModel(
            planComparisonInfoModel,
            index,
            'app-plan-comparison.totalBudgetAndBookedBudgetComparison',
            planComparisonInfoModel.bookedBudgetPercentage,
            'app-plan-comparison.bookedBudget',
            planComparisonInfoModel.bookedBudget,
            'app-plan-comparison.onProcessWorkPlan',
            planComparisonInfoModel.realizedPlan,
            this.global.appConstant.esm.WORK_PLAN_STATUS_TYPE_ON_PROCESS_WORK_PLAN
          );
        const usedPlanComparisonTemplateDataModel =
          this.createPlanComparisonTemplateDataModel(
            planComparisonInfoModel,
            index,
            'app-plan-comparison.totalBudgetAndUsedBudgetComparison',
            planComparisonInfoModel.usedBudgetPercentage,
            'app-plan-comparison.usedBudget',
            planComparisonInfoModel.usedBudget,
            'app-plan-comparison.procurementCompleteWorkPlan',
            planComparisonInfoModel.procurementCompletePlan,
            this.global.appConstant.esm.WORK_PLAN_STATUS_TYPE_ON_PROCESS_PROCUREMENT
          );
        const paidPlanComparisonTemplateDataModel =
          this.createPlanComparisonTemplateDataModel(
            planComparisonInfoModel,
            index,
            'app-plan-comparison.totalBudgetAndPaidOffBudgetComparison',
            planComparisonInfoModel.paidOffBudgetPercentage,
            'app-plan-comparison.paidOffBudget',
            planComparisonInfoModel.paidOffBudget,
            'app-plan-comparison.paymentCompletePlan',
            planComparisonInfoModel.paymentCompletePlan,
            this.global.appConstant.esm.WORK_PLAN_STATUS_TYPE_ON_PROCESS_PAYMENT
          );
        this.templateDataList[0].push(bookedPlanComparisonTemplateDataModel);
        this.templateDataList[1].push(usedPlanComparisonTemplateDataModel);
        this.templateDataList[2].push(paidPlanComparisonTemplateDataModel);
      }
    );
    this.loadingBlockService.close();
  }

  private createPlanComparisonTemplateDataModel(
    planComparisionInfoModel: PlanComparisonInfoModel,
    index: number,
    title: string,
    percentage: number,
    secondDataSlotTitle: string,
    secondData: number,
    fourthDataSlotTitle: string,
    fourthData: number,
    statusWorkplanFromWidget: string
  ): PlanComparisonTemplateDataModel {
    const chartLabels = [
      this.translateService.instant(secondDataSlotTitle),
      this.translateService.instant('app-plan-comparison.budgetTotal')
    ];

    const chartDataSets = [percentage, 100 - percentage];
    const chartModel = new ChartModel(
      'doughnut',
      chartLabels,
      chartDataSets,
      null,
      {
        defaultColor: [this.colorSchemaList[index], '#a0a0a0'],
        tooltips: {
          callbacks: {
            // tslint:disable-next-line:typedef
            label(item: ChartTooltipItem, data: ChartData) {
              const dataList: number[] = data.datasets[0].data as Array<number>;
              const totalData = dataList.reduce(
                (total: number, value: number) => total + value
              );
              const percentage = (dataList[item.index] / totalData) * 100;
              return (
                (data.labels[item.index] as string) + ': ' + percentage + '%'
              );
            }
          }
        }
      }
    );
    return {
      title,
      chartModel,
      type: planComparisionInfoModel.type,
      firstDataSlot: {
        title: 'app-plan-comparison.budgetTotal',
        value: this.global.converterService.convertToShortMoney(
          planComparisionInfoModel.totalBudget || 0,
          this.moneyFormatTranslateKeyList
        ) as string
      },
      secondDataSlot: {
        title: secondDataSlotTitle,
        value: this.global.converterService.convertToShortMoney(
          secondData || 0,
          this.moneyFormatTranslateKeyList
        ) as string
      },
      thirdDataSlot: {
        title: 'app-plan-comparison.workPlanTotal',
        value: planComparisionInfoModel.totalPlan
      },
      fourthDataSlot: {
        title: fourthDataSlotTitle,
        value: fourthData,
        statusWorkplanFromWidget
      }
    };
  }

  private handleModelListenerChanges(): void {
    this.model.requestBodyChanges.subscribe(() => {
      this.setInitializationState();
      this.dispatchTemplateDataModelList();
    });
    this.model.reloadChanges.subscribe(() => {
      this.setInitializationState();
      this.dispatchTemplateDataModelList();
    });
  }

  public doShowMoreDetailWorkPlan(statusWorkplanFromWidget: string): void {
    let customData;
    let customDataList: String[];
    customDataList = statusWorkplanFromWidget.split(',');
    if (this.model.requestBody) {
      const budgetFilterValueModel: BudgetFilterValuesModel = this.model
        .requestBody as BudgetFilterValuesModel;
      customData = {
        statusType: customDataList[0],
        organization: budgetFilterValueModel.organization,
        periode: budgetFilterValueModel.periode,
        budgetTypeCode: customDataList[1]
      };
    } else {
      customData = {
        statusType: customDataList[0],
        budgetTypeCode: customDataList[1]
      };
    }
    this.global.routerParams.set(
      'statusWorkplanFromWidget',
      JSON.stringify(customData)
    );
    this.router.navigate(['/pages/work-plan']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
