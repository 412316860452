import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { Module } from '../bean/module';
import { Procurement } from '../bean/procurement';
import { User } from '../bean/user';

export class ProcurementFailedEntity extends BaseWorkflowObjectEntity {
  procurement: Procurement;
  module: Module;
  statusHistory: string;
  processedByUser: User;
  failedDate: Date;
  note: string;
}
