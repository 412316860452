<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="all-performance.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-fieldset>
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'all-performance.form.vendorCode' | translate }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field autofocus="true" size="SM"
                formControlName="vendorCode"></app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'all-performance.form.vendorName' | translate }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="vendorName"></app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'all-performance.form.period' | translate }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box [optionList]="periodList" formControlName="period">
              </app-combo-box>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{ 'all-performance.form.year'
              | translate }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box size="SM" [optionList]="yearList"
                formControlName="year">
              </app-combo-box>
            </div>
          </div>

          <div class="button-group button-group-center mt-5">
            <app-button mode="BYPASS" color="SECONDARY" (onClick)="doReset()">
              <em class="fas fa-sync-alt"></em>
              {{ 'app.button.reset' | translate }}
            </app-button>
            <app-button mode="BYPASS" color="PRIMARY" (onClick)="doApply()"
              [disabled]="formSaving"><em class="fas fa-check"></em>
              {{ 'app.button.apply' | translate }}
            </app-button>
          </div>
        </form>

      </app-fieldset>
      <app-table [model]="tableResponse" [isServerSide]="true"
        stringUrl="/all-performance/index">
        <ng-template #headerButtons>
          <app-button mode="BYPASS" (onClick)="doExport()"
            [disabled]="isLoading">
            <em class="fas"
              [ngClass]="!isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'"></em>
            {{ 'app.button.export' | translate }}
          </app-button>
          <app-button (onClick)="doGenerate()">{{ 'all-performance.generate' |
            translate }}</app-button>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button mode="BYPASS" size="TN" (onClick)="doView(rowData)">
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>