import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppChatModule } from 'src/app/core/components/app-chat/app-chat.module';
import { AppItemRequestModule } from 'src/app/core/components/app-item-request/app-item-request.module';
import { AppPopupSignatureModule } from 'src/app/core/components/app-popup/app-popup-signature/app-popup-signature.module';
import { AppProcurementInfoModule } from 'src/app/core/components/app-procurement-info/app-procurement-info.module';
import { AppQrCodeModule } from 'src/app/core/components/app-qr-code/app-qr-code.module';
import { AppSignatureModule } from 'src/app/core/components/app-signature/app-signature.module';
import { AppWorkflowStepInfoModule } from 'src/app/core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AanwijzingEditComponent } from './aanwijzing-edit.component';
import { AanwijzingPopupCancelComponent } from './aanwijzing-popup-cancel.component';
import { AanwijzingPopupCreateSessionComponent } from './aanwijzing-popup-create-session.component';
import { AanwijzingPopupInviteParticipantNewComponent } from './aanwijzing-popup-invite-participant-new.component';
import { AanwijzingPopupInviteParticipantComponent } from './aanwijzing-popup-invite-participant.component';
import { AanwijzingPopupJoinOfflineComponent } from './aanwijzing-popup-join-offline.component';
import { AanwijzingPopupShowQRComponent } from './aanwijzing-popup-show-qr.component';
import { AanwijzingSessionComponent } from './aanwijzing-session.component';
import { AanwijzingComponent } from './aanwijzing.component';

const routes = [
  { path: '', component: AanwijzingComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: AanwijzingEditComponent,
    data: { breadcrumb: 'aanwijzing.breadcrumb.details' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: AanwijzingEditComponent,
    data: { breadcrumb: 'aanwijzing.breadcrumb.details' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: AanwijzingEditComponent,
    data: { breadcrumb: 'aanwijzing.breadcrumb.details' }
  },
  {
    path: 'session-room',
    component: AanwijzingSessionComponent,
    data: { breadcrumb: 'aanwijzing.breadcrumb.details' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppItemRequestModule,
    AppApprovalPrcsXModule,
    AppProcurementInfoModule,
    AppWorkflowStepInfoModule,
    AppQrCodeModule,
    AppChatModule,
    AppSignatureModule,
    AppPopupSignatureModule,
    AppBadgeCatalogModule
  ],
  declarations: [
    AanwijzingEditComponent,
    AanwijzingComponent,
    AanwijzingSessionComponent,
    AanwijzingPopupCreateSessionComponent,
    AanwijzingPopupInviteParticipantComponent,
    AanwijzingPopupInviteParticipantNewComponent,
    AanwijzingPopupShowQRComponent,
    AanwijzingPopupCancelComponent,
    AanwijzingPopupJoinOfflineComponent
  ],
  providers: [],
  entryComponents: [
    AanwijzingPopupCreateSessionComponent,
    AanwijzingPopupInviteParticipantComponent,
    AanwijzingSessionComponent,
    AanwijzingPopupInviteParticipantNewComponent,
    AanwijzingPopupShowQRComponent,
    AanwijzingPopupCancelComponent,
    AanwijzingPopupJoinOfflineComponent
  ],
  exports: [
    AanwijzingPopupCreateSessionComponent,
    AanwijzingPopupInviteParticipantComponent,
    AanwijzingSessionComponent,
    AanwijzingPopupInviteParticipantNewComponent,
    AanwijzingPopupShowQRComponent,
    AanwijzingPopupCancelComponent,
    AanwijzingPopupJoinOfflineComponent
  ]
})
export class AanwijzingModule {}
