<div class="row main-row app-accordion-row">
  <div class="col-12">
    <app-card
      header="payment-request-review.title"
      detail="payment-request-review.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-accordion
        [expandAll]="false"
        [isShowControls]="false"
        *ngIf="!formLoading"
      >
        <app-accordion-item
          header="payment-request-review.accordion.paymentRequest"
          [active]="true"
          [isExpanded]="true"
        >
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-request-review.form.title' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ paymentBillingView?.title }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-request-review.form.orderNumber' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ paymentBillingView?.code }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-request-review.form.stageName' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ paymentBillingView?.sowPaymentTerm?.paymentTerm?.name }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-request-review.form.term' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ paymentBillingView?.stageOfWork?.name }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-request-review.form.amount' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{
                global.converterService.convertMoney(paymentBillingView?.amount)
              }}
              {{ 'IDR' }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-request-review.form.remainingBills' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{
                global.converterService.convertMoney(
                  paymentBillingView?.remainingAmount
                )
              }}
              {{ 'IDR' }}
            </div>
          </div>
          <h5 class="separator">{{
            'payment-request-review.separator.paymentRequestDataForm'
              | translate
          }}</h5>
          <h6 class="separator">{{
            'payment-request-review.separator.invoice' | translate
          }}</h6>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-request-review.form.invoiceNumber' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ paymentInvoice?.code }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-request-review.form.invoiceDate' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{
                paymentInvoice?.invoiceDate
                  | date: global.config.parameterModel.dateFormatShort
              }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-request-review.form.requestedBills' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{
                global.converterService.convertMoney(
                  paymentInvoice?.payment?.amount
                )
              }}
              {{ 'IDR' }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-request-review.form.file' | translate
            }}</label>
            <div class="col">
              <div class="col-12 text-left p-0">
                <a
                  href="#"
                  (click)="
                    doDownload(
                      $event,
                      '/payment-request-review/file/view/' +
                        paymentInvoice.docFile.uploadedFileName +
                        '/' +
                        paymentInvoice.docFile.fileType.code,
                      paymentInvoice.docFile.fileName
                    )
                  "
                >
                  {{
                    paymentInvoice?.docFile?.fileName
                      ? paymentInvoice?.docFile?.fileName
                      : '-'
                  }}
                </a>
              </div>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-request-review.form.description' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              {{ paymentInvoice?.description || '-' }}
            </div>
          </div>
          <h6 class="separator">{{
            'payment-request-review.separator.billingTerms' | translate
          }}</h6>
          <app-table
            [model]="tableResponse"
            [isServerSide]="true"
            stringUrl="/payment-request-review/get-payment-billing-document-list"
          >
          </app-table>
          <h6 class="separator">{{
            'payment-request-review.separator.reviewPaymentRequest' | translate
          }}</h6>
          <form class="form-horizontal" [formGroup]="formGroup" novalidate>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'payment-request-review.form.status' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-radio
                  size="MD"
                  formControlName="status"
                  [optionList]="statusOptionList"
                  position="HORIZONTAL"
                ></app-radio>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'payment-request-review.form.note' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-area formControlName="note" maxLength="512">
                </app-text-area>
              </div>
            </div>
          </form>
          <h6 class="separator">{{
            'payment-request-review.separator.paymentRequestReviewHistory'
              | translate
          }}</h6>
          <app-table [model]="tableResponsePaymentHistory"> </app-table>
        </app-accordion-item>
        <app-accordion-item
          header="payment-request-review.accordion.deliverableItems"
        >
          <app-deliverable-item
            *ngIf="!formLoading && isDeliverableItem"
            [paymentId]="paymentInvoice.payment.id"
          >
          </app-deliverable-item>
        </app-accordion-item>
        <app-accordion-item
          header="payment-request-review.accordion.vendorInformation"
        >
          <app-vendor-payment-information
            [todo]="'view'"
            [paymentId]="paymentId"
            [isSubmit]="formGroup.get('isSubmit').value"
          >
          </app-vendor-payment-information>
        </app-accordion-item>
      </app-accordion>
      <div
        class="floating-button mt-5"
        *ngIf="!paymentRequestReviewEditResponse.isUserApproval"
      >
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button
                mode="BYPASS"
                color="SECONDARY"
                (onClick)="doCancel()"
                class="mr-1"
                [disabled]="formSaving"
                >{{ 'app.button.back' | translate }}
              </app-button>
              <app-button
                mode="BYPASS"
                color="PRIMARY"
                (onClick)="doSave()"
                class="mr-1"
                [disabled]="formSaving"
                *ngIf="!formGroup.isView"
                >{{ 'app.button.save' | translate }}
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
<app-approval-prcs-x
  *ngIf="paymentRequestReviewEditResponse.isUserApproval && !formLoading"
  [workflowModelPrcs]="paymentHistory.payment.workflowModelPrcs"
  [approvalModelPrcsId]="approvalModelPrcsId"
>
</app-approval-prcs-x>
