import { NgModule } from '@angular/core';
import { TableModule } from 'src/app/core/components/table/table.module';
import { PipesModule } from 'src/app/core/pipe/pipe.module';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetTotalAvailablePaymentComponent } from './widget-total-available-payment.component';

@NgModule({
  imports: [VendorModule, WidgetCardModule, TableModule, PipesModule],
  declarations: [WidgetTotalAvailablePaymentComponent]
})
export class WidgetTotalAvailablePaymentModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetTotalAvailablePaymentComponent.moduleCode,
      WidgetTotalAvailablePaymentComponent,
      'w-100'
    );
  }
}
