<app-layout
  [isLoading]="formLoading"
  [backTo]="urlBackOutside"
  header="vendor-reference.breadcrumb.index"
>
  <ng-template #additionalTitle *ngIf="isShowBadgeStatus">
    <app-badge-catalog
      class="cml-2"
      *ngIf="!formLoading && procurement?.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge
      *ngIf="todo !== 'add' && !formLoading"
      class="cml-2"
      [color]="badgeColor[procurement?.procurementWorklistStatus?.code]"
    >
      {{ getTranslateKey(procurement.procurementWorklistStatus) | translate }}
    </app-badge>
    <app-badge
      *ngIf="todo === 'add' && !formLoading"
      color="INFO"
      class="cml-2"
    >
      {{ getTranslateKey(procurement.procurementWorklistStatus) | translate }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          (procurement?.procurementStatus?.code ===
            global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm
                .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT)
        "
      >
        {{ canceledOrFailedMessage | translate }}
      </app-alert-x>
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="vendorReferenceResponse.workflowStepInfoModel"
        >
        </app-workflow-step-info>
        <app-card
          header="vendor-reference.detail.general.title"
          tips="vendor-reference.detail.general.tips"
        >
          <app-procurement-info [procurementId]="procurementId">
          </app-procurement-info>
        </app-card>

        <app-item-request
          [objectList]="vendorReferenceResponse.procurementItemList"
          [isShowPrice]="true"
          [isView]="true"
          [itemTypeList]="vendorReferenceResponse.itemTypeList"
          (onChange)="doChangeItemRequest($event)"
        ></app-item-request>

        <app-card
          header="vendor-reference.detail.vendorReference.title"
          tips="vendor-reference.detail.vendorReference.tips"
        >
          <ng-template #headerRight>
            <div
              class="button-group button-group-right"
              *ngIf="
                vendorReferenceResponse.isUserApproval &&
                !vendorReferenceResponse.isUserApprovalViewOnly &&
                procurement.vendorSelectionType.code ===
                  global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
              "
            >
              <app-button
                (onClick)="doChangeReferenceVendor()"
                title="{{
                  'vendor-reference.button.changeReferenceVendor' | translate
                }}"
              >
                {{
                  'vendor-reference.button.changeReferenceVendor' | translate
                }}
              </app-button>
            </div>
          </ng-template>
          <app-alert-x
            color="DANGER"
            *ngIf="
              isMustFullFill &&
              ['VENDOR-REFERENCE', 'APPROVAL-VENDOR-REFERENCE'].includes(
                procurement.workflowModelPrcs.code
              )
            "
            >{{
              'vendor-reference.detail.alertInfo.mustBeFullfilled' | translate
            }}</app-alert-x
          >
          <app-alert-x
            color="WARNING"
            *ngIf="
              !isMustFullFill &&
              isThingsToConsider &&
              ['VENDOR-REFERENCE', 'APPROVAL-VENDOR-REFERENCE'].includes(
                procurement.workflowModelPrcs.code
              )
            "
            >{{
              'vendor-reference.detail.alertInfo.thingsToConsider' | translate
            }}</app-alert-x
          >
          <app-alert-x
            color="WARNING"
            *ngIf="
              isInvalidChooseVendorSingleWinner &&
              procurement.vendorSelectionType.code ===
                global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER &&
              procurement?.procurementWorklistStatus?.code !==
                global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION
            "
            >{{
              'vendor-reference.detail.alertInfo.chooseVendor' | translate
            }}</app-alert-x
          >
          <app-table-xx
            *ngIf="
              !formLoading &&
              procurement.vendorSelectionType.code ===
                global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
            "
            [model]="tableResponse"
            [isShowSearch]="tableResponse.getRecordList().length > 0"
            [isShowCheckBox]="
              todo !== 'view' &&
              procurement?.procurementWorklistStatus?.code !==
                global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION
            "
            [isMultipleSelect]="false"
            (onChange)="doOnChangeTableResponse()"
            (onClick)="doViewFinalPriceDetail($event)"
          >
          </app-table-xx>
          <app-tab
            [bordered]="true"
            *ngIf="
              !formLoading &&
              procurement.vendorSelectionType.code ===
                global.appConstant.pm.VENDOR_SELECTION_TYPE_MULTI_WINNER
            "
          >
            <app-tab-item
              header="{{
                'vendor-reference.tab.header.allocatedItem' | translate
              }}"
            >
              <app-alert-x
                color="WARNING"
                *ngIf="
                  isInvalidAllocatedItem &&
                  procurement.vendorSelectionType.code ===
                    global.appConstant.pm.VENDOR_SELECTION_TYPE_MULTI_WINNER
                "
                >{{
                  'vendor-reference.detail.alertInfo.allocatedAllItem'
                    | translate
                }}</app-alert-x
              >
              <app-table-xx
                [model]="tableResponseQuotationItem"
                *ngIf="!formLoading"
                (onClick)="doClickQuotationItem($event)"
              ></app-table-xx>
            </app-tab-item>
            <app-tab-item
              header="{{
                'vendor-reference.tab.header.selectedVendor' | translate
              }}"
            >
              <app-table-xx
                [model]="tableResponseQuotationVendor"
                *ngIf="!formLoading"
                (onClick)="doClickQuotationVendor($event)"
              ></app-table-xx>
            </app-tab-item>
          </app-tab>
        </app-card>

        <app-card
          *ngIf="
            !formLoading &&
            (procurement?.procurementWorklistStatus?.code ===
              global.appConstant.pm
                .PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION ||
              (procurement?.procurementWorklistStatus?.code ===
                global.appConstant.pm
                  .PROCUREMENT_WORKLIST_STATUS_WAITING_APPROVAL &&
                vendorReferenceResponse.isHasFinalReNego))
          "
          header="vendor-reference.detail.priceEvaluationMethod.title"
          tips="vendor-reference.detail.priceEvaluationMethod.tips"
        >
          <app-select
            [isMultiple]="false"
            formControlName="evaluationMethod"
            [optionList]="evaluationMethodOptionList"
            maxHeight="max-content"
            [isShowBorder]="true"
          >
            <ng-template #content let-data>
              <div>
                <div class="row">
                  <div class="col-sm-8">
                    <h4>{{
                      data.nameTranslationKey.module.code.toLowerCase() +
                        '.' +
                        data.nameTranslationKey.key | translate
                    }}</h4>
                    <p>{{
                      data.descTranslationKey.module.code.toLowerCase() +
                        '.' +
                        data.descTranslationKey.key | translate
                    }}</p>
                  </div>
                  <div class="col-sm-4 text-right">
                    <app-button
                      (onClick)="doViewDetail()"
                      [disabled]="formSaving"
                      >{{
                        'vendor-reference.button.viewDetail' | translate
                      }}</app-button
                    >
                  </div>
                </div>
              </div>
            </ng-template>
          </app-select>
        </app-card>

        <app-card
          *ngIf="
            !formLoading &&
            (procurement?.procurementWorklistStatus?.code ===
              global.appConstant.pm
                .PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION ||
              (procurement?.procurementWorklistStatus?.code ===
                global.appConstant.pm
                  .PROCUREMENT_WORKLIST_STATUS_WAITING_APPROVAL &&
                vendorReferenceResponse.isHasFinalReNego))
          "
          header="vendor-reference.detail.finalNegotiationPrice.title"
          tips="vendor-reference.detail.finalNegotiationPrice.tips"
        >
          <div *ngIf="!vendorReferenceResponse.isHasFinalReNego">
            <div
              class="pvc pv-file-list"
              style="width: 100px; height: 100px; margin: 0 auto"
            ></div
            ><br />
            <div class="text-center">
              <span>
                {{
                  'vendor-reference.finalEvaluationWillAvailableAfterSessionIsDone'
                    | translate
                }}
              </span> </div
            ><br />
          </div>
          <app-alert-x
            color="DANGER"
            *ngIf="
              vendorReferenceResponse.isHasFinalReNego &&
              isMustFullFill &&
              ['VENDOR-REFERENCE', 'APPROVAL-VENDOR-REFERENCE'].includes(
                procurement.workflowModelPrcs.code
              )
            "
            >{{
              'vendor-reference.detail.alertInfo.mustBeFullfilled' | translate
            }}</app-alert-x
          >
          <app-alert-x
            color="WARNING"
            *ngIf="
              vendorReferenceResponse.isHasFinalReNego &&
              !isMustFullFill &&
              isThingsToConsider &&
              ['VENDOR-REFERENCE', 'APPROVAL-VENDOR-REFERENCE'].includes(
                procurement.workflowModelPrcs.code
              )
            "
            >{{
              'vendor-reference.detail.alertInfo.thingsToConsider' | translate
            }}</app-alert-x
          >
          <app-alert-x
            color="WARNING"
            *ngIf="
              isInvalidChooseVendorSingleWinner &&
              procurement.vendorSelectionType.code ===
                global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER &&
              procurement?.procurementWorklistStatus?.code ===
                global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION
            "
            >{{
              'vendor-reference.detail.alertInfo.chooseVendor' | translate
            }}</app-alert-x
          >
          <app-table-xx
            *ngIf="
              !formLoading &&
              vendorReferenceResponse.isHasFinalReNego &&
              procurement.vendorSelectionType.code ===
                global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
            "
            [model]="tableResponseReNego"
            [isShowSearch]="tableResponseReNego.getRecordList().length > 0"
            [isShowCheckBox]="todo !== 'view'"
            [isMultipleSelect]="false"
            (onChange)="doOnChangeTableResponse()"
          >
          </app-table-xx>
          <app-tab
            [bordered]="true"
            *ngIf="
              !formLoading &&
              vendorReferenceResponse.isHasFinalReNego &&
              procurement.vendorSelectionType.code ===
                global.appConstant.pm.VENDOR_SELECTION_TYPE_MULTI_WINNER
            "
          >
            <app-tab-item
              header="{{ 'vendor-reference.tab.header.byItem' | translate }}"
            >
              <app-table-xx
                [model]="tableResponseQuotationItemReNego"
                *ngIf="!formLoading"
                (onClick)="doClickQuotationItem($event)"
              ></app-table-xx>
            </app-tab-item>
            <app-tab-item
              header="{{ 'vendor-reference.tab.header.byVendor' | translate }}"
            >
              <app-table-xx
                [model]="tableResponseQuotationVendorReNego"
                *ngIf="!formLoading"
                (onClick)="doClickQuotationVendor($event)"
              ></app-table-xx>
            </app-tab-item>
          </app-tab>
        </app-card>

        <app-card
          header="vendor-reference.detail.officialReport.title"
          tips="vendor-reference.detail.officialReport.tips"
        >
          <app-alert-x color="WARNING" *ngIf="!isOfficialReportValid">{{
            'vendor-reference.detail.officialReport.alertInfo' | translate
          }}</app-alert-x>
          <app-official-report
            [objectId]="procurementId"
            [templateCode]="
              procurement.vendorSelectionType.code ===
              global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
                ? global.appConstant.pm
                    .OFFICIAL_REPORT_CODE_ORT_VENDOR_REFERENCE_SINGLE_WINNER_LETTER
                : global.appConstant.pm
                    .OFFICIAL_REPORT_CODE_ORT_VENDOR_REFERENCE_MULTI_WINNER_LETTER
            "
            [appOfficialReportModel]="
              vendorReferenceResponse.appOfficialReportModel
            "
            [mode]="todoOfficialReport"
            (onChange)="doOnChange($event)"
            [isShowButtonGenerate]="todoOfficialReport !== 'view'"
            numberGeneratorCode="NUMBER"
          ></app-official-report>
        </app-card>

        <app-approval-prcs-x
          *ngIf="!formLoading && vendorReferenceResponse.isUserApproval"
          [workflowModelPrcs]="procurement?.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
          [moduleFormGroup]="formGroup"
        >
        </app-approval-prcs-x>

        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            vendorReferenceResponse.approvalPathResponseModel &&
            !vendorReferenceResponse.isUserApproval &&
            (procurement.workflowModelPrcs.code !== 'VENDOR-REFERENCE' ||
              (procurement.workflowModelPrcs.code === 'VENDOR-REFERENCE' &&
                procurement.workflowPrcs?.workflowStatus?.code ===
                  global.appConstant.core.WORKFLOW_CODE_STATUS_REVISION))
          "
          [workflowPrcs]="vendorReferenceResponse?.procurement?.workflowPrcs"
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="
            vendorReferenceResponse.approvalPathResponseModel
          "
        ></app-approval-monitoring>

        <div
          class="floating-button mt-5"
          *ngIf="todo !== 'view' && !vendorReferenceResponse.isUserApproval"
        >
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center">
                <app-button
                  [outline]="true"
                  (onClick)="doSave()"
                  [disabled]="
                    formSaving ||
                    (procurement.vendorSelectionType.code ===
                    global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
                      ? !tableResponse.selectedRow ||
                        tableResponse.selectedRow.getSelectedRecordList()
                          .length === 0
                      : '')
                  "
                  >{{ 'app.button.save' | translate }}</app-button
                >
                <app-button (onClick)="doSubmit()" [disabled]="formSaving">{{
                  'app.button.submit' | translate
                }}</app-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
