<div class="app-widget-rfq-status" *ngIf="!formLoading">
    <app-widget>
      <h3 class="separator">
        <div class="row">
          <div class="cml-2 d-flex align-items-center">
            <div class="pvc pv-shopping-cart" style="width:65px;    height:65px; margin:0 auto"></div>
          </div>
          <div class="cml-2 d-flex align-items-center">
            <h1>{{ totalRfq }}</h1>
          </div>
          <div class="cml-2 d-flex align-items-center">
            Total RFQ
          </div>
        </div>
      </h3>
      <app-widget-content-slider>
        <div class="widget-card" *ngFor="let data of rfqDataList" (click)="goToMoreDetail(data)" (keyup)="onKeyUp($event)">
          <h1 class="m-0">{{ data.value || 0}}</h1>
          <br>
          <app-badge
            class="cml-2"
            [color]="data.badge || 'PRIMARY'">
            {{ data.translationKey | translate }}
          </app-badge>
        </div>
      </app-widget-content-slider>
    </app-widget>
  </div>