import { BudgetType } from './../../../core/bean/budget-type';
import { Currency } from 'src/app/core/bean/currency';
import { Coa } from 'src/app/core/bean/coa';
import { BudgetAllocation } from 'src/app/core/bean/budget-allocation';
export class BudgetAllocationInfoResponse {

    budgetTypeList: BudgetType[] = [];
    currencyList: Currency[] = [];
    coaList: Coa[] = [];
    budgetAllocationList: BudgetAllocation[] = [];
    totalBudgetResume: number;
    bookedBudgetResume: number;
    usedBudgetResume: number;
    paidOffBudgetResume: number;
    availableBudgetResume: number;
    remainingPayment: number;
}
