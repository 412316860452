import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contract } from 'src/app/core/bean/contract';
import { OrderPreparation } from 'src/app/core/bean/order-preparation';
import { PrItemReleased } from 'src/app/core/bean/pr-item-released';
import { AppPopupService } from '../app-popup.service';
import { AppPopupChoosePrReleasedComponent } from './app-popup-choose-pr-released.component';

@Injectable()
export class AppPopupChoosePrReleasedService {
  constructor(public appPopupService: AppPopupService) {}
  public open(prItemReleasedList?: PrItemReleased[], 
    vendorId?: number, 
    prId?: number,
    baseModuleCode?: String, 
    orderPreparationList?: OrderPreparation[],
    contractBeanList?: Contract[]): Observable<any> {
    return this.appPopupService.open(
      AppPopupChoosePrReleasedComponent,
      {
        prItemReleasedList, vendorId, prId, baseModuleCode, orderPreparationList, contractBeanList
      },
      { size: 'xl' }
    );
  }
}
