<div class="widget-card">
  <div class="widget-card-header mb-3" *ngIf="header">
    <h5 class="widget-card-title">{{ header }}</h5>
  </div>
  <ng-content select=".widget-card-header"></ng-content>
  <div class="widget-card-content">
    <ng-content></ng-content>
  </div>
  <ng-content select=".widget-card-footer"></ng-content>
</div>
