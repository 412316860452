<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="home-slide.title" [isLoading]="formLoading"
            [isSaving]="formSaving">
            <app-table [model]="tableResponse" [isServerSide]="true"
                stringUrl="/home-slide/index">
                <ng-template #headerButtons>
                    <app-button color="PRIMARY" class="ml-2"
                        (onClick)="doAdd()">
                        <em class="fas fa-plus"></em>{{ 'app.button.add' |
                        translate }}
                    </app-button>
                </ng-template>
                <ng-template #actionButtons let-rowData>
                    <app-button size="TN" (onClick)="doEdit(rowData)"
                        title="{{ 'app.tooltip.edit' | translate }}">
                        <em class="fas fa-pencil-alt"></em>
                    </app-button>
                    <app-button size="TN" color="DANGER"
                        (onClick)="doDelete(rowData)"
                        title="{{ 'app.tooltip.delete' | translate }}">
                        <em class="fa fas fa-trash"></em>
                    </app-button>
                </ng-template>
            </app-table>
            <div class="button-group button-group-center mt-5">
                <app-button color="SECONDARY" (onClick)="doCancel()">
                    {{ 'app.button.back' | translate}}
                </app-button>
            </div>
        </app-card>
    </div>
</div>