<app-card
  *ngIf="!formLoading"
  header="app-item-request-released.general.title"
  tips="app-item-request-released.general.tips"
>
  <ng-template #headerRight>
    <div class="button-group button-group-right">
      <app-button
        (onClick)="doAddList()"
        title="{{ 'app.button.add' | translate }}"
        *ngIf="!isView"
      >
        <em class="pir pi-plus-circle"></em>
        {{ 'app-item-request-released.button.addItem' | translate }}
      </app-button>
    </div>
  </ng-template>

  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <app-fullfilled
      *ngIf="tableResponse?.getRecordList()?.length === 0"
    ></app-fullfilled>
    <app-table-xx [model]="tableResponse" (onClick)="doViewItem($event)">
      <ng-template #headerFilter>
        <div class="form-group row text-sm-left">
          <div class="col text-left" style="min-width: 10rem; margin-top: 1rem">
            <app-dropdown-select
              size="XL"
              type="COMBOBOX"
              formControlName="itemType"
              [optionList]="itemTypeOptionList"
              (onChange)="onChangeItem()"
              container="body"
              placeholder="{{
                'app-item-request.placeholder.type' | translate
              }}"
            ></app-dropdown-select>
          </div>
        </div>
      </ng-template>
      <ng-container *ngIf="isShowPrice">
        <ng-template #row let-state>
          <ng-container *ngIf="state.rowList.length > 0">
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-right" colspan="3">
                {{ 'app-item-request.footer.goodsAmount' | translate }}
              </td>
              <td colspan="99" class="text-right">
                <strong>
                  {{ formGroup.value.goodsAmount | resolveNumberToCurrency }}
                  IDR
                </strong>
              </td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-right" colspan="3">
                {{ 'app-item-request.footer.serviceAmount' | translate }}
              </td>
              <td colspan="99" class="text-right">
                <strong>
                  {{ formGroup.value.serviceAmount | resolveNumberToCurrency }}
                  IDR
                </strong>
              </td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-right" colspan="3">
                {{ 'app-item-request.footer.amount' | translate }}
              </td>
              <td colspan="99" class="text-right">
                <strong>
                  {{ formGroup.value.amount | resolveNumberToCurrency }}
                  IDR
                </strong>
                <br />
                <i>
                  ({{
                    formGroup.value.amount
                      | resolveNumberToWords: global.currentLang
                  }}
                  {{ 'Rupiah' }})
                </i>
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="!isView && source === 'ORDER' ">
        <ng-template #actionButtons let-rowData>
          <div class="ml-2">
            <app-button
              size="TN"
              (onClick)="doDeleteItem(rowData)"
              title="{{ 'app.tooltip.delete' | translate }}"
            >
              <em class="pir pi-trash-alt"></em>
              {{ 'app.button.delete' | translate }}
            </app-button>
          </div>
        </ng-template>
      </ng-container>
    </app-table-xx>
  </form>
</app-card>
