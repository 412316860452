import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ModuleGroup } from '../../core/bean/module-group';
import { PickListModel } from '../../core/components/app-pick-list/model/pick-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Dependency } from './../../core/bean/dependency';
import { Module } from './../../core/bean/module';
import { ModuleDependencyResponse } from './module-dependency-response';
import { ModuleDependencyRequest } from './module-dependency-request';

@Component({
  templateUrl: 'module-dependency-edit-add.component.html'
})
export class ModuleDependencyEditAddComponent extends BaseModuleComponent {
  public moduleGroupList: ModuleGroup[];
  public module: Module = new Module();
  public moduleGroupName1: string;
  public moduleGroupName2: string;
  public moduleGroupName3: string;
  public sourceDependencyInput: Dependency[];
  public targetDependencyInput: Dependency[];
  public sourceDependencyOutput: Dependency[];
  public targetDependencyOutput: Dependency[];
  public moduleDependencyResponse: ModuleDependencyResponse = new ModuleDependencyResponse();
  public moduleDependencyRequest: ModuleDependencyRequest = new ModuleDependencyRequest();

  public dependencyInputPickListModel: PickListModel<Dependency>;
  public dependencyOutputPickListModel: PickListModel<Dependency>;

  constructor(translateService: TranslateService) {
    super('module-dependency', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.setModuleGroupList();
    this.getAndSetModuleDependencyToPicklist();
    this.buildFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.module = this.global.routerParams.get('module');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      moduleGroup: [null]
    });
  }

  public setModuleGroupList(): void {
    this.httpClientService
      .get<ModuleGroup[]>('/module-dependency/module-group-list', {})
      .subscribe(moduleGroupList => {
        this.moduleGroupList = moduleGroupList;
      });
  }

  public getAndSetModuleDependencyToPicklist(): void {
    this.httpClientService
      .post<ModuleDependencyResponse>('/module-dependency/edit', this.module.id)
      .subscribe(moduleDependencyResponse => {
        this.moduleGroupName1 = this.module.moduleGroupCode;
        const moduleGroupNameSplit = this.moduleGroupName1.split(',');
        for (const [index, moduleGroupName] of moduleGroupNameSplit.entries()) {
          for (const moduleGroup of this.moduleGroupList) {
            if (moduleGroupName === moduleGroup.code) {
              if (moduleGroupNameSplit.length > 1) {
                if (index === 0) {
                  this.moduleGroupName1 = moduleGroup.name;
                } else {
                  this.moduleGroupName2 = moduleGroup.name;
                  this.moduleGroupName3 = this.moduleGroupName1.concat(
                    ', ',
                    this.moduleGroupName2
                  );
                  this.moduleGroupName1 = this.moduleGroupName3;
                }
              } else {
                this.moduleGroupName3 = moduleGroup.name;
              }
            }
          }
        }
        this.formGroup.patchValue({ moduleGroup: this.moduleGroupName3 });
        const {
          dependencyListNotByModuleInput,
          dependencyListByModuleInput,
          dependencyListNotByModuleOutput,
          dependencyListByModuleOutput
        } = moduleDependencyResponse;
        this.dependencyInputPickListModel = new PickListModel(
          this.moduleCode,
          dependencyListNotByModuleInput,
          dependencyListByModuleInput
        );
        this.dependencyOutputPickListModel = new PickListModel(
          this.moduleCode,
          dependencyListNotByModuleOutput,
          dependencyListByModuleOutput
        );
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.moduleDependencyRequest.module = this.module;
          this.moduleDependencyRequest.dependencyListByModuleInput = this.dependencyInputPickListModel.targetItemList;
          this.moduleDependencyRequest.dependencyListByModuleOutput = this.dependencyOutputPickListModel.targetItemList;
          this.httpClientService
            .post<Response<ModuleDependencyRequest>>(
              '/module-dependency/update',
              this.moduleDependencyRequest
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessSavingOnNextRoute();
                this.router.navigate(['/pages/module-dependency/']);
              } else {
                this.setStateReady();
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/module-dependency/']);
  }
}
