
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { RfiItem } from '../../core/bean/rfi-item';
import { FileUploader } from '../../core/components/upload';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { ResponseRfiDTO } from './dto/response-rfi.dto';
@Component({
    templateUrl: './response-rfi-detail.component.html'
})
export class ResponseRfiDetailComponent extends BaseModuleComponent {
    public responseRfiDTO: ResponseRfiDTO = new ResponseRfiDTO();
    public rfiItemList: RfiItem[];
    public rfiVendorId: number;
    public phoneRegion: string;
    public phoneNumber: string;
    public fileNameRfi: string;
    public uploadedFileNameRfi: string;
    public fileNameRfiVendor: string;
    public uploadedFileNameRfiVendor: string;
    public vendorName: string;
    public vendorEmail: string;
    public fileUploader: FileUploader = new FileUploader('/response-rfi/', 'rfiDoc', this.global.appConstant.fileType.DOC_RFI);

    constructor(translateService: TranslateService) {
        super('response-rfi', translateService);
    }

    onInit(): void {
        this.setDataFromRouterParams();
        this.setFormGroup();
    }

    public setDataFromRouterParams(): void {
        this.todo = this.global.routerParams.get('todo');
        this.rfiVendorId = this.global.routerParams.get('rfiVendorId');
    }

    public setFormGroup(): void {
        this.httpClientService.post<ResponseRfiDTO>('/response-rfi/detail', new RouteRequestModel(this.todo, this.rfiVendorId))
        .subscribe(responseRfiDTO => {
            this.responseRfiDTO = responseRfiDTO;
            this.rfiItemList = responseRfiDTO.rfiItemList;
            this.vendorName = this.responseRfiDTO.rfiVendor.vendorHistory.vendor.user.name;
            this.vendorEmail = this.responseRfiDTO.rfiVendor.vendorHistory.vendor.user.email;
            this.phoneRegion = this.responseRfiDTO.rfiVendor.vendorHistory.vendor.phoneRegion.callingCode;
            this.phoneNumber = this.responseRfiDTO.rfiVendor.vendorHistory.vendor.phone;
            this.fileNameRfi = this.responseRfiDTO.rfi.file.fileName;
            this.uploadedFileNameRfi = this.responseRfiDTO.rfi.file.uploadedFileName;
            this.fileNameRfiVendor = this.responseRfiDTO.rfiVendor.file.fileName;
            this.uploadedFileNameRfiVendor = this.responseRfiDTO.rfiVendor.file.uploadedFileName;
            this.setStateReady();
        });
    }

    public doNext(): void {
        if (this.responseRfiDTO != null) {
            this.global.routerParams.clear();
            this.global.routerParams.set('todo', this.todo);
            this.global.routerParams.set('responseRfiDTO', this.responseRfiDTO);
            this.router.navigate(['/pages/response-rfi/question-detail']);
        }
    }

    public doCancel(): void {
        this.router.navigate(['/pages/response-rfi/']);
    }

    public get formGroupControls() {
        return this.formGroup.controls;
    }
}
