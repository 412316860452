<div class="portal-select-role my-5">
  <div class="container">
    <div class="select-role-wrapper">
      <app-card>
        <div class="select-role-card mb-2">
          <h2>{{ 'portal.selectRole.loginAs' | translate }}</h2>
          <a *ngIf="!formSaving" routerLink="/register" class="text-gray">{{
            'portal.selectRole.title' | translate
          }}</a>
          <div *ngIf="formSaving">
            <img
              src="assets/img/common/loader-small.gif"
              alt="Loading"
              height="11"
              width="40"
            />
          </div>
          <form [formGroup]="formGroup" class="text-left mt-4">
            <div class="form-group mt-4 select-role-error">
              <app-combo-box
                size="XL"
                formControlName="userRoleCode"
                [optionList]="userRoleOptionList"
                placeholder="portal.selectRole.placeholder"
              ></app-combo-box>
            </div>
            <div class="form-group mt-4">
              <app-check-box formControlName="isDontAskMeAgain">
                {{ 'portal.selectRole.dontAskMeAgain' | translate }}
              </app-check-box>
            </div>

            <div class="button-group button-group-center mt-4">
              <app-button
                color="SECONDARY"
                class="app-button"
                (onClick)="doBack()"
                [ngStyle]="{ 'padding-right': '0.11rem' }"
                >{{ 'app.button.cancel' | translate }}</app-button
              >
              <app-button
                class="app-button"
                [disabled]="!formGroup.valid || formSaving"
                (onClick)="doSubmit()"
                [ngStyle]="{ 'padding-left': '0.11rem' }"
                >{{ 'portal.login.button.signIn' | translate }}</app-button
              >
            </div>
          </form>
        </div>
      </app-card>
    </div>
  </div>
</div>
