import { TableFieldModel } from './table-field-model';
import { TableFilterMetaData } from './table-filter-meta-data-model';
import { TableSortMeta } from './table-sort-meta-model';
export class TableRequestModel {
  cols: Array<TableFieldModel> = new Array();
  dataMap?: Map<string, any> = new Map();
  customData: any; // for app table old version
  first: number;
  rows: number;
  sortField: string;
  sortOrder: number;
  multiSortMeta: Array<TableSortMeta> = new Array();
  filters: {
    [s: string]: TableFilterMetaData;
  };
  globalFilter: string;
  constructor(columns?: Array<TableFieldModel>, rows = 10) {
    this.cols = columns;
    this.first = 0;
    this.sortOrder = 1;
    this.globalFilter = '';
    this.rows = rows;
  }

  setSearchText(searchText: string): void {
    this.globalFilter = searchText;
  }

  setFirst(first: number): void {
    this.first = first;
  }
}
