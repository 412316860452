import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { Bank } from '../../../bean/bank';
import { Validators } from '../../../validators';
@Component({
  templateUrl: './app-popup-bank.component.html'
})
export class AppPopupBankComponent extends BaseComponentComponent {
  @Input() bank: Bank;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  constructor() {
    super('app-popup-bank');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.setStateReady();
  }

  buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required()],
      description: [null]
    });
  }

  setFormGroup(): void {
    if (this.bank != null) {
      this.formGroup.patchValue({
        name: this.bank.name,
        description: this.bank.description
      });
    }
  }

  doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup);
    }
  }
}
