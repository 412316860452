<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="user-action.title" detail="Detail">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="form-group">
              <label for="logDate">
                {{ 'user-action.form.logDate' | translate }}
              </label>
              <app-month-year-picker formControlName="logDate" size="LG">
              </app-month-year-picker>
            </div>
          </div>
        </div>
        <div class="button-group button-group-right button-group-icon-text mt-5">
          <app-button mode="BYPASS" (onClick)="doReset()"><em class="fas fa-sync-alt"></em>{{ 'app.button.reset' |
            translate }}
          </app-button>
          <app-button mode="BYPASS" (onClick)="doApply()"><em class="fas fa-check"></em>{{ 'app.button.apply' |
            translate }}
          </app-button>
        </div>
      </form>
    </app-card>
    <app-card [isLoading]="formLoading" [isSaving]="formSaving">
      <app-table [model]="tableResponse" [isServerSide]="true" stringUrl="/user-action/index">
        <ng-template #headerButtons>
          <app-button mode="BYPASS" (onClick)="doExport()" [disabled]="isLoading"><em class="fas"
              [ngClass]="!isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'"></em>{{
            'app.button.export' | translate }}</app-button>
        </ng-template>
      </app-table>
      <div class="button-group button-group-center mt-5">
        <app-button mode="BYPASS" color="SECONDARY" (onClick)="doBack()">{{
          'app.button.back' | translate }}</app-button>
      </div>
    </app-card>
  </div>
</div>