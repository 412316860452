<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-x
        [model]="tableResponse"
        stringUrl="/dr/index"
        (onClick)="doClick($event)"
        header="{{ 'dr.table.header' | translate }}"
      >
        <ng-template #headerFilterGroup>
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
            *ngIf="!formLoading"
          >
            <div
              class="form-group row text-sm-left"
              style="margin-bottom: 0.25rem"
            >
              <div class="col-sm-3 text-left">
                <app-date-picker-x
                  size="XL"
                  formControlName="requiredDate"
                  [range]="true"
                  (onChange)="onChangeFilter()"
                  placeholder="{{ 'dr.placeholder.requiredDate' | translate }}"
                >
                </app-date-picker-x>
              </div>
              <div
                class="col-3"
                *ngIf="
                  global.userSession?.activeUserRole.role.code !==
                  global.appConstant.ROLE_CODE_VENDOR
                "
              >
                <app-dropdown-select
                  type="CHOSEN"
                  size="XL"
                  formControlName="organizationList"
                  [optionList]="organizationOptionList"
                  (onChange)="onChangeFilter($event)"
                  showLimitValue="1"
                  placeholder="{{ 'dr.placeholder.department' | translate }}"
                >
                  <ng-template #selectAll>
                    <p> {{ 'dr.placeholder.all' | translate }} </p>
                  </ng-template>
                </app-dropdown-select>
              </div>
              <div class="col-3">
                <app-dropdown-select
                  type="CHOSEN"
                  size="XL"
                  formControlName="statusList"
                  [optionList]="statusOptionList"
                  (onChange)="onChangeFilter($event)"
                  showLimitValue="1"
                  placeholder="{{ 'dr.placeholder.status' | translate }}"
                >
                  <ng-template #selectAll>
                    <p> {{ 'dr.placeholder.all' | translate }} </p>
                  </ng-template>
                  <ng-template #option let-data>
                    <p> {{ data?.name }} </p>
                  </ng-template>
                </app-dropdown-select>
              </div>
              <div
                class="col-3"
                *ngIf="
                  global.userSession?.activeUserRole.role.code !==
                  global.appConstant.ROLE_CODE_VENDOR
                "
              >
                <app-dropdown-select
                  type="CHOSEN"
                  size="XL"
                  formControlName="drTypeList"
                  [optionList]="drTypeOptionList"
                  (onChange)="onChangeFilter($event)"
                  showLimitValue="1"
                  placeholder="{{ 'dr.placeholder.drType' | translate }}"
                >
                  <ng-template #selectAll>
                    <p> {{ 'dr.placeholder.all' | translate }} </p>
                  </ng-template>
                </app-dropdown-select>
              </div>
            </div>
          </form>
        </ng-template>
      </app-table-x>
    </div>
  </div>
</app-layout>
