<app-popup
  header="contract-history.popup.contractClosure.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <form
    *ngIf="!formLoading"
    class="form-horizontal"
    [formGroup]="formGroup"
    novalidate
  >
    <div class="form-group row text-left">
      <label class="col-sm-3">{{
        'contract-history.popup.form.closureDate' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-date-picker-x
          formControlName="closureDate"
          size="LG"
          [minDate]="startDate"
        ></app-date-picker-x>
      </div>
    </div>
    <div class="form-group row text-left">
      <label class="col-sm-3">{{
        'contract-history.popup.form.description' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-area
          formControlName="description"
          maxLength="512"
          size="XL"
        ></app-text-area>
      </div>
    </div>
    <div class="form-group row text-left">
      <label class="col-sm-3 optional">{{
        'contract-history.popup.form.attachment' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-upload-x
          formControlName="file"
          size="LG"
          [fileUploader]="fileUploader"
        ></app-upload-x>
      </div>
    </div>
  </form>
  <div class="button-group button-group-center mt-5">
    <app-button [outline]="true" color="SECONDARY" (onClick)="doCancel()">{{
      'app.button.cancel' | translate
    }}</app-button>
    <app-button (onClick)="doSave()">{{
      'app.button.save' | translate
    }}</app-button>
  </div>
</app-popup>
