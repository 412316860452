<ng-container *ngIf="!ISVIEW; else isViewTmpl">
  <div
    class="app-choose form-control pr-5"
    [ngClass]="[
      elementWidthClassName ? elementWidthClassName : '',
      elementHeightClassName ? elementHeightClassName : '',
      isInvalid ? 'is-invalid' : '',
      isDisabled ? 'disabled' : ''
    ]"
    [formGroup]="formGroup"
    (click)="doClick($event)"
    (keyup)="onKeyUp($event)"
    (keydown)="onKeyDown($event)"
  >
    <input
      type="text"
      class="cursor-pointer"
      *ngIf="
        !formControl.value ||
        (formControl.value && formControl.value.length === 0)
      "
      [placeholder]="
        (placeholder | translate) || ('app.chooseData' | translate)
      "
      [disabled]="disabled"
      readonly
    />
    <!-- is not multiple and hierarchy-->
    <ng-container
      *ngIf="!isMultiple && formControl.value && formControl.value.length > 0"
    >
      <ul class="ul-breadchumbs" *ngIf="!isString">
        <li
          *ngFor="let item of formControl.value"
          [ngStyle]="{
            'font-weight': formControl.value.length === 1 ? 'normal' : ''
          }"
        >
          {{ item.name }}</li
        >
      </ul>
      <ul class="ul-breadchumbs" *ngIf="isString">
        <input
          type="text"
          class="cursor-pointer w-100"
          [formControl]="formControl"
          readonly
        />
      </ul>
    </ng-container>
    <!-- is multiple  -->
    <ng-container
      *ngIf="isMultiple && formControl.value && formControl.value.length > 0"
    >
      <app-tagging
        [formControl]="formControl"
        [optionView]="optionView"
      ></app-tagging>
    </ng-container>
    <div class="icon-wrapper">
      <div class="icon">
        <em class="fab fa-wpforms"></em>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
  <small class="text-danger" *ngIf="isInvalid">
    {{ errors.message | translate }}
  </small>
</ng-container>
<ng-template #isViewTmpl>
  <div class="position-relative d-inline-block">
    <ng-container *ngIf="isMultiple">
      <app-tagging [formControl]="formControl"></app-tagging>
    </ng-container>
    <ng-container *ngIf="!isMultiple">
      {{ (formControl.value[0] && formControl.value[0].name) || '-' }}
    </ng-container>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-container *ngIf="tooltipContentTmpl">
        <ng-container *ngTemplateOutlet="tooltipContentTmpl"></ng-container>
      </ng-container>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
