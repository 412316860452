import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Tc } from '../../core/bean/tc';
import { AppPopupImportService } from '../../core/components/app-popup/app-popup-import/app-popup-import.service';
import { AppTableXComponent } from '../../core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from '../../core/components/app-table-x/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';

@Component({
  templateUrl: './tc.component.html'
})
export class TcComponent extends BaseModuleComponent implements OnInit {
  @ViewChild(AppTableXComponent) 
  public table: AppTableXComponent;
  public tableResponse: TableResponseModel<Tc>;
  constructor(
    translateService: TranslateService,
    public appPopupImportService: AppPopupImportService
  ) {
    super('tc', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: '0.code', header: 'table.column.code', plugins: 'hyperlink' },
      { field: '1', header: 'table.column.name' },
      { field: '0.description', header: 'table.column.description' }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/tc/add']);
  }

  public doEdit(data: any): void {
    const tc: Tc = data[0];
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('tcId', tc.id);
    this.router.navigate(['/pages/tc/edit']);
  }

  public doDelete(event: any): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          const tcList: Tc[] = event.checkedRecordList.map(data => data[0]);
          this.httpClientService
            .post<Response<Tc[]>>('/tc/delete', tcList)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
