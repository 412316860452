<app-popup
  header="app-catalog-detail-info.popupItemContract.title"
  [isShowHeaderCloseButton]="false"
  [isLoading]="formLoading"
>
  <ng-container *ngIf="!formLoading">
    <div class="col">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-table-xx
          [model]="tableResponse"
          [isShowCheckBox]="true"
          [isShowPagination]="false"
          [isMultipleSelect]="false"
        >
          <!--[isShowButtonExpandCollapse]="true"-->
          <ng-template #alertWrapper>
            <app-alert-x color="INFO">{{
              'app-catalog-detail-info.popupItemContract.info' | translate
            }}</app-alert-x>
          </ng-template>
        </app-table-xx>
      </form>
    </div>
  </ng-container>

  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button
        class="mr-1"
        color="SECONDARY"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button (onClick)="doChoose()">{{
        'app.button.choose' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
