import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppApprovalLogModule } from '../../core/components/app-approval-log/app-approval-log.module';
import { AppApprovalPathModule } from '../../core/components/app-approval-path/app-approval-path.module';
import { AppApprovalPrcsModule } from '../../core/components/app-approval-prcs/app-approval-prcs.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { WorklistPraProcurementComponent } from '../worklist-pra-procurement/worklist-pra-procurement.component';
import { WorklistPraProcurementModule } from '../worklist-pra-procurement/worklist-pra-procurement.module';
import { TorSubmissionEditAddComponent } from './tor-submission-edit-add.component';
import { TorSubmissionComponent } from './tor-submission.component';
export const routes = [
  {
    path: '',
    component: WorklistPraProcurementComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'edit',
    component: TorSubmissionEditAddComponent,
    data: { breadcrumb: 'tor-submission.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: TorSubmissionEditAddComponent,
    data: { breadcrumb: 'tor-submission.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: TorSubmissionEditAddComponent,
    data: { breadcrumb: 'tor-submission.breadcrumb.view' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    WorklistPraProcurementModule,
    AppApprovalPathModule,
    AppApprovalPrcsModule,
    AppApprovalLogModule
  ],
  declarations: [TorSubmissionComponent, TorSubmissionEditAddComponent],
  providers: []
})
export class TorSubmissionModule {}
