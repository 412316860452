import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementVendor } from '../../core/bean/procurement-vendor';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { RecapPrintService } from '../../core/services/recap-print.service';
import { AppPopupPerformanceProcurementComponent } from './app-popup-performance-procurement.component';
@Component({
  templateUrl: './performance-procurement-detail-procurement.component.html'
})
export class PerformanceProcurementDetailProcurementComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Procurement>;

  public isLoading = false;
  public vendorId: number;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public recapPrintService: RecapPrintService
  ) {
    super('performance-procurement', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildTableResponse();
    this.doSetCustomData();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.vendorId = this.global.routerParams.get('vendorId');
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: '0.procurement.name', header: 'table.column.procName' },
      {
        field: '0.procurement.procurementType.name',
        header: 'table.column.procType',
        customClass: 'text-center'
      },
      {
        field: '1.code',
        header: 'table.column.performanceID',
        customClass: 'text-center'
      },
      {
        field: '1.createdDate',
        header: 'table.column.date',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: '1.rating',
        header: 'table.column.rating',
        format: FieldFormatEnum.Rating, customClass: 'text-center'
      },
      {
        field: '1.workflowPrcs.workflowStatus.name',
        header: 'table.column.status',
        customClass: 'text-center'
      }
    ]);
    // .0. = t5_procurement_vendor; .1. = t6_performance
  }

  public doSetCustomData(): void {
    this.log.debug(this.global.routerParams);
    this.tableResponse.setCustomData(this.vendorId);
  }

  public doEdit(procurementVendor: ProcurementVendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set(
      'procurementId',
      procurementVendor.procurement.id
    );
    this.global.routerParams.set('vendorId', this.vendorId);
    this.router.navigate([
      '/pages/performance-procurement/detail-procurement/edit'
    ]);
  }

  public doView(procurementVendor: ProcurementVendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'viewPerformance');
    this.global.routerParams.set(
      'procurementId',
      procurementVendor.procurement.id
    );
    this.global.routerParams.set('vendorId', this.vendorId);
    this.router.navigate([
      '/pages/performance-procurement/detail-procurement/detail'
    ]);
  }

  public doAdd(): void {
    const todo = 'add';
    const vendorId: number = this.vendorId;
    this.appPopupService
      .open(
        AppPopupPerformanceProcurementComponent,
        { todo, vendorId },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(() => {
        this.table.reload();
      });
  }

  public doExport(): void {
    this.isLoading = true;
    // this.tableResponse.setCustomData(this.vendorId);
    this.recapPrintService
      .print(
        '/performance-procurement/recap-detail-procurement',
        this.tableResponse.request
      )
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  public doBack(): void {
    this.global.routerParams.clear();
    this.router.navigate(['/pages/performance-procurement']);
  }
}
