import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppComboBoxModule } from '../../app-combo-box/app-combo-box.module';
import { AppDatepickerModule } from '../../app-date-picker/app-date-picker.module';
import { AppTextAreaModule } from '../../app-text-area/app-text-area.module';
import { AppTextFieldModule } from '../../app-text-field/app-text-field.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupProcurementGuaranteeComponent } from './app-popup-procurement-guarantee.component';
import { AppPopupProcurementGuaranteeService } from './app-popup-procurement-guarantee.service';
@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppTextFieldModule,
    AppTextAreaModule,
    AppButtonModule,
    AppComboBoxModule,
    AppDatepickerModule
  ],
  declarations: [AppPopupProcurementGuaranteeComponent],
  entryComponents: [AppPopupProcurementGuaranteeComponent],
  providers: [AppPopupProcurementGuaranteeService],
  exports: [AppPopupProcurementGuaranteeComponent]
})
export class AppPopupProcurementGuaranteeModule {}
