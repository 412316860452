<app-popup
  header="contract-history.popup.selectGuarantee.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <app-select
      formControlName="guarantee"
      [optionList]="guaranteeOptionList"
      [isShowBorder]="true"
      [isMultiple]="false"
    >
      <ng-template #content let-data>
        <ng-container>
          <span class="d-inline-flex flex-column">
            <label class="mr-1">{{ data.guaranteeBondType.name }}</label>
            <label class="mr-1 mb-0 text-danger"
              >{{
                'contract-history.popup.selectGuarantee.expireInfo.prefix'
                  | translate
              }}&nbsp;{{ data.code }}&nbsp;{{
                'contract-history.popup.selectGuarantee.expireInfo.suffix'
                  | translate
              }}</label
            >
          </span>
        </ng-container>
      </ng-template>
    </app-select>
  </form>
  <div class="button-group button-group-center mt-5">
    <app-button [outline]="true" color="SECONDARY" (onClick)="doCancel()">{{
      'app.button.cancel' | translate
    }}</app-button>
    <app-button (onClick)="doSelect()">{{
      'app.button.select' | translate
    }}</app-button>
  </div>
</app-popup>
