import { EventEmitter } from '@angular/core';
import { PaginationModel } from '../../../../../../../core/components/app-pagination/model/pagination-model';
import { TablePageModel } from '../../../../../../../core/components/app-table/model/table-page-model';
import { TableVendorRequestModel } from './table-vendor-request-model';
import { TableViewType } from './table-view-type';
export class TableVendorResponseModel<T> {
  public page: TablePageModel<T> = new TablePageModel();
  stringUrl: string;
  viewType: TableViewType;
  isLoading: boolean;
  pagination: PaginationModel = new PaginationModel();
  tableRequest: TableVendorRequestModel = new TableVendorRequestModel();
  valueChanges: EventEmitter<T[]> = new EventEmitter();
  reloadChanges: EventEmitter<boolean> = new EventEmitter();
  constructor(
    stringUrl: string,
    perPage = 10,
    viewType: TableViewType = 'LIST'
  ) {
    this.stringUrl = stringUrl;
    this.viewType = viewType;
    this.tableRequest.rows = perPage;
    this.pagination.page = 1;
    this.pagination.perPage = perPage;
    this.pagination.totalRecords = 0;
  }

  setPageRecords(records: T[]): void {
    this.page.records = records;
    this.page.totalRecords = records.length;
    this.valueChanges.emit(records);
  }

  getPageRecords(): T[] {
    return this.page.records;
  }

  setViewTipe(viewType: TableViewType): void {
    this.viewType = viewType;
  }

  setStateLoading(): void {
    this.isLoading = true;
  }

  setStateReady(): void {
    this.isLoading = false;
  }

  reload(): void {
    this.reloadChanges.emit(true);
  }

  setCustomData(customData: any): void {
    this.tableRequest.setCustomData(customData);
    this.reload();
  }

  setSearchString(strSearch: string): void {
    this.tableRequest.globalFilter = strSearch;
    this.reload();
  }

  setPerPage(perPage: number): void {
    this.tableRequest.rows = +perPage;
    this.pagination.perPage = +perPage;
    this.setCurrentPage(this.pagination.page);
  }

  setTotalRecords(totalRecords: number): void {
    this.pagination.totalRecords = totalRecords;
  }

  setSortField(sortField: string): void {
    this.tableRequest.sortField = sortField;
    this.reload();
  }

  setCurrentPage(page: number): void {
    this.tableRequest.first = (page - 1) * this.pagination.perPage;
    this.reload();
  }
}
