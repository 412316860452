import { BaseEntity } from '../base/base-entity';
import { Notification } from '../bean/notification';

export class ReminderEntity extends BaseEntity {
  notification: Notification;
  time: number;
  period: number;
  isRepeat: boolean;
  isActive: boolean;
}

