import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { EvaluationCriteria } from 'src/app/core/bean/evaluation-criteria';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './technical-criteria.component.html'
})
export class TechnicalCriteriaComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<EvaluationCriteria>;

  constructor(translateService: TranslateService) {
    super('technical-criteria', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      { field: 'description', header: 'table.column.description' }
    ]);
  }
  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/technical-criteria/add']);
  }

  public doEdit(technicalCriteria: EvaluationCriteria): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('technicalCriteriaId', technicalCriteria.id);
    this.router.navigate(['/pages/technical-criteria/edit']);
  }

  public doDelete(technicalCriteria: EvaluationCriteria): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<EvaluationCriteria>>(
              '/technical-criteria/delete',
              technicalCriteria
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }
}
