<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="project.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label"
            >{{ 'project.form.name' | translate }}
          </label>
          <div class="col-sm-4 text-left required">
            <app-text-field
              type="alphanumeric"
              size="XL"
              formControlName="name"
              maxlength="32"
              autofocus="true"
            ></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'project.form.organization' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <app-choose
              formControlName="organizationName"
              size="XL"
              (onClick)="doChooseOrganization($event)"
            ></app-choose>
            <small
              class="text-danger"
              *ngIf="submitted && form.organizationName.errors"
            >
              {{ 'app.validation.required' | translate }}
            </small>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'project.form.description' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <textarea
              class="form-control"
              formControlName="description"
              maxlength="512"
              rows="4"
              [ngClass]="{ 'is-invalid': submitted && form.description.errors }"
              aria-label="Description"
            ></textarea>
            <small
              class="text-danger"
              *ngIf="
                (submitted && form.description.errors) ||
                (form.description.touched && form.description.errors)
              "
            >
              {{ 'app.validation.required' | translate }}
            </small>
          </div>
        </div>
      </form>

      <div class="col-lg-12 mb-4">
        <app-card header="project.workPlan">
          <div class="row">
            <div class="col-md-12 mb-2 text-right">
              <button
                type="button"
                class="btn btn-primary"
                (click)="doAddWorkPlan()"
                >{{ 'app.button.add' | translate }}</button
              >
            </div>
          </div>
          <div
            class="table-responsive custom-table float-none"
            [ngStyle]="{ margin: '0 auto' }"
          >
            <table
              class="table table-striped box-shadow"
              [formGroup]="formGroup"
              aria-describedby="Table"
            >
              <thead>
                <tr>
                  <th scope="col" class="text-sm-center">{{
                    'app.table.column.no' | translate
                  }}</th>
                  <th scope="col">
                    {{ 'project.table.column.number' | translate }}
                  </th>
                  <th scope="col" class="fit">
                    {{ 'project.table.column.name' | translate }}
                  </th>
                  <th scope="col" class="fit">
                    {{ 'project.table.column.organization' | translate }}
                  </th>
                  <th scope="col" class="text-sm-right">
                    {{ 'project.table.column.totalPriceItem' | translate }}
                  </th>
                  <th scope="col" class="fit">{{
                    'app.table.column.action' | translate
                  }}</th>
                </tr>
              </thead>
              <tbody formArrayName="workPlanList">
                <tr *ngIf="formGroup.value.workPlanList.length == 0">
                  <td colspan="99" class="bg-white text-center">
                    {{ 'app.info.noData' | translate }}
                  </td>
                </tr>
                <tr
                  *ngFor="
                    let workPlan of formArrayWorkPlanList.controls;
                    let i = index
                  "
                  [formGroup]="workPlan"
                >
                  <td class="text-center">{{ i + 1 }}.</td>
                  <td>
                    {{ workPlan.controls.number.value }}
                  </td>
                  <td>
                    {{ workPlan.controls.name.value }}
                  </td>
                  <td>
                    {{ workPlan.controls.organizationName.value }}
                  </td>
                  <td class="text-sm-right">
                    {{ workPlan.value.convertTotalPriceItem }}
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-danger btn-sm"
                      type="button"
                      (click)="doDeleteWorkPlan(i, workPlan.value)"
                    >
                      <span class="fa fas fa-trash"></span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row justify-content-center mt-5">
            <button
              type="button "
              class="btn btn-secondary"
              (click)="doCancel()"
              [disabled]="formSaving"
            >
              {{ 'app.button.back' | translate }}
            </button>
            <button
              type="button "
              class="btn btn-primary ml-2"
              (click)="doSave()"
            >
              {{ 'app.button.save' | translate }}
            </button>
          </div>
        </app-card>
      </div>
    </app-card>
  </div>
</div>
