import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { ClaimStatus } from '../bean/claim-status';

export class ClaimEntity extends BaseWorkflowObjectEntity {
  idJaminan: string;
  accidentDate: Date;
  kantorJR: string;
  tautanGL: string;
  tautanHistory: string;
  glNumber: string;
  hospitalCode: string;
  hospitalName: string;
  accidentVictim: string;
  description: string;
  claimStatus: ClaimStatus;
  glDate: Date;
  glStatus: string;
  counterCode: string;
  verificatorCode: string;
}
