import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppFieldsetComponent } from './app-fieldset.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AppFieldsetComponent],
  exports: [AppFieldsetComponent]
})
export class AppFieldsetModule {}
