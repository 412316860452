import { EventEmitter } from '@angular/core';

export class TableCatalogFilterModel {
  public filterMapper: Map<string, any>;
  requestValueChanges: EventEmitter<any> = new EventEmitter();
  isHasvalue: boolean;
  constructor(public isWaitingFromServer: boolean = true) {}

  setRequestValues(filterMapper: Map<string, any>): void {
    this.filterMapper = filterMapper;
    this.isHasvalue = true;
    this.requestValueChanges.emit(filterMapper);
  }
}
