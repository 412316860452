<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="principle-permit.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [tableResponse]="tableResponse"
        [isServerSide]="true"
        stringUrl="/principle-permit-submission/index"
      >
        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            *ngIf="
              rowData.workflowPrcs.workflowStatus.code == 'ON_GOING' ||
              rowData.workflowPrcs.workflowStatus.code ==
                global.appConstant.core.WORKFLOW_CODE_STATUS_REVISION
            "
            (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}"
          >
            <em class="fas fa-pencil-alt"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
