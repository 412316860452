<div class="modal-header" *ngIf="header">
  <h3 class="modal-title fw-500" maxLine attr.title="{{ header | translate }}">
    {{ header | translate }}
  </h3>
  <button
    type="button"
    class="close outline-none"
    aria-label="Close"
    (click)="doClosePopup($event)"
    *ngIf="isShowHeaderCloseButton"
  >
    <!-- <span aria-hidden="true">&times;</span> -->
    <span aria-hidden="true" class="pir pi-times"></span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="isLoading; then loadingTemplate; else contentTemplate">
  </ng-container>
  <ng-template #contentTemplate>
    <ng-content></ng-content>
  </ng-template>
  <ng-template #loadingTemplate>
    <div class="promise-loading" *ngIf="isLoading">
      <div class="loading"></div>
    </div>
  </ng-template>
</div>
<div class="modal-footer" *ngIf="modalFooterTmpl && !isLoading">
  <ng-container
    *ngTemplateOutlet="modalFooterTmpl; context: { $implicit: ngbActiveModal }"
  >
  </ng-container>
</div>
