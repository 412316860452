import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ScoringCriteriaGroupEditAddComponent } from './scoring-criteria-group-edit-add.component';
import { ScoringCriteriaGroupComponent } from './scoring-criteria-group.component';
const routes = [
  {
    path: '',
    component: ScoringCriteriaGroupComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'add',
    component: ScoringCriteriaGroupEditAddComponent,
    data: { breadcrumb: 'scoring-criteria-group.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: ScoringCriteriaGroupEditAddComponent,
    data: { breadcrumb: 'scoring-criteria-group.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],

  declarations: [
    ScoringCriteriaGroupComponent,
    ScoringCriteriaGroupEditAddComponent
  ],

  providers: []
})
export class ScoringCriteriaGroupModule {}
