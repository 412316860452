import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { PurchaseOrder } from '../../../core/bean/purchase-order';
import { PurchaseRequest } from '../../../core/bean/purchase-request';
import { PurchaseRequestItem } from '../../../core/bean/purchase-request-item';
import { AppTableComponent } from '../../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../../core/components/upload';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { RouteRequestModel } from '../../../core/model/route-request-model';
import { Validators } from '../../../core/validators';
import { PurchaseOrderDTO } from '../dto/purchase-order.dto';
@Component({
  templateUrl: 'work-plan-purchase-order.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class WorkPlanPurchaseOrderComponent extends BaseModuleComponent {
  public readonly FILE_TYPE_CODE = this.global.appConstant.fileType.DOC_PURCHASE_ORDER;
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public tableResponse: TableResponseModel<File>;
  public workPlanRealizeId: number;
  organizationName: string;
  url: string;
  public routeList: string[] = [
    'work-plan',
    'purchase-request',
    'procurement',
    'contract',
    'purchase-order',
    'delivery-received',
    'payment'
  ];
  public purchaseOrderDTO: PurchaseOrderDTO = new PurchaseOrderDTO();
  fileUploader: FileUploader = new FileUploader(
    '/purchase-order/',
    null,
    this.FILE_TYPE_CODE,
    false,
    5
  );
  public fileUploaderList: FileUploader[] = new Array();
  public isAllUploaded = false;
  purchaseRequestItem: PurchaseRequestItem = new PurchaseRequestItem();
  purchaseRequestList: PurchaseRequest[];
  purchaseOrderList: PurchaseOrder[];
  public isHavePO = false;
  public invalid = false;
  public isHavePR = false;
  public isHaveProcurement = false;
  public isHaveContract = false;
  public isHaveDR = false;
  public isPONumberValidList: boolean[] = new Array();
  public samePONumberList: string[] = new Array();
  public isAllPONumberValid = true;
  msgInfo = ' ';
  public purchaseRequestIds: number[] = [];
  public purchaseRequestIdsDisable: number[] = [];
  public isDisabledAddMore = false;
  constructor(translateService: TranslateService) {
    super('work-plan-purchase-order', translateService);
  }

  onInit(): void {
    // this.workPlanRealizeId = this.global.routerParams['workPlanRealizeId'];
    this.workPlanRealizeId = this.global.routerParams.get('workPlanRealizeId');

    this.formGroup = this.formBuilder.group({
      purchaseOrderList: this.formBuilder.array([])
    });

    this.httpClientService
      .post<PurchaseOrderDTO>(
        '/purchase-order/add-edit',
        new RouteRequestModel(this.todo, this.workPlanRealizeId)
      )
      .subscribe(purchaseOrderDTO => {
        this.purchaseOrderDTO = purchaseOrderDTO;
        this.purchaseOrderList = purchaseOrderDTO.purchaseOrderList;
        this.purchaseOrderDTO.workPlanRealizeId = this.workPlanRealizeId;
        this.purchaseOrderDTO.organizationName =
          purchaseOrderDTO.organizationName;
        if (this.purchaseOrderDTO.purchaseOrderList !== null) {
          this.isHavePO = true;
          this.todo = 'edit';
          this.buildPurchaseOrder(this.purchaseOrderDTO.purchaseOrderList);
        } else {
          this.buildPurchaseOrder(this.purchaseOrderDTO.purchaseOrderList);
        }
        this.setStateReady();
      });
    this.checkUploadedStatus();
  }

  public onTabChange(event): void {
    this.router.navigate([
      '/pages/work-plan-realize/' + this.routeList[event.index]
    ]);
  }

  public checkPONumber(index: number): void {
    let next = true;
    const purchaseOrderList: PurchaseOrder[] = this.formGroup.controls
      .purchaseOrderList.value;
    const num = purchaseOrderList[index].number;
    purchaseOrderList.forEach((purchaseOrder, index2) => {
      this.isPONumberValidList[index2] = true;
      purchaseOrderList.forEach((purchaseOrder2, index3) => {
        if (
          index2 !== index3 &&
          purchaseOrder.number === purchaseOrder2.number
        ) {
          this.isPONumberValidList[index2] = false;
          this.samePONumberList[index2] = purchaseOrder.number;
          next = false;
        }
      });
    });
    if (next && num !== '') {
      this.log.debug(index);
    }
    this.isAllPONumberValid = next;
  }

  public buildPurchaseOrder(purchaseOrderList: PurchaseOrder[]): void {
    const purchaseOrderForm = this.formGroup.controls
      .purchaseOrderList as FormArray;
    if (purchaseOrderList !== null) {
      this.purchaseOrderDTO.purchaseOrderList.forEach(
        (purchaseOrder, index) => {
          const createdDate: Date = new Date(purchaseOrder.createdDate);
          purchaseOrderForm.push(
            this.formBuilder.group({
              id: purchaseOrder.id,
              number: [purchaseOrder.number, Validators.required()],
              createdDate: [createdDate, Validators.required()],
              description: [purchaseOrder.description, Validators.required()],
              deliveryAddress: [
                purchaseOrder.deliveryAddress,
                Validators.required()
              ],
              purchaseRequestList: this.formBuilder.array([]),
              file: [purchaseOrder.fileList, Validators.required()]
            })
          );
          this.buildPurchaseRequestList(
            purchaseOrder.purchaseRequestList,
            index
          );
          this.fileUploaderList.push(
            new FileUploader(
              '/purchase-order/',
              null,
              this.FILE_TYPE_CODE,
              false,
              5
            )
          );
          this.fileUploaderList[index].setFileList(purchaseOrder.fileList);
          this.isPONumberValidList.push(true);
        }
      );
    } else {
      const index = purchaseOrderForm.length;
      purchaseOrderForm.push(
        this.formBuilder.group({
          id: [null],
          number: [null, Validators.required()],
          createdDate: [null, Validators.required()],
          description: [null, Validators.required()],
          deliveryAddress: [null, Validators.required()],
          purchaseRequestList: this.formBuilder.array([]),
          file: [null, Validators.required()]
        })
      );
      this.fileUploaderList.push(
        new FileUploader(
          '/purchase-order/',
          null,
          this.FILE_TYPE_CODE,
          false,
          5
        )
      );
      this.buildPurchaseRequestList(null, index);
      this.isPONumberValidList.push(true);
    }
  }

  public buildPurchaseRequestList(
    purchaseOrderRequestList: PurchaseRequest[],
    index: number
  ): void {
    const purchaseOrderForm = this.formGroup.controls
      .purchaseOrderList as FormArray;
    const purchaseRequestListForm = purchaseOrderForm.controls[index].get(
      'purchaseRequestList'
    ) as FormArray;
    if (purchaseOrderRequestList === null) {
      purchaseRequestListForm.push(
        this.formBuilder.group({
          id: null,
          purchaseRequest: null,
          vendor: [null, Validators.required()],
          totalRealisasi: null,
          purchaseRequestItemList: this.formBuilder.array([])
        })
      );
    } else {
      purchaseOrderRequestList.forEach((purchaseRequestValue, indexPR) => {
        // tslint:disable-next-line:max-line-length
        const indexPurchaseRequest = this.purchaseOrderDTO.purchaseRequestList.findIndex(
          purchaseRequest =>
            purchaseRequest.id ===
            this.purchaseOrderDTO.purchaseOrderList[index].purchaseRequestList[
              indexPR
            ].id
        );

        purchaseRequestListForm.push(
          this.formBuilder.group({
            id: purchaseRequestValue.id,
            purchaseRequest: this.purchaseOrderDTO.purchaseRequestList[
              indexPurchaseRequest
            ],
            vendor: purchaseRequestValue.purchaseOrderItemList[indexPR].vendor,
            totalRealisasi: purchaseRequestValue.name,
            purchaseRequestItemList: this.formBuilder.array([])
          })
        );
        this.doOnChangePurchaseRequest(
          purchaseRequestListForm.controls[indexPR]
        );
      });
    }
  }

  public doDeletePurchaseRequest(
    i: number,
    index: number,
    purchaseRequestList: FormArray
  ): void {
    const id = purchaseRequestList.at(i).value.id;
    this.purchaseOrderDTO.purchaseRequestList.forEach(purchaseRequest => {
      if (purchaseRequest.id === id) {
        purchaseRequest.isDisabled = false;
      }
    });
    const purchaseOrderListForm = this.formGroup.controls
      .purchaseOrderList as FormArray;
    const purchaseRequestListForm = purchaseOrderListForm.controls[index].get(
      'purchaseRequestList'
    ) as FormArray;
    if (purchaseRequestListForm.length > 1) {
      purchaseRequestListForm.removeAt(i);
    } else {
      this.global.alertService.showError(
        this.translateService.instant(
          'work-plan-realize.alert.addressJobMinimum'
        )
      );
    }
    this.isDisabledAddMore = false;
    this.msgInfo = '';
    this.checkUploadedStatus();
  }

  public onClickAddPurchaseRequest(i: number): void {
    const purchaseOrderForm = this.formGroup.controls
      .purchaseOrderList as FormArray;
    const purchaseRequestListForm = purchaseOrderForm.controls[i].get(
      'purchaseRequestList'
    ) as FormArray;
    purchaseRequestListForm.push(
      this.formBuilder.group({
        id: null,
        purchaseRequest: null,
        vendor: null,
        totalRealisasi: [null],
        purchaseRequestItemList: this.formBuilder.array([])
      })
    );
  }

  public onChangeFileUpload(): void {
    this.checkUploadedStatus();
  }

  public doNext(): void {
    this.isHavePO = true;
    this.router.navigate(['/pages/work-plan-realize/delivery-received']);
  }

  public doPrevios(): void {
    this.isHavePO = true;
    this.router.navigate(['/pages/work-plan-realize/contract']);
  }

  public checkUploadedStatus(): void {
    let isAllUploaded = true;
    this.fileUploaderList.forEach(fileuploader => {
      if (!fileuploader.isAllUploaded) {
        isAllUploaded = fileuploader.isAllUploaded;
      }
    });
    this.isAllUploaded = isAllUploaded;
  }

  public onClickAddPurchaseOrder(): void {
    this.buildPurchaseOrder(null);
  }

  public doDeletePurchaseOrder(i: number): void {
    const purchaseOrderForm = this.formGroup.controls
      .purchaseOrderList as FormArray;
    if (purchaseOrderForm.length > 1) {
      purchaseOrderForm.removeAt(i);
      this.fileUploaderList.splice(i);
    } else {
      this.global.alertService.showError(
        this.translateService.instant(
          'work-plan-realize.alert.addressJobMinimum'
        )
      );
    }
    this.checkUploadedStatus();
  }
  public buildPurchaseRequestItemList(
    purchaseRequestItemList: PurchaseRequestItem[],
    index: number,
    purchaseRequestListForm: FormArray
  ): void {
    const purchaseRequesItemtListForm = purchaseRequestListForm.controls[
      index
    ].get('purchaseRequestItemList') as FormArray;
    purchaseRequestItemList.forEach(purchaseRequestItem => {
      this.purchaseRequestItem = purchaseRequestItem;
      purchaseRequesItemtListForm.push(
        this.formBuilder.group({
          id: purchaseRequestItem.id,
          quantity: purchaseRequestItem.quantity,
          unitPrice: purchaseRequestItem.unitPrice,
          totalPrice: purchaseRequestItem.totalPrice,
          unitPriceRealize: purchaseRequestItem.unitPriceRealize,
          totalPriceRealize: purchaseRequestItem.totalPriceRealize,
          workPlanItem: purchaseRequestItem.workPlanItem,
          description: purchaseRequestItem.description,
          vendor: null
        })
      );
    });
  }

  public doOnChangePurchaseRequest(purchaseRequestObj?: any): void {
    const purchaseOrderListForm = this.formGroup.controls
      .purchaseOrderList as FormArray;
    let purchaseRequest = new PurchaseRequest();
    purchaseRequest = purchaseRequestObj.get('purchaseRequest').value;
    const purchaseRequestItemListForm = purchaseRequestObj.controls
      .purchaseRequestItemList as FormArray;
    purchaseRequest.isDisabled = true;
    this.purchaseRequestIds = [];
    this.purchaseRequestIdsDisable = [];

    for (const purchaseOrder of purchaseOrderListForm.value) {
      for (const pr of purchaseOrder.purchaseRequestList) {
        this.purchaseRequestIds.push(pr.purchaseRequest.id);
      }
    }

    this.purchaseOrderDTO.purchaseRequestList.forEach(pr => {
      this.purchaseRequestIds.forEach(id => {
        if (pr.id === id) {
          this.purchaseRequestIdsDisable.push(pr.id);
        }
      });
    });

    this.purchaseOrderDTO.purchaseRequestList.forEach(pr => {
      pr.isDisabled = false;
      this.purchaseRequestIdsDisable.forEach(id => {
        if (pr.id === id) {
          pr.isDisabled = true;
        }
      });
    });

    const lengthPR = this.purchaseOrderDTO.purchaseRequestList.length;
    const lengthPRSelected = this.purchaseRequestIdsDisable.length;

    if (lengthPR === lengthPRSelected) {
      this.isDisabledAddMore = true;
      this.msgInfo = this.translateService.instant(
        'purchase-order.tooltip.purchaseOrder'
      );
    }
    let totalRealisasi = 0;

    for (let i = 0; i <= purchaseRequestItemListForm.length; i++) {
      purchaseRequestItemListForm.removeAt(0);
    }

    purchaseRequest.purchaseRequestItemList.forEach(purchaseRequestItem => {
      purchaseRequestItemListForm.push(
        this.formBuilder.group({
          id: purchaseRequestItem.id,
          quantity: purchaseRequestItem.quantity,
          unitPrice: purchaseRequestItem.unitPrice,
          totalPrice: purchaseRequestItem.totalPrice,
          unitPriceRealize: purchaseRequestItem.unitPriceRealize,
          totalPriceRealize: purchaseRequestItem.totalPriceRealize,
          workPlanItem: purchaseRequestItem.workPlanItem,
          description: purchaseRequestItem.description,
          vendor: null
        })
      );

      totalRealisasi = totalRealisasi + purchaseRequestItem.totalPrice;
    });

    purchaseRequestObj.patchValue({
      id: purchaseRequest.id,
      totalRealisasi
    });
  }

  get form(): any {
    return this.formGroup.controls;
  }

  public doSave(): void {
    this.validate();
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.purchaseOrderDTO = this.global.copyFormAttributeToModel(
            this.purchaseOrderDTO,
            this.formGroup
          );
          this.purchaseOrderDTO.purchaseOrderList.forEach(
            (purchaseOrder, i) => {
              purchaseOrder.fileObjectList = this.fileUploaderList[
                i
              ].fileObjectList;
            }
          );
          this.setStateProcessing();
          if (this.todo === 'edit') {
            this.url = '/purchase-order/update';
          } else {
            this.url = '/purchase-order/insert';
          }
          this.httpClientService
            .post<Response<PurchaseOrder>>(this.url, this.purchaseOrderDTO)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.isHavePO = true;
                this.global.alertService.showSuccessSaving();
                this.setStateReady();
              } else {
                this.global.alertService.showError(response.statusText);
                this.setStateReady();
                this.invalid = true;
              }
            });
        }
      });
  }

  public get formArrayPurchaseOrderList(): FormArray {
    return this.formGroup.get('purchaseOrderList') as FormArray;
  }
}
