import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ScoringTemplate } from '../../core/bean/scoring-template';
import { AppPopupImportService } from '../../core/components/app-popup/app-popup-import/app-popup-import.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { AppPopupScoringTemplateComponent } from './app-popup-scoring-template.component';
@Component({
  templateUrl: 'scoring-template.component.html'
})
export class ScoringTemplateComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<ScoringTemplate>;
  constructor(
    translateService: TranslateService,
    public appPopupImportService: AppPopupImportService,
    public appPopupService: AppPopupService
  ) {
    super('scoring-template', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      {
        field: 'name',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      },
      { field: 'moduleScoringCriteria.name', header: 'table.column.module' }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/scoring-template/add']);
  }

  public doSetting(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view-setting');
    this.router.navigate(['/pages/scoring-template/view-setting']);
  }

  public doEdit(scoringTemplate: ScoringTemplate): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('scoringTemplateId', scoringTemplate.id);
    this.router.navigate(['/pages/scoring-template/edit']);
  }

  public doPopupCopy(scoringTemplate: ScoringTemplate): void {
    const todoNew = 'app-popup-scoring-template';
    const scoringTemplateId = scoringTemplate.id;
    const todo = 'copy';
    this.appPopupService.open(AppPopupScoringTemplateComponent, {
      todoNew,
      scoringTemplateId,
      todo
    });
  }

  public doDelete(scoringTemplate: ScoringTemplate): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<ScoringTemplate>>(
              '/scoring-template/delete',
              scoringTemplate
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
