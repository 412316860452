import { Component, ViewEncapsulation } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { PurchaseOrder } from '../../../core/bean/purchase-order';
import { PurchaseOrderItem } from '../../../core/bean/purchase-order-item';
import { TableResponseModel } from '../../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../../core/components/upload';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { RouteRequestModel } from '../../../core/model/route-request-model';
import { Validators } from '../../../core/validators';
import { WorkPlanDeliveryReceivedDTO } from '../dto/work-plan-delivery-received.dto';
@Component({
  templateUrl: 'work-plan-delivery-received-edit-add.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class WorkPlanDeliveryReceivedComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<File>;
  public workPlanId: number;
  public workPlanRealizeId: number;
  public purchaseOrderItemId: number;
  public routeList: string[] = [
    'work-plan',
    'purchase-request',
    'procurement',
    'contract',
    'purchase-order',
    'delivery-received',
    'payment'
  ];
  workPlanDeliveryReceivedDTO: WorkPlanDeliveryReceivedDTO = new WorkPlanDeliveryReceivedDTO();
  fileUploaderList: FileUploader[][] = new Array();
  url: string;
  isValidUpload: boolean;
  public isHaveDR = false;

  constructor(translateService: TranslateService) {
    super('work-plan-delivery-received', translateService);
  }

  onInit(): void {
    this.workPlanId = this.global.routerParams.get('workPlanId');
    this.workPlanRealizeId = this.global.routerParams.get('workPlanRealizeId');

    this.formGroup = this.formBuilder.group({
      purchaseOrderList: this.formBuilder.array([])
    });
    this.httpClientService
      .post<WorkPlanDeliveryReceivedDTO>(
        '/work-plan-delivery-received/add-edit',
        new RouteRequestModel(this.todo, this.workPlanRealizeId)
      )
      .subscribe(workPlanDeliveryReceivedDTO => {
        this.workPlanDeliveryReceivedDTO = workPlanDeliveryReceivedDTO;
        this.buildPurchaseOrder(workPlanDeliveryReceivedDTO.purchaseOrderList);
        this.setStateReady();
      });
  }

  public buildPurchaseOrder(purchaseOrderList: PurchaseOrder[]): void {
    const purchaseOrderForm = this.formGroup.controls
      .purchaseOrderList as FormArray;
    purchaseOrderList.forEach(purchaseOrder => {
      purchaseOrderForm.push(
        this.formBuilder.group({
          id: purchaseOrder.id,
          number: purchaseOrder.number,
          purchaseOrderItemList: this.buildPurchaseOrderItem(
            purchaseOrder.purchaseOrderItemList
          )
        })
      );
    });
  }

  public buildPurchaseOrderItem(
    purchaseOrderItemList: PurchaseOrderItem[]
  ): FormArray {
    const purchaseOrderItemForm = new FormArray([]);
    const fileUploader: FileUploader[] = new Array();
    purchaseOrderItemList.forEach((purchaseOrderItem, index) => {
      let receivedDate: Date;
      if (purchaseOrderItem.receivedDate != null) {
        this.isHaveDR = true;
        this.isValidUpload = true;
        receivedDate = new Date(purchaseOrderItem.receivedDate);
      } else {
        receivedDate = null;
      }
      purchaseOrderItemForm.push(
        this.formBuilder.group({
          id: purchaseOrderItem.id,
          purchaseRequestItem: purchaseOrderItem.purchaseRequestItem,
          purchaseOrder: purchaseOrderItem.purchaseOrder,
          vendor: purchaseOrderItem.vendor,
          quantity: purchaseOrderItem.purchaseRequestItem.quantity,
          receivedDate: [receivedDate, Validators.required()],
          file: purchaseOrderItem.file
        })
      );

      fileUploader.push(
        new FileUploader(
          '/work-plan-delivery-received/',
          ' ',
          this.global.appConstant.fileType.DOC_WORKPLAN_DELIVERY_RECEIVED,
          true,
          1
        )
      );
      fileUploader[index].setFile(purchaseOrderItem.file);
      fileUploader[index].titleRequired = false;

      this.fileUploaderList.push(fileUploader);
    });
    return purchaseOrderItemForm;
  }

  public onChangeFileUpload(): void {
    let num = 0;
    this.isValidUpload = true;
    this.workPlanDeliveryReceivedDTO.purchaseOrderList.forEach(
      (purchaseOrder, a) => {
        // tslint:disable-next-line: variable-name
        purchaseOrder.purchaseOrderItemList.forEach((_purchaseOrderItem, b) => {
          this.fileUploaderList[a][b].fileObjectList.forEach(fileUploader => {
            if (fileUploader.isUploaded) {
              num++;
            }
          });
        });
      }
    );
    if (this.fileUploaderList.length === num) {
      this.isValidUpload = true;
    } else {
      this.isValidUpload = false;
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.workPlanDeliveryReceivedDTO = this.global.copyFormAttributeToModel(
              this.workPlanDeliveryReceivedDTO,
              this.formGroup
            );
            this.workPlanDeliveryReceivedDTO.purchaseOrderList.forEach(
              (purchaseOrder, a) => {
                purchaseOrder.purchaseOrderItemList.forEach(
                  (purchaseOrderItem, b) => {
                    purchaseOrderItem.fileObjectList = this.fileUploaderList[a][
                      b
                    ].fileObjectList;
                    purchaseOrderItem.file = null;
                  }
                );
              }
            );
            this.httpClientService
              .post<Response<WorkPlanDeliveryReceivedDTO>>(
                '/work-plan-delivery-received/update',
                this.workPlanDeliveryReceivedDTO
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.isHaveDR = true;
                  this.global.alertService.showSuccessSaving();
                  this.setStateReady();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doPrevious(): void {
    this.global.routerParams.set('workPlanId', this.workPlanId);
    this.router.navigate(['/pages/work-plan-realize/purchase-order']);
  }

  public doNext(): void {
    const data = { index: 6 };
    this.onTabChange(data);
  }

  onTabChange(event): void {
    this.router.navigate([
      '/pages/work-plan-realize/' + this.routeList[event.index]
    ]);
  }

  public get formArrayPurchaseOrderItemList(): FormArray {
    return this.formGroup.get('purchaseOrderItemList') as FormArray;
  }

  public get formArrayPurchaseOrderList(): FormArray {
    return this.formGroup.get('purchaseOrderList') as FormArray;
  }
}
