import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { BudgetAllocation } from '../../core/bean/budget-allocation';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { BudgetAllocationByCoaView } from '../../core/view/entity/bean/budget-allocation-by-coa-view';
import { AppPopupBudgetAllocationBjbComponent } from './app-popup-budget-allocation-bjb.component';
@Component({
  templateUrl: './budget-allocation-bjb-coa.component.html'
})
export class BudgetAllocationBjbCoaComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;

  public coaId: number;
  public tableResponse: TableResponseModel<BudgetAllocationByCoaView>;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('budget-allocation-bjb', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setDataFromRouterParams();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.coaId = this.global.routerParams.get('coaId');
    this.formGroup.patchValue({
      coaId: this.coaId
    });
    this.tableResponse.setCustomData(this.formGroup.get('coaId').value);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      coaId: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'coa.code',
        header: 'table.column.coaCode',
        customClass: 'text-center',
        fn: this.handleHyperlink.bind(this)
      },
      { field: 'coa.name', header: 'table.column.coaName' },
      {
        field: 'totalBudget',
        header: 'table.column.totalBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'bookedBudget',
        header: 'table.column.bookedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'usedBudget',
        header: 'table.column.usedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'paidOffBudget',
        header: 'table.column.paidOffBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'remainingPayment',
        header: 'table.column.remainingPayment',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'availableBudget',
        header: 'table.column.availableBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      }
    ]);
  }

  public handleHyperlink(value: any, record: any): void {
    this.log.debug(value);
    let aElement: any = record.coa.code;
    if (!record.coa.isHasChild) {
      aElement = document.createElement('a');
      aElement.style.color = 'var(--primary)';
      aElement.addEventListener('click', () => {
        this.doDetailCoaOrganization(record);
      });
      aElement.textContent = record.coa.code;
    }
    return aElement;
  }

  public doAdd(): void {
    this.global.routerParams.set('todo', 'add');
    this.global.routerParams.set(
      'backUrl',
      '/pages/budget-allocation-bjb/coa/detail/index'
    );
    this.router.navigate(['/pages/budget-allocation-bjb/add']);
  }

  public doDetailCoa(budgetAllocation: BudgetAllocationByCoaView): void {
    this.coaId = budgetAllocation.coa.id;
    this.formGroup.patchValue({
      coaId: this.coaId
    });
    this.tableResponse.setCustomData(this.formGroup.value.coaId);
    this.table.reload();
  }

  public doDetailCoaOrganization(
    budgetAllocation: BudgetAllocationByCoaView
  ): void {
    const coaId = budgetAllocation.coa.id;
    this.appPopupService
      .open(AppPopupBudgetAllocationBjbComponent, { coaId })
      .subscribe(() => {});
  }
  public doEdit(budget: BudgetAllocation): void {
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('budgetId', budget.id);
    this.global.routerParams.set(
      'backUrl',
      '/pages/budget-allocation-bjb/coa/detail/index'
    );
    this.router.navigate(['/pages/budget-allocation-bjb/edit']);
  }

  public doDelete(budget: BudgetAllocation): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tableResponse.loading = true;
          this.httpClientService
            .post<Response<BudgetAllocation>>(
              '/budget-allocation-bjb/delete',
              budget
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }
  public doCancel(): void {
    this.router.navigate(['/pages/budget-allocation-bjb']);
  }
}
