import { BaseEntity } from '../base/base-entity';
import { Region } from '../bean/region';
import { Vendor } from '../bean/vendor';

export class VendorRepresentativeAgentEntity extends BaseEntity {
    phoneNumber: string;
    vendorChild: Vendor;
    vendorParent: Vendor;
    phoneRegion: Region;
    vendor: Vendor;
    companyName: string;
    taxNumber: string;
    address: string;
    phone: string;
    email: string;
    information: string;
    coordinateLocation: string;
    companyStatus: number;
}
