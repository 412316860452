import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { File } from '../../core/bean/file';
import { VendorQualification } from '../../core/bean/vendor-qualification';
import { AppPopupImportService } from '../../core/components/app-popup/app-popup-import/app-popup-import.service';
import { ImportFileWebsocketService } from '../../core/components/app-popup/app-popup-import/import-file-websocket.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';

@Component({
  templateUrl: './vendor-qualification.component.html'
})
export class VendorQualificationComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;

  public tableResponse: TableResponseModel<VendorQualification>;
  public readonly urlDeleteVendorQualification = '/vendor-qualification/delete';
  public readonly urlImportExcelVendorQualification =
    'vendor-qualification/import-excel';

  constructor(
    translateService: TranslateService,
    public appPopupImportService: AppPopupImportService,
    public importFileWebsocketService: ImportFileWebsocketService
  ) {
    super('vendor-qualification', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      imgFile: [new File()]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.column.code' },
      { field: 'name', header: 'table.column.name' },
      { field: 'description', header: 'table.column.description' }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/vendor-qualification/add']);
  }

  public doEdit(vendorQualification: VendorQualification): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set(
      'vendorQualificationId',
      vendorQualification.id
    );
    this.router.navigate(['/pages/vendor-qualification/edit']);
  }

  public doDelete(vendorQualification: VendorQualification): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.deleteVendorQualification(vendorQualification);
        }
      });
  }

  public doImport(): void {
    this.appPopupImportService
      .open(this.urlImportExcelVendorQualification)
      .subscribe(result => {
        if (result) {
          this.tableResponse.reload();
        }
      });
    this.importFileWebsocketService.appPopupImportComponent =
      this.appPopupImportService.appPopupService.componentInstance;
  }

  public deleteVendorQualification(
    vendorQualification: VendorQualification
  ): void {
    this.vendorQualificationFromServer(
      this.urlDeleteVendorQualification,
      vendorQualification
    ).subscribe(response => {
      this.showAlertMessage('delete', response);
    });
  }

  public showAlertMessage(
    command: string,
    response: Response<VendorQualification>
  ): void {
    if (response.status === ResponseStatusModel.OK) {
      if (command === 'delete') {
        this.global.alertService.showSuccessDelete();
      }
      if (command === 'save') {
        this.global.alertService.showSuccessSaving();
      }
    } else {
      this.global.alertService.showError(response.statusText);
    }
  }

  public vendorQualificationFromServer(
    urlDeleteVendorQualification: string,
    vendorQualification: VendorQualification
  ): Observable<Response<VendorQualification>> {
    return this.httpClientService.post<Response<VendorQualification>>(
      urlDeleteVendorQualification,
      vendorQualification
    );
  }
}
