<div *ngIf="!formLoading" class="app-widget-contract-report-statistical">
  <app-widget>
    <div class="app-widget-contract-report-statistical-header">
      <h4>{{ moduleCode + '.title' | translate }}</h4>
      <div class="filter-wrapper">
        <em
          class="fas fa-chart-bar"
          (click)="changeType('bar')"
          title="Bar Chart"
        ></em>
        <em
          class="fas fa-chart-line"
          (click)="changeType('line')"
          title="Line Chart"
        ></em>
        <em
          class="fas fa-filter"
          (click)="doOpenPopupFilter()"
          title="Filter"
        ></em>
      </div>
    </div>
    <app-chart [model]="chartModel"></app-chart>
  </app-widget>
</div>
