import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SlaContractView } from 'src/app/core/view/entity/bean/sla-contract-vew';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Workflow } from '../../core/bean/workflow';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
@Component({
  templateUrl: './sla-contract.component.html'
})
export class SlaContractComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public workflowList: OptionListModel<Workflow> = new OptionListModel(true);
  public tableResponse: TableResponseModel<SlaContractView>;
  constructor(translateService: TranslateService) {
    super('sla-contract', translateService);
  }

  public onInit(): void {
    this.setWorkflowList();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      workflowList: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.workflowName',
        className: 'white-space-normal',
        plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
      },
      {
        field: 'title',
        header: 'table.column.title',
        className: 'white-space-normal'
      },
      {
        field: 'vendorName',
        header: 'table.column.vendorName',
        className: 'white-space-normal'
      },
      {
        field: 'totalSlaInDays',
        header: 'table.column.totalSla',
        plugins: {
          name: 'custom-plugin',
          before: (tablePluginData: TablePluginData): string => {
            if (tablePluginData.value !== 0 && tablePluginData.value !== null) {
              return (
                tablePluginData.value +
                ' ' +
                this.translateService.instant('sla-contract.day')
              );
            } else {
              return '-';
            }
          }
        }
      },
      {
        field: 'totalSlaRealizedName',
        header: 'table.column.totalRealization',
        className: 'text-center'
      }
    ]);
  }

  public setTranslationUom(totalSlaRealizedName: string): string {
    let translatedName = '';
    let count = 0;
    if (totalSlaRealizedName.includes('sla-contract.day')) {
      count += 1;
      translatedName = totalSlaRealizedName.replace(
        'sla-contract.day',
        this.translateService.instant('sla-contract.day')
      );
    }
    if (totalSlaRealizedName.includes('sla-contract.hour')) {
      count += 1;
      if (count !== 0) {
        translatedName = translatedName.replace(
          'sla-contract.hour',
          this.translateService.instant('sla-contract.hour')
        );
      } else {
        translatedName = totalSlaRealizedName.replace(
          'sla-contract.hour',
          this.translateService.instant('sla-contract.hour')
        );
      }
    }
    if (totalSlaRealizedName.includes('sla-contract.minute')) {
      if (count !== 0) {
        translatedName = translatedName.replace(
          'sla-contract.minute',
          this.translateService.instant('sla-contract.minute')
        );
      } else {
        translatedName = totalSlaRealizedName.replace(
          'sla-contract.minute',
          this.translateService.instant('sla-contract.minute')
        );
      }
    }
    return translatedName;
  }

  public addUomDay(slaInDays: number): string {
    if (slaInDays !== 0 && slaInDays !== null) {
      return (
        slaInDays + ' ' + this.translateService.instant('sla-contract.day')
      );
    } else {
      return '-';
    }
  }

  public setWorkflowList(): void {
    const moduleGroupCode = 'CM';
    this.httpClientService
      .get<Workflow[]>('/workflow/workflow-list/' + moduleGroupCode)
      .subscribe((workflowList: Workflow[]) => {
        this.workflowList.setRequestValues(workflowList);
      });
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.workflowList) {
      this.formGroup.value.workflowList.forEach(value => {
        value.slaUpdatedDate = new Date(value.slaUpdatedDate);
      });
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doClick(slaContractView: SlaContractView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'detail');
    this.global.routerParams.set('workflowPrcsId', slaContractView.id);
    this.global.routerParams.set('workflowName', slaContractView.name);
    this.router.navigate(['/pages/sla-contract/detail/']);
  }
}
