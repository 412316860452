import { NgModule } from '@angular/core';
import { AppButtonModule } from '../../components/app-button/app-button.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetModule } from '../app-widget.module';
import { AppWidgetCardXModule } from '../components/app-widget-card-x/app-widget-card-x.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetProgressCompleteProfileComponent } from './app-widget-progress-complete-profile.component';
@NgModule({
  imports: [
    VendorModule,
    AppWidgetModule,
    AppButtonModule,
    AppWidgetCardXModule
  ],
  declarations: [AppWidgetProgressCompleteProfileComponent],
  entryComponents: [AppWidgetProgressCompleteProfileComponent],
  exports: [AppWidgetProgressCompleteProfileComponent]
})
export class AppWidgetProgressCompleteProfileModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      'app-widget-progress-complete-profile',
      AppWidgetProgressCompleteProfileComponent
    );
  }
}
