import { resolveTableRecordChildrens } from '../helpers/resolve-table-record-childrens-helper';
import { TableRecord } from '../model/table-record';
import { TableState } from '../model/table-state';

export const selectTableRecord = (
  state: TableState,
  payload: {
    record: TableRecord;
    isChecked: boolean;
    parentRecord?: TableRecord;
  }
): void => {
  if (state.isMultipleSelect === false) {
    state.checkedRecord.setCheckedRecordList([payload.record]);
  } else {
    if (payload.isChecked) {
      if (payload.parentRecord) {
        state.checkedRecord.addToCheckedRecordList(payload.record);
        if (state.checkedRecord.isCheckedAll) {
          state.checkedRecord.removeRecordFromUnCheckedRecordList(
            payload.record
          );
        }
      } else {
        payload.record.selectedRecord.isCheckedAll = payload.isChecked;
        if (state.isGroup) {
          if (
            !payload.record.isExpanded &&
            payload.record.childrens.length === 0
          ) {
            payload.record.tableRequest.rows = 100;
            resolveTableRecordChildrens(state, payload.record).then(value => {
              payload.record.childrens = value.recordList;
              value.recordList.forEach(childrenRecord => {
                state.checkedRecord.addToCheckedRecordList(childrenRecord);
              });
            });
          } else {
            payload.record.childrens.forEach(childrenRecord => {
              const indexOfRecord =
                state.checkedRecord.checkedRecordList.findIndex(
                  checkedRecord =>
                    JSON.stringify(checkedRecord) ===
                    JSON.stringify(childrenRecord)
                );
              indexOfRecord === -1 &&
                state.checkedRecord.addToCheckedRecordList(childrenRecord);
            });
          }
        } else {
          state.checkedRecord.addToCheckedRecordList(payload.record);
          if (state.checkedRecord.isCheckedAll) {
            state.checkedRecord.removeRecordFromUnCheckedRecordList(
              payload.record
            );
          }
        }
      }
    } else {
      if (payload.parentRecord) {
        payload.parentRecord.selectedRecord.isCheckedAll = false;
        state.checkedRecord.removeRecordFromCheckedRecordList(payload.record);
        if (state.checkedRecord.isCheckedAll) {
          state.checkedRecord.addToUnCheckedRecordList(payload.record);
        }
      } else {
        payload.record.selectedRecord.isCheckedAll = payload.isChecked;
        if (state.isGroup) {
          payload.record.childrens.forEach(childrenRecord => {
            state.checkedRecord.removeRecordFromCheckedRecordList(
              childrenRecord
            );
            if (state.checkedRecord.isCheckedAll) {
              state.checkedRecord.addToUnCheckedRecordList(payload.record);
            }
          });
        } else {
          state.checkedRecord.removeRecordFromCheckedRecordList(payload.record);
          if (state.checkedRecord.isCheckedAll) {
            state.checkedRecord.addToUnCheckedRecordList(payload.record);
          }
        }
      }
    }

    if (!state.checkedRecord.isCheckedAll) {
      state.checkedRecord.isCheckedAll =
        state.checkedRecord.checkedRecordList.length ===
        state.pagination.totalRecords;
    }
  }

  state.model.checkedRecord = state.checkedRecord;
  state.setStateReady();
};
