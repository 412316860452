import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProcurementSowPaymentTerm } from 'src/app/core/bean/procurement-sow-payment-term';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './performance-fulfillment-detail-termin.component.html'
})
export class PerformanceFulfillmentDetailTerminComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) table: AppTableComponent;

  public readonly urlRouterNavigateToEditContractFulfillment =
    '/pages/performance-fulfillment/detail/detail-termin';

  public objectId: number;
  public objectName: string;
  public vendorId: number;
  public tableResponse: TableResponseModel<ProcurementSowPaymentTerm>;

  constructor(translateService: TranslateService) {
    super('performance-fulfillment', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setDataFromRouterParams();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.vendorId = this.global.routerParams.get('vendorId');
    this.objectId = this.global.routerParams.get('objectId');
    this.objectName = this.global.routerParams.get('objectName');
    this.tableResponse.setCustomData({
      todo: this.objectName,
      id: this.objectId
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: '1', header: 'table.column.stage', customClass: 'text-center' },
      {
        field: '2',
        header: 'table.column.paymentTerm'
      },
      {
        field: '3',
        header: 'table.column.terminValue',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: '8'
      },
      {
        field: '4',
        header: 'table.column.billingMethod'
      },
      {
        field: '5',
        header: 'table.column.performanceNumber'
      },
      {
        field: '6',
        header: 'table.column.performanceDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: '7',
        header: 'table.column.rating',
        format: FieldFormatEnum.Rating
      }
    ]);
  }

  public doEdit(sowPaymentTermId: number): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('vendorId', this.vendorId);
    this.global.routerParams.set('objectId', this.objectId);
    this.global.routerParams.set('objectName', this.objectName);
    this.global.routerParams.set('sowPaymentTermId', sowPaymentTermId);
    this.router.navigate(['/pages/performance-fulfillment/detail/edit']);
  }

  public doView(sowPaymentTermId: number): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'viewPerformance');
    this.global.routerParams.set('vendorId', this.vendorId);
    this.global.routerParams.set('objectId', this.objectId);
    this.global.routerParams.set('objectName', this.objectName);
    this.global.routerParams.set('sowPaymentTermId', sowPaymentTermId);
    this.router.navigate([
      '/pages/performance-fulfillment/detail/detail-result'
    ]);
  }

  public doBack(): void {
    this.global.routerParams.set('vendorId', this.vendorId);
    this.router.navigate(['/pages/performance-fulfillment/detail']);
  }
}
