<div class="button-group button-group-right button-group-icon-text">
  <!-- <app-button 
  (onClick)="doViewHistory()" 
  title="View History">
  <em class="fas fa-search"></em>
  View History</app-button> -->
</div>
<form class="form-horizontal" *ngIf="!model.isLoading">
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-marketing.form.logo' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      <p *ngIf="vendorHistory.logoFile == null"> - </p>
      <a
        *ngIf="vendorHistory.logoFile != null"
        href="#"
        (click)="
          doDownload(
            $event,
            '/company-profile/file/view/' +
              vendorHistory.logoFile.uploadedFileName +
              '/' +
              global.appConstant.fileType.IMG_LOGO_VENDOR,
            vendorHistory.logoFile.fileName
          )
        "
      >
        <p>{{ vendorHistory.logoFile.fileName }}</p>
      </a>
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-marketing.form.banner' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      <p *ngIf="vendorHistory.bannerFile == null"> - </p>
      <a
        *ngIf="vendorHistory.bannerFile != null"
        href="#"
        (click)="
          doDownload(
            $event,
            '/company-profile/file/view/' +
              vendorHistory.bannerFile.uploadedFileName +
              '/' +
              global.appConstant.fileType.IMG_BANNER_VENDOR,
            vendorHistory.bannerFile.fileName
          )
        "
      >
        <p>{{ vendorHistory.bannerFile.fileName }}</p>
      </a>
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-marketing.form.description' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.description !== nuul ? vendorHistory.description : '-' }}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-marketing.form.fB' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.facebook !== null ? vendorHistory.facebook : '-' }}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-marketing.form.linkedIn' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.linkedin !== null ? vendorHistory.linkedin : '-' }}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-marketing.form.iG' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.ig !== null ? vendorHistory.ig : '-' }}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-marketing.form.twitter' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      {{ vendorHistory.twitter !== null ? vendorHistory.twitter : '-' }}
    </div>
  </div>

  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'tab-marketing.form.file' | translate
    }}</label>
    <div class="col-sm-9 text-left">
      <p *ngIf="vendorHistory.companyProfileFile == null"> - </p>
      <a
        *ngIf="vendorHistory.companyProfileFile != null"
        href="#"
        (click)="
          doDownload(
            $event,
            '/company-profile/file/view/' +
              vendorHistory.companyProfileFile.uploadedFileName +
              '/' +
              global.appConstant.fileType.DOC_COMPANY_PROFILE,
            vendorHistory.companyProfileFile.fileName
          )
        "
      >
        <p>{{ vendorHistory.companyProfileFile.fileName }}</p>
      </a>
    </div>
  </div>
</form>
