import { Claim } from "src/app/core/bean/claim";
import { ClaimDoc } from "src/app/core/bean/claim-doc";
import { ClaimVerification } from "src/app/core/bean/claim-verification";
import { FileObject } from "src/app/core/components/upload";

export class ClaimVerificationRequest {
    claim: Claim;
    claimDocList: ClaimDoc[] = [];
    fileObjectList: FileObject[] = [];
    isResend: boolean;
    claimVerification: ClaimVerification;
}