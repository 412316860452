<app-popup
  class="popup-chage-definition"
  [isLoading]="formLoading"
  header="workload.popup.workloadLevelDefinition.header"
  [isShowHeaderCloseButton]="false"
>
  <form [formGroup]="formGroup">
    <app-select
      [isMultiple]="false"
      formControlName="workloadThresholdObject"
      [optionList]="workloadThresholdObjectOptionList"
      maxHeight="max-content"
      [isShowBorder]="true"
      [search]="true"
    >
      <ng-template #content let-data>
        <div>
          {{
            data.translationKey.module.code.toLowerCase() +
              '.' +
              data.translationKey.key | translate
          }}
        </div>
      </ng-template>
    </app-select>
  </form>
  <div class="button-group button-group-center mt-5">
    <app-button [outline]="true" color="SECONDARY" (onClick)="doCancel()">{{
      'app.button.cancel' | translate
    }}</app-button>
    <app-button (onClick)="doAdd()">{{
      'app.button.select' | translate
    }}</app-button>
  </div>
</app-popup>
