import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { CustomValidatorError } from '../../core/custom-validator-error';
export class MaxValidator {
  public static max(max: number | string, message?: string): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors => {
      const value = abstractControl.value?.price
        ? abstractControl.value.price
        : abstractControl.value;
      if (typeof max === 'number') {
        if (value && value > max) {
          return CustomValidatorError.create('max', message);
        }
      } else {
        const ACParent = abstractControl.parent as FormGroup;
        if (
          ACParent &&
          ACParent.get(max) instanceof AbstractControl &&
          value &&
          ACParent.get(max).value
        ) {
          if (value > ACParent.get(max).value) {
            return CustomValidatorError.create('max', message);
          }
          ACParent.get(max).valueChanges.subscribe((val: any) => {
            if (val && !abstractControl.valid && +value <= +val) {
              abstractControl.updateValueAndValidity();
            }
          });
        }
      }
    };
  }
}
