<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="contract-template.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'contract-template.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              type="code"
              autofocus="true"
              formControlName="code"
              maxLength="64"
            ></app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'contract-template.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              type="alphanumeric"
              formControlName="name"
              maxLength="128"
              include="-/"
            ></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'contract-template.form.templateGroup' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              formControlName="templateGroup"
              stringUrl="/template-group"
              header="Template Group"
            >
            </app-text-tree>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'contract-template.form.contractType' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              formControlName="contractType"
              [optionList]="contractTypeList"
              (onChange)="onChangeContractType($event)"
            ></app-combo-box>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'contract-template.form.dealingAgreementType' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-chosen
              formControlName="dealingAgreementType"
              [optionList]="dealingAgreementTypeList"
            >
            </app-chosen>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'contract-template.form.organization' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              formControlName="organization"
              stringUrl="/organization"
              isLazy="true"
            >
            </app-text-tree>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'contract-template.form.description' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area
              formControlName="description"
              maxLength="512"
            ></app-text-area>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'contract-template.form.uploadFile' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-upload
              formControlName="file"
              [fileUploader]="fileUploader"
            >
            </app-upload>
          </div>
        </div>

        <br />
        <h3 class="separator">
          {{ 'contract-template.form-group.keyword' | translate }}
            <em class="fas fa-info-circle fa-xs" ngbTooltip="{{ 'contract-template.tooltip.keyword' | translate }}"></em>
        </h3>

        <div class="form-group row required">
          <div class="col-lg-12 text-center">
            <app-pick-list
              [model]="keywordPickListModel"
              searchSourcePlaceholder="contract-template.appPickList.placeHolderKeyword"
              searchTargetPlaceholder="contract-template.appPickList.placeHolderKeyword"
              sourceTitle="contract-template.appPickList.listKeyword"
              targetTitle="contract-template.appPickList.setKeyword"
            >
            </app-pick-list>
            <small
              class="text-danger"
              [ngStyle]="{ 'margin-left': '18%' }"
              *ngIf="formGroup.value.keyword == false"
            >
              {{ 'app.validation.required' | translate }}
            </small>
          </div>
        </div>

        <br />
        <h3 
          class="separator"
          >{{ 'contract-template.form-group.contractParameter' | translate }}</h3
        >

        <div class="form-group row required">
          <div class="col-lg-12 text-center">
            <app-pick-list
              [model]="parameterPickListModel"
              searchSourcePlaceholder="contract-template.appPickList.placeHolderParameter"
              searchTargetPlaceholder="contract-template.appPickList.placeHolderParameter"
              sourceTitle="contract-template.appPickList.listParameter"
              targetTitle="contract-template.appPickList.setParameter"
            >
            </app-pick-list>
            <small
              class="text-danger"
              [ngStyle]="{ 'margin-left': '18%' }"
              *ngIf="formGroup.value.keyword == false"
            >
              {{ 'app.validation.required' | translate }}
            </small>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button color="SECONDARY" class="mr-1" (onClick)="doCancel()">
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button color="PRIMARY" (onClick)="doSave()">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
