import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { Payment } from '../bean/payment';

export class PaymentTaxProofEntity extends BaseEntity {
  payment: Payment = new Payment();
  file: File = new File();
  description: string;
  version: number;
}
