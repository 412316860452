import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { VendorModule } from '../../vendor/vendor.module';
import { AppChartComponent } from './app-chart.component';
@NgModule({
  imports: [VendorModule, ChartsModule],
  declarations: [AppChartComponent],
  exports: [AppChartComponent]
})
export class AppChartModule {}
