import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { VendorEquipmentHistory } from '../bean/vendor-equipment-history';
import { FileObject } from '../components/upload';
export class VendorEquipmentDocHistoryEntity extends BaseEntity {
    fileObjectList: FileObject[];
    crudInfo: string;
    file: File;
    vendorEquipmentHistory: VendorEquipmentHistory;
    createdDate: Date;
    certificateNumber: string;
    startDate: Date;
    endDate: Date;
    vendorEquipmentDocId: number;
}
