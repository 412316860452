import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppChartModule } from '../app-chart/app-chart.module';
import { AppActualPlanBarChartComponent } from './app-actual-plan-bar-chart.component';
@NgModule({
  imports: [VendorModule, AppChartModule, AppCardModule],
  declarations: [AppActualPlanBarChartComponent],
  entryComponents: [AppActualPlanBarChartComponent],
  exports: [AppActualPlanBarChartComponent]
})
export class AppActualPlanBarChartModule {}
