import { PrStatus } from '../../core/bean/pr-status';
import { User } from '../../core/bean/user';
import { WorkloadLevel } from '../../core/bean/workload-level';

export class WorkloadMonitoringCustomDataRequest {
  public workloadLevelList: WorkloadLevel[];
  public statusList: PrStatus[];
  public user: User;
  public moduleCode: string;
  public fromDate: string;
  public untilDate: string;
}
