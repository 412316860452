import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppCheckBoxModule } from '../../app-check-box/app-check-box.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupTOCComponent } from './app-popup-toc.component';
import { AppPopUpTOCService } from './app-popup-toc.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppCheckBoxModule],
  declarations: [AppPopupTOCComponent],
  entryComponents: [AppPopupTOCComponent],
  providers: [AppPopUpTOCService],
  exports: [AppPopupTOCComponent]
})
export class AppPopupTOCModule {}
