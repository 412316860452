import { BaseEntity } from '../base/base-entity';
import { Procurement } from '../bean/procurement';

export class ProcurementFilterEntity extends BaseEntity {
  procurement: Procurement = new Procurement();
  fromContractDate: Date;
  toContractDate: Date;
  contractNum: number;
  contractAmount: number;
  minRating: number;
}
