import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AppLoginFormModule } from './app-login-form.module';
import { LoginComponent } from './login.component';
export const routes = [{ path: '', component: LoginComponent, pathMatch: 'full' }];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), AppLoginFormModule],
  declarations: [LoginComponent]
})
export class LoginModule { }
