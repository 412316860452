import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupPICComponent } from './popup/app-popup-pic.component';
import { AppPopupVendorAddressAlertComponent } from './popup/app-popup-vendor-address-alert.component';
import { AppPopupVendorAddressComponent } from './popup/app-popup-vendor-address.component';
import { TabProfileCompanyDataComponent } from './tab-profile-company-data.component';

export const routes = [
  {
    path: '',
    component: TabProfileCompanyDataComponent,
    data: { breadcrumb: '' }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    TabProfileCompanyDataComponent,
    AppPopupVendorAddressAlertComponent,
    AppPopupPICComponent,
    AppPopupVendorAddressComponent
  ],
  entryComponents: [
    AppPopupVendorAddressAlertComponent,
    AppPopupPICComponent,
    AppPopupVendorAddressComponent
  ],
  exports: [
    TabProfileCompanyDataComponent,
    AppPopupVendorAddressAlertComponent,
    AppPopupPICComponent,
    AppPopupVendorAddressComponent
  ]
})
export class TabProfileCompanyDataModule {}
