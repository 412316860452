import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalPrcsXModule } from '../../core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorWhitelistDetailComponent } from './vendor-whitelist-detail.component';
import { VendorWhitelistEditAddComponent } from './vendor-whitelist-edit-add.component';
import { VendorWhitelistComponent } from './vendor-whitelist.component';
export const routes = [
  { path: '', component: VendorWhitelistComponent, data: { breadcrumb: '' } },
  {
    path: 'detail',
    component: VendorWhitelistDetailComponent,
    data: { breadcrumb: 'vendor-whitelist.breadcrumb.detail' }
  },
  {
    path: 'add',
    component: VendorWhitelistEditAddComponent,
    data: { breadcrumb: 'vendor-whitelist.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: VendorWhitelistEditAddComponent,
    data: { breadcrumb: 'vendor-whitelist.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit/:token',
    component: VendorWhitelistEditAddComponent,
    data: { breadcrumb: 'vendor-whitelist.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: VendorWhitelistDetailComponent,
    data: { breadcrumb: 'vendor-whitelist.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    AppApprovalPrcsXModule
  ],
  declarations: [
    VendorWhitelistComponent,
    VendorWhitelistDetailComponent,
    VendorWhitelistEditAddComponent
  ],
  providers: []
})
export class VendorWhitelistModule {}
