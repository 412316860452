<div class="row">
  <div class="col-lg-12 mb-4">
    <div class="text-center py-4" *ngIf="formLoading">
      <img src="assets/img/common/loader-small.gif" alt="Loading" />
    </div>
    <form
      class="form-horizontal"
      [formGroup]="tabCompanyData.formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <h5 class="separator">{{
        'tab-profile-company-data.form-group.basicInformation' | translate
      }}</h5>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.registrationNumber' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <p>{{ tabCompanyData.formGroup.get('registrationNumber').value }}</p>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.companyName' | translate
        }}</label>
        <div
          class="text-left"
          [ngClass]="!tabCompanyData.formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
        >
          <app-text-field
            [autofocus]="true"
            formControlName="name"
            maxLength="255"
            tooltip="{{
              'tab-profile-company-data.tooltip.companyName' | translate
            }}"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileCompanyDataDTO.vendor.name"
              [newValue]="tabProfileCompanyDataDTO.vendorHistory.name"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.abbreviation' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="MD"
            formControlName="abbreviation"
            maxLength="8"
            tooltip="{{
              'tab-profile-company-data.tooltip.abbreviation' | translate
            }}"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileCompanyDataDTO.vendor.abbreviation"
              [newValue]="tabProfileCompanyDataDTO.vendorHistory.abbreviation"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.trademarkName' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="MD"
            formControlName="trademarkName"
            maxLength="64"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileCompanyDataDTO.vendor.trademarkName"
              [newValue]="tabProfileCompanyDataDTO.vendorHistory.trademarkName"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.taxNumber' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-npwp
            formControlName="taxNumber"
            *ngIf="
              companyProfileDTO.vendorHistory.vendorType.code !==
              global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
            "
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileCompanyDataDTO.vendor.taxNumber"
              [newValue]="tabProfileCompanyDataDTO.vendorHistory.taxNumber"
            ></app-flag-new>
          </app-npwp>
          <app-text-field
            formControlName="taxNumber"
            maxLength="20"
            type="alphanumeric"
            *ngIf="
              companyProfileDTO.vendorHistory.vendorType.code ===
              global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
            "
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileCompanyDataDTO.vendor.taxNumber"
              [newValue]="tabProfileCompanyDataDTO.vendorHistory.taxNumber"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        *ngIf="
          !tabCompanyData.formGroup.isView &&
          companyProfileDTO.vendorHistory.vendorType.code !==
            global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
        "
      >
        <label class="col-sm-3 control-label"></label>
        <div class="col-sm-9 text-left">
          <app-radio
            size="MD"
            formControlName="isPkp"
            (onChange)="changePKP()"
            [optionList]="taxNumberTypeOptionList"
            position="HORIZONTAL"
          >
          </app-radio>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
        *ngIf="
          companyProfileDTO.vendorHistory.vendorType.code !==
          global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
        "
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.pkpNumber' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field size="MD" formControlName="pkpNumber" maxLength="64">
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileCompanyDataDTO.vendor.pkpNumber"
              [newValue]="tabProfileCompanyDataDTO.vendorHistory.pkpNumber"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.certificateOfEstablishmentNo'
            | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="MD"
            formControlName="certificateOfEstablishment"
            type="integer"
            maxLength="15"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileCompanyDataDTO.vendor.certificateOfEstablishment
              "
              [newValue]="
                tabProfileCompanyDataDTO.vendorHistory
                  .certificateOfEstablishment
              "
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.registeredOn' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <p>{{ tabProfileCompanyDataDTO.vendorHistory.organization.name }}</p>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.companyEmail' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="MD"
            formControlName="email"
            tooltip="{{ 'tab-profile-company-data.tooltip.email' | translate }}"
            maxLength="64"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileCompanyDataDTO.vendorHistory.vendor.email"
              [newValue]="tabProfileCompanyDataDTO.vendorHistory.email"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.phone' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-phone
            size="MD"
            formControlName="phone"
            [optionList]="countryRegionOptionList"
            maxLength="32"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="phoneNumber"
              [newValue]="phoneNumberHistory"
            ></app-flag-new>
          </app-phone>
        </div>
      </div>

      <h5 class="separator">{{
        'tab-profile-company-data.form-group.vendorType' | translate
      }}</h5>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.ownership' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            size="MD"
            [optionList]="vendorOwnershipOptionList"
            formControlName="vendorOwnership"
            tooltip="{{
              'tab-profile-company-data.tooltip.ownership' | translate
            }}"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileCompanyDataDTO.vendor.vendorOwnership
                  ? tabProfileCompanyDataDTO.vendor.vendorOwnership.name
                  : null
              "
              [newValue]="
                tabProfileCompanyDataDTO.vendorHistory.vendorOwnership
                  ? tabProfileCompanyDataDTO.vendorHistory.vendorOwnership.name
                  : null
              "
            ></app-flag-new>
          </app-combo-box>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.entity' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            size="MD"
            [optionList]="vendorEntityOptionList"
            formControlName="vendorEntity"
            tooltip="{{
              'tab-profile-company-data.tooltip.entity' | translate
            }}"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileCompanyDataDTO.vendor.vendorEntity
                  ? tabProfileCompanyDataDTO.vendor.vendorEntity.name
                  : null
              "
              [newValue]="
                tabProfileCompanyDataDTO.vendorHistory.vendorEntity
                  ? tabProfileCompanyDataDTO.vendorHistory.vendorEntity.name
                  : null
              "
            ></app-flag-new>
          </app-combo-box>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.location' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            size="MD"
            [optionList]="vendorLocationOptionList"
            formControlName="vendorLocation"
            tooltip="{{
              'tab-profile-company-data.tooltip.location' | translate
            }}"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileCompanyDataDTO.vendor.vendorLocation
                  ? tabProfileCompanyDataDTO.vendor.vendorLocation.name
                  : null
              "
              [newValue]="
                tabProfileCompanyDataDTO.vendorHistory.vendorLocation
                  ? tabProfileCompanyDataDTO.vendorHistory.vendorLocation.name
                  : null
              "
            ></app-flag-new>
          </app-combo-box>
        </div>
      </div>

      <h5 class="separator">{{
        'tab-profile-company-data.form-group.companyData' | translate
      }}</h5>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.qualification' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            size="MD"
            [optionList]="qualificationOptionList"
            formControlName="qualification"
            tooltip="{{
              'tab-profile-company-data.tooltip.qualification' | translate
            }}"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="
                tabProfileCompanyDataDTO.vendor.qualification
                  ? tabProfileCompanyDataDTO.vendor.qualification.name
                  : null
              "
              [newValue]="
                tabProfileCompanyDataDTO.vendorHistory.qualification
                  ? tabProfileCompanyDataDTO.vendorHistory.qualification.name
                  : null
              "
            ></app-flag-new>
          </app-combo-box>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.establishmentYear' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="SM"
            formControlName="establishmentYear"
            type="integer"
            maxLength="4"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileCompanyDataDTO.vendor.establishmentYear"
              [newValue]="
                tabProfileCompanyDataDTO.vendorHistory.establishmentYear
              "
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.employee' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="SM"
            formControlName="totalEmployee"
            type="integer"
            maxLength="6"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileCompanyDataDTO.vendor.totalEmployee"
              [newValue]="tabProfileCompanyDataDTO.vendorHistory.totalEmployee"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.website' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="MD"
            formControlName="website"
            maxLength="32"
            placeholder="{{
              'tab-profile-company-data.placeholder.website' | translate
            }}"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileCompanyDataDTO.vendor.website"
              [newValue]="tabProfileCompanyDataDTO.vendorHistory.website"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.productLocations' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-tree
            size="MD"
            formControlName="vendorProductLocationHistoryList"
            stringUrl="/region"
            [isMultiple]="true"
            header="{{
              'tab-profile-company-data.header.productLocations' | translate
            }}"
            isLazy="true"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileCompanyDataDTO.productLocationNames"
              [newValue]="tabProfileCompanyDataDTO.productLocationHistoryNames"
            ></app-flag-new>
          </app-text-tree>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.industry' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-chosen
            size="MD"
            formControlName="vendorIndustryHistoryList"
            [optionList]="industryOptionList"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileCompanyDataDTO.industryNames"
              [newValue]="tabProfileCompanyDataDTO.industryHistoryNames"
            ></app-flag-new>
          </app-chosen>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-company-data.form.keywords' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-tagging
            size="MD"
            formControlName="keyword"
            tooltip="{{
              'tab-profile-company-data.tooltip.keywords' | translate
            }}"
          >
            <app-flag-new
              *ngIf="
                roleType !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                tabCompanyData.formGroup.isView &&
                !isVendorList &&
                (this.fromOutside === '/pages/on-boarding' ||
                  this.fromOutside === '/pages/approval-prcs')
              "
              [oldValue]="tabProfileCompanyDataDTO.vendor.keyword"
              [newValue]="tabProfileCompanyDataDTO.vendorHistory.keyword"
            ></app-flag-new>
          </app-text-tagging>
        </div>
      </div>

      <div
        class="form-group row"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
      >
        <div class="col-lg-12">
          <h5 class="separator"
            >{{ 'tab-profile-company-data.form-group.address' | translate }}
            <span class="control-label"></span
          ></h5>
          <small
            class="text-danger"
            *ngIf="
              tabCompanyData.formGroup.value.isTableAddressEmpty &&
              tabCompanyData.formGroup.value.changeTab
            "
          >
            {{ 'app.validation.required' | translate }}
          </small>
          <app-table #selectorVendorAddress [model]="tableAddressResponse">
            <ng-template #headerButtons>
              <ng-container *ngIf="!isView">
                <app-button (onClick)="doAddVendorAddress()"
                  ><em class="fas fa-plus"></em
                  >{{ 'app.button.add' | translate }}</app-button
                >
              </ng-container>
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <ng-container *ngIf="!isView">
                <app-button
                  size="TN"
                  (onClick)="doEditVendorAddress(rowData)"
                  title="{{ 'app.tooltip.edit' | translate }}"
                >
                  <span class="fas fa-pencil-alt"></span>
                </app-button>
                <app-button
                  size="TN"
                  color="DANGER"
                  (onClick)="doDeleteVendorAddress(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <span class="fas fa-trash"></span>
                </app-button>
              </ng-container>
              <app-button
                *ngIf="isView"
                size="TN"
                (onClick)="doViewVendorAddress(rowData)"
                title="{{ 'app.tooltip.detail' | translate }}"
              >
                <span class="fas fa-search"></span>
              </app-button>
            </ng-template>
          </app-table>
        </div>
      </div>

      <div
        class="form-group row"
        *ngIf="!tabCompanyData.formGroup.isView"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
      >
        <div class="col-lg-12">
          <h5 class="separator"
            >{{ 'tab-profile-company-data.form-group.PIC' | translate }}
            <span class="control-label"></span
          ></h5>
          <small
            class="text-danger"
            *ngIf="
              tabCompanyData.formGroup.value.isTablePICEmpty &&
              tabCompanyData.formGroup.value.changeTab
            "
          >
            {{ 'app.validation.required' | translate }}
          </small>
          <app-table #selectorVendorPIC [model]="tablePICResponse">
            <ng-template #headerButtons>
              <ng-container *ngIf="!isView">
                <app-button (onClick)="doAddPIC()"
                  ><em class="fas fa-plus"></em
                  >{{ 'app.button.add' | translate }}</app-button
                >
              </ng-container>
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <ng-container *ngIf="!isView">
                <app-button
                  size="TN"
                  (onClick)="doEditPIC(rowData)"
                  title="{{ 'app.tooltip.edit' | translate }}"
                >
                  <span class="fas fa-pencil-alt"></span>
                </app-button>
                <app-button
                  size="TN"
                  color="DANGER"
                  (onClick)="doDeletePIC(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <span class="fas fa-trash"></span>
                </app-button>
              </ng-container>
            </ng-template>
          </app-table>
        </div>
      </div>

      <div
        class="form-group row"
        *ngIf="tabCompanyData.formGroup.isView"
        [ngClass]="!tabCompanyData.formGroup.isView ? 'required' : ''"
      >
        <div class="col-lg-12">
          <h5 class="separator"
            >{{ 'tab-profile-company-data.form-group.PIC' | translate }}
            <span class="control-label"></span
          ></h5>
          <small
            class="text-danger"
            *ngIf="
              tabCompanyData.formGroup.value.isTablePICEmpty &&
              tabCompanyData.formGroup.value.changeTab
            "
          >
            {{ 'app.validation.required' | translate }}
          </small>
          <app-table #selectorVendorPIC [model]="tablePICResponse"></app-table>
        </div>
      </div>
    </form>
  </div>
</div>
