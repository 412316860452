import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from '../../core/shared/shared.module';
import { TabProcurementHistoryComponent } from './tab-procurement-history.component';
@NgModule({
  imports: [SharedModule],
  declarations: [TabProcurementHistoryComponent],
  entryComponents: [TabProcurementHistoryComponent],
  exports: [TabProcurementHistoryComponent]
})
export class TabProcurementHistoryModule {
  constructor(accordionService: AccordionService) {
    accordionService.register(
      'tab-procurement-history',
      TabProcurementHistoryComponent
    );
  }
}
