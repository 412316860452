import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppItemRequestModule } from 'src/app/core/components/app-item-request/app-item-request.module';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppOfficialReportModule } from '../../core/components/app-official-report/app-official-report.module';
import { AppProcurementInfoModule } from '../../core/components/app-procurement-info/app-procurement-info.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorInvitationEditComponent } from './vendor-invitation-edit.component';
import { VendorInvitationComponent } from './vendor-invitation.component';

const routes = [
  { path: '', component: VendorInvitationComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: VendorInvitationEditComponent,
    data: { breadcrumb: 'vendor-invitation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: VendorInvitationEditComponent,
    data: { breadcrumb: 'vendor-invitation.breadcrumb.detail' }
  },
  {
    path: 'detail/:token',
    component: VendorInvitationEditComponent,
    data: { breadcrumb: 'vendor-invitation.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppProcurementInfoModule,
    AppItemRequestModule,
    AppApprovalPrcsXModule,
    AppBadgeCatalogModule,
    AppOfficialReportModule
  ],
  declarations: [VendorInvitationComponent, VendorInvitationEditComponent],
  entryComponents: [VendorInvitationComponent, VendorInvitationEditComponent],
  exports: [VendorInvitationComponent, VendorInvitationEditComponent]
})
export class VendorInvitationModule {}
