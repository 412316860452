import { BaseDTO } from '../../../core/base/base-dto';
import { BudgetAllocation } from '../../../core/bean/budget-allocation';
import { BudgetHistory } from '../../../core/bean/budget-history';
import { BudgetType } from '../../../core/bean/budget-type';
import { Coa } from '../../../core/bean/coa';
import { CostCenter } from '../../../core/bean/cost-center';
import { Currency } from '../../../core/bean/currency';
import { Module } from '../../../core/bean/module';
import { Organization } from '../../../core/bean/organization';
import { WorkProgram } from '../../../core/bean/work-program';
export class BudgetAllocationDTO extends BaseDTO {
  budgetAllocation: BudgetAllocation = new BudgetAllocation();
  workProgramList: WorkProgram[] = [];
  costCenterList: CostCenter[] = [];
  currencyList: Currency[] = [];
  budgetTypeList: BudgetType[];
  coaList: Coa[] = [];
  budgetAllocationList: BudgetAllocation[] = [];
  organizationList: Organization[];
  budgetHistory: BudgetHistory = new BudgetHistory();
  budgetHistoryList: BudgetHistory[] = [];
  totalBudgetResume: number;
  bookedBudgetResume: number;
  usedBudgetResume: number;
  paidOffBudgetResume: number;
  availableBudgetResume: number;
  module: Module;
}
