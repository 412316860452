<app-popup (onClose)="doCancel()" [isShowHeaderCloseButton]="!isLoadingSync && !isSyncButtonClicked" header="budget-allocation-bjb.popup.sync.title" >
  <div class="row main-row-popup">
    <div class="col-12 mb-4">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-5 control-label">{{
        'budget-allocation-bjb.form.selectFilter' | translate
      }}</label>
      <div class="col-sm-7 text-left">
        <app-radio
          formControlName="radioSelect"
          [optionList]="selectOptionList"
        >
        </app-radio>
      </div>
    </div>

    <ng-container
      *ngIf="(formGroup.get('radioSelect').valueChanges | async) === 'Filter'"
    >
      <div
        class="form-group row text-sm-right"
      >
        <label class="col-sm-5 control-label">{{
          'budget-allocation-bjb.form.year' | translate
        }}</label>
        <div class="col-sm-7 text-left">
          <app-combo-box
            [optionList]="yearOptionList"
            formControlName="year"
          ></app-combo-box>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
      >
        <label class="col-sm-5 control-label">{{
          'budget-allocation-bjb.form.organization' | translate
        }}</label>
        <div class="col-sm-7 text-left">
          <app-text-tree
            formControlName="workgroup"
            stringUrl="/organization"
            isLazy="true"
            header="Organization"
          >
          </app-text-tree>
        </div>
      </div>
    </ng-container>
  </form>

<div class="row" *ngIf="isLoadingSync">
    <div class="col-sm-12 text-center">{{
        'budget-allocation-bjb.sync.pleaseWait' | translate
      }}
    </div>
</div>
<div class="row" *ngIf="isLoadingSync">
  <div class="col-sm-12 text-center">{{
      'budget-allocation-bjb.sync.gettingData' | translate
    }}
  </div>
</div>
<div class="row" *ngIf="isLoadingSync && webSocketResponseModel.progressUnit === 'kb'">
  <div class="col-sm-12 text-center">
      {{ webSocketResponseModel.progressValue + webSocketResponseModel.progressUnit }}
  </div>
</div>
<div class="row" *ngIf="isSyncButtonClicked && !webSocketResponseModel">
  <div class="col-sm-12 text-center">
    <img src="assets/img/common/loader-small.gif" alt="Loading" height="8" width="30" />
  </div>
</div>

<div class="w-50 mx-auto">
  <ngb-progressbar
  type="success"
  textType="white"
  [value]="webSocketResponseModel.progressValue"
  [showValue]="true"
  *ngIf="isLoadingSync && webSocketResponseModel.progressValue && webSocketResponseModel.progressUnit !== 'kb'"
  >
  </ngb-progressbar>
</div>

  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center">
      <app-button mode="BYPASS" color="SECONDARY" (onClick)="activeModal.close(true)" *ngIf="!isLoadingSync"
      [disabled]="formSaving || isLoadingSync || isSyncButtonClicked">{{
        'app.button.close' | translate
      }}</app-button>
      <app-button
        mode="BYPASS"
        color="PRIMARY"
        (onClick)="doSyncronize()"
        [disabled]="formSaving || isLoadingSync || isSyncButtonClicked"
        *ngIf="!isLoadingSync">{{ 'budget-allocation-bjb.popup.button.sync' | translate }}
      </app-button>
      <app-button mode="BYPASS" color="SECONDARY" (onClick)="doCancel()" *ngIf="isLoadingSync && isUser">{{
        'app.button.cancel' | translate
      }}</app-button>
    </div>
  </ng-template>
  </div>
  </div>
</app-popup>
