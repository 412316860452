import { NgModule } from '@angular/core';
import { AppFieldsetModule } from 'src/app/core/components/app-fieldset/app-fieldset.module';
import { AppTableModule } from 'src/app/core/components/app-table/app-table.module';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppPopupBillingMemoBudgetDetailService } from '../app-popup/app-popup-billing-memo-budget-detail/app-popup-billing-memo-budget-detail.service';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppPopupModule } from '../app-popup/app-popup.module';
import { AppBillingMemoInformationViewComponent } from './app-billing-memo-information-view.component';
import { AppPopupBillingMemoInfoViewBudgetDetailComponent } from './app-popup-billing-memo-info-view-budget-detail.component';

@NgModule({
  imports: [VendorModule, AppTableModule, AppFieldsetModule, AppButtonModule, AppPopupModule],
  declarations: [AppBillingMemoInformationViewComponent, AppPopupBillingMemoInfoViewBudgetDetailComponent],
  exports: [AppBillingMemoInformationViewComponent, AppPopupBillingMemoInfoViewBudgetDetailComponent],
  entryComponents: [AppPopupBillingMemoInfoViewBudgetDetailComponent],
  providers: [AppPopupBillingMemoBudgetDetailService]
})
export class AppBillingMemoInformationViewModule {}
