import {
  AfterViewInit,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { ActiveDropDown } from './active-drop-down.service';
import { AppDropDownComponent } from './app-drop-down.component';

declare let ResizeObserver: any;

@Directive({
  selector: '[appDropDown]'
})
export class AppDropDownDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input() appDropDown: TemplateRef<any>;
  @Input() isFixed: boolean;
  @Input() targetSelector: string;
  @Input() isShowArrow: boolean;
  @Input() disabled: boolean;
  @Input() position: 'BOTTOM-LEFT' | 'BOTTOM-RIGHT';
  @Input()
  disableOnClick: boolean; /** dropdown will not be closed on click input */
  @Input() className: string;

  @Output() onClick: EventEmitter<boolean> = new EventEmitter();
  private isActive: boolean;
  private zIndex: number;
  private targetElement: Element;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef,
    private activeDropDown: ActiveDropDown
  ) {}

  ngOnInit(): void {
    this.setInitializationZIndex();
    this.setDropDownClassName();
    this.onClosedListener();
    this.listenElementResize();
  }

  ngAfterViewInit(): void {
    const element = this.getScrollElement(this.elementRef.nativeElement);
    let timeout: number;
    (element || window).addEventListener('scroll', () => {
      if (this.isActive) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this.setDropDownPosition();
        });
      }
    });
  }

  public getScrollElement(element: HTMLElement): HTMLElement {
    if (element) {
      if (element.offsetHeight < element.scrollHeight) {
        return element;
      } else {
        return this.getScrollElement(element.parentElement);
      }
    } else {
      return null;
    }
  }

  private setInitializationZIndex(): void {
    this.zIndex = 0;
    const htmlCollection: HTMLCollection = document.body.children;
    for (const i in htmlCollection) {
      const htmlElement: any = htmlCollection.item(+i);
      if (htmlElement.style) {
        if (
          htmlElement.style.zIndex &&
          htmlElement.style.zIndex > this.zIndex
        ) {
          this.zIndex = htmlElement.style.zIndex;
        }
      }
    }

    this.setIndexWithParentElementIndex(this.elementRef.nativeElement);

    if (this.disabled) {
      this.elementRef.nativeElement.classList.add('cursor-default');
    }
  }

  private setDropDownClassName(): void {
    this.elementRef.nativeElement.classList.add('drop-down');
    if (this.className) {
      this.elementRef.nativeElement.classList.add(this.className.split(' '));
    }
  }

  private setIndexWithParentElementIndex(element: HTMLElement): void {
    if (element && element.tagName !== 'body') {
      if (element.style.zIndex && +element.style.zIndex > this.zIndex) {
        this.zIndex = +element.style.zIndex;
      }
      if (element.parentElement) {
        this.setIndexWithParentElementIndex(element.parentElement);
      }
    }
  }

  private onClosedListener(): void {
    this.activeDropDown.onClosed.subscribe(() => {
      this.isActive = false;
      if (this.targetElement) {
        this.targetElement.classList.remove('drop-down-show');
      }
    });
  }

  private listenElementResize(): void {
    new ResizeObserver(() => {
      if (this.isActive) {
        this.setDropDownPosition();
      }
    }).observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.activeDropDown.close();
  }

  @HostListener('click', ['$event'])
  onHostlistenerClick(event: MouseEvent): void {
    if (!this.disabled) {
      event.preventDefault();
      event.stopPropagation();

      if ((!this.isActive && this.disableOnClick) || !this.disableOnClick) {
        this.onClick.emit(this.isActive);
        this.isActive ? this.closeDropDown() : this.createDropDown();
      }
    }
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    if (this.isActive) {
      this.setDropDownPosition();
    }
  }

  public createDropDown(): void {
    this.activeDropDown.close();
    this.isActive = true;
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        AppDropDownComponent
      );
    this.activeDropDown.componentRef =
      this.viewContainerRef.createComponent(componentFactory);

    const appDropDownElement =
      this.activeDropDown.componentRef.location.nativeElement;

    this.activeDropDown.componentRef.instance.isShowArrow = this.isShowArrow;
    this.activeDropDown.componentRef.instance.dropDownContent =
      this.appDropDown;

    this.activeDropDown.componentRef.instance.parentElementWidth =
      this.elementRef.nativeElement.scrollWidth;

    this.setInitializationDropDown();
    this.setDropDownPosition();

    document.body.appendChild(appDropDownElement);
  }

  private closeDropDown(): void {
    this.activeDropDown.close();
    this.isActive = false;
    if (this.targetElement) {
      this.targetElement.classList.remove('drop-down-show');
    }
  }

  private setInitializationDropDown(): void {
    if (this.isActive) {
      const appDropDownElement =
        this.activeDropDown.componentRef.location.nativeElement;

      appDropDownElement.classList.add('app-drop-down', 'app-drop-down-body');
      appDropDownElement.style.minWidth =
        this.elementRef.nativeElement.scrollWidth + 'px';

      if (this.isFixed) {
        appDropDownElement.classList.add('fixed');
      }

      if (this.zIndex > appDropDownElement.style.zIndex) {
        appDropDownElement.style.zIndex = this.zIndex;
      }
    }
  }

  private setDropDownPosition(): void {
    if (this.isActive) {
      if (!this.targetElement) {
        this.targetElement = this.targetSelector
          ? document.getElementById(this.targetSelector)
          : this.elementRef.nativeElement;
      }

      if (this.targetElement) {
        const appDropDownElement =
          this.activeDropDown.componentRef.location.nativeElement;

        this.targetElement.classList.add('drop-down-show');

        const boundingClientRect = this.targetElement.getBoundingClientRect();
        let offsetLeft =
          boundingClientRect.left + (this.isFixed ? 0 : window.scrollX);
        const offsetTop =
          boundingClientRect.top + (this.isFixed ? 0 : window.scrollY);

        if (this.position === 'BOTTOM-RIGHT') {
          setTimeout(() => {
            offsetLeft =
              offsetLeft +
              this.targetElement.scrollWidth -
              appDropDownElement.children
                .item(0)
                .children.item(0)
                .children.item(0).scrollWidth;
            this.setAppDropDownTransform(
              appDropDownElement,
              offsetLeft,
              offsetTop,
              boundingClientRect
            );
          });
        } else {
          this.setAppDropDownTransform(
            appDropDownElement,
            offsetLeft,
            offsetTop,
            boundingClientRect
          );
        }
      }
    }
  }

  private setAppDropDownTransform(
    appDropDownElement,
    offsetLeft,
    offsetTop,
    boundingClientRect
  ): void {
    appDropDownElement.style.transform = `translate(${
      this.isShowArrow
        ? offsetLeft - this.elementRef.nativeElement.scrollWidth / 2
        : offsetLeft
    }px, ${offsetTop + boundingClientRect.height + 2}px)`;
  }
}
