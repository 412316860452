<app-popup>
  <ng-container *ngIf="formLoading; then loadingTmpl; else imgTmpl"></ng-container>
  <ng-template #loadingTmpl>
    <div class="loading-preview-image">
      <img src="assets/img/common/loader-small.gif" alt="Loading" height="11" width="40" />
      <span class="loading-text">{{ 'app.loading ' | translate }}</span>
    </div>
  </ng-template>
  <ng-template #imgTmpl>
    <em class="fas fa-times" (click)="ngbActiveModal.close()" (keyUp)="onKeyUp($event)"></em>
  </ng-template>
  <img [src]="imageUrl" (load)="onLoad()" (error)="onError($event)">
</app-popup>