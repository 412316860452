<app-popup header="vendor-blacklist.popup.title">
  <div class="main-row-popup-blacklist-criteria">
    <app-table
      [tableResponse]="tableResponse"
      [isServerSide]="true"
      stringUrl="/vendor-blacklist/app-popup-blacklist-criteria"
      customData="{{ blacklistCriteriaIdCustomData }}"
      (onChange)="onChangeTableRecords($event)"
    >
    </app-table>
    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
        <app-button color="PRIMARY" (onClick)="doSubmit()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </div>
</app-popup>
