import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Currency } from 'src/app/core/bean/currency';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { ProcurementItem } from '../../../bean/procurement-item';
import { QuotationItemReference } from '../../../bean/quotation-item-reference';
import { ResponseStatusModel } from '../../../model/response-status-model';
import { Validators } from '../../../validators';
import { PopupChooseVendorMultiWinnerView } from '../../../view/entity/bean/popup-choose-vendor-multi-winner-view';
import { TableColumn } from '../../table/domain/table-column';
import { TableRow } from '../../table/domain/table-row';
import { CountPlugin } from '../../table/interface/available-table-plugin';
import { TablePluginData } from '../../table/interface/table-plugin-data';
import { TableResponseModel } from '../../table/model/table-response-model';
import { AppPopupChooseVendorMultiWinnerResponse } from './app-popup-choose-vendor-multi-winner-response';

@Component({
  templateUrl: './app-popup-choose-vendor-multi-winner.component.html'
})
export class AppPopupChooseVendorMultiWinnerComponent extends BaseModuleComponent {
  @Input()
  popupChooseVendorMultiWinnerViewList: PopupChooseVendorMultiWinnerView[] = [];
  @Input() procurementItemId: number;
  @Input() todo: string;
  @Input() isShowChangeWinningVendor: boolean;
  @Input() isRefute: boolean;

  @Output() onChange = new EventEmitter();

  public isView = false;
  public isThingsToConsider = false;
  public reminingQuantity: number;
  public procurementItem: ProcurementItem;
  public currencyList: Currency[];
  public tableResponse: TableResponseModel<PopupChooseVendorMultiWinnerView>;
  public CURRENCY_DIGITS_INFO: string;
  public isMustFullFill = false;
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );
  public isHasRemainingQuantity = false;

  constructor(
    translateService: TranslateService,
    public currencyPipe: CurrencyPipe
  ) {
    super('app-popup-choose-vendor-multi-winner', translateService);
  }

  public onInit(): void {
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
    this.buildTableResponse();
    this.buildFormGroup();
    this.setIsView();
    this.getListVendorForTable();
  }

  public setIsView(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
      this.isView = true;
      const plugins = this.tableResponse.columnList[3].plugins as CountPlugin;
      plugins.isView = true;
      this.tableResponse.columnList[3].plugins = plugins;
    }
  }
  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      recordList: this.tableResponse.formArray
    });
  }

  public getListVendorForTable(): void {
    this.httpClientService
      .get<PopupVendorView>(
        '/app-popup-choose-vendor-multi-winner/index/' + this.procurementItemId
      )
      .subscribe((response: AppPopupChooseVendorMultiWinnerResponse) => {
        this.reminingQuantity = response.procurementItem.prItem.quantity;
        this.procurementItem = response.procurementItem;
        this.currencyList = response.currencyList;
        this.currencyOptionList.setRequestValues(response.currencyList);
        this.popupChooseVendorMultiWinnerViewList =
          response.popupChooseVendorMultiWinnerViewList;
        this.tableResponse.setRecordList(
          this.popupChooseVendorMultiWinnerViewList
        );
        this.tableResponse.getRecordList().forEach((element: any) => {
          if (!element.quotationItemReference) {
            element.quotationItemReference = new QuotationItemReference();
            element.quotationItemReference.quotationItem =
              element.quotationItem;
          }
          if (
            element.quotationItemReference?.totalPrice &&
            typeof element.quotationItemReference?.totalPrice === 'number'
          ) {
            const totalBidPrice = {
              price: element.quotationItemReference?.totalPrice,
              currency: this.currencyList[0]
            };
            element.quotationItemReference.totalPrice = totalBidPrice;
          }
          this.reminingQuantity -= element.quotationItemReference?.quantity
            ? element.quotationItemReference?.quantity
            : 0;
        });
        this.popupChooseVendorMultiWinnerViewList =
          this.tableResponse.getRecordList();
        const quotationItemRef = this.tableResponse
          .getRecordList()
          .filter(
            x =>
              x.quotationItemReference &&
              x.quotationItemReference.quantity &&
              (x.quotationItemReference.quantity !== null ||
                x.quotationItemReference.quantity > 0)
          );
        this.tableResponse.setRecordList(quotationItemRef);
        this.checkValidity();
        this.tableResponse.reload();
        this.setStateReady();
      });
  }

  public checkValidity(): void {
    const plugins = this.tableResponse.columnList[3].plugins as CountPlugin;
    if (this.reminingQuantity <= 0) {
      plugins.max = 0;
      if (this.tableResponse.formArray.value) {
        this.tableResponse.formArray.value.forEach((element, index) => {
          this.tableResponse.formArray.controls[index]
            .get('quotationItemReferenceQuantity')
            .setValidators(
              Validators.compose([
                Validators.required(),
                Validators.max(element.quotationItemReference?.quantity)
              ])
            );
        });
      }
    } else {
      plugins.max = +this.procurementItem.prItem.quantity;
    }
    this.tableResponse.columnList[3].plugins = plugins;
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'quotationItem.quotation.procurementVendor.vendor.name',
        header: 'table.column.vendorName',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'icon',
            className: 'pic pi-exclamation-triangle',
            onClick: this.onClickIconConsider.bind(this),
            isShowIconFn: this.showIcon.bind(this)
          },
          {
            name: 'icon',
            className: 'pis pi-exclamation-triangle text-danger',
            onClick: this.onClickIconDanger.bind(this),
            isShowIconFn: this.showIconDanger.bind(this)
          }
        ]
      },
      {
        field: 'quotationItem.finalPrice',
        header: 'table.column.finalPrice',
        className: 'text-right',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      },
      {
        field: 'quotationItem.finalProfitLossAmount',
        header: 'table.column.saveLoss',
        className: 'd-flex justify-content-end',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): void => {
              if (tablePlugin.value) {
                tablePlugin.element.innerText =
                  this.currencyPipe.transform(
                    +tablePlugin.value,
                    '',
                    '',
                    this.CURRENCY_DIGITS_INFO
                  ) + ' %';
              }
            }
          },
          {
            name: 'badge',
            field: 'quotationItem.finalProfitLossStatus.name',
            colorMap: {
              SAVE: 'SUCCESS',
              LOSS: 'DANGER'
            },
            pill: false,
            colorField: 'quotationItem.finalProfitLossStatus.code',
            className: 'badge-catalog ml-3'
          }
        ]
      },
      {
        field: 'quotationItemReference.quantity',
        header: 'table.column.qty',
        className: 'text-center',
        plugins: {
          name: 'count',
          min: 0,
          onChange: this.onChangeCount.bind(this),
          onInput: this.onChangeCount.bind(this)
        }
      },
      {
        field: 'quotationItemReference.totalPrice',
        header: 'table.column.totalPrice',
        className: 'text-right',
        plugins: [
          {
            name: 'currency',
            optionList: this.currencyOptionList,
            isView: true
          }
        ]
      }
    ]);
    // }
  }

  public onClickIconConsider(
    popupChooseVendorMultiWinnerView: PopupChooseVendorMultiWinnerView
  ): void {
    if (
      !popupChooseVendorMultiWinnerView.isExpiredDoc &&
      !popupChooseVendorMultiWinnerView.isExpiredCertificate &&
      (popupChooseVendorMultiWinnerView.quotationItem.quotation
        .procurementVendor.vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE ||
        popupChooseVendorMultiWinnerView.quotationItem.quotation
          .procurementVendor.vendor.vendorStatus.code ===
          this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) &&
      !popupChooseVendorMultiWinnerView.isCatalogContract
    ) {
      let msg = '<ol class="text-left ml-4">';
      if (popupChooseVendorMultiWinnerView.sameCompanyOrgVendorName) {
        let vendorNameList: string[] =
          popupChooseVendorMultiWinnerView.sameCompanyOrgVendorName.split(',');
        vendorNameList = [...new Set(vendorNameList)];

        msg +=
          '<li>' +
          popupChooseVendorMultiWinnerView.quotationItem.quotation
            .procurementVendor.vendor.name +
          ' ' +
          this.global.translateService.instant(
            'app-popup-choose-vendor-multi-winner.popup.warning.msg.sameCompanyOrg'
          ) +
          ' ' +
          vendorNameList.toString().replace('[', '').replace(']', '') +
          '</li>';
      }
      if (!popupChooseVendorMultiWinnerView.isFinancialReport) {
        msg +=
          '<li>' +
          popupChooseVendorMultiWinnerView.quotationItem.quotation
            .procurementVendor.vendor.name +
          ' ' +
          this.global.translateService.instant(
            'app-popup-choose-vendor-multi-winner.popup.warning.msg.isFinancialReport'
          ) +
          '</li>';
      }
      if (popupChooseVendorMultiWinnerView.isBlacklist) {
        msg +=
          '<li>' +
          popupChooseVendorMultiWinnerView.quotationItem.quotation
            .procurementVendor.vendor.name +
          ' ' +
          this.global.translateService.instant(
            'app-popup-choose-vendor-multi-winner.popup.warning.msg.isBlacklist'
          ) +
          '</li>';
      }
      if (popupChooseVendorMultiWinnerView.isCertificateWillBeExpired) {
        msg +=
          '<li>' +
          this.global.translateService.instant(
            'app-popup-choose-vendor-multi-winner.popup.warning.msg.certificate'
          ) +
          ' ' +
          popupChooseVendorMultiWinnerView.quotationItem.quotation
            .procurementVendor.vendor.name +
          ' ' +
          this.global.translateService.instant(
            'app-popup-choose-vendor-multi-winner.popup.warning.msg.isCertificateWillBeExpired'
          ) +
          '</li>';
      }

      if (popupChooseVendorMultiWinnerView.isDer) {
        msg +=
          '<li>' +
          this.global.translateService.instant(
            'app-popup-choose-vendor-multi-winner.popup.warning.msg.derRatio'
          ) +
          ' ' +
          popupChooseVendorMultiWinnerView.quotationItem.quotation
            .procurementVendor.vendor.name +
          ' ' +
          this.global.translateService.instant(
            'app-popup-choose-vendor-multi-winner.popup.warning.msg.isDer'
          ) +
          '</li>';
      }
      const title =
        'app-popup-choose-vendor-multi-winner.popup.warning.thingsToConsider.title';
      this.doShowPopup(msg, title);
    }
  }

  public showIcon(
    popupChooseVendorMultiWinnerView: PopupChooseVendorMultiWinnerView
  ): boolean {
    if (
      !popupChooseVendorMultiWinnerView.isExpiredCertificate &&
      !popupChooseVendorMultiWinnerView.isExpiredDoc &&
      !popupChooseVendorMultiWinnerView.isCatalogContract &&
      (popupChooseVendorMultiWinnerView.quotationItem.quotation
        .procurementVendor.vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE ||
        popupChooseVendorMultiWinnerView.quotationItem.quotation
          .procurementVendor.vendor.vendorStatus.code ===
          this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) &&
      (popupChooseVendorMultiWinnerView.sameCompanyOrgVendorName ||
        !popupChooseVendorMultiWinnerView.isFinancialReport ||
        popupChooseVendorMultiWinnerView.isBlacklist ||
        popupChooseVendorMultiWinnerView.isCertificateWillBeExpired ||
        popupChooseVendorMultiWinnerView.isDer)
    ) {
      this.isThingsToConsider = true;
      return true;
    } else {
      return false;
    }
  }

  public showIconDanger(
    popupChooseVendorMultiWinnerView: PopupChooseVendorMultiWinnerView
  ): boolean {
    if (
      popupChooseVendorMultiWinnerView.isExpiredCertificate ||
      popupChooseVendorMultiWinnerView.isExpiredDoc ||
      (popupChooseVendorMultiWinnerView.quotationItem.quotation
        .procurementVendor.vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
        popupChooseVendorMultiWinnerView.quotationItem.quotation
          .procurementVendor.vendor.vendorStatus.code !==
          this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST) ||
      popupChooseVendorMultiWinnerView.isCatalogContract
    ) {
      this.isMustFullFill = true;
      return true;
    } else {
      return false;
    }
  }

  public onClickIconDanger(
    popupChooseVendorMultiWinnerView: PopupChooseVendorMultiWinnerView
  ): void {
    let msg = '';
    if (
      popupChooseVendorMultiWinnerView.quotationItem.quotation.procurementVendor
        .vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE &&
      popupChooseVendorMultiWinnerView.quotationItem.quotation.procurementVendor
        .vendor.vendorStatus.code !==
        this.global.appConstant.vm.VENDOR_STATUS_BLACKLIST
    ) {
      msg +=
        '<li>' +
        popupChooseVendorMultiWinnerView.quotationItem.quotation
          .procurementVendor.vendor.name +
        ' ' +
        this.global.translateService.instant(
          'app-popup-choose-vendor-multi-winner.popup.warning.msg.isNotActive'
        ) +
        '</li>';
    }

    if (popupChooseVendorMultiWinnerView.isExpiredCertificate) {
      msg +=
        '<li>' +
        this.global.translateService.instant(
          'app-popup-choose-vendor-multi-winner.popup.warning.msg.certificate'
        ) +
        ' ' +
        popupChooseVendorMultiWinnerView.quotationItem.quotation
          .procurementVendor.vendor.name +
        ' ' +
        this.global.translateService.instant(
          'app-popup-choose-vendor-multi-winner.popup.warning.msg.isExpiredCertificate'
        ) +
        '</li>';
    }

    if (popupChooseVendorMultiWinnerView.isExpiredDoc) {
      msg +=
        '<li>' +
        popupChooseVendorMultiWinnerView.quotationItem.quotation
          .procurementVendor.vendor.name +
        ' ' +
        this.global.translateService.instant(
          'app-popup-choose-vendor-multi-winner.popup.warning.msg.isExpiredDoc'
        ) +
        '</li>';
    }

    if (popupChooseVendorMultiWinnerView.isCatalogContract) {
      msg +=
        '<li>' +
        popupChooseVendorMultiWinnerView.quotationItem.quotation
          .procurementVendor.vendor.name +
        ' ' +
        this.global.translateService.instant(
          'app-popup-choose-vendor-multi-winner.popup.warning.msg.isCatalogContract'
        ) +
        ' ' +
        popupChooseVendorMultiWinnerView.catalogName +
        '</li>';
    }
    const title =
      'app-popup-choose-vendor-multi-winner.popup.warning.mustFullFill.title';
    this.doShowPopup(msg, title);
  }

  public doShowPopup(msg: string, title: string) {
    this.global.modalService.confirmation(
      msg,
      title,
      null,
      null,
      'pvc pv-exclamation-triangle',
      true
    );
  }

  public onChangeCount(count: number): void {
    const currentRowChange = this.tableResponse.currentRowChange;
    const tableColumnPrice: TableColumn = currentRowChange.row.columnList[1];
    const tableColumnTotalPrice: TableColumn =
      currentRowChange.row.columnList[4];
    // const objTotalBidPrice = count * +tableColumnPrice.value;
    const objTotalBidPrice = {
      price: count * +tableColumnPrice.value,
      currency: this.currencyList[0]
    };
    tableColumnTotalPrice?.changeValue(objTotalBidPrice);
    tableColumnTotalPrice?.reload();

    this.tableResponse.getRecordList().forEach((element: any) => {
      if (
        element.quotationItem.quotation.id ===
        currentRowChange.row.record.quotationItem.quotation.id
      ) {
        element.quotationItemReference.quantity = count;
        const objTotalPrice = {
          price:
            element.quotationItem.finalPrice *
            element.quotationItemReference.quantity,
          currency: this.currencyList[0]
        };
        element.quotationItemReference.totalPrice = objTotalPrice;
      }
    });

    let totalQuantity = 0;
    this.tableResponse.getRecordList().forEach(element => {
      totalQuantity += +element.quotationItemReference?.quantity || 0;
    });
    this.reminingQuantity =
      this.procurementItem.prItem.quantity - totalQuantity || 0;
    this.checkValidity();
    if (this.isHasRemainingQuantity && this.reminingQuantity === 0) {
      this.isHasRemainingQuantity = false;
    }
  }

  public doChangeWinningVendor(): void {
    this.isView = false;
    this.todo = 'edit';
    this.tableResponse.setRecordList(this.popupChooseVendorMultiWinnerViewList);
    const plugins = this.tableResponse.columnList[3].plugins as CountPlugin;
    plugins.isView = false;
    this.tableResponse.columnList[3].plugins = plugins;
    this.tableResponse.reload();
  }

  public doEmitData(): void {
    if (this.reminingQuantity === 0) {
      this.isHasRemainingQuantity = false;
      this.tableResponse.getRecordList().forEach((element: any) => {
        if (element.quotationItemReference?.totalPrice?.price > 0) {
          element.quotationItemReference.totalPrice =
            element.quotationItemReference?.totalPrice.price;
        } else {
          element.quotationItemReference.totalPrice = 0;
        }
        if (
          element.quotationItemReference?.quantity &&
          element.quotationItemReference?.quantity > 0
        ) {
          element.quotationItem.quotation.isReference = true;
        } else {
          element.quotationItem.quotation.isReference = false;
        }
      });
      this.setStateProcessing();
      this.httpClientService
        .post(
          '/app-popup-choose-vendor-multi-winner/update',
          this.tableResponse.getRecordList()
        )
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.onChange.emit({
              quotationItemReferenceList: this.tableResponse.getRecordList(),
              quotationItemReferenceFinalList: response.body
            });
            this.setStateReady();
          }
        });
    } else {
      this.isHasRemainingQuantity = true;
    }
  }

  public onAfterRowCreated(tableRow: TableRow): void {
    if (this.isView || this.showIconDanger(tableRow.record)) {
      tableRow.formGroup.get('quotationItemReferenceQuantity').setIsView(true);
    } else {
      tableRow.formGroup.get('quotationItemReferenceQuantity').setIsView(false);
    }
  }
}
