import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { ItemType } from 'src/app/core/bean/item-type';
import { Procurement } from 'src/app/core/bean/procurement';
import { QuotationItem } from 'src/app/core/bean/quotation-item';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { PopupBidPriceResponse } from './popup-bid-price.response';

@Component({
  templateUrl: './app-popup-bid-price.component.html'
})
export class AppPopupBidPriceComponent extends BaseComponentComponent {
  @Input() public quotationId: number;
  @Input() public vendorName: number;

  public popupBidPriceResponse: PopupBidPriceResponse;
  public procurement: Procurement;
  public fileUploaderBidBond: FileUploader;
  public fileUploaderAdm: FileUploader;
  public header: string;

  public tableResponseQuotationItem: TableResponseModel<QuotationItem>;
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    false
  );

  public constructor(public translateService: TranslateService) {
    super('price-evaluation');
  }
  public onInit(): void {
    this.header =
      this.translateService.instant('price-evaluation.popup.bidPriceDetail') +
      ' ' +
      this.vendorName;
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<PopupBidPriceResponse>(
        '/price-evaluation/popup-bid-price/' + this.quotationId
      )
      .subscribe((response: PopupBidPriceResponse) => {
        this.popupBidPriceResponse = response;
        this.procurement = response.quotation.procurementVendor.procurement;
        this.buildTableResponse();
        this.itemTypeOptionList.setRequestValues(response.itemTypeList);
        this.tableResponseQuotationItem.setRecordList(
          response.quotationItemList
        );
        this.fileUploaderAdm = new FileUploader(
          '/price-evaluation/',
          '',
          response.quotationAdministration.docFile.fileType.code
        );
        this.fileUploaderAdm.setFile(response.quotationAdministration.docFile);
        this.formGroup.patchValue({
          documentAdministration: this.fileUploaderAdm.fileObjectList,
          validityPeriodAdm: {
            from: response.quotationAdministration.startDate,
            to: response.quotationAdministration.endDate
          }
        });
        this.formGroup.get('documentAdministration').setIsView(true);
        this.formGroup.get('validityPeriodAdm').setIsView(true);

        this.fileUploaderBidBond = new FileUploader(
          '/price-evaluation/',
          '',
          response.quotationBidBond.docFile.fileType.code
        );
        this.fileUploaderBidBond.setFile(response.quotationBidBond.docFile);
        this.formGroup.patchValue({
          documentBidBond: this.fileUploaderBidBond.fileObjectList,
          validityPeriodBidBond: {
            from: response.quotationBidBond.startDate,
            to: response.quotationBidBond.endDate
          }
        });
        this.formGroup.get('documentBidBond').setIsView(true);
        this.formGroup.get('validityPeriodBidBond').setIsView(true);
        this.setStateReady();
      });
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      documentBidBond: [null],
      documentAdministration: [null],
      validityPeriodAdm: [null],
      validityPeriodBidBond: [null],
      itemType: [null],
      amount: [null],
      goodsAmount: [null],
      serviceAmount: [null],
      amountPotensial: [null],
      goodsAmountPotensial: [null],
      serviceAmountPotensial: [null],
      amountPotensialStatus: [null],
      goodsAmountPotensialStatus: [null],
      serviceAmountPotensialStatus: [null]
    });
  }

  public buildTableResponse(): void {
    if (
      this.procurement.biddingSubmissionType.code ===
      this.global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
    ) {
      this.buildTableResponseMultiWinner();
    } else {
      this.buildTableResponseSingleWinner();
    }
  }

  public buildTableResponseSingleWinner(): void {
    this.tableResponseQuotationItem = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementItem.prItem.prItemImageList',
        header: 'table.column.thumbnail',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePluginData: TablePluginData): File => {
              if (tablePluginData.value && tablePluginData.value.length > 0) {
                return tablePluginData.value[0].file;
              }
              return null;
            }
          },
          {
            name: 'img',
            classNameMap: {
              MATERIAL: 'pvr pv-box',
              JASA: 'pvr pv-tools'
            },
            fieldClassName: 'procurementItem.prItem.item.itemType.code'
          }
        ],
        isSortable: false,
        isSearchTable: false,
        className: 'text-center'
      },
      {
        field: 'procurementItem.prItem.item.name',
        header: 'table.column.itemName'
      },
      {
        field: 'procurementItem.prItem.item.code',
        header: 'table.column.itemCode'
      },
      {
        field: 'procurementItem.prItem.item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'procurementItem.prItem.item.itemType.code'
        }
      },
      {
        field: 'procurementItem.prItem.quantity',
        header: 'table.column.quantity',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'procurementItem.prItem.item.uom.name',
        header: 'table.column.unit'
      },
      {
        field: 'price',
        header: 'table.column.bidPrice',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      {
        field: 'totalPrice',
        header: 'table.column.totalBidPrice',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      {
        field: 'profitLossAmount',
        header: 'table.column.potensialSavingLoss',
        className: 'd-flex justify-content-end',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): void => {
              if (tablePlugin.value) {
                tablePlugin.element.innerText =
                  this.global.converterService.convertMoney(tablePlugin.value) +
                  ' %';
              }
            }
          },
          {
            name: 'badge',
            field: 'profitLossStatus.name',
            colorMap: {
              SAVE: 'SUCCESS',
              LOSS: 'DANGER'
            },
            pill: false,
            colorField: 'profitLossStatus.code',
            className: 'badge-catalog ml-3'
          }
        ]
      }
    ]);
  }

  public buildTableResponseMultiWinner(): void {
    this.tableResponseQuotationItem = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementItem.prItem.prItemImageList',
        header: 'table.column.thumbnail',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePluginData: TablePluginData): File => {
              if (tablePluginData.value && tablePluginData.value.length > 0) {
                return tablePluginData.value[0].file;
              }
              return null;
            }
          },
          {
            name: 'img',
            classNameMap: {
              MATERIAL: 'pvr pv-box',
              JASA: 'pvr pv-tools'
            },
            fieldClassName: 'procurementItem.prItem.item.itemType.code'
          }
        ],
        isSortable: false,
        isSearchTable: false,
        className: 'text-center'
      },
      {
        field: 'procurementItem.prItem.item.name',
        header: 'table.column.itemName'
      },
      {
        field: 'procurementItem.prItem.item.code',
        header: 'table.column.itemCode'
      },
      {
        field: 'procurementItem.prItem.item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'procurementItem.prItem.item.itemType.code'
        }
      },
      {
        field: 'procurementItem.prItem.quantity',
        header: 'table.column.quantity',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'procurementItem.prItem.item.uom.name',
        header: 'table.column.unit'
      },
      this.procurement.biddingEvaluation.code ===
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
        ? {
            field: 'scoringStatus.name',
            header: 'table.column.evaluationPrice',
            plugins: {
              name: 'badge',
              pill: true,
              colorMap: {
                PASSED: 'SUCCESS',
                NOT_PASSED: 'DANGER'
              },
              colorField: 'scoringStatus.code'
            }
          }
        : {
            field: 'evaluationScore',
            header: 'table.column.evaluationPrice',
            plugins: {
              name: 'text-field',
              type: 'decimal',
              isView: true
            },
            className: 'text-right'
          },
      {
        field: 'price',
        header: 'table.column.bidPrice',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      {
        field: 'totalPrice',
        header: 'table.column.totalBidPrice',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      {
        field: 'profitLossAmount',
        header: 'table.column.potensialSavingLoss',
        className: 'd-flex justify-content-end',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): void => {
              if (tablePlugin.value) {
                tablePlugin.element.innerText =
                  this.global.converterService.convertMoney(tablePlugin.value) +
                  ' %';
              }
            }
          },
          {
            name: 'badge',
            field: 'profitLossStatus.name',
            colorMap: {
              SAVE: 'SUCCESS',
              LOSS: 'DANGER'
            },
            pill: false,
            colorField: 'profitLossStatus.code',
            className: 'badge-catalog ml-3'
          }
        ]
      }
    ]);
  }

  public onChangeItem(): void {
    const itemType = this.formGroup.value.itemType;
    if (itemType) {
      const quotationItemList = [];
      this.popupBidPriceResponse.quotationItemList.forEach(
        (quotationItem: QuotationItem) => {
          if (
            quotationItem.procurementItem.prItem &&
            quotationItem.procurementItem.prItem.item.itemType.id ===
              itemType.id
          ) {
            quotationItemList.push(quotationItem);
          }
        }
      );
      this.tableResponseQuotationItem.setRecordList(quotationItemList);
    } else {
      this.tableResponseQuotationItem.setRecordList(
        this.popupBidPriceResponse.quotationItemList
      );
    }
    this.tableResponseQuotationItem.reload();
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }
}
