import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ScoringTemplate } from '../../core/bean/scoring-template';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { ScoringTemplateResponse } from './scoring-template-response';
@Component({
  templateUrl: './app-popup-scoring-template.component.html'
})
export class AppPopupScoringTemplateComponent extends BaseModuleComponent {
  @Input() public todoNew: string;
  @Input() public scoringTemplateId: number;
  public scoringTemplate: ScoringTemplate = new ScoringTemplate();
  public type: string;
  public objectName: string;
  public moduleScoringCriteriaName: string;
  public scoringTemplateWeightListCommon = [];
  public scoringTemplateWeightListSpesific = [];
  public scoringTemplateWeightListCommonLength: number;
  public scoringTemplateWeightListSpesificLength: number;

  constructor(
    translateService: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    super('app-popup-scoring-template', translateService);
  }

  onInit(): void {
    this.setFormGroup();
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ScoringTemplateResponse>(
        '/scoring-template/add-edit',
        new RouteRequestModel(this.todoNew, this.scoringTemplateId)
      )
      .subscribe(scoringTemplateResponse => {
        this.scoringTemplateWeightListCommonLength =
          scoringTemplateResponse.scoringTemplateWeightListCommon.length;
        this.scoringTemplateWeightListSpesificLength =
          scoringTemplateResponse.scoringTemplateWeightListSpesific.length;

        scoringTemplateResponse.scoringTemplateWeightListCommon.forEach(
          scoringTemplateWeightListCommon => {
            this.scoringTemplateWeightListCommon.push({
              scoringCriteriaGroup:
                scoringTemplateWeightListCommon.scoringCriteriaGroup.name,
              weight: scoringTemplateWeightListCommon.weight
            });
          }
        );

        scoringTemplateResponse.scoringTemplateWeightListSpesific.forEach(
          scoringTemplateWeightListSpesific => {
            this.scoringTemplateWeightListSpesific.push({
              scoringCriteriaGroup:
                scoringTemplateWeightListSpesific.scoringCriteriaGroup.name,
              weight: scoringTemplateWeightListSpesific.weight
            });
          }
        );

        this.scoringTemplate = scoringTemplateResponse.scoringTemplate;
        this.moduleScoringCriteriaName =
          scoringTemplateResponse.scoringTemplate.moduleScoringCriteria.name;
        if (this.scoringTemplate.vendorType != null) {
          this.type = this.scoringTemplate.vendorType.name;
        } else if (this.scoringTemplate.procurementType != null) {
          this.type = '';
          for (
            let i = 0;
            i < scoringTemplateResponse.procurementTypeList.length;
            i++
          ) {
            this.type += scoringTemplateResponse.procurementTypeList[i].name;
            if (i !== scoringTemplateResponse.procurementTypeList.length - 1) {
              this.type += ' > ';
            }
          }
        } else if (scoringTemplateResponse.contractType != null) {
          this.type = scoringTemplateResponse.contractType.name;
          this.objectName = this.scoringTemplate.objectName;
        } else if (scoringTemplateResponse.orderType != null) {
          this.type = scoringTemplateResponse.orderType.name;
          this.objectName = this.scoringTemplate.objectName;
        }
      });
  }

  public doCopy(scoringTemplateId: number): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'copy');
    this.global.routerParams.set('scoringTemplateId', scoringTemplateId);
    this.router.navigate(['/pages/scoring-template/copy']);
    this.activeModal.dismiss();
  }
}
