import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../base/angular/base-component.component';
@Component({
  selector: 'app-widget-card',
  templateUrl: './app-widget-card.component.html',
  styleUrls: ['./app-widget-card.component.scss']
})
export class AppWidgetCardComponent extends BaseComponentComponent {
  @Input() header: string;
  constructor() {
    super('app-widget-card');
  }

  public onInit(): void {
    this.setStateReady();
  }
}
