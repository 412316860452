import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorType } from '../../core/bean/vendor-type';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './vendor-type-edit-add.component.html'
})
export class VendorTypeEditAddComponent extends BaseModuleComponent {
  public vendorTypeId: number;
  constructor(translateService: TranslateService) {
    super('vendor-type', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorTypeId = this.global.routerParams.get('vendorTypeId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      description: [null, Validators.maxLength(512)],
      isCompany: [null],
      vendorTabCode: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<VendorType>(
        '/vendor-type/add-edit',
        new RouteRequestModel(this.todo, this.vendorTypeId)
      )
      .subscribe(vendorType => {
        if (vendorType != null) {
          const {
            id,
            name,
            code,
            description,
            vendorTabCode,
            isCompany
          } = vendorType;
          this.formGroup.patchValue({
            id,
            name,
            code,
            description,
            vendorTabCode,
            isCompany
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const vendorType: VendorType = this.global.copyFormAttributeToModel(
              new VendorType(),
              this.formGroup
            );
            const url: string =
              this.todo === 'edit'
                ? '/vendor-type/update'
                : '/vendor-type/insert';
            this.httpClientService
              .post<Response<VendorType>>(url, vendorType)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/vendor-type/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/vendor-type/']);
  }

  public get formGroupControls() {
    return this.formGroup.controls;
  }
}
