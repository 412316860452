import { EventEmitter } from '@angular/core';
import {
  ChartDataSets,
  ChartOptions,
  ChartPluginsOptions,
  ChartType
} from 'chart.js';
import { Color, MultiDataSet, SingleDataSet } from 'ng2-charts';

export class ChartModel {
  public plugins: Array<ChartPluginsOptions> = new Array();
  public colors: Color[];
  public reloadChanges: EventEmitter<void> = new EventEmitter();
  public typeChanges: EventEmitter<ChartType> = new EventEmitter();
  constructor(
    public type: ChartType,
    public labels: Array<string | Array<string>>,
    public dataSets:
      | Array<ChartDataSets>
      | MultiDataSet
      | Array<number>
      | SingleDataSet,
    public dataSetsLabel?: Array<string>,
    public options?: ChartOptions
  ) {}

  public setType(type: ChartType): void {
    this.type = type;
    this.typeChanges.emit(type);
  }

  public reload(): void {
    this.reloadChanges.emit();
  }
}
