<app-popup
  [header]="'tab-profile-segmentation.form.popup.header.segmentation.' + todo"
  [isLoading]="formLoading"
>
  <form [formGroup]="formGroup" class="app-popup-segmentation-vendor">
    <h5 class="separator">{{
      'tab-profile-segmentation.form-group.popup.classification' | translate
    }}</h5>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-segmentation.form.popup.segmentation' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-tree
          size="LG"
          formControlName="segmentation"
          stringUrl="/segmentation"
          header="{{
            'tab-profile-segmentation.header.segmentation' | translate
          }}"
          [onlyLastChild]="true"
          (onChange)="doChangeSegmentation($event)"
          isLazy="true"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorSegmentationHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorSegmentation.segmentation
                ? vendorSegmentation.segmentation.name
                : null
            "
            [newValue]="
              vendorSegmentationHistoryEdit.segmentation
                ? vendorSegmentationHistoryEdit.segmentation.name
                : null
            "
          ></app-flag-new>
        </app-text-tree>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-segmentation.form.popup.qualification' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="LG"
          [optionList]="qualificationOptionList"
          formControlName="qualification"
        >
          <app-flag-new
            *ngIf="
              global.userSession.activeUserRole.role.type !==
                global.appConstant.core.ROLE_TYPE_VENDOR &&
              formGroup.isView &&
              !isVendorList &&
              (fromOutside === '/pages/on-boarding' ||
                fromOutside === '/pages/approval-prcs') &&
              vendorSegmentationHistoryEdit.vendorHistory !== null
            "
            [oldValue]="
              vendorSegmentation.qualification
                ? vendorSegmentation.qualification.name
                : null
            "
            [newValue]="
              vendorSegmentationHistoryEdit.qualification
                ? vendorSegmentationHistoryEdit.qualification.name
                : null
            "
          ></app-flag-new>
        </app-combo-box>
      </div>
    </div>

    <h5 class="separator">{{
      'tab-profile-segmentation.form-group.popup.association' | translate
    }}</h5>
    <ng-container formArrayName="associationList" *ngIf="!formLoading">
      <app-fieldset
        *ngFor="let association of associationList.controls; let i = index"
        [formGroupName]="i"
      >
        <em
          *ngIf="!formGroup.isView"
          class="fas fa-times text-danger cursor-pointer position-absolute"
          [ngStyle]="{ right: '1rem', top: '1rem' }"
          (click)="doDeleteAssociation(i)"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        ></em>
        <div
          *ngIf="
            global.userSession.activeUserRole.role.type !==
              global.appConstant.core.ROLE_TYPE_VENDOR &&
            formGroup.isView &&
            !isVendorList &&
            (fromOutside === '/pages/on-boarding' ||
              fromOutside === '/pages/approval-prcs') &&
            vendorSegmentationHistoryEdit.vendorHistory !== null
          "
          [innerHtml]="association.value.crudInfo"
        ></div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-profile-segmentation.form.popup.association' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-auto-complete
              size="LG"
              formControlName="association"
              stringUrl="/tab-profile-segmentation/auto-complete-association-list"
              (onChange)="autoCompleteValueChange($event, i)"
              tooltip="{{
                'tab-profile-segmentation.tooltip.association' | translate
              }}"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorSegmentationHistoryEdit.vendorHistory !== null
                "
                [oldValue]="
                  association.value.association
                    ? association.value.association.name
                    : null
                "
                [newValue]="
                  association.value.association
                    ? association.value.association.name
                    : null
                "
              ></app-flag-new>
            </app-auto-complete>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-profile-segmentation.form.popup.certificateNumber' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              size="LG"
              formControlName="certificateNumber"
              maxLength="32"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorSegmentationHistoryEdit.vendorHistory !== null
                "
                [oldValue]="association.value.certificateNumber"
                [newValue]="association.value.certificateNumber"
              ></app-flag-new>
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-profile-segmentation.form.popup.validationDate' | translate
          }}</label>
          <div class="col-sm-9 text-left d-flex">
            <label class="mr-3 control-label">{{
              'tab-profile-segmentation.form.popup.from' | translate
            }}</label>
            <app-date-picker
              size="XL"
              formControlName="startDate"
              maxDate="endDate"
              [ngStyle]="{
                'margin-right': !formGroup.isView ? '0px' : '30px'
              }"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorSegmentationHistoryEdit.vendorHistory !== null
                "
                [oldValue]="startDateActualList[i]"
                [newValue]="startDateHistoryList[i]"
              ></app-flag-new>
            </app-date-picker>
            <label class="ml-3 mr-3 control-label">{{
              'tab-profile-segmentation.form.popup.until' | translate
            }}</label>
            <app-date-picker
              size="XL"
              formControlName="endDate"
              minDate="startDate"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorSegmentationHistoryEdit.vendorHistory !== null
                "
                [oldValue]="endDateActualList[i]"
                [newValue]="endDateHistoryList[i]"
              ></app-flag-new>
            </app-date-picker>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label *ngIf="!formGroup.isView" class="col-sm-3 control-label">{{
            'tab-profile-segmentation.form.popup.uploadCertificate' | translate
          }}</label>
          <label *ngIf="formGroup.isView" class="col-sm-3 control-label">{{
            'tab-profile-segmentation.form.popup.certificate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-upload
              size="LG"
              formControlName="fileCertificate"
              [fileUploader]="fileUploaderList[i]"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorSegmentationHistoryEdit.vendorHistory !== null
                "
                [oldValue]="
                  association.value.file ? association.value.file.id : null
                "
                [newValue]="
                  association.value.file ? association.value.file.id : null
                "
                [hoverValue]="
                  association.value.file
                    ? association.value.file.fileName
                    : null
                "
              ></app-flag-new>
            </app-upload>
          </div>
        </div>
      </app-fieldset>
    </ng-container>

    <div class="form-group row text-sm-right" *ngIf="!formGroup.isView">
      <div class="col-sm-12 pl-4 text-center">
        <app-button (onClick)="doAddMoreAssociation()">{{
          'app.button.addMore' | translate
        }}</app-button>
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          class="mr-1"
          color="SECONDARY"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.close' | translate }}</app-button
        >
        <app-button *ngIf="!formGroup.isView" (onClick)="doSave()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
