import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file'

export class ResourceEntity extends BaseEntity {
    name: string;
    height: number;
    width: number;
    imgFile: File;
    type: string;
    isReadOnly: boolean;
}
