import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { BudgetAllocationByCoaDetailOrganizationView } from '../../core/view/entity/bean/budget-allocation-by-coa-organization';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { BudgetAllocation } from './../../core/bean/budget-allocation';
@Component({
  templateUrl: './app-popup-budget-allocation-bjb.component.html'
})
export class AppPopupBudgetAllocationBjbComponent extends BaseComponentComponent {
  @Input() public coaId: number;
  public tableResponse: TableResponseModel<BudgetAllocationByCoaDetailOrganizationView>;

  constructor(public activeModal: NgbActiveModal) {
    super('budget-allocation-bjb');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'organization.name', header: 'table.column.organization' },
      {
        field: 'branchOffice.name',
        header: 'table.column.branchOffice',
        customClass: 'text-center'
      },
      {
        field: 'activityPeriod',
        header: 'table.column.activityPeriod',
        customClass: 'text-center'
      },
      {
        field: 'totalBudget',
        header: 'table.column.totalBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'bookedBudget',
        header: 'table.column.bookedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'usedBudget',
        header: 'table.column.usedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'paidOffBudget',
        header: 'table.column.paidOffBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'remainingPayment',
        header: 'table.column.remainingPayment',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'availableBudget',
        header: 'table.column.availableBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      }
    ]);
  }

  public doDetail(budget: BudgetAllocation): void {
    this.activeModal.dismiss();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('budgetId', budget.id);
    this.global.routerParams.set(
      'backUrl',
      '/pages/budget-allocation-bjb/organization/detail/index'
    );
    this.router.navigate(['/pages/budget-allocation-bjb/detail']);
  }
}
