import { BaseEntity } from '../base/base-entity';
import { QuotationItem } from '../bean/quotation-item';

export class QuotationItemReferenceEntity extends BaseEntity {
  quotationItem: QuotationItem = new QuotationItem();
  quantity: number;
  tempQuantity: number;
  tempQuantityNego: number;
  price: number;
  totalPrice: number;
}
