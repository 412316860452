import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { AppPopupModule } from '../../core/components/app-popup/app-popup.module';
import { SharedModule } from '../../core/shared/shared.module';
import { TabFinancialStatementHistoryComponent } from './tab-financial-statement-history.component';
import { TabFinancialStatementComponent } from './tab-financial-statement.component';
@NgModule({
  imports: [SharedModule, AppPopupModule],
  declarations: [
    TabFinancialStatementComponent,
    TabFinancialStatementHistoryComponent
  ],
  entryComponents: [
    TabFinancialStatementComponent,
    TabFinancialStatementHistoryComponent
  ],
  exports: [
    TabFinancialStatementComponent,
    TabFinancialStatementHistoryComponent
  ]
})
export class TabFinancialStatementModule {
  constructor(accordionService: AccordionService) {
    accordionService.register(
      'tab-financial-statement',
      TabFinancialStatementComponent
    );
    accordionService.register(
      'tab-financial-statement-history',
      TabFinancialStatementHistoryComponent
    );
  }
}
