import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContractTermination } from 'src/app/core/bean/contract-termination';
import { File } from 'src/app/core/bean/file';
import { AppPopupVersionHistoryModel } from 'src/app/core/components/app-popup/app-popup-version-history/app-popup-version-history-model';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { Validators } from 'src/app/core/validators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Contract } from '../../core/bean/contract';
import { ContractNegotiationHistory } from '../../core/bean/contract-negotiation-history';
import { ContractWorklistStatus } from '../../core/bean/contract-worklist-status';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { AppPopupVersionHistoryService } from '../../core/components/app-popup/app-popup-version-history/app-popup-version-history.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FileUploader } from '../../core/components/upload';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { AppPopupContractTerminationTerminateContractComponent } from './app-popup-contract-termination-terminate-contract.component';
import { ContractTerminationEditAddResponse } from './contract-termination-edit-add-response';
import { ContractTerminationInsertRequest } from './contract-termination-insert-request';
import { ContractTerminationPopupContractReviewerComponent } from './contract-termination-popup-contract-reviewer.component';

@Component({
  templateUrl: './contract-termination-edit-add-x.component.html'
})
export class ContractTerminationEditAddXComponent extends BaseModuleComponent {
  public contractNegotiationHistory: ContractNegotiationHistory;
  public contract: Contract = new Contract();
  public contractTermination: ContractTermination = new ContractTermination();
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public response: ContractTerminationEditAddResponse =
    new ContractTerminationEditAddResponse();
  public request: ContractTerminationInsertRequest =
    new ContractTerminationInsertRequest();
  public tableResponse: TableResponseModel<AppPopupVersionHistoryModel>;
  public model: AppPopupVersionHistoryModel;
  public currentDate: Date = new Date();
  public formGroupTermination: FormGroup = new FormGroup({});
  public fileList: File[] = [];
  public fileUploader: FileUploader = new FileUploader(
    '/contract-termination/',
    'contract-termination.title',
    this.global.appConstant.fileType.DOC_CONTRACT_TERMINATION,
    false,
    5
  );

  public urlBackOutside: string;
  public contractWorklistStatus: ContractWorklistStatus;
  public approvalModelPrcsId: number;
  public objectId: number;
  public contractId: number;
  public contractTerminationId: number;
  public isView = false;
  public isRevision = false;
  public isParameterReady = false;
  public validateChange = true;
  public roleCode: string;
  public startDate: Date;
  public endDate: Date;
  public isReady = false;
  public token: string;

  public badgeColor = {
    NEW: 'INFO',
    REVISION: 'FEEDBACK',
    WAITING_CONFIRMATION: 'GOOD',
    ON_PROGRESS: 'PROGRESS',
    TERMINATED: 'DANGER',
    REJECTED: 'DANGER',
    WAITING_FOR_TERMINATION: 'WARNING',
    REVISION_BY_VENDOR: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING'
  };
  public getColorAndIconConfirm = {
    APPROVED: { color: 'text-success', icon: 'pic pi-thumbs-up-circle' },
    REVISION: { color: 'text-primary', icon: 'pic pi-pencil-circle' }
  };
  public APPROVED = 1;
  public REVISED = 2;

  constructor(
    translateService: TranslateService,
    public appPopupVersionHistoryService: AppPopupVersionHistoryService,
    public appPopupService: AppPopupService,
    public activatedRoute: ActivatedRoute
  ) {
    super('contract-termination', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Contract>('/contract-termination/notification/' + this.token)
      .subscribe((contract: Contract) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('contractId', contract.id);
        this.global.routerParams.set(
          'urlBackOutside',
          '/pages/contract-termination'
        );
        this.global.routerParams.set('todo', 'add');
        this.router.navigate(['/pages/contract-termination/add']);
      });
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    this.contract = this.global.routerParams.get('contract');
    this.contractId = this.global.routerParams.get('contractId');
    this.contractTerminationId = this.global.routerParams.get(
      'contractTerminationId'
    );
    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId != null) {
      this.contractId = this.objectId;
    }
    if (!this.contractTerminationId) {
      this.contractTerminationId = this.contractId;
    }
    this.contractWorklistStatus = this.global.routerParams.get(
      'contractWorklistStatus'
    );
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
  }

  public onClickVersionHistory(): void {
    this.appPopupVersionHistoryService.open(
      this.response.appPopupVersionHistoryModelList
    );
  }

  public setIsViewOnly(): void {
    if (
      this.todo === 'view' ||
      this.todo === 'monitoring' ||
      this.contractTermination?.terminationStatus.code ===
        this.global.appConstant.cm.CONTRACT_STATUS_WAITING_APPROVAL ||
      this.contractTermination?.terminationStatus.code ===
        this.global.appConstant.cm.CONTRACT_STATUS_TERMINATED ||
      this.contractTermination?.terminationStatus.code ===
        this.global.appConstant.cm.CONTRACT_STATUS_WAITING_FOR_TERMINATION ||
      this.contractTermination?.terminationStatus.code ===
        this.global.appConstant.cm.CONTRACT_STATUS_REJECTED ||
      this.contractTermination?.terminationStatus.code ===
        this.global.appConstant.cm.CONTRACT_STATUS_REVISION
    ) {
      this.isView = true;
      this.formGroupTermination.setIsView(true);
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      docFile: [null, Validators.required()],
      date: [null, Validators.compose([Validators.required()])],
      startDate: [null],
      endDate: [null],
      currentDate: this.currentDate,
      endDateValidate: [null],
      contractNumber: [null]
    });
    this.formGroupTermination = this.formBuilder.group({
      terminationDate: [null, Validators.compose([Validators.required()])],
      code: [null],
      description: [null],
      file: [null, Validators.required()]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'docFile',
        header: 'table.column.file',
        plugins: {
          name: 'upload',
          fileUploader: new FileUploader(
            '/app-contract-negotiation-view/',
            '',
            this.global.appConstant.fileType.DOC_CONTRACT,
            true
          ),
          isView: true
        },
        isSearchTable: false
      },
      {
        field: 'version',
        header: 'table.column.version'
      },
      {
        field: 'uploaderName',
        header: 'table.column.uploadedBy'
      },
      {
        field: 'role',
        header: 'table.column.uploaderRole'
      },
      {
        field: 'lastModifiedDate',
        header: 'table.column.date'
      }
    ]);
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ContractTerminationEditAddResponse>(
        '/contract-termination/add-edit-x',
        new RouteRequestModel(this.todo, this.contractTerminationId)
      )
      .subscribe(contractTerminationResponse => {
        this.roleCode = this.global.userSession.activeUserRole.role.code;
        this.response = contractTerminationResponse;
        this.contract = this.response.contract;
        this.contractId = this.contract.id;
        this.contractNegotiationHistory =
          this.response.contractNegotiationHistory;
        this.model = this.response.versionHistoryModel;
        this.startDate = new Date(this.response.contract.startDate);
        this.endDate = new Date(this.response.contract.endDate);
        this.formGroup.patchValue({
          date: {
            from: this.startDate,
            to: this.endDate
          },
          contractNumber: this.response.contract.code
        });
        this.formGroup.setIsView(true);
        if (!this.contractWorklistStatus) {
          this.contractWorklistStatus =
            this.response.contract.contractWorklistStatus;
        }
        if (this.response.contractTermination) {
          this.contractTermination = this.response.contractTermination;
          const terminationDate: Date = new Date(
            this.response.contractTermination.terminationDate
          );
          if (this.response.contractTerminationDocList) {
            this.response.contractTerminationDocList.forEach(ctd => {
              this.fileList.push(ctd.file);
            });
            this.fileUploader.setFileList(this.fileList);
          }
          this.formGroupTermination.patchValue({
            file: this.fileUploader.fileObjectList,
            terminationDate,
            code: this.response.contractTermination.code,
            description: this.response.contractTermination.description
          });
        }
        if (this.response.contractDocModel) {
          this.tableResponse.setRecordList([this.response.contractDocModel]);
          this.tableResponse.reload();
        } else {
          this.tableResponse.setRecordList([]);
        }
        this.setIsViewOnly();
        this.setStateReady();
        this.isReady = true;
      });
  }

  public doViewReviewer(): void {
    this.appPopupService.open(
      ContractTerminationPopupContractReviewerComponent,
      {
        contractReviewerList: this.response.contractReviewerList
      }
    );
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public goToDeliveryMonitoring(): void {
    localStorage.setItem('drId', this.contract.dr.id.toString());
    if (
      this.global.userSession?.activeUserRole.role.code ===
        this.global.appConstant.ROLE_CODE_VENDOR ||
      this.response.contract.dr.deliveryStatus.code ===
        this.global.appConstant.pm.DELIVERY_STATUS_DONE
    ) {
      localStorage.setItem('todo', 'view');
      window.open('/pages/dr/detail');
    } else {
      localStorage.setItem('todo', 'edit');
      window.open('/pages/dr/edit');
    }
  }

  public doTerminate(): void {
    this.appPopupService
      .open(
        AppPopupContractTerminationTerminateContractComponent,
        {
          contract: this.contract,
          contractTermination: this.response.contractTermination,
          contractTerminationDocList: this.response.contractTerminationDocList,
          isNumberGenerator: this.response.module.isNumberGenerator
        },
        { size: 'lg' }
      )
      .subscribe();
  }
}
