import { Uploader } from '../model/uploader';
import { addFileToUploaderFileList } from './add-files-to-uploader-files.action';
import { bulkDeleteFile } from './bulk-delete-file.action';
import { cancelUploadFile } from './cancel-upload-file.action';
import { changeFile } from './change-file.action';
import { deleteFile } from './delete-file.action';
import { reUploadFile } from './re-upload-file.action';
import * as uploadFileAction from './upload-file.action';
export function dispatchUpload(
  state: Uploader,
  action: uploadFileAction.All
): void {
  switch (action.type) {
    case uploadFileAction.ADD_FILE:
      addFileToUploaderFileList(state, action.payload);
      break;
    case uploadFileAction.CANCEL_UPLOAD:
      cancelUploadFile(action.payload);
      break;
    case uploadFileAction.RE_UPLOAD:
      reUploadFile(action.payload);
      break;
    case uploadFileAction.CHANGE_FILE:
      changeFile(state, action.payload);
      break;
    case uploadFileAction.DELETE_FILE:
      deleteFile(state, action.payload);
      break;
    case uploadFileAction.BULK_DELETE_FILE:
      bulkDeleteFile(state);
      break;
    case uploadFileAction.RESET:
      break;
    default:
      break;
  }
}
