import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { StageOfWork } from '../../core/bean/stage-of-work';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './stage-of-work-edit-add.component.html'
})
export class StageOfWorkEditAddComponent extends BaseModuleComponent {
  public stageOfWorkId: number;
  constructor(translateService: TranslateService) {
    super('stage-of-work', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.stageOfWorkId = this.global.routerParams.get('stageOfWorkId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        {
          validators: Validators.compose([
            Validators.required(),
            Validators.maxLength(64)
          ]),
          asyncValidators: [
            Validators.existsAsync('/stage-of-work/check-exist', null, null, {
              id: this.stageOfWorkId
            })
          ]
        }
      ],
      name: [
        null,
        {
          validators: Validators.compose([
            Validators.required(),
            Validators.maxLength(128)
          ]),
          asyncValidators: [
            Validators.existsAsync('/stage-of-work/check-exist', null, null, {
              id: this.stageOfWorkId
            })
          ]
        }
      ],
      description: [null, Validators.maxLength(512)]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<StageOfWork>(
        '/stage-of-work/add-edit',
        new RouteRequestModel(this.todo, this.stageOfWorkId)
      )
      .subscribe(stageOfWork => {
        if (stageOfWork != null) {
          const { id, code, name, description } = stageOfWork;
          this.formGroup.patchValue({ id, code, name, description });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const stageOfWork: StageOfWork = this.global.copyFormAttributeToModel(
              new StageOfWork(),
              this.formGroup
            );
            const url =
              this.todo === 'edit'
                ? '/stage-of-work/update'
                : '/stage-of-work/insert';
            this.httpClientService
              .post<Response<StageOfWork>>(url, stageOfWork)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/stage-of-work/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/stage-of-work/']);
  }
}
