
export class TablePageModel<T> {
    records: Array<T> = new Array();
    totalRecords: number;
    constructor() {}
    setRecords(records: Array<T>): void {
        this.records = records;
    }
    setTotalRecords(totalRecords: number): void {
        this.totalRecords = totalRecords;
    }
}
