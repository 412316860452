<app-layout
  [isLoading]="formLoading"
  header="compliance-test-master.layout.header.masterComplianceTest"
>
  <app-table-xx
    header="compliance-test-master.table.header.complianceTestList"
    [model]="tableResponse"
    stringUrl="/compliance-test-master/index"
    (onClick)="doClickMethod($event)"
  >
  </app-table-xx>
</app-layout>
