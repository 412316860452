import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorExpertiseHistory } from '../../core/bean/vendor-expertise-history';
@Component({
  templateUrl: './app-popup-tab-vendor-expertise.component.html'
})
export class AppPopupTabVendorExpertiseComponent extends BaseModuleComponent {
  @Input() header: string;
  @Input() vendorExpertiseHistory: VendorExpertiseHistory;
  constructor(translate: TranslateService) {
    super('app-popup-tab-vendor-expertise', translate);
  }

  public onInit(): void {
    this.setStateReady();
  }
}
