import { OrganizationEntity } from '../entity/organization.entity';
import { HospitalClass } from './hospital-class';
import { Region } from './region';
import { Zone } from './zone';

export class Organization extends OrganizationEntity {
	isDistributor: boolean;
	isBranch: boolean;
	distributorLocationCode: string;
	countryRegion: Region;
	provinceRegion: Region;
	cityRegion: Region;
	phone: string;
	fax: string;
	email: string;
	website: string;
	address: string;
	hospitalCode: string;
	zone: Zone;
	hospitalClass: HospitalClass;
}
