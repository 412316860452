<app-layout [isLoading]="formLoading">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-alert-x
        [color]="alertColor[workloadLevel.code]"
        *ngIf="!formLoading"
        [icon]="
          workloadLevel.code === global.appConstant.core.WORKLOAD_LEVEL_LOW
            ? 'fa-info-circle'
            : 'fa-exclamation-triangle'
        "
      >
        <div
          [ngClass]="
            workloadLevel.code !==
            global.appConstant.core.WORKLOAD_LEVEL_NOT_ASSIGNED
              ? 'd-flex justify-content-between'
              : ''
          "
        >
          <div>
            {{ alertMessage[workloadLevel.code] | translate }}
          </div>
          <div
            *ngIf="
              workloadLevel.code !==
              global.appConstant.core.WORKLOAD_LEVEL_NOT_ASSIGNED
            "
            (click)="doOpenPopupLevelInfo()"
            class="cursor-pointer"
          >
            <span class="mr-3"
              ><em
                class="pir pi-info-circle"
                style="
                  color: var(--gray-700);
                  font-size: 20px;
                  position: static;
                "
                placement="top"
              ></em
            ></span>
            <a style="text-decoration: underline; color: var(--gray-700)"
              >{{
                'workload-monitoring.alert.label.viewDefinitionLevel'
                  | translate
              }}
            </a>
          </div>
        </div>
      </app-alert-x>
      <app-card
        header="workload-monitoring.detail.assignment.title"
        tips="workload-monitoring.detail.assignment.tips"
      >
        <app-alert-x>{{
          'workload-monitoring.alert.msg.infoAssignment' | translate
        }}</app-alert-x>
        <app-table-xx
          *ngIf="!formLoading"
          [model]="tableResponse"
          stringUrl="/workload-monitoring/table-assignment"
          (onClick)="doClickDataAssignment($event)"
        >
          <ng-template #headerFilterGroup>
            <form
              class="form-horizontal"
              [formGroup]="formGroup"
              novalidate
              *ngIf="!formLoading"
            >
              <div class="form-group row text-sm-left">
                <div class="col-lg-12 row" style="margin-bottom: -1.5rem">
                  <label
                    class="cml-3 d-flex align-items-center"
                    style="margin-top: auto"
                    >{{
                      'workload-monitoring.detail.form.date' | translate
                    }}</label
                  >
                  <div class="cml-3 text-left">
                    <app-date-picker-x
                      size="XL"
                      formControlName="date"
                      [range]="true"
                      (onChange)="onChangeFilter()"
                    >
                    </app-date-picker-x>
                  </div>
                  <div class="cml-3 text-left col-sm-2">
                    <app-dropdown-select
                      size="XL"
                      formControlName="statusList"
                      (onChange)="onChangeFilter()"
                      showLimitValue="3"
                      type="CHOSEN"
                      isMultiple="true"
                      placeholder="workload-monitoring.detail.placeholder.status"
                      [optionList]="statusOptionList"
                    >
                    </app-dropdown-select>
                  </div>
                </div>
              </div>
            </form>
          </ng-template>
        </app-table-xx>
      </app-card>
      <app-card
        header="workload-monitoring.detail.userWorkload.title"
        tips="workload-monitoring.detail.userWorkload.tips"
        *ngIf="!formLoading"
      >
        <app-user-workload [user]="user" [activeModuleCode]="moduleCodeTmp"></app-user-workload>
      </app-card>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
