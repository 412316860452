<app-popup header="user-monitoring.popup.title" [isLoading]="formLoading">
  <app-table-xx
    [model]="tableResponseCounter"
    [isMultipleSelect]="true"
    [isShowCheckBox]="true"
    (onChange)="doValidate()"
  >
  </app-table-xx>
  <div class="row justify-content-center mt-5">
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          [disabled]="formSaving"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button
          color="PRIMARY"
          (onClick)="doAddUserCounter()"
          [disabled]="formLoading"
          >{{ 'app.button.add' | translate }}</app-button
        >
      </div>
    </ng-template>
  </div>
</app-popup>
