<app-popup
  header="contract-monitoring.popup.contractData"
  [isLoading]="formLoading"
>
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    novalidate
    *ngIf="!formLoading"
  >
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'contract-monitoring.form.contractDocument' | translate
      }}</label>
      <div class="col">
        <div class="col-12 text-left p-0">
          <a
            href="#"
            (click)="
              doDownload(
                $event,
                '/contract-negotiation/file/view/' +
                  contractFileHistory.file.uploadedFileName +
                  '/' +
                  contractFileHistory.file.fileType.code,
                contractFileHistory.file.fileName
              )
            "
          >
            {{
              contractFileHistory.file.fileName
                ? contractFileHistory.file.fileName
                : '-'
            }}
          </a>
        </div>
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'contract-monitoring.form.contractNumber' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ contract.number ? contract.number : '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'contract-monitoring.form.effectiveDate' | translate
      }}</label>
      <div class="col-sm-9 text-left" style="display: flex">
        <p class="mr-3 control-label">{{
          'contract-monitoring.form.from' | translate
        }}</p>
        <app-date-picker size="XL" formControlName="startDate">
        </app-date-picker>
        <p class="mr-3 ml-1 control-label">{{
          'contract-monitoring.form.until' | translate
        }}</p>
        <app-date-picker
          size="XL"
          formControlName="endDate"
          [minDate]="startDate"
        >
        </app-date-picker>
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'contract-monitoring.form.accountNumber' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ vendorBank.accountNumber ? vendorBank.accountNumber : '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'contract-monitoring.form.bankName' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ vendorBank.bank.name ? vendorBank.bank.name : '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'contract-monitoring.form.accountHolder' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ vendorBank.accountHolder ? vendorBank.accountHolder : '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'contract-monitoring.form.branchName' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ vendorBank.branch ? vendorBank.branch : '-' }}
      </div>
    </div>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
        'app.button.close' | translate
      }}</app-button>
      <app-button
        *ngIf="!formGroup.isView"
        color="PRIMARY"
        (onClick)="doSave()"
        >{{ 'app.button.save' | translate }}</app-button
      >
    </div>
  </ng-template>
</app-popup>
