import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormArray, FormGroup, NgModel } from '@angular/forms';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { BudgetAllocation } from '../../core/bean/budget-allocation';
import { BudgetType } from '../../core/bean/budget-type';
import { Coa } from '../../core/bean/coa';
import { CostCenter } from '../../core/bean/cost-center';
import { Currency } from '../../core/bean/currency';
import { Item } from '../../core/bean/item';
import { Organization } from '../../core/bean/organization';
import { WorkPlanItemBudget } from '../../core/bean/work-plan-item-budget';
import { AppPopupCoaBjbService } from '../../core/components/app-popup/app-popup-coa-bjb/app-popup-coa-bjb.service';
import { AppPopupItemService } from '../../core/components/app-popup/app-popup-item/app-popup-item.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';
import { WorkPlanItem } from './../../core/bean/work-plan-item';
import { ChangeBudget, WorkPlanItemModel } from './work-plan-item.model';

@Component({
  selector: 'app-popup-work-plan-item-product',
  templateUrl: './app-popup-work-plan-item-product.component.html'
})
export class AppPopupWorkPlanItemProductComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() todo: string;
  @Input() workPlanItemList: WorkPlanItem[];
  @Input() public workplanItemEdit: WorkPlanItem;
  @Input() public budgetTypeList: BudgetType[] = [];
  @Input() public costCenterList: CostCenter[] = [];
  @Input() public currencyList: Currency[] = [];
  @Input() public usedBudgetList: Map<number, number> = new Map<
    number,
    number
  >();
  @Output() public onChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('selectorBudgetView') tableBudgetView: AppTableComponent;

  public budgetEditItemList: number[] = [];
  public itemList: Item[] = [];
  public tableResponse: TableResponseModel<BudgetAllocation>;
  public tableResponseBudgetView: TableResponseModel<WorkPlanItemBudget>;
  public isGenerateTableBudget: boolean = null;
  public formGroupBudget: FormGroup;
  public isDefaultBudgetEdit = false;
  public budgetTypeOptionList: OptionListModel<BudgetType> =
    new OptionListModel(false);
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );
  public periodOptionList: OptionListModel<any> = new OptionListModel(false);
  public workPlanItemBudget: FormGroup;
  public totalBudget = 0;
  public workPlanItemModel: WorkPlanItemModel = new WorkPlanItemModel();
  public errorMapper: Map<number, string> = new Map();
  public budgetIdWorkplanItemEdit: Map<number, number> = new Map<
    number,
    number
  >();
  public budgetTempId: number; /* Custom BJB */
  public defaultCurrency: string; /* Custom BJB */
  public autoCompleteValue: any;
  public organizationIdParams: Object;
  public isNoError = true;

  constructor(
    public appPopupItemService: AppPopupItemService,
    public appPopupCoaBjbService: AppPopupCoaBjbService
  ) {
    super('work-plan');
  }

  onInit(): void {
    this.setOptionModel();
    this.buildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
    this.buildFormGroupBudget();
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setOptionModel(): void {
    this.budgetTypeOptionList.setRequestValues(this.budgetTypeList);
    this.currencyOptionList.setRequestValues(this.currencyList);
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const periodOptionList = [
      { id: 1, name: currentYear },
      { id: 2, name: nextYear }
    ];
    this.periodOptionList.setRequestValues(periodOptionList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      item: [null, Validators.compose([Validators.required()])],
      itemName: [null, Validators.compose([Validators.required()])],
      currency: [null, Validators.compose([Validators.required()])],
      uom: [null, Validators.compose([Validators.required()])],
      quantity: [null, Validators.compose([Validators.required()])],
      /* unitPrices: [null, Validators.compose([Validators.required()])], */
      unitPrice: [null, Validators.compose([Validators.required()])],
      totalPrice: [null, Validators.compose([Validators.required()])],
      specification: [null, Validators.compose([Validators.required()])],
      workPlanItemBudgetList: this.formBuilder.array([])
    });
  }

  public autoCompleteValueChange(event: any): void {
    this.autoCompleteValue = event;
  }

  public get workPlanItemBudgetList(): FormArray {
    return this.formGroup.get('workPlanItemBudgetList') as FormArray;
  }

  public setFormGroup(): void {
    if (this.todo !== 'add') {
      this.formGroup.patchValue({
        item: this.workplanItemEdit.item,
        itemName: this.workplanItemEdit.item.name,
        currency: this.workplanItemEdit.currency,
        uom: this.workplanItemEdit.uom,
        quantity: this.workplanItemEdit.quantity,
        /* PRODUCT */
        /* unitPrices: {
          currency: this.workplanItemEdit.currency,
          price: this.workplanItemEdit.unitPrice,
        }, */
        unitPrice: this.workplanItemEdit.unitPrice,
        totalPrice: this.workplanItemEdit.totalPrice,
        specification: this.workplanItemEdit.specification
      });

      this.workplanItemEdit.workPlanItemBudgetList.forEach(
        (workPlanItemBudget: WorkPlanItemBudget) => {
          const plannedBudget: ChangeBudget = {
            id: workPlanItemBudget.budgetAllocation.id,
            isChecked: true,
            plannedBudget: workPlanItemBudget.updatedBudget,
            organization: workPlanItemBudget.budgetAllocation.organization,
            costCenter: workPlanItemBudget.budgetAllocation.costCenter,
            coa: workPlanItemBudget.budgetAllocation.coa,
            budgetType: workPlanItemBudget.budgetAllocation.budgetType,
            currency: workPlanItemBudget.budgetAllocation.currency,
            workProgram: workPlanItemBudget.budgetAllocation.workProgram
          };
          this.workPlanItemModel.recordPageMap.set(
            workPlanItemBudget.budgetAllocation.id,
            plannedBudget
          );
          this.totalBudget += +workPlanItemBudget.updatedBudget;
          this.budgetEditItemList.push(workPlanItemBudget.budgetAllocation.id);
          this.setError(workPlanItemBudget.budgetAllocation);
        }
      );
      if (this.todo === 'view') {
        this.defaultCurrency =
          this.global.converterService.convertMoney(
            this.workplanItemEdit.unitPrice
          ) +
          ' ' +
          this.workplanItemEdit.currency.code;
      } else {
        this.defaultCurrency = this.workplanItemEdit.currency.code;
      }

      this.workplanItemEdit.workPlanItemBudgetList.forEach(itemBudget => {
        this.budgetIdWorkplanItemEdit.set(
          itemBudget.budgetAllocation.id,
          +itemBudget.updatedBudget
        );
      });

      this.buildTableResponseBudgetView();
      this.isDefaultBudgetEdit = true;
      this.buildTableResponse();
      this.tableResponse.setCustomData({
        budgetAllocationId: this.budgetEditItemList
      });
      this.isGenerateTableBudget = true;
    } else {
      this.defaultCurrency = this.currencyList[0].code;
    }
    this.setStateReady();
  }

  public buildFormGroupBudget(): void {
    this.formGroupBudget = this.formBuilder.group({
      budgetAllocationId: [null],
      organization: [null, Validators.compose([Validators.required()])],
      workProgram: [null, Validators.compose([Validators.required()])],
      budgetType: [null],
      costCenter: [null],
      coa: [null],
      coaName: [null],
      activityPeriod: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'organization.name',
        header: 'table.column.organization',
        customClass: 'text-center'
      },
      { field: 'workProgram.name', header: 'table.column.workProgram' },
      {
        field: 'costCenter.code',
        header: 'table.column.costCenterCode',
        customClass: 'text-center'
      },
      {
        field: 'costCenter.name',
        header: 'table.column.costCenterName'
      },
      {
        field: 'coa.code',
        header: 'table.column.coaCode',
        customClass: 'text-center'
      },
      { field: 'coa.name', header: 'table.column.coaName' },
      { field: 'budgetType.name', header: 'table.column.budgetType' },
      {
        field: 'totalBudget',
        header: 'table.column.availableBudget',
        customClass: 'text-right',
        fn: this.calculteAvailable.bind(this)
      }
    ]);
  }

  public buildTableResponseBudgetView(): void {
    this.tableResponseBudgetView = new TableResponseModel(this.moduleCode, [
      {
        field: 'budgetAllocation.organization.name',
        header: 'table.column.organization',
        customClass: 'text-center'
      },
      {
        field: 'budgetAllocation.workProgram.name',
        header: 'table.column.workProgram'
      },
      {
        field: 'budgetAllocation.costCenter.code',
        header: 'table.column.costCenterCode',
        customClass: 'text-center'
      },
      {
        field: 'budgetAllocation.costCenter.name',
        header: 'table.column.costCenterName'
      },
      {
        field: 'budgetAllocation.coa.code',
        header: 'table.column.coaCode',
        customClass: 'text-center'
      },
      { field: 'budgetAllocation.coa.name', header: 'table.column.coaName' },
      {
        field: 'budgetAllocation.budgetType.name',
        header: 'table.column.budgetType'
      },
      {
        field: 'updatedBudget',
        header: 'table.column.budgetValue',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'budgetAllocation.currency.code'
      }
    ]);

    this.tableResponseBudgetView.page.records =
      this.workplanItemEdit.workPlanItemBudgetList;
    this.tableResponseBudgetView.page.totalRecords =
      this.workplanItemEdit.workPlanItemBudgetList.length;
  }

  public getAvailablePlanned (budgetAllocation: BudgetAllocation): number {
    let availablePlanned = this.budgetIdWorkplanItemEdit.get(budgetAllocation.id)
    ? this.usedBudgetList.get(budgetAllocation.id)
      ? budgetAllocation.totalBudget -
          +budgetAllocation.plannedBudget -
          this.usedBudgetList.get(budgetAllocation.id) +
          this.budgetIdWorkplanItemEdit.get(budgetAllocation.id)
      : budgetAllocation.totalBudget -
          +budgetAllocation.plannedBudget +
          this.budgetIdWorkplanItemEdit.get(budgetAllocation.id)
    : this.usedBudgetList.get(budgetAllocation.id)
      ? budgetAllocation.totalBudget -
          +budgetAllocation.plannedBudget -
          this.usedBudgetList.get(budgetAllocation.id)
      : budgetAllocation.totalBudget - +budgetAllocation.plannedBudget
    return availablePlanned;
  }


  public calculteAvailable(
    totalBudget: number,
    budget: BudgetAllocation
  ): string {
    let availablePlanned = 
      this.global.converterService.convertMoney(this.getAvailablePlanned(budget));
    this.log.debug(totalBudget);
    availablePlanned = availablePlanned === undefined ? '0 ' : availablePlanned + ' ';
    availablePlanned += budget.currency.code;
    return availablePlanned;
  }

  public onChangeOrganization(): void {
    this.organizationIdParams = {
      organizationId:
        this.formGroupBudget.get('organization').value[
          this.formGroupBudget.get('organization').value.length - 1
        ].id
    };
    this.formGroupBudget.get('workProgram').patchValue(null);
  }

  public chooseItem(): void {
    const itemCategoryCode = 'Material';
    this.appPopupItemService.open([], itemCategoryCode).subscribe(item => {
      this.formGroup.patchValue({
        itemName: item.name,
        item,
        uom: item.uom
      });
    });
  }

  public doChooseCoa(): void {
    this.appPopupCoaBjbService.open().subscribe((coa: Coa) => {
      this.formGroupBudget.patchValue({
        coa,
        coaName: coa.name
      });
    });
  }

  /* Custom BJB */
  public onInputQuantityOrPrice(): void {
    let unitPrice = this.formGroup.get('unitPrice').value
      ? this.global.converterService.uConvertMoney(
          this.formGroup.get('unitPrice').value
        )
      : this.formGroup.get('unitPrice').value;
    let quantity = this.formGroup.get('quantity').value
      ? this.global.converterService.uConvertMoney(
          this.formGroup.get('quantity').value
        )
      : this.formGroup.get('quantity').value;
    if (
      unitPrice &&
      this.global.converterService.convertMoney(unitPrice) === '0.00'
    ) {
      this.formGroup.patchValue({
        unitPrice: null
      });
      unitPrice = null;
    }
    if (
      quantity &&
      this.global.converterService.convertMoney(quantity) === '0.00'
    ) {
      this.formGroup.patchValue({
        quantity: null
      });
      quantity = null;
    }
    let totalPrice = null;
    if (unitPrice && quantity) {
      const unitPriceNumber =
        this.global.converterService.uConvertMoney(unitPrice);
      totalPrice = +(+unitPriceNumber * quantity).toFixed(
        this.global.appConstant.core.CURRENCY_PRECISSION_SCALE
      );
      unitPrice = this.global.converterService.convertMoney(unitPriceNumber);
      this.formGroup.patchValue({
        unitPriceNumber
      });
    }
    this.formGroup.patchValue({
      totalPrice
    });
    this.totalBudget = 0;
    this.workPlanItemModel.recordPageMap.forEach((workPlanItemModel) => {
      if(workPlanItemModel.isChecked) {
        workPlanItemModel.plannedBudget = this.formGroup.get('totalPrice').value;
        this.totalBudget += +workPlanItemModel.plannedBudget;
        const budget = this.tableResponse.page.records.filter(budget => budget.id === workPlanItemModel.id)[0];
        this.setError(budget);
      }
    });
  }

  /* Product */
  /* public onChangeQuantity(): void {
    const { unitPrices, quantity } = this.formGroup.value;
    if (unitPrices) {
      // tslint:disable-next-line: no-non-null-assertion
      const unitPrice = unitPrices!.price;
      const unitPriceNumber = this.global.converterService.uConvertMoney(
        unitPrice
      );
      const totalPrice = +unitPriceNumber * quantity;
      this.formGroup.patchValue({
        totalPrice,
        unitPrice: unitPriceNumber,
      });
    }
  }

  public onChangeUnitPrice(event: any): void {
    const { unitPrices, quantity } = this.formGroup.value;
    const unitPrice = unitPrices.price;
    const unitPriceNumber = this.global.converterService.uConvertMoney(
      unitPrice
    );
    const totalPrice = +event.price * quantity;

    this.formGroup.patchValue({
      totalPrice,
      unitPrice: unitPriceNumber,
    });
  } */

  public doCheckFormGroupValid(): void {
    if (this.formGroup.get('unitPrice').value) {
      this.formGroup.patchValue({
        unitPrice: this.global.converterService.uConvertMoney(
          this.formGroup.get('unitPrice').value
        ),
        currency: this.currencyList[0]
      });
    }
    this.validate();
    if (!this.isGenerateTableBudget) {
      this.formGroupBudget.setStateSubmitted();
      this.formGroupService.validateAllFormFields(this.formGroupBudget);
      if (!this.formGroupBudget.valid) {
        this.formGroupBudget.resetStateSubmitted();
      }
    }
  }

  public doApply(): void {
    this.doCheckFormGroupValid();
    if (this.formGroupBudget.valid && this.formGroup.valid) {
      // const coaList: Coa[] = this.formGroupBudget.get('coa').value;
      const organizationList: Organization[] =
        this.formGroupBudget.get('organization').value;
      const coa: Coa = this.formGroupBudget.get('coa').value;
      if (coa !== null) {
        this.formGroupBudget.patchValue({
          coa,
          coaName: coa.name
        });
      } else {
        this.formGroupBudget.patchValue({
          coa: null,
          coaName: null
        });
      }
      if (organizationList !== null && organizationList.length > 0) {
        this.formGroupBudget.patchValue({
          organization: organizationList[organizationList.length - 1]
        });
      } else {
        this.formGroupBudget.patchValue({
          organization: null
        });
      }
      if (this.formGroupBudget.get('activityPeriod').value) {
        this.formGroupBudget.patchValue({
          activityPeriod: this.formGroupBudget.get('activityPeriod').value.name
        });
      }

      if (this.isGenerateTableBudget === null) {
        this.isDefaultBudgetEdit = false;
        this.buildTableResponse();
        this.tableResponse.setCustomData(this.formGroupBudget.value);
      } else {
        this.isDefaultBudgetEdit = false;
        this.tableResponse.setCustomData(this.formGroupBudget.value);
        this.tableResponse.reload();
      }
      this.isGenerateTableBudget = true;
      this.formGroupBudget.patchValue({
        coa,
        organization: organizationList
      });
      this.setOptionModel();
    }
  }

  public doReset(): void {
    this.formGroupBudget.reset();
    this.workPlanItemModel.recordPageMap.clear();
    if (
      this.isGenerateTableBudget !== null &&
      !this.isDefaultBudgetEdit &&
      this.todo === 'add'
    ) {
      this.isGenerateTableBudget = false;
      this.tableResponse.resetCustomData();
    } else if (this.isGenerateTableBudget && this.todo !== 'add') {
      this.workplanItemEdit.workPlanItemBudgetList.forEach(
        (workPlanItemBudget: WorkPlanItemBudget) => {
          const plannedBudget: ChangeBudget = {
            id: workPlanItemBudget.budgetAllocation.id,
            isChecked: true,
            plannedBudget: workPlanItemBudget.updatedBudget,
            organization: workPlanItemBudget.budgetAllocation.organization,
            costCenter: workPlanItemBudget.budgetAllocation.costCenter,
            coa: workPlanItemBudget.budgetAllocation.coa,
            budgetType: workPlanItemBudget.budgetAllocation.budgetType,
            currency: workPlanItemBudget.budgetAllocation.currency,
            workProgram: workPlanItemBudget.budgetAllocation.workProgram
          };
          this.workPlanItemModel.recordPageMap.set(
            workPlanItemBudget.budgetAllocation.id,
            plannedBudget
          );
        }
      );
      this.tableResponse.setCustomData({
        budgetAllocationId: this.budgetEditItemList
      });
      this.tableResponse.reload();
    }
  }

  public doCheckAll(event: any): void {
    this.log.info(event.target.checked);
    this.workPlanItemModel.isCheckedAll = event.target.checked;
    this.tableResponse.page.records.forEach(budgetAllocation => {
      let plannedBudget: ChangeBudget =
        this.workPlanItemModel.recordPageMap.get(budgetAllocation.id);
      if (!plannedBudget) {
        plannedBudget = {
          id: budgetAllocation.id,
          isChecked: event.target.checked,
          plannedBudget: 0,
          organization: budgetAllocation.organization,
          costCenter: budgetAllocation.costCenter,
          coa: budgetAllocation.coa,
          budgetType: budgetAllocation.budgetType,
          currency: budgetAllocation.currency,
          workProgram: budgetAllocation.workProgram
        };
      }
      plannedBudget.isChecked = event.target.checked;
      this.workPlanItemModel.recordPageMap.set(
        budgetAllocation.id,
        plannedBudget
      );
      this.setError(budgetAllocation, event.target.checked);
    });

    this.totalBudget = 0;

    this.workPlanItemModel.recordPageMap.forEach(changeBudget => {
      changeBudget.isChecked = event.target.checked;
      if (!event.target.checked) {
        this.workPlanItemModel.recordPageMap.delete(changeBudget.id);
      } else {
        this.totalBudget = this.totalBudget + +changeBudget.plannedBudget;
      }
    });
  }

  public doCheck(event: any, budgetAllocation: BudgetAllocation): void {
    this.workPlanItemModel.recordPageMap.clear(); // Custom BJB
    this.errorMapper.delete(this.budgetTempId); // Custom BJB
    let plannedBudget: ChangeBudget = this.workPlanItemModel.recordPageMap.get(
      budgetAllocation.id
    );
    if (!plannedBudget) {
      plannedBudget = {
        id: budgetAllocation.id,
        isChecked: event.target.checked,
        plannedBudget: 0,
        organization: budgetAllocation.organization,
        costCenter: budgetAllocation.costCenter,
        coa: budgetAllocation.coa,
        budgetType: budgetAllocation.budgetType,
        currency: budgetAllocation.currency,
        workProgram: budgetAllocation.workProgram
      };
    }

    if (this.workPlanItemModel.isCheckedAll && !event.target.checked) {
      this.workPlanItemModel.isCheckedAll = false;
      plannedBudget.isChecked = event.target.checked;
    } else {
      plannedBudget.isChecked = event.target.checked;
    }
    if (plannedBudget.isChecked) {
      plannedBudget.plannedBudget = this.formGroup.get('totalPrice').value;
    } else {
      plannedBudget.plannedBudget = 0;
    }
    this.workPlanItemModel.recordPageMap.set(
      budgetAllocation.id,
      plannedBudget
    );
    this.setError(budgetAllocation);
    this.totalBudget = 0;
    this.workPlanItemModel.recordPageMap.forEach(workPlanItemModel => {
      this.totalBudget = this.totalBudget + +workPlanItemModel.plannedBudget;
    });

    if (!event.target.checked) {
      this.totalBudget =
        this.totalBudget -
        this.workPlanItemModel.recordPageMap.get(budgetAllocation.id)
          .plannedBudget;
      this.workPlanItemModel.recordPageMap.delete(budgetAllocation.id);
    }
  }

  public setWorkPlanItemBudgetList(): void {
    this.workPlanItemBudgetList.clear();
    this.workPlanItemModel.recordPageMap.forEach(budgetAllocation => {
      const formGroup = this.formBuilder.group({
        budgetAllocation,
        updatedBudget: budgetAllocation.plannedBudget
      });
      if (budgetAllocation.isChecked) {
        this.workPlanItemBudgetList.push(formGroup);
      }
    });
  }

  public doOnInput(budgetAllocation: BudgetAllocation, ngModel: NgModel): void {
    if (ngModel.value && !isNaN(+ngModel.value)) {
      let plannedBudget: ChangeBudget =
        this.workPlanItemModel.recordPageMap.get(budgetAllocation.id);
      if (!plannedBudget) {
        plannedBudget = {
          id: budgetAllocation.id,
          isChecked: !!this.workPlanItemModel.isCheckedAll,
          plannedBudget: ngModel.value,
          organization: budgetAllocation.organization,
          costCenter: budgetAllocation.costCenter,
          coa: budgetAllocation.coa,
          budgetType: budgetAllocation.budgetType,
          currency: budgetAllocation.currency,
          workProgram: budgetAllocation.workProgram
        };
      }
      plannedBudget.plannedBudget = ngModel.value;
      this.workPlanItemModel.recordPageMap.set(plannedBudget.id, plannedBudget);
      this.setError(budgetAllocation);
      if (plannedBudget.isChecked) {
        this.totalBudget = 0;
        this.workPlanItemModel.recordPageMap.forEach(workPlanItemModel => {
          this.totalBudget += +workPlanItemModel.plannedBudget;
        });
      }
    } else {
      this.setError(budgetAllocation);
    }
  }

  public setError(
    budgetAllocation: BudgetAllocation,
    isCheckedDefault = false
  ): void {
    if (
      !isNaN(
        +this.workPlanItemModel.recordPageMap.get(budgetAllocation.id)
          .plannedBudget
      )
    ) {
      let availablePlanned: number;
      const { plannedBudget: plannedBudget, isChecked } =
        this.workPlanItemModel.recordPageMap.get(budgetAllocation.id);

      availablePlanned = this.getAvailablePlanned(budgetAllocation);

      if (plannedBudget > availablePlanned && (isCheckedDefault || isChecked)) {
        this.errorMapper.set(
          budgetAllocation.id,
          this.global.translateService.instant('app.validation.max')
        );
        this.budgetTempId = budgetAllocation.id; // Custom BJB
        this.isNoError = false;
      } else if (plannedBudget <= 0 && (isCheckedDefault || isChecked)) {
        this.errorMapper.set(
          budgetAllocation.id,
          this.global.translateService.instant('app.validation.min')
        );
        this.budgetTempId = budgetAllocation.id; // Custom BJB
        this.isNoError = false;
      } else {
        this.errorMapper.delete(budgetAllocation.id);
        this.isNoError = true;
      }
    } else {
      this.errorMapper.set(
        budgetAllocation.id,
        this.global.translateService.instant('work-plan.msg.error.isNotNumber')
      );
      this.isNoError = false;
    }
  }

  public handleFocusOut(event: any): void {
    if (!isNaN(+event.target.value)) {
      const uConvertMoneyValue =
        event.target.value === ''
          ? '0'
          : this.global.converterService.uConvertMoney(event.target.value);
      event.target.value =
        this.global.converterService.convertMoney(uConvertMoneyValue);
    }
  }

  public handleFocusIn(event: any): void {
    const uConvertMoneyValue = this.global.converterService.uConvertMoney(
      event.target.value
    );
    event.target.value = uConvertMoneyValue;
  }

  public doSubmit(): void {
    this.doCheckFormGroupValid();
    if (
      this.formGroup.valid &&
      (this.formGroupBudget.valid || this.isGenerateTableBudget) &&
      this.errorMapper.size === 0 &&
      this.totalBudget === this.formGroup.value.totalPrice && this.isNoError
    ) {
      this.setWorkPlanItemBudgetList();
      this.onChange.emit(this.formGroup.value);
      this.totalBudget = 0;
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public doSort(sortField: string): void {
    this.tableResponse.setSortField(sortField);
    this.tableResponse.reload();
  }
}
