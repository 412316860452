import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { AppPopupProgressBarDownloadComponent } from '../components/app-popup/app-popup-progress-bar-download/app-popup-progress-bar-download.component';
import { ProgressBarDownloadModel } from '../components/app-popup/app-popup-progress-bar-download/progress-bar-download-model';
import { AppPopupService } from '../components/app-popup/app-popup.service';
import { ToastService } from '../components/app-toast/app-toast.service';
import { HttpClientService } from './http-client.service';

@Injectable({ providedIn: 'root' })
export class DownloadService {
  public progressBarDownload = new ProgressBarDownloadModel();
  public httpRequest: Subscription;
  constructor(
    private httpClientService: HttpClientService,
    private toastService: ToastService,
    private appPopupService: AppPopupService
  ) {}

  public download(stringUrl: string, fileName: string): void {
    if (stringUrl) {
      this.httpClientService.get(stringUrl, { responseType: 'blob' }).subscribe(
        response => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(response);
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
        },
        error => {
          error.error.text().then(text => this.toastService.showError(text));
        }
      );
    }
  }

  public downloadToRar(
    stringUrl: string,
    uploadedFileNameList: string[],
    fileName: string
  ): Observable<any> {
    const subject = new Subject();
    this.appPopupService
      .open(AppPopupProgressBarDownloadComponent, {
        model: this.progressBarDownload
      })
      .subscribe(isCancel => {
        if (isCancel) {
          this.httpRequest.unsubscribe();
        }
      });
    this.httpRequest = this.httpClientService
      .post(stringUrl, uploadedFileNameList, {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events'
      })
      .subscribe(
        response => {
          if (response.type === HttpEventType.DownloadProgress) {
            const percentDone = (100 * response.loaded) / response.total;
            this.progressBarDownload.setProgress(percentDone);
          }
          if (response.type === HttpEventType.Response) {
            setTimeout(() => {
              const a = document.createElement('a');
              a.href = URL.createObjectURL(response.body);
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
            }, 500);
            subject.next(response);
          }
        },
        error => {
          error.error.text().then(text => this.toastService.showError(text));
        }
      );
    return subject;
  }
}
