import { Contract } from '../../core/bean/contract';
import { ContractCancelation } from '../../core/bean/contract-cancelation';
import { ProcurementFines } from '../../core/bean/procurement-fines';
import { ProcurementGuarantee } from '../../core/bean/procurement-guarantee';
import { ProcurementSow } from '../../core/bean/procurement-sow';
import { FileObject } from '../../core/components/upload';

export class ContractRequestRequest {
  contract: Contract = new Contract();
  isSaveDraft: boolean;
  procurementSowList: ProcurementSow[] = [];
  procurementFinesList: ProcurementFines[] = [];
  procurementGuaranteeList: ProcurementGuarantee[] = [];
  fileList: FileObject[] = [];
  contractCanceled: ContractCancelation = new ContractCancelation();
}
