<app-landing-page-card
  vectorClassName="pvc pv-key"
  header="Create New Password"
> 
<div *ngIf="tokenValid">
  <p>Please enter your new password</p>
  <form [formGroup]="formGroup" class="text-left">
    <div class="form-group">
      <label for="password">New Password</label>
      <app-text-field formControlName="password" type="password" size="XL">
      </app-text-field>
    </div>
    <div class="form-group">
      <label for="confirmPassword">Retype New Password</label>
      <app-text-field
        formControlName="confirmPassword"
        type="password"
        size="XL"
      ></app-text-field>
    </div>
    <div class="button-group full-button">
      <app-button (onClick)="doSavePassword()">Save My Password</app-button>
    </div>
  </form>
</div>
<div *ngIf="!tokenValid">
  <h4><span style="color:#cc0000">Invalid token!</span></h4>
</div>

</app-landing-page-card>



