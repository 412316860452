<app-popup
  header="app-catalog-info.popup.location.title"
  [isLoading]="formLoading"
>
  <form class="form-horizontal ml-4 mr-4" [formGroup]="formGroup" novalidate>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><em class="fas fa-search"></em></span>
      </div>
      <div class="col p-0 mr-2">
        <app-text-field
          formControlName="keywordLocation"
          size="XL"
          placeholder="{{
            'app-catalog-info.popup.placeholder.searchLocation' | translate
          }}"
          (onChange)="onInputSearch($event)"
        >
        </app-text-field>
      </div>
    </div>
    <br />

    <div
      [ngStyle]="{
        height: '351px',
        'overflow-y': 'scroll',
        'overflow-x': 'hidden'
      }"
    >
      <div class="catalog-info-location-checkbox">
        <!-- START CHECKBOX -->
        <!-- static checkbox -->
        <ul class="multi is-inline-check-box">
          <li
            class="custom-control custom-checkbox custom-control-inline col-sm-3 mb-2"
            *ngFor="let staticVal of locationDefChecklist; index as i"
          >
            <input
              type="checkbox"
              class="custom-control-input checkbox-primary"
              id="{{ generatedId + staticVal.name + i }}"
              (change)="handleChange($event, staticVal.name)"
              [checked]="staticVal.isChecked"
              [value]="staticVal.codeList"
            />
            <label
              class="custom-control-label cursor-pointer"
              for="{{ generatedId + staticVal.name + i }}"
              [title]="staticVal.name"
              ><span maxLine="1">{{ staticVal.name }}</span></label
            >
          </li>
        </ul>

        <!-- generated checkbox -->
        <ng-container *ngFor="let char of charList">
          <h5 *ngIf="getGroupedList(dynamicRegionList, char).length > 0">
            {{ char }}
          </h5>
          <ul
            class="multi is-inline-check-box"
            *ngIf="getGroupedList(dynamicRegionList, char).length > 0"
          >
            <li
              class="custom-control custom-checkbox custom-control-inline col-sm-3 mb-2"
              *ngFor="
                let val of getGroupedList(dynamicRegionList, char);
                index as i
              "
            >
              <ng-container *ngIf="isMatchGroup(val.name, char)">
                <!-- val.name.charAt(0).toUpperCase() === char-->
                <input
                  type="checkbox"
                  class="custom-control-input checkbox-primary"
                  id="{{ generatedId + val.code + i }}"
                  (change)="handleChange($event, val.name)"
                  [checked]="val.isChecked"
                  [value]="val.code"
                />
                <label
                  class="custom-control-label cursor-pointer"
                  for="{{ generatedId + val.code + i }}"
                  [title]="val.name"
                  ><span maxLine="1">{{ val.name }}</span>
                </label>
              </ng-container>
            </li>
          </ul>
        </ng-container>
        <!-- END CHECKBOX -->
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" [outline]="true" (onClick)="doReset()">
          {{ 'app.button.reset' | translate }}</app-button
        >
        <app-button (onClick)="doApply()">
          {{ 'app.button.apply' | translate }}</app-button
        >
      </div>
    </ng-template>
  </form>
  <!-- </div> -->
</app-popup>
