import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Organization } from 'src/app/core/bean/organization';
import { Procurement } from 'src/app/core/bean/procurement';
import { ProcurementWorklistStatus } from 'src/app/core/bean/procurement-worklist-status';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { AanwijzingOptionListResponse } from './response/aanwijzing-option-list.response';

@Component({
  templateUrl: './aanwijzing.component.html'
})
export class AanwijzingComponent extends BaseModuleComponent implements OnInit {
  public tableResponse: TableResponseModel<Procurement>;
  public procurementWorklistStatusOptionList: OptionListModel<ProcurementWorklistStatus> =
    new OptionListModel(true);
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);

  constructor(translateService: TranslateService) {
    super('aanwijzing', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.buildSetFormGroup();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.procurementNumber',
        plugins: {
          name: 'hyperlink',
          className: 'white-space-normal',
          onClick: this.onClick.bind(this)
        }
      },
      {
        field: 'procurementDate',
        header: 'table.column.procurementDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'name',
        header: 'table.column.procurementName',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'badge',
            field: 'isRepeat',
            pill: false,
            className: 'badge-catalog badge-danger ml-3',
            colorMap: {
              RE_HELD: 'DANGER'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.row.record.isRepeat) {
                  const RE_HELD: string = this.translateService.instant(
                    'dynamic-master-attribute.procurementFlag.reHeld'
                  );
                  return RE_HELD;
                }
              }
            }
          },
          {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData): void => {
              if (!tablePluginData.row.record.isRepeat) {
                tablePluginData.element.children[1].remove();
              }
            }
          }
        ]
      },
      {
        field: 'organization.name',
        header: 'table.column.department'
      },
      {
        field: 'procurementMethod.name',
        header: 'table.column.procurementMethod'
      },
      {
        field: 'procurementWorklistStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            DRAFT: 'SECONDARY',
            NEW: 'INFO',
            REVISION: 'FEEDBACK',
            WAITING_APPROVAL: 'WARNING',
            ON_PROGRESS: 'PROGRESS',
            DONE: 'SUCCESS',
            CLOSED: 'DANGER',
            CANCELED: 'DANGER',
            HOLD: 'DANGER'
          },
          colorField: 'procurementWorklistStatus.code'
        }
      }
    ]);
  }

  public buildSetFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      organizationList: [null],
      statusList: [null]
    });

    this.httpClientService
      .get<any>('/aanwijzing/get-option-list', {})
      .subscribe((response: AanwijzingOptionListResponse) => {
        this.procurementWorklistStatusOptionList.setRequestValues(
          response.procurementWorklistStatusList
        );
        this.organizationOptionList.setRequestValues(response.organizationList);

        this.setStateReady();
      });
  }

  public onChangeFilter(event: any): void {
    this.log.debug(event);
    const customData = this.formGroup.value;
    customData.dateFrom = this.formGroup.value.date?.from || null;
    customData.dateTo = this.formGroup.value.date?.to || null;

    this.tableResponse.setCustomData(customData);
    this.tableResponse.reload();
  }

  public onClick(procurement: Procurement): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('procurementId', procurement.id);
    this.global.routerParams.set('urlBackOutside', '/pages/aanwijzing');
    this.global.routerParams.set('todo', 'edit');
    this.router.navigate(['/pages/aanwijzing/edit']);
  }
}
