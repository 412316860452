import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppFlagNewComponent } from './app-flag-new.component';
@NgModule({
  imports: [VendorModule],
  declarations: [AppFlagNewComponent],
  entryComponents: [AppFlagNewComponent],
  exports: [AppFlagNewComponent]
})
export class AppFlagNewModule {}
