<app-layout [isLoading]="formLoading">
  <ng-template #additionalTitle>
    <app-badge
      class="cml-2"
      *ngIf="
        rfpVendor?.rfpResponseStatus?.code ===
        global.appConstant.pm.RFP_STATUS_WAITING_RESPONSE
      "
      color="GOOD"
    >
      {{ 'dynamic-master-attribute.rfpStatus.waitingResponse' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        rfpVendor?.rfpResponseStatus?.code ===
        global.appConstant.pm.RFP_STATUS_DRAFT
      "
      color="SECONDARY"
    >
      {{ 'dynamic-master-attribute.rfpStatus.draft' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        rfpVendor?.rfpResponseStatus?.code ===
        global.appConstant.pm.RFP_STATUS_SUBMITTED
      "
      color="SUCCESS"
    >
      {{ 'dynamic-master-attribute.rfpStatus.submitted' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        rfpVendor?.rfpResponseStatus?.code ===
        global.appConstant.pm.RFP_STATUS_DONE
      "
      color="SUCCESS"
    >
      {{ 'dynamic-master-attribute.rfpStatus.done' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        rfpVendor?.rfpResponseStatus?.code ===
          global.appConstant.pm.RFP_STATUS_CANCELED
      "
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.rfpStatus.canceled' | translate }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-alert-x
          *ngIf="
            rfpVendor?.rfpResponseStatus?.code ===
            global.appConstant.pm.RFP_STATUS_SUBMITTED
          "
          color="INFO"
          [isShowCloseButton]="true"
          >Your response is being evaluated, you have submitted this procurement
          on
          {{
            rfpVendor.submittedDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}</app-alert-x
        >
        <app-alert-x
          *ngIf="
            rfpVendor?.rfpResponseStatus?.code ===
              global.appConstant.pm.RFP_STATUS_DONE &&
            rfpVendor?.scoringStatus?.code ===
              global.appConstant.pm.SCORING_STATUS_NOT_PASSED
          "
          color="DANGER"
          [isShowCloseButton]="true"
          >Thank you for your participation, we decided to not continue with
          your company for this procurement</app-alert-x
        >
        <app-alert-x
          *ngIf="
            rfpVendor?.rfpResponseStatus?.code ===
            global.appConstant.pm.RFP_STATUS_CANCELED
          "
          color="DANGER"
          [isShowCloseButton]="true"
          >This process canceled by
          {{ rfpResponse.rfpCancellation.cancelByUser.name }}
          at
          {{
            rfpResponse.rfpCancellation.cancelDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}
          the cancellation reason is
          {{ rfpResponse.rfpCancellation.note }}</app-alert-x
        >
        <app-alert-icon
          *ngIf="
            rfpVendor?.rfpResponseStatus?.code ===
              global.appConstant.pm.RFP_STATUS_DONE &&
            rfpVendor?.scoringStatus?.code ===
              global.appConstant.pm.SCORING_STATUS_PASSED
          "
          color="INFO"
          icon="CONFETTI"
          class="alert-icon-center alert-icon"
          [isShowCloseButton]="true"
        >
          <strong style="display: grid">
            <span class="pb-3"
              >Congratulation you are passing the criteria for this procurement.
            </span>
            <span>Please wait for further information.</span>
          </strong>
        </app-alert-icon>
        <app-card
          header="rfp-response.detail.general.title"
          tips="rfp-response.detail.general.tips"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfp.form.rfpNumber' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="code"> </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{ 'rfp.form.title' | translate }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="title"> </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfp.form.responsePeriod' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-date-picker-x formControlName="date" [range]="true">
              </app-date-picker-x>
            </div>
          </div>
        </app-card>

        <app-item-request
          [objectList]="
            procurementItemList && procurementItemList.length > 0
              ? procurementItemList
              : null
          "
          [isShowPrice]="false"
          isView="true"
          [itemTypeList]="rfpResponse.itemTypeList"
        ></app-item-request>

        <app-card
          header="rfp-response.detail.proposalCriteria.title"
          tips="rfp-response.detail.proposalCriteriatips"
        >
          <app-table-xx
            #selectorProposalCriteria
            [model]="tableResponseProposalCriteria"
            *ngIf="!formLoading"
          >
          </app-table-xx>
        </app-card>

        <app-card
          header="rfp-response.detail.responseDocument.title"
          tips="rfp-response.detail.responseDocument.tips"
        >
          <app-alert-x *ngIf="todo === 'edit'">
            {{ 'rfp-response.message.info' | translate }}
          </app-alert-x>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfp-response.form.template' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              <app-upload-x
                formControlName="file"
                [fileUploader]="fileUploader"
              >
              </app-upload-x>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfp-response.form.note' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              <app-text-area size="XL" formControlName="note" maxLength="255">
              </app-text-area>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfp-response.form.uploadResponse' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              <app-upload-x
                formControlName="responseFile"
                [fileUploader]="fileUploaderResponse"
                (onChange)="doSetMarkAsDirty()"
              >
              </app-upload-x>
            </div>
          </div>
        </app-card>
        <div class="floating-button mt-5" *ngIf="todo !== 'view'">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div
                class="button-group button-group-center"
                *ngIf="todo !== 'view'"
              >
                <app-button
                  color="LIGHT"
                  [outline]="true"
                  (onClick)="doSave()"
                  [disabled]="formSaving"
                  >{{ 'app.button.save' | translate }}
                </app-button>
                <app-button (onClick)="doSubmit()" [disabled]="formSaving">{{
                  'app.button.submit' | translate
                }}</app-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
