<app-popup
  header="open-quotation.popup.vendorQuotationDetail"
  [isLoading]="formLoading"
>
  <app-table-xx
    [model]="tableResponseQuotationItem"
    [isShowSearch]="false"
    [isShowPagination]="false"
    stringUrl="/open-quotation/index-quotation-item"
  ></app-table-xx>
</app-popup>
