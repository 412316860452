import { CurrencyPipe } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartTooltipItem } from 'chart.js';
import { ChartModel } from '../../../../../core/components/app-chart/models/chart-model';
import { AppPopupService } from '../../../../../core/components/app-popup/app-popup.service';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetBestSpendingByDivisionModel } from './widget-best-spending-by-division-model';
import { WidgetBestSpendingByDivisionPopupComponent } from './widget-best-spending-by-division-popup.component';
import { WidgetBestSpendingByDivisionRequest } from './widget-best-spending-by-division-request';
import { WidgetBestSpendingByDivisionResponse } from './widget-best-spending-by-division-response';

@Component({
  selector: 'dasboard-widget-best-spending-by-division',
  templateUrl: './widget-best-spending-by-division.component.html',
  styleUrls: ['./widget-best-spending-by-division.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetBestSpendingByDivisionComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-best-spending-by-division';
  public moneyFormatTranslateKeyList: Array<string>;
  public widgetBestSpendingByDivisionRequest: WidgetBestSpendingByDivisionRequest =
    new WidgetBestSpendingByDivisionRequest();
  public widgetBestSpendingByDivisionModelList: WidgetBestSpendingByDivisionModel[] =
    [];
  public widgetBestSpendingByDivisionResponse: WidgetBestSpendingByDivisionResponse;
  public totalSpending: number;
  public modelDefaultList: any[] = [];
  public chartModel: ChartModel;
  public CURRENCY_DIGITS_INFO: string;

  constructor(
    public translateService: TranslateService,
    public currencyPipe: CurrencyPipe,
    public appPopupService: AppPopupService
  ) {
    super('dashboard-widget-best-spending-by-division');
  }

  public onInit(): void {
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
    this.setStateMoneyFormatTranslateKeyList();
    this.setRequest();
    this.setFormGroup();
  }

  private setStateMoneyFormatTranslateKeyList(): void {
    this.moneyFormatTranslateKeyList = [
      this.global.translateService.instant('app.moneyFormat.thousand'),
      this.global.translateService.instant('app.moneyFormat.million'),
      this.global.translateService.instant('app.moneyFormat.billion'),
      this.global.translateService.instant('app.moneyFormat.trillion'),
      this.global.translateService.instant('app.moneyFormat.quadrillion'),
      this.global.translateService.instant('app.moneyFormat.quantillion'),
      this.global.translateService.instant('app.moneyFormat.sextillion')
    ];
  }

  private setRequest(): void {
    const req = new WidgetBestSpendingByDivisionRequest();
    req.year = new Date().getFullYear();
    req.sortBy = 'spendingAmount DESC';
    req.maxResult = 5;
    req.ignoreZero = false;
    this.widgetBestSpendingByDivisionRequest = req;
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<WidgetBestSpendingByDivisionModel[]>(
        '/widget-best-spending-by-division/index',
        this.widgetBestSpendingByDivisionRequest
      )
      .subscribe(
        (
          widgetBestSpendingByDivisionResponse: WidgetBestSpendingByDivisionResponse
        ) => {
          this.widgetBestSpendingByDivisionResponse = widgetBestSpendingByDivisionResponse;
          if (this.widgetBestSpendingByDivisionResponse.widgetBestSpendingByDivisonModelList) {
            if (this.widgetBestSpendingByDivisionRequest.ignoreZero) {
              this.widgetBestSpendingByDivisionModelList =
                this.widgetBestSpendingByDivisionResponse.widgetBestSpendingByDivisonModelList.filter(
                  model => model.spendingAmount
                );
            } else {
              this.widgetBestSpendingByDivisionModelList =
                this.widgetBestSpendingByDivisionResponse.widgetBestSpendingByDivisonModelList;
            }

            this.setChartModel();
          }
          this.setStateReady();
        }
      );
  }

  public setDataGroup(): void {
    this.totalSpending = 0;
    this.widgetBestSpendingByDivisionModelList.forEach(
      widgetBestSpendingByDivisionModel => {
        this.totalSpending += widgetBestSpendingByDivisionModel.spendingAmount;
      }
    );
    this.modelDefaultList = [
      {
        code: this.translateService.instant(
          this.moduleCode + '.label.spending'
        ),
        total: this.global.converterService.convertToShortMoney(
          this.totalSpending || 0,
          this.moneyFormatTranslateKeyList
        ) as string,
        color: '#14B1AB'
      }
    ];
  }

  public setChartModel(): void {
    this.setDataGroup();
    let labels = [];
    const dataSetLabels = [];
    const dataSets = [];

    if (labels.length === 0) {
      labels = [...this.widgetBestSpendingByDivisionModelList].map(data =>
        data.divisionName.length > 15
          ? data.divisionName.slice(0, 15) + '...'
          : data.divisionName
      );
    }

    dataSets.push({
      data: [...this.widgetBestSpendingByDivisionModelList].map(
        data => data.spendingAmount
      )
    });
    dataSetLabels.push(
      this.translateService.instant(this.moduleCode + '.label.spending')
    );

    const CURRENCY_PRECISSION_SCALE =
      this.global.appConstant.core.CURRENCY_PRECISSION_SCALE;
    const CURRENCY_DIGITS_INFO = `0.${CURRENCY_PRECISSION_SCALE}-${CURRENCY_PRECISSION_SCALE}`;
    this.chartModel = new ChartModel('bar', labels, dataSets, dataSetLabels, {
      legend: {
        display: false
      },
      defaultColor: this.modelDefaultList.map(model => model.color),
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 500000000,
              callback: (value): string => {
                return this.global.converterService.convertToShortMoneyChart(
                  value
                ) as string;
              }
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              display: false
            }
          }
        ]
      },
      tooltips: {
        mode: 'nearest',
        xPadding: 20,
        yPadding: 20,
        titleFontStyle: 'font-weight: normal; padding: 20px',
        bodyFontStyle: 'font-weight: bold',
        footerFontStyle: 'font-weight: normal',
        callbacks: {
          title: (item: ChartTooltipItem[]): string => {
            return this.widgetBestSpendingByDivisionModelList[item[0].index]
              .divisionName;
          },
          label: () => null,
          afterBody: (item: Chart.ChartTooltipItem[]): string => {
            const data =
              this.widgetBestSpendingByDivisionModelList[item[0].index];
            const body = this.translateService.instant(
              this.moduleCode + '.tooltip.spending'
            );
            return `${body +
              ' ' +
              this.currencyPipe.transform(
                data.spendingAmount,
                '',
                '',
                CURRENCY_DIGITS_INFO
              )
              + ' ' + (this.widgetBestSpendingByDivisionResponse.currencyCode || 'IDR')}`;
          }
        }
      }
    });
  }

  public doChangeFilter(): void {
    this.appPopupService
      .open(
        WidgetBestSpendingByDivisionPopupComponent,
        {
          request: this.widgetBestSpendingByDivisionRequest
        },
        {
          size: 'SM'
        }
      )
      .subscribe((request: WidgetBestSpendingByDivisionRequest) => {
        if (request) {
          this.widgetBestSpendingByDivisionRequest = request;
          this.setStateLoading();
          this.setFormGroup();
        }
      });
  }
}
