<app-layout [isLoading]="formLoading">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-card
        header="refutation-toc.title"
        [isLoading]="formLoading"
        [isSaving]="formSaving"
      >
        <form class="form-horizontal" [formGroup]="formGroup">
          <app-tab formArrayName="refutationTocList" bordered="true">
            <app-tab-item
              header="{{ refutationTOC.value.lang.name }}"
              [formGroupName]="i"
              *ngFor="
                let refutationTOC of formGroup
                  | resolveFormArray: 'refutationTocList';
                let i = index
              "
            >
              <div class="form-group row text-sm-left mt-3">
                <label class="col-sm-3">{{
                  'refutation-toc.form.text' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-wysiwyg formControlName="value" size="XL"> </app-wysiwyg>
                </div>
              </div>
            </app-tab-item>
          </app-tab>
          <div class="button-group button-group-center mt-5">
            <app-button
              color="LIGHT"
              [outline]="true"
              (onClick)="doCancel()"
              [disabled]="formSaving"
            >
              {{ 'app.button.back' | translate }}
            </app-button>
            <app-button (onClick)="doSave()" [disabled]="formSaving">
              {{ 'app.button.save' | translate }}
            </app-button>
          </div>
        </form>
      </app-card>
    </div>
  </div>
</app-layout>
