<app-card [isSaving]="formSaving" header="app-approval-prcs.title.approval">
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    novalidate
    [isLoading]="formLoading"
    *ngIf="!isLoadingSection"
  >
    <div>
      <div class="form-group row">
        <label class="col-sm-3">{{
          'app-approval-prcs.form.requestedDate' | translate
        }}</label>
        <div class="col-sm-4 text-left">
          {{
            formGroup.controls.requestedDate.value
              | date: global.config.parameterModel.datetimeFormatLong
          }}
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3">{{
          'app-approval-prcs.form.status' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-dropdown-select
            formControlName="status"
            [optionList]="statusList"
            (onChange)="onChangeStatus($event)"
          >
            <ng-template #value let-value>
              <em
                *ngIf="value && value.value === 1"
                class="pic pi-thumbs-up-circle"
              ></em>
              <span
                *ngIf="value && value.value === 1"
                style="color: 'var(--success)'"
              >
                {{ value.name }}
              </span>

              <em
                *ngIf="value && value.value === 3"
                class="pic pi-clock-circle"
              ></em>
              <span
                style="color: 'var(--warning)'"
                *ngIf="value && value.value === 3"
              >
                {{ value.name }}
              </span>

              <em
                *ngIf="value && value.value === 2"
                class="pic pi-pencil-circle"
              ></em>
              <span
                style="color: 'var(--primary)'"
                *ngIf="value && value.value === 2"
              >
                {{ value.name }}
              </span>
              <em
                *ngIf="value && value.value === 4"
                class="pic pi-times-circle"
              ></em>
              <span
                style="color: 'var(--danger)'"
                *ngIf="value && value.value === 4"
              >
                {{ value.name }}
              </span>
              <span *ngIf="!value && formGroup.isView">
                {{ '-' }}
              </span>
            </ng-template>
          </app-dropdown-select>
        </div>
      </div>

      <div
        class="form-group row"
        *ngIf="
          formGroup.get('status').value?.value === 2 &&
          reviseConditionList &&
          reviseConditionList.length > 0
        "
      >
        <label class="col-sm-3">{{
          'app-approval-prcs.form.reviseCondition' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-dropdown-select
            formControlName="reviseCondition"
            [optionList]="reviseConditionOptionList"
          >
            <ng-template #value let-value>
              <div *ngIf="value">
                {{ getTranslateKey(value) | translate }}
              </div>
            </ng-template>
            <ng-template #option let-option>
              {{ getTranslateKey(option) | translate }}
            </ng-template>
          </app-dropdown-select>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-3 optional">{{
          'app-approval-prcs.form.note' | translate
        }}</label>
        <div class="col-sm-6 text-left">
          <app-text-area maxLength="512" size="XL" formControlName="note">
          </app-text-area>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3">{{
          'app-approval-prcs.form.document' | translate
        }}</label>
        <div class="col-sm-6 text-left">
          <app-upload-x
            formControlName="fileApproval"
            [fileUploader]="fileUploader"
          ></app-upload-x>
        </div>
      </div>
    </div>
    <div>
      <h4 class="separator">{{
        'app-approval-prcs.title.monitoringApproval' | translate
      }}</h4>
      <app-approval-path-x
        [model]="approvalPath"
        [isApprovalView]="true"
      ></app-approval-path-x>
    </div>
    <div class="mt-4">
      <h4 class="separator">{{
        'app-approval-prcs.header.approvalHistory' | translate
      }}</h4>
      <app-approval-log
        [moduleCode]="referModuleCode"
        [workflowPrcs]="workflowPrcs"
      >
      </app-approval-log>
    </div>
    <div
      class="button-group button-group-center mt-5"
      *ngIf="!approvalModelPrcs?.isViewOnly"
    >
      <app-button
        color="SECONDARY"
        (onClick)="doBack()"
        [disabled]="formSaving"
        >{{ 'app.button.back' | translate }}
      </app-button>
      <app-button
        *ngIf="!formGroup.isView"
        color="PRIMARY"
        (onClick)="doSave()"
        [disabled]="formSaving || !formGroup.value.status"
        >{{ 'app.button.submit' | translate }}
      </app-button>
    </div>
  </form>
</app-card>
