import { Component, Input, Renderer2, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { News } from './../../core/bean/news';
@Component({
  templateUrl: './news-popup.component.html',
  styleUrls: ['./news-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewsPopupComponent extends BaseComponentComponent {
  @Input() news: News;
  constructor(public renderer2: Renderer2) {
    super('news-popup');
  }

  public onInit(): void {
    this.setStateReady();
  }

  public onImageError(event): void {
    this.renderer2.removeChild(event.target.parentElement, event.target);
  }
}
