import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CatalogRegionBulkPrice } from 'src/app/core/bean/catalog-region-bulk-price';
import { Currency } from 'src/app/core/bean/currency';
import { Item } from 'src/app/core/bean/item';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { TabModel } from '../../../core/model/tab/tab-model';
import { ManageCatalogDTO } from '../dto/manage-catalog.dto';
import { PopupRegionBulkPriceItemComponent } from './popup/app-popup-region-bulk-price-item.component';
import Module = require('module');
import { Region } from 'src/app/core/bean/region';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';

@Component({
  templateUrl: './item-contract-detail-xt.component.html'
})
export class ItemContractDetailXtComponent extends BaseModuleComponent {
  // public tableResponseCatalogRegionDetail: TableResponseModel<any>[] = [];
  public module: Module;
  public item: Item;
  public manageCatalogDTO: ManageCatalogDTO = new ManageCatalogDTO();
  public itemContractDetailTab: TabModel;
  public isTodoView;
  public todo;
  public isVendor = false;
  public isOther = false;
  public isGuest = false;
  public isValidItem: boolean;
  public currentDate: Date = new Date();
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );
  public provinceRegionOptionList: OptionListModel<Region> =
  new OptionListModel();
  public provinceRegionOptionListArr: OptionListModel<Region>[] = [];
  public updatedProvinceRegionOptionList: OptionListModel<Region> =
  new OptionListModel();
  public updatedProvinceList: Region[] = [];

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public toastService: ToastService
  ) {
    super('manage-catalog', translateService);
  }

  onInit(): void {
    this.isVendor = this.global.userSession.vendor ? true : false;
    this.isOther = this.isVendor
      ? false
      : this.global.userSession.activeUserRole.role.type ===
        this.global.appConstant.core.ROLE_TYPE_OTHER
      ? true
      : false;
    this.setCatalog();
    this.setOptionList();
    this.buildFormGroup();
    this.setStateReady();
  }

  public setCatalog(): void {
    this.itemContractDetailTab = this.global.routerParams
      .get('tabResponse')
      .tabs.get('ITEM_CONTRACT_DETAIL');
    this.isTodoView = this.global.routerParams.get('tabResponse').isView;
    if (this.isOther) {
      this.isTodoView = true;
    }
    this.todo = this.global.routerParams.get('todo');
    this.manageCatalogDTO = this.global.routerParams.get('manageCatalogDTO');

    this.item = this.manageCatalogDTO.catalog.item;
    this.isValidItem =
      this.global.routerParams.get('isValidItem') === undefined
        ? true
        : this.global.routerParams.get('isValidItem');
    this.manageCatalogDTO.catalog.organization =
      this.manageCatalogDTO.catalogContractList[0].organization;
    if (this.global.routerParams.get('isGuest')) {
      this.isGuest = this.global.routerParams.get('isGuest');
    }
  }

  public setOptionList(): void {
    this.currencyOptionList.setRequestValues(
      this.manageCatalogDTO.currencyList
    );
    this.provinceRegionOptionList.setRequestValues(
      this.manageCatalogDTO.updatedProvinceRegionList
    );
  }

  public buildFormGroup(): void {
    this.itemContractDetailTab.formGroup = this.formBuilder.group({
      catalogRegionList: this.formBuilder.array([])
    });
    if (this.manageCatalogDTO.catalogRegionList && this.manageCatalogDTO.catalogRegionList.length > 0) {
      this.manageCatalogDTO.catalogRegionList.forEach((catalogRegion) => {
        const isAvailable =
          catalogRegion.price ||
          catalogRegion.startDate ||
          catalogRegion.quantityInPackage
            ? true
            : false;
        if ((!this.isVendor && isAvailable) || this.isVendor) {
          const startDate = catalogRegion.startDate
            ? new Date(catalogRegion.startDate)
            : null;
          const endDate = catalogRegion.endDate
            ? new Date(catalogRegion.endDate)
            : null;
          const formGroup = this.formBuilder.group({
            distributionRegionId: [null],
            provinceRegion: [catalogRegion.provinceRegion || null, Validators.required()],
            provinceRegionOptionList: this.provinceRegionOptionList,
            name: [catalogRegion?.provinceRegion?.name || null],
            date: [
              startDate
                ? {
                    from: startDate,
                    to: endDate
                  }
                : null
            ],
            currentDate: this.currentDate,
            endDateValidate: [null],
            price: [
              catalogRegion.price
                ? {
                    price: catalogRegion.price,
                    currency: this.manageCatalogDTO.currencyList[0]
                  }
                : null
            ],
            discount: [catalogRegion.discount || null],
            isBulkPrice: [catalogRegion.isBulkPrice|| false],
            quantityInPackage: [catalogRegion.quantityInPackage || null]
            // catalogRegionDetailList: [catalogRegion.catalogRegionDetailList || []]
          });
  
          // const catalogRegionDetaiLists: CatalogRegionDetail[] =
          //   catalogRegion.catalogRegionDetailList.filter(
          //     (catalogRegionDetail: CatalogRegionDetail) =>
          //       catalogRegionDetail.quantity !== 0
          //   );
          // const isFirstStock =
          //   catalogRegionDetaiLists.length === 0 ? true : false;
  
          if (catalogRegion?.provinceRegion?.id == this.manageCatalogDTO?.vendorAddress?.provinceRegion.id || 
            this.isTodoView == 'view') {
            formGroup.get('provinceRegion').setIsView(true);
          }
          this.catalogRegionList.push(formGroup);
          // this.buildTableResponseCatalogRegionDetail(
          //   catalogRegion.catalogRegionDetailList,
          //   isFirstStock
          // );
          
          if (this.isTodoView) {
            formGroup.setIsView(true);
          }
          if (isAvailable) {
            this.onChangeForm(formGroup);
          }
        }
      });
      this.updateOptionRegionList(null);
    } else {
        if (this.isVendor) {
          const formGroup = this.createSetFormGroup();
          if (this.catalogRegionList.controls.length == 0) {
            formGroup.get('provinceRegion').setIsView(true);
          }
          this.catalogRegionList.push(formGroup);
          this.manageCatalogDTO.catalogRegionList.push(formGroup.value);
          // this.buildTableResponseCatalogRegionDetail(
          //   [],
          //   true
          // );
          if (this.isTodoView) {
            formGroup.setIsView(true);
          }
          this.updateOptionRegionList(null);
        }
    }
    
  }

  public get catalogRegionList(): FormArray {
    return this.itemContractDetailTab.formGroup.get(
      'catalogRegionList'
    ) as FormArray;
  }

  public createSetFormGroup(): FormGroup {
    const formGroup = this.formBuilder.group({
      distributionRegionId: [null],
    
      provinceRegionOptionList: this.provinceRegionOptionList,
      catalogRegionId: [null],
      provinceRegion: [this.catalogRegionList.controls.length == 0 && this.manageCatalogDTO?.vendorAddress ? 
        this.manageCatalogDTO.vendorAddress.provinceRegion : null, Validators.required()],
      provinceRegionOld: [this.catalogRegionList.controls.length == 0 && this.manageCatalogDTO?.vendorAddress ? 
        this.manageCatalogDTO.vendorAddress.provinceRegion : null],
      date: [null],
      currentDate: this.currentDate,
      endDateValidate: [null],
      price: [null],
      discount: [null],
      isBulkPrice: false,
      quantityInPackage: [null]
      // catalogRegionDetailList: [[]]
    });
    return formGroup;
  }

  public doDeleteCatalogRegion(index): void {
    this.manageCatalogDTO.catalogRegionList.splice(index, 1);
    this.catalogRegionList.removeAt(index);
    this.buildFormGroup();
  }

  public doAddCatalogRegion(): void {
    const formGroup = this.createSetFormGroup();
    this.manageCatalogDTO.catalogRegionList.push(formGroup.value);
    this.buildFormGroup();
  }

  public updateOptionRegionList(index: number): void {
    const idProvinceFormList = this.catalogRegionList.controls.map(cr => cr.value.provinceRegion?.id);
    if (this.catalogRegionList.value && this.catalogRegionList.value.length > 0) {
      this.catalogRegionList.controls.forEach((catalogRegionControl) => {
        const provinceRegionList = this.manageCatalogDTO.provinceRegionList;
        const temp: Region[] = provinceRegionList.filter(pr => !idProvinceFormList.includes(pr.id));;
        if(catalogRegionControl.value.provinceRegion) {
          temp.push(catalogRegionControl.value.provinceRegion);
        }
        temp.sort((a, b) => a.name.localeCompare(b.name));
        const updatedProvinceRegionOptionList: OptionListModel<Region> =
        new OptionListModel();
        updatedProvinceRegionOptionList.setRequestValues(temp);
        catalogRegionControl.get('provinceRegionOptionList').patchValue(updatedProvinceRegionOptionList);
      });
      
    }
    if (index && this.catalogRegionList.controls[index] && this.catalogRegionList.controls[index].value.provinceRegion) {
          this.manageCatalogDTO.catalogRegionList[index].provinceRegion = this.catalogRegionList.controls[index].value.provinceRegion;
        }
  }

  public onClickPopupBulkPriceItem(data): void {
    let catalogRegion;
    this.manageCatalogDTO.catalogRegionList.forEach(catalogRegionTemp => {
      if (
        data.value.provinceRegion.id ===
        catalogRegionTemp?.provinceRegion?.id
      ) {
        catalogRegion = catalogRegionTemp;
      }
    });

    if (catalogRegion.price != null && catalogRegion.provinceRegion) {
      const catalogRegionBulkPriceLists: CatalogRegionBulkPrice[] = this
        .manageCatalogDTO.catalogRegionBulkPriceList
        ? this.manageCatalogDTO.catalogRegionBulkPriceList.filter(
            (catalogRegionBulkPrice: CatalogRegionBulkPrice) =>
              catalogRegionBulkPrice.catalogRegion?.provinceRegion?.id ===
              catalogRegion.provinceRegion.id
          )
        : [];

      this.appPopupService
        .open(PopupRegionBulkPriceItemComponent, {
          catalogRegion,
          catalogRegionBulkPriceLists,
          isView: this.isTodoView
        })
        .subscribe((catalogRegionBulkPriceList: CatalogRegionBulkPrice[]) => {
          this.manageCatalogDTO.catalogRegionList.forEach(
            (catalogRegionTemp, index) => {
              if (
                catalogRegionTemp.provinceRegion.id ===
                catalogRegion.provinceRegion.id
              ) {
                catalogRegionBulkPriceList.forEach(catalogRegionBulkPrice => {
                  this.manageCatalogDTO.catalogRegionList[index] =
                    catalogRegionBulkPrice.catalogRegion;
                });
              }
            }
          );

          const catalogRegionBulkPriceListTemp = this.manageCatalogDTO
            .catalogRegionBulkPriceList
            ? this.manageCatalogDTO.catalogRegionBulkPriceList.filter(
                (catalogRegionBulkPrice: CatalogRegionBulkPrice) =>
                  catalogRegionBulkPrice.catalogRegion?.provinceRegion
                    ?.id !== catalogRegion.provinceRegion.id
              )
            : [];
          catalogRegionBulkPriceListTemp.push(...catalogRegionBulkPriceList);
          this.manageCatalogDTO.catalogRegionBulkPriceList =
            catalogRegionBulkPriceListTemp;

          this.global.modalService.confirmation(
            'app.confirmation.saveSuccess.prompt',
            'app.confirmation.saveSuccess.title',
            null,
            null,
            'pvc pv-check-circle',
            false,
            {
              autoCloseInMs: 2000
            }
          );
          this.buildFormGroup();
        });
    } else {
      this.toastService.showWarning(this.translateService.instant('manage-catalog.please.choose.province'));
    }
  }

  // public buildTableResponseCatalogRegionDetail(data, isFirstStock): void {
  //   const isView =
  //     this.todo === 'view' ? true : this.isValidItem ? false : true;

  //   const tableResponse = new TableResponseModel(this.moduleCode, [
  //     {
  //       field: 'provinceRegion.name',
  //       header: 'table.item-contract-detail.column.province'
  //     },
  //     {
  //       header: 'table.item-contract-detail.column.contractedQuantity',
  //       columnList: [
  //         {
  //           field: 'remainingQuantity',
  //           header: 'table.item-contract-detail.column.remainingQuantity',
  //           plugins: {
  //             name: 'text-field',
  //             type: 'integer',
  //             validators: Validators.min(1),
  //             isView: isView ? true : isFirstStock ? true : false,
  //             onChange: this.onChangeQty.bind(
  //               this,
  //               this.tableResponseCatalogRegionDetail.length
  //             )
  //           }
  //         },
  //         {
  //           field: 'quantity',
  //           header: 'table.item-contract-detail.column.firstStock',
  //           plugins: {
  //             name: 'text-field',
  //             type: 'integer',
  //             validators: Validators.min(1),
  //             isView: isView ? true : isFirstStock ? false : true,
  //             onChange: this.onChangeQty.bind(
  //               this,
  //               this.tableResponseCatalogRegionDetail.length
  //             ),
  //             placeholder: '0.00'
  //           }
  //         }
  //       ]
  //     }
  //   ]);
  //   tableResponse.setRecordList(data);
  //   this.tableResponseCatalogRegionDetail.push(tableResponse);
  // }

  // public onChangeQty(record): void {
  //   console.log(record);
  //   // const catalogRegionDetailTemp =
  //   //   this.tableResponseCatalogRegionDetail[i].currentRowChange.row
  //   //     .updatedRecord;
  //   // this.manageCatalogDTO.catalogRegionList.forEach(catalogRegion => {
  //   //   catalogRegion.catalogRegionDetailList.forEach(catalogRegionDetail => {
  //   //     if (
  //   //       catalogRegionDetailTemp.distributionRegionDetail.id ===
  //   //       catalogRegionDetail.distributionRegionDetail.id
  //   //     ) {
  //   //       catalogRegionDetail.remainingQuantity = record;
  //   //       catalogRegionDetail.quantity = record;
  //   //     }
  //   //   });
  //   // });
  //   this.buildFormGroup();
  // }

  public onChangeForm(catalogRegionFormGroup: FormGroup): void {
    const data = catalogRegionFormGroup.value;
    if (
      (data.price !== null && data.price !== '') ||
      (data.date !== null && data.date !== '') ||
      (data.quantityInPackage !== null && data.quantityInPackage !== '')
    ) {
      if (data.price === null || data.price.price === "0") {
          catalogRegionFormGroup.controls.price.setErrors({
            message: this.translateService.instant('app.validation.required')
          });
          catalogRegionFormGroup.controls.price.markAsDirty();
          catalogRegionFormGroup.controls.price.markAsTouched();
          catalogRegionFormGroup
            .get('price')
            .setValidators(Validators.required());
      } else {
        const minPrice = this.item?.minPrice;
        const maxPrice = this.item?.maxPrice;
        if (minPrice && minPrice > data.price?.price) {
          catalogRegionFormGroup.controls.price.setErrors({
            min: true,
            message: this.translateService.instant('app.validation.min')
          });
          catalogRegionFormGroup.controls.price.markAsDirty();
          catalogRegionFormGroup.controls.price.markAsTouched();
          catalogRegionFormGroup
            .get('price')
            .setValidators(Validators.min(minPrice));
        }
        if (maxPrice && maxPrice < data.price?.price) {
          catalogRegionFormGroup.controls.price.setErrors({
            max: true,
            message: this.translateService.instant('app.validation.max')
          });
          catalogRegionFormGroup.controls.price.markAsDirty();
          catalogRegionFormGroup.controls.price.markAsTouched();
          catalogRegionFormGroup
            .get('price')
            .setValidators(Validators.max(maxPrice));
        }
      }

      if (data.price.price !== "0" && data.date === null && (data.quantityInPackage !== null || data.quantityInPackage !== '' || data.quantityInPackage !== "0")) {
        catalogRegionFormGroup.controls.date.setErrors({
          message: this.translateService.instant('app.validation.required')
        });
        catalogRegionFormGroup.controls.date.markAsDirty();
        catalogRegionFormGroup.controls.date.markAsTouched();
        catalogRegionFormGroup.get('date').setValidators(Validators.required());
      }

      if (data.price.price !== "0" && (data.quantityInPackage === null || data.quantityInPackage === '') && data.date !== null) {
        catalogRegionFormGroup.controls.quantityInPackage.setErrors({
          message: this.translateService.instant('app.validation.required')
        });
        catalogRegionFormGroup.controls.quantityInPackage.markAsDirty();
        catalogRegionFormGroup.controls.quantityInPackage.markAsTouched();
        catalogRegionFormGroup
          .get('quantityInPackage')
          .setValidators(Validators.required());
      } else if (data.price.price !== "0" && data.quantityInPackage < 1) {
        catalogRegionFormGroup.controls.quantityInPackage.setErrors({
          min: true,
          message: this.translateService.instant('app.validation.min')
        });
        catalogRegionFormGroup.controls.quantityInPackage.markAsDirty();
        catalogRegionFormGroup.controls.quantityInPackage.markAsTouched();
        catalogRegionFormGroup
          .get('quantityInPackage')
          .setValidators(Validators.min(1));
      }
      this.isValidItem = true;
    } else {
      catalogRegionFormGroup.get('date').clearValidators();
      catalogRegionFormGroup.get('price').clearValidators();
      catalogRegionFormGroup.get('quantityInPackage').clearValidators();
      catalogRegionFormGroup.controls.quantityInPackage.reset();
      catalogRegionFormGroup.controls.date.reset();
      catalogRegionFormGroup.controls.price.reset();
      catalogRegionFormGroup.controls.discount.reset();
      catalogRegionFormGroup.controls.isBulkPrice.setValue(false);
      data.discount = null;
      data.isBulkPrice = false;            
    }
    this.formGroupService.validateAllFormFields(catalogRegionFormGroup);

    this.manageCatalogDTO.catalogRegionList.forEach(catalogRegion => {
      if (data.provinceRegion.id === catalogRegion.provinceRegion?.id) {
        if (data.date === null && (catalogRegion.startDate !== null && catalogRegion.endDate !== null)) {
          catalogRegion.startDate = null;
          catalogRegion.endDate = null;
          catalogRegionFormGroup.get('date').reset();
          catalogRegionFormGroup.controls['date'].clearValidators();
        } else {
          catalogRegion.startDate = data.date?.from;
          catalogRegion.endDate = data.date?.to;
        }
        if (data.price === null && catalogRegion.price !== null) {
          catalogRegion.price = null;
          catalogRegion.currency = null;
          catalogRegionFormGroup.get('price').reset();
          catalogRegionFormGroup.controls['price'].clearValidators();
        } else {
          catalogRegion.price = data.price?.price;
          catalogRegion.currency = data.price?.currency;
        }
        if ((data.quantityInPackage === null || data.quantityInPackage === '') && catalogRegion.quantityInPackage !== null) {
          catalogRegion.quantityInPackage = null;
          catalogRegionFormGroup.get('quantityInPackage').reset();
          catalogRegionFormGroup.controls['quantityInPackage'].clearValidators();
        } else {
          catalogRegion.quantityInPackage = data.quantityInPackage;
        }
        if (data.discount === null && (catalogRegion.discount !== null && catalogRegion.discountedPrice !== null)) {
          catalogRegion.discount = null;
          catalogRegion.discountedPrice = null;
          catalogRegionFormGroup.get('discount').reset();
        } else {
          catalogRegion.discount = data.discount;
          if (catalogRegion.price !== null && catalogRegion.discount !== null) {
            catalogRegion.discountedPrice = catalogRegion.price * (catalogRegion.discount / 100);
          }
        }
        if (data.isBulkPrice === false && catalogRegion.isBulkPrice === true) {
          catalogRegion.isBulkPrice = false;
        } else {
          catalogRegion.isBulkPrice = data.isBulkPrice;
        }

        this.manageCatalogDTO.catalogRegionBulkPriceList.forEach(
          catalogRegionBulkPrice => {
            if (
              catalogRegionBulkPrice.catalogRegion?.provinceRegion?.id ===
              catalogRegion.provinceRegion.id
            ) {
              catalogRegionBulkPrice.catalogRegion = catalogRegion;
            }
          }
        );
        // if (
        //   (data.price === null || data.price === '') &&
        //   (data.date === null || data.date === '') &&
        //   (data.quantityInPackage === null || data.quantityInPackage === '')
        // ) {
        //   // catalogRegion.catalogRegionDetailList.forEach(
        //   //   catalogRegionDetail => {
        //   //     catalogRegionDetail.remainingQuantity = 0;
        //   //     catalogRegionDetail.quantity = 0;
        //   //   }
        //   // );
        // } else {
        //   // if (catalogRegion.catalogRegionDetailList && catalogRegion.catalogRegionDetailList.length == 0) {
        //   //   const tempList: CatalogRegionDetail[] = [];
        //   //   const catalogRegionDetailTemp: CatalogRegionDetail = new CatalogRegionDetail();
        //   //   catalogRegionDetailTemp.id = null;
        //   //   catalogRegionDetailTemp.quantity = 0;
        //   //   catalogRegionDetailTemp.remainingQuantity = 0;
        //   //   catalogRegionDetailTemp.catalogRegion = null;
        //   //   tempList.push(catalogRegionDetailTemp);
        //   //   catalogRegion.catalogRegionDetailList = tempList;
        //   // }
          
        //   // catalogRegion.catalogRegionDetailList.forEach(
        //   //   catalogRegionDetail => {
        //   //     catalogRegionDetail.remainingQuantity = catalogRegionDetail.remainingQuantity ? catalogRegionDetail.remainingQuantity:  0;
        //   //     catalogRegionDetail.quantity = catalogRegionDetail.quantity ? catalogRegionDetail.quantity : 0;
        //   //   }
        //   // );
        // }
      }
    });
  }
}
