import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../../../../../../core/base/angular/base-component.component';
import { OptionListModel } from '../../../../../../../core/model/option-list-model';
import { TableCatalogFilterModel } from './table-catalog-filter-model';
@Component({
  selector: 'app-table-catalog-filter',
  templateUrl: './app-table-catalog-filter.component.html',
  styleUrls: ['./app-table-catalog-filter.component.scss']
})
export class AppTableCatalogFilterComponent extends BaseComponentComponent {
  @Input() model: TableCatalogFilterModel;
  public categoryOptionList: OptionListModel<any> = new OptionListModel(false);
  public catalogAttributeGroupOptionList: OptionListModel<any> = new OptionListModel(false);
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  constructor() {
    super('view-catalog');
  }

  public onInit(): void {
    this.buildFormGroup();
    if (this.model.isWaitingFromServer) {
      this.model.requestValueChanges.subscribe((filterMapper: Map<string, any>) => {
        const categoryList = filterMapper.get('categoryList');
        const catalogAttributeGroupList = filterMapper.get('catalogAttributeGroupList');
        this.categoryOptionList.setRequestValues(categoryList);
        this.catalogAttributeGroupOptionList.setRequestValues(catalogAttributeGroupList);
        this.setStateReady();
      });
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      catalog: [null],
      vendorName: [null],
      categoryList: [null],
      catalogAttributeGroup: [null],
      regionList: [null]
    });
  }

  public doApply(): void {
    this.onChange.emit(this.formGroup.value);
  }

  public doReset(): void {
    this.formGroup.reset();
    this.onChange.emit(null);
  }
}
