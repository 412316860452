import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
@Component({
  selector: 'app-choose',
  templateUrl: './app-choose.component.html',
  styleUrls: ['./app-choose.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: makeProvider(AppChooseComponent)
})
export class AppChooseComponent extends BaseValueAccessorComponent<any> {
  @Input() maxShowString: number;
  @Input() isMultiple = false;

  @Output() onClick: EventEmitter<PointerEvent> = new EventEmitter();

  @ContentChild('tooltipContent') tooltipContentTmpl: TemplateRef<any>;

  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super('app-choose', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    if (this.formControl) {
      this.setFormControlValueToArray();
    }
  }

  get isString(): boolean {
    return typeof this.formControl.value === 'string';
  }

  setFormControlValueToArray(): void {
    if (this.formControl.value === null) {
      this.formControl.patchValue([]);
    }
  }

  public doClick(event: PointerEvent): void {
    this.onClick.emit(event);
  }

  public onKeyUp(event: KeyboardEvent): void {
    this.log.debug(event);
  }

  public onKeyDown(event: KeyboardEvent): void {
    this.log.debug(event);
  }
}
