<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="login-log.title" [isLoading]="formLoading">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <label class="control-label">{{ 'login-log.date.login' |
                  translate }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 text-left">
                <div class="form-group">
                  <label class="mr-3" for="from">{{ 'login-log.date.from' |
                    translate }}</label>
                  <div class="col-md-12 p-0">
                    <app-date-picker size="XL" formControlName="loginDateFrom" maxDate="loginDateTo"> </app-date-picker>
                  </div>
                </div>
              </div>
              <div class="col-md-5 text-left">
                <div class="form-group">
                  <label class="mr-3" for="inputLastName">{{ 'login-log.date.to'
                    | translate }}</label>
                  <div class="col-md-12 p-0">
                    <app-date-picker size="XL" formControlName="loginDateTo" minDate="loginDateFrom"> </app-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <label class="control-label">{{ 'login-log.date.logout' |
                  translate }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-left">
                <div class="form-group">
                  <label class="mr-3" for="inputFirstName">{{
                    'login-log.date.from' | translate }}</label>
                  <div class="col-md-12 p-0">
                    <app-date-picker size="XL" formControlName="logoutDateFrom" maxDate="logoutDateTo">
                    </app-date-picker>
                  </div>
                </div>
              </div>
              <div class="col-md-6 text-left">
                <div class="form-group">
                  <label class="mr-3" for="inputWebsite">{{ 'login-log.date.to'
                    | translate }}</label>
                  <div class="col-md-12 p-0">
                    <app-date-picker size="XL" formControlName="logoutDateTo" minDate="logoutDateFrom">
                    </app-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button-group button-group-right button-group-icon-text mt-5">
          <app-button mode="BYPASS" (onClick)="doReset()"><em class="fas fa-sync-alt"></em>{{ 'app.button.reset' |
            translate }}
          </app-button>
          <app-button mode="BYPASS" (onClick)="doApply()"><em class="fas fa-check"></em>{{ 'app.button.apply' |
            translate }}
          </app-button>
        </div>
      </form>
    </app-card>
    <app-card [isSaving]="formSaving">
      <app-table [model]="tableResponse" [isServerSide]="true" stringUrl="/login-log/index">
        <ng-template #headerButtons>
          <app-button mode="BYPASS" (onClick)="doExport()" [disabled]="isLoading">
            <em class="fas" [ngClass]="!isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'">
            </em>{{ 'app.button.export' | translate }}
          </app-button>
        </ng-template>
        <ng-template #headerFilterTable>
          <div class="app-table-filter-item" [formGroup]="formGroup">
            <label for="organizationName">Organisasi</label>
            <app-choose size="XL" formControlName="organizationName" (onClick)="onChooseOrganization($event)">
            </app-choose>
          </div>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button mode="BYPASS" color="DANGER"
            *ngIf="rowData.statusText === 'Active' && rowData.token !== global.userSession?.token" size="TN"
            class="mr-2" title="{{ 'login-log.tooltip.forceLogout' | translate }}" (onClick)="doForceLogout(rowData)">
            <em class="fas fa-sign-out-alt"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>