import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { UserActionViewComponent } from './user-action-view.component';
import { UserActionComponent } from './user-action.component';
const routes: Routes = [
  { path: '', component: UserActionComponent, data: { breadcrumb: '' } },
  {
    path: 'view',
    component: UserActionViewComponent,
    data: { breadcrumb: 'user-action.breadcrumb.view' }
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [UserActionComponent, UserActionViewComponent]
})
export class UserActionModule {}
