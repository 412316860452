import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';

@Component({
  selector: 'app-binary-tree',
  templateUrl: './app-binary-tree.component.html',
  styleUrls: ['./app-binary-tree.component.scss']
})
export class AppBinaryTreeComponent
  extends BaseComponentComponent
  implements AfterViewInit
{
  @Input('treeList') treeItemList: Array<any>;
  @ContentChild('itemContent') itemContentTemplate: TemplateRef<any>;
  
  public zoomPercentage: number;
  public levelList = [1, 2, 3];
  private treeElement: HTMLElement;
  @ViewChild('contentWrapper') contentWrapper: ElementRef;

  constructor() {
    super('app-committee');
  }

  onInit(): void {
    this.setInitializationState();
  }

  ngAfterViewInit(): void {
    this.treeElement = this.contentWrapper.nativeElement.children.item(0);
  }

  private setInitializationState(): void {
    this.zoomPercentage = 100;
  }

  public doZoom(command: 'in' | 'out'): void {
    if (
      (this.zoomPercentage > 0 && command === 'out') ||
      (this.zoomPercentage < 200 && command === 'in')
    ) {
      this.zoomPercentage =
        command === 'in' ? this.zoomPercentage + 25 : this.zoomPercentage - 25;
      const scale = this.zoomPercentage / 100;
      this.treeElement.style.transform = `scale(${scale})`;
    }
  }

  public doReset(): void {
    this.zoomPercentage = 100;
    this.treeElement.style.transform = 'scale(1)';
  }

  public filterByLevel(level): any[] {
    return this.treeItemList.filter(
      x => (x.committeePosition.level = level + 1)
    );
  }
}
