import { Injectable } from '@angular/core';
import { dispatchTab } from '../actions/dispatch-tab.action';
import * as tabAction from '../actions/tab.action';
import { TabState } from '../interfaces/tab-state.interface';
@Injectable()
export class TabStoreService {
  public tabState: TabState;

  public setState(tabState: TabState): void {
    this.tabState = tabState;
  }

  public dispatch(action: tabAction.All): void {
    dispatchTab(this.tabState, action);
  }
}
