<ng-container *ngIf="ISVIEW || isView; then isViewTmpl; else isNotViewTmpl">
</ng-container>
<ng-template #isNotViewTmpl>
  <div
    class="app-text-field"
    [ngClass]="[elementWidthClassName, type ? type : 'text']"
  >
    <ng-container
        *ngIf="groupText; else elseNotGroupText">
      <div class="input-group">
          <input
            [attr.type]="'text'"
            [attr.class]="'form-control'"
            [attr.type]="appTextFieldType"
            [attr.placeholder]="placeholder ? placeholder : ''"
            [attr.maxLength]="maxLength"
            [attr.min]="min"
            [attr.max]="max"
            [ngClass]="[
              elementHeightClassName,
              isInvalid ? 'is-invalid' : '',
              isValid ? 'is-valid' : '',
              valueAlignmentClassName,
              type === 'currency' || type === 'integer' || type === 'decimal'
                ? 'text-right'
                : (type === 'integer-left' ? 'text-left' : '')
            ]"
            [ngStyle]="{ 'border-color': isInvalid ? '#dc3545' : '' }"
            [formControl]="formControl"
            (change)="onChange.emit($event.target.value)"
            (input)="doOnInput($event)"
            (blur)="onBlur.emit($event.target.value)"
            autofocus="{{ autofocus }}"
          />
          <div class="input-group-append">
            <span class="input-group-text">{{
              groupText
            }}</span>
          </div>
        </div>
    </ng-container>
    <ng-template #elseNotGroupText>
      <input
      [attr.type]="'text'"
      [attr.class]="'form-control'"
      [attr.type]="appTextFieldType"
      [attr.placeholder]="placeholder ? placeholder : ''"
      [attr.maxLength]="maxLength"
      [attr.min]="min"
      [attr.max]="max"
      [ngClass]="[
        elementHeightClassName,
        isInvalid ? 'is-invalid' : '',
        isValid ? 'is-valid' : '',
        valueAlignmentClassName,
        type === 'currency' || type === 'integer' || type === 'decimal'
          ? 'text-right'
          : (type === 'integer-left' ? 'text-left' : '')
      ]"
      [ngStyle]="{ 'border-color': isInvalid ? '#dc3545' : '' }"
      [formControl]="formControl"
      (change)="onChange.emit($event.target.value)"
      (input)="doOnInput($event)"
      (blur)="onBlur.emit($event.target.value)"
      autofocus="{{ autofocus }}"
    />
    </ng-template>
    
    <em class="icon-show-password" [ngStyle]="{ display: 'none' }"></em>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
  <small class="text-danger" *ngIf="!isHideValid && isInvalid">
    {{ errors.message }}
  </small>
</ng-template>
<ng-template #isViewTmpl>
  <div class="position-relative d-inline-block">
    <span>{{
      formControl.value && (type === 'currency' || type === 'decimal')
        ? (formControl.value | currency: '':'':CURRENCY_PRECISSION_SCALE)
        : formControl.value || '-'
    }}</span>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <span class="ml-1" *ngIf="ISVIEW">
    <ng-container *ngTemplateOutlet="flagNewTmpl"></ng-container>
  </span>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [symbolic]="symbolic"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-container *ngIf="!ISVIEW">
        <ng-container *ngTemplateOutlet="flagNewTmpl"></ng-container>
      </ng-container>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>

<ng-template #flagNewTmpl>
  <ng-content select="app-flag-new"></ng-content>
</ng-template>
