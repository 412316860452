import { resolveTableRecords } from '../helpers/resolve-table-records.helper';
import { TableState } from '../model/table-state';
export const changeTablePage = (
  state: TableState,
  payload: { page: number }
) => {
  state.pagination.page = payload.page;
  state.tableRequest.first = (payload.page - 1) * state.pagination.perPage;
  resolveTableRecords(state).then((records: Array<any>) => {
    state.isShowPagination === false
      ? state.addRecords(records)
      : state.setRecords(records);
    state.setStateReady();
  });
};
