import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../../core/shared/shared.module';
import { AppPopupVendorComparisonComponent } from '../app-popup-vendor-comparison/app-popup-vendor-comparison.component';
import { AppTableVendorFilterComponent } from './app-table-vendor-filter/app-table-vendor-filter.component';
import { AppTableVendorComponent } from './app-table-vendor.component';
@NgModule({
  imports: [SharedModule],
  declarations: [AppTableVendorComponent, AppTableVendorFilterComponent, AppPopupVendorComparisonComponent],
  exports: [AppTableVendorComponent, AppPopupVendorComparisonComponent]
})
export class AppTableVendorModule {}
