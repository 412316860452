import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { AppDynamicComponentService } from '../../core/services/app-dynamic-component.service';
import { SharedModule } from '../../core/shared/shared.module';
import { TabBankModule } from '../tab-bank/tab-bank.module';
import { TabBlacklistHistoryModule } from '../tab-blacklist-history/tab-blacklist-history.module';
import { TabCompanyDataModule } from '../tab-company-data/tab-company-data.module';
import { TabCompanyOrganizationModule } from '../tab-company-organization/tab-company-organization.module';
import { TabDocumentModule } from '../tab-document/tab-document.module';
import { TabExpertAndEquipmentModule } from '../tab-expert-and-equipment/tab-expert-and-equipment.module';
import { TabFinancialStatementModule } from '../tab-financial-statement/tab-financial-statement.module';
import { TabGeneralInformationModule } from '../tab-general-information/tab-general-information.module';
import { TabLoginModule } from '../tab-login/tab-login.module';
import { TabMarketingModule } from '../tab-marketing/tab-marketing.module';
import { TabPartnershipModule } from '../tab-partnership/tab-partnership.module';
import { TabPerformanceHistoryModule } from '../tab-performance-history/tab-performance-history.module';
import { TabProcurementHistoryModule } from '../tab-procurement-history/tab-procurement-history.module';
import { TabRepAgentModule } from '../tab-rep-agent/tab-rep-agent.module';
import { TabWanprestasiHistoryModule } from '../tab-wanprestasi-history/tab-wanprestasi-history.module';
import { TabActivityModule } from './../tab-activity/tab-activity.module';
import { TabCertificateTDRHistoryModule } from './../tab-certificate-tdr-history/tab-certificate-tdr-history.module';
import { TabContractModule } from './../tab-contract/tab-contract.module';
import { TabExperienceModule } from './../tab-experience/tab-experience.module';
import { TabSegmentationModule } from './../tab-segmentation/tab-segmentation.module';
import { VendorHistoryDetailHistoryComponent } from './vendor-history-detail-history.component';
import { VendorHistoryDetailPopupPrintComponent } from './vendor-history-detail-popup-print.component';
import { VendorHistoryDetailComponent } from './vendor-history-detail.component';
import { VendorHistoryComponent } from './vendor-history.component';

const routes: Routes = [
  { path: '', component: VendorHistoryComponent, data: { breadcrumb: '' } },
  {
    path: 'detail',
    data: { breadcrumb: 'vendor-history.breadcrumb.detail' },
    children: [
      { path: '', component: VendorHistoryDetailComponent },
      {
        path: 'history',
        component: VendorHistoryDetailHistoryComponent,
        data: { breadcrumb: 'vendor-history.breadcrumb.history' }
      }
    ]
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    TabGeneralInformationModule,
    TabMarketingModule,
    TabLoginModule,
    TabPartnershipModule,
    TabBlacklistHistoryModule,
    TabWanprestasiHistoryModule,
    TabCompanyDataModule,
    TabCompanyOrganizationModule,
    TabBankModule,
    TabExpertAndEquipmentModule,
    TabFinancialStatementModule,
    TabPerformanceHistoryModule,
    TabProcurementHistoryModule,
    TabSegmentationModule,
    TabExperienceModule,
    TabContractModule,
    TabRepAgentModule,
    TabActivityModule,
    TabDocumentModule,
    TabCertificateTDRHistoryModule
  ],
  declarations: [
    VendorHistoryComponent,
    VendorHistoryDetailComponent,
    VendorHistoryDetailHistoryComponent,
    VendorHistoryDetailPopupPrintComponent
  ],
  entryComponents: [VendorHistoryDetailPopupPrintComponent],
  providers: [
    AppDynamicComponentService,
    AccordionService,
    VendorHistoryDetailPopupPrintComponent
  ]
})
export class VendorHistoryModule {}
