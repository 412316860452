import { WorkPlan } from 'src/app/core/bean/work-plan';
import { File } from '../../core/bean/file';
import { Organization } from '../../core/bean/organization';
import { PrinciplePermit } from '../../core/bean/principle-permit';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path';
export class PrinciplePermitResponse {
  principlePermit: PrinciplePermit;
  workPlan: WorkPlan;
  fileList: File[];
  organizationList: Organization[];
  approvalPathResponseModel: ApprovalPathResponseModel;
  isUserApproval: boolean;
  isNumberGenerator: boolean;
}
