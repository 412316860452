<app-popup header="Filter">
  <form [formGroup]="formGroup">
    <div class="form-group">
      <label for="periode">
        {{
        'app-widget-contract-report-statistical.contractPeriod' |
        translate
        }}
      </label>
      <app-combo-box formControlName="period" [optionList]="periodeOptionList" size="XL">
      </app-combo-box>
    </div>
    <div class="form-group">
      <label for="year">
        {{
        'app-widget-contract-report-statistical.year' | translate
        }}
      </label>
      <app-combo-box formControlName="year" [optionList]="yearOptionList" size="XL">
      </app-combo-box>
    </div>
  </form>
  <div class="button-group button-group-right mt-5">
    <app-button color="SECONDARY" (onClick)="doCancel()">
      {{ 'app.button.cancel' | translate }}
    </app-button>
    <app-button color="WARNING" (onClick)="doReset()">
      {{ 'app.button.reset' | translate }}
    </app-button>
    <app-button (onClick)="doApply()">
      {{ 'app.button.apply' | translate }}
    </app-button>
  </div>
</app-popup>