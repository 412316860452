import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from './../../core/shared/shared.module';
import { DistributionRegionEditAddComponent } from './distribution-region-edit-add.component';
import { DistributionRegionComponent } from './distribution-region.component';
const routes: Routes = [
  { path: '', component: DistributionRegionComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: DistributionRegionEditAddComponent,
    data: { breadcrumb: 'distribution-region.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: DistributionRegionEditAddComponent,
    data: { breadcrumb: 'distribution-region.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    DistributionRegionComponent,
    DistributionRegionEditAddComponent
  ]
})
export class DistributionRegionModule {}
