<div class="main-row">
  <div class="row">
    <div class="col-lg-12 mb-4">
      <app-card header="worklist-approval.title" [isLoading]="formLoading">

        <!--<app-worklist-info
          *ngIf="!formLoading"
          (onChange)="doClick($event)"
          [position]="currentPosition"
          isServerSide="true"
          [stringUrl]="'/approval-prcs/get-worklist-info?isWaitingApproval='+isWaitingApproval">
        </app-worklist-info>-->

        <!--<form class="form-horizontal" [formGroup]="formGroup" novalidate>
           <app-fieldset legend="Filter">
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label" for="from">{{
                'worklist-approval.date.requested' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-date-picker
                  formControlName="requestDateFrom"
                  maxDate="requestDateTo"
                ></app-date-picker>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label" for="to">{{
                'worklist-approval.date.limitOfSla' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-date-picker
                  formControlName="requestDateTo"
                  minDate="requestDateFrom"
                ></app-date-picker>
              </div>
            </div>
            <div class="row justify-content-center mt-5">
              <app-button
                mode="BYPASS"
                color="SECONDARY"
                (onClick)="doReset()"
                class="mr-1"
                ><em class="fas fa-sync-alt"></em>
                {{ 'app.button.reset' | translate }}
              </app-button>
              <app-button
                mode="BYPASS"
                color="PRIMARY"
                (onClick)="doApply()"
                class="mr-1"
                ><em class="fas fa-check"></em>
                {{ 'app.button.apply' | translate }}
              </app-button>
            </div>
          </app-fieldset> 
        </form>-->
        <div [formGroup]="formGroup">
          <app-table
            [model]="tableResponse"
            [isServerSide]="true"
            stringUrl="/approval-prcs/index"
          >
            <ng-template #headerFilterTable>
              <!-- <div class="app-table-filter-item">
                <label for="moduleGroup">{{
                  'app.filter.module' | translate
                }}</label>
                <select
                  (change)="doOnChangeModuleGroup()"
                  class="form-control"
                  required
                  formControlName="moduleGroup"
                  aria-label="moduleGroup"
                >
                  <option [ngValue]="null"
                    >{{ 'app.chooseData' | translate }}
                  </option>
                  <option
                    *ngFor="let moduleGroup of moduleGroupList"
                    [ngValue]="moduleGroup"
                  >
                    {{ moduleGroup.name }}
                  </option>
                </select>
              </div> -->
              <div class="app-table-filter-item">
                <label for="organizationName">{{
                  'app.filter.organization' | translate
                }}</label>
                <app-choose
                  size="XL"
                  formControlName="organizationName"
                  (onClick)="doChooseOrganization($event)"
                ></app-choose>
              </div>
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <app-button
                size="TN"
                (onClick)="doEdit(rowData.approvalModelPrcs)"
                *ngIf="!rowData.approvalModelPrcs.isViewOnly"
                title="{{ 'app.tooltip.edit' | translate }}"
              >
                <em class="fas fa-pencil-alt"></em>
              </app-button>
              <app-button
                size="TN"
                (onClick)="doEdit(rowData.approvalModelPrcs)"
                *ngIf="rowData.approvalModelPrcs.isViewOnly"
                title="{{ 'app.tooltip.view' | translate }}"
                mode="BYPASS"
              >
                <em class="fas fa-search"></em>
              </app-button>
            </ng-template>
          </app-table>
        </div>
      </app-card>
    </div>
  </div>
</div>
