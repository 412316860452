import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { FileObject } from 'src/app/core/components/upload';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Region } from '../../core/bean/region';
import { Vendor } from '../../core/bean/vendor';
import { VendorEntity } from '../../core/bean/vendor-entity';
import { VendorExperienceHistory } from '../../core/bean/vendor-experience-history';
import { VendorExperienceSegmentationHistory } from '../../core/bean/vendor-experience-segmentation-history';
import { VendorExperienceTitle } from '../../core/bean/vendor-experience-title';
import { VendorType } from '../../core/bean/vendor-type';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { TabModel } from '../../core/model/tab/tab-model';
import { CompanyProfileServie } from '../company-profile/company-profile.service';
import { CompanyProfileDTO } from '../company-profile/dto/company-profile.dto';
import { TabProfilePartnershipDTO } from './dto/tab-profile-partnership.dto';
import { AppPopupPartnershipComponent } from './popup/app-popup-partnership.component';

@Component({
  templateUrl: './tab-profile-partnership.component.html',
  styles: [
    '.width-company-name { min-width : 250px; white-space: normal ! important }',
    '.segmentation-column-width { white-space: normal ! important; text-align: center; min-width: 300px }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class TabProfilePartnershipComponent extends BaseModuleComponent {
  @ViewChild('selectorVendorPartnership')
  public tableVendorPartnership: AppTableComponent;

  public userId: number;
  public companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public tabProfilePartnershipDTO: TabProfilePartnershipDTO =
    new TabProfilePartnershipDTO();
  public vendor: Vendor = new Vendor();
  public now = new Date().getTime();
  public sessionId = null;
  public vendorTypeList: VendorType[] = [];
  public regionList: Region[] = [];
  public vendorEntityList: VendorEntity[] = [];
  public tabPartnership: TabModel;
  public isVendorPartnership = true;
  public segmentation: string;
  public vendorExperienceSegmentationHistory: VendorExperienceSegmentationHistory;
  public vendorExperienceSegmentationHistoryList: VendorExperienceSegmentationHistory[] =
    [];
  public vendorExperienceTitle: VendorExperienceTitle;
  public vendorPartnershipHistory: VendorExperienceHistory =
    new VendorExperienceHistory();
  public fileData: any;
  public isView: boolean;
  public fromOutside: string;
  public isVendorList: boolean;

  public tableResponse: TableResponseModel<any>;

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public companyProfileService: CompanyProfileServie
  ) {
    super('tab-profile-partnership', translate);
  }

  public onInit(): void {
    this.getDataFromRouterParam();
    if (this.tabPartnership.routerParams.get('tabProfilePartnershipDTO')) {
      this.tabProfilePartnershipDTO = this.tabPartnership.routerParams.get(
        'tabProfilePartnershipDTO'
      );
      this.buildTableResponse();
      this.getAndSetVendorToFormGroup();
    } else {
      this.getDataFromServer();
    }
  }

  public getDataFromServer(): void {
    // tslint:disable-next-line: max-line-length
    this.httpClientService
      .get<TabProfilePartnershipDTO>(
        this.global.userSession.activeUserRole.role.type !==
          this.global.appConstant.core.ROLE_TYPE_VENDOR
          ? '/tab-profile-partnership/index?id=' +
              this.companyProfileDTO.vendorHistory.id +
              '&todo=' +
              this.todo
          : '/tab-profile-partnership/index'
      )
      .subscribe(tabProfilePartnershipDTO => {
        this.tabProfilePartnershipDTO = tabProfilePartnershipDTO;
        this.tabPartnership.routerParams.set(
          'tabProfilePartnershipDTO',
          tabProfilePartnershipDTO
        );
        this.buildFormGroup();
        this.buildTableResponse();
        this.getAndSetVendorToFormGroup();
      });
  }

  public buildTableResponse(): void {
    if (
      this.global.userSession.activeUserRole.role.type !==
        this.global.appConstant.core.ROLE_TYPE_VENDOR &&
      !this.isVendorList &&
      this.companyProfileDTO.vendor.vendorStatus.code ===
        this.global.appConstant.vm.VENDOR_STATUS_ACTIVE
    ) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'partnership',
          header: 'table.column.partnership',
          customClass: 'width-company-name'
        },
        {
          field: 'countryRegion.name',
          header: 'table.column.country'
        },
        {
          field: 'partnershipStatus',
          header: 'table.column.statusPartnership'
        },
        { field: 'projectOwner', header: 'table.column.projectOwner' },
        {
          field: 'projectType',
          header: 'table.column.projectType'
        },
        {
          field: 'segmentationNamesHistory',
          header: 'table.column.segmentation',
          customClass: 'segmentation-column-width'
        },
        {
          field: 'vendorExperienceStatus.name',
          header: 'table.column.experienceStatus'
        },
        {
          field: 'contractNumber',
          header: 'table.column.contractNumber'
        },
        {
          field: 'contractDate',
          header: 'table.column.contractDate',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'contractValue',
          header: 'table.column.contractValue',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'file.fileName',
          header: 'table.column.proofOfContract',
          format: FieldFormatEnum.DownloadableFile,
          urlFile: 'file.uploadedFileName'
        },
        {
          field: 'crudInfo',
          header: 'table.column.status',
          format: FieldFormatEnum.Html,
          customClass: 'text-center'
        }
      ]);
    } else {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'partnership',
          header: 'table.column.partnership',
          customClass: 'width-company-name'
        },
        {
          field: 'countryRegion.name',
          header: 'table.column.country'
        },
        {
          field: 'partnershipStatus',
          header: 'table.column.statusPartnership'
        },
        { field: 'projectOwner', header: 'table.column.projectOwner' },
        {
          field: 'projectType',
          header: 'table.column.projectType'
        },
        {
          field: 'segmentationNamesHistory',
          header: 'table.column.segmentation',
          customClass: 'segmentation-column-width'
        },
        {
          field: 'vendorExperienceStatus.name',
          header: 'table.column.experienceStatus'
        },
        {
          field: 'contractNumber',
          header: 'table.column.contractNumber'
        },
        {
          field: 'contractDate',
          header: 'table.column.contractDate',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'contractValue',
          header: 'table.column.contractValue',
          format: FieldFormatEnum.Currency,
          customClass: 'text-right',
          currencyCodeRef: 'currency.code'
        },
        {
          field: 'file.fileName',
          header: 'table.column.proofOfContract',
          format: FieldFormatEnum.DownloadableFile,
          urlFile: 'file.uploadedFileName'
        }
      ]);
    }
    this.tableResponse.setRecords(
      this.tabProfilePartnershipDTO.partnershipHistoryList
    );
    this.tableResponse.setTotalRecords(
      this.tabProfilePartnershipDTO.partnershipHistoryList.length
    );
  }

  public doAdd(): void {
    const todo = 'add';
    this.appPopupService
      .open(
        AppPopupPartnershipComponent,
        { todo },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(partnership => {
        this.segmentation = '';
        // tslint:disable-next-line: no-shadowed-variable

        this.vendorExperienceSegmentationHistoryList = [];
        partnership.segmentationList.forEach((element, index) => {
          this.vendorExperienceSegmentationHistory =
            new VendorExperienceSegmentationHistory();
          this.vendorExperienceSegmentationHistory.segmentation = element;
          this.vendorExperienceSegmentationHistoryList.push(
            this.vendorExperienceSegmentationHistory
          );
          if (index !== partnership.segmentationList.length - 1) {
            this.segmentation += element.name + ' , ';
          } else {
            this.segmentation += element.name;
          }
        });
        this.vendorExperienceTitle = new VendorExperienceTitle();
        this.vendorExperienceTitle.id = 2;
        partnership.vendorExperienceSegmentationHistoryList =
          this.vendorExperienceSegmentationHistoryList;
        partnership.segmentationNamesHistory = this.segmentation;
        partnership.vendorExperienceTitle = this.vendorExperienceTitle;
        if (partnership.currencyValue !== null) {
          partnership.currency = partnership.currencyValue.currency;
          partnership.contractValue = parseFloat(
            partnership.currencyValue.price
          );
        } else {
          partnership.currency = null;
          partnership.contractValue = null;
        }
        if (partnership.cityRegion !== null) {
          if (partnership.cityRegion.id === undefined) {
            partnership.cityRegion = null;
          }
        }
        const dataPartnership: VendorExperienceHistory = partnership;
        const file = partnership.contractFile;
        if (file !== null && file.length !== 0) {
          const fileObjectList: FileObject[] = [];
          fileObjectList.push(partnership.contractFile[0]);
          dataPartnership.file = file[0].isDeleted
            ? null
            : partnership.contractFile[0].file;
          dataPartnership.fileObjectList = !file[0].isDeleted
            ? fileObjectList
            : file[0].file.id
            ? fileObjectList
            : [];
        } else {
          dataPartnership.file = null;
          dataPartnership.fileObjectList = [];
        }
        this.vendorPartnershipHistory = dataPartnership;
        this.tabProfilePartnershipDTO.partnershipHistoryList.push(
          this.vendorPartnershipHistory
        );
        this.tableResponse.setRecords(
          this.tabProfilePartnershipDTO.partnershipHistoryList
        );
        this.tableResponse.setTotalRecords(
          this.tabProfilePartnershipDTO.partnershipHistoryList.length
        );
        this.tableResponse.reloadClient();
      });
  }

  public doEdit(vendorPartnershipHistoryEdit: VendorExperienceHistory): void {
    const todo = 'edit';
    this.appPopupService
      .open(
        AppPopupPartnershipComponent,
        { todo, vendorPartnershipHistoryEdit },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(partnership => {
        this.tabProfilePartnershipDTO.partnershipHistoryList.forEach(
          (vendorPartnershipHistory, index) => {
            if (vendorPartnershipHistory === vendorPartnershipHistoryEdit) {
              this.segmentation = '';
              // tslint:disable-next-line: no-shadowed-variable
              this.vendorExperienceSegmentationHistoryList = [];

              partnership.segmentationList.forEach((element, index1) => {
                this.vendorExperienceSegmentationHistory =
                  new VendorExperienceSegmentationHistory();
                this.vendorExperienceSegmentationHistory.segmentation = element;
                if (partnership.experienceSegmentationHistoryList !== null) {
                  partnership.experienceSegmentationHistoryList.forEach(
                    data => {
                      if (data.segmentation.code === element.code) {
                        this.vendorExperienceSegmentationHistory.vendorExperienceSegmentationId =
                          data.vendorExperienceSegmentationId;
                      }
                    }
                  );
                }
                this.vendorExperienceSegmentationHistoryList.push(
                  this.vendorExperienceSegmentationHistory
                );
                if (index1 !== partnership.segmentationList.length - 1) {
                  this.segmentation += element.name + ' , ';
                } else {
                  this.segmentation += element.name;
                }
              });
              this.vendorExperienceTitle = new VendorExperienceTitle();
              this.vendorExperienceTitle.id = 2;
              partnership.vendorExperienceSegmentationHistoryList =
                this.vendorExperienceSegmentationHistoryList;
              partnership.segmentationNamesHistory = this.segmentation;
              partnership.vendorExperienceTitle = this.vendorExperienceTitle;
              if (partnership.currencyValue !== null) {
                partnership.currency = partnership.currencyValue.currency;
                partnership.contractValue = parseFloat(
                  partnership.currencyValue.price
                );
              } else {
                partnership.currency = null;
                partnership.contractValue = null;
              }
              if (partnership.cityRegion !== null) {
                if (partnership.cityRegion.id === undefined) {
                  partnership.cityRegion = null;
                }
              }
              const dataPartnership: VendorExperienceHistory = partnership;
              const file = partnership.contractFile;
              if (file !== null && file.length !== 0) {
                const fileObjectList: FileObject[] = [];
                fileObjectList.push(partnership.contractFile[0]);
                dataPartnership.file =
                  partnership.contractFile.length !== undefined
                    ? file[0].isDeleted
                      ? null
                      : partnership.contractFile[0].file
                    : partnership.contractFile;
                dataPartnership.fileObjectList =
                  file.length !== undefined
                    ? !file[0].isDeleted
                      ? fileObjectList
                      : file[0].file.id
                      ? fileObjectList
                      : []
                    : partnership.fileUploader
                    ? partnership.fileUploader.fileObjectList
                    : [];
              } else {
                dataPartnership.file = null;
                dataPartnership.fileObjectList = [];
              }
              this.tabProfilePartnershipDTO.partnershipHistoryList[index] =
                dataPartnership;
            }
          }
        );
        this.tableResponse.setRecords(
          this.tabProfilePartnershipDTO.partnershipHistoryList
        );
        this.tableResponse.setTotalRecords(
          this.tabProfilePartnershipDTO.partnershipHistoryList.length
        );
        this.tableResponse.reloadClient();
      });
  }

  public doDelete(
    vendorPartnershipHistoryDelete: VendorExperienceHistory
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tabProfilePartnershipDTO.partnershipHistoryList.forEach(
            (vendorPartnershipHistory, index) => {
              if (vendorPartnershipHistory === vendorPartnershipHistoryDelete) {
                this.tabProfilePartnershipDTO.partnershipHistoryList.splice(
                  index,
                  1
                );
              }
            }
          );
          this.tableResponse.page.records =
            this.tabProfilePartnershipDTO.partnershipHistoryList;
          this.tableResponse.page.totalRecords =
            this.tabProfilePartnershipDTO.partnershipHistoryList.length;
          this.tableResponse.reloadClient();
        }
      });
  }

  public doView(vendorPartnershipHistoryEdit: VendorExperienceHistory): void {
    const todo = 'detail';
    const vendorPartnershipHistoryList =
      this.tabProfilePartnershipDTO.partnershipHistoryList;
    const vendorPartnershipList = this.tabProfilePartnershipDTO.partnershipList;
    this.appPopupService.open(
      AppPopupPartnershipComponent,
      {
        todo,
        vendorPartnershipHistoryEdit,
        vendorPartnershipHistoryList,
        vendorPartnershipList
      },
      { size: 'lg' }
    );
  }

  public getDataFromRouterParam(): void {
    this.tabPartnership = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_PARTNERSHIP);
    this.isView = this.companyProfileService.getTabResponse().isView;
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.todo = this.global.routerParams.get('todo');
    this.companyProfileDTO = this.global.routerParams.get('companyProfileDTO');
  }

  public buildFormGroup(): void {
    if (Object.keys(this.tabPartnership.formGroup.controls).length > 0) {
      return;
    }
    this.tabPartnership.formGroup = this.formBuilder.group({
      isTableEmpty: [null],
      changeTab: [null]
    });
  }

  public getAndSetVendorToFormGroup(): void {
    this.tabPartnership.formGroup.patchValue(this.companyProfileDTO);
    this.setStateReady();
  }
}
