import { BaseDTO } from '../../../core/base/base-dto';
import { Contract } from '../../../core/bean/contract';
import { ContractDraftParameterValue } from '../../../core/bean/contract-draft-parameter-value';
import { ProcurementSow } from '../../../core/bean/procurement-sow';
import { VendorBank } from '../../../core/bean/vendor-bank';

export class ContractFinalizationDTO extends BaseDTO {
  contract: Contract = new Contract();
  vendorBank: VendorBank = new VendorBank();
  vendorBankList: VendorBank[] = [];
  contractDraftParameterValueList: ContractDraftParameterValue[] = [];
  contractSowList: ProcurementSow[] = [];
  isUserApproval: boolean;
}
