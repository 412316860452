import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CustomValidatorError } from '../../core/custom-validator-error';
export class ProcurementNumberValidator {
  public static procurementNumber(message?: string): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors => {
      const value = abstractControl.value;
      if (value) {
        const regex = /^\w{5}[\/]\w{3}[-]\w{2}/g;
        const isValidNumber = regex.test(String(value).toLowerCase());
        if (!isValidNumber) {
          return CustomValidatorError.create('procurement_number', message);
        }
      }
      return null;
    };
  }
}
