import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';

@Component({
  selector: 'app-qr-code',
  templateUrl: './app-qr-code.component.html',
  styleUrls: ['./app-qr-code.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppQrCodeComponent extends BaseComponentComponent {
  @Input() public barcodeText: string;

  constructor() {
    super('qr-code-engine');
  }

  public onInit(): void {
  }
}
