import { ComponentRef, EventEmitter, Injectable } from '@angular/core';
import { AppDropDownComponent } from './app-drop-down.component';

@Injectable({
  providedIn: 'root'
})
export class ActiveDropDown {
  public componentRef: ComponentRef<AppDropDownComponent>;
  public onClosed: EventEmitter<void> = new EventEmitter();
  public close(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.onClosed.emit();
      this.componentRef = null;
    }
  }
}
