<div
  class="app-budget-filter"
  *ngFor="let budgetFilterTemplateData of budgetFilterTemplateDataList"
>
  <div class="header-wrapper">
    <div class="header-wrapper-left">
      <span class="description">
        {{ 'dashboard-widget-budget-spend.budgetAllocation' | translate }}
      </span>
      <h2>{{ budgetFilterTemplateData.budgetTotal }} IDR</h2>
    </div>
    <div class="header-wrapper-right">
      <ul>
        <li class="capex">
          {{ 'dashboard-widget-budget-spend.capex' | translate }}
        </li>
        <li class="opex">
          {{ 'dashboard-widget-budget-spend.opex' | translate }}
        </li>
      </ul>
    </div>
  </div>
  <div class="bar-wrapper">
    <div
      class="bar bar-left"
      [ngStyle]="{ width: budgetFilterTemplateData.capex.width + '%' }"
    >
      {{ budgetFilterTemplateData.capex.total }}
    </div>
    <div
      class="bar bar-right"
      [ngStyle]="{ width: budgetFilterTemplateData.opex.width + '%' }"
    >
      {{ budgetFilterTemplateData.opex.total }}
    </div>
  </div>
  <div class="filter-wrapper">
    <span
      class="filter"
      (click)="doOpenPopupFilter()"
      (keyup)="onKeyUp($event)"
      title="{{ 'app-budget-filter.title.filter' | translate }}"
    >
      <em class="fas fa-filter mr-2"></em>
      <strong>Filter</strong>
    </span>
    <ul class="filter-tag">
      <li class="filter-tag-item" *ngFor="let tag of tagList">
        <span class="filter-title">{{ tag.title }}</span>
        <span class="filter-text">{{ tag.value }}</span>
        <em
          class="fas fa-times-circle"
          (click)="doDeleteTag(tag.key)"
          (keyup)="onKeyUp($event)"
        ></em>
      </li>
    </ul>
  </div>
</div>
