import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Currency } from '../../core/bean/currency';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementSegQualification } from '../../core/bean/procurement-seg-qualification';
import { Region } from '../../core/bean/region';
import { VendorExperience } from '../../core/bean/vendor-experience';
import { AppTableComponent } from '../../core/components/table/components/app-table/app-table.component';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { ProcurementRegVendorCriteriaResponse } from './procurement-reg-vendor-criteria-response';

@Component({
  templateUrl: './app-popup-criteria-procurement.component.html',
  styleUrls: ['./app-popup-criteria-procurement.component.scss']
})
export class AppPopupCriteriaProcurementComponent extends BaseComponentComponent {
  @Input() public procurement: Procurement;

  public procRegVendorCriteriaResp: ProcurementRegVendorCriteriaResponse;
  public regionOptionList: OptionListModel<Region> = new OptionListModel();
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel();
  public segQualValueCheck: Map<number, boolean>;
  public tableResponse: TableResponseModel<ProcurementSegQualification>;
  public regionList: Region[] = [];
  @ViewChild(AppTableComponent) public table: AppTableComponent;

  public checkedValue: object = {
    isRegionMeet: false,
    isContractValueMeet: false,
    isTotalContractMeet: false,
    isRatingMeet: false,
    isContractDateMeet: false
  };

  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    super('procurement-reg-vendor');
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.buildTableResponse();
    this.doSetFormGroup();
    this.setViewOnly();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      regionList: [null],
      rating: [null],
      contractValue: [null],
      totalContract: [null],
      contractDate: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: '',
        header: '',
        className: 'text-center',
        plugins: {
          name: 'custom-plugin',
          before: (data: TablePluginData): HTMLElement | string => {
            const emELement = document.createElement('em');
            if (this.segQualValueCheck[data.row.record.procurementSeg.id]) {
              emELement.classList.add('pir', 'pi-check-circle');
              emELement.style.fontSize = '1.5rem';
              emELement.style.color = 'var(--success)';
            }
            return emELement;
          }
        },
        isSortable: false
      },
      {
        field: 'procurementSeg.segmentationName',
        header: 'table.column.segmentation'
      },
      {
        field: 'termQualification.name',
        header: 'table.column.termQualification'
      },
      {
        field: 'qualification.name',
        header: 'table.column.qualification'
      }
    ]);
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .get<Response<ProcurementRegVendorCriteriaResponse>>(
        '/procurement-reg-vendor/get-criteria-procurement/' +
          +this.procurement.id
      )
      .subscribe((response: Response<ProcurementRegVendorCriteriaResponse>) => {
        if (response.status === ResponseStatusModel.OK) {
          this.procRegVendorCriteriaResp = response.body;
          this.currencyOptionList.setRequestValues(response.body.currencyList);
          this.regionList =
            this.procRegVendorCriteriaResp.procurementRegionList.map(
              data => data.region
            );

          const vendorRegion: Region = response.body.vendorRegion;
          const vendorExperienceList: VendorExperience[] =
            response.body.vendorExperienceList;
          const vendorRating: number = response.body.vendorRating;
          const totalContractValue = vendorExperienceList.reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue.contractValue,
            0
          );
          this.segQualValueCheck = response.body.segQualValueCheck;
          const procurementSegList = response.body.procurementSegList;
          const procurementSegQualificationList =
            response.body.procurementSegQualificationList;

          this.checkedValue = {
            isRegionMeet:
              this.regionList.length === 0
                ? true
                : this.regionList.findIndex(r => (r.id = vendorRegion.id)) ===
                  -1
                ? false
                : true,
            isContractValueMeet: this.procRegVendorCriteriaResp
              .procurementFilter.contractAmount
              ? this.procRegVendorCriteriaResp.procurementFilter
                  .contractAmount <= totalContractValue
              : true,
            isTotalContractMeet: +this.procRegVendorCriteriaResp
              .procurementFilter.contractNum
              ? +this.procRegVendorCriteriaResp.procurementFilter.contractNum >=
                vendorExperienceList.length
              : true,
            isRatingMeet: this.procRegVendorCriteriaResp.procurementFilter
              .minRating
              ? this.procRegVendorCriteriaResp.procurementFilter.minRating <=
                vendorRating
              : true,
            isContractDateMeet:
              this.procRegVendorCriteriaResp.procurementFilter.toContractDate &&
              this.procRegVendorCriteriaResp.procurementFilter.fromContractDate
                ? vendorExperienceList.length > 0
                  ? this.procRegVendorCriteriaResp.procurementFilter
                      .fromContractDate >=
                      vendorExperienceList[0].contractDate &&
                    this.procRegVendorCriteriaResp.procurementFilter
                      .toContractDate <=
                      vendorExperienceList[vendorExperienceList.length - 1]
                        .contractDate
                  : false
                : true
          };
          const newProcSegQualList: ProcurementSegQualification[] = [];
          procurementSegList.forEach(procSeg => {
            const procSegQualList: ProcurementSegQualification[] =
              procurementSegQualificationList.filter(
                psq => psq.procurementSeg.id === procSeg.id
              );
            var qualification = '';
            procSegQualList.forEach(psq => {
              qualification += psq.qualification.name + ' ';
            });
            procSegQualList[0].procurementSeg.segmentationName =
              procSeg.segmentationName;
            procSegQualList[0].qualification.name = qualification;
            newProcSegQualList.push(procSegQualList[0]);
          });

          this.tableResponse.setRecordList(newProcSegQualList);
          this.tableResponse.reload();

          this.formGroup.patchValue({
            regionList: this.regionList,
            contractValue: {
              price:
                this.procRegVendorCriteriaResp.procurementFilter
                  .contractAmount || 0,
              currency: response.body.currencyList[0]
            },
            totalContract:
              this.procRegVendorCriteriaResp.procurementFilter.contractNum ||
              '-',
            rating: this.procRegVendorCriteriaResp.procurementFilter.minRating,
            contractDate: {
              from: this.procRegVendorCriteriaResp.procurementFilter
                .fromContractDate,
              to: this.procRegVendorCriteriaResp.procurementFilter
                .toContractDate
            }
          });
        }
        this.setStateReady();
      });
  }

  public goToProfile(): void {
    this.activeModal.dismiss();
    this.router.navigate(['/pages/company-profile']);
  }
}
