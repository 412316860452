import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Pr } from 'src/app/core/bean/pr';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from 'src/app/core/components/app-table-x/model/table-response-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Committee } from '../../core/bean/committee';
import { ProposalCriteria } from '../../core/bean/proposal-criteria';

@Component({
  templateUrl: './proposal-criteria.component.html'
})
export class ProposalCriteriaComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  public tableResponse: TableResponseModel<Committee>;

  constructor(translateService: TranslateService) {
    super('proposal-criteria', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    if (this.global.currentLang === 'en') {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'code',
          header: 'table.column.code',
          plugins: 'hyperlink'
        },
        {
          field: 'enName',
          header: 'table.column.name'
        }
      ]);
    } else if (this.global.currentLang === 'id') {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'code',
          header: 'table.column.code',
          plugins: 'hyperlink'
        },
        {
          field: 'idName',
          header: 'table.column.name'
        }
      ]);
    }
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/proposal-criteria/add']);
    this.global.routerParams.set('urlBackOutside', '/pages/proposal-criteria');
  }

  public doClick(proposalCriteria: ProposalCriteria): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('proposalCriteria', proposalCriteria);
    this.global.routerParams.set('urlBackOutside', '/pages/proposal-criteria');
    this.global.routerParams.set('todo', 'edit');
    this.router.navigate(['/pages/proposal-criteria/edit']);
  }

  public doDelete(event): void {
    this.global.modalService
      .newDeleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<Pr[]>>(
              '/proposal-criteria/delete',
              event.checkedRecordList
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }
}
