<ng-container *ngIf="!ISVIEW">
  <div class="app-chosen p-0" [ngClass]="elementWidthClassName">
    <div
      class="form-control app-chosen-label"
      (click)="handleClickAppChosenLabel($event)"
      [ngClass]="[
        elementHeightClassName,
        isInvalid ? 'is-invalid' : '',
        isValid ? 'is-valid' : ''
      ]"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    >
      <app-tagging
        [formControl]="formControl"
        [optionView]="this.optionList.viewPath"
        (onChange)="handleAppTaggingChange()"
      >
        <ul class="d-inline-block">
          <li class="d-inline-block">
            <input
              type="text"
              size="1"
              [attr.id]="generatedId + 'input'"
              (input)="handleInput($event)"
              (focusout)="handleFocusOut($event)"
            />
          </li>
        </ul>
      </app-tagging>
    </div>
    <div class="app-select-container"></div>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
  <small class="text-danger" *ngIf="isInvalid">
    {{ errors.message }}
  </small>
</ng-container>
<ng-container *ngIf="ISVIEW">
  <div class="d-inline-block position-relative" [formGroup]="formGroup">
    <app-tagging
      [formControlName]="formControlName"
      [optionView]="this.optionList.viewPath"
    >
    </app-tagging>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-container>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [symbolic]="symbolic"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
