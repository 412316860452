import { BaseEntity } from '../base/base-entity';
import { BondType } from '../bean/bond-type';
import { File } from '../bean/file';
import { Quotation } from '../bean/quotation';
import { FileObject } from '../components/upload';

export class QuotationBidBondEntity extends BaseEntity {
  fileObjectList: FileObject[] = [];
  quotation: Quotation = new Quotation();
  bondType: BondType = new BondType();
  docFile: File = new File();
  bidBondNumber: string;
  startDate: Date;
  endDate: Date;
  amount: number;
}
