import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalPrcsModule } from '../../core/components/app-approval-prcs/app-approval-prcs.module';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupPerformanceProcurementComponent } from './app-popup-performance-procurement.component';
import { PerformanceProcurementDetailProcurementComponent } from './performance-procurement-detail-procurement.component';
import { PerformanceProcurementDetailComponent } from './performance-procurement-detail.component';
import { PerformanceProcurementEditComponent } from './performance-procurement-edit.component';
import { PerformanceProcurementComponent } from './performance-procurement.component';
const routes = [
  { path: '', component: PerformanceProcurementComponent, data: { breadcrumb: '' } },
  {
    path: 'detail-procurement',
    data: { breadcrumb: 'performance-procurement.breadcrumb.detailProcurement' },
    children: [
      { path: '', component: PerformanceProcurementDetailProcurementComponent },
      { path: 'edit', component: PerformanceProcurementEditComponent, data: { breadcrumb: 'performance-procurement.breadcrumb.edit' } },
      { path: 'detail', component: PerformanceProcurementDetailComponent, data: { breadcrumb: 'performance-procurement.breadcrumb.detail' } }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, AppApprovalPrcsModule],
  declarations: [PerformanceProcurementComponent, PerformanceProcurementDetailProcurementComponent, PerformanceProcurementEditComponent,
    PerformanceProcurementDetailComponent, AppPopupPerformanceProcurementComponent],
  providers: [],
  exports: [AppPopupPerformanceProcurementComponent],
  entryComponents: [AppPopupPerformanceProcurementComponent]
})
export class PerformanceProcurementModule {}
