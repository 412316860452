import { Fines } from 'src/app/core/bean/fines';
import { Guarantee } from 'src/app/core/bean/guarantee';
import { Organization } from 'src/app/core/bean/organization';
import { VendorAddress } from 'src/app/core/bean/vendor-address';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { ItemType } from '../../core/bean/item-type';
import { Order } from '../../core/bean/order';
import { OrderActivityHistory } from '../../core/bean/order-activity-history';
import { OrderActivityStatus } from '../../core/bean/order-activity-status';
import { OrderCancellation } from '../../core/bean/order-cancellation';
import { OrderDoc } from '../../core/bean/order-doc';
import { OrderShipping } from '../../core/bean/order-shipping';
import { OrderShippingItem } from '../../core/bean/order-shipping-item';
import { OrderStatus } from '../../core/bean/order-status';
import { OrderTc } from '../../core/bean/order-tc';
import { PrItemReleased } from '../../core/bean/pr-item-released';
import { Sow } from '../../core/bean/sow';
import { PrItem } from 'src/app/core/bean/pr-item';
import { Currency } from 'src/app/core/bean/currency';

export class OrderResponseResponse {
  order: Order;
  orderTcList: OrderTc[];
  orderDocList: OrderDoc[];
  orderStatusList: OrderStatus[];
  orderShippingList: OrderShipping[];
  orderShippingItemList: OrderShippingItem[];
  orderActivityHistoryList: OrderActivityHistory[];
  orderActivityStatusList: OrderActivityStatus[];
  itemTypeList: ItemType[];
  sowList: Sow[];
  prItemReleasedList: PrItemReleased[];
  orderCancellation: OrderCancellation;
  finesList: Fines[] = [];
  guaranteeList: Guarantee[] = [];
  vendorAddressList: VendorAddress[] = [];
  organization: Organization = new Organization();
  appOfficialReportModel: AppOfficialReportModel = new AppOfficialReportModel();

  prItemList: PrItem[] = [];
  companyCurrency: Currency;
}
