import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ContractReviewer } from 'src/app/core/bean/contract-reviewer';
import { ContractDraft } from 'src/app/core/bean/contract-draft';
import { ContractDraftHistory } from 'src/app/core/bean/contract-draft-history';
import { ContractDraftParameterValue } from 'src/app/core/bean/contract-draft-parameter-value';
import { ContractTemplateParameter } from 'src/app/core/bean/contract-template-parameter';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Contract } from '../../core/bean/contract';
import { ContractTemplate } from '../../core/bean/contract-template';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { ApprovalPathModel } from '../../core/components/app-approval-path';
import { OptionListModel } from '../../core/model/option-list-model';
import { WorkflowPrcs } from './../../core/bean/workflow-prcs';
import { AppPopupContractDraftVersionComponent } from './app-popup-contract-draft-version.component';
import { AppPopupParticipantReviewersComponent } from './app-popup-participant-reviewers.component';
import { ContractDraftAddEditResponse } from './contract-draft-add-edit.response';
import { ContractDraftGenerateRequest } from './contract-draft-generate.request';
import { ContractDraftGenerateResponse } from './contract-draft-generate.response';
import { ContractDraftUpdateRequest } from './contract-draft-update.request';

@Component({
  templateUrl: './contract-draft-edit-add.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./contract-draft-edit-add.component.scss']
})
export class ContractDraftEditAddComponent extends BaseModuleComponent {
  @ViewChild('selectorParameter') public tableParameter: AppTableComponent;
  @ViewChild('selectorCollab') public tableCollab: AppTableComponent;
  @ViewChild('selectorHistoryApproval') tableHistoryApproval: AppTableComponent;

  public tableResponseCollab: TableResponseModel<ContractReviewer>;
  public tableResponseHistoryApproval: TableResponseModel<ContractReviewer>;
  public formGroupCollab: FormGroup = new FormGroup({});
  public contract: Contract = new Contract();
  public contractTemplateList: OptionListModel<ContractTemplate> =
    new OptionListModel(false);
  public url: string;
  public isLoading = true;
  public isParameterReady = false;
  public isParamValueNull = false;
  public procurementVendorId: number;
  public workflowPrcs: WorkflowPrcs;
  public isAllUploaded = false;
  public isNextAccordion = false;
  public objectId: number;
  public contractId: number;
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public contractDraftUpdateRequest: ContractDraftUpdateRequest =
    new ContractDraftUpdateRequest();
  public contractDraftAddEditResponse: ContractDraftAddEditResponse =
    new ContractDraftAddEditResponse();
  public contractDraftGenerateRequest: ContractDraftGenerateRequest =
    new ContractDraftGenerateRequest();
  public approvalPath: ApprovalPathModel<any> = new ApprovalPathModel(
    this.moduleCode,
    'READ'
  );
  public fileUploader: FileUploader = new FileUploader(
    '/contract-draft/',
    'contract-draft.title',
    this.global.appConstant.fileType.DOC_CONTRACT
  );
  public showTables = false;
  public rowData = [];
  public contractTemplateId: number;
  public contractTemplateParameterList: ContractTemplateParameter[] = [];
  public logApprovalCustomData: any;
  public contractTemplate: ContractTemplate;
  public emailList: string[] = [];

  constructor(
    public translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('contract-draft', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setFormGroup();
    this.setIsViewOnly();
  }

  public buildTableResponse(): void {
    this.tableResponseCollab = new TableResponseModel(this.moduleCode, [
      {
        field: 'userType',
        header: 'table.column.user',
        datamap: '{ "1" : "Internal", "2" : "Eksternal", "3" : "PIC Vendor" }'
      },
      { field: 'email', header: 'table.column.email' },
      { field: 'name', header: 'table.column.name' },
      { field: 'position', header: 'table.column.position' }
    ]);

    this.tableResponseHistoryApproval = new TableResponseModel(
      this.moduleCode,
      [
        { field: 'user', header: 'table.column.user' },
        { field: 'description', header: 'table.column.description' },
        { field: 'date', header: 'table.column.date' },
        { field: 'file', header: 'table.column.file' },
        { field: 'level', header: 'table.column.level' },
        { field: 'status', header: 'table.column.status' }
      ]
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      requestNumber: [null],
      contractTemplateList: [null, Validators.required()],
      contractTemplateParamList: this.formBuilder.array([])
    });

    this.formGroupCollab = this.formBuilder.group({
      id: [null],
      uploadFile: [null],
      note: [null],
      file: [null],
      version: [null]
    });
  }

  public get contractTemplateParamList(): FormArray {
    return this.formGroup.get('contractTemplateParamList') as FormArray;
  }

  public setDataFromRouterParams(): void {
    const todoLocalStorage = localStorage.getItem('todo');
    if (todoLocalStorage) {
      this.todo = todoLocalStorage;
    } else {
      this.todo = this.global.routerParams.get('todo');
    }
    this.contract = this.global.routerParams.get('contract');
    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.contractId = this.objectId;
    } else {
      this.contractId = this.contract.id;
    }
    if (this.contract != null) {
      this.contractId = this.contract.id;
      this.workflowPrcs = this.contract.workflowPrcs;
      this.workflowModelPrcs = this.contract.workflowModelPrcs;
    }
  }

  public setIsViewOnly(): void {
    if (this.todo === 'detail' || this.todo === 'view') {
      this.setViewOnly();
      this.formGroupCollab.setIsView(true);
    }
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<ContractDraftAddEditResponse>(
        '/contract-draft/add-edit/' + this.contractId
      )
      .subscribe(
        (contractDraftAddEditResponse: ContractDraftAddEditResponse) => {
          let data = localStorage.getItem('temp') as Object;
          if (data) {
            data = JSON.parse(data.toString());
            this.contractDraftAddEditResponse =
              data as ContractDraftAddEditResponse;
            localStorage.removeItem('temp');
            localStorage.removeItem('todo');
          } else {
            this.contractDraftAddEditResponse = contractDraftAddEditResponse;
          }
          this.contract = this.contractDraftAddEditResponse.contract;
          this.procurementVendorId = this.contract.procurementVendor.id;
          this.contractTemplateList.setRequestValues(
            this.contractDraftAddEditResponse.contractTemplateList
          );
          if (this.objectId != null) {
            this.workflowPrcs = this.contract.workflowPrcs;
            this.workflowModelPrcs = this.contract.workflowModelPrcs;
          }
          this.formGroup.patchValue({
            requestNumber:
              this.contractDraftAddEditResponse.contract.requestNumber,
            contractTemplateList: this.contractDraftAddEditResponse
              .contractDraft
              ? this.contractDraftAddEditResponse.contractDraft.contractTemplate
              : null
          });
          if (
            this.contractDraftAddEditResponse.contractDraftParameterValueList &&
            this.contractDraftAddEditResponse.contractDraftParameterValueList
              .length !== 0
          ) {
            this.contractDraftAddEditResponse.contractDraftParameterValueList.forEach(
              contractDraftParamValue => {
                const formGroup: FormGroup = this.formBuilder.group({
                  id: contractDraftParamValue.id,
                  code: contractDraftParamValue.contractTemplateParameter
                    .contractParameter.code,
                  name: contractDraftParamValue.contractTemplateParameter
                    .contractParameter.name,
                  value: [
                    contractDraftParamValue.contractTemplateParameter
                      .contractParameter.valueType.code ===
                      'DATE_FORMAT_SHORT' ||
                    contractDraftParamValue.contractTemplateParameter
                      .contractParameter.valueType.code === 'DATE_FORMAT_LONG'
                      ? contractDraftParamValue.value
                        ? new Date(contractDraftParamValue.value)
                        : null
                      : this.contract.contractType.code ===
                          this.global.appConstant.cm
                            .CONTRACT_TYPE_ADDENDUM_CONTRACT &&
                        contractDraftParamValue.contractTemplateParameter
                          .contractParameter.code ===
                          this.global.appConstant.cm
                            .CONTRACT_PARAMETER_NOMOR_KONTRAK
                      ? this.contract.fromContract.number
                      : contractDraftParamValue.value,
                    !contractDraftParamValue.contractTemplateParameter.contractParameter.code.includes(
                      'PERUBAHAN'
                    )
                      ? Validators.required()
                      : null
                  ],
                  valueTypeCode:
                    contractDraftParamValue.contractTemplateParameter
                      .contractParameter.valueType.code,
                  contractTemplateParameter:
                    contractDraftParamValue.contractTemplateParameter
                });
                if (this.todo === 'view' || this.todo === 'detail') {
                  formGroup.setIsView(true);
                }
                if (
                  this.contract.contractType.code ===
                    this.global.appConstant.cm
                      .CONTRACT_TYPE_ADDENDUM_CONTRACT &&
                  contractDraftParamValue.contractTemplateParameter
                    .contractParameter.code ===
                    this.global.appConstant.cm.CONTRACT_PARAMETER_NOMOR_KONTRAK
                ) {
                  formGroup.get('value').setIsView(true);
                }
                this.contractTemplateParamList.push(formGroup);
              }
            );
            this.showTables = true;
            this.isParameterReady = true;
          } else {
            this.isParameterReady = true;
          }
          if (this.contractDraftAddEditResponse.contractDraftHistory) {
            const version =
              this.contractDraftAddEditResponse.contractDraftHistory.sequence -
              1;
            this.formGroupCollab.patchValue({
              id: this.contractDraftAddEditResponse.contractDraftHistory.id,
              note: this.contractDraftAddEditResponse.contractDraftHistory.note,
              file: this.contractDraftAddEditResponse.contractDraftHistory
                .generatedFile,
              uploadFile:
                this.contractDraftAddEditResponse.contractDraftHistory
                  .draftFile,
              version
            });
          }

          this.contractDraftAddEditResponse.contractCollabAccessList.forEach(
            collabAccess => {
              this.emailList.push(collabAccess.email);
            }
          );

          this.tableResponseCollab.setRecords(
            this.contractDraftAddEditResponse.contractCollabAccessList
          );
          this.tableResponseCollab.setTotalRecords(
            this.contractDraftAddEditResponse.contractCollabAccessList.length
          );

          this.setStateReady();
        }
      );
  }

  public changeTemplate(contractTemplate: ContractTemplate): void {
    this.showTables = false;
    this.contractTemplate = contractTemplate;
    this.httpClientService
      .get<ContractTemplateParameter[]>(
        '/contract-draft/contract-parameter/' + contractTemplate.id
      )
      .subscribe(
        (contractTemplateParameterList: ContractTemplateParameter[]) => {
          if (this.contractDraftAddEditResponse.contractDraft) {
            this.contractDraftAddEditResponse.contractDraft.contractTemplate =
              contractTemplate;
          } else {
            const contractDraft: ContractDraft = new ContractDraft();
            contractDraft.contractTemplate = contractTemplate;
            this.contractDraftAddEditResponse.contractDraft = contractDraft;
          }
          this.contractTemplateParamList.clear();
          this.contractTemplateParameterList = contractTemplateParameterList;
          this.contractTemplateParameterList.forEach(contractTemplateParam => {
            const formGroup = this.formBuilder.group({
              id: [null],
              code: contractTemplateParam.contractParameter.code,
              name: contractTemplateParam.contractParameter.name,
              value: [
                this.contract.contractType.code ===
                  this.global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT &&
                contractTemplateParam.contractParameter.code ===
                  this.global.appConstant.cm.CONTRACT_PARAMETER_NOMOR_KONTRAK
                  ? this.contract.fromContract.number
                  : contractTemplateParam.contractParameter.code ===
                    this.global.appConstant.cm.CONTRACT_PARAMETER_NOMOR_KONTRAK
                  ? this.contract.number
                  : null,
                !contractTemplateParam.contractParameter.code.includes(
                  'PERUBAHAN'
                )
                  ? Validators.required()
                  : null
              ],
              valueTypeCode:
                contractTemplateParam.contractParameter.valueType.code,
              contractTemplateParameter: contractTemplateParam
            });
            if (this.todo === 'view' || this.todo === 'detail') {
              formGroup.setIsView(true);
            }
            if (
              this.contract.contractType.code ===
                this.global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT &&
              contractTemplateParam.contractParameter.code ===
                this.global.appConstant.cm.CONTRACT_PARAMETER_NOMOR_KONTRAK
            ) {
              formGroup.get('value').setIsView(true);
            }
            this.contractTemplateParamList.push(formGroup);
          });
          this.showTables = true;
          this.isParameterReady = true;
        }
      );
  }

  public onChangeTemplateList(contractTemplate: ContractTemplate): void {
    this.isParamValueNull = false;
    this.checkParameterValue();
    if (contractTemplate && this.isParamValueNull) {
      this.changeTemplate(contractTemplate);
    } else if (contractTemplate) {
      this.showTables = false;
      const prompt = this.translateService.instant(
        'contract-draft.confirm.changeTemp.msg'
      );
      const title = this.translateService.instant(
        'contract-draft.confirm.changeTemp.title'
      );
      this.global.modalService
        .confirm(prompt, title)
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.isParamValueNull = true;
            this.changeTemplate(contractTemplate);
          } else {
            this.formGroup.patchValue({
              contractTemplateList: this.contractDraftAddEditResponse
                .contractDraft
                ? this.contractDraftAddEditResponse.contractDraft
                    .contractTemplate
                : null
            });
          }
        });
    } else {
      this.showTables = false;
      this.contractTemplate = contractTemplate;
      this.contractTemplateParamList.clear();
    }
  }

  public onChangeValue(): void {
    const contractTemplateParamList =
      this.formGroup.value.contractTemplateParamList;
    const contractDraftParamValueList: ContractDraftParameterValue[] = [];
    if (contractTemplateParamList) {
      contractTemplateParamList.forEach(parameter => {
        const contractDraftParameterValue: ContractDraftParameterValue =
          new ContractDraftParameterValue();
        contractDraftParameterValue.contractTemplateParameter =
          parameter.contractTemplateParameter;
        contractDraftParameterValue.value = parameter.value;
        if (parameter.value) {
          this.isParamValueNull = false;
        }
        if (
          parameter.code ===
          this.global.appConstant.cm.CONTRACT_PARAMETER_NOMOR_KONTRAK
        ) {
          this.contract.number = parameter.value;
        }
        contractDraftParamValueList.push(contractDraftParameterValue);
      });
    }
    this.contractDraftAddEditResponse.contractDraftParameterValueList =
      contractDraftParamValueList;

    if (this.contractDraftAddEditResponse.contractDraftHistory) {
      const formGroupCollab = this.formGroupCollab.value;
      this.contractDraftAddEditResponse.contractDraftHistory.note =
        formGroupCollab.note;
    }
  }

  get form(): any {
    return this.formGroup.controls;
  }

  public doVersions(): void {
    this.appPopupService.open(
      AppPopupContractDraftVersionComponent,
      { contract: this.contract },
      { size: 'xl', backdrop: 'static' }
    );
  }

  public doAdd(): void {
    this.todo = 'add';
    this.appPopupService
      .open(
        AppPopupParticipantReviewersComponent,
        { todo: this.todo, emailList: this.emailList },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(data => {
        const contractCollabAccess: ContractReviewer =
          new ContractReviewer();
        contractCollabAccess.userType = data.userType.id;
        contractCollabAccess.email = data.email;
        contractCollabAccess.name = data.name;
        contractCollabAccess.position = data.position;
        this.emailList.push(data.email);
        this.contractDraftAddEditResponse.contractCollabAccessList.push(
          contractCollabAccess
        );
        this.tableResponseCollab.setRecords(
          this.contractDraftAddEditResponse.contractCollabAccessList
        );
        this.tableResponseCollab.setTotalRecords(
          this.contractDraftAddEditResponse.contractCollabAccessList.length
        );
        this.tableResponseCollab.reloadClient();
      });
  }

  public doEdit(contractCollabAccess: ContractReviewer): void {
    this.todo = 'edit';
    this.appPopupService
      .open(
        AppPopupParticipantReviewersComponent,
        { contractCollabAccess, todo: this.todo, emailList: this.emailList },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe(data => {
        this.contractDraftAddEditResponse.contractCollabAccessList.forEach(
          (collabAccess, index) => {
            if (collabAccess === contractCollabAccess) {
              const contractCollabAccess: ContractReviewer =
                new ContractReviewer();
              contractCollabAccess.userType = data.userType.id;
              contractCollabAccess.email = data.email;
              contractCollabAccess.name = data.name;
              contractCollabAccess.position = data.position;
              this.emailList[index] = data.email;
              this.contractDraftAddEditResponse.contractCollabAccessList[
                index
              ] = contractCollabAccess;

              this.tableResponseCollab.setRecords(
                this.contractDraftAddEditResponse.contractCollabAccessList
              );
              this.tableResponseCollab.setTotalRecords(
                this.contractDraftAddEditResponse.contractCollabAccessList
                  .length
              );
              this.tableResponseCollab.reloadClient();
            }
          }
        );
      });
  }

  public doDelete(contractCollabAccess: ContractReviewer): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.contractDraftAddEditResponse.contractCollabAccessList.forEach(
            (collabAccess, index) => {
              if (collabAccess === contractCollabAccess) {
                this.contractDraftAddEditResponse.contractCollabAccessList.splice(
                  index,
                  1
                );
                this.emailList.splice(index, 1);
              }
            }
          );
          this.tableResponseCollab.page.records =
            this.contractDraftAddEditResponse.contractCollabAccessList;
          this.tableResponseCollab.page.totalRecords =
            this.contractDraftAddEditResponse.contractCollabAccessList.length;
          this.tableResponseCollab.reloadClient();
        }
      });
  }

  public doSaveDraft(): void {
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.formGroupCollab.disable();
          this.contractDraftUpdateRequest.isSave = false;
          this.prepareContractDraftParameterValue();
          const url = '/contract-draft/update';
          this.httpClientService
            .post<Response<ContractDraft>>(url, this.contractDraftUpdateRequest)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessSavingOnNextRoute();
                this.router.navigate(['/pages/worklist-cm']);
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
              this.formGroupCollab.enable();
            });
        }
      });
  }

  public doSave(): void {
    this.validate();
    this.validateFormCollab();
    if (this.formGroupCollab.valid && this.formGroupCollab.value.file) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.formGroupCollab.disable();
            this.contractDraftUpdateRequest.isSave = true;
            this.prepareContractDraftParameterValue();
            const url = '/contract-draft/update';
            this.httpClientService
              .post<Response<ContractDraft>>(
                url,
                this.contractDraftUpdateRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/worklist-cm']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
                this.formGroupCollab.enable();
              });
          }
        });
    } else {
      this.global.alertService.showError(
        'contract-draft.validation.generateDocument'
      );
    }
  }

  public validateFormCollab(): void {
    this.formGroupCollab['submitted'] = true;
    this.formGroupService.validateAllFormFields(this.formGroupCollab);
    if (!this.formGroupCollab.valid) {
      this.formGroupCollab['submitted'] = false;
    }
  }

  public prepareContractDraftParameterValue(): void {
    const contractTemplateList: ContractTemplate[] = [];
    if (this.formGroup.value.contractTemplateList) {
      contractTemplateList.push(this.formGroup.value.contractTemplateList);
    }
    this.contractDraftUpdateRequest.contractTemplateList = contractTemplateList;

    const contractDraftHistory: ContractDraftHistory =
      new ContractDraftHistory();
    contractDraftHistory.note = this.formGroupCollab.value.note;
    contractDraftHistory.id = this.formGroupCollab.value.id;
    contractDraftHistory.generatedFile = this.formGroupCollab.value.file;
    const fileObjectList = this.formGroupCollab.value.uploadFile;
    if (fileObjectList && typeof fileObjectList.length !== 'undefined') {
      if (fileObjectList.length !== 0) {
        contractDraftHistory.draftFile = fileObjectList[0].isDeleted
          ? null
          : fileObjectList[0].file;
        this.contractDraftUpdateRequest.fileObjectList = fileObjectList;
      } else {
        contractDraftHistory.draftFile = null;
        this.contractDraftUpdateRequest.fileObjectList = [];
      }
    } else {
      contractDraftHistory.draftFile = this.formGroupCollab.value.uploadFile;
      this.contractDraftUpdateRequest.fileObjectList = [];
    }
    this.contractDraftUpdateRequest.contractDraftHistory = contractDraftHistory;

    const contractTemplateParamList =
      this.formGroup.value.contractTemplateParamList;
    const contractDraftParamValueList: ContractDraftParameterValue[] = [];
    if (contractTemplateParamList) {
      contractTemplateParamList.forEach(parameter => {
        const contractDraftParameterValue: ContractDraftParameterValue =
          new ContractDraftParameterValue();
        contractDraftParameterValue.id = parameter.id;
        contractDraftParameterValue.contractTemplateParameter =
          parameter.contractTemplateParameter;
        contractDraftParameterValue.value = parameter.value;
        if (parameter.value) {
          this.isParamValueNull = false;
        }
        contractDraftParamValueList.push(contractDraftParameterValue);
      });
    }
    this.contractDraftUpdateRequest.contractDraft =
      this.contractDraftAddEditResponse.contractDraft;
    this.contractDraftUpdateRequest.contract = this.contract;
    this.contractDraftUpdateRequest.contractDraftParameterValueList =
      contractDraftParamValueList;
    this.contractDraftUpdateRequest.contractCollabAccessList =
      this.contractDraftAddEditResponse.contractCollabAccessList;
  }

  public checkParameterValue(): void {
    const contractTemplateParamList =
      this.formGroup.value.contractTemplateParamList;
    if (this.contractTemplateParamList.length !== 0) {
      let parameterNull = true;
      contractTemplateParamList.forEach(parameter => {
        if (parameter.value) {
          parameterNull = false;
        }
      });
      if (!parameterNull) {
        this.isParamValueNull = false;
      } else {
        this.isParamValueNull = true;
      }
    } else {
      this.isParamValueNull = true;
    }
  }

  public doNext(): void {
    this.validate();
    if (this.formGroup.valid) {
      let prompt: string;
      this.isNextAccordion = false;
      if (this.contractDraftAddEditResponse.contractDraftHistory) {
        prompt = this.translateService.instant(
          'contract-draft.generate-again.msg'
        );
      } else {
        prompt = this.translateService.instant('contract-draft.generate.msg');
      }
      const title = this.translateService.instant(
        'contract-draft.generate.title'
      );

      this.global.modalService
        .confirm(prompt, title)
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.formGroupCollab.disable();
            const contractTemplateParamList =
              this.formGroup.value.contractTemplateParamList;
            const contractDraftParamValueList: ContractDraftParameterValue[] =
              [];
            if (contractTemplateParamList) {
              contractTemplateParamList.forEach(parameter => {
                const contractDraftParameterValue: ContractDraftParameterValue =
                  new ContractDraftParameterValue();
                contractDraftParameterValue.id = parameter.id;
                contractDraftParameterValue.contractTemplateParameter =
                  parameter.contractTemplateParameter;
                contractDraftParameterValue.value = parameter.value;
                contractDraftParamValueList.push(contractDraftParameterValue);
              });
            }
            this.contractDraftGenerateRequest.contract =
              this.contractDraftAddEditResponse.contract;
            this.contractDraftGenerateRequest.contractTemplate =
              this.formGroup.value.contractTemplateList;
            this.contractDraftGenerateRequest.contractDraftParameterValueList =
              contractDraftParamValueList;
            const url = '/contract-draft/generate';
            this.httpClientService
              .post<Response<ContractDraftGenerateResponse>>(
                url,
                this.contractDraftGenerateRequest
              )
              .subscribe(response => {
                this.setStateReady();
                this.formGroupCollab.enable();
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccess(
                    'contract-draft.generate.success'
                  );
                  const contractDraftGenerateResponse: ContractDraftGenerateResponse =
                    response.body;
                  this.contractDraftAddEditResponse.contractDraftHistory =
                    contractDraftGenerateResponse.contractDraftHistory;
                  this.contractDraftAddEditResponse.contractDraft =
                    contractDraftGenerateResponse.contractDraft;
                  contractDraftGenerateResponse.contractDraftParameterValueList.forEach(
                    (paramValue, index) => {
                      this.contractTemplateParamList
                        .at(index)
                        .get('id')
                        .setValue(paramValue.id);
                      this.contractTemplateParamList
                        .at(index)
                        .get('id')
                        .updateValueAndValidity();
                    }
                  );
                  const version =
                    this.contractDraftAddEditResponse.contractDraftHistory
                      .sequence - 1;
                  this.formGroupCollab.patchValue({
                    file: this.contractDraftAddEditResponse.contractDraftHistory
                      .generatedFile,
                    version
                  });
                  this.isNextAccordion = true;
                } else {
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doBack(): void {
    this.router.navigate(['/pages/worklist-cm/']);
  }
}
