import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BiddingEvaluation } from '../../core/bean/bidding-evaluation';
import { ItemType } from '../../core/bean/item-type';
import { ProcurementVendor } from '../../core/bean/procurement-vendor';
import { QuotationItemReference } from '../../core/bean/quotation-item-reference';
import { TableHeaderPluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';

@Component({
  templateUrl: './app-popup-refutation-vendor-item-detail.component.html'
})
export class AppPopupRefutationVendorItemDetailComponent extends BaseModuleComponent {
  @Input() procurementVendor: ProcurementVendor;
  @Input() itemTypeList: ItemType[];
  @Input() biddingEvaluation: BiddingEvaluation;
  @Input() technicalPercentage: number;

  public tableResponse: TableResponseModel<QuotationItemReference>;
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    false
  );

  constructor(translateService: TranslateService) {
    super('refutation-vendor', translateService);
  }

  public onInit(): void {
    this.setRequestValues();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      itemType: [null]
    });
  }

  public setRequestValues(): void {
    this.itemTypeOptionList.setRequestValues(this.itemTypeList);
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'quotationItem.procurementItem.prItem.item.name',
        header: 'table.column.itemName'
      },
      {
        field: 'quotationItem.procurementItem.prItem.pr.code',
        header: 'table.column.itemCode'
      },
      {
        field: 'quotationItem.procurementItem.prItem.item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'quotationItem.procurementItem.prItem.item.itemType.code'
        }
      },
      this.biddingEvaluation.code ===
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
        ? {
            field: 'quotationItem.finalScoringStatus.name',
            header: 'table.column.priceEvaluation',
            plugins: {
              name: 'badge',
              pill: true,
              colorMap: {
                PASSED: 'SUCCESS',
                NOT_PASSED: 'DANGER',
                WAITING_SCORING: 'WARNING'
              },
              colorField: 'quotationItem.finalScoringStatus.code'
            }
          }
        : {
            field: 'quotationItem.finalEvaluationScore',
            plugins: {
              name: 'text-field',
              isView: true,
              type: 'decimal'
            },
            className: 'text-right',
            header: {
              key: 'table.column.priceEvaluation',
              plugins: {
                name: 'custom-plugin',
                after: (tableHeaderPlugin: TableHeaderPluginData): void => {
                  if (tableHeaderPlugin) {
                    if (
                      this.biddingEvaluation.code ===
                      this.global.appConstant.pm
                        .BIDDING_EVALUATION_CODE_MERIT_POINT
                    ) {
                      tableHeaderPlugin.element.innerText +=
                        ' (' + (this.technicalPercentage || ' - ') + '%)';
                    }
                  }
                }
              }
            }
          },
      {
        field: 'quantity',
        header: 'table.column.qty',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      }
    ]);
    this.tableResponse.setCustomData({
      procurementVendorId: this.procurementVendor.id
    });
    this.tableResponse.reload();
  }

  public onChangeItemType(): void {
    const itemType = this.formGroup.value.itemType;
    if (itemType) {
      this.tableResponse.setCustomData({
        procurementVendorId: this.procurementVendor.id,
        itemType
      });
      this.tableResponse.reload();
    }
  }
}
