<app-popup
  header="app-popup-allocated-item-detail.popup.title"
  [isLoading]="formLoading"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="popup-choose-vendor">
    <app-table-xx
      [model]="tableResponse"
      *ngIf="!formLoading">
      <ng-template #headerFilter>
          <div class="app-table-filter-item" style="height: 34px">
            <div class="form-group row text-sm-left">
              <div class="col text-left" [ngStyle]="{ 'min-width': '10rem' }">
                <app-dropdown-select
                  *ngIf="!formLoading"
                  size="XL"
                  formControlName="itemType"
                  type="COMBOBOX"
                  [optionList]="itemTypeOptionList"
                  (onChange)="onChangeItem()"
                  container="body"
                  placeholder="{{
                    'app-popup-allocated-item-detail.placeholder.type' | translate
                  }}"
                ></app-dropdown-select>
              </div>
            </div>
          </div>
      </ng-template>
    <!-- <ng-container *ngIf="tableResponse.getRecordList().length > 0"> -->
      <ng-template #row>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right" colspan="4">
            {{ 'app-popup-allocated-item-detail.form.goodsPrice' | translate }}
          </td>
          <td class="text-right">
            <strong>
             {{ formGroup.value.goodsAmount
                | resolveNumberToCurrency
              }}
            IDR
            </strong>
          </td>
          <td></td>
        </tr>

        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right" colspan="4">
            {{ 'app-popup-allocated-item-detail.form.servicePrice' | translate }}
          </td>
          <td class="text-right">
            <strong>
              {{ formGroup.value.serviceAmount
                | resolveNumberToCurrency
              }}
              IDR
            </strong>
          </td>
          <td></td>
        </tr>

        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-right" colspan="4">
            {{ 'app-popup-allocated-item-detail.form.grandTotal' | translate }}
          </td>
          <td class="text-right">
            <strong>
              {{ formGroup.value.amount
                  | resolveNumberToCurrency
              }}
              IDR 
            </strong>
            <br>
            <i>
              ({{
                  formGroup.value.amount
                  | resolveNumberToWords: global.currentLang
               }}
                Rupiah)
            </i>
          </td>
          <td></td>
        </tr>
      </ng-template>
            <!-- </ng-container> -->
    </app-table-xx>
  </div>
  </form>
</app-popup>
