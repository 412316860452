import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupRegistrationTermConditionComponent } from './app-popup-registration-term-condition.component';
import { AppPopupRegistrationTermConditionService } from './app-popup-registration-term-condition.service';
@NgModule({
  imports: [VendorModule, AppPopupModule],
  declarations: [AppPopupRegistrationTermConditionComponent],
  entryComponents: [AppPopupRegistrationTermConditionComponent],
  providers: [AppPopupRegistrationTermConditionService],
  exports: [AppPopupRegistrationTermConditionComponent]
})
export class AppPopupRegistrationTermConditionModule {}
