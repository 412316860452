import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { CatalogBulkPrice } from '../../bean/catalog-bulk-price';
import { CatalogContract } from '../../bean/catalog-contract';
import { TablePluginData } from '../table/interface/table-plugin-data';
import { TableResponseModel } from '../table/model/table-response-model';

@Component({
  templateUrl: './app-popup-bulk-price-catalog-detail-info.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppPopupBulkPriceCatalogDetailInfoComponent extends BaseComponentComponent {
  @Input() public catalogContract: CatalogContract;
  public catalogBulkPriceList: CatalogBulkPrice[];
  public tableResponse: TableResponseModel<CatalogBulkPrice>;

  public onInit(): void {
    this.buildTableResponse();
    this.setData();
    this.setStateReady();
  }

  constructor(public translateService: TranslateService) {
    super('app-catalog-detail-info');
  }

  public setData(): void {
    this.catalogBulkPriceList = this.catalogContract['catalogBulkPriceList'];
    this.tableResponse.setRecordList(this.catalogBulkPriceList);
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'discount',
        header: 'table.column.discount',
        className: 'text-right',
        plugins: {
          name: 'custom-plugin',
          after: (tablePluginData: TablePluginData): void => {
            if (tablePluginData.value) {
              tablePluginData.element.innerText += '%';
            }
          }
        }
      },
      {
        field: 'minimumOrderTotal',
        header: 'table.column.minimumOrder',
        className: 'text-right'
      },
      {
        field: 'discountedPrice',
        header: 'table.column.itemPrice',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      }
    ]);
  }
}
