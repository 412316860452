import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppCatalogItemXModule } from '../app-catalog-item-x/app-catalog-item-x.module';
import { AppPaginationXModule } from '../app-pagination-x/app-pagination-x.module';
import { AppSimilarProductComponent } from './app-similar-product.component';

@NgModule({
  imports: [
    VendorModule,
    AppCatalogItemXModule,
    AppPaginationXModule,
    AppButtonModule
  ],
  declarations: [AppSimilarProductComponent],
  exports: [AppSimilarProductComponent]
})
export class AppSimilarProductModule {}
