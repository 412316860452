import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupSegmentationVendorComponent } from './popup/app-popup-segmentation-vendor.component';
import { TabProfileSegmentationComponent } from './tab-profile-segmentation.component';

export const routes = [
  {
    path: '',
    component: TabProfileSegmentationComponent,
    data: { breadcrumb: '' }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    TabProfileSegmentationComponent,
    AppPopupSegmentationVendorComponent
  ],
  entryComponents: [
    AppPopupSegmentationVendorComponent
  ],
  exports: [
    TabProfileSegmentationComponent,
    AppPopupSegmentationVendorComponent
  ]
})

export class TabProfileSegmentationModule {}
