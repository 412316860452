import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { RoleDashboardWidget } from '../../bean/role-dashboard-widget';
import { AppPopupService } from '../../components/app-popup/app-popup.service';
import { WidgetEvent } from '../model/widget-event-model';
import { WidgetItemModel } from '../model/widget-item-model';
import { WidgetService } from '../widgets.service';
import { AppPopupWidgetWrapperComponent } from './app-popup-widget-wrapper.component';
@Component({
  selector: 'app-widget-wrapper-row',
  templateUrl: './app-widget-wrapper-row.component.html',
  styleUrls: ['./app-widget-wrapper-row.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetWrapperRowComponent extends BaseComponentComponent {
  @Input() model: any;
  @Output() onChange: EventEmitter<WidgetEvent> = new EventEmitter();
  constructor(
    public widgetService: WidgetService,
    public appPopupService: AppPopupService
  ) {
    super('app-widget-wrapper-row');
  }

  public onInit(): void {
    this.setStateLoading();
  }

  public handleDeleteContent(model: any, index: number): void {
    model.splice(index, 1);
    this.onChange.emit({ type: 'ON-DELETE' });
  }

  doOnChange(event: any, postion: string): void {
    if (typeof event === 'boolean') {
      this.onChange.emit({ type: 'ON-EDIT' });
    } else {
      this.onChange.emit({
        type: 'ON-EDIT',
        data: { index: event, position: postion }
      });
    }
  }

  handleAddWidget(widgets: Array<string>, group: string): void {
    this.appPopupService
      .open(AppPopupWidgetWrapperComponent)
      .subscribe((widget: RoleDashboardWidget) => {
        const widgetItem: WidgetItemModel = new WidgetItemModel();
        widgetItem.setGroup(group);
        widgetItem.setSequence(widgets.length + 1);
        widgetItem.setRoleDashboardWidget(widget);
        this.onChange.emit({ type: 'ON-ADD', data: widgetItem });
      });
  }

  handleCopyRow(widgets: Array<string>): void {
    this.widgetService.setCopy(widgets);
  }

  handlePasteRow(widgets: Array<string>): void {
    const widgetsCopy = this.widgetService.getCopy();
    widgets.push(...widgetsCopy);
  }

  handleDeleteRow(widgets: Array<string>): void {
    widgets.splice(0);
    this.onChange.emit({ type: 'ON-DELETE' });
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
