import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorEntity } from '../../core/entity/vendor.entity';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { VendorEntityResponse } from './vendor-entity-response';

@Component({
  templateUrl: './vendor-entity-edit-add.component.html'
})
export class VendorEntityEditAddComponent extends BaseModuleComponent {
  public vendorEntityId: number;
  vendorTypeOptionList: OptionListModel<any> = new OptionListModel(true);
  constructor(translateService: TranslateService) {
    super('vendor-entity', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorEntityId = this.global.routerParams.get('vendorEntityId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required()])
      ],
      name: [
        null,
        Validators.compose([Validators.required()])
      ],
      vendorType: [null, Validators.compose([Validators.required()])],
      description: [null, Validators.maxLength(512)]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<VendorEntityResponse>(
        '/vendor-entity/add-edit',
        new RouteRequestModel(this.todo, this.vendorEntityId)
      )
      .subscribe(vendorEntityResponse => {
        this.vendorTypeOptionList.setRequestValues(
          vendorEntityResponse.vendorTypeList
        );
        if (vendorEntityResponse.vendorEntity != null) {
          const {
            id,
            name,
            code,
            description,
            vendorType
          } = vendorEntityResponse.vendorEntity;
          this.formGroup.patchValue({
            id,
            name,
            code,
            description,
            vendorType
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const vendorEntity: VendorEntity = this.global.copyFormAttributeToModel(
              new VendorEntity(),
              this.formGroup
            );
            const url =
              this.todo === 'edit'
                ? '/vendor-entity/update'
                : '/vendor-entity/insert';
            this.httpClientService
              .post<Response<VendorEntity>>(url, vendorEntity)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/vendor-entity/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/vendor-entity/']);
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
}
