import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Vendor } from '../../core/bean/vendor';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './vendor-whitelist.component.html',
  styles: [
    '.width-company-name { min-width : 250px; white-space: normal ! important }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class VendorWhitelistComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Vendor>;

  constructor(translateService: TranslateService) {
    super('vendor-whitelist', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.name',
        customClass: 'text-left'
      },
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      { field: 'vendorType.name',
        header: 'table.column.vendorType',
        customClass: 'text-center'
      },
      { field: 'email', header: 'table.column.email' },
      {
        field: 'vendorActivityStatus.name',
        header: 'table.column.vendorActivityStatus',
        customClass: 'text-center'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/vendor-whitelist/add']);
  }

  public doEdit(vendor: Vendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('vendorId', vendor.id);
    this.router.navigate(['/pages/vendor-whitelist/edit']);
  }

  public doView(vendor: Vendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'detail');
    this.global.routerParams.set('vendorId', vendor.id);
    this.router.navigate(['/pages/vendor-whitelist/detail']);
  }
}
