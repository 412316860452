import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupDocGroupComponent } from './app-popup-doc-group.component';
import { AppPopupDocGroupService } from './app-popup-doc-group.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppTableModule],
  declarations: [AppPopupDocGroupComponent],
  entryComponents: [AppPopupDocGroupComponent],
  providers: [AppPopupDocGroupService],
  exports: [AppPopupDocGroupComponent]
})
export class AppPopupDocGroupModule {}
