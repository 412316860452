<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="user-notification.title" [isLoading]="formLoading" [isSaving]="formSaving">
      <app-table [model]="tableResponse" [isServerSide]="true" stringUrl="/user-notification/index">
        <ng-template #actionButtons let-rowData>
          <app-button mode="BYPASS" size="TN" (onClick)="doView(rowData)" title="{{ 'app.tooltip.view' | translate }}">
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>