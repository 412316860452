import { BaseEntity } from '../base/base-entity';
import { ContractNegotiation } from '../bean/contract-negotiation';
import { ContractWorklistStatus } from '../bean/contract-worklist-status';
import { File } from '../bean/file';

export class ContractNegotiationHistoryEntity extends BaseEntity {
  picRole: string;

  contractNegotiation: ContractNegotiation;
  vendorNegotiationStatus: ContractWorklistStatus;
  userNegotiationStatus: ContractWorklistStatus;
  docFile: File;
  processedDate: Date;
  userType: number;
  note: string;
  sequence: number;
}
