import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ComplianceTestMaster } from '../../core/bean/compliance-test-master';
import { ProcurementMethod } from '../../core/bean/procurement-method';
import { VendorDocMaster } from '../../core/bean/vendor-doc-master';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { Validators } from '../../core/validators';
import { AppPopupComplianceTestMasterComponent } from './app-popup-compliance-test-master.component';
import { ComplianceTestMasterChecklistModel } from './compliance-test-master-checklist-model';
import { ComplianceTestMasterEditResponse } from './compliance-test-master-edit-response';
import { ComplianceTestMasterModel } from './compliance-test-master-model';
import { ComplianceTestMasterUpdateRequest } from './compliance-test-master-update-request';

@Component({
  templateUrl: './compliance-test-master-edit.component.html'
})
export class ComplianceTestMasterEditComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<ComplianceTestMasterModel>;

  public complianceTestGroupProcList: ComplianceTestMasterModel[];
  public delTestGrouptList: ComplianceTestMasterModel[] = [];
  public delTestChecklistList: ComplianceTestMasterChecklistModel[] = [];
  public procurementMethod: ProcurementMethod;
  public complianceTestMasterEditResponse: ComplianceTestMasterEditResponse;

  public vendorDocMasterOptionList: OptionListModel<VendorDocMaster> =
    new OptionListModel(true, 'nameTranslated');

  public constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public snackbarService: SnackbarService
  ) {
    super('compliance-test-master', translateService);
  }

  public onInit(): void {
    this.getDataFromRouterParam();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      complianceTestChecklistList: this.formBuilder.array([])
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<ComplianceTestMasterEditResponse>(
        '/compliance-test-master/edit/' + this.procurementMethod.id
      )
      .subscribe((response: ComplianceTestMasterEditResponse) => {
        this.complianceTestMasterEditResponse = response;
        this.complianceTestGroupProcList = response.procurementDocTestGroupList;
        this.tableResponse.setRecordList(response.procurementDocTestGroupList);
        this.vendorDocMasterOptionList.setRequestValues(
          response.vendorDocMasterList
        );

        if (
          response.vendorDocTestGroup.complianceTestMasterChecklistModelList
        ) {
          response.vendorDocTestGroup.complianceTestMasterChecklistModelList.forEach(
            checklistModel => {
              if (
                (checklistModel.complianceTestMaster.crudOperation &&
                  checklistModel.complianceTestMaster.crudOperation !==
                    this.global.appConstant.core.CRUD_OPERATION_DELETE) ||
                checklistModel.complianceTestMaster.crudOperation ===
                  undefined ||
                checklistModel.complianceTestMaster.crudOperation === null
              ) {
                const formGroup = this.formBuilder.group({
                  id: checklistModel.complianceTestMaster.id,
                  testName: checklistModel.complianceTestMaster.name,
                  checklistDocument:
                    checklistModel.vendorDocMasterList &&
                    checklistModel.vendorDocMasterList.length !== 0
                      ? [checklistModel.vendorDocMasterList]
                      : [null],
                  isExpanded: false
                });
                this.complianceTestChecklistList.push(formGroup);
              } else {
                this.delTestChecklistList.push(checklistModel);
              }
            }
          );
        } else {
          this.doAddChecklist();
        }

        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'complianceTestGroup.name',
        header: 'table.column.complianceGroupName',
        plugins: {
          name: 'hyperlink',
          onClick: this.doAddComplianceGroup.bind(this)
        }
      },
      {
        field: 'complianceTestGroup.description',
        header: 'table.column.description'
      }
    ]);
  }

  public getDataFromRouterParam(): void {
    this.procurementMethod = this.global.routerParams.get('procurementMethod');
  }

  public doAddComplianceGroup(model: ComplianceTestMasterModel): void {
    const groupNameTempList = this.complianceTestGroupProcList.map(
      model => model.complianceTestGroup.name
    );
    let groupNameList = groupNameTempList;
    if (model) {
      groupNameList = groupNameTempList.filter(
        name => name !== model.complianceTestGroup.name
      );
    }
    this.appPopupService
      .open(
        AppPopupComplianceTestMasterComponent,
        {
          complianceTestMasterModel: model,
          groupNameList,
          procDocTypeList:
            this.complianceTestMasterEditResponse.procurementDocTypeList
        },
        { size: 'xl' }
      )
      .subscribe(model => {
        const index = this.complianceTestGroupProcList.indexOf(model);
        if (index !== -1) {
          this.complianceTestGroupProcList[index] = model;
        } else {
          this.complianceTestGroupProcList.push(model);
        }
        this.tableResponse.setRecordList(this.complianceTestGroupProcList);
        this.tableResponse.reload();
      });
  }

  public doDeleteComplianceGroup(event): void {
    this.global.modalService
      .newDeleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach(
            (record: ComplianceTestMasterModel) => {
              const indexOfRecord = this.complianceTestGroupProcList.findIndex(
                r =>
                  r.complianceTestGroup.name === record.complianceTestGroup.name
              );

              if (indexOfRecord !== -1) {
                const complianceTestMasterModel =
                  this.complianceTestGroupProcList[indexOfRecord];
                if (complianceTestMasterModel.complianceTestGroup.id !== null) {
                  const complianceTestGroup =
                    complianceTestMasterModel.complianceTestGroup;
                  const masterModel: ComplianceTestMasterModel =
                    new ComplianceTestMasterModel();
                  complianceTestGroup.crudOperation =
                    this.global.appConstant.core.CRUD_OPERATION_DELETE;
                  masterModel.complianceTestGroup = complianceTestGroup;
                  masterModel.complianceTestMasterChecklistModelList =
                    complianceTestMasterModel.complianceTestMasterChecklistModelList;
                  this.delTestGrouptList.push(masterModel);
                }
                this.complianceTestGroupProcList.splice(indexOfRecord, 1);
              }
            }
          );
          this.tableResponse.setRecordList(this.complianceTestGroupProcList);
          this.tableResponse.reload();
        }
      });
  }

  public doAddChecklist(): void {
    const formGroup = this.formBuilder.group({
      id: [null],
      testName: [null, Validators.required()],
      checklistDocument: [null, Validators.required()],
      isExpanded: true
    });

    this.complianceTestChecklistList.push(formGroup);
  }

  public doDeleteChecklist(event: PointerEvent, i: number): void {
    this.global.modalService
      .deleteItemConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.preventDefault();
          const checklist = this.complianceTestChecklistList.controls[i].value;
          if (checklist.id !== null) {
            const complianceTestMaster: ComplianceTestMaster =
              new ComplianceTestMaster();
            const checklistModel: ComplianceTestMasterChecklistModel =
              new ComplianceTestMasterChecklistModel();
            complianceTestMaster.id = checklist.id;
            complianceTestMaster.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_DELETE;
            complianceTestMaster.name = checklist.testName;
            checklistModel.complianceTestMaster = complianceTestMaster;
            checklistModel.vendorDocMasterList = checklist.checklistDocument;
            this.delTestChecklistList.push(checklistModel);
          }
          this.complianceTestChecklistList.removeAt(i);
          this.snackbarService.showWarning(
            'compliance-test-master.msg.info.delete'
          );
        }
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid && this.complianceTestGroupProcList.length !== 0) {
      const request = new ComplianceTestMasterUpdateRequest();
      this.complianceTestGroupProcList.forEach(testMasterModel => {
        if (testMasterModel.complianceTestGroup.id) {
          testMasterModel.complianceTestGroup.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_UPDATE;
        } else {
          testMasterModel.complianceTestGroup.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        }
      });
      request.procurementDocTestGroupList =
        this.complianceTestGroupProcList.concat(this.delTestGrouptList);

      const vendorDocTestGroup =
        this.complianceTestMasterEditResponse.vendorDocTestGroup;
      const checklistModelList: ComplianceTestMasterChecklistModel[] = [];
      this.formGroup.value.complianceTestChecklistList.forEach(
        masterChecklist => {
          const complianceTestMaster: ComplianceTestMaster =
            new ComplianceTestMaster();
          const checklistModel: ComplianceTestMasterChecklistModel =
            new ComplianceTestMasterChecklistModel();
          complianceTestMaster.id = masterChecklist.id;
          if (masterChecklist.id !== null) {
            complianceTestMaster.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_UPDATE;
          } else {
            complianceTestMaster.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }
          complianceTestMaster.name = masterChecklist.testName;
          checklistModel.complianceTestMaster = complianceTestMaster;
          checklistModel.vendorDocMasterList =
            masterChecklist.checklistDocument;
          checklistModelList.push(checklistModel);
        }
      );
      vendorDocTestGroup.complianceTestMasterChecklistModelList =
        checklistModelList.concat(this.delTestChecklistList);

      request.vendorDocTestGroup = vendorDocTestGroup;
      request.procurementMethodId = this.procurementMethod.id;
      this.global.modalService
        .newSaveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<ComplianceTestMasterModel>>(
                '/compliance-test-master/update',
                request
              )
              .subscribe(response => {
                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .saveSuccess()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate(['/pages/compliance-test-master']);
                      }
                    });
                }
              });
          }
        });
    }
  }

  public get complianceTestChecklistList(): FormArray {
    return this.formGroup.get('complianceTestChecklistList') as FormArray;
  }

  public onDrop(event: CdkDragDrop<string[]>): void {
    this.moveItemInFormArray(
      this.complianceTestChecklistList,
      event.previousIndex,
      event.currentIndex
    );
  }

  public moveItemInFormArray(
    formArray: FormArray,
    fromIndex: number,
    toIndex: number
  ): void {
    const dir = toIndex > fromIndex ? 1 : -1;

    const from = fromIndex;
    const to = toIndex;

    const temp = formArray.at(from);
    for (let i = from; i * dir < to * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(to, temp);
  }

  public onMouseEnter(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
