import { BaseDTO } from '../../core/base/base-dto';
import { Organization } from '../../core/bean/organization';
import { Region } from '../../core/bean/region';
import { Vendor } from '../../core/bean/vendor';
import { VendorType } from '../../core/bean/vendor-type';
import { VendorEntity } from '../../core/entity/vendor.entity';

export class RegistrationVendorRequest extends BaseDTO {
  organizationList: Organization[] = [];
  vendorType: VendorType;
  vendorEntity: VendorEntity;
  companyName: string;
  name: string;
  email: string;
  password: string;
  retypePassword: string;
  phoneNumber: string;
  lang: string;
  regionPhone: Region;
  vendor: Vendor;
  taxNumber: string;
}
