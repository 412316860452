<app-popup [isShowHeaderCloseButton]="false">
  <div style="font-family: Roboto; font-size: 14px">
    <h3>{{ 'aanwijzing-others.popup.joinAanwijzing.title' | translate }}</h3>
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <app-alert-x color="INFO" *ngIf="!isSureAlert"
        >{{ 'aanwijzing-others.alert.msg.pleaseFillParticipant' | translate }}
      </app-alert-x>
      <app-alert-x color="INFO" *ngIf="isSureAlert"
        >{{ 'aanwijzing-others.alert.msg.areYouSureInfoValid' | translate }}
      </app-alert-x>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'aanwijzing-others.form.fullName' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            formControlName="name"
            size="XL"
            maxLength="128"
            placeholder="{{
              'aanwijzing-others.placeholder.fullName' | translate
            }}"
          ></app-text-field>
          <small class="text-secondary" *ngIf="!isSureAlert">
            {{ 'aanwijzing-others.form.pleaseEnterFullName' | translate }}
          </small>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'aanwijzing-others.form.role' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ aanwijzingParticipant.participantRole.name }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'aanwijzing-others.form.position' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            formControlName="position"
            size="XL"
            maxLength="128"
            placeholder="{{
              'aanwijzing-others.placeholder.position' | translate
            }}"
          ></app-text-field>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'aanwijzing-others.form.phoneNumber' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <ng-container *ngIf="!isSureAlert">
            <app-phone
              [optionList]="countryRegionOptionList"
              maxLength="32"
              formControlName="phoneNumber"
              size="XL"
              placeholder="{{
                'aanwijzing-others.placeholder.phoneNumber' | translate
              }}"
            ></app-phone>
          </ng-container>
          <ng-container *ngIf="isSureAlert">
            {{
              formGroup.value.phoneNumber.country.callingCode +
                formGroup.value.phoneNumber.number
            }}
          </ng-container>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'aanwijzing-others.form.roomCode' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ aanwijzingParticipant.aanwijzing.roomCode }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'aanwijzing-others.form.signature' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <div [ngStyle]="{ display: 'flex', 'justify-content': 'center' }">
            <app-signature
              size="XL"
              formControlName="signatureImg"
              [model]="model"
              (onChange)="onChangeBlank($event)"
            ></app-signature>
          </div>
          <div [ngStyle]="{ display: 'flex', 'margin-left': '10px' }">
            <app-check-box formControlName="isCheck"></app-check-box>
            <label class="white-space-normal"
              >{{ 'app-popup-signature.information.msg' | translate }}&nbsp;
              Aanwijzing
            </label>
          </div>
        </div>
      </div>

      <ng-template #modalFooter let-activeModal>
        <div class="button-group button-group-center">
          <app-button
            color="LIGHT"
            [outline]="true"
            (onClick)="doCancel()"
            *ngIf="!isSureAlert"
          >
            <!--activeModal.close(false)-->
            {{ 'app.button.cancel' | translate }}
          </app-button>

          <app-button
            color="LIGHT"
            [outline]="true"
            *ngIf="isSureAlert"
            (onClick)="doEdit()"
          >
            <!--activeModal.close(false)-->
            {{ 'app.button.edit' | translate }}
          </app-button>

          <app-button
            *ngIf="!isSureAlert"
            (onClick)="doJoinFirst()"
            [disabled]="!formGroup.value.isCheck || isBlank"
          >
            {{ 'aanwijzing-others.button.join' | translate }}
          </app-button>
          <app-button *ngIf="isSureAlert" (onClick)="doJoinFinal()">
            {{ 'aanwijzing-others.button.join' | translate }}
          </app-button>
        </div>
      </ng-template>
    </form>
  </div>
</app-popup>
