import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { InstitutionEditAddComponent } from './institution-edit-add.component';
import { InstitutionComponent } from './institution.component';
const routes: Routes = [
  { path: '', component: InstitutionComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: InstitutionEditAddComponent,
    data: { breadcrumb: 'institution.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: InstitutionEditAddComponent,
    data: { breadcrumb: 'institution.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [InstitutionComponent, InstitutionEditAddComponent]
})
export class InstitutionModule {}
