import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppDropDownComponent } from './app-drop-down.component';
import { AppDropDownDirective } from './app-drop-down.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [AppDropDownComponent, AppDropDownDirective],
  entryComponents: [AppDropDownComponent, AppDropDownDirective],
  exports: [AppDropDownComponent, AppDropDownDirective]
})
export class AppDropDownModule {}
