import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
export class TreePopupResponseModel {
  isLoading = true;
  constructor(
    public type: 'ADD' | 'EDIT',
    public formGroup: FormGroup,
    public header?: string,
    public treePopupContentTmpl?: TemplateRef<any>
  ) {}
  setStateReady(): void {
    this.isLoading = false;
  }
}
@Component({
  templateUrl: './app-tree-popup.component.html',
  styleUrls: ['./app-tree-popup.component.scss']
})
export class AppTreePopupComponent extends BaseComponentComponent {
  @Input() model: TreePopupResponseModel;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  constructor() {
    super('');
  }
  public onInit(): void {}

  public doSave(): void {
    this.formGroupService.validateAllFormFields(this.model.formGroup);
    if (this.model.formGroup.valid) {
      this.onChange.emit(this.formGroup.value);
    }
  }
}
