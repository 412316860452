import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppUserWorkloadModule } from '../../core/components/app-user-workload/app-user-workload.module';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupDefinitionLevelComponent } from './app-popup-definition-level.component';
import { WorkloadMonitoringEditComponent } from './workload-monitoring-edit.component';
import { WorkloadMonitoringComponent } from './workload-monitoring.component';

const routes = [
  {
    path: '',
    component: WorkloadMonitoringComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'detail',
    component: WorkloadMonitoringEditComponent,
    data: { breadcrumb: 'workload-monitoring.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), AppUserWorkloadModule],
  declarations: [
    WorkloadMonitoringComponent,
    WorkloadMonitoringEditComponent,
    AppPopupDefinitionLevelComponent
  ],
  entryComponents: [AppPopupDefinitionLevelComponent]
})
export class WorkloadMonitoringModule {}
