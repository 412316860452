import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FileObject } from '../../../components/upload';
import { CustomValidatorError } from '../../core/custom-validator-error';
export class RequiredValidator {
  static required(message?: string): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors => {
      const value = abstractControl.value;
      if (
        !value ||
        (typeof value === 'string' && value.trim() === '') ||
        (Array.isArray(value) && value.length === 0)
      ) {
        return CustomValidatorError.create('required', message);
      } else {
        const isUploadFile =
          value && Array.isArray(value) && value[0] instanceof FileObject;
        if (isUploadFile) {
          const uploadedFileObjectList = [...value].filter(
            (val: FileObject) => !val.isDeleted
          );
          if (uploadedFileObjectList.length === 0) {
            return CustomValidatorError.create('required', message);
          }
        }
        return null;
      }
    };
  }
}
