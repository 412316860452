import { BaseEntity } from './../base/base-entity';
import { Region } from '../bean/region';
import { VendorPosition } from '../bean/vendor-position';
import { Vendor } from '../bean/vendor';
export class VendorPICEntity extends BaseEntity {
    phoneNumber: string;

    phoneRegion: Region;
    vendorPosition: VendorPosition;
    vendor: Vendor;
    name: string;
    phone: string;
    email: string;
    isEmailNotification: boolean;
}
