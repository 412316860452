import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { EmployeeDataComponent } from './employee-data.component';
import { AppPopupSyncEmployeeDataComponent } from './app-popup-sync-employee-data.component';
const routes: Routes = [
    { path: '', component: EmployeeDataComponent, data: { breadcrumb: '' } }
];
@NgModule({
    imports: [SharedModule, RouterModule.forChild(routes)],
    declarations: [
        EmployeeDataComponent,
        AppPopupSyncEmployeeDataComponent
    ],
    providers: [],
    entryComponents: [AppPopupSyncEmployeeDataComponent],
    exports: [AppPopupSyncEmployeeDataComponent]
})
export class EmployeeDataModule { }
