import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { ProcurementSeg } from 'src/app/core/bean/procurement-seg';
import { ProcurementSegQualification } from 'src/app/core/bean/procurement-seg-qualification';
import { Qualification } from 'src/app/core/bean/qualification';
import { TermQualification } from 'src/app/core/bean/term-qualification';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: 'initiation-popup-segmentation.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InitiationPopupSegmentationComponent extends BaseComponentComponent {
  @Input() termQualificationList: TermQualification[];
  @Input() qualificationList: Qualification[];
  @Input() procSegQualificationList: ProcurementSegQualification[];
  @Output() public onChange: EventEmitter<any[]> = new EventEmitter();
  public termQualificationOptionList: OptionListModel<TermQualification> =
    new OptionListModel(true);
  public qualificationOptionList: OptionListModel<Qualification> =
    new OptionListModel(true);

  constructor(public translateService: TranslateService) {
    super('initiation');
  }

  public onInit(): void {
    this.termQualificationOptionList.setRequestValues(
      this.termQualificationList
    );

    this.qualificationOptionList.setRequestValues(this.qualificationList);

    this.formGroup = this.formBuilder.group({
      segmentation: [null, Validators.required()],
      termQualification: [null, Validators.required()],
      qualification: [null, Validators.required()]
    });

    this.setStateReady();
  }

  public doAdd(): void {
    this.validate();

    let isExist = false;
    if (
      this.procSegQualificationList &&
      this.procSegQualificationList.length > 0
    ) {
      const segmentation =
        this.formGroup.value.segmentation[
          this.formGroup.value.segmentation.length - 1
        ];
      const termQualification = this.formGroup.value.termQualification;
      const qualification = this.formGroup.value.qualification;
      isExist = !!this.procSegQualificationList.find(
        procSegQualif =>
          procSegQualif.procurementSeg.segmentation.id === segmentation.id &&
          procSegQualif.termQualification.id === termQualification.id &&
          procSegQualif.qualification.id === qualification.id
      );
    }

    if (this.formGroup.valid && !isExist) {
      const procurementSegQualificationList: ProcurementSegQualification[] = [];

      const qualificationList: Qualification[] = Array.isArray(
        this.formGroup.value.qualification
      )
        ? this.formGroup.value.qualification
        : [this.formGroup.value.qualification];

      qualificationList.forEach(qualification => {
        const procurementSeg = new ProcurementSeg();
        procurementSeg['segmentationName'] =
          this.formGroup.value.segmentation[
            this.formGroup.value.segmentation.length - 1
          ].name;

        procurementSeg.segmentation =
          this.formGroup.value.segmentation[
            this.formGroup.value.segmentation.length - 1
          ];

        const procurementSegQualification = new ProcurementSegQualification();
        procurementSegQualification.qualification = qualification;
        procurementSegQualification.procurementSeg = procurementSeg;
        procurementSegQualification.termQualification =
          this.formGroup.value.termQualification;

        procurementSegQualificationList.push(procurementSegQualification);
      });

      this.onChange.emit(procurementSegQualificationList);
    } else if (isExist) {
      this.global.alertService.showError(
        'initiation.alert.msg.error.segmentTermQualificationExist',
        '.initiation-popup-segmentation'
      );
    }
  }

  public doClearQualification(event: TermQualification): void {
    this.log.debug(event);
    this.formGroup.get('qualification').reset();
  }
}
