<app-popup [isShowHeaderCloseButton]="false"
header="aanwijzing.popup.attendanceForm.title">
    <div>
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
            
            <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
                'aanwijzing.form.fullName' | translate
            }}</label>
            <div class="col-sm-9 text-left">
                {{ user.name }}
            </div>
            </div>
            <div class="form-group row text-sm-left">
                <label class="col-sm-3">{{
                'aanwijzing.form.role' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                <p>{{ aanwijzingParticipant.participantRole?.name }} </p>
                </div>
            </div>
            <div class="form-group row text-sm-left">
                <ng-container *ngIf="aanwijzingParticipant.participantRole.code
                === global.appConstant.pm.PARTICIPANT_ROLE_INTERNAL">
                    <label class="col-sm-3">{{
                    'aanwijzing.form.department' | translate
                    }}</label>
                    <div class="col-sm-9 text-left">
                        {{ aanwijzingParticipant.user?.organization.name }}
                    </div>
                </ng-container>

                <ng-container *ngIf="aanwijzingParticipant.participantRole.code
                === global.appConstant.pm.PARTICIPANT_ROLE_VENDOR">
                    <label class="col-sm-3">{{
                    'aanwijzing.form.companyName' | translate
                    }}</label>
                    <div class="col-sm-9 text-left">
                        {{ aanwijzingParticipant.vendor?.name }}
                    </div>
                </ng-container>
                
            </div>
            <div class="form-group row text-sm-left">
                <label class="col-sm-3">{{
                'aanwijzing.form.phoneNumber' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                    -
                </div>
            </div>
            <div class="form-group row text-sm-left">
                <label class="col-sm-3">{{
                'aanwijzing.form.signature' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                    <div [ngStyle]="{ display: 'flex', 'justify-content': 'center' }">
                        <app-signature size="XL" 
                          formControlName="signatureImg"
                          [model]="model"
                        ></app-signature>
                    </div>
                    <div [ngStyle]="{ display: 'flex', 'margin-left': '10px' }">
                        <app-check-box formControlName="isCheck"></app-check-box>
                        <label class="white-space-normal"
                            >{{ 'app-popup-signature.information.msg' | translate }}&nbsp;
                        Aanwijzing
                        </label>
                    </div>
                </div>
            </div>

        <ng-template #modalFooter let-activeModal>
        <div class="button-group button-group-center">
            <app-button color="LIGHT" [outline]="true"
            (onClick)="activeModal.close(true)"> 
            {{ 'app.button.cancel' | translate }}
            </app-button>

            <app-button (onClick)="doSubmit()"
            [disabled]="!formGroup.value.isCheck">
            {{ 'app.button.submit' | translate }}
            </app-button>
        </div>
        </ng-template>
        </form>
    </div>
  </app-popup>