import { BaseValueEntity } from '../base/base-value-entity';
import { Region } from '../bean/region';

export class BankEntity extends BaseValueEntity {
  region: Region = new Region();
  key: string;
  name: string;
  swift: string;
  description: string;
  idGift: string;
}
