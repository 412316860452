import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { ModuleGroup } from './../../core/bean/module-group';
import { Workflow } from './../../core/bean/workflow';
@Component({
  templateUrl: './approval-workflow.component.html'
})
export class ApprovalWorkflowComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Workflow>;
  public moduleGroupList: OptionListModel<ModuleGroup> = new OptionListModel(
    true
  );
  // public moduleGroupList: ModuleGroup[];
  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('approval-workflow', translateService);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.doSetModuleGroupList();
    this.setStateReady();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      organizationName: [null],
      organizationId: [null],
      moduleGroup: [null]
    });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      {
        field: 'moduleGroup.name',
        header: 'table.column.moduleGroup',
        customClass: 'text-center'
      }
    ]);
  }

  public doSetModuleGroupList(): void {
    this.httpClientService
      .get<ModuleGroup[]>('/approval-workflow/module-group-list', {})
      .subscribe(moduleGroupList => {
        this.moduleGroupList.setRequestValues(moduleGroupList);
      });
  }

  public doView(workflow: Workflow): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('workflow', workflow);
    this.router.navigate(['/pages/approval-workflow/detail/']);
  }

  public onChooseOrganization(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(organization => {
        const { name: organizationName, id: organizationId } =
          organization.pop();
        this.formGroup.patchValue({ organizationName, organizationId });
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
      });
  }

  public doOnChangeModuleGroup(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
