import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppTextGeneratedComponent } from './app-text-generated.component';
import { AppTextGeneratedDirective } from './app-text-generated.directive';
@NgModule({
  imports: [VendorModule, AppTooltipWrapperModule],
  declarations: [AppTextGeneratedComponent, AppTextGeneratedDirective],
  entryComponents: [AppTextGeneratedComponent],
  exports: [AppTextGeneratedComponent, AppTextGeneratedDirective]
})
export class AppTextGeneratedModule {}
