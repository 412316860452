import { AddressBook } from '../../bean/address-book';
import { Currency } from '../../bean/currency';
import { OrderStatus } from '../../bean/order-status';
import { OrderType } from '../../bean/order-type';
import { ReportRecap } from '../../bean/report-recap';
import { ReportRecapColumn } from '../../bean/report-recap-column';
import { ReportRecapFilter } from '../../bean/report-recap-filter';
import { ReportFilterGroup } from '../../bean/report-recap-filter-group';

export class ReportRecapOrderProcessResponse {
  reportRecap: ReportRecap;
  reportRecapColumnList: ReportRecapColumn[];
  reportFilterGroupList: ReportFilterGroup[];
  reportRecapFilterList: ReportRecapFilter[];
  orderStatusList: OrderStatus[];
  orderTypeList: OrderType[];
  shippingAddressList: AddressBook[];
  currencyList: Currency[];
}
