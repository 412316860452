import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Role } from '../../core/bean/role';
import { UserManual } from '../../core/bean/user-manual';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './user-manual.component.html'
})
export class UserManualComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<UserManual>;
  constructor(translateService: TranslateService) {
    super('user-manual', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.role' }
    ]);
  }

  public doEdit(role: Role): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('role', role);
    this.router.navigate(['/pages/user-manual/edit']);
  }
}
