import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { EvaluationCriteria } from 'src/app/core/bean/evaluation-criteria';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './administration-criteria-edit-add.component.html'
})
export class AdministrationCriteriaEditAddComponent extends BaseModuleComponent {
  public administrationCriteriaId: number;
  constructor(translateService: TranslateService) {
    super('administration-criteria', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.administrationCriteriaId = this.global.routerParams.get(
      'administrationCriteriaId'
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(100)])
      ],
      description: [null, Validators.maxLength(512)]
    });
  }

  public setFormGroup(): void {
    type NewType = EvaluationCriteria;

    this.httpClientService
      .post<NewType>(
        '/administration-criteria/add-edit',
        new RouteRequestModel(this.todo, this.administrationCriteriaId)
      )
      .subscribe(administrationCriteria => {
        if (administrationCriteria != null) {
          const { id, name, description } = administrationCriteria;
          this.formGroup.patchValue({ id, name, description });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const administrationCriteria: EvaluationCriteria = this.global.copyFormAttributeToModel(
              new EvaluationCriteria(),
              this.formGroup
            );
            const url =
              this.todo === 'edit'
                ? '/administration-criteria/update'
                : '/administration-criteria/insert';
            this.httpClientService
              .post<Response<EvaluationCriteria>>(
                url,
                administrationCriteria
              )
              .subscribe((response: Response<EvaluationCriteria>) => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/administration-criteria/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/administration-criteria/']);
  }
}
