import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CatalogImage } from 'src/app/core/bean/catalog-image';
import { Vendor } from 'src/app/core/bean/vendor';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { Catalog } from '../../../core/bean/catalog';
import { File } from '../../../core/bean/file';
import { AppMapPopupComponent } from '../../../core/components/app-map/app-map-popup.component';
import { MapResponseModel } from '../../../core/components/app-map/model/map-response-model';
import { AppPopupEmailComponent } from '../../../core/components/app-popup/app-popup-email/app-popup-email.component';
import { AppPopupService } from '../../../core/components/app-popup/app-popup.service';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { RouteRequestModel } from '../../../core/model/route-request-model';
import { ViewCatalogDetailDTO } from './dto/view-catalog-detail.dto';

@Component({
  templateUrl: 'view-catalog-detail.component.html',
  styleUrls: ['./view-catalog-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewCatalogDetailComponent extends BaseModuleComponent {
  public mapModel: MapResponseModel = new MapResponseModel(null, true);
  public catalogId: number;
  public viewCatalogDetail: ViewCatalogDetailDTO = new ViewCatalogDetailDTO();
  public catalogImageList: Array<File> = new Array();
  public catalogList: Catalog[] = [];
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public route: ActivatedRoute
  ) {
    super('view-catalog', translateService);
  }

  public onInit(): void {
    this.route.params.subscribe((param: Params) => {
      this.setStateLoading();
      this.catalogId = param.id;
      this.todo = 'View';
      this.getAndSetCatalog();
    });
  }

  public getAndSetCatalog(): void {
    this.catalogList = this.global.routerParams.get('catalogList')
      ? this.global.routerParams.get('catalogList')
      : [];

    this.httpClientService
      .post(
        '/view-catalog/detail',
        new RouteRequestModel(this.todo, this.catalogId)
      )
      .subscribe((viewCatalogDetail: ViewCatalogDetailDTO) => {
        this.viewCatalogDetail = viewCatalogDetail;
        const latitudeLongitude = this.mapModel.convertStringToLatLng(
          viewCatalogDetail.vendorAddress.coordinateLocation
        );
        this.mapModel.setLatitudeAndLongitude(
          latitudeLongitude.latitude,
          latitudeLongitude.longitude
        );
        this.catalogImageList =
          this.viewCatalogDetail.catalogBeans.catalogImageList.map(
            (catalogImage: CatalogImage) => catalogImage.file
          );
        this.setStateReady();
      });
  }

  public doView(vendorId: number): void {
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('vendorId', vendorId);
    this.router.navigate(['/pages/view-vendor/detail']);
  }

  public doOpenPopupChat(): void {}

  public doOpenPopupMap(): void {
    if (this.viewCatalogDetail.vendorAddress.coordinateLocation) {
      this.appPopupService.open(AppMapPopupComponent, { model: this.mapModel });
    } else {
      this.global.alertService.showError(
        this.translateService.instant(
          'view-catalog.alert.error.validatePopupMap'
        )
      );
    }
  }

  public doAddToComparisonList(catalog: Catalog): void {
    const checkCatalogs = this.catalogList.filter(
      thisCatalog => thisCatalog.id === catalog.id
    );
    if (!checkCatalogs || (checkCatalogs && checkCatalogs.length > 0)) {
      this.global.alertService.showError(
        'view-catalog.msg.error.cannotAddSameCatalogSameVendor'
      );
    } else {
      const fileImageList: File[] = [];
      this.viewCatalogDetail.catalogBeans.catalogImageList.forEach(
        catalogImage => {
          fileImageList.push(catalogImage.file);
        }
      );
      catalog.fileImageList = fileImageList;
      this.catalogList.push(catalog);
      this.global.routerParams.set('catalogList', this.catalogList);
      this.global.alertService.showSuccess(
        'view-catalog.msg.success.addToCompare'
      );
    }
  }

  public doOpenPopupEmail(): void {
    const url = '/view-catalog/send-email';
    const to = this.viewCatalogDetail.vendorPICEmails;
    this.appPopupService
      .open(AppPopupEmailComponent, { url, to })
      .subscribe((response: Response<Vendor>) => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccess(
            this.translateService.instant('view-catalog.alert.msg.sendSuccess')
          );
        } else {
          this.global.alertService.showError(
            this.translateService.instant('view-catalog.alert.msg.sendError')
          );
        }
      });
  }

  public doBack(): void {
    this.router.navigate(['/pages/view-catalog']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
