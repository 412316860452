import { Procurement } from '../../core/bean/procurement';
import { RefutationReview } from '../../core/bean/refutation-review';
import { AppOfficialReportModel } from '../../core/components/app-official-report/app-official-report-model';

export class RefutationRequest {
  isGenerate: boolean;
  isProcurementFailed: boolean;
  isSubmit: boolean;
  procurement: Procurement;
  refutationReviewList: RefutationReview[];
  appOfficialReportModel: AppOfficialReportModel;
}
