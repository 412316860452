import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProcurementFilter } from 'src/app/core/bean/procurement-filter';
import { ProcurementSegQualification } from 'src/app/core/bean/procurement-seg-qualification';
import { Region } from 'src/app/core/bean/region';
import { Segmentation } from 'src/app/core/bean/segmentation';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { AppPopupService } from '../app-popup.service';
import { AppPopupChooseVendorCondition } from './app-popup-choose-vendor-condition';
import { AppPopupChooseVendorComponent } from './app-popup-choose-vendor.component';

@Injectable()
export class AppPopupChooseVendorService {
  constructor(public appPopupService: AppPopupService) {}

  private setConditionResponse(): AppPopupChooseVendorCondition {
    const chooseVendorConditionResponse: AppPopupChooseVendorCondition =
      new AppPopupChooseVendorCondition();
    chooseVendorConditionResponse.isVendorType = true;
    chooseVendorConditionResponse.isCategory = true;
    chooseVendorConditionResponse.isSegmentation = true;
    chooseVendorConditionResponse.isKeyword = true;
    return chooseVendorConditionResponse;
  }

  private setConditionProcurement(): AppPopupChooseVendorCondition {
    const chooseVendorConditionProcurement: AppPopupChooseVendorCondition =
      new AppPopupChooseVendorCondition();
    chooseVendorConditionProcurement.isMinimumContractValue = true;
    chooseVendorConditionProcurement.isTotalContract = true;
    chooseVendorConditionProcurement.isRangeExperience = true;
    return chooseVendorConditionProcurement;
  }

  openProcurement(
    vendorList?: PopupVendorView[],
    prIdList?: number[],
    segmentationList?: Segmentation[],
    procurementSegQualificationList?: ProcurementSegQualification[],
    procurementFilter?: ProcurementFilter,
    regionList?: Region[],
    isShowCheckBox?: boolean,
    procurementMethodId?: number
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupChooseVendorComponent,
      {
        vendorList,
        prIdList,
        procurementFilter,
        regionList,
        segmentationList,
        procurementSegQualificationList,
        procurementMethodId,
        isOutputFilter: true,
        isShowCheckBox,
        chooseVendorCondition: this.setConditionProcurement()
      },
      { size: 'xl' }
    );
  }

  openRfpRfqRfi(
    vendorList?: PopupVendorView[],
    prIdList?: number[],
    excludeVendorIdList?: number[]
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupChooseVendorComponent,
      {
        vendorList,
        prIdList,
        chooseVendorCondition: this.setConditionResponse(),
        excludeVendorIdList
      },
      { size: 'xl' }
    );
  }

  openProcurementRegist(
    excludeVendorIdList?: number[],
    prIdList?: number[],
    procurementFilter?: ProcurementFilter,
    regionList?: Region[],
    procurementSegQualificationList?: ProcurementSegQualification[],
    procurementMethodId?: number,
    maxParticipant?: number
  ): Observable<any> {
    const chooseVendorCondition = this.setConditionProcurement();
    chooseVendorCondition.isDisableFilter = true;
    chooseVendorCondition.isButtonInvite = true;
    return this.appPopupService.open(
      AppPopupChooseVendorComponent,
      {
        excludeVendorIdList,
        prIdList,
        procurementFilter,
        regionList,
        procurementSegQualificationList,
        chooseVendorCondition,
        procurementMethodId,
        maxParticipant
      },
      { size: 'xl' }
    );
  }

  openPr(vendorList?: PopupVendorView[]): Observable<any> {
    return this.appPopupService.open(
      AppPopupChooseVendorComponent,
      {
        vendorList,
        isUserRefer: false
      },
      { size: 'xl' }
    );
  }
}
