import { NgModule } from '@angular/core';
import { AppRatingModule } from '../../components/app-rating/app-rating.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetModule } from '../app-widget.module';
import { AppWidgetCardXModule } from '../components/app-widget-card-x/app-widget-card-x.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetPerformanceOverallComponent } from './app-widget-performance-overall.component';
@NgModule({
  imports: [
    VendorModule,
    AppWidgetModule,
    AppRatingModule,
    AppWidgetCardXModule
  ],
  declarations: [AppWidgetPerformanceOverallComponent],
  entryComponents: [AppWidgetPerformanceOverallComponent],
  exports: [AppWidgetPerformanceOverallComponent]
})
export class AppWidgetPerformanceOverallModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      'app-widget-performance-overall',
      AppWidgetPerformanceOverallComponent
    );
  }
}
