import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../../core/shared/shared.module';
import { OTSComponent } from './ots.component';
const routes: Routes = [{ path: '', component: OTSComponent, data: { breadcrumb: '' } }];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [OTSComponent]
})
export class OTSModule {}
