<app-popup>
  <div class="text-center"
    ><br />
    <h3> {{ 'rfq.popupCancel.cancelConfirmation' | translate }}</h3
    ><br />
    <div innerHTML="{{ 'rfq.popupCancel.cancelInformation' | translate }}"></div
    ><br />
  </div>
  <form
    class="form-horizontal popup-proposal-criteria"
    [formGroup]="formGroup"
    novalidate
  >
    <div class="row text-sm-left">
      <div class="col-sm-8 text-left">
        <p>{{ 'rfq.popupCancel.fullFillCancelNote' | translate }}</p>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <div class="col-sm-12 text-left">
        <app-text-area size="XL" formControlName="note" maxLength="255">
        </app-text-area>
      </div>
    </div>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button
          color="LIGHT"
          [outline]="true"
          minWidth="130px"
          (onClick)="activeModal.close(false)"
        >
          {{ 'rfq.popupCancel.no' | translate }}
        </app-button>
        <app-button (onClick)="doSubmit()" minWidth="130px">
          {{ 'rfq.popupCancel.yes' | translate }}
        </app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
