import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { ChartModel } from '../../components/app-chart/models/chart-model';
import { WidgetService } from '../widgets.service';
import { WidgetContractPerCategoryResponse } from './model/widget-contract-per-category-response';
@Component({
  selector: 'app-widget-contract-per-category',
  templateUrl: './app-widget-contract-per-category.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetContractPerCategoryComponent extends BaseComponentComponent {
  public chartModel: ChartModel;
  constructor(
    public widgetService: WidgetService,
    public translateService: TranslateService
  ) {
    super('app-widget-contract-per-category');
  }

  public onInit(): void {
    this.getAndSetContractInformation();
  }

  public getAndSetContractInformation(): void {
    this.httpClientService
      .get<WidgetContractPerCategoryResponse>('/widget-contract-per-category/index').subscribe(
        (
          widgetContractPerCategoryResponse: WidgetContractPerCategoryResponse
        ) => {
          if (widgetContractPerCategoryResponse) {
            const labels = [];
            const dataSets = [];
            widgetContractPerCategoryResponse.widgetContractPerCategoryModelList.forEach(
              widgetContractPerCategoryModel => {
                labels.push(
                  this.translateService.instant(
                    'app-widget-contract-per-status.' +
                      widgetContractPerCategoryModel.name
                        .toLowerCase()
                        .replace(/\s/g, '')
                        .trim()
                  )
                );
                dataSets.push(widgetContractPerCategoryModel.total);
              }
            );
            this.chartModel = new ChartModel('pie', labels, dataSets, null, {
              defaultColor: ['#204d95 ', '#782095', '#3d9520']
            });
          }
          this.setStateReady();
        }
      );
  }
}
