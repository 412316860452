<app-popup header="tab-segmentation.detail.title" [isLoading]="formLoading">
  <form [formGroup]="formGroup" class="app-popup-tab-segmentation">
    <h5 class="separator"
      >{{ 'tab-segmentation.classification' | translate }}
    </h5>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-segmentation.segmentation' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          vendorSegmentationHistory.segmentationName !== null
            ? vendorSegmentationHistory.segmentationName
            : '-'
        }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-segmentation.qualification' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          vendorSegmentationHistory.qualification !== null
            ? vendorSegmentationHistory.qualification.name
            : '-'
        }}
      </div>
    </div>

    <div *ngIf="!vendorAssociationExist">
      <h5 class="separator" *ngIf="!vendorAssociationExist">{{
        'tab-segmentation.association' | translate
      }}</h5>
      <!-- <ng-container formArrayName="associationList" *ngIf="!vendorAssociationExist"> -->
      <app-fieldset
        *ngFor="
          let vendorAssociationHistory of vendorAssociationHistoryList;
          let i = index
        "
      >
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-segmentation.association' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorAssociationHistory.association !== null
                ? vendorAssociationHistory.association.name
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-segmentation.certificate-number' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorAssociationHistory.certificateNumber !== null
                ? vendorAssociationHistory.certificateNumber
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-segmentation.validasi-date' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <label class="mr-3 control-label">{{
              'tab-segmentation.from' | translate
            }}</label>
            {{
              vendorAssociationHistory.startDate === null
                ? '-'
                : (vendorAssociationHistory.startDate | date: 'dd/MM/yyyy')
            }}
            <label class="ml-3 mr-3 control-label">{{
              'tab-segmentation.to' | translate
            }}</label>
            {{
              vendorAssociationHistory.endDate === null
                ? '-'
                : (vendorAssociationHistory.endDate | date: 'dd/MM/yyyy')
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-segmentation.certificate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div *ngIf="vendorAssociationHistory.file == null"> - </div>
            <div *ngIf="vendorAssociationHistory.file != null">
              <a
                href="#"
                (click)="
                  doDownload(
                    $event,
                    '/company-profile/file/view/' +
                      vendorAssociationHistory.file.uploadedFileName +
                      '/' +
                      global.appConstant.fileType.DOC_CERTIFICATE_VENDOR,
                    vendorAssociationHistory.file.fileName
                  )
                "
              >
                {{
                  vendorAssociationHistory.file !== null
                    ? vendorAssociationHistory.file.fileName
                    : '-'
                }}
              </a>
            </div>
          </div>
        </div>
      </app-fieldset>
      <!-- </ng-container> -->
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          class="mr-1"
          color="SECONDARY"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.close' | translate }}</app-button
        >
      </div>
    </ng-template>
  </form>
</app-popup>
