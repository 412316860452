<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-workflow-progress-info *ngIf="isWorkflowProgress" [workPlan]="workPlan" [currentPosition]="moduleCode">
        </app-workflow-progress-info>
        <app-card header="work-plan.title" detail="app.action.{{ todo === 'view' ? 'detail' : todo }}"
            [isLoading]="formLoading" [isSaving]="formSaving">
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>
                <div class="form-group row text-sm-right" [ngClass]="!formGroup.isView ? 'required' : ''">
                    <label class="col-sm-3 control-label">{{
            'work-plan.form.number' | translate
          }}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-field placeholder="{{ placeholder }}" autofocus="true" formControlName="number"
                            maxLength="32"
                            symbolic="{{ !formGroup.isView ? workPlanResponse?.workPlan?.generateMemoNumber : '' }}"
                            (onInput)="handleProcMemoNumbInput($event)">
                            ></app-text-field>
                    </div>
                </div>

                <div class="form-group row text-sm-right" [ngClass]="!formGroup.isView ? 'required' : ''">
                    <label class="col-sm-3 control-label">{{
            'work-plan.form.name' | translate
          }}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-field formControlName="name" maxLength="512">
                        </app-text-field>
                    </div>
                </div>

                <div class="form-group row text-sm-right" [ngClass]="!formGroup.isView ? 'required' : ''">
                    <label class="col-sm-3 control-label">{{
            'work-plan.form.createdDate' | translate
          }}</label>
                    <div class="col-sm-9 text-left">
                        <app-date-picker formControlName="createdDate">
                        </app-date-picker>
                    </div>
                </div>

                <div class="form-group row text-sm-right" [ngClass]="!formGroup.isView ? 'required' : ''">
                    <label class="col-sm-3 control-label">{{
            'work-plan.form.targetDate' | translate
          }}</label>
                    <div class="col-sm-9 text-left">
                        <app-date-picker formControlName="workTarget" minDate="createdDate">
                        </app-date-picker>
                    </div>
                </div>

                <div class="form-group row text-sm-right">
                    <label class="col-sm-3 control-label">{{
            'work-plan.form.organization' | translate
          }}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-tree formControlName="organization" stringUrl="/organization" isLazy="true" header="Organization">
                        </app-text-tree>
                    </div>
                </div>

                <div class="form-group row text-sm-right" [ngClass]="!formGroup.isView ? 'required' : ''">
                    <label class="col-sm-3 control-label">{{
            'work-plan.form.procurementType' | translate
          }}</label>
                    <div class="col-sm-9 text-left">
                        <app-combo-box formControlName="procurementType" [optionList]="procurementTypeOptionList"
                            (onChange)="doClearWorkflow()"></app-combo-box>
                    </div>
                </div>

                <div class="form-group row text-sm-right" [ngClass]="!formGroup.isView ? 'required' : ''">
                    <label class="col-sm-3 control-label">{{
            'work-plan.form.description' | translate
          }}</label>
                    <div class="text-left" [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'">
                        <app-text-area formControlName="description" maxLength="512">
                        </app-text-area>
                    </div>
                </div>

                <!-- PRODUCT -->
                <!-- <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'work-plan.form.uploadFile' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-upload-multiple
              formControlName="file"
              [fileUploader]="fileUploader"
            ></app-upload-multiple>
          </div>
        </div> -->
            </form>
            <app-tab bordered="true" *ngIf="!formLoading">
                <app-tab-item header="work-plan.tab.item">
                    <div class="form-group row required">
                        <div class="col-lg-12">
                            <h5 class="separator">{{
                'work-plan.separator.product' | translate
              }}</h5>
                            <app-table #selectorProduct [tableResponse]="tableResponseProduct">
                                <ng-template *ngIf="!formGroup.isView" #headerButtons>
                                    <app-button (onClick)="doAddWorkPlanItemProduct()"><em class="fas fa-plus"></em>
                                        {{ 'app.button.add' | translate }}</app-button>
                                </ng-template>
                                <ng-template #actionButtons let-rowData let-i="index">
                                    <app-button *ngIf="!formGroup.isView" size="TN"
                                        (onClick)="doEditWorkPlanItemProduct(rowData)"
                                        title="{{ 'app.tooltip.edit' | translate }}">
                                        <em class="fas fa-pencil-alt"></em>
                                    </app-button>
                                    <app-button size="TN" (onClick)="doDetailWorkPlanItemProduct(rowData)"
                                        title="{{ 'app.tooltip.view' | translate }}">
                                        <em class="fas fa-search"></em>
                                    </app-button>
                                    <app-button *ngIf="!formGroup.isView" size="TN" color="DANGER"
                                        (onClick)="doDeleteWorkPlanItemProduct(rowData, i)"
                                        title="{{ 'app.tooltip.delete' | translate }}">
                                        <em class="fas fa-trash"></em>
                                    </app-button>
                                </ng-template>
                                <ng-template #tableFooter>
                                    <p class="
                      m-0
                      text-right
                      border border-top-0
                      d-flex
                      align-items-center
                      justify-content-end
                    " [ngStyle]="{
                      height: '3rem',
                      'padding-right': '2rem'
                    }">
                                        <span class="mr-2"><strong>{{
                          'work-plan.form.totalProductCost' | translate
                        }}
                                                :</strong></span>
                                        {{
                      totalProductCost
                        ? global.converterService.convertMoney(
                            totalProductCost
                          ) +
                          ' ' +
                          workPlanResponse.workPlanItemProductList[0].currency
                            .code
                        : '-'
                    }}
                                    </p>
                                </ng-template>
                            </app-table>
                        </div>
                    </div>
                    <div class="form-group row required">
                        <div class="col-lg-12">
                            <h5 class="separator">{{
                'work-plan.separator.service' | translate
              }}</h5>
                            <app-table #selectorService [tableResponse]="tableResponseService">
                                <ng-template *ngIf="!formGroup.isView" #headerButtons>
                                    <app-button (onClick)="doAddWorkPlanItemService()"><em class="fas fa-plus"></em>
                                        {{ 'app.button.add' | translate }}</app-button>
                                </ng-template>
                                <ng-template #actionButtons let-rowData let-i="index">
                                    <app-button *ngIf="!formGroup.isView" size="TN"
                                        (onClick)="doEditWorkPlanItemService(rowData)"
                                        title="{{ 'app.tooltip.edit' | translate }}">
                                        <em class="fas fa-pencil-alt"></em>
                                    </app-button>
                                    <app-button size="TN" (onClick)="doDetailWorkPlanItemService(rowData)"
                                        title="{{ 'app.tooltip.view' | translate }}">
                                        <em class="fas fa-search"></em>
                                    </app-button>
                                    <app-button *ngIf="!formGroup.isView" size="TN" color="DANGER"
                                        (onClick)="doDeleteWorkPlanItemService(rowData, i)"
                                        title="{{ 'app.tooltip.delete' | translate }}">
                                        <em class="fas fa-trash"></em>
                                    </app-button>
                                </ng-template>
                                <ng-template #tableFooter>
                                    <p class="
                      m-0
                      text-right
                      border border-top-0
                      d-flex
                      align-items-center
                      justify-content-end
                    " [ngStyle]="{
                      height: '3rem',
                      'padding-right': '2rem'
                    }">
                                        <span class="mr-2"><strong>{{
                          'work-plan.form.totalServiceCost' | translate
                        }}
                                                :</strong></span>
                                        {{
                      totalServiceCost
                        ? global.converterService.convertMoney(
                            totalServiceCost
                          ) +
                          ' ' +
                          workPlanResponse.workPlanItemServiceList[0].currency
                            .code
                        : '-'
                    }}
                                    </p>
                                </ng-template>
                            </app-table>
                        </div>
                    </div>
                    <app-fieldset>
                        <p class="text-right">
                            <span class="mr-2"><strong>{{ 'work-plan.form.totalCost' | translate }} :</strong></span>
                            <strong>{{
                totalPriceOfAllItem
                  ? global.converterService.convertMoney(totalPriceOfAllItem) +
                    ' IDR'
                  : '-'
              }}</strong>
                        </p>
                    </app-fieldset>
                    <div class="col-md-12 text-left">
                        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
                            <div class="form-group row text-sm-left" [ngClass]="!formGroup.isView ? 'required' : ''">
                                <label class="col-sm-2 control-label">{{
                  'work-plan.form.toc' | translate
                }}</label>
                                <div class="col-sm-9 text-left">
                                    <app-text-area formControlName="toc" size="XL" maxLength="512"></app-text-area>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-12 text-left">
                        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
                            <div class="form-group row text-sm-left" [ngClass]="
                  !formGroup.isView && isMemoMandatory ? 'required' : ''
                ">
                                <label class="col-sm-2 control-label">{{
                  'work-plan.form.uploadApprovalMemo' | translate
                }}</label>
                                <div class="col-sm-9 text-left" *ngIf="!formGroup.isView">
                                    <app-upload-multiple formControlName="approvalMemoDoc"
                                        [fileUploader]="approvalMemoDocUploader"></app-upload-multiple>
                                </div>
                                <div class="col-sm-9 text-left" *ngIf="formGroup.isView">
                                    -
                                </div>
                            </div>
                        </form>
                    </div>
                </app-tab-item>
                <!-- PRODUCT -->
                <!-- <app-tab-item header="work-plan.tab.addressJob" hidden>
          <div class="form-group row required">
            <div class="col-lg-12">
              <h5 class="separator">{{
                'work-plan.separator.jobLocation' | translate
              }}</h5>
              <app-table
                #selectorAddress
                [tableResponse]="tableResponseAddress"
              >
                <ng-template *ngIf="!formGroup.isView" #headerButtons>
                  <app-button (onClick)="doAddWorkPlanLocation()"
                    ><em class="fas fa-plus"></em>
                    {{ 'app.button.add' | translate }}</app-button
                  >
                </ng-template>
                <ng-template
                  *ngIf="!formGroup.isView"
                  #actionButtons
                  let-rowData
                >
                  <app-button
                    size="TN"
                    (onClick)="doEditWorkPlanLocation(rowData)"
                    title="{{ 'app.tooltip.edit' | translate }}"
                  >
                    <em class="fas fa-pencil-alt"></em>
                  </app-button>
                  <app-button
                    size="TN"
                    color="DANGER"
                    (onClick)="doDeleteWorkPlanLocation(rowData)"
                    title="{{ 'app.tooltip.delete' | translate }}"
                  >
                    <em class="fas fa-trash"></em>
                  </app-button>
                </ng-template>
              </app-table>
            </div>
          </div>
        </app-tab-item> -->
            </app-tab>
            <app-fieldset *ngIf="!formGroup.isView" legend="{{ 'work-plan.form.workPlanWorkflow' | translate }}"
                [required]="!formGroup.isView ? true : false">
                <form class="form-horizontal" [formGroup]="formGroup" novalidate>
                    <div class="form-group row text-sm-right" [ngClass]="!formGroup.isView ? 'required' : ''">
                        <div class="col-sm-9 text-left">
                            <app-choose formControlName="workflowName" (onClick)="chooseWorkflow($event)">
                            </app-choose>
                        </div>
                    </div>
                </form>
            </app-fieldset>
            <div *ngIf="!workPlanResponse.isUserApproval" class="button-group button-group-center mt-5">
                <app-button mode="BYPASS" color="SECONDARY" (onClick)="doCancel()" [disabled]="formSaving">
                    {{ 'app.button.back' | translate }}
                </app-button>
                <app-button *ngIf="!formGroup.isView" (onClick)="doSaveDraft()" [disabled]="formSaving">
                    {{ 'app.button.save-draft' | translate }}
                </app-button>
                <app-button *ngIf="!formGroup.isView" (onClick)="doSave()" [disabled]="formSaving">
                    {{ 'app.button.save' | translate }}
                </app-button>
            </div>

            <div class="col-lg-12" *ngIf="!formLoading && todo !== 'add'">
                <app-fieldset legend="{{ 'work-plan.fieldset.workPlanHistory' | translate }}">
                    <app-table #selectorWorkPlanHistory [tableResponse]="tableResponseWorkPlanHistory"
                        [isServerSide]="true" stringUrl="/work-plan/work-plan-history-list"
                        customData="{{ workPlanId }}">
                    </app-table>
                </app-fieldset>
            </div>

            <div class="col-lg-12" *ngIf="
          !formLoading &&
          workPlanResponse.approvalPathResponseModel !== null &&
          !workPlanResponse.isUserApproval &&
          todo === 'view'
        ">
                <h5 class="separator">{{
          'work-plan.separator.monitoringApproval' | translate
        }}</h5>
        <div class="col text-left">
          <app-approval-path [model]="approvalPath">
            <ng-template #customText let-userApprover>
              <span class="d-block" [ngStyle]="{ color: '#343434' }">{{
                userApprover.user?.name || '-'
              }}</span>
                            <span class="d-block" [ngStyle]="{ color: '#343434' }"
                                *ngIf="userApprover.delegateUser">({{ userApprover.delegateUser?.name }})</span>
                            <span class="d-block" [ngStyle]="{ color: '#343434' }">{{
                userApprover.position?.name || '-'
              }}</span>
                            <span class="d-block" [ngStyle]="{ color: '#343434' }">{{
                userApprover.organization?.name || '-'
              }}</span>
            </ng-template>
          </app-approval-path>
        </div>
      </div>

            <div class="col-lg-12" *ngIf="
          !formLoading &&
          workPlanResponse.approvalPathResponseModel !== null &&
          !workPlanResponse.isUserApproval &&
          todo !== 'add'
        ">
                <h5 class="separator">{{
          'work-plan.separator.logApproval' | translate
        }}</h5>
        <app-approval-log
          [moduleCode]="moduleCode"
          [workflowPrcs]="workflowPrcs"
        >
        </app-approval-log>
      </div>
    </app-card>
    <app-approval-prcs-x
      *ngIf="workPlanResponse.isUserApproval"
      [workflowModelPrcs]="workflowModelPrcs"
      [approvalModelPrcsId]="approvalModelPrcsId"
    ></app-approval-prcs-x>
  </div>
</div>
