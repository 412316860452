import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { WebContent } from '../../../core/bean/web-content';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
@Component({
  templateUrl: './disclaimer-edit-add.component.html'
})
export class DisclaimerEditAddComponent extends BaseModuleComponent {
  constructor(translateService: TranslateService) {
    super('disclaimer', translateService);
  }

  public onInit(): void {
    this.doSetTodoFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetTodoFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      disclaimerList: this.formBuilder.array([])
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<Array<WebContent>>('/disclaimer/add-edit', {})
      .subscribe((disclaimerList: Array<WebContent>) => {
        const control = this.formGroup.controls.disclaimerList as FormArray;
        disclaimerList.forEach((disclaimer: WebContent) => {
          const formGroup: FormGroup = this.formBuilder.group({
            id: disclaimer.id,
            key: disclaimer.key,
            description: disclaimer.description,
            code: disclaimer.code,
            lang: disclaimer.lang,
            value: disclaimer.value
          });
          control.push(formGroup);
        });
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          const disclaimerList = (
            this.formGroup.controls.disclaimerList as FormArray
          ).value as Array<WebContent>;
          this.httpClientService
            .post<Response<WebContent>>('/disclaimer/update', disclaimerList)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.router.navigate(['/pages/content/']);
                this.global.alertService.showSuccessSavingOnNextRoute();
              } else {
                this.global.alertService.showError(response.statusText);
                this.setStateReady();
              }
            });
        }
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/content/']);
  }

  public get formArrayDisclaimerList(): FormArray {
    return this.formGroup.get('disclaimerList') as FormArray;
  }
}
