import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Contract } from '../../core/bean/contract';
import { File } from '../../core/bean/file';
import { ReviewContract } from '../../core/bean/review-contract';
import { ReviewContractHistory } from '../../core/bean/review-contract-history';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { ReviewContractRequest } from './request/review-contract.request';
import { ReviewContractResponse } from './response/review-contract.response';
@Component({
  templateUrl: './review-contract-edit-add.component.html'
})
export class ReviewContractEditAddComponent extends BaseModuleComponent {
  @ViewChild('selectorHistory') public table: AppTableComponent;
  public tableResponse: TableResponseModel<ReviewContractHistory>;
  public fileUploader: FileUploader = new FileUploader(
    '/review-contract/',
    '',
    this.global.appConstant.fileType.DOC_APPROVAL
  );
  public contractId: number;
  public fileTypeId: number;
  public latestContractDocument: File;
  public contract: Contract = new Contract();
  public reviewContract: ReviewContract = new ReviewContract();
  public reviewContractHistoryList: ReviewContractHistory[] = [];
  public statusList: OptionListModel<any> = new OptionListModel();
  constructor(translateService: TranslateService) {
    super('review-contract', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setIsViewOnly();
    this.doBuildFormGroup();
    this.buildTableResponse();
    this.doSetOptionList();
    this.doGetAndSetApprovalModelPrcs();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.contractId = this.global.routerParams.get('contractId');
  }

  public setIsViewOnly(): void {
    if (this.todo === 'detail' || this.todo === 'view') {
      this.setViewOnly();
      this.formGroup.setIsView(true);
    }
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      status: [null, Validators.required()],
      uploadFile: [null],
      note: [null, Validators.required()]
    });
  }

  public doSetOptionList(): void {
    const selectList = [
      {
        view: this.translateService.instant('review-contract.approve'),
        value: 1
      },
      {
        view: this.translateService.instant('review-contract.revision'),
        value: 2
      }
    ];

    this.statusList
      .setRequestValues(selectList)
      .setViewPath('view')
      .setValuePath('value');
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'file.fileName',
        header: 'table.column.file',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName'
      },
      {
        field: 'status',
        header: 'table.column.status',
        customClass: 'text-center',
        datamap:
          '{' +
          '"1" : "' +
          this.global.translateService.instant(
            'review-contract.status.approve'
          ) +
          '"' +
          ', "2" : "' +
          this.global.translateService.instant(
            'review-contract.status.revision'
          ) +
          '"' +
          '}'
      },
      {
        field: 'user.name',
        header: 'table.column.pic',
        customClass: 'width-company-name'
      },
      {
        field: 'note',
        header: 'table.column.note',
        customClass: 'white-space-normal'
      },
      {
        field: 'date',
        header: 'table.column.date',
        format: FieldFormatEnum.ShortDateAndTime,
        customClass: 'text-center'
      }
    ]);
  }

  public doGetAndSetApprovalModelPrcs(): void {
    this.httpClientService
      .post<ReviewContractResponse>(
        '/review-contract/edit',
        new RouteRequestModel(this.todo, this.contractId)
      )
      .subscribe(reviewContractResponse => {
        this.latestContractDocument =
          reviewContractResponse.latestContractDocument;
        this.contract = reviewContractResponse.contract;
        this.reviewContract = reviewContractResponse.reviewContract;
        this.reviewContractHistoryList =
          reviewContractResponse.reviewContractHistoryList;
        this.fileUploader.setFile(
          reviewContractResponse.latestContractDocument
        );
        if (reviewContractResponse.reviewContract) {
          this.formGroup.patchValue({
            id: reviewContractResponse.reviewContract.id
          });
        }

        this.tableResponse.page.records = this.reviewContractHistoryList;
        this.tableResponse.page.totalRecords =
          this.reviewContractHistoryList.length;
        this.tableResponse.reloadClient();

        if (this.reviewContract && this.reviewContract.status) {
          this.reviewContract.status =
            this.reviewContract.status === 1
              ? this.translateService.instant('review-contract.approve')
              : this.translateService.instant('review-contract.revision');
        }
        this.setStateReady();
      });
  }

  public doConfirmSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      if (this.formGroup.get('status').value === 1) {
        // modal confirm for approve
        this.global.modalService
          .confirm(
            this.translateService.instant(
              'review-contract.msg.approveConfirmation'
            ),
            'Save Confirmation'
          )
          .pipe(take(1))
          .subscribe(result => {
            this.doSave(result);
          });
      } else {
        // modal confirm for revision
        this.global.modalService
          .confirm(
            this.translateService.instant(
              'review-contract.msg.revisionConfirmation'
            ),
            'Revision Confirmation'
          )
          .pipe(take(1))
          .subscribe(result => {
            this.doSave(result);
          });
      }
    }
  }

  public doSave(result): void {
    if (result) {
      this.setStateProcessing();
      const reviewContractRequest = new ReviewContractRequest();
      const reviewContract = this.formGroup.value;
      reviewContract.contract = this.contract;
      reviewContractRequest.reviewContract = reviewContract;
      reviewContractRequest.fileObjectList =
        this.formGroup.get('uploadFile').value;
      this.httpClientService
        .post<Response<ReviewContractRequest>>(
          '/review-contract/insert',
          reviewContractRequest
        )
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.router.navigate(['/pages/review-contract']);
            this.global.alertService.showSuccessSavingOnNextRoute();
          } else {
            this.setStateReady();
            this.global.alertService.showError(response.statusText);
          }
        });
    }
  }

  public doBack(): void {
    this.router.navigate(['/pages/review-contract']);
  }

  public doReject(): void {
    // do some logic here..
  }

  public doHold(): void {
    // do some logic here..
  }

  public doRevisi(): void {
    // do some logic here..
  }
}
