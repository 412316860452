export class ESMConstant {
  public WORK_PLAN_STATUS_DRAFT = 'DRAFT';
  public WORK_PLAN_STATUS_WAITING_FOR_APPROVAL = 'WAITING-FOR-APPROVAL';
  public WORK_PLAN_STATUS_REVISION = 'REVISION';
  public WORK_PLAN_STATUS_REJECTED = 'REJECTED';
  public WORK_PLAN_STATUS_TOR_SUBMISSION = 'TOR-SUBMISSION';
  public WORK_PLAN_STATUS_PRINCIPLE_PERMIT_SUBMISSION =
    'PRINCIPLE-PERMIT-SUBMISSION';
  public WORK_PLAN_STATUS_DIRECTORS_DECREE_SUBMISSION =
    'DIRECTORS-DECREE-SUBMISSION';
  public WORK_PLAN_STATUS_PROJECT_TEAM_SUBMISSION = 'PROJECT-TEAM-SUBMISSION';
  public WORK_PLAN_STATUS_OFFICIAL_MEMO_SUBMISSION = 'OFFICIAL-MEMO-SUBMISSION';
  public WORK_PLAN_STATUS_PURCHASE_REQUISITION = 'PURCHASE-REQUISITION';
  public WORK_PLAN_STATUS_PROCUREMENT_PROCESS = 'PROCUREMENT-PROCESS';
  public WORK_PLAN_STATUS_CONTRACT_PROCESS = 'CONTRACT-PROCESS';
  public WORK_PLAN_STATUS_PURCHASE_ORDER_PROCESS = 'PURCHASE-ORDER-PROCESS';
  public WORK_PLAN_STATUS_DELIVERY_RECEIVED_PROCESS =
    'DELIVERY-RECEIVED-PROCESS';
  public WORK_PLAN_STATUS_PROCUREMENT_COMPLETED = 'PROCUREMENT-COMPLETED';
  public WORK_PLAN_STATUS_PROCUREMENT_SUBMISSION = 'PROCUREMENT-SUBMISSION';
  public WORK_PLAN_STATUS_PAYMENT_COMPLETED = 'PAYMENT-COMPLETED';

  public WORK_PLAN_STATUS_TYPE_ON_PROCESS_WORK_PLAN = 'ON-PROCESS-WORK-PLAN';
  public WORK_PLAN_STATUS_TYPE_ON_PROCESS_PROCUREMENT =
    'ON-PROCESS-PROCUREMENT';
  public WORK_PLAN_STATUS_TYPE_ON_PROCESS_PAYMENT = 'ON-PROCESS-PAYMENT';

  /* t1_procurement_status */
  public PROCUREMENT_STATUS_PURCHASE_REQUEST = 'PURCHASE-REQUEST';
  public PROCUREMENT_STATUS_PROCUREMENT_PROCESS = 'PROCUREMENT-PROCESS';
  public PROCUREMENT_STATUS_PAYMENT_PROCESS = 'PAYMENT-PROCESS';
  public PROCUREMENT_STATUS_CLOSED = 'CLOSED';

  public BUDGET_NONE = 0;
  public BUDGET_SAFE = 1;
  public BUDGET_WARNING = 2;
  public BUDGET_EXPIRED = 3;
}
