<div class="row main-row form-registration m-0" *ngIf="validOrInvalid === 0">
</div>

<div class="row main-row registration-confirmation form-registration m-0"
  *ngIf="validOrInvalid === 1">
  <app-card legend="Confirmation" header="reset-email.title">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{ 'reset-email.form.oldEmail' |
          translate }}</label>
        <label class="col-sm-7 text-left">{{ user.email }}</label>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{ 'reset-email.form.newEmail' |
          translate }}</label>
        <label class="col-sm-7 text-left">{{ user.proposeValue }}</label>
      </div>
      <div class="form-group row text-sm-right required">
        <label class="col-sm-3 control-label">{{ 'reset-email.form.password' |
          translate }}</label>
        <div class="col-7 text-left">
          <app-text-field autofocus="true" size="MD" formControlName="password"
            type="password" maxLength="32"
            tooltip="{{ 'reset-password-confirmation.passwordTooltip' | translate }}">
          </app-text-field>
        </div>
      </div>
      <br /><br /><br />
      <div class="button-group button-group-center">
        <app-button color="PRIMARY" (onClick)="doSave()">{{ 'app.button.save' |
          translate }}</app-button>
      </div>
    </form>
  </app-card>
</div>