import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Faq } from '../../core/bean/faq';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './faq.component.html'
})
export class FaqComponent extends BaseModuleComponent {
  public faq: string;
  public qaList: Array<any>;
  public keyword: FormControl = new FormControl(null);
  constructor(translate: TranslateService) {
    super('faq', translate);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFaqState();
  }

  private buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required()],
      email: [
        null,
        Validators.compose([Validators.required(), Validators.email()])
      ],
      question: [null, Validators.required()]
    });
  }

  public doResetSearch(): void {
    this.keyword.reset();
  }

  private setFaqState(): void {
    this.httpClientService
      .get<Faq[]>('/faq/get-faq-list/' + this.global.getCurrentLang())
      .subscribe(faqList => {
        this.qaList = faqList;
        this.setStateReady();
      });
  }

  public doSendQuestion(): void {
    this.validate();
  }
}
