<app-layout [isLoading]="formLoading" header="order.breadcrumb.index">
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge
      *ngIf="todo !== 'add'"
      class="cml-2"
      [color]="badgeColor[response.order.orderStatus.code]"
    >
      {{ getTranslateKey(response.order.orderStatus) | translate }}
    </app-badge>
    <app-badge *ngIf="todo === 'add'" class="cml-2" color="INFO">
      {{ 'order.badge.status.new' | translate }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12">
      <app-alert-x *ngIf="isCanceled" color="DANGER" [isBold]="false">
        {{ 'order.alert.msg.thisProcessWasCanceledBy' | translate }}
        <strong>{{ orderCancel?.cancelByUser?.name }}</strong>
        {{ 'order.alert.msg.at' | translate }}
        <strong>
          {{
            orderCancel?.cancelDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}
        </strong>
        {{ 'order.alert.msg.theCancelationReasonIs' | translate }}
        <strong>&#34;{{ orderCancel?.cancelNote }}&#34;</strong>
      </app-alert-x>

      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        *ngIf="!formLoading"
        novalidate
      >
        <app-card header="order.details.title" tips="order.details.tips">
          <ng-template #headerRight>
            <div
              *ngIf="
                response.order?.orderStatus.code ===
                  global.appConstant.pm.ORDER_STATUS_ON_PROGRESS &&
                source !== global.appConstant.core.SOURCE_ORDER_CONTRACT
              "
            >
              <a
                class="text-primary"
                style="text-decoration: underline"
                (click)="goToDeliveryMonitoring($event)"
              >
                {{ 'order.hyperlink.monitoringDelivery' | translate }}
              </a>
              <!---->
              <span class="ml-1" (click)="goToDeliveryMonitoring($event)">
                <em
                  class="pir pi-external-link"
                  style="color: var(--primary)"
                  placement="top"
                ></em
              ></span>
            </div>
          </ng-template>

          <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>
          <div class="form-group row text-sm-left" *ngIf="todo !== 'add'">
            <label class="col-sm-3">
              {{ 'order.form.number' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-generated
                formControlName="code"
                maxLength="64"
                [moduleCode]="moduleCode"
                numberGeneratorCode="NUMBER"
              ></app-text-generated>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order.form.registeredOnDistributor' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <label>
                {{ response?.organization?.name || '-' }}
              </label>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order.form.distributionLocationCode' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <label>
                {{ response?.organization?.name || '-' }}
              </label>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order.form.requestorDepartment' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <label>
                {{ global.userSession?.user?.organization?.name || '-' }}
              </label>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order.form.requestor' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <label>
                {{ global.userSession?.user?.name || '-' }}
              </label>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order.form.title' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="title"
                placeholder="{{ 'order.placeholder.inputTitle' | translate }}"
                maxLength="128"
                type="alphanumeric"
              >
              </app-text-field>
            </div>
          </div>

          <div
             class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order.form.requiredDate' | translate }}
            </label>
            <div class="col-sm-9 text-left" *ngIf="prItemReleasedList.length > 0 && todo === 'add'">
              <label>
                {{ prShipping?.pr?.requiredDate | date: global.config.parameterModel.dateFormatShort }}
              </label>
            </div>
            <div class="col-sm-9 text-left" *ngIf="todo !== 'add'">
              <label formCon>
                {{ response?.order?.requiredDate | date: global.config.parameterModel.dateFormatShort }}
              </label>
            </div>
            <div class="col-sm-9 text-left" *ngIf="prItemReleasedList.length == 0 && todo === 'add'">
              <label>
                -
              </label>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label
              class="col-sm-3"
              [ngClass]="!formGroup.isView ? 'optional' : ''"
            >
              {{ 'order.form.description' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              <app-text-area
                formControlName="description"
                maxLength="512"
                size="XL"
              >
              </app-text-area>
            </div>
          </div>
        </app-card>

        <app-card>
          <small
            class="text-danger"
            *ngIf="response.prItemList.length === 0"
          >
            {{ 'app.validation.required' | translate }}
          </small>
          <app-table-xx
            #selectorItem
            [model]="tableResponseItem"
            (onClick)="
              !formGroup.isView ? doEditItem($event) : doViewItem($event)
            "
            *ngIf="!formLoading"
            [isShowSearch]="prItemList?.length !== 0"
            [isMultipleSelect]="false"
            tips="pr.detail.itemRequest.tips"
            header="pr.detail.itemRequest.title"
          >
            <ng-template #headerFilter>
              <form
                class="form-horizontal"
                [formGroup]="formGroup"
                novalidate
                *ngIf="prItemList?.length !== 0"
              >
                <div class="form-group row text-sm-left">
                  <div
                    class="col-sm-9 text-left"
                    [ngStyle]="{ 'min-width': '10rem' }"
                  >
                    <app-dropdown-select
                      size="XL"
                      [formControl]="itemType"
                      [optionList]="itemTypeOptionList"
                      (onChange)="onChangeItem()"
                      container="body"
                      placeholder="{{
                        'pr-verification.placeholder.type' | translate
                      }}"
                      [ngStyle]="{ 'min-width': '10rem' }"
                    >
                      <ng-template #value let-value>
                        {{ getTranslateKey(value) | translate }}
                      </ng-template>
                      <ng-template #option let-data>
                        {{ getTranslateKey(data) | translate }}
                      </ng-template></app-dropdown-select
                    >
                  </div>
                </div>
              </form>
            </ng-template>

            <ng-template #row let-state>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right" colspan="3">
                  {{ 'pr.form.goodsAmount' | translate }}
                </td>
                <td colspan="99" class="text-right">
                  <strong>
                    {{ formGroup.value.goodsAmount | resolveNumberToCurrency }}
                    {{ response?.companyCurrency.code }}
                  </strong>
                </td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right" colspan="3">
                  {{ 'pr.form.serviceAmount' | translate }}
                </td>
                <td colspan="99" class="text-right">
                  <strong>
                    {{
                      formGroup.value.serviceAmount | resolveNumberToCurrency
                    }}
                    {{ response?.companyCurrency.code }}
                  </strong>
                </td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right" colspan="3">
                  {{ 'pr.form.amount' | translate }}
                </td>
                <td colspan="99" class="text-right">
                  <strong>
                    {{ formGroup.value.amount | resolveNumberToCurrency }}
                    {{ response?.companyCurrency.code }}
                  </strong>
                  <br />
                  <i>
                    ({{
                      formGroup.value.amount
                        | resolveNumberToWords: global.currentLang
                    }}
                    {{ response?.companyCurrency.name }})
                  </i>
                </td>
              </tr>
            </ng-template>
          </app-table-xx>
        </app-card>

        <app-card
          header="pr-verification.detail.officialReport.title"
          tips="pr-verification.detail.officialReport.tips"
          *ngIf="prItemReleasedList.length > 0"
        >
          <app-official-report
            [objectId]="prItemReleasedList[0].prItem.pr.id"
            [templateCode]="global.appConstant.pm.ORT_PURCHASE_REQUEST_LETTER"
            [appOfficialReportModel]="appOfficialReportModel"
            mode="view"
            numberGeneratorCode="NUMBER"
            [isShowButtonGenerate]="false"
          ></app-official-report>
        </app-card>

        <app-card
          header="order.shippingAddress.title"
          tips="order.shippingAddress.tips"
        >

          <div
            *ngIf="
              (!orderShippingOptionList.getRequestValues() ||
              orderShippingOptionList.getRequestValues().length === 0)
            "
            class="no-data-wrapper"
          >
            <div class="book-wrapper pvc pv-file-list"></div>
            <div class="text-no-data">
              <span class="text-no-data">
                {{ 'app-table.noDataAvailable' | translate }}
              </span>
              <span class="text-try-to-add">
                {{ 'app-table.letsTryToAddList' | translate }}
              </span>
            </div>
          </div>

          <app-select
            *ngIf="
              orderShippingOptionList.getRequestValues() &&
              orderShippingOptionList.getRequestValues().length > 0
            "
            formControlName="orderShipping"
            [optionList]="orderShippingOptionList"
            [isMultiple]="true"
            maxHeight="max-content"
            [isShowHover]="false"
            [isShowInputControl]="false"
          >
            <ng-template #content let-data>
              <div
                style="
                  border: 1px solid var(--gray-400);
                  border-radius: 5px;
                  box-sizing: border-box;
                  padding: 1rem;
                "
              >
              <p>{{ orderShipping?.address?.organization?.name }}</p>
              <h5>{{ getUserNameList(data.address.addressPicList) }}</h5>
              <p>{{ orderShipping?.address?.addressDetail }}</p>
              <p>{{ orderShipping?.address?.phone }}</p>
              </div>
            </ng-template>
          </app-select>
        </app-card>

        <app-card
          header="order.workDefinition.title"
          tips="order.workDefinition.tips"
        >
          <app-sow
            *ngIf="!formLoading && isAppSowReady"
            [isView]="formGroup.isView || isSourceContractView"
            [sowList]="sowList"
            [prItemReleasedList]="prItemReleasedList"
            [amount]="amount"
            [isDisabled]="orderShippingOptionList.getRequestValues().length === 0"
            [source]="source"
            [isPo]="true"
            (onChange)="onChangeSowList($event)"
          ></app-sow>
          <br />
          <!--<app-fines
            *ngIf="!formLoading && isSourceReady"
            [isView]="formGroup.isView || isSourceContractView"
            [finesList]="finesList"
            [amount]="amount"
            [isDisabled]="!isSourceReady"
            (onChange)="onChangeFinesList($event)"
          ></app-fines>

          <br />
          <app-guarantee
            *ngIf="!formLoading && isSourceReady"
            [isView]="formGroup.isView || isSourceContractView"
            [guaranteeList]="guaranteeList"
            [amount]="amount"
            [isDisabled]="!isSourceReady"
            (onChange)="onChangeGuaranteeList($event)"
          ></app-guarantee>-->

          <br />
          <br />
        </app-card>

        <app-card
          header="pr.detail.officialReport.title"
          tips="pr.detail.officialReport.tips"
        >
          <app-alert-x color="WARNING" *ngIf="isOfficialReportNull && prId">{{
            'pr.detail.officialReport.alertInfo' | translate
          }}</app-alert-x>
          <app-alert-x
            color="WARNING"
            *ngIf="
              isOfficialReportNull === false &&
              !appOfficialReportModel.isGenerate
            "
            >{{
              'pr.detail.officialReport.regenerate' | translate
            }}</app-alert-x
          >
          <!-- <app-alert-x color="WARNING" *ngIf="!prId">{{
            'pr.detail.officialReport.saveDraftFirst' | translate
          }}</app-alert-x> -->

          <app-official-report
            [objectId]="formGroup.get('id').value ? formGroup.get('id').value : 0"
            [templateCode]="global.appConstant.pm.ORT_PURCHASE_REQUEST_LETTER"
            [appOfficialReportModel]="appOfficialReportModel"
            [mode]="todo"
            numberGeneratorCode="NUMBER"
            (onChange)="onChangeOfficialReport($event)"
            [isShowButtonGenerate]="!formGroup.isView"
            [include]="['subject', 'priority', 'location']"
          ></app-official-report>
        </app-card>

        <app-card
          header="order.vendorOrderConfirmation.title"
          tips="order.vendorOrderConfirmation.tips"
          *ngIf="response.orderActivityHistory"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order.form.orderActStatus' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <div
                *ngIf="response.orderActivityHistory"
                style="display: inline-flex"
              >
                <em
                  style="font-size: 1.5rem"
                  class="pic pi-thumbs-up-circle"
                  *ngIf="
                    response.orderActivityHistory?.orderActivityStatus.code ===
                    global.appConstant.pm.ORDER_ACTIVITY_STATUS_CONFIRMED
                  "
                >
                </em>
                <em
                  style="font-size: 1.5rem"
                  class="pic pi-pencil-circle"
                  *ngIf="
                    response.orderActivityHistory?.orderActivityStatus.code ===
                    global.appConstant.pm.ORDER_ACTIVITY_STATUS_REVISED
                  "
                >
                </em>
                <em
                  style="font-size: 1.5rem"
                  class="pic pi-times-circle"
                  *ngIf="
                    response.orderActivityHistory?.orderActivityStatus.code ===
                    global.appConstant.pm.ORDER_ACTIVITY_STATUS_REJECTED
                  "
                >
                </em>
                &nbsp;<span class="ml-1">
                  {{ response.orderActivityHistory?.orderActivityStatus.name }}
                </span>
              </div>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order.form.confirmationDate' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              {{
                response.orderActivityHistory?.processedDate
                  | date: global.config.parameterModel.dateFormatShort
              }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order.form.noteFromVendor' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              {{ response.orderActivityHistory?.note || '-' }}
            </div>
          </div>
          <!-- formcontrol text area response to Confirm -->
          <div
            *ngIf="
              response.order?.orderStatus.code ===
                this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION ||
              formGroup.value.responseToConfirmNote
            "
            class="form-group row text-sm-left"
          >
            <label class="col-sm-3">
              {{ 'order.form.responseToConfirmation' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-area
                formControlName="responseToConfirmNote"
                maxLength="512"
              ></app-text-area>
            </div>
          </div>
        </app-card>
      </form>

      <app-approval-monitoring
        *ngIf="
          !formLoading &&
          response.approvalPathResponseModel &&
          !response.isUserApproval &&
          response?.order?.orderStatus?.code &&
          this.statusForApprovalList.includes(
            response?.order?.orderStatus?.code
          )
        "
        [workflowPrcs]="response?.order?.workflowPrcs"
        moduleCode="order-response"
        [approvalPathResponseModel]="response.approvalPathResponseModel"
      ></app-approval-monitoring>

      <app-card
        header="order.detail.changeHistory.title"
        tips="order.detail.changeHistory.tips"
        *ngIf="isShowChangeHistory()"
      >
        <app-change-history
          *ngIf="!formLoading"
          [moduleId]="response.module?.id"
          [objectId]="orderId"
        ></app-change-history>
      </app-card>

      <app-card
        *ngIf="
          response?.order &&
          response?.order?.orderStatus?.code !==
            this.global.appConstant.pm.ORDER_STATUS_DRAFT
        "
        header="order.orderActHistory.title"
        tips="order.orderActHistory.tips"
      >
        <app-table-xx [model]="tableResponseOrderActHistory"> </app-table-xx>
      </app-card>

      <div class="floating-button mt-5">
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button
                color="DANGER"
                [outline]="true"
                (onClick)="doCancel()"
                *ngIf="
                  (statusCancelList.includes(
                    response.order?.orderStatus.code
                  ) ||
                    (response.order?.orderStatus.code ===
                      global.appConstant.pm.ORDER_STATUS_ON_PROGRESS &&
                      (response.order?.dr?.percentage === 0 ||
                        !response.order?.dr?.percentage))) &&
                  isRoleTypeCommittee()
                "
              >
                {{ 'app.button.cancel' | translate }}</app-button
              >

              <app-button
                [outline]="true"
                (onClick)="doClose()"
                *ngIf="
                  response.order?.orderStatus.code ===
                    this.global.appConstant.pm.ORDER_STATUS_ON_PROGRESS &&
                  response.order?.dr?.percentage > 0 &&
                  isRoleTypeCommittee()
                "
              >
                {{ 'app.button.close' | translate }}</app-button
              >

              <app-button
                (onClick)="doSaveDraft()"
                color="PRIMARY"
                [outline]="true"
                *ngIf="
                  !formGroup.isView &&
                  (todo === 'add' ||
                    response.order?.orderStatus.code ===
                      this.global.appConstant.pm.ORDER_STATUS_DRAFT)
                "
                [disabled]="prItemReleasedList.length === 0"
              >
                {{ 'app.button.save' | translate }}</app-button
              >
              <app-button
                (onClick)="doSubmit()"
                *ngIf="!formGroup.isView && !isRevisions"
                [disabled]="prItemReleasedList.length === 0"
              >
                {{ 'app.button.submit' | translate }}</app-button
              >

              <app-button
                *ngIf="isRevisions"
                (onClick)="doSubmit()"
                color="PRIMARY"
                [outline]="true"
                [disabled]="prItemReleasedList.length === 0"
              >
                {{ 'order.button.resend' | translate }}
              </app-button>
            </div>
          </div>
        </div>
      </div>

      <app-approval-prcs-x
        *ngIf="response.isUserApproval"
        [workflowModelPrcs]="response.order?.workflowModelPrcs"
        [approvalModelPrcsId]="approvalModelPrcsId"
      >
      </app-approval-prcs-x>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
