import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppApprovalPrcsModule } from 'src/app/core/components/app-approval-prcs/app-approval-prcs.module';
import { AppWorkflowProgressInfoModule } from 'src/app/core/components/app-workflow-progress-info/app-workflow-progress-info.module';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { ReviewContractEditAddComponent } from './review-contract-edit-add.component';
import { ReviewContractComponent } from './review-contract.component';

export const routes = [
  { path: '', component: ReviewContractComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: ReviewContractEditAddComponent,
    data: { breadcrumb: 'review-contract.breadcrumb.edit' }
  },
  {
    path: 'detail',
    component: ReviewContractEditAddComponent,
    data: { breadcrumb: 'review-contract.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppApprovalPrcsModule,
    AppWorkflowProgressInfoModule
  ],
  declarations: [ReviewContractComponent, ReviewContractEditAddComponent],
  entryComponents: [],
  exports: []
})
export class ReviewContractModule {}
