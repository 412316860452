import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  selector: 'app-button-action-group',
  templateUrl: './app-button-action-group.component.html',
  styleUrls: ['./app-button-action-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppButtonActionGroupComponent extends BaseComponentComponent {
  @Input() public position: string;

  constructor() {
    super('app-button-action');
  }

  onInit(): void {
    this.setStateReady();
  }
}
