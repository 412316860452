import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { ScheduleHistoryDtl } from 'src/app/core/bean/schedule-history-dtl';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';

@Component({
  templateUrl: './app-popup-schedule-history-dtl.component.html'
})
export class AppPopupScheduleHistoryDtlComponent extends BaseComponentComponent {
  @Input() public scheduleHistoryId: number;
  public tableResponseChangeHistoryDtl: TableResponseModel<ScheduleHistoryDtl>;

  constructor(
    public translateService: TranslateService,
    public datePipe: DatePipe
  ) {
    super('procurement-schedule');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponseChangeHistoryDtl = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'module.name',
          header: 'table.column.stage',
          plugins: [
            {
              name: 'default'
            },
            {
              name: 'badge',
              pill: false,
              className: 'badge-catalog ml-3',
              colorMap: {
                MODIFIED: 'INFO'
              },
              callbacks: {
                text: (tablePluginData: TablePluginData): string => {
                  if (tablePluginData.row.record.isModified) {
                    const MODIFIED: string = this.translateService.instant(
                      'procurement-schedule.info.modified'
                    );
                    return MODIFIED;
                  }
                },
                color: (tablePluginData: TablePluginData): string => {
                  if (tablePluginData.row.record.isModified) {
                    return 'MODIFIED';
                  }
                }
              }
            },
            {
              name: 'custom-plugin',
              after: (tablePluginData: TablePluginData): void => {
                if (!tablePluginData.row.record.isModified) {
                  tablePluginData.element.children[1].remove();
                }
              }
            }
          ]
        },
        {
          field: 'beforeStartDate',
          header: 'table.column.dateBeforeChange',
          plugins: [
            { name: 'date', format: 'short-date-and-time' },
            {
              name: 'custom-plugin',
              before: (tablePlugin: TablePluginData): string => {
                if (tablePlugin.row.record.beforeStartDate) {
                  return (
                    this.datePipe.transform(
                      tablePlugin.row.record.beforeStartDate,
                      this.global.config.parameterModel.datetimeFormatShort
                    ) +
                    ' - ' +
                    this.datePipe.transform(
                      tablePlugin.row.record.beforeEndDate,
                      this.global.config.parameterModel.datetimeFormatShort
                    )
                  );
                }
              }
            }
          ]
        },
        {
          field: 'afterStartDate',
          header: 'table.column.dateAfterChange',
          plugins: [
            { name: 'date', format: 'short-date-and-time' },
            {
              name: 'custom-plugin',
              before: (tablePlugin: TablePluginData): string => {
                return (
                  this.datePipe.transform(
                    tablePlugin.row.record.afterStartDate,
                    this.global.config.parameterModel.datetimeFormatShort
                  ) +
                  ' - ' +
                  this.datePipe.transform(
                    tablePlugin.row.record.afterEndDate,
                    this.global.config.parameterModel.datetimeFormatShort
                  )
                );
              }
            }
          ]
        },
        {
          field: 'beforeLocation',
          header: 'table.column.locationBeforeChange'
        },
        {
          field: 'afterLocation',
          header: 'table.column.locationAfterChange'
        }
      ]
    );

    this.tableResponseChangeHistoryDtl.setCustomData(
      this.scheduleHistoryId as any
    );
  }
}
