import { CurrencyPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { FileUploader } from 'src/app/core/components/upload';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Contract } from '../../core/bean/contract';
import { ContractClosed } from '../../core/bean/contract-closed';
import { ProcurementScopeWork } from '../../core/bean/procurement-scope-work';
import { Validators } from '../../core/validators';
import { DeliveryMonitoringClosedContractRequest } from './request/delivery-monitoring-closed-contract.request';

@Component({
  templateUrl: './app-popup-request-closed-contract.component.html'
})
export class AppPopupRequestClosedContractComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() todo: string;
  @Input() procurementScopeWork: ProcurementScopeWork;
  @Output()
  public onChange: EventEmitter<DeliveryMonitoringClosedContractRequest> = new EventEmitter();
  public isAllUploaded = false;
  public contractClosed: ContractClosed = new ContractClosed();
   public fileUploader: FileUploader = new FileUploader(
    '/delivery-monitoring/',
    '',
    this.global.appConstant.fileType.DOC_CLOSED_CONTRACT
  );

  constructor(
    public translateService: TranslateService,
    public currency: CurrencyPipe
  ) {
    super('delivery-monitoring');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setStateReady();
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      contractFile: [null, Validators.required()],
      description: [null]
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const deliveryMonitoringClosedContractRequest = new DeliveryMonitoringClosedContractRequest();
            deliveryMonitoringClosedContractRequest.procurementScopeWorkId = this.procurementScopeWork.id;
            deliveryMonitoringClosedContractRequest.description =
              this.formGroup.value.description;
            deliveryMonitoringClosedContractRequest.fileObjectList =
              this.formGroup.value.contractFile;

            this.httpClientService
              .post<Response<Contract>>(
                '/delivery-monitoring/update-contract-status',
                deliveryMonitoringClosedContractRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.onChange.emit(response);
                  this.setStateReady();
                  this.global.alertService.showSuccessOnNextRoute(
                      this.global.translateService.instant(
                        'app.alert.msg.saveSuccess'
                      )
                    );
                  this.router.navigate(['/pages/delivery-monitoring']);
                } else {
                  this.onChange.emit(response);
                  this.setStateReady();
                  this.global.alertService.showErrorOnNextRoute(response.statusText);
                  this.router.navigate(['/pages/delivery-monitoring']);
                }
                this.setStateReady();
              });
          }
        });
    }
  }
}
