import { Pipe, PipeTransform } from '@angular/core';
import { createDateString } from './helper/create-date-picker-date-helper';
import { DatePickerValue } from './type/date-picker-value-type';

@Pipe({ name: 'resolveDatePickerClassName' })
export class ResolveDatePickerClassNamePipe implements PipeTransform {
  transform(
    currentDateString: number,
    monthPosition: 'PREVIOUS' | 'CURRENT' | 'NEXT',
    isCurrentDate: boolean,
    index: number,
    value: DatePickerValue,
    startDateString: number,
    onDateString: number,
    endDateString: number,
    isRange: boolean
  ): string {
    let className = monthPosition.toLowerCase() + '-month';

    // set holiday color
    if (index % 7 === 0) {
      className += ' holiday';
    }

    // set current date or today color or border
    if (isCurrentDate) {
      className += ' current-date';
    }

    // resolve range class. Make sure starRange and onRange exist.
    if (isRange) {
      if (startDateString && (onDateString || endDateString)) {
        if (
          ((startDateString && onDateString) || endDateString) >=
          startDateString
        ) {
          if (!endDateString) {
            if (currentDateString === startDateString) {
              className += ' on-start-range';
            }

            if (
              currentDateString > startDateString &&
              currentDateString < onDateString
            ) {
              className += ' on-range';
            }

            if (currentDateString === onDateString) {
              className += ' on-end-range';
            }
          } else {
            if (currentDateString === startDateString) {
              className += ' start-range';
            }

            if (
              currentDateString > startDateString &&
              currentDateString < endDateString
            ) {
              className += ' on-range';
            }

            if (currentDateString === endDateString) {
              className += ' end-range';
            }
          }
        } else if ((onDateString || endDateString) <= startDateString) {
          if (!endDateString) {
            if (currentDateString === startDateString) {
              className += ' on-end-range';
            }

            if (
              currentDateString < startDateString &&
              currentDateString > onDateString
            ) {
              className += ' on-range';
            }

            if (currentDateString === onDateString) {
              className += ' on-start-range';
            }
          }
        }
      }
    }

    if (!isRange) {
      if (value && currentDateString === createDateString(value as Date)) {
        className += ' active';
      }
    }
    return className;
  }
}
