<!-- <div class="row" *ngIf="!formLoading"> -->
<div class="row main-row-manage-catalog">
  <div class="col-12">
    <app-card
      header="{{ header }}"
      detail="app.action.{{ todo === 'view' ? 'detail' : todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        *ngIf="!formLoading"
        novalidate
      >
        <div
          class="form-group row text-sm-right ml-1"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'manage-catalog.form.attributeGroup' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              [optionList]="optionList"
              formControlName="attributeGroup"
              (onChange)="doChooseCatalogGroup($event)"
            ></app-combo-box>
          </div>
        </div>

        <div class="col-12 custom-tabs">
          <app-tab [model]="tabResponse" (onChangeTab)="doNext($event)">
            <router-outlet></router-outlet>

            <div class="col-sm-12 text-left" *ngIf="isVendor && todo !== 'view'">
              <app-check-box formControlName="termCondition">
                {{ 'registration.form.termsAndCondition1' | translate }}
                <a
                  (click)="doDisplayTermCondition()"
                  [ngStyle]="{ color: '#87b4e7' }"
                  >{{ 'registration.form.termsAndCondition2' | translate }}</a
                >
              </app-check-box>
            </div>

            <ng-template #tabFooter let-currentTab>
              <div class="button-group button-group-center mt-5">
                <app-button
                  color="SECONDARY"
                  (onClick)="doBack()"
                  *ngIf="currentTab.isFirstTab"
                  mode="BYPASS"
                  [disabled]="formSaving"
                  >{{ 'app.button.back' | translate }}
                </app-button>
                <app-button
                  color="SECONDARY"
                  (onClick)="doPrevious(currentTab.previousTab)"
                  *ngIf="
                    !currentTab.isFirstTab && currentTab.code !== 'VERIFICATION'
                  "
                  mode="BYPASS"
                  [disabled]="formSaving"
                  >{{ 'app.button.previous' | translate }}</app-button
                >
                <app-button
                  color="PRIMARY"
                  (onClick)="doNext(currentTab.nextTab)"
                  *ngIf="!currentTab.isLastTab"
                  mode="BYPASS"
                  [disabled]="formSaving"
                  >{{ 'app.button.next' | translate }}
                </app-button>
                <app-button
                  color="PRIMARY"
                  (onClick)="doSave()"
                  *ngIf="currentTab.isLastTab && !isGuest && !isTodoView"
                  [disabled]="formSaving"
                  >{{ 'app.button.save' | translate }}
                </app-button>
              </div>
            </ng-template>
          </app-tab>
        </div>
      </form>
    </app-card>
  </div>
</div>
