<div class="row main-row">
  <div class="col-lg-12 mb-4" *ngIf="!shippingCostListIsNotShowing">
    <form
      class="form-horizontal"
      [formGroup]="shippingCostTab.formGroup"
      novalidate
    >
      <ng-container formArrayName="shippingCostList">
        <ng-container
          *ngFor="
            let shippingCostLists of formArrayShippingCostList.controls;
            let i = index
          "
          [formGroup]="shippingCostLists"
        >
          <app-card>
            <div
              class="d-flex justify-content-end"
              *ngIf="!shippingCostTab.formGroup.isView"
            >
              <em
                class="fas fa-times text-danger cursor-pointer"
                (click)="doDeleteShippingCost(i)"
                title="{{ 'app.button.delete' | translate }}"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
              ></em>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'manage-catalog.form.shippingDestination' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="AUTOCOMPLETE"
                  formControlName="region"
                  [optionList]="regionShippingCostOptionList"
                >
                </app-dropdown-select>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'manage-catalog.form.amount' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-currency
                  formControlName="amount"
                  [optionList]="currencyOptionList"
                  maxLength="16"
                ></app-currency>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'manage-catalog.form.deliveryEstimateDayTotal' | translate
              }}</label>
              <div class="col-sm-6 text-left">
                <app-text-field
                  formControlName="deliveryEstimateDayTotal"
                  type="integer"
                  maxLength="11"
                  symbolic="{{ 'manage-catalog.symbolic.days' | translate }}"
                ></app-text-field>
              </div>
            </div>
          </app-card>
        </ng-container>
      </ng-container>

      <div
        class="text-center"
        *ngIf="!shippingCostTab.formGroup.isView"
      >
        <app-button color="PRIMARY" (onClick)="addMoreShippingCost()"
          ><em class="fas fa-plus"></em>
          {{ 'manage-catalog.button.addMore' | translate }}</app-button
        >
      </div>
    </form>
  </div>
</div>