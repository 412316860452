import { BaseEntity } from '../base/base-entity';
import { FormObject } from '../bean/form-object';
import { TranslationKey } from '../bean/translation-key';
export class CatalogAttributeEntity extends BaseEntity {
  fieldName: string;
  status: string;
  translationKey: TranslationKey;
  formObject: FormObject;
  listOfValue: string;
  description: string;
  isActive: boolean;
  function: string;
}
