import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Procurement } from '../../core/bean/procurement';
import { QuotationEvaluation } from '../../core/bean/quotation-evaluation';
import { AppOfficialReportModel } from '../../core/components/app-official-report/app-official-report-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { TechnicalEvaluationPopupDetailComponent } from './technical-evaluation-popup-detail.component';
import { TechnicalEvaluationRequest } from './technical-evaluation-request';
import { TechnicalEvaluationResponse } from './technical-evaluation-response';

@Component({
  templateUrl: './technical-evaluation-edit.component.html'
})
export class TechnicalEvaluationEditComponent
  extends BaseModuleComponent
  implements OnInit
{
  public procurementId: number;
  public procurement: Procurement;
  public urlBackOutside: string;
  public objectId: number;
  public approvalModelPrcsId: number;
  public technicalEvaluationResponse: TechnicalEvaluationResponse;
  public tableResponse: TableResponseModel<QuotationEvaluation>;
  public mode: string;
  public isHasWaitingScore = false;
  public isOfficialReportNull = false;
  public appOfficialReportModel: AppOfficialReportModel;
  public canceledOrFailedMessage: string;
  public isShowBadgeStatus: boolean;

  public badgeColor = {
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    NEW: 'INFO',
    DRAFT: 'SECONDARY',
    ON_PROGRESS: 'PROGRESS',
    NOT_STARTED: 'SECONDARY',
    CLOSED: 'DANGER',
    DONE: 'SUCCESS',
    EXTENDED: 'INFO',
    HOLD: 'DANGER',
    CANCELED: 'DANGER'
  };

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService // public ngbActiveModal: NgbActiveModal
  ) {
    super('technical-evaluation', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setIsViewOnly();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.procurement = this.global.routerParams.get('procurement');
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.objectId = this.global.routerParams.get('objectId');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<TechnicalEvaluationResponse>(
        '/technical-evaluation/edit',
        new RouteRequestModel(this.todo, this.procurementId)
      )
      .subscribe((response: TechnicalEvaluationResponse) => {
        this.technicalEvaluationResponse = response;
        this.procurement = response.procurement;

        const statusList = [
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
        ];

        if (statusList.includes(this.procurement.procurementStatus.code)) {
          if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'technical-evaluation.alert.msg.sorryWaitingCancelationApproval';
          } else if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'technical-evaluation.alert.msg.sorryWaitingFailedApproval';
          } else {
            this.canceledOrFailedMessage =
              'technical-evaluation.alert.msg.sorryFailedAssessment';
          }
        }

        this.buildTableResponse();
        if (
          this.procurement.biddingEvaluation.code !==
          this.global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
        ) {
          this.technicalEvaluationResponse.quotationEvaluationList =
            this.technicalEvaluationResponse.quotationEvaluationList.sort(
              (quotationEvalA, quotationEvalB) => {
                if (quotationEvalA.score) {
                  if (quotationEvalA.score >= quotationEvalB?.score) {
                    return -1;
                  }
                  if (quotationEvalA.score < quotationEvalB.score) {
                    return 1;
                  }
                }
              }
            );
        }
        this.tableResponse.setRecordList(
          this.technicalEvaluationResponse.quotationEvaluationList
        );

        this.appOfficialReportModel = response.appOfficialReportModel;
        this.tableResponse.reload();
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    if (
      this.procurement.biddingEvaluation.code ===
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
    ) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'quotation.procurementVendor.vendor.name',
          header: 'table.column.vendor',
          plugins: { name: 'hyperlink', onClick: this.onClickVendor.bind(this) }
        },
        {
          field: 'quotation.procurementVendor.vendor.email',
          header: 'table.column.email'
        },
        {
          field: 'evaluationDate',
          header: 'table.column.evaluationDate',
          plugins: {
            name: 'date',
            format: 'short-date-and-time'
          }
        },
        {
          field: 'scoringStatus.name',
          header: 'table.column.scoringStatus',
          plugins: {
            name: 'badge',
            colorField: 'scoringStatus.code',
            colorMap: {
              WAITING_SCORING: 'WARNING',
              PASSED: 'SUCCESS',
              NOT_PASSED: 'DANGER'
            }
          }
        }
      ]);
    } else {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'quotation.procurementVendor.vendor.name',
          header: 'table.column.vendor',
          plugins: { name: 'hyperlink', onClick: this.onClickVendor.bind(this) }
        },
        {
          field: 'quotation.procurementVendor.vendor.email',
          header: 'table.column.email'
        },
        {
          field: 'evaluationDate',
          header: 'table.column.evaluationDate',
          plugins: {
            name: 'date',
            format: 'short-date-and-time'
          }
        },
        {
          field: 'score',
          header: {
            key:
              this.procurement.biddingEvaluation.code ===
                this.global.appConstant.pm
                  .BIDDING_EVALUATION_CODE_MERIT_POINT &&
              this.technicalEvaluationResponse.procurementCriteria
                .isAppliedFinalScoring
                ? 'table.column.weightScore'
                : 'table.column.finalScore',
            tooltip: {
              content:
                this.translateService.instant(
                  'technical-evaluation.table.column.tooltip.minimumScore'
                ) +
                this.global.converterService.convertDecimal(
                  this.technicalEvaluationResponse.procurementCriteria
                    .minimumScore
                ) +
                ' ' +
                this.translateService.instant(
                  'technical-evaluation.table.column.tooltip.technicalScore'
                ) +
                this.global.converterService.convertDecimal(
                  this.technicalEvaluationResponse
                    .procurementFinalizationCriteria.technicalPercentage
                ) +
                '%',
              icon: 'pir pi-info-circle'
            }
          },
          className: 'text-right',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            isView: true
          }
        },
        {
          field: 'scoringStatus.name',
          header: 'table.column.scoringStatus',
          plugins: {
            name: 'badge',
            colorField: 'scoringStatus.code',
            colorMap: {
              WAITING_SCORING: 'WARNING',
              PASSED: 'SUCCESS',
              NOT_PASSED: 'DANGER'
            }
          }
        }
      ]);
    }
  }

  public onClickVendor(quotationEvaluation: QuotationEvaluation): void {
    const index = this.tableResponse
      .getRecordList()
      .indexOf(quotationEvaluation);
    this.appPopupService
      .open(
        TechnicalEvaluationPopupDetailComponent,
        {
          todo: this.todo,
          quotationEvaluation,
          scoringStatusList: this.technicalEvaluationResponse.scoringStatusList,
          procurementCriteria:
            this.technicalEvaluationResponse.procurementCriteria,
          procurementFinalizationCriteria:
            this.technicalEvaluationResponse.procurementFinalizationCriteria
        },
        { size: 'xl' }
      )
      .subscribe((quotationEvaluation: QuotationEvaluation) => {
        this.formGroup.markAsDirty();
        if (quotationEvaluation.id) {
          this.global.modalService
            .confirmation(
              this.translateService.instant(
                'technical-evaluation.modal.saveSuccess.msg'
              ),
              'technical-evaluation.modal.saveSuccess.title',
              null,
              null,
              'pvc pv-check-circle',
              false,
              {
                autoCloseInMs: 1000
              }
            )
            .subscribe();
          this.tableResponse.getRecordList()[index] = quotationEvaluation;
          const quotationEvaluationSorted = this.tableResponse
            .getRecordList()
            .sort((quotationEvalA, quotationEvalB) => {
              if (quotationEvalA.score) {
                if (quotationEvalA.score >= quotationEvalB?.score) {
                  return -1;
                }
                if (quotationEvalA.score < quotationEvalB.score) {
                  return 1;
                }
              }
            });
          this.tableResponse.setRecordList(quotationEvaluationSorted);
          this.tableResponse.reload();
        }
      });
  }

  public doSubmit(): void {
    if (
      this.tableResponse
        .getRecordList()
        .filter(
          data =>
            data.scoringStatus?.code ===
            this.global.appConstant.pm.SCORING_STATUS_WAITING_SCORING
        ).length > 0
    ) {
      this.isHasWaitingScore = true;
    } else {
      this.isHasWaitingScore = false;
      if (
        this.appOfficialReportModel.fileObjectList.length === 0 ||
        this.appOfficialReportModel.fileObjectList.filter(
          fileObject => fileObject.isUploaded
        ).length === 0 ||
        this.appOfficialReportModel.letterDate === null ||
        this.appOfficialReportModel.letterNumber === null
      ) {
        this.isOfficialReportNull = true;
      } else {
        this.isOfficialReportNull = false;
        this.validate();
        if (this.formGroup.valid) {
          const participantPassed = this.tableResponse
            .getRecordList()
            .filter(
              quotationEval =>
                quotationEval.scoringStatus.code ===
                this.global.appConstant.pm.SCORING_STATUS_PASSED
            ).length;
          if (
            participantPassed <
            this.technicalEvaluationResponse?.procurementParticipant
              ?.minimumParticipant
          ) {
            this.global.modalService
              .submitProcurementFailed(
                this.technicalEvaluationResponse.procurementParticipant
                  .minimumParticipant
              )
              .pipe(take(1))
              .subscribe(result => {
                if (result) {
                  this.doSubmitTechnicalEval(true);
                }
              });
          } else {
            this.global.modalService
              .submitConfirmation()
              .pipe(take(1))
              .subscribe(result => {
                if (result) {
                  this.doSubmitTechnicalEval(false);
                }
              });
          }
        }
      }
    }
  }

  public doSubmitTechnicalEval(isProcurementFailed: boolean): void {
    const technicalEvalRequest: TechnicalEvaluationRequest =
      new TechnicalEvaluationRequest();
    technicalEvalRequest.procurement = this.procurement;
    technicalEvalRequest.isProcurementFailed = isProcurementFailed;
    technicalEvalRequest.appOfficialReportModel = this.appOfficialReportModel;
    this.setStateProcessing();
    this.httpClientService
      .post<Response<Procurement>>(
        '/technical-evaluation/update',
        technicalEvalRequest
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.modalService
            .submitSuccess()
            .pipe(take(1))
            .subscribe(result => {
              if (!result) {
                this.router
                  .navigateByUrl('/', { skipLocationChange: true })
                  .then(() => {
                    this.global.routerParams.clear();
                    this.global.routerParams.set('procurement', response.body);
                    this.global.routerParams.set(
                      'procurementId',
                      response.body.id
                    );
                    this.global.routerParams.set(
                      'urlBackOutside',
                      '/pages/worklist-pm'
                    );

                    this.global.routerParams.set('todo', 'view');
                    this.router.navigate([
                      '/pages/technical-evaluation/detail'
                    ]);
                  });
                this.setStateReady();
              } else {
                this.router.navigate(['/pages/worklist-pm/']);
              }
              this.setStateReady();
            });
        }
      });
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public doOnChange(event: AppOfficialReportModel): void {
    this.appOfficialReportModel = event;
  }
}
