import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ContractType } from '../../core/bean/contract-type';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './contract-type-edit-add.component.html'
})
export class ContractTypeEditAddComponent extends BaseModuleComponent {
  public contractTypeId: number;
  constructor(translateService: TranslateService) {
    super('contract-type', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.contractTypeId = this.global.routerParams.get('contractTypeId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [
        null,
        {
          validators: [Validators.required(), Validators.maxLength(128)],
          asyncValidators: [
            Validators.existsAsync('/contract-type/check-exist', null, null, {
              id: this.contractTypeId
            })
          ]
        }
      ],
      code: [
        null,
        {
          validators: [Validators.required(), Validators.maxLength(32)],
          asyncValidators: [
            Validators.existsAsync('/contract-type/check-exist', null, null, {
              id: this.contractTypeId
            })
          ]
        }
      ],
      description: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ContractType>(
        '/contract-type/add-edit',
        new RouteRequestModel(this.todo, this.contractTypeId)
      )
      .subscribe(contractType => {
        if (contractType != null) {
          this.formGroup.patchValue(contractType);
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const contractType = this.global.copyFormAttributeToModel(
              new ContractType(),
              this.formGroup
            );
            const url =
              this.todo === 'edit'
                ? '/contract-type/update'
                : '/contract-type/insert';
            this.httpClientService
              .post<Response<ContractType>>(url, contractType)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/contract-type']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/contract-type']);
  }
}
