import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AppPopupResourceService } from '../../core/components/app-popup/app-popup-resource/app-popup-resource.service';
import { TreeEvent, TreeModel } from '../../core/components/app-tree';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './region.component.html'
})
export class RegionComponent extends BaseModuleComponent {
  public optionList: OptionListModel<number> = new OptionListModel();
  public treeModel: TreeModel = new TreeModel(this.moduleCode, 'WRITE');
  constructor(
    translateService: TranslateService,
    private appPopupResourceService: AppPopupResourceService
  ) {
    super('region', translateService);
  }

  public onInit(): void {
    this.buildTreeFormGroup();
    this.setStateReady();
    const valueList: number[] = [];
    valueList.push(0);
    valueList.push(1);
    valueList.push(2);
    valueList.push(3);
    valueList.push(4);
    valueList.push(5);

    this.optionList.setRequestValues(valueList);
  }

  public buildTreeFormGroup(): void {
    this.treeModel.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      callingCode: [null, Validators.maxLength(8)],
      postcode: [null, Validators.maxLength(8)],
      value: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.max(5),
          Validators.min(0)
        ])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      resource: [null],
      parentId: null,
      tempId: [null],
      hasChild: [null]
    });
  }

  public doChooseFlagIcon(): void {
    this.appPopupResourceService.open().subscribe(data => {
      this.treeModel.formGroup.patchValue({ resource: data });
    });
  }

  public doOnEventChange(treeEvent: TreeEvent): void {
    if (treeEvent.type === 'ON-SHOW-POPUP') {
      const id = this.formGroup.value.id;
      treeEvent.formGroup.get('code').setAsyncValidators(
        Validators.existsAsync('/organization/isAlreadyExist', null, null, {
          id
        })
      );
    } else if (treeEvent.type === 'ON-CLOSE-POPUP') {
      treeEvent.formGroup.get('code').clearAsyncValidators();
    }
  }
}
