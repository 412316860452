<app-popup header="tab-partnership.popup.partnership" [isLoading]="formLoading">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-partnership.form.partnership' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorPartnershipHistory.partnership !== null
                ? vendorPartnershipHistory.partnership
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-partnership.form.country' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorPartnershipHistory.countryRegion !== null
                ? vendorPartnershipHistory.countryRegion.name
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-partnership.form.city' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorPartnershipHistory.cityRegion !== null
                ? vendorPartnershipHistory.cityRegion.name
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-partnership.form.address' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            {{
              vendorPartnershipHistory.address !== null
                ? vendorPartnershipHistory.address
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right" *ngIf="!formLoading">
          <label class="col-sm-3 control-label">{{
            'tab-partnership.form.coordinateLocation' | translate
          }}</label>
          <div class="col-sm-7 text-left">
            {{
              vendorPartnershipHistory.coordinateLocation !== null
                ? vendorPartnershipHistory.coordinateLocation
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-partnership.form.statusPartnership' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorPartnershipHistory.partnershipStatus !== null
                ? vendorPartnershipHistory.partnershipStatus
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-partnership.form.projectOwner' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorPartnershipHistory.projectOwner !== null
                ? vendorPartnershipHistory.projectOwner
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-partnership.form.projectType' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorPartnershipHistory.projectType !== null
                ? vendorPartnershipHistory.projectType
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-partnership.form.segmentation' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ segmentationList !== null ? segmentationList : '-' }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-partnership.form.status' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorPartnershipHistory.vendorExperienceStatus !== null
                ? vendorPartnershipHistory.vendorExperienceStatus.name
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-partnership.form.contractNumber' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorPartnershipHistory.contractNumber !== null
                ? vendorPartnershipHistory.contractNumber
                : '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-partnership.form.contractDate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              vendorPartnershipHistory.contractDate === null
                ? '-'
                : (vendorPartnershipHistory.contractDate | date: 'dd/MM/yyyy')
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-partnership.form.contractValue' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ convertContract ? convertContract : '-' }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-partnership.form.proofOfContract' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div *ngIf="vendorPartnershipHistory.file == null"> - </div>
            <div *ngIf="vendorPartnershipHistory.file != null">
              <a
                href="#"
                (click)="
                  doDownload(
                    $event,
                    '/company-profile/file/view/' +
                      vendorPartnershipHistory.file.uploadedFileName +
                      '/' +
                      global.appConstant.fileType.DOC_PROOF_CONTRACT,
                    vendorPartnershipHistory.file.fileName
                  )
                "
              >
                {{ vendorPartnershipHistory.file.fileName }}
              </a>
            </div>
          </div>
        </div>
        <ng-template #modalFooter let-activeModal>
          <div class="button-group button-group-center">
            <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
              'app.button.close' | translate
            }}</app-button>
          </div>
        </ng-template>
      </form>
    </div>
  </div>
</app-popup>
