import { BaseEntity } from '../base/base-entity';
import { ModuleScoringCriteria } from './../bean/module-scoring-criteria';
import { ProcurementType } from './../bean/procurement-type';
import { VendorType } from './../bean/vendor-type';
export class ScoringTemplateEntity extends BaseEntity {
  moduleScoringCriteria: ModuleScoringCriteria;
  vendorType: VendorType;
  procurementType: ProcurementType;
  name: boolean;
  description: boolean;
  minimumQualified: number;
  objectId: number;
  objectName: string;
}
