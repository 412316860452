import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { User } from '../../core/bean/user';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { RecapPrintService } from '../../core/services/recap-print.service';
import { UserActionModel } from './model/user-action-model';
@Component({
  templateUrl: './user-action-view.component.html'
})
export class UserActionViewComponent extends BaseModuleComponent {
  public user: User;
  public tableResponse: TableResponseModel<UserActionModel>;
  public isLoading: boolean;
  constructor(
    translateService: TranslateService,
    public recapPrintService: RecapPrintService
  ) {
    super('user-action', translateService);
  }

  onInit(): void {
    this.doSetUserFromRouterParams();
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.doSetCustomDataTable();
    this.setStateReady();
  }

  public doSetUserFromRouterParams(): void {
    this.user = this.global.routerParams.get('user');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      userId: [this.user.id],
      logDate: [null]
    });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'userName', header: 'table.column.user' },
      {
        field: 'roleName',
        header: 'table.column.role',
        format: FieldFormatEnum.Html
      },
      { field: 'organizationName', header: 'table.column.organization' },
      { field: 'action', header: 'table.column.action' },
      {
        field: 'logDate',
        header: 'table.column.logDate',
        format: FieldFormatEnum.ShortDateAndTime
      }
    ]);
  }

  public doSetCustomDataTable(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
  }

  public doApply(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.formGroup.get('userId').setValue(this.user.id);
    this.tableResponse.setCustomData({ userId: this.user.id });
    this.tableResponse.reload();
  }

  public doBack(): void {
    this.router.navigate(['/pages/user-action']);
  }

  public doExport(): void {
    this.isLoading = true;
    this.recapPrintService
      .print('/user-action/recap-user-action', this.tableResponse.request)
      .subscribe(() => {
        this.isLoading = false;
      });
  }
}
