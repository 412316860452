import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TeamProject } from '../../core/bean/team-project';
import { WorkPlan } from '../../core/bean/work-plan';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { ApprovalPathModel } from '../../core/components/app-approval-path/model/approval-path-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { TeamProjectForm } from '../team-project/model/team-project-form-model';
import { WorkflowPrcs } from './../../core/bean/workflow-prcs';
import { RouteRequestModel } from './../../core/model/route-request-model';
import { ProjectTeamSubmissionResponse } from './project-team-submission-response';
@Component({
  templateUrl: './project-team-submission-edit-add.component.html',
  styleUrls: ['./project-team-submission-edit-add.component.scss']
})
export class ProjectTeamSubmissionEditAddComponent extends BaseModuleComponent {
  public teamProject: TeamProject = new TeamProject();
  public url: string;
  public workflowModelPrcsId: number;
  public workPlan: WorkPlan;
  public workPlanId: number;
  public teamProjectList: TeamProject[] = new Array();
  public teamProjectFormList: TeamProjectForm[];
  public isReady = false;
  public objectId: number;
  public workflowPrcs: WorkflowPrcs;
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public projectTeamSubmissionResponse: ProjectTeamSubmissionResponse =
    new ProjectTeamSubmissionResponse();
  public isDisabled: boolean;
  public approvalPath: ApprovalPathModel<any> = new ApprovalPathModel(
    this.moduleCode,
    'READ'
  );
  public readonly TEAM_PROJECT_CHIEF = 1;
  public readonly TEAM_PROJECT_SECRETARY = 2;
  public readonly TEAM_PROJECT_MEMBER = 3;

  constructor(translateService: TranslateService) {
    super('project-team-submission', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.buildTeamProjectFormList();
    this.setFormGroup();
  }

  setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.objectId = this.global.routerParams.get('objectId');
    this.workPlan = this.global.routerParams.get('workPlan');
    this.workPlanId = this.objectId;
    if (this.objectId == null) {
      this.workPlan = this.global.routerParams.get('workPlan');
      this.workPlanId = this.workPlan.id;
      this.workflowPrcs = this.workPlan.workflowPrcs;
      this.workflowModelPrcs = this.workPlan.workflowModelPrcs;
    }
  }

  buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      workPlan: this.workPlan
    });
  }
  setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.isDisabled = true;
      this.setViewOnly();
    }
  }

  buildTeamProjectFormList(): void {
    this.teamProjectFormList = [
      {
        label: 'project-team-submission.form.chief',
        type: this.TEAM_PROJECT_CHIEF,
        maxOfficer: 1,
        teamProjectList: []
      },
      {
        label: 'project-team-submission.form.secretary',
        type: this.TEAM_PROJECT_SECRETARY,
        maxOfficer: 1,
        teamProjectList: []
      },
      {
        label: 'project-team-submission.form.member',
        type: this.TEAM_PROJECT_MEMBER,
        teamProjectList: []
      }
    ];
  }

  setFormGroup(): void {
    this.httpClientService
      .post<ProjectTeamSubmissionResponse>(
        '/project-team-submission/add-edit',
        new RouteRequestModel(
          this.todo,
          this.objectId != null ? this.objectId : this.workPlanId
        )
      )
      .subscribe(projectTeamResponse => {
        this.projectTeamSubmissionResponse = projectTeamResponse;
        if (this.objectId != null) {
          this.workPlan = this.projectTeamSubmissionResponse.workPlan;
          this.workPlanId = this.workPlan.id;
          this.workflowPrcs = this.workPlan.workflowPrcs;
          this.workflowModelPrcs = this.workPlan.workflowModelPrcs;
        }
        if (
          projectTeamResponse.teamProjectList != null &&
          projectTeamResponse.teamProjectList.length !== 0
        ) {
          this.todo = this.todo !== 'view' ? 'edit' : 'view';
          if (
            this.projectTeamSubmissionResponse.approvalPathResponseModel !=
              null &&
            !this.projectTeamSubmissionResponse.isUserApproval
          ) {
            this.approvalPath
              .setApproverList(
                this.projectTeamSubmissionResponse.approvalPathResponseModel
                  .userApproverList
              )
              .setType(
                this.projectTeamSubmissionResponse.approvalPathResponseModel
                  .type
              );
          }
          this.formGroup.patchValue({
            workPlan: projectTeamResponse.teamProjectList[0].workPlan
          });
          if (this.todo === 'view') {
            this.isDisabled = true;
          }
          this.buildTeamProject(projectTeamResponse.teamProjectList);
        } else if (
          (!this.projectTeamSubmissionResponse.teamProjectList ||
            (this.projectTeamSubmissionResponse.teamProjectList &&
              this.projectTeamSubmissionResponse.teamProjectList.length ===
                0)) &&
          this.todo !== 'add'
        ) {
          this.global.routerParams.clear();
          this.global.routerParams.set('todo', 'add');
          this.global.routerParams.set('workPlan', this.workPlan);
          this.router.navigate(['/pages/project-team-submission/add']);
        }
        this.isReady = true;
        this.setStateReady();
      });
  }

  buildTeamProject(teamProjectList: TeamProject[]): void {
    this.teamProjectFormList.forEach(teamProjectForm => {
      teamProjectList.forEach(teamProject => {
        const newTeamProject = new TeamProject();
        if (teamProject.type === teamProjectForm.type) {
          newTeamProject.position = teamProject.position;
          newTeamProject.organization = teamProject.organization;
          newTeamProject.user = teamProject.user;
          teamProjectForm.teamProjectList.push(newTeamProject);
        }
      });
    });
  }

  public doSave(): void {
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.teamProject = this.global.copyFormAttributeToModel(
            this.teamProject,
            this.formGroup
          );
          this.teamProject.workPlan.workflowModelPrcs =
            this.workPlan.workflowModelPrcs;
          this.teamProjectList = [];
          this.teamProjectFormList.forEach(teamProjectForm => {
            teamProjectForm.teamProjectList.forEach(teamProject => {
              teamProject.id = this.teamProject.id;
              teamProject.workPlan = this.teamProject.workPlan;
              teamProject.type = teamProjectForm.type;
              this.teamProjectList.push(teamProject);
            });
          });

          if (this.todo !== 'add') {
            this.url = '/project-team-submission/update';
          } else {
            this.url = '/project-team-submission/insert';
          }

          this.httpClientService
            .post<Response<TeamProject[]>>(this.url, this.teamProjectList)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessSavingOnNextRoute();
                this.router.navigate(['/pages/worklist-pra-procurement']);
              } else {
                this.setStateReady();
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doBack(): void {
    this.router.navigate(['/pages/worklist-pra-procurement']);
  }
}
