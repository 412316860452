import { BaseEntity } from '../base/base-entity';
import { Industry } from '../bean/industry';
import { VendorHistory } from '../bean/vendor-history';

export class VendorIndustryHistoryEntity extends BaseEntity {
    crudInfo: string;

    vendorHistory: VendorHistory;
    industry: Industry;
    createdDate: Date;
    vendorIndustryId: number;
}
