import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonCompareComponent } from './app-button-compare/app-button-compare.component';
import { AppButtonComponent } from './app-button.component';
@NgModule({
  imports: [VendorModule],
  declarations: [AppButtonComponent, AppButtonCompareComponent],
  entryComponents: [AppButtonComponent, AppButtonCompareComponent],
  exports: [AppButtonComponent, AppButtonCompareComponent]
})
export class AppButtonModule {}
