import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Committee } from '../../bean/committee';
import { AppPopupService } from '../app-popup/app-popup.service';
import { AppCommitteeResponse } from './app-committee-response';
import { AppPopupAddCommitteeComponent } from './app-popup-add-committee.component';
import { AppPopupChooseCommitteeComponent } from './app-popup-choose-committee.component';
import { BinaryTreeModel } from './model/binary-tree-model';

@Component({
  selector: 'app-committee',
  templateUrl: './app-committee.component.html',
  styleUrls: ['./app-committee.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppCommitteeComponent extends BaseComponentComponent {
  // @ContentChild('actionButtons') actionButtonsTemplate: TemplateRef<any>;
  @Input() binaryTreeModel: BinaryTreeModel;
  @Input() header: string;
  @Input() tips: string;
  @Output() onChange: EventEmitter<Committee> = new EventEmitter();
  public appCommitteeResponse: AppCommitteeResponse =
    new AppCommitteeResponse();
  @Input() public committee: Committee;
  @Input() baseModulCode: String;
  constructor(public appPopupService: AppPopupService) {
    super('app-committee');
  }

  onInit(): void {
    // this.setInitializationState();
    this.setRecursiveList();
    this.getDataFromServer();
    this.setStateReady();
  }

  ngOnChanges(): void {
    this.getDataFromServerReload();
  }

  public setRecursiveList(): void {
    this.binaryTreeModel.setRecursiveList();
  }

  public getDataFromServer(): void {
    if (
      this.binaryTreeModel.isWrite ||
      this.binaryTreeModel.isShowChooseButton
    ) {
      this.getDataFromServerReload();
    }
  }

  public getDataFromServerReload(): void {
    var url = '';
    if (this.baseModulCode === null || this.baseModulCode === undefined) {
      url = '/app-committee/get-option-list';
    } else {
      url =
        '/app-committee/get-option-list?baseModulCode=' + this.baseModulCode;
    }
    this.httpClientService
      .get<AppCommitteeResponse>(url)
      .subscribe(response => {
        this.appCommitteeResponse = response;
      });
  }

  public doRemove(item): void {
    this.binaryTreeModel.treeList.forEach((element, index) => {
      if (element.user?.id === item.user?.id) {
        this.binaryTreeModel.treeList.splice(index, 1);
      }
    });
    this.setRecursiveList();
  }

  public doReplace(item): void {
    const committeeParticipantEdit = item;
    const todo = 'replace';
    const treeList = this.binaryTreeModel.treeList;
    const appCommitteeResponse = this.appCommitteeResponse;
    const currentIndex = this.binaryTreeModel.treeList.findIndex(
      x => x.user === item.user
    );
    this.appPopupService
      .open(AppPopupAddCommitteeComponent, {
        committeeParticipantEdit,
        todo,
        appCommitteeResponse,
        treeList
      })
      .subscribe(committeeParticipant => {
        this.binaryTreeModel.treeList.splice(
          currentIndex,
          0,
          committeeParticipant
        );
        this.binaryTreeModel.treeList.splice(currentIndex + 1, 1);
        this.setRecursiveList();
      });
  }

  public doAdd(): void {
    const todo = 'add';
    const appCommitteeResponse = this.appCommitteeResponse;
    const treeList = this.binaryTreeModel.treeList;
    this.appPopupService
      .open(AppPopupAddCommitteeComponent, {
        todo,
        appCommitteeResponse,
        treeList
      })
      .subscribe(committeeParticipantList => {
        if (!this.binaryTreeModel.treeList) {
          this.binaryTreeModel.treeList = [];
        }
        committeeParticipantList.forEach(element => {
          this.binaryTreeModel.treeList.push(element);
        });
        this.setRecursiveList();
      });
  }

  public doChoose(): void {
    const committee = this.committee;
    const appCommitteeResponse = this.appCommitteeResponse;
    this.appPopupService
      .open(AppPopupChooseCommitteeComponent, {
        committee,
        appCommitteeResponse
      })
      .subscribe((committee: Committee) => {
        this.committee = committee;
        this.onChange.emit(committee);

        if (
          this.committee.committeeType.code ===
          this.global.appConstant.pm.COMMITTEE_TYPE_COMMITTEE
        ) {
          this.httpClientService
            .get<AppCommitteeResponse>(
              '/app-committee/get-committee-participant-list/' + committee.id
            )
            .subscribe((response: AppCommitteeResponse) => {
              this.binaryTreeModel.treeList = response.committeeParticipantList;

              this.setRecursiveList();
            });
        } else {
          if (this.binaryTreeModel && this.binaryTreeModel.treeList) {
            this.binaryTreeModel.treeList.splice(
              0,
              this.binaryTreeModel.treeList.length
            );
          }
        }

        this.setRecursiveList();
      });
  }
}
