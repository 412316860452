import { Injectable } from '@angular/core';
import { AppPopupService } from '../app-popup.service';
import { AppPopupUserComponent } from './app-popup-user.component';

@Injectable()
export class AppPopupUserService {
  constructor(public appPopupService: AppPopupService) {}

  public open(
    userIdList?: number[],
    roleCodeExcludeList?: string[],
    roleCodeIncludeList?: string[],
    organizationId?: number,
    hierarchyType?: number
  ) {
    return this.appPopupService.open(AppPopupUserComponent, {
      userIdList,
      roleCodeExcludeList,
      roleCodeIncludeList,
      organizationId,
      hierarchyType
    });
  }
}
