import { File } from '../bean/file';
import { VendorBlacklist } from '../bean/vendor-blacklist';
import { BaseWorkflowObjectEntity } from './../base/base-workflow-object-entity.model';

export class VendorWhitelistEntity extends BaseWorkflowObjectEntity {
    vendorBlacklist: VendorBlacklist;
    file: File;
    startDate: Date;
    note: string;
    reason: string;
}
