<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="scheduler-log.title" [isLoading]="formLoading">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <label class="control-label">{{ 'scheduler-log.date.log-date' |
                  translate }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-left">
                <div class="form-group">
                  <label class="mr-3" for="from">{{ 'scheduler-log.date.from' |
                    translate }}</label>
                  <div class="col-md-12 p-0">
                    <app-date-picker size="XL" formControlName="logDateFrom"
                      maxDate="logDateTo"> </app-date-picker>
                  </div>
                </div>
              </div>
              <div class="col-md-6 text-left">
                <div class="form-group">
                  <label class="mr-3" for="to">{{ 'scheduler-log.date.to' |
                    translate }}</label>
                  <div class="col-md-12 p-0">
                    <app-date-picker size="XL" formControlName="logDateTo"
                      minDate="logDateFrom"> </app-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="button-group button-group-right button-group-icon-text mt-5">
        <app-button mode="BYPASS" (onClick)="doReset()"><em
            class="fas fa-sync-alt"></em>{{ 'app.button.reset' | translate }}
        </app-button>
        <app-button mode="BYPASS" (onClick)="doApply()"><em
            class="fas fa-check"></em>{{ 'app.button.apply' | translate }}
        </app-button>
      </div>
    </app-card>
    <app-card [isLoading]="formLoading" [isSaving]="formSaving">
      <app-table [model]="tableResponse" [isServerSide]="true"
        stringUrl="/scheduler-log/index">
        <ng-template #headerButtons>
          <app-button mode="BYPASS" (onClick)="doExport()"
            [disabled]="isLoading">
            <em class="fas"
              [ngClass]="!isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'"></em>{{
            'app.button.export' | translate }}
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>