import { Injectable } from '@angular/core';
import { AppDynamicComponentService } from '../../services/app-dynamic-component.service';
import { TextUtils } from '../../utils';
import { AppToastComponent } from './app-toast.component';
import { ActiveToast } from './interface/active-toast';
import { ToastOptions } from './interface/toast-options';
@Injectable()
export class ToastService {
  private generatedId: string = TextUtils.generateRandomString();
  constructor(private appDynamicComponentService: AppDynamicComponentService) {}

  public showInfo(msg: string, options: ToastOptions = {}): void {
    options.color = 'INFO';
    options.message = msg;
    options.title = 'app.alert.title.info';
    this.createToast(options);
  }

  public showSuccess(msg: string, options: ToastOptions = {}): void {
    options.color = 'SUCCESS';
    options.message = msg;
    options.title = 'app.alert.title.success';
    this.createToast(options);
  }

  public showWarning(msg: string, options: ToastOptions = {}): void {
    options.color = 'WARNING';
    options.message = msg;
    options.title = 'app.alert.title.warning';
    this.createToast(options);
  }

  public showError(msg: string, options: ToastOptions = {}): void {
    options.color = 'DANGER';
    options.message = msg;
    options.title = options.title || 'app.alert.title.error';
    this.createToast(options);
  }

  public show(options: ToastOptions = {}): void {
    this.createToast(options);
  }

  private createToast(options: ToastOptions): void {
    options.interval = options.interval ? options.interval + 1100 : 4500;
    const promiseToastWrapper: HTMLElement = this.createToastWrapper(options);
    const promiseToast = document.createElement('div');
    promiseToast.classList.add(
      'promise-toast',
      `promise-toast-${options.color.toLowerCase()}`
    );
    if (options.width) {
      promiseToastWrapper.style.width = options.width;
      if (
        options.position === 'BOTTOM-CENTER' ||
        options.position === 'TOP-CENTER'
      ) {
        const leftWidth = +options.width.replace('px', '') / 2 + 'px';
        promiseToastWrapper.style.setProperty(
          'left',
          'calc(50% - ' + leftWidth + ')'
        );
      }
      promiseToast.style.width = '100%';
    }
    const promiseToastId =
      `promise-toast-${options.color.toLowerCase()}` + Date.now();
    promiseToast.id = promiseToastId;
    promiseToastWrapper.appendChild(promiseToast);
    setTimeout(() => {
      promiseToast.classList.add('show');
    }, 50);
    const activeToast: ActiveToast = {
      options,
      close: () => {
        promiseToast.classList.add('hide');
        setTimeout(() => {
          promiseToastWrapper.removeChild(promiseToast);
          this.removeToastWrapper(promiseToastWrapper);
        }, 1050);
      }
    };
    this.appDynamicComponentService.create(AppToastComponent, promiseToast, {
      activeToast
    });
    if (!options.static) {
      setTimeout(() => {
        const isPromiseToast = !!document.getElementById(promiseToastId);
        if (isPromiseToast) {
          promiseToast.classList.add('hide');
          setTimeout(() => {
            promiseToastWrapper.removeChild(promiseToast);
            this.removeToastWrapper(promiseToastWrapper);
          }, 1050);
        }
      }, options.interval);
    }
  }

  private createToastWrapper(options: ToastOptions): any {
    const position: string = (options.position || 'TOP-RIGHT').toLowerCase();
    let toastWrapperElement = document.getElementById(
      this.generatedId + position
    );
    if (!toastWrapperElement) {
      const customClassNameList: Array<string> = options.customClass
        ? options.customClass.split(' ')
        : [];
      toastWrapperElement = document.createElement('div');
      toastWrapperElement.classList.add(
        'promise-toast-wrapper',
        ...position.split('-'),
        ...customClassNameList
      );
      toastWrapperElement.id = this.generatedId + position;
      jQuery(toastWrapperElement).insertBefore('app-root');
    }
    return toastWrapperElement;
  }

  private removeToastWrapper(toastWrapperElement: any): void {
    const isPromiseWrapper = !!document.getElementById(toastWrapperElement.id);
    if (isPromiseWrapper && toastWrapperElement.children.length === 0) {
      jQuery(toastWrapperElement).remove();
    }
  }
}
