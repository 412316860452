<div class="row main-row">
  <div class="col-12">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <app-accordion
        formArrayName="quotationCriteriaList"
        [isLoading]="formLoading"
      >
        <app-accordion-item
          header="{{ quotationCriteria.value.name }}"
          [isExpanded]="true"
          *ngFor="
            let quotationCriteria of formGroup.controls.quotationCriteriaList
              .controls;
            let i = index
          "
          [formGroupName]="i"
        >
          <ng-template #headerLeft>
            <div
              *ngIf="
                submitQuotationResponse.tecQuotationCriteriaList[i]
                  .quotationCriteriaStatus.code === 'WAITING'
              "
            >
              <em class="pir pi-clock" style="color: 'var(--warning)'"></em>
            </div>
            <div
              *ngIf="
                submitQuotationResponse.tecQuotationCriteriaList[i]
                  .quotationCriteriaStatus.code === 'COMPLETED'
              "
            >
              <em
                class="pir pi-check-circle"
                style="color: 'var(--success)'"
              ></em>
            </div>
          </ng-template>
          <ng-container
            *ngIf="
              submitQuotationResponse.tecQuotationCriteriaList[i]
                .procurementCriteriaDetail?.evaluationCriteria?.isUploadDoc
            "
          >
            <div class="form-group row text-sm-left">
              <div class="col-sm-6 text-left">
                <label *ngIf="!quotationCriteria.isView">{{
                  'submit-quotation-vendor.form.uploadDocument' | translate
                }}</label>
                <app-upload-x
                  (onChange)="onChange(i)"
                  formControlName="docFile"
                  [fileUploader]="fileUploader"
                  [isSingle]="true"
                >
                </app-upload-x>
                <app-check-box
                  *ngIf="!quotationCriteria.isView"
                  formControlName="isNote"
                  (onChange)="onChange(i)"
                ></app-check-box>
                <label
                  *ngIf="!quotationCriteria.isView"
                  class="text-primary custom-label optional"
                  >{{ 'submit-quotation-vendor.addNotes' | translate }}</label
                >
                <app-text-field
                  *ngIf="quotationCriteria.value.isNote"
                  size="XL"
                  formControlName="note"
                  (onChange)="onChange(i)"
                >
                </app-text-field>
              </div>
              <div *ngIf="!quotationCriteria.isView" class="col-sm-6 text-left">
                <label>{{
                  'submit-quotation-vendor.form.selectDocument' | translate
                }}</label>
                <app-dropdown-select
                  size="XL"
                  formControlName="document"
                  type="COMBOBOX"
                  [optionList]="documentOptionList"
                  placeholder="submit-quotation-vendor.placeholder.document"
                  (onChange)="onChangeSelectDocument(i)"
                >
                </app-dropdown-select>
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              !submitQuotationResponse.tecQuotationCriteriaList[i]
                .procurementCriteriaDetail?.evaluationCriteria?.isUploadDoc
            "
          >
            {{ quotationCriteria.value.value }}
          </ng-container>
        </app-accordion-item>
      </app-accordion>
    </form>
  </div>
</div>
