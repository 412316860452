import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppWidgetContentSliderModule } from '../app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetContractStatusComponent } from './app-widget-contract-status.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule, AppWidgetContentSliderModule],
  declarations: [AppWidgetContractStatusComponent],
  entryComponents: [AppWidgetContractStatusComponent],
  exports: [AppWidgetContractStatusComponent]
})
export class AppWidgetContractStatusModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-contract-status', AppWidgetContractStatusComponent);
  }
}
