import { Organization } from 'src/app/core/bean/organization';
import { WidgetBudgetIndicatorModel } from './model/widget-budget-indicator.model';

export class WidgetBudgetIndicatorResponse {
  totalBudget: number;
  paidOffBudget: number;
  percentageAbsorption: number;
  periodList: number[];
  organizationList: Organization[] = [];
  widgetBudgetIndicatorModelList: WidgetBudgetIndicatorModel[] = [];
}
