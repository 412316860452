<app-popup header="rfi.addVendor">
  <form [formGroup]="formGroup">
    <app-fieldset legend="Filter">
      <div class="col-sm-12 row main-row-popup-rfi-vendor">
        <div class="col-md-6 col-sm-12">
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'app-popup-vendor-promise.form.vendorType' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                size="XL"
                formControlName="vendorTypeList"
                [optionList]="vendorTypeOptionList"
                (onChange)="doChangeVendorCategoryList($event)"
              ></app-chosen>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'app-popup-vendor-promise.form.vendorCategory' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                size="XL"
                formControlName="vendorCategoryList"
                [optionList]="vendorCategoryOptionList"
              ></app-chosen>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'app-popup-vendor-promise.form.region' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-tree
                size="XL"
                formControlName="regionList"
                stringUrl="/region"
                isLazy="true"
                header="{{
                  'app-popup-vendor-promise.form.region' | translate
                }}"
              >
              </app-text-tree>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'app-popup-vendor-promise.form.segmentation' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-tree
                size="XL"
                formControlName="segmentationList"
                stringUrl="/segmentation"
                [isMultiple]="true"
                isLazy="true"
                header="{{
                  'app-popup-vendor-promise.form.segmentation' | translate
                }}"
              >
              </app-text-tree>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'app-popup-vendor-promise.form.keyword' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-tagging
                size="XL"
                formControlName="keywordList"
                placeholder="rfi.pressEnter"
              >
              </app-text-tagging>
              <!-- <app-chosen size="XL"
               formControlName="keywordList" 
               [optionList]="catalogOptionList">
               </app-chosen> -->
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'app-popup-vendor-promise.form.performance' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-check-box formControlName="ratingList">
                <ng-template #content let-val>
                  <app-rating [value]="val.value" [isViewOnly]="true">
                  </app-rating>
                </ng-template>
              </app-check-box>
            </div>
          </div>
        </div>
      </div>
    </app-fieldset>
    <div class="row justify-content-center mt-5">
      <app-button color="SECONDARY" (onClick)="doReset()" class="mr-1"
        >{{ 'app.button.reset' | translate }}
      </app-button>
      <app-button color="PRIMARY" (onClick)="doApply()">{{
        'app.button.apply' | translate
      }}</app-button>
    </div>
  </form>

  <app-table
    [model]="tableResponse"
    [isServerSide]="false"
  > <!--stringUrl="/request-for-information/popup-vendor"-->
    <ng-template #actionHeader>
      <input
        type="checkbox"
        class="form-check-input"
        (click)="doCheckAll($event)"
        [checked]="dataRecords.length === tableResponse.records.length
        && dataRecords.length > 0"
        aria-label="Check All"
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
      />
    </ng-template>
    <ng-template #actionButtons let-rowData>
      <input
        type="checkbox"
        class="form-check-input"
        (click)="doCheck(rowData)"
        [checked]="dataRecords.indexOf(rowData) !== -1"
        aria-label="Check"
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
      />
    </ng-template>
  </app-table>

  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
        'app.button.close' | translate
      }}</app-button>
      <app-button color="PRIMARY" (onClick)="doSubmit()">{{
        'app.button.save' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
