import { CurrencyPipe } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartTooltipItem } from 'chart.js';
import { ChartModel } from '../../../../../core/components/app-chart/models/chart-model';
import { AppPopupService } from '../../../../../core/components/app-popup/app-popup.service';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetSavingAndSpendingByTopItemModel } from './widget-saving-and-spending-by-top-item-model';
import { WidgetSavingAndSpendingByTopItemPopupComponent } from './widget-saving-and-spending-by-top-item-popup.component';
import { WidgetSavingAndSpendingByTopItemRequest } from './widget-saving-and-spending-by-top-item-request';
import { WidgetSavingAndSpendingByTopItemResponse } from './widget-saving-and-spending-by-top-item-response';

@Component({
  selector: 'dasboard-widget-saving-and-spending-by-top-item',
  templateUrl: './widget-saving-and-spending-by-top-item.component.html',
  styleUrls: ['./widget-saving-and-spending-by-top-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetSavingAndSpendingByTopItemComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-saving-and-spending-by-top-item';
  public moneyFormatTranslateKeyList: Array<string>;
  public widgetSavingAndSpendingByTopItemRequest: WidgetSavingAndSpendingByTopItemRequest =
    new WidgetSavingAndSpendingByTopItemRequest();
  public widgetSavingAndSpendingByTopItemModelList: WidgetSavingAndSpendingByTopItemModel[] =
    [];
  public widgetSavingAndSpendingByTopItemResponse: WidgetSavingAndSpendingByTopItemResponse;
  public totalSaving: number;
  public totalSpending: number;
  public modelDefaultList: any[] = [];
  public chartModel: ChartModel;
  public CURRENCY_DIGITS_INFO: string;
  public sortByFilterLabel: string;

  constructor(
    public translateService: TranslateService,
    public currencyPipe: CurrencyPipe,
    public appPopupService: AppPopupService
  ) {
    super('dashboard-widget-saving-and-spending-by-top-item');
  }

  public onInit(): void {
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
    this.setStateMoneyFormatTranslateKeyList();
    this.setRequest();
    this.setFormGroup();
  }

  private setStateMoneyFormatTranslateKeyList(): void {
    this.moneyFormatTranslateKeyList = [
      this.global.translateService.instant('app.moneyFormat.thousand'),
      this.global.translateService.instant('app.moneyFormat.million'),
      this.global.translateService.instant('app.moneyFormat.billion'),
      this.global.translateService.instant('app.moneyFormat.trillion'),
      this.global.translateService.instant('app.moneyFormat.quadrillion'),
      this.global.translateService.instant('app.moneyFormat.quantillion'),
      this.global.translateService.instant('app.moneyFormat.sextillion')
    ];
  }

  private setRequest(): void {
    const req = new WidgetSavingAndSpendingByTopItemRequest();
    req.year = new Date().getFullYear();
    req.sortBy = 'savingAmount DESC';
    req.maxResult = 5;
    req.ignoreZero = false;
    this.widgetSavingAndSpendingByTopItemRequest = req;
    this.sortByFilterLabel = this.translateService.instant(
      'dashboard-widget-saving-and-spending-by-top-item.label.filter.topSaving'
    );
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<WidgetSavingAndSpendingByTopItemModel[]>(
        '/widget-saving-and-spending-by-top-item/index',
        this.widgetSavingAndSpendingByTopItemRequest
      )
      .subscribe(
        (
          widgetSavingAndSpendingByTopItemResponse: WidgetSavingAndSpendingByTopItemResponse
        ) => {
          if (widgetSavingAndSpendingByTopItemResponse) {
            this.widgetSavingAndSpendingByTopItemResponse = widgetSavingAndSpendingByTopItemResponse;
            if (this.widgetSavingAndSpendingByTopItemResponse.widgetSavingAndSpendingByTopItemModelList) {
              if (this.widgetSavingAndSpendingByTopItemRequest.ignoreZero) {
                this.widgetSavingAndSpendingByTopItemModelList =
                  this.widgetSavingAndSpendingByTopItemModelList.filter(
                    model => model.spendingAmount
                  );
              } else {
                this.widgetSavingAndSpendingByTopItemModelList =
                  this.widgetSavingAndSpendingByTopItemResponse.widgetSavingAndSpendingByTopItemModelList;
              }

              this.setChartModel();
            }
          }
          this.setStateReady();
        }
      );
  }

  public setDataGroup(): void {
    this.totalSaving = 0;
    this.totalSpending = 0;
    this.widgetSavingAndSpendingByTopItemModelList.forEach(
      widgetSavingAndSpendingByTopItemModel => {
        this.totalSpending += widgetSavingAndSpendingByTopItemModel.spendingAmount;
        this.totalSaving += widgetSavingAndSpendingByTopItemModel.savingAmount;
      }
    );

    this.modelDefaultList = [
      {
        code: this.translateService.instant(
          this.moduleCode + '.label.spending'
        ),
        total: this.global.converterService.convertToShortMoney(
          this.totalSpending || 0,
          this.moneyFormatTranslateKeyList
        ) as string,
        color: '#14B1AB'
      },
      {
        code: this.translateService.instant(this.moduleCode + '.label.saving'),
        total: this.global.converterService.convertToShortMoney(
          this.totalSaving || 0,
          this.moneyFormatTranslateKeyList
        ) as string,
        color: '#0772B6'
      }
    ];
  }

  public setChartModel(): void {
    this.setDataGroup();
    let labels = [];
    const dataSetLabels = [];
    const dataSets = [];

    if (labels.length === 0) {
      labels = [...this.widgetSavingAndSpendingByTopItemModelList].map(data =>
        data.itemName.length > 15
          ? data.itemName.slice(0, 15) + '...'
          : data.itemName
      );
    }

    dataSets.push({
      data: [...this.widgetSavingAndSpendingByTopItemModelList].map(
        data => data.spendingAmount
      )
    });
    dataSets.push({
      data: [...this.widgetSavingAndSpendingByTopItemModelList].map(
        data => (data.savingAmount)
      )
    });
    dataSetLabels.push(
      this.translateService.instant(this.moduleCode + '.label.spending')
    );

    dataSetLabels.push(
      this.translateService.instant(this.moduleCode + '.label.saving')
    );

    const CURRENCY_PRECISSION_SCALE =
      this.global.appConstant.core.CURRENCY_PRECISSION_SCALE;
    const CURRENCY_DIGITS_INFO = `0.${CURRENCY_PRECISSION_SCALE}-${CURRENCY_PRECISSION_SCALE}`;
    this.chartModel = new ChartModel('bar', labels, dataSets, dataSetLabels, {
      legend: {
        display: false
      },
      defaultColor: this.modelDefaultList.map(model => model.color),
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              stepSize: 500000000,
              callback: (value): string => {
                return this.global.converterService.convertToShortMoneyChart(
                  value
                ) as string;
              }
            }
          }
        ],
        xAxes: [
          {
            gridLines: {
              display: false
            }
          }
        ]
      },
      tooltips: {
        mode: 'nearest',
        xPadding: 20,
        yPadding: 20,
        titleFontStyle: 'font-weight: normal; padding: 20px',
        bodyFontStyle: 'font-weight: bold',
        footerFontStyle: 'font-weight: normal',
        callbacks: {
          title: (item: ChartTooltipItem[]): string => {
            return this.widgetSavingAndSpendingByTopItemModelList[item[0].index]
              .itemName;
          },
          label: () => null,
          footer: (item: Chart.ChartTooltipItem[]): string => {
            return item[0].datasetIndex === 1
              ? this.currencyPipe.transform(
                (this.widgetSavingAndSpendingByTopItemModelList[
                  item[0].index
                ].procurementAmount ?
                  ((this.widgetSavingAndSpendingByTopItemModelList[
                    item[0].index
                  ].savingAmount) / (this.widgetSavingAndSpendingByTopItemModelList[
                    item[0].index
                  ].procurementAmount)) * 100 : 0
                ),
                '',
                '',
                this.CURRENCY_DIGITS_INFO
              ) +
              '% ' +
              this.translateService.instant(
                'dashboard-widget-saving-and-spending-by-top-item.tooltip.percentageAYear'
              )
              : 'Total ' +
              (+Math.abs(
                this.widgetSavingAndSpendingByTopItemModelList[
                  item[0].index
                ].totalItem
              )).toFixed(0) +
              ' ' +
              this.translateService.instant(
                'dashboard-widget-saving-and-spending-by-top-item.tooltip.item'
              );
          },
          afterBody: (item: Chart.ChartTooltipItem[]): string => {
            const data =
              this.widgetSavingAndSpendingByTopItemModelList[item[0].index];
            const body =
              item[0].datasetIndex === 0
                ? this.translateService.instant(
                  this.moduleCode + '.tooltip.spending'
                )
                : this.translateService.instant(
                  this.moduleCode + '.tooltip.saving'
                );
            return `${body +
              ' ' +
              this.currencyPipe.transform(
                item[0].datasetIndex === 0
                  ? data.spendingAmount
                  : data.savingAmount,
                '',
                '',
                CURRENCY_DIGITS_INFO
              )
              + ' ' + (this.widgetSavingAndSpendingByTopItemResponse.currencyCode || 'IDR')}`;
          }
        }
      }
    });
  }

  public doChangeFilter(): void {
    this.appPopupService
      .open(
        WidgetSavingAndSpendingByTopItemPopupComponent,
        {
          request: this.widgetSavingAndSpendingByTopItemRequest
        },
        {
          size: 'SM'
        }
      )
      .subscribe((request: WidgetSavingAndSpendingByTopItemRequest) => {
        if (request) {
          this.widgetSavingAndSpendingByTopItemRequest = request;
          this.setStateLoading();
          this.setFormGroup();
          if (request.sortBy === 'savingAmount DESC') {
            this.sortByFilterLabel = this.translateService.instant(
              'dashboard-widget-saving-and-spending-by-top-item.label.filter.topSaving'
            );
          } else if (request.sortBy === 'savingAmount ASC') {
            this.sortByFilterLabel = this.translateService.instant(
              'dashboard-widget-saving-and-spending-by-top-item.label.filter.lowestSaving'
            );
          } else if (request.sortBy === 'spendingAmount DESC') {
            this.sortByFilterLabel = this.translateService.instant(
              'dashboard-widget-saving-and-spending-by-top-item.label.filter.topSpending'
            );
          } else {
            this.sortByFilterLabel = this.translateService.instant(
              'dashboard-widget-saving-and-spending-by-top-item.label.filter.lowestSpending'
            );
          }
        }
      });
  }
}
