import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorAttribute } from '../../core/bean/vendor-attribute';
import { VendorAttributeGroup } from '../../core/bean/vendor-attribute-group';
import { VendorType } from '../../core/bean/vendor-type';
import { AppPopupAttributeService } from '../../core/components/app-popup/app-popup-attribute/app-popup-attribute.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { VendorAttributeRequest } from './vendor-attribute-request';
import { VendorAttributeResponse } from './vendor-attribute-response';

@Component({
  templateUrl: './vendor-attribute-edit.component.html',
  styleUrls: ['./vendor-attribute-edit.component.scss']
})
export class VendorAttributeEditComponent extends BaseModuleComponent {
  public vendorAttributeResponse: VendorAttributeResponse;
  public vendorAttributeRequest: VendorAttributeRequest =
    new VendorAttributeRequest();
  public dataTransfer: VendorAttributeResponse = new VendorAttributeResponse();
  public attributeIdList: number[] = [];
  public vendorAttribute: VendorAttribute = new VendorAttribute();
  public vendorAttributeGroup: VendorAttributeGroup =
    new VendorAttributeGroup();
  public dataUpdate1: any = { generated: null, additionalInformation: null };
  public vendorType: VendorType;
  public dataReady = false;

  public readonly DELETE = 0;
  public readonly UPDATE = 1;
  public readonly INSERT = 2;

  public readonly urlEditVendorAttribute = '/vendor-attribute/edit';
  public readonly urlUpdateVendorAttribute = '/vendor-attribute/update';
  public readonly urlNavipageToVendorAttribute = '/pages/vendor-attribute';
  public tableResponse: TableResponseModel<VendorAttribute>;

  constructor(
    translate: TranslateService,
    public appPopupAttributeService: AppPopupAttributeService
  ) {
    super('vendor-attribute', translate);
  }

  onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorType = this.global.routerParams.get('vendorType');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      generated: this.formBuilder.array([]),
      additionalInformation: this.formBuilder.array([])
    });
  }

  public setFormGroup(): void {
    this.getVendorAttributeListByVendorTypeFromServer(
      this.urlEditVendorAttribute,
      'edit',
      this.vendorType.id
    ).subscribe(vendorAttributeResponse => {
      this.vendorAttributeResponse = vendorAttributeResponse;
      const formArray: FormArray = this.formGroupService.builder(
        this.vendorAttributeResponse.vendorTabListByVendorType
      ) as FormArray;
      (this.formGroup.get('generated') as FormArray).controls =
        formArray.controls;
      this.setAdditionalInformation(
        this.vendorAttributeResponse.vendorAttributeListByVendorGroup
      );
      this.dataReady = true;
      this.setStateReady();
    });
  }

  public setAdditionalInformation(itemList: VendorAttribute[]): void {
    itemList.forEach((item: VendorAttribute) => {
      this.attributeIdList.push(item.attribute.id);
      this.additionalInformation.push(this.formGroupService.builder(item));
    });
  }

  public get additionalInformation(): FormArray {
    return this.formGroup.get('additionalInformation') as FormArray;
  }

  public doDelete(additionalInformation: FormArray, index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (
            additionalInformation.controls[index].value.crudOperation ===
            this.INSERT
          ) {
            additionalInformation.removeAt(index);
            this.attributeIdList.splice(index, 1);
          } else {
            additionalInformation.controls[index].patchValue({
              crudOperation: this.DELETE
            });
            this.attributeIdList.splice(index, 1);
          }
        }
      });
  }

  public changeIsMandatory(index: number): void {
    if (
      this.additionalInformation.controls[index].value.crudOperation !==
      this.INSERT
    ) {
      this.additionalInformation.controls[index].patchValue({
        crudOperation: this.UPDATE
      });
    }
  }

  public changeIsApproval(index: number): void {
    if (
      this.additionalInformation.controls[index].value.crudOperation !==
      this.INSERT
    ) {
      this.additionalInformation.controls[index].patchValue({
        crudOperation: this.UPDATE
      });
    }
  }

  public changeIsCertificate(index: number): void {
    if (
      this.additionalInformation.controls[index].value.crudOperation !==
      this.INSERT
    ) {
      this.additionalInformation.controls[index].patchValue({
        crudOperation: this.UPDATE
      });
    }
  }

  public doCancel(): void {
    this.router.navigate([this.urlNavipageToVendorAttribute]);
  }

  public doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.updateVendorAttribute();
          }
        });
    }
  }

  public updateVendorAttribute(): void {
    this.setStateProcessing();
    this.dataUpdate1 = this.global.copyFormAttributeToModel(
      this.dataUpdate1,
      this.formGroup
    );

    // this.vendorAttributeRequest.vendorAttributeGroupId = this.vendorAttributeResponse.vendorAttributeGroupId;
    this.vendorAttributeRequest.vendorAttributeListByVendorGroup =
      this.dataUpdate1.additionalInformation;
    this.vendorAttributeRequest.vendorTabListByVendorType =
      this.dataUpdate1.generated;
    this.vendorAttributeRequest.vendorType = this.vendorType;
    this.saveVendorAttributeResponseToServer(
      this.urlUpdateVendorAttribute,
      this.vendorAttributeRequest
    ).subscribe((response: Response<VendorAttributeRequest>) => {
      if (response.status === ResponseStatusModel.OK) {
        this.global.alertService.showSuccessSavingOnNextRoute();
        this.router.navigate([this.urlNavipageToVendorAttribute]);
      } else {
        this.global.alertService.showError(response.statusText);
        this.setStateReady();
      }
    });
  }

  public getVendorAttributeListByVendorTypeFromServer(
    urlVendorAttributeListByVendorType: string,
    todo: string,
    vendorTypeId: number
  ): Observable<VendorAttributeResponse> {
    return this.httpClientService.post<VendorAttributeResponse>(
      urlVendorAttributeListByVendorType,
      new RouteRequestModel(todo, vendorTypeId)
    );
  }

  public saveVendorAttributeResponseToServer(
    urlUpdateVendorAttribute: string,
    vendorAttributeRequest: VendorAttributeRequest
  ): Observable<Response<VendorAttributeRequest>> {
    return this.httpClientService.post<Response<VendorAttributeRequest>>(
      urlUpdateVendorAttribute,
      vendorAttributeRequest
    );
  }

  public doChoose(): void {
    // const generated = this.formGroup.value.generated;
    this.appPopupAttributeService.open(this.attributeIdList).subscribe(attr => {
      this.attributeIdList.push(attr[0].id);
      this.vendorAttribute.id = null;
      this.vendorAttribute.fieldName = attr[0].description;
      this.vendorAttribute.isApproval = false;
      this.vendorAttribute.isMandatory = false;
      this.vendorAttribute.isCertificate = false;
      this.vendorAttribute.crudOperation = this.INSERT;
      this.vendorAttribute.attribute = attr[0];
      this.additionalInformation.push(
        this.formGroupService.builder(this.vendorAttribute)
      );
    });
  }

  public getFormArray(
    formGroup: FormGroup,
    formControlName: string
  ): FormArray {
    return formGroup.get(formControlName) as FormArray;
  }
}
