import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppContractNegotiationViewModule } from 'src/app/core/components/app-contract-negotiation-view/app-contract-negotiation-view.module';
import { AppApprovalPrcsXModule } from '../../core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppContractRequestInfoModule } from '../../core/components/app-contract-request-info/app-contract-request-info.module';
import { AppVendorInformationModule } from '../../core/components/app-vendor-information/app-vendor-information.module';
import { AppWorkDefinitionModule } from '../../core/components/app-work-definition/app-work-definition.module';
import { AppWorkflowStepInfoModule } from '../../core/components/app-workflow-step-info/app-workflow-step-info.module';
import { AppUploadXModule } from '../../core/components/upload/components/app-upload-x/app-upload-x.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ContractNegotiationEditAddComponent } from './contract-negotiation-edit-add.component';
import { ContractNegotiationPopupContractReviewerComponent } from './contract-negotiation-popup-contract-reviewer.component';
import { ContractNegotiationComponent } from './contract-negotiation.component';

export const routes = [
  {
    path: '',
    component: ContractNegotiationComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'edit',
    component: ContractNegotiationEditAddComponent,
    data: { breadcrumb: 'contract-negotiation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: ContractNegotiationEditAddComponent,
    data: { breadcrumb: 'contract-negotiation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: ContractNegotiationEditAddComponent,
    data: { breadcrumb: 'contract-negotiation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppContractNegotiationViewModule,
    AppWorkflowStepInfoModule,
    AppApprovalPrcsXModule,
    AppUploadXModule,
    AppContractRequestInfoModule,
    AppVendorInformationModule,
    AppWorkDefinitionModule
  ],
  declarations: [
    ContractNegotiationComponent,
    ContractNegotiationEditAddComponent,
    ContractNegotiationPopupContractReviewerComponent
  ],
  entryComponents: [ContractNegotiationPopupContractReviewerComponent],
  exports: [ContractNegotiationPopupContractReviewerComponent]
})
export class ContractNegotiationModule {}
