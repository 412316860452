import { CurrencyPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { ItemType } from 'src/app/core/bean/item-type';
import { RfqVendor } from 'src/app/core/bean/rfq-vendor';
import { RfqVendorDoc } from 'src/app/core/bean/rfq-vendor-doc';
import { RfqVendorItem } from 'src/app/core/bean/rfq-vendor-item';
import { RfqVendorScoring } from 'src/app/core/bean/rfq-vendor-scoring';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { ConverterService } from 'src/app/core/services/converter.service';
import { Validators } from 'src/app/core/validators';
import { RfpVendorDoc } from '../../core/bean/rfp-vendor-doc';
import { RfqPopupVendorViewScoreResponse } from './response/rfq-popup-vendor-view-score.reponse';

@Component({
  templateUrl: './rfq-popup-vendor-view-score.component.html'
})
export class RfqPopupVendorViewScoreComponent extends BaseComponentComponent {
  @Input() rfqVendor: RfqVendor;
  @Input() rfpId: number;
  @Input() minAmount: number;
  @Output() onChange: EventEmitter<RfqVendor> = new EventEmitter();
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  @ViewChild('selectorProposalCriteria')
  tableProposalCriteria: AppTableXComponent;
  @ViewChild('selectorVendor')
  tableVendor: AppTableXComponent;
  @ViewChild('selectorItem')
  tableItem: AppTableXComponent;

  public tableResponseProposalCriteria: TableResponseModel<any>;
  public tableResponseProposalCriteriaEdit: TableResponseModel<any>;
  public tableResponseProposalCriteriaView: TableResponseModel<any>;
  public tableResponseRfqVendorItem: TableResponseModel<RfqVendorItem>;

  public rfqPopupVendorViewScoreResponse: RfqPopupVendorViewScoreResponse;
  public totalProposalCriteria: number;
  public CURRENCY_DIGITS_INFO: string;
  public proposalPoint: number;
  public pricePoint: number;
  public totalPoint: number;
  public todo: string;

  public fileUploader: FileUploader = new FileUploader(
    '/rfq/',
    'rfq.popup.form.file',
    this.global.appConstant.fileType.DOC_RFQ_RESPONSE
  );
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    false
  );

  constructor(
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public currency: CurrencyPipe,
    public converterService: ConverterService
  ) {
    super('rfq');
  }

  onInit(): void {
    const rfqStatusCodeList = [
      this.global.appConstant.pm.RFQ_STATUS_WAITING_APPROVAL,
      this.global.appConstant.pm.RFQ_STATUS_DONE,
      this.global.appConstant.pm.RFQ_STATUS_CANCELED,
      this.global.appConstant.pm.RFQ_STATUS_COMPLETED,
      this.global.appConstant.pm.RFQ_STATUS_WAITING_RESPONSE
    ];
    if (
      this.rfqVendor.rfpVendor ||
      !this.rfpId ||
      rfqStatusCodeList.includes(this.rfqVendor.rfq?.rfqStatus?.code)
    ) {
      this.todo = 'view';
    } else if (
      this.rfqVendor.rfqVendorScoringList &&
      this.rfqVendor.rfqVendorScoringList.length > 0
    ) {
      this.todo = 'edit';
    } else {
      this.todo = 'add';
    }
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
    this.buildTableResponse();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      file: [null],
      itemType: [null],
      amount: [null],
      goodsAmount: [null],
      serviceAmount: [null]
    });
  }

  public setFormGroup(): void {
    const url = this.rfpId
      ? '/rfq/get-data-popup?rfqVendorId=' +
      this.rfqVendor.id +
      '&rfpId=' +
      this.rfpId
      : '/rfq/get-data-popup?rfqVendorId=' + this.rfqVendor.id;
    this.httpClientService
      .get(url)
      .subscribe(
        (rfqPopupVendorViewScoreResponse: RfqPopupVendorViewScoreResponse) => {
          this.rfqPopupVendorViewScoreResponse =
            rfqPopupVendorViewScoreResponse;
          const fileList = (
            rfqPopupVendorViewScoreResponse.rfqVendorDocList as Array<RfqVendorDoc>
          ).map(vendorDoc => vendorDoc.file);
          if (
            rfqPopupVendorViewScoreResponse.rfpVendorDocList &&
            rfqPopupVendorViewScoreResponse.rfpVendorDocList.length > 0
          ) {
            fileList.push(
              ...(
                rfqPopupVendorViewScoreResponse.rfpVendorDocList as Array<RfpVendorDoc>
              ).map(rfpVendorDoc => rfpVendorDoc.file)
            );
          }

          this.fileUploader.setFileList(fileList);
          this.formGroup.patchValue({
            file: this.fileUploader.fileObjectList
          });
          this.formGroup.get('file').setIsView(true);
          this.tableResponseProposalCriteria.setRecordList(
            rfqPopupVendorViewScoreResponse.rfpProposalCriteriaList
          );
          this.tableResponseProposalCriteriaEdit.setRecordList(
            this.rfqVendor.rfqVendorScoringList
          );
          this.tableResponseProposalCriteriaView.setRecordList(
            this.rfqVendor.rfqVendorScoringList
          );
          this.tableResponseRfqVendorItem.setRecordList(
            rfqPopupVendorViewScoreResponse.rfqVendorItemList
          );
          this.itemTypeOptionList.setRequestValues(
            rfqPopupVendorViewScoreResponse.itemTypeList
          );
          this.doSetAmount();
          if (this.todo !== 'add' && this.rfpId) {
            this.calculationPoint();
          }
          this.setStateReady();
        }
      );
  }

  public buildTableResponse(): void {
    this.tableResponseProposalCriteria = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'proposalCriteria',
          header: 'table.column.proposalCriteria',
          plugins: {
            name: 'custom-plugin',
            before: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.value && tablePluginData.value.translationKey) {
                return this.global.translateService.instant((tablePluginData.value.translationKey.module.code.toLowerCase() + '.' + tablePluginData.value.translationKey.key))
              }
              return tablePluginData.value.code;
            }
          }
        },
        {
          field: 'weightPercentage',
          header: 'table.column.weight',
          className: 'text-right',
          plugins: { name: 'text-field', type: 'decimal', isView: true }
        },
        {
          field: 'score',
          header: 'table.column.score',
          className: 'text-right',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            validators: Validators.max(100),
            onChange: (record: any): void => {
              this.log.debug(record);
              this.onChangeScore();
            }
          }
        },
        {
          field: 'weightScore',
          header: 'table.column.weightScore',
          className: 'text-right',
          plugins: { name: 'text-field', type: 'decimal', isView: true }
        }
      ]
    );

    this.tableResponseProposalCriteriaEdit = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'rfpProposalCriteria.proposalCriteria',
          header: 'table.column.proposalCriteria',
          plugins: {
            name: 'custom-plugin',
            before: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.value && tablePluginData.value.translationKey) {
                return this.global.translateService.instant((tablePluginData.value.translationKey.module.code.toLowerCase() + '.' + tablePluginData.value.translationKey.key))
              }
              return tablePluginData.value.code;
            }
          }
        },
        {
          field: 'rfpProposalCriteria.weightPercentage',
          header: 'table.column.weight',
          className: 'text-right',
          plugins: { name: 'text-field', type: 'decimal', isView: true }
        },
        {
          field: 'score',
          header: 'table.column.score',
          className: 'text-right',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            onChange: (record: any): void => {
              this.log.debug(record);
              this.onChangeScore();
            },
            validators: Validators.max(100)
          }
        },
        {
          field: 'weightScore',
          header: 'table.column.weightScore',
          className: 'text-right',
          plugins: { name: 'text-field', type: 'decimal', isView: true }
        }
      ]
    );

    this.tableResponseProposalCriteriaView = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'rfpProposalCriteria.proposalCriteria',
          header: 'table.column.proposalCriteria',
          plugins: {
            name: 'custom-plugin',
            before: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.value && tablePluginData.value.translationKey) {
                return this.global.translateService.instant((tablePluginData.value.translationKey.module.code.toLowerCase() + '.' + tablePluginData.value.translationKey.key))
              }
              return tablePluginData.value.code;
            }
          }
        },
        {
          field: 'rfpProposalCriteria.weightPercentage',
          header: 'table.column.weight',
          className: 'text-right'
        },
        {
          field: 'score',
          header: 'table.column.score',
          className: 'text-right'
        },
        {
          field: 'weightScore',
          header: 'table.column.weightScore',
          className: 'text-right'
        }
      ]
    );

    this.tableResponseRfqVendorItem = new TableResponseModel(this.moduleCode, [
      {
        field: 'rfqItem.prItem.prItemImageList',
        header: 'table.column.thumbnail',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePluginData: TablePluginData): File => {
              if (tablePluginData.value && tablePluginData.value.length > 0) {
                return tablePluginData.value[0].file;
              }
              return null;
            }
          },
          {
            name: 'img',
            classNameMap: {
              MATERIAL: 'pvr pv-box',
              JASA: 'pvr pv-tools'
            },
            fieldClassName: 'prItem.item.itemType.code'
          }
        ],
        isSortable: false,
        isSearchTable: false
      },
      {
        field: 'rfqItem.prItem.pr.code',
        header: 'table.column.prNumber',
        plugins: { name: 'hyperlink', onClick: this.doViewItem.bind(this) }
      },
      {
        field: 'rfqItem.prItem.item.name',
        header: 'table.column.itemName'
      },

      {
        field: 'rfqItem.prItem.item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'rfqItem.prItem.item.itemType.code'
        }
      },
      {
        field: 'rfqItem.prItem.quantity',
        header: 'table.column.quantity',
        className: 'text-right',
        plugins: {
          name: 'custom-plugin',
          before: (data: TablePluginData): string => {
            return this.currency.transform(
              +data.value,
              '',
              '',
              this.CURRENCY_DIGITS_INFO
            );
          }
        }
      },
      {
        field: 'bidPrice',
        header: 'table.column.bidPrice',
        className: 'text-right',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      },
      {
        field: 'totalBidPrice',
        header: 'table.column.totalBidPrice',
        className: 'text-right',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      }
    ]);
  }

  public onChangeItem(): void {
    const itemType = this.formGroup.value.itemType;
    if (itemType) {
      const rfqVendorItemList = [];
      this.rfqPopupVendorViewScoreResponse.rfqVendorItemList.forEach(
        (rfqVendorItem: RfqVendorItem) => {
          if (
            rfqVendorItem.rfqItem.prItem &&
            rfqVendorItem.rfqItem.prItem.item.itemType.id === itemType.id
          ) {
            rfqVendorItemList.push(rfqVendorItem);
          }
        }
      );
      this.tableResponseRfqVendorItem.setRecordList(rfqVendorItemList);
    } else {
      this.tableResponseRfqVendorItem.setRecordList(
        this.rfqPopupVendorViewScoreResponse.rfqVendorItemList
      );
    }
    this.tableResponseRfqVendorItem.reload();
    this.doSetAmount();
  }

  public onChangeScore(): void {
    if (this.todo === 'add') {
      const proposalCriteria =
        this.tableResponseProposalCriteria.currentRowChange.row.formGroup.value;

      const currentRowChange =
        this.tableResponseProposalCriteria.getRecordList()[
        this.tableResponseProposalCriteria.currentRowChange.row.position - 1
        ];
      currentRowChange.score = proposalCriteria.score;
      currentRowChange.weightScore =
        proposalCriteria.weightPercentage * proposalCriteria.score * 0.01;

      this.tableResponseProposalCriteria.getRecordList()[
        this.tableResponseProposalCriteria.currentRowChange.row.position - 1
      ] = currentRowChange;
      this.tableResponseProposalCriteria.reload();
    } else {
      const proposalCriteria =
        this.tableResponseProposalCriteriaEdit.currentRowChange.row.formGroup
          .value;
      const currentRowChange =
        this.tableResponseProposalCriteriaEdit.getRecordList()[
        this.tableResponseProposalCriteriaEdit.currentRowChange.row.position -
        1
        ];
      currentRowChange.score = proposalCriteria.score;
      currentRowChange.weightScore =
        proposalCriteria.rfpProposalCriteriaWeightPercentage *
        proposalCriteria.score *
        0.01;

      this.tableResponseProposalCriteriaEdit.getRecordList()[
        this.tableResponseProposalCriteriaEdit.currentRowChange.row.position - 1
      ] = currentRowChange;
      this.tableResponseProposalCriteriaEdit.reload();
    }
    this.calculationPoint();
  }

  public calculationPoint(): void {
    this.totalProposalCriteria = 0;
    if (this.todo === 'add') {
      this.tableResponseProposalCriteria
        .getRecordList()
        .forEach(proposalCriteria => {
          if (proposalCriteria.weightScore) {
            this.totalProposalCriteria += proposalCriteria.weightScore;
          }
        });
    } else if (this.todo === 'edit') {
      this.tableResponseProposalCriteriaEdit
        .getRecordList()
        .forEach(proposalCriteria => {
          if (proposalCriteria.weightScore) {
            this.totalProposalCriteria += proposalCriteria.weightScore;
          }
        });
    } else {
      this.totalProposalCriteria = this.rfqVendor.totalWeightScore;
    }

    this.proposalPoint =
      this.todo !== 'view'
        ? this.totalProposalCriteria *
        this.rfqPopupVendorViewScoreResponse.rfp.proposalPercentage *
        0.01
        : this.rfqVendor.totalProposalScore;
    this.pricePoint =
      this.todo !== 'view'
        ? (this.minAmount / this.rfqVendor.amount) *
        100 *
        this.rfqPopupVendorViewScoreResponse.rfp.pricePercentage *
        0.01
        : this.rfqVendor.totalPriceScore;
    this.pricePoint = +this.converterService.convertDecimal(this.pricePoint);
    this.totalPoint =
      this.todo !== 'view'
        ? this.proposalPoint + this.pricePoint
        : this.rfqVendor.totalScore;
  }

  public setDecimal(field: number): string {
    return this.currency.transform(field, '', '', this.CURRENCY_DIGITS_INFO);
  }

  public doSetAmount(): void {
    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;
    this.tableResponseRfqVendorItem
      .getRecordList()
      .forEach((rfqVendorItem: RfqVendorItem) => {
        if (
          rfqVendorItem.rfqItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
        ) {
          goodsAmountTotal = +goodsAmountTotal + +rfqVendorItem.totalBidPrice;
        }
        if (
          rfqVendorItem.rfqItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
        ) {
          serviceAmountTotal =
            +serviceAmountTotal + +rfqVendorItem.totalBidPrice;
        }
      });
    this.formGroup.patchValue({
      goodsAmount: goodsAmountTotal,
      serviceAmount: serviceAmountTotal,
      amount: +goodsAmountTotal + +serviceAmountTotal
    });
  }

  public doViewItem(rfqVendorItem: RfqVendorItem): void {
    this.appPopupPrItemInfoService.open(rfqVendorItem.rfqItem.prItem.id);
  }

  public doSave(): void {
    this.rfqVendor.amount = this.formGroup.value.amount;
    this.rfqVendor.goodsAmount = this.formGroup.value.goodsAmount;
    this.rfqVendor.serviceAmount = this.formGroup.value.serviceAmount;
    this.rfqVendor.totalScore = this.totalPoint;
    this.rfqVendor.totalPriceScore = this.pricePoint;
    this.rfqVendor.totalProposalScore = this.proposalPoint;
    this.rfqVendor.totalWeightScore = this.totalProposalCriteria;

    const rfqVendorScoringList: RfqVendorScoring[] = [];
    if (this.todo === 'add') {
      this.tableResponseProposalCriteria
        .getRecordList()
        .forEach((proposalCriteria, index) => {
          const rfqVendorScoring: RfqVendorScoring = new RfqVendorScoring();
          rfqVendorScoring.score = +proposalCriteria.score;
          rfqVendorScoring.weightScore = proposalCriteria.weightScore;
          rfqVendorScoring.rfpProposalCriteria =
            this.tableResponseProposalCriteria.getRecordList()[index];
          rfqVendorScoringList.push(rfqVendorScoring);
        });
    } else if (this.todo === 'edit') {
      this.tableResponseProposalCriteriaEdit
        .getRecordList()
        .forEach((proposalCriteria, index) => {
          const rfqVendorScoring: RfqVendorScoring = new RfqVendorScoring();
          rfqVendorScoring.score = +proposalCriteria.score;
          rfqVendorScoring.weightScore = proposalCriteria.weightScore;
          rfqVendorScoring.rfpProposalCriteria =
            this.tableResponseProposalCriteria.getRecordList()[index];
          rfqVendorScoring.id =
            this.tableResponseProposalCriteriaEdit.getRecordList()[index].id;
          rfqVendorScoringList.push(rfqVendorScoring);
        });
    }
    this.rfqVendor.rfqVendorScoringList = rfqVendorScoringList;

    this.onChange.emit(this.rfqVendor);
  }
}
