import { Payment } from 'src/app/core/bean/payment';
import { PaymentBillingDoc } from 'src/app/core/bean/payment-billing-doc';
import { PaymentHistory } from 'src/app/core/bean/payment-history';
import { PaymentInvoice } from 'src/app/core/bean/payment-invoice';
import { ProcurementSowPaymentTermBilling } from 'src/app/core/bean/procurement-sow-payment-billing';
import { FileObject } from 'src/app/core/components/upload';
import { SowPaymentTermBilling } from 'src/app/core/bean/sow-payment-term-billing';
import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { PaymentBillingView } from 'src/app/core/view/entity/bean/payment-billing-view';
import { StageOfWork } from 'src/app/core/bean/stage-of-work';

export class VendorPaymentRequestEditAddResponse {
    payment: Payment;
    paymentHistory: PaymentHistory;
    paymentInvoice: PaymentInvoice;
    paymentBilling: PaymentBilling;
    paymentBillingViewBean: PaymentBillingView;
    paymentBillingDocumentList: PaymentBillingDoc[];
    procurementSowPaymentTermBillingList: ProcurementSowPaymentTermBilling[];
    fileObjectList: FileObject[];
    sowPaymentTermBillingList: SowPaymentTermBilling[];
    paymentBillingDocList: PaymentBillingDoc[];
    remainingPayment: number;
    isUserApproval: boolean;
    stageOfWork: StageOfWork;
    isDeliverableItem: boolean;
}
