<div class="row main-row">
  <div class="col-12">
    <form
      class="form-horizontal"
      [formGroup]="imageTab.formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <div class="form-group row text-sm-right">
        <ng-container *ngIf="!isTodoView">
          <label class="col-sm-3 control-label">{{
            'manage-catalog.form.uploadImages' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-upload-images
              formControlName="file"
              [fileUploader]="fileUploader"
            ></app-upload-images>
          </div>
        </ng-container>
        <ng-container *ngIf="isTodoView">
          <div
            class="table-responsive custom-table float-none"
            [ngStyle]="{ margin: '0 auto' }"
          >
            <table
              class="table table-striped box-shadow"
              aria-describedby="Table"
            >
              <thead>
                <tr class="text-center">
                  <th scope="col"
                    >{{ 'manage-catalog.table.file' | translate }}
                  </th>
                  <th scope="col"
                    >{{ 'manage-catalog.table.name' | translate }}
                  </th>
                  <th scope="col"
                    >{{ 'manage-catalog.table.size' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let fileObject of manageCatalogDTO?.fileUploadImage;
                    let i = index
                  "
                  class="text-center"
                >
                  <td [ngStyle]="{ 'max-width': '200px' }">
                    <img
                      [ngStyle]="{ 'max-width': '200px' }"
                      [attr.src]="
                        global.config.BACKEND_ADDRESS +
                          '/manage-catalog/thumbnail/view/' +
                          fileObject.file.uploadedFileName +
                          '/' +
                          global.appConstant.fileType.IMG_ITEM_CATALOG
                          | resolveImgSrc
                          | async
                      "
                      [attr.alt]="fileObject.file.fileName"
                      width="auto"
                      height="auto"
                    />
                  </td>
                  <td [ngStyle]="{ 'white-space': 'normal' }"
                    ><a
                      href="#"
                      (click)="
                        doDownload(
                          $event,
                          '/manage-catalog/file/view/' +
                            fileObject.file.uploadedFileName +
                            '/' +
                            global.appConstant.fileType.IMG_ITEM_CATALOG,
                          fileObject.file.fileName
                        )
                      "
                    >
                      {{ fileObject.file.fileName || '-' }}</a
                    >
                  </td>
                  <td
                    >{{
                      fileObject.file.size / 1000000 || '-' | number: '1.2-2'
                    }}
                    MB</td
                  >
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
</div>
