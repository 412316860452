<div class="app-pick-list row" *ngIf="model">
  <div
    class="col-12"
    [ngClass]="isShowButtonMoveAllItem ? 'col-md-5' : 'col-md-6'"
  >
    <div class="app-pick-list-container">
      <h4 class="title m-0" *ngIf="model.sourceTitle">
        {{ model.sourceTitle | translate }}

        <ng-container *ngIf="headerSourceTmpl">
          <ng-container *ngTemplateOutlet="headerSourceTmpl"></ng-container>
        </ng-container>
      </h4>
      <div class="ml-3 mb-2 mt-3 mr-3">
        <div class="input-group my-1">
          <input
            type="text"
            class="form-control"
            [attr.placeholder]="model.searchSourcePlaceholder | translate"
            (input)="onSearch($event.target.value, 'SOURCE')"
            [attr.aria-label]="generatedId + 'source'"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <em class="fas fa-search"></em>
            </span>
          </div>
        </div>
      </div>
      <ul
        cdkDropList
        #sourceList="cdkDropList"
        [cdkDropListData]="sourceItemList"
        [cdkDropListConnectedTo]="[targetList]"
        class="pick-list-ul"
        (cdkDropListDropped)="drop($event)"
      >
        <ng-container
          *ngIf="
            sourceTmpl || itemTmpl;
            then customSourceTemplate;
            else defaultSourceTemplate
          "
        ></ng-container>
        <ng-template #customSourceTemplate>
          <li
            class="pick-list-li-item"
            *ngFor="let item of sourceItemList; let i = index"
            cdkDrag
            (click)="doMoveItem(item, 'TARGET')"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
          >
            <ng-container
              *ngTemplateOutlet="
                sourceTmpl || itemTmpl;
                context: { $implicit: item }
              "
            ></ng-container>
          </li>
        </ng-template>
        <ng-template #defaultSourceTemplate>
          <li
            class="pick-list-li-item"
            *ngFor="let item of sourceItemList; let i = index"
            cdkDrag
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
          >
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input checkbox-primary"
                id="{{ item.id }}"
                (click)="doCheckColumn($event, item)"
                [checked]="item.isChecked"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
              />
              <label class="custom-control-label" for="{{ item.id }}">{{
                item | resolveFieldData: model.viewField | translate
              }}</label>
            </div>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
  <div class="col-12 col-md-2 center-button" *ngIf="isShowButtonMoveAllItem">
    <div class="mb-3">
      <app-button color="SECONDARY" (onClick)="doMoveAllItemToTarget()">
        <em class="fas fa-angle-double-right"></em>
      </app-button>
    </div>
    <div>
      <app-button color="SECONDARY" (onClick)="doMoveAllItemToSource()">
        <em class="fas fa-angle-double-left"></em>
      </app-button>
    </div>
  </div>
  <div
    class="col-12"
    [ngClass]="isShowButtonMoveAllItem ? 'col-md-5' : 'col-md-6'"
  >
    <div class="app-pick-list-container">
      <h4 class="title m-0 separator" *ngIf="model.targetTitle">
        {{ model.targetTitle | translate }}

        <ng-container *ngIf="headerTargetTmpl">
          <ng-container *ngTemplateOutlet="headerTargetTmpl"></ng-container>
        </ng-container>
      </h4>
      <div class="m-2" *ngIf="searchTargetPlaceholder">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            [attr.placeholder]="searchTargetPlaceholder | translate"
            (input)="onSearch($event.target.value, 'TARGET')"
            [attr.aria-label]="generatedId + 'target'"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <em class="fas fa-search"></em>
            </span>
          </div>
        </div>
      </div>
      <ul
        cdkDropList
        #targetList="cdkDropList"
        [cdkDropListData]="targetItemList"
        [cdkDropListConnectedTo]="[sourceList]"
        class="pick-list-ul"
        (cdkDropListDropped)="drop($event)"
      >
        <ng-container
          *ngIf="
            targetTmpl || itemTmpl;
            then customTargetTemplate;
            else defaultTargetTemplate
          "
        ></ng-container>
        <ng-template #customTargetTemplate>
          <li
            class="pick-list-li-item"
            *ngFor="let item of targetItemList; let i = index"
            cdkDrag
            (click)="doMoveItem(item, 'SOURCE')"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
          >
            <ng-container
              *ngTemplateOutlet="
                targetTmpl || itemTmpl;
                context: { $implicit: item }
              "
            ></ng-container>
          </li>
        </ng-template>
        <ng-template #defaultTargetTemplate>
          <li
            class="pick-list-li-item"
            *ngFor="let item of targetItemList; let i = index"
            cdkDrag
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
          >
            <div>
              <em
                class="pir pi-bars"
                [ngStyle]="{ 'padding-right': '21px' }"
              ></em>
              {{ item | resolveFieldData: model.viewField | translate }}
            </div>
            <em
              class="pir pi-times btn-delete pi-3x text-danger text-right"
              (click)="doDeleteTargetItem(item, 'SOURCE')"
            ></em>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
</div>
