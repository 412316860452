import { Component, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ScoringCriteria } from '../../core/bean/scoring-criteria';
import { ScoringCriteriaGroup } from '../../core/bean/scoring-criteria-group';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { PerformanceOnRegistrationDTO } from './dto/performance-on-registration.dto';
@Component({
  templateUrl: './performance-on-registration-detail.component.html'
})
export class PerformanceOnRegistrationDetailComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<ScoringCriteria>;
  public vendorId: number;
  public performanceOnRegistrationDTO: PerformanceOnRegistrationDTO;
  public isSummary = false;
  public isView = false;
  public totalScoring = 0;
  public totalScoring1 = 0;
  public totalResultCommon: number[] = [];
  public totalResultSpecific: number[] = [];
  public resultList: number[] = [];
  public scoringCriteriaGroupList: ScoringCriteriaGroup[] = [];
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public isApproval = false;
  public objectId: number;

  public fileUploader: FileUploader = new FileUploader(
    '/performance-on-registration/',
    'performance-on-registration.form.headerImage',
    this.global.appConstant.fileType.DOC_PERFORMANCE
  );

  constructor(translateService: TranslateService) {
    super('performance-on-registration', translateService);
  }

  onInit(): void {
    this.getFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
    this.setViewOnly();
  }

  public getFromRouterParams(): void {
    this.vendorId = this.global.routerParams.get('vendorId');
    this.todo = this.global.routerParams.get('todo');
    this.isApproval = this.global.routerParams.get('isApproval');
    this.objectId = this.global.routerParams.get('objectId'); // vendor performance id
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorName: [null],
      address: [null],
      vendorType: [null],
      uploadFile: [null],
      recommendationNote: [null],
      commonList: this.formBuilder.array([]),
      specificList: this.formBuilder.array([]),
      summaryList: this.formBuilder.array([]),
      performanceUser: [null],
      rating: [null]
    });
  }

  public setFormGroup(): void {
    this.log.debug(this.global.routerParams);
    this.getPerformanceOnRegistrationDTOFromServer(
      '/performance-on-registration/detail',
      this.todo,
      this.objectId ? this.objectId : this.vendorId
    ).subscribe(performanceOnRegistrationDTO => {
      this.workflowModelPrcs =
        performanceOnRegistrationDTO.performance.workflowModelPrcs;
      this.performanceOnRegistrationDTO = performanceOnRegistrationDTO;

      this.performanceOnRegistrationDTO.scoringCriteriaGroupCommonList.forEach(
        common => {
          this.scoringCriteriaGroupList.push(common);
        }
      );
      this.performanceOnRegistrationDTO.scoringCriteriaGroupSpesificList.forEach(
        specific => {
          this.scoringCriteriaGroupList.push(specific);
        }
      );
      this.fileUploader.setFile(
        this.performanceOnRegistrationDTO.performance.file
      );
      if (this.performanceOnRegistrationDTO.vendor !== null) {
        this.formGroup.patchValue({
          vendorName: this.performanceOnRegistrationDTO.vendor.name,
          vendorType: this.performanceOnRegistrationDTO.vendor.vendorType.name,
          address: this.performanceOnRegistrationDTO.vendorAddress.address,
          performanceUser:
            this.performanceOnRegistrationDTO.performance.verifiedByUser.name,
          recommendationNote:
            this.performanceOnRegistrationDTO.performance.note,
          uploadFile: this.fileUploader.fileObjectList
        });
      }
      this.formGroup.patchValue({ rating: this.totalScoring1 });
      this.formGroup.get('rating').setIsView(true);
      this.buildCommonList();
      this.buildSpecificList();
      this.buildSummaryList();
      this.setStateReady();
    });
  }

  public buildCommonList(): void {
    const parentForm = this.formGroup.controls.commonList as FormArray;
    this.performanceOnRegistrationDTO.scoringCriteriaGroupCommonList.forEach(
      common => {
        const formGroup = this.formBuilder.group({
          scoringCriteriaGroup: common,
          scoringCriteriaList: this.formBuilder.array([])
        });
        parentForm.push(formGroup);
      }
    );
    this.buildScoringCriteriaCommonList();
  }

  public buildSpecificList(): void {
    const parentForm = this.formGroup.controls.specificList as FormArray;
    this.performanceOnRegistrationDTO.scoringCriteriaGroupSpesificList.forEach(
      specific => {
        const formGroup = this.formBuilder.group({
          scoringCriteriaGroup: specific,
          scoringCriteriaList: this.formBuilder.array([])
        });
        parentForm.push(formGroup);
      }
    );
    this.buildScoringCriteriaSpecificList();
  }

  public buildSummaryList(): void {
    const parentForm = this.formGroup.controls.summaryList as FormArray;
    this.performanceOnRegistrationDTO.performanceSummaryList.forEach(
      summary => {
        this.totalScoring =
          Math.round((this.totalScoring + summary.total) * 100) / 100;
        const formGroup = this.formBuilder.group({
          scoringCriteriaGroup: summary.scoringCriteriaGroupName,
          weight: summary.weight,
          result: summary.result,
          total: summary.total
        });
        parentForm.push(formGroup);
      }
    );
    this.formGroup.patchValue({ rating: this.totalScoring });
    this.formGroup.get('rating').setIsView(true);
  }

  public buildScoringCriteriaCommonList(): void {
    const parentForm = this.formGroup.controls.commonList as FormArray;
    this.performanceOnRegistrationDTO.scoringCriteriaGroupCommonList.forEach(
      (common, index) => {
        let total = 0;
        let numberTemp = 0;
        this.performanceOnRegistrationDTO.performanceScoreCommonList.forEach(
          score => {
            if (common.code === score.scoringCriteriaGroupCode) {
              numberTemp += 1;
              const formGroup = this.formBuilder.group({
                scoringCriteria: score.scoringCriteriaName,
                value: score.value,
                result: score.result
              });
              total = total + score.result;
              const scoringCriteriaListForm = parentForm.controls[index].get(
                'scoringCriteriaList'
              ) as FormArray;
              scoringCriteriaListForm.push(formGroup);
            }
          }
        );
        total = Math.round((total / numberTemp) * 100) / 100;
        this.totalResultCommon.push(total);
      }
    );
  }

  public buildScoringCriteriaSpecificList(): void {
    const parentForm = this.formGroup.controls.specificList as FormArray;
    this.performanceOnRegistrationDTO.scoringCriteriaGroupSpesificList.forEach(
      (specific, index) => {
        let total = 0;
        let numberTemp = 0;
        this.performanceOnRegistrationDTO.performanceScoreSpecificList.forEach(
          score => {
            if (specific.code === score.scoringCriteriaGroupCode) {
              numberTemp += 1;
              const formGroup = this.formBuilder.group({
                scoringCriteria: score.scoringCriteriaName,
                value: score.value,
                result: score.result
              });
              total = total + score.result;
              const scoringCriteriaListForm = parentForm.controls[index].get(
                'scoringCriteriaList'
              ) as FormArray;
              scoringCriteriaListForm.push(formGroup);
            }
          }
        );
        total = Math.round((total / numberTemp) * 100) / 100;
        this.totalResultSpecific.push(total);
      }
    );
  }

  public getPerformanceOnRegistrationDTOFromServer(
    urlGetDTO: string,
    todo: string,
    vendorId: number
  ) {
    return this.httpClientService.post<PerformanceOnRegistrationDTO>(
      urlGetDTO,
      new RouteRequestModel(todo, vendorId)
    );
  }

  // public doScoring(): void {
  //     this.isSummary = true;
  //     this.buildSummaryList();
  // }

  public doBack(): void {
    this.global.routerParams.clear();
    this.router.navigate(['/pages/performance-registration']);
  }

  public doPrint(): void {}

  public getFormArray(
    formGroup: FormGroup,
    formControlName: string
  ): FormArray {
    return formGroup.get(formControlName) as FormArray;
  }
}
