import { EventEmitter } from '@angular/core';
import { CarouselImage } from './carousel-image-slider';
export class CarouselResponseModel {
  public images: CarouselImage[];
  public isSlideOnly: boolean;
  public isShowIndicator: boolean;
  public isShowControl: boolean;
  public isShowCaption: boolean;
  public carouselInterval: number;
  public carouselKeyboard: boolean;
  public carouselPause: string | boolean;
  public carouselRide: string | boolean;
  public carouselWrap: boolean;
  public stringUrl: string;
  reloadChanges: EventEmitter<boolean> = new EventEmitter();
  constructor(
    interval?: number,
    ride?: string | boolean,
    pause?: string | boolean
  ) {
    this.carouselInterval = interval || 5000;
    this.carouselRide = ride || 'carousel';
    this.carouselPause = pause || 'hover';
    this.isShowIndicator = true;
    this.isShowControl = true;
    this.isShowCaption = true;
    this.carouselKeyboard = true;
    this.carouselWrap = true;
  }

  public setStringUrl(stringUrl: string): void {
    this.stringUrl = stringUrl;
  }
}
