<div class="app-tree-org">
  <div class="content-wrapper">
    <div class="tree-control left" [attr.id]="'control-left' + generatedId">
      <em
        class="fas fa-chevron-left"
        (click)="doScrollToLeft()"
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
      ></em>
    </div>
    <div class="app-tree-body row" [attr.id]="generatedId" #appTreeBody>
      <div
        class="app-tree-item col-12 col-sm-6 col-lg-4"
        *ngFor="let tree of this.model.treeList; index as i"
      >
        <header class="item-header">
          <input
            type="text"
            class="form-control"
            [attr.placeholder]="'app-tree.search' | translate"
            (input)="doSearch($event.target.value, i)"
            aria-label="{{ 'search-' + generatedId + '-' + i }}"
          />
          <em
            class="fas fa-search"
            [ngStyle]="{ right: model.mode === 'READ' ? '1.4rem' : '3.5rem' }"
          ></em>
          <button
            class="btn btn-primary"
            [attr.title]="'app.button.add.organization' | translate"
            (click)="doAdd(i)"
            [disabled]="model.disableRootButton && i === 0"
            *ngIf="model.mode === 'WRITE'"
          >
            <em class="fas fa-plus"></em>
          </button>
        </header>
        <div class="form-loader" *ngIf="tree.isLoading"></div>
        <ul class="item-content my-2" *ngIf="!tree.isLoading">
          <app-lazy-load
            [isLoading]="tree.isLoading"
            (onScrollEnd)="onScrollEnd(i, $event)"
            target=".item-content"
            maxHeight="auto"
          >
          <li
            class="content-child"
            *ngFor="let treeItem of tree.treeItemList; index as j"
            (click)="doClickTreeItem(treeItem, i)"
            [ngClass]="treeItem.selected ? 'selected' : ''"
            [attr.title]="treeItem.code + '\n' + treeItem.name"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
          >
            <div class="content-child-left">
              <div
                class="custom-control custom-checkbox"
                (click)="doChecked($event, treeItem)"
                *ngIf="model.mode === 'READ' && model.isMultiple"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="{{ 'tree-check-box' + treeItem.id }}"
                  [value]="treeItem.isChecked"
                  [checked]="treeItem.isChecked"
                  [ngClass]="
                    model.onlyLastChild && treeItem.hasChild
                      ? 'checkbox-secondary'
                      : 'checkbox-primary cursor-pointer'
                  "
                  [disabled]="model.onlyLastChild && treeItem.hasChild"
                />
                <label
                  class="custom-control-label"
                  for="{{ 'tree-check-box' + treeItem.id }}"
                ></label>
              </div>
              <span class="app-tree-item-content-text">
                <ng-container *ngIf="customTextTmpl">
                  <ng-container
                    *ngTemplateOutlet="
                      customTextTmpl;
                      context: { $implicit: treeItem }
                    "
                  >
                  </ng-container>
                </ng-container>
                <div style="margin: 0 auto; display: flex; position: relative">
                  <div
                    style="
                      width: 50px;
                      height: 50px;
                      overflow: hidden;
                      border-radius: 50px;
                    "
                    class="mr-3"
                  >
                    <!--<img               
                      [attr.src]="
                        global.config.BACKEND_ADDRESS +
                        '/' +
                        'organization/file/view/' +
                        treeItem.fileImage?.uploadedFileName +
                        '/IMG_SMALL_LOGO'
                        | resolveImgSrc
                        | async
                        "
                        alt="logo"
                        width="auto"
                        height="auto"
                    />-->
                  </div>
                  <div class="d-inline-block" style="margin-top: 15px;">     
                    <ng-container *ngIf="!customTextTmpl"> 
                      <span class="d-inline-flex flex-column">       
                        {{ treeItem.name }}
                      </span>
                    </ng-container>
                  </div>
                </div>
              </span>
            </div>
            <div class="content-child-right">
              <div
                class="button-group button-group-right"
                *ngIf="model.mode === 'WRITE'"
              >
                <app-button
                  size="TN"
                  title="{{ 'app.tooltip.edit' | translate }}"
                  (onClick)="doEdit(treeItem)"
                >
                  <em class="pir pi-pencil-alt"></em>
                </app-button>
              </div>
              <em
                class="fas fa-caret-right"
                [ngClass]="
                  !treeItem.hasChild || i >= model.limitLevel
                    ? 'transparent'
                    : ''
                "
              ></em>
            </div>
          </li>
          <li class="text-center my-2" *ngIf="tree.isNoData">
            {{ 'app.info.noData' | translate }}
          </li>
          </app-lazy-load>
        </ul>
      </div>
    </div>
    <div class="tree-control right" [attr.id]="'control-right' + generatedId">
      <em
        class="fas fa-chevron-right"
        (click)="doScrollToRight()"
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
      ></em>
    </div>
  </div>
  <div
    class="app-tree-footer"
    [hidden]="model.treeProcess.treeProcessItemList.length === 0"
    *ngIf="!footerButtonTmpl || model.mode === 'READ'"
  >
  </div>
  <div
    class="app-tree-footer"
    *ngIf="footerButtonTmpl && model.treeProcess.treeProcessItemList.length > 0"
  >
    <ng-container
      *ngTemplateOutlet="
        footerButtonTmpl;
        context: { $implicit: model.treeProcess }
      "
    >
    </ng-container>
  </div>
</div>
