import { NgModule } from '@angular/core';
import { AppDropDownModule } from '../../../../core/components/app-drop-down/app-drop-down.module';
import { DirectivesModule } from '../../../../core/directives/directives.module';
import { PipesModule } from '../../../../core/pipe/pipe.module';
import { VendorModule } from '../../../../core/vendor/vendor.module';
import { ProfileComponent } from './profile.component';

@NgModule({
  imports: [VendorModule, AppDropDownModule, PipesModule, DirectivesModule],
  declarations: [ProfileComponent],
  exports: [ProfileComponent]
})
export class ProfileModule {}
