<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="role-menu.title" detail="{{ role.name }}" [isLoading]="formLoading" [isSaving]="formSaving">
      <app-pick-list [model]="pickListModel" searchSourcePlaceholder="role-menu.appPickList.menuPlaceHolder"
        searchTargetPlaceholder="role-menu.appPickList.menuPlaceHolder"
        sourceTitle="role-menu.appPickList.menuSourceTitle" targetTitle="role-menu.appPickList.menuTargetTitle">

        <ng-template #headerTarget>
          <h4 style="display: contents;">{{role.name}}</h4>
          <span style="font-size: 1rem; padding: 0 1rem;">
            <em class="fas fa-info-circle" placement="right" ngbTooltip="{{'role-menu.tooltip.text' | translate}}"></em>
          </span>
        </ng-template>

        <ng-template #source let-menu>
          <div>
            <em class="fa fa-{{ menu.css }}" [ngStyle]="{ 'padding-right': '0.22rem' }"></em>
            {{ menu.name }}
          </div>
        </ng-template>
        <ng-template #target let-menu>
          <div>
            <em class="fa fa-{{ menu.css }}" [ngStyle]="{ 'padding-right': '0.22rem' }"></em>
            {{ menu.name }}
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input checkbox-primary" id="{{ menu.id }}"
              (click)="doChangeIsWrite($event, menu)" [checked]="menu.isWrite" (keyup)="onKeyUp($event)"
              (keydown)="onKeyDown($event)" />
            <label class="custom-control-label" for="{{ menu.id }}"></label>
          </div>
        </ng-template>
      </app-pick-list>
      <div class="button-group button-group-center mt-5">
        <app-button color="SECONDARY" (onClick)="doCancel()" [disabled]="formSaving">
          {{ 'app.button.back' | translate }}
        </app-button>
        <app-button (onClick)="doSave()" [disabled]="formSaving">
          {{ 'app.button.save' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>