import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppWorkPathComponent } from './app-work-path.component';
@NgModule({
  imports: [VendorModule, AppCardModule],
  declarations: [AppWorkPathComponent],
  exports: [AppWorkPathComponent]
})
export class AppWorkPathModule {}
