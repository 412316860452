import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTaggingModule } from '../app-tagging/app-tagging.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppTextTaggingComponent } from './app-text-tagging.component';
@NgModule({
  imports: [VendorModule, AppTaggingModule, AppTooltipWrapperModule],
  declarations: [AppTextTaggingComponent],
  exports: [AppTextTaggingComponent]
})
export class AppTextTaggingModule {}
