import { FileType } from '../../core/bean/file-type';
import { Lang } from '../../core/bean/lang';
import { ProcurementDocType } from '../../core/bean/procurement-doc-type';
import { Translation } from '../../core/bean/translation';

export class ProcurementDocTypeResponse {
  translationList: Translation[];
  fileTypeList: FileType[];
  langList: Lang[];
  procurementDocType: ProcurementDocType;
}
