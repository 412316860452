import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppFieldsetModule } from '../../app-fieldset/app-fieldset.module';
import { AppTextFieldModule } from '../../app-text-field/app-text-field.module';
import { AppTextTaggingModule } from '../../app-text-tagging/app-text-tagging.module';
import { AppWysiwygModule } from '../../app-wysiwyg/app-wysiwyg.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupEmailComponent } from './app-popup-email.component';
import { AppPopupEmailService } from './app-popup-email.service';
@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppFieldsetModule,
    AppButtonModule,
    AppWysiwygModule,
    AppTextFieldModule,
    AppTextTaggingModule
  ],
  declarations: [AppPopupEmailComponent],
  entryComponents: [AppPopupEmailComponent],
  providers: [AppPopupEmailService],
  exports: [AppPopupEmailComponent]
})
export class AppPopupEmailModule {}
