import { File } from '../bean/file';
import { Organization } from '../bean/organization';
import { Qualification } from '../bean/qualification';
import { Region } from '../bean/region';
import { User } from '../bean/user';
import { VendorActivityStatus } from '../bean/vendor-activity-status';
import { VendorCategory } from '../bean/vendor-category';
import { VendorIndustry } from '../bean/vendor-industry';
import { VendorLocation } from '../bean/vendor-location';
import { VendorOwnership } from '../bean/vendor-ownership';
import { VendorProductLocation } from '../bean/vendor-product-location';
import { VendorProductServiceCategory } from '../bean/vendor-product-service-category';
import { VendorStatus } from '../bean/vendor-status';
import { VendorType } from '../bean/vendor-type';
import { BaseWorkflowObjectEntity } from './../base/base-workflow-object-entity.model';
import { VendorEntityEntity } from './vendor-entity.entity';
export class VendorEntity extends BaseWorkflowObjectEntity {
  totalWanprestasi: number;
  vendorProductServiceCategoryList: VendorProductServiceCategory[];
  vendorIndustryList: VendorIndustry[];
  vendorProductLocationList: VendorProductLocation[];
  phoneRegion: Region;
  vendorType: VendorType;
  vendorOwnership: VendorOwnership;
  vendorCategory: VendorCategory;
  vendorEntity: VendorEntityEntity;
  vendorLocation: VendorLocation;
  vendorStatus: VendorStatus;
  vendorActivityStatus: VendorActivityStatus;
  user: User;
  verifiedByUser: User;
  logoFile: File;
  bannerFile: File;
  companyProfileFile: File;
  qualification: Qualification;
  onBoardingByOrganization: Organization;
  abbreviation: string;
  trademarkName: string;
  pkpNumber: string;
  taxNumber: string;
  certificateOfEstablishment: string;
  email: string;
  establishmentYear: number;
  phone: string;
  totalEmployee: number;
  description: string;
  facebook: string;
  linkedin: string;
  ig: string;
  twitter: string;
  website: string;
  registrationNumber: string;
  keyword: string;
  initialName: string;
  isPkp: boolean;
  idNumber: string;
  totalExpert: number;
  totalExperience: number;
  totalEquipment: number;
  onBoardingSubmissionDate: Date;
  isManual: boolean;
}
