<div class="app-table-wrapper">
  <div class="app-table-header">
    <!-- header top -->
    <div
      class="header-top lpb-1"
      *ngIf="header || isShowEditTable || headerButtonsTmpl"
    >
      <div class="table-title" *ngIf="header">
        <h3 class="title">{{ header | translate }}</h3>
      </div>
      <div
        class="table-header-action"
        *ngIf="isShowEditTable || headerButtonsTmpl"
      >
        <div class="button-group button-group-right">
          <app-button
            (onClick)="doShowPopupEditTable()"
            *ngIf="isShowEditTable"
          >
            {{ 'app-table.button.modifyTable' | translate }}
          </app-button>
          <ng-container *ngTemplateOutlet="headerButtonsTmpl"></ng-container>
        </div>
      </div>
    </div>
    <div class="header-bottom lpy-1">
      <!-- filter section -->
      <div class="app-table-filter">
        <div
          class="app-table-filter-left"
          *ngIf="
            headerFilterGroupTemplate ||
            headerFilterTemplate ||
            isShowButtonExpandCollapse
          "
        >
          <div
            class="app-table-filter-item btn-filter"
            *ngIf="headerFilterGroupTemplate"
          >
            <app-button
              mode="BYPASS"
              (onClick)="state.isShowFilterGroup = !state.isShowFilterGroup"
            >
              <span class="filter-text">
                {{ 'app-table.button.filters' | translate }}
              </span>
              <em
                class="pir pi-chevron-circle-down ml-2"
                [@rotate]="!state.isShowFilterGroup ? 'default' : 'rotated'"
              ></em>
            </app-button>
          </div>
          <ng-container *ngIf="headerFilterTemplate">
            <ng-container
              *ngTemplateOutlet="headerFilterTemplate"
            ></ng-container>
          </ng-container>
          <div class="app-table-filter-item" *ngIf="isShowButtonExpandCollapse">
            <div class="button-group button-group-left">
              <app-button
                color="PRIMARY"
                (onClick)="doExpandOrCollapseAllTableRow('EXPAND')"
                >{{ 'app-table.button.expandAll' | translate }}</app-button
              >
              <app-button
                color="LIGHT"
                (onClick)="doExpandOrCollapseAllTableRow('COLLAPSE')"
                >{{ 'app-table.button.collapseAll' | translate }}</app-button
              >
            </div>
          </div>
        </div>
        <div class="app-table-filter-right" *ngIf="isShowSearch !== false">
          <div class="app-table-filter-item">
            <div class="input-group input-group-search">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <em class="pir pi-search"></em>
                </span>
              </div>
              <input
                #inputSearch
                type="text"
                class="form-control"
                [placeholder]="'app-table.search' | translate"
                [attr.aria-label]="'app-table.search' | translate"
                (input)="onInputSearch($event.target.value)"
              />
              <em
                class="pir pi-times btn-delete pi-3x text-danger"
                *ngIf="state.tableRequest.globalFilter"
                (click)="doResetInputSearch(inputSearch)"
              ></em>
            </div>
          </div>
        </div>
      </div>
      <!-- filter group section -->
      <div
        class="app-table-filter"
        [@expandedCollapsed]="
          !state.isShowFilterGroup ? 'collapsed' : 'expanded'
        "
      >
        <div class="app-table-filter-group lmt-1">
          <ng-container *ngTemplateOutlet="headerFilterGroupTemplate">
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div
    class="app-table-content"
    [ngStyle]="{
      'min-height': state.records.length < 3 ? '150px' : 'fit-content'
    }"
  >
    <div
      class="app-table-loading"
      *ngIf="state.isLoading && state.isShowPagination !== false"
    >
      {{ 'app-table.processing' | translate }}
    </div>
    <ng-container
      *ngTemplateOutlet="
        state.isShowPagination === false ? lazyLoadTableTemplate : tableTemplate
      "
    ></ng-container>
  </div>
  <div class="app-table-footer" *ngIf="state.records.length > 0">
    <ng-container *ngTemplateOutlet="tableFooterTmpl"></ng-container>
    <ng-container *ngIf="isShowPagination !== false">
      <app-pagination-x
        [model]="state.pagination"
        (onChange)="onChangePage($event)"
      >
        <ng-template #contentLeft>
          <div class="form-group d-flex align-items-center m-0">
            {{ 'app-table.show' | translate }}
            <select
              class="form-control form-control-sm"
              id="exampleFormControlSelect1"
              style="width: fit-content"
              (change)="onChangeFilterPerPage($event.target.value)"
              [ngStyle]="{ margin: '0 8px' }"
            >
              <option
                *ngFor="let page of [5, 10, 25, 50, 100]"
                [value]="page"
                [selected]="page === state.pagination.perPage"
                >{{ page }}</option
              >
            </select>
            {{ 'app-table.pagination.itemsOf' | translate }}
            {{ state.pagination.totalRecords }}
            {{ 'app-table.pagination.entries' | translate }}
          </div>
        </ng-template>
      </app-pagination-x>
    </ng-container>
  </div>
</div>

<ng-template #lazyLoadTableTemplate>
  <div class="fixed-table-wrapper">
    <div class="fixed-table-content">
      <table class="table">
        <thead #theadFixed>
          <ng-container *ngTemplateOutlet="theadRowTemplate"></ng-container>
        </thead>
      </table>
    </div>
  </div>
  <app-lazy-load
    maxHeight="400px"
    (onScrollEnd)="onScrollEnd()"
    [isLoading]="state.isLoading"
  >
    <ng-container *ngTemplateOutlet="tableTemplate"></ng-container>
  </app-lazy-load>
</ng-template>

<ng-template #tableTemplate>
  <perfect-scrollbar (psScrollX)="onPsScrollX($event)">
    <table class="table">
      <thead #theadDefault>
        <ng-container *ngTemplateOutlet="theadRowTemplate"></ng-container>
      </thead>
      <tbody>
        <tr
          class="tr-no-data"
          *ngIf="!state.isLoading && state.records.length === 0"
        >
          <td colspan="9999999" rowspan="7" class="bg-white text-center">
            <div class="no-data-wrapper">
              <div class="book-wrapper pvc pv-file-list"></div>
              <div class="text-no-data">
                <span class="text-no-data">
                  {{ 'app-table.noDataAvailable' | translate }}
                </span>
                <span class="text-try-to-add">
                  {{ 'app-table.letsTryToAddList' | translate }}
                </span>
              </div>
            </div>
          </td>
        </tr>
        <ng-container *ngFor="let record of state.records; let i = index">
          <tr
            [ngClass]="record.isExpanded ? 'show' : ''"
            (click)="onClick.emit(record.record)"
          >
            <td
              class="col-action"
              *ngIf="actionButtonsTemplate || isShowCheckBox"
            >
              <div
                class="custom-control"
                [ngClass]="
                  isMultipleSelect === false
                    ? 'custom-radio'
                    : 'custom-checkbox'
                "
                [hidden]="state | resolveIsShowCheckBox: record"
                (click)="doClickInputCheckbox($event)"
              >
                <input
                  [type]="isMultipleSelect === false ? 'radio' : 'checkbox'"
                  [name]="generatedId"
                  class="custom-control-input"
                  [attr.id]="'customCheck' + generatedId + i + 2"
                  (change)="
                    onChangeSelectTableRecord($event.target.checked, record)
                  "
                  [checked]="
                    state.checkedRecord
                      | resolveTableRowIsChecked
                        : record
                        : state.checkedRecord.isCheckedAll
                  "
                />
                <label
                  class="custom-control-label"
                  [attr.for]="'customCheck' + generatedId + i + 2"
                ></label>
              </div>
            </td>
            <td *ngIf="model.options?.group">
              <div class="td-content expand-collapse">
                <em
                  class="fas fa-chevron-down cursor-pointer"
                  (click)="doExpandOrCollapseTableRow(record)"
                ></em>
              </div>
            </td>
            <td
              [ngClass]="model.options?.group ? '' : 'cursor-pointer'"
              *ngFor="
                let column of model.options?.group
                  ? [model.options.group]
                  : model.columns;
                let j = index
              "
              [attr.colspan]="model.options?.group ? 999 : 1"
              [tableRecord]="record"
              [column]="column"
              [state]="state"
              [indexI]="
                i + 1 + (state.pagination.page - 1) * state.pagination.perPage
              "
              [indexJ]="j + 1"
              app-table-td
            >
            </td>
          </tr>

          <!-- start: children -->
          <ng-container *ngIf="record.isExpanded">
            <tr
              *ngFor="let r of record.childrens; let j = index"
              (click)="onClick.emit(record.record)"
            >
              <td
                class="col-action"
                *ngIf="actionButtonsTemplate || isShowCheckBox"
              >
                <div
                  class="custom-control"
                  [ngClass]="
                    isMultipleSelect === false
                      ? 'custom-radio'
                      : 'custom-checkbox'
                  "
                  (click)="doClickInputCheckbox($event)"
                >
                  <input
                    [type]="isMultipleSelect === false ? 'radio' : 'checkbox'"
                    [name]="generatedId"
                    class="custom-control-input"
                    [attr.id]="'customCheck' + i + j + 2"
                    (change)="
                      onChangeSelectTableRecord(
                        $event.target.checked,
                        r,
                        record
                      )
                    "
                    [checked]="
                      state.checkedRecord
                        | resolveTableRowIsChecked
                          : r
                          : state.checkedRecord.isCheckedAll ||
                              record.selectedRecord.isCheckedAll
                    "
                  />
                  <label
                    class="custom-control-label"
                    [attr.for]="'customCheck' + i + j + 2"
                  ></label>
                </div>
              </td>
              <td></td>
              <td
                class="cursor-pointer"
                *ngFor="let column of model.columns; let j = index"
                [tableRecord]="r"
                [column]="column"
                [state]="state"
                [indexI]="i"
                [indexJ]="j"
                app-table-td
              >
              </td>
            </tr>
            <tr>
              <td
                colspan="99999"
                class="text-center"
                *ngIf="!record.isLoading && record.isShowButtonLoadMore"
              >
                <button
                  class="btn btn-primary"
                  (click)="doLoadTableRecordChildren(record)"
                  >{{ 'app-table.button.loadMore' | translate }}</button
                >
              </td>
            </tr>
            <tr *ngIf="record.isLoading">
              <td colspan="99999">
                <div class="promise-loading">
                  <div class="loading"></div>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="rowTemplate">
          <ng-container
            *ngTemplateOutlet="rowTemplate; context: { $implicit: state }"
          ></ng-container>
        </ng-container>
      </tbody>
    </table>
  </perfect-scrollbar>
</ng-template>

<ng-template #theadRowTemplate>
  <tr
    class="tr-action"
    *ngIf="
      (actionButtonsTemplate || isShowCheckBox) &&
      (state.checkedRecord.checkedRecordList.length > 0 ||
        state.checkedRecord.isCheckedAll)
    "
  >
    <th class="th-action" colspan="9999">
      <div class="th-action-content">
        <span class="total-selected">
          {{
            state.checkedRecord.isCheckedAll
              ? 'All Record Selected'
              : state.checkedRecord.checkedRecordList.length + ' Items Selected'
          }}
        </span>
        <div
          class="button-group button-group-left btn-delete"
          *ngIf="actionButtonsTemplate"
        >
          <ng-container
            *ngTemplateOutlet="
              actionButtonsTemplate;
              context: {
                $implicit: state.checkedRecord.getValue()
              }
            "
          ></ng-container>
        </div>
      </div>
    </th>
  </tr>
  <tr>
    <th class="col-action" *ngIf="actionButtonsTemplate || isShowCheckBox">
      <div
        class="custom-control custom-checkbox"
        *ngIf="isMultipleSelect !== false"
      >
        <input
          type="checkbox"
          class="custom-control-input"
          [indeterminate]="
            state.checkedRecord.checkedRecordList.length > 0 &&
            state.checkedRecord.checkedRecordList.length !==
              state.pagination.totalRecords
          "
          [attr.id]="'customCheck' + generatedId + 0"
          [checked]="
            state.pagination.totalRecords > 0 &&
            state.checkedRecord.checkedRecordList.length ===
              state.pagination.totalRecords
          "
          (change)="onChangeSelectAllTableRecord($event.target.checked)"
        />
        <label
          class="custom-control-label"
          [attr.for]="'customCheck' + generatedId + 0"
        ></label>
      </div>
    </th>
    <th class="th-group" *ngIf="model.options?.group"></th>
    <th
      [ngClass]="column.sortable === false ? '' : 'sort'"
      *ngFor="let column of model.columns; let i = index"
      (click)="doSort(column)"
    >
      <div class="th-content">
        <span class="th-text" [ngClass]="[column | resolveColumnClassName]">
          {{
            column.header
              ? (model.moduleCode + '.' + column.header | translate)
              : ''
          }}
        </span>
        <!-- <em class="fas fa-sort ml-2"></em> -->
        <em
          *ngIf="column.sortable !== false"
          class="pis pi-sort ml-2"
          [ngClass]="
            column.field === state.tableRequest.sortField
              ? state.tableRequest.sortOrder === 1
                ? 'sort-asc'
                : 'sort-desc'
              : ''
          "
        ></em>
      </div>
    </th>
  </tr>
</ng-template>
