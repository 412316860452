import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { NotificationTemplateEditAddComponent } from './notification-template-edit-add.component';
import { NotificationTemplateComponent } from './notification-template.component';
const routes: Routes = [
  {
    path: '',
    component: NotificationTemplateComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'edit',
    component: NotificationTemplateEditAddComponent,
    data: { todo: 'edit', breadcrumb: 'notification-template.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    NotificationTemplateComponent,
    NotificationTemplateEditAddComponent
  ]
})
export class NotificationTemplateModule {}
