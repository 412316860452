import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../../core/base/angular/base-module.component';
import { TabModel } from '../../../../core/model/tab/tab-model';
import { TabResponseModel } from '../../../../core/model/tab/tab-response-model';
import { Validators } from '../../../../core/validators';
@Component({
  templateUrl: './tab-one.component.html',
  styles: ['./tab-one.component.scss']
})
export class ExampleTabOneComponent extends BaseModuleComponent {
  public tabResponse: TabResponseModel;
  public tabOne: TabModel;
  constructor(translate: TranslateService) {
    super('tab-one', translate);
  }

  onInit(): void {
    this.setTabResponseAndCurrentTab();
    this.buildFormGroup();
    this.setStateReady();
  }

  public setTabResponseAndCurrentTab(): void {
    this.tabResponse = this.global.routerParams.get('tabResponse');
    this.tabOne = this.tabResponse.currentTab;
  }

  public buildFormGroup(): void {
    if (!this.tabOne.isFormGroupReady) {
      this.tabOne.formGroup = this.formBuilder.group({
        name: [null, Validators.required()],
        salary: [null, Validators.required()],
        region: [null, Validators.required()]
      });
    }
  }
}
