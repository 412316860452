import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AppTableComponent } from 'src/app/core/components/table/components/app-table/app-table.component';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { WorkflowPrcs } from 'src/app/core/bean/workflow-prcs';
import { Workflow } from 'src/app/core/bean/workflow';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { SLAPmResponse } from './sla-pm-response';
import { SLAPmRequest } from './sla-pm-request';

@Component({
    templateUrl: './sla-pm.component.html',
    styleUrls: ['./sla-pm.component.scss']
})
export class SlaPmComponent
    extends BaseModuleComponent
    implements OnInit {
    @ViewChild(AppTableComponent) public table: AppTableComponent;
    public tableResponseProcurement: TableResponseModel<WorkflowPrcs>;
    public tableResponsePurchaseRequest: TableResponseModel<WorkflowPrcs>;
    public tableResponseRfp: TableResponseModel<WorkflowPrcs>;
    public tableResponseRfq: TableResponseModel<WorkflowPrcs>;
    public tableResponseOrderProcess: TableResponseModel<WorkflowPrcs>;
    public workflowList: OptionListModel<Workflow> = new OptionListModel(true);
    public workflowPrcs: WorkflowPrcs[] = [];
    public workflowBeanList: Workflow[] = [];
    public workflowNameList: Workflow[] = [];
    public getWorkflowName: Workflow;
    public setWorkflowName: Workflow;
    public tableResponse: TableResponseModel<WorkflowPrcs>;
    public historyType = 'procurement';
    public roleCode: string;
    public slaPmResponse: SLAPmResponse = new SLAPmResponse();
    public slaPmRequest: SLAPmRequest = new SLAPmRequest();
    constructor(translateService: TranslateService) {
        super('sla-pm', translateService);
    }

    public onInit(): void {
        this.setTabList();
        this.buildFormGroup();
        this.buildTableResponse();
        this.setStateReady();
    }

    public setTabList(): void {
        /*const moduleGroupCode = 'PM';

        this.httpClientService
            .get<Workflow[]>('/workflow/workflow-list-history-type/' + moduleGroupCode + '/' + this.historyType)
            .subscribe((workflowList: Workflow[]) => {
                this.workflowList.setRequestValues(workflowList);
            });*/
        this.roleCode = this.global.userSession.activeUserRole.role.code;

        if(this.roleCode === this.global.appConstant.ROLE_CODE_SUPER_ADMIN || 
            this.roleCode === this.global.appConstant.ROLE_CODE_PJB_PBJ_PUSAT){
            this.historyType = 'purchase request';
        }else{
            this.historyType = 'order process';
        }

    }

    public setWorkflowNameList(): void {
        const moduleGroupCode = 'PM';

        this.httpClientService
            .get<Workflow[]>('/workflow/workflow-list-history-type/' + moduleGroupCode + '/' + this.historyType)
            .subscribe((workflowList: Workflow[]) => {
                this.workflowList.setRequestValues(workflowList);
            });
    }

    public buildFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            date: [null],
            fromDate: [null],
            untilDate: [null],
            workflowName: [null],
            historyType: [this.historyType],
            getWorkflowName: [null]
        });
    }

    public addUomDay(slaInDays: number): string {
        if (slaInDays !== 0 && slaInDays !== null) {
            return slaInDays + ' ' + this.translateService.instant('sla-vendor.day');
        } else {
            return '-';
        }
    }

    public buildTableResponse(): void {
        this.setWorkflowNameList();
        this.formGroup.get('workflowName').reset();
        if (this.historyType === 'procurement') {
            this.tableResponseProcurement = new TableResponseModel(this.moduleCode, [
                {
                    field: 'codeProcurement',
                    header: 'table.column.code',
                    plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
                },
                {
                    field: 'dateProcurement',
                    header: 'table.column.date',
                    plugins: 'date'
                },
                {
                    field: 'procurementName',
                    header: 'table.column.procurementName'
                },
                {
                    field: 'name',
                    header: 'table.column.workflowName',
                    className: 'white-space-normal'
                },
                {
                    field: 'totalSlaInDays',
                    header: 'table.column.totalSla',
                    plugins: {
                        name: 'custom-plugin',
                        before: (tablePluginData: TablePluginData): string => {
                            if (tablePluginData.value !== 0 && tablePluginData.value !== null) {
                                return tablePluginData.value + ' ' + this.translateService.instant('sla-vendor.day');
                            } else {
                                return '-';
                            }
                        }
                    }
                },
                {
                    field: 'totalSlaRealizedName',
                    header: 'table.column.totalRealization'
                }
            ]);
            this.tableResponseProcurement.setCustomData(this.formGroup.value);
        } else if (this.historyType === 'purchase request') {
            this.tableResponsePurchaseRequest = new TableResponseModel(this.moduleCode, [
                {
                    field: 'codePurchaseRequest',
                    header: 'table.column.code',
                    plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
                },
                {
                    field: 'datePurchaseRequest',
                    header: 'table.column.date',
                    plugins: 'date'
                },
                {
                    field: 'description',
                    header: 'table.column.description'
                },
                {
                    field: 'name',
                    header: 'table.column.workflowName',
                    className: 'white-space-normal'
                },
                {
                    field: 'totalSlaInDays',
                    header: 'table.column.totalSla',
                    plugins: {
                        name: 'custom-plugin',
                        before: (tablePluginData: TablePluginData): string => {
                            if (tablePluginData.value !== 0 && tablePluginData.value !== null) {
                                return tablePluginData.value + ' ' + this.translateService.instant('sla-vendor.day');
                            } else {
                                return '-';
                            }
                        }
                    }
                },
                {
                    field: 'totalSlaRealizedName',
                    header: 'table.column.totalRealization'
                }
            ]);
            this.tableResponsePurchaseRequest.setCustomData(this.formGroup.value);
        } else if (this.historyType === 'rfp') {
            this.tableResponseRfp = new TableResponseModel(this.moduleCode, [
                {
                    field: 'codeRfp',
                    header: 'table.column.code',
                    plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
                },
                {
                    field: 'dateRfp',
                    header: 'table.column.date',
                    plugins: 'date'
                },
                {
                    field: 'rfpName',
                    header: 'table.column.title'
                },
                {
                    field: 'name',
                    header: 'table.column.workflowName',
                    className: 'white-space-normal'
                },
                {
                    field: 'totalSlaInDays',
                    header: 'table.column.totalSla',
                    plugins: {
                        name: 'custom-plugin',
                        before: (tablePluginData: TablePluginData): string => {
                            if (tablePluginData.value !== 0 && tablePluginData.value !== null) {
                                return tablePluginData.value + ' ' + this.translateService.instant('sla-vendor.day');
                            } else {
                                return '-';
                            }
                        }
                    }
                },
                {
                    field: 'totalSlaRealizedName',
                    header: 'table.column.totalRealization'
                }
            ]);
            this.tableResponseRfp.setCustomData(this.formGroup.value);
        } else if (this.historyType === 'rfq') {
            this.tableResponseRfq = new TableResponseModel(this.moduleCode, [
                {
                    field: 'codeRfq',
                    header: 'table.column.code',
                    plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
                },
                {
                    field: 'dateRfq',
                    header: 'table.column.date',
                    plugins: 'date'
                },
                {
                    field: 'rfqName',
                    header: 'table.column.title'
                },
                {
                    field: 'name',
                    header: 'table.column.workflowName',
                    className: 'white-space-normal'
                },
                {
                    field: 'totalSlaInDays',
                    header: 'table.column.totalSla',
                    plugins: {
                        name: 'custom-plugin',
                        before: (tablePluginData: TablePluginData): string => {
                            if (tablePluginData.value !== 0 && tablePluginData.value !== null) {
                                return tablePluginData.value + ' ' + this.translateService.instant('sla-vendor.day');
                            } else {
                                return '-';
                            }
                        }
                    }
                },
                {
                    field: 'totalSlaRealizedName',
                    header: 'table.column.totalRealization'
                }
            ]);
            this.tableResponseRfq.setCustomData(this.formGroup.value);
        } else if (this.historyType === 'order process') {
            this.tableResponseOrderProcess = new TableResponseModel(this.moduleCode, [
                {
                    field: 'codeOrder',
                    header: 'table.column.code',
                    plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
                },
                {
                    field: 'dateOrder',
                    header: 'table.column.date',
                    plugins: 'date'
                },
                {
                    field: 'orderName',
                    header: 'table.column.title'
                },
                {
                    field: 'name',
                    header: 'table.column.workflowName',
                    className: 'white-space-normal'
                },
                {
                    field: 'totalSlaInDays',
                    header: 'table.column.totalSla',
                    plugins: {
                        name: 'custom-plugin',
                        before: (tablePluginData: TablePluginData): string => {
                            if (tablePluginData.value !== 0 && tablePluginData.value !== null) {
                                return tablePluginData.value + ' ' + this.translateService.instant('sla-vendor.day');
                            } else {
                                return '-';
                            }
                        }
                    }
                },
                {
                    field: 'totalSlaRealizedName',
                    header: 'table.column.totalRealization'
                }
            ]);
            this.tableResponseOrderProcess.setCustomData(this.formGroup.value);
        }
    }

    // tslint:disable-next-line:typedef
    public doClickHistoryType(event) {
        this.historyType = event;
        this.formGroup.patchValue({
            historyType: this.historyType
        });
        this.buildTableResponse();
        this.tableResponseProcurement?.setCustomData(this.formGroup.value);
        this.tableResponseProcurement?.reload();
        this.tableResponsePurchaseRequest?.setCustomData(this.formGroup.value);
        this.tableResponsePurchaseRequest?.reload();
        this.tableResponseRfp?.setCustomData(this.formGroup.value);
        this.tableResponseRfp?.reload();
        this.tableResponseRfq?.setCustomData(this.formGroup.value);
        this.tableResponseRfq?.reload();
        this.tableResponseOrderProcess?.setCustomData(this.formGroup.value);
        this.tableResponseOrderProcess?.reload();
    }

    public onChangeFilter(): void {
        if (this.formGroup.value.date) {
            this.formGroup.patchValue({
                fromDate: this.formGroup.value.date.from,
                untilDate: this.formGroup.value.date.to
            });
        }
        if (this.formGroup.value.workflowName != null) {
            this.workflowBeanList = this.formGroup.value.workflowName;
            this.workflowBeanList.forEach(value => {
                value.slaUpdatedDate = new Date(value.slaUpdatedDate);
            });
        }
        this.tableResponseProcurement?.setCustomData(this.formGroup.value);
        this.tableResponseProcurement?.reload();
        this.tableResponsePurchaseRequest?.setCustomData(this.formGroup.value);
        this.tableResponsePurchaseRequest?.reload();
        this.tableResponseRfp?.setCustomData(this.formGroup.value);
        this.tableResponseRfp?.reload();
        this.tableResponseRfq?.setCustomData(this.formGroup.value);
        this.tableResponseRfq?.reload();
        this.tableResponseOrderProcess?.setCustomData(this.formGroup.value);
        this.tableResponseOrderProcess?.reload();
        this.formGroup.get('fromDate').reset();
        this.formGroup.get('untilDate').reset();
    }

    public doClick(workflowPrcs: WorkflowPrcs): void {
        this.global.routerParams.clear();
        this.global.routerParams.set(
            'workflowPrcsId',
            workflowPrcs.id
        );
        this.global.routerParams.set(
            'historyType',
            this.historyType
        );
        this.router.navigate([
            '/pages/sla-pm/detail'
        ]);
    }
}
