<div class="app-approval-path">
  <ul
    [ngClass]="[
      model.type === 'SERIAL'
        ? 'serial'
        : model.type === 'PARALEL'
        ? 'paralel'
        : '',
      model.mode === 'READ' ? 'view' : 'write'
    ]"
  >
    <li
      class="text-center"
      *ngFor="let approver of model.approverList; let i = index"
    >
      <div class="item-content">
        <em
          class="fas fa-user-circle"
          [ngStyle]="{ color: !model.type ? '#343434' : 'initial' }"
          [attr.title]="
            model.mode === 'WRITE'
              ? model.moduleCode + '.approvalPath.title.edit'
              : ''
          "
          (click)="doEditApprover(i)"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        >
        </em>
        <em
          class="fas fa-times"
          title="{{
            model.moduleCode + '.approvalPath.title.delete' | translate
          }}"
          (click)="doDeleteApprover(i)"
          *ngIf="model.mode === 'WRITE'"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        ></em>
        <ng-container
          *ngIf="!customTextTmpl; then approvalView; else customView"
        >
        </ng-container>
        <ng-template #customView>
          <ng-container
            *ngTemplateOutlet="
              customTextTmpl;
              context: { $implicit: model.origin[i] }
            "
          ></ng-container>
        </ng-template>
        <ng-template #defaultView>
          <span
            class="d-block"
            [ngStyle]="{
              color: !model.type ? '#343434' : 'initial',
              'font-weight': '500',
              'margin-top': '10px'
            }"
            >{{ approver.title | translate }}</span
          >
          <span
            class="d-block"
            [ngStyle]="{
              color: !model.type ? '#343434' : 'initial',
              margin: '5px 0 5px',
              'font-style': 'italic'
            }"
            >{{ approver.subTitle | translate }}</span
          >
        </ng-template>
        <ng-template #approvalView>
          <ng-container *ngIf="isApprovalView; else defaultView">
            <span
              [ngStyle]="{
                color: '#343434',
                'font-weight': '500',
                'margin-top': '12px'
              }"
              maxLine
              [attr.title]="model.origin[i].user.name"
              >{{ model.origin[i].user?.name || '-' }}</span
            >
            <span class="d-flex" *ngIf="model.origin[i].delegateUser"
              >(<span
                [ngStyle]="{ color: '#343434' }"
                maxLine
                [attr.title]="model.origin[i].delegateUser.name"
                >{{ model.origin[i].delegateUser?.name }}</span
              >)</span
            >
            <span
              class="d-block"
              [ngStyle]="{ margin: '5px 0 5px', 'font-style': 'italic' }"
              maxLine
              [attr.title]="model.origin[i].position.name"
              >{{ model.origin[i].position?.name || '-' }}</span
            >
            <span maxLine [attr.title]="model.origin[i].organization.name">{{
              model.origin[i].organization?.name || '-'
            }}</span>
            <span
              class="d-block"
              [ngStyle]="{
                'margin-top': '7px',
                'font-size': '10px',
                'font-weight': '500',
                'line-height': '12px',
                border: '1px solid',
                'border-radius': '50px',
                padding: '3px 6px',
                color: '#FFFFFF',
                background: approvalPathStatus[model.origin[i].status].color
              }"
              >{{
                approvalPathStatus[model.origin[i].status].value | translate
              }}
            </span>
          </ng-container>
        </ng-template>
      </div>
    </li>
    <li
      class="text-center user-add"
      *ngIf="
        model.mode === 'WRITE' &&
        (!model.max || model.origin.length < model.max)
      "
    >
      <div
        title="{{
          model.moduleCode + '.approvalPath.title.choose' | translate
        }}"
      >
        <span
          class="button-add"
          (click)="doChooseApprover()"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        >
          <em class="fas fa-plus cursor-pointer"></em>
        </span>
        <span class="button-add-text">
          {{ model.moduleCode + '.approvalPath.button.choose' | translate }}
        </span>
      </div>
    </li>
  </ul>
</div>
