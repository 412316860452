import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartModel } from '../../../../../core/components/app-chart/models/chart-model';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetContractPerStatusResponse } from './model/widget-contract-per-status-response';
@Component({
  selector: 'dashboard-widget-contract-per-status',
  templateUrl: './widget-contract-per-status.component.html',
  //   styleUrls: ['./app-widget-contract-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetContractPerStatusComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-contract-per-status';
  public chartModel: ChartModel;
  constructor(
    public translateService: TranslateService
  ) {
    super(WidgetContractPerStatusComponent.moduleCode);
  }

  public onInit(): void {
    this.model.isLoading
    this.getAndSetContractInformation();
  }

  public getAndSetContractInformation(): void {
    this.httpClientService
      .get<WidgetContractPerStatusResponse>('/widget-contract-per-status/index').subscribe(
        (widgetContractPerStatusResponse: WidgetContractPerStatusResponse) => {
          if (widgetContractPerStatusResponse) {
            const labels = [];
            const dataSets = [];
            widgetContractPerStatusResponse.widgetContractPerStatusModelList.forEach(
              widgetContractPerStatusModel => {
                labels.push(
                  this.translateService.instant(
                    this.moduleCode + '.' +
                    widgetContractPerStatusModel.name
                      .toLowerCase()
                      .replace(/\s/g, '')
                      .trim()
                  )
                );
                dataSets.push(widgetContractPerStatusModel.total);
              }
            );
            this.chartModel = new ChartModel('pie', labels, dataSets, null, {
              defaultColor: ['#204d95 ', '#782095', '#3d9520', '#334d4d']
            });
          }
          this.setStateReady();
        }
      );
  }
}
