import {
  AfterViewChecked,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2
} from '@angular/core';
const floatingButtonSelector = '.floating-button';
@Directive({
  selector: floatingButtonSelector
})
export class FloatingButtonDirective implements AfterViewChecked {
  @Input() top: string;
  @Input() right: string;
  @Input() bottom: string;
  @Input() left: string;
  public floatingButtonElement: any;
  public dataTargetElement: any;
  public offsetLeft: any;
  public previousOffsetLeft: any;
  public dataTargetElementWidth: string;
  constructor(public elementRef: ElementRef, public renderer2: Renderer2) {}

  ngAfterViewChecked(): void {
    this.setElement();
    this.setOffsetLeft();
    this.setFloatingButton();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.setFloatingButton();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.setDataTargetElementWidth();
    this.renderer2.setStyle(
      this.dataTargetElement,
      'width',
      this.dataTargetElementWidth
    );
    this.setOffsetLeft();
    this.setFloatingButton();
  }

  public setElement(): void {
    this.floatingButtonElement = this.elementRef.nativeElement;
    this.setDataTargetElement();
    this.setDataTargetElementWidth();
  }

  public setDataTargetElement(): void {
    const dataTarget = this.floatingButtonElement.getAttribute('data-target');
    let dataTargetElement: any;
    if (dataTarget) {
      if (dataTarget[0] === '#') {
        dataTargetElement = document.getElementById(
          dataTarget.slice(1, dataTarget.length)
        );
      } else if (dataTarget[0] === '.') {
        dataTargetElement = document
          .getElementsByClassName(dataTarget.slice(1, dataTarget.length))
          .item(0);
      } else {
        dataTargetElement = document.getElementById(dataTarget);
      }
    } else {
      if (this.floatingButtonElement.children.length === 1) {
        dataTargetElement = this.floatingButtonElement.children.item(0);
      } else {
        const contentElement = document.createElement('div');
        contentElement.classList.add('floating-button-content');
        for (let i = 0; i < this.floatingButtonElement.children.length; i++) {
          contentElement.appendChild(
            this.floatingButtonElement.children.item(i)
          );
        }
        dataTargetElement = contentElement;
      }
    }
    this.dataTargetElement = dataTargetElement;
  }

  public setDataTargetElementWidth(): void {
    this.dataTargetElementWidth =
      this.floatingButtonElement.scrollWidth + 64 + 'px';
  }

  public pxToNumber(px: string): number {
    return +px.slice(0, px.indexOf('px'));
  }

  public setFixedElement(): void {
    this.renderer2.addClass(this.dataTargetElement, 'fixed');
    this.renderer2.setStyle(
      this.dataTargetElement,
      'width',
      this.dataTargetElementWidth
    );
    if (this.top) {
      this.renderer2.setStyle(this.dataTargetElement, 'top', this.top);
    }

    if (this.right) {
      this.renderer2.setStyle(this.dataTargetElement, 'right', this.left);
    }

    if (this.bottom) {
      this.renderer2.setStyle(this.dataTargetElement, 'bottom', this.bottom);
    }

    this.renderer2.setStyle(
      this.dataTargetElement,
      'left',
      (this.left || this.offsetLeft) - 32 + 'px'
    );
  }

  public removeFixedElement(): void {
    if (this.dataTargetElement.classList.contains('fixed')) {
      this.renderer2.removeClass(this.dataTargetElement, 'fixed');
      this.renderer2.removeStyle(this.dataTargetElement, 'left');
      this.renderer2.removeStyle(this.dataTargetElement, 'width');
    }
  }

  public setFloatingButton(): void {
    if (!this.isElementVisible()) {
      this.setFixedElement();
      this.previousOffsetLeft = this.offsetLeft;
    } else {
      this.removeFixedElement();
    }
  }

  public isElementVisible(): boolean {
    const boundingClientRect =
      this.floatingButtonElement.getBoundingClientRect();
    const viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );
    return !(
      boundingClientRect.bottom < 0 || boundingClientRect.top - viewHeight >= 0
    );
  }

  public setOffsetLeft(): void {
    const element = this.floatingButtonElement;
    const boundingClientRect = element.getBoundingClientRect();
    this.offsetLeft = boundingClientRect.left + window.scrollX;
  }
}
