<app-card
  header="work-plan-purchase-request.title"
  [isLoading]="formLoading"
  [isSaving]="formSaving"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div formArrayName="purchaseRequestModelList">
      <app-fieldset
        legend="{{
          'work-plan-purchase-request.fieldset.purchaseRequest' | translate
        }} {{ i + 1 }}"
        *ngFor="
          let purchaseRequestModel of formArrayPurchaseRequestModelList.controls;
          let i = index
        "
        [formGroup]="purchaseRequestModel"
      >
        <div [formGroup]="purchaseRequestModel.get('purchaseRequest')">
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'work-plan-purchase-request.form.number' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              <input
                class="form-control validation-field"
                type="text"
                (keyup)="checkPRNumber(i)"
                formControlName="number"
                maxlength="32"
                [ngClass]="{
                  'is-invalid':
                    submitted &&
                    purchaseRequestModel.get('purchaseRequest').get('number')
                      .errors
                }"
                aria-label="Number"
              />
              <small
                class="text-danger"
                *ngIf="
                  (submitted &&
                    purchaseRequestModel.get('purchaseRequest').get('number')
                      .errors) ||
                  (purchaseRequestModel.get('purchaseRequest').get('number')
                    .touched &&
                    purchaseRequestModel.get('purchaseRequest').get('number')
                      .errors)
                "
                >{{ 'app.validation.required' | translate }}</small
              >

              <small class="text-danger" *ngIf="!isPRNumberValidList[i]"
                >{{ samePRNumberList[i] }}
                {{
                  'work-plan-purchase-request.validation.used' | translate
                }}</small
              >
            </div>
          </div>

          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'work-plan-purchase-request.form.name' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              <input
                class="form-control validation-field"
                type="text"
                formControlName="name"
                maxlength="255"
                [ngClass]="{
                  'is-invalid':
                    submitted &&
                    purchaseRequestModel.controls['purchaseRequest'].controls[
                      'name'
                    ].errors
                }"
                aria-label="Name"
              />
              <small
                class="text-danger"
                *ngIf="
                  (submitted &&
                    purchaseRequestModel.controls['purchaseRequest'].controls[
                      'name'
                    ].errors) ||
                  (purchaseRequestModel.controls['purchaseRequest'].controls[
                    'name'
                  ].touched &&
                    purchaseRequestModel.controls['purchaseRequest'].controls[
                      'name'
                    ].errors)
                "
                >{{ 'app.validation.required' | translate }}
              </small>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'work-plan-purchase-request.form.organization' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              <label class="control-label">{{ organizationName }}</label>
            </div>
          </div>

          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'work-plan-purchase-request.form.date' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              <app-date-picker formControlName="createdDate"> </app-date-picker>
            </div>
          </div>

          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'work-plan-purchase-request.form.description' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              <textarea
                class="form-control"
                formControlName="description"
                maxlength="512"
                rows="4"
                [ngClass]="{
                  'is-invalid':
                    submitted &&
                    purchaseRequestModel.controls['purchaseRequest'].controls[
                      'description'
                    ].errors
                }"
                aria-label="Purchase Request"
              ></textarea>
              <small
                class="text-danger"
                *ngIf="
                  (submitted &&
                    purchaseRequestModel.controls['purchaseRequest'].controls[
                      'description'
                    ].errors) ||
                  (purchaseRequestModel.controls['purchaseRequest'].controls[
                    'description'
                  ].touched &&
                    purchaseRequestModel.controls['purchaseRequest'].controls[
                      'description'
                    ].errors)
                "
                >{{ 'app.validation.required' | translate }}</small
              >
            </div>
          </div>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'work-plan-purchase-request.form.uploadFile' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              <app-upload-multiple
                formControlName="file"
                [fileUploader]="fileUploaderList[i]"
                (onChange)="onChangeFileUpload()"
              >
              </app-upload-multiple>
              <small class="text-danger" *ngIf="submitted && !isAllUploaded"
                >{{ 'app.validation.required' | translate }}
              </small>
            </div>
          </div>
        </div>

        <div class="form-group row justify-content-center">
          <div class="col-md-11">
            <div class="card">
              <div class="card-header">
                <div
                  class="float-left control-label"
                  [ngStyle]="{ 'font-size': '17px' }"
                >
                  {{ 'work-plan-purchase-request.form.item' | translate }}
                </div>
              </div>
              <div class="card-body">
                <div
                  class="table-responsive custom-table mb-4 float-none"
                  [ngStyle]="{ margin: '0 auto' }"
                >
                  <table
                    class="table table-striped box-shadow"
                    aria-describedby="Table"
                  >
                    <thead>
                      <tr>
                        <th scope="col" class="text-sm-right">#</th>
                        <th scope="col" class="text-sm-right">{{
                          'work-plan-purchase-request.table.column.itemCode'
                            | translate
                        }}</th>
                        <th scope="col" class="text-sm-right">{{
                          'work-plan-purchase-request.table.column.itemName'
                            | translate
                        }}</th>
                        <th scope="col" class="text-sm-right">{{
                          'work-plan-purchase-request.table.column.uom'
                            | translate
                        }}</th>
                        <th scope="col" class="text-sm-right">{{
                          'work-plan-purchase-request.table.column.category'
                            | translate
                        }}</th>
                        <th scope="col" class="text-sm-right asterisk">{{
                          'work-plan-purchase-request.table.column.quantity'
                            | translate
                        }}</th>
                        <th scope="col" class="text-sm-right">{{
                          'work-plan-purchase-request.table.column.price'
                            | translate
                        }}</th>
                        <th scope="col" class="text-sm-right">{{
                          'work-plan-purchase-request.table.column.totalPrice'
                            | translate
                        }}</th>
                        <th scope="col" class="text-sm-right">{{
                          'work-plan-purchase-request.table.column.budgetNumber'
                            | translate
                        }}</th>
                      </tr>
                    </thead>
                    <tbody formArrayName="purchaseRequestItemList">
                      <tr
                        [formGroupName]="j"
                        *ngFor="
                          let purchaseRequestItem of purchaseRequestModel.get(
                            'purchaseRequestItemList'
                          ).controls;
                          let j = index
                        "
                      >
                        <td class="text-sm-right">{{ j + 1 }}.</td>
                        <td class="text-sm-right">
                          {{ workPlanItemList[j].item.code }}
                        </td>
                        <td>{{ workPlanItemList[j].item.name }}</td>
                        <td>{{ workPlanItemList[j].item.uom.name }}</td>
                        <td>{{ workPlanItemList[j].item.itemCategory.name }}</td>
                        <td class="text-sm-right">
                          <input
                            appDecimal
                            class="col-sm-4 px-2 text-right"
                            formControlName="quantity"
                            (keyup)="onQtyChange(purchaseRequestItem, j, i)"
                            aria-label="Quantity"
                          />
                          /{{ workPlanItemList[j].quantity }}
                          <div>
                            <small
                              class="text-danger"
                              *ngIf="!isQuantityValidList[j]"
                              >{{
                                'work-plan-purchase-request.msg.error.quantity'
                                  | translate
                              }}
                            </small>
                          </div>
                        </td>
                        <td class="text-sm-right">{{
                          workPlanItemList[j]?.unitPrice
                        }}</td>
                        <td class="text-sm-right">
                          {{ purchaseRequestItem.get('totalPrice')?.value }}
                        </td>
                        <td>{{ workPlanItemList[j].budget.number }}</td>
                      </tr>
                      <tr>
                        <td colspan="6"></td>
                        <td class="text-sm-right">
                          <strong>{{
                            'work-plan-purchase-request.table.column.totalPrice'
                              | translate
                          }}</strong>
                        </td>
                        <td class="text-sm-right">
                          <strong>{{
                            totalBudgetList[i] | currency: ' '
                          }}</strong>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="formGroup.get('purchaseRequestModelList').value.length > 1"
          class="row justify-content-center mt-5"
        >
          <div class="">
            <button
              type="button "
              class="btn btn-danger btn-block"
              (click)="doDeletePurchaseRequest(i)"
            >
              {{ 'app.button.delete' | translate }}
            </button>
          </div>
        </div>
      </app-fieldset>
      <div class="row justify-content-center mt-5">
        <span
          placement="top"
          ngbTooltip="{{
            !isAllQtyUsed
              ? ''
              : ('work-plan-purchase-request.tooltip.no-qty' | translate)
          }}"
        >
          <button
            class="btn btn-primary ml-2"
            [disabled]="isAllQtyUsed"
            type="button"
            (click)="doAddPurchaseRequest()"
          >
            <span class="fas fa-plus"></span>
            <span>
              {{
                'work-plan-purchase-request.button.addPurchaseRequest'
                  | translate
              }}</span
            >
          </button>
        </span>
      </div>
      <div class="row justify-content-center mt-5">
        <button type="button " class="btn btn-primary ml-2" (click)="doSave()">
          {{ 'app.button.save' | translate }}
        </button>
      </div>
    </div>
  </form>

  <div class="row justify-content-center mt-5">
    <button
      type="button "
      class="btn btn-secondary"
      (click)="doPrevious()"
      [disabled]="formSaving"
    >
      {{ 'app.button.previous' | translate }}
    </button>
    <button
      type="button "
      class="btn btn-primary ml-2"
      (click)="doNext()"
      [disabled]="!isHavePR"
    >
      {{ 'app.button.next' | translate }}
    </button>
  </div>
</app-card>
