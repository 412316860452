<app-card
  header="app-work-definition.card.title"
  tips="app-work-definition.card.tips"
>
  <div class="promise-loading" *ngIf="formLoading">
    <div class="loading"></div>
  </div>
  <app-sow
    [isView]="true"
    [sowList]="response.sowList"
    [prItemReleasedList]="response.prItemReleasedList"
    [amount]="response.amount"
    *ngIf="!formLoading"
  >
  </app-sow>
  <app-fines
    [isView]="true"
    [finesList]="response.finesList"
    [amount]="response.amount"
    *ngIf="!formLoading"
  ></app-fines>
  <app-guarantee
    [isView]="true"
    [guaranteeList]="response.guaranteeList"
    [amount]="response.amount"
    *ngIf="!formLoading"
  ></app-guarantee>
</app-card>
