<app-layout [isLoading]="formLoading" [backTo]="backToUrl">
  <div class="row main-row" id="refutationItemReview">
    <div class="col-lg-12 mb-4">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        *ngIf="!formLoading"
        novalidate
      >
        <div
          *ngIf="
            !formGroup.isView &&
            !formLoading &&
            procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm
                .PROCUREMENT_WORKLIST_STATUS_WAITING_RESPONSE
          "
        >
          <app-alert-icon color="INFO" icon="SANDGLASS">
            <div style="display: grid">
              <h2 class="text-info">{{
                'refutation.info.dueDateStillAvailable' | translate
              }}</h2>
              <span class="mt-3"
                >{{
                  ('refutation.info.dueDateStillAvailableDesc' | translate) +
                    ' ' +
                    (procurementSchedule?.endDate | date: 'dd/MM/YYYY HH:mm')
                }}
              </span>
            </div>
          </app-alert-icon>
        </div>
        <app-card
          header="{{
            ('refutation.refutationItem.general.title' | translate) +
              ' - ' +
              refutationBuyerByItemView.procurementItem.prItem.item.name
          }}"
          tips="refutation.refutationItem.general.tips"
        >
          <div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{ 'refutation.refutationItem.form.itemName' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                {{ refutationBuyerByItemView.procurementItem.prItem.item.name }}
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'refutation.refutationItem.form.itemCode' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{ refutationBuyerByItemView.procurementItem.prItem.item.code }}
              </div>
            </div>

            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'refutation.refutationItem.form.uom' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{
                  refutationBuyerByItemView.procurementItem.prItem.item.uom.name
                }}
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'refutation.refutationItem.form.quantity' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{
                  refutationBuyerByItemView.procurementItem.prItem.quantity
                    | number: '1.2-2'
                }}
              </div>
            </div>
            <h5 class="separator"></h5>
          </div>
          <app-table-xx
            [model]="tableResponse"
            *ngIf="!formLoading"
            [isShowSearch]="false"
          >
          </app-table-xx>
          <h4 class="separator">{{
            'refutation.vendorParticipant.general.title' | translate
          }}</h4>
          <app-table-xx
            [model]="tableResponseVendorParticipant"
            *ngIf="!formLoading"
            [isShowSearch]="false"
            [isShowPagination]="false"
            (onClick)="doShowVendorRefutation($event)"
          >
          </app-table-xx>
          <div
            *ngIf="
              !formLoading &&
              procurement.procurementWorklistStatus.code !==
                this.global.appConstant.pm
                  .PROCUREMENT_WORKLIST_STATUS_WAITING_RESPONSE
            "
          >
            <h4 class="separator">{{
              'refutation.refutationReview.general.title' | translate
            }}</h4>
            <app-fullfilled *ngIf="isMandatory"></app-fullfilled>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{ 'refutation.form.refutationStatus' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  formControlName="refutationReviewStatus"
                  [optionList]="refutationReviewStatusOptionList"
                  placeholder="refutation.placeholder.chooseStatus"
                >
                  <ng-template #value let-value>
                    <div
                      *ngIf="
                        value &&
                        value.code ===
                          global.appConstant.pm
                            .REFUTATION_REVIEW_STATUS_ACCEPTED
                      "
                    >
                      <em class="pic pi-thumbs-up-circle"></em>
                      <span style="color: 'var(--success)'">
                        {{ getTranslateKey(value) | translate }}
                      </span>
                    </div>

                    <div
                      *ngIf="
                        value &&
                        value.code ===
                          global.appConstant.pm
                            .REFUTATION_REVIEW_STATUS_NOT_ACCEPTED
                      "
                    >
                      <em class="pic pi-times-circle"></em>
                      <span style="color: 'var(--danger)'">
                        {{ getTranslateKey(value) | translate }}
                      </span>
                    </div>
                  </ng-template>
                  <ng-template #option let-data>
                    {{ getTranslateKey(data) | translate }}
                  </ng-template>
                </app-dropdown-select>
              </div>
            </div>

            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{ 'refutation.form.refutationDate' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-date-picker-x
                  formControlName="refutationDate"
                  [minDate]="today"
                ></app-date-picker-x>
              </div>
            </div>

            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{ 'refutation.form.notes' | translate }}
              </label>
              <div class="col-sm-6 text-left">
                <app-text-area formControlName="note" maxLength="512" size="XL">
                </app-text-area>
              </div>
            </div>
          </div>
        </app-card>
        <div class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div
                class="button-group button-group-center cmt-4"
                *ngIf="!formGroup.isView"
              >
                <app-button
                  color="SECONDARY"
                  [outline]="true"
                  (onClick)="doCancel()"
                  [disabled]="disabled"
                  >{{ 'app.button.cancel' | translate }}
                </app-button>
                <app-button (onClick)="doSave()" [disabled]="disabled">
                  {{ 'app.button.save' | translate }}
                </app-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips parent="#refutationItemReview"></app-tips>
  </ng-template>
</app-layout>
