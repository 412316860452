import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { CatalogAttribute } from '../../../core/bean/catalog-attribute';
import { CatalogBeans } from '../../../core/bean/catalog-beans';
import { CatalogSpecification } from '../../../core/bean/catalog-specification';
import { Response } from '../../../core/model/response-model';
import { ViewCatalogComparisonDTO } from './dto/view-catalog-comparison.dto';

@Component({
  templateUrl: 'view-catalog-comparison.component.html',
  styleUrls: ['./view-catalog-comparison.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ViewCatalogComparisonComponent extends BaseModuleComponent {
  public catalogBeansList: CatalogBeans[] = [];
  public catalogAttributeList: CatalogAttribute[] = [];

  constructor(translateService: TranslateService) {
    super('view-catalog', translateService);
  }

  public onInit(): void {
    this.getAndSetComparison();
  }

  public getAndSetComparison(): void {
    const responseComparison: Response<ViewCatalogComparisonDTO> = this.global.routerParams.get(
      'responseComparison'
    );
    this.catalogBeansList = responseComparison.body.catalogBeansList;
    this.catalogAttributeList = responseComparison.body.catalogAttributeList;
  }

  public doGetSpecification(
    catalogAttribute: CatalogAttribute,
    catalogSpesificationList: CatalogSpecification[]
  ): string {
    const cSpesificationList = catalogSpesificationList.filter(
      cSpesification =>
        cSpesification.catalogAttribute.id === catalogAttribute.id
    );

    if (cSpesificationList && cSpesificationList.length > 0) {
      return this.doParseValue(cSpesificationList[0].value);
    } else {
      return null;
    }
  }

  public doDelete(index: number): void {
    this.catalogBeansList.splice(index, 1);
  }

  public doParseValue(value: string): string {
    const parsedValue: any = JSON.parse(value);
    if (parsedValue) {
      if (Array.isArray(parsedValue)) {
        value = '';
        parsedValue.forEach((valueObject, i) => {
          value += valueObject.name;
          if (i !== parsedValue.length - 1) {
            value += ', ';
          }
        });
      } else if (typeof parsedValue === 'string') {
        value = parsedValue;
      } else {
        value = parsedValue.name;
      }
    } else {
      value = '-';
    }
    return value;
  }

  public doBack(): void {
    this.router.navigate(['/pages/view-catalog']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
