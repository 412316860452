import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UploaderHelpers } from '../helpers/upload-helpers';
import { UploaderFile } from '../model/uploader-file';
import { UploadService } from '../upload.service';
@Pipe({ name: 'resolveUploadSrcX' })
export class ResolveUploadSrcX implements PipeTransform {
  constructor(
    private uploadService: UploadService,
    private sanitizer: DomSanitizer
  ) {}
  transform(uploaderFile: UploaderFile): Promise<any> {
    return new Promise(resolve => {
      if (uploaderFile.file.isImage) {
        const imageSrc = UploaderHelpers.getThumbnailUrlWithFileUploader(
          this.uploadService.global,
          uploaderFile
        );
        this.uploadService.httpClientService
          .get(imageSrc, { responseType: 'blob' })
          .subscribe(
            response => {
              const reader = new FileReader();
              reader.onload = e =>
                resolve(
                  this.sanitizer.bypassSecurityTrustUrl(
                    e.target.result as string
                  )
                );
              reader.readAsDataURL(response);
            },
            () => {
              resolve('assets/img/app/no-image.png');
            }
          );
      } else {
        const myImage = new Image();
        myImage.src =
          '/assets/component/fileuploader/logo-icon/' +
          uploaderFile.file.extension.toLowerCase() +
          '.png';
        myImage.onload = () => {
          resolve(myImage.src);
        };
        myImage.onerror = () => {
          resolve('/assets/component/fileuploader/logo-icon/Default.png');
        };
      }
    });
  }
}
