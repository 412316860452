import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorRepresentativeAgentHistory } from '../../core/bean/vendor-representative-agent-history';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { AppPopupTabRepAgentComponent } from './app-popup-tab-rep-agent.component';
@Component({
  templateUrl: './tab-rep-agent.component.html'
})
export class TabRepAgentComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<VendorRepresentativeAgentHistory>;
  public tableResponse: TableResponseModel<VendorRepresentativeAgentHistory>;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('tab-rep-agent', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setCustomData();
    this.model.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: '0.companyName', header: 'table.column.companyName' },
      { field: '1', header: 'table.column.phone', customClass: 'text-center' },
      { field: '0.email', header: 'table.column.email' },
      {
        field: '0.address',
        header: 'table.column.address',
        customClass: 'white-space-normal'
      },
      {
        field: '0.information',
        header: 'table.column.information',
        customClass: 'white-space-normal'
      },
      {
        field: '0.companyStatus',
        header: 'table.column.companyStatus',
        datamap: '{"1" : "Holding" , "2" : "Not Holding" }',
        customClass: 'text-center'
      }
    ]);
  }

  public setCustomData(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponse.setCustomData(customData);
  }

  public doDetail(
    vendorRepresentativeAgentHistory: VendorRepresentativeAgentHistory
  ): void {
    this.appPopupService.open(
      AppPopupTabRepAgentComponent,
      { vendorRepresentativeAgentHistory: vendorRepresentativeAgentHistory[0] },
      { size: 'lg', backdrop: 'static' }
    );
  }
}
