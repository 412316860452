import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../../../directives/directives.module';
import { AppButtonModule } from '../../../app-button/app-button.module';
import { UploadSharedModule } from '../../shared/upload-shared.module';
import { AppUploadXComponent } from './app-upload-x.component';
@NgModule({
  imports: [UploadSharedModule, AppButtonModule, DirectivesModule],
  declarations: [AppUploadXComponent],
  exports: [AppUploadXComponent]
})
export class AppUploadXModule {}
