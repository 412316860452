import { TranslationKey } from '../../core/bean/translation-key';
import { Translation } from '../../core/bean/translation';
import { ListOfValueModel } from '../../core/model/list-of-value-model';
import { CatalogAttribute } from '../../core/bean/catalog-attribute';

export class CatalogAttributeRequest {

    translationKey: TranslationKey;
    translationValueList: Translation[];
    catalogAttribute: CatalogAttribute;
    listOfValueModel: ListOfValueModel[];
}