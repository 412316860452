<div class="row">
  <div class="col-12">
    <app-card header="catalog-attribute-group.title"
      detail="app.action.{{todo}}">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'catalog-attribute-group.form.upload' | translate }}</label>
          <div class="col-sm-9 col-lg-7 text-left">
            <app-fieldset>
              <app-text-field autofocus="true" formControlName="file"
                type="alphabet"></app-text-field>
            </app-fieldset>
          </div>
        </div>
        <div class="col-12 text-center">
          <app-button color="SECONDARY" (onClick)="doCancel()" class="mr-1">{{
            'app.button.back' | translate }}</app-button>
          <!-- method do submit di bawah tidak ada di .ts: (click)="doSubmit()"-->
          <app-button>{{ 'app.button.save' | translate }}</app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>