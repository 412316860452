import { BaseEntity } from './base-entity';
export class BaseTreeEntity extends BaseEntity {
  code: string;
  name: string;
  parentId: number;
  sequence: number;
  tempId: number;
  parentTempId: number;
  level: number;
}
