import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppButtonModule } from './../../app-button/app-button.module';
import { AppPopupCoaBjbComponent } from './app-popup-coa-bjb.component';
import { AppPopupCoaBjbService } from './app-popup-coa-bjb.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppTableModule, AppButtonModule],
  declarations: [AppPopupCoaBjbComponent],
  entryComponents: [AppPopupCoaBjbComponent],
  providers: [AppPopupCoaBjbService],
  exports: [AppPopupCoaBjbComponent]
})
export class AppPopupCoaBjbModule {}
