<app-popup header="tab-company-organization.popup.shareHolder.header" [isLoading]="formLoading">
  <form class="form-horizontal">
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-organization.form.popup.name' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorShareHolder.name || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-organization.form.popup.idNumber' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorShareHolder.idNumber || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-organization.form.popup.type' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorShareHolder.shareholderType?.name || '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-organization.form.popup.establishDate' | translate
        }}</label>
      <div class="text-left" [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'">
        {{
        (vendorShareHolder.birthDate
        | date: global.config.parameterModel.dateFormatShort) || '-'
        }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-company-organization.form.popup.shareValue' | translate
        }}</label>
      <div class="text-left" [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'">
        {{
        vendorShareHolder.shareValue === null
        ? '-'
        : vendorShareHolder.shareValue + ' %'
        }}
      </div>
    </div>
    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
          }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>