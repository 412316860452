import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAlertComponent } from './app-alert.component';
@NgModule({
  imports: [VendorModule],
  declarations: [AppAlertComponent],
  entryComponents: [AppAlertComponent],
  exports: [AppAlertComponent]
})
export class AppAlertModule {}
