import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { VendorTab } from '../../core/bean/vendor-tab';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { ReportService } from '../../core/services/report.service';
@Component({
  templateUrl: './vendor-history-detail-popup-print.component.html'
})
export class VendorHistoryDetailPopupPrintComponent extends BaseComponentComponent {
  @Input() accordionItemList: Array<AccordionItemModel<VendorTab>>;
  @Input() vendorHistoryId: number;
  @Input() versionName: string;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  public tableResponse: TableResponseModel<AccordionItemModel<VendorTab>>;
  public accordionItemListTemp: Array<AccordionItemModel<VendorTab>> =
    new Array();
  public isPrint = false;

  constructor(public reportService: ReportService) {
    super('vendor-history');
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.doSetTableResponse();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'header', header: 'table.column.listHistory' }
    ]);
  }

  public doSetTableResponse(): void {
    this.tableResponse.page.records = this.accordionItemList;
    this.tableResponse.page.totalRecords = this.accordionItemList.length;
  }

  public doCheckAll(event: any): void {
    if (event.target.checked) {
      this.accordionItemListTemp.splice(0);
      this.accordionItemListTemp.push(...this.accordionItemList);
    } else {
      this.accordionItemListTemp.splice(0);
    }
  }

  public doCheck(accordionItem: AccordionItemModel<VendorTab>): void {
    const indexOfAccordionItem: number =
      this.accordionItemListTemp.indexOf(accordionItem);
    indexOfAccordionItem === -1
      ? this.accordionItemListTemp.push(accordionItem)
      : this.accordionItemListTemp.splice(indexOfAccordionItem, 1);
  }

  public doPrint(): void {
    this.isPrint = true;
    if (this.accordionItemListTemp.length > 0) {
      this.loadingBlockService.showInfo('Your data is being prepared...');
      const vendorTabList = this.accordionItemListTemp.map(
        (accordionItem: AccordionItemModel<VendorTab>) =>
          accordionItem.origin.module.code
      );
      const moduleCodeList = vendorTabList.toString();
      this.reportService
        .print('/vendor-history/print', {
          moduleCodeList,
          vendorHistoryId: this.vendorHistoryId,
          versionName: this.versionName
        })
        .subscribe(() => {
          this.loadingBlockService.showCompleteAndClose(
            'Your data has been successfully imported.'
          );
        });
    } else {
      this.global.alertService.showError(
        'app.validation.isChecked',
        '.vendor-history-detail-popup-print'
      );
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
