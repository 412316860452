import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { BlacklistCriteria } from '../../core/bean/blacklist-criteria';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  selector: 'app-popup-user',
  templateUrl: './app-popup-blacklist-criteria.component.html'
})
export class AppPopupBlacklistCriteriaComponent extends BaseComponentComponent {
  @Input() public blacklistCriteriaIdList: number[];
  @Input() public blacklistCriteriaIdCustomData: any;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public tableResponse: TableResponseModel<BlacklistCriteria>;
  public dataRecord: BlacklistCriteria[];

  constructor() {
    super('app-popup-blacklist-criteria');
  }

  public onInit(): void {
    this.setBlacklistCriteriaIdCustomData();
    this.buildTableResponse();
  }

  public setBlacklistCriteriaIdCustomData(): void {
    this.blacklistCriteriaIdCustomData = JSON.stringify(
      this.blacklistCriteriaIdList
    );
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'violation', header: 'table.column.violation' },
      {
        field: 'durationInMonth',
        header: 'table.column.recomendedDuration',
        customClass: 'text-center',
        fn: this.global.converterService.convertMonthToYears
      },
      {
        field: 'id',
        header: 'table.column.select',
        format: FieldFormatEnum.CheckBox,
        sortable: false
      }
    ]);
  }

  public onChangeTableRecords(records: any): void {
    this.dataRecord = records;
  }

  public doSubmit(): void {
    if (this.dataRecord && this.dataRecord.length !== 0) {
      this.onChange.emit(this.dataRecord);
    } else {
      this.global.alertService.showError(
        this.global.translateService.instant('app.validation.chooseOneData'),
        '.main-row-popup-blacklist-criteria'
      );
    }
  }
}
