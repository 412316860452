import { Component, ViewEncapsulation } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Organization } from '../../core/bean/organization';
import { WorkPlan } from '../../core/bean/work-plan';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { ApprovalPathModel } from '../../core/components/app-approval-path';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { OfficialMemoSubmission } from './../../core/bean/official-memo-submission';
import { WorkflowPrcs } from './../../core/bean/workflow-prcs';
import { OfficialMemoSubmissionRequest } from './official-memo-submission-request';
import { OfficialMemoSubmissionResponse } from './official-memo-submission-response';
@Component({
  templateUrl: './official-memo-submission-edit-add.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./official-memo-submission-edit-add.scss']
})
export class OfficialMemoSubmissionEditAddComponent extends BaseModuleComponent {
  public isAllUploaded = false;
  public officialMemoSubmission: OfficialMemoSubmission =
    new OfficialMemoSubmission();
  public workPlan: WorkPlan = new WorkPlan();
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public officialMemoSubmissionResponse: OfficialMemoSubmissionResponse =
    new OfficialMemoSubmissionResponse();
  public officialMemoSubmissionRequest: OfficialMemoSubmissionRequest =
    new OfficialMemoSubmissionRequest();
  public approvalPath: ApprovalPathModel<any> = new ApprovalPathModel(
    this.moduleCode,
    'READ'
  );
  public fileUploader: FileUploader = new FileUploader(
    '/official-memo-submission/',
    '',
    this.global.appConstant.fileType.DOC_OFFICIAL_MEMO,
    false,
    5
  );

  public url: string;
  public officialMemoSubmissionId: number;
  public id: number;
  public objectId: number;
  public workPlanId: number;
  public workflowModelPrcsId: number;
  public workflowPrcs: WorkflowPrcs;
  public submitted: boolean;
  public isValidOfficer = false;

  constructor(translateService: TranslateService) {
    super('official-memo-submission', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.objectId = this.global.routerParams.get('objectId');
    this.workPlan = this.global.routerParams.get('workPlan');
    this.workPlanId = this.objectId;
    if (this.workPlan != null) {
      this.workPlanId = this.workPlan.id;
      this.workflowPrcs = this.workPlan.workflowPrcs;
      this.workflowModelPrcsId = this.workPlan.workflowModelPrcs.id;
      this.workflowModelPrcs = this.workPlan.workflowModelPrcs;
    }
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      number: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      today: [new Date()],
      createdDate: [null, Validators.required()],
      organization: [null, Validators.required()],
      subject: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(128)])
      ],
      description: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(512)])
      ],
      uploadFile: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<OfficialMemoSubmissionResponse>(
        '/official-memo-submission/add-edit',
        new RouteRequestModel(this.todo, this.workPlanId)
      )
      .subscribe(officialMemoSubmissionResponse => {
        this.officialMemoSubmissionResponse = officialMemoSubmissionResponse;
        if (this.objectId != null) {
          this.workPlan = this.officialMemoSubmissionResponse.workPlan;
          this.workflowPrcs = this.workPlan.workflowPrcs;
          this.workflowModelPrcsId = this.workPlan.workflowModelPrcs.id;
          this.workflowModelPrcs = this.workPlan.workflowModelPrcs;
        }
        this.formGroup.patchValue({
          organization: this.officialMemoSubmissionResponse.organizationList
        });
        if (officialMemoSubmissionResponse.officialMemo != null) {
          this.todo = this.todo !== 'view' ? 'edit' : 'view';
          if (
            officialMemoSubmissionResponse.approvalPathResponseModel != null
          ) {
            this.approvalPath
              .setApproverList(
                officialMemoSubmissionResponse.approvalPathResponseModel
                  .userApproverList
              )
              .setType(
                officialMemoSubmissionResponse.approvalPathResponseModel.type
              );
          }
          const createdDate: Date = new Date(
            officialMemoSubmissionResponse.officialMemo.createdDate
          );
          this.fileUploader.setFileList(
            officialMemoSubmissionResponse.fileList
          );
          this.formGroup.patchValue({
            id: officialMemoSubmissionResponse.officialMemo.id,
            number: officialMemoSubmissionResponse.officialMemo.number,
            createdDate,
            subject: officialMemoSubmissionResponse.officialMemo.subject,
            description:
              officialMemoSubmissionResponse.officialMemo.description,
            uploadFile: this.fileUploader.fileObjectList
          });
          // this.doCheckOfficer();
        } else if (
          officialMemoSubmissionResponse.officialMemo === null &&
          this.todo !== 'add'
        ) {
          this.global.routerParams.clear();
          this.global.routerParams.set('todo', 'add');
          this.global.routerParams.set('workPlan', this.workPlan);
          this.router.navigate(['/pages/official-memo-submission/add']);
        }
        this.setStateReady();
      });
    // tslint:disable-next-line:align
    this.formGroup.get('organization').setIsView(true);
  }

  public doSave(): void {
    if (this.officialMemoSubmissionResponse.isNumberGenerator) {
      this.formGroup.get('number').clearValidators();
      this.formGroup.get('number').updateValueAndValidity();
    }
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.officialMemoSubmission = this.formGroup.value;
            const organizationList: Organization[] =
              this.formGroup.get('organization').value;
            this.officialMemoSubmission.organization =
              organizationList[organizationList.length - 1];
            this.officialMemoSubmission.workPlan = this.workPlan;
            this.officialMemoSubmission.workPlan.workflowModelPrcs =
              this.workPlan.workflowModelPrcs;
            this.officialMemoSubmissionRequest.workPlan =
              this.officialMemoSubmissionResponse.workPlan;
            this.officialMemoSubmissionRequest.fileObjectList =
              this.fileUploader.fileObjectList;
            this.officialMemoSubmission = this.global.copyFormAttributeToModel(
              this.officialMemoSubmission,
              this.formGroup
            );
            this.officialMemoSubmissionRequest.officialMemo =
              this.officialMemoSubmission;
            if (this.officialMemoSubmission.id !== null) {
              this.url = '/official-memo-submission/update';
            } else {
              this.url = '/official-memo-submission/insert';
            }
            this.httpClientService
              .post<Response<OfficialMemoSubmissionResponse>>(
                this.url,
                this.officialMemoSubmissionRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/worklist-pra-procurement']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public onChangeFileUpload(fileUploader: FileUploader): void {
    let isAllUploaded = true;
    if (!fileUploader.isAllUploaded) {
      isAllUploaded = fileUploader.isAllUploaded;
    }
    this.isAllUploaded = isAllUploaded;
  }

  public doBack(): void {
    this.router.navigate(['/pages/worklist-pra-procurement']);
  }
}
