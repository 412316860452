<div class="row main-row m-0 p-0">
  <div class="col-lg-12 mb-4 m-0 p-0">
    <app-card header="document-group.title" detail="app.action.{{ todo }}"
      [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'document-group.form.code' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field autofocus="true" size="SM" formControlName="code"
              type="code"
              tooltip="{{ 'document-group.tooltip.code' | translate }}"
              maxLength="32"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right mb-0">
          <label class="col-sm-3 control-label text-right">{{
            'document-group.form.name' | translate }}</label>
          <div class="col-sm-9 text-left">
            <ng-container formArrayName="translationList">
              <div class="form-group row col-12 p-0"
                *ngFor="let translation of translationList.controls; let i = index"
                [formGroupName]="i">
                <div class="form-group row col-5 text-sm-right required">
                  <label class="col-sm-2 text-left control-label">{{
                    translationListLabel[i] }}</label>
                  <div class="col-sm-9 text-left">
                    <app-text-field size="XL" formControlName="value"
                      maxLength="64"></app-text-field>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'document-group.form.description' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxLength="512">
            </app-text-area>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button color="SECONDARY" class="mr-1" (onClick)="doCancel()">
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button color="PRIMARY" (onClick)="doSave()">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>