import { DrDetail } from '../../bean/dr-detail';
import { PaymentBillingItem } from '../../bean/payment-billing-item';
import { PaymentTerm } from '../../bean/payment-term';
import { StageOfWork } from '../../bean/stage-of-work';

export class AppDeliverableItemResponse {
  paymentBillingItemGoodsList: PaymentBillingItem[];
  paymentBillingItemServiceList: PaymentBillingItem[];
  drDetailGoodsList: DrDetail[];
  drDetailServiceList: DrDetail[];
  stageOfWork: StageOfWork;
  paymentTerm: PaymentTerm;
  totalProductCost: number;
  totalServiceCost: number;
}
