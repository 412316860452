import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { Contract } from '../../../bean/contract';
import { FieldFormatEnum } from '../../app-table/model/field-format.enum';
import { TableResponseModel } from '../../app-table/model/table-response-model';
@Component({
  templateUrl: './app-popup-contract.component.html'
})
export class AppPopupContractComponent extends BaseComponentComponent {
  @Input() contractIdList: number[];
  @Output() onChange: EventEmitter<Contract> = new EventEmitter();
  contractCustomData: any;

  tableResponse: TableResponseModel<Contract>;
  vendorId: number;

  constructor() {
    super('app-popup-contract');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setCustomData();
    this.buildTableResponse();
  }

  buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorId: [null],
      contractIdList: [null]
    });
  }

  setCustomData(): void {
    this.formGroup.patchValue({
      vendorId: this.global.routerParams.get('vendorId'),
      contractIdList: this.contractIdList
    });

    this.contractCustomData = JSON.stringify(this.formGroup.value);
  }

  buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        format: FieldFormatEnum.ShortDate
      },
      { field: 'number', header: 'table.column.number' },
      { field: 'stage', header: 'table.column.stage' }
    ]);
  }

  doChoose(contract: Contract): void {
    this.onChange.emit(contract);
  }
}
