import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { PaginationModel } from '../app-pagination/model/pagination-model';

@Component({
  selector: 'app-pagination-x',
  templateUrl: './app-pagination-x.component.html',
  styleUrls: ['./app-pagination-x.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPaginationXComponent extends BaseComponentComponent {
  @Input() model: PaginationModel;
  @Output() onChange: EventEmitter<number> = new EventEmitter();

  @ContentChild('contentLeft') contentLeftTemplate: TemplateRef<any>;

  constructor() {
    super('app-pagination');
  }

  public onInit(): void {
    this.setStateReady();
  }

  public onPageChanges(page: number): void {
    this.model.setCurrentPage(page);
    this.onChange.emit(page);
  }
}
