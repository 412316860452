import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Bank } from 'src/app/core/bean/bank';
import { Currency } from 'src/app/core/bean/currency';
import { Region } from 'src/app/core/bean/region';
import { VendorBank } from 'src/app/core/bean/vendor-bank';
import { VendorBankHistory } from 'src/app/core/bean/vendor-bank-history';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';
import { VendorPopupVendorBankDTO } from '../dto/vendor-popup-vendor-bank.dto';
import { AppPopupVendorBankAlertComponent } from './app-popup-vendor-bank-alert.component';

@Component({
  templateUrl: './app-popup-vendor-bank.component.html'
})
export class AppPopupVendorBankComponent extends BaseComponentComponent {
  @Input() public vendorBankHistoryEdit: VendorBankHistory;
  @Input() public vendorBank: VendorBank;
  @Input() public todo: string;
  @Input() public bankId: number;
  @Input() public defaultBank: Bank;
  @Input() public vendorBankHistoryList: VendorBankHistory[];
  @Input() public vendorBankList: VendorBank[];
  @Input() public accountNumberList = [];
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public vendorPopupVendorBankDTO: VendorPopupVendorBankDTO =
    new VendorPopupVendorBankDTO();
  public countryRegionOptionList: OptionListModel<Region> = new OptionListModel(
    true
  );
  public cityRegionOptionList: OptionListModel<Region> = new OptionListModel(
    true
  );
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true,
    'code'
  );
  public cityList: Region[] = [];
  public isPriorityExist: boolean;
  public isPriority: string;
  public isPriorityHistory: string;
  public isAccountNumberExist: boolean;
  public isAccountNumberExists: string;
  public isVendorList: boolean;
  public fromOutside: string;
  public countryRegionId: Object;

  constructor(
    public appPopupService: AppPopupService,
    public activeModal: NgbActiveModal
  ) {
    super('');
  }

  public onInit(): void {
    this.setParam();
    this.getVendorBank();
    this.buildFormGroup();
    this.setFormGroup();
    if (this.todo === 'detail') {
      this.setViewOnly();
    }
  }

  public setParam(): void {
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      accountHolder: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      accountNumber: [
        null,
        {
          asyncValidators: [
            Validators.existsAsync(
              '/tab-profile-bank/check-vendor-bank-exist',
              null,
              null,
              { id: this.bankId }
            )
          ],
          validators: [
            Validators.compose([
              Validators.required(),
              Validators.maxLength(32),
              Validators.exists(this.accountNumberList)
            ])
          ]
        }
      ],
      address: [null],
      bank: [null],
      bankName: [null, Validators.required()],
      branch: [null, Validators.maxLength(32)],
      cityRegion: [null],
      countryRegion: [null],
      coordinateLocation: [null],
      currency: [null, Validators.required()],
      indexBank: [null],
      isAgree: [null],
      isPriority: [null],
      vendorBankId: [null]
    });
  }

  public setFormGroup(): void {
    // tslint:disable-next-line: max-line-length
    this.httpClientService
      .post<VendorPopupVendorBankDTO>(
        '/tab-profile-bank/add-edit-popup-vendor-bank',
        null
      )
      .subscribe(vendorPopupVendorBankDTO => {
        this.vendorPopupVendorBankDTO = vendorPopupVendorBankDTO;
        this.setOptionListModel();
        if (this.vendorBankHistoryEdit !== undefined) {
          this.countryRegionId = {
            countryRegionId: this.vendorBankHistoryEdit.countryRegion
              ? this.vendorBankHistoryEdit.countryRegion.id
              : 0
          };
          this.formGroup.patchValue({
            bank: this.vendorBankHistoryEdit.bank,
            bankName: this.vendorBankHistoryEdit.bank.name,
            branch: this.vendorBankHistoryEdit.branch,
            address: this.vendorBankHistoryEdit.address,
            countryRegion: this.vendorBankHistoryEdit.countryRegion,
            cityRegion: this.vendorBankHistoryEdit.cityRegion,
            accountNumber: this.vendorBankHistoryEdit.accountNumber,
            accountHolder: this.vendorBankHistoryEdit.accountHolder,
            currency: this.vendorBankHistoryEdit.currency,
            isPriority: this.vendorBankHistoryEdit.isPriority,
            coordinateLocation: this.vendorBankHistoryEdit.coordinateLocation,
            vendorBankId: this.vendorBankHistoryEdit.vendorBankId
          });
        }
        this.setStateReady();
      });
  }

  public setOptionListModel(): void {
    
    const regionList = this.vendorPopupVendorBankDTO.countryRegionList;
        const idReg = regionList.find(region => region.code === this.global.appConstant.core.REGION_CODE_INDONESIA);
        const indexIdReg = regionList.findIndex(region => region.code === this.global.appConstant.core.REGION_CODE_INDONESIA);

        if (indexIdReg !== -1) {
          regionList.splice(indexIdReg, 1);
          regionList.unshift(idReg);
        }

    this.countryRegionOptionList.setRequestValues(regionList);
    this.cityRegionOptionList.setRequestValues(this.cityList);
    this.cityRegionOptionList.setSortBy('name');
    this.currencyOptionList.setRequestValues(
      this.vendorPopupVendorBankDTO.currencyList
    );
  }

  public getVendorBank(): void {
    if (this.vendorBankList) {
      this.vendorBankHistoryList.forEach(bankHistory => {
        if (bankHistory === this.vendorBankHistoryEdit) {
          this.vendorBankList.forEach(bank => {
            if (bank.id === bankHistory.vendorBankId) {
              this.vendorBank = bank;
              this.isPriority = this.vendorBank.isPriority ? 'Yes' : 'No';
            }
          });
        }
      });
      if (this.vendorBank === undefined) {
        this.vendorBank = new VendorBank();
      }
    }
    if (this.vendorBankHistoryEdit !== undefined) {
      this.isPriorityHistory = this.vendorBankHistoryEdit.isPriority
        ? 'Yes'
        : 'No';
    }
  }

  public doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      if (this.isPriorityExist) {
        this.appPopupService
          .open(AppPopupVendorBankAlertComponent, {
            size: 'lg',
            backdrop: 'static'
          })
          .subscribe(agree => {
            this.formGroup.patchValue({ isAgree: agree });
            this.onChange.emit(this.formGroup);
            this.activeModal.dismiss();
          });
      } else {
        this.onChange.emit(this.formGroup);
        this.activeModal.dismiss();
      }
    }
  }

  public autoCompleChanged(bank: Bank): void {
    this.formGroup.patchValue({
      bank
    });
  }

  public doChangeCountryRegion(): void {
    this.formGroup.patchValue({
      cityRegion: null
    });
    this.countryRegionId = {
      countryRegionId: this.formGroup.value.countryRegion.id
    };
  }

  public onChangePriority(): void {
    if (this.formGroup.value.isPriority === true) {
      this.vendorBankHistoryList.forEach((bank, index) => {
        if (bank.isPriority === true) {
          this.isPriorityExist = true;
          this.formGroup.patchValue({ indexBank: index });
        }
      });
    } else {
      this.isPriorityExist = false;
    }
  }
}
