import { HttpClientService } from '../../../services/http-client.service';
import { ArrayUtils } from '../../../utils';
import { TablePageModel } from '../../app-table/model/table-page-model';
import { TableRow } from '../domain/table-row';
import { TableState } from '../domain/table-state';
import { createTableCustomData } from './create-table-custom-data-helper';
export const resolveTableRowChildrenList = (
  state: TableState,
  row: TableRow
): Promise<{ rowList: TableRow[]; totalRecord: number }> => {
  return new Promise(resolve => {
    if (state.model.options.group.childListField) {
      const chilrenRecordList: Array<any> =
        ArrayUtils.resolveFieldData(
          row.record,
          state.model.options.group.childListField
        ) || [];

      resolve({
        rowList: chilrenRecordList.map(
          childrenRecord => new TableRow(childrenRecord)
        ),
        totalRecord: ArrayUtils.resolveFieldData(
          row.record,
          state.model.options.group.childListField
        ).length
      });
    } else {
      row.tableRequest.customData = createTableCustomData({
        id: row.record.id
      });
      const httpClientService = state.injector.get(HttpClientService);
      httpClientService
        .post(state.stringUrl + '/child', row.tableRequest)
        .subscribe((page: TablePageModel<object>) => {
          row.isShowButtonLoadMore =
            row.childrenList.length < page.totalRecords;
          resolve({
            rowList: page.records.map(pageRecord => new TableRow(pageRecord)),
            totalRecord: page.totalRecords
          });
        });
    }
  });
};
