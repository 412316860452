import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ContractActivity } from '../../bean/contract-activity';
import { PaymentActivity } from '../../bean/payment-activity';
import { FieldFormatEnum } from '../app-table/model/field-format.enum';
import { TableResponseModel } from '../app-table/model/table-response-model';

@Component({
  selector: 'app-activity-history-view',
  templateUrl: './app-activity-history-view.component.html'
})
export class AppActivityHistoryViewComponent extends BaseComponentComponent {
  @Input() public paymentId: number;
  @Input() public contractId: number;
  @Input() public activityType: number;

  public tableResponsePayment: TableResponseModel<PaymentActivity>;
  public tableResponseContract: TableResponseModel<ContractActivity>;
  public ACTIVITY_TYPE_CONTRACT = 1;
  public ACTIVITY_TYPE_PAYMENT = 2;

  constructor() {
    super('app-activity-history-view');
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setCustomData();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    if (this.activityType === this.ACTIVITY_TYPE_CONTRACT) {
      this.tableResponseContract = new TableResponseModel(this.moduleCode, [
        {
          field: 'activity.name',
          header: 'table.column.activity'
        },
        {
          field: 'createdByUser.name',
          header: 'table.column.pic'
        },
        {
          field: 'date',
          header: 'table.column.date',
          format: FieldFormatEnum.ShortDate
        }
      ]);
    } else if (this.activityType === this.ACTIVITY_TYPE_PAYMENT) {
      this.tableResponsePayment = new TableResponseModel(this.moduleCode, [
        {
          field: 'activity.name',
          header: 'table.column.activity'
        },
        {
          field: 'createdByUser.name',
          header: 'table.column.pic'
        },
        {
          field: 'activityDate',
          header: 'table.column.date',
          format: FieldFormatEnum.ShortDate
        }
      ]);
    }
  }

  public setCustomData(): void {
    if (this.activityType === this.ACTIVITY_TYPE_CONTRACT) {
      this.tableResponseContract.setCustomData(this.contractId);
      this.tableResponseContract.sortOrder = 'DESC';
      this.tableResponseContract.setSortField('date');
    } else if (this.activityType === this.ACTIVITY_TYPE_PAYMENT) {
      this.tableResponsePayment.setCustomData(this.paymentId);
    }
  }
}
