/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */
export class UpoadFileResponseModel {
  uploadedFileName: string;
  fileName: string;
  fileSize: number;
  status: number;
  errorMsg: string;
}
