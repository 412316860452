import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupTabPerformanceHistoryDetailComponent } from './app-popup-tab-performance-history-detail.component';
import { TabPerformanceHistoryComponent } from './tab-performance-history.component';
@NgModule({
  imports: [SharedModule],
  declarations: [
    TabPerformanceHistoryComponent,
    AppPopupTabPerformanceHistoryDetailComponent
  ],
  entryComponents: [
    TabPerformanceHistoryComponent,
    AppPopupTabPerformanceHistoryDetailComponent
  ],
  exports: [
    TabPerformanceHistoryComponent,
    AppPopupTabPerformanceHistoryDetailComponent
  ]
})
export class TabPerformanceHistoryModule {
  constructor(accordionService: AccordionService) {
    accordionService.register(
      'tab-performance-history',
      TabPerformanceHistoryComponent
    );
  }
}
