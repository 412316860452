import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { PrItem } from '../../../bean/pr-item';
import { OptionListModel } from '../../../model/option-list-model';
import { TablePluginData } from '../../table/interface/table-plugin-data';
import { TableResponseModel } from '../../table/model/table-response-model';

@Component({
  templateUrl: './app-popup-choose-pr.component.html',
  styleUrls: ['./app-popup-choose-pr.scss']
})
export class AppPopupChoosePrComponent extends BaseModuleComponent {
  @Input() isMultiple: boolean;
  @Input() prItems: PrItem[] = [];
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() baseModuleCode: String;
  public CURRENCY_DIGITS_INFO: string;
  public tableResponse: TableResponseModel<PrItem>;
  public groupByMode: any;
  public groupByOptionList: OptionListModel<any> = new OptionListModel(false);

  constructor(
    translateService: TranslateService,
    public currency: CurrencyPipe
  ) {
    super('app-popup-choose-pr', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.setGroupByMode();
    this.buildTableResponse();
    this.setPrItem();
    this.setStateReady();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      groupBy: [null]
    });
  }

  public setGroupByMode(): void {
    if (!this.groupByMode) {
      this.groupByMode = 'prNumber';
    } else {
      this.groupByMode = this.formGroup.get('groupBy').value
        ? this.formGroup.get('groupBy').value.code
        : 'prNumber';
    }
  }

  public setFormGroup(): void {
    const column = [
      {
        code: 'prNumber',
        name: this.translateService.instant(
          'app-popup-choose-pr.table.column.prNumber'
        )
      },
      {
        code: 'itemCategory',
        name: this.translateService.instant(
          'app-popup-choose-pr.table.column.itemCategory'
        )
      },
      {
        code: 'prDate',
        name: this.translateService.instant(
          'app-popup-choose-pr.table.column.prDate'
        )
      },
      {
        code: 'department',
        name: this.translateService.instant(
          'app-popup-choose-pr.table.column.departement'
        )
      }
    ];
    this.groupByOptionList.setRequestValues(column);
  }

  public buildTableResponse(): void {
    if (this.groupByMode === 'prNumber') {
      !this.tableResponse
        ? (this.tableResponse = new TableResponseModel(
            this.moduleCode,
            [
              {
                field: 'pr.code',
                header: 'table.column.prNumber',
                className: 'text-center',
                plugins: 'hyperlink'
              },
              {
                field: 'code',
                header: 'table.column.prLine',
                className: 'text-center'
              },
              {
                field: 'pr.requiredDate',
                header: 'table.column.prDate',
                className: 'text-center',
                plugins: 'date'
              },
              {
                field: 'pr.organization.name',
                header: 'table.column.departement',
                className: 'text-center'
              },
              {
                field: 'item.name',
                header: 'table.column.itemName'
              },
              {
                field: 'item.itemCategory.name',
                header: 'table.column.itemCategory'
              },
              {
                field: 'item.itemType.name',
                header: 'table.column.type',
                plugins: {
                  name: 'badge',
                  pill: true,
                  colorMap: {
                    MATERIAL: 'GOOD',
                    JASA: 'SERVICE'
                  },
                  colorField: 'item.itemType.code'
                }
              },
              {
                field: 'quantity',
                header: 'table.column.quantity',
                className: 'text-center',
                plugins: {
                  name: 'custom-plugin',
                  before: (tablePlugin: TablePluginData): string => {
                    return this.global.converterService.convertDecimal(
                      tablePlugin.value
                    );
                  }
                }
              },
              {
                field: 'item.uom.name',
                header: 'table.column.uom'
              },
              {
                field: 'price',
                header: 'table.column.price',
                plugins: {
                  name: 'default',
                  type: 'currency'
                }
              },
              {
                field: 'totalPrice',
                header: 'table.column.totalPrice',
                plugins: {
                  name: 'default',
                  type: 'currency'
                }
              }
            ],
            { group: { field: 'pr.code' } }
          ))
        : (this.tableResponse.options.group.field = 'pr.code');
    } else if (this.groupByMode === 'itemCategory') {
      this.tableResponse.options.group.field = 'item.itemCategory.name';
    } else if (this.groupByMode === 'prDate') {
      this.tableResponse.options.group.field = 'pr.requiredDate';
      this.tableResponse.options.group.plugins = 'date';
    } else if (this.groupByMode === 'department') {
      this.tableResponse.options.group.field = 'pr.organization.name';
    }
    if(this.baseModuleCode === null || this.baseModuleCode === undefined){
      this.tableResponse.setCustomData(this.groupByMode);
    }else{
      const customData = {
        groupByMode: this.groupByMode,
        baseModuleCode: this.baseModuleCode
      };
      this.tableResponse.setCustomData(customData);
    }
    this.tableResponse.options.group.isExpanded = true;
  }

  public doOnChangeGroupBy(): void {
    this.setGroupByMode();
    this.buildTableResponse();
    this.tableResponse.reload();
  }

  public setPrItem(): void {
    if (this.prItems && this.prItems.length !== 0) {
      this.tableResponse.setSelectedRowList(this.prItems);
    }
  }

  public setDecimal(field: number): string {
    if (!field) {
      return this.currency.transform(0, '', '', this.CURRENCY_DIGITS_INFO);
    } else {
      return this.currency.transform(field, '', '', this.CURRENCY_DIGITS_INFO);
    }
  }

  public doChoose(): void {
    // this.setBudgetList();
    if (this.tableResponse.selectedRow.selectedRowList.length !== 0) {
      this.onChange.emit({
        prItemList: this.tableResponse.selectedRow.getSelectedRecordList(),
        parentList: this.tableResponse.selectedRow.getSelectedRecordList()
      });
    } else if (this.tableResponse.selectedRow.isSelectAll) {
      this.httpClientService
        .get<PrItem[]>('/app-popup-choose-pr/get-list-all')
        .subscribe((prItemList: PrItem[]) => {
          this.onChange.emit({
            prItemList: prItemList,
            parentList: prItemList
          });
        });
    } else {
      this.global.alertService.showError(
        'app-popup-choose-pr.validation.choosePr',
        '.popup-choose-pr'
      );
    }
  }
}
