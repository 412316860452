<app-layout [isLoading]="formLoading">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'procurement-method.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              size="SM"
              formControlName="code"
              type="code"
              maxLength="32"
            ></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label text-right required">{{
            'procurement-method.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <ng-container formArrayName="translationList">
              <div
                class="form-group row"
                *ngFor="
                  let translation of translationList.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <div [ngStyle]="{ 'padding-left': '1%' }"></div>
                <label class="col-sm-0 text-left control-label">{{
                  translationListLabel[i]
                }}</label>
                <div class="col-sm-4 text-left">
                  <app-text-field
                    size="XL"
                    formControlName="value"
                    maxLength="128"
                  ></app-text-field>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'procurement-method.form.transactionType' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select
              [optionList]="transactionTypeOptionList"
              formControlName="transactionType"
              type="COMBOBOX"
              tooltip="{{
                'procurement-method.form.tooltip.transactionType' | translate
              }}"
            >
              <ng-template #option let-data>
                {{ translateTransactionType(data) | translate }}
              </ng-template>

              <ng-template #value let-value>
                <p *ngIf="value">{{
                  translateTransactionType(value) | translate
                }}</p>
              </ng-template>
            </app-dropdown-select>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'procurement-method.form.registrationType' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select
              [optionList]="registrationTypeOptionList"
              formControlName="procurementRegType"
              type="COMBOBOX"
              tooltip="{{
                'procurement-method.form.tooltip.registrationType' | translate
              }}"
            >
              <ng-template #option let-data>
                {{ translateRegType(data) | translate }}
              </ng-template>

              <ng-template #value let-value>
                <p *ngIf="value">{{ translateRegType(value) | translate }}</p>
              </ng-template>
            </app-dropdown-select>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'procurement-method.form.vendorStatus' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select
              [optionList]="vStatusOptionList"
              formControlName="vStatusList"
              type="CHOSEN"
              tooltip="{{
                'procurement-method.form.tooltip.vendorStatus' | translate
              }}"
            >
            </app-dropdown-select>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'procurement-method.form.vendorActStatus' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select
              [optionList]="vActStatusOptionList"
              formControlName="vActStatusList"
              type="CHOSEN"
              tooltip="{{
                'procurement-method.form.tooltip.vendorActStatus' | translate
              }}"
            >
            </app-dropdown-select>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'procurement-method.form.vendorCertStatus' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select
              [optionList]="vCertStatusOptionList"
              formControlName="vCertStatusList"
              type="CHOSEN"
              tooltip="{{
                'procurement-method.form.tooltip.vendorCertStatus' | translate
              }}"
            >
            </app-dropdown-select>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'procurement-method.form.description' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxLength="512">
            </app-text-area>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()"
            >{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </div>
  </div>
</app-layout>
