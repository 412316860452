import { TransactionType } from 'src/app/core/bean/transaction-type';
import { CertificateStatus } from '../../core/bean/certificate-status';
import { Lang } from '../../core/bean/lang';
import { ProcMethodVActStatus } from '../../core/bean/proc-method-v-act-status';
import { ProcMethodVCertStatus } from '../../core/bean/proc-method-v-cert-status';
import { ProcMethodVStatus } from '../../core/bean/proc-method-v-status';
import { ProcurementMethod } from '../../core/bean/procurement-method';
import { ProcurementRegType } from '../../core/bean/procurement-reg-type';
import { Translation } from '../../core/bean/translation';
import { VendorActivityStatus } from '../../core/bean/vendor-activity-status';
import { VendorStatus } from '../../core/bean/vendor-status';

export class ProcurementMethodResponse {
  procurementMethod: ProcurementMethod;
  langList: Lang[];
  translationList: Translation[];
  procMethodVStatusList: ProcMethodVStatus[];
  procMethodVActStatusList: ProcMethodVActStatus[];
  procMethodVCertStatusList: ProcMethodVCertStatus[];

  // optionlist
  procurementTransactionTypeOptionList: TransactionType[];
  procurementRegTypeOptionList: ProcurementRegType[];
  vStatusOptionList: VendorStatus[];
  vCertStatusOptionList: CertificateStatus[];
  vActStatusOptionList: VendorActivityStatus[];
}
