<app-popup [header]="header" [isLoading]="formLoading">
  <form [formGroup]="formGroup">
    <app-card
      header="price-evaluation.quotationDetail.vendorQuotation.title"
      *ngIf="!formLoading"
    >
      <app-table-xx
        [model]="tableResponseQuotationItem"
        [isShowPagination]="true"
        *ngIf="!formLoading"
      >
        <ng-template #headerFilter>
          <div class="form-group row text-sm-left">
            <div class="col text-left" [ngStyle]="{ 'min-width': '10rem' }">
              <app-dropdown-select
                size="XL"
                formControlName="itemType"
                type="COMBOBOX"
                [optionList]="itemTypeOptionList"
                (onChange)="onChangeItem()"
                container="body"
                placeholder="price-evaluation.filter.placeholder.type"
              ></app-dropdown-select>
            </div>
          </div>
        </ng-template>
        <ng-container
          *ngIf="tableResponseQuotationItem.getRecordList().length > 0"
        >
          <ng-template #row>
            <tr>
              <td></td>
              <td></td>
              <td
                *ngIf="
                  procurement &&
                  procurement.biddingSubmissionType.code ===
                    global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
                "
              ></td>
              <td></td>
              <td class="text-right" colspan="4">
                {{ 'price-evaluation.table.footer.goodsAmount' | translate }}
              </td>
              <td class="text-right">
                <strong>
                  {{
                    popupBidPriceResponse.quotation.goodsAmount
                      | resolveNumberToCurrency
                  }}
                  IDR
                </strong>
              </td>
              <td
                *ngIf="
                  procurement.biddingSubmissionType.code ===
                  global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
                "
              ></td>
              <td
                colspan="99"
                class="d-flex justify-content-end"
                *ngIf="
                  procurement.biddingSubmissionType.code !==
                  global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
                "
              >
                {{
                  popupBidPriceResponse.quotation.goodsProfitLossAmount
                    ? global.converterService.convertMoney(
                        popupBidPriceResponse.quotation.goodsProfitLossAmount
                      ) + ' %'
                    : '-'
                }}
                <app-badge
                  *ngIf="
                    popupBidPriceResponse.quotation.goodsProfitLossStatus &&
                    popupBidPriceResponse.quotation.goodsProfitLossAmount
                  "
                  [pill]="false"
                  [color]="
                    popupBidPriceResponse.quotation.goodsProfitLossStatus.code.toLowerCase() ===
                    global.appConstant.pm.PROFIT_LOSS_STATUS_SAVE.toLowerCase()
                      ? 'SUCCESS'
                      : 'DANGER'
                  "
                  customClass="badge-catalog ml-3"
                >
                  {{
                    popupBidPriceResponse.quotation.goodsProfitLossStatus.name
                  }}
                </app-badge>
              </td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td
                *ngIf="
                  procurement &&
                  procurement.biddingSubmissionType.code ===
                    global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
                "
              ></td>
              <td class="text-right" colspan="4">
                {{ 'price-evaluation.table.footer.serviceAmount' | translate }}
              </td>
              <td class="text-right">
                <strong>
                  {{
                    popupBidPriceResponse.quotation.serviceAmount
                      | resolveNumberToCurrency
                  }}
                  IDR
                </strong>
              </td>
              <td
                *ngIf="
                  procurement.biddingSubmissionType.code ===
                  global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
                "
              ></td>
              <td
                colspan="99"
                class="d-flex justify-content-end"
                *ngIf="
                  procurement.biddingSubmissionType.code !==
                  global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
                "
              >
                {{
                  popupBidPriceResponse.quotation.serviceProfitLossAmount
                    ? global.converterService.convertMoney(
                        popupBidPriceResponse.quotation.serviceProfitLossAmount
                      ) + ' %'
                    : '-'
                }}
                <app-badge
                  *ngIf="
                    popupBidPriceResponse.quotation.serviceProfitLossStatus &&
                    popupBidPriceResponse.quotation.serviceProfitLossAmount
                  "
                  [pill]="false"
                  [color]="
                    popupBidPriceResponse.quotation.serviceProfitLossStatus.code.toLowerCase() ===
                    global.appConstant.pm.PROFIT_LOSS_STATUS_SAVE.toLowerCase()
                      ? 'SUCCESS'
                      : 'DANGER'
                  "
                  customClass="badge-catalog ml-3"
                >
                  {{
                    popupBidPriceResponse.quotation.serviceProfitLossStatus.name
                  }}
                </app-badge>
              </td>
            </tr>

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td
                *ngIf="
                  procurement &&
                  procurement.biddingSubmissionType.code ===
                    global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
                "
              ></td>
              <td class="text-right" colspan="4">
                {{ 'price-evaluation.table.footer.amount' | translate }}
              </td>
              <td class="text-right">
                <strong>
                  {{
                    popupBidPriceResponse.quotation.amount
                      | resolveNumberToCurrency
                  }}
                  IDR
                </strong>
                <br />
                <i>
                  ({{
                    popupBidPriceResponse.quotation.amount
                      | resolveNumberToWords: global.currentLang
                  }}
                  {{ 'Rupiah' }})
                </i>
              </td>
              <td
                *ngIf="
                  procurement.biddingSubmissionType.code ===
                  global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
                "
              ></td>
              <td
                colspan="99"
                class="d-flex justify-content-end"
                *ngIf="
                  procurement.biddingSubmissionType.code !==
                  global.appConstant.pm.BIDDING_SUBMISSION_TYPE_UNIT_PRICE
                "
              >
                {{
                  global.converterService.convertMoney(
                    popupBidPriceResponse.quotation.profitLossAmount
                  ) || '0'
                }}&nbsp;%
                <app-badge
                  *ngIf="popupBidPriceResponse.quotation.profitLossStatus"
                  [pill]="false"
                  [color]="
                    popupBidPriceResponse.quotation.profitLossStatus.code.toLowerCase() ===
                    global.appConstant.pm.PROFIT_LOSS_STATUS_SAVE.toLowerCase()
                      ? 'SUCCESS'
                      : 'DANGER'
                  "
                  customClass="badge-catalog ml-3"
                >
                  {{ popupBidPriceResponse.quotation.profitLossStatus.name }}
                </app-badge>
              </td>
            </tr>
          </ng-template>
        </ng-container>
      </app-table-xx>
    </app-card>
    <app-card
      header="price-evaluation.popup.bidPrice.quotationAdministration.title"
      *ngIf="!formLoading"
    >
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'price-evaluation.popup.bidPrice.form.letterNumber' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{ popupBidPriceResponse.quotationAdministration.letterNumber }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{
            'price-evaluation.popup.bidPrice.form.validityPeriod' | translate
          }}
        </label>
        <div class="col-sm-9 text-left">
          <app-date-picker-x
            formControlName="validityPeriodAdm"
            [range]="true"
          ></app-date-picker-x>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'price-evaluation.popup.bidPrice.form.currency' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{ popupBidPriceResponse.quotationAdministration.currency.code }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{
            'price-evaluation.popup.bidPrice.form.letterOfQuotation' | translate
          }}
        </label>
        <div class="col-sm-6 text-left">
          <app-upload-x
            formControlName="documentBidBond"
            [fileUploader]="fileUploaderBidBond"
          ></app-upload-x>
        </div>
      </div>
    </app-card>
    <app-card
      header="price-evaluation.popup.bidPrice.quotationBidBond.title"
      *ngIf="!formLoading"
    >
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'price-evaluation.popup.bidPrice.form.bidBondType' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{
            getTranslateKey(popupBidPriceResponse.quotationBidBond.bondType)
              | translate
          }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'price-evaluation.popup.bidPrice.form.bidBondNumber' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{ popupBidPriceResponse.quotationBidBond.bidBondNumber }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'price-evaluation.popup.bidPrice.form.bidBondValue' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{
            global.converterService.convertMoney(
              popupBidPriceResponse.quotationBidBond.amount
            )
          }}&nbsp;IDR
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{
            'price-evaluation.popup.bidPrice.form.validityPeriod' | translate
          }}
        </label>
        <div class="col-sm-9 text-left">
          <app-date-picker-x
            formControlName="validityPeriodBidBond"
            [range]="true"
          ></app-date-picker-x>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'price-evaluation.popup.bidPrice.form.bidBondFile' | translate }}
        </label>
        <div class="col-sm-6 text-left">
          <app-upload-x
            formControlName="documentBidBond"
            [fileUploader]="fileUploaderBidBond"
          ></app-upload-x>
        </div>
      </div>
    </app-card>
  </form>
</app-popup>
