import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { UserDashboardWidget } from '../../bean/user-dashboard-widget';
import { WidgetEvent } from '../model/widget-event-model';
import { WidgetItemModel } from '../model/widget-item-model';
import { WidgetResponseModel } from '../model/widget-response-model';
import { WidgetService } from '../widgets.service';

@Component({
  selector: 'app-widget-wrapper',
  templateUrl: './app-widget-wrapper.component.html',
  styleUrls: ['./app-widget-wrapper.component.scss']
})
export class AppWidgetWrapperComponent extends BaseComponentComponent {
  @Input() model: WidgetResponseModel;
  public isShowEditDashboard: boolean;
  public lastLogin: Date;
  constructor(public widgetService: WidgetService) {
    super('app-widget-wrapper');
  }

  public onInit(): void {
    this.widgetService.isEdit = false;
    this.getAndSetLastLoginState();
  }

  public getAndSetLastLoginState(): void {
    this.httpClientService
      .get('/widget-login-information/index')
      .subscribe((response: any) => {
        this.lastLogin = new Date(response.lastLogin);
        this.setStateReady();
      });
  }

  public doShowEditDashboard(): void {
    this.isShowEditDashboard = !this.isShowEditDashboard;
    this.widgetService.isEdit = this.isShowEditDashboard;
  }

  public onChange(event: WidgetEvent): void {
    if (event.type === 'ON-ADD') {
      this.doAdd(event.data);
    } else if (event.type === 'ON-DELETE') {
      this.doSaveWidget();
    } else if (event.type === 'ON-EDIT') {
      if (event.data) {
        const { index, position } = event.data;
        this.model.dragAndDropValues[position][index].group = position;
      }
      this.doSaveWidget();
    }
  }

  public doAdd(widget: WidgetItemModel): void {
    this.model.dragAndDropValues[widget.group].push(widget);
    this.doSaveWidget();
  }

  public doSaveWidget(): void {
    // this.isShowEditDashboard = !this.isShowEditDashboard;
    const userDashboardWidget: UserDashboardWidget[] = this.model.values;
    this.httpClientService
      .post('/dashboard/save-my-dashboard', userDashboardWidget)
      .subscribe(() => {});
  }
}
