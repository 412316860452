import { Injectable } from '@angular/core';
import { Global } from '../../global/global';
import { WebSocketService } from './websocket.service';

@Injectable({ providedIn: 'root' })
export class CartWebsocketService extends WebSocketService<number> {
  constructor(global: Global) {
    super(`cart/${global.userSession.token}`);
  }

  protected onReceivedMessage(totalCart: string): Promise<any> {
    return new Promise(resolve => {
      const userNotificationResponse = +totalCart;
      resolve(this.setReceivedMessage(userNotificationResponse));
    });
  }
}
