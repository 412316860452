<app-popup>
  <div class="text-center"><br>
    <h3>{{ 'Are you sure to cancel this process?' | translate }}</h3><br>
    <div innerHTML="{{ 'Once you cancel this process, you can not continue anymore' | translate }}"></div><br>
  </div>
  <form class="form-horizontal popup-proposal-criteria" [formGroup]="formGroup" novalidate>
    <div class="row text-sm-left">
      <div class="col-sm-8 text-left">
        <p>Please fulfill the cancelation reason</p>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <div class="col-sm-12 text-left">
        <app-text-area size="XL" formControlName="note" maxLength="255">
        </app-text-area>
      </div>
    </div> 
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center">
      <app-button color="LIGHT" [outline]="true" minWidth="130px" (onClick)="activeModal.close(false)">
        {{ 'No' | translate }}
      </app-button>
      <app-button (onClick)="doSubmit()" minWidth="130px">
        {{ 'Yes, I am Sure' | translate }}
      </app-button>
    </div>
  </ng-template>
  </form>
</app-popup>