import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppTableModule } from '../table/components/app-table/app-table.module';
import { AppApprovalLogComponent } from './app-approval-log.component';
@NgModule({
  imports: [VendorModule, AppCardModule, AppTableModule],
  declarations: [AppApprovalLogComponent],
  exports: [AppApprovalLogComponent]
})
export class AppApprovalLogModule {}
