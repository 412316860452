<div class="app-widget-total-sla" *ngIf="!formLoading">
    <app-widget>
        <div class="row">
          <div class="cml-1 col-6">
             <h4 style="min-width: 90px; margin-bottom: 5px;">Total SLA</h4>
             <h3 style="min-width: 90px;">{{ total || 0 }} %</h3>
          </div>
        </div>    
      <div class="bar-wrapper">
        <div class="bar bar-left" [ngStyle]="{'width': (total) + '%'}">
        </div>
        <div class="bar bar-right" [ngStyle]="{'width': (100-total) + '%'}">
        </div>
      </div>
    </app-widget>
  </div>