<app-popup
  [header]="'tab-profile-expert-and-equipment.form.popup.header.expert.' + todo"
  [isLoading]="formLoading"
>
  <form [formGroup]="formGroup" class="app-popup-expert">
    <h5 class="separator">{{
      'tab-profile-expert-and-equipment.form-group.popup.expert' | translate
    }}</h5>
    <ng-container formGroupName="expert">
      <div
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-expert-and-equipment.form.popup.name' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            [autofocus]="true"
            size="LG"
            formControlName="name"
            maxLength="64"
            type="alphanumeric"
            include="-.\',"
          >
            <app-flag-new
              *ngIf="
                global.userSession.activeUserRole.role.type !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs') &&
                vendorExpertiseHistoryEdit.vendorHistory !== null
              "
              [oldValue]="vendorExpertise.name"
              [newValue]="vendorExpertiseHistoryEdit.name"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-expert-and-equipment.form.popup.DOB' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-date-picker formControlName="birthDate" maxDate="currentDate">
            <app-flag-new
              *ngIf="
                global.userSession.activeUserRole.role.type !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs') &&
                vendorExpertiseHistoryEdit.vendorHistory !== null
              "
              [oldValue]="birthDate"
              [newValue]="birthDateHistory"
            ></app-flag-new>
          </app-date-picker>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-expert-and-equipment.form.popup.experience' | translate
        }}</label>
        <div
          class="text-left"
          [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-7'"
        >
          <app-text-area size="LG" formControlName="experience" maxLength="512">
            <app-flag-new
              *ngIf="
                global.userSession.activeUserRole.role.type !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs') &&
                vendorExpertiseHistoryEdit.vendorHistory !== null
              "
              [oldValue]="vendorExpertise.experience"
              [newValue]="vendorExpertiseHistoryEdit.experience"
              [ngStyle]="{ 'padding-left': '3px' }"
            ></app-flag-new>
          </app-text-area>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-expert-and-equipment.form.popup.profession' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            size="LG"
            [optionList]="professionOptionList"
            formControlName="profession"
          >
            <app-flag-new
              *ngIf="
                global.userSession.activeUserRole.role.type !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs') &&
                vendorExpertiseHistoryEdit.vendorHistory !== null
              "
              [oldValue]="
                vendorExpertise.profession
                  ? vendorExpertise.profession.name
                  : null
              "
              [newValue]="
                vendorExpertiseHistoryEdit.profession
                  ? vendorExpertiseHistoryEdit.profession.name
                  : null
              "
            ></app-flag-new>
          </app-combo-box>
        </div>
      </div>

      <ng-container formGroupName="employmentPeriode">
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-profile-expert-and-equipment.form.popup.employmentPeriod'
              | translate
          }}</label>
          <div class="col-sm-9 text-left d-flex">
            <label class="mr-3 control-label">{{
              'tab-profile-expert-and-equipment.form.popup.from' | translate
            }}</label>
            <app-date-picker
              size="XL"
              formControlName="startDate"
              maxDate="endDate"
              [ngStyle]="{
                'margin-right': !formGroup.isView ? '0px' : '30px'
              }"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorExpertiseHistoryEdit.vendorHistory !== null
                "
                [oldValue]="startDateEP"
                [newValue]="startDateHistoryEP"
              ></app-flag-new>
            </app-date-picker>
            <label class="ml-3 mr-3 control-label">{{
              'tab-profile-expert-and-equipment.form.popup.until' | translate
            }}</label>
            <app-date-picker
              size="XL"
              formControlName="endDate"
              minDate="startDate"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorExpertiseHistoryEdit.vendorHistory !== null
                "
                [oldValue]="endDateEP"
                [newValue]="endDateHistoryEP"
              ></app-flag-new>
            </app-date-picker>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <h5 class="separator">{{
      'tab-profile-expert-and-equipment.form-group.popup.education' | translate
    }}</h5>
    <ng-container formGroupName="educationInfo">
      <div
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-expert-and-equipment.form.popup.education' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            size="LG"
            [optionList]="educationOptionList"
            formControlName="education"
            tooltip="{{
              'tab-profile-expert-and-equipment.tooltip.education' | translate
            }}"
          >
            <app-flag-new
              *ngIf="
                global.userSession.activeUserRole.role.type !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs') &&
                vendorExpertiseHistoryEdit.vendorHistory !== null
              "
              [oldValue]="
                vendorExpertise.education
                  ? vendorExpertise.education.name
                  : null
              "
              [newValue]="
                vendorExpertiseHistoryEdit.education
                  ? vendorExpertiseHistoryEdit.education.name
                  : null
              "
            ></app-flag-new>
          </app-combo-box>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-expert-and-equipment.form.popup.institution' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="LG"
            formControlName="institution"
            type="alphanumeric"
            maxLength="50"
          >
            <app-flag-new
              *ngIf="
                global.userSession.activeUserRole.role.type !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs') &&
                vendorExpertiseHistoryEdit.vendorHistory !== null
              "
              [oldValue]="vendorExpertise.institution"
              [newValue]="vendorExpertiseHistoryEdit.institution"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label" *ngIf="!formGroup.isView">{{
          'tab-profile-expert-and-equipment.form.popup.uploadCV' | translate
        }}</label>
        <label class="col-sm-3 control-label" *ngIf="formGroup.isView">{{
          'tab-profile-expert-and-equipment.form.popup.CV' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-upload
            size="LG"
            formControlName="fileCV"
            [fileUploader]="fileUploaderCV"
          >
            <app-flag-new
              *ngIf="
                global.userSession.activeUserRole.role.type !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs') &&
                vendorExpertiseHistoryEdit.vendorHistory !== null
              "
              [oldValue]="vendorExpertise.file ? vendorExpertise.file.id : null"
              [newValue]="
                vendorExpertiseHistoryEdit.file
                  ? vendorExpertiseHistoryEdit.file.id
                  : null
              "
              [hoverValue]="
                vendorExpertise.file ? vendorExpertise.file.fileName : null
              "
            ></app-flag-new>
          </app-upload>
        </div>
      </div>

      <ng-container formGroupName="year">
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-profile-expert-and-equipment.form.popup.educationYear'
              | translate
          }}</label>
          <div class="col-sm-9 text-left d-flex">
            <label class="mr-3 control-label">{{
              'tab-profile-expert-and-equipment.form.popup.from' | translate
            }}</label>
            <app-text-field
              size="XL"
              formControlName="startYear"
              type="integer"
              maxLength="4"
              [ngStyle]="{ width: '100px' }"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorExpertiseHistoryEdit.vendorHistory !== null
                "
                [oldValue]="vendorExpertise.startYear"
                [newValue]="vendorExpertiseHistoryEdit.startYear"
              ></app-flag-new>
            </app-text-field>
            <label class="ml-3 mr-3 control-label">{{
              'tab-profile-expert-and-equipment.form.popup.until' | translate
            }}</label>
            <app-text-field
              size="XL"
              formControlName="endYear"
              type="integer"
              maxLength="4"
              [ngStyle]="{ width: !formGroup.isView ? '100px' : '0px' }"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorExpertiseHistoryEdit.vendorHistory !== null
                "
                [oldValue]="vendorExpertise.endYear"
                [newValue]="vendorExpertiseHistoryEdit.endYear"
              ></app-flag-new>
            </app-text-field>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <h5 class="separator">{{
      'tab-profile-expert-and-equipment.form-group.popup.certification'
        | translate
    }}</h5>
    <ng-container formArrayName="certificationList">
      <app-fieldset
        *ngFor="let certification of certificationList.controls; let i = index"
        [formGroupName]="i"
      >
        <em
          *ngIf="!formGroup.isView"
          class="fas fa-times text-danger cursor-pointer position-absolute"
          [ngStyle]="{ right: '1rem', top: '1rem' }"
          (click)="doDeleteCertification(i)"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        ></em>
        <div
          *ngIf="
            formGroup.isView &&
            !isVendorList &&
            (fromOutside === '/pages/on-boarding' ||
              fromOutside === '/pages/approval-prcs') &&
            vendorExpertiseHistoryEdit.vendorHistory !== null
          "
          [innerHtml]="certification.value.crudInfo"
        ></div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-profile-expert-and-equipment.form.popup.certificationNumber'
              | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              size="LG"
              formControlName="certification"
              tooltip="{{
                'tab-profile-expert-and-equipment.tooltip.certificateNumber'
                  | translate
              }}"
              maxLength="32"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorExpertiseHistoryEdit.vendorHistory !== null
                "
                [oldValue]="certification.value.certification"
                [newValue]="certification.value.certification"
              ></app-flag-new>
            </app-text-field>
          </div>
        </div>
        <ng-container formGroupName="validationDate">
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'tab-profile-expert-and-equipment.form.popup.validationDate'
                | translate
            }}</label>
            <div class="col-sm-9 text-left d-flex">
              <label class="mr-3 control-label">{{
                'tab-profile-expert-and-equipment.form.popup.from' | translate
              }}</label>
              <app-date-picker
                size="XL"
                formControlName="startDate"
                maxDate="endDate"
                [ngStyle]="{
                  'margin-right': !formGroup.isView ? '0px' : '30px'
                }"
              >
                <app-flag-new
                  *ngIf="
                    global.userSession.activeUserRole.role.type !==
                      global.appConstant.core.ROLE_TYPE_VENDOR &&
                    formGroup.isView &&
                    !isVendorList &&
                    (fromOutside === '/pages/on-boarding' ||
                      fromOutside === '/pages/approval-prcs') &&
                    vendorExpertiseHistoryEdit.vendorHistory !== null
                  "
                  [oldValue]="startDateVDList[i]"
                  [newValue]="startDateHistoryVDList[i]"
                ></app-flag-new>
              </app-date-picker>
              <label class="ml-3 mr-3 control-label">{{
                'tab-profile-expert-and-equipment.form.popup.until' | translate
              }}</label>
              <app-date-picker
                size="XL"
                formControlName="endDate"
                minDate="startDate"
              >
                <app-flag-new
                  *ngIf="
                    global.userSession.activeUserRole.role.type !==
                      global.appConstant.core.ROLE_TYPE_VENDOR &&
                    formGroup.isView &&
                    !isVendorList &&
                    (fromOutside === '/pages/on-boarding' ||
                      fromOutside === '/pages/approval-prcs') &&
                    vendorExpertiseHistoryEdit.vendorHistory !== null
                  "
                  [oldValue]="endDateVDList[i]"
                  [newValue]="endDateHistoryVDList[i]"
                ></app-flag-new>
              </app-date-picker>
            </div>
          </div>
        </ng-container>
        <div class="form-group row text-sm-right" *ngIf="!formLoading">
          <label class="col-sm-3 control-label" *ngIf="!formGroup.isView">{{
            'tab-profile-expert-and-equipment.form.popup.uploadCertificate'
              | translate
          }}</label>
          <label class="col-sm-3 control-label" *ngIf="formGroup.isView">{{
            'tab-profile-expert-and-equipment.form.popup.certificate'
              | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-upload
              size="LG"
              formControlName="fileCertificate"
              [fileUploader]="fileUploaderList[i]"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorExpertiseHistoryEdit.vendorHistory !== null
                "
                [oldValue]="
                  certification.value.file ? certification.value.file.id : null
                "
                [newValue]="
                  certification.value.file ? certification.value.file.id : null
                "
                [hoverValue]="
                  certification.value.file
                    ? certification.value.file.fileName
                    : null
                "
              ></app-flag-new>
            </app-upload>
          </div>
        </div>
      </app-fieldset>
      <div class="form-group row text-sm-right" *ngIf="!formGroup.isView">
        <div class="col-sm-12 text-center pl-4">
          <app-button (onClick)="doAddCertification()">{{
            'app.button.addMore' | translate
          }}</app-button>
        </div>
      </div>
    </ng-container>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
        <app-button (onClick)="doSave()" *ngIf="!formGroup.isView">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
