import { BaseEntity } from '../base/base-entity';
import { FormObject } from '../bean/form-object';
import { TranslationKey } from '../bean/translation-key';

export class AttributeEntity extends BaseEntity {
  nameTranslated: string;
  translationKey: TranslationKey = new TranslationKey();
  formObject: FormObject;
  listOfValue: string;
  className: string;
  dataSource: string;
  groupName: string;
  description: string;
  isScore: boolean;
  isEditableMandatory: boolean;
  isEditableApproval: boolean;
}
