<div class="qrcode-outer-border">
  <img
    width="200px"
    height="200px"
    [attr.src]="
      global.config.BACKEND_ADDRESS +
        '/qr-code/generate/?barcodeText=' +
        barcodeText
        | resolveImgSrc
        | async
    "
    [attr.alt]="barcodeText"
  />
  <small class="qrcode-label white-space-normal text-center">{{ barcodeText }}</small>
  <div class="qrcode-inner-border">
    {{ 'qr-code-engine.scanBarcode.info' | translate }}
  </div>
</div>
