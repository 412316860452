import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppApprovalPrcsModule } from 'src/app/core/components/app-approval-prcs/app-approval-prcs.module';
import { AppDeliverableItemModule } from 'src/app/core/components/app-deliverable-item/app-deliverable-item.module';
import { AppProcurementInformationViewModule } from 'src/app/core/components/app-procurement-information-view/app-procurement-information-view.module';
import { AppVendorPaymentInformationModule } from 'src/app/core/components/app-vendor-payment-information/app-vendor-payment-information.module';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AppApprovalLogModule } from '../../core/components/app-approval-log/app-approval-log.module';
import { PaymentRequestReviewEditComponent } from './payment-request-review-edit.component';
import { PaymentRequestReviewComponent } from './payment-request-review.component';

export const routes = [
  {
    path: '',
    component: PaymentRequestReviewComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'edit',
    component: PaymentRequestReviewEditComponent,
    data: { breadcrumb: 'payment-request-review.breadcrumb.edit' }
  },
  {
    path: 'detail',
    component: PaymentRequestReviewEditComponent,
    data: { breadcrumb: 'payment-request-review.breadcrumb.view' }
  },
  {
    path: 'edit/:token',
    component: PaymentRequestReviewEditComponent,
    data: { breadcrumb: 'payment-request-review.breadcrumb.edit' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppProcurementInformationViewModule,
    AppVendorPaymentInformationModule,
    AppDeliverableItemModule,
    AppApprovalPrcsModule,
    AppApprovalLogModule,
    AppApprovalPrcsXModule
  ],
  declarations: [
    PaymentRequestReviewComponent,
    PaymentRequestReviewEditComponent
  ]
})
export class PaymentRequestReviewModule {}
