<div class="row main-row app-accordion-row">
  <div class="col-12">
    <app-workflow-progress-info
      [contract]="contract"
      [currentPosition]="moduleCode"
    >
    </app-workflow-progress-info>
    <app-card
      header="contract-finalization.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-accordion>
        <app-accordion-item
          [isExpanded]="true"
          header="contract-finalization.accordion.contractFinalization"
        >
          <form
            *ngIf="!formLoading"
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
          >
            <div *ngIf="!isShowParameter">
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'contract-finalization.form.file' | translate
                }}</label>
                <div class="col">
                  <div class="col-12 text-left p-0">
                    <a
                      href="#"
                      (click)="
                        doDownload(
                          $event,
                          '/contract-finalization/file/view/' +
                            latestContractDocument.uploadedFileName +
                            '/' +
                            latestContractDocument.fileType.code,
                          latestContractDocument.fileName
                        )
                      "
                    >
                      {{
                        latestContractDocument
                          ? latestContractDocument.fileName
                          : '-'
                      }}
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="form-group row text-sm-right"
                [ngClass]="
                  !formGroup.get('number').isView && todo !== 'view'
                    ? 'required'
                    : ''
                "
              >
                <label class="col-sm-3 control-label">{{
                  'contract-finalization.form.number' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-text-field
                    formControlName="number"
                    maxLength="32"
                  ></app-text-field>
                </div>
              </div>
              <div class="form-group row text-sm-right">
                <label
                  class="col-sm-3 control-label"
                  [ngClass]="!formGroup.isView ? 'required' : ''"
                  >{{
                    'contract-finalization.form.effectiveDate' | translate
                  }}</label
                >
                <div class="col-sm-9 text-left" style="display: flex">
                  <p class="mr-3 control-label">{{
                    'contract-finalization.from' | translate
                  }}</p>
                  <app-date-picker
                    size="XL"
                    formControlName="startDate"
                    [ngStyle]="{
                      'margin-right': !formGroup.isView ? '0px' : '30px'
                    }"
                  >
                  </app-date-picker>
                  <p class="ml-3 mr-3 control-label">{{
                    'contract-finalization.until' | translate
                  }}</p>
                  <app-date-picker
                    size="XL"
                    formControlName="endDate"
                    minDate="startDate"
                  ></app-date-picker>
                </div>
              </div>
              <div
                class="form-group row text-sm-right"
                [ngClass]="!formGroup.isView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'contract-finalization.form.vendorAccountNumber' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-combo-box
                    [optionList]="vendorBankAccountList"
                    tooltip="{{
                      'contract-finalization.tooltip.vendorAccountNumber'
                        | translate
                    }}"
                    formControlName="vendorAccountNumber"
                    (onChange)="doOnChange($event)"
                  ></app-combo-box>
                </div>
              </div>
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'contract-finalization.form.bankName' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-text-field formControlName="bankName"></app-text-field>
                </div>
              </div>
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'contract-finalization.form.accountHolder' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-text-field
                    formControlName="accountHolder"
                  ></app-text-field>
                </div>
              </div>
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'contract-finalization.form.branchName' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-text-field formControlName="branchName"></app-text-field>
                </div>
              </div>
              <div class="row justify-content-center mt-5">
                <app-button
                  color="PRIMARY"
                  (onClick)="doNext()"
                  [disabled]="formSaving"
                  *ngIf="!formGroup.isView"
                >
                  {{ 'app.button.next' | translate }}
                </app-button>
              </div>
            </div>
            <div class="text-center" *ngIf="isShowParameter && isLoading">
              <img
                src="assets/img/common/loader-small.gif"
                alt="Loading"
                height="11"
                width="40"
              />
            </div>
            <div *ngIf="isShowParameter && !isLoading">
              <h5 class="separator mt-3">{{
                'contract-finalization.separator.parameter' | translate
              }}</h5>
              <div class="removeSearchText app-table-search">
                <ng-container formArrayName="contractTemplateParamList">
                  <div class="form-group row text-sm-right">
                    <div
                      class="card border-0"
                      [ngStyle]="{
                        margin: '1px',
                        width: '-webkit-fill-available'
                      }"
                    >
                      <div class="card-body">
                        <div
                          class="table-responsive custom-table float-none w-100"
                          [ngStyle]="{ margin: '0 auto' }"
                        >
                          <table
                            class="table table-striped box-shadow"
                            aria-describedby="Table"
                          >
                            <thead>
                              <tr class="text-center">
                                <th class="tableNo" colspan="1" scope="col">
                                  {{ 'app-table.column.no' | translate }}
                                </th>
                                <th scope="col">{{
                                  'contract-finalization.table.column.name'
                                    | translate
                                }}</th>
                                <th scope="col">{{
                                  'contract-finalization.table.column.value'
                                    | translate
                                }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let contractTemplateParam of contractTemplateParamList.controls;
                                  let i = index
                                "
                                [formGroupName]="i"
                              >
                                <td class="text-center" colspan="1">
                                  {{ i + 1 }}</td
                                >
                                <td
                                  class="text-left"
                                  [ngStyle]="{
                                    'max-width': '240px',
                                    'white-space': 'normal',
                                    'min-width': '200px'
                                  }"
                                >
                                  {{
                                    contractTemplateParam.controls.name.value
                                  }}
                                  <span
                                    class="font-weight-bold text-danger"
                                    *ngIf="
                                      (!formGroup.isView &&
                                        !contractTemplateParam.controls.code.value.includes(
                                          'PERUBAHAN'
                                        ) &&
                                        contractTemplateParam.controls.code
                                          .value !==
                                          global.appConstant.cm
                                            .CONTRACT_PARAMETER_NOMOR_KONTRAK) ||
                                      (!formGroup.isView &&
                                        contract &&
                                        contract.contractType.code !==
                                          global.appConstant.cm
                                            .CONTRACT_TYPE_ADDENDUM_CONTRACT &&
                                        contractTemplateParam.controls.code
                                          .value ===
                                          global.appConstant.cm
                                            .CONTRACT_PARAMETER_NOMOR_KONTRAK)
                                    "
                                  >
                                    *
                                  </span>
                                </td>
                                <td class="text-left">
                                  <div
                                    *ngIf="
                                      contractTemplateParam.controls
                                        .valueTypeCode.value === 'STRING'
                                    "
                                  >
                                    <app-text-field
                                      size="LG"
                                      formControlName="value"
                                    ></app-text-field>
                                  </div>
                                  <div
                                    *ngIf="
                                      contractTemplateParam.controls
                                        .valueTypeCode.value === 'NUMBER'
                                    "
                                  >
                                    <app-text-field
                                      size="LG"
                                      formControlName="value"
                                      type="integer"
                                    ></app-text-field>
                                  </div>
                                  <div
                                    *ngIf="
                                      contractTemplateParam.controls
                                        .valueTypeCode.value ===
                                        'DATE_FORMAT_SHORT' ||
                                      contractTemplateParam.controls
                                        .valueTypeCode.value ===
                                        'DATE_FORMAT_LONG'
                                    "
                                  >
                                    <app-date-picker formControlName="value">
                                    </app-date-picker>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="row justify-content-center mt-5">
                <app-button
                  color="PRIMARY"
                  (onClick)="doSaveAll()"
                  [disabled]="formSaving"
                  *ngIf="!formGroup.isView && !formLoading"
                >
                  {{ 'app.button.save' | translate }}
                </app-button>
              </div>
            </div>
          </form>
        </app-accordion-item>
        <app-accordion-item
          header="contract-finalization.accordion.contractInformation"
          [isHidden]="
            !(
              (!formLoading &&
                contractFinalizationDTO.contract?.contractType?.code ===
                  global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT) ||
              contractFinalizationDTO.contract?.contractType?.code ===
                global.appConstant.cm.CONTRACT_TYPE_REPEAT_ORDER
            )
          "
        >
          <app-procurement-information-view
            [contractId]="
              contract.fromContract ? contract.fromContract.id : contractId
            "
          >
          </app-procurement-information-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-finalization.accordion.procurementInformation"
        >
          <app-procurement-information-view
            [procurementVendorId]="procurementVendorId"
          >
          </app-procurement-information-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-finalization.accordion.requestInformation"
        >
          <app-contract-request-view [contractId]="contractId">
          </app-contract-request-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-finalization.accordion.draftInformation"
        >
          <app-contract-draft-view [contractId]="contractId">
          </app-contract-draft-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-finalization.accordion.negotiationInformation"
        >
          <app-contract-negotiation-view [contractId]="contractId">
          </app-contract-negotiation-view>
        </app-accordion-item>
        <app-accordion-item
          [isHidden]="!contractFinalizationDTO.isUserApproval"
          header="contract-finalization.accordion.historyApproval"
        >
          <app-table
            #selectorHistoryApproval
            [model]="tableResponseHistoryApproval"
          >
          </app-table>
        </app-accordion-item>
        <app-accordion-item
          header="contract-finalization.accordion.vendorInformation"
        >
          <app-vendor-payment-information
            [procurementVendorId]="procurementVendorId"
            [todo]="todo"
            [temp]="formGroup.value"
          ></app-vendor-payment-information>
        </app-accordion-item>
      </app-accordion>
      <div
        class="floating-button mt-5"
        *ngIf="!formGroup.isView && !contractFinalizationDTO.isUserApproval"
      >
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button
                color="SECONDARY"
                (onClick)="doBack()"
                [disabled]="formSaving"
                >{{ 'app.button.back' | translate }}</app-button
              >
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
<app-approval-prcs
  *ngIf="contractFinalizationDTO.isUserApproval && !formLoading"
  [workflowModelPrcs]="workflowModelPrcs"
>
</app-approval-prcs>
