import { NgModule } from '@angular/core';
import { AppPopupModule } from 'src/app/core/components/app-popup/app-popup.module';
import { TableModule } from 'src/app/core/components/table/table.module';
import { PipesModule } from 'src/app/core/pipe/pipe.module';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppWidgetCardXModule } from 'src/app/core/widgets/components/app-widget-card-x/app-widget-card-x.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetBestSavingByBuyerComponent } from './widget-best-saving-by-buyer.component';

@NgModule({
  imports: [
    VendorModule,
    WidgetCardModule,
    TableModule,
    AppPopupModule,
    PipesModule,
    AppWidgetCardXModule
  ],
  declarations: [WidgetBestSavingByBuyerComponent],
  entryComponents: [WidgetBestSavingByBuyerComponent],
  exports: [WidgetBestSavingByBuyerComponent]
})
export class WidgetBestSavingByBuyerModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetBestSavingByBuyerComponent.moduleCode,
      WidgetBestSavingByBuyerComponent,
      'w-50'
    );
  }
}
