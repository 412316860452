<div class="app-approval-path">
  <ul
    [ngClass]="[
      model.type === 'SERIAL'
        ? 'serial'
        : model.type === 'PARALEL'
        ? 'paralel'
        : '',
      model.mode === 'READ' ? 'view' : 'write'
    ]"
  >
    <li
      class="text-center"
      *ngFor="let approver of model.approverList; let i = index"
      [ngClass]="[
        model.mode === 'READ'
          ? approver.isApproved
            ? 'approved'
            : (approver.isCurrentPosition && model.type === 'SERIAL') ||
              model.type === 'PARALEL'
            ? 'awaiting'
            : ''
          : ''
      ]"
    >
      <div class="item-content">
        <em
          class="fas fa-user-circle"
          [ngClass]="
            model.mode === 'READ' ? 'cursor-default' : 'cursor-pointer'
          "
          [ngStyle]="{ color: !model.type ? '#343434' : 'initial' }"
          [attr.title]="
            model.mode === 'WRITE'
              ? model.moduleCode + '.approvalPath.title.edit'
              : ''
          "
          (click)="doEditApprover(i)"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        >
        </em>
        <em
          class="fas fa-check-circle"
          title="{{ 'app-approval-path.status.approved' | translate }}"
          *ngIf="
            model.mode === 'READ' &&
            approver.isApproved &&
            model.isShowIconProgress
          "
        ></em>
        <em
          class="fas fa-times-circle"
          title="{{
            model.moduleCode + '.approvalPath.title.delete' | translate
          }}"
          (click)="doDeleteApprover(i)"
          *ngIf="model.mode === 'WRITE'"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        ></em>
        <em
          class="far fa-clock"
          title="{{ 'app-approval-path.status.waitingApproval' | translate }}"
          *ngIf="
            (model.type === 'SERIAL' &&
              model.mode === 'READ' &&
              approver.isCurrentPosition &&
              !approver.isApproved &&
              model.isShowIconProgress) ||
            (model.type === 'PARALEL' &&
              model.mode === 'READ' &&
              !approver.isApproved &&
              model.isShowIconProgress)
          "
        ></em>
        <ng-container
          *ngIf="!customTextTmpl; then defaultView; else customView"
        >
        </ng-container>
        <ng-template #customView>
          <ng-container
            *ngTemplateOutlet="
              customTextTmpl;
              context: { $implicit: model.origin[i] }
            "
          ></ng-container>
        </ng-template>
        <ng-template #defaultView>
          <span
            class="d-block"
            [ngStyle]="{ color: !model.type ? '#343434' : 'initial' }"
            >{{ approver.title | translate }}</span
          >
          <span
            class="d-block"
            [ngStyle]="{ color: !model.type ? '#343434' : 'initial' }"
            >{{ approver.subTitle | translate }}</span
          >
        </ng-template>
      </div>
    </li>
    <li
      class="text-center user-add"
      *ngIf="
        model.mode === 'WRITE' &&
        (!model.max || model.origin.length < model.max)
      "
    >
      <div
        class="item-content"
        title="{{
          model.moduleCode + '.approvalPath.title.choose' | translate
        }}"
      >
        <span
          class="button-add"
          (click)="doChooseApprover()"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        >
          <em class="fas fa-plus cursor-pointer"></em>
        </span>
        <span class="button-add-text">
          {{ model.moduleCode + '.approvalPath.button.choose' | translate }}
        </span>
      </div>
    </li>
  </ul>
</div>
