import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DirectivesModule } from '../../../../core/directives/directives.module';
import { SidebarLeftComponent } from './sidebar-left.component';
import { SidebarLeftService } from './sidebar-left.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    PerfectScrollbarModule,
    DirectivesModule
  ],
  declarations: [SidebarLeftComponent],
  exports: [SidebarLeftComponent],
  providers: [SidebarLeftService]
})
export class SidebarLeftModule {
  public static forRoot(): ModuleWithProviders<SidebarLeftModule> {
    return {
      ngModule: SidebarLeftModule,
      providers: [SidebarLeftService]
    };
  }

  // public static forChild(
  //   options?: object
  // ): ModuleWithProviders<SidebarLeftModule> {
  //   return {
  //     ngModule: SidebarLeftModule,
  //     providers: [SidebarLeftService],
  //   };
  // }
}
