import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppContractRequestInfoModule } from 'src/app/core/components/app-contract-request-info/app-contract-request-info.module';
import { AppContractRequestViewModule } from 'src/app/core/components/app-contract-request-view/app-contract-request-view.module';
import { AppContractReviewLogModule } from 'src/app/core/components/app-contract-review-log/app-contract-review-log.module';
import { AppFinesModule } from 'src/app/core/components/app-fines/app-fines.module';
import { AppGuaranteeModule } from 'src/app/core/components/app-guarantee/app-guarantee.module';
import { AppSowModule } from 'src/app/core/components/app-sow/app-sow.module';
import { AppApprovalLogModule } from '../../core/components/app-approval-log/app-approval-log.module';
import { AppApprovalPathModule } from '../../core/components/app-approval-path/app-approval-path.module';
import { AppApprovalPrcsModule } from '../../core/components/app-approval-prcs/app-approval-prcs.module';
import { AppProcurementInformationViewModule } from '../../core/components/app-procurement-information-view/app-procurement-information-view.module';
import { AppVendorPaymentInformationModule } from '../../core/components/app-vendor-payment-information/app-vendor-payment-information.module';
import { SharedModule } from '../../core/shared/shared.module';
import { WorklistCMComponent } from '../worklist-cm/worklist-cm.component';
import { WorklistCMModule } from '../worklist-cm/worklist-cm.module';
import { AppPopupContractDraftVersionHistoryComponent } from './app-popup-contract-draft-version-history.component';
import { AppPopupContractDraftVersionComponent } from './app-popup-contract-draft-version.component';
import { AppPopupContractReviewerComponent } from './app-popup-contract-reviewer.component';
import { AppPopupParticipantReviewersComponent } from './app-popup-participant-reviewers.component';
import { ContractDraftEditAddXComponent } from './contract-draft-edit-add-x.component';
import { ContractDraftEditAddComponent } from './contract-draft-edit-add.component';
import { AppPopupNewContractReviewerComponent } from './app-popup-new-contract-reviewer.component';
import { AppWorkflowStepInfoModule } from 'src/app/core/components/app-workflow-step-info/app-workflow-step-info.module';


export const routes = [
  {
    path: '',
    component: WorklistCMComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'edit',
    component: ContractDraftEditAddXComponent,
    data: { breadcrumb: 'contract-draft.breadcrumb.edit' }
  },
  {
    path: 'add',
    component: ContractDraftEditAddXComponent,
    data: { breadcrumb: 'contract-draft.breadcrumb.add' }
  },
  {
    path: 'detail',
    component: ContractDraftEditAddXComponent,
    data: { breadcrumb: 'contract-draft.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    WorklistCMModule,
    AppApprovalPrcsModule,
    AppApprovalPathModule,
    AppApprovalLogModule,
    AppSowModule,
    AppFinesModule,
    AppGuaranteeModule,
    AppContractRequestInfoModule,
    AppApprovalPrcsXModule,
    AppProcurementInformationViewModule,
    AppContractRequestViewModule,
    AppVendorPaymentInformationModule,
    AppContractReviewLogModule,
    AppWorkflowStepInfoModule
  ],
  declarations: [
    ContractDraftEditAddXComponent,
    ContractDraftEditAddComponent,
    AppPopupParticipantReviewersComponent,
    AppPopupContractDraftVersionComponent,
    AppPopupContractDraftVersionHistoryComponent,
    AppPopupContractReviewerComponent,
    AppPopupNewContractReviewerComponent
  ],
  entryComponents: [
    AppPopupParticipantReviewersComponent,
    AppPopupContractDraftVersionComponent,
    AppPopupContractDraftVersionHistoryComponent,
    AppPopupContractReviewerComponent,
    AppPopupNewContractReviewerComponent
  ],
  exports: [
    AppPopupParticipantReviewersComponent,
    AppPopupContractDraftVersionComponent,
    AppPopupContractDraftVersionHistoryComponent,
    AppPopupContractReviewerComponent,
    AppPopupNewContractReviewerComponent
  ]
})
export class ContractDraftModule {}
