import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { BlacklistCriteriaEditAddComponent } from './blacklist-criteria-edit-add.component';
import { BlacklistCriteriaComponent } from './blacklist-criteria.component';

export const routes = [
  { path: '', component: BlacklistCriteriaComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: BlacklistCriteriaEditAddComponent,
    data: { breadcrumb: 'blacklist-criteria.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: BlacklistCriteriaEditAddComponent,
    data: { breadcrumb: 'blacklist-criteria.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [BlacklistCriteriaComponent, BlacklistCriteriaEditAddComponent],
  providers: []
})
export class BlacklistCriteriaModule {}
