import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { BudgetAllocation } from '../../core/bean/budget-allocation';
import { BudgetHistory } from '../../core/bean/budget-history';
import { BudgetHistoryView } from '../../core/bean/budget-history-view';
import { BudgetType } from '../../core/bean/budget-type';
import { Coa } from '../../core/bean/coa';
import { CostCenter } from '../../core/bean/cost-center';
import { Currency } from '../../core/bean/currency';
import { Organization } from '../../core/bean/organization';
import { WorkPlanItem } from '../../core/bean/work-plan-item';
import { WorkProgram } from '../../core/bean/work-program';
import { AppChooseComponent } from '../../core/components/app-choose/app-choose.component';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { AppPopupWorkPlanItemInfoService } from '../../core/components/app-popup/app-popup-work-plan-item-info/app-popup-work-plan-item-info.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { ReportService } from '../../core/services/report.service';
import { Validators } from '../../core/validators';
import { BudgetAllocationDTO } from './dto/budget-allocation.dto';

@Component({
  templateUrl: './budget-allocation-edit-add.component.html'
})
export class BudgetAllocationEditAddComponent extends BaseModuleComponent {
  @ViewChild(AppChooseComponent) public appChoose: AppChooseComponent;

  public today: Date = new Date();
  public tableResponse: TableResponseModel<BudgetHistoryView>;
  public budgetAllocationDTO: BudgetAllocationDTO = new BudgetAllocationDTO();
  public budget: BudgetAllocation = new BudgetAllocation();
  public budgetAllocationId: number;
  public isLoading: boolean;
  public activityPeriod: number;
  public backUrl: string;
  public budgetTypeOptionList: OptionListModel<BudgetType> =
    new OptionListModel();
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );
  public costCenterOptionList: OptionListModel<CostCenter> =
    new OptionListModel();
  public workProgramOptionList: OptionListModel<WorkProgram> =
    new OptionListModel();

  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService,
    public appPopupWorkPlanItemInfoService: AppPopupWorkPlanItemInfoService,
    public reportService: ReportService,
    public appPopUpService: AppPopupService
  ) {
    super('budget-allocation', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.getAndSetBudgetAllocationToFormGroup();
    this.buildTableResponse();
  }

  public setOptionModel(): void {
    this.budgetTypeOptionList.setRequestValues(
      this.budgetAllocationDTO.budgetTypeList
    );
    this.costCenterOptionList.setRequestValues(
      this.budgetAllocationDTO.costCenterList
    );
    this.currencyOptionList.setRequestValues(
      this.budgetAllocationDTO.currencyList
    );
    this.workProgramOptionList.setRequestValues(
      this.budgetAllocationDTO.workProgramList
    );
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.budgetAllocationId = this.global.routerParams.get('budgetId');
    this.backUrl = this.global.routerParams.get('backUrl');
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.activityPeriod = new Date().getFullYear();
    this.formGroup = this.formBuilder.group({
      id: [null],
      organizationName: [null, Validators.required()],
      workProgram: [null, Validators.required()],
      budgetType: [null, Validators.required()],
      costCenter: [null, Validators.required()],
      coa: [null, Validators.required()],
      activityPeriod: [this.activityPeriod, Validators.required()],
      currency: [null, Validators.required()],
      availableBudget: [null],
      totalBudget: [null, Validators.required()],
      bookedBudget: [null],
      usedBudget: [null],
      paidOffBudget: [null]
    });
    if (this.todo === 'edit') {
      this.formGroup = this.formBuilder.group({
        id: [null],
        organizationName: [null, Validators.required()],
        workProgram: [null, Validators.required()],
        budgetType: [null, Validators.required()],
        costCenter: [null, Validators.required()],
        coa: [null, Validators.required()],
        activityPeriod: [this.activityPeriod, Validators.required()],
        availableBudget: [null],
        totalBudget: [null, Validators.required()],
        bookedBudget: [null],
        usedBudget: [null],
        paidOffBudget: [null],
        currency: [null, Validators.required()],
        note: [null, Validators.required()]
      });
    }
  }

  public getAndSetBudgetAllocationToFormGroup(): void {
    this.httpClientService
      .post<BudgetAllocationDTO>(
        '/budget-allocation/add-edit',
        new RouteRequestModel(this.todo, this.budgetAllocationId)
      )
      .subscribe(budgetAllocationDTO => {
        this.budgetAllocationDTO = budgetAllocationDTO;
        if (this.budgetAllocationDTO.budgetAllocation !== null) {
          this.formGroup.patchValue({
            id: this.budgetAllocationDTO.budgetAllocation.id,
            organizationName: this.budgetAllocationDTO.organizationList,
            workProgram: this.budgetAllocationDTO.budgetAllocation.workProgram,
            budgetType: this.budgetAllocationDTO.budgetAllocation.budgetType,
            costCenter: this.budgetAllocationDTO.budgetAllocation.costCenter,
            coa: this.budgetAllocationDTO.coaList,
            activityPeriod:
              this.budgetAllocationDTO.budgetAllocation.activityPeriod,
            currency: this.budgetAllocationDTO.budgetAllocation.currency,
            availableBudget: this.global.converterService.convertMoney(
              this.budgetAllocationDTO.budgetAllocation.availableBudget.toString()
            ),
            totalBudget: null,
            bookedBudget:
              this.budgetAllocationDTO.budgetAllocation.bookedBudget,
            usedBudget: this.budgetAllocationDTO.budgetAllocation.usedBudget,
            paidOffBudget:
              this.budgetAllocationDTO.budgetAllocation.paidOffBudget,
            note: null
          });
        }

        this.setOptionModel();
        this.setStateReady();
        if (this.todo === 'edit') {
          this.formGroup.get('currency').disable();
        }
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'user.name',
        header: 'table.column.userName'
      },
      {
        field: 'updatedDate',
        header: 'table.column.modifyDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'valueFrom',
        header: 'table.column.valueFrom',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'valueTo',
        header: 'table.column.valueTo',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      { field: 'note', header: 'table.column.note' }
    ]);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      if (
        this.todo === 'edit' &&
        this.budgetAllocationDTO.budgetAllocation.availableBudget +
          +this.formGroup.value.totalBudget <
          0
      ) {
        this.global.alertService.showError(
          this.translateService.instant(
            'budget-allocation.alert.availableBudget'
          )
        );
      } else {
        this.global.modalService
          .saveConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              if (this.todo === 'edit') {
                this.formGroup.patchValue({
                  availableBudget: this.global.converterService.uConvertMoney(
                    this.formGroup.value.availableBudget
                  )
                });
              }
              this.budget = this.global.copyFormAttributeToModel(
                this.budget,
                this.formGroup
              );
              this.budgetAllocationDTO.budgetAllocation = this.budget;
              this.budgetAllocationDTO.budgetAllocation.activityPeriod =
                this.formGroup.value.workProgram.activityPeriod;
              const organizationList: Organization[] =
                this.formGroup.get('organizationName').value;
              this.budgetAllocationDTO.budgetAllocation.organization =
                organizationList[organizationList.length - 1];
              const coaList: Coa[] = this.formGroup.get('coa').value;
              this.budgetAllocationDTO.budgetAllocation.coa =
                coaList[coaList.length - 1];
              this.budgetAllocationDTO.budgetHistory = new BudgetHistory();
              this.budgetAllocationDTO.budgetHistory.note =
                this.formGroup.value.note;
              const url =
                this.todo === 'edit'
                  ? '/budget-allocation/update'
                  : '/budget-allocation/insert';
              this.httpClientService
                .post<Response<BudgetAllocationDTO>>(
                  url,
                  this.budgetAllocationDTO
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.router.navigate([this.backUrl]);
                    this.global.alertService.showSuccessSavingOnNextRoute();
                  } else {
                    this.global.alertService.showError(response.statusText);
                  }
                  this.setStateReady();
                });
            }
          });
      }
    }
  }

  public doDetailItem(workPlanItem: WorkPlanItem): void {
    this.appPopupWorkPlanItemInfoService.open(workPlanItem.workPlan);
  }

  public doReport(budget: BudgetAllocation): void {
    this.isLoading = true;
    this.reportService
      .print('/budget-allocation/print/report', budget)
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/budget-allocation']);
  }
}
