import { BaseEntity } from '../base/base-entity';
import { Procurement } from '../bean/procurement';

export class ProcurementFinalizationCriteriaEntity extends BaseEntity {
  procurement: Procurement = new Procurement();
  upperThreshold: number;
  lowerThreshold: number;
  pricePercentage: number;
  technicalPercentage: number;
  isPricingThreshold: boolean;
}
