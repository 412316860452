<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-xx
        [model]="tableResponse"
        stringUrl="/pr-verification/index"
        [isShowCheckBox]="false"
        (onClick)="doClick($event)"
        header="{{ 'pr-verification.table.header' | translate }}"
      >
        <ng-template #headerFilterGroup>
          <form
            class="form-horizontal pt-4"
            [formGroup]="formGroup"
            novalidate
            *ngIf="!formLoading"
          >
            <div
              class="form-group row text-sm-left"
              style="margin-top: -2.5rem; margin-bottom: -1rem"
            >
              <label style="margin-left: 1.5rem; margin-top: 1.75rem">{{
                'pr-verification.form.date' | translate
              }}</label>
              <div
                class="col-sm-8 text-left"
                style="
                  display: inline-flex;
                  align-content: center;
                  align-items: center;
                "
              >
                <div class="col-sm-4" style="margin-top: 1.25rem">
                  <app-date-picker-x
                    size="XL"
                    formControlName="date"
                    (onChange)="onChangeFilter()"
                    [range]="true"
                    [ngStyle]="{
                      'margin-right': '7px',
                      width: 'inherit'
                    }"
                  >
                  </app-date-picker-x>
                </div>
                <div class="col-sm-3">
                  <app-dropdown-select
                    type="CHOSEN"
                    size="XL"
                    placeholder="{{
                      'pr-verification.placeholder.status' | translate
                    }}"
                    formControlName="prStatusList"
                    [optionList]="prStatusOptionList"
                    (onChange)="onChangeFilter()"
                    container="body"
                    [ngStyle]="{
                      width: 'inherit'
                    }"
                    showLimitValue="1"
                  >
                    <ng-template #selectAll>
                      <p>
                        {{
                          'pr-verification.placeholder.allStatus' | translate
                        }}
                      </p>
                    </ng-template></app-dropdown-select
                  >
                </div>
                <div class="col-sm-4">
                  <app-dropdown-select
                    size="XL"
                    formControlName="organizationList"
                    (onChange)="onChangeFilter()"
                    showLimitValue="1"
                    type="CHOSEN"
                    isMultiple="true"
                    placeholder="pr-verification.placeholder.department"
                    [optionList]="organizationOptionList"
                  >
                  </app-dropdown-select>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </app-table-xx>
    </div>
  </div>
</app-layout>
