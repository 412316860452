import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppChooseModule } from '../app-choose/app-choose.module';
import { AppComboBoxModule } from '../app-combo-box/app-combo-box.module';
import { AppMonthYearPickerModule } from '../app-month-year-picker/app-month-year-picker.module';
import { AppPopupModule } from '../app-popup/app-popup.module';
import { AppTextTreeModule } from '../app-text-tree/app-text-tree.module';
import { AppBudgetFilterPopupComponent } from './app-budget-filter-popup.component';
import { AppBudgetFilterComponent } from './app-budget-filter.component';
@NgModule({
  imports: [
    VendorModule,
    AppChooseModule,
    AppPopupModule,
    AppButtonModule,
    AppTextTreeModule,
    AppComboBoxModule,
    AppMonthYearPickerModule
  ],
  declarations: [AppBudgetFilterComponent, AppBudgetFilterPopupComponent],
  exports: [AppBudgetFilterComponent]
})
export class AppBudgetFilterModule {}
