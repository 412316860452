import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
@Component({
  templateUrl: './tab-activity-history.component.html'
})
export class TabActivityHistoryComponent extends BaseModuleComponent {
  @Input() model: any;
  constructor(translateService: TranslateService) {
    super('tab-activity', translateService);
  }

  public onInit(): void {
    this.setStateReady();
  }

  public doBack(): void {
    this.router.navigate(['/pages/vendor-history/detail']);
  }
}
