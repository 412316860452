import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { WidgetResponseModel } from '../../widgets/model/widget-response-model';
@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './app-drag-and-drop.component.html',
  styleUrls: ['./app-drag-and-drop.component.scss']
})
export class AppDragAndDropComponent extends BaseComponentComponent {
  @Input() public connectedTo: Array<any> = new Array();
  @Input() public dataList: WidgetResponseModel;
  @Input() public dragDropId: any;
  @Input() public disabled: boolean;

  @Output() private onChange: EventEmitter<boolean | number> =
    new EventEmitter();

  @ContentChild('dragDropItem') public dragDropItemTmpl: TemplateRef<any>;

  constructor() {
    super('app-drag-and-drop');
  }

  public onInit(): void {
    this.setStateReady();
  }

  public onDrop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.onChange.emit(true);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.onChange.emit(event.currentIndex);
    }
  }
}
