<div
  class="app-content-slider lmb-4"
  *ngIf="!isLoading"
  [ngClass]="customClass ? customClass : ''"
>
  <div class="content-slider-header">
    <h5 *ngIf="header">{{ header | translate }}</h5>
    <ng-container *ngTemplateOutlet="customHeaderTmpl"> </ng-container>
    <ng-container *ngFor="let isShowButtonControl of isShowButtonControls">
      <div
        class="content-slider-controls"
        *ngIf="controlPosition === 'TOP' && isShowButtonControl"
      >
        <app-button
          size="TN"
          (onClick)="doScrollToLeft()"
          *ngFor="let isDisable of scrollOnStartPosition"
          [disabled]="isDisable"
          [ngStyle]="{ 'margin-right': '8px' }"
        >
          <em class="pir pi-chevron-left"></em>
        </app-button>
        <app-button
          size="TN"
          (onClick)="doScrollToRight()"
          *ngFor="let isDisable of scrollOnEndPosition"
          [disabled]="isDisable"
        >
          <em class="pir pi-chevron-right"></em>
        </app-button>
      </div>
    </ng-container>
  </div>
  <div class="main-content-slider" [ngClass]="isShowShadow ? 'shadow' : ''">
    <ng-content select="app-content-slider-item[position='LEFT']"></ng-content>
    <div class="content-slider-body">
      <ng-container *ngFor="let isShowButtonControl of isShowButtonControls">
        <div
          class="content-slider-controls left"
          *ngIf="controlPosition === 'ASIDE' && isShowButtonControl"
        >
          <ng-container *ngFor="let isDisable of scrollOnStartPosition">
            <ng-container
              *ngTemplateOutlet="
                buttonControlLeftTmpl
                  ? customButtonLeftTemplate
                  : buttonLeftTemplate
              "
            ></ng-container>
            <ng-template #customButtonLeftTemplate>
              <ng-container
                *ngTemplateOutlet="
                  buttonControlLeftTmpl;
                  context: { $implicit: isDisable }
                "
              ></ng-container>
            </ng-template>
            <ng-template #buttonLeftTemplate>
              <app-button
                color="LIGHT"
                size="TN"
                (onClick)="doScrollToLeft()"
                [disabled]="isDisable"
              >
                <em class="pir pi-chevron-left pi-2x pi-2x"></em>
              </app-button>
            </ng-template>
          </ng-container>
        </div>
      </ng-container>
      <div class="main-content" #mainContentSlider>
        <ng-container *ngTemplateOutlet="customContentTmpl"></ng-container>
        <ng-content></ng-content>
        <ng-content select="app-content-slider-item"></ng-content>
      </div>
      <ng-container *ngFor="let isShowButtonControl of isShowButtonControls">
        <div
          class="content-slider-controls right"
          *ngIf="controlPosition === 'ASIDE' && isShowButtonControl"
        >
          <ng-container *ngFor="let isDisable of scrollOnEndPosition">
            <ng-container
              *ngTemplateOutlet="
                buttonControlRightTmpl
                  ? customButtonRightTemplate
                  : buttonRightTemplate
              "
            ></ng-container>
            <ng-template #customButtonRightTemplate>
              <ng-container
                *ngTemplateOutlet="
                  buttonControlRightTmpl;
                  context: { $implicit: doScrollToRight, isDisabled: isDisable }
                "
              ></ng-container>
            </ng-template>
            <ng-template #buttonRightTemplate>
              <app-button
                color="LIGHT"
                size="TN"
                (onClick)="doScrollToRight()"
                [disabled]="isDisable"
              >
                <em class="pir pi-chevron-right pi-2x fw-500"></em>
              </app-button>
            </ng-template>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <ng-content select="app-content-slider-item[position='RIGHT']"></ng-content>
  </div>
</div>
<div class="promise-loading" *ngIf="isLoading">
  <div class="loading"></div>
</div>
