import { TreeProcessItem } from '../interface/tree-process-item';
import { TreeProcessItemModel } from './tree-process-item-model';
export class TreeProcessModel {
  treeProcessItemList: Array<TreeProcessItemModel> = new Array();
  constructor() {}
  addItemToTreeProcessItem(treeProcessItem: TreeProcessItemModel): void {
    this.treeProcessItemList.push(treeProcessItem);
  }

  removeAt(index: number): void {
    this.treeProcessItemList.splice(index, 1);
  }

  getIndexOfData(data: any): number {
    const filterField = data.id !== null ? 'id' : 'tempId';
    return this.treeProcessItemList.findIndex(
      (treeProcessItem: TreeProcessItemModel) =>
        treeProcessItem.data[filterField] === data[filterField]
    );
  }

  get values(): Array<TreeProcessItem> {
    const values: Array<TreeProcessItem> = new Array();
    this.treeProcessItemList.forEach(
      (treeProcessItemModel: TreeProcessItemModel, index: number) => {
        const treeProcessItem: TreeProcessItem = treeProcessItemModel.value;
        treeProcessItem.seq = index + 1;
        if (treeProcessItem.type === 'ADD') {
          delete treeProcessItem.id;
        }
        if (
          treeProcessItem.type === 'EDIT' ||
          treeProcessItem.type === 'DELETE'
        ) {
          delete treeProcessItem.tempId;
        }
        values.push(treeProcessItem);
      }
    );
    return values;
  }

  reset(): void {
    this.treeProcessItemList.splice(0);
  }
}
