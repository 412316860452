import { Uploader } from '../model/uploader';
import { UploaderFile } from '../model/uploader-file';
export function bulkDeleteFile(state: Uploader): void {
  state.uploaderFileList.forEach((uploaderFile: UploaderFile) => {
    if (!uploaderFile.isNew) {
      uploaderFile.setStatus('DELETED');
      state.trash.push(uploaderFile);
    }
  });
  state.uploaderFileList.splice(0);
  state.valueChanges.emit(state);
}
