import { BaseEntity } from '../base/base-entity';
import { BillingMemoPriority } from '../bean/billing-memo-priority';
import { BillingMemoStatus } from '../bean/billing-memo-status';
import { Payment } from '../bean/payment';
import { PaymentMethod } from '../bean/payment-method';
import { PaymentType } from '../bean/payment-type';
import { Region } from '../bean/region';
import { User } from '../bean/user';

export class BillingMemoEntity extends BaseEntity {
    payment: Payment;
    region: Region;
    paymentMethod: PaymentMethod;
    paymentType: PaymentType;
    billingMemoPriority: BillingMemoPriority;
    billingMemoStatus: BillingMemoStatus;
    firstSigner: User;
    secondSigner: User;
    amount: number;
    number: string;
    postingDate: Date;
    date: Date;
    sayAmount: string;
    isIncludeVAT: boolean;
    subject: string;
    background: string;
    base: string;
    closing: string;
    rejectNote: string;
    approvalResponse: string;
}
