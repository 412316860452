import { Module } from '../../core/bean/module';
import { ModuleGroup } from '../../core/bean/module-group';
import { Workflow } from '../../core/bean/workflow';
import { WorkflowAdditional } from '../../core/bean/workflow-additional';

export class WorkflowEditAddResponse {
  workflow: Workflow;
  moduleGroupList: ModuleGroup[];
  moduleStarterList: Module[];
  sourceModuleList: Module[];
  targetModuleList: Module[];
  workflowAdditionalList: WorkflowAdditional[];
}
