<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="performance-fulfillment.titleContractDetail"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/performance-fulfillment/index-contract-detail"
      >
        <ng-template #actionButtons let-rowData>
          <app-button
            *ngIf="!rowData[7]"
            color="PRIMARY"
            size="TN"
            (onClick)="doEdit(rowData[0])"
            title="{{ 'app.tooltip.edit' | translate }}"
          >
            <span class="fas fa-pencil-alt"></span>
          </app-button>

          <app-button
            mode="BYPASS"
            *ngIf="rowData[7]"
            color="PRIMARY"
            size="TN"
            (onClick)="doView(rowData[0])"
            title="{{ 'app.tooltip.view' | translate }}"
          >
            <span class="fas fa-search"></span>
          </app-button>
        </ng-template>
      </app-table>

      <div class="button-group button-group-center">
        <app-button mode="BYPASS" color="SECONDARY" (onClick)="doBack()"
          >{{ 'app.button.back' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>
