import { NgModule } from '@angular/core';
import { AppPaginationModule } from '../../../../../../core/components/app-pagination/app-pagination.module';
import { VendorModule } from '../../../../../../core/vendor/vendor.module';
import { AppCatalogItemModule } from '../app-catalog-item/app-catalog-item.module';
import { AppCatalogSimilarProductComponent } from './app-catalog-similar-product.component';
@NgModule({
  imports: [VendorModule, AppCatalogItemModule, AppPaginationModule],
  declarations: [AppCatalogSimilarProductComponent],
  exports: [AppCatalogSimilarProductComponent]
})
export class AppCatalogSimilarProductModule {}
