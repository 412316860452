import { BaseEntity } from '../base/base-entity';
import { Rfp } from '../bean/rfp';
import { RfpStatus } from '../bean/rfp-status';
import { ScoringStatus } from '../bean/scoring-status';
import { SubmittedStatus } from '../bean/submitted-status';
import { Vendor } from '../bean/vendor';

export class RfpVendorEntity extends BaseEntity {
  rfp: Rfp = new Rfp();
  vendor: Vendor = new Vendor();
  rfpResponseStatus: RfpStatus = new RfpStatus();
  scoringStatus: ScoringStatus = new ScoringStatus();
  submittedStatus: SubmittedStatus = new SubmittedStatus();
  submittedDate: Date = new Date();
  lastReminderDate: Date = new Date();
  totalScore: number;
  totalProposalScore: number;
}
