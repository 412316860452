<div class="row main-row">
  <div class="col-12">
    <!--*ngIf="formReady"-->
    <app-card
      header="performance-fulfillment.titleDetail"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-fulfillment.form.vendorName' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ pFulfillmentDetailResponse.vendor.name }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-fulfillment.form.address' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ pFulfillmentDetailResponse.vendorAddress.address }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-fulfillment.form.agreementNumber' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ pFulfillmentDetailResponse.agreementNumber }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-fulfillment.form.termin' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ pFulfillmentDetailResponse.termin }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-fulfillment.form.agreementType' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ pFulfillmentDetailResponse.agreementType }}
          </div>
        </div>
        <app-fieldset legend="Common Scoring">
          <ng-container formArrayName="performanceScoreCommonList">
            <div
              class="table-responsive custom-table float-none"
              [ngStyle]="{ margin: '0 auto' }"
            >
              <p
                *ngIf="
                  !performanceScoreCommonList ||
                  performanceScoreCommonList.length == 0
                "
                class="text-center"
              >
                <strong>{{ 'app.info.noData' | translate }}</strong></p
              >
              <ng-container
                *ngFor="
                  let performanceScore of performanceScoreCommonList.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <label
                  ><strong>{{ performanceScore.value.name }}</strong></label
                >
                <table
                  class="table table-striped box-shadow mb-5"
                  aria-describedby="Table"
                >
                  <thead>
                    <!-- <tr class="text-left">
                      <th scope="col">{{ performanceScore.value.name }}</th>
                    </tr> -->
                    <tr class="text-center">
                      <th scope="col"
                        >{{ 'app.table.column.no' | translate }}
                      </th>
                      <th scope="col">
                        {{
                          'performance-fulfillment.form.criteria' | translate
                        }}
                      </th>
                      <th scope="col">
                        {{ 'performance-fulfillment.form.value' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'performance-fulfillment.form.result' | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody formArrayName="scoringCriteriaList">
                    <!-- <tr *ngIf="scoringCriteriaList.length = 0">
                          <td colspan="99" class="bg-white text-center">
                            {{ 'app.info.noData' | translate }}
                          </td>
                        </tr> -->
                    <ng-container>
                      <tr
                        *ngFor="
                          let scoringCriteria of performanceScore.controls
                            .scoringCriteriaList.controls;
                          let j = index
                        "
                        [formGroupName]="j"
                      >
                        <td class="text-center">{{ j + 1 }}.</td>
                        <td class="text-center">
                          {{ scoringCriteria.value.scoringCriteriaName }}
                        </td>
                        <td class="text-center">
                          {{ scoringCriteria.value.value }}
                        </td>
                        <td class="text-center">
                          {{ scoringCriteria.value.result }}
                        </td>
                      </tr>
                      <tr class="text-center">
                        <td></td>
                        <td></td>
                        <td class="font-weight-bold">
                          {{ 'performance-fulfillment.form.total' | translate }}
                        </td>
                        <td class="font-weight-bold">{{
                          performanceScore.value.totalResult
                        }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </ng-container>
        </app-fieldset>

        <app-fieldset legend="Specific Scoring">
          <ng-container formArrayName="performanceScoreSpecificList">
            <div
              class="table-responsive custom-table float-none"
              [ngStyle]="{ margin: '0 auto' }"
            >
              <p
                *ngIf="
                  !performanceScoreSpecificList ||
                  performanceScoreSpecificList.length == 0
                "
                class="text-center"
              >
                <strong>{{ 'app.info.noData' | translate }}</strong>
              </p>
              <ng-container
                *ngFor="
                  let performanceScore of performanceScoreSpecificList.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <label
                  ><strong>{{ performanceScore.value.name }}</strong></label
                >
                <table
                  class="table table-striped box-shadow"
                  aria-describedby="Table"
                >
                  <thead>
                    <tr class="text-center">
                      <th scope="col"
                        >{{ 'app.table.column.no' | translate }}
                      </th>
                      <th scope="col">
                        {{
                          'performance-fulfillment.form.criteria' | translate
                        }}
                      </th>
                      <th scope="col">
                        {{ 'performance-fulfillment.form.value' | translate }}
                      </th>
                      <th scope="col">
                        {{ 'performance-fulfillment.form.result' | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody formArrayName="scoringCriteriaList">
                    <!-- <tr *ngIf="pFulfillmentDetailResponse.
                    scoringCriteriaGroupByIsSpecificList.length = 0">
                        <td colspan="99" class="bg-white text-center">
                          {{ 'app.info.noData' | translate }}
                        </td>
                      </tr> -->
                    <tr
                      *ngFor="
                        let scoringCriterias of performanceScore.controls
                          .scoringCriteriaList.controls;
                        let j = index
                      "
                      [formGroupName]="j"
                    >
                      <td class="text-center">{{ j + 1 }}.</td>
                      <td class="text-center">
                        {{ scoringCriterias.value.scoringCriteriaName }}
                      </td>
                      <td class="text-center">
                        {{ scoringCriterias.value.value }}
                      </td>
                      <td class="text-center">
                        {{ scoringCriterias.value.result }}
                      </td>
                    </tr>
                    <tr class="text-center">
                      <td></td>
                      <td></td>
                      <td class="font-weight-bold">
                        {{ 'performance-fulfillment.form.total' | translate }}
                      </td>
                      <td class="font-weight-bold">{{
                        performanceScore.value.totalResult
                      }}</td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
            </div>
          </ng-container>
        </app-fieldset>

        <app-fieldset legend="Scoring Summary">
          <div
            class="table-responsive custom-table float-none"
            [ngStyle]="{ margin: '0 auto' }"
          >
            <table
              class="table table-striped box-shadow"
              aria-describedby="Table"
            >
              <thead>
                <tr class="text-center">
                  <th scope="col">{{ 'app.table.column.no' | translate }}</th>
                  <th scope="col">
                    {{
                      'performance-fulfillment.form.criteriaGroup' | translate
                    }}
                  </th>
                  <th scope="col">
                    {{ 'performance-fulfillment.form.weight' | translate }}
                  </th>
                  <th scope="col">
                    {{ 'performance-fulfillment.form.result' | translate }}
                  </th>
                  <th scope="col">
                    {{ 'performance-fulfillment.form.total' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="performanceSummaryList.length == 0">
                  <td colspan="99" class="bg-white text-center">
                    {{ 'app.info.noData' | translate }}
                  </td>
                </tr>
                <ng-container
                  *ngFor="
                    let performanceSummary of performanceSummaryList;
                    let i = index
                  "
                >
                  <tr>
                    <td class="text-center">{{ i + 1 }}.</td>
                    <td class="text-center">
                      {{ performanceSummary.scoringCriteriaGroupName }}
                    </td>
                    <td class="text-center">
                      {{ performanceSummary.weight + '%' }}
                    </td>
                    <td class="text-center">
                      {{ performanceSummary.result }}
                    </td>
                    <td class="text-center">
                      {{ performanceSummary.total }}
                    </td>
                  </tr>
                </ng-container>
                <tr class="text-center">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="font-weight-bold">
                    {{
                      'performance-fulfillment.form.totalScoring' | translate
                    }}
                  </td>
                  <td class="font-weight-bold">{{ totalScoring }}</td>
                </tr>
                <tr class="text-center">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="font-weight-bold">
                    {{ 'performance-fulfillment.form.rating' | translate }}
                  </td>
                  <td>
                    <div class="d-flex justify-content-center">
                      <div class="col-sm-9 text-center">
                        <app-rating
                          formControlName="rating"
                          [isViewOnly]="true"
                        ></app-rating>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-fieldset>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-fulfillment.form.user' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              pFulfillmentDetailResponse.performance.verifiedByUser?.name || '-'
            }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-fulfillment.form.upload' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-upload formControlName="file" [fileUploader]="fileUploader">
            </app-upload>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-fulfillment.form.note' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            {{ pFulfillmentDetailResponse.performance.note || '-' }}
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button
            mode="BYPASS"
            color="SECONDARY"
            (onClick)="doBack()"
            class="mr-1"
            >{{ 'app.button.back' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
