import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
@Pipe({
  name: 'resolveFormArray'
})
export class ResolveFormArrayPipe implements PipeTransform {
  transform(formGroup: FormGroup, formControlName: string): AbstractControl[] {
    return (formGroup.get(formControlName) as FormArray).controls;
  }
}
