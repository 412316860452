import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { WidgetAccordionListModule } from '../../components/widget-accordion-list/widget-accordion-list.module';
import { WidgetService } from '../../widget.service';
import { WidgetPaymentStatusComponent } from './widget-payment-status.component';
@NgModule({
  imports: [VendorModule, WidgetAccordionListModule],
  declarations: [WidgetPaymentStatusComponent],
  entryComponents: [WidgetPaymentStatusComponent],
  exports: [WidgetPaymentStatusComponent]
})
export class WidgetPaymentStatusModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetPaymentStatusComponent.moduleCode,
      WidgetPaymentStatusComponent,
      'w-25'
    );
  }
}
