import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { ContractReminder } from '../../core/bean/contract-reminder';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './contract-history-popup-new-email.component.html'
})
export class ContractHistoryPopupNewEmailComponent extends BaseComponentComponent {
  @Output() onChange: EventEmitter<ContractReminder> = new EventEmitter();

  public positionOptionList: OptionListModel<any> = new OptionListModel(false);
  public positionList = [
    { name: 'contract-history.popup.newEmail.internal', value: 'Internal' },
    { name: 'contract-history.popup.newEmail.vendor', value: 'Vendor' },
    { name: 'contract-history.popup.newEmail.others', value: 'Others' }
  ];

  constructor(public ngbActiveModal: NgbActiveModal) {
    super('contract-history');
  }
  public onInit(): void {
    this.buildFormGroup();
    this.setOptionList();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required()],
      email: [
        null,
        Validators.compose([Validators.required(), Validators.email()])
      ],
      position: [null, Validators.required()]
    });
  }

  public setOptionList(): void {
    this.positionOptionList.setRequestValues(this.positionList);
  }

  public doCancel(): void {
    this.ngbActiveModal.close();
  }

  public doAdd(): void {
    this.validate();
    if (this.formGroup.valid) {
      const formGroupValue = this.formGroup.value;
      const contractReminder = new ContractReminder();
      contractReminder.name = formGroupValue.name;
      contractReminder.email = formGroupValue.email;
      contractReminder.position = formGroupValue.position.value;
      this.onChange.emit(contractReminder);
    }
  }
}
