import { WorkProgram } from '../../../core/bean/work-program';
import { BudgetType } from '../../../core/bean/budget-type';
import { CostCenter } from 'src/app/core/bean/cost-center';
import { Currency } from 'src/app/core/bean/currency';
import { BudgetAllocation } from 'src/app/core/bean/budget-allocation';
import { Coa } from 'src/app/core/bean/coa';
import { BudgetHistory } from '../../../core/bean/budget-history';
import { Module } from 'src/app/core/bean/module';
import { Organization } from 'src/app/core/bean/organization';
export class BudgetAllocationResponse {

    budgetTypeList: BudgetType[] = [];
    workProgramList: WorkProgram[] = [];
    currencyList: Currency[] = [];
    coaList: Coa[] = [];
    costCenterList: CostCenter[] = [];
    organizationList: Organization[] = [];
    budgetHistoryList: BudgetHistory[] = [];
    budgetAllocation: BudgetAllocation;
    budgetHistory: BudgetHistory;
    module: Module;
}
