import { ApprovalModelPrcs } from '../../core/bean/approval-model-prcs';
import { BlacklistDuration } from '../../core/bean/blaclist-duration';
import { TransactionLog } from '../../core/bean/transaction-log';
import { Vendor } from '../../core/bean/vendor';
import { VendorBlacklist } from '../../core/bean/vendor-blacklist';
import { VendorViolation } from '../../core/bean/vendor-violation';
export class VendorBlacklistResponse {
  vendorBlacklist: VendorBlacklist = new VendorBlacklist();
  vendor: Vendor = new Vendor();
  vendorViolationList: VendorViolation[] = [];
  blacklistDurationList: BlacklistDuration[] = [];
  isUserApproval: boolean;
  approvalModelPrcs: ApprovalModelPrcs = new ApprovalModelPrcs();
  transactionLog: TransactionLog = new TransactionLog();
}
