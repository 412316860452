import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { TextUtils } from '../../utils';
import { CarouselResponseModel } from './model/carousel-response-model';
@Component({
  selector: 'app-carousel',
  templateUrl: './app-carousel.component.html',
  styleUrls: ['./app-carousel.component.scss']
})
export class AppCarouselComponent extends BaseComponentComponent {
  @Input() model: CarouselResponseModel;
  @ContentChild('carouselItem') carouselItemTmpl: TemplateRef<any>;
  @ContentChild('carouselControl') carouselControlTmpl: TemplateRef<any>;
  @ContentChild('carouselIndicator') carouselIndicatorTmpl: TemplateRef<any>;
  generatedId: string = TextUtils.generateRandomString();
  constructor() {
    super('app-carousel');
  }

  public onInit(): void {
    this.setStateReady();
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
