import {
  Component,
  ElementRef,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
@Component({
  selector: 'app-captcha',
  templateUrl: './app-captcha.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: makeProvider(AppCaptchaComponent)
})
export class AppCaptchaComponent extends BaseValueAccessorComponent<string> {
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super('app-captcha', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setStateReady();
  }
}
