<app-layout [isLoading]="formLoading" header="committee.breadcrumb.index">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-card
          header="committee.detail.general.title"
          tips="committee.detail.general.tips"
        >
          <app-fullfilled></app-fullfilled>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'committee.form.code' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="code"
                autofocus="true"
                type="code"
              >
              </app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'committee.form.name' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="name"> </app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3 optional">{{
              'committee.form.description' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-area
                size="LG"
                formControlName="description"
                maxLength="255"
              >
              </app-text-area>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'committee.form.type' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                type="COMBOBOX"
                [optionList]="typeOptionList"
                formControlName="committeeType"
                (onChange)="doOnChangeType()"
              ></app-dropdown-select>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'committee.form.organization' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-tree
                formControlName="organization"
                stringUrl="/organization"
                isLazy="true"
                (onChange)="onChangeOrganization()"
              >
              </app-text-tree>
            </div>
            <div class="col-sm-4 text-left">
              {{ organizationName }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'committee.form.user' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-auto-complete
                formControlName="picUser"
                stringUrl="/committee/auto-complete-user-list"
                [params]="organizationParam"
              >
              </app-auto-complete>
            </div>
          </div>

          <h5 class="separator">{{
            'committee.detail.decree.title' | translate
          }}</h5>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'committee.form.decreeNumber' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-generated
                formControlName="decreeCode"
                maxLength="64"
                [moduleCode]="moduleCode"
                numberGeneratorCode="COMMITTEE_DECREE_NUMBER"
              ></app-text-generated>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'committee.form.dateOfDecree' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-date-picker-x
                formControlName="decreeDate"
              ></app-date-picker-x>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'committee.form.attachment' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              <app-upload-x
                formControlName="fileObjectList"
                [fileUploader]="fileUploader"
              ></app-upload-x>
            </div>
          </div>
        </app-card>

        <app-committee
          *ngIf="isShowProcCommittee"
          header="committee.detail.procurementCommittee.title"
          tips="committee.detail.procurementCommittee.tips"
          [binaryTreeModel]="binaryTreeModel"
        >
        </app-committee>

        <div
          class="button-group button-group-center mt-5"
          *ngIf="!formGroup.isView"
        >
          <app-button (onClick)="doSave()" [disabled]="formSaving"
            >{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips *ngIf="!formLoading" #appTips></app-tips>
  </ng-template>
</app-layout>
