import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Contract } from 'src/app/core/bean/contract';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';

@Component({
  templateUrl: './app-popup-contract-termination-choose-contract.component.html'
})
export class AppPopupContractTerminationChooseContractComponent extends BaseComponentComponent {
  @Input() public contractId: number;
  @Input() public contract: Contract;
  @Output() onChange: EventEmitter<Contract> = new EventEmitter();
  public tableResponse: TableResponseModel<Contract>;

  constructor(public translateService: TranslateService) {
    super('contract-termination');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'requestNumber',
        header: 'table.column.contractNumber'
      },
      {
        field: 'startDate',
        header: 'table.column.startDateContract',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'endDate',
        header: 'table.column.endDateContract',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'title',
        header: 'table.column.title'
      },
      {
        field: 'vendor.name',
        header: 'table.column.vendor',
        customClass: 'white-space-normal'
      },
      {
        field: 'contractType.name',
        header: 'table.column.contractType',
        customClass: 'text-center'
      }
    ]);
  }

  public doCheckContract(contract: Contract): void {
    this.contractId = contract.id;
    this.contract = contract;
  }

  public doSave(): void {
    if (this.contractId) {
      this.onChange.emit(this.contract);
    } else {
      this.global.alertService.showError(
        this.global.translateService.instant('app.validation.chooseOneData'),
        '.main-row-popup-choose-contract'
      );
    }
  }

  public doSort(sortField: string): void {
    this.tableResponse.setSortField(sortField);
    this.tableResponse.reload();
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
