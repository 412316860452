import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupAdjustmentVendorComponent } from './app-popup-adjustment-vendor.component';
import { SlaVendorDetailComponent } from './sla-vendor-detail.component';
import { SlaVendorComponent } from './sla-vendor.component';

export const routes = [
  { path: '', component: SlaVendorComponent, data: { breadcrumb: '' } },
  {
    path: 'detail',
    component: SlaVendorDetailComponent,
    data: { breadcrumb: 'sla-vendor.breadcrumb.detail' }
  },
  {
    path: 'app-popup-adjustment-vendor',
    component: AppPopupAdjustmentVendorComponent,
    data: { breadcrumb: 'sla-vendor.breadcrumb.adjustment' }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    SlaVendorComponent,
    SlaVendorDetailComponent,
    AppPopupAdjustmentVendorComponent
  ],
  providers: []
})
export class SlaVendorModule {}
