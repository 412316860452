<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="scoring-criteria.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'scoring-criteria.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              size="SM"
              formControlName="code"
              type="code"
              tooltip="{{ 'scoring-criteria.tooltip.code' | translate }}"
              maxLength="32"
            >
            </app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'scoring-criteria.form.isManualScoring' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-toggle-switch
              formControlName="isManualScoring"
              (onChange)="change($event)"
            >
            </app-toggle-switch>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'scoring-criteria.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="name" maxLength="128">
            </app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="formGroup.get('isManualScoring').value ? '' : 'required'"
        >
          <label class="col-sm-3 control-label">{{
            'scoring-criteria.form.attribute' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-choose
              formControlName="attributeCode"
              tooltip="{{ 'scoring-criteria.tooltip.attribute' | translate }}"
              (onClick)="doChooseAttribute($event)"
            ></app-choose>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'scoring-criteria.form.description' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area
              formControlName="description"
              maxLength="512"
            ></app-text-area>
          </div>
        </div>

        <div
          class="row justify-content-center"
          *ngIf="this.formGroup.get('isManualScoring').value"
        >
          <div class="col-md-11">
            <div class="card">
              <div class="card-header">
                <div
                  class="float-left control-label"
                  [ngStyle]="{ 'font-size': '17px' }"
                >
                  {{ 'scoring-criteria.form.scoringCriteriaRange' | translate }}
                </div>
                <div class="float-right">
                  <button
                    type="button"
                    class="btn btn-primary"
                    (click)="doAdd()"
                    [disabled]="isDisabled"
                  >
                    {{ 'app.button.add' | translate }}</button
                  >
                </div>
              </div>
              <div class="card-body">
                <div
                  class="text-center table-responsive float-none"
                  [ngStyle]="{ margin: '0 auto' }"
                >
                  <table
                    class="table table-striped box-shadow"
                    aria-describedby="Table"
                  >
                    <thead>
                      <tr>
                        <th scope="col">{{
                          'app.table.column.no' | translate
                        }}</th>
                        <th scope="col" class="fit">{{
                          'scoring-criteria.form.from' | translate
                        }}</th>
                        <th scope="col" class="fit">{{
                          'scoring-criteria.form.until' | translate
                        }}</th>
                        <th scope="col" class="fit"
                          >{{ 'scoring-criteria.form.isInfinity' | translate }}
                        </th>
                        <th scope="col" class="fit">{{
                          'scoring-criteria.form.result' | translate
                        }}</th>
                        <th scope="col">{{
                          'app.table.column.action' | translate
                        }}</th>
                      </tr>
                    </thead>
                    <tbody formArrayName="scoringCriteriaRangeList">
                      <tr
                        [formGroupName]="i"
                        *ngFor="
                          let tr of formGroup.get('scoringCriteriaRangeList')
                            .controls;
                          let i = index;
                          let last = last
                        "
                      >
                        <td>{{ i + 1 }}.</td>
                        <td>
                          <div>
                            <app-text-field
                              size="XL"
                              formControlName="from"
                              maxLength="16"
                            >
                            </app-text-field>
                          </div>
                        </td>

                        <td>
                          <div>
                            <div class="col-sm-12 text-center">
                              <app-text-field
                                size="XL"
                                formControlName="until"
                                maxLength="16"
                                type="integer"
                                include="."
                                (onInput)="doInputMethod($event, tr)"
                              >
                              </app-text-field>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div>
                            <div class="col-sm-12 text-center">
                              <app-check-box
                                formControlName="isInfinity"
                                (change)="
                                  doIsInfinityChange(
                                    $event.target.checked,
                                    tr,
                                    i
                                  )
                                "
                              >
                              </app-check-box>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div>
                            <div class="col-sm-12 text-center">
                              <app-combo-box
                                size="XL"
                                [optionList]="optionList"
                                formControlName="result"
                                container="body"
                              >
                              </app-combo-box>
                            </div>
                          </div>
                        </td>

                        <td>
                          <button
                            *ngIf="last && i != 0"
                            class="btn btn-secondary btn-sm mr-2"
                            type="button"
                            title="Delete"
                            (click)="doRemove(i)"
                          >
                            <span class="fa fas fa-trash"></span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button color="SECONDARY" class="mr-1" (onClick)="doCancel()">
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button
            color="PRIMARY"
            (onClick)="doSave()"
            [disabled]="disableSaveButtons || formSaving"
          >
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
