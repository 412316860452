import { FormControl, FormGroup } from '@angular/forms';
import { Fines } from 'src/app/core/bean/fines';
import { Guarantee } from 'src/app/core/bean/guarantee';
import { ItemType } from 'src/app/core/bean/item-type';
import { Order } from 'src/app/core/bean/order';
import { OrderActivityHistory } from 'src/app/core/bean/order-activity-history';
import { OrderCancellation } from 'src/app/core/bean/order-cancellation';
import { OrderDoc } from 'src/app/core/bean/order-doc';
import { OrderItem } from 'src/app/core/bean/order-item';
import { OrderShipping } from 'src/app/core/bean/order-shipping';
import { OrderShippingItem } from 'src/app/core/bean/order-shipping-item';
import { OrderTc } from 'src/app/core/bean/order-tc';
import { OrderType } from 'src/app/core/bean/order-type';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PrItemReleased } from 'src/app/core/bean/pr-item-released';
import { Sow } from 'src/app/core/bean/sow';
import { Tc } from 'src/app/core/bean/tc';
import { Vendor } from 'src/app/core/bean/vendor';
import { VendorAddress } from 'src/app/core/bean/vendor-address';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { FileObject, FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';

export class OrderModel {
    orderTypeOptionList: OptionListModel<OrderType>;
    fileUploader: FileUploader;
    orderShippingOptionList: OptionListModel<OrderShipping>;
    amount: number;
    goodsAmount: number;
    serviceAmount: number;
    tableResponseVendor: TableResponseModel<Vendor>;
    tableResponseTc: TableResponseModel<OrderTc>;
    tableResponseOrderActHistory: TableResponseModel<OrderActivityHistory>;
    sowList: Sow[] = [];
    prItemReleasedList: PrItemReleased[] = [];
    itemTypeList: ItemType[] = [];
    orderShippingItemList: OrderShippingItem[] = []; 
    isRevisions: boolean;
    isAppSowReady = false;
    tcList: Tc[] = [];
    finesList: Fines[] = [];
    guaranteeList: Guarantee[] = [];
    isSourceReady = false;
    source: string;
    workloadPrcsId: number;
    tableResponseItem: TableResponseModel<any>;
    itemType: FormControl = new FormControl('');
    prItemList: PrItem[] = [];
    delPrItemList: PrItem[] = [];
    itemTypeOptionList: OptionListModel<ItemType>;
    vendorId: number;

    currentPage: number;
    formGroup: FormGroup;


    // new concept
    orderDocList: OrderDoc[] = [];
    order: Order;
    orderShippingList: OrderShipping[] = [];
    vendorAddress: VendorAddress;
    orderTcList: OrderTc[] = [];
    orderActivityHistoryList: OrderActivityHistory[] = [];
    orderCancellation: OrderCancellation;

    fileObjectDocList: FileObject[];
    fileObjectVendorList: FileObject[] = [];


    deleteOrderItemList: OrderItem[] = [];
    deleteOrderTcList: OrderTc[] = [];
    deleteSowList: Sow[] = [];
    deleteOrderShippingList: OrderShipping[] = [];
    vendor: Vendor;
    catalogIdList: number[] = [];

}
