import { createDatePickerDate } from '../helper/create-date-picker-date-helper';
import { createDatePickerDateList } from '../helper/create-date-picker-date-list-helper';
import { createDatePickerTimeList } from '../helper/create-date-picker-time-list-helper';
import { createMinOrMaxDate } from '../helper/create-min-or-max-date-helper';
import { createTotalSelectedDate } from '../helper/create-total-selected-date-helper';
import { DatePickerState } from '../model';
import { DatePickerDateRange } from '../model/date-picker-date-range-model';
import { DatePickerTime } from '../model/date-picker-time-model';
export const firstLoadDatePicker = (state: DatePickerState) => {
  state.monthList =
    'January,February,March,April,May,June,July,August,September,October,November,December'
      .toLowerCase()
      .split(',');
  createMinOrMaxDate(state, 'MIN');
  createMinOrMaxDate(state, 'MAX');
  state.view = 'DATE';
  const date = state.model.range
    ? (state.model.formControl.value && state.model.formControl.value.to) ||
      null
    : state.model.formControl.value;
  state.currentDate = createDatePickerDate(date || new Date());
  state.dateList = createDatePickerDateList(date || new Date());
  if (state.model.range) {
    state.currentDateRange = new DatePickerDateRange();
    if (state.model.formControl.value) {
      state.currentDateRange.start = createDatePickerDate(
        state.model.formControl.value.from
      );
      state.currentDateRange.end = createDatePickerDate(
        state.model.formControl.value.to
      );
      state.currentDateRange.totalSelectedDate = createTotalSelectedDate(
        state.currentDateRange.start,
        state.currentDateRange.end
      );
    }
  }

  if (state.model.time) {
    state.timeList = createDatePickerTimeList();
    state.currentTime = new DatePickerTime();
    if (state.model.formControl.value) {
      const date = new Date(state.model.formControl.value);
      state.currentTime.timeString = date.getHours() + ':' + date.getMinutes();
      state.currentTime.hoursString = `${date.getHours()}`;
      state.currentTime.minutesString = `${date.getMinutes()}`;
    }
  }
};
