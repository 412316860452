import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';
import { Region } from '../../../core/bean/region';
import { Vendor } from '../../../core/bean/vendor';
import { VendorAddress } from '../../../core/bean/vendor-address';
import { VendorHistory } from '../../../core/bean/vendor-history';
import { VendorRepresentativeAgent } from '../../../core/bean/vendor-representative-agent';
import { VendorRepresentativeAgentHistory } from '../../../core/bean/vendor-representative-agent-history';
import { VendorType } from '../../../core/bean/vendor-type';
import { OptionListModel } from '../../../core/model/option-list-model';
import { Validators } from '../../../core/validators';
import { VendorPopupVendorRepAgentDTO } from '../dto/vendor-popup-vendor-rep-agent.dto';

@Component({
  templateUrl: './app-popup-rep-agent.component.html'
})
export class AppPopupRepAgentComponent extends BaseComponentComponent {
  @Input()
  public vendorRepresentativeAgentHistoryEdit: VendorRepresentativeAgentHistory;
  @Input() public vendorRepAgentHistoryList: VendorRepresentativeAgentHistory[];
  @Input() public vendorRepAgentList: VendorRepresentativeAgent[];
  @Input() public vendorRepAgent: VendorRepresentativeAgent;
  @Input() public vendorType: VendorType;
  @Input() public vendorHistory: VendorHistory;
  @Input() public todo: string;
  @Input() public taxNumberList: string[];
  @Input() public companyNameList: string[];
  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();

  public dataSource: any;
  public dataSourceName: string;
  public dataSourceActualName: string;
  public dataSourceActual: number;
  public dataTaxNumber: string;
  public dataAutoComplete: Vendor = new Vendor();
  public mainAddress: VendorAddress = new VendorAddress();
  public vendorPopupVendorRepAgentDTO: VendorPopupVendorRepAgentDTO =
    new VendorPopupVendorRepAgentDTO();
  public codeNumber: string;
  public notFound = false;
  public isVendorList: boolean;
  public fromOutside: string;
  public companyStatusName: string;
  public companyStatusHistoryName: string;

  public companyStatusOptionList: OptionListModel<any> = new OptionListModel();
  public dataSouceOptionsList: OptionListModel<any> = new OptionListModel();
  public regionPhoneOptionList: OptionListModel<Region> = new OptionListModel();

  constructor(public activeModal: NgbActiveModal) {
    super('tab-profile-rep-agent');
  }

  public onInit(): void {
    this.setParam();
    this.getVendorRepAgent();
    this.buildFormGroup();
    this.setFormGroup();
    if (this.todo === 'detail') {
      this.setViewOnly();
    }
  }

  public setParam(): void {
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
    this.isVendorList = this.global.routerParams.get('isVendorList');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      companyStatus: [null, Validators.required()],
      dataSource: [null, Validators.required()],
      companyName: [null, Validators.compose([Validators.required()])],
      taxNumber: [
        null,
        {
          validators:
            this.vendorType.code !==
            this.global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
              ? // tslint:disable-next-line: max-line-length
                [
                  Validators.required(),
                  Validators.npwp(),
                  Validators.exists(this.taxNumberList)
                ]
              : [Validators.required(), Validators.exists(this.taxNumberList)]
        }
      ],
      email: [
        null,
        Validators.compose([Validators.required(), Validators.email()])
      ],
      appPhone: [null, Validators.required()],
      address: [null, Validators.compose([Validators.required()])],
      information: [null, Validators.compose([Validators.required()])],
      coordinateLocation: [null],
      id: [null],
      parentOrChild: [null],
      vendorRepresentativeAgentId: [null]
    });
  }

  public setOptionList(): void {
    const dataSources = [
      { id: 1, name: 'Vendor Promise' },
      { id: 2, name: 'Input Manual' }
    ];
    const companyStatus = [
      { id: 1, name: 'Holding' },
      { id: 2, name: 'Non Holding' }
    ];
    this.dataSouceOptionsList.setRequestValues(dataSources);
    this.companyStatusOptionList.setRequestValues(companyStatus);
  }

  public setFormGroup(): void {
    this.setOptionList();
    this.getAppPopupVendorRepAgentByIdFromServer(
      '/tab-profile-rep-agent/add-edit-popup-vendor-representative-agent'
    ).subscribe(vendorPopupVendorRepAgentDTO => {
      this.vendorPopupVendorRepAgentDTO = vendorPopupVendorRepAgentDTO;
      this.regionPhoneOptionList.stringUrl = '/resource/';
      this.regionPhoneOptionList.fileTypeCode =
        this.global.appConstant.fileType.IMG_ICON;
      this.regionPhoneOptionList.setRequestValues(
        vendorPopupVendorRepAgentDTO.regionPhoneList
      );
      if (this.vendorRepresentativeAgentHistoryEdit !== undefined) {
        if (
          this.vendorRepresentativeAgentHistoryEdit.vendorChild === null &&
          this.vendorRepresentativeAgentHistoryEdit.vendorParent === null
        ) {
          this.dataSource = 2;
        } else {
          this.dataSource = 1;
          this.formGroup.controls['coordinateLocation'].setIsView(true);
        }
        const dataSource = this.dataSource;
        this.codeNumber =
          this.vendorRepresentativeAgentHistoryEdit.phoneRegion.callingCode +
          this.vendorRepresentativeAgentHistoryEdit.phone;
        const appPhone = {
          country: this.vendorRepresentativeAgentHistoryEdit.phoneRegion,
          number: this.vendorRepresentativeAgentHistoryEdit.phone
        };
        const {
          id,
          companyName,
          companyStatus,
          taxNumber,
          email,
          address,
          information,
          coordinateLocation,
          vendorRepresentativeAgentId
        } = this.vendorRepresentativeAgentHistoryEdit;
        this.formGroup.patchValue({
          id,
          companyStatus,
          taxNumber,
          email,
          address,
          information,
          coordinateLocation,
          companyName,
          appPhone,
          dataSource,
          vendorRepresentativeAgentId
        });
        this.dataAutoComplete.taxNumber =
          this.vendorRepresentativeAgentHistoryEdit.taxNumber;
        this.dataAutoComplete.email =
          this.vendorRepresentativeAgentHistoryEdit.email;
        this.dataAutoComplete.phone =
          this.vendorRepresentativeAgentHistoryEdit.phone;
        this.dataAutoComplete.phoneRegion =
          this.vendorRepresentativeAgentHistoryEdit.phoneRegion;
        this.mainAddress.address =
          this.vendorRepresentativeAgentHistoryEdit.address;
        this.mainAddress.coordinateLocation =
          this.vendorRepresentativeAgentHistoryEdit.coordinateLocation;
      }
      this.setStateReady();
    });
  }

  public handleChange(): void {
    this.formGroup.get('dataSource').enable();
  }

  public handleChangeSource(): void {
    this.formGroup.get('companyName').reset();
    this.formGroup.get('email').reset();
    this.formGroup.get('appPhone').reset();
    this.formGroup.get('address').reset();
    this.formGroup.get('information').reset();
  }

  public autoCompleteValueChange(value: any): void {
    this.dataAutoComplete = value;
    this.companyNameList.forEach(companyName => {
      if (companyName === value.name) {
        this.formGroup
          .get('companyName')
          .setErrors({ message: 'app.validation.exists' });
      }
      if (value.name === this.vendorHistory.name) {
        this.formGroup.get('companyName').setErrors({
          message: 'tab-profile-rep-agent.validation.myTaxNumber'
        });
      }
    });
    this.dataTaxNumber = value.taxNumber;
    this.httpClientService
      .post<VendorAddress>(
        '/tab-profile-rep-agent/get-vendor-address-main',
        value.id
      )
      .subscribe(mainAddress => {
        this.mainAddress = mainAddress;
        this.formGroup.patchValue({ parentOrChild: value });
      });
  }

  public onChangeTaxNumber(): void {
    const taxNumber = this.formGroup.get('taxNumber').value;
    if (this.vendorHistory.taxNumber) {
      if (this.vendorHistory.taxNumber === taxNumber) {
        this.formGroup.get('taxNumber').setErrors({
          message: 'tab-profile-rep-agent.validation.myTaxNumber'
        });
      }
    }
  }

  public doChange(): void {
    this.formGroup.get('coordinateLocation').reset();
    this.formGroup.get('taxNumber').reset();
    this.codeNumber = '-';
    if (this.formGroup.get('dataSource').value === 1) {
      this.formGroup.get('taxNumber').clearAsyncValidators();
      this.formGroup.get('taxNumber').updateValueAndValidity();
      this.formGroup.controls['coordinateLocation'].setIsView(true);
    } else {
      if (this.todo === 'edit') {
        if (
          this.vendorType.code !==
          this.global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
        ) {
          this.formGroup
            .get('taxNumber')
            .setValidators([Validators.required(), Validators.npwp()]);
        } else {
          this.formGroup
            .get('taxNumber')
            .setValidators([Validators.required()]);
        }
      } else {
        if (
          this.vendorType.code !==
          this.global.appConstant.vm.VENDOR_TYPE_FOREIGN_COMPANY
        ) {
          this.formGroup
            .get('taxNumber')
            .setValidators([
              Validators.required(),
              Validators.npwp(),
              Validators.exists(this.taxNumberList)
            ]);
        } else {
          this.formGroup
            .get('taxNumber')
            .setValidators([
              Validators.required(),
              Validators.exists(this.taxNumberList)
            ]);
        }
      }
      this.formGroup.get('taxNumber').updateValueAndValidity();
      this.formGroup.controls['coordinateLocation'].setIsView(false);
    }
  }

  public doSearch(): void {
    if (
      this.formGroup.get('taxNumber').value === this.dataAutoComplete.taxNumber
    ) {
      this.formGroup.patchValue({
        email: this.dataAutoComplete.email,
        address: this.mainAddress.address,
        coordinateLocation: this.mainAddress.coordinateLocation,
        appPhone: {
          number: this.dataAutoComplete.phone,
          country: this.dataAutoComplete.phoneRegion
        }
      });
      this.codeNumber =
        this.dataAutoComplete.phoneRegion.callingCode +
        this.dataAutoComplete.phone;
    } else {
      this.global.alertService.showError(
        'tab-profile-rep-agent.validation.dataNotFound',
        '.app-popup-rep-agent'
      );
    }
  }

  public getVendorRepAgent(): void {
    if (this.vendorRepAgentList) {
      this.vendorRepAgentHistoryList.forEach(repAgentHistory => {
        if (repAgentHistory === this.vendorRepresentativeAgentHistoryEdit) {
          this.vendorRepAgentList.forEach(repAgent => {
            if (repAgent.id === repAgentHistory.vendorRepresentativeAgentId) {
              this.vendorRepAgent = repAgent;
              if (
                this.vendorRepAgent.vendorChild === null &&
                this.vendorRepAgent.vendorParent === null
              ) {
                this.dataSourceActual = 2;
              } else {
                this.dataSourceActual = 1;
              }
              this.dataSourceActualName =
                this.dataSourceActual === 1 ? 'Vendor Promise' : 'Input Manual';
              this.companyStatusName =
                this.vendorRepAgent.companyStatus === 1
                  ? 'Holding'
                  : 'Non Holding';
            }
          });
        }
      });
      if (this.vendorRepAgent === undefined) {
        this.vendorRepAgent = new VendorRepresentativeAgent();
      }
    }
    if (this.vendorRepresentativeAgentHistoryEdit !== undefined) {
      if (
        this.vendorRepresentativeAgentHistoryEdit.vendorChild === null &&
        this.vendorRepresentativeAgentHistoryEdit.vendorParent === null
      ) {
        this.dataSource = 2;
      } else {
        this.dataSource = 1;
      }
      this.dataSourceName =
        this.dataSource === 1 ? 'Vendor Promise' : 'Input Manual';
      this.companyStatusHistoryName =
        this.vendorRepresentativeAgentHistoryEdit.companyStatus === 1
          ? 'Holding'
          : 'Non Holding';
    }
  }

  public getAppPopupVendorRepAgentByIdFromServer(
    urlVendorExperiencePopup: string
  ): Observable<VendorPopupVendorRepAgentDTO> {
    return this.httpClientService.post<VendorPopupVendorRepAgentDTO>(
      urlVendorExperiencePopup,
      null
    );
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup.value);
      this.activeModal.dismiss();
    } else if (
      this.formGroup.get('dataSource').value === 1 &&
      !this.formGroup.valid
    ) {
      this.global.alertService.showError(
        'tab-profile-rep-agent.validation.search',
        '.app-popup-rep-agent'
      );
    }
  }
}
