import { BaseEntity } from '../../base/base-entity';
import { ProcurementVendor } from '../../bean/procurement-vendor';
import { ProfitLossStatus } from '../../bean/profit-loss-status';
import { ScoringStatus } from '../../bean/scoring-status';
import { WorkflowModelPrcs } from '../../bean/workflow-model-prcs';

export class VendorQuotationViewEntity extends BaseEntity {
  public workflowModelPrcs: WorkflowModelPrcs;
  public procurementVendor: ProcurementVendor;
  public profitLossStatus: ProfitLossStatus;
  public finalProfitLossStatus: ProfitLossStatus;
  public administrationScoreStatus: ScoringStatus;
  public technicalScoreStatus: ScoringStatus;
  public priceScoreStatus: ScoringStatus;
  public finalPriceScoreStatus: ScoringStatus;
  public bidPrice: number;
  public finalPrice: number;
  public profitLossAmount: number;
  public finalProfitLossAmount: number;
  public technicalScore: number;
  public priceScore: number;
  public finalPriceScore: number;
  public totalEvaluationScore: number;
  public totalFinalEvaluationScore: number;
  public vendorName: string;
  public isReference: boolean;
  /* memiliki komisaris yang sama dengan vendor lain */
  public sameCompanyOrgVendorName: string;
  /* memiliki laporan keuangan */
  public isFinancialReport: boolean;
  /* pernah diblacklist */
  public isBlacklist: boolean;
  /* memiliki dokumen yang expired */
  public isExpiredDoc: boolean;
  /* masa aktif sertifikat vendor kurang dari 90 hari */
  public isCertificateWillBeExpired: boolean;
  /* nilai DER vendor lebih dari 1 */
  public isDer: boolean;
  /* sertifikat vendor sudah expired */
  public isExpiredCertificate: boolean;
  /* harga setelah di alokasikan */
  public finalPriceReference: number;
  /* vendor telah memiliki kontrak katalog  */
  isCatalogContract: number;
  /* catalog name vendor telah memiliki kontrak katalog  */
  catalogName: string;
  submittedDate: Date;
}
