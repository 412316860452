import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { WidgetService } from '../widgets.service';
import { WidgetPrStatusResponse } from './app-widget-pr-status.response';
@Component({
  templateUrl: './app-widget-pr-status.component.html',
  styleUrls: ['./app-widget-pr-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetPrStatusComponent extends BaseComponentComponent {
  public totalPr: number;
  public prDataList: Array<any>;
  public prDataBadge = {
    totalDraft: 'SECONDARY',
    totalWaitingApproval: 'WARNING',
    totalRevision: 'FEEDBACK',
    totalHold: 'DANGER',
    totalWaitingVerification: 'WARNING',
    totalRejected: 'DANGER',
    totalVerified: 'INFO',
    totalDone: 'SUCCESS',
    totalCanceled: 'CANCELED',
    totalOnProgress: 'PROGRESS'
  };
  public routerParamFilter = {
    totalDraft: 'DRAFT',
    totalWaitingApproval: 'WAITING_APPROVAL',
    totalRevision: 'REVISION',
    totalHold: 'HOLD',
    totalWaitingVerification: 'WAITING_VERIFICATION',
    totalRejected: 'REJECTED',
    totalVerified: 'VERIFIED'
  };
  constructor(public widgetService: WidgetService) {
    super('app-widget-pr-status');
  }

  public onInit(): void {
    this.getAndSetPrStatusInformation();
  }

  public getAndSetPrStatusInformation(): void {
    this.httpClientService
      .get<WidgetPrStatusResponse>('/widget-pr-status/index')
      .subscribe(response => {
        if (response.totalPr) {
          this.totalPr = response.totalPr;
        }
        this.prDataList = Object.keys(response).map((key: string) => {
          const strKey = key.slice(5);
          return {
            value: response[key],
            badge: this.prDataBadge[key],
            filter: this.routerParamFilter[key],
            translationKey:
              this.moduleCode +
              '.' +
              strKey.charAt(0).toLowerCase() +
              strKey.slice(1)
          };
        });
        this.prDataList.splice(0, 1);
        this.setStateReady();
      });
  }

  public goToMoreDetail(data): void {
    this.global.routerParams.set('filter', data.filter);
    this.router.navigate(['/pages/pr']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
