import { BaseDTO } from '../../../../core/base/base-dto';
import { Segmentation } from '../../../../core/bean/segmentation';
import { VendorAddress } from '../../../../core/bean/vendor-address';
import { VendorPerformanceOverall } from '../../../../core/bean/vendor-performance-overall';
export class ViewVendorDTO extends BaseDTO {
    vendorName: string;
    performanceRatingList: number[] = [];
    segmentationList: Segmentation[] = [];
    vendorAddress: VendorAddress = new VendorAddress();
    vendorPerformanceOverall: VendorPerformanceOverall = new VendorPerformanceOverall();
    vendorPICEmails: string;
}
