import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementFilter } from '../../core/bean/procurement-filter';
import { ProcurementRegion } from '../../core/bean/procurement-region';
import { ProcurementSegQualification } from '../../core/bean/procurement-seg-qualification';
import { ProcurementVendor } from '../../core/bean/procurement-vendor';
import { AppOfficialReportModel } from '../../core/components/app-official-report/app-official-report-model';
import { AppPopupChooseVendorService } from '../../core/components/app-popup/app-popup-choose-vendor/app-popup-choose-vendor.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { ToastService } from '../../core/components/app-toast/app-toast.service';
import { SelectedTableRecordModel } from '../../core/components/table/model/selected-table-record-model';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { PopupVendorView } from '../../core/view/entity/bean/popup-vendor-view';
import { VendorInvitationEditResponse } from './vendor-invitation-edit.response';
import { VendorInvitationNotificationResponse } from './vendor-invitation-notification.response';
import { VendorInvitationRequest } from './vendor-invitation.request';

@Component({
  templateUrl: './vendor-invitation-edit.component.html',
  encapsulation: ViewEncapsulation.None
})
export class VendorInvitationEditComponent
  extends BaseModuleComponent
  implements OnInit
{
  public tableResponseSeg: TableResponseModel<ProcurementSegQualification>;
  public tableResponseVendor: TableResponseModel<any>;
  public filterVendor: ProcurementFilter = null;

  public badgeColor = {
    DRAFT: 'SECONDARY',
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    ON_PROGRESS: 'PROGRESS',
    DONE: 'SUCCESS',
    FAILED: 'DANGER',
    FAILED_ASSESSMENT: 'SECONDARY',
    WAITING_CANCEL_APPROVAL: 'WARNING',
    CANCELED: 'DANGER',
    WAITING_FAILED_APPROVAL: 'WARNING',
    SAVE: 'SUCCESS',
    LOSS: 'DANGER',
    NEW: 'INFO'
  };

  public response: VendorInvitationEditResponse;
  public procurementId: number;
  public objectId: number; /** for worklist-approval */
  public approvalModelPrcsId: number;
  public urlBackOutside: string;
  public token: string;
  public appOfficialReportModel: AppOfficialReportModel;
  public isOfficialReportNull = false;
  public isUpdated: boolean;

  constructor(
    translateService: TranslateService,
    public appPopupChooseVendorService: AppPopupChooseVendorService,
    public appPopupService: AppPopupService,
    public toastService: ToastService,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute
  ) {
    super('vendor-invitation', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Procurement>('/vendor-invitation/notification/' + this.token)
      .subscribe((response: VendorInvitationNotificationResponse) => {
        const procurement = response.procurement;
        this.approvalModelPrcsId = response.approvalModelPrcsId;

        this.global.routerParams.clear();
        this.global.routerParams.set('procurementId', procurement.id);
        this.global.routerParams.set(
          'urlBackOutside',
          '/pages/vendor-invitation'
        );
        this.global.routerParams.set(
          'approvalModelPrcsId',
          this.approvalModelPrcsId
        );

        if (
          procurement.procurementWorklistStatus.code ===
          this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_NEW
        ) {
          this.global.routerParams.set('todo', 'add');
          this.router.navigate(['/pages/vendor-invitation/edit']);
        } else if (
          [
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT,
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION
          ].includes(procurement.procurementWorklistStatus.code)
        ) {
          this.global.routerParams.set('todo', 'edit');
          this.router.navigate(['/pages/vendor-invitation/edit']);
        } else {
          this.global.routerParams.set('todo', 'view');
          this.router.navigate(['/pages/vendor-invitation/detail']);
        }
      });
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.buildTableResponseSeg();
    this.buildTableResponseVendor();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');

    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.isUpdated =
      this.global.routerParams.get(
        'isUpdated'
      ); /** mengambil isUpdated ketika page di reload setelah save draft */

    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public buildTableResponseSeg(): void {
    this.tableResponseSeg = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementSeg.segmentationName',
        header: 'table.column.segmentation'
      },
      {
        field: 'termQualification.name',
        header: 'table.column.termQualification'
      }, // translate
      {
        field: 'qualification.name',
        header: 'table.column.qualification'
      }
    ]);
  }

  public buildTableResponseVendor(): void {
    this.tableResponseVendor = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.vendorName',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'icon',
            className: 'pic pi-exclamation-triangle',
            onClick: this.onClickVendor.bind(this),
            isShowIconFn: this.showIcon.bind(this)
          }
        ]
      },
      {
        field: 'address',
        header: 'table.column.address'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      },
      {
        field: 'userRefer',
        header: 'table.column.userRefer',
        dataMap:
          '{"true":"' +
          this.global.translateService.instant(
            'vendor-invitation.tableVendor.userRefer.yes'
          ) +
          '","false":"-"}'
      },
      {
        field: 'averageRatingOverall',
        header: 'table.column.performance',
        plugins: {
          name: 'rating',
          isView: true
        },
        className: 'text-center'
      }
    ]);
  }

  public onClickVendor(vendor: PopupVendorView): void {
    if (vendor.blacklistRangeYear || vendor.sameCompanyOrgVendorName) {
      let msg = '<ol class="text-left ml-4">';

      const tkeyBlacklist: string = this.global.translateService.instant(
        'vendor-invitation.popup.warning.msg.blacklist'
      );
      const tkeyCommissioner: string = this.global.translateService.instant(
        'vendor-invitation.popup.warning.msg.commissioner'
      );
      if (vendor.blacklistRangeYear) {
        msg +=
          '<li>' +
          vendor.name +
          ' ' +
          tkeyBlacklist +
          ' ' +
          vendor.blacklistRangeYear +
          '</li>';
      }

      if (vendor.sameCompanyOrgVendorName) {
        let vendorNameList: string[] =
          vendor.sameCompanyOrgVendorName.split(',');
        vendorNameList = [...new Set(vendorNameList)];

        msg +=
          '<li>' +
          vendor.name +
          ' ' +
          tkeyCommissioner +
          ' ' +
          vendorNameList.toString().replace('[', '').replace(']', '') +
          '</li>';
      }
      msg += '</ol>';

      this.global.modalService.confirmation(
        msg,
        'vendor-invitation.modal.vendorWarning.title',
        null,
        null,
        'pvc pv-exclamation-triangle',
        true
      );
    }
  }

  public showIcon(vendor: PopupVendorView): boolean {
    if (vendor.blacklistRangeYear || vendor.sameCompanyOrgVendorName) {
      return true;
    } else {
      return false;
    }
  }

  public isWarningAlert(): boolean {
    return this.tableResponseVendor
      .getRecordList()
      .find(v => v.blacklistRangeYear || v.sameCompanyOrgVendorName)
      ? true
      : false;
  }

  public setFormGroup(): void {
    const url =
      '/vendor-invitation/edit?id=' +
      (this.procurementId ? this.procurementId : '') +
      '&todo=' +
      this.todo;
    this.httpClientService
      .get<VendorInvitationEditResponse>(url, {})
      .subscribe((response: VendorInvitationEditResponse) => {
        this.response = response;
        this.appOfficialReportModel = response.appOfficialReportModel;

        this.tableResponseSeg.setRecordList(
          response.procurementSegQualificationList
        );
        this.tableResponseSeg.reload();

        this.tableResponseVendor.setRecordList(response.popupVendorViewList);
        this.tableResponseVendor.reload();

        if (this.tableResponseVendor.getRecordList().length > 0) {
          this.isUpdated = true;
        }

        if (this.isUpdated) {
          this.appOfficialReportModel.isGenerate = false;
          this.setIsOfficialReportNull();
        }

        this.setStateReady();

        if (this.todo === 'view') {
          this.setViewOnly();
        }
      });
  }

  public doAddVendor(): void {
    const vendorList: PopupVendorView[] =
      this.tableResponseVendor.getRecordList();
    const prIdList: number[] = this.response.procurementItemList.map(
      procItem => procItem?.prItem?.pr?.id
    );

    const procurementSegQualificationList: ProcurementSegQualification[] =
      this.tableResponseSeg.getRecordList();

    const procurementFilter =
      this.filterVendor || this.response.procurementFilter;

    const regionList =
      this.filterVendor && this.filterVendor['regionList']
        ? this.filterVendor['regionList']
        : this.response.procurementRegionList
        ? this.response.procurementRegionList.map(procReg => procReg.region)
        : [];
    const procurementMethodId: number =
      this.response.procurement.procurementMethod.id;

    this.appPopupChooseVendorService
      .openProcurement(
        vendorList,
        prIdList,
        [],
        procurementSegQualificationList,
        procurementFilter,
        regionList,
        true,
        procurementMethodId
      )
      .subscribe(data => {
        this.tableResponseVendor.setRecordList(data.vendorList);
        this.tableResponseVendor.reload();
        this.filterVendor = new ProcurementFilter();
        this.filterVendor.contractNum = data.filterList.totalContract;
        this.filterVendor.contractAmount = data.filterList.contractValue;
        this.filterVendor.minRating = data.filterList.rating;
        this.filterVendor.fromContractDate = data.filterList.startContractDate;
        this.filterVendor.toContractDate = data.filterList.endContractDate;
        this.filterVendor['regionList'] = data.filterList.regionList;
        this.formGroup.markAsDirty();

        this.isUpdated = false; /** trigger to save draft(update data) again */
      });
  }

  public doDeleteVendor(event: SelectedTableRecordModel): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const checkedIdList: number[] = (
            event.selectedRecordList as Array<PopupVendorView>
          ).map(record => record.id);

          this.tableResponseVendor.setRecordList(
            this.tableResponseVendor
              .getRecordList()
              .filter(vendor => !checkedIdList.includes(vendor.id))
          );
          this.tableResponseVendor.reload();
          this.isUpdated =
            false; /** trigger to save draft(update data) again */
        }
      });
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
          '.' +
          data.translationKey.key || '-'
      );
    } else {
      if (this.todo === 'view') {
        return data?.name || '-';
      } else {
        return data?.name;
      }
    }
  }

  public doSaveDraft(): void {
    this.setStateProcessing();

    const url =
      this.todo === 'edit'
        ? '/vendor-invitation/update'
        : '/vendor-invitation/insert';
    this.httpClientService
      .post<Response<VendorInvitationEditResponse>>(
        url,
        this.getProcessedRequest(false)
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.formGroup.markAsPristine();
          this.snackbarService.showWarning('app.alert.msg.saveSuccess');
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.global.routerParams.clear();
              this.global.routerParams.set('procurementId', response.body.id);
              this.global.routerParams.set(
                'urlBackOutside',
                '/pages/vendor-invitation'
              );
              this.global.routerParams.set('todo', 'edit');

              this.isUpdated =
                this.tableResponseVendor.getRecordList().length > 0;

              this.global.routerParams.set('isUpdated', this.isUpdated);

              this.router.navigate(['/pages/vendor-invitation/edit']);
            });
        } else {
          this.toastService.showError(response.statusText);
        }
        this.setStateReady();
      });
  }

  public setIsOfficialReportNull(): void {
    if (
      this.appOfficialReportModel.fileObjectList.length === 0 ||
      this.appOfficialReportModel.fileObjectList.filter(
        fileObject => fileObject.isUploaded
      ).length === 0 ||
      this.appOfficialReportModel.letterDate === null ||
      this.appOfficialReportModel.letterNumber === null
    ) {
      this.isOfficialReportNull = true;
    } else {
      this.isOfficialReportNull = false;
    }
  }

  public doSubmit(): void {
    this.validate();

    const minParticipant = this.response.procurementParticipantList.find(
      ppart =>
        ppart.module.code.toUpperCase() === this.moduleCode.toUpperCase() &&
        ppart.procurementMethod.id ===
          this.response.procurement.procurementMethod?.id
    )?.minimumParticipant;

    const maxParticipant = this.response.procurementParticipantList.find(
      ppart =>
        ppart.module.code.toUpperCase() === this.moduleCode.toUpperCase() &&
        ppart.procurementMethod.id ===
          this.response.procurement.procurementMethod?.id
    )?.maximumParticipant;

    const isVendorResultValid =
      minParticipant &&
      this.tableResponseVendor.getRecordList().length >= minParticipant &&
      maxParticipant &&
      this.tableResponseVendor.getRecordList().length <= maxParticipant;

    const isValid =
      this.tableResponseVendor.getRecordList().length > 0 &&
      isVendorResultValid &&
      this.appOfficialReportModel.letterNumber &&
      this.appOfficialReportModel.letterDate;
    if (isValid) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            const url =
              this.todo === 'edit'
                ? '/vendor-invitation/update'
                : '/vendor-invitation/insert';
            this.httpClientService
              .post<Response<VendorInvitationEditResponse>>(
                url,
                this.getProcessedRequest(true)
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.setStateLoading();
                  this.global.modalService.submitSuccess().subscribe(result => {
                    this.formGroup.markAsPristine();
                    if (result) {
                      this.router.navigate(['/pages/vendor-invitation']);
                    } else {
                      this.router
                        .navigateByUrl('/', { skipLocationChange: true })
                        .then(() => {
                          // this.global.routerParams.clear();
                          this.global.routerParams.set('todo', 'view');
                          this.router.navigate([
                            '/pages/vendor-invitation/detail'
                          ]);
                          this.global.routerParams.set(
                            'urlBackOutside',
                            '/pages/vendor-invitation'
                          );
                        });
                    }
                    this.setStateReady();
                  });
                } else {
                  this.toastService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    } else if (
      !minParticipant ||
      this.tableResponseVendor.getRecordList().length < minParticipant
    ) {
      this.toastService.showError(
        'vendor-invitation.alert.msg.minimumProcurementParticipant'
      );
      // Minimal participant harus sesuai konfigurasi master procurement participant
    } else if (
      !maxParticipant ||
      this.tableResponseVendor.getRecordList().length > maxParticipant
    ) {
      const maxPartMsg =
        this.translateService.instant(
          'vendor-invitation.alert.msg.reachedMaximumParticipant'
        ) +
        ' ' +
        maxParticipant +
        ' ' +
        this.translateService.instant(
          'vendor-invitation.alert.msg.vendorAllowedToInvite'
        );
      this.toastService.showError(maxPartMsg);
    } else if (
      !this.appOfficialReportModel.letterNumber ||
      !this.appOfficialReportModel.letterDate
    ) {
      this.toastService.showError(
        this.translateService.instant(
          'vendor-invitation.alert.msg.officialReportLetterNumberDateFill'
        )
      );
    }
  }

  public getProcessedRequest(isSubmit: boolean): VendorInvitationRequest {
    const request: VendorInvitationRequest = new VendorInvitationRequest();
    request.isSubmit = isSubmit;
    request.procurement = this.response.procurement;

    /** proc vendor list */
    if (this.todo === 'add') {
      this.tableResponseVendor.getRecordList().forEach(vendor => {
        const procVendor = new ProcurementVendor();
        procVendor.vendor.id = vendor.id;
        request.procurementVendorList.push(procVendor);
      });
    } else {
      const existVendorIdList = this.response.procurementVendorList.map(
        pv => pv.vendor.id
      );
      const newVendorList = this.tableResponseVendor
        .getRecordList()
        .filter(v => !existVendorIdList.includes(v.id));

      if (newVendorList.length > 0) {
        newVendorList.forEach(vendor => {
          const procVendor = new ProcurementVendor();
          procVendor.vendor.id = vendor.id;
          procVendor.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
          request.procurementVendorList.push(procVendor);
        });
      }

      const vendorIdList = this.tableResponseVendor
        .getRecordList()
        .map(v => v.id);
      const deleteProcVList = this.response.procurementVendorList.filter(
        pv => !vendorIdList.includes(pv.vendor.id)
      );
      deleteProcVList.forEach(procV => {
        procV.crudOperation =
          this.global.appConstant.core.CRUD_OPERATION_DELETE;
      });
      request.procurementVendorList.push(...deleteProcVList);
    }

    /** proc filter */
    let procurementFilter = null;
    if (!this.response.procurementFilter) {
      procurementFilter = new ProcurementFilter();
    } else {
      procurementFilter = this.response.procurementFilter;
    }
    if (this.filterVendor) {
      procurementFilter.contractAmount = this.filterVendor.contractAmount;
      procurementFilter.contractNum = this.filterVendor.contractNum;
      procurementFilter.fromContractDate = this.filterVendor.fromContractDate
        ? new Date(this.filterVendor.fromContractDate)
        : null;
      procurementFilter.toContractDate = this.filterVendor.toContractDate
        ? new Date(this.filterVendor.toContractDate)
        : null;
      procurementFilter.minRating = this.filterVendor.minRating;
    }

    request.procurementFilter = procurementFilter;

    /** proc_region */
    if (
      this.filterVendor &&
      this.filterVendor['regionList'] &&
      this.filterVendor['regionList'].length > 0
    ) {
      if (this.todo === 'add') {
        this.filterVendor['regionList'].forEach(region => {
          const procurementRegion = new ProcurementRegion();
          procurementRegion.region = region;
          request.procurementRegionList.push(procurementRegion);
        });
      } else {
        const existRegIdList = this.response.procurementRegionList.map(
          preg => preg.region.id
        );

        const newRegList = this.filterVendor['regionList'].filter(
          reg => !existRegIdList.includes(reg.id)
        );
        newRegList.forEach(reg => {
          const procurementRegion = new ProcurementRegion();
          procurementRegion.region = reg;
          procurementRegion.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
          request.procurementRegionList.push(procurementRegion);
        });

        const regIdList = this.filterVendor['regionList'].map(reg => reg.id);
        const deleteProcRegList = this.response.procurementRegionList.filter(
          preg => !regIdList.includes(preg.region.id)
        );
        deleteProcRegList.forEach(procReg => {
          procReg.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
        });
        request.procurementRegionList.push(...deleteProcRegList);
      }
    }

    this.setIsOfficialReportNull();
    request.appOfficialReportModel = this.appOfficialReportModel;

    return request;
  }

  public doOnChange(event: AppOfficialReportModel): void {
    this.appOfficialReportModel = event;
  }
}
