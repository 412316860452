import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupVendorBankAlertComponent } from './popup/app-popup-vendor-bank-alert.component';
import { AppPopupVendorBankComponent } from './popup/app-popup-vendor-bank.component';
import { TabProfileBankComponent } from './tab-profile-bank.component';
export const routes = [
  {
    component: TabProfileBankComponent,
    data: { breadcrumb: '' },
    path: ''
  }
];

@NgModule({
  declarations: [
    TabProfileBankComponent,
    AppPopupVendorBankAlertComponent,
    AppPopupVendorBankComponent
  ],
  entryComponents: [
    AppPopupVendorBankAlertComponent,
    AppPopupVendorBankComponent
  ],
  exports: [
    TabProfileBankComponent,
    AppPopupVendorBankAlertComponent,
    AppPopupVendorBankComponent
  ],
  imports: [SharedModule, RouterModule.forChild(routes)]
})
export class TabProfileBankModule {}
