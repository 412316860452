import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Workflow } from 'src/app/core/bean/workflow';
import { WorkflowModelPrcs } from 'src/app/core/bean/workflow-model-prcs';
import { WorkflowPrcs } from 'src/app/core/bean/workflow-prcs';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import {
  TableHeaderPluginData,
  TablePluginData
} from '../../core/components/table/interface/table-plugin-data';
import { Adjustment } from './../../core/bean/adjustment';
import { TransactionLog } from './../../core/bean/transaction-log';
import { AppPopupAdjustmentPmComponent } from './app-popup-adjustment-pm.component';
import { SLAPmRequest } from './sla-pm-request';
import { SLAPmResponse } from './sla-pm-response';

@Component({
  templateUrl: './sla-pm-detail.component.html'
})
export class SlaPmDetailComponent extends BaseModuleComponent {
  public workflowPrcsId: number;
  public historyType: string;
  public slaPmResponse: SLAPmResponse = new SLAPmResponse();
  public slaPmRequest: SLAPmRequest = new SLAPmRequest();

  public totalMain: string;
  public totalAdditional: string;
  public totalApproval: string;
  public maximumAdjustment: number;
  public error: string;
  public type: string;
  public title: string;
  public roleCode: string;
  public totalRealizationWorkflowMain: string;
  public totalRealizationSlaHistory: string;
  public adjustmentPopup: Adjustment = new Adjustment();
  public tableResponseSlaReport: TableResponseModel<WorkflowModelPrcs>;
  public tableResponseHistorySla: TableResponseModel<TransactionLog>;
  public tableResponseHistoryAdjusment: TableResponseModel<Adjustment>;
  public workflowList: OptionListModel<String> = new OptionListModel(true);

  public transactionLog: TransactionLog[] = [];
  public adjustment: Adjustment[] = [];
  public workflowModelPrcsMain: WorkflowModelPrcs[] = [];
  public workflowBeanList: Workflow[] = [];
  public getWorkflowList: string[] = [];
  public filterWorkflowList: string[] = [];

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('sla-pm', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setDataTable();
    this.buildTableResponse();
  }

  public setDataFromRouterParams(): void {
    this.getWorkflowList = [
      'Main Workflow',
      'Approval Workflow',
      'Additional Workflow'
    ];
    this.workflowList.setRequestValues(this.getWorkflowList);
    this.workflowPrcsId = this.global.routerParams.get('workflowPrcsId');
    this.historyType = this.global.routerParams.get('historyType');

    if (this.historyType === 'procurement') {
      this.title = this.translateService.instant(
        'sla-pm.title.detail.procurement'
      );
    }
    if (this.historyType === 'purchase request') {
      this.title = this.translateService.instant(
        'sla-pm.title.detail.purchaserequest'
      );
    }
    if (this.historyType === 'rfp') {
      this.title = this.translateService.instant('sla-pm.title.detail.rfp');
    }
    if (this.historyType === 'rfq') {
      this.title = this.translateService.instant('sla-pm.title.detail.rfq');
    }
    if (this.historyType === 'order process') {
      this.title = this.translateService.instant(
        'sla-pm.title.detail.orderprocess'
      );
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      workflow: [null],
      historyType: this.historyType,
      workflowPrcsId: this.workflowPrcsId
    });
  }

  public setDataTable(): void {
    this.slaPmRequest.historyType = this.historyType;
    this.slaPmRequest.workflowPrcsId = this.workflowPrcsId;
    this.slaPmRequest.workflowList = this.formGroup.value.workflow;
    this.httpClientService
      .post<SLAPmResponse>('/sla-pm/detail', this.slaPmRequest)
      .subscribe(slaReportResponse => {
        this.slaPmResponse = slaReportResponse;

        // Table Main Workflow
        this.totalRealizationWorkflowMain =
          this.slaPmResponse.totalRealizationWorkflowMain;
        this.tableResponseSlaReport.setRecordList(
          this.slaPmResponse.workflowModelPrcsMainList
        );
        this.tableResponseSlaReport.reload();

        this.totalMain = this.slaPmResponse.totalRealizationWorkflowMain;

        this.transactionLog = this.slaPmResponse.transactionLogList;
        this.transactionLog.forEach(transactionLog => {
          if (
            transactionLog.approvalSequence !== null &&
            transactionLog.approvalSequence
          ) {
            transactionLog.name =
              transactionLog.name + ' Lv ' + transactionLog.approvalSequence;
          }
        });
        this.totalRealizationSlaHistory =
          this.slaPmResponse.totalRealizationSlaHistory;
        this.tableResponseHistorySla.setRecordList(this.transactionLog);
        this.tableResponseHistorySla.reload();

        // Table Adjustment
        this.adjustment = slaReportResponse.adjustmentList;
        this.adjustment.forEach(adjustment => {
          if (adjustment !== null) {
            let name = '';
            if (adjustment.approvalModelPrcs !== null) {
              name =
                adjustment.approvalModelPrcs.approvalPrcs.workflowModelPrcs
                  .name;
              if (
                adjustment.approvalModelPrcs.sequence !== 0 &&
                adjustment.approvalModelPrcs.sequence !== null
              ) {
                name +=
                  ' Lv ' +
                  adjustment.approvalModelPrcs.sequence +
                  ' - ' +
                  adjustment.approvalModelPrcs.approvedByUser.name;
              }
              adjustment.stageName = name;
            } else {
              adjustment.stageName = adjustment.workflowModelPrcs.name;
            }
          }
        });
        this.tableResponseHistoryAdjusment.setRecordList(this.adjustment);
        this.tableResponseHistoryAdjusment.reload();
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponseSlaReport = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.mainWorkflow.column.stage',
        plugins: {
          name: 'hyperlink',
          onClick: this.doAdjustmentWorkflow.bind(this)
        }
      },
      {
        field: 'pic',
        header: {
          key: 'table.mainWorkflow.column.pic',
          plugins: {
            name: 'custom-plugin',
            after: (data: TableHeaderPluginData) => {
              data.element.style.maxWidth = '150px';
            }
          }
        },
        plugins: {
          name: 'custom-plugin',
          after: (data: TablePluginData) => {
            data.element.style.maxWidth = '150px';
          }
        }
      },
      { field: 'moduleCode', header: 'table.mainWorkflow.column.workflow' },
      {
        header: 'table.column.date',
        columnList: [
          {
            field: 'startDate',
            header: 'table.mainWorkflow.column.startDate',
            plugins: {
              name: 'date',
              format: 'short-date-and-time'
            }
          },
          {
            field: 'endDate',
            header: 'table.mainWorkflow.column.endDate',
            plugins: {
              name: 'date',
              format: 'short-date-and-time'
            }
          },
          {
            field: 'processedDate',
            header: 'table.mainWorkflow.column.realizationDate',
            plugins: {
              name: 'date',
              format: 'short-date-and-time'
            }
          }
        ]
      },
      {
        field: 'slaInDays',
        header: 'table.mainWorkflow.column.sla',
        plugins: {
          name: 'custom-plugin',
          before: (tablePluginData: TablePluginData): string => {
            if (tablePluginData.value !== 0 && tablePluginData.value !== null) {
              return (
                tablePluginData.value +
                ' ' +
                this.translateService.instant('sla-vendor.day')
              );
            } else {
              return '-';
            }
          }
        }
      },
      {
        field: 'slaRealizedName',
        header: 'table.mainWorkflow.column.realization'
      },
      {
        field: 'slaRealizedStatusName',
        header: 'table.column.statusSla',
        className: 'text-center',
        plugins: {
          name: 'badge',
          colorField: 'slaRealizedStatus',
          colorMap: {
            0: 'SUCCESS',
            '-1': 'DANGER',
            1: 'PRIMARY',
            null: 'WARNING'
          }
        }
      }
    ]);

    const DELEGATED = this.translateService.instant(
      'dynamic-master-attribute.sla.historySla.flag.delegated'
    );
    this.tableResponseHistorySla = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.history.column.stage' },
      {
        field: 'processedByUser.name',
        header: 'table.history.column.pic',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'badge',
            field: 'isDelegated',
            pill: false,
            className: 'badge-catalog badge-danger ml-3',
            colorMap: {
              DELEGATED: 'DANGER'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.row.record.isDelegated) {
                  return DELEGATED;
                }
              }
            }
          },
          {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData): void => {
              if (!tablePluginData.row.record.isDelegated) {
                tablePluginData.element.children[1].remove();
              }
            }
          }
        ]
      },
      {
        header: 'table.column.date',
        columnList: [
          {
            field: 'startDate',
            header: 'table.mainWorkflow.column.startDate',
            plugins: {
              name: 'date',
              format: 'short-date-and-time'
            }
          },
          {
            field: 'endDate',
            header: 'table.mainWorkflow.column.endDate',
            plugins: {
              name: 'date',
              format: 'short-date-and-time'
            }
          },
          {
            field: 'processedDate',
            header: 'table.mainWorkflow.column.realizationDate',
            plugins: {
              name: 'date',
              format: 'short-date-and-time'
            }
          }
        ]
      },
      {
        field: 'slaInDays',
        header: 'table.mainWorkflow.column.sla',
        plugins: {
          name: 'custom-plugin',
          before: (tablePluginData: TablePluginData): string => {
            if (tablePluginData.value !== 0 && tablePluginData.value !== null) {
              return (
                tablePluginData.value +
                ' ' +
                this.translateService.instant('sla-vendor.day')
              );
            } else {
              return '-';
            }
          }
        }
      },
      {
        field: 'slaRealizedName',
        header: 'table.history.column.realization'
      }
    ]);

    this.tableResponseHistoryAdjusment = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'adjustmentBy',
          header: 'table.adjustment.column.adjustmentBy'
        },
        {
          field: 'createdDate',
          header: 'table.adjustment.column.adjustmentDate',
          plugins: {
            name: 'date',
            format: 'short-date-and-time'
          }
        },
        {
          field: 'stageName',
          header: 'table.adjustment.column.stage'
        },
        {
          header: 'table.column.adjustment',
          columnList: [
            {
              field: 'beforeAdjustmentName',
              header: 'table.adjustment.column.beforeAdjustment',
              className: 'text-center'
            },
            {
              field: 'totalAdjustmentName',
              header: 'table.adjustment.column.totalAdjustment',
              className: 'text-center'
            },
            {
              field: 'afterAdjustmentName',
              header: 'table.adjustment.column.afterAdjustment',
              className: 'text-center'
            }
          ]
        },
        {
          field: 'description',
          header: 'table.adjustment.column.description'
        }
      ]
    );
  }

  public doAdjustmentWorkflow(workflowModelPrcs: WorkflowModelPrcs): void {
    if (this.slaPmResponse.isAdjustmentEdit) {
      this.type = 'workflow';
      this.adjustmentPopup.workflowModelPrcs = workflowModelPrcs;
      this.adjustmentPopup.workflowPrcs = workflowModelPrcs.workflowPrcs;
      this.adjustmentPopup.slaRealizedBeforeAdjustmentInMinutes =
        workflowModelPrcs.slaRealizedInMinutes;
      this.adjustmentPopup.beforeAdjustmentWorkingInDays =
        workflowModelPrcs.slaRealizedWorkingInDays;
      this.adjustmentPopup.beforeAdjustmentWorkingInHours =
        workflowModelPrcs.slaRealizedWorkingInHours;
      this.adjustmentPopup.beforeAdjustmentWorkingInMinutes =
        workflowModelPrcs.slaRealizedWorkingInMinutes;

      if (workflowModelPrcs.slaRealizedStatus != null) {
        this.appPopupService
          .open(
            AppPopupAdjustmentPmComponent,
            {
              adjustmentPopup: this.adjustmentPopup,
              type: this.type,
              historyType: this.historyType
            },
            { size: 'lg', backdrop: 'static' }
          )
          .subscribe(() => {
            this.doGetListAdjustment(workflowModelPrcs.workflowPrcs);
          });
        this.adjustmentPopup = new Adjustment();
      }
    }
  }

  public doGetListAdjustment(workflowPrcs: WorkflowPrcs): void {
    /*this.tableResponseAdjustment.setStateWatingFromServer();*/
    this.httpClientService
      .post<Adjustment[]>('/sla-pm/get-list-adjustment', workflowPrcs)
      .subscribe((adjustmentList: Adjustment[]) => {
        this.adjustment = adjustmentList;
        this.adjustment.forEach((adjustment: Adjustment) => {
          if (adjustment !== null) {
            let name = '';
            if (adjustment.approvalModelPrcs !== null) {
              name =
                adjustment.approvalModelPrcs.approvalPrcs.workflowModelPrcs
                  .name;
              if (
                adjustment.approvalModelPrcs.sequence !== 0 &&
                adjustment.approvalModelPrcs.sequence !== null
              ) {
                name +=
                  ' Lv ' +
                  adjustment.approvalModelPrcs.sequence +
                  ' - ' +
                  adjustment.approvalModelPrcs.approvedByUser.name;
              }
              adjustment.stageName = name;
            } else {
              adjustment.stageName = adjustment.workflowModelPrcs.name;
            }
          }
        });
        this.tableResponseHistoryAdjusment.setRecordList(this.adjustment);
        this.tableResponseHistoryAdjusment.reload();
      });
  }

  public setDataTableSlaReport(): void {
    this.httpClientService
      .post<SLAPmResponse>(
        '/sla-pm/detailSlaReport',
        this.tableResponseSlaReport
      )
      .subscribe(slaReportResponse => {
        this.slaPmResponse = slaReportResponse;

        // Table Main Workflow
        this.totalRealizationWorkflowMain =
          this.slaPmResponse.totalRealizationWorkflowMain;
        this.tableResponseSlaReport.setRecordList(
          this.slaPmResponse.workflowModelPrcsMainList
        );
        /*this.tableResponseSlaReport.setCustomData(this.formGroup.value);*/
        this.tableResponseSlaReport.reload();

        this.totalMain = this.slaPmResponse.totalRealizationWorkflowMain;
        this.setStateReady();
      });
  }

  public onChangeFilter(): void {
    this.tableResponseSlaReport.setCustomData(this.formGroup.value);
    this.setDataTable();
  }
}
