import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
const widgetWrapperSelector = '.widget-wrapper';
@Directive({
  selector: widgetWrapperSelector
})
export class WidgetWrapperDirective {
  @Output() output: EventEmitter<any> = new EventEmitter();
  constructor() {}

  @HostListener('drop', ['$event'])
  handleDrop(event: any): void {
    event.preventDefault();
    const transfer = event.dataTransfer;
    this.output.emit(transfer.getData('text'));
  }

  @HostListener('dragover', ['$event'])
  handleDragOver(event: any): void {
    event.preventDefault();
  }

  @HostListener('ondragstart', ['$event'])
  handleOnDragStart(): void {}
}
