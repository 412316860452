import { BaseEntity } from '../base/base-entity';
import { Region } from '../bean/region';
import { Vendor } from '../bean/vendor';
import { VendorHistory } from '../bean/vendor-history';

export class VendorRepresentativeAgentHistoryEntity extends BaseEntity {
    crudInfo: string;

    phoneNumber: string;
    vendorHistory: VendorHistory;
    createdDate: Date;
    vendorChild: Vendor;
    vendorParent: Vendor;
    companyName: string;
    taxNumber: string;
    address: string;
    phoneRegion: Region;
    phone: string;
    email: string;
    information: string;
    coordinateLocation: string;
    companyStatus: number;
    vendorRepresentativeAgentId: number;
}
