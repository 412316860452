import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Organization } from '../../core/bean/organization';
import { Project } from '../../core/bean/project';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { ReportService } from '../../core/services/report.service';
import { WorkPlan } from './../../core/bean/work-plan';
import { FieldFormatEnum } from './../../core/components/app-table/model/field-format.enum';
import { ProjectResponse } from './project-response';
@Component({
  templateUrl: './project-detail.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ProjectDetailComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Project>;

  project: Project = new Project();
  url: string;
  projectId: number;
  organization: Organization = new Organization();
  organizationId: number;
  projectResponse: ProjectResponse = new ProjectResponse();
  workPlanList: WorkPlan[] = [];

  @ViewChild(AppTableComponent) table: AppTableComponent;

  constructor(translateService: TranslateService, public reportService: ReportService) {
    super('project', translateService);
  }

  onInit(): void {
    this.todo = this.global.routerParams.get('todo');
    this.projectId = this.global.routerParams.get('projectId');
    this.organizationId = this.global.routerParams.get('organizationId');

    this.formGroup = this.formBuilder.group({
      name: [null],
      organization: [null],
      organizationName: [null],
      description: [null]
    });

    this.httpClientService.post<ProjectResponse>('/project/detail', new RouteRequestModel(this.todo, this.projectId))
      .subscribe(projectResponse => {
        this.projectResponse = projectResponse;
        if (this.projectResponse.project != null) {
          this.workPlanList = this.projectResponse.workPlanList;
          this.formGroup.patchValue({
            name: projectResponse.project.name,
            organization: projectResponse.project.organization,
            organizationName: projectResponse.project.organization.name,
            description: projectResponse.project.description
          });
        }

        this.setStateReady();
      });

    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'number', header: 'table.column.number' },
      { field: 'name', header: 'table.column.name' },
      { field: 'organization.name', header: 'table.column.organization' },
      { field: 'totalPriceItem', header: 'table.column.totalPriceItem', sortable: false, format: FieldFormatEnum.Currency }
    ]);
  }

  public doCancel(): void {
    this.router.navigate(['/pages/project']);
  }

  public doReport(project: Project): void {
    this.reportService.print('/project/print/report', project);
  }
}
