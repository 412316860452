import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Token } from '../../core/bean/token';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { AuthenticationService } from '../../core/services/authentication.service';
import { RecapPrintService } from '../../core/services/recap-print.service';
@Component({
  templateUrl: 'login-log.component.html'
})
export class LoginLogComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Token>;
  public isLoading: boolean;
  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService,
    public recapPrintService: RecapPrintService,
    public authenticationService: AuthenticationService
  ) {
    super('login-log', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.doBuildFormGroup();
    setTimeout(() => {
      this.setStateReady();
    }, 1000);
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'user.name',
        header: 'table.column.name',
        customClass: 'text-center'
      },
      {
        field: 'loginAt',
        header: 'table.column.loginAt',
        format: FieldFormatEnum.ShortDateAndTime
      },
      {
        field: 'logoutAt',
        header: 'table.column.logoutAt',
        format: FieldFormatEnum.ShortDateAndTime
      },
      { field: 'ip', header: 'table.column.ip', customClass: 'text-center' },
      {
        field: 'statusText',
        header: 'table.column.status',
        sortable: false,
        format: FieldFormatEnum.Html,
        customClass: 'text-center'
      }
    ]);
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      loginDateFrom: [null],
      loginDateTo: [null],
      logoutDateFrom: [null],
      logoutDateTo: [null],
      organizationId: [null],
      organizationName: [null]
    });
  }

  public doApply(): void {
    const formGroupValue = this.formGroup.value;
    if (
      (formGroupValue.loginDateFrom === null &&
        formGroupValue.loginDateTo === null) ||
      (formGroupValue.logoutDateFrom === null &&
        formGroupValue.logoutDateTo === null)
    ) {
      this.global.alertService.showError(
        this.translateService.instant('login-log.validation.chooseDate')
      );
    } else {
      this.tableResponse.setCustomData(this.formGroup.value);
      this.tableResponse.reload();
    }
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }

  public doExport(): void {
    this.isLoading = true;
    this.recapPrintService
      .print('/login-log/recap-login-log', this.tableResponse.request)
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  public onChooseOrganization(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(organization => {
        const { name: organizationName, id: organizationId } =
          organization.pop();
        this.formGroup.patchValue({ organizationName, organizationId });
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
      });
  }

  public doForceLogout(token: Token): void {
    this.global.modalService
      .confirm(
        this.translateService.instant('login-log.confirmation.logout.msg') +
          ' ' +
          token.user.name +
          '?',
        this.translateService.instant('login-log.confirmation.logout.title')
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.authenticationService.logout(token.token);
          this.tableResponse.reload();
          this.global.alertService.showSuccess(
            token.user.name +
              ' ' +
              this.translateService.instant(
                'login-log.alert.msg.forceLogoutSuccess'
              )
          );
          this.setStateReady();
        }
      });
  }
}
