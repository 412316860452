import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAlertXModule } from '../app-alert-x/app-alert-x.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppComboBoxModule } from '../app-combo-box/app-combo-box.module';
import { AppDropdownSelectModule } from '../app-dropdown-select/app-dropdown-select.module';
import { AppFullfilledModule } from '../app-fullfilled/app-fullfilled.module';
import { AppTableXModule } from '../app-table-x/components/app-table-x/app-table-x.module';
import { TableModule } from '../table/table.module';
import { AppItemRequestReleasedComponent } from './app-item-request-released.component';

@NgModule({
  imports: [
    VendorModule,
    AppTableXModule,
    TableModule,
    AppCardModule,
    AppComboBoxModule,
    PipesModule,
    AppButtonModule,
    AppDropdownSelectModule,
    AppFullfilledModule,
    AppAlertXModule
  ],
  declarations: [AppItemRequestReleasedComponent],
  exports: [AppItemRequestReleasedComponent]
})
export class AppItemRequestReleasedModule {}
