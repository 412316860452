<app-layout [backTo]="urlBackOutside" [isLoading]="formLoading">
  <ng-template #additionalTitle *ngIf="!formLoading && isReady">
    <app-badge
      *ngIf="todo !== 'add'"
      class="cml-2"
      [color]="badgeColor[contractTermination?.terminationStatus.code]"
    >
      {{ getTranslateKey(contractTermination?.terminationStatus) | translate }}
    </app-badge>
    <app-badge *ngIf="todo === 'add'" class="cml-2" color="INFO">
      {{ 'contract-termination.badge.status.new' | translate }}
    </app-badge>
  </ng-template>
  <app-alert-x
    *ngIf="
      !formLoading &&
      isReady &&
      contractTermination?.terminationStatus.code ===
        global.appConstant.cm.CONTRACT_STATUS_TERMINATED
    "
    color="DANGER"
    [isBold]="false"
  >
    {{
      'contract-termination.alert.msg.thisProcessWasTerminatedBy' | translate
    }}
    <strong>{{ response.contractTermination?.terminatedByUser?.name }}</strong>
    {{ 'contract-termination.alert.msg.at' | translate }}
    <strong>
      {{
        response.contractTermination?.terminationDate
          | date: global.config.parameterModel.datetimeFormatShort
      }}
    </strong>
    {{ 'contract-termination.alert.msg.theTerminationReasonIs' | translate }}
    <strong>&#34;{{ response.contractTermination?.description }}&#34;</strong>
  </app-alert-x>
  <app-alert-x
    [isShowIcon]="false"
    color="INFO"
    *ngIf="!formLoading && contract.dr && isReady"
  >
    <div class="row">
      <div class="col-sm-1 pvc pv-box-delivery"></div>
      <div class="col-sm-5" style="margin-top: 10px">
        <span class="text-primary" style="font-size: 1rem">
          {{ 'contract-termination.hyperlink.monitoringDelivery' | translate }}
        </span>
      </div>
      <div class="col-sm-6">
        <app-button
          class="button-group button-group-right"
          (onClick)="goToDeliveryMonitoring($event)"
        >
          {{ 'contract-request.button.viewDetail' | translate }}
        </app-button>
      </div>
    </div>
  </app-alert-x>
  <app-contract-request-info
    *ngIf="!formLoading"
    [contractId]="contractId"
  ></app-contract-request-info>
  <app-work-definition
    *ngIf="!formLoading"
    [contractId]="contractId"
  ></app-work-definition>
  <form *ngIf="!formLoading && isReady" [formGroup]="formGroup">
    <app-card
      *ngIf="!formLoading"
      header="contract-finalization.detail.card.contractFinalization.title"
      tips="contract-finalization.detail.card.contractFinalization.tips"
    >
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-finalization.detail.form.finalContract' | translate }}
        </label>
        <div class="col-sm-7 text-left">
          <div
            style="
              margin: 0 auto;
              display: flex;
              position: relative;
              border-radius: 5px;
              box-sizing: border-box;
              height: 78px;
              border: 1px solid var(--gray-400);
            "
          >
            <div
              style="
                width: 60px;
                height: 60px;
                overflow: hidden;
                border-radius: 60px;
                display: contents;
              "
              class="mr-3"
            >
              <img
                [attr.src]="
                  '/assets/component/fileuploader/logo-icon/' +
                  model.docFile.extension.toLowerCase() +
                  '.png'
                "
                [attr.alt]="model.docFile.fileName"
                [default]="global.appConstant.core.URL_DEFAULT_ICON"
                class="img-thumbnail"
                style="width: 60px; height: 60px; border: none"
              />
            </div>

            <div style="flex: 1; padding: 0.25rem 1rem" class="d-inline-block">
              <a
                class="item-content-name"
                href="#"
                (click)="
                  doDownload(
                    $event,
                    '/contract-draft/file/view/' +
                      model.docFile.uploadedFileName +
                      '/' +
                      model.docFile.fileType.code,
                    model.docFile.fileName
                  )
                "
                maxLine
                [attr.title]="model.docFile.fileName"
              >
                {{ model.docFile.fileName }}
              </a>
              <ng-container>
                <span class="d-inline-flex">
                  <strong class="mr-1">{{ model.version }}</strong>
                  <span class="item-content-size">
                    {{ model.docFile.size / 1000000 | number: '1.2-2' }} MB
                  </span>
                </span>
                <p class="text-secondary">
                  {{ 'app-popup-version-history.form.uploadedBy' | translate }}
                  {{ model.uploaderName }}
                  {{ 'app-popup-version-history.form.at' | translate }}
                  {{
                    model.lastModifiedDate
                      | date: global.appConstant.core.FORMAT_SHORT_DATE_AND_TIME
                  }}
                </p>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-finalization.detail.form.documentVersion' | translate }}
        </label>
        <div class="col-sm-6 text-left">
          <label>Ver.&nbsp;{{ contractNegotiationHistory.sequence }}</label
          >&nbsp;
          <span
            class="hyperlink-text cursor-pointer"
            (click)="onClickVersionHistory()"
          >
            <strong>
              {{
                'contract-finalization.detail.from.viewVersionHistory'
                  | translate
              }}
            </strong>
          </span>
        </div>
      </div>
      <div
        *ngIf="
          (!formLoading && !response.module.isNumberGenerator) ||
          (!formLoading && isView)
        "
        class="form-group row text-sm-left"
      >
        <label class="col-sm-3">{{
          'contract-finalization.detail.form.contractNumber' | translate
        }}</label>
        <div class="col-sm-6 text-left">
          <app-text-generated
            formControlName="contractNumber"
            maxLength="64"
            [moduleCode]="moduleCode"
            numberGeneratorCode="CONTRACT_NUMBER"
            autofocus="true"
          ></app-text-generated>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-finalization.detail.form.contractPeriod' | translate }}
        </label>
        <div class="col-sm-6 text-left">
          <app-date-picker-x
            size="XL"
            formControlName="date"
            [range]="true"
            minDate="currentDate"
            maxDate="endDateValidate"
          >
          </app-date-picker-x>
        </div>
      </div>
    </app-card>
  </form>

  <app-card
    *ngIf="!formLoading && isReady"
    header="contract-termination.detail.card.document.title"
    tips="contract-termination.detail.card.document.tips"
  >
    <ng-template #headerRight>
      <app-button
        (onClick)="doViewReviewer()"
        *ngIf="roleCode !== global.appConstant.ROLE_CODE_VENDOR"
        >{{
          'contract-termination.detail.button.contractReviewer' | translate
        }}</app-button
      >
    </ng-template>
    <app-table-xx [model]="tableResponse"></app-table-xx>
  </app-card>

  <app-vendor-information
    *ngIf="!formLoading"
    [vendorId]="contract.vendor.id"
  ></app-vendor-information>

  <form *ngIf="todo !== 'add' && isReady" [formGroup]="formGroupTermination">
    <app-card
      *ngIf="!formLoading"
      header="contract-termination.detail.card.contractTermination.title"
      tips="contract-termination.detail.card.contractTermination.tips"
    >
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-termination.detail.form.terminationDate' | translate }}
        </label>
        <div class="col-sm-6 text-left">
          <app-date-picker-x
            formControlName="terminationDate"
            [minDate]="startDate"
            [maxDate]="endDate"
            size="XL"
          >
          </app-date-picker-x>
        </div>
      </div>
      <div
        *ngIf="
          (!formLoading && !response.module.isNumberGenerator) ||
          (!formLoading && formGroupTermination.isView)
        "
        class="form-group row text-sm-left"
      >
        <label class="col-sm-3">{{
          'contract-termination.detail.form.terminationNumber' | translate
        }}</label>
        <div class="col-sm-6 text-left">
          <app-text-generated
            formControlName="code"
            maxLength="64"
            [moduleCode]="moduleCode"
            numberGeneratorCode="TERMINATION_NUMBER"
            autofocus="true"
            size="XL"
          ></app-text-generated>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'contract-termination.detail.form.terminationReason' | translate
        }}</label>
        <div class="col-sm-6 text-left">
          <app-text-area
            formControlName="description"
            size="XL"
            maxLength="2000"
          >
          </app-text-area>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-termination.detail.form.attachment' | translate }}
        </label>
        <div class="col-sm-6 text-left">
          <app-upload-x
            formControlName="file"
            [fileUploader]="fileUploader"
            [maxFile]="5"
            [isSingle]="false"
            size="XL"
          ></app-upload-x>
        </div>
      </div>
    </app-card>
  </form>

  <app-card
    *ngIf="
      !formLoading &&
      todo !== 'add' &&
      isReady &&
      contractTermination?.terminationStatus.code !==
        global.appConstant.cm.CONTRACT_STATUS_WAITING_APPROVAL
    "
    header="contract-termination.detail.card.activityHistory.title"
    tips="contract-termination.detail.card.activityHistory.tips"
  >
    <app-activity-history-view
      *ngIf="todo !== 'add'"
      [contractId]="contractId"
      [activityType]="1"
    >
    </app-activity-history-view>
  </app-card>

  <app-approval-monitoring
    *ngIf="
      !formLoading &&
      isReady &&
      roleCode !== global.appConstant.ROLE_CODE_VENDOR &&
      response?.isProcessed &&
      (((todo === 'view' || todo === 'monitoring') &&
        response &&
        response.approvalPathResponseModel &&
        !response.isUserApproval) ||
        contractTermination?.terminationStatus.code ===
          global.appConstant.cm.CONTRACT_WORKLIST_STATUS_REVISION)
    "
    [workflowPrcs]="contractTermination?.workflowPrcs"
    [moduleCode]="moduleCode"
    [approvalPathResponseModel]="response.approvalPathResponseModel"
  >
  </app-approval-monitoring>
  <app-approval-prcs-x
    *ngIf="!formLoading && response.isUserApproval"
    [workflowModelPrcs]="contractTermination?.workflowModelPrcs"
    [approvalModelPrcsId]="approvalModelPrcsId"
  >
  </app-approval-prcs-x>

  <div
    class="floating-button mt-5"
    *ngIf="
      (!formLoading && todo === 'add') ||
      (!formLoading &&
        isReady &&
        contractTermination?.terminationStatus.code ===
          global.appConstant.cm.CONTRACT_STATUS_REVISION)
    "
  >
    <div class="floating-button-wrapper">
      <div class="floating-button-content">
        <div class="button-group button-group-center">
          <app-button
            color="DANGER"
            [outline]="true"
            (onClick)="doTerminate()"
            [disabled]="formSaving"
            >{{
              'contract-termination.button.terminate' | translate
            }}</app-button
          >
        </div>
      </div>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips
      *ngIf="!formLoading"
      [activeTipHeader]="
        todo !== 'add'
          ? 'contract-termination.detail.card.contractTermination.title'
          : ''
      "
    ></app-tips>
  </ng-template>
</app-layout>
