import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupProcurementSummaryComponent } from './app-popup-procurement-summary.component';

@Injectable()
export class AppPopupProcurementSummaryService {
  constructor(public appPopupService: AppPopupService) {}
  public open(procurementId: number): Observable<any> {
    return this.appPopupService.open(
      AppPopupProcurementSummaryComponent,
      {
        procurementId
      },
      { size: 'xl' }
    );
  }
}
