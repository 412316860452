<app-popup header="order-response.popup.viewItem">
  <div class="alert alert-dismissible show alert-info" role="alert">
    <div>
      <em
        style="font-size: 2rem; top: 5px; left: auto; font-weight: 500"
        class="pir pi-map-marker-alt mr-2 text-primary"
      ></em>
      <span
        class="d-block text-primary"
        style="margin-left: 3rem; font-weight: 500"
        maxLine
        >{{ address.addressDetail }}</span
      >
    </div>
  </div>
  <app-table-xx
    [model]="tableResponse"
    (onAfterRowCreated)="onAfterRowCreated($event)"
  ></app-table-xx>
</app-popup>
