import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { User } from '../../core/bean/user';
import { UserDelegation } from '../../core/bean/user-delegation';
import { AppPopupUserService } from '../../core/components/app-popup/app-popup-user/app-popup-user.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { UserDelegationRequest } from './user-delegation-request';
@Component({
  templateUrl: './user-delegation-edit-add.component.html'
})
export class UserDelegationEditAddComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<User>;
  public user: User = new User();
  public delegation: UserDelegation = new UserDelegation();
  public delegationRequest: UserDelegationRequest = new UserDelegationRequest();
  public fileUploader: FileUploader = new FileUploader(
    '/delegation/',
    ' ',
    this.global.appConstant.fileType.DOC_DELEGATION
  );
  public isAllUploaded = false;
  constructor(
    translateService: TranslateService,
    public appPopupUserService: AppPopupUserService
  ) {
    super('delegation', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.doSetMinDateEndDate();
    this.setStateReady();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.user = this.global.routerParams.get('user');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      user: [this.user],
      delegatedUserName: [null, Validators.required()],
      startDate: [null, Validators.required()],
      endDate: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.min('currentDate')
        ])
      ],
      isActive: [null],
      docFile: [null],
      uploadDocFile: [null],
      currentDate: new Date(),
      minDateEndDate: [null]
    });
  }

  public doSetMinDateEndDate(startDate?: Date): void {
    if (
      this.formGroup.get('startDate') &&
      this.formGroup.get('startDate').value
    ) {
      const startDatee = startDate
        ? startDate
        : this.formGroup.get('startDate').value;
      this.log.debug('startDate: ' + startDatee);
      const minDateEndDate: Date = new Date();
      minDateEndDate.setDate(startDatee.getDate());
      minDateEndDate.setMonth(startDatee.getMonth());
      minDateEndDate.setFullYear(startDatee.getFullYear());
      minDateEndDate.setHours(11);
      minDateEndDate.setMinutes(59);
      minDateEndDate.setSeconds(59);
      this.log.debug('minDate endDate: ' + minDateEndDate);
      this.formGroup.patchValue({
        minDateEndDate
      });

      if (
        this.formGroup.get('endDate').value <=
        this.formGroup.get('startDate').value
      ) {
        this.formGroup.get('endDate').reset();
      }

      this.formGroup
        .get('endDate')
        .setValidators(
          Validators.compose([
            Validators.required(),
            Validators.min('minDateEndDate', 'delegation.form.error.endDate')
          ])
        );
    }
  }

  public doBuildTableResponse(): void {
    const active = this.translateService.instant('delegation.status.active');
    const notActive = this.translateService.instant(
      'delegation.status.notActive'
    );

    if (this.todo === 'edit') {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'delegatedUser.name',
          header: 'table.column.delegatedUser',
          customClass: 'white-space-normal'
        },
        {
          field: 'startDate',
          header: 'table.column.startDate',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'endDate',
          header: 'table.column.endDate',
          format: FieldFormatEnum.ShortDate
        },
        {
          field: 'isActive',
          header: 'table.column.isActive',
          datamap:
            '{"false" : "' + notActive + '" , "true" : "' + active + '" }'
        }
      ]);
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.formGroup.patchValue({
              docFile: null
            });
            this.delegationRequest.delegation =
              this.global.copyFormAttributeToModel(
                this.delegation,
                this.formGroup
              );
            this.delegationRequest.user = this.user;
            this.delegationRequest.fileObjectList =
              this.fileUploader.fileObjectList;

            this.delegationRequest.delegation.startDate.setHours(0);
            this.delegationRequest.delegation.endDate.setHours(23);
            this.delegationRequest.delegation.endDate.setMinutes(59);
            this.delegationRequest.delegation.endDate.setSeconds(59);

            this.httpClientService
              .post<Response<UserDelegationRequest>>(
                '/delegation/insert',
                this.delegationRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/user-delegation']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doChooseUser(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupUserService
      .open(
        [this.user.id],
        ['VENDOR'],
        null,
        this.global.userSession.organizationTenant.id,
        this.global.appConstant.core.HIERARCHY_INCLUSIVE
      )
      .subscribe(user => {
        this.delegation.delegatedUser = user;
        const { delegatedUser } = this.delegation;
        const { name: delegatedUserName } = delegatedUser;
        this.formGroup.patchValue({ delegatedUserName, name });
      });
  }

  public doDeactivateDelegation(): void {
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.delegationRequest.user = this.user;
          this.delegationRequest.delegation = this.delegation;
          this.httpClientService
            .post<Response<UserDelegationRequest>>(
              '/delegation/update-status',
              this.delegationRequest
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessSavingOnNextRoute();
                this.router.navigate(['/pages/user-delegation']);
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/user-delegation']);
  }
}
