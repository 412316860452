import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { TabModel } from 'src/app/core/model/tab/tab-model';
import { TabResponseModel } from 'src/app/core/model/tab/tab-response-model';
import { OrderAddEditResponse } from './response/order-add-edit.response';
import { OrderAddEditModel } from './model/order-add-edit.model';

@Component({
  templateUrl: './pr-catalog-add.component.html'
})
export class PrCatalogAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  constructor(translateService: TranslateService) {
    super('pr', translateService);
  }
  public orderResponse: OrderAddEditResponse = new OrderAddEditResponse();
  public tabResponse: TabResponseModel;
  public tabOne: TabModel;
  public itemTypeCode: string;
  public orderAddEditModelMap: Map<number, OrderAddEditModel> = new Map<number, OrderAddEditModel>();
  public currentPage: number;
  public vendorId: number;

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setStateReady();
  }

  public doSetDataFromRouterParams(): void {
    this.orderAddEditModelMap = this.global.routerParams.get('orderAddEditModelMap');
    this.currentPage = this.global.routerParams.get('currentPage');
    this.todo = this.global.routerParams.get('todo');
    // this.orderResponse = this.global.routerParams.get('orderResponse');
    this.orderResponse = this.orderAddEditModelMap.get(this.currentPage).response;
    this.tabResponse = this.global.routerParams.get('tabResponse');
    this.vendorId = this.global.routerParams.get('vendorId');
    
    this.tabOne = this.tabResponse.currentTab;
    if(this.orderResponse.procurementTypeList[0].code === 
      this.global.appConstant.pm.PROCUREMENT_TYPE_NON_CONSTRUCTION){
      this.itemTypeCode = this.global.appConstant.core.ITEM_TYPE_CODE_OBAT;
    }else if(this.orderResponse.procurementTypeList[0].code === 
      this.global.appConstant.pm.PROCUREMENT_TYPE_CONSTRUCTION){
      this.itemTypeCode = this.global.appConstant.core.ITEM_TYPE_CODE_ALKES;
    }
  }
}
