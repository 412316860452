import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { TabProfileMarketingComponent } from './tab-profile-marketing.component';

export const routes = [
  {
    path: '',
    component: TabProfileMarketingComponent,
    data: { breadcrumb: '' }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    TabProfileMarketingComponent
  ],
  exports: [
    TabProfileMarketingComponent
  ]
})

export class TabProfileMarketingModule {}
