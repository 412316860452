import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetContentSliderModule } from '../app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetAdminOprWorklistComponent } from './app-widget-admin-opr-worklist.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule, AppWidgetContentSliderModule],
  declarations: [AppWidgetAdminOprWorklistComponent],
  entryComponents: [AppWidgetAdminOprWorklistComponent],
  exports: [AppWidgetAdminOprWorklistComponent]
})
export class AppWidgetAdminOprWorklistModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-admin-opr-worklist', AppWidgetAdminOprWorklistComponent);
  }
}
