<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="user.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div
          class="form-group row text-sm-right"
          [ngClass]="isVendor ? '' : 'required'"
        >
          <label class="col-sm-3 control-label">{{
            'user.form.name' | translate
          }}</label>
          <div *ngIf="!isVendor" class="col-sm-4 text-left">
            <app-text-field
              autofocus="true"
              size="XL"
              formControlName="name"
              type="alphanumeric"
              include="-.\\'\\,_"
              maxLength="255"
            >
            </app-text-field>
          </div>
          <div *ngIf="isVendor" class="col-sm-4 text-left">
            {{ formGroup.controls.name.value }}
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="isVendor ? '' : 'required'"
        >
          <label class="col-sm-3 control-label">{{
            'user.form.email' | translate
          }}</label>
          <div *ngIf="!isEdit" class="col-sm-4 text-left">
            <app-text-field
              size="XL"
              formControlName="email"
              type="email"
              maxLength="64"
            ></app-text-field>
          </div>
          <div *ngIf="isEdit" class="col-sm-9 text-left">
            {{ formGroup.controls.email.value }}
            <p class="m-0" *ngIf="userResponse.user.proposeType === 'EMAIL'"
              >{{ userResponse.user.proposeValue }} -
              <em class="text-warning">Waiting for confirmation</em>
            </p>
          </div>
        </div>

        <div
          *ngIf="!isVendor && isEdit"
          class="form-group row text-sm-right required"
        >
          <label class="col-sm-3 control-label">{{
            'account-setting.form.changeEmail' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-button size="SM" (onClick)="doChangeEmail()">{{
              'account-setting.button.changeEmail' | translate
            }}</app-button>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label"
            >{{ 'user.form.sipaNumber' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            <app-text-field
              formControlName="sipaNumber"
              maxlength="128"
            ></app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-right"
          [ngClass]="isVendor ? '' : 'required'"
        >
          <label class="col-sm-3 control-label">{{
            'user.form.organization' | translate
          }}</label>
          <div *ngIf="!isVendor" class="col-sm-4 text-left">
            <app-text-tree
              size="XL"
              formControlName="organization"
              stringUrl="/organization"
              header="Organization"
            >
            </app-text-tree>
          </div>
          <div *ngIf="isVendor" class="col-sm-4 text-left">
            {{ organizationName }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'user.form.active' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <app-toggle-switch formControlName="isActive"></app-toggle-switch>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'user.form.unLocked' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <app-toggle-switch formControlName="isLocked"></app-toggle-switch>
          </div>
        </div>

        <div *ngIf="todo == 'edit'" class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'user.form.password' | translate
          }}</label>
          <div class="col-sm-7 text-left">
            <app-button size="SM" (onClick)="doResetPassword()">
              {{ 'user.form.resetPassword' | translate }}
            </app-button>
          </div>
        </div>

        <div *ngIf="todo == 'edit'" class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'user.form.loginAttempt' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ formGroup.controls.loginAttempt.value }}
            <app-button
              *ngIf="formGroup.controls.loginAttempt.value !== 0"
              size="SM"
              (onClick)="doResetAttempt()"
            >
              {{ 'user.form.resetAttempt' | translate }}
            </app-button>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="card" *ngIf="!isVendor">
              <div class="card-header">
                <div
                  class="float-left control-label"
                  [ngStyle]="{ 'font-size': '17px' }"
                >
                  {{ 'user.form.role' | translate }}
                </div>
                <div class="float-right">
                  <app-button
                    *ngIf="!isVendor"
                    (onClick)="doAdd()"
                    [disabled]="isRoleValid"
                  >
                    {{ 'app.button.add' | translate }}
                  </app-button>
                </div>
              </div>
              <div class="card-body">
                <div
                  class="text-center table-responsive float-none"
                  [ngStyle]="{ margin: '0 auto' }"
                >
                  <table
                    class="table table-striped box-shadow"
                    aria-describedby="Table"
                  >
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col" class="fit">{{
                          'user.form.role' | translate
                        }}</th>
                        <th scope="col" class="fit">{{
                          'user.form.startDate' | translate
                        }}</th>
                        <th scope="col" class="fit">{{
                          'user.form.endDate' | translate
                        }}</th>
                        <th scope="col" class="fit">{{
                          'user.form.defaultRole' | translate
                        }}</th>
                        <th scope="col" *ngIf="!isVendor">Action</th>
                      </tr>
                    </thead>
                    <tbody formArrayName="userRoleList">
                      <tr
                        [formGroupName]="i"
                        *ngFor="
                          let ru of formGroup.get('userRoleList').controls;
                          let i = index
                        "
                      >
                        <ng-container *ngIf="ru.value.crudOperation !== 0">
                          <td>
                            {{
                              resolveTableIndex(
                                i,
                                formGroup.get('userRoleList').controls
                              )
                            }}.
                          </td>
                          <td>
                            <app-combo-box
                              size="XL"
                              formControlName="role"
                              [optionList]="userRoleOptionList"
                              container="body"
                            >
                            </app-combo-box>
                          </td>
                          <td>
                            <app-date-picker
                              size="XL"
                              formControlName="startDate"
                              minDate="currentDate"
                              maxDate="endDate"
                            ></app-date-picker>
                          </td>
                          <td>
                            <app-date-picker
                              size="XL"
                              formControlName="endDate"
                              minDate="startDate"
                            ></app-date-picker>
                          </td>
                          <td>
                            <app-radio
                              size="XL"
                              formControlName="isDefault"
                              [optionList]="defaultRoleOptionList"
                              (onChange)="onChangeDefaultRole($event, i)"
                            ></app-radio>
                          </td>
                          <td>
                            <app-button
                              size="TN"
                              color="DANGER"
                              (onClick)="doRemove(i, ru.value)"
                              title="Remove"
                            >
                              <em class="fas fa-trash"></em>
                            </app-button>
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>

      <div class="mt-4" *ngIf="todo == 'edit'">
        <app-card header="user-role-log.title" *ngIf="!isVendor">
          <app-table
            [model]="tableResponse"
            [isServerSide]="true"
            stringUrl="/user/user-role-log"
          ></app-table>
        </app-card>
      </div>
    </app-card>
  </div>
</div>
