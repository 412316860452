import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AppPopupImportQuantityService } from 'src/app/core/components/app-popup/app-popup-import-quantity/app-popup-import-quantity.service';
import { AppPopupImportService } from 'src/app/core/components/app-popup/app-popup-import/app-popup-import.service';
import { ImportFileWebsocketService } from 'src/app/core/components/app-popup/app-popup-import/import-file-websocket.service';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Catalog } from '../../core/bean/catalog';
import { CatalogViewManage } from '../../core/bean/catalog-view-manage';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './manage-catalog.component.html'
})
export class ManageCatalogComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public tableResponse: TableResponseModel<Catalog>;
  public isAllowed = false;
  public isVendor: boolean;
  public isOther: boolean;
  constructor(
    translateService: TranslateService,
    public appPopupImportService: AppPopupImportService,
    public importFileWebsocketService: ImportFileWebsocketService,
    public appPopupImportQuantityService: AppPopupImportQuantityService
  ) {
    super('manage-catalog', translateService);
  }

  public onInit(): void {
    this.isVendor = this.global.userSession.vendor ? true : false;
    this.isOther = this.isVendor
      ? false
      : this.global.userSession.activeUserRole.role.type ===
        this.global.appConstant.core.ROLE_TYPE_OTHER
      ? true
      : false;
    this.isAccessible();
  }

  public isAccessible(): void {
    if (this.global.routerParams.get('isFromManageCatalog') === true) {
      this.isAllowed = true;
      this.buildTableResponse();
      this.setStateReady();
    } else {
      this.httpClientService
        .post<Response<Catalog>>('/manage-catalog/is-accessible', {})
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.isAllowed = true;
            this.buildTableResponse();
            this.setStateReady();
          } else {
            this.isAllowed = false;
            window.history.go(-1);
            this.global.alertService.showInfoOnNextRoute(response.statusText);
          }
        });
    }
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'merk',
        header: 'table.column.itemMerk',
        customClass: 'white-space-normal'
      },
      {
        field: 'catalogAttributeGroup.name',
        header: 'table.column.catalogAttributeGroupName',
        customClass: 'text-left'
      },
      // {
      //   field: 'price',
      //   header: 'table.column.price',
      //   customClass: 'text-right',
      //   format: FieldFormatEnum.Currency,
      //   currencyCodeRef: 'currency.code'
      // },
      {
        field: 'produsenName',
        header: 'table.column.unit',
        customClass: 'text-left'
      },
      {
        field: 'catalogStatus.name',
        header: 'table.column.statusCatalogName',
        customClass: 'text-left'
      },
      {
        field: 'vendor.name',
        header: 'table.column.vendor',
        customClass: 'text-left'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    const parentRoute = this.router.url;
    const url = parentRoute + '/add';
    this.router.navigate([url]);
  }

  public doEdit(catalog: CatalogViewManage): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('catalogId', catalog.id);
    this.router.navigate(['/pages/manage-catalog/edit']);
  }

  public doView(catalog: CatalogViewManage): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('catalogId', catalog.id);
    this.router.navigate(['/pages/manage-catalog/detail']);
  }

  /*public doImport(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'import');
    this.router.navigate(['/pages/manage-catalog/import']);
  }*/

  /*public doImport(): void {
    this.appPopupImportService.open('/manage-catalog/import-excel').subscribe(result => {
      if (result) {
        this.tableResponse.reload();
      }
    });
    this.importFileWebsocketService.appPopupImportComponent =
      this.appPopupImportService.appPopupService.componentInstance;
  }*/

  public doImport(): void {
    this.appPopupImportQuantityService
      .open('/manage-catalog/import-excel')
      .subscribe(result => {
        if (result) {
          this.tableResponse.reload();
        }
      });
  }

  public doDelete(catalog: CatalogViewManage): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<CatalogViewManage>>(
              '/manage-catalog/delete',
              catalog
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
                this.setStateReady();
              }
            });
        }
      });
  }
}
