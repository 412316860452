import { CommitteeDecree } from 'src/app/core/bean/committee-decree';
import { Organization } from 'src/app/core/bean/organization';
import { CommitteeParticipant } from '../../core/bean/committee-participant';
import { CommitteeType } from '../../core/bean/committee-type';

export class CommitteeResponse {
  committeeTypeList: CommitteeType[] = [];
  committeeParticipantList: CommitteeParticipant[] = [];
  organizationList: Organization[] = [];
  committeeDecree: CommitteeDecree = new CommitteeDecree();
}
