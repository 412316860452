import { EventEmitter } from '@angular/core';
import { ActualPlanBarChartType } from '../types/actual-plan-bar-chart-type';
export class ActualPlanBarChartModel {
  public requestBody: object;
  public stringUrl: string;
  public typeChanges: EventEmitter<void>;
  public reloadChanges: EventEmitter<void>;
  public requestBodyChanges: EventEmitter<void>;
  constructor(public type: ActualPlanBarChartType, stringUrl?: string) {
    this.requestBody = {};
    this.setStateStringUrl(stringUrl);
    this.typeChanges = new EventEmitter();
    this.reloadChanges = new EventEmitter();
    this.requestBodyChanges = new EventEmitter();
  }

  public changeType(type: ActualPlanBarChartType): void {
    this.type = type;
    this.setStateStringUrl();
    this.typeChanges.emit();
  }

  public setRequestBody(requestBody: object): void {
    this.requestBody = requestBody;
    this.requestBodyChanges.emit();
  }

  public reload(): void {
    this.reloadChanges.emit();
  }

  private setStateStringUrl(stringUrl?: string): void {
    this.stringUrl =
      stringUrl || this.type === 'budget-info'
        ? '/app-actual-plan-bar-chart-info/index/budget'
        : '/app-actual-plan-bar-chart-info/index/work-plan';
  }
}
