import { NgModule } from '@angular/core';
import { AppButtonModule } from '../../../../../../core/components/app-button/app-button.module';
import { PipesModule } from '../../../../../../core/pipe/pipe.module';
import { VendorModule } from '../../../../../../core/vendor/vendor.module';
import { AppCatalogItemComponent } from './app-catalog-item.component';
@NgModule({
  imports: [VendorModule, AppButtonModule, PipesModule],
  declarations: [AppCatalogItemComponent],
  exports: [AppCatalogItemComponent]
})
export class AppCatalogItemModule { }
