import { BillingTerm } from 'src/app/core/bean/billing-term';
import { ContractType } from 'src/app/core/bean/contract-type';
import { FinesMaster } from 'src/app/core/bean/fines-master';
import { GuaranteeBondType } from 'src/app/core/bean/guarantee-bond-type';
import { PaymentTerm } from 'src/app/core/bean/payment-term';
import { ProcurementResult } from 'src/app/core/bean/procurement-result';
import { ProcurementScopeWork } from 'src/app/core/bean/procurement-scope-work';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { StageOfWork } from 'src/app/core/bean/stage-of-work';
import { ProcurementFines } from '../../core/bean/procurement-fines';
import { ProcurementGuarantee } from '../../core/bean/procurement-guarantee';
import { ProcurementSow } from '../../core/bean/procurement-sow';

export class ProcurementScopeWorkResponse {
  procurementScopeWork: ProcurementScopeWork = new ProcurementScopeWork();
  procurementVendor: ProcurementVendor = new ProcurementVendor();
  procurementSowList: ProcurementSow[] = [];
  procurementFinesList: ProcurementFines[] = [];
  procurementGuaranteeList: ProcurementGuarantee[] = [];
  procurementResultProductList: ProcurementResult[] = [];
  procurementResultServiceList: ProcurementResult[] = [];
  finesMasterList: FinesMaster[] = [];
  guaranteeBondTypeList: GuaranteeBondType[] = [];
  paymentTermList: PaymentTerm[] = [];
  stageOfWorkList: StageOfWork[] = [];
  contractTypeList: ContractType[] = [];
  billingTermList: BillingTerm[] = [];
}
