<app-popup [isLoading]="formLoading" [isShowHeaderCloseButton]="false">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-card header="initiation.popup-select.initiationType.title">
          <div>
            <app-select
              [optionList]="optionList"
              [isMultiple]="false"
              formControlName="initiationTypeList"
              maxHeight="max-content"
              [isShowBorder]="true"
              (onChange)="disableSelectInitiationButton()"
            >
              <ng-template #content let-data>
                <div>
                    <h4>{{
                      data.nameTranslationKey.module.code.toLowerCase() 
                      + '.' + data.nameTranslationKey.key | translate
                    }}</h4>
                    <p>{{
                      data.descTranslationKey.module.code.toLowerCase() 
                      + '.' + data.descTranslationKey.key | translate
                    }}</p>
                </div>
              </ng-template>
            </app-select>
          </div>
        </app-card>

        <ng-template #modalFooter let-activeModal>
            <div class="button-group button-group-center">
                <app-button color="LIGHT" (onClick)="activeModal.close(true)">
                    {{ 'app.button.cancel' | translate }}</app-button>
                <app-button [disabled]="initButtonIsDisable" (onClick)="doSelectInitationType()">
                    {{ 'app.button.createNew' | translate }}</app-button>
            </div>
        </ng-template>
    </form>
</app-popup>