<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="performance-procurement.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-table [tableResponse]="tableResponse" [isServerSide]="true"
        stringUrl="/performance-procurement/index">
        <ng-template #headerButtons>
          <app-button mode="BYPASS" (onClick)="doExport()"
            [disabled]="isLoading"><em class="fas"
              [ngClass]="!isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'">
            </em>{{ 'app.button.export' | translate }}
          </app-button>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button mode="BYPASS" size="TN" class="mr-2"
            (onClick)="doView(rowData)"
            title="{{ 'app.tooltip.view' | translate }}">
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>