<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="performance-procurement.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-procurement.table.column.vendorName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ vendor.name }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-procurement.form.address' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ vendorAddress }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-procurement.table.column.procName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ performanceProcurementDTO.procurement.name }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-procurement.table.column.procType' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ performanceProcurementDTO.procurement.procurementType.name }}
          </div>
        </div>

        <app-fieldset legend="Common Scoring">
          <!-- Common Scoring HTML Tables -->
          <div
            *ngIf="
              performanceProcurementDTO.scoringCriteriaGroupListCommon.length ==
              0
            "
            class="table-responsive custom-table float-none"
            [ngStyle]="{ margin: '0 auto' }"
          >
            <table
              class="table table-striped box-shadow"
              aria-describedby="Table"
            >
              <tr class="text-center">
                <th scope="col" colspan="99" class="text-center">{{
                  'app.info.noData' | translate
                }}</th>
              </tr>
            </table>
          </div>

          <ng-container formArrayName="parentsCommonScoring">
            <!--Scoring Criteria Group List-->
            <ng-container
              *ngFor="
                let parent of parentsCommonScoring.controls;
                let i = index
              "
              [formGroup]="parent"
            >
              <label class="control-label"> {{ parent.value.name }}</label>
              <div
                class="table-responsive custom-table float-none"
                [ngStyle]="{ margin: '0 auto' }"
              >
                <table
                  class="table table-striped box-shadow"
                  aria-describedby="Table"
                >
                  <thead>
                    <tr class="text-center">
                      <th scope="col" colspan="10">{{
                        'app.table.column.no' | translate
                      }}</th>
                      <th scope="col" colspan="40"
                        >{{
                          'performance-procurement.form.criteria' | translate
                        }}
                      </th>
                      <th scope="col" colspan="25">{{
                        'performance-procurement.form.value' | translate
                      }}</th>
                      <th scope="col" colspan="24"
                        >{{ 'performance-procurement.form.result' | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container formArrayName="listOfScoringCriteria">
                      <tr
                        class="text-center"
                        *ngFor="
                          let criteria of addScoringCriteria(parent).controls;
                          let j = index
                        "
                        [formGroup]="criteria"
                      >
                        <td colspan="10">{{ j + 1 }}.</td>
                        <td colspan="40">{{ criteria.value.name }}</td>
                        <td colspan="25">
                          <ng-container
                            *ngIf="
                              criteria.value.scoringCriteria.attribute ===
                                null &&
                                global.userSession.user.organization.id ===
                                  procSubOrganization.id;
                              then nullTmpl;
                              else valueTmpl
                            "
                          >
                          </ng-container>
                          <ng-template #nullTmpl>
                            <div
                              [ngStyle]="{ width: '6rem', margin: '0 auto' }"
                            >
                              <app-text-field
                                (onChange)="
                                  setResult(
                                    $event,
                                    criteria,
                                    criteria.value.scoringCriteria
                                  )
                                "
                                formControlName="value"
                                size="XL"
                                type="decimal"
                                [tooltip]="htmlContent"
                              ></app-text-field>
                              <ng-template #htmlContent>
                                <div [ngStyle]="{ width: '100px' }">
                                  <span class="d-block"
                                    >Min. input:
                                    {{
                                      getMaxMinInput(
                                        criteria.value.scoringCriteria
                                      )[0]
                                    }}</span
                                  >
                                  <span class="d-block"
                                    >Max. input:
                                    {{
                                      getMaxMinInput(
                                        criteria.value.scoringCriteria
                                      )[1] || ' - '
                                    }}</span
                                  >
                                </div>
                              </ng-template>
                            </div>
                          </ng-template>
                          <ng-template #valueTmpl>
                            <div
                              *ngIf="
                                performanceProcurementDTO.procurement
                                  .isPmGroup &&
                                performanceProcurementDTO.procurement
                                  .procurementTransactionType.code === global.appConstant.vm.PROC_TRANSACTION_TYPE_ONLINE
                              "
                            >
                              {{ criteria.value.value || '-' }}
                            </div>
                            <div
                              *ngIf="
                                !performanceProcurementDTO.procurement
                                  .isPmGroup ||
                                (performanceProcurementDTO.procurement
                                  .isPmGroup &&
                                  performanceProcurementDTO.procurement
                                    .procurementTransactionType.code ===
                                    global.appConstant.vm.PROC_TRANSACTION_TYPE_OFFLINE)
                              "
                              [ngStyle]="{ width: '22rem', margin: '0 auto' }"
                            >
                              <app-combo-box
                                (onChange)="
                                  setResultByCombobox(
                                    $event,
                                    criteria,
                                    criteria.value.scoringCriteria
                                  )
                                "
                                [optionList]="
                                  criteria.value.criteriaModelOptionList
                                "
                                formControlName="value"
                                size="XL"
                              ></app-combo-box>
                            </div>
                          </ng-template>
                        </td>
                        <td colspan="24">{{ criteria.value.result }}</td>
                      </tr>
                    </ng-container>
                    <tr class="text-center">
                      <td colspan="10"></td>
                      <td colspan="40"></td>
                      <td colspan="25" class="font-weight-bold">{{
                        'performance-procurement.form.total' | translate
                      }}</td>
                      <td colspan="24" class="font-weight-bold">{{
                        doSetAvgTotal(
                          addScoringCriteria(parent),
                          parent.controls.scoringCriteriaGroup.value,
                          parent
                        ) | number: '1.2-2'
                      }}</td>
                      <!-- {{ parent.value.result }} -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
            </ng-container>
          </ng-container>
        </app-fieldset>

        <app-fieldset legend="Specific Scoring">
          <div
            *ngIf="
              performanceProcurementDTO.scoringCriteriaGroupListSpecific
                .length == 0
            "
            class="table-responsive custom-table float-none"
            [ngStyle]="{ margin: '0 auto' }"
          >
            <table
              class="table table-striped box-shadow"
              aria-describedby="Table"
            >
              <tr class="text-center">
                <th scope="col" colspan="99" class="text-center">{{
                  'app.info.noData' | translate
                }}</th>
              </tr>
            </table>
          </div>

          <ng-container formArrayName="parentsSpecificScoring">
            <!--Scoring Criteria Group List-->
            <ng-container
              *ngFor="
                let parent of parentsSpecificScoring.controls;
                let i = index
              "
              [formGroup]="parent"
            >
              <label class="control-label"> {{ parent.value.name }}</label>
              <div
                class="table-responsive custom-table float-none"
                [ngStyle]="{ margin: '0 auto' }"
              >
                <table
                  class="table table-striped box-shadow"
                  aria-describedby="Table"
                >
                  <thead>
                    <tr class="text-center">
                      <th scope="col" colspan="10">{{
                        'app.table.column.no' | translate
                      }}</th>
                      <th scope="col" colspan="40"
                        >{{
                          'performance-procurement.form.criteria' | translate
                        }}
                      </th>
                      <th scope="col" colspan="25">{{
                        'performance-procurement.form.value' | translate
                      }}</th>
                      <th scope="col" colspan="24"
                        >{{ 'performance-procurement.form.result' | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container formArrayName="listOfScoringCriteria">
                      <tr
                        class="text-center"
                        *ngFor="
                          let criteria of addScoringCriteria(parent).controls;
                          let j = index
                        "
                        [formGroup]="criteria"
                      >
                        <td colspan="10">{{ j + 1 }}.</td>
                        <td colspan="40">{{ criteria.value.name }}</td>
                        <td class="text-center" colspan="25">
                          <ng-container
                            *ngIf="
                              criteria.value.scoringCriteria.attribute ===
                                null &&
                                global.userSession.user.organization.id ===
                                  procSubOrganization.id;
                              then nullTmpl;
                              else valueTmpl
                            "
                          >
                          </ng-container>
                          <ng-template #nullTmpl>
                            <div
                              [ngStyle]="{ width: '6rem', margin: '0 auto' }"
                            >
                              <app-text-field
                                (onChange)="
                                  setResult(
                                    $event,
                                    criteria,
                                    criteria.value.scoringCriteria
                                  )
                                "
                                formControlName="value"
                                size="XL"
                                type="decimal"
                                [tooltip]="htmlContent"
                              ></app-text-field>
                              <ng-template #htmlContent>
                                <div [ngStyle]="{ width: '100px' }">
                                  <span class="d-block"
                                    >Min. input:
                                    {{
                                      getMaxMinInput(
                                        criteria.value.scoringCriteria
                                      )[0]
                                    }}</span
                                  >
                                  <span class="d-block"
                                    >Max. input:
                                    {{
                                      getMaxMinInput(
                                        criteria.value.scoringCriteria
                                      )[1] || ' - '
                                    }}</span
                                  >
                                </div>
                              </ng-template>
                            </div>
                          </ng-template>
                          <ng-template #valueTmpl>
                            <div
                              *ngIf="
                                performanceProcurementDTO.procurement
                                  .isPmGroup &&
                                performanceProcurementDTO.procurement
                                  .procurementTransactionType.code === global.appConstant.vm.PROC_TRANSACTION_TYPE_ONLINE
                              "
                            >
                              {{ criteria.value.value || '-' }}
                            </div>
                            <div
                              *ngIf="
                                !performanceProcurementDTO.procurement
                                  .isPmGroup ||
                                (performanceProcurementDTO.procurement
                                  .isPmGroup &&
                                  performanceProcurementDTO.procurement
                                    .procurementTransactionType.code ===
                                    global.appConstant.vm.PROC_TRANSACTION_TYPE_OFFLINE)
                              "
                              [ngStyle]="{ width: '22rem', margin: '0 auto' }"
                            >
                              <app-combo-box
                                (onChange)="
                                  setResultByCombobox(
                                    $event,
                                    criteria,
                                    criteria.value.scoringCriteria
                                  )
                                "
                                [optionList]="
                                  criteria.value.criteriaModelOptionList
                                "
                                formControlName="value"
                                size="XL"
                              ></app-combo-box>
                            </div>
                          </ng-template>
                        </td>
                        <td colspan="24">{{ criteria.value.result }}</td>
                      </tr>
                    </ng-container>
                    <tr class="text-center">
                      <td colspan="10"></td>
                      <td colspan="40"></td>
                      <td colspan="25" class="font-weight-bold">{{
                        'performance-procurement.form.total' | translate
                      }}</td>
                      <td colspan="24" class="font-weight-bold">{{
                        doSetAvgTotal(
                          addScoringCriteria(parent),
                          parent.controls.scoringCriteriaGroup.value,
                          parent
                        ) | number: '1.2-2'
                      }}</td>
                      <!-- {{ parent.value.result }} -->
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
            </ng-container>
          </ng-container>
        </app-fieldset>

        <app-fieldset legend="Scoring Summary">
          <!-- Scoring Summary HTML Table -->
          <div
            class="table-responsive custom-table float-none"
            [ngStyle]="{ margin: '0 auto' }"
          >
            <table
              class="table table-striped box-shadow"
              aria-describedby="Table"
            >
              <thead>
                <tr class="text-center">
                  <th scope="col" colspan="10">{{
                    'app.table.column.no' | translate
                  }}</th>
                  <th scope="col"
                    >{{
                      'performance-procurement.form.criteriaGroup' | translate
                    }}
                  </th>
                  <th scope="col">{{
                    'performance-procurement.form.weight' | translate
                  }}</th>
                  <th scope="col">{{
                    'performance-procurement.form.result' | translate
                  }}</th>
                  <th scope="col">{{
                    'performance-procurement.form.weightResult' | translate
                  }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngIf="
                    performanceProcurementDTO.scoringTemplateWeightList
                      .length == 0
                  "
                >
                  <td colspan="99" class="bg-white text-center">{{
                    'app.info.noData' | translate
                  }}</td>
                </tr>
                <ng-container formArrayName="summaryList">
                  <tr
                    *ngFor="let data of summaryList.controls; let j = index"
                    [formGroup]="data"
                  >
                    <td colspan="10" class="text-center">{{ j + 1 }}.</td>
                    <td class="text-center">{{
                      data.value.scoringCriteriaGroup.name
                    }}</td>
                    <td class="text-center">{{ data.value.weight }} %</td>
                    <td class="text-center">
                      {{ data.value.result | number: '1.2-2' }}
                    </td>
                    <td class="text-center">
                      {{
                        data.value.weight * 0.01 * data.value.result
                          | number: '1.2-2'
                      }}
                    </td>
                  </tr>
                </ng-container>
                <tr class="text-center">
                  <td colspan="10"></td>
                  <td></td>
                  <td></td>
                  <td class="font-weight-bold"
                    >{{
                      'performance-procurement.form.totalScoring' | translate
                    }}
                  </td>
                  <td class="font-weight-bold">{{
                    totalScoring | number: '1.2-2'
                  }}</td>
                </tr>
                <tr class="text-center">
                  <td colspan="10"></td>
                  <td></td>
                  <td></td>
                  <td class="font-weight-bold">{{
                    'performance-procurement.form.rating' | translate
                  }}</td>
                  <td>
                    <app-rating formControlName="rating"></app-rating>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-fieldset>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-procurement.form.upload' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-upload formControlName="file" [fileUploader]="fileUploader">
            </app-upload>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-procurement.form.note' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="note" maxLength="512">
            </app-text-area>
          </div>
        </div>
        <br />

        <!-- History Activity -->
        <h5 *ngIf="isShowHistoryActivity" class="separator">{{
          'performance-procurement.form.historyActivity' | translate
        }}</h5>
        <div
          *ngIf="isShowHistoryActivity"
          class="table-responsive custom-table float-none"
          [ngStyle]="{ margin: '0 auto' }"
        >
          <table
            class="table table-striped box-shadow"
            aria-describedby="Table"
          >
            <thead>
              <tr class="text-left">
                <th scope="col" class="text-center"
                  >{{ 'app.table.column.no' | translate }}
                </th>
                <th scope="col" [ngStyle]="{ width: '150px' }"
                  >{{
                    'performance-procurement.form.performanceUser' | translate
                  }}
                </th>
                <th scope="col" [ngStyle]="{ width: '200px' }">{{
                  'performance-procurement.form.recommendationNote' | translate
                }}</th>
                <th scope="col" [ngStyle]="{ width: '150px' }">{{
                  'performance-procurement.form.approver' | translate
                }}</th>
                <th scope="col" [ngStyle]="{ width: '200px' }"
                  >{{ 'performance-procurement.form.approvalNote' | translate }}
                </th>
                <th scope="col" class="text-center">{{
                  'performance-procurement.form.status' | translate
                }}</th>
                <th scope="col" class="text-center">{{
                  'performance-procurement.form.file' | translate
                }}</th>
              </tr>
            </thead>
            <tbody>
              <!-- 
                <tr *ngIf="!performanceProcurementDTO?.performanceList || 
                performanceProcurementDTO?.performanceList.length == 0">
                    <td colspan="99" class="bg-white text-center"> {{ 'app.info.
                    noData' | translate }}</td>
                </tr> -->
              <tr
                *ngFor="
                  let data of performanceProcurementDTO?.performanceList;
                  let i = index
                "
                class="text-left"
              >
                <td class="text-center">{{ i + 1 }}.</td>
                <td [ngStyle]="{ 'white-space': 'normal' }">{{
                  data[0].verifiedByUser?.name || '-'
                }}</td>
                <td class="text-break" [ngStyle]="{ 'max-width': '400px' }">{{
                  data[0].note || '-'
                }}</td>
                <td class="text-break" [ngStyle]="{ 'max-width': '300px' }">{{
                  data[1].approvedByUser?.name || '-'
                }}</td>
                <td [ngStyle]="{ 'white-space': 'normal' }">{{
                  data[1].approvalNote || '-'
                }}</td>
                <td
                  [ngStyle]="{ 'max-width': '200px' }"
                  class="text-center text-break"
                  >{{ data[1].workflowPrcs.workflowStatus.name || '-' }}</td
                >
                <td *ngIf="data[1].approvalFile" class="text-center"
                  ><a
                    href="#"
                    (click)="
                      doDownload(
                        $event,
                        '/performance-procurement/file/view/' +
                          data[1].approvalFile.uploadedFileName +
                          '/' +
                          global.appConstant.fileType.DOC_PERFORMANCE,
                        data[1].approvalFile.fileName
                      )
                    "
                  >
                    {{ data[1].approvalFile.fileName }}</a
                  >
                </td>
                <td *ngIf="!data[1].approvalFile" class="text-center"> - </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="!isEnabled"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button
            *ngIf="
              todo !== 'view' &&
              global.userSession.user.organization.id ===
                procSubOrganization?.id
            "
            [disabled]="!isEnabled"
            (onClick)="doSave()"
          >
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
