<div
  class="app-content-slider-item"
  [ngStyle]="{
    'background-color': backgroundColor,
    color: color ? color : 'initial',
    width: width ? width : 'initial',
    cursor: disable ? 'default' : 'pointer'
  }"
  (click)="doClickItem($event)"
>
  <ng-content></ng-content>
</div>
