import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Workflow } from '../../core/bean/workflow';
import { WorkflowPrcs } from '../../core/bean/workflow-prcs';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
@Component({
  templateUrl: './sla-vendor.component.html'
})
export class SlaVendorComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public workflowList: OptionListModel<Workflow> = new OptionListModel(true);
  public workflowPrcs: WorkflowPrcs[] = [];
  public tableResponse: TableResponseModel<WorkflowPrcs>;
  constructor(translateService: TranslateService) {
    super('sla-vendor', translateService);
  }

  public onInit(): void {
    // this.setVendorTypeList();
    this.setWorkflowList();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      // vendorName: [null],
      // vendorType: [null],
      workflowList: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.workflowName',
        className: 'white-space-normal',
        plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
      },
      {
        field: 'totalSlaInDays',
        header: 'table.column.totalSla',
        plugins: {
          name: 'custom-plugin',
          before: (tablePluginData: TablePluginData): string => {
            if (tablePluginData.value !== 0 && tablePluginData.value !== null) {
              return (
                tablePluginData.value +
                ' ' +
                this.translateService.instant('sla-vendor.day')
              );
            } else {
              return '-';
            }
          }
        }
      },
      {
        field: 'totalSlaRealizedName',
        header: 'table.column.totalRealization',
        className: 'text-center'
      }
    ]);
  }

  public addUomDay(slaInDays: number): string {
    if (slaInDays !== 0 && slaInDays !== null) {
      return slaInDays + ' ' + this.translateService.instant('sla-vendor.day');
    } else {
      return '-';
    }
  }

  public setWorkflowList(): void {
    const moduleGroupCode = 'VM';
    this.httpClientService
      .get<Workflow[]>('/workflow/workflow-list/' + moduleGroupCode)
      .subscribe((workflowList: Workflow[]) => {
        this.workflowList.setRequestValues(workflowList);
      });
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.workflowList) {
      this.formGroup.value.workflowList.forEach(value => {
        value.slaUpdatedDate = new Date(value.slaUpdatedDate);
      });
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doClick(workflowPrcs: WorkflowPrcs): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'detail');
    this.global.routerParams.set('workflowPrcsId', workflowPrcs.id);
    this.global.routerParams.set('workflowName', workflowPrcs.name);
    this.router.navigate(['/pages/sla-vendor/detail/']);
  }
}
