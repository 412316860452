<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="performance-on-registration.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-on-registration.form.vendorName' | translate
          }}</label>
          <div class="col-sm-9 col-lg-7 text-left">
            {{ formGroup.get('vendorName').value }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-on-registration.form.address' | translate
          }}</label>
          <div class="col-sm-9 col-lg-7 text-left">
            {{ formGroup.get('address').value }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'performance-on-registration.form.vendorType' | translate
          }}</label>
          <div class="col-sm-9 col-lg-7 text-left">
            {{ formGroup.get('vendorType').value }}
          </div>
        </div>

        <app-fieldset
          legend="{{
            'performance-on-registration.legend.commonScoring' | translate
          }}"
        >
          <ng-container formArrayName="commonList">
            <ng-container
              *ngFor="
                let item of getFormArray(formGroup, 'commonList').controls;
                let i = index
              "
              [formGroup]="item"
            >
              <div class="form-group row">
                <div class="col">
                  <label class="control-label"
                    >&nbsp;{{
                      item.controls.scoringCriteriaGroup.value.name
                    }}</label
                  >
                  <div
                    class="table-responsive custom-table float-none"
                    [ngStyle]="{ margin: '0 auto' }"
                  >
                    <table
                      class="table table-striped box-shadow"
                      aria-describedby="Table"
                    >
                      <thead>
                        <tr class="text-center">
                          <th scope="col">{{
                            'app.table.column.no' | translate
                          }}</th>
                          <th scope="col">{{
                            'performance-on-registration.table.column.criteria'
                              | translate
                          }}</th>
                          <th scope="col">{{
                            'performance-on-registration.table.column.value'
                              | translate
                          }}</th>
                          <th scope="col">{{
                            'performance-on-registration.table.column.result'
                              | translate
                          }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container formArrayName="scoringCriteriaList">
                          <tr
                            *ngFor="
                              let data of getFormArray(
                                item,
                                'scoringCriteriaList'
                              ).controls;
                              let j = index
                            "
                            [formGroup]="data"
                          >
                            <td class="text-center">{{ j + 1 }}.</td>
                            <td class="text-center">{{
                              data.value.scoringCriteria.name
                            }}</td>
                            <td class="text-center">
                              <p
                                *ngIf="
                                  (data.value.scoringCriteria.value &&
                                    data.value.scoringCriteria.attribute) ||
                                  formGroup.isView
                                "
                              >
                                {{ data.value.value || '-' }}</p
                              >
                              <div
                                *ngIf="
                                  (!data.value.scoringCriteria.value ||
                                    !data.value.scoringCriteria.attribute) &&
                                  !formGroup.isView
                                "
                                [ngStyle]="{ width: '6rem', margin: '0 auto' }"
                              >
                                <app-text-field
                                  size="XL"
                                  (onChange)="
                                    setResult(
                                      $event,
                                      data,
                                      data.value.scoringCriteria
                                    )
                                  "
                                  type="decimal"
                                  formControlName="value"
                                  [tooltip]="htmlContent"
                                ></app-text-field>
                                <ng-template #htmlContent>
                                  <div [ngStyle]="{ width: '100px' }">
                                    <span class="d-block"
                                      >Min. input:
                                      {{
                                        getMaxMinInput(
                                          data.value.scoringCriteria
                                        )[0]
                                      }}</span
                                    >
                                    <span class="d-block"
                                      >Max. input:
                                      {{
                                        getMaxMinInput(
                                          data.value.scoringCriteria
                                        )[1] || ' - '
                                      }}</span
                                    >
                                  </div>
                                </ng-template>
                              </div>
                            </td>
                            <td class="text-center">
                              <p>{{ data.value.result }}</p>
                            </td>
                          </tr>
                        </ng-container>
                        <tr>
                          <td class="text-center"></td>
                          <td class="text-center"></td>
                          <td class="text-center font-weight-bold">
                            {{
                              'performance-on-registration.table.column.total'
                                | translate
                            }}
                          </td>
                          <td class="text-center font-weight-bold">
                            {{
                              doSetAvgTotal(
                                item.controls.scoringCriteriaList,
                                item.controls.scoringCriteriaGroup.value,
                                item
                              ) | number: '1.2-2'
                            }}
                            <!-- doSetAvgTotal
                            (item.controls.scoringCriteriaList, 
                            item.controls
                              .scoringCriteriaGroup.value) 
                            -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </app-fieldset>

        <app-fieldset
          legend="{{
            'performance-on-registration.legend.specificScoring' | translate
          }}"
        >
          <ng-container formArrayName="specificList">
            <ng-container
              *ngFor="
                let item of getFormArray(formGroup, 'specificList').controls;
                let i = index
              "
              [formGroup]="item"
            >
              <div class="form-group row">
                <div class="col">
                  <label class="control-label"
                    >&nbsp;{{
                      item.controls.scoringCriteriaGroup.value.name
                    }}</label
                  >
                  <div
                    class="table-responsive custom-table float-none"
                    [ngStyle]="{ margin: '0 auto' }"
                  >
                    <table
                      class="table table-striped box-shadow"
                      aria-describedby="Table"
                    >
                      <thead>
                        <tr class="text-center">
                          <th scope="col">{{
                            'app.table.column.no' | translate
                          }}</th>
                          <th scope="col">{{
                            'performance-on-registration.table.column.criteria'
                              | translate
                          }}</th>
                          <th scope="col">{{
                            'performance-on-registration.table.column.value'
                              | translate
                          }}</th>
                          <th scope="col">{{
                            'performance-on-registration.table.column.result'
                              | translate
                          }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container formArrayName="scoringCriteriaList">
                          <tr
                            *ngFor="
                              let data of getFormArray(
                                item,
                                'scoringCriteriaList'
                              ).controls;
                              let j = index
                            "
                            [formGroup]="data"
                          >
                            <td class="text-center">{{ j + 1 }}.</td>
                            <td class="text-center">{{
                              data.value.scoringCriteria.name
                            }}</td>
                            <td class="text-center">
                              <p
                                *ngIf="
                                  (data.value.scoringCriteria.value &&
                                    data.value.scoringCriteria.attribute) ||
                                  formGroup.isView
                                "
                              >
                                {{ data.value.value || '-' }}</p
                              >
                              <div
                                *ngIf="
                                  (!data.value.scoringCriteria.value ||
                                    !data.value.scoringCriteria.attribute) &&
                                  !formGroup.isView
                                "
                                [ngStyle]="{ width: '6rem', margin: '0 auto' }"
                              >
                                <app-text-field
                                  size="XL"
                                  (onChange)="
                                    setResult(
                                      $event,
                                      data,
                                      data.value.scoringCriteria
                                    )
                                  "
                                  type="decimal"
                                  formControlName="value"
                                  [tooltip]="htmlContent"
                                ></app-text-field>
                                <ng-template #htmlContent>
                                  <div [ngStyle]="{ width: '100px' }">
                                    <span class="d-block"
                                      >Min. input:
                                      {{
                                        getMaxMinInput(
                                          data.value.scoringCriteria
                                        )[0]
                                      }}</span
                                    >
                                    <span class="d-block"
                                      >Max. input:
                                      {{
                                        getMaxMinInput(
                                          data.value.scoringCriteria
                                        )[1] || ' - '
                                      }}</span
                                    >
                                  </div>
                                </ng-template>
                              </div>
                            </td>
                            <td class="text-center">
                              <p>{{ data.value.result }}</p>
                            </td>
                          </tr>
                        </ng-container>
                        <tr>
                          <td class="text-center"></td>
                          <td class="text-center"></td>
                          <td class="text-center font-weight-bold">
                            {{
                              'performance-on-registration.table.column.total'
                                | translate
                            }}
                          </td>
                          <td class="text-center font-weight-bold">
                            {{
                              doSetAvgTotal(
                                item.controls.scoringCriteriaList,
                                item.controls.scoringCriteriaGroup.value,
                                item
                              ) | number: '1.2-2'
                            }}
                            <!-- doSetAvgTotal(item.controls.
                            scoringCriteriaList, 
                            item.controls
                            .scoringCriteriaGroup.value) -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </app-fieldset>

        <ng-container>
          <!-- SUMMARY -->
          <app-fieldset
            legend="{{
              'performance-on-registration.legend.scoringSummary' | translate
            }}"
          >
            <div class="form-group row">
              <div class="col">
                <div
                  class="table-responsive custom-table float-none"
                  [ngStyle]="{ margin: '0 auto' }"
                >
                  <table
                    class="table table-striped box-shadow"
                    aria-describedby="Table"
                  >
                    <thead>
                      <tr class="text-center">
                        <th scope="col"
                          >{{ 'app.table.column.no' | translate }}
                        </th>
                        <th scope="col">{{
                          'performance-on-registration.table.column.criteriaGroup'
                            | translate
                        }}</th>
                        <th scope="col">{{
                          'performance-on-registration.table.column.weight'
                            | translate
                        }}</th>
                        <th scope="col">{{
                          'performance-on-registration.table.column.result'
                            | translate
                        }}</th>
                        <th scope="col">{{
                          'performance-on-registration.table.column.total'
                            | translate
                        }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container formArrayName="summaryList">
                        <tr
                          *ngFor="
                            let data of getFormArray(formGroup, 'summaryList')
                              .controls;
                            let j = index
                          "
                          [formGroup]="data"
                        >
                          <td class="text-center">{{ j + 1 }}.</td>
                          <td class="text-center">{{
                            data.value.scoringCriteriaGroup.name
                          }}</td>
                          <td class="text-center">{{ data.value.weight }} %</td>
                          <td class="text-center">
                            {{ data.value.result | number: '1.2-2' }}
                          </td>
                          <td class="text-center">
                            {{
                              data.value.weight * 0.01 * data.value.result
                                | number: '1.2-2'
                            }}
                          </td>
                        </tr>
                      </ng-container>
                      <tr>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center font-weight-bold">
                          {{
                            'performance-on-registration.table.column.totalScoring'
                              | translate
                          }}
                        </td>
                        <td class="text-center font-weight-bold">
                          {{ totalScoring | number: '1.2-2' }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center font-weight-bold">
                          {{
                            'performance-on-registration.table.column.rating'
                              | translate
                          }}
                        </td>
                        <td class="text-center">
                          <app-rating formControlName="rating"></app-rating>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
            </div>
            {{
              'performance-on-registration.form.minimumQualified' | translate
            }}
            =
            {{ performanceOnRegistrationDTO?.scoringTemplate.minimumQualified }}
          </app-fieldset>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'performance-on-registration.form.uploadFile' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-upload
                formControlName="uploadFile"
                [fileUploader]="fileUploader"
              ></app-upload>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'performance-on-registration.form.recommendationNote' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-area formControlName="recommendationNote">
              </app-text-area>
            </div>
          </div>
        </ng-container>
      </form>

      <br />

      <!-- History Activity -->
      <h5 *ngIf="isShowHistoryActivity" class="separator">{{
        'performance-on-registration.form.historyActivity' | translate
      }}</h5>
      <div
        *ngIf="isShowHistoryActivity"
        class="table-responsive custom-table float-none"
        [ngStyle]="{ margin: '0 auto' }"
      >
        <table class="table table-striped box-shadow" aria-describedby="Table">
          <thead>
            <tr class="text-left">
              <th scope="col" class="text-center"
                >{{ 'app.table.column.no' | translate }}
              </th>
              <th scope="col" [ngStyle]="{ width: '150px' }">{{
                'performance-on-registration.form.performanceUser' | translate
              }}</th>
              <th scope="col" [ngStyle]="{ width: '200px' }">{{
                'performance-on-registration.form.recommendationNote'
                  | translate
              }}</th>
              <th scope="col" [ngStyle]="{ width: '150px' }">{{
                'performance-on-registration.form.approver' | translate
              }}</th>
              <th scope="col" [ngStyle]="{ width: '200px' }"
                >{{
                  'performance-on-registration.form.approvalNote' | translate
                }}
              </th>
              <th scope="col" class="text-center">{{
                'performance-on-registration.form.status' | translate
              }}</th>
              <th scope="col" class="text-center">{{
                'performance-on-registration.form.file' | translate
              }}</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr 
            *ngIf="!performanceOnRegistrationDTO?
            .performanceList || performanceOnRegistrationDTO?
            .performanceList.length == 0">
                    <td colspan="99" 
                    class="bg-white text-center"> 
                    {{ 'app.info.noData' | translate }}
                    </td>
                </tr> -->
            <tr
              *ngFor="
                let data of performanceOnRegistrationDTO?.performanceList;
                let i = index
              "
              class="text-left"
            >
              <td class="text-center">{{ i + 1 }}.</td>
              <td [ngStyle]="{ 'white-space': 'normal' }">{{
                data[0].verifiedByUser?.name || '-'
              }}</td>
              <td class="text-break" [ngStyle]="{ 'max-width': '400px' }">{{
                data[0].note || '-'
              }}</td>
              <td class="text-break" [ngStyle]="{ 'max-width': '300px' }">{{
                data[1].approvedByUser?.name || '-'
              }}</td>
              <td [ngStyle]="{ 'white-space': 'normal' }"
                >{{ data[1].approvalNote || '-' }}
              </td>
              <td
                [ngStyle]="{ 'max-width': '200px' }"
                class="text-center text-break"
                >{{ data[1].workflowPrcs.workflowStatus.name || '-' }}</td
              >
              <td *ngIf="data[1].approvalFile" class="text-center"
                ><a
                  href="#"
                  (click)="
                    doDownload(
                      $event,
                      '/performance-on-registration/file/view/' +
                        data[1].approvalFile.uploadedFileName +
                        '/' +
                        global.appConstant.fileType.DOC_PERFORMANCE,
                      data[1].approvalFile.fileName
                    )
                  "
                >
                  {{ data[1].approvalFile.fileName }}</a
                >
              </td>
              <td *ngIf="!data[1].approvalFile" class="text-center"> - </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row justify-content-center mt-5">
        <app-button
          [disabled]="!isEnabled"
          color="SECONDARY"
          (onClick)="doBack()"
          class="mr-1"
          >{{ 'app.button.back' | translate }}
        </app-button>
        <app-button
          *ngIf="!formGroup.isView"
          [disabled]="!isEnabled"
          (onClick)="doSave()"
          >{{ 'app.button.save' | translate }}</app-button
        >
      </div>
    </app-card>
  </div>
</div>
