import { Claim } from "src/app/core/bean/claim";
import { ClaimDoc } from "src/app/core/bean/claim-doc";
import { ClaimVerification } from "src/app/core/bean/claim-verification";
import { Counter } from "src/app/core/bean/counter";
import { ExecutorAssignment } from "src/app/core/bean/executor-assignment";
import { File } from "src/app/core/bean/file";
import { GlStatus } from "src/app/core/bean/gl-status";
import { Verificator } from "src/app/core/bean/verificator";
import { ApprovalPathResponseModel } from "src/app/core/components/app-approval-path-x";
import { ClaimDocMaster } from "../../core/bean/claim-doc-master";
import { AccidentVictim } from "src/app/core/bean/accident-victim";
import { Organization } from "src/app/core/bean/organization";
import { Currency } from "src/app/core/bean/currency";
import { ClaimRequestAmount } from "src/app/core/bean/claim-request-amount";
import { RoomType } from "src/app/core/bean/room-type";

export class ClaimResponse {
    claimDocList: ClaimDoc[] = [];
    fileList: File[] = [];
    claim: Claim;
    isUserApproval: boolean;
    approvalPathResponseModelBean: ApprovalPathResponseModel;
    executorAssignment: ExecutorAssignment;
    verificator: Verificator;
    counter: Counter;
    claimVerificationList: ClaimVerification[] = [];
    glStatus: GlStatus;
    claimDocMasterList: ClaimDocMaster[] = [];
    fileOtherList: File[] = [];
    accidentVictim: AccidentVictim;
    organization: Organization;
    currencyList: Currency[];
    roomTypeList: RoomType[];
    claimRequestAmount: ClaimRequestAmount;
    lastNoteFromUserRequester: ClaimVerification;
}