import { BaseEntity } from '../base/base-entity';
import { CriteriaType } from '../bean/criteria-type';

export class EvaluationCriteriaEntity extends BaseEntity {
  criteriaType: CriteriaType = new CriteriaType();
  name: string;
  query: string;
  description: string;
  isUploadDoc: boolean;
  isLinkToVendorDoc: boolean;
}
