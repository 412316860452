import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { ConverterService } from '../../services/converter.service';
@Component({
  selector: 'app-count',
  templateUrl: './app-count.component.html',
  styleUrls: ['./app-count.component.scss'],
  providers: makeProvider(AppCountComponent),
  encapsulation: ViewEncapsulation.None
})
export class AppCountComponent
  extends BaseValueAccessorComponent<number>
  implements OnChanges
{
  @Input() min: number;
  @Input() max: number;
  public generatedId: string;
  public disableButtonPlus: boolean;
  public disableButtonMinus: boolean;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public converterService: ConverterService
  ) {
    super('app-count', controlContainer, elementRef);
  }

  public onInitBaseValueAccessor(): void {
    this.checkMinMaxValue();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.formControl &&
      ((changes.min &&
        changes.min.currentValue !== changes.min.previousValue) ||
        (changes.max && changes.max.currentValue !== changes.max.previousValue))
    ) {
      this.checkMinMaxValue();
    }
  }

  public checkMinMaxValue(): void {
    this.disableButtonPlus =
      +this.max !== null && +(this.formControl.value || 0) >= this.max;
    this.disableButtonMinus =
      +this.min !== null && +(this.formControl.value || 0) <= this.min;
  }

  public onChangeTextField(): void {
    this.checkMinMaxValue();
    this.onChange.emit(this.formControl.value);
  }

  public onInputTextField(): void {
    this.checkMinMaxValue();
    this.onInput.emit(this.formControl.value);
  }

  public doIncrement(): void {
    const value = this.formGroup.get(this.formControlName).value;
    const newValue = value ? +value + 1 : 1;
    this.formControl.patchValue(newValue);
    this.checkMinMaxValue();
    this.onChange.emit(this.formControl.value);
  }

  public doDecrement(): void {
    const value = this.formGroup.get(this.formControlName).value;
    const newValue = value ? +value - 1 : -1;
    this.formControl.patchValue(newValue);
    this.checkMinMaxValue();
    this.onChange.emit(this.formControl.value);
  }
}
