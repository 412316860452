import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RegistrationVendorResponse } from '../../../../portal/registration/registration-vendor-response';
import { AppPopupService } from '../app-popup.service';
import { AppPopupRegistrationTermConditionComponent } from './app-popup-registration-term-condition.component';
@Injectable({
  providedIn: 'root'
})
export class AppPopupRegistrationTermConditionService {
  constructor(public appPopupService: AppPopupService) {}
  public open(
    registrationVendorResponse: RegistrationVendorResponse
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupRegistrationTermConditionComponent,
      { title: 'registration.title', registrationVendorResponse }
    );
  }
}
