import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { ContractReviewer } from 'src/app/core/bean/contract-reviewer';
import { Officer } from 'src/app/core/bean/officer';
import { User } from 'src/app/core/bean/user';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './app-popup-participant-reviewers.component.html'
})
export class AppPopupParticipantReviewersComponent extends BaseComponentComponent {
  @Input() public contractCollabAccess: ContractReviewer;
  @Input() public todo: string;
  @Input() public emailList: string[];
  @Output() public onChange: EventEmitter<any> = new EventEmitter();
  public userOptionList: OptionListModel<any> = new OptionListModel();
  public isInternal = false;
  public isUserInternalExist = false;
  public isEmailExist = false;
  public dataReady = true;

  constructor() {
    super('contract-draft');
  }

  onInit(): void {
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      userType: [null, Validators.compose([Validators.required()])],
      email: [
        null,
        Validators.compose([Validators.required(), Validators.email()])
      ],
      name: [null, Validators.compose([Validators.required()])],
      position: [null, Validators.compose([Validators.required()])]
    });
  }

  public doSetFormGroup(): void {
    const user: any[] = this.getUserList();
    this.userOptionList.setRequestValues(user);
    if (this.contractCollabAccess != null) {
      this.formGroup.patchValue(this.contractCollabAccess);
      this.formGroup.patchValue({
        userType: this.getUserType()
      });
      if (this.contractCollabAccess.userType === 1) {
        this.isInternal = true;
        this.formGroup.get('name').setIsView(true);
        this.formGroup.get('position').setIsView(true);
        this.formGroup.get('name').clearValidators();
        this.formGroup.get('position').clearValidators();
        this.formGroup.get('name').updateValueAndValidity();
        this.formGroup.get('position').updateValueAndValidity();
      }
    }
  }

  public getUserList(): any[] {
    const user = [];
    user.push({ id: 1, name: 'Internal' });
    user.push({ id: 2, name: 'Eksternal' });
    return user;
  }

  public getUserType(): any {
    if (this.contractCollabAccess.userType === 1) {
      return this.getUserList()[0];
    } else if (this.contractCollabAccess.userType === 2) {
      return this.getUserList()[1];
    } else {
      return this.getUserList()[2];
    }
  }

  public onChangeUserType(event): void {
    if (event) {
      if (event.id === 1) {
        this.isInternal = true;
        this.formGroup.get('name').setIsView(true);
        this.formGroup.get('position').setIsView(true);
        this.formGroup.get('position').clearValidators();
        this.formGroup.get('position').updateValueAndValidity();
      } else {
        this.isInternal = false;
        this.formGroup.get('name').setIsView(false);
        this.formGroup.get('position').setIsView(false);
        this.formGroup.get('position').setValidators([Validators.required()]);
        this.formGroup.get('position').updateValueAndValidity();
      }
      this.formGroup.get('email').reset();
      this.formGroup.get('name').reset();
      this.formGroup.get('position').reset();
    }
  }

  public autoCompleChanged(user: User): void {
    if (user) {
      this.dataReady = false;
      this.isUserInternalExist = false;
      this.isEmailExist = false;
      this.emailList.forEach(email => {
        if (email === user.email && !this.contractCollabAccess) {
          this.isUserInternalExist = true;
        } else if (
          email === user.email &&
          this.contractCollabAccess &&
          this.contractCollabAccess.email !== user.email
        ) {
          this.isUserInternalExist = true;
        }
      });
      this.formGroup.patchValue({
        email: user.email,
        name: user.name
      });
      this.httpClientService
        .get<Officer>('/contract-draft/get-position/' + user.email)
        .subscribe((officer: Officer) => {
          this.formGroup.patchValue({
            position: officer
              ? officer.position
                ? officer.position.name
                : null
              : null
          });
          this.dataReady = true;
        });
    }
  }

  public onChangeEmailEksternal(): void {
    const emailEksternal = this.formGroup.value.email;
    this.isUserInternalExist = false;
    this.isEmailExist = false;
    this.emailList.forEach(email => {
      if (email === emailEksternal && !this.contractCollabAccess) {
        this.isEmailExist = true;
      } else if (
        email === emailEksternal &&
        this.contractCollabAccess &&
        this.contractCollabAccess.email !== emailEksternal
      ) {
        this.isEmailExist = true;
      }
    });
  }

  public doSave(): void {
    this.validate();
    if (
      this.formGroup.valid &&
      !this.isUserInternalExist &&
      !this.isEmailExist &&
      this.dataReady
    ) {
      this.onChange.emit(this.formGroup.value);
    } else if (this.formGroup.valid && this.isUserInternalExist) {
      this.global.alertService.showError(
        'contract-draft.validation.userInternalExist',
        '.participant-reviewer'
      );
    } else if (this.formGroup.valid && this.isEmailExist) {
      this.global.alertService.showError(
        'contract-draft.validation.emailEksternalExist',
        '.participant-reviewer'
      );
    }
  }

  public prepareContractCollabAccess(): any {
    if (this.contractCollabAccess == null) {
      return {
        userType: this.formGroup.value.userType.id,
        email: this.formGroup.value.email,
        name: this.formGroup.value.name,
        position: this.formGroup.value.position
      };
    } else {
      this.contractCollabAccess.userType = this.formGroup.value.userType.id;
      this.contractCollabAccess.email = this.formGroup.value.email;
      this.contractCollabAccess.name = this.formGroup.value.name;
      this.contractCollabAccess.position = this.formGroup.value.position;
      return this.contractCollabAccess;
    }
  }
}
