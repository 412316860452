import { Injectable } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Global } from '../../global/global';
import { ServiceLocator } from '../../services/service-locator';
@Injectable()
export class DateFormatter extends NgbDateParserFormatter {
  parse(): NgbDateStruct {
    // if (value) {
    // value = value.trim();
    // moment(value, this.DT_FORMAT);
    // }
    return null;
  }

  format(date: NgbDateStruct): string {
    const global = ServiceLocator.injector.get(Global);
    const DT_FORMAT = global.config.parameterModel.dateFormatShort.toUpperCase();
    if (!date) {
      return '';
    }
    const mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) {
      return '';
    }
    return mdt.format(DT_FORMAT);
  }
}
