import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';

@Component({
  selector: 'app-alert-icon',
  templateUrl: './app-alert-icon.component.html',
  styleUrls: ['./app-alert-icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppAlertIconComponent extends BaseComponentComponent {
  @Input() public message: string;
  @Input() public color: 'SUCCESS' | 'INFO' | 'DANGER' | 'WARNING';
  @Input() public isShowCloseButton: boolean;
  @Input() public icon: string;
  constructor() {
    super('app-alert-icon');
  }

  public readonly ALERT_COLOR: object = {
    SUCCESS: 'alert-success',
    INFO: 'alert-info',
    DANGER: 'alert-danger',
    WARNING: 'alert-warn'
  };
  public readonly ALERT_ICON: object = {
    FILE_DOWNLOAD: 'pv-file-download',
    CONFETTI: 'pv-confetti',
    FILES_ALT_CIRCLE_CHECK: 'pv-files-alt-circle-check',
    TROPHY: 'pv-trophy',
    SANDGLASS: 'pv-sandglass',
    HAND_TIMES_CIRCLE: 'pv-hand-times-circle',
    WALLET: 'pv-wallet'
  };

  public onInit(): void {
    this.createAlert();
    this.setStateReady();
  }

  public createAlert(): void {
    this.isShowCloseButton =
      typeof this.isShowCloseButton === 'undefined'
        ? false
        : this.isShowCloseButton;
    this.color = this.color
      ? this.ALERT_COLOR[this.color]
      : this.ALERT_COLOR['INFO'];
    this.icon = this.icon
      ? this.ALERT_ICON[this.icon]
      : this.ALERT_ICON['CONFETTI'];
  }
}
