import { Component, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { FileType } from '../../core/bean/file-type';
import { ScoringCriteria } from '../../core/bean/scoring-criteria';
import { ScoringCriteriaGroup } from '../../core/bean/scoring-criteria-group';
import { VendorPerformance } from '../../core/bean/vendor-performance';
import { VendorPerformanceScore } from '../../core/bean/vendor-performance-score';
import { VendorPerformanceSummary } from '../../core/bean/vendor-performance-summary';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { PerformanceOnRegistrationDTO } from './dto/performance-on-registration.dto';
@Component({
  templateUrl: './performance-on-registration-edit.component.html'
})
export class PerformanceOnRegistrationEditComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<ScoringCriteria>;
  public vendorId: number;
  public performanceOnRegistrationDTO: PerformanceOnRegistrationDTO;
  public isSummary = false;
  public isView = false;
  public totalScoring = 0;
  public totalScoring1 = 0;
  public totalResultCommon: number[] = [];
  public totalResultSpecific: number[] = [];
  public resultList: number[] = [];
  public scoringCriteriaGroupList: ScoringCriteriaGroup[] = [];
  public employeeAllFileType: FileType = this.global.config.allowedFile.get('');
  public fileUploader: FileUploader = new FileUploader(
    '/performance-on-registration/',
    'performance-on-registration.form.headerImage',
    this.global.appConstant.fileType.DOC_PERFORMANCE
  );

  public isShowHistoryActivity = false;

  public readonly urlBackToIndex = '/pages/performance-registration';
  constructor(translateService: TranslateService) {
    super('performance-on-registration', translateService);
  }

  onInit(): void {
    this.getFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public showHistoryActivity(): void {
    if (
      this.performanceOnRegistrationDTO.performance &&
      this.performanceOnRegistrationDTO.performance.workflowPrcs &&
      this.performanceOnRegistrationDTO.performance.workflowPrcs.workflowStatus.code
        .toUpperCase()
        .includes('REVISI')
    ) {
      this.isShowHistoryActivity = true;
    }
  }

  public getFromRouterParams(): void {
    this.vendorId = this.global.routerParams.get('vendorId');
    this.todo = this.global.routerParams.get('todo');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorName: [null],
      address: [null],
      vendorType: [null],
      uploadFile: [null],
      recommendationNote: [null],
      commonList: this.formBuilder.array([]),
      specificList: this.formBuilder.array([]),
      summaryList: this.formBuilder.array([]),
      performanceUser: [null],
      rating: [null]
    });
  }

  public setFormGroup(): void {
    this.formGroup.get('rating').setIsView(true);
    this.httpClientService
      .post<PerformanceOnRegistrationDTO>(
        '/performance-on-registration/edit',
        new RouteRequestModel(this.todo, this.vendorId)
      )
      .subscribe(performanceOnRegistrationDTO => {
        this.performanceOnRegistrationDTO = performanceOnRegistrationDTO;
        this.performanceOnRegistrationDTO.performanceScoreList = [];
        this.performanceOnRegistrationDTO.performanceSummaryList = [];
        this.performanceOnRegistrationDTO.scoringCriteriaGroupCommonList.forEach(
          common => {
            this.scoringCriteriaGroupList.push(common);
          }
        );
        this.performanceOnRegistrationDTO.scoringCriteriaGroupSpesificList.forEach(
          specific => {
            this.scoringCriteriaGroupList.push(specific);
          }
        );
        if (this.performanceOnRegistrationDTO.vendor !== null) {
          this.formGroup.patchValue({
            vendorName: this.performanceOnRegistrationDTO.vendor.name,
            vendorType: this.performanceOnRegistrationDTO.vendor.vendorType
              .name,
            address: this.performanceOnRegistrationDTO.vendorAddress.address
          });
        }
        this.buildCommonList();
        this.buildSpecificList();
        this.buildSummaryList();
        this.showHistoryActivity();

        this.setStateReady();
      });
  }

  public buildCommonList(): void {
    const parentForm = this.formGroup.controls.commonList as FormArray;
    this.performanceOnRegistrationDTO.scoringCriteriaGroupCommonList.forEach(
      common => {
        const formGroup = this.formBuilder.group({
          scoringCriteriaGroup: common,
          scoringCriteriaList: this.formBuilder.array([]),
          avgTotal: [null]
        });
        parentForm.push(formGroup);
      }
    );
    this.buildScoringCriteriaCommonList();
  }

  public buildSpecificList(): void {
    const parentForm = this.formGroup.controls.specificList as FormArray;
    this.performanceOnRegistrationDTO.scoringCriteriaGroupSpesificList.forEach(
      specific => {
        const formGroup = this.formBuilder.group({
          scoringCriteriaGroup: specific,
          scoringCriteriaList: this.formBuilder.array([]),
          avgTotal: [null]
        });
        parentForm.push(formGroup);
      }
    );
    this.buildScoringCriteriaSpecificList();
  }

  public buildSummaryList(): void {
    const parentForm = this.formGroup.controls.summaryList as FormArray;
    this.performanceOnRegistrationDTO.scoringTemplateWeightList.forEach(
      scoringTempW => {
        const formGroup = this.formBuilder.group({
          scoringCriteriaGroup: scoringTempW.scoringCriteriaGroup,
          weight: scoringTempW.weight,
          result: [null],
          total: [null]
        });
        parentForm.push(formGroup);
      }
    );
  }

  // set Result for manual input
  public setResult(
    eventValue: any,
    data: FormGroup,
    scoringCriteria: ScoringCriteria
  ): void {
    eventValue = eventValue ? parseFloat(eventValue) : null;

    const rangeList = scoringCriteria.scoringCriteriaRangeList.filter(
      range => range.from <= eventValue && eventValue <= range.until
    );
    if (rangeList.length > 0) {
      data.value.result = rangeList[0].result;
    } else {
      const rangeListInfinite = scoringCriteria.scoringCriteriaRangeList.filter(
        range => range.from <= eventValue && range.isInfinity
      );
      data.value.result =
        rangeListInfinite.length > 0 ? rangeListInfinite[0].result : null;
    }

    data.patchValue({
      value: eventValue,
      result: data.value.result
    });
  }

  public getMaxMinInput(scoringCriteria: ScoringCriteria): number[] {
    const lastIndex = scoringCriteria.scoringCriteriaRangeList.length - 1;
    const minInput = scoringCriteria.scoringCriteriaRangeList[0].from;
    const maxInput = scoringCriteria.scoringCriteriaRangeList[lastIndex].until;

    const inputList: number[] = [];
    inputList.push(minInput);
    inputList.push(maxInput);
    return inputList;
  }

  public doSetAvgTotal(
    criteriaList: FormArray,
    scoringCriteriaGroup: ScoringCriteriaGroup,
    itemCommonSpesific: FormGroup
  ): number {
    let avgTotal = 0; // perhitungan rata2
    criteriaList.controls.forEach(data => {
      avgTotal += data.value.result * 100;
    });
    avgTotal = Math.round(avgTotal) / 100 / criteriaList.controls.length;

    itemCommonSpesific.patchValue({
      avgTotal
    });

    // di set ke formArray summaryList
    this.totalScoring = 0;

    const summaryList = this.formGroup.get('summaryList') as FormArray;
    summaryList.controls.forEach((summaryControl, index) => {
      if (
        summaryControl.value.scoringCriteriaGroup.code ===
        scoringCriteriaGroup.code
      ) {
        summaryList.at(index).patchValue({
          result: avgTotal,
          total: avgTotal * summaryControl.value.weight * 0.01
        });
      }

      this.totalScoring +=
        summaryControl.value.weight * 0.01 * summaryControl.value.result * 100;
    });
    this.totalScoring = Math.round(this.totalScoring) / 100;
    this.formGroup.patchValue({ rating: this.totalScoring });
    this.formGroup.get('rating').setIsView(true);

    return avgTotal;
  }

  public buildScoringCriteriaCommonList(): void {
    const parentForm = this.formGroup.controls.commonList as FormArray;
    this.performanceOnRegistrationDTO.scoringCriteriaGroupCommonList.forEach(
      (common, index) => {
        let total = 0;
        common.scoringCriteriaList.forEach(scoringCriteria => {
          let minInput = 0;
          let maxInput = 0;

          if (scoringCriteria.scoringCriteriaRangeList.length !== 0) {
            minInput = this.getMaxMinInput(scoringCriteria)[0];
            maxInput = this.getMaxMinInput(scoringCriteria)[1];
          }

          const formGroup = this.formBuilder.group({
            scoringCriteria,
            value:
              scoringCriteria.value && scoringCriteria.attribute
                ? scoringCriteria.value
                : [
                    scoringCriteria.value,
                    Validators.compose([
                      Validators.required(),
                      Validators.min(minInput),
                      Validators.max(maxInput)
                    ])
                  ],
            result:
              scoringCriteria.result && scoringCriteria.attribute
                ? scoringCriteria.result
                : [scoringCriteria.result]
          });
          total = total + scoringCriteria.result;
          const scoringCriteriaListForm = parentForm.controls[index].get(
            'scoringCriteriaList'
          ) as FormArray;
          scoringCriteriaListForm.push(formGroup);
        });
        total =
          Math.round((total / common.scoringCriteriaList.length) * 100) / 100;
        this.totalResultCommon.push(total);
      }
    );
  }

  public buildScoringCriteriaSpecificList(): void {
    const parentForm = this.formGroup.controls.specificList as FormArray;
    this.performanceOnRegistrationDTO.scoringCriteriaGroupSpesificList.forEach(
      (specific, index) => {
        let total = 0;
        specific.scoringCriteriaList.forEach(scoringCriteria => {
          let minInput = 0;
          let maxInput = 0;

          if (scoringCriteria.scoringCriteriaRangeList.length !== 0) {
            minInput = this.getMaxMinInput(scoringCriteria)[0];
            maxInput = this.getMaxMinInput(scoringCriteria)[1];
          }

          const formGroup = this.formBuilder.group({
            scoringCriteria,
            value:
              scoringCriteria.value && scoringCriteria.attribute
                ? scoringCriteria.value
                : [
                    scoringCriteria.value,
                    Validators.compose([
                      Validators.required(),
                      Validators.min(minInput),
                      Validators.max(maxInput)
                    ])
                  ],
            result:
              scoringCriteria.result && scoringCriteria.attribute
                ? scoringCriteria.result
                : [scoringCriteria.result]
          });
          total = total + scoringCriteria.result;
          const scoringCriteriaListForm = parentForm.controls[index].get(
            'scoringCriteriaList'
          ) as FormArray;
          scoringCriteriaListForm.push(formGroup);
        });
        total =
          Math.round((total / specific.scoringCriteriaList.length) * 100) / 100;
        this.totalResultSpecific.push(total);
      }
    );
  }

  public doBack(): void {
    this.global.routerParams.clear();
    this.router.navigate([this.urlBackToIndex]);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.savePerformance();
          }
        });
    }
  }

  public savePerformance(): void {
    this.setStateProcessing();

    const performanceFormGroup = this.formGroup.value;
    const performance: VendorPerformance = new VendorPerformance();
    performance.createdDate = new Date();
    performance.rating = performanceFormGroup.rating;
    performance.note = performanceFormGroup.recommendationNote;
    // const fileUpload = performanceFormGroup.uploadFile !== null ? performanceFormGroup.uploadFile : [];
    // if (performanceFormGroup.uploadFile !== null) {
    //   performance.file = performanceFormGroup.uploadFile[0].file;
    // }
    // performance.file = this.formGroup.get('uploadFile').value;
    this.performanceOnRegistrationDTO.uploadFileList = this.formGroup.get(
      'uploadFile'
    ).value;
    performanceFormGroup.commonList.forEach(scoringGroup => {
      scoringGroup.scoringCriteriaList.forEach(scoringCriteria => {
        const performanceScore: VendorPerformanceScore = new VendorPerformanceScore();
        performanceScore.result = scoringCriteria.result;
        performanceScore.value = scoringCriteria.value;
        performanceScore.scoringCriteriaCode =
          scoringCriteria.scoringCriteria.code;
        performanceScore.scoringCriteriaName =
          scoringCriteria.scoringCriteria.name;
        performanceScore.scoringCriteriaGroupCode =
          scoringGroup.scoringCriteriaGroup.code;
        performanceScore.scoringCriteriaGroupName =
          scoringGroup.scoringCriteriaGroup.name;
        this.performanceOnRegistrationDTO.performanceScoreList.push(
          performanceScore
        );
      });
    });

    performanceFormGroup.specificList.forEach(scoringGroup => {
      scoringGroup.scoringCriteriaList.forEach(scoringCriteria => {
        const performanceScore: VendorPerformanceScore = new VendorPerformanceScore();
        performanceScore.result = scoringCriteria.result;
        performanceScore.value = scoringCriteria.value;
        performanceScore.scoringCriteriaCode =
          scoringCriteria.scoringCriteria.code;
        performanceScore.scoringCriteriaName =
          scoringCriteria.scoringCriteria.name;
        performanceScore.scoringCriteriaGroupCode =
          scoringGroup.scoringCriteriaGroup.code;
        performanceScore.scoringCriteriaGroupName =
          scoringGroup.scoringCriteriaGroup.name;
        this.performanceOnRegistrationDTO.performanceScoreList.push(
          performanceScore
        );
      });
    });

    performanceFormGroup.summaryList.forEach(summary => {
      const performanceSummary: VendorPerformanceSummary = new VendorPerformanceSummary();
      performanceSummary.result = Math.round(summary.result * 100) / 100;
      performanceSummary.total = Math.round(summary.total * 100) / 100;
      performanceSummary.weight = summary.weight;
      performanceSummary.scoringCriteriaGroupName =
        summary.scoringCriteriaGroup.name;
      performanceSummary.scoringCriteriaGroupCode =
        summary.scoringCriteriaGroup.code;
      this.performanceOnRegistrationDTO.performanceSummaryList.push(
        performanceSummary
      );
    });
    this.performanceOnRegistrationDTO.performance = performance;
    this.performanceOnRegistrationDTO.performanceList = []; // kosongkan krna join

    this.httpClientService
      .post<Response<PerformanceOnRegistrationDTO>>(
        '/performance-on-registration/insert',
        this.performanceOnRegistrationDTO
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccessSavingOnNextRoute();
          this.router.navigate([this.urlBackToIndex]);
        } else {
          this.global.alertService.showError(response.statusText);
          this.setStateReady();
        }
      });
  }

  public getFormArray(
    formGroup: FormGroup,
    formControlName: string
  ): FormArray {
    return formGroup.get(formControlName) as FormArray;
  }
}
