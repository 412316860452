import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Attribute } from '../../core/bean/attribute';
import { FormObject } from '../../core/bean/form-object';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { AttributeResponse } from './attribute-response';
import { AttributeRequest } from './attribute-request';
@Component({
  templateUrl: './attribute-edit-add.component.html'
})
export class AttributeEditAddComponent extends BaseModuleComponent {
  public attribute: Attribute = new Attribute();
  public attributeResponse: AttributeResponse = new AttributeResponse();
  public attributeResponseTemp: AttributeResponse = new AttributeResponse();
  public attributeRequest: AttributeRequest = new AttributeRequest();
  public attributeRequestTemp: AttributeRequest = new AttributeRequest();
  public attributeId: number;
  public langList = [];
  public formObjectValue: boolean;
  public isDisabled: boolean;
  public translationListLabel = [];
  public formObjectOptionList: OptionListModel<FormObject> = new OptionListModel(
    true
  );
  constructor(translateService: TranslateService) {
    super('attribute', translateService);
  }

  onInit(): void {
    this.doGetAndSetDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public doGetAndSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.attributeId = this.global.routerParams.get('attributeId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      description: [null, Validators.maxLength(512)],
      className: [null, Validators.required()],
      formObject: [null, Validators.compose([Validators.required()])],
      translationKey: [null],
      groupName: [null],
      credentials: this.formBuilder.array([]),
      translationList: this.formBuilder.array([])
    });
  }

  public showFormOption(): void {
    if (this.formGroup.get('formObject').value.isValue) {
      this.formObjectValue = true;
      this.setListOfValue(this.attributeResponseTemp);
    } else {
      this.formObjectValue = false;
      this.setListOfValue(this.attributeResponseTemp);
    }
  }

  public addCreds(): void {
    this.inputList.push(
      this.formBuilder.group({
        value: null,
        name: null
      })
    );
  }

  public get inputList(): FormArray {
    return this.formGroup.get('credentials') as FormArray;
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<AttributeResponse>(
        '/attribute/add-edit',
        new RouteRequestModel(this.todo, this.attributeId)
      )
      .subscribe(attributeResponse => {
        this.attributeResponseTemp = attributeResponse;
        this.langList = attributeResponse.langList;
        this.formObjectOptionList.setRequestValues(attributeResponse.formObjectList);
        this.setTranslation(attributeResponse);
        if (attributeResponse.attribute != null) {
          this.setAttribute(attributeResponse);
          this.showFormOption();
        }
        this.setStateReady();
      });
  }

  public setAttribute(attributeResponse: AttributeResponse): void {
    const {
      id,
      code,
      description,
      className,
      formObject,
      translationKey,
      groupName
    } = attributeResponse.attribute;
    this.formGroup.patchValue({
      id,
      code,
      description,
      className,
      formObject,
      translationKey,
      groupName
    });
  }

  public setTranslation(attributeResponse: AttributeResponse): void {
    if (attributeResponse.translationList.length > 0) {
      attributeResponse.translationList.forEach(translation => {
        this.translationListLabel.push(translation.lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: translation.value
          })
        );
      });
    } else {
      attributeResponse.langList.forEach(lang => {
        this.translationListLabel.push(lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: [null, Validators.required()]
          })
        );
      });
    }
  }

  public setListOfValue(attributeResponse: AttributeResponse): void {
    if (attributeResponse.listOfValueModel !== null) {
      const inputListLength = this.inputList.length;
      if (
        this.formGroup.get('formObject').value.code !==
        attributeResponse.attribute.formObject.code
      ) {
        for (let i = 0; i <= inputListLength - 1; i++) {
          this.inputList.removeAt(0);
        }
      } else {
        this.formObjectValue = true;
        attributeResponse.listOfValueModel.forEach(listOfValue => {
          this.inputList.push(
            this.formBuilder.group({
              name: listOfValue.name,
              value: listOfValue.value
            })
          );
        });
      }
    }
  }

  public doRemove(index: number): void {
    this.inputList.removeAt(index);
    this.isDisabled = false;
    // const lastIndex = index - 1;
  }

  public doSave(): void {
    this.validate();
    this.setFormArrayValidation();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.saveAttribute();
          }
        });
    }
  }

  public setFormArrayValidation(): void {
    for (const control of this.translationList.controls) {
      control.setStateSubmitted();
    }
  }

  public saveAttribute(): void {
    this.setStateProcessing();
    this.attributeRequest.attribute = this.formGroup.value;
    this.attributeRequest.translationValueList = this.formGroup
      .get('translationList')
      .value.map(a => a.value);
    this.attributeRequest.listOfValueModel = this.formGroup.get(
      'credentials'
    ).value;
    const url =
      this.todo === 'edit' ? '/attribute/update' : '/attribute/insert';
    this.httpClientService
      .post<Response<AttributeRequest>>(url, this.attributeRequest)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccessSavingOnNextRoute();
          this.router.navigate(['pages/attribute']);
          this.translateService.reloadLang(this.global.getCurrentLang());
        } else {
          this.global.alertService.showError(response.statusText);
        }
        this.setStateReady();
      });
  }

  public get translationList(): FormArray {
    return this.formGroup.get('translationList') as FormArray;
  }

  public doCancel(): void {
    this.router.navigate(['pages/attribute']);
  }
}
