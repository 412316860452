<ng-container *ngIf="!formLoading && activityType === 1">
  <app-table
    [model]="tableResponseContract"
    [isServerSide]="true"
    stringUrl="/app-activity-history-view/index-contract-activity"
    [customData]="contractId"
  >
  </app-table>
</ng-container>
<ng-container *ngIf="!formLoading && activityType === 2">
  <app-table
    [model]="tableResponsePayment"
    [isServerSide]="true"
    stringUrl="/app-activity-history-view/index-payment-activity"
    [customData]="paymentId"
  >
  </app-table>
</ng-container>
