import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAutoCompleteModule } from '../app-auto-complete/app-auto-complete.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppDropdownSelectModule } from '../app-dropdown-select/app-dropdown-select.module';
import { AppTextFieldModule } from '../app-text-field/app-text-field.module';
import { AppVendorPaymentInformationComponent } from './app-vendor-payment-information.component';

@NgModule({
  imports: [
    VendorModule,
    AppButtonModule,
    AppCardModule,
    AppDropdownSelectModule,
    AppAutoCompleteModule,
    AppTextFieldModule
  ],
  declarations: [AppVendorPaymentInformationComponent],
  exports: [AppVendorPaymentInformationComponent]
})
export class AppVendorPaymentInformationModule {}
