<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <div class="app-accordion-row">
      <app-workflow-progress-info
        *ngIf="
          !formLoading &&
          (!principlePermitResponse.isUserApproval ||
            principlePermitResponse.isUserApproval === null)
        "
        [workPlan]="workPlan"
        [currentPosition]="moduleCode"
      >
      </app-workflow-progress-info>
      <app-card [isLoading]="formLoading" [isSaving]="formSaving">
        <app-work-plan-info [workPlanId]="workPlanId"> </app-work-plan-info>
        <app-accordion [isExpandAll]="true" [isLoading]="formLoading">
          <app-accordion-item
            header="principle-permit-submission.principlePermitSubmission"
          >
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>
              <div
                *ngIf="
                  !(
                    principlePermitResponse.isNumberGenerator &&
                    !formGroup.isView
                  )
                "
                class="form-group row text-sm-right"
                [ngClass]="!formGroup.isView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'principle-permit-submission.form.number' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-text-field
                    autofocus="true"
                    formControlName="number"
                    maxLength="64"
                  ></app-text-field>
                </div>
              </div>

              <div
                class="form-group row text-sm-right"
                [ngClass]="!formGroup.isView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'principle-permit-submission.form.date' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-date-picker
                    formControlName="createdDate"
                  >
                  </app-date-picker>
                </div>
              </div>

              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'principle-permit-submission.form.organization' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-text-tree
                    formControlName="organization"
                    stringUrl="/organization"
                    isLazy="true"
                    header="Organization"
                  >
                  </app-text-tree>
                </div>
              </div>

              <div
                class="form-group row text-sm-right"
                [ngClass]="!formGroup.isView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'principle-permit-submission.form.subject' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-text-field formControlName="subject" maxLength="128">
                  </app-text-field>
                </div>
              </div>

              <div
                class="form-group row text-sm-right"
                [ngClass]="!formGroup.isView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'principle-permit-submission.form.description' | translate
                }}</label>
                <div
                  class="text-left"
                  [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'"
                >
                  <app-text-area formControlName="description" maxLength="512">
                  </app-text-area>
                </div>
              </div>

              <div
                class="form-group row text-sm-right"
                [ngClass]="!formGroup.isView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'principle-permit-submission.form.uploadFile' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-upload-multiple
                    formControlName="uploadFile"
                    [fileUploader]="fileUploader"
                    (onChange)="onChangeFileUpload($event)"
                  >
                  </app-upload-multiple>
                </div>
              </div>

              <div class="button-group button-group-center mt-5">
                <app-button
                  color="SECONDARY"
                  (onClick)="doBack()"
                  [disabled]="formSaving"
                  *ngIf="!principlePermitResponse.isUserApproval"
                  >{{ 'app.button.back' | translate }}
                </app-button>
                <app-button
                  (onClick)="doSave()"
                  [disabled]="formSaving"
                  *ngIf="!formGroup.isView"
                  >{{ 'app.button.save' | translate }}
                </app-button>
              </div>
            </form>
          </app-accordion-item>
          <app-accordion-item
            header="principle-permit-submission.monitoringApproval"
            [isHidden]="
              !(
                !formLoading &&
                !principlePermitResponse.isUserApproval &&
                principlePermitResponse.approvalPathResponseModel != null &&
                todo !== 'edit' &&
                todo !== 'add'
              )
            "
          >
            <app-approval-path [model]="approvalPath">
              <ng-template #customText let-userApprover>
                <span class="d-block" [ngStyle]="{ color: '#343434' }">{{
                  userApprover.user?.name || '-'
                }}</span>
                <span
                  class="d-block"
                  [ngStyle]="{ color: '#343434' }"
                  *ngIf="userApprover.delegateUser"
                  >({{ userApprover.delegateUser?.name }})</span
                >
                <span class="d-block" [ngStyle]="{ color: '#343434' }">{{
                  userApprover.position?.name || '-'
                }}</span>
                <span class="d-block" [ngStyle]="{ color: '#343434' }">{{
                  userApprover.organization?.name || '-'
                }}</span>
              </ng-template>
            </app-approval-path>
          </app-accordion-item>
          <app-accordion-item
            header="principle-permit-submission.form.logApproval"
            [isHidden]="
              !(
                !formLoading &&
                !principlePermitResponse.isUserApproval &&
                principlePermitResponse.approvalPathResponseModel !== null &&
                todo !== 'add'
              )
            "
          >
            <app-approval-log
              [moduleCode]="moduleCode"
              [workflowPrcs]="workflowPrcs"
            >
            </app-approval-log>
          </app-accordion-item>
        </app-accordion>
      </app-card>
    </div>
    <app-approval-prcs
      *ngIf="principlePermitResponse.isUserApproval"
      [workflowModelPrcs]="workflowModelPrcs"
    >
    </app-approval-prcs>
  </div>
</div>
