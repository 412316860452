import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { InitiationType } from 'src/app/core/bean/initiation-type';
import { OptionListModel } from 'src/app/core/model/option-list-model';

@Component({
  templateUrl: 'initiation-popup-select-initiation-type.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InitiationPopupSelectInitiationTypeComponent extends BaseComponentComponent {
  @Input() public initiationTypeList: InitiationType[];
  public optionList: OptionListModel<InitiationType> = new OptionListModel(true);
  @Output() public onChange: EventEmitter<InitiationType[]> = new EventEmitter();
  public initButtonIsDisable: boolean = true;

  constructor(public translateService: TranslateService) {
    super('initiation');
  }

  public onInit(): void {
    this.optionList.setRequestValues(this.initiationTypeList);

    this.formGroup = this.formBuilder.group({
      initiationTypeList: [null]
    });

    this.setStateReady();
  }

  public doSelectInitationType(): void {
    this.onChange.emit(this.formGroup.value);
  }

  public disableSelectInitiationButton(): void{
    this.initButtonIsDisable = false;
  }
}
