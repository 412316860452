import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppChooseModule } from '../../app-choose/app-choose.module';
import { AppTextAreaModule } from '../../app-text-area/app-text-area.module';
import { AppTextFieldModule } from '../../app-text-field/app-text-field.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupAddressBookComponent } from './app-popup-address-book.component';
import { AppPopupAddressBookService } from './app-popup-address-book.service';
@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppChooseModule,
    AppTextFieldModule,
    AppTextAreaModule,
    AppButtonModule
  ],
  declarations: [AppPopupAddressBookComponent],
  entryComponents: [AppPopupAddressBookComponent],
  providers: [AppPopupAddressBookService],
  exports: [AppPopupAddressBookComponent]
})
export class AppPopupAddressBookModule {}
