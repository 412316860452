import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AanwijzingParticipant } from 'src/app/core/bean/aanwijzing-participant';
import { Region } from 'src/app/core/bean/region';
import { SignatureModel } from 'src/app/core/components/app-signature/signature.model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';
import { AanwijzingOthersRequest } from './aanwijzing-others.request';

@Component({
  templateUrl: './aanwijzing-others-popup-session.component.html'
})
export class AanwijzingOthersPopupSessionComponent
  extends BaseModuleComponent
  implements OnInit
{
  @Input() regionList: Region[];
  @Input() public aanwijzingParticipant: AanwijzingParticipant;
  public countryRegionOptionList: OptionListModel<Region> = new OptionListModel(
    true
  );
  public isSureAlert = false;
  public model: SignatureModel = new SignatureModel();
  public isCanceled = false;
  public isBlank: boolean;

  @Output() onChange: EventEmitter<AanwijzingParticipant> = new EventEmitter();

  constructor(
    translateService: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    super('aanwijzing-others', translateService);
  }

  public onInit(): void {
    this.buildAndSetFormGroup();
    this.setStateReady();
  }

  public buildAndSetFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required()],
      position: [null, Validators.required()],
      phoneNumber: [null, Validators.required()],
      signatureImg: [null, Validators.required()],
      isCheck: [null, Validators.required()]
    });

    this.countryRegionOptionList.setRequestValues(this.regionList);

    this.formGroup.get('phoneNumber').patchValue({
      country: this.countryRegionOptionList
        .getRequestValues()
        .find(region => region.code === 'ID'),
      number: null
    });
  }

  public doJoinFirst(): void {
    this.setStateProcessing();
    this.model.requestUpload().subscribe(() => {
      this.setStateReady();
      this.validate();
      if (this.formGroup.valid) {
        if (!this.isSureAlert) {
          this.setViewOnly();
          this.isSureAlert = true;
        }
      }
    });
  }

  public doJoinFinal(): void {
    this.setStateProcessing();
    this.aanwijzingParticipant.name = this.formGroup.value.name;
    this.aanwijzingParticipant.positionName = this.formGroup.value.position;
    this.aanwijzingParticipant.phoneRegion =
      this.formGroup.value.phoneNumber.country;
    this.aanwijzingParticipant.phone = this.formGroup.value.phoneNumber.number;

    this.log.debug(this.formGroup.value.signatureImg);

    const req = new AanwijzingOthersRequest();
    req.aanwijzingParticipant = this.aanwijzingParticipant;
    req.fileObject = this.formGroup.value.signatureImg[0];

    this.httpClientService
      .post<any>('/aanwijzing-others/update', req)
      .subscribe(response => {
        this.log.debug(response);

        this.onChange.emit(this.formGroup.value);
      });
  }

  public doEdit(): void {
    this.formGroup.setIsView(false);
    this.isSureAlert = false;

    this.formGroup.get('phoneNumber').patchValue({
      country: this.formGroup.value.phoneNumber.country,
      number: this.formGroup.value.phoneNumber.number
    });
    this.setStateLoading();
    setTimeout(() => {
      this.setStateReady();
    });
  }

  public doCancel(): void {
    this.activeModal.close();
    this.router.navigate(['/portal/home']);
  }

  public onChangeBlank(event): void {
    this.isBlank = event.isBlank;
  }
}
