<app-popup header="app-popup-user-toc.title" (onClose)="onClosePopup()">
  <form [formGroup]="formGroup">
    <div class="row main-row">
      <div class="col-12">
        <div class="app-popup-user-toc" [innerHTML]="termsAndCondition"></div>
      </div>
    </div>
    <br />
    <app-check-box formControlName="check" position="HORIZONTAL">{{
      'app-popup-user-toc.confirmation' | translate
    }}</app-check-box>
  </form>
  <div
      class="col-sm-12 alert-dark text-left"
      [ngStyle]="{ height: '2%', 'border-radius': '3px', padding: '10px' }"
    >
      <!-- Click here for download registration manual book -->
      {{ 'app-popup-user-toc.form.downloadUserManual1' | translate }}
      <a (click)="downloadUserToc()" [ngStyle]="{ color: '#87b4e7' }">
        {{ 'app-popup-user-toc.form.downloadUserManual2' | translate }}
        <!-- click here -->
      </a>
  </div>
  <div class="button-group button-group-center mt-5">
    <button
      type="button"
      class="btn btn-primary"
      (click)="doSave()"
      [disabled]="formSaving"
    >
      {{ 'app.button.agree' | translate }}
    </button>
  </div>
</app-popup>
