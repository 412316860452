<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="item.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-table [model]="tableResponse" [isServerSide]="true"
        stringUrl="/item/index">
        <ng-template #headerButtons>
          <app-button 
          mode="BYPASS"
          (onClick)="doExport()">    <em
            class="fas"
            [ngClass]="
              !isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'
            "
          ></em>
            {{
            'app.button.export' | translate }}</app-button>
          <app-button (onClick)="doAdd()"><em class="fas fa-plus"></em>{{
            'app.button.add' | translate }}</app-button>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button *ngIf="rowData.itemStatusCode === this.global.appConstant.core.ITEM_STATUS_DONE 
            || rowData.itemStatusCode === this.global.appConstant.core.ITEM_STATUS_REVISION"
            size="TN" (onClick)="doEdit(rowData.id)"
            title="{{ 'app.tooltip.edit' | translate }}">
            <em class="fas fa-pencil-alt"></em>
          </app-button>
          <app-button *ngIf="rowData.itemStatusCode === 
              this.global.appConstant.core.ITEM_STATUS_WAITING_APPROVAL"
            size="TN" (onClick)="doView(rowData.id)"
            title="{{ 'app.tooltip.view' | translate }}"
          >
            <em class="fa fa-search"></em>
          </app-button>
          <!-- <app-button *ngIf="!rowData.itemStatus?.code === 
              this.global.appConstant.core.ITEM_STATUS_WAITING_APPROVAL"
            size="TN" color="DANGER" (onClick)="doDelete(rowData)"
            title="{{ 'app.tooltip.delete' | translate }}">
            <em class="fa fas fa-trash"></em>
          </app-button> -->
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>