import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppRadioModule } from '../../app-radio/app-radio.module';
import { AppTableModule } from '../../table/components/app-table/app-table.module';
import { TableModule } from '../../table/table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupChooseBillComponent } from './app-popup-choose-bill.component';
import { AppPopupChooseBillService } from './app-popup-choose-bill.service';

@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppTableModule,
    AppButtonModule,
    AppRadioModule,
    TableModule
  ],
  declarations: [AppPopupChooseBillComponent],
  entryComponents: [AppPopupChooseBillComponent],
  providers: [AppPopupChooseBillService],
  exports: [AppPopupChooseBillComponent]
})
export class AppPopupChooseBillModule {}
