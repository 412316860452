<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="notification-template.title"
      detail="app.action.{{ todo }}" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            "notification-template.form.code" | translate
            }}</label>
          <div class="col-sm-9 text-left">
            {{ formGroup.controls.code.value }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            "notification-template.form.moduleGroup" | translate
            }}</label>
          <div class="col-sm-9 text-left">
            {{ moduleGroupName }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            "notification-template.form.description" | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxLength="512"
              autofocus="true"></app-text-area>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            "notification-template.form.status" | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box formControlName="isActive" [optionList]="statusList">
            </app-combo-box>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            "notification-template.form.keyword" | translate
            }}</label>
          <div class="col-sm-9 text-left">
            {{ notificationTemplateResponse.notification?.keyword }}
          </div>
        </div>
        <app-tab bordered="true">
          <app-tab-item header="{{ nt.value.name }}" *ngFor="
              let nt of getFormArray(formGroup, 'notifTemplate').controls;
              let i = index
            " [formGroup]="nt">
            <div formArrayName="notifTemplateDetail">
              <div *ngFor="
                  let ntDetail of getFormArray(nt, 'notifTemplateDetail')
                    .controls;
                  let j = index
                " [formGroup]="ntDetail">
                <h4>{{ ntDetail.value.notificationType.name }}</h4>
                <app-toggle-switch formControlName="isActive" (onChange)="
                    onChangeNotificationType(
                      $event,
                      ntDetail.value.notificationType
                    )
                  "></app-toggle-switch>
                <div class="form-group row text-sm-right required">
                  <label class="col-sm-3 control-label">{{
                    "notification-template.form.subject" | translate
                    }}</label>
                  <div class="col-sm-9 text-left">
                    <app-text-field formControlName="subject" size="LG"
                      maxLength="128"></app-text-field>
                  </div>
                </div>
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    "notification-template.form.content" | translate
                    }}</label>
                  <div *ngIf="ntDetail.value.notificationType.code == 'EMAIL'"
                    class="col-sm-9 text-left">
                    <app-wysiwyg formControlName="body" size="LG"
                      placeholder="Enter text here..."></app-wysiwyg>
                  </div>
                  <div *ngIf="ntDetail.value.notificationType.code != 'EMAIL'"
                    class="col-sm-9 text-left">
                    <app-text-area formControlName="body" size="LG"
                      maxLength="320"></app-text-area>
                  </div>
                  <div *ngIf="ntDetail.value.notificationType.name == 'Whatsup'"
                    class="col-sm-2 text-left"></div>
                </div>
                <br />
              </div>
            </div>
          </app-tab-item>
        </app-tab>
        <div class="button-group button-group-center mt-5">
          <app-button color="SECONDARY" (onClick)="doCancel()"
            [disabled]="formSaving">
            {{ "app.button.back" | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">
            {{ "app.button.save" | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>