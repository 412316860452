<app-popup [header]="header" [isLoading]="formLoading">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate *ngIf="!formLoading">
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'fines-monitoring.form.type' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ finesAdjustment.procurementFines.fines?.name }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'fines-monitoring.form.percentage' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ percentageValue }} %
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">Not Fined</label>
      <div class="col-sm-9 text-left">
        <app-check-box formControlName="isWorn" (onChange)="onChangeWorned()"></app-check-box>
      </div>
    </div>
    <div class="form-group row text-sm-right" *ngIf="
        !formGroup.isView
      " [ngClass]="!formGroup.isView ? 'required' : ''">
      <label class="col-sm-3 control-label">{{
        'fines-monitoring.form.duration' | translate
        }}</label>
      <div class="col-sm-3 text-left">
        <app-text-field size="XL" formControlName="duration" type="integer" maxLength="15"
          (onInput)="onChangeDuration()"></app-text-field>
      </div>
      <div class="col-sm-6 text-left px-0 d-flex-align-items-center" style="line-height: 200%;">Day</div>
    </div>
    <div class="form-group row text-sm-right" *ngIf="
        !formGroup.isView">
      <label class="col-sm-3 control-label">{{
        'fines-monitoring.form.value' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ global.converterService.convertMoney(value) || 0 }} {{ finesAdjustment.procurementFines.currency?.code || '
        '}}
      </div>
    </div>
    <div class="form-group row text-sm-right" *ngIf="!formGroup.isView">
      <label class="col-sm-3 control-label">{{
        'fines-monitoring.form.attachment' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        <app-upload formControlName="uploadFile" [fileUploader]="fileUploader" size="LG"
          (onChange)="onChangeFileUpload($event)"></app-upload>
      </div>
    </div>
    <div class="form-group row text-sm-right" *ngIf="!formGroup.isView">
      <label class="col-sm-3 control-label">{{
        'fines-monitoring.form.note' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        <app-text-area formControlName="description" size="LG"></app-text-area>
      </div>
    </div>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
        'app.button.close' | translate
        }}</app-button>
      <app-button *ngIf="!formGroup.isView" color="PRIMARY" (onClick)="doSave()">{{ 'app.button.save' | translate }}
      </app-button>
    </div>
  </ng-template>
</app-popup>