import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../../../core/base/angular/base-component.component';
import { Education } from '../../../../core/bean/education';
import { Profession } from '../../../../core/bean/profession';
import { VendorExpertise } from '../../../../core/bean/vendor-expertise';
import { VendorExpertiseCertificate } from '../../../../core/bean/vendor-expertise-certificate';
import { VendorExpertiseHistory } from '../../../../core/bean/vendor-expertise-history';
import { FileUploader } from '../../../../core/components/upload';
import { OptionListModel } from '../../../../core/model/option-list-model';
import { Validators } from '../../../../core/validators';
import { VendorPopupVendorExpertiseDTO } from '../../dto/vendor-popup-vendor-expertise.dto';

@Component({
  templateUrl: './app-popup-expert.component.html',
  styles: [
    '.company-profile-insert { color: blue; border-style: solid; border-width: thin; float: right }',
    '.company-profile-update { color: orange; border-style: solid; border-width: thin; float: right }',
    '.company-profile-delete { color: red; border-style: solid; border-width: thin; float: right }',
    '.company-profile-text-crud { margin: 0.5rem }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupExpertComponent extends BaseComponentComponent {
  @Input() public todo: string;
  @Input() public vendorExpertiseHistoryEdit: VendorExpertiseHistory;
  @Input() public vendorExpertiseHistoryList: VendorExpertiseHistory[];
  @Input() public vendorExpertiseList: VendorExpertise[];
  @Input() public vendorExpertise: VendorExpertise;
  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();
  public vendorPopupVendorExpertiseDTO: VendorPopupVendorExpertiseDTO =
    new VendorPopupVendorExpertiseDTO();
  public certificateList: any[] = [];
  public fileUploaderCV: FileUploader = new FileUploader(
    '/tab-profile-expert-and-equipment/',
    'cv',
    this.global.appConstant.fileType.DOC_CV
  );
  public fileUploaderCertificate: FileUploader = new FileUploader(
    '/tab-profile-expert-and-equipment/',
    'certificate',
    this.global.appConstant.fileType.DOC_CERTIFICATE_VENDOR
  );
  public fileUploaderList: FileUploader[] = [];
  public vendorExpertiseCertificateList: VendorExpertiseCertificate[] = [];
  public professionOptionList: OptionListModel<Profession> =
    new OptionListModel(true);
  public educationOptionList: OptionListModel<Education> = new OptionListModel(
    true
  );
  public autoCompleteValue: any;
  public isVendorList: boolean;
  public fromOutside: string;
  public startDateEP: string;
  public startDateHistoryEP: string;
  public endDateEP: string;
  public endDateHistoryEP: string;
  public startDateVDList: string[] = [];
  public startDateHistoryVDList: string[] = [];
  public endDateVDList: string[] = [];
  public endDateHistoryVDList: string[] = [];
  public birthDate: string;
  public birthDateHistory: string;

  constructor(public datePipe: DatePipe) {
    super('tab-profile-expert-and-equipment');
  }

  public onInit(): void {
    this.setParam();
    this.getVendorExpertise();
    this.buildFormGroup();
    this.setFormGroup();
    if (this.todo === 'detail') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      expert: this.newExpert(),
      educationInfo: this.newEducation(),
      certificationList: this.formBuilder.array([this.newCertification()]),
      vendorExpertiseId: [null]
    });
  }

  public setParam(): void {
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
  }

  public setFormGroup(): void {
    this.getAppPopupVendorExpertiseFromServer(
      '/tab-profile-expert-and-equipment/add-edit-popup-vendor-expertise'
    ).subscribe(vendorPopupVendorExpertiseDTO => {
      this.vendorPopupVendorExpertiseDTO = vendorPopupVendorExpertiseDTO;
      this.professionOptionList.setRequestValues(
        vendorPopupVendorExpertiseDTO.professionList
      );
      this.educationOptionList.setRequestValues(
        vendorPopupVendorExpertiseDTO.educationList
      );
      if (this.vendorExpertiseHistoryEdit !== undefined) {
        const {
          id,
          name,
          education,
          experience,
          endYear,
          startYear,
          profession,
          institution,
          vendorExpertiseId,
          file
        } = this.vendorExpertiseHistoryEdit;
        let birthDate = new Date();
        let startDate = new Date();
        let endDate = new Date();
        if (this.vendorExpertiseHistoryEdit.birthDate !== null) {
          birthDate = new Date(this.vendorExpertiseHistoryEdit.birthDate);
        } else {
          birthDate = null;
        }
        if (this.vendorExpertiseHistoryEdit.startDate !== null) {
          startDate = new Date(this.vendorExpertiseHistoryEdit.startDate);
        } else {
          startDate = null;
        }
        if (this.vendorExpertiseHistoryEdit.endDate !== null) {
          endDate = new Date(this.vendorExpertiseHistoryEdit.endDate);
        } else {
          endDate = null;
        }
        const employmentPeriode = { startDate, endDate };
        const year = { startYear, endYear };
        const expert = {
          name,
          birthDate,
          birthDateActual: this.birthDate,
          birthDateHistory: this.birthDateHistory,
          experience,
          profession,
          employmentPeriode
        };
        this.fileUploaderCV.setFile(file);
        if (
          this.vendorExpertiseHistoryEdit.fileObjectList &&
          this.vendorExpertiseHistoryEdit.fileObjectList.length !== 0
        ) {
          this.fileUploaderCV.fileObjectList =
            this.vendorExpertiseHistoryEdit.fileObjectList;
        }
        const educationInfo = {
          education,
          institution,
          year,
          fileCV: this.fileUploaderCV.fileObjectList,
          fileUploader: this.fileUploaderCV
        };
        if (
          this.fileUploaderList[0].fileObjectList &&
          this.fileUploaderList[0].fileObjectList.length === 0 &&
          this.vendorExpertiseHistoryEdit.vendorExpertiseCertificateHistoryList
            .length !== 0 &&
          this.vendorExpertiseHistoryEdit
            .vendorExpertiseCertificateHistoryList[0].certificateNumber !== null
        ) {
          this.fileUploaderList = [];
        }

        this.vendorExpertiseHistoryEdit.vendorExpertiseCertificateHistoryList.forEach(
          certificate => {
            const fileUploaderCertificate: FileUploader = new FileUploader(
              '/tab-profile-expert-and-equipment/',
              'certificate',
              this.global.appConstant.fileType.DOC_CERTIFICATE_VENDOR
            );
            let certification = null;
            if (certificate.certificateNumber !== null) {
              certification = certificate.certificateNumber;
            }
            const startDateC =
              certificate.startDate !== null
                ? new Date(certificate.startDate)
                : null;
            const endDateC =
              certificate.endDate !== null
                ? new Date(certificate.endDate)
                : null;
            fileUploaderCertificate.setFile(certificate.file);
            if (
              certificate.fileObjectList &&
              certificate.fileObjectList.length !== 0
            ) {
              fileUploaderCertificate.fileObjectList =
                certificate.fileObjectList;
            }
            const vendorExpertiseCertificateId =
              certificate.vendorExpertiseCertificateId;
            this.fileUploaderList.push(fileUploaderCertificate);
            const addCertification = {
              certification,
              fileCertificate: fileUploaderCertificate.fileObjectList,
              fileUploader: fileUploaderCertificate,
              vendorExpertiseCertificateId,
              validationDate: { startDate: startDateC, endDate: endDateC },
              file: certificate.file,
              crudInfo: certificate.crudInfo
            };

            this.certificateList.push(addCertification);
          }
        );

        for (let index = 0; index < this.certificateList.length; index++) {
          if (index !== this.certificateList.length - 1) {
            this.certificationList.push(this.newCertification());
          }
        }
        this.formGroup.patchValue({
          id,
          expert,
          educationInfo,
          certificationList: this.certificateList,
          vendorExpertiseId
        });
      }
      this.setStateReady();
    });
  }

  public newExpert(): FormGroup {
    const formGroup = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required()])],
      birthDate: [null],
      currentDate: new Date(),
      experience: [null, Validators.compose([Validators.required()])],
      profession: [null, Validators.required()],
      employmentPeriode: this.newDateFromAndTo()
    });
    if (this.todo === 'detail') {
      formGroup.setIsView(true);
    }
    return formGroup;
  }

  public newEducation(): FormGroup {
    const formGroup = this.formBuilder.group({
      education: [null, Validators.required()],
      institution: [null],
      fileCV: [null],
      year: this.newYearFromAndTo(),
      fileUploader: [null]
    });
    if (this.todo === 'detail') {
      formGroup.setIsView(true);
    }
    return formGroup;
  }

  public newCertification(): FormGroup {
    const fileUploader: FileUploader = new FileUploader(
      '/tab-profile-expert-and-equipment/',
      'certificate',
      this.global.appConstant.fileType.DOC_CERTIFICATE_VENDOR
    );
    this.fileUploaderList.push(fileUploader);
    const formGroup = this.formBuilder.group({
      certification: [null],
      validationDate: this.newDateFromAndTo(),
      fileCertificate: [null],
      vendorExpertiseCertificateId: [null],
      fileUploader: [null],
      crudInfo: [null],
      file: [null]
    });
    if (this.todo === 'detail') {
      formGroup.setIsView(true);
    }
    return formGroup;
  }

  public newDateFromAndTo(): FormGroup {
    const formGroup = this.formBuilder.group({
      startDate: [null],
      endDate: [null]
    });
    if (this.todo === 'detail') {
      formGroup.setIsView(true);
    }
    return formGroup;
  }

  public newYearFromAndTo(): FormGroup {
    const formGroup = this.formBuilder.group({
      startYear: [
        null,
        Validators.compose([
          Validators.actualLength(
            4,
            'tab-profile-expert-and-equipment.validation.year.actualLength'
          ),
          Validators.max('endYear')
        ])
      ],
      // tslint:disable-next-line: max-line-length
      endYear: [
        null,
        Validators.compose([
          Validators.actualLength(
            4,
            'tab-profile-expert-and-equipment.validation.year.actualLength'
          ),
          Validators.min('startYear'),
          Validators.max(new Date().getFullYear())
        ])
      ]
    });
    if (this.todo === 'detail') {
      formGroup.setIsView(true);
    }
    return formGroup;
  }

  public doAddCertification(): void {
    this.certificationList.push(this.newCertification());
  }

  public doDeleteCertification(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.certificationList.removeAt(index);
        }
      });
  }

  public get certificationList(): FormArray {
    return this.formGroup.get('certificationList') as FormArray;
  }

  public doSave(): void {
    this.validate();
    if (
      this.formGroup.get('expert').valid &&
      this.formGroup.get('educationInfo').valid
    ) {
      this.onChange.emit(this.formGroup.value);
    }
  }

  public getVendorExpertise(): void {
    if (this.vendorExpertiseList) {
      this.vendorExpertiseHistoryList.forEach(expertiseHistory => {
        if (expertiseHistory === this.vendorExpertiseHistoryEdit) {
          this.vendorExpertiseList.forEach(expertise => {
            if (expertise.id === expertiseHistory.vendorExpertiseId) {
              this.vendorExpertise = expertise;
              this.birthDate =
                this.vendorExpertise.birthDate !== null
                  ? this.datePipe.transform(
                      this.vendorExpertise.birthDate,
                      this.global.config.parameterModel.dateFormatShort
                    )
                  : null;
              this.startDateEP =
                this.vendorExpertise.startDate !== null
                  ? this.datePipe.transform(
                      this.vendorExpertise.startDate,
                      this.global.config.parameterModel.dateFormatShort
                    )
                  : null;
              this.endDateEP =
                this.vendorExpertise.endDate !== null
                  ? this.datePipe.transform(
                      this.vendorExpertise.endDate,
                      this.global.config.parameterModel.dateFormatShort
                    )
                  : null;
            }
          });
        }
      });
      if (this.vendorExpertise === undefined) {
        this.vendorExpertise = new VendorExpertise();
      }
    }
    if (this.vendorExpertiseHistoryEdit !== undefined) {
      if (this.vendorExpertiseHistoryEdit.birthDate !== null) {
        this.birthDateHistory = this.datePipe.transform(
          new Date(this.vendorExpertiseHistoryEdit.birthDate),
          this.global.config.parameterModel.dateFormatShort
        );
      }
      if (this.vendorExpertiseHistoryEdit.startDate !== null) {
        this.startDateHistoryEP = this.datePipe.transform(
          new Date(this.vendorExpertiseHistoryEdit.startDate),
          this.global.config.parameterModel.dateFormatShort
        );
      }
      if (this.vendorExpertiseHistoryEdit.endDate !== null) {
        this.endDateHistoryEP = this.datePipe.transform(
          new Date(this.vendorExpertiseHistoryEdit.endDate),
          this.global.config.parameterModel.dateFormatShort
        );
      }
      this.vendorExpertiseHistoryEdit.vendorExpertiseCertificateHistoryList.forEach(
        (certificate, index) => {
          let vendorExpertiseCertificate = null;
          if (
            this.vendorExpertiseHistoryEdit.vendorExpertiseCertificateList !==
            undefined
          ) {
            vendorExpertiseCertificate =
              this.vendorExpertiseHistoryEdit.vendorExpertiseCertificateList[
                index
              ];
          }
          const startDateC =
            certificate.startDate !== null
              ? new Date(certificate.startDate)
              : null;
          const endDateC =
            certificate.endDate !== null ? new Date(certificate.endDate) : null;
          const startDateHistoryVD =
            startDateC !== null
              ? this.datePipe.transform(
                  startDateC,
                  this.global.config.parameterModel.dateFormatShort
                )
              : null;
          const endDateHistoryVD =
            endDateC !== null
              ? this.datePipe.transform(
                  endDateC,
                  this.global.config.parameterModel.dateFormatShort
                )
              : null;
          this.startDateHistoryVDList.push(startDateHistoryVD);
          this.endDateHistoryVDList.push(endDateHistoryVD);
          if (
            vendorExpertiseCertificate !== null &&
            vendorExpertiseCertificate !== undefined
          ) {
            const endDateVD =
              vendorExpertiseCertificate.endDate !== null
                ? this.datePipe.transform(
                    vendorExpertiseCertificate.endDate,
                    this.global.config.parameterModel.dateFormatShort
                  )
                : null;
            const startDateVD =
              vendorExpertiseCertificate.startDate !== null
                ? this.datePipe.transform(
                    vendorExpertiseCertificate.startDate,
                    this.global.config.parameterModel.dateFormatShort
                  )
                : null;
            this.startDateVDList.push(startDateVD);
            this.endDateVDList.push(endDateVD);
          }
        }
      );
    }
  }

  public getAppPopupVendorExpertiseFromServer(
    urlVendorExpertisePopup: string
  ): Observable<VendorPopupVendorExpertiseDTO> {
    return this.httpClientService.post<VendorPopupVendorExpertiseDTO>(
      urlVendorExpertisePopup,
      null
    );
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
