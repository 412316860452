import { Rfq } from "src/app/core/bean/rfq";
import { RfqDoc } from "src/app/core/bean/rfq-doc";
import { RfqItem } from "src/app/core/bean/rfq-item";
import { RfqVendor } from "src/app/core/bean/rfq-vendor";
import { RfqVendorScoring } from "src/app/core/bean/rfq-vendor-scoring";
import { FileObject } from "src/app/core/components/upload";
import { RfqVendorView } from "src/app/core/view/entity/bean/rfq-vendor-view";

export class RfqRequest {
    rfq: Rfq = new Rfq();
    rfqItemList: RfqItem[] = [];
    rfqDocList: RfqDoc[] = [];
    rfqVendorList: RfqVendor[] = [];
    rfqVendorScoringList: RfqVendorScoring[] = [];
    fileObjectList: FileObject[] = [];
    isSubmit: boolean;
    rfqVendorViewList: RfqVendorView[] = [];
    rfqVendorWinner: RfqVendorView = new RfqVendorView();
}