import { BaseEntity } from '../base/base-entity';
import { AddressBook } from '../bean/address-book';
import { User } from '../bean/user';

export class AddressPicEntity extends BaseEntity {
  user: User;
  address: AddressBook;
  isMain: boolean;
  crudOperation: number;
}
