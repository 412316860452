import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppComboBoxModule } from '../../app-combo-box/app-combo-box.module';
import { AppTextAreaModule } from '../../app-text-area/app-text-area.module';
import { AppTextFieldModule } from '../../app-text-field/app-text-field.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupProcurementFinesComponent } from './app-popup-procurement-fines.component';
import { AppPopupProcurementFinesService } from './app-popup-procurement-fines.service';
@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppTextFieldModule,
    AppTextAreaModule,
    AppButtonModule,
    AppComboBoxModule
  ],
  declarations: [AppPopupProcurementFinesComponent],
  entryComponents: [AppPopupProcurementFinesComponent],
  providers: [AppPopupProcurementFinesService],
  exports: [AppPopupProcurementFinesComponent]
})
export class AppPopupProcurementFinesModule {}
