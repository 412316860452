<div class="row">
  <div class="col-lg-12 mb-4">
    <div class="text-center py-4" *ngIf="formLoading">
      <img src="assets/img/common/loader-small.gif" alt="Loading" />
    </div>
    <form
      class="form-horizontal"
      [formGroup]="tabExpertAndEquipment.formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <div class="form-group row">
        <div class="col-lg-12">
          <h5 class="separator"
            >{{
              'tab-profile-expert-and-equipment.form-group.expert' | translate
            }}
            <span class="control-label"></span
          ></h5>
          <app-table #vendorExpert [model]="tableExpertResponse">
            <ng-template #headerButtons>
              <ng-container *ngIf="!isView">
                <app-button (onClick)="doAddExpert()">
                  <em class="fas fa-plus"></em>
                  {{ 'app.button.add' | translate }}
                </app-button>
              </ng-container>
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <ng-container *ngIf="!isView">
                <app-button
                  size="TN"
                  (onClick)="doEditExpert(rowData)"
                  title="{{ 'app.tooltip.edit' | translate }}"
                >
                  <span class="fas fa-pencil-alt"></span>
                </app-button>
                <app-button
                  size="TN"
                  color="DANGER"
                  (onClick)="doDeleteExpert(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <span class="fas fa-trash"></span>
                </app-button>
              </ng-container>
              <ng-container *ngIf="isView">
                <app-button
                  size="TN"
                  (onClick)="doViewExpert(rowData)"
                  title="{{ 'app.tooltip.detail' | translate }}"
                >
                  <span class="fas fa-search"></span>
                </app-button>
              </ng-container>
            </ng-template>
          </app-table>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-lg-12">
          <h5 class="separator"
            >{{
              'tab-profile-expert-and-equipment.form-group.equipment'
                | translate
            }}
            <span class="control-label"></span
          ></h5>
          <app-table #vendorEquipment [model]="tableEquipmentResponse">
            <ng-template #headerButtons>
              <ng-container *ngIf="!isView">
                <app-button (onClick)="doAddEquipment()">
                  <em class="fas fa-plus"></em>
                  {{ 'app.button.add' | translate }}
                </app-button>
              </ng-container>
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <ng-container *ngIf="!isView">
                <app-button
                  size="TN"
                  (onClick)="doEditEquipment(rowData)"
                  title="{{ 'app.tooltip.edit' | translate }}"
                >
                  <span class="fas fa-pencil-alt"></span>
                </app-button>
                <app-button
                  size="TN"
                  color="DANGER"
                  (onClick)="doDeleteEquipment(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <span class="fas fa-trash"></span>
                </app-button>
              </ng-container>
              <ng-container *ngIf="isView">
                <app-button
                  size="TN"
                  (onClick)="doViewEquipment(rowData)"
                  title="{{ 'app.tooltip.detail' | translate }}"
                >
                  <span class="fas fa-search"></span>
                </app-button>
              </ng-container>
            </ng-template>
          </app-table>
        </div>
      </div>
    </form>
  </div>
</div>
