import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Renderer2,
  SecurityContext,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { ResolveFieldDataPipe } from '../../pipe/resolve-field-data.pipe';
import { ResolveImgSrc } from '../../pipe/resolve-img-src.pipe';
import { ArrayUtils, TextUtils } from '../../utils';
@Component({
  selector: 'app-combo-box',
  templateUrl: './app-combo-box.component.html',
  styleUrls: ['./app-combo-box.component.scss'],
  providers: makeProvider(AppComboBoxComponent),
  encapsulation: ViewEncapsulation.None
})
export class AppComboBoxComponent
  extends BaseValueAccessorComponent<string | number | boolean | object>
  implements AfterViewChecked, OnDestroy
{
  @Input() customClass: string;
  @Input() container: 'body';
  generatedId: string = TextUtils.generateRandomString();
  optionsCopy: any;
  selectSize: number;
  isFocus = false;
  isShowIcon: boolean;
  isShowSearch: boolean;
  iconPath: string;
  divSelectElement: any;
  inputSearchElement: any;
  divAppSelectElement: any;
  divSelectLabelElement: HTMLElement;
  divSelectContainerElement: HTMLElement;
  viewPath: string | { fn: (option: any) => string };
  valuePath: string;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public renderer2: Renderer2,
    public resolveFieldDataPipe: ResolveFieldDataPipe,
    private resolveImgSrc: ResolveImgSrc,
    public domSanitizer: DomSanitizer,
  ) {
    super('app-combo-box', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.getAndSetOptionListOption();
    if (!this.ISVIEW) {
      this.setElement();
      this.handleFormControlChange();
      this.reload();
    } else {
      this.renderer2.removeChild(
        this.parentElement,
        this.parentElement.children[0]
      );
    }
  }

  ngAfterViewChecked(): void {
    if (this.container === 'body') {
      // this.setDivSelectContainerElementPosition();
    }
  }

  ngOnDestroy(): void {
    if (this.container === 'body') {
      this.removeSelectElement();
    }
  }

  setElement(): void {
    this.divAppSelectElement = this.elementRef.nativeElement.children[0];
    this.divSelectLabelElement = this.divAppSelectElement.children[0];
    if (!this.container) {
      this.divSelectContainerElement = this.divAppSelectElement.children[1];
    }
  }

  handleFormControlChange(): void {
    if (this.formControl) {
      this.formControl.valueChanges.subscribe(value => {
        this.value = value;
        this.setLabelValueAndIcon();
      });
    }
  }

  getAndSetOptionListOption(): void {
    if (this.optionList) {
      this.isShowIcon = this.optionList.options.get('isShowIcon');
      this.isShowSearch = this.optionList.options.get('isShowSearch');
      this.iconPath = this.optionList.options.get('iconPath');
      this.viewPath = this.optionList.viewPath;
      this.value = this.optionList.valuePath;
    }
  }

  reload(): void {
    if (!this.optionList.isWaitFromServer) {
      this.build();
    }
    this.optionList.requestValueChanges.subscribe(() => {
      this.build();
    });
  }

  build(): void {
    this.optionsCopy = this.optionList.getRequestValues();
    this.setSelectSize();
    this.setLabelValueAndIcon();
  }

  builder(): void {
    if (this.container === 'body') {
      if (this.divSelectContainerElement) {
        this.removeSelectElement();
      } else {
        this.divSelectContainerElement = document.createElement('div');
        this.divSelectContainerElement.classList.add('app-select-container');
        document.body.appendChild(this.divSelectContainerElement);
        this.setDivSelectContainerElementPosition();

        if (this.optionsCopy && this.optionsCopy.length > 0) {
          this.setParentClass();
          this.builderInputSearchElement();
          this.builderSelectElement();
        }
      }
    } else {
      if (this.divSelectElement) {
        this.divSelectContainerElement.classList.toggle('show');
        this.removeSelectElement();
      } else {
        if (this.optionsCopy && this.optionsCopy.length > 0) {
          this.setParentClass();
          this.builderInputSearchElement();
          this.builderSelectElement();
        }
      }
    }
  }

  private setDivSelectContainerElementPosition(): void {
    if (this.divSelectContainerElement) {
      const boundingClientRect =
        this.divSelectLabelElement.getBoundingClientRect();
      const offsetLeft = boundingClientRect.left + window.scrollX;
      const offsetTop = boundingClientRect.top + window.scrollY;

      this.divSelectContainerElement.style.transform = `translate(${offsetLeft}px, ${
        offsetTop + boundingClientRect.height + 5
      }px)`;
    }
  }

  setParentClass(): void {
    if (this.isShowIcon) {
      this.renderer2.addClass(this.divSelectContainerElement, 'show-icon');
    }
  }

  builderInputSearchElement(): void {
    if (this.isShowSearch) {
      this.renderer2.addClass(this.divSelectContainerElement, 'show-search');
      this.inputSearchElement = this.renderer2.createElement('input');
      this.renderer2.addClass(this.inputSearchElement, 'form-control');
      this.renderer2.listen(this.inputSearchElement, 'input', (event: any) => {
        this.handleSearchChange(event.target.value);
      });
      this.renderer2.listen(
        this.inputSearchElement,
        'focusin',
        (event: any) => {
          this.handleFocus(event);
        }
      );
      this.inputSearchElement.id = this.generatedId + 'search';
      this.renderer2.appendChild(
        this.divSelectContainerElement,
        this.inputSearchElement
      );
    }
  }

  builderSelectElement(): void {
    this.divSelectElement = this.renderer2.createElement('div');
    this.renderer2.addClass(this.divSelectElement, 'select');
    this.builderSelectOption();
    this.renderer2.appendChild(
      this.divSelectContainerElement,
      this.divSelectElement
    );
    if (
      this.divSelectContainerElement.scrollWidth <
      this.divSelectElement.scrollWidth
    ) {
      this.renderer2.setStyle(this.divSelectElement, 'width', 'max-content');
    }
  }

  builderSelectOption(): void {
    this.divSelectElement.size = this.selectSize;
    this.buildSelectOptionDefault();
    this.optionsCopy.forEach((option: any, index: number) => {
      const customRadioId = `customRadio` + this.generatedId + index;
      const customRadioElement = this.renderer2.createElement('div');
      this.renderer2.addClass(customRadioElement, 'custom-control');
      this.renderer2.addClass(customRadioElement, 'custom-radio');
      const inputRadioElement = this.renderer2.createElement('input');
      this.renderer2.setAttribute(inputRadioElement, 'type', 'radio');
      this.renderer2.setAttribute(inputRadioElement, 'id', customRadioId);
      this.renderer2.setAttribute(inputRadioElement, 'name', this.generatedId);
      this.renderer2.setAttribute(
        inputRadioElement,
        'class',
        'custom-control-input'
      );

      const optionValue = this.optionList.valuePath
        ? this.resolveFieldDataPipe.transform(option, this.optionList.valuePath)
        : option.id || option;
      if (this.formControl.value) {
        const selectedValue =
          this.optionList.valuePath &&
          typeof this.formControl.value === 'object'
            ? this.resolveFieldDataPipe.transform(
                this.formControl.value,
                this.optionList.valuePath
              )
            : this.formControl.value.id || this.formControl.value;
        inputRadioElement.checked =
          typeof selectedValue === 'object'
            ? JSON.stringify(selectedValue) === JSON.stringify(optionValue)
            : selectedValue === optionValue;
      }

      const labelRadioElement = this.renderer2.createElement('label');
      this.renderer2.setAttribute(
        labelRadioElement,
        'class',
        'custom-control-label'
      );
      this.renderer2.setAttribute(labelRadioElement, 'for', customRadioId);
      this.renderer2.listen(labelRadioElement, 'click', event => {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        this.isFocus = true;
        inputRadioElement.checked = true;
        this.handleChangeSelect(option);
        this.removeSelectElement();
      });
      const text = this.optionList.viewPath
        ? this.viewPath !== null &&
          typeof this.viewPath !== 'string' &&
          this.viewPath.fn
          ? this.viewPath.fn(option)
          : this.resolveFieldDataPipe.transform(
              option,
              String(this.optionList.viewPath)
            )
        : option.name || option;
      const labelRadioTextElement = this.renderer2.createText(
        this.global.translateService.instant(text.toString())
      );

      if (this.isShowIcon) {
        const imgElement = this.renderer2.createElement('img');
        const imageUrl = `${this.global.config.BACKEND_ADDRESS}${
          this.optionList.stringUrl
        }file/view/${this.resolveFieldDataPipe.transform(
          option,
          this.iconPath
        )}/${this.optionList.fileTypeCode}`;
        this.resolveImgSrc
          .transform(imageUrl)
          .then(
            url =>
              (imgElement.src = this.domSanitizer.sanitize(
                SecurityContext.URL,
                url
              ))
          );
        this.renderer2.setAttribute(imgElement, 'src', imageUrl);
        this.renderer2.setAttribute(imgElement, 'class', 'thumbnail mr-2');
        this.renderer2.setAttribute(imgElement, 'width', '25');
        this.renderer2.setAttribute(imgElement, 'height', '25');
        this.renderer2.appendChild(labelRadioElement, imgElement);
      }
      this.renderer2.appendChild(labelRadioElement, labelRadioTextElement);
      this.renderer2.appendChild(customRadioElement, inputRadioElement);
      this.renderer2.appendChild(customRadioElement, labelRadioElement);
      this.renderer2.appendChild(this.divSelectElement, customRadioElement);
    });
  }

  buildSelectOptionDefault(): void {
    const customRadioId = `customRadio` + this.generatedId;
    const customRadioElement = this.renderer2.createElement('div');
    this.renderer2.addClass(customRadioElement, 'custom-control');
    this.renderer2.addClass(customRadioElement, 'custom-radio');
    const inputRadioElement = this.renderer2.createElement('input');
    this.renderer2.setAttribute(inputRadioElement, 'type', 'radio');
    this.renderer2.setAttribute(inputRadioElement, 'id', customRadioId);
    this.renderer2.setAttribute(inputRadioElement, 'name', this.generatedId);
    this.renderer2.setAttribute(
      inputRadioElement,
      'class',
      'custom-control-input'
    );
    const labelRadioElement = this.renderer2.createElement('label');
    this.renderer2.setAttribute(
      labelRadioElement,
      'class',
      'custom-control-label'
    );
    this.renderer2.setAttribute(labelRadioElement, 'for', customRadioId);
    this.renderer2.listen(labelRadioElement, 'click', event => {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.formControl.patchValue(null);
      this.optionList.setResponseValues(null);
      this.onChange.emit(null);
      this.isFocus = false;
      this.setLabelValueAndIcon();
      this.removeSelectElement();
    });
    const chooseDataText = this.placeholder || 'app.chooseData';
    const labelRadioTextElement = this.renderer2.createText(
      this.global.translateService.instant(chooseDataText)
    );
    this.renderer2.appendChild(labelRadioElement, labelRadioTextElement);
    this.renderer2.appendChild(customRadioElement, inputRadioElement);
    this.renderer2.appendChild(customRadioElement, labelRadioElement);
    this.renderer2.appendChild(this.divSelectElement, customRadioElement);
  }

  setLabelValueAndIcon(element?: any): void {
    const parentElement = element ? element : this.divSelectLabelElement;
    const viewPath = this.optionList.viewPath;
    const valuePath = this.optionList.valuePath;
    parentElement.innerHTML = '';
    if (this.isShowIcon && this.formControl.value) {
      const imgElement = this.renderer2.createElement('img');
      const imageUrl = `${this.global.config.BACKEND_ADDRESS}${
        this.optionList.stringUrl
      }file/view/${this.resolveFieldDataPipe.transform(
        this.formControl.value,
        this.iconPath
      )}/${this.optionList.fileTypeCode}`;

      this.resolveImgSrc
      .transform(imageUrl)
      .then(
        url =>
          (imgElement.src = this.domSanitizer.sanitize(
            SecurityContext.URL,
            url
          ))
      );
      parentElement.classList.add('show-icon');
      this.renderer2.appendChild(parentElement, imgElement);
    }
    const spanElement = this.renderer2.createElement('span');
    let textValue: string;
    if (
      typeof this.formControl.value !== 'undefined' &&
      this.formControl.value !== null
    ) {
      if (this.optionList.valuePath) {
        const requestValues: Array<any> = this.optionList.getRequestValues();
        const indexOfValue = requestValues.findIndex(option =>
          typeof option === 'object'
            ? this.resolveFieldDataPipe.transform(option, valuePath) ===
              this.formControl.value
            : option === this.formControl.value
        );
        textValue =
          viewPath && typeof requestValues[indexOfValue] === 'object'
            ? this.viewPath !== null &&
              typeof this.viewPath !== 'string' &&
              this.viewPath.fn
              ? this.viewPath.fn(requestValues[indexOfValue])
              : this.resolveFieldDataPipe.transform(
                  requestValues[indexOfValue],
                  String(viewPath)
                )
            : requestValues[indexOfValue].name || requestValues[indexOfValue];
      } else {
        textValue = viewPath
          ? this.viewPath !== null &&
            typeof this.viewPath !== 'string' &&
            this.viewPath.fn
            ? this.viewPath.fn(this.formControl.value)
            : this.resolveFieldDataPipe.transform(
                this.formControl.value,
                String(viewPath)
              )
          : this.formControl.value.name || this.formControl.value;
      }
    } else {
      textValue = this.placeholder || 'app.chooseData';
    }
    const textElement = this.renderer2.createText(
      ((textValue || typeof textValue === 'number') && !Array.isArray(textValue) &&
        this.global.translateService.instant(textValue.toString())) ||
        ''
    );
    this.renderer2.appendChild(spanElement, textElement);
    this.renderer2.appendChild(parentElement, spanElement);
  }

  handleChangeSelect(option: any): void {
    const valuePath = this.optionList.valuePath;
    if (
      !this.formControl.value ||
      JSON.stringify(option) !== JSON.stringify(this.formControl.value)
    ) {
      const value = valuePath
        ? this.resolveFieldDataPipe.transform(option, valuePath)
        : option;
      this.optionList.setResponseValues(value);
      this.formControl.patchValue(value);
      this.onChange.emit(value);
      this.setLabelValueAndIcon();
    }
    this.isFocus = false;
  }

  resetInputSearchElement(): void {
    if (this.inputSearchElement) {
      this.inputSearchElement.value = '';
      this.optionsCopy = this.optionList.getRequestValues();
    }
  }

  handleSearchChange(value: string): void {
    this.optionsCopy = ArrayUtils.filterArrayByText(
      this.optionList.getRequestValues(),
      value,
      String(this.optionList.viewPath)
    );
    this.setSelectSize();
    this.builderSelectOption();
  }

  setSelectSize(): void {
    this.selectSize =
      this.optionsCopy.length < 5
        ? this.optionsCopy.length < 2
          ? 2
          : this.optionsCopy.length
        : 5;
  }

  handleClickInput(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    if (!this.formControl.disabled) {
      this.optionsCopy = this.optionList.getRequestValues();
      this.setSelectSize();
      this.builder();
      const appSelect = document.getElementsByClassName('app-select-container');
      for (let i = 0; i <= appSelect.length; i++) {
        if (this.divSelectContainerElement !== appSelect.item(i)) {
          if (appSelect.item(i)) {
            this.renderer2.removeClass(appSelect.item(i), 'show');
            if (appSelect.item(i).children[0]) {
              this.renderer2.removeChild(
                appSelect.item(i),
                appSelect.item(i).children[0]
              );
            }
          }
        } else {
          if (appSelect.item(i)) {
            this.renderer2.addClass(appSelect.item(i), 'show');
          }
        }
      }
      this.isFocus = false;
    }
  }

  handleFocus(event: any): void {
    event.stopPropagation();
    event.preventDefault();
    this.isFocus = true;
  }

  removeSelectElement(): void {
    if (this.container === 'body') {
      if (this.divSelectContainerElement) {
        document.body.removeChild(this.divSelectContainerElement);
        this.divSelectContainerElement = null;
      }
    } else {
      this.renderer2.removeChild(
        this.divSelectContainerElement,
        this.divSelectElement
      );

      if (this.isShowSearch) {
        this.renderer2.removeClass(
          this.divSelectContainerElement,
          'show-search'
        );
        this.renderer2.removeChild(
          this.divSelectContainerElement,
          this.inputSearchElement
        );
      }
      this.divSelectContainerElement.classList.toggle('show');
      this.divSelectElement = null;
      if (!this.formControl.touched) {
        this.formControl.markAsTouched();
      }
    }
  }

  @HostListener('document:click')
  handleDocumentClick(): void {
    if (!this.isFocus && this.divSelectElement) {
      this.removeSelectElement();
      this.isFocus = false;
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    if (this.container === 'body') {
      this.setDivSelectContainerElementPosition();
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
