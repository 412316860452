import { ChartDataSets } from 'chart.js';
import { Color, SingleDataSet } from 'ng2-charts';
import { chartColorConstant } from '../constant/chart-color-constant';
import { Chart } from '../models/chart';
import { ChartModel } from '../models/chart-model';
import { doughnutChartOptions } from '../options/doughnut-chart.options';
import { customChartPlugin } from '../plugins/app-chart-custom-plugins';
export function setPolarAreaChart(chartState: ChartModel): Array<Chart> {
  const { type } = { ...chartState };
  let dataSets: SingleDataSet = [];
  const firstDataOfDataSets = chartState.dataSets[0];
  let labels: Array<string | Array<string>> = [];
  if (typeof firstDataOfDataSets === 'number') {
    dataSets = chartState.dataSets as Array<Array<number>>;
  } else if (Array.isArray(firstDataOfDataSets)) {
    dataSets = [...chartState.dataSets] as Array<number>;
  } else if (
    typeof firstDataOfDataSets === 'object' &&
    firstDataOfDataSets.hasOwnProperty('data')
  ) {
    dataSets.push(
      ...((chartState.dataSets[0] as ChartDataSets).data as Array<number>)
    );
  }

  labels = [...chartState.labels].splice(0, dataSets.length);

  const colors: Array<Color> = [];
  dataSets.forEach(() => {
    colors.push({ backgroundColor: chartColorConstant });
  });

  return [
    {
      dataSets,
      options: doughnutChartOptions,
      plugins: [customChartPlugin],
      labels,
      type,
      colors
    }
  ];
}
