<app-layout [isLoading]="formLoading">
  <div
    *ngIf="!formLoading"
    style="
      border-bottom: 1px solid var(--light-tertiary-color);
      padding-top: 32px;
    "
  >
    <app-content-slider>
      <ng-template #customContent>
        <div
          class="slider-item-report cursor-pointer"
          (click)="doClickHistoryType('drafting')"
          [ngStyle]="
            historyType === 'drafting'
              ? { border: '1px solid var(--primary)' }
              : ''
          "
        >
          <div class="slider-title">
            <div class="slider-title-icon pvc pv-binder"></div>
            <h4 class="text-primary white-space-normal">
              {{ 'contract-history.slider.drafting.title' | translate }}</h4
            >
          </div>
        </div>
        <div
          class="slider-item-report cursor-pointer"
          (click)="doClickHistoryType('monitoring')"
          [ngStyle]="
            historyType === 'monitoring'
              ? { border: '1px solid var(--primary)' }
              : ''
          "
        >
          <div class="slider-title">
            <div class="slider-title-icon pvc pv-file-contract"></div>
            <h4 class="text-primary white-space-normal">
              {{ 'contract-history.slider.onGoing.title' | translate }}</h4
            >
          </div>
        </div>
        <div
          class="slider-item-report cursor-pointer"
          (click)="doClickHistoryType('closeAndTerminate')"
          [ngStyle]="
            historyType === 'closeAndTerminate'
              ? { border: '1px solid var(--primary)' }
              : ''
          "
        >
          <div class="slider-title">
            <div class="slider-title-icon pvc pv-clipboard-x"></div>
            <h4 class="text-primary white-space-normal">
              {{
                'contract-history.slider.closeAndTerminate.title' | translate
              }}</h4
            >
          </div>
        </div>
      </ng-template>
    </app-content-slider>
  </div>
  <app-table-xx
    *ngIf="historyType === 'drafting'"
    header="contract-history.index.table.contractDraftingList"
    [model]="tableResponse"
    stringUrl="/contract-history/index"
    isShowButtonModifyTable="true"
    (onClick)="doDetail($event)"
    key="DRAFTING"
  >
    <ng-template #headerButtons>
      <app-button
        *ngIf="!isView"
        [disabled]="formSaving || isDisabled"
        (onClick)="doExport()"
        ><em class="pir pi-file-download pi-2x"></em>&nbsp;
        {{ 'contract-history.button.export' | translate }}</app-button
      >
    </ng-template>
    <ng-template #headerFilterGroup>
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <div class="form-group row text-sm-left">
          <div class="col-lg-12 row" style="margin-bottom: -1.5rem">
            <label
              class="cml-3 d-flex align-items-center"
              style="margin-top: auto"
              >{{ 'contract-history.form.requestPeriod' | translate }}</label
            >
            <div class="cml-3 text-left">
              <app-date-picker-x
                size="XL"
                formControlName="date"
                [range]="true"
                (onChange)="onChangeFilter()"
              >
              </app-date-picker-x>
            </div>
            <div class="cml-3 text-left col-sm-2">
              <app-dropdown-select
                size="XL"
                formControlName="worklistStatusList"
                (onChange)="onChangeFilter()"
                showLimitValue="3"
                type="CHOSEN"
                isMultiple="true"
                placeholder="contract-history.placeholder.status"
                [optionList]="worklistStatusOptionList"
              >
              </app-dropdown-select>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </app-table-xx>
  <app-table-xx
    *ngIf="historyType === 'monitoring'"
    header="contract-history.index.table.contractList"
    [model]="tableResponseMonitoring"
    stringUrl="/contract-history/index"
    isShowButtonModifyTable="true"
    (onClick)="doDetail($event)"
    key="ON_GOING"
  >
    <ng-template #headerButtons>
      <app-button
        *ngIf="!isView"
        [disabled]="formSaving || isDisabled"
        (onClick)="doExport()"
        ><em class="pir pi-file-download pi-2x"></em>&nbsp;
        {{ 'contract-history.button.export' | translate }}</app-button
      >
    </ng-template>
    <ng-template #headerFilterGroup>
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <div class="form-group row text-sm-left">
          <div class="col-lg-12 row" style="margin-bottom: -1.5rem">
            <label
              class="cml-3 d-flex align-items-center"
              style="margin-top: auto"
              >{{ 'contract-history.form.contractPeriod' | translate }}</label
            >
            <div class="cml-3 text-left">
              <app-date-picker-x
                size="XL"
                formControlName="date"
                [range]="true"
                (onChange)="onChangeFilter()"
              >
              </app-date-picker-x>
            </div>
            <div class="cml-3 text-left col-sm-2">
              <app-dropdown-select
                size="XL"
                formControlName="contractStatusList"
                (onChange)="onChangeFilter()"
                showLimitValue="3"
                type="CHOSEN"
                isMultiple="true"
                placeholder="contract-history.placeholder.status"
                [optionList]="contractStatusOptionList"
              >
              </app-dropdown-select>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </app-table-xx>
  <app-table-xx
    *ngIf="historyType === 'closeAndTerminate'"
    header="contract-history.index.table.contractClosureList"
    [model]="tableResponseClosureTerminate"
    stringUrl="/contract-history/index"
    isShowButtonModifyTable="true"
    (onClick)="doDetail($event)"
    key="CLOSURE_TERMINATION"
  >
    <ng-template #headerButtons>
      <app-button
        *ngIf="!isView"
        [disabled]="formSaving || isDisabled"
        (onClick)="doExport()"
        ><em class="pir pi-file-download pi-2x"></em>&nbsp;
        {{ 'contract-history.button.export' | translate }}</app-button
      >
    </ng-template>
    <ng-template #headerFilterGroup>
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <div class="form-group row text-sm-left">
          <div class="col-lg-12 row" style="margin-bottom: -1.5rem">
            <label
              class="cml-3 d-flex align-items-center"
              style="margin-top: auto"
              >{{ 'contract-history.form.contractPeriod' | translate }}</label
            >
            <div class="cml-3 text-left">
              <app-date-picker-x
                size="XL"
                formControlName="date"
                [range]="true"
                (onChange)="onChangeFilter()"
              >
              </app-date-picker-x>
            </div>
            <div class="cml-3 text-left col-sm-2">
              <app-dropdown-select
                size="XL"
                formControlName="contractStatusList"
                (onChange)="onChangeFilter()"
                showLimitValue="3"
                type="CHOSEN"
                isMultiple="true"
                placeholder="contract-history.placeholder.status"
                [optionList]="contractStatusOptionList"
              >
              </app-dropdown-select>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </app-table-xx>
</app-layout>
