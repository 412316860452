import { Injectable } from '@angular/core';
import { TablePlugin } from '../interfaces/table-plugin';
import * as plugins from '../plugins/index';
@Injectable({
  providedIn: 'root'
})
export class TablePluginService {
  private pluginsMap: Map<string, TablePlugin> = new Map();
  constructor() {
    Object.keys(plugins).forEach(key => {
      this.pluginsMap.set(plugins[key].name, plugins[key]);
    });
  }

  public getPlugin(name: string): TablePlugin {
    return this.pluginsMap.get(name);
  }
}
