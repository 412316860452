import { Aanwijzing } from 'src/app/core/bean/aanwijzing';
import { AanwijzingParticipant } from 'src/app/core/bean/aanwijzing-participant';
import { ItemType } from 'src/app/core/bean/item-type';
import { Module } from 'src/app/core/bean/module';
import { Procurement } from 'src/app/core/bean/procurement';
import { ProcurementItem } from 'src/app/core/bean/procurement-item';
import { ProcurementSchedule } from 'src/app/core/bean/procurement-schedule';
import { TransactionType } from 'src/app/core/bean/transaction-type';
import { ApprovalPathResponseModel } from 'src/app/core/components/app-approval-path-x';
import { WorkflowStepInfoModel } from 'src/app/core/components/app-workflow-step-info/model/workflow-step-info-model';

export class AanwijzingEditResponse {
    procurement: Procurement;
    workflowStepInfoModel: WorkflowStepInfoModel;
    itemTypeList: ItemType[] = [];
    procurementItemList: ProcurementItem[] = [];
    procurementSchedule: ProcurementSchedule;
    transactionTypeList: TransactionType[] = [];
    aanwijzingList: Aanwijzing[] = [];

    approvalPathResponseModel: ApprovalPathResponseModel;
    module: Module;
    isUserApproval: boolean;

    aanwijzingParticipant: AanwijzingParticipant;
    myAanwijzingParticipantList: AanwijzingParticipant[] = [];
    isPassedMinVendor: boolean;
    minimumParticipant: number;
}
