import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { EvaluationCriteria } from 'src/app/core/bean/evaluation-criteria';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { AppTableComponent } from './../../core/components/app-table/app-table.component';
import { Response } from './../../core/model/response-model';
import { ResponseStatusModel } from './../../core/model/response-status-model';
@Component({
  templateUrl: './administration-criteria.component.html'
})
export class AdministrationCriteriaComponent
  extends BaseModuleComponent
  implements OnInit {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<EvaluationCriteria>;
  constructor(translateService: TranslateService) {
    super('administration-criteria', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      { field: 'description', header: 'table.column.description' }
    ]);
    console.log(this.tableResponse)
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/administration-criteria/add']);
  }

  public doEdit(administrationCriteria: EvaluationCriteria): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set(
      'administrationCriteriaId',
      administrationCriteria.id
    );
    this.router.navigate(['/pages/administration-criteria/edit']);
  }

  public doDelete(administrationCriteria: EvaluationCriteria): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<EvaluationCriteria>>(
              '/administration-criteria/delete',
              administrationCriteria
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
