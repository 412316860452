import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
@Component({
  templateUrl: './about.component.html'
})
export class AboutComponent extends BaseModuleComponent {
  constructor(translateService: TranslateService) {
    super('contact', translateService);
  }

  onInit(): void {
    this.setStateReady();
  }
}
