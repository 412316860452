import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { CostCenter } from '../../core/bean/cost-center';
import { Currency } from '../../core/bean/currency';
import { PaymentVoucherTax } from '../../core/bean/payment-voucher-tax';
import { Tax } from '../../core/bean/tax';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';

@Component({
  templateUrl: './payment-voucher-popup-tax.component.html'
})
export class PaymentVoucherPopupTaxComponent extends BaseComponentComponent {
  @Input() public currencyList: Currency[] = [];
  @Input() public taxList: Tax[] = [];
  @Input() public costCenterList: CostCenter[] = [];
  @Input() public paymentVoucherTax: PaymentVoucherTax;
  @Input() public todo: string;
  @Output() onChange: EventEmitter<PaymentVoucherTax> = new EventEmitter();

  public taxOptionList: OptionListModel<Tax> = new OptionListModel(true);
  public costCenterOptionList: OptionListModel<CostCenter> =
    new OptionListModel(true);
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );

  constructor(public translateService: TranslateService) {
    super('payment-voucher');
  }

  onInit(): void {
    this.buildFomGroup();
    this.setFormGroup();
  }

  public setOptionList(): void {
    this.taxOptionList.setRequestValues(this.taxList);
    this.costCenterOptionList.setRequestValues(this.costCenterList);
    this.currencyOptionList.setRequestValues(this.currencyList);
  }

  public buildFomGroup(): void {
    this.formGroup = this.formBuilder.group({
      tax: [null, Validators.required()],
      coa: [null, Validators.required()],
      costCenter: [null, Validators.required()],
      taxPercentage: [null],
      taxBase: [null, Validators.required()],
      incomeTax: [null],
      taxMethod: [null],
      taxValue: [null]
    });
  }

  public setFormGroup(): void {
    if (this.paymentVoucherTax) {
      const taxBase = {
        price: this.paymentVoucherTax.taxBaseAmount,
        currency: this.currencyList[0]
      };

      const taxValue = {
        price: this.paymentVoucherTax.taxPercentageValue,
        currency: this.currencyList[0]
      };

      this.formGroup.patchValue({
        tax: this.paymentVoucherTax.tax,
        coa: this.paymentVoucherTax.coa,
        costCenter: this.paymentVoucherTax.costCenter,
        taxPercentage: this.paymentVoucherTax.taxPercentageValue,
        taxBase,
        taxValue,
        incomeTax: this.paymentVoucherTax.incomeTaxAmount,
        taxMethod: this.paymentVoucherTax.taxMethod
      });
    }
    if (this.todo === 'view') {
      this.setViewOnly();
    }
    this.setOptionList();
    this.setStateReady();
  }

  public onChangePayment(): void {
    const tax = this.formGroup.get('tax').value;
    if (tax) {
      this.formGroup.get('coa').patchValue(tax.coa);
      if (tax.taxMethod.code === this.global.appConstant.cm.TAX_METHOD_VALUE) {
        this.formGroup.patchValue({
          taxValue: { price: +tax.amount }
        });

        this.formGroup.get('taxValue').setValidators([Validators.required()]);
        this.formGroup.get('taxValue').updateValueAndValidity();

        this.formGroup.get('taxPercentage').clearValidators();
        this.formGroup.get('taxPercentage').updateValueAndValidity();
      } else if (
        tax.taxMethod.code === this.global.appConstant.cm.TAX_METHOD_PERCENT
      ) {
        this.formGroup.get('taxPercentage').patchValue(tax.amount);

        this.formGroup
          .get('taxPercentage')
          .setValidators([Validators.required()]);
        this.formGroup.get('taxPercentage').updateValueAndValidity();

        this.formGroup.get('taxValue').clearValidators();
        this.formGroup.get('taxValue').updateValueAndValidity();
      }

      this.formGroup.get('taxMethod').patchValue(tax.taxMethod);
      this.onInputTaxPercentageOrBase(this.formGroup);
    }
  }

  public onInputTaxPercentageOrBase(event: any): void {
    const taxMethod = this.formGroup.get('taxMethod').value as Tax;
    const taxPercentage =
      taxMethod.code === this.global.appConstant.cm.TAX_METHOD_PERCENT
        ? this.formGroup.value.taxPercentage
        : this.formGroup.value.taxValue;

    let taxBase = 0;
    if (this.formGroup.value.taxBase) {
      taxBase = +this.formGroup.value.taxBase.price;
    }

    if (taxMethod.code === this.global.appConstant.cm.TAX_METHOD_PERCENT) {
      if (taxPercentage && +taxPercentage > 100) {
        this.formGroup
          .get('taxPercentage')
          .setValidators([
            Validators.min(taxPercentage.price),
            Validators.required()
          ]);
        this.formGroup.get('taxPercentage').setErrors({
          message: this.translateService.instant('app.validation.max')
        });
        this.formGroup.get('taxPercentage').markAsTouched({ onlySelf: true });
      }
    }

    if (taxPercentage && taxPercentage !== 0 && taxBase !== 0) {
      let incomeTax: number;
      if (taxMethod.code === this.global.appConstant.cm.TAX_METHOD_PERCENT) {
        incomeTax = (taxPercentage * taxBase) / 100;
      } else if (
        taxMethod.code === this.global.appConstant.cm.TAX_METHOD_VALUE
      ) {
        incomeTax = taxBase - +taxPercentage?.price;

        if (incomeTax < 0) {
          this.formGroup
            .get('taxBase')
            .setValidators([
              Validators.min(taxPercentage.price),
              Validators.required()
            ]);
          this.formGroup.get('taxBase').setErrors({
            min: true,
            message: this.translateService.instant('app.validation.min')
          });
          this.formGroup.get('taxBase').markAsTouched({ onlySelf: true });
        } else {
          this.formGroup.get('taxBase').clearValidators();

          this.formGroup
            .get('taxBase')
            .setValidators([
              Validators.min(taxPercentage.price),
              Validators.required()
            ]);
          this.formGroup.get('taxBase').updateValueAndValidity();
        }
      }
      this.formGroup.patchValue({ incomeTax });
    } else {
      this.formGroup.get('incomeTax').patchValue(null);
    }
    console.log(event);
    console.log(this.formGroup);
  }

  public doAdd(): void {
    this.validate();
    if (this.formGroup.valid) {
      if (!this.paymentVoucherTax) {
        this.paymentVoucherTax = new PaymentVoucherTax();
      }

      this.paymentVoucherTax.taxMethod = this.formGroup.value.taxMethod;
      this.paymentVoucherTax.coa = this.formGroup.value.coa;
      this.paymentVoucherTax.costCenter = this.formGroup.value.costCenter;
      this.paymentVoucherTax.taxPercentageValue =
        this.paymentVoucherTax.taxMethod.code ===
        this.global.appConstant.cm.TAX_METHOD_PERCENT
          ? this.formGroup.value.taxPercentage
          : this.formGroup.value.taxValue.price;
      this.paymentVoucherTax.tax = this.formGroup.value.tax;
      this.paymentVoucherTax.taxBaseAmount =
        +this.formGroup.value.taxBase.price;
      this.paymentVoucherTax.incomeTaxAmount = +this.formGroup.value.incomeTax;
      this.onChange.emit(this.paymentVoucherTax);
    }
  }
}
