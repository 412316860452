import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { BondType } from '../../core/bean/bond-type';
import { Currency } from '../../core/bean/currency';
import { QuotationItemReference } from '../../core/bean/quotation-item-reference';
import { Refutation } from '../../core/bean/refutation';
import { RefutationStatus } from '../../core/bean/refutation-status';
import { RefutationSubmission } from '../../core/bean/refutation-submission';
import { RefutationVendor } from '../../core/bean/refutation-vendor';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { ToastService } from '../../core/components/app-toast/app-toast.service';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';
import { RefutationVendorByItemViewEntity } from '../../core/view/entity/refutation-vendor-by-item-view.entity';
import { AppPopupRefutationVendorTocComponent } from './app-popup-refutation-vendor-toc.component';
import { RefutationVendorResponse } from './refutation-vendor-response';
@Component({
  templateUrl: './refutation-vendor-item-submission.component.html'
})
export class RefutationVendorItemSubmissionComponent extends BaseModuleComponent {
  public procurementId: number;
  public todo: string;
  public mode: string;
  public backToUrl: string;
  public urlBackOutside: string;
  public refutationStatus: RefutationStatus;
  public refutationVendorResponse: RefutationVendorResponse;
  public refutationVendorByItemView: RefutationVendorByItemViewEntity;
  public tableResponse: TableResponseModel<QuotationItemReference>;
  public isShowForm = false;
  public today: Date = new Date();
  public minBidBondValue: number;
  public refutationVendorList: RefutationVendor[] = [];
  public refutationSubmissionList: RefutationSubmission[] = [];
  public refutationVendor: RefutationVendor;
  public refutationSubmission: RefutationSubmission;
  public bondTypeOptionList: OptionListModel<BondType> = new OptionListModel(
    true
  );
  public refutationOptionList: OptionListModel<any> = new OptionListModel(true);
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );
  public fileUploader: FileUploader = new FileUploader(
    '/refutation-vendor/',
    'refutation-vendor.form.refutationEvidence',
    this.global.appConstant.fileType.DOC_REFUTATION,
    false,
    this.global.config.parameterModel.maxFileRefutation
  );

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public toastService: ToastService
  ) {
    super('refutation-vendor', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.setRequestValues();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.refutationVendorByItemView = this.global.routerParams.get(
      'refutationVendorByItemView'
    );
    this.procurementId = this.global.routerParams.get('procurementId');
    this.todo = this.global.routerParams.get('todo');
    this.refutationStatus = this.global.routerParams.get('refutationStatus');
    this.refutationVendorResponse = this.global.routerParams.get(
      'refutationVendorResponse'
    );
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    this.backToUrl = this.global.routerParams.get('backToUrl');
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'quotationItem.quotation.procurementVendor.vendor.name',
        header: 'table.column.winningVendorCandidate'
      },
      {
        field: 'quantity',
        header: 'table.column.qty',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      }
    ]);
    this.tableResponse.setCustomData({
      procurementItemId: this.refutationVendorByItemView.procurementItem.id
    });
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      bondType: [null],
      bondNumber: [null],
      bondIssued: [null],
      amount: [null],
      date: [null],
      startDate: [null],
      endDate: [null],
      note: [null],
      refutationVendor: [null],
      fileList: [null],
      isAccepted: [null],
      isRefute: [null, Validators.required()]
    });
  }

  public setViewOnly(): void {
    this.formGroup.setIsView(true);
    this.isViewOnly = true;
  }

  public setRequestValues(): void {
    const refutationList = [
      {
        value: false,
        name: this.translateService.instant('app.button.no'),
        description: this.translateService.instant(
          'refutation-vendor.info.confirmProcurementResult'
        )
      },
      {
        value: true,
        name: this.translateService.instant('app.button.yes'),
        description: this.translateService.instant(
          'refutation-vendor.info.submitRefutation'
        )
      }
    ];
    this.refutationOptionList.setRequestValues(refutationList);
    this.bondTypeOptionList.setRequestValues(
      this.refutationVendorResponse.bondTypeList
    );
    this.currencyOptionList.setRequestValues(
      this.refutationVendorResponse.currencyList
    );
  }

  public setFormGroup(): void {
    this.mode =
      this.todo === 'view' ||
      (this.todo !== 'view' && this.refutationVendorByItemView.isReference)
        ? 'view'
        : 'edit';
    this.minBidBondValue =
      (this.refutationVendorByItemView.quotationItem.price *
        this.refutationVendorByItemView.procurementItem.prItem.quantity *
        this.global.config.parameterModel.minBidBondValue) /
      100;
    this.refutationVendorList =
      this.refutationVendorResponse.refutationVendorList;
    this.refutationSubmissionList =
      this.refutationVendorResponse.refutationSubmissionList;

    if (this.refutationVendorList && this.refutationVendorList.length > 0) {
      this.refutationVendor = this.refutationVendorList.find(
        refutationVendor =>
          refutationVendor.procurementItem.id ===
          this.refutationVendorByItemView.procurementItem.id
      );
      if (this.refutationVendor) {
        const isRefute =
          this.refutationStatus.code ===
            this.global.appConstant.pm.REFUTATION_STATUS_CLOSED_RESPONSE &&
          this.refutationVendorResponse.refutation.refutationStatus.code ===
            this.global.appConstant.pm.REFUTATION_STATUS_DRAFT
            ? this.refutationOptionList
                .getRequestValues()
                .find(refutationStatus => refutationStatus.value === false)
            : this.refutationOptionList
                .getRequestValues()
                .find(
                  refutationStatus =>
                    refutationStatus.value === this.refutationVendor.isRefute
                );
        this.formGroup.patchValue({ isRefute });
        this.isShowForm = isRefute.value;
        if (this.todo === 'view') {
          const index = this.refutationOptionList
            .getRequestValues()
            .findIndex(
              refutationStatus => refutationStatus.value === !isRefute.value
            );
          this.refutationOptionList.requestValues.splice(index, 1);
        }

        if (this.refutationVendor.isRefute) {
          this.refutationSubmission = this.refutationSubmissionList.find(
            refutationSubmission =>
              refutationSubmission.refutationVendor.procurementItem.id ===
              this.refutationVendorByItemView.procurementItem.id
          );

          const {
            id,
            bondType,
            bondNumber,
            bondIssued,
            amount,
            startDate,
            endDate,
            note,
            refutationVendor
          } = this.refutationSubmission;

          if (
            refutationVendor.refutationSubmissionDocList &&
            refutationVendor.refutationSubmissionDocList.length > 0
          ) {
            this.fileUploader.setFileList(
              refutationVendor.refutationSubmissionDocList.map(
                refutationSubmissionDoc => refutationSubmissionDoc.file
              )
            );
          }

          this.formGroup.patchValue({
            id,
            bondType,
            bondNumber,
            bondIssued,
            amount: { price: amount },
            date: { from: startDate, to: endDate },
            startDate,
            endDate,
            note,
            refutationVendor,
            fileList:
              refutationVendor.fileObjectList &&
              refutationVendor.fileObjectList.length > 0
                ? refutationVendor.fileObjectList
                : this.fileUploader.fileObjectList
          });
        }
      } else {
        this.setFormGroupForNoResponse();
      }
    } else {
      this.setFormGroupForNoResponse();
    }
    this.setStateReady();
    if (this.mode === 'view') {
      this.setViewOnly();
    }
  }

  public setFormGroupForNoResponse(): void {
    if (
      this.refutationStatus.code ===
      this.global.appConstant.pm.REFUTATION_STATUS_CLOSED_RESPONSE
    ) {
      const isRefute = this.refutationOptionList
        .getRequestValues()
        .find(refutationStatus => refutationStatus.value === false);

      this.formGroup.patchValue({ isRefute });
      this.isShowForm = isRefute.value;
      const index = this.refutationOptionList
        .getRequestValues()
        .findIndex(
          refutationStatus => refutationStatus.value === !isRefute.value
        );
      this.refutationOptionList.requestValues.splice(index, 1);
    }
  }

  public onChangeRefutation(data: any): void {
    if (data.value) {
      this.isShowForm = true;
      this.formGroup
        .get('isAccepted')
        .setValidators(
          Validators.isChecked(
            null,
            this.translateService.instant(
              'refutation-vendor.msg.warning.accToProceed'
            )
          )
        );
      this.formGroup.get('bondType').setValidators(Validators.required());
      this.formGroup.get('bondNumber').setValidators(Validators.required());
      this.formGroup.get('bondIssued').setValidators(Validators.required());
      this.formGroup
        .get('amount')
        .setValidators(
          Validators.compose([
            Validators.min(this.minBidBondValue),
            Validators.required()
          ])
        );
      this.formGroup.get('date').setValidators(Validators.required());
      this.formGroup.get('fileList').setValidators(Validators.required());
    } else {
      this.isShowForm = false;
      this.formGroup.get('isAccepted').clearValidators();
      this.formGroup.get('bondType').clearValidators();
      this.formGroup.get('bondNumber').clearValidators();
      this.formGroup.get('bondIssued').clearValidators();
      this.formGroup.get('amount').clearValidators();
      this.formGroup.get('date').clearValidators();
      this.formGroup.get('fileList').clearValidators();
    }
    this.formGroup.get('isAccepted').updateValueAndValidity();
    this.formGroup.get('bondType').updateValueAndValidity();
    this.formGroup.get('bondNumber').updateValueAndValidity();
    this.formGroup.get('bondIssued').updateValueAndValidity();
    this.formGroup.get('amount').updateValueAndValidity();
    this.formGroup.get('date').updateValueAndValidity();
    this.formGroup.get('fileList').updateValueAndValidity();
  }

  public onInputBondValue(data: any): void {
    if (data && +data.price && +data.price < this.minBidBondValue) {
      this.formGroup
        .get('amount')
        .setValidators(Validators.min(this.minBidBondValue));
      this.formGroup.get('amount').setErrors({
        message: this.translateService.instant('app.validation.min'),
        min: true
      });
    } else {
      this.formGroup.get('amount').setValidators(Validators.required());
      this.formGroup.get('amount').updateValueAndValidity();
    }
    this.formGroup.get('amount').markAsTouched();
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public doOpenPopupTOC(): void {
    this.appPopupService.open(AppPopupRefutationVendorTocComponent);
  }

  public doCancel(): void {
    this.global.routerParams.set('procurementId', this.procurementId);
    this.global.routerParams.set('todo', this.todo);
    this.global.routerParams.set('refutationStatus', this.refutationStatus);
    this.global.routerParams.set(
      'refutationVendorResponse',
      this.refutationVendorResponse
    );
    this.global.routerParams.set('urlBackOutside', '/pages/refutation-vendor');
    this.router.navigate(['/pages/refutation-vendor/detail']);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const refutation = this.refutationVendorResponse.refutation
              ? this.refutationVendorResponse.refutation
              : new Refutation();
            refutation.quotation = this.refutationVendorResponse.quotation;

            const refutationVendor = this.refutationVendor
              ? this.refutationVendor
              : new RefutationVendor();
            refutationVendor.refutation = refutation;
            refutationVendor.procurementItem =
              this.refutationVendorByItemView.procurementItem;
            refutationVendor.isRefute =
              this.formGroup.get('isRefute').value.value;

            if (refutationVendor.isRefute) {
              const refutationSubmission: RefutationSubmission =
                this.global.copyFormAttributeToModel(
                  new RefutationSubmission(),
                  this.formGroup
                );
              refutationSubmission.amount = +this.formGroup.value.amount.price;
              refutationSubmission.startDate = this.formGroup.value.date.from;
              refutationSubmission.endDate = this.formGroup.value.date.to;
              refutationSubmission.refutationVendor = refutationVendor;
              refutationVendor.fileObjectList = this.formGroup.value.fileList;
              if (
                this.refutationSubmissionList &&
                this.refutationSubmissionList.length > 0
              ) {
                const index = this.refutationSubmissionList.findIndex(
                  refutationSubmission =>
                    refutationSubmission.refutationVendor.procurementItem.id ===
                    this.refutationVendorByItemView.procurementItem.id
                );
                if (index !== -1) {
                  this.refutationSubmissionList[index] = refutationSubmission;
                } else {
                  this.refutationSubmissionList.push(refutationSubmission);
                }
              } else {
                this.refutationSubmissionList.push(refutationSubmission);
              }
            }
            if (
              this.refutationVendorList &&
              this.refutationVendorList.length > 0
            ) {
              const index = this.refutationVendorList.findIndex(
                refutationVendor =>
                  refutationVendor.procurementItem.id ===
                  this.refutationVendorByItemView.procurementItem.id
              );
              if (index !== -1) {
                this.refutationVendorList[index] = refutationVendor;
              } else {
                this.refutationVendorList.push(refutationVendor);
              }
            } else {
              this.refutationVendorList.push(refutationVendor);
            }

            const submittedStatus =
              this.refutationVendorResponse.submittedStatusList.find(
                submittedStatus =>
                  refutationVendor.isRefute
                    ? submittedStatus.code ===
                      this.global.appConstant.pm.SUBMITTED_STATUS_AVAILABLE
                    : submittedStatus.code ===
                      this.global.appConstant.pm.SUBMITTED_STATUS_UNAVAILABLE
              );

            this.refutationVendorByItemView.submittedStatus = submittedStatus;
            const index =
              this.refutationVendorResponse.refutationVendorByItemViewList.findIndex(
                refutationItem =>
                  refutationItem.id === this.refutationVendorByItemView.id
              );
            this.refutationVendorResponse.refutationVendorByItemViewList[
              index
            ] = this.refutationVendorByItemView;
            this.refutationVendorResponse.refutation = refutation;
            this.refutationVendorResponse.refutationVendorList =
              this.refutationVendorList;
            this.refutationVendorResponse.refutationSubmissionList =
              this.refutationSubmissionList;

            this.global.routerParams.set('procurementId', this.procurementId);
            this.global.routerParams.set('todo', this.todo);
            this.global.routerParams.set(
              'refutationStatus',
              this.refutationStatus
            );
            this.global.routerParams.set(
              'refutationVendorResponse',
              this.refutationVendorResponse
            );
            this.global.routerParams.set(
              'urlBackOutside',
              '/pages/refutation-vendor'
            );

            this.formGroup.reset();
            this.global.modalService.confirmation(
              'refutation-vendor.saveConfirmation.msg.yourWorkHasBeenSaved',
              'refutation-vendor.saveConfirmation.title.dataHasBeenSaved',
              null,
              null,
              'pvc pv-check-circle',
              null,
              { autoCloseInMs: 1500 }
            );
            this.router.navigate(['/pages/refutation-vendor/detail']);
          }
        });
    } else {
      if (this.formGroup.get('isRefute').invalid) {
        this.toastService.showError(
          this.translateService.instant(
            'refutation-vendor.alert.msg.chooseRefutationSubmissionFirst'
          )
        );
      }
    }
  }
}
