import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { take } from 'rxjs/operators';
import {} from 'src/app/pages/example/employee/dto/employee.dto';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { PrItemReleased } from '../../bean/pr-item-released';
import { Sow } from '../../bean/sow';
import { SowPaymentTermItem } from '../../bean/sow-payment-term-item';
import { AppPopupSowService } from '../app-popup/app-popup-sow/app-popup-sow.service';
import { SelectedTableRecordModel } from '../table/model/selected-table-record-model';
import { TableResponseModel } from '../table/model/table-response-model';

@Component({
  selector: 'app-sow',
  templateUrl: './app-sow.component.html'
})
export class AppSowComponent extends BaseComponentComponent {
  @Input() public sowList: Sow[] = [];
  @Input() public isView: boolean;
  @Input() public isPo: boolean;
  @Input() public prItemReleasedList: PrItemReleased[];
  @Input() public amount: number;
  @Input() public isDisabled = false;
  @Input() public isBlanketOrder = false;
  @Output() public onChange: EventEmitter<Sow[]> = new EventEmitter();
  public tableResponse: TableResponseModel<Sow>;
  public availableAmountSow: number;
  public totalPercentage: number = 0;
  public isEdit: boolean;

  constructor(public appPopupSowService: AppPopupSowService) {
    super('app-sow');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      sowLists: this.formBuilder.array([])
    });
  }
  public get sowLists(): FormArray {
    return this.formGroup.get('sowLists') as FormArray;
  }

  public setFormGroup(): void {
    this.sowList.forEach((sow: Sow) => {
      const sowPaymentTermList = [];
      sowPaymentTermList.push(sow.sowPaymentTermList);
      let amount = 0;
      sow.sowPaymentTermList?.forEach((sowPaymentTerm: any) => {
        sowPaymentTerm.sowPaymentTermGoodsItemList = [];
        sowPaymentTerm.sowPaymentTermServiceItemList = [];
        amount = amount + +sowPaymentTerm.amount;
        sowPaymentTerm.sowPaymentTermItemList.forEach(
          (sowItem: SowPaymentTermItem) => {
            if (
              sowItem.prItemReleased.prItem.item.itemType.code ===
              this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
            ) {
              sowPaymentTerm.sowPaymentTermGoodsItemList.push({
                prItemReleased: sowItem.prItemReleased,
                quantity: sowItem.quantity,
                remainingQuantity: sowItem.remainingQuantity
              });
            }
            if (
              sowItem.prItemReleased.prItem.item.itemType.code ===
              this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
            ) {
              sowPaymentTerm.sowPaymentTermServiceItemList.push({
                prItemReleased: sowItem.prItemReleased,
                quantity: sowItem.quantity,
                remainingQuantity: sowItem.remainingQuantity
              });
            }
            this.isEdit = true;
            if (+sowPaymentTerm.percentage.toFixed(2) === 100) {
              this.totalPercentage = 100;
            } else {
              this.totalPercentage = 0;
            }
          }
        );
      });
      const formGroup = this.formBuilder.group({
        id: sow.id,
        stageOfWork: sow.stageOfWork,
        sowPaymentTermList,
        startDate: sow.startDate,
        endDate: sow.endDate,
        totalTermin: sow.sowPaymentTermList?.length,
        amount: this.isBlanketOrder ? this.amount : amount,
        sowPaymentType: sow.sowPaymentType
      });
      this.sowLists.push(formGroup);
      this.buildTableResponse();
      this.doSetAvaiableAmount();
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'stageOfWork.name',
        header: 'table.column.stageOfWork',
        plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
      },
      {
        field: 'totalTermin',
        header: 'table.column.totalTermin',
        className: 'text-right'
      },
      {
        field: 'amount',
        header: 'table.column.amount',
        className: 'text-right',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      },
      {
        field: 'startDate',
        header: 'table.column.startDate',
        plugins: 'date'
      },
      {
        field: 'endDate',
        header: 'table.column.endDate',
        plugins: 'date'
      }
    ]);
    this.tableResponse.setRecordList(this.sowLists.value);
  }

  public doSetAvaiableAmount(): void {
    if (this.isBlanketOrder) {
      this.availableAmountSow = this.sowLists?.length > 0 ? 0 : this.amount;
    } else {
      this.availableAmountSow = this.sowLists.value[this.sowLists.length - 1]
        ? this.sowLists.value[this.sowLists.length - 1].sowPaymentTermList[0]
            ?.remainingAmount
        : +this.amount;
    }
  }

  public doAdd(): void {
    const todo = 'add';
    this.doSetAvaiableAmount();
    const availableAmountSow = this.availableAmountSow;
    const amount = +this.amount;
    const prItemReleasedList = this.prItemReleasedList;
    let sowPaymentTermLists;
    if (this.sowLists.value.length > 0) {
      sowPaymentTermLists =
        this.sowLists.value[this.sowLists.length - 1].sowPaymentTermList[0];
    }
    if (this.isEdit) {
      this.totalPercentage = 0;
    }

    const sowList = this.sowLists.value;
    this.appPopupSowService
      .openPo(
        todo,
        availableAmountSow,
        amount,
        prItemReleasedList,
        sowPaymentTermLists,
        sowList,
        null,
        this.isBlanketOrder,
        this.isPo
      )
      .subscribe(sow => {
        const sowPaymentTermList = [];
        sowPaymentTermList.push(sow.sowPaymentTermList);
        const formGroup = this.formBuilder.group({
          id: sow.id,
          stageOfWork: sow.stageOfWork,
          date: {
            from: sow.startDate,
            to: sow.endDate
          },
          startDate: sow.startDate,
          endDate: sow.endDate,
          totalTermin: sow.sowPaymentTermList?.length,
          amount: sow.amount,
          sowPaymentType: sow.sowPaymentType,
          sowPaymentTermList
        });
        this.sowLists.push(formGroup);
        this.tableResponse.setRecordList(this.sowLists.value);
        this.tableResponse.reload();
        if (this.isBlanketOrder) {
          this.availableAmountSow = 0;
        } else {
          this.availableAmountSow = this.sowLists.value[
            this.sowLists.length - 1
          ]
            ? this.sowLists.value[this.sowLists.length - 1]
                .sowPaymentTermList[0]?.remainingAmount
            : +this.amount;
        }
        sow.sowPaymentTermList.forEach((sowPaymentTerm) => {
          this.totalPercentage += +sowPaymentTerm.percentage;
        });
        this.totalPercentage = +this.totalPercentage.toFixed(2);
        this.isEdit = false;
        this.onChange.emit(this.sowLists.value);
        this.doSetAvaiableAmount();
      });
  }

  public doClick(sowEdit): void {
    let todo = '';
    if (this.isView) {
      todo = 'view';
    } else {
      todo = 'edit';
    }
    this.doSetAvaiableAmount();

    const remainingAmount = this.availableAmountSow;
    const availableAmountSow = remainingAmount + +sowEdit.amount;
    const amount = +this.amount;
    const prItemReleasedList = this.prItemReleasedList;
    let sowPaymentTermLists;
    if (this.sowLists.length > 0) {
      sowPaymentTermLists =
        this.sowLists.value[this.sowLists.length - 1].sowPaymentTermList[0];
    }
    if (this.isEdit) {
      this.totalPercentage = 0;
    }
    const sowList = this.sowLists.value;
    this.appPopupSowService
      .openPo(
        todo,
        availableAmountSow,
        amount,
        prItemReleasedList,
        sowPaymentTermLists,
        sowList,
        sowEdit,
        this.isBlanketOrder,
        this.isPo
      )
      .subscribe(sowTemp => {
        this.sowLists.value.forEach((sow, index) => {
          if (JSON.stringify(sow) === JSON.stringify(sowEdit)) {
            this.sowLists.value[index] = sowTemp;
            this.sowLists.controls[index].patchValue(sowTemp);
            sowTemp.sowPaymentTermList.forEach((sowPaymentTerm) => {
              this.totalPercentage += +sowPaymentTerm.percentage;
            });
          }
        });
        this.totalPercentage = +this.totalPercentage.toFixed(2);
        this.isEdit = false;
        this.tableResponse.setRecordList(this.sowLists.value);
        this.tableResponse.reload();
        this.onChange.emit(this.sowLists.value);
        this.doSetAvaiableAmount();
      });
  }

  public doDelete(event: SelectedTableRecordModel): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          let deletedAmount = 0;
          event.selectedRecordList.forEach((record: Sow) => {
            const indexOfRecord = this.sowLists.controls.findIndex(
              r => r.value.stageOfWork.id === record.stageOfWork.id
            );
            if (indexOfRecord !== -1) {
              const sowPaymentTermList = this.sowLists.controls[
                indexOfRecord
              ].get('sowPaymentTermList') as FormArray;
              sowPaymentTermList.value.forEach(sowPaymentTerm => {
                deletedAmount = +deletedAmount + +sowPaymentTerm.amount;
              });
              this.sowLists.value.splice(indexOfRecord, 1);
              this.sowLists.removeAt(indexOfRecord);
              const sowPaymentTermServiceItemList =
                sowPaymentTermList.value[0].sowPaymentTermServiceItemList;
              const sowPaymentTermGoodsItemList =
                sowPaymentTermList.value[0].sowPaymentTermGoodsItemList;
              if (this.sowLists.value.length > 0) {
                if (
                  sowPaymentTermServiceItemList &&
                  sowPaymentTermGoodsItemList
                ) {
                  sowPaymentTermList.value.forEach(paymentTerm => {
                    paymentTerm.sowPaymentTermServiceItemList.forEach(
                      itemService => {
                        this.sowLists.controls.forEach(sow => {
                          sow.value.sowPaymentTermList.forEach(payment => {
                            payment.sowPaymentTermServiceItemList.forEach(
                              (sowService, j) => {
                                if (
                                  itemService.prItemReleased.id ===
                                  sowService.prItemReleased.id
                                ) {
                                  payment.sowPaymentTermServiceItemList[
                                    j
                                  ].remainingQuantity =
                                    +sowService.remainingQuantity +
                                    +itemService.quantity;
                                }
                              }
                            );
                          });
                        });
                      }
                    );
                    paymentTerm.sowPaymentTermGoodsItemList.forEach(
                      itemProduct => {
                        this.sowLists.controls.forEach(sow => {
                          sow.value.sowPaymentTermList.forEach(payment => {
                            payment.sowPaymentTermGoodsItemList.forEach(
                              (sowGoods, j) => {
                                if (
                                  itemProduct.prItemReleased.id ===
                                  sowGoods.prItemReleased.id
                                ) {
                                  payment.sowPaymentTermGoodsItemList[
                                    j
                                  ].remainingQuantity =
                                    +sowGoods.remainingQuantity +
                                    +itemProduct.quantity;
                                }
                              }
                            );
                          });
                        });
                      }
                    );
                  });

                  this.sowLists.controls.forEach(sow => {
                    sow.value.sowPaymentTermList.forEach(payment => {
                      payment.sowPaymentTermItemList = [];
                      payment.sowPaymentTermGoodsItemList.forEach(
                        itemProduct => {
                          payment.sowPaymentTermItemList.push(itemProduct);
                        }
                      );
                      payment.sowPaymentTermServiceItemList.forEach(
                        itemService => {
                          payment.sowPaymentTermItemList.push(itemService);
                        }
                      );
                    });
                  });
                }
              }
            }
          });
          if (this.sowList) {
            this.sowList.forEach(sow => {
              sow.sowPaymentTermList?.forEach(sowPaymentTerm => {
                sowPaymentTerm.remainingAmount =
                  +deletedAmount + +sowPaymentTerm.remainingAmount;
              });
            });
          }
          this.tableResponse.setRecordList(this.sowLists.value);
          this.tableResponse.reload();
          this.onChange.emit(this.sowLists.value);
          this.doSetAvaiableAmount();
        }
      });
  }
}
