import { Component, ElementRef, Input, Optional } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
import { TextUtils } from '../../utils';
import { AppTimeConstant } from './app-time-contant';
@Component({
  selector: 'app-time',
  templateUrl: './app-time.component.html',
  styleUrls: ['./app-time.component.scss'],
  providers: makeProvider(AppTimeComponent)
})
export class AppTimeComponent extends BaseValueAccessorComponent<Date> {
  public hourValue = '';
  public minuteValue = '';
  public appTimeConsntant: AppTimeConstant;
  public generatedId: string;
  @Input() public isShowArrow : boolean = true;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super('app-time', controlContainer, elementRef);
  }

  public onInitBaseValueAccessor(): void {
    this.setGeneratedIdState();
    this.setAppTimeConstantState();
    this.setHourAndMinuteValue();
  }

  private setGeneratedIdState(): void {
    this.generatedId = TextUtils.generateRandomString();
  }

  private setAppTimeConstantState(): void {
    this.appTimeConsntant = new AppTimeConstant();
  }

  public setHourAndMinuteValue(): void {
    this.buildAndSetElementValue(this.formControl.value);
    this.formControl.valueChanges.subscribe(value => {
      if (value) {
        this.buildAndSetElementValue(value);
      } else {
        this.minuteValue = '';
        this.hourValue = '';
      }
    });
  }

  public buildAndSetElementValue(value: any): void {
    if (value) {
      const date = new Date(value);
      this.hourValue = this.setElementValue(date.getHours());
      this.minuteValue = this.setElementValue(date.getMinutes());
    }
  }

  public doIncrement(type: number): void {
    if (type === this.appTimeConsntant.TYPE_HOUR) {
      this.hourValue = this.incrementValue(this.hourValue, 24);
    } else {
      this.minuteValue = this.incrementValue(this.minuteValue, 60);
    }
    this.doPatchValue();
  }

  public doDecrement(type: number): void {
    if (type === this.appTimeConsntant.TYPE_HOUR) {
      this.hourValue = this.decrementValue(this.hourValue, 24);
    } else {
      this.minuteValue = this.decrementValue(this.minuteValue, 60);
    }
    this.doPatchValue();
  }

  public incrementValue(value, maxValue): string {
    let newValue = +value + 1;
    newValue = newValue === maxValue ? 0 : newValue;
    return this.setElementValue(newValue);
  }

  public decrementValue(value, maxValue): string {
    let newValue = +value - 1;
    newValue = newValue < 0 ? maxValue - 1 : newValue;
    return this.setElementValue(newValue);
  }

  public setElementValue(value: number): string {
    return (value < 10 ? '0' + value : value).toString();
  }

  public handleInput(event: any, type: number): void {
    if (isNaN(event.data)) {
      const indexOfData = event.target.value.indexOf(event.data);
      const value = event.target.value.split('');
      value.splice(indexOfData, 1, '');
      event.target.value = value.join('');
    } else {
      if (event.inputType === 'insertText') {
        const value = event.target.value;
        this.setHourOrMinuteValue(value, type);
      }
    }
  }

  public handlePaste(event: any, type: number): void {
    event.preventDefault();
    event.stopPropagation();
    const clipboardData = event.clipboardData;
    const pasteText = clipboardData.getData('text');
    if (!isNaN(+pasteText) && pasteText.length <= 2) {
      this.setHourOrMinuteValue(pasteText, type);
      return;
    }

    if (new Date(pasteText).toString() !== 'Invalid Date') {
      const date = new Date(pasteText);
      this.setHourOrMinuteValue(
        date.getHours() === 0 ? '00' : date.getHours(),
        this.appTimeConsntant.TYPE_HOUR
      );
      this.setHourOrMinuteValue(
        date.getMinutes() === 0 ? '00' : date.getMinutes(),
        this.appTimeConsntant.TYPE_MINUTE
      );
    }
  }

  public handleFocusOut(event: any): void {
    if (event.target.value !== '') {
      const value = +event.target.value;
      event.target.value = this.setElementValue(value);
    }
    this.doPatchValue();
  }

  public handleKeyUp(event: any, type: number): void {
    event.preventDefault();
    event.stopPropagation();
    if (event.key === 'ArrowDown') {
      this.doDecrement(type);
    } else if (event.key === 'ArrowUp') {
      this.doIncrement(type);
    }
  }

  public doPatchValue(): void {
    if (this.hourValue && this.minuteValue) {
      const date = new Date();
      date.setHours(+this.hourValue);
      date.setMinutes(+this.minuteValue);
      this.formControl.patchValue(date);
      this.onChange.emit(this.formControl.value);
    }
    if (!this.formControl.touched) {
      this.formControl.markAsTouched();
    }
  }

  public setHourOrMinuteValue(value: any, type: number): void {
    const newValue =
      value[0] === '0'
        ? value
        : type === this.appTimeConsntant.TYPE_HOUR
        ? +value > 23
          ? 23
          : +value
        : +value > 59
        ? 59
        : +value;
    type === this.appTimeConsntant.TYPE_HOUR
      ? (this.hourValue = newValue)
      : (this.minuteValue = newValue);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
