import { BaseEntity } from '../base/base-entity';
import { ModuleGroup } from '../bean/module-group';
import { Reminder } from '../bean/reminder';
export class NotificationEntity extends BaseEntity {
    isRepeatReminder: boolean;
    isActiveReminder: boolean;
    moduleGroup: ModuleGroup;
    reminder: Reminder;
    name: string;
    description: string;
    keyword: string;
    isActive: boolean;
}

