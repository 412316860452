<app-layout
  [isLoading]="formLoading"
  header="administration-evaluation.breadcrumb.index"
  [backTo]="urlBackOutside"
>
  <ng-template #additionalTitle *ngIf="isShowBadgeStatus">
    <app-badge-catalog
      class="cml-2"
      *ngIf="!formLoading && procurement?.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge class="cml-2" *ngIf="todo === 'add'" color="INFO">
      {{ 'administration-evaluation.label.new' | translate }}
    </app-badge>
    <app-badge
      *ngIf="todo !== 'add' && !formLoading"
      class="cml-2"
      [color]="badgeColor[procurement.procurementWorklistStatus.code]"
    >
      {{ getTranslateKey(procurement.procurementWorklistStatus) | translate }}
    </app-badge>
  </ng-template>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          (procurement?.procurementStatus?.code ===
            global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm
                .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT)
        "
      >
        {{ canceledOrFailedMessage | translate }}
      </app-alert-x>

      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="administrationEvaluationResponse.workflowStepInfoModel"
        >
        </app-workflow-step-info>
        <app-card
          header="administration-evaluation.detail.general.title"
          tips="administration-evaluation.detail.general.tips"
        >
          <app-procurement-info [procurementId]="procurementId">
          </app-procurement-info>
        </app-card>

        <app-item-request
          *ngIf="!formLoading"
          [objectList]="administrationEvaluationResponse.procurementItemList"
          [isShowPrice]="true"
          [isView]="true"
          [itemTypeList]="administrationEvaluationResponse.itemTypeList"
          (onChange)="onChangeItemRequest($event)"
        ></app-item-request>

        <app-card>
          <app-alert-x color="WARNING" *ngIf="isHasWaitingScore">{{
            'administration-evaluation.detail.administrationEvaluation.alertInfo'
              | translate
          }}</app-alert-x>
          <app-table-xx
            header="administration-evaluation.detail.administrationEvaluation.title"
            tips="administration-evaluation.detail.administrationEvaluation.tips"
            [model]="tableResponse"
            (onClick)="onClickVendor($event)"
            [isShowCheckBox]="false"
          >
          </app-table-xx>
        </app-card>
        <app-card
          header="administration-evaluation.detail.officialReport.title"
          tips="administration-evaluation.detail.officialReport.tips"
        >
          <app-alert-x
            color="WARNING"
            *ngIf="isOfficialReportNull && !isHasWaitingScore"
            >{{
              'administration-evaluation.detail.officialReport.alertInfo'
                | translate
            }}</app-alert-x
          >
          <app-official-report
            [objectId]="procurementId"
            [templateCode]="
              global.appConstant.pm
                .OFFICIAL_REPORT_CODE_ORT_EVALUATION_ADMINISTRATION_LETTER
            "
            [appOfficialReportModel]="appOfficialReportModel"
            [mode]="todo"
            numberGeneratorCode="NUMBER"
            (onChange)="doOnChange($event)"
            [isShowButtonGenerate]="todo !== 'view'"
          ></app-official-report>
        </app-card>

        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            administrationEvaluationResponse.approvalPathResponseModel &&
            !administrationEvaluationResponse.isUserApproval &&
            (procurement.workflowModelPrcs.code !==
              'ADMINISTRATION-EVALUATION' ||
              (procurement.workflowModelPrcs.code ===
                'ADMINISTRATION-EVALUATION' &&
                procurement.workflowPrcs?.workflowStatus?.code ===
                  global.appConstant.core.WORKFLOW_CODE_STATUS_REVISION))
          "
          [workflowPrcs]="procurement.workflowPrcs"
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="
            administrationEvaluationResponse?.approvalPathResponseModel
          "
        ></app-approval-monitoring>
        <div class="floating-button mt-5" *ngIf="!formGroup.isView">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div
                class="button-group button-group-center"
                *ngIf="!formGroup.isView"
              >
                <app-button (onClick)="doSubmit(true)">{{
                  'app.button.submit' | translate
                }}</app-button>
              </div>
            </div>
          </div>
        </div>
        <app-approval-prcs-x
          *ngIf="
            !formLoading && administrationEvaluationResponse.isUserApproval
          "
          [workflowModelPrcs]="procurement.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips *ngIf="!formLoading"></app-tips>
  </ng-template>
</app-layout>
