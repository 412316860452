<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="country-flag.title" detail="app.action.{{ todo }}" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'country-flag.form.code' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            {{ formGroup.controls.code.value }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'country-flag.form.name' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            {{ formGroup.controls.name.value }}
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'country-flag.form.flagIcon' | translate
            }}</label>
          <div class="col-sm-4 text-left">
            <span *ngIf="formGroup.get('resource').value != null">
              <img [ngStyle]="{ 'max-width': '36px' }" class="mr-2" [attr.src]="
                  global.config.BACKEND_ADDRESS +
                    '/resource/file/view/' +
                    formGroup.get('resource').value.imgFile.uploadedFileName +
                    '/' + global.appConstant.fileType.IMG_ICON
                    | resolveImgSrc
                    | async
                " [attr.alt]="fileName" width="auto" height="auto" />
            </span>
            <app-button size="SM" (onClick)="doChooseFlagIcon()" [disabled]="formSaving">
              {{ 'app.button.choose' | translate }}
            </app-button>
            <div>
              <small class="text-danger" *ngIf="
                  formGroupControls.resource.touched &&
                  formGroupControls.resource.errors
                ">{{ 'app.validation.required' | translate }}</small>
            </div>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button color="SECONDARY" (onClick)="doCancel()" [disabled]="formSaving">{{ 'app.button.back' | translate
            }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">{{
            'app.button.save' | translate
            }}</app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>