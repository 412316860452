import { ChartOptions } from 'chart.js';

export const lineChartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    position: 'bottom',
    display: false,
    labels: {
      usePointStyle: true
    }
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          display: false
        }
      }
    ]
  }
};
