<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table-x
        [model]="tableResponse"
        stringUrl="/accident-victim/index"
        (onClick)="doClick($event)"
        header="{{ 'claim.table.header' | translate }}"
      >
        <ng-template #headerFilterGroup>
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
            *ngIf="!formLoading"
          >
          <div class="form-group row text-sm-left ml-1" style="margin-bottom: 0.5rem" >
            <div class="col-3 text-right">
              <app-date-picker-x
                size="XL"
                formControlName="date"
                [range]="true"
                (onChange)="onChangeFilter()"
                [placeholder]="'claim.table.column.glDate' | translate"
              >
              </app-date-picker-x>
            </div>
                <div class="col-3">
                  <app-dropdown-select
                    size="XL"
                    formControlName="verificatorList"
                    placeholder="{{
                      'claim.placeholder.verificator' | translate
                    }}"
                    [optionList]="verificatorOptionList"
                    (onChange)="onChangeFilter()"
                    type="CHOSEN"
                    showLimitValue="1"
                  >
                    <ng-template #selectAll>
                      <p>
                        {{ 'claim.placeholder.allVerificator' | translate }}
                      </p>
                    </ng-template></app-dropdown-select
                  >
                </div>
                <!--<div class="col-3">
                    <app-dropdown-select
                      size="XL"
                      formControlName="areaList"
                      placeholder="{{ 'claim.placeholder.area' | translate }}"
                      [optionList]="areaOptionList"
                      (onChange)="onChangeFilter()"
                      type="CHOSEN"
                      showLimitValue="1"
                    >
                      <ng-template #selectAll>
                        <p> {{ 'claim.placeholder.allArea' | translate }} </p>
                      </ng-template></app-dropdown-select
                    >
                  </div>-->
                <!-- <div class="col-3">
                  <app-dropdown-select size="XL" formControlName="executorList"
                    placeholder="{{ 'claim.placeholder.executor' | translate }}" [optionList]="executorOptionList"
                    (onChange)="onChangeFilter()" type="CHOSEN" showLimitValue="1">
                    <ng-template #selectAll>
                      <p> {{ 'claim.placeholder.allExecutor' | translate }} </p>
                    </ng-template></app-dropdown-select>
                </div> -->
                <div class="col-3">
                  <app-dropdown-select
                    size="XL"
                    *ngIf="!formLoading"
                    formControlName="hospitalNameList"
                    (onChange)="onChangeFilter()"
                    placeholder="{{
                      'claim.placeholder.hospitalName' | translate
                    }}"
                    [optionList]="hospitalNameOptionList"
                    type="CHOSEN"
                    showLimitValue="1"
                  >
                    <ng-template #selectAll>
                      <p>
                        {{ 'claim.placeholder.allHospitalName' | translate }}
                      </p>
                    </ng-template></app-dropdown-select
                  >
                </div>
                <div class="col-3"> </div>
              </div>

              <div class="form-group row text-sm-left ml-1" style="margin-bottom: 0.5rem" >
                <div class="col-3 text-right">
                  <app-dropdown-select
                    size="XL"
                    formControlName="claimStatusList"
                    placeholder="{{ 'claim.placeholder.status' | translate }}"
                    [optionList]="claimStatusOptionList"
                    (onChange)="onChangeFilter()"
                    type="CHOSEN"
                    showLimitValue="1"
                  >
                    <ng-template #selectAll>
                      <p> {{ 'claim.placeholder.allStatus' | translate }} </p>
                    </ng-template></app-dropdown-select
                  >
                </div>
                <div class="col-3 text-right">
                  <app-dropdown-select
                    size="XL"
                    formControlName="counterList"
                    placeholder="{{ 'claim.placeholder.counter' | translate }}"
                    [optionList]="counterOptionList"
                    (onChange)="onChangeFilter()"
                    type="CHOSEN"
                    showLimitValue="1"
                  >
                    <ng-template #selectAll>
                      <p> {{ 'claim.placeholder.allCounter' | translate }} </p>
                    </ng-template></app-dropdown-select
                  >
                </div>
                <div class="col-3"></div>
                <div class="col-3"></div>
              </div>
          </form>
        </ng-template>

        <ng-template #headerButtons>
          <app-button
            (onClick)="doPrintReport()"
            mode="BYPASS"
            title="{{ 'app.button.print' | translate }}"
          >
            <em class="fas fa-print"></em>&nbsp;
            {{ 'app.button.printReport' | translate }}
          </app-button>
        </ng-template>
      </app-table-x>
    </div>
  </div>
</app-layout>
