<div class="text-center py-4" *ngIf="formLoading">
  <img src="assets/img/common/loader-small.gif" alt="Loading" />
</div>
<form class="form-horizontal" *ngIf="!formLoading">
  <div class="row">
    <div class="col-12">
      <h5 class="separator">{{
        'app-contract-request-view.separator.stageOfWork' | translate
      }}</h5>
      <app-table
        #selectorStageOfWork
        [model]="tableResponseStageOfWork"
        *ngIf="!formLoading"
      >
        <ng-template #actionButtons let-rowData *ngIf="!formGroup.isView">
          <app-button
            *ngIf="router.url !== '/pages/contract-monitoring/detail'"
            size="TN"
            (onClick)="doDetailStageOfWork(rowData)"
            title="{{ 'app.tooltip.view' | translate }}"
          >
            <em class="fa fa-search"></em>
          </app-button>
          <app-button
            *ngIf="
              router.url === '/pages/contract-monitoring/detail' &&
              global.userSession.activeUserRole.role.code !==
                global.appConstant.ROLE_CODE_USER_PPA &&
              global.userSession.activeUserRole.role.code !==
                global.appConstant.ROLE_CODE_SUPER_ADMIN
            "
            size="TN"
            (onClick)="doDetailStageOfWork(rowData)"
            title="{{ 'app.tooltip.view' | translate }}"
          >
            <em class="fa fa-search"></em>
          </app-button>
          <app-button
            *ngIf="
              router.url === '/pages/contract-monitoring/detail' &&
              (global.userSession.activeUserRole.role.code ===
                global.appConstant.ROLE_CODE_USER_PPA ||
                global.userSession.activeUserRole.role.code ===
                  global.appConstant.ROLE_CODE_SUPER_ADMIN) &&
              rowData.status !== 1
            "
            size="TN"
            (onClick)="doDetailStageOfWork(rowData)"
            title="{{ 'app.tooltip.view' | translate }}"
          >
            <em class="fa fa-search"></em>
          </app-button>
          <app-button
            *ngIf="
              router.url === '/pages/contract-monitoring/detail' &&
              rowData.status === 1 &&
              (global.userSession.activeUserRole.role.code ===
                global.appConstant.ROLE_CODE_USER_PPA ||
                global.userSession.activeUserRole.role.code ===
                  global.appConstant.ROLE_CODE_SUPER_ADMIN)
            "
            size="TN"
            (onClick)="doEditStageOfWork(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}"
          >
            <em class="fa fa-pencil-alt"></em>
          </app-button>
        </ng-template>
      </app-table>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h5 class="separator">{{
        'app-contract-request-view.separator.guarantee' | translate
      }}</h5>
      <app-table #selectorGuarantee [model]="tableResponseGuarantee">
        <ng-template
          #actionButtons
          let-rowData
          *ngIf="
            router.url === '/pages/contract-monitoring/detail' &&
            (global.userSession.activeUserRole.role.code ===
              global.appConstant.ROLE_CODE_USER_PPA ||
              global.userSession.activeUserRole.role.code ===
                global.appConstant.ROLE_CODE_SUPER_ADMIN) &&
            isMaintenanceGuaranteeExist &&
            appContractRequestViewResponse.contract.contractStatus.code !==
              global.appConstant.cm.CONTRACT_STATUS_REQUEST_CLOSED_CONTRACT
          "
        >
          <app-button
            size="TN"
            (onClick)="doEditGuarantee(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}"
            *ngIf="
              procurementSowList.value[0].status === 1 ||
              (procurementSowList.value[0].status !== 1 &&
                rowData.guaranteeBondType.code ===
                  global.appConstant.cm
                    .GUARANTEE_BOND_TYPE_JAMINAN_PEMELIHARAAN)
            "
          >
            <em class="fa fa-pencil-alt"></em>
          </app-button>
        </ng-template>
      </app-table>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h5 class="separator">{{
        'app-contract-request-view.separator.fines' | translate
      }}</h5>
      <app-table #selectorFines [model]="tableResponseFines"> </app-table>
    </div>
  </div>
</form>
