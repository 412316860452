import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { NotificationBlast } from '../../core/bean/notification-blast';
import { AppTabComponent } from '../../core/components/app-tab/app-tab.component';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './notification-blast.component.html'
})
export class NotificationBlastComponent
  extends BaseModuleComponent
  implements OnInit {
  @ViewChild(AppTabComponent) table: AppTableComponent;
  public readonly urlRouterNavigateToAddNotificationBlast =
    '/pages/notification-blast/add';
  public readonly urlRouterNavigateToDetailNotificationBlast =
    '/pages/notification-blast/detail';
  public readonly urlSendEmailNotificationBlast =
    '/notification-blast/send-email';
  public tableResponse: TableResponseModel<NotificationBlast>;

  constructor(translateService: TranslateService) {
    super('notification-blast', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'subject', header: 'table.column.subject', customClass: 'white-space-normal' },
      {
        field: 'content',
        header: 'table.column.content',
        customClass: 'white-space-normal',
        format: FieldFormatEnum.Html
      },
      {
        field: 'createdDate',
        header: 'table.column.createdDate',
        format: FieldFormatEnum.ShortDate
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate([this.urlRouterNavigateToAddNotificationBlast]);
  }

  public doDetail(notificationBlast: NotificationBlast): void {
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('notificationBlastId', notificationBlast.id);
    this.router.navigate([this.urlRouterNavigateToDetailNotificationBlast]);
  }

  public doReSend(notificationBlast: NotificationBlast): void {
    this.global.modalService
        .confirm(this.translateService.instant('notification-blast.confirmation.msg.send'))
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.sendEmailNotificationBlastToServer(
              this.urlSendEmailNotificationBlast,
              notificationBlast
            ).subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccess(
                  this.translateService.instant(
                    'notification-blast.alert.msg.successSendEmail'
                  )
                );
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
          }
        });
  }

  public sendEmailNotificationBlastToServer(
    urlSendEmailNotificationBlast: string,
    notificationBlast: NotificationBlast
  ): Observable<Response<NotificationBlast>> {
    return this.httpClientService.post<Response<NotificationBlast>>(
      urlSendEmailNotificationBlast,
      notificationBlast
    );
  }
}
