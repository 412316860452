import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
@Component({
  templateUrl: './app-popup-preview-image.component.html',
  styleUrls: ['./app-popup-preview-image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupPreviewImageComponent extends BaseComponentComponent {
  @Input() imageUrl: string;
  constructor(public ngbActiveModal: NgbActiveModal) {
    super('app-popup-preview-image');
  }

  onInit(): void {
    this.setStateLoading();
  }

  public onKeyUp(event: KeyboardEvent): void {
    this.log.debug(event);
  }

  public onLoad(): void {
    this.setStateReady();
  }

  public onError(event): void {
    event.target.src = 'assets/img/app/no-image.png';
  }
}
