import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Content } from '../../core/bean/content';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';

@Component({
  templateUrl: './procurement-content-management.component.html'
})
export class ProcurementContentManagementComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Content>;

  constructor(translateService: TranslateService) {
    super('procurement-content-management', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.name',
        plugins: { name: 'hyperlink', onClick: this.doEdit.bind(this) }
      }
    ]);
  }

  public doEdit(content: Content): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.router.navigate([
      '/pages/procurement-content-management/' + content.code.toLowerCase()
    ]);
  }
}
