<dashboard-widget-accordion-list
  header="RFX"
  subHeader="{{
    'dashboard-widget-rfx-total-transaction.totalRequest' | translate
  }}"
  size="LG"
  vector="pvc pv-note"
  [total]="totalRFX"
  [model]="rfxDataList"
  nameField="translationKey"
  totalField="value"
  [isShowViewDetails]="false"
  (onClick)="doNavigateToIndex($event)"
>
</dashboard-widget-accordion-list>
