import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupBudgetComponent } from './app-popup-budget.component';
import { AppPopupBudgetService } from './app-popup-budget.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppTableModule, AppButtonModule],
  declarations: [AppPopupBudgetComponent],
  entryComponents: [AppPopupBudgetComponent],
  providers: [AppPopupBudgetService],
  exports: [AppPopupBudgetComponent]
})
export class AppPopupBudgetModule {}
