import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Association } from '../../core/bean/association';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { AssociationResponse } from './association-response';
import { AssociationRequest } from './association-request';

@Component({
  templateUrl: './association-edit-add.component.html'
})
export class AssociationEditAddComponent extends BaseModuleComponent {
  public associationId: number;
  constructor(translateService: TranslateService) {
    super('association', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.associationId = this.global.routerParams.get('associationId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null, Validators.required()],
      name: [null, Validators.required()],
      listSegmentation: [null, Validators.required()],
      description: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<AssociationResponse>(
        '/association/add-edit',
        new RouteRequestModel(this.todo, this.associationId)
      )
      .subscribe(associationResponse => {
        if (associationResponse.association != null) {
          const { id, code, name, description } = associationResponse.association;
          this.formGroup.patchValue({
            id,
            code,
            name,
            description,
            listSegmentation: associationResponse.listSegmentation
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const association = this.global.copyFormAttributeToModel(
              new Association(),
              this.formGroup
            );
            const associationRequest: AssociationRequest = new AssociationRequest();
            associationRequest.association = association;
            associationRequest.listSegmentation = this.formGroup.get(
              'listSegmentation'
            ).value;
            const url =
              this.todo === 'edit'
                ? '/association/update'
                : '/association/insert';
            this.httpClientService
              .post<Response<AssociationRequest>>(url, associationRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/association/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/association/']);
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
}
