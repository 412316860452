import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { Currency } from './../../core/bean/currency';
@Component({
  templateUrl: './currency-edit-add.component.html'
})
export class CurrencyEditAddComponent extends BaseModuleComponent {
  public currencyId: number;
  constructor(translateService: TranslateService) {
    super('currency', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.currencyId = this.global.routerParams.get('currencyId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      isDefault: [false],
      exchangeRateInIdr: [null, Validators.required()]
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<Currency>(
        '/currency/add-edit',
        new RouteRequestModel(this.todo, this.currencyId)
      )
      .subscribe(currency => {
        if (currency != null) {
          this.formGroup.patchValue(currency);
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const currency = this.global.copyFormAttributeToModel(
              new Currency(),
              this.formGroup
            );
            const url =
              this.todo === 'edit' ? '/currency/update' : '/currency/insert';
            this.httpClientService
              .post<Response<Currency>>(url, currency)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/currency']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/currency']);
  }
}
