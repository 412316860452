import { NgModule } from '@angular/core';
import { AppButtonModule } from '../../../core/components/app-button/app-button.module';
import { AppPopupModule } from '../../../core/components/app-popup/app-popup.module';
import { AppTextFieldModule } from '../../../core/components/app-text-field/app-text-field.module';
import { VendorModule } from '../../../core/vendor/vendor.module';
import { AppPopupChangeEmailUserComponent } from '../app-popup-change-email-user/app-popup-change-email-user.component';
@NgModule({
    imports: [VendorModule, AppPopupModule, AppTextFieldModule, AppButtonModule],
    declarations: [AppPopupChangeEmailUserComponent],
    entryComponents: [AppPopupChangeEmailUserComponent],
    exports: [AppPopupChangeEmailUserComponent]
})
export class AppPopupChangeEmailUserModule { }
