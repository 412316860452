import { BaseEntity } from '../base/base-entity';
import { PrItem } from '../bean/pr-item';
import { RfpItem } from '../bean/rfp-item';
import { Rfq } from '../bean/rfq';

export class RfqItemEntity extends BaseEntity {
  crudOperation: number;
  rfq: Rfq = new Rfq();
  prItem: PrItem = new PrItem();
  rfpItem: RfpItem = new RfpItem();
}
