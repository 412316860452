import { DatePickerState } from '../model';
import { createDatePickerDate } from './create-date-picker-date-helper';
export const createMinOrMaxDate = (
  state: DatePickerState,
  action: 'MIN' | 'MAX'
): void => {
  if (action === 'MIN') {
    state.model.minDate &&
      createMinOrMaxDateValue(state, state.model.minDate, action);
  } else {
    state.model.maxDate &&
      createMinOrMaxDateValue(state, state.model.maxDate, action);
  }
};

const createMinOrMaxDateValue = (
  state: DatePickerState,
  date: Date | string,
  action: 'MIN' | 'MAX'
): void => {
  const dateValue =
    date instanceof Date
      ? date
      : state.model.formControl.parent.get(date).value;
  if (typeof date === 'string') {
    state.model.formControl.parent
      .get(date)
      .valueChanges.subscribe(dateValue =>
        setMinOrMaxDateValueToState(state, dateValue, action)
      );
  }
  setMinOrMaxDateValueToState(state, dateValue, action);
};

const setMinOrMaxDateValueToState = (
  state: DatePickerState,
  date: Date,
  action: 'MIN' | 'MAX'
): void => {
  if (action === 'MIN') {
    if (date) {
      const minDate = new Date(date);
      minDate.setHours(0, 0, 0, 0);
      state.minDate = createDatePickerDate(minDate);
    } else {
      state.minDate = null;
    }
  } else {
    if (date) {
      const maxDate = new Date(date);
      maxDate.setHours(23, 59, 59, 0);
      state.maxDate = createDatePickerDate(maxDate);
    } else {
      state.maxDate = null;
    }
  }
};
