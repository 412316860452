import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Pr } from 'src/app/core/bean/pr';
import { PrCancellation } from 'src/app/core/bean/pr-cancellation';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './app-popup-pr-cancellation.component.html'
})
export class AppPopupPrCancellationComponent extends BaseComponentComponent {
  @Input() public pr: Pr;
  @Output() public onChange: EventEmitter<PrCancellation> = new EventEmitter();

  constructor(public translateService: TranslateService) {
    super('pr');
  }

  onInit(): void {
    this.buildFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      note: [null, Validators.required()],
      cancelByUser: [this.global.userSession.user],
      cancelDate: [new Date()]
    });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup.value);
    }
  }
}
