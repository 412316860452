import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { WebSocketService } from 'src/app/core/services/websocket/websocket.service';
import { WebSocketResponseModel } from './../../core/model/websocket-response-model';
import { AppPopupSyncWorkProgramComponent } from './app-popup-sync-work-program.component';
import { WorkProgramComponent } from './work-program.component';

@Injectable()
export class WebSocketWorkProgramService extends WebSocketService<string> {
  public router: Router;
  public appPopupSyncWorkProgramComponent: AppPopupSyncWorkProgramComponent;
  public onCloseModal: EventEmitter<boolean> = new EventEmitter();
  public message: EventEmitter<string> = new EventEmitter();
  public workProgram: WorkProgramComponent;

  constructor(public appPopupService: AppPopupService) {
    super('synchronize/work-program');
  }

  public onReceivedMessage(message: string): Promise<any> {
    return new Promise(resolve => {
      const webSocketResponseModel: WebSocketResponseModel =
        JSON.parse(message);
      if (this.appPopupSyncWorkProgramComponent) {
        if (
          webSocketResponseModel &&
          webSocketResponseModel.status !==
            this.global.appConstant.core.WEBSOCKET_STATUS_ON_PROGRESS
        ) {
          const result =
            webSocketResponseModel.status ===
            this.global.appConstant.core.WEBSOCKET_STATUS_FINISH
              ? true
              : false;
          setTimeout(() => {
            this.appPopupSyncWorkProgramComponent.isLoadingSync = false;
            this.appPopupSyncWorkProgramComponent = null;
            this.onCloseModal.emit(true);
            resolve(result);
          }, 500);
        } else {
          this.appPopupSyncWorkProgramComponent.isLoadingSync = true;
          this.appPopupSyncWorkProgramComponent.webSocketResponseModel =
            webSocketResponseModel;
        }
      } else {
        let bodyMessage;
        if (
          webSocketResponseModel &&
          webSocketResponseModel.status ===
            this.global.appConstant.core.WEBSOCKET_STATUS_ON_PROGRESS
        ) {
          bodyMessage =
            webSocketResponseModel.progressValue +
            ' ' +
            webSocketResponseModel.progressUnit;
          this.message.emit(bodyMessage);
        } else {
          const result =
            webSocketResponseModel.status ===
            this.global.appConstant.core.WEBSOCKET_STATUS_FINISH
              ? true
              : false;
          setTimeout(() => {
            this.message.emit(null);
            resolve(result);
          }, 500);
        }
      }
    });
  }
}
