import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { SchedulerEditAddComponent } from './scheduler-edit-add.component';
import { SchedulerComponent } from './scheduler.component';
const routes: Routes = [
  { path: '', component: SchedulerComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: SchedulerEditAddComponent,
    data: { breadcrumb: 'scheduler.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: SchedulerEditAddComponent,
    data: { breadcrumb: 'scheduler.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [SchedulerComponent, SchedulerEditAddComponent]
})
export class SchedulerModule {}
