<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="currency.title" detail="app.action.{{todo}}"
            [isLoading]="formLoading" [isSaving]="formSaving">
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>
                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        'currency.form.code' | translate}}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-field formControlName="code" maxLength="32"
                            type="code" autofocus="true"></app-text-field>
                    </div>
                </div>
                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        'currency.form.name' | translate }}
                    </label>
                    <div class="col-sm-9 text-left">
                        <app-text-field formControlName="name"
                            type="alphanumeric" maxLength="32"></app-text-field>
                    </div>
                </div>
                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        'currency.form.exchangeRateInIdr' | translate }}
                    </label>
                    <div class="col-sm-9 text-left">
                        <app-text-field formControlName="exchangeRateInIdr"
                            type="currency" maxLength="20"></app-text-field>
                    </div>
                </div>
                <div class="form-group row text-sm-right">
                    <label class="col-sm-3 control-label"></label>
                    <div class="col-sm-9 text-left">
                        <app-check-box formControlName="isDefault">
                            {{ 'currency.form.isDefault' | translate }}
                        </app-check-box>
                    </div>
                </div>
                <div class="button-group button-group-center mt-5">
                    <app-button color="SECONDARY" (onClick)="doCancel()"
                        [disabled]="formSaving">
                        {{ 'app.button.back' | translate }}
                    </app-button>
                    <app-button (onClick)="doSave()" [disabled]="formSaving">
                        {{ 'app.button.save' | translate }}
                    </app-button>
                </div>
            </form>
        </app-card>
    </div>
</div>