import { Component, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { WebContent } from '../../core/bean/web-content';

@Component({
  templateUrl: './app-popup-refutation-vendor-toc.component.html',
  styleUrls: ['./app-popup-refutation-vendor-toc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupRefutationVendorTocComponent extends BaseComponentComponent {
  public toc: WebContent;
  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    super('refutation-vendor');
  }

  public onInit(): void {
    this.getRefutationTOC();
  }

  public getRefutationTOC(): void {
    this.httpClientService
      .get<WebContent>('/refutation-vendor/get-toc')
      .subscribe(toc => {
        this.toc = toc;
        this.setStateReady();
      });
  }
}
