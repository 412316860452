import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppContractDraftViewModule } from 'src/app/core/components/app-contract-draft-view/app-contract-draft-view.module';
import { AppContractNegotiationViewModule } from 'src/app/core/components/app-contract-negotiation-view/app-contract-negotiation-view.module';
import { AppContractRequestInfoModule } from 'src/app/core/components/app-contract-request-info/app-contract-request-info.module';
import { AppContractRequestViewModule } from 'src/app/core/components/app-contract-request-view/app-contract-request-view.module';
import { AppVendorInformationModule } from 'src/app/core/components/app-vendor-information/app-vendor-information.module';
import { AppVendorPaymentInformationModule } from 'src/app/core/components/app-vendor-payment-information/app-vendor-payment-information.module';
import { AppWorkDefinitionModule } from 'src/app/core/components/app-work-definition/app-work-definition.module';
import { AppWorkflowStepInfoModule } from 'src/app/core/components/app-workflow-step-info/app-workflow-step-info.module';
import { AppUploadXModule } from 'src/app/core/components/upload/components/app-upload-x/app-upload-x.module';
import { AppApprovalPathModule } from '../../core/components/app-approval-path/app-approval-path.module';
import { AppApprovalPrcsModule } from '../../core/components/app-approval-prcs/app-approval-prcs.module';
import { AppProcurementInformationViewModule } from '../../core/components/app-procurement-information-view/app-procurement-information-view.module';
import { AppWorkflowProgressInfoModule } from '../../core/components/app-workflow-progress-info/app-workflow-progress-info.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { WorklistCMComponent } from '../worklist-cm/worklist-cm.component';
import { WorklistCMModule } from '../worklist-cm/worklist-cm.module';
import { ContractFinalizationEditAddXComponent } from './contract-finalization-edit-add-x.component';
import { ContractFinalizationEditAddComponent } from './contract-finalization-edit-add.component';

export const routes = [
  {
    path: '',
    component: WorklistCMComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'edit',
    component: ContractFinalizationEditAddXComponent,
    data: { breadcrumb: 'contract-finalization.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: ContractFinalizationEditAddXComponent,
    data: { breadcrumb: 'contract-finalization.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: ContractFinalizationEditAddXComponent,
    data: { breadcrumb: 'contract-finalization.breadcrumb.view' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    WorklistCMModule,
    AppProcurementInformationViewModule,
    AppContractRequestViewModule,
    AppContractDraftViewModule,
    AppApprovalPrcsModule,
    AppApprovalPathModule,
    AppWorkflowProgressInfoModule,
    AppContractNegotiationViewModule,
    AppVendorPaymentInformationModule,
    AppWorkflowStepInfoModule,
    AppApprovalPrcsXModule,
    AppUploadXModule,
    AppContractRequestInfoModule,
    AppVendorInformationModule,
    AppWorkDefinitionModule
  ],
  declarations: [
    ContractFinalizationEditAddXComponent,
    ContractFinalizationEditAddComponent
  ],
  providers: []
})
export class ContractFinalizationModule {}
