<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="delivery-monitoring.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" *ngIf="!formLoading">
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'delivery-monitoring.form.procurementName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ procurementScopeWork.procurementVendor.procurement.name }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'delivery-monitoring.form.procurementNumber' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ procurementScopeWork.procurementVendor.procurement.number }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'delivery-monitoring.form.procurementDate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{
              procurementScopeWork.procurementVendor.procurement.procurementDate
                | date: 'dd/MM/yyyy'
            }}
          </div>
        </div>
      </form>
      <app-accordion [isLoading]="formLoading">
        <app-accordion-item
          *ngFor="
            let procurementSow of deliveryMonitoringResponse.procurementSowList;
            let i = index
          "
          [header]="procurementSow.stageOfWork.name"
          [isExpanded]="activeStageList[i]"
        >
          <ng-template #actionButton>
            <div *ngIf="procurementSow.status === 1">
              <div class="text-secondary">
                {{ 'delivery-monitoring.label.status.new' | translate }}
                &nbsp;( {{ totalTermClosedList[i] }} /
                {{ procurementSow.procurementSowPaymentTermList.length }} )
              </div>
            </div>
            <div *ngIf="procurementSow.status === 2">
              <div class="text-primary">
                {{ 'delivery-monitoring.label.status.onProgress' | translate }}
                &nbsp;( {{ totalTermClosedList[i] }} /
                {{ procurementSow.procurementSowPaymentTermList.length }} )
              </div>
            </div>
            <div *ngIf="procurementSow.status === 3">
              <div class="text-success">
                {{ 'delivery-monitoring.label.status.done' | translate }}
              </div>
            </div>
          </ng-template>
          <app-accordion
            *ngFor="
              let procurementSowPaymentTerm of procurementSow.procurementSowPaymentTermList;
              let j = index
            "
          >
            <app-accordion-item
              [header]="procurementSowPaymentTerm.paymentTerm.name"
              [isExpanded]="activeTermList[i][j]"
            >
              <ng-template #actionButton>
                <div *ngIf="procurementSowPaymentTerm.status === 1">
                  <div class="text-secondary">
                    {{ 'delivery-monitoring.label.status.new' | translate }}
                  </div>
                </div>
                <div *ngIf="procurementSowPaymentTerm.status === 2">
                  <div class="text-primary">
                    <em class="fas fa-clock text-warning"></em>&nbsp;{{
                      'delivery-monitoring.label.status.onProgress' | translate
                    }}
                  </div>
                </div>
                <div *ngIf="procurementSowPaymentTerm.status === 3">
                  <div class="text-success">
                    {{ 'delivery-monitoring.label.status.done' | translate }}
                  </div>
                </div>
              </ng-template>
              <h5 class="separator">{{
                'delivery-monitoring.label.header-group.items' | translate
              }}</h5>
              <h5 class="separator">{{
                'delivery-monitoring.label.header.products' | translate
              }}</h5>
              <!-- <div *ngFor="let tableItem of tableItemList; let k = index"> -->
              <app-table
                [model]="tableItemList[i][j].tableItemResponse"
                [isServerSide]="true"
                stringUrl="/delivery-monitoring/table-item"
              >
                <ng-template #actionButtons let-rowData>
                  <app-button
                    *ngIf="
                      rowData.status !== 3 &&
                      global.userSession?.activeUserRole.role.code !== 'VENDOR'
                    "
                    size="TN"
                    (onClick)="
                      doEdit(
                        rowData,
                        procurementSow,
                        procurementSowPaymentTerm,
                        activeStageList[i],
                        activeTermList[i][j]
                      )
                    "
                    title="{{ 'app.tooltip.edit' | translate }}"
                  >
                    <em class="fas fa-pencil-alt"></em>
                  </app-button>
                  <app-button
                    *ngIf="
                      rowData.status === 3 &&
                      global.userSession?.activeUserRole.role.code !== 'VENDOR'
                    "
                    size="TN"
                    (onClick)="doDetail(rowData)"
                    title="{{ 'app.tooltip.view' | translate }}"
                  >
                    <em class="fas fa-search"></em>
                  </app-button>
                  <app-button
                    *ngIf="
                      global.userSession?.activeUserRole.role.code === 'VENDOR'
                    "
                    size="TN"
                    (onClick)="doDetail(rowData)"
                    title="{{ 'app.tooltip.history' | translate }}"
                  >
                    <em class="fas fa-history"></em>
                  </app-button>
                </ng-template>
              </app-table>
              <!-- </div> -->
              <br />
              <h5 class="separator">{{
                'delivery-monitoring.label.header.service' | translate
              }}</h5>
              <app-table
                [model]="tableItemList[i][j].tableServiceResponse"
                [isServerSide]="true"
                stringUrl="/delivery-monitoring/table-service"
              >
                <ng-template #actionButtons let-rowData>
                  <app-button
                    *ngIf="
                      rowData.status !== 3 &&
                      global.userSession?.activeUserRole.role.code !== 'VENDOR'
                    "
                    size="TN"
                    (onClick)="
                      doEdit(
                        rowData,
                        procurementSow,
                        procurementSowPaymentTerm,
                        activeStageList[i],
                        activeTermList[i][j]
                      )
                    "
                    title="{{ 'app.tooltip.edit' | translate }}"
                  >
                    <em class="fas fa-pencil-alt"></em>
                  </app-button>
                  <app-button
                    *ngIf="
                      rowData.status === 3 &&
                      global.userSession?.activeUserRole.role.code !== 'VENDOR'
                    "
                    size="TN"
                    (onClick)="doDetail(rowData)"
                    title="{{ 'app.tooltip.view' | translate }}"
                  >
                    <em class="fas fa-search"></em>
                  </app-button>
                  <app-button
                    *ngIf="
                      global.userSession?.activeUserRole.role.code === 'VENDOR'
                    "
                    size="TN"
                    (onClick)="doDetail(rowData)"
                    title="{{ 'app.tooltip.history' | translate }}"
                  >
                    <em class="fas fa-history"></em>
                  </app-button>
                </ng-template>
              </app-table>
              <div
                *ngIf="
                  global.userSession?.activeUserRole.role.code === 'VENDOR' &&
                  procurementSowPaymentTerm.status !== 1 &&
                  procurementSowPaymentTerm.isPartial
                "
                class="button-group button-group-center"
              >
                <!-- <app-button
                  (onClick)="doPrint(procurementSowPaymentTerm)"
                  [disabled]="formSaving"
                  >{{
                    'delivery-monitoring.button.printInvoice' | translate
                  }}</app-button> -->
                <app-button
                  (onClick)="
                    doRequest(procurementSowPaymentTerm, procurementSow)
                  "
                >
                  'delivery-monitoring.button.requestPayment' | translate
                  }}</app-button
                >
              </div>
              <div
                class="button-group button-group-center"
                *ngIf="
                  global.userSession?.activeUserRole.role.code !== 'VENDOR' &&
                  procurementSowPaymentTerm.status === 3 &&
                  !procurementSowPaymentTerm.isFinishAssessed &&
                  procurementSowPaymentTerm.isAssessment
                "
              >
                <app-button
                  (onClick)="
                    doProcurementFulfillment(procurementSowPaymentTerm)
                  "
                  [disabled]="formSaving"
                  >{{
                    'delivery-monitoring.button.performanceFulfillment'
                      | translate
                  }}</app-button
                >
              </div>
              <div
                *ngIf="
                  global.userSession?.activeUserRole.role.code === 'VENDOR' &&
                  procurementSowPaymentTerm.status === 3 &&
                  !procurementSowPaymentTerm.isPartial
                "
                class="button-group button-group-center"
              >
                <!-- <app-button
                  (onClick)="doPrint(procurementSowPaymentTerm)"
                  [disabled]="formSaving"
                  >{{
                    'delivery-monitoring.button.printInvoice' | translate
                  }}</app-button> -->
                <app-button
                  (onClick)="
                    doRequest(procurementSowPaymentTerm, procurementSow)
                  "
                  [disabled]="formSaving"
                  >{{
                    'delivery-monitoring.button.requestPayment' | translate
                  }}</app-button
                >
              </div>
            </app-accordion-item>
          </app-accordion>
        </app-accordion-item>
      </app-accordion>
      <div class="floating-button mt-5">
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div
              *ngIf="
                global.userSession?.activeUserRole.role.code === 'VENDOR' ||
                deliveryMonitoringResponse.deliveryStatus !== 3
              "
              class="button-group button-group-center"
            >
              <app-button
                color="SECONDARY"
                (onClick)="doBack()"
                [disabled]="formSaving"
                >{{ 'app.button.back' | translate }}</app-button
              >
            </div>
            <div
              *ngIf="
                global.userSession?.activeUserRole.role.code !== 'VENDOR' &&
                deliveryMonitoringResponse.deliveryStatus === 3
              "
              class="button-group button-group-center"
            >
              <app-button
                color="SECONDARY"
                (onClick)="doBack()"
                [disabled]="formSaving"
                >{{ 'app.button.back' | translate }}</app-button
              >
              <app-button
                *ngIf="
                  procurementScopeWork.agreementType !== 'PO_SPK' &&
                  procurementScopeWork.contract?.contractStatus?.code !==
                    'REQUEST-CLOSED-CONTRACT'
                "
                color="PRIMARY"
                (onClick)="doRequestCloseContract(procurementScopeWork)"
                [disabled]="formSaving"
                >{{
                  'delivery-monitoring.button.requestCloseContract' | translate
                }}</app-button
              >
              <app-button
                *ngIf="procurementScopeWork.agreementType === 'PO_SPK'"
                color="PRIMARY"
                (onClick)="doRequestCloseScopeWork(procurementScopeWork)"
                [disabled]="formSaving"
                >{{
                  'delivery-monitoring.button.workCompletion' | translate
                }}</app-button
              >
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
