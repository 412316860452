import { CurrencyPipe } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Global } from '../../global/global';
import { Log } from '../../services/logger';
import { TextUtils } from '../../utils';
import { AppTextFieldAttrType } from './app-text-field-attr-type';
const selector = 'app-text-field[type]';
@Directive({ selector })
export class AppTextFieldDirective implements OnInit, AfterViewInit {
  @Input() type: AppTextFieldAttrType;
  @Input() include: string;
  @Input() pattern: string;
  @Input() formControlName: string;

  public isShowPassword = false;
  public generatedId = TextUtils.generateRandomString();

  public CURRENCY_PRECISSION_SCALE: number;
  public CURRENCY_DIGITS_INFO: string;
  public CURRENCY_MAX_INPUT_PRECISSION_SCALE: number;
  public log: Log = new Log(this);
  constructor(
    public elRef: ElementRef,
    public ngControl: NgControl,
    public global: Global,
    public currency: CurrencyPipe
  ) {}

  ngOnInit(): void {
    if (this.type === 'currency' || this.type === 'decimal') {
      this.CURRENCY_PRECISSION_SCALE =
        this.global.appConstant.core.CURRENCY_PRECISSION_SCALE;
      this.CURRENCY_DIGITS_INFO = `0.${this.CURRENCY_PRECISSION_SCALE}-${this.CURRENCY_PRECISSION_SCALE}`;
      this.CURRENCY_MAX_INPUT_PRECISSION_SCALE =
        this.global.appConstant.core.CURRENCY_MAX_INPUT_PRECISSION_SCALE;
    }
  }

  ngAfterViewInit(): void {
    this.setShowIconInputPassword();
    this.doSetCurrencyFormat();
    this.doSetDecimalFormat();
  }

  public setShowIconInputPassword(): void {
    if (this.type === 'password') {
      const iElement = jQuery(this.elRef.nativeElement).find(
        '.icon-show-password'
      )[0];
      if (iElement) {
        iElement.setAttribute('id', this.generatedId);
        iElement.style.display = 'block';
      }
    }
  }

  doSetCurrencyFormat(): void {
    const formGroup = this.ngControl.control.parent;
    const formControl = formGroup.get(this.formControlName);
    const isView = formControl.isView || formGroup.isView;
    if (this.type === 'currency' && !isView) {
      const inputElement = jQuery(this.elRef.nativeElement).find('input')[0];
      inputElement.value = inputElement.value
        ? Number(inputElement.value).toString() !== 'NaN'
          ? this.currency.transform(
              inputElement.value,
              '',
              '',
              this.CURRENCY_DIGITS_INFO
            )
          : inputElement.value
        : null;
      const placeholderDecimal = TextUtils.generateRandomString(
        this.CURRENCY_PRECISSION_SCALE,
        '0'
      );
      if (!inputElement.value) {
        inputElement.placeholder = `0.${placeholderDecimal}`;
      }
      this.ngControl.control.valueChanges.subscribe((value: any) => {
        if (!this.ngControl.control.dirty && value !== null) {
          inputElement.value = value
            ? Number(value).toString() !== 'NaN'
              ? this.currency.transform(
                  value,
                  '',
                  '',
                  this.CURRENCY_DIGITS_INFO
                )
              : value
            : null;
          if (!value) {
            inputElement.placeholder = `0.${placeholderDecimal}`;
          }
        }
      });
    }
  }

  doSetDecimalFormat(): void {
    const formGroup = this.ngControl.control.parent;
    const formControl = formGroup.get(this.formControlName);
    const isView = formControl.isView || formGroup.isView;
    if (this.type === 'decimal' && !isView) {
      const inputElement = jQuery(this.elRef.nativeElement).find('input')[0];
      inputElement.value = inputElement.value
        ? this.currency
            .transform(inputElement.value, '', '', this.CURRENCY_DIGITS_INFO)
            .replace(/,/g, '')
        : null;
      const placeholderDecimal = TextUtils.generateRandomString(
        this.CURRENCY_PRECISSION_SCALE,
        '0'
      );
      if (!inputElement.value) {
        inputElement.placeholder = `0.${placeholderDecimal}`;
      }
      this.ngControl.control.valueChanges.subscribe((value: any) => {
        if (!this.ngControl.control.dirty && value !== null) {
          inputElement.value = value
            ? this.currency
                .transform(value, '', '', this.CURRENCY_DIGITS_INFO)
                .replace(/,/g, '')
            : null;
          if (!value) {
            inputElement.placeholder = `0.${placeholderDecimal}`;
          }
        }
      });
    }
  }

  @HostListener('input', ['$event'])
  handleInput(event: any): void {
    const eventInputType = event.inputType;
    if (eventInputType === 'insertText') {
      this.handleInsertText(event);
    } else if (eventInputType === 'insertFromPaste') {
      this.handleInsertFromPaste(event);
    } else if (eventInputType === 'deleteContentBackward') {
      this.setShowIconInputPassword();
      this.doSetCurrency(event);
    } else if (eventInputType === 'deleteContentForward') {
      this.doSetCurrency(event);
    }
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent): void {
    const clipboard = event.clipboardData;
    const pasteText = this.global.converterService.uConvertMoney(
      clipboard.getData('text')
    );
    if (this.type === 'currency') {
      this.errorCatcher(
        event,
        `Ooopss...!!! You\'ve been paste an invalid Currency Number`,
        () => {
          this.currency.transform(pasteText, '', '', this.CURRENCY_DIGITS_INFO);
        }
      );
    } else if (this.type === 'integer' || this.type === 'integer-left') {
      this.errorCatcher(event, null, () => {
        if (isNaN(+pasteText)) {
          throw new Error(`Ooopss...!!! You've been paste an invalid integer`);
        }
      });
    } else if (this.type === 'decimal') {
      this.errorCatcher(event, null, () => {
        /**
         * this regex below is working with javascript, but I don't know why it's not working on typescript
         * save for try it later
         * const regex =  /^[-+]?[\d]+((?=\.)\.[\d]+|(?!\.)[\d]+)$/;
         */
        if (isNaN(+pasteText)) {
          throw new Error('Ooopss...!!! You\ve been paste an invalid decimal.');
        }
      });
    } else if (this.type === 'code') {
      this.errorCatcher(event, null, () => {
        if (isNaN(+pasteText)) {
          throw new Error(`Ooopss...!!! You've been paste an invalid code`);
        }
      });
    }
  }

  @HostListener('focusout', ['$event'])
  handleFocusOut(event: any): void {
    if (this.type === 'currency') {
      if (event.target.value.length === 1 && isNaN(event.target.value)) {
        event.target.value = event.target.value.slice(1);
      }
      event.target.value = (+event.target.value).toFixed(2);
      const fixedValue = event.target.value;
      this.ngControl.control.patchValue(+fixedValue);
      const uConvertMoneyValue = this.global.converterService.uConvertMoney(
        event.target.value
      );
      event.target.value = this.currency.transform(
        uConvertMoneyValue,
        '',
        '',
        this.CURRENCY_DIGITS_INFO
      );
    } else if (this.type === 'decimal') {
      const lastStringOfValue =
        event.target.value[event.target.value.length - 1];
      const isNumber = !isNaN(Number(lastStringOfValue));
      if (!isNumber && event.target.value[0] !== '-') {
        event.target.value = event.target.value.slice(
          0,
          event.target.value.length - 1
        );
      }

      let eventTargetValue = event.target.value;
      while (
        eventTargetValue[0] === '0' &&
        eventTargetValue[1] !== '.' &&
        eventTargetValue.length > 1
      ) {
        eventTargetValue = eventTargetValue.slice(1, eventTargetValue.length);
      }
      event.target.value =
        eventTargetValue &&
        this.currency.transform(
          eventTargetValue,
          '',
          '',
          this.CURRENCY_DIGITS_INFO
        );
    } else {
      this.ngControl.control.patchValue(event.target.value);
    }
  }

  @HostListener('focusin', ['$event'])
  handleFocusIn(event: any): void {
    if (event.target.value !== '') {
      if (this.type === 'currency') {
        event.target.value = this.global.converterService.uConvertMoney(
          event.target.value
        );
      } else if (this.type === 'decimal') {
        event.target.value = this.global.converterService.uConvertMoney(
          event.target.value
        );
      }
    }
  }

  @HostListener('click', ['$event'])
  public onListenerTriggered(event: any): void {
    if (event.target.id === this.generatedId) {
      const element = jQuery(this.elRef.nativeElement).find('input')[0];
      event.target.classList.toggle('show');
      const type = !this.isShowPassword ? 'text' : 'password';
      element.setAttribute('type', type);
      this.isShowPassword = !this.isShowPassword;
    }
  }

  public handleInsertText(event: any): void {
    if (this.type === 'email') {
      event.target.value = event.target.value.toLowerCase();
      this.ngControl.control.patchValue(event.target.value);
    }

    if (this.type === 'decimal' || this.type === 'currency') {
      const strRegex =
        this.pattern || this.include
          ? '[^0-9.' + (this.pattern || this.include) + ']'
          : '[^0-9.]';
      const regex = new RegExp(strRegex, 'g');
      event.target.value = event.target.value.replace(regex, '');
      const totalDot = event.target.value.split('.').length - 1;

      if (event.target.value[0] === '.') {
        event.target.value = event.target.value.slice(1);
      } else {
        if (totalDot === 2) {
          event.target.value = event.target.value.slice(
            0,
            event.target.value.length - 1
          );
        }

        if (totalDot === 1) {
          const CURRENCY_PRECISSION_SCALE =
            this.global.appConstant.core.CURRENCY_PRECISSION_SCALE;
          const decimal = event.target.value.split('.')[1];
          if (decimal.length > CURRENCY_PRECISSION_SCALE) {
            event.target.value = event.target.value.slice(
              0,
              event.target.value.length - 1
            );
          }
        }
      }
      this.ngControl.control.patchValue(event.target.value);
    }

    if (this.type === 'integer' || this.type === 'integer-left') {
      const strRegex =
        this.pattern || this.include
          ? '[^0-9' + (this.pattern || this.include) + ']'
          : '[^0-9]';
      const regex = new RegExp(strRegex, 'g');
      event.target.value = event.target.value.replace(regex, '');
    }

    if (this.type === 'password') {
      this.setShowIconInputPassword();
    }

    if (this.type === 'code') {
      const strRegex =
        this.pattern || this.include
          ? '[^a-zA-Z0-9-_' + (this.pattern || this.include) + ']'
          : '[^a-zA-Z0-9-_]';
      const regex = new RegExp(strRegex, 'g');
      event.target.value = event.target.value.replace(regex, '').toUpperCase();
    }

    if (this.type === 'uppercase') {
      event.target.value = event.target.value.toUpperCase();
    }

    if (this.type === 'alphabet') {
      const strRegex =
        this.pattern || this.include
          ? '[^a-zA-Z ' + (this.pattern || this.include) + ']'
          : '[^a-zA-Z ]';
      const regex = new RegExp(strRegex, 'g');
      event.target.value = event.target.value.replace(regex, '');
    }

    if (this.type === 'alphanumeric') {
      const strRegex =
        this.pattern || this.include
          ? '[^\\w\\s' + (this.pattern || this.include) + ']'
          : '[^\\w\\s]';
      const regex = new RegExp(strRegex, 'gi');
      event.target.value = event.target.value.replace(regex, '');
    }
  }

  doSetCurrency(event: any): void {
    if (this.type === 'currency') {
      const inputValue = event.target.value;
      const inputValueLength = inputValue.length;
      const indexOfDot = event.target.value.indexOf('.');
      const indexOfInclude = event.target.value.indexOf('-');
      const isRemoveDot =
        (indexOfDot !== -1 &&
          indexOfDot + this.CURRENCY_MAX_INPUT_PRECISSION_SCALE <
            inputValueLength - 1) ||
        (event.data === '.' && indexOfDot !== inputValueLength - 1);
      const isRemoveInclude =
        this.include &&
        this.include.includes(event.data) &&
        (indexOfInclude !== 0 ||
          event.target.value.split('-').length >
            this.CURRENCY_MAX_INPUT_PRECISSION_SCALE);
      const include = this.include ? this.include + '.' : '.';
      const isShouldRemoveLastString =
        isRemoveInclude ||
        isRemoveDot ||
        (isNaN(+event.data) && !include.includes(event.data));
      if (isShouldRemoveLastString) {
        /*event.target.value = event.target.value.slice(0, -1);*/
        this.ngControl.control.patchValue(event.target.value);
      } else {
        if (event.data !== '.' && indexOfDot === -1) {
          this.ngControl.control.patchValue(event.target.value);
        }
      }
    }
  }

  handleInsertFromPaste(event: any): void {
    if (this.type === 'decimal') {
      const totalDots = event.target.value.split('.').length - 1;
      if (totalDots > 0) {
        const lastStringOfValue =
          event.target.value[event.target.value.length - 1];
        if (isNaN(Number(lastStringOfValue))) {
          event.target.value = event.target.value.slice(
            0,
            event.target.value.length - 1
          );
        } else {
          const lastIndexOfDot =
            event.target.value.lastIndexOf('.') - totalDots + 1;
          const fullNumber = event.target.value.replace(/\./g, '');
          const arrayOfNumber = fullNumber.split('');
          const newNumber = [
            ...arrayOfNumber.slice(0, lastIndexOfDot),
            '.',
            ...arrayOfNumber.slice(lastIndexOfDot, lastIndexOfDot + 2)
          ];
          event.target.value = newNumber.join('');
        }
      }
    } else if (this.type === 'email') {
      event.target.value = event.target.value.toLowerCase();
    }
    this.setShowIconInputPassword();
  }

  public replaceLastIndexOfDotAndLeaveOne(s: string): string {
    const dotCount = s.match(/\./g);
    if (dotCount && dotCount.length === 1) {
      return s;
    }
    const pos = s.lastIndexOf('.');
    if (pos >= 0) {
      return s.substring(0, pos) + '' + s.substring(pos + 1);
    }
    return s;
  }

  public errorCatcher(
    event: ClipboardEvent,
    message: string,
    callback: Function
  ): void {
    try {
      callback();
    } catch (ex) {
      event.stopPropagation();
      event.preventDefault();
      this.log.error(message ? message : ex.message);
    }
  }
}
