import {
  Component,
  ElementRef,
  Input,
  Optional,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { BaseValueAccessorComponent } from '../../../core/base/angular/base-value-accessor.component';
import {
  makeUploaderProvider,
  Uploader,
  UploadMapper,
  UploadService
} from '../../../core/components/upload';
import * as uploadAction from '../../../core/components/upload/actions/upload-file.action';
@Component({
  selector: 'app-user-profile-card',
  templateUrl: './app-user-profile-card.component.html',
  styleUrls: ['./app-user-profile-card.component.scss'],
  providers: [...makeUploaderProvider(AppUserProfileCardComponent)],
  encapsulation: ViewEncapsulation.None
})
export class AppUserProfileCardComponent extends BaseValueAccessorComponent<any> {
  @Input() showChangeButton: boolean;
  public model: Uploader = new Uploader(
    '/account-setting/',
    this.global.appConstant.fileType.IMG_PROFILE_PICTURE
  );
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public uploadService: UploadService,
    public renderer2: Renderer2
  ) {
    super('app-user-profile-card', controlContainer, elementRef);
  }

  public onInitBaseValueAccessor(): void {
    this.uploadService
      .setUploader(this.model, this.formControl)
      .subscribe((uploader: Uploader) => {
        this.formControl.patchValue(UploadMapper.toValues(uploader));
        this.onChange.emit(this.formControl.value);
      });
  }

  public onChangeUpload(event: any): void {
    this.model.totalUploaded > 0
      ? this.uploadService.dispatch(
        new uploadAction.ChangeFile({
          fileList: event.target.files,
          index: 0
        })
      )
      : this.uploadService.dispatch(
        new uploadAction.AddFile({ fileList: event.target.files })
      );
    event.target.value = null;
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
