import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { UserManualEditAddComponent } from './user-manual-edit-add.component';
import { UserManualComponent } from './user-manual.component';
const routes: Routes = [
  { path: '', component: UserManualComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: UserManualEditAddComponent,
    data: { breadcrumb: 'user-manual.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [UserManualComponent, UserManualEditAddComponent]
})
export class UserManualModule {}
