<app-layout [isLoading]="formLoading">
  <app-content-slider [isLoading]="formLoading">
    <app-content-slider-item
      *ngFor="let model of worklaodInfoModelList; let i = index"
      (onClick)="doChangeModule(model.module)"
      [backgroundColor]="
        model.module.code === currentPosition
          ? 'var(--gray-200)'
          : 'var(--gray-100)'
      "
      color="var(--primary-dark)"
      width="200px"
    >
      <div class="p-4">
        <h2 class="mb-2" maxLine="1">{{ model.recordCount }}</h2>
        <h5 class="text-break-space" maxLine="2">{{ model.module.name }}</h5>
      </div>
    </app-content-slider-item>
  </app-content-slider>
  <app-table-xx
    *ngIf="!formLoading"
    header="workload-monitoring.table.header.assignmentMonitoringList"
    [model]="tableResponse"
    stringUrl="/workload-monitoring/index"
    (onClick)="doClickUser($event)"
  >
    <ng-template #headerFilterGroup>
      <form
        class="form-horizontal pt-4"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <div class="form-group row text-sm-left ml-1">
          <div class="col-sm-2 text-left">
            <app-dropdown-select
              *ngIf="!formLoading"
              size="XL"
              formControlName="workloadLevelList"
              placeholder="
                {{
                'workload-monitoring.placeholder.workloadLevel' | translate
              }}
              "
              [optionList]="workloadLevelOptionList"
              (onChange)="onChangeFilter()"
              type="CHOSEN"
              [ngStyle]="{
                width: 'inherit'
              }"
              showLimitValue="4"
            ></app-dropdown-select>
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template
      *ngIf="
        !formLoading &&
        module &&
        module.delegation === global.appConstant.core.DELEGATION_AUTO_DELEGATION
      "
      #headerButtons
    >
      <app-button (onClick)="doReBalancing()">
        {{ 'workload-monitoring.button.reBalanceAssignment' | translate }}
      </app-button>
    </ng-template>
  </app-table-xx>
</app-layout>
