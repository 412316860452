import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorHistory } from '../../core/bean/vendor-history';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './on-boarding.component.html',
  styles: ['.width-company-name { min-width : 250px; white-space: normal ! important }'],
  encapsulation: ViewEncapsulation.None
})
export class OnBoardingComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<VendorHistory>;
  public customData: string = null;

  constructor(translateService: TranslateService) {
    super('on-boarding', translateService);
  }

  onInit(): void {
    this.setCustomData();
    this.buildTableResponse();
    this.setStateReady();
    this.global.routerParams.clear();
  }

  public setCustomData(): void {
    this.customData = this.global.routerParams.get('vendorActivityStatusCodeFromWidget');
  }

  public buildTableResponse(): void {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        { field: 'name', header: 'table.column.vendorName', customClass: 'width-company-name' },
        { field: 'vendor.code', header: 'table.column.vendorCode', customClass: 'text-center' },
        { field: 'vendor.onBoardingSubmissionDate', header: 'table.column.submittedDate', format: FieldFormatEnum.ShortDateAndTime },
        { field: 'vendorType.name', header: 'table.column.vendorType', customClass: 'text-center' },
        { field: 'email', header: 'table.column.email' },
        { field: 'vendor.onBoardingByOrganization.name', header: 'table.column.organization', customClass: 'white-space-normal' },
        { field: 'vendor.verifiedByUser.name', header: 'table.column.picName', customClass: 'white-space-normal', sortable: false },
        { field: 'vendor.vendorActivityStatus.name', header: 'table.column.vendorActivityStatus', customClass: 'text-center' }
      ]);
  }

  public doEdit(vendorHistory: VendorHistory): void {
    this.global.routerParams.set('vendorHistoryId', vendorHistory.id);
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('isGuest', true);
    this.global.routerParams.set('activityStatus', vendorHistory.vendor.vendorActivityStatus.code);
    this.global.routerParams.set('vendorStatus', vendorHistory.vendor.vendorStatus.code);
    this.global.routerParams.set('urlBackOutside', '/pages/on-boarding');
    this.router.navigate(['/pages/on-boarding/edit']);
  }
}
