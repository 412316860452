import { FileObject } from 'src/app/core/components/upload';
import { ProcurementOfflineBudget } from '../../core/bean/procurement-offline-budget';
import { PurchaseRequestBudget } from '../../core/bean/purchase-request-budget';
import { ProcurementOfflinePaymentHistory } from '../../core/bean/procurement-offline-payment-history';
import { PurchaseRequestData } from '../../core/bean/purchase-request-data';
import { ProcurementOfflinePayment } from '../../core/bean/procurement-offline-payment';
import { ProcurementOffline } from 'src/app/core/bean/procurement-offline';

export class ProcurementOfflineRequest {
    isNumberGenerator: number;
    purchaseRequestData: PurchaseRequestData;
    procurementOffline: ProcurementOffline;
    procurementValueTotal: number;
    paymentValueTotal: number;
    purchaseRequestBudgetList: PurchaseRequestBudget[] = [];
    procurementOfflineBudgetList: ProcurementOfflineBudget[] = [];
    procurementOfflinePaymentList: ProcurementOfflinePayment[] = [];
    procurementOfflinePaymentHistoryList: ProcurementOfflinePaymentHistory[] = [];
    fileObjectList: FileObject[];
}
