import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { Qualification } from '../bean/qualification';
import { Region } from '../bean/region';
import { User } from '../bean/user';
import { Vendor } from '../bean/vendor';
import { VendorCategory } from '../bean/vendor-category';
import { VendorEntity } from '../bean/vendor-entity';
import { VendorIndustryHistory } from '../bean/vendor-industry-history';
import { VendorLocation } from '../bean/vendor-location';
import { VendorOwnership } from '../bean/vendor-ownership';
import { VendorProductLocationHistory } from '../bean/vendor-product-location-history';
import { VendorProductServiceCategoryHistory } from '../bean/vendor-product-service-category-history';
import { VendorType } from '../bean/vendor-type';
export class VendorHistoryEntity extends BaseEntity {
  address: string;
  vendorProductServiceCategoryHistoryList: VendorProductServiceCategoryHistory[] = [];
  vendorIndustryHistoryList: VendorIndustryHistory[] = [];
  vendorProductLocationHistoryList: VendorProductLocationHistory[] = [];
  vendor: Vendor = new Vendor();
  phoneRegion: Region;
  vendorType: VendorType;
  vendorOwnership: VendorOwnership;
  vendorCategory: VendorCategory;
  vendorEntity: VendorEntity;
  vendorLocation: VendorLocation;
  user: User;
  logoFile: File;
  bannerFile: File;
  qualification: Qualification;
  name: string;
  abbreviation: string;
  trademarkName: string;
  pkpNumber: string;
  taxNumber: string;
  certificateOfEstablishment: string;
  email: string;
  establishmentYear: number;
  phone: string;
  totalEmployee: number;
  description: string;
  facebook: string;
  linkedin: string;
  ig: string;
  twitter: string;
  website: string;
  attachmentFile: File;
  externalLink: string;
  registrationNumber: string;
  keyword: string;
  initialName: string;
  isPkp: boolean;
  idNumber: string;
  createdDate: Date;
  sequence: number;
  isSubmit: boolean;
  companyProfileFile: File;
  totalExpert: number;
  totalExperience: number;
  totalEquipment: number;
  statusApproval: number;
}
