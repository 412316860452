import { BaseEntity } from '../base/base-entity';
import { Currency } from '../bean/currency';
import { File } from '../bean/file';
import { Region } from '../bean/region';
import { Vendor } from '../bean/vendor';
import { VendorExperienceSegmentation } from '../bean/vendor-experience-segementation';
import { VendorExperienceStatus } from '../bean/vendor-experience-status';
import { VendorExperienceTitle } from '../bean/vendor-experience-title';
import { FileObject } from '../components/upload';
export class VendorExperienceEntity extends BaseEntity {
    fileContractList: FileObject[] = [];
    segmentation: string;
    vendorExperienceSegmentationList: VendorExperienceSegmentation[];

    countryRegion: Region;
    cityRegion: Region;
    vendorExperienceStatus: VendorExperienceStatus;
    file: File;
    vendor: Vendor;
    currency: Currency;
    vendorExperienceTitle: VendorExperienceTitle;
    projectName: string;
    businessPartnerName: string;
    partnership: string;
    address: string;
    projectOwner: string;
    projectType: string;
    contractNumber: string;
    contractDate: Date;
    contractValue: number;
    coordinateLocation: string;
    partnershipStatus: string;
}
