import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Global } from '../../global/global';
import { Log } from '../../services/logger';
import { TextUtils } from '../../utils';
import { AppTextGeneratedAttrType } from './app-text-generated-attr-type';
const selector = 'app-text-generated[type]';
@Directive({ selector })
export class AppTextGeneratedDirective implements OnInit, AfterViewInit {
  @Input() type: AppTextGeneratedAttrType;
  @Input() include: string;
  @Input() pattern: string;
  @Input() formControlName: string;

  public generatedId = TextUtils.generateRandomString();

  public log: Log = new Log(this);
  constructor(
    public elRef: ElementRef,
    public ngControl: NgControl,
    public global: Global
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  @HostListener('input', ['$event'])
  handleInput(event: any): void {
    this.handleInsertText(event);
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent): void {
    const clipboard = event.clipboardData;
    const pasteText = this.global.converterService.uConvertMoney(
      clipboard.getData('text')
    );
    if (this.type === 'integer') {
      this.errorCatcher(event, null, () => {
        if (isNaN(+pasteText)) {
          throw new Error(`Ooopss...!!! You've been paste an invalid integer`);
        }
      });
    } else if (this.type === 'code') {
      this.errorCatcher(event, null, () => {
        if (isNaN(+pasteText)) {
          throw new Error(`Ooopss...!!! You've been paste an invalid code`);
        }
      });
    }
  }

  @HostListener('focusout', ['$event'])
  handleFocusOut(event: any): void {
    this.ngControl.control.patchValue(event.target.value);
  }

  @HostListener('click', ['$event'])
  public onListenerTriggered(event: any): void {
    if (event.target.id === this.generatedId) {
      const element = jQuery(this.elRef.nativeElement).find('input')[0];
      event.target.classList.toggle('show');
      element.setAttribute('type', 'text');
    }
  }

  public handleInsertText(event: any): void {
    if (this.type === 'integer') {
      const strRegex =
        this.pattern || this.include
          ? '[^0-9' + (this.pattern || this.include) + ']'
          : '[^0-9]';
      const regex = new RegExp(strRegex, 'g');
      event.target.value = event.target.value.replace(regex, '');
    }

    if (this.type === 'code') {
      const strRegex =
        this.pattern || this.include
          ? '[^a-zA-Z0-9-_' + (this.pattern || this.include) + ']'
          : '[^a-zA-Z0-9-_]';
      const regex = new RegExp(strRegex, 'g');
      event.target.value = event.target.value.replace(regex, '').toUpperCase();
    }

    if (this.type === 'uppercase') {
      event.target.value = event.target.value.toUpperCase();
    }

    if (this.type === 'alphabet') {
      const strRegex =
        this.pattern || this.include
          ? '[^a-zA-Z ' + (this.pattern || this.include) + ']'
          : '[^a-zA-Z ]';
      const regex = new RegExp(strRegex, 'g');
      event.target.value = event.target.value.replace(regex, '');
    }

    if (this.type === 'alphanumeric') {
      const strRegex =
        this.pattern || this.include
          ? '[^\\w\\s' + (this.pattern || this.include) + ']'
          : '[^\\w\\s]';
      const regex = new RegExp(strRegex, 'gi');
      event.target.value = event.target.value.replace(regex, '');
    }
  }

  public errorCatcher(
    event: ClipboardEvent,
    message: string,
    callback: Function
  ): void {
    try {
      callback();
    } catch (ex) {
      event.stopPropagation();
      event.preventDefault();
      this.log.error(message ? message : ex.message);
    }
  }
}
