import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { PrStatus } from '../../core/bean/pr-status';
import { User } from '../../core/bean/user';
import { WorkloadLevel } from '../../core/bean/workload-level';
import { WorkloadPrcs } from '../../core/bean/workload-prcs';
import { AppPopupChooseUserService } from '../../core/components/app-popup/app-popup-choose-user/app-popup-choose-user.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableColumnModel } from '../../core/components/table/model/table-column-model';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { WorkloadMonitoringAssignmentView } from '../../core/view/entity/bean/workload-monitoring-assignment-view';
import { AppPopupDefinitionLevelComponent } from './app-popup-definition-level.component';
import { WorkloadMonitoringEditResponse } from './workload-monitoring-edit-response';
import { WorkloadMonitoringModel } from './workload-monitoring-model';

@Component({
  templateUrl: './workload-monitoring-edit.component.html'
})
export class WorkloadMonitoringEditComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<WorkloadMonitoringAssignmentView>;
  public tableResponseWorkloadList: TableResponseModel<any>[] = [];

  public statusOptionList: OptionListModel<PrStatus> = new OptionListModel(
    true
  );

  public user: User;
  public workloadLevel: WorkloadLevel;
  public moduleCodeTmp: string;
  public monitoringEditResponse: WorkloadMonitoringEditResponse;

  public alertColor = {
    HIGH: 'DANGER',
    MEDIUM: 'WARNING',
    LOW: 'INFO',
    NOT_ASSIGNED: 'WARNING'
  };

  public alertMessage = {
    HIGH: 'workload-monitoring.alert.msg.workloadLevelDanger',
    MEDIUM: 'workload-monitoring.alert.msg.workloadLevelMedium',
    LOW: 'workload-monitoring.alert.msg.workloadLevelLow',
    NOT_ASSIGNED: 'workload-monitoring.alert.msg.workloadLevelNotAssigned'
  };

  public constructor(
    translateService: TranslateService,
    public appPopupChooseUserService: AppPopupChooseUserService,
    public appPopupService: AppPopupService
  ) {
    super('workload-monitoring', translateService);
  }

  public onInit(): void {
    this.getDataFromRouterParam();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public getDataFromRouterParam(): void {
    this.user = this.global.routerParams.get('user');
    this.workloadLevel = this.global.routerParams.get('workloadLevel');
    this.moduleCodeTmp = this.global.routerParams.get('moduleCode');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      statusList: [null],
      userId: this.user.id,
      moduleCode: this.moduleCodeTmp
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<WorkloadMonitoringEditResponse>(
        '/workload-monitoring/edit?moduleCode=' +
          this.moduleCodeTmp +
          '&userId=' +
          this.user.id
      )
      .subscribe(response => {
        this.monitoringEditResponse = response;
        this.statusOptionList.setRequestValues(response.prStatusList);
        this.buildTableUserWorkload(
          this.monitoringEditResponse.workloadMonitoringModelList
        );
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'title',
        header: 'table.column.title',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClickDataAssignment.bind(this)
        }
      },
      {
        field: 'requiredDate',
        header: 'table.column.requiredDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'organization.name',
        header: 'table.colum.department'
      },
      {
        field: 'description',
        header: 'table.colum.description'
      },
      {
        field: 'prStatus.name',
        header: 'table.column.status',
        plugins: [
          {
            name: 'badge',
            colorField: 'prStatus.code',
            colorMap: {
              DRAFT: 'SECONDARY',
              REVISION: 'FEEDBACK',
              WAITING_APPROVAL: 'WARNING',
              DONE: 'SUCCESS',
              ON_PROGRESS: 'PROGRESS',
              HOLD: 'DANGER',
              VERIFIED: 'INFO',
              WAITING_VERIFICATION: 'WARNING',
              CANCELED: 'DANGER'
            }
          }
        ]
      }
    ]);

    this.tableResponse.setCustomData(this.formGroup.value);
  }

  public buildTableUserWorkload(modelList: WorkloadMonitoringModel[]): void {
    modelList.forEach(model => {
      const arrayColumn: Array<TableColumnModel> = model.workloadSummary
        .generatedTableResponse
        ? JSON.parse(model.workloadSummary.generatedTableResponse)
        : null;
      const tableResponse = new TableResponseModel(
        this.moduleCode,
        arrayColumn
      );

      if (model.workloadSummary.code === 'MODULE') {
        tableResponse.setRecordList(model.moduleList);
      } else {
        tableResponse.setRecordList(model.workloadUserViewList);
      }
      this.tableResponseWorkloadList.push(tableResponse);
    });
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
    } else {
      this.formGroup.value.fromDate = null;
      this.formGroup.value.untilDate = null;
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doClickDataAssignment(
    assignmentDelegationView: WorkloadMonitoringAssignmentView
  ): void {
    const workloadPrcsList: WorkloadPrcs[] = [];
    workloadPrcsList.push(assignmentDelegationView.workloadPrcs);
    const titleList: string[] = [];
    titleList.push(assignmentDelegationView.title);
    this.appPopupChooseUserService
      .open(workloadPrcsList, true, false, null, titleList, this.user)
      .subscribe(result => {
        if (result) {
          this.tableResponse.reload();
        }
      });
  }

  public doOpenPopupLevelInfo(): void {
    this.appPopupService.open(
      AppPopupDefinitionLevelComponent,
      {
        user: this.user,
        workloadLevel: this.workloadLevel,
        levelThreshold: this.monitoringEditResponse.levelThreshold
      },
      { size: 'md' }
    );
  }
}
