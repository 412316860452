<div
  class="row"
  *ngFor="let teamProjectForm of teamProjectFormList; let i = index"
>
  <label class="col-sm-12 control-label">{{
    teamProjectForm.label | translate
  }}</label>
  <div class="col-md-12 mt-4">
    <app-approval-path
      [model]="approvalPathList[i]"
      (onChoose)="doOnChoose(i)"
    ></app-approval-path>
  </div>
</div>
