import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PopupChooseVendorMultiWinnerView } from '../../../view/entity/bean/popup-choose-vendor-multi-winner-view';
import { AppPopupService } from '../app-popup.service';
import { AppPopupChooseVendorMultiWinnerComponent } from './app-popup-choose-vendor-multi-winner.component';

@Injectable()
export class AppPopupChooseVendorMultiWinnerService {
  constructor(public appPopupService: AppPopupService) {}

  open(
    procurementItemId: number,
    todo: string,
    isShowChangeWinningVendor: boolean,
    popupChooseVendorMultiWinnerViewList?: PopupChooseVendorMultiWinnerView[],
    isRefute?: boolean
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupChooseVendorMultiWinnerComponent,
      {
        popupChooseVendorMultiWinnerViewList,
        procurementItemId,
        todo,
        isShowChangeWinningVendor,
        isRefute
      },
      { size: 'xl' }
    );
  }
}
