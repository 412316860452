import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Guarantee } from 'src/app/core/bean/guarantee';
import { GuaranteeBondType } from 'src/app/core/bean/guarantee-bond-type';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './app-popup-guarantee.component.html'
})
export class AppPopupGuaranteeComponent extends BaseComponentComponent {
  @Input() public todo: string;
  @Input() public amountSource: number;
  @Input() public guaranteeEdit: Guarantee = new Guarantee();
  @Input() public guaranteeList: Guarantee[] = [];
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public guaranteeBondTypeOptionList: OptionListModel<GuaranteeBondType> =
    new OptionListModel(true);
  public guaranteeBondTypeList: GuaranteeBondType[] = [];
  public amount: number;
  public currentDate: Date = new Date();

  public GUARANTEE_PERCENTAGE_RULE_EXACT = 1;
  public GUARANTEE_PERCENTAGE_RULE_MINIMUM = 2;
  public GUARANTEE_PERCENTAGE_RULE_MAXIMUM = 3;

  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    super('app-popup-guarantee');
  }

  onInit(): void {
    this.setOptionListModel();
    this.buildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setOptionListModel(): void {
    this.httpClientService
      .get<GuaranteeBondType[]>('/app-popup-guarantee/get-option-list')
      .subscribe(response => {
        if (response != null) {
          this.guaranteeBondTypeList = response;
          this.guaranteeBondTypeOptionList.setRequestValues(
            this.guaranteeBondTypeList
          );
          this.setStateReady();
          this.setGuaranteeBondTypeList();
        }
      });
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      guaranteeBondType: [null, Validators.compose([Validators.required()])],
      guarantorName: [null, Validators.compose([Validators.required()])],
      percentageValue: [
        null,
        Validators.compose([Validators.required(), Validators.max(100)])
      ],
      submissionDate: [null, Validators.compose([Validators.required()])],
      date: [null, Validators.compose([Validators.required()])],
      startDate: [null],
      endDate: [null],
      currentDate: this.currentDate,
      endDateValidate: [null],
      description: [null],
      amount: [null]
    });
  }

  public setFormGroup(): void {
    if (this.todo !== 'add') {
      const startDate: Date = new Date(this.guaranteeEdit.startDate);
      const endDate: Date = new Date(this.guaranteeEdit.endDate);
      const submissionDate: Date = new Date(this.guaranteeEdit.submissionDate);
      this.formGroup.patchValue({
        id: this.guaranteeEdit.id,
        guaranteeBondType: this.guaranteeEdit.guaranteeBondType,
        guarantorName: this.guaranteeEdit.guarantorName,
        percentageValue: this.guaranteeEdit.percentageValue,
        description: this.guaranteeEdit.description,
        submissionDate,
        date: {
          from: startDate,
          to: endDate
        },
        startDate,
        endDate,
        currentDate: this.currentDate,
        amount: this.guaranteeEdit.amount
      });
      this.amount = this.guaranteeEdit.amount;
      this.onChangeGuaranteeBondType();
    }
  }

  public setGuaranteeBondTypeList(): void {
    if (this.todo === 'add') {
      const guaranteeBondTypeList = this.guaranteeBondTypeList.filter(
        (guaranteeBondType: GuaranteeBondType) =>
          this.guaranteeList.every(
            guarantee => guarantee.guaranteeBondType.id !== guaranteeBondType.id
          )
      );
      this.guaranteeBondTypeOptionList.setRequestValues(guaranteeBondTypeList);
    } else {
      const guaranteeBondTypeList = this.guaranteeBondTypeList.filter(
        (guaranteeBondType: GuaranteeBondType) =>
          this.guaranteeList.every(
            (guarantee: Guarantee) =>
              guarantee.guaranteeBondType.id !== guaranteeBondType.id ||
              guarantee.guaranteeBondType.id ===
                this.guaranteeEdit.guaranteeBondType.id
          )
      );
      this.guaranteeBondTypeOptionList.setRequestValues(guaranteeBondTypeList);
    }
  }

  public onInputPercentage(): void {
    const percentage = this.formGroup.get('percentageValue').value;
    const amount = +this.amountSource * (+percentage / 100);
    this.formGroup.get('amount').patchValue(amount);
    this.amount = amount;
  }

  public onChangeGuaranteeBondType(): void {
    const guaranteeBondType: GuaranteeBondType =
      this.formGroup.get('guaranteeBondType').value;
    if (guaranteeBondType && !this.isViewOnly) {
      const percentage = guaranteeBondType.percentageValue;
      this.formGroup.get('percentageValue').patchValue(percentage);
      this.onInputPercentage();

      if (
        guaranteeBondType.percentageRule ===
        this.GUARANTEE_PERCENTAGE_RULE_EXACT
      ) {
        this.formGroup.get('percentageValue').setIsView(true);
      } else if (
        guaranteeBondType.percentageRule ===
        this.GUARANTEE_PERCENTAGE_RULE_MINIMUM
      ) {
        this.formGroup.get('percentageValue').setIsView(false);
        this.formGroup.get('percentageValue').clearValidators();
        this.formGroup
          .get('percentageValue')
          .setValidators([
            Validators.required(),
            Validators.min(guaranteeBondType.percentageValue)
          ]);
        this.formGroup.get('percentageValue').updateValueAndValidity();
      } else if (
        guaranteeBondType.percentageRule ===
        this.GUARANTEE_PERCENTAGE_RULE_MAXIMUM
      ) {
        this.formGroup.get('percentageValue').setIsView(false);
        this.formGroup.get('percentageValue').clearValidators();
        this.formGroup
          .get('percentageValue')
          .setValidators([
            Validators.required(),
            Validators.max(guaranteeBondType.percentageValue)
          ]);
        this.formGroup.get('percentageValue').updateValueAndValidity();
      }
    }
  }

  public doSave(): void {
    this.formGroup.markAllAsTouched();
    this.formGroup.markAsDirty();
    this.validate();
    if (this.formGroup.valid) {
      this.formGroup.patchValue({
        startDate: this.formGroup.value.date.from,
        endDate: this.formGroup.value.date.to
      });
      this.onChange.emit(this.formGroup.value);
    }
  }
}
