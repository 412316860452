import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Region } from '../../core/bean/region';
import { RfiVendorNonPromise } from '../../core/bean/rfi-vendor-non-promise';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';
import { RfiDTO } from './dto/rfi.dto';
@Component({
  selector: 'app-popup-vendor-non-promise',
  templateUrl: './app-popup-vendor-non-promise.component.html'
})
export class AppPopupVendorNonPromiseComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() public rfiVendorNonPromiseEdit: RfiVendorNonPromise;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public rfiDTO: RfiDTO = new RfiDTO();
  public countryCode: any;
  public regionList: Region[] = [];
  public regionPhoneOptionList: OptionListModel<Region> = new OptionListModel(
    true
  );
  constructor() {
    super('app-poup-vendor-non-promise');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required()])],
      email: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.email(),
          Validators.maxLength(64)
        ])
      ],
      phone: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      address: [null, Validators.compose([Validators.required()])],
      submitedDate: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<RfiDTO>('/request-for-information/popup-vendor-non-promise', null)
      .subscribe(rfiDTO => {
        this.rfiDTO = rfiDTO;
        this.regionPhoneOptionList.setRequestValues(rfiDTO.regionPhoneList);
        if (this.rfiVendorNonPromiseEdit != null) {
          this.formGroup.patchValue({
            name: this.rfiVendorNonPromiseEdit.name,
            email: this.rfiVendorNonPromiseEdit.email,
            phone: {
              number: this.rfiVendorNonPromiseEdit.phone,
              country: this.rfiVendorNonPromiseEdit.phoneRegion
            },
            address: this.rfiVendorNonPromiseEdit.address,
            submitedDate: this.rfiVendorNonPromiseEdit.submittedDate
          });
        }
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup.value);
    }
  }
}
