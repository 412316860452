import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppStepperModule } from '../app-stepper/app-stepper.module';
import { AppWorkflowStepInfoComponent } from './app-workflow-step-info.component';
@NgModule({
  imports: [VendorModule, AppStepperModule],
  declarations: [AppWorkflowStepInfoComponent],
  exports: [AppWorkflowStepInfoComponent]
})
export class AppWorkflowStepInfoModule {}
