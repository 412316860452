<app-popup header="app-popup-email.title">
  <form [formGroup]="formGroup" class="p-3">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="sendemailto">{{ 'app-popup-email.to'
          | translate }}</span>
      </div>
      <div class="col p-0">
        <app-text-field autofocus="true" size="XL" formControlName="to"
          maxLength="64"></app-text-field>
      </div>
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="sendemailcc">{{ 'app-popup-email.cc'
          | translate }}</span>
      </div>
      <div class="col p-0">
        <app-text-tagging size="XL" formControlName="ccList" maxLength="255">
        </app-text-tagging>
      </div>
    </div>
    <div class="input-group mb-3">
      <div class="input-group-prepend d-inline-block">
        <span class="input-group-text" id="sendemailsubject">{{
          'app-popup-email.subject' | translate }}</span>
      </div>
      <div class="col p-0">
        <app-text-field size="XL" formControlName="subject" maxLength="255">
        </app-text-field>
      </div>
    </div>
    <app-wysiwyg formControlName="content" size="XL"></app-wysiwyg>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
        'app.button.close' | translate }}</app-button>
      <app-button (onClick)="doSend()">{{ 'app.button.send' | translate }}
      </app-button>
    </div>
  </ng-template>
</app-popup>