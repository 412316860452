<app-widget [header]="moduleCode + '.title'" *ngIf="!formLoading">
  <p *ngIf="vendorStatusInformationList == null">{{
    'app-widget-vendor-status-information.noData' | translate }}</p>
  <app-widget-content-slider [isShowControl]="vendorStatusInformationList.length > 5"
    *ngIf="vendorStatusInformationList != null">
    <app-widget-card *ngFor="let vendorStatusInformation of vendorStatusInformationList"
      [header]="vendorStatusInformation.vendorStatus.name">
      <em class="fas fa-2x mr-2" [ngClass]="vendorStatusIconList[vendorStatusInformation.vendorStatus.code]"></em>
      <span class="h3 m-0">{{ vendorStatusInformation.numberOfVendor }}</span>
      <div class="widget-card-footer mt-2">
        <p class="m-0 cursor-pointer more-detail"
          (click)="goToMoreDetail(vendorStatusInformation.targetUrl, vendorStatusInformation.vendorStatus.code)"
          (keyup)="onKeyUp($event)" (keydown)="onKeyDown($event)">
          {{ 'app-widget-vendor-status-information.moreDetail' | translate }}
          <!-- <em class="fas fa-chevron-circle-right"></em> -->
        </p>
      </div>
    </app-widget-card>
  </app-widget-content-slider>
</app-widget>