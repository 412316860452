import { EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TreeItem } from '../interface/tree-item';
import { TreeItemListModel } from './tree-item-list-model';
import { TreeItemModel } from './tree-item-model';
import { TreeModeType } from './tree-mode-type';
import { TreeProcessModel } from './tree-process-model';
export class TreeModel {
  formGroup: FormGroup = new FormGroup({});
  treeList: Array<TreeItemListModel> = new Array();
  currentIndexTree: number;
  currentIndexItemInTree: number;
  treeProcess: TreeProcessModel = new TreeProcessModel();
  checkedTreeItemListTemp: Array<TreeItemModel> = new Array();
  onlyLastChild: boolean;
  limitLevel: number;
  disableRootButton: boolean;
  isLoading = false;
  requestReload: EventEmitter<void> = new EventEmitter();
  constructor(
    public moduleCode?: string,
    public mode?: TreeModeType,
    public parentId?: number,
    public isMultiple: boolean = false
  ) {}

  buildTreeItemList(): void {
    const treeItemList: TreeItemListModel = new TreeItemListModel();
    treeItemList.setStateLoading();
    this.treeList.push(treeItemList);
  }

  setItemList(itemList: Array<TreeItem>): void {
    const treeItemList: Array<TreeItemModel> = new Array();
    itemList.forEach((item: TreeItem) => {
      const treeItem: TreeItemModel = new TreeItemModel(item);
      this.setIsChecked(treeItem);
      treeItemList.push(treeItem);
    });
    const lastIndexOfTreeList = this.treeList.length - 1;
    this.treeList[lastIndexOfTreeList].setTreeItemList(treeItemList);
    this.treeList[lastIndexOfTreeList].isNoData = treeItemList.length === 0;
    this.treeList[lastIndexOfTreeList].setStateReady();
  }

  setCurrentIndex(currentIndex: number, currentIndexItemInTree?: number): void {
    this.currentIndexTree = currentIndex;
    this.currentIndexItemInTree = currentIndexItemInTree;
  }

  addItemToTreeItemList(treeItem: TreeItemModel): void {
    this.treeList[this.currentIndexTree].addTreeItem(treeItem);
    this.treeList[this.currentIndexTree].isNoData =
      this.treeList[this.currentIndexTree].treeItemList.length === 0;
  }

  getCurrentTreeItem(): TreeItemModel {
    return this.treeList[this.currentIndexTree].treeItemList[
      this.currentIndexItemInTree
    ];
  }

  clearSelectedItemByIndex(index: number): void {
    this.treeList[index].resetTreeItemSelected();
  }

  getIndexOfCurrentParentItem(): number {
    let indexOfParent = -1;
    if (this.currentIndexTree) {
      indexOfParent =
        this.treeList[this.currentIndexTree - 1].getIndexOfSelectedItem();
    }
    return indexOfParent;
  }

  removeCurrentItem(): void {
    this.treeList[this.currentIndexTree].removeAt(this.currentIndexItemInTree);
  }

  removeAt(index: number): void {
    this.treeList.splice(index);
  }

  reset(): void {
    this.treeList.splice(0);
    this.currentIndexTree = null;
    this.currentIndexItemInTree = null;
    this.formGroup.reset();
    this.treeProcess.reset();
    this.requestReload.emit();
  }

  setCurrentValue(currentValue: Array<any>): void {
    this.checkedTreeItemListTemp = currentValue;
  }

  get value(): Array<TreeItemModel> {
    if (this.isMultiple) {
      return this.checkedTreeItemListTemp;
    } else {
      const treeItemList: Array<TreeItemModel> = new Array();
      this.treeList.forEach((tree: TreeItemListModel) => {
        treeItemList.push(tree.getSelectedItem());
      });
      return treeItemList.filter(treeItem => treeItem);
    }
  }

  public setIsChecked(treeItem: TreeItemModel): void {
    if (treeItem.id) {
      treeItem.isChecked =
        this.checkedTreeItemListTemp.findIndex(
          checkedTreeItem => checkedTreeItem.id === treeItem.id
        ) !== -1;
    }
  }

  public setStateLoading(): void {
    this.isLoading = true;
  }

  public setStateReady(): void {
    this.isLoading = false;
  }
}
