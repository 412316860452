import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  templateUrl: './app-widget-top-vendor-performance.component.html',
  styleUrls: ['./app-widget-top-vendor-performance.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetTopVendorPerformanceComponent extends BaseComponentComponent {
  public topPerformanceVendorList: Array<any> = new Array();
  constructor() {
    super('app-widget-top-vendor-performance');
  }

  public onInit(): void {
    this.getAndSetTopVendor();
  }

  public getAndSetTopVendor(): void {
    this.httpClientService.get('/widget-top-vendor-performance/index').subscribe((response: any) => {
      this.topPerformanceVendorList = response;
      this.global.widgetLoadingStatus.set(this.moduleCode, true);
      this.setStateReady();
    });
  }
}
