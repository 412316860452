import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../../../base/angular/base-component.component';
import { OptionListModel } from '../../../../model/option-list-model';
import { TableColumnModel } from '../../model/table-column-model';
import { TableResponseModel } from '../../model/table-response-model';
@Component({
  templateUrl: './app-popup-modify-table.component.html',
  styleUrls: ['./app-popup-modify-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupModifyTableComponent extends BaseComponentComponent {
  @Input() tableParentResponse: TableResponseModel<any>;
  @Input() columnList: Array<TableColumnModel>;
  @Input() columnFieldList: Array<any>;

  @Output() onChange: EventEmitter<Array<string>> = new EventEmitter();
  @Output() onClose: EventEmitter<void> = new EventEmitter();

  public optionList: OptionListModel<{
    id: number;
    code: string;
    field: string;
    isChecked: boolean;
  }> = new OptionListModel(false, 'code');

  public tableColumn: FormControl = new FormControl(null);
  constructor(public translateService: TranslateService) {
    super('app-popup-modify-table');
  }

  public onInit(): void {
    this.setOptionListState();
    this.setStateTableColumn();
  }

  public setOptionListState(): void {
    const columnList = Array.from(this.columnList);
    const isCheckedAll = this.columnFieldList.length === 0;
    const optionList: any = columnList.map(
      (column: TableColumnModel, index: number) => {
        return {
          id: index + 1,
          code: this.tableParentResponse.moduleCode + '.' + column.header,
          field: column.field,
          isChecked:
            isCheckedAll ||
            this.columnFieldList.findIndex(field => field === column.field) !==
              -1
        };
      }
    );
    this.optionList.setRequestValues(optionList);
  }

  private setStateTableColumn(): void {
    this.tableColumn.patchValue(
      this.optionList.requestValues.filter(option => option.isChecked)
    );
  }

  public doSave(): void {
    this.onChange.emit(this.tableColumn.value.map(column => column.field));
  }
}
