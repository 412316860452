<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      [header]="header"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'all-performance.form.vendorName' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ vendor.name }}
          </div>
          <div class="col-sm-4 text-left">
            <app-button (onClick)="doViewProfile()">Profile</app-button>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'all-performance.form.vendorCode' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ vendor.code }}
          </div>
        </div>
      

      <br />

      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/all-performance/index-performance"
      >
        <ng-template #actionButtons let-rowData>
          <app-button size="TN" (onClick)="doView(rowData)">
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>

      <app-fieldset legend="all-performance.performanceResult">
        <div
          class="table-responsive custom-table float-none"
          [ngStyle]="{ margin: '0 auto' }"
        >
          <table
            class="table table-striped box-shadow"
            aria-describedby="Table"
          >
            <thead>
              <tr class="text-center">
                <th scope="col">{{ 'app.table.column.no' | translate }}</th>
                <th scope="col">{{
                  'all-performance.table.column.performance' | translate
                }}</th>
                <th scope="col">{{
                  'all-performance.table.column.weight' | translate
                }}</th>
                <th scope="col">{{
                  'all-performance.table.column.result' | translate
                }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let data of vendorPerformanceOverallDetailList;
                  let i = index
                "
              >
                <td class="text-center">{{ i + 1 }}.</td>
                <td class="text-center"
                  >{{ data.moduleScoringCriteria.name }}
                </td>
                <td class="text-center">{{ data.weight }} % </td>
                <td class="text-center">{{ data.result | number: '1.2-2' }}</td>
              </tr>
              <tr>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"><strong>{{ rating | number: '1.2-2' }}</strong></td>
              </tr>
              <tr>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center">
                  <app-rating formControlName="rating" [isViewOnly]="true"></app-rating>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </app-fieldset>

      <div class="button-group button-group-center">
        <app-button mode="BYPASS" color="SECONDARY" (onClick)="doBack()">
          {{ 'app.button.back' | translate }}
        </app-button>
      </div>

    </form>
    </app-card>
  </div>
</div>
