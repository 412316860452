import {
  Component,
  ElementRef,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { take } from 'rxjs/operators';
import * as uploadAction from '../../actions/upload-file.action';
import {
  BaseUploadComponent,
  makeUploaderProvider
} from '../../base/base-upload.component';
import { UploadDragAndDropEvent } from '../../events/upload-drag-and-drop-event';
import { UploaderHelpers } from '../../helpers/upload-helpers';
import { UploadMapper } from '../../mapper/upload-mapper';
import { Uploader } from '../../model/uploader';
import { UploaderFile } from '../../model/uploader-file';
import { UploadService } from '../../upload.service';
@Component({
  selector: 'app-upload-multiple',
  templateUrl: './app-upload-multiple.component.html',
  styleUrls: ['./app-upload-multiple.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [...makeUploaderProvider(AppUploadMultipleComponent)]
})
export class AppUploadMultipleComponent extends BaseUploadComponent {
  public isDragOver: boolean;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    uploadService: UploadService
  ) {
    super('app-upload-multiple', controlContainer, elementRef, uploadService);
  }

  public onInitBaseUpload(): void {
    this.uploadService
      .setUploader(this.model, this.formControl)
      .subscribe((uploader: Uploader) => {
        const values = UploadMapper.toValues(uploader);
        this.formControl.patchValue(values);
        this.doSetFileListToFileUploader();
        this.onChange.emit(values);
      });
  }

  public onUploadChange(event: any, index?: number): void {
    typeof index !== 'undefined'
      ? this.uploadService.dispatch(
          new uploadAction.ChangeFile({ fileList: event.target.files, index })
        )
      : this.uploadService.dispatch(
          new uploadAction.AddFile({ fileList: event.target.files })
        );
    event.target.value = null;
  }

  public doDeleteFile(index?: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          typeof index !== 'undefined'
            ? this.uploadService.dispatch(
                new uploadAction.DeleteFile({ index })
              )
            : this.uploadService.dispatch(new uploadAction.BulkDeleteFile());
        }
      });
  }

  public doCancelUpload(uploaderFile: UploaderFile): void {
    this.uploadService.dispatch(
      new uploadAction.CancelUpload({ uploaderFile })
    );
  }

  public doReupload(uploaderFile: UploaderFile): void {
    this.uploadService.dispatch(new uploadAction.ReUpload({ uploaderFile }));
  }

  public onDragAndDropChange(
    uploadDragAndDropEvent: UploadDragAndDropEvent
  ): void {
    switch (uploadDragAndDropEvent.type) {
      case 'ON-DRAGOVER':
        this.isDragOver = true;
        break;
      case 'ON-DRAGLEAVE':
        this.isDragOver = false;
        break;
      case 'ON-DROP':
        this.isDragOver = false;
        this.uploadService.dispatch(
          new uploadAction.AddFile({
            fileList: uploadDragAndDropEvent.fileList
          })
        );
        break;
      default:
        break;
    }
  }

  public doDownloadFile(event: MouseEvent, uploaderFile: UploaderFile): void {
    event.preventDefault();
    event.stopPropagation();
    this.downloadService.download(
      UploaderHelpers.getFileUrlWithFileUploader(this.global, uploaderFile),
      uploaderFile.file.fileName
    );
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
