import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserView } from 'src/app/core/view/entity/bean/user-view';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { User } from '../../core/bean/user';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './user-action.component.html'
})
export class UserActionComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<User>;
  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('user-action', translateService);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      organizationName: [null],
      organizationId: [null]
    });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      {
        field: 'organizationName',
        header: 'table.column.organization',
        customClass: 'white-space-normal'
      },
      {
        field: 'roleText',
        header: 'table.column.role',
        format: FieldFormatEnum.Html
      },
      {
        field: 'lastAction',
        header: 'table.column.logActionDate',
        format: FieldFormatEnum.ShortDateAndTime
      }
    ]);
  }

  public doView(userView: UserView): void {
    this.httpClientService
      .get<User>('/user/get-user/' + userView.id)
      .subscribe(user => {
        this.global.routerParams.clear();
        this.global.routerParams.set('user', user);
        this.router.navigate(['/pages/user-action/view/']);
      });
  }

  public onChooseOrganization(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(organization => {
        const { name: organizationName, id: organizationId } =
          organization.pop();
        this.formGroup.patchValue({ organizationName, organizationId });
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
      });
  }
}
