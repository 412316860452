import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupAnalysisSingleComponent } from './app-popup-analysis-single.component';

@Injectable()
export class AppPopupAnalysisSingleService {
  constructor(public appPopupService: AppPopupService) {}

  public open(
    procurementVendorId: number,
    parentModuleCode: string
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupAnalysisSingleComponent,
      {
        procurementVendorId,
        parentModuleCode
      },
      { size: 'xl' }
    );
  }
}
