import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { ItemType } from 'src/app/core/bean/item-type';
import { Rfq } from 'src/app/core/bean/rfq';
import { RfqItem } from 'src/app/core/bean/rfq-item';
import { RfqStatus } from 'src/app/core/bean/rfq-status';
import { RfqVendor } from 'src/app/core/bean/rfq-vendor';
import { AppPopupChooseVendorService } from 'src/app/core/components/app-popup/app-popup-choose-vendor/app-popup-choose-vendor.service';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { Validators } from 'src/app/core/validators';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { AppPopupChooseRfqItemComponent } from './app-popup-choose-rfq-item.component';
import { RfqRequest } from './request/rfq.request';
import { RfqItemAndVendorResponse } from './response/rfq-item-and-vendor.response';
import { RfqResponse } from './response/rfq.response';
@Component({
  templateUrl: './rfq-edit-add.component.html',
  encapsulation: ViewEncapsulation.None
})
export class RfqEditAddCompenent extends BaseModuleComponent implements OnInit {
  public rfqId: number;
  public objectId: number; /** for worklist-approval */
  public approvalModelPrcsId: number;
  public rfqResponse: RfqResponse;
  public tableResponseVendor: TableResponseModel<any>;
  public tableResponseItem: TableResponseModel<RfqItem>;
  public prIdList: number[] = [];
  public rfqItemList: RfqItem[] = [];
  public isFromRfp = false;
  public CURRENCY_DIGITS_INFO: string;
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    false
  );
  public rfqItemVendorResponse: RfqItemAndVendorResponse =
    new RfqItemAndVendorResponse();
  public fileUploader: FileUploader = new FileUploader(
    '/rfq/',
    '',
    this.global.appConstant.fileType.DOC_RFQ_TEMPLATE,
    false,
    this.global.config.parameterModel.maxFileRfq
  );
  public vendorList = [];
  public currentDate = new Date();
  public urlBackOutside: string;

  public badgeColor = {
    DRAFT: 'SECONDARY',
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    WAITING_RESPONSE: 'GOOD',
    CLOSED_RESPONSE: 'DANGER',
    CANCELED: 'DANGER',
    COMPLETED: 'SUCCESS',
    DONE: 'SUCCESS'
  };

  constructor(
    translateService: TranslateService,
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public appPopupChooseVendorService: AppPopupChooseVendorService,
    public appPopupService: AppPopupService,
    public currency: CurrencyPipe,
    public snackbarService: SnackbarService
  ) {
    super('rfq', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildTableResponseVendor();
    this.buildFormGroup();
    this.setFormGroup();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.rfqId =
      localStorage.getItem('rfqId') || this.global.routerParams.get('rfqId');
    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.rfqId = this.objectId;
    }
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );

    localStorage.removeItem('todo');
    localStorage.removeItem('rfqId');
    localStorage.removeItem('urlBackOutside');
  }

  public buildTableResponseVendor(): void {
    this.tableResponseItem = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'prItem.prItemImageList',
          header: 'table.column.thumbnail',
          plugins: [
            {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): File => {
                if (tablePluginData.value && tablePluginData.value.length > 0) {
                  return tablePluginData.value[0].file;
                }
                return null;
              }
            },
            {
              name: 'img',
              classNameMap: {
                MATERIAL: 'pvr pv-box',
                JASA: 'pvr pv-tools'
              },
              fieldClassName: 'prItem.item.itemType.code'
            }
          ],
          isSortable: false,
          isSearchTable: false
        },
        {
          field: 'prItem.pr.code',
          header: 'table.column.prNumber',
          plugins: { name: 'hyperlink', onClick: this.doViewItem.bind(this) }
        },
        {
          field: 'prItem.code',
          header: 'table.column.prLine'
        },
        {
          field: 'prItem.pr.requiredDate',
          header: 'table.column.requiredDate',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'prItem.pr.organization.name',
          header: 'table.column.departement'
        },
        {
          field: 'prItem.item.name',
          header: 'table.column.itemName'
        },

        {
          field: 'prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'prItem.item.itemType.code'
          }
        },
        {
          field: 'prItem.quantity',
          header: 'table.column.quantity',
          className: 'text-right',
          plugins: {
            name: 'custom-plugin',
            before: (data: TablePluginData): string => {
              return this.currency.transform(
                +data.value,
                '',
                '',
                this.CURRENCY_DIGITS_INFO
              );
            }
          }
        },
        {
          field: 'prItem.price',
          header: 'table.column.price',
          className: 'text-right',
          plugins: {
            name: 'default',
            type: 'currency'
          }
        },
        {
          field: 'prItem.totalPrice',
          header: 'table.column.totalPrice',
          className: 'text-right',
          plugins: {
            name: 'default',
            type: 'currency'
          }
        }
      ],
      { checkBoxFn: this.isShowCheckBox.bind(this) }
    );
    this.tableResponseVendor = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.vendorName'
      },
      {
        field: 'address',
        header: 'table.column.address'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      },
      {
        field: 'userRefer',
        header: 'table.column.userRefer',
        dataMap:
          '{"true":"' +
          this.global.translateService.instant('rfq.userRefer.yes') +
          '","false":"-"}'
      },
      {
        field: 'averageRatingOverall',
        header: 'table.column.performance',
        plugins: {
          name: 'rating',
          isView: true
        }
      }
    ]);
  }

  public isShowCheckBox(): boolean {
    let isShowCheckBox;
    isShowCheckBox = this.isFromRfp ? false : true;
    return isShowCheckBox;
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      title: [null, Validators.required()],
      responsePeriod: [null, Validators.required()],
      template: [null, Validators.required()],
      note: [null, Validators.required()],
      itemType: null,
      amount: [null],
      goodsAmount: [null],
      serviceAmount: [null],
      agreementType: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<RfqResponse>(
        '/rfq/add-edit?id=' +
          (this.rfqId ? this.rfqId : '') +
          '&todo=' +
          this.todo,
        {}
      )
      .subscribe((response: RfqResponse) => {
        this.rfqResponse = response;
        if (this.todo === 'edit') {
          this.fileUploader.setFileList(this.rfqResponse.fileList);
          this.formGroup.patchValue({
            id: this.rfqResponse.rfq.id,
            code: this.rfqResponse.rfq.code,
            title: this.rfqResponse.rfq.title,
            responsePeriod: {
              from: this.rfqResponse.rfq.startDate,
              to: this.rfqResponse.rfq.endDate
            },
            template: this.fileUploader.fileObjectList,
            note: this.rfqResponse.rfq.note,
            amount: this.rfqResponse.rfq.amount,
            goodsAmount: this.rfqResponse.rfq.goodsAmount,
            serviceAmount: this.rfqResponse.rfq.serviceAmount,
            agreementType: this.rfqResponse.rfq.agreementType
          });
          this.rfqItemList = this.rfqResponse.rfqItemList;
          if (
            this.rfqResponse.rfqItemList[0].rfpItem &&
            this.rfqResponse.rfqItemList[0].rfpItem.id
          ) {
            this.isFromRfp = true;
          }
          this.tableResponseItem.setRecordList(this.rfqResponse.rfqItemList);
          this.tableResponseItem.reload();
          this.rfqItemVendorResponse.rfqItemList = this.rfqResponse.rfqItemList;
          this.tableResponseVendor.setRecordList(
            this.rfqResponse.vendorViewList
          );
          this.vendorList = this.rfqResponse.vendorViewList;
          this.tableResponseVendor.reload();
        }
        this.itemTypeOptionList.setRequestValues(this.rfqResponse.itemTypeList);
        this.setStateReady();
      });
  }

  public setDecimal(field: number): string {
    console.log(
      this.currency.transform(+field, '', '', this.CURRENCY_DIGITS_INFO)
    );
    return this.currency.transform(+field, '', '', this.CURRENCY_DIGITS_INFO);
  }

  public doAddItemList(): void {
    const rfqItemTempList = this.rfqItemVendorResponse.rfqItemList;
    const isFromRfp = this.isFromRfp;
    this.appPopupService
      .open(
        AppPopupChooseRfqItemComponent,
        {
          isFromRfp,
          rfqItemList: rfqItemTempList,
          baseModuleCode: this.moduleCode
        },
        { size: 'xl' }
      )
      .subscribe((rfqItemList: RfqItem[]) => {
        let rfpId: number;
        if (rfqItemList[0].rfpItem && rfqItemList[0].rfpItem.rfp.id) {
          rfpId = rfqItemList[0].rfpItem.rfp.id;
          this.isFromRfp = true;
        } else {
          this.prIdList = rfqItemList.map(rfqItem => {
            return rfqItem.prItem.pr.id;
          });
          this.isFromRfp = false;
        }
        this.setStateProcessing();
        const url =
          '/rfq/get-item-and-vendor?prIdList=' +
          (this.prIdList && this.prIdList.length > 0 && !this.isFromRfp
            ? this.prIdList.toString()
            : '') +
          '&rfpId=' +
          (rfpId ? rfpId : '');
        this.httpClientService
          .get<RfqItemAndVendorResponse>(url)
          .subscribe((response: RfqItemAndVendorResponse) => {
            this.rfqItemVendorResponse = response;
            if (this.isFromRfp) {
              if (
                this.rfqResponse.rfqItemList &&
                this.rfqResponse.rfqItemList.length > 0
              ) {
                this.rfqItemVendorResponse.rfqItemList.forEach(rfqItem => {
                  const index = this.rfqResponse.rfqItemList.findIndex(
                    rfqItemExist => rfqItemExist.prItem.id === rfqItem.prItem.id
                  );
                  if (index !== -1 && this.rfqItemList[index].id) {
                    rfqItem.id = this.rfqItemList[index].id;
                    rfqItem.crudOperation =
                      this.global.appConstant.core.CRUD_OPERATION_UPDATE;
                  }
                });
              }
              this.tableResponseItem.setRecordList(
                this.rfqItemVendorResponse.rfqItemList
              );
              this.tableResponseVendor.setRecordList(
                this.rfqItemVendorResponse.popupVendorViewList
              );
            } else {
              if (
                this.rfqResponse.rfqItemList &&
                this.rfqResponse.rfqItemList.length > 0
              ) {
                rfqItemList.forEach(rfqItem => {
                  const index = this.rfqResponse.rfqItemList.findIndex(
                    rfqItemExist => rfqItemExist.prItem.id === rfqItem.prItem.id
                  );
                  if (index !== -1 && this.rfqItemList[index].id) {
                    rfqItem.id = this.rfqItemList[index].id;
                    rfqItem.crudOperation =
                      this.global.appConstant.core.CRUD_OPERATION_UPDATE;
                  }
                });
              }
              this.rfqItemVendorResponse.rfqItemList = rfqItemList;
              this.tableResponseItem.setRecordList(rfqItemList);
            }
            this.doSetAmount();
            this.tableResponseItem.reload();
            this.tableResponseVendor.reload();
            // this.vendorList = [...this.vendorList, ...this.rfqItemVendorResponse.popupVendorViewList];
            this.setStateReady();
          });
      });
  }

  public doDeleteItem(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.log.debug(event.selectedRecordList);
          const checkedIdList: number[] = (
            event.selectedRecordList as Array<RfqItem>
          ).map(record => record.prItem.id);

          this.tableResponseItem.setRecordList(
            this.tableResponseItem
              .getRecordList()
              .filter(rfqItem => !checkedIdList.includes(rfqItem.prItem.id))
          );
          this.tableResponseItem.reload();
          this.rfqItemVendorResponse.rfqItemList =
            this.rfqItemVendorResponse.rfqItemList.filter(
              rfqItem => !checkedIdList.includes(rfqItem.prItem.id)
            );
          this.global.alertService.showSuccessDelete();
        }
      });
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public doViewItem(rfqItem: RfqItem): void {
    this.appPopupPrItemInfoService.open(rfqItem.prItem.id, true);
  }

  public onChangeItemType(): void {
    const itemType = this.formGroup.value.itemType;
    if (itemType) {
      const rfqItemList = [];
      this.rfqItemVendorResponse.rfqItemList.forEach((rfqItem: RfqItem) => {
        if (rfqItem.prItem && rfqItem.prItem.item.itemType.id === itemType.id) {
          rfqItemList.push(rfqItem);
        }
      });
      this.tableResponseItem.setRecordList(rfqItemList);
    } else {
      this.tableResponseItem.setRecordList(
        this.rfqItemVendorResponse.rfqItemList
      );
    }
    this.tableResponseItem.reload();
    this.doSetAmount();
  }

  public doSetAmount(): void {
    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;
    this.tableResponseItem.getRecordList().forEach(rfqItem => {
      if (
        rfqItem.prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      ) {
        goodsAmountTotal = +goodsAmountTotal + +rfqItem.prItem.totalPrice;
      }
      if (
        rfqItem.prItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
      ) {
        serviceAmountTotal = +serviceAmountTotal + +rfqItem.prItem.totalPrice;
      }
    });
    this.formGroup.patchValue({
      goodsAmount: goodsAmountTotal,
      serviceAmount: serviceAmountTotal,
      amount: +goodsAmountTotal + +serviceAmountTotal
    });
  }

  public doInviteVendor(): void {
    const prIdlist: number[] = []; /* reference */
    this.rfqItemVendorResponse.rfqItemList.forEach(rfqItem => {
      prIdlist.push(rfqItem.prItem.pr.id);
    });
    const vendorList = this.tableResponseVendor.getRecordList();
    /* excludes */
    const excludeVendorIdList =
      this.rfqItemVendorResponse.vendorNotPassedList.map(vendor => vendor.id);

    this.appPopupChooseVendorService
      .openRfpRfqRfi(vendorList, prIdlist, excludeVendorIdList)
      .subscribe(vendorList => {
        this.vendorList = vendorList;
        this.tableResponseVendor.setRecordList(this.vendorList);
        this.tableResponseVendor.reload();
      });
  }

  public doDeleteVendor(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.log.debug(event.selectedRecordList);
          const checkedIdList: number[] = (
            event.selectedRecordList as Array<PopupVendorView>
          ).map(record => record.id);
          this.vendorList = this.vendorList.filter(
            vendor => !checkedIdList.includes(vendor.id)
          );
          this.tableResponseVendor.setRecordList(
            this.tableResponseVendor
              .getRecordList()
              .filter(vendor => !checkedIdList.includes(vendor.id))
          );
          this.tableResponseVendor.reload();
          this.global.alertService.showSuccessDelete();
        }
      });
  }

  public onClickVendor(vendor: PopupVendorView): void {
    console.log(vendor);
  }

  public doSave(isSubmit: boolean) {
    this.validate();
    if (
      this.formGroup.valid &&
      this.rfqItemVendorResponse.rfqItemList.length > 0 &&
      ((isSubmit && this.tableResponseVendor.getRecordList().length > 0) ||
        !isSubmit)
    ) {
      if (isSubmit) {
        this.global.modalService
          .submitConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.doSubmit(isSubmit);
            }
          });
      } else {
        this.doSubmit(isSubmit);
      }
    } else {
      if (isSubmit) {
        this.global.alertService.showError('rfq.validation.chooseVendor');
      }
    }
  }

  public doSubmit(isSubmit): void {
    this.setStateProcessing();
    const rfqRequest: RfqRequest = new RfqRequest();
    /* Set Value for RfqItem */
    if (
      this.rfqResponse.rfqItemList &&
      this.rfqResponse.rfqItemList.length > 0
    ) {
      let itemId;
      let itemDelete: RfqItem[];
      itemId = this.rfqItemVendorResponse.rfqItemList.map(
        rfqItem => rfqItem.prItem.id
      );
      itemDelete = this.rfqResponse.rfqItemList.filter(rfqItem => {
        if (!itemId.includes(rfqItem.prItem.id)) {
          rfqItem.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
          return rfqItem;
        }
      });
      rfqRequest.rfqItemList = [
        ...this.rfqItemVendorResponse.rfqItemList,
        ...itemDelete
      ];
    } else {
      rfqRequest.rfqItemList = this.rfqItemVendorResponse.rfqItemList;
    }
    /* Set Value for RfqVendor */
    rfqRequest.rfqVendorList = this.tableResponseVendor.getRecordList();
    if (
      this.rfqResponse.vendorViewList &&
      this.rfqResponse.vendorViewList.length > 0
    ) {
      const vendorId = rfqRequest.rfqVendorList.map(rfqVendor => rfqVendor.id);
      this.rfqResponse.vendorViewList.forEach(vendorView => {
        const rfqVendor: RfqVendor = new RfqVendor();
        if (!vendorId.includes(vendorView.id)) {
          rfqVendor.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
          rfqVendor.vendor.id = vendorView.id;
          rfqVendor.id = vendorView.id;
          rfqRequest.rfqVendorList.push(rfqVendor);
        }
      });
    }
    rfqRequest.isSubmit = isSubmit;
    rfqRequest.fileObjectList = this.formGroup.get('template').value;
    rfqRequest.rfqDocList = this.rfqResponse.rfqDocList;
    if (rfqRequest.rfqDocList && rfqRequest.rfqDocList.length > 0) {
      const deletedFile = rfqRequest.fileObjectList
        .filter(fileObject => fileObject.isDeleted)
        .map(fileObj => fileObj.file.id);
      const updatedFile = rfqRequest.fileObjectList
        .filter(fileObject => !fileObject.isDeleted && !fileObject.isNew)
        .map(fileObj => fileObj.file.id);
      rfqRequest.rfqDocList.forEach(rfqDoc => {
        if (
          deletedFile &&
          deletedFile.length > 0 &&
          deletedFile.includes(rfqDoc.file.id)
        ) {
          rfqDoc.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
        }
        if (
          updatedFile &&
          updatedFile.length > 0 &&
          updatedFile.includes(rfqDoc.file.id)
        ) {
          rfqDoc.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_UPDATE;
        }
      });
    }
    rfqRequest.fileObjectList = this.formGroup.get('template').value;
    rfqRequest.rfq = this.rfqResponse.rfq ? this.rfqResponse.rfq : new Rfq();
    rfqRequest.rfq = this.global.copyFormAttributeToModel(
      rfqRequest.rfq,
      this.formGroup
    );
    rfqRequest.rfq.rfqStatus = this.rfqResponse.rfq
      ? this.rfqResponse.rfq.rfqStatus
      : new RfqStatus();
    rfqRequest.rfq.startDate = this.formGroup.get('responsePeriod').value.from;
    rfqRequest.rfq.endDate = this.formGroup.get('responsePeriod').value.to;
    const url = this.todo === 'edit' ? '/rfq/update' : '/rfq/insert';
    this.httpClientService
      .post<Response<RfqResponse>>(url, rfqRequest)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          if (!isSubmit) {
            this.snackbarService.showWarning('app.msg.info.sucsessSave');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set('rfqId', response.body.id);
                this.global.routerParams.set(
                  'status',
                  response.body.rfqStatus.code
                );
                this.global.routerParams.set('urlBackOutside', '/pages/rfq');
                this.global.routerParams.set('todo', 'edit');
                this.router.navigate(['/pages/rfq/edit']);
              });
            this.todo = 'edit';
            this.setStateReady();
          } else {
            this.global.modalService
              .submitSuccessCreateNew()
              .pipe(take(1))
              .subscribe(result => {
                if (result) {
                  this.router
                    .navigateByUrl('/', { skipLocationChange: true })
                    .then(() => {
                      this.global.routerParams.clear();
                      this.global.routerParams.set('todo', 'add');
                      this.router.navigate(['/pages/rfq/add']);
                      this.global.routerParams.set(
                        'urlBackOutside',
                        '/pages/rfq'
                      );
                    });
                } else {
                  this.router.navigate(['/pages/rfq/']);
                }
                this.setStateReady();
              });
          }
        } else {
          this.global.alertService.showError(response.statusText);
          this.setStateReady();
        }
      });
  }

  public doClose(): void {
    this.global.modalService
      .confirmation(
        'If you close reponse, invited vendor could not edit or update their response anymore',
        'Are you sure to close response?',
        'No',
        'Yes, I am Sure',
        null,
        false
      )
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<Rfq>>('/rfq/close', this.rfqResponse.rfq)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.router.navigate(['/pages/rfq']);
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }
}
