import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';

@Component({
  templateUrl: './vendor-designation-popup-winner-confirmation.component.html',
  styleUrls: ['./vendor-designation-popup-winner-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VendorDesignationPopupWinnerComponent extends BaseComponentComponent {
  @Input() procurementVendorList;
  @Input() vendorSelectionTypeCode: string;
  @Output() onChange: EventEmitter<boolean> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {
    super('vendor-designation');
  }

  onInit(): void {
    this.setStateReady();
  }

  doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.onChange.emit(true);
    }
  }

  public onScrollend(): void {}
}
