import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { BillingMemoBudgetDetail } from 'src/app/core/bean/billing-memo-budget-detail';
import { BudgetAllocation } from 'src/app/core/bean/budget-allocation';
import { Currency } from 'src/app/core/bean/currency';
import { AppPopupChooseBudgetService } from 'src/app/core/components/app-popup/app-popup-choose-budget/app-popup-choose-budget.service';

@Component({
  templateUrl: './app-popup-billing-memo-item-detail.component.html'
})
export class AppPopupBillingMemoItemDetailComponent extends BaseComponentComponent {
  @Input() public billingMemoBudgetDetailList: BillingMemoBudgetDetail[];
  @Input() public totalBilling: number;
  @Input() public taxPercentage: number;
  @Input() public billingMemoItemId: number;
  @Input() public currency: Currency;
  @Input() public budgetAllocation: BudgetAllocation;
  @Input() public todo: string;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();
  public totalTemp: number;

  constructor(public appPopupChooseBudgetService: AppPopupChooseBudgetService) {
    super('');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      totalBilling: this.totalBilling,
      billingMemoBudgetDetailList: this.formBuilder.array([])
    });
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setFormGroup(): void {
    if (this.billingMemoBudgetDetailList) {
      this.setFormArray();
      this.setStateReady();
    } else {
      if (this.billingMemoItemId) {
        this.httpClientService
          .get<BillingMemoBudgetDetail[]>(
            '/billing-memo/edit-popup-billing-memo-detail/' +
              this.billingMemoItemId
          )
          .subscribe(response => {
            if (response.length !== 0) {
              this.billingMemoBudgetDetailList = response;
              this.setFormArray();
            } else {
              const formGroup = this.formBuilder.group({
                id: [null],
                coa: [null],
                coaCode: [null],
                costCenter: [null],
                taxPercentageValue: this.taxPercentage,
                taxBaseAmount: [null],
                vatAmount: [null],
                totalAmount: [null],
                budgetAllocation: [null]
              });
              if (this.todo === 'view') {
                formGroup.setIsView(true);
              }
              this.memoItemDetailList.push(formGroup);
            }
            this.setStateReady();
          });
      } else {
        const formGroup = this.formBuilder.group({
          id: [null],
          coa: [null],
          coaCode: [null],
          costCenter: [null],
          taxPercentageValue: this.taxPercentage,
          taxBaseAmount: [null],
          vatAmount: [null],
          totalAmount: [null],
          budgetAllocation: [null]
        });
        if (this.todo === 'view') {
          formGroup.setIsView(true);
        }
        this.memoItemDetailList.push(formGroup);
        this.setStateReady();
      }
    }
  }

  public setFormArray(): void {
    this.billingMemoBudgetDetailList.forEach(memoItemDetail => {
      const formGroup = this.formBuilder.group({
        id: memoItemDetail.id,
        coa: memoItemDetail.budgetAllocation
          ? memoItemDetail.budgetAllocation.coa
          : memoItemDetail.coa,
        coaCode: memoItemDetail.budgetAllocation
          ? memoItemDetail.budgetAllocation.coa.code
          : memoItemDetail.coa.code,
        costCenter: memoItemDetail.budgetAllocation
          ? memoItemDetail.budgetAllocation.costCenter
          : memoItemDetail.costCenter,
        taxPercentageValue: this.taxPercentage,
        taxBaseAmount: memoItemDetail.taxBaseAmount,
        vatAmount: memoItemDetail.vatAmount,
        totalAmount: memoItemDetail.totalAmount,
        budgetAllocation: memoItemDetail.budgetAllocation
      });
      if (this.todo === 'view') {
        formGroup.setIsView(true);
      }
      this.memoItemDetailList.push(formGroup);
    });
  }

  public get memoItemDetailList(): FormArray {
    return this.formGroup.get('billingMemoBudgetDetailList') as FormArray;
  }

  public onChangeTaxBase(index: number): void {
    const taxPercentage = this.memoItemDetailList
      .at(index)
      .get('taxPercentageValue').value;
    const taxBase = this.memoItemDetailList
      .at(index)
      .get('taxBaseAmount').value;
    if (taxPercentage && taxBase) {
      const vat = (taxBase * taxPercentage * 0.01).toFixed(2);
      this.memoItemDetailList.at(index).get('vatAmount').setValue(vat);
      this.memoItemDetailList
        .at(index)
        .get('vatAmount')
        .updateValueAndValidity();
      const total = (+taxBase + +vat).toFixed(2);
      this.memoItemDetailList.at(index).get('totalAmount').setValue(total);
      this.memoItemDetailList
        .at(index)
        .get('totalAmount')
        .updateValueAndValidity();
    }
  }

  public doChooseBudgetCost(index: number): void {
    this.appPopupChooseBudgetService
      .open()
      .subscribe((budgetAllocationList: BudgetAllocation[]) => {
        budgetAllocationList.forEach(budgetAllocation => {
          this.memoItemDetailList.at(index).patchValue({
            coaCode: budgetAllocation.coa.code,
            costCenter: budgetAllocation.costCenter,
            budgetAllocation
          });
        });
      });
  }

  public doDelete(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(response => {
        if (response) {
          this.memoItemDetailList.removeAt(index);
        }
      });
  }

  public doAddMore(): void {
    const formGroup = this.formBuilder.group({
      id: [null],
      coa: [null],
      coaCode: [null],
      costCenter: [null],
      taxPercentageValue: this.taxPercentage,
      taxBaseAmount: [null],
      vatAmount: [null],
      totalAmount: [null],
      budgetAllocation: [null]
    });
    this.memoItemDetailList.push(formGroup);
  }

  public doSave(): void {
    this.totalTemp = 0;
    const memoItemDetailList: BillingMemoBudgetDetail[] = [];
    this.formGroup.value.billingMemoBudgetDetailList.forEach(memoItemDetail => {
      const billingMemoItemDetail: BillingMemoBudgetDetail =
        new BillingMemoBudgetDetail();
      billingMemoItemDetail.id = memoItemDetail.id;
      billingMemoItemDetail.coa = memoItemDetail.budgetAllocation
        ? null
        : memoItemDetail.coa;
      billingMemoItemDetail.costCenter = memoItemDetail.budgetAllocation
        ? null
        : memoItemDetail.costCenter;
      billingMemoItemDetail.taxPercentageValue =
        memoItemDetail.taxPercentageValue;
      billingMemoItemDetail.taxBaseAmount = +memoItemDetail.taxBaseAmount;
      billingMemoItemDetail.vatAmount = +memoItemDetail.vatAmount;
      billingMemoItemDetail.totalAmount = memoItemDetail.totalAmount;
      billingMemoItemDetail.budgetAllocation = memoItemDetail.budgetAllocation;
      memoItemDetailList.push(billingMemoItemDetail);
      this.totalTemp += +memoItemDetail.totalAmount;
    });
    if (this.totalTemp === +this.totalBilling) {
      this.onChange.emit(memoItemDetailList);
    } else {
      const msgError =
        this.totalTemp < this.totalBilling
          ? 'billing-memo.error.msg.lessThanTotal'
          : 'billing-memo.error.msg.moreThanTotal';
      this.global.alertService.showError(msgError, '.popup-biling-memo');
    }
  }
}
