<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-layout>
      <app-card
        header="executor.title"
        [isLoading]="formLoading"
        [isSaving]="formSaving"
      >
        <app-table
          [model]="tableResponse"
          [isServerSide]="true"
          stringUrl="/executor/index"
        >
          <ng-template #actionButtons let-rowData>
            <app-button
              size="TN"
              (onClick)="doEdit(rowData)"
              title="{{ 'app.tooltip.edit' | translate }}"
            >
              <em class="fas fa-pencil-alt"></em>
            </app-button>
          </ng-template>
        </app-table>
      </app-card>
    </app-layout>
  </div>
</div>

<!-- <app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-table [model]="tableResponse" stringUrl="/executor/index">
        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}"
          >
            <em class="fas fa-pencil-alt"></em>
          </app-button>
        </ng-template>
      </app-table>
    </div>
  </div>
</app-layout> -->
