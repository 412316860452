<div class="row main-row app-accordion-row">
  <div class="col-12">
    <app-workflow-progress-info
      *ngIf="isWorkflowProgress"
      [contract]="contract"
      [currentPosition]="moduleCode"
    >
    </app-workflow-progress-info>
    <app-card
      header="contract-request.title"
      detail="contract-request.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-accordion
        [expandAll]="false"
        [isShowControls]="false"
        *ngIf="!formLoading"
      >
        <app-accordion-item
          *ngIf="todo === 'cancel' || isCanceled"
          header="contract-request.accordion.contractCanceled"
          [active]="todo === 'cancel' || isCanceled"
        >
          <div class="text-center py-4" *ngIf="formLoading">
            <img src="assets/img/common/loader-small.gif" alt="Loading" />
          </div>
          <form
            *ngIf="!formLoading && (todo === 'cancel' || isCanceled)"
            class="form-horizontal"
            [formGroup]="formGroupCanceled"
            novalidate
          >
            <div
              *ngIf="
                !formLoading &&
                !contractRequestResponse.module.isNumberGenerator
              "
              class="form-group row text-sm-right"
              [ngClass]="
                formGroup.get('number').isView || formGroup.isView
                  ? ''
                  : 'required'
              "
            >
              <label class="col-sm-3 control-label">{{
                'tor-submission.form.number' | translate
              }}</label>
              <div
                class="col-sm-9 text-left"
                *ngIf="global.userSession.user.organization.code === 'P112'"
              >
                <app-text-generated
                  formControlName="number"
                  maxLength="64"
                  [moduleCode]="moduleCode"
                  numberGeneratorCode="PGP_CONTRACT_NUMBER"
                  autofocus="true"
                ></app-text-generated>
              </div>
              <div
                class="col-sm-9 text-left"
                *ngIf="global.userSession.user.organization.code !== 'P112'"
              >
                <app-text-generated
                  formControlName="number"
                  maxLength="64"
                  [moduleCode]="moduleCode"
                  numberGeneratorCode="PLJ_CONTRACT_NUMBER"
                  autofocus="true"
                ></app-text-generated>
              </div>
            </div>
            <div
              *ngIf="
                !formLoading &&
                !contractRequestResponse.module.isNumberGenerator
              "
              class="form-group row text-sm-right"
              [ngClass]="
                formGroup.get('number').isView || formGroup.isView
                  ? ''
                  : 'required'
              "
            >
              <label class="col-sm-3 control-label">{{
                'tor-submission.form.number' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-generated
                  formControlName="requestNumber"
                  maxLength="64"
                  [moduleCode]="moduleCode"
                  numberGeneratorCode="REQUEST_NUMBER"
                  autofocus="true"
                ></app-text-generated>
              </div>
            </div>
            <div class="form-group row text-sm-right" *ngIf="isCanceled">
              <label class="col-sm-3 control-label">{{
                'contract-request.form.date' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{
                  contractRequestResponse.contractCanceledDate
                    | date: global.config.parameterModel.dateFormatShort
                }}
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroupCanceled.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'contract-request.form.file' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-upload
                  formControlName="fileDoc"
                  [fileUploader]="fileUploader"
                ></app-upload>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroupCanceled.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'contract-request.form.note' | translate
              }}</label>
              <div
                class="text-left"
                [ngClass]="formGroupCanceled.isView ? 'col-sm-6' : 'col-sm-9'"
              >
                <app-text-area
                  formControlName="note"
                  maxLength="512"
                ></app-text-area>
              </div>
            </div>
          </form>
        </app-accordion-item>
        <app-accordion-item
          header="contract-request.accordion.contractRequest"
          [active]="todo !== 'cancel'"
        >
          <div class="text-center py-4" *ngIf="formLoading">
            <img src="assets/img/common/loader-small.gif" alt="Loading" />
          </div>
          <form class="form-horizontal" [formGroup]="formGroup" novalidate>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'contract-request.form.contractType' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-combo-box
                  [optionList]="contractTypeOptionList"
                  formControlName="contractType"
                  (onChange)="doChangeContractType()"
                ></app-combo-box>
              </div>
            </div>

            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
              *ngIf="
                !formLoading &&
                formGroup.value.contractType?.code !==
                  global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
              "
            >
              <label class="col-sm-3 control-label">{{
                'contract-request.form.dealingAgreementType' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-combo-box
                  [optionList]="dealingAgreementTypeOptionList"
                  formControlName="dealingAgreementType"
                  (onChange)="doOnChangeDealingAgreementType()"
                ></app-combo-box>
              </div>
            </div>
            <div
              *ngIf="
                !formLoading &&
                !formGroup.isView &&
                formGroup.value.dealingAgreementType &&
                formGroup.value.contractType.code !==
                  global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
              "
              class="form-group row text-sm-right"
            >
              <label class="col-sm-3 control-label"></label>
              <div class="col-sm-3 text-right ml-4">
                <app-button
                  *ngIf="!formGroup.isView"
                  color="PRIMARY"
                  (onClick)="doChooseProcurement()"
                  [disabled]="formSaving"
                  >{{
                    'contract-request.button.chooseProcurement' | translate
                  }}</app-button
                >
              </div>
            </div>
            <div
              *ngIf="
                !formLoading &&
                !formGroup.isView &&
                formGroup.value.contractType?.code ===
                  global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
              "
              class="form-group row text-sm-right"
            >
              <label class="col-sm-3 control-label"></label>
              <div class="col-sm-3 text-right ml-4">
                <app-button
                  *ngIf="!formGroup.isView"
                  color="PRIMARY"
                  (onClick)="doChooseContract()"
                  [disabled]="formSaving"
                  >{{
                    'contract-request.button.chooseContract' | translate
                  }}</app-button
                >
              </div>
            </div>
            <div
              class="text-center py-4"
              *ngIf="isLoading || isLoadingProcData"
            >
              <img src="assets/img/common/loader-small.gif" alt="Loading" />
            </div>
            <ng-container
              *ngIf="
                (formGroup.value.contract ||
                  formGroup.value.procurementVendor) &&
                !isLoading &&
                !isLoadingProcData
              "
            >
              <h5 class="separator">{{
                'contract-request.separator.requestDataForm' | translate
              }}</h5>
              <h5 class="separator"
                >{{ 'contract-request.separator.stageOfWork' | translate
                }}<span
                  *ngIf="!formGroup.isView"
                  class="control-label text-danger"
                >
                  *</span
                ></h5
              >
              <small
                class="text-danger"
                *ngIf="
                  (formGroup.value.procurementVendor ||
                    formGroup.value.procurementVendor) &&
                  isSave &&
                  procurementSowList.length === 0
                "
              >
                {{ 'app.validation.required' | translate }}
              </small>
              <app-table
                #selectorStageOfWork
                [model]="tableResponseStageOfWork"
                *ngIf="!formLoading"
              >
                <ng-template #headerButtons>
                  <app-button
                    [disabled]="
                      formSaving ||
                      (availableAmountSow && availableAmountSow === 0)
                    "
                    *ngIf="
                      !formGroup.isView &&
                      (formGroup.value.dealingAgreementType.name !==
                        global.appConstant.cm
                          .DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN ||
                        procurementSowList.length !== 1)
                    "
                    (onClick)="doAddStageOfWork()"
                    ><em class="fas fa-plus"></em>
                    {{ 'app.button.add' | translate }}</app-button
                  >
                </ng-template>
                <ng-template
                  #actionButtons
                  let-rowData
                  let-i="index"
                  *ngIf="
                    formGroup.value.contractType.code ===
                    global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
                  "
                >
                  <app-button
                    *ngIf="
                      !formGroup.isView &&
                      rowData.status !=
                        global.appConstant.cm.PROCUREMENT_SOW_STATUS_CLOSED
                    "
                    [disabled]="formSaving"
                    size="TN"
                    (onClick)="doEditStageOfWork(rowData)"
                    title="{{ 'app.tooltip.edit' | translate }}"
                  >
                    <em class="fas fa-pencil-alt"></em>
                  </app-button>
                  <app-button
                    *ngIf="
                      formGroup.isView ||
                      rowData.status ==
                        global.appConstant.cm.PROCUREMENT_SOW_STATUS_CLOSED
                    "
                    [disabled]="formSaving"
                    size="TN"
                    (onClick)="doDetailStageOfWork(rowData)"
                    title="{{ 'app.tooltip.view' | translate }}"
                  >
                    <em class="fas fa-search"></em>
                  </app-button>
                  <app-button
                    *ngIf="
                      !formGroup.isView &&
                      tableResponseStageOfWork.page.totalRecords === i + 1 &&
                      rowData.status ===
                        global.appConstant.cm.PROCUREMENT_SOW_STATUS_NEW &&
                      i !== 0
                    "
                    [disabled]="formSaving"
                    size="TN"
                    color="DANGER"
                    (onClick)="doDeleteStageOfWork(rowData)"
                    title="{{ 'app.tooltip.delete' | translate }}"
                  >
                    <em class="fas fa-trash"></em>
                  </app-button>
                </ng-template>
                <ng-template
                  #actionButtons
                  let-rowData
                  let-i="index"
                  *ngIf="
                    formGroup.value.contractType.code !==
                    global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
                  "
                >
                  <app-button
                    *ngIf="!formGroup.isView"
                    [disabled]="formSaving"
                    size="TN"
                    (onClick)="doEditStageOfWork(rowData)"
                    title="{{ 'app.tooltip.edit' | translate }}"
                  >
                    <em class="fas fa-pencil-alt"></em>
                  </app-button>
                  <app-button
                    *ngIf="formGroup.isView"
                    [disabled]="formSaving"
                    size="TN"
                    (onClick)="doDetailStageOfWork(rowData)"
                    title="{{ 'app.tooltip.view' | translate }}"
                  >
                    <em class="fas fa-search"></em>
                  </app-button>
                  <app-button
                    *ngIf="
                      !formGroup.isView &&
                      tableResponseStageOfWork.page.totalRecords === i + 1 &&
                      i !== 0
                    "
                    [disabled]="formSaving"
                    size="TN"
                    color="DANGER"
                    (onClick)="doDeleteStageOfWork(rowData)"
                    title="{{ 'app.tooltip.delete' | translate }}"
                  >
                    <em class="fas fa-trash"></em>
                  </app-button>
                </ng-template>
              </app-table>
              <h5 class="separator">{{
                'contract-request.separator.guarantee' | translate
              }}</h5>
              <app-table
                #selectorGuarantee
                [model]="tableResponseGuarantee"
                *ngIf="!formLoading"
              >
                <ng-template #headerButtons>
                  <app-button
                    [disabled]="formSaving"
                    *ngIf="!formGroup.isView"
                    (onClick)="doAddGuarantee()"
                    ><em class="fas fa-plus"></em>
                    {{ 'app.button.add' | translate }}</app-button
                  >
                </ng-template>
                <ng-template
                  #actionButtons
                  let-rowData
                  *ngIf="!formGroup.isView"
                >
                  <app-button
                    *ngIf="!formGroup.isView"
                    [disabled]="formSaving"
                    size="TN"
                    (onClick)="doEditGuarantee(rowData)"
                    title="{{ 'app.tooltip.edit' | translate }}"
                  >
                    <em class="fas fa-pencil-alt"></em>
                  </app-button>
                  <app-button
                    *ngIf="!formGroup.isView"
                    [disabled]="formSaving"
                    size="TN"
                    color="DANGER"
                    (onClick)="doDeleteGuarantee(rowData)"
                    title="{{ 'app.tooltip.delete' | translate }}"
                  >
                    <em class="fas fa-trash"></em>
                  </app-button>
                </ng-template>
              </app-table>
              <h5 class="separator"
                >{{ 'contract-request.separator.fines' | translate
                }}<span
                  *ngIf="!formGroup.isView"
                  class="control-label text-danger"
                >
                  *</span
                ></h5
              >
              <small
                class="text-danger"
                *ngIf="
                  isSave &&
                  contractRequestResponse.procurementFinesList.length === 0
                "
              >
                {{ 'app.validation.required' | translate }}
              </small>
              <app-table
                #selectorFines
                [model]="tableResponseFines"
                *ngIf="!formLoading"
              >
                <ng-template #headerButtons>
                  <app-button
                    [disabled]="formSaving"
                    *ngIf="!formGroup.isView"
                    (onClick)="doAddFines()"
                    ><em class="fas fa-plus"></em>
                    {{ 'app.button.add' | translate }}</app-button
                  >
                </ng-template>
                <ng-template
                  #actionButtons
                  let-rowData
                  *ngIf="!formGroup.isView"
                >
                  <app-button
                    *ngIf="!formGroup.isView"
                    [disabled]="formSaving"
                    size="TN"
                    (onClick)="doEditFines(rowData)"
                    title="{{ 'app.tooltip.edit' | translate }}"
                  >
                    <em class="fas fa-pencil-alt"></em>
                  </app-button>
                  <app-button
                    *ngIf="!formGroup.isView"
                    [disabled]="formSaving"
                    size="TN"
                    color="DANGER"
                    (onClick)="doDeleteFines(rowData)"
                    title="{{ 'app.tooltip.delete' | translate }}"
                  >
                    <em class="fas fa-trash"></em>
                  </app-button>
                </ng-template>
              </app-table>
            </ng-container>
          </form>
        </app-accordion-item>
        <app-accordion-item
          header="contract-request.contractInformation"
          *ngIf="
            !formLoading &&
            !isLoading &&
            !isLoadingProcData &&
            formGroup.value.contractType?.code ===
              global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT &&
            formGroup.value.contract
          "
        >
          <div class="text-center py-4" *ngIf="formLoading">
            <img src="assets/img/common/loader-small.gif" alt="Loading" />
          </div>
          <app-procurement-information-view
            *ngIf="!formLoading && !isLoading && !isLoadingProcData"
            [contractId]="
              formGroup.value?.contract.fromContract && todo !== 'add'
                ? formGroup.value?.contract.fromContract.id
                : formGroup.value?.contract.id
            "
          >
          </app-procurement-information-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-request.procurementInformation"
          *ngIf="
            !formLoading &&
            !isLoading &&
            !isLoadingProcData &&
            formGroup.value.contractType?.code ===
              global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT &&
            formGroup.value.contract &&
            procurementVendorId
          "
        >
          <div class="text-center py-4" *ngIf="formLoading">
            <img src="assets/img/common/loader-small.gif" alt="Loading" />
          </div>
          <app-procurement-information-view
            *ngIf="!formLoading && !isLoading"
            [procurementVendorId]="procurementVendorId"
          >
          </app-procurement-information-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-request.procurementInformation"
          *ngIf="
            !formLoading &&
            !isLoading &&
            !isLoadingProcData &&
            formGroup.value.contractType?.code !==
              global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT &&
            formGroup.value.procurementVendor
          "
        >
          <div class="text-center py-4" *ngIf="formLoading">
            <img src="assets/img/common/loader-small.gif" alt="Loading" />
          </div>
          <app-procurement-information-view
            *ngIf="!formLoading && !isLoading"
            [procurementVendorId]="formGroup.value.procurementVendor.id"
          >
          </app-procurement-information-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-request.accordion.vendorInformation"
          *ngIf="
            !formLoading &&
            !isLoading &&
            !isLoadingProcData &&
            formGroup.value.contractType?.code ===
              global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT &&
            formGroup.value.contract &&
            procurementVendorId
          "
        >
          <app-vendor-payment-information
            [procurementVendorId]="procurementVendorId"
            [temp]="contractRequestResponse"
            [todo]="todo"
          ></app-vendor-payment-information>
        </app-accordion-item>
        <app-accordion-item
          header="contract-request.accordion.vendorInformation"
          *ngIf="
            !formLoading &&
            !isLoading &&
            !isLoadingProcData &&
            formGroup.value.contractType?.code !==
              global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT &&
            formGroup.value.procurementVendor
          "
        >
          <app-vendor-payment-information
            [procurementVendorId]="formGroup.value.procurementVendor.id"
            [temp]="contractRequestResponse"
            [todo]="todo"
          ></app-vendor-payment-information>
        </app-accordion-item>

        <!-- <app-accordion-item header="contract-request.accordion.logApproval" 
        *ngIf="workflowPrcs && todo !== 'add' 
        && !contractRequestResponse.isUserApproval">
        <app-approval-log
         [moduleCode]="moduleCode" 
         [workflowPrcs]="workflowPrcs">
         </app-approval-log>
      </app-accordion-item> -->
      </app-accordion>
      <div class="floating-button mt-5">
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div
              class="button-group button-group-center"
              *ngIf="
                todo !== 'cancel' && !contractRequestResponse.isUserApproval
              "
            >
              <app-button
                mode="BYPASS"
                color="SECONDARY"
                (onClick)="doCancel()"
                class="mr-1"
                [disabled]="formSaving"
                >{{ 'app.button.back' | translate }}
              </app-button>
              <app-button
                *ngIf="!formGroup.isView"
                color="PRIMARY"
                (onClick)="doSaveDraft()"
                [disabled]="formSaving"
                class="mr-1"
                >{{ 'app.button.saveDraft' | translate }}</app-button
              >
              <app-button
                *ngIf="!formGroup.isView"
                color="PRIMARY"
                (onClick)="doSave()"
                [disabled]="formSaving"
                >{{ 'app.button.save' | translate }}</app-button
              >
            </div>
            <div
              class="button-group button-group-center"
              *ngIf="
                todo === 'cancel' && !contractRequestResponse.isUserApproval
              "
            >
              <app-button
                mode="BYPASS"
                color="SECONDARY"
                (onClick)="doCancel()"
                class="mr-1"
                [disabled]="formSaving"
                >{{ 'app.button.back' | translate }}
              </app-button>
              <app-button
                color="PRIMARY"
                (onClick)="doSaveCancel()"
                [disabled]="formSaving"
                >{{ 'app.button.save' | translate }}</app-button
              >
            </div>
          </div>
        </div>
      </div>
    </app-card>
    <app-approval-prcs
      *ngIf="contractRequestResponse.isUserApproval"
      [workflowModelPrcs]="workflowModelPrcs"
    >
    </app-approval-prcs>
  </div>
</div>
