import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'portal', pathMatch: 'full' },
  {
    path: 'portal',
    loadChildren: () =>
      import('./portal/portal.module').then(m => m.PortalModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'reset-email/:token',
    loadChildren: () =>
      import('./portal/reset-email/reset-email.module').then(
        m => m.ResetEmailModule
      )
  },
  {
    path: 'reload',
    loadChildren: () =>
      import('./reload/reload.module').then(m => m.ReloadModule)
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./pages/errors/error-page/error-page.module').then(
        m => m.ErrorPageModule
      )
  },
  {
    path: 'lost-connection',
    loadChildren: () =>
      import(
        './pages/errors/lost-connection-page/lost-connection-page.module'
      ).then(m => m.LostConnectionPageModule)
  },
  {
    path: 'aanwijzing',
    loadChildren: () =>
      import('./public/aanwijzing-others/aanwijzing-others.module').then(
        m => m.AanwijzingOthersModule
      )
  },
  {
    path: 'aanwijzing-attendance-form',
    loadChildren: () =>
      import('./public/attendance-form/attendance-form.module').then(
        m => m.AttendanceFormModule
      )
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/errors/not-found/not-found.module').then(
        m => m.NotFoundModule
      )
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
