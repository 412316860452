<div class="row main-row">
    <div class="col-12">
        <app-card header="compliance-test-group.title"
            detail="app.action.{{todo}}" [isLoading]="formLoading"
            [isSaving]="formSaving">
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>

                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        'compliance-test-group.form.name' | translate}}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-field autofocus="true" formControlName="name"
                            maxLength=218></app-text-field>
                    </div>
                </div>

                <div class="form-group row text-sm-right">
                    <label class="col-sm-3 control-label">{{
                        'compliance-test-group.form.description' |
                        translate}}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-area formControlName="description"
                            maxLength=512></app-text-area>
                    </div>
                </div>

                <div class="row justify-content-center mt-5">
                    <app-button type="BTN_SECONDARY" (onClick)="doBack()"
                        class="mr-1" color="SECONDARY">{{ 'app.button.back' |
                        translate }}</app-button>
                    <app-button type="BTN_PRIMARY" (onClick)="doSave()">{{
                        'app.button.save' | translate }}</app-button>
                </div>
            </form>
        </app-card>
    </div>
</div>