import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';
import { Currency } from '../../../core/bean/currency';
import { Region } from '../../../core/bean/region';
import { Segmentation } from '../../../core/bean/segmentation';
import { VendorExperience } from '../../../core/bean/vendor-experience';
import { VendorExperienceHistory } from '../../../core/bean/vendor-experience-history';
import { VendorExperienceSegmentationHistory } from '../../../core/bean/vendor-experience-segmentation-history';
import { VendorExperienceStatus } from '../../../core/bean/vendor-experience-status';
import { FileUploader } from '../../../core/components/upload';
import { OptionListModel } from '../../../core/model/option-list-model';
import { Validators } from '../../../core/validators';
import { VendorPopupVendorExperienceDTO } from '../dto/vendor-popup-vendor-experience.dto';

@Component({
  templateUrl: './app-popup-experience.component.html'
})
export class AppPopupExperienceComponent extends BaseComponentComponent {
  @Input() public todo: string;
  @Input() public vendorExperienceHistoryEdit: VendorExperienceHistory;
  @Input() public vendorExperienceHistoryList: VendorExperienceHistory[];
  @Input() public vendorExperience: VendorExperience;
  @Input() public vendorExperienceList: VendorExperience[];
  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();

  public cityList: Region[] = [];
  public dataTransfer: VendorExperience;
  public valueCurrency = '';
  public valueCurrencyActual = '';
  public isVendorList: boolean;
  public fromOutside: string;
  public fileUploader: FileUploader = new FileUploader(
    '/tab-profile-experience/',
    'experience',
    this.global.appConstant.fileType.DOC_PROOF_CONTRACT
  );
  public vendorPopupVendorExperienceDTO: VendorPopupVendorExperienceDTO =
    new VendorPopupVendorExperienceDTO();
  public segmentationList: Segmentation[] = [];
  public experienceSegmentationHistoryList: VendorExperienceSegmentationHistory[] =
    [];
  public date: string;
  public dateHistory: string;
  public countryRegionId: Object;

  public countryRegionOptionList: OptionListModel<Region> = new OptionListModel(
    true
  );
  public cityRegionOptionList: OptionListModel<Region> = new OptionListModel(
    true
  );
  public vendorExperienceStatusOptionList: OptionListModel<VendorExperienceStatus> =
    new OptionListModel(true);
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true,
    'code'
  );

  constructor(public datePipe: DatePipe, public activeModal: NgbActiveModal) {
    super('tab-profile-experience');
  }

  public onInit(): void {
    this.setParam();
    this.getVendorExperience();
    this.buildFormGroup();
    this.setFormGroup();
    if (this.todo === 'detail') {
      this.setViewOnly();
    }
  }

  public setParam(): void {
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      projectName: [null, Validators.required()],
      businessPartnerName: [null, Validators.required()],
      countryRegion: [null, Validators.required()],
      cityRegion: [null],
      address: [null],
      projectOwner: [null, Validators.required()],
      projectType: [null, Validators.required()],
      segmentationList: [null, Validators.required()],
      vendorExperienceStatus: [null, Validators.required()],
      contractNumber: [null, Validators.required()],
      contractDate: [null, Validators.required()],
      currencyValue: [null],
      coordinateLocation: [null],
      file: [null],
      fileUploader: [null],
      id: [null],
      vendorExperienceId: [null],
      todayDate: [new Date()],
      minDate: [new Date().setFullYear(new Date().getFullYear() - 3)],
      experienceSegmentationHistoryList: [null]
    });
  }

  public setFormGroup(): void {
    this.getAppPopupVendorExperienceByIdFromServer(
      '/tab-profile-experience/add-edit-popup-vendor-experience'
    ).subscribe(vendorPopupVendorExperienceDTO => {
      this.vendorPopupVendorExperienceDTO = vendorPopupVendorExperienceDTO;
      this.setOptionListModel();
      if (this.vendorExperienceHistoryEdit !== undefined) {
        const {
          id,
          projectName,
          businessPartnerName,
          countryRegion,
          cityRegion,
          address,
          projectOwner,
          projectType,
          vendorExperienceStatus,
          contractNumber,
          vendorExperienceId
        } = this.vendorExperienceHistoryEdit;
        this.countryRegionId = {
          countryRegionId: this.vendorExperienceHistoryEdit.countryRegion.id
        };
        this.fileUploader.setFile(this.vendorExperienceHistoryEdit.file);
        if (
          this.vendorExperienceHistoryEdit.fileObjectList &&
          this.vendorExperienceHistoryEdit.fileObjectList.length !== 0
        ) {
          this.fileUploader.fileObjectList =
            this.vendorExperienceHistoryEdit.fileObjectList;
        }
        this.formGroup.patchValue({ fileUploader: this.fileUploader });
        if (
          this.vendorExperienceHistoryEdit.currency !== null &&
          this.formGroup.isView
        ) {
          this.formGroup.patchValue({
            currencyValue: {
              currency: this.vendorExperienceHistoryEdit.currency,
              price: this.global.converterService.convertMoney(
                this.vendorExperienceHistoryEdit.contractValue.toString()
              )
            }
          });
        } else if (this.vendorExperienceHistoryEdit.currency !== null) {
          this.formGroup.patchValue({
            currencyValue: {
              currency: this.vendorExperienceHistoryEdit.currency,
              price: this.vendorExperienceHistoryEdit.contractValue
            }
          });
        }
        const coordinateLocation =
          this.vendorExperienceHistoryEdit.coordinateLocation;
        const contractDate: Date = new Date(
          this.vendorExperienceHistoryEdit.contractDate
        );
        if (
          this.vendorExperienceHistoryEdit
            .vendorExperienceSegmentationHistoryList !== null
        ) {
          this.vendorExperienceHistoryEdit.vendorExperienceSegmentationHistoryList.forEach(
            experienceSegmentation => {
              if (experienceSegmentation.segmentation !== null) {
                this.segmentationList.push(experienceSegmentation.segmentation);
              }
            }
          );
        }
        const segmentationList = this.segmentationList;
        this.formGroup.patchValue({
          id,
          projectName,
          businessPartnerName,
          address,
          cityRegion,
          vendorExperienceStatus,
          projectOwner,
          projectType,
          contractNumber,
          file: this.fileUploader.fileObjectList,
          countryRegion,
          segmentationList,
          contractDate,
          coordinateLocation,
          vendorExperienceId,
          experienceSegmentationHistoryList:
            this.vendorExperienceHistoryEdit
              .vendorExperienceSegmentationHistoryList
        });
      }
      this.setStateReady();
    });
  }

  public setOptionListModel(): void {
    this.countryRegionOptionList.setRequestValues(
      this.vendorPopupVendorExperienceDTO.countryRegionList
    );
    this.vendorExperienceStatusOptionList.setRequestValues(
      this.vendorPopupVendorExperienceDTO.vendorExperienceStatusList
    );
    this.currencyOptionList.setRequestValues(
      this.vendorPopupVendorExperienceDTO.currencyList
    );
  }

  public doChangeCountryRegion(): void {
    this.formGroup.patchValue({
      cityRegion: null
    });
    this.countryRegionId = {
      countryRegionId: this.formGroup.value.countryRegion.id
    };
  }

  public getVendorExperience(): void {
    if (this.vendorExperienceList) {
      this.vendorExperienceHistoryList.forEach(experienceHistory => {
        if (experienceHistory === this.vendorExperienceHistoryEdit) {
          this.vendorExperienceList.forEach(experience => {
            if (experience.id === experienceHistory.vendorExperienceId) {
              this.vendorExperience = experience;
              this.date = this.datePipe.transform(
                new Date(this.vendorExperience.contractDate),
                this.global.config.parameterModel.dateFormatShort
              );
              if (this.vendorExperience.currency !== null) {
                this.valueCurrencyActual =
                  this.global.converterService.convertMoney(
                    this.vendorExperience.contractValue.toString()
                  ) +
                  ' ' +
                  this.vendorExperience.currency.code;
              }
            }
          });
        }
      });
      if (this.vendorExperience === undefined) {
        this.vendorExperience = new VendorExperience();
      }
    }
    if (this.vendorExperienceHistoryEdit !== undefined) {
      this.dateHistory = this.datePipe.transform(
        new Date(this.vendorExperienceHistoryEdit.contractDate),
        this.global.config.parameterModel.dateFormatShort
      );
      this.valueCurrency =
        this.vendorExperienceHistoryEdit.contractValue !== null
          ? this.global.converterService.convertMoney(
              this.vendorExperienceHistoryEdit.contractValue.toString()
            ) +
            ' ' +
            this.vendorExperienceHistoryEdit.currency.code
          : null;
    }
  }

  public getAppPopupVendorExperienceByIdFromServer(
    urlVendorExperiencePopup: string
  ): Observable<VendorPopupVendorExperienceDTO> {
    // tslint:disable-next-line: max-line-length
    return this.httpClientService.post<VendorPopupVendorExperienceDTO>(
      urlVendorExperiencePopup,
      this.vendorExperienceHistoryEdit != null
        ? this.vendorExperienceHistoryEdit
        : new VendorExperience()
    );
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.value.experienceSegmentationHistoryList !== null) {
      const segmentationList: Segmentation[] =
        this.formGroup.value.segmentationList;
      const experienceSegmentationHistoryList: VendorExperienceSegmentationHistory[] =
        this.formGroup.value.experienceSegmentationHistoryList;
      segmentationList.forEach(segmentation => {
        experienceSegmentationHistoryList.forEach(expSegHistory => {
          if (segmentation.code === expSegHistory.segmentation.code) {
            this.experienceSegmentationHistoryList.push(expSegHistory);
          }
        });
      });
      this.formGroup.patchValue({
        experienceSegmentationHistoryList:
          this.experienceSegmentationHistoryList
      });
    }
    if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup.value);
      this.activeModal.dismiss();
    }
  }
}
