import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { WorkloadView } from '../../core/view/entity/bean/workload-view';

@Component({
  templateUrl: './workload.component.html'
})
export class WorkloadComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<WorkloadView>;

  public moduleGroupOptionList: OptionListModel<string> = new OptionListModel(
    true
  );

  public constructor(translateService: TranslateService) {
    super('workload', translateService);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setOptionList();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      moduleGroupList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<string[]>('/workload/get-option-list')
      .subscribe(response => {
        this.moduleGroupOptionList.setRequestValues(response);
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'module.name',
        header: 'table.column.stageName',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClickWorkload.bind(this)
        }
      },
      {
        field: 'moduleGroupName',
        header: 'table.column.moduleGroup'
      }
    ]);
  }

  public doClickWorkload(workloadView: WorkloadView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('workload', workloadView.workload);
    this.global.routerParams.set('module', workloadView.module);
    this.router.navigate(['/pages/workload/detail']);
  }

  public onChangeFilter(): void {
    this.tableResponse.setCustomData(this.formGroup.value.moduleGroupList);
    this.tableResponse.reload();
  }
}
