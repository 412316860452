import { BaseEntity } from '../base/base-entity';
import { EquipmentCondition } from '../bean/equipment-condition';
import { EquipmentType } from '../bean/equipment-type';
import { Region } from '../bean/region';
import { ResourceOwnership } from '../bean/resource-ownership';
import { Uom } from '../bean/uom';
import { VendorEquipmentDoc } from '../bean/vendor-equipment-doc';
import { VendorEquipmentDocHistory } from '../bean/vendor-equipment-doc-history';
import { VendorHistory } from '../bean/vendor-history';

export class VendorEquipmentHistoryEntity extends BaseEntity {
    regionList: Region[];
    regionNameList: string;
    capacityUom: string;
    crudInfo: string;

    vendorEquipmentDocList: VendorEquipmentDoc[] = [];
    vendorEquipmentDocHistoryList: VendorEquipmentDocHistory[] = [];
    vendorHistory: VendorHistory;
    equipmentType: EquipmentType;
    uom: Uom;
    region: Region;
    equipmentCondition: EquipmentCondition;
    resourceOwnership: ResourceOwnership;
    createdDate: Date;
    quantity: number;
    capacity: number;
    brand: string;
    year: number;
    vendorEquipmentId: number;
}
