import { BaseEntity } from '../base/base-entity';
import { BillingMemoBudget } from '../bean/billing-memo-budget';
import { BudgetAllocation } from '../bean/budget-allocation';
import { Coa } from '../bean/coa';
import { CostCenter } from '../bean/cost-center';

export class BillingMemoBudgetDetailEntity extends BaseEntity {
    public billingMemoBudget: BillingMemoBudget;
    public coa: Coa;
    public costCenter: CostCenter;
    public budgetAllocation: BudgetAllocation;
    public taxBaseAmount: number;
    public vatAmount: number;
    public totalAmount: number;
    public taxPercentageValue: number;
}
