import { Component, ViewChild } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ContractNegotiation } from '../../core/bean/contract-negotiation';
import { ContractWorklistStatus } from '../../core/bean/contract-worklist-status';
import { Organization } from '../../core/bean/organization';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
@Component({
  templateUrl: 'contract-negotiation.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class ContractNegotiationComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public tableResponse: TableResponseModel<ContractNegotiation>;
  public statusOptionList: OptionListModel<ContractWorklistStatus> =
    new OptionListModel(true);
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);

  constructor(translateService: TranslateService) {
    super('contract-negotiation', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      organizationList: [null],
      statusList: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<ContractNegotiation>('/contract-negotiation/get-option-list')
      .subscribe(response => {
        if (response) {
          this.statusOptionList.setRequestValues(response.statusList);
          this.organizationOptionList.setRequestValues(
            response.organizationList
          );
        }
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'contract.requestNumber',
        header: 'table.column.requestNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClick.bind(this)
        }
      },
      {
        field: 'contract.requestDate',
        header: 'table.column.requestDate',
        plugins: 'date'
      },
      {
        field: 'contract.title',
        header: 'table.column.title'
      },
      {
        field: 'contract.organization.name',
        header: 'table.column.organization'
      },
      {
        field: 'vendorNegotiationStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'vendorNegotiationStatus.code',
          colorMap: {
            NEW: 'INFO',
            REVISION: 'FEEDBACK',
            WAITING_CONFIRMATION: 'GOOD',
            ON_PROGRESS: 'PROGRESS'
          }
        }
      }
    ]);
  }

  public doClick(contractNego: ContractNegotiation): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('contract', contractNego.contract);
    this.global.routerParams.set('contractId', contractNego.contract.id);

    this.global.routerParams.set('contractNegotiation', contractNego);
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/contract-negotiation'
    );
    this.global.routerParams.set(
      'contractWorklistStatus',
      contractNego.vendorNegotiationStatus
    );

    if (
      contractNego.vendorNegotiationStatus.code ===
        this.global.appConstant.cm
          .CONTRACT_WORKLIST_STATUS_WAITING_CONFIRMATION ||
      contractNego.vendorNegotiationStatus.code ===
        this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_ON_PROGRESS
    ) {
      this.global.routerParams.set('todo', 'view');
    } else {
      this.global.routerParams.set('todo', 'edit');
    }
    this.router.navigate(['/pages/contract-negotiation/detail']);
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
    } else {
      this.formGroup.value.fromDate = null;
      this.formGroup.value.untilDate = null;
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
