import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { VendorExpertiseHistory } from '../bean/vendor-expertise-history';
import { FileObject } from '../components/upload';
export class VendorExpertiseCertificateHistoryEntity extends BaseEntity {
    fileObjectList: FileObject[];
    crudInfo: string;

    vendorExpertiseHistory: VendorExpertiseHistory;
    file: File;
    createdDate: Date;
    certificateNumber: string;
    startDate: Date;
    endDate: Date;
    vendorExpertiseCertificateId: number;
}
