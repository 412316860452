import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppBadgeCatalogModule } from 'src/app/core/components/app-badge-catalog/app-badge-catalog.module';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { CartComponent } from './cart.component';

export const routes = [
  { path: '', component: CartComponent, data: { breadcrumb: '' } }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, AppBadgeCatalogModule],
  declarations: [CartComponent]
})
export class CartModule {}
