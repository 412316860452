<div class="app-widget-content-slider d-flex align-items-center">
  <div
    class="content-outer-wrapper content-outer-wrapper-left mr-1"
    *ngIf="contentOuterLeftTmpl"
  >
    <ng-container *ngTemplateOutlet="contentOuterLeftTmpl"></ng-container>
  </div>
  <div class="widget-content-slider">
    <div
      class="widget-control left"
      (click)="doScrollToLeft()"
      *ngIf="isShowControl"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    >
      <em class="fas fa-chevron-left"></em>
    </div>
    <div class="widget-content-slider-content" #widgetContentSlider>
      <ng-content></ng-content>
    </div>
    <div
      class="widget-control right"
      (click)="doScrollToRight()"
      *ngIf="isShowControl"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    >
      <em class="fas fa-chevron-right"></em>
    </div>
  </div>
  <div
    class="content-outer-wrapper content-outer-wrapper-right ml-1"
    *ngIf="contentOuterRightTmpl"
  >
    <ng-container *ngTemplateOutlet="contentOuterRightTmpl"></ng-container>
  </div>
</div>
