import { ProcurementResult } from 'src/app/core/bean/procurement-result';
import { ProcurementSow } from 'src/app/core/bean/procurement-sow';
import { BaseDTO } from '../../../core/base/base-dto';
import { Procurement } from '../../../core/bean/procurement';
import { Vendor } from '../../../core/bean/vendor';

export class DeliveryMonitoringResponse extends BaseDTO {
  fromDate: string;
  untilDate: string;
  deliveryStatus: number;
  vendorList: Vendor[] = [];
  procurementList: Procurement[] = [];
  procurementSowList: ProcurementSow[] = [];
  procurementResultList: ProcurementResult[] = [];
}
