import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { ComplianceTestGroup } from '../../core/bean/compliance-test-group';
import { ComplianceTestMaster } from '../../core/bean/compliance-test-master';
import { ProcurementDocType } from '../../core/bean/procurement-doc-type';
import { OptionListModel } from '../../core/model/option-list-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { Validators } from '../../core/validators';
import { ComplianceTestMasterChecklistModel } from './compliance-test-master-checklist-model';
import { ComplianceTestMasterModel } from './compliance-test-master-model';

@Component({
  templateUrl: './app-popup-compliance-test-master.component.html'
})
export class AppPopupComplianceTestMasterComponent extends BaseComponentComponent {
  @Input() public complianceTestMasterModel: ComplianceTestMasterModel;
  @Input() public groupNameList: string[] = [];
  @Input() public procDocTypeList: ProcurementDocType[];
  @Output() public onChange: EventEmitter<ComplianceTestMasterModel> =
    new EventEmitter();

  public header: string;
  public delChecklistList: ComplianceTestMasterChecklistModel[] = [];

  public procDocTypeOptionList: OptionListModel<ProcurementDocType> =
    new OptionListModel(true, 'code');

  public constructor(
    public ngbActiveModal: NgbActiveModal,
    public snackbarService: SnackbarService,
    public translateService: TranslateService
  ) {
    super('compliance-test-master');
  }

  public onInit(): void {
    this.header = this.complianceTestMasterModel
      ? 'compliance-test-master.popup.complianceTestGroup.editHeader'
      : 'compliance-test-master.popup.complianceTestGroup.addHeader';
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.exists(this.groupNameList)
        ])
      ],
      description: [null],
      complianceChecklistList: this.formBuilder.array([])
    });
  }

  public setFormGroup(): void {
    if (this.complianceTestMasterModel) {
      this.formGroup.patchValue({
        name: this.complianceTestMasterModel.complianceTestGroup.name,
        description:
          this.complianceTestMasterModel.complianceTestGroup.description
      });

      this.complianceTestMasterModel.complianceTestMasterChecklistModelList.forEach(
        checklistModel => {
          if (
            (checklistModel.complianceTestMaster.crudOperation &&
              checklistModel.complianceTestMaster.crudOperation !==
                this.global.appConstant.core.CRUD_OPERATION_DELETE) ||
            checklistModel.complianceTestMaster.crudOperation === undefined ||
            checklistModel.complianceTestMaster.crudOperation === null
          ) {
            const formGroup = this.formBuilder.group({
              id: checklistModel.complianceTestMaster.id,
              testName: checklistModel.complianceTestMaster.name,
              checklistDocument:
                checklistModel.procDocTypeList &&
                checklistModel.procDocTypeList.length !== 0
                  ? [checklistModel.procDocTypeList]
                  : [null],
              isExpanded: false
            });
            this.complianceChecklistList.push(formGroup);
          } else {
            this.delChecklistList.push(checklistModel);
          }
        }
      );
    } else {
      this.doAddChecklist();
    }
    this.procDocTypeOptionList.setRequestValues(this.procDocTypeList);
    this.setStateReady();
  }

  public doAddChecklist(): void {
    const formGroup = this.formBuilder.group({
      id: [null],
      testName: [null, Validators.required()],
      checklistDocument: [null, Validators.required()],
      isExpanded: true
    });

    this.complianceChecklistList.push(formGroup);
  }

  public doDeleteChecklist(event: PointerEvent, i: number): void {
    this.global.modalService
      .deleteItemConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.preventDefault();
          const checklist = this.complianceChecklistList.controls[i].value;
          if (checklist.id !== null) {
            const complianceTestMaster: ComplianceTestMaster =
              new ComplianceTestMaster();
            const checklistModel: ComplianceTestMasterChecklistModel =
              new ComplianceTestMasterChecklistModel();
            complianceTestMaster.id = checklist.id;
            complianceTestMaster.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_DELETE;
            complianceTestMaster.name = checklist.testName;
            checklistModel.complianceTestMaster = complianceTestMaster;
            checklistModel.procDocTypeList = checklist.checklistDocument;
            this.delChecklistList.push(checklistModel);
          }
          this.complianceChecklistList.removeAt(i);
          this.snackbarService.showWarning(
            'compliance-test-master.msg.info.delete'
          );
        }
      });
  }

  public get complianceChecklistList(): FormArray {
    return this.formGroup.get('complianceChecklistList') as FormArray;
  }

  public onDrop(event: CdkDragDrop<string[]>): void {
    this.moveItemInFormArray(
      this.complianceChecklistList,
      event.previousIndex,
      event.currentIndex
    );
  }

  public moveItemInFormArray(
    formArray: FormArray,
    fromIndex: number,
    toIndex: number
  ): void {
    const dir = toIndex > fromIndex ? 1 : -1;

    const from = fromIndex;
    const to = toIndex;

    const temp = formArray.at(from);
    for (let i = from; i * dir < to * dir; i = i + dir) {
      const current = formArray.at(i + dir);
      formArray.setControl(i, current);
    }
    formArray.setControl(to, temp);
  }

  public onMouseEnter(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public doCancel(): void {
    this.ngbActiveModal.close();
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      if (!this.complianceTestMasterModel) {
        this.complianceTestMasterModel = new ComplianceTestMasterModel();
        const complianceTestGroup = new ComplianceTestGroup();
        this.complianceTestMasterModel.complianceTestGroup =
          complianceTestGroup;
      }
      this.complianceTestMasterModel.complianceTestGroup.name =
        this.formGroup.value.name;
      this.complianceTestMasterModel.complianceTestGroup.description =
        this.formGroup.value.description;
      const checklistModelList: ComplianceTestMasterChecklistModel[] = [];
      this.formGroup.value.complianceChecklistList.forEach(masterChecklist => {
        const complianceTestMaster: ComplianceTestMaster =
          new ComplianceTestMaster();
        const checklistModel: ComplianceTestMasterChecklistModel =
          new ComplianceTestMasterChecklistModel();
        complianceTestMaster.id = masterChecklist.id;
        if (masterChecklist.id !== null) {
          complianceTestMaster.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_UPDATE;
        } else {
          complianceTestMaster.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        }
        complianceTestMaster.name = masterChecklist.testName;
        checklistModel.complianceTestMaster = complianceTestMaster;
        checklistModel.procDocTypeList = masterChecklist.checklistDocument;
        checklistModelList.push(checklistModel);
      });
      this.complianceTestMasterModel.complianceTestMasterChecklistModelList =
        checklistModelList.concat(this.delChecklistList);
      this.onChange.emit(this.complianceTestMasterModel);
    }
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data.code;
    }
  }
}
