import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { TextUtils } from '../../utils';
import { WorkPathItem } from './interface/work-path-item.model';
import { WorkPathModel } from './model/work-path-model';
import { WorkPathStatusType } from './type/work-path-status-type';
@Component({
  selector: 'app-work-path',
  templateUrl: './app-work-path.component.html',
  styleUrls: ['./app-work-path.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWorkPathComponent extends BaseComponentComponent {
  @Input() public model: WorkPathModel;
  @Output() public onChange: EventEmitter<WorkPathItem> = new EventEmitter();
  public generatedId: string = TextUtils.generateRandomString();
  constructor() {
    super('app-work-path');
  }

  onInit(): void {
    if (!this.model.isWaitingFromServer) {
      this.buildWorkpath();
      this.setStateReady();
    } else {
      this.model.valueChanges.subscribe(() => {
        this.buildWorkpath();
        this.setStateReady();
      });
    }
  }

  public buildWorkpath(): void {
    const parentElement = document.getElementById(this.generatedId);
    const ulElement = document.createElement('ul');
    ulElement.classList.add('work-path-list');
    const workPathItemWidth = 100 / this.model.workPathItemList.length + '%';
    this.model.workPathItemList.forEach(
      (workPathItem: WorkPathItem, index: number) => {
        const activeModuleCode =
          this.global.activeModuleCode || workPathItem.activeCode;
        const isCurrentPosition =
          workPathItem.code.toLowerCase() ===
          (activeModuleCode && activeModuleCode.toLowerCase());
        const liElement = document.createElement('li');
        liElement.classList.add('work-path-item');
        liElement.style.width = workPathItemWidth;
        liElement.id = `work-path-item-${this.generatedId}-${index}`;
        this.setLiElementClassByStatus(workPathItem.status, liElement);
        if (
          (workPathItem.status === 'CURRENT' && !isCurrentPosition) ||
          (workPathItem.status === 'DONE' && isCurrentPosition)
        ) {
          liElement.classList.add('active');
        }
        const spanWorkPathItemTextElement = document.createElement('span');
        spanWorkPathItemTextElement.classList.add('work-path-item-text');
        spanWorkPathItemTextElement.textContent = workPathItem.moduleTitle;
        const spanWorkPathItemRoundedIElement = this.buildSpanWorkPathItemRoundedContent(
          workPathItem,
          index + 1
        );
        this.doSetTitle(spanWorkPathItemRoundedIElement, workPathItem);
        this.buildLiElementContent(
          liElement,
          spanWorkPathItemTextElement,
          spanWorkPathItemRoundedIElement,
          index
        );
        ulElement.appendChild(liElement);
      }
    );
    parentElement.appendChild(ulElement);
  }

  public setLiElementClassByStatus(
    status: WorkPathStatusType,
    liElement: any
  ): void {
    if (status) {
      liElement.classList.add(status === 'DONE' ? 'completed' : 'current');
    }
  }

  public buildSpanWorkPathItemRoundedContent(
    workPathItem: WorkPathItem,
    index: number
  ): any {
    const spanWorkPathItemRoundedIElement = document.createElement('span');
    spanWorkPathItemRoundedIElement.classList.add('work-path-item-rounded');
    switch (workPathItem.status) {
      case 'DONE':
        const iElementFaCheck = document.createElement('em');
        iElementFaCheck.classList.add('fas', 'fa-check');
        spanWorkPathItemRoundedIElement.appendChild(iElementFaCheck);
        break;
      case 'CURRENT':
        const iElementFaClock = document.createElement('em');
        iElementFaClock.classList.add('far', 'fa-clock');
        spanWorkPathItemRoundedIElement.appendChild(iElementFaClock);
        break;
      default:
        spanWorkPathItemRoundedIElement.textContent = index.toString();
        break;
    }
    if (workPathItem.status === 'CURRENT' || workPathItem.status === 'DONE') {
      spanWorkPathItemRoundedIElement.addEventListener('click', event => {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        const workPathItemElement = document.getElementById(
          `work-path-item-${this.generatedId}-${index - 1}`
        );
        this.doRemoveAllActiveClass(workPathItemElement.parentElement);
        workPathItemElement.classList.add('active');
        this.global.isModuleChange = true;
        this.onChange.emit(workPathItem);
      });
    }
    return spanWorkPathItemRoundedIElement;
  }

  private doSetTitle(
    spanRoundedElement: any,
    workPathItem: WorkPathItem
  ): void {
    if (workPathItem.status === 'CURRENT') {
      spanRoundedElement.title = this.global.translateService.instant(
        'app-work-path.title.waiting'
      );
    } else if (workPathItem.status === 'DONE') {
      spanRoundedElement.title = this.global.translateService.instant(
        'app-work-path.title.done'
      ) + ' - ' + workPathItem.date;
    }
  }

  public buildLiElementContent(
    liElement: any,
    spanWorkPathItemTextElement: any,
    spanWorkPathItemRoundedIElement: any,
    index: number
  ): void {
    if (index % 2 === 0) {
      liElement.appendChild(spanWorkPathItemTextElement);
      liElement.appendChild(spanWorkPathItemRoundedIElement);
    } else {
      liElement.appendChild(spanWorkPathItemRoundedIElement);
      liElement.appendChild(spanWorkPathItemTextElement);
    }
  }

  public doRemoveAllActiveClass(parentElement: any): void {
    for (let i = 0; i < parentElement.children.length; i++) {
      parentElement.children.item(i).classList.remove('active');
    }
  }
}
