<app-popup header="app-popup-address-book.title" [isLoading]="formLoading">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>

    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'app-popup-address-book.form.code' | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field autofocus="true" size="LG" formControlName="code"
          maxlength="32"></app-text-field>
      </div>
    </div>

    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'app-popup-address-book.form.organization' | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-choose size="LG" formControlName="organizationName"
          (onClick)="doChooseOrganization($event)"></app-choose>
      </div>
    </div>

    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'app-popup-address-book.form.addressName' | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field size="LG" formControlName="address" maxlength="32">
        </app-text-field>
      </div>
    </div>

    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{ 'app-popup-address-book.form.pic'
        | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-choose size="LG" formControlName="userName"
          placeholder="app.choose.user" (onClick)="doChooseUser($event)">
        </app-choose>
      </div>
    </div>

    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'app-popup-address-book.form.picPhone' | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field size="LG" formControlName="phone" type="integer"
          maxLength="32"></app-text-field>
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'app-popup-address-book.form.addressDetail' | translate }}</label>
      <div class="col-sm-9 text-left">
        <app-text-area size="LG" formControlName="addressDetail"
          maxlength="512"></app-text-area>
      </div>
    </div>
    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate }}</app-button>
        <app-button color="PRIMARY" (onClick)="doSubmit()">{{ 'app.button.save'
          | translate }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>