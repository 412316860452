<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="all-performance.titleGenerate" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-fieldset>
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'all-performance.form.period' | translate }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box autofocus="true" [optionList]="periodList"
                formControlName="period">
              </app-combo-box>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{ 'all-performance.form.year'
              | translate }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box size="SM" [optionList]="yearList"
                formControlName="year">
              </app-combo-box>
            </div>
          </div>

          <div class="row justify-content-center mt-5">
            <app-button color="PRIMARY" (onClick)="doSearch()"
              [disabled]="formSaving">
              {{ 'app.button.search' | translate }}
            </app-button>
          </div>
        </form>

      </app-fieldset>

      <ng-container *ngIf="isSearch">
        <app-table [model]="tableResponse"
          [isServerSide]="true" stringUrl="/all-performance/index-vendor"
          (onChange)="onChange($event)">
        </app-table>
      </ng-container>

      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="doBack()">
          {{ 'app.button.back' | translate }}
        </app-button>
        <app-button color="PRIMARY" (onClick)="doSubmit()"
          [disabled]="formSaving">
          {{ 'all-performance.button.submit' | translate }}
        </app-button>
      </div>

    </app-card>
  </div>
</div>