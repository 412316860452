import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as chartAction from '../actions/chart.action';
import { dispatchChart } from '../actions/dispatch-chart.action';
import { Chart } from '../models/chart';
import { ChartModel } from '../models/chart-model';
@Injectable()
export class ChartStoreService {
  public chartState: ChartModel;
  public isLoading: boolean;
  public resultChanges: EventEmitter<Array<Chart>> = new EventEmitter();
  public result: Array<Chart> = [];
  public setState(chartState: ChartModel): Observable<Array<Chart>> {
    this.chartState = chartState;
    return this.resultChanges;
  }

  public dispatch(action: chartAction.All): void {
    this.result = dispatchChart(this.chartState, action);
    this.resultChanges.emit(this.result);
  }
}
