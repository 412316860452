import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { ClaimUnclaim } from '../../core/bean/claim-unclaim';
@Component({
  templateUrl: 'claim-unclaim.component.html'
})
export class ClaimUnclaimComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<ClaimUnclaim>;
  constructor(translateService: TranslateService) {
    super('claim-unclaim', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code'
      },
      {
        field: 'name',
        header: 'table.column.name'
      },
      {
        field: 'isClaimed',
        header: 'table.column.isClaimed',
        customClass: 'text-center',
        datamap: '{"false" : "No" , "true" : "Yes" }'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/claim-unclaim/add']);
  }

  public doEdit(claimUnclaim: ClaimUnclaim): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('claimUnclaimId', claimUnclaim.id);
    this.router.navigate(['/pages/claim-unclaim/edit']);
  }

  public doDelete(claimUnclaim: ClaimUnclaim): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<ClaimUnclaim>>('/claim-unclaim/delete', claimUnclaim)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
