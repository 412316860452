<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="response-rfi.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div
          *ngFor="
            let rfiQuestion of responseRfiDTO.rfiQuestionList;
            let i = index
          "
        >
          <app-fieldset>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'response-rfi.question' | translate
              }}</label>
              <div class="col-sm-7 text-left">
                <p>{{ rfiQuestion.question }}</p>
              </div>
            </div>

            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'response-rfi.answer' | translate
              }}</label>
              <div class="col-9 text-left">
                <ng-container
                  *ngIf="rfiQuestion.formObject.code === 'TEXT-FIELD'"
                >
                  <app-text-field
                    [formControlName]="'generated' + i"
                  maxLength="128"></app-text-field>
                </ng-container>

                <ng-container
                  *ngIf="rfiQuestion.formObject.code === 'RADIO-BUTTON'"
                >
                  <app-radio
                    position="HORIZONTAL"
                    [formControlName]="'generated' + i"
                    [optionList]="mapperOptionsList.get('optionList' + i)"
                  >
                  </app-radio>
                </ng-container>

                <ng-container
                  *ngIf="rfiQuestion.formObject.code === 'CHECK-BOX'"
                >
                  <app-check-box
                    position="HORIZONTAL"
                    [formControlName]="'generated' + i"
                  >
                  </app-check-box>
                </ng-container>

                <ng-container
                  *ngIf="rfiQuestion.formObject.code === 'COMBO-BOX'"
                >
                  <app-combo-box
                    position="HORIZONTAL"
                    [formControlName]="'generated' + i"
                    [optionList]="mapperOptionsList.get('optionList' + i)"
                  >
                  </app-combo-box>
                </ng-container>

                <ng-container
                  *ngIf="rfiQuestion.formObject.code === 'TEXT-AREA'"
                >
                  <app-text-area
                    [formControlName]="'generated' + i"
                  ></app-text-area>
                </ng-container>

                <ng-container
                  *ngIf="rfiQuestion.formObject.code === 'DATE-PICKER'"
                >
                  <app-date-picker
                    [formControlName]="'generated' + i"
                  ></app-date-picker>
                </ng-container>

                <ng-container *ngIf="rfiQuestion.formObject.code === 'WYSIWYG'">
                  <app-wysiwyg
                    [formControlName]="'generated' + i"
                  ></app-wysiwyg>
                </ng-container>

                <ng-container
                  *ngIf="rfiQuestion.formObject.code === 'AUTOCOMPLETE'"
                >
                  <app-auto-complete
                    [formControlName]="'generated' + i"
                    stringUrl="/response-rfi/auto-complete-list-of-value"
                    [params]="{rfiQuestionId: rfiQuestion.id}"
                  >
                  </app-auto-complete>
                </ng-container>

                <ng-container *ngIf="rfiQuestion.formObject.code === 'CHOSEN'">
                  <app-chosen
                    [formControlName]="'generated' + i"
                    [optionList]="mapperOptionsList.get('optionList' + i)"
                  ></app-chosen>
                </ng-container>

                <ng-container
                  *ngIf="rfiQuestion.formObject.code === 'TOGGLE-SWITCH'"
                >
                  <app-toggle-switch
                    [formControlName]="'generated' + i"
                  ></app-toggle-switch>
                </ng-container>

                <ng-container
                  *ngIf="rfiQuestion.formObject.code === 'TEXT-FIELD-NUMERIC'"
                >
                  <app-text-field
                    [formControlName]="'generated' + i"
                    type="integer"
                  ></app-text-field>
                </ng-container>

                <ng-container
                  *ngIf="rfiQuestion.formObject.code === 'FIELD-PRICE'"
                >
                  <app-currency
                    [formControlName]="'generated' + i"
                    [optionList]="currencyOptionList"
                  ></app-currency>
                </ng-container>

                <ng-container
                  *ngIf="rfiQuestion.formObject.code === 'UPLOAD-SINGLE'"
                >
                  <app-upload
                    [formControlName]="'generated' + i"
                    [fileUploader]="mapperFileUploader.get('fileUploader' + i)"
                  ></app-upload>
                </ng-container>
              </div>
            </div>
          </app-fieldset>
        </div>

        <div class="button-group button-group-center mt-5">
          <app-button
            mode="BYPASS"
            color="SECONDARY"
            (onClick)="doBack()"
            [disabled]="formSaving"
            >{{ 'app.button.previous' | translate }}</app-button
          >
          <app-button (onClick)="doSave()" [disabled]="formSaving">{{
            'app.button.save' | translate
          }}</app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
