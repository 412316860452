import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppFieldsetModule } from '../app-fieldset/app-fieldset.module';
import { AppPopupModule } from '../app-popup/app-popup.module';
import { AppTableModule } from '../table/components/app-table/app-table.module';
import { AppDeliverableItemComponent } from './app-deliverable-item.component';
import { AppPopupDeliverableItemDetailComponent } from './app-popup-deliverable-item-detail.component';

@NgModule({
  imports: [
    VendorModule,
    AppFieldsetModule,
    AppPopupModule,
    AppButtonModule,
    AppCardModule,
    AppTableModule,
    PipesModule
  ],
  declarations: [
    AppDeliverableItemComponent,
    AppPopupDeliverableItemDetailComponent
  ],
  exports: [AppDeliverableItemComponent, AppPopupDeliverableItemDetailComponent]
})
export class AppDeliverableItemModule {}
