import { File as FileBean } from '../../../bean/file';
import { TextUtils } from '../../../utils';
export function createUploaderFileObject(file: File): FileBean {
  const fileBean: FileBean = new FileBean();
  const nameSplit = file.name.split('.');
  fileBean.extension = nameSplit.pop();
  const randomString = TextUtils.generateRandomString(1);
  fileBean.fileName = file.name.replace(/\#/g, randomString);
  fileBean.size = file.size;
  fileBean.isImage = file.type.includes('image');
  return fileBean;
}
