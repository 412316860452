import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { addtionalInformation, dataTempList } from './data-dummy';
@Component({
  templateUrl: './example-one.component.html',
  styleUrls: ['./example-one.component.scss']
})
export class ExampleOneComponent extends BaseModuleComponent {
  constructor(translate: TranslateService) {
    super('employee', translate);
  }

  onInit(): void {
    this.buildFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      generated: this.formGroupService.builder(dataTempList),
      addtionalInformation: this.formGroupService.builder(addtionalInformation)
    });
  }

  public get addtionalInformation(): FormArray {
    return this.formGroup.get('addtionalInformation') as FormArray;
  }

  public doAdd(): void {
    const item = { fieldName: 'Addtional' + this.addtionalInformation.controls.length, isMandatory: false };
    this.addtionalInformation.push(this.formGroupService.builder(item));
  }

  public doDelete(index: number): void {
    this.addtionalInformation.controls.splice(index, 1);
  }

  public doSubmit(): void {
    this.log.info('Info: I am comming from handleSubmit');
    this.log.info(this.formGroup.value);
  }

  public get formArrayGenerated(): FormArray {
    return this.formGroup.get('generated') as FormArray;
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }

}
