import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BackgroundImageDirective } from './background-image.directive';
import { FloatingButtonDirective } from './floating-button.directive';
import { ImgDirective } from './img.directive';
import { AlphabetDirective } from './input/alphabet.directive';
import { AlphanumericDirective } from './input/alphanumeric.directive';
import { AutofocusDirective } from './input/autofocus.directive';
import { CurrencyDirective } from './input/currency.directive';
import { DecimalDirective } from './input/decimal.directive';
import { AppIntegerDirective } from './input/integer.directive';
import { MaxLineDirective } from './max-line.directive';
import { ReadMoreDirective } from './read-more.directive';
import { WidgetDirective } from './widget/widget.directive';
@NgModule({
  imports: [CommonModule],
  declarations: [
    WidgetDirective,
    AlphabetDirective,
    CurrencyDirective,
    DecimalDirective,
    AlphanumericDirective,
    AutofocusDirective,
    AppIntegerDirective,
    FloatingButtonDirective,
    ImgDirective,
    BackgroundImageDirective,
    MaxLineDirective,
    ReadMoreDirective
  ],
  exports: [
    WidgetDirective,
    AlphabetDirective,
    CurrencyDirective,
    DecimalDirective,
    AlphanumericDirective,
    AutofocusDirective,
    AppIntegerDirective,
    FloatingButtonDirective,
    ImgDirective,
    BackgroundImageDirective,
    MaxLineDirective,
    ReadMoreDirective
  ]
})
export class DirectivesModule {}
