import { ParticipantRole } from '../bean/participant-role';
import { Region } from '../bean/region';
import { User } from '../bean/user';
import { BaseEntity } from './base-entity';

export class BaseParticipantEntity extends BaseEntity {

    roomCode: string; /** unique */
    crudOperation: number; /* to delete */
    user: User;
    participantRole: ParticipantRole;
    phoneRegion: Region;

    name: string;
    email: string;
    positionName: string;
    companyName: string;
    departmentName: string;
    phone: string;
    isJoin: boolean;
    isOnline: boolean;
    isBlocked: boolean;
}
