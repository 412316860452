import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { Validators } from '../../../core/validators';
import { AccountSettingRequest } from '../account-setting.request';
@Component({
  templateUrl: './app-popup-change-email.component.html',
  styleUrls: ['./app-popup-change-email.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupChangeEmailComponent extends BaseComponentComponent {
  @Input() accountSettingRequest: AccountSettingRequest
  @Output() onChange: EventEmitter<string> = new EventEmitter();
  public hasSent: boolean;
  constructor() {
    super('app-popup-change-email');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      email: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.email(),
          Validators.maxLength(64),
          Validators.exists(
            [this.accountSettingRequest.user.email],
            'Tidak boleh sama dengan email sebelumnya'
          )
        ])
      ],
      confirmEmail: [
        null,
        Validators.compose([Validators.required(), Validators.match('email')])
      ]
    });
  }

  public setFormGroup(): void {
    if (this.accountSettingRequest.newEmail) {
      this.formGroup.get('email').patchValue(this.accountSettingRequest.newEmail);
    }
  }

  public doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .confirm('Are your sure to change your email address?', 'Changes email')
        .subscribe((isChange: boolean) => {
          if (isChange) {
            this.setStateProcessing();
            this.accountSettingRequest.newEmail = this.formGroup.get('email').value;
            this.httpClientService
              .post<Response<AccountSettingRequest>>(
                '/account-setting/send-verification-email',
                this.accountSettingRequest
              )
              .subscribe((resp: Response<AccountSettingRequest>) => {
                if (resp.status === ResponseStatusModel.OK) {
                  this.hasSent = true;
                  this.global.alertService.showSuccess(
                    `Confirmation link has been sent to your email`,
                    '#form-change-email'
                  );
                } else {
                  this.global.alertService.showError(resp.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doChangeEmail(): void {
    this.hasSent = false;
    this.formGroup.reset();
    this.formGroup.enable();
  }
}
