import { BillingMemo } from 'src/app/core/bean/billing-memo';
import { BillingMemoBudget } from 'src/app/core/bean/billing-memo-budget';
import { BillingMemoOtherCost } from 'src/app/core/bean/billing-memo-other-cost';
import { BillingMemoPriority } from 'src/app/core/bean/billing-memo-priority';
import { BillingMemoTax } from 'src/app/core/bean/billing-memo-tax';
import { BudgetAllocation } from 'src/app/core/bean/budget-allocation';
import { OtherCost } from 'src/app/core/bean/other-cost';
import { PaymentMethod } from 'src/app/core/bean/payment-method';
import { PaymentType } from 'src/app/core/bean/payment-type';
import { Region } from 'src/app/core/bean/region';
import { Tax } from 'src/app/core/bean/tax';
import { Vendor } from 'src/app/core/bean/vendor';
import { VendorBank } from 'src/app/core/bean/vendor-bank';
import { VendorBankOffline } from 'src/app/core/bean/vendor-bank-offline';
import { VendorOffline } from 'src/app/core/bean/vendor-offline';
import { SignerHCSView } from 'src/app/core/view/entity/bean/signer-hcs-view';

export class BillingMemoEditResponse {
    public taxList: Tax[];
    public otherCostList: OtherCost[];
    public budgetAllocationList: BudgetAllocation[];
    public billingMemoBudgetList: BillingMemoBudget[];
    public billingMemoTaxList: BillingMemoTax[];
    public billingMemoOtherCostList: BillingMemoOtherCost[];
    public billingMemoPriorityList: BillingMemoPriority[];
    public paymentMethodList: PaymentMethod[];
    public paymentTypeList: PaymentType[];
    public regionList: Region[];
    public billingMemo: BillingMemo;
    public vendor: Vendor;
    public vendorBank: VendorBank;
    public vendorOffline: VendorOffline;
    public vendorBankOffline: VendorBankOffline;
    public firstSigner: SignerHCSView;
    public secondSigner: SignerHCSView;
    public companyPPN: number;
    public isWAPU: boolean;
}
