import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Package } from 'src/app/core/bean/package';
import { VictimDiagnose } from 'src/app/core/bean/victim-diagnose';
import { DiagnosePackageResponse } from './diagnose-package.response';
import { Procedure } from 'src/app/core/bean/procedure';
import { DiagnosePackageRequest } from './diagnose-package.resquest';

@Component({
  templateUrl: './diagnose-package-edit-add.component.html'
})
export class DiagnosePackageEditAddComponent extends BaseModuleComponent {
  public packageId: number;
  public package: Package;
  public diagnosePackageResponse: DiagnosePackageResponse;
  public victimDiagnoseOptionList: OptionListModel<VictimDiagnose> =
    new OptionListModel(false);
  public procedureOptionList: OptionListModel<Procedure> =
    new OptionListModel(false);
  constructor(translateService: TranslateService) {
    super('diagnose-package', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.packageId = this.global.routerParams.get('packageId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(128)])
      ],
      victimDiagnose: [null, Validators.required()],
      procedureList: [null, Validators.compose([Validators.required()])]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<DiagnosePackageResponse>(
        '/diagnose-package/add-edit',
        new RouteRequestModel(this.todo, this.packageId)
      )
      .subscribe(response => {
        this.diagnosePackageResponse = response;
        this.victimDiagnoseOptionList.setRequestValues(
          this.diagnosePackageResponse.victimDiagnoseList
        );
        this.procedureOptionList.setRequestValues(
          this.diagnosePackageResponse.procedureList
        );
        if (this.diagnosePackageResponse.packageBean != null) {
          this.package = this.diagnosePackageResponse.packageBean;
          const procedureList = this.diagnosePackageResponse.procedurePackageList?.map(
            procedurePackage => procedurePackage.procedure
          );
          this.formGroup.patchValue({
            id: this.package.id,
            code: this.package.code,
            name: this.package.name,
            victimDiagnose: this.package.victimDiagnose,
            procedureList: procedureList
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            const diagnosePackageRequest: DiagnosePackageRequest = new DiagnosePackageRequest();

            diagnosePackageRequest.packageBean = {
              id: this.formGroup.get('id')?.value,
              code: this.formGroup.get('code')?.value,
              name: this.formGroup.get('name')?.value,
              victimDiagnose: this.formGroup.get('victimDiagnose')?.value
            } as any;

            diagnosePackageRequest.procedureList = this.formGroup.get('procedureList')?.value;

            const url: string =
              this.todo === 'edit' ? '/diagnose-package/update' : '/diagnose-package/insert';

            this.httpClientService
              .post<Response<Package>>(url, diagnosePackageRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/diagnose-package/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/diagnose-package/']);
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
}
