<div class="row main-row app-accordion-row">
  <div class="col-12">
    <app-card
      header="payment-history.title"
      detail="payment-history.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-accordion [isLoading]="formLoading">
        <app-accordion-item
          header="payment-history.accordion.paymentInformation"
          [isExpanded]="true"
        >
          <app-payment-information-view
            [paymentId]="paymentId"
          ></app-payment-information-view>
        </app-accordion-item>
        <app-accordion-item header="payment-history.accordion.activityHistory">
          <app-activity-history-view
            [paymentId]="paymentId"
            [activityType]="2"
          ></app-activity-history-view>
        </app-accordion-item>
      </app-accordion>
      <div class="button-group button-group-center button-group-icon-text mt-5">
        <app-button
          mode="BYPASS"
          color="SECONDARY"
          (onClick)="doCancel()"
          [disabled]="formSaving"
        >
          {{ 'app.button.back' | translate }}
        </app-button>
        <app-button mode="BYPASS" (onClick)="doOpenPopupPrint()">
          <em class="fas fa-print"></em>{{ 'app.button.print' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>
