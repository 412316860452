import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { ModuleGroupComponent } from './module-group.component';
const routes: Routes = [
    { path: '', component: ModuleGroupComponent, data: { breadcrumb: '' } }
];
@NgModule({
    imports: [SharedModule, RouterModule.forChild(routes)],
    declarations: [ModuleGroupComponent]
})
export class ModuleGroupModule { }

