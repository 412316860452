<div class="row main-row vendor-reactivation-detail-vendor">
    <div class="col-lg-12 mb-4">
        <app-card header="" [isLoading]="formLoading" [isSaving]="formSaving">
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>
                <router-outlet></router-outlet>
                <!-- <div class="row justify-content-center my-4">
                    <button type="button " class="btn btn-secondary " 
                        (click)="doCancel()" [disabled]="formSaving">
                        {{ 'app.button.back' | translate}}
                    </button>
                </div> -->
            </form>
        </app-card>
    </div>
</div>