<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="vendor-certificate.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-fieldset legend="Filter">
        <form class="form-horizontal pb-4" [formGroup]="formGroup" novalidate>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-certificate.form.vendorCode' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                autofocus="true"
                size="SM"
                formControlName="vendorCode"
                tooltip="{{ 'vendor-certificate.tooltip' | translate }}"
              >
              </app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-certificate.form.vendorName' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="vendorName"
                tooltip="{{ 'vendor-certificate.tooltip' | translate }}"
              >
              </app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-certificate.form.releaseDate' | translate
            }}</label>
            <div class="col-sm-9 text-left d-flex">
              <!-- <label class="mr-3 control-label">{{
                'vendor-certificate.form.from' | translate
              }}</label> -->
              <app-date-picker
                size="XL"
                formControlName="releaseDateFrom"
                maxDate="releaseDateTo"
              >
              </app-date-picker>
              <label class="ml-3 mr-3 control-label">{{
                'vendor-certificate.form.until' | translate
              }}</label>
              <app-date-picker
                size="XL"
                formControlName="releaseDateTo"
                minDate="releaseDateFrom"
              >
              </app-date-picker>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-certificate.form.certificateStatus' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box
                [optionList]="certificateStatusList"
                formControlName="certificateStatus"
              ></app-combo-box>
            </div>
          </div>

          <div
            class="button-group button-group-center button-group-icon-text mt-5"
          >
            <app-button
              mode="BYPASS"
              type="BTN_SECONDARY"
              color="SECONDARY"
              (onClick)="doReset()"
            >
              <em class="fas fa-sync-alt"></em
              >{{ 'app.button.reset' | translate }}
            </app-button>
            <app-button
              mode="BYPASS"
              type="BTN_PRIMARY"
              (onClick)="doApply()"
              [disabled]="formSaving"
              ><em class="fas fa-check"></em
              >{{ 'app.button.apply' | translate }}</app-button
            >
          </div>
        </form>
      </app-fieldset>

      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/vendor-certificate/index"
      >
        <ng-template #headerButtons>
          <app-button
            mode="BYPASS"
            (onClick)="doExport()"
            [disabled]="isLoading"
            ><em
              class="fas"
              [ngClass]="
                !isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'
              "
            ></em
            >{{ 'app.button.export' | translate }}</app-button
          >
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            mode="BYPASS"
            size="TN"
            (onClick)="doViewCertificate(rowData)"
            title="{{ 'app.tooltip.detail' | translate }}"
          >
            <em class="fas fa-search"></em>
          </app-button>
          <app-button
            size="TN"
            (onClick)="doSendEmail(rowData)"
            *ngIf="rowData.certificateStatus != null && rowData.certificateStatus.code == global.appConstant.vm.CERTIFICATE_STATUS_EXPIRED"
            title="{{ 'vendor-certificate.vc.tooltip.send-email' | translate }}"
          >
            <em class="fas fa-envelope"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
