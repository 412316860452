import { BaseEntity } from '../base/base-entity';
import { PaymentTerm } from '../bean/payment-term';
import { ProcurementSow } from '../bean/procurement-sow';
import { ProcurementSowPaymentTermBilling } from '../bean/procurement-sow-payment-billing';
import { ProcurementSowPaymentTermItem } from '../bean/procurement-sow-payment-term-item';

export class ProcurementSowPaymentTermEntity extends BaseEntity {
  isScored: boolean;

  procurementSowPaymentTermItemList: ProcurementSowPaymentTermItem[] = [];
  procurementSowPaymentTermBillingList: ProcurementSowPaymentTermBilling[] = [];
  paymentTerm: PaymentTerm = new PaymentTerm();
  procurementSow: ProcurementSow = new ProcurementSow();
  isPartial: boolean;
  amount: number;
  percentageValue: number;
  description: string;
  status: number;
  workPercentageValue: number;
  isFinishAssessed: boolean;
  isAssessment: boolean;
  isPaymentProcess: boolean;
}
