import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalPrcsXModule } from '../../core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppItemRequestModule } from '../../core/components/app-item-request/app-item-request.module';
import { AppProcurementInfoModule } from '../../core/components/app-procurement-info/app-procurement-info.module';
import { AppUploadDocumentTableXModule } from '../../core/components/app-upload-document-table-x/app-upload-document-table-x.module';
import { AppWorkflowStepInfoModule } from '../../core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { UploadDocumentEditComponent } from './upload-document-edit.component';
const routes = [
  {
    path: 'add',
    component: UploadDocumentEditComponent,
    data: { breadcrumb: 'upload-document.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: UploadDocumentEditComponent,
    data: { breadcrumb: 'upload-document.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: UploadDocumentEditComponent,
    data: { breadcrumb: 'upload-document.breadcrumb.detail' }
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppWorkflowStepInfoModule,
    AppProcurementInfoModule,
    AppItemRequestModule,
    AppUploadDocumentTableXModule,
    AppApprovalPrcsXModule,
    AppBadgeCatalogModule
  ],
  declarations: [UploadDocumentEditComponent]
})
export class UploadDocumentModule { }
