import { EventEmitter } from '@angular/core';
import { LatLngRequestModel } from './lat-lng-request-model';
import { LatLngResponseModel } from './lat-lng-response-model';
export class MapResponseModel {
  public originalResource: any;
  public zoom = 5;
  public defaultLatLng: Array<number> = [
    -6.235101108437181,
    106.83448791503908
  ];
  public latitude: number;
  public longitude: number;
  public address: string;
  public valueChanges: EventEmitter<boolean> = new EventEmitter();
  constructor(
    latLng?: string,
    public isViewOnly: boolean = false,
    public isServerSide: boolean = false
  ) {
    const coordinate = this.convertStringToLatLng(latLng);
    this.latitude = coordinate.latitude;
    this.longitude = coordinate.longitude;
  }

  public convertStringToLatLng(strLatLng: string): LatLngResponseModel {
    let newStrLatLng: string;
    strLatLng === null || !strLatLng
      ? (newStrLatLng = '-6.235101108437181,106.83448791503908')
      : (newStrLatLng = strLatLng);
    const arrayLatLng = newStrLatLng.split(',');
    const latitude = +arrayLatLng[0].trim();
    const longitude = +arrayLatLng[1].trim();
    return { latitude, longitude };
  }

  public setLatitudeAndLongitude(latitude: number, longitude: number): void {
    this.latitude = latitude;
    this.longitude = longitude;
    this.valueChanges.emit(true);
  }

  public setAddress(addressText: string): void {
    this.address = addressText;
  }

  public getAddress(): string {
    return this.address;
  }

  public getTextValue(): string {
    return this.latitude + ',' + this.longitude;
  }

  public getResponseValue(): LatLngResponseModel {
    return {
      latitude: this.latitude,
      longitude: this.longitude
    };
  }

  public getRequestValue(): LatLngRequestModel {
    return {
      lat: +this.latitude,
      lng: +this.longitude
    };
  }

  public getMarkerTooltipText(): string {
    return `<strong>Latitude:</strong> ${this.latitude}<br/><strong>Longitude:</strong> ${this.longitude}`;
  }

  public getCurrentPosition(): any {
    return this.latitude && this.longitude
      ? [this.latitude, this.longitude]
      : this.defaultLatLng;
  }
}
