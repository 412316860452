import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Procurement } from 'src/app/core/bean/procurement';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { VendorQuotationView } from 'src/app/core/view/entity/bean/vendor-quotation-view';
import { PriceEvaluationDetail } from '../../core/bean/price-evaluation-detail';
import { ProcurementAuction } from '../../core/bean/procurement-auction';
import { AppPopupPriceVendorComponent } from './app-popup-price-vendor.component';
import { PriceEvaluationVendorEditResponse } from './price-evaluation-vendor-edit.response';

@Component({
  templateUrl: './price-evaluation-vendor-edit.component.html',
  styleUrls: ['./price-evaluation-vendor-edit.component.scss']
})
export class PriceEvaluationVendorEditComponent extends BaseModuleComponent {
  public priceEvaluationVendorEditResponse: PriceEvaluationVendorEditResponse;
  public tableResponsePriceEval: TableResponseModel<VendorQuotationView>;
  public tableResponseFinalPriceEval: TableResponseModel<VendorQuotationView>;
  public tableResponseAuctionSession: TableResponseModel<PriceEvaluationDetail>;

  public procurement: Procurement;
  public appOfficialReportModel: AppOfficialReportModel;
  public procurementId: number;
  public quotationId: number;
  public priceEvaluationStatusCode: string;
  public procurementAuction: ProcurementAuction;
  public isFailedAssessment: boolean;
  public isCancelation: boolean;
  public urlBackOutside: string;
  public isShowBadgeStatus: boolean;

  public badgeColor = {
    ON_PROGRESS: 'PROGRESS',
    DONE: 'SUCCESS',
    NEW: 'INFO',
    HOLD: 'DANGER'
  };

  public constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('price-evaluation-vendor', translateService);
  }

  public onInit(): void {
    this.getDataFromRouterParams();
    this.getDataFromServer();
  }

  public getDataFromRouterParams(): void {
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.quotationId =
      localStorage.getItem('quotationId') ||
      this.global.routerParams.get('quotationId');
    this.priceEvaluationStatusCode =
      localStorage.getItem('priceEvaluationStatusCode') ||
      this.global.routerParams.get('priceEvaluationStatusCode');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('quotationId');
    localStorage.removeItem('urlBackOutside');
    localStorage.removeItem('priceEvaluationStatusCode');
  }

  public getDataFromServer(): void {
    this.httpClientService
      .post<PriceEvaluationVendorEditResponse>(
        '/price-evaluation-vendor/edit',
        { id: this.quotationId }
      )
      .subscribe(response => {
        this.priceEvaluationVendorEditResponse = response;
        this.procurement = this.priceEvaluationVendorEditResponse.procurement;
        this.procurementAuction =
          this.priceEvaluationVendorEditResponse.procurementAuction;
        this.appOfficialReportModel =
          this.priceEvaluationVendorEditResponse.appOfficialReportModel;
        if (
          this.procurement.procurementStatus?.code ===
            this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT ||
          this.procurement.procurementStatus?.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL
        ) {
          this.isFailedAssessment = true;
        } else if (
          this.procurement.procurementStatus?.code ===
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
        ) {
          this.isCancelation = true;
        }
        this.buildTableResponse();
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.buildTableResponsePriceEval();
    this.buildTableResponseFinalPriceEval();
    this.buildTableResponseAuctionSession();
  }

  public buildTableResponsePriceEval(): void {
    this.tableResponsePriceEval = new TableResponseModel(
      this.moduleCode,
      this.procurement.biddingEvaluation.code !==
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
        ? [
            {
              field: 'vendorName',
              header: 'table.column.vendorName',
              plugins: {
                name: 'hyperlink',
                onClick: this.doOpenPopupPrice.bind(this)
              }
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScoreStatus.name',
                  header: 'table.column.technical',
                  plugins: {
                    name: 'badge',
                    colorField: 'technicalScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                this.procurement.biddingEvaluation.code ===
                this.global.appConstant.pm
                  .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
                  ? {
                      field: 'priceScoreStatus.name',
                      header: 'table.column.price',
                      plugins: {
                        name: 'badge',
                        colorField: 'priceScoreStatus.code',
                        colorMap: {
                          PASSED: 'SUCCESS',
                          NOT_PASSED: 'DANGER'
                        }
                      }
                    }
                  : {
                      field: 'priceScore',
                      header: 'table.column.price',
                      className: 'text-right',
                      plugins: {
                        name: 'text-field',
                        type: 'decimal',
                        isView: true
                      }
                    }
              ]
            },
            {
              field: 'bidPrice',
              header: 'table.column.bidPrice',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: 'text-right'
            }
          ]
        : [
            {
              field: 'vendorName',
              header: 'table.column.vendorName',
              plugins: {
                name: 'hyperlink',
                onClick: this.doOpenPopupPrice.bind(this)
              }
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScore',
                  header: 'table.column.technical',
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                },
                {
                  field: 'priceScore',
                  header: 'table.column.price',
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                },
                {
                  field: 'totalEvaluationScore',
                  header: 'table.column.total',
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                }
              ]
            },
            {
              field: 'bidPrice',
              header: 'table.column.bidPrice',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: 'text-right'
            }
          ]
    );

    this.tableResponsePriceEval.setCustomData({
      procurementId: this.procurementId
    });
  }

  public buildTableResponseFinalPriceEval(): void {
    this.tableResponseFinalPriceEval = new TableResponseModel(
      this.moduleCode,
      this.procurement.biddingEvaluation.code !==
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
        ? [
            {
              field: 'vendorName',
              header: 'table.column.vendorName',
              plugins: {
                name: 'hyperlink',
                onClick: this.doOpenPopupFinalPrice.bind(this)
              }
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScoreStatus.name',
                  header: 'table.column.technical',
                  plugins: {
                    name: 'badge',
                    colorField: 'technicalScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                this.procurement.biddingEvaluation.code ===
                this.global.appConstant.pm
                  .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
                  ? {
                      field: 'finalPriceScoreStatus.name',
                      header: 'table.column.price',
                      plugins: {
                        name: 'badge',
                        colorField: 'finalPriceScoreStatus.code',
                        colorMap: {
                          PASSED: 'SUCCESS',
                          NOT_PASSED: 'DANGER'
                        }
                      }
                    }
                  : {
                      field: 'finalPriceScore',
                      header: 'table.column.price',
                      className: 'text-right',
                      plugins: {
                        name: 'text-field',
                        type: 'decimal',
                        isView: true
                      }
                    }
              ]
            },
            {
              field: 'finalPrice',
              header: 'table.column.finalPrice',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: 'text-right'
            }
          ]
        : [
            {
              field: 'vendorName',
              header: 'table.column.vendorName',
              plugins: {
                name: 'hyperlink',
                onClick: this.doOpenPopupFinalPrice.bind(this)
              }
            },
            {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScore',
                  header: 'table.column.technical',
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                },
                {
                  field: 'finalPriceScore',
                  header: 'table.column.price',
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                },
                {
                  field: 'totalFinalEvaluationScore',
                  header: 'table.column.total',
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                }
              ]
            },
            {
              field: 'finalPrice',
              header: 'table.column.finalPrice',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: 'text-right'
            }
          ]
    );

    this.tableResponseFinalPriceEval.setCustomData({
      procurementId: this.procurementId
    });
  }

  public buildTableResponseAuctionSession(): void {
    this.tableResponseAuctionSession = new TableResponseModel(this.moduleCode, [
      {
        field: 'priceEvaluation.sessionNumber',
        header: 'table.column.auctionNumber',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClickSession.bind(this)
        }
      },
      {
        field: 'priceEvaluation.auctionType.name',
        header: 'table.column.auctionType',
        plugins: {
          name: 'badge',
          colorField: 'priceEvaluation.auctionType.code',
          colorMap: {
            OPEN: 'PROGRESS',
            CLOSED: 'PROGRESS'
          }
        }
      },
      {
        field: 'priceEvaluation.startDate',
        header: 'table.column.startTime',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'priceEvaluation.endDate',
        header: 'table.column.endTime',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'priceEvaluation.sessionStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'priceEvaluation.sessionStatus.code',
          colorMap: {
            NOT_STARTED: 'SECONDARY',
            ON_GOING: 'PROGRESS',
            DONE: 'SUCCESS',
            CANCELED: 'DANGER'
          }
        }
      }
    ]);
    this.tableResponseAuctionSession.setCustomData({
      procurementId: this.procurementId,
      quotationId: this.quotationId
    });
  }

  public doClickSession(priceEvaluationDetail: PriceEvaluationDetail): void {
    if (priceEvaluationDetail.isAttend) {
      window.location.href =
        this.global.config.parameterModel.promiseAuctionAddressUrl +
        '/vendor-auction/session/' +
        priceEvaluationDetail.priceEvaluation.code;
    } else if (priceEvaluationDetail.isAttend === null) {
      window.location.href =
        this.global.config.parameterModel.promiseAuctionAddressUrl +
        '/vendor-auction/attendance-confirmation/' +
        this.procurementAuction.promiseAuctionCode +
        '/' +
        priceEvaluationDetail.confirmationCode;
    }
  }

  public doOpenPopupPrice(vendorQuotationView: VendorQuotationView): void {
    this.appPopupService.open(
      AppPopupPriceVendorComponent,
      {
        quotationId: vendorQuotationView.id,
        isFinal: false
      },
      { size: 'xl' }
    );
  }

  public doOpenPopupFinalPrice(vendorQuotationView: VendorQuotationView): void {
    this.appPopupService.open(
      AppPopupPriceVendorComponent,
      {
        quotationId: vendorQuotationView.id,
        isFinal: true
      },
      { size: 'xl' }
    );
  }

  public translateLabel(code: string): string {
    if (code === this.global.appConstant.pm.PRICE_EVALUATION_STATUS_NEW) {
      return 'price-evaluation-vendor.badge.status.new';
    } else if (
      code === this.global.appConstant.pm.PRICE_EVALUATION_STATUS_ON_PROGRESS
    ) {
      return 'price-evaluation-vendor.badge.status.onProgress';
    } else if (
      code === this.global.appConstant.pm.PRICE_EVALUATION_STATUS_DONE
    ) {
      return 'price-evaluation-vendor.badge.status.done';
    } else if (
      code === this.global.appConstant.pm.PRICE_EVALUATION_STATUS_HOLD
    ) {
      return 'price-evaluation-vendor.badge.status.hold';
    }
  }
}
