import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VendorModule } from '../../../core/vendor/vendor.module';
import { NotFoundComponent } from './not-found.component';

const routes: Routes = [
  { path: '', component: NotFoundComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [VendorModule, RouterModule.forChild(routes)],
  declarations: [NotFoundComponent]
})
export class NotFoundModule {}
