import { Catalog } from 'src/app/core/bean/catalog';
import { Currency } from 'src/app/core/bean/currency';
import { ItemType } from 'src/app/core/bean/item-type';
import { Module } from 'src/app/core/bean/module';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PrShipping } from 'src/app/core/bean/pr-shipping';
import { User } from 'src/app/core/bean/user';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { WorkflowStepInfoModel } from 'src/app/core/components/app-workflow-step-info/model/workflow-step-info-model';
import { AppUploadDocumentTableModel } from '../../core/components/app-upload-document-table-x/app-upload-document-table-model';
import { OrderStatus } from 'src/app/core/bean/order-status';
import { Order } from 'src/app/core/bean/order';
import { PrPoVerification } from 'src/app/core/bean/pr-po-verification';
import { Organization } from 'src/app/core/bean/organization';
import { OrderShipping } from 'src/app/core/bean/order-shipping';
import { Sow } from 'src/app/core/bean/sow';

export class PrPoVerificationResponse {
  prPoVerification: PrPoVerification = new PrPoVerification();
  order: Order = new Order();
  companyCurrency: Currency;
  prShippingList: PrShipping[] = [];
  prItemList: PrItem[] = [];
  orderStatusList: OrderStatus[] = [];
  itemTypeList: ItemType[] = [];
  requestor: User;
  module: Module;
  workflowStepInfoModel: WorkflowStepInfoModel;
  appUploadDocumentTableModelList: AppUploadDocumentTableModel[] = [];
  appOfficialReportModel: AppOfficialReportModel = new AppOfficialReportModel();
  isUserApproval: boolean;
  catalogList: Catalog[] = [];
  orderShippingList: OrderShipping[] = [];
  organization: Organization;
  sowList: Sow[] = [];
}
