<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="on-boarding.title" [isLoading]="formLoading" [isSaving]="formSaving">
            <app-table [tableResponse]="tableResponse" [isServerSide]="true" stringUrl="/on-boarding/index"
                customData="{{ customData }}">
                <ng-template #actionButtons let-rowData>
                    <app-button *ngIf="rowData.vendor.vendorActivityStatus?.code !== 'APPROVAL_CANDIDATE' &&
                     rowData.vendor.vendorActivityStatus?.code !== 'APPROVAL_UPDATE_PROFILE' && 
                     rowData.vendor.vendorActivityStatus?.code !== 'APPROVAL_EXTENDED_CERTIFICATE' &&
                     (global.userSession?.user.organization.id === rowData.vendor.onBoardingByOrganization.id)"
                        size="TN" (onClick)="doEdit(rowData)" title="{{ 'app.tooltip.edit' | translate }}">
                        <em class="fas fa-pencil-alt"></em>
                    </app-button>
                    <app-button *ngIf="rowData.vendor.vendorActivityStatus?.code === 'APPROVAL_CANDIDATE' ||
                     rowData.vendor.vendorActivityStatus?.code === 'APPROVAL_UPDATE_PROFILE' ||
                     rowData.vendor.vendorActivityStatus?.code === 'APPROVAL_EXTENDED_CERTIFICATE' ||
                     (global.userSession?.user.organization.id !== rowData.vendor.onBoardingByOrganization.id) "
                        size="TN" (onClick)="doEdit(rowData)" title="{{ 'app.tooltip.view' | translate }}"
                        mode="BYPASS">
                        <em class="fas fa-search"></em>
                    </app-button>
                </ng-template>
            </app-table>
        </app-card>
    </div>
</div>