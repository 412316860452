import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { VendorActivityStatusHistory } from './../../core/bean/vendor-activity-status-history';
import { FieldFormatEnum } from './../../core/components/app-table/model/field-format.enum';
@Component({
  templateUrl: './tab-activity.component.html'
})
export class TabActivityComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<VendorActivityStatusHistory>;
  public tableResponse: TableResponseModel<VendorActivityStatusHistory>;
  constructor(translateService: TranslateService) {
    super('tab-activity-history', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setCustomData();
    this.model.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'vendorActivityStatus.name', header: 'table.column.activity' },
      { field: 'createdDate', header: 'table.column.date', format: FieldFormatEnum.ShortDate, customClass: 'text-center' }
    ]);
  }

  public setCustomData(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponse.setCustomData(customData);
  }
}
