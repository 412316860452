import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AddressBook } from 'src/app/core/bean/address-book';
import { Fines } from 'src/app/core/bean/fines';
import { Guarantee } from 'src/app/core/bean/guarantee';
import { ItemType } from 'src/app/core/bean/item-type';
import { Order } from 'src/app/core/bean/order';
import { OrderActivityHistory } from 'src/app/core/bean/order-activity-history';
import { OrderCancellation } from 'src/app/core/bean/order-cancellation';
import { OrderItem } from 'src/app/core/bean/order-item';
import { OrderShipping } from 'src/app/core/bean/order-shipping';
import { OrderShippingItem } from 'src/app/core/bean/order-shipping-item';
import { OrderTc } from 'src/app/core/bean/order-tc';
import { PrItemReleased } from 'src/app/core/bean/pr-item-released';
import { PrShipping } from 'src/app/core/bean/pr-shipping';
import { Sow } from 'src/app/core/bean/sow';
import { Vendor } from 'src/app/core/bean/vendor';
import { VendorAddress } from 'src/app/core/bean/vendor-address';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { AppPopupAddressService } from 'src/app/core/components/app-popup/app-popup-address/app-popup-address.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { Validators } from 'src/app/core/validators';
import { AddressPic } from '../../core/bean/address-pic';
import { Contract } from '../../core/bean/contract';
import { ContractDetail } from '../../core/bean/contract-detail';
import { OrderPreparation } from '../../core/bean/order-preparation';
import { Tc } from '../../core/bean/tc';
import { SelectedTableRecordModel } from '../../core/components/table/model/selected-table-record-model';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { PopupOrderCancelComponent } from './popup/popup-order-cancel.component';
import { PopupOrderChooseTermComponent } from './popup/popup-order-choose-term.component';
import { PopupOrderEditConditionComponent } from './popup/popup-order-edit-condition.component';
import { PopupOrderSplitItemComponent } from './popup/popup-order-split-item.component';
import { OrderRequest } from './request/order.request';
import { OrderAddEditResponse } from './response/order-add-edit.response';

@Component({
  templateUrl: './order-edit-add.component.html',
  encapsulation: ViewEncapsulation.None
})
export class OrderEditAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  public token: string;
  public orderId: number;
  public objectId: number;
  public approvalModelPrcsId: number;
  public fileUploader: FileUploader = new FileUploader(
    '/order/',
    'order.form.headerImage',
    this.global.appConstant.fileType.DOC_ORDER,
    false,
    this.global.config.parameterModel.maxFileOrder
  );
  public response: OrderAddEditResponse = new OrderAddEditResponse();
  public today = new Date();
  public orderCancel: OrderCancellation;
  public orderShippingOptionList: OptionListModel<OrderShipping> =
    new OptionListModel(true, 'address.address'); // to keep data from popup
  public prShippingOptionList: OptionListModel<PrShipping> =
    new OptionListModel(true, 'address.address');
  public dataMap: Map<string, Object> = new Map<string, Object>();

  public badgeColor = {
    DRAFT: 'SECONDARY',
    REVISION: 'FEEDBACK',
    VENDOR_REVISION: 'FEEDBACK',
    WAITING_CONFIRMATION: 'WARNING',
    WAITING_APPROVAL: 'WARNING',
    ON_PROGRESS: 'PROGRESS',
    DONE: 'SUCCESS',
    CLOSED: 'DANGER',
    CANCELED: 'DANGER'
  };

  public statusEditList = [
    this.global.appConstant.pm.ORDER_STATUS_DRAFT,
    this.global.appConstant.pm.ORDER_STATUS_REVISION,
    this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION
  ];

  public statusForApprovalList = [
    this.global.appConstant.pm.ORDER_STATUS_WAITING_APPROVAL,
    this.global.appConstant.pm.ORDER_STATUS_REVISION,
    this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION,
    this.global.appConstant.pm.ORDER_STATUS_REJECTED,
    this.global.appConstant.pm.ORDER_STATUS_ON_PROGRESS,
    this.global.appConstant.pm.ORDER_STATUS_DONE,
    this.global.appConstant.pm.ORDER_STATUS_CLOSED,
    this.global.appConstant.pm.ORDER_STATUS_CANCELED
  ];

  public statusCancelList = [
    this.global.appConstant.pm.ORDER_STATUS_WAITING_CONFIRMATION,
    this.global.appConstant.pm.ORDER_STATUS_REVISION,
    this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION
  ];

  public amount: number;
  public goodsAmount: number;
  public serviceAmount: number;

  public tableResponseVendor: TableResponseModel<Vendor>;
  public tableResponseTc: TableResponseModel<OrderTc>;
  public tableResponseOrderActHistory: TableResponseModel<OrderActivityHistory>;
  public sowList: Sow[] = [];
  public prItemReleasedList: PrItemReleased[] = [];
  public itemTypeList: ItemType[] = [];
  public isCanceled: boolean;
  public orderShippingItemList: OrderShippingItem[] = [];
  public isRevisions: boolean;
  public orderRequest: OrderRequest = new OrderRequest();
  public isAppSowReady = false;
  public tcList: Tc[] = [];
  public finesList: Fines[] = [];
  public guaranteeList: Guarantee[] = [];
  public addressBookList: AddressBook[] = [];
  public vendorList: Vendor[] = [];
  public vendorListEdit: Vendor[] = [];
  public prShippingList: PrShipping[] = [];
  public orderShippingList: OrderShipping[] = [];
  public isSourceReady = false;
  public source: string;
  public orderPreparationId: number;
  public isSourceContractView: boolean;
  public contract: Contract;
  public appOfficialReportModel: AppOfficialReportModel;
  public orderShipping: OrderShipping;
  public prShipping: PrShipping;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupAddressService: AppPopupAddressService,
    public toastService: ToastService,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute
  ) {
    super('order', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Order>('/order/notification/' + this.token)
      .subscribe((order: Order) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('orderId', order.id);
        this.global.routerParams.set('orderStatus', order.orderStatus);
        this.global.routerParams.set('urlBackOutside', '/pages/order');
        this.global.routerParams.set('todo', 'edit');
        this.router.navigate(['/pages/order/edit']);
      });
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.buildTableResponseVendor();
    this.doBuildOrderActHistoryTableresponse();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.orderId = this.global.routerParams.get('orderId');
    this.objectId =
      localStorage.getItem('objectId') ||
      this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.orderId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    localStorage.removeItem('objectId');
    localStorage.removeItem('todo');
    localStorage.removeItem('urlBackOutside');
  }

  public buildTableResponseVendor(): void {
    this.tableResponseVendor = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.vendorName'
      },
      {
        field: 'email',
        header: 'table.column.email'
      },
      {
        field: 'address',
        header: 'table.column.address'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      }
    ]);
  }

  public buildTableResponseTc(): void {
    this.tableResponseTc = new TableResponseModel(this.moduleCode, [
      {
        field: 'tcDetail.termType.name',
        header: 'table.column.term',
        plugins: {
          name: 'hyperlink',
          className: 'white-space-normal',
          onClick: this.onClickTc.bind(this)
        }
      },
      {
        field: 'condition',
        header: 'table.column.condition',
        plugins: {
          name: 'html'
        }
      }
    ]);
  }

  public doBuildOrderActHistoryTableresponse(): void {
    this.tableResponseOrderActHistory = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'processedByUser.name',
          header: 'table.column.userName'
        },
        {
          field: 'processedDate',
          header: 'table.column.date',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'note',
          header: 'table.column.note'
        },
        {
          field: 'orderActivityStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            field: 'orderActivityStatus.code',
            colorMap: {
              SUBMITTED: 'SUCCESS',
              CONFIRMED: 'SUCCESS',
              REVISED: 'FEEDBACK',
              RESEND: 'DANGER',
              REJECTED: 'DANGER',
              CANCELED: 'DANGER',
              CLOSED: 'DANGER'
            }
          }
        }
      ]
    );
  }

  public onClickTc(orderTc: OrderTc): void {
    if (!this.formGroup.isView) {
      this.appPopupService
        .open(PopupOrderEditConditionComponent, { orderTc })
        .subscribe((orderTc: OrderTc) => {
          this.tableResponseTc.getRecordList().forEach(otc => {
            if (otc.tcDetail.id === orderTc.tcDetail.id) {
              otc = orderTc;
            }
          });
          this.tableResponseTc.reload();
        });
    }
  }

  public doAddTc(): void {
    const tcList = this.tcList; // optionlist
    let selectedTcList = [];
    if (
      this.tableResponseTc.getRecordList() &&
      this.tableResponseTc.getRecordList().length > 0
    ) {
      selectedTcList = this.tableResponseTc
        .getRecordList()
        .map(otc => otc.tcDetail.tc);
      selectedTcList = selectedTcList.filter(
        (v, i, a) => a.findIndex(t => t.id === v.id) === i
      );
    }

    this.appPopupService
      .open(PopupOrderChooseTermComponent, {
        tcList,
        selectedTcList
      })
      .subscribe((tc: Tc) => {
        const tcDetailList = this.response.tcDetailList.filter(
          tcDetail => tcDetail.tc.id === tc.id
        );
        const orderTcList = [];
        tcDetailList.forEach(tcDetail => {
          const orderTc = new OrderTc();
          orderTc.tcDetail = tcDetail;
          orderTc.condition = tcDetail.condition;
          orderTcList.push(orderTc);
        });

        this.tableResponseTc.setRecordList(orderTcList);
        this.tableResponseTc.reload();
      });
  }

  public doDeleteTc(event: SelectedTableRecordModel): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const orderTcList: OrderTc[] = event.selectedRecordList;
          orderTcList.forEach(orderTc => {
            const i = this.tableResponseTc
              .getRecordList()
              .findIndex(rec => rec.tcDetail.id === orderTc.tcDetail.id);
            i !== -1
              ? this.tableResponseTc.getRecordList().splice(i, 1)
              : this.log.debug(orderTc);

            if (
              orderTc.id &&
              !this.orderRequest.deleteOrderTcList.find(
                del => del.id === orderTc.id
              )
            ) {
              orderTc.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_DELETE;
              this.orderRequest.deleteOrderTcList.push(orderTc);
            }
          });
          this.tableResponseTc.setRecordList(
            this.tableResponseTc.getRecordList()
          );
          this.tableResponseTc.reload();
        }
      });
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      title: [null, Validators.required()],
      orderStatus: [null],
      requiredDate: [null, Validators.required()],
      description: [null],
      goodsAmount: [null],
      serviceAmount: [null],
      amount: [null],
      orderShipping: [null],
      orderShippingList: [null],
      responseToConfirmNote: [
        null
      ] /** muncul jika status order = vendor_revision */
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<OrderAddEditResponse>(
        '/order/add-edit?id=' +
          (this.orderId ? this.orderId : '') +
          '&todo=' +
          this.todo,
        {}
      )
      .subscribe((response: OrderAddEditResponse) => {
        this.response = response;
        this.appOfficialReportModel = response.appOfficialReportModel;
        this.orderShippingOptionList.setRequestValues([]);
        this.itemTypeList = response.itemTypeList;
        this.tcList = response.tcList;
        if (response.order) {
          const {
            id,
            code,
            title,
            orderStatus,
            requiredDate,
            description,
            goodsAmount,
            serviceAmount,
            amount
          } = response.order;

          this.fileUploader.setFileList(
            response.orderDocList.map(orderDoc => orderDoc.file)
          );

          this.formGroup.patchValue({
            id,
            code,
            title,
            orderStatus,
            requiredDate: new Date(requiredDate),
            description,
            goodsAmount,
            serviceAmount,
            amount,
            docList: this.fileUploader.fileObjectList,
            orderShippingList: response.orderShippingList
          });

          /** order item, order ship, order shipitem */
          this.prItemReleasedList = response.orderItemList.map(
            orderItem => orderItem.prItemReleased
          );

          this.orderShippingOptionList.setRequestValues(
            response.orderShippingList
          );
          this.orderShipping = response.orderShippingList?.[0];
          this.orderShippingItemList = this.response.orderShippingItemList;

          this.sowList = response.sowList;
          this.amount = response.order.amount;
          this.goodsAmount = response.order.goodsAmount;
          this.serviceAmount = response.order.serviceAmount;

          this.finesList = response.finesList || [];
          this.guaranteeList = response.guaranteeList || [];

          this.tableResponseOrderActHistory.setRecordList(
            response.orderActivityHistoryList
          );
          this.tableResponseOrderActHistory.reload();

          this.orderCancel = response.orderCancellation;

          if (
            response.order.orderStatus.code ===
            this.global.appConstant.pm.ORDER_STATUS_CANCELED
          ) {
            this.isCanceled = true;
          }

          if (
            response.order.orderStatus.code.includes(
              this.global.appConstant.pm.ORDER_STATUS_REVISION
            )
          ) {
            /** revision approval, revision vendor */
            this.isRevisions = true;

            if (
              response.order.orderStatus.code ===
              this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION
            ) {
              this.formGroup
                .get('responseToConfirmNote')
                .setValidators([Validators.required()]);
              this.formGroup
                .get('responseToConfirmNote')
                .updateValueAndValidity();
            } else {
              this.formGroup.get('responseToConfirmNote').setIsView(true);
            }
          }

          const oahResend = response.orderActivityHistoryList.find(
            oah =>
              oah.orderActivityStatus.code ===
              this.global.appConstant.pm.ORDER_ACTIVITY_STATUS_RESEND
          );
          if (oahResend) {
            this.formGroup
              .get('responseToConfirmNote')
              .patchValue(oahResend.note);
          }
          this.isAppSowReady = true;
          this.isSourceReady = true;
          this.source = response.order.source;
        }

        this.setStateReady();
        this.isAppSowReady = true;
        this.isSourceReady = true;

        if (
          this.response.order &&
          !this.statusEditList.includes(this.response.order.orderStatus.code)
        ) {
          // selain draft, revision, revision_vendor
          this.setViewOnly();
        }

        if (
          this.response.order &&
          this.response.order.source ===
            this.global.appConstant.core.SOURCE_CONTRACT
        ) {
          this.isSourceContractView = true;
        }
      });
  }

  public onChangeItemRequest(event): void {
    this.log.debug(event);
    this.source = event.source;
    const delOrderShipItemList = [];
    const prId =
      event.prItemReleasedList.length > 0
        ? event.prItemReleasedList[0].prItem?.pr?.id
        : 0;
    this.setStateProcessing();
    this.httpClientService
      .get<OrderAddEditResponse>('/order/get-pr-shipping/' + prId, {})
      .subscribe(response => {
        this.appOfficialReportModel = response.appOfficialReportModel;
        this.prShippingList = response.prShippingList;
        this.prShipping = response.prShippingList?.[0];
        this.prShippingOptionList.setRequestValues(this.prShippingList);

        const orderShippingList: OrderShipping[] = [];
        const orderShipping = new OrderShipping();
        orderShipping.address = response.prShippingList?.[0]?.address;
        orderShippingList.push(orderShipping);

        this.formGroup.patchValue({
          requiredDate: this.prShipping?.pr?.requiredDate
        });
        this.formGroup.get('requiredDate').updateValueAndValidity();

        if (orderShippingList?.[0].address !== undefined) {
          this.orderShippingOptionList.setRequestValues(orderShippingList);
          this.orderShipping = orderShipping;
          this.formGroup.markAsDirty();
        }
      });
    setTimeout(() => {
      if (this.orderShippingItemList && this.orderShippingItemList.length > 0) {
        delOrderShipItemList.push(
          ...this.orderShippingItemList.filter(osi => {
            return !event.prItemReleasedList
              .map(prItemRel => prItemRel.id)
              .includes(osi.orderItem.prItemReleased.id);
          })
        );
      }

      this.prItemReleasedList = event.prItemReleasedList;

      if (this.prItemReleasedList && this.prItemReleasedList.length > 0) {
        this.vendorList = [];
        this.prItemReleasedList.forEach(prItemReleased => {
          const vendor: Vendor = { ...prItemReleased.vendor };
          this.httpClientService
            .get<VendorAddress>('/order/get-vendor-address/' + vendor.id, {})
            .subscribe((vendorAddress: VendorAddress) => {
              vendor['address'] = vendorAddress.address;
              this.vendorList.push(vendor);
            });
          setTimeout(() => {
            this.tableResponseVendor.setRecordList(this.vendorList);
            this.tableResponseVendor.reload();
            this.setStateReady();
          }, 1000);
        });
      } else {
        this.tableResponseVendor
          .getRecordList()
          .splice(0, this.tableResponseVendor.getRecordList().length);
        this.tableResponseVendor.reload();
      }

      this.amount = event.amountValue.amount;
      this.goodsAmount = event.amountValue.goodsAmount;
      this.serviceAmount = event.amountValue.serviceAmount;

      if (
        this.response.orderItemList &&
        this.response.orderItemList.length > 0
      ) {
        this.response.orderItemList
          .filter(oitem => {
            return !this.prItemReleasedList
              .map(prItemRel => prItemRel.id)
              .includes(oitem.prItemReleased.id);
          })
          .forEach(deleteOrderItem => {
            if (
              deleteOrderItem.id &&
              !this.orderRequest.deleteOrderItemList.find(
                del => del.id === deleteOrderItem.id
              )
            ) {
              deleteOrderItem.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_DELETE;
              this.orderRequest.deleteOrderItemList.push(deleteOrderItem);
            }
          });
      }

      if (
        this.orderShippingItemList &&
        this.orderShippingItemList.length > 0 &&
        delOrderShipItemList &&
        delOrderShipItemList.length > 0
      ) {
        delOrderShipItemList.forEach(delOsi => {
          const indexDelOsi = this.orderShippingItemList.findIndex(
            osi =>
              osi.orderItem.prItemReleased.id ===
                delOsi.orderItem.prItemReleased.id &&
              osi.orderShipping.address.id === delOsi.orderShipping.address.id
          );
          this.orderShippingItemList.splice(indexDelOsi, 1);
        });
      }

      /** reset sowList */
      if (this.sowList && this.sowList.length > 0) {
        this.sowList.forEach(sow => {
          sow.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_DELETE;
          if (
            sow.id &&
            ((this.orderRequest.deleteSowList.length > 0 &&
              this.orderRequest.deleteSowList.filter(
                deletedSow => deletedSow.id && deletedSow.id === sow.id
              ).length === 0) ||
              this.orderRequest.deleteSowList.length === 0)
          ) {
            this.orderRequest.deleteSowList.push(sow);
          }
        });
      }
      this.sowList = event.sowList === undefined ? [] : event.sowList;
      this.isAppSowReady = false;
      this.isSourceReady = false;
      if (
        this.source === this.global.appConstant.core.SOURCE_CONTRACT &&
        this.sowList[0]?.objectId
      ) {
        this.httpClientService
          .get<OrderAddEditResponse>(
            '/order/get-contract-data/' + this.sowList[0].objectId,
            {}
          )
          .subscribe((response: OrderAddEditResponse) => {
            this.finesList = response.finesList;
            this.guaranteeList = response.guaranteeList;

            const contractShippingList = response.contractShippingList;
            const contractShippingDetailList =
              response.contractShippingDetailList;

            const duplicateContractDetailList = contractShippingDetailList.map(
              cshippingDetail => cshippingDetail.contractDetail
            ); // potentially duplicated data

            const contractDetailList: ContractDetail[] = [];
            if (
              duplicateContractDetailList &&
              duplicateContractDetailList.length > 0
            ) {
              duplicateContractDetailList.filter(item => {
                const i = contractDetailList.findIndex(
                  x =>
                    x.id === item.id &&
                    x.prItemReleased.id === item.prItemReleased.id
                );
                if (i <= -1) {
                  contractDetailList.push(item);
                }
                return null;
              });
            }

            const orderShippingList: OrderShipping[] = [];
            contractShippingList.forEach(contractShipping => {
              const orderShipping = new OrderShipping();
              orderShipping.address = contractShipping.address;
              orderShippingList.push(orderShipping);
            });

            const orderItemList: OrderItem[] = [];
            contractDetailList.forEach(cdetail => {
              const orderItem = new OrderItem();
              orderItem.prItemReleased = cdetail.prItemReleased;
              orderItemList.push(orderItem);
            });

            const orderShippingItemList: OrderShippingItem[] = [];
            contractShippingDetailList.forEach(cshippingDetail => {
              const orderShippingItem = new OrderShippingItem();
              orderShippingItem.quantity = cshippingDetail.quantity;
              orderShippingItem.remainingQuantity =
                cshippingDetail.remainingQuantity;
              orderShippingItem.orderShipping = orderShippingList.find(
                oshipping =>
                  oshipping.address.id ===
                  cshippingDetail.contractShipping.address.id
              );
              orderShippingItem.orderItem = orderItemList.find(
                oitem =>
                  oitem.prItemReleased.id ===
                  cshippingDetail.contractDetail.prItemReleased.id
              );
              orderShippingItemList.push(orderShippingItem);
            });

            this.orderShippingOptionList.setRequestValues(orderShippingList);
            this.formGroup.get('orderShipping').setIsView(true);
            this.orderShippingItemList = orderShippingItemList;

            this.isSourceContractView = true;
            this.isSourceReady = true;
          });
      } else {
        this.isSourceContractView = false;
        this.isSourceReady = true;
      }

      setTimeout(() => {
        this.isAppSowReady = true;
      }, 50);

      if (event.orderPreparationId) {
        this.orderPreparationId = event.orderPreparationId;
      } else {
        this.orderPreparationId = null;
      }

      this.contract = event.contract;

      if (
        event.prItemReleasedList === undefined ||
        event.prItemReleasedList.length === 0
      ) {
        const orderShippingList: OrderShipping[] = [];
        this.orderShippingOptionList.setRequestValues(orderShippingList);
      } else {
        const addressBookIdList: number[] = [];
        const organizationIdList: number[] = [];
        this.httpClientService
          .post<AddressBook[]>(
            '/app-popup-address/index',
            JSON.stringify({
              addressBookIdList: addressBookIdList,
              organizationIdList: organizationIdList
            })
          )
          .subscribe(adressBookList => {
            const orderShippingList: OrderShipping[] = [];
            adressBookList.forEach(addrs => {
              const orderShipping = new OrderShipping();
              orderShipping.address = addrs;
              orderShippingList.push(orderShipping);
            });
            if (this.todo !== 'add') {
              this.orderShippingOptionList.setRequestValues(orderShippingList);
            } else {
              const orderShipping = new OrderShipping();
              orderShipping.address = this.prShipping.address;
              orderShippingList.push(orderShipping);
              if (this.orderShippingOptionList.requestValues.length == 0) {
                this.orderShippingOptionList.setRequestValues(
                  orderShippingList
                );
              }
            }

            this.formGroup.markAsDirty();
          });
      }
      this.setStateReady();
    }, 1000);
  }

  public getPrId(): number {
    if (this.todo !== 'add') {
      if (this.prItemReleasedList && this.prItemReleasedList.length > 0) {
        return this.prItemReleasedList[0].prItem.pr.id;
      }
    } else {
      return null; /** bisa memilih vendor lain */
    }
  }

  public doAddAddress(): void {
    const addressBookList = this.orderShippingOptionList.getRequestValues()
      ? (
          this.orderShippingOptionList.getRequestValues() as Array<OrderShipping>
        ).map(oship => oship.address)
      : [];

    this.appPopupAddressService
      .open(addressBookList, true)
      .subscribe(addrsList => {
        const orderShippingList: OrderShipping[] = [];
        this.addressBookList = addrsList;
        const orderShipping = new OrderShipping();
        orderShipping.address = addrsList;
        orderShippingList.push(orderShipping);

        this.orderShippingOptionList.setRequestValues(orderShippingList);
        this.orderShipping = orderShipping;
        console.log(orderShipping?.address.addressDetail);
        this.formGroup.markAsDirty();
        /** reset quantity shippingitem */
      });
  }

  public doDeleteShipping(): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const orderShippingList = Array.from(
            this.formGroup.get('orderShipping').value
          );
          const orderShippingOptionList =
            this.orderShippingOptionList.getRequestValues();
          orderShippingList.forEach((orderShipping: OrderShipping) => {
            const index = orderShippingOptionList.findIndex(
              oship => oship.address.id === orderShipping.address.id
            );
            const oship = orderShippingOptionList[index];

            if (index !== -1) {
              orderShippingOptionList.splice(index, 1);
              if (
                this.orderShippingItemList &&
                this.orderShippingItemList.length > 0
              ) {
                const delOsiList = this.orderShippingItemList.filter(
                  delOsi =>
                    delOsi.orderShipping.address.id === orderShipping.address.id
                );
                delOsiList.forEach(delOsi => {
                  const indexDelOsi = this.orderShippingItemList.findIndex(
                    osi =>
                      osi.orderItem.prItemReleased.id ===
                        delOsi.orderItem.prItemReleased.id &&
                      osi.orderShipping.address.id ===
                        delOsi.orderShipping.address.id
                  );
                  this.orderShippingItemList.splice(indexDelOsi, 1);
                });
              }

              if (
                oship.id &&
                !this.orderRequest.deleteOrderShippingList.find(
                  del => del.id === oship.id
                )
              ) {
                oship.crudOperation =
                  this.global.appConstant.core.CRUD_OPERATION_DELETE;
                this.orderRequest.deleteOrderShippingList.push(oship);
              }
            }
          });
          this.orderShippingOptionList.setRequestValues(
            orderShippingOptionList
          );
          this.formGroup.patchValue({
            orderShipping: null
          });
          this.formGroup.markAsDirty();
        }
      });
  }

  public doViewItem(shipping: OrderShipping): void {
    const isView = this.formGroup.isView || this.isSourceContractView;
    const address = shipping.address;

    let orderShippingItemList = [];
    let allOShipItemList = [];

    if (!this.isSourceContractView) {
      orderShippingItemList = this.orderShippingItemList.filter(
        osi => osi.orderShipping.id === shipping.id
      );
    } else {
      // source contract jika blm pernah insert order
      orderShippingItemList = this.orderShippingItemList.filter(
        osi => osi.orderShipping.address.id === shipping.address.id
      );
    }

    allOShipItemList = this.orderShippingItemList;

    this.appPopupService.open(
      PopupOrderSplitItemComponent,
      {
        address,
        orderShippingItemList,
        isView,
        allOShipItemList
      },
      { size: 'xl' }
    );
  }

  public doSplit(orderShip: OrderShipping): void {
    this.log.debug(orderShip);
    const address = orderShip.address;
    const orderShippingItemList: OrderShippingItem[] =
      this.orderShippingItemList.filter(
        osi => osi.orderShipping.address.id === orderShip.address.id
      );

    /** kondisi jika revisi data dari response, kynya gak perlu krn sudah diatur saat processreq */
    this.prItemReleasedList.forEach(prItemRel => {
      if (
        (orderShippingItemList && orderShippingItemList.length === 0) ||
        (orderShippingItemList &&
          orderShippingItemList.length > 0 &&
          !orderShippingItemList.find(
            oshitem => oshitem.orderItem.prItemReleased.id === prItemRel.id
          ))
      ) {
        const orderShippingItem = new OrderShippingItem();
        orderShippingItem.orderShipping = orderShip;
        orderShippingItem.orderItem = new OrderItem();
        orderShippingItem.orderItem.prItemReleased = prItemRel;

        orderShippingItem.quantity =
          orderShippingItem.orderItem.prItemReleased.quantity > 0 ? null : 0;
        orderShippingItemList.push(orderShippingItem);
      }
    });

    this.appPopupService
      .open(PopupOrderSplitItemComponent, {
        isView: this.todo === 'view',
        address,
        orderShippingItemList,
        allOShipItemList: this.orderShippingItemList
      })
      .subscribe((orderShippingItemList: OrderShippingItem[]) => {
        /** remove old oshipItem having same address */
        this.orderShippingItemList = this.orderShippingItemList.filter(
          oshipItem =>
            oshipItem.orderShipping.address.id !== orderShip.address.id
        );
        const delOrderShippingItemList = orderShippingItemList.filter(
          osi => osi.quantity === 0
        );

        this.orderShippingItemList.push(...orderShippingItemList);

        /** crud operation for update */
        this.orderShippingItemList.forEach(orderShippingItem => {
          if (
            this.response.orderItemList &&
            this.response.orderItemList.length > 0
          ) {
            const existingOrderItem = this.response.orderItemList.find(
              oi =>
                oi.prItemReleased.id ===
                orderShippingItem.orderItem.prItemReleased.id
            );
            if (existingOrderItem) {
              /** existing */
              orderShippingItem.orderItem.id = existingOrderItem.id;
              orderShippingItem.orderItem.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_UPDATE;
            } else {
              orderShippingItem.orderItem.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
            }
          }

          if (
            this.response.orderShippingList &&
            this.response.orderShippingList.length > 0
          ) {
            const existingOrderShip = this.response.orderShippingList.find(
              oship =>
                oship.address.id === orderShippingItem.orderShipping.address.id
            );
            if (existingOrderShip) {
              /** existing */
              orderShippingItem.orderShipping.id = existingOrderShip.id;
              orderShippingItem.orderShipping.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_UPDATE;
            } else {
              orderShippingItem.orderShipping.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
            }
          }
        });

        delOrderShippingItemList.forEach(delOsi => {
          const index = this.orderShippingItemList.findIndex(
            osi =>
              osi.orderItem.prItemReleased.id ===
                delOsi.orderItem.prItemReleased.id &&
              osi.orderShipping.address.id === delOsi.orderShipping.address.id
          );
          this.orderShippingItemList.splice(index, 1);
        });

        this.log.debug(this.orderShippingItemList);
      });
  }

  public onChangeSowList(event): void {
    this.sowList = event;

    if (this.response.sowList && this.response.sowList.length > 0) {
      this.response.sowList
        .filter(sow => {
          return !this.sowList.map(sw => sw.id).includes(sow.id);
        })
        .forEach(deleteSow => {
          if (
            deleteSow.id &&
            !this.orderRequest.deleteSowList.find(
              del => del.id === deleteSow.id
            )
          ) {
            deleteSow.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_DELETE;
            this.orderRequest.deleteSowList.push(deleteSow);
          }
        });
    }
  }

  public doCancel(): void {
    this.loadingBlockService.showInfo('order.msg.checkingData');

    const order = this.response.order;
    this.httpClientService
      .post<Order>('/order/validate-cancel', order)
      .subscribe((response: Response<Contract>) => {
        this.loadingBlockService.close();
        if (response.status === ResponseStatusModel.OK) {
          // popup cancel po
          this.appPopupService
            .open(PopupOrderCancelComponent, {})
            .subscribe((note: string) => {
              this.orderCancel = new OrderCancellation();
              this.orderCancel.order = this.response.order;
              this.orderCancel.cancelNote = note;

              this.setStateProcessing();
              this.httpClientService
                .post<Response<OrderCancellation>>(
                  '/order/cancel',
                  this.orderCancel
                )
                .subscribe((response: Response<OrderCancellation>) => {
                  this.setStateLoading();
                  if (response.status === ResponseStatusModel.OK) {
                    this.orderCancel = response.body;
                    this.response.order = this.orderCancel.order;

                    this.isCanceled = true;
                    this.isRevisions = false;
                  } else {
                    this.toastService.showError(response.statusText);
                  }

                  this.setStateReady();
                  this.isAppSowReady = true;
                  this.setViewOnly();
                });
            });
        } else if (response.status === ResponseStatusModel.ERROR) {
          if (response.statusText === 'REQUEST_CANCELATION') {
            // failed to cancel po
            const prompt =
              this.translateService.instant(
                'order.modal.validateCancel.prompt'
              ) +
              response.body.requestNumber +
              ' ' +
              this.translateService.instant('order.modal.msg.first');
            this.global.modalService
              .confirmation(
                prompt,
                'order.modal.validateCancel.title',
                'app.button.close',
                'order.button.requestCancelation',
                'pvc pv-exclamation-triangle',
                false
              )
              .subscribe(result => {
                if (result) {
                  // request cancelation
                  this.setStateProcessing();
                  const contract = response.body;
                  this.httpClientService
                    .post<Response<Contract>>(
                      '/order/request-cancelation',
                      contract
                    )
                    .subscribe(response => {
                      if (response.status === ResponseStatusModel.OK) {
                        this.snackbarService.showWarning(
                          'order.alert.msg.requestCancelationHasBeenSent'
                        );
                      }
                      this.loadingBlockService.close();
                    });
                }
              });
          } else if (
            response.statusText === 'FAILED_REQUEST_WAITING_APPROVAL'
          ) {
            // request failed, status kontrak = draft, waiting approval, revision
            this.global.modalService.confirmation(
              'order.modal.requestFailedWaitingApproval.prompt',
              'order.modal.requestFailedWaitingApproval.title',
              null,
              null,
              'pvc pv-exclamation-triangle',
              true
            );
          } else if (
            response.statusText ===
            'FAILED_REQUEST_WAITING_APPROVAL_TERMINATION'
          ) {
            this.global.modalService.confirmation(
              'order.modal.requestFailedWaitingApprovalTermination.prompt',
              'order.modal.requestFailedWaitingApproval.title',
              null,
              null,
              'pvc pv-exclamation-triangle',
              true
            );
          }
        }
      });
  }

  public doClose(): void {
    this.loadingBlockService.showInfo('order.msg.checkingData');
    const order = this.response.order;
    this.httpClientService
      .post<Order>('/order/validate-close', order)
      .subscribe((response: Response<Contract>) => {
        this.loadingBlockService.close();
        if (response.status === ResponseStatusModel.OK) {
          // popup close po
          this.global.modalService
            .confirm(
              this.global.translateService.instant(
                'order.alert.msg.areYouSureYouWantToClose'
              ),
              'order.alert.title.closeConfirmation'
            )
            .subscribe(result => {
              if (result) {
                this.setStateProcessing();
                this.httpClientService
                  .post<Response<Order>>('/order/close', this.response.order)
                  .subscribe((response: Response<Order>) => {
                    this.setStateLoading();
                    if (response.status === ResponseStatusModel.OK) {
                      this.response.order = response.body;
                    } else {
                      this.toastService.showError(response.statusText);
                    }

                    this.setStateReady();
                    this.isAppSowReady = true;
                    this.setViewOnly();
                  });
              }
            });
        } else if (response.status === ResponseStatusModel.ERROR) {
          // failed to close po
          const prompt =
            this.translateService.instant('order.modal.validateClose.prompt') +
            response.body.requestNumber +
            ' ' +
            this.translateService.instant('order.modal.msg.first');
          this.global.modalService
            .confirmation(
              prompt,
              'order.modal.validateClose.title',
              'app.button.close',
              'order.button.requestTermination',
              'pvc pv-exclamation-triangle',
              false
            )
            .subscribe(result => {
              if (result) {
                // request termination
                this.setStateProcessing();
                const contract = response.body;
                this.httpClientService
                  .post<Response<Contract>>(
                    '/order/request-termination',
                    contract
                  )
                  .subscribe(response => {
                    if (response.status === ResponseStatusModel.OK) {
                      this.snackbarService.showWarning(
                        'order.alert.msg.requestTerminationHasBeenSent'
                      );
                    }
                    this.loadingBlockService.close();
                  });
              }
            });
        }
      });
  }

  public goToDeliveryMonitoring(): void {
    localStorage.setItem('drId', this.response.order.dr.id.toString());
    if (
      this.global.userSession?.activeUserRole.role.code === 'VENDOR' ||
      this.response.order.dr.deliveryStatus.code === 'DONE'
    ) {
      localStorage.setItem('todo', 'view');
      window.open('/pages/dr/detail');
    } else {
      localStorage.setItem('todo', 'edit');
      window.open('/pages/dr/edit');
    }
  }

  public doSaveDraft(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.setStateProcessing();

      const url = this.todo === 'edit' ? '/order/update' : '/order/insert';
      this.httpClientService
        .post<Response<OrderAddEditResponse>>(
          url,
          this.getProcessedRequest(false)
        )
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.snackbarService.showWarning('app.alert.msg.saveSuccess');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set('orderId', response.body.id);
                this.global.routerParams.set('urlBackOutside', '/pages/order');
                this.global.routerParams.set('todo', 'edit');
                this.router.navigate(['/pages/order/edit']);
              });
          } else {
            this.toastService.showError(response.statusText);
          }
          this.setStateReady();
        });
    }
  }

  public doSubmit(): void {
    this.validate();
    const isItemAddressedList: boolean[] = [];
    this.prItemReleasedList.forEach(prItemRel => {
      if (
        prItemRel.quantity > 0 &&
        this.orderShippingOptionList.getRequestValues().length > 1
      ) {
        /** max quantity > 0 */
        let maxQuantity = 0;
        if (
          prItemRel.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
        ) {
          maxQuantity = 100;
        } else {
          maxQuantity = prItemRel.quantity;
        }
        let totalUsedQuantity = 0;
        if (
          this.orderShippingItemList &&
          this.orderShippingItemList.length > 1
        ) {
          const orderShipItemList = this.orderShippingItemList.filter(
            osi => osi.orderItem.prItemReleased.id === prItemRel.id
          );
          orderShipItemList.forEach(oShipItem => {
            totalUsedQuantity += oShipItem.quantity;
          });
        }
        const remainingQuantity = maxQuantity - totalUsedQuantity;
        if (remainingQuantity === 0) {
          isItemAddressedList.push(true);
        } else {
          isItemAddressedList.push(false);
        }
      }
    });

    const indexItemAddressedFalse = isItemAddressedList.findIndex(
      isiadd => isiadd === false
    );

    const isFullyAddressed: boolean[] = [];
    if (
      this.orderShippingOptionList.getRequestValues().length > 1 &&
      this.todo !== 'add'
    ) {
      this.orderShippingOptionList.getRequestValues().forEach(os => {
        const osiList = this.orderShippingItemList.filter(
          osi => osi.orderShipping.address.id === os.address.id
        );
        if (
          osiList &&
          osiList.length > 0 &&
          this.prItemReleasedList &&
          this.prItemReleasedList.length > 0
        ) {
          let totalQuantity = 0;
          this.prItemReleasedList.forEach(pri => {
            const osi = osiList.find(
              osi => osi.orderItem.prItemReleased.id === pri.id
            );
            if (osi) {
              totalQuantity += osi.quantity;
            }
          });
          if (totalQuantity > 0) {
            isFullyAddressed.push(true);
          } else {
            isFullyAddressed.push(false);
          }
        } else {
          isFullyAddressed.push(false);
        }
      });
    }

    if (
      this.prShippingOptionList.getRequestValues().length > 1 &&
      this.todo === 'add'
    ) {
      this.prShippingOptionList.getRequestValues().forEach(os => {
        const osiList = this.orderShippingItemList.filter(
          osi => osi.orderShipping.address.id === os.address.id
        );
        if (
          osiList &&
          osiList.length > 0 &&
          this.prItemReleasedList &&
          this.prItemReleasedList.length > 0
        ) {
          let totalQuantity = 0;
          this.prItemReleasedList.forEach(pri => {
            const osi = osiList.find(
              osi => osi.orderItem.prItemReleased.id === pri.id
            );
            if (osi) {
              totalQuantity += osi.quantity;
            }
          });
          if (totalQuantity > 0) {
            isFullyAddressed.push(true);
          } else {
            isFullyAddressed.push(false);
          }
        } else {
          isFullyAddressed.push(false);
        }
      });
    }
    const indexFullAdd = isFullyAddressed.findIndex(isFull => isFull === false);

    const remainingAmount =
      this.sowList.length > 0
        ? this.sowList[this.sowList.length - 1].sowPaymentTermList[
            this.sowList[this.sowList.length - 1].sowPaymentTermList.length - 1
          ].remainingAmount
        : null;

    const isValid =
      this.formGroup.valid &&
      this.sowList &&
      this.sowList.length > 0 &&
      indexItemAddressedFalse === -1 &&
      indexFullAdd === -1 &&
      /*this.tableResponseTc.getRecordList().length > 0 &&*/
      ((this.orderShippingOptionList.getRequestValues().length > 0 &&
        this.todo !== 'add') ||
        (this.prShippingOptionList.getRequestValues.length > 0 &&
          this.todo === 'add')) &&
      remainingAmount === 0;

    if (isValid) {
      this.setIsItemExist();
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            const url =
              this.todo === 'edit' ? '/order/update' : '/order/insert';
            this.httpClientService
              .post<Response<OrderAddEditResponse>>(
                url,
                this.getProcessedRequest(true)
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccessCreateNew()
                    .subscribe(result => {
                      if (result) {
                        this.setStateLoading();
                        this.isAppSowReady = false;
                        setTimeout(() => {
                          this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(() => {
                              this.global.routerParams.clear();
                              this.global.routerParams.set('todo', 'add');
                              this.router.navigate(['/pages/order/add']);
                              this.global.routerParams.set(
                                'urlBackOutside',
                                '/pages/order'
                              );
                            });

                          this.setStateReady();
                          this.isAppSowReady = true;
                        }, 50);
                      } else {
                        this.router.navigate(['/pages/order']);
                      }
                    });
                } else {
                  this.setStateReady();
                  this.toastService.showError(response.statusText);
                }
              });
          }
        });
    } else if (
      !this.prItemReleasedList ||
      this.prItemReleasedList.length === 0
    ) {
      this.toastService.showError(
        this.translateService.instant('order.alert.msg.minimumOneItem')
      );
    } else if (
      this.orderShippingOptionList.getRequestValues().length === 0 &&
      this.prShippingOptionList.getRequestValues().length === 0
    ) {
      this.toastService.showError(
        this.translateService.instant('order.alert.msg.minimumOneAddress')
      );
    } else if (indexItemAddressedFalse !== -1 || indexFullAdd !== -1) {
      this.toastService.showError(
        this.translateService.instant(
          'order.alert.msg.allItemsShouldBeAddressed'
        )
      );
    } else if (!this.sowList || this.sowList.length === 0) {
      this.toastService.showError(
        this.translateService.instant('order.alert.msg.minimumOneSow')
      );
      //     not valid to submit
    } else if (remainingAmount !== 0) {
      this.toastService.showError(
        this.translateService.instant(
          'order.alert.msg.percentagePaymentNotOneHundredYet'
        )
      );
    }
  }

  public setIsItemExist(): void {
    this.sowList.forEach(sow => {
      sow.sowPaymentTermList.forEach(sowPaymentTerm => {
        let isItemExist = false;
        sowPaymentTerm.sowPaymentTermItemList.forEach(sowPaymentTermItem => {
          if (sowPaymentTermItem.quantity > 0) {
            isItemExist = true;
          }
        });
        sowPaymentTerm.isItemExist = isItemExist;
      });
    });
  }

  public getProcessedRequest(isSubmit: boolean): OrderRequest {
    this.orderRequest.order = this.formGroup.value;
    this.orderRequest.order.requiredDate = this.orderRequest.order.requiredDate
      ? new Date(this.orderRequest.order.requiredDate)
      : new Date();

    this.orderRequest.order.amount = this.amount;
    this.orderRequest.order.goodsAmount = this.goodsAmount;
    this.orderRequest.order.serviceAmount = this.serviceAmount;
    this.orderRequest.order.source = this.source;
    this.orderRequest.contract = this.contract;

    if (this.orderPreparationId) {
      this.orderRequest.order.orderPreparation = new OrderPreparation();
      this.orderRequest.order.orderPreparation.id = this.orderPreparationId;
    } else {
      this.orderRequest.order.orderPreparation = null;
    }

    this.orderRequest.fileList = this.formGroup.value.docList;

    this.orderRequest.guaranteeList = this.guaranteeList;
    this.orderRequest.finesList = this.finesList;

    const orderShippingList =
      this.orderShippingOptionList.getRequestValues() as Array<OrderShipping>;

    if (orderShippingList && orderShippingList.length === 1) {
      /** one Address */
      this.orderShippingItemList = [];

      (orderShippingList as Array<OrderShipping>).forEach(orderShip => {
        this.prItemReleasedList.forEach(prItemRel => {
          const orderShippingItem = new OrderShippingItem();
          orderShippingItem.orderShipping = orderShip;
          orderShippingItem.orderItem = new OrderItem();
          orderShippingItem.orderItem.prItemReleased = prItemRel;
          orderShippingItem.quantity = prItemRel.quantity || 0; // max quantity
          orderShippingItem.remainingQuantity = 0; /** fully used */

          /** crud operation for revision */
          if (
            this.response.orderItemList &&
            this.response.orderItemList.length > 0
          ) {
            const existingOrderItem = this.response.orderItemList.find(
              oi =>
                oi.prItemReleased.id ===
                orderShippingItem.orderItem.prItemReleased.id
            );
            if (existingOrderItem) {
              /** existing */
              orderShippingItem.orderItem.id = existingOrderItem.id;
              orderShippingItem.orderItem.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_UPDATE;
            } else {
              orderShippingItem.orderItem.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
            }
          } else {
            orderShippingItem.orderItem.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }

          if (
            this.response.orderShippingList &&
            this.response.orderShippingList.length > 0
          ) {
            const existingOrderShip = this.response.orderShippingList.find(
              oship =>
                oship.address.id === orderShippingItem.orderShipping.address.id
            );
            if (existingOrderShip) {
              /** existing */
              orderShippingItem.orderShipping.id = existingOrderShip.id;
              orderShippingItem.orderShipping.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_UPDATE;
            } else {
              orderShippingItem.orderShipping.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
            }
          } else {
            orderShippingItem.orderShipping.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }

          this.orderShippingItemList.push(orderShippingItem);
        });
      });
    } else if (orderShippingList && orderShippingList.length > 1) {
      /** more than one address */
      this.orderShippingItemList.forEach(orderShippingItem => {
        /** crud operation for revision */
        if (
          this.response.orderItemList &&
          this.response.orderItemList.length > 0
        ) {
          const existingOrderItem = this.response.orderItemList.find(
            oi =>
              oi.prItemReleased.id ===
              orderShippingItem.orderItem.prItemReleased.id
          );
          if (existingOrderItem) {
            /** existing */
            orderShippingItem.orderItem.id = existingOrderItem.id;
            orderShippingItem.orderItem.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_UPDATE;
          } else {
            orderShippingItem.orderItem.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }
        } else {
          orderShippingItem.orderItem.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        }

        if (
          this.response.orderShippingList &&
          this.response.orderShippingList.length > 0
        ) {
          const existingOrderShip = this.response.orderShippingList.find(
            oship =>
              oship.address.id === orderShippingItem.orderShipping.address.id
          );
          if (existingOrderShip) {
            /** existing */
            orderShippingItem.orderShipping.id = existingOrderShip.id;
            orderShippingItem.orderShipping.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_UPDATE;
          } else {
            orderShippingItem.orderShipping.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
          }
        } else {
          orderShippingItem.orderShipping.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        }

        orderShippingItem.remainingQuantity =
          this.getRemainingQuantityItem(orderShippingItem);
      });
    }

    this.orderRequest.orderShippingItemList = this.orderShippingItemList;

    if (
      !this.orderShippingItemList ||
      (this.orderShippingItemList && this.orderShippingItemList.length === 0)
    ) {
      this.orderRequest.prItemReleasedList = this.prItemReleasedList;
    }

    this.orderRequest.sowList = this.sowList;

    /** crud operation for revision */
    if (this.response.sowList && this.response.sowList.length > 0) {
      this.orderRequest.sowList.forEach(sow => {
        if (this.response.sowList.find(sw => sw.id === sow.id)) {
          sow.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_UPDATE;
        } else {
          sow.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        }
      });
    } else {
      this.orderRequest.sowList.forEach(sow => {
        sow.crudOperation = this.global.appConstant.core.CRUD_OPERATION_INSERT;
      });
    }

    if (
      this.response.order &&
      this.response.order.orderStatus.code ===
        this.global.appConstant.pm.ORDER_STATUS_VENDOR_REVISION
    ) {
      this.orderRequest.responseToConfirmNote =
        this.formGroup.value.responseToConfirmNote;
    }

    this.orderRequest.isSubmit = isSubmit;
    return this.orderRequest;
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public getRemainingQuantityItem(
    orderShippingItem: OrderShippingItem
  ): number {
    const maxQuantity = orderShippingItem.orderItem.prItemReleased.quantity;
    let totalUsedQuantity = 0;
    if (this.orderShippingItemList && this.orderShippingItemList.length > 1) {
      const orderShipItemList = this.orderShippingItemList.filter(
        osi =>
          osi.orderItem.prItemReleased.id ===
          orderShippingItem.orderItem.prItemReleased.id
      );
      orderShipItemList.forEach(oShipItem => {
        totalUsedQuantity += oShipItem.quantity;
      });
    }
    const remainingQuantity = maxQuantity - totalUsedQuantity;
    return remainingQuantity;
  }

  public getUserNameList(addressPicList: AddressPic[]): string {
    return addressPicList.map(a => a.user.name).join(', ');
  }

  public isShowChangeHistory(): boolean {
    return (
      this.response &&
      ((this.response.order &&
        this.response.order.orderStatus &&
        this.response.order.orderStatus.code &&
        this.statusForApprovalList.includes(
          this.response.order.orderStatus.code
        )) ||
        (this.response.orderActivityHistoryList &&
          this.response.orderActivityHistoryList.length > 0 &&
          this.response.orderActivityHistoryList[0].orderActivityStatus.code ===
            this.global.appConstant.pm.ORDER_ACTIVITY_STATUS_RESEND))
    );
  }

  public onChangeFinesList(event): void {
    this.finesList = event;
  }

  public onChangeGuaranteeList(event): void {
    this.guaranteeList = event;
  }

  public isRoleTypeCommittee(): boolean {
    return (
      this.global.userSession.activeUserRole.role.type ===
      this.global.appConstant.core.ROLE_TYPE_COMMITTEE
    );
  }
}
