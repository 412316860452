import { NgModule } from '@angular/core';
import { CKEditorModule } from 'ckeditor4-angular';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppWysiwygComponent } from './app-wysiwyg.component';
@NgModule({
  imports: [VendorModule, AppTooltipWrapperModule, CKEditorModule],
  declarations: [AppWysiwygComponent],
  exports: [AppWysiwygComponent]
})
export class AppWysiwygModule {}
