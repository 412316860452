<app-popup [header]="header" [isLoading]="formLoading">
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    novalidate
    *ngIf="!formLoading"
  >
    <div
      class="form-group row text-sm-right"
      [ngClass]="
        router.url !== '/pages/contract-monitoring/detail' ? 'required' : ''
      "
    >
      <label class="col-sm-3 control-label">{{
        'app-popup-procurement-guarantee.form.type' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="LG"
          [optionList]="guaranteeBondTypeOptionList"
          formControlName="guaranteeBondType"
          (onChange)="onChangeGuaranteeBondType()"
        ></app-combo-box>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="
        router.url !== '/pages/contract-monitoring/detail' ? 'required' : ''
      "
    >
      <label class="col-sm-3 control-label">{{
        'app-popup-procurement-guarantee.form.name' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="name"
          type="text"
          maxLength="32"
        ></app-text-field>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="
        router.url !== '/pages/contract-monitoring/detail' ? 'required' : ''
      "
    >
      <label class="col-sm-3 control-label">{{
        'app-popup-procurement-guarantee.form.percentageValue' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="MD"
          type="decimal"
          formControlName="percentageValue"
          maxLength="15"
          symbolic="%"
          (onChange)="onChangePercentageValue()"
        >
        </app-text-field
      ></div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-popup-procurement-guarantee.form.amount' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          formGroup.controls.amount.value
            ? (formGroup.controls.amount.value | currency: ' ')
            : '-'
        }}
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="
        router.url !== '/pages/contract-monitoring/detail' ? 'required' : ''
      "
    >
      <label class="col-sm-3 control-label">{{
        'app-popup-procurement-guarantee.form.submissionDate' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-date-picker
          size="LG"
          formControlName="submissionDate"
          [minDate]="currentDate"
          maxDate="endDate"
        >
        </app-date-picker>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="
        router.url === '/pages/contract-monitoring/detail' ? 'required' : ''
      "
    >
      <label class="col-sm-3 control-label">{{
        'app-popup-procurement-guarantee.form.startDate' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-date-picker
          size="LG"
          formControlName="startDate"
          [minDate]="currentDate"
        >
        </app-date-picker>
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="
        router.url === '/pages/contract-monitoring/detail' ? 'required' : ''
      "
    >
      <label class="col-sm-3 control-label">{{
        'app-popup-procurement-guarantee.form.endDate' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-date-picker
          size="LG"
          formControlName="endDate"
          minDate="startDate"
        >
        </app-date-picker>
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-popup-procurement-guarantee.form.description' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-area
          size="LG"
          formControlName="description"
          maxlength="512"
        ></app-text-area>
      </div>
    </div>
  </form>

  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
        'app.button.close' | translate
      }}</app-button>
      <app-button color="PRIMARY" (onClick)="doSave()">{{
        'app.button.save' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
