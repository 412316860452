<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="mvc-generator.title"
      detail=""
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'mvc-generator.form.tableName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              formControlName="tableName"
              maxlength="32"
            >
            </app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'mvc-generator.form.fileName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              formControlName="fileName"
              maxlength="32"
            ></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'mvc-generator.form.parentMenu' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              formControlName="parentMenu"
              [optionList]="parentMenuList"
            ></app-combo-box>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'mvc-generator.form.moduleGroup' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              formControlName="moduleGroup"
              [optionList]="moduleGroupList"
            ></app-combo-box>
          </div>
        </div>

        <div class="button-group button-group-center">
          <app-button (onClick)="doSave()" [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
