<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="disclaimer.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-tab formArrayName="disclaimerList" bordered="true">
          <app-tab-item
            header="{{ disclaimer.value.lang.name }}"
            *ngFor="
              let disclaimer of formArrayDisclaimerList.controls;
              let i = index
            "
            [formGroup]="disclaimer"
          >
            <div class="form-group row text-sm-right">
              <label class="col-sm-2 control-label">{{
                'disclaimer.form.text' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-wysiwyg formControlName="value" size="XL"></app-wysiwyg>
              </div>
            </div>
          </app-tab-item>
        </app-tab>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">{{
            'app.button.save' | translate
          }}</app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
