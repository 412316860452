import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { RatingProductModel } from './model/rating-product-model';
@Component({
  selector: 'app-rating-product',
  templateUrl: './app-rating-product.component.html',
  styleUrls: ['./app-rating-product.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppRatingProductComponent extends BaseComponentComponent {
  @Input() model: RatingProductModel;
  constructor() {
    super('app-rating-product');
  }

  public onInit(): void {
    this.buildFormGroup();
  }

  buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      rating: [this.model.rating]
    });
  }
}
