<form
  class="form-horizontal"
  [formGroup]="formGroup"
  novalidate
  *ngIf="!model.isLoading"
>
  <div class="form-group row">
    <div class="col-lg-12">
      <app-table
        [isServerSide]="true"
        stringUrl="/tab-activity/index"
        [model]="tableResponse"
      >
      </app-table>
    </div>
  </div>
</form>
