import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { RefutationStatus } from '../../core/bean/refutation-status';
import { AppTableComponent } from '../../core/components/table/components/app-table/app-table.component';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { RefutationView } from '../../core/view/entity/bean/refutation-view';
@Component({
  templateUrl: './refutation-vendor.component.html'
})
export class RefutationVendorComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<RefutationView>;
  public statusOptionList: OptionListModel<RefutationStatus> =
    new OptionListModel();
  constructor(translateService: TranslateService) {
    super('refutation-vendor', translateService);
  }

  public onInit(): void {
    this.setOptionList();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      dateFrom: [null],
      dateTo: [null],
      refutationStatusList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<RefutationStatus[]>('/refutation-vendor/get-option-list')
      .subscribe((statusList: RefutationStatus[]) => {
        if (statusList && statusList.length > 0) {
          this.statusOptionList.setRequestValues(statusList);
        }
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurement.code',
        header: 'table.column.procurementNumber',
        plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
      },
      {
        field: 'procurement.name',
        header: 'table.column.procurementName',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'badge',
            field: 'isRepeat',
            pill: false,
            className: 'badge-catalog badge-danger ml-3',
            colorMap: {
              RE_HELD: 'DANGER'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                if (tablePluginData.row.record.procurement.isRepeat) {
                  const RE_HELD: string = this.translateService.instant(
                    'dynamic-master-attribute.procurementFlag.reHeld'
                  );
                  return RE_HELD;
                }
              }
            }
          },
          {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData): void => {
              if (!tablePluginData.row.record.procurement.isRepeat) {
                tablePluginData.element.children[1].remove();
              }
            }
          }
        ]
      },
      {
        field: 'procurement.procurementMethod.name',
        header: 'table.column.method'
      },
      {
        field: 'endDate',
        header: 'table.column.lastSubmissionDate',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'submittedDate',
        header: 'table.column.submittedDate',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'refutationStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorMap: {
            NEW: 'INFO',
            DRAFT: 'SECONDARY',
            SUBMITTED: 'SUCCESS',
            WAITING_RESPONSE: 'GOOD',
            CLOSED_RESPONSE: 'DANGER',
            HOLD: 'DANGER',
            CANCELED: 'DANGER',
            FAILED: 'DANGER'
          },
          colorField: 'refutationStatus.code'
        }
      }
    ]);
  }

  public doClick(refutationView: RefutationView): void {
    const isSchedule = this.validateSchedule(refutationView);
    if (isSchedule) {
      this.global.routerParams.clear();
      this.global.routerParams.set(
        'procurementId',
        refutationView.procurement.id
      );
      this.global.routerParams.set(
        'refutationStatus',
        refutationView.refutationStatus
      );
      this.global.routerParams.set(
        'urlBackOutside',
        '/pages/refutation-vendor'
      );
      if (
        (refutationView.refutationStatus.code ===
          this.global.appConstant.pm.REFUTATION_STATUS_WAITING_RESPONSE &&
          ((!refutationView.isReference &&
            refutationView.vendorSelectionType.code ===
              this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER) ||
            refutationView.vendorSelectionType.code ===
              this.global.appConstant.pm.VENDOR_SELECTION_TYPE_MULTI_WINNER)) ||
        refutationView.refutationStatus.code ===
          this.global.appConstant.pm.REFUTATION_STATUS_DRAFT
      ) {
        this.global.routerParams.set('todo', 'edit');
      } else {
        this.global.routerParams.set('todo', 'view');
      }
      this.router.navigate(['/pages/refutation-vendor/detail']);
    } else {
      this.global.modalService.scheduleMismatch();
    }
  }
  public validateSchedule(refutationView: RefutationView): boolean {
    if (refutationView.procurement.workflowModelPrcs.module.isSchedule) {
      if (refutationView.startDate && refutationView.endDate) {
        return (
          new Date(refutationView.startDate).getTime() <= new Date().getTime()
        );
      } else {
        return true;
      }
    }
  }

  public onChangeFilter(): void {
    this.formGroup.patchValue({
      dateFrom: this.formGroup.value.date?.from,
      dateTo: this.formGroup.value.date?.to
    });
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
