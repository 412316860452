import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppApprovalPrcsXModule } from "src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module";
import { SharedModule } from "src/app/core/shared/shared.module";
import { ConfirmGuard } from "../../core/guard/confirm.guard";
import { AccidentVictimEditAddComponent } from "./accident-victim-edit-add.component";
import { AccidentVictimComponent } from "./accident-victim.component";

const routes: Routes = [
  { path: '', component: AccidentVictimComponent, data: { breadcrumb: '' } },
  {
    path: 'detail',
    component: AccidentVictimEditAddComponent,
    data: { breadcrumb: 'accident-victim.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes), AppApprovalPrcsXModule
  ],
  declarations: [
    AccidentVictimEditAddComponent,
    AccidentVictimComponent
  ],
  providers: []
})
export class AccidentVictimModule { }