import { ContractTemplate } from 'src/app/core/bean/contract-template';
import { ContractTemplateParameter } from 'src/app/core/bean/contract-template-parameter';
import { FileObject } from 'src/app/core/components/upload';

export class ContractTemplateEditAddRequest {
    contractTemplateParameterList: ContractTemplateParameter[];
    fileObjectList: FileObject[];
    dealingAgreementTypeCodeList: string[];
    contractTemplate: ContractTemplate;
}
