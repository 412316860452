import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Workflow } from './../../core/bean/workflow';
import { WorkflowModel } from './../../core/bean/workflow-model';
@Component({
  templateUrl: './approval-workflow-detail.component.html'
})
export class ApprovalWorkflowDetailComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<WorkflowModel>;
  public workflowId: number;
  public workflow: Workflow;
  constructor(translateService: TranslateService) {
    super('approval-workflow', translateService);
  }

  public onInit(): void {
    this.doSetWorkflowFromRouterParams();
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doSetWorkflowFromRouterParams(): void {
    this.workflow = this.global.routerParams.get('workflow');
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'module.name', header: 'table.column.stage', customClass: 'text-center' },
      { field: 'type', header: 'table.column.type', datamap: '{ "1" : "Initiation", "2" : "Main", "3" : "Additional" }', customClass: 'text-center' }    ]);
  }

  public doAdd(workflowModel: WorkflowModel): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('workflowModel', workflowModel);
    this.global.routerParams.set('workflow', this.workflow);
    this.router.navigate(['/pages/approval-workflow/add/']);
  }

  public doCancel(): void {
    this.router.navigate(['/pages/approval-workflow/']);
  }
}
