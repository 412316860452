import { CurrencyPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BillingTerm } from 'src/app/core/bean/billing-term';
import { ContractType } from 'src/app/core/bean/contract-type';
import { DealingAgreementType } from 'src/app/core/bean/dealing-agreement-type';
import { PaymentTerm } from 'src/app/core/bean/payment-term';
import { ProcurementResult } from 'src/app/core/bean/procurement-result';
import { ProcurementSow } from 'src/app/core/bean/procurement-sow';
import { ProcurementSowPaymentTermItem } from 'src/app/core/bean/procurement-sow-payment-term-item';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { StageOfWork } from 'src/app/core/bean/stage-of-work';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { Contract } from '../../../bean/contract';
import { Validators } from '../../../validators';
import { AppTableComponent } from '../../app-table/app-table.component';
import { TableResponseModel } from '../../app-table/model/table-response-model';

@Component({
  templateUrl: './app-popup-procurement-scope-work.component.html'
})
export class AppPopupProcurementScopeWorkComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() public todo: string;
  @Input() public procurementSowEdit: any;
  @Input() public contract: Contract;
  @Input() public stageOfWorkList: StageOfWork[] = [];
  @Input() public billingTermList: BillingTerm[] = [];
  @Input() public paymentTermList: PaymentTerm[] = [];
  @Input() public procurementResultServiceList: ProcurementResult[] = [];
  @Input() public procurementResultProductList: ProcurementResult[] = [];
  @Input() public procurementVendor: ProcurementVendor;
  @Input() public availableAmountSow: number;
  @Input() public procurementPaymentItemList: any;
  @Input() public procurementSowPaymentList: any;
  @Input() public procurementSowList: any;
  @Input() public contractType: ContractType;
  @Input() public dealingAgreementType: DealingAgreementType;
  @Input() public isFromWorkDefinition: boolean;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('selectorProduct') tableContract: AppTableComponent;
  @ViewChild('selectorService') tableProcurement: AppTableComponent;

  public stageOfWorkOptionList: OptionListModel<StageOfWork> =
    new OptionListModel();
  public billingTermOptionList: OptionListModel<BillingTerm> =
    new OptionListModel();
  public paymentTermOptionList: OptionListModel<PaymentTerm> =
    new OptionListModel();
  public typeOptionList: OptionListModel<any> = new OptionListModel();
  public durationOptionList: OptionListModel<any> = new OptionListModel();
  public partialOptionList: OptionListModel<any> = new OptionListModel();
  public tableResponseProduct: TableResponseModel<ProcurementSowPaymentTermItem>;
  public tableResponseService: TableResponseModel<ProcurementSowPaymentTermItem>;
  public currentDate: Date = new Date();
  public isDate = false;
  public isDuration = false;
  public isAddMore = false;
  public totalContractValue: number;
  public CURRENCY_DIGITS_INFO: string;
  public isShowAssesment: boolean;

  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal,
    public currency: CurrencyPipe
  ) {
    super('app-popup-procurement-scope-work');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setIsShowAssesment();
    this.setIsViewOnlyFormGroup();
    this.setOptionListModel();
    this.setFormGroup();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public doSetParameterTypeOptionList(): void {
    const typeList = [
      {
        view: this.translateService.instant(
          'app-popup-procurement-scope-work.form.date'
        ),
        value: 1
      },
      {
        view: this.translateService.instant(
          'app-popup-procurement-scope-work.form.duration'
        ),
        value: 2
      }
    ];
    const durationList = [
      {
        name: this.translateService.instant(
          'app-popup-procurement-scope-work.form.day'
        ),
        value: 1
      },
      {
        name: this.translateService.instant(
          'app-popup-procurement-scope-work.form.month'
        ),
        value: 2
      },
      {
        name: this.translateService.instant(
          'app-popup-procurement-scope-work.form.year'
        ),
        value: 3
      }
    ];
    // hide for bjb
    // const partialList = [
    //   {
    //     view: this.translateService.instant(
    //       'app-popup-procurement-scope-work.form.partial'
    //     ),
    //     value: 'true'
    //   },
    //   {
    //     view: this.translateService.instant(
    //       'app-popup-procurement-scope-work.form.nonPartial'
    //     ),
    //     value: 'false'
    //   }
    // ];
    this.durationOptionList.setRequestValues(durationList);
    this.typeOptionList
      .setRequestValues(typeList)
      .setViewPath('view')
      .setValuePath('value');

    // hide for bjb
    // this.partialOptionList
    //   .setRequestValues(partialList)
    //   .setViewPath('view')
    //   .setValuePath('value');
  }

  public setOptionListModel(): void {
    this.doSetParameterTypeOptionList();
    this.stageOfWorkOptionList.setRequestValues(this.stageOfWorkList);
    this.billingTermOptionList.setRequestValues(this.billingTermList);
    if (
      (this.dealingAgreementType && this.dealingAgreementType.name) !==
      this.global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN
    ) {
      const paymentTermList = this.paymentTermList.filter(
        paymentTerm =>
          paymentTerm.code !== this.global.appConstant.cm.PAYMENT_TERM_OTHERS
      );
      this.paymentTermOptionList.setRequestValues(paymentTermList);
    } else {
      this.paymentTermOptionList.setRequestValues(this.paymentTermList);
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      stageOfWork: [null, Validators.compose([Validators.required()])],
      periodType: [null, Validators.compose([Validators.required()])],
      value: [null],
      startDate: [null, Validators.compose([Validators.required()])],
      endDate: [null],
      duration: [null],
      durationType: [null],
      isMonthlyPayment: [null],
      totalTermin: [null],
      amount: [null],
      procurementSowPaymentTermList: this.formBuilder.array([]),
      currentDate: this.currentDate,
      endDateValidate: [null]
    });
    this.formGroup.patchValue({
      currentDate: this.currentDate
    });
    if (
      (this.dealingAgreementType && this.dealingAgreementType.name) ===
      this.global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN
    ) {
      this.formGroup.patchValue({
        stageOfWork: this.stageOfWorkList[0],
        isMonthlyPayment: false
      });
      this.formGroup.get('stageOfWork').setIsView(true);
    }
    if (
      this.router.url === '/pages/contract-monitoring/detail' &&
      this.todo === 'edit'
    ) {
      this.setViewOnly();
    }
    this.getTotalContractValue();
    if (this.todo === 'add') {
      this.buildFormArray();
      this.buildprocurementSowPaymentTermItemList();
    }
  }

  public buildFormArray(): void {
    this.procurementSowPaymentTermList.push(
      this.newprocurementSowPaymentTerm()
    );
  }

  public get procurementSowPaymentTermList(): FormArray {
    return this.formGroup.get('procurementSowPaymentTermList') as FormArray;
  }

  public newprocurementSowPaymentTerm(): FormGroup {
    const formGroup = this.formBuilder.group({
      id: [null],
      paymentTerm: [null, Validators.required()],
      percentageValue: [
        null,
        Validators.compose([Validators.required(), Validators.max(100)])
      ],
      amount: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.max(this.totalContractValue)
        ])
      ],
      // hide for bjb
      // isPartial: [null, Validators.required()], comment for bjb
      billingTermList: [null, Validators.required()],
      isAssessment: [null],
      description: [null],
      status: [null],
      procurementSowPaymentTermItemList: this.formBuilder.array([]),
      procurementSowPaymentTermItemServiceList: this.formBuilder.array([]),
      procurementSowPaymentTermItemProductList: this.formBuilder.array([]),
      procurementSowPaymentTermBillingList: this.formBuilder.array([])
    });
    formGroup.patchValue({
      isAssessment: false
    });
    if (
      (this.dealingAgreementType && this.dealingAgreementType.name) ===
      this.global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN
    ) {
      formGroup.patchValue({
        paymentTerm: this.paymentTermList.find(
          paymentTerm =>
            paymentTerm.code === this.global.appConstant.cm.PAYMENT_TERM_OTHERS
        ),
        percentageValue: 100,
        amount: this.availableAmountSow,
        isAssessment: true
      });
      formGroup.get('paymentTerm').setIsView(true);
      formGroup.get('percentageValue').setIsView(true);
      formGroup.get('amount').setIsView(true);
    }
    if (this.isAddMore) {
      this.addProcurementSowPaymentTermItem(
        formGroup.get('procurementSowPaymentTermItemProductList') as FormArray,
        formGroup.get('procurementSowPaymentTermItemServiceList') as FormArray
      );
      this.setPaymentTermList();
    }
    return formGroup;
  }

  public setPaymentTermList(): void {
    const paymentTermList = this.paymentTermList.filter(paymentTerm =>
      this.procurementSowPaymentTermList.value.every(
        procurementSowPaymentTerm =>
          paymentTerm.id !== procurementSowPaymentTerm.paymentTerm?.id
      )
    );
    if (
      (this.dealingAgreementType && this.dealingAgreementType.name) !==
      this.global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN
    ) {
      const paymentTermLists = paymentTermList.filter(
        paymentTerm =>
          paymentTerm.code !== this.global.appConstant.cm.PAYMENT_TERM_OTHERS
      );
      this.paymentTermOptionList.setRequestValues(paymentTermLists);
    } else {
      this.paymentTermOptionList.setRequestValues(paymentTermList);
    }
  }

  public addProcurementSowPaymentTerm(): void {
    this.isAddMore = true;
    this.procurementSowPaymentTermList.push(
      this.newprocurementSowPaymentTerm()
    );
  }

  public addProcurementSowPaymentTermItem(
    procurementSowPaymentTermItemProductForm: FormArray,
    procurementSowPaymentTermItemServiceForm: FormArray
  ): void {
    const index = this.procurementSowPaymentTermList.value.length - 1;
    const procurementSowPaymentTermItemServiceList =
      this.procurementSowPaymentTermList.controls[index].get(
        'procurementSowPaymentTermItemServiceList'
      ) as FormArray;
    const procurementSowPaymentTermItemProductList =
      this.procurementSowPaymentTermList.controls[index].get(
        'procurementSowPaymentTermItemProductList'
      ) as FormArray;
    procurementSowPaymentTermItemProductList.controls.forEach(
      procurementSowPaymentTermItemProduct => {
        procurementSowPaymentTermItemProductForm.push(
          this.formBuilder.group({
            procurementResult:
              procurementSowPaymentTermItemProduct.value.procurementResult,
            quantity: [null],
            remainingQuantity:
              procurementSowPaymentTermItemProduct.value.remainingQuantity,
            status: [null],
            deliveredTotal: [null]
          })
        );
      }
    );

    procurementSowPaymentTermItemServiceList.controls.forEach(
      procurementSowPaymentTermItemService => {
        procurementSowPaymentTermItemServiceForm.push(
          this.formBuilder.group({
            procurementResult:
              procurementSowPaymentTermItemService.value.procurementResult,
            quantity: [null],
            remainingQuantity:
              procurementSowPaymentTermItemService.value.remainingQuantity,
            status: [null],
            deliveredTotal: [null]
          })
        );
      }
    );
  }

  public buildprocurementSowPaymentTermItemList(): void {
    this.setprocurementSowPaymentTerm();
    const index = this.procurementSowPaymentTermList.value.length - 1;
    const procurementSowPaymentTermItemServiceList =
      this.procurementSowPaymentTermList.controls[index].get(
        'procurementSowPaymentTermItemServiceList'
      ) as FormArray;
    const procurementSowPaymentTermItemProductList =
      this.procurementSowPaymentTermList.controls[index].get(
        'procurementSowPaymentTermItemProductList'
      ) as FormArray;
    if (this.procurementResultServiceList) {
      this.procurementResultServiceList.forEach((procurementResult, i) => {
        const formGroup = this.formBuilder.group({
          id: [null],
          procurementResult,
          quantity: [null],
          remainingQuantity: this.procurementPaymentItemList
            ? this.procurementPaymentItemList
                .procurementSowPaymentTermItemServiceList[i].remainingQuantity
            : +100,
          status: [null],
          deliveredTotal: [null]
        });
        procurementSowPaymentTermItemServiceList.push(formGroup);
      });
    }
    if (this.procurementResultProductList) {
      this.procurementResultProductList.forEach((procurementResult, i) => {
        procurementSowPaymentTermItemProductList.push(
          this.formBuilder.group({
            id: [null],
            procurementResult,
            quantity: [null],
            remainingQuantity: this.procurementPaymentItemList
              ? this.procurementPaymentItemList
                  .procurementSowPaymentTermItemProductList[i].remainingQuantity
              : procurementResult.itemQuantity,
            status: [null],
            deliveredTotal: [null]
          })
        );
      });
    }
    this.getTotalContractValue();
  }

  public setprocurementSowPaymentTerm(): void {
    if (
      this.procurementPaymentItemList &&
      !this.procurementPaymentItemList.procurementSowPaymentTermItemServiceList
    ) {
      this.procurementPaymentItemList.procurementSowPaymentTermItemProductList =
        [];
      this.procurementPaymentItemList.procurementSowPaymentTermItemServiceList =
        [];
      this.procurementPaymentItemList.procurementSowPaymentTermItemList.forEach(
        contractPaymentItem => {
          if (
            contractPaymentItem.procurementResult.procurementItem.item.itemType
              .code === this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
          ) {
            this.procurementPaymentItemList.procurementSowPaymentTermItemProductList.push(
              {
                id: contractPaymentItem.id,
                procurementResult: contractPaymentItem.procurementResult,
                quantity: contractPaymentItem.quantity,
                remainingQuantity: contractPaymentItem.remainingQuantity,
                status: contractPaymentItem.status,
                deliveredTotal: contractPaymentItem.deliveredTotal
              }
            );
          }
          if (
            contractPaymentItem.procurementResult.procurementItem.item.itemType
              .code === this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
          ) {
            this.procurementPaymentItemList.procurementSowPaymentTermItemServiceList.push(
              {
                id: contractPaymentItem.id,
                procurementResult: contractPaymentItem.procurementResult,
                quantity: contractPaymentItem.quantity,
                remainingQuantity: contractPaymentItem.remainingQuantity,
                status: contractPaymentItem.status,
                deliveredTotal: contractPaymentItem.deliveredTotal
              }
            );
          }
        }
      );
    }
  }

  public setProcurementSowPaymentTermItemList(): void {
    this.procurementSowEdit.procurementSowPaymentTermList.forEach(
      (procurementSowPaymentTerm, i) => {
        const billingTermList = [];
        procurementSowPaymentTerm.procurementSowPaymentTermBillingList.forEach(
          procurementSowBilling => {
            billingTermList.push(procurementSowBilling.billingTerm);
          }
        );
        const billingTermLists = [];
        billingTermLists.push(billingTermList);
        // hide for bjb
        // const isPartial = this.partialOptionList
        //   .getRequestValues()
        //   .filter(
        //     partial =>
        //       partial.value === String(procurementSowPaymentTerm.isPartial)
        //   )[0];

        const formGroup = this.formBuilder.group({
          id: [null],
          paymentTerm: [null, Validators.required()],
          percentageValue: [
            null,
            Validators.compose([Validators.required(), Validators.max(100)])
          ],
          amount: [
            null,
            Validators.compose([
              Validators.required(),
              Validators.max(this.totalContractValue)
            ])
          ],
          // isPartial: [null, Validators.required()],
          isAssessment: [null],
          billingTermList: [null, Validators.required()],
          description: [null],
          status: [null],
          isPaymentProcess: [null],
          procurementSowPaymentTermItemList: this.formBuilder.array([]),
          procurementSowPaymentTermItemServiceList: this.formBuilder.array([]),
          procurementSowPaymentTermItemProductList: this.formBuilder.array([]),
          procurementSowPaymentTermBillingList: this.formBuilder.array([])
        });
        formGroup.patchValue({
          id: procurementSowPaymentTerm.id,
          paymentTerm: procurementSowPaymentTerm.paymentTerm,
          percentageValue: procurementSowPaymentTerm.percentageValue,
          amount: procurementSowPaymentTerm.amount,
          // hide for bjb
          // isPartial: isPartial.value,
          status: procurementSowPaymentTerm.status,
          isAssessment: procurementSowPaymentTerm.isAssessment,
          billingTermList,
          description: procurementSowPaymentTerm.description,
          isPaymentProcess: procurementSowPaymentTerm.isPaymentProcess
        });
        if (this.todo === 'view') {
          formGroup.patchValue({
            billingTermList
          });
        }
        if (
          (this.dealingAgreementType && this.dealingAgreementType.name) ===
          this.global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN
        ) {
          formGroup.get('paymentTerm').setIsView(true);
          formGroup.get('percentageValue').setIsView(true);
          formGroup.get('amount').setIsView(true);
        }
        this.procurementSowPaymentTermList.push(formGroup);
        if (
          this.todo === 'view' ||
          (procurementSowPaymentTerm.status ===
            this.global.appConstant.cm
              .PROCUREMENT_SOW_PAYMENT_TERM_STATUS_CLOSED &&
            this.contract &&
            procurementSowPaymentTerm.isPaymentProcess) ||
          (this.router.url === '/pages/contract-monitoring/detail' &&
            this.todo === 'edit')
        ) {
          formGroup.setIsView(true);
        }
        const procurementSowPaymentTermItemProductList =
          this.procurementSowPaymentTermList.controls[i].get(
            'procurementSowPaymentTermItemProductList'
          ) as FormArray;
        const procurementSowPaymentTermItemServiceList =
          this.procurementSowPaymentTermList.controls[i].get(
            'procurementSowPaymentTermItemServiceList'
          ) as FormArray;
        const procurementSowPaymentTermBillingList =
          this.procurementSowPaymentTermList.controls[i].get(
            'procurementSowPaymentTermBillingList'
          ) as FormArray;
        procurementSowPaymentTerm.procurementSowPaymentTermBillingList.forEach(
          procurementSowBilling => {
            procurementSowPaymentTermBillingList.push(
              this.formBuilder.group({
                id: procurementSowBilling.id,
                billingTerm: procurementSowBilling.billingTerm
              })
            );
          }
        );
        procurementSowPaymentTerm.procurementSowPaymentTermItemList.forEach(
          (procurementSowItem, j) => {
            if (
              procurementSowItem.procurementResult.procurementItem.item.itemType
                .code === this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
            ) {
              const formGroupItemProduct = this.formBuilder.group({
                id: procurementSowItem.id,
                procurementResult: procurementSowItem.procurementResult,
                quantity: procurementSowItem.quantity,
                remainingQuantity: this.procurementPaymentItemList
                  ? this.procurementPaymentItemList
                      .procurementSowPaymentTermItemList[j].remainingQuantity
                  : procurementSowItem.remainingQuantity,
                status: procurementSowItem.status,
                deliveredTotal: procurementSowItem.deliveredTotal
              });
              if (
                this.todo === 'view' ||
                (procurementSowPaymentTerm.status ===
                  this.global.appConstant.cm
                    .PROCUREMENT_SOW_PAYMENT_TERM_STATUS_CLOSED &&
                  this.contract &&
                  procurementSowPaymentTerm.isPaymentProcess) ||
                (procurementSowItem.status ===
                  this.global.appConstant.cm
                    .PROCUREMENT_SOW_PAYMENT_TERM_ITEM_STATUS_CLOSED &&
                  this.contract &&
                  procurementSowPaymentTerm.isPaymentProcess) ||
                (this.router.url === '/pages/contract-monitoring/detail' &&
                  this.todo === 'edit')
              ) {
                formGroupItemProduct.setIsView(true);
              }
              procurementSowPaymentTermItemProductList.push(
                formGroupItemProduct
              );
            }
            if (
              procurementSowItem.procurementResult.procurementItem.item.itemType
                .code === this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
            ) {
              const formGroupItemService = this.formBuilder.group({
                id: procurementSowItem.id,
                procurementResult: procurementSowItem.procurementResult,
                quantity: procurementSowItem.quantity,
                remainingQuantity: this.procurementPaymentItemList
                  ? this.procurementPaymentItemList
                      .procurementSowPaymentTermItemList[j].remainingQuantity
                  : procurementSowItem.remainingQuantity,
                status: procurementSowItem.status,
                deliveredTotal: procurementSowItem.deliveredTotal
              });
              if (
                this.todo === 'view' ||
                (procurementSowPaymentTerm.status ===
                  this.global.appConstant.cm
                    .PROCUREMENT_SOW_PAYMENT_TERM_STATUS_CLOSED &&
                  this.contract &&
                  procurementSowPaymentTerm.isPaymentProcess) ||
                (procurementSowItem.status ===
                  this.global.appConstant.cm
                    .PROCUREMENT_SOW_PAYMENT_TERM_ITEM_STATUS_CLOSED &&
                  this.contract &&
                  procurementSowPaymentTerm.isPaymentProcess) ||
                (this.router.url === '/pages/contract-monitoring/detail' &&
                  this.todo === 'edit')
              ) {
                formGroupItemService.setIsView(true);
              }
              procurementSowPaymentTermItemServiceList.push(
                formGroupItemService
              );
            }
          }
        );
      }
    );
    this.getTotalContractValue();
  }

  public setFormGroup(): void {
    if (this.todo !== 'add') {
      const durationType = this.durationOptionList
        .getRequestValues()
        .filter(
          durationType =>
            durationType.value === this.procurementSowEdit.durationType
        )[0];
      const startDate: Date = new Date(this.procurementSowEdit.startDate);
      const endDate: Date = new Date(this.procurementSowEdit.endDate);
      this.formGroup.patchValue({
        id: this.procurementSowEdit.id,
        stageOfWork: this.procurementSowEdit.stageOfWork,
        periodType: this.procurementSowEdit.periodType,
        startDate,
        endDate,
        duration: this.procurementSowEdit.duration,
        durationType,
        isMonthlyPayment: this.procurementSowEdit.isMonthlyPayment,
        totalTermin: this.procurementSowEdit.totalTermin,
        amount: this.procurementSowEdit.amount,
        currentDate: this.currentDate
      });

      this.onChangeType();
      if (this.todo !== 'view') {
        this.onChangeStage();
      }
      if (
        this.procurementSowEdit.stageOfWork.code ===
          this.global.appConstant.cm.STAGE_OF_WORK_STAGE_1 &&
        this.contractType?.code ===
          this.global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
      ) {
        this.formGroup.get('startDate').setIsView(true);
      }
      this.setProcurementSowPaymentTermItemList();
      this.setPaymentTermList();
      this.setStateReady();
    } else {
      this.formGroup.patchValue({
        periodType: 1,
        isMonthlyPayment: false
      });
      this.onChangeType();
      this.setStateReady();
    }

    if (this.router.url === '/pages/procurement-scope-work/edit') {
      this.formGroup.get('periodType').clearValidators();
      this.formGroup.get('startDate').clearValidators();
      this.formGroup.get('endDate').clearValidators();
      this.formGroup.get('periodType').updateValueAndValidity();
      this.formGroup.get('startDate').updateValueAndValidity();
      this.formGroup.get('endDate').updateValueAndValidity();
    } else if (
      this.router.url === '/pages/contract-monitoring/detail' &&
      this.todo === 'edit'
    ) {
      this.formGroup.get('startDate').setIsView(false);
      this.formGroup.get('endDate').setIsView(false);
      this.formGroup.get('duration').setIsView(false);
      this.formGroup.get('periodType').setIsView(false);
    }
  }

  public onChangeStage(): void {
    const stageOfWork = this.formGroup.value.stageOfWork;
    this.procurementSowList.value.forEach(procurementSow => {
      if (procurementSow.stageOfWork.id < stageOfWork.id) {
        const date: Date = new Date(procurementSow.endDate);
        this.formGroup.patchValue({
          currentDate: date
        });
      }

      if (procurementSow.stageOfWork.id > stageOfWork.id) {
        const date: Date = new Date(procurementSow.startDate);
        this.formGroup.patchValue({
          endDateValidate: date
        });
      }
    });
  }

  public onChangeType(): void {
    const type = this.formGroup.value.periodType;
    if (type === 1) {
      this.isDate = true;
      this.isDuration = false;
      this.formGroup.get('endDate').clearValidators();
      this.formGroup.get('duration').clearValidators();
      this.formGroup.get('duration').updateValueAndValidity();
      this.formGroup.get('durationType').clearValidators();
      this.formGroup.get('durationType').updateValueAndValidity();
      this.formGroup.get('endDate').setValidators([Validators.required()]);
      this.formGroup.get('endDate').updateValueAndValidity();
    } else {
      this.isDuration = true;
      this.isDate = false;
      this.formGroup.get('endDate').clearValidators();
      this.formGroup.get('endDate').updateValueAndValidity();
      this.formGroup.get('duration').clearValidators();
      this.formGroup
        .get('duration')
        .setValidators([Validators.required(), Validators.maxLength(5)]);
      this.formGroup.get('duration').updateValueAndValidity();
      this.formGroup.get('durationType').clearValidators();
      this.formGroup.get('durationType').setValidators([Validators.required()]);
      this.formGroup.get('durationType').updateValueAndValidity();
    }
  }

  public onChangePaymentTerm(index: number): void {
    const paymentTerm =
      this.procurementSowPaymentTermList.controls[index].get(
        'paymentTerm'
      ).value;
    if (paymentTerm) {
      this.setPaymentTermList();
    }
  }

  public onInputQuantityProduct(
    paymentItemProduct: FormGroup,
    j: number,
    i: number
  ): void {
    const quantity = paymentItemProduct.controls.quantity;
    let procurementResult = this.procurementPaymentItemList
      ? this.procurementPaymentItemList
          .procurementSowPaymentTermItemProductList[j].remainingQuantity
      : paymentItemProduct.controls.procurementResult.value.itemQuantity;
    let remainingValue = paymentItemProduct.get('remainingQuantity').value;
    if (this.todo !== 'add' && !this.isAddMore) {
      remainingValue = this.procurementSowEdit.procurementSowPaymentTermList[i]
        ? this.procurementSowEdit.procurementSowPaymentTermList[i]
            .procurementSowPaymentTermItemProductList[j].remainingQuantity
        : remainingValue;
      let oldQuantity = 0;

      this.procurementSowEdit.procurementSowPaymentTermList.forEach(payment => {
        oldQuantity =
          oldQuantity +
          +payment.procurementSowPaymentTermItemProductList[j].quantity;
      });
      procurementResult = +oldQuantity + +remainingValue;
    } else if (this.todo !== 'add' && this.isAddMore) {
      const remainingValue =
        +this.procurementPaymentItemList
          .procurementSowPaymentTermItemProductList[j].remainingQuantity;
      let oldQuantity = 0;
      this.procurementSowEdit.procurementSowPaymentTermList.forEach(payment => {
        oldQuantity =
          oldQuantity +
          +payment.procurementSowPaymentTermItemProductList[j].quantity;
      });
      procurementResult = +oldQuantity + +remainingValue;
    }
    const totalQuantity = this.getTotalQuantityProduct(j);

    if (+totalQuantity > +procurementResult.toFixed(2)) {
      quantity.setValidators([
        Validators.max(
          +remainingValue === null ? +procurementResult : +remainingValue
        )
      ]);
      quantity.setErrors({
        message: this.translateService.instant('app.validation.max')
      });
      quantity.markAsTouched({ onlySelf: true });
      quantity.updateValueAndValidity();
    } else if (+paymentItemProduct.value.deliveredTotal > +quantity.value) {
      quantity.setValidators([
        Validators.min(+paymentItemProduct.value.deliveredTotal),
        Validators.required()
      ]);
      quantity.setErrors({
        message: this.translateService.instant(
          'app-popup-procurement-scope-work.validation.minDelivered'
        )
      });
      quantity.markAsTouched({ onlySelf: true });
    } else {
      this.procurementSowPaymentTermList.controls.forEach(
        (paymentFormGroup: FormGroup) => {
          (
            paymentFormGroup.get(
              'procurementSowPaymentTermItemProductList'
            ) as FormArray
          ).controls[j]
            .get('quantity')
            .clearValidators();

          (
            paymentFormGroup.get(
              'procurementSowPaymentTermItemProductList'
            ) as FormArray
          ).controls[j]
            .get('quantity')
            .updateValueAndValidity();
        }
      );
      quantity.clearValidators();
      quantity.updateValueAndValidity();
    }
    const remainingQuantity = +procurementResult - +totalQuantity;
    if (remainingQuantity || remainingQuantity !== null) {
      this.procurementSowPaymentTermList.controls.forEach(
        (paymentFormGroup: FormGroup) => {
          (
            paymentFormGroup.get(
              'procurementSowPaymentTermItemProductList'
            ) as FormArray
          ).controls[j]
            .get('remainingQuantity')
            .patchValue(+remainingQuantity.toFixed(2));
        }
      );
    }
  }

  public getTotalQuantityProduct(j: number): number {
    let totalQuantity = 0;
    this.procurementSowPaymentTermList.controls.forEach(
      (paymentFormGroup: FormGroup) => {
        totalQuantity =
          totalQuantity +
          +(
            paymentFormGroup.get(
              'procurementSowPaymentTermItemProductList'
            ) as FormArray
          ).controls[j].get('quantity').value;
      }
    );
    return totalQuantity;
  }

  public onInputQuantityService(
    paymentItemService: FormGroup,
    j: number,
    i: number
  ): void {
    const quantity = paymentItemService.controls.quantity;
    let remainingValue = paymentItemService.get('remainingQuantity').value;
    let total = this.procurementPaymentItemList
      ? this.procurementPaymentItemList
          .procurementSowPaymentTermItemServiceList[j].remainingQuantity
      : 100;
    if (this.todo !== 'add' && !this.isAddMore) {
      remainingValue = this.procurementSowEdit.procurementSowPaymentTermList[i]
        ? this.procurementSowEdit.procurementSowPaymentTermList[i]
            .procurementSowPaymentTermItemServiceList[j].remainingQuantity
        : remainingValue;

      let oldQuantity = 0;
      this.procurementSowEdit.procurementSowPaymentTermList.forEach(payment => {
        oldQuantity =
          oldQuantity +
          +payment.procurementSowPaymentTermItemServiceList[j].quantity;
      });
      total = +oldQuantity + +remainingValue;
    } else if (this.todo !== 'add' && this.isAddMore) {
      const remainingValue =
        +this.procurementPaymentItemList
          .procurementSowPaymentTermItemServiceList[j].remainingQuantity;
      let oldQuantity = 0;
      this.procurementSowEdit.procurementSowPaymentTermList.forEach(payment => {
        oldQuantity =
          oldQuantity +
          +payment.procurementSowPaymentTermItemServiceList[j].quantity;
      });
      total = +oldQuantity + +remainingValue;
    }
    const totalQuantity = this.getTotalQuantityService(j);
    if (+totalQuantity > +total) {
      quantity.setValidators([Validators.max(+remainingValue)]);
      quantity.setErrors({
        max: true,
        message: this.translateService.instant('app.validation.max')
      });
      quantity.updateValueAndValidity();
    } else if (+paymentItemService.value.deliveredTotal > +quantity.value) {
      quantity.setValidators([
        Validators.min(+paymentItemService.value.deliveredTotal),
        Validators.required()
      ]);
      quantity.setErrors({
        message: this.translateService.instant(
          'app-popup-procurement-scope-work.validation.minDelivered'
        )
      });
      quantity.markAsTouched({ onlySelf: true });
    } else {
      this.procurementSowPaymentTermList.controls.forEach(
        (paymentFormGroup: FormGroup) => {
          (
            paymentFormGroup.get(
              'procurementSowPaymentTermItemServiceList'
            ) as FormArray
          ).controls[j]
            .get('quantity')
            .clearValidators();

          (
            paymentFormGroup.get(
              'procurementSowPaymentTermItemServiceList'
            ) as FormArray
          ).controls[j]
            .get('quantity')
            .updateValueAndValidity();
        }
      );
      quantity.clearValidators();
      quantity.updateValueAndValidity();
    }
    const remainingQuantity = +total - +totalQuantity;
    this.procurementSowPaymentTermList.controls.forEach(
      (paymentFormGroup: FormGroup) => {
        (
          paymentFormGroup.get(
            'procurementSowPaymentTermItemServiceList'
          ) as FormArray
        ).controls[j]
          .get('remainingQuantity')
          .patchValue(remainingQuantity.toFixed(2));
      }
    );
  }

  public getTotalQuantityService(j: number): number {
    let totalQuantity = 0;
    this.procurementSowPaymentTermList.controls.forEach(
      (paymentFormGroup: FormGroup) => {
        totalQuantity =
          totalQuantity +
          +(
            paymentFormGroup.get(
              'procurementSowPaymentTermItemServiceList'
            ) as FormArray
          ).controls[j].get('quantity').value;
      }
    );
    return totalQuantity;
  }

  public onChangePercentageValue(payment: FormGroup, index: number): void {
    const amountForm = payment.controls.amount;
    const percentageValue =
      this.procurementSowPaymentTermList.controls[index].get(
        'percentageValue'
      ).value;
    const procurementAmount = this.contract
      ? this.contract.procurementVendor.amount
      : this.procurementVendor.amount;
    const amount = procurementAmount * (+percentageValue / 100);
    this.procurementSowPaymentTermList.controls[index].patchValue({
      amount
    });
    this.getTotalContractValue();
    if (this.totalContractValue < 0) {
      amountForm.setErrors({
        max: true,
        message: this.translateService.instant('app.validation.max')
      });
      amountForm.markAsDirty();
      amountForm.markAsTouched();
      this.totalContractValue = 0;
    }
  }

  public onChangeAmount(index: number): void {
    const amount =
      this.procurementSowPaymentTermList.controls[index].get('amount').value;
    const procurementAmount = this.contract
      ? this.contract.procurementVendor.amount
      : this.procurementVendor.amount;
    const percentageValue = (+amount / +procurementAmount) * 100;
    this.procurementSowPaymentTermList.controls[index].patchValue({
      percentageValue: this.global.converterService.convertNumber(
        percentageValue.toString()
      )
    });
    this.getTotalContractValue();
    if (this.totalContractValue < 0) {
      this.procurementSowPaymentTermList.controls[index]
        .get('amount')
        .setErrors({
          message: this.translateService.instant('app.validation.max')
        });
      this.procurementSowPaymentTermList.controls[index]
        .get('amount')
        .updateValueAndValidity();
      this.totalContractValue = 0;
    }
  }

  public getTotalContractValue(): void {
    let totalContractValue = 0;
    this.procurementSowPaymentTermList.controls.forEach(
      procurementSowPayementTerm => {
        totalContractValue =
          totalContractValue + +procurementSowPayementTerm.value.amount;
      }
    );
    this.totalContractValue = +this.availableAmountSow - +totalContractValue;
  }

  public doOnChangeBillingTermList(index: number, event: any): void {
    const procurementSowPaymentTermListForm = this.formGroup.controls
      .procurementSowPaymentTermList as FormGroup;
    const procurementSowPaymentTermBillingList =
      procurementSowPaymentTermListForm.controls[index].get(
        'procurementSowPaymentTermBillingList'
      ) as FormArray;
    procurementSowPaymentTermBillingList.clear();
    event.forEach(billingTerm => {
      procurementSowPaymentTermBillingList.push(
        this.formBuilder.group({
          id: event.id,
          billingTerm
        })
      );
    });
  }

  public doDeletePaymentTerm(index: number): void {
    if (
      this.contractType?.code ===
        this.global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT &&
      index === 0 &&
      this.formGroup.get('stageOfWork')?.value?.code ===
        this.global.appConstant.cm.STAGE_OF_WORK_STAGE_1
    ) {
      this.global.alertService.showInfo(
        this.global.translateService.instant(
          'app-popup-procurement-scope-work.validation.cantDeletePaymentTerm1'
        ),
        '.main-row-popup-scope-work'
      );
    } else {
      this.global.modalService
        .deleteConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const amount =
              this.procurementSowPaymentTermList.controls[index].get(
                'amount'
              ).value;
            const procurementSowPaymentTermItemServiceList =
              this.procurementSowPaymentTermList.controls[index].get(
                'procurementSowPaymentTermItemServiceList'
              ) as FormArray;
            const procurementSowPaymentTermItemProductList =
              this.procurementSowPaymentTermList.controls[index].get(
                'procurementSowPaymentTermItemProductList'
              ) as FormArray;
            this.totalContractValue = this.totalContractValue + +amount;
            this.procurementSowPaymentTermList.removeAt(index);

            procurementSowPaymentTermItemServiceList.value.forEach(
              itemService => {
                this.procurementSowPaymentTermList.controls.forEach(
                  (paymentFormGroup: FormGroup) => {
                    (
                      paymentFormGroup.get(
                        'procurementSowPaymentTermItemServiceList'
                      ) as FormArray
                    ).controls.forEach((sowProduct, j) => {
                      if (
                        itemService.procurementResult.id ===
                        sowProduct.value.procurementResult.id
                      ) {
                        (
                          paymentFormGroup.get(
                            'procurementSowPaymentTermItemServiceList'
                          ) as FormArray
                        ).controls[j]
                          .get('remainingQuantity')
                          .patchValue(
                            +sowProduct.value.remainingQuantity +
                              +itemService.quantity
                          );
                      }
                    });
                  }
                );
              }
            );

            procurementSowPaymentTermItemProductList.value.forEach(
              itemProduct => {
                this.procurementSowPaymentTermList.controls.forEach(
                  (paymentFormGroup: FormGroup) => {
                    (
                      paymentFormGroup.get(
                        'procurementSowPaymentTermItemProductList'
                      ) as FormArray
                    ).controls.forEach((sowProduct, j) => {
                      if (
                        itemProduct.procurementResult.id ===
                        sowProduct.value.procurementResult.id
                      ) {
                        (
                          paymentFormGroup.get(
                            'procurementSowPaymentTermItemProductList'
                          ) as FormArray
                        ).controls[j]
                          .get('remainingQuantity')
                          .patchValue(
                            +sowProduct.value.remainingQuantity +
                              +itemProduct.quantity
                          );
                      }
                    });
                  }
                );
              }
            );
          }
        });
    }
  }

  public doSetEndDate(): void {
    const { duration, durationType, startDate } = this.formGroup.value;
    const endDate: Date = new Date(startDate);
    if (durationType.value === 2) {
      endDate.setMonth(startDate.getMonth() + +duration);
    } else if (durationType.value === 1) {
      endDate.setDate(startDate.getDate() + +duration);
    } else if (durationType.value === 3) {
      endDate.setFullYear(startDate.getFullYear() + +duration);
    }
    this.formGroup.patchValue({
      endDate
    });
  }

  public doSetprocurementSowPaymentItemList(): void {
    this.procurementSowPaymentTermList.controls.forEach(
      procurementSowPaymentTerm => {
        procurementSowPaymentTerm.value.procurementSowPaymentTermItemProductList.forEach(
          itemProduct => {
            procurementSowPaymentTerm.value.procurementSowPaymentTermItemList.push(
              itemProduct
            );
          }
        );
        procurementSowPaymentTerm.value.procurementSowPaymentTermItemServiceList.forEach(
          itemService => {
            procurementSowPaymentTerm.value.procurementSowPaymentTermItemList.push(
              itemService
            );
          }
        );
      }
    );
  }

  public doSetRemainingQuantity(): void {
    const procurementSowPaymentTermItemProductList =
      this.procurementSowPaymentTermList.controls[0].value
        .procurementSowPaymentTermItemProductList;
    const procurementSowPaymentTermItemServiceList =
      this.procurementSowPaymentTermList.controls[0].value
        .procurementSowPaymentTermItemServiceList;
    const procurementSowPaymentTermItemList =
      this.procurementSowPaymentTermList.controls[0].value
        .procurementSowPaymentTermItemList;
    if (this.procurementSowList) {
      procurementSowPaymentTermItemProductList.forEach(paymentProduct => {
        this.procurementSowList.controls.forEach(procurementSow => {
          procurementSow.value.procurementSowPaymentTermList.forEach(
            sowPayment => {
              sowPayment.procurementSowPaymentTermItemProductList.forEach(
                itemProduct => {
                  if (
                    paymentProduct.procurementResult.id ===
                    itemProduct.procurementResult.id
                  ) {
                    itemProduct.remainingQuantity =
                      paymentProduct.remainingQuantity;
                  }
                }
              );
            }
          );
        });
      });
      procurementSowPaymentTermItemServiceList.forEach(paymentProduct => {
        this.procurementSowList.controls.forEach(procurementSow => {
          procurementSow.value.procurementSowPaymentTermList.forEach(
            sowPayment => {
              sowPayment.procurementSowPaymentTermItemServiceList.forEach(
                itemProduct => {
                  if (
                    paymentProduct.procurementResult.id ===
                    itemProduct.procurementResult.id
                  ) {
                    itemProduct.remainingQuantity =
                      paymentProduct.remainingQuantity;
                  }
                }
              );
            }
          );
        });
      });
      procurementSowPaymentTermItemList.forEach(paymentTermItem => {
        this.procurementSowList.controls.forEach(procurementSow => {
          procurementSow.value.procurementSowPaymentTermList.forEach(
            sowPayment => {
              sowPayment.procurementSowPaymentTermItemList.forEach(
                itemProduct => {
                  if (
                    paymentTermItem.procurementResult.id ===
                    itemProduct.procurementResult.id
                  ) {
                    itemProduct.remainingQuantity =
                      paymentTermItem.remainingQuantity;
                  }
                }
              );
            }
          );
        });
      });
    }
  }

  public doSave(): void {
    this.formGroup.markAllAsTouched();
    this.formGroup.markAsDirty();
    this.procurementSowPaymentTermList.markAsDirty();
    this.procurementSowPaymentTermList.markAllAsTouched();
    let amount = 0;
    this.procurementSowPaymentTermList.value.forEach(
      procurementSowPaymentTerm => {
        amount = amount + +procurementSowPaymentTerm.amount;
      }
    );
    this.formGroup.patchValue({
      amount,
      totalTermin: this.procurementSowPaymentTermList.value.length
    });

    if (
      this.formGroup.get('startDate').value &&
      this.formGroup.get('periodType').value === 2
    ) {
      this.doSetEndDate();
      this.formGroup
        .get('durationType')
        .setValue(this.formGroup.get('durationType').value.value);
    }

    let isRemainingQuantity = false;
    this.procurementSowPaymentTermList.controls.forEach(
      (paymentFormGroup: FormGroup) => {
        (
          paymentFormGroup.get(
            'procurementSowPaymentTermItemProductList'
          ) as FormArray
        ).controls.forEach(itemProduct => {
          if (
            !this.totalContractValue &&
            +itemProduct.value.remainingQuantity
          ) {
            isRemainingQuantity = true;
          }
        });
      }
    );
    this.procurementSowPaymentTermList.controls.forEach(
      (paymentFormGroup: FormGroup) => {
        (
          paymentFormGroup.get(
            'procurementSowPaymentTermItemServiceList'
          ) as FormArray
        ).controls.forEach(itemService => {
          if (
            !this.totalContractValue &&
            +itemService.value.remainingQuantity
          ) {
            isRemainingQuantity = true;
          }
        });
      }
    );
    if (
      (this.dealingAgreementType && this.dealingAgreementType.name) ===
      this.global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN
    ) {
      isRemainingQuantity = false;
    }
    this.validate();
    if (this.formGroup.valid && !isRemainingQuantity) {
      this.doSetprocurementSowPaymentItemList();
      this.doSetRemainingQuantity();
      if (this.router.url === '/pages/contract-monitoring/detail') {
        this.global.modalService
          .saveConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              const procurementSow: ProcurementSow = this.formGroup.value;
              procurementSow.id = this.procurementSowEdit.id;
              procurementSow.procurementScopeWork =
                this.procurementSowEdit.procurementScopeWork;
              this.httpClientService
                .post<Response<ProcurementSow>>(
                  '/contract-monitoring/update-procurement-sow',
                  procurementSow
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.onChange.emit(this.formGroup.value);
                    this.global.alertService.showSuccess(
                      this.global.translateService.instant(
                        'app.alert.msg.saveSuccess'
                      )
                    );
                  } else {
                    this.setStateReady();
                    this.global.alertService.showError(response.statusText);
                  }
                  this.setStateReady();
                });
            }
          });
      } else {
        this.onChange.emit(this.formGroup.value);
      }
    } else if (isRemainingQuantity) {
      this.global.alertService.showInfo(
        this.global.translateService.instant(
          'app-popup-procurement-scope-work.validation.remainingQuantity'
        ),
        '.main-row-popup-scope-work'
      );
    }
  }

  public setIsShowAssesment(): void {
    if (
      (this.router.url === '/pages/procurement-scope-work/edit' &&
        (this.contract
          ? this.contract.procurementVendor.procurement
              .procurementTransactionType.code
          : this.procurementVendor.procurement.procurementTransactionType
              .code) ===
          this.global.appConstant.cm.PROCUREMENT_TRANSACTION_TYPE_OFFLINE &&
        (this.contract
          ? this.contract.procurementVendor.amount
          : this.procurementVendor.amount) <= 25000000) ||
      (this.dealingAgreementType && this.dealingAgreementType.name) ===
        this.global.appConstant.cm.DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN
    ) {
      this.isShowAssesment = false;
    } else {
      this.isShowAssesment = true;
    }
  }
}
