import { BaseEntity } from '../base/base-entity';
import { PrItem } from '../bean/pr-item';
import { Vendor } from '../bean/vendor';

export class PrItemReleasedEntity extends BaseEntity {
  prItem: PrItem = new PrItem();
  vendor: Vendor = new Vendor();
  isPoReleased: boolean;
  totalPrice: number;
  price: number;
  quantity: number;
  source: string;
}
