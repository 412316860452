import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PipesModule } from '../../../../pipe/pipe.module';
import { VendorModule } from '../../../../vendor/vendor.module';
import { AppButtonModule } from '../../../app-button/app-button.module';
import { AppLazyLoadModule } from '../../../app-lazy-load/app-lazy-load.module';
import { AppPaginationXModule } from '../../../app-pagination-x/app-pagination-x.module';
import { TableTdDirective } from '../../directives/table-td.directive';
import { TableThDirective } from '../../directives/table-th.directive';
import { ResolveColumnClassNamePipe } from '../../pipe/resolve-column-class-name.pipe';
import { ResolveIsShoWCheckBoxPipe } from '../../pipe/resolve-is-show-check-box.pipe';
import { ResolveTableRecordsPipe } from '../../pipe/resolve-table-records-per-page.pipe';
import { ResolveTableRowIsCheckedPipe } from '../../pipe/resolve-table-row-is-checked.pipe';
import { AppTableXComponent } from './app-table-x.component';
@NgModule({
  imports: [
    VendorModule,
    AppPaginationXModule,
    AppButtonModule,
    PipesModule,
    AppLazyLoadModule,
    PerfectScrollbarModule
  ],
  declarations: [
    AppTableXComponent,
    ResolveTableRecordsPipe,
    TableTdDirective,
    TableThDirective,
    ResolveColumnClassNamePipe,
    ResolveIsShoWCheckBoxPipe,
    ResolveTableRowIsCheckedPipe
  ],
  providers: [ResolveColumnClassNamePipe],
  exports: [AppTableXComponent]
})
export class AppTableXModule {}
