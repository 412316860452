import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Holiday } from '../../core/bean/holiday';
import { Organization } from '../../core/bean/organization';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { HolidayResponse } from './holiday-response';
@Component({
  templateUrl: './holiday-edit-add.component.html'
})
export class HolidayEditAddComponent extends BaseModuleComponent {
  public holidayId: number;
  public statusOptionList: OptionListModel<any> = new OptionListModel();
  public holidayResponse: HolidayResponse;
  constructor(translateService: TranslateService) {
    super('holiday', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doSetStatusOptionList();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.holidayId = this.global.routerParams.get('holidayId');
  }

  public doSetStatusOptionList(): void {
    const statusList = [
      { value: true, name: 'Active' },
      { value: false, name: 'Inactive' }
    ];
    this.statusOptionList.setRequestValues(statusList);
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      organization: [null, Validators.required()],
      name: [null, Validators.required()],
      startDate: [null, Validators.required()],
      endDate: [null, Validators.required()],
      isYearly: false,
      isActive: [null, Validators.required()]
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<HolidayResponse>(
        '/holiday/add-edit',
        new RouteRequestModel(this.todo, this.holidayId)
      )
      .subscribe(holidayResponse => {
        if (holidayResponse.holiday != null) {
          this.holidayResponse = holidayResponse;
          const {
            id,
            name,
            isYearly,
            isActive: isActiveStatus
          } = this.holidayResponse.holiday;
          const isActive = this.statusOptionList
            .getRequestValues()
            .filter(status => status.value === isActiveStatus)[0];
          const startDate: Date = new Date(this.holidayResponse.holiday.startDate);
          const endDate: Date = new Date(this.holidayResponse.holiday.endDate);
          this.formGroup.patchValue({
            id,
            name,
            startDate,
            endDate,
            isYearly,
            isActive
          });
          this.formGroup
            .get('organization')
            .patchValue(this.holidayResponse.organizationList);
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const holiday = this.global.copyFormAttributeToModel(
              new Holiday(),
              this.formGroup
            );
            holiday.isActive = this.formGroup.get('isActive').value.value;
            const organizationList: Organization[] = this.formGroup.get(
              'organization'
            ).value;
            holiday.organization =
              organizationList[organizationList.length - 1];
            const url =
              this.todo === 'edit' ? '/holiday/update' : '/holiday/insert';
            this.httpClientService
              .post<Response<Holiday>>(url, holiday)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/holiday']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/holiday']);
  }
}
