import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';

@Component({
  selector: 'app-alert-x',
  templateUrl: './app-alert-x.component.html',
  styleUrls: ['./app-alert-x.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppAlertXComponent extends BaseComponentComponent {
  @Input() public message: string;
  @Input() public color: 'SUCCESS' | 'INFO' | 'DANGER' | 'WARNING';
  @Input() public icon:
    | 'fa-check'
    | 'fa-info-circle'
    | 'fa-exclamation-triangle';
  @Input() public isShowCloseButton: boolean;
  @Input() public isShowIcon: boolean;
  @Input() public isBold = true;
  constructor() {
    super('app-alert');
  }

  public readonly ALERT_TYPE: object = {
    SUCCESS: { icon: 'fa-check', color: 'alert-success' },
    INFO: { icon: 'fa-info-circle', color: 'alert-info' },
    DANGER: { icon: 'fa-exclamation-triangle', color: 'alert-danger' },
    WARNING: { icon: 'fa-exclamation-triangle', color: 'alert-warn' }
  };

  public onInit(): void {
    this.createAlert();
    this.setStateReady();
  }

  public createAlert(): void {
    this.isShowCloseButton =
      typeof this.isShowCloseButton === 'undefined'
        ? false
        : this.isShowCloseButton;
    this.isShowIcon =
      typeof this.isShowIcon === 'undefined' ? true : this.isShowIcon;
    this.icon = this.icon
      ? this.icon
      : this.color
      ? this.ALERT_TYPE[this.color].icon
      : this.ALERT_TYPE['INFO'].icon;
    this.color = this.color
      ? this.ALERT_TYPE[this.color].color
      : this.ALERT_TYPE['INFO'].color;
  }
}
