<div class="content-wrapper lpb-4 lmt-2" id="contentWrapper">
  <header class="content-header">
    <div
      class="page-navigation lmb-2"
      [ngClass]="[
        tabModel ? 'border-bottom-solid' : '',
        isFixedNavigation && (isEnableBackButton || backTo || isShowBackButton)
          ? 'fixed'
          : '',
        tabModel ? '' : 'py-2'
      ]"
      [@fixedNavigation]="
        isFixedNavigation && (isEnableBackButton || backTo || isShowBackButton)
          ? 'fixed'
          : 'default'
      "
    >
      <div
        class="back-button-wrapper cmr-2"
        *ngIf="isEnableBackButton || backTo || isShowBackButton"
      >
        <div class="back-button" (click)="doBack()">
          <em class="pir pi-arrow-left-back mr-2"></em>
          <span class="back-button-text fw-700">
            {{ 'app.button.back' | translate }}
          </span>
        </div>
      </div>
      <h2 class="page-title fw-700" *ngIf="!tabModel"
        >{{ header || layoutTitle | translate }}
      </h2>
      <ng-container *ngTemplateOutlet="additionalTitleTemplate"></ng-container>
      <div class="custom-header" *ngIf="tabModel">
        <app-tab [model]="tabModel" (onChangeTab)="doNext($event)"></app-tab>
      </div>
      <ng-container *ngTemplateOutlet="contentHeaderTemplate"></ng-container>
      <div class="page-header-section lpx-1"></div>
    </div>
  </header>
  <div class="content-body">
    <ng-container *ngIf="isLoading">
      <div class="promise-loading">
        <div class="loading"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="!isLoading">
      <aside
        class="sidebar sidebar-content-body lpr-3"
        *ngIf="contentSidebarLeftTemplate"
      >
        <div class="sidebar-content sticky" #sidebarContentLeft>
          <ng-container
            *ngTemplateOutlet="contentSidebarLeftTemplate"
          ></ng-container>
        </div>
      </aside>

      <div class="content">
        <ng-content></ng-content>
      </div>

      <ng-container *ngIf="!isHideContentSidebarRight">
        <aside
          class="sidebar sidebar-content-body lpl-3"
          *ngIf="contentSidebarRightTemplate"
        >
          <div class="sidebar-content sticky" #sidebarContentRight>
            <perfect-scrollbar>
              <ng-container
                *ngTemplateOutlet="contentSidebarRightTemplate"
              ></ng-container>
            </perfect-scrollbar>
          </div>
        </aside>
      </ng-container>
    </ng-container>
  </div>
</div>
