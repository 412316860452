import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { CatalogImage } from '../../../core/bean/catalog-image';
import { File } from '../../../core/bean/file';
import { AppTableComponent } from '../../../core/components/app-table/app-table.component';
import { FileUploader } from '../../../core/components/upload';
import { TabModel } from '../../../core/model/tab/tab-model';
import { Validators } from '../../../core/validators';
import { ManageCatalogDTO } from '../dto/manage-catalog.dto';
@Component({
  templateUrl: './image.component.html'
})
export class ImageComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public manageCatalogDTO: ManageCatalogDTO = new ManageCatalogDTO();
  public imageTab: TabModel;
  public isTodoView;
  public catalogImageList: CatalogImage[] = [];
  public fileList: File[] = [];
  public isVendor: boolean;
  public isOther: boolean;
  public fileUploader: FileUploader = new FileUploader('/manage-catalog/', 'manage-catalog.form.header', this.global.appConstant.fileType.IMG_ITEM_CATALOG, false, 5);
  constructor(translateService: TranslateService) {
    super('manage-catalog', translateService);
  }

  onInit(): void {
    this.isVendor = this.global.userSession.vendor? true: false;
    this.isOther = this.isVendor? false : this.global.userSession.activeUserRole.role.type 
    === this.global.appConstant.core.ROLE_TYPE_OTHER? true: false;
    this.setCatalog();
    this.buildFormGroup();
    this.isOther ? this.imageTab.setIsView(true)
    :this.imageTab.setIsView(this.isTodoView);
    this.setStateReady();
  }

  public setCatalog(): void {
    this.imageTab = this.global.routerParams.get('tabResponse').tabs.get('IMAGE');
    this.isTodoView = this.isOther ? true
    :this.global.routerParams.get('tabResponse').isView;
    this.manageCatalogDTO = this.global.routerParams.get('manageCatalogDTO');
  }

  public buildFormGroup(): void {
    if (!this.imageTab.isFormGroupReady) {
      this.imageTab.formGroup = this.formBuilder.group({
        file: [null, Validators.required()]
      });
      this.manageCatalogDTO.catalogImageList.forEach(catalogImage => {
        this.fileList.push(catalogImage.file);
      });
      this.fileUploader.setFileList(this.fileList);
      this.imageTab.formGroup.patchValue({ file: this.fileUploader.fileObjectList });
    } else {
      this.fileUploader.fileObjectList = this.imageTab.formGroup.value.file;
    }
    this.manageCatalogDTO.fileUploadImage = this.fileUploader.fileObjectList;
  }
}
