import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppChooseModule } from '../app-choose/app-choose.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppTextTreeComponent } from './app-text-tree.component';

@NgModule({
  imports: [ VendorModule, AppChooseModule, AppTooltipWrapperModule ],
  declarations: [ AppTextTreeComponent ],
  exports: [ AppTextTreeComponent ]
})

export class AppTextTreeModule { }
