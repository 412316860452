<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="notification-log.title" [isLoading]="formLoading">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <label class="control-label">{{ 'notification-log.date.login' |
                  translate }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 text-left">
                <div class="form-group">
                  <label class="mr-3" for="from">{{ 'notification-log.date.from'
                    | translate }}</label>
                  <div class="col-md-12 p-0">
                    <app-date-picker size="XL" formControlName="logDateFrom"
                      maxDate="logDateTo"></app-date-picker>
                  </div>
                </div>
              </div>
              <div class="col-md-6 text-left">
                <div class="form-group">
                  <label class="mr-3" for="to">{{ 'notification-log.date.to' |
                    translate }}</label>
                  <div class="col-md-12 p-0">
                    <app-date-picker size="XL" formControlName="logDateTo"
                      minDate="logDateFrom"></app-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="button-group button-group-right button-group-icon-text mt-5">
          <app-button mode="BYPASS" (onClick)="doReset()" title="Reset"><em
              class="fas fa-sync-alt"></em>{{ 'app.button.reset' | translate }}
          </app-button>
          <app-button mode="BYPASS" (onClick)="doApply()" title="Apply"><em
              class="fas fa-check"></em>{{ 'app.button.apply' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
    <div class="row main-row">
      <div class="col-lg-12 mb-4">
        <app-card header="notification-log.title" [isLoading]="formLoading"
          [isSaving]="formSaving">
          <app-table [model]="tableResponse" [isServerSide]="true"
            stringUrl="/notification-job/index">
            <ng-template #headerFilterTable>
              <div class="app-table-filter-item" [formGroup]="formGroup">
                <label for="organizationName">{{
                  'notification-log.popup.filter.organization' | translate
                  }}</label>
                <app-choose size="XL" formControlName="organizationName"
                  (onClick)="doOpenPopupTree($event)"></app-choose>
              </div>
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <app-button mode="BYPASS" size="TN" (onClick)="doDetail(rowData)"
                title="{{ 'app.tooltip.detail' | translate }}">
                <em class="fas fa-search"></em>
              </app-button>
            </ng-template>
          </app-table>
        </app-card>
      </div>
    </div>
  </div>
</div>