import { Organization } from 'src/app/core/bean/organization';
import { Pr } from 'src/app/core/bean/pr';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PrShipping } from 'src/app/core/bean/pr-shipping';
import { PrVendorReference } from 'src/app/core/bean/pr-vendor-reference';
import { User } from 'src/app/core/bean/user';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { AppUploadDocumentTableModel } from 'src/app/core/components/app-upload-document-table/app-upload-document-table-model';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';

export class PrRequest {
  prItemList: PrItem[] = [];
  pr: Pr = new Pr();
  vendorViewList: PopupVendorView[] = [];
  prShippingList: PrShipping[] = [];
  appUploadDocumentTableModelList: AppUploadDocumentTableModel[] = [];
  cartIdList: number[] = [];
  isSubmit: boolean;
  prVendorReferenceList: PrVendorReference[] = [];
  appOfficialReportModel: AppOfficialReportModel = new AppOfficialReportModel();
  user: User = new User ();
  messageRequestItemToDistributor: string;
  organization: Organization = new Organization();
}
