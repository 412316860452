import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Organization } from '../../bean/organization';
import { OrganizationPlanInfoModel } from './model/organization-plan-info-model';

@Component({
  selector: 'app-organization-rk-info-item-card',
  templateUrl: './app-organization-rk-info-item-card.component.html',
  styleUrls: ['./app-organization-rk-info-item-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppOrganizationRkInfoItemCardComponent extends BaseComponentComponent {
  @Input() organizationPlanInfoModel: OrganizationPlanInfoModel;
  @Output() onClick: EventEmitter<Organization> = new EventEmitter();
  constructor() {
    super('app-organization-rk-info-item-card');
  }

  onInit(): void {
    this.setStateReady();
  }

  onKeyUp(event: KeyboardEvent): void {
    this.log.debug(event);
  }

  onKeyDown(event: KeyboardEvent): void {
    this.log.debug(event);
  }
}
