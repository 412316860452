import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupContractComponent } from './app-popup-contract.component';
import { AppPopupContractService } from './app-popup-contract.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppTableModule],
  declarations: [AppPopupContractComponent],
  entryComponents: [AppPopupContractComponent],
  providers: [AppPopupContractService],
  exports: [AppPopupContractComponent]
})
export class AppPopupContractModule {}
