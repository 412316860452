<app-table-xx
  [model]="tableResponse"
  (onClick)="doClick($event)"
  header="{{ 'app-guarantee.table.header' | translate }}"
>
  <ng-template #headerButtons>
    <app-button
      *ngIf="!isView"
      [disabled]="formSaving || isDisabled"
      (onClick)="doAdd()"
      ><em class="pir pi-plus-circle"></em>
      {{ 'app-guarantee.button.addGuarantee' | translate }}</app-button
    >
  </ng-template>
  <ng-container *ngIf="!isView">
    <ng-template #actionButtons let-rowData>
      <div class="ml-2">
        <app-button
          size="TN"
          [disabled]="formSaving"
          (onClick)="doDelete(rowData)"
          title="{{ 'app.tooltip.delete' | translate }}"
        >
          <em class="pir pi-trash-alt"></em>
          {{ 'app.tooltip.delete' | translate }}
        </app-button>
      </div>
    </ng-template>
  </ng-container>
</app-table-xx>
