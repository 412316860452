import { NgModule } from '@angular/core';
import { AppButtonModule } from 'src/app/core/components/app-button/app-button.module';
import { AppPopupModule } from 'src/app/core/components/app-popup/app-popup.module';
import { AppSelectModule } from 'src/app/core/components/app-select/app-select.module';
import { TableModule } from 'src/app/core/components/table/table.module';
import { PipesModule } from 'src/app/core/pipe/pipe.module';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { AppPopupWidgetTotalPaymentBillsComponent } from './app-popup-widget-total-payment-bills.component';
import { WidgetTotalPaymentBillsComponent } from './widget-total-payment-bills.component';

@NgModule({
  imports: [
    VendorModule,
    WidgetCardModule,
    TableModule,
    PipesModule,
    AppSelectModule,
    AppPopupModule,
    AppButtonModule
  ],
  declarations: [
    WidgetTotalPaymentBillsComponent,
    AppPopupWidgetTotalPaymentBillsComponent
  ],
  entryComponents: [AppPopupWidgetTotalPaymentBillsComponent],
  exports: [AppPopupWidgetTotalPaymentBillsComponent]
})
export class WidgetTotalPaymentBillsModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetTotalPaymentBillsComponent.moduleCode,
      WidgetTotalPaymentBillsComponent,
      'w-100'
    );
  }
}
