import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppQrCodeComponent } from './app-qr-code.component';

@NgModule({
  imports: [VendorModule, PipesModule, DirectivesModule],
  declarations: [AppQrCodeComponent],
  exports: [AppQrCodeComponent]
})
export class AppQrCodeModule {}
