<div class="announcement-item mb-2">
  <div class="announcement-item-status">
    <h4>{{ announcement.status }}</h4>
  </div>
  <div class="announcement-item-content">
    <h4 class="announcement-title">
      <a href="#">{{ announcement.title }}</a>
    </h4>
    <p class="announcement-detail">
      {{ announcement.detail }}
    </p>
  </div>
</div>
