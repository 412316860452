import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTableModule } from '../app-table/app-table.module';
import { AppContractReviewLogComponent } from './app-contract-review-log.component';

@NgModule({
  imports: [VendorModule, AppTableModule],
  declarations: [AppContractReviewLogComponent],
  exports: [AppContractReviewLogComponent]
})

export class AppContractReviewLogModule {}
