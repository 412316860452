<app-popup
  header="initiation.directPurchase.selectVendor.title"
  [isShowHeaderCloseButton]="false"
  [isLoading]="formLoading"
>
  <div class="main-row-popup-choose-vendor">
    <app-table-xx
      [model]="tableResponse"
      [isShowCheckBox]="true"
      [isMultipleSelect]="false"
      stringUrl="/initiation/index-vendor"
    >
      <ng-template #headerButtons>
        <app-button
          color="PRIMARY"
          [outline]="true"
          (onClick)="doAddNewVendor()"
        >
          <em class="pir pi-plus-circle"></em>
          {{
            'initiation.directPurchase.popupSelectVendor.button.newVendor'
              | translate
          }}
        </app-button>
      </ng-template>
      <ng-template #headerFilterGroup>
        <form
          class="form-horizontal"
          [formGroup]="formGroup"
          novalidate
          *ngIf="!formLoading"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'initiation.directPurchase.form.vendorPurchaseType' | translate
              }}
            </label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                formControlName="vendorPurchaseType"
                [optionList]="vendorPurchaseTypeOptionList"
                (onChange)="onChangeVendorPurchase($event)"
              ></app-dropdown-select>
            </div>
          </div>
        </form>
      </ng-template>
    </app-table-xx>
    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          class="mr-1"
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button (onClick)="doChoose()" [disabled]="formLoading">{{
          'initiation.directPurchase.button.select' | translate
        }}</app-button>
      </div>
    </ng-template>
  </div>
</app-popup>
