import { Currency } from 'src/app/core/bean/currency';
import { PrItem } from 'src/app/core/bean/pr-item';
import { InitiationRequest } from 'src/app/pages/initiation/request/initiation.request';
import { CatalogType } from '../../bean/catalog-type';

export class AppItemRequestInitiationCatalogModel {
  currencyList: Currency[] = [];
  prItemList: PrItem[] = [];
  companyCurrency: Currency;
  initiationRequest: InitiationRequest;
  catalogType: CatalogType;
}
