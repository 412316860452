import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppCheckBoxModule } from '../../app-check-box/app-check-box.module';
import { AppSignatureModule } from '../../app-signature/app-signature.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupSignatureComponent } from './app-popup-signature.component';
import { AppPopupSignatureService } from './app-popup-signature.service';

@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppButtonModule,
    AppCheckBoxModule,
    AppSignatureModule
  ],
  declarations: [AppPopupSignatureComponent],
  entryComponents: [AppPopupSignatureComponent],
  providers: [AppPopupSignatureService],
  exports: [AppPopupSignatureComponent]
})
export class AppPopupSignatureModule {}
