import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../../../directives/directives.module';
import { AppButtonModule } from '../../../app-button/app-button.module';
import { UploadSharedModule } from '../../shared/upload-shared.module';
import { AppUploadComponent } from './app-upload.component';
@NgModule({
  imports: [UploadSharedModule, AppButtonModule, DirectivesModule],
  declarations: [AppUploadComponent],
  exports: [AppUploadComponent]
})
export class AppUploadModule {}
