import { Pipe, PipeTransform } from '@angular/core';
import { UploaderHelpers } from '../helpers/upload-helpers';
import { UploaderFile } from '../model/uploader-file';
import { UploadService } from '../upload.service';
@Pipe({ name: 'resolveUploadHref' })
export class ResolveUploadHref implements PipeTransform {
  constructor(private uploadService: UploadService) {}
  transform(uploaderFile: UploaderFile): string {
    return UploaderHelpers.getFileUrlWithFileUploader(
      this.uploadService.global,
      uploaderFile
    );
  }
}
