import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { TableModule } from '../../../../../core/components/table/table.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetProcurementMonitoringOverdueComponent } from './widget-procurement-monitoring-overdue.component';
@NgModule({
  imports: [VendorModule, WidgetCardModule, TableModule],
  declarations: [WidgetProcurementMonitoringOverdueComponent],
  exports: [WidgetProcurementMonitoringOverdueComponent]
})
export class WidgetProcurementMonitoringOverdueModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetProcurementMonitoringOverdueComponent.moduleCode,
      WidgetProcurementMonitoringOverdueComponent,
      'w-100'
    );
  }
}
