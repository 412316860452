import { ConverterService } from '../../../services/converter.service';
const convert = new ConverterService();
export const customChartPlugin = {
  id: 'custom-chart-plugin',
  afterDatasetsDraw: (chart: Chart & { legend }) => {
    const ctx = chart.ctx;
    const datasets = chart.data.datasets;
    const type = chart.config.type;
    datasets.forEach((dataset: Chart.ChartDataSets, indexI: number) => {
      const meta = chart.getDatasetMeta(indexI);
      const barLabelData = meta.controller._data;
      const color = dataset.backgroundColor[0];
      if (!meta.hidden) {
        meta.data.forEach((segment, indexJ) => {
          const model = segment._model;
          const position = segment._view;
          const x = position.x;
          const y = position.y;
          let text = '';
          ctx.restore();
          ctx.textAlign = 'center';
          if (type === 'bar') {
            ctx.textBaseline = 'ideographic';
            ctx.font = 'bold ' + 13 + 'px Arial';
            ctx.fillStyle = model.backgroundColor;
            text = convert.convertToShortMoney(barLabelData[indexJ]).toString();
          }

          if (type === 'doughnut') {
            ctx.textBaseline = 'middle';
            ctx.font = 12 + 'px sans-serif';
            ctx.fillStyle = color;
            text = barLabelData[0] + '%';
          }
          ctx.fillText(text, x, y);
          ctx.save();
        });
      }
    });
  }
};

export const chartPaddingBelowLegends = {
  id: 'paddingBelowLegends',
  beforeInit: function (chart) {
    chart.legend.afterFit = function () {
      this.height = this.height + 20;
    };
  }
};
