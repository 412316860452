import { createUploaderFileFromFileBean } from '../actions/create-uploader-file-from-file-bean';
import { FileObject } from '../model/file-object';
import { Uploader } from '../model/uploader';
import { UploaderFile } from '../model/uploader-file';
import { FileUploader } from '../model/v1/file.uploader';
export class UploadMapper {
  public static toModel(
    uploader: Uploader,
    values: FileObject[] | FileObject
  ): UploaderFile[] {
    const uploaderFileList: UploaderFile[] = [];
    let fileObjectList: FileObject[] = [];
    if (Array.isArray(values)) {
      if (values.length > 0) {
        if (values[0].file) {
          fileObjectList = values;
        } else {
          if (
            values &&
            ((Array.isArray(values) && values.length > 0) ||
              (typeof values === 'object' && Object.keys(values).length > 0))
          ) {
            fileObjectList = [new FileObject(values as any, false)];
          }
        }
      }
    } else {
      if (values && values instanceof FileUploader) {
        fileObjectList = values.fileObjectList;
      } else if (values && values instanceof FileObject) {
        fileObjectList = [values];
      }
    }
    fileObjectList.forEach(fileObject => {
      const uploaderFile = createUploaderFileFromFileBean(
        uploader,
        fileObject.file
      );
      uploader.totalUploaded++;
      uploaderFileList.push(uploaderFile);
    });
    return uploaderFileList;
  }

  public static toValues(model: Uploader): FileObject[] {
    const uploaderFileList = [...model.uploaderFileList, ...model.trash];
    return uploaderFileList.map(uploaderFile => {
      const isChange = !!(
        uploaderFile.uploadFileChange &&
        uploaderFile.uploadFileChange.uploaderFile
      );
      const file = isChange
        ? Object.assign(
            uploaderFile.uploadFileChange.uploaderFile.file,
            uploaderFile.file
          )
        : uploaderFile.file;
      const fileObject: FileObject = new FileObject(file, true);
      fileObject.file.fileType = model.options.fileType;
      fileObject.isChange = isChange;
      fileObject.isDeleted = uploaderFile.status === 'DELETED';
      fileObject.isNew = isChange ? false : uploaderFile.isNew;
      fileObject.isUploaded =
        uploaderFile.status === 'UPLOADED' || !!uploaderFile.file.id;
      return fileObject;
    });
  }

  public static toFileUploader(
    fileUploader: FileUploader,
    values: FileObject[]
  ): FileUploader {
    const totalFileNotDeleted = values.filter(
      fileObject => !fileObject.isDeleted
    ).length;
    fileUploader.fileObjectList = values;
    fileUploader.isAllUploaded = totalFileNotDeleted === fileUploader.maxFile;
    fileUploader.totalUploaded = values.length - totalFileNotDeleted;
    return fileUploader;
  }
}
