import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartModel } from 'src/app/core/components/app-chart/models/chart-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetTotalContractRequest } from './widget-total-contract.request';
import { WidgetTotalContractResponse } from './widget-total-contract.response';

@Component({
  templateUrl: './widget-total-contract.component.html'
})
export class WidgetTotalContractComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-total-contract';
  public chartModel: ChartModel;
  public yearOptionList: OptionListModel<number> = new OptionListModel(true);
  public filterOptionList: OptionListModel<any> = new OptionListModel(true);
  public widgetTotalContractRequest: WidgetTotalContractRequest =
    new WidgetTotalContractRequest();
  public widgetTotalContractResponse: WidgetTotalContractResponse =
    new WidgetTotalContractResponse();
  public modelList: any[] = [];

  public FILTER_BY_CONTRACT_TYPE = 1;
  public FILTER_BY_CONTRACT_STATUS = 2;
  public colorList = [
    '--primary-dark',
    '--primary',
    '--good',
    '--warning',
    '--warning-light',
    '--feedback'
  ];
  public legendList: Array<any>;

  constructor(public translateService: TranslateService) {
    super(WidgetTotalContractComponent.moduleCode);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFilterList();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      year: [null],
      filterBy: [{ value: this.FILTER_BY_CONTRACT_TYPE, name: 'Type' }]
    });
  }

  public setFilterList(): void {
    const filterList = [
      { value: this.FILTER_BY_CONTRACT_TYPE, name: 'Type' },
      { value: this.FILTER_BY_CONTRACT_STATUS, name: 'Status' }
    ];
    this.filterOptionList.setRequestValues(filterList);
  }

  public setFormGroup(): void {
    this.widgetTotalContractRequest.year = this.formGroup.value.year;
    this.widgetTotalContractRequest.filterBy =
      this.formGroup.value.filterBy?.value;

    this.httpClientService
      .post<WidgetTotalContractResponse>(
        '/widget-total-contract/index',
        this.widgetTotalContractRequest
      )
      .subscribe((response: WidgetTotalContractResponse) => {
        if (response) {
          this.widgetTotalContractResponse = response;
          this.modelList = response.widgetTotalContractModelList;

          this.yearOptionList.setRequestValues(response.yearList);
          console.log(this.yearOptionList);
          this.setChartModel();
        }
      });
  }

  public setChartModel(): void {
    const colorList = this.colorList.map(color =>
      getComputedStyle(document.body).getPropertyValue(color)
    );

    this.legendList =
      this.widgetTotalContractResponse.widgetTotalContractModelList.map(
        (data, index) => {
          return {
            percentage: data.totalAll
              ? `${this.global.converterService.convertDecimal(
                  (data.total / data.totalAll) * 100
                )}%`
              : '0%',
            total: data.totalAll
              ? Math.round((data.total / data.totalAll) * 100)
              : 0,
            fraction: data.fraction,
            label: data.name,
            color: colorList[index]
          };
        }
      );
    const label = this.legendList.map(val => val.label);
    const dataSets = this.legendList.map(val => val.total);
    this.chartModel = new ChartModel('doughnut', label, dataSets, null, {
      defaultColor: colorList,
      legend: {
        display: false
      },
      elements: {
        arc: {
          borderWidth: 0
        }
      },
      tooltips: {
        bodyFontStyle: 'font-weight: 700',
        bodySpacing: 10,
        footerFontStyle: 'font-weight: 400',
        callbacks: {
          labelTextColor: () => 'transparent',
          footer: (
            item: Chart.ChartTooltipItem[],
            data: Chart.ChartData
          ): string => {
            const datasetIndex = item[0].index;
            return `${this.legendList[item[0].index].percentage}% ${
              data.labels[datasetIndex]
            }`;
          },
          afterFooter: (item: Chart.ChartTooltipItem[]): string => {
            return this.legendList[item[0].index].fraction + ' Contract Done';
          }
        }
      },
      plugins: {
        id: 'custom-chart-plugin'
      }
    });

    this.setStateReady();
  }

  public onChangeFormGroup(): void {
    if (this.formGroup.value.filterBy?.value) {
      this.setStateLoading();
      this.setFormGroup();
    }
  }
}
