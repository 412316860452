import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import {
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import {
  expandCollapseAnimation,
  rotateAnimation
} from '../../../../core/animation';
import { BaseComponentComponent } from '../../../../core/base/angular/base-component.component';
import { PromiseThemeService } from '../../promise-theme.service';
import { MenuService } from '../../services/menu.service';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@Component({
  selector: 'theme-sidebar[position=left]',
  templateUrl: './sidebar-left.component.html',
  styleUrls: ['./sidebar-left.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [expandCollapseAnimation(), rotateAnimation()],
  providers: [
    MenuService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class SidebarLeftComponent extends BaseComponentComponent {
  public menuItems: Array<any>;
  constructor(
    public menuService: MenuService,
    private promiseThemeService: PromiseThemeService
  ) {
    super('sidebar-left');
  }

  onInit(): void {
    this.menuItems = this.menuService.getMenu();
    this.addDashboardToLastActiveRouterList();
  }

  private addDashboardToLastActiveRouterList(): void {
    this.global.lastActiveRouterList.push(
      this.menuService.activeMenu?.routerLink || '/pages/dashboard'
    );
  }

  public handleExpandOrCollapseMenu(): void {
    this.promiseThemeService.changeSidebarWidth();
    const sidebarWidth = this.promiseThemeService
      .getDataTheme()
      .split(' ')
      .includes('sidebar-full-width')
      ? 'FULL'
      : 'SMALL';
    localStorage.setItem('sidebar-width', sidebarWidth);
  }

  public doClickMenuItem(event: PointerEvent, menu: any): void {
    event.preventDefault();
    event.stopPropagation();
    if (menu.routerLink) {
      this.menuService.setActiveMenuToUnactive(this.menuItems);
      this.global.isModuleChange = true;
      this.global.activeModuleCode = (menu as any).moduleCode;
      this.doLoadMenu(menu);
      this.menuService.setExpandedMenu(
        menu.parentId === 0 ? this.menuItems : menu.parent.childrens
      );
      this.menuService.setActiveMenu(menu);
    } else {
      let isExpanded = menu.isExpanded;
      this.menuService.setExpandedMenu(
        menu.parentId === 0 ? this.menuItems : menu.parent.childrens
      );
      menu.isExpanded = !isExpanded;
    }
  }

  public doLoadMenu(menu: any): void {
    if (this.global.config.parameterModel.isRecordMode) {
      this.httpClientService
        .post('/dashboard/menu-info', menu.code)
        .subscribe(() => {});
    }
    this.global.lastActiveRouterList.push(menu.routerLink);
    this.router.navigate([menu.routerLink]);
  }

  public onMouseEnterOnMenu(
    event: MouseEvent & { target: HTMLElement },
    menuTooltip: HTMLElement
  ): void {
    if (this.isSmallSidebar) {
      event.target.classList.add('show');
      if (menuTooltip) {
        menuTooltip.style.top =
          event.target.getBoundingClientRect().top + 4 + 'px';
      }
    } else {
      const spanMenuText = event.target.children.item(1) as HTMLElement;
      if (spanMenuText.offsetHeight < spanMenuText.scrollHeight) {
        event.target.classList.add('show');
        if (menuTooltip) {
          menuTooltip.style.top =
            event.target.getBoundingClientRect().top + 4 + 'px';
          menuTooltip.style.display = 'block';
        }
      }
    }
  }

  onMouseLeaveFromMenu(
    event: MouseEvent & { target: HTMLElement },
    menuTooltip: HTMLElement
  ): void {
    if (this.isSmallSidebar) {
      event.target.classList.remove('show');
    } else {
      const spanMenuText = event.target.children.item(1) as HTMLElement;
      if (spanMenuText.offsetHeight < spanMenuText.scrollHeight) {
        event.target.classList.remove('show');
      }
    }
    if (menuTooltip) {
      menuTooltip.removeAttribute('style');
    }
  }

  @HostListener('mousewheel', ['$event'])
  onMouseEWheel(event: WheelEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  private get isSmallSidebar(): boolean {
    return document.documentElement
      .getAttribute('data-theme')
      .includes('sidebar-small-width');
  }
}
