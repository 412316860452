import { NgModule } from '@angular/core';
import { AppUploadImagesModule } from './components/app-upload-images/app-upload-images.module';
import { AppUploadMultipleModule } from './components/app-upload-multiple/app-upload-multiple.module';
import { AppUploadXModule } from './components/app-upload-x/app-upload-x.module';
import { AppUploadModule } from './components/app-upload/app-upload.module';
import { UploadSharedModule } from './shared/upload-shared.module';
@NgModule({
  imports: [
    AppUploadModule,
    AppUploadMultipleModule,
    AppUploadImagesModule,
    UploadSharedModule,
    AppUploadXModule
  ],
  exports: [
    AppUploadModule,
    AppUploadMultipleModule,
    AppUploadImagesModule,
    UploadSharedModule,
    AppUploadXModule
  ]
})
export class UploadModule {}
