import { NgModule } from '@angular/core';
import {
  HammerGestureConfig,
  HammerModule,
  HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DirectivesModule } from '../../../../directives/directives.module';
import { AppAlertXModule } from '../../../app-alert-x/app-alert-x.module';
import { AppButtonModule } from '../../../app-button/app-button.module';
import { AppPopupModule } from '../../../app-popup/app-popup.module';
import { UploadSharedModule } from '../../shared/upload-shared.module';
import { AppPopupUploadImagesComponent } from './app-popup-upload-images.component';
import { AppUploadImagesComponent } from './app-upload-images.component';
@NgModule({
  imports: [
    UploadSharedModule,
    DirectivesModule,
    ImageCropperModule,
    AppPopupModule,
    AppButtonModule,
    HammerModule,
    AppAlertXModule
  ],
  declarations: [AppUploadImagesComponent, AppPopupUploadImagesComponent],
  exports: [AppUploadImagesComponent],
  entryComponents: [AppPopupUploadImagesComponent],
  providers: [{ provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig }]
})
export class AppUploadImagesModule {}
