import { BaseDTO } from '../../../core/base/base-dto';
import { BuildingLocation } from '../../../core/bean/building-location';
import { BuildingType } from '../../../core/bean/building-type';
import { Region } from '../../../core/bean/region';
import { ResourceOwnership } from '../../../core/bean/resource-ownership';

export class VendorPopupVendorAddressDTO extends BaseDTO {
    countryRegionList: Region[];
    provinceRegionList: Region[];
    cityRegionList: Region[];
    districtRegionList: Region[];
    buildingTypeList: BuildingType[];
    buildingLocationList: BuildingLocation[];
    regionPhoneList: Region[];
    resourceOwnershipList: ResourceOwnership[];
}
