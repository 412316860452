<app-popup (onClose)="doCancel()" [isShowHeaderCloseButton]="!!isUser || !isLoadingSync" header="billing-memo.popup.sync.title">
  <div class="row main-row-popup">
    <div class="col-12 mb-4">

<div class="row" *ngIf="isLoadingSync">
    <div class="col-sm-12 text-center">{{
        'billing-memo.sync.pleaseWait' | translate
      }}
    </div>
</div>
<div class="row" *ngIf="isLoadingSync">
  <div class="col-sm-12 text-center">{{
      'billing-memo.sync.gettingData' | translate
    }}
  </div>
</div>
<div class="row" *ngIf="isLoadingSync && gettingDataMessage">
    <div class="col-sm-12 text-center">
        {{ gettingDataMessage }}
    </div>
</div>

<div class="row" *ngIf="isLoadingSync && isSyncButtonClicked && !gettingDataMessage && !syncProgressMessage">
  <div class="col-sm-12 text-center">
    <img src="assets/img/common/loader-small.gif" alt="Loading" height="8" width="30" />
  </div>
</div>

<div class="w-50 mx-auto">
  <ngb-progressbar
  type="success"
  textType="white"
  [value]="syncProgressMessage"
  [showValue]="true"
  *ngIf="isLoadingSync && syncProgressMessage"
  >
  </ngb-progressbar>
</div>

  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center">
      <app-button mode="BYPASS" color="SECONDARY" (onClick)="doCancel()" *ngIf="isLoadingSync && isUser">{{
        'app.button.cancel' | translate
      }}</app-button>
    </div>
  </ng-template>
    </div>
</div>
</app-popup>
