import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Aanwijzing } from 'src/app/core/bean/aanwijzing';
import { AanwijzingCancellation } from 'src/app/core/bean/aanwijzing-cancellation';
import { AanwijzingParticipant } from 'src/app/core/bean/aanwijzing-participant';
import { AanwijzingSummary } from 'src/app/core/bean/aanwijzing-summary';
import { File } from 'src/app/core/bean/file';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { FileObject, FileUploader } from 'src/app/core/components/upload';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { ReportService } from 'src/app/core/services/report.service';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { Validators } from 'src/app/core/validators';
import { ChatWebsocketService } from 'src/app/pages/aanwijzing/chat-websocket.service';
import { AppChatComponent } from '../../core/components/app-chat/app-chat.component';
import { AppOfficialReportRequest } from '../../core/components/app-official-report/app-official-report.request';
import { ToastService } from '../../core/components/app-toast/app-toast.service';
import { EncryptService } from '../../core/services/encrypt.service';
import { AanwijzingPopupCancelComponent } from './aanwijzing-popup-cancel.component';
import { AanwijzingPopupInviteParticipantComponent } from './aanwijzing-popup-invite-participant.component';
import { AanwijzingPopupJoinOfflineComponent } from './aanwijzing-popup-join-offline.component';
import { AanwijzingPopupShowQRComponent } from './aanwijzing-popup-show-qr.component';
import { ChatMenuWebsocketService } from './chat-menu-websocket.service';
import { ChatParticipantWebsocketService } from './chat-participant-websocket.service';
import { AanwijzingRemindRequest } from './request/aanwijzing-remind.request';
import { AanwijzingSessionRequest } from './request/aanwijzing-session.request';
import { AanwijzingSessionResponse } from './response/aanwijzing-session.response';
import { AanwijzingUpdateSessionResponse } from './response/aanwijzing-update-session.response';
import { SessionRoomWebsocketService } from './session-room-websocket.service';

@Component({
  templateUrl: './aanwijzing-session.component.html'
})
export class AanwijzingSessionComponent
  extends BaseModuleComponent
  implements OnInit, OnDestroy
{
  public aanwijzing: Aanwijzing;
  public aanwijzingId: number;
  public response: AanwijzingSessionResponse;
  public fileUploader: FileUploader = new FileUploader(
    '/aanwijzing/',
    'aanwijzing.form.headerImage',
    this.global.appConstant.fileType.DOC_SUMMARY,
    false,
    1
  );
  public isTypeOnline = false;
  public tableResponsePartInternal: TableResponseModel<AanwijzingParticipant>;
  public tableResponsePartVendor: TableResponseModel<AanwijzingParticipant>;
  public tableResponsePartOthers: TableResponseModel<AanwijzingParticipant>;
  public isCanceled: boolean;
  public canCancelStatusList = [
    this.global.appConstant.pm.AANWIJZING_STATUS_DRAFT,
    this.global.appConstant.pm.AANWIJZING_STATUS_NOT_STARTED
  ];
  public isVendor = false;
  public isInternal = false;
  public aanwijzingCancellation: AanwijzingCancellation;
  public aanwijzingParticipantId: number; /** join session from email */
  public isJoinOffline: boolean;
  public isInvite: boolean;
  public isTolerated: boolean;
  public isTemplateDownloaded: boolean;
  @ViewChild('appChat') appChat: AppChatComponent;
  public remindedParticipantIdList: number[] = [];
  public isAllReminded: boolean;
  public participantSubscription: Subscription;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public snackBarService: SnackbarService,
    public chatWebsocketService: ChatWebsocketService,
    public chatParticipantWebsocketService: ChatParticipantWebsocketService,
    public sessionRoomWebsocketService: SessionRoomWebsocketService,
    public chatMenuWebsocketService: ChatMenuWebsocketService,
    public reportService: ReportService,
    public toastService: ToastService,
    public encryptService: EncryptService
  ) {
    super('aanwijzing', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParam();
    this.buildAllTableParticipants();
    this.buildFormGroup();
    this.setFormGroup();

    this.chatWebsocketService.connect();
    this.chatParticipantWebsocketService.connect();
    this.chatMenuWebsocketService.connect();
    this.sessionRoomWebsocketService.connect();
    this.sessionRoomWebsocketService.session.subscribe(
      (aanwijzing: Aanwijzing) => {
        this.aanwijzing.sessionStartTime = aanwijzing.sessionStartTime;
        this.aanwijzing.sessionEndTime = aanwijzing.sessionEndTime;
        this.aanwijzing.sessionTime = aanwijzing.sessionTime;
        this.aanwijzing.aanwijzingStatus = aanwijzing.aanwijzingStatus;
        if (!this.isRoleBuyer()) {
          this.disableChatField();
        }
      }
    );
    this.participantSubscription =
      this.chatParticipantWebsocketService.participant.subscribe(
        (participant: AanwijzingParticipant) => {
          this.showPopupOutSession(participant);
        }
      );
  }

  public leaveConfirmation(): Promise<boolean> {
    return new Promise(resolve => {
      if (
        this.isTypeOnline &&
        this.isRoleBuyer() &&
        this.aanwijzing.aanwijzingStatus.code ===
          this.global.appConstant.pm.AANWIJZING_STATUS_ON_PROGRESS
      ) {
        /** confirm leave this page */
        const prompt = 'aanwijzing.confirm.leavePageProgress.prompt';
        const title = 'aanwijzing.confirm.leavePageProgress.title';
        const buttonLeft = 'aanwijzing.button.inviteParticipant';
        const buttonRight = 'app.button.leaveThisPage';
        const icon = null;
        const isShowCloseButton = false;
        this.global.modalService
          .confirmation(
            prompt,
            title,
            buttonLeft,
            buttonRight,
            icon,
            isShowCloseButton
          )
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              resolve(result);
            } else {
              /** invite */
              this.doInviteParticipant();
            }
          });
      } else if (
        this.isTypeOnline &&
        this.isRoleBuyer() &&
        this.aanwijzing.aanwijzingStatus.code ===
          this.global.appConstant.pm.AANWIJZING_STATUS_DRAFT
      ) {
        /** have you invited participant */
        const prompt = 'aanwijzing.confirm.haveYouInviteParticipant.prompt';
        const title = 'aanwijzing.confirm.haveYouInviteParticipant.title';
        const buttonLeft = 'aanwijzing.button.inviteParticipant';
        const buttonRight = 'aanwijzing.button.leaveThisPage';
        const icon = null;
        const isShowCloseButton = false;
        this.global.modalService
          .confirmation(
            prompt,
            title,
            buttonLeft,
            buttonRight,
            icon,
            isShowCloseButton
          )
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              resolve(result);
            } else {
              this.doInviteParticipant();
            }
          });
      } else {
        /** back, log out */
        resolve(true);
      }
    });
  }

  public ngOnDestroy(): void {
    this.log.debug('destroy');
    if (this.response && this.response.aanwijzingParticipant) {
      this.response.aanwijzingParticipant.isOnline = false;
      this.setParticipantVendorIsJoinFalse();
      this.chatParticipantWebsocketService.disconnect();
      this.updateSocketParticipant(this.response.aanwijzingParticipant);
    }
  }

  @HostListener('window:beforeunload')
  onBeforeUnload(): void {
    if (this.response && this.response.aanwijzingParticipant) {
      this.response.aanwijzingParticipant.isOnline = false;
      this.setParticipantVendorIsJoinFalse();
      this.chatParticipantWebsocketService.disconnect();
      this.updateSocketParticipant(this.response.aanwijzingParticipant);
    }
  }

  public setParticipantVendorIsJoinFalse(): void {
    if (
      this.aanwijzing.isVendorMandatory &&
      this.aanwijzing.aanwijzingStatus.code ===
        this.global.appConstant.pm.AANWIJZING_STATUS_NOT_STARTED &&
      this.response.aanwijzingParticipant.participantRole.code ===
        this.global.appConstant.pm.PARTICIPANT_ROLE_VENDOR
    ) {
      /** vendor mandatory, sesi blm dimulai, utk role vendor dihitung tidak join (akan gugur jika tidak pernah join saat sesi on progress) */
      this.response.aanwijzingParticipant.isJoin = false;
    }
  }

  public updateSocketParticipant(participant: AanwijzingParticipant): void {
    if (this.isTypeOnline) {
      this.httpClientService
        .post<any>('/aanwijzing/chat-participant/update', participant)
        .subscribe((data: AanwijzingParticipant) => {
          this.log.debug(data);
        });
    }
  }

  public isRoleBuyer(): boolean {
    return (
      this.global.userSession?.activeUserRole.role.code ===
      this.global.appConstant.ROLE_CODE_BUYER
    );
  }

  public showPopupOutSession(participant: AanwijzingParticipant): void {
    if (
      participant.user?.id === this.global.userSession.user.id &&
      participant.roomCode === this.aanwijzing.roomCode &&
      !this.isRoleBuyer() &&
      this.isTypeOnline &&
      (!participant ||
        participant?.isBlocked ||
        !participant?.isOnline ||
        participant?.crudOperation ===
          this.global.appConstant.core.CRUD_OPERATION_DELETE)
    ) {
      this.response.aanwijzingParticipant =
        participant; /** agar kebawa isBlocked terupdate */
      // block/kick/deleted, not buyer
      // setTimeout(() => {
      //   this.chatParticipantWebsocketService.disconnect();
      // });

      if (this.participantSubscription) {
        this.participantSubscription.unsubscribe();
      }

      const prompt = 'aanwijzing.confirm.outSession.prompt';
      const title = 'aanwijzing.confirm.outSession.title';
      const buttonLeft = null;
      const buttonRight = 'aanwijzing.button.leaveThisPage';
      const icon = 'pvc pv-sign-out';
      const isShowCloseButton = false;
      this.global.modalService
        .confirmation(
          prompt,
          title,
          buttonLeft,
          buttonRight,
          icon,
          isShowCloseButton,
          null,
          true
        )
        .pipe(take(1))
        .subscribe(result => {
          this.log.debug(result);

          this.chatParticipantWebsocketService.disconnect();
          this.chatWebsocketService.disconnect();
          this.sessionRoomWebsocketService.disconnect();
          this.chatMenuWebsocketService.disconnect();
          this.router.navigate(['/pages/aanwijzing/edit']);
        });
    }
  }

  public buildAllTableParticipants(): void {
    this.tableResponsePartInternal = new TableResponseModel(this.moduleCode, [
      {
        field: 'user.name',
        header: 'table.column.name'
      },
      {
        field: 'user.email',
        header: 'table.column.email'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      },
      {
        field: 'departmentName',
        header: 'table.column.department'
      },
      {
        field: 'attendanceDate',
        header: 'table.column.attendanceTime',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'signatureImgFile',
        header: 'table.column.signature',
        plugins: 'img'
      }
    ]);

    this.tableResponsePartVendor = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.name'
      },
      {
        field: 'email',
        header: 'table.column.email'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      },
      {
        field: 'vendor.name',
        header: 'table.column.companyName'
      },
      {
        field: 'positionName',
        header: 'table.column.position'
      },
      {
        field: 'attendanceDate',
        header: 'table.column.attendanceTime',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'signatureImgFile',
        header: 'table.column.signature',
        plugins: 'img'
      }
    ]);

    this.tableResponsePartOthers = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.name'
      },
      {
        field: 'email',
        header: 'table.column.email'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      },
      {
        field: 'companyName',
        header: 'table.column.companyName'
      },
      {
        field: 'positionName',
        header: 'table.column.position'
      },
      {
        field: 'attendanceDate',
        header: 'table.column.attendanceTime',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'signatureImgFile',
        header: 'table.column.signature',
        plugins: 'img'
      }
    ]);
  }

  public setDataFromRouterParam(): void {
    this.todo = this.global.routerParams.get('todo');
    this.aanwijzingId = this.global.routerParams.get('aanwijzingId');
    this.aanwijzing = this.global.routerParams.get('aanwijzing');
    this.aanwijzingParticipantId = this.global.routerParams.get(
      'aanwijzingParticipantId'
    );
  }

  public getToleranceMinute(): void {
    const toleranceTimeInMinutes =
      this.response.aanwijzingToleranceTimeInMinutes;
    if (
      new Date(this.aanwijzing.periodStartDate).getTime() -
        toleranceTimeInMinutes * 60000 <=
      new Date().getTime()
    ) {
      this.isTolerated = true;
    } else {
      this.isTolerated = false;
    }
  }

  public disableChatField(): boolean {
    if (
      !this.aanwijzing.sessionStartTime ||
      (this.aanwijzing.sessionStartTime && this.aanwijzing.sessionEndTime)
    ) {
      return true;
    } else {
      return false;
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      letterNumber: [null, Validators.required()],
      summary: [null, Validators.required()],
      docList: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<any>(
        '/aanwijzing/session-room?aanwijzingId=' +
          (this.aanwijzingId || this.aanwijzing.id),
        {}
      )
      .subscribe((response: AanwijzingSessionResponse) => {
        this.response = response;
        this.aanwijzing = response.aanwijzing;
        this.isTypeOnline =
          this.aanwijzing.transactionType.code ===
          this.global.appConstant.core.TRANSACTION_TYPE_ONLINE;

        const participantList = response.existAanwijzingParticipantList;
        if (participantList) {
          this.isInvite = participantList.length > 1 ? true : false;
          participantList.forEach(participant => {
            participant.phone =
              participant.phoneRegion?.callingCode + participant.phone;
          });

          this.tableResponsePartInternal.setRecordList(
            participantList.filter(
              p =>
                p.participantRole.code ===
                this.global.appConstant.pm.PARTICIPANT_ROLE_INTERNAL
            )
          );
          this.tableResponsePartVendor.setRecordList(
            participantList.filter(
              p =>
                p.participantRole.code ===
                this.global.appConstant.pm.PARTICIPANT_ROLE_VENDOR
            )
          );
          this.tableResponsePartOthers.setRecordList(
            participantList.filter(
              p =>
                p.participantRole.code ===
                this.global.appConstant.pm.PARTICIPANT_ROLE_OTHERS
            )
          );

          this.tableResponsePartInternal.reload();
          this.tableResponsePartVendor.reload();
          this.tableResponsePartOthers.reload();
        }

        if (
          this.response.aanwijzing.aanwijzingStatus.code ===
          this.global.appConstant.pm.AANWIJZING_STATUS_CANCELED
        ) {
          this.isCanceled = true;
        }
        this.aanwijzingCancellation = this.response.aanwijzingCancellation;

        // summary dan doclist
        if (this.response.aanwijzingSummary) {
          this.formGroup.patchValue({
            letterNumber: this.response.aanwijzingSummary.letterNumber,
            summary: this.response.aanwijzingSummary.summary
          });
        } else {
          this.formGroup.patchValue({
            letterNumber: this.response.aanwijzing.code
          });

          if (this.formGroup.get('letterNumber').value) {
            this.formGroup.get('letterNumber').setIsView(true);
          }
        }

        if (this.response.aanwijzingSummaryDocList) {
          const fileList = this.response.aanwijzingSummaryDocList.map(
            d => d.file
          );
          this.fileUploader.setFileList(fileList);
          this.formGroup.patchValue({
            docList: this.fileUploader.fileObjectList
          });
        }

        this.setStateReady();

        if (
          this.aanwijzing.aanwijzingStatus.code ===
            this.global.appConstant.pm.AANWIJZING_STATUS_DONE ||
          this.aanwijzing.aanwijzingStatus.code ===
            this.global.appConstant.pm.AANWIJZING_STATUS_EXTENDED ||
          this.aanwijzing.aanwijzingStatus.code ===
            this.global.appConstant.pm.AANWIJZING_STATUS_CANCELED
        ) {
          this.setViewOnly();
        }

        if (this.response.aanwijzingParticipant) {
          if (!this.response.aanwijzingParticipant.isBlocked) {
            // this.response.aanwijzingParticipant.isJoin = true; sudah di set dari popup signature di anwz edit
            this.response.aanwijzingParticipant.isOnline = true;
            this.updateSocketParticipant(this.response.aanwijzingParticipant);
          }
          this.showPopupOutSession(this.response.aanwijzingParticipant);
        }

        this.getToleranceMinute();
        this.disableChatField();

        this.isAllReminded = this.response.isAllRemindedToday;
      });
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public onChangeTimer(event: {
    startDate: Date;
    endDate: Date;
    signatureImgFile: FileObject[];
  }): void {
    this.log.debug(event.startDate);
    this.log.debug(event.endDate);
    this.log.debug(event.signatureImgFile);

    if (event.startDate && !event.endDate) {
      // this.aanwijzing.sessionStartTime = new Date(event.startDate);
      const req = {
        processType: 'START',
        aanwijzing: this.aanwijzing
      };

      this.httpClientService
        .post<Aanwijzing>('/aanwijzing/session/update', req)
        .subscribe((response: Response<AanwijzingUpdateSessionResponse>) => {
          if (response.status === ResponseStatusModel.OK) {
            this.aanwijzing = response.body.aanwijzing;
            this.aanwijzing.sessionStartTime = new Date(
              this.aanwijzing.sessionStartTime
            );
          }
          this.disableChatField();
          this.setStateReady();
          this.isAllReminded = false;
        });

      const reqSignature = {
        fileObject: event.signatureImgFile[0],
        aanwijzingParticipant: this.response.aanwijzingParticipant
      };
      this.httpClientService
        .post<any>('/aanwijzing/insert-update-participant', reqSignature)
        .subscribe((response: Response<Aanwijzing>) => {
          this.log.debug(response);
        });
    }

    if (event.endDate) {
      // this.aanwijzing.sessionEndTime = new Date(event.endDate);
      const req = {
        processType: 'END',
        aanwijzing: this.aanwijzing
      };
      this.httpClientService
        .post<Aanwijzing>('/aanwijzing/session/update', req)
        .subscribe((response: Response<AanwijzingUpdateSessionResponse>) => {
          // backend update status anwz jadi closed
          if (response.status === ResponseStatusModel.OK) {
            this.aanwijzing = response.body.aanwijzing;
            this.aanwijzing.sessionEndTime = new Date(
              this.aanwijzing.sessionEndTime
            );
          }
          this.disableChatField();
          this.setStateReady();
        });
    }
  }

  public doShowQRCode(): void {
    const aanwijzing = this.aanwijzing;
    this.appPopupService.open(
      AanwijzingPopupShowQRComponent,
      { aanwijzing },
      { size: 'xl' }
    );
  }

  public doCancel(): void {
    this.appPopupService
      .open(AanwijzingPopupCancelComponent, {})
      .subscribe((note: string) => {
        const aanwijzingCancellation = new AanwijzingCancellation();
        aanwijzingCancellation.note = note;
        aanwijzingCancellation.aanwijzing = this.aanwijzing;

        this.setStateProcessing();
        this.httpClientService
          .post<Response<AanwijzingCancellation>>(
            '/aanwijzing/cancel',
            aanwijzingCancellation
          )
          .subscribe((response: Response<AanwijzingCancellation>) => {
            if (response.status === ResponseStatusModel.OK) {
              this.isCanceled = true;
            }
            this.aanwijzingCancellation = response.body;
            this.aanwijzing = this.aanwijzingCancellation.aanwijzing;
            this.setStateReady();
            this.setViewOnly();
          });
      });
  }

  public doClose(): void {
    /** offline only */
    const prompt = 'aanwijzing.confirm.closeSession.prompt';
    const title = 'aanwijzing.confirm.closeSession.title';
    const buttonLeft = 'app.button.cancel';
    const buttonRight = 'aanwijzing.button.yesImSure';
    const icon = null;
    const isShowCloseButton = false;

    this.global.modalService
      .confirmation(
        prompt,
        title,
        buttonLeft,
        buttonRight,
        icon,
        isShowCloseButton
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.httpClientService
            .post<Response<Aanwijzing>>('/aanwijzing/close', this.aanwijzing)
            .subscribe((response: Response<Aanwijzing>) => {
              if (response.status === ResponseStatusModel.OK) {
                this.aanwijzing = response.body;
              }
              this.setStateReady();
            });
        }
      });
  }

  public isShowSummary(): boolean {
    const isShowSummary =
      (this.isRoleBuyer() &&
        ((this.isTypeOnline && !!this.aanwijzing.sessionEndTime) ||
          (!this.isTypeOnline &&
            (this.aanwijzing.aanwijzingStatus?.code ===
              this.global.appConstant.pm.AANWIJZING_STATUS_CLOSED ||
              this.aanwijzing.aanwijzingStatus?.code ===
                this.global.appConstant.pm.AANWIJZING_STATUS_DONE ||
              this.aanwijzing.aanwijzingStatus?.code ===
                this.global.appConstant.pm.AANWIJZING_STATUS_EXTENDED)))) ||
      (!this.isRoleBuyer() &&
        (this.aanwijzing.aanwijzingStatus?.code ===
          this.global.appConstant.pm.AANWIJZING_STATUS_DONE ||
          this.aanwijzing.aanwijzingStatus?.code ===
            this.global.appConstant.pm.AANWIJZING_STATUS_EXTENDED));

    return isShowSummary;
  }

  public doSave(): void {
    this.setStateProcessing();

    const req = new AanwijzingSessionRequest();
    req.aanwijzing = this.aanwijzing;
    req.aanwijzingSummary = this.formGroup.value;
    req.aanwijzingSummary.id = this.response.aanwijzingSummary?.id;
    req.fileObjectList = this.fileUploader.fileObjectList;
    req.isSubmit = false;

    this.httpClientService
      .post<Aanwijzing>('/aanwijzing/insert-summary', req)
      .subscribe((response: Response<AanwijzingSummary>) => {
        if (response.status === ResponseStatusModel.OK) {
          this.snackBarService.showWarning(
            this.translateService.instant(
              'aanwijzing.alert.msg.yourWorkHasBeenSaved'
            )
          );

          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['/pages/aanwijzing/session-room']);
            });
        }
        this.setStateReady();
      });
  }

  public doSubmitSession(): void {
    this.formGroup.get('docList').updateValueAndValidity();
    this.validate();
    if (this.formGroup.valid && this.isTemplateDownloaded) {
      const prompt = 'aanwijzing.confirm.prompt.submit';
      const title = 'aanwijzing.confirm.title.submit';
      const buttonLeft = 'app.button.cancel';
      const buttonRight = 'aanwijzing.button.yesSubmit';
      const icon = null;
      const isShowCloseButton = false;
      this.global.modalService
        .confirmation(
          prompt,
          title,
          buttonLeft,
          buttonRight,
          icon,
          isShowCloseButton
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            const req = new AanwijzingSessionRequest();
            req.aanwijzing = this.aanwijzing;
            req.aanwijzingSummary = this.formGroup.value;
            req.aanwijzingSummary.id = this.response.aanwijzingSummary?.id;
            req.fileObjectList = this.fileUploader.fileObjectList;
            req.isSubmit = true;

            this.httpClientService
              .post<Aanwijzing>('/aanwijzing/insert-summary', req)
              .subscribe((response: Response<AanwijzingSummary>) => {
                this.log.debug(response);

                this.aanwijzing = response.body.aanwijzing;
                this.response.aanwijzingSummary = response.body;

                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  this.setViewOnly();

                  this.global.modalService.submitSuccess().subscribe(result => {
                    if (result) {
                      this.router.navigate(['/pages/aanwijzing/edit']);
                    }
                  });
                }
              });
          }
        });
    } else if (!this.isTemplateDownloaded) {
      this.toastService.showError(
        'aanwijzing.alert.msg.pleaseGenerateAndDownloadTemplateFirst'
      );
    }
  }

  public doInviteParticipant(): void {
    const aanwijzing = this.response.aanwijzing;
    const aanwijzingParticipantList = this.response.aanwijzingParticipantList;
    const participantRoleList = this.response.participantRoleList;
    const vendorList = this.response.vendorList;
    const departmentList = this.response.aanwijzingParticipantList
      .filter(
        ap =>
          ap.participantRole.code ===
          this.global.appConstant.pm.PARTICIPANT_ROLE_INTERNAL
      )
      .map(ap => (ap.user ? ap.user.organization : ap.organization));
    aanwijzingParticipantList.forEach((ap, index) => {
      if (!ap.id) {
        ap.id = index;
      }
    });
    const existAanwijzingParticipantList =
      this.response.existAanwijzingParticipantList;

    this.appPopupService
      .open(AanwijzingPopupInviteParticipantComponent, {
        aanwijzing,
        aanwijzingParticipantList,
        participantRoleList,
        vendorList,
        departmentList,
        existAanwijzingParticipantList
      })
      .subscribe(participantList => {
        this.isInvite = true;
        this.isAllReminded = false;
        existAanwijzingParticipantList.push(...participantList);
        this.response.existAanwijzingParticipantList =
          existAanwijzingParticipantList;
        this.aanwijzing.aanwijzingStatus =
          participantList[0].aanwijzing.aanwijzingStatus;
      });
  }

  public doSendReminder(): void {
    const prompt = 'aanwijzing.confirm.reminder.prompt';
    const title = 'aanwijzing.confirm.reminder.title';
    const buttonLeft = 'app.button.close';
    const buttonRight = 'aanwijzing.button.sendReminder';
    const icon = 'pvc pv-envelope-reminder';
    const isShowCloseButton = false;

    this.global.modalService
      .confirmation(
        prompt,
        title,
        buttonLeft,
        buttonRight,
        icon,
        isShowCloseButton
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();

          const appChatParticipantList =
            this.appChat.state.participantList.filter(
              participant =>
                participant.user?.id !== this.global.userSession.user.id
            );

          let participantIdList = [];
          if (
            this.aanwijzing.aanwijzingStatus.code ===
            this.global.appConstant.pm.AANWIJZING_STATUS_NOT_STARTED
          ) {
            participantIdList = appChatParticipantList
              .map(participant => participant.id)
              .filter(id => !this.remindedParticipantIdList.includes(id));
          } else {
            participantIdList = appChatParticipantList.map(
              participant => participant.id
            );
          }

          const req = new AanwijzingRemindRequest();
          req.aanwijzing = this.aanwijzing;
          req.aanwijzingParticipantIdList = participantIdList;

          this.httpClientService
            .post<Aanwijzing>('/aanwijzing/remind', req)
            .subscribe(response => {
              this.log.debug(response);

              if (response.status === ResponseStatusModel.OK) {
                this.snackBarService.showWarning(
                  this.global.translateService.instant(
                    'aanwijzing.alert.msg.reminderSent'
                  )
                );

                this.remindedParticipantIdList.push(...participantIdList);

                const isSameList =
                  this.remindedParticipantIdList.length ===
                    appChatParticipantList.map(p => p.id).length &&
                  this.remindedParticipantIdList.every(
                    (val, index) =>
                      val === appChatParticipantList.map(p => p.id)[index]
                  );
                this.log.debug(isSameList);

                this.isAllReminded =
                  isSameList &&
                  this.aanwijzing.aanwijzingStatus.code ===
                    this.global.appConstant.pm.AANWIJZING_STATUS_NOT_STARTED;

                this.setStateReady();
              } else {
                this.snackBarService.showError(response.statusText);
                this.setStateReady();
              }
            });
        }
      });
  }

  public doAttendanceForm(): void {
    const aanwijzingParticipant = this.response.aanwijzingParticipant;
    this.appPopupService
      .open(AanwijzingPopupJoinOfflineComponent, { aanwijzingParticipant })
      .subscribe(() => {
        this.isJoinOffline = true;
        this.setStateLoading();
        this.setFormGroup();
      });
  }

  public statusContains(statusList: string[]): boolean {
    return statusList.includes(this.aanwijzing.aanwijzingStatus?.code);
  }

  public doPrintSummary(): void {
    const aanwijzingId = this.aanwijzing.id;
    this.setStateProcessing();
    this.reportService
      .print('/aanwijzing/print/summary', aanwijzingId)
      .subscribe(() => this.setStateReady());
  }

  public doPrintAttendance(): void {
    const aanwijzingId = this.aanwijzing.id;
    this.setStateProcessing();
    this.reportService
      .print('/aanwijzing/print/attendance', aanwijzingId)
      .subscribe(() => this.setStateReady());
  }

  public doGenerate(event: any): void {
    const appOfficialReportRequest = new AppOfficialReportRequest();
    appOfficialReportRequest.letterDate = new Date();
    appOfficialReportRequest.letterNumber =
      this.formGroup.get('letterNumber').value;

    const contentList = {
      $AWZ_SUMMARY: this.formGroup.get('summary').value
    };

    appOfficialReportRequest.contentList = contentList;

    appOfficialReportRequest.auth = this.encryptService.encrypt([
      this.aanwijzing.id.toString(),
      'ORT_AANWIJZING_LETTER',
      'NUMBER'
    ]);

    this.loadingBlockService.showInfo(`app.msg.processing`);
    this.httpClientService
      .post<Response<File>>('/aanwijzing/generate', appOfficialReportRequest)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          const file: File = response.body;
          this.doDownload(
            event,
            '/aanwijzing/file/view/' +
              file.uploadedFileName +
              '/' +
              file.fileType.code,
            file.fileName
          );
          this.isTemplateDownloaded = true;
          this.loadingBlockService.close();
        }
      });
  }

  public getDate(date): Date {
    return new Date(date);
  }
}
