<app-popup
  [header]="
    'tab-profile-financial-statement.form.popup.header.financial.' + todo
  "
  [isLoading]="formLoading"
>
  <form [formGroup]="formGroup" class="app-popup-expert">
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.financialYear' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          [autofocus]="true"
          size="SM"
          formControlName="year"
          type="integer"
          maxLength="4"
        ></app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.currency' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="SM"
          [optionList]="currencyOptionList"
          formControlName="currency"
        ></app-combo-box>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.auditNumber' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="auditNumber"
          maxLength="32"
        ></app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.auditDate' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-date-picker
          formControlName="date"
          maxDate="currentDate"
        ></app-date-picker>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.auditorName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="auditor"
          maxLength="64"
          type="alphanumeric"
          include="-.\',"
        ></app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.currentAsset' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="currentAsset"
          maxLength="16"
          type="currency"
          (onInput)="onInputCurrentAsset()"
        ></app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.currentLiability'
          | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="currentLiability"
          maxLength="16"
          type="currency"
          (onInput)="onInputCurrentLiability()"
        ></app-text-field>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.currentRatio' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <p>{{ formGroup.get('currentRatio').value || ' ' }}</p>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.totalLiability' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="totalLiability"
          maxLength="16"
          type="currency"
          (onInput)="onInputTotalLiability()"
        ></app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.equity' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="equity"
          maxLength="16"
          type="currency"
          (onInput)="onInputEquity()"
        ></app-text-field>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.deptEquityRatio' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <p>{{ formGroup.get('deptEquityRatio').value || ' ' }}</p>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.netIncome' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="netIncome"
          maxLength="16"
          type="currency"
          (onInput)="onInputNetIncome()"
          include="-"
        ></app-text-field>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.returnOfEquity' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <p>{{ formGroup.get('roe').value || ' ' }} <strong>%</strong></p>
        <small
          class="text-danger"
          *ngIf="
            formGroup.get('roe').value !== null && formGroup.get('roe').invalid
          "
        >
          {{ formGroup.get('roe').errors.message }}
        </small>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.cash' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="cash"
          maxLength="16"
          type="currency"
          (onInput)="onInputCash()"
        ></app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.totalActiva' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="totalActiva"
          maxLength="16"
          type="currency"
        ></app-text-field>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.statusAudit' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-combo-box
          size="LG"
          [optionList]="vendorFinancialStatusOptionList"
          formControlName="vendorFinancialStatus"
        ></app-combo-box>
      </div>
    </div>

    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'tab-profile-financial-statement.form.popup.uploadFinancialDoc'
          | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-upload
          size="LG"
          formControlName="file"
          [fileUploader]="fileUploader"
        ></app-upload>
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <div class="col-sm-9 text-left ml-4">
        *)
        {{
          'tab-profile-financial-statement.form.popup.currentRatio' | translate
        }}
        =
        {{
          'tab-profile-financial-statement.form.popup.calculationInfoCR'
            | translate
        }}
        <br />
        *)
        {{
          'tab-profile-financial-statement.form.popup.returnOfEquity'
            | translate
        }}
        (%) =
        {{
          'tab-profile-financial-statement.form.popup.calculationInfoROE'
            | translate
        }}
        <br />
        *)
        {{
          'tab-profile-financial-statement.form.popup.deptEquityRatio'
            | translate
        }}
        =
        {{
          'tab-profile-financial-statement.form.popup.calculationInfoDER'
            | translate
        }}
        <br />
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
        <app-button *ngIf="!formGroup.isView" (onClick)="doSave()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
