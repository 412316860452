import { BaseDTO } from '../../../core/base/base-dto';
import { WorkPlan } from '../../../core/bean/work-plan';
import { WorkPlanItem } from '../../../core/bean/work-plan-item';
import { PurchaseRequestModel } from '../../../core/model/purchase-request-model';

export class WorkPlanPurchaseRequestDTO extends BaseDTO {
    workPlan: WorkPlan;
    workPlanItemList: WorkPlanItem[];
    purchaseRequestModelList: PurchaseRequestModel[];
}
