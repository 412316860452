import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { TableModule } from '../../table/table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupAnalysisSingleComponent } from './app-popup-analysis-single.component';
import { AppPopupAnalysisSingleService } from './app-popup-analysis-single.service';

@NgModule({
  imports: [VendorModule, AppPopupModule, TableModule],
  declarations: [AppPopupAnalysisSingleComponent],
  entryComponents: [AppPopupAnalysisSingleComponent],
  providers: [AppPopupAnalysisSingleService],
  exports: [AppPopupAnalysisSingleComponent]
})
export class AppPopupAnalysisSingleModule {}
