<app-popup [header]="popupHeader" [isLoading]="formLoading">
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    *ngIf="!formLoading"
    novalidate
  >
    <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'initiation.form.itemName' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        {{ directPurchaseDelivery.procurementItem.prItem.item.name }}
      </div>
    </div>

    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'initiation.form.deliveredItem' | translate }}
      </label>
      <div
        *ngIf="
          this.directPurchaseDelivery.procurementItem.prItem.item.itemType
            .code === this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
        "
        class="col-sm-9 text-left"
      >
        {{ directPurchaseDelivery.receivedQuantity | resolveNumberToDecimal }} /
        {{
          directPurchaseDelivery.procurementItem.prItem.quantity
            | resolveNumberToDecimal
        }}
      </div>
      <div
        *ngIf="
          this.directPurchaseDelivery.procurementItem.prItem.item.itemType
            .code === this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
        "
        class="col-sm-9 text-left"
      >
        {{ directPurchaseDelivery.receivedQuantity | resolveNumberToDecimal }}
        /
        {{
          directPurchaseDelivery.procurementItem.prItem.quantity
            | resolveNumberToDecimal
        }}
      </div>
    </div>

    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'initiation.form.dateReceived' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-date-picker-x
          size="LG"
          formControlName="receivedDate"
          [time]="true"
        ></app-date-picker-x>
      </div>
    </div>

    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'initiation.form.deliveryPerformance' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-rating formControlName="performanceValue"></app-rating>
      </div>
    </div>

    <ng-template #modalFooter let-activeModal *ngIf="!formGroup.isView">
      <div class="col-12 text-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button color="PRIMARY" (onClick)="doSave()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
