import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ScoringCriteria } from '../../core/bean/scoring-criteria';
import { ScoringCriteriaGroup } from '../../core/bean/scoring-criteria-group';
import { ScoringTemplate } from '../../core/bean/scoring-template';
import { ScoringTemplateWeight } from '../../core/bean/scoring-template-weight';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { ProcurementType } from './../../core/bean/procurement-type';
import { ScoringTemplateOptionListResponse } from './scoring-template-option-list-response';
import { ScoringTemplateRequest } from './scoring-template-request';
import { ScoringTemplateResponse } from './scoring-template-response';
@Component({
  templateUrl: './scoring-template-edit-add.component.html'
})
export class ScoringTemplateEditAddComponent extends BaseModuleComponent {
  public scoringTemplateResponse: ScoringTemplateResponse =
    new ScoringTemplateResponse();
  public scoringTemplateRequest: ScoringTemplateRequest =
    new ScoringTemplateRequest();
  public scoringTemplateId: number;
  public moduleOptionList: OptionListModel<any> = new OptionListModel(true);
  public moduleScoringCriteriaList = [];
  public scoringCriteriaGroupList: ScoringCriteriaGroup[];
  public optionList: OptionListModel<any> = new OptionListModel();
  public objectNameOptionList: OptionListModel<any> = new OptionListModel();
  public objectTypeOptionList: OptionListModel<any> = new OptionListModel();
  public scoringTemplateResponseTemp: ScoringTemplateResponse =
    new ScoringTemplateResponse();
  public scoringTemplateWeightList: ScoringTemplateWeight[];
  public isDisabled: boolean;
  public weightTotal = 0;
  public moduleScoringCriteria: number;
  public moduleScoringCriteriaCode = '';
  public idListCommon: number[] = [];
  public idListSpesific: number[] = [];
  public customDataCommon: Object;
  public customDataSpesific: Object;
  public idListCommonString = '';
  public idListSpesificString = '';

  private OBJECT_NAME_ORDER_TYPE_BEAN = 'OrderTypeBean';
  private OBJECT_NAME_CONTRACT_TYPE_BEAN = 'ContractTypeBean';

  public objectNameList = [
    {
      name: this.global.translateService.instant(
        'scoring-template.objectName.contractType'
      ),
      value: this.OBJECT_NAME_CONTRACT_TYPE_BEAN
    },
    {
      name: this.global.translateService.instant(
        'scoring-template.objectName.orderType'
      ),
      value: this.OBJECT_NAME_ORDER_TYPE_BEAN
    }
  ];

  constructor(translateService: TranslateService) {
    super('scoring-template', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.scoringTemplateId = this.global.routerParams.get('scoringTemplateId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      description: [null, Validators.maxLength(512)],
      moduleScoringCriteria: [
        null,
        Validators.compose([Validators.required()])
      ],
      type: [null, Validators.compose([Validators.required()])],
      minimumQualified: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.max(5),
          Validators.maxLength(11)
        ])
      ],
      scoringTemplateWeightListAndCommon: this.formBuilder.array([]),
      scoringTemplateWeightListAndSpesific: this.formBuilder.array([]),
      objectName: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ScoringTemplateResponse>(
        '/scoring-template/add-edit',
        new RouteRequestModel(this.todo, this.scoringTemplateId)
      )
      .subscribe(scoringTemplateResponse => {
        this.scoringTemplateResponseTemp = scoringTemplateResponse;
        this.setModuleOption(scoringTemplateResponse);
        this.setTypeOption();
        this.setScoringCriteriaGroupListCommon(scoringTemplateResponse);
        this.setScoringCriteriaGroupListSpesific(scoringTemplateResponse);
        this.scoringCriteriaGroupList =
          scoringTemplateResponse.scoringCriteriaGroupList;
        this.moduleScoringCriteriaList =
          scoringTemplateResponse.moduleScoringCriteriaList;
        if (this.todo === 'edit') {
          const { id, name, code, description, minimumQualified } =
            scoringTemplateResponse.scoringTemplate;
          this.formGroup.patchValue({
            id,
            name,
            code,
            description,
            minimumQualified
          });
        } else if (this.todo === 'copy') {
          const { minimumQualified } = scoringTemplateResponse.scoringTemplate;
          this.formGroup.patchValue({ minimumQualified });
        } else {
          this.addScoringCriteriaGroupCommon();
          this.addScoringCriteriaGroupSpesific();
        }

        this.countTotal();
        this.setStateReady();
      });
  }

  public setTooltips(): void {
    this.inputScoringTemplateWeightListAndCommon.controls.forEach(
      scoreTemplateWeight => {
        if (
          scoreTemplateWeight.value.scoringCriteriaGroup !== null ||
          scoreTemplateWeight.value.scoringCriteriaGroup !== ''
        ) {
          const code = scoreTemplateWeight.value.scoringCriteriaGroup.code;
          this.httpClientService
            .get<ScoringCriteria[]>(
              '/scoring-template/get-scoring-criteria-list/' + code
            )
            .subscribe(scoreCriteriaList => {
              scoreTemplateWeight.value.scoringCriteriaGroup.scoringCriteriaList =
                scoreCriteriaList; // utk table tooltip
              this.setStateReady();
            });
        }
      }
    );
  }

  public setModuleOption(
    scoringTemplateResponse: ScoringTemplateResponse
  ): void {
    const moduleOptionList = [];
    scoringTemplateResponse.moduleScoringCriteriaList.forEach(
      moduleScoringCriteria => {
        moduleOptionList.push({
          id: moduleScoringCriteria.id,
          code: moduleScoringCriteria.code,
          name: moduleScoringCriteria.name
        });
        if (
          scoringTemplateResponse.scoringTemplate !== null &&
          moduleScoringCriteria.id ===
            scoringTemplateResponse.scoringTemplate.moduleScoringCriteria.id
        ) {
          this.formGroup
            .get('moduleScoringCriteria')
            .patchValue(moduleScoringCriteria.id);
        }
      }
    );
    this.moduleOptionList.setRequestValues(moduleOptionList);
  }

  public get inputScoringTemplateWeightListAndCommon(): FormArray {
    return this.formGroup.get(
      'scoringTemplateWeightListAndCommon'
    ) as FormArray;
  }

  public get inputScoringTemplateWeightListAndSpesific(): FormArray {
    return this.formGroup.get(
      'scoringTemplateWeightListAndSpesific'
    ) as FormArray;
  }

  public addScoringCriteriaGroupSpesific(): void {
    this.inputScoringTemplateWeightListAndSpesific.push(
      this.formBuilder.group({
        scoringCriteriaGroup: ['', Validators.required()],
        weight: [
          null,
          Validators.compose([
            Validators.required(),
            Validators.max(100),
            Validators.min(1)
          ])
        ]
      })
    );

    this.inputScoringTemplateWeightListAndSpesific.controls.forEach(
      scoreTemplateWeight => {
        if (scoreTemplateWeight.value.scoringCriteriaGroup !== '') {
          this.idListSpesific.push(
            scoreTemplateWeight.value.scoringCriteriaGroup.id
          );
        }
      }
    );

    if (this.idListSpesific.length !== 0) {
      this.customDataSpesific = {
        moduleScoringCode: this.moduleScoringCriteriaCode,
        idList: this.idListSpesific
      };
    }
  }

  public addScoringCriteriaGroupCommon(): void {
    this.inputScoringTemplateWeightListAndCommon.push(
      this.formBuilder.group({
        scoringCriteriaGroup: ['', Validators.required()],
        weight: [
          null,
          Validators.compose([
            Validators.required(),
            Validators.max(100),
            Validators.min(1)
          ])
        ]
      })
    );

    this.inputScoringTemplateWeightListAndCommon.controls.forEach(
      scoreTemplateWeight => {
        if (scoreTemplateWeight.value.scoringCriteriaGroup !== '') {
          this.idListCommon.push(
            scoreTemplateWeight.value.scoringCriteriaGroup.id
          );
        }
      }
    );

    if (this.idListCommon.length !== 0) {
      this.customDataCommon = {
        moduleScoringCode: this.moduleScoringCriteriaCode,
        idList: this.idListCommon
      };
    }
  }

  public setScoringCriteriaGroupListCommon(
    scoringTemplateResponse: ScoringTemplateResponse
  ): void {
    if (scoringTemplateResponse.scoringTemplateWeightListCommon !== null) {
      scoringTemplateResponse.scoringTemplateWeightListCommon.forEach(
        scoringTemplateWeight => {
          this.inputScoringTemplateWeightListAndCommon.push(
            this.formBuilder.group({
              scoringCriteriaGroup: [
                scoringTemplateWeight.scoringCriteriaGroup,
                Validators.required()
              ],
              weight: [
                scoringTemplateWeight.weight,
                Validators.compose([
                  Validators.required(),
                  Validators.max(100),
                  Validators.min(1)
                ])
              ]
            })
          );
          this.idListCommon.push(scoringTemplateWeight.scoringCriteriaGroup.id);
        }
      );
    }
    this.customDataCommon = {
      moduleScoringCode: this.moduleScoringCriteriaCode,
      idList: this.idListCommon
    };
  }

  public setScoringCriteriaGroupListSpesific(
    scoringTemplateResponse: ScoringTemplateResponse
  ): void {
    if (scoringTemplateResponse.scoringTemplateWeightListSpesific !== null) {
      scoringTemplateResponse.scoringTemplateWeightListSpesific.forEach(
        scoringTemplateWeight => {
          this.inputScoringTemplateWeightListAndSpesific.push(
            this.formBuilder.group({
              scoringCriteriaGroup: [
                scoringTemplateWeight.scoringCriteriaGroup,
                Validators.required()
              ],
              weight: [
                scoringTemplateWeight.weight,
                Validators.compose([
                  Validators.required(),
                  Validators.max(100),
                  Validators.min(1)
                ])
              ]
            })
          );
          this.idListSpesific.push(
            scoringTemplateWeight.scoringCriteriaGroup.id
          );
        }
      );
    }
    this.customDataSpesific = {
      moduleScoringCode: this.moduleScoringCriteriaCode,
      idList: this.idListSpesific
    };
  }

  public setTypeOption(): void {
    const moduleScoringCriteria = this.formGroup.get(
      'moduleScoringCriteria'
    ).value;
    this.moduleScoringCriteria = moduleScoringCriteria;
    const { vendorTypeList, contractTypeList } =
      this.scoringTemplateResponseTemp;
    if (moduleScoringCriteria === 1) {
      this.moduleScoringCriteriaCode = 'REGISTRATION';
      this.optionList.setRequestValues(vendorTypeList);
      this.formGroup.get('type').patchValue(null);
      if (this.scoringTemplateResponseTemp.scoringTemplate !== null) {
        this.formGroup
          .get('type')
          .patchValue(
            this.scoringTemplateResponseTemp.scoringTemplate.vendorType
          );
      }
    } else if (moduleScoringCriteria === 2) {
      this.moduleScoringCriteriaCode = 'PROCUREMENT';
      this.formGroup.get('type').patchValue(null);
      if (this.scoringTemplateResponseTemp.scoringTemplate !== null) {
        this.formGroup
          .get('type')
          .patchValue(this.scoringTemplateResponseTemp.procurementTypeList);
      }
    } else if (moduleScoringCriteria === 3) {
      this.moduleScoringCriteriaCode = 'FULFILLMENT';
      this.optionList.setRequestValues(contractTypeList);
      this.formGroup.get('type').patchValue(null);
      if (this.scoringTemplateResponseTemp.scoringTemplate !== null) {
        this.formGroup.patchValue({
          type:
            this.scoringTemplateResponseTemp.contractType ||
            this.scoringTemplateResponseTemp.orderType,
          objectName: this.objectNameList.filter(
            data =>
              data.value ===
              this.scoringTemplateResponseTemp.scoringTemplate.objectName
          )[0]
        });
      }
      this.formGroup.get('objectName').setValidators(Validators.required());
      this.formGroup.get('objectName').updateValueAndValidity();
      this.objectNameOptionList.setRequestValues(this.objectNameList);
      this.objectTypeOptionList.setRequestValues([]);
    } else {
      this.optionList.reset();
    }

    this.inputScoringTemplateWeightListAndCommon.controls.forEach(control => {
      control.get('scoringCriteriaGroup').reset();
      control.get('weight').reset();
    });
    this.inputScoringTemplateWeightListAndSpesific.controls.forEach(control => {
      control.get('scoringCriteriaGroup').reset();
      control.get('weight').reset();
    });
    this.customDataCommon = {
      moduleScoringCode: this.moduleScoringCriteriaCode,
      idList: this.idListCommon
    };
    this.customDataSpesific = {
      moduleScoringCode: this.moduleScoringCriteriaCode,
      idList: this.idListSpesific
    };
  }

  public onChangeObjectName(): void {
    this.objectTypeOptionList.reset();
    this.formGroup.get('type').patchValue(null);
    const objectName = this.formGroup.value.objectName.value;
    this.httpClientService
      .get<ScoringTemplateOptionListResponse>(
        '/scoring-template/get-type-option-list/' + objectName
      )
      .subscribe((response: ScoringTemplateOptionListResponse) => {
        const optionValueList =
          response.contractTypeList || response.orderTypeList;
        this.objectTypeOptionList.setRequestValues(optionValueList);
      });
  }

  public doSave(): void {
    this.validate();

    const scoringTemplateWeightListAndCommon = this.formGroup.get(
      'scoringTemplateWeightListAndCommon'
    ).value;
    const scoringTemplateWeightListAndSpesific = this.formGroup.get(
      'scoringTemplateWeightListAndSpesific'
    ).value;
    const scoringCriteriaList = [];
    const scoringCriteriaListSelectedNew = [];
    this.scoringTemplateWeightList = scoringTemplateWeightListAndCommon.concat(
      scoringTemplateWeightListAndSpesific
    );
    for (let i = 0; i <= this.scoringTemplateWeightList.length - 1; i++) {
      scoringCriteriaList.push(
        this.scoringTemplateWeightList[i].scoringCriteriaGroup
          .scoringCriteriaList
      );
    }
    scoringCriteriaList.forEach(scoringCriteriaListSelected => {
      scoringCriteriaListSelected.forEach(scoringCriteriaSelected => {
        scoringCriteriaListSelectedNew.push(scoringCriteriaSelected);
      });
    });
    this.hasDuplicate(scoringCriteriaListSelectedNew);

    if (
      this.formGroup.valid &&
      this.weightTotal / 100 === 100 &&
      !this.hasDuplicate(scoringCriteriaListSelectedNew)
    ) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.moduleScoringCriteriaList.forEach(moduleScoringCriteria => {
              if (
                moduleScoringCriteria.id ===
                this.formGroup.get('moduleScoringCriteria').value
              ) {
                this.formGroup
                  .get('moduleScoringCriteria')
                  .patchValue(moduleScoringCriteria);
              }
            });
            const scoringTemplate: ScoringTemplate = this.formGroup.value;
            if (scoringTemplate.moduleScoringCriteria.id === 1) {
              scoringTemplate.vendorType = this.formGroup.get('type').value;
              scoringTemplate.procurementType = null;
            } else if (scoringTemplate.moduleScoringCriteria.id === 2) {
              scoringTemplate.vendorType = null;
              const procurementTypeList: ProcurementType[] =
                this.formGroup.get('type').value;
              scoringTemplate.procurementType =
                procurementTypeList[procurementTypeList.length - 1];
            } else if (scoringTemplate.moduleScoringCriteria.id === 3) {
              scoringTemplate.vendorType = null;
              scoringTemplate.procurementType = null;
              scoringTemplate.objectName =
                this.formGroup.get('objectName').value.value;
              scoringTemplate.objectId = this.formGroup.get('type').value.id;
            } else {
              scoringTemplate.vendorType = null;
              scoringTemplate.procurementType = null;
            }

            this.scoringTemplateRequest.scoringTemplate = scoringTemplate;
            this.scoringTemplateRequest.scoringTemplateWeightListCommon =
              this.formGroup.get('scoringTemplateWeightListAndCommon').value;
            this.scoringTemplateRequest.scoringTemplateWeightListSpesific =
              this.formGroup.get('scoringTemplateWeightListAndSpesific').value;
            const url: string =
              this.todo === 'edit'
                ? '/scoring-template/update'
                : '/scoring-template/insert';
            this.httpClientService
              .post<Response<ScoringTemplateRequest>>(
                url,
                this.scoringTemplateRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/scoring-template/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    } else if (this.formGroup.valid && this.weightTotal / 100 !== 100) {
      this.global.alertService.showError(
        this.translateService.instant(
          'scoring-template.msg.error.mustOneHundredPercent'
        )
      );
    } else if (this.hasDuplicate(scoringCriteriaListSelectedNew)) {
      this.global.alertService.showError(
        this.translateService.instant(
          'scoring-template.msg.error.chooseAnotherScoringCriteriaGroup'
        )
      );
    }
  }

  public hasDuplicate(array: any): boolean {
    const valueSoFar = Object.create(null);
    for (const value of array) {
      const id = value.id;
      if (id in valueSoFar) {
        return true;
      }
      valueSoFar[id] = true;
    }
    return false;
  }

  public autoCompleteValueChangeCommon(event: any, index: number): void {
    if (event !== null) {
      this.idListCommon.push(event.id);
      this.customDataCommon = {
        moduleScoringCode: this.moduleScoringCriteriaCode,
        idList: this.idListCommon
      };
    }

    if (event !== null || event !== '') {
      const code = event.code;
      this.httpClientService
        .get<ScoringCriteria[]>(
          '/scoring-template/get-scoring-criteria-list/' + code
        )
        .subscribe(scoreCriteriaList => {
          this.inputScoringTemplateWeightListAndCommon.controls[
            index
          ].value.scoringCriteriaGroup.scoringCriteriaList = scoreCriteriaList; // utk table tooltip
        });
    }
  }

  public autoCompleteValueChangeSpesific(event: any, index: number): void {
    if (event !== null) {
      this.idListSpesific.push(event.id);
      this.customDataSpesific = {
        moduleScoringCode: this.moduleScoringCriteriaCode,
        idList: this.idListSpesific
      };
    }

    if (event !== null || event !== '') {
      const code = event.code;
      this.httpClientService
        .get<ScoringCriteria[]>(
          '/scoring-template/get-scoring-criteria-list/' + code
        )
        .subscribe(scoreCriteriaList => {
          this.inputScoringTemplateWeightListAndSpesific.controls[
            index
          ].value.scoringCriteriaGroup.scoringCriteriaList = scoreCriteriaList; // utk table tooltip
        });
    }
  }

  public countTotal(): void {
    let totalWeightCommon = 0;
    let totalWeightSpesific = 0;
    for (
      let i = 0;
      i <=
      this.formGroup.get('scoringTemplateWeightListAndCommon').value.length - 1;
      i++
    ) {
      if (
        this.formGroup.get('scoringTemplateWeightListAndCommon').value[i]
          .weight !== '' &&
        this.formGroup.get('scoringTemplateWeightListAndCommon').value[i]
          .weight !== null
      ) {
        totalWeightCommon +=
          parseFloat(
            this.formGroup.get('scoringTemplateWeightListAndCommon').value[i]
              .weight
          ) * 100;
      }
    }
    for (
      let i = 0;
      i <=
      this.formGroup.get('scoringTemplateWeightListAndSpesific').value.length -
        1;
      i++
    ) {
      if (
        this.formGroup.get('scoringTemplateWeightListAndSpesific').value[i]
          .weight !== '' &&
        this.formGroup.get('scoringTemplateWeightListAndSpesific').value[i]
          .weight !== null
      ) {
        totalWeightSpesific +=
          parseFloat(
            this.formGroup.get('scoringTemplateWeightListAndSpesific').value[i]
              .weight
          ) * 100;
      }
    }
    this.weightTotal = totalWeightCommon + totalWeightSpesific;
  }

  public onChangeWeight(): void {
    this.countTotal();
  }

  public doCancel(): void {
    this.router.navigate(['/pages/scoring-template/']);
  }

  public doRemoveCommon(index: number): void {
    this.inputScoringTemplateWeightListAndCommon.controls.forEach(
      (scoreTemplateWeight, i) => {
        if (
          (scoreTemplateWeight.value.scoringCriteriaGroup !== null ||
            scoreTemplateWeight.value.scoringCriteriaGroup !== '') &&
          index === i
        ) {
          // tslint:disable-next-line: no-shadowed-variable
          this.idListCommon.forEach((id, i) => {
            if (id === scoreTemplateWeight.value.scoringCriteriaGroup.id) {
              if (this.idListCommon.length > 1) {
                this.idListCommon.splice(i, 1);
              } else {
                this.idListCommon = [];
              }
            }
          });
        }
      }
    );

    this.inputScoringTemplateWeightListAndCommon.removeAt(index);
    this.isDisabled = false;
    const lastIndex = index - 1;
    this.inputScoringTemplateWeightListAndCommon.at(lastIndex);

    this.customDataCommon = {
      moduleScoringCode: this.moduleScoringCriteriaCode,
      idList: this.idListCommon
    };
  }

  public doRemoveSpesific(index: number): void {
    this.inputScoringTemplateWeightListAndSpesific.controls.forEach(
      (scoreTemplateWeight, i) => {
        if (
          (scoreTemplateWeight.value.scoringCriteriaGroup !== null ||
            scoreTemplateWeight.value.scoringCriteriaGroup !== '') &&
          index === i
        ) {
          // tslint:disable-next-line: no-shadowed-variable
          this.idListSpesific.forEach((id, i) => {
            if (id === scoreTemplateWeight.value.scoringCriteriaGroup.id) {
              if (this.idListSpesific.length > 1) {
                this.idListSpesific.splice(i, 1);
              } else {
                this.idListSpesific = [];
              }
            }
          });
        }
      }
    );

    this.inputScoringTemplateWeightListAndSpesific.removeAt(index);
    this.isDisabled = false;
    const lastIndex = index - 1;
    this.inputScoringTemplateWeightListAndSpesific.at(lastIndex);

    this.customDataSpesific = {
      moduleScoringCode: this.moduleScoringCriteriaCode,
      idList: this.idListSpesific
    };
  }

  public get formGroupControls(): any {
    return this.formGroup.controls;
  }
}
