import { AppFullfilledModule } from '../../core/components/app-fullfilled/app-fullfilled.module';
import { SlaCoreDetailComponent } from './sla-core-detail.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { SlaCoreComponent } from './sla-core.component';
import { AppSlaInfoModule } from 'src/app/core/components/app-sla-info/app-sla-info.module';
import { AppPopupAdjustmentCoreComponent } from './app-popup-adjustment-core.component';

export const routes = [
    { path: '', component: SlaCoreComponent, data: { breadcrumb: '' } },
    {
        path: 'detail',
        component: SlaCoreDetailComponent,
        data: { breadcrumb: 'sla-core.breadcrumb.detail' }
    },
    {
        path: 'app-popup-adjustment-vendor',
        component: AppPopupAdjustmentCoreComponent,
        data: { breadcrumb: 'sla-core.breadcrumb.adjustment' }
    }
];

@NgModule({
    imports: [SharedModule, RouterModule.forChild(routes), AppSlaInfoModule, AppFullfilledModule],
    declarations: [
        SlaCoreComponent,
        SlaCoreDetailComponent,
        AppPopupAdjustmentCoreComponent
    ],
    providers: []
})
export class SlaCoreModule { }
