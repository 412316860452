<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="vendor-document.title" [isLoading]="formLoading"
            [isSaving]="formSaving">
            <app-table [tableResponse]="tableResponse" [isServerSide]="true"
                stringUrl="/vendor-doc-master/vendor-type/index">
                <ng-template #actionButtons let-rowData>
                    <app-button size="TN" (onClick)="doEdit(rowData)"
                        title="{{ 'app.tooltip.edit' | translate }}">
                        <em class="fas fa-pencil-alt"></em>
                    </app-button>
                </ng-template>
            </app-table>
        </app-card>
    </div>
</div>