<app-popup header="app-popup-billing-memo-budget-detail.popup.budgetTitle">
  <form
    class="form-horizontal popup-biling-memo"
    [formGroup]="formGroup"
    novalidate
  >
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-popup-billing-memo-budget-detail.popup.form.fiscalYear' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          budgetAllocation.activityPeriod
            ? budgetAllocation.activityPeriod
            : '-'
        }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-popup-billing-memo-budget-detail.popup.form.organization'
          | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          budgetAllocation.organization
            ? budgetAllocation.organization.name
            : '-'
        }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-popup-billing-memo-budget-detail.popup.form.workProgram'
          | translate
      }}</label>
      <div class="col-sm-7 text-left">
        {{
          budgetAllocation.workProgram ? budgetAllocation.workProgram.name : '-'
        }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-popup-billing-memo-budget-detail.popup.form.budgetType' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          budgetAllocation.budgetType ? budgetAllocation.budgetType.name : '-'
        }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-popup-billing-memo-budget-detail.popup.form.costCenter' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          budgetAllocation.costCenter ? budgetAllocation.costCenter.name : '-'
        }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-popup-billing-memo-budget-detail.popup.form.branch' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          budgetAllocation.branchOffice
            ? budgetAllocation.branchOffice.code
            : '-'
        }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-popup-billing-memo-budget-detail.popup.form.coa' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ budgetAllocation.coa ? budgetAllocation.coa.code : '-' }}
      </div>
    </div>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
