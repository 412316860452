import { BaseEntity } from '../base/base-entity';
import { Attribute } from '../bean/attribute';
import { VendorAttributeGroup } from '../bean/vendor-attribute-group';
export class VendorAttributeEntity extends BaseEntity {
  fieldName: string;
  crudOperation: number;
  isEditableMandatory: boolean;
  isEditableApproval: boolean;
  vendorAttributeGroup: VendorAttributeGroup;
  attribute: Attribute;
  isMandatory: boolean;
  isApproval: boolean;
  isCertificate: boolean;
  sequence: number;
}
