import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { Payment } from '../bean/payment';

export class PaymentInvoiceEntity extends BaseEntity {
  payment: Payment;
  docFile: File;
  number: string;
  amount: number;
  invoiceDate: Date;
  description: string;
}
