import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { BudgetAllocation } from '../../core/bean/budget-allocation';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './budget-allocation-organization.component.html'
})
export class BudgetAllocationOrganizationComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;

  public organizationId: number;
  public activityPeriod: number;
  public tableResponse: TableResponseModel<BudgetAllocation>;

  constructor(translateService: TranslateService) {
    super('budget-allocation', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setCustomData();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.organizationId = this.global.routerParams.get('organizationId');
    this.activityPeriod = this.global.routerParams.get('activityPeriod');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      organizationId: this.organizationId,
      activityPeriod: this.activityPeriod
    });
  }

  public setCustomData(): void {
    this.formGroup.patchValue({
      organizationId: this.organizationId,
      activityPeriod: this.activityPeriod
    });
    this.tableResponse.setCustomData(this.formGroup.value);
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'workProgram.name', header: 'table.column.workProgram' },
      {
        field: 'costCenter.code',
        header: 'table.column.costCenterCode',
        customClass: 'text-center'
      },
      { field: 'costCenter.name', header: 'table.column.costCenterName' },
      {
        field: 'coa.code',
        header: 'table.column.coaCode',
        customClass: 'text-center'
      },
      { field: 'coa.name', header: 'table.column.coaName' },
      {
        field: 'budgetType.name',
        header: 'table.column.budgetType',
        customClass: 'text-center'
      },
      {
        field: 'branchOffice.name',
        header: 'table.column.branchOffice',
        customClass: 'text-center'
      },
      {
        field: 'activityPeriod',
        header: 'table.column.activityPeriod',
        customClass: 'text-center'
      },
      {
        field: 'totalBudget',
        header: 'table.column.totalBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'bookedBudget',
        header: 'table.column.bookedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'usedBudget',
        header: 'table.column.usedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'paidOffBudget',
        header: 'table.column.paidOffBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'availableBudget',
        header: 'table.column.availableBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.set('todo', 'add');
    this.global.routerParams.set(
      'backUrl',
      '/pages/budget-allocation/organization/detail/index'
    );
    this.router.navigate(['/pages/budget-allocation/add']);
  }

  public doEdit(budget: BudgetAllocation): void {
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('budgetId', budget.id);
    this.global.routerParams.set(
      'backUrl',
      '/pages/budget-allocation/organization/detail/index'
    );
    this.router.navigate(['/pages/budget-allocation/edit']);
  }

  public doDelete(budget: BudgetAllocation): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<BudgetAllocation>>(
              '/budget-allocation/delete',
              budget
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
  public doCancel(): void {
    this.router.navigate(['/pages/budget-allocation']);
  }
}
