import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetContentSliderModule } from '../../widgets/app-widget-content-slider/app-widget-content-slider.module';
import { AppPopupModule } from '../app-popup/app-popup.module';
import { AppWorkflowInfoModule } from '../app-workflow-info/app-workflow-info.module';
import { AppButtonModule } from './../app-button/app-button.module';
import { AppOrganizationRkInfoItemCardComponent } from './app-organization-rk-info-item-card.component';
import { AppOrganizationRkInfoPopupComponent } from './app-organization-rk-info-popup.component';
import { AppOrganizationRkInfoComponent } from './app-organization-rk-info.component';
@NgModule({
  imports: [
    VendorModule,
    AppWorkflowInfoModule,
    AppPopupModule,
    AppWidgetContentSliderModule,
    AppButtonModule
  ],
  declarations: [
    AppOrganizationRkInfoComponent,
    AppOrganizationRkInfoPopupComponent,
    AppOrganizationRkInfoItemCardComponent
  ],
  entryComponents: [AppOrganizationRkInfoPopupComponent],
  exports: [AppOrganizationRkInfoComponent, AppOrganizationRkInfoPopupComponent]
})
export class AppOrganizationRKInfoModule {}
