import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppApprovalPrcsModule } from 'src/app/core/components/app-approval-prcs/app-approval-prcs.module';
import { AppFinesModule } from 'src/app/core/components/app-fines/app-fines.module';
import { AppGuaranteeModule } from 'src/app/core/components/app-guarantee/app-guarantee.module';
import { AppItemRequestReleasedModule } from 'src/app/core/components/app-item-request-released/app-item-request-released.module';
import { AppProcurementInformationViewModule } from 'src/app/core/components/app-procurement-information-view/app-procurement-information-view.module';
import { AppSowModule } from 'src/app/core/components/app-sow/app-sow.module';
import { AppVendorPaymentInformationModule } from 'src/app/core/components/app-vendor-payment-information/app-vendor-payment-information.module';
import { AppWorkflowProgressInfoModule } from 'src/app/core/components/app-workflow-progress-info/app-workflow-progress-info.module';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AppApprovalLogModule } from '../../core/components/app-approval-log/app-approval-log.module';
import { AppPopupContractRequestCancelContractComponent } from './app-popup-contract-request-cancel-contract.component';
import { AppPopupContractRequestChooseContractComponent } from './app-popup-contract-request-choose-contract.component';
import { AppPopupContractRequestChooseProcurementForContractComponent } from './app-popup-contract-request-choose-procurement-for-contract.component';
import { AppPopupContractRequestChooseProcurementComponent } from './app-popup-contract-request-choose-procurement.component';
import { AppPopupContractRequestSplitItemComponent } from './app-popup-contract-request-split-item.component';
import { ContractRequestEditAddXComponent } from './contract-request-edit-add-x.component';
import { ContractRequestEditAddComponent } from './contract-request-edit-add.component';
import { ContractRequestComponent } from './contract-request.component';

export const routes = [
  { path: '', component: ContractRequestComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: ContractRequestEditAddXComponent,
    data: { breadcrumb: 'contract-request.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: ContractRequestEditAddXComponent,
    data: { breadcrumb: 'contract-request.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: ContractRequestEditAddXComponent,
    data: { breadcrumb: 'contract-request.breadcrumb.view' }
  },
  {
    path: 'cancel',
    component: ContractRequestEditAddXComponent,
    data: { breadcrumb: 'contract-request.breadcrumb.cancel' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: ContractRequestEditAddXComponent,
    data: { breadcrumb: 'contract-request.breadcrumb.view' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppProcurementInformationViewModule,
    AppApprovalLogModule,
    AppApprovalPrcsModule,
    AppWorkflowProgressInfoModule,
    AppItemRequestReleasedModule,
    AppSowModule,
    AppFinesModule,
    AppGuaranteeModule,
    AppApprovalPrcsXModule,
    AppVendorPaymentInformationModule
  ],
  declarations: [
    ContractRequestComponent,
    ContractRequestEditAddComponent,
    ContractRequestEditAddXComponent,
    AppPopupContractRequestChooseProcurementComponent,
    AppPopupContractRequestChooseContractComponent,
    AppPopupContractRequestChooseProcurementForContractComponent,
    AppPopupContractRequestSplitItemComponent,
    AppPopupContractRequestCancelContractComponent
  ],
  entryComponents: [
    AppPopupContractRequestChooseProcurementComponent,
    AppPopupContractRequestChooseContractComponent,
    AppPopupContractRequestChooseProcurementForContractComponent,
    AppPopupContractRequestSplitItemComponent,
    AppPopupContractRequestCancelContractComponent
  ],
  exports: [
    AppPopupContractRequestChooseProcurementComponent,
    AppPopupContractRequestChooseContractComponent,
    AppPopupContractRequestChooseProcurementForContractComponent,
    AppPopupContractRequestSplitItemComponent,
    AppPopupContractRequestCancelContractComponent
  ]
})
export class ContractRequestModule {}
