import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  selector: 'app-badge-catalog',
  templateUrl: './app-badge-catalog.component.html',
})
export class AppBadgeCatalogComponent extends BaseComponentComponent {
  @Input() public color = 'INFO';

  constructor(public translate: TranslateService) {
    super('app-badge-catalog');
  }

  public onInit(): void {
    this.log.debug('');
  }
}
