<div
  class="widget-card"
  [ngClass]="organizationPlanInfoModel.attentionOrganizationBudget"
>
  <div class="widget-header">
    <em class="fas fa-building"></em>
    <h5
      class="m-0"
      (click)="onClick.emit(organizationPlanInfoModel.organization)"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    >
      {{ organizationPlanInfoModel.organization.name }}
    </h5>
  </div>
  <div class="widget-content mt-2 mr-3">
    <div class="content-text">
      <div class="content-text-left">
        {{ 'app-organization-plan-info.workPlan' | translate }}
      </div>
      <div class="content-text-right">
        {{ organizationPlanInfoModel.totalWorkPlan || 0 }}/
        {{ organizationPlanInfoModel.totalRealizedWorkPlan || 0 }}
      </div>
    </div>
    <div class="content-text">
      <div class="content-text-left">
        {{ 'app-organization-plan-info.absorption' | translate }}
      </div>
      <div class="content-text-right">
        {{ organizationPlanInfoModel.realizedBudgetPercentage || 0 }}%
      </div>
    </div>
    <div class="content-text justify-content-end">
      <div class="content-text-right">
        {{
          global.converterService.convertToShortMoney(
            organizationPlanInfoModel.realizedBudget || 0
          )
        }}/
        {{
          global.converterService.convertToShortMoney(
            organizationPlanInfoModel.totalBudget || 0
          )
        }}
      </div>
    </div>
  </div>
</div>
