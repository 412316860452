import { ElementRef } from '@angular/core';
import { Action } from '../../../interfaces/action';
import { TableFieldModel } from '../model/table-field-model';
import { TableRecord } from '../model/table-record';

// TODO Make this constant standard. Do it later
export const FIRST_LOAD_TABLE = `[ TABLE ]: First Load Table`;
export const CHANGE_FILTER_TABLE_PER_PAGE = `[ Table ]: Change Filter Table Per Page`;
export const CHANGE_TABLE_PAGE = `[ TABLE ]: Change Table Page`;
export const SEARCH_TABLE = `[ TABLE ]: Search Table`;
export const RELOAD_TABLE = `[ Table ]: Reload Table`;
export const SORT_TABLE = `[ TABLE ]: Sort Table`;
export const LOAD_TABLE_RECORD_CHILDREN = `[ TABLE ]: Load Table Record Children`;
// addtional
export const DELETE_TABLE_RECORD = `[ Table ]: Delete Table Record`;
export const SELECT_ALL_TABLE_RECORD = `[ Table ]: Select All Table Record`;
export const SELECT_TABLE_RECORD = `[ Table ]: Select Table Record`;
export const EXPAND_OR_COLLAPSE_TABLE_ROW = `[ TABLE ]: Expand Or Collapse Table Row`;
export const EXPAND_OR_COLLAPSE_ALL_TABLE_ROW = `[ TABLE ]: Expand Or Collapse All Table Row`;
export const SET_STICKY_TABLE_HEADER = `[ TABLE ]: Set Sticky Table Header`;

export class FirstLoadTable implements Action<any> {
  readonly type = FIRST_LOAD_TABLE;
}

export class ChangeFilterTablePerPage implements Action<{ perPage: number }> {
  readonly type = CHANGE_FILTER_TABLE_PER_PAGE;
  constructor(public payload: { perPage: number }) {}
}

export class ChangeTablePage implements Action<{ page: number }> {
  readonly type = CHANGE_TABLE_PAGE;
  constructor(public payload: { page: number }) {}
}

export class SearchTable implements Action<{ keywords: string }> {
  readonly type = SEARCH_TABLE;
  constructor(public payload: { keywords: string }) {}
}

export class ReloadTable implements Action<void> {
  readonly type = RELOAD_TABLE;
}

export class SortTable implements Action<{ column: TableFieldModel }> {
  readonly type = SORT_TABLE;
  constructor(public payload: { column: TableFieldModel }) {}
}

export class DeleteTableRecord implements Action<void> {
  readonly type = DELETE_TABLE_RECORD;
}

export class SelectAllTableRecord implements Action<{ isChecked: boolean }> {
  readonly type = SELECT_ALL_TABLE_RECORD;
  constructor(public payload: { isChecked: boolean }) {}
}

export class SelectTableRecord
  implements
    Action<{
      record: TableRecord;
      isChecked: boolean;
      parentRecord?: TableRecord;
    }>
{
  readonly type = SELECT_TABLE_RECORD;
  constructor(
    public payload: {
      record: TableRecord;
      isChecked: boolean;
      parentRecord?: TableRecord;
    }
  ) {}
}

export class LoadTableRecordChildrens
  implements Action<{ record: TableRecord }>
{
  readonly type = LOAD_TABLE_RECORD_CHILDREN;
  constructor(public payload: { record: TableRecord }) {}
}

export class ExpandOrCollapseTableRow
  implements Action<{ record?: TableRecord }>
{
  readonly type = EXPAND_OR_COLLAPSE_TABLE_ROW;
  constructor(public payload: { record: TableRecord }) {}
}

export class ExpandOrCollapseAllTableRow
  implements Action<{ action: 'EXPAND' | 'COLLAPSE' }>
{
  readonly type = EXPAND_OR_COLLAPSE_ALL_TABLE_ROW;
  constructor(public payload: { action: 'EXPAND' | 'COLLAPSE' }) {}
}

export class SetStickyTableHeader implements Action<any> {
  readonly type = SET_STICKY_TABLE_HEADER;
  constructor(
    public payload: {
      headerFixedElementRef: ElementRef;
      headerTempElementRef: ElementRef;
    }
  ) {}
}

export type AllTableAction =
  | FirstLoadTable
  | SearchTable
  | SortTable
  | ChangeTablePage
  | SetStickyTableHeader
  | DeleteTableRecord
  | SelectTableRecord
  | SelectAllTableRecord
  | ChangeFilterTablePerPage
  | ExpandOrCollapseTableRow
  | ExpandOrCollapseAllTableRow
  | LoadTableRecordChildrens
  | ReloadTable;
