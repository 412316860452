import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAlertXModule } from '../app-alert-x/app-alert-x.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppLabelOptionalModule } from '../app-label-optional/app-label-optional.module';
import { AppPopupModule } from '../app-popup/app-popup.module';
import { AppSelectModule } from '../app-select/app-select.module';
import { AppTextAreaModule } from '../app-text-area/app-text-area.module';
import { AppTooltipModule } from '../app-tooltip/app-tooltip.module';
import { TableModule } from '../table/table.module';
import { AppUploadXModule } from '../upload/components/app-upload-x/app-upload-x.module';
import { AppContractDocumentComponent } from './app-contract-document.component';
import { AppPopupAddContractDocumentComponent } from './app-popup-add-contract-document.component';
import { AppPopupReviewerContractDocumentComponent } from './app-popup-reviewer-contract-document.component';

@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppButtonModule,
    AppSelectModule,
    DirectivesModule,
    AppCardModule,
    AppUploadXModule,
    TableModule,
    AppTextAreaModule,
    AppTooltipModule,
    PipesModule,
    AppLabelOptionalModule,
    AppAlertXModule
  ],
  declarations: [
    AppContractDocumentComponent,
    AppPopupAddContractDocumentComponent,
    AppPopupReviewerContractDocumentComponent
  ],
  entryComponents: [
    AppContractDocumentComponent,
    AppPopupAddContractDocumentComponent,
    AppPopupReviewerContractDocumentComponent
  ],
  exports: [
    AppContractDocumentComponent,
    AppPopupAddContractDocumentComponent,
    AppPopupReviewerContractDocumentComponent
  ]
})
export class AppContractDocumentModule {}
