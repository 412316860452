import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';
import { ShareholderType } from '../../../core/bean/shareholder-type';
import { VendorCompanyOrganization } from '../../../core/bean/vendor-company-organization';
import { VendorCompanyOrganizationHistory } from '../../../core/bean/vendor-company-organization-history';
import { VendorPosition } from '../../../core/bean/vendor-position';
import { FileUploader } from '../../../core/components/upload';
import { OptionListModel } from '../../../core/model/option-list-model';
import { Validators } from '../../../core/validators';
import { VendorPopupVendorCompanyOrganizationDTO } from '../dto/vendor-popup-vendor-company-organization.dto';

@Component({
  templateUrl: './app-popup-company-organization.component.html'
})
export class AppPopupCompanyOrganizationComponent extends BaseComponentComponent {
  @Input() public header: string;
  @Input()
  public vendorCompanyOrganizationHistoryEdit: VendorCompanyOrganizationHistory;
  @Input()
  public companyOrganizationShareHolderHistoryList: VendorCompanyOrganizationHistory[];
  @Input()
  public companyOrganizationShareHolderList: VendorCompanyOrganization[];
  @Input() public companyOrganizationShareHolder: VendorCompanyOrganization;
  @Input() public type: number; // 1. Commisioner 2. Director 3. Share Holders
  @Input() public vendorCompanyOrganizationId: number;
  @Input() public currentTotalShareValue: number;
  @Input() public idNumberList: string[];
  @Input() public todo: string;
  @Input() public vendorId: number;
  @Input() public companyOrganizationIdList: number[];

  public isExceedMaxTotalShareValue = false;
  public isVendorList: boolean;
  public fromOutside: string;
  public birthDateHistory: string;
  public birthDateActual: string;

  public fileUploader: FileUploader = new FileUploader(
    '/tab-profile-company-organization/',
    'companyOrganization',
    this.global.appConstant.fileType.DOC_IDENTITY
  );

  public vendorPositionOptionList: OptionListModel<VendorPosition> =
    new OptionListModel(true);
  public shareHolderTypeOptionList: OptionListModel<ShareholderType> =
    new OptionListModel(true);

  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public datePipe: DatePipe, public activeModal: NgbActiveModal) {
    super('tab-profile-company-organization');
  }

  public onInit(): void {
    this.getCompanyOrganizationShareHolder();
    this.setParam();
    this.buildFormGroup();
    this.setFormGroup();
    if (
      this.todo === 'detailCommissioner' ||
      this.todo === 'detailDirector' ||
      this.todo === 'detailShareholder'
    ) {
      this.setViewOnly();
    }
  }

  public setParam(): void {
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
  }

  public buildFormGroup(): void {
    if (this.type === 2) {
      this.formGroup = this.formBuilder.group({
        name: [null, Validators.required()],
        idNumber: [
          null,
          {
            validators: [
              Validators.required() /*,
                            Validators.exists(this.idNumberList)*/
            ] /*,
            asyncValidators: [
              Validators.existsAsync(
                '/tab-profile-company-organization/check-vendor-company-organization-exist',
                null,
                null,
                { id: this.vendorCompanyOrganizationId }
              )
            ]*/
          }
        ],
        vendorPosition: [null, Validators.required()],
        birthDate: [null],
        shareholderType: [null],
        shareValue: [null],
        shareValueWithPercent: [null],
        companyOrganizationTittle: [null],
        imgFile: [null, Validators.required()],
        fileUploader: [null],
        vendorCompanyOrganizationId: [null],
        currentDate: new Date()
      });
    } else if (this.type === 1) {
      this.formGroup = this.formBuilder.group({
        name: [null, Validators.required()],
        idNumber: [
          null,
          Validators.compose([
            Validators.required(),
            Validators.exists(this.idNumberList)
          ])
        ],
        vendorPosition: [null, Validators.required()],
        birthDate: [null],
        shareholderType: [null],
        shareValue: [null],
        shareValueWithPercent: [null],
        companyOrganizationTittle: [null],
        imgFile: [null, Validators.required()],
        fileUploader: [null],
        currentDate: new Date(),
        vendorCompanyOrganizationId: [null]
      });
    } else {
      this.formGroup = this.formBuilder.group({
        name: [null, Validators.required()],
        idNumber: [
          null,
          Validators.compose([
            Validators.required(),
            Validators.exists(this.idNumberList)
          ])
        ],
        birthDate: [null],
        shareholderType: [null, Validators.required()],
        shareValue: [
          null,
          Validators.compose([
            Validators.required(),
            Validators.max(100),
            Validators.min(1)
          ])
        ],
        shareValueWithPercent: [null],
        companyOrganizationTittle: [null],
        currentDate: new Date(),
        vendorCompanyOrganizationId: [null]
      });
    }
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<VendorPopupVendorCompanyOrganizationDTO>(
        '/tab-profile-company-organization/add-edit-popup-vendor-company-organization',
        this.type
      )
      .subscribe(vendorPopupVendorCompanyOrganizationDTO => {
        this.vendorPositionOptionList.setRequestValues(
          vendorPopupVendorCompanyOrganizationDTO.vendorPositionList
        );
        if (this.type === 3) {
          this.shareHolderTypeOptionList.setRequestValues(
            vendorPopupVendorCompanyOrganizationDTO.shareholderTypeList
          );
        }
        if (this.vendorCompanyOrganizationHistoryEdit !== undefined) {
          if (this.vendorCompanyOrganizationHistoryEdit.birthDate !== null) {
            this.vendorCompanyOrganizationHistoryEdit.birthDate = new Date(
              this.vendorCompanyOrganizationHistoryEdit.birthDate
            );
          }
          this.formGroup.patchValue(this.vendorCompanyOrganizationHistoryEdit);
          this.fileUploader.setFile(
            this.vendorCompanyOrganizationHistoryEdit.file
          );
          if (
            this.vendorCompanyOrganizationHistoryEdit.fileObjectList &&
            this.vendorCompanyOrganizationHistoryEdit.fileObjectList.length !==
              0
          ) {
            this.fileUploader.fileObjectList =
              this.vendorCompanyOrganizationHistoryEdit.fileObjectList;
          }
          this.formGroup.patchValue({
            fileUploader: this.fileUploader,
            imgFile: this.fileUploader.fileObjectList
          });
        }
        this.setStateReady();
      });
  }

  public getCompanyOrganizationShareHolder(): void {
    if (this.companyOrganizationShareHolderList) {
      this.companyOrganizationShareHolderHistoryList.forEach(
        shareHolderHistory => {
          if (
            shareHolderHistory === this.vendorCompanyOrganizationHistoryEdit
          ) {
            this.companyOrganizationShareHolderList.forEach(shareHolder => {
              if (
                shareHolder.id ===
                shareHolderHistory.vendorCompanyOrganizationId
              ) {
                this.companyOrganizationShareHolder = shareHolder;
                if (this.companyOrganizationShareHolder.birthDate !== null) {
                  this.birthDateActual = this.datePipe
                    .transform(
                      new Date(this.companyOrganizationShareHolder.birthDate),
                      this.global.config.parameterModel.dateFormatShort
                    )
                    .toString();
                }
              }
            });
          }
        }
      );
      if (this.companyOrganizationShareHolder === undefined) {
        this.companyOrganizationShareHolder = new VendorCompanyOrganization();
      }
    }
    if (this.vendorCompanyOrganizationHistoryEdit !== undefined) {
      this.birthDateHistory =
        this.type === 3 &&
        this.vendorCompanyOrganizationHistoryEdit.birthDate !== null
          ? this.datePipe
              .transform(
                new Date(this.vendorCompanyOrganizationHistoryEdit.birthDate),
                this.global.config.parameterModel.dateFormatShort
              )
              .toString()
          : null;
    }
  }

  public doSave(): void {
    this.validate();
    if (this.type === 3) {
      this.isExceedMaxTotalShareValue = false;
      this.currentTotalShareValue *= 100;
      if (!this.vendorCompanyOrganizationHistoryEdit) {
        this.currentTotalShareValue +=
          parseFloat(this.formGroup.get('shareValue').value) * 100;
      } else if (
        this.vendorCompanyOrganizationHistoryEdit.shareValue !== null
      ) {
        this.currentTotalShareValue -=
          parseFloat(this.vendorCompanyOrganizationHistoryEdit.shareValue) *
          100;
        this.currentTotalShareValue +=
          parseFloat(this.formGroup.get('shareValue').value) * 100;
      }
      this.currentTotalShareValue /= 100;
    }

    if (this.type !== 3 && this.formGroup.valid) {
      this.onChange.emit(this.formGroup.value);
      this.activeModal.dismiss();
    } else if (
      this.type === 3 &&
      this.formGroup.valid &&
      this.currentTotalShareValue <= 100
    ) {
      this.onChange.emit(this.formGroup.value);
      this.activeModal.dismiss();
    } else if (this.type === 3 && this.currentTotalShareValue > 100) {
      this.currentTotalShareValue *= 100;
      this.currentTotalShareValue -=
        parseFloat(this.formGroup.get('shareValue').value) * 100;
      this.currentTotalShareValue /= 100;
      this.isExceedMaxTotalShareValue = true;
      this.formGroup.get('shareValue').reset(); // to remove green border
      this.formGroup.get('shareValue').markAsPristine(); // to remove overlapped error message
    }
  }
}
