/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */

export class AppBuildModel {
    public svnRevision: string;
    public buildId: string;
    public buildDate: string;
}
