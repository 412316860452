import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Contract } from 'src/app/core/bean/contract';
import { ContractFileHistory } from 'src/app/core/bean/contract-file-history';
import { ContractNegotiation } from 'src/app/core/bean/contract-negotiation';
import { FileUploader } from 'src/app/core/components/upload';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';
import { ContractMonitoringResponse } from './contract-monitoring.response';
import { ContractMonitoringRequest } from './contract-monitoring.request';

@Component({
  templateUrl: './app-popup-contract-monitoring-signed-document.component.html'
})
export class AppPopupContractMonitoringSignedDocumentComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() contract: Contract;
  @Input() contractFileHistory: ContractFileHistory;
  @Input() contractMonitoringResponse: ContractMonitoringResponse;
  @Output() public onChange: EventEmitter<ContractNegotiation> =
    new EventEmitter();
  public contractMonitoringRequest: ContractMonitoringRequest =
    new ContractMonitoringRequest();
  public fileUploader: FileUploader = new FileUploader(
    '/contract-monitoring/',
    '',
    this.global.appConstant.fileType.DOC_CONTRACT
  );

  constructor() {
    super('contract-monitoring');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      contract: [null],
      contractFile: [null, Validators.required()],
      description: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(512)])
      ]
    });
  }

  public setFormGroup(): void {
    if (this.contractFileHistory != null) {
      this.fileUploader.setFile(this.contractFileHistory.file);
      this.formGroup.patchValue({
        id: this.contractFileHistory.id,
        contract: this.contract,
        contractFile: this.fileUploader.fileObjectList,
        description: this.contractFileHistory.description
      });
    }
  }

  public doSave(): void {
    this.validate();
    this.formGroup.patchValue({
      contract: this.contract
    });
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.contractMonitoringRequest.contractFileHistory =
              this.formGroup.value;
            if (
              this.formGroup.value.contractFile[0].isChange ||
              this.formGroup.value.contractFile[0].isNew
            ) {
              this.contractMonitoringRequest.fileObjectList =
                this.formGroup.value.contractFile;
            }
            this.httpClientService
              .post<Response<ContractFileHistory>>(
                '/contract-monitoring/update',
                this.contractMonitoringRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.onChange.emit(this.formGroup.value);
                  this.global.alertService.showSuccess(
                    this.global.translateService.instant(
                      'app.alert.msg.saveSuccess'
                    )
                  );
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }
}
