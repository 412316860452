import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppApprovalLogModule } from 'src/app/core/components/app-approval-log/app-approval-log.module';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ItemEditAddXtComponent } from './item-edit-add-xt.component';
import { ItemEditAddComponent } from './item-edit-add.component';
import { ItemComponent } from './item.component';
const routes: Routes = [
  { path: '', component: ItemComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: ItemEditAddXtComponent,
    data: { todo: 'edit', breadcrumb: 'item.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: ItemEditAddXtComponent,
    data: { todo: 'add', breadcrumb: 'item.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: ItemEditAddXtComponent,
    data: { todo: 'view', breadcrumb: 'item.breadcrumb.detail' }
  },
  {
    path: 'detail/:token',
    component: ItemEditAddXtComponent,
    data: { todo: 'view', breadcrumb: 'item.breadcrumb.detail' }
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes),
    AppApprovalPrcsXModule,
    AppApprovalLogModule],
  declarations: [ItemComponent, ItemEditAddComponent, ItemEditAddXtComponent]
})
export class ItemModule {}
