<div class="promise-portal promise-portal-outer">
  <promise-portal-header></promise-portal-header>
  <ng-container
    *ngTemplateOutlet="isOffline ? offlineTemplate : onlineTemplate"
  ></ng-container>
  <ng-template #offlineTemplate>
    <div class="offline-wrapper">
      <div
        class="pvc pv-sattelite-antenna mx-auto"
        style="width: 300px; height: 300px"
      ></div>
      <h2 class="lpt-2">Connection Lost</h2>
      <h5 class="fw-400">Please check your internet connection</h5>
    </div>
  </ng-template>
  <ng-template #onlineTemplate>
    <ng-content></ng-content>
  </ng-template>
</div>
