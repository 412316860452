<app-popup [isShowHeaderCloseButton]="false">
  <div class="text-center"
    ><br />
    <h3>{{
      'order.confirm.title.areYouSureToCancelThisProcess' | translate
    }}</h3
    ><br />
    <div
      innerHTML="{{
        'order.confirm.msg.cancelCannotContinueAnymore' | translate
      }}"
    ></div
    ><br />
  </div>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="row text-sm-left">
      <div class="col-sm-8 text-left">
        <p>{{
          'order.confirm.msg.pleaseFulfillTheCancelReason' | translate
        }}</p>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <div class="col-sm-12 text-left">
        <app-text-area size="XL" formControlName="note" maxLength="512">
        </app-text-area>
      </div>
    </div>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          minWidth="130px"
          (onClick)="activeModal.close(false)"
        >
          {{ 'order.button.no' | translate }}
        </app-button>
        <app-button (onClick)="doSubmit()" minWidth="130px">
          {{ 'order.button.yesImSure' | translate }}
        </app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
