import { Injectable } from '@angular/core';
import { Global } from '../global/global';
import { ServiceLocator } from './service-locator';
declare let AesUtil: any;

@Injectable({
  providedIn: 'root'
})
export class EncryptService {
  constructor() {}

  public encrypt(paramList: string[]): string {
    const global = ServiceLocator.injector.get(Global);

    const aesUtil = new AesUtil();
    const s = new Date().getSeconds();
    const m = new Date().getMinutes();
    const h = new Date().getHours();
    const separator = global.appConstant.core.LOGIN_SEPARATOR_KEY;

    let value = s.toString();
    paramList.forEach((param, i) => {
      switch (i % 3) {
        case 0:
          value += separator + param + separator + m;
          break;

        case 1:
          value += separator + param + separator + h;
          break;

        case 2:
          value += separator + param + separator + s;
          break;

        default:
          break;
      }
    });

    const encryptedValue = aesUtil.encrypt(
      global.appConstant.core.LOGIN_SALT_KEY,
      global.appConstant.core.LOGIN_IV_KEY,
      global.appConstant.core.LOGIN_PASSPHRASE_KEY,
      value
    );

    return encryptedValue;
  }
}
