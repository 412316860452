import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartType } from 'chart.js';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { OrderType } from '../../bean/order-type';
import { ChartModel } from '../../components/app-chart/models/chart-model';
import { OptionListModel } from '../../model/option-list-model';
import { WidgetNumberOfOrderRequest } from './model/widget-number-of-order-request';
import { WidgetNumberOfOrderResponse } from './model/widget-number-of-order-response';
import Chart = require('chart.js');
@Component({
  selector: 'app-widget-number-of-order',
  templateUrl: './app-widget-number-of-order.component.html',
  styleUrls: ['./app-widget-number-of-order.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetNumberOfOrderComponent extends BaseComponentComponent {
  public chartModel: ChartModel;
  public widgetNumberOfOrderRequest: WidgetNumberOfOrderRequest =
    new WidgetNumberOfOrderRequest();
  public orderTypeOptionList: OptionListModel<OrderType> = new OptionListModel(
    true
  );
  public yearOptionList: OptionListModel<number> = new OptionListModel(true);

  constructor(public translateService: TranslateService) {
    super('app-widget-number-of-order');
  }

  public onInit(): void {
    this.buildSetFormGroup();
    this.setStateChartModel();
  }

  public buildSetFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      orderTypeList: [null],
      year: [null]
    });

    const currentYear = new Date().getFullYear();
    this.yearOptionList.setRequestValues(
      Array(9)
        .fill(0)
        .map((fill: number, index: number) => currentYear - 5 + fill + index)
    );

    this.httpClientService
      .get<OrderType[]>('/widget-number-of-order/get-option-list', {})
      .subscribe(response => {
        this.orderTypeOptionList.setRequestValues(response);
      });
  }

  public setStateChartModel(): void {
    this.httpClientService
      .post<WidgetNumberOfOrderResponse>(
        '/widget-number-of-order/index',
        this.widgetNumberOfOrderRequest
      )
      .subscribe((response: WidgetNumberOfOrderResponse) => {
        if (response) {
          let labels = [];
          const dataSetLabels = [];
          const dataSets = [];
          response.widgetNumberOfOrderModelResponseList.forEach(
            widgetNumberOfOrderModelResponse => {
              if (labels.length === 0) {
                labels = [...widgetNumberOfOrderModelResponse.data].map(
                  data => data.month
                );
              }
              dataSets.push({
                data: [...widgetNumberOfOrderModelResponse.data].map(
                  data => data.totalOrder
                )
              });
              dataSetLabels.push(
                this.translateService.instant(
                  'app-widget-number-of-order.' +
                    widgetNumberOfOrderModelResponse.label
                      .toLowerCase()
                      .replace(/\s/g, '')
                      .trim()
                )
              );
            }
          );
          this.chartModel = new ChartModel(
            'line',
            labels,
            dataSets,
            dataSetLabels,
            {
              legend: {
                display: false
              },
              defaultColor: ['#204d95 ', '#14b1ab'],
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      stepSize: 10,
                      max: 100
                    }
                  }
                ],
                xAxes: [
                  {
                    gridLines: {
                      display: false
                    }
                  }
                ]
              },
              tooltips: {
                mode: 'nearest',
                xPadding: 20,
                yPadding: 20,
                titleFontStyle: 'font-weight: normal; padding: 20px',
                bodyFontStyle: 'font-weight: bold',
                footerFontStyle: 'font-weight: normal',
                callbacks: {
                  title: () => 'Total Order Process',
                  label: () => null,
                  footer: (item: Chart.ChartTooltipItem[]): string => {
                    const totalGrowth =
                      response.widgetNumberOfOrderModelResponseList[
                        item[0].datasetIndex
                      ].data[item[0].index].totalGrowth;
                    return totalGrowth
                      ? `${totalGrowth} from last month`
                      : null;
                  },
                  afterBody: (item: Chart.ChartTooltipItem[]): string => {
                    return `${
                      response.widgetNumberOfOrderModelResponseList[
                        item[0].datasetIndex
                      ].data[item[0].index].totalOrder
                    }`;
                  }
                }
              }
            }
          );
        }
        this.setStateReady();
      });
  }

  public changeType(type: ChartType): void {
    this.chartModel.setType(type);
  }

  public onChangeFilter(): void {
    this.widgetNumberOfOrderRequest = this.formGroup.value;
    this.setStateChartModel();
  }
}
