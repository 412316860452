import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Module } from '../../core/bean/module';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { ModuleGroup } from './../../core/bean/module-group';
@Component({
  templateUrl: './number-generator.component.html'
})
export class NumberGeneratorComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Module>;
  public moduleGroupList: ModuleGroup[];
  constructor(translateService: TranslateService) {
    super('number-generator', translateService);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.doSetModuleGroupList();
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doSetModuleGroupList(): void {
    this.httpClientService.get<ModuleGroup[]>('/number-generator/module-group-list', {}).subscribe(moduleGroupList => {
      this.moduleGroupList = moduleGroupList;
    });
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({ moduleGroup: [null] });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'moduleGroupCode', header: 'table.column.moduleGroup', customClass: 'text-center' },
      { field: 'name', header: 'table.column.name', customClass: 'white-space-normal' },
      { field: 'description', header: 'table.column.description', customClass: 'white-space-normal' }
    ]);
  }

  public doView(module: Module): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('module', module);
    this.router.navigate(['/pages/number-generator/detail/']);
  }

  public doOnChangeModuleGroup(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }
}
