import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { BudgetAllocation } from 'src/app/core/bean/budget-allocation';
import { BudgetType } from 'src/app/core/bean/budget-type';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Coa } from '../../../bean/coa';
import { BudgetItemView } from '../../../view/entity/bean/budget-item-view';
import { TableResponseModel } from '../../table/model/table-response-model';
import { AppPopupAddBudgetItemCustomDataResponse } from './app-popup-add-budget-item-custom-data-response';
import { AppPopupAddBudgetItemModel } from './app-popup-add-budget-item.model';

@Component({
  templateUrl: './app-popup-add-budget-item.component.html'
})
export class AppPopupAddBudgetItemComponent extends BaseModuleComponent {
  @Input() budgetItemViewList: BudgetItemView[] = [];
  @Input() paymentId: number;
  @Input() paymentBillingIdList: number[] = [];
  @Output()
  onChange: EventEmitter<BudgetItemView[]> = new EventEmitter();

  public tableResponse: TableResponseModel<BudgetItemView>;
  public activityPeriodOptionList: OptionListModel<number> =
    new OptionListModel(false);
  public budgetTypeOptionList: OptionListModel<BudgetType> =
    new OptionListModel(false);
  public appPopupChooseBudgetModel: AppPopupAddBudgetItemModel =
    new AppPopupAddBudgetItemModel();
  public errorMapper: Map<number, string> = new Map();
  public budgetAllocationTempList: BudgetAllocation[] = [];
  public isBudgetChecked = false;
  public isShowFilter = false;

  constructor(translateService: TranslateService) {
    super('app-popup-add-budget-item', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.getOptionList();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      activityPeriod: [null],
      organization: [null],
      budgetType: [null],
      costCenter: [null],
      coa: [null],
      paymentBillingIdList: this.formBuilder.array(this.paymentBillingIdList),
      paymentId: this.paymentId
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'budgetAllocation.organization.name',
        header: 'table.column.department'
      },
      {
        field: 'budgetAllocation.activityPeriod',
        header: 'table.column.period'
      },
      {
        field: 'budgetAllocation.costCenter.code',
        header: 'table.column.costCenter'
      },
      {
        field: 'budgetAllocation.coa.code',
        header: 'table.column.coa'
      },
      {
        field: 'budgetAllocation.budgetType.name',
        header: 'table.column.type'
      },
      {
        field: 'remainingAmount',
        header: 'table.column.availableBudget',
        plugins: [
          {
            name: 'default',
            type: 'currency'
          }
        ],
        className: 'text-right'
      }
    ]);

    this.tableResponse.setCustomData(this.formGroup.value);
  }

  public getOptionList(): void {
    this.httpClientService
      .get<AppPopupAddBudgetItemCustomDataResponse>(
        '/app-popup-add-budget-item/get-option-list/' + this.paymentId
      )
      .subscribe((response: AppPopupAddBudgetItemCustomDataResponse) => {
        this.activityPeriodOptionList.setRequestValues(
          response.activityPeriodList
        );
        this.budgetTypeOptionList.setRequestValues(response.budgetTypeList);
      });
  }

  public setBudgetAllocation(): void {
    if (this.budgetItemViewList && this.budgetItemViewList.length !== 0) {
      this.tableResponse.setSelectedRowList(this.budgetItemViewList);
    }
  }

  public onAfterRowCreated(): void {
    this.setBudgetAllocation();
  }

  public doChoose(): void {
    if (this.tableResponse.selectedRow.getSelectedRecordList().length !== 0) {
      this.onChange.emit(
        this.tableResponse.selectedRow.getSelectedRecordList()
      );
    } else {
      this.global.alertService.showError(
        'app-popup-choose-budget.validation.chooseBudget',
        '.popup-choose-budget'
      );
    }
  }

  public doApply(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doShowFilter(): void {
    this.isShowFilter = !this.isShowFilter;
  }

  public doOnChange(event): void {
    console.log(event);
  }

  public concatCoa(coa: Coa): string {
    return coa.code + ' - ' + coa.name;
  }
}
