import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppBadgeCatalogModule } from 'src/app/core/components/app-badge-catalog/app-badge-catalog.module';
import { AppCatalogDetailInfoModule } from 'src/app/core/components/app-catalog-detail-info/app-catalog-detail-info.module';
import { AppCatalogInfoModule } from 'src/app/core/components/app-catalog-info/app-catalog-info.module';
import { AppFinesModule } from 'src/app/core/components/app-fines/app-fines.module';
import { AppGuaranteeModule } from 'src/app/core/components/app-guarantee/app-guarantee.module';
import { AppImageSlideshowModule } from 'src/app/core/components/app-image-slideshow/app-image-slideshow.module';
import { AppItemRequestReleasedModule } from 'src/app/core/components/app-item-request-released/app-item-request-released.module';
import { AppItemRequestModule } from 'src/app/core/components/app-item-request/app-item-request.module';
import { AppOfficialReportModule } from 'src/app/core/components/app-official-report/app-official-report.module';
import { AppPaginationXModule } from 'src/app/core/components/app-pagination-x/app-pagination-x.module';
import { AppSimilarProductModule } from 'src/app/core/components/app-similar-product/app-similar-product.module';
import { AppSowXModule } from 'src/app/core/components/app-sow-x/app-sow-x.module';
import { AppSowModule } from 'src/app/core/components/app-sow/app-sow.module';
import { AppVendorCatalogInfoModule } from 'src/app/core/components/app-vendor-catalog-info/app-vendor-catalog-info.module';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { OfficialReportOrderModule } from './official-report-order/app-official-report.module';
import { OrderAddItemDetailXComponent } from './order-add-item-detail-x.component';
import { OrderCatalogAddComponent } from './order-catalog-add.component';
import { OrderEditAddFinalXtComponent } from './order-edit-add-final-xt.component';
import { OrderEditAddFinalComponent } from './order-edit-add-final.component';
import { OrderEditAddXtPaginationComponent } from './order-edit-add-xt-pagination.component';
import { OrderEditAddXtComponent } from './order-edit-add-xt.component';
import { OrderEditAddComponent } from './order-edit-add.component';
import { OrderComponent } from './order.component';
import { AppPopupPrAddBudgetComponent } from './popup/app-popup-pr-add-budget.component';
import { AppPopupPrItemDistributorComponent } from './popup/app-popup-pr-item-distributor.component';
import { AppPopupPrItemEditComponent } from './popup/app-popup-pr-item-edit.component';
import { PopupOrderCancelComponent } from './popup/popup-order-cancel.component';
import { PopupOrderChooseTermComponent } from './popup/popup-order-choose-term.component';
import { PopupOrderEditConditionComponent } from './popup/popup-order-edit-condition.component';
import { PopupOrderSplitItemXtComponent } from './popup/popup-order-split-item-xt.component';
import { PopupOrderSplitItemComponent } from './popup/popup-order-split-item.component';
import { PrAddItemDetailComponent } from './pr-add-item-detail.component';
import { PrCatalogAddComponent } from './pr-catalog-add.component';
import { PrItemCatalogAddComponent } from './pr-item-catalog-add.component';
import { PrItemEditAddComponent } from './pr-item-edit-add.component';
import { PrVendorDetailComponent } from './pr-vendor-detail.component';

const routes = [
  { path: '', component: OrderComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: OrderEditAddFinalXtComponent,
    data: { breadcrumb: 'order.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: OrderEditAddFinalXtComponent,
    data: { breadcrumb: 'order.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: OrderEditAddFinalXtComponent,
    data: { breadcrumb: 'order.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add-item',
    component: PrItemEditAddComponent,
    data: { breadcrumb: 'pr.breadcrumb.addItem' },
    canDeactivate: [ConfirmGuard],
    children: [
      {
        path: '',
        component: PrCatalogAddComponent,
        canDeactivate: [ConfirmGuard]
      },
      {
        path: 'catalog',
        component: PrItemCatalogAddComponent,
        data: { breadcrumb: 'pr.breadcrumb.itemCatalog' },
        canDeactivate: [ConfirmGuard]
      }
    ]
  },
  {
    path: 'edit-item',
    component: PrItemEditAddComponent,
    data: { breadcrumb: 'pr.breadcrumb.editItem' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add-item-detail',
    component: PrAddItemDetailComponent,
    data: { breadcrumb: 'pr.breadcrumb.catalogDetail' },
    canDeactivate: [ConfirmGuard]
  },

  {
    path: 'add-item-detail',
    component: OrderAddItemDetailXComponent,
    data: { breadcrumb: 'pr.breadcrumb.catalogDetail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'vendor-detail',
    component: PrVendorDetailComponent,
    data: { breadcrumb: 'pr.breadcrumb.vendorDetail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit/:token',
    component: OrderEditAddFinalXtComponent,
    data: { breadcrumb: 'order.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: OrderEditAddFinalXtComponent,
    data: { breadcrumb: 'order.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppItemRequestModule,
    AppItemRequestReleasedModule,
    AppSowModule,
    AppApprovalPrcsXModule,
    AppFinesModule,
    AppGuaranteeModule,
    AppOfficialReportModule,
    AppPaginationXModule,
    AppCatalogInfoModule,
    AppCatalogDetailInfoModule,
    AppSimilarProductModule,
    AppBadgeCatalogModule,
    AppImageSlideshowModule,
    AppSowXModule,
    AppVendorCatalogInfoModule,
    OfficialReportOrderModule,
    AppPaginationXModule
  ],
  declarations: [
    OrderComponent,
    OrderEditAddComponent,
    PopupOrderChooseTermComponent,
    PopupOrderSplitItemComponent,
    PopupOrderEditConditionComponent,
    PopupOrderCancelComponent,
    OrderEditAddXtComponent,
    AppPopupPrItemEditComponent,
    OrderAddItemDetailXComponent,
    AppPopupPrAddBudgetComponent,
    OrderCatalogAddComponent,
    OrderEditAddXtPaginationComponent,
    PopupOrderSplitItemXtComponent,
    AppPopupPrAddBudgetComponent,
    AppPopupPrItemEditComponent,
    PrAddItemDetailComponent,
    PrVendorDetailComponent,
    PrItemCatalogAddComponent,
    PrCatalogAddComponent,
    AppPopupPrItemDistributorComponent,
    OrderEditAddFinalComponent,
    PrItemEditAddComponent,
    OrderEditAddFinalXtComponent
  ],
  providers: [],
  entryComponents: [
    PopupOrderChooseTermComponent,
    PopupOrderSplitItemComponent,
    PopupOrderEditConditionComponent,
    PopupOrderCancelComponent,
    AppPopupPrItemEditComponent,
    AppPopupPrAddBudgetComponent,
    PopupOrderSplitItemXtComponent,
    AppPopupPrItemDistributorComponent
  ],
  exports: [
    PopupOrderChooseTermComponent,
    PopupOrderSplitItemComponent,
    PopupOrderEditConditionComponent,
    PopupOrderCancelComponent,
    AppPopupPrItemEditComponent,
    AppPopupPrAddBudgetComponent,
    PopupOrderSplitItemXtComponent,
    AppPopupPrItemDistributorComponent
  ]
})
export class OrderModule { }
