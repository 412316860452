import { ContractDoc } from 'src/app/core/bean/contract-doc';
import { Currency } from 'src/app/core/bean/currency';
import { DrShipping } from 'src/app/core/bean/dr-shipping';
import { DrShippingItem } from 'src/app/core/bean/dr-shipping-item';
import { Module } from 'src/app/core/bean/module';
import { Order } from 'src/app/core/bean/order';
import { OrderCancellation } from 'src/app/core/bean/order-cancellation';
import { OrderDoc } from 'src/app/core/bean/order-doc';
import { Organization } from 'src/app/core/bean/organization';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PrItemReleased } from 'src/app/core/bean/pr-item-released';
import { Sow } from 'src/app/core/bean/sow';
import { SowPaymentTerm } from 'src/app/core/bean/sow-payment-term';
import { SowPaymentTermBilling } from 'src/app/core/bean/sow-payment-term-billing';
import { SowPaymentTermItem } from 'src/app/core/bean/sow-payment-term-item';
import { TcDetail } from 'src/app/core/bean/tc-detail';
import { VendorAddress } from 'src/app/core/bean/vendor-address';
import { ApprovalPathResponseModel } from 'src/app/core/components/app-approval-path-x';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { DrShippingItemView } from 'src/app/core/view/entity/bean/dr-shipping-item-view';
import { Dr } from '../../../core/bean/dr';
import { DrTc } from '../../../core/bean/dr-tc';
import { PaymentBilling } from '../../../core/bean/payment-billing';
import { Tc } from '../../../core/bean/tc';

export class DrAddEditResponse {
  dr: Dr;
  order: Order;
  orderCancellation: OrderCancellation;
  orderDocList: OrderDoc[] = [];
  contractDocList: ContractDoc[] = [];
  tcDetailList: TcDetail[] = [];
  tcList: Tc[];
  drShippingList: DrShipping[] = [];
  paymentBillingList: PaymentBilling[] = [];
  drShippingItemList: DrShippingItem[] = [];
  drShippingItemViewList: DrShippingItemView[] = [];

  drTcList: DrTc[] = [];
  vendorAddress: VendorAddress;

  sowList: Sow[] = [];
  sowPaymentTermList: SowPaymentTerm[] = [];
  sowPaymentTermItemList: SowPaymentTermItem[] = [];
  sowPaymentTermBillingList: SowPaymentTermBilling[] = [];
  vendorAddressList: VendorAddress[] = [];

  isUserApproval: boolean;
  approvalPathResponseModel: ApprovalPathResponseModel =
    new ApprovalPathResponseModel();
  module: Module = new Module();
  organization: Organization = new Organization();
  prItemReleased: PrItemReleased = new PrItemReleased();
  appOfficialReportModel: AppOfficialReportModel = new AppOfficialReportModel();

  daysContractExpiredSoon: number;
  daysOrderExpiredSoon: number;

  prItemList: PrItem[] = [];
  companyCurrency: Currency;
}
