import { resolveTableRecords } from '../helpers/resolve-table-records.helper';
import { TableState } from '../model/table-state';
export const changeTableFilterPerPage = (
  state: TableState,
  payload: { perPage: number }
): void => {
  state.pagination.perPage = payload.perPage;
  state.tableRequest.rows = payload.perPage;
  resolveTableRecords(state).then((records: Array<any>) => {
    state.setRecords(records);
    state.setStateReady();
  });
};
