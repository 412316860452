import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Menu } from '../../core/bean/menu';
import { Role } from '../../core/bean/role';
import { PickListModel } from '../../core/components/app-pick-list/model/pick-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RoleMenuResponse } from './role-menu-response';
import { RoleMenuRequest } from './role-menu-request';

@Component({
  templateUrl: 'role-menu-edit-add.component.html',
  styleUrls: ['./role-menu-edit-add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoleMenuEditAddComponent extends BaseModuleComponent {
  public role: Role = new Role();
  public pickListModel: PickListModel<Menu>;
  constructor(translateService: TranslateService) {
    super('role-menu', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doGetAndSetRoleMenuToPicklist();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.role = this.global.routerParams.get('role');
  }

  public doGetAndSetRoleMenuToPicklist(): void {
    this.httpClientService
      .post<RoleMenuResponse>('/role-menu/edit', this.role.id)
      .subscribe(roleMenuResponse => {
        this.pickListModel = new PickListModel(
          this.moduleCode,
          roleMenuResponse.menuListNotByRole,
          roleMenuResponse.menuListByRole
        );
        this.setStateReady();
      });
  }

  public doCheck(event: any, menu: Menu): void {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    menu.isWrite = !menu.isWrite;
  }

  public doChangeIsWrite(event: any, menu: any): void {
    event.stopPropagation();
    menu.isWrite = !menu.isWrite;
    event.target.checked = menu.isWrite;
  }

  public doSave(): void {
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          const roleMenuRequest: RoleMenuRequest = new RoleMenuRequest();
          roleMenuRequest.role = this.role;
          roleMenuRequest.menuListByRole = this.pickListModel.targetItemList;
          this.httpClientService
            .post<Response<RoleMenuResponse>>('/role-menu/update', roleMenuRequest)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessSavingOnNextRoute();
                this.router.navigate(['/pages/role-menu/']);
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/role-menu/']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}