import { DueDiligenceOTS } from 'src/app/core/bean/due-diligence-ots';
import { DueDiligenceRealization } from 'src/app/core/bean/due-diligence-realization';
import { DueDiligenceTemplateOTS } from 'src/app/core/bean/due-diligence-template-OTS';
import { VendorHistory } from 'src/app/core/bean/vendor-history';
import { FileObject } from 'src/app/core/components/upload';

export class OtsRequest {
    vendorHistory: VendorHistory = new VendorHistory();
    dueDiligenceTemplateOTSList: DueDiligenceTemplateOTS[] = [];
    dueDiligenceRealizationOTS: DueDiligenceRealization = new DueDiligenceRealization();
    dueDiligenceOTSList: DueDiligenceOTS[] = [];
    fileOTSList: FileObject[] = [];
}
