import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { ContractDoc } from '../../bean/contract-doc';
import { ContractReviewer } from '../../bean/contract-reviewer';
import { OptionListModel } from '../../model/option-list-model';

@Component({
  templateUrl: './app-popup-reviewer-contract-document.component.html'
})
export class AppPopupReviewerContractDocumentComponent extends BaseComponentComponent {
  @Input() contractId: number;
  @Output() onChange: EventEmitter<ContractDoc> = new EventEmitter();

  public contractReviewerOptionList: OptionListModel<ContractReviewer> =
    new OptionListModel(false);

  constructor() {
    super('app-contract-document');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      contractReviewerList: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<ContractReviewer[]>(
        '/app-contract-document/get-reviewer-list/' + this.contractId
      )
      .subscribe((contractReviewerList: ContractReviewer[]) => {
        this.contractReviewerOptionList.setRequestValues(contractReviewerList);
        this.setStateReady();
        this.setViewOnly();
      });
  }
}
