export class CMConstant {
  /* t1_contract_status */
  public CONTRACT_STATUS_REQUEST_NEW = 'REQUEST-NEW';
  public CONTRACT_STATUS_REQUEST_SAVE_DRAFT = 'REQUEST-SAVE-DRAFT';
  public CONTRACT_STATUS_REQUEST_WAITING_APPROVAL = 'REQUEST-WAITING-APPROVAL';
  public CONTRACT_STATUS_REQUEST_REVISION = 'REQUEST-REVISION';
  public CONTRACT_STATUS_DRAFT_NEW = 'DRAFT-NEW';
  public CONTRACT_STATUS_DRAFT_SAVE_DRAFT = 'DRAFT-SAVE-DRAFT';
  public CONTRACT_STATUS_DRAFT_WAITING_APPROVAL = 'DRAFT-WAITING-APPROVAL';
  public CONTRACT_STATUS_DRAFT_REVISION = 'DRAFT-REVISION';
  public CONTRACT_STATUS_NEGOTIATION_NEW = 'NEGOTIATION-NEW';
  public CONTRACT_STATUS_NEGOTIATION_WAITING_APPROVAL =
    'NEGOTIATION-WAITING-APPROVAL';
  public CONTRACT_STATUS_NEGOTIATION_REVISION = 'NEGOTIATION-REVISION';
  public CONTRACT_STATUS_FINAL_NEW = 'FINAL-NEW';
  public CONTRACT_STATUS_FINAL_WAITING_APPROVAL = 'FINAL-WAITING-APPROVAL';
  public CONTRACT_STATUS_FINAL_REVISION = 'FINAL-REVISION';
  public CONTRACT_STATUS_TERMINATION = 'TERMINATION';
  public CONTRACT_STATUS_WAITING_FOR_APPROVAL_TERMINATION =
    'WAITING-FOR-APPROVAL-TERMINATION';
  public CONTRACT_STATUS_REVISION_TERMINATION = 'REVISION-TERMINATION';
  public CONTRACT_STATUS_REVIEW_TERMINATION = 'REVIEW-TERMINATION';
  public CONTRACT_STATUS_REQUEST_CLOSED_CONTRACT = 'REQUEST-CLOSED-CONTRACT';

  /* t1_contract_status new */
  public CONTRACT_STATUS_DRAFT = 'DRAFT';
  public CONTRACT_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public CONTRACT_STATUS_WAITING_CANCEL_APPROVAL = 'WAITING_CANCEL_APPROVAL';
  public CONTRACT_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public CONTRACT_STATUS_REQUEST_ON_PROGRESS = 'REQUEST_ON_PROGRESS';
  public CONTRACT_STATUS_REVISION = 'REVISION';
  public CONTRACT_STATUS_DONE = 'DONE';
  public CONTRACT_STATUS_TERMINATED = 'TERMINATED';
  public CONTRACT_STATUS_REJECTED = 'REJECTED';
  public CONTRACT_STATUS_WAITING_FOR_TERMINATION = 'WAITING_FOR_TERMINATION';
  public CONTRACT_STATUS_CANCEL = 'CANCELED';
  public CONTRACT_STATUS_CLOSED = 'CLOSED';
  public CONTRACT_STATUS_EXPIRED = 'EXPIRED';

  /* t1_payment_status */
  public PAYMENT_STATUS_NEW_PAYMENT = 'NEW-PAYMENT';
  public PAYMENT_STATUS_REVISED = 'REVISED';
  public PAYMENT_STATUS_BILLING_MEMO = 'BILLING-MEMO';
  public PAYMENT_STATUS_REVISION_BILLING_MEMO = 'REVISION-BILLING-MEMO';
  public PAYMENT_STATUS_DRAFT_BILLING_MEMO = 'DRAFT-BILLING-MEMO';
  public PAYMENT_STATUS_PAID_SUCCESS = 'PAID-SUCCESS';
  public PAYMENT_STATUS_WAITING_APPROVAL_BILLING_MEMO =
    'WAITING-APPROVAL-BILLING-MEMO';
  public PAYMENT_STATUS_WAITING_APPROVAL_PAYMENT_REQUEST =
    'WAITING-APPROVAL-PAYMENT-REQUEST';
  public PAYMENT_STATUS_REVISION_PAYMENT_REQUEST = 'REVISION-PAYMENT-REQUEST';
  public PAYMENT_STATUS_PAYMENT_PROGRESS = 'PAYMENT_PROGRESS';
  public PAYMENT_STATUS_PAYMENT_CLOSED = 'PAYMENT_CLOSED';

  /* NEW t1_payment_status */
  public PAYMENT_STATUS_DRAFT = 'DRAFT';
  public PAYMENT_STATUS_REVISION = 'REVISION';
  public PAYMENT_STATUS_WAITING_FOR_REVIEW = 'WAITING_FOR_REVIEW';
  public PAYMENT_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public PAYMENT_STATUS_WAITING_CONFIRMATION = 'WAITING_CONFIRMATION';
  public PAYMENT_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public PAYMENT_STATUS_PAYMENT_PROCESS = 'PAYMENT_PROCESS';
  public PAYMENT_STATUS_DONE = 'DONE';
  public PAYMENT_STATUS_CLOSED = 'CLOSED';

  /* t1_scope_work_status */
  public SCOPE_WORK_STATUS_DRAFT = 'DRAFT';
  public SCOPE_WORK_STATUS_PROCUREMENT_PROCESS = 'PROCUREMENT-PROCESS';
  public SCOPE_WORK_STATUS_CONTRACT_REQUEST = 'CONTRACT-REQUESTED';
  public SCOPE_WORK_STATUS_CONTRACT_CANCELED = 'CONTRACT-CANCELED';
  public SCOPE_WORK_STATUS_CONTRACT_CLOSED = 'CONTRACT-CLOSED';
  public SCOPE_WORK_STATUS_CONTRACT_TERMINATE = 'CONTRACT-TERMINATE';

  /* t1_activity */
  public CONTRACT_ACTIVITY_REQUEST = 'REQUEST';
  public CONTRACT_ACTIVITY_CANCEL_REQUEST = 'CANCEL-REQUEST';
  public CONTRACT_ACTIVITY_DRAFT = 'DRAFT';
  public CONTRACT_ACTIVITY_DRAFT_REVISION = 'DRAFT-REVISION';
  public CONTRACT_ACTIVITY_NEGOTIATION = 'NEGOTIATION';
  public CONTRACT_ACTIVITY_NEGOTIATION_REVISION = 'NEGOTIATION-REVISION';
  public CONTRACT_ACTIVITY_FINALIZATION = 'FINALIZATION';
  public CONTRACT_ACTIVITY_TERMINATION = 'TERMINATION';
  public CONTRACT_ACTIVITY_CLOSE_CONTRACT = 'CLOSE-CONTRACT';
  public PAYMENT_ACTIVITY_PAYMENT_REQUEST = 'PAYMENT-REQUEST';
  public PAYMENT_ACTIVITY_REVISION_PAYMENT_REQUEST = 'REVISION-PAYMENT-REQUEST';
  public PAYMENT_ACTIVITY_BILLING_MEMO = 'BILLING-MEMO';
  public PAYMENT_ACTIVITY_REVISION_BILLING_MEMO = 'REVISION-BILLING-MEMO';
  public PAYMENT_ACTIVITY_CLOSE_PAYMENT = 'CLOSE-PAYMENT';

  /* t1_payment_term */
  public PAYMENT_TERM_DOWN_PAYMENT = 'PAYMENT_TERM_DP';
  public PAYMENT_TERM_OTHERS = 'TAHAP_LAINNYA';
  public PAYMENT_TERM_FULL_PAYMENT = 'PAYMENT_TERM_FULL';
  public PAYMENT_TERM_TERMIN_ONE = 'PAYMENT_TERM_1';

  /* t1_contract_type */
  public CONTRACT_TYPE_ADDENDUM_CONTRACT = 'ADDENDUM_CONTRACT';
  public CONTRACT_TYPE_REPEAT_ORDER = 'REPEAT_ORDER_CONTRACT';
  public CONTRACT_TYPE_NEW_CONTRACT = 'NEW_CONTRACT';

  /* t6_procurement_scope_work status*/
  public PROCUREMENT_SCOPE_WORK_STATUS_NEW = 1;
  public PROCUREMENT_SCOPE_WORK_STATUS_ON_PROGRESS = 2;
  public PROCUREMENT_SCOPE_WORK_STATUS_CLOSED = 3;

  /* t7_procurement_sow status*/
  public PROCUREMENT_SOW_STATUS_NEW = 1;
  public PROCUREMENT_SOW_STATUS_ON_PROGRESS = 2;
  public PROCUREMENT_SOW_STATUS_CLOSED = 3;

  /* t8_procurement_sow_payment_term status*/
  public PROCUREMENT_SOW_PAYMENT_TERM_STATUS_NEW = 1;
  public PROCUREMENT_SOW_PAYMENT_TERM_STATUS_ON_PROGRESS = 2;
  public PROCUREMENT_SOW_PAYMENT_TERM_STATUS_CLOSED = 3;

  /* t9_procurement_sow_payment_term_item*/
  public PROCUREMENT_SOW_PAYMENT_TERM_ITEM_STATUS_NEW = 1;
  public PROCUREMENT_SOW_PAYMENT_TERM_ITEM_STATUS_ON_PROGRESS = 2;
  public PROCUREMENT_SOW_PAYMENT_TERM_ITEM_STATUS_CLOSED = 3;

  /* t1_stage_of_work */
  public STAGE_OF_WORK_STAGE_1 = 'STAGE_1';

  /* t1_pocurement_transaction_type*/
  public PROCUREMENT_TRANSACTION_TYPE_ONLINE = 'ONLINE';
  public PROCUREMENT_TRANSACTION_TYPE_OFFLINE = 'OFFLINE';

  /*t2_dealing_agreement_type*/
  public DEALING_AGREEMENT_TYPE_KONTRAK_SATUAN = 'Kontrak Satuan';

  /* t2_contract_parameter */
  public CONTRACT_PARAMETER_NOMOR_KONTRAK = '$NOMOR_KONTRAK';

  /* t1_other_cost */
  public OTHER_COST_MATERAI = 'MATERAI';

  /* t1_payment_method */
  public PAYMENT_METHOD_BANK_TRANSFER = 'BANK_TRANSFER';
  public PAYMENT_METHOD_CASHIER = 'CASHIER';
  public PAYMENT_METHOD_COMPANY_CREDIT_CARD = 'COMPANY_CREDIT_CARD';

  /* t1_guarantee_bond_type */
  public GUARANTEE_BOND_TYPE_JAMINAN_PEMELIHARAAN = 'JAMINAN_PEMELIHARAAN';

  /* Code Review Contract */
  public REVIEW_CONTRACT = 'REVIEW_CONTRACT';
  public REVIEW_TERMINATION = 'REVIEW_TERMINATION';

  public BALANCE_TYPE_DEBIT = 1;
  public BALANCE_TYPE_CREDIT = 2;

  public PAYMENT_VOUCHER_STATUS_NEW = 'NEW';
  public PAYMENT_VOUCHER_STATUS_DRAFT = 'DRAFT';
  public PAYMENT_VOUCHER_STATUS_REVISION = 'REVISION';
  public PAYMENT_VOUCHER_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';

  /* user_type payment history*/
  public USER_TYPE_REQUEST_PAYMENT_INTERNAL = 1;
  public USER_TYPE_REQUEST_PAYMENT_VENDOR = 2;

  /* t1_contract_worklist_status new */
  public CONTRACT_WORKLIST_STATUS_NEW = 'NEW';
  public CONTRACT_WORKLIST_STATUS_DRAFT = 'DRAFT';
  public CONTRACT_WORKLIST_STATUS_REVISION = 'REVISION';
  public CONTRACT_WORKLIST_STATUS_WAITING_CONFIRMATION = 'WAITING_CONFIRMATION';
  public CONTRACT_WORKLIST_STATUS_REVISION_BY_VENDOR = 'REVISION_BY_VENDOR';
  public CONTRACT_WORKLIST_STATUS_ON_PROGRESS = 'ON_PROGRESS';
  public CONTRACT_WORKLIST_STATUS_APPROVED = 'APPROVED';
  public CONTRACT_WORKLIST_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';

  /* user_type contract negotiation history*/
  public USER_TYPE_VENDOR = 1;
  public USER_TYPE_STAFF = 2;

  /* t1_payment_worklist_status */
  public PAYMENT_WORKLIST_STATUS_DRAFT = 'DRAFT';
  public PAYMENT_WORKLIST_STATUS_NEW = 'NEW';
  public PAYMENT_WORKLIST_STATUS_WAITING_APPROVAL = 'WAITING_APPROVAL';
  public PAYMENT_WORKLIST_STATUS_REVISION = 'REVISION';
  public PAYMENT_WORKLIST_STATUS_WAITING_FOR_REVIEW = 'WAITING_FOR_REVIEW';
  public PAYMENT_WORKLIST_STATUS_WAITING_CONFIRMATION = 'WAITING_CONFIRMATION';

  /* t1_tax-method */
  public TAX_METHOD_PERCENT = 'PERCENT';
  public TAX_METHOD_VALUE = 'VALUE';
}
