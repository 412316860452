import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Currency } from '../../bean/currency';
import { OptionListModel } from '../../model/option-list-model';
import { FormObjectModel } from './model/form-object-model';
@Component({
  selector: 'app-form-object',
  templateUrl: './app-form-object.component.html',
  styleUrls: ['./app-form-object.component.scss']
})
export class AppFormObjectComponent extends BaseComponentComponent {
  @Input() public model: FormObjectModel<any>;
  @Input() public isFilter: boolean;
  @Input() private currencyList: Currency[];
  @Output() private onChange: EventEmitter<any> = new EventEmitter();

  public values: any;
  public currencyOptionList: OptionListModel<Currency>;

  constructor() {
    super('app-form-object');
  }

  public onInit(): void {
    this.setInitializationState();
    this.model
      .buildFormObjectList()
      .buildFormGroup(this.formBuilder)
      .setFormGroup()
      .handleFormGroupValueChanges();
    this.handleModelResponseValueChanges();
    this.values = this.model.getRequestValues();
  }

  private setInitializationState(): void {
    this.currencyOptionList = new OptionListModel(false, 'code');
    this.currencyOptionList.setRequestValues(this.currencyList);
  }

  private handleModelResponseValueChanges(): void {
    this.model.responseValueChanges.subscribe((values: any) => {
      this.onChange.emit(values);
    });
  }
}
