import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { WidgetService } from '../widgets.service';
import { WidgetContractStatusDTO } from './dto/widget-contract-status.dto';
@Component({
  templateUrl: './app-widget-contract-status.component.html',
  styleUrls: ['./app-widget-contract-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetContractStatusComponent extends BaseComponentComponent {
  public totalContractRequest: number;
  public contractDataList: Array<any>;
  public contractDataIcon = {
    totalDrafting: 'fas fa-edit',
    totalOnProgress: 'fas fa-clock',
    totalClosed: 'fas fa-check-circle',
    totalCancel: 'fas fa-ban',
    totalWarning: 'fas fa-exclamation-circle'
  };
  public contractStatusUrl = {
    totalDrafting: '/pages/worklist-cm/',
    totalOnProgress: '/pages/contract-monitoring/',
    totalClosed: '/pages/contract-history/',
    totalCancel: '/pages/contract-request/',
    totalWarning: '/pages/contract-monitoring/'
  };
  public routerParamFilter = {
    totalDrafting: '',
    totalOnProgress: 'ON-PROGRESS',
    totalClosed: 'CLOSED',
    totalCancel: 'CANCEL',
    totalWarning: 'EXPIRED'
  };
  constructor(public widgetService: WidgetService) {
    super('app-widget-contract-status');
  }

  public onInit(): void {
    this.getAndSetContractStatusInformation();
  }

  public getAndSetContractStatusInformation(): void {
    this.httpClientService
      .get<WidgetContractStatusDTO>('/widget-contract-status/index')
      .subscribe(response => {
        this.contractDataList = Object.keys(response).map((key: string) => {
          const strKey = key.slice(5);
          return {
            value: response[key],
            icon: this.contractDataIcon[key],
            url: this.contractStatusUrl[key],
            filter: this.routerParamFilter[key],
            translationKey:
              this.moduleCode +
              '.' +
              strKey.charAt(0).toLowerCase() +
              strKey.slice(1)
          };
        });
        this.setStateReady();
      });
  }

  public goToMoreDetail(data): void {
    this.global.routerParams.set('filter', data.filter);
    this.router.navigate([data.url]);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
