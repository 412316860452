import { BudgetType } from 'src/app/core/bean/budget-type';
import { Coa } from 'src/app/core/bean/coa';
import { CostCenter } from 'src/app/core/bean/cost-center';
import { Currency } from 'src/app/core/bean/currency';
import { ItemType } from 'src/app/core/bean/item-type';
import { Module } from 'src/app/core/bean/module';
import { Organization } from 'src/app/core/bean/organization';
import { Pr } from 'src/app/core/bean/pr';
import { PrCancellation } from 'src/app/core/bean/pr-cancellation';
import { PrItem } from 'src/app/core/bean/pr-item';
import { PrItemBudget } from 'src/app/core/bean/pr-item-budget';
import { PrShipping } from 'src/app/core/bean/pr-shipping';
import { PrType } from 'src/app/core/bean/pr-type';
import { PrVendorReference } from 'src/app/core/bean/pr-vendor-reference';
import { ProcurementType } from 'src/app/core/bean/procurement-type';
import { User } from 'src/app/core/bean/user';
import { WorkProgram } from 'src/app/core/bean/work-program';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path-x';
import { AppUploadDocumentTableModel } from '../../core/components/app-upload-document-table-x/app-upload-document-table-model';

export class PrResponse {
  prItemBudgetList: PrItemBudget[] = [];
  currencyList: Currency[] = [];
  budgetTypeList: BudgetType[] = [];
  costCenterList: CostCenter[] = [];
  workProgramList: WorkProgram[] = [];
  prItemList: PrItem[] = [];
  prVendorReferenceList: PrVendorReference[] = [];
  vendorViewList: PopupVendorView[] = [];
  coaList: Coa[] = [];
  itemTypeList: ItemType[] = [];
  isModuleGroupESMActive: boolean;
  companyCurrency: Currency;
  pr: Pr = new Pr();
  requestor: User;
  procurementTypeList: ProcurementType[];
  prShippingList: PrShipping[] = [];
  isUserApproval: boolean;
  module: Module = new Module();
  approvalPathResponseModel: ApprovalPathResponseModel =
    new ApprovalPathResponseModel();
  prCancellation: PrCancellation = new PrCancellation();
  appUploadDocumentTableModelList: AppUploadDocumentTableModel[] = [];
  prTypeList: PrType[] = [];
  appOfficialReportModel: AppOfficialReportModel = new AppOfficialReportModel();
  organization: Organization = new Organization();
  isValidatePr: boolean;
}
