import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ChangeHistory } from '../../bean/change-history';
import { TableResponseModel } from '../table/model/table-response-model';

@Component({
  selector: 'app-change-history',
  templateUrl: './app-change-history.component.html'
})
export class AppChangeHistoryComponent extends BaseComponentComponent {
  @Input() public moduleId: number;
  @Input() public objectId: number;

  public tableResponse: TableResponseModel<ChangeHistory>;

  constructor() {
    super('app-change-history');
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setCustomData();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: '0.processedByUser.name',
        header: 'table.column.changeBy'
      },
      {
        field: '0.processedDate',
        header: 'table.column.date',
        plugins: 'date'
      },
      {
        field: '1',
        header: 'table.column.context'
      },
      {
        field: '0.oldValue',
        header: 'table.column.oldValue'
      },
      {
        field: '0.newValue',
        header: 'table.column.newValue'
      }
    ]);
  }

  public setCustomData(): void {
    this.tableResponse.setCustomData({
      moduleId: this.moduleId,
      objectId: this.objectId
    });
  }
}
