import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppSignatureModule } from 'src/app/core/components/app-signature/app-signature.module';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppLazyLoadModule } from '../../core/components/app-lazy-load/app-lazy-load.module';
import { AppAttendanceFormCardComponent } from './app-attendance-form-card.component';
import { AttendanceFormInformationComponent } from './attendance-form-information.component';
import { AttendanceFormParticipantListComponent } from './attendance-form-participant-list.component';
import { AttendanceFormComponent } from './attendance-form.component';
export const routes = [
  {
    path: 'form/:id',
    component: AttendanceFormComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'participant-list',
    component: AttendanceFormParticipantListComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'information',
    component: AttendanceFormInformationComponent,
    data: { breadcrumb: '' }
  }
];
@NgModule({
  imports: [
    SharedModule,
    AppSignatureModule,
    AppBadgeCatalogModule,
    AppLazyLoadModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    AttendanceFormComponent,
    AttendanceFormParticipantListComponent,
    AttendanceFormInformationComponent,
    AppAttendanceFormCardComponent
  ] // AttendanceFormComponent
})
export class AttendanceFormModule {}
