<app-popup header="work-plan.form.chooseItem" [isLoading]="formLoading">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'work-plan.form.item' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-choose
          size="LG"
          formControlName="itemName"
          placeholder="app.choose.item"
          (onClick)="chooseItem()"
        >
        </app-choose>
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'work-plan.form.uom' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ formGroup.controls.unit.value }}
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'work-plan.form.quantity' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="qty"
          type="decimal"
          maxLength="32"
          (onChange)="onChangeQuantity()"
        >
        </app-text-field>
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'work-plan.form.unitPrice' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-field
          size="LG"
          formControlName="unitPrice"
          type="currency"
          maxLength="32"
          (onChange)="onChangeUnitPrice()"
        ></app-text-field>
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'work-plan.form.totalPrice' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ formGroup.controls.convertTotalPrice.value }}
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'work-plan.form.specification' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-text-area
          size="LG"
          formControlName="specification"
          maxLength="512"
        ></app-text-area>
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'work-plan.form.budget' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-choose
          size="LG"
          formControlName="coa"
          (onClick)="chooseBudget($event)"
        >
        </app-choose>
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
        <app-button color="PRIMARY" (onClick)="doSubmit()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
