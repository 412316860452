
export class TreeItemModel {
    id: number;
    name: string;
    code: string;
    selected: boolean;
    parentId: number;
    hasChild: boolean;
    tempId: number;
    isChecked: boolean;
    fileImage: File;
    level: number;
    constructor ({ id, name, code, parentId, hasChild, fileImage, level, ...treeItem}) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.parentId = parentId;
        this.hasChild = hasChild;
        this.fileImage = fileImage;
        this.level = level;
        Object.assign(this, {...treeItem});
    }

    setHasChild(hasChild: boolean): void {
        this.hasChild = hasChild;
    }

    setParentId(parentId: number): void {
        this.parentId = parentId;
    }

    setStateSelected(): void {
        this.selected = true;
    }
}
