import { ProcMethodVActStatus } from '../../core/bean/proc-method-v-act-status';
import { ProcMethodVCertStatus } from '../../core/bean/proc-method-v-cert-status';
import { ProcMethodVStatus } from '../../core/bean/proc-method-v-status';
import { ProcurementMethod } from '../../core/bean/procurement-method';

export class ProcurementMethodRequest {
  procurementMethod: ProcurementMethod;
  translation: string[];
  procMethodVStatusList: ProcMethodVStatus[];
  procMethodVActStatusList: ProcMethodVActStatus[];
  procMethodVCertStatusList: ProcMethodVCertStatus[];
}
