import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { WorkflowModelPrcs } from 'src/app/core/bean/workflow-model-prcs';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Item } from '../../core/bean/item';
import { ItemCategory } from '../../core/bean/item-category';
import { ItemType } from '../../core/bean/item-type';
import { Uom } from '../../core/bean/uom';
import { AppAutocompleteComponent } from '../../core/components/app-auto-complete/app-auto-complete.component';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { OptionListModel } from './../../core/model/option-list-model';
import { ItemResponse } from './item-response';

@Component({
  templateUrl: 'item-edit-add.component.html',
  styleUrls: ['./item-edit-add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ItemEditAddComponent extends BaseModuleComponent {
  @ViewChild(AppAutocompleteComponent)
  public autoComplete: AppAutocompleteComponent;
  public itemId: number;
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(true);
  public uomOptionList: OptionListModel<Uom> = new OptionListModel(true);
  public autoCompleteValue: any;
  public itemType: any;
  public isValid = true;
  public isChooseMinPrice = false;
  public isChooseMaxPrice = false;
  public urlBackOutside: string;
  public activityStatus: string;
  public itemStatus: string;
  public approvalModelPrcsId: number;
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public ItemResponse: ItemResponse = new ItemResponse();
  public token: string;
  constructor(translateService: TranslateService,
    public activatedRoute: ActivatedRoute) {
    super('item', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.doBuildFormGroup();
      this.doSetDataFromToken();
    } else {
      this.doSetDataFromRouterParams();
      this.doBuildFormGroup();
      this.setFormGroup();
    }
  }

  public doSetDataFromToken(): void {
    this.httpClientService
      .get<Item>('/item/notification/' + this.token)
      .subscribe((item: Item) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('itemId', item.id);
        if(this.global.userSession.activeUserRole.role.code === this.global.appConstant.ROLE_CODE_APPROVER_CATALOG){
          this.global.routerParams.set('todo', 'view');
          this.global.routerParams.set('urlBackOutside', '/pages/approval-prcs');
          this.global.routerParams.set('activityStatus', 'APPROVAL');
          if(item.workflowPrcs.workflowStatus.code !== this.global.appConstant.core.WORKFLOW_CODE_STATUS_REVISION &&
            item.workflowPrcs.workflowStatus.code !== this.global.appConstant.core.WORKFLOW_CODE_STATUS_FINISH){
            this.router.navigate(['/pages/item/detail']);
          }else{
            this.router.navigate(['/pages/approval-prcs']);
          }
        }else{
          if(item.workflowPrcs.workflowStatus.code === this.global.appConstant.core.WORKFLOW_CODE_STATUS_ON_APPROVAL){
            this.global.routerParams.set('todo', 'view');
          }else{
            this.global.routerParams.set('todo', 'edit');
          }
          this.global.routerParams.set('urlBackOutside', '/pages/item');
          this.router.navigate(['/pages/item/detail']);
        }
      });
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.itemId = this.global.routerParams.get('itemId');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    this.activityStatus = this.global.routerParams.get('activityStatus');
    this.approvalModelPrcsId = this.global.routerParams.get('approvalModelPrcsId');
    var objectId = this.global.routerParams.get('objectId');
    if (objectId) {
      this.itemId = objectId;
    }
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null, Validators.required()],
      name: [null, Validators.required()],
      itemType: [null, Validators.required()],
      uom: [null, Validators.required()],
      category: [null, Validators.required()],
      description: [null],
      minPrice: [null],
      maxPrice: [null],
      dose: [null, Validators.required()],
      isActive: [null]
    });
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ItemResponse>(
        '/item/add-edit',
        new RouteRequestModel(this.todo, this.itemId)
      )
      .subscribe((ItemResponse: ItemResponse) => {
        this.ItemResponse = ItemResponse;
        this.itemTypeOptionList.setRequestValues(ItemResponse.itemTypeList);
        this.uomOptionList.setRequestValues(ItemResponse.uomList);
        this.autoCompleteValue = ItemResponse.item && ItemResponse.item.uom;
        if (ItemResponse.item != null) {
          this.workflowModelPrcs = ItemResponse.item.workflowModelPrcs;
          this.itemStatus = ItemResponse.item.itemStatus.code;
          const indexItemType = ItemResponse.itemTypeList.findIndex(
            (itemType: ItemType) =>
              itemType.id === ItemResponse.item.itemType.id
          );
          const { code, name, description, id, uom , minPrice, maxPrice, dose, isActive } = ItemResponse.item;
          this.formGroup.patchValue({
            code,
            name,
            description,
            id,
            uom: uom.name,
            itemType: ItemResponse.itemTypeList[indexItemType],
            category: ItemResponse.categoryList,
            minPrice,
            maxPrice,
            dose,
            isActive
          });
          if (this.itemStatus !== this.global.appConstant.core.ITEM_STATUS_REVISION) {
            this.onClickisActive(ItemResponse.item.isActive);
          }
        }
        this.setStateReady();
      });
    if (this.todo === 'edit') {
      this.formGroup.get('code').setIsView(true);
      if (this.itemStatus === this.global.appConstant.core.ITEM_STATUS_REVISION) {
        this.formGroup.get('name').setIsView(false);
        this.formGroup.get('description').setIsView(false);
        this.formGroup.get('uom').setIsView(false);
        this.formGroup.get('itemType').setIsView(false);
        this.formGroup.get('category').setIsView(false);
        this.formGroup.get('minPrice').setIsView(false);
        this.formGroup.get('maxPrice').setIsView(false);
        this.formGroup.get('dose').setIsView(false);
      }
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.isValid = true;
            const maxPrice = this.formGroup.get('maxPrice').value;
            const minPrice = this.formGroup.get('minPrice').value;
            if (this.isChooseMaxPrice) {
              if (maxPrice === 0) {
                this.formGroup.patchValue({maxPrice: null});
              }
            }
            if (this.isChooseMinPrice) {
              if (minPrice === 0) {
                this.formGroup.patchValue({minPrice: null});
              }
            }
            if (maxPrice !== minPrice) {
              if (maxPrice < minPrice) {
                this.isValid = false;
                this.global.alertService.showError(
                  'item.validationMaxPrice'
                );
              }
            } else {
              if ((maxPrice !== null && minPrice !== null) && (maxPrice !== 0 && minPrice !== 0)) {
                if (maxPrice === minPrice) {
                  this.isValid = false;
                  this.global.alertService.showError(
                  'item.validationSamePrice'
                  );
                }
              }
            }
            if (this.isValid) {
              this.setStateProcessing();
              const item: Item = this.global.copyFormAttributeToModel(
                new Item(),
                this.formGroup
              );
              if (this.todo !== 'add') {
                const uom: Uom = new Uom();
                uom.id = this.autoCompleteValue.id;
                item.uom = uom;
                item.uom.id = this.autoCompleteValue.id;
              }
              const categoryList: ItemCategory[] =
                this.formGroup.get('category').value;
              item.itemCategory = categoryList[categoryList.length - 1];
              const url = this.todo === 'edit' ? '/item/update' : '/item/insert';
              this.httpClientService
                .post<Response<Item>>(url, item)
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.router.navigate(['/pages/item']);
                    this.global.alertService.showSuccessSavingOnNextRoute();
                  } else {
                    this.setStateReady();
                    this.global.alertService.showError(response.statusText);
                  }
                });
            }
          }
        });
    }
  }

  public autoCompleteValueChange(event: any): void {
    this.autoCompleteValue = event;
  }

  public doChooseMinPrice(): void {
    this.isChooseMinPrice = true;
  }

  public doChooseMaxPrice(): void {
    this.isChooseMaxPrice = true;
  }

  public doCancel(): void {
    if(this.urlBackOutside){
      this.router.navigate([this.urlBackOutside]);
    }else{
      this.router.navigate(['/pages/item']);
    }
    
  }

  public onClickisActive(data): void {
    if(data){
      this.formGroup.get('name').setIsView(false);
      this.formGroup.get('description').setIsView(false);
      this.formGroup.get('uom').setIsView(false);
      this.formGroup.get('itemType').setIsView(false);
      this.formGroup.get('category').setIsView(false);
      this.formGroup.get('minPrice').setIsView(false);
      this.formGroup.get('maxPrice').setIsView(false);
      this.formGroup.get('dose').setIsView(false);
    }else{
      this.formGroup.get('name').setIsView(true);
      this.formGroup.get('description').setIsView(true);
      this.formGroup.get('uom').setIsView(true);
      this.formGroup.get('itemType').setIsView(true);
      this.formGroup.get('category').setIsView(true);
      this.formGroup.get('minPrice').setIsView(true);
      this.formGroup.get('maxPrice').setIsView(true);
      this.formGroup.get('dose').setIsView(true);
      this.setFormGroupCustom(data);
    }
  }

  public setFormGroupCustom(data): void {
    var ItemResponse = this.ItemResponse;
    const { code, name, description, id, uom , minPrice, maxPrice, dose, itemType } = ItemResponse.item;
    this.formGroup.patchValue({
      code,
      name,
      description,
      id,
      uom: uom.name,
      itemType: itemType,
      category: ItemResponse.categoryList,
      minPrice,
      maxPrice,
      dose,
      isActive: data
    });
    this.setStateReady();
  }
}
