import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorAddressHistory } from '../../core/bean/vendor-address-history';
import { VendorHistory } from '../../core/bean/vendor-history';
import { VendorPICHistory } from '../../core/bean/vendor-pic-history';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { AppPopupTabCompanyDataAddressComponent } from './app-popup-tab-company-data-address.component';
import { TabCompanyDataDTO } from './dto/tab-company-data.dto';
@Component({
  templateUrl: './tab-company-data.component.html'
})
export class TabCompanyDataComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<TabCompanyDataDTO>;
  public vendorHistory: VendorHistory = new VendorHistory();
  public tableAddressResponse: TableResponseModel<VendorAddressHistory>;
  public tablePICResponse: TableResponseModel<VendorPICHistory>;
  public tabCompanyDataDto: TabCompanyDataDTO;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('tab-company-data', translateService);
  }

  onInit(): void {
    this.buildTableAddress();
    this.buildTablePIC();
    this.doSetCustomDataAddress();
    this.doSetCustomDataPIC();
    this.doGetAndSetCompanyProfileDTO();
  }

  public doGetAndSetCompanyProfileDTO(): void {
    this.httpClientService
      .get<TabCompanyDataDTO>('/tab-company-data/index/' + this.vendorHistoryId)
      .subscribe(tabCompanyDataDto => {
        this.tabCompanyDataDto = tabCompanyDataDto;
        this.doSetVendor();
        this.model.setStateReady();
      });
  }

  public doSetVendor(): void {
    if (this.tabCompanyDataDto.vendorHistory !== null) {
      this.vendorHistory = this.tabCompanyDataDto.vendorHistory;
    }
  }

  public buildTableAddress(): void {
    this.tableAddressResponse = new TableResponseModel(this.moduleCode, [
      { field: '0.buildingName', header: 'table.column.buildingName' },
      {
        field: '0.buildingType.name',
        header: 'table.column.buildingType',
        customClass: 'text-center'
      },
      {
        field: '0.resourceOwnership.name',
        header: 'table.column.resourceOwnership',
        customClass: 'text-center'
      },
      {
        field: '0.size',
        header: 'table.column.size',
        customClass: 'text-right'
      },
      {
        field: '0.countryRegion.name',
        header: 'table.column.country',
        customClass: 'text-center'
      },
      {
        field: '0.provinceRegion.name',
        header: 'table.column.province',
        customClass: 'text-center'
      },
      { field: '1', header: 'table.column.phone', customClass: 'text-center' }
    ]);
  }

  public buildTablePIC(): void {
    this.tablePICResponse = new TableResponseModel(this.moduleCode, [
      { field: '0.name', header: 'table.column.picName' },
      {
        field: '1',
        header: 'table.column.picPhoneNumber',
        customClass: 'text-center'
      },
      { field: '0.email', header: 'table.column.picEmail' },
      {
        field: '0.vendorPosition.name',
        header: 'table.column.picPosition',
        customClass: 'text-center'
      }
    ]);
  }

  public doSetCustomDataAddress(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableAddressResponse.setCustomData(customData);
  }

  public doSetCustomDataPIC(): void {
    const customData = { id: this.vendorHistoryId };
    this.tablePICResponse.setCustomData(customData);
  }

  public doDetail(vendorAddressHistory: any): void {
    this.appPopupService.open(
      AppPopupTabCompanyDataAddressComponent,
      { vendorAddressHistory: vendorAddressHistory[0] },
      { size: 'lg', backdrop: 'static' }
    );
  }
}
