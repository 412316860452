<app-layout>
    <div class="row main-row">
        <div class="col-lg-12 mb-4">
            <div *ngIf="!formLoading" style="
          border-bottom: 1px solid var(--light-tertiary-color);
          padding-top: 32px;
        ">
                <app-content-slider>
                    <app-content-slider-item>
                        <ng-template #customHeader>
                    	    <div></div>
                        </ng-template>
                        <ng-template #customContent>
                            <div *ngIf="
                                (roleCode === global.appConstant.ROLE_CODE_SUPER_ADMIN || 
                                roleCode === global.appConstant.ROLE_CODE_PJB_PBJ_PUSAT ||
                                roleCode === global.appConstant.ROLE_CODE_BUYER ||
                                roleCode === global.appConstant.ROLE_CODE_DELIVERY)" 
                                class="slider-item-report cursor-pointer"
                                (click)="doClickHistoryType('item')" [ngStyle]="
                                historyType === 'item'
                                ? { border: '1px solid var(--primary)' }
                                : ''
                            ">
                                <div class="slider-title">
                                    <div class="slider-title-icon pvc pv-order-process"></div>
                                    <h4 class="text-primary">
                                        {{
                                        'sla-core.slider.item.title' | translate
                                    }}</h4>
                                </div>
                            </div>
                        </ng-template>
                    </app-content-slider-item>
                </app-content-slider>
            </div>
            <app-table-xx *ngIf="formGroup.get('historyType').value === 'item'"
                [model]="tableResponseItem" stringUrl="/sla-core/index" (onClick)="doClick($event)">
                <ng-template #headerFilterGroup>
                    <form class="form-horizontal" [formGroup]="formGroup" novalidate *ngIf="!formLoading">
                        <div class="form-group row text-sm-left">
                            <div class="col-lg-12 row" style="margin-bottom: -1.5rem">
                                <label class="cml-3 d-flex align-items-center"
                                    style="margin-top: auto">{{ 'sla-core.form.date' | translate }}</label>
                                <div class="cml-3 text-left">
                                    <app-date-picker-x size="XL" formControlName="date" [range]="true"
                                        (onChange)="onChangeFilter()">
                                    </app-date-picker-x>
                                </div>
                                <div class="cml-3 text-left col-sm-2">
                                    <app-dropdown-select size="XL" formControlName="workflowName"
                                        (onChange)="onChangeFilter()" showLimitValue="1" type="CHOSEN" isMultiple="true"
                                        placeholder="sla-core.placeholder.workflowName" [optionList]="workflowList">
                                        <ng-template #selectAll>
                                            <p>
                                                {{
                                        'sla-core.placeholder.allWorkflowName' | translate
                                    }}
                                            </p>
                                        </ng-template>
                                    </app-dropdown-select>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </app-table-xx>
        </div>
    </div>
</app-layout>