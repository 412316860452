import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTaggingComponent } from './app-tagging.component';
@NgModule({
  imports: [VendorModule, PipesModule, DirectivesModule],
  declarations: [AppTaggingComponent],
  entryComponents: [AppTaggingComponent],
  exports: [AppTaggingComponent]
})
export class AppTaggingModule {}
