import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../../core/shared/shared.module';
import { InterviewComponent } from './interview.component';
const routes: Routes = [{ path: '', component: InterviewComponent, data: { breadcrumb: '' } }];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [InterviewComponent]
})
export class InterviewModule {}
