import { Payment } from 'src/app/core/bean/payment';
import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { PaymentHistory } from 'src/app/core/bean/payment-history';
import { PaymentInvoice } from 'src/app/core/bean/payment-invoice';
import { SowPaymentTermBilling } from 'src/app/core/bean/sow-payment-term-billing';
import { StageOfWork } from 'src/app/core/bean/stage-of-work';
import { WorkflowStepInfoModel } from 'src/app/core/components/app-workflow-step-info/model/workflow-step-info-model';
import { FileObject } from 'src/app/core/components/upload';
import { PaymentBillingDoc } from '../../core/bean/payment-billing-doc';

export class InternalPaymentRequestEditAddResponse {
  payment: Payment;
  paymentHistory: PaymentHistory;
  paymentInvoice: PaymentInvoice;
  paymentBilling: PaymentBilling = new PaymentBilling();
  workflowStepInfoModel: WorkflowStepInfoModel;
  stageOfWork: StageOfWork = new StageOfWork();
  paymentBillingDocList: PaymentBillingDoc[];
  sowPaymentTermBillingList: SowPaymentTermBilling[];
  fileObjectList: FileObject[];
  isUserApproval: boolean;
  sowObjectId: number;
}
