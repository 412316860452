import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { DiagnosePackageComponent } from './diagnose-package.component';
import { DiagnosePackageEditAddComponent } from './diagnose-package-edit-add.component';
export const routes = [
  { path: '', component: DiagnosePackageComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: DiagnosePackageEditAddComponent,
    data: { breadcrumb: 'diagnose-package.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: DiagnosePackageEditAddComponent,
    data: { breadcrumb: 'diagnose-package.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [DiagnosePackageComponent, DiagnosePackageEditAddComponent],
  providers: []
})
export class DiagnosePackageModule { }
