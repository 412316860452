<app-popup header="price-evaluation.popup.oep" [isLoading]="formLoading">
  <app-table-xx
    [model]="tableResponse"
    [isShowSearch]="false"
    [isShowPagination]="false"
    *ngIf="!formLoading"
  >
    <ng-template #row>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td class="text-right" colspan="3">
          {{ 'price-evaluation.popup.table.footer.grandTotal' | translate }}
        </td>
        <td colspan="99" class="text-right">
          <strong>
            {{ popupOepResponse.amount | resolveNumberToCurrency }}
            IDR
          </strong>
        </td>
      </tr>
    </ng-template>
  </app-table-xx>
  <h3>{{ 'price-evaluation.popup.header.document' | translate }}</h3><br/>
  <form [formGroup]="formGroup">
    <app-upload-x
      formControlName="oepFileList"
      [fileUploader]="fileUploader"
    ></app-upload-x>
  </form>
</app-popup>
