import { BaseEntity } from '../base/base-entity';
import { EquipmentCondition } from '../bean/equipment-condition';
import { EquipmentType } from '../bean/equipment-type';
import { Region } from '../bean/region';
import { ResourceOwnership } from '../bean/resource-ownership';
import { Uom } from '../bean/uom';
import { Vendor } from '../bean/vendor';
import { VendorEquipmentDoc } from '../bean/vendor-equipment-doc';

export class VendorEquipmentEntity extends BaseEntity {
    vendorEquipmentDocList: VendorEquipmentDoc[] = [];
    regionList: Region[];
    regionNameList: string;
    capacityUom: string;

    equipmentType: EquipmentType;
    uom: Uom;
    region: Region;
    equipmentCondition: EquipmentCondition;
    resourceOwnership: ResourceOwnership;
    vendor: Vendor;
    quantity: number;
    capacity: number;
    brand: string;
    year: number;
}
