import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { ItemType } from 'src/app/core/bean/item-type';
import { Procurement } from 'src/app/core/bean/procurement';
import { Quotation } from 'src/app/core/bean/quotation';
import { QuotationCriteria } from 'src/app/core/bean/quotation-criteria';
import { QuotationItem } from 'src/app/core/bean/quotation-item';
import { ValidityStatus } from 'src/app/core/bean/validity-status';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { DownloadService } from 'src/app/core/services/download.service';
import { Validators } from 'src/app/core/validators';
import { OpenQuotationDetailResponse } from './open-quotation-detail.response';
import { OpenQuotationUpdateDetailRequest } from './open-quotation-update-detail.request';

@Component({
  templateUrl: './open-quotation-vendor.component.html',
  encapsulation: ViewEncapsulation.None
})
export class OpenQuotationVendorComponent extends BaseModuleComponent {
  public quotationId: number;
  public vendorId: number;
  public vendorName: string;
  public urlBack: string;
  public quotation: Quotation;
  public procurement: Procurement;
  public header: string;
  public isStatusChoosen: boolean;
  public quotationDetailResponse: OpenQuotationDetailResponse;
  public validityStatusOptionList: OptionListModel<ValidityStatus> =
    new OptionListModel(true);
  public fileUploaderBidBond: FileUploader;
  public fileUploaderAdm: FileUploader;
  public tableResponseQuotationItem: TableResponseModel<QuotationItem>;
  public tableResponseAdmCriteria: TableResponseModel<QuotationCriteria>;
  public tableResponseTechnicalCriteria: TableResponseModel<QuotationCriteria>;
  public itemTypeOptionList: OptionListModel<ItemType> = new OptionListModel(
    false
  );
  public isFailedAssessment: boolean;
  public isCancelation: boolean;

  public getColorAndIconConfirm = {
    VALID: { color: 'text-success', icon: 'pic pi-thumbs-up-circle' },
    INVALID: { color: 'text-danger', icon: 'pic pi-times-circle' }
  };

  public constructor(
    translateService: TranslateService,
    public downloadService: DownloadService
  ) {
    super('open-quotation', translateService);
  }

  public PROFIT_LOSS_STATUS_SAVE_NAME = 'Save';
  public PROFIT_LOSS_STATUS_LOSS_NAME = 'Loss';

  public onInit(): void {
    this.getDataFromRouterParams();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      validityStatus: [null, Validators.required()],
      invalidNotes: [null],
      documentBidBond: [null],
      documentAdministration: [null],
      validityPeriodAdm: [null],
      validityPeriodBidBond: [null],
      itemType: [null],
      amount: [null],
      goodsAmount: [null],
      serviceAmount: [null],
      amountPotensial: [null],
      goodsAmountPotensial: [null],
      serviceAmountPotensial: [null],
      amountPotensialStatus: [null],
      goodsAmountPotensialStatus: [null],
      serviceAmountPotensialStatus: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponseQuotationItem = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementItem.prItem.prItemImageList',
        header: 'table.column.thumbnail',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePluginData: TablePluginData): File => {
              if (tablePluginData.value && tablePluginData.value.length > 0) {
                return tablePluginData.value[0].file;
              }
              return null;
            }
          },
          {
            name: 'img',
            classNameMap: {
              MATERIAL: 'pvr pv-box',
              JASA: 'pvr pv-tools'
            },
            fieldClassName: 'procurementItem.prItem.item.itemType.code'
          }
        ],
        isSortable: false,
        isSearchTable: false,
        className: 'text-center'
      },
      {
        field: 'procurementItem.prItem.item.name',
        header: 'table.column.itemName'
      },
      {
        field: 'procurementItem.prItem.item.code',
        header: 'table.column.itemCode'
      },
      {
        field: 'procurementItem.prItem.item.itemType.name',
        header: 'table.column.type',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            MATERIAL: 'GOOD',
            JASA: 'SERVICE'
          },
          colorField: 'procurementItem.prItem.item.itemType.code'
        }
      },
      {
        field: 'procurementItem.prItem.quantity',
        header: 'table.column.quantity',
        className: 'text-right',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          isView: true
        }
      },
      {
        field: 'procurementItem.prItem.item.uom.name',
        header: 'table.column.unit'
      },
      {
        field: 'price',
        header: 'table.column.bidPrice',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      {
        field: 'totalPrice',
        header: 'table.column.totalBidPrice',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      {
        field: 'profitLossAmount',
        header: 'table.column.potensialSavingLoss',
        className: 'd-flex justify-content-end',
        plugins: [
          {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): void => {
              if (tablePlugin.value) {
                tablePlugin.element.innerText =
                  this.global.converterService.convertMoney(tablePlugin.value) +
                  ' %';
              }
            }
          },
          {
            name: 'badge',
            field: 'profitLossStatus.name',
            colorMap: {
              SAVE: 'SUCCESS',
              LOSS: 'DANGER'
            },
            pill: false,
            colorField: 'profitLossStatus.code',
            className: 'badge-catalog ml-3'
          }
        ]
      }
    ]);

    this.tableResponseAdmCriteria = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementCriteriaDetail.evaluationCriteria.name',
        header: 'table.column.administrationCriteria'
      },
      {
        field: 'docFile',
        header: 'table.column.value',
        plugins: [
          {
            name: 'upload',
            fileUploader: new FileUploader(
              '/' + this.moduleCode + '/',
              '',
              null
            ),
            isView: true
          },
          {
            name: 'custom-plugin',
            after: (tablePlugin: TablePluginData): void => {
              if (!tablePlugin.value) {
                tablePlugin.element.innerText = tablePlugin.row.record.value;
              }
            }
          }
        ]
      },
      {
        field: 'note',
        header: 'table.column.noteFromVendor'
      }
    ]);

    this.tableResponseTechnicalCriteria = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'procurementCriteriaDetail.evaluationCriteria.name',
          header: 'table.column.technicalCriteria'
        },
        {
          field: 'docFile',
          header: 'table.column.value',
          plugins: [
            {
              name: 'upload',
              fileUploader: new FileUploader(
                '/' + this.moduleCode + '/',
                '',
                null
              ),
              isView: true
            },
            {
              name: 'custom-plugin',
              after: (tablePlugin: TablePluginData): void => {
                if (!tablePlugin.value) {
                  tablePlugin.element.innerText = tablePlugin.row.record.value;
                }
              }
            }
          ]
        },
        {
          field: 'note',
          header: 'table.column.noteFromVendor'
        }
      ]
    );
  }

  public getDataFromRouterParams(): void {
    this.quotationId = this.global.routerParams.get('quotationId');
    this.todo =
      this.global.routerParams.get('todoQuotation') ||
      this.global.routerParams.get('todo');
    this.procurement = this.global.routerParams.get('procurement');
    this.vendorId = this.global.routerParams.get('vendorId');
    this.vendorName = this.global.routerParams.get('vendorName');
    this.urlBack = this.global.routerParams.get('backToQuotation');
    this.header =
      this.global.translateService.instant(
        'open-quotation.header.quotationDetail'
      ) +
      ' ' +
      this.vendorName;
    this.isFailedAssessment =
      this.global.routerParams.get('isFailedAssessment');
    this.isCancelation = this.global.routerParams.get('isCancelation');
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<OpenQuotationDetailResponse>(
        '/open-quotation/quotation-detail/' + this.quotationId
      )
      .subscribe((response: OpenQuotationDetailResponse) => {
        this.quotationDetailResponse = response;
        this.tableResponseQuotationItem.setRecordList(
          response.quotationItemList
        );
        this.tableResponseAdmCriteria.setRecordList(
          response.administrationCriteriaList
        );
        this.tableResponseTechnicalCriteria.setRecordList(
          response.technicalCriteriaList
        );
        this.itemTypeOptionList.setRequestValues(response.itemTypeList);
        this.validityStatusOptionList.setRequestValues(
          response.validityStatusList
        );
        this.quotation = response.quotation;
        if (
          response.quotation.validityStatus.code !==
          this.global.appConstant.pm.VALIDITY_STATUS_WAITING_VERIFICATION
        ) {
          this.formGroup.patchValue({
            validityStatus: response.quotation.validityStatus,
            invalidNotes: response.quotation.invalidNote
          });
          if (this.todo === 'view') {
            this.formGroup.setIsView(true);
          }
          this.isStatusChoosen = true;
        }
        if (response.quotationBidBond) {
          this.fileUploaderBidBond = new FileUploader(
            '/open-quotation/',
            '',
            response.quotationBidBond.docFile.fileType.code
          );
          this.fileUploaderBidBond.setFile(response.quotationBidBond.docFile);
          this.formGroup.patchValue({
            documentBidBond: this.fileUploaderBidBond.fileObjectList,
            validityPeriodBidBond: {
              from: response.quotationBidBond.startDate,
              to: response.quotationBidBond.endDate
            }
          });
          this.formGroup.get('documentBidBond').setIsView(true);
          this.formGroup.get('validityPeriodBidBond').setIsView(true);
        }
        if (response.quotationAdministration) {
          this.fileUploaderAdm = new FileUploader(
            '/open-quotation/',
            '',
            response.quotationAdministration.docFile.fileType.code
          );
          this.fileUploaderAdm.setFile(
            response.quotationAdministration.docFile
          );
          this.formGroup.patchValue({
            documentAdministration: this.fileUploaderAdm.fileObjectList,
            validityPeriodAdm: {
              from: response.quotationAdministration.startDate,
              to: response.quotationAdministration.endDate
            }
          });
          this.formGroup.get('documentAdministration').setIsView(true);
          this.formGroup.get('validityPeriodAdm').setIsView(true);
        }
        this.formGroup.get('itemType').setIsView(false);
        this.doSetAmount();
        this.setStateReady();
        if (this.todo === 'view') {
          this.formGroup.setIsView(true);
        }
      });
  }

  public doChangeStatus(): void {
    if (
      this.formGroup.value.validityStatus?.code ===
      this.global.appConstant.pm.VALIDITY_STATUS_INVALID
    ) {
      this.formGroup.get('invalidNotes').setValidators(Validators.required());
      this.formGroup.get('invalidNotes').updateValueAndValidity();
    } else {
      this.formGroup.get('invalidNotes').clearValidators();
      this.formGroup.get('invalidNotes').updateValueAndValidity();
    }

    if (this.formGroup.value.validityStatus) {
      this.quotation.validityStatus = this.formGroup.value.validityStatus;
      this.isStatusChoosen = true;
    } else {
      this.isStatusChoosen = false;
    }
  }

  public onChangeItem(): void {
    const itemType = this.formGroup.value.itemType;
    if (itemType) {
      const quotationItemList = [];
      this.quotationDetailResponse.quotationItemList.forEach(
        (quotationItem: QuotationItem) => {
          if (
            quotationItem.procurementItem.prItem &&
            quotationItem.procurementItem.prItem.item.itemType.id ===
              itemType.id
          ) {
            quotationItemList.push(quotationItem);
          }
        }
      );
      this.tableResponseQuotationItem.setRecordList(quotationItemList);
    } else {
      this.tableResponseQuotationItem.setRecordList(
        this.quotationDetailResponse.quotationItemList
      );
    }
    this.tableResponseQuotationItem.reload();
    this.doSetAmount();
  }

  public doSetAmount(): void {
    let goodsAmountTotal = 0;
    let serviceAmountTotal = 0;
    let goodAmountPotensialTotal = 0;
    let serviceAmountPotensialTotal = 0;
    let amountPotensialTotal = 0;
    let countGoods = 0;
    let countService = 0;
    this.tableResponseQuotationItem
      .getRecordList()
      .forEach((quotationItem: QuotationItem) => {
        if (
          quotationItem.procurementItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
        ) {
          goodsAmountTotal = +goodsAmountTotal + +quotationItem.totalPrice;
          goodAmountPotensialTotal =
            +goodAmountPotensialTotal + +quotationItem.profitLossAmount;
          countGoods += 1;
        }
        if (
          quotationItem.procurementItem.prItem.item.itemType.code ===
          this.global.appConstant.core.ITEM_TYPE_CODE_SERVICE
        ) {
          serviceAmountTotal = +serviceAmountTotal + +quotationItem.totalPrice;
          serviceAmountPotensialTotal =
            +serviceAmountPotensialTotal + +quotationItem.profitLossAmount;
          countService += 1;
        }
      });
    goodAmountPotensialTotal = goodAmountPotensialTotal / countGoods;
    serviceAmountPotensialTotal = serviceAmountPotensialTotal / countService;
    if (+goodAmountPotensialTotal && +serviceAmountPotensialTotal) {
      amountPotensialTotal =
        +goodAmountPotensialTotal + +serviceAmountPotensialTotal;
    } else if (+goodAmountPotensialTotal) {
      amountPotensialTotal = +goodAmountPotensialTotal;
    } else if (+serviceAmountPotensialTotal) {
      amountPotensialTotal = +serviceAmountPotensialTotal;
    }
    this.formGroup.patchValue({
      goodsAmount: goodsAmountTotal,
      serviceAmount: serviceAmountTotal,
      amount: +goodsAmountTotal + +serviceAmountTotal,
      goodsAmountPotensial: +goodAmountPotensialTotal,
      serviceAmountPotensial: +serviceAmountPotensialTotal,
      amountPotensial: amountPotensialTotal,
      amountPotensialStatus:
        amountPotensialTotal >= 0
          ? this.PROFIT_LOSS_STATUS_SAVE_NAME
          : this.PROFIT_LOSS_STATUS_LOSS_NAME,
      goodsAmountPotensialStatus: +goodAmountPotensialTotal
        ? +goodAmountPotensialTotal >= 0
          ? this.PROFIT_LOSS_STATUS_SAVE_NAME
          : this.PROFIT_LOSS_STATUS_LOSS_NAME
        : this.PROFIT_LOSS_STATUS_SAVE_NAME,
      serviceAmountPotensialStatus: +serviceAmountPotensialTotal
        ? +serviceAmountPotensialTotal >= 0
          ? this.PROFIT_LOSS_STATUS_SAVE_NAME
          : this.PROFIT_LOSS_STATUS_LOSS_NAME
        : this.PROFIT_LOSS_STATUS_SAVE_NAME
    });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const request = new OpenQuotationUpdateDetailRequest();
            request.quotationId = this.quotationId;
            request.validityStatus = this.formGroup.value.validityStatus;
            request.invalidNote = this.formGroup.value.invalidNotes;
            request.vendorId = this.vendorId;
            this.httpClientService
              .post<Response<Quotation>>(
                '/open-quotation/update-detail',
                request
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.setStateReady();
                  if (this.todo === 'add') {
                    this.router
                      .navigateByUrl('/', { skipLocationChange: true })
                      .then(() => {
                        this.router.navigate(['/pages/open-quotation/add']);
                      });
                  } else if (this.todo === 'edit') {
                    this.router
                      .navigateByUrl('/', { skipLocationChange: true })
                      .then(() => {
                        this.router.navigate(['/pages/open-quotation/edit']);
                      });
                  } else {
                    this.router
                      .navigateByUrl('/', { skipLocationChange: true })
                      .then(() => {
                        this.router.navigate(['/pages/open-quotation/detail']);
                      });
                  }
                }
              });
          }
        });
    }
  }

  public doDownloadAll(event: any, type: string): void {
    const fileList = [];
    if (type === 'ADMINISTRATION') {
      this.quotationDetailResponse.administrationCriteriaList.forEach(
        admCriteria => {
          if (admCriteria.docFile) {
            fileList.push(admCriteria.docFile.uploadedFileName);
          }
        }
      );
    } else {
      this.quotationDetailResponse.technicalCriteriaList.forEach(
        techCriteria => {
          if (techCriteria.docFile) {
            fileList.push(techCriteria.docFile.uploadedFileName);
          }
        }
      );
    }

    if (fileList.length !== 0) {
      this.doDownloadToRar(
        event,
        '/open-quotation/file/view-rar',
        fileList,
        'Dokumen_Quotation.rar'
      );
    }
  }

  public doViewProfile(): void {
    const stringTodo =
      this.todo === 'add' || this.todo === 'edit' ? this.todo : 'detail';
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/open-quotation/' + stringTodo + '/quotation-detail'
    );
    this.global.routerParams.set('backToWorklist', '/pages/worklist-pm');
    this.router.navigate(['/pages/company-profile']);
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }
}
