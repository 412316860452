import { AppFullfilledModule } from './../../core/components/app-fullfilled/app-fullfilled.module';
import { SlaPmDetailComponent } from './sla-pm-detail.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { SlaPmComponent } from './sla-pm.component';
import { AppSlaInfoModule } from 'src/app/core/components/app-sla-info/app-sla-info.module';
import { AppPopupAdjustmentPmComponent } from './app-popup-adjustment-pm.component';

export const routes = [
    { path: '', component: SlaPmComponent, data: { breadcrumb: '' } },
    {
        path: 'detail',
        component: SlaPmDetailComponent,
        data: { breadcrumb: 'sla-pm.breadcrumb.detail' }
    },
    {
        path: 'app-popup-adjustment-vendor',
        component: AppPopupAdjustmentPmComponent,
        data: { breadcrumb: 'sla-vendor.breadcrumb.adjustment' }
    }
];

@NgModule({
    imports: [SharedModule, RouterModule.forChild(routes), AppSlaInfoModule, AppFullfilledModule],
    declarations: [
        SlaPmComponent,
        SlaPmDetailComponent,
        AppPopupAdjustmentPmComponent
    ],
    providers: []
})
export class SlaPmModule { }
