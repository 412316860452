import { TabState } from '../interfaces/tab-state.interface';
import { addTabItem } from './add-tab-item.action';
import { changeTabItemDisabled } from './change-tab-item-disabled.action';
import { changeTabItemSelected } from './change-tab-item-selected.action';
import * as tabAction from './tab.action';
export function dispatchTab(tabState: TabState, action: tabAction.All): void {
  switch (action.type) {
    case tabAction.ADD_TAB_ITEM:
      addTabItem(tabState, action.payload);
      break;
    case tabAction.CHANGE_TAB_ITEM_DISABLED:
      changeTabItemDisabled(tabState, action.payload);
      break;
    case tabAction.CHANGE_TAB_ITEM_SELECTED:
      changeTabItemSelected(tabState, action.payload);
      break;
    default:
      console.log(`Info: There's no type`);
      break;
  }
}
