import { Component } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { AppLoadService } from '../../core/services/app-load.service';
import { SlaJustificationLog } from './../../core/bean/sla-justification-log';
import { WorkPlan } from './../../core/bean/work-plan';
import { FieldFormatEnum } from './../../core/components/app-table/model/field-format.enum';
import { PopupJustificationComponent } from './popup-justification.component';
@Component({
  templateUrl: './reporting-sla-work-plan-detail.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class ReportingSlaWorkPlanDetailComponent extends BaseModuleComponent {
  public tableResponseWorkflowModelPrcs: TableResponseModel<WorkflowModelPrcs>;
  public tableResponseSlaJustificationLog: TableResponseModel<SlaJustificationLog>;
  // workPlanItem: WorkPlanItem = new WorkPlanItem();
  workPlan: WorkPlan = new WorkPlan();
  workflowPrcsId: number;
  workPlanId: number;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appLoadService: AppLoadService
  ) {
    super('reporting-sla-work-plan', translateService);
  }

  onInit(): void {
    this.todo = this.global.routerParams['todo'];
    this.workPlan = this.global.routerParams['workPlan'];
    // note: error disini dari awal sebelum popup diubah
    // workPlanItem.budget dan workPlanItem.workPlan
    // tidak ada dalam object this.workPlanItem
    this.formGroup = this.formBuilder.group({
      //  'numberBudget': this.workPlanItem.budget.number,
      numberWorkPlan: this.workPlan.number,
      createdDate: new Date(this.workPlan.createdDate),
      finishDate: [null],
      userCreatedName: this.workPlan.user.name
    });

    this.httpClientService
      .post<WorkflowModelPrcs>(
        '/reporting-sla-work-plan/last-workflow-model-prcs',
        new RouteRequestModel(this.todo, this.workPlan.workflowPrcs.id)
      )
      .subscribe(workflowModelPrcs => {
        this.formGroup.patchValue({
          finishDate: workflowModelPrcs.startDate
        });
      });

    this.workflowPrcsId = this.workPlan.workflowPrcs.id;
    this.workPlanId = this.workPlan.id;

    this.tableResponseWorkflowModelPrcs = new TableResponseModel(
      this.moduleCode,
      [
        { field: 'name', header: 'table.column.workflowModelPrcs.name' },
        {
          field: 'startDate',
          header: 'table.column.workflowModelPrcs.createdDate',
          format: FieldFormatEnum.ShortDateAndTime
        },
        {
          field: 'endDate',
          header: 'table.column.workflowModelPrcs.lastDate',
          format: FieldFormatEnum.ShortDateAndTime
        },
        {
          field: 'realizedDate',
          header: 'table.column.workflowModelPrcs.realizeDate',
          format: FieldFormatEnum.ShortDateAndTime
        },
        { field: 'slaInDays', header: 'table.column.workflowModelPrcs.sla' },
        {
          field: 'slaRealizedInMinutes',
          header: 'table.column.workflowModelPrcs.processDayTotal'
        },
        {
          field: 'justification',
          header: 'table.column.workflowModelPrcs.justification'
        }
      ]
    );

    this.tableResponseSlaJustificationLog = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'workflowModelPrcs.name',
          header: 'table.column.workflowModelPrcs.name'
        },
        {
          field: 'userCreated.name',
          header: 'table.column.slaJustificationLog.name'
        },
        {
          field: 'createdDate',
          header: 'table.column.slaJustificationLog.createdDate',
          format: FieldFormatEnum.ShortDateAndTime
        },
        {
          field: 'workflowModelPrcs.slaRealizedInMinutes',
          header:
            'table.column.workflowModelPrcs.processDayTotalBeforeJustificatio'
        },
        {
          field: 'justificationDayTotal',
          header: 'table.column.slaJustificationLog.processDayTotal'
        },
        {
          field: 'justificationAfterDayTotal',
          header: 'table.column.slaJustificationLog.justificationAfterDayTotal'
        },
        {
          field: 'description',
          header: 'table.column.slaJustificationLog.description'
        }
      ]
    );

    this.setStateReady();
  }

  public doCancel(): void {
    this.router.navigate(['/pages/reporting-sla-work-plan']);
  }

  public doAddJustification(workflowModelPrcs: WorkflowModelPrcs): void {
    const workPlan = this.workPlan;
    this.appPopupService
      .open(
        PopupJustificationComponent,
        { workflowModelPrcs, workPlan },
        { size: 'lg', windowClass: 'modal-custom' }
      )
      .subscribe(() => {
        // this.appLoadService.getBackendConfig().then(() => {
        //     this.tableResponseSlaJustificationLog = new TableResponseModel(this.moduleCode,
        //         [
        //             { field: 'workflowModelPrcs.name', header: 'table.column.workflowModelPrcs.name' },
        //             { field: 'createdDate', header: 'table.column.createdDate', format: FieldFormatEnum.ShortDateAndTime },
        //             { field: 'workflowModelPrcs.slaRealizedInMinutes', header: 'table.column.workflowModelPrcs.processDayTotal' },
        //             { field: 'justificationDayTotal', header: 'table.column.justificationDayTotal' },
        //             { field: 'description', header: 'table.column.description' },
        //         ]
        //     );
        // });
        // this.setStateReady();
        this.router.navigate(['/pages/reporting-sla-work-plan']);
      });
  }

  public doExport(): void {}
}
