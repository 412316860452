import {
  Component,
  ElementRef,
  Input,
  Optional,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';

@Component({
  selector: 'app-text-field',
  templateUrl: './app-text-field.component.html',
  styleUrls: ['./app-text-field.component.scss'],
  providers: makeProvider(AppTextFieldComponent),
  encapsulation: ViewEncapsulation.None
})
export class AppTextFieldComponent extends BaseValueAccessorComponent<
  string | number
> {
  @Input() symbolic: string;
  @Input() type: string;
  @Input() min: number;
  @Input() max: number;
  @Input() groupText: string;
  @Input() isHideValid: string;
  appTextFieldType = 'text';
  defaultInputType = ['password', 'number', 'email'];
  divAppTextFieldElement: any;
  previousTextValue: string;
  public CURRENCY_DIGITS_INFO: string;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public renderer2: Renderer2
  ) {
    super('app-text-field', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setAppTextFieldType();
    this.setTextFieldElement();
    this.setMaxLength();
  }

  doSetCurrencyDigitInfo(): void {
    if (this.type === 'currency' || this.type === 'decimal') {
      const CURRENCY_PRECISSION_SCALE =
        this.global.appConstant.core.CURRENCY_PRECISSION_SCALE;
      this.CURRENCY_DIGITS_INFO = `0.${CURRENCY_PRECISSION_SCALE}-${CURRENCY_PRECISSION_SCALE}`;
    }
  }

  public setTextFieldElement(): void {
    this.divAppTextFieldElement = this.elementRef.nativeElement.children[0];
  }

  public setAppTextFieldType(): void {
    if (this.defaultInputType.indexOf(this.type) !== -1) {
      this.appTextFieldType = this.type;
    }
  }

  public doOnInput(value: string | number): void {
    setTimeout(() => {
      const values = this.formControl.value;
      if (this.type === 'number') {
        this.formControl.patchValue(+Number(value));
      }

      this.onInput.emit(values);
    });
  }

  public setMaxLength(): void {
    if (!this.maxLength) {
      if (this.isTinySize) {
        this.maxLength = 4;
      } else if (this.isSmallSize) {
        this.maxLength = 8;
      } else if (this.isMediumSize) {
        this.maxLength = 32;
      } else if (this.isLongSize) {
        this.maxLength = 128;
      }
    }
  }
}
