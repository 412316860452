<form *ngIf="workPlan != null" class="form-horizontal">
  <app-accordion [expandAll]="true" *ngIf="!formLoading">
    <app-accordion-item
      header="app-work-plan-info.form.workplanInfo"
      [hidden]="formLoading"
    >
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'app-work-plan-info.form.number' | translate
        }}</label>
        <label class="col-sm-4 text-left">{{ workPlan.number }}{{ workPlan.generateMemoNumber }}</label>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'app-work-plan-info.form.name' | translate
        }}</label>
        <label class="col-sm-4 text-left">{{ workPlan.name }}</label>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'app-work-plan-info.form.createdDate' | translate
        }}</label>
        <label class="col-sm-4 text-left">{{
          workPlan.createdDate | date: 'dd/MM/yyyy'
        }}</label>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'app-work-plan-info.form.targetDate' | translate
        }}</label>
        <label class="col-sm-4 text-left">{{
          workPlan.workTarget | date: 'dd/MM/yyyy'
        }}</label>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'app-work-plan-info.form.organization' | translate
        }}</label>
        <label class="col-sm-9 text-left">{{
          workPlan.organization.name
        }}</label>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'app-work-plan-info.form.description' | translate
        }}</label>
        <label class="col-sm-4 text-left">{{ workPlan.description }}</label>
      </div>
      <!-- <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'app-work-plan-info.form.document' | translate
        }}</label>
        <div class="col">
          <div
            *ngFor="let workPlanDoc of workPlanDocList; let index = index"
            class="col-12 text-left p-0"
          >
            <a
              href="#"
              (click)="
                doDownload(
                  $event,
                  '/app-work-plan-info/file/view/' +
                    workPlanDoc.file.uploadedFileName +
                    '/' +
                    workPlanDoc.file.fileType.code,
                  workPlanDoc.file.fileName
                )
              "
            >
              {{ workPlanDoc.file.fileName }}
            </a>
          </div>
        </div>
      </div> -->
    </app-accordion-item>
    <app-accordion-item
      header="app-work-plan-info.form.item"
      [hidden]="formLoading"
    >
      <h5 class="separator">{{
        'app-work-plan-info.separator.product' | translate
      }}</h5>
      <app-table
        #selectorProduct
        *ngIf="workPlanId != null"
        [tableResponse]="tableResponseProduct"
      >
        <ng-template #tableFooter>
          <p
            class="
              m-0
              text-right
              border border-top-0
              d-flex
              align-items-center
              justify-content-end
            "
            [ngStyle]="{ height: '3rem', 'padding-right': '2rem' }"
          >
            <span class="mr-2"
              ><strong
                >{{
                  'app-work-plan-info.form.totalProductCost' | translate
                }}
                :</strong
              ></span
            >
            {{
              workPlanInfoRequest.totalProductCost
                ? global.converterService.convertMoney(
                    workPlanInfoRequest.totalProductCost
                  ) +
                  ' ' +
                  workPlanInfoRequest.workPlanItemProductList[0].currency.code
                : '-'
            }}
          </p>
        </ng-template>
      </app-table>
      <h5 class="separator">{{
        'app-work-plan-info.separator.service' | translate
      }}</h5>
      <app-table
        #selectorService
        *ngIf="workPlanId != null"
        [tableResponse]="tableResponseService"
      >
        <ng-template #tableFooter>
          <p
            class="
              m-0
              text-right
              border border-top-0
              d-flex
              align-items-center
              justify-content-end
            "
            [ngStyle]="{ height: '3rem', 'padding-right': '2rem' }"
          >
            <span class="mr-2"
              ><strong
                >{{
                  'app-work-plan-info.form.totalServiceCost' | translate
                }}
                :</strong
              ></span
            >
            {{
              workPlanInfoRequest.totalServiceCost
                ? global.converterService.convertMoney(
                    workPlanInfoRequest.totalServiceCost
                  ) +
                  ' ' +
                  workPlanInfoRequest.workPlanItemServiceList[0].currency.code
                : '-'
            }}
          </p>
        </ng-template>
      </app-table>
      <app-fieldset>
        <p class="text-right">
          <span class="mr-2"
            ><strong
              >{{ 'app-work-plan-info.form.totalCost' | translate }} :</strong
            ></span
          >
          <strong>{{
            totalItemPrice
              ? global.converterService.convertMoney(totalItemPrice) + ' IDR'
              : '-'
          }}</strong>
        </p>
      </app-fieldset>
    </app-accordion-item>
  </app-accordion>
</form>
