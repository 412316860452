import {
  AfterViewChecked,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { AngularTreeModel } from '../../model/angular-tree-model';
import { TextUtils } from '../../utils';
import { AppPopupService } from '../app-popup/app-popup.service';
import { TreeEvent } from './events/tree-event';
import { TreeItem } from './interface/tree-item';
import { TreeItemModel } from './model/tree-item-model';
import { TreeModel } from './model/tree-model';
import { TreeProcessItemModel } from './model/tree-process-item-model';
import { TreeRequestModel } from './model/tree-request-model';
import {
  TreePopupResponseModel
} from './popup/app-tree-popup.component';
import { TreeActionType } from './type/tree-action-type';
import { TreeEventType } from './type/tree-event-type';
@Component({
  selector: 'app-tree-org',
  templateUrl: './app-tree-org.component.html',
  styleUrls: ['./app-tree-org.component.scss']
})
export class AppTreeOrgComponent
  extends BaseComponentComponent
  implements AfterViewChecked, OnDestroy
{
  @Input() public model: TreeModel;
  @Output() onEventChange: EventEmitter<TreeEvent> = new EventEmitter();
  @Output() onChange: EventEmitter<TreeItemModel | Array<TreeItemModel>> =
    new EventEmitter();
  @ContentChild('treePopupContent') treePopupContentTmpl: TemplateRef<any>;
  @ContentChild('footerButton') footerButtonTmpl: TemplateRef<any>;
  @ContentChild('customText') customTextTmpl: TemplateRef<any>;
  public treePopupResponse: TreePopupResponseModel;
  public generatedId: string = TextUtils.generateRandomString();
  public httpClientRequest: Subscription;
  public previousClickItemTime: number = +Date.now - 1001;
  public originTemp: any;
  public parentIdAdd: number;

  timeOut: any;
  readonly SEARCH_INTERVAL = 500; // 0.5s
  public first = 0; /** for lazy load similar to pagination */
  public rows = 10; /** for lazy load similar to pagination */
  public searchTextList: {
    index: number;
    searchText: string;
  }[] = [];

  constructor(public appPopupService: AppPopupService) {
    super('app-tree-org');
  }

  ngAfterViewChecked(): void {
    this.handleAppTreeControl();
  }

  ngOnDestroy(): void {
    this.httpClientRequest.unsubscribe();
  }

  public onInit(): void {
    this.handleModelRequestReset();
    this.doLoadTree(
      this.model.parentId ? this.model.parentId : null,
      0,
      null,
      true
    );
  }

  public doLoadTree(
    id: number,
    index: number,
    tempId: number,
    isRoot: boolean
  ): void {
    id === null && tempId !== null
      ? this.doReloadTreeDataTemp(index, tempId)
      : this.doReloadTreeData(id, index, isRoot);
  }

  public onScrollEnd(index: number, event: any): void {
    this.log.debug(event);
    /** index = level */
    let parentId = null;
    if (index !== 0) {
      if (
        this.parentIdAdd !== this.model.treeList[index - 1].getSelectedItem().id
      ) {
        parentId = this.model.treeList[index - 1].getSelectedItem().id;
        this.model.removeAt(index + 1);
        this.model.clearSelectedItemByIndex(index);
        this.searchTextList = this.searchTextList.filter(
          text => text.index !== index + 1
        );
        this.first = this.model.treeList[index].treeItemList.length;
      } else {
        this.rows += 10;
      }
    } else {
      this.first += this.rows;
    }

    const searchText = this.searchTextList.find(
      text => text.index === index
    )?.searchText;

    this.httpClientRequest = this.httpClientService
      .post<any[]>(
        '/' +
          this.model.moduleCode +
          (searchText ? '/tree/search' : '/tree/view-child'),
        new TreeRequestModel(
          parentId || this.parentIdAdd,
          searchText || null,
          false,
          this.first,
          this.rows
        )
      )
      .subscribe((angularTreeList: TreeItem[]) => {
        this.log.debug(this.model.currentIndexTree);

        const treeItemList: Array<TreeItemModel> = new Array();
        angularTreeList.forEach((item: TreeItem) => {
          const treeItem: TreeItemModel = new TreeItemModel(item);
          this.model.setIsChecked(treeItem);
          treeItemList.push(treeItem);
        });

          const allTreeItemList = this.model.treeList[index].treeItemList;

          /** prevent duplicate children */
          const childIdList = treeItemList.map(treeItem => treeItem.id);
          childIdList.forEach(childId => {
            const isChildIdExist = !!allTreeItemList
              .map(treeItem => treeItem.id)
              .includes(childId);

            if (!isChildIdExist) {
              allTreeItemList.push(
                treeItemList.find(item => item.id === childId)
              );
            }
          });

        if (angularTreeList.length === 0) {
          this.first = this.model.treeList[index]?.treeItemList.length;
        }
      });
  }

  public doReloadTreeData(
    parentId: number,
    index: number,
    isRoot: boolean
  ): void {
    this.parentIdAdd = parentId;
    this.model.removeAt(index);
    this.model.buildTreeItemList();
    this.httpClientRequest = this.httpClientService
      .post<any[]>(
        '/' + this.model.moduleCode + '/tree/view-child',
        new TreeRequestModel(parentId, null, isRoot, this.first, this.rows)
      )
      .subscribe((angularTreeList: TreeItem[]) => {
        this.doSetItemList(angularTreeList, parentId);
        this.doScrollToRight();
      });
  }

  public doReloadTreeDataTemp(index: number, tempId: number): void {
    this.model.removeAt(index);
    this.model.buildTreeItemList();
    const angularTreeList = this.model.treeProcess.treeProcessItemList
      .map((treeProcess: TreeProcessItemModel) => treeProcess.data)
      .filter((data: any) => data.parentId === tempId);
    this.model.setItemList(angularTreeList);
    this.doScrollToRight();
  }

  public doSearch(searchText: string, index: number): void {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      const searchTextModel = this.searchTextList.find(
        text => text.index === index
      );
      if (searchTextModel) {
        this.searchTextList = this.searchTextList.filter(
          text => text.index !== index
        );
      }
      this.searchTextList.push({
        index,
        searchText
      });

      this.first = 0;
      this.model.removeAt(index + 1);
      this.model.treeList[index].isLoading = true;
      this.model.checkedTreeItemListTemp.splice(index);
      const parentId =
        index === 0
          ? null
          : this.model.treeList[index - 1].getSelectedItem().id;
      this.httpClientRequest = this.httpClientService
        .post<AngularTreeModel[]>(
          '/' + this.model.moduleCode + '/tree/search',
          new TreeRequestModel(
            parentId,
            searchText,
            null,
            this.first,
            this.rows
          )
        )
        .subscribe((angularTreeList: Array<TreeItem>) => {
          this.doSetItemList(angularTreeList, parentId, searchText);
          this.parentIdAdd = parentId;
        });
    }, this.SEARCH_INTERVAL);
  }

  public doSetItemList(
    angularTreeList: Array<TreeItem>,
    parentId: number,
    searchText = ''
  ): void {
    this.parentIdAdd = parentId;
    const angularTreeListOfProcessList = Array.from(
      this.model.treeProcess.treeProcessItemList
    )
      .map((treeProcessItem: TreeProcessItemModel) => treeProcessItem.data)
      .filter(
        (data: any) =>
          data.parentId === parentId &&
          data.name.toLowerCase().includes(searchText.toLocaleLowerCase())
      );
    const idListOfProcessListWithTypeDeleted =
      this.model.treeProcess.treeProcessItemList
        .filter(
          (treeProcessItem: TreeProcessItemModel) =>
            treeProcessItem.type === 'DELETE' &&
            treeProcessItem.data.parentId === parentId &&
            treeProcessItem.data.id
        )
        .map((treeProcess: TreeProcessItemModel) => treeProcess.data.id);
    let combineAngularTreeList = [
      ...angularTreeList,
      ...angularTreeListOfProcessList
    ];
    if (idListOfProcessListWithTypeDeleted.length > 0) {
      combineAngularTreeList = combineAngularTreeList.filter(
        (treeItem: TreeItemModel) =>
          idListOfProcessListWithTypeDeleted.indexOf(treeItem.id) === -1
      );
    }
    this.model.setItemList(combineAngularTreeList);
  }

  public doChecked(event: any, treeItem: TreeItemModel): void {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    if (
      (this.model.onlyLastChild && !treeItem.hasChild) ||
      !this.model.onlyLastChild
    ) {
      treeItem.isChecked = !treeItem.isChecked;
      if (treeItem.isChecked) {
        this.model.checkedTreeItemListTemp.push(treeItem);
      } else {
        const indexOfTreeItem =
          this.model.checkedTreeItemListTemp.indexOf(treeItem);
        this.model.checkedTreeItemListTemp.splice(indexOfTreeItem, 1);
      }
    }
  }

  public doClickTreeItem(data: TreeItemModel, index: number): void {
    this.model.setCurrentIndex(index);
    this.first = 0;
    this.searchTextList = this.searchTextList.filter(
      text => text.index <= index
    );
    const currentTime = Date.now();
    if (
      this.model.mode === 'READ' &&
      !this.model.isMultiple &&
      ((this.model.onlyLastChild && !data.hasChild) ||
        (this.model.onlyLastChild &&
          data.hasChild &&
          this.model.limitLevel &&
          index === this.model.limitLevel) ||
        !this.model.onlyLastChild) &&
      currentTime - this.previousClickItemTime < 1000
    ) {
      //this.doSave();
    } else {
      if (
        this.model.mode === 'READ' &&
        this.model.onlyLastChild &&
        data.hasChild &&
        ((this.model.limitLevel && index < this.model.limitLevel) ||
          !this.model.limitLevel) &&
        currentTime - this.previousClickItemTime < 1000
      ) {
        this.global.alertService.showError(
          'app.validation.chooseLastLevel',
          '.app-tree-org',
          { totalAlert: 1 }
        );
      } else {
        if (
          !this.model.limitLevel ||
          (this.model.limitLevel && index < this.model.limitLevel)
        ) {
          this.doLoadTree(data.id, index + 1, data.tempId, false);
        }
        this.model.clearSelectedItemByIndex(index);
        data.setStateSelected();
        this.previousClickItemTime = currentTime;
      }
    }
  }

  public doAdd(level: number): void {
    this.httpClientService
      .post<any>(
        `/organization/check-parent/${this.parentIdAdd}/${level}`, {}
      )
      .subscribe((data: any) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('todo', 'add');
        this.global.routerParams.set('parentIdAdd', data);
        this.router.navigate(['/pages/organization/add']);
      });
  }

  public doEdit(data: TreeItemModel): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('organizationId', data.id);
    this.global.routerParams.set('parentIdAdd', this.parentIdAdd);
    this.router.navigate(['/pages/organization/edit']);
  }

  public doDelete(
    event: MouseEvent,
    data: any,
    currentIndexTree: number,
    currentIndexItemInTree: number
  ): void {
    event.preventDefault();
    event.stopPropagation();
    this.model.setCurrentIndex(currentIndexTree, currentIndexItemInTree);
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (data.id !== null) {
            const indexOfDataInTreeProcess =
              this.model.treeProcess.treeProcessItemList.findIndex(
                (treeProcess: TreeProcessItemModel) =>
                  treeProcess.data.id === data.id
              );
            if (indexOfDataInTreeProcess !== -1) {
              this.model.treeProcess.treeProcessItemList[
                indexOfDataInTreeProcess
              ].data = data;
              this.model.treeProcess.treeProcessItemList[
                indexOfDataInTreeProcess
              ].type = 'DELETE';
            } else {
              const treeProcessItem: TreeProcessItemModel =
                new TreeProcessItemModel(data, 'DELETE');
              treeProcessItem.origin = data;
              this.model.treeProcess.addItemToTreeProcessItem(treeProcessItem);
            }
            const parentId = this.model.getCurrentTreeItem().parentId;
            this.model.removeCurrentItem();
            if (this.model.treeList[currentIndexTree].length === 0) {
              const indexOfParent =
                this.model.treeList[
                  currentIndexTree - 1
                ].getIndexOfTreeItemById(parentId);
              this.model.treeList[currentIndexTree - 1].treeItemList[
                indexOfParent
              ].setHasChild(false);
              this.model.treeList.splice(currentIndexTree);
            }
          } else {
            const indexOfData = this.model.treeProcess.getIndexOfData(data);
            this.model.treeProcess.removeAt(indexOfData);
            this.model.removeCurrentItem();
            if (this.model.treeList[currentIndexTree].length === 0) {
              const indexOfCurrentParentItem =
                this.model.getIndexOfCurrentParentItem();
              this.model.treeList[currentIndexTree - 1].treeItemList[
                indexOfCurrentParentItem
              ].setHasChild(false);
              this.model.treeList.splice(currentIndexTree);
            }
          }
        }
      });
  }

  public doEmitEvent(
    eventType: TreeEventType,
    action: TreeActionType,
    angularTree?: AngularTreeModel
  ): void {
    this.onEventChange.emit({
      type: eventType,
      angularTree,
      formGroup: this.model.formGroup,
      treeProcess: this.model.treeProcess,
      action
    });
  }

  public doScrollToLeft(): void {
    const appTreeBodyElement = document.getElementById(this.generatedId);
    if (appTreeBodyElement) {
      const childrenElement = appTreeBodyElement.children.item(0);
      appTreeBodyElement.scrollTo(
        -(childrenElement.scrollWidth + appTreeBodyElement.scrollLeft),
        0
      );
    }
  }

  public doScrollToRight(): void {
    const appTreeBodyElement = document.getElementById(this.generatedId);
    if (appTreeBodyElement) {
      const childrenElement = appTreeBodyElement.children.item(0);
      appTreeBodyElement.scrollTo(
        childrenElement.scrollWidth + appTreeBodyElement.scrollLeft,
        0
      );
    }
  }

  public handleAppTreeControl(): void {
    const appTreeBodyElement = document.getElementById(this.generatedId);
    const controlLeft = document.getElementById(
      'control-left' + this.generatedId
    );
    const controlRight = document.getElementById(
      'control-right' + this.generatedId
    );
    const maxTreeInTreeBody =
      appTreeBodyElement.offsetWidth /
      appTreeBodyElement.children.item(0).scrollWidth;
    if (appTreeBodyElement.children.length > maxTreeInTreeBody) {
      controlLeft.removeAttribute('style');
      controlRight.removeAttribute('style');
    } else {
      controlLeft.style.display = 'none';
      controlRight.style.display = 'none';
    }
  }

  private handleModelRequestReset(): void {
    this.model.requestReload.subscribe(() => {
      this.doLoadTree(
        this.model.parentId ? this.model.parentId : null,
        0,
        null,
        true
      );
    });
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
