import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from '../core/global/global';
import { Log } from '../core/services/logger';
@Component({
  template: ''
})
export class ReloadComponent implements OnInit {
  private log: Log = new Log(this);
  constructor(public router: Router, public global: Global) {}
  public ngOnInit(): void {
    const from = this.global.routerParams.get('reload');
    this.log.debug('reload from = ' + from);
    from.includes('portal')
      ? this.router.navigate(['/portal/home/'])
      : this.router.navigate([from]);
  }
}
