import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { AppMapPopupComponent } from '../../../core/components/app-map/app-map-popup.component';
import { MapResponseModel } from '../../../core/components/app-map/model/map-response-model';
import { AppPopupEmailComponent } from '../../../core/components/app-popup/app-popup-email/app-popup-email.component';
import { AppPopupEmailService } from '../../../core/components/app-popup/app-popup-email/app-popup-email.service';
import { AppPopupStatistikVendorService } from '../../../core/components/app-popup/app-popup-statistik-vendor/app-popup-statistik-vendor.service';
import { AppPopupService } from '../../../core/components/app-popup/app-popup.service';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { RouteRequestModel } from '../../../core/model/route-request-model';
import { TableCatalogResponseModel } from '../view-catalog/core/components/app-table-catalog/model/table-catalog-response-model';
import { ViewVendorDTO } from './dto/view-vendor.dto';
@Component({
  templateUrl: './view-vendor-detail.component.html',
  styleUrls: ['./view-vendor-detail.component.scss']
})
export class ViewVendorDetailComponent extends BaseModuleComponent {
  public mapModel: MapResponseModel = new MapResponseModel(null, true);
  public vendorId: number;
  public tableCatalogResponse: TableCatalogResponseModel<any> =
    new TableCatalogResponseModel('/view-catalog/index', 10, 'GRID');
  public viewVendorDTO: ViewVendorDTO = new ViewVendorDTO();
  constructor(
    translate: TranslateService,
    public appPopupStatistikVendorService: AppPopupStatistikVendorService,
    public appPopupEmailService: AppPopupEmailService,
    public appPopupService: AppPopupService
  ) {
    super('view-vendor-detail', translate);
  }

  public onInit(): void {
    this.getAndSetDataFromRouterParams();
    this.setTableCatalogResponseCustomData();
    this.getAndSetViewVendorDTO();
  }

  public getAndSetDataFromRouterParams(): void {
    this.vendorId = this.global.routerParams.get('vendorId');
  }

  public setTableCatalogResponseCustomData(): void {
    this.tableCatalogResponse.setCustomData({ vendorId: this.vendorId });
  }

  public getAndSetViewVendorDTO(): void {
    this.httpClientService
      .post('/view-vendor/detail', new RouteRequestModel('View', this.vendorId))
      .subscribe((response: ViewVendorDTO) => {
        const latitudeLongitude = this.mapModel.convertStringToLatLng(
          response.vendorAddress.coordinateLocation
        );
        this.mapModel.setLatitudeAndLongitude(
          latitudeLongitude.latitude,
          latitudeLongitude.longitude
        );
        this.viewVendorDTO = response;
        this.setStateReady();
      });
  }

  public doOpenPopupStatistikVendor(): void {
    this.appPopupStatistikVendorService.open({
      rating: 4,
      ratings: [60, 20, 15, 3, 2],
      totalProduct: 100,
      totalTransaction: 88
    });
  }

  public doOpenPopupEmail(): void {
    const url = '/view-vendor/send-email';
    const to = this.viewVendorDTO.vendorPICEmails;
    this.appPopupService
      .open(AppPopupEmailComponent, { url, to })
      .subscribe((response: Response<any>) => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccess(
            this.translateService.instant('view-vendor.alert.msg.sendSuccess')
          );
        } else {
          this.global.alertService.showError(
            this.translateService.instant('view-vendor.alert.msg.sendError')
          );
        }
      });
  }

  public doOpenPopupMap(): void {
    if (this.viewVendorDTO.vendorAddress.coordinateLocation) {
      this.appPopupService.open(AppMapPopupComponent, { model: this.mapModel });
    } else {
      this.global.alertService.showError(
        this.translateService.instant(
          'view-vendor.alert.error.validatePopupMap'
        )
      );
    }
  }

  public onChooseCatalogItem(catalogId: number): void {
    this.router.navigate([`/pages/view-catalog/detail/${catalogId}`]);
  }

  public doBack(): void {
    this.router.navigate(['/pages/view-vendor']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
