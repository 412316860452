<app-alert>
  <h5 class="text-center text-info">
    {{ broadcastMessage.message }}
  </h5>
  <em
    class="fas fa-times text-danger btn-close"
    (click)="doClose()"
    (keyup)="onKeyUp($event)"
    (keydown)="onKeyDown($event)"
  ></em>
</app-alert>
