import { Component, EventEmitter, Output } from '@angular/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { Resource } from '../../../bean/resource';
import { FieldFormatEnum } from '../../app-table/model/field-format.enum';
import { TableResponseModel } from '../../app-table/model/table-response-model';
@Component({
  templateUrl: './app-popup-resource.component.html'
})
export class AppPopupResourceComponent extends BaseComponentComponent {
  @Output() onChange: EventEmitter<Resource> = new EventEmitter();
  public tableResponse: TableResponseModel<Resource>;

  constructor() {
    super('app-popup-resource');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      {
        field: 'imgFile.uploadedFileName',
        header: 'table.column.icon',
        format: FieldFormatEnum.Img,
        urlImage: '/resource',
        sortable: false,
        customClass: 'text-center',
        fileTypeCode: this.global.appConstant.fileType.IMG_ICON
      }
    ]);
  }

  doChoose(resource: Resource): void {
    this.onChange.emit(resource);
  }
}
