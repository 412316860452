import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupTabVendorEquipmentComponent } from './app-popup-tab-vendor-equipment.component';
import { AppPopupTabVendorExpertiseComponent } from './app-popup-tab-vendor-expertise.component';
import { TabExpertAndEquipmentComponent } from './tab-expert-and-equipment.component';
@NgModule({
  imports: [SharedModule],
  declarations: [
    TabExpertAndEquipmentComponent,
    AppPopupTabVendorExpertiseComponent,
    AppPopupTabVendorEquipmentComponent
  ],
  entryComponents: [
    TabExpertAndEquipmentComponent,
    AppPopupTabVendorExpertiseComponent,
    AppPopupTabVendorEquipmentComponent
  ],
  exports: [
    TabExpertAndEquipmentComponent,
    AppPopupTabVendorExpertiseComponent,
    AppPopupTabVendorEquipmentComponent
  ]
})
export class TabExpertAndEquipmentModule {
  constructor(accordionService: AccordionService) {
    accordionService.register(
      'tab-expert-and-equipment',
      TabExpertAndEquipmentComponent
    );
  }
}
