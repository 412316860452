import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupSyncWorkProgramComponent } from './app-popup-sync-work-program.component';
import { WorkProgramEditAddComponent } from './work-program-edit-add.component';
import { WorkProgramComponent } from './work-program.component';

export const routes = [
  { path: '', component: WorkProgramComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: WorkProgramEditAddComponent,
    data: { breadcrumb: 'work-program.breadcrumb.add' }
  },
  {
    path: 'edit',
    component: WorkProgramEditAddComponent,
    data: { breadcrumb: 'work-program.breadcrumb.edit' }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    WorkProgramComponent,
    WorkProgramEditAddComponent,
    AppPopupSyncWorkProgramComponent
  ],
  providers: [],
  entryComponents: [AppPopupSyncWorkProgramComponent],
  exports: [AppPopupSyncWorkProgramComponent]
})
export class WorkProgramModule {}
