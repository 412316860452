import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { HomeSlide } from '../../core/bean/home-slide';
import { CarouselResponseModel } from '../../core/components/app-carousel/model/carousel-response-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { HomePopupReadMoreComponent } from './home-popup-read-more.component';
@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent extends BaseModuleComponent {
  public modelList: Array<any>;
  public cardItemList: Array<any>;
  public homeSlideList: Array<any>;
  public carouselModel: CarouselResponseModel = new CarouselResponseModel();
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('home', translateService);
  }

  onInit(): void {
    this.checkIsLoggedInAndRedirect();
    this.setCardItemListState();
    this.setHomeSilde();
  }

  public checkIsLoggedInAndRedirect(): void {
    if (this.global.userSession) {
      this.router.navigate(['/pages/dashboard']);
    }
  }

  private setCardItemListState(): void {
    this.cardItemList = [
      {
        vector: 'pvc pv-newspaper',
        title: 'News',
        text: 'Read the updated news here',
        routerUrl: '/portal/news'
      },
      {
        vector: 'pvc pv-user-decision',
        title: 'Vendor Policy',
        text: 'Read the terms, condition and policy for vendor',
        routerUrl: '/portal/vendor-policy'
      },
      {
        vector: 'pvc pv-chat',
        title: 'FAQ',
        text: 'Most asking question, find the answer in here',
        routerUrl: '/portal/faq'
      }
    ];
  }

  private setHomeSilde(): void {
    this.httpClientService
      .get<HomeSlide[]>('/home-slide/get-home-slide-list')
      .subscribe(homeSlideList => {
        this.homeSlideList = homeSlideList;
        this.setStateReady();
      });
  }

  public doViewMoreAnnouncement(): void {
    this.router.navigate(['/portal/procurement-announcement']);
  }

  public doReadMore(data): void {
    this.appPopupService.open(HomePopupReadMoreComponent, {
      data
    });
  }

  public doNavigate(routerUrl: string): void {
    this.router.navigate([routerUrl]);
  }
}
