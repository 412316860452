import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { NotificationLoaderComponent } from './notification-loader.component';

export const routes = [
  {
    path: ':moduleCode/:id',
    component: NotificationLoaderComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'download/:moduleCode/:fileId',
    component: NotificationLoaderComponent,
    data: { breadcrumb: '' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [NotificationLoaderComponent]
})
export class NotificationLoaderModule {}
