<app-layout
  [isLoading]="formLoading"
  header="rfp.breadcrumb.index"
  [backTo]="urlBackOutside"
>
  <ng-template #additionalTitle>
    <app-badge class="cml-2" *ngIf="todo === 'add'" color="INFO">
      {{ 'rfp.label.new' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        rfp?.rfpStatus?.code === global.appConstant.pm.RFP_STATUS_DRAFT
      "
      color="SECONDARY"
    >
      {{ 'dynamic-master-attribute.rfpStatus.draft' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        rfp?.rfpStatus?.code ===
          global.appConstant.pm.RFP_STATUS_WAITING_APPROVAL
      "
      color="WARNING"
    >
      {{ 'dynamic-master-attribute.rfpStatus.waitingApproval' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        rfp?.rfpStatus?.code ===
          global.appConstant.pm.RFP_STATUS_WAITING_RESPONSE
      "
      color="GOOD"
    >
      {{ 'dynamic-master-attribute.rfpStatus.waitingResponse' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        rfp?.rfpStatus?.code === global.appConstant.pm.RFP_STATUS_COMPLETED
      "
      color="SUCCESS"
    >
      {{ 'dynamic-master-attribute.rfpStatus.completed' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        rfp?.rfpStatus?.code ===
          global.appConstant.pm.RFP_STATUS_CLOSED_RESPONSE
      "
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.rfpStatus.closedResponse' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        rfp?.rfpStatus?.code === global.appConstant.pm.RFP_STATUS_CANCELED
      "
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.rfpStatus.canceled' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        rfp?.rfpStatus?.code === global.appConstant.pm.RFP_STATUS_DONE
      "
      color="SUCCESS"
    >
      {{ 'dynamic-master-attribute.rfpStatus.done' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        rfp?.rfpStatus?.code === global.appConstant.pm.RFP_STATUS_REVISION
      "
      color="FEEDBACK"
    >
      {{ 'dynamic-master-attribute.rfpStatus.revision' | translate }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-alert-x
          *ngIf="cancellationAlert"
          color="DANGER"
          [isShowCloseButton]="true"
          >This process canceled by
          {{ rfpResponse.rfpCancellation.cancelByUser.name }}
          at
          {{
            rfpResponse.rfpCancellation.cancelDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}
          the cancellation reason is
          {{ rfpResponse.rfpCancellation.note }}</app-alert-x
        >
        <app-card
          header="rfp.detail.general.title"
          tips="rfp.detail.general.tips"
        >
          <app-alert-x *ngIf="!formGroup.isView">
            {{ 'rfp.message.info.optional' | translate }}
          </app-alert-x>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{ 'rfp.form.title' | translate }}</label>
            <div class="col-sm-4 text-left">
              <app-text-field
                size="XL"
                formControlName="title"
                placeholder="{{ 'rfp.placeholder.title' | translate }}"
              >
              </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfp.form.responsePeriod' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              <app-date-picker-x
                size="XL"
                formControlName="date"
                [range]="true"
                [minDate]="currentDate"
              >
              </app-date-picker-x>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfp.form.template' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              <app-upload-x
                formControlName="file"
                [fileUploader]="fileUploader"
              >
              </app-upload-x>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{ 'rfp.form.note' | translate }}</label>
            <div class="col-sm-6 text-left">
              <app-text-area size="XL" formControlName="note" maxLength="255">
              </app-text-area>
            </div>
          </div>
        </app-card>

        <app-item-request
          [isShowRequired]="isSubmit && prItemList.length === 0"
          [objectList]="procurementItemList"
          [isShowPrice]="true"
          [isView]="formGroup.isView"
          [itemTypeList]="rfpResponse.itemTypeList"
          (onChange)="doChangeItemRequest($event)"
          baseModuleCode="RFP"
        ></app-item-request>

        <app-card>
          <app-table-xx
            header="rfp.detail.proposalCriteria.title"
            tips="rfp.detail.proposalCriteriatips"
            [model]="
              !formGroup.isView
                ? tableResponseProposalCriteria
                : tableResponseProposalCriteriaView
            "
            [isShowPagination]="false"
            [isShowSearch]="rfpResponse.rfpProposalCriteriaList?.length > 0"
            *ngIf="!formLoading"
          >
            <ng-template #headerButtons>
              <app-button
                *ngIf="!formGroup.isView"
                class="float-right"
                (onClick)="doAddCriteria()"
                [ngStyle]="{ 'margin-bottom': '2%' }"
                [disabled]="prItemList.length === 0"
                ><em class="pir pi-plus-circle"></em>
                {{ 'rfp.button.addCriteria' | translate }}
              </app-button>
            </ng-template>
            <div
              *ngIf="tableResponseProposalCriteria.getRecordList().length > 0"
            >
              <div *ngIf="!formGroup.isView">
                <ng-template #row>
                  <tr>
                    <td></td>
                    <td class="text-left">
                      {{ 'rfp.totalProposalCriteria' | translate }}
                    </td>
                    <td>
                      <div class="td-content text-right">
                        {{ totalProposalCriteria || 0 }} %
                        <p
                          ><em class="pir pi-info-circle"></em
                          ><small
                            [ngClass]="
                              totalProposalCriteria &&
                              totalProposalCriteria !== 100
                                ? 'cml-1 text-danger'
                                : 'cml-1'
                            "
                            >Total score must be 100</small
                          ></p
                        >
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </div>
              <div *ngIf="formGroup.isView">
                <ng-template #row>
                  <tr>
                    <td class="text-left">
                      {{ 'rfp.totalProposalCriteria' | translate }}
                    </td>
                    <td>
                      <div class="td-content text-right">
                        {{
                          (totalProposalCriteria | resolveNumberToDecimal) ||
                            0.0
                        }}
                        %
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </div>
            </div>
            <ng-container *ngIf="!formGroup.isView">
              <ng-template #actionButtons let-rowData>
                <app-button
                  size="TN"
                  (onClick)="doDeleteCriteria(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="pir pi-trash-alt"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>
              </ng-template>
            </ng-container> </app-table-xx
          ><br />
          <h4 class="separator"
            >{{ 'rfp.form.scoringCriteria' | translate }}
          </h4>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfp.form.minimumScoreOfProposalCriteria' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="minimumScore"
                type="decimal"
                placeholder="{{ 'rfp.placeholder.inputScore' | translate }}"
              ></app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfp.form.proposalPoint' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="proposalPercentage"
                type="decimal"
                groupText="%"
                (onChange)="doOnChangePricePoint()"
                placeholder="{{
                  'rfp.placeholder.percentageNumber' | translate
                }}"
              >
              </app-text-field>
              <span *ngIf="formGroup.isView"> %</span>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfp.form.pricePoint' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="pricePercentage"
                type="decimal"
                groupText="%"
                (onChange)="doOnChangePricePoint()"
                placeholder="{{
                  'rfp.placeholder.percentageNumber' | translate
                }}"
              >
              </app-text-field>
              <span *ngIf="formGroup.isView"> %</span>
            </div>
          </div>
        </app-card>

        <app-card>
          <app-table-xx
            header="rfp.detail.vendor.title"
            tips="rfp.detail.vendor.tips"
            [model]="tableResponseVendor"
            *ngIf="!formLoading"
            [isShowSearch]="tableResponseVendor.getRecordList().length > 0"
          >
            <ng-template #headerButtons>
              <app-button
                [disabled]="formSaving"
                *ngIf="!formGroup.isView"
                (onClick)="doAddVendor()"
                [disabled]="
                  prItemList.length === 0 ||
                  !rfpResponse.rfpProposalCriteriaList ||
                  rfpResponse.rfpProposalCriteriaList.length === 0
                "
                ><em class="pir pi-plus-circle"></em>
                {{ 'rfp.button.inviteVendor' | translate }}</app-button
              >
            </ng-template>
            <ng-container *ngIf="!formGroup.isView">
              <ng-template #actionButtons let-rowData>
                <app-button
                  size="TN"
                  (onClick)="doDeleteVendor(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="pir pi-trash-alt"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>
              </ng-template>
            </ng-container>
          </app-table-xx>
        </app-card>

        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            rfpResponse.approvalPathResponseModel &&
            !rfpResponse.isUserApproval &&
            rfp?.rfpStatus?.code &&
            rfp?.rfpStatus?.code !== global.appConstant.pm.RFP_STATUS_DRAFT
          "
          [workflowPrcs]="rfpResponse?.rfp?.workflowPrcs"
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="rfpResponse.approvalPathResponseModel"
        ></app-approval-monitoring>

        <div class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center" *ngIf="!viewMode">
                <app-button
                  *ngIf="
                    rfp?.rfpStatus?.code ===
                      global.appConstant.pm.RFP_STATUS_WAITING_RESPONSE ||
                    rfp?.rfpStatus?.code ===
                      global.appConstant.pm.RFP_STATUS_COMPLETED ||
                    rfp?.rfpStatus?.code ===
                      global.appConstant.pm.RFP_STATUS_CLOSED_RESPONSE ||
                    (rfp?.rfpStatus?.code ===
                      global.appConstant.pm.RFP_STATUS_REVISION &&
                      rfp?.workflowPrcs?.code === 'RFP_SCORING')
                  "
                  color="DANGER"
                  [outline]="true"
                  (onClick)="doCancel()"
                  >{{ 'rfp.button.cancelRfp' | translate }}
                </app-button>
                <app-button
                  color="LIGHT"
                  [outline]="true"
                  *ngIf="isShowCloseButton"
                  (onClick)="doClose()"
                  [disabled]="formSaving"
                  >{{ 'rfp.button.closeResponse' | translate }}</app-button
                >
                <div
                  *ngIf="
                    !formGroup.isView ||
                    rfp.rfpStatus.code ===
                      global.appConstant.pm.RFP_STATUS_CLOSED_RESPONSE ||
                    (rfp.rfpStatus.code ===
                      global.appConstant.pm.RFP_STATUS_REVISION &&
                      rfp.workflowPrcs.code === 'RFP_SCORING')
                  "
                >
                  <app-button
                    color="SECONDARY"
                    [outline]="true"
                    (onClick)="doSave()"
                    [disabled]="
                      formSaving ||
                      prItemList.length === 0 ||
                      !rfpResponse.rfpProposalCriteriaList ||
                      rfpResponse.rfpProposalCriteriaList.length === 0 ||
                      !tableResponseVendor.getRecordList() ||
                      tableResponseVendor.getRecordList().length === 0
                    "
                    >{{ 'app.button.save' | translate }}
                  </app-button>
                  <app-button
                    (onClick)="doSubmit()"
                    [disabled]="
                      formSaving ||
                      prItemList.length === 0 ||
                      !rfpResponse.rfpProposalCriteriaList ||
                      rfpResponse.rfpProposalCriteriaList.length === 0 ||
                      !tableResponseVendor.getRecordList() ||
                      tableResponseVendor.getRecordList().length === 0
                    "
                    >{{ 'app.button.submit' | translate }}</app-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-approval-prcs-x
          *ngIf="!formLoading && rfpResponse.isUserApproval"
          [workflowModelPrcs]="rfpResponse?.rfp?.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight *ngIf="!rfpResponse?.isUserApproval">
    <app-tips></app-tips>
  </ng-template>
</app-layout>
