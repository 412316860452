import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { VerificatorEditAddComponent } from './verificator-edit-add.component';
import { VerificatorComponent } from './verificator.component';

export const routes = [
  { path: '', component: VerificatorComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: VerificatorEditAddComponent,
    data: { breadcrumb: 'verificator.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: VerificatorEditAddComponent,
    data: { breadcrumb: 'verificator.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), NgbModule],

  declarations: [VerificatorComponent, VerificatorEditAddComponent],

  providers: []
})
export class VerificatorModule {}
