import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { CatalogType } from 'src/app/core/bean/catalog-type';
import { AppItemRequestInitiationCatalogModel } from 'src/app/core/components/app-item-request/app-item-request-initiation-catalog-model';
import { TabModel } from 'src/app/core/model/tab/tab-model';
import { TabResponseModel } from 'src/app/core/model/tab/tab-response-model';

@Component({
  templateUrl: './initiation-catalog-add.component.html'
})
export class InitiationCatalogAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  constructor(translateService: TranslateService) {
    super('initiation', translateService);
  }
  public prResponse: AppItemRequestInitiationCatalogModel = new AppItemRequestInitiationCatalogModel();
  public catalogType: CatalogType;
  public tabResponse: TabResponseModel;
  public tabOne: TabModel;

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setStateReady();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.prResponse = this.global.routerParams.get('prResponse');
    this.tabResponse = this.global.routerParams.get('tabResponse');
    this.tabOne = this.tabResponse.currentTab;
    this.catalogType = this.prResponse.catalogType;
  }
}
