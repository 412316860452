import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAccordionModule } from '../app-accordion/app-accordion.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppFieldsetModule } from '../app-fieldset/app-fieldset.module';
import { AppTableModule } from '../app-table/app-table.module';
import { AppPaymentRequestInformationViewComponent } from './app-payment-request-information-view.component';
@NgModule({
  imports: [
    VendorModule,
    AppTableModule,
    AppCardModule,
    AppAccordionModule,
    AppFieldsetModule
  ],
  declarations: [AppPaymentRequestInformationViewComponent],
  exports: [AppPaymentRequestInformationViewComponent]
})
export class AppPaymentRequestInformationViewModule {}
