<app-popup
  header="order.popup.chooseTerm"
  [isShowHeaderCloseButton]="false"
  [isLoading]="formLoading"
>
  <div class="row popup-order-term">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-table-xx
          [model]="tableResponse"
          [isMultipleSelect]="false"
          [isShowCheckBox]="true"
          [isShowPagination]="false"
        >
        </app-table-xx>
        <ng-template #modalFooter let-activeModal>
          <div class="col-12 text-center">
            <app-button
              color="SECONDARY"
              [outline]="true"
              (onClick)="activeModal.close(true)"
              >{{ 'app.button.cancel' | translate }}</app-button
            >
            <app-button color="PRIMARY" (onClick)="doChoose()">{{
              'app.button.choose' | translate
            }}</app-button>
          </div>
        </ng-template>
      </form>
    </div>
  </div>
</app-popup>
