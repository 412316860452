import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { Vendor } from '../../../bean/vendor';
import { AppTableComponent } from '../../app-table/app-table.component';
import { TableResponseModel } from '../../app-table/model/table-response-model';
@Component({
  templateUrl: './app-popup-vendor.component.html'
})
export class AppPopupVendorComponent extends BaseComponentComponent {
  @Input() vendorIdList: number[];
  @Input() vendorActivityStatusCodeList: string[];
  @Input() vendorStatusCodeList: string[];
  @Input() vendorCertificateStatusCodeList: string[];
  @Output() onChange: EventEmitter<Vendor> = new EventEmitter();
  @ViewChild(AppTableComponent) table: AppTableComponent;

  public vendorCustomData: any;
  public tableResponse: TableResponseModel<Vendor>;

  constructor() {
    super('app-popup-vendor');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setVendorCustomData();
    this.buildTableResponse();
  }

  buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorIdList: [null],
      vendorActivityStatusCodeList: [null],
      vendorStatusCodeList: [null],
      vendorCertificateStatusCodeList: [null]
    });
  }

  setVendorCustomData(): void {
    this.formGroup.patchValue({
      vendorIdList: this.vendorIdList,
      vendorActivityStatusCodeList: this.vendorActivityStatusCodeList,
      vendorStatusCodeList: this.vendorStatusCodeList,
      vendorCertificateStatusCodeList: this.vendorCertificateStatusCodeList
    });
    this.vendorCustomData = JSON.stringify(this.formGroup.value);
  }

  buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      {
        field: 'vendorType.name',
        header: 'table.column.vendorType',
        customClass: 'text-center'
      },
      { field: 'email', header: 'table.column.email' }
    ]);
  }

  doChoose(vendor: Vendor): void {
    this.onChange.emit(vendor);
  }
}
