import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../../../../core/base/angular/base-component.component';
import { OptionListModel } from '../../../../../core/model/option-list-model';
@Component({
  templateUrl:
    './widget-contract-report-statistical-filter-popup.component.html'
})
export class WidgetContractReportStatisticalFilterPopupComponent extends BaseComponentComponent {
  @Input() model: { periode: number; year: number };

  @Output() onChange: EventEmitter<{
    periode: number;
    year: number;
  }> = new EventEmitter();

  public static moduleCode = 'dashboard-widget-contract-report-statistical';
  public periodeOptionList: OptionListModel<{
    id: number;
    name: string;
  }> = new OptionListModel(false, null, 'id');
  public yearOptionList: OptionListModel<number> = new OptionListModel();

  constructor(private ngbActiveModal: NgbActiveModal) {
    super('dashboard-widget-contract-report-statistical');
  }

  public onInit(): void {
    this.buildFormGroupState();
    this.setStatePeriodeOptionList();
    this.setStateYearOptionList();
    this.setStateFormGroup();
    this.log.warn(this.yearOptionList);
    this.log.info(this.periodeOptionList);
  }

  private buildFormGroupState(): void {
    this.formGroup = this.formBuilder.group({
      period: [null],
      year: [null]
    });
  }

  private setStatePeriodeOptionList(): void {
    this.periodeOptionList.setRequestValues([
      {
        id: 1,
        name: this.global.translateService.instant(
          this.moduleCode + '.' + 'form.period.lessThanEqualsOneYear'
        )
      },
      {
        id: 2,
        name: this.global.translateService.instant(
          this.moduleCode + '.' + 'form.period.lessThanEqualsTwoYear'
        )
      },
      {
        id: 3,
        name: this.global.translateService.instant(
          this.moduleCode + '.' + 'form.period.lessThanEqualsThreeYear'
        )
      },
      {
        id: 4,
        name: this.global.translateService.instant(
          this.moduleCode + '.' + 'form.period.moreThanEqualsThreeYear'
        )
      }
    ]);
  }

  private setStateYearOptionList(): void {
    const currentYear = new Date().getFullYear();
    this.yearOptionList.setRequestValues(
      Array(9)
        .fill(0)
        .map((fill: number, index: number) => currentYear - 5 + fill + index)
    );
  }

  private setStateFormGroup(): void {
    this.formGroup.patchValue(this.model);
  }

  public doApply(): void {
    this.onChange.emit(this.formGroup.value);
  }

  public doReset(): void {
    this.formGroup.reset();
    this.onChange.emit(this.formGroup.value);
  }

  public doCancel(): void {
    this.ngbActiveModal.close();
  }
}
