import { BaseDTO } from '../../../core/base/base-dto';
import { Vendor } from '../../../core/bean/vendor';
import { VendorHistory } from '../../../core/bean/vendor-history';
import { FileObject } from '../../../core/components/upload';

export class TabProfileMarketingDTO extends BaseDTO {
    vendorHistory: VendorHistory;
    vendor: Vendor;
    bannerFileObjectList: FileObject[];
    logoFileObjectList: FileObject[];
    companyFileObjectList: FileObject[];
}
