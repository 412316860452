import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { TechnicalCriteria } from '../../core/bean/technical-criteria';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';

@Component({
  templateUrl: './popup-add-technical-criteria.component.html'
})
export class PopupAddTechnicalCriteriaComponent extends BaseComponentComponent {
  public technicalCriteria: TechnicalCriteria = new TechnicalCriteria();
  public technicalOptionList: OptionListModel<any> = new OptionListModel(
    true,
    'name'
  );
  public isAddressChecked = false;
  @Input() technicalCriteriaList: TechnicalCriteria[] = [];
  @Input() technicalCriteriaOptList: TechnicalCriteria[] = [];
  @Output() onChange: EventEmitter<TechnicalCriteria[]> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {
    super('evaluation-criteria');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      technicalCriteria: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.technicalOptionList.setRequestValues(this.technicalCriteriaOptList);
    if (this.technicalCriteriaList && this.technicalCriteriaList.length !== 0) {
      this.formGroup.patchValue({
        technicalCriteria: this.technicalCriteriaList
      });
    }
    this.setStateReady();
  }

  doSubmit(): void {
    this.technicalCriteriaList = this.formGroup.get('technicalCriteria').value;
    if (this.technicalCriteriaList && this.technicalCriteriaList.length !== 0) {
      this.onChange.emit(this.technicalCriteriaList);
    } else {
      this.global.alertService.showError(
        'evaluation-criteria.validation.chooseTechnical',
        '.popup-technical'
      );
    }
  }
}
