import { Global } from '../../../global/global';
import { UploaderFile } from '../model';
export class UploaderHelpers {
  public static getFileUrlWithFileUploader(
    global: Global,
    uploaderFile: UploaderFile
  ): string {
    const fileTypeCode = uploaderFile.parent.options.fileType
      ? uploaderFile.parent.options.fileType.code
      : uploaderFile.parent.fileTypeCode;

    return (
      global.config.BACKEND_ADDRESS +
      uploaderFile.parent.stringUrl +
      'file/view/' +
      uploaderFile.file.uploadedFileName +
      '/' +
      fileTypeCode
    );
  }

  public static getThumbnailUrlWithFileUploader(
    global: Global,
    uploaderFile: UploaderFile
  ): string {
    return this.getFileUrlWithFileUploader(global, uploaderFile).replace(
      '/file/',
      '/thumbnail/'
    );
  }
}
