import { Fines } from 'src/app/core/bean/fines';
import { Guarantee } from 'src/app/core/bean/guarantee';
import { Order } from 'src/app/core/bean/order';
import { OrderItem } from 'src/app/core/bean/order-item';
import { OrderShipping } from 'src/app/core/bean/order-shipping';
import { OrderShippingItem } from 'src/app/core/bean/order-shipping-item';
import { OrderTc } from 'src/app/core/bean/order-tc';
import { Sow } from 'src/app/core/bean/sow';
import { FileObject } from 'src/app/core/components/upload';
import { Contract } from '../../../core/bean/contract';
import { PrItemReleased } from '../../../core/bean/pr-item-released';
import { PrItem } from 'src/app/core/bean/pr-item';
import { User } from 'src/app/core/bean/user';
import { Organization } from 'src/app/core/bean/organization';
import { AppOfficialReportModel } from '../official-report-order/app-official-report-model';

export class OrderRequest {
  isSubmit = false;
  order: Order;
  fileList: FileObject[] = [];
  orderShippingItemList: OrderShippingItem[] = [];
  orderTcList: OrderTc[] = [];
  sowList: Sow[] = [];
  prItemReleasedList: PrItemReleased[] = [];
  finesList: Fines[] = [];
  guaranteeList: Guarantee[] = [];
  contract: Contract;

  responseToConfirmNote: string;

  deleteOrderItemList: OrderItem[] = [];
  deleteOrderTcList: OrderTc[] = [];
  deleteSowList: Sow[] = [];
  deleteOrderShippingList: OrderShipping[] = [];
  appOfficialReportModel: AppOfficialReportModel;
  // by pass pr
  prItemList: PrItem[] = [];
  cartIdList: number[] = [];
  catalogId: number;
  catalogIdList: number[] = [];
  fileVendorList: FileObject[] = [];
  delPrItemList: PrItem[] = [];


  //exisitn pr request
  user: User = new User ();
  messageRequestItemToDistributor: string;
  organization: Organization = new Organization();
  orderShippingList: OrderShipping[] = [];
}
