import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { BondType } from '../../core/bean/bond-type';
import { Currency } from '../../core/bean/currency';
import { ItemType } from '../../core/bean/item-type';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { Quotation } from '../../core/bean/quotation';
import { Refutation } from '../../core/bean/refutation';
import { RefutationStatus } from '../../core/bean/refutation-status';
import { RefutationSubmission } from '../../core/bean/refutation-submission';
import { RefutationVendor } from '../../core/bean/refutation-vendor';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { ToastService } from '../../core/components/app-toast/app-toast.service';
import { WorkflowStepInfoModel } from '../../core/components/app-workflow-step-info/model/workflow-step-info-model';
import {
  TableHeaderPluginData,
  TablePluginData
} from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { Validators } from '../../core/validators';
import { RefutationVendorByItemView } from '../../core/view/entity/bean/refutation-vendor-by-item-view';
import { RefutationView } from '../../core/view/entity/bean/refutation-view';
import { VendorQuotationView } from '../../core/view/entity/bean/vendor-quotation-view';
import { VendorReferenceSelectedVendorView } from '../../core/view/entity/bean/vendor-reference-selected-vendor-view';
import { AppPopupRefutationVendorItemDetailComponent } from './app-popup-refutation-vendor-item-detail.component';
import { AppPopupRefutationVendorTocComponent } from './app-popup-refutation-vendor-toc.component';
import { RefutationVendorRequest } from './refutation-vendor-request';
import { RefutationVendorResponse } from './refutation-vendor-response';
@Component({
  templateUrl: './refutation-vendor-edit.component.html'
})
export class RefutationVendorEditComponent extends BaseModuleComponent {
  public token: string;
  public procurementId: number;
  public urlBackOutside: string;
  public refutationStatus: RefutationStatus;
  public refutationVendorResponse: RefutationVendorResponse;
  public procurementItemList: ProcurementItem[];
  public procurement: Procurement;
  public itemTypeList: ItemType[];
  public quotation: Quotation;
  public workflowStepInfoModel: WorkflowStepInfoModel;
  public refutationOptionList: OptionListModel<any> = new OptionListModel(true);
  public tableResponseFinalPriceEval: TableResponseModel<VendorQuotationView>;
  public tableResponseReferenceByItem: TableResponseModel<RefutationVendorByItemView>;
  public tableResponseReferenceByVendor: TableResponseModel<VendorReferenceSelectedVendorView>;
  public isShowForm = false;
  public today: Date = new Date();
  public minBidBondValue: number;
  public refutationVendorList: RefutationVendor[] = [];
  public refutationSubmissionList: RefutationSubmission[] = [];
  public canceledOrFailedMessage: string;
  public bondTypeOptionList: OptionListModel<BondType> = new OptionListModel(
    true
  );
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );
  public fileUploader: FileUploader = new FileUploader(
    '/refutation-vendor/',
    'refutation-vendor.form.refutationEvidence',
    this.global.appConstant.fileType.DOC_REFUTATION,
    false,
    this.global.config.parameterModel.maxFileRefutation
  );

  public badgeColor = {
    NEW: 'INFO',
    DRAFT: 'SECONDARY',
    SUBMITTED: 'SUCCESS',
    CLOSED_RESPONSE: 'DANGER',
    WAITING_RESPONSE: 'GOOD',
    HOLD: 'DANGER',
    CANCELED: 'DANGER',
    FAILED: 'DANGER'
  };

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute,
    public toastService: ToastService
  ) {
    super('refutation-vendor', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setDataFromToken();
    } else {
      this.setData();
    }
  }

  public setDataFromToken(): void {
    this.httpClientService
      .get<RefutationView>('/refutation-vendor/notification/' + this.token)
      .subscribe((refutationView: RefutationView) => {
        const isSchedule = this.validateSchedule(refutationView);
        if (isSchedule) {
          this.global.routerParams.clear();
          this.global.routerParams.set(
            'procurementId',
            refutationView.procurement.id
          );
          this.global.routerParams.set(
            'refutationStatus',
            refutationView.refutationStatus
          );
          this.global.routerParams.set(
            'urlBackOutside',
            '/pages/refutation-vendor'
          );
          if (
            (refutationView.refutationStatus.code ===
              this.global.appConstant.pm.REFUTATION_STATUS_WAITING_RESPONSE &&
              ((!refutationView.isReference &&
                refutationView.vendorSelectionType.code ===
                  this.global.appConstant.pm
                    .VENDOR_SELECTION_TYPE_SINGLE_WINNER) ||
                refutationView.vendorSelectionType.code ===
                  this.global.appConstant.pm
                    .VENDOR_SELECTION_TYPE_MULTI_WINNER)) ||
            refutationView.refutationStatus.code ===
              this.global.appConstant.pm.REFUTATION_STATUS_DRAFT
          ) {
            this.global.routerParams.set('todo', 'edit');
          } else {
            this.global.routerParams.set('todo', 'view');
          }
          this.router.navigate(['/pages/refutation-vendor/detail']);
        } else {
          this.router.navigate(['/pages/refutation-vendor']);
          this.global.modalService.scheduleMismatch();
        }
      });
  }

  public validateSchedule(refutationView: RefutationView): boolean {
    if (refutationView.procurement.workflowModelPrcs.module.isSchedule) {
      if (refutationView.startDate && refutationView.endDate) {
        return (
          new Date(refutationView.startDate).getTime() <= new Date().getTime()
        );
      } else {
        return true;
      }
    }
  }

  public setData(): void {
    this.setDataFromRouterParams();
    this.setRequestValues();
    this.setFormGroup();
  }

  public setRequestValues(): void {
    const refutationList = [
      {
        value: false,
        name: this.translateService.instant('app.button.no'),
        description: this.translateService.instant(
          'refutation-vendor.info.confirmProcurementResult'
        )
      },
      {
        value: true,
        name: this.translateService.instant('app.button.yes'),
        description: this.translateService.instant(
          'refutation-vendor.info.submitRefutation'
        )
      }
    ];
    this.refutationOptionList.setRequestValues(refutationList);
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.refutationStatus = this.global.routerParams.get('refutationStatus');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.refutationVendorResponse = this.global.routerParams.get(
      'refutationVendorResponse'
    );

    // start set doc retrievak status dari proc history
    let refutationStatusTemp = localStorage.getItem(
      'refutationStatus'
    ) as Object;
    if (refutationStatusTemp) {
      refutationStatusTemp = JSON.parse(refutationStatusTemp.toString());
      this.refutationStatus = refutationStatusTemp as RefutationStatus;
      localStorage.removeItem('refutationStatus');
    }
    // end set doc retrievak status dari proc history

    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      bondType: [null],
      bondNumber: [null],
      bondIssued: [null],
      amount: [null],
      date: [null],
      startDate: [null],
      endDate: [null],
      note: [null],
      refutationVendor: [null],
      fileList: [null],
      isAccepted: [null],
      isRefute: [null, Validators.required()]
    });
  }

  public setViewOnly(): void {
    this.formGroup.setIsView(true);
    this.isViewOnly = true;
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<RefutationVendorResponse>(
        '/refutation-vendor/edit',
        new RouteRequestModel(this.todo, this.procurementId)
      )
      .subscribe((response: RefutationVendorResponse) => {
        this.refutationVendorResponse =
          this.refutationVendorResponse || response;
        this.procurementItemList =
          this.refutationVendorResponse.procurementItemList;
        this.itemTypeList = this.refutationVendorResponse.itemTypeList;
        this.workflowStepInfoModel =
          this.refutationVendorResponse.workflowStepInfoModel;
        this.procurement = this.refutationVendorResponse.procurement;
        this.quotation = this.refutationVendorResponse.quotation;
        this.minBidBondValue =
          (this.procurement.amount *
            this.global.config.parameterModel.minBidBondValue) /
          100;
        this.bondTypeOptionList.setRequestValues(
          this.refutationVendorResponse.bondTypeList
        );
        this.currencyOptionList.setRequestValues(
          this.refutationVendorResponse.currencyList
        );

        if (
          this.refutationVendorResponse.refutation &&
          this.refutationVendorResponse.refutation.refutationStatus &&
          (this.refutationStatus.code !==
            this.global.appConstant.pm.REFUTATION_STATUS_CLOSED_RESPONSE ||
            (this.refutationStatus.code ===
              this.global.appConstant.pm.REFUTATION_STATUS_CLOSED_RESPONSE &&
              this.refutationVendorResponse.refutation.refutationStatus.code ===
                this.global.appConstant.pm.REFUTATION_STATUS_SUBMITTED))
        ) {
          this.refutationStatus =
            this.refutationVendorResponse.refutation.refutationStatus;
        }

        this.doBuildTableResponse();

        if (
          this.procurement.vendorSelectionType.code ===
          this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
        ) {
          this.buildFormGroup();
          if (this.refutationVendorResponse.refutationVendor) {
            const isRefute =
              this.refutationStatus.code ===
                this.global.appConstant.pm.REFUTATION_STATUS_CLOSED_RESPONSE &&
              this.refutationVendorResponse.refutation.refutationStatus.code ===
                this.global.appConstant.pm.REFUTATION_STATUS_DRAFT
                ? this.refutationOptionList
                    .getRequestValues()
                    .find(refutationStatus => refutationStatus.value === false)
                : this.refutationOptionList
                    .getRequestValues()
                    .find(
                      refutationStatus =>
                        refutationStatus.value ===
                        this.refutationVendorResponse.refutationVendor.isRefute
                    );
            this.formGroup.patchValue({ isRefute });
            this.isShowForm = isRefute.value;
            if (this.todo === 'view') {
              const index = this.refutationOptionList
                .getRequestValues()
                .findIndex(
                  refutationStatus => refutationStatus.value === !isRefute.value
                );
              this.refutationOptionList.requestValues.splice(index, 1);
            }
            if (this.refutationVendorResponse.refutationSubmission) {
              const {
                id,
                bondType,
                bondNumber,
                bondIssued,
                amount,
                startDate,
                endDate,
                note,
                refutationVendor
              } = this.refutationVendorResponse.refutationSubmission;
              this.fileUploader.setFileList(
                this.refutationVendorResponse.refutationSubmissionDocList.map(
                  refutationSubmissionDoc => refutationSubmissionDoc.file
                )
              );
              this.formGroup.patchValue({
                id,
                bondType,
                bondNumber,
                bondIssued,
                amount: { price: amount },
                date: { from: startDate, to: endDate },
                startDate,
                endDate,
                note,
                refutationVendor,
                fileList: this.fileUploader.fileObjectList
              });
            }
          } else {
            if (
              this.refutationStatus.code ===
              this.global.appConstant.pm.REFUTATION_STATUS_CLOSED_RESPONSE
            ) {
              const isRefute = this.refutationOptionList
                .getRequestValues()
                .find(refutationStatus => refutationStatus.value === false);

              this.formGroup.patchValue({ isRefute });
              this.isShowForm = isRefute.value;
              const index = this.refutationOptionList
                .getRequestValues()
                .findIndex(
                  refutationStatus => refutationStatus.value === !isRefute.value
                );
              this.refutationOptionList.requestValues.splice(index, 1);
            }
          }
        } else {
          if (
            this.refutationVendorResponse.refutationVendorList &&
            this.refutationVendorResponse.refutationVendorList.length > 0
          ) {
            this.refutationVendorList =
              this.refutationVendorResponse.refutationVendorList;
          }
          if (
            this.refutationVendorResponse.refutationSubmissionList &&
            this.refutationVendorResponse.refutationSubmissionList.length > 0
          ) {
            this.refutationSubmissionList =
              this.refutationVendorResponse.refutationSubmissionList;
          }
        }

        const statusList = [
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
        ];

        if (statusList.includes(this.procurement.procurementStatus.code)) {
          if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'refutation-vendor.alert.msg.sorryWaitingCancelationApproval';
          } else if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'refutation-vendor.alert.msg.sorryWaitingFailedApproval';
          } else {
            this.canceledOrFailedMessage =
              'refutation-vendor.alert.msg.sorryFailedAssessment';
          }
        }
        this.setStateReady();
        if (this.todo === 'view') {
          this.setViewOnly();
        }
      });
  }

  public doBuildTableResponse(): void {
    if (
      this.procurement.vendorSelectionType.code ===
      this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
    ) {
      this.tableResponseFinalPriceEval = new TableResponseModel(
        this.moduleCode,
        this.procurement.biddingEvaluation.code !==
        this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
          ? [
              {
                field: 'vendorName',
                header: 'table.column.vendorName',
                plugins: {
                  name: 'hyperlink'
                }
              },
              {
                header: 'table.column.evaluation',
                columnList: [
                  {
                    field: 'administrationScoreStatus.name',
                    header: 'table.column.administration',
                    plugins: {
                      name: 'badge',
                      colorField: 'administrationScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  {
                    field: 'technicalScoreStatus.name',
                    header: 'table.column.technical',
                    plugins: {
                      name: 'badge',
                      colorField: 'technicalScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  this.procurement.biddingEvaluation.code ===
                  this.global.appConstant.pm
                    .BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
                    ? {
                        field: 'finalPriceScoreStatus.name',
                        header: 'table.column.price',
                        plugins: {
                          name: 'badge',
                          colorField: 'finalPriceScoreStatus.code',
                          colorMap: {
                            PASSED: 'SUCCESS',
                            NOT_PASSED: 'DANGER'
                          }
                        }
                      }
                    : {
                        field: 'finalPriceScore',
                        header: 'table.column.price',
                        className: 'text-right',
                        plugins: {
                          name: 'text-field',
                          type: 'decimal',
                          isView: true
                        }
                      }
                ]
              },
              {
                field: 'finalPrice',
                header: 'table.column.finalPrice',
                plugins: {
                  name: 'default',
                  type: 'currency'
                },
                className: 'text-right'
              },
              {
                field: 'finalProfitLossAmount',
                header: 'table.column.savingLoss',
                className: 'text-right',
                plugins: [
                  {
                    name: 'custom-plugin',
                    before: (tablePlugin: TablePluginData): void => {
                      if (tablePlugin.value) {
                        tablePlugin.element.innerText =
                          this.global.converterService.convertMoney(
                            tablePlugin.value
                          ) + ' %';
                      }
                    }
                  },
                  {
                    name: 'badge',
                    field: 'finalProfitLossStatus.name',
                    colorMap: {
                      SAVE: 'SUCCESS',
                      LOSS: 'DANGER'
                    },
                    pill: false,
                    colorField: 'finalProfitLossStatus.code',
                    className: 'badge-catalog ml-3'
                  }
                ]
              }
            ]
          : [
              {
                field: 'vendorName',
                header: 'table.column.vendorName',
                plugins: {
                  name: 'hyperlink'
                }
              },
              {
                header: 'table.column.evaluation',
                columnList: [
                  {
                    field: 'administrationScoreStatus.name',
                    header: 'table.column.administration',
                    plugins: {
                      name: 'badge',
                      colorField: 'administrationScoreStatus.code',
                      colorMap: {
                        PASSED: 'SUCCESS',
                        NOT_PASSED: 'DANGER'
                      }
                    }
                  },
                  {
                    field: 'technicalScore',
                    header: {
                      key: 'table.column.technical',
                      plugins: {
                        name: 'custom-plugin',
                        after: (
                          tableHeaderPlugin: TableHeaderPluginData
                        ): void => {
                          if (tableHeaderPlugin) {
                            tableHeaderPlugin.element.innerText +=
                              ' (' +
                              (this.refutationVendorResponse
                                .procurementFinalizationCriteria
                                .technicalPercentage || ' - ') +
                              '%)';
                          }
                        }
                      }
                    },
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  },
                  {
                    field: 'finalPriceScore',
                    header: {
                      key: 'table.column.price',
                      plugins: {
                        name: 'custom-plugin',
                        after: (
                          tableHeaderPlugin: TableHeaderPluginData
                        ): void => {
                          if (tableHeaderPlugin) {
                            tableHeaderPlugin.element.innerText +=
                              ' (' +
                              (this.refutationVendorResponse
                                .procurementFinalizationCriteria
                                .pricePercentage || ' - ') +
                              '%)';
                          }
                        }
                      }
                    },
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  },
                  {
                    field: 'totalFinalEvaluationScore',
                    header: 'table.column.total',
                    className: 'text-right',
                    plugins: {
                      name: 'text-field',
                      type: 'decimal',
                      isView: true
                    }
                  }
                ]
              },
              {
                field: 'finalPrice',
                header: 'table.column.finalPrice',
                plugins: {
                  name: 'default',
                  type: 'currency'
                },
                className: 'text-right'
              },
              {
                field: 'finalProfitLossAmount',
                header: 'table.column.savingLoss',
                className: 'text-right',
                plugins: [
                  {
                    name: 'custom-plugin',
                    before: (tablePlugin: TablePluginData): void => {
                      if (tablePlugin.value) {
                        tablePlugin.element.innerText =
                          this.global.converterService.convertMoney(
                            tablePlugin.value
                          ) + ' %';
                      }
                    }
                  },
                  {
                    name: 'badge',
                    field: 'finalProfitLossStatus.name',
                    colorMap: {
                      SAVE: 'SUCCESS',
                      LOSS: 'DANGER'
                    },
                    pill: false,
                    colorField: 'finalProfitLossStatus.code',
                    className: 'badge-catalog ml-3'
                  }
                ]
              }
            ]
      );
      this.tableResponseFinalPriceEval.setRecordList(
        this.refutationVendorResponse.vendorQuotationViewList
      );
      this.tableResponseFinalPriceEval.reload();
    } else {
      this.buildTableReferenceByItem();
      this.buildTableReferenceByVendor();
    }
  }

  public buildTableReferenceByItem(): void {
    this.tableResponseReferenceByItem = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'procurementItem.prItem.item.name',
          header: 'table.column.itemName',
          plugins: {
            name: 'hyperlink',
            onClick: this.doClickQuotationItem.bind(this)
          }
        },
        {
          field: 'procurementItem.prItem.pr.code',
          header: 'table.column.prNumber'
        },
        {
          field: 'procurementItem.prItem.code',
          header: 'table.column.prLine'
        },
        {
          field: 'procurementItem.prItem.item.code',
          header: 'table.column.itemCode'
        },
        {
          field: 'procurementItem.prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'procurementItem.prItem.item.itemType.code'
          }
        },
        {
          field: 'procurementItem.prItem.quantity',
          header: 'table.column.quantity',
          className: 'text-right',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            isView: true
          }
        },
        {
          field: 'procurementItem.prItem.item.uom.name',
          header: 'table.column.unit'
        },
        {
          field: 'submittedStatus.name',
          header: 'table.column.refutationStatus',
          plugins: {
            name: 'badge',
            colorMap: {
              AVAILABLE: 'SUCCESS',
              UNAVAILABLE: 'SECONDARY',
              WAITING_RESPONSE: 'GOOD'
            },
            colorField: 'submittedStatus.code'
          }
        }
      ]
    );

    this.tableResponseReferenceByItem.setRecordList(
      this.refutationVendorResponse.refutationVendorByItemViewList
    );
    this.tableResponseReferenceByItem.reload();
  }

  public buildTableReferenceByVendor(): void {
    this.tableResponseReferenceByVendor = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'quotation.procurementVendor.vendor.name',
          header: 'table.column.vendorName',
          plugins: {
            name: 'hyperlink',
            onClick: this.doShowItem.bind(this)
          }
        },
        this.procurement.biddingEvaluation.code !==
        this.global.appConstant.pm.BIDDING_EVALUATION_CODE_MERIT_POINT
          ? {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScoreStatus.name',
                  header: 'table.column.technical',
                  plugins: {
                    name: 'badge',
                    colorField: 'technicalScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                }
              ]
            }
          : {
              header: 'table.column.evaluation',
              columnList: [
                {
                  field: 'administrationScoreStatus.name',
                  header: 'table.column.administration',
                  plugins: {
                    name: 'badge',
                    colorField: 'administrationScoreStatus.code',
                    colorMap: {
                      PASSED: 'SUCCESS',
                      NOT_PASSED: 'DANGER'
                    }
                  }
                },
                {
                  field: 'technicalScore',
                  header: {
                    key: 'table.column.technical',
                    plugins: {
                      name: 'custom-plugin',
                      after: (
                        tableHeaderPlugin: TableHeaderPluginData
                      ): void => {
                        if (tableHeaderPlugin) {
                          tableHeaderPlugin.element.innerText +=
                            ' (' +
                            (this.refutationVendorResponse
                              .procurementFinalizationCriteria
                              .technicalPercentage || ' - ') +
                            '%)';
                        }
                      }
                    }
                  },
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                },
                {
                  field: 'totalFinalEvaluationScore',
                  header: 'table.column.total',
                  className: 'text-right',
                  plugins: {
                    name: 'text-field',
                    type: 'decimal',
                    isView: true
                  }
                }
              ]
            },
        {
          header: 'table.column.allocatedItem',
          columnList: [
            {
              field: 'goodsSelectedItem',
              header: 'table.column.goodsItem',
              className: 'text-center'
            },
            {
              field: 'serviceSelectedItem',
              header: 'table.column.serviceItem',
              className: 'text-center'
            }
          ]
        }
      ]
    );
    this.tableResponseReferenceByVendor.setRecordList(
      this.refutationVendorResponse.vendorDesignationSelectedVendorViewList
    );
    this.tableResponseReferenceByVendor.reload();
  }

  public doShowItem(
    vendorReferenceSelectedVendorView: VendorReferenceSelectedVendorView
  ): void {
    this.appPopupService.open(
      AppPopupRefutationVendorItemDetailComponent,
      {
        procurementVendor:
          vendorReferenceSelectedVendorView.quotation.procurementVendor,
        itemTypeList: this.itemTypeList,
        biddingEvaluation: this.procurement.biddingEvaluation,
        technicalPercentage:
          this.refutationVendorResponse.procurementFinalizationCriteria
            .technicalPercentage
      },
      { size: 'xl' }
    );
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public onChangeRefutation(data: any): void {
    if (data.value) {
      this.isShowForm = true;
      this.formGroup
        .get('isAccepted')
        .setValidators(
          Validators.isChecked(
            null,
            this.translateService.instant(
              'refutation-vendor.msg.warning.accToProceed'
            )
          )
        );
      this.formGroup.get('bondType').setValidators(Validators.required());
      this.formGroup.get('bondNumber').setValidators(Validators.required());
      this.formGroup.get('bondIssued').setValidators(Validators.required());
      this.formGroup
        .get('amount')
        .setValidators(
          Validators.compose([
            Validators.min(this.minBidBondValue),
            Validators.required()
          ])
        );
      this.formGroup.get('date').setValidators(Validators.required());
      this.formGroup.get('fileList').setValidators(Validators.required());
    } else {
      this.isShowForm = false;
      this.formGroup.get('isAccepted').clearValidators();
      this.formGroup.get('bondType').clearValidators();
      this.formGroup.get('bondNumber').clearValidators();
      this.formGroup.get('bondIssued').clearValidators();
      this.formGroup.get('amount').clearValidators();
      this.formGroup.get('date').clearValidators();
      this.formGroup.get('fileList').clearValidators();
    }
    this.formGroup.get('isAccepted').updateValueAndValidity();
    this.formGroup.get('bondType').updateValueAndValidity();
    this.formGroup.get('bondNumber').updateValueAndValidity();
    this.formGroup.get('bondIssued').updateValueAndValidity();
    this.formGroup.get('amount').updateValueAndValidity();
    this.formGroup.get('date').updateValueAndValidity();
    this.formGroup.get('fileList').updateValueAndValidity();
  }

  public onInputBondValue(data: any): void {
    if (+data.price && +data.price < this.minBidBondValue) {
      this.formGroup
        .get('amount')
        .setValidators(Validators.min(this.minBidBondValue));
      this.formGroup.get('amount').setErrors({
        message: this.translateService.instant('app.validation.min'),
        min: true
      });
    } else {
      this.formGroup.get('amount').setValidators(Validators.required());
      this.formGroup.get('amount').updateValueAndValidity();
    }
    this.formGroup.get('amount').markAsTouched();
  }

  public doSave(): void {
    if (
      this.procurement.vendorSelectionType.code ===
      this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
    ) {
      this.formGroup.get('isAccepted').clearValidators();
      this.formGroup.get('isAccepted').updateValueAndValidity();
    }

    this.validate();
    if (this.formGroup.valid) {
      this.setStateProcessing();
      const refutationVendorRequest = new RefutationVendorRequest();
      refutationVendorRequest.isSubmit = false;
      const refutation: Refutation = new Refutation();
      refutation.id = this.refutationVendorResponse.refutation?.id;
      refutation.quotation = this.quotation;
      refutationVendorRequest.refutation = refutation;

      if (
        this.procurement.vendorSelectionType.code ===
        this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
      ) {
        const refutationVendor = new RefutationVendor();
        refutationVendor.id =
          this.refutationVendorResponse.refutationVendor?.id;
        refutationVendor.refutation = refutation;
        refutationVendor.isRefute = this.formGroup.get('isRefute').value.value;

        if (refutationVendor.isRefute) {
          const refutationSubmission: RefutationSubmission =
            this.global.copyFormAttributeToModel(
              new RefutationSubmission(),
              this.formGroup
            );
          refutationSubmission.amount = +this.formGroup.value.amount.price;
          refutationSubmission.startDate = this.formGroup.value.date.from;
          refutationSubmission.endDate = this.formGroup.value.date.to;
          this.refutationSubmissionList.push(refutationSubmission);
          refutationVendor.fileObjectList = this.formGroup.value.fileList;
        }
        this.refutationVendorList.push(refutationVendor);
      }
      refutationVendorRequest.refutationVendorList = this.refutationVendorList;
      refutationVendorRequest.refutationSubmissionList =
        this.refutationSubmissionList;

      this.httpClientService
        .post<Response<RefutationVendor>>(
          '/refutation-vendor/update',
          refutationVendorRequest
        )
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.snackbarService.showWarning('app.alert.msg.saveSuccess');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set(
                  'procurementId',
                  this.procurementId
                );
                this.global.routerParams.set(
                  'refutationStatus',
                  response.body.refutationStatus
                );
                this.global.routerParams.set(
                  'urlBackOutside',
                  '/pages/refutation-vendor'
                );
                this.global.routerParams.set('todo', 'edit');
                this.router.navigate(['/pages/refutation-vendor/detail']);
              });
          } else {
            this.toastService.showError(response.statusText);
          }
          this.setStateReady();
        });
    } else {
      if (this.formGroup.get('isRefute').invalid) {
        this.toastService.showError(
          this.translateService.instant(
            'refutation-vendor.alert.msg.chooseRefutationSubmissionFirst'
          )
        );
      }
    }
  }

  public doSubmit(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const refutationVendorRequest = new RefutationVendorRequest();
            refutationVendorRequest.isSubmit = true;
            const refutation: Refutation = new Refutation();
            refutation.id = this.refutationVendorResponse.refutation?.id;
            refutation.quotation = this.quotation;
            refutationVendorRequest.refutation = refutation;

            if (
              this.procurement.vendorSelectionType.code ===
              this.global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
            ) {
              const refutationVendor = new RefutationVendor();
              refutationVendor.id =
                this.refutationVendorResponse.refutationVendor?.id;
              refutationVendor.refutation = refutation;
              refutationVendor.isRefute =
                this.formGroup.get('isRefute').value.value;

              if (refutationVendor.isRefute) {
                const refutationSubmission: RefutationSubmission =
                  this.global.copyFormAttributeToModel(
                    new RefutationSubmission(),
                    this.formGroup
                  );
                refutationSubmission.amount =
                  +this.formGroup.value.amount.price;
                refutationSubmission.startDate = this.formGroup.value.date.from;
                refutationSubmission.endDate = this.formGroup.value.date.to;
                this.refutationSubmissionList.push(refutationSubmission);
                refutationVendor.fileObjectList = this.formGroup.value.fileList;
              }
              this.refutationVendorList.push(refutationVendor);
            } else {
              const refutationItemWaitingResponse =
                this.refutationVendorResponse.refutationVendorByItemViewList.filter(
                  refItem =>
                    refItem.submittedStatus.code ===
                    this.global.appConstant.pm.SUBMITTED_STATUS_WAITING_RESPONSE
                );
              if (
                refutationItemWaitingResponse &&
                refutationItemWaitingResponse.length > 0
              ) {
                refutationItemWaitingResponse.forEach(refItem => {
                  const refutationVendor = new RefutationVendor();
                  refutationVendor.refutation = refutation;
                  refutationVendor.isRefute = false;
                  refutationVendor.procurementItem = refItem.procurementItem;
                  this.refutationVendorList.push(refutationVendor);
                });
              }
            }
            refutationVendorRequest.refutationVendorList =
              this.refutationVendorList;
            refutationVendorRequest.refutationSubmissionList =
              this.refutationSubmissionList;

            this.httpClientService
              .post<Response<RefutationVendor>>(
                '/refutation-vendor/update',
                refutationVendorRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService.submitSuccess().subscribe(result => {
                    if (result) {
                      this.router.navigate(['/pages/refutation-vendor/']);
                    } else {
                      this.router
                        .navigateByUrl('/', {
                          skipLocationChange: true
                        })
                        .then(() => {
                          this.global.routerParams.clear();
                          this.global.routerParams.set(
                            'refutationStatus',
                            response.body.refutationStatus
                          );
                          this.global.routerParams.set(
                            'procurementId',
                            this.procurementId
                          );
                          this.global.routerParams.set(
                            'urlBackOutside',
                            '/pages/refutation-vendor'
                          );
                          this.global.routerParams.set('todo', 'view');
                          this.router.navigate([
                            '/pages/refutation-vendor/detail'
                          ]);
                        });
                    }
                  });
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    } else {
      if (this.formGroup.get('isRefute').invalid) {
        this.toastService.showError(
          this.translateService.instant(
            'refutation-vendor.alert.msg.chooseRefutationSubmissionFirst'
          )
        );
      }
    }
  }

  public doOpenPopupTOC(): void {
    this.appPopupService.open(AppPopupRefutationVendorTocComponent);
  }

  public doClickQuotationItem(
    refutationVendorByItemView: RefutationVendorByItemView
  ): void {
    this.refutationVendorResponse.refutationVendorList =
      this.refutationVendorList;
    this.refutationVendorResponse.refutationSubmissionList =
      this.refutationSubmissionList;
    this.global.routerParams.set(
      'refutationVendorByItemView',
      refutationVendorByItemView
    );
    this.global.routerParams.set('procurementId', this.procurementId);
    this.global.routerParams.set('todo', this.todo);
    this.global.routerParams.set('refutationStatus', this.refutationStatus);
    this.global.routerParams.set(
      'refutationVendorResponse',
      this.refutationVendorResponse
    );
    this.global.routerParams.set('urlBackOutside', '/pages/refutation-vendor');
    this.global.routerParams.set('backToUrl', this.router.url);
    this.formGroup.reset();
    this.router.navigate(['/pages/refutation-vendor/detail-submission']);
  }
}
