import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { ProcurementMatrix } from 'src/app/core/bean/procurement-matrix';
import { SelectedTableRecordModel } from 'src/app/core/components/table/model/selected-table-record-model';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';

@Component({
  templateUrl: './procurement-matrix.component.html'
})
export class ProcurementMatrixComponent
  extends BaseModuleComponent
  implements OnInit
{
  public tableResponse: TableResponseModel<ProcurementMatrix>;

  constructor(translateService: TranslateService) {
    super('procurement-matrix', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClick.bind(this)
        }
      },
      {
        field: 'name',
        header: 'table.column.name'
      },
      {
        field: 'description',
        header: 'table.column.description',
        className: 'white-space-normal'
      }
    ]);
  }

  public doClick(procurementMatrix: ProcurementMatrix): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('procurementMatrixId', procurementMatrix.id);
    this.global.routerParams.set('urlBackOutside', '/pages/procurement-matrix');
    this.global.routerParams.set('todo', 'edit');
    this.router.navigate(['/pages/procurement-matrix/edit']);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/procurement-matrix/add']);
    this.global.routerParams.set('urlBackOutside', '/pages/procurement-matrix');
  }

  public doDelete(event: SelectedTableRecordModel): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<ProcurementMatrix[]>>(
              '/procurement-matrix/delete',
              event.selectedRecordList
            )
            .subscribe((response: Response<ProcurementMatrix[]>) => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }
}
