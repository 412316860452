import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { NotificationTemplate } from '../../core/bean/notification-template';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { Notification } from './../../core/bean/notification';
import { NotificationType } from './../../core/bean/notification-type';
import { NotificationTemplateResponse } from './notification-template-response';
import { NotificationTemplateRequest } from './notification-template-request';

@Component({
  templateUrl: 'notification-template-edit-add.component.html'
})
export class NotificationTemplateEditAddComponent extends BaseModuleComponent {
  public notificationTemplateResponse: NotificationTemplateResponse = new NotificationTemplateResponse();
  public notificationTemplateRequest: NotificationTemplateRequest = new NotificationTemplateRequest();
  public notificationId: number;
  public moduleGroupName: string;
  public statusList: OptionListModel<any> = new OptionListModel();
  constructor(translateService: TranslateService) {
    super('notification-template', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doSetStatusList();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.notificationId = this.global.routerParams.get('notificationId');
  }

  public doSetStatusList(): void {
    const notificationTemplateStatus = [
      { name: 'app.status.active', value: true },
      { name: 'app.status.inActive', value: false }
    ];
    this.statusList.setRequestValues(notificationTemplateStatus);
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      moduleGroup: [null],
      description: [null],
      isActive: [null, Validators.required()],
      notifTemplate: this.formBuilder.array([])
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<NotificationTemplateResponse>(
        '/notification-template/edit',
        new RouteRequestModel(this.todo, this.notificationId)
      )
      .subscribe((notificationTemplateResponse: NotificationTemplateResponse) => {
        this.notificationTemplateResponse = notificationTemplateResponse;
        if (this.todo === 'edit') {
          const status = this.statusList
            .getRequestValues()
            .filter(
              valueOption =>
                valueOption.value ===
                notificationTemplateResponse.notification.isActive
            )[0];
          this.formGroup.patchValue(this.notificationTemplateResponse.notification);
          this.formGroup.patchValue({ isActive: status });
          this.moduleGroupName = this.notificationTemplateResponse.notification.moduleGroup.name;
          this.buildForm();
        }
      });
  }

  public buildForm(): void {
    this.notificationTemplateResponse.langList.forEach(lang => {
      const notifTemplateList: NotificationTemplate[] = [];
      this.notificationTemplateResponse.notificationTemplateList.forEach(
        notifTemplate => {
          if (notifTemplate.lang.id === lang.id) {
            notifTemplateList.push(notifTemplate);
          }
        }
      );
      this.notifTemplate.push(
        this.formBuilder.group({
          name: lang.name,
          notifTemplateDetail: this.buildNotifTemplate(notifTemplateList)
        })
      );
    });
    this.setStateReady();
  }

  public buildNotifTemplate(
    notifTemplateList: NotificationTemplate[]
  ): FormArray {
    const controlLang = new FormArray([]);
    notifTemplateList.forEach(notifTemplate => {
      const {
        id,
        subject,
        body,
        lang,
        notification,
        notificationType,
        isActive
      } = notifTemplate;
      controlLang.push(
        this.formBuilder.group({
          id,
          // body: notificationType.code === 'EMAIL' ? body : body, // kondisinya sama aja, harusnya tidak perlu make kondisi
          body,
          lang,
          notification,
          notificationType,
          isActive,
          subject: [
            subject,
            Validators.compose([
              Validators.required(),
              Validators.maxLength(128)
            ])
          ]
        })
      );
    });
    return controlLang;
  }

  public onChangeNotificationType(
    event: any,
    notificationType: NotificationType
  ): void {
    this.notifTemplate.controls.forEach(notifTemplateDetail => {
      const notifTemplateDetailArray = notifTemplateDetail.get(
        'notifTemplateDetail'
      ) as FormArray;
      notifTemplateDetailArray.controls.forEach(notifTemplate => {
        if (
          notifTemplate.value.notificationType.name === notificationType.name
        ) {
          notifTemplate.patchValue({
            isActive: event ? true : false
          });
        }
      });
    });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const notification: Notification = this.global.copyFormAttributeToModel(
              new Notification(),
              this.formGroup
            );
            notification.isActive = this.formGroup.get('isActive').value.value;
            const notificationTemplateList: Array<NotificationTemplate> = new Array();
            this.formGroup.value.notifTemplate.forEach(notifTemplate => {
              notifTemplate.notifTemplateDetail.forEach(
                (notifTemplateDetail: NotificationTemplate) => {
                  notificationTemplateList.push(notifTemplateDetail);
                }
              );
            });
            this.notificationTemplateRequest.notificationTemplateList = notificationTemplateList;
            this.notificationTemplateRequest.notification = notification;
            this.httpClientService
              .post<Response<NotificationTemplateRequest>>(
                '/notification-template/update',
                this.notificationTemplateRequest
              )
              .subscribe((response: Response<NotificationTemplateResponse>) => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/notification-template/']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public get notifTemplate(): FormArray {
    return this.formGroup.controls.notifTemplate as FormArray;
  }

  public doCancel(): void {
    this.router.navigate(['/pages/notification-template/']);
  }

  public getFormArray(
    formGroup: FormGroup,
    formControlName: string
  ): FormArray {
    return formGroup.get(formControlName) as FormArray;
  }
}
