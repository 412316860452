<app-layout [isLoading]="formLoading">
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge
      *ngIf="todo !== 'add'"
      class="cml-2"
      [color]="badgeColor[response.contract.contractStatus.code]"
    >
      {{ getTranslateKey(response.contract.contractStatus) | translate }}
    </app-badge>
    <app-badge *ngIf="todo === 'add'" class="cml-2" color="INFO">
      {{ 'contract-request.badge.status.new' | translate }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12">
      <app-alert-x *ngIf="isCanceled" color="DANGER" [isBold]="false">
        {{ 'contract-request.alert.msg.thisProcessWasCanceledBy' | translate }}
        <strong>{{ response.contractCancelation?.cancelByUser?.name }}</strong>
        {{ 'contract-request.alert.msg.at' | translate }}
        <strong>
          {{
            response.contractCancelation?.cancelDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}
        </strong>
        {{ 'contract-request.alert.msg.theCancelationReasonIs' | translate }}
        <strong>&#34;{{ response.contractCancelation?.note }}&#34;</strong>
      </app-alert-x>

      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        *ngIf="!formLoading"
        novalidate
      >
        <app-card
          header="contract-request.detail.data.title"
          tips="contract-request.detail.data.tips"
        >
          <ng-template #headerRight>
            <app-button
              *ngIf="formGroup.isView && contractPreparation"
              (onClick)="viewDetail()"
            >
              {{ 'contract-request.button.viewDetail' | translate }}
            </app-button>
          </ng-template>

          <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>
          <div class="form-group row text-sm-left" *ngIf="formGroup.isView">
            <label class="col-sm-3">{{
              'contract-request.form.requestNumber' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              {{ contract ? contract.requestNumber : '-' }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'contract-request.form.requestDate' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-date-picker-x
                formControlName="requestDate"
                [minDate]="currentDate"
              >
              </app-date-picker-x>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'contract-request.form.contractType' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                formControlName="contractType"
                [optionList]="contractTypeOptionList"
                (onChange)="doChangeContractType()"
                [ngStyle]="{ 'min-width': '10rem' }"
              ></app-dropdown-select>
            </div>
          </div>
          <div
            class="form-group row text-sm-left"
            [ngClass]="!formGroup.isView ? 'required' : ''"
            *ngIf="
              !formLoading &&
              formGroup.value.contractType?.code !==
                global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
            "
          >
            <label class="col-sm-3">{{
              'contract-request.form.dealingAgreementType' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                formControlName="dealingAgreementType"
                [optionList]="dealingAgreementTypeOptionList"
                [ngStyle]="{ 'min-width': '10rem' }"
              ></app-dropdown-select>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'contract-request.form.description' | translate
            }}</label>
            <div
              [ngClass]="
                !formGroup.isView ? 'col-sm-9 text-left' : 'col-sm-6 text-left'
              "
            >
              <app-text-area formControlName="description" maxLength="2000">
              </app-text-area>
            </div>
          </div>
          <div class="text-sm-right">
            <div
              *ngIf="!formLoading && !formGroup.isView"
              class="button-group button-group-right"
            >
              <app-button
                color="PRIMARY"
                [outline]="true"
                *ngIf="contractPreparation"
                (onClick)="viewDetail()"
              >
                {{ 'contract-request.button.viewDetail' | translate }}
              </app-button>
              <app-button
                *ngIf="
                  !formGroup.isView &&
                  formGroup.value.contractType?.code !==
                    global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
                "
                [disabled]="!formGroup.value.dealingAgreementType"
                (onClick)="doChooseProcurement()"
              >
                {{ 'contract-request.button.addSource' | translate }}
              </app-button>
              <app-button
                *ngIf="
                  !formGroup.isView &&
                  formGroup.value.contractType?.code ===
                    global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
                "
                [disabled]="!formGroup.value.contractType"
                (onClick)="doChooseContract()"
              >
                {{ 'contract-request.button.addSource' | translate }}
              </app-button>
            </div>
          </div>

          <ng-container *ngIf="!contractPreparation && !isSourceReady">
            <h3 style="padding-bottom: 20px">
              {{ 'contract-request.separator.generalInformation' | translate }}
            </h3>
            <perfect-scrollbar>
              <table class="table">
                <thead #theadDefault> </thead>
                <tbody>
                  <tr>
                    <td
                      colspan="9999999"
                      rowspan="7"
                      class="bg-white text-center"
                    >
                      <div
                        style="
                          text-align: center;
                          padding: 50px 0;
                          display: block;
                        "
                      >
                        <div
                          class="pvc pv-file-list"
                          style="height: 68px; width: 68px; margin: 0 auto"
                        ></div>
                        <div style="margin-top: 20px">
                          <span
                            style="
                              margin-top: 20px;
                              display: block;
                              color: var(--gray-500);
                              font-weight: 500;
                            "
                          >
                            {{ 'app-table.noDataAvailable' | translate }}
                          </span>
                          <span
                            style="
                              margin-top: 20px;
                              display: block;
                              color: var(--gray-500);
                              font-weight: 500;
                            "
                          >
                            {{ 'app-table.letsTryToAddList' | translate }}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </perfect-scrollbar>
          </ng-container>
          <ng-container *ngIf="contractPreparation">
            <h3 class="separator">
              {{ 'contract-request.separator.generalInformation' | translate }}
            </h3>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'contract-request.form.object' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{ contractPreparation ? contractPreparation.code : '-' }}
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'contract-request.form.date' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{
                  contractPreparation.orderDate
                    | date: global.appConstant.core.FORMAT_SHORT_DATE
                }}
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'contract-request.form.title' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{ contractPreparation ? contractPreparation.title : '-' }}
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'contract-request.form.department' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{
                  contractPreparation.organization
                    ? contractPreparation.organization.name
                    : '-'
                }}
              </div>
            </div>
          </ng-container>
        </app-card>

        <app-item-request-released
          *ngIf="!formLoading && isSourceReady"
          [objectList]="prItemReleasedList"
          [isShowPrice]="true"
          [isView]="true"
          [itemTypeList]="itemTypeList"
          [vendorId]="vendorId"
        ></app-item-request-released>

        <app-card
          *ngIf="!formLoading && isSourceReady"
          header="contract-request.vendor.title"
          tips="contract-request.vendor.tips"
        >
          <ng-template #headerRight>
            <app-button (onClick)="goToVendorProfile()">
              {{
                'contract-request.button.vendorProfiles' | translate
              }}</app-button
            >
          </ng-template>
          <app-table-xx [model]="tableResponseVendor"> </app-table-xx>
        </app-card>

        <app-card
          *ngIf="!formLoading && isSourceReady"
          header="contract-request.detail.shippingAddress.title"
          tips="contract-request.detail.shippingAddress.tips"
        >
          <ng-template #headerRight>
            <div class="button-group button-group-right">
              <app-button
                *ngIf="!formGroup.isView"
                (onClick)="doAddAddress()"
                [disabled]="!isSourceReady"
              >
                <em class="pir pi-plus-circle"></em>
                {{ 'contract-request.button.addAddress' | translate }}
              </app-button>
            </div>
          </ng-template>

          <app-alert-x color="INFO" *ngIf="!formGroup.isView">
            {{ 'contract-request.shippingAddress.info.alert' | translate }}
          </app-alert-x>

          <app-select
            formControlName="contractShipping"
            [optionList]="contractShippingOptionList"
            selectAllText="{{
              'contract-request.shippingAddress.selectAll' | translate
            }}"
            [isMultiple]="true"
            maxHeight="max-content"
            [isShowHover]="false"
          >
            <ng-template #content let-data>
              <div
                style="
                  border: 1px solid var(--gray-400);
                  border-radius: 5px;
                  box-sizing: border-box;
                  padding: 1rem;
                "
              >
                <p>{{ data.address.organization.name }}</p>
                <h5>{{ getUserNameList(data.address.addressPicList) }}</h5>
                <p>{{ data.address.addressDetail }}</p>
                <p>{{ data.address.phone }}</p>

                <div
                  class="py-1"
                  style="border-top: 1px dashed var(--gray-400)"
                  *ngIf="
                    contractShippingOptionList.getRequestValues().length > 1
                  "
                >
                </div>
                <div class="button-group button-group-right">
                  <app-button
                    (onClick)="doSplit(data)"
                    *ngIf="
                      !formGroup.isView &&
                      contractShippingOptionList.getRequestValues().length > 1
                    "
                  >
                    {{ 'contract-request.button.splitItemToShip' | translate }}
                  </app-button>

                  <app-button
                    (onClick)="doViewItem(data)"
                    *ngIf="
                      formGroup.isView &&
                      contractShippingOptionList.getRequestValues().length > 1
                    "
                  >
                    {{ 'contract-request.button.viewItem' | translate }}
                  </app-button>
                </div>
              </div>
            </ng-template>

            <ng-template #actionButtons>
              <div
                *ngIf="!formGroup.isView"
                [ngStyle]="{ cursor: 'pointer', display: 'contents' }"
              >
                <em
                  class="pir pi-trash-alt mr-2"
                  (click)="doDeleteShipping()"
                  style="font-size: 24px"
                ></em>
                <span (click)="doDeleteShipping()">
                  {{ 'app.button.delete' | translate }}
                </span>
              </div>
            </ng-template>
          </app-select>
        </app-card>

        <app-card
          *ngIf="!formLoading && isSourceReady"
          header="contract-request.detail.workDefinition.title"
          tips="contract-request.detail.workDefinition.tips"
        >
          <app-sow
            *ngIf="!formLoading && isSourceReady"
            [isView]="formGroup.isView"
            [sowList]="sowList"
            [prItemReleasedList]="prItemReleasedList"
            [amount]="amount"
            [isDisabled]="!isSourceReady"
            [isBlanketOrder]="isBlanketOrder"
            (onChange)="onChangeSowList($event)"
          ></app-sow>

          <br />
          <app-fines
            *ngIf="!formLoading && isSourceReady"
            [isView]="formGroup.isView"
            [finesList]="finesList"
            [amount]="amount"
            [isDisabled]="!isSourceReady"
            (onChange)="onChangeFinesList($event)"
          ></app-fines>

          <br />
          <app-guarantee
            *ngIf="!formLoading && isSourceReady"
            [isView]="formGroup.isView"
            [guaranteeList]="guaranteeList"
            [amount]="amount"
            [isDisabled]="!isSourceReady"
            (onChange)="onChangeGuaranteeList($event)"
          ></app-guarantee>
        </app-card>
      </form>

      <app-card
        *ngIf="!formLoading && (todo === 'cancel' || isCanceled)"
        header="contract-request.detail.cancelContract.title"
        tips="contract-request.detail.cancelContract.tips"
      >
        <form
          class="form-horizontal"
          [formGroup]="formGroupCanceled"
          novalidate
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'contract-request.popup.requestNumber' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              {{ contract ? contract.requestNumber : '-' }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'contract-request.popup.cancelDate' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              {{
                formGroupCanceled.value.cancelDate
                  | date: global.appConstant.core.FORMAT_SHORT_DATE
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'contract-request.popup.stage' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              {{
                formGroupCanceled.value.stage
                  ? formGroupCanceled.value.stage
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'contract-request.popup.cancelReason' | translate
            }}</label>
            <div class="col-sm-7 text-left">
              {{
                formGroupCanceled.value.note
                  ? formGroupCanceled.value.note
                  : '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'contract-request.popup.attachment' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-upload-x
                formControlName="fileDoc"
                [fileUploader]="fileUploader"
                size="LG"
              ></app-upload-x>
            </div>
          </div>
        </form>
      </app-card>

      <app-approval-monitoring
        *ngIf="
          !formLoading &&
          response.approvalPathResponseModel &&
          !response.isUserApproval &&
          response?.contract?.contractStatus?.code &&
          this.statusForApprovalList.includes(
            response?.contract?.contractStatus?.code
          )
        "
        [workflowPrcs]="response?.contract?.workflowPrcs"
        [moduleCode]="moduleCode"
        [approvalPathResponseModel]="response.approvalPathResponseModel"
      ></app-approval-monitoring>

      <div class="floating-button mt-5">
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button
                color="DANGER"
                [outline]="true"
                (onClick)="doCancel()"
                *ngIf="
                  response.contract?.contractStatus.code ===
                  global.appConstant.cm.CONTRACT_STATUS_REQUEST_ON_PROGRESS
                "
              >
                {{ 'app.button.cancel' | translate }}</app-button
              >
              <app-button
                (onClick)="doSaveDraft()"
                color="PRIMARY"
                [outline]="true"
                *ngIf="
                  !formGroup.isView &&
                  (todo === 'add' ||
                    response.contract?.contractStatus.code ===
                      this.global.appConstant.cm.CONTRACT_STATUS_DRAFT)
                "
                [disabled]="prItemReleasedList.length === 0"
              >
                {{ 'app.button.save' | translate }}</app-button
              >
              <app-button
                (onClick)="doSubmit()"
                *ngIf="!formGroup.isView && !isRevisions"
                [disabled]="prItemReleasedList.length === 0"
              >
                {{ 'app.button.submit' | translate }}</app-button
              >

              <app-button
                *ngIf="isRevisions"
                (onClick)="doSubmit()"
                color="PRIMARY"
                [outline]="true"
                [disabled]="prItemReleasedList.length === 0"
              >
                {{ 'contract-request.button.resend' | translate }}
              </app-button>
            </div>
          </div>
        </div>
      </div>

      <app-approval-prcs-x
        *ngIf="response.isUserApproval"
        [workflowModelPrcs]="response.contract?.workflowModelPrcs"
        [approvalModelPrcsId]="approvalModelPrcsId"
      >
      </app-approval-prcs-x>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips *ngIf="!formLoading && !isSourceLoading"></app-tips>
  </ng-template>
</app-layout>
