import { ProcurementDocType } from '../../bean/procurement-doc-type';
import { ProcurementType } from '../../bean/procurement-type';
import { FileObject } from '../upload';

export class AppUploadDocumentTableModel {
  id: number;
  crudOperation: number;
  accessibility: ProcurementType;
  procurementDocType: ProcurementDocType;
  fileObject: FileObject;
}
