<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="reminder.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'reminder.code' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ notification.code }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'reminder.name' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ notification.name }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'reminder.description' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ notification.description }}
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'reminder.timeReminder' | translate
          }}</label>
          <div class="col-sm-2 text-left">
            <input
              type="number"
              class="form-control"
              formControlName="time"
              maxlength="64"
              [ngClass]="{
                'is-invalid': submitted && formGroupControls.time.errors
              }"
              aria-label="Time"
            />
            <small
              class="text-danger"
              *ngIf="
                (submitted && formGroupControls.time.errors) ||
                (formGroupControls.time.touched &&
                  formGroupControls.time.errors)
              "
              >{{ 'app.validation.required' | translate }}</small
            >
          </div>
          <label>{{ 'reminder.day' | translate }}</label>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'reminder.isRepeat' | translate
          }}</label>
          <div class="col-sm-7 text-left">
            <label class="radio-inline">
              <input
                type="radio"
                formControlName="isRepeat"
                [value]="true"
                aria-label="IS Repeat One Time"
              />
              {{ 'reminder.onetime' | translate }}
            </label>
            <label class="radio-inline ml-2">
              <input
                type="radio"
                formControlName="isRepeat"
                [value]="false"
                aria-activedescendant="Is Repeat"
              />
              {{ 'reminder.repeat' | translate }}
            </label>
            <div class="col-sm-7 text-left">
              <small
                class="text-danger"
                *ngIf="
                  (submitted && formGroupControls.isRepeat.errors) ||
                  (formGroupControls.isRepeat.touched &&
                    formGroupControls.isRepeat.errors)
                "
                >{{ 'app.validation.required' | translate }}</small
              >
            </div>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'reminder.period' | translate
          }}</label>
          <div class="col-sm-2 text-left">
            <input
              type="number"
              class="form-control"
              formControlName="period"
              maxlength="64"
              [ngClass]="{
                'is-invalid': submitted && formGroupControls.period.errors
              }"
              aria-label="Period"
            />
            <small
              class="text-danger"
              *ngIf="
                (submitted && formGroupControls.period.errors) ||
                (formGroupControls.period.touched &&
                  formGroupControls.period.errors)
              "
              >{{ 'app.validation.required' | translate }}</small
            >
          </div>
          <label>{{ 'reminder.day' | translate }}</label>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'reminder.isActive' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <select
              class="form-control validation-field"
              id="isActive"
              formControlName="isActive"
              [ngClass]="{
                'is-invalid': submitted && formGroupControls.isActive.errors
              }"
              aria-label="is Active"
            >
              <option value="true">{{ 'reminder.active' | translate }}</option>
              <option value="false">{{
                'reminder.notActive' | translate
              }}</option>
            </select>
            <small
              class="text-danger"
              *ngIf="
                (submitted && formGroupControls.isActive.errors) ||
                (formGroupControls.isActive.touched &&
                  formGroupControls.isActive.errors)
              "
              >{{ 'app.validation.required' | translate }}</small
            >
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="doCancel()"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}</button
          >
          <button
            type="button"
            class="btn btn-primary ml-2"
            (click)="doSave()"
            >{{ 'app.button.save' | translate }}</button
          >
        </div>
      </form>
    </app-card>
  </div>
</div>
