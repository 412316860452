<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="module-dependency.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-table [model]="tableResponse" [isServerSide]="true"
        stringUrl="/module-dependency/index">
        <ng-template #headerFilterTable>
          <div class="app-table-filter-item" [formGroup]="formGroup">
            <label for="moduleGroupCode">Modul</label>
            <select (change)="doOnChangeModuleGroup()" class="form-control"
              required formControlName="moduleGroupCode"
              aria-label="Select Module">
              <option [ngValue]="null">{{ 'app.chooseData' | translate }}
              </option>
              <option *ngFor="let moduleGroup of moduleGroupList"
                [ngValue]="moduleGroup.code">
                {{ moduleGroup.name }}
              </option>
            </select>
          </div>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button size="TN" (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}">
            <em class="fas fa-pencil-alt"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>