import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppBadgeModule } from '../../components/app-badge/app-badge.module';
import { AppWidgetContentSliderModule } from '../app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetRfpStatusComponent } from './app-widget-rfp-status.component';
@NgModule({
  imports: [
    VendorModule,
    AppWidgetModule,
    AppWidgetContentSliderModule,
    AppBadgeModule
  ],
  declarations: [AppWidgetRfpStatusComponent],
  entryComponents: [AppWidgetRfpStatusComponent],
  exports: [AppWidgetRfpStatusComponent]
})
export class AppWidgetRfpStatusModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      'app-widget-rfp-status',
      AppWidgetRfpStatusComponent
    );
  }
}
