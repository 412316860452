import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorExperienceHistory } from '../../core/bean/vendor-experience-history';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { AppPopupTabPartnershipComponent } from './app-popup-tab-partnership.component';
@Component({
  templateUrl: './tab-partnership.component.html'
})
export class TabPartnershipComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<Array<object>>;
  public tableResponse: TableResponseModel<VendorExperienceHistory>;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('tab-partnership', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setCustomData();
    this.model.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      history: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'partnership', header: 'table.column.partnership' },
      {
        field: 'countryRegion.name',
        header: 'table.column.country',
        customClass: 'text-center'
      },
      { field: 'partnershipStatus', header: 'table.column.statusPartnership' },
      { field: 'projectOwner', header: 'table.column.projectOwner' },
      {
        field: 'projectType',
        header: 'table.column.projectType',
        customClass: 'text-center'
      },
      {
        field: 'segmentationNames',
        header: 'table.column.segmentation',
        customClass: 'text-center'
      },
      {
        field: 'vendorExperienceStatus.name',
        header: 'table.column.status',
        customClass: 'text-center'
      },
      {
        field: 'contractNumber',
        header: 'table.column.contractNumber',
        customClass: 'text-center'
      },
      {
        field: 'contractDate',
        header: 'table.column.contractDate',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: 'contractValue',
        header: 'table.column.contractValue',
        format: FieldFormatEnum.Currency,
        customClass: 'text-right',
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'file.fileName',
        header: 'table.column.proofOfContract',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName'
      }
    ]);
  }

  public setCustomData(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponse.setCustomData(customData);
  }

  public doDetail(vendorPartnershipHistory: VendorExperienceHistory): void {
    this.appPopupService.open(
      AppPopupTabPartnershipComponent,
      { vendorPartnershipHistory },
      { size: 'lg', backdrop: 'static' }
    );
  }

  public doViewHistory(): void {
    this.global.routerParams.set('selector', 'tab-partnership-history');
    this.global.routerParams.set('componentInstance', {
      id: 1,
      name: 'Terserah'
    });
    this.router.navigate(['/pages/vendor-history/detail/history']);
  }
}
