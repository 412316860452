<app-popup
  header="contract-request.popup.chooseProcurement"
  [isShowHeaderCloseButton]="false"
  [isLoading]="formLoading"
>
  <form
    class="form-horizontal popup-choose-procurement-for-contract"
    [formGroup]="formGroup"
    novalidate
    *ngIf="!formLoading"
  >
    <ng-container>
      <div *ngIf="!formLoading">
        <app-table-xx
          [model]="tableResponseContractPreparation"
          (onClick)="doShowContractPreparationDetail($event)"
          stringUrl="/contract-request/get-procurement-for-contract-list"
          [isShowCheckBox]="true"
          [isMultipleSelect]="false"
        >
        </app-table-xx>
      </div>
    </ng-container>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button
        class="mr-1"
        color="SECONDARY"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button (onClick)="doSave()">{{
        'app.button.add' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
