import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AdminPanelComponent } from './admin-panel.component';
const routes: Routes = [
    { path: '', component: AdminPanelComponent, data: { breadcrumb: '' } }
  ];

@NgModule({
    declarations: [AdminPanelComponent],
    imports: [SharedModule, RouterModule.forChild(routes)]
  })

export class AdminPanelModule {}
