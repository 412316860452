import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupResourceComponent } from './app-popup-resource.component';
import { AppPopupResourceService } from './app-popup-resource.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppTableModule, AppButtonModule],
  declarations: [AppPopupResourceComponent],
  entryComponents: [AppPopupResourceComponent],
  providers: [AppPopupResourceService],
  exports: [AppPopupResourceComponent]
})
export class AppPopupResourceModule {}
