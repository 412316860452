import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Organization } from '../../core/bean/organization';
import { WorkProgram } from '../../core/bean/work-program';
import { AppChooseComponent } from '../../core/components/app-choose/app-choose.component';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './work-program-edit-add.component.html'
})
export class WorkProgramEditAddComponent extends BaseModuleComponent {
  @ViewChild(AppChooseComponent) public appChoose: AppChooseComponent;

  public workProgram: WorkProgram = new WorkProgram();
  public workProgramtId: number;
  public periodList: OptionListModel<any> = new OptionListModel(false);

  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService
  ) {
    super('work-program', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.getAndSetWorkProgramDTO();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.workProgramtId = this.global.routerParams.get('workProgramId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null, Validators.required()],
      name: [null, Validators.required()],
      activityPeriod: [null, Validators.required()],
      organizationName: [null, Validators.required()],
      description: [null]
    });
  }

  public setPeriodList(): void {
    const year = new Date().getFullYear();
    const periodList = [
      { value: year, name: year },
      { value: year + 1, name: year + 1 }
    ];
    this.periodList.setRequestValues(periodList);
  }

  public getAndSetWorkProgramDTO(): void {
    this.httpClientService
      .post<WorkProgram>(
        '/work-program/add-edit',
        new RouteRequestModel(this.todo, this.workProgramtId)
      )
      .subscribe(workProgram => {
        this.workProgram = workProgram;
        this.setPeriodList();
        this.setFormGroup();
        this.setStateReady();
      });
    if (this.todo === 'edit') {
      this.formGroup.get('code').setIsView(true);
    }
  }

  public setFormGroup(): void {
    if (this.workProgram != null) {
      const activityPeriod = this.periodList
        .getRequestValues()
        .filter(period => period.value === this.workProgram.activityPeriod)[0];
      this.formGroup.patchValue({
        id: this.workProgram.id,
        code: this.workProgram.code,
        name: this.workProgram.name,
        activityPeriod,
        description: this.workProgram.description,
        organizationName: this.workProgram.organizationList
      });
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.workProgram = this.global.copyFormAttributeToModel(
              this.workProgram,
              this.formGroup
            );
            const organizationList: Organization[] =
              this.formGroup.get('organizationName').value;
            this.workProgram.organization =
              organizationList[organizationList.length - 1];
            this.workProgram.activityPeriod =
              this.formGroup.get('activityPeriod').value.value;
            const url =
              this.todo === 'edit'
                ? '/work-program/update'
                : '/work-program/insert';
            this.httpClientService
              .post<Response<WorkProgram>>(url, this.workProgram)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/work-program']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/work-program']);
  }

  public doChooseOrganization(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(organization => {
        organization = organization.pop();
        const { name: organizationName } = organization;
        this.formGroup.patchValue({ organization, organizationName });
      });
  }
}
