import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Content } from './../../core/bean/content';
@Component({
  templateUrl: 'content.component.html'
})
export class ContentComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Content>;
  constructor(translateService: TranslateService) {
    super('content', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [{ field: 'moduleCode.name', header: 'table.column.name' }]);
  }

  public doEdit(content: Content): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('contentModule', content.moduleCode.path);
    this.router.navigate([content.moduleCode.path]);
  }
}
