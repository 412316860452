import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { MapResponseModel } from './model/map-response-model';
@Component({
  templateUrl: './app-map-popup.component.html',
  styleUrls: ['./app-map-popup.component.scss']
})
export class AppMapPopupComponent extends BaseComponentComponent {
  @Input() model: MapResponseModel;
  @Output() onChange: EventEmitter<MapResponseModel> = new EventEmitter();

  constructor() {
    super('app-map');
  }

  public onInit(): void {
    this.setStateReady();
  }

  doSave(): void {
    this.onChange.emit(this.model);
  }
}
