<app-layout [isLoading]="formLoading" [isEnableBackButton]="true">
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge-catalog
      class="cml-2"
      *ngIf="procurement.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge class="cml-2" [color]="badgeColor[refutationStatus.code]">
      {{ getTranslateKey(refutationStatus) | translate }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          (procurement?.procurementStatus?.code ===
            global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm
                .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT)
        "
      >
        {{ canceledOrFailedMessage | translate }}
      </app-alert-x>
      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          refutationStatus.code ===
            global.appConstant.pm.REFUTATION_STATUS_CLOSED_RESPONSE
        "
      >
        {{ 'refutation-vendor.alert.msg.refutationPeriodClosed' | translate }}
      </app-alert-x>
      <div
        *ngIf="
          !formLoading &&
          quotation.isReference &&
          procurement.vendorSelectionType.code ===
            global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
        "
      >
        <app-alert-icon color="INFO" icon="TROPHY">
          <div style="display: grid">
            <h2 class="text-info">{{
              'refutation-vendor.info.congratulation' | translate
            }}</h2>
            <span class="mt-3"
              >{{
                'refutation-vendor.info.buyerSelectedYouAsWinner' | translate
              }}
            </span>
          </div>
        </app-alert-icon>
      </div>
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        *ngIf="!formLoading"
        novalidate
      >
        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="workflowStepInfoModel"
        >
        </app-workflow-step-info>
        <app-card
          header="refutation-vendor.procDetail.general.title"
          tips="refutation-vendor.procDetail.general.tips"
        >
          <app-procurement-info [procurementId]="procurement.id">
          </app-procurement-info>
        </app-card>

        <app-item-request
          [objectList]="procurementItemList"
          [isView]="true"
          [isShowPrice]="false"
          [itemTypeList]="itemTypeList"
        >
        </app-item-request>

        <app-card
          header="refutation-vendor.winningVendorAndRefutation.general.title"
          tips="refutation-vendor.winningVendorAndRefutation.general.tips"
        >
          <h4 class="separator mb-4">{{
            'refutation-vendor.winningVendorCandidate.general.title' | translate
          }}</h4>
          <div
            *ngIf="
              !formLoading &&
              !quotation.isReference &&
              procurement.vendorSelectionType.code ===
                global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
            "
          >
            <app-alert-icon color="INFO" icon="HAND_TIMES_CIRCLE">
              <div style="display: grid">
                <h2 class="text-info">{{
                  'refutation-vendor.info.thankYouForYourParticipation'
                    | translate
                }}</h2>
                <span class="mt-3"
                  >{{
                    ('refutation-vendor.info.sorryBuyerHasSelected'
                      | translate) +
                      ' ' +
                      refutationVendorResponse.vendorQuotationViewList[0]
                        .procurementVendor.vendor.name +
                      ' ' +
                      ('refutation-vendor.info.asWinnerThisProcurement'
                        | translate)
                  }}
                </span>
              </div>
            </app-alert-icon>
            <h5 class="separator" style="margin-top: -1rem"></h5>
          </div>

          <div
            *ngIf="
              !formLoading &&
              procurement.vendorSelectionType.code ===
                global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
            "
          >
            <app-table-xx
              [model]="tableResponseFinalPriceEval"
              *ngIf="!formLoading"
              [isShowSearch]="false"
              [isShowPagination]="false"
            >
            </app-table-xx>
          </div>
          <div
            *ngIf="
              !formLoading &&
              procurement.vendorSelectionType.code ===
                global.appConstant.pm.VENDOR_SELECTION_TYPE_MULTI_WINNER
            "
          >
            <app-tab [bordered]="true" *ngIf="!formLoading">
              <app-tab-item
                header="{{ 'refutation-vendor.tab.header.byItem' | translate }}"
              >
                <app-table-xx
                  [model]="tableResponseReferenceByItem"
                  *ngIf="!formLoading"
                  (onClick)="doClickQuotationItem($event)"
                ></app-table-xx>
              </app-tab-item>
              <app-tab-item
                header="{{
                  'refutation-vendor.tab.header.byVendor' | translate
                }}"
              >
                <app-table-xx
                  [model]="tableResponseReferenceByVendor"
                  *ngIf="!formLoading"
                ></app-table-xx>
              </app-tab-item>
            </app-tab>
          </div>

          <div
            *ngIf="
              !quotation.isReference &&
              procurement.vendorSelectionType.code ===
                global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
            "
          >
            <h4 class="separator">{{
              'refutation-vendor.refutationSubmission.general.title' | translate
            }}</h4>
            <div>
              <div *ngIf="!formGroup.isView">
                <h3 class="text-info">{{
                  'refutation-vendor.info.doYouWantToSubmitRefutation'
                    | translate
                }}</h3>
                <p class="my-3 text-secondary" style="font-size: 14px">
                  {{
                    'refutation-vendor.info.refutationSubmitDescription'
                      | translate
                  }}
                </p>
              </div>

              <app-select
                maxHeight="max-content"
                formControlName="isRefute"
                [isMultiple]="false"
                [optionList]="refutationOptionList"
                [isShowBorder]="!formGroup.isView"
                [isShowHover]="false"
                (onChange)="onChangeRefutation($event)"
              >
                <ng-template #content let-data>
                  <div *ngIf="!formGroup.isView">
                    <h4>{{ data.name }}</h4>
                    <p class="my-2 text-secondary" style="font-size: 14px">{{
                      data.description
                    }}</p>
                  </div>
                  <div *ngIf="formGroup.isView">
                    <p>{{ data.name + ', ' + data.description }}</p>
                  </div>
                  <div *ngIf="isShowForm && data.value">
                    <div *ngIf="!formGroup.isView">
                      <h5 class="separator mb-3"></h5>
                      <h5 class="text-info pb-2">{{
                        'refutation-vendor.info.howToSubmitRefutation'
                          | translate
                      }}</h5>
                      <div
                        class="my-2 px-2"
                        style="display: flex; justify-content: space-between"
                      >
                        <div style="display: inline-flex"
                          ><div
                            style="
                              border-radius: 50%;
                              background: var(--alert-info);
                            "
                            ><h5 class="px-2 py-1 text-info">1</h5></div
                          ><span class="ml-2 text-secondary">{{
                            'refutation-vendor.info.fillTheRequirement'
                              | translate
                          }}</span></div
                        >
                        <div style="display: inline-flex"
                          ><div
                            style="
                              border-radius: 50%;
                              background: var(--alert-info);
                            "
                            ><h5 class="px-2 py-1 text-info">2</h5></div
                          ><span class="ml-2 text-secondary">{{
                            'refutation-vendor.info.uploadLegitimateEvidence'
                              | translate
                          }}</span></div
                        >
                        <div style="display: inline-flex"
                          ><div
                            style="
                              border-radius: 50%;
                              background: var(--alert-info);
                            "
                            ><h5 class="px-2 py-1 text-info">3</h5></div
                          ><span class="ml-2 text-secondary"
                            >{{
                              'refutation-vendor.info.checklistTermCondition'
                                | translate
                            }}s</span
                          ></div
                        >
                      </div>
                      <h5 class="separator"></h5>
                      <app-fullfilled></app-fullfilled>
                    </div>

                    <div class="form-group row text-sm-left">
                      <label class="col-sm-3">
                        {{ 'refutation-vendor.form.bondType' | translate }}
                      </label>
                      <div class="col-sm-9 text-left">
                        <app-dropdown-select
                          formControlName="bondType"
                          [optionList]="bondTypeOptionList"
                          placeholder="refutation-vendor.placeholder.chooseBondType"
                        >
                          <ng-template #value let-value>
                            <div *ngIf="value">{{
                              getTranslateKey(value) | translate
                            }}</div>
                          </ng-template>
                          <ng-template #option let-data>
                            {{ getTranslateKey(data) | translate }}
                          </ng-template>
                        </app-dropdown-select>
                      </div>
                    </div>
                    <div class="form-group row text-sm-left">
                      <label class="col-sm-3">
                        {{ 'refutation-vendor.form.bondNumber' | translate }}
                      </label>
                      <div class="col-sm-9 text-left">
                        <app-text-field
                          formControlName="bondNumber"
                          placeholder="{{
                            'refutation-vendor.placeholder.typeNumber'
                              | translate
                          }}"
                          maxLength="32"
                          type="alphanumeric"
                        >
                        </app-text-field>
                      </div>
                    </div>
                    <div class="form-group row text-sm-left">
                      <label class="col-sm-3">
                        {{ 'refutation-vendor.form.bondIssued' | translate }}
                      </label>
                      <div class="col-sm-9 text-left">
                        <app-text-field
                          formControlName="bondIssued"
                          placeholder="{{
                            'refutation-vendor.placeholder.typeInput'
                              | translate
                          }}"
                          maxLength="64"
                          type="alphanumeric"
                        >
                        </app-text-field>
                      </div>
                    </div>
                    <div class="form-group row text-sm-left">
                      <label class="col-sm-3">
                        {{
                          'refutation-vendor.form.validityBondPeriod'
                            | translate
                        }}
                      </label>
                      <div class="col-sm-9 text-left">
                        <app-date-picker-x
                          formControlName="date"
                          [minDate]="today"
                          [range]="true"
                        ></app-date-picker-x>
                      </div>
                    </div>
                    <div class="form-group row text-sm-left">
                      <label class="col-sm-3 text-left">{{
                        'refutation-vendor.form.bondValue' | translate
                      }}</label>
                      <div class="col-sm-9 text-left">
                        <app-currency
                          placeholder="{{
                            'refutation-vendor.placeholder.inputValue'
                              | translate
                          }}"
                          [optionList]="currencyOptionList"
                          formControlName="amount"
                          (onInput)="onInputBondValue($event)"
                        ></app-currency>
                        <p *ngIf="todo !== 'view'" class="mt-1">
                          <small>
                            {{
                              ('refutation-vendor.info.minBidBondValueIs'
                                | translate) +
                                ' ' +
                                (minBidBondValue || 0
                                  | resolveNumberToCurrency) +
                                ' ' +
                                refutationVendorResponse.currencyList[0].code
                            }}
                          </small>
                        </p>
                      </div>
                    </div>
                    <div class="form-group row text-sm-left">
                      <label
                        class="col-sm-3"
                        [ngClass]="!formGroup.isView ? 'optional' : ''"
                      >
                        {{ 'refutation-vendor.form.notes' | translate }}
                      </label>
                      <div class="col-sm-6 text-left">
                        <app-text-area
                          formControlName="note"
                          maxLength="512"
                          size="XL"
                        >
                        </app-text-area>
                      </div>
                    </div>
                    <div class="form-group row text-sm-left">
                      <label class="col-sm-3">
                        {{
                          'refutation-vendor.form.refutationEvidence'
                            | translate
                        }}
                      </label>
                      <div class="col-sm-6 text-left">
                        <app-upload-x
                          formControlName="fileList"
                          [fileUploader]="fileUploader"
                        ></app-upload-x>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #contentBottom let-data>
                  <ng-container
                    *ngIf="!formGroup.isView && isShowForm && data.value"
                  >
                    <h5 class="separator"></h5>
                    <app-alert-x
                      color="WARNING"
                      [isShowCloseButton]="true"
                      [isBold]="false"
                      *ngIf="formGroup.dirty && !formGroup.value.isAccepted"
                      >{{
                        'refutation-vendor.msg.warning.accToProceed' | translate
                      }}
                    </app-alert-x>
                    <div style="position: relative; top: -1rem">
                      <app-check-box
                        formControlName="isAccepted"
                        position="HORIZONTAL"
                        ><span>{{
                          'refutation-vendor.form.confirmation' | translate
                        }}</span>
                        <span>
                          <a
                            (click)="doOpenPopupTOC()"
                            class="ml-1 text-info"
                            style="text-decoration: underline"
                            >{{
                              'refutation-vendor.info.termsAndCondition'
                                | translate
                            }}
                          </a>
                        </span>
                      </app-check-box>
                    </div>
                  </ng-container>
                </ng-template>
              </app-select>
            </div>
          </div>
        </app-card>
        <div class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div
                class="button-group button-group-center cmt-4"
                *ngIf="!formGroup.isView"
              >
                <app-button
                  color="PRIMARY"
                  [outline]="true"
                  (onClick)="doSave()"
                  [disabled]="disabled"
                  >{{ 'app.button.save' | translate }}
                </app-button>
                <app-button (onClick)="doSubmit()" [disabled]="disabled">
                  {{ 'app.button.submit' | translate }}
                </app-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
