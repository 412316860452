<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="fines.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'fines.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              type="alphanumeric"
              autofocus="true"
              formControlName="code"
              maxLength="32"
              type="code"
            ></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'fines.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              type="alphanumeric"
              formControlName="name"
              maxLength="128"
            ></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'fines.form.percentageValue' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              size="TN"
              type="decimal"
              formControlName="percentageValue"
              maxLength="15"
              symbolic="%"
            >
            </app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'fines.form.description' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxLength="512">
            </app-text-area>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button
            color="SECONDARY"
            class="mr-1"
            (onClick)="doBack()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button
            color="PRIMARY"
            (onClick)="doSave()"
            [disabled]="formSaving"
          >
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
