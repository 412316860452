import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  selector: 'app-flag-new',
  templateUrl: './app-flag-new.component.html',
  styleUrls: ['./app-flag-new.component.scss']
})
export class AppFlagNewComponent extends BaseComponentComponent {
  @Input() private oldValue: any;
  @Input() private newValue: any;
  @Input() private hoverValue: any;

  public isNew = false;
  public titleValue: string | number;

  constructor() {
    super('app-flag-new');
  }

  public onInit(): void {
    this.setIsNew();
  }

  private setIsNew(): void {
    this.isNew = this.newValue && this.oldValue !== this.newValue;
    this.titleValue = this.hoverValue
      ? this.hoverValue
      : this.oldValue
      ? this.oldValue
      : '-';
  }
}
