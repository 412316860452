import { createTotalSelectedDate } from '../helper/create-total-selected-date-helper';
import { DatePickerDate, DatePickerState } from '../model';
export const setDatePickerOnRange = (
  state: DatePickerState,
  payload: { datePickerDate: DatePickerDate }
): void => {
  if (
    state.model.range &&
    state.currentDateRange.start &&
    !(state.currentDateRange.start && state.currentDateRange.end)
  ) {
    state.currentDateRange.on = payload.datePickerDate;
    state.currentDateRange.totalSelectedDate = createTotalSelectedDate(
      state.currentDateRange.start,
      state.currentDateRange.on
    );
  }
};
