import { CroppedPoints } from '../interface/cropped-points';
import { Uploader } from '../model/uploader';
import { UploaderFile } from '../model/uploader-file';
import { UploaderFileError } from '../model/uploader-file-error';
import { createUploaderFileSrc } from './create-uploader-file-src.action';
import { createUploaderFile } from './create-uploader-file.action';
import { validateMaxUploadFile } from './validate-uploader-file.action';
export function addFileToUploaderFileList(
  state: Uploader,
  payload: {
    fileList: FileList;
    croppedPoints?: CroppedPoints;
  }
): void {
  if (payload && payload.fileList.length > 0) {
    const uploaderWithError = validateMaxUploadFile(state, payload.fileList);
    const uploaderFileList: UploaderFile[] = [];
    let uploaderFileError: UploaderFileError;
    if (!uploaderWithError.error) {
      Object.values(payload.fileList).forEach((file: File) => {
        if (!uploaderFileError) {
          const uploaderFile: UploaderFile = createUploaderFileSrc(
            createUploaderFile(state, file)
          );
          uploaderFile.croppedPoints = payload.croppedPoints;
          uploaderFile.isNew = true;
          uploaderFileList.push(uploaderFile);
          uploaderFileError = uploaderFile.error;
        }
      });
    }
    uploaderFileError
      ? state.setError(uploaderFileError)
      : state.uploaderFileList.push(...uploaderFileList);
  }
}
