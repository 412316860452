<app-card header="view-catalog.title" [isLoading]="formLoading">
  <app-fieldset legend="Filter">
    <form [formGroup]="formGroup">
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{ 'view-catalog.catalogName' |
          translate }}</label>
        <div class="col-sm-9 text-left">
          <app-auto-complete formControlName="catalog"
            stringUrl="/view-catalog/get-catalog-list"></app-auto-complete>
        </div>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{ 'view-catalog.vendorName' |
          translate }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field formControlName="vendorName"></app-text-field>
        </div>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{ 'view-catalog.category' |
          translate }}</label>
        <div class="col-sm-9 text-left">
          <app-chosen formControlName="categoryList"
            [optionList]="categoryOptionList"></app-chosen>
        </div>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{ 'view-catalog.attributeGroup' |
          translate }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box formControlName="catalogAttributeGroup"
            [optionList]="catalogAttributeGroupOptionList"></app-combo-box>
        </div>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{ 'view-catalog.location' |
          translate }}</label>
        <div class="col-sm-9 text-left">
          <app-text-tree formControlName="regionList" stringUrl="/region"
            [isMultiple]="true"
            header="{{ 'view-catalog.location' | translate }}"
            isLazy="true"
            ></app-text-tree>
        </div>
      </div>
      <div class="button-group button-group-center mt-5 mb-4">
        <app-button mode="BYPASS" size="SM" (onClick)="doReset()"
          color="SECONDARY">{{ 'app.button.reset' | translate }}</app-button>
        <app-button mode="BYPASS" size="SM" (onClick)="doApply()">{{
          'app.button.apply' | translate }}</app-button>
      </div>
    </form>
  </app-fieldset>
</app-card>