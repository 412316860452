import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorLocationEditAddComponent } from './vendor-location-edit-add.component';
import { VendorLocationComponent } from './vendor-location.component';

export const routes = [
  { path: '', component: VendorLocationComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: VendorLocationEditAddComponent,
    data: { breadcrumb: 'vendor-location.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: VendorLocationEditAddComponent,
    data: { breadcrumb: 'vendor-location.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [VendorLocationComponent, VendorLocationEditAddComponent],
  providers: []
})
export class VendorLocationModule {}
