import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetContentSliderModule } from '../app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetVendorStatusInformationComponent } from './app-widget-vendor-status-information.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule, AppWidgetContentSliderModule],
  declarations: [AppWidgetVendorStatusInformationComponent],
  entryComponents: [AppWidgetVendorStatusInformationComponent],
  exports: [AppWidgetVendorStatusInformationComponent]
})
export class AppWidgetVendorStatusInformationModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-vendor-status-information', AppWidgetVendorStatusInformationComponent);
  }
}
