<div class="row app-accordion-row">
  <div class="col-12">
    <app-card
      header="{{ vendorType.name }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <div class="row font-weight-bold">
        <div class="col-sm-10">
          <div class="row mx-1">
            <div class="col-3">{{
              'vendor-attribute.form.fieldName' | translate
            }}</div>
            <div class="col-3 text-center">{{
              'vendor-attribute.form.mandatory' | translate
            }}</div>
            <div class="col-3 text-center">{{
              'vendor-attribute.form.approval' | translate
            }}</div>
            <div class="col-3 text-center">{{
              'vendor-attribute.form.certification' | translate
            }}</div>
          </div>
        </div>
      </div>
      <form [formGroup]="formGroup">
        <app-accordion formArrayName="generated">
          <app-accordion-item
            *ngFor="
              let item of getFormArray(formGroup, 'generated').controls;
              let i = index
            "
            [formGroup]="item"
            [header]="
              'dynamic-attribute.' + item.controls.translationKey.value.key
            "
          >
            <ng-container formArrayName="vendorAttributeGroupList">
              <div class="row">
                <div class="col-sm-10">
                  <ng-container
                    *ngFor="
                      let childrenKey of getFormArray(
                        item,
                        'vendorAttributeGroupList'
                      ).controls;
                      let i = index
                    "
                    [formGroup]="childrenKey"
                  >
                    <h6 class="separator">
                      {{
                        (childrenKey.controls.translationKey.value.module.code
                          | lowercase) +
                          '.' +
                          childrenKey.controls.translationKey.value.key
                          | translate
                      }}
                    </h6>
                    <ng-container formArrayName="vendorAttributeList">
                      <div
                        class="row py-2"
                        *ngFor="
                          let childerChildKey of getFormArray(
                            childrenKey,
                            'vendorAttributeList'
                          ).controls;
                          let i = index
                        "
                        [formGroupName]="i"
                      >
                        <div class="col-3">
                          {{
                            (childerChildKey.value.attribute.translationKey
                              .module.code | lowercase) +
                              '.' +
                              childerChildKey.value.attribute.translationKey.key
                              | translate
                          }}
                        </div>
                        <div class="col-3 text-center">
                          <app-check-box
                            formControlName="isMandatory"
                            [isDisabled]="
                              childerChildKey.value.attribute
                                .isEditableMandatory
                            "
                          >
                          </app-check-box>
                        </div>
                        <div class="col-3 text-center">
                          <app-check-box
                            formControlName="isApproval"
                            [isDisabled]="
                              childerChildKey.value.attribute.isEditableApproval
                            "
                          >
                          </app-check-box>
                        </div>
                        <div class="col-3 text-center">
                          <app-check-box formControlName="isCertificate">
                          </app-check-box>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </app-accordion-item>
        </app-accordion>
        <app-accordion formArrayName="additionalInformation">
          <app-accordion-item
            header="vendor-attribute.form.additionalAttribute"
          >
            <div class="row">
              <div class="col-12 text-right mb-3">
                <app-button color="PRIMARY" (onClick)="doChoose()"
                  ><em class="fas fa-plus"></em>
                  {{ 'app.button.add' | translate }}</app-button
                >
              </div>
            </div>
            <div
              class="row py-1"
              *ngFor="let item of additionalInformation.controls; let i = index"
              [formGroupName]="i"
            >
              <div class="col-sm-10">
                <div
                  class="row"
                  *ngIf="item != null && item.value.crudOperation !== 0"
                >
                  <div class="col-3">
                    {{
                      (item.value.attribute.translationKey.module.code
                        | lowercase) +
                        '.' +
                        item.value.attribute.translationKey.key | translate
                    }}
                  </div>
                  <div class="col-3 text-center">
                    <app-check-box
                      formControlName="isMandatory"
                      (onChange)="changeIsMandatory(i)"
                    ></app-check-box>
                  </div>
                  <div class="col-3 text-center">
                    <app-check-box
                      formControlName="isApproval"
                      (onChange)="changeIsApproval(i)"
                    ></app-check-box>
                  </div>
                  <div class="col-3 text-center">
                    <app-check-box
                      formControlName="isCertificate"
                      (onChange)="changeIsCertificate(i)"
                    >
                    </app-check-box>
                  </div>
                </div>
              </div>
              <div class="col-sm-2 text-right">
                <app-button
                  size="TN"
                  color="DANGER"
                  (onClick)="doDelete(additionalInformation, i)"
                  *ngIf="item != null && item.value.crudOperation !== 0"
                >
                  <em class="fas fa-trash"></em>
                </app-button>
              </div>
            </div>
          </app-accordion-item>
        </app-accordion>
      </form>
      <div class="button-group button-group-center mt-5">
        <div class="col-12 text-center">
          <app-button color="SECONDARY" (onClick)="doCancel()">{{
            'app.button.back' | translate
          }}</app-button>
          <app-button (onClick)="doSubmit()"
            >{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </div>
    </app-card>
  </div>
</div>
