<app-popup header="app-popup-vendor.title">
  <app-table
    [tableResponse]="tableResponse"
    [isServerSide]="true"
    stringUrl="/app-popup-vendor/index"
    customData="{{ vendorCustomData }}"
  >
    <ng-template #actionButtons let-rowData>
      <app-button
        (onClick)="doChoose(rowData)"
      > {{ 'app.button.select' | translate }}
      </app-button>
    </ng-template>
  </app-table>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
        'app.button.close' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
