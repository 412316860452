<app-popup
  header="app-deliverable-item.popup.deliveryProcess"
  [isShowHeaderCloseButton]="true"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row">
      <label class="col-sm-3">{{
        'app-deliverable-item.form.itemName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ sowPaymentTermItem.prItemReleased.prItem?.item?.name }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3">{{
        'app-deliverable-item.form.totalReceived' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          sowPaymentTermItem.prItemReleased.prItem.item.itemType.code ===
          global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
            ? (paymentBillingItem.passQuantity | resolveNumberToDecimal) +
              ' ' +
              sowPaymentTermItem.prItemReleased.prItem.item.uom.name
            : (paymentBillingItem.passQuantity | resolveNumberToDecimal) + ' %'
        }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3">{{
        'app-deliverable-item.form.remaining' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          sowPaymentTermItem.prItemReleased.prItem.item.itemType.code ===
          global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
            ? (sowPaymentTermItem.remainingQuantity | resolveNumberToDecimal) +
              ' ' +
              sowPaymentTermItem.prItemReleased.prItem.item.uom.name
            : (paymentBillingItem.remainingQuantity | resolveNumberToDecimal) +
              ' %'
        }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3">{{
        'app-deliverable-item.form.stageName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ stageOfWork?.name }}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3">{{
        'app-deliverable-item.form.term' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ paymentTerm?.name }}
      </div>
    </div>
    <h3 class="separator">{{
      'app-deliverable-item.label.historyLog' | translate
    }}</h3>
    <app-table-xx
      *ngIf="
        sowPaymentTermItem.prItemReleased.prItem.item.itemType.code ===
        global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      "
      [model]="tableResponseGoods"
      stringUrl="/app-deliverable-item/get-delivery-item-history-list"
    >
    </app-table-xx>
    <app-table-xx
      *ngIf="
        sowPaymentTermItem.prItemReleased.prItem.item.itemType.code !==
        global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      "
      [model]="tableResponseService"
      stringUrl="/app-deliverable-item/get-delivery-item-history-list"
    >
    </app-table-xx>
  </form>
</app-popup>
