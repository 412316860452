<dashboard-widget-card
  size="MD"
  header="dashboard-widget-best-saving-by-buyer.title"
  [isLoading]="model.isLoading"
>
  <app-table-xx
    [model]="tableResponse"
    [isShowSearch]="false"
    customClass="promise-table-best-saving-by-buyer p-0"
    [isShowPagination]="false"
    theme="NONE"
  ></app-table-xx>
</dashboard-widget-card>
