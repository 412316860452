import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupBillingMemoBudgetDetailComponent } from './app-popup-billing-memo-budget-detail.component';

@NgModule({
  imports: [VendorModule, AppPopupModule, AppButtonModule],
  declarations: [AppPopupBillingMemoBudgetDetailComponent],
  entryComponents: [AppPopupBillingMemoBudgetDetailComponent],
  exports: [AppPopupBillingMemoBudgetDetailComponent]
})
export class AppPopupBillingMemoBudgetDetailModule {}
