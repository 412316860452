import { Component, ElementRef, Optional } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
@Component({
  selector: 'app-tagging',
  templateUrl: './app-tagging.component.html',
  styleUrls: ['./app-tagging.component.scss'],
  providers: makeProvider(AppTaggingComponent)
})
export class AppTaggingComponent extends BaseValueAccessorComponent<any> {
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super('app-tagging', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setStateReady();
  }

  doDeleteItem(event: any, index: number): void {
    if (!this.ISVIEW) {
      event.preventDefault();
      event.stopPropagation();

      const value: Array<any> = Array.from(this.formControl.value || []);
      this.onChange.emit(value[index]);
      value.splice(index, 1);
      this.formControl.patchValue(value.length > 0 ? value : null);
      this.formControl.markAsDirty();
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
