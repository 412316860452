<app-layout
  [isLoading]="formLoading"
  [backTo]="urlBackOutside"
  header="aanwijzing.breadcrumb.index"
>
  <ng-template #additionalTitle *ngIf="!formLoading && isShowBadgeStatus">
    <app-badge-catalog
      class="cml-2"
      *ngIf="response.procurement.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge
      class="cml-2"
      [color]="badgeColor[response.procurement.procurementWorklistStatus.code]"
    >
      {{
        getTranslateKey(response.procurement.procurementWorklistStatus)
          | translate
      }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12">
      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          response.procurement?.procurementStatus?.code ===
            global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
        "
      >
        {{ 'aanwijzing.alert.msg.sorryWaitingCancelationApproval' | translate }}
      </app-alert-x>
      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          [
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
            this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
          ].includes(this.response.procurement?.procurementStatus?.code)
        "
      >
        {{ 'aanwijzing.alert.msg.sorryWaitingFailedApproval' | translate }}
      </app-alert-x>
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-workflow-step-info
          *ngIf="!formLoading"
          [isLoading]="formLoading"
          [model]="response.workflowStepInfoModel"
        >
        </app-workflow-step-info>

        <app-card
          header="aanwijzing.procurementDetails.title"
          tips="aanwijzing.procurementDetails.tips"
        >
          <app-procurement-info [procurementId]="procurementId">
          </app-procurement-info>
        </app-card>

        <app-item-request
          *ngIf="!formLoading"
          [objectList]="response.procurementItemList"
          [isShowPrice]="isRoleBuyer()"
          [isView]="true"
          [itemTypeList]="response.itemTypeList"
        ></app-item-request>

        <app-card>
          <app-table-xx
            [model]="tableResponse"
            *ngIf="!formLoading"
            (onClick)="onClick($event)"
            header="aanwijzing.aanwijzing.title"
            tips="aanwijzing.aanwijzing.tips"
          >
            <ng-template #headerButtons>
              <app-button
                *ngIf="!formGroup.isView && isRoleBuyer() && !isSubmitted"
                (onClick)="doCreateSession()"
                [disabled]="
                  tableResponse.getRecordList()[0] &&
                  noNewSessionStatusList.includes(
                    tableResponse.getRecordList()[0].aanwijzingStatus?.code
                  )
                "
              >
                {{ 'aanwijzing.button.createNewSession' | translate }}
              </app-button>
            </ng-template>
            <ng-template
              #alertWrapper
              *ngIf="
                (isRoleBuyer() &&
                  tableResponse.getRecordList()?.length === 0) ||
                (isRoleBuyer() &&
                  tableResponse.getRecordList()?.length > 0 &&
                  !isSubmitted)
              "
            >
              <app-alert-x
                color="INFO"
                *ngIf="
                  isRoleBuyer() && tableResponse.getRecordList()?.length === 0
                "
              >
                {{ 'aanwijzing.alert.msg.createMinimumOneSession' | translate }}
              </app-alert-x>
              <app-alert-x
                color="WARNING"
                *ngIf="
                  isRoleBuyer() &&
                  tableResponse.getRecordList()?.length > 0 &&
                  !isSubmitted
                "
              >
                {{
                  'aanwijzing.alert.msg.allSessionStatusShouldBeDoneBeforeSubmitted'
                    | translate
                }}
              </app-alert-x>
            </ng-template>

            <ng-template #noDataTemplate>
              <div class="ndtw-icon">
                <div class="pvc pv-comment-dots"></div>
              </div>
              <div class="ndtw-content">
                <span class="ndtwc-text">
                  {{ 'aanwijzing.noSessionAvailable' | translate }}
                </span>
                <span class="ndtwc-text">
                  {{ 'aanwijzing.letsTryToCreateOne' | translate }}
                </span>
              </div>
            </ng-template>

            <ng-template #actionButtons let-rowData *ngIf="isRoleBuyer()">
              <app-button
                size="TN"
                (onClick)="doDeleteSession(rowData)"
                title="{{ 'app.tooltip.delete' | translate }}"
              >
                <em class="pir pi-trash-alt"></em>
                {{ 'app.button.delete' | translate }}
              </app-button>
            </ng-template>
          </app-table-xx>
        </app-card>
      </form>

      <app-approval-monitoring
        *ngIf="
          !formLoading &&
          isRoleBuyer() &&
          response.approvalPathResponseModel &&
          !response.isUserApproval &&
          isSubmitted
        "
        [workflowPrcs]="procurement.workflowPrcs"
        [moduleCode]="moduleCode"
        [approvalPathResponseModel]="response?.approvalPathResponseModel"
      ></app-approval-monitoring>

      <div
        class="button-group button-group-center"
        *ngIf="!formGroup.isView && isRoleBuyer() && !isSubmitted"
      >
        <app-button
          (onClick)="doSubmit()"
          [disabled]="this.tableResponse.getRecordList().length === 0"
        >
          {{ 'app.button.submit' | translate }}</app-button
        >
      </div>

      <app-approval-prcs-x
        *ngIf="response.isUserApproval"
        [workflowModelPrcs]="response.procurement.workflowModelPrcs"
        [approvalModelPrcsId]="approvalModelPrcsId"
      >
      </app-approval-prcs-x>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips activeTipHeader="aanwijzing.aanwijzing.title"></app-tips>
  </ng-template>
</app-layout>
