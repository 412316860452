import { NgModule } from '@angular/core';
import { AppRatingModule } from '../../../../../core/components/app-rating/app-rating.module';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetPerformanceOverallComponent } from './widget-performance-overall.component';

@NgModule({
  imports: [VendorModule, WidgetCardModule, AppRatingModule],
  declarations: [WidgetPerformanceOverallComponent],
  entryComponents: [WidgetPerformanceOverallComponent],
  exports: [WidgetPerformanceOverallComponent]
})
export class WidgetPerformanceOverallModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetPerformanceOverallComponent.moduleCode,
      WidgetPerformanceOverallComponent,
      'w-25'
    );
  }
}
