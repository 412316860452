<span
  class="badge"
  [ngClass]="[
    badgeClassName,
    pill ? 'badge-pill' : '',
    customClass ? customClass : ''
  ]"
>
  <ng-container *ngIf="text">
    {{ text | translate }}
  </ng-container>
  <ng-container *ngIf="!text">
    <ng-content></ng-content>
  </ng-container>
</span>
