import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Contract } from 'src/app/core/bean/contract';
import { AddressPic } from '../../core/bean/address-pic';
import { ContractDoc } from '../../core/bean/contract-doc';
import { ContractShipping } from '../../core/bean/contract-shipping';
import { ContractStatus } from '../../core/bean/contract-status';
import { PrItemReleased } from '../../core/bean/pr-item-released';
import { AppPopupVersionHistoryModel } from '../../core/components/app-popup/app-popup-version-history/app-popup-version-history-model';
import { AppPopupVersionHistoryService } from '../../core/components/app-popup/app-popup-version-history/app-popup-version-history.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FileUploader } from '../../core/components/upload/model';
import { OptionListModel } from '../../core/model/option-list-model';
import { AppPopupContractRequestSplitItemComponent } from '../contract-request/app-popup-contract-request-split-item.component';
import { ContractHistoryDetailResponse } from './contract-history-detail-response';
import { ContractHistoryPopupCloseContractComponent } from './contract-history-popup-close-contract.component';
import { ContractHistoryPopupGuaranteeComponent } from './contract-history-popup-guarantee.component';
import { ContractHistoryPopupSendReminderComponent } from './contract-history-popup-send-reminder.component';

@Component({
  templateUrl: './contract-history-detail.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None
})
export class ContractHistoryDetailComponent
  extends BaseModuleComponent
  implements OnInit
{
  public contractId: number;
  public urlBack: string;
  public header: string;
  public token: string;
  public contract: Contract = new Contract();
  public contractDoc: ContractDoc;
  public contractStatus: ContractStatus;
  public contractHistoryDetailResponse: ContractHistoryDetailResponse;
  public model: AppPopupVersionHistoryModel;
  public modelList: AppPopupVersionHistoryModel[] = [];
  public prItemReleasedList: PrItemReleased[] = [];
  public contractShippingOptionList: OptionListModel<ContractShipping> =
    new OptionListModel(true, 'address.address');

  public fileUploader: FileUploader;
  public fileUploaderCancel: FileUploader = new FileUploader(
    '/contract-history/',
    'contract-history.detail.form.fileCancel',
    this.global.appConstant.fileType.DOC_CONTRACT_CANCELED,
    false,
    5
  );

  public badgeColor = {
    DONE: 'SUCCESS',
    ON_PROGRESS: 'PROGRESS',
    CANCELED: 'DANGER',
    CLOSED: 'DANGER',
    EXPIRED: 'DANGER'
  };

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupVersionHistryService: AppPopupVersionHistoryService,
    public activatedRoute: ActivatedRoute
  ) {
    super('contract-history', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Contract>('/contract-history/notification/' + this.token)
      .subscribe((contract: Contract) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('contractId', contract.id);
        this.global.routerParams.set(
          'contractWorklistStatus',
          contract.contractWorklistStatus
        );
        this.global.routerParams.set(
          'urlBackOutside',
          '/pages/contract-history'
        );
        if (
          contract.contractStatus.code ===
            this.global.appConstant.cm.CONTRACT_STATUS_TERMINATED ||
          contract.contractStatus.code ===
            this.global.appConstant.cm.CONTRACT_STATUS_WAITING_FOR_TERMINATION
        ) {
          this.global.routerParams.set('todo', 'monitoring');
          this.router.navigate(['/pages/contract-termination/detail']);
        } else {
          const todo =
            this.global.userSession.activeUserRole.role.type !==
              this.global.appConstant.core.ROLE_TYPE_USER &&
            contract.contractStatus.code !==
              this.global.appConstant.cm.CONTRACT_STATUS_EXPIRED &&
            contract.contractStatus.code !==
              this.global.appConstant.cm.CONTRACT_STATUS_CANCEL
              ? 'edit'
              : 'view';
          this.global.routerParams.set('todo', todo);
          let header = 'contract-history.detail.contractHistory.header';
          if (
            contract.contractStatus.code ===
              this.global.appConstant.cm.CONTRACT_STATUS_CLOSED ||
            contract.contractStatus.code ===
              this.global.appConstant.cm.CONTRACT_STATUS_CANCEL
          ) {
            header = 'contract-history.detail.contractClosure.header';
          }
          this.global.routerParams.set('header', header);
          this.router.navigate(['/pages/contract-history/detail']);
        }
      });
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.getContract();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.contractId = this.global.routerParams.get('contractId');
    this.urlBack = this.global.routerParams.get('urlBackOutside');
    this.header = this.global.routerParams.get('header');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      file: [null],
      fileCancel: [null],
      contractShipping: [null]
    });
  }

  public getContract(): void {
    this.httpClientService
      .get<ContractHistoryDetailResponse>(
        '/contract-history/detail/' + this.contractId
      )
      .subscribe((response: ContractHistoryDetailResponse) => {
        this.contractHistoryDetailResponse = response;
        this.contract = response.contract;
        this.prItemReleasedList = response.contractDetailList.map(
          detail => detail.prItemReleased
        );
        this.contractShippingOptionList.setRequestValues(
          this.contractHistoryDetailResponse.contractShippingList
        );
        this.fileUploader = new FileUploader(
          '/contract-history/',
          'contract-history.detail.form.file',
          this.global.appConstant.fileType.DOC_CLOSED_CONTRACT,
          false,
          this.contractHistoryDetailResponse.maxFileCloseContract
        );
        if (this.contractHistoryDetailResponse.contractClosedDocList) {
          const fileList =
            this.contractHistoryDetailResponse.contractClosedDocList.map(
              closedDoc => closedDoc.file
            );
          this.fileUploader.setFileList(fileList);
        }
        if (this.contractHistoryDetailResponse.contractCancelationDocList) {
          const fileList =
            this.contractHistoryDetailResponse.contractCancelationDocList.map(
              cancelDoc => cancelDoc.file
            );
          this.fileUploaderCancel.setFileList(fileList);
        }
        this.formGroup.patchValue({
          date: {
            from: this.contract.startDate,
            to: this.contract.endDate
          },
          file: this.fileUploader.fileObjectList,
          fileCancel: this.fileUploaderCancel.fileObjectList
        });
        this.formGroup.setIsView(true);
        this.contractStatus = this.contract.contractStatus;
        this.contractDoc = response.contractDoc;
        this.model = response.versionHistoryModelList[0];
        this.modelList = response.versionHistoryModelList;
        if (!this.contractDoc) {
          this.contractDoc = new ContractDoc();
          this.contractDoc.contract = this.contract;
        }
        this.setStateReady();
      });
  }

  public doSendReminderContract(): void {
    this.appPopupService
      .open(ContractHistoryPopupSendReminderComponent, {
        contract: this.contract,
        defaultContractReminderList:
          this.contractHistoryDetailResponse.defaultUserReminderList,
        userReminderMap: this.contractHistoryDetailResponse.userReminderMap
      })
      .subscribe(result => {
        console.log(result);
      });
  }

  public doSendReminderGuarantee(): void {
    if (this.contractHistoryDetailResponse.guaranteeList?.length > 1) {
      this.appPopupService
        .open(ContractHistoryPopupGuaranteeComponent, {
          guaranteeList: this.contractHistoryDetailResponse.guaranteeList
        })
        .subscribe(guarantee => {
          this.appPopupService
            .open(ContractHistoryPopupSendReminderComponent, {
              contract: this.contract,
              guarantee,
              defaultContractReminderList:
                this.contractHistoryDetailResponse.defaultUserReminderList,
              userReminderMap:
                this.contractHistoryDetailResponse.userReminderMap
            })
            .subscribe(result => {
              console.log(result);
            });
        });
    } else {
      const guarantee = this.contractHistoryDetailResponse.guaranteeList[0];
      this.appPopupService
        .open(ContractHistoryPopupSendReminderComponent, {
          contract: this.contract,
          guarantee,
          defaultContractReminderList:
            this.contractHistoryDetailResponse.defaultUserReminderList,
          userReminderMap: this.contractHistoryDetailResponse.userReminderMap
        })
        .subscribe(result => {
          console.log(result);
        });
    }
  }

  public doViewItem(contractShip: ContractShipping): void {
    const contractShippingDetailList =
      this.contractHistoryDetailResponse.contractShippingDetailList.filter(
        csi => csi.contractShipping.id === contractShip.id
      );
    const address = contractShip.address;
    const isView = this.formGroup.isView;

    this.appPopupService.open(
      AppPopupContractRequestSplitItemComponent,
      {
        address,
        contractShippingDetailList,
        isView,
        allCShipItemList:
          this.contractHistoryDetailResponse.contractShippingDetailList
      },
      { size: 'xl' }
    );
  }

  public doViewDetailDR(): void {
    localStorage.setItem('drId', this.contract.dr.id.toString());
    localStorage.setItem('objectId', this.contract.dr.id.toString());
    localStorage.setItem('todo', 'view');
    localStorage.setItem('urlBackOutside', 'close-window');
    window.open('/pages/dr/detail');
  }

  public doClickVersionHistory(): void {
    this.appPopupVersionHistryService.open(this.modelList);
  }

  public onChangeContractDoc(contractDoc: ContractDoc): void {
    console.log(contractDoc);
    this.contractDoc = contractDoc;
  }

  public getUserNameList(addressPicList: AddressPic[]): string {
    return addressPicList.map(a => a.user.name).join(', ');
  }

  public doClose(): void {
    if (this.contractDoc?.id) {
      this.appPopupService
        .open(ContractHistoryPopupCloseContractComponent, {
          contract: this.contract,
          maxFile: this.contractHistoryDetailResponse.maxFileCloseContract
        })
        .subscribe(result => {
          console.log(result);
        });
    }
  }
}
