<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="due-diligence-template.title"
            detail="app.action.{{todo}}" [isLoading]="formLoading"
            [isSaving]="formSaving">
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>
                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        'due-diligence-template.form.code' | translate
                        }}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-field autofocus="true" size="SM"
                            formControlName="code"
                            tooltip="{{ 'due-diligence-template.tooltip.code' | translate }}"
                            type="code" maxLength="32">
                        </app-text-field>
                    </div>
                </div>

                <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        'due-diligence-template.form.name' | translate
                        }}</label>
                    <div class="col-sm-9 text-left">
                        <app-text-field formControlName="name" maxLength="32">
                        </app-text-field>
                    </div>
                </div>

                <div *ngIf="dueDiligenceList.length > 0"
                    class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                        'due-diligence-template.form.dueDiligence' | translate
                        }}</label>
                    <div class=" col-sm-9 text-left ">
                        <app-check-box position="HORIZONTAL"
                            formControlName="dueDiligence"></app-check-box>
                    </div>
                </div>

                <div
                    *ngIf="formGroup.get('dueDiligence').value && formGroup.get('dueDiligence').value[0].isChecked">
                    <app-pick-list [model]="attributePickListModel"
                        searchSourcePlaceholder="due-diligence-template.appPickList.attributePlaceHolder"
                        searchTargetPlaceholder="due-diligence-template.appPickList.attributePlaceHolder"
                        sourceTitle="due-diligence-template.appPickList.attributeSourceTitle"
                        targetTitle="due-diligence-template.appPickList.attributeTargetTitle">
                        <!-- <ng-template #source let-attribute>
                            <div style="font-size:14px; float:left; margin: 0; padding-right: 2rem;">
                                <span style="display:inline-block;margin:2px 0 2px 2px" width="30"></span>
                                {{attribute.description}}
                            </div>
                        </ng-template>
                        <ng-template #target let-attribute>
                            <div style="font-size:14px; float:left; margin: 0; padding-right: 2rem;">
                                <span style="display:inline-block;margin:2px 0 2px 2px" width="30"></span>
                                {{attribute.description}}
                            </div>
                        </ng-template> -->
                    </app-pick-list>
                </div>

                <br>

                <div
                    *ngIf="formGroup.get('dueDiligence').value && formGroup.get('dueDiligence').value[1].isChecked">
                    <app-pick-list [model]="questionPickListModel"
                        searchSourcePlaceholder="due-diligence-template.appPickList.questionPlaceHolder"
                        searchTargetPlaceholder="due-diligence-template.appPickList.questionPlaceHolder"
                        sourceTitle="due-diligence-template.appPickList.questionSourceTitle"
                        targetTitle="due-diligence-template.appPickList.questionTargetTitle">
                        <!-- <ng-template #item let-question>
                            <div style="font-size:14px; float:left; margin: 0; padding-right: 2rem;">
                                <span style="display:inline-block;margin:2px 0 2px 2px" width="30"></span>
                                {{question.name}}
                            </div>
                        </ng-template> -->
                    </app-pick-list>
                </div>

                <div class=" row justify-content-center mt-5">
                    <app-button color="SECONDARY" class="mr-1"
                        (onClick)="doCancel()" [disabled]="!isEnabled">
                        {{ 'app.button.back' | translate}}
                    </app-button>
                    <app-button color="PRIMARY" (onClick)="doSave()"
                        [disabled]="!isEnabled">
                        {{ 'app.button.save' | translate}}
                    </app-button>
                </div>
            </form>
        </app-card>
    </div>
</div>