import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ConfirmGuard } from "src/app/core/guard/confirm.guard";
import { SharedModule } from "src/app/core/shared/shared.module";
import { ClaimVerificationEditAddXtComponent } from "./claim-verification-edit-add-xt.component";
import { ClaimVerificationComponent } from "./claim-verification.component";

const routes: Routes = [
    { path: '', component: ClaimVerificationComponent, data: { breadcrumb: '' } },
    {
      path: 'detail',
      component: ClaimVerificationEditAddXtComponent,
      data: { breadcrumb: 'claim-verification.breadcrumb.detail' },
      canDeactivate: [ConfirmGuard]
    },
    {
      path: 'detail/:token',
      component: ClaimVerificationEditAddXtComponent,
      data: { breadcrumb: 'claim-verification.breadcrumb.detail' },
      canDeactivate: [ConfirmGuard]
    },
    {
      path: 'detail/tab/:uuid',
      component: ClaimVerificationEditAddXtComponent,
      data: { breadcrumb: 'claim-verification.breadcrumb.detail' },
      canDeactivate: [ConfirmGuard]
    }
    
    
  ];
  @NgModule({
    imports: [
      SharedModule,
      RouterModule.forChild(routes)
    ],
    declarations: [
      ClaimVerificationComponent,
      ClaimVerificationEditAddXtComponent
    ],
    providers: []
  })
  export class ClaimVerificationModule {}