import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { File } from '../bean/file';
import { VendorHistory } from '../bean/vendor-history';

export class VendorReactivationEntity extends BaseWorkflowObjectEntity {
    vendorHistory: VendorHistory;
    file: File;
    reason: string;
    sequence: number;
}
