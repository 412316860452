<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="period.title" detail="app.action.{{ todo }}"
      [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'period.form.code' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field autofocus="true"
              tooltip="{{ 'period.tooltip.code' | translate }}"
              formControlName="code" type="code" maxLength="32">
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'period.form.name' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="name" type="alphanumeric"
              maxLength="32"></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'period.form.date' |
            translate }}</label>
          <div class="col-sm-9 text-left d-flex">
            <div class="col-2 p-0">
              <app-month-picker size="XL" formControlName="startMonth"
                maxMonth="startMonth"></app-month-picker>
            </div>
            <span class="col-1 p-0 text-center">{{ 'period.form.to' | translate
              }}</span>
            <div class="col-2 p-0">
              <app-month-picker size="XL" formControlName="endMonth"
                minMonth="startMonth"></app-month-picker>
            </div>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{ 'period.form.description' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxLength="512">
            </app-text-area>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button color="SECONDARY" (onClick)="doCancel()"
            [disabled]="formSaving">{{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()">{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>