<app-popup
  [isLoading]="formLoading"
  [header]="header"
  [isShowHeaderCloseButton]="false"
>
  <form [formGroup]="formGroup">
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{
          'compliance-test-master.popup.form.complianceGroupName' | translate
        }}
      </label>
      <div class="col-sm-9 text-left">
        <app-text-field
          formControlName="name"
          autofocus="true"
          size="XL"
          maxLength="128"
        ></app-text-field>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'compliance-test-master.popup.form.description' | translate }}
      </label>
      <div class="col-sm-6 text-left">
        <app-text-area
          formControlName="description"
          size="XL"
          maxLength="512"
        ></app-text-area>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <h3 class="mb-0">{{
        'compliance-test-master.popup.form.checklistComplianceGroup' | translate
      }}</h3>
      <app-button (onClick)="doAddChecklist()"
        ><em class="pir pi-plus-circle"></em>&nbsp;{{
          'compliance-test-master.popup.button.addCompliance' | translate
        }}</app-button
      >
    </div>
    <h3 class="separator"></h3>

    <app-accordion
      formArrayName="complianceChecklistList"
      [isLoading]="formLoading"
      cdkDropList
      (cdkDropListDropped)="onDrop($event)"
      [isShowMultiple]="true"
    >
      <app-accordion-item
        header="{{
          checklist.value.testName ||
            'compliance-test-master.popup.accordionItem.header.addCompliance'
        }}"
        *ngFor="
          let checklist of complianceChecklistList.controls;
          let i = index
        "
        [formGroupName]="i"
        [isExpanded]="checklist.value.isExpanded"
        customClassHeader="bg-white"
        customClassBody="bg-light"
        cdkDrag
      >
        <ng-template #headerLeft>
          <em
            class="pir pi-bars pi-3x"
            cdkDragHandle
            (mouseenter)="onMouseEnter($event)"
          ></em>
        </ng-template>
        <ng-template #headerRight>
          <em
            class="pir pi-trash-alt pi-2x"
            (click)="doDeleteChecklist($event, i)"
          ></em>
        </ng-template>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 text-left">{{
            'compliance-test-master.popup.form.complianceTestName' | translate
          }}</label>
          <div class="col-sm-9 text-left"
            ><app-text-field
              formControlName="testName"
              size="XL"
              maxLength="32"
            ></app-text-field
          ></div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 text-left">{{
            'compliance-test-master.popup.form.checklistDocument' | translate
          }}</label>
          <div class="col-sm-9 text-left"
            ><app-dropdown-select
              [optionList]="procDocTypeOptionList"
              size="XL"
              formControlName="checklistDocument"
              showLimitValue="5"
              [isMultiple]="true"
              container="body"
              type="AUTOCOMPLETE"
            >
              <ng-template #option let-option>
                {{ getTranslateKey(option) | translate }}
              </ng-template>
              <ng-template #value let-value>
                {{ getTranslateKey(value) | translate }}
              </ng-template>
            </app-dropdown-select></div
          >
        </div>
      </app-accordion-item>
    </app-accordion>
  </form>
  <div class="button-group button-group-center mt-5">
    <app-button [outline]="true" color="SECONDARY" (onClick)="doCancel()">{{
      'app.button.cancel' | translate
    }}</app-button>
    <app-button (onClick)="doSave()">{{
      'app.button.save' | translate
    }}</app-button>
  </div>
</app-popup>
