import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { AdministrationCriteria } from '../../core/bean/administration-criteria';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';

@Component({
  templateUrl: './popup-add-administration-criteria.component.html'
})
export class PopupAddAdministrationCriteriaComponent extends BaseComponentComponent {
  public administrationCriteria: AdministrationCriteria =
    new AdministrationCriteria();
  public administrationOptionList: OptionListModel<any> = new OptionListModel(
    true,
    'name'
  );
  public isAddressChecked = false;
  @Input() administrationCriteriaList: AdministrationCriteria[] = [];
  @Input() administrationCriteriaOptList: AdministrationCriteria[] = [];
  @Output() onChange: EventEmitter<AdministrationCriteria[]> =
    new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {
    super('evaluation-criteria');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      administrationCriteria: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.administrationOptionList.setRequestValues(
      this.administrationCriteriaOptList
    );
    if (
      this.administrationCriteriaList &&
      this.administrationCriteriaList.length !== 0
    ) {
      this.formGroup.patchValue({
        administrationCriteria: this.administrationCriteriaList
      });
    }
    this.setStateReady();
  }

  doSubmit(): void {
    this.administrationCriteriaList = this.formGroup.get(
      'administrationCriteria'
    ).value;
    if (
      this.administrationCriteriaList &&
      this.administrationCriteriaList.length !== 0
    ) {
      this.onChange.emit(this.administrationCriteriaList);
    } else {
      this.global.alertService.showError(
        'evaluation-criteria.validation.chooseAdministration',
        '.popup-administration'
      );
    }
  }
}
