<app-popup header="app-popup-budget-work-plan-info.title">
    <div class="row main-row">
        <div class="col-lg-12 mb-4">
            <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{ 'app-popup-budget-work-plan-info.label.number' |
                        translate }}</label>
                <div class=" col-sm-4 text-left ">
                    {{ budget.code }}
                </div>
            </div>

            <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{ 'app-popup-budget-work-plan-info.label.organization' |
                        translate }}</label>
                <div class=" col-sm-4 text-left ">
                    {{ budget.organization.name }}
                </div>
            </div>

            <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{ 'app-popup-budget-work-plan-info.label.period' | translate
                        }}</label>
                <div class=" col-sm-4 text-left ">
                    {{ budget.activityPeriod }}
                </div>
            </div>
        </div>
    </div>
    <app-table [tableResponse]="tableResponse" [isServerSide]="true" stringUrl="/app-popup-budget-work-plan-info/index"
        customData="{{budget.id}}">
        <ng-template #headerButtons>
        </ng-template>
    </app-table>
</app-popup>