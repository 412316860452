import { BaseEntity } from '../base/base-entity';
import { Coa } from '../bean/coa';
import { CostCenter } from '../bean/cost-center';
import { OtherCost } from '../bean/other-cost';
import { PaymentVoucher } from '../bean/payment-voucher';

export class PaymentVoucherOtherCostEntity extends BaseEntity {
  public coa: Coa;
  public costCenter: CostCenter;
  public paymentVoucher: PaymentVoucher;
  public otherCost: OtherCost;
  public amount: number;
  public name: string;
  public balanceType: number;
}
