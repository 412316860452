<ng-container *ngIf="!model.isLoading">
  <h5 class="separator">{{ 'company-profile.form-group.commisioner' | translate
    }} <span class="control-label"></span></h5>
  <app-table #selectorCommissioner [isServerSide]="true"
    stringUrl="/tab-company-organization/index-commissioner"
    [model]="tableResponseCommissioner">
  </app-table>

  <h5 class="separator">{{ 'company-profile.form-group.director' | translate }}
    <span class="control-label"></span></h5>
  <app-table #selectorDirector [isServerSide]="true"
    stringUrl="/tab-company-organization/index-director"
    [model]="tableResponseDirector">
  </app-table>

  <h5 class="separator">{{ 'company-profile.form-group.shareHolder' | translate
    }} <span class="control-label"></span></h5>
  <app-table #selectorShareHolder [isServerSide]="true"
    stringUrl="/tab-company-organization/index-share-holder"
    [model]="tableResponseShareHolders">
    <ng-template #actionButtons let-rowData>
      <ng-container>
        <app-button size="TN" (onClick)="doDetail(rowData)"
          title="{{ 'app.tooltip.detail' | translate }}">
          <span class="fas fa-search"></span>
        </app-button>
      </ng-container>
    </ng-template>
  </app-table>
</ng-container>