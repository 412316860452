<em
  class="fas fa-lg icon-rating"
  (click)="handleClick()"
  (mouseenter)="handleMouseEnter()"
  (mouseleave)="handleMouseLeave()"
  [ngClass]="[
    icon,
    rating >= iconId ? 'icon-rating-filled' : 'icon-rating-blank'
  ]"
  (keyup)="onKeyUp($event)"
  (keydown)="onKeyDown($event)"
></em>
<ng-content></ng-content>
