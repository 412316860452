import { TableState } from '../model/table-state';
import { changeTableFilterPerPage } from './change-table-filter-per-page-action';
import { changeTablePage } from './change-table-page-action';
import { deleteTableRecord } from './delete-table-record-action';
import { expandOrCollapseAllTableRow } from './expand-or-collapse-all-table-row-action';
import { expandOrCollapseTableRow } from './expand-or-collapse-table-row-action';
import { firstLoadTable } from './first-load-table-action';
import { loadTableRecordChildrens } from './load-table-record-childrens-action';
import { reloadTable } from './reload-table-action';
import { searchTable } from './search-table-action';
import { selectAllTableRecord } from './select-all-table-record-action';
import { selectTableRecord } from './select-table-record-action';
import { setTableHeaderSticky } from './set-table-header-sticky-action';
import { sortTable } from './sort-table-action';
import * as tableAction from './table-action';
export function dispatchTable(
  state: TableState,
  action: tableAction.AllTableAction
): void {
  switch (action.type) {
    case tableAction.FIRST_LOAD_TABLE:
      firstLoadTable(state);
      break;
    case tableAction.SEARCH_TABLE:
      searchTable(state, action.payload);
      break;
    case tableAction.SORT_TABLE:
      sortTable(state, action.payload);
      break;
    case tableAction.CHANGE_TABLE_PAGE:
      changeTablePage(state, action.payload);
      break;
    case tableAction.SET_STICKY_TABLE_HEADER:
      setTableHeaderSticky(state, action.payload);
      break;
    case tableAction.DELETE_TABLE_RECORD:
      deleteTableRecord(state);
      break;
    case tableAction.SELECT_TABLE_RECORD:
      selectTableRecord(state, action.payload);
      break;
    case tableAction.SELECT_ALL_TABLE_RECORD:
      selectAllTableRecord(state, action.payload);
      break;
    case tableAction.CHANGE_FILTER_TABLE_PER_PAGE:
      changeTableFilterPerPage(state, action.payload);
      break;
    case tableAction.EXPAND_OR_COLLAPSE_TABLE_ROW:
      expandOrCollapseTableRow(state, action.payload);
      break;
    case tableAction.EXPAND_OR_COLLAPSE_ALL_TABLE_ROW:
      expandOrCollapseAllTableRow(state, action.payload);
      break;
    case tableAction.RELOAD_TABLE:
      reloadTable(state);
      break;
    case tableAction.LOAD_TABLE_RECORD_CHILDREN:
      loadTableRecordChildrens(state, action.payload);
      break;
    default:
      console.log(`Info: There's no action type`);
      break;
  }
}
