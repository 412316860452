import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { PqEditAddComponent } from './pq-edit-add.component';
import { PqComponent } from './pq.component';
export const routes = [
  { path: '', component: PqComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: PqEditAddComponent,
    data: { breadcrumb: 'pq.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: PqEditAddComponent,
    data: { breadcrumb: 'pq.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [PqComponent, PqEditAddComponent],
  providers: []
})
export class PqModule {}
