import { ItemType } from '../../core/bean/item-type';
import { PriceEvaluationMethod } from '../../core/bean/price-evaluation-method';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { ProcurementParticipant } from '../../core/bean/procurement-participant';
import { ProcurementSchedule } from '../../core/bean/procurement-schedule';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path-x';
import { AppOfficialReportModel } from '../../core/components/app-official-report/app-official-report-model';
import { WorkflowStepInfoModel } from '../../core/components/app-workflow-step-info/model/workflow-step-info-model';
import { QuotationItemReferenceEntity } from '../../core/entity/quotation-item-reference.entity';
import { VendorQuotationByItemView } from '../../core/view/entity/bean/vendor-quotation-by-item-view';
import { VendorQuotationFinalReNegoView } from '../../core/view/entity/bean/vendor-quotation-final-re-nego-view';
import { VendorQuotationFinalView } from '../../core/view/entity/bean/vendor-quotation-final-view';
import { VendorQuotationView } from '../../core/view/entity/bean/vendor-quotation-view';
import { VendorReferenceSelectedVendorView } from '../../core/view/entity/bean/vendor-reference-selected-vendor-view';

export class VendorReferenceResponse {
  public procurement: Procurement;
  public procurementSchedule: ProcurementSchedule;
  public procurementParticipant: ProcurementParticipant;
  public workflowStepInfoModel: WorkflowStepInfoModel;
  public approvalPathResponseModel: ApprovalPathResponseModel;
  public appOfficialReportModel: AppOfficialReportModel;
  public itemTypeList: ItemType[];
  public procurementItemList: ProcurementItem[];
  public vendorQuotationViewList: VendorQuotationView[];
  public priceEvaluationMethodList: PriceEvaluationMethod[];
  public vendorQuotationFinalViewList: VendorQuotationFinalView[];
  public quotationItemReferenceList: QuotationItemReferenceEntity[];
  public vendorQuotationByItemViewList: VendorQuotationByItemView[];
  public vendorQuotationByVendorViewList: VendorReferenceSelectedVendorView[];
  public vendorQuotationFinalReNegoViewList: VendorQuotationFinalReNegoView[];
  public isUserApproval: boolean;
  public isHasFinalReNego: boolean;
  public isProcurementFailed: boolean;
  public isHasOep: boolean;
  public technicalPercentage: number;
  public pricePercentage: number;
  public isUserApprovalViewOnly: boolean;
  public isEndedReNego: boolean;
}
