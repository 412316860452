import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Organization } from 'src/app/core/bean/organization';
import { Pr } from 'src/app/core/bean/pr';
import { PrStatus } from 'src/app/core/bean/pr-status';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table-x/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table-x/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { PrCustomRequest } from './pr-custom-request';
import { PrOptionResponse } from './pr-option.reponse';

@Component({
  templateUrl: './pr.component.html'
})
export class PrComponent extends BaseModuleComponent implements OnInit {
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  public filter: string;
  public tableResponse: TableResponseModel<Pr>;
  public prStatusOptionList: OptionListModel<PrStatus> = new OptionListModel(
    true
  );
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);
  public fromDate: String;
  public untilDate: String;
  constructor(translateService: TranslateService) {
    super('pr', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.setOptionList();
    this.buildFormGroup();
    this.doBuildTableResponse();
    this.doSetCustomData();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.filter = this.global.routerParams.get('filter');
    this.global.routerParams.clear();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      prStatusList: [null],
      organizationList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<PrOptionResponse>('/pr/get-option-list')
      .subscribe((prOptionResponse: PrOptionResponse) => {
        this.prStatusOptionList.setRequestValues(prOptionResponse.prStatusList);
        this.organizationOptionList.setRequestValues(
          prOptionResponse.organizationList
        );
      });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'code',
          header: 'table.column.prNumber',
          plugins: 'hyperlink'
        },
        {
          field: 'postDate',
          header: 'table.column.postDate',
          plugins: 'date'
        },
        {
          field: 'requiredDate',
          header: 'table.column.requiredDate',
          plugins: 'date'
        },
        {
          field: 'organization.name',
          header: 'table.column.department'
        },
        {
          field: 'description',
          header: 'table.column.description'
        },
        {
          field: 'amount',
          header: 'table.column.totalCost',
          format: FieldFormatEnum.Currency
        },
        {
          field: 'prStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            field: 'prStatus.code',
            colorMap: {
              DRAFT: 'SECONDARY',
              REVISION: 'FEEDBACK',
              WAITING_APPROVAL: 'WARNING',
              DONE: 'SUCCESS',
              ON_PROGRESS: 'PROGRESS',
              HOLD: 'DANGER',
              VERIFIED: 'INFO',
              WAITING_VERIFICATION: 'WARNING',
              CANCELED: 'DANGER'
            }
          }
        }
      ],
      { checkBoxFn: this.isShowCheckBox.bind(this) }
    );
  }

  public isShowCheckBox(record: Pr): boolean {
    let isStatusDraft = false;
    if (record.prStatus.code !== this.global.appConstant.pm.PR_STATUS_DRAFT) {
      isStatusDraft = false;
    } else {
      isStatusDraft = true;
    }
    return isStatusDraft;
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/pr/add']);
    this.global.routerParams.set('urlBackOutside', '/pages/pr');
  }

  public doSetCustomData(): void {
    if (this.filter) {
      const customData = {
        statusCode: this.filter
      };
      this.global.routerParams.clear();
      this.tableResponse.setCustomData(customData);
      this.tableResponse.reload();
    }
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
      this.fromDate = this.formGroup.get('fromDate').value;
      this.untilDate = this.formGroup.get('untilDate').value;
    } else if (!this.formGroup.value.date) {
      this.fromDate = null;
      this.untilDate = null;
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
    this.formGroup.get('fromDate').reset();
    this.formGroup.get('untilDate').reset();
  }

  public doClick(pr: Pr): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('prId', pr.id);
    this.global.routerParams.set('prStatus', pr.prStatus);
    this.global.routerParams.set('urlBackOutside', '/pages/pr/');
    if (
      pr.prStatus.code !== this.global.appConstant.pm.PR_STATUS_DRAFT &&
      pr.prStatus.code !== this.global.appConstant.pm.PR_STATUS_REVISION
    ) {
      this.global.routerParams.set('todo', 'view');
      this.router.navigate(['/pages/pr/detail']);
    } else {
      this.global.routerParams.set('todo', 'edit');
      this.router.navigate(['/pages/pr/edit']);
    }
  }

  public doPrintReport(): void {
    const request = new PrCustomRequest();
    request.fromDate = this.fromDate;
    request.untilDate = this.untilDate;
    request.prStatusList = this.formGroup.get('prStatusList').value;
    request.organizationList = this.formGroup.get('organizationList').value;
    this.loadingBlockService.showInfo(
      this.translateService.instant('app.info.preparingDownload')
    );
    this.httpClientService.post<PrCustomRequest>('/pr/print-report', request, { responseType: 'arrayBuffer' as 'json' })
      .subscribe(response => {
        const file = new File([response], 'JR CARE - PR Report.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = url;
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.loadingBlockService.showCompleteAndClose(
          this.translateService.instant(
            'app.info.successDownloaded'
          )
        );
      });
  }

  public doDelete(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<Pr[]>>('/pr/delete', event.checkedRecordList)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }
}
