import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Coa } from 'src/app/core/bean/coa';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Tax } from '../../core/bean/tax';
import { TaxMethod } from '../../core/bean/tax-method';
import { TaxType } from '../../core/bean/tax-type';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { TaxResponse } from './tax-response';
@Component({
  templateUrl: './tax-edit-add.component.html'
})
export class TaxEditAddComponent extends BaseModuleComponent {
  public taxId: number;
  taxTypeOptionList: OptionListModel<TaxType> = new OptionListModel(false);
  taxMethodOptionList: OptionListModel<TaxMethod> = new OptionListModel(false);
  constructor(translateService: TranslateService) {
    super('tax', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setIsView();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.taxId = this.global.routerParams.get('taxId');
  }

  public setIsView(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        {
          validators: Validators.compose([
            Validators.required(),
            Validators.maxLength(64)
          ]),
          asyncValidators: [
            Validators.existsAsync('/tax/check-exist', null, null, {
              id: this.taxId
            })
          ]
        }
      ],
      name: [
        null,
        {
          validators: Validators.compose([
            Validators.required(),
            Validators.maxLength(128)
          ]),
          asyncValidators: [
            Validators.existsAsync('/tax/check-exist', null, null, {
              id: this.taxId
            })
          ]
        }
      ],
      taxType: [null, Validators.compose([Validators.required()])],
      taxMethod: [null, Validators.compose([Validators.required()])],
      amount: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(15)])
      ],
      coa: [null, Validators.required()],
      description: [
        null,
        Validators.compose([Validators.maxLength(512), Validators.required()])
      ]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<TaxResponse>(
        '/tax/add-edit',
        new RouteRequestModel(this.todo, this.taxId)
      )
      .subscribe(taxResponse => {
        this.taxTypeOptionList.setRequestValues(taxResponse.taxTypeList);
        this.taxMethodOptionList.setRequestValues(taxResponse.taxMethodList);
        if (taxResponse.tax != null) {
          const {
            id,
            code,
            name,
            taxType,
            taxMethod,
            amount,
            coa,
            description
          } = taxResponse.tax;
          this.formGroup.patchValue({
            id,
            code,
            name,
            taxType,
            taxMethod,
            amount,
            coa,
            description
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const tax: Tax = this.global.copyFormAttributeToModel(
              new Tax(),
              this.formGroup
            );
            const url = this.todo === 'edit' ? '/tax/update' : '/tax/insert';
            this.httpClientService
              .post<Response<Tax>>(url, tax)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/tax/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/tax/']);
  }

  public concatCoa(coa: Coa): string {
    return coa?.code + ' - ' + coa?.name;
  }
}
