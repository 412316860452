import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from '../../../../core/bean/user-role';
import { ToastService } from '../../../../core/components/app-toast/app-toast.service';
import { Global } from '../../../../core/global/global';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { HttpClientService } from '../../../../core/services/http-client.service';
import { LoadingBlockService } from '../../../../core/services/loading-block.service';
import { ServiceLocator } from '../../../../core/services/service-locator';

@Component({
  selector: 'theme-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private loadingBlockService: LoadingBlockService,
    public global: Global,
    public router: Router,
    public toastService: ToastService
  ) {}

  ngOnInit(): void {}

  public doLogOut(): void {
    this.loadingBlockService.showInfo('app.profile.loggingOut');
    this.authenticationService.logout();
  }

  public doSelectRole(activeRoleCode: string): void {
    const userRoleList = this.global.userSession.user.userRoleList;

    const userRole = userRoleList.find(
      userRole => userRole.role.code === activeRoleCode
    );

    const httpClientService = ServiceLocator.injector.get(HttpClientService);
    httpClientService
      .get<number>('/app-info/get-server-time', {})
      .subscribe((serverTime: number) => {
        this.doChangeRole(activeRoleCode, userRole, serverTime);
      });
  }

  private doChangeRole(
    activeRoleCode: string,
    userRole: UserRole,
    serverTime: number
  ): void {
    if (
      new Date(userRole.endDate).getTime() < serverTime ||
      new Date(userRole.startDate).getTime() > serverTime
    ) {
      const textSorry = this.global.translateService.instant(
        'app.alert.msg.sorryAccess'
      );
      const textContactAdmin = this.global.translateService.instant(
        'app.alert.msg.expiredNotStartedContactAdmin'
      );

      const msg = textSorry + ' ' + userRole.role.name + ' ' + textContactAdmin;
      this.toastService.showError(msg, {
        isShowCloseButton: false,
        title: this.global.translateService.instant(
          'app.alert.title.expiredNotStartedContactAdmin'
        )
      });
    } else {
      localStorage.setItem(
        this.global.appConstant.core.COOKIES_APP_ACTIVE_ROLE_CODE,
        activeRoleCode
      );
      window.location.pathname = '/pages/dashboard';
      this.authenticationService.relogin();
    }
  }

  public goToAccountSetting(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate(['/pages/account-setting']);
  }

  public goToSetting(): void {}
}
