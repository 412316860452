<app-rating-icon
  *ngFor="let icon of icons"
  [iconId]="icon"
  [rating]="ratingHover || value"
  (onMouseEnter)="onMouseEnter($event)"
  (onMouseLeave)="onMouseLeave($event)"
  (onClick)="onClickChange($event)"
  [disabled]="disabled"
  [isViewOnly]="isViewOnly"
  [title]="(disabled || isViewOnly ? value || 0 : icon) + ' ratings'"
></app-rating-icon>
<small class="row px-4 text-danger" *ngIf="isInvalid">
  {{ errors.message }}
</small>
