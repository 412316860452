import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { BudgetSource } from '../../core/bean/budget-source';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './budget-source-edit-add.component.html'
})
export class BudgetSourceEditAddComponent extends BaseModuleComponent {
  public budgetSourceId: number;
  constructor(translateService: TranslateService) {
    super('budget-source', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.budgetSourceId = this.global.routerParams.get('budgetSourceId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ]
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<BudgetSource>(
        '/budget-source/add-edit',
        new RouteRequestModel(this.todo, this.budgetSourceId)
      )
      .subscribe(budgetSource => {
        if (budgetSource != null) {
          this.formGroup.patchValue({
            id: budgetSource.id,
            name: budgetSource.name,
            code: budgetSource.code
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const budgetSource = this.global.copyFormAttributeToModel(
              new BudgetSource(),
              this.formGroup
            );
            const url =
              this.todo === 'edit'
                ? '/budget-source/update'
                : '/budget-source/insert';
            this.httpClientService
              .post<Response<BudgetSource>>(url, budgetSource)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/budget-source']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/budget-source']);
  }
}
