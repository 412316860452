<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <form
      class="form-horizontal"
      [formGroup]="itemContractDetailTab.formGroup"
      novalidate
    >

      <app-alert-x *ngIf="item != null 
      && ((item.minPrice != null && item.minPrice != 0) || (item.maxPrice != null && item.maxPrice != 0))
      && !isTodoView">
        <div *ngIf="item.minPrice != null && item.minPrice != 0">{{ 
          ('manage-catalog.form.alertInfo.minItemPrice' | translate) +
            ' ' + item.minPrice + ' ' + manageCatalogDTO.currencyList[0].code
        }}</div>
        <div *ngIf="item.maxPrice != null && item.maxPrice != 0">{{
          ('manage-catalog.form.alertInfo.maxItemPrice' | translate) +
            ' ' + item.maxPrice + ' ' + manageCatalogDTO.currencyList[0].code
        }}</div>
      </app-alert-x>
      <app-alert-x color="WARNING" *ngIf="!isValidItem">{{
        'manage-catalog.alertIsValidItem' | translate
      }}</app-alert-x>

      <app-card *ngIf="catalogRegionList" header="{{ 'manage-catalog.item-contract-detail.title' | translate }}">
        <ng-container formArrayName="catalogRegionList">
          <ng-container
            *ngFor="
              let catalogRegion of catalogRegionList.controls;
              let i = index
            "
            [formGroup]="catalogRegion"
          >
            <app-card header="{{ catalogRegion?.value?.provinceRegion?.name ? catalogRegion?.value?.provinceRegion?.name : '-' }}">
              <ng-template #headerRight>
                <div class="button-group button-group-right button-delete" 
                *ngIf="i != 0 && !isTodoView">
                  <app-button
                    (onClick)="doDeleteCatalogRegion(i)"
                    title="{{ 'app.button.delete' | translate }}"
                    size="TN"
                    color="DANGER"
                  >
                    <em class="fas fa-trash"></em>
                  </app-button>
                </div>
              </ng-template>

              <div
                class="form-group row text-sm-right"
                [ngClass]="!isTodoView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'manage-catalog.form.province' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-combo-box
                    size="MD"
                    [optionList]="catalogRegionList.controls[i].value.provinceRegionOptionList"
                    formControlName="provinceRegion"
                    (onChange)="updateOptionRegionList(i)"
                  >
                  </app-combo-box>
                </div>
              </div>

              <div
                class="form-group row text-sm-right"
                [ngClass]="!isTodoView ? 'required' : ''"
                *ngIf="!formLoading && isVendor || isOther || isGuest"
              >
                <label class="col-sm-3 control-label">{{
                  'manage-catalog.form.duration' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  {{ currendDate }}
                  <app-date-picker-x
                    formControlName="date"
                    [range]="true"
                    minDate="currentDate"
                    maxDate="endDateValidate"
                    (onChange)="onChangeForm(catalogRegion)"
                  >
                  </app-date-picker-x>
                </div>
              </div>

              <div
                class="form-group row text-sm-right"
                [ngClass]="!isTodoView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'manage-catalog.form.price' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-currency
                    formControlName="price"
                    [optionList]="currencyOptionList"
                    maxLength="16"
                    (onChange)="onChangeForm(catalogRegion)"
                    tooltip="{{ 'manage-catalog.tooltip.price' | translate }}"
                  ></app-currency>
                </div>
              </div>

              <div
                class="form-group row text-sm-right"
                *ngIf="!formLoading && isVendor || isOther || isGuest"
              >
                <label class="col-sm-3 control-label">{{
                  'manage-catalog.form.discount' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-text-field
                    type="decimal"
                    formControlName="discount"
                    maxLength="5"
                    groupText="%"
                    (onChange)="onChangeForm(catalogRegion)"
                    tooltip="{{ 'manage-catalog.tooltip.discount' | translate }}"
                  >
                  </app-text-field>
                </div>
              </div>

              <div
                class="form-group row text-sm-right"
                *ngIf="!formLoading && isVendor || isOther || isGuest"
              >
                <label class="col-sm-3 control-label">{{
                  'manage-catalog.form.bulkPrice' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <div *ngIf="catalogRegion.value.isBulkPrice" >YES
                    <em class="pir pi-pencil-alt text-primary" 
                      title="Bulk Price"
                      (click)="onClickPopupBulkPriceItem(catalogRegion)"
                      >
                    </em>
                    <label class="col-sm-4">
                      <p class="d-inline-block position-relative mb-1 ml-1">
                        <app-tooltip-wrapper
                        tooltip="{{ 'manage-catalog.tooltip.bulkPrice' | translate }}"
                        ></app-tooltip-wrapper>
                      </p>
                    </label>
                  </div>
                  <div *ngIf="!catalogRegion.value.isBulkPrice" >NO
                    <em class="pir pi-pencil-alt text-primary" 
                      title="Bulk Price"
                      (click)="onClickPopupBulkPriceItem(catalogRegion)"
                      *ngIf="!itemContractDetailTab.isView "
                      >
                    </em>
                    <label class="col-sm-4">
                      <p class="d-inline-block position-relative mb-1 ml-1">
                        <app-tooltip-wrapper
                        tooltip="{{ 'manage-catalog.tooltip.bulkPrice' | translate }}"
                        ></app-tooltip-wrapper>
                      </p>
                    </label>
                  </div>
                </div>
              </div>

              <div
                class="form-group row text-sm-right"
                [ngClass]="!isTodoView ? 'required' : ''"
              >
                <label class="col-sm-3 control-label">{{
                  'manage-catalog.form.quantityInPackage' | translate
                }}</label>
                <div class="col-sm-9 text-left">
                  <app-text-field
                    type="decimal"
                    formControlName="quantityInPackage"
                    maxLength="53"
                    (keyup)="onChangeForm(catalogRegion)"
                    tooltip="{{ 'manage-catalog.tooltip.quantityInPackage' | translate }}"
                    appInteger
                  >
                  </app-text-field>
                </div>
              </div>

              <!-- <app-table-xx
                *ngIf="(!formLoading && isOther && !isGuest  && isValidItem) ||
                (!formLoading && !isOther && !isGuest && !isVendor && isValidItem)"
                [model]="tableResponseCatalogRegionDetail[i]"
              >
              </app-table-xx> -->
            </app-card>
          </ng-container>
        </ng-container>
        <div class="button-group button-group-center">
          <app-button
            (onClick)="doAddCatalogRegion()"
            [disabled]="formSaving"
            *ngIf="!isTodoView"
          >
          <em class="pir pi-plus-circle"></em>
            {{ 'app.button.add' | translate }}
          </app-button>
        </div>
      </app-card>
    </form>
  </div>
</div>
