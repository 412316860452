import { FormGroup } from '@angular/forms';
import { AngularTreeModel } from '../../../model/angular-tree-model';
import { TreeProcessModel } from '../model/tree-process-model';
import { TreeActionType } from '../type/tree-action-type';
import { TreeEventType } from '../type/tree-event-type';
export class TreeEvent {
    type: TreeEventType;
    formGroup: FormGroup;
    treeProcess: TreeProcessModel;
    angularTree: AngularTreeModel;
    action: TreeActionType;
}
