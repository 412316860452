import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { CatalogAttributeGroup } from 'src/app/core/bean/catalog-attribute-group';
import { CatalogGroupTemplate } from 'src/app/core/bean/catalog-group-template';
import { CatalogSpecification } from 'src/app/core/bean/catalog-specification';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';
import { FormObjectModel } from '../../app-form-object/model/form-object-model';
import { AppCatalogInfoResponse } from '../response/app-catalog-info.response';

@Component({
  templateUrl: './app-popup-catalog-info-specification.component.html'
})
export class AppPopupCatalogInfoSpecificationComponent extends BaseModuleComponent
implements OnInit {

  @Output() public onChange: EventEmitter<any> = new EventEmitter();
  @Input() getAppCatalogInfoResponse: AppCatalogInfoResponse[] = [];
    public optionList: OptionListModel<CatalogAttributeGroup> = new OptionListModel();
    public appCatalogInfoResponse: AppCatalogInfoResponse = new AppCatalogInfoResponse();
    public formObjectModel: FormObjectModel<CatalogGroupTemplate>;
    public isViewFormObject = true;
    public isGuest = false;
    public isTodoView: boolean;

  constructor(
    translateService: TranslateService
  ) {
    super('app-popup-catalog-info-specification', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTabResponse();
  }

  public setOptionListModel(): void {
    this.optionList.setRequestValues(
      this.appCatalogInfoResponse.catalogAttributeGroupList
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
        attributeGroup: [null, Validators.required()],
        isViewFormObject: null,
        formObjectModel: null
    });
  }

  public setFormObjectModel(model: any): void {
    this.formObjectModel = FormObjectModel.create(model).setIsView(
      this.isTodoView
    );
    this.formGroup.patchValue({
      formObjectModel: this.formObjectModel
    });
    this.appCatalogInfoResponse.catalogAttributeGroup = this.formGroup.value.attributeGroup;
        this.setStateReady();
  }

  public setFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
      this.todo = 'view';
      this.todo = this.isGuest ? 'edit' : 'view';
    }
    this.formGroup.patchValue({
      attributeGroup: this.appCatalogInfoResponse.catalogAttributeGroup
    });
    this.isViewFormObject = true;
    this.setFormObjectModel(this.appCatalogInfoResponse.catalogSpesificationList);

    // tslint:disable-next-line: no-string-literal
    this.formObjectModel.formGroup.setIsView(this.isTodoView);
  }

  public buildTabResponse(): void {
    console.log(this.getAppCatalogInfoResponse);
    this.httpClientService
      .get<AppCatalogInfoResponse>(
        '/app-catalog-info/get-catalog-attribute-group-list'
      )
      .subscribe(catalogAttributeGroupList => {
        this.appCatalogInfoResponse.catalogAttributeGroupList = catalogAttributeGroupList;
        this.setOptionListModel();
        this.setFormGroup();
        this.setStateReady();
      });
  }

  public doChooseCatalogGroup(
    catalogAttributeGroup: CatalogAttributeGroup
  ): void {

    this.formGroup.patchValue({
      isViewFormObject: false
    });
    this.isViewFormObject = this.formGroup.get('isViewFormObject').value;
    const catalogAttributeGroupOld = this.getAppCatalogInfoResponse.find(
      id =>
        catalogAttributeGroup?.id ===
        id.catalogAttributeGroup?.id
    )
    
    if (catalogAttributeGroup) {
      this.loadingBlockService.showInfo(`app.msg.processing`);
      this.httpClientService
        .get<CatalogGroupTemplate[]>('/manage-catalog/get-catalog-group-template/' + catalogAttributeGroup.id)
        .subscribe(catalogGroupTemplateList => {
          this.formGroup.patchValue({
            isViewFormObject: true
          });
          this.isViewFormObject = this.formGroup.get('isViewFormObject').value;
          if (catalogAttributeGroupOld !== undefined) {
            if (catalogAttributeGroupOld.catalogSpesificationList !== undefined) {
              const catalogAttrSpecsIdList: number[] = catalogAttributeGroupOld.catalogSpesificationList.map(
                catalogSpec => catalogSpec.catalogAttribute.id);
        
              const newCatalogGroupTemplList: CatalogGroupTemplate[] = catalogAttributeGroupOld.catalogGroupTemplateList.filter(catalogGroupTempl =>
                !catalogAttrSpecsIdList.includes(catalogGroupTempl.catalogAttribute.id));
        
              this.log.debug(newCatalogGroupTemplList);
              newCatalogGroupTemplList.forEach(newCatalogGroupTempl => {
                const catalogSpesification = new CatalogSpecification();
                catalogSpesification.catalogAttribute = newCatalogGroupTempl.catalogAttribute;
                catalogSpesification.catalogAttributeGroup = catalogAttributeGroupOld.catalogAttributeGroup;
                catalogSpesification.value = null;
                catalogAttributeGroupOld.catalogSpesificationList.push(catalogSpesification);
              });
              let arr: String[] = [];
              let indexNotArray: number[] = [];
              let indexArrayList: number[] = [];
              let isArrayUse = false;
              let isNotArray = false;
              let isNotArrayTemp = false;
              let isNotUpdateArray = false;
              let getIndexCatalogSpecification = 0;
              let indexCatalogSpecification = 0;
              let indexArray = 0;
              catalogAttributeGroupOld.catalogSpesificationList.forEach((catalogSpecificationTemplOld) => {
                if (catalogSpecificationTemplOld.value !== undefined && catalogSpecificationTemplOld.value !== null
                   && catalogSpecificationTemplOld.value !== "null"){
                  if (catalogSpecificationTemplOld.isChoosen) {
                    if (catalogSpecificationTemplOld.isArray) {
                      if (catalogAttributeGroupOld.lengthCatalogSpesificationNew !== 
                        catalogAttributeGroupOld.lengthCatalogSpesificationOld) {
                          let selectedIndex = catalogSpecificationTemplOld.catalogAttribute.formObject['value'].findIndex(
                            catalogSpesificationOld => catalogSpesificationOld.name === catalogSpecificationTemplOld.name
                            );
                            arr.push(catalogSpecificationTemplOld.catalogAttribute.formObject['value'][selectedIndex]);
                            isArrayUse = true;
                            indexArray = indexCatalogSpecification;
                        } else {
                          arr.push(catalogSpecificationTemplOld.name);
                          catalogSpecificationTemplOld.value = JSON.stringify(
                            catalogSpecificationTemplOld.value
                          );
                          isNotUpdateArray = true;
                        }
                      
                    } else {
                      if (!isArrayUse) {
                        isNotArray = true;
                        getIndexCatalogSpecification = indexCatalogSpecification;
                        if (isNotArray) {
                          let selectedIndex = catalogSpecificationTemplOld.catalogAttribute.formObject['value'].findIndex(
                            catalogSpesificationOld => catalogSpesificationOld.name === catalogSpecificationTemplOld.name
                            );
                          arr.push(catalogSpecificationTemplOld.catalogAttribute.formObject['value'][selectedIndex]);
                          getIndexCatalogSpecification = catalogAttributeGroupOld.catalogSpesificationList.length - 1;
                          let index = catalogAttributeGroupOld.catalogSpesificationList.findIndex(
                            catalogSpesificationOld => catalogSpesificationOld.value === catalogSpecificationTemplOld.value
                            );
                          if (isNotUpdateArray) {
                            indexNotArray.push(index)
                          } else {
                            if (index !== getIndexCatalogSpecification) {
                              indexNotArray.push(index);
                            }
                          }
                          isNotArrayTemp = true;
                        }
                      } else {
                          let index = catalogAttributeGroupOld.catalogSpesificationList.findIndex(
                            catalogSpesificationOld => catalogSpesificationOld.value === catalogSpecificationTemplOld.value
                          );
                          indexArrayList.push(index);
                          let selectedIndex = catalogSpecificationTemplOld.catalogAttribute.formObject['value'].findIndex(
                            catalogSpesificationOld => catalogSpesificationOld.name === catalogSpecificationTemplOld.name
                            );
                          arr.push(catalogSpecificationTemplOld.catalogAttribute.formObject['value'][selectedIndex]);
                      }
                    }
                  } else {
                    catalogSpecificationTemplOld.value = JSON.stringify(
                      catalogSpecificationTemplOld.catalogAttribute.formObject['value']
                    );
                  }
                }
                indexCatalogSpecification +=1;
                catalogSpecificationTemplOld.catalogAttributeGroup = catalogAttributeGroupOld.catalogAttributeGroup;
              });
              
              if (isArrayUse) {
                if (indexArrayList.length > 0) {
                  catalogAttributeGroupOld.catalogSpesificationList[indexArray].value = JSON.stringify(
                    arr
                    );
                    indexArrayList.forEach((index) => {
                      catalogAttributeGroupOld.catalogSpesificationList.splice(index, 1);
                    });
                } else {
                  catalogAttributeGroupOld.catalogSpesificationList[indexArray].value = JSON.stringify(
                    arr
                    );
                }
              }
              if (isNotArrayTemp) {
                catalogAttributeGroupOld.catalogSpesificationList[getIndexCatalogSpecification].value = JSON.stringify(
                  arr
                  );
                  indexNotArray.forEach((index) => {
                    catalogAttributeGroupOld.catalogSpesificationList.splice(index, 1);
                  });
              }
              this.setFormObjectModel(catalogAttributeGroupOld.catalogSpesificationList);
            }
          } else {
            this.setFormObjectModel(catalogGroupTemplateList);
          }
          
          this.loadingBlockService.close();
        });
    }
  }

  public validateSpecification(): boolean {
    const formObjectModel: FormObjectModel<CatalogGroupTemplate> = this
        .formGroup.controls.formObjectModel.value;
      this.formGroupService.validateAllFormFields(formObjectModel.formGroup);
      if (formObjectModel.formGroup.valid) {
        return true;
      } else {
        return false;
      }
  }

  public doAdd(): void {
    //const isValidateSpecification = this.validateSpecification();
    //if (isValidateSpecification) {
      this.onChange.emit(this.appCatalogInfoResponse);
    //}
  }

  public doOnChange(): void {
    this.appCatalogInfoResponse.catalogSpesificationList = [];
    this.formGroup.value.formObjectModel
      .getRequestValues()
      .forEach(catalogGroupTemplate => {
        const catalogSpecification: CatalogSpecification = new CatalogSpecification();
        catalogSpecification.catalogAttribute =
          catalogGroupTemplate.catalogAttribute;
        // tslint:disable-next-line: no-string-literal
        catalogSpecification.value = catalogGroupTemplate.catalogAttribute.formObject['value'];
        this.appCatalogInfoResponse.catalogSpesificationList.push(
          catalogSpecification
        );
      });
  }
}
