import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { Procurement } from '../../../bean/procurement';
import { FieldFormatEnum } from '../../app-table/model/field-format.enum';
import { TableResponseModel } from '../../app-table/model/table-response-model';
@Component({
  templateUrl: './app-popup-procurement.component.html'
})
export class AppPopupProcurementComponent extends BaseComponentComponent {
  @Input() procurementIdList: number[];
  @Output() onChange: EventEmitter<Procurement> = new EventEmitter();
  procurementCustomData: any;

  tableResponse: TableResponseModel<Procurement>;

  constructor() {
    super('app-popup-procurement');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setCustomData();
    this.buildTableResponse();
  }

  buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorId: [null],
      procurementIdList: [null]
    });
  }

  setCustomData(): void {
    this.formGroup.patchValue({
      vendorId: this.global.routerParams.get('vendorId'),
      procurementIdList: this.procurementIdList
    });
    this.procurementCustomData = JSON.stringify(this.formGroup.value);
  }

  buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'procurement.name' },
      {
        field: 'procurementDate',
        header: 'procurement.date',
        format: FieldFormatEnum.ShortDate
      },
      { field: 'procurementType.name', header: 'procurement.type' },
      { field: 'workflowModelPrcs.name', header: 'stages' }
    ]);
  }

  public doChoose(procVendor: Procurement): void {
    this.onChange.emit(procVendor);
  }
}
