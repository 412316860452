import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppActivityHistoryViewModule } from 'src/app/core/components/app-activity-history-view/app-activity-history-view.module';
import { AppContractDocumentModule } from '../../core/components/app-contract-document/app-contract-document.module';
import { AppContractRequestInfoModule } from '../../core/components/app-contract-request-info/app-contract-request-info.module';
import { AppItemRequestReleasedModule } from '../../core/components/app-item-request-released/app-item-request-released.module';
import { AppVendorInformationModule } from '../../core/components/app-vendor-information/app-vendor-information.module';
import { AppWorkDefinitionModule } from '../../core/components/app-work-definition/app-work-definition.module';
import { SharedModule } from '../../core/shared/shared.module';
import { ContractHistoryVendorDetailComponent } from './contract-history-vendor-detail.component';
import { ContractHistoryVendorComponent } from './contract-history-vendor.component';

export const routes = [
  {
    path: '',
    component: ContractHistoryVendorComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'detail',
    component: ContractHistoryVendorDetailComponent,
    data: { breadcrumb: 'contract-history-vendor.breadcrumb.view' }
  },
  {
    path: 'detail/:token',
    component: ContractHistoryVendorDetailComponent,
    data: { breadcrumb: 'contract-history-vendor.breadcrumb.view' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppContractRequestInfoModule,
    AppVendorInformationModule,
    AppWorkDefinitionModule,
    AppActivityHistoryViewModule,
    AppContractDocumentModule,
    AppItemRequestReleasedModule
  ],
  declarations: [
    ContractHistoryVendorComponent,
    ContractHistoryVendorDetailComponent
  ],
  entryComponents: [],
  exports: []
})
export class ContractHistoryVendorModule {}
