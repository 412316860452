import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { PerformanceWeight } from '../../core/bean/performance-weight';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';

@Component({
  templateUrl: './scoring-template-setting.component.html'
})
export class ScoringTemplateSettingComponent extends BaseModuleComponent {
  public performanceWeightList: PerformanceWeight[];
  public performanceWeight: PerformanceWeight = new PerformanceWeight();
  public scoringTemplateId: number;

  constructor(translateService: TranslateService) {
    super('scoring-template', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.scoringTemplateId = this.global.routerParams.get('scoringTemplateId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      registrationWeight: [
        null,
        Validators.compose([Validators.required(), Validators.max(100)])
      ],
      procurementWeight: [
        null,
        Validators.compose([Validators.required(), Validators.max(100)])
      ],
      fulfillmentWeight: [
        null,
        Validators.compose([Validators.required(), Validators.max(100)])
      ]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<PerformanceWeight>(
        '/scoring-template/view-setting',
        new RouteRequestModel(this.todo, this.scoringTemplateId)
      )
      .subscribe(performanceWeightList => {
        this.performanceWeightList = performanceWeightList
        performanceWeightList.forEach(
          performanceWeight => {
            if (
              performanceWeight.moduleScoringCriteria.code === 'REGISTRATION'
            ) {
              this.formGroup
                .get('registrationWeight')
                .patchValue(performanceWeight.weight);
            } else if (
              performanceWeight.moduleScoringCriteria.code === 'PROCUREMENT'
            ) {
              this.formGroup
                .get('procurementWeight')
                .patchValue(performanceWeight.weight);
            } else {
              this.formGroup
                .get('fulfillmentWeight')
                .patchValue(performanceWeight.weight);
            }
          }
        );
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    let totalWeight = 0;
    const valueList = Object.values(this.formGroup.value).toString();
    const valueListInString = valueList.split(',');
    for (const value of valueListInString) {
      totalWeight += parseFloat(value) * 100;
    }
    if (this.formGroup.valid && totalWeight / 100 === 100) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            let performanceWeightNew: PerformanceWeight = new PerformanceWeight();
            if (this.performanceWeightList.length !== 0) {
              this.performanceWeightList.forEach(
                performanceWeight => {
                  if (
                    performanceWeight.moduleScoringCriteria.code ===
                    'REGISTRATION'
                  ) {
                    performanceWeightNew = performanceWeight;
                    performanceWeightNew.weight = this.formGroup.get(
                      'registrationWeight'
                    ).value;
                  } else if (
                    performanceWeight.moduleScoringCriteria.code ===
                    'PROCUREMENT'
                  ) {
                    performanceWeightNew = performanceWeight;
                    performanceWeightNew.weight = this.formGroup.get(
                      'procurementWeight'
                    ).value;
                  } else {
                    performanceWeightNew = performanceWeight;
                    performanceWeightNew.weight = this.formGroup.get(
                      'fulfillmentWeight'
                    ).value;
                  }
                  this.performanceWeightList.push(
                    performanceWeight
                  );
                }
              );
            } else {
              // to insert new data
              const listOfModuleCode: string[] = [
                'REGISTRATION',
                'PROCUREMENT',
                'FULFILLMENT'
              ];
              listOfModuleCode.forEach((moduleCode, index) => {
                const performanceWeight: PerformanceWeight = new PerformanceWeight();

                performanceWeight.moduleScoringCriteria.id = index + 1;
                if (moduleCode === 'REGISTRATION') {
                  performanceWeight.weight = this.formGroup.get(
                    'registrationWeight'
                  ).value;
                } else if (moduleCode === 'PROCUREMENT') {
                  performanceWeight.weight = this.formGroup.get(
                    'procurementWeight'
                  ).value;
                } else {
                  performanceWeight.weight = this.formGroup.get(
                    'fulfillmentWeight'
                  ).value;
                }

                this.performanceWeightList.push(
                  performanceWeight
                );
              });
            }

            this.todo = 'setting';
            const url = '/scoring-template/setting';
            this.httpClientService
              .post<Response<PerformanceWeight[]>>(url, this.performanceWeightList)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/scoring-template/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    } else {
      this.global.alertService.showError(
        'Settingan bobot performance salah, total bobot performance harus sama dengan 100%'
      );
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/scoring-template/']);
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
}
