import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { TabModel } from 'src/app/core/model/tab/tab-model';
import { TabResponseModel } from 'src/app/core/model/tab/tab-response-model';
import { OrderAddEditResponse } from './response/order-add-edit.response';

@Component({
  templateUrl: './order-catalog-add.component.html'
})
export class OrderCatalogAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  constructor(translateService: TranslateService) {
    super('pr', translateService);
  }
  public orderResponse: OrderAddEditResponse = new OrderAddEditResponse();
  public tabResponse: TabResponseModel;
  public tabOne: TabModel;

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setStateReady();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.orderResponse = this.global.routerParams.get('orderResponse');
    this.tabResponse = this.global.routerParams.get('tabResponse');
    this.tabOne = this.tabResponse.currentTab;
  }
}
