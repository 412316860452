<ng-container *ngIf="!ISVIEW; then notIsViewTmpl; else isViewTmpl">
</ng-container>
<ng-template #notIsViewTmpl>
  <div class="app-time">
    <div class="d-flex justify-content-between align-items-center font-weight-bold" [ngStyle]="{ width: '136px' }">
      <div class="hour-section" [ngStyle]="{ width: '64px' }">
        <em class="fas fa-caret-up app-time-control" (click)="doIncrement(appTimeConsntant.TYPE_HOUR)" [ngClass]="[
            isInvalid && !hourValue ? 'text-danger' : '',
            hourValue ? 'text-success' : ''
          ]" *ngIf="!formControl.disabled && isShowArrow" (keyup)="onKeyUp($event)" (keydown)="onKeyDown($event)"></em>
        <input type="text" min="0" max="23" class="form-control text-center" placeholder="00" maxlength="2"
          [value]="hourValue" (input)="handleInput($event, appTimeConsntant.TYPE_HOUR)"
          (focusout)="handleFocusOut($event, appTimeConsntant.TYPE_HOUR)" [ngClass]="[
            isInvalid && !hourValue ? 'is-invalid' : '',
            hourValue ? 'is-valid' : ''
          ]" [disabled]="formControl.disabled" (keyup)="handleKeyUp($event, appTimeConsntant.TYPE_HOUR)"
          (paste)="handlePaste($event, appTimeConsntant.TYPE_HOUR)" aria-label="{{ generatedId + '-hour' }}" />
        <em class="fas fa-caret-down app-time-control" (click)="doDecrement(appTimeConsntant.TYPE_HOUR)" [ngClass]="[
            isInvalid && !hourValue ? 'text-danger' : '',
            hourValue ? 'text-success' : ''
          ]" *ngIf="!formControl.disabled && isShowArrow" (keyup)="onKeyUp($event)" (keydown)="onKeyDown($event)"></em>
      </div>
      <span>:</span>
      <div class="minute-section" [ngStyle]="{ width: '64px' }">
        <em class="fas fa-caret-up app-time-control" (click)="doIncrement(appTimeConsntant.TYPE_MINUTE)" [ngClass]="[
            isInvalid && !minuteValue ? 'text-danger' : '',
            minuteValue ? 'text-success' : ''
          ]" *ngIf="!formControl.disabled && isShowArrow" (keyup)="onKeyUp($event)" (keydown)="onKeyDown($event)"></em>
        <input type="text" min="0" max="59" class="form-control text-center" placeholder="00" maxlength="2"
          [value]="minuteValue" (input)="handleInput($event, appTimeConsntant.TYPE_MINUTE)"
          (focusout)="handleFocusOut($event, appTimeConsntant.TYPE_MINUTE)" [ngClass]="[
            isInvalid && !minuteValue ? 'is-invalid' : '',
            minuteValue ? 'is-valid' : ''
          ]" [disabled]="formControl.disabled" (keyup)="handleKeyUp($event, appTimeConsntant.TYPE_MINUTE)"
          (paste)="handlePaste($event, appTimeConsntant.TYPE_MINUTE)" aria-label="{{ generatedId + '-minute' }}" />
        <em class="fas fa-caret-down app-time-control" (click)="doDecrement(appTimeConsntant.TYPE_MINUTE)" [ngClass]="[
            isInvalid && !minuteValue ? 'text-danger' : '',
            minuteValue ? 'text-success' : ''
          ]" *ngIf="!formControl.disabled && isShowArrow" (keyup)="onKeyUp($event)" (keydown)="onKeyDown($event)"></em>
      </div>
    </div>
    <small class="text-danger" *ngIf="isInvalid">
      {{ errors.message }}
    </small>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-template>
<ng-template #isViewTmpl>
  <div class="d-inline-block position-relative">
    <span>{{ (formControl.value | date: 'HH:mm') || '-' }}</span>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper [tooltip]="tooltip" [position]="tooltipPosition" [symbolic]="symbolic" [isView]="ISVIEW">
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>