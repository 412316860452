import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Contract } from 'src/app/core/bean/contract';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { ContractDraftHistoyModel } from './contract-draft-history.model';

@Component({
  templateUrl: './app-popup-contract-draft-version.component.html'
})
export class AppPopupContractDraftVersionComponent extends BaseComponentComponent {
  @Input() contract: Contract;
  public customData: any;
  public tableResponse: TableResponseModel<ContractDraftHistoyModel>;

  constructor() {
    super('contract-draft');
  }

  onInit(): void {
    this.doBuildTableResponse();
    this.doSetTableResponse();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'contractTemplateName',
        header: 'table.column.contractTemplate'
      },
      {
        field: 'file.fileName',
        header: 'table.column.file',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName',
        fileTypeCode: this.global.appConstant.fileType.DOC_CONTRACT
      },
      {
        field: 'version',
        header: 'table.column.version',
        fn: this.setVersion.bind(this)
      },
      {
        field: 'date',
        header: 'table.column.date',
        format: FieldFormatEnum.ShortDateAndTime
      },
      { field: 'userName', header: 'table.column.user' }
    ]);
  }

  public setVersion(version: number): string {
    if (version && version === 0) {
      return '0';
    } else {
      return version.toString();
    }
  }

  public doSetTableResponse(): void {
    this.httpClientService
      .get<ContractDraftHistoyModel[]>(
        '/contract-draft/version-history/' + this.contract.id
      )
      .subscribe(
        (contractDraftHistoryModelList: ContractDraftHistoyModel[]) => {
          if (contractDraftHistoryModelList.length !== 0) {
            this.tableResponse.setRecords(contractDraftHistoryModelList);
            this.tableResponse.setTotalRecords(
              contractDraftHistoryModelList.length
            );
            this.tableResponse.reloadClient();
          }
          this.setStateReady();
        }
      );
  }
}
