<div class="widget-announcement">
  <div class="widget-announcement-header text-center mb-3">
    <h3 class="title">{{ 'portal.menu.announcement' | translate }}</h3>
  </div>
  <div class="widget-announcement-content">
    <app-widget-announcement-item *ngFor="let announcement of announcementList"
      [announcement]="announcement"></app-widget-announcement-item>
    <div class="border rounded p-4 text-center"
      *ngIf="announcementList.length === 0">
      No announcement available
    </div>
  </div>
  <div class="widget-announcement-footer mt-2"
    *ngIf="announcementList.length > 0">
    <div class="button-group button-group-right">
      <app-button size="SM" (onClick)="handleShowAll()">Show All</app-button>
    </div>
  </div>
</div>