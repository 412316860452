import { DatePickerState } from '../model';
import { changeDatePickerTime } from './change-date-picker-time-action';
import * as datePickerAction from './date-picker-action';
import { firstLoadDatePicker } from './first-load-date-picker-action';
import { resetDatePicker } from './reset-date-picker-action';
import { resetDatePickerTimeHtmlText } from './reset-date-picker-time-html-text-action';
import { setDatePickerDate } from './set-date-picker-date-action';
import { setDatePickerDropDownView } from './set-date-picker-drop-down-view-action';
import { setDatePickerMonth } from './set-date-picker-month-action';
import { setDatePickerOnRange } from './set-date-picker-on-range-action';
import { setDatePickerTime } from './set-date-picker-time-action';
import { setDatePickerYear } from './set-date-picker-year-action';
import { setDatePickerYearList } from './set-date-picker-year-list-action';
import { setDatePickerTimeHtmlText } from './set-date-piker-time-html-text-action';
import { setDatePickerMinOrMaxDate } from './set-min-or-max-date-action';
export function dispatchDatePicker(
  state: DatePickerState,
  action: datePickerAction.AllDatePickerAction
): void {
  switch (action.type) {
    case datePickerAction.FIRST_LOAD_DATE_PICKER:
      firstLoadDatePicker(state);
      break;
    case datePickerAction.SET_DATE_PICKER_DATE:
      setDatePickerDate(state, action.payload);
      break;
    case datePickerAction.SET_DATE_PICKER_MONTH:
      setDatePickerMonth(state, action.payload);
      break;
    case datePickerAction.SET_DATE_PICKER_YEAR:
      setDatePickerYear(state, action.payload);
      break;
    case datePickerAction.SET_DATE_PICKER_TIME:
      setDatePickerTime(state, action.payload);
      break;
    case datePickerAction.SET_DATE_PICKER_TIME_HTML_TEXT:
      setDatePickerTimeHtmlText(state, action.payload);
      break;
    case datePickerAction.SET_DATE_PICKER_DROP_DOWN_VIEW:
      setDatePickerDropDownView(state, action.payload);
      break;
    case datePickerAction.SET_DATE_PICKER_ON_RANGE:
      setDatePickerOnRange(state, action.payload);
      break;
    case datePickerAction.SET_DATE_PICKER_YEAR_LIST:
      setDatePickerYearList(state, action.payload);
      break;
    case datePickerAction.SET_DATE_PICKER_MIN_OR_MAX_DATE:
      setDatePickerMinOrMaxDate(state, action.payload);
      break;
    case datePickerAction.CHANGE_DATE_PICKER_TIME:
      changeDatePickerTime(state, action.payload);
      break;
    case datePickerAction.RESET_DATE_PICKER:
      resetDatePicker(state);
      break;
    case datePickerAction.RESET_DATE_PICKER_TIME_HTML_TEXT:
      resetDatePickerTimeHtmlText(state, action.payload);
      break;
    default:
      console.log(`Info: There's no action type`);
      break;
  }
}
