import { ScoringCriteria } from '../../core/bean/scoring-criteria';
import { ScoringCriteriaGroup } from '../../core/bean/scoring-criteria-group';
import { ModuleScoringCriteria } from '../../core/bean/module-scoring-criteria';

export class ScoringCriteriaGroupResponse {
    scoringCriteriaListByScoringCriteriaGroup: ScoringCriteria[];
    scoringCriteriaListNotByScoringCriteriaGroup: ScoringCriteria[];
    moduleScoringCriteriaList: ModuleScoringCriteria[];
    scoringCriteriaGroup: ScoringCriteriaGroup;
}


