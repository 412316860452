import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppComboBoxComponent } from './app-combo-box.component';
@NgModule({
  imports: [VendorModule, AppTooltipWrapperModule, PipesModule, DirectivesModule],
  declarations: [AppComboBoxComponent],
  exports: [AppComboBoxComponent]
})
export class AppComboBoxModule {}
