<app-popup header="rfq.popup.title.rfqResponse" [isLoading]="formLoading">
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    novalidate
    *ngIf="!formLoading"
  >
    <h4 class="separator">{{
      'rfq.popup.separator.vendorInformation' | translate
    }}</h4>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'rfq.popup.form.vendorName' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{ rfqVendor.vendor.name }}
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">{{
        'rfq.popup.form.responseDate' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          rfqVendor.submittedDate
            | date: global.config.parameterModel.datetimeFormatShort
        }}
      </div>
    </div>

    <h4 class="separator">{{
      'rfq.popup.separator.responseDocument' | translate
    }}</h4>
    <app-upload-x formControlName="file" [fileUploader]="fileUploader">
    </app-upload-x>

    <div
      *ngIf="
        !formLoading &&
        rfpId &&
        rfqVendor.rfq?.rfqStatus?.code !==
          global.appConstant.pm.RFQ_STATUS_WAITING_RESPONSE &&
        rfqVendor.rfq?.rfqStatus?.code !==
          global.appConstant.pm.RFQ_STATUS_COMPLETED &&
        ((todo === 'add' &&
          rfqPopupVendorViewScoreResponse?.rfpProposalCriteriaList?.length >
            0) ||
          (todo !== 'add' && rfqVendor?.rfqVendorScoringList?.length > 0))
      "
    >
      <h4 class="separator">{{
        'rfq.popup.separator.proposalCriteria' | translate
      }}</h4>
      <app-fullfilled *ngIf="todo !== 'view'"></app-fullfilled>
      <app-table-xx
        #selectorProposalCriteria
        [model]="
          todo === 'add'
            ? tableResponseProposalCriteria
            : todo === 'edit'
            ? tableResponseProposalCriteriaEdit
            : tableResponseProposalCriteriaView
        "
        [isShowPagination]="false"
        [isShowSearch]="false"
        (onChange)="onChangeScore($event)"
        *ngIf="!formLoading"
      >
        <ng-template #row>
          <tr>
            <td class="text-left">
              {{ 'rfq.popup.table.row.totalProposalCriteria' | translate }}
            </td>
            <td></td>
            <td></td>
            <td>
              <div class="td-content text-right">{{
                totalProposalCriteria | resolveNumberToDecimal
              }}</div>
              <div class="td-content text-right"
                ><em class="far fa info-circle"></em>&nbsp;{{
                  'rfq.popup.table.row.minWeightScore' | translate
                }}
                {{ rfqPopupVendorViewScoreResponse.rfp.minimumScore }}&nbsp;{{
                  'rfq.popup.row.toPassed' | translate
                }}</div
              >
            </td>
          </tr>
        </ng-template>
      </app-table-xx>

      <h4 class="separator">{{
        'rfq.popup.separator.calculation' | translate
      }}</h4>
      <table
        class="table table-striped box-shadow"
        aria-describedby="Table"
        *ngIf="!formLoading"
      >
        <thead>
          <tr>
            <th scope="col">
              <div class="td-content text-center"
                >{{ 'rfq.popup.table.row.proposalPoint' | translate }} ({{
                  rfqPopupVendorViewScoreResponse.rfp.proposalPercentage
                }}%)</div
              >
            </th>
            <th scope="col">
              <div class="td-content text-center"
                >{{ 'rfq.popup.table.row.pricePoint' | translate }} ({{
                  rfqPopupVendorViewScoreResponse.rfp.pricePercentage
                }}%)</div
              >
            </th>
            <th scope="col">
              <div class="td-content text-center">{{
                'rfq.popup.table.row.totalPoint' | translate
              }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="td-content text-center">{{
              (proposalPoint | resolveNumberToDecimal) || '-'
            }}</td>
            <td class="td-content text-center">{{
              (pricePoint | resolveNumberToDecimal) || '-'
            }}</td>
            <td class="td-content text-center">{{
              (totalPoint | resolveNumberToDecimal) || '-'
            }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h4 class="separator">{{
      'rfq.popup.separator.itemRequest' | translate
    }}</h4>
    <app-table-xx
      #selectorItem
      [model]="tableResponseRfqVendorItem"
      (onClick)="doViewItem($event)"
      [isShowPagination]="true"
      *ngIf="!formLoading"
    >
      <ng-template #headerFilter>
        <div class="form-group row text-sm-left">
          <div class="col text-left" [ngStyle]="{ 'min-width': '10rem' }">
            <app-dropdown-select
              size="XL"
              formControlName="itemType"
              type="COMBOBOX"
              [optionList]="itemTypeOptionList"
              (onChange)="onChangeItem()"
              container="body"
              placeholder="rfq.popup.filter.placeholder.type"
            ></app-dropdown-select>
          </div>
        </div>
      </ng-template>
      <ng-container
        *ngIf="tableResponseRfqVendorItem.getRecordList().length > 0"
      >
        <ng-template #row>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right" colspan="3">
              {{ 'rfq.popup.table.footer.goodsAmount' | translate }}
            </td>
            <td colspan="99" class="text-right">
              <strong>
                {{ formGroup.value.goodsAmount | resolveNumberToCurrency }}
                IDR
              </strong>
            </td>
          </tr>

          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right" colspan="3">
              {{ 'rfq.popup.table.footer.serviceAmount' | translate }}
            </td>
            <td colspan="99" class="text-right">
              <strong>
                {{ formGroup.value.serviceAmount | resolveNumberToCurrency }}
                IDR
              </strong>
            </td>
          </tr>

          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right" colspan="3">
              {{ 'rfq.popup.table.footer.amount' | translate }}
            </td>
            <td colspan="99" class="text-right">
              <strong>
                {{ formGroup.value.amount | resolveNumberToCurrency }}
                IDR
              </strong>
            </td>
          </tr>
        </ng-template>
      </ng-container>
    </app-table-xx>

    <ng-container *ngIf="todo !== 'view'">
      <ng-template #modalFooter let-activeModal>
        <div class="col-12 text-center">
          <app-button
            color="SECONDARY"
            [outline]="true"
            (onClick)="activeModal.close(true)"
            >{{ 'app.button.cancel' | translate }}</app-button
          >
          <app-button color="PRIMARY" (onClick)="doSave()">{{
            'app.button.save' | translate
          }}</app-button>
        </div>
      </ng-template>
    </ng-container>
  </form>
</app-popup>
