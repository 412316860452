import { BaseEntity } from '../base/base-entity';
import { BuildingLocation } from '../bean/building-location';
import { BuildingType } from '../bean/building-type';
import { Region } from '../bean/region';
import { ResourceOwnership } from '../bean/resource-ownership';
import { VendorHistory } from '../bean/vendor-history';

export class VendorAddressHistoryEntity extends BaseEntity {
  phoneNumber: string;
  sizeUom: string;
  crudInfo: string;
  countryRegion: Region;
  provinceRegion: Region;
  cityRegion: Region;
  districtRegion: Region;
  phoneRegion: Region;
  faxRegion: Region;
  buildingType: BuildingType;
  buildingLocation: BuildingLocation;
  vendorHistory: VendorHistory;
  resourceOwnership: ResourceOwnership;
  createdDate: Date;
  buildingName: string;
  size: number;
  address: string;
  postCode: Region;
  phone: string;
  fax: string;
  poBox: string;
  isMain: boolean;
  coordinateLocation: string;
  vendorAddressId: number;
}
