import { BaseEntity } from '../base/base-entity';
import { Pq } from '../bean/pq';
import { Procurement } from '../bean/procurement';

export class ProcurementPqEntity extends BaseEntity {
  crudOperation: number;

  procurement: Procurement = new Procurement();
  pq: Pq = new Pq();
}
