import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ProposalCriteriaEditAddComponent } from './proposal-criteria-edit-add.component';
import { ProposalCriteriaComponent } from './proposal-criteria.component';

const routes = [
  {
    path: '',
    component: ProposalCriteriaComponent,
    data: { breadcrumb: '' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: ProposalCriteriaEditAddComponent,
    data: { breadcrumb: 'proposal-criteria.breadcrumb.add' }
  },
  {
    path: 'edit',
    component: ProposalCriteriaEditAddComponent,
    data: { breadcrumb: 'proposal-criteria.breadcrumb.detail' }
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [ProposalCriteriaComponent, ProposalCriteriaEditAddComponent]
})
export class ProposalCriteriaModule {}
