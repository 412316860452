<div class="row main-row">
  <div class="col-12">
    <app-card [isLoading]="formLoading" header="view-vendor.vendorDetail">
      <div *ngIf="!formLoading" class="vendor-banner">
        <h4 class="vendor-banner-title px-3 py-2">{{
          viewVendorDTO?.vendorAddress.vendor?.vendorEntity.name +
            ' ' +
            viewVendorDTO?.vendorAddress.vendor.name
        }}</h4>
        <div class="vendor-banner-image">
          <img
            [attr.src]="
              global.config.BACKEND_ADDRESS +
                '/view-vendor/file/view/' +
                viewVendorDTO?.vendorAddress.vendor?.bannerFile
                  ?.uploadedFileName +
                '/' + global.appConstant.fileType.IMG_LOGO_VENDOR
                | resolveImgSrc
                | async
            "
            alt="banner"
            *ngIf="viewVendorDTO?.vendorAddress.vendor.bannerFile"
            width="auto"
            height="auto"
          />
          <h6
            class="text-center mb-5"
            *ngIf="!viewVendorDTO?.vendorAddress.vendor.bannerFile"
          ></h6>
        </div>
      </div>

      <div class="vendor-card py-4 my-4">
        <div class="row">
          <div class="col-6 vendor-card-left">
            <div class="col-3 vendor-card-left-image">
              <img
                [attr.src]="
                  global.config.BACKEND_ADDRESS +
                    '/view-vendor/file/view/' +
                    viewVendorDTO?.vendorAddress.vendor?.logoFile
                      ?.uploadedFileName +
                    '/' + global.appConstant.fileType.IMG_LOGO_VENDOR
                    | resolveImgSrc
                    | async
                "
                *ngIf="viewVendorDTO?.vendorAddress.vendor?.logoFile"
                alt="Vendor Image"
                width="auto"
                height="auto"
              />
              <em
                *ngIf="!viewVendorDTO?.vendorAddress.vendor?.logoFile"
                class="fas fa-user"
              ></em>
            </div>
            <div *ngIf="!formLoading" class="col-9 vendor-detail">
              <h6>{{
                viewVendorDTO?.vendorAddress.vendor?.vendorEntity.name +
                  '
                ' +
                  viewVendorDTO?.vendorAddress.vendor.name
              }}</h6>
              <p class="vendor-location">
                <em class="fas fa-map-marker"></em>
                {{
                  viewVendorDTO?.vendorAddress.buildingName
                    ? viewVendorDTO?.vendorAddress.buildingName + ', '
                    : ''
                }}
                {{
                  viewVendorDTO?.vendorAddress.address +
                    ', ' +
                    viewVendorDTO?.vendorAddress.cityRegion?.name +
                    ', ' +
                    viewVendorDTO?.vendorAddress.provinceRegion?.name +
                    ' ' +
                    viewVendorDTO?.vendorAddress.postCode
                }}
              </p>
              <div class="d-flex">
                <em
                  class="far fa-envelope mr-3 cursor-pointer"
                  [ngStyle]="{ 'font-size': '1.4rem' }"
                  title="{{ 'view-vendor.composeEmail' | translate }}"
                  (click)="doOpenPopupEmail()"
                  (keyup)="onKeyUp($event)"
                  (keydown)="onKeyDown($event)"
                ></em>
                <em
                  class="far fa-map cursor-pointer"
                  [ngStyle]="{ 'font-size': '1.4rem' }"
                  title="{{ 'view-vendor.location' | translate }}"
                  (click)="doOpenPopupMap()"
                  (keyup)="onKeyUp($event)"
                  (keydown)="onKeyDown($event)"
                ></em>
              </div>
            </div>
          </div>
          <div class="col-6 vendor-card-right my-4">
            <h6>{{ 'view-vendor.rating' | translate }}</h6>
            <h2 class="my-2">
              <span class="mr-2">{{
                viewVendorDTO?.vendorPerformanceOverall?.rating || '0'
                  | number: '1.2-2'
              }}</span>
              <app-rating
                [value]="viewVendorDTO?.vendorPerformanceOverall?.rating || 0"
                [isViewOnly]="true"
              ></app-rating>
            </h2>
          </div>
        </div>
      </div>

      <app-table-catalog
        [model]="tableCatalogResponse"
        [isShowFilter]="false"
        (onChooseItem)="onChooseCatalogItem($event)"
        header="view-vendor.allProducts"
      ></app-table-catalog>

      <br />
      <div class="button-group button-group-center">
        <app-button mode="BYPASS" color="SECONDARY" (onClick)="doBack()">
          {{ 'app.button.back' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>
