<app-popup
  [header]="header"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div [ngStyle]="{ display: 'flex', 'justify-content': 'center' }">
      <app-signature
        formControlName="signatureImg"
        [model]="model"
        (onChange)="onChangeBlank($event)"
      ></app-signature>
    </div>
    <div [ngStyle]="{ display: 'flex', 'margin-left': '10px' }">
      <app-check-box formControlName="isCheck"></app-check-box>
      <label class="white-space-normal"
        >{{ 'app-popup-signature.information.msg' | translate }}&nbsp;{{
          stages
        }}</label
      >
    </div>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center">
      <app-button
        color="LIGHT"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button
        (onClick)="doSubmit()"
        [disabled]="!formGroup.value.isCheck || isBlank"
        >{{ 'app.button.submit' | translate }}</app-button
      >
    </div>
  </ng-template>
</app-popup>
