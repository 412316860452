import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SidebarRightComponent } from './sidebar-right.component';
import { SidebarRightService } from './sidebar-right.service';

@NgModule({
  imports: [CommonModule],
  declarations: [SidebarRightComponent],
  providers: [SidebarRightService],
  exports: [SidebarRightComponent]
})
export class SidebarRightModule {
  public static forRoot(): ModuleWithProviders<SidebarRightModule> {
    return {
      ngModule: SidebarRightModule,
      providers: [SidebarRightService]
    };
  }

  // public static forChild(
  //   options?: object
  // ): ModuleWithProviders<SidebarRightModule> {
  //   return {
  //     ngModule: SidebarRightModule,
  //     providers: [SidebarRightService],
  //   };
  // }
}
