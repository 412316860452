import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { PrItem } from 'src/app/core/bean/pr-item';
import { AppPopupChooseBudgetXService } from 'src/app/core/components/app-popup/app-popup-choose-budget-x/app-popup-choose-budget-x.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { TabModel } from 'src/app/core/model/tab/tab-model';
import { TabResponseModel } from 'src/app/core/model/tab/tab-response-model';
import { File } from '../../core/bean/file';
import { QuotationCriteriaStatus } from '../../core/bean/quotation-criteria-status';
import { SubmitQuotationVendorResponse } from './submit-quotation-vendor.response';

@Component({
  templateUrl: './submit-quotation-vendor-technical-criteria.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SubmitQuotationVendorTechnicalCriteriaComponent
  extends BaseModuleComponent
  implements OnInit
{
  public documentList = [];
  public submitQuotationResponse: SubmitQuotationVendorResponse =
    new SubmitQuotationVendorResponse();

  public documentOptionList: OptionListModel<File> = new OptionListModel(
    false,
    'fileName'
  );
  public prItem: PrItem = new PrItem();
  public autoCompleteValue: any;
  public isBudgetValue: boolean;
  public fileUploaderList: FileUploader[] = [];
  public fileUploader: FileUploader = new FileUploader(
    '/rfp/',
    '',
    'DOC_RFP_TEMPLATE'
  );
  public tabResponse: TabResponseModel;
  public tabTwo: TabModel;
  public backToUrl: string;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupChooseBudgetService: AppPopupChooseBudgetXService
  ) {
    super('submit-quotation-vendor', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.setOptionModel();
    this.setStateReady();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.tabResponse = this.global.routerParams.get('tabResponse');
    this.backToUrl = this.global.routerParams.get('backToUrl');
    this.tabTwo = this.tabResponse.currentTab;
    this.submitQuotationResponse = this.global.routerParams.get(
      'submitQuotationResponse'
    );
    this.global.routerParams.set(
      'tecQuotationCriteria',
      this.submitQuotationResponse.tecQuotationCriteriaList
    );
  }

  public setOptionModel(): void {
    if (this.submitQuotationResponse.tecFileList.length > 0) {
      this.documentOptionList.setRequestValues(
        this.submitQuotationResponse.tecFileList.map(
          fileObject => fileObject.file
        )
      );
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      quotationCriteriaList: this.formBuilder.array([])
    });
    this.buildFormArray();
  }

  public buildFormArray(): void {
    this.submitQuotationResponse.tecFileList = [];
    this.submitQuotationResponse.tecQuotationCriteriaList.forEach(
      (data, index) => {
        this.quotationCriteriaList.push(this.newQuotationCriteria());
        const fileUpload: FileUploader = new FileUploader(
          '/rfp/',
          '',
          'DOC_RFP_TEMPLATE'
        );
        this.fileUploaderList.push(fileUpload);
        if (data.docFile) {
          const fileList = [];
          fileList.push(data.docFile);
          fileUpload.setFileList(fileList);
        }
        if (
          data.fileObjectList &&
          data.fileObjectList.length > 0 &&
          !data.fileObjectList[0].isDeleted
        ) {
          fileUpload.fileObjectList = data.fileObjectList;
        }
        if (
          fileUpload.fileObjectList &&
          fileUpload.fileObjectList.length > 0 &&
          !fileUpload.fileObjectList[0].isDeleted
        ) {
          if (
            (this.submitQuotationResponse.tecFileList.length > 0 &&
              this.submitQuotationResponse.tecFileList.filter(
                fileObject =>
                  fileObject.file.id === fileUpload.fileObjectList[0].file.id
              ).length === 0) ||
            this.submitQuotationResponse.tecFileList.length === 0
          ) {
            this.submitQuotationResponse.tecFileList.push(
              fileUpload.fileObjectList[0]
            );
          }
        }
        this.quotationCriteriaList.controls[index].patchValue({
          name: data.procurementCriteriaDetail.evaluationCriteria.name,
          value: data.value,
          note: data.note,
          isNote: data.note ? true : false,
          docFile: fileUpload.fileObjectList,
          document:
            fileUpload.fileObjectList &&
            fileUpload.fileObjectList.length > 0 &&
            fileUpload.fileObjectList[0].file
              ? fileUpload.fileObjectList[0].file
              : null
        });
      }
    );
  }

  public get quotationCriteriaList(): FormArray {
    return this.formGroup.get('quotationCriteriaList') as FormArray;
  }

  public newQuotationCriteria(): FormGroup {
    const formGroup = this.formBuilder.group({
      id: [null],
      name: [null],
      docFile: [null],
      document: [null],
      isNote: [null],
      note: [null],
      value: [null]
    });
    if (
      this.todo === 'view' ||
      this.submitQuotationResponse.procurement.documentSubmissionMethod
        ?.code === this.global.appConstant.pm.DOCUMENT_SUBMISSION_METHOD_STAGE_2
    ) {
      formGroup.setIsView(true);
    }
    return formGroup;
  }

  public onChange(index: number): void {
    const quotationCriteriaStatusCompleted = new QuotationCriteriaStatus();
    quotationCriteriaStatusCompleted.code = 'COMPLETED';

    const quotationCriteriaStatusWaiting = new QuotationCriteriaStatus();
    quotationCriteriaStatusWaiting.code = 'WAITING';

    const quotationCriteria = this.quotationCriteriaList.controls[index].value;
    if (quotationCriteria.docFile && quotationCriteria.docFile.length > 0) {
      if (!this.submitQuotationResponse.tecFileList) {
        this.submitQuotationResponse.tecFileList = [];
      }
      if (!quotationCriteria.docFile[0].isDeleted) {
        this.submitQuotationResponse.tecQuotationCriteriaList[
          index
        ].quotationCriteriaStatus = quotationCriteriaStatusCompleted;
        if (
          !this.submitQuotationResponse.tecFileList.find(
            x => x.file === quotationCriteria.docFile[0].file
          )
        ) {
          this.submitQuotationResponse.tecFileList.push(
            quotationCriteria.docFile[0]
          );
        }
        this.quotationCriteriaList.controls[index].patchValue({
          document: quotationCriteria.docFile[0].file
        });
      } else {
        this.submitQuotationResponse.tecQuotationCriteriaList[
          index
        ].quotationCriteriaStatus = quotationCriteriaStatusWaiting;
        this.quotationCriteriaList.controls[index].patchValue({
          document: null
        });
      }
    } else {
      if (!quotationCriteria.value) {
        this.submitQuotationResponse.tecQuotationCriteriaList[
          index
        ].quotationCriteriaStatus = quotationCriteriaStatusWaiting;
        if (
          this.submitQuotationResponse.tecQuotationCriteriaList[index]
            .procurementCriteriaDetail.evaluationCriteria.isUploadDoc
        ) {
          this.quotationCriteriaList.controls[index].patchValue({
            document: null
          });
        }
      }
    }

    if (quotationCriteria.isNote) {
      this.submitQuotationResponse.tecQuotationCriteriaList[index].note =
        quotationCriteria.note;
    } else {
      this.quotationCriteriaList.controls[index].patchValue({
        note: null
      });
    }

    this.submitQuotationResponse.tecQuotationCriteriaList[index].note =
      quotationCriteria.note;
    this.submitQuotationResponse.tecQuotationCriteriaList[index].value =
      quotationCriteria.value;
    this.submitQuotationResponse.tecQuotationCriteriaList[
      index
    ].fileObjectList = quotationCriteria.docFile;
    this.submitQuotationResponse.tecQuotationCriteriaList[index].docFile = null;

    if (this.submitQuotationResponse.tecFileList.length > 0) {
      this.documentOptionList.setRequestValues(
        this.submitQuotationResponse.tecFileList.map(
          fileObject => fileObject.file
        )
      );
    }
    this.global.routerParams.set(
      'submitQuotationResponse',
      this.submitQuotationResponse
    );
    this.global.routerParams.set(
      'tecQuotationCriteria',
      this.submitQuotationResponse.tecQuotationCriteriaList
    );
  }

  public onChangeSelectDocument(index: number): void {
    const quotationCriteriaStatusCompleted = new QuotationCriteriaStatus();
    quotationCriteriaStatusCompleted.code = 'COMPLETED';

    const quotationCriteriaStatusWaiting = new QuotationCriteriaStatus();
    quotationCriteriaStatusWaiting.code = 'WAITING';

    const quotationCriteria = this.quotationCriteriaList.controls[index].value;

    if (quotationCriteria.document) {
      const fileList = [];
      fileList.push(quotationCriteria.document);
      this.fileUploaderList[index].setFileList(fileList);
      quotationCriteria.docFile = this.fileUploaderList[index].fileObjectList;
      this.quotationCriteriaList.controls[index]
        .get('docFile')
        .patchValue(quotationCriteria.docFile);
      this.submitQuotationResponse.tecQuotationCriteriaList[
        index
      ].quotationCriteriaStatus = quotationCriteriaStatusCompleted;
    } else {
      this.submitQuotationResponse.tecQuotationCriteriaList[
        index
      ].quotationCriteriaStatus = quotationCriteriaStatusWaiting;
    }

    this.submitQuotationResponse.tecQuotationCriteriaList[index].value =
      quotationCriteria.value;
    this.submitQuotationResponse.tecQuotationCriteriaList[
      index
    ].fileObjectList = quotationCriteria.docFile;
    this.submitQuotationResponse.tecQuotationCriteriaList[index].docFile = null;

    if (this.submitQuotationResponse.tecFileList.length > 0) {
      this.documentOptionList.setRequestValues(
        this.submitQuotationResponse.tecFileList.map(
          fileObject => fileObject.file
        )
      );
    }
    this.global.routerParams.set(
      'submitQuotationResponse',
      this.submitQuotationResponse
    );
    this.global.routerParams.set(
      'tecQuotationCriteria',
      this.submitQuotationResponse.tecQuotationCriteriaList
    );
  }
}
