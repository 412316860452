import { FileType } from '../bean/file-type';
import { BaseEntity } from './../base/base-entity';

export class FileEntity extends BaseEntity {
  fileType: FileType;
  uploadedFileName: string;
  fileName: string;
  size: number;
  extension: string;
  isImage: boolean;
}
