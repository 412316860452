import { Component } from '@angular/core';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetRFXTotalTransactionResponse } from './widget-rfx-total-transaction-response';

@Component({
  templateUrl: './widget-rfx-total-transaction.component.html'
})
export class WidgetRfxTotalTransactionComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-rfx-total-transaction';
  public routerParamFilter = {
    totalRfi: 'RFI',
    totalRfp: 'RFP',
    totalRfq: 'RFQ'
  };

  public totalRFX: number;
  public rfxDataList: Array<object>;
  constructor() {
    super(WidgetRfxTotalTransactionComponent.moduleCode);
  }

  protected onInit(): void {
    this.httpClientService
      .get<WidgetRFXTotalTransactionResponse>(
        '/widget-rfx-total-transaction/index'
      )
      .subscribe((response: WidgetRFXTotalTransactionResponse) => {
        this.totalRFX = response.totalRfx;
        this.rfxDataList = Object.keys(response)
          .map((key: string) => {
            if (this.routerParamFilter[key]) {
              const strKey = key.slice(5);
              return {
                value: response[key],
                filter: this.routerParamFilter[key],
                translationKey:
                  this.moduleCode +
                  '.' +
                  strKey.charAt(0).toLowerCase() +
                  strKey.slice(1)
              };
            }
          })
          .filter(val => val);
        this.setStateReady();
      });
  }

  public doNavigateToIndex(data: any): void {
    if (data) {
      this.global.routerParams.set('isFromWidget', true);
      if (data.filter === 'RFI') {
        this.router.navigate(['/pages/response-rfi']);
      } else if (data.filter === 'RFP') {
        this.router.navigate(['/pages/rfp-response']);
      } else if (data.filter === 'RFQ') {
        this.router.navigate(['/pages/rfq-response']);
      }
    }
  }
}
