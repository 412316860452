import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { FaqComponent } from './faq.component';
const routes: Routes = [{ path: '', component: FaqComponent }];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [FaqComponent]
})
export class FaqModule {}
