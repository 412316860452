import { Component, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Claim } from 'src/app/core/bean/claim';
import { ClaimStatus } from 'src/app/core/bean/claim-status';
import { ClaimVerification } from 'src/app/core/bean/claim-verification';
import { File } from 'src/app/core/bean/file';
import { WorkflowPrcs } from 'src/app/core/bean/workflow-prcs';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { Validators } from 'src/app/core/validators';
import { ClaimDocMaster } from '../../core/bean/claim-doc-master';
import { ClaimVerificationRequest } from './claim-verification.request';
import { ClaimVerificationResponse } from './claim-verification.response';

@Component({
  templateUrl: './claim-verification-edit-add-xt.component.html'
})
export class ClaimVerificationEditAddXtComponent
  extends BaseModuleComponent
  implements OnInit {
  public claimId: number;
  public objectId: number;
  public approvalModelPrcsId: number;
  public claimVerificationResponse: ClaimVerificationResponse =
    new ClaimVerificationResponse();
  public claimVerificationRequest: ClaimVerificationRequest =
    new ClaimVerificationRequest();
  public claimStatus: ClaimStatus = new ClaimStatus();
  public workflowPrcs: WorkflowPrcs;
  public isSubmit = false;
  public urlBackOutside: string;
  public currentDate: Date = new Date();
  public token: string;
  public uuid: string;
  public isUserAvailable = false;
  public statusOptionList: OptionListModel<any> = new OptionListModel();
  public tableResponse: TableResponseModel<ClaimVerification>;
  public fileUploaderList: FileUploader[] = new Array();

  public fileUploader: FileUploader = new FileUploader(
    '/claim-verification/',
    'claim.form.headerImage',
    this.global.appConstant.fileType.DOC_KLAIM_OTHER,
    false,
    10
  );

  constructor(
    translateService: TranslateService,
    public snackbarService: SnackbarService,
    public toastService: ToastService,
    public activatedRoute: ActivatedRoute
  ) {
    super('claim-verification', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    this.activatedRoute.paramMap.subscribe(
      param => (this.uuid = param.get('uuid'))

    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else if (this.uuid) {
      this.setInitializationStateFromUUID();
    } else {
      this.setInitializationState();
    }
    this.buildTableResponse();
  }

  public setInitializationStateFromUUID(): void {

    this.httpClientService

      .get<Claim>('/claim-verification/detail/tab/' + this.uuid)

      .subscribe((claim: Claim) => {

        this.global.routerParams.clear();

        this.global.routerParams.set('claimId', claim.id);

        this.global.routerParams.set('claimStatus', claim.claimStatus);

        this.global.routerParams.set(

          'urlBackOutside',

          '/pages/claim-verification'

        );

        if (claim.claimStatus.code != this.global.appConstant.pm.CLAIM_STATUS_WAITING_MANUAL_VERIFICATION) {

          this.global.routerParams.set('todo', 'view');

        } else {

          this.global.routerParams.set('todo', 'detail');

        }

        this.router.navigate(['/pages/claim-verification/detail']);

      });

  }
  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Claim>('/claim-verification/notification/' + this.token)
      .subscribe((claim: Claim) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('claimId', claim.id);
        this.global.routerParams.set('claimStatus', claim.claimStatus);
        this.global.routerParams.set(
          'urlBackOutside',
          '/pages/claim-verification'
        );
        if (
          claim.claimStatus.code ===
          this.global.appConstant.pm.CLAIM_STATUS_WAITING_VERIFICATION &&
          claim.glStatus === this.global.appConstant.pm.GL_STATUS_ACTIVE
        ) {
          this.global.routerParams.set('todo', 'detail');
          this.router.navigate(['/pages/claim-verification/detail']);
        } else {
          this.router.navigate(['/pages/claim-verification']);
        }
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'processedByUser.name', header: 'table.column.admin' },
      {
        field: 'note',
        header: 'table.column.note',
        customClass: 'white-space-normal'
      },
      {
        field: 'processedDate',
        header: 'table.column.date',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'claimStatus.name',
        header: 'table.column.status',
        customClass: 'text-center'
      }
    ]);
  }

  public setInitializationState(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.claimId = this.global.routerParams.get('claimId');
    this.claimStatus = this.global.routerParams.get('claimStatus');
    this.claimVerificationResponse = this.global.routerParams.get(
      'claimVerificationResponse'
    );
    this.objectId = this.global.routerParams.get('objectId');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    if (this.objectId) {
      this.claimId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
    this.formGroup.controls.fileObjectList.setIsView(true);
    this.formArrayDocClaimList.controls.forEach(formGroup => {
      formGroup.setIsView(true);
    });
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      hospitalCode: [null, Validators.required()],
      hospitalName: [null, Validators.required()],
      glNumber: [null, Validators.required()],
      accidentVictim: [null, Validators.required()],
      description: [null],
      documentList: [null],
      fileObjectList: [null],
      verificator: [null],
      counter: [null],
      glStatus: [null],
      claimStatus: [null, Validators.required()],
      note: [null],
      docClaimList: this.formBuilder.array([]),
      totalRequest: [null],
      doctor: [null],
      medicine: [null],
      medicalTools: [null],
      administration: [null],
      room: [null],
      totalP3k: [null],
      totalAmbulance: [null],
      totalCompensationRequest: [null],
      roomType: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ClaimVerificationResponse>(
        '/claim-verification/edit',
        new RouteRequestModel(this.todo, this.claimId)
      )
      .subscribe((claimVerificationResponse: ClaimVerificationResponse) => {
        if (!this.claimVerificationResponse) {
          this.claimVerificationResponse = claimVerificationResponse;
        }

        if (this.claimVerificationResponse.claim) {
          this.fileUploader.setFileList(
            claimVerificationResponse.fileOtherList
          );
          this.workflowPrcs =
            this.claimVerificationResponse?.claim?.workflowPrcs;
          this.statusOptionList.setRequestValues(
            this.claimVerificationResponse.claimStatusList
          );
          this.formGroup.patchValue({
            hospitalCode: this.claimVerificationResponse.claim.hospitalCode,
            hospitalName: this.claimVerificationResponse.claim.hospitalName,
            glNumber: this.claimVerificationResponse.claim.glNumber,
            accidentVictim: this.claimVerificationResponse.claim.accidentVictim,
            description: this.claimVerificationResponse.claim.description,
            fileObjectList: this.fileUploader.fileObjectList,
            verificator: this.claimVerificationResponse.verificator.name,
            counter: this.claimVerificationResponse.counter.name,
            glStatus: this.claimVerificationResponse.glStatus.name
          });
        }

        if (this.claimVerificationResponse.claimRequestAmount) {
          this.formGroup.patchValue({
            totalRequest: this.claimVerificationResponse.claimRequestAmount.totalRequest,
            doctor: this.claimVerificationResponse.claimRequestAmount.doctor,
            medicine: this.claimVerificationResponse.claimRequestAmount.medicine,
            medicalTools: this.claimVerificationResponse.claimRequestAmount.medicalTools,
            administration: this.claimVerificationResponse.claimRequestAmount.administration,
            room: this.claimVerificationResponse.claimRequestAmount.room,
            totalP3k: this.claimVerificationResponse.claimRequestAmount.totalP3k,
            totalAmbulance: this.claimVerificationResponse.claimRequestAmount.totalAmbulance,
            roomType: this.claimVerificationResponse.claimRequestAmount.roomType ? this.claimVerificationResponse.claimRequestAmount.roomType.name : '-',
            totalCompensationRequest: this.claimVerificationResponse.claimRequestAmount.totalCompensationRequest,
          });
        }

        if (this.claimVerificationResponse.executorAssignment?.id) {
          this.isUserAvailable = true;
        }
        if (this.claimVerificationResponse.claimVerificationList.length > 0 && this.todo == 'view') {
          const firstClaimVerif = this.claimVerificationResponse.claimVerificationList[0];
          this.formGroup.patchValue({ claimStatus: firstClaimVerif.claimStatus, note: firstClaimVerif.note });
        }
        this.tableResponse.setRecords(
          this.claimVerificationResponse.claimVerificationList
        );
        this.tableResponse.setTotalRecords(
          this.claimVerificationResponse.claimVerificationList
            ? this.claimVerificationResponse.claimVerificationList.length
            : 0
        );
        this.tableResponse.reloadClient();
        this.buildFileUploader(claimVerificationResponse.claimDocMasterList);
        this.setFileUploader(
          claimVerificationResponse.fileList,
          claimVerificationResponse.claimDocMasterList
        );
        this.setStateReady();
      });
  }

  public doSubmit(): void {
    const claimStatus = this.formGroup.get('claimStatus').value;
    if (claimStatus !== null) {
      if (
        claimStatus.code === this.global.appConstant.pm.CLAIM_STATUS_REVISION
      ) {
        if (
          this.formGroup.get('note').value === null ||
          this.formGroup.get('note').value === ''
        ) {
          this.formGroup.get('note').setValidators(Validators.required());
          this.formGroup.updateValueAndValidity();
          this.formGroup.get('note').setErrors({
            message: this.translateService.instant('app.validation.required')
          });
        } else {
          this.formGroup.get('note').clearValidators();
        }
      } else {
        this.formGroup.get('note').clearValidators();
      }
    }
    this.validate();
    if (this.formGroup.valid && this.isUserAvailable) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.claimVerificationRequest.claim = this.formGroup.value;
            this.claimVerificationRequest.claim.id = this.claimId;
            this.claimVerificationRequest.fileObjectList =
              this.formGroup.get('fileObjectList').value;
            this.claimVerificationRequest.claimVerification =
              new ClaimVerification();
            this.claimVerificationRequest.claimVerification.claimStatus =
              this.formGroup.get('claimStatus').value;
            this.claimVerificationRequest.claimVerification.note =
              this.formGroup.get('note').value;
            this.setStateProcessing();
            this.httpClientService
              .post<Response<Claim>>(
                '/claim-verification/update',
                this.claimVerificationRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccess()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate(['/pages/claim-verification/']);
                      } else {
                        this.router
                          .navigateByUrl('/', { skipLocationChange: true })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set(
                              'claimId',
                              this.claimId
                            );
                            this.global.routerParams.set('todo', 'view');
                            this.router.navigate([
                              '/pages/claim-verification/detail'
                            ]);
                          });
                      }
                    });
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }
  public buildFileUploader(claimDocMasterList: ClaimDocMaster[]): void {
    claimDocMasterList.forEach(claimDocMaster => {
      const fileUploader: FileUploader = new FileUploader(
        '/claim/',
        claimDocMaster.fileType.translationKey?.key,
        claimDocMaster.fileType?.code,
        false,
        1
      );
      this.fileUploaderList.push(fileUploader);
    });
  }

  public setFileUploader(
    docFileClaimList: File[],
    claimDocMasterList: ClaimDocMaster[]
  ): void {
    const control = this.formGroup.controls.docClaimList as FormArray;
    claimDocMasterList.forEach((claimDocMaster, index) => {
      if (
        claimDocMaster.fileType.code !==
        this.global.appConstant.fileType.DOC_KLAIM_OTHER
      ) {
        docFileClaimList.forEach(docFileClaim => {
          if (claimDocMaster.fileType.code === docFileClaim.fileType.code) {
            this.fileUploaderList[index].setFile(docFileClaim);
          }
        });
        control.push(
          this.formBuilder.group({
            translationKey: claimDocMaster.fileType.translationKey,
            file: [this.fileUploaderList[index].fileObjectList]
          })
        );
      }
    });
    this.setIsViewArray();
  }

  public setIsViewArray(): void {
    this.formArrayDocClaimList.controls.forEach(formGroup => {
      formGroup.setIsView(true);
    });
  }

  public get formArrayDocClaimList(): FormArray {
    return this.formGroup.get('docClaimList') as FormArray;
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key || '-'
      );
    }
  }

  public doBack(): void {
    this.router.navigate(['/pages/claim-verification']);
  }
}
