import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { ProcurementCriteria } from '../../core/bean/procurement-criteria';
import { ProcurementFinalizationCriteria } from '../../core/bean/procurement-finalization-criteria';
import { QuotationEvaluation } from '../../core/bean/quotation-evaluation';
import { QuotationEvaluationDtl } from '../../core/bean/quotation-evaluation-dtl';
import { ScoringStatus } from '../../core/bean/scoring-status';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';
import { TechnicalEvaluationPopupRequest } from './technical-evaluation-popup-request';

@Component({
  templateUrl: './technical-evaluation-popup-detail.component.html'
})
export class TechnicalEvaluationPopupDetailComponent extends BaseComponentComponent {
  @Input() todo: string;
  @Input() quotationEvaluation: QuotationEvaluation;
  @Input() scoringStatusList: ScoringStatus[];
  @Input() procurementCriteria: ProcurementCriteria;
  @Input() procurementFinalizationCriteria: ProcurementFinalizationCriteria;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public tableResponse: TableResponseModel<QuotationEvaluationDtl>;
  public scoringStatusOptionList: OptionListModel<ScoringStatus> =
    new OptionListModel();
  public isFullFill = false;
  public isSave = false;
  public statusResult: ScoringStatus;

  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    super('technical-evaluation');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setFormGroup();
  }

  public getColorAndIconConfirm = {
    PASSED: { color: 'text-success', icon: 'pic pi-thumbs-up-circle' },
    NOT_PASSED: { color: 'text-danger', icon: 'pic pi-times-circle' }
  };

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      recordList: this.tableResponse.formArray
    });
  }

  public setFormGroup(): void {
    this.statusResult = this.quotationEvaluation.scoringStatus;
    if (
      this.statusResult &&
      this.statusResult.code !==
        this.global.appConstant.pm.SCORING_STATUS_WAITING_SCORING
    ) {
      this.isFullFill = true;
    }
    if (
      this.quotationEvaluation.quotationEvaluationDtlList &&
      this.quotationEvaluation.quotationEvaluationDtlList.length > 0
    ) {
      this.setTableResponse(
        this.quotationEvaluation.quotationEvaluationDtlList
      );
    } else {
      const request: TechnicalEvaluationPopupRequest =
        new TechnicalEvaluationPopupRequest();
      request.quotationId = this.quotationEvaluation.quotation.id;
      request.quotationEvaluationId = this.quotationEvaluation.id;
      this.httpClientService
        .post('/technical-evaluation/get-quotation-evaluation-dtl', request)
        .subscribe((response: QuotationEvaluationDtl[]) => {
          if (
            this.procurementCriteria.procurement.biddingEvaluation.code !==
            this.global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
          ) {
            response.forEach(quotationEvaluationDtl => {
              if (!quotationEvaluationDtl.maximumScore) {
                if (
                  this.procurementCriteria.procurement.biddingEvaluation
                    .code ===
                    this.global.appConstant.pm
                      .BIDDING_EVALUATION_CODE_MERIT_POINT &&
                  this.procurementCriteria.isAppliedFinalScoring
                ) {
                  quotationEvaluationDtl.maximumScore =
                    (+quotationEvaluationDtl.quotationCriteria
                      .procurementCriteriaDetail.score *
                      this.procurementFinalizationCriteria
                        .technicalPercentage) /
                    100;
                } else {
                  quotationEvaluationDtl.maximumScore =
                    +quotationEvaluationDtl.quotationCriteria
                      .procurementCriteriaDetail.score;
                }
              }
            });
          }
          this.setTableResponse(response);
        });
    }
    this.scoringStatusOptionList.setRequestValues(this.scoringStatusList);
  }

  public setTableResponse(response: QuotationEvaluationDtl[]): void {
    this.tableResponse.setRecordList(response);
    this.tableResponse.reload();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    if (
      this.procurementCriteria.procurement.biddingEvaluation.code ===
      this.global.appConstant.pm.BIDDING_EVALUATION_CODE_LEAST_COST_SYSTEM
    ) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field:
            'quotationCriteria.procurementCriteriaDetail.evaluationCriteria.name',
          header: 'table.column.technicalCriteria'
        },
        {
          field: 'quotationCriteria.note',
          header: 'table.column.noteFromVendor'
        },
        {
          field: 'quotationCriteria.docFile.fileName',
          header: 'table.column.value',
          plugins: [
            {
              name: 'hyperlink',
              onClick: this.doDownloadFile.bind(this),
              className: 'text-primary'
            },
            {
              name: 'custom-plugin',
              after: (tablePlugin: TablePluginData): void => {
                if (!tablePlugin.value) {
                  tablePlugin.element.innerText =
                    tablePlugin.row.record.quotationCriteria.value;
                }
              }
            }
          ]
        },
        {
          field: 'scoringStatus',
          header: 'table.column.status',

          plugins: {
            name: 'drop-down-select',
            optionList: this.scoringStatusOptionList,
            validators: Validators.required(),
            placeholder: 'technical-evaluation.table.column.placeholder.status',
            isView: !!(this.todo === 'view'),
            onChange: this.checkNoteValidation.bind(this),
            customValue: (value: any): string => {
              if (value) {
                return this.getStringCustomValue(value);
              } else {
                return null;
              }
            },
            customOption: (option: any): string => {
              return this.translateService.instant(
                this.getTranslateKey(option)
              );
            }
          }
        },
        {
          field: 'note',
          header: 'table.column.note',
          plugins: {
            name: 'text-field',
            isView: !!(this.todo === 'view'),
            onInput: this.checkNoteValidation.bind(this),
            placeholder: this.translateService.instant(
              'technical-evaluation.table.column.placeholder.note'
            )
          }
        }
      ]);
    } else {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field:
            'quotationCriteria.procurementCriteriaDetail.evaluationCriteria.name',
          header: 'table.column.technicalCriteria'
        },
        {
          field: 'quotationCriteria.note',
          header: 'table.column.noteFromVendor'
        },
        {
          field: 'quotationCriteria.docFile.fileName',
          header: 'table.column.value',
          plugins: [
            {
              name: 'hyperlink',
              onClick: this.doDownloadFile.bind(this),
              className: 'text-primary'
            },
            {
              name: 'custom-plugin',
              after: (tablePlugin: TablePluginData): void => {
                if (!tablePlugin.value) {
                  tablePlugin.element.innerText =
                    tablePlugin.row.record.quotationCriteria.value;
                }
              }
            }
          ]
        },
        {
          field: 'givenScore',
          header: 'table.column.givenScore',
          className: 'text-right',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            onInput: this.onChangeScore.bind(this),
            isView: !!(this.todo === 'view'),
            validators: Validators.max(100)
          }
        },
        {
          header: 'table.column.score',
          columnList: [
            {
              field: 'maximumScore',
              header: 'table.column.maximum',
              className: 'text-right',
              plugins: {
                name: 'text-field',
                type: 'decimal',
                isView: true
              }
            },
            {
              field: 'score',
              header:
                this.procurementCriteria.procurement.biddingEvaluation.code ===
                  this.global.appConstant.pm
                    .BIDDING_EVALUATION_CODE_MERIT_POINT &&
                this.procurementCriteria.isAppliedFinalScoring
                  ? 'table.column.weighted'
                  : 'table.column.final',
              className: 'text-right',
              plugins: {
                name: 'text-field',
                type: 'decimal',
                isView: true
              }
            }
          ]
        }
      ]);
    }
    this.buildFormGroup();
  }

  public doDownloadFile(quotationEvaluationDtl: QuotationEvaluationDtl): void {
    if (quotationEvaluationDtl?.quotationCriteria?.docFile) {
      const urlFile =
        '/technical-evaluation/file/view/' +
        quotationEvaluationDtl.quotationCriteria.docFile.uploadedFileName +
        '/' +
        quotationEvaluationDtl.quotationCriteria.docFile.fileType.code;
      this.downloadService.download(
        urlFile,
        quotationEvaluationDtl.quotationCriteria.docFile.fileName
      );
    }
  }

  public onChangeScore(): void {
    const currentRowChange =
      this.tableResponse.getRecordList()[
        this.tableResponse.currentRowChange.row.position - 1
      ];
    currentRowChange.givenScore =
      this.tableResponse.currentRowChange.row.formGroup.value.givenScore;

    if (
      !currentRowChange.givenScore.toString().includes('.') ||
      currentRowChange.givenScore.toString().lastIndexOf('.') !==
        currentRowChange.givenScore.toString().length - 1
    ) {
      const currentRowChange =
        this.tableResponse.getRecordList()[
          this.tableResponse.currentRowChange.row.position - 1
        ];
      currentRowChange.givenScore =
        +this.tableResponse.currentRowChange.row.formGroup.value.givenScore;
      currentRowChange.score =
        (currentRowChange.maximumScore * currentRowChange.givenScore) / 100;
      currentRowChange.givenScore =
        this.tableResponse.currentRowChange.row.formGroup.value.givenScore ===
        ''
          ? null
          : currentRowChange.givenScore;
      const indeks = this.tableResponse.currentRowChange.row.position - 1;
      this.tableResponse.getRecordList()[indeks] = currentRowChange;
      this.tableResponse.formArray.controls[indeks].patchValue({
        givenScore:
          this.tableResponse.currentRowChange.row.formGroup.value.givenScore ===
          ''
            ? null
            : currentRowChange.givenScore,
        score:
          (currentRowChange.maximumScore * currentRowChange.givenScore) / 100
      });
      this.quotationEvaluation.score = 0;
      this.tableResponse.getRecordList().forEach(quotationEvaluationDtl => {
        this.quotationEvaluation.score += quotationEvaluationDtl.score;
      });
      this.isFullFill =
        this.tableResponse
          .getRecordList()
          .filter(data => !data.givenScore && data.givenScore !== 0).length > 0
          ? false
          : true;
      if (this.isFullFill) {
        this.checkStatus(
          this.quotationEvaluation.score < this.procurementCriteria.minimumScore
            ? true
            : false
        );
      }
    }
  }

  public checkNoteValidation() {
    const status =
      this.tableResponse.currentRowChange.row.formGroup.get(
        'scoringStatus'
      ).value;
    const noteFormGroup =
      this.tableResponse.currentRowChange.row.formGroup.get('note');

    if (status?.code === this.global.appConstant.pm.SCORING_STATUS_NOT_PASSED) {
      noteFormGroup.setValidators(Validators.required());
      if (!noteFormGroup.value) {
        noteFormGroup.setErrors({
          message: this.translateService.instant('app.validation.required')
        });
      }
    } else {
      noteFormGroup.clearValidators();
      noteFormGroup.setErrors(null);
    }
    noteFormGroup.markAsTouched();
    noteFormGroup.updateValueAndValidity;

    this.isFullFill =
      this.tableResponse.formArray.value.length === 0 ||
      this.tableResponse.formArray.value.filter(data => !data.scoringStatus)
        .length > 0
        ? false
        : true;
    if (this.isFullFill) {
      const statusNotPassed =
        this.tableResponse.formArray.value.filter(
          data =>
            data.scoringStatus?.code ===
            this.global.appConstant.pm.SCORING_STATUS_NOT_PASSED
        ).length > 0;
      this.checkStatus(statusNotPassed);
    }
  }

  public checkStatus(isNotPassed: boolean): void {
    if (this.isFullFill) {
      this.statusResult = isNotPassed
        ? this.scoringStatusOptionList
            .getRequestValues()
            .filter(
              scoringStatus =>
                scoringStatus.code ===
                this.global.appConstant.pm.SCORING_STATUS_NOT_PASSED
            )[0]
        : this.scoringStatusOptionList
            .getRequestValues()
            .filter(
              scoringStatus =>
                scoringStatus.code ===
                this.global.appConstant.pm.SCORING_STATUS_PASSED
            )[0];
    }
  }

  public getStringCustomValue(value: any): string {
    const color = this.getColorAndIconConfirm[value.code].color;
    const icon = this.getColorAndIconConfirm[value.code].icon;
    const text = this.translateService.instant(this.getTranslateKey(value));
    return (
      '<div style="display: inline-flex"><em style="font-size: 1.5rem" class="' +
      icon +
      '"></em>' +
      '&nbsp;<span class="' +
      color +
      '">' +
      text +
      '</span></div>'
    );
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public goToProfile(): void {
    localStorage.setItem(
      'vendorId',
      this.quotationEvaluation.quotation.procurementVendor.vendor.id.toString()
    );
    localStorage.setItem('urlBackOutside', 'close-window');
    window.open('/pages/company-profile');
  }

  public doSave(): void {
    this.isSave = true;
    this.validate();
    if (this.isFullFill && this.formGroup.valid) {
      this.setStateProcessing();
      this.quotationEvaluation.quotationEvaluationDtlList =
        this.tableResponse.getRecordList();
      this.quotationEvaluation.scoringStatus = this.statusResult;
      this.quotationEvaluation.isFinalScore =
        this.procurementCriteria.isAppliedFinalScoring;
      this.httpClientService
        .post('/technical-evaluation/save-popup', this.quotationEvaluation)
        .subscribe(response => {
          this.onChange.emit(response.body);
          this.setStateReady();
        });
    }
  }
}
