import { VendorAddressHistory } from 'src/app/core/bean/vendor-address-history';
import { VendorHistory } from 'src/app/core/bean/vendor-history';
import { VendorSegmentationEntity } from 'src/app/core/entity/vendor-segmentation.entity';

export class TabProfileDocumentReportRequest {
    vendorHistory: VendorHistory;
    vendorAddressHistoryList: VendorAddressHistory[];
    vendorSegmentationHistoryList: VendorSegmentationEntity[];
    isFromGeneral: boolean;
}
