import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAutoCompleteModule } from '../app-auto-complete/app-auto-complete.module';
import { AppCheckBoxModule } from '../app-check-box/app-check-box.module';
import { AppChosenModule } from '../app-chosen/app-chosen.module';
import { AppComboBoxModule } from '../app-combo-box/app-combo-box.module';
import { AppCurrencyModule } from '../app-currency/app-currency.module';
import { AppDatepickerModule } from '../app-date-picker/app-date-picker.module';
import { AppRadioModule } from '../app-radio/app-radio.module';
import { AppTextAreaModule } from '../app-text-area/app-text-area.module';
import { AppTextFieldModule } from '../app-text-field/app-text-field.module';
import { AppToggleSwitchModule } from '../app-toggle-switch/app-toggle-switch.module';
import { AppWysiwygModule } from '../app-wysiwyg/app-wysiwyg.module';
import { UploadModule } from '../upload';
import { AppFormObjectComponent } from './app-form-object.component';
@NgModule({
  imports: [
    VendorModule,
    AppTextFieldModule,
    AppComboBoxModule,
    AppCheckBoxModule,
    AppRadioModule,
    AppTextAreaModule,
    AppDatepickerModule,
    AppWysiwygModule,
    AppChosenModule,
    AppAutoCompleteModule,
    AppToggleSwitchModule,
    UploadModule,
    AppCurrencyModule
  ],
  declarations: [AppFormObjectComponent],
  entryComponents: [AppFormObjectComponent],
  exports: [AppFormObjectComponent]
})
export class AppFormObjectModule {}
