<div class="row" *ngIf="isShow">
  <div class="col-12">
    <app-card
      header="app-approval-prcs.approvalSection"
      *ngIf="isLoadingSection"
    >
      <div class="text-center" [ngStyle]="{ margin: '10vh 0' }">
        <img src="assets/img/common/loader-small.gif" alt="Loading" />
      </div>
    </app-card>

    <app-card
      *ngIf="!isLoadingSection"
      header="app-approval-prcs.approvalSection"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-left">
          <div class="col text-left">
            <app-approval-path [model]="approvalPath">
              <ng-template #customText let-userApprover>
                <span
                  class="d-block span-app-approval-path"
                  [ngStyle]="{ color: '#343434' }"
                  >{{ userApprover.user?.name || '-' }}</span
                >
                <span
                  class="d-block span-app-approval-path"
                  [ngStyle]="{ color: '#343434' }"
                  *ngIf="userApprover.delegateUser"
                  >({{ userApprover.delegateUser?.name }})</span
                >
                <span class="d-block span-app-approval-path">{{
                  userApprover.position?.name || '-'
                }}</span>
                <span class="d-block span-app-approval-path">{{
                  userApprover.organization?.name || '-'
                }}</span>
              </ng-template>
            </app-approval-path>
          </div>
        </div>
        <div *ngIf="!approvalModelPrcs?.isViewOnly">
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'app-approval-prcs.form.requestedDate' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              {{
                formGroup.controls.requestedDate.value
                  | date: global.config.parameterModel.datetimeFormatShort
              }}
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'app-approval-prcs.form.uploadFile' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-upload
                formControlName="fileApproval"
                [fileUploader]="fileUploader"
              ></app-upload>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'app-approval-prcs.form.note' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-area maxLength="512" formControlName="note">
              </app-text-area>
            </div>
          </div>

          <div class="button-group button-group-center mt-5">
            <app-button
              color="SECONDARY"
              (onClick)="doBack()"
              class="mr-2"
              mode="BYPASS"
              [disabled]="formSaving"
            >
              {{ 'app.button.back' | translate }}</app-button
            >

            <app-button
              color="DANGER"
              *ngIf="isShowButtonReject"
              (onClick)="doSave(4)"
              class="mr-2"
              [disabled]="formSaving"
            >
              {{ 'app.button.reject' | translate }}</app-button
            >

            <app-button
              color="PRIMARY"
              (onClick)="doSave(2)"
              class="mr-2"
              [disabled]="formSaving"
            >
              {{ 'app.button.revision' | translate }}
            </app-button>
            <app-button
              color="SUCCESS"
              (onClick)="doSave(1)"
              class="mr-2"
              [disabled]="formSaving"
            >
              {{ 'app.button.approve' | translate }}
            </app-button>
          </div>
        </div>
      </form>
      <div class="mt-4">
        <app-card header="app-approval-prcs.approvalHistory">
          <app-table [model]="tableResponse"></app-table>
        </app-card>
      </div>
    </app-card>
  </div>
</div>
