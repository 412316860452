import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { TeamProject } from '../../../core/bean/team-project';
import { ApprovalPathModel } from '../../../core/components/app-approval-path';
import { AppPopupOfficerService } from '../../../core/components/app-popup/app-popup-officer/app-popup-officer.service';
import { TeamProjectForm } from '../../team-project/model/team-project-form-model';
import { TeamProjectMapper } from './mapper/team-project-mapper';
@Component({
  selector: 'app-team-project',
  templateUrl: './app-team-project.component.html',
  styleUrls: ['./app-team-project.component.scss']
})
export class AppTeamProjectComponent extends BaseModuleComponent {
  @Input() public teamProjectFormList: TeamProjectForm[];
  @Input() public isDisabled: boolean;
  public excludeFirstTime: boolean;
  public approvalPathList: Array<ApprovalPathModel<TeamProject>> = new Array();
  constructor(
    translateService: TranslateService,
    private appPopupOfficerService: AppPopupOfficerService
  ) {
    super('app-team-project', translateService);
  }

  onInit(): void {
    this.buildApproverPathList();
    this.buildFormGroup();
  }

  private buildApproverPathList(): void {
    const approvalPathMode = this.isDisabled ? 'READ' : 'WRITE';
    const teamProjectMapper: TeamProjectMapper<TeamProject> =
      new TeamProjectMapper();
    this.teamProjectFormList.forEach(
      (teamProjectForm: TeamProjectForm, index: number) => {
        const maxApprover = [1, 1, 0];
        const approvalPath: ApprovalPathModel<TeamProject> =
          new ApprovalPathModel(this.moduleCode, approvalPathMode);
        approvalPath.mapper = teamProjectMapper;
        approvalPath
          .setApproverList(teamProjectForm.teamProjectList)
          .setMax(maxApprover[index]);
        this.approvalPathList.push(approvalPath);
        this.setStateReady();
      }
    );
  }

  private buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      officerList: this.formBuilder.array([])
    });
  }

  public doOnChoose(index: number): void {
    this.excludeFirstTime = false;
    const officerList = [];
    this.approvalPathList.forEach(approvalPath =>
      officerList.push(...approvalPath.value)
    );
    this.appPopupOfficerService.open(officerList).subscribe(data => {
      const newTeamProject = new TeamProject();
      const { position, organization, user } = data;
      newTeamProject.position = position;
      newTeamProject.organization = organization;
      newTeamProject.user = user;
      this.approvalPathList[index].addApproverToApproverList(newTeamProject);
    });
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
