<app-popup
  header="dr.popup.deliveryProcess"
  [isShowHeaderCloseButton]="formGroup.isView"
>
  <div class="main-row-popup-dr">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <app-alert-x
        color="WARNING"
        *ngIf="!isRejectQuantityTrue || !isPassQuantityTrue"
      >
        {{ 'dr.detail.alertInfo.quantityValidation' | translate }}
      </app-alert-x>
      <app-alert-x *ngIf="!formGroup.isView">
        {{
          'dr.message.info.allFieldsAreRequiredUnlessSpecifiedOptional'
            | translate
        }}
      </app-alert-x>
      <app-alert-x
        *ngIf="formGroup.value.drShipping && !isLessThenDrItemShipping()"
      >
        {{ 'dr.validation.itemQuantity' | translate }}
      </app-alert-x>
      <app-alert-x
        *ngIf="!isDeliverableTrue"
      >
        {{ 'dr.validation.validateDeliverable' | translate }}
      </app-alert-x>
      <div class="form-group row">
        <label class="col-sm-3">{{ 'dr.form.itemMerk' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{ sowPaymentTermItem.merk }}
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3">{{ 'dr.form.itemName' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{ sowPaymentTermItem.prItem?.item?.name }}
        </div>
      </div>
      <div class="form-group row" *ngIf="formGroup.isView">
        <label class="col-sm-3"
          >{{ 'dr.form.totalReceived' | translate }}
          <app-tooltip
            position="BOTTOM"
            style="position: absolute; margin-left: 1%; margin-top: 0.3%"
          >
            <em
              class="pir pi-info-circle float-right"
              style="color: 'var(--primary)'"
            ></em>
            <ng-template #tooltipContent>
              <div style="width: 150px; padding: 5px">
                {{ 'dr.tooltip.totalReceived' | translate }}
              </div>
            </ng-template>
          </app-tooltip>
        </label>
        <div class="col-sm-9 text-left">
          {{
            sowPaymentTermItem.prItem.item.itemType.parentCode ===
            global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
              ? (sowPaymentTermItem.quantity | resolveNumberToDecimal) +
                ' ' +
                sowPaymentTermItem.prItem.item.uom.name
              : (sowPaymentTermItem.deliveryPercentage
                  | resolveNumberToDecimal) + ' %'
          }}
        </div>
      </div>
      <div class="form-group row" *ngIf="formGroup.isView">
        <label class="col-sm-3">{{ 'dr.form.remaining' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{
            sowPaymentTermItem.prItem.item.itemType.parentCode ===
            global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
              ? (sowPaymentTermItem.remainingQuantity
                  | resolveNumberToDecimal) +
                ' ' +
                sowPaymentTermItem.prItem.item.uom.name
              : (sowPaymentTermItem.remainingQuantity
                  | resolveNumberToDecimal) + ' %'
          }}
        </div>
      </div>
      <div class="form-group row" *ngIf="formGroup.isView">
        <label class="col-sm-3">{{ 'dr.form.stageName' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{ sow?.stageOfWork?.name }}
        </div>
      </div>
      <div class="form-group row" *ngIf="formGroup.isView">
        <label class="col-sm-3">{{ 'dr.form.term' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{ sowPaymentTerm?.paymentTerm?.name }}
        </div>
      </div>
      <div class="form-group row" *ngIf="!formGroup.isView">
        <label class="col-sm-3">{{
          'dr.form.deliveredItem' | translate
        }}</label>
        <div
          class="col-sm-9 text-left"
          *ngIf="
            sowPaymentTermItem.prItem.item.itemType.parentCode ===
            global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
          "
        >
          {{
            sowPaymentTermItem.quantity - sowPaymentTermItem.remainingQuantity
              | resolveNumberToDecimal
          }}
          / {{ sowPaymentTermItem.quantity | resolveNumberToDecimal }}
          {{ sowPaymentTermItem.prItem?.item?.uom?.name || '-' }}
        </div>
        <div
          class="col-sm-9 text-left"
          *ngIf="
            sowPaymentTermItem.prItem.item.itemType.parentCode ===
            global.appConstant.core.ITEM_TYPE_CODE_SERVICE
          "
        >
          {{
            (sowPaymentTermItem.deliveryPercentage
              ? sowPaymentTermItem.deliveryPercentage
              : 0
            ) | resolveNumberToDecimal
          }}
          % / {{ 100 | resolveNumberToDecimal }} %
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="
          !formGroup.isView &&
          sowPaymentTermItem.prItem.item.itemType.parentCode ===
            global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
        "
      >
        <label class="col-sm-3">{{
          'dr.form.totalReceived' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-count
            formControlName="receivedQuantity"
            (onChange)="onChangePassTotal()"
            min="0"
            [max]="sowPaymentTermItem.remainingQuantity"
          ></app-count>
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="
          !formGroup.isView &&
          sowPaymentTermItem.prItem.item.itemType.parentCode ===
            global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
        "
      >
        <label class="col-sm-3">{{ 'dr.form.totalPass' | translate }}</label>
        <div class="col-sm-9 text-left">
          <app-count
            formControlName="passQuantity"
            (onChange)="onChangePassTotal()"
            min="0"
          ></app-count>
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="
          !formGroup.isView &&
          sowPaymentTermItem.prItem.item.itemType.parentCode ===
            global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
        "
      >
        <label class="col-sm-3">{{ 'dr.form.totalReject' | translate }}</label>
        <div class="col-sm-9 text-left">
          <app-count
            formControlName="rejectQuantity"
            (onChange)="onChangeRejectTotal()"
            min="0"
          ></app-count>
        </div>
      </div>
      <div
        class="form-group row"
        *ngIf="
          !formGroup.isView &&
          sowPaymentTermItem.prItem.item.itemType.parentCode ===
            global.appConstant.core.ITEM_TYPE_CODE_SERVICE
        "
      >
        <label class="col-sm-3">{{ 'dr.form.deliverable' | translate }}</label>
        <div class="col-sm-2 text-left">
          <app-text-field
            size="XL"
            formControlName="passPercentageValue"
            type="decimal"
            groupText="%"
            (onChange)="doCheckDeliverable()"
          ></app-text-field>
        </div>
      </div>
      <div class="form-group row" *ngIf="!formGroup.isView">
        <label class="col-sm-3">{{ 'dr.form.receivedDate' | translate }}</label>
        <div class="col-sm-9 text-left">
          <app-date-picker-x formControlName="receivedDate" [time]="true">
          </app-date-picker-x>
        </div>
      </div>
      <!--<div class="form-group row" *ngIf="!formGroup.isView">
        <label class="col-sm-3">{{
          'dr.form.deliveryPerformance' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-rating formControlName="performanceValue"></app-rating>
        </div>
      </div>-->
      <div class="form-group row" *ngIf="!formGroup.isView">
        <label class="col-sm-3">{{
          'dr.form.deliveredAddress' | translate
        }}</label>
        <!--<div class="col-sm-6 text-left">
          <app-choose
            size="XL"
            (onClick)="doOpenPopupAddress($event)"
            formControlName="address"
          >
          </app-choose>
        </div>-->
        <div class="col-sm-6 text-left">
        {{ formGroup.get('address')?.value }}
        </div>
      </div>

      <div class="form-group row" *ngIf="!formGroup.isView">
        <label class="col-sm-3 optional">{{
          'dr.form.note' | translate
        }}</label>
        <div class="col-sm-6 text-left">
          <app-text-area size="XL" formControlName="note" maxLength="255">
          </app-text-area>
        </div>
      </div>
      <div class="form-group row" *ngIf="!formGroup.isView">
        <label class="col-sm-3 optional">{{
          'dr.form.attachment' | translate
        }}</label>
        <div class="col-sm-6 text-left">
          <app-upload-x
            formControlName="uploadFile"
            [fileUploader]="fileUploader"
          ></app-upload-x>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h3 class="separator">{{ 'dr.label.historyLog' | translate }}</h3>
          <app-table-xx
            *ngIf="
              sowPaymentTermItem.prItem.item.itemType.parentCode ===
              global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
            "
            [model]="tableResponseGoods"
            stringUrl="/dr/table-history-log"
          >
          </app-table-xx>
          <app-table-xx
            *ngIf="
              sowPaymentTermItem.prItem.item.itemType.parentCode !==
              global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
            "
            [model]="tableResponseService"
            stringUrl="/dr/table-history-log"
          >
          </app-table-xx>
        </div>
      </div>
    </form>
  </div>
  <ng-template #modalFooter let-activeModal *ngIf="!formGroup.isView">
    <div class="col-12 text-center">
      <app-button
        color="SECONDARY"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button color="PRIMARY" (onClick)="doSave()">{{
        'app.button.save' | translate
      }}</app-button>
    </div>
  </ng-template>
</app-popup>
