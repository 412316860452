<app-card [header]="header" [tips]="tips">
  <ng-template #headerRight>
    <div class="button-group button-group-right">
      <app-button
        *ngIf="binaryTreeModel.isWrite"
        class="float-right"
        (onClick)="doAdd()"
        [ngStyle]="{ 'margin-bottom': '2%' }"
      >
        <em class="pir pi-plus-circle"></em>
        {{ 'app-committee.button.addCommitte' | translate }}
      </app-button>
      <app-button
        *ngIf="binaryTreeModel.isShowChooseButton"
        class="float-right"
        (onClick)="doChoose()"
        [ngStyle]="{ 'margin-bottom': '2%' }"
      >
        <em class="pir pi-plus-circle"></em>
        {{ 'app-committee.button.chooseCommitte' | translate }}
      </app-button>
    </div>
  </ng-template>

  <div class="cmt-3">
    <app-fullfilled
      *ngIf="!binaryTreeModel.getTreeList() && !formGroup.isView"
    ></app-fullfilled>
  </div>

  <div class="app-committee cmb-2" style="align-content: center">
    <app-binary-tree
      *ngIf="
        binaryTreeModel.recursiveTreeList &&
        binaryTreeModel.recursiveTreeList.length > 0
      "
      [treeList]="binaryTreeModel.recursiveTreeList"
    >
      <ng-template *ngIf="binaryTreeModel.isWrite" #itemContent let-item>
        <div class="box-level text-primary">
          {{ item.committeePosition.name }}
        </div>

        <div class="box-user" (click)="doReplace(item)">
          <div class="user-image">
            <img
              [src]="
                item.user?.imgFile
                  ? this.global.config.BACKEND_ADDRESS +
                    '/app-committee/thumbnail/view/' +
                    item.user?.imgFile.uploadedFileName +
                    '/' +
                    item.user?.imgFile.fileType.code
                  : global.appConstant.core.URL_DEFAULT_PROFILE_IMAGE
              "
              alt="user image"
              [default]="global.appConstant.core.URL_DEFAULT_PROFILE_IMAGE"
            />
          </div>
          <div class="user-detail">
            <span class="user-name">{{
              item.user ? item.user.name : item.name
            }}</span>
            <span class="user-role">{{
              item.user ? item.user.organization?.name : item.organization?.name
            }}</span>
          </div>
          <em class="pir pi-trash-alt del-icon" (click)="doRemove(item)"></em>
        </div>
      </ng-template>

      <ng-template *ngIf="!binaryTreeModel.isWrite" #itemContent let-item>
        <div class="box-level text-primary">
          {{ item.committeePosition.name }}
        </div>

        <div class="box-user">
          <div class="user-image">
            <img
              [src]="
                item.user?.imgFile
                  ? this.global.config.BACKEND_ADDRESS +
                    '/app-committee/thumbnail/view/' +
                    item.user?.imgFile.uploadedFileName +
                    '/' +
                    item.user?.imgFile.fileType.code
                  : global.appConstant.core.URL_DEFAULT_PROFILE_IMAGE
              "
              alt="user image"
            />
          </div>
          <div class="user-detail">
            <span class="user-name">{{
              item.user ? item.user.name : item.name
            }}</span>
            <span class="user-role">{{
              item.user ? item.user.organization?.name : item.organization?.name
            }}</span>
          </div>
        </div>
      </ng-template>
    </app-binary-tree>
    <div
      *ngIf="
        !binaryTreeModel.recursiveTreeList ||
        binaryTreeModel.recursiveTreeList.length === 0
      "
      class="text-center"
    >
      <div
        class="pvc pv-social"
        style="width: 100px; height: 100px; margin: 0 auto"
      ></div
      ><br />
      <ng-container *ngIf="!committee">
        <p class="text-no-data">{{
          'app-committee.noDataAvailable' | translate
        }}</p>
        <p class="text-no-data">{{
          'app-committee.letsTryToAddOne' | translate
        }}</p>
      </ng-container>

      <ng-container
        *ngIf="
          committee &&
          committee?.committeeType?.code ===
            global.appConstant.pm.COMMITTEE_TYPE_PERSONAL
        "
      >
        <!--PERSONAL-->
        <p class="text-no-data">{{
          'app-committee.personalCommitteeSelected' | translate
        }}</p>
        <p class="text-no-data">{{
          'app-committee.noCommitteeAvailable' | translate
        }}</p>
      </ng-container>

      <ng-container
        *ngIf="
          committee &&
          committee?.committeeType?.code ===
            global.appConstant.pm.COMMITTEE_TYPE_COMMITTEE
        "
      >
        <!--PERSONAL-->
        <p class="text-no-data">{{
          'app-committee.gettingParticipantData' | translate
        }}</p>
      </ng-container>
    </div>
  </div>
</app-card>
