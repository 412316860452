import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { DirectorsDecreeSubmission } from '../../core/bean/directors-decree-submission';
import { WorkPlan } from '../../core/bean/work-plan';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';

@Component({
  templateUrl: './directors-decree-submission.component.html'
})
export class DirectorsDecreeSubmissionComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public tableResponse: TableResponseModel<DirectorsDecreeSubmission>;

  constructor(translateService: TranslateService) {
    super('directors-decree-submission', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      { field: 'number', header: 'table.column.number' }
    ]);
  }

  public doEdit(workPlan: WorkPlan): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('workPlanId', workPlan.id);
    this.router.navigate(['/pages/directors-decree-submission/edit']);
  }
}
