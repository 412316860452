import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../base/angular/base-module.component';
import { OptionListModel } from '../../../model/option-list-model';
import { AppPopupVersionHistoryModel } from './app-popup-version-history-model';

@Component({
  templateUrl: './app-popup-version-history.component.html'
})
export class AppPopupVersionHistoryComponent extends BaseModuleComponent {
  @Input() modelList: AppPopupVersionHistoryModel[];

  public modelOptionList: OptionListModel<AppPopupVersionHistoryModel> =
    new OptionListModel(false);

  constructor(translateService: TranslateService) {
    super('app-popup-version-history', translateService);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      modelList: [null]
    });
  }

  public setFormGroup(): void {
    this.modelOptionList.setRequestValues(this.modelList);
    this.modelOptionList.searchFieldList = ['uploaderName', 'docFile.fileName'];
    this.formGroup.patchValue({
      modelList: this.modelList
    });
    this.formGroup.setIsView(true);
    this.setStateReady();
  }
}
