import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppActivityHistoryViewModule } from 'src/app/core/components/app-activity-history-view/app-activity-history-view.module';
import { AppContractDocumentModule } from '../../core/components/app-contract-document/app-contract-document.module';
import { AppContractRequestInfoModule } from '../../core/components/app-contract-request-info/app-contract-request-info.module';
import { AppItemRequestReleasedModule } from '../../core/components/app-item-request-released/app-item-request-released.module';
import { AppVendorInformationModule } from '../../core/components/app-vendor-information/app-vendor-information.module';
import { AppWorkDefinitionModule } from '../../core/components/app-work-definition/app-work-definition.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ContractHistoryDetailComponent } from './contract-history-detail.component';
import { ContractHistoryPopupCloseContractComponent } from './contract-history-popup-close-contract.component';
import { ContractHistoryPopupGuaranteeComponent } from './contract-history-popup-guarantee.component';
import { ContractHistoryPopupNewEmailComponent } from './contract-history-popup-new-email.component';
import { ContractHistoryPopupPrintComponent } from './contract-history-popup-print.component';
import { ContractHistoryPopupSendReminderComponent } from './contract-history-popup-send-reminder.component';
import { ContractHistoryComponent } from './contract-history.component';

export const routes = [
  {
    path: '',
    component: ContractHistoryComponent,
    data: { breadcrumb: '' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: ContractHistoryDetailComponent,
    data: { breadcrumb: 'contract-history.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: ContractHistoryDetailComponent,
    data: { breadcrumb: 'contract-history.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppContractRequestInfoModule,
    AppVendorInformationModule,
    AppWorkDefinitionModule,
    AppActivityHistoryViewModule,
    AppContractDocumentModule,
    AppItemRequestReleasedModule
  ],
  declarations: [
    ContractHistoryComponent,
    ContractHistoryDetailComponent,
    ContractHistoryPopupPrintComponent,
    ContractHistoryPopupGuaranteeComponent,
    ContractHistoryPopupSendReminderComponent,
    ContractHistoryPopupCloseContractComponent,
    ContractHistoryPopupNewEmailComponent
  ],
  entryComponents: [
    ContractHistoryPopupGuaranteeComponent,
    ContractHistoryPopupSendReminderComponent,
    ContractHistoryPopupCloseContractComponent,
    ContractHistoryPopupNewEmailComponent
  ],
  exports: [
    ContractHistoryPopupGuaranteeComponent,
    ContractHistoryPopupSendReminderComponent,
    ContractHistoryPopupCloseContractComponent,
    ContractHistoryPopupNewEmailComponent
  ]
})
export class ContractHistoryModule {}
