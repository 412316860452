import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CustomValidatorError } from '../../core/custom-validator-error';
export class IsCheckedValidator {
  public static isChecked(length = 1, message?: string): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors => {
      const value = abstractControl.value;
      if (value && Array.isArray(value)) {
        const isCheckedValueList = abstractControl.value.filter(
          (val: any) => val.isChecked
        );
        if (isCheckedValueList.length < length) {
          return CustomValidatorError.create('isChecked', message);
        }
      } else if (abstractControl.value === null || !abstractControl.value) {
        return CustomValidatorError.create('isChecked', message);
      }
      return null;
    };
  }
}
