import { Component } from '@angular/core';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetPaymentStatusResponse } from './widget-payment-status.response';
@Component({
  templateUrl: './widget-payment-status.component.html'
})
export class WidgetPaymentStatusComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-payment-status';
  public totalPayment: number;
  public paymentDataList: Array<any>;

  constructor() {
    super(WidgetPaymentStatusComponent.moduleCode);
  }

  protected onInit(): void {
    this.httpClientService
      .get<WidgetPaymentStatusResponse>('/widget-payment-status/index')
      .subscribe((response: WidgetPaymentStatusResponse) => {
        if (response.totalPayment) {
          this.totalPayment = response.totalPayment;
        }
        this.paymentDataList = Object.keys(response)
          .map((key: string) => {
            if (key != 'totalPayment') {
              const strKey = key.slice(5);
              return {
                value: response[key],
                translationKey:
                  this.moduleCode +
                  '.' +
                  strKey.charAt(0).toLowerCase() +
                  strKey.slice(1)
              };
            }
          })
          .filter(val => val);
        this.setStateReady();
      });
  }
}
