import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetContractInformationComponent } from './app-widget-contract-information.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule],
  declarations: [AppWidgetContractInformationComponent],
  entryComponents: [AppWidgetContractInformationComponent],
  exports: [AppWidgetContractInformationComponent]
})
export class AppWidgetContractInformationModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-contract-information', AppWidgetContractInformationComponent);
  }
}
