import { NgModule } from '@angular/core';
import { AppButtonModule } from '../../../../../core/components/app-button/app-button.module';
import { AppChartModule } from '../../../../../core/components/app-chart/app-chart.module';
import { AppComboBoxModule } from '../../../../../core/components/app-combo-box/app-combo-box.module';
import { AppDropdownSelectModule } from '../../../../../core/components/app-dropdown-select/app-dropdown-select.module';
import { AppPopupModule } from '../../../../../core/components/app-popup/app-popup.module';
import { PipesModule } from '../../../../../core/pipe/pipe.module';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetBudgetSpendingTypeComponent } from './widget-budget-spending-type.component';

@NgModule({
  imports: [
    VendorModule,
    WidgetCardModule,
    AppChartModule,
    AppComboBoxModule,
    AppButtonModule,
    AppPopupModule,
    AppDropdownSelectModule,
    PipesModule
  ],
  declarations: [WidgetBudgetSpendingTypeComponent],
  entryComponents: [WidgetBudgetSpendingTypeComponent],
  exports: [WidgetBudgetSpendingTypeComponent]
})
export class WidgetBudgetSpendingTypeModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      'dashboard-widget-budget-spending-type',
      WidgetBudgetSpendingTypeComponent,
      'w-50'
    );
  }
}
