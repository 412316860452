import { Component, OnInit } from '@angular/core';
import { BaseComponentComponent } from '../../../../core/base/angular/base-component.component';
import { UserManual } from '../../../../core/bean/user-manual';
import { ToastService } from '../../../../core/components/app-toast/app-toast.service';
import { Response } from '../../../../core/model/response-model';
import { ResponseStatusModel } from '../../../../core/model/response-status-model';
import { DownloadService } from '../../../../core/services/download.service';

@Component({
  selector: 'theme-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent extends BaseComponentComponent implements OnInit {
  constructor(
    public downloadService: DownloadService,
    public toastService: ToastService
  ) {
    super('help');
  }

  public onInit(): void {
    this.log.debug();
  }

  public doDownloadUserManual(): void {
    this.loadingBlockService.showInfo('app.msg.processing');
    const url = '/user-manual/download-user-manual';
    this.httpClientService
      .get<Response<UserManual>>(url)
      .subscribe(response => {
        this.loadingBlockService.close();
        if (response.status === ResponseStatusModel.OK) {
          if (response.body.file != null) {
            const stringUrl =
              '/user-manual' +
              '/file/view/' +
              response.body.file.uploadedFileName +
              '/' +
              this.global.appConstant.fileType.DOC_USER_MANUAL;
            this.downloadService.download(
              stringUrl,
              response.body.file.fileName
            );
          } else {
            this.toastService.showError(
              this.global.translateService.instant('user-manual.fileNotExist')
            );
          }
        } else {
          this.toastService.showError(response.statusText);
        }
      });
  }
}
