<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-workflow-progress-info
      *ngIf="isViewOnly && !formLoading"
      [contract]="contract"
      [currentPosition]="moduleCode"
    >
    </app-workflow-progress-info>
    <app-card
      header="review-contract.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form
        *ngIf="!formLoading"
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
      >
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'review-contract.form.contractDocument' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <a
              href="#"
              (click)="
                doDownload(
                  $event,
                  '/review-contract/file/view/' +
                    latestContractDocument.uploadedFileName +
                    '/' +
                    latestContractDocument.fileType.code,
                  latestContractDocument.fileName
                )
              "
            >
              {{
                latestContractDocument ? latestContractDocument.fileName : '-'
              }}
            </a>
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!isViewOnly ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'review-contract.form.status' | translate
          }}</label>
          <div *ngIf="!isViewOnly" class="col-sm-9 text-left">
            <app-radio formControlName="status" [optionList]="statusList">
            </app-radio>
          </div>
          <div *ngIf="isViewOnly" class="col-sm-9 text-left">
            {{ reviewContract.status || '-' }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'review-contract.form.file' | translate
          }}</label>
          <div *ngIf="!isViewOnly" class="col-sm-9 text-left">
            <app-upload
              formControlName="uploadFile"
              [fileUploader]="fileUploader"
            ></app-upload>
          </div>
          <div *ngIf="isViewOnly" class="col-sm-9 text-left">
            <a
              href="#"
              (click)="
                doDownload(
                  $event,
                  '/review-contract/file/view/' +
                    reviewContract.file.uploadedFileName +
                    '/' +
                    reviewContract.file.fileType.code,
                  reviewContract.file.fileName
                )
              "
            >
              {{ reviewContract.file ? reviewContract.file.fileName : '-' }}
            </a>
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!isViewOnly ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'review-contract.form.note' | translate
          }}</label>
          <div *ngIf="!isViewOnly" class="col-sm-9 text-left">
            <app-text-area
              formControlName="note"
              maxLength="512"
            ></app-text-area>
          </div>
          <div *ngIf="isViewOnly" class="col-sm-6 text-left">
            {{ reviewContract.note ? reviewContract.note : '-' }}
          </div>
        </div>
      </form>
      <div *ngIf="!isViewOnly" class="button-group button-group-center">
        <app-button
          mode="BYPASS"
          color="SECONDARY"
          (onClick)="doBack()"
          class="mr-1"
          [disabled]="formSaving"
          >{{ 'app.button.back' | translate }}
        </app-button>
        <app-button
          color="PRIMARY"
          (onClick)="doConfirmSave()"
          [disabled]="formSaving"
          >{{ 'app.button.save' | translate }}</app-button
        >
      </div>
      <div class="row">
        <div class="col-12">
          <h5 class="separator">{{
            'review-contract.reviewContractHistory' | translate
          }}</h5>
          <app-table
            #selectorHistory
            [model]="tableResponse"
            *ngIf="!formLoading"
          ></app-table>
        </div>
      </div>
    </app-card>
  </div>
</div>
