<app-layout [isLoading]="formLoading">
<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="Sample" tips="Tips Form"
      detail="app.action.{{ todo }}"
      [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-fullfilled></app-fullfilled>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label">{{
            'sample.form.name' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field autofocus="true" formControlName="name"
              maxLength="100"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label">{{
            'sample.form.description' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxLength="512">
            </app-text-area>
          </div>
        </div>

        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label">Vehicle Single Combo</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select 
              formControlName="vehicleCombo"
              [optionList]="vehicleOptionList"
              placeholder="Choose Vehicle"
            >
            </app-dropdown-select>
          </div>
        </div>

        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label">Vehicle Single Combo MultipleView</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select 
              formControlName="vehicleComboMultipleView"
              [optionList]="vehicleOptionList"
              placeholder="Choose Vehicle"
            >
              <ng-template #option let-data>
                <p> {{ data.name }} </p>
                <p> {{ data.category }}</p>
              </ng-template>
            </app-dropdown-select>
          </div>
        </div>

        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label">Vehicle Multiple chosen</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select 
              formControlName="vehicleMultipleChosen"
              [optionList]="vehicleOptionList"
              showLimitValue="3"
              type="CHOSEN"
            >
              <ng-template #selectAll>
                <p> All Department </p>
              </ng-template>
            </app-dropdown-select>
          </div>
        </div>

        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label">Vehicle Multiple chosen and view</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select 
              formControlName="vehicleMultipleChosenView"
              [optionList]="vehicleOptionList"
              showLimitValue="3"
              type="CHOSEN"
            >
              <ng-template #selectAll>
                <p> All Department </p>
              </ng-template>
              <ng-template #option let-data>
                <p> {{ data.name }} </p>
                <p> {{ data.category }}</p>
              </ng-template>
            </app-dropdown-select>
          </div>
        </div>

        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label">AutoComplete Single</label>
          <div class="col-sm-9 text-left">
            <app-dropdown-select 
              formControlName="autoCompleteSingle"
              stringUrl="/sample/auto-complete-bank-list"
              type="AUTOCOMPLETE"
            >
            </app-dropdown-select>
          </div>
        </div>

        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label">Currency Static</label>
          <div class="col-sm-9 text-left">
            <app-currency
                  formControlName="staticPrice"
                  [optionList]="currencySingleOptionList"
                ></app-currency>
          </div>
        </div>

        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label">Currency Chosen</label>
          <div class="col-sm-9 text-left">
                <app-currency
                  formControlName="chosenPrice"
                  [optionList]="currencyOptionList"
                ></app-currency>
          </div>
        </div>

        <div class="form-group row text-sm-left">
          <label class="col-sm-3 control-label">Count</label>
          <div class="col-sm-9 text-left">
                <app-count
                  formControlName="quantity"
                  maxLength="32"
                  [min]="1"
                ></app-count>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button color="PRIMARY" (onClick)="doSave()">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>

    <app-card header="Button" tips="Tips Button"
      [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>

        <div class="button-group button-group-center">
          <app-button size="SM">
            DEFAULT SM
          </app-button>
          <app-button>
            DEFAULT
          </app-button>
          <app-button outline="true">
            DEFAULT OUTLINE
          </app-button>
          <app-button size="LG">
            DEFAULT LG
          </app-button>
        </div>
      </form>
    </app-card>

    <app-card header="Alert" tips="Tips Alert"
      [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-alert-x color="SUCCESS" [isShowCloseButton]="true"
          >Success</app-alert-x>
        <app-alert-x color="INFO" [isShowCloseButton]="true"
          >Info</app-alert-x>
        <app-alert-x color="DANGER" [isShowCloseButton]="true"
          >Danger</app-alert-x>
        <app-alert-x color="WARNING" [isShowCloseButton]="true"
          >Warning</app-alert-x>
        
        <app-alert-icon
          color="INFO"
          icon="CONFETTI"
          class="alert-icon-center alert-icon"
          [isShowCloseButton]="true"
        >
          <strong style="display: grid">
            <span class="pb-3"
              >Congratulation you are passing the criteria for this procurement.
            </span>
            <span>Please wait for further information.</span>
          </strong>
        </app-alert-icon>

        <app-alert-icon color="INFO" icon="DOWNLOAD_FILE">
          <strong style="display: grid">
            <span class="pb-3"
              >Your company has registered for this procurement on 22 April 2020 14:35
            </span>
          </strong>
        </app-alert-icon>
      </form>
    </app-card>

    <app-card header="Badge" tips="Tips Badge"
      [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>

        <div class="row justify-content-center mt-5">
          <app-badge>
            DEFAULT
          </app-badge>
          <app-badge [pill]="false">
            DEFAULT NOT PILL
          </app-badge>
        </div>
      </form>
    </app-card>

  </div>
</div>
<ng-template #contentSidebarRight>
  <app-tips></app-tips>
</ng-template>
</app-layout>