<app-popup
  header="app-popup-choose-vendor-multi-winner.popup.title"
  [isLoading]="formLoading"
>
  <div class="popup-choose-vendor">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'app-popup-choose-vendor.form.itemName' | translate }}
        </label>
        <div class="col-sm-9 text-left">
          {{ procurementItem.prItem.item.name }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-choose-vendor.form.itemCode' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ procurementItem.prItem.item.code }}
        </div>
      </div>

      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-choose-vendor.form.uom' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ procurementItem.prItem.item.uom.name }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-choose-vendor.form.quantity' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ procurementItem.prItem.quantity }}
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'app-popup-choose-vendor.form.reminingQuantity' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          {{ reminingQuantity }}
        </div>
      </div>
    </form>

    <app-alert-x color="DANGER" *ngIf="isMustFullFill">{{
      'vendor-reference.detail.alertInfo.mustBeFullfilled' | translate
    }}</app-alert-x>
    <app-alert-x
      color="WARNING"
      *ngIf="!isMustFullFill && isThingsToConsider"
      >{{
        'vendor-reference.detail.alertInfo.thingsToConsider' | translate
      }}</app-alert-x
    >

    <app-alert-x
      color="WARNING"
      *ngIf="isRemainingAlert && reminingQuantity !== 0"
      >{{ 'vendor-reference.detail.alertInfo.youStillHave' | translate }}
      {{ reminingQuantity }}
      {{
        'vendor-reference.detail.alertInfo.remainingQuantityToAllocate'
          | translate
      }}
    </app-alert-x>
    <app-table-xx
      [model]="tableResponse"
      [isShowSearch]="false"
      *ngIf="!formLoading"
      (onAfterRowCreated)="onAfterRowCreated($event)"
    >
      <ng-template #headerButtons>
        <app-button
          *ngIf="isShowChangeWinningVendor && this.todo === 'view'"
          (onClick)="doChangeWinningVendor()"
        >
          {{
            'app-popup-choose-vendor-multi-winner.button.changeWinningVendor'
              | translate
          }}</app-button
        >
      </ng-template>
    </app-table-xx>

    <ng-template #modalFooter let-activeModal>
      <div *ngIf="todo !== 'view'" class="col-12 text-center">
        <app-button
          class="mr-1"
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button (onClick)="doEmitData()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </div>
</app-popup>
