<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="technical-criteria.title" detail="app.action.{{ todo }}"
      [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'technical-criteria.form.name' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field autofocus="true" size="LG" formControlName="name"
              type="alphanumeric" maxLength="218"></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'technical-criteria.form.description' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxLength="512">
            </app-text-area>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button color="SECONDARY" (onClick)="doCancel()"
            [disabled]="formSaving">{{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()">{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>