import { Injectable } from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { from, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppPopupComponent } from './app-popup.component';

@Injectable()
export class AppPopupService {
  componentInstance: any;
  private modal: NgbModalRef;
  constructor(public ngbModal: NgbModal) {}

  public open(
    component?: any,
    config?: object,
    options?: NgbModalOptions & { autoCloseInMs?: number }
  ): Observable<any> {
    const defaultOptions: NgbModalOptions = {
      backdrop: 'static',
      centered: true,
      size: 'lg',
      keyboard: false
    };
    const newOptions: NgbModalOptions = Object.assign(defaultOptions, {
      ...options
    });

    this.modal = this.ngbModal.open(component || AppPopupComponent, newOptions);

    Object.assign(this.modal.componentInstance, config);
    this.componentInstance = this.modal.componentInstance;

    if (this.modal.componentInstance.onClose) {
      this.modal.componentInstance.onClose.subscribe(() => {
        this.modal.dismiss();
      });
    }

    if (this.modal.componentInstance.onCloseAll) {
      this.modal.componentInstance.onCloseAll.subscribe(() => {
        this.ngbModal.dismissAll();
      });
    }

    if (this.modal.componentInstance.onChange) {
      this.modal.componentInstance.onChange.subscribe(() => {
        this.modal.dismiss();
      });
      return this.modal.componentInstance.onChange;
    }

    // destroy modal in milisecond
    if (options && options.autoCloseInMs) {
      setTimeout(() => {
        this.modal.dismiss();
      }, options.autoCloseInMs);
    }

    return from(this.modal.result).pipe(catchError(() => of(undefined)));
  }

  public close(): void {
    if (this.modal) {
      this.modal.close();
    }
  }
}
