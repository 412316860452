import { Uploader } from '../model/uploader';
import { UploaderFile } from '../model/uploader-file';
export function deleteFile(state: Uploader, payload: { index: number }): void {
  const { index } = payload;
  const uploaderFile: UploaderFile = state.uploaderFileList[index];
  if (!uploaderFile.isNew) {
    uploaderFile.setStatus('DELETED');
    state.trash.push(uploaderFile);
  }
  state.removeAt(index);
  state.valueChanges.emit(state);
}
