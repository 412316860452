<app-popup header="app-popup-justification.title">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-right">
      <label class="col-sm-4 control-label text-right">{{
        'app-popup-justification.form.numberWorkPlan' | translate
      }}</label>
      <div class="col-sm-6 text-left">
        {{ formGroup.value.numberWorkPlan }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-4 control-label text-right">{{
        'app-popup-justification.form.realizeDate' | translate
      }}</label>
      <div class="col-sm-6 text-left">
        {{ formGroup.value.realizeDate | date: 'dd/MM/yyyy' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-4 control-label text-right">{{
        'app-popup-justification.form.processDayTotal' | translate
      }}</label>
      <div class="col-sm-6 text-left">
        {{ formGroup.value.processDayTotal }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-4 control-label text-right">{{
        'app-popup-justification.form.createdBy' | translate
      }}</label>
      <div class="col-sm-6 text-left">
        {{ formGroup.value.userName }}
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-4 control-label text-right">{{
        'app-popup-justification.form.justification' | translate
      }}</label>
      <div class="col-sm-1 text-left">
        <input
          appInteger
          class="form-control validation-field"
          type="text"
          formControlName="justificationDayTotal"
          maxlength="32"
          aria-label="Justification Day Total"
        />
        <small
          class="text-danger"
          *ngIf="
            formGroup.controls['justificationDayTotal'].invalid &&
            formGroup.controls['justificationDayTotal'].touched
          "
          >{{ 'app.validation.required' | translate }}</small
        >
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-4 control-label">{{
        'app-popup-justification.form.description' | translate
      }}</label>
      <div class="col-sm-6 text-left">
        <textarea
          appAlphaNumeric
          class="form-control"
          formControlName="description"
          rows="5"
          maxlength="512"
          aria-label="Description"
        ></textarea>
        <small
          class="text-danger"
          *ngIf="
            formGroup.controls['description'].invalid &&
            formGroup.controls['description'].touched
          "
          >{{ 'app.validation.required' | translate }}</small
        >
      </div>
    </div>

    <div class="row justify-content-center mt-5">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="doCancel()"
        [disabled]="formSaving"
        >{{ 'app.button.back' | translate }}</button
      >
      <button
        type="button"
        class="btn btn-primary ml-2"
        (click)="doSave()"
        [disabled]="!formGroup.valid"
        >{{ 'app.button.save' | translate }}</button
      >
    </div>
  </form>
</app-popup>
