<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="company-identity.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <div class="table-responsive custom-table">
        <table
          class="table table-striped"
          [formGroup]="formGroup"
          aria-describedby="Table"
        >
          <thead>
            <tr>
              <th
                scope="col"
                [ngStyle]="{ width: '20%' }"
                class="text-left"
                >{{ 'company-identity.table.column.name' | translate }}</th
              >
              <th
                scope="col"
                [ngStyle]="{ width: '30%' }"
                class="text-left"
                >{{
                  'company-identity.table.column.value' | translate
                }}</th
              >
            </tr>
          </thead>
          <tbody formArrayName="companyIdentityList">
            <tr
              *ngFor="
                let companyIdentity of companyIdentityList.controls;
                let i = index
              "
              [formGroup]="companyIdentity"
            >
              <td>{{ companyIdentity.value.name }}</td>
              <td>
                <app-text-field
                  size="LG"
                  formControlName="value"
                ></app-text-field
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="button-group button-group-center mt-5">
        <app-button
          color="SECONDARY"
          (onClick)="doCancel()"
          [disabled]="formSaving"
        >
          {{ 'app.button.back' | translate }}
        </app-button>
        <app-button (onClick)="doSave()" [disabled]="formSaving">
          {{ 'app.button.save' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>
