import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { RfiVendor } from '../../core/bean/rfi-vendor';
import { AppPopupImportService } from '../../core/components/app-popup/app-popup-import/app-popup-import.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';

@Component({
  templateUrl: './response-rfi.component.html'
})
export class ResponseRfiComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<ResponseRfiComponent>;
  public isFromWidget: boolean;
  constructor(
    translateService: TranslateService,
    public appPopupImportService: AppPopupImportService
  ) {
    super('response-rfi', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    const waitingForResponse = this.translateService.instant(
      'response-rfi.status.waitingForResponse'
    );
    const submitted = this.translateService.instant(
      'response-rfi.status.submitted'
    );

    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'rfi.title', header: 'table.column.title' },
      {
        field: 'rfi.type',
        header: 'table.column.type',
        customClass: 'text-center'
      },
      {
        field: 'submittedDate',
        header: 'table.column.submitedDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'rfi.responseDeadlineDate',
        header: 'table.column.reponseDeadlineDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'isSubmitted',
        header: 'table.column.rfiStatus',
        datamap:
          '{"false" : "' +
          waitingForResponse +
          '", "true" : "' +
          submitted +
          '"}',
        customClass: 'text-center'
      }
    ]);

    this.isFromWidget = this.global.routerParams.get('isFromWidget');
    this.global.routerParams.clear();
  }

  public doEdit(rfiVendor: RfiVendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('rfiVendorId', rfiVendor.id);

    if (rfiVendor.isSubmitted === false) {
      this.global.routerParams.clear();
      this.global.routerParams.set('todo', 'edit');
      this.global.routerParams.set('rfiVendorId', rfiVendor.id);
      this.router.navigate(['/pages/response-rfi/edit']);
    } else {
      this.global.routerParams.clear();
      this.global.routerParams.set('todo', 'detail');
      this.global.routerParams.set('rfiVendorId', rfiVendor.id);
      this.router.navigate(['/pages/response-rfi/detail']);
    }
  }
}
