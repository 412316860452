<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="translation.title"
      detail="{{ module.name }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <div class="button-group button-group-right button-group-icon-text mb-2">
        <app-button
          title="{{ 'app.action.add' | translate }}"
          [disabled]="editedRowId !== -2"
          (onClick)="doAdd()"
        >
          <em class="fas fa-plus"></em>Add Key
        </app-button>
      </div>
      <div class="table-responsive custom-table">
        <table class="table table-striped" aria-describedby="Table">
          <thead>
            <tr>
              <th scope="col" [ngStyle]="{ width: '30%' }" class="text-left"
                >Key</th
              >
              <th
                scope="col"
                *ngFor="let lang of translationResponse.langList"
                class="text-left"
                >{{ lang.name
                }}<span
                  class="flag-icon flag-icon-{{
                    lang.code | lowercase
                  }} ml-2 mt-6`"
                ></span>
              </th>
              <th scope="col" [ngStyle]="{ width: '10%' }" class="text-center"
                >Action</th
              >
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="translationResponse.translationKeyList.length === 0">
              <td colspan="4" class="text-center"
                >{{ 'app.info.noData' | translate }}
              </td>
            </tr>
            <tr
              *ngFor="
                let key of translationResponse.translationKeyList;
                let keyIdx = index
              "
            >
              <th scope="col" *ngIf="key.id === -1" class="text-left"
                ><input
                  class="form-control"
                  maxlength="64"
                  [(ngModel)]="translationResponse.translationKeyList[keyIdx].key"
                  autofocus
              /></th>
              <th scope="col" *ngIf="key.id !== -1" class="text-left">{{
                key.key
              }}</th>
              <td
                *ngFor="let lang of translationResponse.langList"
                (click)="doEditRow(key.id)"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
                class="text-left"
              >
                <div *ngIf="key.id === editedRowId">
                  <div
                    *ngFor="
                      let trans of key.translationList;
                      let transIdx = index
                    "
                  >
                    <div *ngIf="trans.lang.id === lang.id">
                      <input
                        class="form-control"
                        (keyup)="
                          doCheckField(
                            translationResponse.translationKeyList[keyIdx]
                              .translationList
                          )
                        "
                        maxlength="500"
                        [(ngModel)]="
                          translationResponse.translationKeyList[keyIdx]
                            .translationList[transIdx].value
                        "
                      />
                    </div>
                  </div>
                </div>
                <div *ngIf="key.id !== editedRowId">
                  <div *ngFor="let trans of key.translationList">
                    <div *ngIf="trans.lang.id === lang.id">
                      {{ trans.value }}
                    </div>
                  </div>
                </div>
              </td>

              <td class="text-center" [ngStyle]="{ height: '50px' }">
                <div class="button-group button-group-center">
                  <app-button
                    size="TN"
                    color="DANGER"
                    title="{{ 'app.button.delete' | translate }}"
                    (onClick)="doDelete(key, keyIdx)"
                    *ngIf="
                      key.id !== editedRowId &&
                      editedRowId === -2 &&
                      !formSaving &&
                      module.code !== 'DYNAMIC-ATTRIBUTE'
                    "
                  >
                    <em class="fas fa-trash"></em>
                  </app-button>
                  <app-button
                    size="TN"
                    color="SECONDARY"
                    title="{{ 'app.button.cancel' | translate }}"
                    (onClick)="doCancelEditRow()"
                    *ngIf="key.id === editedRowId && !formSaving"
                  >
                    <em class="fas fa-times"></em>
                  </app-button>
                  <app-button
                    size="TN"
                    *ngIf="key.id === editedRowId && !formSaving"
                    title="{{ 'app.button.save' | translate }}"
                    (onClick)="doSave(key)"
                    [disabled]="
                    translationResponse.translationKeyList[keyIdx].key == null ||
                    translationResponse.translationKeyList[keyIdx].key == '' ||
                      isDisable == true
                    "
                  >
                    <em class="fas fa-check"></em>
                  </app-button>
                  <div *ngIf="formSaving && key.id === editedRowId">
                    <img
                      src="assets/img/common/loader-circle-small.gif"
                      alt="Loading"
                      width="auto"
                      height="auto"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="button-group button-group-center mt-5">
        <app-button color="SECONDARY" (onClick)="doCancel()">
          {{ 'app.button.back' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>
