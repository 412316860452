import { TabState } from '../interfaces/tab-state.interface';
import { TabElement } from '../models/tab-element-model';
export function addTabItem(
  tabState: TabState,
  payload: {
    id: string;
    tabItemElement: HTMLElement;
    selected: boolean;
    disabled: boolean;
    isDynamic: boolean;
  }
): void {
  const tabElement = new TabElement(tabState.tabElement);
  if (!payload.isDynamic) {
    const tabItemContent = payload.tabItemElement.children.item(0);
    payload.tabItemElement.removeChild(payload.tabItemElement.children.item(0));
    tabElement.addTabContent(tabItemContent);
    if (
      payload.selected ||
      tabElement.tabNavElement.children.item(0) === payload.tabItemElement
    ) {
      tabItemContent.classList.add('active');
      payload.selected = true;
    }
  }
  tabState.tabDisabled[payload.id] = payload.disabled;
  tabState.tabSelected[payload.id] = payload.selected;
}
