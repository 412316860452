import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppToastComponent } from './app-toast.component';
import { ToastService } from './app-toast.service';
@NgModule({
  imports: [VendorModule],
  declarations: [AppToastComponent],
  entryComponents: [AppToastComponent],
  providers: [ToastService],
  exports: [AppToastComponent]
})
export class AppToastModule {}
