import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BudgetAllocation } from '../../../bean/budget-allocation';
import { AppPopupService } from '../app-popup.service';
import { AppPopupChooseBudgetXComponent } from './app-popup-choose-budget-x.component';

@Injectable()
export class AppPopupChooseBudgetXService {
  constructor(public appPopupService: AppPopupService) {}
  public open(
    isMultiple?: boolean,
    budgetAllocations?: BudgetAllocation[],
    usedBudgetList?: Map<number, number>,
    prItemBudgetEditList?: Map<number, number>
  ): Observable<any> {
    const isMultipleValue = isMultiple ? isMultiple : false;
    return this.appPopupService.open(
      AppPopupChooseBudgetXComponent,
      {
        isMultiple: isMultipleValue,
        budgetAllocations,
        usedBudgetList,
        prItemBudgetEditList
      },
      { size: 'xl' }
    );
  }
}
