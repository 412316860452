import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ItemType } from '../../core/bean/item-type';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { ProcurementParticipant } from '../../core/bean/procurement-participant';
import { ProcurementSchedule } from '../../core/bean/procurement-schedule';
import { TransactionType } from '../../core/bean/transaction-type';
import { WorkflowStepInfoModel } from '../../core/components/app-workflow-step-info/model/workflow-step-info-model';
import { TableRow } from '../../core/components/table/domain/table-row';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { ProcurementNotifResponseModel } from '../../core/model/procurement-notif-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { Validators } from '../../core/validators';
import { DocRetrievalView } from '../../core/view/entity/bean/doc-retrieval-view';
import { WorklistPMService } from '../worklist-pm/worklist-pm.service';
import { DocRetrievalRequest } from './doc-retrieval-request';
import { DocRetrievalResponse } from './doc-retrieval-response';

@Component({
  templateUrl: './doc-retrieval-edit.component.html'
})
export class DocRetrievalEditComponent extends BaseModuleComponent {
  public objectId: number;
  public procurementId: number;
  public urlBackOutside: string;
  public approvalModelPrcsId: number;
  public procurement: Procurement;
  public docRetrievalResponse: DocRetrievalResponse;
  public procurementItemList: ProcurementItem[];
  public itemTypeList: ItemType[];
  public docRetrievalViewList: DocRetrievalView[];
  public workflowStepInfoModel: WorkflowStepInfoModel;
  public tableResponse: TableResponseModel<DocRetrievalView>;
  public token: string;
  public offlineRetrievalProcess: TransactionType;
  public procurementSchedule: ProcurementSchedule;
  public procurementParticipant: ProcurementParticipant;
  public canceledOrFailedMessage: string;
  public isShowBadgeStatus: boolean;

  public badgeColor = {
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    NEW: 'INFO',
    DRAFT: 'SECONDARY',
    HOLD: 'DANGER',
    FAILED: 'DANGER',
    CANCELED: 'DANGER'
  };

  constructor(
    translateService: TranslateService,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute,
    public datePipe: DatePipe,
    public worklistPMService: WorklistPMService
  ) {
    super('doc-retrieval', translateService);
  }
  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken() {
    this.httpClientService
      .get<ProcurementNotifResponseModel>(
        '/doc-retrieval/notification/' + this.token
      )
      .subscribe((response: ProcurementNotifResponseModel) => {
        this.worklistPMService.doNavigateDetail(response, this.moduleCode);
      });
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.setFormGroup();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      recordList: this.tableResponse.formArray
    });
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.objectId = this.global.routerParams.get('objectId');
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.procurement = this.global.routerParams.get('procurement');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementVendor.vendor.name',
        header: 'table.column.vendorName'
      },
      {
        field: 'procurementVendor.vendor.email',
        header: 'table.column.vendorEmail'
      },
      {
        field: 'vendorAddress',
        header: 'table.column.vendorAddress'
      },
      {
        field: 'procurementVendor.registeredDate',
        header: 'table.column.registerDate',
        plugins: {
          name: 'date',
          format: 'short-date-and-time'
        }
      },
      {
        field: 'retrievalDate',
        header: 'table.column.docRetrievalDate',
        plugins: {
          name: 'date-picker',
          time: true,
          maxDate: new Date(this.procurementSchedule.endDate),
          minDate: new Date(this.procurementSchedule.startDate),
          onChange: this.onChangeValue.bind(this),
          onInput: this.onChangeValue.bind(this)
        }
      },
      {
        field: 'retrievalProcess.name',
        header: 'table.column.retrievalProcess',
        plugins: {
          name: 'text-field',
          isView: true
        }
      },
      {
        field: 'retrievalBy',
        header: 'table.column.retrievalBy',
        plugins: {
          name: 'text-field',
          placeholder: this.translateService.instant(
            'doc-retrieval.table.placeholder.typeName'
          ),
          onChange: this.onChangeValue.bind(this),
          onInput: this.onChangeValue.bind(this)
        }
      }
    ]);
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setFormGroup() {
    this.httpClientService
      .post<DocRetrievalResponse>(
        '/doc-retrieval/edit',
        new RouteRequestModel(
          this.todo,
          this.procurementId || this.procurement.id
        )
      )
      .subscribe((response: DocRetrievalResponse) => {
        this.docRetrievalResponse = response;
        this.procurement = response.procurement;
        this.procurementItemList = response.procurementItemList;
        this.itemTypeList = response.itemTypeList;
        this.docRetrievalViewList = response.docRetrievalViewList;
        this.workflowStepInfoModel = response.workflowStepInfoModel;
        this.offlineRetrievalProcess = response.offlineRetrievalProcess;
        this.procurementSchedule = response.procurementSchedule;
        this.procurementParticipant = response.procurementParticipant;
        this.doBuildTableResponse();
        this.doBuildFormGroup();
        this.tableResponse.setRecordList(this.docRetrievalViewList);

        const statusList = [
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
        ];

        if (statusList.includes(this.procurement.procurementStatus.code)) {
          if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'doc-retrieval.alert.msg.sorryWaitingCancelationApproval';
          } else if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'doc-retrieval.alert.msg.sorryWaitingFailedApproval';
          } else {
            this.canceledOrFailedMessage =
              'doc-retrieval.alert.msg.sorryFailedAssessment';
          }
        }
        this.setStateReady();
        this.setIsViewOnly();
      });
  }

  public onAfterRowCreated(tableRow: TableRow): void {
    if (
      tableRow.record.retrievalProcess &&
      tableRow.record.retrievalProcess.code ===
        this.global.appConstant.core.TRANSACTION_TYPE_ONLINE
    ) {
      tableRow.formGroup.setIsView(true);
    }
  }

  public onChangeValue(): void {
    this.tableResponse.currentRowChange.row.formGroup
      .get('retrievalProcessName')
      .patchValue(
        this.translateService.instant(
          this.getTranslateKey(this.offlineRetrievalProcess)
        )
      );

    const currentRowFormGroup =
      this.tableResponse.currentRowChange.row.formGroup;

    if (
      !currentRowFormGroup.get('retrievalBy').value &&
      !currentRowFormGroup.get('retrievalDate').value
    ) {
      this.tableResponse.currentRowChange.row.formGroup
        .get('retrievalProcessName')
        .patchValue(null);
      currentRowFormGroup.get('retrievalDate').clearValidators();
      currentRowFormGroup.get('retrievalBy').clearValidators();
      currentRowFormGroup.get('retrievalBy').markAsUntouched();
      currentRowFormGroup.get('retrievalDate').markAsUntouched();
      this.tableResponse.currentRowChange.row.formGroup
        .get('retrievalBy')
        .updateValueAndValidity();
      this.tableResponse.currentRowChange.row.formGroup
        .get('retrievalDate')
        .updateValueAndValidity();
    } else {
      if (!currentRowFormGroup.get('retrievalBy').value) {
        currentRowFormGroup
          .get('retrievalBy')
          .setValidators(Validators.required());
        currentRowFormGroup.get('retrievalBy').markAsTouched();
        this.tableResponse.currentRowChange.row.formGroup
          .get('retrievalBy')
          .updateValueAndValidity();
      }
      if (!currentRowFormGroup.get('retrievalDate').value) {
        currentRowFormGroup
          .get('retrievalDate')
          .setValidators(Validators.required());
        currentRowFormGroup.get('retrievalDate').markAsTouched();
        this.tableResponse.currentRowChange.row.formGroup
          .get('retrievalDate')
          .updateValueAndValidity();
      } else {
        if (
          new Date(currentRowFormGroup.get('retrievalDate').value).getTime() >
          new Date(this.procurementSchedule.endDate).getTime()
        ) {
          currentRowFormGroup
            .get('retrievalDate')
            .setValidators(Validators.required());
          currentRowFormGroup.get('retrievalDate').setErrors({
            message: this.translateService.instant(
              'doc-retrieval.validation.maxDate'
            )
          });
          currentRowFormGroup
            .get('retrievalDate')
            .markAsTouched({ onlySelf: true });
        } else if (
          new Date(currentRowFormGroup.get('retrievalDate').value).getTime() <
          new Date(this.procurementSchedule.startDate).getTime()
        ) {
          currentRowFormGroup
            .get('retrievalDate')
            .setValidators(Validators.required());
          currentRowFormGroup.get('retrievalDate').setErrors({
            message: this.translateService.instant(
              'doc-retrieval.validation.minDate'
            )
          });
          currentRowFormGroup
            .get('retrievalDate')
            .markAsTouched({ onlySelf: true });
        }
      }
    }

    const recordList = this.tableResponse.getRecordList().map(record => {
      const indexOfR = this.tableResponse
        .getUpdatedRecordList()
        .findIndex(
          updateRecord =>
            updateRecord.procurementVendor.id === record.procurementVendor.id
        );
      return indexOfR === -1
        ? record
        : this.tableResponse.getUpdatedRecordList()[indexOfR];
    });

    this.tableResponse.setRecordList(recordList);
  }

  public doSave(): void {
    this.validate();
    const isHasNull =
      this.tableResponse.getUpdatedRecordList() &&
      this.tableResponse.getUpdatedRecordList().length > 0 &&
      this.tableResponse
        .getUpdatedRecordList()
        .filter(
          (r: DocRetrievalView) =>
            (!r.retrievalDate && r.retrievalBy) ||
            (r.retrievalDate && !r.retrievalBy)
        ).length > 0;
    if (!isHasNull) {
      const docRetrievalRequest: DocRetrievalRequest =
        new DocRetrievalRequest();
      docRetrievalRequest.isSubmit = false;
      docRetrievalRequest.procurement = this.procurement;
      if (
        this.tableResponse.getUpdatedRecordList() &&
        this.tableResponse.getUpdatedRecordList().length > 0
      ) {
        this.tableResponse.getUpdatedRecordList().forEach(element => {
          element.retrievalProcess = this.offlineRetrievalProcess;
        });
        docRetrievalRequest.docRetrievalViewList =
          this.tableResponse.getUpdatedRecordList();
      }
      this.setStateProcessing();
      this.httpClientService
        .post<Response<DocRetrievalResponse>>(
          '/doc-retrieval/update',
          docRetrievalRequest
        )
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.snackbarService.showWarning('app.msg.info.sucsessSave');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set(
                  'procurementId',
                  this.procurementId
                );
                this.global.routerParams.set(
                  'urlBackOutside',
                  '/pages/worklist-pm'
                );
                this.global.routerParams.set('todo', 'edit');
                this.router.navigate(['/pages/doc-retrieval/edit']);
              });
          } else {
            this.global.alertService.showError(response.statusText);
          }
          this.setStateReady();
        });
    } else {
      this.global.alertService.showError(
        this.translateService.instant(
          'doc-retrieval.alert.msg.completeAllRequiredData'
        )
      );
    }
  }

  public doSubmit(): void {
    const currentDate = new Date().getTime();
    const endDate =
      this.procurementSchedule && this.procurementSchedule.endDate
        ? new Date(this.procurementSchedule.endDate).getTime()
        : null;
    if (endDate && currentDate < endDate) {
      const date = this.datePipe.transform(
        this.procurementSchedule.endDate,
        this.global.config.parameterModel.datetimeFormatShort
      );
      this.global.modalService.confirmation(
        this.translateService.instant('doc-retrieval.dueDateAvailable.prompt') +
          ' ' +
          date,
        this.translateService.instant('doc-retrieval.dueDateAvailable.title'),
        null,
        null,
        'pvc pv-sandglass',
        true
      );
    } else {
      this.validate();
      const isHasNull =
        this.tableResponse.getUpdatedRecordList() &&
        this.tableResponse.getUpdatedRecordList().length > 0 &&
        this.tableResponse
          .getUpdatedRecordList()
          .filter(
            (r: DocRetrievalView) =>
              (!r.retrievalDate && r.retrievalBy) ||
              (r.retrievalDate && !r.retrievalBy)
          ).length > 0;
      const isProcurementFailed = this.procurementParticipant
        ? this.tableResponse
            .getRecordList()
            .filter((r: DocRetrievalView) => r.retrievalDate && r.retrievalBy)
            .length < this.procurementParticipant.minimumParticipant
        : false;
      if (!isHasNull && this.formGroup.valid) {
        if (!isProcurementFailed) {
          this.global.modalService
            .submitConfirmation()
            .pipe(take(1))
            .subscribe(result => {
              if (result) {
                this.doSubmitProcess(isProcurementFailed);
              }
            });
        } else {
          this.global.modalService
            .submitProcurementFailed(
              this.procurementParticipant.minimumParticipant
            )
            .pipe(take(1))
            .subscribe(result => {
              if (result) {
                this.doSubmitProcess(isProcurementFailed);
              }
            });
        }
      } else {
        this.global.alertService.showError(
          this.translateService.instant(
            'doc-retrieval.alert.msg.completeAllRequiredData'
          )
        );
      }
    }
  }

  public doSubmitProcess(isProcurementFailed: boolean): void {
    const docRetrievalRequest: DocRetrievalRequest = new DocRetrievalRequest();
    docRetrievalRequest.isSubmit = true;
    docRetrievalRequest.isProcurementFailed = isProcurementFailed;
    docRetrievalRequest.procurement = this.procurement;
    if (
      this.tableResponse.getUpdatedRecordList() &&
      this.tableResponse.getUpdatedRecordList().length > 0
    ) {
      this.tableResponse.getUpdatedRecordList().forEach(element => {
        element.retrievalProcess = this.offlineRetrievalProcess;
      });
      docRetrievalRequest.docRetrievalViewList =
        this.tableResponse.getUpdatedRecordList();
    }
    this.setStateProcessing();
    this.httpClientService
      .post<Response<DocRetrievalResponse>>(
        '/doc-retrieval/update',
        docRetrievalRequest
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.modalService.submitSuccess().subscribe(result => {
            if (result) {
              this.router.navigate(['/pages/worklist-pm/']);
            } else {
              this.router
                .navigateByUrl('/', {
                  skipLocationChange: true
                })
                .then(() => {
                  this.global.routerParams.clear();
                  this.global.routerParams.set(
                    'procurementId',
                    this.procurementId
                  );
                  this.global.routerParams.set(
                    'urlBackOutside',
                    '/pages/worklist-pm'
                  );
                  this.global.routerParams.set('todo', 'view');
                  this.router.navigate(['/pages/doc-retrieval/detail']);
                });
            }
          });
        } else {
          this.global.alertService.showError(response.statusText);
        }
        this.setStateReady();
      });
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }
}
