import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Currency } from 'src/app/core/bean/currency';
import { AppItemRequestInitiationCatalogModel } from 'src/app/core/components/app-item-request/app-item-request-initiation-catalog-model';
import { AppPopupChooseBudgetXService } from 'src/app/core/components/app-popup/app-popup-choose-budget-x/app-popup-choose-budget-x.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { FileObject, FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { TabModel } from 'src/app/core/model/tab/tab-model';
import { TabResponseModel } from 'src/app/core/model/tab/tab-response-model';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './initiation-item-catalog-add.component.html',
  styleUrls: ['./initiation-catalog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InitiationItemCatalogAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  public prResponse: AppItemRequestInitiationCatalogModel = new AppItemRequestInitiationCatalogModel();
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel();
  public prItem: AppItemRequestInitiationCatalogModel = new AppItemRequestInitiationCatalogModel();
  public autoCompleteValue: any;
  public fileUploader: FileUploader = new FileUploader(
    '/initiation/',
    '',
    this.global.appConstant.fileType.IMG_ITEM_PR
  );
  public tabResponse: TabResponseModel;
  public tabTwo: TabModel;
  public backToUrl: string;
  //public prItemBudgetEditList: Map<number, number> = new Map<number, number>(); // budgetAllocationId, prItemBugetAmount

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupChooseBudgetService: AppPopupChooseBudgetXService
  ) {
    super('initiation', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setOptionModel();
    this.buildFormGroup();
    this.setStateReady();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.prResponse = this.global.routerParams.get('prResponse');
    this.tabResponse = this.global.routerParams.get('tabResponse');
    this.backToUrl = this.global.routerParams.get('backToUrl');
    this.tabTwo = this.tabResponse.currentTab;
    // this.prItemBudgetEditList = this.global.routerParams.get(
    //   'prItemBudgetEditList'
    // );
  }

  public setOptionModel(): void {
    this.currencyOptionList.setRequestValues(this.prResponse.currencyList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      prItemList: this.formBuilder.array([])
    });
    this.buildFormArray();
  }

  public get prItemList(): FormArray {
    return this.formGroup.get('prItemList') as FormArray;
  }

  public newPrItem(): FormGroup {
    const formGroup = this.formBuilder.group({
      id: [null],
      pr: [null],
      item: [null, Validators.required()],
      prices: [null],
      price: [null, Validators.required()],
      totalPrice: [null, Validators.required()],
      quantity: [
        null,
        Validators.compose([Validators.required(), Validators.min(1)])
      ],
      specification: [null, Validators.required()],
      itemImages: [null],
      currency: [this.prResponse.companyCurrency],
      totalBudget: [null],
      catalog: [null],
      prItemBudgetList: this.formBuilder.array([]),
      budgetAllocationList: this.formBuilder.array([]),
      prItemImageList: this.formBuilder.array([])
    });
    return formGroup;
  }

  public buildFormArray(): void {
    this.prItemList.push(this.newPrItem());
  }
  public autoCompleteValueChange(event: any): void {
    this.autoCompleteValue = event;
  }

  public doDeletePrItem(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.prItemList.removeAt(index);
        }
      });
  }
  public addPrItem(): void {
    this.prItemList.push(this.newPrItem());
  }

  public onChangeQuantity(i: number): void {
    const prices =
      this.prItemList.value[i].prices && this.prItemList.value[i].prices.price;

    const quantity = this.prItemList.value[i].quantity;

    let totalPrice = 0;
    if (prices && quantity) {
      totalPrice = +prices * +quantity;
      this.prItemList.controls[i].patchValue({
        prices: {
          price: prices
        }
      });

      this.prItemList.controls[i].patchValue({
        price: prices
      });
    }
    this.prItemList.controls[i].patchValue({
      totalPrice
    });
  }

  public addToItemRequest(): void {
    this.global.modalService
      .initiationAddToItemRequestModal()
      .subscribe(result => {
        this.validate();
        if (this.formGroup.valid) {
          if (result) {
            this.prItemList.controls.forEach(prItem => {
              prItem.value.item.name = prItem.value.item.name.split(' - ')[1];
              const prItemImageList = prItem.get('prItemImageList') as FormArray;
              prItem.get('itemImages').value &&
                prItem.get('itemImages').value.forEach(itemImage => {
                  const fileObjectList: FileObject[] = [];
                  fileObjectList.push(itemImage);
                  const formGroupItem = this.formBuilder.group({
                    fileObjectList: [fileObjectList],
                    file: itemImage.file
                  });
                  prItemImageList.push(formGroupItem);
                });
            });
            this.prItemList.value.forEach(prItem => {
              prItem.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
              this.prResponse.prItemList.push(prItem);
            });
            this.prResponse.prItemList.forEach((prItem, index) => {
              prItem.code = '00' + (+index + +1);
            });
            this.global.routerParams.set('prResponse', this.prResponse);
            this.router.navigate(['/pages/initiation/add']);
          } else {
            this.prItemList.controls.forEach(prItem => {
              prItem.value.item.name = prItem.value.item.name.split(' - ')[1];
              const prItemImageList = prItem.get('prItemImageList') as FormArray;
              prItem.get('itemImages').value &&
                prItem.get('itemImages').value.forEach(itemImage => {
                  const fileObjectList: FileObject[] = [];
                  fileObjectList.push(itemImage);
                  const formGroupItem = this.formBuilder.group({
                    fileObjectList: [fileObjectList],
                    file: itemImage.file
                  });
                  prItemImageList.push(formGroupItem);
                });
            });
            this.prItemList.value.forEach(prItem => {
              prItem.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_INSERT;
              this.prResponse.prItemList.push(prItem);
            });
            this.prResponse.prItemList.forEach((prItem, index) => {
              prItem.code = '00' + (+index + +1);
            });
            this.global.routerParams.set('prResponse', this.prResponse);
            this.formGroup.reset();
            let index: number = 0;
            this.prItemList.value.forEach(() => {
              this.prItemList.removeAt(index);
              index++;
            });
            this.addPrItem();
          }
        }
      });
  }
}
