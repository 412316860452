import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Module } from 'src/app/core/bean/module';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { PushNotification } from '../../core/bean/push-notification';
import { PushNotificationTemplate } from '../../core/bean/push-notification-template';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { PushNotificationRequest } from './push-notification-request';
import { PushNotificationResponse } from './push-notification-response';

@Component({
  templateUrl: './push-notification-edit.component.html'
})
export class PushNotificationEditComponent extends BaseModuleComponent {
  public pushNotificationId: number;
  public pushNotification: PushNotification;
  public pushNotificationResponse: PushNotificationResponse =
    new PushNotificationResponse();
  public pushNotificationRequest: PushNotificationRequest;
  public module: Module;
  public iconList: OptionListModel<any> = new OptionListModel();
  public statusList: OptionListModel<any> = new OptionListModel(
    false,
    'name',
    'value'
  );

  constructor(translateService: TranslateService) {
    super('push-notification', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setStatusListRequestValue();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.pushNotificationId =
      this.global.routerParams.get('pushNotificationId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      name: [null],
      description: [null],
      icon: [null],
      keyword: [null],
      isActive: [null],
      moduleGroupCode: [null],
      moduleName: [null],
      statusList: [null],
      notifTemplate: this.formBuilder.array([])
    });
  }

  public buildForm(): void {
    this.pushNotificationResponse.langList.forEach(lang => {
      const notifTemplateList: PushNotificationTemplate[] = [];
      this.pushNotificationResponse.pushNotificationTemplateList.forEach(
        notifTemplate => {
          if (notifTemplate.lang.id === lang.id) {
            notifTemplateList.push(notifTemplate);
          }
        }
      );
      this.notifTemplate.push(
        this.formBuilder.group({
          name: lang.name,
          notifTemplateDetail: this.buildNotifTemplate(notifTemplateList)
        })
      );
    });
    this.setStateReady();
  }

  public get notifTemplate(): FormArray {
    return this.formGroup.controls.notifTemplate as FormArray;
  }

  public buildNotifTemplate(
    notifTemplateList: PushNotificationTemplate[]
  ): FormArray {
    const controlLang = new FormArray([]);
    notifTemplateList.forEach(notifTemplate => {
      const { id, subject, body, lang } = notifTemplate;
      controlLang.push(
        this.formBuilder.group({
          id,
          body,
          lang,
          subject: [
            subject,
            Validators.compose([
              Validators.required(),
              Validators.maxLength(128)
            ])
          ]
        })
      );
    });
    return controlLang;
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<PushNotification>(
        '/push-notification/edit',
        new RouteRequestModel(this.todo, this.pushNotificationId)
      )
      .subscribe((pushNotificationResponse: PushNotificationResponse) => {
        if (pushNotificationResponse.pushNotification != null) {
          this.pushNotificationResponse = pushNotificationResponse;
          const { id, name, code, description, icon, keyword } =
            pushNotificationResponse.pushNotification;
          const {} = pushNotificationResponse.pushNotificationTemplateList;
          const { name: moduleName, moduleGroupCode } =
            pushNotificationResponse.pushNotification.module;
          this.module = pushNotificationResponse.pushNotification.module;
          this.iconList.setRequestValues(pushNotificationResponse.iconList);
          const status = this.statusList
            .getRequestValues()
            .filter(
              valueOption =>
                valueOption.value ===
                pushNotificationResponse.pushNotification.isActive
            )[0];
          this.setStatusListRequestValue();
          this.formGroup.patchValue({
            id,
            code,
            description,
            icon,
            keyword,
            moduleName,
            moduleGroupCode,
            isActive: status,
            name
          });
        }
        this.setStateReady();
        this.formGroup.get('code').setIsView(true);
        this.formGroup.get('moduleName').setIsView(true);
        this.formGroup.get('moduleGroupCode').setIsView(true);
        this.formGroup.get('keyword').setIsView(true);
        this.buildForm();
      });
  }

  public setStatusListRequestValue(): void {
    const statusList = [
      { name: 'Active', value: true },
      { name: 'Non Active', value: false }
    ];
    this.statusList.setRequestValues(statusList);
  }

  public getFormArray(
    formGroup: FormGroup,
    formControlName: string
  ): FormArray {
    return formGroup.get(formControlName) as FormArray;
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const pushNotification: PushNotification =
              this.global.copyFormAttributeToModel(
                new PushNotification(),
                this.formGroup
              );
            pushNotification.module = this.module;
            pushNotification.isActive =
              this.formGroup.get('isActive').value.value;
            const pushNotificationRequest: PushNotificationRequest =
              new PushNotificationRequest();
            const notificationTemplateList: Array<PushNotificationTemplate> =
              new Array();
            this.formGroup.value.notifTemplate.forEach(notifTemplate => {
              notifTemplate.notifTemplateDetail.forEach(
                (notifTemplateDetail: PushNotificationTemplate) => {
                  notificationTemplateList.push(notifTemplateDetail);
                }
              );
            });
            pushNotificationRequest.pushNotification = pushNotification;
            pushNotificationRequest.pushNotificationTemplate =
              notificationTemplateList;
            const url = this.todo === 'edit' ? '/push-notification/update' : '';
            this.httpClientService
              .post<Response<PushNotificationRequest>>(
                url,
                pushNotificationRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService.confirmation(
                    'push-notification.saveSuccess.msg',
                    'push-notification.saveSuccess.title',
                    null,
                    null,
                    'pvc pv-check-circle',
                    null,
                    { autoCloseInMs: 1500 }
                  );
                  this.router.navigate(['/pages/push-notification/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }
}
