import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { FileType } from '../../core/bean/file-type';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  selector: 'app-popup-document',
  templateUrl: './app-popup-document.component.html'
})
export class AppPopupDocumentComponent extends BaseComponentComponent {
  @Input() public fileTypeIdList: number[];
  @Input() public fileTypeIdCustomData: any;
  @Output() public onChange: EventEmitter<FileType> = new EventEmitter();

  public tableResponse: TableResponseModel<FileType>;

  constructor() {
    super('app-popup-document');
  }

  onInit(): void {
    this.setFileTypeIdCustomData();
    this.buildTableResponse();
  }

  public setFileTypeIdCustomData(): void {
    this.fileTypeIdCustomData =
      this.fileTypeIdList.length !== 0
        ? JSON.stringify(this.fileTypeIdList)
        : null;
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      // { field: 'code', header: 'table.column.code' },
      // { field: 'nameTranslated', header: 'table.column.translationKey' },
      { field: '0.code', header: 'table.column.code' },
      { field: '1', header: 'table.column.translationKey' }
    ]);
  }

  public doChoose(fileType: FileType): void {
    this.onChange.emit(fileType);
  }
}
