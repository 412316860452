import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppApprovalPathXComponent } from './app-approval-path-x.component';
@NgModule({
  imports: [VendorModule, DirectivesModule],
  declarations: [AppApprovalPathXComponent],
  exports: [AppApprovalPathXComponent]
})
export class AppApprovalPathXModule {}
