<app-layout [isLoading]="formLoading" [header]="header" [backTo]="urlBack">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-alert-x
        *ngIf="isCancelation && !formLoading"
        color="DANGER"
        [isBold]="false"
      >
        {{ 'open-quotation.alert.msg.cancelationApproval' | translate }}
      </app-alert-x>

      <app-alert-x
        *ngIf="isFailedAssessment && !formLoading"
        color="DANGER"
        [isBold]="false"
      >
        {{ 'open-quotation.alert.msg.failApproval' | translate }}
      </app-alert-x>
      <form [formGroup]="formGroup">
        <app-card>
          <app-table-xx
            [model]="tableResponseAdmCriteria"
            [isShowSearch]="false"
            *ngIf="!formLoading"
            header="open-quotation.quotationDetail.administrationCriteria.title"
            tips="open-quotation.quotationDetail.administrationCriteria.tips"
          >
            <ng-template #headerButtons>
              <app-button
                color="PRIMARY"
                [outline]="true"
                (onClick)="doDownloadAll($event, 'ADMINISTRATION')"
              >
                {{ 'open-quotation.button.downloadAll' | translate }}
              </app-button>
              <app-button (onClick)="doViewProfile()">
                {{ 'open-quotation.button.viewProfile' | translate }}
              </app-button>
            </ng-template>
          </app-table-xx>
        </app-card>
        <app-card>
          <app-table-xx
            [model]="tableResponseTechnicalCriteria"
            [isShowSearch]="false"
            *ngIf="!formLoading"
            header="open-quotation.quotationDetail.technicalCriteria.title"
            tips="open-quotation.quotationDetail.technicalCriteria.tips"
          >
            <ng-template #headerButtons>
              <app-button
                color="PRIMARY"
                [outline]="true"
                (onClick)="doDownloadAll($event, 'TECHNICAL')"
              >
                {{ 'open-quotation.button.downloadAll' | translate }}
              </app-button>
              <app-button (onClick)="doViewProfile()">
                {{ 'open-quotation.button.viewProfile' | translate }}
              </app-button>
            </ng-template>
          </app-table-xx>
        </app-card>
        <app-card
          header="open-quotation.quotationDetail.vendorQuotation.title"
          tips="open-quotation.quotationDetail.vendorQuotation.tips"
          *ngIf="
            !formLoading &&
            (procurement.documentSubmissionType.code ===
              global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_ONE_ENVELOPE ||
              (procurement.documentSubmissionType.code ===
                global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE &&
                procurement.documentSubmissionMethod.code ===
                  global.appConstant.pm.DOCUMENT_SUBMISSION_METHOD_STAGE_2) ||
              (procurement.documentSubmissionType.code ===
                global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_ENVELOPE &&
                procurement.documentSubmissionMethod.code ===
                  global.appConstant.pm.DOCUMENT_SUBMISSION_METHOD_ENVELOPE_2))
          "
        >
          <app-table-xx
            [model]="tableResponseQuotationItem"
            [isShowPagination]="true"
            *ngIf="!formLoading"
          >
            <ng-template #headerFilter>
              <div class="form-group row text-sm-left">
                <div class="col text-left" [ngStyle]="{ 'min-width': '10rem' }">
                  <app-dropdown-select
                    size="XL"
                    formControlName="itemType"
                    type="COMBOBOX"
                    [optionList]="itemTypeOptionList"
                    (onChange)="onChangeItem()"
                    container="body"
                    placeholder="open-quotation.filter.placeholder.type"
                  ></app-dropdown-select>
                </div>
              </div>
            </ng-template>
            <ng-container
              *ngIf="tableResponseQuotationItem.getRecordList().length > 0"
            >
              <ng-template #row>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="4">
                    {{ 'open-quotation.table.footer.goodsAmount' | translate }}
                  </td>
                  <td class="text-right">
                    <strong>
                      {{
                        formGroup.value.goodsAmount | resolveNumberToCurrency
                      }}
                      IDR
                    </strong>
                  </td>
                  <td colspan="99" class="d-flex justify-content-end">
                    {{
                      quotationDetailResponse.quotation.goodsProfitLossAmount
                        ? global.converterService.convertMoney(
                            quotationDetailResponse.quotation
                              .goodsProfitLossAmount
                          )
                        : '' || '-'
                    }}&nbsp;{{
                      quotationDetailResponse.quotation.goodsProfitLossAmount
                        ? '%'
                        : ''
                    }}
                    <app-badge
                      *ngIf="
                        quotationDetailResponse.quotation.goodsProfitLossStatus
                      "
                      [pill]="false"
                      [color]="
                        quotationDetailResponse.quotation.goodsProfitLossStatus.code.toLowerCase() ===
                        global.appConstant.pm.PROFIT_LOSS_STATUS_SAVE.toLowerCase()
                          ? 'SUCCESS'
                          : 'DANGER'
                      "
                      customClass="badge-catalog ml-3"
                    >
                      {{
                        quotationDetailResponse.quotation.goodsProfitLossStatus
                          .name
                      }}
                    </app-badge>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="4">
                    {{
                      'open-quotation.table.footer.serviceAmount' | translate
                    }}
                  </td>
                  <td class="text-right">
                    <strong>
                      {{
                        formGroup.value.serviceAmount | resolveNumberToCurrency
                      }}
                      IDR
                    </strong>
                  </td>
                  <td colspan="99" class="d-flex justify-content-end">
                    {{
                      quotationDetailResponse.quotation.serviceProfitLossAmount
                        ? global.converterService.convertMoney(
                            quotationDetailResponse.quotation
                              .serviceProfitLossAmount
                          )
                        : '' || '-'
                    }}&nbsp;{{
                      quotationDetailResponse.quotation.serviceProfitLossAmount
                        ? '%'
                        : ''
                    }}
                    <app-badge
                      *ngIf="
                        quotationDetailResponse.quotation
                          .serviceProfitLossStatus
                      "
                      [pill]="false"
                      [color]="
                        quotationDetailResponse.quotation.serviceProfitLossStatus.code.toLowerCase() ===
                        global.appConstant.pm.PROFIT_LOSS_STATUS_SAVE.toLowerCase()
                          ? 'SUCCESS'
                          : 'DANGER'
                      "
                      customClass="badge-catalog ml-3"
                    >
                      {{
                        quotationDetailResponse.quotation
                          .serviceProfitLossStatus.name
                      }}
                    </app-badge>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="4">
                    {{ 'open-quotation.table.footer.amount' | translate }}
                  </td>
                  <td class="text-right">
                    <strong>
                      {{ formGroup.value.amount | resolveNumberToCurrency }}
                      IDR
                    </strong>
                    <br />
                    <i>
                      ({{
                        formGroup.value.amount
                          | resolveNumberToWords: global.currentLang
                      }}
                      {{ 'Rupiah' }})
                    </i>
                  </td>
                  <td colspan="99" class="d-flex justify-content-end">
                    {{
                      quotationDetailResponse.quotation.profitLossAmount
                        ? global.converterService.convertMoney(
                            quotationDetailResponse.quotation.profitLossAmount
                          )
                        : '' || '-'
                    }}&nbsp;{{
                      quotationDetailResponse.quotation.profitLossAmount
                        ? '%'
                        : ''
                    }}
                    <app-badge
                      *ngIf="quotationDetailResponse.quotation.profitLossStatus"
                      [pill]="false"
                      [color]="
                        quotationDetailResponse.quotation.profitLossStatus.code.toLowerCase() ===
                        global.appConstant.pm.PROFIT_LOSS_STATUS_SAVE.toLowerCase()
                          ? 'SUCCESS'
                          : 'DANGER'
                      "
                      customClass="badge-catalog ml-3"
                    >
                      {{
                        quotationDetailResponse.quotation.profitLossStatus.name
                      }}
                    </app-badge>
                  </td>
                </tr>
              </ng-template>
            </ng-container>
          </app-table-xx>
        </app-card>
        <app-card
          header="open-quotation.quotationDetail.quotationAdministration.title"
          tips="open-quotation.quotationDetail.quotationAdministration.tips"
          *ngIf="
            !formLoading &&
            (procurement.documentSubmissionType.code ===
              global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_ONE_ENVELOPE ||
              (procurement.documentSubmissionType.code ===
                global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE &&
                procurement.documentSubmissionMethod.code ===
                  global.appConstant.pm.DOCUMENT_SUBMISSION_METHOD_STAGE_2) ||
              (procurement.documentSubmissionType.code ===
                global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_ENVELOPE &&
                procurement.documentSubmissionMethod.code ===
                  global.appConstant.pm.DOCUMENT_SUBMISSION_METHOD_ENVELOPE_2))
          "
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'open-quotation.quotationDetail.form.letterNumber' | translate
              }}
            </label>
            <div class="col-sm-9 text-left">
              {{ quotationDetailResponse.quotationAdministration.letterNumber }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'open-quotation.quotationDetail.form.validityPeriod' | translate
              }}
            </label>
            <div class="col-sm-9 text-left">
              <app-date-picker-x
                formControlName="validityPeriodAdm"
                [range]="true"
              ></app-date-picker-x>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'open-quotation.quotationDetail.form.currency' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              {{
                quotationDetailResponse.quotationAdministration.currency.code
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'open-quotation.quotationDetail.form.letterOfQuotation'
                  | translate
              }}
            </label>
            <div class="col-sm-6 text-left">
              <app-upload-x
                formControlName="documentBidBond"
                [fileUploader]="fileUploaderBidBond"
              ></app-upload-x>
            </div>
          </div>
        </app-card>
        <app-card
          header="open-quotation.quotationDetail.quotationBidBond.title"
          tips="open-quotation.quotationDetail.quotationBidBond.tips"
          *ngIf="
            !formLoading &&
            (procurement.documentSubmissionType.code ===
              global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_ONE_ENVELOPE ||
              (procurement.documentSubmissionType.code ===
                global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE &&
                procurement.documentSubmissionMethod.code ===
                  global.appConstant.pm.DOCUMENT_SUBMISSION_METHOD_STAGE_2) ||
              (procurement.documentSubmissionType.code ===
                global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_ENVELOPE &&
                procurement.documentSubmissionMethod.code ===
                  global.appConstant.pm.DOCUMENT_SUBMISSION_METHOD_ENVELOPE_2))
          "
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'open-quotation.quotationDetail.form.bidBondType' | translate
              }}
            </label>
            <div class="col-sm-9 text-left">
              {{
                getTranslateKey(
                  quotationDetailResponse.quotationBidBond.bondType
                ) | translate
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'open-quotation.quotationDetail.form.bidBondNumber' | translate
              }}
            </label>
            <div class="col-sm-9 text-left">
              {{ quotationDetailResponse.quotationBidBond.bidBondNumber }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'open-quotation.quotationDetail.form.bidBondValue' | translate
              }}
            </label>
            <div class="col-sm-9 text-left">
              {{
                global.converterService.convertMoney(
                  quotationDetailResponse.quotationBidBond.amount
                )
              }}&nbsp;IDR
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'open-quotation.quotationDetail.form.validityPeriod' | translate
              }}
            </label>
            <div class="col-sm-9 text-left">
              <app-date-picker-x
                formControlName="validityPeriodBidBond"
                [range]="true"
              ></app-date-picker-x>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'open-quotation.quotationDetail.form.bidBondFile' | translate
              }}
            </label>
            <div class="col-sm-6 text-left">
              <app-upload-x
                formControlName="documentBidBond"
                [fileUploader]="fileUploaderBidBond"
              ></app-upload-x>
            </div>
          </div>
        </app-card>
        <app-card
          header="open-quotation.quotationDetail.quotationStatus.title"
          tips="open-quotation.quotationDetail.quotationStatus.tips"
        >
          <div
            class="form-group row text-sm-left"
            *ngIf="formGroup.isView || isStatusChoosen"
            [ngStyle]="{ 'padding-left': '15px' }"
          >
            <label>{{
              'open-quotation.quotationDetail.quotationStatus.information'
                | translate
            }}</label
            >&nbsp;{{ vendorName }}&nbsp;<label>{{
              'open-quotation.quotationDetail.quotationStatus.is' | translate
            }}</label
            >&nbsp;<label
              [ngClass]="
                quotation.validityStatus
                  ? quotation.validityStatus.code ===
                    global.appConstant.pm.VALIDITY_STATUS_INVALID
                    ? 'text-danger'
                    : 'text-success'
                  : ''
              "
            >
              {{ getTranslateKey(quotation.validityStatus) | translate }}
            </label>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-2">
              {{
                'open-quotation.quotationDetail.form.validityStatus' | translate
              }}
            </label>
            <div class="col-sm-10 text-left">
              <app-dropdown-select
                type="COMBOBOX"
                formControlName="validityStatus"
                [optionList]="validityStatusOptionList"
                placeholder="open-quotation.placeholder.chooseOne"
                size="LG"
                (onChange)="doChangeStatus()"
              >
                <ng-template #value let-value>
                  <div *ngIf="value" style="display: inline-flex">
                    <em
                      style="font-size: 1.5rem"
                      [ngClass]="getColorAndIconConfirm[value.code].icon"
                    >
                    </em>
                    &nbsp;<span
                      class="ml-1"
                      [ngClass]="getColorAndIconConfirm[value.code].color"
                      >{{ getTranslateKey(value) | translate }}</span
                    >
                  </div>
                  <div *ngIf="!value && todo ==='view'">-</div>
                </ng-template>
                <ng-template #option let-data>
                  {{ getTranslateKey(data) | translate }}
                </ng-template>
              </app-dropdown-select>
            </div>
          </div>
          <div
            class="form-group row text-sm-left"
            *ngIf="
              formGroup.value.validityStatus?.code ===
              global.appConstant.pm.VALIDITY_STATUS_INVALID
            "
          >
            <label class="col-sm-2">
              {{
                'open-quotation.quotationDetail.form.invalidReason' | translate
              }}
            </label>
            <div class="col-sm-10 text-left">
              <app-text-area
                maxLength="512"
                formControlName="invalidNotes"
                size="LG"
              ></app-text-area>
            </div>
          </div>
        </app-card>
        <div class="floating-button mt-5" *ngIf="!formGroup.isView">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center">
                <app-button
                  (onClick)="doSave()"
                  [disabled]="formSaving"
                  *ngIf="!formGroup.isView"
                  >{{ 'app.button.save' | translate }}</app-button
                >
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
