import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ReviewContractHistory } from '../../bean/review-contract-history';
import { FieldFormatEnum } from '../app-table/model/field-format.enum';
import { TableResponseModel } from '../app-table/model/table-response-model';

@Component({
  selector: 'app-contract-review-log',
  templateUrl: './app-contract-review-log.component.html'
})
export class AppContractReviewLogComponent extends BaseComponentComponent {
  @Input() public contractId: number;
  @Input() public code: string;
  public tableResponse: TableResponseModel<ReviewContractHistory>;

  constructor() {
    super('app-contract-review-log');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setCustomData();
    this.setStateReady();
  }

  public setCustomData(): void {
    this.tableResponse.setCustomData({
      contractId: this.contractId,
      code: this.code
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'file.fileName',
        header: 'table.column.file',
        customClass: 'text-center',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName'
      },
      {
        field: 'status',
        header: 'table.column.status',
        customClass: 'text-center',
        fn: this.handleStatusTextColor.bind(this)
      },
      {
        field: 'user.name',
        header: 'table.column.pic',
        customClass: 'text-left'
      },
      {
        field: 'note',
        header: 'table.column.note',
        customClass: 'white-space-normal'
      },
      {
        field: 'date',
        header: 'table.column.date',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDateAndTime
      }
    ]);
  }

  public handleStatusTextColor(value: string, record: any): HTMLElement {
    this.log.debug(value);
    const spanElement = document.createElement('span');
    if (record.status === 1) {
      spanElement.textContent = this.global.translateService.instant(
        'app-contract-review-log.approved'
      );
    } else if (record.status === 2) {
      spanElement.textContent = this.global.translateService.instant(
        'app-contract-review-log.revision'
      );
    }
    return spanElement;
  }
}
