<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="scoring-criteria-group.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'scoring-criteria-group.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              size="SM"
              formControlName="code"
              type="code"
              maxLength="32"
              tooltip="{{ 'scoring-criteria-group.tooltip.code' | translate }}"
            >
            </app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'scoring-criteria-group.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="name" maxLength="32">
            </app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'scoring-criteria-group.form.type' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-radio
              formControlName="type"
              position="HORIZONTAL"
              [optionList]="typeOptionList"
            >
            </app-radio>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'scoring-criteria-group.form.description' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxLength="512">
            </app-text-area>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'scoring-criteria-group.form.moduleScoringCriteria' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-radio
              formControlName="moduleScoringCriteria"
              position="HORIZONTAL"
              [optionList]="moduleOptionList"
            >
            </app-radio>
          </div>
        </div>

        <div class="form-group row required">
          <div class="col-lg-12 text-center">
            <app-pick-list
              [model]="scoringCriteriaPickListModel"
              searchSourcePlaceholder="scoring-criteria-group.appPickList.scoringCriteriaPlaceHolder"
              searchTargetPlaceholder="scoring-criteria-group.appPickList.scoringCriteriaPlaceHolder"
              sourceTitle="scoring-criteria-group.appPickList.scoringCriteriaSourceTitle"
              targetTitle="scoring-criteria-group.appPickList.scoringCriteriaTargetTitle"
            >
              <!-- <ng-template #source let-scoringCriteria>
                  <div style="font-size:14px;float:left;margin:0 px 0px 0 0;">
                      <span style="display:inline-block;margin:2px 0 2px 2px" width="30"></span>
                      {{scoringCriteria.name}}
                  </div>
              </ng-template>
              <ng-template #target let-scoringCriteria>
                  <div style="font-size:14px;float:left;margin:0 px 0px 0 0;">
                      <span style="display:inline-block;margin:2px 0 2px 2px" width="30"></span>
                      {{scoringCriteria.name}}
                  </div>
              </ng-template> -->
            </app-pick-list>
            <small
              class="text-danger"
              [ngStyle]="{ 'margin-left': '18%' }"
              *ngIf="formGroup.value.isCriteriaEmpty == false"
            >
              {{ 'app.validation.required' | translate }}
            </small>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button color="SECONDARY" class="mr-1" (onClick)="doCancel()">
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button color="PRIMARY" (onClick)="doSave()">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
