import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Pr } from 'src/app/core/bean/pr';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from 'src/app/core/components/app-table-x/model/table-response-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Committee } from '../../core/bean/committee';

@Component({
  templateUrl: './committee.component.html'
})
export class CommitteeComponent extends BaseModuleComponent implements OnInit {
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  public tableResponse: TableResponseModel<Committee>;

  constructor(translateService: TranslateService) {
    super('committee', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        plugins: 'hyperlink'
      },
      {
        field: 'name',
        header: 'table.column.committeeName'
      },
      {
        field: 'committeeType.name',
        header: 'table.column.type'
      },
      {
        field: 'description',
        header: 'table.column.description'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/committee/add']);
    this.global.routerParams.set('urlBackOutside', '/pages/committee');
  }

  public doClick(committee: Committee): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('committee', committee);
    this.global.routerParams.set('urlBackOutside', '/pages/committee');
    this.global.routerParams.set('todo', 'edit');
    this.router.navigate(['/pages/committee/edit']);
  }

  public doDelete(event): void {
    this.global.modalService
      .newDeleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<Pr[]>>('/committee/delete', event.checkedRecordList)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }
}
