import { BaseEntity } from '../base/base-entity';
import { ProposalCriteria } from '../bean/proposal-criteria';
import { Rfp } from '../bean/rfp';

export class RfpProposalCriteriaEntity extends BaseEntity {
  crudOperation: number;

  rfp: Rfp = new Rfp();
  proposalCriteria: ProposalCriteria = new ProposalCriteria();
  weightPercentage: number;
}
