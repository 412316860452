import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { WidgetAccordionListModule } from '../../components/widget-accordion-list/widget-accordion-list.module';
import { WidgetService } from '../../widget.service';
import { WidgetContractStatusComponent } from './widget-contract-status.component';
@NgModule({
  imports: [VendorModule, WidgetAccordionListModule],
  declarations: [WidgetContractStatusComponent],
  entryComponents: [WidgetContractStatusComponent],
  exports: [WidgetContractStatusComponent]
})
export class WidgetContractStatusModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetContractStatusComponent.moduleCode,
      WidgetContractStatusComponent,
      'w-25'
    );
  }
}
