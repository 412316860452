import { ValidatorFn } from '@angular/forms';
import { MatchValidator } from './match.validator';
export class MatchPasswordValidator {
  public static matchPassword(
    matchWith = 'password',
    message?: string
  ): ValidatorFn {
    return MatchValidator.match(matchWith, message, 'matchPassword');
  }
}
