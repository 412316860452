import { Component, ViewChild } from '@angular/core';
import { FormGroup, NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { ProcurementOffline } from 'src/app/core/bean/procurement-offline';
import { ProcurementType } from 'src/app/core/bean/procurement-type';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { BudgetAllocation } from './../../core/bean/budget-allocation';
import { Organization } from './../../core/bean/organization';
import { ProcurementMethod } from './../../core/bean/procurement-method';
import { ProcurementOfflineBudget } from './../../core/bean/procurement-offline-budget';
import { ProcurementOfflinePayment } from './../../core/bean/procurement-offline-payment';
import { ProcurementOfflinePaymentHistory } from './../../core/bean/procurement-offline-payment-history';
import { PurchaseRequestBudget } from './../../core/bean/purchase-request-budget';
import { PurchaseRequestData } from './../../core/bean/purchase-request-data';
import { AppTableComponent } from './../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from './../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from './../../core/components/app-table/model/table-response-model';
import { FileObject } from './../../core/components/upload/model/file-object';
import { FileUploader } from './../../core/components/upload/model/v1/file.uploader';
import { Response } from './../../core/model/response-model';
import { ResponseStatusModel } from './../../core/model/response-status-model';
import { RouteRequestModel } from './../../core/model/route-request-model';
import { Validators } from './../../core/validators/index';
import { ChangeBudget } from './../work-plan/work-plan-item.model';
import { AppPopupChooseBudgetByFilterComponent } from './app-popup-choose-budget-by-filter.component';
import { ProcurementOfflineRequest } from './procurement-offline-request.component';
import { ProcurementOfflineResponse } from './procurement-offline-response.component';
import { PurchaseRequestBudgetModel } from './purchase-request-budget.model';
@Component({
  templateUrl: './procurement-offline-submission-edit-add.component.html'
})
export class ProcurementOfflineSubmissionEditAddComponent extends BaseModuleComponent {
  @ViewChild('selectorPurchaseRequest')
  tablePurchaseRequestBudget: AppTableComponent;
  public tableResponsePurchaseRequest: TableResponseModel<PurchaseRequestBudget>;

  @ViewChild('selectorProcurement') tableProcurement: AppTableComponent;
  public tableResponseProcurement: TableResponseModel<ProcurementOfflineBudget>;

  @ViewChild('selectorPayment') tablePayment: AppTableComponent;
  public tableResponsePayment: TableResponseModel<ProcurementOfflinePayment>;

  @ViewChild('selectorPaymentHistory') tablePaymentHistory: AppTableComponent;
  public tableResponsePaymentHistory: TableResponseModel<ProcurementOfflinePaymentHistory>;

  public purchaseRequestDataId: number;
  public procurementOfflineResponse: ProcurementOfflineResponse =
    new ProcurementOfflineResponse();
  public formGroupProcurement: FormGroup;
  public purchaseRequestBudgetList: PurchaseRequestBudget[] = [];
  public procurementOfflinePaymentList: ProcurementOfflinePayment[] = [];
  public procurementOfflineBudgetList: ProcurementOfflineBudget[] = [];
  public procurementOfflinePaymentHistoryList: ProcurementOfflinePaymentHistory[] =
    [];
  public purchaseRequestData: PurchaseRequestData = new PurchaseRequestData();
  public procurementOfflineRequest: ProcurementOfflineRequest =
    new ProcurementOfflineRequest();
  public totalBudget = 0;
  public totalProcurementValue = 0;
  public totalPurchaseValue = 0;
  public totalPaymentValue = 0;
  public errorMapper: Map<number, string> = new Map();
  public currentDate: Date = new Date();
  public currentYear: number = new Date().getFullYear();
  public showButton = true;
  public purchaseRequestBudgetModel: PurchaseRequestBudgetModel =
    new PurchaseRequestBudgetModel();
  public budgetIdList: number[] = [];
  public isPageFromConfirmation: boolean;
  public remainingPayment: Map<number, number> = new Map();
  public autoCompleteValue: any;
  public userCustomData: ProcurementType;
  public procurementTypeOptionList: OptionListModel<ProcurementType> =
    new OptionListModel(false);
  public procurementMethodOptionList: OptionListModel<ProcurementMethod> =
    new OptionListModel(false);
  public procurementTypeParams: Object;
  public fileUploader: FileUploader = new FileUploader(
    '/procurement-offline-submission/',
    'news.form.headerImage',
    this.global.appConstant.fileType.DOC_PROCUREMENT_OFFLINE,
    false,
    5
  );

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('procurement-offline-submission', translateService);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setDataFromRouterParam();
    this.setFormGroup();
  }

  public setDataFromRouterParam(): void {
    this.todo = this.global.routerParams.get('todo');
    this.procurementOfflineResponse = this.global.routerParams.get(
      'procurementOfflineResponse'
    );
    this.purchaseRequestDataId =
      this.global.routerParams.get('purchaseRequestId');
    this.isPageFromConfirmation = this.global.routerParams.get(
      'isPageFromConfirmation'
    );
    this.remainingPayment = this.global.routerParams.get('remainingPayment');
    this.remainingPayment = this.remainingPayment
      ? this.remainingPayment
      : new Map<number, number>();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      number: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      createdDate: [null, Validators.required()],
      organization: [null, Validators.required()]
    });

    this.formGroupProcurement = this.formBuilder.group({
      id: [null],
      number: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.maxLength(64),
          Validators.procurementNumber()
        ])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      createdDate: [null, Validators.required()],
      procurementType: [null, Validators.required()],
      procurementMethod: [null, Validators.required()],
      vendorName: [null, Validators.required()],
      description: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(512)])
      ],
      user: [null, Validators.required()],
      file: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    if (this.todo !== 'confirmation' && !this.isPageFromConfirmation) {
      this.httpClientService
        .post<ProcurementOfflineResponse>(
          '/procurement-offline-submission/add-edit',
          new RouteRequestModel(this.todo, this.purchaseRequestDataId)
        )
        .subscribe((procurementOfflineResponse: ProcurementOfflineResponse) => {
          this.procurementOfflineResponse = procurementOfflineResponse;
          this.setDataProcurementResponse();
          this.setStateReady();
          if (
            this.todo === 'edit' &&
            this.procurementOfflineResponse.purchaseRequestData
              .procurementStatus.code ===
            this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
          ) {
            this.formGroupProcurement.get('user').disable();
          }
        });
    } else {
      this.setDataProcurementResponse();
      this.setStateReady();
      if (
        this.todo === 'edit' &&
        this.procurementOfflineResponse.purchaseRequestData.procurementStatus
          .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST &&
        !this.isPageFromConfirmation
      ) {
        this.formGroupProcurement.get('user').disable();
      }
    }
  }

  public setDataProcurementResponse(): void {
    /* Jika back saat pertama membuat PR */
    if (this.todo === 'add' && this.isPageFromConfirmation) {
      this.formGroup.patchValue({
        id: null,
        number: this.procurementOfflineResponse.purchaseRequestData.number,
        createdDate:
          this.procurementOfflineResponse.purchaseRequestData.createdDate,
        organization: this.procurementOfflineResponse.organizationPurchaseList
      });
      this.purchaseRequestBudgetList =
        this.procurementOfflineResponse.purchaseRequestData.purchaseRequestBudgetList;
      this.purchaseRequestBudgetList.forEach(purchaseBudget => {
        this.totalPurchaseValue =
          this.totalPurchaseValue + +purchaseBudget.value;
      });
    }
    /* Set Data saat PR sudah ada */
    if (this.todo !== 'add') {
      this.procurementTypeOptionList.setRequestValues(
        this.procurementOfflineResponse.procurementTypeList
      );
      this.procurementMethodOptionList.setRequestValues(
        this.procurementOfflineResponse.procurementMethodList
      );
      if (
        this.procurementOfflineResponse.purchaseRequestData &&
        this.procurementOfflineResponse.purchaseRequestData.procurementStatus &&
        this.procurementOfflineResponse.purchaseRequestData.procurementStatus
          .code !== this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
      ) {
        /* handle button save And Continue saat edit payment budget*/
        this.showButton = false;
      }
      this.setViewOnly();
      this.formGroup.setIsView(true);
      this.formGroup.patchValue({
        id: null,
        number: this.procurementOfflineResponse.purchaseRequestData.number,
        createdDate:
          this.procurementOfflineResponse.purchaseRequestData.createdDate,
        organization: this.procurementOfflineResponse.organizationPurchaseList
      });
      this.purchaseRequestBudgetList =
        this.procurementOfflineResponse.purchaseRequestData.purchaseRequestBudgetList;
      this.purchaseRequestBudgetList.forEach(purchaseBudget => {
        this.totalPurchaseValue =
          this.totalPurchaseValue + +purchaseBudget.value;
      });
      if (
        this.todo === 'edit' &&
        this.procurementOfflineResponse.purchaseRequestData.procurementStatus &&
        this.procurementOfflineResponse.purchaseRequestData.procurementStatus
          .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
      ) {
        /* set data saat pembuatan procurement offline */
        if (this.isPageFromConfirmation) {
          this.fileUploader.setFileList(
            this.procurementOfflineResponse.fileList
          );
          this.formGroupProcurement.patchValue({
            id: null,
            number: this.procurementOfflineResponse.procurementOffline.number,
            name: this.procurementOfflineResponse.procurementOffline.name,
            createdDate:
              this.procurementOfflineResponse.procurementOffline.createdDate,
            procurementType:
              this.procurementOfflineResponse.procurementOffline
                .procurementType,
            procurementMethod:
              this.procurementOfflineResponse.procurementOffline
                .procurementMethod,
            vendorName:
              this.procurementOfflineResponse.procurementOffline.vendorName,
            description:
              this.procurementOfflineResponse.procurementOffline.description,
            user: this.procurementOfflineResponse.procurementOffline.user,
            file: this.fileUploader.fileObjectList
          });
          this.procurementOfflineBudgetList =
            this.procurementOfflineResponse.procurementOffline.procurementOfflineBudgetList;
          this.procurementOfflineBudgetList.forEach(
            (procurementBudget: ProcurementOfflineBudget) => {
              this.totalProcurementValue =
                this.totalProcurementValue + +procurementBudget.value;
            }
          );
        } else {
          this.purchaseRequestBudgetList.forEach(purchaseRequestBudget => {
            const procurementOfflineBudget: ProcurementOfflineBudget =
              new ProcurementOfflineBudget();
            procurementOfflineBudget.procurementOffline =
              this.procurementOfflineResponse.procurementOffline;
            procurementOfflineBudget.purchaseRequestBudget =
              purchaseRequestBudget;
            procurementOfflineBudget.value = purchaseRequestBudget.value;
            this.totalProcurementValue =
              this.totalProcurementValue + +procurementOfflineBudget.value;
            this.procurementOfflineBudgetList.push(procurementOfflineBudget);
          });
        }
        this.procurementOfflineBudgetList.forEach(procurementOfflineBudget => {
          const budget: ChangeBudget = {
            id: procurementOfflineBudget.purchaseRequestBudget.budgetAllocation
              .id,
            isChecked: true,
            workProgram:
              procurementOfflineBudget.purchaseRequestBudget.budgetAllocation
                .workProgram,
            organization:
              procurementOfflineBudget.purchaseRequestBudget.budgetAllocation
                .organization,
            currency:
              procurementOfflineBudget.purchaseRequestBudget.budgetAllocation
                .currency,
            costCenter:
              procurementOfflineBudget.purchaseRequestBudget.budgetAllocation
                .costCenter,
            coa: procurementOfflineBudget.purchaseRequestBudget.budgetAllocation
              .coa,
            budgetType:
              procurementOfflineBudget.purchaseRequestBudget.budgetAllocation
                .budgetType,
            plannedBudget: !this.isPageFromConfirmation
              ? procurementOfflineBudget.purchaseRequestBudget.value
              : procurementOfflineBudget.value
          };
          this.purchaseRequestBudgetModel.recordPageMap.set(
            procurementOfflineBudget.purchaseRequestBudget.budgetAllocation.id,
            budget
          );
        });
      }
      if (
        this.procurementOfflineResponse.purchaseRequestData.procurementStatus &&
        this.procurementOfflineResponse.purchaseRequestData.procurementStatus
          .code !== this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
      ) {
        /* set data saat edit payment */
        this.formGroupProcurement.setIsView(true);
        this.fileUploader.setFileList(this.procurementOfflineResponse.fileList);
        this.formGroupProcurement.patchValue({
          id: this.procurementOfflineResponse.procurementOffline.id,
          number: this.procurementOfflineResponse.procurementOffline.number,
          name: this.procurementOfflineResponse.procurementOffline.name,
          createdDate:
            this.procurementOfflineResponse.procurementOffline.createdDate,
          procurementType:
            this.procurementOfflineResponse.procurementOffline.procurementType,
          procurementMethod:
            this.procurementOfflineResponse.procurementOffline
              .procurementMethod,
          vendorName:
            this.procurementOfflineResponse.procurementOffline.vendorName,
          description:
            this.procurementOfflineResponse.procurementOffline.description,
          user: this.procurementOfflineResponse.procurementOffline.user,
          file: this.fileUploader.fileObjectList
        });
        this.procurementOfflineBudgetList =
          this.procurementOfflineResponse.procurementOffline.procurementOfflineBudgetList;
        this.procurementOfflineBudgetList.forEach(
          (procurementBudget: ProcurementOfflineBudget) => {
            this.totalProcurementValue =
              this.totalProcurementValue + +procurementBudget.value;
          }
        );
        if (
          this.todo === 'edit' &&
          this.procurementOfflineResponse.purchaseRequestData.procurementStatus
            .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PROCUREMENT_PROCESS
        ) {
          /* set data jika pertama kali edit paymenyt budget */
          if (this.isPageFromConfirmation) {
            this.procurementOfflinePaymentList =
              this.procurementOfflineResponse.procurementOfflinePaymentList;
          } else {
            this.procurementOfflineBudgetList.forEach(
              procurementOfflineBudget => {
                const procurementOfflinePayment: ProcurementOfflinePayment =
                  new ProcurementOfflinePayment();
                procurementOfflinePayment.procurementOfflineBudget =
                  procurementOfflineBudget;
                procurementOfflinePayment.value =
                  procurementOfflineBudget.value;
                this.procurementOfflinePaymentList.push(
                  procurementOfflinePayment
                );
              }
            );
          }
        } else if (
          /* set data saat edit payment yang ke-2 kali dan selanjutnya, dan saat confirmation insert payment*/
          this.procurementOfflineResponse.purchaseRequestData.procurementStatus
            .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PAYMENT_PROCESS ||
          this.procurementOfflineResponse.purchaseRequestData.procurementStatus
            .code === this.global.appConstant.esm.PROCUREMENT_STATUS_CLOSED ||
          (this.todo === 'confirmation' &&
            this.procurementOfflineResponse.purchaseRequestData
              .procurementStatus.code ===
            this.global.appConstant.esm.PROCUREMENT_STATUS_PROCUREMENT_PROCESS)
        ) {
          this.procurementOfflinePaymentList =
            this.procurementOfflineResponse.procurementOfflinePaymentList;
        }

        this.procurementOfflinePaymentList.forEach(
          (procurementOfflinePayment: ProcurementOfflinePayment) => {
            const budget: ChangeBudget = {
              id: procurementOfflinePayment.procurementOfflineBudget
                .purchaseRequestBudget.budgetAllocation.id,
              isChecked: true,
              workProgram:
                procurementOfflinePayment.procurementOfflineBudget
                  .purchaseRequestBudget.budgetAllocation.workProgram,
              organization:
                procurementOfflinePayment.procurementOfflineBudget
                  .purchaseRequestBudget.budgetAllocation.organization,
              currency:
                procurementOfflinePayment.procurementOfflineBudget
                  .purchaseRequestBudget.budgetAllocation.currency,
              costCenter:
                procurementOfflinePayment.procurementOfflineBudget
                  .purchaseRequestBudget.budgetAllocation.costCenter,
              coa: procurementOfflinePayment.procurementOfflineBudget
                .purchaseRequestBudget.budgetAllocation.coa,
              budgetType:
                procurementOfflinePayment.procurementOfflineBudget
                  .purchaseRequestBudget.budgetAllocation.budgetType,
              plannedBudget:
                this.procurementOfflineResponse.purchaseRequestData
                  .procurementStatus.code ===
                  this.global.appConstant.esm.PROCUREMENT_STATUS_PAYMENT_PROCESS &&
                  this.todo !== 'confirmation' &&
                  !this.isPageFromConfirmation
                  ? procurementOfflinePayment.procurementOfflineBudget.value -
                  +procurementOfflinePayment.value
                  : procurementOfflinePayment.value
            };
            this.purchaseRequestBudgetModel.recordPageMap.set(
              procurementOfflinePayment.procurementOfflineBudget
                .purchaseRequestBudget.budgetAllocation.id,
              budget
            );
            this.totalPaymentValue =
              this.totalPaymentValue + +budget.plannedBudget;
            if (this.todo !== 'confirmation' && !this.isPageFromConfirmation) {
              this.remainingPayment.set(
                procurementOfflinePayment.procurementOfflineBudget
                  .purchaseRequestBudget.budgetAllocation.id,
                budget.plannedBudget
              );
            }
          }
        );
      }
      if (
        this.todo === 'confirmation' &&
        this.procurementOfflineResponse.purchaseRequestData.procurementStatus &&
        this.procurementOfflineResponse.purchaseRequestData.procurementStatus
          .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
      ) {
        /* set data di confirmation page untuk insert procurement */
        this.formGroupProcurement.setIsView(true);
        this.fileUploader.setFileList(this.procurementOfflineResponse.fileList);
        this.formGroupProcurement.patchValue({
          id: null,
          number: this.procurementOfflineResponse.procurementOffline.number,
          name: this.procurementOfflineResponse.procurementOffline.name,
          createdDate:
            this.procurementOfflineResponse.procurementOffline.createdDate,
          procurementType:
            this.procurementOfflineResponse.procurementOffline.procurementType,
          procurementMethod:
            this.procurementOfflineResponse.procurementOffline
              .procurementMethod,
          vendorName:
            this.procurementOfflineResponse.procurementOffline.vendorName,
          description:
            this.procurementOfflineResponse.procurementOffline.description,
          user: this.procurementOfflineResponse.procurementOffline.user,
          file: this.fileUploader.fileObjectList
        });
        this.procurementOfflineBudgetList =
          this.procurementOfflineResponse.procurementOffline.procurementOfflineBudgetList;
        this.procurementOfflineBudgetList.forEach(
          (procurementBudget: ProcurementOfflineBudget) => {
            this.totalProcurementValue =
              this.totalProcurementValue + +procurementBudget.value;
          }
        );
        this.procurementOfflinePaymentList = this.procurementOfflineResponse
          .procurementOfflinePaymentList
          ? this.procurementOfflineResponse.procurementOfflinePaymentList
          : [];
      }
      if (
        this.procurementOfflineResponse.procurementOfflinePaymentHistoryList &&
        this.procurementOfflineResponse.procurementOfflinePaymentHistoryList
          .length > 0
      ) {
        this.procurementOfflinePaymentHistoryList =
          this.procurementOfflineResponse.procurementOfflinePaymentHistoryList;
      }
    }
    this.formGroup.get('number').setIsView(true);
    this.buildTableResponse();
    this.log.debug(this.procurementOfflineResponse);
  }

  public autoCompleteValueChange(event: any): void {
    if (this.formGroupProcurement.get('procurementType').value) {
      this.userCustomData =
        this.formGroupProcurement.get('procurementType').value;
      this.autoCompleteValue = event;
    } else {
      this.global.alertService.showError(
        this.translateService.instant(
          'procurement-offline-submission.msg.alert.procurementTypeEmpty'
        )
      );
    }
  }

  public buildTableResponse(): void {
    this.tableResponsePurchaseRequest = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'budgetAllocation.organization.name',
          header: 'table.column.organization',
          customClass: 'text-center'
        },
        {
          field: 'budgetAllocation.workProgram.name',
          header: 'table.column.workProgram',
          customClass: 'text-center'
        },
        {
          field: 'budgetAllocation.costCenter.code',
          header: 'table.column.costCenterCode',
          customClass: 'text-center'
        },
        {
          field: 'budgetAllocation.costCenter.name',
          header: 'table.column.costCenterName'
        },
        {
          field: 'budgetAllocation.coa.code',
          header: 'table.column.coaCode',
          customClass: 'text-center'
        },
        {
          field: 'budgetAllocation.coa.name',
          header: 'table.column.coaName'
        },
        {
          field: 'budgetAllocation.budgetType.code',
          header: 'table.column.budgetType',
          customClass: 'text-center'
        },
        {
          field: 'value',
          header: 'table.column.value',
          format: FieldFormatEnum.Currency,
          currencyCodeRef: 'budgetAllocation.currency.code'
        }
      ]
    );
    this.tableResponsePurchaseRequest.setRecords(
      this.purchaseRequestBudgetList
    );
    this.tableResponsePurchaseRequest.setTotalRecords(
      this.purchaseRequestBudgetList.length
    );

    this.tableResponseProcurement = new TableResponseModel(this.moduleCode, [
      {
        field: 'purchaseRequestBudget.budgetAllocation.organization.name',
        header: 'table.column.organization',
        customClass: 'text-center'
      },
      {
        field: 'purchaseRequestBudget.budgetAllocation.workProgram.name',
        header: 'table.column.workProgram',
        customClass: 'text-center'
      },
      {
        field: 'purchaseRequestBudget.budgetAllocation.costCenter.code',
        header: 'table.column.costCenterCode',
        customClass: 'text-center'
      },
      {
        field: 'purchaseRequestBudget.budgetAllocation.costCenter.name',
        header: 'table.column.costCenterName'
      },
      {
        field: 'purchaseRequestBudget.budgetAllocation.coa.code',
        header: 'table.column.coaCode',
        customClass: 'text-center'
      },
      {
        field: 'purchaseRequestBudget.budgetAllocation.coa.name',
        header: 'table.column.coaName'
      },
      {
        field: 'purchaseRequestBudget.budgetAllocation.budgetType.code',
        header: 'table.column.budgetType',
        customClass: 'text-center'
      }
    ]);
    this.tableResponseProcurement.setRecords(this.procurementOfflineBudgetList);
    this.tableResponseProcurement.setTotalRecords(
      this.procurementOfflineBudgetList.length
    );

    this.tableResponsePayment = new TableResponseModel(this.moduleCode, [
      {
        field:
          'procurementOfflineBudget.purchaseRequestBudget.budgetAllocation.organization.name',
        header: 'table.column.organization',
        customClass: 'text-center'
      },
      {
        field:
          'procurementOfflineBudget.purchaseRequestBudget.budgetAllocation.workProgram.name',
        header: 'table.column.workProgram',
        customClass: 'text-center'
      },
      {
        field:
          'procurementOfflineBudget.purchaseRequestBudget.budgetAllocation.costCenter.code',
        header: 'table.column.costCenterCode',
        customClass: 'text-center'
      },
      {
        field:
          'procurementOfflineBudget.purchaseRequestBudget.budgetAllocation.costCenter.name',
        header: 'table.column.costCenterName'
      },
      {
        field:
          'procurementOfflineBudget.purchaseRequestBudget.budgetAllocation.coa.code',
        header: 'table.column.coaCode',
        customClass: 'text-center'
      },
      {
        field:
          'procurementOfflineBudget.purchaseRequestBudget.budgetAllocation.coa.name',
        header: 'table.column.coaName'
      },
      {
        field:
          'procurementOfflineBudget.purchaseRequestBudget.budgetAllocation.budgetType.code',
        header: 'table.column.budgetType',
        customClass: 'text-center'
      },
      {
        field: 'procurementOfflineBudget.value',
        header: 'table.column.procurementOfflineValue',
        format: FieldFormatEnum.Currency,
        currencyCodeRef:
          'procurementOfflineBudget.purchaseRequestBudget.budgetAllocation.currency.code'
      },
      {
        field: 'value',
        header: 'table.column.remainingPayment',
        customClass: 'text-right',
        fn: this.calculateRemaining.bind(this)
      }
    ]);
    this.tableResponsePayment.setRecords(this.procurementOfflinePaymentList);
    this.tableResponsePayment.setTotalRecords(
      this.procurementOfflinePaymentList.length
    );

    this.tableResponsePaymentHistory = new TableResponseModel(this.moduleCode, [
      {
        field: 'user.name',
        header: 'table.column.user'
      },
      {
        field: 'createdDate',
        header: 'table.column.createdDate',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: 'value',
        header: 'table.column.value',
        format: FieldFormatEnum.Currency
      }
    ]);
    this.tableResponsePaymentHistory.setRecords(
      this.procurementOfflinePaymentHistoryList
    );
    this.tableResponsePaymentHistory.setTotalRecords(
      this.procurementOfflinePaymentHistoryList.length
    );
  }

  public calculateRemaining(
    paymentValue: number,
    procurementPayment: ProcurementOfflinePayment
  ): string {
    let remainingValue: number;
    remainingValue = this.remainingPayment.get(
      procurementPayment.procurementOfflineBudget.purchaseRequestBudget
        .budgetAllocation.id
    );
    if (
      this.todo === 'edit' &&
      !this.isPageFromConfirmation &&
      this.procurementOfflineResponse.purchaseRequestData.procurementStatus
        .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PAYMENT_PROCESS
    ) {
      const payment = this.procurementOfflinePaymentList.filter(
        payment =>
          payment.procurementOfflineBudget.id ===
          procurementPayment.procurementOfflineBudget.id
      );
      this.procurementOfflinePaymentList[
        this.procurementOfflinePaymentList.indexOf(payment[0])
      ].value = remainingValue;
    }

    this.log.debug(paymentValue);
    let remaining: string =
      this.global.converterService.convertMoney(remainingValue);
    remaining =
      (remaining ? remaining : '0.00 ') +
      ' ' +
      procurementPayment.procurementOfflineBudget.purchaseRequestBudget
        .budgetAllocation.currency.code;
    return remaining;
  }

  public doDeleteBudget(
    purchaseBudget: PurchaseRequestBudget,
    index: number
  ): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const oldValue: number = +purchaseBudget.value;
          this.totalPurchaseValue = +this.totalPurchaseValue - +oldValue;
          this.purchaseRequestBudgetList.splice(index, 1);
          if (this.purchaseRequestBudgetList.length === 0) {
            const purchaseEmpty: PurchaseRequestBudget[] = [];
            this.purchaseRequestBudgetList = purchaseEmpty;
            this.budgetIdList = [];
            this.totalPurchaseValue = 0;
          }
          this.budgetIdList.splice(index, 1);
          this.tableResponsePurchaseRequest.removeAt(index);
        }
      });
  }
  public doAddBudget(): void {
    const todo = 'add';
    const budgetTypeList = this.procurementOfflineResponse.budgetTypeList;
    const costCenterList = this.procurementOfflineResponse.costCenterList;
    const currencyList = this.procurementOfflineResponse.currencyList;
    const budgetIdList = this.budgetIdList;

    this.appPopupService
      .open(
        AppPopupChooseBudgetByFilterComponent,
        {
          todo,
          budgetTypeList,
          costCenterList,
          currencyList,
          budgetIdList
        },
        { size: 'xl' }
      )
      .subscribe((purchaseRequestBudgetList: PurchaseRequestBudget[]) => {
        purchaseRequestBudgetList.forEach(purchaseBudget => {
          this.purchaseRequestBudgetList.push(purchaseBudget);
          this.budgetIdList.push(purchaseBudget.budgetAllocation.id);
          this.totalPurchaseValue =
            this.totalPurchaseValue + +purchaseBudget.value;
        });
        this.tableResponsePurchaseRequest.setRecords(
          this.purchaseRequestBudgetList
        );
        this.tableResponsePurchaseRequest.setTotalRecords(
          this.purchaseRequestBudgetList.length
        );
        this.tableResponsePurchaseRequest.reloadClient();
        this.log.debug(purchaseRequestBudgetList);
      });
  }

  public doEditBudget(purchaseRequestBudget: PurchaseRequestBudget): void {
    const todo = 'edit';
    const budgetTypeList = this.procurementOfflineResponse.budgetTypeList;
    const costCenterList = this.procurementOfflineResponse.costCenterList;
    const currencyList = this.procurementOfflineResponse.currencyList;
    const budgetIdList = this.budgetIdList;
    const purchaseRequestBudgetEdit = purchaseRequestBudget;

    this.appPopupService
      .open(
        AppPopupChooseBudgetByFilterComponent,
        {
          todo,
          budgetTypeList,
          costCenterList,
          currencyList,
          budgetIdList,
          purchaseRequestBudgetEdit
        },
        { size: 'xl' }
      )
      .subscribe((purchaseRequestBudgetList: PurchaseRequestBudget[]) => {
        purchaseRequestBudgetList.forEach(purchaseBudget => {
          if (
            purchaseBudget.budgetAllocation.id ===
            purchaseRequestBudget.budgetAllocation.id
          ) {
            const purchaseRequest = this.purchaseRequestBudgetList.filter(
              purchaseBudget =>
                purchaseBudget.budgetAllocation.id ===
                purchaseRequestBudget.budgetAllocation.id
            );
            const oldValue: number = +purchaseRequest[0].value;
            const newValue: number = +purchaseBudget.value;
            this.totalPurchaseValue =
              this.totalPurchaseValue - +oldValue + +newValue;
            const index = this.purchaseRequestBudgetList.indexOf(
              purchaseRequest[0]
            );
            this.purchaseRequestBudgetList[index].value = purchaseBudget.value;
          } else {
            this.purchaseRequestBudgetList.push(purchaseBudget);
            this.budgetIdList.push(purchaseBudget.budgetAllocation.id);
            this.totalPurchaseValue =
              this.totalPurchaseValue + +purchaseBudget.value;
          }
        });
        if (
          purchaseRequestBudgetList.filter(
            purchaseBudget =>
              purchaseBudget.budgetAllocation.id ===
              purchaseRequestBudget.budgetAllocation.id
          ).length <= 0
        ) {
          const purchaseRequest = this.purchaseRequestBudgetList.filter(
            purchaseBudget =>
              purchaseBudget.budgetAllocation.id ===
              purchaseRequestBudget.budgetAllocation.id
          );
          const oldValue: number = +purchaseRequest[0].value;
          this.totalPurchaseValue = this.totalPurchaseValue - oldValue;
          const index = this.purchaseRequestBudgetList.indexOf(
            purchaseRequest[0]
          );
          this.purchaseRequestBudgetList.splice(index, 1);
          this.budgetIdList.splice(
            this.budgetIdList.indexOf(
              purchaseRequestBudget.budgetAllocation.id
            ),
            1
          );
        }
        this.tableResponsePurchaseRequest.setRecords(
          this.purchaseRequestBudgetList
        );
        this.tableResponsePurchaseRequest.setTotalRecords(
          this.purchaseRequestBudgetList.length
        );
        this.tableResponsePurchaseRequest.reloadClient();
        this.log.debug(purchaseRequestBudgetList);
      });
  }

  public onChangeProcurementType(): void {
    if (!this.formGroupProcurement.get('procurementType').value) {
      this.formGroupProcurement.get('user').patchValue(null);
      this.formGroupProcurement.get('user').disable();
    } else {
      this.formGroupProcurement.get('user').enable();
      this.formGroupProcurement.get('user').patchValue(null);
      this.procurementTypeParams = {
        procurementTypeCode:
          this.formGroupProcurement.get('procurementType').value.code
      };
    }
  }

  public doSave(nextAction: string): void {
    if (!this.purchaseRequestBudgetList || (
      this.purchaseRequestBudgetList && this.purchaseRequestBudgetList.length <= 0)) {
        this.validate();
        this.global.alertService.showError(
        this.translateService.instant('procurement-offline-submission.msg.error.listBudgetEmpty')
      );
    } else {
      if (
        (!this.procurementOfflineResponse.purchaseRequestData ||
          !this.procurementOfflineResponse.purchaseRequestData
            .procurementStatus) &&
        this.purchaseRequestBudgetList &&
        this.purchaseRequestBudgetList.length > 0
      ) {
        this.formGroup.get('number').patchValue('-');
        this.validate();
      } else if (
        this.procurementOfflineResponse.purchaseRequestData &&
        this.procurementOfflineResponse.purchaseRequestData.procurementStatus &&
        this.procurementOfflineResponse.purchaseRequestData.procurementStatus
          .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
      ) {
        this.formGroupProcurement.setStateSubmitted();
        this.formGroupService.validateAllFormFields(this.formGroupProcurement);
        if (!this.formGroupProcurement.valid) {
          this.formGroupProcurement.resetStateSubmitted();
        }
      }

      if (
        this.errorMapper.size === 0 &&
        (((!this.procurementOfflineResponse.purchaseRequestData ||
          !this.procurementOfflineResponse.purchaseRequestData
            .procurementStatus) &&
          this.formGroup.valid) ||
          (this.procurementOfflineResponse.purchaseRequestData &&
            this.procurementOfflineResponse.purchaseRequestData
              .procurementStatus &&
            this.procurementOfflineResponse.purchaseRequestData.procurementStatus
              .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST &&
            this.formGroupProcurement.valid) ||
          (this.procurementOfflineResponse.purchaseRequestData &&
            this.procurementOfflineResponse.purchaseRequestData
              .procurementStatus &&
            this.procurementOfflineResponse.purchaseRequestData.procurementStatus
              .code !== this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST))
      ) {
        this.formSaving = true;
        if (
          !this.procurementOfflineResponse.purchaseRequestData ||
          !this.procurementOfflineResponse.purchaseRequestData.procurementStatus
        ) {
          const purchaseRequestData: PurchaseRequestData =
            new PurchaseRequestData();
          purchaseRequestData.createdDate =
            this.formGroup.get('createdDate').value;
          purchaseRequestData.number = this.formGroup.get('number').value;
          const organizationList: Organization[] =
            this.formGroup.get('organization').value;
          purchaseRequestData.organization =
            organizationList[organizationList.length - 1];
          purchaseRequestData.purchaseRequestBudgetList =
            this.purchaseRequestBudgetList;
          this.procurementOfflineResponse.purchaseRequestData =
            purchaseRequestData;
          this.procurementOfflineResponse.organizationPurchaseList =
            organizationList;
        } else if (
          this.procurementOfflineResponse.purchaseRequestData.procurementStatus
            .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
        ) {
          const procurementOffline: ProcurementOffline = new ProcurementOffline();
          procurementOffline.number =
            this.formGroupProcurement.get('number').value;
          procurementOffline.name = this.formGroupProcurement.get('name').value;
          procurementOffline.createdDate =
            this.formGroupProcurement.get('createdDate').value;
          procurementOffline.description =
            this.formGroupProcurement.get('description').value;
          procurementOffline.procurementMethod =
            this.formGroupProcurement.get('procurementMethod').value;
          procurementOffline.procurementType =
            this.formGroupProcurement.get('procurementType').value;
          procurementOffline.user = this.formGroupProcurement.get('user').value;
          procurementOffline.vendorName =
            this.formGroupProcurement.get('vendorName').value;
          procurementOffline.purchaseRequestData =
            this.procurementOfflineResponse.purchaseRequestData;
          procurementOffline.procurementOfflineBudgetList =
            this.procurementOfflineBudgetList;
          this.procurementOfflineResponse.fileList =
            this.fileUploader.fileObjectList.map(
              (fileObject: FileObject) => fileObject.file
            );
          this.procurementOfflineResponse.fileObjectList =
            this.fileUploader.fileObjectList;
          this.procurementOfflineResponse.procurementOffline = procurementOffline;
        } else if (
          this.procurementOfflineResponse.purchaseRequestData.procurementStatus
            .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PROCUREMENT_PROCESS ||
          this.procurementOfflineResponse.purchaseRequestData.procurementStatus
            .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PAYMENT_PROCESS
        ) {
          this.procurementOfflineResponse.procurementOfflinePaymentList =
            this.procurementOfflinePaymentList;
        }
        if (
          this.procurementOfflineResponse.purchaseRequestData &&
          this.procurementOfflineResponse.purchaseRequestData.procurementStatus &&
          this.procurementOfflineResponse.purchaseRequestData.procurementStatus
            .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
        ) {
          this.httpClientService
            .get<Response<ProcurementOfflineResponse>>('/procurement-offline-submission/check-number?number=' + this.procurementOfflineResponse.procurementOffline.number)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.routerParams.clear();
                this.global.routerParams.set(
                  'purchaseRequestId',
                  this.procurementOfflineResponse.purchaseRequestData.id
                );
                this.global.routerParams.set(
                  'procurementOfflineResponse',
                  this.procurementOfflineResponse
                );
                this.global.routerParams.set('todo', 'confirmation');
                this.global.routerParams.set(
                  'nextUrl',
                  nextAction === 'save'
                    ? '/pages/procurement-offline-submission'
                    : '/pages/procurement-offline-submission/edit/'
                );
                this.router.navigate([
                  '/pages/procurement-offline-submission/confirmation'
                ]);
                this.setStateReady();
              } else {
                this.setStateReady();
                this.global.alertService.showError(response.statusText);
              }
            });
        } else {
          this.global.routerParams.clear();
          this.global.routerParams.set(
            'purchaseRequestId',
            this.procurementOfflineResponse.purchaseRequestData.id
          );
          this.global.routerParams.set(
            'procurementOfflineResponse',
            this.procurementOfflineResponse
          );
          this.global.routerParams.set('remainingPayment', this.remainingPayment);
          this.global.routerParams.set('todo', 'confirmation');
          this.global.routerParams.set(
            'nextUrl',
            nextAction === 'save'
              ? '/pages/procurement-offline-submission'
              : '/pages/procurement-offline-submission/edit/'
          );
          this.router.navigate([
            '/pages/procurement-offline-submission/confirmation'
          ]);
          this.setStateReady();
        }
      }
    }

  }

  public doCancel(): void {
    this.router.navigate(['/pages/procurement-offline-submission/']);
  }

  public doBack(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set(
      'purchaseRequestId',
      this.procurementOfflineResponse.purchaseRequestData.id
    );
    this.global.routerParams.set(
      'procurementOfflineResponse',
      this.procurementOfflineResponse
    );
    this.global.routerParams.set('remainingPayment', this.remainingPayment);
    this.global.routerParams.set('isPageFromConfirmation', true);
    if (this.procurementOfflineResponse.purchaseRequestData.procurementStatus) {
      this.global.routerParams.set('todo', 'edit');
      this.router.navigate(['/pages/procurement-offline-submission/edit']);
    } else {
      this.global.routerParams.set('todo', 'add');
      this.router.navigate(['/pages/procurement-offline-submission/add']);
    }
  }

  public handleFocusOut(event: any): void {
    if (!isNaN(+event.target.value)) {
      const uConvertMoneyValue = event.target.value  === '' ? '0' : this.global.converterService.uConvertMoney(
        event.target.value
      );
      event.target.value =
        this.global.converterService.convertMoney(uConvertMoneyValue);
    }
  }

  public handleFocusIn(event: any): void {
    const uConvertMoneyValue = this.global.converterService.uConvertMoney(
      event.target.value
    );
    event.target.value = uConvertMoneyValue;
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public doSort(sortField: string, tableName: string): void {
    if (tableName === 'procurement') {
      this.tableResponseProcurement.setSortField(sortField);
      this.tableResponseProcurement.reloadClient();
    } else if (tableName === 'payment') {
      this.tableResponsePayment.setSortField(sortField);
      this.tableResponsePayment.reloadClient();
    }
  }

  public doOnInput(
    budgetAllocation: BudgetAllocation,
    ngModel: NgModel,
    index: number
  ): void {
    if (ngModel.value && !isNaN(+ngModel.value)) {
      let plannedBudget: ChangeBudget =
        this.purchaseRequestBudgetModel.recordPageMap.get(budgetAllocation.id);
      if (!plannedBudget) {
        plannedBudget = {
          id: budgetAllocation.id,
          isChecked: !!this.purchaseRequestBudgetModel.isCheckedAll,
          plannedBudget: +((+(ngModel.value)).toFixed(this.global.appConstant.core.CURRENCY_PRECISSION_SCALE)),
          organization: budgetAllocation.organization,
          costCenter: budgetAllocation.costCenter,
          coa: budgetAllocation.coa,
          budgetType: budgetAllocation.budgetType,
          currency: budgetAllocation.currency,
          workProgram: budgetAllocation.workProgram
        };
      }
      plannedBudget.plannedBudget = +((+(ngModel.value)).toFixed(this.global.appConstant.core.CURRENCY_PRECISSION_SCALE));
      this.purchaseRequestBudgetModel.recordPageMap.set(
        plannedBudget.id,
        plannedBudget
      );
      this.setError(budgetAllocation, index);
      if (plannedBudget.isChecked) {
        this.procurementOfflineResponse.purchaseRequestData.procurementStatus
          .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
          ? (this.totalProcurementValue = 0)
          : (this.totalPaymentValue = 0);

        this.purchaseRequestBudgetModel.recordPageMap.forEach(
          purchaseBudgetModel => {
            this.procurementOfflineResponse.purchaseRequestData
              .procurementStatus.code ===
              this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
              ? (this.totalProcurementValue +=
                +purchaseBudgetModel.plannedBudget)
              : (this.totalPaymentValue += +purchaseBudgetModel.plannedBudget);
          }
        );
      }
      this.procurementOfflineResponse.purchaseRequestData.procurementStatus
        .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
        ? (this.procurementOfflineBudgetList[index].value = +((+(ngModel.value)).toFixed(this.global.appConstant.core.CURRENCY_PRECISSION_SCALE)))
        : (this.procurementOfflinePaymentList[index].value = +((+(ngModel.value)).toFixed(this.global.appConstant.core.CURRENCY_PRECISSION_SCALE)));
    } else {
      this.setError(budgetAllocation, index);
    }
  }

  public setError(
    budgetAllocation: BudgetAllocation,
    index: number,
    isCheckedDefault = false
  ): void {
    if (
      !isNaN(
        +this.purchaseRequestBudgetModel.recordPageMap.get(budgetAllocation.id)
          .plannedBudget
      )
    ) {
      let remaining: number;
      const { plannedBudget: plannedBudget, isChecked } =
        this.purchaseRequestBudgetModel.recordPageMap.get(budgetAllocation.id);
      if (
        this.procurementOfflineResponse.purchaseRequestData.procurementStatus
          .code === this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
      ) {
        remaining =
          this.procurementOfflineBudgetList[index].purchaseRequestBudget.value;
      } else {
        remaining = this.remainingPayment.get(budgetAllocation.id);
      }
      const valueBudget: number = +plannedBudget;
      if (+valueBudget > +remaining && (isCheckedDefault || isChecked)) {
        this.errorMapper.set(
          budgetAllocation.id,
          this.global.translateService.instant('app.validation.max')
        );
      } else if (+valueBudget <= 0 && (isCheckedDefault || isChecked)) {
        this.errorMapper.set(
          budgetAllocation.id,
          this.global.translateService.instant('app.validation.min')
        );
      } else {
        this.errorMapper.delete(budgetAllocation.id);
      }
    } else {
      this.errorMapper.set(
        budgetAllocation.id,
        this.global.translateService.instant(
          'procurement-offline-submission.msg.error.isNotNumber'
        )
      );
    }
  }

  public doSubmit(): void {
    const nextUrl: string = this.global.routerParams.get('nextUrl');
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          let url = '/procurement-offline-submission/insert';
          this.procurementOfflineRequest.purchaseRequestData =
            this.procurementOfflineResponse.purchaseRequestData;
          this.procurementOfflineRequest.purchaseRequestBudgetList =
            this.procurementOfflineResponse.purchaseRequestData.purchaseRequestBudgetList;
          this.procurementOfflineRequest.isNumberGenerator =
            this.procurementOfflineResponse.isNumberGenerator;
          if (
            this.procurementOfflineResponse.purchaseRequestData
              .procurementStatus
          ) {
            url = '/procurement-offline-submission/update';
            this.procurementOfflineRequest.procurementOffline =
              this.procurementOfflineResponse.procurementOffline;
            if (
              this.procurementOfflineRequest.purchaseRequestData
                .procurementStatus.code ===
              this.global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
            ) {
              this.procurementOfflineRequest.procurementOffline.number +=
                '/M/' + this.currentYear;
            }
            this.procurementOfflineRequest.procurementOfflineBudgetList =
              this.procurementOfflineResponse.procurementOffline.procurementOfflineBudgetList;
            let totalProc = 0;
            this.procurementOfflineRequest.fileObjectList =
              this.procurementOfflineResponse.fileObjectList;
            this.procurementOfflineResponse.procurementOffline.procurementOfflineBudgetList.forEach(
              (procurementOfflineBudget: ProcurementOfflineBudget) => {
                totalProc = totalProc + +procurementOfflineBudget.value;
              }
            );
            this.procurementOfflineRequest.procurementValueTotal = totalProc;
            if (
              this.procurementOfflineResponse.purchaseRequestData
                .procurementStatus.code ===
              this.global.appConstant.esm.PROCUREMENT_STATUS_PROCUREMENT_PROCESS ||
              this.procurementOfflineResponse.purchaseRequestData
                .procurementStatus.code ===
              this.global.appConstant.esm.PROCUREMENT_STATUS_PAYMENT_PROCESS
            ) {
              let totalPayment = 0;
              this.procurementOfflineResponse.procurementOfflinePaymentList.forEach(
                (procurementOfflinePayment: ProcurementOfflinePayment) => {
                  totalPayment =
                    totalPayment + +procurementOfflinePayment.value;
                }
              );
              this.procurementOfflineRequest.procurementOfflinePaymentList =
                this.procurementOfflineResponse.procurementOfflinePaymentList;
              this.procurementOfflineRequest.paymentValueTotal = totalPayment;
            }
          }
          this.httpClientService
            .post<Response<ProcurementOfflineResponse>>(
              url,
              this.procurementOfflineRequest
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessSavingOnNextRoute();
                this.global.routerParams.clear();
                if (nextUrl.includes('edit')) {
                  this.global.routerParams.set(
                    'purchaseRequestId',
                    response.body.purchaseRequestData.id
                  );
                  this.global.routerParams.set(
                    'procurementOfflineResponse',
                    this.procurementOfflineResponse
                  );
                  this.global.routerParams.set('todo', 'edit');
                }
                this.router.navigate([nextUrl]);
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
