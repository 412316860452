<div class="portal-vendor-policy">
  <div class="promise-container promise-container-lg">
    <app-card header="portal.menu.vendorPolicy" [isLoading]="formLoading">
      <ng-template #customLoading>
        <div class="promise-loading">
          <div class="loading"></div>
        </div>
      </ng-template>
      <div class="vendor-policy-content">
        <div [innerHtml]="vendorPolicy"></div>
      </div>
    </app-card>
  </div>
</div>
