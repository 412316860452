import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Dependency } from '../../core/bean/dependency';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './dependency.component.html'
})
export class DependencyComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Dependency>;
  constructor(translateService: TranslateService) {
    super('dependency', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      {
        field: 'name',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      }
    ]);
  }
}
