<app-popup
  header="payment-voucher.popup.title.addOtherCostItem"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="isViewOnly"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'payment-voucher.detail.popup.form.itemName' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-dropdown-select
          size="XL"
          formControlName="otherCost"
          type="AUTOCOMPLETE"
          [optionList]="otherCostOptionList"
          container="body"
          placeholder="payment-voucher.detail.popup.form.placeholder.inputItem"
          showLimitValue="5"
          [isAddManual]="true"
        ></app-dropdown-select>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'payment-voucher.detail.popup.form.coa' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-dropdown-select
          size="XL"
          formControlName="coa"
          type="AUTOCOMPLETE"
          container="body"
          stringUrl="/payment-voucher/auto-complete-coa-list"
          placeholder="payment-voucher.detail.popup.form.placeholder.inputCoa"
          showLimitValue="5"
        ></app-dropdown-select>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'payment-voucher.detail.popup.form.costCenter' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-dropdown-select
          size="XL"
          formControlName="costCenter"
          type="AUTOCOMPLETE"
          stringUrl="/payment-voucher/auto-complete-cost-center-list"
          container="body"
          placeholder="payment-voucher.detail.popup.form.placeholder.inputCostCenter"
          showLimitValue="5"
        ></app-dropdown-select>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'payment-voucher.detail.popup.form.transactionStatus' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-dropdown-select
          size="XL"
          formControlName="balanceType"
          type="COMBOBOX"
          [optionList]="balanceTypeOptionList"
          container="body"
          placeholder="payment-voucher.detail.popup.form.placeholder.selectType"
        ></app-dropdown-select>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'payment-voucher.detail.popup.form.value' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-currency
          size="LG"
          [optionList]="currencyOptionList"
          formControlName="amount"
        ></app-currency>
      </div>
    </div>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center" *ngIf="!isViewOnly">
      <app-button
        color="SECONDARY"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button
        color="PRIMARY"
        (onClick)="doAdd()"
        [disabled]="formLoading"
        >{{ 'app.button.add' | translate }}</app-button
      >
    </div>
  </ng-template>
</app-popup>
