import { ComplianceCheckStatus } from '../../core/bean/compliance-check-status';
import { ComplianceSummary } from '../../core/bean/compliance-summary';
import { ComplianceTest } from '../../core/bean/compliance-test';
import { ComplianceType } from '../../core/bean/compliance-type';
import { File } from '../../core/bean/file';
import { ItemType } from '../../core/bean/item-type';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { ProcurementVendor } from '../../core/bean/procurement-vendor';
import { User } from '../../core/bean/user';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path-x';
import { AppOfficialReportModel } from '../../core/components/app-official-report/app-official-report-model';
import { WorkflowStepInfoModel } from '../../core/components/app-workflow-step-info/model/workflow-step-info-model';
import { ComplianceTestModel } from './compliance-test.model';

export class ComplianceTestResponse {
  public itemTypeList: ItemType[];
  public procurementItemList: ProcurementItem[];
  public complianceTypeList: ComplianceType[];
  public complianceCheckStatusList: ComplianceCheckStatus[];
  public procurementVendorList: ProcurementVendor[];
  public procurementModelList: ComplianceTestModel[];
  public vendorModelList: ComplianceTestModel[];
  public summaryFileList: File[];
  public preparedByUserList: User[];
  public checkedByUserList: User[];
  public userTempList: User[];
  public procurement: Procurement;
  public user: User;
  public complianceTest: ComplianceTest;
  public complianceSummary: ComplianceSummary;
  public workflowStepInfoModel: WorkflowStepInfoModel;
  public approvalPathResponseModel: ApprovalPathResponseModel;
  public appOfficialReportModel: AppOfficialReportModel;
  public isUserApproval: boolean;
  public isRevise: boolean;
  public isDone: boolean;
}
