import { CompanyOrganizationTitle } from '../bean/company-organization-tittle';
import { File } from '../bean/file';
import { Vendor } from '../bean/vendor';
import { VendorPosition } from '../bean/vendor-position';
import { BaseEntity } from './../base/base-entity';
import { ShareholderType } from './../bean/shareholder-type';

export class VendorCompanyOrganizationEntity extends BaseEntity {
    shareValueWithPercent: string;

    companyOrganizationTittle: CompanyOrganizationTitle;
    vendorPosition: VendorPosition;
    vendor: Vendor;
    shareholderType: ShareholderType;
    file: File;
    name: string;
    idNumber: string;
    birthDate: Date;
    shareValue: string;
}
