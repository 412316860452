import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Vendor } from '../../core/bean/vendor';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { RecapPrintService } from '../../core/services/recap-print.service';
@Component({
  templateUrl: './performance-fulfillment.component.html',
  styles: [
    '.width-company-name { min-width : 250px; white-space: normal ! important }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PerformanceFulfillmentComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public readonly urlRouterNavigateToDetailContractFulfillment =
    '/pages/performance-fulfillment/detail';
  public readonly urlRouterNavigateToEditFulfillment =
    '/pages/performance-fulfillment/edit';
  public tableResponse: TableResponseModel<Vendor>;
  public isLoading: boolean;
  constructor(
    translateService: TranslateService,
    public recapPrintService: RecapPrintService
  ) {
    super('performance-fulfillment', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.name',
        customClass: 'width-company-name'
      },
      { field: 'code', header: 'table.column.code' },
      { field: 'vendorTypeName', header: 'table.column.vendorTypeName' },
      { field: 'email', header: 'table.column.email' },
      {
        field: 'averageRatingFulfillment',
        header: 'table.column.rating',
        format: FieldFormatEnum.Rating
      }
    ]);
  }

  public doDetail(vendor: Vendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('vendorId', vendor.id);
    this.router.navigate([this.urlRouterNavigateToDetailContractFulfillment]);
  }

  public doExport(): void {
    this.isLoading = true;
    this.recapPrintService
      .print(
        '/performance-fulfillment/recap-performance-fulfillment',
        this.tableResponse.request
      )
      .subscribe(() => {
        this.isLoading = false;
      });
  }
}
