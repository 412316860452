import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableResponseModel } from 'src/app/core/components/app-table-x/model/table-response-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { HospitalStock } from 'src/app/core/bean/hospital-stock';
import { HistoryHospitalStockView } from 'src/app/core/view/entity/history-hospital-stock-view';
@Component({
  templateUrl: './hospital-stock-monitoring-detail.component.html'
})
export class HospitalStockMonitoringDetailComponent extends BaseModuleComponent {
  public hospitalStock: HospitalStock;
  public urlBackOutside: string;
  public tableResponse: TableResponseModel<HistoryHospitalStockView>;
  constructor(
    translateService: TranslateService
  ) {
    super('hospital-stock', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildTableResponse();
    this.setCustomData();
    this.setStateReady();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.hospitalStock = this.global.routerParams.get('hospitalStock');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
  }

  public setCustomData(): void {
    this.tableResponse.setCustomData({
      id: this.hospitalStock.item.id
    });
  }
  

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'nomorTransaksi',
        header: 'table.column.nomorTransaksi',
        customClass: 'text-center'
      },
      {
        field: 'tanggalAktivitas',
        header: 'table.column.tanggalAktivitas',
        plugins: {
          name: 'date',
          format: 'short-date'
        },
        customClass: 'text-center'
      },
      {
        field: 'produsen',
        header: 'table.column.produsen',
        customClass: 'text-center'
      },
      {
        field: 'jumlah',
        header: 'table.column.jumlah',
        customClass: 'text-center'
      },
      {
        field: 'jenisTransaksi',
        header: 'table.column.jenisTransaksi',
        customClass: 'text-center'
      }
    ]);
  }
}
