<app-layout
  [isLoading]="formLoading"
  header="initiation.breadcrumb.index"
  [backTo]="urlBackOutside"
>
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge-catalog
      class="cml-2"
      *ngIf="response.procurement?.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge
      *ngIf="todo !== 'add' || formGroup.value.fromProcurement"
      class="cml-2"
      [color]="badgeColor[response.procurement?.procurementStatus?.code]"
    >
      {{ getTranslateKey(response.procurement?.procurementStatus) | translate }}
    </app-badge>
    <app-badge
      *ngIf="todo === 'add' && !formGroup.value.fromProcurement"
      class="cml-2"
      color="INFO"
    >
      {{ 'initiation.badge.status.new' | translate }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12">
      <app-alert-x
        *ngIf="isCanceled && !formLoading"
        color="DANGER"
        [isBold]="false"
      >
        {{ 'initiation.alert.msg.thisProcessWasCanceledBy' | translate }}
        <strong>{{ response.procurementCancelation.cancelByUser.name }}</strong>
        {{ 'initiation.alert.msg.at' | translate }}
        <strong>
          {{
            response.procurementCancelation.cancelDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}
        </strong>
        {{ 'initiation.alert.msg.theCancelationReasonIs' | translate }}
        <strong>&#34;{{ response.procurementCancelation.note }}&#34;</strong>
      </app-alert-x>

      <app-alert-x
        *ngIf="isFailed && !formLoading"
        color="DANGER"
        [isBold]="false"
      >
        {{
          'initiation.alert.msg.thisProcurementIsFailedTheReasonIs' | translate
        }}
        <strong>&#34;{{ response.procurementFailed.note }}&#34;</strong>
      </app-alert-x>

      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          this.response.procurement?.procurementStatus?.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
        "
      >
        {{ 'initiation.alert.msg.sorryWaitingCancelationApproval' | translate }}
      </app-alert-x>

      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          [
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
            this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
          ].includes(this.response.procurement?.procurementStatus?.code)
        "
      >
        {{ 'initiation.alert.msg.sorryWaitingFailedApproval' | translate }}
      </app-alert-x>

      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-item-request
          [isShowRequired]="
            procurementItemList.length === 0 && !formGroup.isView
          "
          *ngIf="!formLoading"
          [objectList]="procurementItemList"
          [isShowPrice]="true"
          [isView]="formGroup.isView"
          [itemTypeList]="response.itemTypeList"
          (onChange)="
            initiationTypeCode === 'TENDER'
              ? onChangeItemRequest($event)
              : onChangeItemRequestBlanketOrder($event)
          "
          baseModuleCode="INITIATION"
          [initiationType]="initiationTypeCode"
          [todo]="todo"
          [prResponse]="prResponse"
          [initiationFormGroup]="formGroup"
        ></app-item-request>

        <app-card
          header="initiation.detail.procurementDetails.title"
          tips="initiation.detail.procurementDetails.tips"
        >
          <app-fullfilled
            *ngIf="formGroup.invalid && !formGroup.isView"
            [isOptional]="true"
          ></app-fullfilled>

          <h4 class="separator">{{
            'initiation.separator.generalInformation' | translate
          }}</h4>
          <div class="form-group row text-sm-left" *ngIf="todo !== 'add'">
            <label class="col-sm-3">
              {{ 'initiation.form.procurementNumber' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-generated
                formControlName="code"
                maxLength="64"
                [moduleCode]="moduleCode"
                numberGeneratorCode="PROCUREMENT_NUMBER"
                (onChange)="setInitiationValue()"
              ></app-text-generated>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'initiation.form.procurementName' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="name"
                placeholder="{{
                  'initiation.placeholder.inputText' | translate
                }}"
                maxLength="128"
                type="alphanumeric"
                include="-.\',"
                (onChange)="setInitiationValue()"
              >
              </app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'initiation.form.procurementDate' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-date-picker-x
                formControlName="procurementDate"
                [minDate]="minDate"
                (onChange)="setInitiationValue()"
              ></app-date-picker-x>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'initiation.form.tenderType' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              {{ getTranslateKey(initiationType) | translate }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'initiation.form.procurementMethod' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                type="COMBOBOX"
                formControlName="procurementMethod"
                [optionList]="procurementMethodOptionList"
                placeholder="initiation.placeholder.chooseOne"
                (onChange)="onChangeProcMethod($event); setInitiationValue()"
              >
                <ng-template #value let-value>
                  {{ getTranslateKey(value) | translate }}
                </ng-template>
                <ng-template #option let-data>
                  {{ getTranslateKey(data) | translate }}
                </ng-template>
              </app-dropdown-select>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'initiation.form.transactionType' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              {{
                getTranslateKey(
                  formGroup.get('procurementMethod')?.value?.transactionType
                ) || '-' | translate
              }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'initiation.form.registrationType' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              {{
                getTranslateKey(
                  formGroup.get('procurementMethod')?.value?.procurementRegType
                ) || '-' | translate
              }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'initiation.form.procurementType' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-combo-box-tree
                formControlName="procurementType"
                stringUrl="/procurement-type"
                (onChange)="setInitiationValue()"
              >
              </app-combo-box-tree>
            </div>
          </div>

          <br />

          <ng-container *ngIf="!isDirectPurchase()">
            <h4 class="separator">{{
              'initiation.separator.evaluationMethods' | translate
            }}</h4>

            <div class="form-group row text-sm-left" *ngIf="todo !== 'add'">
              <label class="col-sm-3">
                {{ 'initiation.form.announcementNumber' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-text-generated
                  formControlName="announcementNumber"
                  maxLength="32"
                  [moduleCode]="moduleCode"
                  numberGeneratorCode="ANNOUNCEMENT_NUMBER"
                  (onChange)="setInitiationValue()"
                ></app-text-generated>
              </div>
            </div>

            <div
              class="form-group row text-sm-left"
              *ngIf="
                response?.procurement ||
                procurementMatrix?.documentSubmissionType
              "
            >
              <label class="col-sm-3">
                {{ 'initiation.form.documentSubmissionType' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="COMBOBOX"
                  formControlName="documentSubmissionType"
                  [optionList]="documentSubmissionTypeOptionList"
                  placeholder="initiation.placeholder.chooseOne"
                  (onChange)="setInitiationValue()"
                >
                  <ng-template #value let-value>
                    {{ getTranslateKey(value) | translate }}
                  </ng-template>
                  <ng-template #option let-data>
                    {{ getTranslateKey(data) | translate }}
                  </ng-template>
                </app-dropdown-select>
              </div>
            </div>

            <div
              class="form-group row text-sm-left"
              *ngIf="
                response?.procurement || procurementMatrix?.negotiationType
              "
            >
              <label class="col-sm-3">
                {{ 'initiation.form.negotiationType' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="COMBOBOX"
                  formControlName="negotiationType"
                  [optionList]="negotiationTypeOptionList"
                  placeholder="initiation.placeholder.chooseOne"
                  (onChange)="setInitiationValue()"
                >
                  <ng-template #value let-value>
                    {{ getTranslateKey(value) | translate }}
                  </ng-template>
                  <ng-template #option let-data>
                    {{ getTranslateKey(data) | translate }}
                  </ng-template>
                </app-dropdown-select>
              </div>
            </div>

            <div
              class="form-group row text-sm-left"
              *ngIf="
                response?.procurement || procurementMatrix?.priceEvaluationType
              "
            >
              <label class="col-sm-3">
                {{ 'initiation.form.priceEvaluation' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="COMBOBOX"
                  formControlName="priceEvaluationType"
                  [optionList]="priceEvaluationTypeOptionList"
                  placeholder="initiation.placeholder.chooseOne"
                  (onChange)="setInitiationValue()"
                >
                  <ng-template #value let-value>
                    {{ getTranslateKey(value) | translate }}
                  </ng-template>
                  <ng-template #option let-data>
                    {{ getTranslateKey(data) | translate }}
                  </ng-template>
                </app-dropdown-select>
              </div>
            </div>

            <div
              class="form-group row text-sm-left"
              *ngIf="
                response?.procurement || procurementMatrix?.biddingEvaluation
              "
            >
              <label class="col-sm-3">
                {{ 'initiation.form.biddingEvaluation' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="COMBOBOX"
                  formControlName="biddingEvaluation"
                  [optionList]="biddingEvaluationOptionList"
                  placeholder="initiation.placeholder.chooseOne"
                  (onChange)="setInitiationValue()"
                >
                  <ng-template #value let-value>
                    {{ getTranslateKey(value) | translate }}
                  </ng-template>
                  <ng-template #option let-data>
                    {{ getTranslateKey(data) | translate }}
                  </ng-template>
                </app-dropdown-select>
              </div>
            </div>

            <div
              class="form-group row text-sm-left"
              *ngIf="
                response?.procurement ||
                procurementMatrix?.biddingSubmissionType
              "
            >
              <label class="col-sm-3">
                {{ 'initiation.form.biddingSubmissionType' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="COMBOBOX"
                  formControlName="biddingSubmissionType"
                  [optionList]="biddingSubmissionTypeOptionList"
                  placeholder="initiation.placeholder.chooseOne"
                  (onChange)="
                    onChangeVendorSelectionBiddingSubmission($event);
                    setInitiationValue()
                  "
                >
                  <ng-template #value let-value>
                    {{ getTranslateKey(value) | translate }}
                  </ng-template>
                  <ng-template #option let-data>
                    {{ getTranslateKey(data) | translate }}
                  </ng-template>
                </app-dropdown-select>
              </div>
            </div>

            <div
              class="form-group row text-sm-left"
              *ngIf="
                response?.procurement || procurementMatrix?.vendorSelectionType
              "
            >
              <label class="col-sm-3">
                {{ 'initiation.form.vendorSelection' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="COMBOBOX"
                  formControlName="vendorSelectionType"
                  [optionList]="vendorSelectionTypeOptionList"
                  placeholder="initiation.placeholder.chooseOne"
                  (onChange)="
                    onChangeVendorSelectionBiddingSubmission($event);
                    setInitiationValue()
                  "
                >
                  <ng-template #value let-value>
                    {{ getTranslateKey(value) | translate }}
                  </ng-template>
                  <ng-template #option let-data>
                    {{ getTranslateKey(data) | translate }}
                  </ng-template>
                </app-dropdown-select>
              </div>
            </div>

            <div
              class="form-group row text-sm-left"
              *ngIf="response?.procurement || procurementMatrix?.currencyType"
            >
              <label class="col-sm-3">
                {{ 'initiation.form.currencyType' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="COMBOBOX"
                  formControlName="currencyType"
                  [optionList]="currencyTypeOptionList"
                  placeholder="initiation.placeholder.chooseOne"
                  (onChange)="setInitiationValue()"
                >
                  <ng-template #value let-value>
                    {{ getTranslateKey(value) | translate }}
                  </ng-template>
                  <ng-template #option let-data>
                    {{ getTranslateKey(data) | translate }}
                  </ng-template>
                </app-dropdown-select>
              </div>
            </div>

            <div
              *ngIf="!isDirectPurchase()"
              class="form-group row text-sm-left"
            >
              <label class="col-sm-3">
                {{ 'initiation.form.praQualification' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-toggle-switch
                  formControlName="isPq"
                  *ngIf="!formGroup.isView && !formGroup.get('isPq').isView"
                  (onChange)="setInitiationValue()"
                >
                </app-toggle-switch>
                <p *ngIf="formGroup.isView || formGroup.get('isPq').isView">
                  {{
                    (formGroup.get('isPq').value
                      ? 'initiation.form.isPq.yes'
                      : 'initiation.form.isPq.no'
                    ) | translate
                  }}
                </p>
              </div>
            </div>

            <div
              *ngIf="formGroup.get('isPq').value && !formGroup.isView"
              class="form-group row text-sm-left"
            >
              <label class="col-sm-3">
                {{ 'initiation.form.praQualificationItem' | translate }}
              </label>
              <div *ngIf="!formGroup.isView" class="col-sm-9 text-left">
                <app-button (onClick)="doChoosePq()">
                  <em class="pir pi-plus-circle"></em>
                  {{ 'initiation.detail.button.addRequirement' | translate }}
                </app-button>
              </div>
            </div>

            <div
              *ngIf="formGroup.get('isPq').value"
              class="form-group row text-sm-left"
            >
              <label *ngIf="!formGroup.isView" class="col-sm-3"> </label>
              <label *ngIf="formGroup.isView" class="col-sm-3">
                {{ 'initiation.form.praQualificationItem' | translate }}
              </label>
              <div class="col-sm-6 text-left">
                <small
                  class="text-danger"
                  *ngIf="
                    isSubmit && tableResponsePq.getRecordList().length === 0
                  "
                >
                  {{ 'app.validation.required' | translate }}
                </small>
                <app-table-xx
                  [model]="tableResponsePq"
                  [isShowSearch]="false"
                  [isShowPagination]="false"
                  [isShowCheckBox]="
                    !formGroup.isView && !formGroup.get('isPq').isView
                  "
                  customClass="pb-0"
                >
                  <ng-container *ngIf="!formGroup.isView">
                    <ng-template #actionButtons let-rowData>
                      <app-button
                        size="TN"
                        (onClick)="doDeletePq(rowData)"
                        title="{{ 'app.tooltip.delete' | translate }}"
                      >
                        <em class="pir pi-trash-alt"></em>
                        {{ 'app.button.delete' | translate }}
                      </app-button>
                    </ng-template>
                  </ng-container>
                </app-table-xx>
              </div>
            </div>
          </ng-container>

          <div class="form-group row text-sm-left">
            <label
              class="col-sm-3"
              [ngClass]="!formGroup.isView ? 'optional' : ''"
            >
              {{ 'initiation.form.description' | translate }}
            </label>
            <div
              [ngClass]="
                !formGroup.isView ? 'col-sm-9 text-left' : 'col-sm-6 text-left'
              "
            >
              <app-text-area
                formControlName="description"
                maxLength="512"
                size="LG"
                (onChange)="setInitiationValue()"
              >
              </app-text-area>
            </div>
          </div>
        </app-card>

        <!-- direct purchase: 
        proc vendor, shipping address, delivery item -->
        <ng-container *ngIf="isDirectPurchase()">
          <app-card
            header="initiation.detail.vendor.title"
            tips="initiation.detail.vendor.tips"
          >
            <ng-template #headerRight>
              <app-button
                *ngIf="!formGroup.isView"
                (onClick)="doSelectPurchaseVendor()"
              >
                <em class="pir pi-plus-circle"></em>
                {{ 'initiation.detail.button.selectVendor' | translate }}
              </app-button>
            </ng-template>
            <app-table-xx
              [model]="tableResponsePurchaseVendor"
              (onClick)="onClickPurchaseVendor($event)"
              [isShowCheckBox]="!formGroup.isView"
            >
              <ng-template
                #alertWrapper
                *ngIf="
                  tableResponsePurchaseVendor.getRecordList().length === 0 &&
                  !formGroup.isView
                "
              >
                <app-fullfilled></app-fullfilled>
              </ng-template>
              <ng-container *ngIf="!formGroup.isView">
                <ng-template #actionButtons let-rowData>
                  <app-button
                    size="TN"
                    (onClick)="doDeletePurchaseVendor(rowData)"
                    title="{{ 'app.tooltip.delete' | translate }}"
                  >
                    <em class="pir pi-trash-alt"></em>
                    {{ 'app.button.delete' | translate }}
                  </app-button>
                </ng-template>
              </ng-container>
            </app-table-xx>
          </app-card>

          <app-card
            header="initiation.detail.shippingAddress.title"
            tips="initiation.detail.shippingAddress.tips"
          >
            <ng-template #headerRight
              ><div class="button-group button-group-right">
                <app-button
                  *ngIf="!formGroup.isView"
                  (onClick)="doAddAddress()"
                >
                  <em class="pir pi-plus-circle"></em>
                  {{ 'initiation.detail.button.addAddress' | translate }}
                </app-button>
              </div></ng-template
            >

            <app-fullfilled
              *ngIf="
                directPurchaseShippingOptionList.getRequestValues().length ===
                  0 && !formGroup.isView
              "
            ></app-fullfilled>

            <app-select
              formControlName="directPurchaseShippingList"
              [optionList]="directPurchaseShippingOptionList"
              selectAllText="{{
                'initiation.detail.shippingAddress.checkAll' | translate
              }}"
              [isMultiple]="true"
              maxHeight="max-content"
              [isShowHover]="false"
              (onChange)="setInitiationValue()"
            >
              <ng-template #content let-data>
                <div
                  style="
                    border: 1px solid var(--gray-400);
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding: 1rem;
                  "
                >
                  <p>{{ data.address.organization.name }}</p>
                  <h5>{{ getUserNameList(data.address.addressPicList) }}</h5>
                  <p>{{ data.address.addressDetail }}</p>
                  <p>{{ data.address.phone }}</p>
                </div>
              </ng-template>

              <ng-template #actionButtons>
                <div
                  *ngIf="
                    !formGroup.isView &&
                    directPurchaseShippingOptionList.requestValues.length !== 0
                  "
                  [ngStyle]="{ cursor: 'pointer', display: 'contents' }"
                >
                  <em
                    class="pir pi-trash-alt mr-2"
                    (click)="doDeleteShipping()"
                    style="font-size: 24px"
                  ></em>
                  <span (click)="doDeleteShipping()">
                    {{ 'app.button.delete' | translate }}
                  </span>
                </div>
              </ng-template>
            </app-select>
          </app-card>

          <ng-container
            *ngIf="
              tableResponsePurchaseVendor.getRecordList()[0]?.procurementVendor
                ?.isReceived
            "
          >
            <!-- isReceived true -->
            <app-card
              header="initiation.detail.deliveryItem.title"
              tips="initiation.detail.deliveryItem.tips"
            >
              <app-tab>
                <app-tab-item header="initiation.tab.goods">
                  <app-table-xx
                    [model]="tableResponseGoods"
                    (onClick)="onClickGoodsService($event)"
                    [isShowCheckBox]="false"
                  >
                    <ng-template
                      #alertWrapper
                      *ngIf="
                        isWaitingReview(tableResponseGoods.getRecordList())
                      "
                    >
                      <app-alert-x color="WARNING">
                        {{
                          'initiation.alert.msg.warning.youStillHaveDeliveryItemToReview'
                            | translate
                        }}
                      </app-alert-x>
                    </ng-template>
                  </app-table-xx>
                </app-tab-item>
                <app-tab-item header="initiation.tab.service">
                  <app-table-xx
                    [model]="tableResponseService"
                    (onClick)="onClickGoodsService($event)"
                    [isShowCheckBox]="false"
                  >
                    <ng-template
                      #alertWrapper
                      *ngIf="
                        isWaitingReview(tableResponseService.getRecordList())
                      "
                    >
                      <app-alert-x color="WARNING">
                        {{
                          'initiation.alert.msg.warning.youStillHaveDeliveryItemToReview'
                            | translate
                        }}
                      </app-alert-x>
                    </ng-template>
                  </app-table-xx>
                </app-tab-item>
              </app-tab>
            </app-card>
          </ng-container>
        </ng-container>

        <!-- non direct purchase -->
        <app-card
          *ngIf="!isDirectPurchase()"
          header="initiation.detail.procurementVendor.title"
          tips="initiation.detail.procurementVendor.tips"
        >
          <small
            class="text-danger"
            *ngIf="isSubmit && tableResponseSeg.getRecordList().length === 0"
          >
            {{ 'app.validation.required' | translate }}
          </small>
          <app-table-xx
            [model]="tableResponseSeg"
            header="{{ 'initiation.table.vendorSegmentation' | translate }}"
            [isShowCheckBox]="!formGroup.isView"
          >
            <ng-template
              #alertWrapper
              *ngIf="
                tableResponseSeg.getRecordList().length === 0 &&
                !formGroup.isView
              "
            >
              <app-fullfilled></app-fullfilled>
            </ng-template>
            <ng-container *ngIf="!formGroup.isView">
              <ng-template #headerButtons>
                <app-button (onClick)="doAddSeg()">
                  <em class="pir pi-plus-circle"></em>
                  {{ 'initiation.detail.button.addSegmentation' | translate }}
                </app-button>
              </ng-template>

              <ng-template #actionButtons let-rowData>
                <app-button
                  size="TN"
                  (onClick)="doDeleteSeg(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="pir pi-trash-alt"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>
              </ng-template>
            </ng-container>
          </app-table-xx>
          

          <!-- show/hide card vendor result by procurementMatrix.isShowVendorResult -->
          <ng-container *ngIf="isShowVendorResult">
            <br />
          <br />
          <br />
            <!-- INVITATION or proc reg type null -->
            <app-table-xx
              *ngIf="
                formGroup.value.procurementMethod?.procurementRegType?.code !==
                  global.appConstant.pm.PROC_REG_TYPE_ANNOUNCEMENT ||
                !formGroup.value.procurementMethod?.procurementRegType
              "
              [model]="tableResponseVendor"
              (onClick)="onClickVendor($event)"
              header="{{ 'initiation.table.vendorResult' | translate }}"
              [isShowCheckBox]="!formGroup.isView"
            >
              <ng-template
              #alertWrapper *ngIf="(tableResponseVendor.getRecordList().length === 0 &&
                !formGroup.isView) || isWarningAlert()"
            >
                <app-fullfilled *ngIf="
                  tableResponseVendor.getRecordList().length === 0 &&
                  !formGroup.isView
                "></app-fullfilled>
                <app-alert-x color="WARNING" *ngIf="isWarningAlert()">
                  {{
                    'initiation.alert.msg.warning.youHaveThingsToConsider'
                      | translate
                  }}
                </app-alert-x>
              </ng-template>
              <ng-container *ngIf="!formGroup.isView">
                <ng-template #headerButtons>
                  <app-button
                    [disabled]="
                      tableResponseSeg.getRecordList().length === 0 ||
                      procurementItemList.length === 0
                    "
                    (onClick)="doAddVendor()"
                  >
                    <em class="pir pi-plus-circle"></em>
                    {{ 'initiation.detail.button.addVendor' | translate }}
                  </app-button>
                </ng-template>

                <ng-template #actionButtons let-rowData>
                  <app-button
                    size="TN"
                    (onClick)="doDeleteVendor(rowData)"
                    title="{{ 'app.tooltip.delete' | translate }}"
                  >
                    <em class="pir pi-trash-alt"></em>
                    {{ 'app.button.delete' | translate }}
                  </app-button>
                </ng-template>
              </ng-container>
            </app-table-xx>

            <!-- ANNOUNCEMENT -->
            <app-table-xx
              *ngIf="
                formGroup.value.procurementMethod?.procurementRegType?.code ===
                global.appConstant.pm.PROC_REG_TYPE_ANNOUNCEMENT
              "
              [model]="tableResponseVendor"
              (onClick)="onClickVendor($event)"
              header="{{ 'initiation.table.vendorResult' | translate }}"
              [isShowCheckBox]="false"
            >
              <ng-container *ngIf="!formGroup.isView">
                <ng-template #headerButtons>
                  <app-button (onClick)="doAddVendor()">
                    <em class="pir pi-plus-circle"></em>
                    {{ 'initiation.detail.button.doAddVendor' | translate }}
                  </app-button>
                </ng-template>
              </ng-container>
            </app-table-xx>
          </ng-container>
        </app-card>

        <app-card
          header="initiation.detail.document.title"
          tips="initiation.detail.document.tips"
        >
          <app-fullfilled
            *ngIf="
              (!formGroup.value.documentList ||
                formGroup.value.documentList.length === 0) &&
              !formGroup.isView
            "
          >
          </app-fullfilled>
          <app-upload-document-table-x
            *ngIf="!formLoading"
            formControlName="documentList"
            fromModuleCode="PR"
            [mode]="todo"
            [objIdList]="updatedPrIdList"
            (onChange)="setInitiationValue()"
          ></app-upload-document-table-x>
        </app-card>

        <app-committee
          (onChange)="onChangeCommittee($event)"
          [binaryTreeModel]="binaryTreeModel"
          [committee]="committee"
          header="initiation.detail.procurementCommittee.title"
          tips="initiation.detail.procurementCommittee.tips"
          baseModulCode="INITIATION"
        >
        </app-committee>

        <app-card
          *ngIf="isDirectPurchase()"
          header="initiation.detail.directPurchaseRealization.title"
          tips="initiation.detail.directPurchaseRealization.tips"
        >
          <app-table-xx [model]="tableResponseRealization">
            <ng-container
              *ngIf="tableResponseRealization.getRecordList().length > 0"
            >
              <ng-template #row>
                <tr>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="4">
                    {{ 'initiation.form.goodsPrice' | translate }}
                  </td>
                  <td class="text-right">
                    <strong>
                      {{
                        formGroup.value.goodsAmountReal || 0
                          | resolveNumberToCurrency
                      }}
                      IDR
                    </strong>
                  </td>
                  <td class="text-right">
                    {{
                      (formGroup.get('goodsProfitLoss').value
                        | resolveNumberToDecimal) + '% '
                    }}
                    <app-badge-catalog
                      *ngIf="
                        !formLoading &&
                        formGroup.get('goodsAmountReal').value &&
                        formGroup.get('goodsProfitLoss').value
                      "
                      class="ml-2"
                      [color]="
                        badgeColor[
                          formGroup.get('goodsProfitLoss').value > 0
                            ? 'SAVE'
                            : 'LOSS'
                        ]
                      "
                    >
                      {{
                        formGroup.get('goodsProfitLoss').value > 0
                          ? 'Save'
                          : 'Loss'
                      }}
                    </app-badge-catalog>
                    <span
                      class="ml-2"
                      *ngIf="
                        !formLoading &&
                        (formGroup.get('goodsAmountReal').value === 0 ||
                          formGroup.get('goodsProfitLoss').value === 0)
                      "
                    >
                      -
                    </span>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="4">
                    {{ 'initiation.form.servicePrice' | translate }}
                  </td>
                  <td class="text-right">
                    <strong>
                      {{
                        formGroup.value.serviceAmountReal || 0
                          | resolveNumberToCurrency
                      }}
                      IDR
                    </strong>
                  </td>
                  <td class="text-right">
                    {{
                      (formGroup.get('serviceProfitLoss').value
                        | resolveNumberToDecimal) + '% '
                    }}
                    <app-badge-catalog
                      *ngIf="
                        !formLoading &&
                        formGroup.get('serviceAmountReal').value &&
                        formGroup.get('serviceProfitLoss').value
                      "
                      class="ml-2"
                      [color]="
                        badgeColor[
                          formGroup.get('serviceProfitLoss').value > 0
                            ? 'SAVE'
                            : 'LOSS'
                        ]
                      "
                    >
                      {{
                        formGroup.get('serviceProfitLoss').value > 0
                          ? 'Save'
                          : 'Loss'
                      }}
                    </app-badge-catalog>
                    <span
                      class="ml-2"
                      *ngIf="
                        !formLoading &&
                        (formGroup.get('serviceAmountReal').value === 0 ||
                          formGroup.get('serviceProfitLoss').value === 0)
                      "
                    >
                      -
                    </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="4">
                    {{ 'initiation.form.grandTotal' | translate }}
                  </td>
                  <td class="text-right">
                    <strong>
                      {{
                        formGroup.value.amountReal || 0
                          | resolveNumberToCurrency
                      }}
                      IDR
                    </strong>
                  </td>
                  <td class="text-right">
                    {{
                      (formGroup.get('amountProfitLoss').value
                        | resolveNumberToDecimal) + '% '
                    }}
                    <app-badge-catalog
                      *ngIf="
                        !formLoading &&
                        formGroup.get('amountReal').value &&
                        formGroup.get('amountProfitLoss').value
                      "
                      class="ml-2"
                      [color]="
                        badgeColor[
                          formGroup.get('amountProfitLoss').value > 0
                            ? 'SAVE'
                            : 'LOSS'
                        ]
                      "
                    >
                      {{
                        formGroup.get('amountProfitLoss').value > 0
                          ? 'Save'
                          : 'Loss'
                      }}
                    </app-badge-catalog>
                    <span
                      class="ml-2"
                      *ngIf="
                        !formLoading &&
                        (formGroup.get('amountReal').value === 0 ||
                          formGroup.get('amountProfitLoss').value === 0)
                      "
                    >
                      -
                    </span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="4">
                    <i>
                      ({{
                        formGroup.value.amountReal || 0
                          | resolveNumberToWords: global.currentLang
                      }}
                      {{ 'Rupiah' }}

                      )
                    </i>
                  </td>
                  <td></td>
                </tr>
              </ng-template>
            </ng-container>
          </app-table-xx>
        </app-card>

        <app-card
          *ngIf="isDirectPurchase()"
          header="initiation.detail.officialReport.title"
          tips="initiation.detail.officialReport.tips"
        >
          <app-alert-x
            color="WARNING"
            *ngIf="isOfficialReportNull && response.procurement"
            >{{
              'initiation.alert.msg.officialReportInfo' | translate
            }}</app-alert-x
          >
          <app-alert-x color="WARNING" *ngIf="!response.procurement">{{
            'initiation.alert.msg.saveDraftFirst' | translate
          }}</app-alert-x>
          <app-official-report
            [objectId]="response.procurement?.id"
            templateCode="ORT_INITIATION_DIRECT_PURCHASE_LETTER"
            [appOfficialReportModel]="appOfficialReportModel"
            [mode]="todo"
            numberGeneratorCode="PROCUREMENT_NUMBER"
            (onChange)="onChangeOfficialReport($event)"
            [isShowButtonGenerate]="!formGroup.isView"
          ></app-official-report>
        </app-card>

        <app-card
          *ngIf="response.procurementCancelation"
          header="initiation.detail.procurementCancel.title"
          tips="initiation.detail.procurementCancel.tips"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'initiation.form.stage' | translate }}
            </label>
            <div class="col-sm-6">
              {{ response.procurementCancelation?.module?.name }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'initiation.form.cancelationReason' | translate }}
            </label>
            <div class="col-sm-6">
              {{ response.procurementCancelation?.note }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'initiation.form.attachmentFile' | translate }}
            </label>
            <div class="col-sm-6">
              <app-upload-x
                formControlName="cancelDocList"
                [fileUploader]="fileUploaderCancel"
                (onChange)="setInitiationValue()"
              ></app-upload-x>
            </div>
          </div>
        </app-card>

        <app-card
          *ngIf="
            response.procurementFailed &&
            response.procurement.procurementStatus.code !==
              global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
          "
          header="initiation.detail.procurementFailed.title"
          tips="initiation.detail.procurementFailed.tips"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'initiation.form.stage' | translate }}
            </label>
            <div class="col-sm-6">
              {{ response.procurementFailed?.module?.name }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'initiation.form.failedReason' | translate }}
            </label>
            <div class="col-sm-6">
              {{ response.procurementFailed?.note }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'initiation.form.attachmentFile' | translate }}
            </label>
            <div class="col-sm-6">
              <app-upload-x
                formControlName="failedDocList"
                [fileUploader]="fileUploaderFailed"
                (onChange)="setInitiationValue()"
              ></app-upload-x>
            </div>
          </div>
        </app-card>
      </form>

      <app-approval-monitoring
        *ngIf="
          !formLoading &&
          response.approvalPathResponseModel &&
          !response.isUserApproval &&
          response?.procurement?.procurementStatus?.code &&
          (statusForApprovalList.includes(
            response?.procurement?.procurementStatus?.code
          ) ||
            procCancelFailedStatusList.includes(
              response?.procurement?.procurementStatus?.code
            ))
        "
        [workflowPrcs]="getWorkflowModelPrcs().workflowPrcs"
        [moduleCode]="getModuleCode()"
        [approvalPathResponseModel]="response.approvalPathResponseModel"
      ></app-approval-monitoring>

      <app-card
        header="initiation.detail.changeHistory.title"
        *ngIf="
          response?.procurement?.procurementStatus?.code &&
          statusForApprovalList.includes(
            response?.procurement?.procurementStatus?.code
          )
        "
      >
        <app-change-history
          *ngIf="!formLoading"
          [moduleId]="response.module?.id"
          [objectId]="procurementId"
        ></app-change-history>
      </app-card>

      <div class="button-group button-group-center" *ngIf="!formGroup.isView">
        <app-button
          (onClick)="doSaveDraft()"
          color="LIGHT"
          [outline]="true"
          [disabled]="procurementItemList.length === 0"
        >
          {{ 'app.button.save' | translate }}</app-button
        >
        <app-button
          (onClick)="doSubmit()"
          [disabled]="procurementItemList.length === 0"
        >
          {{ 'app.button.submit' | translate }}</app-button
        >
      </div>

      <div
        class="button-group button-group-center"
        *ngIf="
          !formLoading &&
          response.procurement?.procurementStatus.code ===
            global.appConstant.pm.PROCUREMENT_STATUS_ON_PROGRESS &&
          response.procurement?.committee?.picUser?.id ===
            global.userSession.user.id
        "
      >
        <app-button (onClick)="doCancel()" color="DANGER" [outline]="true">
          {{ 'initiation.button.cancelProcurement' | translate }}</app-button
        >
      </div>

      <div
        class="button-group button-group-center"
        *ngIf="
          !formLoading &&
          response.procurement?.procurementStatus.code ===
            global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT &&
          response.procurement?.committee?.picUser?.id ===
            global.userSession.user.id
        "
      >
        <app-button (onClick)="doConfirmFailed()" [outline]="true">
          {{ 'initiation.button.confirm' | translate }}</app-button
        >
      </div>

      <div
        class="button-group button-group-center"
        *ngIf="
          !formLoading &&
          response.procurement?.procurementStatus.code ===
            global.appConstant.pm.PROCUREMENT_STATUS_FAILED &&
          response.procurement?.maxRepeatable !== 0
        "
      >
        <app-button (onClick)="doReHeld()">
          {{ 'initiation.button.reHeldProcurement' | translate }}</app-button
        >
      </div>

      <app-approval-prcs-x
        *ngIf="response.isUserApproval"
        [workflowModelPrcs]="getWorkflowModelPrcs()"
        [approvalModelPrcsId]="approvalModelPrcsId"
      >
      </app-approval-prcs-x>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips #appTips></app-tips>
  </ng-template>
</app-layout>
