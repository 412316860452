import { User } from '../../core/bean/user';
import { Workload } from '../../core/bean/workload';
import { WorkloadThreshold } from '../../core/bean/workload-threshold';
import { WorkloadGenerateModel } from './workload-generate-model';

export class WorkloadUpdateRequest {
  public userList: User[];
  public workloadGenerateModelList: WorkloadGenerateModel[];
  public workloadThresholdList: WorkloadThreshold[];
  public workload: Workload;
  public isActivateCondition: boolean;
}
