import { TranslationKey } from '../../core/bean/translation-key';
import { Translation } from '../../core/bean/translation';
import { Lang } from '../../core/bean/lang';
import { Module } from '../../core/bean/module';
import { FormObject } from '../../core/bean/form-object';
import { ListOfValueModel } from '../../core/model/list-of-value-model';
import { CatalogAttribute } from '../../core/bean/catalog-attribute';

export class CatalogAttributeResponse {

    translationKey: TranslationKey;
    langList: Lang[];
    catalogAttribute: CatalogAttribute;
    translationList: Translation[];
    module: Module;
    formObjectList: FormObject[];
    listOfValueModel: ListOfValueModel[];
}
