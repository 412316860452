
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { RfiItem } from '../../core/bean/rfi-item';
import { FileUploader } from '../../core/components/upload';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { ResponseRfiDTO } from './dto/response-rfi.dto';
@Component({
    templateUrl: './response-rfi-edit.component.html'
})
export class ResponseRfiEditComponent extends BaseModuleComponent {
    public responseRfiDTO: ResponseRfiDTO = new ResponseRfiDTO();
    public rfiItemList: RfiItem[];
    public rfiVendorId: number;
    public phoneRegion: string;
    public phoneNumber: string;
    public fileName: string;
    public uploadedFileName: string;
    public vendorName: string;
    public vendorEmail: string;
    public isFromQuestion = false;
    public formGroupValueQuestion: object;

    public fileUploader: FileUploader = new FileUploader('/response-rfi/', 'rfiDoc', this.global.appConstant.fileType.DOC_RFI);

    constructor(translateService: TranslateService) {
        super('response-rfi', translateService);
    }

    onInit(): void {
        this.setDataFromRouterParams();
        this.buildFormGroup();
        this.setFormGroup();
    }

    public setDataFromRouterParams(): void {
        this.todo = this.global.routerParams.get('todo');
        this.rfiVendorId = this.global.routerParams.get('rfiVendorId');
        this.responseRfiDTO = this.global.routerParams.get('responseRfiDTO') || new ResponseRfiDTO();
        this.isFromQuestion = this.global.routerParams.get('isFromQuestion');
        this.formGroupValueQuestion = this.global.routerParams.get('formGroupValueQuestion');
    }

    public buildFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            templateRfiDoc: [null],
            rfiDoc: [null, Validators.required()]
        });
    }

    public setFormGroup(): void {
        if (this.isFromQuestion) {
            this.rfiItemList = this.responseRfiDTO.rfiItemList;
            this.vendorName = this.responseRfiDTO.rfiVendor.vendorHistory.vendor.user.name;
            this.vendorEmail = this.responseRfiDTO.rfiVendor.vendorHistory.vendor.user.email;
            this.phoneRegion = this.responseRfiDTO.rfiVendor.vendorHistory.vendor.phoneRegion.callingCode;
            this.phoneNumber = this.responseRfiDTO.rfiVendor.vendorHistory.vendor.phone;
            this.fileName = this.responseRfiDTO.rfi.file.fileName;
            this.uploadedFileName = this.responseRfiDTO.rfi.file.uploadedFileName;
            this.formGroup.patchValue({
                templateRfiDoc: this.responseRfiDTO.rfi.file,
                rfiDoc: this.responseRfiDTO.rfiVendor.file
            });
            this.setStateReady();
        } else {
            this.httpClientService.post<ResponseRfiDTO>('/response-rfi/edit', new RouteRequestModel(this.todo, this.rfiVendorId)).subscribe(responseRfiDTO => {
                this.responseRfiDTO = responseRfiDTO;
                this.rfiItemList = this.responseRfiDTO.rfiItemList;
                this.vendorName = this.responseRfiDTO.rfiVendor.vendorHistory.vendor.user.name;
                this.vendorEmail = this.responseRfiDTO.rfiVendor.vendorHistory.vendor.user.email;
                this.phoneRegion = this.responseRfiDTO.rfiVendor.vendorHistory.vendor.phoneRegion.callingCode;
                this.phoneNumber = this.responseRfiDTO.rfiVendor.vendorHistory.vendor.phone;
                this.fileName = this.responseRfiDTO.rfi.file.fileName;
                this.uploadedFileName = this.responseRfiDTO.rfi.file.uploadedFileName;
                this.formGroup.patchValue({
                    templateRfiDoc: this.responseRfiDTO.rfi.file
                });
                this.setStateReady();
            });
        }

    }

    public doNext(): void {
        this.validate();
        if (this.responseRfiDTO != null) {
            if (this.formGroup.valid) {
                if (this.formGroup.get('rfiDoc').value !== null) {
                    this.responseRfiDTO.rfiVendor.file = Array.isArray(this.formGroup.get('rfiDoc').value) ?
                     this.formGroup.get('rfiDoc').value[0].file : this.formGroup.get('rfiDoc').value;
                }
                this.global.routerParams.clear();
                this.global.routerParams.set('todo', this.todo);
                this.global.routerParams.set('responseRfiDTO', this.responseRfiDTO);
                this.global.routerParams.set('formGroupValueQuestion', this.formGroupValueQuestion);
                this.router.navigate(['/pages/response-rfi/question']);
            }
        }
    }

    /* save with no questions available */
    public doSave(): void {
        this.validate();
        if (this.formGroup.valid) {
          this.global.modalService
            .saveConfirmation()
            .pipe(take(1))
            .subscribe(result => {
              if (result) {
                this.setStateProcessing();
                this.responseRfiDTO.answer = [];
                this.responseRfiDTO.rfiVendor.file = Array.isArray(this.formGroup.get('rfiDoc').value) ?
                     this.formGroup.get('rfiDoc').value[0].file : this.formGroup.get('rfiDoc').value;

                const url = '/response-rfi/update';
                this.httpClientService
                  .post<Response<ResponseRfiDTO>>(url, this.responseRfiDTO)
                  .subscribe(response => {
                    if (response.status === ResponseStatusModel.OK) {
                      this.global.alertService.showSuccessSavingOnNextRoute();
                      this.router.navigate(['/pages/response-rfi/']);
                    } else {
                      this.global.alertService.showError(response.statusText);
                    }
                    this.setStateReady();
                  });
              }
            });
        }
    }

    public doCancel(): void {
        this.router.navigate(['/pages/response-rfi/']);
    }

}
