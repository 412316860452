<div class="catalog-item-carousel">
  <app-carousel [model]="carouselResponseModel">
    <ng-template
      #carouselIndicator
      let-generatedId
      let-images="images"
      let-stringUrl="stringUrl"
    >
      <ol class="carousel-indicators" *ngIf="images">
        <li
          class="cursor-pointer carousel-indicator-item"
          [attr.data-target]="'#' + generatedId"
          [attr.data-slide-to]="i"
          *ngFor="let image of images; let i = index"
          [ngClass]="{ active: i === 0 }"
        >
          <div class="carousel-indicator-item-image">
            <img
              *ngIf="!image.referenceLink"
              [attr.src]="
                global.config.BACKEND_ADDRESS +
                  '/' +
                  stringUrl +
                  '/file/view/' +
                  image.uploadedFileName +
                  '/' + global.appConstant.fileType.IMG_ITEM_CATALOG
                  | resolveImgSrc
                  | async
              "
              [attr.alt]="image.uploadedFileName"
              width="auto"
              height="auto"
            />
          </div>
        </li>
      </ol>
    </ng-template>
  </app-carousel>
</div>
