import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { RegionComponent } from './region.component';
const routes = [
    {
        path: '',        component: RegionComponent,
       data: { breadcrumb: '' },
        canDeactivate: [ConfirmGuard]
    }
];
@NgModule({
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        NgbModule
    ],
    declarations: [RegionComponent
   ]
})
export class RegionModule { }
