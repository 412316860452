import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Catalog } from 'src/app/core/bean/catalog';
import { VendorView } from 'src/app/core/view/entity/bean/vendor-view';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Region } from '../../core/bean/region';
import { VendorCategory } from '../../core/bean/vendor-category';
import { VendorType } from '../../core/bean/vendor-type';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { RfiPopupVendorDTO } from './dto/rfi-popup-vendor.dto';
import { RfiDTO } from './dto/rfi.dto';
@Component({
  selector: 'app-popup-vendor-promise',
  templateUrl: './app-popup-vendor.component.html',
  styles: [
    '.width-company-name { min-width : 250px; white-space: normal ! important }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupVendorComponent extends BaseComponentComponent {
  @Input() public vendorIdExcludeList: number[] = null;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  @ViewChild(AppTableComponent) tableVendor: AppTableComponent;

  public tableResponse: TableResponseModel<VendorView>;
  public dataRecords: VendorView[] = [];

  public rfiDTO: RfiDTO = new RfiDTO();
  public vendorCategoryList: VendorCategory[] = [];
  public vendorCategoryIdList: number[] = [];
  public country: Region;
  public city: Region;
  public ratingList: number[] = [];
  public readonly vendorViewList: VendorView[] = [];

  public vendorTypeOptionList: OptionListModel<VendorType> =
    new OptionListModel(true);
  public vendorCategoryOptionList: OptionListModel<VendorCategory> =
    new OptionListModel(true);
  public catalogOptionList: OptionListModel<Catalog> = new OptionListModel(
    true
  );
  public ratingOptionLists: {};

  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    super('app-popup-vendor-promise');
  }

  onInit(): void {
    this.buildFormGroup();
    this.buildTableResponse();
    this.setVendorIdCustomData();
    this.getVendorToFormGroup();
    this.getVendorList();
    this.setOptionsValue();
  }
  public setOptionsValue(): void {
    const ratingOptionLists = [
      { id: 1, name: 'rating five', isChecked: false, value: 5 },
      { id: 2, name: 'rating four', isChecked: false, value: 4 },
      { id: 3, name: 'rating three', isChecked: false, value: 3 },
      { id: 4, name: 'rating two', isChecked: false, value: 2 },
      { id: 5, name: 'rating one', isChecked: false, value: 1 }
    ];

    this.ratingOptionLists = ratingOptionLists;

    this.formGroup.get('ratingList').patchValue(this.ratingOptionLists);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorTypeList: [null],
      vendorCategoryList: [null],
      regionList: [null],
      segmentationList: [null],
      ratingList: [null],
      keywordList: [null],
      vendorIdExcludeList: [null]
    });
  }

  public setVendorIdCustomData(): void {
    if (this.vendorIdExcludeList && this.vendorIdExcludeList.length > 0) {
      this.tableResponse.setCustomData({
        vendorIdExcludeList: this.vendorIdExcludeList
      });

      this.formGroup.patchValue({
        vendorIdExcludeList: this.vendorIdExcludeList
      });
    }
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.name',
        customClass: 'width-company-name'
      },
      {
        field: 'vendorType.name',
        header: 'table.column.vendorType',
        customClass: 'text-center'
      },
      { field: 'email', header: 'table.column.email' },
      {
        field: 'location',
        header: 'table.column.location',
        customClass: 'white-space-normal'
      }
    ]);
  }

  public getVendorToFormGroup(): void {
    this.httpClientService
      .get<RfiDTO>('/request-for-information/popup-vendor-promise')
      .subscribe(rfiDTO => {
        this.rfiDTO = rfiDTO;
        this.setOptionModelList();
        this.setStateReady();
      });
  }

  public getVendorList(): void {
    this.tableResponse.setStateLoading();
    this.httpClientService
      .post<VendorView[]>(
        '/request-for-information/popup-vendor',
        new RfiPopupVendorDTO()
      )
      .subscribe((vendorViewList: VendorView[]) => {
        this.vendorViewList.push(...vendorViewList);

        const vendorViewExcludeList = this.vendorViewList.filter(vendorView =>
          this.vendorIdExcludeList.includes(vendorView.id)
        );
        this.log.debug(vendorViewExcludeList);
        vendorViewExcludeList.forEach(vendorViewExclude => {
          const findIndex = this.vendorViewList.findIndex(
            vendorView => vendorView.id === vendorViewExclude.id
          );
          if (findIndex !== -1) {
            this.vendorViewList.splice(findIndex, 1);
          }
        });

        this.tableResponse.setRecords(
          this.vendorViewList ? this.vendorViewList : []
        );
        this.tableResponse.setTotalRecords(
          this.vendorViewList ? this.vendorViewList.length : 0
        );
        this.tableResponse.reloadClient();
        this.tableResponse.setStateReady();
      });
  }

  public setOptionModelList(): void {
    this.vendorTypeOptionList.setRequestValues(this.rfiDTO.vendorTypeList);
    this.rfiDTO.vendorCategoryList.forEach(vendorCategory => {
      vendorCategory.name += ' (' + vendorCategory.vendorType.name + ')';
    });
    this.vendorCategoryOptionList.setRequestValues(
      this.rfiDTO.vendorCategoryList
    );

    this.catalogOptionList.setRequestValues(this.rfiDTO.catalogList);
  }

  public doChangeVendorCategoryList(vendorTypeList: VendorType[]): void {
    if (vendorTypeList && vendorTypeList.length !== 0) {
      const filteredVendorCategoryOptionList: VendorCategory[] = [];

      vendorTypeList.forEach(vendorType => {
        filteredVendorCategoryOptionList.push(
          ...this.rfiDTO.vendorCategoryList.filter(
            vendorCategory => vendorCategory.vendorType.id === vendorType.id
          )
        );
      });
      this.vendorCategoryOptionList.setRequestValues(
        filteredVendorCategoryOptionList
      );
    } else {
      this.vendorCategoryOptionList.setRequestValues(
        this.rfiDTO.vendorCategoryList
      );
    }
  }

  // public onChange(records: VendorView[]): void {
  //   this.dataRecords = records;
  // }

  public doSubmit(): void {
    if (this.dataRecords && this.dataRecords.length > 0) {
      this.onChange.emit(this.dataRecords);
      this.activeModal.dismiss();
    } else {
      this.global.alertService.showError(
        this.translateService.instant('rfi.alert.popup.chooseVendorFirst'),
        '.main-row-popup-rfi-vendor'
      );
    }
  }

  public doApply(): void {
    this.formGroup.value.ratingList.forEach(rating => {
      if (rating.isChecked === true) {
        this.ratingList.push(rating.value);
      }
    });

    /* list catalog name */
    /* const catalogNameList: string[] = [];
    const catalogList: Catalog[] = this.formGroup.get('keywordList').value;
    catalogNameList.push(...catalogList.map((catalog) => catalog.name)); */

    this.formGroup.patchValue({
      ratingList: this.ratingList,
      vendorIdExcludeList: this.vendorIdExcludeList
      // keywordList: catalogNameList // if app-chosen
    });

    // this.tableResponse.setCustomData(this.formGroup.value);
    // this.tableResponse.resetRecords();
    this.tableResponse.setStateLoading();
    this.httpClientService
      .post<VendorView[]>(
        '/request-for-information/popup-vendor',
        this.formGroup.value
      )
      .subscribe((vendorViewList: VendorView[]) => {
        this.tableResponse.setRecords(vendorViewList ? vendorViewList : []);
        this.tableResponse.setTotalRecords(
          vendorViewList ? vendorViewList.length : 0
        );
        this.tableResponse.reloadClient();
        this.tableResponse.setStateReady();
      });

    this.ratingList = [];
    this.formGroup.patchValue({
      ratingList: this.ratingOptionLists
    });
  }

  public doReset(): void {
    this.dataRecords.splice(0, this.dataRecords.length);

    this.formGroup.reset();
    this.formGroup.patchValue({
      vendorIdExcludeList: this.vendorIdExcludeList
    });
    this.tableResponse.setRecords(
      this.vendorViewList ? this.vendorViewList : []
    );
    this.tableResponse.setTotalRecords(
      this.vendorViewList ? this.vendorViewList.length : 0
    );
    this.tableResponse.reloadClient();
    this.tableResponse.setStateReady();

    this.setOptionsValue();
  }

  public doCheckAll(event: any): void {
    if (event.target.checked) {
      this.dataRecords.splice(0);

      if (this.tableResponse.records !== this.vendorViewList) {
        /* setelah filter pasti all records table beda */
        this.dataRecords.push(...this.tableResponse.records);
      } else {
        this.dataRecords.push(...this.vendorViewList);
      }
    } else {
      this.dataRecords.splice(0);
    }
  }

  public doCheck(vendorView: VendorView): void {
    const indexOfVendor: number = this.dataRecords.indexOf(vendorView);

    if (indexOfVendor === -1) {
      this.dataRecords.push(vendorView);
      const indexVendorTable: number =
        this.tableResponse.records.indexOf(vendorView);
      this.tableResponse.records.splice(indexVendorTable, 1);
      this.tableResponse.records.unshift(vendorView);
    } else {
      this.dataRecords.splice(indexOfVendor, 1);
    }
  }

  public onKeyUp(event: KeyboardEvent): void {
    this.log.debug(event);
  }

  public onKeyDown(event: KeyboardEvent): void {
    this.log.debug(event);
  }
}
