import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorListDetailVendorComponent } from './vendor-list-detail-vendor.component';
import { VendorListDetailComponent } from './vendor-list-detail.component';
import { VendorListComponent } from './vendor-list.component';

export const routes = [
  { path: '', component: VendorListComponent, data: { breadcrumb: '' } },
  {
    path: 'detail',
    component: VendorListDetailComponent,
    data: { breadcrumb: 'vendor-list.breadcrumb.detail' }
  },
  {
    path: 'detail-vendor',
    component: VendorListDetailVendorComponent,
    data: { breadcrumb: 'vendor-list.breadcrumb.detailVendor' },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/pages/company-profile/company-profile.module').then(
            m => m.CompanyProfileModule
          ),
        data: { breadcrumb: 'Company Profile' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [
    VendorListComponent,
    VendorListDetailComponent,
    VendorListDetailVendorComponent
  ],
  providers: []
})
export class VendorListModule {}
