import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupContractActivityComponent } from './app-popup-contract-activity.component';
import { AppPopupProcurementActivityComponent } from './app-popup-procurement-activity.component';
import { WanprestasiAddComponent } from './wanprestasi-add.component';
import { WanprestasiComponent } from './wanprestasi.component';

export const routes = [
  { path: '', component: WanprestasiComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: WanprestasiAddComponent,
    data: { breadcrumb: 'wanprestasi.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: WanprestasiAddComponent,
    data: { breadcrumb: 'wanprestasi.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [
    WanprestasiComponent,
    WanprestasiAddComponent,
    AppPopupProcurementActivityComponent,
    AppPopupContractActivityComponent
  ],
  providers: [],
  exports: [
    AppPopupProcurementActivityComponent,
    AppPopupContractActivityComponent
  ],
  entryComponents: [
    AppPopupProcurementActivityComponent,
    AppPopupContractActivityComponent
  ]
})
export class WanprestasiModule {}
