import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { File } from 'src/app/core/bean/file';
import { Payment } from 'src/app/core/bean/payment';
import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { PaymentHistory } from 'src/app/core/bean/payment-history';
import { SowPaymentTermBilling } from 'src/app/core/bean/sow-payment-term-billing';
import { StageOfWork } from 'src/app/core/bean/stage-of-work';
import { VendorBank } from 'src/app/core/bean/vendor-bank';
import { WorkflowModelPrcs } from 'src/app/core/bean/workflow-model-prcs';
import { WorkflowPrcs } from 'src/app/core/bean/workflow-prcs';
import { AppPopupChooseBillService } from 'src/app/core/components/app-popup/app-popup-choose-bill/app-popup-choose-bill.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { WorkflowStepInfoModel } from 'src/app/core/components/app-workflow-step-info/model/workflow-step-info-model';
import { FileUploader } from 'src/app/core/components/upload';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';
import { PaymentRequestView } from 'src/app/core/view/entity/bean/payment-request-view';
import { PaymentBillingDoc } from '../../core/bean/payment-billing-doc';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { PaymentBillingView } from '../../core/view/entity/bean/payment-billing-view';
import { VendorPaymentRequestEditAddRequest } from './vendor-payment-request-edit-add-request';
import { VendorPaymentRequestEditAddResponse } from './vendor-payment-request-edit-add-response';
import { VendorPaymentRequestInsertRequest } from './vendor-payment-request-insert-request';
import { VendorPaymentRequestUpdateRequest } from './vendor-payment-request-update-request';

@Component({
  templateUrl: './vendor-payment-request-edit-add.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None
})
export class VendorPaymentRequestEditAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  public tableHistoryReviewPayment: AppTableComponent;
  public tableResponseHistoryPaymentReview: TableResponseModel<PaymentHistory>;

  public paymentId: number;
  public sowPaymentTermId: number;
  public deliveryItemId: number;
  public payment: Payment = new Payment();
  public paymentBilling: PaymentBilling = new PaymentBilling();
  vendorPaymentRequestBilling;

  public fileUploaderBillingTermList: FileUploader[] = [];
  public sowPaymentTermBillingList: SowPaymentTermBilling[];
  public paymentBillingDocList: PaymentBillingDoc[];
  public paymentHistoryList: PaymentHistory[];
  public vendorPaymentRequestEditAddRequest: VendorPaymentRequestEditAddRequest =
    new VendorPaymentRequestEditAddRequest();
  public vendorPaymentRequestEditAddResponse: VendorPaymentRequestEditAddResponse =
    new VendorPaymentRequestEditAddResponse();
  public vendorPaymentRequestInsertRequest: VendorPaymentRequestInsertRequest =
    new VendorPaymentRequestInsertRequest();
  public vendorPaymentRequestUpdateRequest: VendorPaymentRequestUpdateRequest =
    new VendorPaymentRequestUpdateRequest();
  public urlBack: string;
  public isLoading: boolean;
  public index: number;
  public objectId: number;
  public paymentBillingId: number;
  public workflowPrcs: WorkflowPrcs;
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public stageOfWork: StageOfWork;
  public approvalModelPrcsId: number;
  public remainingPayment: number;
  public isPartial: boolean;
  public isDeliverableItem: boolean;
  public workflowStepInfoModel: WorkflowStepInfoModel;
  public isExpandedVendorInfo: boolean;
  public token: string;

  public fileUploader: FileUploader = new FileUploader(
    '/vendor-payment-request/',
    '',
    this.global.appConstant.fileType.DOC_INVOICE
  );
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupChooseBillService: AppPopupChooseBillService,
    public activatedRoute: ActivatedRoute
  ) {
    super('vendor-payment-request', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<PaymentBillingView>(
        '/vendor-payment-request/notification/' + this.token
      )
      .subscribe((paymentBillingView: PaymentBillingView) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('todo', 'view');
        this.global.routerParams.set(
          'paymentId',
          paymentBillingView.payment.id
        );
        this.global.routerParams.set('payment', paymentBillingView.payment);
        this.global.routerParams.set('paymentBillingId', paymentBillingView.id);
        this.global.routerParams.set('paymentBillingView', paymentBillingView);
        this.global.routerParams.set(
          'urlBackOutside',
          '/pages/vendor-payment-request'
        );
        this.router.navigate(['/pages/vendor-payment-request/detail']);
      });
  }

  public setInitializationState(): void {
    this.buildTableResponse();
    this.setDataFromRouterParams();
    this.getAndSetPaymentHistoryList();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.paymentBillingId =
      localStorage.getItem('paymentBillingId') ||
      this.global.routerParams.get('paymentBillingId');
    this.objectId = this.global.routerParams.get('objectId');
    this.paymentId =
      localStorage.getItem('paymentId') ||
      this.global.routerParams.get('paymentId') ||
      this.objectId;
    this.urlBack =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );

    localStorage.removeItem('todo');
    localStorage.removeItem('paymentBillingId');
    localStorage.removeItem('paymentId');
    localStorage.removeItem('urlBackOutside');
  }

  public getAndSetPaymentHistoryList(): void {
    this.httpClientService
      .post<PaymentHistory[]>(
        '/vendor-payment-request/index-history-payment-review',
        new RouteRequestModel(null, this.paymentId)
      )
      .subscribe(paymentHistoryList => {
        this.paymentHistoryList = paymentHistoryList;
        if (paymentHistoryList !== []) {
          this.tableResponseHistoryPaymentReview.page.records =
            this.paymentHistoryList;
          this.tableResponseHistoryPaymentReview.page.totalRecords =
            this.paymentHistoryList && this.paymentHistoryList.length;
          this.tableResponseHistoryPaymentReview.reloadClient();
        }
      });
  }

  public buildTableResponse(): void {
    this.tableResponseHistoryPaymentReview = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'date',
          header: 'table.column.date',
          format: FieldFormatEnum.ShortDateAndTime
        },
        {
          field: 'status',
          header: 'table.column.status',
          fn: this.handleStatusTextColor.bind(this)
        },
        {
          field: 'picName',
          header: 'table.column.pic',
          customClass: 'text-left',
          sortable: false
        },
        {
          field: 'note',
          header: 'table.column.note'
        }
      ]
    );
  }

  public handleStatusTextColor(value: string, record: any): HTMLElement {
    this.log.debug(value);
    const spanElement = document.createElement('span');
    if (record.status === 1) {
      spanElement.style.color = 'var(--black)';
      spanElement.textContent = this.global.translateService.instant(
        'vendor-payment-request.new'
      );
    } else if (record.status === 2) {
      spanElement.style.color = 'var(--warning)';
      spanElement.textContent = this.global.translateService.instant(
        'vendor-payment-request.revision'
      );
    } else if (record.status === 3) {
      spanElement.style.color = 'var(--success)';
      spanElement.textContent = this.global.translateService.instant(
        'vendor-payment-request.approved'
      );
    }
    return spanElement;
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      number: [null, Validators.required()],
      invoiceDate: [null, Validators.required()],
      invoiceFile: [null, Validators.required()],
      file: [new File()],
      description: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(512)])
      ],
      vendorBank: [null, Validators.required()],
      isSubmit: [false],
      sowPaymentTermBillingList: this.formBuilder.array([])
    });
  }

  public setBillingTerm(): void {
    const parentsForm = this.formGroup.controls
      .sowPaymentTermBillingList as FormArray;
    if (this.todo === 'add') {
      this.sowPaymentTermBillingList =
        this.vendorPaymentRequestEditAddResponse.sowPaymentTermBillingList;
      this.sowPaymentTermBillingList.forEach(sowPaymentTermBilling => {
        const fileUpload: FileUploader = new FileUploader(
          '/vendor-payment-request/',
          '',
          this.global.appConstant.fileType.DOC_BILLING_TERMS
        );
        this.fileUploaderBillingTermList.push(fileUpload);
        parentsForm.push(
          this.formBuilder.group({
            billingTermName: sowPaymentTermBilling.billingTerm.name,
            billingTermCode: [null, Validators.required()],
            billingTermDate: [null, Validators.required()],
            file: [null, Validators.required()]
          })
        );
      });
    } else if (this.todo === 'edit' || this.todo === 'view') {
      this.paymentBillingDocList =
        this.vendorPaymentRequestEditAddResponse.paymentBillingDocList;
      this.paymentBillingDocList.forEach(
        (paymentBillingDoc: PaymentBillingDoc, index) => {
          const fileUpload: FileUploader = new FileUploader(
            '/vendor-payment-request/',
            '',
            this.global.appConstant.fileType.DOC_BILLING_TERMS
          );
          fileUpload.setFile(paymentBillingDoc.file);
          this.fileUploaderBillingTermList.push(fileUpload);
          this.log.debug(index);
          const formGroup = this.formBuilder.group({
            billingTermName:
              paymentBillingDoc.sowPaymentTermBilling.billingTerm.name,
            billingTermCode: [paymentBillingDoc.code, Validators.required()],
            billingTermDate: [
              paymentBillingDoc.billingDocDate &&
                new Date(paymentBillingDoc.billingDocDate),
              Validators.required()
            ],
            file: [fileUpload.fileObjectList, Validators.required()]
          });
          parentsForm.push(formGroup);
          if (this.todo === 'view') {
            formGroup.setIsView(true);
          }
        }
      );
    }
  }

  public setPaymentBillingDocument(): void {
    const billingDocumentList = this.formGroup.value.sowPaymentTermBillingList;
    if (this.todo === 'add') {
      this.paymentBillingDocList = billingDocumentList;
      this.paymentBillingDocList.forEach((paymentBillingDoc, index) => {
        paymentBillingDoc.fileObjectList = billingDocumentList[index].file;
        paymentBillingDoc.file = null;
        paymentBillingDoc.code = billingDocumentList[index].billingTermCode;
        paymentBillingDoc.billingDocDate =
          billingDocumentList[index].billingTermDate;
      });
      this.vendorPaymentRequestInsertRequest.sowPaymentTermBillingList =
        this.sowPaymentTermBillingList;
      this.vendorPaymentRequestInsertRequest.paymentBillingDocList =
        this.paymentBillingDocList;
    } else {
      this.paymentBillingDocList = billingDocumentList;
      this.paymentBillingDocList.forEach(
        (paymentBillingDoc: PaymentBillingDoc, index) => {
          paymentBillingDoc.fileObjectList =
            typeof billingDocumentList[index].file.length !== 'undefined'
              ? billingDocumentList[index].file
              : [];
          paymentBillingDoc.file = null;
          paymentBillingDoc.code = billingDocumentList[index].billingTermCode;
          paymentBillingDoc.billingDocDate =
            billingDocumentList[index].billingTermDate;
        }
      );
      this.vendorPaymentRequestUpdateRequest.paymentBillingDocList =
        this.paymentBillingDocList;
    }
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setRequest(): void {
    this.vendorPaymentRequestEditAddRequest.todo = this.todo;
    if (this.paymentId !== null) {
      this.vendorPaymentRequestEditAddRequest.paymentId =
        this.paymentId || this.objectId;
    }
    if (this.paymentBillingId !== null) {
      this.vendorPaymentRequestEditAddRequest.paymentBillingId =
        this.paymentBillingId;
    }
  }

  public setFormGroup(): void {
    this.setRequest();
    this.httpClientService
      .post<VendorPaymentRequestEditAddResponse>(
        '/vendor-payment-request/add-edit',
        this.vendorPaymentRequestEditAddRequest
      )
      .subscribe(
        (
          vendorPaymentRequestEditAddResponse: VendorPaymentRequestEditAddResponse
        ) => {
          this.vendorPaymentRequestEditAddResponse =
            vendorPaymentRequestEditAddResponse;
          this.isDeliverableItem =
            this.vendorPaymentRequestEditAddResponse.isDeliverableItem;
          this.paymentBilling =
            vendorPaymentRequestEditAddResponse.paymentBilling;
          this.stageOfWork = vendorPaymentRequestEditAddResponse.stageOfWork;
          this.paymentBillingDocList =
            this.vendorPaymentRequestEditAddResponse.paymentBillingDocList;
          this.sowPaymentTermBillingList =
            this.vendorPaymentRequestEditAddResponse.sowPaymentTermBillingList;
          this.payment = vendorPaymentRequestEditAddResponse.payment;
          this.isPartial =
            this.paymentBilling.sowPaymentTerm.billingMethod.code ===
            this.global.appConstant.pm.BILLING_METHOD_PARTIAL
              ? true
              : false;

          if (this.objectId && (this.todo === 'edit' || this.todo === 'view')) {
            this.payment = vendorPaymentRequestEditAddResponse.payment;
            this.workflowPrcs = this.payment.workflowPrcs;
            this.workflowModelPrcs = this.payment.workflowModelPrcs;
          }
          if (vendorPaymentRequestEditAddResponse.paymentInvoice !== null) {
            const invoiceDate: Date = new Date(
              vendorPaymentRequestEditAddResponse.paymentInvoice.invoiceDate
            );
            this.fileUploader.setFile(
              vendorPaymentRequestEditAddResponse.paymentInvoice.docFile
            );
            this.formGroup.patchValue({
              id: vendorPaymentRequestEditAddResponse.payment.id,
              number: vendorPaymentRequestEditAddResponse.paymentInvoice.code,
              invoiceDate,
              invoiceFile:
                vendorPaymentRequestEditAddResponse.paymentInvoice.docFile &&
                this.fileUploader,
              description:
                vendorPaymentRequestEditAddResponse.paymentInvoice.description,
              vendorBank: this.payment.vendorBank
            });
          }
          this.setBillingTerm();
          this.setStateReady();
        }
      );
  }

  public doSave(): void {
    this.formGroup.get('invoiceFile').clearValidators();
    this.formGroup.get('description').clearValidators();
    this.formGroup.get('vendorBank').clearValidators();

    this.formGroup.get('invoiceFile').updateValueAndValidity();
    this.formGroup.get('description').updateValueAndValidity();
    this.formGroup.get('vendorBank').updateValueAndValidity();

    const parentsForm = this.formGroup.controls
      .sowPaymentTermBillingList as FormArray;
    parentsForm.controls.forEach(sowPaymentTermBilling => {
      sowPaymentTermBilling.get('billingTermCode').clearValidators();
      sowPaymentTermBilling.get('billingTermDate').clearValidators();
      sowPaymentTermBilling.get('file').clearValidators();

      sowPaymentTermBilling.get('billingTermCode').updateValueAndValidity();
      sowPaymentTermBilling.get('billingTermDate').updateValueAndValidity();
      sowPaymentTermBilling.get('file').updateValueAndValidity();
    });

    this.validate();
    this.vendorPaymentRequestInsertRequest.isSubmit = false;
    this.vendorPaymentRequestUpdateRequest.isSubmit = false;

    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            if (this.todo === 'add') {
              this.doInsertVendorPaymentRequest();
            } else if (this.todo === 'edit') {
              this.doUpdateVendorPaymentRequest();
            }
          }
        });
    }
  }

  public doSubmit(): void {
    this.formGroup.get('invoiceFile').setValidators(Validators.required());
    this.formGroup.get('description').setValidators(Validators.required());
    this.formGroup.get('vendorBank').setValidators(Validators.required());

    this.formGroup.get('invoiceFile').updateValueAndValidity();
    this.formGroup.get('description').updateValueAndValidity();
    this.formGroup.get('vendorBank').updateValueAndValidity();

    const parentsForm = this.formGroup.controls
      .sowPaymentTermBillingList as FormArray;

    parentsForm.controls.forEach(sowPaymentTermBilling => {
      sowPaymentTermBilling
        .get('billingTermCode')
        .setValidators(Validators.required());
      sowPaymentTermBilling
        .get('billingTermDate')
        .setValidators(Validators.required());
      sowPaymentTermBilling.get('file').setValidators(Validators.required());

      sowPaymentTermBilling.get('billingTermCode').updateValueAndValidity();
      sowPaymentTermBilling.get('billingTermDate').updateValueAndValidity();
      sowPaymentTermBilling.get('file').updateValueAndValidity();
    });
    this.formGroup.get('isSubmit').patchValue(true);
    this.validate();
    this.vendorPaymentRequestInsertRequest.isSubmit = true;
    this.vendorPaymentRequestUpdateRequest.isSubmit = true;
    this.isExpandedVendorInfo =
      this.formGroup.get('isSubmit').value &&
      !this.formGroup.get('vendorBank').valid;

    if (this.formGroup.valid) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            if (this.todo === 'add') {
              this.doInsertVendorPaymentRequest();
            } else if (this.todo === 'edit') {
              this.doUpdateVendorPaymentRequest();
            }
          }
        });
    }
  }

  public doInsertVendorPaymentRequest(): void {
    this.setStateProcessing();
    if (
      this.sowPaymentTermBillingList !== [] ||
      this.paymentBillingDocList !== []
    ) {
      this.setPaymentBillingDocument();
    }
    this.payment = this.formGroup.value;
    this.vendorPaymentRequestInsertRequest.paymentInvoice =
      this.formGroup.value;
    this.vendorPaymentRequestInsertRequest.fileObjectList =
      this.fileUploader.fileObjectList;
    this.vendorPaymentRequestInsertRequest.sowPaymentTermId =
      this.sowPaymentTermId;
    this.payment = this.global.copyFormAttributeToModel(
      this.payment,
      this.formGroup
    );
    this.vendorPaymentRequestInsertRequest.payment = this.payment;
    this.vendorPaymentRequestInsertRequest.paymentBilling = this.paymentBilling;
    this.vendorPaymentRequestInsertRequest.isPartial = this.isPartial;
    this.vendorPaymentRequestInsertRequest.amount = this.payment.amount;
    this.vendorPaymentRequestInsertRequest.remainingPayment =
      this.remainingPayment;
    const url = '/vendor-payment-request/insert';
    this.httpClientService
      .post<Response<PaymentRequestView>>(
        url,
        this.vendorPaymentRequestInsertRequest
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.router.navigate(['/pages/vendor-payment-request']);
          this.global.alertService.showSuccessSavingOnNextRoute();
        } else {
          this.setStateReady();
          this.global.alertService.showError(response.statusText);
        }
      });
  }

  public doUpdateVendorPaymentRequest(): void {
    this.setStateProcessing();
    if (
      this.sowPaymentTermBillingList !== [] ||
      this.paymentBillingDocList !== []
    ) {
      this.setPaymentBillingDocument();
    }
    this.payment.vendorBank = this.formGroup.get('vendorBank').value;
    this.vendorPaymentRequestUpdateRequest.payment = this.payment;
    this.vendorPaymentRequestUpdateRequest.paymentInvoice =
      this.formGroup.value;
    this.vendorPaymentRequestUpdateRequest.paymentInvoice.id =
      this.vendorPaymentRequestUpdateRequest.paymentInvoice.id;
    this.vendorPaymentRequestUpdateRequest.fileObjectList =
      this.fileUploader.fileObjectList;
    this.vendorPaymentRequestUpdateRequest.paymentBilling = this.paymentBilling;
    this.vendorPaymentRequestUpdateRequest.paymentBillingId =
      this.paymentBilling.id;
    this.vendorPaymentRequestUpdateRequest.isPartial = this.isPartial;
    this.vendorPaymentRequestUpdateRequest.remainingPayment =
      this.remainingPayment;

    const url = '/vendor-payment-request/update';
    this.httpClientService
      .post<Response<PaymentRequestView>>(
        url,
        this.vendorPaymentRequestUpdateRequest
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.router.navigate(['/pages/vendor-payment-request']);
          this.global.alertService.showSuccessSavingOnNextRoute();
        } else {
          this.setStateReady();
          this.global.alertService.showError(response.statusText);
        }
      });
  }

  public doChangeBill(): void {
    this.global.modalService
      .confirmation(
        'vendor-payment-request.confirmation.changeBill.prompt',
        'vendor-payment-request.confirmation.changeBill.title',
        'vendor-payment-request.confirmation.changeBill.button.cancel',
        'vendor-payment-request.confirmation.changeBill.button.yes',
        'pvc pv-invoice',
        false
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.appPopupChooseBillService
            .open(false, this.paymentBilling)
            .subscribe(paymentBilling => {
              this.log.debug(paymentBilling);
              this.setStateLoading();
              this.global.routerParams.clear();
              this.global.routerParams.set('todo', 'add');
              this.global.routerParams.set(
                'paymentBillingId',
                paymentBilling.id
              );
              this.global.routerParams.set(
                'sowPaymentTermId',
                paymentBilling.sowPaymentTerm.id
              );
              this.global.routerParams.set(
                'vendorPaymentRequestBilling',
                paymentBilling
              );
              this.global.routerParams.set(
                'urlBackOutside',
                '/pages/vendor-payment-request'
              );
              this.setInitializationState();
            });
        }
      });
  }

  public onChangeAmount(): void {
    const remainingPaymentTemp = this.paymentBilling.sowPaymentTerm.amount
      ? this.paymentBilling.sowPaymentTerm.amount
      : this.vendorPaymentRequestEditAddResponse.remainingPayment;
    const amountTemp = this.vendorPaymentRequestEditAddResponse.payment
      ? this.vendorPaymentRequestEditAddResponse.payment.amount
      : 0;
    const totalRemainingAmount = remainingPaymentTemp + amountTemp;
    const amount = this.formGroup.value.amount;
    if (amount && amount !== '') {
      this.remainingPayment = totalRemainingAmount - +amount;
    } else {
      if (this.todo === 'edit') {
        this.remainingPayment = remainingPaymentTemp + amountTemp;
      } else {
        this.remainingPayment = remainingPaymentTemp;
      }
    }
    if (amount > totalRemainingAmount) {
      this.remainingPayment = null;
    }
  }

  public doBack(): void {
    if (this.urlBack === 'close-window') {
      window.close();
    } else if (this.vendorPaymentRequestEditAddResponse.isUserApproval) {
      this.router.navigate(['/pages/approval-prcs']);
    } else {
      this.router.navigate([this.urlBack]);
    }
  }

  public onChangeVendor(vendorBank: VendorBank): void {
    this.formGroup.patchValue({
      vendorBank
    });
  }
}
