
import { BaseDTO } from '../../../core/base/base-dto';
import { File } from '../../../core/bean/file';
import { Procurement } from '../../../core/bean/procurement';
import { PurchaseRequest } from '../../../core/bean/purchase-request';
import { PurchaseRequestItem } from '../../../core/bean/purchase-request-item';
import { WorkPlanRealize } from '../../../core/bean/work-plan-realize';
import { FileObject } from '../../../core/components/upload';
export class WorkPlanRealizeProcurementDTO extends BaseDTO {
  workPlanRealize: WorkPlanRealize;
  procurementList: Procurement[];
  fileObjectList: FileObject[];
  fileList: File[];
  purchaseRequestList: PurchaseRequest[];
  purchaseRequestListIsSelected: PurchaseRequest[];
  purchaseRequestItemList: PurchaseRequestItem[];
}
