import { Component, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Threshold } from '../../core/bean/threshold';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { Condition } from './../../core/bean/condition';
import { ThresholdRange } from './../../core/bean/threshold-range';
@Component({
  templateUrl: './threshold-edit-add.component.html',
  styles: [
    '.table th.fit{ white-space: nowrap; width: 200px; min-width: 200px;}'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ThresholdEditAddComponent extends BaseModuleComponent {
  public threshold: Threshold = new Threshold();
  public condition: Condition = new Condition();
  public thresholdRangeListResult: ThresholdRange[] = [];
  public isDisabled: boolean;
  public disableSaveButton: boolean;
  constructor(translateService: TranslateService) {
    super('threshold', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.doGetAndSetThreshold();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.condition = this.global.routerParams.get('condition');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      thresholdRangeList: this.formBuilder.array([])
    });
  }

  public doGetAndSetThreshold(): void {
    this.httpClientService
      .post<Threshold>('/threshold/edit', this.condition.id)
      .subscribe(threshold => {
        this.threshold = threshold;
        this.doBuildFormControl(this.threshold.thresholdRangeList);
        if (this.threshold != null) {
          for (let i = 0; this.thresholdRangeList.length > i; i++) {
            if (i !== this.thresholdRangeList.length - 1) {
              this.thresholdRangeList.at(i).get('to').disable();
            }
          }
          if (this.threshold.thresholdRangeList.length === 1) {
            this.threshold.thresholdRangeList[0].isDeletable = false;
          }
        }
        this.setStateReady();
        this.doSetDisabledFromThresholdRangeList();
        this.doSetDisabledToThresholdRangeList();
      });
  }

  public doSetDisabledFromThresholdRangeList(): void {
    this.thresholdRangeList.controls.forEach((formGroup: FormGroup) => {
      formGroup.get('from').disable();
    });
  }

  public doSetDisabledToThresholdRangeList(): void {
    this.thresholdRangeList.controls.forEach((formGroup: FormGroup, index) => {
      if (
        index === this.thresholdRangeList.length - 1 &&
        formGroup.get('isInfinite').value === false
      ) {
        formGroup.get('to').enable();
      } else if (
        index === this.thresholdRangeList.length - 1 &&
        formGroup.get('isInfinite').value === true
      ) {
        formGroup.get('to').disable();
        formGroup.get('to').setValue('-');
        this.isDisabled = true;
      } else {
        formGroup.get('to').enable();
      }
    });
  }

  public doSetDisabledToThresholdRangeListAfterValidation(): void {
    this.thresholdRangeList.controls.forEach((formGroup: FormGroup, index) => {
      if (index === this.thresholdRangeList.length - 1) {
        formGroup.get('to').disable();
        formGroup.get('to').setValue('-');
      }
    });
  }

  public doBuildFormControl(thresholdRangeList: ThresholdRange[]): void {
    thresholdRangeList.forEach(thresholdRange => {
      // let thresholdTo: any;
      const formGroup = this.formBuilder.group({
        id: [thresholdRange.id],
        from: [thresholdRange.from],
        to: [thresholdRange.to, Validators.compose([Validators.required()])],
        isInfinite: [thresholdRange.isInfinite]
      });
      this.thresholdRangeList.push(formGroup);
      if (thresholdRange.isInfinite) {
        // thresholdTo = '-';
      }
    });
  }

  public doAdd(): void {
    const thresholdRange: ThresholdRange = new ThresholdRange();
    const sizeFrom = this.thresholdRangeList.length - 1;
    const fromNotConvertMoney = this.thresholdRangeList
      .at(sizeFrom)
      .get('to').value;
    const fromConvertInt = parseFloat(fromNotConvertMoney) + 1;
    const fromConvertMoney = fromConvertInt;
    this.thresholdRangeList.push(
      this.formBuilder.group({
        id: [null],
        from: [{ value: fromConvertMoney, disabled: true }],
        to: [null, Validators.compose([Validators.required()])],
        isInfinite: [false]
      })
    );
    thresholdRange.isDeletable = true;
    this.threshold.thresholdRangeList.push(thresholdRange);
    if (this.threshold.thresholdRangeList.length > 1) {
      this.threshold.thresholdRangeList.forEach(thresholdRange => {
        thresholdRange.isDeletable = true;
      });
    }
  }

  public doRemove(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (index === this.thresholdRangeList.length - 1) {
            this.isDisabled = false;
          }
          if (
            this.thresholdRangeList.controls[index].get('isInfinite').value ===
            true
          ) {
            this.disableSaveButton = true;
          }
          this.thresholdRangeList.removeAt(index);
          if (index === 0 && this.thresholdRangeList.controls.length > 0) {
            this.thresholdRangeList.controls[index].get('from').setValue(0);
            this.thresholdRangeList.controls[index].get('from').disable();
          }

          this.threshold.thresholdRangeList.splice(index, 1);
          if (this.threshold.thresholdRangeList.length === 1) {
            this.threshold.thresholdRangeList[0].isDeletable = false;
          }
        }
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.thresholdRangeList.value.forEach((thresholdRange, index) => {
              const thresholdFrom = this.global.converterService.uConvertMoney(
                thresholdRange.from.toString()
              );
              const from = parseFloat(thresholdFrom);
              const thresholdTo = this.global.converterService.uConvertMoney(
                thresholdRange.to.toString()
              );
              let to: number;
              if (thresholdTo !== '-') {
                to = parseFloat(thresholdTo);
              } else {
                to = null;
              }
              this.thresholdRangeList.at(index).patchValue({ from, to });
            });
            this.thresholdRangeListResult = this.threshold.thresholdRangeList;
            const threshold: Threshold = this.threshold;
            threshold.thresholdRangeList =
              this.formGroup.get('thresholdRangeList').value;

            this.httpClientService
              .post<Response<Threshold>>('/threshold/update', threshold)
              .subscribe((response: Response<Threshold>) => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/threshold']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                  this.threshold.thresholdRangeList =
                    this.thresholdRangeListResult;
                  this.doSetDisabledFromThresholdRangeList();
                  this.doSetDisabledToThresholdRangeListAfterValidation();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/threshold']);
  }

  public doIsInfiniteChange(
    value: boolean,
    thresholdRange: FormGroup,
    index: number
  ): void {
    if (value) {
      thresholdRange.get('to').disable();
      thresholdRange.patchValue({
        to: '-'
      });
      this.disableSaveButton = false;
      for (let i = this.thresholdRangeList.length; i > index; i--) {
        this.thresholdRangeList.removeAt(i);
      }
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
      this.disableSaveButton = true;
      thresholdRange.get('to').enable();
      thresholdRange.patchValue({ to: '' });
    }
  }

  public doOnInputMethod(thresholdRange: FormGroup): void {
    const thresholdFrom = this.global.converterService.uConvertMoney(
      thresholdRange.get('from').value
    );
    const fromConvertInt = parseFloat(thresholdFrom);
    const toConvertInt = parseFloat(thresholdRange.get('to').value);

    if (fromConvertInt > toConvertInt) {
      thresholdRange
        .get('to')
        .setValidators(
          Validators.compose([
            Validators.required(),
            Validators.min(fromConvertInt)
          ])
        );
    } else {
      thresholdRange.get('to').setValidators(Validators.required());
    }
  }

  public doOnChangeToThresholdRangeList(index: number): void {
    const sizeFrom = this.thresholdRangeList.length - 1;
    const fromNotConvertMoney = this.thresholdRangeList
      .at(index)
      .get('to').value;
    const fromConvertInt = parseFloat(fromNotConvertMoney) + 1;
    const fromConvertMoney = fromConvertInt;
    if (index + 1 <= sizeFrom) {
      this.thresholdRangeList.controls[index + 1]
        .get('from')
        .patchValue(fromConvertMoney);
      this.thresholdRangeList.controls[index + 1].get('from').disable();
    }
  }

  public get thresholdRangeList(): FormArray {
    return this.formGroup.get('thresholdRangeList') as FormArray;
  }
}
