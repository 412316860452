import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppRatingDetailComponent } from './app-rating-detail/app-rating-detail.component';
import { AppRatingIconComponent } from './app-rating-icon/app-rating-icon.component';
import { AppRatingProductComponent } from './app-rating-product/app-rating-product.component';
import { AppRatingComponent } from './app-rating.component';
@NgModule({
  imports: [VendorModule],
  declarations: [
    AppRatingComponent,
    AppRatingIconComponent,
    AppRatingProductComponent,
    AppRatingDetailComponent
  ],
  entryComponents: [AppRatingComponent],
  exports: [
    AppRatingComponent,
    AppRatingIconComponent,
    AppRatingProductComponent,
    AppRatingDetailComponent
  ]
})
export class AppRatingModule {}
