import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { PopupVendorView } from '../../core/view/entity/bean/popup-vendor-view';

@Component({
  templateUrl: './app-popup-vendor-winner-info.component.html'
})
export class AppPopupVendorWinnerInfoComponent extends BaseComponentComponent {
  @Input() public procurementId: number;

  public tableResponse: TableResponseModel<PopupVendorView>;

  constructor() {
    super('compliance-test');
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.vendorName'
      },
      {
        field: 'address',
        header: 'table.column.address'
      },
      {
        field: 'phone',
        header: 'table.column.phone'
      },
      {
        field: 'picName',
        header: 'table.column.vendorPIC'
      },
      {
        field: 'averageRatingOverall',
        header: 'table.column.performance',
        plugins: {
          name: 'rating',
          isView: true
        }
      }
    ]);

    this.tableResponse.setCustomData({
      id: this.procurementId
    });
  }
}
