import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../core/shared/shared.module';
import { WorklistPraProcurementComponent } from './worklist-pra-procurement.component';

export const routes = [{ path: '', component: WorklistPraProcurementComponent, data: { breadcrumb: '' } }];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, NgbModule],
  declarations: [WorklistPraProcurementComponent],
  providers: [],
})
export class WorklistPraProcurementModule {}
