import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppPaginationXComponent } from './app-pagination-x.component';

@NgModule({
  imports: [VendorModule],
  declarations: [AppPaginationXComponent],
  exports: [AppPaginationXComponent]
})
export class AppPaginationXModule {}
