import { Component, SecurityContext, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { ResolveImgSrc } from 'src/app/core/pipe/resolve-img-src.pipe';
import { TablePluginData } from '../../../../../core/components/table/interface/table-plugin-data';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetBestSavingByBuyerModel } from './widget-best-saving-by-buyer-model';

@Component({
  selector: 'dashboard-widget-best-saving-by-buyer',
  templateUrl: './widget-best-saving-by-buyer.component.html',
  styleUrls: ['./widget-best-saving-by-buyer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetBestSavingByBuyerComponent extends BaseWidgetComponent {
  public tableResponse: TableResponseModel<WidgetBestSavingByBuyerModel>;
  public static moduleCode = 'dashboard-widget-best-saving-by-buyer';

  constructor(public translateService: TranslateService) {
    super(WidgetBestSavingByBuyerComponent.moduleCode);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setTableResponse();
  }

  private buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'rankNumber',
          header: 'table.column.rank',
          isSortable: false,
          className: 'pr-0',
          plugins: {
            name: 'custom-plugin',
            before: (data: TablePluginData): HTMLElement => {
              const spanElement = document.createElement('span');
              spanElement.textContent = '#' + data.row.record.rankNumber;
              spanElement.className = 'fw-500 pl-auto pr-0 text-secondary';
              data.element.classList.add('text-center');
              return spanElement;
            }
          }
        },
        {
          field: 'buyerName',
          isSortable: false,
          className: 'pl-0 pr-auto',
          plugins: {
            name: 'custom-plugin',
            before: (data: TablePluginData): any => {
              const imgElement = document.createElement('img');
              const imageUrl = data.row.record.uploadedFileName
                ? this.global.config.BACKEND_ADDRESS +
                  '/widget-best-saving-by-buyer/thumbnail/view/' +
                  data.row.record.uploadedFileName +
                  '/' +
                  data.row.record.fileTypeCode
                : '/assets/img/app/no-image.png';
              const resolveImgSrc = data.state.injector.get(ResolveImgSrc);
              const domSanitizer = data.state.injector.get(DomSanitizer);
              resolveImgSrc.transform(imageUrl).then(imgSrc => {
                imgElement.src = domSanitizer.sanitize(
                  SecurityContext.URL,
                  imgSrc
                );
              });
              imgElement.width = 36;
              imgElement.height = 36;
              imgElement.style.borderRadius = '36px';
              imgElement.style.margin = '0 auto';
              data.element.classList.add('px-0');
              data.element.style.height = '36px';
              return imgElement;
            }
          }
        },
        {
          field: 'buyerName',
          header: 'table.column.name',
          isSortable: false
        },
        {
          field: 'totalSaving',
          header: 'table.column.totalSaving',
          isSortable: false,
          className: 'custom-text-right',
          plugins: [
            {
              name: 'custom-plugin',
              after: (tablePlugin: TablePluginData): void => {
                if (tablePlugin.value) {
                  tablePlugin.element.innerText =
                    (this.global.converterService.convertMoney(
                      tablePlugin.value
                    ) as string) + ' IDR';
                }
              }
            }
          ]
        }
      ],
      {
        recordPerPage: 5
      }
    );
  }

  private setTableResponse(): void {
    this.httpClientService
      .get<WidgetBestSavingByBuyerModel[]>('/widget-best-saving-by-buyer/index')
      .subscribe(
        (widgetBestSavingByBuyerModelList: WidgetBestSavingByBuyerModel[]) => {
          widgetBestSavingByBuyerModelList.forEach(
            (widgetBestSavingByBuyerModel, index) => {
              widgetBestSavingByBuyerModel.rankNumber = index + 1;
            }
          );
          this.tableResponse.setRecordList(widgetBestSavingByBuyerModelList);
          this.tableResponse.reload();
          this.setStateReady();
        }
      );
  }
}
