import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppAgreementTypeModule } from 'src/app/core/components/app-agreement-type/app-agreement-type.module';
import { AppApprovalPrcsXModule } from '../../core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppItemRequestModule } from '../../core/components/app-item-request/app-item-request.module';
import { AppOfficialReportModule } from '../../core/components/app-official-report/app-official-report.module';
import { AppPopupAllocatedItemDetailModule } from '../../core/components/app-popup/app-popup-allocated-item-detail/app-popup-allocated-item-detail.module';
import { AppPopupChooseVendorMultiWinnerModule } from '../../core/components/app-popup/app-popup-choose-vendor-multi-winner/app-popup-choose-vendor-multi-winner.module';
import { AppPopupFinalPriceDetailModule } from '../../core/components/app-popup/app-popup-final-price-detail/app-popup-final-price-detail.module';
import { AppProcurementInfoModule } from '../../core/components/app-procurement-info/app-procurement-info.module';
import { AppWorkflowStepInfoModule } from '../../core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorDesignationEditComponent } from './vendor-designation-edit.component';
import { VendorDesignationPopupChangeWinnerComponent } from './vendor-designation-popup-change-winner.component';
import { VendorDesignationPopupWinnerComponent } from './vendor-designation-popup-winner-confirmation.component';
const routes = [
  {
    path: 'add',
    component: VendorDesignationEditComponent,
    data: { breadcrumb: 'vendor-designation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: VendorDesignationEditComponent,
    data: { breadcrumb: 'vendor-designation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: VendorDesignationEditComponent,
    data: { breadcrumb: 'vendor-designation.breadcrumb.detail' }
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppWorkflowStepInfoModule,
    AppProcurementInfoModule,
    AppItemRequestModule,
    AppPopupFinalPriceDetailModule,
    AppPopupChooseVendorMultiWinnerModule,
    AppPopupAllocatedItemDetailModule,
    AppApprovalPrcsXModule,
    AppOfficialReportModule,
    AppBadgeCatalogModule,
    AppAgreementTypeModule
  ],
  declarations: [
    VendorDesignationEditComponent,
    VendorDesignationPopupChangeWinnerComponent,
    VendorDesignationPopupWinnerComponent
  ],
  exports: [
    VendorDesignationPopupChangeWinnerComponent,
    VendorDesignationPopupWinnerComponent
  ],
  entryComponents: [
    VendorDesignationPopupChangeWinnerComponent,
    VendorDesignationPopupWinnerComponent
  ]
})
export class VendorDesignationModule {}
