<nav class="content-navigation">
  <ul class="content-menu">
    <li
      class="menu-item"
      *ngFor="let item of tipItemList"
      [ngClass]="item.active ? 'active' : ''"
      (click)="doScrollToItem(item)"
      [attr.id]="'tips' + item.id"
    >
      {{ item.header | translate }}
    </li>
  </ul>
</nav>

<div
  class="tips-wrapper"
  [ngClass]="
    userPreference.value && userPreference.value === 'FALSE' ? 'p-3' : ''
  "
  *ngIf="
    (userPreference && userPreference.value === 'FALSE') ||
    ((!userPreference.value || userPreference.value === 'TRUE') &&
      activeTip &&
      activeTip.isShowTip)
  "
>
  <div
    class="not-show"
    *ngIf="userPreference.value && userPreference.value === 'FALSE'"
  >
    <h4 class="show-tips" (click)="doOpen()">
      {{ 'app-tips.button.show' | translate }}
      <em class="pir pi-chevron-circle-down ml-2"></em>
    </h4>
    <div class="tips-icon pvc pv-lightbulb"></div>
  </div>
  <div
    *ngIf="
      tipItemList.length > 0 &&
      (!userPreference.value || userPreference.value === 'TRUE') &&
      activeTip &&
      activeTip.isShowTip
    "
    class="text-center show"
  >
    <h4 class="title">
      {{ 'app-tips.title' | translate }}
    </h4>
    <div class="tips-icon pvc pv-lightbulb"></div>
    <div class="tips-content" *ngFor="let content of activeTipContent">
      <p class="text-left text-break">
        {{ content | translate }}
      </p>
      <app-button
        (onClick)="doHideTips(activeTip.id)"
        style="display: flex; place-content: center; margin: 15px 0 20px"
        >{{ 'app.button.ok' | translate }}</app-button
      >
    </div>
    <a (click)="doClose()" class="font-weight-bold link-dont-show">
      {{ 'app-tips.hyperlink.dontShow' | translate }}
    </a>
  </div>
</div>
