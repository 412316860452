import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppChartModule } from '../../../../../core/components/app-chart/app-chart.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetContractPerCategoryComponent } from './widget-contract-per-category.component';
@NgModule({
  imports: [VendorModule, WidgetCardModule, AppChartModule, WidgetCardModule],
  declarations: [WidgetContractPerCategoryComponent],
  entryComponents: [WidgetContractPerCategoryComponent],
  exports: [WidgetContractPerCategoryComponent]
})
export class WidgetContractPerCategoryModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetContractPerCategoryComponent.moduleCode,
      WidgetContractPerCategoryComponent,
      'w-50'
    );
  }
}
