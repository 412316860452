<div class="attendance-form information">
  <div
    class="mb-4"
    [ngStyle]="{
      'max-width': '576px',
      margin: 'auto'
    }"
  >
    <ng-container>
      <br />
      <app-attendance-form-card>
        <div
          *ngIf="icon"
          [ngClass]="icon"
          style="width: 100px; height: 100px; margin: 0 auto"
        ></div
        ><br />
        <h5 class="text-center" *ngIf="isSuccessSubmit">{{
          'attendance-form.information.title.successSubmit' | translate
        }}</h5>
        <h5 class="text-center" *ngIf="!isSuccessSubmit">{{
          'attendance-form.information.title.expiredSession' | translate
        }}</h5>
        <br />
        <h6 class="text-center">{{ message }}</h6>
      </app-attendance-form-card>
      <div class="maxwidth" *ngIf="isSuccessSubmit">
        <app-button (onClick)="doShowAttendanceList()">
          {{ 'attendance-form.button.showList' | translate }}
        </app-button>
      </div>
    </ng-container>
  </div>
</div>
