<app-layout [isLoading]="formLoading">
  <div class="row main-row">
    <div class="col-lg-12">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-card header="procurement-matrix.detail.matrixCondition.title">
          <!--[isLoading]="formLoading"
                    [isSaving]="formSaving"-->
          <h5 class="separator"></h5>
          <app-fullfilled></app-fullfilled>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'procurement-matrix.form.code' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="code"
                maxLength="64"
                type="code"
                placeholder="{{
                  'procurement-matrix.placeholder.typeCode' | translate
                }}"
              >
              </app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'procurement-matrix.form.name' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="name"
                maxLength="128"
                type="alphanumeric"
                placeholder="{{
                  'procurement-matrix.placeholder.typeInput' | translate
                }}"
                size="LG"
              >
              </app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'procurement-matrix.form.description' | translate }}
            </label>
            <div
              [ngClass]="
                !formGroup.isView ? 'col-sm-9 text-left' : 'col-sm-6 text-left'
              "
            >
              <app-text-area
                formControlName="description"
                maxLength="512"
                placeholder="{{
                  'procurement-matrix.placeholder.typeInput' | translate
                }}"
                size="LG"
              >
              </app-text-area>
            </div>
          </div>
          <br />

          <h5 class="separator">{{
            'procurement-matrix.detail.rulesCondition' | translate
          }}</h5>
          <!-- <div class="form-group row text-sm-left"
                    [ngStyle]="{ 
                                'height': '80px', 'border-radius': '5px',
                                'background': 'var(--light-secondary-color)',
                                'margin-right': '0.01em',
                                'margin-left': '0.01em',
                                'padding-top': '1.55em'
                                }">
                    <label class="col-sm-3">
                        {{ 'procurement-matrix.form.addObject' | translate }}
                    </label>
                    <div class="col-sm-9 text-left" [ngStyle]="{ 'padding-left': '0.65em' }">
                        <app-dropdown-select 
                            type="CHOSEN"
                            formControlName="conditionList" 
                            [optionList]="conditionOptionList"
                            (onChange)="onChangeCondition($event)"
                            size="LG">
                            <ng-template #selectAll>
                                <p>{{ 'procurement-matrix.placeholder.allObject' | translate }}</p>
                            </ng-template>
                        </app-dropdown-select>
                        
                    </div>
                </div> -->

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'procurement-matrix.form.procurementValue' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                type="COMBOBOX"
                formControlName="thresholdRange"
                [optionList]="procurementValueOptionList"
                placeholder="procurement-matrix.placeholder.chooseOne"
              >
              </app-dropdown-select>
            </div>
          </div>

          <!-- <div *ngIf="!isProcMethodInMatrix" class="form-group row text-sm-left">
                    <label class="col-sm-3">
                        {{ 'procurement-matrix.form.procurementMethod' | translate }}
                    </label>
                    <div class="col-sm-9 text-left">
                        <app-dropdown-select 
                            type="COMBOBOX"
                            formControlName="procurementMethod" 
                            [optionList]="procurementMethodOptionList"
                            placeholder="procurement-matrix.placeholder.chooseOne">
                        </app-dropdown-select>
                    </div>
                </div> -->
        </app-card>

        <app-card header="procurement-matrix.detail.matrixRule.title">
          <h5 class="separator"></h5>
          <app-fullfilled [isOptional]="isOptional"></app-fullfilled>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'procurement-matrix.form.procurementMethod' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                type="COMBOBOX"
                formControlName="procurementMethod"
                [optionList]="procurementMethodOptionList"
                placeholder="procurement-matrix.placeholder.chooseOne"
                (onChange)="onChangeProcMethod($event)"
              >
              </app-dropdown-select>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'procurement-matrix.form.transactionType' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              {{
                formGroup.get('procurementMethod')?.value?.transactionType
                  ?.name || '-'
              }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'procurement-matrix.form.registrationType' | translate }}
            </label>
            <div class="col-sm-6 text-left">
              <!-- {{ (formGroup.get('procurementMethod')?.value?.procurementRegType?.translationKey.module.code.toLowerCase()
                            + '.' + formGroup.get('procurementMethod')?.value?.procurementRegType?.translationKey.key)
                            || '-' | translate }} -->

              {{
                formGroup.get('procurementMethod')?.value?.procurementRegType
                  ?.name || '-'
              }}
            </div>
          </div>

          <ng-container *ngIf="formGroup.get('procurementMethod').value">
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{
                  'procurement-matrix.form.documentSubmissionType' | translate
                }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="COMBOBOX"
                  formControlName="documentSubmissionType"
                  [optionList]="documentSubmissionTypeOptionList"
                  placeholder="procurement-matrix.placeholder.chooseOne"
                >
                </app-dropdown-select>
              </div>
            </div>

            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{ 'procurement-matrix.form.negotiationType' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="COMBOBOX"
                  formControlName="negotiationType"
                  [optionList]="negotiationTypeOptionList"
                  placeholder="procurement-matrix.placeholder.chooseOne"
                >
                </app-dropdown-select>
              </div>
            </div>

            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{ 'procurement-matrix.form.priceEvaluation' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="COMBOBOX"
                  formControlName="priceEvaluationType"
                  [optionList]="priceEvaluationTypeOptionList"
                  placeholder="procurement-matrix.placeholder.chooseOne"
                >
                </app-dropdown-select>
              </div>
            </div>

            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{ 'procurement-matrix.form.biddingEvaluation' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="COMBOBOX"
                  formControlName="biddingEvaluation"
                  [optionList]="biddingEvaluationOptionList"
                  placeholder="procurement-matrix.placeholder.chooseOne"
                >
                </app-dropdown-select>
              </div>
            </div>

            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{
                  'procurement-matrix.form.biddingSubmissionType' | translate
                }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="COMBOBOX"
                  formControlName="biddingSubmissionType"
                  [optionList]="biddingSubmissionTypeOptionList"
                  placeholder="procurement-matrix.placeholder.chooseOne"
                >
                </app-dropdown-select>
              </div>
            </div>

            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{ 'procurement-matrix.form.vendorSelection' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="COMBOBOX"
                  formControlName="vendorSelectionType"
                  [optionList]="vendorSelectionTypeOptionList"
                  placeholder="procurement-matrix.placeholder.chooseOne"
                >
                </app-dropdown-select>
              </div>
            </div>

            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{ 'procurement-matrix.form.currencyType' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-dropdown-select
                  type="COMBOBOX"
                  formControlName="currencyType"
                  [optionList]="currencyTypeOptionList"
                  placeholder="procurement-matrix.placeholder.chooseOne"
                >
                </app-dropdown-select>
              </div>
            </div>

            <div class="form-group row text-sm-left">
              <label class="col-sm-3">
                {{ 'procurement-matrix.form.praQualification' | translate }}
              </label>
              <div class="col-sm-9 text-left">
                <app-toggle-switch formControlName="isPq"></app-toggle-switch>
              </div>
            </div>

            <div class="form-group row text-sm-left" 
            *ngIf="!formGroup.value.procurementMethod || formGroup.value.procurementMethod?.procurementRegType?.code === this.global.appConstant.pm.PROC_REG_TYPE_INVITATION">
              <label class="col-sm-3">
                {{
                  'procurement-matrix.form.invitedVendorOnInitiation'
                    | translate
                }}
              </label>
              <div class="col-sm-9 text-left">
                <div class="d-inline-block position-relative mb-1">
                  <app-toggle-switch
                    formControlName="isShowVendorResult"
                  ></app-toggle-switch>
                  <app-tooltip-wrapper
                    tooltip="{{
                      'procurement-matrix.tooltip.invitedVendorOnInitiation'
                        | translate
                    }}"
                  ></app-tooltip-wrapper>
                </div>
                <p *ngIf="formGroup.value.isShowVendorResult" class="text-secondary" style="font-size: 12px">{{
                  'procurement-matrix.form.vendorWillBeAddedAtProcurementInitiation'
                    | translate
                }}</p>
                <p *ngIf="!formGroup.value.isShowVendorResult" class="text-secondary" style="font-size: 12px">{{
                  'procurement-matrix.form.vendorWillBeAddedAtInvitationMenu'
                    | translate
                }}</p>
              </div>
            </div>
          </ng-container>
        </app-card>
      </form>

      <div class="button-group button-group-center">
        <app-button
          (onClick)="doSave()"
          [disabled]="formSaving || formGroup.invalid"
        >
          {{ 'app.button.save' | translate }}</app-button
        >
      </div>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
