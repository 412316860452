<ng-container *ngIf="isShowFilter">
  <app-table-catalog-filter
    [model]="tableCatalogFilterModel"
    (onChange)="model.setCustomData($event)"
  ></app-table-catalog-filter>
</ng-container>
<app-card>
  <div class="d-flex">
    <div class="input-group d-inline-block">
      <label class="mr-2" for="filter-table"
        >{{ 'app-table-catalog.show' | translate }}:
      </label>
      <select
        class="form-control custom-select"
        (change)="model.setPerPage($event.target.value)"
        id="filter-table"
        [ngStyle]="{ width: '56px' }"
        aria-label="Show"
      >
        <option *ngFor="let option of showOptionList" [ngValue]="option">{{
          option
        }}</option>
      </select>
    </div>
    <div class="ml-2 d-inline-block" [ngStyle]="{ width: '300px' }">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><em class="fas fa-search"></em></span>
        </div>
        <input
          type="text"
          name="search"
          class="form-control"
          placeholder="{{ 'app.table.search' | translate }}"
          (input)="onInputSearch($event)"
          aria-label="Search"
        />
      </div>
    </div>
    <div class="ml-2 d-inline-block" [ngStyle]="{ width: '250px' }">
      <select
        class="form-control"
        (change)="model.setSortField($event.target.value)"
        aria-label="Sort Category"
      >
        <option value="">{{ 'app-table-catalog.sortBy' | translate }}</option>
        <option value="Name"
          >{{ 'app-table-catalog.nameAtoZ' | translate }}
        </option>
        <option value="Highest-Price">{{
          'app-table-catalog.highestPrice' | translate
        }}</option>
        <option value="Lowest-Price">{{
          'app-table-catalog.lowestPrice' | translate
        }}</option>
        <option value="Location"
          >{{ 'app-table-catalog.location' | translate }}
        </option>
      </select>
    </div>
    <div
      class="ml-2 d-flex align-items-center"
      [ngStyle]="{ width: '100px', 'justify-content': 'space-around' }"
    >
      <div class="view-button-list">
        <em
          class="fas fa-list-ul cursor-pointer"
          [ngStyle]="{ 'font-size': '1.5rem' }"
          (click)="model.setViewTipe('LIST')"
          [ngClass]="{ 'text-warning': model.viewType === 'LIST' }"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        ></em>
      </div>
      <div class="view-button-grid">
        <em
          class="fas fa-th cursor-pointer"
          [ngStyle]="{ 'font-size': '1.5rem' }"
          (click)="model.setViewTipe('GRID')"
          [ngClass]="{ 'text-warning': model.viewType === 'GRID' }"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        ></em>
      </div>
    </div>
    <div class="ml-2 d-flex align-items-center justify-content-center">
      <app-button-compare
        tooltip="app-table-catalog.showComparisonList"
        (click)="doShowPopupComparison()"
        totalCompare="{{ catalogList.length }}"
        (keyup)="onKeyUp($event)"
        (keydown)="onKeyDown($event)"
      ></app-button-compare>
    </div>
  </div>
</app-card>

<app-card header="{{ header }}" [isLoading]="model.isLoading">
  <div
    class="d-block text-center"
    *ngIf="model.page.records && model.page.records.length === 0"
  >
    {{ 'app.info.noData' | translate }}
  </div>
  <div
    class="table-catalog"
    [ngClass]="model.viewType === 'GRID' ? 'grid' : ''"
  >
    <div
      class="catalog-item"
      *ngFor="let record of model.page.records"
      [ngClass]="model.viewType === 'GRID' ? 'text-center' : ''"
    >
      <div class="catalog-item-image">
        <app-catalog-item-carousel [imageList]="record.fileImageList">
        </app-catalog-item-carousel>
      </div>
      <ng-container *ngIf="model.viewType === 'LIST'">
        <div class="catalog-item-details">
          <h5
            class="catalog-item-title cursor-pointer"
            (click)="onChooseItem.emit(record.id)"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
            >{{ record.name }}</h5
          >
          <p class="catalog-item-price">{{
            (record.price | currency: '':'') +
              '
            ' +
              record.currency.code
          }}</p>
          <p class="catalog-item-vendor"
            ><em class="fas fa-building mr-2"></em>{{ record.vendor.name }}</p
          >
          <span class="item-vendor-location"
            ><em class="fas fa-map-marker mr-2"></em
            >{{ record.regionName }}</span
          >
          <app-catalog-item-categories [nameList]="record.categoryNameList">
          </app-catalog-item-categories>
        </div>
        <div class="catalog-item-compare">
          <app-button-compare
            tooltip="app-table-catalog.compare"
            (click)="doAddToComparisonList(record)"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
          >
            {{ 'app-table-catalog.compare' | translate }}</app-button-compare
          >
        </div>
      </ng-container>

      <ng-container *ngIf="model.viewType === 'GRID'">
        <div class="catalog-item-details text-center">
          <div class="left text-right">
            <p
              class="catalog-item-title cursor-pointer"
              (click)="onChooseItem.emit(record.id)"
              (keyup)="onKeyUp($event)"
              (keydown)="onKeyDown($event)"
              >{{ record.name }}</p
            >
            <p class="catalog-item-vendor"
              ><em class="fas fa-building mr-2"></em>{{ record.vendor.name }}</p
            >
          </div>
          <div class="right text-left">
            <p class="catalog-item-price">{{
              (record.price | currency: '':'') + ' ' + record.currency.code
            }}</p>
            <span class="item-vendor-location"
              ><em class="fas fa-map-marker mr-2"></em
              >{{ record.regionName }}</span
            >
          </div>
        </div>
        <div class="catalog-item-footer d-flex justify-content-between">
          <app-catalog-item-categories [nameList]="record.categoryNameList">
          </app-catalog-item-categories>
          <app-button-compare
            tooltip="app-table-catalog.compare"
            (click)="doAddToComparisonList(record)"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
          ></app-button-compare>
        </div>
      </ng-container>
    </div>
  </div>
  <app-pagination
    [model]="model.pagination"
    (onChange)="model.setCurrentPage($event)"
    *ngIf="model.page.records && model.page.records.length > 0"
  >
  </app-pagination>
</app-card>
