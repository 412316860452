import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppMonthPickerComponent } from './app-month-picker.component';
@NgModule({
  imports: [VendorModule, AppTooltipWrapperModule],
  declarations: [AppMonthPickerComponent],
  exports: [AppMonthPickerComponent]
})
export class AppMonthPickerModule {}
