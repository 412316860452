<div class="row">
  <div class="col-lg-12 mb-4">
    <div class="text-center py-4" *ngIf="formLoading">
      <img src="assets/img/common/loader-small.gif" alt="Loading" />
    </div>
    <form
      class="form-horizontal"
      [formGroup]="tabSegmentation.formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <div class="form-group row" [ngClass]="!isView ? 'required' : ''">
        <div class="col-lg-12">
          <h5 class="separator"
            >{{
              'tab-profile-segmentation.form-group.segmentation' | translate
            }}
            <span class="control-label"></span
          ></h5>
          <small
            class="text-danger"
            *ngIf="
              tabSegmentation.formGroup.value.isTableEmpty &&
              tabSegmentation.formGroup.value.changeTab
            "
          >
            {{ 'app.validation.required' | translate }}
          </small>
          <app-table #selectorVendorSegmentation [model]="tableResponse">
            <ng-template #headerButtons>
              <ng-container *ngIf="!isView">
                <app-button (onClick)="doAdd()"
                  ><em class="fas fa-plus"></em
                  >{{ 'app.button.add' | translate }}</app-button
                >
              </ng-container>
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <ng-container *ngIf="!isView">
                <app-button
                  size="TN"
                  (onClick)="doEdit(rowData)"
                  title="{{ 'app.tooltip.edit' | translate }}"
                >
                  <span class="fas fa-pencil-alt"></span>
                </app-button>
                <app-button
                  size="TN"
                  color="DANGER"
                  (onClick)="doDelete(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <span class="fas fa-trash"></span>
                </app-button>
              </ng-container>
              <ng-container *ngIf="isView">
                <app-button
                  size="TN"
                  (onClick)="doView(rowData)"
                  title="{{ 'app.tooltip.detail' | translate }}"
                >
                  <span class="fas fa-search"></span>
                </app-button>
              </ng-container>
            </ng-template>
          </app-table>
          <label *ngIf="!isView"
            >*)
            {{
              'tab-profile-segmentation.validation.segmentation' | translate
            }}
            {{ tabProfileSegmentationDTO.maxSegmentation }}
            {{
              'tab-profile-segmentation.validation.segmentationInfo' | translate
            }}</label
          >
        </div>
      </div>
    </form>
  </div>
</div>
