import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from '../../../core/base/angular/base-component.component';
import { Organization } from '../../../core/bean/organization';
import { Vendor } from '../../../core/bean/vendor';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { Validators } from '../../../core/validators';
import { OnBoardingDelegationRequest } from '../../on-boarding/request/on-boarding-delegation.request';

@Component({
  selector: 'app-popup-delegation',
  templateUrl: './app-popup-delegation.component.html'
})
export class AppPopupDelegationComponent extends BaseComponentComponent {
  @Input() public vendor: Vendor;
  @Input() public onBoardingByOrganizationList: Organization[];
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {
    super('tab-profile-verification');
  }

  onInit(): void {
    this.buildFormGroup();
    this.getAndSetToFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      organizationList: [null, Validators.required()]
    });
  }

  public getAndSetToFormGroup(): void {
    if (this.onBoardingByOrganizationList) {
      this.formGroup.patchValue({
        organizationList: this.onBoardingByOrganizationList
      });
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const onBoardingDelegationRequest: OnBoardingDelegationRequest =
              new OnBoardingDelegationRequest();
            const lastIndexOfOrganization: number =
              this.formGroup.value.organizationList.length - 1;

            onBoardingDelegationRequest.vendor = this.vendor;
            onBoardingDelegationRequest.onBoardingByOrganization =
              this.formGroup.value.organizationList[lastIndexOfOrganization];

            this.httpClientService
              .post<Response<Vendor>>(
                '/on-boarding/delegate',
                onBoardingDelegationRequest
              )
              .subscribe((response: Response<Vendor>) => {
                if (response.status === ResponseStatusModel.OK) {
                  this.activeModal.dismiss();
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/on-boarding']);
                } else {
                  this.global.alertService.showError(
                    response.statusText,
                    '.main-row-delegation'
                  );
                  this.setStateReady();
                }
              });
          }
        });
    }
  }
}
