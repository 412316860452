import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { VictimDiagnose } from 'src/app/core/bean/victim-diagnose';
import { VictimInjuries } from 'src/app/core/bean/victim-injuries';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { VictimDiagnoseResponse } from './victim-diagnose.response';
import { VictimDiagnoseRequest } from './victim-diagnose.request';
@Component({
  templateUrl: './victim-diagnose-edit-add.component.html'
})
export class VictimDiagnoseEditAddComponent extends BaseModuleComponent {
  public victimDiagnoseId: number;
  public victimInjuriesOptionList: OptionListModel<VictimInjuries> =
    new OptionListModel(false);
  public victimDiagnoseResponse: VictimDiagnoseResponse;
  public victimDiagnose: VictimDiagnose;
  constructor(translateService: TranslateService) {
    super('victim-diagnose', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.victimDiagnoseId = this.global.routerParams.get('victimDiagnoseId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required()])
      ],
      name: [
        null,
        Validators.compose([Validators.required()])
      ],
      codingIcdX: [
        null,
        Validators.compose([Validators.required()])
      ],
      actionName: [null],
      actionCodingIcdX: [null],
      averageDurationAction: [null],
      possibleComplications: [null],
      medicalEquipmentNeeds: [null],
      medicationNeeds: [null],
      note: [null],
      victimInjuriesList: [null, Validators.compose([Validators.required()])],
      information: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<VictimDiagnoseResponse>(
        '/victim-diagnose/add-edit',
        new RouteRequestModel(this.todo, this.victimDiagnoseId)
      )
      .subscribe(response => {
        this.victimDiagnoseResponse = response;
        this.victimInjuriesOptionList.setRequestValues(
          this.victimDiagnoseResponse.victimInjuriesCategoryList
        );
        if (this.victimDiagnoseResponse.victimDiagnose != null) {
          this.victimDiagnose = this.victimDiagnoseResponse.victimDiagnose;
          const victimInjuriesCategoryList = this.victimDiagnoseResponse.victimDiagnoseInjuriesCategoryList?.map(
            victimDiagnoseInjuriesCategory => victimDiagnoseInjuriesCategory.victimInjuriesCategory
          );
          this.formGroup.patchValue({
            id: this.victimDiagnose.id,
            code: this.victimDiagnose.code,
            name: this.victimDiagnose.name,
            codingIcdX: this.victimDiagnose.codingIcdX,
            actionName: this.victimDiagnose.actionName,
            actionCodingIcdX: this.victimDiagnose.actionCodingIcdX,
            averageDurationAction: this.victimDiagnose.averageDurationAction,
            possibleComplications: this.victimDiagnose.possibleComplications,
            medicalEquipmentNeeds: this.victimDiagnose.medicalEquipmentNeeds,
            medicationNeeds: this.victimDiagnose.medicationNeeds,
            note: this.victimDiagnose.note,
            victimInjuriesList: victimInjuriesCategoryList,
            information: this.victimDiagnose.information
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            const victimDiagnoseRequest: VictimDiagnoseRequest = this.formGroup.value;

            const url =
              this.todo === 'edit' ? '/victim-diagnose/update' : '/victim-diagnose/insert';
            this.httpClientService
              .post<Response<VictimDiagnose>>(url, victimDiagnoseRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/victim-diagnose/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/victim-diagnose/']);
  }
}
