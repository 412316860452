import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppItemRequestModule } from 'src/app/core/components/app-item-request/app-item-request.module';
import { AppProcurementInfoModule } from 'src/app/core/components/app-procurement-info/app-procurement-info.module';
import { AppWorkflowStepInfoModule } from 'src/app/core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupProcurementScheduleChangesComponent } from './app-popup-procurement-schedule-changes.component';
import { AppPopupScheduleHistoryDtlComponent } from './app-popup-schedule-history-dtl.component';
import { ProcurementScheduleEditComponent } from './procurement-schedule-edit.component';
import { ProcurementScheduleComponent } from './procurement-schedule.component';
const routes: Routes = [
  {
    path: '',
    component: ProcurementScheduleComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'add',
    component: ProcurementScheduleEditComponent,
    data: { breadcrumb: 'procurement-schedule.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: ProcurementScheduleEditComponent,
    data: { breadcrumb: 'procurement-schedule.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: ProcurementScheduleEditComponent,
    data: { breadcrumb: 'procurement-schedule.breadcrumb.detail' }
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppProcurementInfoModule,
    AppItemRequestModule,
    AppWorkflowStepInfoModule,
    AppApprovalPrcsXModule,
    AppBadgeCatalogModule
  ],
  declarations: [
    ProcurementScheduleComponent,
    ProcurementScheduleEditComponent,
    AppPopupProcurementScheduleChangesComponent,
    AppPopupScheduleHistoryDtlComponent
  ],
  entryComponents: [
    AppPopupProcurementScheduleChangesComponent,
    AppPopupScheduleHistoryDtlComponent
  ],
  exports: [
    AppPopupProcurementScheduleChangesComponent,
    AppPopupScheduleHistoryDtlComponent
  ]
})
export class ProcurementScheduleModule {}
