import { Region } from '../bean/region';
import { Resource } from '../bean/resource';
import { FileObject } from '../components/upload';
import { BaseTreeEntity } from './../base/base-tree-entity';
export class RegionEntity extends BaseTreeEntity {
    childOrganizationList: Region[];
    regionNameAndPhoneNumber: string;
    fileObjectList: FileObject[];

    resource: Resource;
    callingCode: string;
    postcode: string;
}
