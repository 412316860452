import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { CatalogAttribute } from '../../core/bean/catalog-attribute';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  selector: 'app-popup-catalog-attribute',
  templateUrl: './app-popup-catalog-attribute.component.html'
})
export class AppPopupCatalogAttributeComponent extends BaseComponentComponent {
  @Input() public catalogAttributeIdList: number[];
  @Input() public catalogAttributeIdCustomData: any;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public tableResponse: TableResponseModel<CatalogAttribute>;
  public dataRecord: any[];
  constructor() {
    super('catalog-attribute-group');
  }

  onInit(): void {
    this.setAttributeIdCustomData();
    this.buildTableResponse();
  }

  public setAttributeIdCustomData(): void {
    this.catalogAttributeIdCustomData = JSON.stringify(
      this.catalogAttributeIdList
    );
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: '0.code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      { field: '1', header: 'table.column.name' }, // fieldName, result of jointable
      {
        field: '0.formObject.name',
        header: 'table.column.objectType',
        customClass: 'text-center'
      },
      { field: '0.description', header: 'table.column.description' },
      {
        field: '0.id',
        header: 'table.column.select',
        format: FieldFormatEnum.CheckBox,
        sortable: false,
        customClass: 'text-center'
      }
    ]);
  }

  public onChangeTableRecords(records: any): void {
    this.dataRecord = records;
  }

  public doSubmit(): void {
    if (!(this.dataRecord && this.dataRecord.length > 0)) {
      this.global.alertService.showError(
        this.global.translateService.instant('app.validation.chooseOneData'),
        '.main-row-popup-catalog-attribute'
      );
    } else {
      const dataRecord = [];
      this.dataRecord.forEach(record => {
        const data = record[0];
        dataRecord.push(data);
      });
      this.onChange.emit(dataRecord);
    }
  }
}
