import { ScoringTemplate } from 'src/app/core/bean/scoring-template';
import { Vendor } from 'src/app/core/bean/vendor';
import { VendorPerformance } from 'src/app/core/bean/vendor-performance';
import { VendorPerformanceScore } from 'src/app/core/bean/vendor-performance-score';
import { VendorPerformanceSummary } from 'src/app/core/bean/vendor-performance-summary';
import { FileObject } from 'src/app/core/components/upload';

export class PerformanceFulfillmentInsertRequest {
  vendor: Vendor = new Vendor();
  performance: VendorPerformance = new VendorPerformance();
  scoringTemplate: ScoringTemplate = new ScoringTemplate();
  filePerformance: FileObject[] = [];
  performanceSummaryList: VendorPerformanceSummary[] = [];
  performanceScoreList: VendorPerformanceScore[] = [];
  sowPaymentTermId: number;
}
