import { BaseEntity } from '../base/base-entity';
import { Education } from '../bean/education';
import { File } from '../bean/file';
import { Profession } from '../bean/profession';
import { Vendor } from '../bean/vendor';
import { VendorExpertiseCertificate } from '../bean/vendor-expertise-certificate';

export class VendorExpertiseEntity extends BaseEntity {
    vendorExpertiseCertificateList: VendorExpertiseCertificate[];

    profession: Profession;
    vendor: Vendor;
    education: Education;
    file: File;
    name: string;
    birthDate: Date;
    experience: string;
    startDate: Date;
    endDate: Date;
    startYear: number;
    endYear: number;
    institution: string;
}
