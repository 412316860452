<div class="row main-row app-accordion-row">
  <div class="col-12">
    <app-card
      header="contract-monitoring.title"
      detail="contract-monitoring.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-accordion [isLoading]="formLoading">
        <app-accordion-item
          header="contract-monitoring.accordion.contractDocument"
          [isExpanded]="true"
        >
          <div class="col-12 text-right mb-3" *ngIf="!contractFileHistory">
            <app-button size="SM" color="PRIMARY" (onClick)="doAddDocument()">
              <em class="fas fa-upload"></em>
              {{ 'app.button.upload' | translate }}
            </app-button>
          </div>
          <div class="col-12 text-right mb-3" *ngIf="contractFileHistory">
            <app-button size="SM" color="PRIMARY" (onClick)="doAddDocument()">
              <em class="fas fa-upload"></em>
              {{ 'app.button.update' | translate }}
            </app-button>
          </div>
          <div class="text-center py-4" *ngIf="formLoading || isLoading">
            <img src="assets/img/common/loader-small.gif" alt="Loading" />
          </div>
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
            *ngIf="contractFileHistory && !formLoading && !isLoading"
          >
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'contract-monitoring.form.file' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-upload
                  formControlName="contractFile"
                  [fileUploader]="fileUploader"
                >
                </app-upload>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'contract-monitoring.form.description' | translate
              }}</label>
              <div class="col-sm-6 text-left">
                <app-text-area formControlName="description"> </app-text-area>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'contract-monitoring.form.uploadBy' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field formControlName="uploadBy"> </app-text-field>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'contract-monitoring.form.uploadDate' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{
                  formGroup.controls.uploadDate.value
                    | date: global.config.parameterModel.datetimeFormatShort
                }}</div
              >
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'contract-monitoring.form.version' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field formControlName="version"> </app-text-field>
              </div>
            </div>
          </form>
        </app-accordion-item>
        <app-accordion-item
          header="contract-monitoring.accordion.procurementInformation"
        >
          <app-procurement-information-view
            [procurementVendorId]="contract.procurementVendor.id"
          >
          </app-procurement-information-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-monitoring.accordion.requestInformation"
        >
          <app-contract-request-view
            [contractId]="contractId"
            [tableResponse]="tableResponse"
          >
          </app-contract-request-view>
        </app-accordion-item>
        <app-accordion-item
          header="contract-monitoring.accordion.contractInformation"
        >
          <form class="form-horizontal" *ngIf="!formLoading">
            <h5 class="separator">{{
              'contract-monitoring.contractData' | translate
            }}</h5>
            <div
              class="col-12 text-right mb-3"
              *ngIf="
                contract.contractStatus.code ===
                  global.appConstant.cm.CONTRACT_STATUS_ON_PROGRESS &&
                (global.userSession.activeUserRole.role.code ===
                  global.appConstant.ROLE_CODE_USER_PPA ||
                  global.userSession.activeUserRole.role.code ===
                    global.appConstant.ROLE_CODE_SUPER_ADMIN) &&
                contractMonitoringResponse.isStatusNew
              "
            >
              <app-button
                size="SM"
                color="PRIMARY"
                (onClick)="doEditContract()"
              >
                <em class="fas fa-pencil-alt"></em>
                {{ 'app.button.update' | translate }}
              </app-button>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'contract-monitoring.form.contractDocument' | translate
              }}</label>
              <div class="col">
                <div class="col-12 text-left p-0">
                  <a
                    href="#"
                    (click)="
                      doDownload(
                        $event,
                        '/contract-negotiation/file/view/' +
                          contractMonitoringResponse.contractFileHistoryNego
                            .file.uploadedFileName +
                          '/' +
                          contractMonitoringResponse.contractFileHistoryNego
                            .file.fileType.code,
                        contractMonitoringResponse.contractFileHistoryNego.file
                          .fileName
                      )
                    "
                  >
                    {{
                      contractMonitoringResponse.contractFileHistoryNego.file
                        .fileName
                        ? contractMonitoringResponse.contractFileHistoryNego
                            .file.fileName
                        : '-'
                    }}
                  </a>
                </div>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'contract-monitoring.form.contractNumber' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{ contract.number ? contract.number : '-' }}
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'contract-monitoring.form.effectiveDate' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{ 'contract-monitoring.form.from' | translate }}
                {{
                  contract.startDate
                    | date: global.config.parameterModel.dateFormatShort
                }}
                {{ 'contract-monitoring.form.until' | translate }}
                {{
                  contract.endDate
                    | date: global.config.parameterModel.dateFormatShort
                }}
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'contract-monitoring.form.accountNumber' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{
                  contractMonitoringResponse.vendorBank.accountNumber
                    ? contractMonitoringResponse.vendorBank.accountNumber
                    : '-'
                }}
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'contract-monitoring.form.bankName' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{
                  contractMonitoringResponse.vendorBank.bank.name
                    ? contractMonitoringResponse.vendorBank.bank.name
                    : '-'
                }}
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'contract-monitoring.form.accountHolder' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{
                  contractMonitoringResponse.vendorBank.accountHolder
                    ? contractMonitoringResponse.vendorBank.accountHolder
                    : '-'
                }}
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'contract-monitoring.form.branchName' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                {{
                  contractMonitoringResponse.vendorBank.branch
                    ? contractMonitoringResponse.vendorBank.branch
                    : '-'
                }}
              </div>
            </div>
          </form>
        </app-accordion-item>
        <app-accordion-item
          header="contract-monitoring.accordion.vendorInformation"
        >
          <app-vendor-payment-information
            [procurementVendorId]="contract.procurementVendor.id"
            [todo]="todo"
          ></app-vendor-payment-information>
        </app-accordion-item>
        <app-accordion-item header="contract-monitoring.accordion.changeLog">
          <app-table
            [model]="tableResponse"
            stringUrl="/contract-monitoring/contract-change-history-list"
          >
          </app-table>
        </app-accordion-item>
      </app-accordion>
      <div class="floating-button mt-5">
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button
                mode="BYPASS"
                color="SECONDARY"
                (onClick)="doCancel()"
                class="mr-1"
                [disabled]="formSaving"
                >{{ 'app.button.back' | translate }}
              </app-button>
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
