import { BaseEntity } from '../base/base-entity';
import { ContractItem } from '../bean/contract-item';
import { PurchaseOrderItem } from '../bean/purchase-order-item';
import { PurchaseRequestItem } from '../bean/purchase-request-item';
import { WorkPlanRealize } from '../bean/work-plan-realize';
export class PurchaseRequestEntity extends BaseEntity {
    vendor: string;
    contractItemList: ContractItem[];
    purchaseOrderItemList: PurchaseOrderItem[];
    purchaseRequestItemList: PurchaseRequestItem[];
    workPlanRealize: WorkPlanRealize;
    name: string;
    number: string;
    createdDate: Date;
    description: string;
    isDisabled: boolean;
}
