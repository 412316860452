import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Procurement } from 'src/app/core/bean/procurement';
import { ProcurementSchedule } from 'src/app/core/bean/procurement-schedule';
import { ScheduleHistory } from 'src/app/core/bean/schedule-history';
import { ScheduleStatus } from 'src/app/core/bean/schedule-status';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { CalendarResponseModel } from 'src/app/core/components/calendar/model/calendar-response-model';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { SnackbarService } from 'src/app/core/services/snackbar.services';
import { AppPopupProcurementScheduleChangesComponent } from './app-popup-procurement-schedule-changes.component';
import { AppPopupScheduleHistoryDtlComponent } from './app-popup-schedule-history-dtl.component';
import { ProcurementScheduleRequest } from './procurement-schedule.request';
import { ProcurementScheduleResponse } from './procurement-schedule.response';

@Component({
  templateUrl: './procurement-schedule-edit.component.html'
})
export class ProcurementScheduleEditComponent extends BaseModuleComponent {
  public procurementId: number;
  public objectId: number;
  public approvalModelPrcsId: number;
  public urlBackOutside: string;
  public procurementScheduleResponse: ProcurementScheduleResponse =
    new ProcurementScheduleResponse();
  public procurement: Procurement = new Procurement();
  public calendarResponseModel: CalendarResponseModel<ProcurementSchedule>;
  public scheduleStatus: ScheduleStatus = new ScheduleStatus();
  public currentDate = new Date();
  public startDate: Date;
  public tableResponseChangeHistory: TableResponseModel<ScheduleHistory>;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public snackbarService: SnackbarService,
    public toastService: ToastService
  ) {
    super('procurement-schedule', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setIsViewOnlyFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.procurementId = this.global.routerParams.get('procurementId');
    this.objectId = this.global.routerParams.get('objectId');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.scheduleStatus = this.global.routerParams.get('scheduleStatus');
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ProcurementScheduleResponse>(
        '/procurement-schedule/edit',
        new RouteRequestModel(this.todo, this.procurementId)
      )
      .subscribe((procurementScheduleResponse: ProcurementScheduleResponse) => {
        this.procurementScheduleResponse = procurementScheduleResponse;
        this.procurement = procurementScheduleResponse.procurement;
        this.setStateReady();
        this.buildCalenderResponse();
        this.calendarResponseModel.setRecordList(
          this.procurementScheduleResponse.procurementScheduleList
        );
        this.scheduleStatus = this.scheduleStatus
          ? this.scheduleStatus
          : this.procurement.scheduleStatus;

        this.startDate = new Date(
          this.calendarResponseModel.recordList[0].startDate
        );
        this.tableResponseChangeHistory.setCustomData(
          this.procurementId as any
        );
      });

    this.formGroup.markAsDirty();
  }

  public buildCalenderResponse(): void {
    this.calendarResponseModel = new CalendarResponseModel(
      this.moduleCode,
      {
        sidebarField: 'module.name',
        startDateField: 'startDateTemp',
        endDateField: 'endDateTemp'
      },
      this.procurementId
    );
  }

  public buildTableResponse(): void {
    this.tableResponseChangeHistory = new TableResponseModel(this.moduleCode, [
      {
        field: 'processedByUser.name',
        header: 'table.column.changedBy',
        plugins: {
          name: 'hyperlink',
          onClick: this.onClickChangeHistory.bind(this)
        }
      },
      {
        field: 'processedDate',
        header: 'table.column.changesDate',
        plugins: { name: 'date', format: 'short-date-and-time' }
      },
      {
        field: 'reason',
        header: 'table.column.reason'
      }
    ]);
  }

  public onClickChangeHistory(scheduleHistory: ScheduleHistory) {
    const scheduleHistoryId = scheduleHistory.id;
    this.appPopupService
      .open(
        AppPopupScheduleHistoryDtlComponent,
        { scheduleHistoryId },
        { size: 'xl' }
      )
      .subscribe();
  }

  public doSave(): void {
    if (this.calendarResponseModel.recordList[0].startDateTemp) {
      this.setStateProcessing();
      this.getProcessedData(false, null);
    }
  }

  public doSubmit(): void {
    if (
      this.scheduleStatus.code ===
        this.global.appConstant.pm.SCHEDULE_STATUS_DONE ||
      this.scheduleStatus.code ===
        this.global.appConstant.pm.SCHEDULE_STATUS_REVISION
    ) {
      this.appPopupService
        .open(AppPopupProcurementScheduleChangesComponent)
        .subscribe(reason => {
          if (reason) {
            this.setStateProcessing();
            this.getProcessedData(true, reason);
          }
        });
    } else if (this.calendarResponseModel.recordList[0].startDateTemp) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(results => {
          if (results) {
            this.setStateProcessing();
            this.getProcessedData(true, null);
          }
        });
    }
  }

  public getProcessedData(isSubmit: boolean, reason: string): void {
    const procurementScheduleRequest = new ProcurementScheduleRequest();
    procurementScheduleRequest.reason = reason;
    procurementScheduleRequest.isSubmit = isSubmit;
    procurementScheduleRequest.procurement =
      this.procurementScheduleResponse.procurement;
    procurementScheduleRequest.procurementScheduleList =
      this.calendarResponseModel.getRecordList();

    this.calendarResponseModel.getRecordList().forEach(record => {
      if (
        record.id &&
        this.scheduleStatus.code !==
          this.global.appConstant.pm.SCHEDULE_STATUS_DRAFT &&
        record['isModified']
      ) {
        procurementScheduleRequest.modifiedModuleIdList.push(record.module.id);
      }
    });
    procurementScheduleRequest.procurementScheduleList.forEach(
      (procurementSchedule: ProcurementSchedule) => {
        if (procurementSchedule.id !== null) {
          procurementSchedule.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_UPDATE;
        } else {
          procurementSchedule.crudOperation =
            this.global.appConstant.core.CRUD_OPERATION_INSERT;
        }
      }
    );

    this.httpClientService
      .post<Response<Procurement>>(
        '/procurement-schedule/update',
        procurementScheduleRequest
      )
      .subscribe(response => {
        if (isSubmit) {
          if (response.status === ResponseStatusModel.OK) {
            this.global.modalService
              .submitSuccess()
              .pipe(take(1))
              .subscribe(result => {
                this.formGroup.reset();
                if (result) {
                  this.router.navigate([this.urlBackOutside]);
                } else {
                  this.router
                    .navigateByUrl('/', { skipLocationChange: true })
                    .then(() => {
                      this.global.routerParams.clear();
                      this.global.routerParams.set(
                        'procurementId',
                        this.procurementId
                      );
                      this.global.routerParams.set('todo', 'view');
                      this.router.navigate([
                        '/pages/procurement-schedule/edit'
                      ]);
                    });
                }
              });
          } else {
            this.toastService.showError(response.statusText);
          }
        } else {
          if (response.status === ResponseStatusModel.OK) {
            this.formGroup.reset();
            this.snackbarService.showWarning('app.msg.info.sucsessSave');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set('procurementId', response.body.id);
                this.global.routerParams.set(
                  'urlBackOutside',
                  '/pages/procurement-schedule'
                );
                this.global.routerParams.set('todo', 'edit');
                this.router.navigate(['/pages/procurement-schedule/edit']);
              });
          } else {
            this.toastService.showError(response.statusText);
          }
        }
      });
  }
}
