import { NgModule } from '@angular/core';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetTotalProcurementWinComponent } from './widget-total-procurement-win.component';

@NgModule({
  imports: [VendorModule, WidgetCardModule],
  declarations: [WidgetTotalProcurementWinComponent],
  entryComponents: [WidgetTotalProcurementWinComponent],
  exports: [WidgetTotalProcurementWinComponent]
})
export class WidgetTotalProcurementWinModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetTotalProcurementWinComponent.moduleCode,
      WidgetTotalProcurementWinComponent,
      'w-25'
    );
  }
}
