import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { SchedulerLogComponent } from './scheduler-log.component';
const routes: Routes = [
  { path: '', component: SchedulerLogComponent, data: { breadcrumb: '' } }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [SchedulerLogComponent]
})
export class SchedulerLogModule {}
