<div class="border" *ngIf="!formLoading">
  <app-widget [header]="moduleCode + '.title'">
    <p *ngIf="adminOperationWorklistList == null">{{
      'app-widget-admin-opr-worklist.noData' | translate }}</p>
    <app-widget-content-slider [isShowControl]="adminOperationWorklistList.length > 5"
      *ngIf="adminOperationWorklistList != null">
      <app-widget-card *ngFor="let adminOprWorklist of adminOperationWorklistList"
        [header]="adminOprWorklist.vendorActivityStatusName">
        <span class="h3 m-0">{{ adminOprWorklist.numberOfVendor }}</span>
        <div class="widget-card-footer mt-2">
          <p class="m-0 cursor-pointer more-detail"
            (click)="goToMoreDetail(adminOprWorklist.targetUrl, adminOprWorklist.vendorActivityStatusCode)"
            (keyup)="onKeyUp($event)" (keydown)="onKeyDown($event)">
            {{ 'app-widget-admin-opr-worklist.moreDetail' | translate }}
            <!-- <em class="fas fa-chevron-circle-right"></em> -->
          </p>
        </div>
      </app-widget-card>
    </app-widget-content-slider>
  </app-widget>
</div>