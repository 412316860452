import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { RoleDashboardWidget } from '../../bean/role-dashboard-widget';
import { AppTableComponent } from '../../components/app-table/app-table.component';
import { TableResponseModel } from '../../components/app-table/model/table-response-model';
import { WidgetItemModel } from '../model/widget-item-model';
import { WidgetService } from '../widgets.service';
@Component({
  templateUrl: './app-popup-widget-wrapper.component.html'
})
export class AppPopupWidgetWrapperComponent extends BaseComponentComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<RoleDashboardWidget>;
  @Output() onChange: EventEmitter<WidgetItemModel> = new EventEmitter();
  constructor(public widgetService: WidgetService) {
    super('app-widget-login-information');
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setTableResponse();
  }

  public buildTableResponse(): void {
    console.log('Info: Come from buildTableResponse');
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'widget.module.name', header: 'name' }
    ]);
  }

  public setTableResponse(): void {
    this.httpClientService
      .get('/dashboard/load-my-available-dashboard')
      .subscribe((roleDashboardWidget: RoleDashboardWidget[]) => {
        console.log('Info: Come from setTableResponse');
        console.log(roleDashboardWidget);
        this.tableResponse.page.records = roleDashboardWidget;
        this.table.reloadClient();
        this.setStateReady();
      });
  }

  public handleChoose(widget: WidgetItemModel): void {
    this.onChange.emit(widget);
  }
}
