import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AccountSettingComponent } from './account-setting.component';
import { AppPopupChangeEmailModule } from './app-popup-change-email/app-popup-change-email.module';
import { AppUserProfileCardModule } from './app-user-profile-card/app-user-profile-card.module';
export const routes = [
  {
    path: '',
    component: AccountSettingComponent,
    data: { breadcrumb: 'account-setting.breadcrumb' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    AppPopupChangeEmailModule,
    AppUserProfileCardModule
  ],
  declarations: [AccountSettingComponent],
  providers: []
})
export class AccountSettingModule {}
