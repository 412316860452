import { BaseDTO } from '../../../core/base/base-dto';
import { Period } from '../../../core/bean/period';
import { ScoringCriteria } from '../../../core/bean/scoring-criteria';
import { ScoringCriteriaGroup } from '../../../core/bean/scoring-criteria-group';
import { ScoringCriteriaRange } from '../../../core/bean/scoring-criteria-range';
import { ScoringGroupTemplate } from '../../../core/bean/scoring-group-template';
import { ScoringTemplate } from '../../../core/bean/scoring-template';
import { ScoringTemplateWeight } from '../../../core/bean/scoring-template-weight';
import { Vendor } from '../../../core/bean/vendor';
import { VendorAddress } from '../../../core/bean/vendor-address';
import { VendorPerformance } from '../../../core/bean/vendor-performance';
import { VendorPerformanceOverallDetail } from '../../../core/bean/vendor-performance-overall-detail';
import { VendorPerformanceScore } from '../../../core/bean/vendor-performance-score';
import { VendorPerformanceSummary } from '../../../core/bean/vendor-performance-summary';

export class AllPerformanceDTO extends BaseDTO {

    vendorPerformanceOverallDetailList: VendorPerformanceOverallDetail[] = [];
    vendorName: string;
    vendorCode: string;
    period: Period = new Period();
    year: number;
    vendorList: Vendor[] = [];

    vendor: Vendor = new Vendor();
    vendorAddress: VendorAddress = new VendorAddress();
    performance: VendorPerformance = new VendorPerformance();
    scoringTemplate: ScoringTemplate = new ScoringTemplate();
    scoringCriteriaList: ScoringCriteria[] = [];
    performanceSummaryList: VendorPerformanceSummary[] = [];
    performanceScoreList: VendorPerformanceScore[] = [];
    performanceScoreCommonList: VendorPerformanceScore[] = [];
    performanceScoreSpecificList: VendorPerformanceScore[] = [];
    scoringCriteriaGroupList: ScoringCriteriaGroup[];
    scoringCriteriaGroupCommonList: ScoringCriteriaGroup[];
    scoringCriteriaGroupSpesificList: ScoringCriteriaGroup[];
    scoringGroupTemplateList: ScoringGroupTemplate[] = [];
    scoringTemplateWeightList: ScoringTemplateWeight[] = [];
    scoringCriteriaRangeList: ScoringCriteriaRange[] = [];

    agreementNumber: string;
    agreementType: string;
    termin: string;
}
