import { BaseEntity } from '../base/base-entity';
import { ItemCategory } from '../bean/item-category';
import { VendorHistory } from '../bean/vendor-history';

export class VendorProductServiceCategoryHistoryEntity extends BaseEntity {
    crudInfo: string;

    vendorHistory: VendorHistory;
    itemCategory: ItemCategory;
    createdDate: Date;
    vendorProductCategoryId: number;
}
