<div class="app-actual-plan-bar-chart">
  <app-card>
    <h4 class="m-0">
      {{
        (model.type === 'budget-info'
          ? 'app-actual-plan-bar-chart-budget-info.actualBudget'
          : 'app-actual-plan-bar-chart-work-plan-info.actualAndWorkPlan'
        ) | translate
      }}
    </h4>
    <div class="bar-chart-budget-info-filter">
      <input
        type="checkbox"
        name="showFilterWrapper"
        id="showFilterWrapper"
        #checkBoxElement
      />
      <label for="showFilterWrapper" (click)="doShowFilterWrapper($event)">
        <em class="fas fa-ellipsis-v"></em>
      </label>
      <div
        class="bar-chart-budget-filter-wrapper"
        (click)="onClickBudgetFilterWrapper($event)"
      >
        <ul class="filter">
          <li class="filter-item-group">
            <span class="filter-item-text">
              {{
                ('app-actual-plan-bar-chart-info.filter.view'
                ) | translate
              }}
              </span>
            <ul class="filter-child">
              <li
                class="filter-item-child"
                [ngClass]="model.type === 'budget-info' ? 'active' : ''"
                (click)="handleClick('view', 'budget-info')"
              >
                {{
                  ('app-actual-plan-bar-chart-info.filter.budgetView'
                  ) | translate
                }}
              </li>
              <li
                class="filter-item-child"
                [ngClass]="model.type === 'work-plan-info' ? 'active' : ''"
                (click)="handleClick('view', 'work-plan-info')"
              >
                {{
                  ('app-actual-plan-bar-chart-info.filter.workPlanView'
                  ) | translate
                }}
              </li>
            </ul>
          </li>
          <li class="filter-item-group">
            <span class="filter-item-text">Type</span>
            <ul class="filter-child" *ngFor="let budgetType of budgetTypeList">
              <li
                class="filter-item-child"
                [ngClass]="
                  activeBudgetType.code === budgetType.code ? 'active' : ''
                "
                (click)="handleClick('type', budgetType)"
              >
                {{ budgetType.code }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <app-chart [model]="actualPlanBarChartStoreService.result"></app-chart>
  </app-card>
</div>
