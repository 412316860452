import { CurrencyPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { DeliveryItem } from 'src/app/core/bean/delivery-item';
import { DeliveryItemHistory } from 'src/app/core/bean/delivery-item-history';
import { ProcurementItem } from 'src/app/core/bean/procurement-item';
import { ProcurementResult } from 'src/app/core/bean/procurement-result';
import { ProcurementSow } from 'src/app/core/bean/procurement-sow';
import { ProcurementSowPaymentTerm } from 'src/app/core/bean/procurement-sow-payment-term';
import { ProcurementSowPaymentTermItem } from 'src/app/core/bean/procurement-sow-payment-term-item';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { FileUploader } from 'src/app/core/components/upload';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';
import { ProcurementScopeWork } from '../../core/bean/procurement-scope-work';
import { DeliveryMonitoringRequest } from './request/delivery-monitoring.request';

@Component({
  templateUrl: './app-popup-delivery-monitoring.component.html'
})
export class AppPopupDeliveryMonitoringComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() todo: string;
  @Input() procurementSowPaymentTerm: ProcurementSowPaymentTerm;
  @Input() procurementSow: ProcurementSow;
  @Input() procurementResult: ProcurementResult;
  @Input() procurementScopeWork: ProcurementScopeWork;
  @Input() procurementItemList: ProcurementItem[] = [];
  @Input() procurementResultList: ProcurementResult[] = [];
  @Input() procurementSowPaymentTermItem: ProcurementSowPaymentTermItem;
  @Output()
  public onChange: EventEmitter<DeliveryMonitoringRequest> = new EventEmitter();
  @ViewChild('selectorProduct') public tableProductHistory: AppTableComponent;
  @ViewChild('selectorService') public tableServiceHistory: AppTableComponent;
  public isAllUploaded = false;
  public deliveryMonitoringRequest: DeliveryMonitoringRequest =
    new DeliveryMonitoringRequest();
  public tableResponseProduct: TableResponseModel<DeliveryItemHistory>;
  public tableResponseService: TableResponseModel<DeliveryItemHistory>;
  public fileUploader: FileUploader = new FileUploader(
    '/delivery-monitoring/',
    '',
    'DOC_DELIVERY_ITEM',
    false,
    10
  );

  constructor(
    public translateService: TranslateService,
    public currency: CurrencyPipe
  ) {
    super('delivery-monitoring');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.doCheckTotal();
    this.setIsViewOnly();
    this.buildTableResponse();
    this.setStateReady();
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    if (
      this.procurementResult.procurementItem.item.itemType.code ===
      this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
    ) {
      this.formGroup = this.formBuilder.group({
        id: [null],
        passQuantity: [null, Validators.required()],
        rejectQuantity: [null, Validators.required()],
        receivedQuantity: [null, Validators.required()],
        receivedDate: [null, Validators.required()],
        performanceValue: [null, Validators.required()],
        uploadFile: [null],
        note: [null]
      });
    } else {
      this.formGroup = this.formBuilder.group({
        id: [null],
        passPercentageValue: [null, Validators.required()],
        performanceValue: [null, Validators.required()],
        uploadFile: [null],
        note: [null]
      });
    }
  }

  public setFormGroup(): void {
    if (this.procurementSowPaymentTermItem) {
      let lastPassTotalPerQuantity = null;
      let lastPassTotal = null;
      let quantity = null;
      if (
        this.procurementResult.procurementItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
      ) {
        lastPassTotalPerQuantity =
          this.procurementSowPaymentTermItem.receivedItemPerQuantity.split('/');
        lastPassTotal = lastPassTotalPerQuantity[0];
        quantity = lastPassTotalPerQuantity[1];
        this.formGroup.patchValue({
          passQuantity: +quantity - +lastPassTotal,
          receivedQuantity: +quantity - +lastPassTotal,
          rejectQuantity: 0
        });
        this.formGroup.patchValue({
          rejectQuantity:
            this.formGroup.get('receivedQuantity').value -
            this.formGroup.get('passQuantity').value
        });
      } else {
        lastPassTotal = +this.procurementSowPaymentTermItem.deliveredTotal;
        quantity = +this.procurementSowPaymentTermItem.quantity;
        this.formGroup.patchValue({
          passPercentageValue: +quantity - +lastPassTotal
        });
      }
    }
  }

  public buildTableResponse(): void {
    this.tableResponseProduct = new TableResponseModel(this.moduleCode, [
      {
        field: 'receivedDate',
        header: 'table.column.receivedDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'receivedQuantity',
        customClass: 'text-right',
        header: 'table.column.receivedTotal',
        fn: this.setUom.bind(this)
      },
      {
        field: 'passQuantity',
        customClass: 'text-right',
        header: 'table.column.passTotal',
        fn: this.setUom.bind(this)
      },
      {
        field: 'rejectQuantity',
        customClass: 'text-right',
        header: 'table.column.rejectTotal',
        fn: this.setUom.bind(this)
      },
      {
        field: 'performanceValue',
        header: 'table.column.deliveryPerformance',
        format: FieldFormatEnum.Rating
      },
      {
        field: 'note',
        header: 'table.column.note',
        customClass: 'white-space-normal'
      },
      {
        field: 'fileList',
        header: 'table.column.file',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName'
      },
      {
        field: 'userName',
        header: 'table.column.user'
      }
    ]);

    this.tableResponseService = new TableResponseModel(this.moduleCode, [
      {
        field: 'receivedDate',
        header: 'table.column.Date',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'passPercentageValue',
        customClass: 'text-right',
        header: 'table.column.deliverable',
        fn: this.setPercentage.bind(this)
      },
      {
        field: 'performanceValue',
        header: 'table.column.deliveryPerformance',
        format: FieldFormatEnum.Rating
      },
      {
        field: 'note',
        header: 'table.column.note',
        customClass: 'white-space-normal'
      },
      {
        field: 'fileList',
        header: 'table.column.file',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName'
      },
      {
        field: 'userName',
        header: 'table.column.user'
      }
    ]);

    this.tableResponseProduct.setCustomData(this.procurementSowPaymentTermItem);
    this.tableResponseService.setCustomData(this.procurementSowPaymentTermItem);
  }

  public doCheckTotal(): void {
    if (this.procurementResult.percentageValue === null) {
      this.procurementResult.percentageValue = '0';
    }
    if (
      this.procurementSowPaymentTermItem &&
      this.procurementSowPaymentTermItem.deliveredTotal === null
    ) {
      this.procurementSowPaymentTermItem.deliveredTotal = '0';
    }
  }

  public doCheckPassQuantity(deliveryItem): boolean {
    let lastPassTotalPerQuantity = null;
    let lastPassTotal = null;
    let quantity = null;
    if (
      this.procurementResult.procurementItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL &&
      this.procurementSowPaymentTermItem === null
    ) {
      lastPassTotalPerQuantity =
        this.procurementResult.receivedItemPerQuantity.split('/');
      lastPassTotal = lastPassTotalPerQuantity[0];
      quantity = lastPassTotalPerQuantity[1];
      if (+deliveryItem.passQuantity + +lastPassTotal > quantity) {
        return false;
      } else {
        return true;
      }
    } else if (
      this.procurementResult.procurementItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL &&
      this.procurementSowPaymentTermItem !== null
    ) {
      lastPassTotalPerQuantity =
        this.procurementSowPaymentTermItem.receivedItemPerQuantity.split('/');
      lastPassTotal = lastPassTotalPerQuantity[0];
      quantity = lastPassTotalPerQuantity[1];
      if (+deliveryItem.passQuantity + +lastPassTotal > quantity) {
        return false;
      } else {
        return true;
      }
    } else if (
      this.procurementResult.procurementItem.item.itemType.code !==
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL &&
      this.procurementSowPaymentTermItem === null
    ) {
      lastPassTotal = this.procurementResult.percentageValue;
      quantity = this.procurementResult.itemQuantity;
      if (+deliveryItem.passQuantity + +lastPassTotal > quantity) {
        return false;
      } else {
        return true;
      }
    } else if (
      this.procurementResult.procurementItem.item.itemType.code !==
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL &&
      this.procurementSowPaymentTermItem !== null
    ) {
      lastPassTotal = +this.procurementSowPaymentTermItem.deliveredTotal;
      quantity = +this.procurementSowPaymentTermItem.quantity;
      if (+deliveryItem.passPercentageValue + +lastPassTotal > quantity) {
        return false;
      } else {
        return true;
      }
    }
  }

  public doCheckRejectQuantity(deliveryItem): boolean {
    if (
      this.procurementResult.procurementItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL &&
      this.procurementSowPaymentTermItem !== null
    ) {
      if (
        +deliveryItem.rejectQuantity + +deliveryItem.passQuantity !==
        +deliveryItem.receivedQuantity
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  public doSave(): void {
    if (
      this.procurementResult.procurementItem.item.itemType.code ===
        this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL &&
      this.formGroup.get('rejectQuantity').value === 0
    ) {
      this.formGroup.get('rejectQuantity').setValue('0.00');
      this.formGroup.get('rejectQuantity').updateValueAndValidity();
    }
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const deliveryItem = this.formGroup.value;
            const isPassQuantityTrue = this.doCheckPassQuantity(deliveryItem);
            const isRejectQuantityTrue =
              this.doCheckRejectQuantity(deliveryItem);

            if (!isPassQuantityTrue) {
              this.global.modalService.message(
                this.translateService.instant(
                  'delivery-monitoring.quantityValidation'
                )
              );
            } else if (!isRejectQuantityTrue) {
              this.global.modalService.message(
                this.translateService.instant(
                  'delivery-monitoring.rejectValidation'
                )
              );
            } else {
              this.setStateProcessing();
              deliveryItem.procurementResult = this.procurementResult;
              this.deliveryMonitoringRequest.deliveryItem = deliveryItem;
              this.deliveryMonitoringRequest.fileObjectList =
                this.fileUploader.fileObjectList;
              this.deliveryMonitoringRequest.procurementScopeWork =
                this.procurementScopeWork;
              this.deliveryMonitoringRequest.procurementSowPaymentTerm =
                this.procurementSowPaymentTerm;
              this.deliveryMonitoringRequest.procurementSow =
                this.procurementSow;
              this.deliveryMonitoringRequest.procurementSowPaymentTermItem =
                this.procurementSowPaymentTermItem;
              this.deliveryMonitoringRequest.procurementResult =
                this.procurementResult;
              this.httpClientService
                .post<Response<DeliveryItem>>(
                  '/delivery-monitoring/insert',
                  this.deliveryMonitoringRequest
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.onChange.emit(response);
                    this.setStateReady();
                    this.global.alertService.showSuccess(
                      this.global.translateService.instant(
                        'app.alert.msg.saveSuccess'
                      )
                    );
                  } else {
                    this.onChange.emit(response);
                    this.setStateReady();
                    this.global.alertService.showError(response.statusText);
                  }
                });
            }
          }
        });
    }
  }

  public onChangeFileUpload(fileUploader: FileUploader): void {
    let isAllUploaded = true;
    if (!fileUploader.isAllUploaded) {
      isAllUploaded = fileUploader.isAllUploaded;
    }
    this.isAllUploaded = isAllUploaded;
  }

  public onChangePassTotal(): void {
    const passQuantity = this.formGroup.value.passQuantity;
    const receivedQuantity = this.formGroup.value.receivedQuantity;
    const value = +receivedQuantity - +passQuantity;
    this.formGroup.patchValue({
      rejectQuantity: value
    });
  }

  public setPercentage(total: number): string {
    if (total === null || (total && total === 0)) {
      return '0.00 %';
    } else {
      return this.currency.transform(
        total,
        '',
        '',
        `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`
      );
    }
  }

  public setUom(total: number): string {
    if (total === null || (total && total === 0)) {
      return '0.00 ' + this.procurementResult.procurementItem?.item?.uom?.name;
    } else {
      return (
        this.currency.transform(
          total,
          '',
          '',
          `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`
        ) +
        ' ' +
        this.procurementResult.procurementItem?.item?.uom?.name
      );
    }
  }
}
