<app-card
  *ngIf="!formLoading"
  header="app-contract-document.title"
  tips="app-contract-document.tips"
>
  <ng-template #headerRight>
    <div class="button-group button-group-right">
      <app-button [outline]="true" (onClick)="doViewReviewer()">{{
        'app-contract-document.button.contractReviewer' | translate
      }}</app-button>
      <app-button (onClick)="doAdd()" *ngIf="mode === 'edit'">{{
        'app-contract-document.button.addSignedContract' | translate
      }}</app-button>
    </div>
  </ng-template>
  <app-alert-x *ngIf="!object.id">{{
    'app-contract-document.alert.closeContract' | translate
  }}</app-alert-x>
  <app-table-xx *ngIf="object.id" [model]="tableResponse"></app-table-xx>
  <div class="no-data-wrapper" *ngIf="!object.id">
    <div class="book-wrapper pvc pv-file-contract"></div>
    <div class="text-no-data">
      <span class="text-no-data">
        {{ 'app-table.noDataAvailable' | translate }}
      </span>
      <span class="text-no-data">
        {{ 'app-table.letsTryToAddList' | translate }}
      </span>
    </div>
  </div>
</app-card>
