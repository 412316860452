<app-layout [isLoading]="formLoading">
    <ng-template #additionalTitle *ngIf="!formLoading">
      <app-badge
        class="cml-2"
        [color]="badgeColor[response.contract.contractWorklistStatus.code]"
      >
        {{ getTranslateKey(response.contract.contractWorklistStatus) | translate }}
      </app-badge>
    </ng-template>
  
    <div class="row main-row">
      <div class="col-lg-12">
        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="response.workflowStepInfoModel"
          *ngIf="
            !formLoading && isSourceReady &&
            (!response.isUserApproval ||
            response.isUserApproval === null)"
        >
        </app-workflow-step-info>

          <app-contract-request-info
            [contractId]="contractId"
          ></app-contract-request-info>
    
          <app-card *ngIf="!formLoading && isSourceReady"
            header="contract-request.detail.workDefinition.title"
            tips="contract-request.detail.workDefinition.tips"
          >
            <app-sow
              *ngIf="!formLoading && isSourceReady"
              [isView]="true"
              [sowList]="sowList"
              [prItemReleasedList]="prItemReleasedList"
              [amount]="amount"
              [isDisabled]="!isSourceReady"
            ></app-sow>
  
            <br />
            <app-fines
              *ngIf="!formLoading && isSourceReady"
              [isView]="true"
              [finesList]="finesList"
              [amount]="amount"
              [isDisabled]="!isSourceReady"
            ></app-fines>
  
            <br />
            <app-guarantee
              *ngIf="!formLoading && isSourceReady"
              [isView]="true"
              [guaranteeList]="guaranteeList"
              [amount]="amount"
              [isDisabled]="!isSourceReady"
            ></app-guarantee>
          </app-card>

          <app-card *ngIf="!formLoading && isSourceReady" header="contract-request.vendor.title" tips="contract-request.vendor.tips">
            <app-table-xx [model]="tableResponseVendor"> </app-table-xx>
          </app-card>

          <app-card *ngIf="!formLoading && isSourceReady" header="contract-draft.drafting.title" tips="contract-draft.drafting.tips">
            <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>
            <form
                class="form-horizontal"
                *ngIf="!formLoading"
                [formGroup]="formGroup"
                novalidate
            >
                <div class="form-group row text-sm-left">
                    <label class="col-sm-4">{{ 'contract-draft.form.template' | translate }}</label>
                    <div class="col-sm-8 text-left">
                    <app-dropdown-select
                        size="LG"
                        formControlName="contractTemplate"
                        [optionList]="contractTemplateList"
                        (onChange)="onChangeTemplateList($event)"
                    ></app-dropdown-select>
                    </div>
                </div>
                <div
                    class="text-center py-4"
                    *ngIf="!showTables && contractTemplate"
                >
                    <img src="assets/img/common/loader-small.gif" alt="Loading" />
                </div>
                <div *ngIf="showTables">
                    <h3 class="separator mt-3">{{
                    'contract-draft.form-group.parameter' | translate
                    }}</h3>
                    <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>
                    <div class="removeSearchText app-table-search">
                    <ng-container formArrayName="contractTemplateParamList">
                        <div class="form-group row text-sm-right">
                        <div
                            [ngStyle]="{
                            width: '100%'
                            }"
                        >
                            <div>
                            <div
                                class="float-none w-100"
                                [ngStyle]="{ margin: '0 auto' }"
                            >
                                <table
                                class="table table-borderless"
                                aria-describedby="Table"
                                >
                                <tbody>
                                    <tr
                                    *ngFor="
                                        let contractTemplateParam of contractTemplateParamList.controls;
                                        let i = index
                                    "
                                    [formGroupName]="i"
                                    >
                                    <td
                                        class="text-left col-sm-4"
                                    >
                                        {{
                                        contractTemplateParam.controls.name
                                            .value
                                        }}
                                        <span
                                        class="font-weight-bold text-danger"
                                        *ngIf="
                                            (!formGroup.isView &&
                                            !contractTemplateParam.controls.code.value.includes(
                                                'PERUBAHAN'
                                            ) &&
                                            contractTemplateParam.controls.code
                                                .value !==
                                                global.appConstant.cm
                                                .CONTRACT_PARAMETER_NOMOR_KONTRAK) ||
                                            (!formGroup.isView &&
                                            contract &&
                                            contract.contractType.code !==
                                                global.appConstant.cm
                                                .CONTRACT_TYPE_ADDENDUM_CONTRACT &&
                                            contractTemplateParam.controls.code
                                                .value ===
                                                global.appConstant.cm
                                                .CONTRACT_PARAMETER_NOMOR_KONTRAK)
                                        "
                                        >*
                                        </span>
                                    </td>
                                    <td class="col-sm-8 text-left">
                                        <div
                                        *ngIf="
                                            contractTemplateParam.controls
                                            .valueTypeCode.value ===
                                            global.appConstant.core
                                            .VALUE_TYPE_STRING
                                        "
                                        >
                                        <app-text-field
                                            size="LG"
                                            formControlName="value"
                                            (onChange)="onChangeValue()"
                                        ></app-text-field>
                                        </div>
                                        <div
                                        *ngIf="
                                            contractTemplateParam.controls
                                            .valueTypeCode.value ===
                                            global.appConstant.core
                                            .VALUE_TYPE_NUMBER
                                        "
                                        >
                                        <app-text-field
                                            size="LG"
                                            formControlName="value"
                                            type="integer"
                                            (onChange)="onChangeValue()"
                                        ></app-text-field>
                                        </div>
                                        <div
                                        *ngIf="
                                            contractTemplateParam.controls
                                            .valueTypeCode.value ===
                                            global.appConstant.core
                                                .VALUE_TYPE_DATE_FORMAT_SHORT ||
                                            contractTemplateParam.controls
                                            .valueTypeCode.value ===
                                            global.appConstant.core
                                                .VALUE_TYPE_DATE_FORMAT_LONG
                                        "
                                        >
                                        <app-date-picker-x
                                            size="LG"
                                            formControlName="value"
                                            (onChange)="onChangeValue()"
                                        >
                                        </app-date-picker-x>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </ng-container>
                    </div>
                </div>
            </form>
            <form
                class="form-horizontal"
                *ngIf="!formLoading"
                [formGroup]="formGroupVersion"
                novalidate
            >
            
                <h3 class="separator mt-3">
                    {{'contract-draft.separator.generateContract' | translate}}
                </h3>
                <div
                    class="form-group row text-sm-left"
                    *ngIf="
                    global.userSession.activeUserRole.role.code !==
                    global.appConstant.ROLE_CODE_VENDOR && !response.isUserApproval
                    "
                >
                    <label class="col-sm-4">
                    {{ 'contract-draft.form.contract' | translate }}
                    </label>
                    <div class="col-sm-5 text-left" style="display: grid">
                        <app-button (onClick)="doGenerate($event)" [disabled]="!showTables">
                            {{ 'contract-draft.button.generateDownloadContract' | translate }}
                        </app-button>
                        <small class="text-secondary">
                            {{ 'contract-draft.form.thisFileIncludeContractTemplate' | translate }}
                        </small>
                    </div>
                </div>

                <div class="form-group row text-sm-left">
                    <label class="col-sm-4">
                    {{ 'contract-draft.form.draftContract' | translate }}
                    </label>
                    <div class="col-sm-5 text-left">
                    <app-upload-x
                        formControlName="uploadFile"
                        [fileUploader]="fileUploader"
                        [disabled]="!showTables"
                    ></app-upload-x>
                    </div>
                </div>
                <div class="form-group row text-sm-left">
                    <label class="col-sm-4">
                    {{ 'contract-draft.form.docVersion' | translate }}
                    </label>
                    <div class="col-sm-5 text-left">
                        <ng-container
                        *ngIf="
                        response.contractDraftHistory !==
                            null;
                        then versionImpl;
                        else nullVersionImpl
                        "
                    ></ng-container>
                    <ng-template #versionImpl>
                        <span> Ver. {{
                            formGroupVersion.value.version !== null
                            ? formGroupVersion.value.version
                            : '0'
                        }}</span>&nbsp;
                        <a
                            *ngIf="formGroupVersion.value.version !== 0"
                            class="text-primary"
                            style="text-decoration: underline"
                            (click)="viewVersionHistory($event)"
                        >
                           <strong> {{ 'contract-draft.hyperlink.versionHistory' | translate }} </strong>
                        </a>
                    </ng-template>
                    <ng-template #nullVersionImpl> Ver. 0 </ng-template>
                    </div>
                </div>
                <div class="form-group row text-sm-left">
                    <label class="col-sm-4 optional">
                    {{ 'contract-draft.form.description' | translate }}
                    </label>
                    <div class="col-sm-5 text-left">
                    <app-text-area
                        size="XL"
                        formControlName="note"
                        maxLength="2000"
                    ></app-text-area>
                    </div>
                </div>
            </form>
          </app-card>

          <app-card *ngIf="!formLoading && isSourceReady" 
            header="contract-draft.contractReviewer.title" 
            tips="contract-draft.contractReviewer.tips">
            <ng-template #headerRight>
                <app-button
                    *ngIf="!formGroup.isView"
                    (onClick)="doAddUser()"
                >
                    <em class="pir pi-plus-circle"></em>
                    {{ 'contract-draft.button.addUser' | translate }}
                </app-button>
            </ng-template>
            <app-alert-x color="INFO" *ngIf="!formGroup.isView">
              {{ 'contract-draft.contractReviewer.info.alert' | translate }}
            </app-alert-x>
            <form class="form-horizontal" [formGroup]="formGroupReviewer" novalidate>
              <app-select
                formControlName="reviewerList"
                [optionList]="reviewerOptionList"
                [isMultiple]="true"
                [isShowHover]="false"
                maxHeight="max-content"
              >
                <ng-template #content let-data>
                  <div style="margin: 0 auto; display: flex; position: relative">
                    <div
                      *ngIf="data.reviewByUser?.imgFile"
                      style="
                        width: 50px;
                        height: 50px;
                        overflow: hidden;
                        border-radius: 50px;
                      "
                      class="mr-3"
                    >
                      <img
                        style="width: 100%"
                        [attr.src]="
                          global.config.BACKEND_ADDRESS +
                          '/' + moduleCode + '/file/view/' +
                          data.reviewByUser?.imgFile?.uploadedFileName +
                          '/' +
                          data.reviewByUser?.imgFile?.fileType?.code | resolveImgSrc | async
                        "
                        alt="user image"
                        [default]="global.appConstant.core.URL_DEFAULT_PROFILE_IMAGE"
                      />
                    </div>
                    <div
                      *ngIf="!data.reviewByUser?.imgFile"
                      style="
                        width: 50px;
                        height: 50px;
                        overflow: hidden;
                        border-radius: 50px;
                      "
                      class="mr-3"
                    >
                      <img
                        style="width: 100%"
                        [src]="'assets/' + data.reviewByUser?.imgFile?.fileName"
                        alt="user image"
                        [default]="global.appConstant.core.URL_DEFAULT_PROFILE_IMAGE"
                      />
                    </div>
                    <div class="d-inline-block">
                      <ng-container>
                        <span class="d-inline-flex">
                          <h5 class="mr-1">{{ data.name }}</h5>
                          <i class="text-secondary">( {{ data.email }} )</i>
                      </span>
                      <p class="text-secondary">{{ data.position }}</p>
                      </ng-container>
                    </div>
                  </div>
                </ng-template>
              </app-select>
            </form>
          </app-card>
          
        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            response.approvalPathResponseModel &&
            !response.isUserApproval &&
            response?.contract?.contractWorklistStatus?.code &&
            this.statusForApprovalList.includes(
              response?.contract?.contractWorklistStatus?.code
            )
          "
          [workflowPrcs]="response?.contract?.workflowPrcs"
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="response.approvalPathResponseModel"
        ></app-approval-monitoring>
  
        <div class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center">
                <app-button
                  (onClick)="doSaveDraft()"
                  color="PRIMARY"
                  [outline]="true"
                  *ngIf="
                    !formGroup.isView &&
                    (todo === 'edit' ||
                      response.contract?.contractWorklistStatus.code ===
                        this.global.appConstant.cm.CONTRACT_WORKLIST_STATUS_DRAFT)
                  "
                  [disabled]="prItemReleasedList.length === 0"
                >
                  {{ 'app.button.save' | translate }}</app-button
                >
                <app-button
                  (onClick)="doSave()"
                  *ngIf="!formGroup.isView && !isRevisions"
                  [disabled]="!showTables"
                >
                  {{ 'app.button.submit' | translate }}</app-button
                >
  
                <app-button
                  *ngIf="isRevisions"
                  (onClick)="doSave()"
                  color="PRIMARY"
                  [outline]="true"
                  [disabled]="prItemReleasedList.length === 0"
                >
                  {{ 'contract-request.button.resend' | translate }}
                </app-button>
              </div>
            </div>
          </div>
        </div>
  
        <app-approval-prcs-x
          *ngIf="response.isUserApproval"
          [workflowModelPrcs]="response.contract?.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>
      </div>
    </div>
  
    <ng-template #contentSidebarRight>
      <app-tips *ngIf="!formLoading && isSourceReady"></app-tips>
    </ng-template>
  </app-layout>
  