<app-popup (onClose)="doCancel()" [isShowHeaderCloseButton]="!isLoadingSync && !isSyncButtonClicked"
    header="cost-center.popup.sync.title">
    <div class="row main-row-popup">
        <div class="col-12 mb-4">
            <p>{{ 'cost-center.notif' | translate }}</p>

            <div class="row" *ngIf="isLoadingSync">
                <div class="col-sm-12 text-center">{{
          'cost-center.sync.pleaseWait' | translate
        }}
                </div>
            </div>
            <div class="row" *ngIf="isLoadingSync">
                <div class="col-sm-12 text-center">{{
        'cost-center.sync.gettingData' | translate
      }}
                </div>
            </div>
            <div class="row" *ngIf="isLoadingSync && webSocketResponseModel.progressUnit === 'kb'">
                <div class="col-sm-12 text-center">
                    {{ webSocketResponseModel.progressValue + webSocketResponseModel.progressUnit }}
                </div>
            </div>

            <div class="row" *ngIf="isSyncButtonClicked && !webSocketResponseModel">
                <div class="col-sm-12 text-center">
                    <img src="assets/img/common/loader-small.gif" alt="Loading" height="8" width="30" />
                </div>
            </div>

            <div class="w-50 mx-auto">
                <ngb-progressbar type="success" textType="white" [value]="webSocketResponseModel.progressValue"
                    [showValue]="true"
                    *ngIf="isLoadingSync && webSocketResponseModel.progressValue && webSocketResponseModel.progressUnit !== 'kb'">
                </ngb-progressbar>
            </div>

            <ng-template #modalFooter let-activeModal>
                <div class="button-group button-group-center">
                    <app-button mode="BYPASS" color="SECONDARY" (onClick)="activeModal.close(true)"
                        *ngIf="!isLoadingSync" [disabled]="formSaving || isLoadingSync || isSyncButtonClicked">{{
          'app.button.close' | translate
        }}</app-button>
                    <app-button mode="BYPASS" color="PRIMARY" (onClick)="doSyncronize()"
                        [disabled]="formSaving || isLoadingSync || isSyncButtonClicked" *ngIf="!isLoadingSync">
                        {{ 'cost-center.popup.button.sync' | translate }}
                    </app-button>
                </div>
            </ng-template>
        </div>
    </div>
</app-popup>