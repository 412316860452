import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { LangEditAddComponent } from './lang-edit-add.component';
import { LangFlagIconPopupComponent } from './lang-flag-icon-popup.component';
import { LangComponent } from './lang.component';
export const routes: Routes = [
  { path: '', component: LangComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: LangEditAddComponent,
    data: { breadcrumb: 'lang.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: LangEditAddComponent,
    data: { breadcrumb: 'lang.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    LangComponent,
    LangEditAddComponent,
    LangFlagIconPopupComponent
  ],
  entryComponents: [LangFlagIconPopupComponent]
})
export class LangModule {}
