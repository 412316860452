<app-popup
  header="app-item-request.popup.editprItem.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="formGroup.isView ? true : false"
>
  <div class="main-row-popup-pr-item-edit">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <div class="row" *ngIf="!formLoading">
        <div class="col-sm-4">
          <app-image-slideshow
            [fileList]="fileList"
            [parentModuleCode]="moduleCode"
          >
          </app-image-slideshow>
        </div>
        <div class="col-sm-8">
          <h5 class="separator">{{ procurementItem.prItem.item?.name }}</h5>
          <h4
            >{{
              procurementItem.prItem.catalogContract?.price ||
                (procurementItem.prItem.catalog
                  ? procurementItem.prItem.catalog.price
                  : procurementItem.prItem.price) | resolveNumberToCurrency
            }}
            IDR</h4
          >
          <p></p>

          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'app-item-request.popup.form.itemCode' | translate }}</div
            >
            <div class="tab-content-item-value">
              {{ procurementItem.prItem.item?.code || '-' }}
            </div>
          </div>
          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'app-item-request.popup.form.uom' | translate }}</div
            >
            <div class="tab-content-item-value">
              {{ procurementItem.prItem.item?.uom?.name || '-' }}
            </div>
          </div>

          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'app-item-request.popup.form.quantity' | translate }}</div
            >
            <div class="tab-content-item-value">
              <app-count
                *ngIf="!formGroup.isView"
                formControlName="quantity"
                type="decimal"
                maxLength="32"
                (onChange)="onChangeQuantity()"
                (input)="onChangeQuantity()"
                size="LG"
                [min]="1"
              ></app-count>
            </div>
          </div>

          <div class="tab-content-item" *ngIf="!formLoading">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'app-item-request.popup.form.subTotal' | translate }}</div
            >
            <div class="tab-content-item-value">
              {{
                formGroup.value.totalPrice
                  ? global.converterService.convertMoney(
                      formGroup.value.totalPrice
                    )
                  : '0'
              }}
              IDR
            </div>
          </div>
          <div class="tab-content-item">
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'app-item-request.popup.form.type' | translate }}</div
            >
            <div class="tab-content-item-value">
              <app-badge
                style="position: absolute"
                [color]="
                  procurementItem.prItem.item?.itemType?.code ===
                  global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
                    ? 'GOOD'
                    : 'SERVICE'
                "
              >
                {{ procurementItem.prItem.item?.itemType?.name || '-' }}
              </app-badge>
            </div>
          </div>
          <div
            class="tab-content-item"
            *ngIf="
              procurementItem.prItem.catalog &&
              procurementItem.prItem.catalog.catalogType.code !==
                global.appConstant.vm.CATALOG_TYPE_CONTRACT
            "
          >
            <div
              class="tab-content-item-label"
              [ngStyle]="{ 'min-width': '175px' }"
              >{{ 'app-item-request.popup.form.catalog' | translate }}</div
            >
            <div
              class="tab-content-item-value"
              *ngIf="procurementItem.prItem.catalog !== null"
            >
              <app-badge-catalog
                style="position: absolute"
                [color]="
                  procurementItem.prItem.catalog?.catalogType.code ===
                  global.appConstant.vm.CATALOG_TYPE_VENDOR
                    ? 'INFO'
                    : 'SUCCESS'
                "
              >
                {{
                  procurementItem.prItem.catalog.catalogType.translationKey.module.code.toLowerCase() +
                    '.' +
                    procurementItem.prItem.catalog.catalogType.translationKey
                      .key | translate
                }}
              </app-badge-catalog>
            </div>
            <div
              class="tab-content-item-value"
              *ngIf="procurementItem.prItem.catalog === null"
            >
              <app-badge-catalog style="position: absolute" color="WARNING">
                {{ 'app-item-request.popup.form.itemCatalog' | translate }}
              </app-badge-catalog>
            </div>
          </div>

          <br />
          <label
            ><b>{{
              'app-item-request.popup.form.specification' | translate
            }}</b></label
          >
          <div *ngIf="procurementItem.prItem.catalog !== null">
            <div class="tab-content-item">
              <div
                class="tab-content-item-label"
                [ngStyle]="{ 'min-width': '175px' }"
                >{{ 'app-item-request.popup.form.weight' | translate }}</div
              >
              <div class="tab-content-item-value">
                {{ procurementItem.prItem.catalog?.weight || '-' }}
              </div>
            </div>
            <div
              class="tab-content-item"
              *ngFor="let data of catalogSpecificationList; let i = index"
            >
              <div class="tab-content-item-label"
                >{{
                  data.catalogAttribute.translationKey.module.code.toLowerCase() +
                    '.' +
                    data.catalogAttribute.translationKey.key | translate
                }}
              </div>
              <!-- WYSIWYG-->
              <div
                *ngIf="data.catalogAttribute.formObject.code === 'WYSIWYG'"
                [innerHTML]="doParseValue(data)"
                [ngStyle]="{
                  'white-space': 'normal',
                  'max-width': '600px',
                  'word-break': 'break-word'
                }"
              ></div>
              <!-- DATE-PICKER -->
              <div
                *ngIf="data.catalogAttribute.formObject.code === 'DATE-PICKER'"
              >
                {{
                  doConvertToDate(data.value)
                    | date: global.config.parameterModel.dateFormatShort
                }}</div
              >
              <!-- TOGGLE-SWITCH -->
              <div
                *ngIf="
                  data.catalogAttribute.formObject.code === 'TOGGLE-SWITCH'
                "
              >
                {{
                  (data.value === 'true'
                    ? 'view-catalog.yes'
                    : 'view-catalog.no'
                  ) | translate
                }}</div
              >
              <!-- ELSE -->
              <div
                *ngIf="
                  data.catalogAttribute.formObject.code !== 'WYSIWYG' &&
                  data.catalogAttribute.formObject.code !== 'DATE-PICKER' &&
                  data.catalogAttribute.formObject.code !== 'TOGGLE-SWITCH'
                "
                class="tab-content-item-value"
                [ngStyle]="{
                  'white-space': 'normal',
                  'max-width': '600px',
                  'word-break': 'break-word'
                }"
                >{{ doParseValue(data) }}</div
              >
            </div>
          </div>

          <div
            *ngIf="procurementItem.prItem.catalog === null"
            class="tab-content-item"
          >
            <app-wysiwyg
              size="LG"
              formControlName="specification"
              maxLength="32"
            ></app-wysiwyg>
          </div>

          <br />
          <div *ngIf="procurementItem.prItem.catalog !== null">
            <label
              ><b>{{
                'app-item-request.popup.form.description' | translate
              }}</b></label
            >
            <div
              *ngIf="!formLoading"
              readMore
              [ngStyle]="{
                'white-space': 'normal',
                'max-width': '600px',
                'word-break': 'break-word'
              }"
              [innerHTML]="procurementItem.prItem.catalog?.description || '-'"
            ></div>
          </div>
        </div>
      </div>
    </form>
    <ng-template #modalFooter let-activeModal *ngIf="!formGroup.isView">
      <div class="col-12 text-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.close' | translate }}</app-button
        >

        <app-button color="PRIMARY" (onClick)="doSave()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </div>
</app-popup>
