import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Pr } from 'src/app/core/bean/pr';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';
import { ProposalCriteria } from '../../core/bean/proposal-criteria';

@Component({
  templateUrl: './proposal-criteria-edit-add.component.html'
})
export class ProposalCriteriaEditAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  public proposalCriteria: ProposalCriteria = new ProposalCriteria();
  public urlBackOutside: string;

  constructor(translateService: TranslateService) {
    super('proposal-criteria', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.proposalCriteria = this.global.routerParams.get('proposalCriteria');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null, Validators.required()],
      idName: [null, Validators.required()],
      enName: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    if (this.todo === 'edit') {
      this.formGroup.patchValue({
        id: this.proposalCriteria.id,
        code: this.proposalCriteria.code,
        idName: this.proposalCriteria.idName,
        enName: this.proposalCriteria.enName
      });
    } else {
      this.proposalCriteria = new ProposalCriteria();
    }

    this.setStateReady();
  }

  public doSave(): void {
    this.proposalCriteria.code = this.formGroup.get('code').value;
    this.proposalCriteria.enName = this.formGroup.get('enName').value;
    this.proposalCriteria.idName = this.formGroup.get('idName').value;
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          const url =
            this.todo === 'edit'
              ? '/proposal-criteria/update'
              : '/proposal-criteria/insert';
          this.httpClientService
            .post<Response<Pr>>(url, this.proposalCriteria)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.modalService.submitSuccess().subscribe(result => {
                  if (result) {
                    this.router.navigate(['/pages/proposal-criteria']);
                  }
                });
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
