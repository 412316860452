import { Injectable } from '@angular/core';
import { Global } from '../global/global';
declare let AesUtil: any;

@Injectable({
    providedIn: 'root'
  })
export class SensitiveDataService {
    constructor(private global: Global) {}

    public encrypt(valueList: string[]): string {
        const aesUtil = new AesUtil();
        const s = new Date().getSeconds();
        const m = new Date().getMinutes();
        const h = new Date().getHours();
        const separator = this.global.appConstant.core.LOGIN_SEPARATOR_KEY;

        let toBeEncrypted = '';
        if (valueList.length === 1) {
            toBeEncrypted =
                    s +
                    separator +
                    valueList[0] +
                    separator +
                    m +
                    h;

        } else if (valueList.length === 2) {
            toBeEncrypted =
                    s +
                    separator +
                    valueList[0] +
                    separator +
                    m +
                    separator +
                    valueList[1] +
                    separator +
                    h;

        } else if (valueList.length === 3) {
            toBeEncrypted =
                    s +
                    separator +
                    valueList[0] +
                    separator +
                    m +
                    separator +
                    valueList[1] +
                    separator +
                    valueList[2] +
                    separator +
                    h;
        }

        return aesUtil.encrypt(
            this.global.appConstant.core.LOGIN_SALT_KEY,
            this.global.appConstant.core.LOGIN_IV_KEY,
            this.global.appConstant.core.LOGIN_PASSPHRASE_KEY, toBeEncrypted);
    }
}
