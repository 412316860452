import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppTreeModule } from '../../app-tree/app-tree.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupTreeOrganizationService } from './app-popup-tree-organization.service';
import { AppPopupTreeComponent } from './app-popup-tree.component';
@NgModule({
  imports: [VendorModule, AppTreeModule, AppPopupModule, AppButtonModule],
  declarations: [AppPopupTreeComponent],
  exports: [AppPopupTreeComponent],
  providers: [AppPopupTreeOrganizationService],
  entryComponents: [AppPopupTreeComponent]
})
export class AppPopupTreeModule {}
