import { BaseEntity } from '../base/base-entity';
import { UserRole } from '../bean/user-role';
import { Widget } from '../bean/widget';

export class UserDashboardWidgetEntity extends BaseEntity {
    userRole: UserRole;
    widget: Widget;
    sequence: number;
    group: string;
}
