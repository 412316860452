import { Component, ViewEncapsulation } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
@Component({
  templateUrl: 'work-plan-view.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class WorkPlanViewComponent extends BaseModuleComponent {
  public workPlanId: number;
  public routeList: string[] = [
    'work-plan',
    'purchase-request',
    'procurement',
    'contract',
    'purchase-order',
    'delivery-received',
    'payment'
  ];

  constructor(translateService: TranslateService) {
    super('work-plan', translateService);
  }

  onInit(): void {
    // this.todo = this.global.routerParams['todo'] = 'edit' ;
    // this.workPlanId = this.global.routerParams['workPlanId'];
    this.todo = this.global.routerParams.get('todo');
    this.workPlanId = this.global.routerParams.get('workPlanId');
  }

  onTabChange(event) {
    this.router.navigate([
      '/pages/work-plan-realize/' + this.routeList[event.index]
    ]);
  }
}
