import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { EducationEditAddComponent } from './education-edit-add.component';
import { EducationComponent } from './education.component';
export const routes = [
  { path: '', component: EducationComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: EducationEditAddComponent,
    data: { breadcrumb: 'education.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: EducationEditAddComponent,
    data: { breadcrumb: 'education.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [EducationComponent, EducationEditAddComponent],
  providers: []
})
export class EducationModule {}
