<app-popup
  header="aanwijzing.popup.scanQRCodeTutorial.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <form
    class="form-horizontal print-tutorial"
    [formGroup]="formGroup"
    novalidate
  >
    <div class="row">
      <div class="col-8">
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'aanwijzing.form.sessionNumber' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            {{ aanwijzing.code }}
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'aanwijzing.form.aanwijzingType' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            {{ getTranslateKey(aanwijzing.transactionType) | translate }}
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'aanwijzing.form.sessionPeriod' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            {{
              aanwijzing.periodStartDate
                | date: global.config.parameterModel.datetimeFormatShort
            }}
            {{ 'aanwijzing.form.until' | translate }}
            {{
              aanwijzing.periodEndDate
                | date: global.config.parameterModel.datetimeFormatShort
            }}
          </div>
        </div>
        <div *ngIf="aanwijzing.location" class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'aanwijzing.form.location' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            {{ aanwijzing.location }}
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'aanwijzing.form.subject' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            {{ aanwijzing.subject }}
          </div>
        </div>
      </div>

      <div class="col-3">
        <app-qr-code [barcodeText]="aanwijzing.qrCodeUrl"></app-qr-code>
      </div>
    </div>

    <h3 class="separator">{{
      'aanwijzing.separator.scanQRCodeTutorial' | translate
    }}</h3>

    <div
      class="lm-2"
      style="
        border: 1px solid var(--gray-400);
        border-radius: 5px;
        box-sizing: border-box;
        padding: 1rem;
      "
    >
      <p>{{ 'aanwijzing.tutorial.qrCode1' | translate }}</p>
      <div
        style="
          margin: auto;
          display: flex;
          margin-right: 100px;
          margin-left: 100px;
        "
      >
        <div
          style="
            background: no-repeat;
            background-position: center;
            width: 50%;
            height: 200px;
            background-image: url('/assets/svg/scan-qr-code-tutorial/scan-qr-code-tutorial-1.svg');
          "
        >
        </div>
        <div
          style="
            background: no-repeat;
            background-position: center;
            width: 50%;
            height: 200px;
            background-image: url('/assets/svg/scan-qr-code-tutorial/scan-qr-code-tutorial-2.svg');
          "
        >
        </div>
      </div>
    </div>

    <div
      class="lm-2"
      style="
        border: 1px solid var(--gray-400);
        border-radius: 5px;
        box-sizing: border-box;
        padding: 1rem;
      "
    >
      <p>{{ 'aanwijzing.tutorial.qrCode2' | translate }}</p>
      <div
        style="
          background: no-repeat;
          background-position: center;
          width: 100%;
          height: 200px;
          background-image: url('/assets/svg/scan-qr-code-tutorial/scan-qr-code-tutorial-3.svg');
        "
      >
      </div>
    </div>

    <div
      class="lm-2"
      style="
        border: 1px solid var(--gray-400);
        border-radius: 5px;
        box-sizing: border-box;
        padding: 1rem;
      "
    >
      <p>{{ 'aanwijzing.tutorial.qrCode3' | translate }}</p>
      <div
        style="
          background: no-repeat;
          background-position: center;
          width: 100%;
          height: 200px;
          background-image: url('/assets/svg/scan-qr-code-tutorial/scan-qr-code-tutorial-4.svg');
        "
      >
      </div>
    </div>

    <div
      class="lm-2"
      style="
        border: 1px solid var(--gray-400);
        border-radius: 5px;
        box-sizing: border-box;
        padding: 1rem;
      "
    >
      <p>{{ 'aanwijzing.tutorial.qrCode4' | translate }}</p>
      <div
        style="
          background: no-repeat;
          background-position: center;
          width: 100%;
          height: 200px;
          background-image: url('/assets/svg/scan-qr-code-tutorial/scan-qr-code-tutorial-5.svg');
        "
      >
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
        >
          {{ 'app.button.close' | translate }}</app-button
        >
        <app-button (onClick)="doPrint()">
          <em class="pir pi-printer"></em>
          {{ 'aanwijzing.button.printThisPage' | translate }}</app-button
        >
      </div>
    </ng-template>
  </form>
</app-popup>
