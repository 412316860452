import { ChartDataSets, ChartOptions } from 'chart.js';
import { chartColorConstant } from '../constant/chart-color-constant';
import { Chart } from '../models/chart';
import { ChartModel } from '../models/chart-model';
import { lineChartOptions } from '../options/line-chart.options';
import {
    chartPaddingBelowLegends,
    customChartPlugin
} from '../plugins/app-chart-custom-plugins';
export function setLineChart(chartState: ChartModel): Array<Chart> {
    const { type } = { ...chartState };
    let dataSets: Array<ChartDataSets> = new Array();
    const firstDataOfDataSets = chartState.dataSets[0];
    if (typeof firstDataOfDataSets === 'number') {
        const dataSet: ChartDataSets = {
            data: chartState.dataSets as Array<number>
        };
        if (chartState.dataSetsLabel) {
            dataSet.label = chartState.dataSetsLabel[0];
        } else {
            dataSet.label = `No 'dataSetsLabel' Defined`;
        }
        dataSets = [dataSet];
    } else if (Array.isArray(firstDataOfDataSets)) {
        chartState.dataSets.forEach((dataList, index: number) => {
            const dataSet: ChartDataSets = { data: dataList };
            if (chartState.dataSetsLabel && chartState.dataSetsLabel[index]) {
                dataSet.label = chartState.dataSetsLabel[index];
            }
            dataSets.push(dataSet);
        });
    } else if (
        typeof firstDataOfDataSets === 'object' &&
        firstDataOfDataSets.hasOwnProperty('data')
    ) {
        dataSets = chartState.dataSets as Array<ChartDataSets>;
        if (chartState.labels) {
            chartState.dataSetsLabel.forEach((label: string, index: number) => {
                if (label && dataSets[index]) {
                    Object.assign(dataSets[index], { label });
                }
            });
        }
    }

    const options: ChartOptions = lineChartOptions;
    if (chartState.options) {
        Object.assign(options, chartState.options);
    }

    const colors = [
        ...((chartState.options &&
            (chartState.options.defaultColor as Array<string>)) ||
            chartColorConstant)
    ].map(color => {
        return {
            borderColor: color,
            backgroundColor: 'transparent',
            pointBackgroundColor: color
        };
    });

    // tslint:disable-next-line:prefer-const
    let dataSetsTemp;
    if (dataSets.length > 0) {
        dataSetsTemp = dataSets[0].data.length;
    } else {
        dataSetsTemp = 0;
    }

    const labels: Array<string | Array<string>> = Array(dataSetsTemp)
        .fill(0)
        .map((f: number, index: number) => {
            return chartState.labels[f + index];
        });

    return [
        {
            dataSets,
            labels,
            options,
            type,
            colors,
            plugins: [customChartPlugin, chartPaddingBelowLegends]
        }
    ];
}
