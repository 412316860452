import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { PrItem } from 'src/app/core/bean/pr-item';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { RfpVendor } from '../../core/bean/rfp-vendor';
import { FileUploader } from '../../core/components/upload';
import { SnackbarService } from '../../core/services/snackbar.services';
import { RfpResponseRequest } from './rfp-response.request';
import { RfpResponseResponse } from './rfp-response.response';

@Component({
  templateUrl: './rfp-response-edit.component.html'
})
export class RfpResponseEditComponent
  extends BaseModuleComponent
  implements OnInit {
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  @ViewChild('selectorProposalCriteria')
  tableProposalCriteria: AppTableXComponent;
  @ViewChild('selectorVendor')
  tableVendor: AppTableXComponent;

  public rfpVendorId: number;
  public urlBackOutside: string;
  public cancellationAlert: string;
  public CURRENCY_DIGITS_INFO: string;
  public currentDate: Date = new Date();
  public rfpVendor: RfpVendor = new RfpVendor();
  public rfpRequest: RfpResponseRequest = new RfpResponseRequest();
  public rfpResponse: RfpResponseResponse = new RfpResponseResponse();
  public prItemList: PrItem[] = [];
  public procurementItemList: ProcurementItem[] = [];
  public vendorList: PopupVendorView[] = [];
  public isSubmit = false;
  public isShowCloseButton = false;
  public token: string;
  public tableResponseProposalCriteria: TableResponseModel<any>;

  public fileUploader: FileUploader = new FileUploader(
    '/rfp/',
    'rfp.form.file',
    'DOC_RFP_TEMPLATE',
    false,
    5
  );
  public fileUploaderResponse: FileUploader = new FileUploader(
    '/rfp-response/',
    'rfp-response.form.file',
    'DOC_RFP_RESPONSE',
    false,
    5
  );

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public currency: CurrencyPipe,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute
  ) {
    super('rfp-response', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<RfpVendor>('/rfp-response/notification/' + this.token)
      .subscribe((rfpVendor: RfpVendor) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('rfpVendor', rfpVendor);
        this.global.routerParams.set('rfpVendorId', rfpVendor.id);
        this.global.routerParams.set('urlBackOutside', '/pages/rfp-response');
        if (
          rfpVendor.rfpResponseStatus.code ===
          this.global.appConstant.pm.RFP_STATUS_WAITING_RESPONSE ||
          rfpVendor.rfpResponseStatus.code ===
          this.global.appConstant.pm.RFP_STATUS_DRAFT
        ) {
          this.router.navigate(['/pages/rfp-response/edit']);
          this.global.routerParams.set('todo', 'edit');
        } else {
          this.router.navigate(['/pages/rfp-response/detail']);
          this.global.routerParams.set('todo', 'view');
        }
      });
  }

  public setInitializationState(): void {
    this.doSetDataFromRouterParams();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.rfpVendorId = this.global.routerParams.get('rfpVendorId');
    this.rfpVendor = this.global.routerParams.get('rfpVendor');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      title: [null],
      date: [null],
      note: [null],
      startDate: [null],
      endDate: [null],
      file: [null],
      responseFile: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<RfpResponseResponse>(
        '/rfp-response/add-edit',
        new RouteRequestModel(this.todo, this.rfpVendorId)
      )
      .subscribe((rfpResponse: RfpResponseResponse) => {
        this.rfpResponse = rfpResponse;

        if (this.rfpVendor.rfp) {
          const startDate: Date = this.rfpVendor.rfp.startDate
            ? new Date(this.rfpVendor.rfp.startDate)
            : null;
          const endDate: Date = this.rfpVendor.rfp.endDate
            ? new Date(this.rfpVendor.rfp.endDate)
            : null;
          const date = { from: startDate, to: endDate };
          const { id, code, title, note } = this.rfpVendor.rfp;
          this.fileUploader.setFileList(this.rfpResponse.fileList);
          this.formGroup.patchValue({
            id,
            code,
            title,
            startDate,
            endDate,
            date,
            note,
            file: this.fileUploader.fileObjectList
          });
        }

        this.prItemList = this.rfpResponse.prItemList;
        this.prItemList.forEach(element => {
          const procurementItem = new ProcurementItem();
          procurementItem.prItem = element;
          this.procurementItemList.push(procurementItem);
        });
        this.tableResponseProposalCriteria.setRecordList(
          this.rfpResponse.rfpProposalCriteriaList
        );

        if (
          this.rfpResponse.responseFileList &&
          this.rfpResponse.responseFileList.length > 0
        ) {
          this.fileUploaderResponse.setFileList(
            this.rfpResponse.responseFileList
          );
          this.formGroup.patchValue({
            responseFile: this.fileUploaderResponse.fileObjectList
          });
        }

        this.formGroup.get('code').setIsView(true);
        this.formGroup.get('file').setIsView(true);
        this.formGroup.get('title').setIsView(true);
        this.formGroup.get('date').setIsView(true);
        this.formGroup.get('note').setIsView(true);

        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponseProposalCriteria = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'proposalCriteria.enName',
          header: 'table.column.proposalCriteria'
        }
      ]
    );
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.rfpRequest.rfpVendor = this.rfpVendor;
      this.rfpRequest.fileObjectList = this.fileUploaderResponse.fileObjectList;
      this.rfpRequest.isSubmit = false;

      this.setStateProcessing();
      const url =
        this.rfpVendor.rfpResponseStatus.code ===
          this.global.appConstant.pm.RFP_STATUS_DRAFT
          ? '/rfp-response/update'
          : '/rfp-response/insert';
      this.httpClientService
        .post<Response<RfpVendor>>(url, this.rfpRequest)
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.snackbarService.showWarning('app.msg.info.sucsessSave');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set('rfpVendor', response.body);
                this.global.routerParams.set('rfpVendorId', response.body.id);
                this.global.routerParams.set(
                  'urlBackOutside',
                  '/pages/rfp-response'
                );
                this.router.navigate(['/pages/rfp-response/edit']);
                this.global.routerParams.set('todo', 'edit');
              });
          } else {
            this.global.alertService.showError(response.statusText);
          }
          this.setStateReady();
        });
    }
  }

  public doSetMarkAsDirty(): void {
    this.formGroup.markAsDirty();
  }

  public doSubmit(): void {
    this.isSubmit = true;
    this.validate();
    if (this.formGroup.valid) {
      this.rfpRequest.rfpVendor = this.rfpVendor;
      this.rfpRequest.fileObjectList = this.fileUploaderResponse.fileObjectList;
      this.rfpRequest.isSubmit = true;

      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const url =
              this.rfpVendor.rfpResponseStatus.code ===
                this.global.appConstant.pm.RFP_STATUS_DRAFT
                ? '/rfp-response/update'
                : '/rfp-response/insert';
            this.httpClientService
              .post<Response<RfpVendor>>(url, this.rfpRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService.confirmation(
                    this.translateService.instant(
                      'rfp-response.confirm.msg.submitSuccess'
                    ),
                    'rfp-response.confirm.title.submitSuccess',
                    null,
                    null,
                    'pvc pv-confetti',
                    true
                  );
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.rfpVendor.rfpResponseStatus.code =
                  this.global.appConstant.pm.RFP_STATUS_SUBMITTED;
                this.todo = 'view';
                this.formGroup.get('responseFile').setIsView(true);
                this.setStateReady();
              });
          }
        });
    }
  }
}
