import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { OnBoardingEditComponent } from './on-boarding-edit.component';
import { OnBoardingComponent } from './on-boarding.component';
const routeChildrens: Routes = [
  { path: 'due-diligence', loadChildren: () => import('./due-diligence/due-diligence.module').then(m => m.DueDiligenceModule) },
  { path: 'verification', loadChildren: () => import('./verification/verification.module').then(m => m.VerificationModule) },
  { path: 're-verification-certif-expired', loadChildren: () => import('./verification/verification.module').then(m => m.VerificationModule)},
  { path: 're-verification-tdr', loadChildren: () => import('./verification/verification.module').then(m => m.VerificationModule) },
  { path: 're-verification-non-tdr', loadChildren: () => import('./verification/verification.module').then(m => m.VerificationModule) },
  { path: 'interview', loadChildren: () => import('./interview/interview.module').then(m => m.InterviewModule) },
  { path: 'ots', loadChildren: () => import('./ots/ots.module').then(m => m.OTSModule) }
];
const routes: Routes = [
  { path: '', component: OnBoardingComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: OnBoardingEditComponent,
    data: { todo: 'edit', breadcrumb: 'on-boarding.breadcrumb.edit' },
    children: routeChildrens,
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: OnBoardingEditComponent,
    data: { breadcrumb: 'on-boarding.breadcrumb.detail'},
    children: routeChildrens
  },
  {
    path: 'edit/:token',
    component: OnBoardingEditComponent,
    data: { todo: 'edit', breadcrumb: 'on-boarding.breadcrumb.edit' },
    children: routeChildrens,
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [OnBoardingComponent, OnBoardingEditComponent]
})
export class OnBoardingModule {}
