import { Component } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  templateUrl: './app-widget-vendor-contract.component.html',
  styleUrls: ['./app-widget-vendor-contract.component.scss']
})
export class AppWidgetVendorContractComponent extends BaseComponentComponent {
  constructor() {
    super('app-widget-vendor-contracted');
  }

  public onInit(): void {
    this.setStateReady();
  }
}
