import { CurrencyPipe } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ContractDoc } from '../../bean/contract-doc';
import { ProcurementDoc } from '../../bean/procurement-doc';
import { ProcurementResult } from '../../bean/procurement-result';
import { VendorDoc } from '../../bean/vendor-doc';
import { RouteRequestModel } from '../../model/route-request-model';
import { AppTableComponent } from '../app-table/app-table.component';
import { FieldFormatEnum } from '../app-table/model/field-format.enum';
import { TableResponseModel } from '../app-table/model/table-response-model';
import { AppProcurementInformationViewResponse } from './app-procurement-information.response';

@Component({
  selector: 'app-procurement-information-view',
  templateUrl: './app-procurement-information-view.component.html'
})
export class AppProcurementInformationViewComponent extends BaseComponentComponent {
  @Input() public procurementVendorId: number;
  @Input() public contractId: number;
  @ViewChild('selectorProcurementDocument')
  public tableProcurementDocument: AppTableComponent;
  @ViewChild('selectorVendorLegalityDocument')
  public tableVendorLegalityDocument: AppTableComponent;
  @ViewChild('selectorProduct') public tableProduct: AppTableComponent;
  @ViewChild('selectorService') public tableService: AppTableComponent;

  public tableResponseProcurementDocument: TableResponseModel<ProcurementDoc>;
  public tableResponseVendorLegalityDocument: TableResponseModel<VendorDoc>;
  public tableResponseProduct: TableResponseModel<ProcurementResult>;
  public tableResponseService: TableResponseModel<ProcurementResult>;

  public totalProductCost: number;
  public totalServiceCost: number;
  public totalItemPrice: number;

  public procurementNumber: string;
  public procurementDate: Date;
  public procurementName: string;
  public procurementOrganization: string;
  public procurementType: string;
  public procurementMethod: string;
  public procurementAmount: number;
  public hpsAmount: number;
  public currencyCode: string;

  public vendorName: string;
  public vendorAddress: string;
  public vendorEmail: string;
  public vendorPhone: string;
  public vendorBankName: string;
  public vendorBankAccountNumber: string;
  public vendorBankAccountHolder: string;
  public vendorBankBranchName: string;

  public contractNumber: string;
  public contractStartDate: Date;
  public contractEndDate: Date;
  public contractDocList: ContractDoc[];

  public urlProcurementInformation =
    '/app-procurement-information-view/index-procurement-information';
  public urlContractAndProcurementInformation =
    '/app-procurement-information-view/index-contract-information';

  public appProcurementInformationViewResponse: AppProcurementInformationViewResponse = new AppProcurementInformationViewResponse();
  public CURRENCY_DIGITS_INFO: string;

  constructor(public currency: CurrencyPipe) {
    super('app-procurement-information-view');
  }

  public onInit(): void {
    this.getAndsetProcurementInformationData();
    this.buildTableResponseProcurementDocument();
    this.buildTableResponseProcurementItems();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public getAndsetProcurementInformationData(): void {
    this.httpClientService
      .post<AppProcurementInformationViewResponse>(
        this.contractId != null
          ? this.urlContractAndProcurementInformation
          : this.urlProcurementInformation,
        new RouteRequestModel(
          null,
          this.contractId != null ? this.contractId : this.procurementVendorId
        )
      )
      .subscribe(appProcurementInformationViewResponse => {
        this.appProcurementInformationViewResponse = appProcurementInformationViewResponse;
        if (appProcurementInformationViewResponse != null) {
          if (appProcurementInformationViewResponse.contract != null) {
            this.contractNumber = appProcurementInformationViewResponse.contract.number;
            this.contractStartDate =
              appProcurementInformationViewResponse.contract.startDate;
            this.contractEndDate =
              appProcurementInformationViewResponse.contract.endDate;
            this.contractDocList =
              appProcurementInformationViewResponse.contractDocList;
          }
          this.tableResponseProcurementDocument.page.records = this.appProcurementInformationViewResponse.procurementDocList;
          this.tableResponseProcurementDocument.page.totalRecords =
            this.appProcurementInformationViewResponse.procurementDocList &&
            this.appProcurementInformationViewResponse.procurementDocList.length;
          this.tableResponseProcurementDocument.reloadClient();

          this.tableResponseProduct.page.records = this.appProcurementInformationViewResponse.procurementResultProductList;
          this.tableResponseProduct.page.totalRecords =
            this.appProcurementInformationViewResponse.procurementResultProductList &&
            this.appProcurementInformationViewResponse.procurementResultProductList
              .length;
          this.tableResponseProduct.reloadClient();

          this.tableResponseService.page.records = this.appProcurementInformationViewResponse.procurementResultServiceList;
          this.tableResponseService.page.totalRecords =
            this.appProcurementInformationViewResponse.procurementResultServiceList &&
            this.appProcurementInformationViewResponse.procurementResultServiceList
              .length;
          this.tableResponseService.reloadClient();

          this.totalProductCost =
            appProcurementInformationViewResponse.totalProductCost;
          this.totalServiceCost =
            appProcurementInformationViewResponse.totalServiceCost;
          this.totalItemPrice = +this.totalProductCost + this.totalServiceCost;
          this.procurementNumber =
            appProcurementInformationViewResponse.procurement.number;
          this.procurementDate = appProcurementInformationViewResponse.procurement.procurementDate;
          this.procurementName = appProcurementInformationViewResponse.procurement.name;
          this.procurementOrganization =
            appProcurementInformationViewResponse.procurement.organization.name;
          this.procurementType =
            appProcurementInformationViewResponse.procurement.procurementType.name;
          this.procurementMethod =
            appProcurementInformationViewResponse.procurement.procurementMethod.name;
          this.procurementAmount =
            appProcurementInformationViewResponse.procurementVendor.amount;
          this.hpsAmount = appProcurementInformationViewResponse.procurement.hpsAmount;
          this.currencyCode =
            appProcurementInformationViewResponse.procurementVendor.currency.code;
        }
        this.setStateReady();
      });
  }

  public buildTableResponseProcurementDocument(): void {
    this.tableResponseProcurementDocument = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'file.fileName',
          header: 'table.column.procurementDocument',
          format: FieldFormatEnum.DownloadablePMFile,
          urlFile: 'file.uploadedFileName'
        }
      ]
    );
  }

  public buildTableResponseVendorLegalityDocument(): void {
    this.tableResponseVendorLegalityDocument = new TableResponseModel(
      this.moduleCode,
      [
        { field: 'documentInformation', header: 'table.column.name' },
        {
          field: 'docNumber',
          header: 'table.column.number',
          customClass: 'text-center'
        },
        { field: 'docPublisher', header: 'table.column.publisher' },
        {
          field: 'startDate',
          header: 'table.column.startDate',
          format: FieldFormatEnum.ShortDate,
          customClass: 'text-center'
        },
        {
          field: 'endDate',
          header: 'table.column.endDate',
          format: FieldFormatEnum.ShortDate,
          customClass: 'text-center'
        },
        {
          field: 'file.fileName',
          header: 'table.column.vendorLegalityDocument',
          format: FieldFormatEnum.DownloadableFile,
          urlFile: 'file.uploadedFileName'
        }
      ]
    );
  }

  public buildTableResponseProcurementItems(): void {
    this.tableResponseProduct = new TableResponseModel(this.moduleCode, [
      { field: 'procurementItem.item.name', header: 'table.column.itemName' },
      {
        field: 'procurementItem.item.uom.name',
        header: 'table.column.uom',
        customClass: 'text-center'
      },
      {
        field: 'procurementItem.item.itemCategory.name',
        header: 'table.column.category'
      },
      {
        field: 'itemQuantity',
        header: 'table.column.quantity',
        customClass: 'text-right',
        fn: this.setDecimal.bind(this)
      },
      {
        field: 'price',
        header: 'table.column.price',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'totalPrice',
        header: 'table.column.totalPrice',
        format: FieldFormatEnum.Currency
      }
    ]);

    this.tableResponseService = new TableResponseModel(this.moduleCode, [
      { field: 'procurementItem.item.name', header: 'table.column.itemName' },
      {
        field: 'procurementItem.item.uom.name',
        header: 'table.column.uom',
        customClass: 'text-center'
      },
      {
        field: 'procurementItem.item.itemCategory.name',
        header: 'table.column.category'
      },
      {
        field: 'itemQuantity',
        header: 'table.column.quantity',
        customClass: 'text-right',
        fn: this.setDecimal.bind(this)
      },
      {
        field: 'price',
        header: 'table.column.price',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'totalPrice',
        header: 'table.column.totalPrice',
        format: FieldFormatEnum.Currency
      }
    ]);
  }

  public setDecimal(field: number): string {
    return this.currency.transform(field, '', '', this.CURRENCY_DIGITS_INFO);
  }
}
