import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './popup-order-cancel.component.html'
})
export class PopupOrderCancelComponent extends BaseComponentComponent {
  @Output() onChange: EventEmitter<string> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {
    super('order');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      note: [null, Validators.required()]
    });
  }

  public doSubmit(): void {
    this.validate();

    if (this.formGroup.valid) {
        const note = this.formGroup.get('note').value;
        this.onChange.emit(note);
    }
  }
}
