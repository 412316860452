import { NgModule } from '@angular/core';
import { SharedModule } from '../core/shared/shared.module';
import { PromisePortalThemeModule } from '../themes/promise-portal/promise-portal-theme.module';
import { AppLoginFormModule } from './login/app-login-form.module';
import { PortalRoutingModule } from './portal-routing.module';
import { PortalComponent } from './portal.component';
@NgModule({
  imports: [
    PortalRoutingModule,
    SharedModule,
    AppLoginFormModule,
    PromisePortalThemeModule
  ],
  declarations: [PortalComponent]
})
export class PortalModule {}
