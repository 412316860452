<app-popup
  header="initiation.popup.procurementFailed.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <div class="popup-failed">
    <app-alert-x
      color="WARNING"
      *ngIf="isSubmit && (!isDownloaded || !formGroup.value.docList)"
    >
      {{ 'initiation.alert.msg.pleaseDownloadOffReportAndUpload' | translate }}
    </app-alert-x>

    <form class="form-horizontal" [formGroup]="formGroup" novalidate>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'initiation.form.letterNumber' | translate }}
        </label>
        <div class="col">
          <app-text-generated
            formControlName="letterNumber"
            maxLength="64"
            moduleCode="PROCUREMENT-FAILED"
            numberGeneratorCode="NUMBER"
          ></app-text-generated>
        </div>
      </div>

      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'initiation.form.letterDate' | translate }}
        </label>
        <div class="col">
          <app-date-picker-x formControlName="letterDate" [minDate]="minDate">
          </app-date-picker-x>
        </div>
      </div>

      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'initiation.form.stage' | translate }}
        </label>
        <div class="col">
          {{ procurement.workflowModelPrcs.name.replace('Approval - ', '') }}
        </div>
      </div>

      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'initiation.form.failedReason' | translate }}
        </label>
        <div class="col-sm-6">
          {{ failedReason }}
        </div>
      </div>

      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'initiation.form.officialReport' | translate }}
        </label>
        <div class="col" style="display: grid">
          <app-button (onClick)="doGenerate($event)">
            {{ 'initiation.button.generateAndDownloadReport' | translate }}
          </app-button>
          <small class="text-secondary">{{
            'initiation.button.thisFileIncludesOfficialReport' | translate
          }}</small>
        </div>
      </div>

      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'initiation.form.attachmentFile' | translate }}
        </label>
        <div class="col">
          <app-upload-x
            formControlName="docList"
            [fileUploader]="fileUploader"
            size="XL"
          ></app-upload-x>
        </div>
      </div>

      <ng-template #modalFooter let-activeModal>
        <div class="button-group button-group-center">
          <app-button
            color="SECONDARY"
            [outline]="true"
            (onClick)="activeModal.close(true)"
          >
            {{ 'app.button.cancel' | translate }}</app-button
          >
          <app-button (onClick)="doSubmit()">
            {{ 'app.button.submit' | translate }}</app-button
          >
        </div>
      </ng-template>
    </form>
  </div>
</app-popup>
