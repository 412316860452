import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Parameter } from '../../core/bean/parameter';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './parameter.component.html'
})
export class ParameterComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Parameter>;
  constructor(translateService: TranslateService) {
    super('parameter', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.column.code' },
      { field: 'valueType.name', header: 'table.column.type', customClass: 'text-center' },
      { field: 'moduleGroup.name', header: 'table.column.group', customClass: 'text-center' },
      { field: 'value', header: 'table.column.value', customClass: 'white-space-normal' },
      { field: 'description', header: 'table.column.description', customClass: 'white-space-normal' }
    ]);
  }

  public doEdit(parameter: Parameter): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('parameterId', parameter.id);
    this.router.navigate(['/pages/parameter/edit']);
  }
}
