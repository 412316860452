import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BackTopComponent } from './components/back-top/back-top.component';
import { LayoutModule } from './layout/layout.module';
import { PromiseThemeComponent } from './promise-theme.component';
@NgModule({
  imports: [LayoutModule, CommonModule],
  declarations: [PromiseThemeComponent, BackTopComponent],
  exports: [PromiseThemeComponent]
})
export class PromiseThemeModule {}
