<app-popup>
  <div class="text-center">
    <br />
    <div
      class="pvc pv-trophy"
      style="width: 100px; height: 100px; margin: 0 auto"
    ></div>
    <br />
    <h3 class="title">{{
      'vendor-designation.popupWinnerConfirmation.title' | translate
    }}</h3>
    <div class="description pt-3">
      {{
        'vendor-designation.popupWinnerConfirmation.description' | translate
      }}</div
    >
  </div>
  <div class="vendor-winner">
    <app-lazy-load
      (onScrollEnd)="onScrollend()"
      [isLoading]="formLoading"
      maxHeight="150px"
    >
      <ul>
        <ng-container>
          <li
            class="list-vendor"
            *ngFor="let procurementVendor of procurementVendorList"
          >
            <div class="px-3 font-weight-bold">
              {{ procurementVendor.vendor.name }}
            </div>
            <div
              class="list-item px-4"
              *ngFor="let item of procurementVendor.itemList"
            >
              {{ item }}
            </div>
          </li>
        </ng-container>
      </ul>
    </app-lazy-load>
  </div>
  <div class="button-group button-group-center">
    <app-button
      color="SECONDARY"
      [outline]="true"
      (onClick)="activeModal.close(true)"
      >{{ 'app.button.cancel' | translate }}</app-button
    >
    <app-button color="PRIMARY" (onClick)="doSubmit()">{{
      'vendor-designation.button.yes' | translate
    }}</app-button>
  </div>
</app-popup>
