import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { CatalogAttributeGroup } from '../../../core/bean/catalog-attribute-group';
import { CatalogGroupTemplate } from '../../../core/bean/catalog-group-template';
import { CatalogSpecification } from '../../../core/bean/catalog-specification';
import { Currency } from '../../../core/bean/currency';
import { FormObjectModel } from '../../../core/components/app-form-object/model/form-object-model';
import { OptionListModel } from '../../../core/model/option-list-model';
import { TabModel } from '../../../core/model/tab/tab-model';
import { ManageCatalogDTO } from '../dto/manage-catalog.dto';
@Component({
  templateUrl: './specification.component.html'
})
export class SpecificationComponent extends BaseModuleComponent {
  userId = this.global.userSession.user.id;
  public manageCatalogDTO: ManageCatalogDTO = new ManageCatalogDTO();
  public optionList: OptionListModel<CatalogAttributeGroup> = new OptionListModel();
  public specificationTab: TabModel;
  public isTodoView;
  public isViewFormObject = false;
  public catalogSpecificationList: CatalogSpecification[] = [];
  public formObjectModel: FormObjectModel<CatalogGroupTemplate>;
  public catalogSpecification: CatalogSpecification = new CatalogSpecification();
  public currencyList: Currency[] = [];
  public isVendor: boolean;
  public isOther: boolean;
  constructor(translateService: TranslateService) {
    super('manage-catalog', translateService);
  }

  onInit(): void {
    this.setCatalog();
    this.setFormGroup();
    this.setOptionListModel();
    this.setStateReady();
  }

  public setOptionListModel(): void {
    this.optionList.setRequestValues(
      this.manageCatalogDTO.catalogAttributeGroupList
    );
  }

  public setCatalog(): void {
    this.specificationTab = this.global.routerParams
      .get('tabResponse')
      .tabs.get('SPECIFICATION');
    this.isTodoView = this.global.routerParams.get('tabResponse').isView;
    this.manageCatalogDTO = this.global.routerParams.get('manageCatalogDTO');
    this.formObjectModel = this.global.routerParams.get('formObjectModel'); /* gak ada globalparam ini */
    this.formGroup = this.global.routerParams.get('formGroup');
    this.currencyList = this.global.routerParams.get('currencyList');
  }
  public setFormGroup(): void {
    this.formGroup.value.isViewFormObject = true;

    this.formGroup.patchValue({
      isViewFormObject: true
    });
    this.isVendor = this.global.userSession.vendor? true: false;
    this.isOther = this.isVendor? false : this.global.userSession.activeUserRole.role.type 
    === this.global.appConstant.core.ROLE_TYPE_OTHER? true: false;

    if(this.isOther){
      this.specificationTab.setIsView(true);
      this.formGroup.value.formObjectModel.formGroup.setIsView(true);
    }else{
      this.specificationTab.setIsView(this.isTodoView);
      this.formGroup.value.formObjectModel.formGroup.setIsView(this.isTodoView);
    }

    if (this.manageCatalogDTO.catalogSpesificationList.length !== 0) {
      this.log.debug(this.formObjectModel);

      const catalogAttrSpecsIdList: number[] = this.manageCatalogDTO.catalogSpesificationList.map(catalogSpec => catalogSpec.catalogAttribute.id);

      const newCatalogGroupTemplList: CatalogGroupTemplate[] = this.manageCatalogDTO.catalogGroupTemplateList.filter(catalogGroupTempl =>
        !catalogAttrSpecsIdList.includes(catalogGroupTempl.catalogAttribute.id));

      this.log.debug(newCatalogGroupTemplList);
      newCatalogGroupTemplList.forEach(newCatalogGroupTempl => {
        const catalogSpesification = new CatalogSpecification();
        catalogSpesification.catalog = this.manageCatalogDTO.catalog;
        catalogSpesification.catalogAttribute = newCatalogGroupTempl.catalogAttribute;
        catalogSpesification.value = null;
        this.manageCatalogDTO.catalogSpesificationList.push(catalogSpesification);
      });

      this.setFormObjectModel(this.manageCatalogDTO.catalogSpesificationList);
    }
  }

  public setFormObjectModel(model: any): void {
    if(this.isOther){
      this.formObjectModel = FormObjectModel.create(model).setIsView(true);
    }else{
      this.formObjectModel = FormObjectModel.create(model).setIsView(this.isTodoView);
    }
    
    this.formGroup.patchValue({
      formObjectModel: this.formObjectModel
    });
    this.manageCatalogDTO.catalog.catalogAttributeGroup = this.formGroup.value.attributeGroup;

    this.log.debug(this.formGroup);
  }

  public doOnChange(): void {
    this.manageCatalogDTO.catalogSpesificationList = [];
    this.formGroup.value.formObjectModel
      .getRequestValues()
      .forEach(catalogGroupTemplate => {
        const catalogSpecification: CatalogSpecification = new CatalogSpecification();
        catalogSpecification.catalogAttribute =
          catalogGroupTemplate.catalogAttribute;
        // tslint:disable-next-line: no-string-literal
        catalogSpecification.value = JSON.stringify(
          catalogGroupTemplate.catalogAttribute.formObject['value']
        );

        this.manageCatalogDTO.catalogSpesificationList.push(
          catalogSpecification
        );
      });
  }

}
