<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="home-slide.title" detail="app.action.{{ todo }}"
      [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'home-slide.form.imgFile' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-upload formControlName="file" [fileUploader]="fileUploader">
            </app-upload>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'home-slide.form.title' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="title" maxLength="512">
            </app-text-area>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'home-slide.form.body' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="body">
            </app-text-area>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'home-slide.form.referenceLink' | translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="referenceLink" maxLength="256">
            </app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'home-slide.form.status' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box formControlName="isActive"
              [optionList]="homeSlideStatusOptionList"></app-combo-box>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button color="SECONDARY" (onClick)="doCancel()"
            [disabled]="formSaving">{{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">{{
            'app.button.save' | translate }}</app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>