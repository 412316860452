import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { FileUploader } from '../../core/components/upload';
@Component({
  templateUrl: './catalog-attribute-group-import.component.html'
})
export class CatalogAttributeGroupImportComponent extends BaseModuleComponent {
  public fileUploader: FileUploader = new FileUploader('/employee/', 'news.form.headerImage', 'EMPLOYEE_ALL');

  constructor(translate: TranslateService) {
    super('catalog-attribute-group', translate);
    Object.assign(this, this.global.routerParams);
  }

  onInit(): void {
    this.setDataFromRouterParam();
    this.buildFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      file: [null]
    });
}

  public setDataFromRouterParam(): void {
    this.todo = this.global.routerParams.get('todo');
  }

  public doCancel(): void {
    this.router.navigate(['/pages/catalog-attribute-group']);
  }
}
