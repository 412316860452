import { Lang } from '../../core/bean/lang';
import { Tc } from '../../core/bean/tc';
import { TcDetail } from '../../core/bean/tc-detail';
import { TermType } from '../../core/bean/term-type';
import { Translation } from '../../core/bean/translation';

export class TcResponse {
  translationList: Translation[];
  langList: Lang[];
  tc: Tc;
  tcDetailList: TcDetail[];
  termTypeList: TermType[];
}
