<app-popup header="app-popup-budget.title">
    <app-table [tableResponse]="tableResponse" [isServerSide]="true"
        stringUrl="/app-popup-budget/index">
        <ng-template #actionButtons let-rowData>
            <app-button *ngIf="rowData.remainingAmount > totalPrice"
                (onClick)="doChoose(rowData)">
                {{ 'app.button.select' | translate}}
            </app-button>
        </ng-template>
    </app-table>
</app-popup>