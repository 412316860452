import { BaseEntity } from '../../base/base-entity';
import { PaymentInvoice } from '../../bean/payment-invoice';
import { PaymentWorklistStatus } from '../../bean/payment-worklist-status';
import { VendorBank } from '../../bean/vendor-bank';
import { PaymentActivity } from './../../bean/payment-activity';
import { PaymentBilling } from './../../bean/payment-billing';
import { PaymentProof } from './../../bean/payment-proof';
import { PaymentStatus } from './../../bean/payment-status';
import { PaymentVoucher } from './../../bean/payment-voucher';
import { Vendor } from './../../bean/vendor';

export class PaymentViewEntity extends BaseEntity {
  paymentInvoice: PaymentInvoice = new PaymentInvoice();
  paymentStatus: PaymentStatus = new PaymentStatus();
  paymentBilling: PaymentBilling = new PaymentBilling();
  paymentVoucher: PaymentVoucher = new PaymentVoucher();
  paymentActivity: PaymentActivity = new PaymentActivity();
  paymentProof: PaymentProof = new PaymentProof();
  vendorBank: VendorBank = new VendorBank();
  paymentWorklistStatus: PaymentWorklistStatus;
  vendor: Vendor = new Vendor();
  title: string;
  prcsCode: string;
  workflowModelPrcsCode: string;
}
