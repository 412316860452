import { ChartOptions } from 'chart.js';
export const doughnutChartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: 'right',
    labels: {
      usePointStyle: true
    }
  }
};
