import { EventEmitter } from '@angular/core';
import { UploaderOptions } from '../interface/uploader-options';
import { UploaderFile } from './uploader-file';
import { UploaderFileError } from './uploader-file-error';
export class Uploader {
  uploaderFileList: Array<UploaderFile> = new Array();
  trash: Array<UploaderFile> = new Array();
  options: UploaderOptions = new UploaderOptions();
  valueChanges: EventEmitter<Uploader> = new EventEmitter();
  totalUploaded = 0;
  error: UploaderFileError;
  isFormControlChanges: boolean;
  isHandleError = true;
  constructor(
    public stringUrl: string,
    public fileTypeCode?: string,
    options?: UploaderOptions
  ) {
    this.options = options || { formDataName: 'file' };
    this.options.formDataName = this.options.formDataName || 'file';
  }

  removeAt(index: number): void {
    this.uploaderFileList.splice(index, 1);
  }

  roolback(): void {
    this.uploaderFileList.forEach((uploaderFile, index) => {
      if (uploaderFile.error && uploaderFile.uploadFileChange) {
        this.uploaderFileList[index] =
          uploaderFile.uploadFileChange.uploaderFile;
      } else if (uploaderFile.error && !uploaderFile.uploadFileChange) {
        this.removeAt(index);
      }
    });
  }

  setError(error: UploaderFileError): void {
    this.error = error;
  }
}
