<app-popup
  header="manage-catalog.item-contract-detail.popup.title"
  [isShowHeaderCloseButton]="true"
  [isLoading]="formLoading"
  size="XL"
>
  <app-alert-x>
    {{ 'manage-catalog.alert.message.toggle' | translate }}
  </app-alert-x>
  <div class="row main-row">
    <div class="col-lg-12">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{
            'manage-catalog.popup.form.price' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ catalogRegion.price | resolveNumberToCurrency }} IDR
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 control-label">{{
            'manage-catalog.popup.form.isBulkPrice' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            <app-toggle-switch
              formControlName="isBulkPrice"
              (onChange)="doBulkValidation()"
            ></app-toggle-switch>
          </div>
        </div>

        <ng-container *ngIf="formGroup.get('isBulkPrice').value">
          <div>
            <h3 class="separator"></h3>
          </div>

          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="content">
                <div class="content-body">
                  <div
                    class="text-center table-responsive float-none"
                    [ngStyle]="{ margin: '0 auto' }"
                  >
                    <table
                      class="table table-striped box-shadow"
                      aria-describedby="Table"
                    >
                      <thead>
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col" class="fit text-right">{{
                            'manage-catalog.popup.form.minimumOrderTotal'
                              | translate
                          }}</th>
                          <th scope="col" class="fit text-right">{{
                            'manage-catalog.popup.form.discount' | translate
                          }}</th>
                          <th scope="col" class="fit text-right">{{
                            'manage-catalog.popup.form.discountedPrice'
                              | translate
                          }}</th>
                          <th scope="col" *ngIf="!formGroup.isView">Action</th>
                        </tr>
                      </thead>
                      <tbody formArrayName="catalogRegionBulkPriceList">
                        <tr
                          [formGroupName]="i"
                          *ngFor="
                            let catalogRegionBulkPrice of formGroup.get(
                              'catalogRegionBulkPriceList'
                            ).controls;
                            let i = index
                          "
                        >
                          <ng-container
                            *ngIf="
                              catalogRegionBulkPrice.value.crudOperation !==
                              global.appConstant.core.CRUD_OPERATION_DELETE
                            "
                          >
                            <td>
                              {{
                                resolveTableIndex(
                                  i,
                                  formGroup.get('catalogRegionBulkPriceList').controls
                                )
                              }}.
                            </td>
                            <td
                              [ngClass]="formGroup.isView ? 'text-right' : ''"
                            >
                              <div>
                                <app-count
                                  formControlName="minimumOrderTotal"
                                  maxLength="6"
                                  [min]="
                                    formGroup.get('catalogRegionBulkPriceList')
                                      .controls[i - 1]
                                      ? formGroup
                                          .get('catalogRegionBulkPriceList')
                                          .controls[i - 1].get(
                                            'minimumOrderTotal'
                                          ).value + 1
                                      : 1
                                  "
                                  (onInput)="
                                    onInputMinimumOrderTotal(
                                      catalogRegionBulkPrice,
                                      i
                                    )
                                  "
                                  (onChange)="
                                    onInputMinimumOrderTotal(
                                      catalogRegionBulkPrice,
                                      i
                                    )
                                  "
                                >
                                </app-count>
                              </div>
                            </td>
                            <td
                              [ngClass]="formGroup.isView ? 'text-right' : ''"
                            >
                              <div>
                                <app-text-field
                                  size="XL"
                                  formControlName="discount"
                                  type="decimal"
                                  (onInput)="
                                    onInputDiscount(catalogRegionBulkPrice, i)
                                  "
                                >
                                </app-text-field>
                              </div>
                            </td>
                            <td>
                              <div class="text-right">
                                {{
                                  catalogRegionBulkPrice.get('discountedPrice').value
                                    | resolveNumberToCurrency
                                }}
                                IDR
                              </div>
                            </td>
                            <td *ngIf="!formGroup.isView">
                              <app-button
                                size="TN"
                                color="DANGER"
                                (onClick)="doDelete(i)"
                                title="Remove"
                              >
                                <em class="fas fa-trash"></em>
                              </app-button>
                            </td>
                          </ng-container>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!formGroup.isView">
            <div class="col-12 mt-2 text-center">
              <app-button
                size="XL"
                minWidth="100%"
                (onClick)="doAdd()"
                [disabled]="disableAddBulkPriceButton"
                ><em class="pir pi-plus-circle"></em>
                {{ 'manage-catalog.button.addMore' | translate }}</app-button
              >
            </div>
          </div>
        </ng-container>

        <ng-template #modalFooter let-activeModal>
          <div class="button-group button-group-center">
            <app-button
              color="SECONDARY"
              [outline]="true"
              (onClick)="activeModal.close(true)"
            >
              {{ 'app.button.cancel' | translate }}
            </app-button>
            <app-button
              (onClick)="doSave()"
              [disabled]="formSaving"
              *ngIf="!formGroup.isView"
            >
              {{ 'app.button.save' | translate }}
            </app-button>
          </div>
        </ng-template>
      </form>
    </div>
  </div>
</app-popup>
