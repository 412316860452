import { BaseEntity } from '../base/base-entity';
import { Activity } from '../bean/activity';
import { Payment } from '../bean/payment';
import { User } from '../bean/user';

export class PaymentActivityEntity extends BaseEntity {
  picUser: string;
  createdByUser: User;

  payment: Payment;
  activity: Activity;
  date: Date;
}
