import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorAttributeEditComponent } from './vendor-attribute-edit.component';
import { VendorAttributeComponent } from './vendor-attribute.component';
export const routes = [
  { path: '', component: VendorAttributeComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: VendorAttributeEditComponent,
    data: { breadcrumb: 'vendor-attribute.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [VendorAttributeComponent, VendorAttributeEditComponent]
})
export class VendorAttributeModule {}
