<app-popup header="budget-allocation.popup.title">
  <app-table [tableResponse]="tableResponse" [isServerSide]="true"
    stringUrl="/budget-allocation/coa/detail/organization"
    customData="{{ coaId }}">
  </app-table>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
        'app.button.close' | translate }}</app-button>
    </div>
  </ng-template>
</app-popup>