import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { SegmentationComponent } from './segmentation.component';
const routes = [
  {
    path: '',
    component: SegmentationComponent,
    data: { breadcrumb: '' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [SegmentationComponent]
})
export class SegmentationModule {}
