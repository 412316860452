import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ModuleGroup } from '../../core/bean/module-group';
import { Notification } from '../../core/bean/notification';
import { NotificationTemplate } from '../../core/bean/notification-template';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: 'notification-template.component.html'
})
export class NotificationTemplateComponent extends BaseModuleComponent {
  public moduleGroupList: ModuleGroup[];
  public tableResponse: TableResponseModel<Notification>;
  constructor(translateService: TranslateService) {
    super('notification-template', translateService);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setModuleListGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      moduleGroupId: [null]
    });
  }

  public setModuleListGroup(): void {
    this.httpClientService
      .get<ModuleGroup[]>('/notification-template/module-group-list', {})
      .subscribe(moduleGroupList => {
        this.moduleGroupList = moduleGroupList;
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      {
        field: 'moduleGroup.name',
        header: 'table.column.moduleGroup',
        customClass: 'text-center'
      },
      { field: 'name', header: 'table.column.name' },
      {
        field: 'description',
        header: 'table.column.description',
        customClass: 'white-space-normal'
      },
      {
        field: 'isActive',
        header: 'table.column.status',
        datamap:
          '{"false" : "' +
          this.translateService.instant('notification-template.nonactive') +
          '" , "true" : "' +
          this.translateService.instant('notification-template.active') +
          '" }'
      }
    ]);
  }

  public doEdit(notification: Notification): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('notificationId', notification.id);
    this.router.navigate(['/pages/notification-template/edit']);
  }

  public doOnChangeModuleGroup(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doDelete(notificationTemplate: NotificationTemplate): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<NotificationTemplate>>(
              '/notification-template/delete',
              notificationTemplate
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
