<div class="row main-row">
  <div class="col-lg-12">
    <dashboard-widget-welcome-card></dashboard-widget-welcome-card>
  </div>
  <div class="col-lg-12">
    <dashboard-widget
      [model]="widgetModel"
      [isLoading]="formLoading"
    ></dashboard-widget>
  </div>
</div>
