import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CustomValidatorError } from '../../core/custom-validator-error';
export class NpwpValidator {
  public static npwp(message?: string): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors => {
      const value = abstractControl.value;
      if (value) {
        const regex = /^\d{2}[.]\d{3}[.]\d{3}[.]\d{1}-\d{3}[.]\d{3}/g;
        const isValidNPWP = regex.test(String(value).toLowerCase());
        if (!isValidNPWP) {
          return CustomValidatorError.create('npwp', message);
        }
      }
      return null;
    };
  }
}
