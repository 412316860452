import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppDeliverableItemModule } from 'src/app/core/components/app-deliverable-item/app-deliverable-item.module';
import { AppPaymentRequestInformationViewModule } from 'src/app/core/components/app-payment-request-information-view/app-payment-request-information-view.module';
import { AppPopupBillingMemoBudgetDetailService } from 'src/app/core/components/app-popup/app-popup-billing-memo-budget-detail/app-popup-billing-memo-budget-detail.service';
import { AppPopupChooseBudgetModule } from 'src/app/core/components/app-popup/app-popup-choose-budget/app-popup-choose-budget.module';
import { AppPopupChooseBudgetService } from 'src/app/core/components/app-popup/app-popup-choose-budget/app-popup-choose-budget.service';
import { AppProcurementInformationViewModule } from 'src/app/core/components/app-procurement-information-view/app-procurement-information-view.module';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AppPopupBillingMemoItemDetailComponent } from './app-popup-billing-memo-item-detail.component';
import { AppPopupSyncBillingMemoComponent } from './app-popup-sync-billing-memo.component';
import { BillingMemoEditAddComponent } from './billing-memo-edit-add.component';
import { BillingMemoComponent } from './billing-memo.component';

export const routes = [
  {
    path: '',
    component: BillingMemoComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'edit',
    component: BillingMemoEditAddComponent,
    data: { breadcrumb: 'billing-memo.breadcrumb.edit' }
  },
  {
    path: 'view',
    component: BillingMemoEditAddComponent,
    data: { breadcrumb: 'billing-memo.breadcrumb.view' }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppProcurementInformationViewModule,
    AppPopupChooseBudgetModule,
    AppDeliverableItemModule,
    AppPaymentRequestInformationViewModule
  ],
  declarations: [
    BillingMemoComponent,
    BillingMemoEditAddComponent,
    AppPopupBillingMemoItemDetailComponent,
    AppPopupSyncBillingMemoComponent
  ],
  exports: [
    BillingMemoComponent,
    BillingMemoEditAddComponent,
    AppPopupBillingMemoItemDetailComponent,
    AppPopupSyncBillingMemoComponent
  ],
  entryComponents: [
    AppPopupBillingMemoItemDetailComponent,
    AppPopupSyncBillingMemoComponent
  ],
  providers: [
    AppPopupChooseBudgetService,
    AppPopupBillingMemoBudgetDetailService
  ]
})
export class BillingMemoModule {}
