import { NgModule } from '@angular/core';
import { AppPhoneComponent } from './app-phone.component';
import { AppComboBoxModule } from '../app-combo-box/app-combo-box.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { VendorModule } from '../../vendor/vendor.module';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
    imports: [ VendorModule, AppComboBoxModule, AppTooltipWrapperModule, DirectivesModule ],
    declarations: [ AppPhoneComponent ],
    exports: [ AppPhoneComponent ]
})

export class AppPhoneModule { }
