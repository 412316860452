import { Component, ViewEncapsulation } from '@angular/core';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { File } from '../../../core/bean/file';
import { AppPopupService } from '../../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../../core/components/app-table/model/table-response-model';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { RouteRequestModel } from '../../../core/model/route-request-model';
import { WorkPlanPaymentDTO } from '../dto/work-plan-payment.dto';
import { WorkPlanPaymentDetailPopupComponent } from './work-plan-payment-detail-popup.component';
@Component({
  templateUrl: 'work-plan-payment-edit-add.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class WorkPlanPaymentComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<File>;
  public workPlanId = this.global.routerParams.get('workPlanId');
  public routeList: string[] = [
    'work-plan',
    'purchase-request',
    'procurement',
    'contract',
    'purchase-order',
    'delivery-received',
    'payment'
  ];
  public workPlanRealizeId: number;
  public workPlanPaymentDTOList: WorkPlanPaymentDTO[] = new Array();
  public url = '';
  public totalPriceList: number[] = new Array();
  public totalPaidList: number[] = new Array();
  public remainingPaymentList: number[] = new Array();
  public itemNameListList: string[][] = new Array();
  public file: File;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('work-plan-payment', translateService);
  }

  onInit(): void {
    this.workPlanRealizeId = this.global.routerParams.get('workPlanRealizeId');

    this.httpClientService
      .post<WorkPlanPaymentDTO[]>(
        '/work-plan-payment/add-edit',
        new RouteRequestModel(this.todo, this.workPlanRealizeId)
      )
      .subscribe(workPlanPaymentDTOList => {
        this.workPlanPaymentDTOList = workPlanPaymentDTOList;
        Object.keys(this.workPlanPaymentDTOList).forEach(index => {
          this.totalPriceList.push(0);
          this.totalPaidList.push(0);
          this.remainingPaymentList.push(0);
          this.doCalculate(+index);
        });
        this.setStateReady();
      });
  }

  public onTabChange(event): void {
    this.router.navigate([
      '/pages/work-plan-realize/' + this.routeList[event.index]
    ]);
  }

  public doAddEditPurchaseOrderPaymentDetail(
    purchaseOrderPayment,
    DTOIndex,
    paymentIndex
  ): void {
    const workPlanPaymentDTO = this.workPlanPaymentDTOList[DTOIndex];
    this.appPopupService
      .open(WorkPlanPaymentDetailPopupComponent, {
        purchaseOrderPayment,
        workPlanPaymentDTO,
        paymentIndex
      })
      .subscribe((data: WorkPlanPaymentDTO) => {
        if (data != null) {
          this.workPlanPaymentDTOList[DTOIndex] = data;
          this.doCalculate(DTOIndex);
        }
      });
  }

  public doDelete(DTOIndex: number, paymentIndex: number): void {
    const POPaymentList =
      this.workPlanPaymentDTOList[DTOIndex].purchaseOrderPaymentList[
        paymentIndex
      ];
    POPaymentList.purchaseOrderPaymentDetailList.forEach(
      (POPaymentDetail, index) => {
        this.workPlanPaymentDTOList[
          DTOIndex
        ].purchaseOrder.purchaseOrderItemList[index].paymentAmount -=
          +POPaymentDetail.paymentAmount;
      }
    );
    this.doCalculate(DTOIndex);
    this.workPlanPaymentDTOList[DTOIndex].purchaseOrderPaymentList.splice(
      paymentIndex,
      1
    );
  }

  public doCalculate(indexDTO: number): void {
    let totalPrice = 0;
    let totalPaid = 0;
    let remainingPayment = 0;
    this.workPlanPaymentDTOList[
      indexDTO
    ].purchaseOrder.purchaseOrderItemList.forEach(poItem => {
      totalPaid = +totalPaid + +poItem.paymentAmount;
      totalPrice = +totalPrice + +poItem.purchaseRequestItem.totalPriceRealize;
    });
    remainingPayment = +totalPrice - +totalPaid;
    this.totalPriceList[indexDTO] = totalPrice;
    this.totalPaidList[indexDTO] = totalPaid;
    this.remainingPaymentList[indexDTO] = remainingPayment;
  }

  public doSave(): void {
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.url = '/work-plan-payment/insert-update';
          this.workPlanPaymentDTOList[0].workPlanId = this.workPlanId;
          this.workPlanPaymentDTOList[0].workPlanRealizeId =
            this.workPlanRealizeId;
          this.httpClientService
            .post<Response<WorkPlanPaymentDTO[]>>(
              this.url,
              this.workPlanPaymentDTOList
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                if (response.body == null) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.setStateReady();
                  this.router.navigate(['/pages/work-plan']);
                } else {
                  this.global.alertService.showSuccessSaving();
                  this.workPlanPaymentDTOList = response.body;
                  this.setStateReady();
                }
              } else {
                this.setStateReady();
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doPrevious(): void {
    const data = { index: 4 };
    this.onTabChange(data);
  }
}
