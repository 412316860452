import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { PaymentStatus } from 'src/app/core/bean/payment-status';
import { AppPopupChooseBillService } from 'src/app/core/components/app-popup/app-popup-choose-bill/app-popup-choose-bill.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { PaymentBillingView } from 'src/app/core/view/entity/bean/payment-billing-view';
@Component({
  templateUrl: './internal-payment-request.component.html'
})
export class InternalPaymentRequestComponent
  extends BaseModuleComponent
  implements OnInit
{
  public tableResponse: TableResponseModel<PaymentBillingView>;
  public paymentStatusOptionList: OptionListModel<PaymentStatus> =
    new OptionListModel(true);

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupChooseBillService: AppPopupChooseBillService
  ) {
    super('internal-payment-request', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.buildFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      fromDate: [null],
      untilDate: [null],
      paymentStatusList: [null]
    });

    this.httpClientService
      .get<PaymentStatus>('/internal-payment-request/get-option-list', {})
      .subscribe((response: PaymentStatus[]) => {
        this.paymentStatusOptionList.setRequestValues(response);

        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'paymentInvoice.code',
          header: 'table.column.invoiceNumber',
          plugins: {
            name: 'hyperlink',
            onClick: this.doClick.bind(this)
          }
        },
        {
          field: 'paymentInvoice.invoiceDate',
          header: 'table.column.invoiceDate',
          plugins: { name: 'date' }
        },
        {
          field: 'code',
          header: 'table.column.orderNumber'
        },
        {
          field: 'title',
          header: 'table.column.title'
        },
        {
          field: 'amount',
          header: 'table.column.requestedBills',
          plugins: { name: 'default', type: 'currency' },
          className: 'text-right'
        },
        {
          field: 'payment.workflowModelPrcs.name',
          header: 'table.column.stage',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              if (
                tablePlugin.row.record.payment.paymentStatus.code ===
                this.global.appConstant.cm.PAYMENT_STATUS_PAYMENT_PROCESS
              ) {
                return this.translateService.instant(
                  'internal-payment-request.stage.paymentMonitoring'
                );
              } else if (
                tablePlugin.row.record.payment.paymentStatus.code ===
                this.global.appConstant.cm.PAYMENT_STATUS_CLOSED
              ) {
                return this.translateService.instant(
                  'internal-payment-request.stage.paymentHistory'
                );
              } else {
                return tablePlugin.row.record.payment.workflowModelPrcs?.name;
              }
            }
          }
        },
        {
          field: 'payment.paymentStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              DRAFT: 'SECONDARY',
              WAITING_APPROVAL: 'WARNING',
              REVISION: 'FEEDBACK',
              WAITING_FOR_REVIEW: 'WARNING',
              WAITING_CONFIRMATION: 'WARNING',
              PAYMENT_PROCESS: 'PROGRESS',
              ON_PROGRESS: 'PROGRESS',
              CLOSED: 'DANGER'
            },
            colorField: 'payment.paymentStatus.code'
          }
        }
      ],
      { checkBoxFn: this.isShow.bind(this) }
    );
  }

  public isShow(record: PaymentBillingView): boolean {
    let isStatusDraft = false;
    if (
      record.payment.paymentStatus.code !==
      this.global.appConstant.cm.PAYMENT_STATUS_DRAFT
    ) {
      isStatusDraft = false;
    } else {
      isStatusDraft = true;
    }
    return isStatusDraft;
  }

  public doAddBill(): void {
    this.appPopupChooseBillService.open(true).subscribe(paymentBilling => {
      this.log.debug(paymentBilling);
      this.global.routerParams.clear();
      this.global.routerParams.set('todo', 'add');
      this.global.routerParams.set('paymentBillingId', paymentBilling.id);
      this.global.routerParams.set(
        'sowPaymentTermId',
        paymentBilling.sowPaymentTerm.id
      );
      this.global.routerParams.set('paymentBilling', paymentBilling);
      this.global.routerParams.set(
        'urlBackOutside',
        '/pages/internal-payment-request'
      );
      this.router.navigate(['/pages/internal-payment-request/add']);
    });
  }
  public doClick(paymentBilling: PaymentBillingView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('paymentId', paymentBilling.payment.id);
    this.global.routerParams.set('paymentBillingId', paymentBilling.id);
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/internal-payment-request'
    );

    if (
      paymentBilling.payment.paymentStatus.code ===
        this.global.appConstant.cm.PAYMENT_STATUS_DRAFT ||
      paymentBilling.payment.paymentStatus.code ===
        this.global.appConstant.cm.PAYMENT_STATUS_REVISED ||
      paymentBilling.payment.paymentStatus.code ===
        this.global.appConstant.cm.PAYMENT_STATUS_REVISION
    ) {
      this.global.routerParams.set('todo', 'edit');

      this.router.navigate(['/pages/internal-payment-request/edit']);
    } else {
      this.global.routerParams.set('todo', 'view');
      this.router.navigate(['/pages/internal-payment-request/detail']);
    }
  }

  public onChangeFilter(): void {
    if (this.formGroup.value.date) {
      this.formGroup.patchValue({
        fromDate: this.formGroup.value.date.from,
        untilDate: this.formGroup.value.date.to
      });
    }
    this.tableResponse?.setCustomData(this.formGroup.value);
    this.tableResponse?.reload();
    this.formGroup.get('fromDate').reset();
    this.formGroup.get('untilDate').reset();
  }

  public doDelete(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<PaymentBillingView[]>>(
              '/internal-payment-request/delete',
              event.selectedRecordList
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }
}
