/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */

export class ResponseStatusModel {
  public static readonly OK = 'OK';
  public static readonly ERROR = 'ERROR';
}
