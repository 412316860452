import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppApprovalLogModule } from '../app-approval-log/app-approval-log.module';
import { AppApprovalPathXModule } from '../app-approval-path-x';
import { AppCardModule } from '../app-card/app-card.module';
import { AppApprovalMonitoringComponent } from './app-approval-monitoring.component';

@NgModule({
  imports: [
    VendorModule,
    AppApprovalPathXModule,
    AppApprovalLogModule,
    AppCardModule
  ],
  declarations: [AppApprovalMonitoringComponent],
  exports: [AppApprovalMonitoringComponent]
})
export class AppApprovalMonitoringModule {}
