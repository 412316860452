import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppAgreementTypeModule } from 'src/app/core/components/app-agreement-type/app-agreement-type.module';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppCatalogDetailInfoModule } from 'src/app/core/components/app-catalog-detail-info/app-catalog-detail-info.module';
import { AppCatalogInfoModule } from 'src/app/core/components/app-catalog-info/app-catalog-info.module';
import { AppCommitteeModule } from 'src/app/core/components/app-committee/app-committee.module';
import { AppItemRequestModule } from 'src/app/core/components/app-item-request/app-item-request.module';
import { AppSimilarProductModule } from 'src/app/core/components/app-similar-product/app-similar-product.module';
import { AppVendorCatalogInfoModule } from 'src/app/core/components/app-vendor-catalog-info/app-vendor-catalog-info.module';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppOfficialReportModule } from '../../core/components/app-official-report/app-official-report.module';
import { AppUploadDocumentTableXModule } from '../../core/components/app-upload-document-table-x/app-upload-document-table-x.module';
import { SharedModule } from '../../core/shared/shared.module';
import { DirectPurchasePopupAddVendorComponent } from './direct-purchase-popup-add-vendor.component';
import { DirectPurchasePopupSelectVendorComponent } from './direct-purchase-popup-select-vendor.component';
import { InitiationAddItemDetailComponent } from './initiation-catalog/initiation-add-item-detail.component';
import { InitiationCatalogAddComponent } from './initiation-catalog/initiation-catalog-add.component';
import { InitiationItemCatalogAddComponent } from './initiation-catalog/initiation-item-catalog-add.component';
import { InitiationItemEditAddComponent } from './initiation-catalog/initiation-item-edit-add.component';
import { InitiationVendorDetailComponent } from './initiation-catalog/initiation-vendor-detail.component';
import { InitiationEditAddComponent } from './initiation-edit-add.component';
import { InitiationPopupCancelationComponent } from './initiation-popup-cancelation.component';
import { InitiationPopupChoosePqComponent } from './initiation-popup-choose-pq.component';
import { InitiationPopupDeliveryProcessComponent } from './initiation-popup-delivery-process.component';
import { InitiationPopupFailedComponent } from './initiation-popup-failed.component';
import { InitiationPopupSegmentationComponent } from './initiation-popup-segmentation.component';
import { InitiationPopupSelectInitiationTypeComponent } from './initiation-popup-select-initiation-type.component';
import { InitiationComponent } from './initiation.component';

const routes = [
  { path: '', component: InitiationComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: InitiationEditAddComponent,
    data: { breadcrumb: 'initiation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: InitiationEditAddComponent,
    data: { breadcrumb: 'initiation.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add-item',
    component: InitiationItemEditAddComponent,
    data: { breadcrumb: 'initiation.breadcrumb.addItem' },
    canDeactivate: [ConfirmGuard],
    children: [
      {
        path: '',
        component: InitiationCatalogAddComponent,
        canDeactivate: [ConfirmGuard]
      },
      {
        path: 'catalog',
        component: InitiationItemCatalogAddComponent,
        data: { breadcrumb: 'initiation.breadcrumb.itemCatalog' },
        canDeactivate: [ConfirmGuard]
      }
    ]
  },
  {
    path: 'edit-item',
    component: InitiationItemEditAddComponent,
    data: { breadcrumb: 'initiation.breadcrumb.editItem' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add-item-detail',
    component: InitiationAddItemDetailComponent,
    data: { breadcrumb: 'initiation.breadcrumb.catalogDetail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'vendor-detail',
    component: InitiationVendorDetailComponent,
    data: { breadcrumb: 'initiation.breadcrumb.vendorDetail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: InitiationEditAddComponent,
    data: { breadcrumb: 'initiation.breadcrumb.detail' }
  },
  {
    path: 'detail/:token',
    component: InitiationEditAddComponent,
    data: { breadcrumb: 'initiation.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppVendorCatalogInfoModule,
    AppItemRequestModule,
    AppCommitteeModule,
    AppUploadDocumentTableXModule,
    AppApprovalPrcsXModule,
    AppOfficialReportModule,
    AppBadgeCatalogModule,
    AppAgreementTypeModule,
    AppCatalogInfoModule,
    AppCatalogDetailInfoModule,
    AppSimilarProductModule
  ],
  declarations: [
    InitiationComponent,
    InitiationEditAddComponent,
    InitiationPopupChoosePqComponent,
    InitiationPopupSegmentationComponent,
    InitiationPopupCancelationComponent,
    InitiationPopupFailedComponent,
    DirectPurchasePopupSelectVendorComponent,
    DirectPurchasePopupAddVendorComponent,
    InitiationPopupDeliveryProcessComponent,
    InitiationItemEditAddComponent,
    InitiationItemCatalogAddComponent,
    InitiationCatalogAddComponent,
    InitiationAddItemDetailComponent,
    InitiationPopupSelectInitiationTypeComponent,
    InitiationVendorDetailComponent
  ],
  providers: [DatePipe],
  entryComponents: [
    InitiationPopupChoosePqComponent,
    InitiationPopupSegmentationComponent,
    InitiationPopupCancelationComponent,
    InitiationPopupFailedComponent,
    DirectPurchasePopupSelectVendorComponent,
    DirectPurchasePopupAddVendorComponent,
    InitiationPopupDeliveryProcessComponent,
    InitiationPopupSelectInitiationTypeComponent
  ],
  exports: [
    InitiationPopupChoosePqComponent,
    InitiationPopupSegmentationComponent,
    InitiationPopupCancelationComponent,
    InitiationPopupFailedComponent,
    DirectPurchasePopupSelectVendorComponent,
    DirectPurchasePopupAddVendorComponent,
    InitiationPopupDeliveryProcessComponent,
    InitiationPopupSelectInitiationTypeComponent
  ]
})
export class InitiationModule {}
