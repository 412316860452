<dashboard-widget-card [isLoading]="model.isLoading" [header]="moduleCode + '.title'"
  customClassHeader="align-items-center">
  <ng-template #headerRight>
    <div class="d-flex">
      <div class="mr-2">
        <app-button (onClick)="doChangeFilter()" [disabled]="model.isLoading">
          <em class="pir pi-filter"></em>
          {{
          'dashboard-widget-saving-and-spending-by-top-vendor.button.filter'
          | translate
          }}
        </app-button>
      </div>
    </div>
  </ng-template>
  <div *ngIf="!model.isLoading" class="dashboard-widget-saving-and-spending-by-top-vendor">
    <form class="form-horizontal" [formGroup]="formGroup" novalidate *ngIf="!model.isLoading">
      <div class="widget-info row">
        <div class="mx-3 widget-saving-spending-vendor-badge-filter">
          <div class="label">
            {{ sortByFilterLabel }}
          </div>
        </div>
        <div class="mr-3 widget-saving-spending-vendor-badge-filter">
          <div class="label">
            {{
            widgetSavingAndSpendingByTopVendorRequest.maxResult + ' vendor'
            }}
          </div>
        </div>
        <div class="mr-3 widget-saving-spending-vendor-badge-filter">
          <div class="label">
            {{ widgetSavingAndSpendingByTopVendorRequest.year }}
          </div>
        </div>
      </div>

      <app-chart [model]="chartModel" height="195px"></app-chart><br />
      <ng-template #footer>
        <div class="row">
          <ng-container *ngFor="let model of modelDefaultList">
            <div class="cml-2 d-flex align-items-center">
              <div [ngStyle]="{
                  background: model.color,
                  width: '20px',
                  height: '20px'
                }"></div>
            </div>
            <div class="cml-1 row col-sm-4 text-left align-items-center" style="display: grid">
              <h6 class="font-weight-bold"> {{ model.code }}</h6>

              <h6> {{ model.total || 0.0 }}{{ ' ' + (widgetSavingAndSpendingByTopVendorResponse.currencyCode || 'IDR')}}
              </h6>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </form>
  </div>
</dashboard-widget-card>