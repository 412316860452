<app-popup
  header="contract-termination.popup.terminateContract"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="formGroup.isView"
>
  <app-fullfilled></app-fullfilled>
  <div class="main-row-popup-terminate-contract">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{ 'contract-termination.detail.form.terminationDate' | translate }}
        </label>
        <div class="col-sm-6 text-left">
            <app-date-picker-x
            formControlName="terminationDate"
            [minDate]="startDate"
            [maxDate]="endDate"
            size="XL"
          >
          </app-date-picker-x>
        </div>
      </div>
      <div
        *ngIf="
          !formLoading &&
          !isNumberGenerator || !formLoading && formGroup.isView
        "
        class="form-group row text-sm-left"
      >
        <label class="col-sm-3">{{
          'contract-termination.detail.form.terminationNumber' | translate
        }}</label>
        <div class="col-sm-6 text-left">
          <app-text-generated
            formControlName="code"
            maxLength="64"
            [moduleCode]="moduleCode"
            numberGeneratorCode="TERMINATION_NUMBER"
            autofocus="true"
            size="XL"
          ></app-text-generated>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
          'contract-termination.detail.form.terminationReason' | translate
        }}</label>
        <div class="col-sm-6 text-left">
          <app-text-area formControlName="description" maxLength="2000" size="XL">
          </app-text-area>
        </div>
      </div>
      <div class="form-group row text-sm-left">
        <label class="col-sm-3">
          {{
            'contract-termination.detail.form.attachment' | translate
          }}
        </label>
        <div class="col-sm-6 text-left">
          <app-upload-x
            formControlName="file"
            [fileUploader]="fileUploader"
            [maxFile]="5"
            [isSingle]="false"
            size="XL"
          ></app-upload-x>
        </div>
      </div>
      <ng-template *ngIf="!formGroup.isView" #modalFooter let-activeModal>
        <div class="button-group button-group-center">
          <app-button
            color="SECONDARY"
            [outline]="true"
            (onClick)="activeModal.close(true)"
            >{{ 'app.button.cancel' | translate }}</app-button
          >
          <app-button color="PRIMARY" (onClick)="doSave()">{{
            'app.button.submit' | translate
          }}</app-button>
        </div>
      </ng-template>
    </form>
  </div>
</app-popup>
