import { ProcurementScopeWork } from 'src/app/core/bean/procurement-scope-work';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { ProcurementFines } from '../../core/bean/procurement-fines';
import { ProcurementGuarantee } from '../../core/bean/procurement-guarantee';
import { ProcurementSow } from '../../core/bean/procurement-sow';

export class ProcurementScopeWorkRequest {
  procurementScopeWork: ProcurementScopeWork = new ProcurementScopeWork();
  procurementVendor: ProcurementVendor = new ProcurementVendor();
  procurementSowList: ProcurementSow[] = [];
  procurementFinesList: ProcurementFines[] = [];
  procurementGuaranteeList: ProcurementGuarantee[] = [];
  isSaveDraft: boolean;
}
