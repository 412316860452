<app-stepper
  [model]="stepperModel"
  [isLoading]="isLoading"
  [currentPosition]="model.currentSequence"
>
  <ng-container *ngIf="model.isShowDate">
    <ng-template #contentBottom let-record>
      <div class="text-left">
        <div class="d-flex">
          <span class="w-25">From</span>:
          <span class="pl-2">
            {{ record.from ? (record.from | date: 'MMM d, y HH:mm') : '-' }}
          </span>
        </div>
        <div class="d-flex">
          <span class="w-25">Until</span>:
          <span class="pl-2">
            {{ record.to ? (record.to | date: 'MMM d, y HH:mm') : '-' }}
          </span>
        </div>
      </div>
    </ng-template>
  </ng-container>
</app-stepper>
