import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorAdditionalDoc } from '../../core/bean/vendor-additional-doc';
import { VendorDoc } from '../../core/bean/vendor-doc';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { VendorExpiredDocumentResponse } from './vendor-expired-document-response';

@Component({
  templateUrl: './vendor-expired-document-detail.component.html'
})
export class VendorExpiredDocumentDetailComponent extends BaseModuleComponent {
  @ViewChild('selectorVendorDoc') public tableVendorDoc: AppTableComponent;
  @ViewChild('selectorVendorAdditionalDoc')
  public tableVendorAdditionalDoc: AppTableComponent;

  public tableResponseVendorDoc: TableResponseModel<VendorDoc>;
  public tableResponseVendorAdditionalDoc: TableResponseModel<VendorAdditionalDoc>;

  public vendorId: number;
  public vendorName: string;
  public vendorCode: string;
  public vendorExpiredDocumentResponse: VendorExpiredDocumentResponse =
    new VendorExpiredDocumentResponse();

  constructor(translateService: TranslateService) {
    super('vendor-expired-document', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.setTableDetail();
    this.buildTableResponse();
    this.buildTableResponse2();
  }

  public setDataFromRouterParams(): void {
    this.vendorId = this.global.routerParams.get('vendorId');
    this.vendorName = this.global.routerParams.get('name');
    this.vendorCode = this.global.routerParams.get('code');
  }

  public setTableDetail(): void {
    this.httpClientService
      .get<VendorExpiredDocumentResponse>(
        '/vendor-expired-document/detail/' + this.vendorId
      )
      .subscribe(vendorExpiredDocumentResponse => {
        this.vendorExpiredDocumentResponse = vendorExpiredDocumentResponse;
        if (vendorExpiredDocumentResponse != null) {
          this.tableResponseVendorDoc.page.records =
            vendorExpiredDocumentResponse.vendorDocList;
          this.tableResponseVendorDoc.page.totalRecords =
            this.tableResponseVendorDoc.page.records.length;
          this.tableVendorDoc.reloadClient();

          this.tableResponseVendorAdditionalDoc.page.records =
            vendorExpiredDocumentResponse.vendorAdditionalList;
          this.tableResponseVendorAdditionalDoc.page.totalRecords =
            this.tableResponseVendorAdditionalDoc.page.records.length;
          this.tableVendorAdditionalDoc.reloadClient();
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponseVendorDoc = new TableResponseModel(this.moduleCode, [
      { field: 'documentInformation', header: 'table.col.documentName' },
      {
        field: 'endDate',
        header: 'table.col.expiredDate',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      }
    ]);
  }

  public buildTableResponse2(): void {
    this.tableResponseVendorAdditionalDoc = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'additionalDocumentInformation',
          header: 'table.col.documentName'
        },
        {
          field: 'endDate',
          header: 'table.col.expiredDate',
          format: FieldFormatEnum.ShortDate,
          customClass: 'text-center'
        }
      ]
    );
  }

  public doSendEmail(): void {
    this.global.modalService
      .confirm(
        this.translateService.instant(
          'vendor-expired-document.confirm.msg.sendEmail'
        ),
        'vendor-expired-document.confirm.title.sendEmail'
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.httpClientService
            .post<Response<VendorExpiredDocumentResponse>>(
              '/vendor-expired-document/send-mail',
              this.vendorExpiredDocumentResponse
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccess(
                  this.translateService.instant(
                    'vendor-expired-document.msg.success.sendEmail'
                  )
                );
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  public doBack(): void {
    this.router.navigate(['/pages/vendor-expired-document']);
  }
}
