import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Region } from '../../core/bean/region';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: 'country-flag.component.html'
})
export class CountryFlagComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Region>;
  constructor(translateService: TranslateService) {
    super('country-flag', translateService);
  }

  onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.column.code', customClass: 'text-center'},
      { field: 'name', header: 'table.column.name' }
    ]);
  }

  public doEdit(region: Region): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('regionId', region.id);
    this.router.navigate(['/pages/country-flag/edit']);
  }
}
