import { NgModule } from '@angular/core';
import { PipesModule } from '../../../pipe/pipe.module';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppAlertXModule } from '../../app-alert-x/app-alert-x.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppCheckBoxModule } from '../../app-check-box/app-check-box.module';
import { AppChosenModule } from '../../app-chosen/app-chosen.module';
import { AppComboBoxTreeModule } from '../../app-combo-box-tree/app-combo-box-tree.module';
import { AppCountModule } from '../../app-count/app-count.module';
import { AppCurrencyModule } from '../../app-currency/app-currency.module';
import { AppDatePickerXModule } from '../../app-date-picker-x/app-date-picker-x.module';
import { AppDropdownSelectModule } from '../../app-dropdown-select/app-dropdown-select.module';
import { AppRatingModule } from '../../app-rating/app-rating.module';
import { AppTableXModule } from '../../app-table-x/components/app-table-x/app-table-x.module';
import { AppTextFieldModule } from '../../app-text-field/app-text-field.module';
import { AppTextTaggingModule } from '../../app-text-tagging/app-text-tagging.module';
import { TableModule } from '../../table/table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupChooseVendorMultiWinnerComponent } from './app-popup-choose-vendor-multi-winner.component';
import { AppPopupChooseVendorMultiWinnerService } from './app-popup-choose-vendor-multi-winner.service';

@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppTableXModule,
    AppButtonModule,
    AppCheckBoxModule,
    AppChosenModule,
    AppRatingModule,
    AppTextFieldModule,
    AppCurrencyModule,
    AppDatePickerXModule,
    AppComboBoxTreeModule,
    AppDropdownSelectModule,
    AppTextTaggingModule,
    TableModule,
    AppAlertXModule,
    AppCountModule,
    PipesModule,
  ],
  declarations: [AppPopupChooseVendorMultiWinnerComponent],
  entryComponents: [AppPopupChooseVendorMultiWinnerComponent],
  providers: [AppPopupChooseVendorMultiWinnerService],
  exports: [AppPopupChooseVendorMultiWinnerComponent]
})
export class AppPopupChooseVendorMultiWinnerModule { }
