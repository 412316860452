<div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-card
        header="procurement-matrix.title"
        [isLoading]="formLoading"
        [isSaving]="formSaving"
      >
        <app-table-xx
          [model]="tableResponse"
          stringUrl="/procurement-matrix/index"
          (onClick)="doClick($event)"
          header="{{ 'procurement-matrix.table.header' | translate }}"
        >
          <ng-template #headerButtons>
            <app-button
              (onClick)="doAdd()"
            >
              <em class="pir pi-plus-circle"></em
              > {{ 'procurement-matrix.index.button.addMatrix' | translate }}
            </app-button>
          </ng-template>
          <ng-template #actionButtons let-rowData>
            <app-button
              size="TN"
              (onClick)="doDelete(rowData)"
              title="{{ 'app.tooltip.delete' | translate }}"
            >
              <em class="pir pi-trash-alt"></em>
              {{ 'app.button.delete' | translate }}
            </app-button>
          </ng-template>
        </app-table-xx>
      </app-card>
    </div>
  </div>
  