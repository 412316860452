import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupDocGroupComponent } from './app-popup-doc-group.component';
@Injectable({ providedIn: 'root' })
export class AppPopupDocGroupService {
  constructor(public appPopupService: AppPopupService) {}
  public open(
    urlPopup: string,
    dataMapPopup?: any,
    filter?: any
  ): Observable<any> {
    return this.appPopupService.open(AppPopupDocGroupComponent, {
      urlString: urlPopup,
      dataMap: dataMapPopup,
      filter
    });
  }
}
