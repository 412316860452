<div class="text-center py-4" *ngIf="formLoading">
  <img src="assets/img/common/loader-small.gif" alt="Loading" />
</div>
<form
  class="form-horizontal"
  *ngIf="!formLoading && appProcurementInformationViewResponse.contract"
>
  <h5 class="separator">{{
    'app-procurement-information-view.contractData' | translate
  }}</h5>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'app-procurement-information-view.form.contractDocument' | translate
    }}</label>
    <div class="col">
      <div class="col-12 text-left p-0">
        <a
          href="#"
          (click)="
            doDownload(
              $event,
              '/contract-negotiation/file/view/' +
                appProcurementInformationViewResponse.contractFileHistory.file
                  .uploadedFileName +
                '/' +
                appProcurementInformationViewResponse.contractFileHistory.file
                  .fileType.code,
              appProcurementInformationViewResponse.contractFileHistory.file
                .fileName
            )
          "
        >
          {{
            appProcurementInformationViewResponse.contractFileHistory.file
              .fileName
              ? appProcurementInformationViewResponse.contractFileHistory.file
                  .fileName
              : '-'
          }}
        </a>
      </div>
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'app-procurement-information-view.form.contractNumber' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{ contractNumber ? contractNumber : '-' }}
    </div>
  </div>
  <div class="form-group row text-sm-right">
    <label class="col-sm-3 control-label">{{
      'app-procurement-information-view.form.effectiveDate' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{ 'app-procurement-information-view.form.from' | translate }}
      {{ contractStartDate | date: global.appConstant.core.FORMAT_SHORT_DATE }}
      {{ 'app-procurement-information-view.form.until' | translate }}
      {{ contractEndDate | date: global.appConstant.core.FORMAT_SHORT_DATE }}
    </div>
  </div>
</form>
<ng-container
  *ngIf="
    !formLoading &&
    appProcurementInformationViewResponse.procurementVendor &&
    !appProcurementInformationViewResponse.contract
  "
>
  <form class="form-horizontal">
    <ng-container
      *ngIf="
        appProcurementInformationViewResponse.procurementVendorReference &&
        !formLoading
      "
    >
      <h5 class="separator">{{
        'app-procurement-information-view.referenceData' | translate
      }}</h5>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'app-procurement-information-view.form.reference' | translate
        }}</label>
        <div class="col-sm-4 text-left">
          {{
            appProcurementInformationViewResponse.procurementVendorReference
              .type === 1
              ? 'SPK'
              : 'PO'
          }}
        </div>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'app-procurement-information-view.form.number' | translate
        }}</label>
        <div class="col-sm-4 text-left">
          {{
            appProcurementInformationViewResponse.procurementVendorReference
              .number
          }}
        </div>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'app-procurement-information-view.form.date' | translate
        }}</label>
        <div class="col-sm-4 text-left">
          {{
            appProcurementInformationViewResponse.procurementVendorReference
              .date | date: global.appConstant.core.FORMAT_SHORT_DATE
          }}
        </div>
      </div>
    </ng-container>
    <h5 class="separator">{{
      'app-procurement-information-view.procurementData' | translate
    }}</h5>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-procurement-information-view.form.procurementNumber' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ procurementNumber ? procurementNumber : '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-procurement-information-view.form.procurementDate' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ procurementDate | date: global.appConstant.core.FORMAT_SHORT_DATE }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-procurement-information-view.form.procurementName' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ procurementName ? procurementName : '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-procurement-information-view.form.procurementOrganization'
          | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ procurementOrganization ? procurementOrganization : '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-procurement-information-view.form.procurementType' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ procurementType ? procurementType : '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-procurement-information-view.form.procurementMethod' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ procurementMethod ? procurementMethod : '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-procurement-information-view.form.hpsAmount' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{ hpsAmount ? global.converterService.convertMoney(hpsAmount) : '-' }}
        {{ currencyCode ? currencyCode : '-' }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'app-procurement-information-view.form.procurementAmount' | translate
      }}</label>
      <div class="col-sm-4 text-left">
        {{
          procurementAmount
            ? global.converterService.convertMoney(procurementAmount)
            : '-'
        }}
        {{ currencyCode ? currencyCode : '-' }}
      </div>
    </div>
  </form>
  <br />
  <h5 class="separator">{{
    'app-procurement-information-view.procurementDocument' | translate
  }}</h5>
  <app-table
    *ngIf="!formLoading"
    #selectorProcurementDocument
    [tableResponse]="tableResponseProcurementDocument"
    [isServerSide]="true"
  >
  </app-table>
  <br />
  <h5 class="separator">{{
    'app-procurement-information-view.procurementItems' | translate
  }}</h5>
  <h6 class="separator">{{
    'app-procurement-information-view.product' | translate
  }}</h6>
  <app-table
    *ngIf="!formLoading"
    #selectorProduct
    [tableResponse]="tableResponseProduct"
  >
    <ng-template #tableFooter>
      <p
        class="
          m-0
          text-right
          border border-top-0
          app-procurement-information-view-table-p
        "
      >
        <span class="mr-2"
          ><strong
            >{{
              'app-procurement-information-view.totalProductCost' | translate
            }}
            :</strong
          ></span
        >
        {{
          totalProductCost
            ? global.converterService.convertMoney(totalProductCost)
            : '-'
        }}
        {{ currencyCode ? currencyCode : '-' }}
      </p>
    </ng-template>
  </app-table>
  <h6 class="separator">{{
    'app-procurement-information-view.service' | translate
  }}</h6>
  <app-table
    *ngIf="!formLoading"
    #selectorService
    [tableResponse]="tableResponseService"
  >
    <ng-template #tableFooter>
      <p
        class="
          m-0
          text-right
          border border-top-0
          app-procurement-information-view-table-p
        "
      >
        <span class="mr-2"
          ><strong
            >{{
              'app-procurement-information-view.totalServiceCost' | translate
            }}
            :</strong
          ></span
        >
        {{
          totalServiceCost
            ? global.converterService.convertMoney(totalServiceCost)
            : '-'
        }}
        {{ currencyCode ? currencyCode : '-' }}
      </p>
    </ng-template>
  </app-table>
  <app-fieldset>
    <p class="text-right">
      <span class="mr-2"
        ><strong
          >{{
            'app-procurement-information-view.totalCost' | translate
          }}
          :</strong
        ></span
      >
      <strong
        >{{
          totalItemPrice
            ? global.converterService.convertMoney(totalItemPrice)
            : '-'
        }}
        {{ currencyCode ? currencyCode : '-' }}</strong
      >
    </p>
  </app-fieldset>
</ng-container>
