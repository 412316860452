import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { BroadcastMessage } from '../../../bean/broadcast-message';
import { ActiveAlertTop } from '../../../services/alert-top/interface/active-alert-top';
@Component({
  templateUrl: './app-alert-top.component.html',
  styleUrls: ['./app-alert-top.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppAlertTopComponent extends BaseComponentComponent {
  @Input() public broadcastMessage: BroadcastMessage;
  @Input() private activeAlertTop: ActiveAlertTop<object>;

  constructor() {
    super('app-alert-top');
  }

  public onInit(): void {
    this.resetFixedElement();
    this.setStateReady();
  }

  private resetFixedElement(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.activeAlertTop.resetFixedElement();
        });
      }
    });
  }

  public doSubmit(): void {
    this.activeAlertTop.result({ message: 'Replace this message with result' });
  }

  public doClose(): void {
    localStorage.setItem(
      'last-read-broadcast-message',
      this.broadcastMessage.id.toString()
    );
    this.activeAlertTop.close();
  }

  public onKeyUp(event: MouseEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: MouseEvent): void {
    event.preventDefault();
  }
}
