<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="budget-allocation-bjb.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div
          class="form-group row text-sm-right"
          [ngClass]="todo === 'add' ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'budget-allocation-bjb.form.organization' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              formControlName="organizationName"
              stringUrl="/organization"
              isLazy="true"
              header="Organization"
            >
            </app-text-tree>
          </div>
          <!-- <div class="col-sm-9 text-left" *ngIf="todo === 'edit'">
            {{ budgetAllocationResponse.budgetAllocation.organization?.name }}
          </div> -->
        </div>
        <div class="form-group row text-sm-right"
          [ngClass]="todo === 'add' ? 'required' : ''">
          <label class="col-sm-3 control-label">{{
            'budget-allocation-bjb.form.workProgram' | translate
          }}</label>
          <div class="col-sm-9 text-left" *ngIf="todo === 'add'">
            <app-auto-complete formControlName="workProgram"
            stringUrl="/budget-allocation-bjb/auto-complete-work-program-list"
            (onChange)="autoCompleteValueChange($event)"
            ></app-auto-complete>
          </div>
          <div class="col-sm-9 text-left" *ngIf="todo === 'edit' || todo === 'view'">
            {{ formGroup.value.workProgram?.name }}
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="todo === 'add' ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'budget-allocation-bjb.form.budgetType' | translate
          }}</label>
          <div class="col-sm-9 text-left" *ngIf="todo === 'add'">
            <app-combo-box
              [optionList]="budgetTypeOptionList"
              formControlName="budgetType"
            ></app-combo-box>
          </div>
          <div class="col-sm-9 text-left" *ngIf="todo === 'edit' || todo === 'view'">
            {{ formGroup.value.budgetType?.name }}
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="todo === 'add' ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'budget-allocation-bjb.form.costCenter' | translate
          }}</label>
          <div class="col-sm-9 text-left" *ngIf="todo === 'add'">
            <app-auto-complete 
              formControlName="costCenter"
              stringUrl="/budget-allocation-bjb/auto-complete-cost-center-list"
              (onChange)="autoCompleteValueChange($event)"
            ></app-auto-complete>
          </div>
          <div class="col-sm-9 text-left" *ngIf="todo === 'edit' || todo === 'view'">
            {{ formGroup.value.costCenter?.name }}
          </div>
        </div>
        <div class="form-group row text-sm-right" [ngClass]="todo === 'add' ? 'required' : ''">
          <label class="col-sm-3 control-label">{{
            'budget-allocation-bjb.form.coa' | translate
          }}</label>
          <div class="col-sm-9 text-left" *ngIf="todo === 'add'">
            <app-choose
              formControlName="coaName"
              (onClick)="doChooseCoa()"
            ></app-choose>
          </div>
          <div class="col-sm-9 text-left" *ngIf="todo === 'edit' || todo === 'view'">
            {{ formGroup.value.coaName }}
          </div>
        </div>
        <!-- <div class="form-group row text-sm-right"
          [ngClass]="todo !== 'edit' ? 'required' : ''">
          <label class="col-sm-3 control-label">{{ 'budget-allocation-bjb.form.coa'
            | translate }}</label>
          <div class="col-sm-9 text-left" *ngIf="todo === 'add'">
            <app-text-tree formControlName="coa" stringUrl="/coa"
              header="Chart Of Account" [onlyLastChild]="true">
            </app-text-tree>
          </div>
          <div class="col-sm-9 text-left" *ngIf="todo === 'edit'">
            {{ budgetAllocationResponse.budgetAllocation?.coa.name}}
          </div>
        </div> -->
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'budget-allocation-bjb.form.activityPeriod' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p *ngIf="formGroup.value.workProgram == null">-</p>
            <p *ngIf="formGroup.value.workProgram !== null">{{
              formGroup.value.workProgram.activityPeriod
            }}</p>
          </div>
        </div>
        <div class="form-group row text-sm-right" *ngIf="todo === 'edit'">
          <label class="col-sm-3 control-label">{{
            'budget-allocation-bjb.form.availableBudget' | translate
          }}</label>
          <div class="col-sm-3 text-right">
            {{
              formGroup.value.availableBudget +
                ' ' +
                budgetAllocationResponse?.budgetAllocation?.currency?.code
            }}
          </div>
        </div>
        <div class="form-group row text-sm-right" [ngClass]="todo !== 'view' ? 'required' : ''" *ngIf="todo !== 'view'">
          <label class="col-sm-3 control-label">{{
            'budget-allocation-bjb.form.totalBudget' | translate
          }}</label>
          <div class="col-sm-9 text-sm-left row d-flex ml-1 border-0">
            <div class="mr-5" [ngStyle]="{ 'font-size': 'medium' }">{{
              defaultCurrency
            }}</div>
            <app-text-field
              *ngIf="todo === 'add'"
              size="XL"
              formControlName="totalBudget"
              type="currency"
              maxLength="16"
              [ngStyle]="{ 'margin-inline-start': 'inherit', width: '23%' }"
            >
            </app-text-field>
            <app-text-field
              *ngIf="todo === 'edit'"
              size="XL"
              formControlName="totalBudget"
              type="currency"
              include="-"
              maxLength="16"
              tooltip="budget-allocation-bjb.tooltip.totalBudget"
              [ngStyle]="{ 'margin-inline-start': 'inherit', width: '23%' }"
            >
            </app-text-field>
          </div>
        </div>
        <div
          *ngIf="todo === 'edit'"
          class="form-group row text-sm-right required"
        >
          <label class="col-sm-3 control-label">{{
            'budget-allocation-bjb.form.note' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="note"></app-text-area>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <app-button
            *ngIf="todo === 'view' || todo === 'edit' || todo === 'add'"
            color="SECONDARY"
            mode="BYPASS"
            (onClick)="doCancel()"
            class="mr-1"
            [disabled]="formSaving && todo !== 'view'"
          >
            {{ 'app.button.back' | translate }}</app-button
          >
          <app-button
            color="PRIMARY"
            (onClick)="doSave()"
            [disabled]="formSaving"
            *ngIf="todo !== 'view'"
            >{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>

    <app-card
      *ngIf="todo !== 'add'"
      header="budget-allocation-bjb.budgetHistory.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [tableResponse]="tableResponse"
        [isServerSide]="true"
        stringUrl="/budget-allocation-bjb/budget-history/index"
        customData="{{ budgetAllocationId }}"
      >
      </app-table>
    </app-card>
  </div>
</div>
