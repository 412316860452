import { Contract } from 'src/app/core/bean/contract';
import { ContractClosed } from 'src/app/core/bean/contract-closed';
import { ContractFileHistory } from 'src/app/core/bean/contract-file-history';
import { ContractStatus } from 'src/app/core/bean/contract-status';
import { Procurement } from 'src/app/core/bean/procurement';
import { User } from 'src/app/core/bean/user';
import { Vendor } from 'src/app/core/bean/vendor';
import { VendorAddress } from 'src/app/core/bean/vendor-address';
import { VendorBank } from 'src/app/core/bean/vendor-bank';
import { FileObject } from 'src/app/core/components/upload';

export class ContractMonitoringResponse {
  contractList: Contract[] = [];
  vendorList: Vendor[] = [];
  procurementList: Procurement[] = [];
  contractStatusList: ContractStatus[] = [];
  fileObjectList: FileObject[] = [];
  contractFileHistory: ContractFileHistory = new ContractFileHistory();
  contractClosed: ContractClosed = new ContractClosed();
  uploadBy: User = new User();
  createdAt: Date = new Date();
  vendorBank: VendorBank = new VendorBank();
  vendorAddress: VendorAddress = new VendorAddress();
  contractFileHistoryNego: ContractFileHistory = new ContractFileHistory();
  isStatusNew: boolean;
  user: User;
}
