import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Contract } from 'src/app/core/bean/contract';
import { ContractFileHistory } from 'src/app/core/bean/contract-file-history';
import { VendorBank } from 'src/app/core/bean/vendor-bank';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './app-popup-contract-monitoring-edit-contract.component.html'
})
export class AppPopupContractMonitoringEditContractComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() contract: Contract;
  @Input() contractFileHistory: ContractFileHistory;
  @Input() vendorBank: VendorBank;
  @Output() public onChange: EventEmitter<Contract> = new EventEmitter();

  constructor() {
    super('contract-monitoring');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      startDate: [null, Validators.compose([Validators.required()])],
      endDate: [null, Validators.compose([Validators.required()])]
    });
  }

  public setFormGroup(): void {
    if (this.contract != null) {
      const startDate: Date = this.contract.startDate
        ? new Date(this.contract.startDate)
        : null;
      const endDate: Date = this.contract.endDate
        ? new Date(this.contract.endDate)
        : null;
      this.formGroup.patchValue({
        startDate,
        endDate
      });
      this.setStateReady();
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const contract: Contract = this.contract;
            contract.id = this.contract.id;
            contract.startDate = this.formGroup.value.startDate;
            contract.endDate = this.formGroup.value.endDate;
            this.httpClientService
              .post<Response<Contract>>(
                '/contract-monitoring/update-contract',
                contract
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.onChange.emit(this.formGroup.value);
                  this.global.alertService.showSuccess(
                    this.global.translateService.instant(
                      'app.alert.msg.saveSuccess'
                    )
                  );
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }
}
