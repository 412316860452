import { BaseEntity } from '../base/base-entity';
import { Lang } from '../bean/lang';
import { TranslationKey } from '../bean/translation-key';

export class TranslationEntity extends BaseEntity {
    module: any;

    translationKey: TranslationKey;
    lang: Lang;
    value: string;
}
