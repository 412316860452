import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TreeEvent, TreeModel } from '../../core/components/app-tree';
import { Validators } from '../../core/validators';
@Component({
    templateUrl: './procurement-type.component.html'
  })
  export class ProcurementTypeComponent extends BaseModuleComponent {
    public treeModel: TreeModel = new TreeModel(this.moduleCode, 'WRITE');
    constructor(translateService: TranslateService) {
      super('procurement-type', translateService);
    }

    onInit(): void {
      this.buildTreeFormGroup();
      this.setStateReady();
    }

    public buildTreeFormGroup(): void {
      this.treeModel.formGroup = this.formBuilder.group({
        id: [null],
        code: [null, Validators.compose([Validators.required(), Validators.maxLength(32)])],
        name: [null, Validators.compose([Validators.required(), Validators.maxLength(32)])],
        description: [null, Validators.maxLength(512)],
        parentId: [null],
        tempId: [null],
        hasChild: [null]
      });
    }

    public doOnEventChange(treeEvent: TreeEvent): void {
      if (treeEvent.type === 'ON-SHOW-POPUP') {
        const id = this.formGroup.value.id;
        treeEvent.formGroup.get('code').setAsyncValidators(Validators.existsAsync('/organization/isAlreadyExist', null, null, { id }));
      } else if (treeEvent.type === 'ON-CLOSE-POPUP') {
        treeEvent.formGroup.get('code').clearAsyncValidators();
      }
    }
  }

