import { Injectable } from '@angular/core';
import * as datePickerAction from './action/date-picker-action';
import { dispatchDatePicker } from './action/dispatch-date-picker-action';
import { DatePickerModel } from './interface/date-picker-model';
import { DatePickerState } from './model';
@Injectable()
export class AppDatePickerXService {
  private state: DatePickerState;
  constructor() {}

  public setState(model: DatePickerModel): DatePickerState {
    this.state = new DatePickerState(model);
    return this.state;
  }

  public dispatch(action: datePickerAction.AllDatePickerAction): void {
    dispatchDatePicker(this.state, action);
  }
}
