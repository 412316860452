<ng-container
  *ngIf="!ISVIEW; then isNotViewTmpl; else isViewTmpl"
></ng-container>
<ng-template #isNotViewTmpl>
  <div class="app-wysiwyg p-0" [ngClass]="elementWidthClassName">
    <ckeditor
      [config]="config"
      height="250px"
      [formControl]="formControl"
      [ngClass]="{ 'is-invalid': isInvalid }"
      [placeholder]="placeholder || 'app-wysiwyg.form.placeholder' | translate"
    ></ckeditor>
    <app-tooltip-wrapper
      tooltip="{{ tooltip }}"
      position="{{ tooltipPosition }}"
    ></app-tooltip-wrapper>
    <ng-container
      *ngTemplateOutlet="appTooltipWrapperTemplateOutlet"
    ></ng-container>
  </div>
  <small class="text-danger" *ngIf="isInvalid">
    {{ errors.message }}
  </small>
</ng-template>
<ng-template #isViewTmpl>
  <div class="position-relative d-inline-block">
    <div [innerHTML]="formControl.value || '-'"></div>
    <ng-container
      *ngTemplateOutlet="appTooltipWrapperTemplateOutlet"
    ></ng-container>
  </div>
</ng-template>

<ng-template #appTooltipWrapperTemplateOutlet>
  <span class="mx-1" *ngIf="ISVIEW">
    <ng-container *ngTemplateOutlet="flagNewTmpl"></ng-container>
  </span>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [symbolic]="symbolic"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-container *ngIf="!ISVIEW">
        <ng-container *ngTemplateOutlet="flagNewTmpl"></ng-container>
      </ng-container>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>

<ng-template #flagNewTmpl>
  <ng-content select="app-flag-new"></ng-content>
</ng-template>
