import { NgModule } from '@angular/core';
import { AppContentSliderModule } from '../../../../../core/components/app-content-slider/app-content-slider.module';
import { AppPopupModule } from '../../../../../core/components/app-popup/app-popup.module';
import { AppRatingModule } from '../../../../../core/components/app-rating/app-rating.module';
import { AppTableModule } from '../../../../../core/components/app-table/app-table.module';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { AppWidgetContentSliderModule } from '../../../../../core/widgets/app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../../../../../core/widgets/app-widget.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { AppPopupDocumentExpiredComponent } from './app-popup-document-expired.component';
import { WidgetVendorInformationComponent } from './widget-vendor-information.component';


@NgModule({
  imports: [VendorModule, AppWidgetModule, AppWidgetContentSliderModule, AppRatingModule, AppTableModule, AppPopupModule, WidgetCardModule, AppContentSliderModule],
  declarations: [WidgetVendorInformationComponent, AppPopupDocumentExpiredComponent],
  entryComponents: [WidgetVendorInformationComponent, AppPopupDocumentExpiredComponent
  ],
  exports: [WidgetVendorInformationComponent]
})
export class WidgetVendorInformationModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetVendorInformationComponent.moduleCode,
      WidgetVendorInformationComponent,
      'w-100'
    );
  }
}
