<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="vendor-whitelist.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form [formGroup]="formGroup" class="form-horizontal" novalidate>
        <h5 class="separator">Data Vendor</h5>
        <div
          class="form-group row text-sm-right"
          [ngClass]="todo === 'add' ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <div *ngIf="!isEdit">
              <app-choose formControlName="name" (onClick)="doChooseVendor()">
              </app-choose>
            </div>
            <div *ngIf="isEdit">
              <p>{{ vendorWhitelistResponse.vendor.name }}</p>
            </div>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p *ngIf="vendorId == null && !isEdit">-</p>
            <p *ngIf="isEdit">{{ vendorWhitelistResponse.vendor.code }}</p>
            <p *ngIf="vendorId != null && !isEdit">{{ vendorCode }}</p>
          </div>
        </div>
        <br />

        <h5 class="separator">{{
          'vendor-whitelist.form.violationData' | translate
        }}</h5>
        <div
          class="table-responsive custom-table float-none"
          [ngStyle]="{ margin: '0 auto' }"
        >
          <table
            class="table table-striped box-shadow"
            aria-describedby="Table"
          >
            <thead>
              <tr class="text-center">
                <th scope="col">{{ 'app.table.column.no' | translate }}</th>
                <th scope="col" class="text-left">{{
                  'vendor-whitelist.table.column.violation' | translate
                }}</th>
                <th scope="col">{{
                  'vendor-whitelist.table.column.duration' | translate
                }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngIf="vendorWhitelistResponse.vendorViolationList.length == 0"
              >
                <td colspan="99" class="bg-white text-center">{{
                  'app.info.noData' | translate
                }}</td>
              </tr>
              <tr
                *ngFor="
                  let data of vendorWhitelistResponse.vendorViolationList;
                  let i = index
                "
              >
                <td class="text-center">{{ i + 1 }}.</td>
                <td class="text-left">{{
                  data.blacklistCriteria.violation
                }}</td>
                <td class="text-center">{{
                  global.converterService.convertMonthToYears(
                    data.blacklistCriteria.durationInMonth
                  )
                }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />

        <h5 class="separator">{{
          'vendor-whitelist.form.blacklistInformation' | translate
        }}</h5>
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-whitelist.form.startDate' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <p
                class="col-sm-7"
                *ngIf="vendorWhitelistResponse.vendorBlacklist == null"
                >-</p
              >
              <div *ngIf="isEdit || isAdd">
                <p class="col-sm-7">{{
                  vendorWhitelistResponse.vendorBlacklist.startDate
                    | date: global.config.parameterModel.dateFormatShort
                }}</p>
              </div>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-whitelist.form.endDate' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <p
                class="col-sm-7"
                *ngIf="vendorWhitelistResponse.vendorBlacklist == null"
                >-</p
              >
              <div *ngIf="isEdit || isAdd">
                <p class="col-sm-7">{{
                  vendorWhitelistResponse.vendorBlacklist.endDate
                    | date: global.config.parameterModel.dateFormatShort
                }}</p>
              </div>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-whitelist.form.blacklistduration' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <p
                class="col-sm-7"
                *ngIf="vendorWhitelistResponse.vendorBlacklist == null"
                >-</p
              >
              <div *ngIf="isEdit || isAdd">
                <p class="col-sm-7">
                  {{
                    vendorWhitelistResponse.vendorBlacklist.blacklistDuration
                      .name
                  }}
                </p>
              </div>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-whitelist.form.note' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <p
                class="col-sm-7"
                *ngIf="vendorWhitelistResponse.vendorBlacklist == null"
                >-</p
              >
              <div *ngIf="isEdit || isAdd">
                <p class="col-sm-7">{{
                  vendorWhitelistResponse.vendorBlacklist.note
                }}</p>
              </div>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-whitelist.form.reason' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <p
                class="col-sm-7"
                *ngIf="vendorWhitelistResponse.vendorBlacklist == null"
                >-</p
              >
              <div *ngIf="isEdit || isAdd">
                <p class="col-sm-7">{{
                  vendorWhitelistResponse.vendorBlacklist.reason
                }}</p>
              </div>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'vendor-whitelist.form.violation-doc' | translate
            }}</label>
            <div class="col-sm-4 text-left">
              <p
                class="col-sm-7"
                *ngIf="
                  vendorWhitelistResponse.vendorBlacklist == null ||
                  vendorWhitelistResponse.vendorBlacklist.file == null
                "
              >
                -</p
              >
              <div
                *ngIf="
                  vendorWhitelistResponse.vendorBlacklist != null &&
                  vendorWhitelistResponse.vendorBlacklist.file != null
                "
              >
                <a
                  href="#"
                  (click)="
                    doDownload(
                      $event,
                      '/vendor-blacklist/file/view/' +
                        vendorWhitelistResponse.vendorBlacklist.file
                          .uploadedFileName +
                        '/' +
                        global.appConstant.fileType.DOC_VENDOR_WHITELIST,
                      vendorWhitelistResponse.vendorBlacklist.file.fileName
                    )
                  "
                >
                  <p class="col-sm-7">{{
                    vendorWhitelistResponse.vendorBlacklist.file.fileName
                  }}</p>
                </a>
              </div>
            </div>
          </div>
        </form>
        <br />

        <h5 class="separator">{{
          'vendor-whitelist.form.whitelistInformation' | translate
        }}</h5>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.startDate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-date-picker
              minDate="currentDate"
              formControlName="startDateWhite"
            ></app-date-picker>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.note' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="noteWhite" maxLength="512">
            </app-text-area>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.reason' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area
              formControlName="reasonWhite"
              maxLength="512"
            ></app-text-area>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.upload' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-upload
              formControlName="whitelistDoc"
              [fileUploader]="fileUploader"
            ></app-upload>
          </div>
        </div>

        <div class="form-group row text-sm-right" *ngIf="isRevision">
          <label class="col-sm-3 control-label">{{
            'vendor-whitelist.form.approvalNote' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p class="col-sm-7">
              {{ vendorWhitelistResponse.transactionLog?.approvalNote || '-' }}
            </p>
          </div>
        </div>
      </form>
      <div class="button-group button-group-center mt-5">
        <app-button
          color="SECONDARY"
          (onClick)="doCancel()"
          [disabled]="formSaving"
        >
          {{ 'app.button.back' | translate }}
        </app-button>
        <app-button (onClick)="doSave()" [disabled]="formSaving">
          {{ 'app.button.save' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>
