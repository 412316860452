import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalPrcsXModule } from '../../core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppItemRequestModule } from '../../core/components/app-item-request/app-item-request.module';
import { AppProcurementInfoModule } from '../../core/components/app-procurement-info/app-procurement-info.module';
import { AppWorkflowStepInfoModule } from '../../core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { DocRetrievalEditComponent } from './doc-retrieval-edit.component';

export const routes = [
  {
    path: 'add',
    component: DocRetrievalEditComponent,
    data: { breadcrumb: 'doc-retrieval.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: DocRetrievalEditComponent,
    data: { breadcrumb: 'doc-retrieval.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: DocRetrievalEditComponent,
    data: { breadcrumb: 'doc-retrieval.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: DocRetrievalEditComponent,
    data: { breadcrumb: 'doc-retrieval.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    AppWorkflowStepInfoModule,
    AppProcurementInfoModule,
    AppItemRequestModule,
    AppApprovalPrcsXModule,
    AppBadgeCatalogModule
  ],
  declarations: [DocRetrievalEditComponent],
  providers: [],
  entryComponents: [],
  exports: []
})
export class DocRetrievalModule {}
