import { BaseEntity } from '../base/base-entity';
import { ProcurementItem } from '../bean/procurement-item';
import { ProfitLossStatus } from '../bean/profit-loss-status';
import { Quotation } from '../bean/quotation';
import { ScoringStatus } from '../bean/scoring-status';
import { SubmittedStatus } from '../bean/submitted-status';

export class QuotationItemEntity extends BaseEntity {
  crudOperation: number;
  oepItemAmount: number; /* field OEP di app popup final price detail */

  quotation: Quotation;
  procurementItem: ProcurementItem;
  submittedStatus: SubmittedStatus;
  profitLossStatus: ProfitLossStatus;
  finalProfitLossStatus: ProfitLossStatus;
  scoringStatus: ScoringStatus;
  finalScoringStatus: ScoringStatus;
  price: number;
  quantity: number;
  finalPrice: number;
  totalPrice: number;
  totalFinalPrice: number;
  profitLossAmount: number;
  finalProfitLossAmount: number;
  evaluationScore: number;
  finalEvaluationScore: number;
}
