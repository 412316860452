import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Lang } from '../../core/bean/lang';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './lang.component.html'
})
export class LangComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Lang>;
  constructor(translateService: TranslateService) {
    super('lang', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      { field: 'name', header: 'table.column.name' },
      {
        field: 'isActive',
        header: 'table.column.status',
        datamap: '{"false" : "Not Active" , "true" : "Active" }',
        customClass: 'text-center'
      },
      {
        field: 'isDefault',
        header: 'table.column.isDefault',
        datamap: '{"false" : "No" , "true" : "Yes" }',
        customClass: 'text-center'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/lang/add']);
  }

  public doEdit(lang: Lang): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('langId', lang.id);
    this.router.navigate(['/pages/lang/edit']);
  }

  public doDelete(lang: Lang): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<Lang>>('/lang/delete', lang)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
