import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Period } from '../../core/bean/period';
import { Vendor } from '../../core/bean/vendor';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { AllPerformanceDTO } from './dto/all-performance.dto';
@Component({
  templateUrl: './all-performance-generate.component.html'
})
export class AllPerformanceGenerateComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Vendor>;
  public periodList: OptionListModel<Period> = new OptionListModel();
  public yearList: OptionListModel<any> = new OptionListModel(false);
  public dataRecord: Vendor[] = [];
  public isSearch = false;

  constructor(translateService: TranslateService) {
    super('all-performance', translateService);
  }

  public onInit(): void {
    this.setOptionList();
    this.buildFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      period: [null, Validators.required()],
      year: [null, Validators.required()]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.vendorName' },
      { field: 'code', header: 'table.column.vendorCode', customClass: 'text-center' },
      { field: 'vendorType.name', header: 'table.column.vendorType', customClass: 'text-center' },
      { field: 'email', header: 'table.column.email' },
      { field: 'vendorActivityStatus.name', header: 'table.column.status', customClass: 'text-center' },
      {
        field: 'id',
        header: 'table.column.select',
        format: FieldFormatEnum.CheckBox,
        sortable: false
      }
    ]);
  }

  public doSearch(): void {
    this.validate();
    if (this.formGroup.valid) {
      const customData = {
        period: this.formGroup.value.period,
        year: this.formGroup.value.year ? this.formGroup.value.year.value : null
      };

      if (!this.isSearch) {
        this.buildTableResponse();
        this.isSearch = true;
      }
      this.tableResponse.setCustomData(customData);

      this.tableResponse.reload();
    }

    this.log.debug('this.isSearch' + this.isSearch);
  }

  public onChange(records: Vendor[]): void {
    this.dataRecord = records;
  }

  public doSubmit(): void {
    if (this.dataRecord.length === 0) {
      this.global.alertService.showError(
        this.translateService.instant(
          'all-performance.alert.selectOneVendorFirst'
        )
      );
    } else {
      this.validate();
      if (this.formGroup.valid) {
        this.global.modalService
          .saveConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();

              const allPerformanceDTO = new AllPerformanceDTO();
              allPerformanceDTO.vendorList = this.dataRecord;
              allPerformanceDTO.year = this.formGroup.value.year.value;
              allPerformanceDTO.period = this.formGroup.value.period;

              this.httpClientService
                .post<Response<Vendor>>(
                  '/all-performance/generate',
                  allPerformanceDTO
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.global.alertService.showSuccessSavingOnNextRoute();
                    this.router.navigate(['/pages/all-performance']);
                  } else {
                    this.global.alertService.showError(response.statusText);
                    this.setStateReady();
                  }
                });
            }
          });
      }
    }
  }

  public setOptionList(): void {
    this.httpClientService
      .get('/all-performance/get-period-list')
      .subscribe((periodList: Period[]) => {
        this.periodList.setRequestValues(periodList);
      });

    const yearList = [
      {
        name: new Date().getFullYear().toString(),
        value: new Date().getFullYear()
      },
      {
        name: (new Date().getFullYear() - 1).toString(),
        value: new Date().getFullYear() - 1
      },
      {
        name: (new Date().getFullYear() - 2).toString(),
        value: new Date().getFullYear() - 2
      },
      {
        name: (new Date().getFullYear() - 3).toString(),
        value: new Date().getFullYear() - 3
      },
      {
        name: (new Date().getFullYear() - 4).toString(),
        value: new Date().getFullYear() - 4
      },
      {
        name: (new Date().getFullYear() - 5).toString(),
        value: new Date().getFullYear() - 5
      }
    ];
    this.yearList.setRequestValues(yearList);
  }

  public doBack(): void {
    this.router.navigate(['/pages/all-performance']);
  }
}
