import { BaseEntity } from '../../base/base-entity';

export class FinesMonitoringViewEntity extends BaseEntity {
  isHasAdjust: Boolean /* Transient */
  startDate: string;
  endDate: string;
  procurementName: string;
  agreementNumber: string;
  vendorName: string;
}
