<div class="text-center py-4" *ngIf="formLoading">
  <img src="assets/img/common/loader-small.gif" alt="Loading" />
</div>
<form class="form-horizontal" *ngIf="!formLoading">
  <div class="row">
    <div class="col-12">
      <h5 class="separator"
        >{{ 'app-contract-draft-view.separator.draftDocument' | translate }}
      </h5>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'app-contract-draft-view.form.file' | translate
        }}</label>
        <div class="col">
          <div class="col-12 text-left p-0">
            <a
              href="#"
              (click)="
                doDownload(
                  $event,
                  '/contract-draft/file/view/' +
                    draftDocument.uploadedFileName +
                    '/' +
                    draftDocument.fileType.code,
                  draftDocument.fileName
                )
              "
            >
              {{ draftDocument ? draftDocument.fileName : '-' }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h5 class="separator">{{
        'app-contract-draft-view.separator.participantReviewer' | translate
      }}</h5>
      <app-table #selectorDraft [model]="tableResponseDraft"> </app-table>
    </div>
  </div>
</form>
