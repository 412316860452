import { BaseEntity } from '../base/base-entity';
import { BudgetAllocation } from '../bean/budget-allocation';
import { PrItem } from '../bean/pr-item';

export class PrItemBudgetEntity extends BaseEntity {
  prItem: PrItem = new PrItem();
  budgetAllocation: BudgetAllocation = new BudgetAllocation();
  bookedAmount: number;
  usedAmount: number;
  paidAmount: number;
  remainingAmount: number;
}
