<div class="row">
  <div class="col-lg-12">
    <app-card
      header="due-diligence.title"
      [isSaving]="formSaving"
      [isLoading]="formLoading"
    >
      <div *ngIf="!formLoading">
      
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'due-diligence.form.companyName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ dueDiligenceResponse.vendorHistory.name }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'due-diligence.form.regisNum' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ dueDiligenceResponse.vendorHistory.registrationNumber }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'due-diligence.form.dueDiligenceTemplate' | translate
          }}</label>
          <div class="col-sm-1 text-left pr-0">
            <div
              *ngIf="
                dueDiligenceResponse.vendorVerification.dueDiligenceTemplate === null
              "
            >
              -
            </div>
            <div
              *ngIf="
                dueDiligenceResponse.vendorVerification.dueDiligenceTemplate !== null
              "
            >
              <app-combo-box
                autofocus="true"
                size="XL"
                [optionList]="dueDiligenceTemplateOptionList"
                formControlName="dueDiligenceTemplate"
              ></app-combo-box>
            </div>
          </div>
          <div
            class="col-sm-1 text-left"
            *ngIf="
              dueDiligenceResponse.vendorVerification.dueDiligenceTemplate !== null
            "
          >
            <app-tooltip position="TOP_RIGHT">
              <em class="fas fa-eye"></em>
              <ng-template #tooltipContent>
                <table *ngIf="isLoadingTooltip"
                    class="table table-striped" aria-describedby="TableLoading">
                    <thead>
                      <tr>
                        <th scope="col">
                          <div class="text-center" [ngStyle]="{ margin: '10vh 0' }">
                            <img src="assets/img/common/loader-small.gif" alt="Loading" />
                          </div>
                        </th>
                      </tr>
                    </thead>
                </table>

                <div class="overflow-auto" [ngStyle]="{ 'max-height': '410px' }">
                  <table *ngIf="!isLoadingTooltip" class="table table-striped" aria-describedby="Table">
                    <thead>
                      <tr>
                        <th scope="col">{{
                          'due-diligence.form.dueDiligenceTemplate' | translate
                        }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ 'due-diligence.form.code' | translate }}</td>
                        <td>{{
                          onBoardingDueDiligenceTemplateResponse.dueDiligenceTemplate.code
                        }}</td>
                      </tr>
                      <tr>
                        <td>{{ 'due-diligence.form.name' | translate }}</td>
                        <td>{{
                          onBoardingDueDiligenceTemplateResponse.dueDiligenceTemplate.name
                        }}</td>
                      </tr>
                      <tr
                        *ngIf="
                          onBoardingDueDiligenceTemplateResponse
                            .attributeList.length != 0
                        "
                      >
                        <td>OTS</td>
                        <td>
                          <p
                            *ngFor="
                              let data of onBoardingDueDiligenceTemplateResponse.attributeList;
                              let i = index
                            "
                          >
                            {{ data.description }}
                          </p>
                        </td>
                      </tr>
                      <tr
                        *ngIf="
                          onBoardingDueDiligenceTemplateResponse
                            .attributeList.length == 0
                        "
                      >
                        <td>OTS</td>
                        <td>-</td>
                      </tr>
                      <tr
                        *ngIf="
                          onBoardingDueDiligenceTemplateResponse
                            .questionList.length != 0
                        "
                      >
                        <td>Interview</td>
                        <td>
                          <p
                            *ngFor="
                              let data of onBoardingDueDiligenceTemplateResponse.questionList;
                              let i = index
                            "
                          >
                            {{ data.name }}
                          </p>
                        </td>
                      </tr>
                      <tr
                        *ngIf="
                          onBoardingDueDiligenceTemplateResponse
                            .questionList.length == 0
                        "
                      >
                        <td>Interview</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-template>
            </app-tooltip>
          </div>
        </div>

        <br />
        <br />
        <h5 class="separator"
          >{{ 'due-diligence.form.taskGiver' | translate }}
        </h5>
        <div class="form-group required" *ngIf="!formGroup.isView">
          <label class="col-sm-3"></label>
          <label class="col-sm-2 control-label">{{
            'due-diligence.table.column.name' | translate
          }}</label>
          <label class="col-sm-2"
            ><strong>{{
              'due-diligence.table.column.position' | translate
            }}</strong></label
          >
        </div>
        <ng-container formArrayName="credentials" *ngIf="!formGroup.isView">
          <div
            class="form-group row text-sm-right"
            *ngFor="let creds of inputList.controls; let i = index"
            [formGroupName]="i"
          >
            <label class="col-sm-3 control-label"></label>
            <div class="col-sm-9 col-lg-7 text-left">
              <div class="form-row">
                <div class="form-group row col-4">
                  <div class="col text-left">
                    <app-choose
                      size="XL"
                      formControlName="name"
                      (onClick)="doChooseUser(i)"
                    ></app-choose>
                  </div>
                </div>
                <div class="form-group row col-4">
                  <div class="col text-left">
                    <label
                      *ngIf="
                        creds.value.position == null ||
                        creds.value.position == ''
                      "
                      >&nbsp; -
                    </label>
                    <label
                      >&nbsp; {{ inputList.at(i).get('position').value }}</label
                    >
                  </div>
                </div>
                <div class="form-group row col-4">
                  <div class="col text-left">
                    <app-button *ngIf="i !== 0" 
                      color="SECONDARY" size="TN" 
                      (onClick)="doRemove(i)" 
                      [disabled]="formSaving"
                      title="{{ 'app.tooltip.delete' | translate }}">
                      <em class="fa fas fa-trash"></em>
                    </app-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="text-center" *ngIf="!formGroup.isView">
          <button class="btn btn-primary" (click)="addCreds()"
            [disabled]="formSaving"
            ><em class="fas fa-plus"></em>
            {{ 'due-diligence.button.addMore' | translate }}</button
          >
        </div>

        <!--Task Giver HTML Table-->
        <div
          class="table-responsive custom-table float-none"
          [ngStyle]="{ margin: '0 auto' }"
          *ngIf="formGroup.isView"
        >
          <table
            class="table table-striped box-shadow"
            aria-describedby="Table"
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  [ngStyle]="{ width: '250px' }"
                  class="text-center"
                  >{{ 'app.table.column.no' | translate }}
                </th>
                <th scope="col" [ngStyle]="{ width: '400px' }">{{
                  'due-diligence.table.column.name' | translate
                }}</th>
                <th scope="col">{{
                  'due-diligence.table.column.position' | translate
                }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngIf="
                  dueDiligenceResponse.dueDiligenceAssignerTaskGiverList.length == 0
                "
              >
                <td colspan="99" class="bg-white text-center">{{
                  'app.info.noData' | translate
                }}</td>
              </tr>
              <tr
                *ngFor="
                  let data of dueDiligenceResponse.dueDiligenceAssignerTaskGiverList;
                  let i = index
                "
              >
                <td class="text-center">{{ i + 1 }}.</td>
                <td>{{ data.user.name }}</td>
                <td *ngIf="data.position">{{ data.position }}</td>
                <td *ngIf="!data.position"> - </td>
              </tr>
            </tbody>
          </table>
        </div>

        <br />
        <br />
        <h5 class="separator">{{
          'due-diligence.form.acknowledgedBy' | translate
        }}</h5>
        <div class="form-group required" *ngIf="!formGroup.isView">
          <label class="col-sm-3"></label>
          <label class="col-sm-2 control-label">{{
            'due-diligence.table.column.name' | translate
          }}</label>
          <label class="col-sm-2"
            ><strong>{{
              'due-diligence.table.column.position' | translate
            }}</strong></label
          >
        </div>
        <ng-container
          formArrayName="credentialsAcknowledged"
          *ngIf="!formGroup.isView"
        >
          <div
            class="form-group row text-sm-right"
            *ngFor="let creds of inputListAcknowledged.controls; let i = index"
            [formGroupName]="i"
          >
            <label class="col-sm-3 control-label"></label>
            <div class="col-sm-9 col-lg-7 text-left">
              <div class="form-row">
                <div class="form-group row col-4">
                  <div class="col text-left">
                    <app-choose
                      size="XL"
                      formControlName="name"
                      (onClick)="doChooseUserAcknowledged(i)"
                    ></app-choose>
                  </div>
                </div>
                <div class="form-group row col-4">
                  <div class="col text-left">
                    <label
                      *ngIf="
                        creds.value.position == null ||
                        creds.value.position == ''
                      "
                      >&nbsp; -
                    </label>
                    <label
                      >&nbsp;
                      {{
                        inputListAcknowledged.at(i).get('position').value
                      }}</label
                    >
                  </div>
                </div>
                <div class="form-group row col-4">
                  <div class="col text-left">
                    <app-button *ngIf="i !== 0" 
                      color="SECONDARY" size="TN" 
                      (onClick)="doRemoveAcknowledged(i)" 
                      [disabled]="formSaving"
                      title="{{ 'app.tooltip.delete' | translate }}">
                      <em class="fa fas fa-trash"></em>
                    </app-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="text-center" *ngIf="!formGroup.isView">
          <button class="btn btn-primary" (click)="addCredsAcknowledged()"
            [disabled]="formSaving"
            ><em class="fas fa-plus"></em>
            {{ 'due-diligence.button.addMore' | translate }}</button
          >
        </div>

        <!--Acknowledged By HTML Table-->
        <div
          class="table-responsive custom-table float-none"
          [ngStyle]="{ margin: '0 auto' }"
          *ngIf="formGroup.isView"
        >
          <table
            class="table table-striped box-shadow"
            aria-describedby="Table"
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  [ngStyle]="{ width: '250px' }"
                  class="text-center"
                  >{{ 'app.table.column.no' | translate }}
                </th>
                <th scope="col" [ngStyle]="{ width: '400px' }">{{
                  'due-diligence.table.column.name' | translate
                }}</th>
                <th scope="col">{{
                  'due-diligence.table.column.position' | translate
                }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngIf="
                  dueDiligenceResponse.dueDiligenceAssignerAcknowledgedByList.length ==
                  0
                "
              >
                <td colspan="99" class="bg-white text-center">{{
                  'app.info.noData' | translate
                }}</td>
              </tr>
              <tr
                *ngFor="
                  let data of dueDiligenceResponse.dueDiligenceAssignerAcknowledgedByList;
                  let i = index
                "
              >
                <td class="text-center">{{ i + 1 }}.</td>
                <td>{{ data.user.name }}</td>
                <td *ngIf="data.position">{{ data.position }}</td>
                <td *ngIf="!data.position"> - </td>
              </tr>
            </tbody>
          </table>
        </div>

        <br />
        <app-fieldset
          legend="{{ 'due-diligence.form.schedule' | translate }} - OTS"
          *ngIf="showScheduleOTS"
        >
          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView ? 'required' : ''"
          >
            <label class="col-sm-3 control-label">{{
              'due-diligence.form.address' | translate
            }}</label>
            <div
              class="text-left"
              [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'"
            >
              <app-text-area maxLength="512" formControlName="addressOTS">
              </app-text-area>
            </div>
          </div>
          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView ? 'required' : ''"
          >
            <label class="col-sm-3 control-label">{{
              'due-diligence.form.date' | translate
            }}</label>
            <div class="col-sm-9 col-lg-7 text-left">
              <app-date-picker
                minDate="currentDateOTS"
                formControlName="dateOTS"
              ></app-date-picker>
            </div>
          </div>

          <!-- time if isView false -->
          <div *ngIf="!formGroup.isView" class="form-group row text-sm-right">
            <div
              class="col-sm-3 mb-3 pr-1 align-items-center"
              [ngStyle]="{ display: 'grid', height: '72px' }"
            >
              <label class="control-label">
                {{ 'due-diligence.form.time' | translate
                }}<span *ngIf="!formGroup.isView" [ngStyle]="{ color: 'red' }">
                  *</span
                >
              </label>
            </div>
            <div class="form-group row col-sm-9 text-left">
              <label
                class="px-3 align-items-center"
                [ngStyle]="{ display: 'grid', height: '72px' }"
              >
                <strong>{{
                  'due-diligence.form.from' | translate
                }}</strong></label
              >
              <div [ngStyle]="{ 'max-width': '135px' }">
                <app-time formControlName="startTimeOTS"></app-time>
              </div>
              <label
                class="ml-3 mr-3 align-items-center"
                [ngStyle]="{ display: 'grid', height: '72px' }"
              >
                <strong>{{ 'due-diligence.form.until' | translate }}</strong></label
              >
              <div [ngStyle]="{ 'max-width': '135px' }">
                <app-time formControlName="endTimeOTS"></app-time>
              </div>
              <label
                class="ml-3 align-items-center"
                [ngStyle]="{ display: 'grid', height: '72px' }"
              >
                <strong>WIB</strong></label
              >
            </div>
          </div>

          <!-- time if isView true -->
          <div *ngIf="formGroup.isView" class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'due-diligence.form.time' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <strong>{{ 'due-diligence.form.from' | translate }}</strong>
              {{ getHours(dueDiligenceResponse.dueDiligenceScheduleOTS?.startTime) }}
              WIB <strong>{{ 'due-diligence.form.until' | translate }}</strong>
              {{ getHours(dueDiligenceResponse.dueDiligenceScheduleOTS?.endTime) }} WIB
            </div>
          </div>

          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView ? 'required' : ''"
          >
            <label class="col-sm-3 control-label">{{
              'due-diligence.form.objective' | translate
            }}</label>
            <div
              class="text-left"
              [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'"
            >
              <app-text-area maxLength="512" formControlName="objectivesOTS">
              </app-text-area>
            </div>
          </div>
          <br />
          <h5 class="separator"
            >{{ 'due-diligence.form.executor' | translate }}
          </h5>
          <div class="form-group required" *ngIf="!formGroup.isView">
            <label class="col-sm-3"></label>
            <label class="col-sm-2 control-label">{{
              'due-diligence.table.column.name' | translate
            }}</label>
            <label class="col-sm-2"
              ><strong>{{
                'due-diligence.table.column.position' | translate
              }}</strong></label
            >
          </div>
          <ng-container
            formArrayName="credentialsOTS"
            *ngIf="!formGroup.isView"
          >
            <div
              class="form-group row text-sm-right"
              *ngFor="let creds of inputListOTS.controls; let i = index"
              [formGroupName]="i"
            >
              <label class="col-sm-3 control-label"></label>
              <div class="col-sm-9 col-lg-7 text-left">
                <div class="form-row">
                  <div class="form-group row col-4">
                    <div class="col text-left">
                      <app-choose
                        size="XL"
                        formControlName="name"
                        (onClick)="doChooseUserExecutorOTS(i)"
                      ></app-choose>
                    </div>
                  </div>
                  <div class="form-group row col-4">
                    <div class="col text-left">
                      <!-- <app-text-field maxLength="32" size="XL" formControlName="position"></app-text-field> -->
                      <label
                        *ngIf="
                          !creds.value.position || creds.value.position == ''
                        "
                        >&nbsp; -
                      </label>
                      <label
                        >&nbsp;
                        {{ inputListOTS.at(i).get('position').value }}</label
                      >
                    </div>
                  </div>
                  <div class="form-group row col-4">
                    <div class="col text-left">
                      <app-button *ngIf="i !== 0" 
                        color="SECONDARY" size="TN" 
                        (onClick)="doRemoveOTS(i)" 
                        [disabled]="formSaving"
                        title="{{ 'app.tooltip.delete' | translate }}">
                        <em class="fa fas fa-trash"></em>
                      </app-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="text-center" *ngIf="!formGroup.isView">
            <button class="btn btn-primary" (click)="addCredsOTS()"
              [disabled]="formSaving"
              ><em class="fas fa-plus"></em>
              {{ 'due-diligence.button.addMore' | translate }}</button
            >
          </div>

          <!--Executor OTS HTML Table-->
          <div
            class="table-responsive custom-table float-none"
            [ngStyle]="{ margin: '0 auto' }"
            *ngIf="formGroup.isView"
          >
            <table
              class="table table-striped box-shadow"
              aria-describedby="Table"
            >
              <thead>
                <tr>
                  <th
                    scope="col"
                    [ngStyle]="{ width: '250px' }"
                    class="text-center"
                    >{{ 'app.table.column.no' | translate }}
                  </th>
                  <th scope="col" [ngStyle]="{ width: '400px' }">{{
                    'due-diligence.table.column.name' | translate
                  }}</th>
                  <th scope="col">{{
                    'due-diligence.table.column.position' | translate
                  }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="dueDiligenceResponse.dueDiligenceExecutorOTSList.length == 0">
                  <td colspan="99" class="bg-white text-center">{{
                    'app.info.noData' | translate
                  }}</td>
                </tr>
                <tr
                  *ngFor="
                    let data of dueDiligenceResponse.dueDiligenceExecutorOTSList;
                    let i = index
                  "
                >
                  <td class="text-center">{{ i + 1 }}.</td>
                  <td>{{ data.user ? data.user.name : '-' }}</td>
                  <td>{{ data.position !== '' ? data.position : '-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-fieldset>

        <app-fieldset
          legend="{{ 'due-diligence.form.schedule' | translate }} - Interview"
          *ngIf="showScheduleInterview"
        >
          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView ? 'required' : ''"
          >
            <label class="col-sm-3 control-label">{{
              'due-diligence.form.address' | translate
            }}</label>
            <div
              class="text-left"
              [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'"
            >
              <app-text-area maxLength="512" formControlName="addressInterview">
              </app-text-area>
            </div>
          </div>
          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView ? 'required' : ''"
          >
            <label class="col-sm-3 control-label">{{
              'due-diligence.form.date' | translate
            }}</label>
            <div class="col-sm-9 col-lg-7 text-left">
              <app-date-picker
                minDate="currentDateInterview"
                formControlName="dateInterview"
              ></app-date-picker>
            </div>
          </div>

          <!-- time if isView false -->
          <div *ngIf="!formGroup.isView" class="form-group row text-sm-right">
            <div
              class="col-sm-3 mb-3 pr-1 align-items-center"
              [ngStyle]="{
                display: 'grid',
                'padding-right': '0.4rem',
                height: '72px'
              }"
            >
              <label class="control-label">
                {{ 'due-diligence.form.time' | translate
                }}<span *ngIf="!formGroup.isView" [ngStyle]="{ color: 'red' }">
                  *</span
                >
              </label>
            </div>
            <div class="form-group row col-sm-9 text-left">
              <label
                class="px-3 align-items-center"
                [ngStyle]="{ display: 'grid', height: '72px' }"
                ><strong>{{
                  'due-diligence.form.from' | translate
                }}</strong></label
              >
              <div [ngStyle]="{ 'max-width': '135px' }">
                <app-time formControlName="startTimeInterview"></app-time>
              </div>
              <label
                class="ml-3 mr-3 align-items-center"
                [ngStyle]="{ display: 'grid', height: '72px' }"
                ><strong>{{ 'due-diligence.form.until' | translate }}</strong></label
              >
              <div [ngStyle]="{ 'max-width': '135px' }">
                <app-time formControlName="endTimeInterview"></app-time>
              </div>
              <label
                class="ml-3 align-items-center"
                [ngStyle]="{ display: 'grid', height: '72px' }"
                ><strong>WIB</strong></label
              >
            </div>
          </div>

          <div *ngIf="formGroup.isView" class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'due-diligence.form.time' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <strong>{{ 'due-diligence.form.from' | translate }}</strong>
              {{
                getHours(dueDiligenceResponse.dueDiligenceScheduleInterview?.startTime)
              }}
              WIB <strong>{{ 'due-diligence.form.until' | translate }}</strong>
              {{
                getHours(dueDiligenceResponse.dueDiligenceScheduleInterview?.endTime)
              }}
              WIB
            </div>
          </div>

          <div
            class="form-group row text-sm-right"
            [ngClass]="!formGroup.isView ? 'required' : ''"
          >
            <label class="col-sm-3 control-label">{{
              'due-diligence.form.objective' | translate
            }}</label>
            <div
              class="text-left"
              [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'"
            >
              <app-text-area
                maxLength="512"
                formControlName="objectivesInterview"
              ></app-text-area>
            </div>
          </div>
          <br />
          <h5 class="separator"
            >{{ 'due-diligence.form.executor' | translate }}
          </h5>
          <div class="form-group required" *ngIf="!formGroup.isView">
            <label class="col-sm-3"></label>
            <label class="col-sm-2 control-label">{{
              'due-diligence.table.column.name' | translate
            }}</label>
            <label class="col-sm-2"
              ><strong>{{
                'due-diligence.table.column.position' | translate
              }}</strong></label
            >
          </div>
          <ng-container
            formArrayName="credentialsInterview"
            *ngIf="!formGroup.isView"
          >
            <div
              class="form-group row text-sm-right"
              *ngFor="let creds of inputListInterview.controls; let i = index"
              [formGroupName]="i"
            >
              <label class="col-sm-3 control-label"></label>
              <div class="col-sm-9 col-lg-7 text-left">
                <div class="form-row">
                  <div class="form-group row col-4">
                    <div class="col text-left">
                      <app-choose
                        size="XL"
                        formControlName="name"
                        (onClick)="doChooseUserExecutorInterview(i)"
                      >
                      </app-choose>
                    </div>
                  </div>
                  <div class="form-group row col-4">
                    <div class="col text-left">
                      <!-- <app-text-field maxLength="32" size="XL" formControlName="position"></app-text-field> -->
                      <label
                        *ngIf="
                          !creds.value.position || creds.value.position == ''
                        "
                        >&nbsp; -
                      </label>
                      <label
                        >&nbsp;
                        {{
                          inputListInterview.at(i).get('position').value
                        }}</label
                      >
                    </div>
                  </div>
                  <div class="form-group row col-4">
                    <div class="col text-left">
                      <app-button *ngIf="i !== 0" 
                        color="SECONDARY" size="TN" 
                        (onClick)="doRemoveInterview(i)" 
                        [disabled]="formSaving"
                        title="{{ 'app.tooltip.delete' | translate }}">
                        <em class="fa fas fa-trash"></em>
                      </app-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="text-center" *ngIf="!formGroup.isView">
            <button class="btn btn-primary" (click)="addCredsInterview()"
              [disabled]="formSaving"
              ><em class="fas fa-plus"></em>
              {{ 'due-diligence.button.addMore' | translate }}</button
            >
          </div>

          <!--Executor Interview HTML Table-->
          <div
            class="table-responsive custom-table float-none"
            [ngStyle]="{ margin: '0 auto' }"
            *ngIf="formGroup.isView"
          >
            <table
              class="table table-striped box-shadow"
              aria-describedby="Table"
            >
              <thead>
                <tr>
                  <th
                    scope="col"
                    [ngStyle]="{ width: '250px' }"
                    class="text-center"
                    >{{ 'app.table.column.no' | translate }}
                  </th>
                  <th scope="col" [ngStyle]="{ width: '400px' }">{{
                    'due-diligence.table.column.name' | translate
                  }}</th>
                  <th scope="col">{{
                    'due-diligence.table.column.position' | translate
                  }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngIf="
                    dueDiligenceResponse.dueDiligenceExecutorInterviewList.length == 0
                  "
                >
                  <td colspan="99" class="bg-white text-center">{{
                    'app.info.noData' | translate
                  }}</td>
                </tr>
                <tr
                  *ngFor="
                    let data of dueDiligenceResponse.dueDiligenceExecutorInterviewList;
                    let i = index
                  "
                >
                  <td class="text-center">{{ i + 1 }}.</td>
                  <td>{{ data.user ? data.user.name : '-' }}</td>
                  <td>{{ data.position !== '' ? data.position : '-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-fieldset>

        <ng-container
          *ngIf="
            !formGroup.isView &&
            dueDiligenceResponse.isPreviousDueDiligenceExist &&
            !dueDiligenceResponse.isTemplateDifferent
          "
        >
          <div class="form-group row ml-4">
            <app-check-box formControlName="isSendEmailDueDiligence">
            </app-check-box>
            <label class="col-sm-5 text-left control-label">{{
              'due-diligence.form.sendEmailDueDiligence' | translate
            }}</label>
          </div>
        </ng-container>
      </form>

      <div *ngIf="formGroup.isView && !dueDiligenceResponse.isUserApproval">
        <div class="button-group button-group-center mb-3">
          <app-button
            *ngIf="isOTS && dueDiligenceResponse.dueDiligenceScheduleOTS"
            color="PRIMARY"
            [disabled]="isLoadingOTS"
            (onClick)="doPrint(dueDiligenceResponse.dueDiligenceScheduleOTS)"
            mode="BYPASS"
            >{{ 'due-diligence.button.printOTS' | translate }}
          </app-button>
          <app-button
            *ngIf="isInterview && dueDiligenceResponse.dueDiligenceScheduleInterview"
            color="PRIMARY"
            [disabled]="isLoadingInterview"
            (onClick)="doPrint(dueDiligenceResponse.dueDiligenceScheduleInterview)"
            mode="BYPASS"
            >{{ 'due-diligence.button.printInterview' | translate }}
          </app-button>
        </div>
        <div class="button-group button-group-center">
          <app-button
            color="SECONDARY"
            (onClick)="doBack()"
            [disabled]="formSaving"
            mode="BYPASS"
            >{{ 'app.button.back' | translate }}</app-button
          >
        </div>
      </div>

      <div *ngIf="!formGroup.isView" class="button-group button-group-center">
        <app-button
          color="SECONDARY"
          (onClick)="doBack()"
          [disabled]="formSaving"
          mode="BYPASS"
          >{{ 'app.button.back' | translate }}</app-button
        >
        <app-button
          color="PRIMARY"
          (onClick)="doSaveDueDiligence()"
          [disabled]="formSaving"
          >{{ 'app.button.save' | translate }}
        </app-button>
      </div>

      <div *ngIf="dueDiligenceResponse.isUserApproval" class="button-group button-group-center">
        <app-button
          color="SECONDARY"
          (onClick)="doBackApproval()"
          [disabled]="formSaving"
          mode="BYPASS"
          >{{ 'app.button.back' | translate }}</app-button>
      </div>
      </div> <!-- !formLoading -->
    </app-card>
  </div>
</div>
