import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupChangeEmailUserModule } from '../user/app-popup-change-email-user/app-popup-change-email-user.module';
import { UserEditAddComponent } from './user-edit-add.component';
import { UserComponent } from './user.component';
const routes = [
  { path: '', component: UserComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: UserEditAddComponent,
    data: { breadcrumb: 'user.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: UserEditAddComponent,
    data: { breadcrumb: 'user.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppPopupChangeEmailUserModule
  ],
  declarations: [UserComponent, UserEditAddComponent]
})
export class UserModule {}
