import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Region } from 'src/app/core/bean/region';
import { TextUtils } from 'src/app/core/utils';

@Component({
    templateUrl: 'app-popup-catalog-info-location.component.html',
    encapsulation: ViewEncapsulation.None
})
export class AppPopupCatalogInfoLocationComponent extends BaseComponentComponent implements OnChanges {
    @Input() public todo: string;
    @Input() public checkedRegionCodeList: string[] = [];
    @Input() public locationDefChecklist: any[];
    @Input() public dataCheckedList: any[];
    @Output() public onChange: EventEmitter<string[]> = new EventEmitter();

    public regionNameList: string[] = [];
    public readonly allRegionCheckList = []; /** display only */
    public checkedList = []; /** to be send */
    public generatedId: string;
    public dynamicRegionList = []; /** dynamic checklist shown and search */
    public charList = [];
    public regionList: Region[] = [];

    constructor(public translateService: TranslateService) {
        super('app-catalog-info');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.keywordLocation.currentValue) {
            this.onInputSearch(changes.keywordLocation.currentValue);
        }
    }

    public onInit(): void {
        this.generateCharArray();
        this.setInitialState();
        //this.setDefaultStaticCheckboxList();
        this.buildAndSetFormGroup();
        this.setRegionChecklist();

        this.formGroup.valueChanges.subscribe(value => {
            this.log.debug('formGroup');
            this.log.debug(value);
            if (!value.keywordLocation) {
                value.keywordLocation = '';
            }
            this.onInputSearch(value.keywordLocation);
        });
    }

    public setInitialState(): void {
        this.generatedId = TextUtils.generateRandomString();
    }

    public setDefaultStaticCheckboxList(): void {
        this.locationDefChecklist.forEach(loc => {
            loc.isChecked = false;
        });
    }

    public generateCharArray(): void {
        let i = 'A'.charCodeAt(0);
        const j = 'Z'.charCodeAt(0);
        for (; i <= j; ++i) {
            this.charList.push(String.fromCharCode(i));
        }
    }

    public getGroupedList(dynamicRegionList: any[], char: string): object[] {
        return dynamicRegionList.filter(reg => this.isMatchGroup(reg.name, char));
    }

    public setRegionChecklist(): void {
        this.checkedList = this.dataCheckedList;
        this.httpClientService
        .get('/app-catalog-info/get-region-list')
        .subscribe((regionList: Region[]) => {
            this.regionList = regionList;

            // this.regionNameList = regionList.map(region => region.name);

            this.regionList.forEach(region => {
                this.allRegionCheckList.push({
                  name: region.name,
                  code: region.code,
                  isChecked: false
                });

              });

            this.checkedRegionCodeList.forEach(checkedRegionCode => {
                const regCheck = this.allRegionCheckList.filter(regCheck => regCheck.code === checkedRegionCode)[0];
                if (regCheck) {
                    const indexRegCheck = this.allRegionCheckList.indexOf(regCheck);
                    if (indexRegCheck !== -1) {
                        this.allRegionCheckList[indexRegCheck].isChecked = true;
                    }
                }
            });

            this.dynamicRegionList = this.allRegionCheckList;
            this.setStateReady();
        });

    }

    public buildAndSetFormGroup(): void {
        this.formGroup = this.formBuilder.group({
            keywordLocation: [null]
        });
    }

    public onInputSearch(keywordLocation: string): void {
        this.log.debug(keywordLocation);

        setTimeout(() => {
            const matchRegList: Region[] = this.regionList.filter(region => region.name.toLowerCase().includes(keywordLocation.toLowerCase()));
            const regionCheckList = [];
            matchRegList.forEach(matchReg => {
                const matchCheckInOldFormGroup = this.checkedList.filter(
                    regCheck => regCheck.code === matchReg.code )[0];

                regionCheckList.push({
                    name: matchReg.name,
                    code: matchReg.code,
                    isChecked: matchCheckInOldFormGroup ? matchCheckInOldFormGroup.isChecked : false
                });
            });

            this.dynamicRegionList = regionCheckList; // like patchvalue
        }, 700);

    }

    public handleChange(event: any, name: string): void {
        const value: string = event.target.value;
        if (event.target.checked) { // true
            if (value.includes(',')) {
                value.split(',').forEach(val => {
                    this.checkedList.push({
                        code: val,
                        isChecked: true,
                        name: name
                    });
                });
            } else {
                this.checkedList.push({
                    code: value,
                    isChecked: true,
                    name: name
                });
            }

        } else {
            this.checkedList.forEach((checked, i) => {
                if (checked.code === value) {
                    this.checkedList.splice(i, 1);

                } else {
                    /* array */
                    const arrValue: string[] = value.split(',');
                    arrValue.forEach(val => {
                        const valIndex = this.checkedList.indexOf(this.checkedList.filter(check => check.code === val)[0]);
                        if (valIndex !== -1) {
                            this.checkedList.splice(valIndex, 1);
                        }

                        const dynIndex = this.dynamicRegionList.indexOf(this.dynamicRegionList.filter(dynReg => dynReg.code === val)[0]);
                        if (dynIndex !== -1) {
                            this.dynamicRegionList[dynIndex].isChecked = false;
                        }
                    });

                }
            });

            // const foundIndex = this.checkedList.indexOf(this.checkedList.filter(checked => checked.code === event.target.value)[0]);
            // foundIndex !== -1 ? this.checkedList.splice(foundIndex, 1) : this.log.debug('nothing to uncheck');
            // this.checkedList = this.checkedList.filter(checkedReg => checkedReg.code !== event.target.value);
        }

        this.log.debug(event.target.value);
        this.log.debug(this.checkedList);
    }

    public doApply(): void {
        this.formGroup.patchValue({
            keywordLocation: ''
        });

        this.onChange.emit(this.checkedList);
    }

    public doReset(): void {
        this.formGroup.get('keywordLocation').reset();
        const prevCheckList = this.dynamicRegionList.filter(reg => reg.isChecked);
        prevCheckList.forEach(prevCheck => {
            const index = this.dynamicRegionList.indexOf(prevCheck);
            index !== -1 ? this.dynamicRegionList[index].isChecked = false : this.log.debug('no previous checklist exists');
        });
        this.checkedList = this.dynamicRegionList;

        this.setDefaultStaticCheckboxList();
    }

    public isMatchGroup(name: string, char: string): boolean {
        if (name.toUpperCase().includes('KOTA ') || name.toUpperCase().includes('KABUPATEN ')) {
            const simpleName = name.replace('KOTA ', '').replace('KABUPATEN ', '');
            // this.log.debug(simpleName);
            return simpleName.charAt(0).toUpperCase() === char;
        } else {
            return name.charAt(0).toUpperCase() === char;
        }
    }
}
