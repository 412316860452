<app-card
  *ngIf="isShowNextProcess"
  header="app-agreement-type.title"
  tips="app-agreement-type.title.detail.agrementType.tips"
>
  <app-alert-x color="WARNING" *ngIf="isAgreementTypeNull">{{
    'app-agreement-type.alertInfo' | translate
  }}</app-alert-x>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <app-alert-x >
      {{ 'app-agreement-type.alert.infoSelectAgreementType' | translate }}
    </app-alert-x>
    <app-select
      [isMultiple]="false"
      formControlName="agreementType"
      [optionList]="agreementTypeOptionList"
      maxHeight="max-content"
      [isShowBorder]="true" 
      (onChange)="doChooseAgreementType($event)"
      [isShowInputControl]="isShowInputControl"
    >
      <ng-template #content let-data> 
        <div>
          <h4>{{
            data.nameTranslationKey.module.code.toLowerCase() +
              '.' +
              data.nameTranslationKey.key | translate
          }}</h4>
          <p>{{
            data.descTranslationKey.module.code.toLowerCase() +
              '.' +
              data.descTranslationKey.key | translate
          }}</p>
        </div>
      </ng-template>
    </app-select>
  </form>
</app-card>