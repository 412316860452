<div class="app-upload p-0" [ngClass]="elementWidthClassName">
  <ng-container
    *ngIf="
      !ISVIEW && model.uploaderFileList.length === 0;
      then uploadWithNoFileTmpl;
      else uploadWithFileTmpl
    "
  >
  </ng-container>
  <p
    [ngStyle]="{ height: 0 }"
    *ngIf="
      model.uploaderFileList.length > 0 &&
      model.uploaderFileList[0].status === 'ONPROGRESS'
    "
  >
    <ngb-progressbar
      type="success"
      textType="white"
      [value]="model.uploaderFileList[0].uploaderProgress"
      [showValue]="true"
    >
    </ngb-progressbar>
  </p>
  <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
  </ng-container>
</div>
<small class="text-danger" *ngIf="!ISVIEW && isInvalid">
  {{ errors.message }}
</small>
<ng-template #appTooltipWrapperTemplateOutlet>
  <span *ngIf="ISVIEW" class="mx-1">
    <ng-container *ngTemplateOutlet="appFlagNew"></ng-container>
  </span>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-container *ngIf="!ISVIEW">
        <ng-container *ngTemplateOutlet="appFlagNew"></ng-container>
      </ng-container>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>

<ng-template #appFlagNew>
  <ng-content select="app-flag-new"></ng-content>
</ng-template>

<ng-template #uploadWithNoFileTmpl>
  <div class="custom-file">
    <input
      type="file"
      class="custom-file-input"
      (change)="onUploadChange($event)"
      [attr.id]="generatedId"
      [ngClass]="[
        elementHeightClassName,
        isInvalid ? 'is-invalid' : '',
        isValid ? 'is-valid' : ''
      ]"
      [disabled]="disabled"
      [attr.title]="
        ('app-upload.title.fileExtension' | translate) +
        model.options.allowedExtension +
        '\n' +
        ('app-upload.title.maxSize' | translate) +
        (model.options.maxSize / 1000000 | number: '1.2-2') +
        'MB'
      "
      aria-label="Choose File"
    />
    <label
      class="custom-file-label"
      for="{{ generatedId }}"
      [ngClass]="disabled ? 'disabled' : ''"
    ></label>
  </div>
</ng-template>

<ng-template #uploadWithFileTmpl>
  <ul class="upload-content">
    <li
      class="upload-item"
      *ngFor="let uploaderFile of model.uploaderFileList; let i = index"
    >
      <div class="item-content">
        <div class="item-content-image">
          <img
            [attr.src]="
              uploaderFile.isNew || uploaderFile.isChange
                ? uploaderFile.src
                : (uploaderFile | resolveUploadSrc | async)
            "
            [attr.alt]="uploaderFile.file.fileName"
            class="img-thumbnail"
            width="0"
            height="0"
          />
        </div>
        <span class="item-content-text">
          <ng-container
            *ngIf="
              uploaderFile.status === 'UPLOADED';
              then uploadedTextTmpl;
              else notUploadedTextTmpl
            "
          >
          </ng-container>
          <ng-template #uploadedTextTmpl>
            <a
              [attr.href]="uploaderFile | resolveUploadHref"
              (click)="doDownloadFile($event, uploaderFile)"
              maxLine
              [attr.title]="
                uploaderFile.file.fileName +
                ' ' +
                (uploaderFile.file.size / 1000000 | number: '1.2-2') +
                'MB'
              "
            >
              <ng-container *ngTemplateOutlet="uploadedText"></ng-container>
            </a>
          </ng-template>
          <ng-template #notUploadedTextTmpl>
            <ng-container *ngTemplateOutlet="uploadedText"></ng-container>
          </ng-template>
          <ng-template #uploadedText>
            {{ uploaderFile.file.fileName }}
            <small *ngIf="!ISVIEW"
              >-
              {{ uploaderFile.file.size / 1000000 | number: '1.2-2' }} MB</small
            >
          </ng-template>
          <div class="d-block">
            <span
              class="badge"
              [ngClass]="uploadStatusColor[uploaderFile.status]"
              *ngIf="uploaderFile.isNew"
              >{{ uploaderFile.status }}</span
            >
          </div>
        </span>
      </div>
      <div class="item-action" *ngIf="!ISVIEW">
        <div
          class="button-group"
          [ngClass]="
            uploaderFile.status === 'UPLOADED'
              ? 'button-group-right'
              : 'button-group-center'
          "
        >
          <input
            #inputFileChange
            type="file"
            (change)="onUploadChange($event, i)"
            aria-label="Change File"
            hidden
          />
          <ng-container
            *ngIf="
              uploaderFile.status === 'CANCELED' ||
              uploaderFile.status === 'FAILED'
            "
          >
            <app-button size="TN" (onClick)="doReupload(uploaderFile)">
              <em class="fas fa-redo-alt"></em>
            </app-button>
          </ng-container>
          <ng-container
            *ngIf="
              uploaderFile.status === 'UPLOADED' ||
              uploaderFile.status === 'CANCELED' ||
              uploaderFile.status === 'FAILED'
            "
          >
            <app-button
              size="TN"
              (onClick)="inputFileChange.click()"
              [attr.title]="
                ('app-upload.title.fileExtension' | translate) +
                model.options.allowedExtension +
                '\n' +
                ('app-upload.title.maxSize' | translate) +
                (model.options.maxSize / 1000000 | number: '1.2-2') +
                'MB'
              "
            >
              <em class="fas fa-folder-open"></em>
            </app-button>
            <app-button
              size="TN"
              color="DANGER"
              (onClick)="doDeleteFile(i)"
              [attr.title]="'app.tooltip.delete' | translate"
            >
              <em class="fas fa-trash"></em>
            </app-button>
          </ng-container>
          <ng-container *ngIf="uploaderFile.status === 'ONPROGRESS'">
            <app-button
              color="WARNING"
              size="TN"
              (onClick)="doCancelUpload(uploaderFile)"
            >
              <em class="fas fa-times"></em>
            </app-button>
          </ng-container>
        </div>
      </div>
    </li>
    <li class="upload-item" *ngIf="model.uploaderFileList.length === 0">-</li>
  </ul>
</ng-template>
