import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppSignatureComponent } from './app-signature.component';

@NgModule({
  imports: [VendorModule, AppButtonModule, PipesModule, DirectivesModule],
  declarations: [AppSignatureComponent],
  exports: [AppSignatureComponent]
})
export class AppSignatureModule {}
