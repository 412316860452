<div class="app-pagination-x d-flex align-items-center" *ngIf="model">
  <div class="app-pagination-left" *ngIf="contentLeftTemplate">
    <ng-container *ngTemplateOutlet="contentLeftTemplate"></ng-container>
  </div>
  <div class="app-pagination-right d-flex">
    <div class="form-group d-flex align-items-center m-0 mr-1">
      <span class="pagination-page-text">
        {{ 'app.pagination.page' | translate }}
      </span>
      <input
        class="form-control form-control-sm page-filter"
        [value]="model.page"
        (change)="onPageChanges($event.target.value)"
      />
      <span class="pagination-page-of-total">
        {{ 'app.pagination.of' | translate }} {{ model.totalPage }}</span
      >
    </div>
    <ngb-pagination
      [collectionSize]="model.totalRecords"
      [(page)]="model.page"
      [pageSize]="model.perPage"
      [maxSize]="5"
      (pageChange)="onPageChanges($event)"
      [rotate]="true"
    >
      <ng-template ngbPaginationPrevious>
        <em class="pir pi-chevron-left"></em>
      </ng-template>
      <ng-template ngbPaginationNext>
        <em class="pir pi-chevron-right"></em>
      </ng-template>
    </ngb-pagination>
  </div>
</div>
