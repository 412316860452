import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { PurchaseOrder } from '../bean/purchase-order';
import { PurchaseOrderPaymentDetail } from '../bean/purchase-order-payment-detail';
import { FileObject } from '../components/upload';
export class PurchaseOrderPaymentEntity extends BaseEntity {
    fileObjectList: FileObject[];
    itemName: string;
    paymentAmount: number;
    purchaseOrderPaymentDetailList: PurchaseOrderPaymentDetail[];
    purchaseOrder: PurchaseOrder;
    file: File;
    terminName: string;
    paymentDate: number;
}
