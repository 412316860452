export class TableStore {
  recordMap: Map<string, any> = new Map();
  public getRecords(): Array<any> {
    const records = new Array();
    this.recordMap.forEach(value => {
      records.push(value);
    });
    return records;
  }
}
