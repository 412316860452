import { NgModule } from '@angular/core';
import { ReadMorePipe } from './read-more.pipe';
import { ResolveFieldDataPipe } from './resolve-field-data.pipe';
import { ResolveFormArrayPipe } from './resolve-form-array.pipe';
import { ResolveImgSrc } from './resolve-img-src.pipe';
import { ResolveInputIsCheckedPipe } from './resolve-input-is-checked.pipe';
import { ResolveNumberToCurrencyPipe } from './resolve-number-to-currency.pipe';
import { ResolveNumberToDecimalPipe } from './resolve-number-to-decimal.pipe';
import { ResolveNumberToWordsPipe } from './resolve-number-to-words.pipe';
import { SearchPipe } from './search.pipe';
@NgModule({
  declarations: [
    ResolveFieldDataPipe,
    ResolveFormArrayPipe,
    ReadMorePipe,
    ResolveImgSrc,
    ResolveNumberToWordsPipe,
    ResolveInputIsCheckedPipe,
    ResolveNumberToCurrencyPipe,
    ResolveNumberToDecimalPipe,
    SearchPipe
  ],
  providers: [ResolveFieldDataPipe, ResolveInputIsCheckedPipe],
  exports: [
    ResolveFieldDataPipe,
    ResolveFormArrayPipe,
    ReadMorePipe,
    ResolveImgSrc,
    ResolveNumberToWordsPipe,
    ResolveInputIsCheckedPipe,
    ResolveNumberToCurrencyPipe,
    ResolveNumberToDecimalPipe,
    SearchPipe
  ]
})
export class PipesModule {}
