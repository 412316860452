import { Payment } from 'src/app/core/bean/payment';
import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { PaymentBillingDoc } from 'src/app/core/bean/payment-billing-doc';
import { PaymentInvoice } from 'src/app/core/bean/payment-invoice';
import { SowPaymentTermBilling } from 'src/app/core/bean/sow-payment-term-billing';
import { FileObject } from 'src/app/core/components/upload';

export class InternalPaymentRequestInsertRequest {
  paymentInvoice: PaymentInvoice;
  payment: Payment = new Payment();
  paymentBilling: PaymentBilling = new PaymentBilling();
  paymentBillingDocList: PaymentBillingDoc[];
  sowPaymentTermBillingList: SowPaymentTermBilling[];
  fileObjectList: FileObject[];
  sowPaymentTermId: number;
  procurementVendorId: number;
  isSubmit: boolean;
}
