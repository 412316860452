import { BaseEntity } from '../base/base-entity';
import { DueDiligenceSchedule } from '../bean/due-diligence-schedule';

export class DueDiligenceRealizationEntity extends BaseEntity {
    dueDiligenceSchedule: DueDiligenceSchedule = new DueDiligenceSchedule();
    date: Date;
    startTime: Date;
    endTime: Date;
    note: string;
    realizationNumber: string;
}
