import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppDeliverableItemModule } from 'src/app/core/components/app-deliverable-item/app-deliverable-item.module';
import { AppVendorPaymentInformationModule } from 'src/app/core/components/app-vendor-payment-information/app-vendor-payment-information.module';
import { AppApprovalPrcsXModule } from '../../core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppProcurementInformationViewModule } from '../../core/components/app-procurement-information-view/app-procurement-information-view.module';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupVendorPaymentRequestChooseBillComponent } from './app-popup-vendor-payment-request-choose-bill.component';
import { VendorPaymentRequestEditAddComponent } from './vendor-payment-request-edit-add.component';
import { VendorPaymentRequestComponent } from './vendor-payment-request.component';

export const routes = [
  {
    path: '',
    component: VendorPaymentRequestComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'add',
    component: VendorPaymentRequestEditAddComponent,
    data: { breadcrumb: 'vendor-payment-request.breadcrumb.add' }
  },
  {
    path: 'edit',
    component: VendorPaymentRequestEditAddComponent,
    data: { breadcrumb: 'vendor-payment-request.breadcrumb.edit' }
  },
  {
    path: 'detail',
    component: VendorPaymentRequestEditAddComponent,
    data: { breadcrumb: 'vendor-payment-request.breadcrumb.detail' }
  },
  {
    path: 'detail/:token',
    component: VendorPaymentRequestEditAddComponent,
    data: { breadcrumb: 'vendor-payment-request.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppProcurementInformationViewModule,
    AppDeliverableItemModule,
    AppVendorPaymentInformationModule,
    AppApprovalPrcsXModule
  ],
  declarations: [
    VendorPaymentRequestComponent,
    VendorPaymentRequestEditAddComponent,
    AppPopupVendorPaymentRequestChooseBillComponent
  ],
  entryComponents: [AppPopupVendorPaymentRequestChooseBillComponent],
  exports: [AppPopupVendorPaymentRequestChooseBillComponent]
})
export class VendorPaymentRequestModule {}
