import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupEquipmentComponent } from './popup/app-popup-equipment/app-popup-equipment.component';
import { AppPopupExpertComponent } from './popup/app-popup-expert/app-popup-expert.component';
import { TabProfileExpertAndEquipmentComponent } from './tab-profile-expert-and-equipment.component';

export const routes = [
  {
    path: '',
    component: TabProfileExpertAndEquipmentComponent,
    data: { breadcrumb: '' }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    TabProfileExpertAndEquipmentComponent,
    AppPopupEquipmentComponent,
    AppPopupExpertComponent
  ],
  entryComponents: [
    AppPopupEquipmentComponent,
    AppPopupExpertComponent
  ],
  exports: [
    TabProfileExpertAndEquipmentComponent,
    AppPopupEquipmentComponent,
    AppPopupExpertComponent
  ]
})

export class TabProfileExpertAndEquipmentModule {}
