import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Counter } from '../../core/bean/counter';
import { ExecutorAssignment } from '../../core/bean/executor-assignment';
import { User } from '../../core/bean/user';
import { UserExecutor } from '../../core/bean/user-executor';
import { Verificator } from '../../core/bean/verificator';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { AppPopupAddExecutorAssignmentComponent } from './app-popup-add-executor-assignment.component';
import { AppPopupAddUserExecutorComponent } from './app-popup-add-user-executor.component';
import { ExecutorRequest } from './executor-request';
import { ExecutorResponse } from './executor-response';

@Component({
  templateUrl: './executor-edit-add.component.html'
})
export class ExecutorEditAddComponent extends BaseModuleComponent {
  public tableResponseUserExecutor: TableResponseModel<UserExecutor>;
  public tableResponseExecutorAssignment: TableResponseModel<ExecutorAssignment>;
  public verificator: Verificator = new Verificator();
  public userExecutorList: UserExecutor[] = [];
  public executorAssignmentList: ExecutorAssignment[] = [];
  public userList: User[] = [];
  public counterList: Counter[] = [];
  public userExecutorOptionList: OptionListModel<UserExecutor> =
    new OptionListModel(true);
  public executorResponseTemp: ExecutorResponse;
  public executorRequest: ExecutorRequest;
  public urlBackOutside: string;
  public isNoUserExecutor = false;
  public isNoExecutorAssignment = false;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('executor', translateService);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setDataFromRouterParams();
    this.doBuildTableResponse();
    this.setFormGroup();
    this.setStateReady();
  }
  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      verificator: [null],
      userExecutorList: [null],
      executorAssignmentList: [null]
    });
  }
  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.verificator = this.global.routerParams.get('verificator');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
  }
  public doBuildTableResponse(): void {
    this.tableResponseUserExecutor = new TableResponseModel(this.moduleCode, [
      {
        field: 'user.name',
        header: 'table.column.user-executor-name'
      }
    ]);
    this.tableResponseExecutorAssignment = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'userExecutor.user.name',
          header: 'table.column.executor-assignment-name'
        },
        {
          field: 'counter.name',
          header: 'table.column.executor-assignment-counter'
        }
      ]
    );
  }
  public setFormGroup(): void {
    this.httpClientService
      .post<ExecutorResponse>(
        '/executor/edit',
        new RouteRequestModel(this.todo, this.verificator.id)
      )
      .subscribe((executorResponse: ExecutorResponse) => {
        this.executorResponseTemp = executorResponse;
        if (executorResponse !== null) {
          this.verificator = executorResponse.verificator;
          this.userExecutorList = executorResponse.userExecutorList;
          this.executorAssignmentList = executorResponse.executorAssignmentList;
          this.userList = executorResponse.userList;
          this.counterList = executorResponse.counterList;
          this.formGroup.patchValue({
            verificator: executorResponse.verificator,
            userExecutorList: executorResponse.userExecutorList,
            executorAssignmentList: executorResponse.executorAssignmentList
          });
          this.setTableResponseUserExecutorState(this.userExecutorList);
          this.setTableResponseExecutorAssignmentState( this.executorAssignmentList);
          this.tableResponseUserExecutor.reloadClient();
          this.tableResponseExecutorAssignment.reloadClient();
        }
      });
  }
  private setTableResponseUserExecutorState(userExecutorList: UserExecutor[]): void {
    this.tableResponseUserExecutor.setRecords(userExecutorList);
    this.tableResponseUserExecutor.setTotalRecords(
      userExecutorList.length
    );
  }
  private setTableResponseExecutorAssignmentState(executorAssignmentList: ExecutorAssignment[]): void {
    this.tableResponseExecutorAssignment.setRecords(
      executorAssignmentList
    );
    this.tableResponseExecutorAssignment.setTotalRecords(
      executorAssignmentList.length
    );
  }
  public doAddUserExecutor(): void {
    this.userList = this.userList.filter((user, index) => {
      return this.userList.indexOf(user) === index;
    });
    this.appPopupService
      .open(
        AppPopupAddUserExecutorComponent,
        {
          userExecutorList: this.userExecutorList,
          userList: this.userList,
          verificator: this.verificator,
          executorResponseTemp: this.executorResponseTemp
        },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe((userExecutor: UserExecutor[]) => {
        this.userExecutorList = userExecutor;
        const userExecutorListTemp = this.userExecutorList.filter(userExecutor => {
          return userExecutor.crudOperation !== this.global.appConstant.core.CRUD_OPERATION_DELETE;
        });
        this.isNoUserExecutor = false;
        this.tableResponseUserExecutor.setRecords(userExecutorListTemp);
        this.tableResponseUserExecutor.setTotalRecords(userExecutorListTemp.length);
        this.tableResponseUserExecutor.reloadClient();
      });
  }
  public doAddExecutorAssignment(): void {
    this.counterList = this.counterList.filter((counter, index) => {
      return this.counterList.indexOf(counter) === index;
    });
    this.appPopupService
      .open(
        AppPopupAddExecutorAssignmentComponent,
        {
          userExecutorList: this.userExecutorList,
          counterList: this.counterList,
          verificator: this.verificator,
          executorAssignmentList: this.executorAssignmentList,
          executorResponseTemp: this.executorResponseTemp
        },
        { size: 'lg', backdrop: 'static' }
      )
      .subscribe((executorAssignment: ExecutorAssignment[]) => {
        this.executorAssignmentList = executorAssignment;
        const executorAssignmentListTemp = this.executorAssignmentList.filter(executorAssignment => {
          return executorAssignment.crudOperation !== this.global.appConstant.core.CRUD_OPERATION_DELETE;
        });
        this.isNoExecutorAssignment = false;
        this.tableResponseExecutorAssignment.setRecords(executorAssignmentListTemp);
        this.tableResponseExecutorAssignment.setTotalRecords(
          executorAssignmentListTemp.length
        );
        this.tableResponseExecutorAssignment.reloadClient();
      });
  }
  public doDeleteUserExecutor(rowData: UserExecutor): void {
    const indexUserExecutor = this.userExecutorList.findIndex(
      i => JSON.stringify(i) === JSON.stringify(rowData)
    );
    if (indexUserExecutor !== -1) {
      this.global.modalService
        .deleteConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.userExecutorList[indexUserExecutor].crudOperation = this.global.appConstant.core.CRUD_OPERATION_DELETE;
            this.userList.push(rowData.user);
            const deletedExecutorAssignmentList =
              this.executorAssignmentList.filter(executorAssignment => {
                return (
                  executorAssignment.userExecutor.user.id === rowData.user.id
                );
              });
            deletedExecutorAssignmentList.forEach(
              (executor: ExecutorAssignment) => {
                this.counterList.push(executor.counter);
              }
            );
            for (const deletedExecutorAssignment of deletedExecutorAssignmentList) {
              for (const executorAssignment of this.executorAssignmentList) {
                if (deletedExecutorAssignment.id === executorAssignment.id) {
                  executorAssignment.crudOperation = this.global.appConstant.core.CRUD_OPERATION_DELETE;
                }
              }
            }
            const userExecutorListTemp = this.userExecutorList.filter(userExecutor => {
              return userExecutor.crudOperation !== this.global.appConstant.core.CRUD_OPERATION_DELETE;
            });
            const executorAssignmentListTemp = this.executorAssignmentList.filter(executorAssignment => {
              return executorAssignment.crudOperation !== this.global.appConstant.core.CRUD_OPERATION_DELETE;
            });
            if (userExecutorListTemp.length === 0) {
              this.tableResponseUserExecutor.page.records =
                userExecutorListTemp;
              this.tableResponseUserExecutor.page.totalRecords =
                userExecutorListTemp.length;
              this.tableResponseExecutorAssignment.reload();
            }
            this.setTableResponseUserExecutorState(userExecutorListTemp);
            this.tableResponseUserExecutor.reloadClient();

            if (executorAssignmentListTemp.length === 0) {
              this.tableResponseExecutorAssignment.page.records =
                executorAssignmentListTemp;
              this.tableResponseExecutorAssignment.page.totalRecords =
                executorAssignmentListTemp.length;
              this.tableResponseExecutorAssignment.reload();
            }
            this.setTableResponseExecutorAssignmentState(executorAssignmentListTemp);
            this.tableResponseExecutorAssignment.reloadClient();

            this.doValidate();
            this.setStateReady();
          }
        });
    }
  }
  public doDeleteExecutorAssignment(rowData): void {
    const index = this.executorAssignmentList.findIndex(
      i => JSON.stringify(i) === JSON.stringify(rowData)
    );
    if (index !== -1) {
      this.global.modalService
        .deleteConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.executorAssignmentList[index].crudOperation = this.global.appConstant.core.CRUD_OPERATION_DELETE;
            this.counterList.push(rowData.counter);
          }
          const executorAssignmentListTemp = this.executorAssignmentList.filter(executorAssignment => {
            return executorAssignment.crudOperation !== this.global.appConstant.core.CRUD_OPERATION_DELETE;
          });
          if (executorAssignmentListTemp.length === 0) {
            this.tableResponseExecutorAssignment.page.records =
              executorAssignmentListTemp;
            this.tableResponseExecutorAssignment.page.totalRecords =
              executorAssignmentListTemp.length;
            this.tableResponseExecutorAssignment.reload();
          }
          this.setTableResponseExecutorAssignmentState(executorAssignmentListTemp);
          this.tableResponseExecutorAssignment.reloadClient();

          this.doValidate();
          this.setStateReady();
        });
    }
  }
  public doSave(): void {
    this.validate();
    this.doValidate();
    if (this.formGroup.valid) {
      if (!this.isNoUserExecutor && !this.isNoExecutorAssignment) {
        this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const executorRequest = new ExecutorRequest();
            const userExecutorChangedList = this.userExecutorList.filter(userExecutor => {
              return (userExecutor.crudOperation === this.global.appConstant.core.CRUD_OPERATION_INSERT || userExecutor.crudOperation === this.global.appConstant.core.CRUD_OPERATION_DELETE);
            });
            const executorAssignmentChangedList = this.executorAssignmentList.filter(executorAssignment => {
              return (executorAssignment.crudOperation ===
                this.global.appConstant.core.CRUD_OPERATION_INSERT || executorAssignment.crudOperation === this.global.appConstant.core.CRUD_OPERATION_DELETE);
            });
            executorRequest.verificator = this.verificator;
            executorRequest.userExecutorList = userExecutorChangedList;
            executorRequest.executorAssignmentList = executorAssignmentChangedList;
            this.setStateProcessing();
            this.httpClientService
              .post<Response<ExecutorRequest>>(
                '/executor/update',
                executorRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/executor/']);
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
      }
    }
  }

  public doValidate(): void {
    if (this.tableResponseUserExecutor.records.length === 0) {
      this.isNoUserExecutor = true;
    } else {
      this.isNoUserExecutor = false;
    }
    if (this.tableResponseExecutorAssignment.records.length === 0) {
      this.isNoExecutorAssignment = true;
    } else {
      this.isNoExecutorAssignment = false;
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/executor/']);
  }
}
