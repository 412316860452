import { Component } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Tc } from '../../core/bean/tc';
import { TcDetail } from '../../core/bean/tc-detail';
import { TermType } from '../../core/bean/term-type';
import { Translation } from '../../core/bean/translation';
import { TableResponseModel } from '../../core/components/app-table-x/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { TcRequest } from './tc-request';
import { TcResponse } from './tc-response';
@Component({
  templateUrl: './tc-edit-add.component.html'
})
export class TcEditAddComponent extends BaseModuleComponent {
  public readonly DELETE: number = 0;
  public readonly UPDATE: number = 1;
  public readonly INSERT: number = 2;
  public tcId: number;
  public langList = [];
  public translationListLabel = [];
  public tcResponse: TcResponse = new TcResponse();
  public tcRequest: TcRequest = new TcRequest();

  public termTypeOptionList: OptionListModel<TermType> = new OptionListModel();

  public termTypeList: TermType[] = [];
  public tableResponse: TableResponseModel<TcDetail>;
  constructor(translateService: TranslateService) {
    super('tc', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.tcId = this.global.routerParams.get('tcId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      langList: this.formBuilder.array([]),
      translationList: this.formBuilder.array([]),
      translationKey: [null],
      description: [null, Validators.maxLength(512)],
      tcDetailList: this.formBuilder.array([])
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'termType',
        header: 'table.column.termType',
        plugins: {
          name: 'combo-box',
          optionList: this.termTypeOptionList,
          validators: Validators.required()
        }
      },
      {
        field: 'condition',
        header: 'table.column.condition',
        plugins: {
          name: 'wysiwyg',
          validators: Validators.required()
        }
      }
    ]);
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<TcResponse>(
        '/tc/add-edit',
        new RouteRequestModel(this.todo, this.tcId)
      )
      .subscribe((tcResponse: TcResponse) => {
        this.langList = tcResponse.langList;
        this.termTypeList = tcResponse.termTypeList;
        this.termTypeOptionList.setRequestValues(tcResponse.termTypeList);
        this.setTranslation(tcResponse);
        if (tcResponse.tc != null) {
          const { id, code, description, translationKey } = tcResponse.tc;
          this.formGroup.patchValue({
            id,
            code,
            description,
            translationKey
          });
          this.tableResponse.setRecords(tcResponse.tcDetailList);
        } else {
          const tcDetail: TcDetail = new TcDetail();
          tcDetail.crudOperation = this.INSERT;
          tcDetail.condition = null;
          tcDetail.termType = null;
          tcDetail.id = Date.now();
          this.tableResponse.records.push(tcDetail);
        }
        this.tableResponse.reload();
        this.setStateReady();
      });
  }

  public setTranslation(tcResponse: TcResponse): void {
    if (tcResponse.translationList.length > 0) {
      tcResponse.translationList.forEach(translation => {
        this.translationListLabel.push(translation.lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: translation.value
          })
        );
      });
    } else {
      tcResponse.langList.forEach(lang => {
        this.translationListLabel.push(lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: [null, Validators.required()]
          })
        );
      });
    }
  }

  public buildFormArray(): void {
    this.tcDetailList.push(this.newTcDetail());
  }

  public get tcDetailList(): FormArray {
    return this.formGroup.get('tcDetailList') as FormArray;
  }

  public newTcDetail(): FormGroup {
    const formGroup = this.formBuilder.group({
      id: [null],
      termType: [null, Validators.required()],
      condition: [null, Validators.required()],
      crudOperation: this.INSERT
    });
    return formGroup;
  }

  public get translationList(): FormArray {
    return this.formGroup.get('translationList') as FormArray;
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const tc: Tc = this.global.copyFormAttributeToModel(
              new Tc(),
              this.formGroup
            );
            this.tcRequest.tc = tc;
            this.tcRequest.translation = this.formGroup
              .get('translationList')
              .value.map((a: Translation) => a.value);
            this.tcRequest.tcDetailList = this.getTcDetailList();
            const url = this.todo === 'edit' ? '/tc/update' : '/tc/insert';
            this.httpClientService
              .post<Response<TcRequest>>(url, this.tcRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccessCreateNew()
                    .subscribe(result => {
                      if (result) {
                        this.global.routerParams.clear();
                        this.global.routerParams.set('todo', 'add');
                        this.router.navigate(['/pages/tc/add/']);
                      } else {
                        this.router.navigate(['/pages/tc/']);
                      }
                    });
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public getTcDetailList(): TcDetail[] {
    const tcDetailList: TcDetail[] = this.formGroup.get('tcDetailList').value;
    this.tableResponse.records.forEach(record => {
      if (record.crudOperation === this.INSERT) {
        record.id = null;
      } else {
        record.crudOperation = this.UPDATE;
      }
      tcDetailList.push(record);
    });
    return tcDetailList;
  }
  public doCancel(): void {
    this.router.navigate(['/pages/tc/']);
  }

  public translateTermType(termType: TermType): string {
    let translateRegType: string = null;
    if (termType) {
      translateRegType = this.global.translateService.instant(
        termType.translationKey.module.code.toLowerCase() +
          '.' +
          termType.translationKey.key
      );
    }
    return translateRegType;
  }

  public doAddTcDetail(): void {
    const tcDetail: TcDetail = new TcDetail();
    tcDetail.crudOperation = this.INSERT;
    tcDetail.condition = null;
    tcDetail.termType = null;
    tcDetail.id = Date.now();
    this.tableResponse.records.push(tcDetail);
    this.tableResponse.reload();
  }

  public doCheckValue(): void {
    /* Check Update field */
    const records = Array.from(this.tableResponse.records).map(r => {
      const indexOfR = this.tableResponse.updatedRecordList.findIndex(
        updateRecord => updateRecord.id === r.id
      );
      return indexOfR === -1
        ? r
        : this.tableResponse.updatedRecordList[indexOfR];
    });

    const termTypeListTmp: TermType[] = records.map(record => record.termType);

    const termTypeOptionListTmp = this.termTypeList.filter(
      termType => !termTypeListTmp.includes(termType)
    );

    this.termTypeOptionList.setRequestValues(termTypeOptionListTmp);

    this.tableResponse.records = records;
  }

  public doDelete(event: any): void {
    event.checkedRecordList.forEach((record: TcDetail) => {
      const indexOfRecord = this.tableResponse.records.findIndex(
        r => r.termType === record.termType
      );
      this.tableResponse.removeAt(indexOfRecord);

      if (record.id) {
        this.tcDetailList.push(
          this.formBuilder.group({
            id: record.id,
            termType: record.termType,
            condition: record.condition,
            crudOperation: this.DELETE
          })
        );
      }
    });
    this.tableResponse.reload();
    const termTypeListTmp: TermType[] = this.tableResponse.records.map(
      record => record.termType
    );

    const termTypeOptionListTmp = this.termTypeList.filter(
      termType => !termTypeListTmp.includes(termType)
    );

    this.termTypeOptionList.setRequestValues(termTypeOptionListTmp);
  }
}
