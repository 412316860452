import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppWidgetContentSliderModule } from '../../core/widgets/app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../../core/widgets/app-widget.module';
import { AppPopupBudgetAllocationBjbComponent } from './app-popup-budget-allocation-bjb.component';
import { AppPopupExportBudgetBjbComponent } from './app-popup-export-budget-allocation-bjb.component';
import { AppPopupSyncBudgetBjbComponent } from './app-popup-sync-budget-allocation-bjb.component';
import { BudgetAllocationBjbCoaComponent } from './budget-allocation-bjb-coa.component';
import { BudgetAllocationBjbEditAddComponent } from './budget-allocation-bjb-edit-add.component';
import { BudgetAllocationBjbOrganizationComponent } from './budget-allocation-bjb-organization.component';
import { BudgetAllocationBjbComponent } from './budget-allocation-bjb.component';
const routes = [
  {
    path: '',
    component: BudgetAllocationBjbComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'add',
    component: BudgetAllocationBjbEditAddComponent,
    data: { breadcrumb: 'budget-allocation-bjb.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: BudgetAllocationBjbEditAddComponent,
    data: { breadcrumb: 'budget-allocation-bjb.breadcrumb.detail' }
  },
  {
    path: 'edit',
    component: BudgetAllocationBjbEditAddComponent,
    data: { breadcrumb: 'budget-allocation-bjb.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'organization/detail/index',
    component: BudgetAllocationBjbOrganizationComponent,
    data: { breadcrumb: 'budget-allocation-bjb.breadcrumb.detail' }
  },
  {
    path: 'coa/detail/index',
    component: BudgetAllocationBjbCoaComponent,
    data: { breadcrumb: 'budget-allocation-bjb.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppWidgetContentSliderModule,
    AppWidgetModule
  ],
  declarations: [
    BudgetAllocationBjbComponent,
    BudgetAllocationBjbEditAddComponent,
    BudgetAllocationBjbCoaComponent,
    BudgetAllocationBjbOrganizationComponent,
    AppPopupBudgetAllocationBjbComponent,
    AppPopupExportBudgetBjbComponent,
    AppPopupSyncBudgetBjbComponent
  ],
  providers: [],
  entryComponents: [
    AppPopupBudgetAllocationBjbComponent,
    AppPopupExportBudgetBjbComponent,
    AppPopupSyncBudgetBjbComponent
  ],
  exports: [
    AppPopupBudgetAllocationBjbComponent,
    AppPopupExportBudgetBjbComponent,
    AppPopupSyncBudgetBjbComponent
  ]
})
export class BudgetAllocationBjbModule {}
