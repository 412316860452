import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Organization } from 'src/app/core/bean/organization';
import { Procurement } from 'src/app/core/bean/procurement';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { ProcurementWorklistStatus } from '../../core/bean/procurement-worklist-status';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { VendorInvitationOptionListResponse } from './vendor-invitation-option-list.response';

@Component({
  templateUrl: './vendor-invitation.component.html'
})
export class VendorInvitationComponent
  extends BaseModuleComponent
  implements OnInit
{
  public tableResponse: TableResponseModel<Procurement>;
  public procurementWorklistStatusOptionList: OptionListModel<ProcurementWorklistStatus> =
    new OptionListModel(true);
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('vendor-invitation', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.buildSetFormGroup();
  }

  public doBuildTableResponse(): void {
    const RE_HELD: string = this.translateService.instant(
      'dynamic-master-attribute.procurementFlag.reHeld'
    );
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.procurementNumber',
        plugins: {
          name: 'hyperlink',
          className: 'white-space-normal',
          onClick: this.doClick.bind(this)
        }
      },
      {
        field: 'procurementDate',
        header: 'table.column.procurementDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'organization.name',
        header: 'table.column.department'
      },
      {
        field: 'name',
        header: 'table.column.procurementName',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'badge',
            field: 'isRepeat',
            pill: false,
            className: 'badge-catalog badge-danger ml-3',
            colorMap: {
              RE_HELD: 'DANGER'
            },
            callbacks: {
              text: (tablePluginData: TablePluginData): string => {
                const procurement = tablePluginData.row.record as Procurement;
                if (procurement.isRepeat) {
                  return RE_HELD;
                }
              }
            }
          },
          {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData): void => {
              const procurement = tablePluginData.row.record as Procurement;
              if (!procurement.isRepeat) {
                tablePluginData.element.children[1].remove();
              }
            }
          }
        ]
      },

      {
        field: 'procurementMethod.name',
        header: 'table.column.procurementMethod'
      },
      {
        field: 'procurementWorklistStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            NEW: 'INFO',
            DRAFT: 'SECONDARY',
            REVISION: 'FEEDBACK',
            WAITING_APPROVAL: 'WARNING'
          },
          colorField: 'procurementWorklistStatus.code'
        }
      }
    ]);
  }

  public buildSetFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      organizationList: [null],
      statusList: [null]
    });

    this.httpClientService
      .get<VendorInvitationOptionListResponse>(
        '/vendor-invitation/get-option-list',
        {}
      )
      .subscribe((response: VendorInvitationOptionListResponse) => {
        this.procurementWorklistStatusOptionList.setRequestValues(
          response.procurementWorklistStatusList
        );
        this.organizationOptionList.setRequestValues(response.organizationList);
        this.setStateReady();
      });
  }

  public onChangeFilter(event: any): void {
    this.log.debug(event);
    const customData = this.formGroup.value;
    customData.dateFrom = this.formGroup.value.date?.from || null;
    customData.dateTo = this.formGroup.value.date?.to || null;

    this.tableResponse.setCustomData(customData);
    this.tableResponse.reload();
  }

  public doClick(procurement: Procurement): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('procurementId', procurement.id);
    this.global.routerParams.set('urlBackOutside', '/pages/vendor-invitation');

    if (
      procurement.procurementWorklistStatus.code ===
      this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_NEW
    ) {
      this.global.routerParams.set('todo', 'add');
      this.router.navigate(['/pages/vendor-invitation/edit']);
    } else if (
      [
        this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT,
        this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION
      ].includes(procurement.procurementWorklistStatus.code)
    ) {
      this.global.routerParams.set('todo', 'edit');
      this.router.navigate(['/pages/vendor-invitation/edit']);
    } else {
      this.global.routerParams.set('todo', 'view');
      this.router.navigate(['/pages/vendor-invitation/detail']);
    }
  }
}
