import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Aanwijzing } from 'src/app/core/bean/aanwijzing';
import { Procurement } from 'src/app/core/bean/procurement';
import { ProcurementItem } from 'src/app/core/bean/procurement-item';
import { AppPopupSignatureService } from 'src/app/core/components/app-popup/app-popup-signature/app-popup-signature.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';
import { SelectedTableRecordModel } from 'src/app/core/components/table/model/selected-table-record-model';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { AanwijzingPopupCreateSessionComponent } from './aanwijzing-popup-create-session.component';
import { AanwijzingRequest } from './request/aanwijzing.request';
import { AanwijzingEditResponse } from './response/aanwijzing-edit.response';

@Component({
  templateUrl: './aanwijzing-edit.component.html'
})
export class AanwijzingEditComponent
  extends BaseModuleComponent
  implements OnInit
{
  public procurementItemList: ProcurementItem[] = [];
  public badgeColor = {
    DRAFT: 'SECONDARY',
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    ON_PROGRESS: 'PROGRESS',
    NOT_STARTED: 'SECONDARY',
    CLOSED: 'DANGER',
    DONE: 'SUCCESS',
    EXTENDED: 'INFO',
    NEW: 'INFO',
    HOLD: 'DANGER',
    CANCELED: 'DANGER'
  };
  public procurementId: number;
  public procurement: Procurement;
  public urlBackOutside: string;
  public objectId: number;
  public approvalModelPrcsId: number;
  public response: AanwijzingEditResponse = new AanwijzingEditResponse();
  public tableResponse: TableResponseModel<Aanwijzing>;
  public noNewSessionStatusList = [
    this.global.appConstant.pm.AANWIJZING_STATUS_DRAFT,
    this.global.appConstant.pm.AANWIJZING_STATUS_NOT_STARTED,
    this.global.appConstant.pm.AANWIJZING_STATUS_ON_PROGRESS,
    this.global.appConstant.pm.AANWIJZING_STATUS_CLOSED
  ];
  public canEditStatusList = [
    this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT,
    this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_ON_PROGRESS,
    this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_NEW,
    this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION
  ];
  public aanwijzingRequest = new AanwijzingRequest();
  public isVendor = false;
  public isInternal = false;
  public canSubmit: boolean;
  public isSubmitted: boolean;
  public isShowBadgeStatus: boolean;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public toastService: ToastService,
    public appPopupSignatureService: AppPopupSignatureService
  ) {
    super('aanwijzing', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.doBuildTableResponse();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.procurement = this.global.routerParams.get('procurement');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.objectId = this.global.routerParams.get('objectId');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    if (this.objectId) {
      this.procurementId = this.objectId;
    }
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'code',
          header: 'table.column.aanwijzingNumber',
          plugins: {
            name: 'hyperlink',
            className: 'white-space-normal',
            onClick: this.onClick.bind(this)
          }
        },
        {
          field: 'subject',
          header: 'table.column.subject'
        },
        {
          field: 'transactionType.name',
          header: 'table.column.type'
        } /** translate */,
        {
          field: 'isVendorMandatory',
          header: 'table.column.mandatory',
          dataMap:
            '{"true":"' +
            this.global.translateService.instant(
              'aanwijzing.table.isVendorMandatory.yes'
            ) +
            '","false":"' +
            this.global.translateService.instant(
              'aanwijzing.table.isVendorMandatory.no'
            ) +
            '"}'
        },
        {
          field: 'periodStartDate',
          header: 'table.column.startDate',
          plugins: {
            name: 'date',
            format: 'short-date-and-time'
          }
        },
        {
          field: 'periodEndDate',
          header: 'table.column.endDate',
          plugins: {
            name: 'date',
            format: 'short-date-and-time'
          }
        },
        {
          field: 'aanwijzingStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              DRAFT: 'SECONDARY',
              REVISION: 'FEEDBACK',
              WAITING_APPROVAL: 'WARNING',
              ON_PROGRESS: 'PROGRESS',
              NOT_STARTED: 'SECONDARY',
              CLOSED: 'DANGER',
              CANCELED: 'DANGER',
              DONE: 'SUCCESS',
              EXTENDED: 'INFO'
            },
            colorField: 'aanwijzingStatus.code'
          }
        }
      ],
      { checkBoxFn: this.isShowCheckBox.bind(this) }
    );
  }

  public isShowCheckBox(record: Aanwijzing): boolean {
    let isStatusDraft = false;

    if (this.isRoleBuyer()) {
      if (
        record.aanwijzingStatus?.code !==
        this.global.appConstant.pm.AANWIJZING_STATUS_DRAFT
      ) {
        isStatusDraft = false;
      } else {
        isStatusDraft = true;
      }
    }

    return isStatusDraft;
  }

  public buildFormGroup(): void {}

  public setFormGroup(): void {
    this.httpClientService
      .post<AanwijzingEditResponse>(
        '/aanwijzing/edit',
        new RouteRequestModel(this.todo, this.procurementId)
      )
      .subscribe((response: AanwijzingEditResponse) => {
        this.response = response;
        this.procurement = response.procurement;
        this.tableResponse.setRecordList(response.aanwijzingList || []);
        this.tableResponse.reload();

        /** sudah submitted? */
        if (this.procurement.workflowModelPrcs.code.includes('AANWIJZING')) {
          if (
            ![
              this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_NEW,
              this.global.appConstant.pm
                .PROCUREMENT_WORKLIST_STATUS_ON_PROGRESS,
              this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION
            ].includes(this.procurement.procurementWorklistStatus.code)
          ) {
            this.isSubmitted = true;
          } else {
            this.isSubmitted = false;
          }
        } else {
          this.isSubmitted = true;
        }

        if (this.response.aanwijzingParticipant) {
          if (
            this.response.aanwijzingParticipant.participantRole.code ===
              this.global.appConstant.pm.PARTICIPANT_ROLE_INTERNAL &&
            this.global.userSession.activeUserRole.role.code !==
              this.global.appConstant.ROLE_CODE_BUYER
          ) {
            this.isInternal = true;
          } else if (
            this.response.aanwijzingParticipant.participantRole.code ===
            this.global.appConstant.pm.PARTICIPANT_ROLE_VENDOR
          ) {
            this.isVendor = true;
          }
        }

        this.setStateReady();
        if (
          !this.canEditStatusList.includes(
            this.procurement.procurementWorklistStatus.code
          )
        ) {
          this.setViewOnly();
        }
      });
  }

  public onClick(aanwijzing: Aanwijzing): void {
    this.global.routerParams.set('aanwijzingId', aanwijzing.id);
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('procurementId', this.procurementId);
    localStorage.setItem('isShowBadgeStatus', false.toString());

    if (
      !this.isRoleBuyer() &&
      aanwijzing.transactionType.code ===
        this.global.appConstant.core.TRANSACTION_TYPE_ONLINE &&
      [
        this.global.appConstant.pm.AANWIJZING_STATUS_NOT_STARTED,
        this.global.appConstant.pm.AANWIJZING_STATUS_ON_PROGRESS
      ].includes(aanwijzing.aanwijzingStatus.code)
    ) {
      const myAanwijzingParticipant =
        this.response.myAanwijzingParticipantList.find(
          aparticipant => aparticipant.aanwijzing.id === aanwijzing.id
        );

      if (!myAanwijzingParticipant.isJoin) {
        this.appPopupSignatureService
          .open('aanwijzing', 'aanwijzing.popup.attendanceSignature.title')
          .subscribe(signatureImgFile => {
            this.log.debug(signatureImgFile);
            myAanwijzingParticipant.isJoin = true;
            myAanwijzingParticipant.isOnline = true;
            const req = {
              aanwijzingParticipant: myAanwijzingParticipant,
              fileObject: signatureImgFile[0]
            };
            this.log.debug(req);

            this.loadingBlockService.showInfo('app.msg.processing');
            this.httpClientService
              .post<any>('/aanwijzing/insert-update-participant', req)
              .subscribe(response => {
                this.log.debug(response);
                this.router.navigate(['/pages/aanwijzing/session-room']);
              });
          });
      } else {
        this.router.navigate(['/pages/aanwijzing/session-room']);
      }
    } else {
      this.router.navigate(['/pages/aanwijzing/session-room']);
    }
  }

  public getTranslateKey(data): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public isRoleBuyer(): boolean {
    return (
      this.global.userSession.activeUserRole.role.code ===
      this.global.appConstant.ROLE_CODE_BUYER
    );
  }

  public doCreateSession(): void {
    const transactionTypeList = this.response.transactionTypeList;
    const procurement = this.response.procurement;
    const procurementSchedule = this.response.procurementSchedule;

    const previousAanwijzing = this.tableResponse
      .getRecordList()
      .find(
        a =>
          a.aanwijzingStatus.code ===
            this.global.appConstant.pm.AANWIJZING_STATUS_DONE ||
          a.aanwijzingStatus.code ===
            this.global.appConstant.pm.AANWIJZING_STATUS_EXTENDED
      );

    this.appPopupService.open(
      AanwijzingPopupCreateSessionComponent,
      {
        transactionTypeList,
        procurement,
        procurementSchedule,
        previousAanwijzing
      },
      { size: 'md' }
    );
  }

  public doDeleteSession(event: SelectedTableRecordModel): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const checkedIdList: number[] = (
            event.selectedRecordList as Array<Aanwijzing>
          )
            .filter(
              anwz =>
                anwz.aanwijzingStatus.code ===
                this.global.appConstant.pm.AANWIJZING_STATUS_DRAFT
            )
            .map(record => record.id);

          // event.selectedRecordList.forEach(aanwijzing => {
          //     aanwijzing.crudOperation = this.global.appConstant.core.CRUD_OPERATION_DELETE;
          // });

          // this.aanwijzingRequest.aanwijzingList.push(...event.selectedRecordList);
          const draftRecordList: Aanwijzing[] = event.selectedRecordList.filter(
            anwz =>
              anwz.aanwijzingStatus.code ===
              this.global.appConstant.pm.AANWIJZING_STATUS_DRAFT
          );

          this.httpClientService
            .post<Response<Aanwijzing[]>>('/aanwijzing/delete', draftRecordList)
            .subscribe((response: Response<Aanwijzing[]>) => {
              if (response.status === ResponseStatusModel.OK) {
                this.tableResponse.setRecordList(
                  this.tableResponse
                    .getRecordList()
                    .filter(a => !checkedIdList.includes(a.id))
                );
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doSubmit(): void {
    const cannotSubmitStatusList = [
      this.global.appConstant.pm.AANWIJZING_STATUS_DRAFT,
      this.global.appConstant.pm.AANWIJZING_STATUS_NOT_STARTED,
      this.global.appConstant.pm.AANWIJZING_STATUS_ON_PROGRESS,
      this.global.appConstant.pm.AANWIJZING_STATUS_CLOSED
    ];

    this.canSubmit = !this.tableResponse
      .getRecordList()
      .find(anwz =>
        cannotSubmitStatusList.includes(anwz.aanwijzingStatus.code)
      );

    if (this.canSubmit) {
      if (this.response.isPassedMinVendor) {
        this.global.modalService
          .submitConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.doProcessSubmit(false);
            }
          });
      } else {
        this.global.modalService
          .submitProcurementFailed(this.response.minimumParticipant)
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.doProcessSubmit(true);
            }
          });
      }
    } else {
      this.toastService.showError('aanwijzing.alert.msg.cannotSubmit');
    }
  }

  public doProcessSubmit(isPotentialFailed: boolean): void {
    this.setStateProcessing();

    this.aanwijzingRequest.isPotentialFailed = isPotentialFailed;
    this.aanwijzingRequest.aanwijzingList.push(
      ...this.tableResponse.getRecordList()
    );

    this.httpClientService
      .post<Response<any>>('/aanwijzing/update', this.aanwijzingRequest)
      .subscribe((response: Response<Procurement>) => {
        if (response.status === ResponseStatusModel.OK) {
          this.response.procurement = response.body;
          this.global.modalService.submitSuccess().subscribe(result => {
            this.setStateReady();
            this.isSubmitted = true;
            if (result) {
              this.router.navigate(['/pages/worklist-pm']);
            } else {
            }
          });
        } else {
          this.setStateReady();
          this.global.alertService.showError(response.statusText);
        }
      });
  }
}
