import { EventEmitter } from '@angular/core';
import { ApprovalPathEvent } from '../events/approval-path-event';
import { ApprovalPathEventType } from '../events/approval-path-event-type';
import { ApprovalPathMapper } from '../mapper/approval-path-mapper';
import { ApprovalPathType } from '../type/approval-path-type';
import { ApprovalPathItem } from './approval-path-item';
import { ApprovalPathModeType } from './approval-path-mode';
export class ApprovalPathModel<T> {
  type: ApprovalPathType;
  approverList: Array<ApprovalPathItem>;
  mapper: ApprovalPathMapper<T>;
  origin: Array<T>;
  approvalEventChanges: EventEmitter<ApprovalPathEvent<T>>;
  max: number;
  constructor(
    public moduleCode: string,
    public mode: ApprovalPathModeType,
    public isShowIconProgress: boolean = true
  ) {
    this.approverList = new Array();
    this.origin = new Array();
    this.approvalEventChanges = new EventEmitter();
  }

  setApproverList(approverList: Array<T>): this {
    this.origin = approverList;
    this.approverList = this.createApproverMap(approverList);
    this.sendEventChange('ON-SET');
    return this;
  }

  addApproverToApproverList(approver: T): void {
    this.origin.push(approver);
    const approverMap = this.createApproverMap([approver])[0];
    this.approverList.push(approverMap);
    this.sendEventChange('ON-ADDED', approver);
  }

  changeApproverInApproverList(approver: T, index: number): void {
    this.origin[index] = approver;
    const approverMap = this.createApproverMap([approver])[0];
    this.approverList[index] = approverMap;
    this.sendEventChange('ON-EDIT', approver);
  }

  setStateModeView(): this {
    this.mode = 'READ';
    return this;
  }

  setMax(max: number): void {
    this.max = max;
  }

  removeAt(index: number): void {
    const approver = this.value[index];
    this.approverList.splice(index, 1);
    this.origin.splice(index, 1);
    this.sendEventChange('ON-DELETED', approver);
  }

  setType(type: number): this {
    this.type = type === 1 ? 'SERIAL' : 'PARALEL';
    this.sendEventChange('ON-TYPECHANGED');
    return this;
  }

  reset(): void {
    this.approverList.splice(0);
    this.origin.splice(0);
    this.sendEventChange('ON-RESET');
  }

  get value(): Array<T> {
    return this.origin;
  }

  public sendEventChange(type: ApprovalPathEventType, approver?: T): void {
    this.approvalEventChanges.emit({ type, value: this.value, approver });
  }

  public createApproverMap(approver: Array<T>): Array<ApprovalPathItem> {
    const approvalPathMapper: ApprovalPathMapper<T> = new ApprovalPathMapper();
    return this.mapper
      ? this.mapper.toModel(approver)
      : approvalPathMapper.toModel(approver);
  }
}
