import { RfqVendor } from '../../core/bean/rfq-vendor';
import { RfqVendorItem } from '../../core/bean/rfq-vendor-item';
import { FileObject } from '../../core/components/upload';

export class RfqResponseRequest {
  isSubmit: boolean;
  rfqVendor: RfqVendor = new RfqVendor();
  fileObjectList: FileObject[] = [];
  rfqVendorItemList: RfqVendorItem[] = [];
}
