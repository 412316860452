import { Component, EventEmitter, Input, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { ContractDoc } from '../../bean/contract-doc';
import { Response } from '../../model/response-model';
import { ResponseStatusModel } from '../../model/response-status-model';
import { Validators } from '../../validators';
import { FileUploader } from '../upload';
import { AppContractDocumentUpdateRequest } from './app-contract-document-update-request';

@Component({
  templateUrl: './app-popup-add-contract-document.component.html'
})
export class AppPopupAddContractDocumentComponent extends BaseComponentComponent {
  @Input() contractDoc: ContractDoc;
  @Output() onChange: EventEmitter<ContractDoc> = new EventEmitter();

  public fileUploader: FileUploader = new FileUploader(
    '/app-contract-document/',
    'app-contract-document.form.doc',
    this.global.appConstant.fileType.DOC_CONTRACT,
    false,
    1
  );

  constructor() {
    super('app-contract-document');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      file: [null, Validators.required()],
      description: [null]
    });
  }

  public setFormGroup(): void {
    if (this.contractDoc) {
      if (this.contractDoc.file) {
        this.fileUploader.setFile(this.contractDoc.file);
      }
      this.formGroup.patchValue({
        description: this.contractDoc.description,
        file: this.fileUploader.fileObjectList
      });
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const request = new AppContractDocumentUpdateRequest();
            this.contractDoc.processedByUser = this.global.userSession.user;
            this.contractDoc.description = this.formGroup.value.description;
            request.contractDoc = this.contractDoc;
            request.fileObjectList = this.formGroup.value.file;
            this.setStateProcessing();
            this.httpClientService
              .post<Response<ContractDoc>>(
                '/app-contract-document/update',
                request
              )
              .subscribe(response => {
                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  this.onChange.emit(response.body);
                }
              });
          }
        });
    }
  }
}
