import { NgModule } from '@angular/core';
import { AppFieldsetModule } from '../../components/app-fieldset/app-fieldset.module';
import { AppRatingModule } from '../../components/app-rating/app-rating.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetTopVendorPerformanceComponent } from './app-widget-top-vendor-performance.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule, AppRatingModule, AppFieldsetModule],
  declarations: [AppWidgetTopVendorPerformanceComponent],
  entryComponents: [AppWidgetTopVendorPerformanceComponent],
  exports: [AppWidgetTopVendorPerformanceComponent]
})
export class AppWidgetTopVendorPerformance {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-top-vendor-performance', AppWidgetTopVendorPerformanceComponent);
  }
}
