<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="rfi.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [tableResponse]="tableResponse"
        [isServerSide]="true"
        stringUrl="/request-for-information/index"
      >
        <ng-template #headerButtons>
          <app-button (onClick)="doAdd()"
            ><em class="fas fa-plus"></em>
            {{ 'app.button.add' | translate }}</app-button
          >
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <!-- <app-button 
          *ngIf="rowData.rfiStatus.code === 'REVISION_RFI'" 
          color="PRIMARY" size="TN" 
          (onClick)="doEdit(rowData)" 
          title="{{ 'app.tooltip.edit' | translate }}">
            <span class="fas fa-pencil-alt"></span>
          </app-button>
          <app-button mode="BYPASS" 
          *ngIf="rowData.rfiStatus.code !== 'REVISION_RFI'" 
          color="PRIMARY" size="TN" 
          (onClick)="doDetail(rowData)" 
          title="{{ 'app.tooltip.view' | translate }}">
            <span class="fas fa-search"></span>
          </app-button> -->

          <app-button
            *ngIf="rowData.rfiStatus.code !== 'CLOSE'"
            color="PRIMARY"
            size="TN"
            (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}"
          >
            <span class="fas fa-pencil-alt"></span>
          </app-button>
          <app-button
            mode="BYPASS"
            *ngIf="rowData.rfiStatus.code === 'CLOSE'"
            color="PRIMARY"
            size="TN"
            (onClick)="doDetail(rowData)"
            title="{{ 'app.tooltip.view' | translate }}"
          >
            <span class="fas fa-search"></span>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
