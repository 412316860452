<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="news.title" detail="app.action.{{ todo }}"
      [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'news.form.title' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field autofocus="true" formControlName="title"
              maxLength="255"></app-text-field>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{ 'news.form.department' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <label class="control-label">{{ organizationName }}</label>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'news.form.publishDate' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-date-picker formControlName="publishDate"
              [minDate]="currentDate"
              tooltip="{{ 'news.tooltip.publishDate' | translate }}">
            </app-date-picker>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'news.form.headerImage' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-upload formControlName="headerImage"
              [fileUploader]="fileUploader"> </app-upload>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{ 'news.form.isActive' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-toggle-switch formControlName="isActive"></app-toggle-switch>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'news.form.body' | translate
            }}</label>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-2 control-label"></label>
          <div class="col-sm-7 text-left" *ngIf="!formLoading">
            <app-wysiwyg formControlName="body" size="XL"></app-wysiwyg>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button color="SECONDARY" (onClick)="doCancel()"
            [disabled]="formSaving">
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>