<app-layout [isLoading]="formLoading">
  <ng-template #additionalTitle>
    <app-badge
      class="cml-2"
      *ngIf="
        !formLoading &&
        rfqVendor?.responseRfqStatus?.code ===
          global.appConstant.pm.RFQ_STATUS_WAITING_RESPONSE
      "
      color="GOOD"
    >
      {{ 'dynamic-master-attribute.rfqStatus.waitingResponse' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        !formLoading &&
        rfqVendor?.responseRfqStatus?.code ===
          global.appConstant.pm.RFQ_STATUS_SUBMITTED
      "
      color="SUCCESS"
    >
      {{ 'dynamic-master-attribute.rfqStatus.submitted' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        !formLoading &&
        rfqVendor?.responseRfqStatus?.code ===
          global.appConstant.pm.RFQ_STATUS_DONE
      "
      color="SUCCESS"
    >
      {{ 'dynamic-master-attribute.rfqStatus.done' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        !formLoading &&
        rfqVendor?.responseRfqStatus?.code ===
          global.appConstant.pm.RFQ_STATUS_DRAFT
      "
      color="SECONDARY"
    >
      {{ 'dynamic-master-attribute.rfqStatus.draft' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        !formLoading &&
        rfqVendor?.responseRfqStatus?.code ===
          global.appConstant.pm.RFQ_STATUS_CLOSED_RESPONSE
      "
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.rfqStatus.closedResponse' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        !formLoading &&
        rfqVendor?.responseRfqStatus?.code ===
          global.appConstant.pm.RFQ_STATUS_CANCELED
      "
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.rfqStatus.canceled' | translate }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-alert-x
          *ngIf="
            rfqVendor?.responseRfqStatus?.code ===
            global.appConstant.pm.RFQ_STATUS_SUBMITTED
          "
          color="INFO"
          [isShowCloseButton]="true"
          >Your response is being evaluated, you have registered for this
          procurement on
          {{
            rfqVendor.submittedDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}</app-alert-x
        >
        <app-alert-x
          *ngIf="
            rfqVendor?.responseRfqStatus?.code ===
              global.appConstant.pm.RFQ_STATUS_DONE && !rfqVendor?.isWinner
          "
          color="DANGER"
          [isShowCloseButton]="true"
          >Thank you for your participation, we decided to not continue with
          your company for this procurement</app-alert-x
        >
        <app-alert-icon
          *ngIf="
            rfqVendor?.responseRfqStatus?.code ===
              global.appConstant.pm.RFQ_STATUS_DONE && rfqVendor?.isWinner
          "
          color="INFO"
          icon="CONFETTI"
          class="alert-icon-center alert-icon"
          [isShowCloseButton]="true"
        >
          <strong style="display: grid">
            <span class="pb-3"
              >Congratulation you are the winner for this procurement.
            </span>
            <span>Please wait for further information.</span>
          </strong>
        </app-alert-icon>
        <app-alert-x
          *ngIf="
            rfqVendor?.responseRfqStatus?.code ===
            global.appConstant.pm.RFQ_STATUS_CANCELED
          "
          color="DANGER"
          [isShowCloseButton]="true"
          >This process canceled by
          {{ rfqResponse.rfqCancellation?.cancelByUser?.name || '-' }}
          at
          {{
            rfqResponse.rfqCancellation?.cancelDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}
          the cancellation reason is
          {{ rfqResponse.rfqCancellation?.note }}</app-alert-x
        >
        <app-card
          header="rfq-response.detail.general.title"
          tips="rfq-response.detail.general.tips"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfq.form.rfqNumber' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="code"> </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{ 'rfq.form.title' | translate }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field formControlName="title"> </app-text-field>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfq.form.responsePeriod' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-date-picker-x formControlName="date" [range]="true">
              </app-date-picker-x>
            </div>
          </div>
        </app-card>

        <app-card>
          <app-table-xx
            tips="rfq-response.detail.itemRequest.tips"
            header="rfq-response.detail.itemRequest.title"
            [model]="tableResponseItem"
            (onClick)="doViewItem($event)"
            *ngIf="!formLoading"
          >
            <ng-template #headerFilter>
              <div class="app-table-filter-item row">
                <div class="col text-left" [ngStyle]="{ 'min-width': '10rem' }">
                  <app-dropdown-select
                    size="XL"
                    formControlName="itemType"
                    type="COMBOBOX"
                    [optionList]="itemTypeOptionList"
                    (onChange)="onChangeItem()"
                    container="body"
                    placeholder="{{
                      'rfq-response.placeholder.type' | translate
                    }}"
                  ></app-dropdown-select>
                </div>
              </div>
            </ng-template>
            <ng-container *ngIf="tableResponseItem.getRecordList().length > 0">
              <ng-template #row>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'rfq.form.goodsPrice' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{
                        formGroup.value.goodsAmount || 0
                          | resolveNumberToCurrency
                      }}
                      {{ rfqResponse.companyCurrency.code }}
                    </strong>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'rfq.form.servicePrice' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{
                        formGroup.value.serviceAmount || 0
                          | resolveNumberToCurrency
                      }}
                      {{ rfqResponse.companyCurrency.code }}
                    </strong>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'rfq.form.grandTotal' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{
                        formGroup.value.amount || 0 | resolveNumberToCurrency
                      }}
                      {{ rfqResponse.companyCurrency.code }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    <i>
                      ({{
                        formGroup.value.amount || 0
                          | resolveNumberToWords: global.currentLang
                      }}
                      {{ rfqResponse.companyCurrency.name }})
                    </i>
                  </td>
                </tr>
              </ng-template>
            </ng-container>
          </app-table-xx>
        </app-card>

        <app-card
          header="rfq-response.detail.responseDocument.title"
          tips="rfq-response.detail.responseDocument.tips"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfq-response.form.template' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              <app-upload-x
                formControlName="file"
                [fileUploader]="fileUploader"
              >
              </app-upload-x>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfq-response.form.note' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              <app-text-area size="XL" formControlName="note" maxLength="255">
              </app-text-area>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'rfq-response.form.uploadResponse' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              <app-upload-x
                formControlName="responseFile"
                [fileUploader]="fileUploaderResponse"
                (onChange)="doSetMarkAsDirty()"
              >
              </app-upload-x>
            </div>
          </div>
        </app-card>

        <div class="floating-button mt-5" *ngIf="todo !== 'view'">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div
                class="button-group button-group-center"
                *ngIf="todo !== 'view'"
              >
                <app-button
                  color="LIGHT"
                  [outline]="true"
                  (onClick)="doSave()"
                  [disabled]="formSaving"
                  >{{ 'app.button.save' | translate }}
                </app-button>
                <app-button (onClick)="doSubmit()" [disabled]="formSaving">{{
                  'app.button.submit' | translate
                }}</app-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
