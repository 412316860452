import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { WorklistInfoResponse } from './worklist-info.response';
@Component({
  selector: 'app-worklist-info',
  templateUrl: './app-worklist-info.component.html',
  styleUrls: ['./app-worklist-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWorklistInfoComponent extends BaseComponentComponent {
  @Input() public stringUrl: string;
  @Input() public moduleGroupCode: string;
  @Input() public position: string;
  @Output() public onChange: EventEmitter<string> = new EventEmitter();
  public dataList = [];
  public totalRecords = 0;
  constructor() {
    super('app-worklist-info');
  }

  public onInit(): void {
    const url = this.moduleGroupCode
      ? this.stringUrl + '/' + this.moduleGroupCode
      : this.stringUrl;
    this.httpClientService.get<WorklistInfoResponse>(url).subscribe(data => {
      const dataModuleList = data[Object.keys(data)[1]] as Array<any>;
      dataModuleList.forEach((dataModule: any) => {
        this.dataList.push({
          name: dataModule[Object.keys(dataModule)[0]].name,
          code: dataModule[Object.keys(dataModule)[0]].code,
          totalRecords: dataModule.recordCount
        });
        this.totalRecords = this.totalRecords + dataModule.recordCount;
      });
      this.setStateReady();
    });
  }

  public onKeyUp(event: KeyboardEvent): void {
    this.log.debug(event);
  }

  public onKeyDown(event: KeyboardEvent): void {
    this.log.debug(event);
  }
}
