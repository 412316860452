import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Catalog } from 'src/app/core/bean/catalog';
import { Currency } from 'src/app/core/bean/currency';
import { File } from 'src/app/core/bean/file';
import { ItemCategory } from 'src/app/core/bean/item-category';
import { PrItem } from 'src/app/core/bean/pr-item';
import { TableCatalogResponseModel } from 'src/app/core/components/app-catalog-item-x/model/table-catalog-response-model';
import { AppItemRequestInitiationCatalogModel } from 'src/app/core/components/app-item-request/app-item-request-initiation-catalog-model';
import { AppPopupChooseBudgetXService } from 'src/app/core/components/app-popup/app-popup-choose-budget-x/app-popup-choose-budget-x.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';
import { CatalogView } from 'src/app/core/view/entity/bean/catalog-view';

@Component({
  templateUrl: './initiation-add-item-detail.component.html',
  styleUrls: ['./initiation-catalog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InitiationAddItemDetailComponent
  extends BaseModuleComponent
  implements OnInit
{
  public catalogId: number;
  public prItemId: number;
  public vendorId: number;
  public prResponse: AppItemRequestInitiationCatalogModel = new AppItemRequestInitiationCatalogModel();
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );
  public prItem: PrItem = new PrItem();
  public fileUploader: FileUploader = new FileUploader(
    '/manage-catalog/',
    'manage-catalog.form.header',
    this.global.appConstant.fileType.IMG_ITEM_CATALOG,
    false,
    5
  );
  public itemCategoryList: ItemCategory[] = [];
  public catalog: Catalog = new Catalog();
  public model: TableCatalogResponseModel<CatalogView> = new TableCatalogResponseModel('/initiation/last-purchase');
  public backToUrl: string;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupChooseBudgetService: AppPopupChooseBudgetXService
  ) {
    super('initiation', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.handleReloadChanges();
    this.getAndSetCatalogs();
    this.setPagination();
    this.setOptionModel();
    this.buildFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.prItemId = this.global.routerParams.get('prItemId');
    this.prResponse = this.global.routerParams.get('prResponse');
    this.catalogId = this.global.routerParams.get('catalogId');
    this.backToUrl = this.global.routerParams.get('backToUrl');
  }

  public setOptionModel(): void {
    this.currencyOptionList.setRequestValues(this.prResponse.currencyList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      pr: [null],
      item: [null],
      price: [null],
      totalPrice: [null],
      quantity: [
        null,
        Validators.compose([Validators.required(), Validators.min(1)])
      ],
      specification: [null],
      itemImages: [null],
      totalBudget: [null],
      catalog: [null],
      crudOperation: [this.global.appConstant.core.CRUD_OPERATION_INSERT],
      isMaxPage: [false],
      prItemBudgetList: this.formBuilder.array([]),
      budgetAllocationList: this.formBuilder.array([]),
      prItemImageList: this.formBuilder.array([])
    });
  }

  public get budgetAllocationList(): FormArray {
    return this.formGroup.get('budgetAllocationList') as FormArray;
  }

  public doChangeCatalog(event): void {
    this.itemCategoryList = event.itemCategoryList;
    this.catalog = event.catalog;
    this.vendorId = event.catalogView.vendorId;
    if (this.catalog) {
      this.formGroup.patchValue({
        id: this.prItem.id,
        pr: this.prItem.pr,
        item: this.catalog.item,
        price: this.catalog.price,
        catalog: this.catalog
      });
      const prItemImageList = this.formGroup.get(
        'prItemImageList'
      ) as FormArray;
      event.imageList.forEach((file: File) => {
        this.fileUploader.setFile(file);
        prItemImageList.push(
          this.formBuilder.group({
            fileObjectList: [this.fileUploader.fileObjectList],
            file
          })
        );
      });
      this.setStateReady();
    }
  }

  public onChangeQuantity(): void {
    const prices = this.catalog.price;
    const quantity = this.formGroup.value.quantity;
    let totalPrice = null;
    if (prices && +quantity) {
      totalPrice = +(+prices * +quantity);
      this.formGroup.patchValue({
        price: prices
      });
    }
    this.formGroup.patchValue({
      totalPrice
    });
  }

  public addToItemRequest(): void {
    this.formGroup.markAllAsTouched();
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .initiationAddToItemRequestModal()
        .subscribe(result => {        
          let isNotAddQuantityAndTotalPrice: boolean = true;
          this.prResponse.prItemList.forEach((prItem) => {
            if(prItem.catalog && prItem.catalog.id === this.formGroup.value.catalog.id){
              prItem.quantity = prItem.quantity + this.formGroup.value.quantity;
              prItem.totalPrice = prItem.totalPrice + this.formGroup.value.totalPrice;
              isNotAddQuantityAndTotalPrice = false;
            }
          });

          if(isNotAddQuantityAndTotalPrice){
            this.prResponse.prItemList.push(this.formGroup.value);
            this.prResponse.prItemList.forEach((prItem, index) => {
              prItem.code = '00' + (+index + +1);
            });
          }

          this.global.routerParams.set('prResponse', this.prResponse);

          if (result) {  
            this.router.navigate(['/pages/initiation/add']);
          } else {
            this.router.navigate(['/pages/initiation/add-item']);
          }     
      });
    }
  }

  public getAndSetCatalogs(): void {
    this.model.setStateLoading();
    this.model.tableRequest.cols = [
      { field: 'name', header: '' },
      { field: 'vendorName', header: '' },
      { field: 'rating', header: '' },
      { field: 'currencyCode', header: '' },
      { field: 'price', header: '' }
    ];

    this.httpClientService
      .post<any>(this.model.stringUrl, this.model.tableRequest)
      .subscribe(page => {
        this.model.setPageRecords(page.records);
        this.model.setTotalRecords(page.totalRecords);
        this.model.setStateReady();
        this.setStateReady();
      });
  }

  public handleReloadChanges(): void {
    this.model.reloadChanges.subscribe(() => {
      this.getAndSetCatalogs();
    });
  }

  public setPagination(): void {
    this.model.setCurrentPage(1);
    this.model.setPerPage(5);
  }

  public onPageChangeRight(): void {
    const page = +this.model.pagination.page + 1;
    if (
      this.model.pagination.page !==
      Math.ceil(
        this.model.pagination.totalRecords / this.model.pagination.perPage
      )
    ) {
      this.model.setCurrentPage(+this.model.pagination.page + 1);
      this.model.pagination.page = page;
      this.model.reload();
    }
    this.formGroup
      .get('isMaxPage')
      .patchValue(
        page ===
          Math.ceil(
            this.model.pagination.totalRecords / this.model.pagination.perPage
          )
          ? true
          : false
      );
  }

  public onPageChangeLeft(): void {
    const page = +this.model.pagination.page - 1;
    this.model.setCurrentPage(+this.model.pagination.page - 1);
    this.model.pagination.page = page;
    this.model.reload();
    this.formGroup
      .get('isMaxPage')
      .patchValue(
        page ===
          Math.ceil(
            this.model.pagination.totalRecords / this.model.pagination.perPage
          )
          ? true
          : false
      );
  }
}
