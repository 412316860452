<app-popup header="tab-expert-and-equipment.popup.header.expert.title" [isLoading]="formLoading">
  <form [formGroup]="formGroup" class="app-popup-expert">
    <h5 class="separator">{{
      'company-profile.form-group.popup.expert' | translate
      }}</h5>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.name' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorExpertiseHistory.name || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.DOB' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{
        (vendorExpertiseHistory.birthDate
        | date: global.config.parameterModel.dateFormatShort) || '-'
        }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.experience' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorExpertiseHistory.experience || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.profession' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorExpertiseHistory.profession?.name || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.employmentPeriod' | translate
        }}</label>
      <div class="col-sm-9 text-left d-flex">
        <label class="mr-3 control-label">{{
          'company-profile.form.popup.from' | translate
          }}</label>
        {{
        (vendorExpertiseHistory.startDate
        | date: global.config.parameterModel.dateFormatShort) || '-'
        }}
        <label class="ml-3 mr-3 control-label">{{
          'company-profile.form.popup.until' | translate
          }}</label>
        {{
        (vendorExpertiseHistory.endDate
        | date: global.config.parameterModel.dateFormatShort) || '-'
        }}
      </div>
    </div>

    <h5 class="separator">{{
      'company-profile.form-group.popup.education' | translate
      }}</h5>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.education' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorExpertiseHistory.education?.name || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.institution' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorExpertiseHistory.institution || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.CV' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        {{ vendorExpertiseHistory.file?.fileName || '-' }}
      </div>
    </div>

    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'company-profile.form.popup.educationYear' | translate
        }}</label>
      <div class="col-sm-9 text-left d-flex">
        <label class="mr-3 control-label">{{
          'company-profile.form.popup.from' | translate
          }}</label>
        {{ vendorExpertiseHistory.startYear || '-' }}
        <label class="ml-3 mr-3 control-label">{{
          'company-profile.form.popup.until' | translate
          }}</label>
        {{ vendorExpertiseHistory.endYear || '-' }}
      </div>
    </div>

    <h5 class="separator">{{
      'company-profile.form-group.popup.certification' | translate
      }}</h5>
    <ng-container *ngFor="
        let certification of vendorExpertiseHistory.vendorExpertiseCertificateHistoryList;
        let i = index
      ">
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'company-profile.form.popup.certificationNumber' | translate
          }}</label>
        <div class="col-sm-9 text-left">
          {{ certification.certificateNumber || '-' }}
        </div>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'company-profile.form.popup.validationDate' | translate
          }}</label>
        <div class="col-sm-9 text-left d-flex">
          <label class="mr-3 control-label">{{
            'company-profile.form.popup.from' | translate
            }}</label>
          {{
          (certification.createdDate
          | date: global.config.parameterModel.dateFormatShort) || '-'
          }}
          <label class="ml-3 mr-3 control-label">{{
            'company-profile.form.popup.until' | translate
            }}</label>
          {{
          (certification.endDate
          | date: global.config.parameterModel.dateFormatShort) || '-'
          }}
        </div>
      </div>
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'company-profile.form.popup.certificate' | translate
          }}</label>
        <div class="col-sm-9 text-left">
          {{ certification.file?.fileName || '-' }}
        </div>
      </div>
    </ng-container>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
          }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>