<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="payment-monitoring.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
      *ngIf="!formLoading"
    >
      <app-fieldset legend="Filter">
        <form
          class="form-horizontal"
          [formGroup]="formGroup"
          novalidate
          *ngIf="!formLoading"
        >
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'payment-monitoring.form.invoiceDate' | translate
            }}</label>
            <div class="col-sm-9 text-left" style="display: flex">
              <app-date-picker-x size="XL" formControlName="startDate">
              </app-date-picker-x>
              <p class="ml-3 mr-3 control-label">{{
                'payment-monitoring.form.until' | translate
              }}</p>
              <app-date-picker-x
                size="XL"
                formControlName="endDate"
                minDate="startDate"
              ></app-date-picker-x>
            </div>
          </div>
          <div class="button-group button-group-center mt-5">
            <app-button mode="BYPASS" color="SECONDARY" (onClick)="doReset()">
              <em class="fas fa-sync-alt"></em>
              {{ 'app.button.reset' | translate }}
            </app-button>
            <app-button
              mode="BYPASS"
              color="PRIMARY"
              (onClick)="doApply()"
              [disabled]="formSaving"
              ><em class="fas fa-check"></em>
              {{ 'app.button.apply' | translate }}
            </app-button>
          </div>
        </form>
      </app-fieldset>
      <app-table
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/payment-monitoring/index"
      >
        <ng-template #headerButtons>
          <app-button (onClick)="doSynchronize()" *ngIf="isAllowAccess">
            <em class="fas fa-sync-alt"></em
            >{{ 'payment-monitoring.button.synchronize' | translate }}
          </app-button>
          <app-button
            mode="BYPASS"
            (onClick)="doExport()"
            [disabled]="isLoading"
            ><em
              class="fas"
              [ngClass]="
                !isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'
              "
            ></em
            >{{ 'app.button.export' | translate }}</app-button
          >
        </ng-template>

        <ng-template #actionButtons let-rowData>
          <app-button
            size="TN"
            color="PRIMARY"
            *ngIf="
              rowData.paymentStatus.code ===
              global.appConstant.cm.PAYMENT_STATUS_PAYMENT_PROCESS
            "
            (onClick)="doEdit(rowData)"
            mode="BYPASS"
            title="{{ 'app.tooltip.edit' | translate }}"
          >
            <em class="fa fa-pencil-alt"></em>
          </app-button>

          <app-button
            size="TN"
            color="PRIMARY"
            *ngIf="
              rowData.paymentStatus.code !==
              global.appConstant.cm.PAYMENT_STATUS_PAYMENT_PROCESS
            "
            (onClick)="doDetail(rowData)"
            mode="BYPASS"
            title="{{ 'app.tooltip.view' | translate }}"
          >
            <em
              [ngClass]="
                isShowButtonEdit(rowData)
                  ? 'fas fa-pencil-alt'
                  : 'fas fa-search'
              "
            ></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
