import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ModuleEditAddComponent } from './module-edit-add.component';
import { ModuleComponent } from './module.component';
const routes: Routes = [
  { path: '', component: ModuleComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: ModuleEditAddComponent,
    data: { breadcrumb: 'module.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: ModuleEditAddComponent,
    data: { breadcrumb: 'module.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ModuleComponent, ModuleEditAddComponent]
})
export class ModuleModule {}
