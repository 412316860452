import { Contract } from 'src/app/core/bean/contract';
import { ContractReviewer } from 'src/app/core/bean/contract-reviewer';
import { ContractDraft } from 'src/app/core/bean/contract-draft';
import { ContractDraftHistory } from 'src/app/core/bean/contract-draft-history';
import { ContractDraftParameterValue } from 'src/app/core/bean/contract-draft-parameter-value';
import { ContractTemplate } from 'src/app/core/bean/contract-template';
import { FileObject } from 'src/app/core/components/upload';

export class ContractDraftUpdateRequest {
  contractTemplateList: ContractTemplate[];
  contractCollabAccessList: ContractReviewer[];
  contractDraftHistory: ContractDraftHistory;
  contractDraft: ContractDraft;
  contract: Contract;
  contractDraftParameterValueList: ContractDraftParameterValue[];
  isSave: boolean;
  fileObjectList: FileObject[];
}
