import { BaseEntity } from '../base/base-entity';
import { Coa } from '../bean/coa';
import { CostCenter } from '../bean/cost-center';
import { PaymentVoucher } from '../bean/payment-voucher';
import { Tax } from '../bean/tax';
import { TaxMethod } from '../bean/tax-method';

export class PaymentVoucherTaxEntity extends BaseEntity {
  public coa: Coa;
  public costCenter: CostCenter;
  public paymentVoucher: PaymentVoucher;
  public tax: Tax;
  public taxMethod: TaxMethod;
  public taxBaseAmount: number;
  public incomeTaxAmount: number;
  public taxPercentageValue: number;
}
