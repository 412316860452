import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class AppTableCheckBoxService {
  public checkedItems: Array<any> = [];
  public onChange: EventEmitter<any> = new EventEmitter();
  public checkedItem(item: any): void {
    const indexOfItem = this.checkedItems.findIndex(checkedItem => JSON.stringify(checkedItem) === JSON.stringify(item));
    indexOfItem !== -1 ? this.checkedItems.splice(indexOfItem, 1) : this.checkedItems.push(item);
    this.onChange.emit(this.checkedItems);
  }

  public isChecked(item: any): boolean {
    return this.checkedItems.findIndex(checkedItem => JSON.stringify(checkedItem) === JSON.stringify(item)) !== -1;
  }
}
