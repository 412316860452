import { Contract } from 'src/app/core/bean/contract';
import { ContractClosed } from 'src/app/core/bean/contract-closed';
import { ContractFileHistory } from 'src/app/core/bean/contract-file-history';
import { FileObject } from 'src/app/core/components/upload';

export class ContractMonitoringRequest {
  emailList: string[];
  fileObjectList: FileObject[] = [];
  contract: Contract = new Contract();
  contractFileHistory: ContractFileHistory = new ContractFileHistory();
  contractClosed: ContractClosed = new ContractClosed();
}
