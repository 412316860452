import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password.component';
export const routes = [
  { path: '', component: ForgotPasswordComponent, pathMatch: 'full' }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ForgotPasswordComponent]
})
export class ForgotPasswordModule {}
