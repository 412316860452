<app-popup
  header="app-popup-choose-vendor.popup.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <div class="popup-choose-vendor">
    <app-table-xx
      [isServerSide]="false"
      [model]="tableResponse"
      [isShowCheckBox]="isShowCheckBox"
      (onChange)="doCheck()"
      *ngIf="!formLoading"
    >
      <ng-container *ngIf="chooseVendorCondition.isDisableFilter === true">
        <ng-template #alertWrapper>
          <app-alert-x [isBold]="false">
            {{ 'app-popup-choose-vendor.form.info' | translate }}
          </app-alert-x>
        </ng-template>
      </ng-container>

      <ng-template #headerFilterGroup>
        <form
          class="form-horizontal"
          [formGroup]="formGroup"
          novalidate
          *ngIf="!formLoading"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'app-popup-choose-vendor.form.vendorLocation' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                type="AUTOCOMPLETE"
                formControlName="regionList"
                [optionList]="regionOptionList"
                placeholder="app-popup-choose-vendor.placeholder.vendorLocation"
                [isMultiple]="true"
                container="body"
              >
              </app-dropdown-select>
            </div>
          </div>
          <div
            class="form-group row text-sm-left"
            *ngIf="optionResponse.isModuleGroupVmActive"
          >
            <label class="col-sm-3">{{
              'app-popup-choose-vendor.form.performance' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-rating formControlName="rating"></app-rating>
            </div>
          </div>

          <div
            class="form-group row text-sm-left"
            *ngIf="
              chooseVendorCondition.isMinimumContractValue &&
              optionResponse?.currencyList?.length > 0
            "
          >
            <label class="col-sm-3">{{
              'app-popup-choose-vendor.form.minimumContractValue' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-currency
                formControlName="contractValue"
                [optionList]="currencyOptionList"
              ></app-currency>
            </div>
          </div>
          <div
            class="form-group row text-sm-left"
            *ngIf="chooseVendorCondition.isTotalContract"
          >
            <label class="col-sm-3">{{
              'app-popup-choose-vendor.form.totalContract' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="totalContract"
                type="integer"
              ></app-text-field>
            </div>
          </div>
          <div
            class="form-group row text-sm-left"
            *ngIf="chooseVendorCondition.isVendorType"
          >
            <label class="col-sm-3">{{
              'app-popup-choose-vendor.form.vendorType' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                type="CHOSEN"
                formControlName="vendorTypeList"
                [optionList]="vendorTypeOptionList"
                [isMultiple]="true"
                container="body"
                placeholder="app-popup-choose-vendor.placeholder.vendorType"
              >
              </app-dropdown-select>
            </div>
          </div>
          <div
            class="form-group row text-sm-left"
            *ngIf="chooseVendorCondition.isCategory"
          >
            <label class="col-sm-3">{{
              'app-popup-choose-vendor.form.category' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                type="CHOSEN"
                formControlName="vendorCategoryList"
                [optionList]="vendorCategoryOptionList"
                [isMultiple]="true"
                container="body"
                placeholder="app-popup-choose-vendor.placeholder.vendorCategory"
              >
              </app-dropdown-select>
            </div>
          </div>
          <div
            class="form-group row text-sm-left"
            *ngIf="chooseVendorCondition.isSegmentation"
          >
            <label class="col-sm-3">{{
              'app-popup-choose-vendor.form.segmentation' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box-tree
                formControlName="segmentationList"
                stringUrl="/segmentation"
                header="{{
                  'app-popup-choose-vendor.header.segmentation' | translate
                }}"
                placeholder="{{
                  'app-popup-choose-vendor.placeholder.segmentation' | translate
                }}"
                [onlyLastChild]="true"
                [isMultiple]="true"
                isLazy="true"
              ></app-combo-box-tree>
            </div>
          </div>
          <div
            class="form-group row text-sm-left"
            *ngIf="chooseVendorCondition.isKeyword"
          >
            <label class="col-sm-3">{{
              'app-popup-choose-vendor.form.keyword' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-tagging
                formControlName="keyword"
                maxLength="255"
                placeholder="app-popup-choose-vendor.placeholder.keyword"
              >
              </app-text-tagging>
            </div>
          </div>
          <div
            class="form-group row text-sm-left"
            *ngIf="chooseVendorCondition.isRangeExperience"
          >
            <label class="col-sm-3">{{
              'app-popup-choose-vendor.form.vendorRangeExperience' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-date-picker-x formControlName="contractDate" [range]="true">
              </app-date-picker-x>
            </div>
          </div>
          <div
            class="button-group button-group-right mt-5"
            *ngIf="!chooseVendorCondition.isDisableFilter"
          >
            <div class="btn-reset-filter">
              <app-button
                mode="BYPASS"
                color="LIGHT"
                [outline]="false"
                (onClick)="doReset()"
              >
                {{ 'app-popup-choose-vendor.button.reset' | translate }}
              </app-button>
            </div>
            <app-button
              mode="BYPASS"
              [outline]="true"
              color="LIGHT"
              (onClick)="doApply()"
              [disabled]="formSaving"
            >
              {{ 'app.button.apply' | translate }}
            </app-button>
          </div>
        </form>
      </ng-template>
    </app-table-xx>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button
          class="mr-1"
          color="SECONDARY"
          [outline]="true"
          (onClick)="activeModal.close(true)"
          >{{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button
          (onClick)="doChoose()"
          [disabled]="tableResponse.getRecordList().length === 0"
          *ngIf="chooseVendorCondition && !chooseVendorCondition.isButtonInvite"
          >{{ 'app.button.add' | translate }}</app-button
        >
        <app-button
          (onClick)="doChoose()"
          *ngIf="chooseVendorCondition && chooseVendorCondition.isButtonInvite"
          >{{ 'app-popup-choose-vendor.button.invite' | translate }}</app-button
        >
      </div>
    </ng-template>
  </div>
</app-popup>
