import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { ProcurementPqDoc } from '../../core/bean/procurement-pq-doc';
import { ProcurementPqDocBuyerStatus } from '../../core/bean/procurement-pq-doc-buyer-status';
import { ProcurementVendor } from '../../core/bean/procurement-vendor';
import { AppTableComponent } from '../../core/components/table/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { DownloadService } from '../../core/services/download.service';
import { Validators } from '../../core/validators';
import { ProcurementRegBuyerRequest } from './procurement-reg-buyer-request';

@Component({
  templateUrl: './app-popup-pra-qualification-requirement.component.html',
  styleUrls: ['./app-popup-pra-qualification-requirement.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupPraQualificationComponent extends BaseComponentComponent {
  @Input() public procurementVendor: ProcurementVendor;
  @Input()
  public procurementPqDocBuyerStatusList: ProcurementPqDocBuyerStatus[];
  @Input() public todo: string;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  public statusDocOptionList: OptionListModel<ProcurementPqDocBuyerStatus> =
    new OptionListModel();
  public procurementPqDocList: ProcurementPqDoc[];
  public tableResponse: TableResponseModel<ProcurementPqDoc>;
  public statusDoc: ProcurementPqDocBuyerStatus;
  public isAllVerified = false;
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  constructor(
    public translateService: TranslateService,
    public downloadService: DownloadService,
    public activeModal: NgbActiveModal
  ) {
    super('procurement-reg-buyer');
  }

  public getColorAndIconConfirm = {
    ACCEPTED: { color: 'text-success', icon: 'pic pi-thumbs-up-circle' },
    REVISION: { color: 'text-primary', icon: 'pic pi-pencil-circle' },
    DECLINED: { color: 'text-danger', icon: 'pic pi-times-circle' }
  };

  public onInit(): void {
    this.setOptionList();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setTableResponse();
  }

  public setOptionList(): void {
    this.statusDocOptionList.setRequestValues(
      this.procurementPqDocBuyerStatusList
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      recordList: this.tableResponse.formArray
    });
  }

  public setTableResponse(): void {
    this.httpClientService
      .get<Response<ProcurementPqDoc[]>>(
        '/procurement-reg-buyer/get-pq-doc-list/' + this.procurementVendor.id
      )
      .subscribe((response: Response<ProcurementPqDoc[]>) => {
        if (response.status === ResponseStatusModel.OK) {
          this.procurementPqDocList = response.body;
          response.body.forEach((procPqDoc, index) => {
            if (
              this.procurementPqDocList[index].procurementPqDocBuyerStatus
                .code ===
              this.global.appConstant.pm.PROC_PQ_DOC_BUYER_STATUS_AVAILABLE
            ) {
              procPqDoc.procurementPqDocBuyerStatus = null;
              procPqDoc.note = null;
              this.procurementPqDocList[index] = procPqDoc;
            }
          });
          this.tableResponse.setRecordList(this.procurementPqDocList);
          this.tableResponse.reload();
          const isHasNull =
            this.tableResponse
              .getRecordList()
              .filter((r: ProcurementPqDoc) => !r.procurementPqDocBuyerStatus)
              .length > 0;
          this.isAllVerified = !isHasNull;
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementPq.pq.name',
        header: 'table.column.documentName'
      },
      {
        field: 'code',
        header: 'table.column.documentNumber'
      },
      {
        field: 'documentDate',
        header: 'table.column.documentDate',
        className: 'text-left',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'file.fileName',
        header: 'table.column.file',
        plugins: {
          name: 'hyperlink',
          className: 'overflow-wrap-break-word',
          onClick: this.doDownloadFile.bind(this)
        }
      },
      {
        field: 'procurementPqDocBuyerStatus',
        header: 'table.column.documentCheck',
        plugins: {
          name: 'drop-down-select',
          optionList: this.statusDocOptionList,
          validators: Validators.required(),
          placeholder:
            'procurement-reg-buyer.table.column.placeholder.documentCheck',
          onChange: this.onChangeDocCheck.bind(this),
          onInput: this.onChangeDocCheck.bind(this),
          customValue: (value: any): string => {
            if (value) {
              return this.getStringCustomValue(value);
            } else {
              return this.todo === 'view' ? '-' : null;
            }
          },
          customOption: (option: any): string => {
            return this.translateService.instant(this.getTranslateKey(option));
          }
        }
      },
      {
        field: 'note',
        header: 'table.column.note',
        plugins: {
          name: 'text-area',
          maxLength: 512,
          placeholder: this.translateService.instant(
            'procurement-reg-buyer.table.column.placeholder.note'
          ),
          onChange: this.onChangeInput.bind(this),
          onInput: this.onChangeInput.bind(this)
        }
      }
    ]);
  }

  public doDownloadFile(data: ProcurementPqDoc): void {
    if (data.file) {
      const urlFile =
        '/procurement-reg-buyer/file/view/' +
        data.file.uploadedFileName +
        '/' +
        data.file.fileType.code;
      this.downloadService.download(urlFile, data.file.fileName);
    }
  }

  public onChangeDocCheck(record: any): void {
    const recordList = this.tableResponse.getRecordList().map(record => {
      const indexOfR = this.tableResponse
        .getUpdatedRecordList()
        .findIndex(
          updateRecord =>
            updateRecord.procurementPq.id === record.procurementPq.id
        );
      return indexOfR === -1
        ? record
        : this.tableResponse.getUpdatedRecordList()[indexOfR];
    });
    this.tableResponse.setRecordList(recordList);

    const values: any[] = this.tableResponse.getRecordList();
    this.isAllVerified =
      values.filter(value => !value.procurementPqDocBuyerStatus).length === 0;
    if (this.isAllVerified) {
      const codeList = Array.from(values)
        .map(value => value.procurementPqDocBuyerStatus.code)
        .join(',');
      const pmConstant = this.global.appConstant.pm;
      const strStatusCode = codeList.includes(
        pmConstant.PROC_PQ_DOC_BUYER_STATUS_DECLINED
      )
        ? pmConstant.PROC_PQ_DOC_BUYER_STATUS_DECLINED
        : codeList.includes(pmConstant.PROC_PQ_DOC_BUYER_STATUS_REVISION)
        ? pmConstant.PROC_PQ_DOC_BUYER_STATUS_REVISION
        : pmConstant.PROC_PQ_DOC_VENDOR_STATUS_ACCEPTED;
      this.statusDoc = values.find(
        value => value.procurementPqDocBuyerStatus.code === strStatusCode
      ).procurementPqDocBuyerStatus;
    }

    if (
      record &&
      record.code !==
        this.global.appConstant.pm.PROC_PQ_DOC_BUYER_STATUS_ACCEPTED
    ) {
      this.tableResponse.currentRowChange.row.formGroup
        .get('note')
        .setValidators(Validators.required());
      this.tableResponse.currentRowChange.row.formGroup
        .get('note')
        .markAsTouched();
    } else {
      this.tableResponse.currentRowChange.row.formGroup
        .get('note')
        .clearValidators();
    }
    this.tableResponse.currentRowChange.row.formGroup
      .get('note')
      .updateValueAndValidity();
  }

  public onChangeInput(record: any): void {
    this.log.debug(record);
    const recordList = this.tableResponse.getRecordList().map(record => {
      const indexOfR = this.tableResponse
        .getUpdatedRecordList()
        .findIndex(
          updateRecord =>
            updateRecord.procurementPq.id === record.procurementPq.id
        );
      return indexOfR === -1
        ? record
        : this.tableResponse.getUpdatedRecordList()[indexOfR];
    });
    this.tableResponse.setRecordList(recordList);
  }

  public doSave(): void {
    if (this.isAllVerified) {
      this.validate();
      if (this.formGroup.valid) {
        const procurementRegBuyerRequest: ProcurementRegBuyerRequest =
          new ProcurementRegBuyerRequest();
        procurementRegBuyerRequest.procurementPqDocList =
          this.tableResponse.getRecordList();
        procurementRegBuyerRequest.statusCode = this.statusDoc.code;
        this.onChange.emit(procurementRegBuyerRequest);
      }
    } else {
      this.global.alertService.showError(
        'procurement-reg-buyer.msg.error.docMustBeVerified',
        '.popup-pra-qualification-requirement'
      );
    }
  }

  public goToProfile(): void {
    this.activeModal.dismiss();
    this.global.routerParams.set('vendorId', this.procurementVendor.vendor.id);
    this.global.routerParams.set('urlBackOutside', this.router.url);
    this.router.navigate(['/pages/company-profile']);
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public getStringCustomValue(value: any): string {
    const color = this.getColorAndIconConfirm[value.code].color;
    const icon = this.getColorAndIconConfirm[value.code].icon;
    const text = this.translateService.instant(this.getTranslateKey(value));
    return (
      '<div style="display: inline-flex"><em style="font-size: 1.5rem" class="' +
      icon +
      '"></em>' +
      '&nbsp;<span class="' +
      color +
      '">' +
      text +
      '</span></div>'
    );
  }
}
