<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <div class="button-group button-group-right">
      <!-- <app-button (onClick)="doViewHistory()">View History</app-button> -->
    </div>
    <form class="form-horizontal" *ngIf="!model.isLoading">
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-general-information.form.registrationNumber' | translate
          }}</label>
        <div class="col-sm-9 text-left">
          {{ vendorHistory.registrationNumber}}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-general-information.form.name' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{ vendorHistory.name}}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-general-information.form.initialName' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{ vendorHistory.initialName !== null ? vendorHistory.initialName :
          '-'}}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-general-information.form.idNumber' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{ vendorHistory.idNumber !== null ? vendorHistory.idNumber : '-'}}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-general-information.form.taxNumber' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{ vendorHistory.taxNumber}}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-general-information.form.address' | translate }}</label>
        <div class="col-sm-6 text-left">
          {{ vendorAddressHistory.address}}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-general-information.form.coordinateLocation' | translate
          }}</label>
        <div class="col-sm-9 text-left">
          {{ vendorAddressHistory.coordinateLocation !== null ?
          vendorAddressHistory.coordinateLocation : '-'}}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-general-information.form.country' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{ vendorAddressHistory.countryRegion.name}}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-general-information.form.province' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{ vendorAddressHistory.provinceRegion.name}}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-general-information.form.city' | translate }} </label>
        <div class="col-sm-9 text-left">
          {{ vendorAddressHistory.cityRegion.name}}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-general-information.form.district' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{ vendorAddressHistory.districtRegion.name}}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-general-information.form.postalCode' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{ vendorAddressHistory.postCode }}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-general-information.form.email' | translate }}</label>
        <div class="col-sm-9 text-left">
          {{ vendorHistory.email}}
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-general-information.form.noHp' | translate }} </label>
        <div class="col-sm-9 text-left">
          {{ vendorHistory.phoneRegion.callingCode + vendorHistory.phone}}
        </div>
      </div>
    </form>
  </div>
</div>