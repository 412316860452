<app-layout>
    <div class="row main-row">
      <div class="col-lg-12 mb-4">
        <app-table-x
          header="official-report.index.title"
          [model]="tableResponse"
          stringUrl="/official-report/index"
          (onClick)="doEdit($event)"
        >
          <ng-template #actionButtons let-rowData>
            <app-button
              size="TN"
              title="{{ 'app.tooltip.delete' | translate }}"
            >
              <em class="pir pi-trash-alt"></em>
              {{ 'app.button.delete' | translate }}
            </app-button>
          </ng-template>
        </app-table-x>
      </div>
    </div>
  </app-layout>
  