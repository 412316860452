export const approvalPathStatus = [
  { value: 'app-approval-path.status.waitingApproval', color: '#FB9224' },
  {
    value: 'app-approval-path.status.approved',
    color: '#00B84D'
  },
  {
    value: 'app-approval-path.status.revision',
    color: '#16697A'
  },
  {
    value: 'app-approval-path.status.hold',
    color: '#EB5757'
  },
  {
    value: 'app-approval-path.status.reject',
    color: '#EB5757'
  }
];
