import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Organization } from '../../core/bean/organization';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';
import { WebSocketResponseModel } from './../../core/model/websocket-response-model';
import { WorkProgramMPIModel } from './work-program-mpi-model';

@Component({
  templateUrl: './app-popup-sync-work-program.component.html'
})
export class AppPopupSyncWorkProgramComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() isLoadingSync: boolean;
  @Input() webSocketResponseModel: WebSocketResponseModel;
  @Input() onCloseModal: EventEmitter<boolean>;
  @Output() onChange: EventEmitter<WorkProgramMPIModel> = new EventEmitter();
  public selectOptionList: OptionListModel<any> = new OptionListModel(false);
  public yearOptionList: OptionListModel<any> = new OptionListModel(false);
  public mpiModel: WorkProgramMPIModel = new WorkProgramMPIModel();
  public isUser: boolean;
  public isSyncButtonClicked = false;

  constructor(
    public translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService,
    public activeModal: NgbActiveModal
  ) {
    super('work-program');
  }

  ngOnChanges(value): void {
    if (value && +value.syncProgressMessage.currentValue === 100.0) {
      this.onChange.emit();
    }
  }

  onInit(): void {
    this.onCloseModal.subscribe(() => this.activeModal.close());
    this.buildFormGroup();
    this.doSetOptionList();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      workgroup: [null],
      year: [null],
      radioSelect: [null, Validators.required()]
    });
  }

  public doSetOptionList(): void {
    const selectList = [
      {
        view: this.translateService.instant('work-program.sync.selectFilter'),
        value: 'Filter'
      },
      {
        view: this.translateService.instant('work-program.sync.selectNoFilter'),
        value: 'All'
      }
    ];

    const yearList = [
      { name: new Date().getFullYear() },
      { name: new Date().getFullYear() + 1 }
    ];
    this.yearOptionList.setRequestValues(yearList);
    this.selectOptionList
      .setRequestValues(selectList)
      .setViewPath('view')
      .setValuePath('value');
  }

  public doSyncronize(): void {
    this.isUser = true;
    this.validate();
    if (this.formGroup.valid) {
      if (this.formGroup.get('radioSelect').value !== 'All') {
        const organizationList: Organization[] =
          this.formGroup.get('workgroup').value;
        if (organizationList.length !== 0 || this.formGroup.get('year').value) {
          if (organizationList.length !== 0) {
            this.mpiModel.workgroup =
              organizationList[organizationList.length - 1].code;
          }
          if (this.formGroup.get('year').value) {
            this.mpiModel.year = this.formGroup.get('year').value.name;
          }
          // this.formGroup.setStateEnable();
          this.formGroup.disable();
          this.doGenerateSync(this.mpiModel);
          this.isLoadingSync = false;
        } else {
          this.global.alertService.showError(
            this.translateService.instant('work-program.sync.filter.alert'),
            '.main-row-popup'
          );
        }
      } else {
        this.formGroup.disable();
        // this.formGroup.setStateEnable();
        this.doGenerateSync(this.mpiModel);
        this.isLoadingSync = false;
      }
    }
  }

  public doGenerateSync(mpiModel: WorkProgramMPIModel): void {
    this.isSyncButtonClicked = true;
    this.httpClientService
      .post<Response>('/work-program/bjb-gift-sync', mpiModel)
      .subscribe();
  }

  public doCancel(): void {
    if (
      this.webSocketResponseModel &&
      this.webSocketResponseModel.progressValue
    ) {
      this.httpClientService
        .post<Response>('/work-program/cancel-bjb-gift-sync', {})
        .subscribe();
      this.activeModal.dismiss();
    }
  }
}
