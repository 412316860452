import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from './../../core/shared/shared.module';
import { BankAccountEditAddComponent } from './bank-account-edit-add.component';
import { BankAccountComponent } from './bank-account.component';
const routes = [
  { path: '', component: BankAccountComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: BankAccountEditAddComponent,
    data: { breadcrumb: 'bank-account.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: BankAccountEditAddComponent,
    data: { breadcrumb: 'bank-account.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [BankAccountComponent, BankAccountEditAddComponent],
  providers: []
})
export class BankAccountModule {}
