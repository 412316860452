import { Component, Input, Renderer2, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { CatalogAttribute } from '../../bean/catalog-attribute';
import { CatalogSpecification } from '../../bean/catalog-specification';
import { CatalogType } from '../../bean/catalog-type';
import { Currency } from '../../bean/currency';
import { OptionListModel } from '../../model/option-list-model';
import { AppCatalogItemCustomDataRequest } from '../app-catalog-item-x/request/app-catalog-item-custom-data.request';
import { AppPopupService } from '../app-popup/app-popup.service';
import { ToastService } from '../app-toast/app-toast.service';
import { AppPopupCatalogInfoLocationComponent } from './popup/app-popup-catalog-info-location.component';
import { AppPopupCatalogInfoSpecificationComponent } from './popup/app-popup-catalog-info-specification.component';
import { AppCatalogInfoResponse } from './response/app-catalog-info.response';

@Component({
  selector: 'app-catalog-info',
  templateUrl: './app-catalog-info.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppCatalogInfoComponent extends BaseComponentComponent {
  @Input() itemPath = '';
  @Input() catalogType: CatalogType;
  @Input() itemTypeCode: string;
  @Input() vendorId: number;
  public sortOptionList: OptionListModel<object> = new OptionListModel(false);
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true,
    'code'
  );
  public catalogTypeOptionList: OptionListModel<CatalogType> =
    new OptionListModel(true);

  public request: AppCatalogItemCustomDataRequest =
    new AppCatalogItemCustomDataRequest();
  public appCatalogInfoResponse: AppCatalogInfoResponse[] = [];
  public customData: string;
  public readonly locationDefChecklist = [];
  public dataCheckedList = [];
  public attributeList: CatalogAttribute[] = [];
  public valueCatalogSpecificationList: String[] = [];
  public catalogSpecificationList: CatalogSpecification[] = [];
  public catalogSpecificationNewList: CatalogSpecification[] = [];
  public indexAttributeGroupList: number[] = [];
  public catalogTypeCode: string;
  selectElement: any;
  divSelectContainerElement: any;

  constructor(
    public translateService: TranslateService,
    public appPopupService: AppPopupService,
    public renderer2: Renderer2,
    public toastService: ToastService
  ) {
    super('app-catalog-info');
  }

  public onInit(): void {
    this.setSortOptionList();
    this.buildFormGroup();
    this.setOptionListAndFormGroup();
    this.subscribeValueChanges();

    this.global.translateService.onLangChange.subscribe(() => {
      this.catalogTypeOptionList.setRequestValues(
        this.catalogTypeOptionList.getRequestValues()
      );
    });
  }

  public subscribeValueChanges(): void {
    this.formGroup.valueChanges.subscribe(value => {
      this.log.debug(value);

      this.request = this.global.copyFormAttributeToModel(
        this.request,
        this.formGroup
      );
      this.request.regionCodeList = [];
      this.formGroup?.get('locationList')?.value?.forEach(location => {
        if (location.isChecked) {
          this.request.regionCodeList.push(...location.codeList);

          this.log.debug(this.request.regionCodeList);
        }
      });
      this.request.itemTypeCode = this.itemTypeCode;
      if (this.vendorId) {
        this.request.vendorId = this.vendorId;
      }
      if (value.keyword || value.maximumPrice || value.minimumPrice || value.valueCatalogSpecificationList ) {
        setTimeout(() => {
          this.customData = JSON.stringify(this.request);
        }, 1000);
      } else {
        this.customData = JSON.stringify(this.request);
      }
    });
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      keyword: [null],
      sortBy: [null],
      itemCategory: [null],
      locationList: [null],
      minimumPrice: [null],
      maximumPrice: [null],
      catalogType: [null],
      attributeList: [null],
      valueCatalogSpecificationList: [null],
      valueList:[null]
    });

    this.formGroup.get('keyword').disable();
    this.formGroup.get('sortBy').disable();
  }

  public setOptionListAndFormGroup(): void {
    this.httpClientService
      .get('/app-catalog-info/get-option-list')
      .subscribe((response: AppCatalogInfoResponse) => {
        this.currencyOptionList.setRequestValues(response.currencyList);
        if(this.catalogType !== null){
          // this.catalogTypeIsFiltered = true;
          this.formGroup.patchValue({
            catalogType: this.catalogType
          });
          this.formGroup.get('catalogType').setIsView(true);
        }
        this.catalogTypeOptionList.setRequestValues(response.catalogTypeList);

        this.log.debug(response.defaultSearchLocationJson);
        const defaultLocationList = JSON.parse(
          response.defaultSearchLocationJson
        );

        defaultLocationList.forEach(defLocation => {
          this.locationDefChecklist.push({
            name: defLocation.name,
            codeList: defLocation.codeList,
            isChecked: false
          });
        });

        this.formGroup
          .get('locationList')
          .patchValue(this.locationDefChecklist);
        this.formGroup.patchValue({
          sortBy: this.sortOptionList.getRequestValues()[0]
        });

        this.setStateReady();
      });
  }

  public setSortOptionList(): void {
    const sortList = [
      {
        name: this.translateService.instant(
          'app-catalog-info.sort.vendorRating'
        ),
        value: 'VENDOR_RATING'
      },
      {
        name: this.translateService.instant(
          'app-catalog-info.sort.highestPrice'
        ),
        value: 'HIGHEST_PRICE'
      },
      {
        name: this.translateService.instant(
          'app-catalog-info.sort.lowestPrice'
        ),
        value: 'LOWEST_PRICE'
      },
      {
        name: this.translateService.instant('app-catalog-info.sort.newProduct'),
        value: 'NEW_PRODUCT'
      }
    ];

    this.sortOptionList.setRequestValues(sortList);
  }

  public doChooseLocation(): void {
    const todo = 'add';
    const checkedRegionCodeList = this.request.regionCodeList;
    const locationDefChecklist = this.locationDefChecklist;
    const dataCheckedList = this.dataCheckedList;
    this.appPopupService
      .open(AppPopupCatalogInfoLocationComponent, {
        todo,
        checkedRegionCodeList,
        locationDefChecklist,
        dataCheckedList
      })
      .subscribe(dataCheckedList => {
        this.log.debug(dataCheckedList);
        this.dataCheckedList = dataCheckedList;
        this.locationDefChecklist.forEach(locationDefChecklist => {
          locationDefChecklist.isChecked = false;
        });
        dataCheckedList.forEach(location => {
          locationDefChecklist.forEach(locationDef => {
            const checked = locationDef.codeList.find(x => x === location.code);
            if (checked) {
              if (locationDef.name === location.name) {
                locationDef.isChecked = true;
              }
            }
          });
          if (location.isChecked) {
            this.request.regionCodeList.push(location.code);
          }
        });
        this.request.itemTypeCode = this.itemTypeCode;
        this.customData = JSON.stringify(this.request);
      });
  }

  public translateCatalogType(catalogType: CatalogType): string {
    const translateCatalogType = this.global.translateService.instant(
      catalogType.translationKey.module.code.toLowerCase() +
        '.' +
        catalogType.translationKey.key
    );
    return translateCatalogType;
  }

  public doReset(): void {
    this.formGroup.get('itemCategory').reset();
    this.formGroup.get('minimumPrice').reset();
    this.formGroup.get('maximumPrice').reset();
    this.formGroup.get('attributeList').reset();
    if(this.catalogType === null){
      this.formGroup.get('catalogType').reset();
    }
    this.locationDefChecklist.forEach(location => {
      location.isChecked = false;
    });
    this.formGroup.get('locationList').patchValue(this.locationDefChecklist);
    this.attributeList = [];
    this.catalogSpecificationList = [];
    this.appCatalogInfoResponse.forEach(appCatalogInfoResponse => {
      appCatalogInfoResponse.catalogSpesificationList = [];
      appCatalogInfoResponse.catalogAttributeGroup = undefined;
    });
    this.formGroup.patchValue({
      valueCatalogSpecificationList: this.catalogSpecificationList,
      attributeList: this.attributeList
    });
  }

  public doResetKeyword(): void {
    this.formGroup.get('keyword').reset();
  }

  public handleAppTaggingChange(catalogSpecification: CatalogSpecification): void {

      this.appCatalogInfoResponse.forEach((appCatalogInfoResponse) => {
        let selectedIndex = appCatalogInfoResponse.catalogSpesificationList.findIndex(
          catalogSpesificationExs => catalogSpesificationExs.value === catalogSpecification.value
          );
          const index = this.request.valueList.findIndex(x => x === appCatalogInfoResponse.catalogSpesificationList?.[selectedIndex]?.name);
          if (selectedIndex >= 0) {
            if (appCatalogInfoResponse.catalogSpesificationList[selectedIndex].isChoosen) {
              appCatalogInfoResponse.catalogSpesificationList.splice(selectedIndex, 1);
            } else {
              appCatalogInfoResponse.catalogSpesificationList[selectedIndex].value = undefined;
              appCatalogInfoResponse.catalogSpesificationList[selectedIndex].name = undefined;
            }
            this.request.valueList.splice(index, 1);
          }
          
          appCatalogInfoResponse.lengthCatalogSpesificationNew = appCatalogInfoResponse.catalogSpesificationList.length;
      });
      this.setStateReady();
  }

  public doChooseSpecification(): void {
    const getAppCatalogInfoResponse = this.appCatalogInfoResponse;
    let valueList: string[] = [];
    this.appPopupService
      .open(AppPopupCatalogInfoSpecificationComponent, {
        getAppCatalogInfoResponse
      })
      .subscribe(appCatalogInfoResponse => {
        valueList = [];
        this.catalogSpecificationNewList = [];
        appCatalogInfoResponse.catalogSpesificationList.forEach((catalogSpecification) => {
          catalogSpecification.isChoosen = false;
          if (catalogSpecification.catalogAttribute.formObject.code === this.global.appConstant.core.FORM_OBJECT_CODE_CHOSEN
             || catalogSpecification.catalogAttribute.formObject.code === this.global.appConstant.core.FORM_OBJECT_CODE_COMBO_BOX
              || catalogSpecification.catalogAttribute.formObject.code === this.global.appConstant.core.FORM_OBJECT_CODE_CHECK_BOX) {

            if (catalogSpecification.value !== null && catalogSpecification.value !== undefined
              && catalogSpecification.value !== "" && catalogSpecification.value !== '') {
                catalogSpecification.name = catalogSpecification.value.name;
                if (catalogSpecification.value.length > 0) {
                  catalogSpecification.isChoosen = true;
                  catalogSpecification.value.forEach((newCatalogSpecificationTempl, index) => {
                    if (index === 0) {
                      catalogSpecification.name = newCatalogSpecificationTempl.name;
                      catalogSpecification.isArray = true;
                      this.catalogSpecificationNewList.push(catalogSpecification);
                      valueList.push(catalogSpecification.name);
                    } else {
                      const catalogSpesification = new CatalogSpecification();
                      catalogSpesification.catalogAttribute = catalogSpecification.catalogAttribute;
                      catalogSpesification.catalogAttributeGroup = catalogSpecification.catalogAttributeGroup;
                      catalogSpesification.value = newCatalogSpecificationTempl.value;
                      catalogSpesification.name = newCatalogSpecificationTempl.name;
                      catalogSpesification.isArray = false;
                      catalogSpesification.isChoosen = true;
                      this.catalogSpecificationNewList.push(catalogSpesification);
                      appCatalogInfoResponse.catalogSpesificationList.push(catalogSpesification);
                      valueList.push(catalogSpesification.name);
                    }
                  });
                } else {
                  this.catalogSpecificationNewList.push(catalogSpecification);
                  valueList.push(catalogSpecification.name);
                }
            }
          } else {
            if (catalogSpecification.value !== null && catalogSpecification.value !== undefined
              && catalogSpecification.value !== "" && catalogSpecification.value !== '') {
               catalogSpecification.name = catalogSpecification.value;
               this.catalogSpecificationNewList.push(catalogSpecification);
               valueList.push(catalogSpecification.name);
            }
          }
        });
        appCatalogInfoResponse.lengthCatalogSpesificationOld = appCatalogInfoResponse.catalogSpesificationList.length;
        appCatalogInfoResponse.lengthCatalogSpesificationNew = appCatalogInfoResponse.catalogSpesificationList.length;
        
        let attributeGroupExs = this.appCatalogInfoResponse.find(
          appCatalogInfoResponseOld => appCatalogInfoResponseOld?.catalogAttributeGroup?.id === appCatalogInfoResponse?.catalogAttributeGroup?.id
          );
          let indexAttributeGroupExs = this.appCatalogInfoResponse.findIndex(
            appCatalogInfoResponseOld => appCatalogInfoResponseOld?.catalogAttributeGroup?.id === appCatalogInfoResponse?.catalogAttributeGroup?.id
            );
          if (attributeGroupExs === undefined) {
            this.appCatalogInfoResponse.splice(indexAttributeGroupExs, 1);
          }
        this.attributeList = [];
        this.catalogSpecificationList = [];
        this.appCatalogInfoResponse.push(appCatalogInfoResponse);
        this.catalogSpecificationNewList.forEach(catalogSpesification => {
          this.catalogSpecificationList.push(catalogSpesification);
          this.attributeList.push(catalogSpesification.catalogAttribute);
        });
        this.formGroup.patchValue({
          valueCatalogSpecificationList: this.catalogSpecificationList,
          attributeList: this.attributeList,
          valueList: valueList
        });
        this.request.attributeList = this.attributeList;
        this.request.valueList = valueList;
        this.request.catalogSpecificationList = this.catalogSpecificationList;
        this.request.itemTypeCode = this.itemTypeCode;
        this.customData = JSON.stringify(this.request);
        this.setStateReady();
      });
  }

  public validatePrice(): void {
    const minimumPrice = Number(this.formGroup.get('minimumPrice').value.price);
    const maximumPrice = Number(this.formGroup.get('maximumPrice').value.price);
    if (minimumPrice > maximumPrice) {
      this.toastService.showError("Nilai minimum tidak boleh lebih besar dari nilai maksimum");
    }
  }
}

