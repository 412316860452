import { BaseEntity } from "../base/base-entity";
import { DueDiligenceTemplateOTS } from "../bean/due-diligence-template-OTS";
import { DueDiligenceSchedule } from "../bean/due-diligence-schedule";

export class DueDiligenceOTSEntity extends BaseEntity {

    dueDiligenceTemplateOTS: DueDiligenceTemplateOTS = new DueDiligenceTemplateOTS();
    dueDiligenceSchedule: DueDiligenceSchedule = new DueDiligenceSchedule();
    isValid: boolean;
    note: string;
}