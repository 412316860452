import { Component } from '@angular/core';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetProgressCompleteProfileResponse } from './widget-progress-complete-profile-response';
@Component({
  selector: 'widget-progress-complete-profile',
  templateUrl: './widget-progress-complete-profile.component.html',
  styleUrls: ['./widget-progress-complete-profile.component.scss']
})
export class WidgetProgressCompleteProfileComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-progress-complete-profile';
  public progressCompleteProfileResponse: WidgetProgressCompleteProfileResponse;
  public isWrongRole = false;
  public profileStrengthLevel: string;
  public response: WidgetProgressCompleteProfileResponse;
  constructor() {
    super(WidgetProgressCompleteProfileComponent.moduleCode);
  }

  public onInit(): void {
    this.getAndSetTotalAttribute();
  }

  public getAndSetTotalAttribute(): void {
    this.httpClientService
      .get('/widget-progress-complete-profile/index')
      .subscribe((response: WidgetProgressCompleteProfileResponse) => {
        if (response != null) {
          this.response = response;
          this.progressCompleteProfileResponse = response;
          this.profileStrengthLevel =
            (response.percentage || 0) < 100 / 3
              ? 'weak'
              : (response.percentage || 0) < (100 * 2) / 3
              ? 'strong'
              : 'expert';
        } else {
          this.isWrongRole = true;
        }
        this.setStateReady();
      });
  }

  public goToCompanyProfile(): void {
    this.router.navigate(['pages/company-profile']);
  }

  public getMinimumField(): number {
    if (this.profileStrengthLevel === 'weak') {
      return Math.ceil((this.response.totalAttribute * 1) / 3);
    } else {
      return Math.ceil((this.response.totalAttribute * 2) / 3);
    }
  }
}
