<div
  class="carousel slide"
  [attr.id]="generatedId"
  [attr.data-interval]="model.carouselInterval"
  [attr.data-keyboard]="model.carouselKeyboard"
  [attr.data-pause]="model.carouselPause"
  [attr.data-ride]="model.carouselRide"
  [attr.data-wrap]="model.carouselWrap"
>
  <ol
    class="carousel-indicators"
    *ngIf="model && model.isShowIndicator && !carouselIndicatorTmpl"
  >
    <li
      class="cursor-pointer"
      [attr.data-target]="'#' + generatedId"
      [attr.data-slide-to]="i"
      *ngFor="let image of model.images; let i = index"
      [ngClass]="{ active: i === 0 }"
    >
      <img
        *ngIf="!image.referenceLink"
        [attr.src]="
          global.config.BACKEND_ADDRESS +
            '/' +
            model.stringUrl +
            '/file/view/' +
            image.uploadedFileName +
            '/IMG_ITEM_CATALOG'
            | resolveImgSrc
            | async
        "
        [attr.alt]="'Carousel Indicator ' + i"
        width="auto"
        height="auto"
      />
    </li>
  </ol>

  <ng-container *ngIf="model.isShowIndicator; carouselIndicatorTmpl">
    <ng-container
      *ngTemplateOutlet="
        carouselIndicatorTmpl;
        context: {
          $implicit: generatedId,
          images: model.images,
          stringUrl: model.stringUrl
        }
      "
    >
    </ng-container>
  </ng-container>

  <div class="carousel-inner" *ngIf="model">
    <div
      class="carousel-item"
      *ngFor="let image of model.images; let i = index"
      [ngClass]="{ active: i === 0 }"
    >
      <ng-container *ngIf="carouselItemTmpl; else elseCarouselItemTmpl">
        <ng-container
          *ngTemplateOutlet="carouselItemTmpl; context: { $implicit: image }"
        >
        </ng-container>
      </ng-container>
      <ng-template #elseCarouselItemTmpl>
        <a
          href="{{ image.referenceLink }}"
          *ngIf="image.referenceLink"
          [attr.title]="image.hoverTitleText"
        >
          <img
            [attr.src]="
              global.config.BACKEND_ADDRESS +
                '/' +
                model.stringUrl +
                '/file/view/' +
                image.uploadedFileName +
                '/IMG_ITEM_CATALOG'
                | resolveImgSrc
                | async
            "
            [attr.alt]="'Carousel Image ' + i"
            width="auto"
            height="auto"
          />
        </a>
        <img
          *ngIf="!image.referenceLink"
          [attr.src]="
            global.config.BACKEND_ADDRESS +
              '/' +
              model.stringUrl +
              '/file/view/' +
              image.uploadedFileName +
              '/IMG_ITEM_CATALOG'
              | resolveImgSrc
              | async
          "
          [attr.alt]="'Carousel Image ' + i"
          width="auto"
          height="auto"
        />
        <div
          class="carousel-caption d-none d-md-block"
          *ngIf="model.isShowCaption"
        >
          <h5>{{ image.captionTitle }}</h5>
          <p>{{ image.captionDescription }}</p>
        </div>
      </ng-template>
    </div>
  </div>

  <ng-container *ngIf="!carouselControlTmpl && model.isShowControl">
    <a
      class="carousel-control-prev"
      [attr.href]="'#' + generatedId"
      role="button"
      data-slide="prev"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    </a>
    <a
      class="carousel-control-next"
      [attr.href]="'#' + generatedId"
      role="button"
      data-slide="next"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
    </a>
  </ng-container>

  <ng-container *ngIf="carouselControlTmpl">
    <ng-container
      *ngTemplateOutlet="
        carouselControlTmpl;
        context: { $implicit: generatedId }
      "
    >
    </ng-container>
  </ng-container>

  <ng-content></ng-content>
</div>
