import { Component, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Vendor } from 'src/app/core/bean/vendor';
import { VendorPerformance } from 'src/app/core/bean/vendor-performance';
import { VendorPerformanceOverall } from 'src/app/core/bean/vendor-performance-overall';
import { VendorPerformanceOverallDetail } from 'src/app/core/bean/vendor-performance-overall-detail';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';

@Component({
  selector: 'app-popup-tab-performance-history-detail',
  templateUrl: './app-popup-tab-performance-history-detail.component.html'
})

export class AppPopupTabPerformanceHistoryDetailComponent extends BaseComponentComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  @Input() vendorPerformanceOverallId: number;
  public header: string;
  public tableResponse: TableResponseModel<VendorPerformance>;
  public vendor: Vendor = new Vendor();
  public vendorHistoryId: number;
  public vendorPerformanceOverall: VendorPerformanceOverall;
  public vendorPerformanceOverallDetailList: VendorPerformanceOverallDetail[] = [];
  public monthList: string[] = [];
  public rating = 0;

  constructor(public translateService: TranslateService) {
    super('tab-performance-history');
  }

  public onInit(): void {
    this.setList();
    this.buildTableResponse();
    this.setCustomData();
    this.getDataFromServer();
  }

  public setList(): void {
    this.monthList = [
      this.translateService.instant('tab-performance-history.month.january'),
      this.translateService.instant('tab-performance-history.month.february'),
      this.translateService.instant('tab-performance-history.month.march'),
      this.translateService.instant('tab-performance-history.month.april'),
      this.translateService.instant('tab-performance-history.month.may'),
      this.translateService.instant('tab-performance-history.month.june'),
      this.translateService.instant('tab-performance-history.month.july'),
      this.translateService.instant('tab-performance-history.month.august'),
      this.translateService.instant('tab-performance-history.month.september'),
      this.translateService.instant('tab-performance-history.month.october'),
      this.translateService.instant('tab-performance-history.month.november'),
      this.translateService.instant('tab-performance-history.month.december')
    ];
  }

  public getDataFromServer(): void {
    this.httpClientService.get<VendorPerformanceOverallDetail[]>('/tab-performance-history/get-overall-detail-list/' + this.vendorPerformanceOverallId)
      .subscribe(list => {
        this.vendorPerformanceOverall = list[0].vendorPerformanceOverall;
        this.header = (this.vendorPerformanceOverall.startYear === this.vendorPerformanceOverall.endYear) ?
          this.translateService.instant('tab-performance-history.titleDetail') + ' ' + this.monthList[+this.vendorPerformanceOverall.startMonth - 1]
          + ' - ' + this.monthList[+this.vendorPerformanceOverall.endMonth - 1] + ' ' + this.vendorPerformanceOverall.endYear :
          this.translateService.instant('tab-performance-history.titleDetail') + ' ' + this.monthList[+this.vendorPerformanceOverall.startMonth - 1]
          + ' ' + this.vendorPerformanceOverall.startYear
          + ' - ' + this.monthList[+this.vendorPerformanceOverall.endMonth - 1] + ' ' + this.vendorPerformanceOverall.endYear;
        this.vendorPerformanceOverallDetailList = list;
        this.vendor = list[0].vendorPerformanceOverall.vendor;
        this.rating = Math.round(list[0].vendorPerformanceOverall.rating * 100) / 100;
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.column.performanceCode', customClass: 'text-center' },
      { field: 'createdDate', header: 'table.column.performanceDate', format: FieldFormatEnum.ShortDateAndTime },
      { field: 'moduleScoringCriteria.name', header: 'table.column.module', customClass: 'text-center' },
      { field: 'rating', header: 'table.column.rating', format: FieldFormatEnum.Rating, customClass: 'text-center' }
    ]);
  }

  public setCustomData(): void {
    this.tableResponse.setCustomData(this.vendorPerformanceOverallId);
  }
}
