import { DatePickerDate } from '../model';
const ONE_DAY_IN_TIME = 1000 * 60 * 60 * 24;
export const createTotalSelectedDate = (
  from: DatePickerDate,
  to: DatePickerDate
): number => {
  const dateFrom = new Date(from.date);
  const dateTo = new Date(to.date);
  return (
    Math.floor(
      (dateTo.getTime() > dateFrom.getTime()
        ? dateTo.getTime() - dateFrom.getTime()
        : dateFrom.getTime() - dateTo.getTime()) / ONE_DAY_IN_TIME
    ) + 1
  );
};
