<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="bank.title" detail="app.action.{{ todo }}"
      [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'bank.form.code' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field autofocus="true" size="SM" type="code"
              formControlName="code" maxLength="32"
              tooltip="{{ 'bank.tooltip.code' | translate }}"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'bank.form.region' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box [optionList]="regionList" formControlName="region">
            </app-combo-box>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'bank.form.key' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field size="SM" type="integer" formControlName="key"
              maxLength="32"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'bank.form.name' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="name" type="alphabet"
              maxLength="128"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{ 'bank.form.swift' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field size="SM" formControlName="swift" type="code"
              maxLength="32"></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{ 'bank.form.value' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box size="SM" formControlName="value"
              [optionList]="valueList"></app-combo-box>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{ 'bank.form.description' |
            translate }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area heigth="LG" formControlName="description"
              maxLength="512"></app-text-area>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button color="SECONDARY" class="mr-1" (onClick)="doCancel()">
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button color="PRIMARY" (onClick)="doSave()">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>