import { Attribute } from '../../core/bean/attribute';
import { FormObject } from '../../core/bean/form-object';
import { Lang } from '../../core/bean/lang';
import { Module } from '../../core/bean/module';
import { Translation } from '../../core/bean/translation';
import { TranslationKey } from '../../core/bean/translation-key';
import { ListOfValueModel } from '../../core/model/list-of-value-model';

export class AttributeResponse {
  langList: Lang[];
  translationKeyList: TranslationKey[];
  translationList: Translation[];
  formObjectList: FormObject[];
  module: Module;
  translationValueList: string[];
  translationKey: TranslationKey;
  attribute: Attribute;
  listOfValueModel: ListOfValueModel[];
  formObject: FormObject;
}