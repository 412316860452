import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorPerformanceScore } from '../../core/bean/vendor-performance-score';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { FileUploader } from '../../core/components/upload';
import { Validators } from '../../core/validators';
import { PerformanceProcurementDTO } from './dto/performance-procurement.dto';
@Component({
  templateUrl: './performance-procurement-detail.component.html'
})
export class PerformanceProcurementDetailComponent extends BaseModuleComponent {
  public get parentsCommonScoring(): FormArray {
    return this.formGroup.get('parentsCommonScoring') as FormArray;
  }

  public get parentsSpecificScoring(): FormArray {
    return this.formGroup.get('parentsSpecificScoring') as FormArray;
  }

  public procurementId: number;
  public vendorId: number;
  public isApproval: boolean;
  public objectId: number;
  public performanceProcurementDTO: PerformanceProcurementDTO = new PerformanceProcurementDTO();
  public total = 0;
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();

  public fileUploader: FileUploader = new FileUploader(
    '/performance-procurement/',
    'performance-procurement',
    this.global.appConstant.fileType.DOC_PERFORMANCE
  );

  constructor(translateService: TranslateService) {
    super('performance-procurement', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
    this.setViewOnly();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.procurementId = this.global.routerParams.get('procurementId');
    this.vendorId = this.global.routerParams.get('vendorId');
    this.isApproval = this.global.routerParams.get('isApproval');
    this.objectId = this.global.routerParams.get('objectId'); // vendor performance id
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      file: [null, Validators.required()],
      note: [null],
      parentsCommonScoring: this.formBuilder.array([]),
      parentsSpecificScoring: this.formBuilder.array([]),
      rating: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<PerformanceProcurementDTO>('/performance-procurement/detail', {
        todo: this.todo,
        vendorId: this.vendorId,
        procurementId: this.procurementId,
        performanceId: this.objectId
      })
      .subscribe(performanceProcurementDTO => {
        this.performanceProcurementDTO = performanceProcurementDTO;
        if (this.performanceProcurementDTO.performance) {
          this.workflowModelPrcs = this.performanceProcurementDTO.performance.workflowModelPrcs;
          this.total = this.performanceProcurementDTO.performance.rating;
          this.fileUploader.setFile(
            this.performanceProcurementDTO.performance.file
          );
          this.formGroup.patchValue({
            rating: this.total,
            file: this.fileUploader.fileObjectList,
            note: this.performanceProcurementDTO.performance.note
          });
      }
        this.addCredsCommonScoring();
        this.addCredsSpecificScoring();

        this.setStateReady();
      });
  }

  public addCredsCommonScoring(): void {
    this.performanceProcurementDTO.scoringCriteriaGroupListCommon.forEach(
      scoringCriteriaGroup => {
        const performanceScoreList: VendorPerformanceScore[] = [];
        this.performanceProcurementDTO.performanceScoreList.forEach(
          performanceScore => {
            if (
              performanceScore.scoringCriteriaGroupCode ===
              scoringCriteriaGroup.code
            ) {
              performanceScoreList.push(performanceScore);
            }
          }
        );

        this.parentsCommonScoring.push(
          this.formBuilder.group({
            name: scoringCriteriaGroup.name, // utk header
            scoringCriteriaGroup,
            result: scoringCriteriaGroup.totalResult,
            listOfScoringCriteria: [performanceScoreList]
          })
        );
      }
    );
  }

  public addCredsSpecificScoring(): void {
    this.performanceProcurementDTO.scoringCriteriaGroupListSpecific.forEach(
      scoringCriteriaGroup => {
        const performanceScoreList: VendorPerformanceScore[] = [];
        this.performanceProcurementDTO.performanceScoreList.forEach(
          performanceScore => {
            if (
              performanceScore.scoringCriteriaGroupCode ===
              scoringCriteriaGroup.code
            ) {
              performanceScoreList.push(performanceScore);
            }
          }
        );

        this.parentsSpecificScoring.push(
          this.formBuilder.group({
            name: scoringCriteriaGroup.name, // utk header
            scoringCriteriaGroup,
            result: scoringCriteriaGroup.totalResult,
            listOfScoringCriteria: [performanceScoreList]
          })
        );
      }
    );
  }

  public addScoringCriteria(parent: any): FormArray {
    const listOfScoringCriteria = parent.get(
      'listOfScoringCriteria'
    ) as FormArray;

    if (listOfScoringCriteria.value.length === 0) {
      parent.controls.scoringCriteriaList.value.forEach(performanceScore => {
        listOfScoringCriteria.push(
          this.formBuilder.group({
            scoringCriteria: performanceScore.scoringCriteria,
            name: performanceScore.scoringCriteria.name,
            value: performanceScore.value,
            result: performanceScore.result
          })
        );
      });
    }

    return listOfScoringCriteria;
  }

  public doCancel(): void {
    this.global.routerParams.set('vendorId', this.vendorId);
    this.router.navigate(['/pages/performance-procurement/detail-procurement']);
  }

  public getFormArray(formControlName: string): FormArray {
    return this.formGroup.get(formControlName) as FormArray;
  }
}
