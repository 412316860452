<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="procedure.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'procedure.form.code' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              autofocus="true"
              size="SM"
              formControlName="code"
              type="code"
              tooltip="{{ 'procedure.tooltip.code' | translate }}"
              maxLength="64"
            ></app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'procedure.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="name" maxLength="128">
            </app-text-field>
          </div>
        </div>

        <div
          class="form-group row text-sm-right required"
          *ngFor="let hospitalClass of hospitalClassList; let i = index"
        >
          <label class="col-sm-3 control-label">{{
            hospitalClass.name | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-currency
              formControlName="amountClass{{ hospitalClass.code }}"
              [optionList]="currencyOptionList"
              maxLength="16"
            ></app-currency>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button color="SECONDARY" class="mr-1" (onClick)="doCancel()">
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button color="PRIMARY" (onClick)="doSave()">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
