import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Pq } from '../../core/bean/pq';
import { AppPopupImportService } from '../../core/components/app-popup/app-popup-import/app-popup-import.service';
import { ImportFileWebsocketService } from '../../core/components/app-popup/app-popup-import/import-file-websocket.service';
import { AppTableXComponent } from '../../core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from '../../core/components/app-table-x/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './pq.component.html'
})
export class PqComponent extends BaseModuleComponent implements OnInit {
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  public tableResponse: TableResponseModel<Pq>;
  constructor(
    translateService: TranslateService,
    public appPopupImportService: AppPopupImportService,
    public importFileWebsocketService: ImportFileWebsocketService
  ) {
    super('pq', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: '0.code', header: 'table.column.code', plugins: 'hyperlink' },
      { field: '1', header: 'table.column.name' },
      { field: '0.description', header: 'table.column.description' }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/pq/add']);
  }

  public doEdit(data: any): void {
    const pq: Pq = data[0];
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('pqId', pq.id);
    this.router.navigate(['/pages/pq/edit']);
  }

  public doDelete(event: any): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const pqList: Pq[] = event.checkedRecordList.map(data => data[0]);
          this.httpClientService
            .post<Response<Pq[]>>('/pq/delete', pqList)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  public doImport(): void {
    this.appPopupImportService.open('/pq/import-excel').subscribe(result => {
      if (result) {
        this.tableResponse.reload();
      }
    });
    this.importFileWebsocketService.appPopupImportComponent =
      this.appPopupImportService.appPopupService.componentInstance;
  }
}
