import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorAddressHistory } from '../../core/bean/vendor-address-history';
import { VendorHistory } from '../../core/bean/vendor-history';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';

@Component({
  templateUrl: './tab-general-information.component.html'
})
export class TabGeneralInformationComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<VendorAddressHistory>;
  public vendorAddressHistory: VendorAddressHistory = new VendorAddressHistory();
  public vendorHistory: VendorHistory = new VendorHistory();
  constructor(translateService: TranslateService) {
    super('tab-general-infromation', translateService);
  }

  onInit(): void {
    this.doGetAndSetVendorAddress();
  }

  public doGetAndSetVendorAddress(): void {
    this.httpClientService.get<VendorAddressHistory>('/tab-general-information/index/' + this.vendorHistoryId)
      .subscribe(vendorAddressHistory => {
        this.vendorAddressHistory = vendorAddressHistory;
        this.doSetVendor();
        this.model.setStateReady();
      });
  }

  public doSetVendor(): void {
    this.vendorHistory = this.vendorAddressHistory.vendorHistory;
  }

  public doViewHistory(): void {
    this.global.routerParams.set('selector', 'tab-general-information-history');
    this.global.routerParams.set('componentInstance', { id: 1, name: 'Terserah' });
    this.router.navigate(['/pages/vendor-history/detail/history']);
  }
}
