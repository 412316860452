<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="budget-allocation.title" [isLoading]="formLoading"
            [isSaving]="formSaving">
            <app-table [model]="tableResponse" [isServerSide]="true"
                stringUrl="/budget-allocation/coa/detail/index">
                <ng-template #actionButtons let-rowData>
                    <app-button mode="BYPASS" *ngIf="rowData.coa.isHasChild"
                        size="TN" class="mr-2" (onClick)="doDetailCoa(rowData)">
                        <em class="fas fa-search"></em>
                    </app-button>
                </ng-template>
            </app-table>
            <div class="row justify-content-center mt-5">
                <app-button mode="BYPASS" color="SECONDARY"
                    (onClick)="doCancel()" class="mr-1" [disabled]='formSaving'>
                    {{ 'app.button.back' | translate }}</app-button>
            </div>
        </app-card>
    </div>
</div>