<ul class="app-rating-detail">
  <li class="row" *ngFor="let rating of ratings; let i = index">
    <div class="col-3 pr-0 rating-star">
      <app-rating-icon value="1">
        <span class="ml-2">{{ ratings.length - i }}</span>
      </app-rating-icon>
    </div>
    <div class="col-6 px-0 mt-1 pipe">
      <span
        class="bg"
        [ngStyle]="{
          'background-color': ratingColors[i],
          width: (rating / totalRating) * 100 + '%'
        }"
      ></span>
    </div>
    <span class="col-2">{{ rating }}</span>
  </li>
</ul>
