<div class="row main-row">
  <div class="col-lg-12 mb-4">
  
    <app-card header="organization.title" [isLoading]="formLoading" [isSaving]="formSaving">

      <div class="row justify-content-end mr-2">
        <div class="col-md6">
           <app-button mode="BYPASS" (onClick)="doExport()"> <em class="fas" [ngClass]="
              !isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'
            "></em>  {{
      'app.button.export' | translate }}</app-button>
        </div>
      </div>
       

      <app-tree-org [model]="treeModel" (onEventChange)="doOnEventChange($event)">
        <ng-template #treePopupContent let-treePopupFormGroup>
          <form class="form-horizontal" [formGroup]="treePopupFormGroup" novalidate>
            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'organization.form.code' | translate
                }}</label>
              <div class="col-sm-8 text-left">
                <app-text-field autofocus="true" size="XL" formControlName="code" type="alphanumeric" minLength="6"
                  maxLength="32" tooltip="{{ 'organization.tooltip.code' | translate }}">
                  (onChange)="doCodeChange($event)"
                  ></app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'organization.form.name' | translate
                }}</label>
              <div class="col-sm-8 text-left">
                <app-text-field size="XL" formControlName="name" maxLength="64"></app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'organization.form.hierarchyLevel' | translate
                }}</label>
              <div class="col-sm-8 text-left">
                <app-text-field size="XL" formControlName="hierarchyLevel" type="integer" min="0"
                  max="8"></app-text-field>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'organization.form.letterNumberCode' | translate
                }}</label>
              <div class="col-sm-8 text-left">
                <app-text-field size="XL" formControlName="letterNumberCode" maxLength="64"></app-text-field>
              </div>
            </div>
          </form>
        </ng-template>
      </app-tree-org>
    </app-card>
  </div>
</div>