import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppBadgeModule } from '../../../../../core/components/app-badge/app-badge.module';
import { AppWidgetContentSliderModule } from '../../../../../core/widgets/app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../../../../../core/widgets/app-widget.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetTotalSlaComponent } from './widget-total-sla.component';

@NgModule({
  imports: [
    VendorModule,
    AppWidgetContentSliderModule,
    AppBadgeModule,
    WidgetCardModule,
    AppWidgetModule
  ],
  declarations: [WidgetTotalSlaComponent],
  entryComponents: [WidgetTotalSlaComponent],
  exports: [WidgetTotalSlaComponent]
})
export class WidgetTotalSlaModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetTotalSlaComponent.moduleCode,
      WidgetTotalSlaComponent,
      'w-25'
    );
  }
}
