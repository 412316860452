import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { ProcurementSowPaymentTerm } from 'src/app/core/bean/procurement-sow-payment-term';
import { ProcurementSowPaymentTermItem } from 'src/app/core/bean/procurement-sow-payment-term-item';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { ReportService } from 'src/app/core/services/report.service';
import { ProcurementScopeWork } from '../../core/bean/procurement-scope-work';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { PaymentRequestBillingView } from '../../core/view/entity/bean/payment-request-billing-view';
import { AppPopupDeliveryMonitoringComponent } from './app-popup-delivery-monitoring.component';
import { AppPopupRequestClosedContractComponent } from './app-popup-request-closed-contract.component';
import { DeliveryMonitoringResponse } from './response/delivery-monitoring.response';

@Component({
  templateUrl: './delivery-monitoring-edit-add.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DeliveryMonitoringEditAddComponent extends BaseModuleComponent {
  public tableItemList: any[][] = [];
  public tableItemResponse: TableResponseModel<ProcurementSowPaymentTermItem>;
  public tableServiceResponse: TableResponseModel<ProcurementSowPaymentTermItem>;
  public tableProcurementItemResponse: TableResponseModel<ProcurementSowPaymentTermItem>;
  public tableProcurementServiceResponse: TableResponseModel<ProcurementSowPaymentTermItem>;
  public deliveryMonitoringResponse: DeliveryMonitoringResponse =
    new DeliveryMonitoringResponse();
  public procurementScopeWork: ProcurementScopeWork;
  public stageOfWorkName: string;
  public termName: string;
  public statusTermProc = 3;
  public statusStageProc = 3;
  public totalTermClosed = 0;
  public totalTermClosedList: number[] = [];
  public activeStageList: boolean[] = [];
  public activeTermList: boolean[][] = [];

  constructor(
    public translateService: TranslateService,
    public appPopupService: AppPopupService,
    public reportService: ReportService
  ) {
    super('delivery-monitoring', translateService);
  }

  public onInit(): void {
    this.getDataFromRouterParam();
    this.getDataFromServer();
  }

  public getDataFromRouterParam(): void {
    this.procurementScopeWork = this.global.routerParams.get(
      'procurementScopeWork'
    );
    this.todo = this.global.routerParams.get('todo');
  }

  public getDataFromServer(): void {
    this.httpClientService
      .get<DeliveryMonitoringResponse>(
        '/delivery-monitoring/detail/' + this.procurementScopeWork.id
      )
      .subscribe(deliveryMonitoringDTO => {
        this.deliveryMonitoringResponse = deliveryMonitoringDTO;
        this.buildTableResponse();
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.activeStageList = [];
    this.activeTermList = [];
    if (this.deliveryMonitoringResponse.procurementSowList) {
      this.totalTermClosedList = [];
      let firstActiveStage = false;
      let i = 0;
      this.deliveryMonitoringResponse.procurementSowList.forEach(
        procurementSow => {
          this.tableItemList[i] = [];
          let totalTermClosed = 0;
          if (
            (procurementSow.status === 1 || procurementSow.status === 2) &&
            !firstActiveStage
          ) {
            this.activeStageList.push(true);
            firstActiveStage = true;
          } else {
            this.activeStageList.push(false);
          }

          this.activeTermList[i] = [];
          let firstActiveTerm = false;
          procurementSow.procurementSowPaymentTermList.forEach(
            procurementSowPaymentTerm => {
              if (
                (procurementSowPaymentTerm.status === 1 ||
                  procurementSowPaymentTerm.status === 2) &&
                !firstActiveTerm
              ) {
                this.activeTermList[i].push(true);
                firstActiveTerm = true;
              } else {
                this.activeTermList[i].push(false);
              }
              this.tableItemResponse = this.createTableItemResponse();
              this.tableItemResponse.setCustomData(
                procurementSowPaymentTerm.id
              );
              this.tableServiceResponse = this.createTableServiceResponse();
              this.tableServiceResponse.setCustomData(
                procurementSowPaymentTerm.id
              );
              const tableItem = {
                tableItemResponse: this.tableItemResponse,
                tableServiceResponse: this.tableServiceResponse
              };
              this.tableItemList[i].push(tableItem);
              if (procurementSowPaymentTerm.status === 3) {
                totalTermClosed += 1;
              }
            }
          );
          this.totalTermClosedList.push(totalTermClosed);
          i++;
        }
      );
    }
  }

  public createTableItemResponse(): TableResponseModel<ProcurementSowPaymentTermItem> {
    return new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementResult.procurementItem.item.name',
        header: 'table.column.name'
      },
      {
        field: 'receivedItemPerQuantity',
        header: 'table.column.received',
        customClass: 'text-center'
      },
      {
        field: 'procurementResult.percentageValue',
        header: 'table.column.percentageOfWork',
        customClass: 'text-center',
        fn: this.setPercentage.bind(this)
      },
      {
        field: 'status',
        header: 'table.column.status',
        customClass: 'text-center',
        datamap:
          '{' +
          '"1" : "' +
          this.global.translateService.instant(
            'delivery-monitoring.status.new'
          ) +
          '"' +
          ', "2" : "' +
          this.global.translateService.instant(
            'delivery-monitoring.status.onProgress'
          ) +
          '"' +
          ', "3" : "' +
          this.global.translateService.instant(
            'delivery-monitoring.status.done'
          ) +
          '"' +
          '}',
        fn: this.handleStatusTextColor.bind(this)
      }
    ]);
  }

  public createTableServiceResponse(): TableResponseModel<ProcurementSowPaymentTermItem> {
    return new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementResult.procurementItem.item.name',
        header: 'table.column.name'
      },
      {
        field: 'deliveredTotal',
        header: 'table.column.deliverable',
        customClass: 'text-center',
        fn: this.setPercentage.bind(this)
      },
      {
        field: 'quantity',
        header: 'table.column.deliveredTotal',
        customClass: 'text-center',
        fn: this.setDeliveredTotal.bind(this)
      },
      {
        field: 'status',
        header: 'table.column.status',
        customClass: 'text-center',
        datamap:
          '{' +
          '"1" : "' +
          this.global.translateService.instant(
            'delivery-monitoring.status.new'
          ) +
          '"' +
          ', "2" : "' +
          this.global.translateService.instant(
            'delivery-monitoring.status.onProgress'
          ) +
          '"' +
          ', "3" : "' +
          this.global.translateService.instant(
            'delivery-monitoring.status.done'
          ) +
          '"' +
          '}',
        fn: this.handleStatusTextColor.bind(this)
      }
    ]);
  }

  public setPercentage(total: number): string {
    if (total === null || (total && total === 0)) {
      return '0.00 %';
    } else {
      return total.toString() + ' %';
    }
  }

  public setDeliveredTotal(total: number): string {
    if (total === null || (total && total === 0)) {
      return '0.00 %';
    } else {
      return total.toString() + '.00 %';
    }
  }

  public handleStatusTextColor(value: string, record: any): HTMLElement {
    this.log.debug(value);
    const spanElement = document.createElement('span');
    if (record.status === 1) {
      spanElement.style.color = 'var(--body-foreground)';
      spanElement.textContent = this.global.translateService.instant(
        'delivery-monitoring.new'
      );
    } else if (record.status === 2) {
      spanElement.style.color = 'var(--primary)';
      spanElement.textContent = this.global.translateService.instant(
        'delivery-monitoring.onProgress'
      );
    } else if (record.status === 3) {
      spanElement.style.color = 'var(--success)';
      spanElement.textContent = this.global.translateService.instant(
        'delivery-monitoring.done'
      );
    }
    return spanElement;
  }

  public doEdit(
    data,
    procurementSow,
    procurementSowPaymentTerm,
    isActiveStage,
    isActiveTerm
  ): void {
    if (isActiveStage && isActiveTerm) {
      const todo = 'edit';
      const header = this.translateService.instant(
        'delivery-monitoring.popup.title.deliveryProcess'
      );
      const procurementScopeWork = this.procurementScopeWork;
      const procurementResultList =
        this.deliveryMonitoringResponse.procurementResultList;
      let procurementSowPaymentTermItem = null;
      let procurementResult = null;
      if (data.procurementResult != null) {
        procurementResult = data.procurementResult;
        procurementSowPaymentTermItem = data;
      } else {
        procurementResult = data;
      }
      this.appPopupService
        .open(
          AppPopupDeliveryMonitoringComponent,
          {
            todo,
            header,
            procurementResult,
            procurementScopeWork,
            procurementSowPaymentTermItem,
            procurementResultList,
            procurementSow,
            procurementSowPaymentTerm
          },
          { size: 'xl' }
        )
        .subscribe(response => {
          if (response) {
            this.getDataFromServer();
            this.tableItemResponse.reload();
            this.tableServiceResponse.reload();
          }
        });
    } else {
      this.global.alertService.showInfo(
        this.translateService.instant(
          'delivery-monitoring.previousDeliveryProcessCompletedInfo'
        )
      );
    }
  }

  public doDetail(data): void {
    const todo = 'view';
    const header = this.translateService.instant(
      'delivery-monitoring.popup.title.deliveryProcess'
    );
    const procurementScopeWork = this.procurementScopeWork;
    let procurementSowPaymentTermItem = null;
    let procurementResult = null;
    if (data.procurementResult != null) {
      procurementResult = data.procurementResult;
      procurementSowPaymentTermItem = data;
    } else {
      procurementResult = data;
    }
    this.appPopupService
      .open(
        AppPopupDeliveryMonitoringComponent,
        {
          todo,
          header,
          procurementResult,
          procurementScopeWork,
          procurementSowPaymentTermItem
        },
        { size: 'xl' }
      )
      .subscribe(procurementItemList => {
        if (procurementItemList) {
          this.deliveryMonitoringResponse.procurementResultList =
            procurementItemList;
        }
      });
  }

  public doBack(): void {
    this.router.navigate(['/pages/delivery-monitoring']);
  }

  public doRequest(procurementSowPaymentTerm, procurementSow): void {
    if (
      !procurementSowPaymentTerm.isFinishAssessed &&
      procurementSow.isAssessment
    ) {
      this.global.modalService.message(
        this.translateService.instant('delivery-monitoring.requestValidation')
      );
    } else {
      const promp = 'delivery-monitoring.confirm.requestPayment.msg';
      const title = 'delivery-monitoring.confirm.requestPayment.title';
      this.global.modalService
        .confirm(promp, title)
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.httpClientService
              .get<PaymentRequestBillingView>(
                '/delivery-monitoring/get-payment-request-billing/' +
                  procurementSowPaymentTerm.id
              )
              .subscribe(paymentRequestBillingView => {
                this.global.routerParams.clear();
                this.global.routerParams.set('todo', 'add');
                this.global.routerParams.set(
                  'procurementSowPaymentTermId',
                  procurementSowPaymentTerm.id
                );
                this.global.routerParams.set(
                  'procurementVendorId',
                  this.procurementScopeWork.procurementVendor.id
                );
                this.global.routerParams.set(
                  'paymentRequestBillingView',
                  paymentRequestBillingView
                );
                this.global.routerParams.set(
                  'urlBackOutside',
                  '/pages/vendor-payment-request'
                );
                this.router.navigate(['/pages/vendor-payment-request/add']);
              });
          }
        });
    }
  }

  public doPrint(procurementSowPaymentTerm: ProcurementSowPaymentTerm): void {
    this.loadingBlockService.showInfo(
      this.translateService.instant('delivery-monitoring.info.preparingData')
    );
    this.reportService
      .print('/delivery-monitoring/print', procurementSowPaymentTerm)
      .subscribe(() => {
        this.loadingBlockService.showCompleteAndClose(
          this.translateService.instant(
            'delivery-monitoring.info.successImported'
          )
        );
      });
  }

  public doProcurementFulfillment(
    procurementSowPaymentTerm: ProcurementSowPaymentTerm
  ): void {
    this.global.modalService
      .confirm(
        this.translateService.instant(
          'delivery-monitoring.msg.confirmationPerformance'
        ),
        this.translateService.instant(
          'delivery-monitoring.msg.confirmationPerformanceTitle'
        )
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.global.routerParams.clear();
          this.global.routerParams.set('todo', 'edit');
          this.global.routerParams.set(
            'vendorId',
            this.procurementScopeWork.procurementVendor.vendor.id
          );
          this.global.routerParams.set(
            'procurementSowPaymentTermId',
            procurementSowPaymentTerm.id
          );
          this.global.routerParams.set(
            'procurementScopeWorkId',
            this.procurementScopeWork.id
          );
          this.router.navigate(['/pages/performance-fulfillment/detail/edit']);
        }
      });
  }

  public doRequestCloseContract(procurementScopeWork): void {
    const todo = 'edit';
    const header = this.translateService.instant(
      'delivery-monitoring.popup.title.requestCloseContract'
    );

    this.appPopupService.open(
      AppPopupRequestClosedContractComponent,
      {
        todo,
        header,
        procurementScopeWork
      },
      { size: 'lg' }
    );
  }

  public doRequestCloseScopeWork(procurementScopeWork): void {
    this.global.modalService
      .confirm(
        this.translateService.instant('delivery-monitoring.msg.workCompletion'),
        this.translateService.instant(
          'delivery-monitoring.msg.workCompletionTitle'
        )
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.httpClientService
            .post<Response<ProcurementScopeWork>>(
              '/delivery-monitoring/update-scope-work-status',
              procurementScopeWork.id
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.setStateReady();
                this.global.alertService.showSuccessOnNextRoute(
                  this.global.translateService.instant(
                    'delivery-monitoring.alert.msg.workCompletionSuccess'
                  )
                );
                this.router.navigate(['/pages/delivery-monitoring']);
              } else {
                this.setStateReady();
                this.global.alertService.showErrorOnNextRoute(
                  response.statusText
                );
                this.router.navigate(['/pages/delivery-monitoring']);
              }
            });
        }
      });
  }
}
