import { BaseEntity } from '../base/base-entity';
import { Committee } from '../bean/committee';
import { CommitteePosition } from '../bean/committee-position';
import { User } from '../bean/user';

export class CommitteeParticipantEntity extends BaseEntity {
  committee: Committee = new Committee();
  committeePosition: CommitteePosition = new CommitteePosition();
  user: User;
  name: string;
  email: string;
}
