<div class="row main-row app-accordion-row">
  <div class="col-12">
    <app-card
      header="procurement-offline-submission.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-accordion [isShowMultiple]="true" [isLoading]="formLoading">
        <app-accordion-item
          [isView]="!formGroup.isView"
          header="procurement-offline-submission.accordion.purchaseRequest"
          [isExpanded]="true"
        >
          <div class="text-center py-4" *ngIf="formLoading">
            <img src="assets/img/common/loader-small.gif" alt="Loading" />
          </div>
          <form class="form-horizontal" [formGroup]="formGroup" novalidate>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
              [hidden]="
                procurementOfflineResponse.isNumberGenerator &&
                (todo === 'add' ||
                  (todo === 'confirmation' &&
                    !procurementOfflineResponse.purchaseRequestData
                      .procurementStatus))
              "
            >
              <label class="col-sm-3 control-label">{{
                'procurement-offline-submission.form.purchaseRequest.number'
                  | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  autofocus="true"
                  formControlName="number"
                  maxLength="64"
                ></app-text-field>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'procurement-offline-submission.form.purchaseRequest.createdDate'
                  | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-date-picker
                  formControlName="createdDate"
                  [maxDate]="currentDate"
                >
                </app-date-picker>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroup.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'procurement-offline-submission.form.purchaseRequest.organization'
                  | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-tree
                  formControlName="organization"
                  stringUrl="/organization"
                  isLazy="true"
                  header="Organization"
                ></app-text-tree>
              </div>
            </div>
          </form>

          <app-fieldset
            legend="{{
              'procurement-offline-submission.fieldset.purchaseRequestValue'
                | translate
            }}"
          >
            <app-table
              [isHidePagination]="true"
              #selectorPurchaseRequest
              [tableResponse]="tableResponsePurchaseRequest"
            >
              <ng-template *ngIf="!formGroup.isView" #headerButtons>
                <app-button (onClick)="doAddBudget()"
                  ><em class="fas fa-plus"></em>
                  {{ 'app.button.add' | translate }}</app-button
                >
              </ng-template>
              <ng-template
                #actionButtons
                let-rowData
                let-i="index"
                *ngIf="todo === 'add'"
              >
                <app-button
                  *ngIf="!formGroup.isView"
                  size="TN"
                  (onClick)="doEditBudget(rowData)"
                  title="{{ 'app.tooltip.edit' | translate }}"
                >
                  <em class="fas fa-pencil-alt"></em>
                </app-button>
                <app-button
                  *ngIf="!formGroup.isView"
                  size="TN"
                  color="DANGER"
                  (onClick)="doDeleteBudget(rowData, i)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="fas fa-trash"></em>
                </app-button>
              </ng-template>
              <ng-template #tableFooter>
                <p
                  class="
                    m-0
                    text-right
                    border border-top-0
                    d-flex
                    align-items-center
                    justify-content-end
                  "
                  [ngStyle]="{
                    height: '3rem',
                    'padding-right': '2rem'
                  }"
                >
                  <span class="mr-2"
                    ><strong
                      >{{
                        'procurement-offline-submission.purchaseRequestData.totalBudgetValue'
                          | translate
                      }}
                      :</strong
                    ></span
                  >
                  {{
                    totalPurchaseValue
                      ? global.converterService.convertMoney(
                          totalPurchaseValue
                        ) +
                        ' ' +
                        purchaseRequestBudgetList[0].budgetAllocation.currency
                          .code
                      : '-'
                  }}
                </p>
              </ng-template>
            </app-table>
          </app-fieldset>
        </app-accordion-item>

        <!-- PROCUREMENT -->

        <app-accordion-item
          [isView]="!formGroupProcurement.isView"
          [isHidden]="
            !(
              procurementOfflineResponse.purchaseRequestData &&
              procurementOfflineResponse.purchaseRequestData
                .procurementStatus &&
              !(
                todo === 'view' &&
                procurementOfflineResponse.purchaseRequestData.procurementStatus
                  .code ===
                  global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
              )
            )
          "
          header="procurement-offline-submission.accordion.procurement"
          [isExpanded]="true"
        >
          <div class="text-center py-4" *ngIf="formLoading">
            <img src="assets/img/common/loader-small.gif" alt="Loading" />
          </div>
          <form
            class="form-horizontal"
            [formGroup]="formGroupProcurement"
            novalidate
          >
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroupProcurement.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'procurement-offline-submission.form.procurement.number'
                  | translate
              }}</label>
              <div
                class="col-sm-9 text-left"
                *ngIf="
                  procurementOfflineResponse.purchaseRequestData
                    .procurementStatus.code ===
                  global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
                "
              >
                <app-text-field
                  placeholder="XXXXX/XXX-XX"
                  autofocus="true"
                  formControlName="number"
                  maxLength="64"
                  symbolic="/M/{{ currentYear }}"
                ></app-text-field>
              </div>
              <div
                class="col-sm-9 text-left"
                *ngIf="
                  procurementOfflineResponse.purchaseRequestData
                    .procurementStatus.code !==
                  global.appConstant.esm.PROCUREMENT_STATUS_PURCHASE_REQUEST
                "
              >
                <app-text-field
                  placeholder="XXXXX/XXX-XX"
                  autofocus="true"
                  formControlName="number"
                  maxLength="64"
                ></app-text-field>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroupProcurement.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'procurement-offline-submission.form.procurement.name'
                  | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field formControlName="name" maxLength="64">
                </app-text-field>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroupProcurement.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'procurement-offline-submission.form.procurement.createdDate'
                  | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-date-picker
                  formControlName="createdDate"
                  [maxDate]="currentDate"
                >
                </app-date-picker>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroupProcurement.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'procurement-offline-submission.form.procurement.procurementType'
                  | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-combo-box
                  formControlName="procurementType"
                  [optionList]="procurementTypeOptionList"
                  (onChange)="onChangeProcurementType()"
                ></app-combo-box>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroupProcurement.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'procurement-offline-submission.form.procurement.procurementMethod'
                  | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-combo-box
                  formControlName="procurementMethod"
                  [optionList]="procurementMethodOptionList"
                ></app-combo-box>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroupProcurement.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'procurement-offline-submission.form.procurement.vendorName'
                  | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field formControlName="vendorName" maxLength="64">
                </app-text-field>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroupProcurement.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'procurement-offline-submission.form.procurement.description'
                  | translate
              }}</label>
              <div
                class="text-left"
                [ngClass]="
                  !formGroupProcurement.isView ? 'col-sm-9' : 'col-sm-6'
                "
              >
                <app-text-area formControlName="description" maxLength="512">
                </app-text-area>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroupProcurement.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'procurement-offline-submission.form.procurement.user'
                  | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-auto-complete
                  [isDisabled]="
                    !formGroupProcurement.get('procurementType').value
                      ? true
                      : false
                  "
                  formControlName="user"
                  stringUrl="/procurement-offline-submission/auto-complete-list-of-user"
                  [params]="procurementTypeParams"
                  (onChange)="autoCompleteValueChange($event)"
                ></app-auto-complete>
              </div>
            </div>

            <div
              class="form-group row text-sm-right"
              [ngClass]="!formGroupProcurement.isView ? 'required' : ''"
            >
              <label class="col-sm-3 control-label">{{
                'procurement-offline-submission.form.procurement.uploadFile'
                  | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-upload-multiple
                  formControlName="file"
                  [fileUploader]="fileUploader"
                ></app-upload-multiple>
              </div>
            </div>
          </form>

          <app-fieldset
            legend="{{
              'procurement-offline-submission.fieldset.procurementValue'
                | translate
            }}"
          >
            <app-table
              [isHidePagination]="true"
              #selectorProcurement
              [model]="tableResponseProcurement"
              [isServerSide]="false"
            >
              <ng-template #thead let-model>
                <thead>
                  <tr>
                    <th scope="col" class="tableNo" colspan="1">{{
                      'app-table.column.no' | translate
                    }}</th>
                    <th
                      scope="col"
                      *ngFor="let column of model.columns; let i = index"
                      (click)="
                        column.sortable !== false &&
                          doSort(column.field, 'procurement')
                      "
                      [ngClass]="[
                        column.sortable !== false ? 'sort' : '',
                        model.request.sortField === column.field
                          ? 'bg-light '
                          : '',
                        model.request.sortField === column.field &&
                        model.sortOrder === 'ASC'
                          ? 'sort-asc'
                          : '',
                        model.request.sortField === column.field &&
                        model.sortOrder === 'DESC'
                          ? 'sort-desc'
                          : '',
                        column.format === 5 || column.format === 5
                          ? 'text-right'
                          : 'text-center'
                      ]"
                      [hidden]="column.isChecked === false"
                      colspan="99"
                      (keyup)="onKeyUp($event)"
                      (keydown)="onKeyDown($event)"
                    >
                      {{ model.moduleCode + '.' + column.header | translate }}
                    </th>
                    <th scope="col" class="text-right">
                      {{
                        'procurement-offline-submission.table.procurementOffline.column.budgetValue'
                          | translate
                      }}</th
                    >
                  </tr>
                </thead>
              </ng-template>
              <ng-template #tbody let-model>
                <tbody>
                  <tr *ngIf="model.page.totalRecords === 0">
                    <td colspan="9999999" class="bg-white text-center">{{
                      'app.info.noData' | translate
                    }}</td>
                  </tr>
                  <tr *ngFor="let record of model.page.records; let i = index">
                    <td class="text-center" colspan="1">{{
                      i + 1 + model.request.first
                    }}</td>
                    <td
                      *ngFor="let column of model.columns"
                      [hidden]="column.isChecked === false"
                      colspan="99"
                      #tdElement
                    >
                      {{ record | resolveColumnValue: column:model:tdElement }}
                    </td>
                    <td
                      class="text-right"
                      *ngIf="
                        procurementOfflineResponse.purchaseRequestData
                          .procurementStatus.code !==
                          global.appConstant.esm
                            .PROCUREMENT_STATUS_PURCHASE_REQUEST ||
                        (todo === 'confirmation' &&
                          procurementOfflineResponse.purchaseRequestData
                            .procurementStatus.code ===
                            global.appConstant.esm
                              .PROCUREMENT_STATUS_PURCHASE_REQUEST)
                      "
                      class="text-right"
                    >
                      {{
                        global.converterService.convertMoney(record.value) +
                          ' ' +
                          record.purchaseRequestBudget.budgetAllocation.currency
                            .code
                      }}
                    </td>
                    <td
                      *ngIf="
                        todo === 'edit' &&
                        procurementOfflineResponse.purchaseRequestData
                          .procurementStatus.code ===
                          global.appConstant.esm
                            .PROCUREMENT_STATUS_PURCHASE_REQUEST
                      "
                    >
                      <input
                        name="plannedBudget"
                        type="text"
                        class="form-control"
                        [(ngModel)]="
                          purchaseRequestBudgetModel.recordPageMap &&
                          purchaseRequestBudgetModel.recordPageMap.get(
                            record.purchaseRequestBudget.budgetAllocation.id
                          ) &&
                          purchaseRequestBudgetModel.recordPageMap.get(
                            record.purchaseRequestBudget.budgetAllocation.id
                          ).plannedBudget
                        "
                        (input)="
                          doOnInput(
                            record.purchaseRequestBudget.budgetAllocation,
                            plannedBudget,
                            i
                          )
                        "
                        #plannedBudget="ngModel"
                        [ngClass]="
                          errorMapper.get(
                            record.purchaseRequestBudget.budgetAllocation.id
                          )
                            ? 'is-invalid'
                            : ''
                        "
                        [disabled]="
                          !purchaseRequestBudgetModel.recordPageMap.get(
                            record.purchaseRequestBudget.budgetAllocation.id
                          )?.isChecked
                        "
                        (focusout)="handleFocusOut($event)"
                        (focusin)="handleFocusIn($event)"
                        [ngStyle]="{ width: '-webkit-fill-available' }"
                        aria-label="Booked Budget"
                      />
                      <small
                        *ngIf="
                          errorMapper.get(
                            record.purchaseRequestBudget.budgetAllocation.id
                          )
                        "
                        class="text-danger"
                      >
                        {{
                          errorMapper.get(
                            record.purchaseRequestBudget.budgetAllocation.id
                          )
                        }}
                      </small>
                    </td>
                  </tr>
                </tbody>
              </ng-template>
              <ng-template #tableFooter>
                <p
                  class="
                    m-0
                    text-right
                    border border-top-0
                    d-flex
                    align-items-center
                    justify-content-end
                  "
                  [ngStyle]="{
                    height: '3rem',
                    'padding-right': '2rem'
                  }"
                >
                  <span class="mr-2"
                    ><strong
                      >{{
                        'procurement-offline-submission.procurementOffline.totalBudgetValue'
                          | translate
                      }}
                      :</strong
                    ></span
                  >
                  {{
                    totalProcurementValue
                      ? global.converterService.convertMoney(
                          totalProcurementValue
                        ) +
                        ' ' +
                        procurementOfflineBudgetList[0].purchaseRequestBudget
                          .budgetAllocation.currency.code
                      : '-'
                  }}
                </p>
              </ng-template>
            </app-table>
          </app-fieldset>
        </app-accordion-item>

        <!-- PAYMENT -->

        <!-- isViewCondition: Check it later for isView condition. Because angular reject this condition and say return always false
         [isView]="
            todo !== 'view' ||
            (todo === 'edit' &&
              (procurementOfflineResponse.purchaseRequestData.procurementStatus
                .cod
                e ===
                global.appConstant.esm.PROCUREMENT_STATUS_PROCUREMENT_PROCESS ||
                procurementOfflineResponse.purchaseRequestData.procurementStatus
                  .code ===
                  global.appConstant.esm.PROCUREMENT_STATUS_PAYMENT_PROCESS))
          " -->
        <app-accordion-item
          [isHidden]="
            !(
              procurementOfflineResponse.purchaseRequestData &&
              procurementOfflineResponse.purchaseRequestData
                .procurementStatus &&
              ((procurementOfflineResponse.purchaseRequestData.procurementStatus
                .code ===
                global.appConstant.esm.PROCUREMENT_STATUS_PROCUREMENT_PROCESS &&
                todo !== 'view') ||
                procurementOfflineResponse.purchaseRequestData.procurementStatus
                  .code ===
                  global.appConstant.esm.PROCUREMENT_STATUS_PAYMENT_PROCESS ||
                procurementOfflineResponse.purchaseRequestData.procurementStatus
                  .code === global.appConstant.esm.PROCUREMENT_STATUS_CLOSED)
            )
          "
          header="procurement-offline-submission.accordion.payment"
          [isExpanded]="true"
        >
          <app-table
            [isHidePagination]="true"
            #selectorPayment
            [model]="tableResponsePayment"
            [isServerSide]="false"
          >
            <ng-template #thead let-model>
              <thead>
                <tr>
                  <th scope="col" class="tableNo" colspan="1">{{
                    'app-table.column.no' | translate
                  }}</th>
                  <th
                    scope="col"
                    *ngFor="let column of model.columns; let i = index"
                    (click)="
                      column.sortable !== false &&
                        doSort(column.field, 'payment')
                    "
                    [ngClass]="[
                      column.sortable !== false ? 'sort' : '',
                      model.request.sortField === column.field
                        ? 'bg-light '
                        : '',
                      model.request.sortField === column.field &&
                      model.sortOrder === 'ASC'
                        ? 'sort-asc'
                        : '',
                      model.request.sortField === column.field &&
                      model.sortOrder === 'DESC'
                        ? 'sort-desc'
                        : '',
                      column.format === 5 || column.format === 5
                        ? 'text-right'
                        : 'text-center'
                    ]"
                    [hidden]="column.isChecked === false"
                    colspan="99"
                    (keyup)="onKeyUp($event)"
                    (keydown)="onKeyDown($event)"
                  >
                    {{ model.moduleCode + '.' + column.header | translate }}
                  </th>
                  <th scope="col" class="text-right">
                    {{
                      'procurement-offline-submission.table.payment.column.budgetValue'
                        | translate
                    }}</th
                  >
                </tr>
              </thead>
            </ng-template>
            <ng-template #tbody let-model>
              <tbody>
                <tr *ngIf="model.page.totalRecords === 0">
                  <td colspan="9999999" class="bg-white text-center">{{
                    'app.info.noData' | translate
                  }}</td>
                </tr>
                <tr *ngFor="let record of model.page.records; let i = index">
                  <td class="text-center" colspan="1">{{
                    i + 1 + model.request.first
                  }}</td>
                  <td
                    *ngFor="let column of model.columns"
                    [hidden]="column.isChecked === false"
                    colspan="99"
                    #tdElement
                  >
                    {{ record | resolveColumnValue: column:model:tdElement }}
                  </td>
                  <td
                    class="text-right"
                    *ngIf="
                      todo === 'view' ||
                      todo === 'confirmation' ||
                      remainingPayment.get(
                        record.procurementOfflineBudget.purchaseRequestBudget
                          .budgetAllocation.id
                      ) === 0
                    "
                  >
                    {{
                      global.converterService.convertMoney(record.value) +
                        ' ' +
                        record.procurementOfflineBudget.purchaseRequestBudget
                          .budgetAllocation.currency.code
                    }}
                  </td>
                  <td
                    *ngIf="
                      todo === 'edit' &&
                      remainingPayment.get(
                        record.procurementOfflineBudget.purchaseRequestBudget
                          .budgetAllocation.id
                      ) !== 0
                    "
                  >
                    <input
                      name="plannedBudget"
                      type="text"
                      class="form-control"
                      [(ngModel)]="
                        purchaseRequestBudgetModel.recordPageMap &&
                        purchaseRequestBudgetModel.recordPageMap.get(
                          record.procurementOfflineBudget.purchaseRequestBudget
                            .budgetAllocation.id
                        ) &&
                        purchaseRequestBudgetModel.recordPageMap.get(
                          record.procurementOfflineBudget.purchaseRequestBudget
                            .budgetAllocation.id
                        ).plannedBudget
                      "
                      (input)="
                        doOnInput(
                          record.procurementOfflineBudget.purchaseRequestBudget
                            .budgetAllocation,
                          plannedBudget,
                          i
                        )
                      "
                      #plannedBudget="ngModel"
                      [ngClass]="
                        errorMapper.get(
                          record.procurementOfflineBudget.purchaseRequestBudget
                            .budgetAllocation.id
                        )
                          ? 'is-invalid'
                          : ''
                      "
                      [disabled]="
                        !purchaseRequestBudgetModel.recordPageMap.get(
                          record.procurementOfflineBudget.purchaseRequestBudget
                            .budgetAllocation.id
                        )?.isChecked
                      "
                      (focusout)="handleFocusOut($event)"
                      (focusin)="handleFocusIn($event)"
                      [ngStyle]="{ width: '-webkit-fill-available' }"
                      aria-label="Booked Budget"
                    />
                    <small
                      *ngIf="
                        errorMapper.get(
                          record.procurementOfflineBudget.purchaseRequestBudget
                            .budgetAllocation.id
                        )
                      "
                      class="text-danger"
                    >
                      {{
                        errorMapper.get(
                          record.procurementOfflineBudget.purchaseRequestBudget
                            .budgetAllocation.id
                        )
                      }}
                    </small>
                  </td>
                </tr>
              </tbody>
            </ng-template>
            <ng-template #tableFooter>
              <p
                class="
                  m-0
                  text-right
                  border border-top-0
                  d-flex
                  align-items-center
                  justify-content-end
                "
                [ngStyle]="{
                  height: '3rem',
                  'padding-right': '2rem'
                }"
              >
                <span class="mr-2"
                  ><strong
                    >{{
                      'procurement-offline-submission.procurementOffline.totalBudgetValue'
                        | translate
                    }}
                    :</strong
                  ></span
                >
                {{
                  totalPaymentValue
                    ? global.converterService.convertMoney(totalPaymentValue) +
                      ' ' +
                      procurementOfflinePaymentList[0].procurementOfflineBudget
                        .purchaseRequestBudget.budgetAllocation.currency.code
                    : '-'
                }}
              </p>
            </ng-template>
          </app-table>
        </app-accordion-item>

        <!-- PAYMENT-HISTORY -->

        <app-accordion-item
          [isHidden]="
            !(
              todo !== 'add' &&
              procurementOfflineResponse.purchaseRequestData &&
              procurementOfflineResponse.purchaseRequestData
                .procurementStatus &&
              (procurementOfflineResponse.purchaseRequestData.procurementStatus
                .code ===
                global.appConstant.esm.PROCUREMENT_STATUS_PAYMENT_PROCESS ||
                procurementOfflineResponse.purchaseRequestData.procurementStatus
                  .code === global.appConstant.esm.PROCUREMENT_STATUS_CLOSED)
            )
          "
          header="procurement-offline-submission.accordion.paymentHistory"
          [isExpanded]="true"
        >
          <div class="text-center py-4" *ngIf="formLoading">
            <img src="assets/img/common/loader-small.gif" alt="Loading" />
          </div>
          <app-table
            #selectorPaymentHistory
            [tableResponse]="tableResponsePaymentHistory"
          >
          </app-table>
        </app-accordion-item>
      </app-accordion>
      <div class="form-group row text-danger" *ngIf="todo === 'confirmation'">
        <div class="col-sm-1"></div>
        <p class="col-sm-9 text-left"
          >*)
          {{ 'procurement-offline-submission.note.recheckData' | translate }}</p
        >
      </div>
      <div class="button-group button-group-center mt-5">
        <app-button
          *ngIf="todo === 'confirmation'"
          color="SECONDARY"
          (onClick)="doBack()"
          [disabled]="formSaving"
          >{{ 'app.button.back' | translate }}
        </app-button>
        <app-button
          *ngIf="todo === 'confirmation'"
          (onClick)="doSubmit()"
          [disabled]="formSaving"
          >{{ 'app.button.submit' | translate }}
        </app-button>
        <app-button
          *ngIf="todo !== 'confirmation'"
          color="SECONDARY"
          (onClick)="doCancel()"
          [disabled]="formSaving"
          >{{ 'app.button.back' | translate }}
        </app-button>
        <app-button
          *ngIf="todo !== 'confirmation' && todo !== 'view' && showButton"
          (onClick)="doSave('saveAndContinue')"
          [disabled]="formSaving"
          >{{
            'procurement-offline-submission.button.saveAndContinue' | translate
          }}
        </app-button>
        <app-button
          *ngIf="todo !== 'confirmation' && todo !== 'view'"
          (onClick)="doSave('save')"
          [disabled]="formSaving"
          >{{ 'app.button.save' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>
