<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-card
          header="executor.verificator.title"
          [isLoading]="formLoading"
          [isSaving]="formSaving"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label required">
              {{ 'executor.verificator.code' | translate }}
            </label>
            <label class="col-sm-4 text-left">
              {{ verificator.code }}
            </label>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3 control-label required">
              {{ 'executor.verificator.name' | translate }}
            </label>
            <label class="col-sm-4 text-left">
              {{ verificator.name }}
            </label>
          </div>
        </app-card>
        <app-card
          header="executor.user-executor.title"
          [isLoading]="formLoading"
          [isSaving]="formSaving"
        >
          <small class="text-danger" *ngIf="isNoUserExecutor">
            {{ 'app.validation.required' | translate }}
          </small>
          <app-table
            *ngIf="!formLoading"
            [model]="tableResponseUserExecutor"
            [isServerSide]="false"
          >
            <ng-template #headerButtons>
              <app-button
                (onClick)="doAddUserExecutor()"
                title="{{ 'app.button.add' | translate }}"
              >
                <em class="fas fa-plus"></em>{{ 'app.button.add' | translate }}
              </app-button>
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <app-button
                size="TN"
                color="DANGER"
                (onClick)="doDeleteUserExecutor(rowData)"
                title="{{ 'app.button.delete' | translate }}"
              >
                <em class="fas fa-trash"></em>
              </app-button>
            </ng-template>
          </app-table>
        </app-card>
        <app-card
          header="executor.executor-assignment.title"
          [isLoading]="formLoading"
          [isSaving]="formSaving"
        >
          <small class="text-danger" *ngIf="isNoExecutorAssignment">
            {{ 'app.validation.required' | translate }}
          </small>
          <app-table
            *ngIf="!formLoading"
            [model]="tableResponseExecutorAssignment"
            [isServerSide]="false"
          >
            <ng-template #headerButtons>
              <app-button
                (onClick)="doAddExecutorAssignment()"
                title="{{ 'app.button.add' | translate }}"
              >
                <em class="fas fa-plus"></em>{{ 'app.button.add' | translate }}
              </app-button>
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <app-button
                size="TN"
                color="DANGER"
                (onClick)="doDeleteExecutorAssignment(rowData)"
                title="{{ 'app.button.delete' | translate }}"
              >
                <em class="fas fa-trash"></em>
              </app-button>
            </ng-template>
          </app-table>
        </app-card>
      </form>
      <div class="row justify-content-center mt-5">
        <app-button
          color="SECONDARY"
          class="mr-1"
          (onClick)="doCancel()"
          [disabled]="formSaving"
          mode="BYPASS"
        >
          {{ 'app.button.back' | translate }}
        </app-button>
        <app-button
          color="PRIMARY"
          (onClick)="doSave()"
          [disabled]="formSaving"
        >
          {{ 'app.button.save' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</app-layout>
