import { BaseEntity } from '../base/base-entity';
import { Resource } from '../bean/resource';

export class LangEntity extends BaseEntity {
    name: string;
    isReadOnly: boolean;
    isActive: boolean;
    isDefault: boolean;
    resource: Resource;
}
