import { BaseEntity } from '../base/base-entity';
import { Catalog } from '../bean/catalog';
import { CatalogContractStatus } from '../bean/catalog-contract-status';
import { Contract } from '../bean/contract';

export class CatalogContractEntity extends BaseEntity {
  catalog: Catalog;
  contract: Contract;
  catalogContractStatus: CatalogContractStatus;

  isBulkPrice: boolean;
  itemPrice: number;
  quantity: number;
  remainingQuantity: number;
  contractedQuantity: string;
  price: number;
  isVerified: boolean;
}
