<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="performance-on-registration.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [tableResponse]="tableResponse"
        [isServerSide]="true"
        stringUrl="/performance-on-registration/index"
      >
        <ng-template #headerButtons>
          <app-button
            mode="BYPASS"
            (onClick)="doExport()"
            [disabled]="isLoading"
            ><em
              class="fas"
              [ngClass]="
                !isLoading ? 'fa-cloud-download-alt' : 'fa-circle-notch fa-spin'
              "
            ></em
            >{{ 'app.button.export' | translate }}</app-button
          >
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <ng-container
            *ngIf="
              rowData[1] === null ||
                (rowData[1] &&
                  rowData[1].workflowPrcs &&
                  rowData[1].workflowPrcs.workflowStatus.code.includes(
                    'REVISI'
                  ));
              then editButton;
              else detailButton
            "
          ></ng-container>
          <ng-template #editButton>
            <app-button
              size="TN"
              (onClick)="doEdit(rowData[0])"
              title="{{ 'app.tooltip.edit' | translate }}"
            >
              <span class="fas fa-pencil-alt"></span>
            </app-button>
          </ng-template>
          <ng-template #detailButton>
            <app-button
              mode="BYPASS"
              size="TN"
              (onClick)="doDetail(rowData[0])"
              title="{{ 'app.tooltip.view' | translate }}"
            >
              <span class="fas fa-search"></span>
            </app-button>
          </ng-template>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
