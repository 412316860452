import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppTableModule } from '../table/components/app-table/app-table.module';
import { AppVendorInformationComponent } from './app-vendor-information.component';

@NgModule({
  imports: [VendorModule, AppCardModule, AppTableModule],
  declarations: [AppVendorInformationComponent],
  exports: [AppVendorInformationComponent]
})
export class AppVendorInformationModule {}
