import { NgModule } from '@angular/core';
import { AppButtonModule } from '../../../../../core/components/app-button/app-button.module';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetProgressCompleteProfileComponent } from './widget-progress-complete-profile.component';

@NgModule({
  imports: [VendorModule, WidgetCardModule, AppButtonModule],
  declarations: [WidgetProgressCompleteProfileComponent],
  exports: [WidgetProgressCompleteProfileComponent]
})
export class WidgetProgressCompleteProfileModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetProgressCompleteProfileComponent.moduleCode,
      WidgetProgressCompleteProfileComponent,
      'w-100'
    );
  }
}
