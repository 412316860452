import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAlertIconComponent } from './app-alert-icon.component';

@NgModule({
  imports: [VendorModule],
  declarations: [AppAlertIconComponent],
  entryComponents: [AppAlertIconComponent],
  exports: [AppAlertIconComponent]
})
export class AppAlertIconModule {}
