import { Component } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { VendorCertificate } from '../../bean/vendor-certificate';
import { ReportService } from '../../services/report.service';
import { WidgetLoginInformationVendorResponse } from './widget-login-information-vendor.response';
@Component({
  templateUrl: './app-widget-login-information-vendor.component.html',
  styleUrls: ['./app-widget-login-information-vendor.component.scss']
})
export class AppWidgetLoginInformationVendorComponent extends BaseComponentComponent {
  public loginInformationVendorResponse: WidgetLoginInformationVendorResponse;
  public currentDate: Date = new Date();
  public releaseDate: any;
  public expiredDate: any;
  public certificateNumber: any;
  public isLoading: boolean;
  // public isVendorReject = false;

  constructor(public reportService: ReportService) {
    super('app-widget-login-information-vendor');
  }

  public onInit(): void {
    this.getAndSetVendor();
  }

  public getAndSetVendor(): void {
    this.httpClientService.get('/widget-login-information-vendor/index').subscribe((response: any) => {
      this.loginInformationVendorResponse = response;
      if (response != null) {
        this.loginInformationVendorResponse.lastLogin = new Date(response.lastLogin);
        if (this.loginInformationVendorResponse.vendorCertificate != null) {
          this.releaseDate = new Date(response.vendorCertificate.releaseDate);
          this.expiredDate = new Date(response.vendorCertificate.expiredDate);
          this.certificateNumber = response.vendorCertificate.certificateNumber;

        } else {
          this.certificateNumber = '-';
        }

      }
      this.global.widgetLoadingStatus.set(this.moduleCode, true);
      this.setStateReady();
    });
  }

  public doPrintCertificate(vendorCertificate: VendorCertificate): void {
    this.isLoading = true;
    this.reportService.print('/widget-login-information-vendor/print/certificate', vendorCertificate).subscribe(() => {
      this.isLoading = false;
    });
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
