import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from './base.component';
@Component({
  template: ''
})
export abstract class BaseModuleComponent extends BaseComponent {
  public todo: string = null;
  public translateService: TranslateService;
  public abstract onInit(): void;
  constructor(
    @Inject(String) moduleCode: string,
    translateService: TranslateService
  ) {
    super(moduleCode);
    this.translateService = translateService;
  }

  protected onBaseInit(): void {
    this.log.debug('[ Load Base Module Component ] : ' + this.moduleCode);
    this.translateService.use(this.global.getCurrentLang());
    this.setStateLoading();
    this.onInit();
  }
}
