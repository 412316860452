import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '.optional',
  templateUrl: './app-label-optional.component.html'
})
export class AppLabelOptionalComponent extends BaseComponentComponent {
  @Input() isLoading: boolean;

  @ContentChild('contentHeader') contentHeaderTemplate: TemplateRef<any>;

  public layoutTitle: string;
  public isShowBackButton: boolean;
  constructor() {
    super('app-layout');
  }

  onInit(): void {
    this.setStateReady();
  }
}
