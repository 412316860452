import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppChooseModule } from '../../app-choose/app-choose.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupOfficerComponent } from './app-popup-officer.component';
import { AppPopupOfficerService } from './app-popup-officer.service';
@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    AppTableModule,
    AppChooseModule,
    AppButtonModule
  ],
  declarations: [AppPopupOfficerComponent],
  entryComponents: [AppPopupOfficerComponent],
  providers: [AppPopupOfficerService],
  exports: [AppPopupOfficerComponent]
})
export class AppPopupOfficerModule {}
