import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ComplianceTestGroupEditAddComponent } from './compliance-test-group-edit-add.component';
import { ComplianceTestGroupComponent } from './compliance-test-group.component';
export const routes = [
  {
    path: '',
    component: ComplianceTestGroupComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'add',
    component: ComplianceTestGroupEditAddComponent,
    data: { breadcrumb: 'compliance-test-group.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: ComplianceTestGroupEditAddComponent,
    data: { breadcrumb: 'compliance-test-group.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [
    ComplianceTestGroupComponent,
    ComplianceTestGroupEditAddComponent
  ],
  providers: []
})
export class ComplianceTestGroupModule {}
