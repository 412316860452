import { AppSlaInfoResponse } from './app-sla-info.response';
import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';

@Component({
    selector: 'app-sla-info',
    templateUrl: './app-sla-info.component.html'
})
export class AppSlaInfoComponent extends BaseComponentComponent {
    @Input() public workflowPrcsId: number;
    @Input() public historyType: string;
    public object: String;
    public date: Date;
    public name: String;
    public totalSla: String;
    public totalRealization: String;

    constructor() {
        super('app-sla-info');
    }

    public onInit(): void {
        this.getAndsetProcurementInfoData();
    }

    public getAndsetProcurementInfoData(): void {
        this.httpClientService
            .get<AppSlaInfoResponse>(
                '/app-sla-info/index/' + this.workflowPrcsId + '/' + this.historyType
            )
            .subscribe((slaInfoResponse: AppSlaInfoResponse) => {
                this.object = slaInfoResponse.object;
                this.date = slaInfoResponse.date;
                this.name = slaInfoResponse.name;
                this.totalSla = slaInfoResponse.totalSla;
                this.totalRealization = slaInfoResponse.totalRealization;
                this.setStateReady();
            });
    }
}
