import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppContentSliderItemComponent } from './app-content-slider-item.component';
import { AppContentSliderComponent } from './app-content-slider.component';
@NgModule({
  imports: [VendorModule, AppButtonModule],
  declarations: [AppContentSliderComponent, AppContentSliderItemComponent],
  exports: [AppContentSliderComponent, AppContentSliderItemComponent]
})
export class AppContentSliderModule {}
