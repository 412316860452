import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-button-compare',
  templateUrl: './app-button-compare.component.html',
  styleUrls: ['./app-button-compare.component.scss']
})
export class AppButtonCompareComponent {
  @Input() totalCompare: number;
  @Input() tooltip: string;
}
