import { BaseEntity } from '../base/base-entity';
import { Contract } from '../bean/contract';
import { User } from '../bean/user';

export class ReviewContractEntity extends BaseEntity {
  status: number;
  note: string;
  user: User;
  file: File;
  date: Date;
  contract: Contract;
}
