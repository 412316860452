<app-popup [isLoading]="formLoading">
  <div class="text-center mb-3">
    <br />
    <div
      class="pvc pv-stopwatch"
      style="width: 100px; height: 100px; margin: 0 auto"
    ></div>
    <br />
    <h3>{{ 'price-evaluation.confirmation.auction.title' | translate }}</h3
    ><br />
    <div>{{ 'price-evaluation.confirmation.auction.prompt' | translate }}</div>
  </div>
  <form
    [formGroup]="formGroup"
    *ngIf="
      request.procurement.biddingSubmissionType.code ===
      global.appConstant.pm.BIDDING_SUBMISSION_TYPE_TOTAL_PRICE
    "
  >
    <div class="form-group row d-flex justify-content-center">
      <div class="col-sm-8">
        <app-dropdown-select
          size="XL"
          formControlName="biddingSubmissionType"
          type="COMBOBOX"
          [optionList]="biddingSubmissionTypeOptionList"
          container="body"
          placeholder="price-evaluation.form.placeholder.biddingSubmissionType"
        ></app-dropdown-select>
      </div>
    </div>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-center">
      <app-button
        [outline]="true"
        (onClick)="activeModal.close(true)"
        [disabled]="formSaving"
        >{{ 'app.button.cancel' | translate }}</app-button
      >
      <app-button
        (onClick)="doCreateAuction()"
        [disabled]="
          formSaving ||
          (request.procurement.biddingSubmissionType.code ===
            global.appConstant.pm.BIDDING_SUBMISSION_TYPE_TOTAL_PRICE &&
            !formGroup.value.biddingSubmissionType)
        "
        >{{ 'price-evaluation.button.yesImSure' | translate }}</app-button
      >
    </div>
  </ng-template>
</app-popup>
