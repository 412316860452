<app-layout
  [isLoading]="formLoading"
  [backTo]="urlBackOutside"
  header="vendor-invitation.breadcrumb.index"
>
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge-catalog
      class="cml-2"
      *ngIf="response.procurement.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge
      class="cml-2"
      [color]="badgeColor[response.procurement.procurementWorklistStatus.code]"
    >
      {{
        getTranslateKey(response.procurement.procurementWorklistStatus)
          | translate
      }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12">
      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          response.procurement?.procurementStatus?.code ===
            global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
        "
      >
        {{
          'vendor-invitation.alert.msg.sorryWaitingCancelationApproval'
            | translate
        }}
      </app-alert-x>
      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          [
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
            this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
          ].includes(this.response.procurement?.procurementStatus?.code)
        "
      >
        {{
          'vendor-invitation.alert.msg.sorryWaitingFailedApproval' | translate
        }}
      </app-alert-x>
      <form
        *ngIf="!formLoading"
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
      >
        <app-card
          header="vendor-invitation.procurementDetails.title"
          tips="vendor-invitation.procurementDetails.tips"
        >
          <app-procurement-info [procurementId]="procurementId">
          </app-procurement-info>
        </app-card>

        <app-item-request
          *ngIf="!formLoading"
          [objectList]="response.procurementItemList"
          [isView]="true"
          [itemTypeList]="response.itemTypeList"
          [isShowPrice]="true"
        ></app-item-request>

        <app-card
          header="vendor-invitation.detail.procurementVendor.title"
          tips="vendor-invitation.detail.procurementVendor.tips"
        >
          <app-table-xx
            [model]="tableResponseSeg"
            header="{{
              'vendor-invitation.table.vendorSegmentation' | translate
            }}"
          ></app-table-xx>

          <br />
          <br />
          <br />

          <app-table-xx
            [model]="tableResponseVendor"
            (onClick)="onClickVendor($event)"
            header="{{ 'vendor-invitation.table.vendorResult' | translate }}"
            [isShowCheckBox]="!formGroup.isView"
          >
            <ng-template
              #alertWrapper
              *ngIf="
                (tableResponseVendor.getRecordList().length === 0 &&
                  !formGroup.isView) ||
                isWarningAlert()
              "
            >
              <app-fullfilled
                *ngIf="
                  tableResponseVendor.getRecordList().length === 0 &&
                  !formGroup.isView
                "
              ></app-fullfilled>
              <app-alert-x color="WARNING" *ngIf="isWarningAlert()">
                {{
                  'vendor-invitation.alert.msg.warning.youHaveThingsToConsider'
                    | translate
                }}
              </app-alert-x>
            </ng-template>
            <ng-container *ngIf="!formGroup.isView">
              <ng-template #headerButtons>
                <app-button (onClick)="doAddVendor()">
                  <em class="pir pi-plus-circle"></em>
                  {{ 'vendor-invitation.detail.button.addVendor' | translate }}
                </app-button>
              </ng-template>

              <ng-template #actionButtons let-rowData>
                <app-button
                  size="TN"
                  (onClick)="doDeleteVendor(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="pir pi-trash-alt"></em>
                  {{ 'app.button.delete' | translate }}
                </app-button>
              </ng-template>
            </ng-container>
          </app-table-xx>
        </app-card>

        <app-card
          *ngIf="!formLoading"
          header="vendor-invitation.detail.officialReport.title"
          tips="vendor-invitation.detail.officialReport.tips"
        >
          <app-alert-x
            color="WARNING"
            *ngIf="
              todo !== 'view' &&
              !isUpdated &&
              tableResponseVendor.getRecordList().length === 0
            "
            >{{
              'vendor-invitation.alert.msg.addVendorAndSaveDraftFirst'
                | translate
            }}</app-alert-x
          >
          <app-alert-x
            color="WARNING"
            *ngIf="
              todo !== 'view' &&
              !isUpdated &&
              tableResponseVendor.getRecordList().length > 0
            "
            >{{
              'vendor-invitation.alert.msg.saveDraftFirst' | translate
            }}</app-alert-x
          >
          <app-official-report
            [objectId]="procurementId"
            [templateCode]="
              global.appConstant.pm
                .OFFICIAL_REPORT_CODE_ORT_VENDOR_INVITATION_LETTER
            "
            [appOfficialReportModel]="appOfficialReportModel"
            [mode]="todo"
            (onChange)="doOnChange($event)"
            [isShowButtonGenerate]="todo !== 'view'"
          ></app-official-report>
        </app-card>

        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            response.approvalPathResponseModel &&
            response.procurement.procurementWorklistStatus.code !==
              global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_NEW &&
            response.procurement.procurementWorklistStatus.code !==
              global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT &&
            !approvalModelPrcsId
          "
          [workflowPrcs]="response.procurement.workflowPrcs"
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="response?.approvalPathResponseModel"
        ></app-approval-monitoring>
        <!--status selain new/draft-->

        <div
          class="button-group button-group-center"
          *ngIf="
            !formGroup.isView &&
            !formLoading &&
            response.procurement.workflowModelPrcs.code.includes(
              'VENDOR-INVITATION'
            )
          "
        >
          <app-button
            (onClick)="doSaveDraft()"
            color="LIGHT"
            [outline]="true"
            [disabled]="tableResponseVendor.getRecordList().length === 0"
          >
            {{ 'app.button.save' | translate }}</app-button
          >
          <app-button
            (onClick)="doSubmit()"
            [disabled]="this.tableResponseVendor.getRecordList().length === 0"
          >
            {{ 'app.button.submit' | translate }}</app-button
          >
        </div>

        <app-approval-prcs-x
          *ngIf="response.isUserApproval && approvalModelPrcsId"
          [workflowModelPrcs]="response.procurement.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>
      </form>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
