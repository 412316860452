<div class="app-workflow-info lmb-4">
  <app-content-slider
    [header]="
      ('app-worklist-info.header.youHave' | translate) +
      ' ' +
      totalRecords +
      ' ' +
      ('app-worklist-info.header.totalWorklistToBeDone' | translate)
    "
    [isLoading]="formLoading"
  >
    <app-content-slider-item
      *ngFor="let data of dataList; let i = index"
      (onClick)="onChange.emit(data.code === position ? null : data.code)"
      [backgroundColor]="
        data.code === position ? 'var(--gray-200)' : 'var(--gray-100)'
      "
      color="var(--primary-dark)"
      width="200px"
    >
      <div class="p-4">
        <h2 class="mb-2" maxLine="1">{{ data.totalRecords }}</h2>
        <h5 class="text-break-space" maxLine="2">{{ data.name }}</h5>
      </div>
    </app-content-slider-item>
  </app-content-slider>
</div>
