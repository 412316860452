import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { BudgetAllocation } from '../../core/bean/budget-allocation';
import { BudgetType } from '../../core/bean/budget-type';
import { Coa } from '../../core/bean/coa';
import { Currency } from '../../core/bean/currency';
import { AppPopupBudgetWorkPlanInfoService } from '../../core/components/app-popup/app-popup-budget-work-plan-info/app-popup-budget-work-plan-info.service';
import { AppPopupImportService } from '../../core/components/app-popup/app-popup-import/app-popup-import.service';
import { ImportFileWebsocketService } from '../../core/components/app-popup/app-popup-import/import-file-websocket.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { RecapPrintService } from '../../core/services/recap-print.service';
import { BudgetAllocationByCoaView } from '../../core/view/entity/bean/budget-allocation-by-coa-view';
import { BudgetAllocationByOrganizationView } from '../../core/view/entity/bean/budget-allocation-by-organization-view';
import { WebSocketWorkProgramService } from './../work-program/websocket-work-program.service';
import { AppPopupBudgetAllocationBjbComponent } from './app-popup-budget-allocation-bjb.component';
import { AppPopupExportBudgetBjbComponent } from './app-popup-export-budget-allocation-bjb.component';
import { AppPopupSyncBudgetBjbComponent } from './app-popup-sync-budget-allocation-bjb.component';
import { BudgetAllocationInfoResponse } from './response/budget-allocation-info-response';
import { WebSocketBudgetService } from './websocket-budget.service';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';

@Component({
  templateUrl: './budget-allocation-bjb.component.html',
  styleUrls: ['./budget-allocation-bjb.component.scss'],
  providers: [
    { provide: WebSocketBudgetService },
    { provide: WebSocketWorkProgramService },
    { provide: AppPopupService }
  ],
  encapsulation: ViewEncapsulation.None
})
export class BudgetAllocationBjbComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponseOrganization: TableResponseModel<BudgetAllocationByOrganizationView>;
  public tableResponseCoa: TableResponseModel<BudgetAllocationByCoaView>;
  public budgetAllocationInfoResponse: BudgetAllocationInfoResponse =
    new BudgetAllocationInfoResponse();
  public isLoading = false;
  public isLoadingReport: boolean;
  public isLoadingSync = false;
  public budgetInfoFormGroup: FormGroup;
  public currencyCode: string;
  public isAllowAccess = false;
  public budgetTypeOptionList: OptionListModel<BudgetType> =
    new OptionListModel(false);
  public isSyncRunning = false;
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );
  public coaOptionList: OptionListModel<Coa> = new OptionListModel(
    false,
    'viewPathCoa'
  );
  public budgetAllocationOptionList: OptionListModel<BudgetAllocation> =
    new OptionListModel(false, 'activityPeriod');

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupImportService: AppPopupImportService,
    public appPopupBudgetWorkPlanInfoService: AppPopupBudgetWorkPlanInfoService,
    public recapPrintService: RecapPrintService,
    public websocketBudgetService: WebSocketBudgetService,
    public websocketWorkProgramService: WebSocketWorkProgramService,
    public importFileWebsocketService: ImportFileWebsocketService
  ) {
    super('budget-allocation-bjb', translateService);
  }

  onInit(): void {
    this.initializeWebSocketWorkProgramConnection();
    this.initializeWebSocketBudgetConnection();
    this.chekedUserAccessButton();
    this.isSynchronizeRunning();
  }

  public chekedUserAccessButton(): void {
    if (
      this.global.config.parameterModel.bjbGiftEnableSyncDataOnUnit.includes(
        this.global.userSession.user.organization.code
      ) ||
      this.global.userSession.user.organization.parentId === null
    ) {
      this.isAllowAccess = true;
    }
  }

  public initializeWebSocketBudgetConnection(): void {
    this.websocketBudgetService.connect().then(result => {
      if (!this.isSyncRunning && result) {
        this.tableResponseOrganization.reload();
        this.tableResponseCoa.reload();
        this.setBudgetInfo();
      } else if (this.isSyncRunning) {
        this.isSyncRunning = false;
        this.loadTableAndFormGroup();
      }
      this.websocketBudgetService.disconnect();
      this.initializeWebSocketBudgetConnection();
      this.isLoadingSync = false;
    });
  }

  public initializeWebSocketWorkProgramConnection(): void {
    this.websocketWorkProgramService.connect().then(result => {
      if (this.isSyncRunning) {
        this.isSyncRunning = false;
        this.loadTableAndFormGroup();
      } else if (!this.isSyncRunning && result) {
        this.tableResponseOrganization.reload();
        this.tableResponseCoa.reload();
        this.setBudgetInfo();
      }
      this.websocketWorkProgramService.disconnect();
      this.initializeWebSocketWorkProgramConnection();
      this.isLoadingSync = false;
    });
  }

  public isSynchronizeRunning(): void {
    this.httpClientService
      .get<Boolean>('/budget-allocation-bjb/is-budget-synchronize-running')
      .subscribe((isSynchronizeRunning: boolean) => {
        this.isSyncRunning = isSynchronizeRunning;
        if (!this.isSyncRunning) {
          this.loadTableAndFormGroup();
        }
      });
  }
  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      coaList: [null],
      budgetTypeList: [null],
      fromPeriod: [null],
      untilPeriod: [null],
      organizationList: [null],
      currency: [null]
    });
    this.formGroup.enable();
  }

  public loadTableAndFormGroup(): void {
    this.buildFormGroup();
    this.buildFormGroupBudgetInfo();
    this.buildTableResponseOrganization();
    this.buildTableResponseCoa();
    this.setResumeBudget();
  }

  public buildFormGroupBudgetInfo(): void {
    this.budgetInfoFormGroup = this.formBuilder.group({
      totalBudgetResume: [null],
      bookedBudgetResume: [null],
      usedBudgetResume: [null],
      paidOffBudgetResume: [null],
      availableBudgetResume: [null],
      remainingPaymentResume: [null]
    });
  }

  public setOptionModel(): void {
    this.budgetTypeOptionList.setRequestValues(
      this.budgetAllocationInfoResponse.budgetTypeList
    );
    const coaList = [...this.budgetAllocationInfoResponse.coaList].map(
      (coa: Coa & { viewPathCoa: string }) => {
        coa.viewPathCoa = coa.code + '-' + coa.name;
        return coa;
      }
    );
    this.coaOptionList.setRequestValues(coaList);
    this.currencyOptionList.setRequestValues(
      this.budgetAllocationInfoResponse.currencyList
    );
    this.budgetAllocationOptionList.setRequestValues(
      this.budgetAllocationInfoResponse.budgetAllocationList
    );
  }

  public setResumeBudget(): void {
    this.httpClientService
      .post<BudgetAllocationInfoResponse>(
        '/budget-allocation-bjb/info',
        this.formGroup.value
      )
      .subscribe(budgetAllocationInfoResponse => {
        this.budgetAllocationInfoResponse = budgetAllocationInfoResponse;
        this.budgetInfoFormGroup.patchValue({
          totalBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationInfoResponse.totalBudgetResume.toString()
          ),
          bookedBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationInfoResponse.bookedBudgetResume.toString()
          ),
          usedBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationInfoResponse.usedBudgetResume.toString()
          ),
          paidOffBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationInfoResponse.paidOffBudgetResume.toString()
          ),
          availableBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationInfoResponse.availableBudgetResume.toString()
          ),
          remainingPaymentResume: this.global.converterService.convertMoney(
            budgetAllocationInfoResponse.remainingPayment.toString()
          )
        });
        this.isLoading = true;
        this.setOptionModel();
        this.setStateReady();
      });
  }
  public buildTableResponseOrganization(): void {
    this.tableResponseOrganization = new TableResponseModel(this.moduleCode, [
      { field: '1', header: 'table.column.organization' },
      {
        field: '2',
        header: 'table.column.period',
        customClass: 'text-center'
      },
      {
        field: '4',
        header: 'table.column.totalBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: '3'
      },
      {
        field: '5',
        header: 'table.column.bookedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: '3'
      },
      {
        field: '6',
        header: 'table.column.usedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: '3'
      },
      {
        field: '7',
        header: 'table.column.paidOffBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: '3'
      },
      {
        field: '8',
        header: 'table.column.remainingPayment',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: '3'
      },
      {
        field: '9',
        header: 'table.column.availableBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: '3'
      }
    ]);
  }

  public buildTableResponseCoa(): void {
    this.tableResponseCoa = new TableResponseModel(this.moduleCode, [
      {
        field: 'coa.code',
        header: 'table.column.coaCode',
        customClass: 'text-center',
        fn: this.handleHyperlink.bind(this)
      },
      { field: 'coa.name', header: 'table.column.coaName' },
      {
        field: 'activityPeriod',
        header: 'table.column.period',
        customClass: 'text-center'
      },
      {
        field: 'totalBudget',
        header: 'table.column.totalBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'bookedBudget',
        header: 'table.column.bookedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'usedBudget',
        header: 'table.column.usedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'paidOffBudget',
        header: 'table.column.paidOffBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'remainingPayment',
        header: 'table.column.remainingPayment',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'availableBudget',
        header: 'table.column.availableBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.set('todo', 'add');
    this.global.routerParams.set('backUrl', '/pages/budget-allocation-bjb');
    this.router.navigate(['/pages/budget-allocation-bjb/add']);
  }

  public doEdit(budgetAllocation: BudgetAllocation): void {
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('budgetId', budgetAllocation[0]);
    this.global.routerParams.set('backUrl', '/pages/budget-allocation-bjb');
    this.router.navigate(['/pages/budget-allocation-bjb/edit']);
  }

  public doDetailOrganization(budgetAllocation: BudgetAllocation): void {
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('organizationId', budgetAllocation[0]);
    this.global.routerParams.set('activityPeriod', budgetAllocation[2]);
    this.global.routerParams.set('backUrl', '/pages/budget-allocation-bjb');
    this.router.navigate([
      '/pages/budget-allocation-bjb/organization/detail/index'
    ]);
  }
  public doDetailCoa(budgetAllocation: BudgetAllocationByCoaView): void {
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('coaId', budgetAllocation.coa.id);
    this.global.routerParams.set('backUrl', '/pages/budget-allocation-bjb');
    this.router.navigate(['/pages/budget-allocation-bjb/coa/detail/index']);
  }

  public doDetailCoaOrganization(
    budgetAllocation: BudgetAllocationByCoaView
  ): void {
    const coaId = budgetAllocation.coa.id;
    this.appPopupService
      .open(AppPopupBudgetAllocationBjbComponent, { coaId })
      .subscribe(() => {});
  }

  public handleHyperlink(value: any, record: any): void {
    this.log.debug(value);
    let aElement: any = record.coa.code;
    if (!record.coa.isHasChild) {
      aElement = document.createElement('a');
      aElement.style.color = 'var(--primary)';
      aElement.addEventListener('click', () => {
        this.doDetailCoaOrganization(record);
      });
      aElement.textContent = record.coa.code;
    }
    return aElement;
  }

  public doImport(): void {
    this.appPopupImportService
      .open('/budget-allocation-bjb/import-excel')
      .subscribe(result => {
        if (result) {
          this.tableResponseOrganization.reload();
          this.tableResponseCoa.reload();
          this.setBudgetInfo();
        }
      });
    this.importFileWebsocketService.appPopupImportComponent =
      this.appPopupImportService.appPopupService.componentInstance;
  }

  public setBudgetInfo(): void {
    this.httpClientService
      .post<BudgetAllocationInfoResponse>(
        '/budget-allocation-bjb/info',
        this.formGroup.value
      )
      .subscribe(budgetAllocationInfoResponse => {
        this.budgetAllocationInfoResponse = budgetAllocationInfoResponse;
        this.budgetInfoFormGroup.patchValue({
          totalBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationInfoResponse.totalBudgetResume.toString()
          ),
          bookedBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationInfoResponse.bookedBudgetResume.toString()
          ),
          usedBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationInfoResponse.usedBudgetResume.toString()
          ),
          paidOffBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationInfoResponse.paidOffBudgetResume.toString()
          ),
          availableBudgetResume: this.global.converterService.convertMoney(
            budgetAllocationInfoResponse.availableBudgetResume.toString()
          ),
          remainingPaymentResume: this.global.converterService.convertMoney(
            budgetAllocationInfoResponse.remainingPayment.toString()
          )
        });
      });
  }

  public doApply(): void {
    if (this.formGroup.get('organizationList').value) {
      if (this.formGroup.get('fromPeriod').value) {
        this.formGroup.value.fromPeriod =
        this.formGroup.get('fromPeriod').value.activityPeriod;
      }
      if (this.formGroup.get('untilPeriod').value) {
        this.formGroup.value.untilPeriod =
          this.formGroup.get('untilPeriod').value.activityPeriod;
      }
      this.httpClientService
      .post<BudgetAllocationInfoResponse>(
        '/budget-allocation-bjb/check-allowed-access',
        this.formGroup.value
      ).subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.doApplyFilter();
        } else {
          this.global.alertService.showError(this.global.translateService.instant('budget-allocation-bjb.alert.msg.noOrganizationAccess'))
        }
      });
    } else {
      this.doApplyFilter();
    }
  }

  public doApplyFilter() {
    this.global.routerParams.clear();
    if (this.formGroup.get('fromPeriod').value) {
      this.formGroup.value.fromPeriod =
        this.formGroup.get('fromPeriod').value.activityPeriod;
    }
    if (this.formGroup.get('untilPeriod').value) {
      this.formGroup.value.untilPeriod =
        this.formGroup.get('untilPeriod').value.activityPeriod;
    }
    this.tableResponseOrganization.setCustomData(this.formGroup.value);
    this.tableResponseCoa.setCustomData(this.formGroup.value);
    this.tableResponseOrganization.reload();
    this.tableResponseCoa.reload();
    this.setBudgetInfo();
    this.currencyCode =
      this.formGroup.value.currency === null
        ? null
        : this.formGroup.value.currency.code;
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponseOrganization.resetCustomData();
    this.tableResponseCoa.setCustomData(this.formGroup.value);
    this.tableResponseOrganization.reload();
    this.tableResponseCoa.reload();
    this.setBudgetInfo();
    this.currencyCode = null;
  }

  public doExport(): void {
    const isAllowAccess = this.isAllowAccess;
    this.appPopupService
      .open(
        AppPopupExportBudgetBjbComponent,
        { isAllowAccess },
        {
          backdrop: true,
          size: null
        }
      )
      .subscribe((data: string) => {
        this.isLoadingReport = true;
        let url: string;
        if (data === 'projection') {
          url = '/budget-allocation-bjb/export-projection-budget';
        } else if (data === 'realization') {
          url = '/budget-allocation-bjb/export-realization-budget';
        }
        if (url) {
          this.recapPrintService
            .print(url, this.tableResponseOrganization.request)
            .subscribe(() => {
              this.isLoadingReport = false;
            });
        }
      });
  }

  public doSync(): void {
    this.appPopupService
      .open(
        AppPopupSyncBudgetBjbComponent,
        { onCloseModal: this.websocketBudgetService.onCloseModal },
        {
          backdrop: 'static',
          size: 'lg'
        }
      )
      .subscribe();
    this.websocketBudgetService.appPopupSyncBudgetBjbComponent =
      this.appPopupService.componentInstance;
  }
}
