<app-layout>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-card
          header="user-monitoring.title"
          [isLoading]="formLoading"
          [isSaving]="formSaving"
        >
          <app-table
            *ngIf="!formLoading"
            [model]="tableResponseUserCounter"
            [isServerSide]="false"
          >
            <ng-template #headerButtons>
              <app-button
                (onClick)="doAddUserCounter()"
                title="{{ 'app.button.add' | translate }}"
              >
                <em class="fas fa-plus"></em>{{ 'app.button.add' | translate }}
              </app-button>
            </ng-template>
            <ng-template #actionButtons let-rowData>
              <app-button
                size="TN"
                color="DANGER"
                (onClick)="doDeleteUserCounter(rowData)"
                title="{{ 'app.button.delete' | translate }}"
              >
                <em class="fas fa-trash"></em>
              </app-button>
            </ng-template>
          </app-table>
        </app-card>
      </form>
      <div class="row justify-content-center mt-5">
        <app-button
          color="SECONDARY"
          class="mr-1"
          (onClick)="doCancel()"
          [disabled]="formSaving"
          mode="BYPASS"
        >
          {{ 'app.button.back' | translate }}
        </app-button>
        <app-button
          color="PRIMARY"
          (onClick)="doSave()"
          [disabled]="formSaving"
        >
          {{ 'app.button.save' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</app-layout>
