import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupSignatureComponent } from './app-popup-signature.component';

@Injectable()
export class AppPopupSignatureService {
  constructor(public appPopupService: AppPopupService) {}
  public open(
    stages: string,
    header: string
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupSignatureComponent,
      {
        stages,
        header
      },
      { size: 'md' }
    );
  }
}
