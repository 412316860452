import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppWorklistInfoModule } from '../../core/components/app-worklist-info/app-worklist-info.module';
import { SharedModule } from '../../core/shared/shared.module';
import { WorklistPMComponent } from './worklist-pm.component';

export const routes = [
  {
    path: '',
    component: WorklistPMComponent,
    data: { breadcrumb: 'worklist-pm.breadcrumb.index' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppWorklistInfoModule
  ],
  declarations: [WorklistPMComponent]
})
export class WorklistPMModule {}
