import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddressBook } from '../../../bean/address-book';
import { AppPopupService } from '../app-popup.service';
import { AppPopupAddressComponent } from './app-popup-address.component';

@Injectable()
export class AppPopupAddressService {
  constructor(public appPopupService: AppPopupService) {}

  public open(
    addressBookList?: AddressBook[],
    isMultiple?: boolean
  ): Observable<any> {
    return this.appPopupService.open(
      AppPopupAddressComponent,
      { addressBookList, isMultiple },
      { size: 'lg' }
    );
  }
}
