import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Vendor } from '../../core/bean/vendor';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { RecapPrintService } from '../../core/services/recap-print.service';
@Component({
  templateUrl: './performance-procurement.component.html',
  styles: ['.width-company-name { min-width : 250px; white-space: normal ! important }'],
  encapsulation: ViewEncapsulation.None
})
export class PerformanceProcurementComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<Vendor>;
  public isLoading: boolean;
  constructor(translateService: TranslateService, public recapPrintService: RecapPrintService) {
    super('performance-procurement', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.vendorName', customClass: 'width-company-name' },
      { field: 'code', header: 'table.column.vendorCode', customClass: 'text-center' },
      { field: 'vendorType.name', header: 'table.column.vendorType', customClass: 'text-center' },
      { field: 'email', header: 'table.column.email' },
      { field: 'averageRatingProcurement', header: 'table.column.rating',
        format: FieldFormatEnum.Rating, customClass: 'text-center'  }
    ]);
  }

  public doView(vendor: Vendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('vendorId', vendor.id);
    this.router.navigate(['/pages/performance-procurement/detail-procurement']);
  }

  public doExport(): void {
    this.isLoading = true;
    this.recapPrintService.print('/performance-procurement/recap-performance-procurement', this.tableResponse.request).subscribe(() => {
      this.isLoading = false;
    });
  }
}
