import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../base/angular/base-module.component';
import { Contract } from '../../bean/contract';
import { WorkPlan } from '../../bean/work-plan';
import { WorkPathModel } from '../app-work-path/model/work-path-model';
import { WorkPlanInfoRequest } from '../app-work-plan-info/request/work-plan-info-request';
import { WorkflowProgressInfoModel } from './model/workflow-progress-info-model';
import { WorkflowProgressInfoRequest } from './request/workflow-progress-info-request';

@Component({
  selector: 'app-workflow-progress-info',
  templateUrl: './app-workflow-progress-info.component.html',
  styleUrls: ['./app-workflow-progress-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWorkflowProgressInfoComponent extends BaseModuleComponent {
  @Input() public workPlan: WorkPlan;
  @Input() public contract: Contract;
  @Input() public currentPosition: string;

  public currentId: number;
  public workPathModel: WorkPathModel = new WorkPathModel(true);

  constructor(translateService: TranslateService) {
    super('', translateService);
  }

  onInit(): void {
    this.doSetWorkPathRequestValues();
  }

  private doSetWorkPathRequestValues(): void {
    this.httpClientService
      .post<WorkPlanInfoRequest>('/app-workflow-progress-info/index', {
        id: this.workPlan
          ? this.workPlan.workflowModelPrcs.id
          : this.contract.workflowModelPrcs.id,
        todo: 'string'
      })
      .subscribe((response: WorkflowProgressInfoRequest) => {
        const workflowProgressInfoModelList: WorkflowProgressInfoModel[] =
          response.workflowProgressInfoModelList;

        const statusList: any = ['DONE', 'CURRENT', ''];
        const workPathItemList = [...workflowProgressInfoModelList].map(
          (data: WorkflowProgressInfoModel) => {
            return {
              id: data.workflowModelPrcs.id,
              status: statusList[data.status - 1],
              code: data.workflowModelPrcs.code,
              moduleTitle: data.workflowModelPrcs.name,
              activeCode: this.currentPosition,
              date: data.finishDate
            };
          }
        );
        this.workPathModel.setRequestValues(workPathItemList);
      });
  }

  doOnChangeWorkpath(item: any): void {
    this.currentId = this.workPlan ? this.workPlan.id : this.contract.id;
    if (this.contract && this.contract.prcsCode !== 'CONTRACT-NEGOTIATION') {
      this.global.routerParams.clear();
    }
    let isTodoEdit = this.workPlan
      ? this.workPlan.workflowPrcs.workflowStatus.code ===
      this.global.appConstant.core.WORKFLOW_CODE_STATUS_REVISION &&
      this.workPlan.prcsCode === item.code
      : (this.contract.workflowPrcs.workflowStatus.code ===
        this.global.appConstant.core.WORKFLOW_CODE_STATUS_REVISION ||
        this.contract.workflowPrcs.workflowStatus.code ===
        this.global.appConstant.core.WORKFLOW_CODE_STATUS_ON_GOING) &&
      this.contract.prcsCode === item.code;
    if (
      this.contract &&
      this.contract.prcsCode === 'CONTRACT-NEGOTIATION' &&
      item.code === 'CONTRACT-NEGOTIATION'
    ) {
      this.contract = this.global.routerParams.get('contract');
      if (
        this.contract.isVendorApproved ||
        (!this.contract.isVendorApproved &&
          this.contract.workflowPrcs.workflowStatus.code ===
          this.global.appConstant.core.WORKFLOW_CODE_STATUS_ON_APPROVAL)
      ) {
        isTodoEdit = false;
      } else if (!this.contract.isVendorApproved) {
        isTodoEdit = true;
      }
      this.global.routerParams.clear();
    }
    this.global.routerParams.set('todo', isTodoEdit ? 'edit' : 'view');
    if (this.workPlan) {
      this.global.routerParams.set('workPlan', this.workPlan);
      this.global.routerParams.set('workPlanId', this.workPlan.id);
      this.global.routerParams.set(
        'urlBackOutside',
        '/pages/worklist-pra-procurement'
      );
    } else if (this.contract) {
      this.global.routerParams.set('contract', this.contract);
      this.global.routerParams.set('contractId', this.contract.id);
      this.global.routerParams.set('urlBackOutside', '/pages/worklist-cm');
    }
    this.global.routerParams.set('isWorkflowProgress', true);
    if (isTodoEdit) {
      this.router.navigate(['/pages/' + item.code.toLowerCase() + '/edit']);
    } else {
      this.router.navigate(['/pages/' + item.code.toLowerCase() + '/detail']);
    }
  }
}
