import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupAddUserExecutorComponent } from './app-popup-add-user-executor.component';
import { AppPopupAddExecutorAssignmentComponent } from './app-popup-add-executor-assignment.component';
import { ExecutorEditAddComponent } from './executor-edit-add.component';
import { ExecutorComponent } from './executor.component';

const routes = [
  {
    path: '',
    component: ExecutorComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'edit',
    component: ExecutorEditAddComponent,
    data: { todo: 'edit', breadcrumb: 'executor.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [
    ExecutorComponent,
    ExecutorEditAddComponent,
    AppPopupAddUserExecutorComponent,
    AppPopupAddExecutorAssignmentComponent
  ]
})
export class ExecutorModule {}
