import {
  AfterViewChecked,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
declare let $: any;
const selector = '[autofocus=true]';
@Directive({ selector })
export class AutofocusDirective implements AfterViewChecked, OnInit {
  @Input() autofocus = true;
  public isSetAutoFocus = false;
  public autoFocusElementMap: Map<string, AutoFocusElement> = new Map();
  public elementTagName: string;
  public constructor(public elementRef: ElementRef) {}

  ngOnInit(): void {
    this.elementTagName = this.elementRef.nativeElement.tagName;
    this.doSetAutoFocusElementMap();
  }

  ngAfterViewChecked(): void {
    if (!this.isSetAutoFocus) {
      const autoFocusElement = this.autoFocusElementMap.get(
        this.elementTagName
      );
      const elementList = $(this.elementRef.nativeElement).find(
        autoFocusElement.elementSelector
      );
      if (elementList.length > 0) {
        const element = elementList[0];
        element.focus({ preventScroll: true });
        if (autoFocusElement.type === 'CUSTOM') {
          element.classList.add('autofocus');
          this.isSetAutoFocus = true;
        }
      }
    }
  }

  @HostListener('focusin', ['$event'])
  onFocusIn(): void {
    this.isSetAutoFocus = true;
  }

  public doSetAutoFocusElementMap(): void {
    this.autoFocusElementMap.set('APP-TEXT-FIELD', {
      type: 'DEFAULT',
      elementSelector: 'input'
    });
    this.autoFocusElementMap.set('APP-TEXT-AREA', {
      type: 'DEFAULT',
      elementSelector: 'textarea'
    });
    this.autoFocusElementMap.set('APP-COMBO-BOX', {
      type: 'CUSTOM',
      elementSelector: '.app-select-label'
    });
    this.autoFocusElementMap.set('APP-TEXT-GENERATED', {
      type: 'DEFAULT',
      elementSelector: 'input'
    });
  }
}

interface AutoFocusElement {
  type: 'DEFAULT' | 'CUSTOM';
  elementSelector: string;
}
