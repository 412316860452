import { Aanwijzing } from 'src/app/core/bean/aanwijzing';
import { AanwijzingOnlineMenu } from 'src/app/core/bean/aanwijzing-online-menu';
import { AanwijzingSummary } from 'src/app/core/bean/aanwijzing-summary';
import { FileObject } from 'src/app/core/components/upload';

export class AanwijzingSessionRequest {

    isSubmit: boolean;
    /* create session */
    aanwijzing: Aanwijzing;
    previousAanwijzing: Aanwijzing;

    aanwijzingOnlineMenu: AanwijzingOnlineMenu;
    // aanwijzingParticipantList: AanwijzingParticipant[] = [];

    /* insert summary */
    aanwijzingSummary: AanwijzingSummary;
    fileObjectList: FileObject[] = [];
}
