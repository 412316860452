<div class="row main-row">
  <div class="col-12">
    <app-card
      header="rfi.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <h5 class="separator">{{ 'rfi.generalInformation' | translate }}</h5>
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div
          class="form-group row text-sm-right"
          [ngClass]="todo === 'add' ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'rfi.form.title' | translate
          }}</label>
          <div
            class="text-left"
            [ngClass]="todo === 'add' ? 'col-sm-9' : 'col-sm-4'"
          >
            <app-text-field
              autofocus="true"
              formControlName="title"
              maxLength="128"
            >
            </app-text-field>
          </div>
        </div>

        <!-- todo = add, bisa isi project amount -->
        <ng-container *ngIf="todo === 'add'">
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'rfi.form.projectAmount' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <div class="form-row">
                <div class="form-group row col-2">
                  <div class="col text-left">
                    <app-combo-box
                      size="XL"
                      formControlName="currency"
                      [optionList]="currencyOptionList"
                    ></app-combo-box>
                  </div>
                </div>
                <div class="form-group row col-4">
                  <div class="col text-left">
                    <app-text-field
                      size="XL"
                      maxLength="16"
                      formControlName="projectAmountFrom"
                      (onChange)="changeAmount($event)"
                      type="currency"
                    >
                    </app-text-field>
                  </div>
                </div>
                <p class="mr-2 ml-2"
                  ><strong>{{ 'rfi.form.until' | translate }}</strong></p
                >
                <div class="form-group row col-4">
                  <div class="col text-left">
                    <app-text-field
                      size="XL"
                      maxLength="16"
                      formControlName="projectAmountTo"
                      type="currency"
                    ></app-text-field>
                  </div>
                </div>
                <div class="form-group row col-2">
                  <div class="col text-left">
                    <app-check-box
                      size="XL"
                      formControlName="undisclosed"
                      (onChange)="changeProjectAmount()"
                      tooltip="{{ 'rfi.tooltip.projectAmount' | translate }}"
                    >
                      {{ 'rfi.form.undisclosed' | translate }}</app-check-box
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- todo = edit, tidak bisa isi project amount -->
        <ng-container *ngIf="todo === 'edit'">
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'rfi.form.projectAmount' | translate
            }}</label>
            <div
              class="col-sm-9 text-left"
              *ngIf="
                rfiDTO?.rfi?.currency ||
                rfiDTO?.rfi?.projectAmountFrom ||
                rfiDTO?.rfi?.projectAmountTo
              "
            >
              <div
                >{{ rfiDTO?.rfi?.currency?.code }}
                {{ formGroup.value.projectAmountFrom | number }}
                {{ 'rfi.form.until' | translate }}
                {{ rfiDTO?.rfi?.currency?.code }}
                {{ formGroup.value.projectAmountTo | number }}</div
              >
            </div>
            <div
              class="col-sm-9 text-left"
              *ngIf="
                !rfiDTO?.rfi?.currency ||
                !rfiDTO?.rfi?.projectAmountFrom ||
                !rfiDTO?.rfi?.projectAmountTo
              "
            >
              <div>{{ 'rfi.form.undisclosed' | translate }}</div>
            </div>
          </div>
        </ng-container>

        <div
          class="form-group row text-sm-right"
          [ngClass]="todo === 'add' ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'rfi.form.publishDate' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-date-picker formControlName="publishDate" minDate="newDate"
            (onChange)="doSetMinDateResponse($event)">
            </app-date-picker>
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="todo === 'add' ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'rfi.form.responseDeadline' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-date-picker
              formControlName="responseDeadlineDate"
              tooltip="{{ 'rfi.tooltip.responseDeadline' | translate }}"
            ></app-date-picker>
          </div>
        </div>
        <div
          class="form-group row text-sm-right"
          [ngClass]="todo === 'add' ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'rfi.form.templateRFI' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-upload formControlName="file" [fileUploader]="fileUploader">
            </app-upload>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'rfi.form.note' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="note" maxLength="512">
            </app-text-area>
          </div>
        </div>

        <h5 class="separator">{{ 'rfi.item' | translate }}</h5>
        <app-table
          #selectorItem
          [model]="tableResponseRfiItem"
          *ngIf="!formLoading"
        >
          <ng-template #headerButtons>
            <app-button (onClick)="doAddItem()"
              ><em class="fas fa-plus"></em>
              {{ 'app.button.add' | translate }}</app-button
            >
          </ng-template>
          <ng-template #actionButtons let-rowData>
            <app-button
              size="TN"
              (onClick)="doEditItem(rowData)"
              title="{{ 'app.tooltip.edit' | translate }}"
            >
              <em class="fas fa-pencil-alt"></em>
            </app-button>
            <app-button
              *ngIf="!rowData.id"
              size="TN"
              color="DANGER"
              (onClick)="doDeleteItem(rowData)"
              title="{{ 'app.tooltip.delete' | translate }}"
            >
              <em class="fas fa-trash"></em>
            </app-button>
          </ng-template>
        </app-table>

        <br />
        <div *ngIf="!formLoading" class="form-group row required">
          <div class="col-lg-12">
            <h5 class="separator">{{ 'rfi.vendorData' | translate }}</h5>
            <app-table
              #selectorVendor
              [model]="tableResponseVendor"
              *ngIf="!formLoading"
            >
              <ng-template #headerButtons>
                <app-button (onClick)="doAddVendor()"
                  ><em class="fas fa-plus"></em>
                  {{ 'app.button.add' | translate }}
                </app-button>
              </ng-template>
              <ng-template #actionButtons let-rowData>
                <!-- rowData = rfiVendor -->
                <!-- sudah ada responseRFI -->
                <app-button
                  *ngIf="rowData.id && rowData.isSubmitted"
                  size="TN"
                  color="PRIMARY"
                  (onClick)="doViewResponseVendor(rowData)"
                  title="{{ 'app.tooltip.view' | translate }}"
                >
                  <em class="fas fa-search"></em>
                </app-button>
                <!-- belum ada responseRFI -->
                <app-button
                  *ngIf="
                    rowData.id &&
                    !rowData.isSubmitted &&
                    isNearDeadline(rowData)
                  "
                  size="TN"
                  color="PRIMARY"
                  (onClick)="doSendEmail(rowData)"
                  title="{{ 'app.button.send' | translate }}"
                >
                  <em class="fas fa-paper-plane"></em>
                </app-button>
                <div
                  *ngIf="
                    rowData.id &&
                    !rowData.isSubmitted &&
                    !isNearDeadline(rowData)
                  "
                >
                  -
                </div>
                <!-- belum ada rfi vendor -->
                <app-button
                  *ngIf="!rowData.id"
                  size="TN"
                  color="DANGER"
                  (onClick)="doDeleteVendor(rowData)"
                  title="{{ 'app.tooltip.delete' | translate }}"
                >
                  <em class="fas fa-trash"></em>
                </app-button>
              </ng-template>
            </app-table>
          </div>
        </div>

        <!-- untuk saat ini vendor non-promise di-hide dulu -->
        <!-- <h5 class="separator">Vendor Non Promise</h5>
        <app-table #selectorVendorNonPromise [model]="tableResponseVendorNonPromise" *ngIf="!formLoading">
          <ng-template #headerButtons>
            <app-button 
            (onClick)="doAddVendorNonPromise()">
            <em class="fas fa-plus"></em>
             {{ 'app.button.add' | translate }}</app-button>
          </ng-template>
          <ng-template 
          #actionButtons let-rowData>
            <app-button size="TN" 
            (onClick)="doEditVendorNonPromise(rowData)" 
            title="{{ 'app.tooltip.edit' | translate }}">
              
              <em class="fas fa-pencil-alt"></em>
            </app-button>
            <app-button size="TN" 
            color="DANGER" 
            (onClick)="doDeleteVendorNonPromise(rowData)" 
            title="{{ 'app.tooltip.delete' | translate }}">
              <em class="fas fa-trash"></em>
            </app-button>
          </ng-template>
        </app-table> -->

        <div *ngIf="!formLoading" class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            [disabled]="formSaving"
            (onClick)="doCancel()"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button color="PRIMARY"
            (onClick)="doNext()">
            {{ 'app.button.next' | translate }}</app-button> <!--*ngIf="
            todo === 'add' ||
            (todo === 'edit' &&
              rfiDTO?.rfiQuestionList &&
              rfiDTO?.rfiQuestionList.length > 0)"-->

          <!-- save without questions -->
          <!-- <app-button
            *ngIf="
              todo === 'edit' &&
              (!rfiDTO?.rfiQuestionList || rfiDTO?.rfiQuestionList.length == 0)
            "
            color="PRIMARY"
            [disabled]="formSaving"
            (onClick)="doSave()"
            >{{ 'app.button.save' | translate }}</app-button> -->
        </div>
      </form>
    </app-card>
  </div>
</div>
