import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import { PromiseThemeService } from '../../../themes/promise/promise-theme.service';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { TabModel } from '../../model/tab/tab-model';
import { TabResponseModel } from '../../model/tab/tab-response-model';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      multi: true
    }
  ],
  animations: [
    trigger('fixedNavigation', [
      state(
        'default',
        style({
          boxShadow: 'unset'
        })
      ),
      state(
        'fixed',
        style({
          boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 4px -4px'
        })
      ),
      transition('default<=>fixed', animate('200ms'))
    ])
  ]
})
export class AppLayoutComponent
  extends BaseComponentComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() isLoading: boolean;
  @Input() header: string;
  @Input() isEnableBackButton: boolean;
  @Input() backTo: string;
  @Input() isShowSidebarLeft: boolean;
  @Input() isHideContentSidebarLeft: boolean;
  @Input() isHideContentSidebarRight: boolean;
  @Input() tabModel: TabResponseModel;
  @Input() sidebarWidth: 'SMALL' | 'FULL' | 'NONE' = 'SMALL';
  @Input() isShowBreadcrumbs = true;

  @ContentChild('contentHeader') contentHeaderTemplate: TemplateRef<any>;
  @ContentChild('contentSidebarLeft')
  contentSidebarLeftTemplate: TemplateRef<any>;
  @ContentChild('contentSidebarRight')
  contentSidebarRightTemplate: TemplateRef<any>;
  @ContentChild('additionalTitle') additionalTitleTemplate: TemplateRef<any>;

  @ViewChild('sidebarContentLeft') sidebarContentLeft: ElementRef;
  @ViewChild('sidebarContentRight') sidebarContentRight: ElementRef;

  public layoutTitle: string;
  public isShowBackButton: boolean;
  public isFixedNavigation: boolean;
  constructor(
    private promiseThemeService: PromiseThemeService,
    private title: Title
  ) {
    super('app-layout');
  }

  onInit(): void {
    this.setInitializationState();
    this.setStateReady();
    this.log.debug(this.tabModel);
  }

  ngAfterViewInit(): void {
    if (!this.isLoading) {
      this.setOrRemoveContentSidebar();
      this.setContentSidebarFromTop();
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (
      simpleChanges.isLoading &&
      simpleChanges.isLoading.currentValue !==
        simpleChanges.isLoading.previousValue
    ) {
      setTimeout(() => {
        this.setOrRemoveContentSidebar();
        this.setContentSidebarFromTop();
      });
    }
  }

  ngOnDestroy(): void {
    this.promiseThemeService.disableContentSidebar('left');
    this.promiseThemeService.disableContentSidebar('right');
  }

  public doBack(): void {
    if (
      this.backTo &&
      (this.backTo === 'close' || this.backTo === 'close-window')
    ) {
      window.close();
    } else {
      this.router.navigate([
        this.backTo ||
          (this.global.navigationHistory.currentNavigation &&
            this.global.navigationHistory.currentNavigation
              .previousNavigation &&
            this.global.navigationHistory.currentNavigation.previousNavigation
              .routerUrl) ||
          '/pages/dashboard'
      ]);
      this.global.navigationHistory.removeLastNavigation();
    }
  }

  private setInitializationState(): void {
    this.promiseThemeService.changeSidebarWidth(
      this.sidebarWidth === 'NONE' ? this.sidebarWidth : 'SMALL'
    );
    this.promiseThemeService.settings.isShowBreadcrumbs =
      this.isShowBreadcrumbs;
    this.layoutTitle = this.title.getTitle().split('>')[1];
    this.isShowBackButton = this.title.getTitle().split('>').length > 2;
  }

  private setOrRemoveContentSidebar(): void {
    this.contentSidebarLeftTemplate && !this.isHideContentSidebarLeft
      ? this.promiseThemeService.enableContentSidebar('left')
      : this.promiseThemeService.disableContentSidebar('left');
    this.contentSidebarRightTemplate && !this.isHideContentSidebarRight
      ? this.promiseThemeService.enableContentSidebar('right')
      : this.promiseThemeService.disableContentSidebar('right');
  }

  private setContentSidebarFromTop(): void {
    if (
      (this.contentSidebarLeftTemplate && !this.isHideContentSidebarLeft) ||
      (this.contentSidebarRightTemplate && !this.isHideContentSidebarRight)
    ) {
      const isShowBackButton =
        this.isEnableBackButton || this.backTo || this.isShowBackButton;
      const headerWrapperElement = document.getElementById('headerWrapper');
      if (headerWrapperElement) {
        const headerWrapperHeight = headerWrapperElement.scrollHeight;
        // tslint:disable-next-line:no-unused-expression
        this.contentSidebarLeftTemplate &&
          !this.isHideContentSidebarLeft &&
          this.sidebarContentLeft &&
          (this.sidebarContentLeft.nativeElement.style.top =
            headerWrapperHeight + 18 + (isShowBackButton ? 50 : 0) + 'px');
        // tslint:disable-next-line:no-unused-expression
        this.contentSidebarRightTemplate &&
          !this.isHideContentSidebarRight &&
          this.sidebarContentRight &&
          (this.sidebarContentRight.nativeElement.style.top =
            headerWrapperHeight + 18 + (isShowBackButton ? 50 : 0) + 'px');
      }
    }
  }

  public doNext(tab: TabModel): void {
    this.tabModel.setCurrentTab(tab);
    this.router.navigate([tab.routeUrl]);
  }

  @HostListener('document:scroll')
  _onDocumentScroll(): void {
    this.isFixedNavigation = document.documentElement.scrollTop > 44;
  }
}
