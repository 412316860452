import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppSelectModule } from '../app-select/app-select.module';
import { AppTextFieldModule } from '../app-text-field/app-text-field.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppCurrencyComponent } from './app-currency.component';
@NgModule({
  imports: [
    VendorModule,
    AppTooltipWrapperModule,
    AppTextFieldModule,
    DirectivesModule,
    AppSelectModule
  ],
  declarations: [AppCurrencyComponent],
  exports: [AppCurrencyComponent]
})
export class AppCurrencyModule {}
