import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppCardComponent } from './app-card.component';
@NgModule({
  imports: [VendorModule, DirectivesModule],
  declarations: [AppCardComponent],
  exports: [AppCardComponent]
})
export class AppCardModule {}
