<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="all-performance.title"
      detail="Detail"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup">
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'all-performance.form.vendorName' | translate
          }}</label>
          <div class="col-sm-9 col-lg-7 text-left">
            {{ formGroup.get('vendorName').value }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'all-performance.form.address' | translate
          }}</label>
          <div class="col-sm-9 col-lg-7 text-left">
            {{ formGroup.get('address').value }}
          </div>
        </div>
        <ng-container *ngIf="allPerformanceDTO?.performance?.moduleScoringCriteria.code 
        === global.appConstant.vm.M_SCORING_CRITERIA_REGISTRATION">
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'all-performance.form.vendorType' | translate
            }}</label>
            <div class="col-sm-9 col-lg-7 text-left">
              {{ formGroup.get('vendorType').value }}
            </div>
          </div>
        </ng-container>
        
        <ng-container *ngIf="allPerformanceDTO?.performance?.moduleScoringCriteria.code 
        === global.appConstant.vm.M_SCORING_CRITERIA_PROCUREMENT">
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'all-performance.form.procurementName' | translate
            }}</label>
            <div class="col-sm-9 col-lg-7 text-left">
              {{ procurement?.name }}
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'all-performance.form.procurementType' | translate
            }}</label>
            <div class="col-sm-9 col-lg-7 text-left">
              {{ procurement?.procurementType.name }}
            </div>
          </div>
        </ng-container>
        
        <ng-container *ngIf="allPerformanceDTO?.performance?.moduleScoringCriteria.code 
        === global.appConstant.vm.M_SCORING_CRITERIA_FULFILLMENT">
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'all-performance.form.agreementNumber' | translate
            }}</label>
            <div class="col-sm-9 col-lg-7 text-left">
              {{ allPerformanceDTO?.agreementNumber }}
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'all-performance.form.agreementType' | translate
            }}</label>
            <div class="col-sm-9 col-lg-7 text-left">
              {{ allPerformanceDTO?.agreementType }}
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'all-performance.form.termin' | translate
            }}</label>
            <div class="col-sm-9 col-lg-7 text-left">
              {{ allPerformanceDTO?.termin }}
            </div>
          </div>
        </ng-container>

        <app-fieldset
          legend="{{ 'all-performance.legend.commonScoring' | translate }}"
        >
          <ng-container formArrayName="commonList">
            <p *ngIf="getFormArrayWithFormControlName(
              formGroup,
              'commonList'
              ).controls.length == 0"
                class="text-center">
                <strong>{{ 'app.info.noData' | translate }}</strong>
            </p>
            <ng-container
              *ngFor="
                let item of getFormArrayWithFormControlName(
                  formGroup,
                  'commonList'
                ).controls;
                let i = index
              "
              [formGroup]="item"
            >
              <div class="form-group row">
                <div class="col">
                  <label class="control-label"
                    >&nbsp;{{
                      item.controls.scoringCriteriaGroup.value.name
                    }}</label
                  >
                  <div
                    class="table-responsive custom-table float-none"
                    [ngStyle]="{ margin: '0 auto' }"
                  >
                    <table
                      class="table table-striped box-shadow"
                      aria-describedby="Table"
                    >
                      <thead>
                        <tr class="text-center">
                          <th scope="col"
                            >{{ 'app.table.column.no' | translate }}
                          </th>
                          <th scope="col">{{
                            'all-performance.table.column.criteria' | translate
                          }}</th>
                          <th scope="col"
                            >{{
                              'all-performance.table.column.value' | translate
                            }}
                          </th>
                          <th scope="col"
                            >{{
                              'all-performance.table.column.result' | translate
                            }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container formArrayName="scoringCriteriaList">
                          <tr
                            *ngFor="
                              let data of getFormArrayWithFormControlName(
                                item,
                                'scoringCriteriaList'
                              ).controls;
                              let j = index
                            "
                            [formGroup]="data"
                          >
                            <td class="text-center">{{ j + 1 }}.</td>
                            <td class="text-center">{{
                              data.value.scoringCriteria
                            }}</td>
                            <td class="text-center">
                              {{ data.value.value || '-' }}
                            </td>
                            <td class="text-center">
                              {{ data.value.result }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center font-weight-bold">
                              {{
                                'all-performance.table.column.total' | translate
                              }}
                            </td>
                            <td class="text-center font-weight-bold">
                              {{ totalResultCommon[i] }}
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </app-fieldset>

        <app-fieldset
          legend="{{ 'all-performance.legend.specificScoring' | translate }}"
        >
          <ng-container formArrayName="specificList">
            <p *ngIf="getFormArrayWithFormControlName(
              formGroup,
              'specificList'
              ).controls.length == 0"
                class="text-center">
                <strong>{{ 'app.info.noData' | translate }}</strong>
            </p>
            <ng-container
              *ngFor="
                let item of getFormArrayWithFormControlName(
                  formGroup,
                  'specificList'
                ).controls;
                let i = index
              "
              [formGroup]="item"
            >
              <div class="form-group row">
                <div class="col">
                  <label class="control-label"
                    >&nbsp;{{
                      item.controls.scoringCriteriaGroup.value.name
                    }}</label
                  >
                  <div
                    class="table-responsive custom-table float-none"
                    [ngStyle]="{ margin: '0 auto' }"
                  >
                    <table
                      class="table table-striped box-shadow"
                      aria-describedby="Table"
                    >
                      <thead>
                        <tr class="text-center">
                          <th scope="col"
                            >{{ 'app.table.column.no' | translate }}
                          </th>
                          <th scope="col">{{
                            'all-performance.table.column.criteria' | translate
                          }}</th>
                          <th scope="col"
                            >{{
                              'all-performance.table.column.value' | translate
                            }}
                          </th>
                          <th scope="col"
                            >{{
                              'all-performance.table.column.result' | translate
                            }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container formArrayName="scoringCriteriaList">
                          <tr
                            *ngFor="
                              let data of getFormArrayWithFormControlName(
                                item,
                                'scoringCriteriaList'
                              ).controls;
                              let j = index
                            "
                            [formGroup]="data"
                          >
                            <td class="text-center">{{ j + 1 }}.</td>
                            <td class="text-center">{{
                              data.value.scoringCriteria
                            }}</td>
                            <td class="text-center">
                              {{ data.value.value || '-' }}
                            </td>
                            <td class="text-center">
                              {{ data.value.result }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center font-weight-bold">
                              {{
                                'all-performance.table.column.total' | translate
                              }}
                            </td>
                            <td class="text-center font-weight-bold">
                              {{ totalResultSpecific[i] }}
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                    <br />
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </app-fieldset>

        <ng-container>
          <app-fieldset
            legend="{{ 'all-performance.legend.scoringSummary' | translate }}"
          >
            <div class="form-group row">
              <div class="col">
                <div
                  class="table-responsive custom-table float-none"
                  [ngStyle]="{ margin: '0 auto' }"
                >
                  <table
                    class="table table-striped box-shadow"
                    aria-describedby="Table"
                  >
                    <thead>
                      <tr class="text-center">
                        <th scope="col"
                          >{{ 'app.table.column.no' | translate }}
                        </th>
                        <th scope="col">{{
                          'all-performance.table.column.criteriaGroup'
                            | translate
                        }}</th>
                        <th scope="col">{{
                          'all-performance.table.column.weight' | translate
                        }}</th>
                        <th scope="col">{{
                          'all-performance.table.column.result' | translate
                        }}</th>
                        <th scope="col">{{
                          'all-performance.table.column.total' | translate
                        }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let data of allPerformanceDTO.performanceSummaryList;
                          let j = index
                        "
                      >
                        <td class="text-center">{{ j + 1 }}.</td>
                        <td class="text-center">{{
                          data.scoringCriteriaGroupName
                        }}</td>
                        <td class="text-center"> {{ data.weight }} % </td>
                        <td class="text-center">
                          {{ data.result | number: '1.2-2' }}
                        </td>
                        <td class="text-center">
                          {{ data.total | number: '1.2-2' }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center font-weight-bold">
                          {{
                            'all-performance.table.column.totalScoring'
                              | translate
                          }}
                        </td>
                        <td class="text-center font-weight-bold">
                          {{ totalScoring | number: '1.2-2' }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-center font-weight-bold">
                          {{
                            'all-performance.table.column.rating' | translate
                          }}
                        </td>
                        <td class="text-center">
                          <app-rating formControlName="rating" [isViewOnly]="true"></app-rating>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
            </div>
          </app-fieldset>
          <div class="form-group row text-sm-right" *ngIf="formGroup.isView">
            <label class="col-sm-3 control-label">{{
              'all-performance.form.performanceUser' | translate
            }}</label>
            <div class="col-sm-9 col-lg-7 text-left">
              <app-text-area autofocus="true" formControlName="performanceUser">
              </app-text-area>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'all-performance.form.uploadFile' | translate
            }}</label>
            <div class="col-sm-9 col-lg-7 text-left">
              <app-upload
                formControlName="uploadFile"
                [fileUploader]="fileUploader"
              ></app-upload>
            </div>
          </div>
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'all-performance.form.recommendationNote' | translate
            }}</label>
            <div class="col-sm-9 col-lg-7 text-left">
              <app-text-area formControlName="recommendationNote">
              </app-text-area>
            </div>
          </div>
        </ng-container>
        <div class="row justify-content-center mt-5">
          <app-button
            mode="BYPASS"
            color="SECONDARY"
            (onClick)="doBack()"
            class="mr-1"
            >{{ 'app.button.back' | translate }}</app-button
          >
        </div>
      </form>
    </app-card>
  </div>
</div>
