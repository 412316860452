import { DatePickerState } from '../model';

export const changeDatePickerTime = (
  state: DatePickerState,
  payload: {
    event: InputEvent & { target: HTMLElement };
    type: 'HOURS' | 'MINUTES';
  }
): void => {
  const { target } = payload.event;
  const hoursOrMinutes =
    +target.innerText < 10 ? '0' + +target.innerText : target.innerText;
  target.innerText = hoursOrMinutes;
  payload.type === 'HOURS'
    ? (state.currentTime.hoursString = hoursOrMinutes)
    : (state.currentTime.minutesString = hoursOrMinutes);

  if (
    state.currentTime.hoursString &&
    state.currentTime.minutesString &&
    state.currentDateValue
  ) {
    state.currentTime.timeString = `${state.currentTime.hoursString}:${state.currentTime.minutesString}`;
    state.currentDateValue.date.setHours(
      +state.currentTime.hoursString,
      +state.currentTime.minutesString
    );
    state.setValue(state.currentDateValue.date);
  }
};
