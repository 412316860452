<app-popup [header]="header" [isLoading]="formLoading">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'work-plan.product.form.item' | translate
      }}</label>
      <div *ngIf="!formGroup.isView" class="col-sm-9 text-left">
        <app-choose
          size="LG"
          formControlName="itemName"
          (onClick)="chooseItem()"
        >
        </app-choose>
      </div>
      <div *ngIf="!formLoading && formGroup.isView" class="col-sm-9 text-left">
        {{ formGroup.value.itemName }}
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'work-plan.product.form.uom' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          formGroup.controls.uom.value ? formGroup.controls.uom.value.name : '-'
        }}
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'work-plan.product.form.quantity' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <!-- Custom BJB -->
        <app-text-field *ngIf="!formGroup.isView"
          size="LG"
          formControlName="quantity"
          type="currency"
          maxLength="32"
          (onInput)="onInputQuantityOrPrice()"
        ></app-text-field>
        <p *ngIf="formGroup.isView"> 
          {{ formGroup.get('quantity').value | number: '1.2-2' }} 
        </p>
      </div>
    </div>
    <div class="form-group row text-sm-right required" *ngIf="todo !== 'view'">
      <label class="col-sm-3 control-label">{{
        'work-plan.product.form.unitPrice' | translate
      }}</label>
      <div class="col-sm-9 row text-left">
        <div class="ml-3 mr-4" [ngStyle]="{ 'font-size': 'medium' }">{{
          defaultCurrency
        }}</div>
        <div class="col-sm-11 text left">
          <app-text-field
            size="LG"
            formControlName="unitPrice"
            type="currency"
            maxLength="32"
            (onInput)="onInputQuantityOrPrice()"
          ></app-text-field>
        </div>
        <!-- PRODUCT -->
        <!-- <app-currency size="LG" formControlName="unitPrices"
          maxLength="16" [optionList]="currencyOptionList"
          (onChange)="onChangeUnitPrice($event)"></app-currency> -->
      </div>
    </div>
    <div class="form-group row text-sm-right" *ngIf="todo === 'view'">
      <label class="col-sm-3 control-label">{{
        'work-plan.product.form.unitPrice' | translate
      }}</label>
      <div class="col-sm-9 row text-left">
        <div class="ml-3 mr-4">{{ defaultCurrency }}</div>
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'work-plan.product.form.totalPrice' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        {{
          formGroup.controls.totalPrice.value
            ? global.converterService.convertMoney(
                formGroup.controls.totalPrice.value
              )
            : '-'
        }} {{ formGroup.controls.totalPrice.value ?
          (workplanItemEdit?.currency?.code 
            ? workplanItemEdit?.currency?.code 
            : currencyList[0].code )
          : '' }}
      </div>
    </div>
    <div
      class="form-group row text-sm-right"
      [ngClass]="!formGroup.isView ? 'required' : ''"
    >
      <label class="col-sm-3 control-label">{{
        'work-plan.product.form.specification' | translate
      }}</label>
      <div class="text-left" [ngClass]="!formGroup.isView ? 'col-sm-9' : 'col-sm-6'">
        <app-text-area
          size="LG"
          formControlName="specification"
          maxLength="512"
        ></app-text-area>
      </div>
    </div>
  </form>
  <app-fieldset
    legend="{{ 'work-plan.product.form.budget' | translate }}"
    [required]="!formGroup.isView ? true : false"
  >
    <ng-container *ngIf="!formGroup.isView">
      <app-fieldset legend="Filter">
        <form class="form-horizontal" [formGroup]="formGroupBudget" novalidate>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'work-plan.product.filterBudget.form.organization' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-tree
                size="LG"
                formControlName="organization"
                stringUrl="/organization"
                isLazy="true"
                header="Organization"
                (onChange)="onChangeOrganization()"
              >
              </app-text-tree>
            </div>
          </div>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'work-plan.product.filterBudget.form.workProgram' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-auto-complete formControlName="workProgram"
              size="LG"
              stringUrl="/work-plan/auto-complete-work-program-list"
              (onChange)="autoCompleteValueChange($event)"
              [params]="organizationIdParams"
              ></app-auto-complete>
            </div>
          </div>
          <!-- PRODUCT -->
          <!-- <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'work-plan.form.coa' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-tree
                size="LG"
                formControlName="coa"
                stringUrl="/coa"
                header="Coa"
              >
              </app-text-tree>
            </div>
          </div> -->
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'work-plan.product.filterBudget.form.period' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-combo-box
                [optionList]="periodOptionList"
                formControlName="activityPeriod"
              ></app-combo-box>
            </div>
          </div>
          <div class="button-group button-group-center mt-5">
            <app-button color="SECONDARY" (onClick)="doReset()"
              ><em class="fas fa-sync-alt"></em>
              {{ 'app.button.reset' | translate }}
            </app-button>
            <app-button
              color="PRIMARY"
              (onClick)="doApply()"
              [disabled]="formSaving"
              ><em class="fas fa-check"></em>
              {{ 'app.button.apply' | translate }}
            </app-button>
          </div>
        </form>
      </app-fieldset>
      <app-table
        *ngIf="!formLoading && isGenerateTableBudget"
        [model]="tableResponse"
        [isServerSide]="true"
        stringUrl="/work-plan/budget-allocation-list"
      >
        <ng-template #thead let-model>
          <thead>
            <tr>
              <th scope="col" class="tableNo" colspan="1">{{
                'app-table.column.no' | translate
              }}</th>
              <th
                scope="col"
                *ngFor="let column of model.columns; let i = index"
                (click)="column.sortable !== false && doSort(column.field)"
                [ngClass]="[
                  column.sortable !== false ? 'sort' : '',
                  model.request.sortField === column.field ? 'bg-light ' : '',
                  model.request.sortField === column.field &&
                  model.sortOrder === 'ASC'
                    ? 'sort-asc'
                    : '',
                  model.request.sortField === column.field &&
                  model.sortOrder === 'DESC'
                    ? 'sort-desc'
                    : '',
                  column.format === 5 || column.format === 5
                    ? 'text-right'
                    : 'text-center'
                ]"
                [hidden]="column.isChecked === false"
                colspan="99"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
              >
                {{ model.moduleCode + '.' + column.header | translate }}
              </th>
              <th scope="col" class="text-right">
                {{ 'work-plan.product.table.column.budgetValue' | translate }}</th
              >
              <!-- Custom BJB -->
              <th scope="col"> {{ 'work-plan.product.table.column.select' | translate }}  </th>
              <!-- PRODUCT -->
              <!-- <th scope="col">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" [attr.id]="'thead' + i" (click)="doCheckAll($event)" [checked]="workPlanItemModel.isCheckedAll">
                      <label class="custom-control-label" [attr.for]="'thead' + i"></label>
                    </div>
                   </th> -->
            </tr>
          </thead>
        </ng-template>
        <ng-template #tbody let-model>
          <tbody>
            <tr *ngIf="model.page.totalRecords === 0">
              <td colspan="9999999" class="bg-white text-center">{{
                'app.info.noData' | translate
              }}</td>
            </tr>
            <tr *ngFor="let record of model.page.records; let i = index">
              <td class="text-center" colspan="1">{{
                i + 1 + model.request.first
              }}</td>
              <td
                *ngFor="let column of model.columns"
                [hidden]="column.isChecked === false"
                colspan="99"
                #tdElement
              >
                {{ record | resolveColumnValue: column:model:tdElement }}
              </td>
              <td>
                <input
                  name="plannedBudget"
                  type="text"
                  class="form-control"
                  [(ngModel)]="
                    workPlanItemModel.recordPageMap.get(record.id) &&
                    workPlanItemModel.recordPageMap.get(record.id).plannedBudget
                  "
                  (input)="doOnInput(record, plannedBudget)"
                  #plannedBudget="ngModel"
                  [ngClass]="errorMapper.get(record.id) ? 'is-invalid' : ''"
                  [disabled]="
                    !workPlanItemModel.recordPageMap.get(record.id)?.isChecked
                  "
                  (focusout)="handleFocusOut($event)"
                  (focusin)="handleFocusIn($event)"
                  [ngStyle]="{ width: '-webkit-fill-available' }"
                  aria-label="Booked Budget"
                />
                <small *ngIf="errorMapper.get(record.id)" class="text-danger">
                  {{ errorMapper.get(record.id) }}
                </small>
              </td>
              <td>
                <!-- Custom BJB -->
                <div class="custom-control custom-radio text-center">
                  <input
                    type="radio"
                    class="custom-control-input"
                    [attr.id]="'tbody' + i"
                    [attr.name]="'tbody'"
                    (click)="doCheck($event, record)"
                    [checked]="
                      workPlanItemModel.recordPageMap.get(record.id)?.isChecked
                    "
                    (keyup)="onKeyUp($event)"
                    (keydown)="onKeyDown($event)"
                    aria-label="Checked"
                  />
                  <label
                    class="custom-control-label"
                    [attr.for]="'tbody' + i"
                  ></label>
                </div>
                <!-- PRODUCT -->
                <!-- <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" [attr.id]="'tbody' + i" (click)="doCheck($event,record)" [checked]="workPlanItemModel.recordPageMap.get(record.id)?.isChecked">
                          <label class="custom-control-label" [attr.for]="'tbody' + i"></label>
                        </div> -->
              </td>
            </tr>
          </tbody>
        </ng-template>
        <ng-template #tableFooter>
          <p
            class="m-0 text-right border border-top-0 d-flex justify-content-end align-items-center"
            [ngStyle]="{
              height: '3rem',
              'padding-right': '2rem'
            }"
          >
            <span class="mr-2"
              ><strong
                >{{ 'work-plan.product.form.totalProductCost' | translate }} :</strong
              ></span
            >
            {{ global.converterService.convertMoney(totalBudget) }} 
            {{ totalBudget ?
              (workplanItemEdit?.currency?.code 
                ? workplanItemEdit?.currency?.code 
                : currencyList[0].code )
              : '' }}
          </p>
          <p class="m-0 text-right">
            <small
              [ngStyle]="{ 'font-size': 'large' }"
              *ngIf="
                formGroup.controls.totalPrice.value &&
                totalBudget !== formGroup.value.totalPrice
              "
              class="text-danger"
            >
              {{ 'work-plan.product.alert.totalPriceNotFit' | translate }}
            </small>
          </p>
        </ng-template>
      </app-table>
    </ng-container>
    <ng-container *ngIf="formGroup.isView">
      <app-table #selectorBudgetView [tableResponse]="tableResponseBudgetView">
        <ng-template #tableFooter>
          <p
            class="m-0 text-right border border-top-0 d-flex align-items-center justify-content-end"
            [ngStyle]="{ height: '3rem', 'padding-right': '2rem' }"
          >
            <span class="mr-2"
              ><strong
                >{{ 'work-plan.product.form.totalProductCost' | translate }} :</strong
              ></span
            >
            {{ global.converterService.convertMoney(totalBudget) }} 
            {{ totalBudget ?
              (workplanItemEdit?.currency?.code 
                ? workplanItemEdit?.currency?.code 
                : currencyList[0].code )
              : '' }}
          </p>
        </ng-template>
      </app-table>
    </ng-container>
  </app-fieldset>

  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
        'app.button.close' | translate
      }}</app-button>
      <app-button
        *ngIf="!formGroup.isView"
        color="PRIMARY"
        (onClick)="doSubmit()"
        >{{ 'app.button.save' | translate }}</app-button
      >
    </div>
  </ng-template>
</app-popup>
