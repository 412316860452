import { BaseEntity } from '../base/base-entity';
import { BudgetAllocation } from '../bean/budget-allocation';
import { Coa } from '../bean/coa';
import { CostCenter } from '../bean/cost-center';
import { PaymentVoucher } from '../bean/payment-voucher';

export class PaymentVoucherBillingEntity extends BaseEntity {
  public coa: Coa;
  public costCenter: CostCenter;
  public paymentVoucher: PaymentVoucher;
  public budgetAllocation: BudgetAllocation;
  public taxBaseAmount: number;
  public totalAmount: number;
  public vatAmount: number;
  public taxPercentageValue: number;
}
