import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appAlphabet]'
})
export class AlphabetDirective {
  @Input() appAlphabet: string;
  @Input() pattern: string;

  public defautPatternStr = '[^a-zA-Z ]';

  constructor(public ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  handleInput(event: any): void {
    const strRegex = this.appAlphabet
      ? '[^a-zA-Z ' + this.appAlphabet + ']'
      : this.defautPatternStr;
    const regex = new RegExp(strRegex, 'g');
    event.target.value = event.target.value.replace(
      this.pattern ? this.pattern : regex,
      ''
    );
    this.ngControl.control.patchValue(event.target.value);
  }
}
