<app-popup header="sla-vendor.popup.adjustment" [isLoading]="formLoading">
  <app-fullfilled></app-fullfilled>
  <div class="row main-row-popup">
    <div class="col-12 mb-4">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row">
          <label class="col-sm-3">{{
            'sla-vendor.form.popup.workflowName' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ workflowName !== null ? workflowName : '-' }}
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3">{{
            'sla-vendor.form.popup.stage' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ stage !== null ? stage : '-' }}
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3">{{
            'sla-vendor.form.popup.beforeAdjustment' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            {{ totalRealizationBefore !== null ? totalRealizationBefore : '-' }}
          </div>
        </div>

        <div class="form-group row row-cols-3">
          <label class="col-sm-3">{{
            'sla-vendor.form.popup.adjustment' | translate
          }}</label>
          <div class="col-sm-2 text-left">
            <div class="form-group row">
              <label class="col-sm-3">{{ 'sla-vendor.day' | translate }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  autofocus="true"
                  size="XL"
                  formControlName="days"
                  type="integer"
                  maxLength="11"
                  (onChange)="doOnChange()"
                  (onInput)="doClickIsValidationTime()"
                >
                </app-text-field>
              </div>
            </div>
          </div>
          <div class="col-sm-2 text-left">
            <div class="form-group row">
              <label class="col-sm-3">{{
                'sla-vendor.hour' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  size="XL"
                  formControlName="hours"
                  type="integer"
                  maxLength="11"
                  (onChange)="doOnChange()"
                  (onInput)="doClickIsValidationTime()"
                >
                </app-text-field>
              </div>
            </div>
          </div>
          <div class="col-sm-3 text-left">
            <div class="form-group row">
              <label class="col-sm-3">{{
                'sla-vendor.minute' | translate
              }}</label>
              <div class="col-sm-6 text-left">
                <app-text-field
                  size="XL"
                  formControlName="minutes"
                  type="integer"
                  maxLength="11"
                  (onInput)="doClickIsValidationTime()"
                >
                </app-text-field>
              </div>
            </div>
          </div>
          <label class="col-sm-3"></label>
          <div class="col-sm-9 text-left">
            <small class="text-danger" *ngIf="isAdjustValidation">
              {{ 'app.validation.required' | translate }}
            </small>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3">{{
            'sla-vendor.form.popup.description' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area
              formControlName="description"
              size="LG"
              maxLength="512"
            ></app-text-area>
          </div>
        </div>
      </form>
      <ng-template #modalFooter let-activeModal>
        <div class="col-12 text-center">
          <app-button
            color="SECONDARY"
            (onClick)="activeModal.dismiss()"
            class="mr-1"
          >
            {{ 'app.button.close' | translate }}
          </app-button>
          <app-button color="PRIMARY" (onClick)="doSave()">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </ng-template>
    </div>
  </div>
</app-popup>
