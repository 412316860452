import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorEquipmentHistory } from '../../core/bean/vendor-equipment-history';
@Component({
  templateUrl: './app-popup-tab-vendor-equipment.component.html'
})
export class AppPopupTabVendorEquipmentComponent extends BaseModuleComponent {
  @Input() header: string;
  @Input() vendorEquipmentHistory: VendorEquipmentHistory;
  constructor(translate: TranslateService) {
    super('app-popup-tab-vendor-equipment', translate);
  }

  public onInit(): void {
    this.setStateReady();
  }
}
