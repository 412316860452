import { CatalogBulkPrice } from 'src/app/core/bean/catalog-bulk-price';
import { CatalogContract } from 'src/app/core/bean/catalog-contract';
import { CatalogRegion } from 'src/app/core/bean/catalog-region';
import { CatalogRegionBulkPrice } from 'src/app/core/bean/catalog-region-bulk-price';
import { CatalogShippingCost } from 'src/app/core/bean/catalog-shipping-cost';
import { DistributionRegion } from 'src/app/core/bean/distribution-region';
import { VendorAddress } from 'src/app/core/bean/vendor-address';
import { WebContent } from 'src/app/core/bean/web-content';
import { BaseDTO } from '../../../core/base/base-dto';
import { Catalog } from '../../../core/bean/catalog';
import { CatalogAttribute } from '../../../core/bean/catalog-attribute';
import { CatalogAttributeGroup } from '../../../core/bean/catalog-attribute-group';
import { CatalogCategory } from '../../../core/bean/catalog-category';
import { CatalogGroupTemplate } from '../../../core/bean/catalog-group-template';
import { CatalogImage } from '../../../core/bean/catalog-image';
import { CatalogInventory } from '../../../core/bean/catalog-inventory';
import { CatalogSpecification } from '../../../core/bean/catalog-specification';
import { CatalogVerification } from '../../../core/bean/catalog-verification';
import { Currency } from '../../../core/bean/currency';
import { EquipmentCondition } from '../../../core/bean/equipment-condition';
import { FormObject } from '../../../core/bean/form-object';
import { ManageCatalogTab } from '../../../core/bean/manage-catalog-tab';
import { Region } from '../../../core/bean/region';
import { Uom } from '../../../core/bean/uom';
import { FileObject } from '../../../core/components/upload';
export class ManageCatalogDTO extends BaseDTO {
  manageCatalogTabList: ManageCatalogTab[] = [];
  catalog: Catalog = new Catalog();
  catalogCategoryList: CatalogCategory[] = [];
  catalogSpesificationList: CatalogSpecification[] = [];
  catalogImageList: CatalogImage[] = [];
  catalogInventoryList: CatalogInventory[] = [];
  catalogShippingCostList: CatalogShippingCost[] = [];
  catalogGroupTemplateList: CatalogGroupTemplate[] = [];
  catalogAttributeList: CatalogAttribute[] = [];
  catalogUomList: Uom[] = [];
  weightList: Uom[] = [];
  widthList: Uom[] = [];
  heightList: Uom[] = [];
  lengthList: Uom[] = [];
  equipmentConditionList: EquipmentCondition[] = [];
  currencyList: Currency[] = [];
  regionList: Region[] = [];
  regionShippingCostList: Region[] = [];
  catalogAttributeGroupList: CatalogAttributeGroup[] = [];
  fileUploadImage: FileObject[] = [];
  formObjectList: FormObject[] = [];
  catalogVerificationList: CatalogVerification[] = [];
  catalogContractList: CatalogContract[] = [];
  vendorAddressList: VendorAddress[] = [];
  catalogRegionBulkPriceList: CatalogRegionBulkPrice[] = [];
  viewIsOther: boolean;
  distributionRegionList: DistributionRegion[] = [];
  provinceRegionList: Region[] = [];
  catalogRegionList: CatalogRegion[] = [];
  catalogBulkPriceList: CatalogBulkPrice[] = [];
  content: WebContent;
  catalogAtributeParam: Object;
  vendorAddress: VendorAddress;
  updatedProvinceRegionList: Region[] = [];
}
