import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ProcurementDocTypeEditAddComponent } from './procurement-doc-type-edit-add.component';
import { ProcurementDocTypeComponent } from './procurement-doc-type.component';
export const routes = [
  {
    path: '',
    component: ProcurementDocTypeComponent,
    data: { breadcrumb: 'procurement-doc-type.breadcrumb.index' }
  },
  {
    path: 'add',
    component: ProcurementDocTypeEditAddComponent,
    data: { breadcrumb: 'procurement-doc-type.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: ProcurementDocTypeEditAddComponent,
    data: { breadcrumb: 'procurement-doc-type.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [
    ProcurementDocTypeComponent,
    ProcurementDocTypeEditAddComponent
  ]
})
export class ProcurementDocTypeModule {}
