import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { Global } from '../../../core/global/global';
import { PromiseThemeService } from '../promise-theme.service';
@Component({
  selector: 'theme-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent {
  public isOffline: boolean;
  constructor(
    public global: Global,
    public promiseThemeService: PromiseThemeService
  ) {}

  public onMouseEnterOnSidebar(): void {
    this.changeSidebar('FULL');
  }

  public onMouseLeaveFromSidebar(): void {
    this.changeSidebar('SMALL');
  }

  public changeSidebar(width: 'FULL' | 'SMALL'): void {
    const dataTheme = this.promiseThemeService.getDataTheme();
    let sidebarWidth = dataTheme;
    if (width === 'FULL') {
      sidebarWidth = dataTheme.replace(
        this.promiseThemeService.SIDEBAR_SMALL_WIDTH,
        this.promiseThemeService.SIDEBAR_FULL_WIDTH
      );
    } else {
      sidebarWidth = dataTheme.replace(
        this.promiseThemeService.SIDEBAR_FULL_WIDTH,
        this.promiseThemeService.SIDEBAR_SMALL_WIDTH
      );
    }
    dataTheme !== sidebarWidth &&
      this.promiseThemeService
        .getHtmlElement()
        .setAttribute(this.promiseThemeService.DATA_THEME_NAME, sidebarWidth);
  }

  @HostListener('window:online')
  onWindowOnline(): void {
    this.promiseThemeService.changeSidebarWidth('SMALL');
    this.isOffline = false;
  }

  @HostListener('window:offline')
  onWindowOffline(): void {
    this.promiseThemeService.changeSidebarWidth('NONE');
    this.isOffline = true;
  }

}
