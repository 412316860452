import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Contract } from '../../core/bean/contract';
import { ContractReviewer } from '../../core/bean/contract-reviewer';
import { ContractDraftParameterValue } from '../../core/bean/contract-draft-parameter-value';
import { File } from '../../core/bean/file';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { WorkflowPrcs } from '../../core/bean/workflow-prcs';
import { ApprovalPathModel } from '../../core/components/app-approval-path';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { ContractFinalizationDTO } from './dto/contract-finalization.dto';

@Component({
  templateUrl: './contract-finalization-edit-add.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None
})
export class ContractFinalizationEditAddComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  @ViewChild('selectorHistoryApproval') tableHistoryApproval: AppTableComponent;

  public url: string;
  public objectId: number;
  public contractId: number;
  public workflowModelPrcsId: number;
  public procurementVendorId: number;
  public isLoading = true;
  public isShowParameter = false;
  public isAllUploaded: Boolean = false;
  public latestContractDocument: File;
  public contractDraftParameterValueList: ContractDraftParameterValue[] = [];
  public contract: Contract = new Contract();
  public workflowPrcs: WorkflowPrcs = new WorkflowPrcs();
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public contractFinalizationDTO: ContractFinalizationDTO =
    new ContractFinalizationDTO();
  public vendorBankAccountList: OptionListModel<any> = new OptionListModel(
    false,
    'accountNumber'
  );
  public approvalPath: ApprovalPathModel<any> = new ApprovalPathModel(
    this.moduleCode,
    'READ'
  );
  public tableResponseHistoryApproval: TableResponseModel<ContractReviewer>;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('contract-finalization', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    const todoLocalStorage = localStorage.getItem('todo');
    if (todoLocalStorage) {
      this.todo = todoLocalStorage;
    } else {
      this.todo = this.global.routerParams.get('todo');
    }

    this.contract = this.global.routerParams.get('contract');
    this.objectId = this.global.routerParams.get('objectId');
    this.contractId = this.global.routerParams.get('contractId');
    this.procurementVendorId = this.contract.procurementVendor.id;

    if (this.objectId) {
      this.contractId = this.objectId;
    }
    if (this.contract != null) {
      this.contractId = this.contract.id;
      this.workflowPrcs = this.contract.workflowPrcs;
      this.workflowModelPrcs = this.contract.workflowModelPrcs;
      this.workflowModelPrcsId = this.contract.workflowModelPrcs.id;
    }
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildTableResponse(): void {
    this.tableResponseHistoryApproval = new TableResponseModel(
      this.moduleCode,
      [
        { field: 'user', header: 'table.column.user' },
        { field: 'description', header: 'table.column.description' },
        { field: 'date', header: 'table.column.date' },
        { field: 'file', header: 'table.column.file' },
        { field: 'level', header: 'table.column.level' },
        { field: 'status', header: 'table.column.status' }
      ]
    );
  }

  public buildFormGroup(): void {
    const contractTemplateParam = this.formBuilder.array([]);
    this.formGroup = this.formBuilder.group({
      id: [null],
      today: [new Date()],
      number: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(64)])
      ],
      startDate: [null, Validators.required()],
      endDate: [null, Validators.required()],
      vendorAccountNumber: [null, Validators.required()],
      bankName: [null],
      accountHolder: [null],
      branchName: [null],
      contractTemplateParamList: contractTemplateParam
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<ContractFinalizationDTO>(
        '/contract-finalization/add-edit/' + this.contractId
      )
      .subscribe(contractFinalizationDTO => {
        this.contractFinalizationDTO = contractFinalizationDTO;
        this.latestContractDocument =
          contractFinalizationDTO.latestContractDocument;
        this.vendorBankAccountList.setRequestValues(
          this.contractFinalizationDTO.vendorBankList
        );

        if (contractFinalizationDTO.approvalPathResponseModel) {
          this.approvalPath
            .setApproverList(
              contractFinalizationDTO.approvalPathResponseModel.userApproverList
            )
            .setType(contractFinalizationDTO.approvalPathResponseModel.type);
        }
        // set isView = true
        this.formGroup.get('bankName')['isView'] = true;
        this.formGroup.get('accountHolder')['isView'] = true;
        this.formGroup.get('branchName')['isView'] = true;

        if (
          this.contractFinalizationDTO.contract.contractType.code ===
          this.global.appConstant.cm.CONTRACT_TYPE_ADDENDUM_CONTRACT
        ) {
          this.formGroup.get('number')['isView'] = true;
        }

        let data = localStorage.getItem('temp') as any;
        if (data) {
          const startDate = new Date(data.startDate);
          const endDate = new Date(data.endDate);
          data = JSON.parse(data.toString());

          this.formGroup.patchValue(data);
          this.formGroup.patchValue({
            startDate,
            endDate
          });
          localStorage.removeItem('temp');
          localStorage.removeItem('todo');
        } else if (
          this.contractFinalizationDTO !== null &&
          this.contractFinalizationDTO.contract !== null &&
          this.todo === 'view'
        ) {
          const startDate = new Date(
            this.contractFinalizationDTO.contract.startDate
          );
          const endDate = new Date(
            this.contractFinalizationDTO.contract.endDate
          );
          this.formGroup.patchValue({
            id: this.contractFinalizationDTO.contract.id,
            number: this.contractFinalizationDTO.contract.number,
            startDate,
            endDate,
            vendorAccountNumber:
              this.contractFinalizationDTO.vendorBank ? this.contractFinalizationDTO.vendorBank.accountNumber : null,
            bankName: this.contractFinalizationDTO.vendorBank ? this.contractFinalizationDTO.vendorBank.bank.name : null,
            accountHolder:
              this.contractFinalizationDTO.vendorBank ? this.contractFinalizationDTO.vendorBank.accountHolder : null,
            branchName: this.contractFinalizationDTO.vendorBank ? this.contractFinalizationDTO.vendorBank.branch : null
          });
        } else {
          let vendorBankPriority = null;
          this.contractFinalizationDTO.vendorBankList.forEach(vendorBank => {
            if (vendorBank.isPriority) {
              vendorBankPriority = vendorBank;
              this.formGroup.patchValue({
                vendorAccountNumber: vendorBank,
                bankName: vendorBank.bank.name,
                accountHolder: vendorBank.accountHolder,
                branchName: vendorBank.branch
              });
            }
          });
          if (!vendorBankPriority) {
            const vendorBank = this.contractFinalizationDTO.vendorBankList[0];
            this.formGroup.patchValue({
              vendorAccountNumber: vendorBank,
              bankName: vendorBank.bank.name,
              accountHolder: vendorBank.accountHolder,
              branchName: vendorBank.branch
            });
          }
          this.formGroup.patchValue({
            id: this.contractFinalizationDTO.contract.id,
            number: this.contractFinalizationDTO.contract.number
          });
        }
        this.setStateReady();
      });
    // tslint:disable-next-line:align
  }

  public get contractTemplateParamList(): FormArray {
    return this.formGroup.get('contractTemplateParamList') as FormArray;
  }

  public doNext(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .nextConfirm(
          this.translateService.instant('contract-finalization.msg.next'),
          'Next Confirmation',
          'app.button.no',
          'app.button.yes'
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            const contractNumber = this.formGroup.get('number').value;
            const contractId = this.formGroup.get('id').value;
            this.httpClientService
              .get<ContractDraftParameterValue>(
                '/contract-finalization/number-exist?id=' +
                  contractId +
                  '&number=' +
                  contractNumber
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.isLoading = true;
                  this.httpClientService
                    .get<ContractDraftParameterValue>(
                      '/contract-finalization/contract-parameter/' +
                        this.contract.id
                    )
                    .subscribe(contractDraftParameterValueList => {
                      if (
                        contractDraftParameterValueList &&
                        contractDraftParameterValueList.length !== 0
                      ) {
                        this.contractDraftParameterValueList =
                          contractDraftParameterValueList;
                        contractDraftParameterValueList.forEach(
                          contractDraftParamValue => {
                            const formGroup = this.formBuilder.group({
                              code: contractDraftParamValue
                                .contractTemplateParameter.contractParameter
                                .code,
                              name: contractDraftParamValue
                                .contractTemplateParameter.contractParameter
                                .name,
                              value: [
                                contractDraftParamValue
                                  .contractTemplateParameter.contractParameter
                                  .valueType.code === 'DATE_FORMAT_SHORT' ||
                                contractDraftParamValue
                                  .contractTemplateParameter.contractParameter
                                  .valueType.code === 'DATE_FORMAT_LONG'
                                  ? new Date(contractDraftParamValue.value)
                                  : contractDraftParamValue
                                      .contractTemplateParameter
                                      .contractParameter.code ===
                                    this.global.appConstant.cm
                                      .CONTRACT_PARAMETER_NOMOR_KONTRAK
                                  ? contractNumber
                                  : contractDraftParamValue.value,
                                !contractDraftParamValue.contractTemplateParameter.contractParameter.code.includes(
                                  'PERUBAHAN'
                                )
                                  ? Validators.required()
                                  : null
                              ],
                              valueTypeCode:
                                contractDraftParamValue
                                  .contractTemplateParameter.contractParameter
                                  .valueType.code,
                              contractTemplateParameter:
                                contractDraftParamValue.contractTemplateParameter
                            });
                            if (
                              this.contract.contractType.code ===
                                this.global.appConstant.cm
                                  .CONTRACT_TYPE_ADDENDUM_CONTRACT &&
                              contractDraftParamValue.contractTemplateParameter
                                .contractParameter.code ===
                                this.global.appConstant.cm
                                  .CONTRACT_PARAMETER_NOMOR_KONTRAK
                            ) {
                              formGroup.get('value').setIsView(true);
                            }
                            this.contractTemplateParamList.push(formGroup);
                          }
                        );
                      }
                      this.isLoading = false;
                      this.isShowParameter = true;
                    });
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          } else {
            this.doSaveAll();
          }
        });
    }
  }

  public doSave(): void {
    this.setStateProcessing();
    // tslint:disable-next-line:variable-name
    this.contractFinalizationDTO.contract.number =
      this.formGroup.get('number').value;
    this.contractFinalizationDTO.contract.startDate =
      this.formGroup.get('startDate').value;
    this.contractFinalizationDTO.contract.endDate =
      this.formGroup.get('endDate').value;
    this.contractFinalizationDTO.vendorBank = this.formGroup.get(
      'vendorAccountNumber'
    ).value;

    this.httpClientService
      .post<Response<ContractFinalizationDTO>>(
        '/contract-finalization/insert',
        this.contractFinalizationDTO
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.router.navigate(['/pages/worklist-cm']);
          this.global.alertService.showSuccessSavingOnNextRoute();
        } else {
          this.setStateReady();
          this.global.alertService.showError(response.statusText);
        }
      });
  }

  public doSaveAll(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            // tslint:disable-next-line:variable-name
            this.contractFinalizationDTO.contract.number =
              this.formGroup.get('number').value;
            this.contractFinalizationDTO.contract.startDate =
              this.formGroup.get('startDate').value;
            this.contractFinalizationDTO.contract.endDate =
              this.formGroup.get('endDate').value;
            this.contractFinalizationDTO.vendorBank = this.formGroup.get(
              'vendorAccountNumber'
            ).value;

            const contractTemplateParamList =
              this.formGroup.value.contractTemplateParamList;
            // const contractDraftParamValueList: ContractDraftParameterValue[] = [];
            if (contractTemplateParamList) {
              for (let i = 0; i < contractTemplateParamList.length; i++) {
                this.contractDraftParameterValueList[i].value =
                  contractTemplateParamList[i].value;
                this.contractDraftParameterValueList[
                  i
                ].contractTemplateParameter =
                  contractTemplateParamList[i].contractTemplateParameter;
                if (
                  contractTemplateParamList[i].code ===
                  this.global.appConstant.cm.CONTRACT_PARAMETER_NOMOR_KONTRAK
                ) {
                  this.contractFinalizationDTO.contract.number =
                    contractTemplateParamList[i].value;
                }
              }
            }

            this.contractFinalizationDTO.contractDraftParameterValueList =
              this.contractDraftParameterValueList;

            this.httpClientService
              .post<Response<ContractFinalizationDTO>>(
                '/contract-finalization/insert',
                this.contractFinalizationDTO
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/worklist-cm']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doOnChange(event: any): void {
    if (event) {
      this.formGroup.patchValue({
        bankName: event.bank?.name,
        accountHolder: event.accountHolder,
        branchName: event.branch
      });
    } else {
      this.formGroup.patchValue({
        bankName: '-',
        accountHolder: '-',
        branchName: '-'
      });
    }
  }

  public onChangeFileUpload(fileUploader: FileUploader): void {
    let isAllUploaded: Boolean = true;
    if (!fileUploader.isAllUploaded) {
      isAllUploaded = fileUploader.isAllUploaded;
    }
    this.isAllUploaded = isAllUploaded;
  }
  public doBack(): void {
    this.router.navigate(['/pages/worklist-cm/']);
  }
}
