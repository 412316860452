import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipe/pipe.module';
import { SharedModule } from '../../shared/shared.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppBadgeCatalogModule } from '../app-badge-catalog/app-badge-catalog.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppComboBoxModule } from '../app-combo-box/app-combo-box.module';
import { AppDropdownSelectModule } from '../app-dropdown-select/app-dropdown-select.module';
import { AppFullfilledModule } from '../app-fullfilled/app-fullfilled.module';
import { AppImageSlideshowModule } from '../app-image-slideshow/app-image-slideshow.module';
import { AppTableModule } from '../table/components/app-table/app-table.module';
import { AppItemRequestComponent } from './app-item-request.component';
import { AppPopupItemEditComponent } from './app-popup-item-edit.component';

@NgModule({
  imports: [
    SharedModule,
    VendorModule,
    AppTableModule,
    AppCardModule,
    AppComboBoxModule,
    PipesModule,
    AppButtonModule,
    AppDropdownSelectModule,
    AppFullfilledModule,
    AppImageSlideshowModule,
    AppBadgeCatalogModule
  ],
  declarations: [AppItemRequestComponent, AppPopupItemEditComponent],
  exports: [AppItemRequestComponent, AppPopupItemEditComponent],
  entryComponents: [AppPopupItemEditComponent]
})
export class AppItemRequestModule {}
