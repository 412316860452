import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupDeliveryMonitoringComponent } from './app-popup-delivery-monitoring.component';
import { AppPopupRequestClosedContractComponent } from './app-popup-request-closed-contract.component';
import { DeliveryMonitoringEditAddComponent } from './delivery-monitoring-edit-add.component';
import { DeliveryMonitoringComponent } from './delivery-monitoring.component';

export const routes = [
  {
    path: '',
    component: DeliveryMonitoringComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'detail',
    component: DeliveryMonitoringEditAddComponent,
    data: { breadcrumb: 'contract-monitoring.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, NgbModule],
  declarations: [
    DeliveryMonitoringComponent,
    DeliveryMonitoringEditAddComponent,
    AppPopupDeliveryMonitoringComponent,
    AppPopupRequestClosedContractComponent
  ]
})
export class DeliveryMonitoringModule {}
