<dashboard-widget-card
  size="XS"
  [isLoading]="model.isLoading"
  header="{{ 'dashboard-widget-performance-overall.title' | translate }}"
  customClassBody="mt-0"
  customClassHeader="border-bottom-0 p-0"
>
  <div style="font-size: xx-large; margin-top: 3px">
    <app-rating
      size="XL"
      [value]="performanceOverall"
      [isViewOnly]="true"
    ></app-rating>
  </div>
</dashboard-widget-card>
