import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { TableVendorResponseModel } from './core/components/app-table-vendor/model/table-vendor-response-model';
@Component({
  templateUrl: './view-vendor.component.html',
  styleUrls: ['./view-vendor.component.scss']
})
export class ViewVendorComponent extends BaseModuleComponent {
  public tableVendorResponse: TableVendorResponseModel<any> = new TableVendorResponseModel('/view-vendor/index');
  constructor(translate: TranslateService) {
    super('app-view-vendor', translate);
  }

  public onInit(): void {
    this.setStateReady();
  }

  public onChooseVendor(vendorId: number): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('vendorId', vendorId);
    this.router.navigate(['/pages/view-vendor/detail/']);
  }
}
