import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { FileUploader } from 'src/app/core/components/upload';
import { FinesAdjustment } from '../../core/bean/fines-adjustment';
import { Validators } from '../../core/validators';
import { FinesMonitoringRequest } from './request/fines-monitoring.request';

@Component({
  templateUrl: './app-popup-fines-monitoring-adjustment.component.html'
})
export class AppPopupFinesMonitoringAdjustmentComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() todo: string;
  @Input() finesAdjustment: FinesAdjustment;
  @Output()
  public onChange: EventEmitter<FinesMonitoringRequest> = new EventEmitter();
  public isAllUploaded = false;
  public value: number;
  public percentage: number;
  public percentageValue: string;
  public CURRENCY_DIGITS_INFO: string;
  public fileUploader: FileUploader = new FileUploader(
    '/fines-monitoring/',
    'fines-monitoring.title',
    this.global.appConstant.fileType.DOC_FINES_ADJUSTMENT
  );

  constructor(
    public translateService: TranslateService,
    public currency: CurrencyPipe
  ) {
    super('fines-monitoring');
  }

  onInit(): void {
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
    this.setStateReady();
    this.onChangeWorned();
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      duration: [null, Validators.compose([Validators.required()])],
      uploadFile: [null],
      description: [null],
      isWorn: [null]
    });
  }

  public setFormGroup(): void {
    if (!this.value) {
      this.value = 0;
    }
    if (this.finesAdjustment) {
      const finesAdjustment = this.finesAdjustment;
      const fileList = [];
      if (this.finesAdjustment.file) {
        fileList.push(this.finesAdjustment.file);
        this.fileUploader.setFileList(fileList);
      }

      this.value = finesAdjustment.amount;
      this.percentage = finesAdjustment.percentageValue;
      this.percentageValue = this.currency.transform(
        this.finesAdjustment.procurementFines.percentageValue,
        '',
        '',
        this.CURRENCY_DIGITS_INFO
      );
      this.formGroup.patchValue({
        uploadFile: this.fileUploader.fileObjectList,
        isWorn:
          finesAdjustment.isWorn == null ||
          (finesAdjustment.isWorn != null && finesAdjustment.isWorn)
            ? false
            : true,
        duration: finesAdjustment.duration,
        description: finesAdjustment.description
      });
      if (finesAdjustment.duration) {
        this.onChangeDuration();
      }
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      const duration = this.formGroup.value.duration;
      this.percentage = duration ? 0.1 * duration : 0;
      if (
        this.percentage > this.finesAdjustment.procurementFines.percentageValue
      ) {
        this.percentage = this.finesAdjustment.procurementFines.percentageValue;
      }

      this.finesAdjustment.isWorn = this.formGroup.value.isWorn ? false : true;
      this.finesAdjustment.duration = this.formGroup.value.duration;
      this.finesAdjustment.description = this.formGroup.value.description;
      this.finesAdjustment.file = this.formGroup.value.uploadFile[0]
        ? !this.formGroup.value.uploadFile[0].isDeleted
          ? this.formGroup.value.uploadFile[0].file
          : null
        : null;
      this.finesAdjustment.user = this.global.userSession.user;
      this.finesAdjustment.amount = this.value;
      this.finesAdjustment.percentageValue = this.percentage;
      this.finesAdjustment.isAdjusted = true;
      this.finesAdjustment.fileObjectList = this.formGroup.value.uploadFile;

      const finesMonitoringRequest = new FinesMonitoringRequest();
      finesMonitoringRequest.finesAdjustmentList[0] = this.finesAdjustment;
      this.onChange.emit(finesMonitoringRequest);
    }
  }

  public onChangeFileUpload(fileUploader: FileUploader): void {
    let isAllUploaded = true;
    if (!fileUploader.isAllUploaded) {
      isAllUploaded = fileUploader.isAllUploaded;
    }
    this.isAllUploaded = isAllUploaded;
  }

  public onChangeDuration(): void {
    this.percentage = 0.1 * this.formGroup.value.duration;
    if (
      this.percentage > this.finesAdjustment.procurementFines.percentageValue
    ) {
      this.percentage = this.finesAdjustment.procurementFines.percentageValue;
    }
    this.value =
      (this.finesAdjustment.procurementFines.procurementScopeWork
        .procurementVendor.amount *
        this.percentage) /
      100;

    // this.value = +this.global.converterService.convertMoney(this.value);
  }

  public onChangeWorned(): void {
    if (this.formGroup.value.isWorn) {
      this.value = 0.0;
      this.formGroup.value.duration = 0;
      this.percentage = 0;
      this.formGroup.get('duration').disable();
    } else {
      this.formGroup.get('duration').enable();
    }
  }
}
