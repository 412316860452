import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { CatalogAttributeGroup } from '../../core/bean/catalog-attribute-group';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './catalog-attribute-group.component.html',
  styles: [
    '.width-name { min-width : 250px; white-space: normal ! important; text-align: left }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class CatalogAttributeGroupComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<CatalogAttributeGroup>;

  @ViewChild(AppTableComponent) catalogAttributeGroupTable: AppTableComponent;
  constructor(translateService: TranslateService) {
    super('catalog-attribute-group', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    const activeText = this.translateService.instant(
      'catalog-attribute-group.status.active'
    );
    const notActiveText = this.translateService.instant(
      'catalog-attribute-group.status.notActive'
    );

    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name', customClass: 'width-name' },
      {
        field: 'description',
        header: 'table.column.description',
        customClass: 'white-space-normal'
      },
      {
        field: 'isActive',
        header: 'table.column.status',
        datamap:
          '{"true" : "' +
          activeText +
          '" , "false" : "' +
          notActiveText +
          '" }',
        customClass: 'text-center'
      }
    ]);
  }

  public doEdit(catalogAttributeGroup: CatalogAttributeGroup): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set(
      'catalogAttributeGroupId',
      catalogAttributeGroup.id
    );
    this.router.navigate(['/pages/catalog-attribute-group/edit']);
  }

  public doDelete(catalogAttributeGroup: CatalogAttributeGroup): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.deleteCatalogAttributeGroupFromServer(
            '/catalog-attribute-group/delete',
            catalogAttributeGroup
          ).subscribe(response => {
            if (response.status === ResponseStatusModel.OK) {
              this.global.alertService.showSuccessDelete();
              this.catalogAttributeGroupTable.reload();
            } else {
              this.global.alertService.showError(response.statusText);
              this.setStateReady();
            }
          });
        }
      });
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/catalog-attribute-group/add']);
  }

  public doImport(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'import');
    this.router.navigate(['/pages/catalog-attribute-group/import']);
  }

  public deleteCatalogAttributeGroupFromServer(
    urlDeleteCatalogGroupAttribute: string,
    catalogAttributeGroup: CatalogAttributeGroup
  ): Observable<Response<CatalogAttributeGroup>> {
    return this.httpClientService.post<Response<CatalogAttributeGroup>>(
      urlDeleteCatalogGroupAttribute,
      catalogAttributeGroup
    );
  }
}
