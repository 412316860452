import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { User } from '../../core/bean/user';
import { UserExecutor } from '../../core/bean/user-executor';
import { Verificator } from '../../core/bean/verificator';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { ExecutorResponse } from './executor-response';

@Component({
  templateUrl: './app-popup-add-user-executor.component.html'
})
export class AppPopupAddUserExecutorComponent extends BaseModuleComponent {
  @Input() verificator: Verificator;
  @Input() userExecutorList: UserExecutor[] = [];
  @Input() executorResponseTemp: ExecutorResponse;
  @Input() userList: User[];
  @Output() onChange: EventEmitter<UserExecutor[]> = new EventEmitter();
  public tableResponseUser: TableResponseModel<User>;
  public isNoUserSelected = false;
  public isUserHaveBeenSelected = false;

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public activeModal: NgbActiveModal
  ) {
    super('executor', translate);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }
  public doBuildTableResponse(): void {
    this.tableResponseUser = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.user-name'
      }
    ]);
    this.setTableResponseUserState();
  }
  private setTableResponseUserState(): void {
    this.tableResponseUser.setRecordList(this.userList);
    this.tableResponseUser.reload();
  }
  public doAddUserExecutor(): void {
    if (this.tableResponseUser.selectedRow.selectedRowList.length !== 0) {
      const addedUserList = this.tableResponseUser.selectedRow.selectedRowList;
      const verificator = this.verificator;
      addedUserList.forEach(newUser => {
        const user = newUser.record;
        const newUserExecutor = new UserExecutor();
        newUserExecutor.user = user;
        newUserExecutor.verificator = verificator;
        newUserExecutor.crudOperation = this.global.appConstant.core.CRUD_OPERATION_INSERT;
        this.userExecutorList.push(newUserExecutor);
        const index = this.userList.findIndex(
          i => JSON.stringify(i) === JSON.stringify(user)
        );
        if (index !== -1) {
          this.userList.splice(index, 1);
        }
      });
      this.onChange.emit(this.userExecutorList);
      this.activeModal.dismiss();
    } else {
      this.isNoUserSelected = true;
    }
  }
  public doValidate(): void {
    if (this.tableResponseUser.selectedRow['isSelectAll'] !== undefined) {
      if (this.tableResponseUser.selectedRow.selectedRowList.length === 0 && this.tableResponseUser.selectedRow.unSelectedRowList.length === 0 ) {
        if (this.tableResponseUser.selectedRow['isSelectAll'] === false) {
          this.isNoUserSelected = true;
        } else if (this.tableResponseUser.selectedRow['isSelectAll'] === true) {
          this.isNoUserSelected = false;
        }
      } else {
        this.isNoUserSelected = false;
      }
    } else if (this.tableResponseUser.selectedRow.selectedRowList.length !== 0) {
      this.isNoUserSelected = false;
      this.isUserHaveBeenSelected = true;
    } else if (this.isUserHaveBeenSelected) {
      this.isNoUserSelected = true;
    }
  }
}
