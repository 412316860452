<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="admin-panel.title">
      <app-tab>
        <app-tab-item header="admin-panel.tab.info"> </app-tab-item>
        <app-tab-item header="admin-panel.tab.tools">
          <br />
          <div class="table-responsive custom-table">
            <table class="table table-striped" aria-describedby="Table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    [ngStyle]="{ width: '70%' }"
                    class="text-center"
                    >{{ 'admin-panel.column.description' | translate }}</th
                  >
                  <th
                    scope="col"
                    [ngStyle]="{ width: '30%' }"
                    class="text-center"
                    >{{ 'admin-panel.column.action' | translate }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{
                    'admin-panel.description.reloadAction' | translate
                  }}</td>
                  <td class="text-center">
                    <app-button
                      (onClick)="doReloadAction()"
                      mode="BYPASS"
                      title="{{
                        'admin-panel.tooltip.reloadAction' | translate
                      }}"
                      [disabled]="isLoading"
                    >
                      <em
                        class="fas"
                        [ngClass]="
                          !isLoading ? 'fa-sync-alt' : 'fa-sync-alt fa-spin'
                        "
                      ></em>
                    </app-button>
                  </td>
                </tr>
                <tr>
                  <td>{{
                    'admin-panel.description.clearCache' | translate
                  }}</td>
                  <td class="text-center">
                    <app-button
                      (onClick)="doClearCache()"
                      mode="BYPASS"
                      [disabled]="isLoading"
                      title="{{ 'admin-panel.tooltip.clearCache' | translate }}"
                    >
                      <em class="fa fa-eraser"></em>
                    </app-button>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'admin-panel.description.logoutAll' | translate }}</td>
                  <td class="text-center">
                    <app-button
                      (onClick)="doLogoutAll()"
                      mode="BYPASS"
                      [disabled]="isLoading"
                      title="{{ 'admin-panel.tooltip.logoutAll' | translate }}"
                    >
                      <em class="fas fa-sign-out-alt"></em>
                    </app-button>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'admin-panel.description.clearSpringCache' | translate }}</td>
                  <td class="text-center">
                    <app-button
                      (onClick)="doClearSpringCache()"
                      mode="BYPASS"
                      [disabled]="isLoading"
                      title="{{ 'admin-panel.tooltip.clearSpringCache' | translate }}"
                    >
                      <em class="fa fa-eraser"></em>
                    </app-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-tab-item>
        <app-tab-item header="admin-panel.tab.broadcastMessage">
          <br />
          <form class="form-horizontal" [formGroup]="formGroup" novalidate>
            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'admin-panel.form.message' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-area
                  formControlName="message"
                  maxLength="512"
                ></app-text-area>
              </div>
            </div>
            <div class="button-group button-group-center">
              <app-button
                class="col-sm-12 text-center"
                (onClick)="doSave()"
                [disabled]="formSaving"
              >
                {{ 'app.button.save' | translate }}
              </app-button>
            </div>
          </form>
          <app-table
            [model]="tableResponse"
            [isServerSide]="true"
            stringUrl="/admin-panel/history-broadcast-message"
          >
            <ng-template #actionButtons let-rowData>
              <app-button
                color="SUCCESS"
                *ngIf="rowData.isActive"
                size="TN"
                (onClick)="doUpdate(rowData)"
                title="{{ 'app.tooltip.on' | translate }}"
              >
                <em class="fas fa-power-off"></em>
              </app-button>
              <app-button
                color="SECONDARY"
                *ngIf="!rowData.isActive"
                size="TN"
                (onClick)="doUpdate(rowData)"
                title="{{ 'app.tooltip.off' | translate }}"
              >
                <em class="fas fa-power-off"></em>
              </app-button>
            </ng-template>
          </app-table>
        </app-tab-item>
      </app-tab>
    </app-card>
  </div>
</div>
