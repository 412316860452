import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Region } from '../../core/bean/region';
import { VendorEntity } from '../../core/bean/vendor-entity';
import { VendorType } from '../../core/bean/vendor-type';
import { FileUploader } from '../../core/components/upload';
import { TabModel } from '../../core/model/tab/tab-model';
import { CompanyProfileServie } from '../company-profile/company-profile.service';
import { CompanyProfileDTO } from '../company-profile/dto/company-profile.dto';
import { TabProfileMarketingDTO } from './dto/tab-profile-marketing.dto';

@Component({
  templateUrl: './tab-profile-marketing.component.html'
})
export class TabProfileMarketingComponent extends BaseModuleComponent {
  public userId: number;
  public companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public tabProfileMarketingDTO: TabProfileMarketingDTO =
    new TabProfileMarketingDTO();
  public dataMarketing: any;
  public now = new Date().getTime();
  public sessionId = null;
  public vendorTypeList: VendorType[] = [];
  public regionList: Region[] = [];
  public vendorEntityList: VendorEntity[] = [];
  public tabMarketing: TabModel;
  public isView: boolean;
  public isVendorList: boolean;
  public fromOutside: string;
  public roleType: number;

  public fileUploaderLogo: FileUploader = new FileUploader(
    '/tab-profile-marketing/',
    'marketing.logo',
    this.global.appConstant.fileType.IMG_LOGO_VENDOR
  );
  public fileUploaderBanner: FileUploader = new FileUploader(
    '/tab-profile-marketing/',
    'marketing.banner',
    this.global.appConstant.fileType.IMG_BANNER_VENDOR
  );
  public fileUploaderCompanyProfile: FileUploader = new FileUploader(
    '/tab-profile-marketing/',
    'marketing.companyProfile',
    this.global.appConstant.fileType.DOC_COMPANY_PROFILE
  );

  constructor(
    translate: TranslateService,
    public companyProfileService: CompanyProfileServie
  ) {
    super('tab-profile-marketing', translate);
  }

  onInit(): void {
    this.roleType = this.global.userSession.activeUserRole.role.type;
    this.getDataFromRouterParams();
    if (this.tabMarketing.routerParams.get('tabProfileMarketingDTO')) {
      this.tabProfileMarketingDTO = this.tabMarketing.routerParams.get(
        'tabProfileMarketingDTO'
      );
      this.setFormGroup();
    } else {
      this.getDataFromServer();
    }
  }

  public getDataFromServer(): void {
    // tslint:disable-next-line: max-line-length
    this.httpClientService
      .get<TabProfileMarketingDTO>(
        this.global.userSession.activeUserRole.role.type !==
          this.global.appConstant.core.ROLE_TYPE_VENDOR
          ? '/tab-profile-marketing/index?id=' +
              this.companyProfileDTO.vendorHistory.id +
              '&todo=' +
              this.todo
          : '/tab-profile-marketing/index'
      )
      .subscribe(tabProfileMarketingDTO => {
        this.tabProfileMarketingDTO = tabProfileMarketingDTO;
        this.tabMarketing.routerParams.set(
          'tabProfileMarketingDTO',
          tabProfileMarketingDTO
        );
        this.buildFormGroup();
        this.setFormGroup();
        if (this.isView) {
          this.setViewOnly();
        }
      });
  }

  public getDataFromRouterParams(): void {
    this.tabMarketing = this.companyProfileService
      .getTabResponse()
      .tabs.get(this.global.appConstant.vm.VENDOR_TAB_MARKETING);
    this.isView = this.companyProfileService.getTabResponse().isView;
    this.isVendorList = this.global.routerParams.get('isVendorList');
    this.fromOutside = this.global.routerParams.get('urlBackOutside');
    this.todo = this.global.routerParams.get('todo');
    this.companyProfileDTO = this.global.routerParams.get('companyProfileDTO');
  }

  public buildFormGroup(): void {
    if (Object.keys(this.tabMarketing.formGroup.controls).length === 0) {
      this.tabMarketing.formGroup = this.formBuilder.group({
        logoFile: [null],
        bannerFile: [null],
        description: [null],
        facebook: [null],
        linkedin: [null],
        ig: [null],
        twitter: [null],
        companyProfileFile: [null],
        id: [null]
      });
    }
  }

  public setFormGroup(): void {
    if (
      Object.keys(this.tabMarketing.formGroup.controls).length === 0 ||
      this.tabMarketing.formGroup.controls.id.value === null
    ) {
      this.tabMarketing.setIsView(this.isView);
      const { facebook, linkedin, ig, description, twitter, id } =
        this.tabProfileMarketingDTO.vendorHistory;
      if (this.tabProfileMarketingDTO.vendorHistory.bannerFile !== null) {
        this.fileUploaderBanner.setFile(
          this.tabProfileMarketingDTO.vendorHistory.bannerFile
        );
        if (
          this.tabProfileMarketingDTO.bannerFileObjectList &&
          this.tabProfileMarketingDTO.bannerFileObjectList.length !== 0
        ) {
          this.fileUploaderBanner.fileObjectList =
            this.tabProfileMarketingDTO.bannerFileObjectList;
        }
        this.tabMarketing.formGroup.patchValue({
          bannerFile: this.fileUploaderBanner.fileObjectList
        });
      }
      if (this.tabProfileMarketingDTO.vendorHistory.logoFile !== null) {
        this.fileUploaderLogo.setFile(
          this.tabProfileMarketingDTO.vendorHistory.logoFile
        );
        if (
          this.tabProfileMarketingDTO.logoFileObjectList &&
          this.tabProfileMarketingDTO.logoFileObjectList.length !== 0
        ) {
          this.fileUploaderLogo.fileObjectList =
            this.tabProfileMarketingDTO.logoFileObjectList;
        }
        this.tabMarketing.formGroup.patchValue({
          logoFile: this.fileUploaderLogo.fileObjectList
        });
      }
      if (
        this.tabProfileMarketingDTO.vendorHistory.companyProfileFile !== null
      ) {
        this.fileUploaderCompanyProfile.setFile(
          this.tabProfileMarketingDTO.vendorHistory.companyProfileFile
        );
        if (
          this.tabProfileMarketingDTO.companyFileObjectList &&
          this.tabProfileMarketingDTO.companyFileObjectList.length !== 0
        ) {
          this.fileUploaderCompanyProfile.fileObjectList =
            this.tabProfileMarketingDTO.companyFileObjectList;
        }
        this.tabMarketing.formGroup.patchValue({
          companyProfileFile: this.fileUploaderCompanyProfile.fileObjectList
        });
      }
      this.tabMarketing.formGroup.patchValue({
        facebook,
        linkedin,
        ig,
        twitter,
        description,
        id
      });
    }
    this.setStateReady();
  }
}
