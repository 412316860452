import { BaseEntity } from '../base/base-entity';

export class SchedulerEntity extends BaseEntity {

    name: string;
    cron: string;
    lastActive: Date;
    className: string;
    isActive: boolean;

}
