import { createYearList } from '../helper/create-year-list-helper';
import { DatePickerState } from '../model';
export const setDatePickerYearList = (
  state: DatePickerState,
  payload: { action: 'BACK' | 'NEXT' }
): void => {
  const yearList = Array.from(state.yearList);
  const year = payload.action === 'BACK' ? yearList[0] - 1 : yearList.pop() + 1;
  state.yearList = createYearList(year);
};
