import { BaseEntity } from './../base/base-entity';
import { Module } from './../bean/module';
export class ScheduleStagesEntity extends BaseEntity {
  module: Module;
  isSchedule: boolean;
  isLocation: boolean;
  isStartDate: boolean;
  isEndDate: boolean;
  isStartTime: boolean;
  isEndTime: boolean;
}
