<app-layout [isLoading]="formLoading" [backTo]="urlBackOutside">
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge
      class="cml-2"
      *ngIf="
        orderStatus.code === global.appConstant.pm.ORDER_STATUS_WAITING_VERIFICATION
      "
      color="WARNING"
    >
      {{
        'dynamic-master-attribute.' + orderStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="orderStatus.code === global.appConstant.pm.ORDER_STATUS_REVISION_VERIFICATION"
      color="FEEDBACK"
    >
      {{
        'dynamic-master-attribute.' + orderStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="orderStatus.code === global.appConstant.pm.PR_STATUS_VERIFIED"
      color="INFO"
    >
      {{
        'dynamic-master-attribute.' + orderStatus.translationKey.key | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        orderStatus?.code === global.appConstant.pm.PR_STATUS_WAITING_APPROVAL
      "
      color="WARNING"
    >
      {{
        'dynamic-master-attribute.' + orderStatus.translationKey.key | translate
      }}
    </app-badge>
  </ng-template>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-card
          header="pr.detail.general.title"
          tips="pr.detail.general.tips"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'order.form.number' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ order.code }}
            </div>
          </div>
          <!-- <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'order.form.registeredOnDistributor' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ prPoVerificationResponse.organization.name }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'order.form.distributionLocationCode' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ prPoVerificationResponse.organization.name }}
            </div>
          </div> -->
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr.form.requestorDepartment' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ prPoVerificationResponse.organization.name }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr.form.requestor' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ prPoVerificationResponse.organization.name }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'order.form.title' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ order.name }}
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr.form.requiredDate' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{
                order.requiredDate
                  | date: global.appConstant.core.FORMAT_SHORT_DATE
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr.form.category' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              {{ order.procurementType.name }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'pr.form.description' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              {{ order.description }}
            </div>
          </div>
        </app-card>
        <app-card
          header="pr.detail.itemRequest.title"
          tips="pr.detail.itemRequest.tips"
        >
          <app-table-xx
            #selectorItem
            [model]="tableResponseItem"
            (onClick)="doViewItem($event)"
          >
            <ng-template #headerFilter>
              <form
                class="form-horizontal"
                [formGroup]="formGroup"
                novalidate
                *ngIf="!formLoading"
              >
                <div class="form-group row text-sm-left">
                  <div
                    class="col-sm-9 text-left"
                    [ngStyle]="{ 'min-width': '10rem' }"
                  >
                    <!-- <app-dropdown-select
                      size="XL"
                      [formControl]="itemType"
                      [optionList]="itemTypeOptionList"
                      (onChange)="onChangeItem()"
                      container="body"
                      placeholder="{{
                        'order.placeholder.type' | translate
                      }}"
                      [ngStyle]="{ 'min-width': '10rem' }"
                    >
                      <ng-template #value let-value>
                        {{ getTranslateKey(value) | translate }}
                      </ng-template>
                      <ng-template #option let-data>
                        {{ getTranslateKey(data) | translate }}
                      </ng-template></app-dropdown-select
                    > -->
                  </div>
                </div>
              </form>
            </ng-template>
            <ng-template #row let-state>
              <ng-container *ngIf="state.rowList.length > 0">
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'pr.form.goodsAmount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{ order.goodsAmount | resolveNumberToCurrency }}
                      {{ prPoVerificationResponse.companyCurrency.code }}
                    </strong>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'pr.form.serviceAmount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{ order.serviceAmount | resolveNumberToCurrency }}
                      {{ prPoVerificationResponse.companyCurrency.code }}
                    </strong>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'pr.form.amount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{ order.amount | resolveNumberToCurrency }}
                      {{ prPoVerificationResponse.companyCurrency.code }}
                    </strong>
                    <br />
                    <i>
                      ({{
                        order.amount | resolveNumberToWords: global.currentLang
                      }}
                      {{ prPoVerificationResponse.companyCurrency.name }})
                    </i>
                  </td>
                </tr>
              </ng-container>
            </ng-template>
          </app-table-xx>
        </app-card>
        <app-card
          header="order.detail.shippingAddress.title"
          tips="order.detail.shippingAddress.tips"
        >
          <app-select
            [optionList]="addressBookOptionList"
            formControlName="orderShipping"
            maxHeight="max-content"
          >
            <ng-template #content let-data>
              <div
                style="
                  border: 1px solid var(--gray-400);
                  border-radius: 5px;
                  box-sizing: border-box;
                  padding: 1rem;
                "
              >
              <p>{{ data.organization.name }}</p>
              <h5>{{ getUserNameList(data.addressPicList) }}</h5>
              <p>{{ data.addressDetail }}</p>
              <p>{{ data.phone }}</p>
              </div>
            </ng-template>
          </app-select>
        </app-card>
        <app-card
          header="order.detail.officialReport.title"
          tips="order.detail.officialReport.tips"
        >
          <app-official-report
            [objectId]="order?.id"
            [templateCode]="global.appConstant.pm.ORT_ORDER_PURCHASE_REQUEST_LETTER"
            [appOfficialReportModel]="appOfficialReportModel"
            mode="view"
            numberGeneratorCode="NUMBER"
            [isShowButtonGenerate]="false"
            [include]="['subject', 'priority']"
          ></app-official-report>
        </app-card>

        <app-card
          header="order.workDefinition.title"
          tips="order.workDefinition.tips"
        >
          <app-sow-x
            *ngIf="!formLoading && sowList && sowList.length > 0 && prItemList"
            [isView]="true"
            [sowList]="sowList"
            [prItemList]="prItemList"
            [amount]="order.amount"
            source="ORDER"
          ></app-sow-x>
          <br />
          <br />
        </app-card>

        <app-card
          *ngIf="!workloadPrcsId"
          header="order.detail.verification.title"
          tips="order.detail.verification.tips"
          [isSaving]="formSaving"
        >
          <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'order.form.status' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                formControlName="orderStatus"
                [optionList]="orderStatusOptionList"
              >
                <ng-template #value let-value>
                  <em
                    *ngIf="
                      value &&
                      value.code === global.appConstant.pm.ORDER_STATUS_VERIFIED_VERIFICATION
                    "
                    class="pic pi-thumbs-up-circle"
                  ></em>
                  <span
                    *ngIf="
                      value &&
                      value.code === global.appConstant.pm.ORDER_STATUS_VERIFIED_VERIFICATION
                    "
                    style="color: 'var(--success)'"
                  >
                    {{ value.name }}
                  </span>

                  <em
                    *ngIf="
                      value &&
                      value.code === global.appConstant.pm.ORDER_STATUS_REVISION_VERIFICATION
                    "
                    class="pic pi-pencil-circle"
                  ></em>
                  <span
                    style="color: 'var(--primary)'"
                    *ngIf="
                      value &&
                      value.code === global.appConstant.pm.ORDER_STATUS_REVISION_VERIFICATION
                    "
                  >
                    {{ value.name }}
                  </span>
                </ng-template>
              </app-dropdown-select>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">{{
              'order.form.note' | translate
            }}</label>
            <div class="col-sm-6 text-left">
              <app-text-area size="XL" formControlName="note" maxLength="255">
              </app-text-area>
            </div>
          </div>
        </app-card>

        <app-card
          header="order.detail.changeHistory.title"
          tips="order.detail.changeHistory.tips"
          *ngIf="
            orderStatus.code !=
            global.appConstant.pm.ORDER_STATUS_WAITING_VERIFICATION
          "
        >
          <app-change-history
            *ngIf="!formLoading"
            [moduleId]="prPoVerificationResponse.module?.id"
            [objectId]="prPoVerificationResponse.prPoVerification?.id"
          ></app-change-history>
        </app-card>
        <app-assignment-delegation
          *ngIf="workloadPrcsId"
          [workloadPrcsId]="workloadPrcsId"
        >
        </app-assignment-delegation>
        <div *ngIf="orderStatus.code ==
          global.appConstant.pm.ORDER_STATUS_WAITING_VERIFICATION"
          class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center">
                <app-button
                  *ngIf="!formGroup.isView"
                  (onClick)="doSubmit()"
                  [disabled]="formSaving"
                  >{{ 'app.button.submit' | translate }}</app-button
                >
              </div>
            </div>
          </div>
        </div>
        <app-approval-prcs-x
          *ngIf="!formLoading && prPoVerificationResponse?.isUserApproval"
          [workflowModelPrcs]="prPoVerificationResponse?.order?.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips
      *ngIf="!formLoading"
      [activeTipHeader]="
        workloadPrcsId
          ? 'app-assignment-delegation.detail.assignedUser.title'
          : 'order.detail.verification.title'
      "
    ></app-tips>
  </ng-template>
</app-layout>
