import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { AanwijzingParticipant } from 'src/app/core/bean/aanwijzing-participant';

@Component({
  templateUrl: './aanwijzing-others.component.html'
})
export class AanwijzingOthersComponent extends BaseModuleComponent implements OnInit {
  public encryptAanwijzingParticipantId: string;

  constructor(translateService: TranslateService, public activatedRoute: ActivatedRoute) {
    super('aanwijzing-others', translateService);
  }

  public onInit(): void {
    this.getRouterParam();
  }

  public getRouterParam(): void {
    this.activatedRoute.paramMap.subscribe(param => {
      this.encryptAanwijzingParticipantId = param.get('id');
    });

    this.httpClientService
      .get<any>('/aanwijzing/join-session/' + this.encryptAanwijzingParticipantId, {})
      .subscribe((participant: AanwijzingParticipant) => {
        this.log.debug(participant);

        this.global.routerParams.set('aanwijzingId', participant?.aanwijzing.id);
        this.global.routerParams.set('aanwijzingParticipantId', participant?.id);
        this.global.routerParams.set('aanwijzingParticipant', participant);

        if (participant?.user || this.global.userSession) { /** internal/vendor yg punya akun */
            window.location.pathname = '/pages/aanwijzing/session-room';

        } else {
            this.router.navigate(['/aanwijzing/session-room']);
        }
        this.setStateReady();
      });
  }

}
