import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { BaseComponentComponent } from '../../../../../../../core/base/angular/base-component.component';
import { OptionListModel } from '../../../../../../../core/model/option-list-model';
@Component({
  selector: 'app-table-vendor-filter',
  templateUrl: './app-table-vendor-filter.component.html',
  styleUrls: ['./app-table-vendor-filter.component.scss']
})
export class AppTableVendorFilterComponent extends BaseComponentComponent {
  public attributeGroupOptionList: OptionListModel<any> = new OptionListModel(
    false
  );
  public categoryOptionList: OptionListModel<any> = new OptionListModel(false);
  public ratingIconList: Array<number> = new Array();
  public formArrayTemp: Array<any> = new Array();
  @Input() isLoading: boolean;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  constructor() {
    super('view-vendor');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setAttributeOptionList();
    this.setCategoryOptionList();
    this.setRatingIconList();
    this.buildPerformanceFormArray();
    this.setStateReady();
  }

  public setRatingIconList(): void {
    this.ratingIconList = [1, 2, 3, 4, 5].reverse();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorName: [null],
      segmentationList: [null],
      performanceRatingList: this.formBuilder.array([])
    });
  }

  public buildPerformanceFormArray(): void {
    this.ratingIconList.forEach(value => {
      const form = {
        id: 1,
        value,
        isChecked: false
      };
      const formGroup: FormGroup = this.formBuilder.group(form);
      (this.formGroup.get('performanceRatingList') as FormArray).push(
        formGroup
      );
      this.formArrayTemp.push(form);
    });
  }

  public setAttributeOptionList(): void {
    const attributeOptionList = [
      { id: 1, name: 'Attribute 1' },
      { id: 2, name: 'Attribute 2' },
      { id: 3, name: 'Attribute 3' },
      { id: 4, name: 'Attribute 4' },
      { id: 5, name: 'Attribute 5' }
    ];
    this.attributeGroupOptionList.setRequestValues(attributeOptionList);
  }

  public setCategoryOptionList(): void {
    const categoryOpstionList = [
      { id: 1, name: 'Category One' },
      { id: 2, name: 'Category Two' },
      { id: 3, name: 'Category Three' },
      { id: 4, name: 'Category Four' },
      { id: 5, name: 'Category Five' }
    ];
    this.categoryOptionList.setRequestValues(categoryOpstionList);
  }

  public doApply(): void {
    const values = this.formGroup.value;
    values.performanceRatingList = values.performanceRatingList
      .map((performance: any) => performance.isChecked && performance.value)
      .filter((value: any) => typeof value === 'number');
    this.onChange.emit(values);
  }

  public doReset(): void {
    this.formGroup.reset();
    this.formGroup.get('performanceRatingList').patchValue(this.formArrayTemp);
    this.onChange.emit(null);
  }
}
