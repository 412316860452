import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
@Component({
  selector: 'app-rating-detail',
  templateUrl: './app-rating-detail.component.html',
  styleUrls: ['./app-rating-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppRatingDetailComponent extends BaseComponentComponent {
  @Input() ratings: Array<number>;
  ratingColors: Array<string> = [
    '#57bb8a',
    '#9ace6a',
    '#ffcf02',
    '#ff9f02',
    '#ff6f31'
  ];
  totalRating = 0;
  constructor() {
    super('app-rating-detail');
  }

  public onInit(): void {
    this.setTotalRating();
  }

  setTotalRating(): void {
    this.ratings.forEach(rating => {
      this.totalRating = +this.totalRating + rating;
    });
  }
}
