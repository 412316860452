import { Quotation } from '../../core/bean/quotation';
import { QuotationAdministration } from '../../core/bean/quotation-administration';
import { QuotationBidBond } from '../../core/bean/quotation-bid-bond';
import { QuotationCriteria } from '../../core/bean/quotation-criteria';
import { QuotationItem } from '../../core/bean/quotation-item';
import { FileObject } from '../../core/components/upload';

export class SubmitQuotationVendorRequest {
  isSubmit: boolean;
  isRevision: boolean;
  fileObjectList: FileObject[] = [];
  quotation: Quotation = new Quotation();
  quotationBidBond: QuotationBidBond = new QuotationBidBond();
  quotationAdministration: QuotationAdministration =
    new QuotationAdministration();
  quotationItemList: QuotationItem[] = [];
  quotationCriteriaList: QuotationCriteria[] = [];
}
