import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Global } from '../../core/global/global';
import { ProcurementNotifResponseModel } from '../../core/model/procurement-notif-response-model';

@Injectable({
  providedIn: 'root'
})
export class WorklistPMService {
  constructor(public global: Global, public router: Router) {}

  doNavigateDetail(
    response: ProcurementNotifResponseModel,
    moduleCode: string
  ): void {
    if (
      response.procurement.prcsCode.replace('APPROVAL-', '').toLowerCase() ===
      moduleCode
    ) {
      const isSchedule = this.validateSchedule(response);
      if (isSchedule) {
        this.global.routerParams.clear();
        this.global.routerParams.set('procurement', response.procurement);
        this.global.routerParams.set('procurementId', response.procurement.id);
        this.global.routerParams.set(
          'procurementWorklistStatusCode',
          response.procurement.procurementWorklistStatus.code
        );
        this.global.routerParams.set('urlBackOutside', '/pages/worklist-pm');

        if (
          response.procurement.committee.picUser.id ===
          this.global.userSession.user.id
        ) {
          if (
            response.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm
                .PROCUREMENT_WORKLIST_STATUS_WAITING_APPROVAL ||
            response.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_HOLD
          ) {
            this.router.navigate(['/pages/' + moduleCode + '/detail']);
            this.global.routerParams.set('todo', 'view');
          } else if (
            response.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT ||
            response.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION ||
            response.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DONE ||
            response.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm
                .PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION
          ) {
            this.router.navigate(['/pages/' + moduleCode + '/edit']);
            this.global.routerParams.set('todo', 'edit');
          } else if (
            response.procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_ON_PROGRESS
          ) {
            this.router.navigate(['/pages/' + moduleCode + '/edit']);
            this.global.routerParams.set('todo', 'progress');
          } else if (
            response.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_NEW &&
            response.procurement.workflowModelPrcs.module.code === 'REFUTATION'
          ) {
            this.doShowScheduleMismatchConfirmation(response.procurement.id);
          } else if (
            response.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm
                .PROCUREMENT_WORKLIST_STATUS_WAITING_RESPONSE &&
            response.procurement.workflowModelPrcs.module.code === 'REFUTATION'
          ) {
            this.global.routerParams.set('todo', 'view');
            this.router.navigate(['/pages/' + moduleCode + '/detail']);
          } else if (
            response.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm
                .PROCUREMENT_WORKLIST_STATUS_CLOSED_RESPONSE &&
            response.procurement.workflowModelPrcs.module.code === 'REFUTATION'
          ) {
            this.global.routerParams.set('todo', 'edit');
            this.router.navigate(['/pages/' + moduleCode + '/edit']);
          } else {
            this.router.navigate(['/pages/' + moduleCode + '/add']);
            this.global.routerParams.set('todo', 'add');
          }
        } else {
          this.router.navigate(['/pages/' + moduleCode + '/detail']);
          this.global.routerParams.set('todo', 'view');
        }
      } else {
        if (
          response.procurement.workflowModelPrcs.module.isSchedule &&
          !response.procurementSchedule.startDate &&
          !response.procurementSchedule.endDate
        ) {
          this.doShowSetScheduleConfirmation(response.procurement.id);
        } else {
          this.doShowScheduleMismatchConfirmation(response.procurement.id);
        }
      }
    } else {
      this.global.routerParams.clear();
      this.global.routerParams.set('procurementId', response.procurement.id);
      this.global.routerParams.set(
        'urlBackOutside',
        '/pages/procurement-history'
      );
      this.global.routerParams.set('todo', 'view');
      this.router.navigate(['/pages/procurement-history/edit']);
    }
  }

  public doShowScheduleMismatchConfirmation(procurementId: number): void {
    this.global.modalService
      .scheduleMismatchConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.global.routerParams.clear();
          this.global.routerParams.set('procurementId', procurementId);
          this.global.routerParams.set('urlBackOutside', '/pages/worklist-pm');
          this.global.routerParams.set('todo', 'edit');
          this.global.routerParams.set('isShowButtonSave', false);
          this.router.navigate(['/pages/procurement-schedule/edit']);
        }
      });
  }

  public doShowSetScheduleConfirmation(procurementId: number): void {
    this.global.modalService
      .confirmation(
        'app.confirmation.setScheduleConfirmation.prompt',
        'app.confirmation.setScheduleConfirmation.title',
        'app.button.later',
        'app.button.settingSchedule',
        'pvc pv-calendar-alt',
        false
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.global.routerParams.clear();
          this.global.routerParams.set('procurementId', procurementId);
          this.global.routerParams.set('urlBackOutside', '/pages/worklist-pm');
          this.global.routerParams.set('todo', 'edit');
          this.router.navigate(['/pages/procurement-schedule/edit']);
        }
      });
  }

  public validateSchedule(response: ProcurementNotifResponseModel): boolean {
    if (
      response.procurement.workflowModelPrcs.module.isSchedule &&
      response.procurement.workflowModelPrcs.module.code !== 'AANWIJZING' &&
      response.procurement.workflowModelPrcs.module.code !== 'PRICE-EVALUATION'
    ) {
      if (
        response.procurementSchedule.startDate &&
        response.procurementSchedule.endDate
      ) {
        // khusus status new/draft/revision ketika melewati end date tidak bisa dibuka kembali
        if (
          !response.procurement.workflowModelPrcs.module.isVendorResponse &&
          (response.procurement.procurementWorklistStatus.code ===
            this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_NEW ||
            response.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_DRAFT ||
            response.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION ||
            response.procurement.procurementWorklistStatus.code ===
              this.global.appConstant.pm
                .PROCUREMENT_WORKLIST_STATUS_RE_NEGOTIATION)
        ) {
          return (
            new Date(response.procurementSchedule.startDate).getTime() <=
              new Date().getTime() &&
            new Date(response.procurementSchedule.endDate).getTime() >=
              new Date().getTime()
          );
        } else {
          return (
            new Date(response.procurementSchedule.startDate).getTime() <=
            new Date().getTime()
          );
        }
      } else if (
        response.procurementSchedule.startDate &&
        !response.procurementSchedule.endDate
      ) {
        return (
          new Date(response.procurementSchedule.startDate).getTime() <=
          new Date().getTime()
        );
      } else if (
        !response.procurementSchedule.startDate &&
        !response.procurementSchedule.endDate
      ) {
        return false;
      }
    } else if (
      response.procurement.workflowModelPrcs.module.code === 'PRICE-EVALUATION'
    ) {
      return (
        new Date(response.procurementSchedule.endDate).getTime() >=
        new Date().getTime()
      );
    } else {
      return true;
    }
  }
}
