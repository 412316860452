import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Organization } from 'src/app/core/bean/organization';
import { Rfq } from 'src/app/core/bean/rfq';
import { RfqStatus } from 'src/app/core/bean/rfq-status';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RfqOptionListResponse } from './response/rfq-option-list.response';
@Component({
  templateUrl: './rfq.component.html'
})
export class RfqComponent extends BaseModuleComponent implements OnInit {
  public filter: string;
  public tableResponse: TableResponseModel<Rfq>;
  public rfqStatusOptionList: OptionListModel<RfqStatus> = new OptionListModel(
    true
  );
  public organizationOptionList: OptionListModel<Organization> =
    new OptionListModel(true);

  constructor(translateService: TranslateService) {
    super('rfq', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildTableResponse();
    this.buildSetFormGroup();
    this.doSetCustomData();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.filter = this.global.routerParams.get('filter');
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'code',
          header: 'table.column.number',
          plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
        },
        {
          field: 'title',
          header: 'table.column.procurementName'
        },
        {
          field: 'startDate',
          header: 'table.column.startDate',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'endDate',
          header: 'table.column.endDate',
          plugins: {
            name: 'date',
            format: 'short-date'
          }
        },
        {
          field: 'organization.name',
          header: 'table.column.organization'
        },
        {
          field: 'rfqStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              DRAFT: 'SECONDARY',
              REVISION: 'FEEDBACK',
              WAITING_APPROVAL: 'WARNING',
              WAITING_RESPONSE: 'GOOD',
              CLOSED_RESPONSE: 'DANGER',
              CANCELED: 'DANGER',
              COMPLETED: 'SUCCESS',
              DONE: 'SUCCESS'
            },
            colorField: 'rfqStatus.code'
          }
        }
      ],
      { checkBoxFn: this.isShowCheckBox.bind(this) }
    );
  }

  public isShowCheckBox(record: Rfq): boolean {
    let isStatusDraft = false;
    if (record.rfqStatus.code !== this.global.appConstant.pm.RFQ_STATUS_DRAFT) {
      isStatusDraft = false;
    } else {
      isStatusDraft = true;
    }
    return isStatusDraft;
  }

  public doSetCustomData(): void {
    if (this.filter) {
      const customData = {
        statusCode: this.filter
      };
      this.global.routerParams.clear();
      this.tableResponse.setCustomData(customData);
      this.tableResponse.reload();
    }
  }

  public onChangeFilter(): void {
    const customData = this.formGroup.value;
    customData.dateFrom = this.formGroup.value.date?.from || null;
    customData.dateTo = this.formGroup.value.date?.to || null;

    customData.dueDateFrom = this.formGroup.value.dueDate?.from || null;
    customData.dueDateTo = this.formGroup.value.dueDdate?.to || null;

    this.tableResponse.setCustomData(customData);
    this.tableResponse.reload();
  }

  public buildSetFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      date: [null],
      dueDate: [null],
      organizationList: [null],
      statusList: [null]
    });

    this.httpClientService
      .get<RfqOptionListResponse>('/rfq/get-option-list', {})
      .subscribe((response: RfqOptionListResponse) => {
        this.rfqStatusOptionList.setRequestValues(response.rfqStatusList);
        this.organizationOptionList.setRequestValues(response.organizationList);

        this.setStateReady();
      });
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/rfq/add']);
    this.global.routerParams.set('urlBackOutside', '/pages/rfq');
  }

  public doDelete(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<Rfq[]>>('/rfq/delete', event.selectedRecordList)
            .subscribe((response: Response<Rfq[]>) => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doClick(rfq: Rfq): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('rfqId', rfq.id);
    this.global.routerParams.set('status', rfq.rfqStatus.code);
    this.global.routerParams.set('urlBackOutside', '/pages/rfq');
    if (
      rfq.rfqStatus.code === this.global.appConstant.pm.PR_STATUS_DRAFT ||
      (rfq.rfqStatus.code === this.global.appConstant.pm.PR_STATUS_REVISION &&
        rfq.workflowPrcs.code.includes('RFQ_SUBMISSION'))
    ) {
      this.global.routerParams.set('todo', 'edit');
      this.router.navigate(['/pages/rfq/edit']);
    } else {
      this.global.routerParams.set('rfq', rfq);
      this.global.routerParams.set('todo', 'view');
      this.router.navigate(['/pages/rfq/detail']);
    }
  }
}
