import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './tab-procurement-history.component.html'
})
export class TabProcurementHistoryComponent extends BaseModuleComponent {
  @Input() vendorId: number;
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<Array<object>>;
  public tableResponse: TableResponseModel<ProcurementVendor>;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('tab-procurement-history', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setCustomData();
    this.model.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurement.number',
        header: 'table.column.procurementNumber',
        customClass: 'text-center'
      },
      { field: 'procurement.name', header: 'table.column.procurementName' },
      {
        field: 'procurement.procurementDate',
        header: 'table.column.date',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'procurement.procurementManagerOrganization.name',
        header: 'table.column.division'
      },
      {
        field: 'isWinner',
        header: 'table.column.procurementWinner',
        datamap: '{"true" : "Yes" , "false" : "No" }'
      },
      {
        field: 'procurement.procurementManager.name',
        header: 'table.column.userProcurement'
      }
    ]);
  }

  public setCustomData(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponse.setCustomData(customData);
  }
}
