<dashboard-widget-accordion-list
  [isLoading]="model.isLoading"
  header="Total Contract"
  subHeader="Total Transaction"
  size="LG"
  vector="pvc pv-file-contract"
  [total]="totalContract"
  [model]="contractDataList"
  nameField="translationKey"
  totalField="value"
  [isShowViewDetails]="false"
>
</dashboard-widget-accordion-list>
