import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray } from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { File } from 'src/app/core/bean/file';
import { Payment } from 'src/app/core/bean/payment';
import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { PaymentHistory } from 'src/app/core/bean/payment-history';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { SowPaymentTermBilling } from 'src/app/core/bean/sow-payment-term-billing';
import { StageOfWork } from 'src/app/core/bean/stage-of-work';
import { VendorBank } from 'src/app/core/bean/vendor-bank';
import { WorkflowModelPrcs } from 'src/app/core/bean/workflow-model-prcs';
import { WorkflowPrcs } from 'src/app/core/bean/workflow-prcs';
import { AppPopupChooseBillService } from 'src/app/core/components/app-popup/app-popup-choose-bill/app-popup-choose-bill.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { WorkflowStepInfoModel } from 'src/app/core/components/app-workflow-step-info/model/workflow-step-info-model';
import { FileUploader } from 'src/app/core/components/upload';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';
import { InternalPaymentRequestView } from 'src/app/core/view/entity/bean/internal-payment-request-view';
import { PaymentBillingDoc } from '../../core/bean/payment-billing-doc';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { InternalPaymentRequestEditAddRequest } from './internal-payment-request-edit-add-request';
import { InternalPaymentRequestEditAddResponse } from './internal-payment-request-edit-add-response';
import { InternalPaymentRequestInsertRequest } from './internal-payment-request-insert-request';
import { InternalPaymentRequestUpdateRequest } from './internal-payment-request-update-request';

@Component({
  templateUrl: './internal-payment-request-edit-add.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  encapsulation: ViewEncapsulation.None
})
export class InternalPaymentRequestEditAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild('selectorHistoryReviewPayment')
  public tableHistoryReviewPayment: AppTableComponent;
  public tableResponseHistoryPaymentReview: TableResponseModel<PaymentHistory>;

  public paymentId: number;
  public sowPaymentTermId: number;
  public deliveryItemId: number;
  public payment: Payment = new Payment();
  public internalPaymentRequestView: InternalPaymentRequestView =
    new InternalPaymentRequestView();
  public paymentBilling: PaymentBilling = new PaymentBilling();

  public fileUploaderBillingTermList: FileUploader[] = [];
  public sowPaymentTermBillingList: SowPaymentTermBilling[];
  public paymentBillingDocList: PaymentBillingDoc[];
  public paymentHistoryList: PaymentHistory[];
  public internalPaymentRequestEditAddRequest: InternalPaymentRequestEditAddRequest =
    new InternalPaymentRequestEditAddRequest();
  public internalPaymentRequestEditAddResponse: InternalPaymentRequestEditAddResponse =
    new InternalPaymentRequestEditAddResponse();
  public internalPaymentRequestInsertRequest: InternalPaymentRequestInsertRequest =
    new InternalPaymentRequestInsertRequest();
  public internalPaymentRequestUpdateRequest: InternalPaymentRequestUpdateRequest =
    new InternalPaymentRequestUpdateRequest();
  public procurementVendor: ProcurementVendor = new ProcurementVendor();
  public procurementVendorId: number;
  public urlBack: string;
  public isLoading: boolean;
  public index: number;
  public objectId: number;
  public paymentBillingId: number;
  public workflowPrcs: WorkflowPrcs;
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public stageOfWork: StageOfWork;
  public approvalModelPrcsId: number;
  public workflowStepInfoModel: WorkflowStepInfoModel;
  public isExpandedVendorInfo: boolean;

  public fileUploader: FileUploader = new FileUploader(
    '/internal-payment-request/',
    '',
    this.global.appConstant.fileType.DOC_INVOICE
  );
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupChooseBillService: AppPopupChooseBillService
  ) {
    super('internal-payment-request', translateService);
  }

  public onInit(): void {
    this.setInitializationState();
  }

  public setInitializationState(): void {
    this.buildTableResponse();
    this.setDataFromRouterParams();
    this.getAndSetPaymentHistoryList();
    this.buildFormGroup();
    this.setIsViewOnly();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.paymentBillingId =
      localStorage.getItem('paymentBillingId') ||
      this.global.routerParams.get('paymentBillingId');
    this.objectId = this.global.routerParams.get('objectId');
    this.paymentId =
      localStorage.getItem('paymentId') ||
      this.global.routerParams.get('paymentId') ||
      this.objectId;
    this.urlBack =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );

    localStorage.removeItem('todo');
    localStorage.removeItem('paymentBillingId');
    localStorage.removeItem('paymentId');
    localStorage.removeItem('urlBackOutside');
  }

  public getAndSetPaymentHistoryList(): void {
    this.httpClientService
      .post<PaymentHistory[]>(
        '/internal-payment-request/index-history-payment-review',
        new RouteRequestModel(null, this.paymentId)
      )
      .subscribe(paymentHistoryList => {
        this.paymentHistoryList = paymentHistoryList;
        if (paymentHistoryList !== []) {
          this.tableResponseHistoryPaymentReview.page.records =
            this.paymentHistoryList;
          this.tableResponseHistoryPaymentReview.page.totalRecords =
            this.paymentHistoryList && this.paymentHistoryList.length;
          this.tableResponseHistoryPaymentReview.reloadClient();
        }
      });
  }

  public buildTableResponse(): void {
    this.tableResponseHistoryPaymentReview = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'date',
          header: 'table.column.date',
          format: FieldFormatEnum.ShortDateAndTime,
          customClass: 'text-center'
        },
        {
          field: 'status',
          header: 'table.column.status',
          customClass: 'text-center',
          fn: this.handleStatusTextColor.bind(this)
        },
        {
          field: 'picName',
          header: 'table.column.pic',
          customClass: 'text-left',
          sortable: false
        },
        {
          field: 'note',
          header: 'table.column.note'
        }
      ]
    );
  }

  public handleStatusTextColor(value: string, record: any): HTMLElement {
    this.log.debug(value);
    const spanElement = document.createElement('span');
    if (record.status === 1) {
      spanElement.style.color = 'var(--black)';
      spanElement.textContent = this.global.translateService.instant(
        'internal-payment-request.new'
      );
    } else if (record.status === 2) {
      spanElement.style.color = 'var(--warning)';
      spanElement.textContent = this.global.translateService.instant(
        'internal-payment-request.revision'
      );
    } else if (record.status === 3) {
      spanElement.style.color = 'var(--success)';
      spanElement.textContent = this.global.translateService.instant(
        'internal-payment-request.approved'
      );
    }
    return spanElement;
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null, Validators.required()],
      invoiceDate: [null, Validators.required()],
      invoiceFile: [null, Validators.required()],
      file: [new File()],
      description: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(512)])
      ],
      vendorBank: [null, Validators.required()],
      isSubmit: [false],
      sowPaymentTermBillingList: this.formBuilder.array([])
    });
  }

  public setBillingTerm(): void {
    const parentsForm = this.formGroup.controls
      .sowPaymentTermBillingList as FormArray;
    if (this.todo === 'add') {
      this.sowPaymentTermBillingList =
        this.internalPaymentRequestEditAddResponse.sowPaymentTermBillingList;
      this.sowPaymentTermBillingList.forEach(sowPaymentTermBilling => {
        const fileUpload: FileUploader = new FileUploader(
          '/internal-payment-request/',
          '',
          this.global.appConstant.fileType.DOC_BILLING_TERMS
        );
        this.fileUploaderBillingTermList.push(fileUpload);
        parentsForm.push(
          this.formBuilder.group({
            billingTermName: sowPaymentTermBilling.billingTerm.name,
            code: [null, Validators.required()],
            billingDocDate: [null, Validators.required()],
            file: [null, Validators.required()]
          })
        );
      });
    } else if (this.todo === 'edit' || this.todo === 'view') {
      this.paymentBillingDocList =
        this.internalPaymentRequestEditAddResponse.paymentBillingDocList;
      this.paymentBillingDocList.forEach(
        (paymentBillingDoc: PaymentBillingDoc, index) => {
          const fileUpload: FileUploader = new FileUploader(
            '/internal-payment-request/',
            '',
            this.global.appConstant.fileType.DOC_BILLING_TERMS
          );
          fileUpload.setFile(paymentBillingDoc.file);
          this.fileUploaderBillingTermList.push(fileUpload);
          this.log.debug(index);
          const formGroup = this.formBuilder.group({
            billingTermName:
              paymentBillingDoc.sowPaymentTermBilling.billingTerm.name,
            code: [paymentBillingDoc.code, Validators.required()],
            billingDocDate: [
              paymentBillingDoc.billingDocDate &&
                new Date(paymentBillingDoc.billingDocDate),
              Validators.required()
            ],
            file: [fileUpload.fileObjectList, Validators.required()]
          });
          parentsForm.push(formGroup);
          if (this.todo === 'view') {
            formGroup.setIsView(true);
          }
        }
      );
    }
  }

  public setPaymentBillingDocument(): void {
    const billingDocumentList = this.formGroup.value.sowPaymentTermBillingList;
    if (this.todo === 'add') {
      this.paymentBillingDocList = billingDocumentList;
      this.paymentBillingDocList.forEach((paymentBillingDoc, index) => {
        paymentBillingDoc.fileObjectList = billingDocumentList[index].file;
        paymentBillingDoc.file = null;
      });
      this.internalPaymentRequestInsertRequest.sowPaymentTermBillingList =
        this.sowPaymentTermBillingList;
      this.internalPaymentRequestInsertRequest.paymentBillingDocList =
        this.paymentBillingDocList;
    } else {
      this.paymentBillingDocList = billingDocumentList;
      this.paymentBillingDocList.forEach(
        (paymentBillingDoc: PaymentBillingDoc, index) => {
          paymentBillingDoc.fileObjectList =
            typeof billingDocumentList[index].file.length !== 'undefined'
              ? billingDocumentList[index].file
              : [];
          paymentBillingDoc.file = null;
        }
      );
      this.internalPaymentRequestUpdateRequest.paymentBillingDocList =
        this.paymentBillingDocList;
    }
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public setRequest(): void {
    this.internalPaymentRequestEditAddRequest.todo = this.todo;
    if (this.paymentId !== null) {
      this.internalPaymentRequestEditAddRequest.paymentId =
        this.paymentId || this.objectId;
    }
    if (this.paymentBillingId !== null) {
      this.internalPaymentRequestEditAddRequest.paymentBillingId =
        this.paymentBillingId;
    }
  }

  public setFormGroup(): void {
    this.setRequest();
    this.httpClientService
      .post<InternalPaymentRequestEditAddResponse>(
        '/internal-payment-request/add-edit',
        this.internalPaymentRequestEditAddRequest
      )
      .subscribe(
        (
          internalPaymentRequestEditAddResponse: InternalPaymentRequestEditAddResponse
        ) => {
          this.internalPaymentRequestEditAddResponse =
            internalPaymentRequestEditAddResponse;
          this.paymentBilling =
            internalPaymentRequestEditAddResponse.paymentBilling;
          this.stageOfWork = internalPaymentRequestEditAddResponse.stageOfWork;
          this.paymentBillingDocList =
            this.internalPaymentRequestEditAddResponse.paymentBillingDocList;
          this.sowPaymentTermBillingList =
            this.internalPaymentRequestEditAddResponse.sowPaymentTermBillingList;
          this.payment = internalPaymentRequestEditAddResponse.payment;
          this.workflowStepInfoModel =
            internalPaymentRequestEditAddResponse.workflowStepInfoModel;

          if (this.objectId && (this.todo === 'edit' || this.todo === 'view')) {
            this.payment = internalPaymentRequestEditAddResponse.payment;
            this.workflowPrcs = this.payment.workflowPrcs;
            this.workflowModelPrcs = this.payment.workflowModelPrcs;
          }
          if (internalPaymentRequestEditAddResponse.paymentInvoice !== null) {
            const invoiceDate: Date = new Date(
              internalPaymentRequestEditAddResponse.paymentInvoice.invoiceDate
            );
            this.fileUploader.setFile(
              internalPaymentRequestEditAddResponse.paymentInvoice.docFile
            );
            this.formGroup.patchValue({
              id: internalPaymentRequestEditAddResponse.payment.id,
              code: internalPaymentRequestEditAddResponse.paymentInvoice.code,
              invoiceDate,
              invoiceFile:
                internalPaymentRequestEditAddResponse.paymentInvoice.docFile &&
                this.fileUploader,
              description:
                internalPaymentRequestEditAddResponse.paymentInvoice
                  .description,
              vendorBank: this.payment.vendorBank
            });
          }
          this.setBillingTerm();
          this.setStateReady();
        }
      );
  }

  public doSave(): void {
    this.formGroup.get('invoiceFile').clearValidators();
    this.formGroup.get('description').clearValidators();
    this.formGroup.get('vendorBank').clearValidators();

    this.formGroup.get('invoiceFile').updateValueAndValidity();
    this.formGroup.get('description').updateValueAndValidity();
    this.formGroup.get('vendorBank').updateValueAndValidity();

    const parentsForm = this.formGroup.controls
      .sowPaymentTermBillingList as FormArray;
    parentsForm.controls.forEach(sowPaymentTermBilling => {
      sowPaymentTermBilling.get('code').clearValidators();
      sowPaymentTermBilling.get('billingDocDate').clearValidators();
      sowPaymentTermBilling.get('file').clearValidators();

      sowPaymentTermBilling.get('code').updateValueAndValidity();
      sowPaymentTermBilling.get('billingDocDate').updateValueAndValidity();
      sowPaymentTermBilling.get('file').updateValueAndValidity();
    });

    this.validate();
    this.internalPaymentRequestInsertRequest.isSubmit = false;
    this.internalPaymentRequestUpdateRequest.isSubmit = false;

    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            if (this.todo === 'add') {
              this.doInsertVendorPaymentRequest();
            } else if (this.todo === 'edit') {
              this.doUpdateVendorPaymentRequest();
            }
          }
        });
    }
  }

  public doSubmit(): void {
    this.formGroup.get('invoiceFile').setValidators(Validators.required());
    this.formGroup.get('description').setValidators(Validators.required());
    this.formGroup.get('vendorBank').setValidators(Validators.required());

    this.formGroup.get('invoiceFile').updateValueAndValidity();
    this.formGroup.get('description').updateValueAndValidity();
    this.formGroup.get('vendorBank').updateValueAndValidity();

    const parentsForm = this.formGroup.controls
      .sowPaymentTermBillingList as FormArray;

    parentsForm.controls.forEach(sowPaymentTermBilling => {
      sowPaymentTermBilling.get('code').setValidators(Validators.required());
      sowPaymentTermBilling
        .get('billingDocDate')
        .setValidators(Validators.required());
      sowPaymentTermBilling.get('file').setValidators(Validators.required());

      sowPaymentTermBilling.get('code').updateValueAndValidity();
      sowPaymentTermBilling.get('billingDocDate').updateValueAndValidity();
      sowPaymentTermBilling.get('file').updateValueAndValidity();
    });
    this.formGroup.get('isSubmit').patchValue(true);
    this.validate();
    this.internalPaymentRequestInsertRequest.isSubmit = true;
    this.internalPaymentRequestUpdateRequest.isSubmit = true;
    this.isExpandedVendorInfo = !this.formGroup.get('vendorBank').valid;
    if (this.formGroup.valid) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            if (this.todo === 'add') {
              this.doInsertVendorPaymentRequest();
            } else if (this.todo === 'edit') {
              this.doUpdateVendorPaymentRequest();
            }
          }
        });
    }
  }

  public doInsertVendorPaymentRequest(): void {
    this.setStateProcessing();
    if (
      this.sowPaymentTermBillingList !== [] ||
      this.paymentBillingDocList !== []
    ) {
      this.setPaymentBillingDocument();
    }
    this.payment = this.formGroup.value;
    this.internalPaymentRequestInsertRequest.paymentInvoice =
      this.formGroup.value;
    this.internalPaymentRequestInsertRequest.fileObjectList =
      this.fileUploader.fileObjectList;
    this.internalPaymentRequestInsertRequest.procurementVendorId =
      this.procurementVendorId;
    this.internalPaymentRequestInsertRequest.sowPaymentTermId =
      this.sowPaymentTermId;
    this.payment = this.global.copyFormAttributeToModel(
      this.payment,
      this.formGroup
    );
    this.internalPaymentRequestInsertRequest.payment = this.payment;
    this.internalPaymentRequestInsertRequest.paymentBilling =
      this.paymentBilling;
    const url = '/internal-payment-request/insert';
    this.httpClientService
      .post<Response<InternalPaymentRequestView>>(
        url,
        this.internalPaymentRequestInsertRequest
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.router.navigate(['/pages/internal-payment-request']);
          this.global.alertService.showSuccessSavingOnNextRoute();
        } else {
          this.setStateReady();
          this.global.alertService.showError(response.statusText);
        }
      });
  }

  public doUpdateVendorPaymentRequest(): void {
    this.setStateProcessing();
    if (
      this.sowPaymentTermBillingList !== [] ||
      this.paymentBillingDocList !== []
    ) {
      this.setPaymentBillingDocument();
    }
    this.payment.vendorBank = this.formGroup.get('vendorBank').value;
    this.internalPaymentRequestUpdateRequest.payment = this.payment;
    this.internalPaymentRequestUpdateRequest.paymentInvoice =
      this.formGroup.value;
    this.internalPaymentRequestUpdateRequest.paymentInvoice.id =
      this.internalPaymentRequestEditAddResponse.paymentInvoice.id;
    this.internalPaymentRequestUpdateRequest.fileObjectList =
      this.fileUploader.fileObjectList;
    this.internalPaymentRequestUpdateRequest.paymentBilling =
      this.paymentBilling;

    const url = '/internal-payment-request/update';
    this.httpClientService
      .post<Response<InternalPaymentRequestView>>(
        url,
        this.internalPaymentRequestUpdateRequest
      )
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.router.navigate(['/pages/internal-payment-request']);
          this.global.alertService.showSuccessSavingOnNextRoute();
        } else {
          this.setStateReady();
          this.global.alertService.showError(response.statusText);
        }
      });
  }

  public doChangeBill(): void {
    this.global.modalService
      .confirmation(
        'internal-payment-request.confirmation.changeBill.prompt',
        'internal-payment-request.confirmation.changeBill.title',
        'internal-payment-request.confirmation.changeBill.button.cancel',
        'internal-payment-request.confirmation.changeBill.button.yes',
        'pvc pv-invoice',
        false
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.appPopupChooseBillService
            .open(true, this.paymentBilling)
            .subscribe(paymentBilling => {
              this.log.debug(paymentBilling);
              this.setStateLoading();
              this.global.routerParams.clear();
              this.global.routerParams.set('todo', 'add');
              this.global.routerParams.set(
                'sowPaymentTermId',
                paymentBilling.sowPaymentTerm.id
              );
              this.global.routerParams.set(
                'procurementVendorId',
                paymentBilling.procurementVendorId
              );
              this.global.routerParams.set('paymentBilling', paymentBilling);
              this.global.routerParams.set(
                'paymentBillingId',
                paymentBilling.id
              );
              this.global.routerParams.set(
                'urlBackOutside',
                '/pages/internal-payment-request'
              );
              this.setInitializationState();
            });
        }
      });
  }

  public doBack(): void {
    if (this.urlBack === 'close-window') {
      window.close();
    } else {
      this.router.navigate([this.urlBack]);
    }
  }

  public onChangeVendor(vendorBank: VendorBank): void {
    this.formGroup.patchValue({
      vendorBank
    });
  }

  public doViewAgreement(): void {
    if (this.paymentBilling.drType.code === 'CONTRACT') {
      localStorage.setItem('todo', 'view');
      localStorage.setItem(
        'objectId',
        this.internalPaymentRequestEditAddResponse.sowObjectId.toString()
      );
      console.log(this.paymentBilling.drType.code);
      window.open('/pages/contract-request/edit');
    } else if (this.paymentBilling.drType.code === 'PURCHASE_ORDER') {
      localStorage.setItem('todo', 'view');
      localStorage.setItem(
        'objectId',
        this.internalPaymentRequestEditAddResponse.sowObjectId.toString()
      );
      window.open('/pages/order/edit');
      console.log(this.paymentBilling.drType.code);
    }
  }
}
