import { BaseEntity } from '../base/base-entity';
import { Catalog } from '../bean/catalog';
import { Region } from '../bean/region';
import { VendorAddress } from '../bean/vendor-address';

export class CatalogInventoryEntity extends BaseEntity {
  regionList: Region[] = [];

  catalog: Catalog;
  region: Region;
  countryRegion: Region = new Region();
  provinceRegion: Region = new Region();
  cityRegion: Region = new Region();
  stock: number;
  supplyAbility: number;
  qtyNotification: number;
  moq: number;
  outStock: number;
  estimatedDeliveryTime: string;
  vendorAddress: VendorAddress;
}
