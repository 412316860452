import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Item } from '../../../bean/item';
import { WorkPlanItem } from '../../../bean/work-plan-item';
import { AppPopupService } from '../app-popup.service';
import { AppPopupWorkPlanItemComponent } from './app-popup-work-plan-item.component';
@Injectable()
export class AppPopupWorkPlanItemService {
  constructor(public appPopupService: AppPopupService) {}
  public open(workPlanItem?: WorkPlanItem, itemList?: Item[]): Observable<any> {
    return this.appPopupService.open(
      AppPopupWorkPlanItemComponent,
      { workPlanItem, itemList },
      { centered: false }
    );
  }
}
