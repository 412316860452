import { ContractDraftParameterValue } from 'src/app/core/bean/contract-draft-parameter-value';
import { Contract } from '../../core/bean/contract';
import { ContractNegotiationHistory } from '../../core/bean/contract-negotiation-history';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path-x';
import { AppPopupVersionHistoryModel } from '../../core/components/app-popup/app-popup-version-history/app-popup-version-history-model';
import { WorkflowStepInfoModel } from '../../core/components/app-workflow-step-info/model/workflow-step-info-model';

export class ContractFinalizationResponse {
  public appPopupVersionHistoryModelList: AppPopupVersionHistoryModel[];
  public versionHistoryModel: AppPopupVersionHistoryModel;
  public contractNegotiationHistory: ContractNegotiationHistory;
  public contract: Contract;
  public workflowStepInfoModel: WorkflowStepInfoModel;
  public approvalPathResponseModel: ApprovalPathResponseModel;
  public isUserApproval: boolean;
  public isProcessed: boolean;
  public contractDraftParameterValueList: ContractDraftParameterValue[];
  public startDate: Date;
  public endDate: Date;
}
