import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { RatingProductModel } from '../../app-rating/app-rating-product/model/rating-product-model';
import { AppPopupService } from '../app-popup.service';
import { AppPopupStatistikVendorComponent } from './app-popup-statistik-vendor.component';
@Injectable()
export class AppPopupStatistikVendorService {
  constructor(public appPopupService: AppPopupService) {}
  public open(
    ratingProduct: RatingProductModel,
    config?: NgbModalOptions
  ): Observable<any> {
    const defaultConfig: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      centered: true
    };
    const newConfig = { ...defaultConfig, ...config };
    return this.appPopupService.open(
      AppPopupStatistikVendorComponent,
      { ratingProduct },
      newConfig
    );
  }
}
