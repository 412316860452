import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { ProposalCriteria } from '../../core/bean/proposal-criteria';
import { OptionListModel } from '../../core/model/option-list-model';
import { Validators } from '../../core/validators';

@Component({
  templateUrl: './rfp-popup-choose-proposal-criteria.component.html'
})
export class RfpPopupChooseProposalCriteriaComponent extends BaseComponentComponent {
  public proposalCriteria: ProposalCriteria = new ProposalCriteria();
  public proposalCriteriaOptionList: OptionListModel<any> = new OptionListModel(
    true,
    'code'
  );
  @Input() proposalCriteriaList: ProposalCriteria[] = [];
  @Input() proposalCriteriaOptList: ProposalCriteria[] = [];
  @Output() onChange: EventEmitter<ProposalCriteria[]> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {
    super('rfp');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      proposalCriteria: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.proposalCriteriaOptionList.setRequestValues(
      this.proposalCriteriaOptList
    );
    if (this.proposalCriteriaList && this.proposalCriteriaList.length !== 0) {
      this.formGroup.patchValue({
        proposalCriteria: this.proposalCriteriaList
      });
    }
    this.setStateReady();
  }

  doSubmit(): void {
    this.proposalCriteriaList = this.formGroup.get('proposalCriteria').value;
    if (this.proposalCriteriaList && this.proposalCriteriaList.length !== 0) {
      this.onChange.emit(this.proposalCriteriaList);
    } else {
      this.global.alertService.showError(
        'rfp.validation.chooseProposalCriteria',
        '.popup-proposal-criteria'
      );
    }
  }
}
