<app-popup
  [header]="
    'tab-profile-expert-and-equipment.form.popup.header.equipment.' + todo
  "
  [isLoading]="formLoading"
>
  <form [formGroup]="formGroup" class="app-popup-equipment">
    <h5 class="separator">{{
      'tab-profile-expert-and-equipment.form-group.popup.equipment' | translate
    }}</h5>
    <ng-container formGroupName="equipment">
      <div
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-expert-and-equipment.form.popup.type' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            [autofocus]="true"
            size="LG"
            [optionList]="equipmentTypeOptionList"
            formControlName="equipmentType"
            (onChange)="onChangeEquipment($event)"
          >
            <app-flag-new
              *ngIf="
                global.userSession.activeUserRole.role.type !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs') &&
                vendorEquipmentHistoryEdit.vendorHistory !== null
              "
              [oldValue]="
                vendorEquipment.equipmentType
                  ? vendorEquipment.equipmentType.name
                  : null
              "
              [newValue]="
                vendorEquipmentHistoryEdit.equipmentType
                  ? vendorEquipmentHistoryEdit.equipmentType.name
                  : null
              "
            ></app-flag-new>
          </app-combo-box>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-expert-and-equipment.form.popup.quantity' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="SM"
            formControlName="quantity"
            type="decimal"
            maxLength="11"
            tooltip="{{
              'tab-profile-expert-and-equipment.tooltip.quantity' | translate
            }}"
          >
            <app-flag-new
              *ngIf="
                global.userSession.activeUserRole.role.type !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs') &&
                vendorEquipmentHistoryEdit.vendorHistory !== null
              "
              [oldValue]="vendorEquipment.quantity"
              [newValue]="vendorEquipmentHistoryEdit.quantity"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <ng-container formGroupName="capacities">
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'tab-profile-expert-and-equipment.form.popup.capacity' | translate
          }}</label>
          <div class="col-sm-9 text-left d-flex">
            <app-text-field
              size="XL"
              formControlName="capacity"
              type="decimal"
              maxLength="11"
              [ngStyle]="{
                width: !formGroup.isView ? '95px' : null,
                'margin-right': '3px'
              }"
            ></app-text-field>
            <app-combo-box
              size="XL"
              [optionList]="uomOptionList"
              formControlName="uom"
              tooltip="{{
                'tab-profile-expert-and-equipment.tooltip.capacity' | translate
              }}"
              [ngStyle]="{ width: '90px' }"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorEquipmentHistoryEdit.vendorHistory !== null
                "
                [oldValue]="capacityUom"
                [newValue]="capacityUomHistory"
              ></app-flag-new>
            </app-combo-box>
          </div>
        </div>
      </ng-container>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-expert-and-equipment.form.popup.brand' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field size="LG" formControlName="brand" maxLength="32">
            <app-flag-new
              *ngIf="
                global.userSession.activeUserRole.role.type !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs') &&
                vendorEquipmentHistoryEdit.vendorHistory !== null
              "
              [oldValue]="vendorEquipment.brand"
              [newValue]="vendorEquipmentHistoryEdit.brand"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-expert-and-equipment.form.popup.condition' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            [optionList]="equipmentConditionOptionList"
            formControlName="equipmentCondition"
          >
            <app-flag-new
              *ngIf="
                global.userSession.activeUserRole.role.type !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs') &&
                vendorEquipmentHistoryEdit.vendorHistory !== null
              "
              [oldValue]="
                vendorEquipment.equipmentCondition
                  ? vendorEquipment.equipmentCondition.name
                  : null
              "
              [newValue]="
                vendorEquipmentHistoryEdit.equipmentCondition
                  ? vendorEquipmentHistoryEdit.equipmentCondition.name
                  : null
              "
            ></app-flag-new>
          </app-combo-box>
        </div>
      </div>

      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'tab-profile-expert-and-equipment.form.popup.location' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-tree
            size="LG"
            formControlName="region"
            stringUrl="/region"
            header="Location"
            [onlyLastChild]="false"
            isLazy="true"
          >
            <app-flag-new
              *ngIf="
                global.userSession.activeUserRole.role.type !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs') &&
                vendorEquipmentHistoryEdit.vendorHistory !== null
              "
              [oldValue]="vendorEquipment.regionNameList"
              [newValue]="vendorEquipmentHistoryEdit.regionNameList"
            ></app-flag-new>
          </app-text-tree>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-expert-and-equipment.form.popup.ownership' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-combo-box
            [optionList]="resourceOwnershipOptionList"
            formControlName="resourceOwnership"
          >
            <app-flag-new
              *ngIf="
                global.userSession.activeUserRole.role.type !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs') &&
                vendorEquipmentHistoryEdit.vendorHistory !== null
              "
              [oldValue]="
                vendorEquipment.resourceOwnership
                  ? vendorEquipment.resourceOwnership.name
                  : null
              "
              [newValue]="
                vendorEquipmentHistoryEdit.resourceOwnership
                  ? vendorEquipmentHistoryEdit.resourceOwnership.name
                  : null
              "
            ></app-flag-new>
          </app-combo-box>
        </div>
      </div>

      <div
        class="form-group row text-sm-right"
        [ngClass]="!formGroup.isView ? 'required' : ''"
      >
        <label class="col-sm-3 control-label">{{
          'tab-profile-expert-and-equipment.form.popup.year' | translate
        }}</label>
        <div class="col-sm-9 text-left">
          <app-text-field
            size="SM"
            formControlName="year"
            type="integer"
            maxLength="4"
          >
            <app-flag-new
              *ngIf="
                global.userSession.activeUserRole.role.type !==
                  global.appConstant.core.ROLE_TYPE_VENDOR &&
                formGroup.isView &&
                !isVendorList &&
                (fromOutside === '/pages/on-boarding' ||
                  fromOutside === '/pages/approval-prcs') &&
                vendorEquipmentHistoryEdit.vendorHistory !== null
              "
              [oldValue]="vendorEquipment.year"
              [newValue]="vendorEquipmentHistoryEdit.year"
            ></app-flag-new>
          </app-text-field>
        </div>
      </div>
    </ng-container>

    <h5 class="separator">{{
      'tab-profile-expert-and-equipment.form-group.popup.ownershipDocument'
        | translate
    }}</h5>
    <ng-container formArrayName="ownershipDocumentList">
      <app-fieldset
        *ngFor="
          let ownershipDocument of ownershipDocumentList.controls;
          let i = index
        "
        [formGroupName]="i"
      >
        <em
          *ngIf="!formGroup.isView"
          class="fas fa-times text-danger cursor-pointer position-absolute"
          [ngStyle]="{ right: '1rem', top: '1rem' }"
          (click)="doDeleteOwnershipDocument(i)"
          (keyup)="onKeyUp($event)"
          (keydown)="onKeyDown($event)"
        ></em>
        <div
          *ngIf="
            formGroup.isView &&
            !isVendorList &&
            (fromOutside === '/pages/on-boarding' ||
              fromOutside === '/pages/approval-prcs') &&
            vendorEquipmentHistoryEdit.vendorHistory !== null
          "
          [innerHtml]="ownershipDocument.value.crudInfo"
        ></div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'tab-profile-expert-and-equipment.form.popup.certificationNumber'
              | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field
              size="LG"
              formControlName="certificateNumber"
              maxLength="32"
              tooltip="{{
                'tab-profile-expert-and-equipment.tooltip.certificateNumber'
                  | translate
              }}"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorEquipmentHistoryEdit.vendorHistory !== null
                "
                [oldValue]="ownershipDocument.value.certificateNumber"
                [newValue]="ownershipDocument.value.certificateNumber"
              ></app-flag-new>
            </app-text-field>
          </div>
        </div>

        <ng-container formGroupName="validationDate">
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'tab-profile-expert-and-equipment.form.popup.validationDate'
                | translate
            }}</label>
            <div class="col-sm-9 text-left d-flex">
              <label class="mr-3 control-label">{{
                'tab-profile-expert-and-equipment.form.popup.from' | translate
              }}</label>
              <app-date-picker
                size="XL"
                formControlName="startDate"
                maxDate="endDate"
                [ngStyle]="{
                  'margin-right': !formGroup.isView ? '0px' : '30px'
                }"
              >
                <app-flag-new
                  *ngIf="
                    global.userSession.activeUserRole.role.type !==
                      global.appConstant.core.ROLE_TYPE_VENDOR &&
                    formGroup.isView &&
                    !isVendorList &&
                    (fromOutside === '/pages/on-boarding' ||
                      fromOutside === '/pages/approval-prcs') &&
                    vendorEquipmentHistoryEdit.vendorHistory !== null
                  "
                  [oldValue]="startDateActualList[i]"
                  [newValue]="startDateHistoryList[i]"
                ></app-flag-new>
              </app-date-picker>
              <label class="ml-3 mr-3 control-label">{{
                'tab-profile-expert-and-equipment.form.popup.until' | translate
              }}</label>
              <app-date-picker
                size="XL"
                formControlName="endDate"
                minDate="startDate"
              >
                <app-flag-new
                  *ngIf="
                    global.userSession.activeUserRole.role.type !==
                      global.appConstant.core.ROLE_TYPE_VENDOR &&
                    formGroup.isView &&
                    !isVendorList &&
                    (fromOutside === '/pages/on-boarding' ||
                      fromOutside === '/pages/approval-prcs') &&
                    vendorEquipmentHistoryEdit.vendorHistory !== null
                  "
                  [oldValue]="endDateActualList[i]"
                  [newValue]="endDateHistoryList[i]"
                ></app-flag-new>
              </app-date-picker>
            </div>
          </div>
        </ng-container>

        <div class="form-group row text-sm-right" *ngIf="!formLoading">
          <label class="col-sm-3 control-label" *ngIf="!formGroup.isView">{{
            'tab-profile-expert-and-equipment.form.popup.uploadOwnershipDoc'
              | translate
          }}</label>
          <label class="col-sm-3 control-label" *ngIf="formGroup.isView">{{
            'tab-profile-expert-and-equipment.form.popup.ownershipDoc'
              | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-upload
              size="LG"
              formControlName="fileDocument"
              [fileUploader]="fileUploaderList[i]"
            >
              <app-flag-new
                *ngIf="
                  global.userSession.activeUserRole.role.type !==
                    global.appConstant.core.ROLE_TYPE_VENDOR &&
                  formGroup.isView &&
                  !isVendorList &&
                  (fromOutside === '/pages/on-boarding' ||
                    fromOutside === '/pages/approval-prcs') &&
                  vendorEquipmentHistoryEdit.vendorHistory !== null
                "
                [oldValue]="
                  ownershipDocument.value.file
                    ? ownershipDocument.value.file.id
                    : null
                "
                [newValue]="
                  ownershipDocument.value.file
                    ? ownershipDocument.value.file.id
                    : null
                "
                [hoverValue]="
                  ownershipDocument.value.file
                    ? ownershipDocument.value.file.fileName
                    : null
                "
              ></app-flag-new>
            </app-upload>
          </div>
        </div>
      </app-fieldset>
      <div class="form-group row text-sm-right" *ngIf="!formGroup.isView">
        <div class="col-sm-12 pl-4 text-center">
          <app-button (onClick)="addOwnershipDocument()">{{
            'app.button.addMore' | translate
          }}</app-button>
        </div>
      </div>
    </ng-container>
    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
        <app-button (onClick)="doSave()" *ngIf="!formGroup.isView">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </form>
</app-popup>
