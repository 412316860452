import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalPrcsXModule } from '../../core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppFullfilledModule } from '../../core/components/app-fullfilled/app-fullfilled.module';
import { AppItemRequestModule } from '../../core/components/app-item-request/app-item-request.module';
import { AppOfficialReportModule } from '../../core/components/app-official-report/app-official-report.module';
import { AppPopupProcurementSummaryService } from '../../core/components/app-popup/app-popup-procurement-summary/app-popup-procurement-summary.service';
import { AppProcurementInfoModule } from '../../core/components/app-procurement-info/app-procurement-info.module';
import { AppWorkflowStepInfoModule } from '../../core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupAddUserComponent } from './app-popup-add-user.component';
import { AppPopupComplianceTypeComponent } from './app-popup-compliance-type.component';
import { AppPopupVendorWinnerInfoComponent } from './app-popup-vendor-winner-info.component';
import { ComplianceTestEditComponent } from './compliance-test-edit.component';
import { ComplianceTestComponent } from './compliance-test.component';
const routes = [
  { path: '', component: ComplianceTestComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: ComplianceTestEditComponent,
    data: { breadcrumb: 'compliance-test.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: ComplianceTestEditComponent,
    data: { breadcrumb: 'compliance-test.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: ComplianceTestEditComponent,
    data: { breadcrumb: 'compliance-test.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: ComplianceTestEditComponent,
    data: { breadcrumb: '' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    AppOfficialReportModule,
    AppProcurementInfoModule,
    AppWorkflowStepInfoModule,
    AppItemRequestModule,
    AppApprovalPrcsXModule,
    AppFullfilledModule,
    AppBadgeCatalogModule
  ],
  declarations: [
    ComplianceTestComponent,
    ComplianceTestEditComponent,
    AppPopupComplianceTypeComponent,
    AppPopupVendorWinnerInfoComponent,
    AppPopupAddUserComponent
  ],
  entryComponents: [
    AppPopupComplianceTypeComponent,
    AppPopupVendorWinnerInfoComponent,
    AppPopupAddUserComponent
  ],
  providers: [AppPopupProcurementSummaryService]
})
export class ComplianceTestModule {}
