import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { BudgetAllocation } from 'src/app/core/bean/budget-allocation';

@Component({
  templateUrl: './app-popup-billing-memo-budget-detail.component.html'
})

export class AppPopupBillingMemoBudgetDetailComponent extends BaseModuleComponent {
  @Input() public budgetAllocation: BudgetAllocation;

  constructor(translateService: TranslateService) {
    super('app-popup-billing-memo-budget-detail', translateService);
  }

  public onInit(): void {}
}
