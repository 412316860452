import { PaymentBillingView } from 'src/app/core/view/entity/bean/payment-billing-view';
import { DeliveryItemHistory } from 'src/app/core/bean/delivery-item-history';
import { PaymentHistory } from 'src/app/core/bean/payment-history';
import { PaymentRequestView } from 'src/app/core/view/entity/bean/payment-request-view';
import { Payment } from 'src/app/core/bean/payment';

export class PaymentRequestReviewEditResponse {
    paymentHistory: PaymentHistory;
    deliveryItemHistory: DeliveryItemHistory;
    isUserApproval: boolean;
    paymentRequestView: PaymentRequestView;
    paymentBillingViewBean: PaymentBillingView;
    payment: Payment;
    isDeliverableItem: boolean;
}
