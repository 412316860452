import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AppDynamicComponentService } from '../../services/app-dynamic-component.service';
import { WidgetService } from '../widgets.service';
const selector = '.widget-content[selector]';
@Directive({ selector })
export class WidgetContentDirective implements OnInit {
  @Input() selector: string;
  @Input() model: any;
  constructor(public widgetService: WidgetService, public appDynamicComponentService: AppDynamicComponentService, public elementRef: ElementRef, public renderer2: Renderer2) {}

  ngOnInit(): void {
    this.buildContent();
  }

  public buildContent(): void {
    const component = this.widgetService.getWidget(this.selector.toLowerCase());
    const divItemElement = this.renderer2.createElement('div');
    this.renderer2.addClass(divItemElement, 'content-item');
    this.renderer2.appendChild(this.elementRef.nativeElement, divItemElement);
    this.appDynamicComponentService.create(component, divItemElement, { model: this.model });
  }
}
