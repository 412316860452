import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppNpwpComponent } from './app-npwp.component';
@NgModule({
  imports: [VendorModule, AppTooltipWrapperModule],
  declarations: [AppNpwpComponent],
  exports: [AppNpwpComponent]
})
export class AppNpwpModule {}
