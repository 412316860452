import { Component, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../base/angular/base-module.component';
import { WorkPlan } from '../../../bean/work-plan';
import { WorkPlanItem } from '../../../bean/work-plan-item';
import { FieldFormatEnum } from '../../app-table/model/field-format.enum';
import { TableResponseModel } from '../../app-table/model/table-response-model';
@Component({
  templateUrl: './app-popup-work-plan-item-info.component.html'
})
export class AppPopupWorkPlanItemInfoComponent extends BaseModuleComponent {
  @Input() public workPlan: WorkPlan;
  public tableResponse: TableResponseModel<WorkPlanItem>;
  public customData: any;
  public passEntry: EventEmitter<any> = new EventEmitter();

  constructor(translateService: TranslateService) {
    super('app-popup-work-plan-item-info', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'item.code', header: 'table.column.code' },
      { field: 'item.name', header: 'table.column.name' },
      { field: 'quantity', header: 'table.column.quantity' },
      {
        field: 'unitPrice',
        header: 'table.column.unitPrice',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'totalPrice',
        header: 'table.column.totalPrice',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'budget.bookedAmount',
        header: 'table.column.bookedAmount',
        format: FieldFormatEnum.Currency
      }
    ]);
  }
}
