import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';

@Component({
  templateUrl: './vendor-reactivation-detail-vendor.component.html',
  styles: [
    `
      div.vendor-reactivation-detail-vendor div.card div.card-body {
        padding: 1;
      }
    `
  ],
  encapsulation: ViewEncapsulation.None
})
export class VendorReactivationDetailVendorComponent extends BaseModuleComponent {
  public vendorHistoryId: number;
  public vendorId: number;

  constructor(translateService: TranslateService) {
    super('vendor-reactivation', translateService);
    Object.assign(this, this.global.routerParams);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorId = this.global.routerParams.get('vendorId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({});
  }

  public setFormGroup(): void {
    this.setStateReady();
  }

  public doCancel(): void {
    this.router.navigate(['/pages/vendor-reactivation']);
  }

  public get formGroupControls() {
    return this.formGroup.controls;
  }
}
