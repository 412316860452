import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { File } from 'src/app/core/bean/file';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ApprovalModelPrcs } from '../../core/bean/approval-model-prcs';
@Component({
  templateUrl: './notification-loader.component.html'
})
export class NotificationLoaderComponent extends BaseModuleComponent {
  constructor(
    public translateService: TranslateService,
    public activatedRoute: ActivatedRoute
  ) {
    super('notification-loader', translateService);
  }
  public id: number;
  public moduleCode: string;
  public fileId: number;
  public onInit(): void {
    this.getRouterParam();
    this.setStateReady();
  }
  public getRouterParam(): void {
    this.activatedRoute.paramMap.subscribe(param => {
      this.id = +param.get('id');
      this.moduleCode = param.get('moduleCode');
      this.fileId = +param.get('fileId');
    });
    if (this.fileId) {
      this.doDownload();
    } else {
      if (this.moduleCode === 'approval-prcs') {
        this.getApprovalModelPrcs();
      } else if (this.moduleCode === 'pr-verification') {
        this.setPrRouterParam();
      }
    }
  }

  public setPrRouterParam(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('prId', this.id);
    this.router.navigate(['/pages/pr-verification/edit']);
  }

  public getApprovalModelPrcs(): void {
    this.httpClientService
      .get<ApprovalModelPrcs>(
        '/notification-loader/get-approval-model-prcs/' + this.id
      )
      .subscribe(approvalModelPrcs => {
        this.doEditApprovalModelPrcs(approvalModelPrcs);
        this.setStateReady();
      });
  }

  public doEditApprovalModelPrcs(approvalModelPrcs: ApprovalModelPrcs): void {
    this.global.routerParams.clear();
    // const approverPathResponseModel: AppApproverModel = new AppApproverModel(approvalModelPrcs.approvalPrcs.objectId, true);
    this.global.routerParams.set('approvalModelPrcsId', approvalModelPrcs.id);
    this.global.routerParams.set(
      'objectId',
      approvalModelPrcs.approvalPrcs.objectId
    );
    this.global.routerParams.set(
      'objectName',
      approvalModelPrcs.approvalPrcs.objectName
    );
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('isGuest', true);
    // tslint:disable-next-line: max-line-length
    this.global.routerParams.set(
      'vendorStatus',
      approvalModelPrcs.approvalPrcs.code === 'VENDOR_UPDATE_DATA_NON_TDR' ||
        approvalModelPrcs.approvalPrcs.code === 'UPDATE_DATA_TDR' ||
        approvalModelPrcs.approvalPrcs.code === 'CERTIFICATE_EXPIRED'
        ? 'ACTIVE'
        : null
    ); // untuk keperluan informasi hover new
    this.global.routerParams.set('activityStatus', 'APPROVAL'); // masih hardcode
    this.global.routerParams.set('urlBackOutside', '/pages/approval-prcs');
    this.global.routerParams.set('modeApproval', 'edit');
    this.global.routerParams.set('isApproval', true);
    this.router.navigate([approvalModelPrcs.approvalPath]);
  }

  public doDownload() {
    this.httpClientService
    .get<ApprovalModelPrcs>(
      '/notification-loader/get-file/' + this.fileId
    )
    .subscribe((file: File) => {
      if (file) {
        const stringUrl = '/'+this.moduleCode+'/file/view/'+file.uploadedFileName+'/'+file.fileType.code.toUpperCase();
        const fileName = file.fileName;
        this.global.downloadFile(stringUrl, fileName);
      }
      this.router.navigate(['/pages/dashboard']);
      this.setStateReady();
    });
  }
}
