import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorTab } from '../../core/bean/vendor-tab';
import { AccordionItem } from '../../core/components/app-accordion/model/accordion-item-model';
import { AccordionResponseModel } from '../../core/components/app-accordion/model/accordion-response-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { OptionListModel } from './../../core/model/option-list-model';
import { VendorHistoryDetailPopupPrintComponent } from './vendor-history-detail-popup-print.component';
@Component({
  templateUrl: './vendor-history-detail.component.html'
})
export class VendorHistoryDetailComponent extends BaseModuleComponent {
  public vendorId: number;
  public vendorHistoryId: number;
  public versionName: string;
  public vendorHistoryIdList: OptionListModel<any> = new OptionListModel();
  public accordionResponse: AccordionResponseModel<VendorTab>;
  public accordionItemList: Array<AccordionItem<VendorTab>> = new Array();
  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('vendor-history', translate);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.getHistoryId();
    this.buildFormGroup();
  }

  public getListByVendorType(): void {
    this.httpClientService
      .get<VendorTab[]>(
        '/vendor-history/get-list-by-vendor-type/' + this.vendorId
      )
      .subscribe(vendorTabList => {
        if (vendorTabList !== null) {
          vendorTabList.forEach(vendorTab => {
            if (vendorTab !== null && vendorTab.module !== null) {
              const accordionItem: AccordionItem<VendorTab> = {
                header: vendorTab.nameTranslated,
                selector: vendorTab.module.code,
                origin: vendorTab
              };
              this.accordionItemList.push(accordionItem);
            }
          });
          this.doSetAccordionResponse();
          this.setStateReady();
        }
      });
  }

  public doSetAccordionResponse(): void {
    this.accordionResponse = new AccordionResponseModel(
      this.accordionItemList,
      {
        vendorHistoryId: this.vendorHistoryId,
        example:
          "Example instance for all modules. It's looks like a global variable."
      }
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorHistoryIdList: [null]
    });
  }

  public setDataFromRouterParams(): void {
    this.vendorId = this.global.routerParams.get('vendorId');
  }

  public getHistoryId(): void {
    this.httpClientService
      .get<number[]>('/vendor-history/get-list-history-id/' + this.vendorId)
      .subscribe(listHistoryId => {
        const historyList = [];
        let i = 1;

        listHistoryId.forEach((historyId, index) => {
          if (index !== listHistoryId.length - 1) {
            historyList.push({ id: historyId, name: 'Version ' + i });
          } else {
            historyList.push({
              id: historyId,
              name: 'Version ' + i + ' (Latest)'
            });
          }
          i++;
        });
        historyList.sort((a, b) => b.id - a.id);
        this.vendorHistoryIdList.setRequestValues(historyList);
        this.formGroup.patchValue({
          vendorHistoryIdList: historyList[i - historyList.length - 1]
        });
        this.vendorHistoryId = historyList[i - historyList.length - 1].id;
        this.versionName = historyList[i - historyList.length - 1].name;
        this.getListByVendorType();
      });
  }

  public doOnChange(event: any): void {
    this.vendorHistoryId = event.id;
    this.versionName = event.name;
    this.doSetAccordionResponse();
  }

  public doOpenPopupPrint(): void {
    this.appPopupService.open(VendorHistoryDetailPopupPrintComponent, {
      accordionItemList: this.accordionItemList,
      vendorHistoryId: this.vendorHistoryId,
      versionName: this.versionName
    });
  }

  public doBack(): void {
    this.router.navigate(['/pages/vendor-history']);
  }
}
