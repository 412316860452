import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PromiseThemeService } from 'src/app/themes/promise/promise-theme.service';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  selector: 'app-content-sidebar',
  templateUrl: './app-content-sidebar.component.html',
  styleUrls: ['./app-content-sidebar.component.scss']
})
export class AppContentSidebarComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() position: 'left' | 'right';
  @Input() isLoading: boolean;

  constructor(private promiseThemeService: PromiseThemeService) {
    super('app-content-sidebar');
  }

  onInit(): void {
    this.setStateReady();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.isLoading) {
      if (
        !simpleChanges.isLoading.firstChange &&
        simpleChanges.isLoading.currentValue !==
          simpleChanges.isLoading.previousValue &&
        !simpleChanges.isLoading.currentValue
      ) {
        this.promiseThemeService.event.emit({
          type: 'sidebarRequest'
        });
      }
    }
  }
}
