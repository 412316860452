import { NgModule } from '@angular/core';
import { AppButtonModule } from '../../components/app-button/app-button.module';
import { AppCardModule } from '../../components/app-card/app-card.module';
import { AppCheckBoxModule } from '../../components/app-check-box/app-check-box.module';
import { AppDragAndDropModule } from '../../components/app-drag-and-drop/app-drag-and-drop.module';
import { AppPopupModule } from '../../components/app-popup/app-popup.module';
import { AppTableModule } from '../../components/app-table/app-table.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetWelcomeModule } from '../app-widget-welcome/app-widget-welcome.module';
import { AppPopupWidgetWrapperComponent } from './app-popup-widget-wrapper.component';
import { AppWidgetWrapperRowComponent } from './app-widget-wrapper-row.component';
import { AppWidgetWrapperComponent } from './app-widget-wrapper.component';
import { WidgetContentDirective } from './widget-content.directive';
import { WidgetWrapperDirective } from './widget-wrapper.directive';
@NgModule({
  imports: [
    VendorModule,
    AppCheckBoxModule,
    AppCardModule,
    AppWidgetWelcomeModule,
    AppTableModule,
    AppPopupModule,
    AppDragAndDropModule,
    AppButtonModule
  ],
  declarations: [
    AppWidgetWrapperComponent,
    WidgetWrapperDirective,
    WidgetContentDirective,
    AppWidgetWrapperRowComponent,
    AppPopupWidgetWrapperComponent
  ],
  entryComponents: [AppWidgetWrapperComponent, AppPopupWidgetWrapperComponent],
  exports: [
    AppWidgetWrapperComponent,
    WidgetWrapperDirective,
    WidgetContentDirective
  ]
})
export class AppWidgetWrapperModule {}
