import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CustomValidatorError } from '../../core/custom-validator-error';
export class MinLengthValidator {
  public static minLength(minLength: number, message?: string): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors => {
      const value = abstractControl.value;
      if (
        value &&
        ((Array.isArray(value) && value.length) ||
          value.toString().trim().length) < minLength
      ) {
        const defaultError = {
          actualLength: value.length,
          requiredLength: minLength
        };
        return CustomValidatorError.create('minLength', message, defaultError);
      } else {
        return null;
      }
    };
  }
}
