import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { ScheduleStages } from './../../core/bean/schedule-stages';
@Component({
    templateUrl: './schedule-stages.component.html'
  })
  export class ScheduleStagesComponent extends BaseModuleComponent {
    public tableResponse: TableResponseModel<ScheduleStages>;
    constructor(translateService: TranslateService) {
      super('schedule-stages', translateService);
    }

    public onInit(): void {
      this.buildTableResponse();
      this.setStateReady();
    }

    public buildTableResponse(): void {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        // { field: 'code', header: 'table.column.code', customClass: 'text-center' },
        { field: 'module.name', header: 'table.column.name' },
        { field: 'isSchedule', header: 'table.column.isSchedule', datamap: '{"false" : "No" , "true" : "Yes" }', customClass: 'text-center' },
        { field: 'isLocation', header: 'table.column.isLocation', datamap: '{"false" : "No" , "true" : "Yes" }', customClass: 'text-center' },
        { field: 'isStartDate', header: 'table.column.isStartDate', datamap: '{"false" : "No" , "true" : "Yes" }', customClass: 'text-center' },
        { field: 'isEndDate', header: 'table.column.isEndDate', datamap: '{"false" : "No" , "true" : "Yes" }', customClass: 'text-center' },
        { field: 'isStartTime', header: 'table.column.isStartTime', datamap: '{"false" : "No" , "true" : "Yes" }', customClass: 'text-center' },
        { field: 'isEndTime', header: 'table.column.isEndTime', datamap: '{"false" : "No" , "true" : "Yes" }', customClass: 'text-center' }
      ]);
    }

    public doEdit(scheduleStages: ScheduleStages): void {
      this.global.routerParams.clear();
      this.global.routerParams.set('todo', 'edit');
      this.global.routerParams.set('scheduleStagesId', scheduleStages.id);
      this.router.navigate(['/pages/schedule-stages/edit']);
    }
  }
