import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Module } from '../../core/bean/module';
import { ModuleGroup } from '../../core/bean/module-group';
import { NumberGeneratorValue } from '../../core/bean/number-generator-value';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { NumberGeneratorResponse } from './number-generator-response';
@Component({
  templateUrl: './number-generator-edit-add.component.html'
})
export class NumberGeneratorEditAddComponent extends BaseModuleComponent {
  public numberGeneratorValue: NumberGeneratorValue =
    new NumberGeneratorValue();
  public module: Module;
  public moduleGroupList: ModuleGroup[];
  public moduleGroupName1: string;
  public moduleGroupName2: string;
  public moduleGroupName3: string;
  constructor(translateService: TranslateService) {
    super('number-generator', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.numberGeneratorValue = this.global.routerParams.get(
      'numberGeneratorValue'
    );
    this.module = this.global.routerParams.get('module');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      moduleGroup: [null],
      module: [this.module],
      format: [null, Validators.required()],
      organization: [this.global.userSession.user.organization]
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .post<NumberGeneratorResponse>(
        '/number-generator/add-edit',
        new RouteRequestModel(this.todo, this.numberGeneratorValue.id)
      )
      .subscribe((numberGeneratorResponse: NumberGeneratorResponse) => {
        this.moduleGroupList = numberGeneratorResponse.moduleGroupList;
        this.moduleGroupName1 = this.module.moduleGroupCode;
        const moduleGroupNameSplit = this.moduleGroupName1.split(',');
        for (const [index, moduleGroupName] of moduleGroupNameSplit.entries()) {
          for (const moduleGroup of this.moduleGroupList) {
            if (moduleGroupName === moduleGroup.code) {
              if (moduleGroupNameSplit.length > 1) {
                if (index === 0) {
                  this.moduleGroupName1 = moduleGroup.name;
                } else {
                  this.moduleGroupName2 = moduleGroup.name;
                  this.moduleGroupName3 = this.moduleGroupName1.concat(
                    ', ',
                    this.moduleGroupName2
                  );
                  this.moduleGroupName1 = this.moduleGroupName3;
                }
              } else {
                this.moduleGroupName3 = moduleGroup.name;
              }
            }
          }
        }
        this.formGroup.patchValue({ moduleGroup: this.moduleGroupName3 });
        if (numberGeneratorResponse.numberGeneratorValue != null) {
          this.formGroup.patchValue({
            id: numberGeneratorResponse.numberGeneratorValue.id,
            format: numberGeneratorResponse.numberGeneratorValue.format,
            organization:
              numberGeneratorResponse.numberGeneratorValue.organization
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const numberGeneratorValue = this.global.copyFormAttributeToModel(
              this.numberGeneratorValue,
              this.formGroup
            );
            const url =
              this.numberGeneratorValue.id === null
                ? '/number-generator/insert'
                : '/number-generator/update';
            this.httpClientService
              .post<Response<NumberGeneratorValue>>(url, numberGeneratorValue)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/number-generator/detail']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/number-generator/detail']);
  }
}
