import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Organization } from 'src/app/core/bean/organization';
import { OrganizationDoc } from 'src/app/core/bean/organization-doc';
import { Region } from 'src/app/core/bean/region';
import { FileObject, FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Response } from '../../core/model/response-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { OrganizationRequest } from './organization-request';
import { OrganizationResponse } from './organization-response';
import { Zone } from 'src/app/core/bean/zone';
import { HospitalClass } from 'src/app/core/bean/hospital-class';
@Component({
  templateUrl: './organization-edit-add.component.html'
})
export class OrganizationEditAddComponent extends BaseModuleComponent {
  public organizationId: number;
  public parentIdAdd: number;
  public hasChild = false;
  public isEdit = false;

  public togglePrimaryColor = false;
  public toggleColorOnPrimaryColor = false;
  public togglePrimaryDarkColor = false;
  public toggleColorOnPrimaryDarkColor = false;
  public toggleTableHeaderColor = false;
  public toggleColorOnTableHeaderColor = false;
  public toggleTableBodyColor = false;
  public toggleColorOnTableBodyColor = false;
  public toggleTextColor = false;
  public isDistributor = false;
  public isMandatory = false;
  public isUseDate = false;
  public isBranch = false;

  public mainLogoUploader = new FileUploader(
    '/organization/',
    '',
    this.global.appConstant.fileType.IMG_MAIN_LOGO,
    true,
    1
  );
  public smallLogoUploader = new FileUploader(
    '/organization/',
    '',
    this.global.appConstant.fileType.IMG_SMALL_LOGO,
    true,
    1
  );
  public reportLogoUploader = new FileUploader(
    '/organization/',
    '',
    this.global.appConstant.fileType.IMG_REPORT_LOGO,
    true,
    1
  );
  public organizationRequestSave: OrganizationRequest;
  public organizationResponseTemp: OrganizationResponse;
  public zoomPercentage: number;

  primaryColor: string;
  colorOnPrimaryColor: string;
  primaryDarkColor: string;
  colorOnPrimaryDarkColor: string;
  tableHeaderColor: string;
  colorOnTableHeaderColor: string;
  tableBodyColor: string;
  colorOnTableBodyColor: string;
  textColor: string;

  public countryRegionOptionList: OptionListModel<Region> = new OptionListModel();
  public provinceRegionOptionList: OptionListModel<Region> = new OptionListModel();
  public cityRegionOptionList: OptionListModel<Region> = new OptionListModel();
  public regionPhoneOptionList: OptionListModel<Region> = new OptionListModel();
  public fileUploaderList: FileUploader[] = [];
  public mandatoryOrganizationDoc: Array<any>;
  public showDateOrganizationDoc: Array<any>;
  public zoneOptionList: OptionListModel<Zone> = new OptionListModel(false);
  public hospitalClassOptionList: OptionListModel<HospitalClass> = new OptionListModel(false);

  constructor(translateService: TranslateService, public datePipe: DatePipe) {
    super('organization', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.setInitializationState();
    this.doBuildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.organizationId = this.global.routerParams.get('organizationId');
    this.parentIdAdd = this.global.routerParams.get('parentIdAdd');
    if (this.todo === 'edit') {
      this.isEdit = true;
    }
  }

  public setOptionListModel(): void {
    this.countryRegionOptionList.setRequestValues(
      this.organizationResponseTemp.countryRegionList
    );
    this.provinceRegionOptionList.setRequestValues(
      this.organizationResponseTemp.provinceRegionList
    );
    this.cityRegionOptionList.setRequestValues(
      this.organizationResponseTemp.cityRegionList
    );
    this.zoneOptionList.setRequestValues(
      this.organizationResponseTemp.zoneList
    );
    this.hospitalClassOptionList.setRequestValues(
      this.organizationResponseTemp.hospitalClassList
    );
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.minLength(3, this.translateService.instant('app.validation.minimumCaracter') + '3'),
          Validators.maxLength(32)
        ])
      ],
      name: [null, Validators.compose([Validators.required()])],
      hierarchyLevel: [null],
      letterNumberCode: [null],
      mainLogoFile: [null],
      smallLogoFile: [null],
      reportLogoFile: [null],
      isDistributor: [false],
      isBranch: [false],
      distributorLocationCode: [null],
      countryRegion: [null, Validators.required()],
      provinceRegion: [null, Validators.required()],
      cityRegion: [null, Validators.required()],
      phone: [null, Validators.required()],
      fax: [null, Validators.required()],
      email: [null, Validators.compose([Validators.email(), Validators.required()])],
      website: [null, Validators.required()],
      address: [null, Validators.required()],
      tempId: [null],
      parentId: [null],
      isHasChild: [null],
      primaryColor: [null],
      colorOnPrimaryColor: [null],
      primaryDarkColor: [null],
      colorOnPrimaryDarkColor: [null],
      tableHeaderColor: [null],
      colorOnTableHeaderColor: [null],
      tableBodyColor: [null],
      colorOnTableBodyColor: [null],
      organizationDocList: this.formBuilder.array([]),
      hospitalCode: [null],
      zone: [null, Validators.required()],
      hospitalClass: [null, Validators.required()],
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<OrganizationResponse>(
        '/organization/add-edit',
        new RouteRequestModel(this.todo, this.organizationId)
      )
      .subscribe(organizationResponse => {
        this.organizationResponseTemp = organizationResponse;
        this.setOptionListModel();
        if (organizationResponse.organization != null) {
          if (organizationResponse.organization.isHasChild != null) {
            this.hasChild = organizationResponse.organization.isHasChild;
          }
          if (organizationResponse.organization.mainLogoFile != null) {
            this.mainLogoUploader.setFile(
              organizationResponse.organization.mainLogoFile
            );
          }
          if (organizationResponse.organization.smallLogoFile != null) {
            this.smallLogoUploader.setFile(
              organizationResponse.organization.smallLogoFile
            );
          }
          if (organizationResponse.organization.reportLogoFile != null) {
            this.reportLogoUploader.setFile(
              organizationResponse.organization.reportLogoFile
            );
          }
          this.isDistributor = organizationResponse.organization.isDistributor ? organizationResponse.organization.isDistributor : false;
          this.isBranch = organizationResponse.organization.isBranch ? organizationResponse.organization.isBranch : false;
          this.formGroup.patchValue({
            id: organizationResponse.organization.id,
            code: organizationResponse.organization.code,
            name: organizationResponse.organization.name,
            isDistributor: organizationResponse.organization.isDistributor ? organizationResponse.organization.isDistributor : false,
            isBranch: organizationResponse.organization.isBranch ? organizationResponse.organization.isBranch : false,
            distributorLocationCode: organizationResponse.organization.distributorLocationCode,
            countryRegion: organizationResponse.organization.countryRegion,
            provinceRegion: organizationResponse.organization.provinceRegion,
            cityRegion: organizationResponse.organization.cityRegion,
            phone: organizationResponse.organization.phone,
            fax: organizationResponse.organization.fax,
            email: organizationResponse.organization.email,
            website: organizationResponse.organization.website,
            address: organizationResponse.organization.address,
            parentId: organizationResponse.organization.parentId,
            level: organizationResponse.organization.level,
            hierarchyLevel: organizationResponse.organization.hierarchyLevel,
            letterNumberCode:
              organizationResponse.organization.letterNumberCode,
            mainLogoFile: this.mainLogoUploader.fileObjectList,
            smallLogoFile: this.smallLogoUploader.fileObjectList,
            reportLogoFile: this.reportLogoUploader.fileObjectList,
            hasChild: organizationResponse.organization.hasChild,
            hospitalCode: organizationResponse.organization.hospitalCode,
            zone: organizationResponse.organization.zone,
            hospitalClass: organizationResponse.organization.hospitalClass,
          });
        }
        this.mandatoryOrganizationDoc = organizationResponse.mandatoryOrganizationDoc;
        this.showDateOrganizationDoc = organizationResponse.showDateOrganizationDoc;
        this.onChangeIsBranch();
        this.setColor(false);
        this.setStateReady();
      });

  }

  public doResetColor(): void {
    this.setColor(true);
  }

  public setColor(isReset: boolean): void {
    if (isReset) {
      this.primaryColor = this.organizationResponseTemp.defaultPrimaryColor;
      this.colorOnPrimaryColor =
        this.organizationResponseTemp.defaultColorOnPrimaryColor;
      this.primaryDarkColor =
        this.organizationResponseTemp.defaultPrimaryDarkColor;
      this.colorOnPrimaryDarkColor =
        this.organizationResponseTemp.defaultColorOnPrimaryDarkColor;
      this.tableHeaderColor =
        this.organizationResponseTemp.defaultTableHeaderColor;
      this.colorOnTableHeaderColor =
        this.organizationResponseTemp.defaultColorOnTableHeaderColor;
      this.tableBodyColor = this.organizationResponseTemp.defaultTableBodyColor;
      this.colorOnTableBodyColor =
        this.organizationResponseTemp.defaultColorOnTableBodyColor;
      this.textColor = this.organizationResponseTemp.defaultTextColor;
    } else {
      this.primaryColor = this.organizationResponseTemp.primaryColor;
      this.colorOnPrimaryColor =
        this.organizationResponseTemp.colorOnPrimaryColor;
      this.primaryDarkColor = this.organizationResponseTemp.primaryDarkColor;
      this.colorOnPrimaryDarkColor =
        this.organizationResponseTemp.colorOnPrimaryDarkColor;
      this.tableHeaderColor = this.organizationResponseTemp.tableHeaderColor;
      this.colorOnTableHeaderColor =
        this.organizationResponseTemp.colorOnTableHeaderColor;
      this.tableBodyColor = this.organizationResponseTemp.tableBodyColor;
      this.colorOnTableBodyColor =
        this.organizationResponseTemp.colorOnTableBodyColor;
      this.textColor = this.organizationResponseTemp.textColor;
    }

    this.formGroup.patchValue({
      primaryColor: this.primaryColor,
      colorOnPrimaryColor: this.colorOnPrimaryColor,
      primaryDarkColor: this.primaryDarkColor,
      colorOnPrimaryDarkColor: this.colorOnPrimaryDarkColor,
      tableHeaderColor: this.tableHeaderColor,
      colorOnTableHeaderColor: this.colorOnTableHeaderColor,
      tableBodyColor: this.tableBodyColor,
      colorOnTableBodyColor: this.colorOnTableBodyColor,
      textColor: this.textColor
    });
  }

  public onChangeIsDistribution(): void {
    if (!this.formGroup.get('isDistributor').value) {
      this.isDistributor = false;
      this.isBranch = false;
      this.formGroup.get('isBranch').setValue(false);
      this.onChangeIsBranch();
    } else {
      this.isDistributor = true;
      let index = 0;
      this.organizationResponseTemp.organizationDocList.forEach(organizationDoc => {
        organizationDoc.id = 0;
        this.organizationDoclist.removeAt(index);
        index + 1;
      });
    }
  }

  public onChangeIsBranch(): void {
    if (!this.formGroup.get('isBranch').value) {
      this.isBranch = false;
      if (!this.formGroup.get('isDistributor').value) {
        this.setorganizationDoc(this.organizationResponseTemp.organizationDocList);
      }
    } else {
      this.isBranch = true;
      let index = 0;
      this.organizationResponseTemp.organizationDocList.forEach(organizationDoc => {
        organizationDoc.id = 0;
        this.organizationDoclist.removeAt(index);
        index + 1;
      });
    }
  }

  public doSave(): void {
    if (!this.isDistributor && !this.isBranch) {
      this.formGroup.get('distributorLocationCode').setValidators(Validators.required());
      this.formGroup.get('hospitalCode').setValidators(Validators.required());
      if (this.formGroup.get('distributorLocationCode').value === null || this.formGroup.get('distributorLocationCode').value === '') {
        this.formGroup.get('distributorLocationCode').setErrors({
          message: this.translateService.instant('app.validation.required')
        });
      }
      if (this.formGroup.get('hospitalCode').value === null || this.formGroup.get('hospitalCode').value === '') {
        this.formGroup.get('hospitalCode').setErrors({
          message: this.translateService.instant('app.validation.required')
        });
      }
    } else {
      this.formGroup.get('distributorLocationCode').clearValidators();
      this.formGroup.get('hospitalCode').clearValidators();
      this.formGroup.patchValue({
        distributorLocationCode: null,
        hospitalCode: null,
        organizationDoclist: null
      });
    }
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .newSaveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const organizationRequest: OrganizationRequest =
              this.prepareOrganizationRequestResponse();
            const url =
              this.todo === 'edit'
                ? '/organization/update'
                : '/organization/insert';
            this.httpClientService
              .post<Response<OrganizationRequest>>(url, organizationRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService.saveSuccessWithoutButton();
                  this.router.navigate(['/pages/organization/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doDelete(): void {
    this.global.modalService
      .newDeleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<number>('/organization/delete', this.organizationId)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.modalService.confirmation(
                  'organization.confirmation.deleteSuccess.prompt',
                  'organization.confirmation.deleteSuccess.title',
                  null,
                  null,
                  'pvc pv-trash',
                  false,
                  {
                    autoCloseInMs: 1000
                  }
                );
                this.router.navigate(['/pages/organization/']);
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/organization/']);
  }

  public prepareOrganizationRequestResponse(): OrganizationRequest {
    const organizationRequest: OrganizationRequest = new OrganizationRequest();
    const organization: Organization = new Organization();
    if (this.todo === 'edit') {
      organization.id = this.organizationId;
    }
    organization.code = this.formGroup.value.code.trim();
    organization.name = this.formGroup.value.name;
    organization.parentId = this.parentIdAdd;
    organization.isDistributor = this.formGroup.value.isDistributor;
    organization.isBranch = this.formGroup.value.isBranch;
    organization.distributorLocationCode = this.formGroup.value.distributorLocationCode;
    organization.countryRegion = this.formGroup.value.countryRegion;
    organization.provinceRegion = this.formGroup.value.provinceRegion;
    organization.cityRegion = this.formGroup.value.cityRegion;
    organization.phone = this.formGroup.value.phone;
    organization.fax = this.formGroup.value.fax;
    organization.email = this.formGroup.value.email;
    organization.website = this.formGroup.value.website;
    organization.address = this.formGroup.value.address;
    organization.zone = this.formGroup.value.zone;
    organization.hospitalClass = this.formGroup.value.hospitalClass;
    organizationRequest.organization = organization;
    organization.hospitalCode = this.formGroup.value.hospitalCode.trim();

    const fileObjectList: FileObject[] = [];
    const mainLogoFileObjectList = this.formGroup.value.mainLogoFile;
    const smallLogoFileObjectList = this.formGroup.value.smallLogoFile;
    const reportLogoFileObjectList = this.formGroup.value.reportLogoFile;
    if (mainLogoFileObjectList != null) {
      fileObjectList.push(mainLogoFileObjectList[0]);
    }
    if (smallLogoFileObjectList != null) {
      fileObjectList.push(smallLogoFileObjectList[0]);
    }
    if (reportLogoFileObjectList != null) {
      fileObjectList.push(reportLogoFileObjectList[0]);
    }
    organizationRequest.fileObjectList = fileObjectList;

    const fileObjectListDoc: FileObject[] = [];
    this.formGroup.value.organizationDocList.forEach(element => {
      if (element.file !== null && element.file !== undefined) {
        fileObjectListDoc.push(element.file[0]);
      }
      element.file = null;
    });
    organizationRequest.fileObjectListDoc = fileObjectListDoc;
    organizationRequest.organizationDocList = this.formGroup.value.organizationDocList;

    organizationRequest.primaryColor = this.formGroup.value.primaryColor;
    organizationRequest.colorOnPrimaryColor =
      this.formGroup.value.colorOnPrimaryColor;
    organizationRequest.primaryDarkColor =
      this.formGroup.value.primaryDarkColor;
    organizationRequest.colorOnPrimaryDarkColor =
      this.formGroup.value.colorOnPrimaryDarkColor;
    organizationRequest.tableHeaderColor =
      this.formGroup.value.tableHeaderColor;
    organizationRequest.colorOnTableHeaderColor =
      this.formGroup.value.colorOnTableHeaderColor;
    organizationRequest.tableBodyColor = this.formGroup.value.tableBodyColor;
    organizationRequest.colorOnTableBodyColor =
      this.formGroup.value.colorOnTableBodyColor;
    // organizationRequest.textColor = this.formGroup.value.textColor;

    return organizationRequest;
  }

  public updatePrimaryColor(event: any): void {
    this.formGroup.get('primaryColor').setValue(event);
  }

  public updateColorOnPrimary(event: any): void {
    this.formGroup.get('colorOnPrimaryColor').setValue(event);
  }

  public updatePrimaryDarkColor(event: any): void {
    this.formGroup.get('primaryDarkColor').setValue(event);
  }

  public updateColorOnPrimaryDark(event: any): void {
    this.formGroup.get('colorOnPrimaryDarkColor').setValue(event);
  }

  public updateTableHeaderColor(event: any): void {
    this.formGroup.get('tableHeaderColor').setValue(event);
  }

  public updateOnTableHeaderColor(event: any): void {
    this.formGroup.get('colorOnTableHeaderColor').setValue(event);
  }

  public updateTableBodyColor(event: any): void {
    this.formGroup.get('tableBodyColor').setValue(event);
  }

  public updateColorOnTableBody(event: any): void {
    this.formGroup.get('colorOnTableBodyColor').setValue(event);
  }

  // public updateTextColor(event: any) {
  //   this.formGroup.get("textColor").setValue(event);
  // }

  public doZoom(command: 'in' | 'out'): void {
    if (
      (this.zoomPercentage > 0 && command === 'out') ||
      (this.zoomPercentage < 200 && command === 'in')
    ) {
      this.zoomPercentage =
        command === 'in' ? this.zoomPercentage + 25 : this.zoomPercentage - 25;
      // const scale = this.zoomPercentage / 100;
      // this.treeElement.style.transform = `scale(${scale})`;
    }
  }

  public doReset(): void {
    this.zoomPercentage = 100;
    // this.treeElement.style.transform = 'scale(1)';
  }

  private setInitializationState(): void {
    this.zoomPercentage = 100;
  }

  public doChangeCountryRegion(): void {
    this.formGroupService.bulkResetFormControl(this.formGroup, [
      'provinceRegion',
      'cityRegion'
    ]);
    this.httpClientService
      .post<Region[]>(
        '/organization/change-region-list',
        this.formGroup.value.countryRegion.id
      )
      .subscribe(provinceRegionList => {
        this.organizationResponseTemp.provinceRegionList =
          provinceRegionList;
        this.provinceRegionOptionList.setRequestValues(provinceRegionList);
        this.cityRegionOptionList.reset();
      });
  }

  public doChangeProvinceRegion(): void {
    this.formGroupService.bulkResetFormControl(this.formGroup, [
      'cityRegion'
    ]);
    this.httpClientService
      .post<Region[]>(
        '/organization/change-region-list',
        this.formGroup.value.provinceRegion.id
      )
      .subscribe(cityRegionList => {
        this.organizationResponseTemp.cityRegionList = cityRegionList;
        this.cityRegionOptionList.setRequestValues(cityRegionList);
      });
  }

  public get organizationDoclist(): FormArray {
    return this.formGroup.get('organizationDocList') as FormArray;
  }

  public fileUploader(code: string): FileUploader {
    return new FileUploader('/organization/', 'document', code);
  }

  public setorganizationDoc(organizationDoclist: OrganizationDoc[]): void {
    let index = 0;
    organizationDoclist.forEach(organizationDoc => {

      this.mandatoryOrganizationDoc.forEach(mandatoryOrganizationDoc => {
        if (mandatoryOrganizationDoc === organizationDoc.fileType.code) {
          this.isMandatory = true
        }
      });
      this.showDateOrganizationDoc.forEach(showDateOrganizationDoc => {
        if (showDateOrganizationDoc === organizationDoc.fileType.code) {
          this.isUseDate = true
        }
      });

      const fileUpload: FileUploader = new FileUploader(
        '/organization/',
        '',
        organizationDoc.fileType.code,
        true,
        1
      );
      this.fileUploaderList.push(fileUpload);
      fileUpload.setFile(organizationDoc.file);
      const startDate: Date = organizationDoc.startDate
        ? new Date(organizationDoc.startDate)
        : null;
      const endDate: Date = organizationDoc.endDate
        ? new Date(organizationDoc.endDate)
        : null;

      const formGroup = this.formBuilder.group({
        id: organizationDoc.id,
        fileType: organizationDoc.fileType,
        number: this.isMandatory ? [organizationDoc.number, Validators.required()] : organizationDoc.number,
        publisher: this.isMandatory ? [organizationDoc.publisher, Validators.required()] : organizationDoc.publisher,
        startDate: this.isUseDate ? (this.isMandatory ? [startDate, Validators.required()] : startDate) : startDate,
        endDate: this.isUseDate ? (this.isMandatory ? [endDate, Validators.required()] : endDate) : endDate,
        file: this.isMandatory ? [fileUpload.fileObjectList, Validators.required()] : fileUpload.fileObjectList,
        currentDate: new Date(),
        index: index,
        isMandatory: this.isMandatory,
        isUseDate: this.isUseDate
      });
      this.organizationDoclist.push(formGroup);
      index += 1;
      this.isMandatory = false;
      this.isUseDate = false;
    }
    );
  }
}
