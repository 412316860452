import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppItemRequestModule } from '../../core/components/app-item-request/app-item-request.module';
import { AppProcurementInfoModule } from '../../core/components/app-procurement-info/app-procurement-info.module';
import { AppWorkflowStepInfoModule } from '../../core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupRefutationVendorItemDetailComponent } from './app-popup-refutation-vendor-item-detail.component';
import { AppPopupRefutationVendorTocComponent } from './app-popup-refutation-vendor-toc.component';
import { RefutationVendorEditComponent } from './refutation-vendor-edit.component';
import { RefutationVendorItemSubmissionComponent } from './refutation-vendor-item-submission.component';
import { RefutationVendorComponent } from './refutation-vendor.component';
export const routes = [
  {
    path: '',
    component: RefutationVendorComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'detail',
    component: RefutationVendorEditComponent,
    data: { breadcrumb: 'refutation-vendor.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail-submission',
    component: RefutationVendorItemSubmissionComponent,
    data: { breadcrumb: 'refutation-vendor.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: RefutationVendorEditComponent,
    data: { breadcrumb: 'refutation-vendor.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    AppWorkflowStepInfoModule,
    AppProcurementInfoModule,
    AppItemRequestModule,
    AppBadgeCatalogModule
  ],
  declarations: [
    RefutationVendorComponent,
    RefutationVendorEditComponent,
    RefutationVendorItemSubmissionComponent,
    AppPopupRefutationVendorTocComponent,
    AppPopupRefutationVendorItemDetailComponent
  ],
  entryComponents: [
    AppPopupRefutationVendorTocComponent,
    AppPopupRefutationVendorItemDetailComponent
  ],
  exports: [
    AppPopupRefutationVendorTocComponent,
    AppPopupRefutationVendorItemDetailComponent
  ]
})
export class RefutationVendorModule {}
