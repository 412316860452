import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { ProcurementContentManagementComponent } from './procurement-content-management.component';
const routes = [
  {
    path: '',
    component: ProcurementContentManagementComponent,
    data: { breadcrumb: 'index' }
  },
  {
    path: 'refutation-toc',
    loadChildren: () =>
      import('./refutation-toc/refutation-toc.module').then(
        m => m.RefutationTocModule
      ),
    data: {
      breadcrumb: 'procurement-content-management.breadcrumb.refutationToc'
    }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ProcurementContentManagementComponent]
})
export class ProcurementContentManagementModule {}
