import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { Item } from '../../../bean/item';
import { TableResponseModel } from '../../app-table/model/table-response-model';

@Component({
  templateUrl: './app-popup-item.component.html'
})
export class AppPopupItemComponent extends BaseComponentComponent {
  @Input() condition: any;
  @Input() itemList: Item[];
  @Input() itemIdList: number[];
  @Input() itemCategoryCode: string;
  @Output() onChange: EventEmitter<Item> = new EventEmitter();

  itemCustomData: any;
  tableResponse: TableResponseModel<Item>;
  itemId: number[] = [];

  constructor() {
    super('app-popup-item');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setItemCustomData();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      itemIdList: [null],
      itemCategoryCode: [null]
    });
  }

  public setItemCustomData(): void {
    this.formGroup.patchValue({
      itemIdList: this.itemIdList,
      itemCategoryCode: this.itemCategoryCode
    });
    this.itemCustomData = JSON.stringify(this.formGroup.value);
  }

  buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.column.code' },
      { field: 'name', header: 'table.column.name' },
      { field: 'uom.name', header: 'table.column.uom.name' }
    ]);
  }

  doChoose(item: Item): void {
    this.onChange.emit(item);
  }
}
