import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { take } from 'rxjs/operators';
import {} from 'src/app/pages/example/employee/dto/employee.dto';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Fines } from '../../bean/fines';
import { AppPopupFinesService } from '../app-popup/app-popup-fines/app-popup-fines.service';
import { TablePluginData } from '../table/interface/table-plugin-data';
import { SelectedTableRecordModel } from '../table/model/selected-table-record-model';
import { TableResponseModel } from '../table/model/table-response-model';

@Component({
  selector: 'app-fines',
  templateUrl: './app-fines.component.html'
})
export class AppFinesComponent extends BaseComponentComponent {
  @Input() public finesList: Fines[] = [];
  @Input() public isView: boolean;
  @Input() public amount: number;
  @Input() public isDisabled = false;
  @Output() public onChange: EventEmitter<Fines[]> = new EventEmitter();
  public tableResponse: TableResponseModel<Fines>;

  constructor(public appPopupFinesService: AppPopupFinesService) {
    super('app-fines');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      finesLists: this.formBuilder.array([])
    });
  }
  public get finesLists(): FormArray {
    return this.formGroup.get('finesLists') as FormArray;
  }

  public setFormGroup(): void {
    this.finesList.forEach((fines: Fines) => {
      const formGroup = this.formBuilder.group({
        id: fines.id,
        finesMaster: fines.finesMaster,
        percentageValue: fines.percentageValue,
        description: fines.description,
        amount: fines.amount
      });
      this.finesLists.push(formGroup);
      this.buildTableResponse();
    });
  }

  public setPercentageValue(total: number): string {
    if (total === null || (total && total === 0)) {
      return '0.00 %';
    } else {
      return total.toString() + ' %';
    }
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'finesMaster.name',
        header: 'table.column.type',
        plugins: { name: 'hyperlink', onClick: this.doClick.bind(this) }
      },
      {
        field: 'percentageValue',
        header: 'table.column.percentageValue',
        className: 'text-right',
        plugins: {
          name: 'custom-plugin',
          before: (tablePlugin: TablePluginData): string => {
            return (
              this.global.converterService.convertDecimal(tablePlugin.value) +
              ' %'
            );
          }
        }
      },
      {
        field: 'amount',
        header: 'table.column.amount',
        className: 'text-right',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      },
      {
        field: 'description',
        header: 'table.column.description',
        className: 'white-space-normal'
      }
    ]);
    this.tableResponse.setRecordList(this.finesLists.value);
  }

  public doAdd(): void {
    const todo = 'add';
    const amount = this.amount;
    const finesList = this.finesLists.value;
    this.appPopupFinesService
      .openPo(todo, amount, finesList)
      .subscribe(fines => {
        const formGroup = this.formBuilder.group({
          id: fines.id,
          finesMaster: fines.finesMaster,
          percentageValue: fines.percentageValue,
          description: fines.description,
          amount: fines.amount
        });
        this.finesLists.push(formGroup);
        this.tableResponse.setRecordList(this.finesLists.value);
        this.tableResponse.reload();
        this.onChange.emit(this.finesLists.value);
      });
  }

  public doClick(finesEdit): void {
    let todo = '';
    if (this.isView) {
      todo = 'view';
    } else {
      todo = 'edit';
    }
    const amount = this.amount;
    const finesList = this.finesLists.value;
    this.appPopupFinesService
      .openPo(todo, amount, finesList, finesEdit)
      .subscribe(finesTemp => {
        this.finesLists.value.forEach((fines, index) => {
          if (JSON.stringify(fines) === JSON.stringify(finesEdit)) {
            this.finesLists.value[index] = finesTemp;
            this.finesLists.controls[index].patchValue(finesTemp);
          }
        });
        this.tableResponse.setRecordList(this.finesLists.value);
        this.tableResponse.reload();
        this.onChange.emit(this.finesLists.value);
      });
  }

  public doDelete(event: SelectedTableRecordModel): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach((record: Fines) => {
            const indexOfRecord = this.finesLists.controls.findIndex(
              r => r.value.finesMaster.id === record.finesMaster.id
            );
            if (indexOfRecord !== -1) {
              this.finesLists.value.splice(indexOfRecord, 1);
              this.finesLists.removeAt(indexOfRecord);
            }
          });
          this.tableResponse.setRecordList(this.finesLists.value);
          this.tableResponse.reload();
          this.onChange.emit(this.finesLists.value);
        }
      });
  }
}
