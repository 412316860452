import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { WorkflowAdditionalPopupComponent } from './workflow-additional-popup.component';
import { WorkflowEditAddComponent } from './workflow-edit-add.component';
import { WorkflowComponent } from './workflow.component';
const routes = [
  { path: '', component: WorkflowComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: WorkflowEditAddComponent,
    data: { breadcrumb: 'workflow.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: WorkflowEditAddComponent,
    data: { breadcrumb: 'workflow.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    WorkflowComponent,
    WorkflowEditAddComponent,
    WorkflowAdditionalPopupComponent
  ],
  entryComponents: [WorkflowAdditionalPopupComponent]
})
export class WorkflowModule {}
