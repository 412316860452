import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorOwnership } from '../../core/bean/vendor-ownership';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: './vendor-ownership-edit-add.component.html'
})
export class VendorOwnershipEditAddComponent extends BaseModuleComponent {
  public vendorOwnershipId: number;
  constructor(translateService: TranslateService) {
    super('vendor-ownership', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorOwnershipId = this.global.routerParams.get('vendorOwnershipId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      description: [null, Validators.maxLength(512)]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<VendorOwnership>(
        '/vendor-ownership/add-edit',
        new RouteRequestModel(this.todo, this.vendorOwnershipId)
      )
      .subscribe(vendorOwnership => {
        if (vendorOwnership != null) {
          const { id, name, code, description } = vendorOwnership;
          this.formGroup.patchValue({ id, name, code, description });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.saveVendorOwnership();
          }
        });
    }
  }

  public saveVendorOwnership(): void {
    this.setStateProcessing();
    const vendorOwnership: VendorOwnership = this.global.copyFormAttributeToModel(
      new VendorOwnership(),
      this.formGroup
    );
    const url: string =
      this.todo === 'edit'
        ? '/vendor-ownership/update'
        : '/vendor-ownership/insert';
    this.httpClientService
      .post<Response<VendorOwnership>>(url, vendorOwnership)
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccessSavingOnNextRoute();
          this.router.navigate(['/pages/vendor-ownership/']);
        } else {
          this.global.alertService.showError(response.statusText);
        }
        this.setStateReady();
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/vendor-ownership/']);
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
}
