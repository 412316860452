<div class="row main-row">
  <div class="col-12">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
      <app-accordion formArrayName="prItemList" [isLoading]="formLoading">
        <app-accordion-item
          header="{{ 'initiation.accordion.itemRequest' | translate }} {{ i + 1 }}"
          *ngFor="
            let prItem of formGroup.controls.prItemList.controls;
            let i = index
          "
          [formGroupName]="i"
          [isExpanded]="i === prItemList.length - 1"
        >
          <ng-container *ngIf="!prItem.value.catalog">
            <app-fullfilled></app-fullfilled>
            <ng-template #headerRight *ngIf="!formGroup.isView">
              <em
                *ngIf="prItemList.value.length > 1"
                class="pir pi-trash-alt float-right"
                [ngStyle]="{ 'padding-right': '10px' }"
                (click)="doDeletePrItem(i)"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
              ></em>
            </ng-template>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'initiation.form.itemName' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-auto-complete
                  formControlName="item"
                  stringUrl="/pr/auto-complete-item-list"
                  (onChange)="autoCompleteValueChange($event)"
                ></app-auto-complete>
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{ 'initiation.form.uom' | translate }}</label>
              <div class="col-sm-9 text-left">
                {{ prItem.value.item ? prItem.value.item?.uom.name : '-' }}
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'initiation.form.quantity' | translate
              }}</label>
              <div class="col-sm-4 text-left">
                <app-count
                  *ngIf="!formGroup.isView"
                  formControlName="quantity"
                  maxLength="32"
                  [min]="1"
                  (onChange)="onChangeQuantity(i)"
                  (input)="onChangeQuantity(i)"
                  size="LG"
                ></app-count>
              </div>
            </div>
            <div class="form-group row text-sm-left" *ngIf="todo !== 'view'">
              <label class="col-sm-3">{{ 'initiation.form.price' | translate }}</label>
              <div class="col-sm-9 text-left">
                <app-currency
                  formControlName="prices"
                  [optionList]="currencyOptionList"
                  (onChange)="onChangeQuantity(i)"
                ></app-currency>
              </div>
            </div>
            <div class="form-group row text-sm-left" *ngIf="!formLoading">
              <label class="col-sm-3">{{
                'initiation.form.totalPrice' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{
                  prItem.value.totalPrice
                    ? global.converterService.convertMoney(
                        prItem.value.totalPrice
                      )
                    : '0'
                }}
                {{ prResponse.companyCurrency.code }}
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'initiation.form.specification' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-wysiwyg
                  size="LG"
                  formControlName="specification"
                  maxLength="32"
                ></app-wysiwyg>
              </div>
            </div>
            <div class="form-group row text-sm-left">
              <label class="col-sm-3">{{
                'initiation.form.itemImages' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-upload-images
                  formControlName="itemImages"
                  [fileUploader]="fileUploader"
                ></app-upload-images>
              </div>
            </div>
          </ng-container>
        </app-accordion-item>
      </app-accordion>
      <div
        class="col-12 mt-5 text-center button-add-more-item"
        *ngIf="!formGroup.isView"
      >
        <app-button (onClick)="addPrItem()" [disabled]="!formGroup.valid"
          ><em class="pir pi-plus-circle"></em>
          {{ 'initiation.button.addOtherItem' | translate }}</app-button
        >
      </div>
      <div class="button-group button-group-center mt-5">
        <app-button
          (onClick)="addToItemRequest()"
          [disabled]="formSaving"
          >{{ 'initiation.button.addItemRequest' | translate }}
        </app-button>
      </div>
    </form>
  </div>
</div>
