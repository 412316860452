import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppWidgetContentSliderModule } from '../app-widget-content-slider/app-widget-content-slider.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetContractComponent } from './app-widget-contract.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule, AppWidgetContentSliderModule],
  declarations: [AppWidgetContractComponent],
  entryComponents: [AppWidgetContractComponent],
  exports: [AppWidgetContractComponent]
})
export class AppWidgetContractModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-contract', AppWidgetContractComponent);
  }
}
