import { Notification } from '../../core/bean/notification';
import { Lang } from '../../core/bean/lang';
import { NotificationTemplate } from '../../core/bean/notification-template';
import { ModuleGroup } from '../../core/bean/module-group';

export class NotificationTemplateResponse {
    langList: Lang[];
    notificationTemplateList: NotificationTemplate[];
    notification: Notification;
    moduleGroupList: ModuleGroup[];
    modulGroupId: number;
}