import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppTableModule } from '../../app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupAttributeComponent } from './app-popup-attribute.component';
import { AppPopupAttributeService } from './app-popup-attribute.service';

@NgModule({
  imports: [VendorModule, AppPopupModule, AppTableModule, AppButtonModule],
  declarations: [AppPopupAttributeComponent],
  entryComponents: [AppPopupAttributeComponent],
  providers: [AppPopupAttributeService],
  exports: [AppPopupAttributeComponent]
})
export class AppPopupAttributeModule {}
