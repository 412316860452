import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { File } from '../../core/bean/file';
import { ItemType } from '../../core/bean/item-type';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { ProcurementPq } from '../../core/bean/procurement-pq';
import { ProcurementPqDoc } from '../../core/bean/procurement-pq-doc';
import { ProcurementPqDocVendorStatus } from '../../core/bean/procurement-pq-doc-vendor-status';
import { ProcurementVendor } from '../../core/bean/procurement-vendor';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { WorkflowStepInfoModel } from '../../core/components/app-workflow-step-info/model/workflow-step-info-model';
import { AppTableComponent } from '../../core/components/table/components/app-table/app-table.component';
import { TableRow } from '../../core/components/table/domain/table-row';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { FileObject, FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { ReportService } from '../../core/services/report.service';
import { SnackbarService } from '../../core/services/snackbar.services';
import { Validators } from '../../core/validators';
import { ProcurementRegVendorView } from '../../core/view/entity/bean/procurement-reg-vendor-view';
import { AppPopupCriteriaProcurementComponent } from './app-popup-criteria-procurement.component';
import { AppPopupRegisteredLetterComponent } from './app-popup-registered-letter.component';
import { ProcurementRegVendorRegisteredResponse } from './procurement-reg-vendor-registered-response';
import { ProcurementRegVendorRequest } from './procurement-reg-vendor-request';
import { ProcurementRegVendorResponse } from './procurement-reg-vendor-response';
@Component({
  templateUrl: './procurement-reg-vendor-edit.component.html'
})
export class ProcurementRegVendorEditComponent extends BaseModuleComponent {
  public procurementId: number;
  public urlBackOutside: string;
  public procurementItemList: ProcurementItem[] = [];
  public itemTypeList: ItemType[] = [];
  public procurement: Procurement;
  public workflowStepInfoModel: WorkflowStepInfoModel;
  public procurementRegVendorStatusCode: string;
  public procurementRegVendorResponse: ProcurementRegVendorResponse;
  public procurementRegVendorRequest: ProcurementRegVendorRequest;
  public procurementPqDocVendorStatusList: ProcurementPqDocVendorStatus[];
  public fileUploaderPqDocList: FileUploader[] = [];
  public procurementPqList: ProcurementPq[];
  public procurementPqDocList: ProcurementPqDoc[];
  public token: string;
  public canceledOrFailedMessage: string;
  public isShowBadgeStatus: boolean;

  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<ProcurementPqDoc>;
  public fileUploader: FileUploader = new FileUploader(
    '/procurement-reg-vendor/',
    '',
    'DOC_PRA_QUALIFICATION'
  );
  constructor(
    translateService: TranslateService,
    public reportService: ReportService,
    public appPopupService: AppPopupService,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute
  ) {
    super('procurement-reg-vendor', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setDataFromToken();
    } else {
      this.setData();
    }
  }
  public setDataFromToken(): void {
    this.httpClientService
      .get<ProcurementRegVendorView>(
        '/procurement-reg-vendor/notification/' + this.token
      )
      .subscribe((procurementRegVendorView: ProcurementRegVendorView) => {
        if (procurementRegVendorView.isSchedule) {
          if (
            procurementRegVendorView.startDate &&
            procurementRegVendorView.endDate
          ) {
            const currentDate = new Date().getTime();
            const startDate = new Date(
              procurementRegVendorView.startDate
            ).getTime();
            if (
              (procurementRegVendorView.procurementRegVendorStatusCode ===
                this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_NEW ||
                procurementRegVendorView.procurementRegVendorStatusCode ===
                  this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_DRAFT ||
                procurementRegVendorView.procurementRegVendorStatusCode ===
                  this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_REVISION) &&
              currentDate < startDate
            ) {
              this.global.modalService.scheduleMismatch();
              this.router.navigate(['/pages/procurement-reg-vendor']);
            } else {
              this.setRouterParamsAndNavigate(procurementRegVendorView);
            }
          } else {
            this.global.modalService.scheduleMismatch();
          }
        } else {
          this.setRouterParamsAndNavigate(procurementRegVendorView);
        }
      });
  }

  public setRouterParamsAndNavigate(
    procurementRegVendorView: ProcurementRegVendorView
  ): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('procurementId', procurementRegVendorView.id);
    this.global.routerParams.set(
      'procurementRegVendorStatusCode',
      procurementRegVendorView.procurementRegVendorStatusCode
    );
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/procurement-reg-vendor'
    );

    if (
      procurementRegVendorView.procurementRegVendorStatusCode !==
        this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_NEW &&
      procurementRegVendorView.procurementRegVendorStatusCode !==
        this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_DRAFT &&
      procurementRegVendorView.procurementRegVendorStatusCode !==
        this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_REVISION
    ) {
      this.global.routerParams.set('todo', 'view');
    } else {
      this.global.routerParams.set('todo', 'edit');
    }
    this.router.navigate(['/pages/procurement-reg-vendor/detail']);
  }

  public setData(): void {
    this.setDataFromRouterParams();
    this.doBuildTableResponse();
    this.buildFormGroup();
    this.setInitializationState();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementPq.pq.name',
        header: 'table.column.documentName'
      },
      {
        field: 'code',
        header: 'table.column.documentNumber',
        plugins: {
          name: 'text-field',
          validators: Validators.required(),
          placeholder: this.translateService.instant(
            'procurement-reg-vendor.placeholder.documentNumber'
          ),
          onChange: (record: any): void => {
            this.log.debug(record);
            this.onChangeInput();
          },
          onInput: this.onChangeInput.bind(this)
        },
        isSortable: false
      },
      {
        field: 'documentDate',
        header: 'table.column.documentDate',
        plugins: {
          name: 'date-picker',
          range: false,
          validators: Validators.required(),
          onChange: (record: any): void => {
            this.log.debug(record);
            this.onChangeInput();
          },
          onInput: this.onChangeInput.bind(this)
        },
        isSortable: false
      },
      {
        field: 'procurementPqDocVendorStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorMap: {
            UNAVAILABLE: 'SECONDARY',
            AVAILABLE: 'SUCCESS',
            ACCEPTED: 'SUCCESS',
            REVISION: 'FEEDBACK',
            DECLINED: 'DANGER',
            EXPIRED: 'DANGER'
          },
          field: 'procurementPqDocVendorStatus.code'
        }
      },
      {
        field: 'note',
        header: 'table.column.note'
      },
      {
        field: 'file',
        header: 'table.column.uploadFile',
        plugins: {
          name: 'upload',
          fileUploader: this.fileUploader,
          validators: Validators.required(),
          onChange: this.onChangeUpload.bind(this),
          onInput: this.onChangeUpload.bind(this)
        },
        isSearchTable: false,
        isSortable: false
      }
    ]);
  }

  public setProcurementPqDocTable(): void {
    const statusDocUnavailable: ProcurementPqDocVendorStatus =
      this.procurementPqDocVendorStatusList.find(
        status =>
          status.code ===
          this.global.appConstant.pm.PROC_PQ_DOC_VENDOR_STATUS_UNAVAILABLE
      );
    if (this.procurementPqDocList && this.procurementPqDocList.length > 0) {
      this.tableResponse.setRecordList(this.procurementPqDocList);
    } else {
      const procurementPqDocList: ProcurementPqDoc[] = [];
      this.procurementPqList.forEach(procPq => {
        const procurementPqDoc: ProcurementPqDoc = new ProcurementPqDoc();
        procurementPqDoc.procurementPq = procPq;
        procurementPqDoc.procurementPqDocVendorStatus = statusDocUnavailable;
        procurementPqDocList.push(procurementPqDoc);
      });
      this.tableResponse.setRecordList(procurementPqDocList);
    }
    this.setStateReady();
    this.setIsViewOnly();
  }

  public setIsViewOnly(): void {
    if (this.todo === 'view') {
      setTimeout(() => {
        this.setViewOnly();
      }, 1000);
    }
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.procurementRegVendorStatusCode =
      localStorage.getItem('procurementRegVendorStatusCode') ||
      this.global.routerParams.get('procurementRegVendorStatusCode');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
    localStorage.removeItem('procurementRegVendorStatusCode');
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    this.global.routerParams.set('todo', this.todo);
    this.global.routerParams.set('procurementId', this.procurementId);
    this.global.routerParams.set(
      'procurementRegVendorStatusCode',
      this.procurementRegVendorStatusCode
    );
    this.global.routerParams.set('urlBackOutside', this.urlBackOutside);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      isAccepted: [
        null,
        Validators.required(
          this.translateService.instant(
            'procurement-reg-vendor.validation.msg.required'
          )
        )
      ],
      recordList: this.tableResponse.formArray
    });
  }

  public setInitializationState(): void {
    this.httpClientService
      .post<ProcurementRegVendorResponse>(
        '/procurement-reg-vendor/edit',
        new RouteRequestModel(this.todo, this.procurementId)
      )
      .subscribe((procRegVendorResponse: ProcurementRegVendorResponse) => {
        this.procurement = procRegVendorResponse.procurement;
        this.procurementRegVendorResponse = procRegVendorResponse;
        this.procurementItemList = procRegVendorResponse.procurementItemList;
        this.itemTypeList = procRegVendorResponse.itemTypeList;
        this.workflowStepInfoModel =
          procRegVendorResponse.workflowStepInfoModel;
        this.procurementPqDocVendorStatusList =
          procRegVendorResponse.procurementPqDocVendorStatusList;
        const statusList = [
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
        ];

        if (statusList.includes(this.procurement.procurementStatus.code)) {
          if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'procurement-reg-vendor.alert.msg.sorryWaitingCancelationApproval';
          } else if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'procurement-reg-vendor.alert.msg.sorryWaitingFailedApproval';
          } else {
            this.canceledOrFailedMessage =
              'procurement-reg-vendor.alert.msg.sorryFailedAssessment';
          }
        }
        if (
          this.procurement.isPq &&
          (procRegVendorResponse.procurementPqList !== [] ||
            procRegVendorResponse.procurementPqList.length > 0)
        ) {
          this.procurementPqList = procRegVendorResponse.procurementPqList;
          this.procurementPqDocList =
            procRegVendorResponse.procurementPqDocList;
          this.setProcurementPqDocTable();
        } else {
          this.setStateReady();
          this.setIsViewOnly();
        }
      });
  }

  public doSave(): void {
    this.formGroup.get('isAccepted').clearValidators();
    this.formGroup.get('isAccepted').updateValueAndValidity();
    this.validate();

    const isHasNull =
      this.tableResponse
        .getRecordList()
        .filter(
          (r: ProcurementPqDoc) =>
            !r.code ||
            !r.documentDate ||
            !r.file ||
            (r.file &&
              r.file instanceof Array &&
              (r.file.length === 0 ||
                (r.file.length > 0 && r.file[0].isDeleted)))
        ).length > 0;
    if (!isHasNull) {
      this.setStateProcessing();
      this.procurementRegVendorRequest = new ProcurementRegVendorRequest();
      if (this.procurement.isPq) {
        const procPqDocList: any[] = this.tableResponse.getRecordList();
        procPqDocList.forEach(procPqDoc => {
          if (procPqDoc.file instanceof Array) {
            procPqDoc.fileObjectList = procPqDoc.file;
            procPqDoc.file = null;
          }
        });
        this.procurementRegVendorRequest.procurementPqDocList = procPqDocList;
      }
      this.procurementRegVendorRequest.procurementRegVendorStatusCode =
        this.procurementRegVendorStatusCode;
      this.procurementRegVendorRequest.procurement = this.procurement;
      this.procurementRegVendorRequest.isSubmit = false;
      this.httpClientService
        .post<Response<ProcurementRegVendorRequest>>(
          '/procurement-reg-vendor/update',
          this.procurementRegVendorRequest
        )
        .subscribe(response => {
          if (response.status === ResponseStatusModel.OK) {
            this.snackbarService.showWarning('app.msg.info.sucsessSave');
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => {
                this.global.routerParams.clear();
                this.global.routerParams.set(
                  'procurementId',
                  this.procurementId
                );
                this.global.routerParams.set(
                  'procurementRegVendorStatusCode',
                  response.body.procurementVendor.procurementRegVendorStatus
                    .code
                );
                this.global.routerParams.set(
                  'urlBackOutside',
                  '/pages/procurement-reg-vendor'
                );
                this.global.routerParams.set('todo', 'edit');
                this.router.navigate(['/pages/procurement-reg-vendor/detail']);
              });
          } else {
            this.global.alertService.showError(response.statusText);
          }
          this.setStateReady();
        });
    } else {
      this.global.alertService.showError(
        'procurement-reg-vendor.msg.error.noDocument'
      );
    }
  }

  public doSubmit(): void {
    this.validate();
    const isHasNull =
      this.tableResponse
        .getRecordList()
        .filter(
          (r: ProcurementPqDoc) =>
            !r.code ||
            !r.file ||
            !r.documentDate ||
            (r.file &&
              r.file instanceof Array &&
              (r.file.length === 0 ||
                (r.file.length > 0 && r.file[0].isDeleted)))
        ).length > 0;
    if (!isHasNull) {
      if (this.formGroup.valid) {
        this.global.modalService
          .confirmation(
            'procurement-reg-vendor.confirmation.msg.beforeRegister',
            'procurement-reg-vendor.confirmation.msg.title',
            'app.button.cancel',
            'procurement-reg-vendor.confirmation.button.register',
            null,
            false
          )
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              this.procurementRegVendorRequest =
                new ProcurementRegVendorRequest();
              if (this.procurement.isPq) {
                const procPqDocList: any[] = [];
                this.tableResponse
                  .getRecordList()
                  .forEach(record =>
                    procPqDocList.push(Object.assign({}, record))
                  );
                procPqDocList.forEach(procPqDoc => {
                  if (procPqDoc.file instanceof Array) {
                    procPqDoc.fileObjectList = procPqDoc.file;
                    procPqDoc.file = null;
                  }
                });
                this.procurementRegVendorRequest.procurementPqDocList =
                  procPqDocList;
              }
              this.procurementRegVendorRequest.procurementRegVendorStatusCode =
                this.procurementRegVendorStatusCode;
              this.procurementRegVendorRequest.procurement = this.procurement;
              this.procurementRegVendorRequest.isSubmit = true;

              this.httpClientService
                .post<Response<ProcurementRegVendorRequest>>(
                  '/procurement-reg-vendor/update',
                  this.procurementRegVendorRequest
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    const procurement: Procurement = this.procurement;
                    const logo: File = response.body.logo;
                    const companyLocation = response.body.companyLocation;
                    const procurementVendor: ProcurementVendor =
                      response.body.procurementVendor;
                    this.setStateReady();
                    this.procurementRegVendorStatusCode = this.procurement.isPq
                      ? this.global.appConstant.pm
                          .PROC_REG_VENDOR_STATUS_VERIFICATION
                      : this.global.appConstant.pm
                          .PROC_REG_VENDOR_STATUS_ACCEPTED;
                    this.procurementRegVendorResponse.procurementVendor =
                      procurementVendor;
                    this.appPopupService
                      .open(AppPopupRegisteredLetterComponent, {
                        procurement,
                        logo,
                        companyLocation,
                        procurementVendor
                      })
                      .subscribe(isClose => {
                        if (isClose) {
                          this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(() => {
                              this.global.routerParams.clear();
                              this.global.routerParams.set(
                                'procurementId',
                                this.procurementId
                              );
                              this.global.routerParams.set(
                                'procurementRegVendorStatusCode',
                                this.procurementRegVendorStatusCode
                              );
                              this.global.routerParams.set(
                                'urlBackOutside',
                                '/pages/procurement-reg-vendor'
                              );
                              this.global.routerParams.set('todo', 'view');
                              this.router.navigate([
                                '/pages/procurement-reg-vendor/detail'
                              ]);
                            });
                        }
                      });
                  } else {
                    this.global.alertService.showError(response.statusText);
                  }
                  this.setStateReady();
                });
            }
          });
      }
    } else {
      this.global.alertService.showError(
        'procurement-reg-vendor.msg.error.noDocument'
      );
    }
  }

  public doOpenPopupCriteria(): void {
    const procurement: Procurement = this.procurement;
    this.appPopupService.open(AppPopupCriteriaProcurementComponent, {
      procurement
    });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/procurement-reg-vendor/']);
  }

  public doPrint(): void {
    const procurement: Procurement = this.procurement;
    this.setStateProcessing();
    this.httpClientService
      .get<ProcurementRegVendorRegisteredResponse>(
        '/procurement-reg-vendor/registration-letter/' + procurement.id
      )
      .subscribe((response: ProcurementRegVendorRegisteredResponse) => {
        const logo: File = response.logo;
        const companyLocation = response.companyLocation;
        const procurementVendor: ProcurementVendor = response.procurementVendor;
        this.setStateReady();
        this.appPopupService.open(AppPopupRegisteredLetterComponent, {
          procurement,
          logo,
          companyLocation,
          procurementVendor
        });
      });
  }

  public onAfterRowCreated(tableRow: TableRow): void {
    if (
      this.procurementRegVendorStatusCode ===
        this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_EXPIRED ||
      (this.procurementRegVendorStatusCode ===
        this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_NEW &&
        !this.procurementRegVendorResponse.isMeetTheCriteria)
    ) {
      tableRow.formGroup.setIsView(true);
    } else {
      if (
        this.todo === 'edit' &&
        this.procurementRegVendorStatusCode ===
          this.global.appConstant.pm.PROC_REG_VENDOR_STATUS_REVISION &&
        tableRow.record.procurementPqDocVendorStatus.code !==
          this.global.appConstant.pm.PROC_PQ_DOC_VENDOR_STATUS_REVISION &&
        tableRow.record.procurementPqDocVendorStatus.code !==
          this.global.appConstant.pm.PROC_PQ_DOC_VENDOR_STATUS_AVAILABLE
      ) {
        tableRow.formGroup.setIsView(true);
      }
    }
  }

  public onChangeUpload(fileObjectList: FileObject[]): void {
    const isHasUploadedFile =
      fileObjectList.filter(fileObject => !fileObject.isDeleted).length > 0;
    const currentRowChange = this.tableResponse.currentRowChange;
    const statusDocAvailable: ProcurementPqDocVendorStatus =
      this.procurementPqDocVendorStatusList.find(
        status =>
          status.code ===
          this.global.appConstant.pm.PROC_PQ_DOC_VENDOR_STATUS_AVAILABLE
      );
    const statusDocUnavailable: ProcurementPqDocVendorStatus =
      this.procurementPqDocVendorStatusList.find(
        status =>
          status.code ===
          this.global.appConstant.pm.PROC_PQ_DOC_VENDOR_STATUS_UNAVAILABLE
      );
    currentRowChange.row.columnList[3].changeValue(
      isHasUploadedFile ? statusDocAvailable.name : statusDocUnavailable.name,
      isHasUploadedFile ? statusDocAvailable.code : statusDocUnavailable.code
    );
    currentRowChange.row.columnList[3].reload();

    this.tableResponse.getUpdatedRecordList().forEach(data => {
      if (
        data.file instanceof Array &&
        data.file.length > 0 &&
        data.file[0].isNew
      ) {
        data.procurementPqDocVendorStatus = statusDocAvailable;
      } else if (
        data.file instanceof Array &&
        data.file.length > 0 &&
        data.file[0].isDeleted
      ) {
        data.procurementPqDocVendorStatus = statusDocUnavailable;
      }
    });

    const recordList = this.tableResponse.getRecordList().map(record => {
      const indexOfR = this.tableResponse
        .getUpdatedRecordList()
        .findIndex(
          updateRecord =>
            updateRecord.procurementPq.id === record.procurementPq.id
        );
      return indexOfR === -1
        ? record
        : this.tableResponse.getUpdatedRecordList()[indexOfR];
    });

    this.tableResponse.setRecordList(recordList);
  }

  public onChangeInput(): void {
    const recordList = this.tableResponse.getRecordList().map(record => {
      const indexOfR = this.tableResponse
        .getUpdatedRecordList()
        .findIndex(
          updateRecord =>
            updateRecord.procurementPq.id === record.procurementPq.id
        );
      return indexOfR === -1
        ? record
        : this.tableResponse.getUpdatedRecordList()[indexOfR];
    });
    this.tableResponse.setRecordList(recordList);
  }

  public doEditProfile(): void {
    this.router.navigate(['/pages/company-profile']);
  }

  public isButtonDisabled(): boolean {
    let isHasNull: boolean = false;
    if (this.procurement.isPq && this.procurementPqList.length > 0) {
      isHasNull =
        this.tableResponse
          .getRecordList()
          .filter(
            (r: ProcurementPqDoc) =>
              !r.code ||
              !r.file ||
              !r.documentDate ||
              (r.file &&
                r.file instanceof Array &&
                (r.file.length === 0 ||
                  (r.file.length > 0 && r.file[0].isDeleted)))
          ).length > 0;
    }
    return (
      this.formSaving ||
      !this.procurementRegVendorResponse.isMeetTheCriteria ||
      this.formGroup.isView ||
      isHasNull
    );
  }
}
