import { Currency } from 'src/app/core/bean/currency';
import { File } from 'src/app/core/bean/file';
import { ItemType } from 'src/app/core/bean/item-type';
import { Oep } from 'src/app/core/bean/oep';
import { OepItem } from 'src/app/core/bean/oep-item';
import { Procurement } from 'src/app/core/bean/procurement';
import { ProcurementItem } from 'src/app/core/bean/procurement-item';
import { WorkflowModelPrcs } from 'src/app/core/bean/workflow-model-prcs';
import { ApprovalPathResponseModel } from 'src/app/core/components/app-approval-path-x';

export class OepResponse {
  currencyList: Currency[] = [];
  procurementItemList: ProcurementItem[] = [];
  oepItemList: OepItem[] = [];
  itemTypeList: ItemType[] = [];
  oep: Oep = new Oep();
  fileList: File[] = [];
  procurement: Procurement = new Procurement();
  approvalPathResponseModel: ApprovalPathResponseModel =
    new ApprovalPathResponseModel();
  isUserApproval: boolean;
  workflowModelPrcs: WorkflowModelPrcs;
}
