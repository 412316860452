import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { TabModel } from '../../model/tab/tab-model';
import { TabResponseModel } from '../../model/tab/tab-response-model';
import { TextUtils } from '../../utils';
import { TabStoreService } from './services/tab-store.service';

@Component({
  selector: 'app-tab',
  templateUrl: './app-tab.component.html',
  styleUrls: ['./app-tab.component.scss'],
  providers: [TabStoreService],
  encapsulation: ViewEncapsulation.None
})
export class AppTabComponent extends BaseComponentComponent {
  @Input() model: TabResponseModel;
  @Input() bordered: boolean;
  @Input() position: 'VERTICAL' | 'HORIZONTAL' = 'HORIZONTAL';

  @Output() onChange: EventEmitter<TabModel> = new EventEmitter();
  @Output() onChangeTab: EventEmitter<TabModel> = new EventEmitter();

  @ContentChild('tabFooter') tabFooterTmpl: TemplateRef<any>;

  public generatedId: string;
  constructor(
    private tabStoreService: TabStoreService,
    private elementRef: ElementRef
  ) {
    super('app-tab');
  }

  onInit(): void {
    this.doSetGeneratedId();
    this.doSetState();
  }

  private doSetGeneratedId(): void {
    this.generatedId = TextUtils.generateRandomString();
  }

  private doSetState(): void {
    this.tabStoreService.setState({
      id: this.generatedId,
      tabElement: this.elementRef.nativeElement,
      tabItemContentMap: new Map(),
      tabDisabled: {},
      tabSelected: {},
      isTabWithModel: !!this.model,
      position: this.position
    });
  }

  public doChangeTab(tab: TabModel): void {
    this.onChangeTab.emit(tab);
    this.onChange.emit(tab);
  }
}
