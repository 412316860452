import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appSelectResolveChecked' })
export class AppSelectResolveCheckedPipe implements PipeTransform {
  transform(values: any, option: any, type: 'checkbox' | 'radio'): boolean {
    if (type === 'checkbox') {
      return (
        values &&
        values.findIndex(
          val => JSON.stringify(val) === JSON.stringify(option)
        ) !== -1
      );
    } else {
      return values && JSON.stringify(values) === JSON.stringify(option);
    }
  }
}
