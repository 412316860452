<app-popup
  header="procurement-offline-submission.popup.budget"
  [isLoading]="formLoading"
>
  <div class="main-row-popup">
    <app-fieldset
      legend="{{
        'procurement-offline-submission.form.filterBudget' | translate
      }}"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'procurement-offline-submission.form.budgetFilter.organization'
              | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-tree
              size="LG"
              formControlName="organization"
              stringUrl="/organization"
              isLazy="true"
              header="Organization"
              (onChange)="onChangeOrganization()"
            >
            </app-text-tree>
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'procurement-offline-submission.form.budgetFilter.workProgram'
              | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-auto-complete
              formControlName="workProgram"
              size="LG"
              stringUrl="/procurement-offline-submission/auto-complete-work-program-list"
              (onChange)="autoCompleteValueChange($event)"
              [params]="organizationIdParams"
            ></app-auto-complete>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'procurement-offline-submission.form.budgetFilter.budgetType'
              | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              size="LG"
              formControlName="budgetType"
              [optionList]="budgetTypeOptionList"
            ></app-combo-box>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'procurement-offline-submission.form.budgetFilter.costCenter'
              | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-auto-complete 
              formControlName="costCenter"
              size="LG"
              stringUrl="/procurement-offline-submission/auto-complete-cost-center-list"
              (onChange)="autoCompleteValueChange($event)"
            ></app-auto-complete>
          </div>
        </div>
        <!-- PRODUCT -->
        <!-- <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
            'procurement-offline-submission.form.coa' | translate
            }}</label>
            <div class="col-sm-9 text-left">
            <app-text-tree
                size="LG"
                formControlName="coa"
                stringUrl="/coa"
                header="Coa"
            >
            </app-text-tree>
            </div>
        </div> -->
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'procurement-offline-submission.form.budgetFilter.coa' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-choose
              formControlName="coaName"
              (onClick)="doChooseCoa()"
            ></app-choose>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'procurement-offline-submission.form.budgetFilter.period'
              | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              [optionList]="periodOptionList"
              formControlName="activityPeriod"
            ></app-combo-box>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button color="SECONDARY" (onClick)="doReset()"
            ><em class="fas fa-sync-alt"></em>
            {{ 'app.button.reset' | translate }}
          </app-button>
          <app-button
            color="PRIMARY"
            (onClick)="doApply()"
            [disabled]="formSaving"
            ><em class="fas fa-check"></em>
            {{ 'app.button.apply' | translate }}
          </app-button>
        </div>
      </form>
    </app-fieldset>
    <app-table
      *ngIf="!formLoading && isGenerateTableBudget"
      [model]="tableResponse"
      [isServerSide]="true"
      stringUrl="/procurement-offline-submission/budget-allocation-list"
    >
      <ng-template #thead let-model>
        <thead>
          <tr>
            <th scope="col" class="tableNo" colspan="1">{{
              'app-table.column.no' | translate
            }}</th>
            <th
              scope="col"
              *ngFor="let column of model.columns; let i = index"
              (click)="column.sortable !== false && doSort(column.field)"
              [ngClass]="[
                column.sortable !== false ? 'sort' : '',
                model.request.sortField === column.field ? 'bg-light ' : '',
                model.request.sortField === column.field &&
                model.sortOrder === 'ASC'
                  ? 'sort-asc'
                  : '',
                model.request.sortField === column.field &&
                model.sortOrder === 'DESC'
                  ? 'sort-desc'
                  : '',
                column.format === 5 || column.format === 5
                  ? 'text-right'
                  : 'text-center'
              ]"
              [hidden]="column.isChecked === false"
              colspan="99"
              (keyup)="onKeyUp($event)"
              (keydown)="onKeyDown($event)"
            >
              {{ model.moduleCode + '.' + column.header | translate }}
            </th>
            <th scope="col" class="text-right">
              {{
                'procurement-offline-submission.table.purchaseRequest.column.budgetValue'
                  | translate
              }}</th
            >
            <th scope="col">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [attr.id]="'thead' + i"
                  (click)="doCheckAll($event)"
                  [checked]="purchaseRequestBudgetModel.isCheckedAll"
                />
                <label
                  class="custom-control-label"
                  [attr.for]="'thead' + i"
                ></label>
              </div>
            </th>
          </tr>
        </thead>
      </ng-template>
      <ng-template #tbody let-model>
        <tbody>
          <tr *ngIf="model.page.totalRecords === 0">
            <td colspan="9999999" class="bg-white text-center">{{
              'app.info.noData' | translate
            }}</td>
          </tr>
          <tr *ngFor="let record of model.page.records; let i = index">
            <td class="text-center" colspan="1">{{
              i + 1 + model.request.first
            }}</td>
            <td
              *ngFor="let column of model.columns"
              [hidden]="column.isChecked === false"
              colspan="99"
              #tdElement
            >
              {{ record | resolveColumnValue: column:model:tdElement }}
            </td>
            <td>
              <input
                name="plannedBudget"
                type="text"
                class="form-control"
                [(ngModel)]="
                  purchaseRequestBudgetModel.recordPageMap &&
                  purchaseRequestBudgetModel.recordPageMap.get(record.id) &&
                  purchaseRequestBudgetModel.recordPageMap.get(record.id)
                    .plannedBudget
                "
                (input)="doOnInput(record, plannedBudget)"
                #plannedBudget="ngModel"
                [ngClass]="errorMapper.get(record.id) ? 'is-invalid' : ''"
                [disabled]="
                  !purchaseRequestBudgetModel.recordPageMap.get(record.id)
                    ?.isChecked
                "
                (focusout)="handleFocusOut($event)"
                (focusin)="handleFocusIn($event)"
                [ngStyle]="{ width: '-webkit-fill-available' }"
                aria-label="Booked Budget"
              />
              <small *ngIf="errorMapper.get(record.id)" class="text-danger">
                {{ errorMapper.get(record.id) }}
              </small>
            </td>
            <td>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [attr.id]="'tbody' + i"
                  (click)="doCheck($event, record)"
                  [checked]="
                    purchaseRequestBudgetModel.recordPageMap.get(record.id)
                      ?.isChecked
                  "
                />
                <label
                  class="custom-control-label"
                  [attr.for]="'tbody' + i"
                ></label>
              </div>
            </td>
          </tr>
        </tbody>
      </ng-template>
      <ng-template #tableFooter>
        <p
          class="
            m-0
            text-right
            border border-top-0
            d-flex
            align-items-center
            justify-content-end
          "
          [ngStyle]="{
            height: '3rem',
            'padding-right': '2rem'
          }"
        >
          <span class="mr-2"
            ><strong
              >{{
                'procurement-offline-submission.purchaseRequestData.totalBudgetValue'
                  | translate
              }}
              :</strong
            ></span
          >
          {{ global.converterService.convertMoney(totalBudget) }}
          {{
            totalBudget
              ? purchaseRequestBudgetEdit?.budgetAllocation?.currency?.code
                ? purchaseRequestBudgetEdit?.budgetAllocation?.currency?.code
                : currencyList[0].code
              : ''
          }}
        </p>
      </ng-template>
    </app-table>

    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
        <app-button
          *ngIf="!formGroup.isView"
          color="PRIMARY"
          (onClick)="doSubmit()"
          >{{ 'app.button.save' | translate }}</app-button
        >
      </div>
    </ng-template>
  </div>
</app-popup>
