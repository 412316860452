import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';

@Component({
  templateUrl: './home-popup-read-more.component.html'
})
export class HomePopupReadMoreComponent extends BaseComponentComponent {
  @Input() data: any;
  @Output() onChange: EventEmitter<string> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {
    super('home');
  }

  onInit(): void {
    this.setStateReady();
  }
}
