import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Procurement } from 'src/app/core/bean/procurement';
import { ProcurementItem } from 'src/app/core/bean/procurement-item';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { OpenQuotationView } from 'src/app/core/view/entity/bean/open-quotation-view';
import { AppPopupOpenQuotationItemComponent } from './app-popup-open-quotation-item.component';
import { OpenQuotationRequest } from './open-quotation.request';
import { OpenQuotationResponse } from './open-quotation.response';

@Component({
  templateUrl: './open-quotation-edit.component.html'
})
export class OpenQuotationEditComponent extends BaseModuleComponent {
  public openQuotationResponse: OpenQuotationResponse =
    new OpenQuotationResponse();
  public tableResponseProcurementItem: TableResponseModel<ProcurementItem>;
  public tableResponseQuotation: TableResponseModel<OpenQuotationView>;
  public tableResponseQuotation2nd: TableResponseModel<OpenQuotationView>;
  public procurement: Procurement;
  public procurementId: number;
  public urlBackOutside: string;
  public detailQuotation: string;
  public isAnyWaitingVerif = false;
  public approvalModelPrcsId: number;
  public header: string;
  public isFailedAssessment: boolean;
  public isCancelation: boolean;
  public isShowBadgeStatus: boolean;

  public badgeColor = {
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    NEW: 'INFO',
    HOLD: 'DANGER'
  };

  public constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public datePipe: DatePipe
  ) {
    super('open-quotation', translateService);
  }

  public onInit(): void {
    this.getDataFromRouterParams();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public buildTableResponse(): void {
    this.tableResponseQuotation = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementVendor.vendor.name',
        header: 'table.column.vendorName',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClickVendor.bind(this)
        }
      },
      {
        field: 'procurementVendor.vendor.email',
        header: 'table.column.email'
      },
      {
        field: 'picName',
        header: 'table.column.picVendor'
      },
      {
        field: 'procurementVendor.vendor.phone',
        header: 'table.column.phone',
        plugins: {
          name: 'custom-plugin',
          after: (tablePlugin: TablePluginData): void => {
            if (tablePlugin.value) {
              tablePlugin.element.innerText =
                tablePlugin.row.record.procurementVendor.vendor.phoneRegion
                  .callingCode + tablePlugin.value;
            }
          }
        }
      },
      {
        field: 'amount',
        header: 'table.column.totalPrice',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      {
        field: 'validityStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            VALID: 'SUCCESS',
            WAITING_VERIFICATION: 'WARNING',
            INVALID: 'DANGER'
          },
          colorField: 'validityStatus.code'
        }
      }
    ]);
    this.tableResponseQuotation.setCustomData({
      procurementId: this.procurementId
    });

    this.tableResponseQuotation2nd = new TableResponseModel(this.moduleCode, [
      {
        field: 'procurementVendor.vendor.name',
        header: 'table.column.vendorName',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClickVendor.bind(this)
        }
      },
      {
        field: 'procurementVendor.vendor.email',
        header: 'table.column.email'
      },
      {
        field: 'picName',
        header: 'table.column.picVendor'
      },
      {
        field: 'procurementVendor.vendor.phone',
        header: 'table.column.phone',
        plugins: {
          name: 'custom-plugin',
          after: (tablePlugin: TablePluginData): void => {
            if (tablePlugin.value) {
              tablePlugin.element.innerText =
                tablePlugin.row.record.procurementVendor.vendor.phoneRegion
                  .callingCode + tablePlugin.value;
            }
          }
        }
      },
      {
        field: 'validityStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          pill: true,
          colorMap: {
            VALID: 'SUCCESS',
            WAITING_VERIFICATION: 'WARNING',
            INVALID: 'DANGER'
          },
          colorField: 'validityStatus.code'
        }
      }
    ]);
    this.tableResponseQuotation2nd.setCustomData({
      procurementId: this.procurementId
    });

    this.tableResponseProcurementItem = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'prItem.item.name',
          header: 'table.column.itemName',
          plugins: {
            name: 'hyperlink',
            onClick: this.doClickItem.bind(this)
          }
        },
        {
          field: 'prItem.pr.code',
          header: 'table.column.prNumber'
        },
        {
          field: 'prItem.code',
          header: 'table.column.prLine'
        },
        {
          field: 'prItem.item.code',
          header: 'table.column.itemCode'
        },
        {
          field: 'prItem.item.itemType.name',
          header: 'table.column.type',
          plugins: {
            name: 'badge',
            pill: true,
            colorMap: {
              MATERIAL: 'GOOD',
              JASA: 'SERVICE'
            },
            colorField: 'prItem.item.itemType.code'
          }
        },
        {
          field: 'prItem.quantity',
          header: 'table.column.quantity',
          className: 'text-right',
          plugins: {
            name: 'text-field',
            type: 'decimal',
            isView: true
          }
        },
        {
          field: 'prItem.item.uom.name',
          header: 'table.column.unit'
        }
      ]
    );
    this.tableResponseProcurementItem.setCustomData({
      procurementId: this.procurementId
    });
  }

  public getDataFromRouterParams(): void {
    this.procurement = this.global.routerParams.get('procurement');
    this.todo =
      localStorage.getItem('todo') ||
      this.global.routerParams.get('todoQuotation') ||
      this.global.routerParams.get('todo');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('backToWorklist') ||
      this.global.routerParams.get('urlBackOutside');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.procurementId =
      localStorage.getItem('procurementId') ||
      (this.procurement && this.procurement.id) ||
      this.global.routerParams.get('objectId');
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<OpenQuotationResponse>('/open-quotation/edit', {
        id: this.procurementId,
        todo: this.todo
      })
      .subscribe(response => {
        this.procurement = response.procurement;

        response.openQuotationViewList.forEach(record => {
          if (
            record.validityStatus.code ===
            this.global.appConstant.pm.VALIDITY_STATUS_WAITING_VERIFICATION
          ) {
            this.isAnyWaitingVerif = true;
          }
        });
        this.tableResponseQuotation.setRecordList(
          response.openQuotationViewList
        );
        this.tableResponseQuotation2nd.setRecordList(
          response.openQuotationViewList
        );
        this.openQuotationResponse = response;
        if (
          this.procurement.procurementStatus?.code ===
            this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT ||
          this.procurement.procurementStatus?.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL
        ) {
          this.isFailedAssessment = true;
        } else if (
          this.procurement.procurementStatus?.code ===
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
        ) {
          this.isCancelation = true;
        }
        this.setStateReady();
      });
  }

  public doSubmit(): void {
    const request = new OpenQuotationRequest();
    request.procurementId = this.procurementId;
    let totalValid = 0;
    this.tableResponseQuotation.getRecordList().forEach(record => {
      if (
        record.validityStatus.code ===
        this.global.appConstant.pm.VALIDITY_STATUS_WAITING_VERIFICATION
      ) {
        this.isAnyWaitingVerif = true;
      }

      if (
        record.validityStatus.code ===
        this.global.appConstant.pm.VALIDITY_STATUS_VALID
      ) {
        totalValid += 1;
      }
    });
    if (this.isAnyWaitingVerif) {
    } else if (
      this.openQuotationResponse.procurementParticipant &&
      totalValid <
        this.openQuotationResponse.procurementParticipant.minimumParticipant
    ) {
      this.global.modalService
        .submitProcurementFailed(
          this.openQuotationResponse.procurementParticipant.minimumParticipant
        )
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            request.isProcurementFailed = true;
            this.doSubmitProcess(request);
          }
        });
    } else {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            request.isProcurementFailed = false;
            this.doSubmitProcess(request);
          }
        });
    }
  }

  public doSubmitProcess(request: OpenQuotationRequest): void {
    this.setStateProcessing();
    this.httpClientService
      .post<Response<Procurement>>('/open-quotation/update', request)
      .subscribe(response => {
        this.setStateReady();
        if (response.status === ResponseStatusModel.OK) {
          this.global.modalService.submitSuccess().subscribe(result => {
            if (result) {
              this.router.navigate([this.urlBackOutside]);
            } else {
              this.router
                .navigateByUrl('/', {
                  skipLocationChange: true
                })
                .then(() => {
                  this.global.routerParams.clear();
                  this.global.routerParams.set('procurement', this.procurement);
                  this.global.routerParams.set(
                    'urlBackOutside',
                    '/pages/worklist-pm'
                  );
                  this.global.routerParams.set('todo', 'view');
                  this.router.navigate(['/pages/open-quotation/detail']);
                });
            }
          });
        }
      });
  }

  public doClickItem(procurementItem: ProcurementItem): void {
    console.log('Item: ', procurementItem);
    this.appPopupService.open(
      AppPopupOpenQuotationItemComponent,
      {
        itemName: procurementItem.prItem.item.name,
        procurementItemId: procurementItem.id,
        procurementId: this.procurementId
      },
      { size: 'lg' }
    );
  }

  public doClickVendor(vendorQuotationView: OpenQuotationView): void {
    this.global.routerParams.set(
      'vendorId',
      vendorQuotationView.procurementVendor.vendor.id
    );
    this.global.routerParams.set(
      'vendorName',
      vendorQuotationView.procurementVendor.vendor.name
    );
    this.global.routerParams.set('procurement', this.procurement);
    this.global.routerParams.set('quotationId', vendorQuotationView.id);
    this.global.routerParams.set('todoQuotation', this.todo);
    this.global.routerParams.set('isFailedAssessment', this.isFailedAssessment);
    this.global.routerParams.set('isCancelation', this.isCancelation);
    if (this.todo === 'add') {
      this.global.routerParams.set(
        'backToQuotation',
        '/pages/open-quotation/add'
      );
      this.router.navigate(['/pages/open-quotation/add/quotation-detail']);
    } else if (this.todo === 'edit') {
      this.global.routerParams.set(
        'backToQuotation',
        '/pages/open-quotation/edit'
      );
      this.router.navigate(['/pages/open-quotation/edit/quotation-detail']);
    } else {
      this.global.routerParams.set(
        'backToQuotation',
        '/pages/open-quotation/detail'
      );
      this.router.navigate(['/pages/open-quotation/detail/quotation-detail']);
    }
  }

  public translateLabel(code: string): string {
    if (code === this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_NEW) {
      return 'open-quotation.label.new';
    } else if (
      code === this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION
    ) {
      return 'open-quotation.label.revision';
    } else if (
      code ===
      this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_WAITING_APPROVAL
    ) {
      return 'open-quotation.label.waitingApproval';
    } else if (
      code === this.global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_HOLD
    ) {
      return 'open-quotation.label.hold';
    }
  }
}
