import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipe/pipe.module';
import { DirectivesModule } from '../../../directives/directives.module';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppBadgeCatalogModule } from '../../app-badge-catalog/app-badge-catalog.module';
import { AppBadgeModule } from '../../app-badge/app-badge.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppImageSlideshowModule } from '../../app-image-slideshow/app-image-slideshow.module';
import { AppTooltipModule } from '../../app-tooltip/app-tooltip.module';
import { AppWysiwygModule } from '../../app-wysiwyg/app-wysiwyg.module';
import { TableModule } from '../../table/table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupPrItemInfoComponent } from './app-popup-pr-item-info.component';
import { AppPopupPrItemInfoService } from './app-popup-pr-item-info.service';

@NgModule({
  imports: [
    VendorModule,
    AppPopupModule,
    TableModule,
    AppButtonModule,
    AppImageSlideshowModule,
    DirectivesModule,
    AppBadgeModule,
    AppBadgeCatalogModule,
    AppWysiwygModule,
    PipesModule,
    AppTooltipModule
  ],
  declarations: [AppPopupPrItemInfoComponent],
  entryComponents: [AppPopupPrItemInfoComponent],
  providers: [AppPopupPrItemInfoService],
  exports: [AppPopupPrItemInfoComponent]
})
export class AppPopupPrItemInfoModule {}
