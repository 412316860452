import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  selector: 'app-card',
  templateUrl: './app-card.component.html',
  styleUrls: ['./app-card.component.scss']
})
export class AppCardComponent extends BaseComponentComponent {
  @Input() public header: string;
  @Input() public detail: string;
  @Input() public isLoading: boolean;
  @Input() public isSaving: boolean;
  @Input() public customClass: string;
  @Input() public customClassHeader: string;
  @Input() public customClassBody: string;
  @Input() public tips: string;

  @ContentChild('customLoading') customLoadingTmpl: TemplateRef<any>;
  @ContentChild('headerLeft', { static: true })
  headerLeftTmpl: TemplateRef<any>;
  @ContentChild('headerRight', { static: true })
  headerRightTmpl: TemplateRef<any>;

  constructor() {
    super('app-card');
  }

  onInit(): void {
    this.setStateReady();
  }
}
