import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppDeliverableItemModule } from 'src/app/core/components/app-deliverable-item/app-deliverable-item.module';
import { AppVendorPaymentInformationModule } from 'src/app/core/components/app-vendor-payment-information/app-vendor-payment-information.module';
import { AppWorkflowStepInfoModule } from 'src/app/core/components/app-workflow-step-info/app-workflow-step-info.module';
import { AppProcurementInformationViewModule } from '../../core/components/app-procurement-information-view/app-procurement-information-view.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupInternalPaymentRequestChooseBillComponent } from './app-popup-internal-payment-request-choose-bill.component';
import { InternalPaymentRequestEditAddComponent } from './internal-payment-request-edit-add.component';
import { InternalPaymentRequestComponent } from './internal-payment-request.component';

export const routes = [
  {
    path: '',
    component: InternalPaymentRequestComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'add',
    component: InternalPaymentRequestEditAddComponent,
    data: { breadcrumb: 'internal-payment-request.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: InternalPaymentRequestEditAddComponent,
    data: { breadcrumb: 'internal-payment-request.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: InternalPaymentRequestEditAddComponent,
    data: { breadcrumb: 'internal-payment-request.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    AppApprovalPrcsXModule,
    AppProcurementInformationViewModule,
    AppDeliverableItemModule,
    AppVendorPaymentInformationModule,
    AppWorkflowStepInfoModule
  ],
  declarations: [
    InternalPaymentRequestComponent,
    InternalPaymentRequestEditAddComponent,
    AppPopupInternalPaymentRequestChooseBillComponent
  ],
  entryComponents: [AppPopupInternalPaymentRequestChooseBillComponent],
  exports: [AppPopupInternalPaymentRequestChooseBillComponent]
})
export class InternalPaymentRequestModule {}
