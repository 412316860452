import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorEquipmentHistory } from '../../core/bean/vendor-equipment-history';
import { VendorExpertiseHistory } from '../../core/bean/vendor-expertise-history';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { AppPopupTabVendorEquipmentComponent } from './app-popup-tab-vendor-equipment.component';
import { AppPopupTabVendorExpertiseComponent } from './app-popup-tab-vendor-expertise.component';
import { TabExpertAndEquipmentDTO } from './dto/tab-expert-and-equipment.dto';
@Component({
  templateUrl: './tab-expert-and-equipment.component.html'
})
export class TabExpertAndEquipmentComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<TabExpertAndEquipmentDTO>;
  public tableExpertResponse: TableResponseModel<VendorExpertiseHistory>;
  public tableEquipmentResponse: TableResponseModel<VendorEquipmentHistory>;
  public tabExpertAndEquipmentDTO: TabExpertAndEquipmentDTO;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('tab-expert-and-equipment', translateService);
  }

  onInit(): void {
    this.buildTableExpert();
    this.buildTableEquipment();
    this.doSetCustomDataExpert();
    this.doSetCustomDataEquipment();
    this.model.setStateReady();
  }

  public buildTableExpert(): void {
    this.tableExpertResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      {
        field: 'birthDate',
        header: 'table.column.DOB',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: 'profession.name',
        header: 'table.column.profession',
        customClass: 'text-center'
      },
      {
        field: 'education.name',
        header: 'table.column.education',
        customClass: 'text-center'
      }
    ]);
  }

  public buildTableEquipment(): void {
    this.tableEquipmentResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'equipmentType.name',
        header: 'table.column.type',
        customClass: 'text-center'
      },
      {
        field: 'quantity',
        header: 'table.column.quantity',
        customClass: 'text-right'
      },
      {
        field: 'capacityUom',
        header: 'table.column.capacity',
        customClass: 'text-center'
      },
      { field: 'brand', header: 'table.column.brand' },
      {
        field: 'equipmentCondition.name',
        header: 'table.column.condition',
        customClass: 'text-center'
      },
      {
        field: 'regionNameList',
        header: 'table.column.location',
        customClass: 'white-space-normal'
      },
      {
        field: 'resourceOwnership.name',
        header: 'table.column.ownership',
        customClass: 'text-center'
      }
    ]);
  }

  public doSetCustomDataExpert(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableExpertResponse.setCustomData(customData);
  }

  public doSetCustomDataEquipment(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableEquipmentResponse.setCustomData(customData);
  }

  public doViewExpertise(vendorExpertiseHistory: VendorExpertiseHistory): void {
    this.appPopupService.open(
      AppPopupTabVendorExpertiseComponent,
      { vendorExpertiseHistory },
      { size: 'lg', backdrop: 'static' }
    );
  }

  public doViewEquipment(vendorEquipmentHistory: VendorEquipmentHistory): void {
    this.appPopupService.open(
      AppPopupTabVendorEquipmentComponent,
      { vendorEquipmentHistory },
      { size: 'lg', backdrop: 'static' }
    );
  }
}
