<app-popup header="work-plan.form.chooseItem">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label" for="name">Name</label>
      <div class="col-sm-7 text-left">
        <app-text-field autofocus="true" size="LONG" formControlName="name"
          tooltip="Example name tooltip" id="name" appAlphabet></app-text-field>
      </div>
    </div>

    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label"
        for="description">Description</label>
      <div class="col-sm-7 text-left">
        <app-text-area size="LONG" formControlName="description"
          id="description">
        </app-text-area>
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="SECONDARY" (onClick)="activeModal.dismiss()"
          [disabled]="formSaving">{{ 'app.button.cancel' | translate }}
        </app-button>
        <app-button (onClick)="doSubmit()">{{ 'app.button.save' | translate }}
        </app-button>
      </div>
    </ng-template>
  </form>
</app-popup>