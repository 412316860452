import { BaseEntity } from '../base/base-entity';
import { File } from '../bean/file';
import { User } from '../bean/user';
import { VendorHistory } from '../bean/vendor-history';
import { DueDiligenceTemplate } from './../bean/due-diligence-template';

export class VendorVerificationEntity extends BaseEntity {
  vendorHistory: VendorHistory = new VendorHistory();
  dueDiligenceTemplate: DueDiligenceTemplate = new DueDiligenceTemplate();
  approvedByUser: User;
  verifiedByUser: User;
  isRecommended: boolean;
  vendorNote: string;
  adminNote: string;
  approvalNote: string;
  recommendationDate: Date;
  recommendation: boolean;
  sequence: number;
  approvalFile: File = new File();
}
