import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseModuleComponent } from '../../base/angular/base-module.component';
import { Currency } from '../../bean/currency';
import { ProcurementMethod } from '../../bean/procurement-method';
import { ReportRecapColumn } from '../../bean/report-recap-column';
import { User } from '../../bean/user';
import { AppPopupProgressBarDownloadComponent } from '../../components/app-popup/app-popup-progress-bar-download/app-popup-progress-bar-download.component';
import { ProgressBarDownloadModel } from '../../components/app-popup/app-popup-progress-bar-download/progress-bar-download-model';
import { AppPopupService } from '../../components/app-popup/app-popup.service';
import { ToastService } from '../../components/app-toast/app-toast.service';
import { TableColumnModel } from '../../components/table/model/table-column-model';
import { TableResponseModel } from '../../components/table/model/table-response-model';
import { OptionListModel } from '../../model/option-list-model';
import { RecapPrintService } from '../../services/recap-print.service';
import { ReportRecapProcurementView } from '../../view/entity/bean/report-recap-procurement-view';
import { ReportRecapProcurementPopupChooseColumnComponent } from './report-recap-procurement-popup-choose-column.component';
import { ReportRecapProcurementRequest } from './report-recap-procurement-request';
import { ReportRecapProcurementResponse } from './report-recap-procurement-response';
import { ReportRecapProcurementStageModel } from './report-recap-procurement-stage-model';
import { ReportRecapProcurementStatusModel } from './report-recap-procurement-status-model';

@Component({
  templateUrl: './report-recap-procurement.component.html'
})
export class ReportRecapProcurementComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<ReportRecapProcurementView>;
  public progressBarDownload = new ProgressBarDownloadModel();
  public httpRequest: Subscription;
  public reportRecapColumnList: ReportRecapColumn[] = [];
  public reportRecapId: number;
  public reportBuilderId: number;
  public generatedTableResponse: string;
  public isShowPopupModifyTable = false;
  public isFullViewTable = false;
  public loadingGetOptionStatus = false;
  public statusOptionList: OptionListModel<ReportRecapProcurementStatusModel> =
    new OptionListModel(true);
  public stageOptionList: OptionListModel<ReportRecapProcurementStageModel> =
    new OptionListModel(true);
  public procurementMethodOptionList: OptionListModel<ProcurementMethod> =
    new OptionListModel(true);
  public picOptionList: OptionListModel<User> = new OptionListModel(true);
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );
  constructor(
    public translateService: TranslateService,
    public appPopupService: AppPopupService,
    public recapPrintService: RecapPrintService,
    private toastService: ToastService
  ) {
    super('report-recap-procurement', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.buildTableResponse();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      procurementDate: [null],
      procurementDateTo: [null],
      procurementDateFrom: [null],
      minimumAmount: [null],
      maximumAmount: [null],
      minAmount: [null],
      maxAmount: [null],
      organizationList: [null],
      statusList: [null],
      stageList: [null],
      procurementNumber: [null],
      prNumber: [null],
      vendorName: [null],
      picList: [null],
      picIdList: [null],
      procurementMethodList: [null]
    });
  }

  public setDataFromRouterParams(): void {
    this.reportBuilderId = this.global.routerParams.get('reportBuilderId');
    this.reportRecapId = this.global.routerParams.get('reportRecapId');
    this.generatedTableResponse = this.global.routerParams.get(
      'generatedTableResponse'
    );
  }

  public doGenerate(): void {
    this.formGroup.patchValue({
      procurementDateFrom: this.formGroup.value.procurementDate?.from,
      procurementDateTo: this.formGroup.value.procurementDate?.to,
      minAmount: this.formGroup.value.minimumAmount?.price,
      maxAmount: this.formGroup.value.maximumAmount?.price
    });

    if (
      this.formGroup.value.picList &&
      this.formGroup.value.picList.length > 0
    ) {
      this.formGroup.patchValue({
        picIdList: this.formGroup.value.picList.map((pic: User) => pic.id)
      });
    }
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.setCustomData(null);
    this.tableResponse.reload();
  }

  public setFormGroup(): void {
    this.reportRecapId = this.reportRecapId ? this.reportRecapId : 2;
    this.httpClientService
      .get<ReportRecapProcurementResponse>(
        '/report-recap-procurement/get-option-list?reportRecapId=' +
          this.reportRecapId +
          (this.reportBuilderId
            ? '&reportBuilderId=' + this.reportBuilderId
            : '')
      )
      .subscribe((response: ReportRecapProcurementResponse) => {
        if (response != null) {
          this.stageOptionList.setRequestValues(response.stageList);
          this.procurementMethodOptionList.setRequestValues(
            response.procurementMethodList
          );
          this.picOptionList.setRequestValues(response.picList);
          this.currencyOptionList.setRequestValues(response.currencyList);
          this.reportRecapColumnList = response.reportRecapColumnList;
        }
        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    const arrayColumn: Array<TableColumnModel> = this.generatedTableResponse
      ? JSON.parse(this.generatedTableResponse)
      : null;
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      this.reportBuilderId
        ? arrayColumn
        : [
            {
              field: 'organization.name',
              header: 'table.column.organization'
            },
            {
              field: 'code',
              header: 'table.column.procurementNumber'
            },
            {
              field: 'name',
              header: 'table.column.procurementName'
            },
            {
              field: 'description',
              header: 'table.column.description'
            },
            {
              field: 'procurementMethod.name',
              header: 'table.column.procurementMethod'
            },
            {
              field: 'prNumber',
              header: 'table.column.prNumber'
            },
            {
              field: 'workflowModelPrcs.name',
              header: 'table.column.stage'
            },
            {
              field: 'statusName',
              header: 'table.column.status',
              plugins: {
                name: 'badge',
                pill: true,
                colorField: 'statusCode',
                colorMap: {
                  DRAFT: 'SECONDARY',
                  REVISION: 'FEEDBACK',
                  WAITING_APPROVAL: 'WARNING',
                  ON_PROGRESS: 'PROGRESS',
                  REJECTED: 'DANGER',
                  FAILED: 'DANGER',
                  CANCELED: 'DANGER',
                  CLOSED: 'DANGER',
                  HOLD: 'DANGER',
                  CLOSED_RESPONSE: 'DANGER',
                  WAITING_RESPONSE: 'WARNING',
                  DONE: 'SUCCESS'
                }
              }
            },
            {
              field: 'amount',
              header: 'table.column.amount',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: ' text-right'
            },
            {
              field: 'hpsAmount',
              header: 'table.column.hpsAmount',
              plugins: {
                name: 'default',
                type: 'currency'
              },
              className: ' text-right'
            },
            {
              field: 'vendor',
              header: 'table.column.vendorName'
            },
            {
              field: 'pic.name',
              header: 'table.column.pic'
            }
          ]
    );
  }

  public doExport(): void {
    const reportRecapProcurementRequest: ReportRecapProcurementRequest =
      new ReportRecapProcurementRequest();
    reportRecapProcurementRequest.reportRecapColumnList =
      this.reportRecapColumnList;
    reportRecapProcurementRequest.customData = JSON.stringify(
      this.tableResponse.getCustomData()
    );

    if (this.reportBuilderId) {
      this.doDirectExport(reportRecapProcurementRequest);
    } else {
      this.appPopupService.open(
        ReportRecapProcurementPopupChooseColumnComponent,
        {
          reportRecapProcurementRequest: reportRecapProcurementRequest
        }
      );
    }
  }

  public doDirectExport(
    reportRecapProcurementRequest: ReportRecapProcurementRequest
  ): void {
    this.appPopupService
      .open(AppPopupProgressBarDownloadComponent, {
        model: this.progressBarDownload
      })
      .subscribe(isCancel => {
        if (isCancel) {
          this.httpClientService.unsubscribe();
        }
      });

    this.recapPrintService
      .print(
        '/report-recap-procurement/export-list',
        reportRecapProcurementRequest
      )
      .subscribe(
        response => {
          if (response) {
            this.progressBarDownload.setProgress(100);
            setTimeout(() => {
              this.global.modalService.downloadSuccess();
            }, 500);
          }
        },
        error => {
          error.error.text().then(text => this.toastService.showError(text));
        }
      );
  }

  public doCustomSetting(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('reportBuilderId', this.reportBuilderId);
    this.router.navigate(['/pages/report-recap/edit']);
  }

  public onChangeStage(event: ReportRecapProcurementStageModel[]): void {
    this.loadingGetOptionStatus = true;
    if (event && event.length > 0) {
      this.httpClientService
        .post<ReportRecapProcurementStatusModel[]>(
          '/report-recap-procurement/get-status-option-list',
          event
        )
        .subscribe((response: ReportRecapProcurementStatusModel[]) => {
          if (response && response.length > 0) {
            this.statusOptionList.setRequestValues(response);
          }
          this.loadingGetOptionStatus = false;
        });
    }
  }
}
