import { Injectable } from '@angular/core';
import { AppPopupService } from '../app-popup.service';
import { AppPopupItemComponent } from './app-popup-item.component';

@Injectable()
export class AppPopupItemService {
  constructor(public appPopupService: AppPopupService) {}

  public open(itemIdList?: number[], itemCategoryCode?: string) {
    return this.appPopupService.open(
      AppPopupItemComponent,
      { itemIdList, itemCategoryCode },
      { size: 'lg' }
    );
  }
}
