<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="procurement-participant.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">Procurement Method</label>
        <div class="col-sm-9 text-left">{{procurementMethod.name}}</div>
      </div>
      <app-table [tableResponse]="tableResponse" [isServerSide]="true"
        stringUrl="/procurement-participant/add-edit"
        customData={{procurementMethod.id}}>
      </app-table>
      <div class="button-group button-group-center mt-5">
        <app-button color="SECONDARY" (onClick)="doCancel()"
          [disabled]="formSaving">{{ 'app.button.back' | translate }}
        </app-button>
        <app-button (onClick)="doSave()">{{ 'app.button.save' | translate }}
        </app-button>
      </div>
    </app-card>
  </div>
</div>