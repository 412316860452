import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
@Component({
  templateUrl: './tab-partnership-history.component.html'
})
export class TabPartnershipHistoryComponent extends BaseModuleComponent {
  @Input() model: any;
  constructor(translate: TranslateService) {
    super('tab-partnership-history', translate);
  }

  public onInit(): void {
    this.setStateReady();
  }

  public doBack(): void {
    this.router.navigate(['/pages/vendor-history/detail']);
  }
}
