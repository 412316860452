import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Currency } from '../../bean/currency';
import { OptionListModel } from '../../model/option-list-model';
import { CatalogItemView } from '../../view/entity/bean/catalog-item-view';
import { TableCatalogResponseModel } from './model/table-catalog-response-model';
import { AppCatalogItemCustomDataRequest } from './request/app-catalog-item-custom-data.request';
@Component({
  selector: 'app-catalog-item-x',
  templateUrl: './app-catalog-item-x.component.html',
  styleUrls: ['./app-catalog-item-x.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppCatalogItemXComponent
  extends BaseComponentComponent
  implements OnChanges
{
  @Input() customData: string;
  @Input() itemPath = '';
  @Input() customModel: any;

  public model: TableCatalogResponseModel<CatalogItemView> =
    new TableCatalogResponseModel('/app-catalog-item/index');

  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false
  );

  constructor(public translateService: TranslateService) {
    super('app-catalog-item');
  }

  ngOnChanges(changes: SimpleChanges): void {
    const customData =
      changes.customData && changes.customData.currentValue
        ? JSON.parse(changes.customData.currentValue)
        : null;
    /* customData (include formGroup.value) */
    const customDataRequest: AppCatalogItemCustomDataRequest =
      new AppCatalogItemCustomDataRequest();
    if (customData) {
      customDataRequest.itemCategory = customData.itemCategory
        ? customData.itemCategory[customData.itemCategory.length - 1]
        : null;
      customDataRequest.regionCodeList = customData.regionCodeList;

      customDataRequest.catalogType = customData.catalogType;
      customDataRequest.minimumPrice = customData.minimumPrice
        ? customData.minimumPrice.price
        : null;
      customDataRequest.maximumPrice = customData.maximumPrice
        ? customData.maximumPrice.price
        : null;
      customDataRequest.vendorId = customData.vendorId;
      customDataRequest.attributeList = customData.attributeList;
      customDataRequest.valueList = customData.valueList;
      customDataRequest.itemTypeCode = customData.itemTypeCode;

      this.model.setSearchString(customData.keyword || '');
      this.model.setSortField(
        (customData.sortBy && customData.sortBy.value) || null
      );

      this.model.setCustomData(customDataRequest);
    }

    this.log.debug('changes');
    this.log.debug(changes);

    this.setStateLoading();
    this.model.reload();
  }

  public onInit(): void {
    if (this.customModel) {
      this.model = this.customModel;
    } else {
      this.model = this.model;
    }
    this.handleReloadChanges();
    this.getAndSetCatalogs();
  }

  public getAndSetCatalogs(): void {
    this.model.setStateLoading();

    this.model.tableRequest.cols = [
      { field: 'merk', header: '' },
      { field: 'distributorName', header: '' },
      { field: 'produsenName', header: '' },
      { field: 'rating', header: '' },
      { field: 'currencyCode', header: '' },
      { field: 'price', header: '' }
    ];

    this.httpClientService
      .post<any>(this.model.stringUrl, this.model.tableRequest)
      .subscribe(page => {
        this.model.setPageRecords(page.records);
        this.model.setTotalRecords(page.totalRecords);
        this.model.setStateReady();
        this.setStateReady();
      });
  }

  public handleReloadChanges(): void {
    this.model.reloadChanges.subscribe(() => {
      this.getAndSetCatalogs();
    });
  }

  public onClickCatalog(catalogId: number): void {
    this.global.routerParams.set('catalogId', catalogId);
    this.router.navigate(['/pages/' + this.itemPath]);
  }

  public onScrollEnd(event): void {
    this.log.debug(event);
    this.log.debug('onScrollEnd');

    const page = this.model.pagination.page + 1;
    const recordPerPage = this.model.pagination.perPage;

    if (!this.model.isLoading) {
      this.model.setPerPage(page * recordPerPage);
      this.model.reload();
    }
  }
}
