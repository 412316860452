<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="notification-blast.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-table [tableResponse]="tableResponse" [isServerSide]="true"
        stringUrl="/notification-blast/index">
        <ng-template #headerButtons>
          <app-button (onClick)="doAdd()"><em class="fas fa-plus"></em> {{
            'app.button.add' | translate }}</app-button>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button color="PRIMARY" size="TN" (onClick)="doReSend(rowData)"
            title="{{ 'app.button.send' | translate }}">
            <span class="fas fa-paper-plane"></span>
          </app-button>
          <app-button mode="BYPASS" color="PRIMARY" size="TN"
            (onClick)="doDetail(rowData)"
            title="{{ 'app.tooltip.view' | translate }}">
            <span class="fas fa-search"></span>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>