import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { MPIModel } from '../../core/model/mpi-model';
import { OptionListModel } from '../../core/model/option-list-model';

@Component({
  templateUrl: './app-popup-sync-billing-memo.component.html'
})
export class AppPopupSyncBillingMemoComponent
  extends BaseComponentComponent
  implements OnChanges {
  @Input() isLoadingSync: boolean;
  @Input() syncProgressMessage: number;
  @Input() gettingDataMessage: string;
  @Input() onCloseModal: EventEmitter<boolean>;
  @Output() data: EventEmitter<MPIModel> = new EventEmitter();
  public selectOptionList: OptionListModel<any> = new OptionListModel();
  public yearOptionList: OptionListModel<any> = new OptionListModel();
  public mpiModel: MPIModel = new MPIModel();
  public isUser: boolean;
  public isSyncButtonClicked: boolean;

  constructor(
    public translateService: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    super('billing-memo');
  }

  ngOnChanges(value): void {
    if (value && +value.syncProgressMessage.currentValue === 100.0) {
      this.data.emit();
    }
  }

  onInit(): void {
    this.onCloseModal.subscribe(() => this.activeModal.close());
    this.setStateReady();
    this.doGenerateSync();
  }

  public doGenerateSync(): void {
    this.isUser = true;
    this.isSyncButtonClicked = true;
    this.httpClientService
      .post<Response>('/billing-memo/bjb-gift-sync', {})
      .subscribe();
  }

  public doCancel(): void {
    if (this.syncProgressMessage || this.gettingDataMessage) {
      this.httpClientService
        .post<Response>('/billing-memo/cancel-bjb-gift-sync', {})
        .subscribe();
      this.activeModal.dismiss();
    }
  }
}
