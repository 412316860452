<app-layout [isLoading]="formLoading">
  <ng-template #additionalTitle *ngIf="!formLoading">
    <app-badge class="cml-2" [color]="badgeColor[orderStatus.code]">
      {{ getTranslateKey(orderStatus) | translate }}
    </app-badge>
  </ng-template>
  <div class="row main-row" *ngIf="!formLoading">
    <div class="col-lg-12">
      <app-alert-x
        *ngIf="orderStatus.code === global.appConstant.pm.ORDER_STATUS_CANCELED"
        color="DANGER"
        [isBold]="false"
      >
        {{ 'order-response.alert.cancel.processCanceledBy' | translate }}
        <strong>{{ orderCancellation?.cancelByUser?.name }}</strong>
        {{ 'order-response.alert.cancel.at' | translate }}
        <strong>
          {{
            orderCancellation?.cancelDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}
        </strong>
        {{ 'order-response.alert.cancel.reason' | translate }}
        <strong>&#34;{{ orderCancellation?.cancelNote }}&#34;</strong>
      </app-alert-x>

      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-card
          header="order-response.detail.orderProcessDetail.title"
          tips="order-response.detail.orderProcessDetail.tips"
          *ngIf="!formLoading"
        >
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order-response.form.order.number' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              {{ formGroup.controls.number.value }}
            </div>
          </div>

          <!-- <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order-response.form.registeredOnDistributor' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <label>
                {{ orderResponseResponse?.organization?.name || '-' }}
              </label>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order-response.form.distributionLocationCode' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <label>
                {{ orderResponseResponse?.organization?.name || '-' }}
              </label>
            </div>
          </div> -->

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order-response.form.requestorDepartment' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <label>
                {{ order.organization ? order.organization.name : '-' }}
              </label>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order-response.form.requestor' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <label>
                {{ order.userRequester ? order.userRequester.name : '-' }}
              </label>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order-response.form.order.title' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              {{ formGroup.controls.title.value }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order-response.form.order.orderType' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              {{
                (getTranslateKey(formGroup.controls.orderType.value)
                  | translate) || '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order-response.form.order.requiredDate' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              {{
                (formGroup.controls.requiredDate.value
                  | date: global.config.parameterModel.dateFormatShort) || '-'
              }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order-response.form.order.department' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              {{ formGroup.controls.department.value.name || '-' }}
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order-response.form.order.description' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-area formControlName="description"></app-text-area>
            </div>
          </div>
        </app-card>

        <app-card
          header="order.detail.itemRequest.title"
          tips="order.detail.itemRequest.tips"
        >
          <app-table-xx
            #selectorItem
            [model]="tableResponseItem"
            (onClick)="doViewItem($event)"
          >
            <ng-template #headerFilter>
              <form
                class="form-horizontal"
                [formGroup]="formGroup"
                novalidate
                *ngIf="!formLoading"
              >
                <div class="form-group row text-sm-left">
                  <div
                    class="col-sm-9 text-left"
                    [ngStyle]="{ 'min-width': '10rem' }"
                  >
                    <!-- <app-dropdown-select
                      size="XL"
                      [formControl]="itemType"
                      [optionList]="itemTypeOptionList"
                      (onChange)="onChangeItem()"
                      container="body"
                      placeholder="{{
                        'order.placeholder.type' | translate
                      }}"
                      [ngStyle]="{ 'min-width': '10rem' }"
                    >
                      <ng-template #value let-value>
                        {{ getTranslateKey(value) | translate }}
                      </ng-template>
                      <ng-template #option let-data>
                        {{ getTranslateKey(data) | translate }}
                      </ng-template></app-dropdown-select
                    > -->
                  </div>
                </div>
              </form>
            </ng-template>
            <ng-template #row let-state>
              <ng-container *ngIf="state.rowList.length > 0">
                <!-- <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'order.form.goodsAmount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{ order.goodsAmount | resolveNumberToCurrency }}
                      {{ orderResponseResponse.companyCurrency.code }}
                    </strong>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'order.form.serviceAmount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{ order.serviceAmount | resolveNumberToCurrency }}
                      {{ orderResponseResponse.companyCurrency.code }}
                    </strong>
                  </td>
                </tr> -->

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" colspan="3">
                    {{ 'order.form.amount' | translate }}
                  </td>
                  <td colspan="99" class="text-right">
                    <strong>
                      {{ order.amount | resolveNumberToCurrency }}
                      {{ orderResponseResponse.companyCurrency.code }}
                    </strong>
                    <br />
                    <i>
                      ({{
                        order.amount | resolveNumberToWords: global.currentLang
                      }}
                      {{ orderResponseResponse.companyCurrency.name }})
                    </i>
                  </td>
                </tr>
              </ng-container>
            </ng-template>
          </app-table-xx>
        </app-card>

        <app-card
          header="pr-verification.detail.officialReport.title"
          tips="pr-verification.detail.officialReport.tips"
          *ngIf="prItemList && prItemList.length > 0"
        >
          <!-- <app-official-report
            [objectId]="prItemReleasedList[0].prItem.pr.id"
            [templateCode]="global.appConstant.pm.ORT_PURCHASE_REQUEST_LETTER"
            [appOfficialReportModel]="orderResponseResponse.appOfficialReportModel"
            mode="view"
            numberGeneratorCode="NUMBER"
            [isShowButtonGenerate]="false"
          ></app-official-report> -->
          <app-official-report
            [objectId]="order?.id"
            [templateCode]="global.appConstant.pm.ORT_ORDER_PURCHASE_REQUEST_LETTER"
            [appOfficialReportModel]="orderResponseResponse.appOfficialReportModel"
            mode="view"
            numberGeneratorCode="NUMBER"
            [isShowButtonGenerate]="false"
            [include]="['subject', 'priority']"
          ></app-official-report>
        </app-card>
        <app-card
          header="order-response.detail.shippingAddress.title"
          tips="order-response.detail.shippingAddress.tips"
        >
          <div>
            <app-select
              [optionList]="addressOptionList"
              [isMultiple]="true"
              formControlName="addressList"
              maxHeight="max-content"
              [isShowHover]="false"
            >
              <ng-template #content let-data>
                <div
                  style="
                    border: 1px solid var(--gray-400);
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding: 1rem;
                  "
                >
                  <p>{{ data.organization.name }}</p>
                  <h5>{{ getUserNameList(data.addressPicList) }}</h5>
                  <p>{{ data.addressDetail }}</p>
                  <p>{{ data.phone }}</p>
                  <div *ngIf="formGroup.controls.addressList.value.length > 1">
                    <div
                      class="py-1"
                      style="border-top: 1px dashed var(--gray-400)"
                    ></div>
                    <div class="button-group button-group-right">
                      <app-button (onClick)="doViewItem(data)"
                        >{{ 'order-response.button.viewItem' | translate }}
                      </app-button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </app-select>
          </div>
        </app-card>

        <app-card
          header="order-response.detail.workDefinition.title"
          tips="order-response.detail.workDefinition.tips"
        >
        <app-sow-x
          *ngIf="!formLoading && sowList && sowList.length > 0 && prItemList"
          [isView]="true"
          [sowList]="sowList"
          [prItemList]="prItemList"
          [amount]="order.amount"
          source="ORDER"
        ></app-sow-x>
          <br />
          <!--<app-fines
            *ngIf="!formLoading"
            [isView]="true"
            [finesList]="finesList"
            [amount]="order.amount"
          ></app-fines>

          <br />
          <app-guarantee
            *ngIf="!formLoading"
            [isView]="true"
            [guaranteeList]="guaranteeList"
            [amount]="order.amount"
          ></app-guarantee>-->

          <div class="my-5"></div>
        </app-card>

        <app-card
          header="order-response.detail.orderConfirmation.title"
          tips="order-response.detail.orderConfirmation.tips"
        >
          <div class="form-group row text-sm-left" *ngIf="isShowBuyerNote">
            <label class="col-sm-3">
              {{ 'order-response.form.orderConfirm.buyerNote' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-area
                formControlName="buyerNote"
                maxLength="512"
                size="LG"
              >
              </app-text-area>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{
                'order-response.form.orderConfirm.orderActStatus' | translate
              }}
            </label>
            <div class="col-sm-9 text-left">
              <app-dropdown-select
                type="COMBOBOX"
                formControlName="orderActivityStatus"
                [optionList]="orderActStatusOptionList"
                placeholder="order-response.placeholder.chooseOne"
              >
                <ng-template #value let-value>
                  <div *ngIf="value" style="display: inline-flex">
                    <em
                      style="font-size: 1.5rem"
                      [ngClass]="getColorAndIconConfirm[value.code].icon"
                    >
                    </em>
                    &nbsp;<span
                      class="ml-1"
                      [ngClass]="getColorAndIconConfirm[value.code].color"
                      >{{ getTranslateKey(value) | translate }}</span
                    >
                  </div>
                  <div *ngIf="!value && todo === 'view'">-</div>
                </ng-template>
                <ng-template #option let-data>
                  {{ getTranslateKey(data) | translate }}
                </ng-template>
              </app-dropdown-select>
            </div>
          </div>
          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'order-response.form.orderConfirm.note' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-area formControlName="note" maxLength="512" size="LG">
              </app-text-area>
            </div>
          </div>
        </app-card>

        <app-card>
          <app-table-xx
            [model]="orderActHistoryTableResponse"
            header="order-response.detail.orderActHistory.title"
            tips="order-response.detail.orderActHistory.tips"
          >
          </app-table-xx>
        </app-card>

        <div class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div
                class="button-group button-group-center"
                *ngIf="!formGroup.isView"
              >
                <app-button (onClick)="doSubmit()" [disabled]="formSaving">{{
                  'app.button.submit' | translate
                }}</app-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
