import { Component, ElementRef, Input, Optional } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
@Component({
  selector: 'app-rating',
  templateUrl: './app-rating.component.html',
  providers: makeProvider(AppRatingComponent)
})
export class AppRatingComponent extends BaseValueAccessorComponent<number> {
  @Input() public maxIcon = 5;
  @Input() public isViewOnly: boolean;

  ratingHover = 0;
  icons: Array<number>;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef
  ) {
    super('app-rating', controlContainer, elementRef);
  }

  public onInitBaseValueAccessor(): void {
    this.setIsViewOnly();
    this.setIcons();
  }

  setIsViewOnly(): void {
    this.isViewOnly = this.isViewOnly ? this.isViewOnly : this.ISVIEW;
  }

  setIcons(): void {
    this.icons = Array(this.maxIcon)
      .fill(0)
      .map((x: number, i: number) => i + 1 + x);
  }

  onMouseEnter(ratingHover: number): void {
    if (!this.isViewOnly || (!this.isViewOnly && !this.disabled)) {
      this.ratingHover = ratingHover;
    }
  }

  onMouseLeave(): void {
    this.ratingHover = 0;
  }

  onClickChange(rating: number): void {
    if (
      this.formControl &&
      (!this.isViewOnly || (!this.isViewOnly && !this.disabled))
    ) {
      this.formControl
        ? this.formControl.patchValue(rating)
        : this.onChange.emit(rating);
    }
  }
}
