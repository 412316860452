import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  ValidationErrors
} from '@angular/forms';
import { Response } from '../../../model/response-model';
import { CustomValidatorError } from '../../core/custom-validator-error';
import { CustomValidatorService } from '../../core/custom-validator-service';
export class ExistsAsyncValidator {
  public static timeout = {};
  public static existsAsync(
    stringUrl: string,
    id?: number,
    message?: string,
    options?: any
  ): AsyncValidatorFn {
    return (abstractControl: AbstractControl): Promise<ValidationErrors> => {
      if (!this.timeout) {
        this.timeout = {};
      }
      return new Promise(resolve => {
        const formGroup: FormGroup = abstractControl.parent as FormGroup;
        const formControlName = Object.keys(formGroup.controls).find(
          (key: any) => formGroup.get(key) === abstractControl && key
        );
        if (abstractControl.value && abstractControl.dirty) {
          if (options && !options.attributeName) {
            options = { ...options, attributeName: formControlName };
          }
          clearTimeout(this.timeout[formControlName]);
          this.timeout[formControlName] = setTimeout(() => {
            CustomValidatorService.exists(
              stringUrl,
              abstractControl.value,
              id,
              options
            ).subscribe((response: boolean | Response<any>) => {
              if (typeof response === 'boolean') {
                resolve(
                  response
                    ? CustomValidatorError.create('exists', message)
                    : null
                );
              } else {
                resolve(
                  response.status === 'ERROR'
                    ? CustomValidatorError.create('exists', response.statusText)
                    : null
                );
              }
            });
          }, 1000);
        } else {
          resolve(null);
        }
      });
    };
  }
}
