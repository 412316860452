import { Organization } from 'src/app/core/bean/organization';
import { OrganizationDoc } from 'src/app/core/bean/organization-doc';
import { FileObject } from 'src/app/core/components/upload';

export class OrganizationRequest {
  organization: Organization;
  fileObjectList: FileObject[];
  primaryColor: string;
  colorOnPrimaryColor: string;
  primaryDarkColor: string;
  colorOnPrimaryDarkColor: string;
  tableHeaderColor: string;
  colorOnTableHeaderColor: string;
  tableBodyColor: string;
  colorOnTableBodyColor: string;
  textColor: string;
  organizationDocList: OrganizationDoc[];
  fileObjectListDoc: FileObject[];
}
