import { WidgetBudgetSpendItemModel } from './widget-budget-spend-item-model';
export class WidgetBudgetSpendModel {
  items: Array<WidgetBudgetSpendItemModel> = new Array();
  isShowMoreVisual: boolean;

  public addItem(widgetBudgetSpendItem: WidgetBudgetSpendItemModel): void {
    this.items.push(widgetBudgetSpendItem);
  }

  public removeAt(index: number): void {
    this.items.splice(index, 1);
  }
}
