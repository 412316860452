import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { ContractTemplateEditAddComponent } from './contract-template-edit-add.component';
import { ContractTemplateComponent } from './contract-template.component';
const routes: Routes = [
  { path: '', component: ContractTemplateComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: ContractTemplateEditAddComponent,
    data: { breadcrumb: 'contract-template.breadcrumb.add' }
  },
  {
    path: 'edit',
    component: ContractTemplateEditAddComponent,
    data: { breadcrumb: 'contract-template.breadcrumb.edit' }
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ContractTemplateComponent, ContractTemplateEditAddComponent]
})
export class ContractTemplateModule {}
