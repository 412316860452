import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppSignatureModule } from 'src/app/core/components/app-signature/app-signature.module';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { AttendanceFormComponent } from './attendance-form.component';
export const routes = [
    {
      path: 'form/:id',
      component: AttendanceFormComponent,
      data: { breadcrumb: '' }
    }
];
@NgModule({
  imports: [SharedModule, AppSignatureModule, RouterModule.forChild(routes)],
  declarations: [AttendanceFormComponent] // AttendanceFormComponent
})
export class AttendanceFormModule {}
