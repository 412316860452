import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Pr } from 'src/app/core/bean/pr';
import { PrItem } from 'src/app/core/bean/pr-item';
import { AppPopupChooseVendorService } from 'src/app/core/components/app-popup/app-popup-choose-vendor/app-popup-choose-vendor.service';
import { AppPopupPrItemInfoService } from 'src/app/core/components/app-popup/app-popup-pr-item-info/app-popup-pr-item-info.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableXComponent } from 'src/app/core/components/app-table-x/components/app-table-x/app-table-x.component';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { VendorView } from 'src/app/core/view/entity/bean/vendor-view';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { Rfp } from '../../core/bean/rfp';
import { RfpCancellation } from '../../core/bean/rfp-cancellation';
import { RfpProposalCriteria } from '../../core/bean/rfp-proposal-criteria';
import { RfpVendorScoring } from '../../core/bean/rfp-vendor-scoring';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { SnackbarService } from '../../core/services/snackbar.services';
import { RfpVendorView } from '../../core/view/entity/bean/rfp-vendor-view';
import { RfpPopupCancelComponent } from './rfp-popup-cancel.component';
import { RfpPopupChooseProposalCriteriaComponent } from './rfp-popup-choose-proposal-criteria.component';
import { RfpPopupResponseComponent } from './rfp-popup-response.component';
import { RfpRequest } from './rfp.request';
import { RfpResponse } from './rfp.response';

@Component({
  templateUrl: './rfp-edit-add.component.html'
})
export class RfpEditAddComponent extends BaseModuleComponent implements OnInit {
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  @ViewChild('selectorProposalCriteria')
  tableProposalCriteria: AppTableXComponent;
  @ViewChild('selectorVendor')
  tableVendor: AppTableXComponent;

  public rfpId: number;
  public objectId: number;
  public viewMode: boolean;
  public approvalModelPrcsId: number;
  public totalProposalCriteria: number;
  public urlBackOutside: string;
  public cancellationAlert: string;
  public CURRENCY_DIGITS_INFO: string;
  public currentDate: Date = new Date();
  public rfp: Rfp = new Rfp();
  public rfpRequest: RfpRequest = new RfpRequest();
  public rfpResponse: RfpResponse = new RfpResponse();
  public prItemList: PrItem[] = [];
  public rfpRequestScoring: RfpRequest[] = [];
  public procurementItemList: ProcurementItem[] = [];
  public vendorList: PopupVendorView[] = [];
  public delRfpProposalCriteria: RfpProposalCriteria[] = [];
  public isSubmit = false;
  public isShowCloseButton = false;
  public token: string;

  public tableResponseVendor: TableResponseModel<any>;
  public tableResponseProposalCriteria: TableResponseModel<any>;
  public tableResponseProposalCriteriaView: TableResponseModel<any>;

  public fileUploader: FileUploader = new FileUploader(
    '/rfp/',
    'rfp.form.file',
    'DOC_RFP_TEMPLATE',
    false,
    this.global.config.parameterModel.maxFileRfp
  );

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupPrItemInfoService: AppPopupPrItemInfoService,
    public appPopupChooseVendor: AppPopupChooseVendorService,
    public currency: CurrencyPipe,
    public snackbarService: SnackbarService,
    public activatedRoute: ActivatedRoute
  ) {
    super('rfp', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Rfp>('/rfp/notification/' + this.token)
      .subscribe((rfp: Rfp) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('rfp', rfp);
        this.global.routerParams.set('rfpId', rfp.id);
        this.global.routerParams.set('urlBackOutside', '/pages/rfp');
        if (
          rfp.rfpStatus.code === this.global.appConstant.pm.RFP_STATUS_DRAFT ||
          (rfp.rfpStatus.code ===
            this.global.appConstant.pm.RFP_STATUS_REVISION &&
            rfp.workflowPrcs.code === 'RFP_SUBMISSION')
        ) {
          this.router.navigate(['/pages/rfp/edit']);
          this.global.routerParams.set('todo', 'edit');
        } else {
          this.router.navigate(['/pages/rfp/detail']);
          this.global.routerParams.set('todo', 'view');
        }
      });
  }

  public setInitializationState(): void {
    this.doSetDataFromRouterParams();
    this.buildTableResponse();
    this.buildFormGroup();
    this.setIsViewOnlyFormGroup();
    this.setFormGroup();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public doSetDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.rfpId =
      localStorage.getItem('rfpId') || this.global.routerParams.get('rfpId');
    this.rfp = this.global.routerParams.get('rfp');
    this.objectId = this.global.routerParams.get('objectId');
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    if (this.objectId) {
      this.rfpId = this.objectId;
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );

    localStorage.removeItem('todo');
    localStorage.removeItem('rfpId');
    localStorage.removeItem('urlBackOutside');
  }

  public setIsViewOnlyFormGroup(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
    }
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null],
      title: [null, Validators.required()],
      date: [null, Validators.required()],
      note: [null, Validators.required()],
      startDate: [null],
      endDate: [null],
      file: [this.fileUploader.fileObjectList, Validators.required()],
      amount: [null],
      goodsAmount: [null],
      serviceAmount: [null],
      minimumScore: [
        null,
        Validators.compose([Validators.required(), Validators.max(100)])
      ],
      pricePercentage: [null, Validators.required()],
      proposalPercentage: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<RfpResponse>(
        '/rfp/add-edit',
        new RouteRequestModel(this.todo, this.rfpId)
      )
      .subscribe((rfpResponse: RfpResponse) => {
        this.rfpResponse = rfpResponse;
        if (this.objectId) {
          this.rfp = rfpResponse.rfp;
          this.buildTableResponse();
        }
        if (this.rfp) {
          const startDate: Date = this.rfp.startDate
            ? new Date(this.rfp.startDate)
            : null;
          const endDate: Date = this.rfp.endDate
            ? new Date(this.rfp.endDate)
            : null;
          const date = { from: startDate, to: endDate };
          this.fileUploader.setFileList(this.rfpResponse.fileList);
          const {
            id,
            code,
            title,
            note,
            amount,
            goodsAmount,
            serviceAmount,
            minimumScore,
            pricePercentage,
            proposalPercentage
          } = this.rfp;
          this.formGroup.patchValue({
            id,
            code,
            title,
            startDate,
            endDate,
            date,
            note,
            amount,
            goodsAmount,
            serviceAmount,
            minimumScore,
            pricePercentage,
            proposalPercentage,
            file: this.fileUploader.fileObjectList
          });
        }
        if (this.rfpResponse.rfpCancellation) {
          this.cancellationAlert =
            'This process canceled by ' +
            this.rfpResponse.rfpCancellation.cancelByUser.name +
            ' at ' +
            this.rfpResponse.rfpCancellation.cancelDate +
            ' the cancellation reason is ' +
            this.rfpResponse.rfpCancellation.note;
        }
        this.prItemList = this.rfpResponse.prItemList;
        this.prItemList.forEach(element => {
          const procurementItem = new ProcurementItem();
          procurementItem.prItem = element;
          this.procurementItemList.push(procurementItem);
        });

        this.tableResponseProposalCriteria.setRecordList(
          this.rfpResponse.rfpProposalCriteriaList
        );
        this.tableResponseProposalCriteriaView.setRecordList(
          this.rfpResponse.rfpProposalCriteriaList
        );

        this.totalProposalCriteria = 0;
        if (this.rfpResponse.rfpProposalCriteriaList?.length !== 0) {
          this.tableResponseProposalCriteria
            .getRecordList()
            .forEach(element => {
              this.totalProposalCriteria += +element.weightPercentage;
            });
        }

        if (
          this.rfp &&
          (this.rfp.rfpStatus.code ===
            this.global.appConstant.pm.RFP_STATUS_WAITING_RESPONSE ||
            (this.rfp.rfpStatus.code ===
              this.global.appConstant.pm.RFP_STATUS_WAITING_APPROVAL &&
              this.rfp.workflowPrcs?.code === 'RFP_SUBMISSION') ||
            this.rfp.rfpStatus.code ===
              this.global.appConstant.pm.RFP_STATUS_CLOSED_RESPONSE ||
            this.rfp.rfpStatus.code ===
              this.global.appConstant.pm.RFP_STATUS_COMPLETED ||
            this.rfp.workflowPrcs?.code === 'RFP_SCORING')
        ) {
          this.tableResponseVendor.setRecordList(
            this.rfpResponse.rfpVendorViewList
          );
        } else {
          this.tableResponseVendor.setRecordList(
            this.rfpResponse.vendorViewList
          );
        }
        if (
          this.rfp &&
          this.rfpResponse.rfpVendorScoringList &&
          (this.rfp.rfpStatus.code ===
            this.global.appConstant.pm.RFP_STATUS_CLOSED_RESPONSE ||
            (this.rfp.rfpStatus.code ===
              this.global.appConstant.pm.RFP_STATUS_REVISION &&
              this.rfp.workflowPrcs?.code === 'RFP_SCORING'))
        ) {
          this.rfpResponse.rfpVendorViewList.forEach(element => {
            const rfpVendorScorings =
              this.rfpResponse.rfpVendorScoringList.filter(
                x => x.rfpVendor.id === element.rfpVendor.id
              );
            if (rfpVendorScorings && rfpVendorScorings.length > 0) {
              const rfpRequest = new RfpRequest();
              rfpRequest.rfp = this.rfp;
              rfpRequest.rfpVendorScoringList = rfpVendorScorings;
              this.rfpRequestScoring.push(rfpRequest);
            }
          });
        }
        this.doCheckStatus();
        this.setStateReady();

        if (!this.prItemList || this.prItemList.length === 0) {
          this.formGroup.get('minimumScore').disable();
          this.formGroup.get('pricePercentage').disable();
          this.formGroup.get('proposalPercentage').disable();
        }
      });
  }

  public buildTableResponse(): void {
    this.tableResponseProposalCriteria = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'proposalCriteria.enName',
          header: 'table.column.proposalCriteria'
        },
        {
          field: 'weightPercentage',
          header: 'table.column.weightScore',
          className: 'text-right',
          plugins: {
            name: 'text-field',
            groupText: '%',
            validators: Validators.required(),
            onInput: this.doOnChangeScore.bind(this),
            type: 'decimal'
          }
        }
      ]
    );
    this.tableResponseProposalCriteriaView = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'proposalCriteria.enName',
          header: 'table.column.proposalCriteria'
        },
        {
          field: 'weightPercentage',
          header: 'table.column.weightScore',
          className: 'text-right',
          plugins: {
            name: 'custom-plugin',
            before: (tablePlugin: TablePluginData): string => {
              return tablePlugin.value + ' %';
            }
          }
        }
      ]
    );
    if (
      this.rfp &&
      (this.rfp.rfpStatus.code ===
        this.global.appConstant.pm.RFP_STATUS_WAITING_RESPONSE ||
        this.rfp.rfpStatus.code ===
          this.global.appConstant.pm.RFP_STATUS_COMPLETED ||
        (this.rfp.rfpStatus.code ===
          this.global.appConstant.pm.RFP_STATUS_WAITING_APPROVAL &&
          this.rfp.workflowPrcs?.code === 'RFP_SUBMISSION'))
    ) {
      this.tableResponseVendor = new TableResponseModel(this.moduleCode, [
        {
          field: 'vendorName',
          header: 'table.column.vendorName',
          plugins: {
            name: 'hyperlink',
            onClick: this.doClickVendor.bind(this)
          }
        },
        {
          field: 'address',
          header: 'table.column.address'
        },
        {
          field: 'phone',
          header: 'table.column.phone'
        },
        {
          field: 'picName',
          header: 'table.column.picName'
        },
        {
          field: 'rfpVendor.submittedDate',
          header: 'table.column.responseDate',
          plugins: 'date'
        },
        {
          field: 'rfpVendor.submittedStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            colorField: 'rfpVendor.submittedStatus.code',
            colorMap: {
              UNAVAILABLE: 'SECONDARY',
              AVAILABLE: 'SUCCESS'
            }
          },
          className: 'text-center'
        }
      ]);
    } else if (
      this.todo === 'add' ||
      this.rfp?.rfpStatus.code ===
        this.global.appConstant.pm.RFP_STATUS_DRAFT ||
      (this.rfp?.workflowPrcs?.code === 'RFP_SUBMISSION' &&
        this.rfp?.rfpStatus.code !==
          this.global.appConstant.pm.RFP_STATUS_CLOSED_RESPONSE)
    ) {
      this.tableResponseVendor = new TableResponseModel(this.moduleCode, [
        {
          field: 'name',
          header: 'table.column.vendorName',
          className: 'white-space-normal'
        },
        {
          field: 'address',
          header: 'table.column.address',
          className: 'white-space-normal'
        },
        {
          field: 'phone',
          header: 'table.column.phone'
        },
        {
          field: 'userRefer',
          header: 'table.column.userRefer',
          dataMap: {
            true: 'rfp.userRefer.yes',
            false: '-'
          }
        },
        {
          field: 'averageRatingOverall',
          header: 'table.column.performance',
          className: 'text-center',
          plugins: {
            name: 'rating',
            isView: true
          }
        }
      ]);
    } else {
      this.tableResponseVendor = new TableResponseModel(this.moduleCode, [
        {
          field: 'vendorName',
          header: 'table.column.vendorName',
          plugins: {
            name: 'hyperlink',
            onClick: this.doClickVendor.bind(this)
          }
        },
        {
          field: 'address',
          header: 'table.column.address'
        },
        {
          field: 'phone',
          header: 'table.column.phone'
        },
        {
          field: 'picName',
          header: 'table.column.picName'
        },
        {
          field: 'evaluator',
          header: 'table.column.evaluator'
        },
        {
          field: 'rfpVendor.scoringStatus.name',
          header: 'table.column.status',
          plugins: {
            name: 'badge',
            colorField: 'rfpVendor.scoringStatus.code',
            colorMap: {
              WAITING_SCORING: 'WARNING',
              PASSED: 'SUCCESS',
              NOT_PASSED: 'DANGER'
            }
          },
          className: 'text-center'
        }
      ]);
    }
  }

  public doCheckStatus(): void {
    if (
      this.rfp &&
      (this.rfp.rfpStatus.code ===
        this.global.appConstant.pm.RFP_STATUS_COMPLETED ||
        this.rfp.rfpStatus.code ===
          this.global.appConstant.pm.RFP_STATUS_WAITING_RESPONSE)
    ) {
      const vendorAvailable = this.rfpResponse.rfpVendorViewList.find(
        x =>
          x.rfpVendor.submittedStatus.code ===
          this.global.appConstant.pm.SUBMITTED_STATUS_AVAILABLE
      );
      if (vendorAvailable) {
        this.isShowCloseButton = true;
      }
    }
  }

  public doClickVendor(rfpVendorView: RfpVendorView): void {
    if (
      this.rfp &&
      (this.rfp.rfpStatus.code ===
        this.global.appConstant.pm.RFP_STATUS_WAITING_RESPONSE ||
        this.rfp.rfpStatus.code ===
          this.global.appConstant.pm.RFP_STATUS_CLOSED_RESPONSE ||
        this.rfp.rfpStatus.code ===
          this.global.appConstant.pm.RFP_STATUS_COMPLETED ||
        this.rfp.workflowPrcs?.code === 'RFP_SCORING')
    ) {
      rfpVendorView.rfpVendor.rfp = this.rfp;
      const rfpProposalCriteriaList = this.rfpResponse.rfpProposalCriteriaList;
      const rfpVendorScoringList = this.rfpResponse.rfpVendorScoringList.filter(
        x => x.rfpVendor.id === rfpVendorView.rfpVendor.id
      );
      if (!rfpVendorScoringList || rfpVendorScoringList.length === 0) {
        if (rfpProposalCriteriaList && rfpProposalCriteriaList.length > 0) {
          rfpProposalCriteriaList.forEach(element => {
            const rfpVendorScoring = new RfpVendorScoring();
            rfpVendorScoring.rfpVendor = rfpVendorView.rfpVendor;
            rfpVendorScoring.rfpProposalCriteria = element;
            rfpVendorScoring.weightScore = 0;
            rfpVendorScoringList.push(rfpVendorScoring);
          });
        }
      }

      if (
        rfpVendorView.rfpVendor.submittedStatus.code ===
        this.global.appConstant.pm.SUBMITTED_STATUS_UNAVAILABLE
      ) {
        this.global.modalService
          .confirmation(
            rfpVendorView.vendorName +
              ' ' +
              this.translateService.instant('rfp.confirm.msg.sendEmail'),
            'rfp.confirm.title.sendEmail',
            'app.button.close',
            'rfp.confirm.button.sendEmail',
            'pvc pv-envelope-reminder',
            false
          )
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              this.httpClientService
                .post<Response<RfpVendorView>>(
                  '/rfp/send-reminder-vendor',
                  rfpVendorView
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.setStateReady();
                    this.snackbarService.showWarning(
                      'app.msg.info.reminderEmailSent'
                    );
                  }
                });
            }
          });
      } else if (
        (!this.viewMode &&
          this.rfp.rfpStatus.code ===
            this.global.appConstant.pm.RFP_STATUS_CLOSED_RESPONSE) ||
        (this.rfp.rfpStatus.code ===
          this.global.appConstant.pm.RFP_STATUS_REVISION &&
          this.rfp.workflowPrcs.code === 'RFP_SCORING')
      ) {
        this.appPopupService
          .open(
            RfpPopupResponseComponent,
            {
              rfpVendorView,
              rfpVendorScoringList,
              isScoring: true,
              todo: 'edit'
            },
            { size: 'xl' }
          )
          .subscribe(response => {
            if (this.rfpRequestScoring && this.rfpRequestScoring.length > 0) {
              const currentIndex = this.rfpRequestScoring.findIndex(
                x =>
                  x.rfpVendorScoringList[0].rfpVendor.id ===
                  response.rfpRequest.rfpVendorScoringList[0].rfpVendor.id
              );
              if (currentIndex !== -1) {
                this.rfpRequestScoring.splice(
                  currentIndex,
                  0,
                  response.rfpRequest
                );
                this.rfpRequestScoring.splice(currentIndex + 1, 1);
              } else {
                this.rfpRequestScoring.push(response.rfpRequest);
              }
            } else {
              this.rfpRequestScoring.push(response.rfpRequest);
            }

            response.rfpRequest.rfpVendorScoringList.forEach(element => {
              const index = this.rfpResponse.rfpVendorScoringList.findIndex(
                x =>
                  x.rfpProposalCriteria === element.rfpProposalCriteria &&
                  x.rfpVendor === element.rfpVendor
              );
              if (index !== -1) {
                this.rfpResponse.rfpVendorScoringList.splice(index, 0, element);
                this.rfpResponse.rfpVendorScoringList.splice(index + 1, 1);
              } else {
                this.rfpResponse.rfpVendorScoringList.push(element);
              }
            });
            const index = this.rfpResponse.rfpVendorViewList.findIndex(
              x => x.rfpVendor.id === rfpVendorView.rfpVendor.id
            );
            this.rfpResponse.rfpVendorViewList.splice(index, 1);
            this.rfpResponse.rfpVendorViewList.splice(index, 0, rfpVendorView);
            this.tableResponseVendor.setRecordList(
              this.rfpResponse.rfpVendorViewList
            );
            this.tableResponseVendor.reload();
          });
      } else if (this.rfp.workflowPrcs.code === 'RFP_SCORING') {
        this.appPopupService.open(
          RfpPopupResponseComponent,
          {
            rfpVendorView,
            rfpVendorScoringList,
            isScoring: true,
            todo: 'view'
          },
          { size: 'xl' }
        );
      } else {
        this.appPopupService.open(RfpPopupResponseComponent, {
          rfpVendorView,
          rfpVendorScoringList,
          isScoring: false,
          todo: 'view'
        });
      }
    }
  }

  public doChangeItemRequest(event): void {
    console.log(event);
    const { amount, goodsAmount, serviceAmount } = event.amountValue;

    this.formGroup.patchValue({ amount, goodsAmount, serviceAmount });
    this.prItemList = (event.procurementItemList as Array<ProcurementItem>).map(
      procItem => procItem.prItem
    );
    this.rfpResponse.prItemList = this.prItemList;
  }

  public doAddCriteria(): void {
    const proposalCriteriaList = [];
    const proposalCriteriaOptList = this.rfpResponse.proposalCriteriaList;
    if (
      this.rfpResponse.rfpProposalCriteriaList &&
      this.rfpResponse.rfpProposalCriteriaList.length > 0
    ) {
      this.rfpResponse.rfpProposalCriteriaList.forEach(element => {
        proposalCriteriaList.push(element.proposalCriteria);
      });
    } else {
      this.rfpResponse.rfpProposalCriteriaList = [];
      this.formGroup.get('minimumScore').enable();
      this.formGroup.get('pricePercentage').enable();
      this.formGroup.get('proposalPercentage').enable();
    }
    this.appPopupService
      .open(RfpPopupChooseProposalCriteriaComponent, {
        proposalCriteriaList,
        proposalCriteriaOptList
      })
      .subscribe(proposalCriteriaList => {
        proposalCriteriaList.forEach(element => {
          if (
            !this.rfpResponse.rfpProposalCriteriaList.find(
              x => x.proposalCriteria.id === element.id
            )
          ) {
            const rfpProposalCriteria = new RfpProposalCriteria();
            rfpProposalCriteria.proposalCriteria = element;
            rfpProposalCriteria.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_INSERT;
            this.rfpResponse.rfpProposalCriteriaList.push(rfpProposalCriteria);
          } else {
            const rfpProposalCriteria =
              this.rfpResponse.rfpProposalCriteriaList.find(
                x => x.proposalCriteria.id === element.id
              );
            rfpProposalCriteria.crudOperation =
              this.global.appConstant.core.CRUD_OPERATION_UPDATE;
          }
        });
        this.rfpResponse.rfpProposalCriteriaList.forEach(
          rfpProposalCriteria => {
            const index = proposalCriteriaList.findIndex(
              x => x.id === rfpProposalCriteria.proposalCriteria.id
            );
            if (index === -1) {
              this.rfpResponse.rfpProposalCriteriaList.splice(index, 1);
              if (rfpProposalCriteria.id) {
                rfpProposalCriteria.crudOperation =
                  this.global.appConstant.core.CRUD_OPERATION_DELETE;
                this.delRfpProposalCriteria.push(rfpProposalCriteria);
              }
            }
          }
        );
        this.tableResponseProposalCriteria.setRecordList(
          this.rfpResponse.rfpProposalCriteriaList
        );
        this.tableResponseProposalCriteria.reload();
        this.doOnChangeScore();
      });
  }

  public doDeleteCriteria(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const proposalCriteriaList =
            this.tableResponseProposalCriteria.getRecordList();

          event.selectedRecordList.forEach((record: RfpProposalCriteria) => {
            const indexOfRecord = this.tableResponseProposalCriteria
              .getRecordList()
              .findIndex(
                r => r.proposalCriteria.id === record.proposalCriteria.id
              );
            if (indexOfRecord !== -1) {
              const rfpProposalCriteria =
                this.rfpResponse.rfpProposalCriteriaList.find(
                  x => x.proposalCriteria.id === record.proposalCriteria.id
                );
              rfpProposalCriteria.crudOperation =
                this.global.appConstant.core.CRUD_OPERATION_DELETE;
              this.delRfpProposalCriteria.push(rfpProposalCriteria);

              proposalCriteriaList.splice(indexOfRecord, 1);
            }

            this.tableResponseProposalCriteria.setRecordList(
              proposalCriteriaList
            );
            this.tableResponseProposalCriteria.reload();
          });
          this.doOnChangeScore();
        }
      });
  }

  public doAddVendor(): void {
    const vendorList = this.rfpResponse.vendorViewList;
    // tslint:disable-next-line:one-variable-per-declaration
    const prIdList = (this.prItemList as Array<PrItem>).map(
      procItem => procItem.pr.id
    );
    this.appPopupChooseVendor
      .openRfpRfqRfi(vendorList, prIdList)
      .subscribe(vendorListTempList => {
        this.vendorList = [];
        vendorListTempList.forEach(vendorTemp => [
          this.vendorList.push(vendorTemp)
        ]);
        this.rfpResponse.vendorViewList = this.vendorList;
        this.tableResponseVendor.setRecordList(this.rfpResponse.vendorViewList);
        this.tableResponseVendor.reload();
      });
  }

  public doDeleteVendor(event): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const vendorList = this.tableResponseVendor.getRecordList();
          event.selectedRecordList.forEach((record: VendorView) => {
            const indexOfRecord = this.tableResponseVendor
              .getRecordList()
              .findIndex(r => r.id === record.id);
            vendorList.splice(indexOfRecord, 1);
          });
          this.tableResponseVendor.setRecordList(vendorList);
          this.tableResponseVendor.reload();
        }
      });
  }

  public doOnChangeScore(): void {
    this.totalProposalCriteria = 0;
    if (this.tableResponseProposalCriteria.getUpdatedRecordList()?.length > 0) {
      this.tableResponseProposalCriteria
        .getUpdatedRecordList()
        .forEach((element: RfpProposalCriteria) => {
          if (
            this.tableResponseProposalCriteria
              .getRecordList()
              ?.find(x => x.proposalCriteria.id === element.proposalCriteria.id)
          ) {
            this.totalProposalCriteria += +element.weightPercentage;
          }
        });
    } else {
    }
  }

  public doCancel(): void {
    this.appPopupService
      .open(RfpPopupCancelComponent, {}, { size: 'SM' })
      .subscribe(result => {
        if (result) {
          const rfpCancelation = new RfpCancellation();
          rfpCancelation.note = result;
          rfpCancelation.rfp = this.rfp;

          this.httpClientService
            .post<Response<Rfp>>('/rfp/cancel', rfpCancelation)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.router.navigate(['/pages/rfp']);
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  public doClose(): void {
    this.global.modalService
      .confirmation(
        'If you close reponse, invited vendor could not edit or update their response anymore',
        'Are you sure to close response?',
        'No',
        'Yes, I am Sure',
        null,
        false
      )
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          this.httpClientService
            .post<Response<Rfp>>('/rfp/close', this.rfp)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.router.navigate(['/pages/rfp']);
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }

  public doOnChangePricePoint(): void {
    if (
      this.formGroup.get('proposalPercentage').value &&
      this.formGroup.get('pricePercentage').value
    ) {
      const proposalPercentage = this.formGroup.get('proposalPercentage').value;
      const validatorPrice = 100 - proposalPercentage;
      this.formGroup.get('pricePercentage').clearValidators();
      this.formGroup
        .get('pricePercentage')
        .setValidators([
          Validators.required(),
          Validators.max(validatorPrice),
          Validators.min(validatorPrice)
        ]);
      this.formGroup.get('pricePercentage').updateValueAndValidity();
    }
  }

  public doSave(): void {
    if (
      this.rfp &&
      (this.rfp.rfpStatus.code ===
        this.global.appConstant.pm.RFP_STATUS_CLOSED_RESPONSE ||
        (this.rfp.rfpStatus.code ===
          this.global.appConstant.pm.RFP_STATUS_REVISION &&
          this.rfp.workflowPrcs?.code === 'RFP_SCORING'))
    ) {
      if (this.rfpRequestScoring && this.rfpRequestScoring.length > 0) {
        this.rfpRequestScoring[0].isSubmit = false;
        this.setStateProcessing();
        this.httpClientService
          .post<Response<RfpVendorView[]>>(
            '/rfp/vendor-scoring',
            this.rfpRequestScoring
          )
          .subscribe(response => {
            if (response.status === ResponseStatusModel.OK) {
              this.snackbarService.showWarning('app.msg.info.sucsessSave');
              this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => {
                  this.global.routerParams.clear();
                  this.global.routerParams.set('rfp', response.body);
                  this.global.routerParams.set('rfpId', response.body.id);
                  this.global.routerParams.set('urlBackOutside', '/pages/rfp');
                  this.global.routerParams.set('todo', 'view');
                  this.router.navigate(['/pages/rfp/detail']);
                });
            } else {
              this.global.alertService.showError(response.statusText);
            }
            this.setStateReady();
          });
      } else {
        this.global.alertService.showError(
          'rfp.alert.info.pleaseCompleteScoring'
        );
      }
    } else {
      this.formGroup.get('minimumScore').clearValidators();
      this.formGroup.get('pricePercentage').clearValidators();
      this.formGroup.get('proposalPercentage').clearValidators();

      this.formGroup.get('minimumScore').updateValueAndValidity();
      this.formGroup.get('pricePercentage').updateValueAndValidity();
      this.formGroup.get('proposalPercentage').updateValueAndValidity();

      this.validate();
      if (this.formGroup.valid) {
        if (this.formGroup.value.date) {
          this.formGroup.patchValue({
            startDate: this.formGroup.value.date.from,
            endDate: this.formGroup.value.date.to
          });
        }
        this.rfpRequest.rfp = this.formGroup.value;
        this.rfpRequest.rfp.rfpStatus = this.rfp?.rfpStatus;
        this.rfpRequest.prItemList = this.rfpResponse.prItemList;
        this.rfpRequest.fileObjectList = this.fileUploader.fileObjectList;
        this.rfpRequest.vendorViewList = this.rfpResponse.vendorViewList
          ? this.rfpResponse.vendorViewList
          : [];
        this.rfpRequest.rfpProposalCriteriaList =
          this.delRfpProposalCriteria && this.delRfpProposalCriteria.length > 0
            ? this.tableResponseProposalCriteria.getUpdatedRecordList()
              ? this.tableResponseProposalCriteria
                  .getUpdatedRecordList()
                  .concat(this.delRfpProposalCriteria)
              : this.tableResponseProposalCriteria
                  .getRecordList()
                  .concat(this.delRfpProposalCriteria)
            : this.tableResponseProposalCriteria.getUpdatedRecordList()
            ? this.tableResponseProposalCriteria.getUpdatedRecordList()
            : this.tableResponseProposalCriteria.getRecordList();
        this.rfpRequest.isSubmit = false;

        this.setStateProcessing();
        const url = this.todo === 'edit' ? '/rfp/update' : '/rfp/insert';
        this.httpClientService
          .post<Response<Rfp>>(url, this.rfpRequest)
          .subscribe(response => {
            if (response.status === ResponseStatusModel.OK) {
              this.rfp = response.body;
              this.snackbarService.showWarning('app.msg.info.sucsessSave');
              this.router
                .navigateByUrl('/', { skipLocationChange: true })
                .then(() => {
                  this.global.routerParams.clear();
                  this.global.routerParams.set('rfp', response.body);
                  this.global.routerParams.set('rfpId', response.body.id);
                  this.global.routerParams.set('urlBackOutside', '/pages/rfp');
                  this.global.routerParams.set('todo', 'edit');
                  this.router.navigate(['/pages/rfp/edit']);
                });
            } else {
              this.global.alertService.showError(response.statusText);
            }
            this.setStateReady();
          });
      }
    }
  }
  public doSubmit(): void {
    if (
      this.rfp &&
      (this.rfp.rfpStatus.code ===
        this.global.appConstant.pm.RFP_STATUS_CLOSED_RESPONSE ||
        (this.rfp.rfpStatus.code ===
          this.global.appConstant.pm.RFP_STATUS_REVISION &&
          this.rfp.workflowPrcs?.code === 'RFP_SCORING'))
    ) {
      if (
        this.rfpRequestScoring &&
        this.rfpRequestScoring.length ===
          this.rfpResponse.rfpVendorViewList.length
      ) {
        this.rfpRequestScoring[0].isSubmit = true;
        this.global.modalService
          .submitConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              this.httpClientService
                .post<Response<RfpVendorView[]>>(
                  '/rfp/vendor-scoring',
                  this.rfpRequestScoring
                )
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    if (
                      response.body.rfpStatus.code ===
                      this.global.appConstant.pm.RFP_STATUS_WAITING_APPROVAL
                    ) {
                      this.global.modalService
                        .submitSuccess()
                        .pipe(take(1))
                        .subscribe(result => {
                          if (result) {
                            this.router.navigate(['/pages/rfp']);
                          } else {
                            this.router
                              .navigateByUrl('/', { skipLocationChange: true })
                              .then(() => {
                                this.global.routerParams.clear();
                                this.global.routerParams.set(
                                  'rfpId',
                                  response.body.id
                                );
                                this.global.routerParams.set(
                                  'rfp',
                                  response.body
                                );
                                this.global.routerParams.set(
                                  'urlBackOutside',
                                  '/pages/rfp'
                                );
                                this.global.routerParams.set('todo', 'view');
                                this.router.navigate(['/pages/rfp/detail']);
                              });
                          }
                        });
                    } else {
                      this.global.modalService
                        .confirmation(
                          'app.confirmation.submitConfirmation.prompt',
                          'app.confirmation.submitConfirmation.title',
                          'app.button.backToList',
                          'rfp.button.createRfq',
                          'pvc pv-check-circle',
                          true
                        )
                        .pipe(take(1))
                        .subscribe(result => {
                          if (result) {
                            this.router.navigate(['/pages/rfq']);
                          } else {
                            this.router.navigate(['/pages/rfp']);
                          }
                        });
                    }
                  } else {
                    this.global.alertService.showError(response.statusText);
                  }
                  this.setStateReady();
                });
            }
          });
      } else {
        this.global.alertService.showError(
          'rfp.alert.info.pleaseCompleteScoring'
        );
      }
    } else {
      this.isSubmit = true;
      this.validate();
      if (this.formGroup.valid && this.totalProposalCriteria === 100) {
        if (this.formGroup.value.date) {
          this.formGroup.patchValue({
            startDate: this.formGroup.value.date.from,
            endDate: this.formGroup.value.date.to
          });
        }
        this.rfpRequest.rfp = this.formGroup.value;
        this.rfpRequest.rfp.rfpStatus = this.rfp?.rfpStatus;
        this.rfpRequest.prItemList = this.rfpResponse.prItemList;
        this.rfpRequest.fileObjectList = this.fileUploader.fileObjectList;
        this.rfpRequest.vendorViewList = this.rfpResponse.vendorViewList
          ? this.rfpResponse.vendorViewList
          : [];
        this.rfpRequest.rfpProposalCriteriaList =
          this.delRfpProposalCriteria && this.delRfpProposalCriteria.length > 0
            ? this.tableResponseProposalCriteria.getUpdatedRecordList()
              ? this.tableResponseProposalCriteria
                  .getUpdatedRecordList()
                  .concat(this.delRfpProposalCriteria)
              : this.tableResponseProposalCriteria
                  .getRecordList()
                  .concat(this.delRfpProposalCriteria)
            : this.tableResponseProposalCriteria.getUpdatedRecordList()
            ? this.tableResponseProposalCriteria.getUpdatedRecordList()
            : this.tableResponseProposalCriteria.getRecordList();
        this.rfpRequest.isSubmit = true;

        this.global.modalService
          .submitConfirmation()
          .pipe(take(1))
          .subscribe(result => {
            if (result) {
              this.setStateProcessing();
              const url = this.todo === 'edit' ? '/rfp/update' : '/rfp/insert';
              this.httpClientService
                .post<Response<Pr>>(url, this.rfpRequest)
                .subscribe(response => {
                  if (response.status === ResponseStatusModel.OK) {
                    this.global.modalService
                      .submitSuccessCreateNew()
                      .pipe(take(1))
                      .subscribe(result => {
                        if (result) {
                          this.router
                            .navigateByUrl('/', { skipLocationChange: true })
                            .then(() => {
                              this.global.routerParams.clear();
                              this.global.routerParams.set('todo', 'add');
                              this.router.navigate(['/pages/rfp/add']);
                              this.global.routerParams.set(
                                'urlBackOutside',
                                '/pages/rfp'
                              );
                            });
                        } else {
                          this.router.navigate(['/pages/rfp']);
                        }
                      });
                  } else {
                    this.global.alertService.showError(response.statusText);
                  }
                  this.setStateReady();
                });
            }
          });
      }
    }
  }
}
