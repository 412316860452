<app-popup header="work-plan-payment.popup.title">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'work-plan-payment.form.popup.terminName' | translate
      }}</label>
      <div class="col-sm-6 text-left">
        <input
          class="form-control validation-field"
          type="text"
          formControlName="terminName"
          [ngClass]="{
            'is-invalid': submitted && formGroup.get('terminName').errors
          }"
          aria-label="Termin Name"
        />
        <small
          class="text-danger"
          *ngIf="
            (submitted && formGroup.get('terminName').errors) ||
            (formGroup.get('terminName').touched &&
              formGroup.get('terminName').errors)
          "
          >{{ 'app.validation.required' | translate }}</small
        >
      </div>
    </div>
    <div class="card-body">
      <div
        class="table-responsive custom-table mb-4 float-none"
        [ngStyle]="{ margin: '0 auto' }"
      >
        <table class="table table-striped box-shadow" aria-describedby="Table">
          <thead>
            <tr>
              <th scope="col" class="text-sm-left">No</th>
              <th scope="col" class="text-sm-left"
                >{{
                  'work-plan-payment.table.popup.column.itemName' | translate
                }}
              </th>
              <th scope="col" class="text-sm-left">{{
                'work-plan-payment.table.popup.column.uom' | translate
              }}</th>
              <th scope="col" class="text-sm-right">{{
                'work-plan-payment.table.popup.column.paymentAmount' | translate
              }}</th>
              <th scope="col" class="text-sm-right">{{
                'work-plan-payment.table.popup.column.remainingPayment'
                  | translate
              }}</th>
              <th scope="col" class="text-sm-right"
                >{{ 'work-plan-payment.table.popup.column.select' | translate }}
              </th>
            </tr>
          </thead>
          <tbody formArrayName="purchaseOrderPaymentDetailList">
            <tr
              *ngFor="
                let purchaseOrderPaymentDetail of formArrayPurchaseOrderPaymentDetailList.controls;
                let j = index
              "
              [formGroup]="purchaseOrderPaymentDetail"
            >
              <td class="text-sm-left">{{ j + 1 }}.</td>
              <td class="text-sm-left">
                {{
                  purchaseOrderPaymentDetail.value.purchaseOrderItem
                    .purchaseRequestItem.workPlanItem.item.name
                }}
              </td>
              <td class="text-sm-left">
                {{
                  purchaseOrderPaymentDetail.value.purchaseOrderItem
                    .purchaseRequestItem.workPlanItem.item.uom.name
                }}
              </td>
              <td class="text-sm-right">
                <input
                  appCurrency
                  class="form-control validation-field text-right px-2"
                  formControlName="paymentAmount"
                  [ngStyle]="{ width: '150px' }"
                  (keyup)="
                    onAmountChange(
                      purchaseOrderPaymentDetail,
                      formGroup.get('purchaseOrderPaymentDetailList').controls,
                      j
                    )
                  "
                  [attr.disabled]="true"
                  aria-label="Purchase Order Payment Detail"
                />
                <!-- 
                  <p>{{checkBoxList[j]}} 
                    <br>
                    {{purchaseOrderPaymentDetail
                    .controls
                    .paymentAmount
                    .disabled}}</p>
                    <p>
                      {{purchaseOrderPaymentDetail
                      .controls.paymentAmount
                      .disabled &&
                      purchaseOrderPaymentDetail
                      .controls
                      .paymentAmount.value 
                      <= 0 ? false 
                      : true}}</p> -->
                <small class="text-danger" *ngIf="!isAmountValidList[j]">{{
                  'work-plan-payment.msg.erorr.paymentAmountInvalid' | translate
                }}</small>
                <small
                  class="text-danger"
                  *ngIf="isPaymentEmptyList[j] && checkBoxList[j]"
                  >{{ 'app.validation.required' | translate }}</small
                >
              </td>
              <td class="text-sm-right">
                {{
                  purchaseOrderPaymentDetail.value.purchaseOrderItem
                    .purchaseRequestItem.totalPriceRealize -
                    purchaseOrderPaymentDetail.value.purchaseOrderItem
                      .paymentAmount +
                    paymentAmountList[j] | currency: ' '
                }}
              </td>
              <td class="text-sm-right">
                <div class="col-sm-12 text-center">
                  <input
                    type="checkbox"
                    (change)="
                      doCheckBox(
                        $event.target.checked,
                        purchaseOrderPaymentDetail,
                        j
                      )
                    "
                    [checked]="checkBoxList[j]"
                    aria-label="Check"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td class="text-sm-right">
                <strong>{{
                  'work-plan-payment.table.popup.column.paymentTotal'
                    | translate
                }}</strong>
              </td>
              <td class="text-sm-right">
                <strong>{{ totalPayment | currency: ' ' }}</strong>
                <p>
                  <small
                    class="text-danger"
                    *ngIf="submitted && totalPayment <= 0"
                    >{{ 'app.validation.required' | translate }}</small
                  >
                </p>
              </td>
              <td class="text-sm-right"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'work-plan-payment.form.popup.paymentDate' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-date-picker formControlName="paymentDate"> </app-date-picker>
      </div>
    </div>

    <div class="form-group row text-sm-right required">
      <label class="col-sm-3 control-label">{{
        'work-plan-payment.form.popup.uploadFile' | translate
      }}</label>
      <div class="col-sm-9 text-left">
        <app-upload
          formControlName="file"
          [fileUploader]="fileUploader"
        ></app-upload>
      </div>
    </div>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="activeModal.close(true)"
        [disabled]="formSaving"
      >
        {{ 'app.button.back' | translate }}
      </button>
      <button type="button" class="btn btn-primary ml-2" (click)="doSubmit()">{{
        'app.button.save' | translate
      }}</button>
    </div>
  </ng-template>
</app-popup>
