import { BaseEntity } from '../base/base-entity';

export class ProjectEntity extends BaseEntity {
    totalPriceItem: string;

    name: string;
    description: string;
    workPlanBudgetTotal: number;
    number: string;
}
