import { ChatMenuWebsocketService } from 'src/app/pages/aanwijzing/chat-menu-websocket.service';
import { ChatState } from '../domain/chat-state';
import { ChatMenuModel } from '../model/chat-menu-model';

export const updateChatRole = (state: ChatState): void => {

    const chatMenuWebsocketService = state.injector.get(ChatMenuWebsocketService);

    chatMenuWebsocketService.menu.subscribe((menu: ChatMenuModel) => {
        console.log('updateChatRole: ', menu);

        if (state.roomCode === menu.roomCode) {
            state.menuModel.roomCode = menu.roomCode;
            state.menuModel.isAllowAttachFile = menu.isAllowAttachFile;
            state.menuModel.isCanSendMessage = menu.isCanSendMessage;
            state.menuModel.isDownloadSession = menu.isDownloadSession;
            state.menuModel.isDownloadUserList = menu.isDownloadUserList;
        }
        console.log('updateChatRole: state', state);
    });

  };
