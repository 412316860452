<app-popup [isLoading]="formLoading">
  <h3 class="separator mb-2">{{
    'compliance-test.popup.complianceType.header' | translate
  }}</h3>
  <form [formGroup]="formGroup">
    <app-select
      [isMultiple]="false"
      formControlName="complianceType"
      [optionList]="complianceTypeOptionList"
      maxHeight="max-content"
      [isShowBorder]="true"
    >
      <ng-template #content let-data>
        <div>
          <h4>{{
            data.nameTranslationKey.module.code.toLowerCase() +
              '.' +
              data.nameTranslationKey.key | translate
          }}</h4>
          {{
            data.descTranslationKey.module.code.toLowerCase() +
              '.' +
              data.descTranslationKey.key | translate
          }}
        </div>
      </ng-template>
    </app-select>
  </form>
  <div class="button-group button-group-center mt-5">
    <app-button [outline]="true" color="SECONDARY" (onClick)="doCancel()">{{
      'app.button.cancel' | translate
    }}</app-button>
    <app-button (onClick)="doChoose()">{{
      'app.button.choose' | translate
    }}</app-button>
  </div>
</app-popup>
