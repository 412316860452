import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  templateUrl: './app-popup-loading-block.component.html',
  styleUrls: ['./app-popup-loading-block.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupLoadingBlockComponent implements OnInit {
  @Input() message: string;
  @Input() isShowCheckMark: boolean;
  @Input() onCloseChanges: EventEmitter<boolean>;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.onCloseChanges.subscribe(() => {
      this.ngbActiveModal.close();
    });
  }
}
