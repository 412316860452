import { BaseEntity } from '../base/base-entity';
import { Module } from '../bean/module';
import { Procurement } from '../bean/procurement';

export class ProcurementScheduleEntity extends BaseEntity {
  crudOperation: number;
  totalSla: number;
  sequence: number;
  procurement: Procurement = new Procurement();
  module: Module = new Module();
  startDate: Date = new Date();
  endDate: Date = new Date();
  location: string;
  startDateTemp: Date = new Date();
  endDateTemp: Date = new Date();
  locationTemp: string;
}
