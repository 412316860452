import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppCardModule } from '../../app-card/app-card.module';
import { AppTableModule } from '../../table/components/app-table/app-table.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupProcurementSummaryComponent } from './app-popup-procurement-summary.component';
import { AppPopupProcurementSummaryService } from './app-popup-procurement-summary.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppTableModule, AppCardModule],
  declarations: [AppPopupProcurementSummaryComponent],
  entryComponents: [AppPopupProcurementSummaryComponent],
  providers: [AppPopupProcurementSummaryService],
  exports: [AppPopupProcurementSummaryComponent]
})
export class AppPopupProcurementSummaryModule {}
