import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { AppPopupWidgetTotalPaymentBillsComponent } from './app-popup-widget-total-payment-bills.component';
import { WidgetTotalPaymentBillsModel } from './model/widget-total-payment-bills.model';

@Component({
  templateUrl: './widget-total-payment-bills.component.html'
})
export class WidgetTotalPaymentBillsComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-total-payment-bills';
  public tableResponse: TableResponseModel<WidgetTotalPaymentBillsModel>;
  public totalRemainingAmount: number;
  public widgetTotalBillsModelList: WidgetTotalPaymentBillsModel[] = [];

  constructor(
    public translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super(WidgetTotalPaymentBillsComponent.moduleCode);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setFormGroup();
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<number>('/widget-total-bills/index')
      .subscribe(
        (widgetTotalBillsModelList: WidgetTotalPaymentBillsModel[]) => {
          this.widgetTotalBillsModelList = widgetTotalBillsModelList;

          this.tableResponse.setRecordList(this.widgetTotalBillsModelList);
          this.tableResponse.reload();

          this.totalRemainingAmount = this.widgetTotalBillsModelList
            .map(model => model.remainingAmount)
            .reduce((a, b) => a + b);
          this.setStateReady();
        }
      );
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'title',
          header: 'table.column.title',
          plugins: { name: 'hyperlink', onClick: this.onClick.bind(this) }
        },
        {
          field: 'vendorName',
          header: 'table.column.vendor'
        },
        {
          field: 'amount',
          header: 'table.column.amount',
          plugins: { name: 'default', type: 'currency' },
          className: 'text-right'
        },
        {
          field: 'paidAmount',
          header: 'table.column.paid',
          plugins: { name: 'default', type: 'currency' },
          className: 'text-right'
        },
        {
          field: 'remainingAmount',
          header: 'table.column.remainingAmount',
          plugins: { name: 'default', type: 'currency' },
          className: 'text-right'
        }
      ],
      { recordPerPage: 5 }
    );
  }

  public onClick(widgetTotalBillsModel: WidgetTotalPaymentBillsModel): void {
    if (widgetTotalBillsModel.isSubmitted) {
      const paymentBillingId = widgetTotalBillsModel.paymentBillingId;
      this.appPopupService
        .open(AppPopupWidgetTotalPaymentBillsComponent, { paymentBillingId })
        .subscribe((paymentBilling: PaymentBilling) => {
          console.log(paymentBilling);
        });
    }
  }
}
