import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Global } from '../global/global';
declare let $: any;
@Pipe({ name: 'resolveNumberToWords' })
export class ResolveNumberToWordsPipe implements PipeTransform {
  constructor(
    public global: Global,
    public translateService: TranslateService
  ) {}

  transform(value: string | number, lang: string): string {
    return $.spellingNumber(value, {
      lang,
      digitsLengthW2F: this.global.appConstant.core.CURRENCY_PRECISSION_SCALE,
      decimalSeperator: this.translateService.instant(
        'app.decimalSeperator.point'
      )
    });
  }
}
