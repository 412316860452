import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';

@Component({
    templateUrl: './rabbit-mq.component.html'
  })
export class RabbitMQComponent extends BaseModuleComponent {

  constructor(
    translateService: TranslateService
  ) {
    super('rabbit-mq', translateService);
  }

    public onInit(): void {
        this.setStateReady();
    }

    public doInterface(entityName: string): void {
        const url = entityName === 'INSTITUTION' ? '/example-rabbit-mq/institution'
        : '/example-rabbit-mq/employee';

        this.global.modalService
        .confirm('Are you sure you want to interface data ' + entityName.toLowerCase() + '?', 'Confirmation')
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<object>>(url, {})
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSaving();
                  this.setStateReady();
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }

}
