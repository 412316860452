<app-popup
  header="contract-termination.popup.header.chooseContract"
  [isLoading]="formLoading"
>
  <div class="main-row-popup-choose-contract">
    <app-table
      [model]="tableResponse"
      [isServerSide]="true"
      stringUrl="/contract-termination/get-contract-list"
    >
      <ng-template #thead let-model>
        <thead>
          <tr>
            <th scope="col" class="tableNo" colspan="1">{{
              'app-table.column.no' | translate
            }}</th>
            <th
              scope="col"
              *ngFor="let column of model.columns; let i = index"
              (click)="
                column.sortable !== false && doSort(column.field)
              "
              [ngClass]="[
                column.sortable !== false ? 'sort' : '',
                model.request.sortField === column.field ? 'bg-light ' : '',
                model.request.sortField === column.field &&
                model.sortOrder === 'ASC'
                  ? 'sort-asc'
                  : '',
                model.request.sortField === column.field &&
                model.sortOrder === 'DESC'
                  ? 'sort-desc'
                  : '',
                column.format === 5 || column.format === 5
                  ? 'text-right'
                  : 'text-center'
              ]"
              [hidden]="column.isChecked === false"
              colspan="99"
            >
              {{ model.moduleCode + '.' + column.header | translate }}
            </th>
            <th scope="col">
              {{ 'contract-termination.table.column.select' | translate }}
            </th>
          </tr>
        </thead>
      </ng-template>
      <ng-template #tbody let-model>
        <tbody>
          <tr *ngIf="model.page.totalRecords === 0">
            <td colspan="9999999" class="bg-white text-center">{{
              'app.info.noData' | translate
            }}</td>
          </tr>
          <tr *ngFor="let record of model.page.records; let i = index">
            <td class="text-center" colspan="1">{{
              i + 1 + model.request.first
            }}</td>
            <td
              *ngFor="let column of model.columns"
              [hidden]="column.isChecked === false"
              colspan="99"
              #tdElement
            >
              {{ record | resolveColumnValue: column:model:tdElement }}
            </td>
            <td>
              <div class="custom-control custom-radio text-center">
                <input
                  type="radio"
                  class="custom-control-input"
                  [attr.id]="'tbody' + i"
                  [attr.name]="'tbody'"
                  (click)="doCheckContract(record)"
                  (keyup)="onKeyUp($event)"
                  [checked]="record.id === contractId"
                />
                <label
                  class="custom-control-label"
                  [attr.for]="'tbody' + i"
                ></label>
              </div>
            </td>
          </tr>
        </tbody>
      </ng-template>
    </app-table>
    <ng-template #modalFooter let-activeModal>
      <div class="col-12 text-center">
        <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
          'app.button.close' | translate
        }}</app-button>
        <app-button color="PRIMARY" (onClick)="doSave()">{{
          'app.button.save' | translate
        }}</app-button>
      </div>
    </ng-template>
  </div>
</app-popup>
