import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { DocRetrieval } from '../../core/bean/doc-retrieval';
import { DocRetrievalStatus } from '../../core/bean/doc-retrieval-status';
import { ItemType } from '../../core/bean/item-type';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementDocType } from '../../core/bean/procurement-doc-type';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { ProcurementSchedule } from '../../core/bean/procurement-schedule';
import { RequirementDoc } from '../../core/bean/requirement-doc';
import { WorkflowStepInfoModel } from '../../core/components/app-workflow-step-info/model/workflow-step-info-model';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { ConverterService } from '../../core/services/converter.service';
import { DownloadService } from '../../core/services/download.service';
import { DocRetrievalView } from '../../core/view/entity/bean/doc-retrieval-view';
import { DocRetrievalResponseRequest } from './doc-retrieval-response-request';
import { DocRetrievalResponseResponse } from './doc-retrieval-response-response';

@Component({
  templateUrl: './doc-retrieval-response-edit.component.html'
})
export class DocRetrievalResponseEditComponent extends BaseModuleComponent {
  public procurementId: number;
  public urlBackOutside: string;
  public docRetrievalStatusCode: string;
  public docRetrievalStatus: DocRetrievalStatus;
  public docRetrievalResponseResponse: DocRetrievalResponseResponse;
  public procurementItemList: ProcurementItem[];
  public procurement: Procurement;
  public itemTypeList: ItemType[];
  public workflowStepInfoModel: WorkflowStepInfoModel;
  public requirementDocList: RequirementDoc[];
  public procurementDocTypeList: ProcurementDocType[];
  public tableResponse: TableResponseModel<DocumentModel>;
  public token: string;
  public procurementSchedule: ProcurementSchedule;
  public canceledOrFailedMessage: string;
  public isShowBadgeStatus: boolean;

  public badgeColor = {
    NEW: 'INFO',
    DONE: 'SUCCESS',
    HOLD: 'DANGER',
    CANCELED: 'DANGER',
    FAILED: 'DANGER'
  };

  constructor(
    translateService: TranslateService,
    public converterService: ConverterService,
    public datePipe: DatePipe,
    public downloadService: DownloadService,
    public activatedRoute: ActivatedRoute
  ) {
    super('doc-retrieval-response', translateService);
  }
  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setDataFromToken();
    } else {
      this.setData();
    }
  }

  public setDataFromToken(): void {
    this.httpClientService
      .get<DocRetrievalView>(
        '/doc-retrieval-response/notification/' + this.token
      )
      .subscribe((docRetrievalView: DocRetrievalView) => {
        if (
          docRetrievalView.startDateProcSchedule &&
          docRetrievalView.endDateProcSchedule
        ) {
          const currentDate = new Date().getTime();
          const startDate = new Date(
            docRetrievalView.startDateProcSchedule
          ).getTime();
          if (
            docRetrievalView.docRetrievalStatus.code ===
              this.global.appConstant.pm.DOC_RETRIEVAL_STATUS_NEW &&
            currentDate < startDate
          ) {
            this.router.navigate(['/pages/doc-retrieval-response']);
            this.global.modalService.scheduleMismatch();
          } else {
            this.setRouterParamsAndNavigate(docRetrievalView);
          }
        } else {
          this.setRouterParamsAndNavigate(docRetrievalView);
        }
      });
  }

  public setRouterParamsAndNavigate(docRetrievalView: DocRetrievalView) {
    this.global.routerParams.clear();
    this.global.routerParams.set(
      'procurementId',
      docRetrievalView.procurementVendor.procurement.id
    );
    this.global.routerParams.set(
      'docRetrievalStatus',
      docRetrievalView.docRetrievalStatus
    );
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/doc-retrieval-response'
    );
    this.global.routerParams.set('todo', 'view');
    this.router.navigate(['/pages/doc-retrieval-response/detail']);
  }

  public setData(): void {
    this.setDataFromRouterParams();
    this.doBuildTableResponse();
    this.setInitializationState();
  }

  public setDataFromRouterParams(): void {
    this.todo =
      localStorage.getItem('todo') || this.global.routerParams.get('todo');
    this.procurementId =
      localStorage.getItem('procurementId') ||
      this.global.routerParams.get('procurementId');
    this.docRetrievalStatus =
      this.global.routerParams.get('docRetrievalStatus');

    // start set doc retrievak status dari proc history
    let docRetrievalStatusTemp = localStorage.getItem(
      'docRetrievalStatus'
    ) as Object;
    if (docRetrievalStatusTemp) {
      docRetrievalStatusTemp = JSON.parse(docRetrievalStatusTemp.toString());
      this.docRetrievalStatus = docRetrievalStatusTemp as DocRetrievalStatus;
      localStorage.removeItem('docRetrievalStatus');
    }
    // end set doc retrievak status dari proc history
    this.isShowBadgeStatus = localStorage.getItem('isShowBadgeStatus')
      ? Boolean(JSON.parse(localStorage.getItem('isShowBadgeStatus')))
      : true;

    localStorage.removeItem('isShowBadgeStatus');
    this.docRetrievalStatusCode = this.docRetrievalStatus.code;
    this.urlBackOutside =
      localStorage.getItem('urlBackOutside') ||
      this.global.routerParams.get('urlBackOutside');
    localStorage.removeItem('todo');
    localStorage.removeItem('procurementId');
    localStorage.removeItem('urlBackOutside');
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'docType',
        header: 'table.column.docType'
      },
      {
        field: 'size',
        header: 'table.column.size'
      }
    ]);
  }

  public setInitializationState(): void {
    this.httpClientService
      .post<DocRetrievalResponseResponse>(
        '/doc-retrieval-response/edit',
        new RouteRequestModel(this.todo, this.procurementId)
      )
      .subscribe((response: DocRetrievalResponseResponse) => {
        this.docRetrievalResponseResponse = response;
        this.procurementItemList = response.procurementItemList;
        this.itemTypeList = response.itemTypeList;
        this.workflowStepInfoModel = response.workflowStepInfoModel;
        this.requirementDocList = response.requirementDocList;
        this.procurementDocTypeList = response.procurementDocTypeList;
        this.procurement = response.procurement;
        this.procurementSchedule = response.procurementSchedule;
        const statusList = [
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
          this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
        ];

        if (statusList.includes(this.procurement.procurementStatus.code)) {
          if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'doc-retrieval-response.alert.msg.sorryWaitingCancelationApproval';
          } else if (
            this.procurement.procurementStatus.code ===
            this.global.appConstant.pm
              .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL
          ) {
            this.canceledOrFailedMessage =
              'doc-retrieval-response.alert.msg.sorryWaitingFailedApproval';
          } else {
            this.canceledOrFailedMessage =
              'doc-retrieval-response.alert.msg.sorryFailedAssessment';
          }
        }
        this.setDocumentModel();
      });
  }

  public setDocumentModel(): void {
    const docModelList: DocumentModel[] = [];
    this.procurementDocTypeList.forEach(procDocType => {
      const docModel = new DocumentModel();
      docModel.docType = this.translateService.instant(
        this.getTranslateKey(procDocType)
      );

      let size = 0;
      const requirementDocList = this.requirementDocList.filter(
        reqDoc => reqDoc.procurementDocType.code === procDocType.code
      );
      requirementDocList.forEach(reqDoc => {
        size += reqDoc.file.size;
      });

      const sizeInMb = this.converterService.convertDecimal(size / 1000000);
      docModel.size =
        +sizeInMb > 1
          ? sizeInMb + ' Mb'
          : this.converterService.convertDecimal(size / 1000) + ' Kb';
      docModelList.push(docModel);
    });

    this.tableResponse.setRecordList(docModelList);
    this.setStateReady();
  }

  public doDownloadAll(): void {
    let fileList: string[] = [];
    this.requirementDocList.map(reqDoc =>
      fileList.push(reqDoc.file.uploadedFileName)
    );
    const request: DocRetrievalResponseRequest =
      new DocRetrievalResponseRequest();
    request.uploadedFileNameList = fileList;
    request.procurementId = this.procurementId;
    const dateTime = this.datePipe.transform(new Date(), 'ddMMyyyyHHmm');
    const fileDownloadName =
      'Document_' + this.procurement.name + '_' + dateTime + '.rar';

    this.downloadService
      .downloadToRar(
        '/doc-retrieval-response/file/view-rar',
        fileList,
        fileDownloadName
      )
      .subscribe(response => {
        if (response.statusText === ResponseStatusModel.OK)
          this.httpClientService
            .post<Response<DocRetrieval>>(
              '/doc-retrieval-response/download-all',
              this.procurementId
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.docRetrievalStatusCode = 'DONE';
              }
            });
      });
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public isDisabledDownloadAllButton(): boolean {
    return this.procurementSchedule
      ? new Date().getTime() >
          new Date(this.procurementSchedule.endDate).getTime()
      : false;
  }
}

export class DocumentModel {
  docType: string;
  size: string;
}
