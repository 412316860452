import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppCheckBoxModule } from '../../app-check-box/app-check-box.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupUserTOCComponent } from './app-popup-user-toc.component';
import { AppPopUpUserTOCService } from './app-popup-user-toc.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppCheckBoxModule],
  declarations: [AppPopupUserTOCComponent],
  entryComponents: [AppPopupUserTOCComponent],
  providers: [AppPopUpUserTOCService],
  exports: [AppPopupUserTOCComponent]
})
export class AppPopupUserTOCModule {}
