import { FileObject } from '../../core/components/upload/model/file-object';
import { WorkPlanItem } from '../../core/bean/work-plan-item';
import { WorkPlanAddress } from '../../core/bean/work-plan-address';
import { WorkPlan } from '../../core/bean/work-plan';

export class WorkPlanRequest {
    workPlan: WorkPlan = new WorkPlan();
    fileObjectList: FileObject[] = [];
    approvalMemoDocObjectList: FileObject[] = [];
    workPlanItemList: WorkPlanItem[] = [];
    workPlanAddressList: WorkPlanAddress[] = [];
    isSaveDraft: boolean;
}
