import { BaseEntity } from '../base/base-entity';
import { DirectPurchaseDeliveryStatus } from '../bean/direct-purchase-delivery-status';
import { ProcurementItem } from '../bean/procurement-item';

export class DirectPurchaseDeliveryEntity extends BaseEntity {
  procurementItem: ProcurementItem;
  directPurchaseDeliveryStatus: DirectPurchaseDeliveryStatus;
  receivedQuantity: number;
  remainingQuantity: number;
  receivedDate: Date;
  performanceValue: number;
  percentage: number;
}
