<dashboard-widget-card
  header="{{ 'dashboard-widget-budget-indicator.header' | translate }}"
  size="MD"
  [isLoading]="model.isLoading"
>
  <form
    class="form-horizontal"
    [formGroup]="formGroup"
    novalidate
    customClassHeader="align-items-center"
    *ngIf="!model.isLoading"
  >
    <ng-template #headerRight>
      <div class="d-flex">
        <div [ngStyle]="{ 'min-width': '150px' }" class="mr-2">
          <app-dropdown-select
            type="COMBOBOX"
            size="XL"
            container="body"
            formControlName="organization"
            [optionList]="organizationOptionList"
            (onChange)="onChangeFormGroup($event)"
            placeholder="dashboard-widget-budget-indicator.form.placeholder.department"
          >
          </app-dropdown-select>
        </div>
        <div [ngStyle]="{ width: '150px' }">
          <app-dropdown-select
            type="COMBOBOX"
            size="XL"
            container="body"
            formControlName="year"
            [optionList]="yearOptionList"
            (onChange)="onChangeFormGroup($event)"
            placeholder="dashboard-widget-budget-indicator.form.placeholder.year"
          >
          </app-dropdown-select>
        </div>
      </div>
    </ng-template>
  </form>
  <app-chart [model]="chartModel" height="239px"></app-chart>
  <ng-template #footer>
    <ul class="list">
      <li class="list-item d-inline-flex align-items-center">
        <span style="width: 20px; height: 20px" class="d-inline-block"></span>
        {{ 'dashboard-widget-budget-indicator.label.totalBudget' | translate }}
        <strong class="d-inline-block ml-2 bold">
          {{
            widgetBudgetIndicatorResponse.totalBudget | resolveNumberToCurrency
          }}
          IDR
        </strong>
      </li>
    </ul>

    <ul class="list">
      <li class="list-item d-inline-flex align-items-center">
        <span style="width: 20px; height: 20px" class="d-inline-block"></span>
        {{ 'dashboard-widget-budget-indicator.label.absorption' | translate }}
        <strong class="text-primary d-inline-block ml-2">
          {{
            widgetBudgetIndicatorResponse.paidOffBudget
              | resolveNumberToCurrency
          }}
          IDR
        </strong>
        <span class="text-secondary d-inline-block ml-1">
          ({{
            widgetBudgetIndicatorResponse.percentageAbsorption
              | resolveNumberToDecimal
          }}
          %)
        </span>
      </li>
    </ul>
  </ng-template>
</dashboard-widget-card>
