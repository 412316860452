import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Vendor } from '../../core/bean/vendor';
import { WorkflowModelPrcs } from '../../core/bean/workflow-model-prcs';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { VendorWhitelistResponse } from './vendor-whitelist-response';
@Component({
  templateUrl: './vendor-whitelist-detail.component.html'
})
export class VendorWhitelistDetailComponent extends BaseModuleComponent {
  public vendorId: number;
  public vendorWhitelistResponse: VendorWhitelistResponse =
    new VendorWhitelistResponse();
  public isDetail = false;
  public workflowModelPrcs: WorkflowModelPrcs = new WorkflowModelPrcs();
  public isApproval = false;
  public objectId: number;
  public approvalModelPrcsId: number;
  public token: string;

  constructor(
    translateService: TranslateService,
    public activatedRoute: ActivatedRoute
  ) {
    super('vendor-whitelist', translateService);
    Object.assign(this, this.global.routerParams);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Vendor>('/vendor-whitelist/notification/' + this.token)
      .subscribe((vendor: Vendor) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('vendorId', vendor.id);
        if (
          vendor.vendorActivityStatus.code ===
          this.global.appConstant.vm.V_ACTIVITY_STATUS_REVISION_VENDOR_WHITELIST
        ) {
          this.global.routerParams.set('todo', 'edit');
          this.router.navigate(['/pages/vendor-whitelist/edit']);
        } else {
          this.global.routerParams.set('todo', 'view');
          this.router.navigate(['/pages/vendor-whitelist/detail']);
        }
      });
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorId = this.global.routerParams.get('vendorId');
    this.isApproval = this.global.routerParams.get('isApproval');
    this.objectId = this.global.routerParams.get('objectId');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [null],
      code: [null],

      startDateWhite: [null],
      noteWhite: [null],
      reasonWhite: [null],
      whitelistDoc: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<VendorWhitelistResponse>(
        '/vendor-whitelist/detail',
        new RouteRequestModel(
          this.todo,
          this.objectId != null ? this.objectId : this.vendorId
        )
      )
      .subscribe(vendorWhitelistResponse => {
        this.vendorWhitelistResponse = vendorWhitelistResponse;
        this.workflowModelPrcs =
          vendorWhitelistResponse.vendorWhitelist.workflowModelPrcs;

        this.isDetail = true;

        if (this.vendorWhitelistResponse.vendor != null) {
          const { name, code } = this.vendorWhitelistResponse.vendor;
          const startDateWhite: Date = new Date(
            this.vendorWhitelistResponse.vendorWhitelist.startDate
          );
          const noteWhite = this.vendorWhitelistResponse.vendorWhitelist.note;
          const reasonWhite =
            this.vendorWhitelistResponse.vendorWhitelist.reason;

          if (this.vendorWhitelistResponse.vendorWhitelist.file != null) {
            const whitelistDoc =
              this.vendorWhitelistResponse.vendorWhitelist.file.fileName;
            this.formGroup.patchValue({ whitelistDoc });
          }

          this.formGroup.patchValue({
            name,
            code,
            startDateWhite,
            noteWhite,
            reasonWhite
          });
        }
        this.setStateReady();
      });
  }

  public doCancel(): void {
    this.router.navigate(['/pages/vendor-whitelist']);
  }
}
