<app-popup
  header="{{ 'app-widget-vendor-information.popupDocExp' | translate }}"
>
  <h5 class="separator"
    >{{ 'app-widget-vendor-information.vendorDocTable' | translate }}
    <span class="control-label"></span
  ></h5>
  <app-table #vendorDoc [tableResponse]="vendorDocTableResponse"> </app-table>
  <h5 class="separator"
    >{{ 'app-widget-vendor-information.vendorAddDocTable' | translate }}
    <span class="control-label"></span
  ></h5>
  <app-table #vendorAdditionalDoc [tableResponse]="vendorAddDocTableResponse">
  </app-table>
</app-popup>
