<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="user-manual.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <div class="form-group row text-sm-right">
        <label class="col-sm-3 control-label">{{
          'user-manual.form.role' | translate
        }}</label>
        <label class="col-sm-7 text-left">{{ role.name }}</label>
      </div>
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'user-manual.form.upload' | translate
          }}</label>
          <div class="col-sm-9">
            <app-tab formArrayName="userManualList" bordered="true">
              <app-tab-item
                header="{{ userManual.value.lang.name }}"
                *ngFor="
                  let userManual of formArrayUserManualList.controls;
                  let i = index
                "
                [formGroup]="userManual"
              >
                <div class="row">
                  <div class="col-sm-9 card-body text-left text-break">
                    <app-upload
                      [fileUploader]="fileUploaderList[i]"
                      formControlName="file"
                      size="LG"
                    >
                    </app-upload>
                  </div>
                </div>
              </app-tab-item>
            </app-tab>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
