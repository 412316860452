import { Injectable } from '@angular/core';
import { AppPopupService } from '../app-popup.service';
import { AppPopupWorkPlanComponent } from './app-popup-work-plan.component';

@Injectable()
export class AppPopupWorkPlanService {
  constructor(public appPopupService: AppPopupService) {}

  public open(workplanId?: number) {
    return this.appPopupService.open(AppPopupWorkPlanComponent, { workplanId });
  }
}
