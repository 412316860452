import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../base/angular/base-module.component';
import { ProcurementVendor } from '../../../bean/procurement-vendor';
import { ResultAnalysisSingleView } from '../../../view/entity/bean/result-analysis-single-view';
import { TablePluginData } from '../../table/interface/table-plugin-data';
import { TableResponseModel } from '../../table/model/table-response-model';

@Component({
  templateUrl: './app-popup-analysis-single.component.html'
})
export class AppPopupAnalysisSingleComponent extends BaseModuleComponent {
  @Input() procurementVendorId: number;
  @Input() parentModuleCode: string;

  public tableResponse: TableResponseModel<ResultAnalysisSingleView>;
  public procurementVendor: ProcurementVendor;

  constructor(
    public activeModal: NgbActiveModal,
    translateService: TranslateService
  ) {
    super('app-popup-analysis-single', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.getData();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'oepValue',
        header: 'table.column.oepValue',
        plugins: { name: 'default', type: 'currency' },
        className: 'text-right'
      },
      {
        field: 'realizationValue',
        header: 'table.column.realizationValue',
        plugins: { name: 'default', type: 'currency' },
        className: 'text-right'
      },
      {
        field: 'procurementRealization',
        header: 'table.column.procurementRealization',
        plugins: {
          name: 'custom-plugin',
          after: (tablePlugin: TablePluginData): void => {
            if (tablePlugin.value) {
              tablePlugin.element.innerText = tablePlugin.value + '%';
            } else {
              tablePlugin.element.innerText = '0%';
            }
          }
        },
        className: 'text-right'
      },
      {
        field: 'procurementSavingLoss',
        header: 'table.column.procurementSavingLoss',
        plugins: { name: 'default', type: 'currency' },
        className: 'text-right'
      },
      {
        field: 'procurementSavingLossPercent',
        header: 'table.column.procurementSavingLossPercent',
        plugins: {
          name: 'custom-plugin',
          after: (tablePlugin: TablePluginData): void => {
            if (tablePlugin.value) {
              tablePlugin.element.innerText = tablePlugin.value + '%';
            } else {
              tablePlugin.element.innerText = '0%';
            }
          }
        },
        className: 'text-right'
      },
      {
        field: 'oepSavingLoss',
        header: 'table.column.oepSavingLoss',
        plugins: { name: 'default', type: 'currency' },
        className: 'text-right'
      },
      {
        field: 'oepSavingLossPercent',
        header: 'table.column.oepSavingLossPercent',
        plugins: {
          name: 'custom-plugin',
          after: (tablePlugin: TablePluginData): void => {
            if (tablePlugin.value) {
              tablePlugin.element.innerText = tablePlugin.value + '%';
            } else {
              tablePlugin.element.innerText = '0%';
            }
          }
        },
        className: 'text-right'
      }
    ]);
  }

  public getData(): void {
    this.httpClientService
      .get<ProcurementVendor>(
        '/app-popup-analysis-single/get-data/' + this.procurementVendorId
      )
      .subscribe((procurementVendor: ProcurementVendor) => {
        this.procurementVendor = procurementVendor;
        this.tableResponse.setCustomData({
          procurementVendorId: this.procurementVendor.id,
          moduleCode: this.parentModuleCode
        });
        this.setStateReady();
      });
  }
}
