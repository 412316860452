import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AddressPic } from '../../core/bean/address-pic';
import { User } from '../../core/bean/user';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { AppPopupUserService } from '../../core/components/app-popup/app-popup-user/app-popup-user.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { UserView } from '../../core/view/entity/bean/user-view';
import { AddressBook } from './../../core/bean/address-book';
import { AddressBookRequest } from './address-book-request';
import { AddressBookResponse } from './address-book-response';
import { PopupChooseAddressLocationComponent } from './popup-choose-address-location.component';
import { PopupChooseAddressPicComponent } from './popup-choose-address-pic.component';
@Component({
  templateUrl: './address-book-edit-add.component.html'
})
export class AddressBookEditAddComponent extends BaseModuleComponent {
  public addressBookId: number;
  public addressBook: AddressBook = new AddressBook();
  public addressPicList: AddressPic[] = [];
  public picList: UserView[] = [];
  public regionCodeList: string[] = [];
  public userViewListAll: UserView[] = [];
  public readonly locationDefChecklist = [];
  constructor(
    translateService: TranslateService,
    public appPopupUserService: AppPopupUserService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService,
    public appPopupService: AppPopupService
  ) {
    super('address-book', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.doGetAndSetAnddressBook();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.addressBookId = this.global.routerParams.get('addressBookId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null, Validators.compose([Validators.required()])],
      address: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      region: [null],
      regionName: [null, Validators.compose([Validators.required()])],
      user: [null],
      userViewList: [null, Validators.required()],
      phone: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      addressDetail: [null],
      organization: [null],
      organizationName: [null, Validators.compose([Validators.required()])]
    });
  }

  public doGetAndSetAnddressBook(): void {
    this.httpClientService
      .post<AddressBookResponse>(
        '/address-book/add-edit',
        new RouteRequestModel(this.todo, this.addressBookId)
      )
      .subscribe((addressBookResponse: AddressBookResponse) => {
        this.userViewListAll = addressBookResponse.userViewListAll;
        if (addressBookResponse && addressBookResponse.address) {
          this.addressBook = addressBookResponse.address;
          const {
            id,
            code,
            address,
            region,
            user,
            phone,
            addressDetail,
            organization
          } = addressBookResponse.address;
          const { name: organizationName } = organization;
          const { name: regionName } = region;
          this.formGroup.patchValue({
            id,
            code,
            address,
            region,
            regionName,
            user,
            phone,
            addressDetail,
            organization,
            organizationName,
            userViewList: addressBookResponse.userViewList
            // addressPicList
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const userViewList: UserView[] =
              this.formGroup.get('userViewList').value;
            const addressBook = this.global.copyFormAttributeToModel(
              new AddressBook(),
              this.formGroup
            );
            addressBook.addressPicList = null;
            const addressPicList: AddressPic[] = [];

            userViewList.forEach(userView => {
              if (
                this.addressBook.addressPicList &&
                this.addressBook.addressPicList.length > 0
              ) {
                const existAddressPic = this.addressBook.addressPicList.find(
                  addressPic => addressPic.user.id === userView.id
                );
                if (existAddressPic) {
                  existAddressPic.crudOperation =
                    this.global.appConstant.core.CRUD_OPERATION_UPDATE;
                  addressPicList.push(existAddressPic);
                } else {
                  const newAddressPic: AddressPic = new AddressPic();
                  newAddressPic.user = new User();
                  newAddressPic.user.id = userView.id;
                  newAddressPic.user.name = userView.name;
                  newAddressPic.crudOperation =
                    this.global.appConstant.core.CRUD_OPERATION_INSERT;
                  addressPicList.push(newAddressPic);
                }
              } else {
                const newAddressPic: AddressPic = new AddressPic();
                newAddressPic.user = new User();
                newAddressPic.user.id = userView.id;
                newAddressPic.user.name = userView.name;
                newAddressPic.crudOperation =
                  this.global.appConstant.core.CRUD_OPERATION_INSERT;
                addressPicList.push(newAddressPic);
              }
            });
            if (
              this.addressBook &&
              this.addressBook.addressPicList &&
              this.addressBook.addressPicList.length > 0
            ) {
              const deletedAddressPicList =
                this.addressBook.addressPicList.filter(
                  addressPic =>
                    !addressPicList.find(
                      ap => ap.user.id === addressPic.user.id
                    ) && addressPic.id
                );
              if (deletedAddressPicList && deletedAddressPicList.length > 0) {
                deletedAddressPicList.map(
                  addressPic =>
                  (addressPic.crudOperation =
                    this.global.appConstant.core.CRUD_OPERATION_DELETE)
                );
                addressPicList.push(...deletedAddressPicList);
              }
            }
            const addressBookRequest: AddressBookRequest =
              new AddressBookRequest();
            addressBookRequest.address = addressBook;
            addressBookRequest.addressPicList = addressPicList;
            const url =
              this.todo === 'edit'
                ? '/address-book/update'
                : '/address-book/insert';
            this.httpClientService
              .post<Response<AddressBook>>(url, addressBookRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/address-book']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/address-book']);
  }

  public doChooseOrganization(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(organization => {
        organization = organization.pop();
        const { name: organizationName } = organization;
        this.formGroup.patchValue({ organization, organizationName });
        this.formGroup.patchValue({ userViewList: [] });
      });
  }

  public doChooseUser(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    const selectedUserList: UserView[] =
      this.formGroup.get('userViewList').value || [];
    if (selectedUserList && selectedUserList.length > 0) {
      selectedUserList.forEach(user => {
        user.lastAction = null;
        // user.organization = null;
      });
    }
    const filteredUserList = this.userViewListAll.filter(user => {
      const organizationCode = this.formGroup.get('organization').value?.code;
      return user.organization?.code === organizationCode;
    });
    this.appPopupService
      .open(
        PopupChooseAddressPicComponent,
        { selectedUserList, filteredUserList },
        { size: 'xl' }
      )
      .subscribe((userViewList: UserView[]) => {
        this.formGroup.patchValue({ userViewList });
      });
  }

  public doChooseLocation(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    const todo = 'add';
    const checkedRegionCodeList = this.regionCodeList;
    const locationDefChecklist = this.locationDefChecklist;
    this.appPopupService
      .open(PopupChooseAddressLocationComponent, {
        todo,
        checkedRegionCodeList,
        locationDefChecklist
      })
      .subscribe(region => {
        const { name: regionName } = region;
        this.formGroup.patchValue({ region, regionName });
      });
  }
}
