import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppWorklistInfoModule } from 'src/app/core/components/app-worklist-info/app-worklist-info.module';
import { SharedModule } from '../../core/shared/shared.module';
import { ApprovalPrcsComponent } from './approval-prcs.component';

export const routes = [
  { path: '', component: ApprovalPrcsComponent, data: { breadcrumb: '' } }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, AppWorklistInfoModule],
  declarations: [ApprovalPrcsComponent]
})
export class ApprovalPrcsModule {}
