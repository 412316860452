import { NgModule } from '@angular/core';
import {
  RecaptchaFormsModule,
  RecaptchaModule,
} from 'ng-recaptcha';
import { VendorModule } from '../../vendor/vendor.module';
import { AppCaptchaComponent } from './app-captcha.component';
@NgModule({
  imports: [VendorModule, RecaptchaModule, RecaptchaFormsModule],
  declarations: [AppCaptchaComponent],
  exports: [AppCaptchaComponent]
})
export class AppCaptchaModule {}
