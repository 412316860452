<div class="row">
  <div class="col-12">
    <app-tab [model]="tabResponse" (onChangeTab)="doNext($event)">
      <router-outlet></router-outlet>
      <ng-template #tabFooter let-currentTab>
        <div class="button-group button-group-center mt-5">
          <app-button type="BTN_PRIMARY"
            (onClick)="doBack(currentTab.previousTab)"
            *ngIf="!currentTab.isFirstTab">{{ 'app.button.back' | translate }}
          </app-button>
          <app-button type="BTN_PRIMARY" (onClick)="doNext(currentTab.nextTab)"
            *ngIf="!currentTab.isLastTab">{{ 'app.button.next' | translate }}
          </app-button>
          <app-button type="BTN_PRIMARY" (onClick)="doSave()"
            *ngIf="currentTab.isLastTab">{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </ng-template>
    </app-tab>

    <br/>
    <app-layout [tabModel]="tabResponse">
      <router-outlet></router-outlet>
    </app-layout>
    
  </div>
</div>