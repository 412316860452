import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { RatingProductModel } from '../../app-rating/app-rating-product/model/rating-product-model';
@Component({
  templateUrl: './app-popup-statistik-vendor.component.html',
  styleUrls: ['./app-popup-statistik-vendor.component.scss']
})
export class AppPopupStatistikVendorComponent extends BaseComponentComponent {
  @Input() ratingProduct: RatingProductModel;
  constructor() {
    super('app-popup-statistik-vendor');
  }

  public onInit(): void {
    this.setStateReady();
  }
}
