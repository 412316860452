import { NgModule } from '@angular/core';
import { AccordionService } from '../../core/components/app-accordion/accordion.service';
import { SharedModule } from './../../core/shared/shared.module';
import { TabContractHistoryComponent } from './tab-contract-history.component';
import { TabContractComponent } from './tab-contract.component';
@NgModule({
  imports: [SharedModule],
  declarations: [TabContractComponent, TabContractHistoryComponent],
  entryComponents: [TabContractComponent, TabContractHistoryComponent],
  exports: [TabContractComponent, TabContractHistoryComponent]
})
export class TabContractModule {
  constructor(accordionService: AccordionService) {
    accordionService.register('tab-contract-history', TabContractComponent);
    // appAccordionService.register('tab-contract-history', TabContractHistoryComponent);
  }
}
