/**
 * author  : Rei
 * version : 1.0
 * since   : 2018-01-08
 */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Global } from '../global/global';
import { Log } from '../services/logger';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  public log: Log = new Log(this);
  constructor(public router: Router, public global: Global) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (ev: HttpEvent<any>) => {
          if (ev instanceof HttpResponse) {
            this.log.debug('');
            this.log.debug(
              '%c Response %c░░▒▒▓▓%c  ' + ev.status,
              'background:green; color: white',
              'background:green; color: white; font-weight:bold',
              'color: blue'
            );
            this.log.debug(ev);
          }
        },
        error => this.handleResponseError(error)
      )
    );
  }

  private handleResponseError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      this.showLogErrors(error);
      switch (error.status) {
        case 0:
          this.global.blockUI(
            'error',
            this.global.translateService.instant(
              'app.msg.error.failedToConnect'
            )
          );

          // this.global.unblockUI();
          const navigateUrl = this.global.userSession
            ? '/pages/lost-connection'
            : '/lost-connection';
          this.global.routerParams.set(
            'lostConnectionTitle',
            this.global.translateService.instant('app.msg.connectionLost.title')
          );
          this.global.routerParams.set(
            'lostConnectionBody',
            this.global.translateService.instant('app.msg.connectionLost.body')
          );
          this.router.navigate([navigateUrl]);
          break;
        case 401:
          this.createUnauthorizedError(error.error);
          break;
        case 404:
          break;
        default:
          // comes from Spring RestControllerExceptionAdvice, HttpStatus.INTERNAL_SERVER_ERROR (500)
          this.global.blockUI(
            'error',
            error.error.statusText ? error.error.statusText : error.statusText
          );
          break;
      }
    }
  }

  private showLogErrors(error: HttpErrorResponse): void {
    if (error.status !== 200 && error.status !== 404) {
      let message: string = error.status === 401 ? error.error : error.message;
      if (error.status === 0) {
        message = this.global.translateService.instant(
          'app.msg.error.failedToConnect'
        );
      }
      this.log.error('');
      this.log.error(
        '%c        *** http ERROR intercepted ***        ',
        'background:red; color: yellow; font-weight:bold'
      );
      this.log.error('status    :', error.status);
      this.log.error('statusText:', error.statusText);
      this.log.error('message   :', message);
      this.log.error(error.headers);
    }
  }

  private createUnauthorizedError(errorMessage: string): void {
    if (errorMessage.includes('REQ-ERR-004')) {
      this.global.blockUI('error', errorMessage);
    } else {
      const navigateUrl = this.global.userSession ? '/pages/error' : '/error';
      this.global.routerParams.set('error.msg', errorMessage);
      this.router.navigate([navigateUrl]);
    }
  }
}
