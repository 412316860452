import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from '../../model/response-model';
import { ServiceLocator } from '../../services/service-locator';
export class CustomValidatorService {
  public static httpClient(): HttpClient {
    return ServiceLocator.injector.get(HttpClient);
  }
  public static exists(
    stringUrl: string,
    data: string,
    id: number,
    options: object
  ): Observable<boolean | Response<any>> {
    return this.httpClient().post<boolean | Response<any>>(
      stringUrl,
      options
        ? JSON.stringify({ ...options, value: data })
        : id
        ? { data, id }
        : data
    );
  }
}
