<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="scoring-template.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'scoring-template.form.code' | translate
          }}</label>
          <div class="col-7 text-left">
            <app-text-field
              autofocus="true"
              size="SM"
              formControlName="code"
              type="code"
              maxLength="32"
              tooltip="{{ 'scoring-template.tooltip.code' | translate }}"
            >
            </app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'scoring-template.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-field formControlName="name" maxLength="32">
            </app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'scoring-template.form.description' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-text-area formControlName="description" maxLength="512">
            </app-text-area>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'scoring-template.form.moduleScoringCriteria' | translate
          }}</label>
          <div class="col-sm-9 col-lg-7 text-left">
            <app-radio
              formControlName="moduleScoringCriteria"
              position="HORIZONTAL"
              [optionList]="moduleOptionList"
              (onChange)="setTypeOption()"
            >
            </app-radio>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label
            *ngIf="moduleScoringCriteria === null"
            class="col-sm-3 control-label"
            >{{ 'scoring-template.form.typeOnly' | translate }}</label
          >
          <label
            *ngIf="moduleScoringCriteria === 1"
            class="col-sm-3 control-label"
            >{{ 'scoring-template.form.type' | translate }}</label
          >
          <label
            *ngIf="moduleScoringCriteria === 2"
            class="col-sm-3 control-label"
            >{{ 'scoring-template.form.typeProcurement' | translate }}</label
          >
          <label
            *ngIf="moduleScoringCriteria === 3"
            class="col-sm-3 control-label"
            >{{ 'scoring-template.form.objectName' | translate }}</label
          >
          <div
            class="col-sm-9 text-left"
            *ngIf="moduleScoringCriteria === 1 || !moduleScoringCriteria"
          >
            <app-combo-box [optionList]="optionList" formControlName="type">
            </app-combo-box>
          </div>
          <div class="col-sm-9 text-left" *ngIf="moduleScoringCriteria === 2">
            <app-text-tree
              formControlName="type"
              stringUrl="/procurement-type"
              header="Procurement Type"
            ></app-text-tree>
          </div>
          <div class="col-sm-9 text-left" *ngIf="moduleScoringCriteria === 3">
            <app-combo-box
              [optionList]="objectNameOptionList"
              formControlName="objectName"
              (onChange)="onChangeObjectName()"
            >
            </app-combo-box>
          </div>
        </div>

        <div
          class="form-group row text-sm-right required"
          *ngIf="moduleScoringCriteria === 3"
        >
          <label class="col-sm-3 control-label">{{
            'scoring-template.form.objectType' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <app-combo-box
              [optionList]="objectTypeOptionList"
              formControlName="type"
            >
            </app-combo-box>
          </div>
        </div>

        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'scoring-template.form.minimumQualified' | translate
          }}</label>
          <div class="col-7 text-left">
            <app-text-field
              size="SM"
              formControlName="minimumQualified"
              type="decimal"
              maxLength="11"
            >
            </app-text-field>
          </div>
        </div>

        <!-- COMMON -->
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-8">
            <app-fieldset legend="Common">
              <ng-container formArrayName="scoringTemplateWeightListAndCommon">
                <div class="form-group row text-sm-left required">
                  <div class="col-sm-2"></div>
                  <label class="col-sm-4 control-label">{{
                    'scoring-template.form.option' | translate
                  }}</label>
                  <label class="col-sm-6 control-label pl-0"
                    >{{ 'scoring-template.form.weight' | translate }} (%)</label
                  >
                </div>
                <div
                  class="form-group row required"
                  *ngFor="
                    let scoringTemplateWeightListAndCommon of inputScoringTemplateWeightListAndCommon.controls;
                    let i = index
                  "
                  [formGroupName]="i"
                >
                  <div class="col-sm-2"></div>
                  <div class="col-sm-4">
                    <app-auto-complete
                      size="XL"
                      formControlName="scoringCriteriaGroup"
                      (onChange)="autoCompleteValueChangeCommon($event, i)"
                      stringUrl="/scoring-template/get-common-list"
                      [params]="customDataCommon"
                    >
                    </app-auto-complete>
                  </div>

                  <div class="col-sm-2 pl-0 pr-1">
                    <app-text-field
                      size="XL"
                      type="decimal"
                      maxLength="5"
                      formControlName="weight"
                      (onChange)="onChangeWeight()"
                    >
                    </app-text-field>
                  </div>
                  <div
                    class="col-sm-1 pl-0 pr-0 d-flex"
                    [ngStyle]="{ 'justify-content': 'space-around' }"
                  >
                    <app-tooltip position="TOP_RIGHT">
                      <em class="fas fa-eye"></em>
                      <ng-template #tooltipContent>
                        <table
                          class="table table-striped"
                          aria-describedby="Table"
                        >
                          <thead>
                            <tr>
                              <th scope="col" colspan="99"
                                >{{
                                  'scoring-template.form.option' | translate
                                }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                >{{ 'scoring-template.form.code' | translate }}
                              </td>
                              <td>
                                {{
                                  formGroup.get(
                                    'scoringTemplateWeightListAndCommon'
                                  ).value[i].scoringCriteriaGroup?.code
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td
                                >{{ 'scoring-template.form.name' | translate }}
                              </td>
                              <td>
                                {{
                                  formGroup.get(
                                    'scoringTemplateWeightListAndCommon'
                                  ).value[i].scoringCriteriaGroup?.name
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>{{
                                'scoring-template.form.typeOnly' | translate
                              }}</td>
                              <td> Common </td>
                            </tr>
                            <tr>
                              <td>{{
                                'scoring-template.form.criteria' | translate
                              }}</td>
                              <td>
                                <p
                                  *ngIf="
                                    formGroup.get(
                                      'scoringTemplateWeightListAndCommon'
                                    ).value[i].scoringCriteriaGroup == null
                                  "
                                >
                                  -
                                </p>
                                <p
                                  *ngFor="
                                    let data of formGroup.get(
                                      'scoringTemplateWeightListAndCommon'
                                    ).value[i].scoringCriteriaGroup
                                      ?.scoringCriteriaList;
                                    let j = index
                                  "
                                >
                                  {{ data.name }}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-template>
                    </app-tooltip>
                    <app-button
                      color="SECONDARY"
                      title="{{ 'app.tooltip.delete' | translate }}"
                      size="SM"
                      (onClick)="doRemoveCommon(i)"
                      ><em class="fa fas fa-trash"></em>
                    </app-button>
                  </div>
                </div>
              </ng-container>
              <div class="text-center">
                <app-button
                  size="SM"
                  (onClick)="addScoringCriteriaGroupCommon()"
                >
                  <em class="fas fa-plus"></em>
                  {{ 'app.button.addMore' | translate }}
                </app-button>
              </div>
            </app-fieldset>
          </div>
        </div>

        <!-- SPECIFIC -->
        <div class="row">
          <div class="col-sm-2"></div>
          <div class="col-sm-8">
            <app-fieldset legend="Specific">
              <ng-container
                formArrayName="scoringTemplateWeightListAndSpesific"
              >
                <div class="form-group row text-sm-left required">
                  <div class="col-sm-2"></div>
                  <label class="col-sm-4 control-label">{{
                    'scoring-template.form.option' | translate
                  }}</label>
                  <label class="col-sm-6 control-label pl-0"
                    >{{ 'scoring-template.form.weight' | translate }} (%)</label
                  >
                </div>
                <div
                  class="form-group row required"
                  *ngFor="
                    let scoringTemplateWeightListAndSpesific of inputScoringTemplateWeightListAndSpesific.controls;
                    let i = index
                  "
                  [formGroupName]="i"
                >
                  <div class="col-sm-2"></div>
                  <div class="col-sm-4">
                    <app-auto-complete
                      size="XL"
                      formControlName="scoringCriteriaGroup"
                      stringUrl="/scoring-template/get-spesific-list"
                      [params]="customDataSpesific"
                      (onChange)="autoCompleteValueChangeSpesific($event, i)"
                    >
                    </app-auto-complete>
                  </div>
                  <div class="col-sm-2 pl-0 pr-1">
                    <app-text-field
                      size="XL"
                      type="decimal"
                      maxLength="5"
                      formControlName="weight"
                      (onChange)="onChangeWeight()"
                    >
                    </app-text-field>
                  </div>
                  <div
                    class="col-sm-1 pl-0 pr-0 d-flex"
                    [ngStyle]="{ 'justify-content': 'space-around' }"
                  >
                    <app-tooltip position="TOP_RIGHT">
                      <em class="fas fa-eye"></em>
                      <ng-template #tooltipContent>
                        <table
                          class="table table-striped"
                          aria-describedby="Table"
                        >
                          <thead>
                            <tr>
                              <th scope="col" colspan="99"
                                >{{
                                  'scoring-template.form.option' | translate
                                }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                >{{ 'scoring-template.form.code' | translate }}
                              </td>
                              <td>
                                {{
                                  formGroup.get(
                                    'scoringTemplateWeightListAndSpesific'
                                  ).value[i].scoringCriteriaGroup?.code
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td
                                >{{ 'scoring-template.form.name' | translate }}
                              </td>
                              <td>
                                {{
                                  formGroup.get(
                                    'scoringTemplateWeightListAndSpesific'
                                  ).value[i].scoringCriteriaGroup?.name
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>{{
                                'scoring-template.form.typeOnly' | translate
                              }}</td>
                              <td> Specific </td>
                            </tr>
                            <tr>
                              <td>{{
                                'scoring-template.form.criteria' | translate
                              }}</td>
                              <td>
                                <p
                                  *ngIf="
                                    formGroup.get(
                                      'scoringTemplateWeightListAndSpesific'
                                    ).value[i].scoringCriteriaGroup == null
                                  "
                                >
                                  -
                                </p>
                                <p
                                  *ngFor="
                                    let data of formGroup.get(
                                      'scoringTemplateWeightListAndSpesific'
                                    ).value[i].scoringCriteriaGroup
                                      ?.scoringCriteriaList;
                                    let j = index
                                  "
                                >
                                  {{ data.name }}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-template>
                    </app-tooltip>
                    <app-button
                      color="SECONDARY"
                      size="SM"
                      title="{{ 'app.tooltip.delete' | translate }}"
                      (onClick)="doRemoveSpesific(i)"
                    >
                      <em class="fa fas fa-trash"></em>
                    </app-button>
                  </div>
                </div>
              </ng-container>

              <div class="text-center">
                <app-button
                  size="SM"
                  (onClick)="addScoringCriteriaGroupSpesific()"
                >
                  <em class="fas fa-plus"></em>
                  {{ 'app.button.addMore' | translate }}
                </app-button>
              </div>
            </app-fieldset>
            {{ 'scoring-template.form.weightTotal' | translate }}:
            {{ weightTotal / 100 | number: '1.2-2' }}%
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <app-button
            class="mr-1"
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button color="PRIMARY" (onClick)="doSave()">
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
