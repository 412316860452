<div class="app-select app-combo-box" [ngClass]="elementWidthClassName">
  <div
    class="form-control app-select-label"
    [ngClass]="[
      elementHeightClassName,
      isInvalid ? 'is-invalid' : '',
      isValid ? 'is-valid' : '',
      formControl.disabled ? 'disabled' : ''
    ]"
    (click)="handleClickInput($event)"
    (keyup)="onKeyUp($event)"
    (keydown)="onKeyDown($event)"
  ></div>
  <div class="app-select-container"></div>
  <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
  </ng-container>
</div>
<small class="text-danger" *ngIf="isInvalid">
  {{ errors.message }}
</small>
<div class="d-inline-block position-relative" *ngIf="ISVIEW">
  <div
    class="app-select-label"
    [ngClass]="[isShowIcon && formControl.value ? 'show-icon' : '']"
  >
    <img
      [attr.src]="
        global.config.BACKEND_ADDRESS +
          '/home-slide/file/view/' +
          (formControl.value | resolveFieldData: iconPath)
          | resolveImgSrc
          | async
      "
      alt="option-icon"
      *ngIf="isShowIcon && value"
      width="16"
      height="16"
    />
    <span>
      {{
        optionList
          ? (formControl.value &&
              (viewPath
                ? (formControl.value | resolveFieldData: viewPath)
                : formControl.value.name)) ||
            '-'
          : (formControl.value && formControl.value.name) ||
            formControl.value ||
            '-'
      }}
    </span>
  </div>
  <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
  </ng-container>
</div>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [symbolic]="symbolic"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
