import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Vendor } from '../../core/bean/vendor';
import { VendorWhitelist } from '../../core/bean/vendor-whitelist';
import { AppPopupVendorService } from '../../core/components/app-popup/app-popup-vendor/app-popup-vendor.service';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { VMConstant } from './../../core/constant/vm-constant';
import { VendorWhitelistRequest } from './vendor-whitelist-request';
import { VendorWhitelistResponse } from './vendor-whitelist-response';

@Component({
  templateUrl: './vendor-whitelist-edit-add.component.html'
})
export class VendorWhitelistEditAddComponent extends BaseModuleComponent {
  public vendorId: number;
  public vendor: Vendor = new Vendor();
  public vendorWhitelistResponse: VendorWhitelistResponse =
    new VendorWhitelistResponse();
  public vendorWhitelistRequest: VendorWhitelistRequest =
    new VendorWhitelistRequest();
  public vendorWhitelist: VendorWhitelist = new VendorWhitelist();
  public isEdit = false;
  public isAdd = false;
  public vendorCode: string;
  public isRevision = false;
  public vmConstant: VMConstant = new VMConstant();
  public token: string;

  public fileUploader: FileUploader = new FileUploader(
    '/vendor-whitelist/',
    'vendorwhitelist',
    this.global.appConstant.fileType.DOC_VENDOR_WHITELIST
  );

  constructor(
    translateService: TranslateService,
    public appPopUpVendorService: AppPopupVendorService,
    public activatedRoute: ActivatedRoute
  ) {
    super('vendor-whitelist', translateService);
    Object.assign(this, this.global.routerParams);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setInitializationStateFromToken();
    } else {
      this.setInitializationState();
    }
  }

  public setInitializationStateFromToken(): void {
    this.httpClientService
      .get<Vendor>('/vendor-whitelist/notification/' + this.token)
      .subscribe((vendor: Vendor) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('vendorId', vendor.id);
        if (
          vendor.vendorActivityStatus.code ===
          this.global.appConstant.vm.V_ACTIVITY_STATUS_REVISION_VENDOR_WHITELIST
        ) {
          this.global.routerParams.set('todo', 'edit');
          this.router.navigate(['/pages/vendor-whitelist/edit']);
        } else {
          this.global.routerParams.set('todo', 'view');
          this.router.navigate(['/pages/vendor-whitelist/detail']);
        }
      });
  }

  public setInitializationState(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorId = this.global.routerParams.get('vendorId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [null, Validators.required()],
      code: [null],

      startDateWhite: [null, Validators.required()],
      noteWhite: [null, Validators.required()],
      reasonWhite: [null, Validators.required()],
      whitelistDoc: [null],
      currentDate: new Date()
    });
  }

  public setFormGroup(vendorIdPopUp?: number): void {
    if (vendorIdPopUp == null) {
      this.httpClientService
        .post<VendorWhitelistResponse>(
          '/vendor-whitelist/add-edit',
          new RouteRequestModel(this.todo, this.vendorId)
        )
        .subscribe(vendorWhitelistResponse => {
          this.vendorWhitelistResponse = vendorWhitelistResponse;
          if (this.todo === 'edit') {
            this.isEdit = true;
          }

          if (this.vendorWhitelistResponse.vendor != null) {
            const { name, code } = this.vendorWhitelistResponse.vendor;
            const startDateWhite: Date = new Date(
              this.vendorWhitelistResponse.vendorWhitelist.startDate
            );
            const noteWhite = this.vendorWhitelistResponse.vendorWhitelist.note;
            const reasonWhite =
              this.vendorWhitelistResponse.vendorWhitelist.reason;

            // put doc single upload
            this.fileUploader.setFile(
              this.vendorWhitelistResponse.vendorWhitelist.file
            );

            this.formGroup.patchValue({
              name,
              code,
              startDateWhite,
              noteWhite,
              reasonWhite
            });
            if (
              this.vendorWhitelistResponse.vendor.vendorActivityStatus.code ===
              'REVISION_VENDOR_WHITELIST'
            ) {
              this.isRevision = true;
            }
            if (this.vendorWhitelistResponse.vendorWhitelist.file !== null) {
              this.formGroup.patchValue({
                whitelistDoc: this.fileUploader.fileObjectList
              });
            }
          }
          this.setStateReady();
        });
    } else {
      this.httpClientService
        .post<VendorWhitelistResponse>(
          '/vendor-whitelist/add-edit',
          new RouteRequestModel(this.todo, vendorIdPopUp)
        )
        .subscribe(vendorWhitelistResponse => {
          this.vendorWhitelistResponse = vendorWhitelistResponse; // to put in html when chosen from pop up
          this.isAdd = true;
        });
    }
  }

  public doChooseVendor(): void {
    const vendorActivityStatusCodeList: string[] = [
      this.vmConstant.V_ACTIVITY_STATUS_VENDOR_BLACKLIST
    ];

    this.appPopUpVendorService
      .open(null, vendorActivityStatusCodeList)
      .subscribe(vendor => {
        this.vendorId = vendor.id;
        this.isEdit = false;
        const id = this.vendorId;
        const name = vendor.name;
        this.vendorCode = vendor.code;
        this.formGroup.patchValue({ id, name });

        this.setFormGroup(vendor.id); // set blacklist from back to front
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            this.vendorWhitelist.startDate =
              this.formGroup.get('startDateWhite').value;
            this.vendorWhitelist.note = this.formGroup.get('noteWhite').value;
            this.vendorWhitelist.reason =
              this.formGroup.get('reasonWhite').value;
            this.vendorWhitelistRequest.fileDocList =
              this.formGroup.get('whitelistDoc').value;

            this.vendor.id = this.vendorId;
            this.vendorWhitelistRequest.vendorWhitelist = this.vendorWhitelist;
            this.vendorWhitelistRequest.vendor = this.vendor;

            const url =
              this.todo === 'edit'
                ? '/vendor-whitelist/update'
                : '/vendor-whitelist/insert';
            this.httpClientService
              .post<Response<VendorWhitelistResponse>>(
                url,
                this.vendorWhitelistRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/vendor-whitelist']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/vendor-whitelist']);
  }
}
