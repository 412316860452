import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SensitiveDataService } from 'src/app/core/services/sensitive-data.service';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { Validators } from '../../core/validators';
import { User } from './../../core/bean/user';
import { ResetEmailRequest } from './reset-email.request';

@Component({
  selector: 'app-reset-email',
  templateUrl: './reset-email.component.html',
  styles: [
    '.form-registration { min-height: 100%; background: #ffffff; display: block }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ResetEmailComponent extends BaseModuleComponent {
  public now = new Date().getTime();
  public strToken: string;
  public user: User = new User();
  public validOrInvalid = 0;

  constructor(
    translateService: TranslateService,
    public activatedRoute: ActivatedRoute,
    public sensitiveDataService: SensitiveDataService
  ) {
    super('reset-email', translateService);
  }

  onInit(): void {
    this.setToken();
    this.buildFormGroup();
    this.checkValidateToken();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      password: [null, Validators.compose([Validators.required()])]
    });
  }

  public checkValidateToken(): void {
    this.httpClientService
      .post<User>('/reset-email/confirmation', this.strToken)
      .subscribe(user => {
        this.user = user;
        if (user !== null) {
          this.formGroup.patchValue({
            id: user.id
          });
          this.validOrInvalid = 1;
          this.setStateReady();
        } else {
          this.global.routerParams.clear();
          this.global.routerParams.set(
            'paragraph',
            this.translateService.instant('reset-email.msg.linkInvalid')
          );
          this.global.routerParams.set('validOrInvalid', 2);
          this.router.navigate(['/portal/landing-page/confirmation']);
        }
      });
  }

  public doCancel(): void {
    this.router.navigate(['/portal/login/']);
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe()
        .subscribe(() => {
          this.setStateProcessing();

          const resetEmailRequest: ResetEmailRequest = new ResetEmailRequest();
          resetEmailRequest.userId = this.user.id;
          resetEmailRequest.proposeValue = this.user.proposeValue;
          resetEmailRequest.password = this.sensitiveDataService.encrypt([
            this.formGroup.get('password').value
          ]);

          this.httpClientService
            .post<Response<User>>('/reset-email/update', resetEmailRequest)
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.routerParams.clear();
                this.global.routerParams.set(
                  'title',
                  this.translateService.instant(
                    'reset-email.msg.titleSuccessChangeEmail'
                  )
                );
                this.global.routerParams.set(
                  'paragraph',
                  this.translateService.instant(
                    'reset-email.msg.paragraphSuccessChangeEmail'
                  )
                );

                this.global.routerParams.set('validOrInvalid', 1);
                this.router.navigate(['/portal/landing-page/confirmation']);
              } else {
                this.global.alertService.showError(response.statusText);
                this.setStateReady();
              }
            });
        });
    } else {
      this.global.alertService.showError(
        this.translateService.instant('reset-email.alert.passwordNotValid')
      );
      this.setStateReady();
    }
  }

  public setToken(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.strToken = params.get('token');
    });
  }
}
