import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ReminderEditAddComponent } from './reminder-edit-add.component';
import { ReminderComponent } from './reminder.component';

export const routes = [
  { path: '', component: ReminderComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: ReminderEditAddComponent,
    data: { todo: 'edit', breadcrumb: 'remider.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ReminderComponent, ReminderEditAddComponent],
  providers: []
})
export class ReminderModule {}
