import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../../../../../core/base/angular/base-component.component';
import { Catalog } from '../../../../../../core/bean/catalog';
import { AppPopupService } from '../../../../../../core/components/app-popup/app-popup.service';
import { ViewCatalogDTO } from '../../../dto/view-catalog.dto';
import { AppPopupCatalogComparisonComponent } from '../app-popup-catalog-comparison/app-popup-catalog-comparison.component';
import { TableCatalogFilterModel } from './app-table-catalog-filter/table-catalog-filter-model';
import { AppTableCatalogService } from './app-table-catalog.service';
import { TableCatalogResponseModel } from './model/table-catalog-response-model';
@Component({
  selector: 'app-table-catalog',
  templateUrl: './app-table-catalog.component.html',
  styleUrls: ['./app-table-catalog.component.scss'],
  providers: [AppTableCatalogService]
})
export class AppTableCatalogComponent extends BaseComponentComponent {
  @Input() model: TableCatalogResponseModel<any>;
  @Input() isShowFilter = true;
  @Input() header = 'view-catalog.productList';
  @Output() onChooseItem: EventEmitter<number> = new EventEmitter();
  public viewCatalogDTO: ViewCatalogDTO = new ViewCatalogDTO();
  public showOptionList: Array<number> = new Array();
  public tableCatalogFilterModel: TableCatalogFilterModel =
    new TableCatalogFilterModel(true);

  public catalogList: Catalog[] = [];

  constructor(
    public appTableCatalogService: AppTableCatalogService,
    public appPopupService: AppPopupService
  ) {
    super('app-table-catalog');
  }

  public onInit(): void {
    this.getCatalogListFromRouterParams();
    this.setShowOptionList();
    this.handleReloadChanges();
    this.getAndSetCatalogs();
  }

  public setShowOptionList(): void {
    this.showOptionList = [10, 25, 50];
  }

  public getCatalogListFromRouterParams(): void {
    if (this.global.routerParams.get('catalogList')) {
      this.catalogList = this.global.routerParams.get('catalogList');
    }
  }

  public getAndSetCatalogs(): void {
    this.model.setStateLoading();
    this.appTableCatalogService
      .getCatalogs(this.model.stringUrl, this.model.tableRequest)
      .subscribe((viewCatalogDTO: ViewCatalogDTO) => {
        this.viewCatalogDTO = viewCatalogDTO;
        this.model.setPageRecords(viewCatalogDTO.page.records);
        this.model.setTotalRecords(viewCatalogDTO.page.totalRecords);
        this.setTableCatalogFilterModel();
        this.model.setStateReady();
        this.setStateReady();
      });
  }

  public setTableCatalogFilterModel(): void {
    if (!this.tableCatalogFilterModel.isHasvalue) {
      const filterMapper: Map<string, any> = new Map();
      filterMapper.set(
        'attributeGroup',
        this.viewCatalogDTO.catalogAttributeGroup
      );
      filterMapper.set(
        'catalogAttributeGroupList',
        this.viewCatalogDTO.catalogAttributeGroupList
      );
      filterMapper.set('categoryList', this.viewCatalogDTO.categoryList);
      this.tableCatalogFilterModel.setRequestValues(filterMapper);
    }
  }

  public onInputSearch(event: any): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
    this.model.setSearchString(event.target.value);
  }

  public handleReloadChanges(): void {
    this.model.reloadChanges.subscribe(() => {
      this.getAndSetCatalogs();
    });
  }

  public doAddToComparisonList(catalog: Catalog): void {
    const checkCatalogs = this.catalogList.filter(
      thisCatalog => thisCatalog.id === catalog.id
    );
    if (!checkCatalogs || (checkCatalogs && checkCatalogs.length > 0)) {
      this.global.alertService.showError(
        'view-catalog.msg.error.cannotAddSameCatalogSameVendor'
      );
    } else {
      this.catalogList.push(catalog);
      this.global.routerParams.set('catalogList', this.catalogList);
      this.global.alertService.showSuccess(
        'view-catalog.msg.success.addToCompare'
      );
    }
  }

  public doShowPopupComparison(): void {
    const catalogList: Catalog[] = this.catalogList;
    this.appPopupService.open(
      AppPopupCatalogComparisonComponent,
      { catalogList },
      { size: 'lg', backdrop: 'static' }
    );
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
