<div class="promise-loading" *ngIf="formLoading">
  <div class="loading"></div>
</div>
<form class="form-horizontal" *ngIf="!formLoading">
  <div class="form-group row text-sm-left">
    <label class="col-sm-3">{{ 'app-sla-info.form.object' | translate }}</label>
    <div class="col-sm-4 text-left">
      {{ object ? object : '-' }}
    </div>
  </div>
  <div class="form-group row text-sm-left">
    <label class="col-sm-3">{{ 'app-sla-info.form.date' | translate }}</label>
    <div class="col-sm-4 text-left">
      {{ date | date: global.appConstant.core.FORMAT_SHORT_DATE }}
    </div>
  </div>
  <div class="form-group row text-sm-left">
    <label class="col-sm-3">{{ 'app-sla-info.form.name' | translate }}</label>
    <div class="col-sm-4 text-left">
      {{ name ? name : '-' }}
    </div>
  </div>
  <div class="form-group row text-sm-left">
    <label class="col-sm-3">{{
      'app-sla-info.form.totalSla' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{ totalSla ? totalSla : '-' }} {{ 'app-sla-info.day' | translate }}
    </div>
  </div>
  <div class="form-group row text-sm-left">
    <label class="col-sm-3">{{
      'app-sla-info.form.totalRealization' | translate
    }}</label>
    <div class="col-sm-4 text-left">
      {{ totalRealization ? totalRealization : '-' }}
    </div>
  </div>
</form>
