import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppWorklistInfoModule } from 'src/app/core/components/app-worklist-info/app-worklist-info.module';
import { SharedModule } from '../../core/shared/shared.module';
import { WorklistCMComponent } from './worklist-cm.component';

export const routes = [
  { path: '', component: WorklistCMComponent, data: { breadcrumb: '' } }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppWorklistInfoModule
  ],
  declarations: [WorklistCMComponent]
})
export class WorklistCMModule {}
