<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="reporting-sla-work-plan.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [tableResponse]="tableResponse"
        [isServerSide]="true"
        stringUrl="/reporting-sla-work-plan/index"
      >
        <ng-template #headerButtons>
          <app-button
            mode="BYPASS"
            (onClick)="doExport()"
            [disabled]="isLoading"
          >
            <em
              class="fas"
              [ngClass]="
                !isLoading ? 'fa-cloud-download' : 'fa-circle-notch fa-spin'
              "
            >
            </em
            >{{ 'app.button.export' | translate }}
          </app-button>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button
            mode="BYPASS"
            size="TN"
            class="mr-2"
            (onClick)="doDetail(rowData)"
          >
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
