import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppDragAndDropComponent } from './app-drag-and-drop.component';

@NgModule({
  imports: [VendorModule, DragDropModule],
  declarations: [AppDragAndDropComponent],
  exports: [AppDragAndDropComponent, DragDropModule]
})
export class AppDragAndDropModule {}
