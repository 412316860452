import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorEntityEditAddComponent } from './vendor-entity-edit-add.component';
import { VendorEntityComponent } from './vendor-entity.component';
export const routes = [
  {
    path: '',
    component: VendorEntityComponent,
    data: { breadcrumb: 'Vendor Management' }
  },
  {
    path: 'add',
    component: VendorEntityEditAddComponent,
    data: { breadcrumb: 'vendor-entity.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: VendorEntityEditAddComponent,
    data: { breadcrumb: 'vendor-entity.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [VendorEntityComponent, VendorEntityEditAddComponent],
  providers: []
})
export class VendorEntityModule {}
