<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="user-manual.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-table [model]="tableResponse" [isServerSide]="true"
        stringUrl="/user-manual/index">
        <ng-template #actionButtons let-rowData>
          <app-button size="TN" (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}">
            <em class="fas fa-pencil-alt"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>