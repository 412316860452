import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetLoginInformationComponent } from './app-widget-login-information.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule],
  declarations: [AppWidgetLoginInformationComponent],
  entryComponents: [AppWidgetLoginInformationComponent],
  exports: [AppWidgetLoginInformationComponent]
})
export class AppWidgetLoginInformationModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-login-information', AppWidgetLoginInformationComponent);
  }
}
