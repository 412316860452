import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BudgetAllocation } from 'src/app/core/bean/budget-allocation';
import { CatalogBulkPrice } from 'src/app/core/bean/catalog-bulk-price';
import { CatalogContract } from 'src/app/core/bean/catalog-contract';
import { PrItemBudget } from 'src/app/core/bean/pr-item-budget';
import { BaseModuleComponent } from '../../../base/angular/base-module.component';
import { CatalogSpecification } from '../../../bean/catalog-specification';
import { File } from '../../../bean/file';
import { PrItem } from '../../../bean/pr-item';
import { CatalogView } from '../../../view/entity/bean/catalog-view';
import { TablePluginData } from '../../table/interface/table-plugin-data';
import { TableResponseModel } from '../../table/model/table-response-model';
import { AppPopupPrItemInfoResponse } from './app-popup-pr-item-info.response';

@Component({
  templateUrl: './app-popup-pr-item-info.component.html',
  styleUrls: ['./app-popup-pr-item-info.component.scss']
})
export class AppPopupPrItemInfoComponent extends BaseModuleComponent {
  @Input() prItemId: number;
  @Input() isShowBudget: boolean;
  @Input() priceRealization: number;
  @Input() totalPriceRealization: number;
  @Output() onChange: EventEmitter<BudgetAllocation[]> = new EventEmitter();

  public tableResponse: TableResponseModel<PrItemBudget>;
  public errorMapper: Map<number, string> = new Map();
  public prItem: PrItem = new PrItem();
  public catalogView: CatalogView = new CatalogView();
  public fileList: File[] = [];
  public catalogSpecificationList: CatalogSpecification[] = [];
  public moduleCode = 'app-popup-pr-item-info';
  public currencyCode: string;
  public totalBudget = 0;
  public prItemBudgetList: PrItemBudget[];
  public catalogContract: CatalogContract;
  public catalogBulkPriceList: CatalogBulkPrice[] = [];

  constructor(
    translateService: TranslateService,
    public currencyPipe: CurrencyPipe
  ) {
    super('app-popup-pr-item-info', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.getDataFromServer();
  }

  public getDataFromServer(): void {
    this.httpClientService
      .get<AppPopupPrItemInfoResponse>(
        '/app-popup-pr-item-info/index/' + this.prItemId
      )
      .subscribe((appPopupPrItemInfoResponse: AppPopupPrItemInfoResponse) => {
        this.prItem = appPopupPrItemInfoResponse.prItem;
        this.fileList = appPopupPrItemInfoResponse.fileList;
        this.catalogView = appPopupPrItemInfoResponse.catalogView;
        this.formGroup.patchValue({
          specification: this.prItem.specification
        });
        this.formGroup.get('specification').setIsView(true);
        this.catalogSpecificationList =
          appPopupPrItemInfoResponse.catalogSpecificationList;
        this.prItemBudgetList = appPopupPrItemInfoResponse.prItemBudgetList;

        if (this.totalPriceRealization) {
          this.totalBudget = this.totalPriceRealization;
        } else {
          appPopupPrItemInfoResponse.prItemBudgetList.forEach(prItemBudget => {
            this.totalBudget += prItemBudget.bookedAmount;
          });
        }

        this.currencyCode = appPopupPrItemInfoResponse.currencyCode;
        this.catalogContract = appPopupPrItemInfoResponse.catalogContract;
        this.catalogBulkPriceList =
          appPopupPrItemInfoResponse.catalogBulkPriceList;
        this.buildTableResponse();
        this.setStateReady();
      });
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      specification: [null]
    });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'budgetAllocation.organization.name',
        header: 'table.column.organization'
      },
      {
        field: 'budgetAllocation.costCenter.name',
        header: 'table.column.costCenter'
      },
      {
        field: 'budgetAllocation.coa.code',
        header: 'table.column.coa',
        className: 'text-right'
      },
      {
        field: 'budgetAllocation.budgetType.name',
        header: 'table.column.budgetType'
      },
      {
        field: 'budgetAllocation.availableBudget',
        header: 'table.column.availableBudget',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      this.priceRealization
        ? {
            field: 'amount',
            header: 'table.column.budgetValue',
            className: 'text-right',
            plugins: {
              name: 'custom-plugin',
              before: (tablePluginData: TablePluginData): string => {
                const budgetValue =
                  (tablePluginData.value * this.totalPriceRealization) /
                  this.prItem.totalPrice;

                return (
                  this.global.converterService.convertMoney(budgetValue) +
                  ' ' +
                  this.currencyCode
                );
              }
            }
          }
        : {
            field: 'bookedAmount',
            header: 'table.column.budgetValue',
            plugins: {
              name: 'default',
              type: 'currency'
            },
            className: 'text-right'
          }
    ]);
    this.tableResponse.setRecordList(this.prItemBudgetList);
    this.tableResponse.reload();
  }

  public doParseValue(catalogSpesification: CatalogSpecification): string {
    let value = catalogSpesification.value;
    const parsedValue: any = JSON.parse(value);
    if (parsedValue) {
      if (Array.isArray(parsedValue)) {
        value = '';
        parsedValue.forEach((valueObject, i) => {
          value += valueObject.name;
          if (i !== parsedValue.length - 1) {
            value += ', ';
          }
        });
      } else if (typeof parsedValue === 'string') {
        value = parsedValue;
      } else if (
        catalogSpesification.catalogAttribute.formObject.code === 'FIELD-PRICE'
      ) {
        const price = this.currencyPipe.transform(
          parsedValue.price,
          '',
          '',
          '1.2-2'
        );
        value = price + ' ' + parsedValue.currency.code;
      } else {
        value = parsedValue.name;
      }
    } else {
      value = '-';
    }

    return value;
  }

  public doConvertToDate(value: string): Date {
    const parsedValue = JSON.parse(value);
    return new Date(parsedValue);
  }
}
