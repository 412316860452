<app-layout [isLoading]="formLoading">
  <app-table-xx
    header="compliance-test.table.header.procurementList"
    [model]="tableResponse"
    stringUrl="/compliance-test/index"
    isShowButtonModifyTable="true"
    (onClick)="doClickProcurement($event)"
  >
    <ng-template #headerFilterGroup>
      <form
        class="form-horizontal pt-4"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <div class="form-group row text-sm-left">
          <label class="col-sm-1" style="margin-top: auto">{{
            'compliance-test.form.date' | translate
          }}</label>
          <div class="col-sm-8 text-left" style="display: flex">
            <app-date-picker-x
              size="XL"
              formControlName="date"
              [range]="true"
              (onChange)="onChangeFilter()"
              [ngStyle]="{
                'margin-right': '7px',
                width: 'inherit'
              }"
            >
            </app-date-picker-x>
            <app-dropdown-select
              *ngIf="!formLoading"
              size="XL"
              formControlName="organizationList"
              (onChange)="onChangeFilter()"
              placeholder="{{
                'compliance-test.placeholder.department' | translate
              }}"
              [optionList]="organizationOptionList"
              type="CHOSEN"
              [ngStyle]="{
                'margin-right': '7px',
                width: 'inherit'
              }"
              showLimitValue="1"
            >
              <ng-template #selectAll>
                <p>
                  {{ 'compliance-test.placeholder.allDepartment' | translate }}
                </p>
              </ng-template></app-dropdown-select
            >
            <app-dropdown-select
              *ngIf="!formLoading"
              size="XL"
              formControlName="complianceTestStatusList"
              placeholder="{{
                'compliance-test.placeholder.status' | translate
              }}"
              [optionList]="complianceTestStatusOptionList"
              (onChange)="onChangeFilter()"
              type="CHOSEN"
              [ngStyle]="{
                width: 'inherit'
              }"
              showLimitValue="1"
            >
              <ng-template #selectAll>
                <p>
                  {{ 'compliance-test.placeholder.allStatus' | translate }}
                </p>
              </ng-template></app-dropdown-select
            >
          </div>
        </div>
      </form>
    </ng-template>
  </app-table-xx>
</app-layout>
