import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Condition } from '../../core/bean/condition';
import { ModuleGroup } from '../../core/bean/module-group';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './threshold.component.html'
})
export class ThresholdComponent extends BaseModuleComponent {
  public moduleGroupList: ModuleGroup[];
  public tableResponse: TableResponseModel<Condition>;
  constructor(translateService: TranslateService) {
    super('threshold', translateService);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.doSetModuleGroupList();
    this.setStateReady();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      moduleGroupId: [null]
    });
  }

  public doSetModuleGroupList(): void {
    this.httpClientService.get<ModuleGroup[]>('/threshold/module-group-list', {}).subscribe(moduleGroupList => {
      this.moduleGroupList = moduleGroupList;
    });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.condition' },
      { field: 'moduleGroup.name', header: 'table.column.module', customClass: 'text-center' },
      { field: 'thresholdRangeText', header: 'table.column.range', format: FieldFormatEnum.Html }
    ]);
  }

  public doEdit(condition: Condition): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('condition', condition);
    this.router.navigate(['/pages/threshold/edit']);
  }

  public doOnChangeModuleGroup(): void {
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doPrint(): void {
  }
}
