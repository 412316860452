<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="wanprestasi.title"
      detail="app.action.{{ todo === 'view' ? 'detail' : todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <h5 class="separator">Data Vendor</h5>
        <div
          class="form-group row text-sm-right"
          [ngClass]="!formGroup.isView ? 'required' : ''"
        >
          <label class="col-sm-3 control-label">{{
            'wanprestasi.form.name' | translate
          }}</label>
          <div class="col-sm-8 text-left">
            <div *ngIf="todo !== 'view'">
              <app-choose
                formControlName="name"
                (onClick)="doChooseVendor()"
              ></app-choose>
            </div>
            <div *ngIf="todo === 'view'">{{ wanprestasiResponse.vendor.name }}</div>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'wanprestasi.form.code' | translate
          }}</label>
          <div class="col-sm-8 text-left">
            <p *ngIf="vendorCode !== null">{{ vendorCode }}</p>
            <p *ngIf="vendorCode === null"> - </p>
          </div>
        </div>
        <br />
        <br />

        <h5 class="separator">{{
          'wanprestasi.form.procurement.activity' | translate
        }}</h5>
        <app-table
          #selectorProcurement
          [model]="tableResponseProc"
          [isServerSide]="false"
        >
          <ng-template #headerButtons>
            <app-button
              *ngIf="todo === 'add'"
              (onClick)="doAddProcurementActivity()"
            >
              <em class="fas fa-plus"></em>{{ 'app.button.add' | translate }}
            </app-button>
          </ng-template>
          <ng-template #actionButtons let-rowData>
            <app-button
              *ngIf="rowData.id == null"
              size="TN"
              (onClick)="doEditProcurementActivity(rowData)"
              title="{{ 'app.tooltip.edit' | translate }}"
            >
              <em class="fas fa-pencil-alt"></em>
            </app-button>
            <app-button
              *ngIf="rowData.id == null"
              size="TN"
              color="DANGER"
              (onClick)="doDeleteProcurementActivity(rowData)"
              title="{{ 'app.tooltip.delete' | translate }}"
            >
              <em class="fa fas fa-trash"></em>
            </app-button>
            <app-button
              *ngIf="rowData.id != null"
              size="TN"
              (onClick)="doViewProcurementActivity(rowData)"
              title="{{ 'app.tooltip.view' | translate }}"
            >
              <em class="fas fa-search"></em>
            </app-button>
          </ng-template>
        </app-table>

        <br />
        <br />
        <h5 class="separator">{{
          'wanprestasi.form.contract.activity' | translate
        }}</h5>
        <app-table
          #selectorContract
          [model]="tableResponseContract"
          [isServerSide]="false"
        >
          <ng-template #headerButtons>
            <app-button
              *ngIf="todo === 'add'"
              (onClick)="doAddContractActivity()"
            >
              <em class="fas fa-plus"></em>{{ 'app.button.add' | translate }}
            </app-button>
          </ng-template>
          <ng-template #actionButtons let-rowData>
            <app-button
              *ngIf="rowData.id == null"
              size="TN"
              (onClick)="doEditContractActivity(rowData)"
              title="{{ 'app.tooltip.edit' | translate }}"
            >
              <em class="fas fa-pencil-alt"></em>
            </app-button>
            <app-button
              *ngIf="rowData.id == null"
              size="TN"
              color="DANGER"
              (onClick)="doDeleteContractActivity(rowData)"
              title="{{ 'app.tooltip.delete' | translate }}"
            >
              <em class="fa fas fa-trash"></em>
            </app-button>
            <app-button
              *ngIf="rowData.id != null"
              size="TN"
              (onClick)="doViewContractActivity(rowData)"
              title="{{ 'app.tooltip.view' | translate }}"
            >
              <em class="fas fa-search"></em>
            </app-button>
          </ng-template>
        </app-table>

        <div class="button-group button-group-center">
          <app-button
            mode="BYPASS"
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
          <app-button
            *ngIf="todo === 'add'"
            [disabled]="formSaving"
            (onClick)="doSave()"
          >
            {{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
