import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { WorkflowPrcs } from '../../bean/workflow-prcs';
import {
  ApprovalPathModel,
  ApprovalPathResponseModel
} from '../app-approval-path-x';

@Component({
  selector: 'app-approval-monitoring',
  templateUrl: './app-approval-monitoring.component.html'
})
export class AppApprovalMonitoringComponent extends BaseComponentComponent {
  @Input() workflowPrcs: WorkflowPrcs;
  @Input() moduleCode: string;
  @Input() approvalPathResponseModel: ApprovalPathResponseModel;

  public approvalPath: ApprovalPathModel<object> = new ApprovalPathModel(
    this.moduleCode,
    'READ'
  );

  constructor() {
    super('app-approval-monitoring');
  }

  public onInit(): void {
    this.approvalPath
      .setApproverList(this.approvalPathResponseModel.userApproverList)
      .setType(this.approvalPathResponseModel.type);
  }
}
