import { Procurement } from './../bean/procurement';
import { BaseEntity } from '../base/base-entity';
import { PurchaseRequest } from '../bean/purchase-request';
import { WorkPlanItem } from '../bean/work-plan-item';


export class PurchaseRequestItemEntity extends BaseEntity {
    purchaseRequest: PurchaseRequest;
    workPlanItem: WorkPlanItem;
    procurement: Procurement;
    quantity: number;
    unitPrice: number;
    totalPrice: number;
    unitPriceRealize: number;
    totalPriceRealize: number;
    description: string;
    vendor: string;
}
