<app-layout
  [isLoading]="formLoading"
  header="vendor-designation.breadcrumb.index"
  [backTo]="urlBackOutside"
>
  <ng-template #additionalTitle *ngIf="isShowBadgeStatus">
    <app-badge-catalog
      class="cml-2"
      *ngIf="!formLoading && procurement?.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge
      class="cml-2"
      *ngIf="todo === 'add' && !formLoading"
      color="INFO"
    >
      {{ 'vendor-designation.label.new' | translate }}
    </app-badge>
    <app-badge
      *ngIf="todo !== 'add' && !formLoading"
      class="cml-2"
      [color]="badgeColor[procurement?.procurementWorklistStatus?.code]"
    >
      {{ getTranslateKey(procurement.procurementWorklistStatus) | translate }}
    </app-badge>
  </ng-template>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          (procurement?.procurementStatus?.code ===
            global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm
                .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT)
        "
      >
        {{ canceledOrFailedMessage | translate }}
      </app-alert-x>
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="vendorDesignationResponse.workflowStepInfoModel"
        >
        </app-workflow-step-info>
        <app-card
          header="vendor-designation.detail.general.title"
          tips="vendor-designation.detail.general.tips"
        >
          <app-procurement-info [procurementId]="procurementId">
          </app-procurement-info>
        </app-card>

        <app-item-request
          *ngIf="!formLoading"
          [objectList]="vendorDesignationResponse.procurementItemList"
          [isShowPrice]="true"
          [isView]="true"
          [itemTypeList]="vendorDesignationResponse.itemTypeList"
          (onChange)="onChangeItemRequest($event)"
        ></app-item-request>

        <app-card
          *ngIf="
            !formLoading &&
            procurement.vendorSelectionType.code ===
              global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
          "
        >
          <app-alert-x color="DANGER" *ngIf="isMustFullFill">{{
            'vendor-designation.detail.alertInfo.mustBeFullfilled' | translate
          }}</app-alert-x>
          <app-alert-x
            color="WARNING"
            *ngIf="!isMustFullFill && isThingsToConsider"
            >{{
              'vendor-designation.detail.alertInfo.thingsToConsider' | translate
            }}</app-alert-x
          >
          <app-table-xx
            header="vendor-designation.detail.winningVendor.title"
            tips="vendor-designation.detail.winningVendor.tips"
            [model]="tableResponseFinalEval"
            *ngIf="!formLoading"
          >
            <ng-template #headerButtons>
              <app-button
                (onClick)="doChangeWinningVendor()"
                *ngIf="todo !== 'view'"
              >
                {{
                  'vendor-designation.button.changeWinningVendor' | translate
                }}
              </app-button>
            </ng-template>
          </app-table-xx>
        </app-card>

        <app-card
          *ngIf="
            !formLoading &&
            procurement.vendorSelectionType.code ===
              global.appConstant.pm.VENDOR_SELECTION_TYPE_MULTI_WINNER
          "
          header="vendor-designation.detail.winningVendor.title"
          tips="vendor-designation.detail.winningVendor.tips"
        >
          <app-tab [bordered]="true" *ngIf="!formLoading">
            <app-tab-item
              header="{{ 'vendor-designation.tab.allocatedItem' | translate }}"
            >
              <app-table-xx
                [model]="tableResponseAllocatedItem"
                *ngIf="!formLoading"
                (onClick)="doChooseQuotationVendor($event)"
              ></app-table-xx>
            </app-tab-item>
            <app-tab-item
              header="{{ 'vendor-designation.tab.selectedVendor' | translate }}"
            >
              <app-alert-x color="DANGER" *ngIf="isMustFullFill">{{
                'vendor-designation.detail.alertInfo.mustBeFullfilled'
                  | translate
              }}</app-alert-x>
              <app-alert-x
                color="WARNING"
                *ngIf="!isMustFullFill && isThingsToConsider"
                >{{
                  'vendor-designation.detail.alertInfo.thingsToConsider'
                    | translate
                }}</app-alert-x
              >
              <app-table-xx
                [model]="tableResponseSelectedVendor"
                *ngIf="!formLoading"
                (onClick)="doShowItem($event)"
              ></app-table-xx>
            </app-tab-item>
          </app-tab>
        </app-card>

        <app-agreement-type
          *ngIf="(todo === 'add' || agreementTypeParam != null) && 
            (procurement?.initiationType?.code !== 'BLANKET_ORDER')"
          [component]="this.global.config.parameterModel.vendorDesignationNextProcess"
          (onChange)="onChangeAgreementType($event)"
          [agreementTypeParam]="agreementTypeParam"
          [isAgreementTypeNull]="isAgreementTypeNull"
          [isShowInputControl]="isShowInputControl"
        ></app-agreement-type>
      
        <app-card
          header="vendor-designation.detail.officialReport.title"
          tips="vendor-designation.detail.officialReport.tips"
        >
          <app-alert-x color="WARNING" *ngIf="isOfficialReportNull">{{
            'vendor-designation.detail.officialReport.alertInfo' | translate
          }}</app-alert-x>
          <app-alert-x
            color="WARNING"
            *ngIf="
              isOfficialReportNull === false &&
              !appOfficialReportModel.isGenerate
            "
            >{{
              'vendor-designation.detail.officialReport.regenerate' | translate
            }}</app-alert-x
          >
          <app-official-report
            [objectId]="procurementId"
            [templateCode]="
              procurement.vendorSelectionType.code ===
              global.appConstant.pm.VENDOR_SELECTION_TYPE_SINGLE_WINNER
                ? global.appConstant.pm
                    .OFFICIAL_REPORT_CODE_ORT_VENDOR_DESIGNATION_SINGLE_WINNER_LETTER
                : global.appConstant.pm
                    .OFFICIAL_REPORT_CODE_ORT_VENDOR_DESIGNATION_MULTI_WINNER_LETTER
            "
            [appOfficialReportModel]="appOfficialReportModel"
            [mode]="todo"
            numberGeneratorCode="NUMBER"
            (onChange)="doOnChange($event)"
            [isShowButtonGenerate]="todo !== 'view'"
          ></app-official-report>
          </app-card>

        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            vendorDesignationResponse.approvalPathResponseModel &&
            !vendorDesignationResponse.isUserApproval &&
            (procurement.workflowModelPrcs.code !== 'VENDOR-DESIGNATION' ||
              (procurement.workflowModelPrcs.code === 'VENDOR-DESIGNATION' &&
                procurement.workflowPrcs?.workflowStatus?.code ===
                  global.appConstant.core.WORKFLOW_CODE_STATUS_REVISION))
          "
          [workflowPrcs]="procurement.workflowPrcs"
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="
            vendorDesignationResponse?.approvalPathResponseModel
          "
        ></app-approval-monitoring>

        <div class="floating-button mt-5" *ngIf="!formGroup.isView">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div
                class="button-group button-group-center"
                *ngIf="!formGroup.isView"
              >
                <app-button
                  color="SECONDARY"
                  [outline]="true"
                  (onClick)="doSubmit(false)"
                  >{{ 'app.button.save' | translate }}
                </app-button>
                <app-button (onClick)="doSubmit(true)">{{
                  'app.button.submit' | translate
                }}</app-button>
              </div>
            </div>
          </div>
        </div>
        <app-approval-prcs-x
          *ngIf="!formLoading && vendorDesignationResponse.isUserApproval"
          [workflowModelPrcs]="procurement.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips *ngIf="!formLoading" #appTips></app-tips>
  </ng-template>
</app-layout>
