import { NgModule } from '@angular/core';
import { AppChartModule } from '../../../../../core/components/app-chart/app-chart.module';
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetTotalJoinedProcurementComponent } from './widget-total-joined-procurement.component';

@NgModule({
  imports: [VendorModule, WidgetCardModule, AppChartModule],
  declarations: [WidgetTotalJoinedProcurementComponent],
  exports: [WidgetTotalJoinedProcurementComponent]
})
export class WidgetTotalJoinedProcurementModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetTotalJoinedProcurementComponent.moduleCode,
      WidgetTotalJoinedProcurementComponent,
      'w-50'
    );
  }
}
