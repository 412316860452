<app-popup [header]="header" [isLoading]="formLoading">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-right" [ngClass]="!formGroup.isView ? 'required' : ''">
      <label class="col-sm-3 control-label">{{
        'delivery-monitoring.form.file' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        <app-upload size="LG" formControlName="contractFile" [fileUploader]="fileUploader"
          tooltip="{{ 'delivery-monitoring.tootlip.file' | translate }}">
        </app-upload>
      </div>
    </div>
    <div class="form-group row text-sm-right">
      <label class="col-sm-3 control-label">{{
        'delivery-monitoring.form.description' | translate
        }}</label>
      <div class="col-sm-9 text-left">
        <app-text-area size="LG" formControlName="description"> </app-text-area>
      </div>
    </div>
  </form>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
        'app.button.close' | translate
        }}</app-button>
      <app-button color="PRIMARY" (onClick)="doSave()">{{ 'app.button.save' | translate }}
      </app-button>
    </div>
  </ng-template>
</app-popup>