<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <div class="app-accordion-row">
      <app-workflow-progress-info
        *ngIf="
          !formLoading &&
          (!projectTeamSubmissionResponse.isUserApproval ||
            projectTeamSubmissionResponse.isUserApproval === null)
        "
        [workPlan]="workPlan"
        [currentPosition]="moduleCode"
      >
      </app-workflow-progress-info>
      <app-card [isLoading]="formLoading" [isSaving]="formSaving">
        <app-work-plan-info [workPlanId]="workPlanId"> </app-work-plan-info>
        <app-accordion [isExpandAll]="true" [isLoading]="formLoading">
          <app-accordion-item
            header="project-team-submission.projectTeamSubmission"
          >
            <form
              *ngIf="isReady"
              class="form-horizontal"
              [formGroup]="formGroup"
              novalidate
            >
              <app-team-project
                [teamProjectFormList]="teamProjectFormList"
                [isDisabled]="isDisabled"
              >
              </app-team-project>
              <div class="button-group button-group-center mt-5">
                <app-button
                  color="SECONDARY"
                  (onClick)="doBack()"
                  [disabled]="formSaving"
                  *ngIf="!projectTeamSubmissionResponse.isUserApproval"
                >
                  {{ 'app.button.back' | translate }}
                </app-button>
                <app-button
                  color="PRIMARY"
                  (onClick)="doSave()"
                  [disabled]="formSaving"
                  *ngIf="!formGroup.isView"
                >
                  {{ 'app.button.save' | translate }}
                </app-button>
              </div>
            </form>
          </app-accordion-item>
          <app-accordion-item
            header="project-team-submission.monitoringApproval"
            [isHidden]="
              !(
                !formLoading &&
                projectTeamSubmissionResponse.approvalPathResponseModel !==
                  null &&
                !projectTeamSubmissionResponse.isUserApproval &&
                todo !== 'edit' &&
                todo !== 'add'
              )
            "
          >
            <app-approval-path [model]="approvalPath">
              <ng-template #customText let-userApprover>
                <span class="d-block" [ngStyle]="{ color: '#343434' }">{{
                  userApprover.user?.name || '-'
                }}</span>
                <span
                  class="d-block"
                  [ngStyle]="{ color: '#343434' }"
                  *ngIf="userApprover.delegateUser"
                  >({{ userApprover.delegateUser?.name }})</span
                >
                <span class="d-block" [ngStyle]="{ color: '#343434' }">{{
                  userApprover.position?.name || '-'
                }}</span>
                <span class="d-block" [ngStyle]="{ color: '#343434' }">{{
                  userApprover.organization?.name || '-'
                }}</span>
              </ng-template>
            </app-approval-path>
          </app-accordion-item>
          <app-accordion-item
            header="project-team-submission.logApproval"
            [isHidden]="
              !(
                !formLoading &&
                projectTeamSubmissionResponse.approvalPathResponseModel !==
                  null &&
                !projectTeamSubmissionResponse.isUserApproval &&
                todo !== 'add'
              )
            "
          >
            <app-approval-log
              [moduleCode]="moduleCode"
              [workflowPrcs]="workflowPrcs"
            >
            </app-approval-log>
          </app-accordion-item>
        </app-accordion>
      </app-card>
    </div>
    <app-approval-prcs
      *ngIf="projectTeamSubmissionResponse.isUserApproval"
      [workflowModelPrcs]="workflowModelPrcs"
    >
    </app-approval-prcs>
  </div>
</div>
