import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { OptionListModel } from '../../../model/option-list-model';
import { TableFieldModel } from '../../app-table-x/model/table-field-model';
import { TableResponseModel } from '../../app-table-x/model/table-response-model';
@Component({
  templateUrl: './app-popup-edit-table.component.html',
  styleUrls: ['./app-popup-edit-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupEditTableComponent extends BaseComponentComponent {
  @Input() tableParentResponse: TableResponseModel<any>;
  @Input() columnFieldList: Array<any>;
  @Output() onChange: EventEmitter<Array<string>> = new EventEmitter();
  @Output() onClose: EventEmitter<void> = new EventEmitter();
  public optionList: OptionListModel<{
    id: number;
    code: string;
    field: string;
    isChecked: boolean;
  }> = new OptionListModel(false, 'code');

  constructor(public translateService: TranslateService) {
    super('app-popup-edit-table');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setOptionListState();
    this.setStateTableColumn();
  }

  private buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      column: [null]
    });
  }

  public setOptionListState(): void {
    const columnList = Array.from(this.tableParentResponse.columns);
    const isCheckedAll = this.columnFieldList.length === 0;
    const optionList: any = columnList.map(
      (column: TableFieldModel, index: number) => {
        return {
          id: index + 1,
          code: this.tableParentResponse.moduleCode + '.' + column.header,
          field: column.field,
          isChecked:
            isCheckedAll ||
            this.columnFieldList.findIndex(field => field === column.field) !==
              -1
        };
      }
    );
    this.optionList.setRequestValues(optionList);
  }

  private setStateTableColumn(): void {
    this.formGroup
      .get('column')
      .patchValue(
        this.optionList.requestValues.filter(option => option.isChecked)
      );
  }

  public doSave(): void {
    this.onChange.emit(
      this.formGroup.get('column').value.map(column => column.field)
    );
  }
}
