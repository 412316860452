import { BaseEntity } from '../base/base-entity';
import { TranslationKey } from '../bean/translation-key';
import { VendorAttributeGroup } from '../bean/vendor-attribute-group';
import { ModuleEntity } from './module.entity';

export class VendorTabEntity extends BaseEntity {
  nameTranslated: string;
  vendorAttributeGroupList: VendorAttributeGroup[];

  translationKey: TranslationKey;
  description: string;
  routeUrl: string;
  module: ModuleEntity;
}
