<div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-card *ngIf="isSyncRunning">
            <div id="billing-memo-sync-info" class="alert alert-warning"  role="alert" *ngIf="(websocketBillingMemoService.message | async)">
              <strong class="mr-2"><em class="fa fa-info-circle mr-2"></em>{{ 'billing-memo.sync.info' | translate }}</strong>
              {{ (websocketBillingMemoService.message | async) }}
            </div>
      </app-card>
      <app-card
        header="billing-memo.title"
        [isLoading]="formLoading"
        [isSaving]="formSaving"
        *ngIf="!isSyncRunning && !formLoading"
      >
        <app-fieldset legend="Filter">
          <form
            class="form-horizontal"
            [formGroup]="formGroup"
            novalidate
            *ngIf="!formLoading"
          >
          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'billing-memo.form.invoiceNumber' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-chosen
                formControlName="invoiceNumberList"
                [optionList]="paymentInvoiceOptionList"
              ></app-chosen>
            </div>
          </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.requestFrom' | translate
              }}</label>
              <div class="col-sm-9 text-left" style="display: flex">
                <app-date-picker
                  size="XL"
                  formControlName="startDate"
                  [ngStyle]="{
                    'margin-right': !formGroup.isView ? '0px' : '30px'
                  }"
                >
                </app-date-picker>
                <p class="ml-3 mr-3 control-label">{{
                  'billing-memo.form.until' | translate
                }}</p>
                <app-date-picker
                  size="XL"
                  formControlName="endDate"
                  minDate="startDate"
                ></app-date-picker>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.agreementNumber' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-chosen
                  formControlName="agreementList"
                  [optionList]="agreementOptionList"
                  tooltip="billing-memo.tooltip.agreementNumber"
                ></app-chosen>
              </div>
            </div>

            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.procurementName' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-chosen
                  formControlName="procurementList"
                  [optionList]="procurementOptionList"
                ></app-chosen>
              </div>
            </div>

            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.vendorName' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-chosen
                  formControlName="vendorNameList"
                  [optionList]="vendorOptionList"
                ></app-chosen>
              </div>
            </div>

            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.status' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-chosen
                  formControlName="statusList"
                  [optionList]="paymentStatusOptionList"
                ></app-chosen>
              </div>
            </div>

            <div class="button-group button-group-center mt-5">
              <app-button mode="BYPASS" color="SECONDARY" (onClick)="doReset()">
                <em class="fas fa-sync-alt"></em>
                {{ 'app.button.reset' | translate }}
              </app-button>
              <app-button
                mode="BYPASS"
                color="PRIMARY"
                (onClick)="doApply()"
                [disabled]="formSaving"
                ><em class="fas fa-check"></em>
                {{ 'app.button.apply' | translate }}
              </app-button>
            </div>
          </form>
        </app-fieldset>
        <app-table
          [model]="tableResponse"
          [isServerSide]="true"
          stringUrl="/billing-memo/index"
        >
          <ng-template #headerButtons>
            <app-button *ngIf="isAllowAccess" (onClick)="doSynchronize()"       [disabled]="formLoading || isLoadingSync 
                || (websocketBillingMemoService.message | async)">
              <em class="fas fa-sync-alt"></em
              >{{ 'billing-memo.button.synchronize' | translate }}
            </app-button>
          </ng-template>
          <ng-template #actionButtons let-rowData>
            <app-button
              size="TN"
              (onClick)="doEdit(rowData)"
              title="{{ 'app.tooltip.edit' | translate }}"
              *ngIf="rowData.paymentStatus.code !== global.appConstant.cm.PAYMENT_STATUS_REVISION_BILLING_MEMO"
            >
              <em class="fas fa-pencil-alt"></em>
            </app-button>
            <app-button
              size="TN"
              (onClick)="doView(rowData)"
              title="{{ 'app.tooltip.detail' | translate }}"
              *ngIf="rowData.paymentStatus.code === global.appConstant.cm.PAYMENT_STATUS_REVISION_BILLING_MEMO"
            >
              <em class="fas fa-search"></em>
            </app-button>
          </ng-template>
        </app-table>
      </app-card>
    </div>
  </div>
  