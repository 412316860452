import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'readMore'
})
export class ReadMorePipe implements PipeTransform {
  transform(content: string, maxLength = 150): string {
    const element = document.createElement('div');
    element.innerHTML = content;
    const textContentLength = element.textContent.slice(0, maxLength).split(' ')
      .length;
    return element.textContent.split(' ').slice(0, textContentLength).join(' ');
  }
}
