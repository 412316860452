import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { ClaimUnclaimEditAddComponent } from './claim-unclaim-edit-add.component';
import { ClaimUnclaimComponent } from './claim-unclaim.component';
const routes: Routes = [
  { path: '', component: ClaimUnclaimComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: ClaimUnclaimEditAddComponent,
    data: { breadcrumb: 'claim-unclaim.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: ClaimUnclaimEditAddComponent,
    data: { breadcrumb: 'claim-unclaim.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [ClaimUnclaimComponent, ClaimUnclaimEditAddComponent]
})
export class ClaimUnclaimModule {}
