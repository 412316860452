<div class="outer-wrapper">
  <aside
    class="sidebar fixed left"
    id="sidebarLeft"
    (mouseenter)="onMouseEnterOnSidebar()"
    (mouseleave)="onMouseLeaveFromSidebar()"
  >
    <div class="sidebar-wrapper">
      <div class="sidebar-header">
        <div class="logo">
          <div class="big-logo" >
            <img  *ngIf="this.global.mainLogoFileName != null"      
            [attr.src]="
              global.config.BACKEND_ADDRESS +
              '/' +
              'portal/file/view/' +
              this.global.mainLogoFileName +
              '/IMG_MAIN_LOGO'
              | resolveImgSrc
              | async
              "
              alt="logo"
              width="auto"
              height="auto"
            />
          </div>
          <div class="small-logo">
            <img *ngIf="this.global.smallLogoFileName != null"                   
            [attr.src]="
              global.config.BACKEND_ADDRESS +
              '/' +
              'portal/file/view/' +
              this.global.smallLogoFileName +
              '/IMG_SMALL_LOGO'
              | resolveImgSrc
              | async
              "
              alt="logo"
              width="auto"
              height="auto"
            />
          </div>
        </div>
      </div>
      <theme-sidebar position="left"></theme-sidebar>
    </div>
  </aside>
  <div class="main-outer">
    <theme-header></theme-header>
    <div class="main-wrapper">
      <ng-container
        *ngTemplateOutlet="isOffline ? offlineTemplate : onlineTemplate"
      ></ng-container>

      <ng-template #offlineTemplate>
        <div class="offline-wrapper">
          <div
            class="pvc pv-sattelite-antenna"
            style="width: 300px; height: 300px"
          ></div>
          <h2 class="lpt-2">Connection Lost</h2>
          <h5 class="fw-400">Please check your internet connection</h5>
        </div>
      </ng-template>

      <ng-template #onlineTemplate>
        <section class="main lpx-3">
          <theme-breadcrumb></theme-breadcrumb>
          <ng-content></ng-content>
        </section>
      </ng-template>
    </div>
  </div>
</div>
