import { BaseEntity } from '../base/base-entity';
import { Education } from '../bean/education';
import { File } from '../bean/file';
import { Profession } from '../bean/profession';
import { VendorExpertiseCertificate } from '../bean/vendor-expertise-certificate';
import { VendorExpertiseCertificateHistory } from '../bean/vendor-expertise-certificate-history';
import { VendorHistory } from '../bean/vendor-history';
import { FileObject } from '../components/upload';
export class VendorExpertiseHistoryEntity extends BaseEntity {
    crudInfo: string;
    fileObjectList: FileObject[];
    vendorExpertiseCertificateList: VendorExpertiseCertificate[];
    vendorExpertiseCertificateHistoryList: VendorExpertiseCertificateHistory[];

    vendorHistory: VendorHistory;
    profession: Profession;
    education: Education;
    file: File;
    createdDate: Date;
    name: string;
    birthDate: Date;
    experience: string;
    startDate: Date;
    endDate: Date;
    startYear: number;
    endYear: number;
    institution: string;
    vendorExpertiseId: number;
}
