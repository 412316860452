import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { PriceEvaluationStatus } from '../bean/price-evaluation-status';
import { ProcurementVendor } from '../bean/procurement-vendor';
import { ProfitLossStatus } from '../bean/profit-loss-status';
import { QuotationStatus } from '../bean/quotation-status';
import { SubmittedStatus } from '../bean/submitted-status';
import { ValidityStatus } from '../bean/validity-status';

export class QuotationEntity extends BaseWorkflowObjectEntity {
  procurementVendor: ProcurementVendor;
  quotationStatus: QuotationStatus;
  submittedStatus: SubmittedStatus;
  validityStatus: ValidityStatus;
  profitLossStatus: ProfitLossStatus;
  finalProfitLossStatus: ProfitLossStatus;
  goodsProfitLossStatus: ProfitLossStatus;
  serviceProfitLossStatus: ProfitLossStatus;
  priceEvaluationStatus: PriceEvaluationStatus;
  submittedDate: Date = new Date();
  lastReminderDate: Date = new Date();
  invalidNote: string;
  amount: number;
  finalAmount: number;
  goodsAmount: number;
  serviceAmount: number;
  profitLossAmount: number;
  finalProfitLossAmount: number;
  goodsProfitLossAmount: number;
  serviceProfitLossAmount: number;
  totalEvaluationScore: number;
  totalFinalEvaluationScore: number;
  isReference: boolean;
}
