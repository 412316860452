import { Contract } from 'src/app/core/bean/contract';
import { ContractCancelation } from 'src/app/core/bean/contract-cancelation';
import { ContractCancelationDoc } from 'src/app/core/bean/contract-cancelation-doc';
import { ContractDetail } from 'src/app/core/bean/contract-detail';
import { ContractPreparationDetail } from 'src/app/core/bean/contract-preparation-detail';
import { ContractShipping } from 'src/app/core/bean/contract-shipping';
import { ContractShippingDetail } from 'src/app/core/bean/contract-shipping-detail';
import { ContractType } from 'src/app/core/bean/contract-type';
import { DealingAgreementType } from 'src/app/core/bean/dealing-agreement-type';
import { Fines } from 'src/app/core/bean/fines';
import { Guarantee } from 'src/app/core/bean/guarantee';
import { ItemType } from 'src/app/core/bean/item-type';
import { Module } from 'src/app/core/bean/module';
import { Sow } from 'src/app/core/bean/sow';
import { SowPaymentTerm } from 'src/app/core/bean/sow-payment-term';
import { SowPaymentTermBilling } from 'src/app/core/bean/sow-payment-term-billing';
import { SowPaymentTermItem } from 'src/app/core/bean/sow-payment-term-item';
import { VendorAddress } from 'src/app/core/bean/vendor-address';
import { VendorPIC } from 'src/app/core/bean/vendor-pic';
import { ApprovalPathResponseModel } from 'src/app/core/components/app-approval-path-x';

export class ContractRequestXResponse {
  contractTypeList: ContractType[] = [];
  dealingAgreementTypeList: DealingAgreementType[] = [];
  itemTypeList: ItemType[] = [];
  contract: Contract;
  contractDetailList: ContractDetail[] = [];
  isUserApproval: boolean;
  sowList: Sow[] = [];
  finesList: Fines[] = [];
  guaranteeList: Guarantee[] = [];
  contractShippingList: ContractShipping[] = [];
  contractShippingDetailList: ContractShippingDetail[] = [];
  vendorAddress: VendorAddress;
  sowPaymentTermList: SowPaymentTerm[] = [];
  sowPaymentTermItemList: SowPaymentTermItem[] = [];
  sowPaymentTermBillingList: SowPaymentTermBilling[] = [];
  approvalPathResponseModel: ApprovalPathResponseModel =
    new ApprovalPathResponseModel();
  contractPreparationDetailList: ContractPreparationDetail[] = [];
  module: Module = new Module();
  vendorPic: VendorPIC;
  contractCancelation: ContractCancelation;
  contractCancelationDoc: ContractCancelationDoc = new ContractCancelationDoc();
  isBlanketOrder: boolean;
}
