import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Vendor } from '../../core/bean/vendor';
import { VendorBlacklist } from '../../core/bean/vendor-blacklist';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './vendor-blacklist.component.html',
  styles: [
    '.width-company-name { min-width : 250px; white-space: normal ! important }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class VendorBlacklistComponent
  extends BaseModuleComponent
  implements OnInit {
  @ViewChild(AppTableComponent) table: AppTableComponent;

  public tableResponse: TableResponseModel<VendorBlacklist>;
  public readonly urlRouterNavigateToAddVendorBlacklist =
    '/pages/vendor-blacklist/add';
  public readonly urlRouterNavigateToEditVendorBlacklist =
    '/pages/vendor-blacklist/edit';
  public readonly urlRouterNavigateToDetailVendorBlacklist =
    '/pages/vendor-blacklist/detail';

  constructor(translateService: TranslateService) {
    super('vendor-blacklist', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name', customClass: 'text-left' },
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      {
        field: 'vendorType.name',
        header: 'table.column.vendorType',
        customClass: 'text-center'
      },
      { field: 'email', header: 'table.column.email' },
      {
        field: 'vendorActivityStatus.name',
        header: 'table.column.vendorActivityStatus',
        customClass: 'text-center'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate([this.urlRouterNavigateToAddVendorBlacklist]);
  }

  public doEdit(vendor: Vendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'editVendorBlacklist');
    this.global.routerParams.set('vendorId', vendor.id);
    this.router.navigate([this.urlRouterNavigateToEditVendorBlacklist]);
  }

  public doDetail(vendor: Vendor): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'viewVendorBlacklist');
    this.global.routerParams.set('vendorId', vendor.id);
    this.router.navigate([this.urlRouterNavigateToDetailVendorBlacklist]);
  }
}
