<div class="row">
  <div class="col-12">
    <app-card header="rfi.titleResponse" [isLoading]="formLoading">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <h5 class="separator">{{ 'rfi.documentResponseRFI' | translate }}</h5>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'rfi.form.document' | translate
          }}</label>
          <div
            class="col-sm-9 text-left"
            *ngIf="rfiVendorId !== undefined && rfiVendorId !== null"
          >
            <a
              href="#"
              (click)="
                doDownload(
                  $event,
                  '/response-rfi/file/view/' +
                    rfiVendor.file.uploadedFileName +
                    '/' +
                    global.appConstant.fileType.DOC_RFI,
                  rfiVendor.file.fileName
                )
              "
            >
              <div>{{ rfiVendor.file.fileName }}</div>
            </a>
          </div>
          <div
            class="col-sm-9 text-left"
            *ngIf="
              rfiVendorNonPromiseId !== undefined &&
              rfiVendorNonPromiseId !== null
            "
          >
            <a
              href="#"
              (click)="
                doDownload(
                  $event,
                  '/response-rfi/file/view/' +
                    formGroup.value.parents[0].fileVendorNonPromise
                      .uploadedFileName +
                    '/' +
                    global.appConstant.fileType.DOC_RFI,
                  formGroup.value.parents[0].fileVendorNonPromise.fileName
                )
              "
            >
              <div>{{
                formGroup.value.parents[0].fileVendorNonPromise.fileName
              }}</div>
            </a>
          </div>
        </div>
        <br />
        <h5 class="separator">{{ 'rfi.additionalQuestion' | translate }}</h5>
        <p *ngIf="isNoQuestions" class="text-center">
          {{ 'app.info.noData' | translate }}</p
        >
        <ng-container formArrayName="parents">
          <ng-container
            *ngFor="let parent of formArrayParents.controls; let i = index"
            [formGroup]="parent"
          >
            <app-card>
              <div class="form-group row text-sm-right">
                <label class="col-sm-3 control-label">{{
                  'rfi.form.question' | translate
                }}</label>
                <div class="col-sm-4 text-left">
                  <div>{{ parents.value[i].question }}</div>
                </div>
              </div>

              <div *ngIf="parent.controls.formObjectCode.value === 'COMBO-BOX'">
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'rfi.form.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p>{{ parent.controls.answer.value.name }}</p>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  parent.controls.formObjectCode.value === 'CHECK-BOX' ||
                  parent.controls.formObjectCode.value === 'CHOSEN'
                "
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'rfi.form.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <ng-container *ngIf="isArray(parent.controls.answer.value)">
                      <div
                        *ngFor="
                          let answer of parent.controls.answer.value;
                          let i = index
                        "
                      >
                        <p>{{ answer.name }}, </p>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="!isArray(parent.controls.answer.value)"
                    >
                      <p>{{ parent.controls.answer.value }}</p>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div
                *ngIf="parent.controls.formObjectCode.value === 'DATE-PICKER'"
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'rfi.form.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p>{{
                      parent.controls.answer.value
                        | date: global.config.parameterModel.dateFormatShort
                    }}</p>
                  </div>
                </div>
              </div>

              <div
                *ngIf="parent.controls.formObjectCode.value === 'TOGGLE-SWITCH'"
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'rfi.form.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p>{{
                      parent.controls.answer === true
                        ? 'response-rfi.yes'
                        : ('response-rfi.no' | translate)
                    }}</p>
                  </div>
                </div>
              </div>

              <div
                *ngIf="parent.controls.formObjectCode.value === 'AUTOCOMPLETE'"
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'rfi.form.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p>{{ parent.controls.answer.value.name }}</p>
                  </div>
                </div>
              </div>

              <div
                *ngIf="parent.controls.formObjectCode.value === 'FIELD-PRICE'"
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'rfi.form.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p
                      >{{ parent.controls.answer.value.currency.code }}
                      {{ parent.controls.answer.value.price | number }}</p
                    >
                  </div>
                </div>
              </div>

              <div *ngIf="parent.controls.formObjectCode.value === 'WYSIWYG'">
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'rfi.form.answer' | translate
                  }}</label>
                  <div
                    class="col-7 text-left"
                    [innerHTML]="parent.controls.answer.value"
                  >
                  </div>
                </div>
              </div>

              <div
                *ngIf="parent.controls.formObjectCode.value === 'UPLOAD-SINGLE'"
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'response-rfi.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p>
                      <a
                        href="#"
                        (click)="
                          doDownload(
                            $event,
                            '/response-rfi/file/view/' +
                              parent.controls.answer.value[0].file
                                .uploadedFileName +
                              '/' +
                              global.appConstant.fileType.DOC_RFI,
                            parent.controls.answer.value[0].file.fileName
                          )
                        "
                      >
                        {{ parent.controls.answer.value[0].file.fileName }}</a
                      >
                    </p>
                  </div>
                </div>
              </div>

              <!-- text field, text area, text field numeric, radio button -->
              <div
                *ngIf="
                  parent.controls.formObjectCode.value === 'TEXT-FIELD' ||
                  parent.controls.formObjectCode.value === 'TEXT-AREA' ||
                  parent.controls.formObjectCode.value ===
                    'TEXT-FIELD-NUMERIC' ||
                  parent.controls.formObjectCode.value === 'RADIO-BUTTON'
                "
              >
                <div class="form-group row text-sm-right">
                  <label class="col-sm-3 control-label">{{
                    'rfi.form.answer' | translate
                  }}</label>
                  <div class="col-7 text-left">
                    <p>{{ parent.controls.answer.value }}</p>
                  </div>
                </div>
              </div>
            </app-card>
          </ng-container>
        </ng-container>

        <div class="row justify-content-center mt-5">
          <app-button
            mode="BYPASS"
            color="SECONDARY"
            (onClick)="doBack()"
            class="mr-1"
            >{{ 'app.button.back' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>
