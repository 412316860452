import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ComplianceTestGroup } from '../../core/bean/compliance-test-group';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './compliance-test-group.component.html'
})
export class ComplianceTestGroupComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<ComplianceTestGroup>;

  constructor(translateService: TranslateService) {
    super('compliance-test-group', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.col.name' },
      { field: 'description', header: 'table.col.description' }
    ]);
    this.setStateReady();
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/compliance-test-group/add']);
  }

  public doEdit(complianceTestGroup: ComplianceTestGroup): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set(
      'complianceTestGroupId',
      complianceTestGroup.id
    );
    this.router.navigate(['/pages/compliance-test-group/edit']);
  }

  public doDelete(complianceTestGroup: ComplianceTestGroup): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<ComplianceTestGroup>>(
              '/compliance-test-group/delete',
              complianceTestGroup
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
