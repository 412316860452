<app-layout
  [isLoading]="formLoading"
  *ngIf="!router.url.includes('quotation-detail')"
  [backTo]="urlBackOutside"
  header="open-quotation.breadcrumb.index"
>
  <ng-template #additionalTitle *ngIf="!formLoading && isShowBadgeStatus">
    <app-badge-catalog
      class="cml-2"
      *ngIf="procurement?.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge
      class="cml-2"
      [color]="badgeColor[procurement.procurementWorklistStatus.code]"
    >
      {{
        translateLabel(procurement.procurementWorklistStatus.code) | translate
      }}
    </app-badge>
  </ng-template>
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-alert-x
        *ngIf="isCancelation && !formLoading"
        color="DANGER"
        [isBold]="false"
      >
        {{ 'open-quotation.alert.msg.cancelationApproval' | translate }}
      </app-alert-x>

      <app-alert-x
        *ngIf="isFailedAssessment && !formLoading"
        color="DANGER"
        [isBold]="false"
      >
        {{ 'open-quotation.alert.msg.failApproval' | translate }}
      </app-alert-x>
      <app-workflow-step-info
        [isLoading]="formLoading"
        [model]="openQuotationResponse.workflowStepInfoModel"
        *ngIf="!formLoading"
      >
      </app-workflow-step-info>
      <app-card
        header="open-quotation.detail.procurementDetail.title"
        tips="open-quotation.detail.procurementDetail.tips"
      >
        <app-procurement-info [procurementId]="procurementId">
        </app-procurement-info>
      </app-card>
      <app-item-request
        [objectList]="openQuotationResponse.procurementItemList"
        [isShowPrice]="true"
        [itemTypeList]="openQuotationResponse.itemTypeList"
        [isView]="true"
        *ngIf="!formLoading"
      ></app-item-request>
      <app-card
        *ngIf="!formLoading"
        header="open-quotation.detail.vendorQuotation.title"
        tips="open-quotation.detail.vendorQuotation.tips"
        [detail]="
          procurement.documentSubmissionType.code !==
            global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_ONE_ENVELOPE &&
          procurement.documentSubmissionMethod?.translationKey.module.code.toLowerCase() +
            '.' +
            procurement.documentSubmissionMethod?.translationKey.key
        "
      >
        <app-alert-x [isBold]="false" color="WARNING" *ngIf="isAnyWaitingVerif">
          {{ 'open-quotation.alert.warning.waitingVerification' | translate }}
        </app-alert-x>
        <app-tab
          [bordered]="true"
          *ngIf="
            !formLoading &&
            (procurement.documentSubmissionType.code ===
              global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_ONE_ENVELOPE ||
              (procurement.documentSubmissionType.code ===
                global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE &&
                procurement.documentSubmissionMethod.code ===
                  global.appConstant.pm.DOCUMENT_SUBMISSION_METHOD_STAGE_2) ||
              (procurement.documentSubmissionType.code ===
                global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_ENVELOPE &&
                procurement.documentSubmissionMethod.code ===
                  global.appConstant.pm.DOCUMENT_SUBMISSION_METHOD_ENVELOPE_2))
          "
        >
          <app-tab-item
            header="{{ 'open-quotation.tab.header.byVendor' | translate }}"
          >
            <app-table-xx
              [model]="tableResponseQuotation"
              [isShowSearch]="false"
              *ngIf="!formLoading"
              (onClick)="doClickVendor($event)"
            ></app-table-xx>
          </app-tab-item>
          <app-tab-item
            header="{{ 'open-quotation.tab.header.byItem' | translate }}"
          >
            <app-table-xx
              [model]="tableResponseProcurementItem"
              [isShowSearch]="false"
              stringUrl="/open-quotation/index-procurement-item"
              *ngIf="!formLoading"
              (onClick)="doClickItem($event)"
            ></app-table-xx>
          </app-tab-item>
        </app-tab>
        <app-table-xx
          [model]="tableResponseQuotation2nd"
          [isShowSearch]="false"
          *ngIf="
            !formLoading &&
            ((procurement.documentSubmissionType.code ===
              global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_STAGE &&
              procurement.documentSubmissionMethod.code ===
                global.appConstant.pm.DOCUMENT_SUBMISSION_METHOD_STAGE_1) ||
              (procurement.documentSubmissionType.code ===
                global.appConstant.pm.DOCUMENT_SUBMISSION_TYPE_TWO_ENVELOPE &&
                procurement.documentSubmissionMethod.code ===
                  global.appConstant.pm.DOCUMENT_SUBMISSION_METHOD_ENVELOPE_1))
          "
          (onClick)="doClickVendor($event)"
        ></app-table-xx>
      </app-card>
      <app-approval-monitoring
        *ngIf="
          !formLoading &&
          todo === 'view' &&
          openQuotationResponse.approvalPathResponseModel &&
          !openQuotationResponse.isUserApproval
        "
        [workflowPrcs]="procurement?.workflowPrcs"
        [moduleCode]="procurement.prcsCode.replace('APPROVAL-', '')"
        [approvalPathResponseModel]="
          openQuotationResponse.approvalPathResponseModel
        "
      >
      </app-approval-monitoring>
      <div
        class="floating-button mt-5"
        *ngIf="!openQuotationResponse.isUserApproval && todo !== 'view'"
      >
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button (onClick)="doSubmit()" [disabled]="formSaving">{{
                'app.button.submit' | translate
              }}</app-button>
            </div>
          </div>
        </div>
      </div>
      <app-approval-prcs-x
        *ngIf="!formLoading && openQuotationResponse.isUserApproval"
        [workflowModelPrcs]="procurement?.workflowModelPrcs"
        [approvalModelPrcsId]="approvalModelPrcsId"
      >
      </app-approval-prcs-x>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
<router-outlet></router-outlet>
