import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { ConditionComponent } from './condition.component';
const routes: Routes = [
    { path: '', component: ConditionComponent, data: { breadcrumb: '' } }
];
@NgModule({
    imports: [SharedModule, RouterModule.forChild(routes)],
    declarations: [ConditionComponent]
})
export class ConditionModule { }
