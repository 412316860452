<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="catalog-attribute.title"
      detail="app.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'catalog-attribute.form.code' | translate
          }}</label>
          <div class="col-sm-9 col-lg-7 text-left">
            <app-text-field
              autofocus="true"
              size="MD"
              formControlName="code"
              type="code"
              maxLength="32"
              tooltip="{{ 'catalog-attribute.tooltip.code' | translate }}"
            >
            </app-text-field>
          </div>
        </div>

        <div class="form-group row text-sm-right mb-0">
          <label class="col-sm-3 control-label text-right">{{
            'catalog-attribute.form.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <ng-container formArrayName="translationList">
              <div
                class="form-group row col-12 p-0 mb-0"
                *ngFor="
                  let translation of translationList.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <div class="form-group row col-7 text-sm-right required">
                  <label class="col-sm-2 text-left control-label">{{
                    translationListLabel[i]
                  }}</label>
                  <div class="col-sm-9 text-left">
                    <app-text-field
                      size="XL"
                      formControlName="value"
                      maxLength="64"
                    ></app-text-field>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'catalog-attribute.form.function' | translate
            }}</label>
            <div class="col-sm-9 col-lg-7 text-left">
              <app-check-box
                size="LG"
                position="VERTICAL"
                formControlName="checkList"
              ></app-check-box>
            </div>
          </div>

          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'catalog-attribute.form.status' | translate
            }}</label>
            <div class="col-sm-9 col-lg-7 text-left">
              <app-combo-box
                size="LG"
                formControlName="isActive"
                [optionList]="statusList"
              >
              </app-combo-box>
            </div>
          </div>

          <div class="form-group row mb-0 text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'catalog-attribute.form.formObject' | translate
            }}</label>
            <div class="col-sm-9 mb-3 col-lg-7 text-left">
              <app-combo-box
                size="LG"
                [optionList]="formObjectList"
                formControlName="formObject"
                (onChange)="showFormOption($event)"
                tooltip="{{
                  'catalog-attribute.tooltip.formObject' | translate
                }}"
              >
              </app-combo-box>
            </div>
          </div>

          <ng-container *ngIf="formObjectValue" formArrayName="credentials">
            <div
              class="form-group row text-sm-right required"
              *ngFor="let creds of inputList.controls; let i = index"
              [formGroupName]="i"
            >
              <label class="col-sm-3 control-label"
                >{{ 'catalog-attribute.form.option' | translate
                }}{{ ' ' + (i + 1) }}</label
              >
              <div class="col-sm-9 col-lg-7 text-left">
                <div class="form-row">
                  <div class="form-group row col-4">
                    <div class="col text-left">
                      <app-text-field
                        size="XL"
                        maxLength="32"
                        formControlName="name"
                        placeholder="{{
                          'catalog-attribute.form.name' | translate
                        }}"
                      >
                      </app-text-field>
                    </div>
                  </div>
                  <div class="form-group row col-4">
                    <div class="col text-left">
                      <app-text-field
                        size="XL"
                        maxLength="32"
                        formControlName="value"
                        placeholder="{{
                          'catalog-attribute.form.value' | translate
                        }}"
                      >
                      </app-text-field>
                    </div>
                  </div>
                  <div class="form-group row col-4">
                    <div class="col text-left">
                      <app-button
                        *ngIf="i !== 0"
                        size="SM"
                        color="SECONDARY"
                        (onClick)="doRemove(i)"
                        title="{{ 'app.tooltip.delete' | translate }}"
                      >
                        <em class="fa fas fa-trash"></em>
                      </app-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="text-center" *ngIf="formObjectValue">
            <button class="btn btn-primary" (click)="addCreds()"
              ><em class="fas fa-plus"></em>
              {{ 'catalog-attribute.form.addMore' | translate }}</button
            >
          </div>

          <br />

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'catalog-attribute.form.description' | translate
            }}</label>
            <div class="col-7 text-left">
              <app-text-area
                size="LG"
                formControlName="description"
                maxLength="512"
              ></app-text-area>
            </div>
          </div>

          <div class="button-group button-group-center mt-5">
            <app-button
              color="SECONDARY"
              (onClick)="doCancel()"
              [disabled]="formSaving"
            >
              {{ 'app.button.back' | translate }}</app-button
            >
            <app-button (onClick)="doSave()" [disabled]="formSaving">
              {{ 'app.button.save' | translate }}</app-button
            >
          </div>
        </form>
      </form>
    </app-card>
  </div>
</div>
