import { BaseEntity } from '../base/base-entity';
import { ScoringCriteriaRange } from '../bean/scoring-criteria-range';
import { Attribute } from './../bean/attribute';
export class ScoringCriteriaEntity extends BaseEntity {
    result: number;
    value: string;
    scoringCriteriaRangeList: ScoringCriteriaRange[];
    attribute: Attribute;
    isManualScoring: boolean;
    name: string;
    description: string;
}
