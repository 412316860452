import { NgModule } from '@angular/core';
import { ResolveUploadHref } from './resolve-upload-href.pipe';
import { ResolveUploadProgressValue } from './resolve-upload-progress-value.pipe';
import { ResolveUploadSrcX } from './resolve-upload-src-x.pipe';
import { ResolveUploadSrc } from './resolve-upload-src.pipe';
@NgModule({
  declarations: [
    ResolveUploadHref,
    ResolveUploadSrc,
    ResolveUploadSrcX,
    ResolveUploadProgressValue
  ],
  exports: [
    ResolveUploadHref,
    ResolveUploadSrc,
    ResolveUploadSrcX,
    ResolveUploadProgressValue
  ]
})
export class UploadPipesModule {}
