import { ClaimEntity } from "../entity/claim.entity";
import { File } from "./file";

export class Claim extends ClaimEntity {

    ocrVerifAmount: number;
    ocrVerifAmountApproved: number;
    ocrFile: File;
    paymentTotal: number;
    paymentDate: Date;
    sysPaymentCheckCode: string;
    nilaiJaminanP3K: number;
    nilaiJaminanAmbl: number;
    sudahDibayarP3k: number;
    sudahDibayarAmbl: number
}