<app-layout
  [isLoading]="formLoading"
  header="doc-retrieval.breadcrumb.index"
  [backTo]="urlBackOutside"
>
  <ng-template #additionalTitle *ngIf="!formLoading && isShowBadgeStatus">
    <app-badge-catalog
      class="cml-2"
      *ngIf="procurement.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge
      *ngIf="todo !== 'add'"
      class="cml-2"
      [color]="badgeColor[procurement.procurementWorklistStatus.code]"
    >
      {{ getTranslateKey(procurement.procurementWorklistStatus) | translate }}
    </app-badge>
    <app-badge *ngIf="todo === 'add'" color="INFO" class="cml-2">
      {{ getTranslateKey(procurement.procurementWorklistStatus) | translate }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          (procurement?.procurementStatus?.code ===
            global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm
                .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT)
        "
      >
        {{ canceledOrFailedMessage | translate }}
      </app-alert-x>
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        *ngIf="!formLoading"
        novalidate
      >
        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="workflowStepInfoModel"
        >
        </app-workflow-step-info>
        <app-card
          header="doc-retrieval.procDetail.general.title"
          tips="doc-retrieval.procDetail.general.tips"
        >
          <app-procurement-info [procurementId]="procurementId">
          </app-procurement-info>
        </app-card>

        <app-item-request
          [objectList]="procurementItemList"
          [isView]="true"
          [isShowPrice]="true"
          [itemTypeList]="itemTypeList"
        >
        </app-item-request>

        <app-card *ngIf="!formLoading">
          <app-table-xx
            [model]="tableResponse"
            header="doc-retrieval.docRetrievalMonitoring.general.title"
            tips="doc-retrieval.docRetrievalMonitoring.general.tips"
            [isView]="todo === 'view'"
            (onAfterRowCreated)="onAfterRowCreated($event)"
            (onChange)="onChangeValue($event)"
          >
          </app-table-xx>
        </app-card>

        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            docRetrievalResponse.approvalPathResponseModel &&
            !docRetrievalResponse.isUserApproval &&
            (procurement.workflowModelPrcs.code !== 'DOC-RETRIEVAL' ||
              (procurement.workflowModelPrcs.code === 'DOC-RETRIEVAL' &&
                procurement.workflowPrcs?.workflowStatus?.code ===
                  global.appConstant.core.WORKFLOW_CODE_STATUS_REVISION))
          "
          [workflowPrcs]="docRetrievalResponse?.procurement?.workflowPrcs"
          [moduleCode]="moduleCode"
          [approvalPathResponseModel]="
            docRetrievalResponse.approvalPathResponseModel
          "
        >
        </app-approval-monitoring>
        <app-approval-prcs-x
          *ngIf="!formLoading && docRetrievalResponse.isUserApproval"
          [workflowModelPrcs]="
            docRetrievalResponse.procurement.workflowModelPrcs
          "
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>

        <div class="floating-button mt-5">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div
                class="button-group button-group-center cmt-4"
                *ngIf="todo !== 'view'"
              >
                <app-button
                  color="PRIMARY"
                  [outline]="true"
                  (onClick)="doSave()"
                  [disabled]="formSaving || formGroup.isView"
                  >{{ 'app.button.save' | translate }}
                </app-button>
                <app-button
                  (onClick)="doSubmit()"
                  [disabled]="formSaving || formGroup.isView"
                  >{{ 'app.button.submit' | translate }}</app-button
                >
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
