import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { BudgetAllocationByCoaDetailOrganizationView } from '../../core/view/entity/bean/budget-allocation-by-coa-organization';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './app-popup-budget-allocation.component.html'
})
export class AppPopupBudgetAllocationComponent extends BaseComponentComponent {
  @Input() public coaId: number;
  public tableResponse: TableResponseModel<BudgetAllocationByCoaDetailOrganizationView>;

  constructor() {
    super('budget-allocation');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'organization.name', header: 'table.column.organization' },
      {
        field: 'branchOffice.name',
        header: 'table.column.branchOffice',
        customClass: 'text-center'
      },
      {
        field: 'activityPeriod',
        header: 'table.column.activityPeriod',
        customClass: 'text-center'
      },
      {
        field: 'totalBudget',
        header: 'table.column.totalBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'bookedBudget',
        header: 'table.column.bookedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'usedBudget',
        header: 'table.column.usedBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'paidOffBudget',
        header: 'table.column.paidOffBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      },
      {
        field: 'availableBudget',
        header: 'table.column.availableBudget',
        format: FieldFormatEnum.Currency,
        currencyCodeRef: 'currency.code'
      }
    ]);
  }
}
