import { EventEmitter } from '@angular/core';

export class PaginationModel {
  perPage: number;
  page: number;
  totalRecords: number;
  totalPage: number;
  pageChanges: EventEmitter<number> = new EventEmitter();
  isShowPaginationDetail: boolean;
  constructor(isShowPaginationDetail = true, perPage = 10) {
    this.isShowPaginationDetail = isShowPaginationDetail;
    this.perPage = perPage;
    this.page = 1;
  }

  setCurrentPage(page: number): void {
    this.page = page;
    this.pageChanges.emit(page);
  }

  setPerPage(perPage: number): void {
    this.perPage = perPage;
    this.setTotalPage();
  }

  setTotalRecords(totalRecords: number): void {
    this.totalRecords = totalRecords;
    this.setTotalPage();
  }

  setTotalPage(): void {
    this.totalPage = Math.ceil(this.totalRecords / this.perPage);
  }
}
