import { AppDynamicComponentService } from '../../../services/app-dynamic-component.service';
import { AppRatingComponent } from '../../app-rating/app-rating.component';
import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';
export const RatingPlugins: TablePlugin = {
  name: 'rating',
  before: (data: TablePluginData): void => {
    data.element.classList.add('white-space-nowrap');
    const appDynamicComponent = data.state.injector.get(
      AppDynamicComponentService
    );
    appDynamicComponent.create(AppRatingComponent, data.element, {
      value: data.value,
      isViewOnly: true
    });
  }
};
