import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppSelectModule } from '../../app-select/app-select.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupEditTableComponent } from './app-popup-edit-table.component';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppButtonModule, AppSelectModule],
  declarations: [AppPopupEditTableComponent],
  entryComponents: [AppPopupEditTableComponent],
  exports: [AppPopupEditTableComponent]
})
export class AppPopupEditTableModule {}
