<app-popup
  header="initiation.popup.segmentation.title"
  [isLoading]="formLoading"
>
  <form
    class="form-horizontal initiation-popup-segmentation"
    [formGroup]="formGroup"
    novalidate
  >
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'initiation.form.segmentation' | translate }}
      </label>
      <div class="col">
        <app-combo-box-tree
          size="XL"
          formControlName="segmentation"
          stringUrl="/segmentation"
          isLazy="true"
        >
        </app-combo-box-tree>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'initiation.form.termQualification' | translate }}
      </label>
      <div class="col">
        <app-dropdown-select
          size="XL"
          formControlName="termQualification"
          [optionList]="termQualificationOptionList"
          (onChange)="doClearQualification($event)"
        >
        </app-dropdown-select>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'initiation.form.qualification' | translate }}
      </label>
      <div class="col">
        <ng-container
          *ngIf="
            formGroup.get('termQualification').value?.code ===
              global.appConstant.pm.TERM_QUALIFICATION_MINIMUM_LIMIT;
            then comboboxSelect;
            else chosenSelect
          "
        ></ng-container>
        <ng-template #chosenSelect>
          <app-dropdown-select
            type="CHOSEN"
            size="XL"
            formControlName="qualification"
            [optionList]="qualificationOptionList"
          >
          </app-dropdown-select>
        </ng-template>

        <ng-template #comboboxSelect>
          <app-combo-box
            formControlName="qualification"
            size="XL"
            [optionList]="qualificationOptionList"
          >
          </app-combo-box>
        </ng-template>
      </div>
    </div>

    <ng-template #modalFooter let-activeModal>
      <div class="button-group button-group-center">
        <app-button color="LIGHT" (onClick)="activeModal.close(true)">
          {{ 'app.button.cancel' | translate }}</app-button
        >
        <app-button (onClick)="doAdd()">
          {{ 'app.button.add' | translate }}</app-button
        >
      </div>
    </ng-template>
  </form>
</app-popup>
