import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Wanprestasi } from '../../core/bean/wanprestasi';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { AppPopupTabWanprestasiHistoryComponent } from './app-popup-tab-wanprestasi-history.component';
@Component({
  templateUrl: './tab-wanprestasi-history.component.html'
})
export class TabWanprestasiHistoryComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<Wanprestasi>;
  public tableResponse: TableResponseModel<Wanprestasi>;
  public header: string;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('tab-wanprestasi-history', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setCustomData();
    this.model.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: '0.wanprestasiDate',
        header: 'table.column.wanprestasiDate',
        customClass: 'text-center',
        format: FieldFormatEnum.ShortDate,
        searchTable: false
      },
      {
        field: '0.memoNumber',
        header: 'table.column.memoNumber',
        customClass: 'text-center'
      },
      { field: '1', header: 'table.column.type' },
      { field: '0.user.name', header: 'table.column.admin' }
    ]);
  }

  public setCustomData(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponse.setCustomData(customData);
  }

  public doDetail(wanprestasi: Wanprestasi): void {
    if (wanprestasi[1] === 'Contract') {
      this.header = this.translateService.instant(
        'tab-wanprestasi-history.popup.contract'
      );
    } else if (wanprestasi[1] === 'Procurement') {
      this.header = this.translateService.instant(
        'tab-wanprestasi-history.popup.procurement'
      );
    }
    const header = this.header;
    this.appPopupService.open(
      AppPopupTabWanprestasiHistoryComponent,
      { wanprestasi: wanprestasi[0], header },
      { size: 'lg', backdrop: 'static' }
    );
  }
}
