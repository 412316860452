<div class="row">
  <div class="col-12">
    <app-card>
      <div class="row font-weight-bold">
        <div class="col-sm-7">
          <div class="row">
            <div class="col-6">Field Name</div>
            <div class="col-3 text-center">isMandatory</div>
            <div class="col-3 text-center">isApproval</div>
          </div>
        </div>
      </div>
      <form [formGroup]="formGroup" class="accordion" id="accordion">
        <ng-container formGroupName="generated">
          <ng-container
            *ngFor="
              let item of formArrayGenerated.controls | keyvalue;
              let i = index
            "
            [formGroup]="item"
          >
            <div class="card">
              <div class="card-header" id="heading{{ i }}">
                <h5 class="d-inline-block mb-0">{{
                  item.value.controls.fieldName.value
                }}</h5>
                <em
                  class="fas fa-chevron-circle-down"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseVendorAttr' + i"
                  aria-expanded="true"
                  [attr.aria-controls]="'collapseVendorAttr' + i"
                ></em>
              </div>
              <div
                id="collapseVendorAttr{{ i }}"
                class="collapse"
                [attr.aria-labelledby]="'heading' + i"
                data-parent="#accordion"
                formGroupName="childrens"
                [ngClass]="{ show: i === 0 }"
              >
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-7">
                      <ng-container
                        *ngFor="
                          let childrenKey of item.value.controls.childrens
                            .controls | keyvalue
                        "
                        [formGroupName]="childrenKey.key"
                      >
                        <h6 class="separator">{{
                          childrenKey.value.controls.fieldName.value
                        }}</h6>
                        <ng-container formArrayName="childrens">
                          <div
                            class="row py-2"
                            *ngFor="
                              let childerChildKey of childrenKey.value.controls
                                .childrens.controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <div
                              class="col-6"
                              (click)="testClick(childerChildKey)"
                              (keyup)="onKeyUp($event)"
                              (keydown)="onKeyDown($event)"
                              >{{ childerChildKey.value.fieldName }}</div
                            >
                            <div class="col-3 text-center">
                              <app-check-box formControlName="isMandatory">
                              </app-check-box>
                            </div>
                            <div class="col-3 text-center">
                              <app-check-box formControlName="isApproval">
                              </app-check-box>
                            </div>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <div class="card" formArrayName="addtionalInformation">
          <div class="card-header" id="headingAddtional">
            <h5 class="d-inline-block mb-0">Addtional Attribute</h5>
            <em
              class="fas fa-chevron-circle-down"
              data-toggle="collapse"
              data-target="#collapseAddtionalAttribute"
              aria-expanded="false"
              aria-controls="collapseAddtionalAttribute"
            ></em>
          </div>
          <div
            id="collapseAddtionalAttribute"
            class="collapse show"
            aria-labelledby="headingAddtional"
          >
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-right mb-3">
                  <app-button size="SM" color="PRIMARY" (onClick)="doAdd()">{{
                    'app.button.add' | translate
                  }}</app-button>
                </div>
              </div>
              <div
                class="row py-1"
                *ngFor="
                  let item of addtionalInformation.controls;
                  let i = index
                "
                [formGroupName]="i"
              >
                <div class="col-sm-7">
                  <div class="row">
                    <div class="col-6">{{ item.value.fieldName }}</div>
                    <div class="col-3 text-center">
                      <app-check-box formControlName="isMandatory">
                      </app-check-box>
                    </div>
                  </div>
                </div>
                <div class="col-sm-5 text-right">
                  <app-button size="TN" color="DANGER" (onClick)="doDelete(i)">
                    <em class="fas fa-trash"></em>
                  </app-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="row mt-3 d-flex">
        <div class="button-group button-group-center">
          <app-button color="SECONDARY"
            >{{ 'app.button.back' | translate }}
          </app-button>
          <app-button (onClick)="doSubmit()"
            >{{ 'app.button.save' | translate }}
          </app-button>
        </div>
      </div>
    </app-card>
  </div>
</div>
