import { Component, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ScoringCriteria } from '../../core/bean/scoring-criteria';
import { ScoringCriteriaRange } from '../../core/bean/scoring-criteria-range';
import { AppPopupAttributeComponent } from '../../core/components/app-popup/app-popup-attribute/app-popup-attribute.component';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { ScoringCriteriaRequest } from './scoring-criteria-request';
import { ScoringCriteriaResponse } from './scoring-criteria-response';

@Component({
  templateUrl: './scoring-criteria-edit-add.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ScoringCriteriaEditAddComponent extends BaseModuleComponent {
  public scoringCriteria: ScoringCriteria = new ScoringCriteria();
  public scoringCriteriaId: number;
  public formGroupTemp: FormGroup = new FormGroup({});
  public scoringCriteriaResponse: ScoringCriteriaResponse =
    new ScoringCriteriaResponse();
  public scoringCriteriaResponseTemp: ScoringCriteriaResponse =
    new ScoringCriteriaResponse();
  public listOptionList: Array<OptionListModel<any>> = new Array();
  public optionList: OptionListModel<any> = new OptionListModel(
    false,
    'name',
    'name'
  );
  public isDisabled = true;
  public disableSaveButtons = false;
  public untilValue = [];

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('scoring-criteria', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setOptionListRequestValue();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.scoringCriteriaId = this.global.routerParams.get('scoringCriteriaId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      isManualScoring: [null],
      name: [
        null,
        Validators.compose([Validators.required()])
      ],
      attributeCode: [null],
      attribute: [null],
      description: [null, Validators.maxLength(512)],
      scoringCriteriaRangeList: this.formBuilder.array([])
    });
  }

  public showScoringCriteriaRange(
    scoringCriteriaResponse: ScoringCriteriaResponse
  ): void {
    if (scoringCriteriaResponse.scoringCriteria != null) {
      const isManualScoring =
        scoringCriteriaResponse.scoringCriteria.isManualScoring;
      this.formGroup.get('isManualScoring').patchValue(isManualScoring);
    }
  }

  public change(event: any): void {
    const isManualScoring = event;
    if (isManualScoring) {
      if (
        this.scoringCriteriaResponseTemp.scoringCriteriaRangeList != null &&
        this.scoringCriteriaResponseTemp.scoringCriteriaRangeList[
          this.scoringCriteriaResponseTemp.scoringCriteriaRangeList.length - 1
        ].id !== null
      ) {
        this.formGroup.get('attributeCode').clearValidators();
        this.formGroup.get('attribute').clearValidators();
        this.formGroup.get('attributeCode').updateValueAndValidity();
        this.formGroup.get('attribute').updateValueAndValidity();
        this.isDisabled = true;
        this.buildFormControl(
          this.scoringCriteriaResponseTemp.scoringCriteriaRangeList
        );
        this.doSetDisabledFromScoringCriteriaRangeList();
        this.doSetDisabledUntilScoringCriteriaRangeList();
      } else {
        this.scoringCriteriaRangeList.push(
          this.formBuilder.group({
            id: [null],
            from: [{ value: 0, disabled: true }],
            until: [null, Validators.compose([Validators.required()])],
            isInfinity: [false],
            result: [null, Validators.compose([Validators.required()])]
          })
        );
      }
    } else {
      this.formGroup.get('attributeCode').setValidators(Validators.required());
      this.formGroup.get('attribute').setValidators(Validators.required());
      this.formGroup.get('attributeCode').updateValueAndValidity();
      this.formGroup.get('attribute').updateValueAndValidity();
      while (this.scoringCriteriaRangeList.length !== 0) {
        this.scoringCriteriaRangeList.removeAt(0);
      }
    }
  }

  public setOptionListRequestValue(): void {
    const optionList = [
      { id: 0, name: '0' },
      { id: 1, name: '1' },
      { id: 2, name: '2' },
      { id: 3, name: '3' },
      { id: 4, name: '4' },
      { id: 5, name: '5' }
    ];
    this.optionList.setRequestValues(optionList);
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ScoringCriteriaResponse>(
        '/scoring-criteria/add-edit',
        new RouteRequestModel(this.todo, this.scoringCriteriaId)
      )
      .subscribe(scoringCriteriaResponse => {
        this.scoringCriteriaResponse = scoringCriteriaResponse;
        this.scoringCriteriaResponseTemp = scoringCriteriaResponse;
        if (scoringCriteriaResponse.scoringCriteria != null) {
          const { id, name, code, attribute, description } =
            scoringCriteriaResponse.scoringCriteria;
          if (attribute !== null) {
            const { code: attributeCode } = attribute;
            this.formGroup.patchValue({
              id,
              name,
              code,
              attribute,
              attributeCode,
              description
            });
          } else {
            this.formGroup.patchValue({
              id,
              name,
              code,
              attribute,
              description
            });
          }
        }
        this.showScoringCriteriaRange(scoringCriteriaResponse);
        if (this.formGroup.get('isManualScoring').value) {
          this.buildFormControl(
            scoringCriteriaResponse.scoringCriteriaRangeList
          );
        }
        for (let i = 0; this.scoringCriteriaRangeList.length > i; i++) {
          if (
            this.scoringCriteriaRangeList.at(i).get('until').value !== null &&
            i !== this.scoringCriteriaRangeList.length - 1
          ) {
            this.scoringCriteriaRangeList.at(i).get('until').disable();
          }
        }
        this.setStateReady();
        this.doSetDisabledFromScoringCriteriaRangeList();
        this.doSetDisabledUntilScoringCriteriaRangeList();
      });
  }

  public doSetDisabledFromScoringCriteriaRangeList(): void {
    this.scoringCriteriaRangeList.controls.forEach((formGroup: FormGroup) => {
      formGroup.get('from').disable();
    });
  }

  public doSetDisabledUntilScoringCriteriaRangeList(): void {
    this.scoringCriteriaRangeList.controls.forEach(
      (formGroup: FormGroup, index) => {
        if (
          index === this.scoringCriteriaRangeList.length - 1 &&
          formGroup.get('isInfinity').value === false
        ) {
          formGroup.get('until').enable();
        } else if (
          index === this.scoringCriteriaRangeList.length - 1 &&
          formGroup.get('isInfinity').value === true
        ) {
          formGroup.get('until').disable();
        } else {
          formGroup.get('until').disable();
        }
      }
    );
  }

  public doSetDisabledUntilScoringCriteriaRangeListAfterValidation(): void {
    this.scoringCriteriaRangeList.controls.forEach(
      (formGroup: FormGroup, index) => {
        if (index !== this.scoringCriteriaRangeList.length - 1) {
          formGroup.get('until').disable();
        } else if (
          index === this.scoringCriteriaRangeList.length - 1 &&
          formGroup.get('isInfinity').value === true
        ) {
          formGroup.get('until').disable();
          formGroup.get('until').setValue('-');
        }
      }
    );
  }

  public get scoringCriteriaRangeList(): FormArray {
    return this.formGroup.controls.scoringCriteriaRangeList as FormArray;
  }

  public buildFormControl(
    scoringCriteriaRangeList: ScoringCriteriaRange[]
  ): void {
    scoringCriteriaRangeList.forEach(scoringCriteriaRange => {
      const scoringCriteriaFrom = scoringCriteriaRange.from.toString();
      let scoringCriteriaRangeUntil: any;
      if (scoringCriteriaRange.until != null) {
        scoringCriteriaRangeUntil = scoringCriteriaRange.until;
      }
      if (scoringCriteriaRange.isInfinity) {
        scoringCriteriaRangeUntil = '-';
      }
      this.scoringCriteriaRangeList.push(
        this.formBuilder.group({
          id: [scoringCriteriaRange.id],
          from: [scoringCriteriaFrom],
          until: [
            scoringCriteriaRangeUntil,
            Validators.compose([Validators.required()])
          ],
          isInfinity: [scoringCriteriaRange.isInfinity],
          result: [
            scoringCriteriaRange.result.toString(),
            Validators.compose([Validators.required()])
          ]
        })
      );
    });
  }

  public doAdd(): void {
    if (this.formGroup.get('isManualScoring').value === true) {
      this.scoringCriteriaRangeList.controls.forEach(
        (formGroup: FormGroup, index) => {
          if (index === this.scoringCriteriaRangeList.length - 1) {
            formGroup.get('until').disable();
            const checkDecimal = formGroup
              .get('until')
              .value.toString()
              .includes('.');
            let fromConvertInt: any;
            if (checkDecimal) {
              const fromToInt = parseFloat(formGroup.get('until').value);
              const splitFrom = formGroup.get('until').value.split('');
              const totalNumberAfterDot =
                splitFrom.length - 1 - splitFrom.indexOf('.');
              if (totalNumberAfterDot === 2) {
                fromConvertInt = fromToInt + 0.01 + 0.00000001;
                fromConvertInt = fromConvertInt
                  .toString()
                  .substring(
                    0,
                    fromConvertInt.toString().split('.')[0].length + 3
                  );
              } else if (totalNumberAfterDot === 1) {
                fromConvertInt = fromToInt + 0.1 + 0.00000001;
                fromConvertInt = fromConvertInt
                  .toString()
                  .substring(
                    0,
                    fromConvertInt.toString().split('.')[0].length + 2
                  );
              } else {
                fromConvertInt = fromToInt + 1;
              }
            } else {
              fromConvertInt = parseFloat(formGroup.get('until').value) + 1;
            }

            this.scoringCriteriaRangeList.push(
              this.formBuilder.group({
                id: [null],
                from: [{ value: fromConvertInt, disabled: true }],
                until: [null, Validators.compose([Validators.required()])],
                isInfinity: [false],
                result: [null, Validators.compose([Validators.required()])]
              })
            );
            this.isDisabled = true;
          }
        }
      );
    }
  }

  public doRemove(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.scoringCriteriaRangeList.removeAt(index);
          this.isDisabled = false;
          const lastIndex = index - 1;
          this.scoringCriteriaRangeList.at(lastIndex).get('until').enable();
        }
      });
  }

  public doIsInfinityChange(
    value: boolean,
    scoringCriteriaRange: FormGroup,
    index: number
  ): void {
    if (value) {
      scoringCriteriaRange.get('until').disable();
      scoringCriteriaRange.patchValue({
        until: '-'
      });
      this.isDisabled = true;
      this.disableSaveButtons = false;
      for (let i = this.scoringCriteriaRangeList.length; i > index; i--) {
        this.scoringCriteriaRangeList.removeAt(i);
      }
    } else {
      this.isDisabled = true;
      this.disableSaveButtons = true;
      scoringCriteriaRange.get('until').enable();
      scoringCriteriaRange.patchValue({ until: '' });
    }
  }

  public doInputMethod(event, scoringCriteriaRange: FormGroup): void {
    event.target.value = event.target.value.replace(/^0+/, '');
    const fromConvertInt = parseFloat(scoringCriteriaRange.get('from').value);
    const untilConvertInt = parseFloat(scoringCriteriaRange.get('until').value);
    if (
      scoringCriteriaRange.get('until').value === null ||
      scoringCriteriaRange.get('until').value === '' ||
      scoringCriteriaRange.get('until').value === '0' ||
      scoringCriteriaRange.get('until').value === 0
    ) {
      this.isDisabled = true;
    } else {
      if (fromConvertInt > untilConvertInt) {
        scoringCriteriaRange
          .get('until')
          .setValidators(
            Validators.compose([
              Validators.required(),
              Validators.min(fromConvertInt)
            ])
          );
        this.isDisabled = true;
        this.disableSaveButtons = true;
      } else {
        scoringCriteriaRange.get('until').setValidators(Validators.required());
        this.isDisabled = false;
        this.disableSaveButtons = false;
      }
    }

    this.limitNumberComma(scoringCriteriaRange);
  }

  public limitNumberComma(scoringCriteriaRange: FormGroup): void {
    let countingComma = 0;
    if (scoringCriteriaRange.get('until').value != null) {
      const arrayNumber = scoringCriteriaRange.get('until').value.split('');

      arrayNumber.forEach(value => {
        if (value === '.') {
          countingComma += 1;
        }
      });

      if (countingComma === 1) {
        const findCommaIndex = arrayNumber.indexOf('.');
        const arrayNumberAfterComma = scoringCriteriaRange
          .get('until')
          .value.substring(findCommaIndex)
          .split('');

        if (
          arrayNumberAfterComma.splice(1, arrayNumberAfterComma.length).length >
          2
        ) {
          this.disableSaveButtons = true;
        }
      } else if (countingComma > 1) {
        this.disableSaveButtons = true;
      }
    }
  }

  public doChooseAttribute(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.formGroup.get('attributeCode').markAsTouched();
    const customData =
      this.formGroup.get('isManualScoring').value !== null
        ? this.formGroup.get('isManualScoring').value
        : false;
    const urlCustom = '/scoring-criteria/popup-attribute';
    this.appPopupService
      .open(AppPopupAttributeComponent, { customData, urlCustom })
      .subscribe(rowData => {
        const attribute = rowData[0];
        const { code: attributeCode } = rowData[0];
        this.formGroup.patchValue({ attribute, attributeCode });
      });
  }

  public doSave(): void {
    this.formGroupTemp = this.formGroup;
    this.validate();
    if (
      this.formGroup.valid &&
      !this.hasDuplicate(this.scoringCriteriaRangeList.value) &&
      !this.notAscendingSort(this.scoringCriteriaRangeList.value)
    ) {
      console.log(this.notAscendingSort(this.scoringCriteriaRangeList.value));
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            // let finalResult = 0;
            // this.formGroup
            //   .get('scoringCriteriaRangeList')
            //   .value.forEach(scoringCriteriaRange => {
            //     finalResult += parseInt(scoringCriteriaRange.result, 10);
            //   });
            const scoringCriteriaRequest: ScoringCriteriaRequest =
              new ScoringCriteriaRequest();
            const scoringCriteriaNew: ScoringCriteria = this.formGroup.value;
            if (this.formGroup.get('isManualScoring').value === true) {
              this.scoringCriteriaRangeList.value.forEach(
                (scoringCriteriaRange, index) => {
                  const from = parseFloat(scoringCriteriaRange.from);
                  const scoringCriteriaRangeUntil = scoringCriteriaRange.until;
                  let until: any;
                  if (
                    scoringCriteriaRangeUntil !== '-' ||
                    scoringCriteriaRangeUntil !== ''
                  ) {
                    until = parseFloat(scoringCriteriaRangeUntil);
                  } else {
                    until = null;
                  }
                  // tslint:disable-next-line: radix
                  this.scoringCriteriaRangeList.at(index).patchValue({
                    from,
                    until,
                    result: scoringCriteriaRange.result
                  });
                }
              );
              scoringCriteriaNew.scoringCriteriaRangeList = this.formGroup.get(
                'scoringCriteriaRangeList'
              ).value;
              scoringCriteriaRequest.scoringCriteriaRangeList =
                this.formGroup.get('scoringCriteriaRangeList').value;
              // scoringCriteriaResponse.totalResult = finalResult;
            } else {
              scoringCriteriaRequest.scoringCriteriaRangeList = null;
              scoringCriteriaNew.scoringCriteriaRangeList = null;
              scoringCriteriaNew.isManualScoring = false;
            }
            scoringCriteriaRequest.scoringCriteria = scoringCriteriaNew;
            const url: string =
              this.todo === 'edit'
                ? '/scoring-criteria/update'
                : '/scoring-criteria/insert';
            this.httpClientService
              .post<Response<ScoringCriteriaRequest>>(
                url,
                scoringCriteriaRequest
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/scoring-criteria/']);
                  this.setStateReady();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                  this.doSetDisabledFromScoringCriteriaRangeList();
                  this.doSetDisabledUntilScoringCriteriaRangeListAfterValidation();
                }
              });
          }
        });
    } else if (this.hasDuplicate(this.scoringCriteriaRangeList.value)) {
      const resultArray = [];
      let duplicateIndex = [];
      let duplicateIndexString = '';
      this.scoringCriteriaRangeList.value.forEach(scoringCriteriaRange => {
        resultArray.push(scoringCriteriaRange.result);
      });
      duplicateIndex = this.showDuplicatePosition(resultArray, null);
      for (let i = 0; i <= duplicateIndex.length - 1; i++) {
        if (i === duplicateIndex.length - 1) {
          duplicateIndexString += duplicateIndex[i] + 1;
        } else {
          duplicateIndexString += duplicateIndex[i] + 1 + ',';
        }
      }
      this.global.alertService.showError(
        this.translateService.instant(
          'scoring-criteria.msg.error.resultDuplicate'
        ) + duplicateIndexString
      );
    } else if (this.notAscendingSort(this.scoringCriteriaRangeList.value)) {
      console.log(this.notAscendingSort(this.scoringCriteriaRangeList.value));
      this.global.alertService.showError(
        this.translateService.instant(
          'scoring-criteria.msg.error.notAscendingResult'
        )
      );
    }
  }

  public showDuplicatePosition(array: any, mindups: any): any[] {
    mindups = mindups || 1;
    let result = [];
    const positions = {};

    array.forEach((value, position) => {
      positions[value] = positions[value] || [];
      positions[value].push(position);
    });

    Object.keys(positions).forEach(value => {
      const positionArray = positions[value];
      if (positionArray.length > mindups) {
        result = result.concat(positionArray);
      }
    });
    return result.sort();
  }

  public hasDuplicate(array: any): boolean {
    const valueSoFar = Object.create(null);
    for (const data of array) {
      const value = data.result;
      if (value in valueSoFar) {
        return true;
      }
      valueSoFar[value] = true;
    }
    return false;
  }

  public notAscendingSort(scoringCriteriaRangeList: any): boolean {
    const resultList = [];
    scoringCriteriaRangeList.forEach(scoringCriteriaRange => {
      resultList.push(scoringCriteriaRange.result);
    });
    for (const [index, resultI] of resultList.entries()) {
      for (const resultJ of resultList) {
        this.log.debug(resultJ);
        if (resultI > resultList[index + 1]) {
          return true;
        }
      }
    }
    return false;
  }

  public doCancel(): void {
    this.router.navigate(['/pages/scoring-criteria/']);
  }

  public get formGroupControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
}
