import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { WidgetService } from '../widgets.service';
import { WidgetOrderStatusResponse } from './app-widget-order-status.response';
@Component({
  templateUrl: './app-widget-order-status.component.html',
  styleUrls: ['./app-widget-order-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetOrderStatusComponent extends BaseComponentComponent {
  public totalOrder: number;
  public orderDataList: Array<any>;
  public orderDataBadge = {
    totalDraft: 'SECONDARY',
    totalWaitingApproval: 'WARNING',
    totalWaitingConfirmation: 'WARNING',
    totalRevision: 'FEEDBACK',
    totalCanceled: 'DANGER',
    totalVendorRevision: 'FEEDBACK',
    totalRejected: 'DANGER',
    totalClosed: 'SUCCESS',
    totalOnProgress: 'PROGRESS',
    totalDone: 'SUCCESS'
  };
  public routerParamFilter = {
    totalDraft: 'DRAFT',
    totalWaitingApproval: 'WAITING_APPROVAL',
    totalWaitingConfirmation: 'WAITING_CONFIRMATION',
    totalRevision: 'REVISION',
    totalCanceled: 'CANCELD',
    totalVendorRevision: 'VENDOR_REVISION',
    totalRejected: 'REJECTED',
    totalOnProgress: 'ON_PROGRESS',
    totalClosed: 'CLOSED',
    totalDone: 'DONE'
  };
  constructor(public widgetService: WidgetService) {
    super('app-widget-order-status');
  }

  public onInit(): void {
    this.getAndSetPrStatusInformation();
  }

  public getAndSetPrStatusInformation(): void {
    this.httpClientService
      .get<WidgetOrderStatusResponse>('/widget-order-status/index')
      .subscribe(response => {
        if (response.totalOrder) {
          this.totalOrder = response.totalOrder;
        }
        this.orderDataList = Object.keys(response).map((key: string) => {
          const strKey = key.slice(5);
          return {
            value: response[key],
            badge: this.orderDataBadge[key],
            filter: this.routerParamFilter[key],
            translationKey:
              this.moduleCode +
              '.' +
              strKey.charAt(0).toLowerCase() +
              strKey.slice(1)
          };
        });
        this.orderDataList.splice(0, 1);
        this.setStateReady();
      });
  }

  public goToMoreDetail(data): void {
    this.global.routerParams.set('filter', data.filter);
    this.router.navigate(['/pages/order']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
