import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Tc } from 'src/app/core/bean/tc';
import { TableResponseModel } from '../../../core/components/table/model/table-response-model';

@Component({
  templateUrl: './popup-order-choose-term.component.html'
})
export class PopupOrderChooseTermComponent extends BaseComponentComponent {
  @Input() tcList: Tc[] = [];
  @Input() selectedTcList: Tc[] = [];
  @Output() onChange: EventEmitter<Tc> = new EventEmitter();

  public tableResponse: TableResponseModel<Tc>;

  constructor(public activeModal: NgbActiveModal) {
    super('order');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.term'
      },
      {
        field: 'description',
        header: 'table.column.description'
      }
    ]);

    this.tableResponse.setRecordList(this.tcList);
    this.tableResponse.setSelectedRowList(this.selectedTcList);
    this.tableResponse.reload();
  }

  public doChoose(): void {
    if (
      this.tableResponse.selectedRow.getSelectedRecordList() &&
      this.tableResponse.selectedRow.getSelectedRecordList().length === 1
    ) {
      this.onChange.emit(
        this.tableResponse.selectedRow.getSelectedRecordList()[0]
      );
    } else {
      this.global.alertService.showError(
        'order.alert.msg.chooseOneTerm',
        '.popup-order-term'
      );
    }
  }
}
