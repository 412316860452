import { Component, ElementRef, Optional, Renderer2 } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import {
  BaseValueAccessorComponent,
  makeProvider
} from '../../base/angular/base-value-accessor.component';
@Component({
  selector: 'app-text-area',
  templateUrl: './app-text-area.component.html',
  styleUrls: ['./app-text-area.component.scss'],
  providers: makeProvider(AppTextAreaComponent)
})
export class AppTextAreaComponent extends BaseValueAccessorComponent<string> {
  cols = 10;
  rows = 5;
  divAppTextAreaElement: any;
  textAreaElement: any;
  previousTestValue: any;
  constructor(
    @Optional() controlContainer: ControlContainer,
    elementRef: ElementRef,
    public renderer2: Renderer2
  ) {
    super('app-textarea', controlContainer, elementRef);
  }

  onInitBaseValueAccessor(): void {
    this.setAppTextAreaElement();
    if (!this.ISVIEW) {
      this.setMaxLength();
      this.setColsAndRows();
    }
  }

  public setAppTextAreaElement(): void {
    this.divAppTextAreaElement = this.elementRef.nativeElement.children[0];
  }

  public setMaxLength(): void {
    if (!this.maxLength) {
      if (this.isTinySize) {
        this.maxLength = 128;
      } else if (this.isSmallSize) {
        this.maxLength = 256;
      } else if (this.isMediumSize) {
        this.maxLength = 512;
      } else if (this.isLongSize) {
        this.maxLength = 2408;
      }
    }
  }

  public setColsAndRows(): void {
    if (this.isLongSize) {
      this.cols = 13;
      this.rows = 6;
    } else if (this.isSmallSize) {
      this.cols = 7;
      this.rows = 4;
    } else if (this.isTinySize) {
      this.cols = 4;
      this.rows = 3;
    }
  }
}
