import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Module } from '../../core/bean/module';
import { NumberGeneratorValue } from '../../core/bean/number-generator-value';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
@Component({
  templateUrl: './number-generator-detail.component.html'
})
export class NumberGeneratorDetailComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<NumberGeneratorValue>;
  public module: Module;
  constructor(translateService: TranslateService) {
    super('number-generator', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildTableResponse();
    this.doSetCustomData();
    this.setStateReady();
  }

  public doSetDataFromRouterParams(): void {
    this.module = this.global.routerParams.get('module');
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'numberGenerator.code', header: 'table.column.code', customClass: 'text-center' },
      { field: 'format', sortable: false, header: 'table.column.format' }
    ]);
  }

  public doSetCustomData(): void {
    this.tableResponse.setCustomData(this.module.id);
  }

  public doEdit(numberGeneratorValue: NumberGeneratorValue): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('module', this.module);
    this.global.routerParams.set('numberGeneratorValue', numberGeneratorValue);
    this.router.navigate(['/pages/number-generator/edit']);
  }

  public doCancel(): void {
    this.router.navigate(['/pages/number-generator']);
  }
}
