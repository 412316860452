import { DatePipe } from '@angular/common';
import { Global } from 'src/app/core/global/global';
import { ArrayUtils } from 'src/app/core/utils';
import { TableFieldModel } from '../model/table-field-model';
import { TableState } from '../model/table-state';
export const searchTableRecords = (
  state: TableState,
  records: Array<any>
): Array<any> => {
  const global = state.injector.get(Global);
  const arrayDateFormat = [
    global.config.parameterModel.dateFormatShort,
    global.config.parameterModel.datetimeFormatShort,
    global.config.parameterModel.dateFormatLong,
    global.config.parameterModel.datetimeFormatLong
  ];
  if (state.tableRequest.globalFilter.toLowerCase()) {
    records = records.filter((record: any) => {
      let isMatch = false;

      const newRecord =
        state.store.recordMap.get(JSON.stringify(record)) || record;

      state.model.columns.forEach((column: TableFieldModel) => {
        if (!isMatch) {
          let value: any = ArrayUtils.resolveFieldData(
            newRecord,
            column.field,
            column.datamap
          );

          if (typeof column.format !== 'undefined' && column.format < 4) {
            const datePipe = state.injector.get(DatePipe);
            value =
              (value &&
                datePipe.transform(value, arrayDateFormat[column.format])) ||
              value;
          }

          const isDateValue = new Date(value).toDateString() !== 'Invalid Date';

          if (
            !isMatch &&
            value &&
            (typeof value === 'number'
              ? value.toString()
              : isDateValue
              ? value.toString()
              : value
            )
              .toLowerCase()
              .includes(state.tableRequest.globalFilter.toLowerCase())
          ) {
            isMatch = true;
          }
        }
      });
      if (isMatch) {
        return newRecord;
      }
    });
  }
  return records;
};
