import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { WorkPlan } from '../../core/bean/work-plan';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';

@Component({
    templateUrl: 'reporting-sla-work-plan.component.html'
})

export class ReportingSlaWorkPlanComponent extends BaseModuleComponent {
    tableResponse: TableResponseModel<WorkPlan>;

    @ViewChild(AppTableComponent) table: AppTableComponent;
    constructor(translateService: TranslateService) {
        super('reporting-sla-work-plan', translateService);
    }

    onInit(): void {
        this.tableResponse = new TableResponseModel(
            this.moduleCode, [
                { field: 'number', header: 'table.column.workPlan.number' },
                { field: 'name', header: 'table.column.workPlan.name' },
                { field: 'organization.name', header: 'table.column.workPlan.organization.name' },
                { field: 'workflowModelPrcs.name', header: 'table.column.workPlan.workflowModelPrcs.name' },
                { field: 'processDayTotal', header: 'table.column.workPlan.workflowModelPrcs.processDayTotal' }
            ]);
        this.setStateReady();
    }

    public doDetail(workPlan: WorkPlan) {
        this.global.routerParams.clear();
        this.global.routerParams['todo'] = 'detail';
        this.global.routerParams['workPlan'] = workPlan;
        this.router.navigate(['/pages/reporting-sla-work-plan/detail']);
    }

}
