import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../../base/angular/base-component.component';
import { Response } from '../../../model/response-model';
import { Validators } from '../../../validators';
import { PopupEmailModel } from './model/popup-email-model';
@Component({
  templateUrl: 'app-popup-email.component.html',
  styleUrls: ['./app-popup-email.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupEmailComponent extends BaseComponentComponent {
  @Input() mailer: any;
  @Input() url: string;
  @Input() to: string;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  constructor() {
    super('app-popup-email');
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      to: [null],
      ccList: [null],
      subject: [null, Validators.required()],
      content: [null, Validators.required()]
    });
  }

  setFormGroup(): void {
    if (this.to) {
      this.formGroup.patchValue({ to: this.to });
      this.formGroup.get('to').disable();
    }
  }

  doSend(): void {
    this.validate();
    const popupEmailModel: PopupEmailModel =
      this.global.copyFormAttributeToModel(
        new PopupEmailModel(),
        this.formGroup
      );
    popupEmailModel.to = this.formGroup.get('to').value;
    if (this.formGroup.valid) {
      this.httpClientService
        .post(this.url, popupEmailModel)
        .subscribe((response: Response<any>) => {
          this.onChange.emit(response);
        });
    }
  }
}
