<app-popup
  header="contract-history.popup.newEmail.title"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="false"
>
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'contract-history.popup.newEmail.form.name' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-text-field
          formControlName="name"
          maxLength="255"
          size="XL"
        ></app-text-field>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'contract-history.popup.newEmail.form.email' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-text-field
          formControlName="email"
          maxLength="64"
          size="XL"
        ></app-text-field>
      </div>
    </div>
    <div class="form-group row text-sm-left">
      <label class="col-sm-3">
        {{ 'contract-history.popup.newEmail.form.position' | translate }}
      </label>
      <div class="col-sm-9 text-left">
        <app-dropdown-select
          formControlName="position"
          [optionList]="positionOptionList"
          type="COMBOBOX"
          size="XL"
        ></app-dropdown-select>
      </div>
    </div>
  </form>
  <div class="button-group button-group-center mt-5">
    <app-button [outline]="true" color="SECONDARY" (onClick)="doCancel()">{{
      'app.button.cancel' | translate
    }}</app-button>
    <app-button (onClick)="doAdd()">{{
      'app.button.add' | translate
    }}</app-button>
  </div>
</app-popup>
