import { BaseEntity } from '../base/base-entity';
import { BudgetAllocation } from '../bean/budget-allocation';
import { Currency } from '../bean/currency';
import { Item } from '../bean/item';
import { PrItem } from '../bean/pr-item';
import { Procurement } from '../bean/procurement';

export class ProcurementItemEntity extends BaseEntity {
  crudOperation: number;
  receivedItemPerQuantity: string;
  percentageValue: string;
  status: number;

  procurement: Procurement = new Procurement();
  item: Item = new Item();
  budgetAllocation: BudgetAllocation = new BudgetAllocation();
  currency: Currency = new Currency();
  prItem: PrItem = new PrItem();
  quantity: number;
  price: number;
  totalPrice: number;
  isAllocated: boolean;
  isRefute: boolean;
}
