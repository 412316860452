<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="vendor-reactivation.title"
      detail="vendor-reactivation.breadcrumb.detail"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-reactivation.table.column.name' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p>{{ vendorReactivationResponse.vendor.name }}</p>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-reactivation.table.column.registNo' | translate
          }}</label>
          <div class="col-sm-9 text-left">
            <p>{{ vendorReactivationResponse.vendor.registrationNumber }} </p>
          </div>
        </div>

        <br />
        <h5 class="separator">{{
          'vendor-reactivation.form.info' | translate
        }}</h5>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-reactivation.form.reason' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            <p *ngIf="vendorReactivationResponse.vendorReactivation != null">
              {{ vendorReactivationResponse.vendorReactivation.reason }}
            </p>
            <p *ngIf="vendorReactivationResponse.vendorReactivation == null">
              -</p
            >
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-reactivation.form.upload' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            <p
              *ngIf="
                vendorReactivationResponse.vendorReactivation?.file == null
              "
            >
              -</p
            >
            <a
              *ngIf="
                vendorReactivationResponse.vendorReactivation?.file != null
              "
              href="#"
              (click)="
                doDownload(
                  $event,
                  '/vendor-reactivation/file/view/' +
                    vendorReactivationResponse.vendorReactivation.file
                      .uploadedFileName +
                    '/' +
                    global.appConstant.fileType.DOC_VENDOR_REACTIVATION,
                  vendorReactivationResponse.vendorReactivation.file.fileName
                )
              "
            >
              <p
                >{{
                  vendorReactivationResponse.vendorReactivation.file.fileName
                }}
              </p>
            </a>
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'vendor-reactivation.form.approvalnote' | translate
          }}</label>
          <div class="col-sm-6 text-left">
            <p *ngIf="vendorReactivationResponse.approvalNote">
              {{ vendorReactivationResponse.approvalNote }}</p
            >
            <p *ngIf="!vendorReactivationResponse.approvalNote"> - </p>
          </div>
        </div>

        <div
          *ngIf="!vendorReactivationResponse.isUserApproval"
          class="row justify-content-center mt-5"
        >
          <app-button
            mode="BYPASS"
            color="SECONDARY"
            (onClick)="doCancel()"
            [disabled]="formSaving"
          >
            {{ 'app.button.back' | translate }}
          </app-button>
        </div>
      </form>
    </app-card>
    <app-approval-prcs
      *ngIf="vendorReactivationResponse.isUserApproval"
      [workflowModelPrcs]="workflowModelPrcs"
    >
    </app-approval-prcs>
  </div>
</div>
