import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorCompanyOrganizationHistory } from '../../core/bean/vendor-company-organization-history';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { CompanyProfileDTO } from '../company-profile/dto/company-profile.dto';
import { AppPopupShareHoldersComponent } from './app-popup-share-holders.component';
@Component({
  templateUrl: './tab-company-organization.component.html'
})
export class TabCompanyOrganizationComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<VendorCompanyOrganizationHistory>;
  public tableResponseCommissioner: TableResponseModel<VendorCompanyOrganizationHistory>;
  public tableResponseDirector: TableResponseModel<VendorCompanyOrganizationHistory>;
  public tableResponseShareHolders: TableResponseModel<VendorCompanyOrganizationHistory>;
  public companyProfileDTO: CompanyProfileDTO;
  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('tab-company-organization', translateService);
  }

  onInit(): void {
    this.buildTableCommisioner();
    this.buildTableDirector();
    this.buildTableShareHolders();
    this.doSetCustomDataCommissioner();
    this.doSetCustomDataDirector();
    this.doSetCustomDataShareHolder();
    this.model.setStateReady();
  }

  public buildTableCommisioner(): void {
    this.tableResponseCommissioner = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      {
        field: 'idNumber',
        header: 'table.column.idNumber',
        customClass: 'text-center'
      },
      {
        field: 'file.fileName',
        header: 'table.column.file',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName',
        customClass: 'text-left'
      },
      {
        field: 'birthDate',
        header: 'table.column.DOB',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: 'vendorPosition.name',
        header: 'table.column.position',
        customClass: 'text-center'
      }
    ]);
  }

  public buildTableDirector(): void {
    this.tableResponseDirector = new TableResponseModel(this.moduleCode, [
      { field: 'name', header: 'table.column.name' },
      {
        field: 'idNumber',
        header: 'table.column.idNumber',
        customClass: 'text-center'
      },
      {
        field: 'file.fileName',
        header: 'table.column.file',
        format: FieldFormatEnum.DownloadableFile,
        urlFile: 'file.uploadedFileName',
        customClass: 'text-left'
      },
      {
        field: 'birthDate',
        header: 'table.column.DOB',
        format: FieldFormatEnum.ShortDate,
        customClass: 'text-center'
      },
      {
        field: 'vendorPosition.name',
        header: 'table.column.position',
        customClass: 'text-center'
      }
    ]);
  }

  public buildTableShareHolders(): void {
    this.tableResponseShareHolders = new TableResponseModel(this.moduleCode, [
      { field: '0.name', header: 'table.column.name' },
      {
        field: '0.idNumber',
        header: 'table.column.idNumber',
        customClass: 'text-center'
      },
      {
        field: '1',
        header: 'table.column.shareValue',
        customClass: 'text-right'
      }
    ]);
  }

  public doSetCustomDataCommissioner(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponseCommissioner.setCustomData(customData);
  }

  public doSetCustomDataDirector(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponseDirector.setCustomData(customData);
  }

  public doSetCustomDataShareHolder(): void {
    const customData = { id: this.vendorHistoryId };
    this.tableResponseShareHolders.setCustomData(customData);
  }

  public doDetail(vendorShareHolder: any): void {
    this.appPopupService.open(
      AppPopupShareHoldersComponent,
      { vendorShareHolder: vendorShareHolder[0] },
      { size: 'lg', backdrop: 'static' }
    );
  }
}
