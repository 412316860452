import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Wanprestasi } from '../../core/bean/wanprestasi';
@Component({
  templateUrl: './app-popup-tab-wanprestasi-history.component.html'
})
export class AppPopupTabWanprestasiHistoryComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() wanprestasi: Wanprestasi;

  constructor() {
    super('app-popup-tab-wanprestasi-history');
  }

  public onInit(): void {
    this.setStateReady();
  }
}
