import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { WorkflowInfoResponse } from './workflow-info.response';
@Component({
  selector: 'app-workflow-info',
  templateUrl: './app-workflow-info.component.html',
  styleUrls: ['./app-workflow-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWorkflowInfoComponent extends BaseComponentComponent {
  @Input() public stringUrl: string;
  @Input() public moduleGroupCode: string;
  @Input() public position: string;
  @Output() public onChange: EventEmitter<string> = new EventEmitter();
  public dataList = [];
  public totalRecords = 0;
  constructor() {
    super('app-workflow-info');
  }

  public onInit(): void {
    this.httpClientService
      .get<WorkflowInfoResponse>(this.stringUrl + '/' + this.moduleGroupCode)
      .subscribe(data => {
        const dataModuleList = data[Object.keys(data)[1]];
        dataModuleList.forEach((dataModule: any) => {
          this.dataList.push({
            name: dataModule[Object.keys(dataModule)[0]].name,
            code: dataModule[Object.keys(dataModule)[0]].code,
            totalRecords: dataModule.recordCount
          });
          this.totalRecords = this.totalRecords + dataModule.recordCount;
        });
      });
  }

  public onKeyUp(event: KeyboardEvent): void {
    this.log.debug(event);
  }

  public onKeyDown(event: KeyboardEvent): void {
    this.log.debug(event);
  }
}
