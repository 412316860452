import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../../core/base/angular/base-module.component';
import { TabModel } from '../../../../core/model/tab/tab-model';
import { TabResponseModel } from '../../../../core/model/tab/tab-response-model';
import { Validators } from '../../../../core/validators';
@Component({
  templateUrl: './tab-two.component.html',
  styleUrls: ['./tab-two.component.scss']
})
export class ExampleTabTwoComponent extends BaseModuleComponent {
  public tabResponse: TabResponseModel;
  public tabTwo: TabModel;
  constructor(translate: TranslateService) {
    super('tab-two', translate);
  }

  onInit(): void {
    this.setTabResponseAndCurrentTab();
    this.buildFormGroup();
  }

  public setTabResponseAndCurrentTab(): void {
    this.tabResponse = this.global.routerParams.get('tabResponse');
    this.tabTwo = this.tabResponse.currentTab;
  }

  public buildFormGroup(): void {
    if (!this.tabTwo.isFormGroupReady) {
      this.tabTwo.formGroup = this.formBuilder.group({
        about: [null, Validators.required()]
      });
    }
  }
}
