import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppApprovalPrcsXModule } from '../../core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppBadgeCatalogModule } from '../../core/components/app-badge-catalog/app-badge-catalog.module';
import { AppItemRequestModule } from '../../core/components/app-item-request/app-item-request.module';
import { AppProcurementInfoModule } from '../../core/components/app-procurement-info/app-procurement-info.module';
import { AppWorkflowStepInfoModule } from '../../core/components/app-workflow-step-info/app-workflow-step-info.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { SubmitQuotationEditComponent } from './submit-quotation-edit.component';
import { SubmitQuotationPopupSubmissionDetailComponent } from './submit-quotation-popup-submission-detail.component';

export const routes = [
  {
    path: 'add',
    component: SubmitQuotationEditComponent,
    data: { breadcrumb: 'submit-quotation.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: SubmitQuotationEditComponent,
    data: { breadcrumb: 'submit-quotation.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: SubmitQuotationEditComponent,
    data: { breadcrumb: 'submit-quotation.breadcrumb.detail' }
  },
  {
    path: 'detail/:token',
    component: SubmitQuotationEditComponent,
    data: { breadcrumb: 'submit-quotation.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppItemRequestModule,
    AppProcurementInfoModule,
    AppApprovalPrcsXModule,
    AppWorkflowStepInfoModule,
    AppBadgeCatalogModule
  ],
  declarations: [
    SubmitQuotationEditComponent,
    SubmitQuotationPopupSubmissionDetailComponent
  ]
})
export class SubmitQuotationModule {}
