import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppSelectModule } from '../../app-select/app-select.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupVersionHistoryComponent } from './app-popup-version-history.component';
import { AppPopupVersionHistoryService } from './app-popup-version-history.service';

@NgModule({
  imports: [VendorModule, AppPopupModule, AppSelectModule],
  declarations: [AppPopupVersionHistoryComponent],
  entryComponents: [AppPopupVersionHistoryComponent],
  providers: [AppPopupVersionHistoryService],
  exports: [AppPopupVersionHistoryComponent]
})
export class AppPopupVersionHistoryModule {}
