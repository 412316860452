import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppBinaryTreeComponent } from './app-binary-tree.component';
@NgModule({
  imports: [VendorModule, AppButtonModule],
  declarations: [AppBinaryTreeComponent],
  exports: [AppBinaryTreeComponent]
})
export class AppBinaryTreeModule {}
