import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { VictimDiagnoseComponent } from './victim-diagnose.component';
import { VictimDiagnoseEditAddComponent } from './victim-diagnose-edit-add.component';
const routes: Routes = [
  { path: '', component: VictimDiagnoseComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: VictimDiagnoseEditAddComponent,
    data: { breadcrumb: 'victim-diagnose.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: VictimDiagnoseEditAddComponent,
    data: { breadcrumb: 'victim-diagnose.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [VictimDiagnoseComponent, VictimDiagnoseEditAddComponent]
})
export class VictimDiagnoseModule { }
