<app-popup [isLoading]="formLoading">
  <div class="letter-box" *ngIf="!formLoading">
    <div class="letter-header">
      <img
        [attr.src]="
          global.config.BACKEND_ADDRESS +
            '/procurement-reg-vendor/file/view/' +
            logo.uploadedFileName +
            '/' +
            logo.fileType.code
            | resolveImgSrc
            | async
        "
        attr.alt="image"
      />
    </div>
    <div class="letter-body mb-4">
      <p class="letter-location-date mb-2"
        >{{
          companyLocation +
            ', ' +
            (procurementVendor.registeredDate
              | date: global.config.parameterModel.dateFormatLong)
        }}
      </p>
      <h2 class="my-4 text-center" style="font-style: italic">
        Congratulations
      </h2>
      <h2 class="my-4 text-center">{{ procurementVendor.vendor.name }}</h2>
      <h5 class="text-center mx-4">
        <h5 class="mb-1">
          Your company has successfully registered for procurement and eligible
          to proceed to the next process.
        </h5>
        <h5 class="mb-1"> Your company is registered on the </h5>
        <h5 class="mb-1">
          <span>
            <strong
              >{{ procurement.code + ' - ' + procurement.name + '.' }}
            </strong>
          </span>
        </h5>
        <h5 class="mb-1">
          Please pay attention to the procurement schedule. If any step is
          skipped, you will not be able to follow the
        </h5>
        <h5 class="mb-1"> next procurement process. </h5>
      </h5>
    </div>
    <div class="letter-footer">
      <h5 class="text-center mb-2">Regards,</h5>
      <img
        [attr.src]="
          global.config.BACKEND_ADDRESS +
            '/procurement-reg-vendor/file/view/' +
            logo.uploadedFileName +
            '/' +
            logo.fileType.code
            | resolveImgSrc
            | async
        "
        attr.alt="image"
      />
    </div>
  </div>
  <ng-template #modalFooter let-activeModal>
    <div class="col-12 text-center">
      <app-button
        color="SECONDARY"
        [outline]="true"
        (onClick)="activeModal.close(true)"
        >{{ 'app.button.close' | translate }}</app-button
      >
      <app-button color="PRIMARY" (onClick)="doPrint()">
        <em class="pir pi-printer"></em>&nbsp;
        {{ 'procurement-reg-vendor.button.printPage' | translate }}</app-button
      >
    </div>
  </ng-template>
</app-popup>
