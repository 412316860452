import { Component } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  templateUrl: './app-widget-welcome.component.html',
  styleUrls: ['./app-widget-welcome.component.scss']
})
export class AppWidgetWelcomeComponent extends BaseComponentComponent {
  public currentDate: Date = new Date();
  constructor() {
    super('app-widget-welcome');
  }

  public onInit(): void {
    this.global.widgetLoadingStatus.set(this.moduleCode, true);
    this.setStateReady();
  }
}
