import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppChartModule } from '../../components/app-chart/app-chart.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetContractPerStatusComponent } from './app-widget-contract-per-status.component';
@NgModule({
  imports: [VendorModule, AppWidgetModule, AppChartModule],
  declarations: [AppWidgetContractPerStatusComponent],
  entryComponents: [AppWidgetContractPerStatusComponent],
  exports: [AppWidgetContractPerStatusComponent]
})
export class AppWidgetContractPerStatusModule {
  constructor(widgetService: WidgetService) {
    widgetService.register('app-widget-contract-per-status', AppWidgetContractPerStatusComponent);
  }
}
