import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { ReportRecapColumn } from '../../bean/report-recap-column';
import { AppPopupProgressBarDownloadComponent } from '../../components/app-popup/app-popup-progress-bar-download/app-popup-progress-bar-download.component';
import { ProgressBarDownloadModel } from '../../components/app-popup/app-popup-progress-bar-download/progress-bar-download-model';
import { AppPopupService } from '../../components/app-popup/app-popup.service';
import { ToastService } from '../../components/app-toast/app-toast.service';
import { TableResponseModel } from '../../components/table/model/table-response-model';
import { RecapPrintService } from '../../services/recap-print.service';
import { BudgetUsedRecapView } from '../../view/entity/bean/budget-used-recap-view';
import { ReportRecapBudgetUsedRequest } from './report-recap-budget-used-request';
import { ReportRecapBudgetUsedPopupChooseColumnComponent } from './report-recap-budget-used-popup-choose-column.component';

@Component({
  templateUrl: './report-recap-budget-used-popup-full-view.component.html'
})
export class ReportRecapBudgetUsedPopupFullViewComponent extends BaseComponentComponent {
  @Input() reportRecapColumnList: ReportRecapColumn[];
  @Input() customData: any;
  @Input() reportBuilderId: number;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  public progressBarDownload = new ProgressBarDownloadModel();
  public httpRequest: Subscription;

  public tableResponse: TableResponseModel<BudgetUsedRecapView>;

  constructor(
    public activeModal: NgbActiveModal,
    public appPopupService: AppPopupService,
    public translateService: TranslateService,
    public recapPrintService: RecapPrintService,
    public toastService: ToastService
  ) {
    super('report-recap-budget-used');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.orderNumber'
      },
      {
        field: 'postDate',
        header: 'table.column.orderDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'requiredDate',
        header: 'table.column.orderRequireDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'title',
        header: 'table.column.title'
      },
      {
        field: 'organization.name',
        header: 'table.column.department'
      },
      {
        field: 'orderType.name',
        header: 'table.column.orderType'
      },
      {
        field: 'description',
        header: 'table.column.description'
      },
      {
        field: 'amount',
        header: 'table.column.orderCost',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: ' text-right'
      },
      {
        field: 'vendor.name',
        header: 'table.column.vendor'
      },
      {
        field: 'confirmationDate',
        header: 'table.column.confirmationDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'orderStatus.name',
        header: 'table.column.status',
        plugins: {
          name: 'badge',
          colorField: 'orderStatus.code',
          pill: true,
          colorMap: {
            DRAFT: 'SECONDARY',
            REVISION: 'FEEDBACK',
            WAITING_APPROVAL: 'WARNING',
            WAITING_CONFIRMATION: 'WARNING',
            ON_PROGRESS: 'PROGRESS',
            VENDOR_REVISION: 'FEEDBACK',
            REJECTED: 'DANGER',
            CANCELED: 'DANGER',
            CLOSED: 'DANGER',
            DONE: 'SUCCESS'
          }
        }
      },
      {
        field: 'lastDateStatus',
        header: 'table.column.lastDateStatus',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'creator',
        header: 'table.column.creator'
      },
      {
        field: 'approval',
        header: 'table.column.approval'
      },
      {
        field: 'approvalDate',
        header: 'table.column.approvalDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'item',
        header: 'table.column.item'
      },
      {
        field: 'shippingAddress',
        header: 'table.column.shippingAddress'
      },
      {
        field: 'totalStage',
        header: 'table.column.totalStage',
        className: 'text-right'
      },
      {
        field: 'totalTermin',
        header: 'table.column.totalTermin',
        className: 'text-right'
      },
      {
        field: 'totalSla',
        header: 'table.column.totalSla',
        className: 'text-right'
      }
    ]);
    this.tableResponse.setCustomData(this.customData);
  }

  public doMinimize(): void {
    this.onChange.emit();
  }

  public doExport(): void {
    if (this.reportBuilderId) {
      this.appPopupService
        .open(AppPopupProgressBarDownloadComponent, {
          model: this.progressBarDownload
        })
        .subscribe(isCancel => {
          if (isCancel) {
            this.httpRequest.unsubscribe();
          }
        });
      const request = new ReportRecapBudgetUsedRequest();
      request.reportRecapColumnList = this.reportRecapColumnList;
      request.customData = JSON.stringify(this.customData);

      this.recapPrintService
        .print('/report-recap-budget-used/print', request)
        .subscribe(
          response => {
            if (response) {
              this.progressBarDownload.setProgress(100);
              setTimeout(() => {
                this.global.modalService.downloadSuccess();
                this.onChange.emit();
              }, 500);
            }
          },
          error => {
            error.error.text().then(text => this.toastService.showError(text));
          }
        );
    } else {
      this.appPopupService.open(
        ReportRecapBudgetUsedPopupChooseColumnComponent,
        {
          customData: this.customData,
          reportRecapColumnList: this.reportRecapColumnList
        }
      );
    }
  }
}
