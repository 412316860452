<div class="row main-row">
    <div class="col-lg-12 mb-4">
        <app-card header="approval-workflow.title" detail="{{workflow.name}}"
            [isLoading]="formLoading" [isSaving]="formSaving">
            <app-table [tableResponse]="tableResponse" [isServerSide]="true"
                customData={{workflow.id}}
                stringUrl="/approval-workflow/workflow-model/index">
                <ng-template #actionButtons let-rowData>
                    <app-button size="TN" (onClick)="doAdd(rowData)"
                        title="{{ 'approval-workflow.button.approval' | translate }}">
                        <em class="fas fa-user-plus"></em>
                    </app-button>
                </ng-template>
            </app-table>
            <div class="button-group button-group-center mt-5">
                <app-button mode="BYPASS" color="SECONDARY"
                    (onClick)="doCancel()">
                    {{ 'app.button.back' | translate }}
                </app-button>
            </div>
        </app-card>
    </div>
</div>