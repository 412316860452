import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTooltipWrapperModule } from '../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { AppTimeComponent } from './app-time.component';
@NgModule({
  imports: [VendorModule, AppTooltipWrapperModule],
  declarations: [AppTimeComponent],
  exports: [AppTimeComponent]
})
export class AppTimeModule {}
