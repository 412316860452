import { Action } from '../interfaces/action.interface';

export const ADD_TAB_ITEM = `[ TAB ]: Add Tab Item`;
export const CHANGE_TAB_ITEM_DISABLED = `[ TAB ]: Change Tab Item Disabled`;
export const CHANGE_TAB_ITEM_SELECTED = `[ TAB ]: Change Tab Item Selected`;

export class AddTabItem implements Action {
  readonly type = ADD_TAB_ITEM;
  constructor(
    public payload: {
      id: string;
      tabItemElement: HTMLElement;
      selected: boolean;
      disabled: boolean;
      isDynamic: boolean;
    }
  ) {}
}

export class ChangeTabItemDisabled implements Action {
  readonly type = CHANGE_TAB_ITEM_DISABLED;
  constructor(
    public payload: {
      id: string;
      disabled: boolean;
    }
  ) {}
}

export class ChangeTabItemSelected implements Action {
  readonly type = CHANGE_TAB_ITEM_SELECTED;
  constructor(
    public payload: {
      id: string;
      selected: boolean;
      isDynamic: boolean;
    }
  ) {}
}

export type All = AddTabItem | ChangeTabItemDisabled | ChangeTabItemSelected;
