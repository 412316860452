<app-layout
  [isLoading]="formLoading"
  header="doc-retrieval-response.breadcrumb.index"
  [backTo]="urlBackOutside"
>
  <ng-template #additionalTitle *ngIf="!formLoading && isShowBadgeStatus">
    <app-badge-catalog
      class="cml-2"
      *ngIf="procurement.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge class="cml-2" [color]="badgeColor[docRetrievalStatusCode]">
      {{
        'doc-retrieval-response.status.' + docRetrievalStatusCode.toLowerCase()
          | translate
      }}
    </app-badge>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-alert-x
        color="DANGER"
        *ngIf="
          !formLoading &&
          (procurement?.procurementStatus?.code ===
            global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm
                .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL ||
            procurement?.procurementStatus?.code ===
              global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT)
        "
      >
        {{ canceledOrFailedMessage | translate }}
      </app-alert-x>
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        *ngIf="!formLoading"
        novalidate
      >
        <app-alert-x
          color="WARNING"
          *ngIf="
            docRetrievalStatusCode ===
            global.appConstant.pm.DOC_RETRIEVAL_STATUS_NEW
          "
        >
          {{ 'doc-retrieval-response.alert.warning' | translate }}
        </app-alert-x>

        <app-alert-x
          *ngIf="
            docRetrievalStatusCode ===
            global.appConstant.pm.DOC_RETRIEVAL_STATUS_DONE
          "
        >
          {{ 'doc-retrieval-response.alert.info' | translate }}
        </app-alert-x>

        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="workflowStepInfoModel"
        >
        </app-workflow-step-info>
        <app-card
          header="doc-retrieval-response.procDetail.general.title"
          tips="doc-retrieval-response.procDetail.general.tips"
        >
          <app-procurement-info [procurementId]="procurementId">
          </app-procurement-info>
        </app-card>

        <app-item-request
          [objectList]="procurementItemList"
          [isView]="true"
          [isShowPrice]="false"
          [itemTypeList]="itemTypeList"
        >
        </app-item-request>

        <app-card *ngIf="!formLoading">
          <app-table-xx
            [model]="tableResponse"
            header="doc-retrieval-response.document.general.title"
            tips="doc-retrieval-response.document.general.tips"
          >
          </app-table-xx>
          <div class="button-group full-button">
            <app-button
              (onClick)="doDownloadAll()"
              [disabled]="
                isDisabledDownloadAllButton() ||
                procurement?.procurementStatus?.code ===
                  global.appConstant.pm
                    .PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL ||
                procurement?.procurementStatus?.code ===
                  global.appConstant.pm
                    .PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL ||
                procurement?.procurementStatus?.code ===
                  global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
              "
            >
              <em class="pir pi-download-line"></em>&nbsp;
              {{ 'doc-retrieval-response.button.downloadAll' | translate }}
            </app-button>
          </div>
        </app-card>
      </form>
    </div>
  </div>

  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
