import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppActivityHistoryViewModule } from 'src/app/core/components/app-activity-history-view/app-activity-history-view.module';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppApprovalPrcsModule } from 'src/app/core/components/app-approval-prcs/app-approval-prcs.module';
import { AppContractNegotiationViewModule } from 'src/app/core/components/app-contract-negotiation-view/app-contract-negotiation-view.module';
import { AppContractRequestInfoModule } from 'src/app/core/components/app-contract-request-info/app-contract-request-info.module';
import { AppContractRequestViewModule } from 'src/app/core/components/app-contract-request-view/app-contract-request-view.module';
import { AppContractReviewLogModule } from 'src/app/core/components/app-contract-review-log/app-contract-review-log.module';
import { AppVendorInformationModule } from 'src/app/core/components/app-vendor-information/app-vendor-information.module';
import { AppVendorPaymentInformationModule } from 'src/app/core/components/app-vendor-payment-information/app-vendor-payment-information.module';
import { AppWorkDefinitionModule } from 'src/app/core/components/app-work-definition/app-work-definition.module';
import { AppWorkflowStepInfoModule } from 'src/app/core/components/app-workflow-step-info/app-workflow-step-info.module';
import { AppUploadXModule } from 'src/app/core/components/upload/components/app-upload-x/app-upload-x.module';
import { AppApprovalLogModule } from '../../core/components/app-approval-log/app-approval-log.module';
import { AppProcurementInformationViewModule } from '../../core/components/app-procurement-information-view/app-procurement-information-view.module';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupContractTerminationChooseContractComponent } from './app-popup-contract-termination-choose-contract.component';
import { AppPopupContractTerminationTerminateContractComponent } from './app-popup-contract-termination-terminate-contract.component';
import { ContractTerminationEditAddXComponent } from './contract-termination-edit-add-x.component';
import { ContractTerminationEditAddComponent } from './contract-termination-edit-add.component';
import { ContractTerminationPopupContractReviewerComponent } from './contract-termination-popup-contract-reviewer.component';
import { ContractTerminationComponent } from './contract-termination.component';

export const routes = [
  {
    path: '',
    component: ContractTerminationComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'add',
    component: ContractTerminationEditAddXComponent,
    data: { breadcrumb: 'contract-termination.breadcrumb.add' }
  },
  {
    path: 'edit',
    component: ContractTerminationEditAddXComponent,
    data: { breadcrumb: 'contract-termination.breadcrumb.edit' }
  },
  {
    path: 'detail',
    component: ContractTerminationEditAddXComponent,
    data: { breadcrumb: 'contract-termination.breadcrumb.detail' }
  },
  {
    path: 'detail/:token',
    component: ContractTerminationEditAddXComponent,
    data: { breadcrumb: 'contract-termination.breadcrumb.detail' }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AppProcurementInformationViewModule,
    AppVendorPaymentInformationModule,
    AppApprovalLogModule,
    AppContractRequestViewModule,
    AppApprovalPrcsModule,
    AppContractReviewLogModule,
    AppWorkflowStepInfoModule,
    AppApprovalPrcsXModule,
    AppUploadXModule,
    AppContractRequestInfoModule,
    AppVendorInformationModule,
    AppWorkDefinitionModule,
    AppContractNegotiationViewModule,
    AppActivityHistoryViewModule
  ],
  declarations: [
    ContractTerminationComponent,
    ContractTerminationEditAddComponent,
    ContractTerminationEditAddXComponent,
    AppPopupContractTerminationChooseContractComponent,
    ContractTerminationPopupContractReviewerComponent,
    AppPopupContractTerminationTerminateContractComponent
  ],
  entryComponents: [
    AppPopupContractTerminationChooseContractComponent,
    ContractTerminationPopupContractReviewerComponent,
    AppPopupContractTerminationTerminateContractComponent
  ],
  exports: [
    AppPopupContractTerminationChooseContractComponent,
    ContractTerminationPopupContractReviewerComponent,
    AppPopupContractTerminationTerminateContractComponent
  ]
})
export class ContractTerminationModule {}
