import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { Vendor } from '../../../core/bean/vendor';
import { AppPopupService } from '../../../core/components/app-popup/app-popup.service';
import { TabModel } from '../../../core/model/tab/tab-model';
import { CompanyProfileDTO } from '../../company-profile/dto/company-profile.dto';
@Component({
  templateUrl: './categories.component.html'
})
export class CategoriesComponent extends BaseModuleComponent {
  userId = this.global.userSession.user.id;
  companyProfileDTO: CompanyProfileDTO = new CompanyProfileDTO();
  public vendor: Vendor = new Vendor();
  public sessionId = null;
  public categories: TabModel;
  public isPkpDisable = false;
  public isTodoView;

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('manage-catalog', translateService);
  }

  onInit(): void {
    this.setVendor();
    this.buildFormGroup();
    this.getAndSetVendorToFormGroup();
  }

  public setVendor(): void {
    this.categories =
      this.global.routerParams['tabResponse'].tabs.get('CATEGORIES');
    this.isTodoView = this.global.routerParams['tabResponse'].isView;
    this.categories.setIsView(this.isTodoView);
    this.global.routerParams.clear();
  }

  public buildFormGroup(): void {
    if (!this.categories.isFormGroupReady) {
      this.categories.formGroup = this.formBuilder.group({});
    }
  }

  public getAndSetVendorToFormGroup(): void {
    this.setStateReady();
  }
}
