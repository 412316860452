import { BaseEntity } from '../base/base-entity';
import { ProcurementItem } from '../bean/procurement-item';
import { Refutation } from '../bean/refutation';
import { RefutationReview } from '../bean/refutation-review';
import { RefutationSubmissionDoc } from '../bean/refutation-submission-doc';
import { SubmittedStatus } from '../bean/submitted-status';
import { FileObject } from '../components/upload';

export class RefutationVendorEntity extends BaseEntity {
  refutation: Refutation;
  submittedStatus: SubmittedStatus;
  procurementItem: ProcurementItem;
  refutationSubmissionDocList: RefutationSubmissionDoc[];
  isRefute: boolean;
  fileObjectList: FileObject[];
  refutationReview: RefutationReview;
}
