import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { CommitteeDecree } from 'src/app/core/bean/committee-decree';
import { Organization } from 'src/app/core/bean/organization';
import { Pr } from 'src/app/core/bean/pr';
import { AppCommitteeResponse } from 'src/app/core/components/app-committee/app-committee-response';
import { FileUploader } from 'src/app/core/components/upload';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { RouteRequestModel } from 'src/app/core/model/route-request-model';
import { Validators } from 'src/app/core/validators';
import { Committee } from '../../core/bean/committee';
import { CommitteeParticipant } from '../../core/bean/committee-participant';
import { CommitteeType } from '../../core/bean/committee-type';
import { BinaryTreeModel } from '../../core/components/app-committee/model/binary-tree-model';
import { AppTipsComponent } from '../../core/components/app-tips/app-tips.component';
import { CommitteeRequest } from './committee-request';
import { CommitteeResponse } from './committee-response';

@Component({
  templateUrl: './committee-edit-add.component.html'
})
export class CommitteeEditAddComponent
  extends BaseModuleComponent
  implements OnInit
{
  public committee: Committee = new Committee();
  public committeeParticipantList: Array<CommitteeParticipant> = [];
  public typeOptionList: OptionListModel<CommitteeType> = new OptionListModel(
    true,
    'name'
  );
  public binaryTreeModel: BinaryTreeModel = new BinaryTreeModel(false, true);
  public urlBackOutside: string;
  public isShowProcCommittee = false;
  public organizationParam: Object;
  public organizationName: string;
  public committeeResponse: CommitteeResponse = new CommitteeResponse();
  public appCommitteeResponse: AppCommitteeResponse =
    new AppCommitteeResponse();
  @ViewChild('appTips') appTips: AppTipsComponent;
  public fileUploader: FileUploader = new FileUploader(
    '/committee/',
    'committee.form.headerImage',
    this.global.appConstant.fileType.DOC_COMMITTEE_DECREE,
    false,
    1
  );

  constructor(translateService: TranslateService) {
    super('committee', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.committee = this.global.routerParams.get('committee');
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [null, Validators.required()],
      name: [null, Validators.required()],
      description: [null],
      committeeType: [null, Validators.required()],
      picUser: [null, Validators.required()],
      organization: [null, Validators.required()],
      committeeDecreeId: [null],
      decreeCode: [null, Validators.required()],
      decreeDate: [null, Validators.required()],
      fileObjectList: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    const committeeId = this.committee ? this.committee.id : null;
    this.httpClientService
      .post<CommitteeResponse>(
        '/committee/add-edit',
        new RouteRequestModel(this.todo, committeeId)
      )
      .subscribe((committeeResponse: CommitteeResponse) => {
        this.typeOptionList.setRequestValues(
          committeeResponse.committeeTypeList
        );

        if (this.todo === 'edit') {
          this.committeeParticipantList =
            committeeResponse.committeeParticipantList;
          this.binaryTreeModel.setTreeList(
            committeeResponse.committeeParticipantList
          );

          if (committeeResponse.committeeDecree) {
            this.fileUploader.setFile(committeeResponse.committeeDecree.file);
          }

          this.formGroup
            .get('organization')
            .patchValue(committeeResponse.organizationList);
          const decreeDate =
            committeeResponse.committeeDecree?.decreeDate &&
            new Date(committeeResponse.committeeDecree?.decreeDate);
          this.formGroup.patchValue({
            id: this.committee.id,
            code: this.committee.code,
            name: this.committee.name,
            description: this.committee.description,
            picUser: this.committee.picUser,
            committeeType: this.committee.committeeType,
            committeeDecreeId: committeeResponse.committeeDecree?.id,
            decreeCode: committeeResponse.committeeDecree?.code,
            decreeDate,
            fileObjectList: this.fileUploader.fileObjectList
          });

          if (this.committee.committeeType.code === 'COMMITTEE') {
            this.isShowProcCommittee = true;
          } else {
            this.isShowProcCommittee = false;
          }
          this.organizationParam = {
            organizationId: this.committee.organization.id
          };
        }
        this.doSetOrganizationName();
        this.setStateReady();
      });
  }

  public doSetOrganizationName(): void {
    if (
      this.committeeResponse.organizationList &&
      this.committeeResponse.organizationList.length > 0
    ) {
      this.organizationName = this.committeeResponse.organizationList
        .map(organization => organization.name)
        .join(' > ');
    }
  }

  public doOnChangeType(): void {
    const type = this.formGroup.get('committeeType').value;
    if (type?.code === 'COMMITTEE') {
      this.isShowProcCommittee = true;
    } else {
      this.isShowProcCommittee = false;
    }
    this.appTips.reload();
  }

  public onChangeOrganization(): void {
    this.organizationParam = {
      organizationId:
        this.formGroup.get('organization').value[
          this.formGroup.get('organization').value.length - 1
        ].id
    };
    this.formGroup.get('picUser').patchValue(null);
    this.appTips.reload();
  }

  public doSave(): void {
    this.validate();
    const typeCode = this.formGroup.get('committeeType').value?.code;
    const isValid =
      this.formGroup.valid &&
      ((typeCode === 'COMMITTEE' &&
        this.binaryTreeModel.getTreeList()?.length > 0) ||
        typeCode === 'PERSONAL');
    if (isValid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            const committeeRequest = new CommitteeRequest();
            committeeRequest.committee = this.formGroup.value;
            committeeRequest.committeeParticipantList =
              this.binaryTreeModel.getTreeList();

            const committeeDecree: CommitteeDecree = new CommitteeDecree();
            committeeDecree.id = this.formGroup.get('committeeDecreeId').value;
            committeeDecree.code = this.formGroup.get('decreeCode').value;
            committeeDecree.decreeDate = this.formGroup.get('decreeDate').value;
            committeeRequest.committeeDecree = committeeDecree;

            committeeRequest.fileObjectList =
              this.formGroup.get('fileObjectList').value;

            const url =
              this.todo === 'edit' ? '/committee/update' : '/committee/insert';

            const organizationList: Organization[] =
              this.formGroup.get('organization').value;
            committeeRequest.committee.organization =
              organizationList[organizationList.length - 1];

            this.httpClientService
              .post<Response<Pr>>(url, committeeRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService.submitSuccess().subscribe(result => {
                    if (result) {
                      this.router.navigate(['/pages/committee']);
                    }
                  });
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }
}
