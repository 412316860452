import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppButtonModule } from '../../app-button/app-button.module';
import { AppPopupModule } from '../app-popup.module';
import { AppPopupImportComponent } from './app-popup-import.component';
import { AppPopupImportService } from './app-popup-import.service';
@NgModule({
  imports: [VendorModule, AppPopupModule, AppButtonModule],
  declarations: [AppPopupImportComponent],
  entryComponents: [AppPopupImportComponent],
  providers: [AppPopupImportService],
  exports: [AppPopupImportComponent]
})
export class AppPopupImportModule {}
