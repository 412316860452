import { Pipe, PipeTransform } from '@angular/core';
import { ConverterService } from '../services/converter.service';

@Pipe({ name: 'resolveNumberToCurrency' })
export class ResolveNumberToCurrencyPipe implements PipeTransform {
  constructor(private converterService: ConverterService) {}

  transform(number: number): string {
    return this.converterService.convertMoney(number);
  }
}
