import { CurrencyPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { PrItem } from 'src/app/core/bean/pr-item';
import { Rfp } from 'src/app/core/bean/rfp';
import { RfpItem } from 'src/app/core/bean/rfp-item';
import { RfqItem } from 'src/app/core/bean/rfq-item';
import { TablePluginData } from 'src/app/core/components/table/interface/table-plugin-data';
import { TableResponseModel } from 'src/app/core/components/table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';

@Component({
  selector: 'app-popup-choose-rfq-item',
  templateUrl: './app-popup-choose-rfq-item.component.html',
  styleUrls: ['./app-popup-choose-rfq-item.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupChooseRfqItemComponent extends BaseComponentComponent {
  @Input() rfqItemList: RfqItem[];
  @Input() isFromRfp: boolean;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();
  @Input() baseModuleCode: String;

  public groupByMode: any;
  public groupByOptionList: OptionListModel<any> = new OptionListModel(false);
  public rfqItemTypeList: OptionListModel<any> = new OptionListModel(false);
  public tableResponsePrItem: TableResponseModel<PrItem>;
  public tableResponseRfpItem: TableResponseModel<Rfp>;
  public CURRENCY_DIGITS_INFO: string;

  constructor(
    public activeModal: NgbActiveModal,
    public currency: CurrencyPipe
  ) {
    super('rfq');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setGroupByMode();
    this.setFormGroup();
    this.buildTableResponse();
    this.setStateReady();
    this.CURRENCY_DIGITS_INFO = `0.${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}-${this.global.appConstant.core.CURRENCY_PRECISSION_SCALE}`;
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      groupBy: [null],
      rfqItemType: [null]
    });
  }

  public doOnChangeGroupBy(): void {
    this.setGroupByMode();
    this.buildTableResponse();
    this.tableResponsePrItem.reload();
  }

  public setFormGroup(): void {
    const column = [
      {
        code: 'prNumber',
        name: this.global.translateService.instant(
          'app-popup-choose-pr.table.column.prNumber'
        )
      },
      {
        code: 'itemCategory',
        name: this.global.translateService.instant(
          'app-popup-choose-pr.table.column.itemCategory'
        )
      },
      {
        code: 'prDate',
        name: this.global.translateService.instant(
          'app-popup-choose-pr.table.column.prDate'
        )
      },
      {
        code: 'department',
        name: this.global.translateService.instant(
          'app-popup-choose-pr.table.column.departement'
        )
      }
    ];
    this.groupByOptionList.setRequestValues(column);

    const rfqItemtypeList = [
      {
        name: this.global.translateService.instant(
          'rfq.form.rfqItemType.prItem'
        ),
        value: 1
      },
      {
        name: this.global.translateService.instant('rfq.form.rfqItemType.rfp'),
        value: 2
      }
    ];
    this.rfqItemTypeList.setRequestValues(rfqItemtypeList);
    this.isFromRfp
      ? this.formGroup.get('rfqItemType').patchValue(2)
      : this.formGroup.get('rfqItemType').patchValue(1);
    this.buildTableResponse();
    this.setRfqItem();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    if (this.formGroup.get('rfqItemType').value === 1) {
      if (this.groupByMode === 'prNumber') {
        !this.tableResponsePrItem
          ? (this.tableResponsePrItem = new TableResponseModel(
              this.moduleCode,
              [
                {
                  field: 'pr.code',
                  header: 'table.column.prNumber',
                  className: 'text-center'
                },
                {
                  field: 'code',
                  header: 'table.column.prLine',
                  className: 'text-center'
                },
                {
                  field: 'pr.requiredDate',
                  header: 'table.column.prDate',
                  plugins: 'date'
                },
                {
                  field: 'pr.organization.name',
                  header: 'table.column.departement',
                  className: 'text-center'
                },
                {
                  field: 'item.name',
                  header: 'table.column.itemName'
                },
                {
                  field: 'item.itemCategory.name',
                  header: 'table.column.itemCategory'
                },
                {
                  field: 'item.itemType.name',
                  header: 'table.column.type'
                },
                {
                  field: 'quantity',
                  header: 'table.column.quantity',
                  className: 'text-center',
                  plugins: {
                    name: 'custom-plugin',
                    before: (data: TablePluginData): string => {
                      return this.currency.transform(
                        +data.value,
                        '',
                        '',
                        this.CURRENCY_DIGITS_INFO
                      );
                    }
                  }
                },
                {
                  field: 'item.uom.name',
                  header: 'table.column.uom'
                },
                {
                  field: 'price',
                  header: 'table.column.price',
                  plugins: {
                    name: 'default',
                    type: 'currency'
                  }
                },
                {
                  field: 'totalPrice',
                  header: 'table.column.totalPrice',
                  plugins: {
                    name: 'default',
                    type: 'currency'
                  }
                }
              ],
              { group: { field: 'pr.code' } }
            ))
          : (this.tableResponsePrItem.options.group.field = 'pr.code');
      } else if (this.groupByMode === 'itemCategory') {
        this.tableResponsePrItem.options.group.field = 'item.itemCategory.name';
      } else if (this.groupByMode === 'prDate') {
        this.tableResponsePrItem.options.group.field = 'pr.requiredDate';
        this.tableResponsePrItem.options.group.plugins = 'date';
      } else if (this.groupByMode === 'department') {
        this.tableResponsePrItem.options.group.field = 'pr.organization.name';
      }
      const customData = {
        groupByMode: this.groupByMode,
        baseModuleCode: this.baseModuleCode
      };
      this.tableResponsePrItem.setCustomData(customData);
      this.tableResponsePrItem.options.group.isExpanded = true;
    } else {
      !this.tableResponseRfpItem
        ? (this.tableResponseRfpItem = new TableResponseModel(this.moduleCode, [
            {
              field: 'code',
              header: 'table.column.rfpNumber',
              plugins: [
                { name: 'hyperlink', onClick: this.doShowRfpDetail.bind(this) },
                {
                  name: 'icon',
                  className: 'pir pi-external-link',
                  onClick: this.doShowRfpDetail.bind(this)
                }
              ]
            },
            {
              field: 'title',
              header: 'table.column.title'
            },
            {
              field: 'createdDate',
              header: 'table.column.createdDate',
              plugins: 'date'
            },
            {
              field: 'organization.name',
              header: 'table.column.organization'
            },
            {
              field: 'createdByUser.name',
              header: 'table.column.picName'
            }
          ]))
        : '';
        this.tableResponseRfpItem.setCustomData(this.baseModuleCode);
      // this.tableResponseRfpItem.options.group.isExpanded = true;
    }
  }

  public setRfqItem(): void {
    if (this.rfqItemList && this.rfqItemList.length !== 0) {
      if (this.isFromRfp) {
        const rfpList = [];
        rfpList.push(this.rfqItemList[0].rfpItem.rfp);
        this.tableResponseRfpItem.setSelectedRowList(rfpList);
      } else {
        const prItemList = [];
        this.rfqItemList.forEach(rfpItem => {
          prItemList.push(rfpItem.prItem);
        });
        this.tableResponsePrItem.setSelectedRowList(prItemList);
      }
    }
  }

  public doShowRfpDetail(rfp: Rfp) {
    this.global.routerParams.clear();
    this.global.routerParams.set('rfp', rfp);
    this.global.routerParams.set('rfpId', rfp.id);
    this.global.routerParams.set('urlBackOutside', '/pages/rfq');
    this.global.routerParams.set('todo', 'view');
    this.router.navigate(['/pages/rfp/detail']);
    this.activeModal.dismiss();
  }

  public setGroupByMode(): void {
    if (!this.groupByMode) {
      this.groupByMode = 'prNumber';
    } else {
      this.groupByMode = this.formGroup.get('groupBy').value
        ? this.formGroup.get('groupBy').value.code
        : 'prNumber';
    }
  }

  public onChangeType(): void {
    if (this.formGroup.get('rfqItemType').value === 1) {
      this.isFromRfp = false;
      this.buildTableResponse();
      this.tableResponsePrItem.reload();
    } else {
      this.isFromRfp = true;
      this.buildTableResponse();
      this.tableResponseRfpItem.reload();
    }
  }

  public doChoose(): void {
    const rfqItemList = [];
    if (
      (!this.isFromRfp &&
        this.tableResponsePrItem.selectedRow &&
        this.tableResponsePrItem.selectedRow.getSelectedRecordList() &&
        this.tableResponsePrItem.selectedRow.getSelectedRecordList().length >
          0) ||
      (this.isFromRfp &&
        this.tableResponseRfpItem.selectedRow &&
        this.tableResponseRfpItem.selectedRow.getSelectedRecordList() &&
        this.tableResponseRfpItem.selectedRow.getSelectedRecordList().length >
          0)
    ) {
      if (!this.isFromRfp) {
        this.tableResponsePrItem.selectedRow
          .getSelectedRecordList()
          .forEach(tableRecord => {
            const rfqItem: RfqItem = new RfqItem();
            rfqItem.prItem = tableRecord;
            rfqItemList.push(rfqItem);
          });
      } else if (this.isFromRfp) {
        this.tableResponseRfpItem.selectedRow
          .getSelectedRecordList()
          .forEach(tableRecord => {
            const rfqItem: RfqItem = new RfqItem();
            const rfpItem: RfpItem = new RfpItem();
            rfqItem.rfpItem = rfpItem;
            rfqItem.rfpItem.rfp = tableRecord;
            rfqItemList.push(rfqItem);
          });
      }
      this.onChange.emit(rfqItemList);
    } else {
      this.global.alertService.showError(
        'rfq.validation.chooseItem',
        '.popup-rfq-item'
      );
    }
  }
}
