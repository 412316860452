import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorType } from '../../core/bean/vendor-type';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { AppPopupDocumentGroupComponent } from './app-popup-document-group.component';
import { AppPopupDocumentComponent } from './app-popup-document.component';
import { VendorDocMasterRequest } from './vendor-doc-master-request';
import { VendorDocMasterResponse } from './vendor-doc-master-response';
@Component({
  templateUrl: './vendor-document-edit.component.html',
  styleUrls: ['./vendor-document.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VendorDocumentEditComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;

  public isValid = false;
  public docGroupId = null;
  public vendorTypeId: number;
  public vendorName: string;
  public vendorDocMasterResponse: VendorDocMasterResponse =
    new VendorDocMasterResponse();
  public vendorDocMasterRequest: VendorDocMasterRequest =
    new VendorDocMasterRequest();
  public vendorType: VendorType = new VendorType();
  public fileTypeIdList: number[] = [];
  public docGroupIdList: number[] = [];
  public fileUploaderList: Array<FileUploader> = new Array();
  public isAllUploaded = false;
  public header;
  public crudOperation: number[] = [];

  public readonly urlEditVendorDoc = '/vendor-doc-master/edit';
  public readonly urlRouterNavigateToVendorDocMaster =
    '/pages/vendor-doc-master/';
  public readonly urlUpdateVendorDocMaster = '/vendor-doc-master/update';

  public readonly DELETE = 0;
  public readonly UPDATE = 1;
  public readonly INSERT = 2;

  public successMessage = false;
  public bulkTableResponse = [];

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('vendor-document', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorTypeId = this.global.routerParams.get('vendorTypeId');
    this.vendorName = this.global.routerParams.get('vendorName');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      parents: this.formBuilder.array([]),
      vendorDocTemplate: this.formBuilder.array([])
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<VendorDocMasterResponse>(
        this.urlEditVendorDoc,
        new RouteRequestModel(this.todo, this.vendorTypeId)
      )
      .subscribe(vendorDocMasterResponse => {
        this.header = this.vendorName;
        this.setVendorDocTemplate(vendorDocMasterResponse);
        const formArrayParent: FormArray = this.formGroupService.builder(
          vendorDocMasterResponse.vendorDocGroupList
        ) as FormArray;
        (this.formGroup.get('parents') as FormArray).controls =
          formArrayParent.controls;
        this.setStateReady();
      });
  }

  public get parents(): FormArray {
    return this.formGroup.get('parents') as FormArray;
  }

  public get vendorDocTemplate(): FormArray {
    return this.formGroup.get('vendorDocTemplate') as FormArray;
  }

  public setVendorDocTemplate(
    vendorDocMasterResponse: VendorDocMasterResponse
  ): void {
    vendorDocMasterResponse.vendorDocTemplateList.forEach(vendorDocTemplate => {
      const fileUploader: FileUploader = new FileUploader(
        '/vendor-doc-master/',
        ' ',
        this.global.appConstant.fileType.DOC_TEMPLATE_VENDOR
      );
      fileUploader.setFile(vendorDocTemplate.file);

      this.vendorDocTemplate.push(
        this.formBuilder.group({
          id: vendorDocTemplate.id,
          docName: [vendorDocTemplate.docName, Validators.required()],
          templateFile: [fileUploader.fileObjectList, Validators.required()],
          crudOperation: null
        })
      );
      this.fileUploaderList.push(fileUploader);
    });
  }

  public addTemplate(): void {
    const fileUploader: FileUploader = new FileUploader(
      '/vendor-doc-master/',
      ' ',
      this.global.appConstant.fileType.DOC_TEMPLATE_VENDOR
    );
    this.vendorDocTemplate.push(
      this.formBuilder.group({
        docName: [null, Validators.required()],
        templateFile: [fileUploader.setFile(null), Validators.required()],
        crudOperation: this.INSERT
      })
    );
    this.fileUploaderList.push(fileUploader);
  }

  public removeTemplate(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (
            this.vendorDocTemplate.controls[index].value.crudOperation ===
            this.INSERT
          ) {
            this.vendorDocTemplate.removeAt(index);
            this.fileUploaderList.splice(index, 1);
          } else {
            this.vendorDocTemplate.controls[index].patchValue({
              crudOperation: this.DELETE
            });
            this.vendorDocTemplate.controls[index]
              .get('templateFile')
              .clearValidators();
            this.vendorDocTemplate.controls[index]
              .get('docName')
              .clearValidators();
            this.vendorDocTemplate.controls[index]
              .get('templateFile')
              .updateValueAndValidity();
            this.vendorDocTemplate.controls[index]
              .get('docName')
              .updateValueAndValidity();
          }
        }
      });
  }

  public changeDocNameAndFile(index: number): void {
    if (
      this.vendorDocTemplate.controls[index].value.crudOperation !== this.INSERT
    ) {
      this.vendorDocTemplate.controls[index].patchValue({
        crudOperation: this.UPDATE
      });
    }
  }

  public doAddDocGroupList(): void {
    const docGroupIdList = this.docGroupIdList;
    this.appPopupService
      .open(AppPopupDocumentGroupComponent, { docGroupIdList }, { size: 'lg' })
      .subscribe(rowData => {
        const docGroupNew = rowData[0];
        docGroupNew.nameTranslated = rowData[1];
        this.docGroupIdList.push(docGroupNew.id);
        const vendorTypeId = this.vendorTypeId;

        const vendorType = { id: vendorTypeId };
        // tslint:disable-next-line: max-line-length
        const newDocGroup = this.formGroupService.builder({
          docGroup: docGroupNew,
          vendorType,
          nameTranslated: docGroupNew.translationKey.key,
          vendorDocMasterList: [],
          crudOperation: this.INSERT
        });
        this.parents.controls.push(newDocGroup);
      });
  }

  public doAddDocument(parents: FormGroup): void {
    const vendorDocMasterList = parents.value.vendorDocMasterList;

    const fileIdList = vendorDocMasterList
      .filter(vendorDocMaster => vendorDocMaster.crudOperation !== this.DELETE)
      .map(vendorDocMaster => vendorDocMaster.fileType.id);
    const fileTypeIdList = fileIdList.concat(
      this.fileTypeIdList.filter(x => fileIdList.every(y => y !== x))
    );
    this.appPopupService
      .open(AppPopupDocumentComponent, { fileTypeIdList }, { size: 'lg' })
      .subscribe(rowData => {
        const fileTypeNew = rowData[0];
        fileTypeNew.nameTranslated = rowData[1];
        this.fileTypeIdList.push(fileTypeNew.id);
        const formArrayVendorDocTypeLists = parents.get(
          'vendorDocMasterList'
        ) as FormArray;
        // tslint:disable-next-line: max-line-length
        const formGroupVendorDocTypeList = this.formGroupService.builder({
          fileType: fileTypeNew,
          nameTranslated: fileTypeNew.translationKey.key,
          isExpiry: false,
          isStart: false,
          crudOperation: this.INSERT
        });
        formArrayVendorDocTypeLists.controls.push(formGroupVendorDocTypeList);
      });
  }

  public dodeleteDocument(vendorDocMasterList: FormArray, index: number): void {
    const vendorDocMasterLists = vendorDocMasterList.value;

    const fileIdList = vendorDocMasterLists
      .filter(vendorDocMaster => vendorDocMaster.crudOperation !== this.DELETE)
      .map(vendorDocMaster => vendorDocMaster.fileType.id);

    const fileTypeIdList = fileIdList.concat(
      this.fileTypeIdList.filter(x => fileIdList.every(y => y !== x))
    );
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (
            vendorDocMasterList.controls[index].value.crudOperation ===
            this.INSERT
          ) {
            vendorDocMasterList.removeAt(index);

            if (fileTypeIdList.hasOwnProperty(fileTypeIdList[index])) {
              fileTypeIdList.splice(index, 1);
            } else {
              fileTypeIdList.splice(index - 1, 1);
            }
          } else {
            vendorDocMasterList.controls[index].patchValue({
              crudOperation: this.DELETE
            });
            fileTypeIdList.splice(index, 1);
          }

          this.fileTypeIdList = fileTypeIdList;
        }
      });
  }

  public dodeleteDocGroupList(index: number): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          if (
            this.parents.controls[index].value.crudOperation === this.INSERT
          ) {
            this.parents.removeAt(index);
            this.docGroupIdList.splice(index, 1);
          } else {
            this.parents.controls[index].patchValue({
              crudOperation: this.DELETE
            });
            if (
              this.parents.controls[index].value.vendorDocMasterList.length !==
              0
            ) {
              this.parents.controls[index].value.vendorDocMasterList.forEach(
                vendorDocMaster => {
                  vendorDocMaster.crudOperation = this.DELETE;
                }
              );

              this.parents.controls[index].patchValue({
                vendorDocMasterList:
                  this.parents.controls[index].value.vendorDocMasterList
              });
              this.docGroupIdList.splice(index, 1);
            }
          }
        }
      });
  }

  public changeIsStartIsExpired(
    vendorDocMasterList: FormArray,
    indexDocument: number
  ): void {
    if (
      vendorDocMasterList.controls[indexDocument].value.crudOperation !==
      this.INSERT
    ) {
      vendorDocMasterList.controls[indexDocument].patchValue({
        crudOperation: this.UPDATE
      });
    }
  }

  public doBack(): void {
    this.router.navigate([this.urlRouterNavigateToVendorDocMaster]);
  }

  public doSubmit(): void {
    this.validate();
    console.log(this.formGroup);
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.saveVendorDocMaster();
          }
        });
    }
  }

  public saveVendorDocMaster(): void {
    this.setStateProcessing();
    this.vendorDocMasterRequest.vendorDocGroupList =
      this.formGroup.value.parents;
    this.formGroup.value.vendorDocTemplate.forEach((vdt, index) => {
      if (Array.isArray(vdt.templateFile)) {
        vdt.file = vdt.templateFile[0].file;
      }
      this.vendorDocMasterRequest.fileObjectList.push(
        this.fileUploaderList[index].fileObjectList[0]
      );
      this.vendorDocMasterRequest.vendorDocTemplateList = vdt;
    });
    this.vendorDocMasterRequest.vendorDocTemplateList =
      this.formGroup.value.vendorDocTemplate;
    this.vendorDocMasterRequest.vendorTypeId = this.vendorTypeId;
    this.saveVendorDocMasterToServer(
      this.urlUpdateVendorDocMaster,
      this.vendorDocMasterRequest
    ).subscribe(response => {
      if (response.status === ResponseStatusModel.OK) {
        this.global.alertService.showSuccessSavingOnNextRoute();
        this.router.navigate([this.urlRouterNavigateToVendorDocMaster]);
      } else {
        this.global.alertService.showError(response.statusText);
        this.setStateReady();
      }
    });
  }

  public saveVendorDocMasterToServer(
    urlUpdateVendorDocMaster: string,
    vendorDocMasterRequest: VendorDocMasterRequest
  ): Observable<Response<VendorDocMasterRequest>> {
    return this.httpClientService.post<Response<VendorDocMasterRequest>>(
      urlUpdateVendorDocMaster,
      vendorDocMasterRequest
    );
  }

  public getFormArray(formControlName: string): FormArray {
    return this.formGroup.get(formControlName) as FormArray;
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
