import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupCatalogAttributeComponent } from './app-popup-catalog-attribute.component';
import { CatalogAttributeGroupEditAddComponent } from './catalog-attribute-group-edit-add.component';
import { CatalogAttributeGroupImportComponent } from './catalog-attribute-group-import.component';
import { CatalogAttributeGroupComponent } from './catalog-attribute-group.component';
export const routes = [
  {
    path: '',
    component: CatalogAttributeGroupComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'edit',
    component: CatalogAttributeGroupEditAddComponent,
    data: { breadcrumb: 'catalog-attribute-group.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: CatalogAttributeGroupEditAddComponent,
    data: { breadcrumb: 'catalog-attribute-group.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'import',
    component: CatalogAttributeGroupImportComponent,
    data: { breadcrumb: 'catalog-attribute-group.breadcrumb.import' }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    CatalogAttributeGroupComponent,
    CatalogAttributeGroupEditAddComponent,
    CatalogAttributeGroupImportComponent,
    AppPopupCatalogAttributeComponent
  ],
  entryComponents: [AppPopupCatalogAttributeComponent],
  providers: []
})
export class CatalogAttributeGroupModule {}
