import { BaseEntity } from '../base/base-entity';
import { FormObject } from '../bean/form-object';
import { ListOfValueModel } from '../model/list-of-value-model';
import { Rfi } from '../bean/rfi';

export class RfiQuestionEntity extends BaseEntity {
    formObject: FormObject;
    listOfValue: ListOfValueModel[];
    rfi: Rfi;
    question: string;
}
