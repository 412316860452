import { OrderShipping } from 'src/app/core/bean/order-shipping';
import { OrderShippingItem } from 'src/app/core/bean/order-shipping-item';
import { OrderTc } from 'src/app/core/bean/order-tc';
import { PrItem } from 'src/app/core/bean/pr-item';
import { Sow } from 'src/app/core/bean/sow';
import { AppOfficialReportModel } from 'src/app/core/components/app-official-report/app-official-report-model';

export class OrderRequestValidation {
    prItemList: PrItem[] = [];
    indexItemAddressedFalse: number;
    indexFullAdd: number;
    orderShippingItemList: OrderShippingItem[] = [];
    orderShippingList: OrderShipping[] = [];
    sowList: Sow[] = [];
    orderTcList: OrderTc[] = [];
    remainingAmount: number;
    isValidOrderModel: boolean;
    appOfficialReportModel: AppOfficialReportModel;
}
