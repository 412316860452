import { ApplicationRef, ComponentFactory, ComponentFactoryResolver, Injectable, Injector, ViewContainerRef } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AppDynamicComponentService {
  public instance: any;
  constructor(public componentFactoryResolver: ComponentFactoryResolver, public applicationRef: ApplicationRef, public injector: Injector) {}
  public create(componentTarget: any, viewChild: any, config?: any, projectTableNodes?: any): any {
    const componentFactory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(componentTarget);
    if (viewChild instanceof ViewContainerRef) {
      const componentRef = viewChild.createComponent(componentFactory, 0, this.injector, projectTableNodes);
      Object.assign(componentRef.instance, config);
      componentRef.changeDetectorRef.detectChanges();
      this.instance = componentRef.instance;
    } else {
      const componentRef = componentFactory.create(this.injector, [[projectTableNodes]], viewChild);
      Object.assign(componentRef.instance, config);
      this.instance = componentRef.instance;
      componentRef.changeDetectorRef.detectChanges();
      this.applicationRef.attachView(componentRef.hostView);
    }
    return this.instance;
  }
}
