import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ProcurementMethod } from '../../core/bean/procurement-method';
import { AppTableXComponent } from '../../core/components/app-table-x/components/app-table-x/app-table-x.component';
import { TableResponseModel } from '../../core/components/app-table-x/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './procurement-method.component.html'
})
export class ProcurementMethodComponent extends BaseModuleComponent {
  @ViewChild(AppTableXComponent) public table: AppTableXComponent;
  public tableResponse: TableResponseModel<ProcurementMethod>;

  constructor(translateService: TranslateService) {
    super('procurement-method', translateService);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: '0.code',
        header: 'table.column.code',
        customClass: 'text-center',
        plugins: 'hyperlink'
      },
      { field: '1', header: 'table.column.name' },
      { field: '0.description', header: 'table.column.description' }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/procurement-method/add']);
  }

  public doEdit(data: any): void {
    const procurementMethod: ProcurementMethod = data[0];
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('procurementMethodId', procurementMethod.id);
    this.router.navigate(['/pages/procurement-method/edit']);
  }

  public doDelete(event: any): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          const procurementMethodList: ProcurementMethod[] =
            event.checkedRecordList.map(data => data[0]);
          this.httpClientService
            .post<Response<ProcurementMethod>>(
              '/procurement-method/delete',
              procurementMethodList
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.tableResponse.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }
}
