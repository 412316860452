import { Global } from '../../../global/global';
import { Uploader } from '../model/uploader';
export function showUploaderError(uploader: Uploader, global: Global): void {
  const { type } = uploader.error;
  let message: string;
  const translateMessage = global.translateService.instant(
    uploader.error.message
  );
  switch (type) {
    case 'MAX_FILE_ERROR':
      message = `${translateMessage} ${uploader.options.maxFile}`;
      break;
    case 'EXTENSION_ERROR':
      message = `${translateMessage} ${
        uploader.options.fileType
          ? uploader.options.fileType.fileExtension
          : uploader.options.allowedExtension
      }`;
      break;
    case 'MAX_SIZE_ERROR':
      message = `${translateMessage} ${
        uploader.options.fileType
          ? uploader.options.fileType.maxSize / 1000000
          : uploader.options.maxSize / 1000000
      }MB`;
      break;
    case 'MAX_LENGTH_FILE_NAME':
      message = `${translateMessage} ${
        uploader.options.maxFileNameLength
      } ${global.translateService.instant(
        'app.acceptable.maxLengthFileNameCharacters'
      )}`;
      break;
    case 'UNICODE_ERROR':
      message = `${translateMessage} ( ${global.config.parameterModel.uploadValidationUnicode} )`;
      break;

    default:
      message = translateMessage;
      break;
  }
  if (uploader.isHandleError) {
    global.modalService.message(message);
    uploader.error = null;
  } else {
    uploader.error.message = message;
  }
}
