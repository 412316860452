import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TableCatalogRequestModel } from 'src/app/pages/catalog/view-catalog/core/components/app-table-catalog/model/table-catalog-request-model';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { Currency } from '../../bean/currency';
import { OptionListModel } from '../../model/option-list-model';
import { CatalogView } from '../../view/entity/bean/catalog-view';
import { TableCatalogResponseModel } from '../app-catalog-item-x/model/table-catalog-response-model';
import { PaginationModel } from '../app-pagination/model/pagination-model';
@Component({
  selector: 'app-similar-product',
  templateUrl: './app-similar-product.component.html',
  styleUrls: ['./app-similar-product.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppSimilarProductComponent extends BaseComponentComponent {
  @Input() public catalogId: number;
  @Input() itemPath = '';

  public similarProductList: Array<number> = new Array();
  public paginationModel: PaginationModel = new PaginationModel(false);
  public tableRequest: TableCatalogRequestModel =
    new TableCatalogRequestModel();

  public model: TableCatalogResponseModel<CatalogView> =
    new TableCatalogResponseModel('/app-similar-product/index');
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false
  );

  constructor() {
    super('app-similar-product');
  }

  public onInit(): void {
    this.setCustomData();
    this.handleReloadChanges();
    this.getAndSetCatalogs();
    this.setPagination();
  }

  public getAndSetCatalogs(): void {
    this.model.setStateLoading();

    this.model.tableRequest.cols = [
      { field: 'name', header: '' },
      { field: 'vendorName', header: '' },
      { field: 'rating', header: '' },
      { field: 'currencyCode', header: '' },
      { field: 'price', header: '' }
    ];

    this.httpClientService
      .post<any>(this.model.stringUrl, this.model.tableRequest)
      .subscribe(page => {
        this.model.setPageRecords(page.records);
        this.model.setTotalRecords(page.totalRecords);
        this.model.setStateReady();
        this.setStateReady();
      });
  }

  public handleReloadChanges(): void {
    this.model.reloadChanges.subscribe(() => {
      this.getAndSetCatalogs();
    });
  }

  public setCustomData(): void {
    this.model.setCustomData(this.catalogId);
  }

  public setPagination(): void {
    this.model.setCurrentPage(1);
    this.model.setPerPage(5);
  }

  public onPageChangeRight(): void {
    this.model.setCurrentPage(this.model.pagination.page + 1);
    this.model.reload();
  }

  public onPageChangeLeft(): void {
    this.model.setCurrentPage(this.model.pagination.page - 1);
    this.model.reload();
  }
}
