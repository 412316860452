<ng-container *ngIf="ISVIEW; then isViewTmpl; else isNotViewTmpl">
</ng-container>
<ng-template #isNotViewTmpl>
  <div
    class="input-group form-control app-date-picker p-0 border-0"
    [ngClass]="[elementWidthClassName, elementHeightClassName]"
    [formGroup]="formGroup"
  >
    <input
      class="form-control validation-field"
      placeholder="{{ global.config.parameterModel.dateFormatShort }}"
      [formControl]="formControl"
      [ngClass]="[
        isInvalid ? 'is-invalid' : '',
        disabled ? 'bg-default' : 'bg-white cursor-pointer'
      ]"
      (click)="date.toggle()"
      [maxDate]="maxDateValue"
      [minDate]="minDateValue"
      container="body"
      (dateSelect)="onDateSelect(formControl.value)"
      #date="ngbDatepicker"
      ngbDatepicker
      readonly
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    />
    <em
      class="clear-date-picker fas fa-times text-danger"
      *ngIf="formControl.value"
      (click)="handleFormControlReset()"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    ></em>
    <div
      class="input-group-append cursor-pointer"
      (click)="date.toggle()"
      (keyup)="onKeyUp($event)"
      (keydown)="onKeyDown($event)"
    >
      <span class="input-group-text"
        ><em class="fas fa-calendar-alt"></em
      ></span>
    </div>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
  <small class="text-danger" *ngIf="isInvalid">
    {{ errors.message }}
  </small>
</ng-template>
<ng-template #isViewTmpl>
  <div class="position-relative d-inline-block">
    <span>{{
      formControl.value
        ? (formControl.value
          | date: global.config.parameterModel.dateFormatShort)
        : '-'
    }}</span>
    <ng-container *ngTemplateOutlet="appTooltipWrapperTemplateOutlet">
    </ng-container>
  </div>
</ng-template>
<ng-template #appTooltipWrapperTemplateOutlet>
  <app-tooltip-wrapper
    [tooltip]="tooltip"
    [position]="tooltipPosition"
    [isView]="ISVIEW"
  >
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</ng-template>
