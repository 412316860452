<ng-container
  *ngIf="stringUrl; then stringUrlTmpl; else elseTmpl"
></ng-container>
<ng-template #stringUrlTmpl>
  <div class="app-tooltip-wrapper">
    <em
      class="fas fa-info-circle"
      [ngbTooltip]="tooltip"
      [placement]="position"
      (mouseenter)="handleMouseEnter()"
      #t="ngbTooltip"
    ></em>
  </div>
</ng-template>
<ng-template #elseTmpl>
  <ul class="app-tooltip-wrapper">
    <ng-content select="app-tooltip-wrapper-item[position=first]"></ng-content>
    <app-tooltip-wrapper-item *ngIf="symbolic">
      <em [ngStyle]="{ 'font-style': 'normal' }" [innerHTML]="symbolic"></em>
    </app-tooltip-wrapper-item>
    <app-tooltip-wrapper-item *ngIf="!isView && tooltip">
      <em
        class="fas fa-info-circle"
        [ngbTooltip]="isStringTooltip ? (tooltip | translate) : tooltip"
        [placement]="position"
        [tooltipClass]="tooltipClass"
      ></em>
    </app-tooltip-wrapper-item>
    <ng-content select="app-tooltip-wrapper-item"></ng-content>
  </ul>
</ng-template>
