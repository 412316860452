import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Counter } from '../../core/bean/counter';
import { User } from '../../core/bean/user';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { UserCounter } from 'src/app/core/bean/user-counter';
import { UserMonitoringResponse } from './user-monitoring-response';

@Component({
  templateUrl: './app-popup-add-user-counter.component.html'
})
export class AppPopupAddUserCounterComponent extends BaseModuleComponent {
  @Input() header: string;
  @Input() user: User;
  @Input() userCounterList: UserCounter[];
  @Input() userMonitoringResponseTemp: UserMonitoringResponse;
  @Input() counterList: Counter[];
  @Output() onChange: EventEmitter<UserCounter[]> = new EventEmitter();
  public isNoCounterSelected = false;
  public tableResponseCounter: TableResponseModel<Counter>;
  public isCounterHaveBeenSelected = false;

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public activeModal: NgbActiveModal
  ) {
    super('user-monitoring', translate);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponseCounter = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.counter.code'
      },
      {
        field: 'name',
        header: 'table.column.counter.name'
      }
    ]);
    this.setTableResponseCounterState();
  }
  private setTableResponseCounterState(): void {
    this.tableResponseCounter.setRecordList(this.counterList);
    this.tableResponseCounter.reload();
  }
  public doAddUserCounter(): void {
    this.validate();
    if (this.formGroup.valid) {
      if (this.tableResponseCounter.selectedRow.selectedRowList.length !== 0) {
        const addedCounterList =
          this.tableResponseCounter.selectedRow.selectedRowList;
        addedCounterList.forEach(newCounter => {
          const counter = newCounter.record;
          const newUserCounter = new UserCounter();
          newUserCounter.counter = counter;
          newUserCounter.user = this.user;
          newUserCounter.crudOperation = this.global.appConstant.core.CRUD_OPERATION_INSERT;
          this.userCounterList.push(newUserCounter);
          const index = this.counterList.findIndex(
            i => JSON.stringify(i) === JSON.stringify(counter)
          );
          if (index !== -1) {
            this.counterList.splice(index, 1);
          }
        });
        this.isNoCounterSelected = false;
        this.onChange.emit(this.userCounterList);
        this.activeModal.dismiss();
      } else {
        this.isNoCounterSelected = true;
      }
    } else if (this.tableResponseCounter.selectedRow.selectedRowList.length === 0) {
      this.isNoCounterSelected = true;
    }
  }

  public doValidate(): void {
    if (this.tableResponseCounter.selectedRow['isSelectAll'] !== undefined) {
      if (this.tableResponseCounter.selectedRow.selectedRowList.length === 0 && this.tableResponseCounter.selectedRow.unSelectedRowList.length === 0) {
        if (this.tableResponseCounter.selectedRow['isSelectAll'] === false) {
          this.isNoCounterSelected = true;
        } else if (this.tableResponseCounter.selectedRow['isSelectAll'] === true) {
          this.isNoCounterSelected = false;
        }
      } else {
        this.isNoCounterSelected = false;
      }
    } else if (this.tableResponseCounter.selectedRow.selectedRowList.length !== 0) {
      this.isNoCounterSelected = false;
      this.isCounterHaveBeenSelected = true;
    } else if (this.isCounterHaveBeenSelected) {
      this.isNoCounterSelected = true;
    }
  }
}
