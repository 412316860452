import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppAgreementTypeModule } from 'src/app/core/components/app-agreement-type/app-agreement-type.module';
import { AppApprovalPrcsXModule } from 'src/app/core/components/app-approval-prcs-x/app-approval-prcs-x.module';
import { AppItemRequestModule } from 'src/app/core/components/app-item-request/app-item-request.module';
import { ConfirmGuard } from 'src/app/core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupChooseRfqItemComponent } from './app-popup-choose-rfq-item.component';
import { RfqEditAddCompenent } from './rfq-edit-add.component';
import { RfqPopupCancelComponent } from './rfq-popup-cancel.component';
import { RfqPopupVendorViewScoreComponent } from './rfq-popup-vendor-view-score.component';
import { RfqViewComponent } from './rfq-view.component';
import { RfqComponent } from './rfq.component';

export const routes = [
  { path: '', component: RfqComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: RfqEditAddCompenent,
    data: { breadcrumb: 'rfq.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: RfqEditAddCompenent,
    data: { breadcrumb: 'rfq.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail',
    component: RfqViewComponent,
    data: { breadcrumb: 'rfq.breadcrumb.detail' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail/:token',
    component: RfqViewComponent,
    data: { breadcrumb: '' }
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgbModule,
    AppItemRequestModule,
    AppApprovalPrcsXModule,
    AppAgreementTypeModule
  ],
  declarations: [
    RfqComponent,
    RfqEditAddCompenent,
    RfqViewComponent,
    AppPopupChooseRfqItemComponent,
    RfqPopupVendorViewScoreComponent,
    RfqPopupCancelComponent
  ],
  exports: [
    AppPopupChooseRfqItemComponent,
    RfqPopupVendorViewScoreComponent,
    RfqPopupCancelComponent
  ],
  entryComponents: [
    AppPopupChooseRfqItemComponent,
    RfqPopupVendorViewScoreComponent,
    RfqPopupCancelComponent
  ],
  providers: []
})
export class RfqModule {}
