<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="schedule-stages.title"
      detail="{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'schedule-stages.form.moduleGroup' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ moduleName }}
          </div>
        </div>

        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'schedule-stages.form.description' | translate
          }}</label>
          <div class="col-sm-4 text-left">
            {{ moduleDescription }}
          </div>
        </div>

        <div class="form-group row text-sm-right" formArrayName="check">
          <label class="col-sm-3 control-label"></label>
          <div (change)="doChecklist()" class="col-sm-9 text-left">
            <ng-container
              *ngFor="let c of check.controls; let i = index"
              [formGroupName]="i"
            >
              <!-- <div style="padding-left: 1%;"></div> -->
              <app-check-box
                [ngStyle]="{ 'padding-left': '3%' }"
                formControlName="isChecked"
                [isDisabled]="c.value.disabled"
              >
                {{ c.value.name }}
              </app-check-box>
            </ng-container>
          </div>
        </div>

        <div class="row justify-content-center mt-5">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="doCancel()"
            [disabled]="formSaving"
            >{{ 'app.button.back' | translate }}</button
          >
          &nbsp;
          <button
            type="button"
            class="btn btn-primary ml-2"
            (click)="doSave()"
            [disabled]="!formGroup.valid"
            >{{ 'app.button.save' | translate }}</button
          >
        </div>
      </form>
    </app-card>
  </div>
</div>
