import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { User } from 'src/app/core/bean/user';
@Component({
  templateUrl: 'user-monitoring.component.html'
})
export class UserMonitoringComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<User>;
  constructor(translateService: TranslateService) {
    super('user-monitoring', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'email',
        header: 'table.index.col.email',
        customClass: 'text-center'
      },
      { field: 'name', header: 'table.index.col.name' }
    ]);
  }

  public doEdit(user: User): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('user', user);
    this.router.navigate(['/pages/user-monitoring/edit']);
  }

}
