import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppApprovalPrcsModule } from '../../core/components/app-approval-prcs/app-approval-prcs.module';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { VendorReactivationDetailVendorComponent } from './vendor-reactivation-detail-vendor.component';
import { VendorReactivationDetailComponent } from './vendor-reactivation-detail.component';
import { VendorReactivationEditAddComponent } from './vendor-reactivation-edit-add.component';
import { VendorReactivationComponent } from './vendor-reactivation.component';
export const routes = [
  {
    path: '',
    component: VendorReactivationComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'detail',
    component: VendorReactivationDetailComponent,
    data: { breadcrumb: 'vendor-reactivation.breadcrumb.detail' }
  },
  {
    path: 'add',
    component: VendorReactivationEditAddComponent,
    data: { breadcrumb: 'vendor-reactivation.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: VendorReactivationEditAddComponent,
    data: { breadcrumb: 'vendor-reactivation.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'detail-vendor',
    component: VendorReactivationDetailVendorComponent,
    data: { breadcrumb: 'vendor-reactivation.breadcrumb.detailVendor' },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/pages/company-profile/company-profile.module').then(
            m => m.CompanyProfileModule
          ),
        data: { breadcrumb: 'Company Profile' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, AppApprovalPrcsModule],
  declarations: [
    VendorReactivationComponent,
    VendorReactivationDetailComponent,
    VendorReactivationEditAddComponent,
    VendorReactivationDetailVendorComponent
  ],
  providers: []
})
export class VendorReactivationModule {}
