import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppAccordionModule } from '../app-accordion/app-accordion.module';
import { AppButtonModule } from '../app-button/app-button.module';
import { AppCardModule } from '../app-card/app-card.module';
import { AppFieldsetModule } from '../app-fieldset/app-fieldset.module';
import { AppTableXModule } from '../app-table-x/components/app-table-x/app-table-x.module';
import { TableModule } from '../table/table.module';
import { AppGuaranteeComponent } from './app-guarantee.component';
@NgModule({
  imports: [
    VendorModule,
    AppTableXModule,
    AppCardModule,
    AppAccordionModule,
    AppFieldsetModule,
    AppButtonModule,
    PipesModule,
    TableModule
  ],
  declarations: [AppGuaranteeComponent],
  exports: [AppGuaranteeComponent]
})
export class AppGuaranteeModule {}
