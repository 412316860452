<app-card [isLoading]="isLoading">
  <div class="widget">
    <div class="widget-header mb-3" *ngIf="header">
      <h4 class="widget-title">{{ header | translate }}</h4>
    </div>
    <div class="widget-content">
      <ng-content></ng-content>
    </div>
    <ng-content select=".widget-card-footer"> </ng-content>
  </div>
</app-card>
