import { environment } from '../../../../environments/environment';
import { Global } from '../../global/global';
import { ServiceLocator } from '../service-locator';
import { LogLevel } from './enum/log-level-enum';
export class Log {
  public global: Global =
    ServiceLocator.injector && ServiceLocator.injector.get(Global);
  public logLevel: LogLevel =
    (this.global &&
      this.global.config &&
      this.global.config.parameterModel &&
      this.global.config.parameterModel.consoleLogLevel) ||
    (environment.production ? 'OFF' : 'ALL');
  public currentClassName: string;
  constructor(classInstance: any) {
    this.currentClassName = classInstance.__proto__.constructor.name;
  }

  public debug(...args: any[]): void {
    const bgColor = '#747474';
    const color = '#ffffff';
    this.writeToLog(args, 'DEBUG', bgColor, color);
  }

  public info(...args: any[]): void {
    const bgColor = '#99ff99';
    const color = 'green';
    this.writeToLog(args, 'INFO', bgColor, color);
  }

  public warn(...args: any[]): void {
    const bgColor = '#ffff99';
    const color = 'orange';
    this.writeToLog(args, 'WARN', bgColor, color);
  }

  public error(...args: any[]): void {
    const bgColor = '#ff9999';
    const color = 'red';
    this.writeToLog(args, 'ERROR', bgColor, color);
  }

  public fatal(...args: any[]): void {
    const bgColor = 'red';
    const color = '#ffffff';
    this.writeToLog(args, 'FATAL', bgColor, color);
  }

  public log(...args: any[]): void {
    const bgColor = '#474747';
    const color = '#ffffff';
    this.writeToLog(args, 'ALL', bgColor, color);
  }

  public writeToLog(...args: any[]): void {
    if (this.isShowLog(args[1])) {
      console.group(
        '%c' + args[1] + '%c- ' + this.currentClassName,
        `background-color: ${args[2]}; color: ${args[3]}; font-weight: bold; padding: 0.11rem 1rem 0.11rem 0.44rem; border-radius: 0.22rem`,
        'font-weight: bold'
      );
      console.log(...args[0]);
      console.groupEnd();
    }
  }

  public isShowLog(level: LogLevel): boolean {
    let isShowLog = false;
    const logLevelList: LogLevel[] = [
      'ALL',
      'DEBUG',
      'INFO',
      'WARN',
      'ERROR',
      'FATAL',
      'OFF'
    ];
    const indexOfLevel = logLevelList.indexOf(level);
    const indexOfLogLevel = logLevelList.indexOf(this.logLevel);
    if (
      (indexOfLevel >= indexOfLogLevel && level !== 'OFF') ||
      this.logLevel === 'ALL'
    ) {
      isShowLog = true;
    }
    return isShowLog;
  }
}
