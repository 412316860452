import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Global } from '../../../global/global';
import { ServiceLocator } from '../../../services/service-locator';
import { CustomValidatorError } from '../../core/custom-validator-error';
export class PasswordValidator {
  public static password(message?: string): ValidatorFn {
    return (abstractControl: AbstractControl): ValidationErrors => {
      const value = abstractControl.value;
      if (value) {
        const global: Global = ServiceLocator.injector.get(Global);
        const regex = new RegExp(global.config.parameterModel.regexPassword);
        const isValidPassword = regex.test(value);
        if (!isValidPassword) {
          return CustomValidatorError.create('password', message);
        }
      }
      return null;
    };
  }
}
