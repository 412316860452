import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { ReportBuilder } from 'src/app/core/bean/report-builder';
import { ReportModuleGroup } from 'src/app/core/bean/report-module-group';
import { ReportRecap } from 'src/app/core/bean/report-recap';
import { ReportRecapColumn } from 'src/app/core/bean/report-recap-column';
import { PickListModel } from 'src/app/core/components/app-pick-list/model/pick-list-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';
import { TableColumnModel } from '../../core/components/table/model/table-column-model';
import { ReportRecapEditResponse } from './report-recap-edit.response';
import { ReportRecapInsertRequest } from './report-recap-insert.request';

@Component({
  templateUrl: './report-recap-add-builder.component.html',
  styleUrls: ['./report-recap-add-builder.component.scss']
})
export class ReportRecapAddBuilderComponent extends BaseModuleComponent {
  public reportBuilderId: number;
  public reportModuleGroupList: ReportModuleGroup[] = [];
  public reportRecapOptionList: OptionListModel<ReportRecap> =
    new OptionListModel(false);
  public reportModuleGroupOptionList: OptionListModel<ReportModuleGroup> =
    new OptionListModel(true, 'name', 'id');
  public pickListModel: PickListModel<ReportRecapColumn>;
  public isPickListReady = false;
  public activeModuleId: number;
  public isAllValid = false;
  public isChose = false;
  public reportRecapColumnList: ReportRecapColumn[] = [];

  public constructor(translateService: TranslateService) {
    super('pr', translateService);
  }

  public onInit(): void {
    this.getDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public getDataFromRouterParams(): void {
    this.reportBuilderId = this.global.routerParams.get('reportBuilderId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required()],
      report: [null, Validators.required()],
      description: [null, Validators.required()]
    });
  }

  public setFormGroup(): void {
    if (this.reportBuilderId) {
      this.httpClientService
        .get<ReportRecapEditResponse>(
          '/report-recap/edit-report-builder/' + this.reportBuilderId
        )
        .subscribe((response: ReportRecapEditResponse) => {
          this.reportRecapColumnList = response.reportRecapColumnList;
          this.reportModuleGroupOptionList.setRequestValues(
            response.reportModuleGroupList
          );
          this.reportRecapOptionList.setRequestValues(response.reportRecapList);
          this.reportModuleGroupList = response.reportModuleGroupList;
          this.activeModuleId =
            response.reportBuilder.reportRecap.reportModuleGroup.id;
          this.formGroup.patchValue({
            name: response.reportBuilder.name,
            report: response.reportBuilder.reportRecap,
            description: response.reportBuilder.description
          });
          const recapColumList = response.reportRecapColumnList.filter(
            reportRecapColum => reportRecapColum.code !== 'id'
          );
          const recapColumTargetList =
            response.reportRecapColumnTargetList.filter(
              reportRecapColum => reportRecapColum.code !== 'id'
            );
          this.pickListModel = new PickListModel(
            this.moduleCode,
            recapColumList,
            recapColumTargetList,
            'name'
          );
          this.global.routerParams.set(
            'reportBuilderId',
            response.reportBuilder.id
          );
          this.global.routerParams.set(
            'reportRecapId',
            response.reportBuilder.reportRecap.id
          );
          this.global.routerParams.set(
            'generatedTableResponse',
            response.reportBuilder.generatedTableResponse
          );
          this.checkValidasiForm();
          this.setStateReady();
          this.isPickListReady = true;
        });
    } else {
      this.httpClientService
        .get<ReportModuleGroup[]>('/report-recap/add-report-builder')
        .subscribe(reportModuleGroupList => {
          this.reportModuleGroupOptionList.setRequestValues(
            reportModuleGroupList
          );
          this.reportModuleGroupList = reportModuleGroupList;
          this.setStateReady();
        });
    }
  }

  public doChooseModule(moduleGroup: ReportModuleGroup): void {
    this.activeModuleId = moduleGroup.id;
    this.httpClientService
      .get<ReportRecap[]>('/report-recap/get-list-report/' + moduleGroup.id)
      .subscribe(reportRecapList => {
        this.reportRecapOptionList.setRequestValues(reportRecapList);
      });
  }

  public checkValidasiForm(): void {
    const formGroup = this.formGroup.value;
    if (
      formGroup.name &&
      formGroup.name !== '' &&
      formGroup.report &&
      formGroup.description &&
      formGroup.description !== ''
    ) {
      this.isAllValid = true;
    } else {
      this.isAllValid = false;
    }
  }

  public doChooseReport(): void {
    this.checkValidasiForm();
    const reportRecap = this.formGroup.get('report').value;
    this.httpClientService
      .get<ReportRecapColumn[]>(
        '/report-recap/get-list-column/' + reportRecap.id
      )
      .subscribe(reportRecapColumnList => {
        const recapColumList = reportRecapColumnList.filter(
          reportRecapColum => reportRecapColum.code !== 'id'
        );
        this.reportRecapColumnList = reportRecapColumnList;
        this.pickListModel = new PickListModel(
          this.moduleCode,
          recapColumList,
          [],
          'name'
        );
        this.isPickListReady = true;
      });
  }

  public doChangeDesc(): void {
    this.checkValidasiForm();
  }

  public doChangeName(): void {
    this.checkValidasiForm();
  }

  public doCheckColumn(event: any, column: ReportRecapColumn): void {
    event.stopPropagation();
    column.isChecked = !column.isChecked;
    event.target.checked = column.isChecked;
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid && this.pickListModel.targetItemList.length >= 3) {
      const request: ReportRecapInsertRequest = new ReportRecapInsertRequest();
      const reportBuilder: ReportBuilder = new ReportBuilder();
      const formGroup = this.formGroup.value;
      reportBuilder.id = this.reportBuilderId;
      reportBuilder.name = formGroup.name;
      reportBuilder.reportRecap = formGroup.report;
      reportBuilder.description = formGroup.description;
      request.reportBuilder = reportBuilder;
      request.reportRecapColumnList = this.reportRecapColumnList.filter(
        reportRecap => reportRecap.code === 'id'
      );
      request.reportRecapColumnList.push(...this.pickListModel.targetItemList);
      reportBuilder.generatedTableResponse = this.buildJsonTableResponse();
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<ReportBuilder>>(
                '/report-recap/insert-report-builder',
                request
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSaving();
                  this.global.modalService
                    .submitSuccessCreateNew()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router
                          .navigateByUrl('/', { skipLocationChange: true })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set('todo', 'add');
                            this.global.routerParams.set(
                              'urlBackOutside',
                              '/pages/report-recap'
                            );
                            this.router.navigate(['/pages/report-recap/add']);
                          });
                      } else {
                        this.router.navigate(['/pages/report-recap']);
                        this.setStateReady();
                      }
                    });
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public buildJsonTableResponse(): string {
    const arrayList: Array<TableColumnModel> = new Array<TableColumnModel>();
    this.pickListModel.targetItemList.forEach(reportRecapColumn => {
      if (reportRecapColumn.sheet === 1) {
        const tableColumnModel: TableColumnModel = new TableColumnModel();
        tableColumnModel.field = reportRecapColumn.code;
        tableColumnModel.header = reportRecapColumn.translationKey.key;
        tableColumnModel.className = reportRecapColumn.className;
        tableColumnModel.isSearchTable = reportRecapColumn.isSearchTable;
        tableColumnModel.isSortable = reportRecapColumn.isSortable;
        tableColumnModel.plugins = reportRecapColumn.plugins
          ? JSON.parse(reportRecapColumn.plugins)
          : null;
        tableColumnModel.dataMap = reportRecapColumn.dataMap
          ? JSON.parse(reportRecapColumn.dataMap)
          : null;
        arrayList.push(tableColumnModel);
      }
    });
    return JSON.stringify(arrayList);
  }

  public doDelete(): void {
    this.global.modalService
      .confirmation(
        'report-recap.deleteConfirmation.prompt',
        'report-recap.deleteConfirmation.title',
        'app.button.no',
        'app.button.yes',
        'pvc pv-exclamation-circle',
        false
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateLoading();
          this.httpClientService
            .post<Response<ReportRecap>>(
              '/report-recap/delete-report-builder',
              this.reportBuilderId
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.modalService.confirmation(
                  'report-recap.deleteSuccess.prompt',
                  'report-recap.deleteSuccess.title',
                  null,
                  null,
                  'pvc pv-trash',
                  null,
                  { autoCloseInMs: 1500 }
                );
                this.router.navigate(['/pages/report-recap']);
              } else {
                this.global.alertService.showError(response.statusText);
              }
              this.setStateReady();
            });
        }
      });
  }
}
