<app-layout [isLoading]="formLoading" [backTo]="'/pages/aanwijzing/edit'">
  <ng-template #additionalTitle *ngIf="!formLoading">
    <h2 class="ml-2 text-secondary">{{ aanwijzing?.code }}</h2>
  </ng-template>

  <div class="row" *ngIf="!formLoading && isCanceled">
    <div class="col">
      <app-alert-x color="DANGER" [isBold]="false">
        {{ 'aanwijzing.alert.msg.thisProcessWasCanceledBy' | translate }}
        <strong>{{ aanwijzingCancellation?.cancelByUser?.name }}</strong>
        {{ 'aanwijzing.alert.msg.at' | translate }}
        <strong>
          {{
            aanwijzingCancellation?.cancelDate
              | date: global.config.parameterModel.datetimeFormatShort
          }}
        </strong>
        {{ 'aanwijzing.alert.msg.theCancelationReasonIs' | translate }}
        <strong>&#34;{{ aanwijzingCancellation?.note }}&#34;</strong>
      </app-alert-x>
    </div>
  </div>
  <div class="row main-row">
    <div
      [ngClass]="
        isTypeOnline ? (isRoleBuyer() ? 'col-lg-8' : 'col-lg-9') : 'col-lg-12'
      "
    >
      <!-- <form
                class="form-horizontal"
                [formGroup]="formGroup"
                novalidate
                > -->
      <app-card *ngIf="!formLoading">
        <h3 class="separator">{{ 'aanwijzing.details.title' | translate }}</h3>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'aanwijzing.form.sessionNumber' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            {{ aanwijzing.code }}
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'aanwijzing.form.aanwijzingType' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            {{ getTranslateKey(aanwijzing.transactionType) | translate }}
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'aanwijzing.form.sessionPeriod' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            {{
              aanwijzing.periodStartDate
                | date: global.config.parameterModel.datetimeFormatShort
            }}
            {{ 'aanwijzing.form.until' | translate }}
            {{
              aanwijzing.periodEndDate
                | date: global.config.parameterModel.datetimeFormatShort
            }}
          </div>
        </div>
        <div *ngIf="!isTypeOnline" class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'aanwijzing.form.location' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            {{ aanwijzing.location }}
          </div>
        </div>
        <div class="form-group row text-sm-left">
          <label class="col-sm-3">
            {{ 'aanwijzing.form.subject' | translate }}
          </label>
          <div class="col-sm-9 text-left">
            {{ aanwijzing.subject }}
          </div>
        </div>
      </app-card>

      <!-- </form> -->
    </div>

    <div
      [ngClass]="isRoleBuyer() ? 'col-lg-4' : 'col-lg-3'"
      *ngIf="isTypeOnline"
    >
      <app-card>
        <app-session-timer
          [startDate]="aanwijzing?.sessionStartTime"
          [endDate]="aanwijzing?.sessionEndTime"
          [isShowActionButton]="isRoleBuyer()"
          [isDisableStartButton]="
            (!isInvite && !aanwijzing.sessionStartTime) ||
            aanwijzing.sessionEndTime ||
            !isTolerated ||
            formGroup.isView
          "
          [isDisableEndButton]="
            (!isInvite && !aanwijzing.sessionStartTime) ||
            aanwijzing.sessionEndTime ||
            formGroup.isView
          "
          (onChange)="onChangeTimer($event)"
        ></app-session-timer>
      </app-card>
    </div>
  </div>

  <div class="row" *ngIf="isTypeOnline">
    <div class="col">
      <app-chat
        #appChat
        [roomCode]="aanwijzing.roomCode"
        stringUrl="/aanwijzing"
        [sessionStatus]="aanwijzing.aanwijzingStatus?.code"
        [aanwijzingId]="aanwijzing.id"
      >
        <ng-template #footerButtons *ngIf="isRoleBuyer()">
          <app-button
            (onClick)="doInviteParticipant()"
            [disabled]="aanwijzing.sessionEndTime || formGroup.isView"
          >
            {{ 'aanwijzing.button.inviteParticipant' | translate }}
          </app-button>

          <app-button
            (onClick)="doSendReminder()"
            color="LIGHT"
            [outline]="false"
            [disabled]="
              aanwijzing.sessionEndTime || formGroup.isView || isAllReminded
            "
          >
            {{ 'aanwijzing.button.sendReminder' | translate }}
          </app-button>
          <!-- <div style="display: inline-block;
                        width: 100%;
                        text-align: center;
                        padding: 8px;
                        margin: 0 auto;
                        cursor: pointer;"
                        (click)="doSendReminder()"
                        [disabled]="aanwijzing.sessionEndTime"
                        >
                    {{ 'aanwijzing.button.sendReminder' | translate }}
                </div> -->
        </ng-template>
      </app-chat>
    </div>
  </div>

  <div class="row" *ngIf="!isTypeOnline">
    <!--OFFLINE-->
    <div class="col">
      <app-card>
        <h3 class="separator">{{
          'aanwijzing.listOfAttendees.title' | translate
        }}</h3>
        <div *ngIf="!formLoading" class="button-group button-group-right">
          <!-- <ng-container *ngIf="">
                        
                    </ng-container> -->
          <app-button
            *ngIf="
              isRoleBuyer() &&
              aanwijzing?.aanwijzingStatus?.code !==
                global.appConstant.pm.AANWIJZING_STATUS_CANCELED
            "
            color="PRIMARY"
            [outline]="true"
            (onClick)="doPrintAttendance()"
          >
            {{ 'aanwijzing.button.downloadList' | translate }}
          </app-button>
          <app-button
            *ngIf="
              !formGroup.isView &&
              isRoleBuyer() &&
              aanwijzing.aanwijzingStatus?.code !==
                global.appConstant.pm.AANWIJZING_STATUS_CLOSED
            "
            (onClick)="doShowQRCode()"
          >
            <em class="pir pi-qr-code"></em>
            {{ 'aanwijzing.button.showQRCode' | translate }}
          </app-button>

          <app-button
            *ngIf="
              !formGroup.isView &&
              !isRoleBuyer() &&
              !isJoinOffline &&
              isTolerated &&
              aanwijzing.aanwijzingStatus?.code !==
                global.appConstant.pm.AANWIJZING_STATUS_CLOSED &&
              !response.aanwijzingParticipant.attendanceDate
            "
            (onClick)="doAttendanceForm()"
          >
            {{ 'aanwijzing.button.attendanceForm' | translate }}
          </app-button>
        </div>

        <ng-container
          *ngIf="
            aanwijzing?.aanwijzingStatus?.code ===
            global.appConstant.pm.AANWIJZING_STATUS_CANCELED
          "
        >
          <div
            style="
              text-align: -webkit-center;
              alignment-baseline: ideographic;
              vertical-align: top;
            "
          >
            <div class="pvc pv-clock-times pv-2x mb-3"></div>
            <p class="text-secondary">{{
              'aanwijzing.info.thisSessionIsCanceled' | translate
            }}</p>
          </div>
        </ng-container>

        <app-tab
          *ngIf="
            aanwijzing?.aanwijzingStatus?.code !==
            global.appConstant.pm.AANWIJZING_STATUS_CANCELED
          "
        >
          <app-tab-item header="aanwijzing.tab.internal">
            <app-table-xx [model]="tableResponsePartInternal">
              <ng-template #noDataTemplate>
                <div class="ndtw-icon">
                  <div class="pvc pv-user-friends"></div>
                </div>
                <div class="ndtw-content">
                  <span class="ndtwc-text">
                    {{ 'aanwijzing.info.noDataAvailable' | translate }}
                  </span>
                  <span class="ndtwc-text">
                    {{
                      'aanwijzing.info.noParticipantFillInAttendeesForm'
                        | translate
                    }}
                  </span>
                </div>
              </ng-template>
            </app-table-xx>
          </app-tab-item>

          <app-tab-item header="aanwijzing.tab.vendor">
            <app-table-xx [model]="tableResponsePartVendor">
              <ng-template #noDataTemplate>
                <div class="ndtw-icon">
                  <div class="pvc pv-user-friends"></div>
                </div>
                <div class="ndtw-content">
                  <span class="ndtwc-text">
                    {{ 'aanwijzing.info.noDataAvailable' | translate }}
                  </span>
                  <span class="ndtwc-text">
                    {{
                      'aanwijzing.info.noParticipantFillInAttendeesForm'
                        | translate
                    }}
                  </span>
                </div>
              </ng-template>
            </app-table-xx>
          </app-tab-item>

          <app-tab-item header="aanwijzing.tab.others">
            <app-table-xx [model]="tableResponsePartOthers">
              <ng-template #noDataTemplate>
                <div class="ndtw-icon">
                  <div class="pvc pv-user-friends"></div>
                </div>
                <div class="ndtw-content">
                  <span class="ndtwc-text">
                    {{ 'aanwijzing.info.noDataAvailable' | translate }}
                  </span>
                  <span class="ndtwc-text">
                    {{
                      'aanwijzing.info.noParticipantFillInAttendeesForm'
                        | translate
                    }}
                  </span>
                </div>
              </ng-template>
            </app-table-xx>
          </app-tab-item>
        </app-tab>
      </app-card>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <app-card *ngIf="!formLoading && isShowSummary()">
          <h3 class="separator">{{
            'aanwijzing.aanwijzingSummary.title' | translate
          }}</h3>
          <app-fullfilled *ngIf="!formGroup.isView"></app-fullfilled>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'aanwijzing.form.letterNumber' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-field
                formControlName="letterNumber"
                maxLength="32"
              ></app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'aanwijzing.form.summary' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-text-area
                size="LG"
                formControlName="summary"
                maxLength="5000"
              ></app-text-area>
            </div>
          </div>

          <div
            class="form-group row text-sm-left"
            *ngIf="
              global.userSession.activeUserRole.role.code !==
              global.appConstant.ROLE_CODE_VENDOR
            "
          >
            <label class="col-sm-3">
              {{ 'aanwijzing.form.officialReport' | translate }}
            </label>
            <div class="col-sm-9 text-left" style="display: grid">
              <app-button (onClick)="doGenerate($event)">
                <ng-container
                  *ngIf="
                    aanwijzing.aanwijzingStatus.code ===
                    this.global.appConstant.pm.AANWIJZING_STATUS_CLOSED
                  "
                >
                  {{ 'aanwijzing.button.generateDownloadTemplate' | translate }}
                </ng-container>
                <ng-container
                  *ngIf="
                    aanwijzing.aanwijzingStatus.code !==
                    this.global.appConstant.pm.AANWIJZING_STATUS_CLOSED
                  "
                >
                  {{ 'aanwijzing.button.generateDownloadReport' | translate }}
                </ng-container>
              </app-button>
              <small class="text-secondary">{{
                'aanwijzing.form.thisFileIncludeOfficialReport' | translate
              }}</small>
            </div>
          </div>

          <div class="form-group row text-sm-left">
            <label class="col-sm-3">
              {{ 'aanwijzing.form.attachment' | translate }}
            </label>
            <div class="col-sm-9 text-left">
              <app-upload-x
                formControlName="docList"
                [fileUploader]="fileUploader"
              ></app-upload-x>
            </div>
          </div>

          <div class="button-group button-group-center">
            <app-button
              *ngIf="!formGroup.isView"
              color="LIGHT"
              [outline]="true"
              (onClick)="doSave()"
              [disabled]="formGroup.invalid"
            >
              {{ 'app.button.save' | translate }}
            </app-button>
            <app-button
              *ngIf="!formGroup.isView"
              (onClick)="doSubmitSession()"
              [disabled]="formGroup.invalid"
            >
              {{ 'aanwijzing.button.submitSession' | translate }}
            </app-button>
          </div>
        </app-card>

        <div *ngIf="!formLoading" class="button-group button-group-center">
          <app-button
            *ngIf="
              !isCanceled &&
              canCancelStatusList.includes(aanwijzing.aanwijzingStatus?.code) &&
              isRoleBuyer() &&
              !aanwijzing.sessionStartTime
            "
            (onClick)="doCancel()"
            color="DANGER"
            [outline]="true"
          >
            <em class="pir pi-times-circle"></em>
            {{ 'aanwijzing.button.cancelSession' | translate }}
          </app-button>

          <app-button
            *ngIf="
              !isTypeOnline &&
              aanwijzing.aanwijzingStatus?.code ===
                global.appConstant.pm.AANWIJZING_STATUS_ON_PROGRESS &&
              isRoleBuyer()
            "
            (onClick)="doClose()"
            color="LIGHT"
            [outline]="true"
          >
            {{ 'aanwijzing.button.closeSession' | translate }}
          </app-button>
        </div>
      </form>
    </div>
  </div>

  <!-- <div style=" background: no-repeat;background-position: center; width: 100%; height: 200px; background-image: url('/assets/svg/scan-qr-code-tutorial/scan-qr-code-tutorial-1.svg');"></div>     -->
</app-layout>
