import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ProcurementDocType } from '../../core/bean/procurement-doc-type';
import { AppTableComponent } from '../../core/components/app-table/app-table.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './procurement-doc-type.component.html'
})
export class ProcurementDocTypeComponent extends BaseModuleComponent {
  @ViewChild(AppTableComponent) table: AppTableComponent;
  public tableResponse: TableResponseModel<ProcurementDocType>;
  constructor(translateService: TranslateService) {
    super('procurement-doc-type', translateService);
  }

  onInit(): void {
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: '0.code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      {
        field: '1',
        header: 'table.column.name',
        customClass: 'text-center'
      },
      {
        field: '0.description',
        header: 'table.column.description',
        customClass: 'text-center'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/procurement-doc-type/add']);
  }

  public doEdit(rowData: any): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('procurementDocTypeId', rowData[0].id);
    this.router.navigate(['/pages/procurement-doc-type/edit']);
  }

  public doDelete(rowData: any): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.httpClientService
            .post<Response<ProcurementDocType>>(
              '/procurement-doc-type/delete',
              rowData[0]
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }
}
