import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { PaymentStatus } from 'src/app/core/bean/payment-status';
import { AppPopupChooseBillService } from 'src/app/core/components/app-popup/app-popup-choose-bill/app-popup-choose-bill.service';
import { AppPopupService } from 'src/app/core/components/app-popup/app-popup.service';
import { AppTableComponent } from 'src/app/core/components/app-table/app-table.component';
import { FieldFormatEnum } from 'src/app/core/components/app-table/model/field-format.enum';
import { TableResponseModel } from 'src/app/core/components/app-table/model/table-response-model';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { PaymentBillingView } from 'src/app/core/view/entity/bean/payment-billing-view';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
@Component({
  templateUrl: './vendor-payment-request.component.html'
})
export class VendorPaymentRequestComponent
  extends BaseModuleComponent
  implements OnInit
{
  @ViewChild(AppTableComponent) public table: AppTableComponent;
  public tableResponse: TableResponseModel<PaymentBillingView>;
  public paymentStatusOptionList: OptionListModel<PaymentStatus> =
    new OptionListModel(true);
  public isLoading: boolean;
  public readonly urlDeleteVendorPaymentRequest =
    '/vendor-payment-request/delete';

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupChooseBillService: AppPopupChooseBillService
  ) {
    super('vendor-payment-request', translateService);
  }

  onInit(): void {
    this.buildFormGroup();
    this.setOptionList();
    this.buildTableResponse();
    this.setStateReady();
    localStorage.removeItem('temp');
    localStorage.removeItem('todo');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      startDate: [null],
      endDate: [null],
      paymentStatusList: [null]
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<PaymentStatus>('/vendor-payment-request/get-option-list')
      .subscribe((response: PaymentStatus[]) => {
        this.paymentStatusOptionList.setRequestValues(response);

        this.setStateReady();
      });
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'paymentInvoice.code',
        header: 'table.column.invoiceNumber'
      },
      {
        field: 'paymentInvoice.invoiceDate',
        header: 'table.column.invoiceDate',
        format: FieldFormatEnum.ShortDate
      },
      {
        field: 'code',
        header: 'table.column.code'
      },
      {
        field: 'title',
        header: 'table.column.title'
      },
      {
        field: 'amount',
        header: 'table.column.amount',
        format: FieldFormatEnum.Currency
      },
      {
        field: 'workflowModelPrcsName',
        header: 'table.column.stage'
      },
      {
        field: 'payment.paymentStatus.name',
        header: 'table.column.paymentStatus'
      }
    ]);
  }

  public doAddBill(): void {
    this.appPopupChooseBillService.open(false).subscribe(paymentBilling => {
      this.log.debug(paymentBilling);
      this.global.routerParams.clear();
      this.global.routerParams.set('todo', 'add');
      this.global.routerParams.set('paymentBillingId', paymentBilling.id);
      this.global.routerParams.set(
        'sowPaymentTermId',
        paymentBilling.sowPaymentTerm.id
      );
      this.global.routerParams.set(
        'vendorPaymentRequestBilling',
        paymentBilling
      );
      this.global.routerParams.set(
        'urlBackOutside',
        '/pages/vendor-payment-request'
      );
      this.router.navigate(['/pages/vendor-payment-request/add']);
    });
  }

  public doEdit(paymentBillingView: PaymentBillingView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('paymentId', paymentBillingView.payment.id);
    this.global.routerParams.set('payment', paymentBillingView.payment);
    this.global.routerParams.set('paymentBillingView', paymentBillingView);
    this.global.routerParams.set('paymentBillingId', paymentBillingView.id);
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/vendor-payment-request'
    );
    this.router.navigate(['/pages/vendor-payment-request/edit']);
  }

  public doDetail(paymentBillingView: PaymentBillingView): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('paymentId', paymentBillingView.payment.id);
    this.global.routerParams.set('payment', paymentBillingView.payment);
    this.global.routerParams.set('paymentBillingId', paymentBillingView.id);
    this.global.routerParams.set('paymentBillingView', paymentBillingView);
    this.global.routerParams.set(
      'urlBackOutside',
      '/pages/vendor-payment-request'
    );
    this.router.navigate(['/pages/vendor-payment-request/detail']);
  }

  public doDelete(paymentBillingViewBean: PaymentBillingView): void {
    this.global.modalService
      .deleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.tableResponse.loading = true;
          this.httpClientService
            .post<Response<PaymentBillingView>>(
              '/vendor-payment-request/delete',
              paymentBillingViewBean
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessDelete();
                this.table.reload();
              } else {
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doApply(): void {
    this.global.routerParams.clear();
    this.tableResponse.setCustomData(this.formGroup.value);
    this.tableResponse.reload();
  }

  public doReset(): void {
    this.formGroup.reset();
    this.tableResponse.resetCustomData();
    this.tableResponse.reload();
  }
}
