import { OfficialMemoSubmission } from '../../core/bean/official-memo-submission';
import { Organization } from '../../core/bean/organization';
import { WorkPlan } from '../../core/bean/work-plan';
import { ApprovalPathResponseModel } from '../../core/components/app-approval-path';

export class OfficialMemoSubmissionResponse {
  officialMemo: OfficialMemoSubmission;
  approvalPathResponseModel: ApprovalPathResponseModel;
  isUserApproval: boolean;
  isNumberGenerator: boolean;
  workPlan: WorkPlan;
  fileList: File[];
  organizationList: Organization[];
}
