import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';
@Component({
  templateUrl: './tab-contract-history.component.html'
})
export class TabContractHistoryComponent extends BaseModuleComponent {
  @Input() model: AccordionItemModel<Array<object>>;
  constructor(translateService: TranslateService) {
    super('tab-contract-history', translateService);
  }

  public onInit(): void {
    this.setStateReady();
  }

  public doBack(): void {
    this.router.navigate(['/pages/vendor-history/detail']);
  }
}
