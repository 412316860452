<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="catalog-verification.title" [isLoading]="formLoading"
      [isSaving]="formSaving">
      <app-table [tableResponse]="tableResponse" [isServerSide]="true"
        stringUrl="/catalog-verification/index">
        <ng-template #actionButtons let-rowData>
          <app-button size="TN" (onClick)="doEdit(rowData)"
            title="{{ 'app.tooltip.edit' | translate }}">
            <span class="fas fa-pencil-alt"></span>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>