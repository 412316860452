<app-layout
  [isLoading]="formLoading"
  header="submit-quotation.breadcrumb.index"
  [backTo]="urlBackOutside"
>
  <ng-template #additionalTitle *ngIf="isShowBadgeStatus">
    <app-badge
      class="cml-2"
      *ngIf="
        procurement?.procurementWorklistStatus?.code ===
        global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_NEW
      "
      color="INFO"
    >
      {{ 'dynamic-master-attribute.worklistStatus.new' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        procurement?.procurementWorklistStatus?.code ===
        global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_WAITING_RESPONSE
      "
      color="GOOD"
    >
      {{
        'dynamic-master-attribute.worklistStatus.waitingResponse' | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        procurement?.procurementWorklistStatus?.code ===
        global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_WAITING_APPROVAL
      "
      color="WARNING"
    >
      {{
        'dynamic-master-attribute.worklistStatus.waitingApproval' | translate
      }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        procurement?.procurementWorklistStatus?.code ===
        global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_CLOSED_RESPONSE
      "
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.worklistStatus.closedResponse' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        procurement?.procurementWorklistStatus?.code ===
          global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_REVISION
      "
      color="FEEDBACK"
    >
      {{ 'dynamic-master-attribute.worklistStatus.revision' | translate }}
    </app-badge>
    <app-badge
      class="cml-2"
      *ngIf="
        todo !== 'add' &&
        procurement?.procurementWorklistStatus?.code ===
          global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_HOLD
      "
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.worklistStatus.hold' | translate }}
    </app-badge>
    <app-badge-catalog
      class="cml-2"
      *ngIf="procurement?.isRepeat"
      color="DANGER"
    >
      {{ 'dynamic-master-attribute.procurementFlag.reHeld' | translate }}
    </app-badge-catalog>
    <app-badge-catalog
      *ngIf="procurement?.documentSubmissionMethod"
      class="cml-2"
      [color]="'SUCCESS'"
    >
      {{ procurement?.documentSubmissionMethod?.name }}
    </app-badge-catalog>
  </ng-template>

  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <form
        class="form-horizontal"
        [formGroup]="formGroup"
        novalidate
        *ngIf="!formLoading"
      >
        <app-alert-x
          color="DANGER"
          *ngIf="
            !formLoading &&
            procurement.procurementStatus?.code ===
              global.appConstant.pm.PROCUREMENT_STATUS_WAITING_CANCEL_APPROVAL
          "
        >
          {{
            'submit-quotation.alert.msg.sorryWaitingCancelationApproval'
              | translate
          }}
        </app-alert-x>
        <app-alert-x
          color="DANGER"
          *ngIf="
            !formLoading &&
            [
              global.appConstant.pm.PROCUREMENT_STATUS_WAITING_FAILED_APPROVAL,
              this.global.appConstant.pm.PROCUREMENT_STATUS_FAILED_ASSESSMENT
            ].includes(procurement.procurementStatus?.code)
          "
        >
          {{
            'submit-quotation.alert.msg.sorryWaitingFailedApproval' | translate
          }}
        </app-alert-x>
        <app-workflow-step-info
          [isLoading]="formLoading"
          [model]="submitQuotationResponse.workflowStepInfoModel"
        >
        </app-workflow-step-info>
        <app-card
          header="submit-quotation.detail.general.title"
          tips="submit-quotation.detail.general.tips"
        >
          <app-procurement-info [procurementId]="procurementId">
          </app-procurement-info>
        </app-card>

        <app-item-request
          [objectList]="submitQuotationResponse.procurementItemList"
          [isShowPrice]="true"
          [isView]="true"
          [itemTypeList]="submitQuotationResponse.itemTypeList"
          (onChange)="doChangeItemRequest($event)"
        ></app-item-request>

        <app-card>
          <app-table-xx
            header="submit-quotation.detail.submitQuotationMonitoring.title"
            tips="submit-quotation.detail.submitQuotationMonitoring.tips"
            [model]="tableResponse"
            *ngIf="!formLoading"
            [isShowSearch]="tableResponse.getRecordList().length > 0"
          >
          </app-table-xx>
        </app-card>

        <app-approval-monitoring
          *ngIf="
            !formLoading &&
            submitQuotationResponse.approvalPathResponseModel &&
            procurement?.procurementWorklistStatus?.code !==
              global.appConstant.pm
                .PROCUREMENT_WORKLIST_STATUS_WAITING_RESPONSE &&
            procurement?.procurementWorklistStatus?.code !==
              global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_NEW &&
            !submitQuotationResponse.isUserApproval &&
            procurement.procurementWorklistStatus?.code !==
              global.appConstant.pm.PROCUREMENT_WORKLIST_STATUS_HOLD
          "
          [workflowPrcs]="procurement?.workflowPrcs"
          [moduleCode]="procurement?.workflowModelPrcs?.module?.code"
          [approvalPathResponseModel]="
            submitQuotationResponse.approvalPathResponseModel
          "
        ></app-approval-monitoring>
        <app-approval-prcs-x
          *ngIf="!formLoading && submitQuotationResponse.isUserApproval"
          [workflowModelPrcs]="procurement?.workflowModelPrcs"
          [approvalModelPrcsId]="approvalModelPrcsId"
        >
        </app-approval-prcs-x>
        <div class="floating-button mt-5" *ngIf="todo !== 'view'">
          <div class="floating-button-wrapper">
            <div class="floating-button-content">
              <div class="button-group button-group-center">
                <app-button (onClick)="doSubmit()" [disabled]="formSaving">{{
                  'app.button.submit' | translate
                }}</app-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #contentSidebarRight>
    <app-tips></app-tips>
  </ng-template>
</app-layout>
