import { BaseEntity } from '../base/base-entity';
import { User } from '../bean/user';
import { WorkPlan } from '../bean/work-plan';
import { WorkflowModelPrcs } from '../bean/workflow-model-prcs';

export class SlaJustificationLogEntity extends BaseEntity {
  workPlan: WorkPlan;
  workflowModelPrcs: WorkflowModelPrcs;
  userCreated: User;
  createdDate: Date;
  justificationDayTotal: number;
  justificationAfterDayTotal: number;
  description: string;
}
