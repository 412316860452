import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Condition } from './../../core/bean/condition';
@Component({
  templateUrl: 'condition.component.html'
})
export class ConditionComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Condition>;
  constructor(translateService: TranslateService) {
    super('condition', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      { field: 'code', header: 'table.column.code', customClass: 'text-center' },
      { field: 'name', header: 'table.column.name', customClass: 'white-space-normal' },
      { field: 'moduleGroup.name', header: 'table.column.moduleGroup', customClass: 'text-center' },
      { field: 'description', header: 'table.column.description', customClass: 'white-space-normal' }
    ]);
  }
}
