import { CurrencyPipe, DatePipe } from '@angular/common';
import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Global } from '../../../global/global';
import { AppDynamicComponentService } from '../../../services/app-dynamic-component.service';
import { ArrayUtils } from '../../../utils';
import { AllTablePluginOptions } from '../interfaces/available-table-plugin';
import { TablePlugin } from '../interfaces/table-plugin';
import { TablePluginData } from '../interfaces/table-plugin-data';
import { FieldFormatEnum } from '../model/field-format.enum';
import { TableFieldModel } from '../model/table-field-model';
import { TableRecord } from '../model/table-record';
import { TableState } from '../model/table-state';
import { ResolveColumnClassNamePipe } from '../pipe/resolve-column-class-name.pipe';
import { TablePluginService } from '../services/table-plugins.service';
@Directive({
  selector: '[app-table-td]'
})
export class TableTdDirective implements OnInit {
  @Input() tableRecord: TableRecord;
  @Input() column: TableFieldModel;
  @Input() state: TableState;
  @Input() indexI: number;
  @Input() indexJ: number;

  private value: any;
  private divTdContentElement: HTMLElement;
  private isHasReturnBeforeValue: any;
  constructor(
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    private tablePluginService: TablePluginService,
    private datePipe: DatePipe,
    private global: Global,
    private currencyPipe: CurrencyPipe,
    private resolveColumnClassNamePipe: ResolveColumnClassNamePipe,
    private appDynamicComponentService: AppDynamicComponentService
  ) {}

  ngOnInit(): void {
    this.setInitializationValueState();
    this.createTDContentElement();
    this.createValueFromFn();
    this.callPluginBefore();
    this.createValueFromFormat();
    this.createTDContent();
    this.callPluginAfter();
  }

  private setInitializationValueState(): void {
    const record =
      this.state.store.recordMap.get(JSON.stringify(this.tableRecord.record)) ||
      this.tableRecord.record;
    this.value = ArrayUtils.resolveFieldData(
      record,
      this.column.field,
      this.column.datamap
    );
  }

  private createTDContentElement(): void {
    this.divTdContentElement = this.renderer2.createElement('div');
    this.renderer2.addClass(this.divTdContentElement, 'td-content');
    this.resolveColumnClassNamePipe
      .transform(this.column)
      .split(' ')
      .forEach(className => {
        if (className && className.trim() !== '') {
          this.renderer2.addClass(this.divTdContentElement, className);
        }
      });
    this.renderer2.appendChild(
      this.elementRef.nativeElement,
      this.divTdContentElement
    );
  }

  private createValueFromFn(): void {
    if (this.column.fn) {
      const value =
        this.column.fn(
          this.value,
          this.tableRecord.record,
          this.divTdContentElement
        ) || '-';
      let textElement: any;
      if (!value.childNodes) {
        this.value = value;
      } else {
        this.renderer2.appendChild(this.divTdContentElement, textElement);
      }
    }
  }

  private callPluginBefore(): void {
    this.isHasReturnBeforeValue = true;
    if (this.column.component) {
      this.appDynamicComponentService.create(
        this.column.component,
        this.divTdContentElement,
        { model: this.tableRecord.record }
      );
      this.isHasReturnBeforeValue = false;
    }

    if (this.column.plugins) {
      const tablePluginOptions = Array.isArray(this.column.plugins)
        ? this.column.plugins
        : [this.column.plugins];
      tablePluginOptions.forEach(
        (tablePluginOption: AllTablePluginOptions | TablePlugin) => {
          let tablePlugin: TablePlugin;
          const tablePluginData: TablePluginData = {
            indexI: this.indexI,
            indexJ: this.indexJ,
            record: this.tableRecord,
            element: this.divTdContentElement,
            state: this.state,
            value: this.value,
            column: this.column
          };
          if (
            typeof tablePluginOption === 'object' &&
            tablePluginOption.hasOwnProperty('before')
          ) {
            tablePlugin = tablePluginOption as TablePlugin;
          } else {
            const plugin = tablePluginOption as AllTablePluginOptions;
            let tablePluginName: string =
              typeof plugin === 'string' ? plugin : plugin.name;
            tablePlugin = this.tablePluginService.getPlugin(
              tablePluginName as string
            );
            tablePluginData.plugin = plugin;
          }

          if (tablePlugin && tablePlugin.before) {
            const result = tablePlugin.before(tablePluginData);
            this.isHasReturnBeforeValue = !!result;
            if (this.isHasReturnBeforeValue) {
              this.value = result;
            }
          }
        }
      );
    }
  }

  private createValueFromFormat(): void {
    if (this.value !== null && typeof this.column.format !== 'undefined') {
      if (this.column.format < 4) {
        const arrayDateFormat = [
          this.global.config.parameterModel.dateFormatShort,
          this.global.config.parameterModel.datetimeFormatShort,
          this.global.config.parameterModel.dateFormatLong,
          this.global.config.parameterModel.datetimeFormatLong
        ];
        this.value =
          this.datePipe.transform(
            this.value,
            arrayDateFormat[this.column.format]
          ) || '-';
      } else if (this.column.format === FieldFormatEnum.Currency) {
        const currencyCode =
          this.column.currencyCodeRef &&
          ArrayUtils.resolveFieldData(
            this.tableRecord.record,
            this.column.currencyCodeRef
          ) + ' ';
        this.value =
          this.currencyPipe.transform(this.value, '', '') +
          ' ' +
          (currencyCode || 'IDR ');
      }
    }
  }

  private createTDContent(): void {
    if (this.isHasReturnBeforeValue) {
      const textContent = this.renderer2.createText(
        (this.value && this.global.translateService.instant(`${this.value}`)) ||
          '-'
      );
      const spanTdText = this.renderer2.createElement('span');
      this.renderer2.addClass(spanTdText, 'td-text');
      this.renderer2.appendChild(spanTdText, textContent);

      this.renderer2.appendChild(this.divTdContentElement, spanTdText);
    }
  }

  private callPluginAfter(): void {
    if (this.column.plugins) {
      const tablePluginOptions = Array.isArray(this.column.plugins)
        ? this.column.plugins
        : [this.column.plugins];
      tablePluginOptions.forEach(
        (tablePluginOption: AllTablePluginOptions | TablePlugin) => {
          let tablePlugin: TablePlugin;
          const tablePluginData: TablePluginData = {
            indexI: this.indexI,
            indexJ: this.indexJ,
            record: this.tableRecord,
            element: this.divTdContentElement,
            state: this.state,
            value: this.value,
            column: this.column
          };

          if (
            typeof tablePluginOption === 'object' &&
            tablePluginOption.hasOwnProperty('after')
          ) {
            tablePlugin = tablePluginOption as TablePlugin;
          } else {
            const plugin = tablePluginOption as AllTablePluginOptions;
            let tablePluginName: string =
              typeof plugin === 'string' ? plugin : plugin.name;
            tablePlugin = this.tablePluginService.getPlugin(
              tablePluginName as string
            );
            tablePluginData.plugin = plugin;
          }

          if (tablePlugin && tablePlugin.after) {
            tablePlugin.after(tablePluginData);
          }
        }
      );
    }
  }
}
