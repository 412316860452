import { NotificationBlast } from '../../core/bean/notification-blast';
import { NotificationBlastAttachment } from '../../core/bean/notification-blast-attachment';
import { NotificationBlastUser } from '../../core/bean/notification-blast-user';
import { User } from '../../core/bean/user';
import { Vendor } from '../../core/bean/vendor';
import { VendorCategory } from '../../core/bean/vendor-category';
import { VendorStatus } from '../../core/bean/vendor-status';
import { VendorType } from '../../core/bean/vendor-type';
import { FileObject } from '../../core/components/upload';

export class NotificationBlastResponse {
  notificationBlast: NotificationBlast = new NotificationBlast();
  notificationBlastUserList: NotificationBlastUser[] = [];
  notificationBlastAttachmentList: NotificationBlastAttachment[] = [];
  userList: User[] = [];
  vendorStatusList: VendorStatus[] = [];
  vendorTypeList: VendorType[] = [];
  vendorCategoryList: VendorCategory[] = [];
  vendorList: Vendor[] = [];
  attachmentList: FileObject[] = [];
  attachmentFileList: File[] = [];
  ccList: string[] = [];
}
