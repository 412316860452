import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from './../../core/shared/shared.module';
import { ResourceEditAddComponent } from './resource-edit-add.component';
import { ResourceComponent } from './resource.component';
export const routes = [
  { path: '', component: ResourceComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: ResourceEditAddComponent,
    data: { breadcrumb: 'resource.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: ResourceEditAddComponent,
    data: { breadcrumb: 'resource.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [ResourceComponent, ResourceEditAddComponent],
  providers: []
})
export class ResourceModule {}
