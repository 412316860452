import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';

@Component({
  selector: 'app-fullfilled',
  templateUrl: './app-fullfilled.component.html',
  styleUrls: ['./app-fullfilled.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppFullfilledComponent extends BaseComponentComponent {
  @Input() public isOptional: boolean = false;
  constructor(public translate: TranslateService) {
    super('app-fullfilled');
  }

  public onInit(): void {}
}
