import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { CountryFlagEditAddComponent } from './country-flag-edit-add.component';
import { CountryFlagComponent } from './country-flag.component';
const routes: Routes = [
  { path: '', component: CountryFlagComponent, data: { breadcrumb: '' } },
  {
    path: 'add',
    component: CountryFlagEditAddComponent,
    data: { breadcrumb: 'country-flag.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'edit',
    component: CountryFlagEditAddComponent,
    data: { breadcrumb: 'country-flag.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [CountryFlagComponent, CountryFlagEditAddComponent]
})
export class CountryFlagModule {}
