<app-layout>
    <div class="row main-row">
      <div class="col-lg-12 mb-4">
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
            <app-card>
                <div class="form-group row text-sm-left">
                    <label class="col-12">
                      {{ 'attendance-form.form.procurementName' | translate }}
                    </label>
                    <div class="col-12 text-left">
                      <app-text-field
                        formControlName="name"
                        placeholder="{{
                          'attendance-form.placeholder.inputText' | translate
                        }}"
                        maxLength="128"
                      >
                      </app-text-field>
                    </div>
                </div>
            </app-card>
        </form>
        
      </div>
    </div>
</app-layout>