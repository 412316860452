import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { File } from '../../core/bean/file';
import { News } from '../../core/bean/news';
import { FileUploader } from '../../core/components/upload';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { NewsRequest } from './news-request';
@Component({
  templateUrl: './news-edit-add.component.html'
})
export class NewsEditAddComponent extends BaseModuleComponent {
  public newsId: number;
  public organizationName: string = this.global.userSession.user.organization
    .name;
  public fileUploader: FileUploader = new FileUploader(
    '/news/',
    'news.form.headerImage',
    this.global.appConstant.fileType.IMG_NEWS
  );
  public currentDate: Date = new Date();
  constructor(translateService: TranslateService) {
    super('news', translateService);
  }

  public onInit(): void {
    this.doSetDataFromRouterParams();
    this.doBuildFormGroup();
    this.setFormGroup();
  }

  public doSetDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.newsId = this.global.routerParams.get('newsId');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      title: [null, Validators.required()],
      organization: [null],
      publishDate: [null, Validators.required()],
      isActive: [false],
      body: [null, Validators.required()],
      viewCount: [0],
      headerImage: [null, Validators.required()],
      imgFile: [new File()]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<News>(
        '/news/add-edit',
        new RouteRequestModel(this.todo, this.newsId)
      )
      .subscribe((news: News) => {
        if (news != null) {
          this.fileUploader.setFile(news.imgFile);
          const publishDate: Date = new Date(news.publishDate);
          this.formGroup.patchValue(news);
          this.formGroup.patchValue({
            publishDate,
            headerImage: this.fileUploader.fileObjectList
          });
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const news: News = this.global.copyFormAttributeToModel(
              new News(),
              this.formGroup
            );
            const newsRequest: NewsRequest = new NewsRequest();
            newsRequest.fileObjectList = this.fileUploader.fileObjectList;
            newsRequest.news = news;
            const url = this.todo === 'edit' ? '/news/update' : '/news/insert';
            this.httpClientService
              .post<Response<News>>(url, newsRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/news']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/news']);
  }
}
