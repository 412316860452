import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Resource } from 'src/app/core/bean/resource';
import { FileUploader } from 'src/app/core/components/upload';
import { ResourceModel } from 'src/app/core/model/resource.model';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { AppLoadService } from 'src/app/core/services/app-load.service';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './background-image-edit-add.component.html'
})
export class BackgroundImageEditAddComponent extends BaseModuleComponent {
  public appLoadService: AppLoadService;
  public fileUploaderList: FileUploader[] = new Array();
  constructor(
    translateService: TranslateService,
    appLoadService: AppLoadService
  ) {
    super('background-image', translateService);
    this.appLoadService = appLoadService;
  }

  public onInit(): void {
    this.doSetTodoFromRouterParams();
    this.doBuildFormGroup();
    this.doSetFormGroup();
  }

  public doSetTodoFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      resourceList: this.formBuilder.array([])
    });
  }

  public doSetFormGroup(): void {
    this.httpClientService
      .get<Response<ResourceModel[]>>('/background-image/add-edit')
      .subscribe((response: Response<ResourceModel[]>) => {
        if (response.status === ResponseStatusModel.OK) {
          const control = this.formGroup.controls.resourceList as FormArray;
          response.body.forEach(
            (resourceModel: ResourceModel, index: number) => {
              const { id, code, name, height, width, imgFile } =
                resourceModel.resource;
              if (imgFile) {
                this.formBuilder.group({ fileType: [imgFile.fileType] });
                this.fileUploaderList[index] = new FileUploader(
                  '/background-image/',
                  '',
                  this.global.appConstant.fileType.IMG_PORTAL
                );
                this.fileUploaderList[index].setFile(
                  resourceModel.resource.imgFile
                );
              } else {
                this.formBuilder.group({ fileType: [resourceModel.fileType] });
                this.fileUploaderList[index] = new FileUploader(
                  '/background-image/',
                  '',
                  this.global.appConstant.fileType.IMG_PORTAL
                );
              }
              control.push(
                this.formBuilder.group({
                  id: [id],
                  code: [code],
                  name: [name],
                  height: [height],
                  width: [width],
                  file: [
                    this.fileUploaderList[index].fileObjectList,
                    Validators.required()
                  ]
                })
              );
            }
          );
          this.setStateReady();
        } else {
          this.global.alertService.showError(response.statusText);
          this.setStateReady();
        }
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const resourceModelList: Array<ResourceModel> =
              this.getResourceModelList();
            this.httpClientService
              .post<Response<ResourceModel>>(
                '/background-image/update',
                resourceModelList
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.appLoadService.getBackendConfig().then(() => {
                    this.router.navigate(['/pages/content']);
                  });
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public getResourceModelList(): Array<ResourceModel> {
    return this.formGroup.value.resourceList.map(
      (resource: Resource, index: number) => {
        const resourceModel: ResourceModel = new ResourceModel();
        resourceModel.resource = resource;
        resourceModel.fileObjectList =
          this.fileUploaderList[index].fileObjectList;
        return resourceModel;
      }
    );
  }

  public doCancel(): void {
    this.router.navigate(['/pages/content']);
  }

  public get formArrayResourceList(): FormArray {
    return this.formGroup.get('resourceList') as FormArray;
  }
}
