import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { TableResponseModel } from '../../table/model/table-response-model';

@Component({
  templateUrl: './app-popup-choose-bill.component.html'
})
export class AppPopupChooseBillComponent extends BaseComponentComponent {
  @Input() public paymentBilling: PaymentBilling;
  @Input() public isInternal: boolean;

  @Output() onChange: EventEmitter<PaymentBilling[]> = new EventEmitter();
  public tableResponse: TableResponseModel<PaymentBilling>;
  public procurementVendorId: number;

  constructor() {
    super('app-popup-choose-bill');
  }

  onInit(): void {
    this.buildTableResponse();
    this.setPaymentBilling();
    this.buildTableResponse();
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'code',
          header: 'table.column.orderNumber'
        },
        {
          field: 'requiredDate',
          header: 'table.column.orderDate',
          plugins: { name: 'date' }
        },
        {
          field: 'drType.name',
          header: 'table.column.orderType'
        },
        {
          field: 'amount',
          header: 'table.column.bills',
          plugins: { name: 'default', type: 'currency' },
          className: 'text-right'
        },
        {
          field: 'sowPaymentTerm.paymentTerm.name',
          header: 'table.column.termin'
        },
        {
          field: 'stageOfWork.name',
          header: 'table.column.stage'
        }
      ],
      { group: { field: 'code' }, checkBoxFn: this.isShowCheckBox.bind(this) }
    );
    this.tableResponse.options.group.isExpanded = true;
    this.tableResponse.reload();
    this.tableResponse.setCustomData({
      isInternal: this.isInternal ? 1 : 0
    });
  }

  public isShowCheckBox(record: PaymentBilling): boolean {
    let isStatusDraft = false;
    if (record.amount) {
      isStatusDraft = true;
    } else {
      isStatusDraft = false;
    }
    return isStatusDraft;
  }

  public setPaymentBilling(): void {
    if (this.paymentBilling) {
      this.tableResponse.setSelectedRowList([this.paymentBilling]);
    }
  }
  public doChoose(): void {
    if (this.tableResponse.selectedRow.getSelectedRecordList().length !== 0) {
      this.onChange.emit(
        this.tableResponse.selectedRow.getSelectedRecordList()[0]
      );
    } else {
      this.global.alertService.showError(
        'app.validation.chooseOneData',
        '.main-row-popup-choose-bill'
      );
    }
  }
}
