import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { DirectPurchaseDelivery } from '../../core/bean/direct-purchase-delivery';
import { Validators } from '../../core/validators';
@Component({
  templateUrl: 'initiation-popup-delivery-process.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InitiationPopupDeliveryProcessComponent extends BaseComponentComponent {
  @Input() public directPurchaseDelivery: DirectPurchaseDelivery;
  @Input() public isView = false;
  @Output() public onChange: EventEmitter<DirectPurchaseDelivery> =
    new EventEmitter();
  public popupHeader: string;
  constructor(public translateService: TranslateService) {
    super('initiation');
  }
  public onInit(): void {
    this.buildAndSetFormGroup();
    this.setStateReady();
    this.formGroup.setIsView(this.isView);
  }

  public buildAndSetFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      receivedDate: [null, Validators.required()],
      performanceValue: [null, Validators.required()]
    });

    this.formGroup.patchValue({
      receivedDate: this.directPurchaseDelivery.receivedDate
        ? new Date(this.directPurchaseDelivery.receivedDate)
        : null,
      performanceValue: this.directPurchaseDelivery.performanceValue
    });

    this.popupHeader =
      this.translateService.instant(
        'initiation.detail.popup.deliveryProcess.title'
      ) +
      ' - ' +
      (this.directPurchaseDelivery.procurementItem.prItem.item.itemType.code ===
      this.global.appConstant.core.ITEM_TYPE_CODE_MATERIAL
        ? this.translateService.instant(
            'initiation.detail.popup.deliveryProcess.goods'
          )
        : this.translateService.instant(
            'initiation.detail.popup.deliveryProcess.service'
          ));
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.directPurchaseDelivery.receivedDate =
        this.formGroup.value.receivedDate;
      this.directPurchaseDelivery.performanceValue =
        this.formGroup.value.performanceValue;
      this.onChange.emit(this.directPurchaseDelivery);
    }
  }
}
