import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorCategory } from '../../core/bean/vendor-category';
import { VendorHistory } from '../../core/bean/vendor-history';
import { WorkPathItem } from '../../core/components/app-work-path';
import { WorkPathModel } from '../../core/components/app-work-path/model/work-path-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { OnBoardingResponse } from './response/on-boarding.response';
@Component({
  templateUrl: './on-boarding-edit.component.html'
})
export class OnBoardingEditComponent extends BaseModuleComponent {
  public onBoardingResponse: OnBoardingResponse = new OnBoardingResponse();
  public today: Date = new Date();
  public vendorHistoryId: number;
  public vendorHistory: VendorHistory = new VendorHistory();
  public vendorCategoryList: VendorCategory[] = [];
  public isShowButton = false;
  public isApproval = false;
  public objectId: number;
  public workPathModel: WorkPathModel = new WorkPathModel(true);
  public modeApproval: string;
  public reloadVerification = 0;
  public token: string;
  constructor(translateService: TranslateService,
    public activatedRoute: ActivatedRoute) {
    super('on-boarding', translateService);
  }

  onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token && this.token !== 'vendor-active') {
      this.setDataFromRouterParamsFromToken();
    } else if (this.token === null){
      this.setDataFromRouterParams();
    }
  }

  public setDataFromRouterParamsFromToken(): void {
    this.httpClientService
      .get<VendorHistory>('/on-boarding/notification/' + this.token)
      .subscribe((vendorHistory: VendorHistory) => {
        this.global.routerParams.clear();
        this.global.routerParams.set('objectId', vendorHistory.id);
        this.global.routerParams.set('todo', 'view');
        this.global.routerParams.set('modeApproval', 'edit');
        this.global.routerParams.set('isApproval', true);
        this.global.routerParams.set('urlBackOutside', '/pages/on-boarding');
        if(vendorHistory.vendor.vendorActivityStatus.code === 'APPROVAL'){
          this.global.routerParams.set('activityStatus', 'APPROVAL');
        }
        this.router.navigate(['/pages/on-boarding/edit']);
      });
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.vendorHistoryId = this.global.routerParams.get('vendorHistoryId');
    this.isApproval = this.global.routerParams.get('isApproval');
    this.objectId = this.global.routerParams.get('objectId');
    this.modeApproval = this.global.routerParams.get('modeApproval');

    this.getAndSetOnBoardingToFormGroup();
    this.showButtons();
  }

  public showButtons(): void {
    if (!this.router.url.toLowerCase().includes('verification')) {
      this.isShowButton = true;
    }
  }

  public getAndSetOnBoardingToFormGroup(): void {
    // if objectId is defined then get last vendorHistory by the vendorId (objectId)
    // objectId = vendor_id
    // send only vendorhistory id for embedded company profile in worklist approval
    // let vendorHistoryId: number; // to prevent this.vendorHistoryId = undefined if objectIs is defined
    if (this.objectId) {
      this.httpClientService
        .post<VendorHistory>(
          '/on-boarding/get-last-vendor-history',
          new RouteRequestModel('view', this.objectId)
        )
        .subscribe(vendorHistory => {
          this.vendorHistoryId = vendorHistory.id;
          this.global.routerParams.set('vendorHistoryId', vendorHistory.id);

          this.httpClientService
            .post<OnBoardingResponse>(
              '/on-boarding/edit',
              new RouteRequestModel(this.todo, this.vendorHistoryId)
            )
            .subscribe(onBoardingResponse => {
              this.setOnBoardingToFormGroup(onBoardingResponse);
              this.setStateReady();
            });
        });
    } else {
      this.httpClientService
        .post<OnBoardingResponse>(
          '/on-boarding/edit',
          new RouteRequestModel(this.todo, this.vendorHistoryId)
        )
        .subscribe(onBoardingResponse => {
          this.setOnBoardingToFormGroup(onBoardingResponse);
          this.setStateReady();
        });
    }
  }

  public setOnBoardingToFormGroup(onBoardingResponse: OnBoardingResponse): void {
    if (onBoardingResponse.vendorHistory !== null) {
      this.vendorHistory = onBoardingResponse.vendorHistory;
      this.onBoardingResponse = onBoardingResponse;
      this.workPathModel.setRequestValues(
        onBoardingResponse.workpathResponseModelList
      );

      this.global.routerParams['onBoardingResponse'] = onBoardingResponse;
      if (this.isApproval) {
        if (this.modeApproval === 'edit') {
          this.router.navigate(['pages/on-boarding/edit/verification']);
        } else {
          this.router.navigate(['pages/on-boarding/detail/verification']);
        }
      } else {
        if (
          onBoardingResponse.vendorHistory.vendor.vendorActivityStatus.code.includes(
            'RE_VERIFICATION'
          ) ||
          onBoardingResponse.vendorHistory.vendor.vendorActivityStatus.code ===
            'APPROVAL_CANDIDATE' ||
          onBoardingResponse.vendorHistory.vendor.vendorActivityStatus.code ===
            'APPROVAL_UPDATE_PROFILE' ||
          onBoardingResponse.vendorHistory.vendor.vendorActivityStatus.code ===
            'APPROVAL_EXTENDED_CERTIFICATE'
        ) {
          this.router.navigate(['pages/on-boarding/edit/verification']);
        } else {
          if (location.pathname === '/pages/on-boarding/edit/verification') {
            this.router.navigate([location.pathname]);
            this.reloadVerification += 1;
          } else {
            this.router.navigate([
              '/pages/on-boarding/edit/' +
                onBoardingResponse.vendorHistory.vendor.vendorActivityStatus.code
                  .toLowerCase()
                  .replace('_', '-')
            ]);
          }
        }
      }
    }
  }

  public doOnChangeWorkpath(workPathItem: WorkPathItem): void {
    this.global.routerParams['todo'] = 'view';
    this.global.routerParams['isWorkflowProgress'] = true;
    if (workPathItem.code === this.global.appConstant.vm.V_ACTIVITY_STATUS_VERIFICATION) {
      if (this.workPathModel.workPathItemList.length === 1){
        this.router.navigateByUrl('/', {skipLocationChange: false}).then(() => {
          this.router.navigate(['/pages/on-boarding/edit/' +
          workPathItem.code.toLowerCase().replace(/_/g, '-')]);
        });
      } else {
        if (this.reloadVerification >= 1) {
          this.router.navigateByUrl('/', {skipLocationChange: false}).then(() => {
            this.router.navigate(['/pages/on-boarding/edit/' +
            workPathItem.code.toLowerCase().replace(/_/g, '-')]);
          });
        } else {
          this.router.navigate([
            '/pages/on-boarding/edit/' +
              workPathItem.code.toLowerCase().replace(/_/g, '-')
          ]);
        }
        this.reloadVerification += 1;
      }
    } else {
      this.reloadVerification = 0
      this.router.navigate([
        '/pages/on-boarding/edit/' +
          workPathItem.code.toLowerCase().replace(/_/g, '-')
      ]);
    }
  }
}
