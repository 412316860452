<div class="app-toggle-switch">
  <input
    type="checkbox"
    [formControl]="formControl"
    [id]="genedatedId"
    (change)="onChange.emit(formControl.value)"
  />

  <label
    class="custom-toggle-switch-label"
    [for]="genedatedId"
    (click)="doClick($event)"
  >
    <span class="custom-toggle-switch-rounded"></span>
    <span #labelOnElement class="custom-toggle-switch-text-on">
      {{ labelOn }}
    </span>
    <span #labelOffElement class="custom-toggle-switch-text-off">
      {{ labelOff }}
    </span>
  </label>
  <app-tooltip-wrapper tooltip="{{ tooltip }}" position="{{ tooltipPosition }}">
    <app-tooltip-wrapper-item>
      <ng-content select="app-flag-new"></ng-content>
    </app-tooltip-wrapper-item>
  </app-tooltip-wrapper>
</div>
