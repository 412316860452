import { Contract } from '../../core/bean/contract';
import { ContractNegotiation } from '../../core/bean/contract-negotiation';
import { ContractNegotiationHistory } from '../../core/bean/contract-negotiation-history';
import { FileObject } from '../../core/components/upload';

export class ContractNegotiationUpdateRequest {
  contractNegotiation: ContractNegotiation;
  contractNegotiationHistory: ContractNegotiationHistory;
  contract: Contract;
  fileObjectList: FileObject[];
  isVendorApproved: boolean;
}
