<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card header="parameter.title" detail="app.action.{{ todo }}" [isLoading]="formLoading" [isSaving]="formSaving">
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'parameter.form.code' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            {{ formGroup.controls.code.value }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'parameter.form.group' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            {{ formGroup.controls.groupName.value }}
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'parameter.form.type' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            {{ formGroup.controls.type.value }}
          </div>
        </div>
        <div class="form-group row text-sm-right required">
          <label class="col-sm-3 control-label">{{
            'parameter.form.value' | translate
            }}</label>
          <div class="col-sm-9 text-left">
            <ng-container *ngIf="!parameter.isSensitive">
            
              <ng-container *ngIf="
                  parameter.valueType && parameter.valueType.code === 'BOOLEAN'
                ">
                <app-radio formControlName="value" [optionList]="valueTypeOptionList"></app-radio>
              </ng-container>
              <ng-container *ngIf="
                  parameter.valueType && parameter.valueType.code === 'STRING'
                ">
                <app-text-field autofocus="true" formControlName="value" maxLength="128"></app-text-field>
              </ng-container>
              <ng-container *ngIf="
                  parameter.valueType && parameter.valueType.code === 'NUMBER'
                ">
                <app-text-field autofocus="true" formControlName="value" type="decimal" maxLength="32"></app-text-field>
              </ng-container>
              <ng-container *ngIf="parameter.valueType && parameter.valueType.code === 'TIME'">
                <app-time formControlName="value"></app-time>
                <!-- <app-text-field 
                autofocus="true" 
                formControlName="value" 
                type="decimal" maxLength="32">
                </app-text-field> -->
              </ng-container>

            </ng-container>

            <ng-container *ngIf="parameter.isSensitive">
              <app-text-field type="password" autofocus="true" formControlName="value" maxLength="128"></app-text-field>
            </ng-container>
          </div>
        </div>
        <div class="form-group row text-sm-right">
          <label class="col-sm-3 control-label">{{
            'parameter.form.description' | translate
            }}</label>
          <div class="col-sm-6 text-left">
            <p *ngIf="parameter.description; else empty">{{
              formGroup.controls.description.value
              }}</p>
            <ng-template #empty> - </ng-template>
          </div>
        </div>
        <div class="button-group button-group-center mt-5">
          <app-button color="SECONDARY" (onClick)="doCancel()" [disabled]="formSaving">{{ 'app.button.back' | translate
            }}
          </app-button>
          <app-button (onClick)="doSave()" [disabled]="formSaving">{{
            'app.button.save' | translate
            }}</app-button>
        </div>
      </form>
    </app-card>
  </div>
</div>