import { createDatePickerDate } from '../helper/create-date-picker-date-helper';
import { DatePickerState } from '../model';
import { DatePickerTime } from '../model/date-picker-time-model';
export const setDatePickerTime = (
  state: DatePickerState,
  payload: { datePickerTime: DatePickerTime }
): void => {
  state.currentTime = payload.datePickerTime;
  if (state.currentDateValue || state.model.formControl.value) {
    if (!state.currentDateValue) {
      state.currentDateValue = createDatePickerDate(
        state.model.formControl.value
      );
    }
    state.currentDateValue.date.setHours(
      +payload.datePickerTime.hoursString,
      +payload.datePickerTime.minutesString,
      0
    );
    state.setValue(state.currentDateValue.date);
  }
};
