import { FileObject } from 'src/app/core/components/upload';
import { VendorViolation } from '../../core/bean/vendor-violation';
import { VendorBlacklist } from '../../core/bean/vendor-blacklist';
import { Vendor } from 'src/app/core/bean/vendor';
export class VendorBlacklistRequest {
    vendorBlacklist: VendorBlacklist = new VendorBlacklist();
    vendor: Vendor = new Vendor();
    vendorViolationList: VendorViolation[] = [];
    docFileList: FileObject[] = [];
}
