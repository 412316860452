import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, Output, } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";
import { BaseComponentComponent } from "src/app/core/base/angular/base-component.component";
import { Contract } from "src/app/core/bean/contract";
import { ContractTermination } from "src/app/core/bean/contract-termination";
import { ContractTerminationDoc } from "src/app/core/bean/contract-termination-doc";
import { File } from "src/app/core/bean/file";
import { FileUploader } from "src/app/core/components/upload";
import { Response } from "src/app/core/model/response-model";
import { ResponseStatusModel } from "src/app/core/model/response-status-model";
import { Validators } from "src/app/core/validators";
import { ContractTerminationInsertRequest } from "./contract-termination-insert-request";
import { ToastService } from 'src/app/core/components/app-toast/app-toast.service';

@Component({
    templateUrl: './app-popup-contract-termination-terminate-contract.component.html'
  })
export class AppPopupContractTerminationTerminateContractComponent extends BaseComponentComponent {

    @Input() public contract: Contract = new Contract();
    @Input() public contractTermination: ContractTermination = new ContractTermination();
    @Input() public contractTerminationDocList: ContractTerminationDoc[] = [];
    @Input() public isNumberGenerator: boolean;
    @Output() public onChange: EventEmitter<any> = new EventEmitter();

    public currentDate: Date = new Date();
    public startDate: Date;
    public endDate: Date;
    public fileList: File[] = [];
    public fileUploader: FileUploader = new FileUploader(
        '/contract-termination/',
        'contract-termination.title',
        this.global.appConstant.fileType.DOC_CONTRACT_TERMINATION,
        false,
        5
      );
    public request: ContractTerminationInsertRequest =
    new ContractTerminationInsertRequest();

    constructor(
        public translateService: TranslateService,
        public toastService: ToastService,
        public activeModal: NgbActiveModal,
        public datePipe: DatePipe
      ) {
        super('contract-termination');
      }

      onInit(): void {
        this.buildFormGroup();
        this.setFormGroup();
      }
    
    
      public buildFormGroup(): void {
        this.formGroup = this.formBuilder.group({
          id: [null],
          terminationDate: [this.currentDate, Validators.compose([Validators.required()])],
          code: [null, Validators.required()],
          description: [null, Validators.required()],
          file: [null, Validators.required()]
        });
      }
    
      public setFormGroup(): void {
        this.startDate = new Date(this.contract.startDate);
        this.endDate = new Date(this.contract.endDate);
        if (this.contractTermination) {
            if (this.contractTerminationDocList) {
              this.contractTerminationDocList.forEach(ctd => {
                  this.fileList.push(ctd.file);
              });
                this.fileUploader.setFileList(
                  this.fileList
                );
            } 
            this.formGroup.patchValue({
                id: this.contractTermination.id,
                file: this.fileUploader.fileObjectList,
                code: this.contractTermination.code,
                terminationDate: this.contractTermination.terminationDate,
                description: this.contractTermination.description
            });
        }
        this.setStateReady();
      }


      public doSave(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.markAsDirty();
        this.validate();
        if (this.formGroup.valid) {
            const prompt =
            '<div>' +
            this.translateService.instant(
              'contract-termination.confirmation.terminateContract.prompt'
            ) +
            '</div></br>';
            this.global.modalService
            .confirmation(
                prompt,
                'contract-termination.confirmation.terminateContract.title',
                'app.button.no',
                'app.button.yes',
                'pvc pv-file-circle-check-alt',
                false
              )
            .pipe(take(1))
            .subscribe(result => {
              if (result) {
                this.formGroup.disable();
                this.setStateProcessing();
                this.request.contractTermination =
                  this.formGroup.value;
                this.request.contract =
                  this.contract;
                this.request.fileObjectList =
                  this.formGroup.value.file;
                this.httpClientService
                  .post<Response<ContractTermination>>(
                    '/contract-termination/terminate',
                    this.request
                  )
                  .subscribe(response => {
                    if (response.status === ResponseStatusModel.OK) {
                      const date = this.datePipe.transform(
                        response.body.terminationDate,
                        this.global.config.parameterModel.datetimeFormatLong
                      );
                      const prompt =
                      this.translateService.instant(
                        'contract-termination.confirmation.terminateConfirmation.prompt'
                      ) +
                      ' '+ date;
                      this.global.modalService
                        .confirmation(
                          prompt,
                          'contract-termination.confirmation.terminateConfirmation.title',
                          'app.button.stayOnThisPage',
                          'app.button.backToList',
                          'pvc pv-check-circle',
                          false
                        )
                        .pipe(take(1))
                        .subscribe(result => {
                          if (result) {
                            this.router.navigate(['/pages/contract-termination/']);
                          } else {
                            this.router
                              .navigateByUrl('/', {
                                skipLocationChange: true
                              })
                              .then(() => {
                                this.global.routerParams.clear();
                                this.global.routerParams.set(
                                  'urlBackOutside',
                                  '/pages/contract-termination'
                                );
                                this.global.routerParams.set(
                                  'contract',
                                  this.contract
                                );
                                this.global.routerParams.set('contractTerminationId', response.body.id);
                                this.global.routerParams.set(
                                  'contractId',
                                  this.contract.id
                                );
                                this.global.routerParams.set('todo', 'view');
                                this.router.navigate([
                                  '/pages/contract-termination/detail'
                                ]);
                              });
                          }
                        });
                      this.setStateReady();
                      this.activeModal.close();
                    } else {
                      this.global.alertService.showError(response.statusText);
                      this.toastService.showError(response.statusText);
                      this.setStateReady();
                    }
                  });
              }
            });
        }
      }
}