import { Pipe, PipeTransform } from '@angular/core';
import { ConverterService } from '../services/converter.service';

@Pipe({ name: 'resolveNumberToDecimal' })
export class ResolveNumberToDecimalPipe implements PipeTransform {
  constructor(private converterService: ConverterService) {}

  transform(number: number): string {
    return this.converterService.convertDecimal(number);
  }
}
