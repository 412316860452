import { ApprovalModelPrcs } from '../bean/approval-model-prcs';
import { WorkflowModelPrcs } from '../bean/workflow-model-prcs';
import { BaseEntity } from './../base/base-entity';
import { WorkflowPrcs } from './../bean/workflow-prcs';
export class AdjustmentEntity extends BaseEntity {
    totalAdjustmentName: string;
    afterAdjustmentName: string;
    beforeAdjustmentName: string;
    stageName: string;
    workflowPrcs: WorkflowPrcs;
    approvalModelPrcs: ApprovalModelPrcs;
    workflowModelPrcs: WorkflowModelPrcs;
    description: string;
    slaRealizedBeforeAdjustmentInMinutes: number;
    slaRealizedAfterAdjustmentInMinutes: number;
    adjustmentInDays: number;
    adjustmentInHours: number;
    adjustmentInMinutes: number;
    createdDate: Date;
    beforeAdjustmentWorkingInDays: number;
    beforeAdjustmentWorkingInHours: number;
    beforeAdjustmentWorkingInMinutes: number;
    afterAdjustmentWorkingInDays: number;
    afterAdjustmentWorkingInHours: number;
    afterAdjustmentWorkingInMinutes: number;
}
