import { NgModule } from '@angular/core';
import { SafeUrlPipe } from 'src/app/core/pipe/save-url.pipe';
import { AppButtonModule } from "../../../../../core/components/app-button/app-button.module";
import { VendorModule } from '../../../../../core/vendor/vendor.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { WidgetClaimVerificationComponent } from './widget-claim-verification.component';
@NgModule({
  imports: [VendorModule, WidgetCardModule, AppButtonModule],
  declarations: [WidgetClaimVerificationComponent,SafeUrlPipe],
  exports: [WidgetClaimVerificationComponent]
})
export class WidgetClaimVerificationModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetClaimVerificationComponent.moduleCode,
      WidgetClaimVerificationComponent,
      'w-100'
    );
  }
}
