import { BaseDTO } from '../../../core/base/base-dto';
import { EquipmentCondition } from '../../../core/bean/equipment-condition';
import { EquipmentType } from '../../../core/bean/equipment-type';
import { ResourceOwnership } from '../../../core/bean/resource-ownership';
import { Uom } from '../../../core/bean/uom';

export class VendorPopupVendorEquipmentDTO extends BaseDTO {
    equipmentTypeList: EquipmentType[];
    uomList: Uom[];
    equipmentConditionList: EquipmentCondition[];
    resourceOwnershipList: ResourceOwnership[];
}
