import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Response } from 'src/app/core/model/response-model';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ComplianceCheckStatus } from '../../core/bean/compliance-check-status';
import { ComplianceTest } from '../../core/bean/compliance-test';
import { ComplianceType } from '../../core/bean/compliance-type';
import { Procurement } from '../../core/bean/procurement';
import { User } from '../../core/bean/user';
import { AppOfficialReportModel } from '../../core/components/app-official-report/app-official-report-model';
import { AppPopupProcurementSummaryService } from '../../core/components/app-popup/app-popup-procurement-summary/app-popup-procurement-summary.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableRow } from '../../core/components/table/domain/table-row';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { FileUploader } from '../../core/components/upload';
import { OptionListModel } from '../../core/model/option-list-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { AlertService } from '../../core/services/alert.service';
import { SnackbarService } from '../../core/services/snackbar.services';
import { Validators } from '../../core/validators';
import { AppPopupAddUserComponent } from './app-popup-add-user.component';
import { AppPopupComplianceTypeComponent } from './app-popup-compliance-type.component';
import { AppPopupVendorWinnerInfoComponent } from './app-popup-vendor-winner-info.component';
import { ComplianceTestChecklistModel } from './compliance-test-checklist.model';
import { ComplianceTestUpdateRequest } from './compliance-test-update.request';
import { ComplianceTestModel } from './compliance-test.model';
import { ComplianceTestResponse } from './compliance-test.response';
@Component({
  templateUrl: './compliance-test-edit.component.html',
  styleUrls: ['./compliance-test-edit.component.scss']
})
export class ComplianceTestEditComponent extends BaseModuleComponent {
  public complianceTestResponse: ComplianceTestResponse =
    new ComplianceTestResponse();
  public tableResponseProcurementList: TableResponseModel<ComplianceTestChecklistModel>[] =
    [];
  public tableResponseVendorList: TableResponseModel<ComplianceTestChecklistModel>[] =
    [];
  public fileUploader: FileUploader = new FileUploader(
    '/compliance-test/',
    '',
    this.global.appConstant.fileType.DOC_COMPLIANCE_VENDOR,
    false,
    5
  );
  public tableResponsePreparedBy: TableResponseModel<User>;
  public tableResponseCheckedBy: TableResponseModel<User>;
  public preparedByUserList: User[] = [];
  public checkedByUserList: User[] = [];
  public complianceType: ComplianceType;
  public appOfficialReportModel: AppOfficialReportModel;
  public procurement: Procurement;
  public procurementId: number;
  public isOfficialReportValid: boolean;
  public roleCode: string;
  public approvalModelPrcsId: number;
  public isSummaryValid = false;
  public token: string;

  public complianceCheckStatusOptionList: OptionListModel<ComplianceCheckStatus> =
    new OptionListModel(true);

  public badgeColor = {
    DRAFT: 'SECONDARY',
    REVISION: 'FEEDBACK',
    REVISION_BY_COMPLIANCE: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    WAITING_REVIEW: 'WARNING',
    DONE: 'SUCCESS',
    NEW: 'INFO'
  };

  public getColorAndIconConfirm = {
    VERIFIED: { color: 'text-success', icon: 'pic pi-thumbs-up-circle' },
    NOT_VERIFIED: { color: 'text-danger', icon: 'pic pi-times-circle' },
    REVISED: { color: 'text-primary', icon: 'pic pi-pencil-circle' }
  };

  constructor(
    translateService: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupProcSummaryService: AppPopupProcurementSummaryService,
    public snackbarService: SnackbarService,
    public alertService: AlertService,
    public activatedRoute: ActivatedRoute
  ) {
    super('compliance-test', translateService);
  }

  public onInit(): void {
    this.activatedRoute.paramMap.subscribe(
      param => (this.token = param.get('token'))
    );
    if (this.token) {
      this.setDataFromToken();
    } else {
      this.setData();
    }
  }

  public setDataFromToken(): void {
    this.httpClientService
      .get<ComplianceTest>('/compliance-test/notification/' + this.token)
      .subscribe((complianceTest: ComplianceTest) => {
        this.global.routerParams.clear();
        this.global.routerParams.set(
          'procurementId',
          complianceTest.procurement.id
        );
        this.global.routerParams.set('procurement', complianceTest.procurement);
        if (
          complianceTest.complianceTestStatus.code ===
          this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW
        ) {
          this.todo = 'add';
        } else if (
          complianceTest.complianceTestStatus.code ===
            this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT ||
          complianceTest.complianceTestStatus.code ===
            this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION
        ) {
          this.todo = 'edit';
        } else {
          this.todo = 'view';
        }
        this.global.routerParams.set('todo', this.todo);
        this.router.navigate([
          this.todo === 'view'
            ? '/pages/compliance-test/detail'
            : '/pages/compliance-test/' + this.todo
        ]);
      });
  }

  public setData(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.procurementId = this.global.routerParams.get('procurementId');
    this.procurement = this.global.routerParams.get('procurement');
    this.todo = this.global.routerParams.get('todo');
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.procurementId =
      this.procurementId || this.global.routerParams.get('objectId');
    this.roleCode = this.global.userSession.activeUserRole.role.code;
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      complianceNote: [null, Validators.required()],
      summaryNote: [null, Validators.required()],
      attachmentFile: [null],
      procurementDocument: this.formBuilder.array([]),
      vendorDocument: this.formBuilder.array([])
    });
  }

  public buildTableResponse(): void {
    this.tableResponsePreparedBy = new TableResponseModel<User>(
      this.moduleCode,
      [
        {
          field: 'name',
          header: 'table.column.user'
        },
        {
          field: 'organization.name',
          header: 'table.column.department'
        }
      ],
      {
        checkBoxFn: this.isShowPreparedCheckBox.bind(this)
      }
    );

    this.tableResponseCheckedBy = new TableResponseModel<User>(
      this.moduleCode,
      [
        {
          field: 'name',
          header: 'table.column.user'
        },
        {
          field: 'organization.name',
          header: 'table.column.department'
        }
      ],
      {
        checkBoxFn: this.isShowCheckedCheckBox.bind(this)
      }
    );

    this.buildTableResponseProcurement(
      this.complianceTestResponse.procurementModelList
    );
    this.buildTableResponseVendor(this.complianceTestResponse.vendorModelList);
  }

  public isShowPreparedCheckBox(record: User): boolean {
    let isShowCheck = false;
    if (
      this.global.userSession.activeUserRole.role.code ===
      this.global.appConstant.ROLE_CODE_BUYER
    ) {
      if (record.id === this.global.userSession.user.id) {
        isShowCheck = false;
      } else {
        isShowCheck = true;
      }
    } else if (!this.isViewOnly) {
      isShowCheck = false;
    }
    return isShowCheck;
  }

  public isShowCheckedCheckBox(record: User): boolean {
    let isShowCheck = false;
    if (
      this.global.userSession.activeUserRole.role.code ===
        this.global.appConstant.ROLE_CODE_BUYER &&
      !this.isViewOnly
    ) {
      if (record.id === this.global.userSession.user.id) {
        isShowCheck = false;
      } else {
        isShowCheck = true;
      }
    } else if (
      this.global.userSession.activeUserRole.role.code !==
      this.global.appConstant.ROLE_CODE_BUYER
    ) {
      if (record.id === this.global.userSession.user.id) {
        isShowCheck = false;
      } else {
        isShowCheck = true;
      }
    } else if (!this.isViewOnly) {
      isShowCheck = false;
    }
    return isShowCheck;
  }

  public buildTableResponseProcurement(
    procurementModelList: ComplianceTestModel[]
  ): void {
    this.tableResponseProcurementList = [];
    procurementModelList.forEach((model, index) => {
      const tableResponse =
        (this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT) ||
        (this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT &&
          this.complianceTestResponse.complianceTest.complianceTestStatus
            .code !== this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW &&
          this.complianceTestResponse.complianceTest.complianceTestStatus
            .code !== this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT &&
          this.complianceTestResponse.complianceTest.complianceTestStatus
            .code !==
            this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION &&
          model.complianceTestChecklistModelList[0].complianceChecklist
            ?.complianceCheckStatus) ||
        (this.roleCode !== this.global.appConstant.ROLE_CODE_BUYER &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT) ||
        (this.complianceTestResponse.isUserApproval &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT)
          ? new TableResponseModel<ComplianceTestChecklistModel>(
              this.moduleCode,
              [
                {
                  header: 'table.column.complianceTestName',
                  field: 'complianceChecklist.complianceTestMaster.name'
                },
                {
                  header: 'table.column.document',
                  field: 'fileList',
                  plugins: [
                    {
                      name: 'upload',
                      fileUploader: new FileUploader(
                        '/' + this.moduleCode + '/',
                        '',
                        this.global.appConstant.fileType.DOC_COMPLIANCE_VENDOR,
                        false,
                        5
                      ),
                      isView:
                        this.roleCode !==
                        this.global.appConstant.ROLE_CODE_BUYER
                          ? true
                          : this.isViewOnly,
                      validators: Validators.required(),
                      onChange: this.onChangeDocumentProc.bind(this, index)
                    }
                  ]
                },
                {
                  field: 'complianceChecklist.complianceCheckStatus',
                  header: 'table.column.documentCheck',
                  plugins: {
                    name: 'drop-down-select',
                    optionList: this.complianceCheckStatusOptionList,
                    placeholder:
                      'compliance-test.table.column.placeholder.documentCheck',
                    isView: this.isViewOnly,
                    onChange: this.onChangeDocumentCheckProcurement.bind(
                      this,
                      index
                    ),
                    customValue: (value: any): string => {
                      if (value) {
                        return this.getStringCustomValue(value);
                      } else {
                        return null;
                      }
                    },
                    customOption: (option: any): string => {
                      return this.translateService.instant(
                        this.getTranslateKey(option)
                      );
                    },
                    validators:
                      (this.roleCode ===
                        this.global.appConstant.ROLE_CODE_BUYER &&
                        this.complianceType.code ===
                          this.global.appConstant.pm
                            .COMPLIANCE_TYPE_SELF_ASSESSMENT) ||
                      (this.roleCode !==
                        this.global.appConstant.ROLE_CODE_BUYER &&
                        this.complianceType.code ===
                          this.global.appConstant.pm
                            .COMPLIANCE_TYPE_NON_SELF_ASSESSMENT)
                        ? Validators.required()
                        : null
                  }
                },
                {
                  field: 'complianceChecklist.note',
                  header: 'table.column.note',
                  plugins: {
                    name: 'text-area',
                    maxLength: 512,
                    isView: this.isViewOnly,
                    placeholder: this.translateService.instant(
                      'compliance-test.table.column.placeholder.note'
                    )
                  }
                }
              ]
            )
          : new TableResponseModel<ComplianceTestChecklistModel>(
              this.moduleCode,
              [
                {
                  header: 'table.column.complianceTestName',
                  field: 'complianceChecklist.complianceTestMaster.name'
                },
                {
                  header: 'table.column.document',
                  field: 'fileList',
                  plugins: [
                    {
                      name: 'upload',
                      fileUploader: new FileUploader(
                        '/' + this.moduleCode + '/',
                        '',
                        this.global.appConstant.fileType.DOC_COMPLIANCE_VENDOR,
                        false,
                        5
                      ),
                      isView:
                        this.roleCode !==
                        this.global.appConstant.ROLE_CODE_BUYER
                          ? true
                          : this.isViewOnly,
                      validators: Validators.required(),
                      onChange: this.onChangeDocumentProc.bind(this, index)
                    }
                  ]
                },
                {
                  field: 'complianceChecklist.note',
                  header: 'table.column.note',
                  plugins: {
                    name: 'text-area',
                    maxLength: 512,
                    isView: this.isViewOnly,
                    placeholder: this.translateService.instant(
                      'compliance-test.table.column.placeholder.note'
                    )
                  }
                }
              ]
            );

      tableResponse.setRecordList(model.complianceTestChecklistModelList);
      this.tableResponseProcurementList.push(tableResponse);

      const formGroup = this.formBuilder.group({
        table: tableResponse.formArray
      });
      this.procurementDocList.push(formGroup);
    });
  }

  public onAfterRowCreated(tableRow: TableRow): void {
    if (
      (this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
        tableRow.record.complianceChecklist.complianceCheckStatus &&
        tableRow.record.complianceChecklist.complianceCheckStatus.code !==
          this.global.appConstant.pm.COMPLIANCE_CHECK_STATUS_REVISED &&
        this.complianceTestResponse.complianceTest.complianceTestStatus.code !==
          this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION &&
        this.complianceTestResponse.complianceTest.complianceTestStatus.code !==
          this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT &&
        (this.complianceTestResponse.complianceTest.complianceTestStatus
          .code !== this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_DONE ||
          (this.complianceTestResponse.complianceTest.complianceTestStatus
            .code === this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_DONE &&
            this.complianceType.code ===
              this.global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT &&
            tableRow.record.complianceChecklist.complianceCheckStatus.code ===
              this.global.appConstant.pm.COMPLIANCE_CHECK_STATUS_VERIFIED))) ||
      (this.complianceTestResponse.isDone &&
        tableRow.record.complianceChecklist.complianceCheckStatus &&
        tableRow.record.complianceChecklist.complianceCheckStatus.code ===
          this.global.appConstant.pm.COMPLIANCE_CHECK_STATUS_VERIFIED &&
        tableRow.record.complianceChecklist.isVerified)
    ) {
      tableRow.formGroup.setIsView(true);
    } else if (
      this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
      tableRow.record.complianceChecklist.complianceCheckStatus &&
      ((tableRow.record.complianceChecklist.complianceCheckStatus.code ===
        this.global.appConstant.pm.COMPLIANCE_CHECK_STATUS_REVISED &&
        this.complianceTestResponse.complianceTest.complianceTestStatus.code ===
          this.global.appConstant.pm
            .COMPLIANCE_TEST_STATUS_REVISION_BY_COMPLIANCE) ||
        (tableRow.record.complianceChecklist.complianceCheckStatus.code ===
          this.global.appConstant.pm.COMPLIANCE_CHECK_STATUS_NOT_VERIFIED &&
          this.complianceTestResponse.complianceTest.complianceTestStatus
            .code === this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_DONE &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT))
    ) {
      tableRow.formGroup
        .get('complianceChecklistComplianceCheckStatus')
        .setIsView(true);
      tableRow.formGroup.get('complianceChecklistNote').setIsView(false);
      tableRow.formGroup.get('fileList').setIsView(false);
    }

    if (
      this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
      this.complianceType.code ===
        this.global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT
    ) {
      tableRow.formGroup.clearValidators();
      tableRow.formGroup.updateValueAndValidity();
    }
  }

  public buildTableResponseVendor(
    vendorModelList: ComplianceTestModel[]
  ): void {
    this.tableResponseVendorList = [];
    vendorModelList.forEach((model, index) => {
      const tableResponse =
        (this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT) ||
        (this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT &&
          this.complianceTestResponse.complianceTest.complianceTestStatus
            .code !== this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW &&
          this.complianceTestResponse.complianceTest.complianceTestStatus
            .code !== this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT &&
          this.complianceTestResponse.complianceTest.complianceTestStatus
            .code !==
            this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION &&
          model.complianceTestChecklistModelList[0].complianceChecklist
            ?.complianceCheckStatus) ||
        (this.roleCode !== this.global.appConstant.ROLE_CODE_BUYER &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT) ||
        (this.complianceTestResponse.isUserApproval &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT)
          ? new TableResponseModel<ComplianceTestChecklistModel>(
              this.moduleCode,
              [
                {
                  header: 'table.column.complianceTestName',
                  field: 'complianceChecklist.complianceTestMaster.name'
                },
                {
                  header: 'table.column.document',
                  field: 'fileList',
                  plugins: [
                    {
                      name: 'upload',
                      fileUploader: new FileUploader(
                        '/' + this.moduleCode + '/',
                        '',
                        this.global.appConstant.fileType.DOC_COMPLIANCE_VENDOR,
                        false,
                        5
                      ),
                      isView:
                        this.roleCode !==
                        this.global.appConstant.ROLE_CODE_BUYER
                          ? true
                          : this.isViewOnly,
                      validators: Validators.required(),
                      onChange: this.onChangeDocumentVendor.bind(this, index)
                    }
                  ]
                },
                {
                  field: 'complianceChecklist.complianceCheckStatus',
                  header: 'table.column.documentCheck',
                  plugins: {
                    name: 'drop-down-select',
                    optionList: this.complianceCheckStatusOptionList,
                    placeholder:
                      'compliance-test.table.column.placeholder.documentCheck',
                    isView: this.isViewOnly,
                    customValue: (value: any): string => {
                      if (value) {
                        return this.getStringCustomValue(value);
                      } else {
                        return null;
                      }
                    },
                    onChange: this.onChangeDocumentCheckVendor.bind(
                      this,
                      index
                    ),
                    customOption: (option: any): string => {
                      return this.translateService.instant(
                        this.getTranslateKey(option)
                      );
                    },
                    validators:
                      (this.roleCode ===
                        this.global.appConstant.ROLE_CODE_BUYER &&
                        this.complianceType.code ===
                          this.global.appConstant.pm
                            .COMPLIANCE_TYPE_SELF_ASSESSMENT) ||
                      (this.roleCode !==
                        this.global.appConstant.ROLE_CODE_BUYER &&
                        this.complianceType.code ===
                          this.global.appConstant.pm
                            .COMPLIANCE_TYPE_NON_SELF_ASSESSMENT)
                        ? Validators.required()
                        : null
                  }
                },
                {
                  field: 'complianceChecklist.note',
                  header: 'table.column.note',
                  plugins: {
                    name: 'text-area',
                    maxLength: 512,
                    isView: this.isViewOnly,
                    placeholder: this.translateService.instant(
                      'compliance-test.table.column.placeholder.note'
                    )
                  }
                }
              ]
            )
          : new TableResponseModel<ComplianceTestChecklistModel>(
              this.moduleCode,
              [
                {
                  header: 'table.column.complianceTestName',
                  field: 'complianceChecklist.complianceTestMaster.name'
                },
                {
                  header: 'table.column.document',
                  field: 'fileList',
                  plugins: [
                    {
                      name: 'upload',
                      fileUploader: new FileUploader(
                        '/' + this.moduleCode + '/',
                        '',
                        this.global.appConstant.fileType.DOC_COMPLIANCE_VENDOR,
                        false,
                        5
                      ),
                      isView:
                        this.roleCode !==
                        this.global.appConstant.ROLE_CODE_BUYER
                          ? true
                          : this.isViewOnly,
                      validators: Validators.required(),
                      onChange: this.onChangeDocumentVendor.bind(this, index)
                    }
                  ]
                },
                {
                  field: 'complianceChecklist.note',
                  header: 'table.column.note',
                  plugins: {
                    name: 'text-area',
                    maxLength: 512,
                    isView: this.isViewOnly,
                    placeholder: this.translateService.instant(
                      'compliance-test.table.column.placeholder.note'
                    )
                  }
                }
              ]
            );
      tableResponse.setRecordList(model.complianceTestChecklistModelList);
      this.tableResponseVendorList.push(tableResponse);
      this.vendorDocList.push(
        this.formBuilder.group({
          table: tableResponse.formArray
        })
      );
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<ComplianceTestResponse>(
        '/compliance-test/edit',
        new RouteRequestModel(this.todo, this.procurementId)
      )
      .subscribe((complianceTestResponse: ComplianceTestResponse) => {
        this.complianceTestResponse = complianceTestResponse;
        this.complianceType =
          this.complianceTestResponse.complianceTest.complianceType;
        if (
          (this.complianceTestResponse.complianceTest.complianceTestStatus
            .code ===
            this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_WAITING_REVIEW &&
            this.roleCode === this.global.appConstant.ROLE_CODE_BUYER) ||
          (this.complianceTestResponse.complianceTest.complianceTestStatus
            .code ===
            this.global.appConstant.pm
              .COMPLIANCE_TEST_STATUS_REVISION_BY_COMPLIANCE &&
            this.roleCode !== this.global.appConstant.ROLE_CODE_BUYER) ||
          this.complianceTestResponse.complianceTest.complianceTestStatus
            .code ===
            this.global.appConstant.pm
              .COMPLIANCE_TEST_STATUS_WAITING_APPROVAL ||
          (this.complianceTestResponse.complianceTest.complianceTestStatus
            .code === this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_DONE &&
            this.complianceType.code ===
              this.global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT)
        ) {
          this.setViewOnly();
        }
        if (
          this.complianceTestResponse.complianceTest.complianceTestStatus
            .code !== this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_NEW &&
          this.complianceTestResponse.complianceTest.complianceTestStatus
            .code !== this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_DRAFT &&
          this.complianceTestResponse.complianceTest.complianceTestStatus
            .code !== this.global.appConstant.pm.COMPLIANCE_TEST_STATUS_REVISION
        ) {
          this.formGroup.get('complianceNote').setIsView(true);
        }
        if (
          this.complianceTestResponse.complianceTest.complianceTestStatus
            .code ===
          this.global.appConstant.pm
            .COMPLIANCE_TEST_STATUS_REVISION_BY_COMPLIANCE
        ) {
          this.formGroup.setIsView(true);
        }
        this.buildTableResponse();
        this.appOfficialReportModel =
          this.complianceTestResponse.appOfficialReportModel;
        if (this.appOfficialReportModel.fileObjectList) {
          this.appOfficialReportModel.fileObjectList.forEach(fileObject => {
            if (!fileObject.isDeleted) {
              this.appOfficialReportModel.isGenerate = true;
            }
          });
        }

        if (this.complianceTestResponse.preparedByUserList) {
          this.preparedByUserList =
            this.complianceTestResponse.preparedByUserList;
          const idList = this.preparedByUserList.map(user => user.id);
          if (
            this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
            idList.indexOf(this.complianceTestResponse.user.id) === -1
          ) {
            this.preparedByUserList.push(this.complianceTestResponse.user);
          }
        } else if (this.roleCode === this.global.appConstant.ROLE_CODE_BUYER) {
          this.preparedByUserList.push(this.complianceTestResponse.user);
        }

        if (this.complianceTestResponse.checkedByUserList) {
          this.checkedByUserList =
            this.complianceTestResponse.checkedByUserList;
          const idList = this.checkedByUserList.map(user => user.id);
          if (
            this.roleCode !== this.global.appConstant.ROLE_CODE_BUYER &&
            idList.indexOf(this.complianceTestResponse.user.id) === -1
          ) {
            this.checkedByUserList.push(this.complianceTestResponse.user);
          }
        } else if (this.roleCode !== this.global.appConstant.ROLE_CODE_BUYER) {
          this.checkedByUserList.push(this.complianceTestResponse.user);
        } else if (
          this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT
        ) {
          this.checkedByUserList.push(this.complianceTestResponse.user);
        }
        if (this.complianceTestResponse.summaryFileList) {
          this.fileUploader.setFileList(
            this.complianceTestResponse.summaryFileList
          );
        }

        if (
          this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT
        ) {
          this.formGroup.get('summaryNote').clearValidators();
          this.formGroup.get('summaryNote').updateValueAndValidity();
        } else if (
          this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT
        ) {
          this.formGroup.get('complianceNote').clearValidators();
          this.formGroup.get('complianceNote').updateValueAndValidity();
        }
        this.formGroup.patchValue({
          complianceNote: this.complianceTestResponse.complianceTest.note,
          summaryNote:
            this.complianceTestResponse.complianceSummary?.summaryNote,
          attachmentFile: this.fileUploader.fileObjectList
        });
        this.tableResponsePreparedBy.setRecordList(this.preparedByUserList);
        this.tableResponseCheckedBy.setRecordList(this.checkedByUserList);
        this.complianceCheckStatusOptionList.setRequestValues(
          this.complianceTestResponse.complianceCheckStatusList
        );
        this.doOnChange(this.appOfficialReportModel);
        this.setStateReady();
      });
  }

  public onChangeDocumentCheckVendor(index: number, record: any): void {
    const tableResponse = this.tableResponseVendorList[index];
    if (
      record &&
      record.code !==
        this.global.appConstant.pm.COMPLIANCE_CHECK_STATUS_VERIFIED
    ) {
      tableResponse.currentRowChange.row.formGroup
        .get('complianceChecklistNote')
        .setValidators(Validators.required());
    } else {
      tableResponse.currentRowChange.row.formGroup
        .get('complianceChecklistNote')
        .clearValidators();
    }
    tableResponse.currentRowChange.row.formGroup
      .get('complianceChecklistNote')
      .updateValueAndValidity();
    this.appOfficialReportModel.isGenerate = false;
  }

  public onChangeDocumentVendor(index: number, record: any): void {
    console.log('Index', index);
    console.log('Record', record);
    const tableResponse = this.tableResponseVendorList[index];
    const formGroup = tableResponse.currentRowChange.row.formGroup.get(
      'complianceChecklistComplianceCheckStatus'
    );
    if (formGroup) {
      formGroup.patchValue(null);
    }
    this.appOfficialReportModel.isGenerate = false;
  }

  public onChangeDocumentProc(index: number, record: any): void {
    console.log('Index', index);
    console.log('Record', record);
    const tableResponse = this.tableResponseProcurementList[index];
    const formGroup = tableResponse.currentRowChange.row.formGroup.get(
      'complianceChecklistComplianceCheckStatus'
    );
    if (formGroup) {
      formGroup.patchValue(null);
    }
    this.appOfficialReportModel.isGenerate = false;
  }

  public onChangeDocumentCheckProcurement(index: number, record: any): void {
    const tableResponse = this.tableResponseProcurementList[index];
    if (
      record &&
      record.code !==
        this.global.appConstant.pm.COMPLIANCE_CHECK_STATUS_VERIFIED
    ) {
      tableResponse.currentRowChange.row.formGroup
        .get('complianceChecklistNote')
        .setValidators(Validators.required());
    } else {
      tableResponse.currentRowChange.row.formGroup
        .get('complianceChecklistNote')
        .clearValidators();
    }
    tableResponse.currentRowChange.row.formGroup
      .get('complianceChecklistNote')
      .updateValueAndValidity();
    this.appOfficialReportModel.isGenerate = false;
  }

  public doChangeComplianceType(): void {
    this.appPopupService
      .open(
        AppPopupComplianceTypeComponent,
        {
          complianceType: this.complianceType,
          complianceTypeList: this.complianceTestResponse.complianceTypeList
        },
        { size: 'md' }
      )
      .subscribe((complianceType: ComplianceType) => {
        this.complianceType = complianceType;
        this.procurementDocList.clear();
        this.vendorDocList.clear();
        this.buildTableResponseProcurement(
          this.complianceTestResponse.procurementModelList
        );
        this.buildTableResponseVendor(
          this.complianceTestResponse.vendorModelList
        );

        this.preparedByUserList = [];
        this.checkedByUserList = [];
        if (this.complianceTestResponse.preparedByUserList) {
          this.preparedByUserList =
            this.complianceTestResponse.preparedByUserList;
          const idList = this.preparedByUserList.map(user => user.id);
          if (
            this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
            idList.indexOf(this.complianceTestResponse.user.id) === -1
          ) {
            this.preparedByUserList.push(this.complianceTestResponse.user);
          }
        } else if (this.roleCode === this.global.appConstant.ROLE_CODE_BUYER) {
          this.preparedByUserList.push(this.complianceTestResponse.user);
        }

        if (this.complianceTestResponse.checkedByUserList) {
          this.checkedByUserList =
            this.complianceTestResponse.checkedByUserList;
          const idList = this.checkedByUserList.map(user => user.id);
          if (
            this.roleCode !== this.global.appConstant.ROLE_CODE_BUYER &&
            idList.indexOf(this.complianceTestResponse.user.id) === -1
          ) {
            this.checkedByUserList.push(this.complianceTestResponse.user);
          }
        } else if (this.roleCode !== this.global.appConstant.ROLE_CODE_BUYER) {
          this.checkedByUserList.push(this.complianceTestResponse.user);
        } else if (
          this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT
        ) {
          this.checkedByUserList.push(this.complianceTestResponse.user);
        }
        this.tableResponsePreparedBy.setRecordList(this.preparedByUserList);
        this.tableResponsePreparedBy.reload();
        this.tableResponseCheckedBy.setRecordList(this.checkedByUserList);
        this.tableResponseCheckedBy.reload();

        if (this.complianceTestResponse.summaryFileList) {
          this.fileUploader.setFileList(
            this.complianceTestResponse.summaryFileList
          );
        }

        if (
          this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT
        ) {
          this.formGroup.get('summaryNote').clearValidators();
          this.formGroup.get('summaryNote').updateValueAndValidity();
          this.formGroup
            .get('complianceNote')
            .setValidators([Validators.required()]);
          this.formGroup.get('complianceNote').updateValueAndValidity();
        } else if (
          this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT
        ) {
          this.formGroup
            .get('summaryNote')
            .setValidators([Validators.required()]);
          this.formGroup.get('summaryNote').updateValueAndValidity();
          this.formGroup.get('complianceNote').clearValidators();
          this.formGroup.get('complianceNote').updateValueAndValidity();
        }

        this.appOfficialReportModel.isGenerate = false;
      });
  }

  public doProcurementSummary(): void {
    this.appPopupProcSummaryService.open(this.procurementId);
  }

  public doVendorWinnerInfo(): void {
    this.appPopupService.open(
      AppPopupVendorWinnerInfoComponent,
      {
        procurementId: this.procurementId
      },
      { size: 'xl' }
    );
  }

  public doAddMorePreparedByUser(): void {
    this.appPopupService
      .open(
        AppPopupAddUserComponent,
        {
          user: this.complianceTestResponse.user,
          userList: this.preparedByUserList,
          userTempList: this.complianceTestResponse.userTempList
        },
        { size: 'lg' }
      )
      .subscribe((userList: User[]) => {
        this.preparedByUserList = userList;
        this.appOfficialReportModel.isGenerate = false;
        this.tableResponsePreparedBy.setRecordList(this.preparedByUserList);
        this.tableResponsePreparedBy.reload();
      });
  }

  public doAddMoreCheckedByUser(): void {
    this.appPopupService
      .open(
        AppPopupAddUserComponent,
        {
          user: this.complianceTestResponse.user,
          userList: this.checkedByUserList,
          userTempList: this.complianceTestResponse.userTempList
        },
        { size: 'lg' }
      )
      .subscribe((userList: User[]) => {
        this.checkedByUserList = userList;
        this.appOfficialReportModel.isGenerate = false;
        this.tableResponseCheckedBy.setRecordList(this.checkedByUserList);
        this.tableResponseCheckedBy.reload();
      });
  }

  public doDeletePreparedByUser(event): void {
    this.global.modalService
      .newDeleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach((record: User) => {
            const indexOfRecord = this.preparedByUserList.findIndex(
              r => r.id === record.id
            );
            this.preparedByUserList.splice(indexOfRecord, 1);
          });
          this.appOfficialReportModel.isGenerate = false;
          this.tableResponsePreparedBy.setRecordList(this.preparedByUserList);
          this.tableResponsePreparedBy.reload();
        }
      });
  }

  public doDeleteCheckedByUser(event): void {
    this.global.modalService
      .newDeleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach((record: User) => {
            const indexOfRecord = this.checkedByUserList.findIndex(
              r => r.id === record.id
            );
            this.checkedByUserList.splice(indexOfRecord, 1);
          });
          this.appOfficialReportModel.isGenerate = false;
          this.tableResponseCheckedBy.setRecordList(this.checkedByUserList);
          this.tableResponseCheckedBy.reload();
        }
      });
  }

  public onChangeSummary(): void {
    if (
      this.formGroup.value.summaryNote &&
      this.formGroup.value.summaryNote !== ''
    ) {
      this.isSummaryValid = true;
    } else {
      this.isSummaryValid = false;
    }
  }

  public doOnChange(model: AppOfficialReportModel): void {
    let isAnyValue = false;
    if (model.fileObjectList) {
      model.fileObjectList.forEach(fileObject => {
        if (!fileObject.isDeleted) {
          isAnyValue = true;
        }
      });
    }
    if (
      model.isGenerate &&
      model.letterDate &&
      model.letterNumber &&
      model.fileObjectList &&
      isAnyValue &&
      ((this.roleCode !== this.global.appConstant.ROLE_CODE_BUYER &&
        this.complianceType.code ===
          this.global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT) ||
        (this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
          this.complianceType.code ===
            this.global.appConstant.pm.COMPLIANCE_TYPE_SELF_ASSESSMENT))
    ) {
      this.isOfficialReportValid = true;
    } else {
      this.isOfficialReportValid = false;
    }
    this.appOfficialReportModel = model;
  }

  public doSave(): void {
    this.setStateProcessing();
    const updateRequest = this.prepareDataUpdateRequest(false);
    this.httpClientService
      .post<Response<ComplianceTest>>('/compliance-test/update', updateRequest)
      .subscribe(response => {
        this.setStateReady();
        if (response.status === ResponseStatusModel.OK) {
          this.formGroup.reset();
          this.snackbarService.showWarning('app.msg.info.sucsessSave');
          this.router
            .navigateByUrl('/', {
              skipLocationChange: true
            })
            .then(() => {
              this.global.routerParams.clear();
              this.global.routerParams.set('procurementId', this.procurementId);
              this.global.routerParams.set('todo', this.todo);
              this.router.navigate(['/pages/compliance-test/edit']);
            });
        }
      });
  }

  public doSubmit(): void {
    this.validate();
    this.doOnChange(this.appOfficialReportModel);
    if (
      this.roleCode === this.global.appConstant.ROLE_CODE_BUYER &&
      this.complianceType.code ===
        this.global.appConstant.pm.COMPLIANCE_TYPE_NON_SELF_ASSESSMENT
    ) {
      this.isOfficialReportValid = true;
    }
    if (this.formGroup.valid && this.isOfficialReportValid) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const updateRequest = this.prepareDataUpdateRequest(true);
            this.httpClientService
              .post<Response<ComplianceTest>>(
                '/compliance-test/update',
                updateRequest
              )
              .subscribe(response => {
                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccess()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate(['/pages/compliance-test']);
                      } else {
                        this.router
                          .navigateByUrl('/', {
                            skipLocationChange: true
                          })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set(
                              'procurementId',
                              this.procurementId
                            );
                            this.global.routerParams.set('todo', this.todo);
                            this.router.navigate([
                              '/pages/compliance-test/edit'
                            ]);
                          });
                      }
                    });
                } else {
                  this.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public prepareDataUpdateRequest(
    isSubmit: boolean
  ): ComplianceTestUpdateRequest {
    const updateRequest = new ComplianceTestUpdateRequest();
    updateRequest.procurementId = this.procurementId;
    updateRequest.appOfficialReportModel = this.appOfficialReportModel;
    updateRequest.summaryNote = this.formGroup.value.summaryNote;
    updateRequest.complianceNote = this.formGroup.value.complianceNote;
    updateRequest.complianceType = this.complianceType;
    updateRequest.isSubmit = isSubmit;
    updateRequest.fileObjectList = this.formGroup.value.attachmentFile;
    updateRequest.preparedByUserList = this.preparedByUserList;
    updateRequest.checkedByUserList = this.checkedByUserList;
    updateRequest.isRevise = false;
    updateRequest.isDone = this.complianceTestResponse.isDone;
    const procurementModelList: ComplianceTestModel[] = [];
    this.complianceTestResponse.procurementModelList.forEach(
      (procModel, index) => {
        const procurementModel = new ComplianceTestModel();
        procurementModel.complianceTestGroup = procModel.complianceTestGroup;
        const checklistModelList: ComplianceTestChecklistModel[] = [];
        this.tableResponseProcurementList[index].formArray.value.forEach(
          (data, index) => {
            const checklistModel = new ComplianceTestChecklistModel();
            const complianceChecklist =
              procModel.complianceTestChecklistModelList[index]
                .complianceChecklist;
            complianceChecklist.complianceCheckStatus =
              data.complianceChecklistComplianceCheckStatus;
            complianceChecklist.note = data.complianceChecklistNote;
            complianceChecklist.complianceTestMaster =
              procModel.complianceTestChecklistModelList[
                index
              ].complianceChecklist.complianceTestMaster;
            checklistModel.complianceChecklist = complianceChecklist;
            checklistModel.fileObjectList = data.fileList;
            if (
              complianceChecklist.complianceCheckStatus &&
              complianceChecklist.complianceCheckStatus.code ===
                this.global.appConstant.pm.COMPLIANCE_CHECK_STATUS_REVISED
            ) {
              updateRequest.isRevise = true;
            }
            checklistModelList.push(checklistModel);
          }
        );
        procurementModel.complianceTestChecklistModelList = checklistModelList;
        procurementModelList.push(procurementModel);
      }
    );
    updateRequest.procurementModelList = procurementModelList;

    const vendorModelList: ComplianceTestModel[] = [];
    this.complianceTestResponse.vendorModelList.forEach((vndrModel, index) => {
      const vendorModel = new ComplianceTestModel();
      vendorModel.procurementVendor = vndrModel.procurementVendor;
      const checklistModelList: ComplianceTestChecklistModel[] = [];
      this.tableResponseVendorList[index].formArray.value.forEach(
        (data, index) => {
          const checklistModel = new ComplianceTestChecklistModel();
          const complianceChecklist =
            vndrModel.complianceTestChecklistModelList[index]
              .complianceChecklist;
          complianceChecklist.complianceCheckStatus =
            data.complianceChecklistComplianceCheckStatus
              ? data.complianceChecklistComplianceCheckStatus
              : vndrModel.complianceTestChecklistModelList[index]
                  .complianceChecklist.complianceCheckStatus;
          complianceChecklist.note = data.complianceChecklistNote;
          complianceChecklist.complianceTestMaster =
            vndrModel.complianceTestChecklistModelList[
              index
            ].complianceChecklist.complianceTestMaster;
          checklistModel.complianceChecklist = complianceChecklist;
          checklistModel.fileObjectList = data.fileList;
          if (
            complianceChecklist.complianceCheckStatus &&
            complianceChecklist.complianceCheckStatus.code ===
              this.global.appConstant.pm.COMPLIANCE_CHECK_STATUS_REVISED
          ) {
            updateRequest.isRevise = true;
          }
          checklistModelList.push(checklistModel);
        }
      );
      vendorModel.complianceTestChecklistModelList = checklistModelList;
      vendorModelList.push(vendorModel);
    });
    updateRequest.vendorModelList = vendorModelList;

    return updateRequest;
  }

  public getTranslateKey(data: any): string {
    if (data?.translationKey) {
      return (
        data.translationKey.module.code.toLowerCase() +
        '.' +
        data.translationKey.key
      );
    } else {
      return data?.name;
    }
  }

  public getStringCustomValue(value: any): string {
    const color = this.getColorAndIconConfirm[value.code].color;
    const icon = this.getColorAndIconConfirm[value.code].icon;
    const text = this.translateService.instant(this.getTranslateKey(value));
    return (
      '<div style="display: inline-flex"><em style="font-size: 1.5rem" class="' +
      icon +
      '"></em>' +
      '&nbsp;<span class="' +
      color +
      '">' +
      text +
      '</span></div>'
    );
  }

  public get procurementDocList(): FormArray {
    return this.formGroup.get('procurementDocument') as FormArray;
  }

  public get vendorDocList(): FormArray {
    return this.formGroup.get('vendorDocument') as FormArray;
  }
}
