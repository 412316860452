<dashboard-widget-card
  size="XS"
  [isLoading]="model.isLoading"
  customClass="widget-card-rounded"
  customClassBody="d-flex justify-content-center align-items-center mt-3"
  (onClick)="doNavigateToProcHistory()"
>
  <div class="cml-2 d-flex align-items-center"
    ><div
      style="
        background: var(--primary-dark);
        width: 20px;
        height: 20px;
        border-radius: 50%;
      "
    ></div
  ></div>
  <div class="flex-1 mx-3">
    <h5 class="mb-2">{{
      'dashboard-widget-total-procurement-win.title' | translate
    }}</h5>
    <h2>{{ totalProcurementWin }}</h2>
  </div>
  <div class="pvc pv-trophy"></div>
</dashboard-widget-card>
