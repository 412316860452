<div class="row">
  <div class="col-12 mb-4">
    <app-card
      header="Purchase Order"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <form class="form-horizontal" [formGroup]="formGroup" novalidate>
        <div formArrayName="purchaseOrderList">
          <app-fieldset
            legend="{{
              'work-plan-purchase-order.fieldset.purchaseOrder' | translate
            }} {{ i + 1 }}"
            *ngFor="
              let purchaseOrder of formArrayPurchaseOrderList.controls;
              let i = index
            "
            [formGroup]="purchaseOrder"
          >
            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'work-plan-purchase-order.form.number' | translate
              }}</label>
              <div class="col-sm-6 text-left">
                <input
                  class="form-control validation-field"
                  type="text"
                  formControlName="number"
                  (keyup)="checkPONumber(i)"
                  [ngClass]="{
                    'is-invalid':
                      submitted && purchaseOrder.get('number').errors
                  }"
                  aria-label="Number"
                />
                <small
                  class="text-danger"
                  *ngIf="
                    (submitted && purchaseOrder.get('number').errors) ||
                    (purchaseOrder.get('number').touched &&
                      purchaseOrder.get('number').errors)
                  "
                >
                  {{ 'app.validation.required' | translate }}</small
                >
                <small class="text-danger" *ngIf="!isPONumberValidList[i]"
                  >{{ samePONumberList[i] }}
                  {{
                    'work-plan-purchase-order.msg.error.numberExist' | translate
                  }}</small
                >
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'work-plan-purchase-order.form.organization' | translate
              }}</label>
              <div class="col-sm-6 text-left">
                <label class="control-label">
                  {{ purchaseOrderDTO.organizationName }}</label
                >
              </div>
            </div>
            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'work-plan-purchase-order.form.startDate' | translate
              }}</label>
              <div class="col-sm-6 text-left">
                <app-date-picker formControlName="createdDate">
                </app-date-picker>
              </div>
            </div>

            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'work-plan-purchase-order.form.address' | translate
              }}</label>
              <div class="col-sm-6 text-left">
                <textarea
                  class="form-control"
                  formControlName="deliveryAddress"
                  maxlength="512"
                  rows="4"
                  [ngClass]="{
                    'is-invalid':
                      submitted &&
                      purchaseOrder.controls['deliveryAddress'].errors
                  }"
                  aria-label="Delivery Address"
                ></textarea>
                <small
                  class="text-danger"
                  *ngIf="
                    (submitted &&
                      purchaseOrder.controls['deliveryAddress'].errors) ||
                    (purchaseOrder.controls['deliveryAddress'].touched &&
                      purchaseOrder.controls['deliveryAddress'].errors)
                  "
                  >{{ 'app.validation.required' | translate }}</small
                >
              </div>
            </div>

            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'work-plan-purchase-order.form.description' | translate
              }}</label>
              <div class="col-sm-6 text-left">
                <textarea
                  class="form-control"
                  formControlName="description"
                  maxlength="512"
                  rows="4"
                  [ngClass]="{
                    'is-invalid':
                      submitted && purchaseOrder.controls['description'].errors
                  }"
                  aria-label="Description"
                ></textarea>
                <small
                  class="text-danger"
                  *ngIf="
                    (submitted &&
                      purchaseOrder.controls['description'].errors) ||
                    (purchaseOrder.controls['description'].touched &&
                      purchaseOrder.controls['description'].errors)
                  "
                  >{{ 'app.validation.required' | translate }}</small
                >
              </div>
            </div>

            <div class="form-group row text-sm-right required">
              <label class="col-sm-3 control-label">{{
                'work-plan-purchase-order.form.uploadfile' | translate
              }}</label>
              <div class="col-sm-7 text-left">
                <app-upload-multiple
                  formControlName="file"
                  [fileUploader]="fileUploaderList[i]"
                  (onChange)="onChangeFileUpload()"
                >
                </app-upload-multiple>
                <small
                  class="text-danger"
                  *ngIf="submitted && !fileUploaderList[i].isAllUploaded"
                  >{{ 'app.validation.required' | translate }}
                </small>
              </div>
            </div>

            <div formArrayName="purchaseRequestList" class="mb-4">
              <div
                [formGroupName]="j"
                *ngFor="
                  let purchaseRequestObj of purchaseOrder.get(
                    'purchaseRequestList'
                  ).controls;
                  let j = index
                "
              >
                <app-fieldset
                  legend="{{
                    'work-plan-purchase-order.fieldset.purchaseRequest'
                      | translate
                  }} {{ j + 1 }}"
                >
                  <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">{{
                      'work-plan-purchase-order.form.noPurchaseRequisition'
                        | translate
                    }}</label>
                    <div class="col-sm-6 text-left">
                      <select
                        (change)="doOnChangePurchaseRequest(purchaseRequestObj)"
                        class="form-control validation-field"
                        required
                        formControlName="purchaseRequest"
                        aria-label="Purchase Request"
                      >
                        <option
                          *ngFor="
                            let purchaseRequest of purchaseOrderDTO.purchaseRequestList
                          "
                          [ngValue]="purchaseRequest"
                          [hidden]="purchaseRequest.isDisabled"
                        >
                          {{ purchaseRequest.name }}
                        </option>
                      </select>
                      <!-- <small class="text-danger"
                      *ngIf="purchaseRequestObj
                      .controls['purchaseRequestList']
                      .invalid && 
                      purchaseRequestObj
                      .controls['purchaseRequestList']
                      .touched">{{
                        'app.validation.required' | translate 
                        }}
                        </small> -->
                    </div>
                  </div>
                  <div
                    class="table-responsive custom-table mb-4 float-none"
                    [ngStyle]="{ margin: '0 auto' }"
                  >
                    <table
                      class="table table-striped box-shadow"
                      aria-describedby="Table"
                    >
                      <thead>
                        <tr>
                          <th scope="col">{{
                            'work-plan-purchase-order.table.column.no'
                              | translate
                          }}</th>
                          <th scope="col">{{
                            'work-plan-purchase-order.table.column.itemCode'
                              | translate
                          }}</th>
                          <th scope="col">{{
                            'work-plan-purchase-order.table.column.itemName'
                              | translate
                          }}</th>
                          <th scope="col">{{
                            'work-plan-purchase-order.table.column.uom'
                              | translate
                          }}</th>
                          <th scope="col">{{
                            'work-plan-purchase-order.table.column.category'
                              | translate
                          }}</th>
                          <th scope="col" class="text-right">{{
                            'work-plan-purchase-order.table.column.quantity'
                              | translate
                          }}</th>
                          <th scope="col" class="text-right">{{
                            'work-plan-purchase-order.table.column.unitPrice'
                              | translate
                          }}</th>
                          <th scope="col" class="text-right">{{
                            'work-plan-purchase-order.table.column.totalPrice'
                              | translate
                          }}</th>
                          <th scope="col">{{
                            'work-plan-purchase-order.table.column.budgetNumber'
                              | translate
                          }}</th>
                        </tr>
                      </thead>
                      <tbody formArrayName="purchaseRequestItemList">
                        <tr
                          *ngIf="
                            purchaseRequestObj.value.purchaseRequestItemList
                              .length == 0
                          "
                        >
                          <td colspan="99" class="bg-white text-center">
                            {{ 'app.info.noData' | translate }}
                          </td>
                        </tr>
                        <tr
                          [formGroupName]="x"
                          *ngFor="
                            let purchaseRequestItem of purchaseRequestObj.get(
                              'purchaseRequestItemList'
                            ).controls;
                            let x = index
                          "
                        >
                          <td class="text-center">{{ x + 1 }}.</td>
                          <td
                            >{{
                              purchaseRequestItem.value.workPlanItem.item.code
                            }}
                          </td>
                          <td
                            >{{
                              purchaseRequestItem.value.workPlanItem.item.name
                            }}
                          </td>
                          <td>{{
                            purchaseRequestItem.value.workPlanItem.item.uom.name
                          }}</td>
                          <td>{{
                            purchaseRequestItem.value.workPlanItem.item.itemCategory
                              .name
                          }}</td>
                          <td class="text-right">{{
                            purchaseRequestItem.value.quantity
                          }}</td>
                          <td class="text-right">{{
                            purchaseRequestItem.value.unitPrice | currency: ' '
                          }}</td>
                          <td class="text-right">{{
                            purchaseRequestItem.value.totalPrice | currency: ' '
                          }}</td>
                          <td>{{
                            purchaseRequestItem.value.workPlanItem.budget.number
                          }}</td>
                        </tr>
                        <tr>
                          <td colspan="6"></td>
                          <td class="text-sm-right">
                            <strong>{{
                              'work-plan-purchase-order.form.budgetTotal'
                                | translate
                            }}</strong>
                          </td>
                          <td class="text-sm-right">
                            <strong>
                              {{
                                purchaseRequestObj.value.totalRealisasi
                                  | currency: '
                              '
                              }}</strong
                            >
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="form-group row text-sm-right required">
                    <label class="col-sm-3 control-label">
                      {{
                        'work-plan-purchase-order.form.vendor' | translate
                      }}</label
                    >
                    <div class="col-sm-6 text-left">
                      <input
                        class="form-control validation-field"
                        type="text"
                        formControlName="vendor"
                        [ngClass]="{
                          'is-invalid':
                            submitted &&
                            purchaseRequestObj.controls['vendor'].errors
                        }"
                        aria-label="Vendor"
                      />
                      <small
                        class="text-danger"
                        *ngIf="
                          (submitted &&
                            purchaseRequestObj.controls['vendor'].errors) ||
                          (purchaseRequestObj.controls['vendor'].touched &&
                            purchaseRequestObj.controls['vendor'].errors)
                        "
                        >{{ 'app.validation.required' | translate }}</small
                      >
                    </div>
                  </div>

                  <div class="row justify-content-center mt-5">
                    <button
                      type="button "
                      *ngIf="
                        purchaseOrder.get('purchaseRequestList').length > 1
                      "
                      class="btn btn-danger ml-2"
                      (click)="
                        doDeletePurchaseRequest(
                          j,
                          i,
                          purchaseOrder.get('purchaseRequestList')
                        )
                      "
                    >
                      <span>
                        {{
                          'work-plan-purchase-order.button.deletePurchaseRequest'
                            | translate
                        }}</span
                      >
                    </button>
                  </div>
                </app-fieldset>
              </div>
              <div class="row justify-content-center mt-5">
                <span [ngbTooltip]="msgInfo" placement="top">
                  <button
                    type="button "
                    class="btn btn-primary ml-2"
                    (click)="onClickAddPurchaseRequest(i)"
                    [disabled]="isDisabledAddMore || formGroup.invalid"
                  >
                    <span class="fas fa-plus"></span>
                    <span>
                      {{
                        'work-plan-purchase-order.button.addPurchaseRequest'
                          | translate
                      }}</span
                    >
                  </button>
                </span>
              </div>
            </div>

            <div class="row justify-content-center mt-5">
              <button
                *ngIf="formGroup.get('purchaseOrderList').length > 1"
                type="button "
                class="btn btn-danger"
                (click)="doDeletePurchaseOrder(i)"
              >
                {{
                  'work-plan-purchase-order.button.deletePurchaseOrder'
                    | translate
                }}
              </button>
            </div>
          </app-fieldset>
        </div>
        <span [ngbTooltip]="msgInfo" placement="top">
          <div class="row justify-content-center mt-5">
            <!-- onClickAddPurchaseOrder(i):
             coba di cek "i"-nya ini diluar
              form jadi tidak ada. 
              Di methodnya juga tidak ada -->
            <button
              type="button "
              class="btn btn-primary ml-2"
              (click)="onClickAddPurchaseOrder()"
              [disabled]="isDisabledAddMore || formGroup.invalid"
            >
              <span class="fas fa-plus"></span>&nbsp;
              <span>{{
                'work-plan-purchase-order.button.addPurchaseOrder' | translate
              }}</span>
            </button>
          </div>
        </span>
        <div class="row justify-content-center mt-5">
          <button
            type="button "
            class="btn btn-primary ml-2"
            (click)="doSave()"
            [disabled]=""
          >
            {{ 'app.button.save' | translate }}
          </button>
        </div>
      </form>
      <div class="row justify-content-center mt-5">
        <button
          type="button "
          class="btn btn-secondary"
          (click)="doPrevios()"
          [disabled]="formSaving"
        >
          {{ 'app.button.previous' | translate }}
        </button>
        <button
          type="button "
          class="btn btn-primary ml-2"
          (click)="doNext()"
          [disabled]="!isHavePO"
        >
          {{ 'work-plan-purchase-order.button.next' | translate }}
        </button>
      </div>
    </app-card>
  </div>
</div>
