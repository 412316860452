import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { AllPerformanceDetailResultComponent } from './all-performance-detail-result.component';
import { AllPerformanceDetailComponent } from './all-performance-detail.component';
import { AllPerformanceGenerateComponent } from './all-performance-generate.component';
import { AllPerformanceComponent } from './all-performance.component';

export const routes = [
  { path: '', component: AllPerformanceComponent, data: { breadcrumb: '' } },
  {
    path: 'generate',
    component: AllPerformanceGenerateComponent,
    data: { breadcrumb: 'all-performance.breadcrumb.generate' }
  },
  //   { path: 'detail', component: AllPerformanceDetailComponent, data: { breadcrumb: 'Detail'} },

  {
    path: 'detail',
    data: { breadcrumb: 'all-performance.breadcrumb.detail' },
    children: [
      { path: '', component: AllPerformanceDetailComponent },
      {
        path: 'detail-result',
        component: AllPerformanceDetailResultComponent,
        data: { breadcrumb: 'all-performance.breadcrumb.detailResult' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [
    AllPerformanceComponent,
    AllPerformanceGenerateComponent,
    AllPerformanceDetailComponent,
    AllPerformanceDetailResultComponent
  ],
  providers: []
})
export class AllPerformanceModule {}
