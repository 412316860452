import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import pako from 'pako';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { HttpClientRequest } from '../interfaces/http-client-request';
@Injectable({ providedIn: 'root' })
export class HttpClientService {
  public httpClientRequestList: Array<HttpClientRequest<any>> = new Array();
  public completedRequestChanges: EventEmitter<void> = new EventEmitter();
  private timeOut: NodeJS.Timeout;
  constructor(public httpClient: HttpClient) {}
  public post<T>(
    url: string,
    body: any,
    options: object = {},
    isCompressed = false
  ): Observable<T | any> {
    const subject: Subject<T> = new Subject();
    let pakoBuffer = null;
    if (isCompressed) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Encoding': 'gzip',
          'Content-Type': 'application/octet-stream'
        })
      };
      pakoBuffer = pako.gzip(JSON.stringify(body)).buffer;
      const httpClientRequest: HttpClientRequest<T> = {
        position: this.httpClientRequestList.length,
        status: 'ON REQUEST',
        responseChanges: new EventEmitter(),
        subject,
        requestUrl: url
      };
      this.httpClientRequestList.push(httpClientRequest);
      return this.httpClient
        .post<T | any>(url, pakoBuffer, httpOptions)
        .pipe(takeUntil(subject))
        .pipe(
          map((event: HttpEvent<T | any>) => {
            httpClientRequest.status = 'COMPLETED';
            this.emitCompleteRequestChanges();
            return event;
          })
        );
    } else {
      const httpClientRequest: HttpClientRequest<T> = {
        position: this.httpClientRequestList.length,
        status: 'ON REQUEST',
        responseChanges: new EventEmitter(),
        subject,
        requestUrl: url
      };
      this.httpClientRequestList.push(httpClientRequest);
      return this.httpClient
        .post<T | any>(url, body, options)
        .pipe(takeUntil(subject))
        .pipe(
          map((event: HttpEvent<T | any>) => {
            httpClientRequest.status = 'COMPLETED';
            this.emitCompleteRequestChanges();
            return event;
          })
        );
    }
  }

  public get<T>(url: string, options: object = {}): Observable<T | any> {
    const subject: Subject<any> = new Subject();
    const httpClientRequest: HttpClientRequest<T> = {
      position: this.httpClientRequestList.length,
      status: 'ON REQUEST',
      responseChanges: new EventEmitter(),
      subject,
      requestUrl: url
    };
    this.httpClientRequestList.push(httpClientRequest);
    return this.httpClient
      .get<T | any>(encodeURI(url), options)
      .pipe(takeUntil(subject))
      .pipe(
        map((event: HttpEvent<T | any>) => {
          httpClientRequest.status = 'COMPLETED';
          this.emitCompleteRequestChanges();
          return event;
        })
      );
  }

  public request<T>(request: HttpRequest<any>): Observable<T | any> {
    const subject: Subject<any> = new Subject();
    const httpClientRequest: HttpClientRequest<T> = {
      position: this.httpClientRequestList.length,
      status: 'ON REQUEST',
      responseChanges: new EventEmitter(),
      subject,
      requestUrl: request.url
    };
    this.httpClientRequestList.push(httpClientRequest);
    return this.httpClient
      .request<T | any>(request)
      .pipe(takeUntil(subject))
      .pipe(
        map((event: HttpEvent<T | any>) => {
          if (event instanceof HttpResponse) {
            httpClientRequest.status = 'COMPLETED';
            this.emitCompleteRequestChanges();
          }
          return event;
        })
      );
  }

  public reset(): void {
    this.httpClientRequestList.splice(0);
  }

  public unsubscribe(): void {
    this.httpClientRequestList.forEach(
      (httpClientRequest: HttpClientRequest<any>) => {
        httpClientRequest.subject.next([]);
        httpClientRequest.subject.complete();
      }
    );
    this.reset();
  }

  private emitCompleteRequestChanges(): void {
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      const isCompleteRequest =
        this.httpClientRequestList.filter(
          httpClientRequest => httpClientRequest.status === 'ON REQUEST'
        ).length === 0;
      if (isCompleteRequest) {
        this.completedRequestChanges.emit();
      }
    }, 500);
  }
}
