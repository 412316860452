import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from '../../../../../../core/services/http-client.service';
import { TableCatalogRequestModel } from './model/table-catalog-request-model';
@Injectable()
export class AppTableCatalogService {
  constructor(public httpClientService: HttpClientService) {}
  getCatalogs(stringUrl: string, tableRequest: TableCatalogRequestModel): Observable<any> {
    return this.httpClientService.post<any>(stringUrl, tableRequest);
  }
}
