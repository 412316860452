import { ContractType } from '../../core/bean/contract-type';
import { ModuleScoringCriteria } from '../../core/bean/module-scoring-criteria';
import { OrderType } from '../../core/bean/order-type';
import { ProcurementType } from '../../core/bean/procurement-type';
import { ScoringCriteriaGroup } from '../../core/bean/scoring-criteria-group';
import { ScoringTemplate } from '../../core/bean/scoring-template';
import { ScoringTemplateWeight } from '../../core/bean/scoring-template-weight';
import { VendorType } from '../../core/bean/vendor-type';

export class ScoringTemplateResponse {
  scoringTemplateWeightListCommon: ScoringTemplateWeight[];
  scoringTemplateWeightListSpesific: ScoringTemplateWeight[];
  moduleScoringCriteriaList: ModuleScoringCriteria[];
  scoringCriteriaGroupListNotByScoringTemplate: ScoringCriteriaGroup[];
  scoringCriteriaGroupListByScoringTemplate: ScoringCriteriaGroup[];
  scoringCriteriaGroupList: ScoringCriteriaGroup[];
  vendorTypeList: VendorType[];
  contractTypeList: ContractType[];
  procurementTypeList: ProcurementType[];
  scoringTemplate: ScoringTemplate;
  contractType: ContractType;
  orderType: OrderType;
}
