import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TreeModel } from '../../core/components/app-tree';
import { Validators } from '../../core/validators';
import { BaseModuleComponent } from './../../core/base/angular/base-module.component';
@Component({
  templateUrl: './segmentation.component.html'
})
export class SegmentationComponent extends BaseModuleComponent {
  public treeModel: TreeModel = new TreeModel(this.moduleCode, 'WRITE');
  constructor(translateService: TranslateService) {
    super('segmentation', translateService);
  }

  onInit(): void {
    this.doBuildTreeFormGroup();
    this.setStateReady();
  }

  public doBuildTreeFormGroup(): void {
    this.treeModel.formGroup = this.formBuilder.group({
      id: [null],
      code: [null, Validators.compose([Validators.required()])],
      name: [null, Validators.compose([Validators.required()])],
      description: [null],
      tempId: [null],
      parentId: [null],
      hasChild: [null]
    });
  }
}
