<app-popup [header]="title">
  <div class="row">
    <div class="col-8">
      <div id="popupCarousel" class="carousel" data-interval="false">
        <!-- Wrapper for slides -->
        <div class="carousel-inner image-box-slideshow-main">
          <div
            class="carousel-item text-center"
            *ngFor="let img of fileList; let i = index"
            [ngClass]="i === activeIndex ? 'active' : ''"
          >
            <img
              class="image-main"
              [attr.src]="
                global.config.BACKEND_ADDRESS +
                '/' + parentModuleCode + '/file/view/' +
                img.uploadedFileName +
                '/' +
                img.fileType.code | resolveImgSrc | async
              "
              [attr.alt]="img.uploadedFileName"
              [disabled]="true"
            />
          </div>
        </div>

        <a
          class="carousel-control-prev control-left"
          href="#popupCarousel"
          data-slide="prev"
          (click)="onPrevious()"
        >
          <em class="fas fa-chevron-circle-left fa-2x"></em>
        </a>
        <a
          class="carousel-control-next control-right"
          href="#popupCarousel"
          data-slide="next"
          (click)="onNext()"
        >
          <em
            class="fas fa-chevron-circle-right fa-2x"
          ></em>
        </a>
      </div>
    </div>
    <div class="col">
      <em type="button" class="close outline-none" aria-label="Close" (click)="doCloseImage()">
        <span aria-hidden="true" class="pir pi-times"></span>
      </em>
      <div class="row">
        <div
          class="col-4 image-box-slideshow"
          *ngFor="let image of fileList; let j = index"
        >
          <img
            [attr.src]="
              global.config.BACKEND_ADDRESS +
              '/' + parentModuleCode + '/thumbnail/view/' +
              image.uploadedFileName +
              '/' +
              image.fileType.code | resolveImgSrc | async
            "
            class="cursor-pointer image-indicator"
            data-target="#popupCarousel"
            [attr.data-slide-to]="j"
            (click)="onClickImage(j)"
            [attr.alt]="image.uploadedFileName"
            style="max-width: 100%"
            [ngClass]="[activeBorder === j ? 'image-choosen' : '']"
            [disabled]="true"
          />
        </div>
      </div>
    </div>
  </div>
</app-popup>
