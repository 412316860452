import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { DocGroupResponse } from './doc-group-response';
import { DocGroupRequest } from './doc-group-request';

@Component({
  templateUrl: './doc-group-edit-add.component.html'
})

export class DocGroupEditAddComponent extends BaseModuleComponent {
  public docGroupResponse: DocGroupResponse = new DocGroupResponse();
  public docGroupRequest: DocGroupRequest = new DocGroupRequest();
  public translationKeyId: number;
  public langList = [];
  public translationListLabel = [];
  constructor(translateService: TranslateService) {
    super('document-group', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.translationKeyId = this.global.routerParams.get('translationKeyId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      description: [null, Validators.maxLength(512)],
      translationList: this.formBuilder.array([]),
      translationKey: [null]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<DocGroupResponse>(
        '/doc-group/add-edit',
        new RouteRequestModel(this.todo, this.translationKeyId)
      )
      .subscribe(docGroupResponse => {
        this.langList = docGroupResponse.langList;
        this.setTranslation(docGroupResponse);
        if (this.todo === 'edit') {
          this.setDocGroup(docGroupResponse);
        }
        this.setStateReady();
      });
  }

  public setDocGroup(docGroupResponse: DocGroupResponse): void {
    const { id, code, description, translationKey } = docGroupResponse.docGroup;
    this.formGroup.patchValue({ id, code, description, translationKey });
  }

  public setTranslation(docGroupResponse: DocGroupResponse): void {
    if (docGroupResponse.translationList != null) {
      docGroupResponse.translationList.forEach(translation => {
        this.translationListLabel.push(translation.lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: translation.value
          })
        );
      });
    } else {
      docGroupResponse.langList.forEach(lang => {
        this.translationListLabel.push(lang.code);
        this.translationList.push(
          this.formBuilder.group({
            value: [
              null,
              Validators.compose([
                Validators.required(),
                Validators.maxLength(64)
              ])
            ]
          })
        );
      });
    }
  }

  public doSave(): void {
    this.validate();
    this.setFormArrayValidation();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.docGroupRequest.docGroup = this.formGroup.value;
            this.docGroupRequest.translationValueList = this.formGroup
              .get('translationList')
              .value.map(a => a.value);
            const url =
              this.todo === 'edit' ? '/doc-group/update' : '/doc-group/insert';
            this.httpClientService
              .post<Response<DocGroupRequest>>(url, this.docGroupRequest)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/doc-group']);
                  this.translateService.reloadLang(
                    this.global.getCurrentLang()
                  );
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public setFormArrayValidation(): void {
    for (const control of this.translationList.controls) {
      control.setStateSubmitted();
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/doc-group']);
  }

  public get translationList(): FormArray {
    return this.formGroup.get('translationList') as FormArray;
  }
}
