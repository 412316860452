<div class="row main-row app-accordion-row">
  <div class="col-12">
    <app-card
      header="billing-memo.title"
      detail="billing-memo.action.{{ todo }}"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-accordion [isLoading]="formLoading" *ngIf="!formLoading">
        <app-accordion-item
          header="billing-memo.accordion.billingMemo"
          [isExpanded]="true"
        >
          <form class="form-horizontal" [formGroup]="formGroup" novalidate>
            <div
              class="form-group row text-sm-right"
              [ngClass]="isViewOnly ? '' : 'required'"
            >
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.number' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  formControlName="number"
                  maxLength="32"
                  placeholder="{{
                    'billing-memo.placeholder.number' | translate
                  }}"
                  autofocus="true"
                ></app-text-field>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="isViewOnly ? '' : 'required'"
            >
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.date' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-date-picker
                  size="MD"
                  formControlName="date"
                ></app-date-picker>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="isViewOnly ? '' : 'required'"
            >
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.priority' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-combo-box
                  formControlName="priority"
                  [optionList]="priorityList"
                ></app-combo-box>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="isViewOnly ? '' : 'required'"
            >
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.subject' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-field
                  formControlName="subject"
                  maxLength="128"
                ></app-text-field>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="isViewOnly ? '' : 'required'"
            >
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.memoBackground' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-area
                  formControlName="background"
                  maxLength="512"
                ></app-text-area>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="isViewOnly ? '' : 'required'"
            >
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.memoBase' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-area
                  formControlName="base"
                  maxLength="512"
                ></app-text-area>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="isViewOnly ? '' : 'required'"
            >
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.memoClosing' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-area
                  formControlName="closing"
                  maxLength="512"
                ></app-text-area>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="isViewOnly ? '' : 'required'"
            >
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.city' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-tree
                  formControlName="city"
                  stringUrl="/region"
                  header="{{ 'billing-memo.header.city' | translate }}"
                  [onlyLastChild]="true"
                  [limitLevel]="2"
                  isLazy="true"
                ></app-text-tree>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="isViewOnly ? '' : 'required'"
            >
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.firstSigner' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-auto-complete
                  formControlName="firstSigner"
                  stringUrl="/billing-memo/auto-complete-first-signer-list"
                ></app-auto-complete>
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.secondSigner' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-auto-complete
                  formControlName="secondSigner"
                  stringUrl="/billing-memo/auto-complete-second-signer-list"
                ></app-auto-complete>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="isViewOnly ? '' : 'required'"
            >
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.paymentType' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-text-tree
                  formControlName="paymentType"
                  stringUrl="/payment-type"
                  header="{{ 'billing-memo.header.paymentType' | translate }}"
                  [onlyLastChild]="true"
                ></app-text-tree>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              [ngClass]="isViewOnly ? '' : 'required'"
            >
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.paymentMethod' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                <app-combo-box
                  formControlName="paymentMethod"
                  [optionList]="paymentMethodList"
                ></app-combo-box>
              </div>
            </div>
            <div
              class="form-group row text-sm-right"
              *ngIf="
                billingMemoEditResponse.billingMemo &&
                paymentStatus.code ===
                  global.appConstant.cm.PAYMENT_STATUS_REVISION_BILLING_MEMO
              "
            >
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.approvalNote' | translate
              }}</label>
              <div class="col-sm-9 text-left"
                >{{
                  billingMemoEditResponse.billingMemo.rejectNote
                    ? billingMemoEditResponse.billingMemo.rejectNote
                    : '-'
                }}
              </div>
            </div>

            <h5 class="separator">{{
              'billing-memo.form-group.procurementValue' | translate
            }}</h5>
            <div
              class="form-group row text-sm-left"
              [ngClass]="isViewOnly ? '' : 'required'"
            >
              <div class="col-sm-3">
                <app-check-box
                  formControlName="isIncludeVAT"
                  position="HORIZONTAL"
                  (onChange)="onChangeIncludeVAT()"
                >
                  <label>{{
                    'billing-memo.form.procurementValue' | translate
                  }}</label>
                </app-check-box>
              </div>
            </div>

            <h6 class="separator">{{
              'billing-memo.form-group.procurementValue' | translate
            }}</h6>
            <ng-container formArrayName="budgetList">
              <div class="form-group row text-sm-right">
                <div
                  class="card border-0"
                  [ngStyle]="{
                    margin: '0 1.5%',
                    width: '-webkit-fill-available'
                  }"
                >
                  <div class="card-body">
                    <div
                      class="table-responsive custom-table float-none w-100"
                      [ngStyle]="{ margin: '0 auto' }"
                    >
                      <table
                        class="table table-striped box-shadow"
                        aria-describedby="Table"
                      >
                        <thead>
                          <tr class="text-center">
                            <th scope="col"
                              >{{ 'app.table.column.no' | translate }}
                            </th>
                            <th scope="col"
                              >{{ 'billing-memo.table.column.coa' | translate }}
                            </th>
                            <th scope="col"
                              >{{
                                'billing-memo.table.column.costCenter'
                                  | translate
                              }}
                            </th>
                            <th scope="col" class="text-right"
                              >{{
                                'billing-memo.table.column.taxPercentage'
                                  | translate
                              }}
                            </th>
                            <th class="text-right" scope="col"
                              >{{
                                'billing-memo.table.column.taxBase' | translate
                              }}
                            </th>
                            <th class="text-right" scope="col"
                              >{{ 'billing-memo.table.column.vat' | translate }}
                            </th>
                            <th class="text-right" scope="col"
                              >{{
                                'billing-memo.table.column.total' | translate
                              }}
                            </th>
                            <th scope="col"
                              >{{ 'app.table.column.action' | translate }}
                              <em
                                class="fas fa-info-circle"
                                ngbTooltip="{{
                                  'billing-memo.tooltip.action' | translate
                                }}"
                                container="body"
                                *ngIf="!isViewOnly"
                              ></em>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of formGroup.controls.budgetList
                                .controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <td class="text-center"> {{ i + 1 }}. </td>
                            <td class="text-center">
                              {{ item.controls.coa.value }}
                              &nbsp;<em
                                class="fas fa-eye cursor-pointer"
                                (click)="doDetailBudget(i)"
                              ></em>
                            </td>
                            <td class="text-center white-space-normal">
                              {{ item.controls.costCenter.value }}
                            </td>
                            <td class="text-center">
                              <app-text-field
                                size="XL"
                                formControlName="taxPercentage"
                                type="integer"
                                include="."
                                (onChange)="onChangeTaxPercentageItem(i)"
                              ></app-text-field>
                            </td>
                            <td class="text-right">
                              <div *ngIf="!formGroup.value.isIncludeVAT">
                                <app-text-field
                                  size="XL"
                                  formControlName="taxBase"
                                  type="currency"
                                  (onChange)="onChangeTaxBaseBudget(i)"
                                ></app-text-field>
                              </div>
                              <div *ngIf="formGroup.value.isIncludeVAT">
                                {{
                                  item.controls.taxBase.value
                                    ? (item.controls.taxBase.value
                                      | currency: ' ')
                                    : 0
                                }}
                                {{ currency.code }}
                              </div>
                            </td>
                            <td class="text-right">
                              {{
                                item.controls.vat.value
                                  ? (item.controls.vat.value | currency: ' ')
                                  : 0
                              }}
                              {{ currency.code }}
                            </td>
                            <td class="text-right">
                              <div *ngIf="formGroup.value.isIncludeVAT">
                                <app-text-field
                                  size="XL"
                                  formControlName="total"
                                  type="currency"
                                  (onChange)="onChangeTotalBudget(i)"
                                ></app-text-field>
                              </div>
                              <div *ngIf="!formGroup.value.isIncludeVAT">
                                {{
                                  item.controls.total.value
                                    ? (item.controls.total.value
                                      | currency: ' ')
                                    : 0
                                }}
                                {{ currency.code }}
                              </div>
                            </td>
                            <td class="text-center">
                              <app-button
                                size="TN"
                                (onClick)="doEditItem(item, i)"
                                title="{{ 'app.tooltip.edit' | translate }}"
                                *ngIf="!isViewOnly"
                              >
                                <em class="fas fa-pencil-alt"></em>
                              </app-button>
                              <app-button
                                size="TN"
                                (onClick)="doViewItem(item, i)"
                                title="{{ 'app.tooltip.detail' | translate }}"
                                *ngIf="isViewOnly"
                              >
                                <em class="fas fa-search"></em>
                              </app-button>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td class="text-right">
                            <b>
                              {{
                                'billing-memo.table.column.totalValue'
                                  | translate
                              }}
                            </b>
                          </td>
                          <td class="text-right">
                            <b>
                              {{
                                totalProcurementValueTotal
                                  ? (totalProcurementValueTotal | currency: ' ')
                                  : 0
                              }}
                              {{ currency.code }}
                            </b>
                          </td>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <label class="text-left">
                    *)
                    {{ 'billing-memo.footnote.procurementValue' | translate
                    }}<b>
                      {{ billingAmount ? (billingAmount | currency: ' ') : 0 }}
                      {{ currency.code }} </b
                    >)
                  </label>
                </div>
              </div>
            </ng-container>

            <h5 class="separator">{{
              'billing-memo.form-group.incomeTax' | translate
            }}</h5>
            <ng-container formArrayName="taxList">
              <div class="form-group row text-sm-right">
                <div
                  class="card border-0"
                  [ngStyle]="{
                    margin: '0 1.5%',
                    width: '-webkit-fill-available'
                  }"
                >
                  <div class="card-body">
                    <div
                      class="table-responsive custom-table float-none w-100"
                      [ngStyle]="{ margin: '0 auto' }"
                    >
                      <table
                        class="table table-striped box-shadow"
                        aria-describedby="Table"
                      >
                        <thead>
                          <tr class="text-center">
                            <th scope="col"
                              >{{ 'app.table.column.no' | translate }}
                            </th>
                            <th class="text-left" scope="col"
                              >{{
                                'billing-memo.table.column.taxName' | translate
                              }}
                            </th>
                            <th scope="col"
                              >{{ 'billing-memo.table.column.coa' | translate }}
                            </th>
                            <th scope="col"
                              >{{
                                'billing-memo.table.column.costCenter'
                                  | translate
                              }}
                            </th>
                            <th scope="col" class="text-right"
                              >{{
                                'billing-memo.table.column.taxPercentage'
                                  | translate
                              }}
                            </th>
                            <th class="text-right" scope="col"
                              >{{
                                'billing-memo.table.column.taxBase' | translate
                              }}
                            </th>
                            <th class="text-right" scope="col"
                              >{{
                                'billing-memo.table.column.incomeTax'
                                  | translate
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let tax of formGroup.controls.taxList.controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <td class="text-center"> {{ i + 1 }}. </td>
                            <td class="text-left">
                              {{ tax.controls.taxName.value }}
                            </td>
                            <td class="text-center">
                              {{
                                tax.controls.coa.value
                                  ? tax.controls.coa.value
                                  : '-'
                              }}
                            </td>
                            <td class="text-center">
                              <app-auto-complete
                                size="XL"
                                formControlName="costCenter"
                                stringUrl="/billing-memo/auto-complete-cost-center-list"
                                (onChange)="onChangeCostCenter(i)"
                                container="body"
                              ></app-auto-complete>
                            </td>
                            <td class="text-center">
                              <app-text-field
                                size="XL"
                                formControlName="taxPercentage"
                                type="integer"
                                include="."
                                (onChange)="onChangeTaxPercentage(i)"
                              ></app-text-field>
                            </td>
                            <td class="text-left">
                              <app-text-field
                                size="XL"
                                formControlName="taxBase"
                                type="currency"
                                (onChange)="onChangeTaxBase(i)"
                              ></app-text-field>
                            </td>
                            <td class="text-right">
                              {{
                                tax.controls.incomeTax.value
                                  ? (tax.controls.incomeTax.value
                                    | currency: ' ')
                                  : 0
                              }}
                              {{ currency.code }}
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td class="text-right">
                            <b>
                              {{
                                'billing-memo.table.column.totalValue'
                                  | translate
                              }}
                            </b>
                          </td>
                          <td class="text-right">
                            <b>
                              {{
                                totalIncomeTax
                                  ? (totalIncomeTax | currency: ' ')
                                  : 0
                              }}
                              {{ currency.code }}
                            </b>
                          </td>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <h5 class="separator">{{
              'billing-memo.form-group.otherCost' | translate
            }}</h5>
            <ng-container formArrayName="otherCostList">
              <div class="text-right">
                <ng-container *ngIf="!isViewOnly">
                  <app-button (onClick)="doAddMore()"
                    ><em class="fas fa-plus"></em>
                    {{ 'app.button.addMore' | translate }}</app-button
                  >
                </ng-container>
              </div>
              <div class="form-group row text-sm-right">
                <div
                  class="card border-0"
                  [ngStyle]="{
                    margin: '0 1.5%',
                    width: '-webkit-fill-available'
                  }"
                >
                  <div class="card-body">
                    <div
                      class="table-responsive custom-table float-none w-100"
                      [ngStyle]="{ margin: '0 auto' }"
                    >
                      <table
                        class="table table-striped box-shadow"
                        aria-describedby="Table"
                      >
                        <thead>
                          <tr class="text-center">
                            <th scope="col"
                              >{{ 'app.table.column.no' | translate }}
                            </th>
                            <th class="text-left" scope="col"
                              >{{
                                'billing-memo.table.column.itemName' | translate
                              }}
                            </th>
                            <th scope="col"
                              >{{ 'billing-memo.table.column.coa' | translate }}
                            </th>
                            <th scope="col"
                              >{{
                                'billing-memo.table.column.costCenter'
                                  | translate
                              }}
                            </th>
                            <th class="text-right" scope="col"
                              >{{
                                'billing-memo.table.column.value' | translate
                              }}
                            </th>
                            <th scope="col"
                              >{{
                                'billing-memo.table.column.type' | translate
                              }}
                            </th>
                            <th scope="col" *ngIf="!isViewOnly"
                              >{{ 'app.table.column.action' | translate }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngIf="
                              formGroup.controls.otherCostList.controls
                                .length === 0
                            "
                          >
                            <td colspan="99" class="bg-white text-center">
                              {{ 'app.info.noData' | translate }}
                            </td>
                          </tr>
                          <tr
                            *ngFor="
                              let cost of formGroup.controls.otherCostList
                                .controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <td class="text-center"> {{ i + 1 }}. </td>
                            <td class="text-left">
                              <app-text-field
                                size="XL"
                                formControlName="itemName"
                                (onChange)="onChangeItemName($event, i)"
                              ></app-text-field>
                            </td>
                            <td class="text-center">
                              <div
                                class="input-group"
                                (click)="doChooseBudgetCost(i)"
                                *ngIf="!isViewOnly"
                              >
                                <input
                                  type="text"
                                  formControlName="coa"
                                  class="form-control cursor-pointer"
                                  aria-label="search"
                                  disabled="true"
                                />
                                <div class="input-group-append">
                                  <span class="input-group-text cursor-pointer"
                                    ><em class="fas fa-search"></em
                                  ></span>
                                </div>
                              </div>
                              <small
                                class="text-danger"
                                *ngIf="
                                  !formLoading &&
                                  cost.controls.budgetAllocation.value ===
                                    null &&
                                  (cost.controls.itemName.value ||
                                    cost.controls.type.value ||
                                    cost.controls.valueCost.value) &&
                                  !isValid
                                "
                              >
                                {{ 'app.validation.required' | translate }}
                              </small>
                              <div *ngIf="isViewOnly">
                                {{
                                  cost.controls.coa.value
                                    ? cost.controls.coa.value
                                    : '-'
                                }}
                              </div>
                            </td>
                            <td class="text-center white-space-normal">
                              {{
                                cost.controls.costCenter.value
                                  ? cost.controls.costCenter.value
                                  : '-'
                              }}
                            </td>
                            <td class="text-center">
                              <app-text-field
                                size="XL"
                                formControlName="valueCost"
                                type="currency"
                                (onChange)="onChangeValueCost($event, i)"
                              ></app-text-field>
                            </td>
                            <td class="text-center">
                              <app-combo-box
                                size="XL"
                                formControlName="type"
                                [optionList]="typeOptionList"
                                (onChange)="onChangeType($event, i)"
                                container="body"
                              ></app-combo-box>
                            </td>
                            <td class="text-center" *ngIf="!isViewOnly">
                              <app-button
                                size="TN"
                                color="DANGER"
                                (onClick)="doDelete(i)"
                                title="{{ 'app.tooltip.delete' | translate }}"
                                *ngIf="!cost.controls.isDefault.value"
                              >
                                <span class="fas fa-trash"></span>
                              </app-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <h5 class="separator">{{
              'billing-memo.form-group.paidToVendor' | translate
            }}</h5>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.vendorName' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{ vendor ? vendor.name : vendorOffline.name }}
              </div>
            </div>
            <div class="form-group row text-sm-right" *ngIf="vendor">
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.taxNumber' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{ vendor.taxNumber }}
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.bankName' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{
                  vendorBank ? vendorBank.bank.name : vendorBankOffline.bankName
                }}
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.accountNumber' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{
                  vendorBank
                    ? vendorBank.accountNumber
                    : vendorBankOffline.accountNumber
                }}
              </div>
            </div>
            <div class="form-group row text-sm-right">
              <label class="col-sm-3 control-label">{{
                'billing-memo.form.accountHolder' | translate
              }}</label>
              <div class="col-sm-9 text-left">
                {{
                  vendorBank
                    ? vendorBank.accountHolder
                    : vendorBankOffline.accountHolder
                }}
              </div>
            </div>

            <div class="form-group row text-sm-right">
              <div
                class="card border-0"
                [ngStyle]="{ margin: '0 1.5%', width: '60%' }"
              >
                <div class="card-body">
                  <div
                    class="table-responsive custom-table float-none w-100"
                    [ngStyle]="{ margin: '0 auto' }"
                  >
                    <table
                      class="table table-striped box-shadow"
                      aria-describedby="Table"
                    >
                      <thead>
                        <tr class="text-center">
                          <th scope="col"
                            >{{ 'app.table.column.no' | translate }}
                          </th>
                          <th class="text-left" scope="col"
                            >{{
                              'billing-memo.table.column.costName' | translate
                            }}
                          </th>
                          <th class="text-right" scope="col"
                            >{{ 'billing-memo.table.column.value' | translate }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center"> 1. </td>
                          <td class="text-left">
                            {{
                              'billing-memo.table.column.taxBaseTotal'
                                | translate
                            }}
                          </td>
                          <td class="text-right">
                            {{
                              totalTaxBase ? (totalTaxBase | currency: ' ') : 0
                            }}
                            {{ currency.code }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center"> 2. </td>
                          <td
                            class="text-left"
                            *ngIf="!billingMemoEditResponse.isWAPU"
                          >
                            {{
                              'billing-memo.table.column.vatTotal' | translate
                            }}
                          </td>
                          <td
                            class="text-left"
                            *ngIf="billingMemoEditResponse.isWAPU"
                          >
                            {{
                              'billing-memo.table.column.vatTotalWapu'
                                | translate
                            }}
                          </td>
                          <td class="text-right">
                            {{ totalVAT ? (totalVAT | currency: ' ') : 0 }}
                            {{ currency.code }}
                          </td>
                        </tr>
                        <ng-container formArrayName="otherCostList">
                          <tr
                            *ngFor="
                              let cost of formGroup.controls.otherCostList
                                .controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <td class="text-center">
                              {{ i + 3 }}
                            </td>
                            <td class="text-left">
                              {{ cost.controls.itemName.value }}
                            </td>
                            <td class="text-right">
                              {{
                                cost.controls.valueCost.value &&
                                cost.controls.type.value &&
                                cost.controls.type.value.value === 2
                                  ? '-'
                                  : ''
                              }}
                              {{
                                cost.controls.valueCost.value
                                  ? (+cost.controls.valueCost.value
                                    | currency: ' ')
                                  : 0
                              }}
                              {{ currency.code }}
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td class="text-left">
                            <b>
                              {{
                                'billing-memo.table.column.subTotal' | translate
                              }}
                            </b>
                            <br />
                            <br />
                            <b>
                              {{
                                'billing-memo.table.column.incomeTaxTotal'
                                  | translate
                              }}
                            </b>
                          </td>
                          <td class="text-right">
                            <b>
                              {{ subTotal ? (subTotal | currency: ' ') : 0 }}
                              {{ currency.code }}
                            </b>
                            <br />
                            <br />
                            <b>
                              {{ totalIncomeTax ? '-' : '' }}
                              {{
                                totalIncomeTax
                                  ? (totalIncomeTax | currency: ' ')
                                  : 0
                              }}
                              {{ currency.code }}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td class="text-left">
                            <b>
                              {{
                                'billing-memo.table.column.totalPaidToVendor'
                                  | translate
                              }}
                            </b>
                          </td>
                          <td class="text-right">
                            <b>
                              {{
                                totalPaidToVendor
                                  ? (totalPaidToVendor | currency: ' ')
                                  : 0
                              }}
                              {{ currency.code }}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td class="text-left">
                            <b>
                              {{ 'billing-memo.table.column.say' | translate }}
                            </b>
                          </td>
                          <td class="text-right">
                            <b>
                              {{ totalPaidToVendor | resolveNumberToWords }}
                              Rupiah
                            </b>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <label class="text-left" *ngIf="billingMemoEditResponse.isWAPU"
                  >*)
                  {{ 'billing-memo.footnote.isWAPU' | translate }}
                </label>
              </div>
            </div>
          </form>
        </app-accordion-item>
        <app-accordion-item
          header="billing-memo.accordion.paymentRequestInformation"
        >
          <app-payment-request-information-view
            *ngIf="!formLoading"
            [paymentInvoiceId]="paymentInvoiceId"
          ></app-payment-request-information-view>
        </app-accordion-item>
        <app-accordion-item header="billing-memo.accordion.deliverableItems">
          <app-deliverable-item [paymentId]="paymentId"> </app-deliverable-item>
        </app-accordion-item>
        <app-accordion-item
          header="billing-memo.accordion.procurementInformation"
        >
          <app-procurement-information-view
            [procurementVendorId]="procurementVendorId"
          >
          </app-procurement-information-view>
        </app-accordion-item>
      </app-accordion>
      <div class="floating-button mt-5">
        <div class="floating-button-wrapper">
          <div class="floating-button-content">
            <div class="button-group button-group-center">
              <app-button
                mode="BYPASS"
                color="SECONDARY"
                (onClick)="doBack()"
                [disabled]="formSaving"
                >{{ 'app.button.back' | translate }}
              </app-button>
              <app-button
                color="PRIMARY"
                (onClick)="doSaveDraft()"
                [disabled]="formSaving"
                *ngIf="!isViewOnly"
                >{{ 'app.button.saveDraft' | translate }}
              </app-button>
              <app-button
                color="PRIMARY"
                (onClick)="doSave()"
                [disabled]="formSaving"
                *ngIf="!isViewOnly"
                >{{ 'app.button.save' | translate }}</app-button
              >
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
