import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardResponse } from '../../../../pages/dashboard/dashboard-response';
import { AppPopupService } from '../app-popup.service';
import { AppPopupChangePasswordComponent } from './app-popup-change-password.component';
@Injectable({ providedIn: 'root' })
export class AppPopupChangePasswordService {
  constructor(public appPopupService: AppPopupService) {}
  public open(dashboardResponse: DashboardResponse): Observable<any> {
    return this.appPopupService.open(
      AppPopupChangePasswordComponent,
      { title: 'change-password.title', dashboardResponse: dashboardResponse },
      { windowClass: 'app-popup-change-password' }
    );
  }
}
