<app-popup
  header="contract-request.popup.cancelContract"
  [isLoading]="formLoading"
  [isShowHeaderCloseButton]="formGroup.isView"
>
  <app-fullfilled></app-fullfilled>
  <div class="main-row-popup-cancel-contract">
    <form
      class="form-horizontal"
      [formGroup]="formGroup"
      novalidate
      *ngIf="!formLoading"
    >
    <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
            'contract-request.popup.requestNumber' | translate
        }}</label>
        <div class="col-sm-4 text-left">
        {{
            contract
            ? contract.requestNumber
            : '-'
        }}
        </div>
    </div>
    <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
            'contract-request.popup.cancelDate' | translate
        }}</label>
        <div class="col-sm-4 text-left">
        {{
            formGroup.value.cancelDate
                | date: global.appConstant.core.FORMAT_SHORT_DATE
            }}
        </div>
    </div>
    <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
            'contract-request.popup.stage' | translate
        }}</label>
        <div class="col-sm-4 text-left">
        {{
            formGroup.value.stage
            ? formGroup.value.stage
            : '-'
        }}
        </div>
    </div>
    <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
            'contract-request.popup.cancelReason' | translate
        }}</label>
        <div class="col-sm-9 text-left">
            <app-text-area
            size="XL"
            formControlName="note"
            maxLength="2000"
            ></app-text-area>
        </div>
    </div>
    <div class="form-group row text-sm-left">
        <label class="col-sm-3">{{
            'contract-request.popup.attachment' | translate
        }}</label>
        <div class="col-sm-9 text-left">
            <app-upload-x
            formControlName="fileDoc"
            [fileUploader]="fileUploader"
            ></app-upload-x>
        </div>
    </div>
      <ng-template *ngIf="!formGroup.isView" #modalFooter let-activeModal>
        <div class="button-group button-group-center">
          <app-button
            color="SECONDARY"
            [outline]="true"
            (onClick)="activeModal.close(true)"
            >{{ 'app.button.cancel' | translate }}</app-button
          >
          <app-button color="PRIMARY" (onClick)="doSave()">{{
            'app.button.submit' | translate
          }}</app-button>
        </div>
      </ng-template>
    </form>
  </div>
</app-popup>
