import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from '../../../../../../core/base/angular/base-component.component';
@Component({
  selector: 'app-catalog-item',
  templateUrl: './app-catalog-item.component.html',
  styleUrls: ['./app-catalog-item.component.scss']
})
export class AppCatalogItemComponent extends BaseComponentComponent {
  @Input() model: any;
  @Output() onChooseItem: EventEmitter<number> = new EventEmitter();
  constructor() {
    super('app-catalog-item');
  }
  public onInit(): void {
    this.setStateReady();
  }

  public onLoadImage(event: any): void {
    this.log.info(event);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }

}
