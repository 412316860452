<form
  class="form-horizontal"
  [formGroup]="formGroup"
  novalidate
  *ngIf="!model.isLoading"
>
  <div class="form-group row">
    <div class="col-lg-12">
      <app-table [isServerSide]="true" stringUrl="/tab-blacklist-history/index"
        [model]="tableResponse">
        <ng-template #actionButtons let-rowData>
          <app-button size="TN" (onClick)="doDetail(rowData)"
            title="{{ 'app.tooltip.detail' | translate }}">
            <em class="fas fa-search"></em>
          </app-button>
        </ng-template>
      </app-table>
    </div>
  </div>
</form>


