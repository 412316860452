<div class="row main-row">
  <div class="col-lg-12 mb-4">
    <app-card
      header="employee.title"
      [isLoading]="formLoading"
      [isSaving]="formSaving"
    >
      <app-table
        [tableResponse]="tableResponse"
        [isServerSide]="true"
        stringUrl="/employee/index"
      >
        <ng-template #headerButtons>
          <app-button (onClick)="doOpen()" mode="BYPASS"
            ><em class="fas fa-print"></em>Snack Bar
          </app-button>
          <app-button mode="BYPASS"
            ><em class="fas fa-print"></em>Print
          </app-button>

          <app-button (onClick)="doAdd()"
            ><em class="fas fa-plus"></em> Add
          </app-button>
          <app-button (onClick)="doCart()"
            ><em class="fas fa-plus"></em> To Cart
          </app-button>
          <app-button
            (onClick)="
              doDownload(
                $event,
                '/employee/generate-file/RAW-6RNBFGFBQDAD8FDIFHLB0ARD3DKG_Test_Doc.docx',
                'Test_Doc.docx'
              )
            "
          >
            Generate File
          </app-button>
        </ng-template>
        <ng-template #actionButtons let-rowData>
          <app-button size="TN" (onClick)="doEdit(rowData)">
            <em class="fas fa-pencil-alt"></em>
          </app-button>
          <app-button size="TN" (onClick)="doView(rowData)">
            <em class="fas fa-search"></em>
          </app-button>
          <app-button size="TN" color="DANGER" (onClick)="doDelete(rowData)">
            <em class="fas fa-trash"></em>
          </app-button>
        </ng-template>
      </app-table>
    </app-card>
  </div>
</div>
