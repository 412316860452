import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppPopupModule } from '../../../../../core/components/app-popup/app-popup.module';
import { AppTableModule } from '../../../../../core/components/app-table/app-table.module';
import { AppWidgetContentSliderModule } from '../../../../../core/widgets/app-widget-content-slider/app-widget-content-slider.module';
import { WidgetCardModule } from '../../components/widget-card/widget-card.module';
import { WidgetService } from '../../widget.service';
import { AppPopupWidgetPaymentMonitoringComponent } from './app-popup-widget-payment-monitoring.component';
import { WidgetPaymentMonitoringComponent } from './widget-payment-monitoring.component';
@NgModule({
  imports: [VendorModule, AppWidgetContentSliderModule, AppTableModule, AppPopupModule, WidgetCardModule],
  declarations: [WidgetPaymentMonitoringComponent, AppPopupWidgetPaymentMonitoringComponent],
  entryComponents: [WidgetPaymentMonitoringComponent, AppPopupWidgetPaymentMonitoringComponent],
  exports: [WidgetPaymentMonitoringComponent]
})
export class WidgetPaymentMonitoringModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      WidgetPaymentMonitoringComponent.moduleCode,
      WidgetPaymentMonitoringComponent,
      'w-100'
    );
  }
}
