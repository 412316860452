import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { AppPopupProgressBarDownloadComponent } from '../../components/app-popup/app-popup-progress-bar-download/app-popup-progress-bar-download.component';
import { ProgressBarDownloadModel } from '../../components/app-popup/app-popup-progress-bar-download/progress-bar-download-model';
import { AppPopupService } from '../../components/app-popup/app-popup.service';
import { ToastService } from '../../components/app-toast/app-toast.service';
import { TableResponseModel } from '../../components/table/model/table-response-model';
import { RecapPrintService } from '../../services/recap-print.service';
import { ReportRecapRfqView } from '../../view/entity/bean/report-recap-rfq-view';
import { ReportRecapRfqPopupChooseColumnComponent } from './report-recap-rfq-popup-choose-column.component';
import { ReportRecapRfqRequest } from './report-recap-rfq-request';

@Component({
  templateUrl: './report-recap-rfq-popup-full-view.component.html'
})
export class ReportRecapRfqPopupFullViewComponent extends BaseComponentComponent {
  @Input() reportRecapRfqRequest: ReportRecapRfqRequest[];
  @Input() reportBuilderId: number;
  @Output() onChange: EventEmitter<any> = new EventEmitter();

  public tableResponse: TableResponseModel<ReportRecapRfqView>;
  public progressBarDownload = new ProgressBarDownloadModel();
  public httpRequest: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    public appPopupService: AppPopupService,
    public translateService: TranslateService,
    public recapPrintService: RecapPrintService,
    private toastService: ToastService
  ) {
    super('report-recap-rfq');
  }

  onInit(): void {
    this.buildTableResponse();
    this.tableResponse.setCustomData(this.reportRecapRfqRequest);
    this.setStateReady();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'rfq.code',
        header: 'table.column.number'
      },
      {
        field: 'rfq.title',
        header: 'table.column.title'
      },
      {
        field: 'organization.name',
        header: 'table.column.department'
      },
      {
        field: 'createdDate',
        header: 'table.column.createdDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'creator',
        header: 'table.column.creator'
      },
      {
        field: 'rfq.startDate',
        header: 'table.column.responseStartDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'rfq.endDate',
        header: 'table.column.responseEndDate',
        plugins: {
          name: 'date',
          format: 'short-date'
        }
      },
      {
        field: 'totalCost',
        header: 'table.column.totalCost',
        className: 'text-right',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      },
      {
        field: 'totalAvoidance',
        header: 'table.column.totalAvoidance',
        className: 'text-right',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      },
      {
        field: 'totalSaving',
        header: 'table.column.totalSaving',
        className: 'text-right',
        plugins: {
          name: 'default',
          type: 'currency'
        }
      },
      {
        field: 'vendorWinnerName',
        header: 'table.column.vendorWinnerName'
      },
      {
        field: 'rfq.note',
        header: 'table.column.note'
      }
    ]);
  }

  public doMinimize(): void {
    this.onChange.emit();
  }

  public doExport(): void {
    if (this.reportBuilderId) {
      this.doDirectExport();
    } else {
      this.appPopupService.open(ReportRecapRfqPopupChooseColumnComponent, {
        reportRecapRfqRequest: this.reportRecapRfqRequest
      });
    }
  }

  public doDirectExport(): void {
    this.appPopupService
      .open(AppPopupProgressBarDownloadComponent, {
        model: this.progressBarDownload
      })
      .subscribe(isCancel => {
        if (isCancel) {
          this.httpRequest.unsubscribe();
        }
      });

    this.recapPrintService
      .print('/report-recap-rfq/export-list', this.reportRecapRfqRequest)
      .subscribe(
        response => {
          if (response) {
            this.progressBarDownload.setProgress(100);
            setTimeout(() => {
              this.global.modalService.downloadSuccess();
              this.onChange.emit();
            }, 500);
          }
        },
        error => {
          error.error.text().then(text => this.toastService.showError(text));
        }
      );
  }
}
