import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { Validators } from '../../core/validators';
import { OptionListModel } from './../../core/model/option-list-model';
@Component({
  templateUrl: './app-popup-export-budget-allocation.component.html'
})
export class AppPopupExportBudgetComponent extends BaseComponentComponent {
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  public reportOptionList: OptionListModel<any> = new OptionListModel();

  constructor(public translateService: TranslateService) {
    super('budget-allocation');
  }

  onInit(): void {
    this.doBuildFormGroup();
    this.doSetParameterTypeOptionList();
    this.setStateReady();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      reportType: [null, Validators.required()]
    });
  }

  public doSetParameterTypeOptionList(): void {
    const reportList = [
      {
        view: this.translateService.instant(
          'budget-allocation.report.projection'
        ),
        value: 'projection'
      },
      {
        view: this.translateService.instant(
          'budget-allocation.report.realization'
        ),
        value: 'realization'
      }
    ];
    this.reportOptionList
      .setRequestValues(reportList)
      .setViewPath('view')
      .setValuePath('value');
  }

  public doGenerate(): void {
    this.validate();
    if (this.formGroup.valid) {
      const type = this.formGroup.get('reportType').value;
      this.onChange.emit(type);
    }
  }
}
