import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Global } from '../../../../core/global/global';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'theme-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('shadowOnScroll', [
      state(
        'default',
        style({
          'box-shadow': 'unset'
        })
      ),
      state(
        'shadow',
        style({
          'box-shadow': 'rgb(0 0 0 / 10%) 0px 0px 4px 0px'
        })
      ),
      transition('default<=>shadow', animate('250ms'))
    ])
  ]
})
export class HeaderComponent implements OnInit {
  public isShowCart: boolean;
  public isShowUserNotif: boolean;
  public isOnScroll: boolean;
  public currentTime: Date = new Date();
  public stringUrl: string;
  public proviousTime = new Date().getTime();
  constructor(
    public global: Global
  ) {}

  ngOnInit(): void {
    this.checkHeaderMenu();
    this.setHeaderTime();
  }

  public checkHeaderMenu(): void {
    const headerMenuList = this.global.userSession.headerMenuList;
    headerMenuList.forEach(headerMenu => {
      if (
        headerMenu.code ===
        this.global.appConstant.core.MENU_CODE_USER_NOTIFICATION
      ) {
        this.isShowUserNotif = true;
      }
      if (headerMenu.code === this.global.appConstant.core.MENU_CODE_CART) {
        this.isShowCart = true;
      }
    });
  }

  private setHeaderTime(): void {
    setInterval(() => {
      let nowInMilliseconds = 0;
      nowInMilliseconds = new Date().getTime() + this.global.deltaTime;
      this.proviousTime = new Date().getTime();
      this.currentTime = new Date(nowInMilliseconds);
    }, 500);
  }

  @HostListener('document:scroll')
  onDocumentScroll(): void {
    this.isOnScroll = document.documentElement.scrollTop !== 0;
  }
}
