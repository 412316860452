import { BondType } from '../../core/bean/bond-type';
import { Currency } from '../../core/bean/currency';
import { ItemType } from '../../core/bean/item-type';
import { Procurement } from '../../core/bean/procurement';
import { ProcurementCriteriaDetail } from '../../core/bean/procurement-criteria-detail';
import { ProcurementItem } from '../../core/bean/procurement-item';
import { ProcurementSchedule } from '../../core/bean/procurement-schedule';
import { Quotation } from '../../core/bean/quotation';
import { QuotationAdministration } from '../../core/bean/quotation-administration';
import { QuotationBidBond } from '../../core/bean/quotation-bid-bond';
import { QuotationCriteria } from '../../core/bean/quotation-criteria';
import { QuotationItem } from '../../core/bean/quotation-item';
import { WorkflowStepInfoModel } from '../../core/components/app-workflow-step-info/model/workflow-step-info-model';
import { FileObject } from '../../core/components/upload';

export class SubmitQuotationVendorResponse {
  vendorId: number;
  quotation: Quotation = new Quotation();
  companyCurrency: Currency = new Currency();
  procurement: Procurement = new Procurement();
  quotationBidBond: QuotationBidBond = new QuotationBidBond();
  quotationAdministration: QuotationAdministration;
  procurementSchedule: ProcurementSchedule = new ProcurementSchedule();
  workflowStepInfoModel: WorkflowStepInfoModel = new WorkflowStepInfoModel();
  admFileList: FileObject[] = [];
  tecFileList: FileObject[] = [];
  itemTypeList: ItemType[] = [];
  currencyList: Currency[] = [];
  quotationList: Quotation[] = [];
  bondTypeList: BondType[] = [];
  quotationItemList: QuotationItem[] = [];
  procurementItemList: ProcurementItem[] = [];
  admQuotationCriteriaList: QuotationCriteria[] = [];
  tecQuotationCriteriaList: QuotationCriteria[] = [];
  procurementCriteriaDetailList: ProcurementCriteriaDetail[] = [];
}
