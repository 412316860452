import { Component, Input } from '@angular/core';
import { BaseComponentComponent } from '../../core/base/angular/base-component.component';
import { VendorBlacklist } from '../../core/bean/vendor-blacklist';
import { VendorViolation } from '../../core/bean/vendor-violation';

@Component({
  templateUrl: './app-popup-tab-blacklist-history.component.html'
})
export class AppPopupTabBlacklistHistoryComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() vendorBlacklist: VendorBlacklist;
  public vendorViolationList: VendorViolation[] = [];

  constructor() {
    super('app-popup-tab-blacklist-history');
  }

  public onInit(): void {
    this.setVendorViolationList();
  }

  public setVendorViolationList(): void {
    this.httpClientService.get<VendorViolation[]>('/tab-blacklist-history/vendor-violation-list/' + this.vendorBlacklist.id)
      .subscribe(vendorViolationList => {
        this.vendorViolationList = vendorViolationList;
        this.setStateReady();
      });
  }
}
