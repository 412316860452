import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { User } from '../../core/bean/user';
import { AppPopupImportService } from '../../core/components/app-popup/app-popup-import/app-popup-import.service';
import { ImportFileWebsocketService } from '../../core/components/app-popup/app-popup-import/import-file-websocket.service';
import { AppPopupTreeOrganizationService } from '../../core/components/app-popup/app-popup-tree/app-popup-tree-organization.service';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { RecapPrintService } from './../../core/services/recap-print.service';
@Component({
  templateUrl: './user.component.html'
})
export class UserComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<User>;
  public isLoading: boolean;
  public isLoadingImport: boolean;
  public isImportRunning = false;
  constructor(
    translateService: TranslateService,
    public appPopupOrganizationService: AppPopupTreeOrganizationService,
    public recapPrintService: RecapPrintService,
    public appPopupImportService: AppPopupImportService,
    public importFileWebsocketService: ImportFileWebsocketService
  ) {
    super('user', translateService);
  }

  public onInit(): void {
    this.doBuildFormGroup();
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      organizationName: [null],
      organizationId: [null]
    });
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.name',
        customClass: 'white-space-normal'
      },
      { field: 'email', header: 'table.column.email' },
      {
        field: 'roleText',
        header: 'table.column.role',
        format: FieldFormatEnum.Html,
        customClass: 'text-center'
      },
      {
        field: 'isActive',
        header: 'table.column.status',
        datamap: '{"false" : "Not Active" , "true" : "Active" }'
      },
      {
        field: 'isLocked',
        header: 'table.column.locked',
        customClass: 'text-center',
        datamap: '{"false" : "False" , "true" : "True" }'
      }
    ]);
  }

  public doAdd(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'add');
    this.router.navigate(['/pages/user/add']);
  }

  public doEdit(user: User): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('userId', user.id);
    this.router.navigate(['/pages/user/edit']);
  }

  public doExport(): void {
    this.isLoading = true;
    this.recapPrintService
      .print('/user/recap-user', this.tableResponse.request)
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  public onChooseOrganization(event: any): void {
    event.srcElement.blur();
    event.preventDefault();
    this.appPopupOrganizationService
      .open(this.global.userSession.user.organization.id)
      .subscribe(organization => {
        const { name: organizationName, id: organizationId } =
          organization.pop();
        this.formGroup.patchValue({ organizationName, organizationId });
        this.tableResponse.setCustomData(this.formGroup.value);
        this.tableResponse.reload();
      });
  }

  public doImport(): void {
    this.appPopupImportService.open('/user/import-excel').subscribe(result => {
      if (result) {
        this.tableResponse.reload();
      }
    });
    this.importFileWebsocketService.appPopupImportComponent =
      this.appPopupImportService.appPopupService.componentInstance;
  }
}
