import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { VendorHistory } from '../../core/bean/vendor-history';
import { AccordionItemModel } from '../../core/components/app-accordion/model/accordion-item-model';

@Component({
  templateUrl: './tab-marketing.component.html'
})
export class TabMarketingComponent extends BaseModuleComponent {
  @Input() vendorHistoryId: number;
  @Input() model: AccordionItemModel<VendorHistory>;
  public vendorHistory: VendorHistory = new VendorHistory();
  constructor(translateService: TranslateService) {
    super('tab-marketing', translateService);
  }

  onInit(): void {
    this.doGetAndSetVendorHistory();
  }

  public doGetAndSetVendorHistory(): void {
    this.httpClientService.get<VendorHistory>('/tab-marketing/index/' + this.vendorHistoryId)
      .subscribe(vendorHistory => {
        this.vendorHistory = vendorHistory;
        this.model.setStateReady();
      });
  }

  public doViewHistory(): void {
    this.global.routerParams.set('selector', 'tab-marketing-history');
    this.global.routerParams.set('componentInstance', { id: 1, name: 'Terserah' });
    this.router.navigate(['/pages/vendor-history/detail/history']);
  }
}
