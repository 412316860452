import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../base/angular/base-module.component';
import { BudgetType } from '../../bean/budget-type';
import * as actualPlanBarChartAction from './actions/actual-plan-bar-chart.action';
import { ActualPlanBarChartInfoDTO } from './dto/actual-plan-bar-chart-info-dto';
import { ActualPlanBarChartWorkPlanInfoDTO } from './dto/actual-plan-bar-chart-work-plan-Info.dto';
import { ActualPlanBarChartModel } from './model/actual-plan-bar-chart-model';
import { ActualPlanBarChartStoreService } from './services/actual-plan-bar-chart-store.service';
import { ActualPlanBarChartType } from './types/actual-plan-bar-chart-type';
@Component({
  selector: 'app-actual-plan-bar-chart',
  templateUrl: './app-actual-plan-bar-chart.component.html',
  styleUrls: ['./app-actual-plan-bar-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ActualPlanBarChartStoreService]
})
export class AppActualPlanBarChartComponent extends BaseModuleComponent {
  @Input() public model: ActualPlanBarChartModel;

  @ViewChild('checkBoxElement') private inputCheckBoxElement: ElementRef;

  public budgetTypeList: Array<BudgetType>;
  public activeBudgetType: BudgetType;

  constructor(
    translateService: TranslateService,
    public actualPlanBarChartStoreService: ActualPlanBarChartStoreService
  ) {
    super('app-actual-plan-bar-chart', translateService);
  }

  onInit(): void {
    this.actualPlanBarChartStoreService.setState(this.model);
    this.dispatchState();
    this.handleModelChanges();
    this.translateService.onLangChange.subscribe(() => this.dispatchState());
  }

  private handleModelChanges(): void {
    if (this.model) {
      this.model.reloadChanges.subscribe(() => this.dispatchState());
      this.model.typeChanges.subscribe(() => this.dispatchState());
      this.model.requestBodyChanges.subscribe(() => this.dispatchState());
    }
  }

  private dispatchState(): void {
    this.httpClientService
      .post<ActualPlanBarChartInfoDTO | ActualPlanBarChartWorkPlanInfoDTO>(
        this.model.stringUrl,
        this.model.requestBody
      )
      .subscribe(response => {
        if (!this.budgetTypeList) {
          this.budgetTypeList = response.budgetTypeList;
          this.activeBudgetType = [...this.budgetTypeList].filter(
            budgetType => !budgetType.id
          )[0];
        }
        if (this.model.type === 'budget-info') {
          this.actualPlanBarChartStoreService.dispatch(
            new actualPlanBarChartAction.SetBudgetInfo(response)
          );
        } else if (this.model.type === 'work-plan-info') {
          this.actualPlanBarChartStoreService.dispatch(
            new actualPlanBarChartAction.SetWorkPlanInfo(response)
          );
        } else {
          this.log.debug(`There's no type`);
        }
        this.loadingBlockService.close();
      });
  }

  public handleClick(
    action: 'view' | 'type',
    value: ActualPlanBarChartType | BudgetType
  ): void {
    this.loadingBlockService.showInfo(`app.msg.processing`);
    this.inputCheckBoxElement.nativeElement.checked = false;
    if (action === 'view') {
      this.model.changeType(value as ActualPlanBarChartType);
    } else if (action === 'type') {
      this.activeBudgetType = value as BudgetType;
      this.model.requestBody = Object.assign(this.model.requestBody, {
        budgetTypeCode: this.activeBudgetType.code
      });
      this.model.reload();
    }
    this.loadingBlockService.close();
  }

  public onClickBudgetFilterWrapper(event: PointerEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public doShowFilterWrapper(event: PointerEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.inputCheckBoxElement.nativeElement.checked =
      !this.inputCheckBoxElement.nativeElement.checked;
  }

  @HostListener('document:click')
  onDocumentClick(): void {
    if (this.inputCheckBoxElement.nativeElement.checked) {
      this.inputCheckBoxElement.nativeElement.checked = false;
    }
  }
}
