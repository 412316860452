import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { WorkPlanModule } from './../work-plan/work-plan.module';
import { WorkPlanContractComponent } from './work-plan-contract/work-plan-contract.component';
import { WorkPlanDeliveryReceivedComponent } from './work-plan-delivery-received/work-plan-delivery-received-edit-add.component';
import { WorkPlanPaymentDetailPopupComponent } from './work-plan-payment/work-plan-payment-detail-popup.component';
import { WorkPlanPaymentComponent } from './work-plan-payment/work-plan-payment-edit-add.component';
import { WorkPlanPurchaseOrderComponent } from './work-plan-purchase-order/work-plan-purchase-order.component';
import { WorkPlanPurchaseRequestComponent } from './work-plan-purchase-request/work-plan-purchase-request-edit-add.component';
import { WorkPlanRealizeProcurementComponent } from './work-plan-realize-procurement/work-plan-realize-procurement.component';
import { WorkPlanRealizeComponent } from './work-plan-realize.component.';
import { WorkPlanViewComponent } from './work-plan-view/work-plan-view.component';
const routes: Routes = [
  {
    path: '',
    component: WorkPlanRealizeComponent,
    children: [
      { path: '', redirectTo: 'work-plan', pathMatch: 'full' },
      {
        path: 'work-plan',
        component: WorkPlanViewComponent,
        data: { breadcrumb: 'Work Plan' }
      },
      {
        path: 'purchase-request',
        component: WorkPlanPurchaseRequestComponent,
        data: { breadcrumb: 'Purchase Request' }
      },
      {
        path: 'procurement',
        component: WorkPlanRealizeProcurementComponent,
        data: { breadcrumb: 'Procurement' }
      },
      {
        path: 'payment',
        component: WorkPlanPaymentComponent,
        data: { breadcrumb: 'Payment' }
      },
      {
        path: 'contract',
        component: WorkPlanContractComponent,
        data: { breadcrumb: 'Contract' }
      },
      {
        path: 'delivery-received',
        component: WorkPlanDeliveryReceivedComponent,
        data: { breadcrumb: 'Delivery Received' }
      },
      {
        path: 'purchase-order',
        component: WorkPlanPurchaseOrderComponent,
        data: { breadcrumb: 'Purchase Order' }
      }
    ]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes), WorkPlanModule],
  declarations: [
    WorkPlanRealizeComponent,
    WorkPlanPurchaseRequestComponent,
    WorkPlanRealizeProcurementComponent,
    WorkPlanPaymentComponent,
    WorkPlanContractComponent,
    WorkPlanDeliveryReceivedComponent,
    WorkPlanPurchaseOrderComponent,
    WorkPlanViewComponent,
    WorkPlanPaymentDetailPopupComponent
  ],
  entryComponents: [WorkPlanPaymentDetailPopupComponent],
  exports: [WorkPlanPaymentDetailPopupComponent]
})
export class WorkPlanRealizeModule {}
