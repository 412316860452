import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Contract } from 'src/app/core/bean/contract';
import { Response } from 'src/app/core/model/response-model';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { Validators } from 'src/app/core/validators';
import { ContractMonitoringRequest } from './contract-monitoring.request';

@Component({
  templateUrl:
    './app-popup-contract-monitoring-send-email-guarantee.component.html'
})
export class AppPopupContractMonitoringSendEmailGuaranteeComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() contract: Contract;
  @Output() public onChange: EventEmitter<any> = new EventEmitter();
  public contractMonitoringRequest: ContractMonitoringRequest =
    new ContractMonitoringRequest();

  constructor(public translateService: TranslateService) {
    super('contract-monitoring');
  }

  onInit(): void {
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      vendorEmail: [
        null,
        Validators.compose([Validators.email(), Validators.required()])
      ],
      picEmailList: this.formBuilder.array([])
    });
  }

  public setFormGroup(): void {
    if (this.contract != null) {
      this.formGroup.patchValue({
        vendorEmail: this.contract.procurementVendor.vendor.email
      });
    }

    this.httpClientService
      .get<String[]>('/contract-monitoring/get-pic-email-list/' + this.contract.procurementVendor.vendor.id)
      .subscribe(picEmailList => {
        if (picEmailList.length > 0) {
          picEmailList.forEach(picEmail => {
            this.picEmailList.push(
              this.formBuilder.group({
                picEmail
              })
            );
          });
        } else {
          this.picEmailList.push(
            this.formBuilder.group({
              picEmail: [
                null,
                {
                  validators: [
                    Validators.compose([
                      Validators.email(),
                      Validators.exists(
                        this.picEmailList.value.map(x => x.picEmail)
                      )
                    ])
                  ]
                }
              ]
            })
          );
        }
        this.setStateReady();
      });
  }

  public get picEmailList(): FormArray {
    return this.formGroup.get('picEmailList') as FormArray;
  }

  public addPicEmail(): void {
    this.picEmailList.push(
      this.formBuilder.group({
        picEmail: [
          null,
          {
            validators: [
              Validators.compose([
                Validators.email(),
                Validators.exists(this.picEmailList.value.map(x => x.picEmail))
              ])
            ]
          }
        ]
      })
    );
  }

  public doRemovePIC(index: number): void {
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.picEmailList.removeAt(index);
        }
      });
  }

  public doSendEmail(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.onChange.emit(this.formGroup.value);
            const emailList = [];
            emailList.push(this.formGroup.value.vendorEmail);
            if (this.picEmailList.length > 0) {
              this.picEmailList.value.forEach(pic => {
                if (pic.picEmail) {
                  emailList.push(pic.picEmail);
                }
              });
            }
            this.contractMonitoringRequest.contract = this.contract;
            this.contractMonitoringRequest.emailList = emailList;
            this.httpClientService
              .post<Response<Contract>>(
                '/contract-monitoring/send-email-guarantee-expired',
                this.contractMonitoringRequest
              )
              .subscribe((response: Response<Contract>) => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccess(
                    'contract-monitoring.msg.success.send-email-guarantee'
                  );
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }
}
