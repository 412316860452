<div class="promise-container promise-container-lg">
  <app-card header="CORE VALUE">
    <div>
      <ul>
        <li>
          <h4 class="caps">INTEGRITY</h4>
          <p
            >Work with sincerity and be trusted at any time and any place.
            Integration of words and actions.</p
          >
        </li>
        <li>
          <h4 class="caps">PROFESSIONALISM</h4>
          <p>
            Work with plan, execute with discipline, responsible and obey work
            ethics to achieve planned target. Teamwork with every departments of
            the company to achieve better results. Keep improving skills and
            competencies through continuous learning.
          </p>
        </li>
        <li>
          <h4 class="caps">BEST SERVICES</h4>
          <p
            >Work with heart to get the job done and and delivers as promised or
            planned to satisfy others.</p
          >
        </li>
      </ul>
    </div>
  </app-card>
</div>
