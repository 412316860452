import { BaseWorkflowObjectEntity } from '../../base/base-workflow-object-entity.model';
import { DeliveryItem } from '../../bean/delivery-item';
import { PaymentInvoice } from '../../bean/payment-invoice';
import { PaymentStatus } from '../../bean/payment-status';
import { ProcurementVendor } from '../../bean/procurement-vendor';
import { VendorBankOffline } from '../../bean/vendor-bank-offline';
import { VendorOffline } from '../../bean/vendor-offline';

export class InternalPaymentRequestViewEntity extends BaseWorkflowObjectEntity {
  paymentStatus: PaymentStatus = new PaymentStatus();
  procurementVendor: ProcurementVendor = new ProcurementVendor();
  vendorOffline: VendorOffline = new VendorOffline();
  vendorBankOffline: VendorBankOffline;
  paymentInvoice: PaymentInvoice = new PaymentInvoice();
  deliveryItem: DeliveryItem;
  contractId: number;
  procurementId: number;
  invoiceNumber: string;
  requestDate: Date = new Date();
  memoNumber: string;
  agreementNumber: string;
  procurementNumber: string;
  procurementName: string;
  vendorName: string;
  stageName: string;
  paymentTermName: string;
  billingMethod: string;
  billingAmount: number;
}
