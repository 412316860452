<dashboard-widget-card
  [header]="moduleCode + '.title'"
  [isLoading]="model.isLoading"
>
  <div class="app-widget-pr-status" *ngIf="!model.isLoading">
    <h3 class="separator">
      <div class="row">
        <div class="cml-2 d-flex align-items-center">
          <div
            class="pvc pv-shopping-cart"
            style="width: 65px; height: 65px; margin: 0 auto"
          ></div>
        </div>
        <div class="cml-2 d-flex align-items-center">
          <h1>{{ totalPr }}</h1>
        </div>
        <div class="cml-2 d-flex align-items-center">
          Total Purchase Request
        </div>
      </div>
    </h3>
    <app-widget-content-slider>
      <div
        class="widget-card"
        *ngFor="let data of prDataList"
        (click)="goToMoreDetail(data)"
        (keyup)="onKeyUp($event)"
      >
        <h1 class="m-0">{{ data.value }}</h1>
        <br />
        <app-badge class="cml-2" [color]="data.badge">
          {{ data.translationKey | translate }}
        </app-badge>
      </div>
    </app-widget-content-slider>
  </div>
</dashboard-widget-card>
