import { BaseEntity } from '../base/base-entity';
import { RfpVendor } from '../bean/rfp-vendor';
import { Rfq } from '../bean/rfq';
import { RfqStatus } from '../bean/rfq-status';
import { RfqVendorScoring } from '../bean/rfq-vendor-scoring';
import { ScoringStatus } from '../bean/scoring-status';
import { SubmittedStatus } from '../bean/submitted-status';
import { Vendor } from '../bean/vendor';

export class RfqVendorEntity extends BaseEntity {
  crudOperation: number;
  rfq: Rfq = new Rfq();
  responseRfqStatus: RfqStatus = new RfqStatus();
  rfpVendor: RfpVendor = new RfpVendor();
  vendor: Vendor = new Vendor();
  submittedStatus: SubmittedStatus = new SubmittedStatus();
  scoringStatus: ScoringStatus = new ScoringStatus();
  isWinner: boolean;
  submittedDate: Date = new Date();
  lastReminderDate: Date = new Date();
  totalWeightScore: number;
  totalPriceScore: number;
  totalProposalScore: number;
  totalScore: number;
  amount: number;
  goodsAmount: number;
  serviceAmount: number;
  rfqVendorScoringList: RfqVendorScoring[] = [];
}
