import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Verificator } from '../../core/bean/verificator';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';

@Component({
  templateUrl: './executor.component.html'
})
export class ExecutorComponent extends BaseModuleComponent implements OnInit {
  public tableResponse: TableResponseModel<Verificator>;
  constructor(translateService: TranslateService) {
    super('executor', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code'
      },
      {
        field: 'name',
        header: 'table.column.name'
      }
    ]);
  }

  public doEdit(verificator: Verificator): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('verificator', verificator);
    this.global.routerParams.set('urlBackOutside', '/pages/executor');
    this.router.navigate(['/pages/executor/edit']);
  }
}
