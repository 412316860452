import { EventEmitter } from '@angular/core';
export class CaptchaModel {
  public sessionId: string;
  public requestNewSessionChanges: EventEmitter<boolean>;
  public isProcessing: boolean;
  constructor() {
    this.requestNewSessionChanges = new EventEmitter();
  }

  public setSessionId(sessionId: string): void {
    this.sessionId = sessionId;
  }

  public getSessionId(): string {
    return this.sessionId;
  }

  public requestNewSession(): void {
    this.requestNewSessionChanges.emit(true);
  }
}
