import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { DocGroupEditAddComponent } from './doc-group-edit-add.component';
import { DocGroupComponent } from './doc-group.component';
export const routes = [
  {
    path: '',
    component: DocGroupComponent,
    data: { breadcrumb: 'Vendor Management' }
  },
  {
    path: 'edit',
    component: DocGroupEditAddComponent,
    data: { todo: 'edit', breadcrumb: 'document-group.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  },
  {
    path: 'add',
    component: DocGroupEditAddComponent,
    data: { todo: 'add', breadcrumb: 'document-group.breadcrumb.add' },
    canDeactivate: [ConfirmGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [DocGroupComponent, DocGroupEditAddComponent],
  providers: []
})
export class DocGroupModule {}
