import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { PurchaseOrderPayment } from '../../../core/bean/purchase-order-payment';
import { FileUploader } from '../../../core/components/upload';
import { WorkPlanPaymentDTO } from '../dto/work-plan-payment.dto';
@Component({
  templateUrl: './work-plan-payment-detail-popup.component.html'
})
export class WorkPlanPaymentDetailPopupComponent extends BaseModuleComponent {
  @Input() public workPlanPaymentDTO: WorkPlanPaymentDTO;
  @Input() public purchaseOrderPayment: PurchaseOrderPayment;
  @Input() public paymentIndex: number;
  @Output() public onChange: EventEmitter<WorkPlanPaymentDTO> =
    new EventEmitter();
  public fileUploader: FileUploader = new FileUploader(
    '/work-plan-payment/',
    'news.form.headerImage',
    this.global.appConstant.fileType.IMG_NEWS
  );
  public paymentRemaining = 0;
  public purchaseOrderPaymentList: PurchaseOrderPayment[] = new Array();
  public paymentValid = true;
  public qtyRemainingList = new Array();
  public isQuantityValidList = new Array();
  public isAmountValidList = new Array();
  public isPaymentEmptyList = new Array();
  public paymentAmountList = new Array();
  public checkBoxList = new Array();
  public payment = 0;
  public name = '';
  public qty = 0;
  public isEdit = false;
  public totalPayment = 0;
  public submitted = false;

  constructor(translateService: TranslateService) {
    super('app-popup-work-plan-payment-detail', translateService);
  }

  onInit(): void {
    this.buildPurchaseOrderPayment();
    const purchaseOrderPaymentDetailList = this.formGroup.controls
      .purchaseOrderPaymentDetailList as FormArray;
    if (
      this.purchaseOrderPayment.purchaseOrderPaymentDetailList !== null &&
      this.purchaseOrderPayment.purchaseOrderPaymentDetailList.length > 0
    ) {
      this.isEdit = true;
      this.workPlanPaymentDTO.purchaseOrder.purchaseOrderItemList.forEach(
        (purchaseOrderItem, index) => {
          this.purchaseOrderPayment.purchaseOrderPaymentDetailList.forEach(
            (purchaseOrderPaymentDetail, i) => {
              if (
                purchaseOrderItem.id ===
                purchaseOrderPaymentDetail.purchaseOrderItem.id
              ) {
                purchaseOrderPaymentDetailList
                  .get(index.toString())
                  .patchValue({
                    paymentAmount: [
                      this.global.converterService.convertMoney(
                        purchaseOrderPaymentDetail.paymentAmount.toString()
                      )
                    ]
                  });
                this.paymentAmountList[index] =
                  +purchaseOrderPaymentDetail.paymentAmount;
                if (purchaseOrderPaymentDetail.paymentAmount > 0) {
                  this.checkBoxList[i] = true;
                }
              }
            }
          );
        }
      );
    }
    this.setStateReady();
  }

  doCancel(): void {
    this.onChange.emit();
  }

  public buildPurchaseOrderPayment(): void {
    this.purchaseOrderPayment === null
      ? (this.purchaseOrderPayment = new PurchaseOrderPayment())
      : this.fileUploader.setFile(this.purchaseOrderPayment.file);

    this.formGroup = this.formBuilder.group({
      id: [this.purchaseOrderPayment.id],
      paymentAmount: [this.purchaseOrderPayment.paymentAmount],
      terminName: [
        this.purchaseOrderPayment.terminName,
        Validators.compose([Validators.required])
      ],
      purchaseOrder: [this.purchaseOrderPayment.purchaseOrder],
      paymentDate: [
        new Date(this.purchaseOrderPayment.paymentDate),
        Validators.compose([Validators.required])
      ],
      file: [this.purchaseOrderPayment.file],
      purchaseOrderPaymentDetailList: this.formBuilder.array([])
    });
    this.buildPurchaseOrderPaymentDetail();
  }

  doSubmit(): void {
    this.submitted = true;
    if (
      this.formGroup.valid &&
      this.fileUploader.isAllUploaded &&
      this.totalPayment > 0
    ) {
      if (this.purchaseOrderPaymentList.length > 0) {
        this.formGroup.controls.purchaseOrderPaymentDetailList.value.forEach(
          (purchaseOrderPaymentDetail, index) => {
            // const paymentAmount = this.workPlanPaymentDTO.purchaseOrder.purchaseOrderItemList[index].paymentAmount;
            if (this.checkBoxList[index]) {
              const amount =
                parseFloat(purchaseOrderPaymentDetail.paymentAmount) -
                this.paymentAmountList[index];
              this.workPlanPaymentDTO.purchaseOrder.purchaseOrderItemList[
                index
              ].paymentAmount += amount;
              this.payment =
                this.payment +
                parseFloat(purchaseOrderPaymentDetail.paymentAmount);
            }
          }
        );

        this.formGroup.patchValue({
          paymentAmount: this.payment
        });
      } else {
        let fileNew;
        for (let i = 0; this.fileUploader.fileObjectList.length > i; i++) {
          if (!this.fileUploader.fileObjectList[i].isDeleted) {
            fileNew = this.fileUploader.fileObjectList[i].file;
            break;
          }
        }

        this.formGroup.controls.purchaseOrderPaymentDetailList.value.forEach(
          (purchaseOrderPaymentDetail, index) => {
            // const paymentAmount = this.workPlanPaymentDTO.purchaseOrder.purchaseOrderItemList[index].paymentAmount;
            if (this.checkBoxList[index]) {
              let paymentConvert = purchaseOrderPaymentDetail.paymentAmount;
              if (
                purchaseOrderPaymentDetail.paymentAmount.indexOf('.') !== -1
              ) {
                paymentConvert = this.global.converterService.uConvertMoney(
                  purchaseOrderPaymentDetail.paymentAmount
                );
              }

              const amount =
                parseFloat(paymentConvert) - this.paymentAmountList[index];
              /*  paymentAmount += parseFloat(purchaseOrderPaymentDetail.paymentAmount); */
              this.workPlanPaymentDTO.purchaseOrder.purchaseOrderItemList[
                index
              ].paymentAmount += amount;
              this.payment = this.payment + parseFloat(paymentConvert);
            }
          }
        );
        this.formGroup.patchValue({
          paymentAmount: this.payment,
          file: fileNew
        });
      }

      if (!this.isEdit) {
        this.workPlanPaymentDTO.purchaseOrderPaymentList.push(
          this.formGroup.value
        );
      } else {
        this.workPlanPaymentDTO.purchaseOrderPaymentList[this.paymentIndex] =
          this.formGroup.value;
      }

      this.onChange.emit(this.workPlanPaymentDTO);
    }
  }

  public buildPurchaseOrderPaymentDetail(): void {
    const purchaseOrderPaymentDetailList = this.formGroup.controls
      .purchaseOrderPaymentDetailList as FormArray;
    this.workPlanPaymentDTO.purchaseOrder.purchaseOrderItemList.forEach(
      poItem => {
        purchaseOrderPaymentDetailList.push(
          this.formBuilder.group({
            id: [null],
            createdPayment: [null],
            paymentAmount: new FormControl(
              { value: 0, disabled: true },
              Validators.required
            ),
            purchaseOrderItem: [poItem],
            purchaseOrderPayment: [this.purchaseOrderPayment]
          })
        );

        this.qtyRemainingList.push(0);
        this.isQuantityValidList.push(true);
        this.isAmountValidList.push(true);
        this.isPaymentEmptyList.push(false);
        this.paymentAmountList.push(0);
        this.checkBoxList.push(false);
      }
    );
  }

  public onQtyChange(formGroup: FormGroup, index: number): void {
    const purchaseOrderPaymentDetail = formGroup.value;
    if (purchaseOrderPaymentDetail.quantity > this.qtyRemainingList[index]) {
      this.isQuantityValidList[index] = false;
    } else {
      this.isQuantityValidList[index] = true;
    }
  }

  public onAmountChange(
    formGroup: FormGroup,
    purchaseOrderPaymentDetailList: FormArray,
    index: number
  ): void {
    const purchaseOrderPaymentDetail = formGroup.value;
    const paymentRemaining =
      purchaseOrderPaymentDetail.purchaseOrderItem.purchaseRequestItem
        .totalPriceRealize -
      purchaseOrderPaymentDetail.purchaseOrderItem.paymentAmount +
      this.paymentAmountList[index];
    if (purchaseOrderPaymentDetail.paymentAmount > paymentRemaining) {
      this.isAmountValidList[index] = false;
    } else {
      this.isAmountValidList[index] = true;
    }

    if (purchaseOrderPaymentDetail.paymentAmount <= 0) {
      this.isPaymentEmptyList[index] = true;
    } else {
      this.isPaymentEmptyList[index] = false;
    }

    // const totalPayment = +purchaseOrderPaymentDetail.paymentAmount;

    let total = 0;
    for (const POPaymentDetail of purchaseOrderPaymentDetailList.value) {
      total = +total + +POPaymentDetail.value.paymentAmount;
    }
    this.totalPayment = total;
  }

  public doCheckBox(
    value: boolean,
    formGroup?: FormGroup,
    index?: number
  ): void {
    if (value) {
      formGroup.get('paymentAmount').enable();
      this.checkBoxList[index] = true;
    } else {
      formGroup.get('paymentAmount').disable();

      formGroup.patchValue({
        paymentAmount: 0
      });
      this.checkBoxList[index] = false;
    }
  }

  public onChangeUnitPriceRealize(
    purchaseRequestItem: FormGroup,
    purchaseRequestItemList: FormArray,
    purchaseRequestObj: FormGroup
  ): void {
    const purchaseRequestItemForm = purchaseRequestItem.value;

    const unitPriceRealize = purchaseRequestItemForm.unitPriceRealize;
    const totalPriceRealize = +unitPriceRealize;
    purchaseRequestItem.patchValue({
      totalPriceRealize,
      convertTotalPriceRealize: this.global.converterService.convertMoney(
        totalPriceRealize.toString()
      )
    });

    let totalAnggaran = 0;
    let totalRealisasi = 0;
    for (const itemPurchaseRequest of purchaseRequestItemList.value) {
      totalAnggaran = totalAnggaran + +itemPurchaseRequest.totalPrice;
      totalRealisasi = totalRealisasi + +itemPurchaseRequest.totalPriceRealize;
    }

    purchaseRequestObj.patchValue({
      totalAnggaran,
      convertTotalAnggaran: this.global.converterService.convertMoney(
        totalAnggaran.toString()
      ),
      totalRealisasi,
      convertTotalRealisasi: this.global.converterService.convertMoney(
        totalRealisasi.toString()
      )
    });
  }

  public get formArrayPurchaseOrderPaymentDetailList(): FormArray {
    return this.formGroup.get('purchaseOrderPaymentDetailList') as FormArray;
  }
}
