import { Component, ViewEncapsulation } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { WidgetService } from '../widgets.service';
import { WidgetRfpStatusResponse } from './app-widget-rfp-status.response';
@Component({
  templateUrl: './app-widget-rfp-status.component.html',
  styleUrls: ['./app-widget-rfp-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWidgetRfpStatusComponent extends BaseComponentComponent {
  public totalRfp: number;
  public rfpDataList: Array<any>;
  public rfpDataBadge = {
    totalDraft: 'SECONDARY',
    totalWaitingApproval: 'WARNING',
    totalWaitingResponse: 'GOOD',
    totalRevision: 'FEEDBACK',
    totalCanceled: 'DANGER',
    totalClosedResponse: 'DANGER',
    totalWaitingRfq: 'PRIMARY',
    totalRfqProcess: 'PRIMARY',
    totalDone: 'SUCCESS',
    totalCompleted: 'SUCCESS'
  };

  public routerParamFilter = {
    totalDraft: 'DRAFT',
    totalWaitingApproval: 'WAITING_APPROVAL',
    totalWaitingResponse: 'WAITING_RESPONSE',
    totalRevision: 'REVISION',
    totalCanceled: 'CANCELED',
    totalClosedResponse: 'CLOSED_RESPONSE',
    totalWaitingRfq: 'WAITING_RFQ',
    totalRfqProcess: 'RFQ_PROCESS',
    totalDone: 'DONE',
    totalCompleted: 'COMPLETED'
  };
  constructor(public widgetService: WidgetService) {
    super('app-widget-rfp-status');
  }

  public onInit(): void {
    this.getAndSetPrStatusInformation();
  }

  public getAndSetPrStatusInformation(): void {
    this.httpClientService
      .get<WidgetRfpStatusResponse>('/widget-rfp-status/index')
      .subscribe(response => {
        if (response.totalRfp) {
          this.totalRfp = response.totalRfp;
        }
        this.rfpDataList = Object.keys(response).map((key: string) => {
          const strKey = key.slice(5);
          return {
            value: response[key],
            badge: this.rfpDataBadge[key],
            filter: this.routerParamFilter[key],
            translationKey:
              this.moduleCode +
              '.' +
              strKey.charAt(0).toLowerCase() +
              strKey.slice(1)
          };
        });
        this.rfpDataList.splice(0, 1);
        this.setStateReady();
      });
  }

  public goToMoreDetail(data): void {
    this.global.routerParams.set('filter', data.filter);
    this.router.navigate(['/pages/rfp']);
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
