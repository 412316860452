import { BaseEntity } from '../base/base-entity';
import { Pr } from '../bean/pr';
import { User } from '../bean/user';

export class PrCancellationEntity extends BaseEntity {
  pr: Pr = new Pr();
  cancelByUser: User = new User();
  cancelDate: Date = new Date();
  note: string;
}
