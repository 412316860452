import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { FieldFormatEnum } from '../../core/components/app-table/model/field-format.enum';
import { TableResponseModel } from '../../core/components/app-table/model/table-response-model';
import { Scheduler } from './../../core/bean/scheduler';
@Component({
  templateUrl: './scheduler.component.html'
})
export class SchedulerComponent extends BaseModuleComponent {
  public tableResponse: TableResponseModel<Scheduler>;
  constructor(translateService: TranslateService) {
    super('scheduler', translateService);
  }

  public onInit(): void {
    this.doBuildTableResponse();
    this.setStateReady();
  }

  public doBuildTableResponse(): void {
    this.tableResponse = new TableResponseModel(this.moduleCode, [
      {
        field: 'code',
        header: 'table.column.code',
        customClass: 'text-center'
      },
      { field: 'name', header: 'table.column.name' },
      { field: 'cron', header: 'table.column.cron' },
      {
        field: 'lastActive',
        header: 'table.column.lastActive',
        format: FieldFormatEnum.ShortDateAndTime
      },
      {
        field: 'isActive',
        header: 'table.column.status',
        datamap:
          '{"false" : "' +
          this.translateService.instant('scheduler.nonactive') +
          '" , "true" : "' +
          this.translateService.instant('scheduler.active') +
          '" }',
        customClass: 'text-center'
      }
    ]);
  }

  public doEdit(scheduler: Scheduler): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('schedulerId', scheduler.id);
    this.router.navigate(['/pages/scheduler/edit']);
  }
}
