<ul class="app-tagging" [ngClass]="ISVIEW ? 'read' : 'write'">
  <ng-container
    *ngIf="
      formControl.value === null && ISVIEW;
      then isViewAndNullValueTmpl;
      else elseTmpl
    "
  >
  </ng-container>
  <ng-template #isViewAndNullValueTmpl>-</ng-template>
  <ng-template #elseTmpl>
    <ng-container
      *ngIf="formControl && formControl.value && formControl.value.length > 0"
    >
        <ng-container *ngFor="let val of formControl.value; let i = index">
          <!-- [ngStyle]="{ padding: disabled ? '0.1rem 0.2rem' : '' }" -->
          <li *ngIf="(showLimitValue && i < showLimitValue) || !showLimitValue"
          [ngClass]="disabled || ISVIEW ? 'bg-secondary' : ''"
          >
            <h6 maxLine="1"
                [title]="optionView ? (val | resolveFieldData: optionView) : val.name || val"
            >{{
                optionView ? (val | resolveFieldData: optionView) : val.name || val
            }}</h6> 
            <em
                class="pir pi-times-circle"
                (click)="doDeleteItem($event, i)"
                *ngIf="!disabled"
                (keyup)="onKeyUp($event)"
                (keydown)="onKeyDown($event)"
            ></em>
          </li>
        </ng-container>

        <h6 class="exceed-number"
          *ngIf="showLimitValue && formControl.value.length > showLimitValue"> +{{ formControl.value.length - showLimitValue }}
        </h6>
      
    </ng-container>
    
    <ng-content></ng-content>
  </ng-template>
</ul>
