import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Verificator } from '../../core/bean/verificator';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';

@Component({
  templateUrl: 'verificator-edit-add.component.html'
})
export class VerificatorEditAddComponent extends BaseModuleComponent {
  public verificatorId: number;
  constructor(translateService: TranslateService) {
    super('verificator', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.verificatorId = this.global.routerParams.get('verificatorId');
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(2)])
      ],
      isActive: [true],
      name: [null, Validators.compose([Validators.required()])],
      description: [null, Validators.maxLength(512)]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<Verificator>(
        '/verificator/add-edit',
        new RouteRequestModel(this.todo, this.verificatorId)
      )
      .subscribe((verificator: Verificator) => {
        if (verificator) {
          const { id, code, name, isActive, description } = verificator;
          this.formGroup.patchValue({
            id,
            code,
            name,
            isActive,
            description
          });
          this.formGroup.get('code').setIsView(true);
        }
        this.setStateReady();
      });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const verificator: Verificator =
              this.global.copyFormAttributeToModel(
                new Verificator(),
                this.formGroup
              );
            const url =
              this.todo === 'edit'
                ? '/verificator/update'
                : '/verificator/insert';
            this.httpClientService
              .post<Response<Verificator>>(url, verificator)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/verificator']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.setStateReady();
                  this.global.alertService.showError(response.statusText);
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/verificator/']);
  }
}
