import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'src/app/core/global/global';
import { ResponseStatusModel } from 'src/app/core/model/response-status-model';
import { CartWebsocketService } from 'src/app/core/services/websocket/cart-websocket.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'theme-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit {
  public totalCart: number;

  constructor(
    public global: Global,
    public router: Router,
    public cartWewbsocketService: CartWebsocketService
  ) {}

  ngOnInit(): void {
    console.log('Info: Come from testing');
    this.getTotalCart();
    this.cartWewbsocketService.connect();
  }

  public getTotalCart(): void {
    this.global.httpClientService
      .get('/cart/get-total-cart')
      .subscribe(response => {
        if (response.status === ResponseStatusModel.OK) {
          this.totalCart = response.body;
          this.cartWewbsocketService.setReceivedMessage(this.totalCart);
        }
      });
  }

  public doOpenCart(): void {
    this.router.navigate(['/pages/cart']);
  }
}
