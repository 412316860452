import { BaseDTO } from '../../../core/base/base-dto';
import { File } from '../../../core/bean/file';
import { PurchaseOrder } from '../../../core/bean/purchase-order';
import { PurchaseOrderItem } from '../../../core/bean/purchase-order-item';
import { PurchaseRequest } from '../../../core/bean/purchase-request';
import { WorkPlan } from '../../../core/bean/work-plan';
import { FileObject } from '../../../core/components/upload';
export class PurchaseOrderDTO extends BaseDTO {
    purchaseOrderList: PurchaseOrder[];
    purchaseOrderItemList: PurchaseOrderItem[];
    fileObjectList: FileObject[];
    fileList: File[];
    purchaseRequestList: PurchaseRequest[];
    workPlan: WorkPlan;
    organizationName: string;
    purchaseOrder: PurchaseOrder;
    workPlanRealizeId: number;
}
