import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

export class SignatureModel {
    public requestUploadChanges: EventEmitter<void> = new EventEmitter();
    public uploadChanges: EventEmitter<void> = new EventEmitter();

    public requestUpload(): Observable<void> {
        this.requestUploadChanges.emit();
        return this.uploadChanges;
    }
}
