import { NgModule } from '@angular/core';
import { VendorModule } from 'src/app/core/vendor/vendor.module';
import { AppButtonModule } from '../../components/app-button/app-button.module';
import { AppChartModule } from '../../components/app-chart/app-chart.module';
import { AppComboBoxModule } from '../../components/app-combo-box/app-combo-box.module';
import { AppDropdownSelectModule } from '../../components/app-dropdown-select/app-dropdown-select.module';
import { AppPopupModule } from '../../components/app-popup/app-popup.module';
import { AppWidgetModule } from '../app-widget.module';
import { WidgetService } from '../widgets.service';
import { AppWidgetNumberOfOrderComponent } from './app-widget-number-of-order.component';

@NgModule({
  imports: [
    VendorModule,
    AppWidgetModule,
    AppChartModule,
    AppComboBoxModule,
    AppButtonModule,
    AppPopupModule,
    AppDropdownSelectModule
  ],
  declarations: [AppWidgetNumberOfOrderComponent],
  entryComponents: [AppWidgetNumberOfOrderComponent],
  exports: [AppWidgetNumberOfOrderComponent]
})
export class AppWidgetNumberOfOrderModule {
  constructor(widgetService: WidgetService) {
    widgetService.register(
      'app-widget-number-of-order',
      AppWidgetNumberOfOrderComponent
    );
  }
}
