import { NgModule } from '@angular/core';
import { VendorModule } from '../../../vendor/vendor.module';
import { AppTooltipWrapperModule } from '../../app-tooltip-wrapper/app-tooltip-wrapper.module';
import { UploadDirectiveModule } from '../directives/upload-directives.module';
import { UploadPipesModule } from '../pipes/upload-pipes.module';
@NgModule({
  imports: [
    VendorModule,
    UploadPipesModule,
    UploadDirectiveModule,
    AppTooltipWrapperModule
  ],
  exports: [
    VendorModule,
    UploadPipesModule,
    UploadDirectiveModule,
    AppTooltipWrapperModule
  ]
})
export class UploadSharedModule {}
