import { Component, ViewEncapsulation } from '@angular/core';
import { AppPopupService } from '../../../../../core/components/app-popup/app-popup.service';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { AppPopupWidgetPaymentMonitoringComponent } from './app-popup-widget-payment-monitoring.component';
import { WidgetPaymentMonitoringDTO } from './dto/widget-payment-monitoring.dto';

@Component({
  selector: 'dashboard-widget-payment-monitoring',
  templateUrl: './widget-payment-monitoring.component.html',
  styleUrls: ['./widget-payment-monitoring.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetPaymentMonitoringComponent extends BaseWidgetComponent {
  public static moduleCode = 'dashboard-widget-payment-monitoring';
  public totalContractRequest: number;
  public paymentMonitoringDTO: WidgetPaymentMonitoringDTO = new WidgetPaymentMonitoringDTO();

  constructor(
    public appPopupService: AppPopupService
  ) {
    super(WidgetPaymentMonitoringComponent.moduleCode);
  }

  public onInit(): void {
    this.getAndSetPaymentInformation();
  }

  public getAndSetPaymentInformation(): void {
    this.httpClientService
      .get<WidgetPaymentMonitoringDTO>('/widget-payment-monitoring/index')
      .subscribe(response => {
        response.paidAmount =
          this.global.converterService.convertMoney(response.paidAmount || 0) ||
          '0';
        response.pendingAmount =
          this.global.converterService.convertMoney(
            response.pendingAmount || 0
          ) || '0';
        this.paymentMonitoringDTO = response;
        this.setStateReady();
      });
  }

  public goToMoreDetail(statusCode): void {
    this.appPopupService.open(
      AppPopupWidgetPaymentMonitoringComponent,
      { statusCode },
      { size: 'lg' }
    );
  }

  public onKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
