import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
import { TransactionLog } from '../../bean/transaction-log';
import { WorkflowPrcs } from '../../bean/workflow-prcs';
import { DownloadService } from '../../services/download.service';
import { TablePluginData } from '../table/interface/table-plugin-data';
import { TableColumnModel } from '../table/model/table-column-model';
import { TableResponseModel } from '../table/model/table-response-model';
@Component({
  selector: 'app-approval-log',
  templateUrl: './app-approval-log.component.html'
})
export class AppApprovalLogComponent extends BaseComponentComponent {
  @Input() private workflowPrcs: WorkflowPrcs;
  @Input() moduleCode: string;

  public tableResponse: TableResponseModel<TransactionLog>;
  public isLoading: boolean;
  public isShowCondition: boolean;

  constructor(
    public downloadService: DownloadService,
    public translateService: TranslateService
  ) {
    super('app-approval-log');
  }

  onInit(): void {
    this.getIsShowCondition();
  }

  private setCustomData(): void {
    this.tableResponse.setCustomData({
      workflowPrcs: this.workflowPrcs,
      moduleCode: this.moduleCode
    });
  }

  public getIsShowCondition(): void {
    this.isLoading = true;
    this.httpClientService
      .get('/app-approval-log/is-show-condition/' + this.moduleCode)
      .subscribe(response => {
        this.isShowCondition = response;
        this.isLoading = false;
        this.buildTableResponse();
        this.setCustomData();
        this.setStateReady();
      });
  }

  private columnList: TableColumnModel[] = [
    {
      field: 'approvedByUser.name',
      header: 'table.column.user'
    },
    {
      field: 'processedDate',
      header: 'table.column.date',
      plugins: 'date'
    },
    {
      field: 'approvalNote',
      header: 'table.column.note'
    },
    {
      field: 'approvalFile.fileName',
      header: 'table.column.document',
      plugins: {
        name: 'hyperlink',
        onClick: this.doDownloadFile.bind(this),
        className: 'text-primary'
      }
    },
    {
      field: 'statusText',
      header: 'table.column.status',
      plugins: {
        name: 'badge',
        colorField: 'approvalStatus',
        colorMap: {
          0: 'WARNING',
          1: 'SUCCESS',
          2: 'FEEDBACK',
          3: 'DANGER',
          4: 'DANGER'
        }
      }
    }
  ];

  private buildTableResponse(): void {
    if (this.isShowCondition) {
      const conditionColumn: TableColumnModel = {
        field: 'reviseCondition',
        header: 'table.column.condition',
        plugins: {
          name: 'badge',
          className: 'badge-catalog',
          pill: false,
          colorMap: {
            DANGER: 'DANGER'
          },
          callbacks: {
            text: (tablePluginData: TablePluginData): string => {
              if (tablePluginData.value !== null) {
                return this.translateService.instant(
                  'dynamic-master-attribute.' +
                    tablePluginData.value.translationKey.key
                );
              }
            },
            color: (): string => {
              return 'DANGER';
            }
          }
        }
      };
      this.columnList.push(conditionColumn);
      this.tableResponse = new TableResponseModel(
        'app-approval-log',
        this.columnList
      );
    } else {
      this.tableResponse = new TableResponseModel(
        'app-approval-log',
        this.columnList
      );
    }
  }

  public doDownloadFile(rowData: TransactionLog): void {
    if (rowData.approvalFile) {
      const urlFile =
        '/app-approval-log/file/view/' +
        rowData.approvalFile.uploadedFileName +
        '/' +
        rowData.approvalFile.fileType.code;
      this.downloadService.download(urlFile, rowData.approvalFile.fileName);
    }
  }
}
