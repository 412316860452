import { BaseWorkflowObjectEntity } from '../base/base-workflow-object-entity.model';
import { File } from '../bean/file';
import { ModuleScoringCriteria } from '../bean/module-scoring-criteria';
import { Procurement } from '../bean/procurement';
import { ProcurementSowPaymentTerm } from '../bean/procurement-sow-payment-term';
import { User } from '../bean/user';
import { Vendor } from '../bean/vendor';
import { VendorPerformanceOverall } from '../bean/vendor-performance-overall';

export class VendorPerformanceEntity extends BaseWorkflowObjectEntity {
  verifiedByUser: User;
  vendor: Vendor = new Vendor();
  vendorPerformanceOverall: VendorPerformanceOverall = new VendorPerformanceOverall();
  moduleScoringCriteria: ModuleScoringCriteria = new ModuleScoringCriteria();
  procurement: Procurement = new Procurement();
  procurementSowPaymentTerm: ProcurementSowPaymentTerm = new ProcurementSowPaymentTerm();
  file: File = new File();
  createdDate: Date;
  note: string;
  rating: number;
  minRating: number;
  isQualified: boolean;
}
