import { Contract } from 'src/app/core/bean/contract';
import { ContractReviewer } from 'src/app/core/bean/contract-reviewer';
import { ContractDraft } from 'src/app/core/bean/contract-draft';
import { ContractDraftHistory } from 'src/app/core/bean/contract-draft-history';
import { ContractDraftParameterValue } from 'src/app/core/bean/contract-draft-parameter-value';
import { ContractTemplate } from 'src/app/core/bean/contract-template';
import { ContractTemplateParameter } from 'src/app/core/bean/contract-template-parameter';
import { ApprovalPathResponseModel } from 'src/app/core/components/app-approval-path-x';
import { Fines } from "src/app/core/bean/fines";
import { Guarantee } from "src/app/core/bean/guarantee";
import { Sow } from "src/app/core/bean/sow";
import { VendorAddress } from "src/app/core/bean/vendor-address";
import { VendorPIC } from "src/app/core/bean/vendor-pic";
import { ContractDetail } from "src/app/core/bean/contract-detail";
import { User } from 'src/app/core/bean/user';
import { AppPopupVersionHistoryModel } from 'src/app/core/components/app-popup/app-popup-version-history/app-popup-version-history-model';
import { WorkflowStepInfoModel } from 'src/app/core/components/app-workflow-step-info/model/workflow-step-info-model';

export class ContractDraftAddEditResponse {
  contractTemplateList: ContractTemplate[];
  contractCollabAccessList: ContractReviewer[];
  contractDraftParameterValueList: ContractDraftParameterValue[];
  contractTemplateParameterList: ContractTemplateParameter[];
  contractDraftHistory: ContractDraftHistory;
  contractDraft: ContractDraft;
  contract: Contract;
  isUserApproval: boolean;
  sowList: Sow[] = [];
  finesList: Fines[] = [];
  guaranteeList: Guarantee[] = [];
  contractDetailList: ContractDetail[] = [];
  vendorAddress: VendorAddress;
  vendorPic: VendorPIC;
  approvalPathResponseModel: ApprovalPathResponseModel = new ApprovalPathResponseModel();
  user: User;
  userTempList: User[] = [];
  existingContractReviewerList: ContractReviewer[];
  addContractReviewerList: ContractReviewer[];
  appPopupVersionHistoryModelList: AppPopupVersionHistoryModel[];
  workflowStepInfoModel: WorkflowStepInfoModel;
}
