import { TreeProcessItem } from '../interface/tree-process-item';
import { TreeProcessItemType } from '../type/tree-process-item-type';
export class TreeProcessItemModel {
    constructor(public data: any, public type: TreeProcessItemType, public origin?: any) { }
    get value(): TreeProcessItem {
        return {
            id: this.data.id,
            tempId: this.data.tempId,
            type: this.type,
            jsonString: JSON.stringify(this.data)
        };
    }
}
