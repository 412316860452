import { DueDiligenceTemplate } from '../../core/bean/due-diligence-template';
import { Attribute } from '../../core/bean/attribute';
import { Question } from '../../core/bean/question';

export class DueDiligenceTemplateResponse {
    questionListByDueDiligenceTemplate: Question[] = [];
    questionListNotByDueDiligenceTemplate: Question[] = [];
    attributeListByDueDiligenceTemplate: Attribute[] = [];
    attributeListNotByDueDiligenceTemplate: Attribute[] = [];
    dueDiligenceTemplate: DueDiligenceTemplate = new DueDiligenceTemplate();
}