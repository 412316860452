import { User } from './../bean/user';
import { ProcurementMethod } from './../bean/procurement-method';
import { ProcurementType } from './../bean/procurement-type';
import { ProcurementOfflineBudget } from './../bean/procurement-offline-budget';
import { ProcurementStatus } from './../bean/procurement-status';
import { PurchaseRequestData } from './../bean/purchase-request-data';
import { BaseEntity } from './../base/base-entity';
export class ProcurementOfflineEntity extends BaseEntity {
    procurementOfflineBudgetList: ProcurementOfflineBudget[] = [];
    purchaseRequestData: PurchaseRequestData;
    procurementStatus: ProcurementStatus;
    procurementType: ProcurementType;
    procurementMethod: ProcurementMethod;
    user: User;
    number: string;
    name: string;
    createdDate: Date;
    description: string;
    vendorName: string;
    status: string;

}
