import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppTooltipWrapperItemComponent } from './app-tooltip-wrapper-item/app-tooltip-wrapper-item.component';
import { AppTooltipWrapperComponent } from './app-tooltip-wrapper.component';
@NgModule({
  imports: [VendorModule],
  declarations: [AppTooltipWrapperComponent, AppTooltipWrapperItemComponent],
  entryComponents: [AppTooltipWrapperComponent],
  exports: [AppTooltipWrapperComponent, AppTooltipWrapperItemComponent]
})
export class AppTooltipWrapperModule {}
