import { NgModule } from '@angular/core';
import { VendorModule } from '../../vendor/vendor.module';
import { AppWorkPathModule } from '../app-work-path';
import { AppWorkflowProgressInfoComponent } from './app-workflow-progress-info.component';
@NgModule({
  imports: [VendorModule, AppWorkPathModule],
  declarations: [AppWorkflowProgressInfoComponent],
  exports: [AppWorkflowProgressInfoComponent]
})
export class AppWorkflowProgressInfoModule {}
