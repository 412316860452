import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { AppPopupDocumentGroupComponent } from './app-popup-document-group.component';
import { AppPopupDocumentComponent } from './app-popup-document.component';
import { VendorDocumentEditComponent } from './vendor-document-edit.component';
import { VendorDocumentComponent } from './vendor-document.component';
const routes: Routes = [
  {
    path: '',
    component: VendorDocumentComponent,
    data: { breadcrumb: 'Vendor Management' }
  },
  {
    path: 'edit',
    component: VendorDocumentEditComponent,
    data: { breadcrumb: 'vendor-document.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  declarations: [
    VendorDocumentComponent,
    VendorDocumentEditComponent,
    AppPopupDocumentGroupComponent,
    AppPopupDocumentComponent
  ],
  entryComponents: [AppPopupDocumentGroupComponent, AppPopupDocumentComponent]
})
export class VendorDocumentModule {}
