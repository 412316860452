import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppPopupService } from '../app-popup.service';
import { AppPopupVendorComponent } from './app-popup-vendor.component';
@Injectable()
export class AppPopupVendorService {
  constructor(public appPopupService: AppPopupService) {}
  public open(
    vendorIdList?: number[],
    vendorActivityStatusCodeList?: string[],
    vendorStatusCodeList?: string[],
    vendorCertificateStatusCodeList?: string[]
  ): Observable<any> {
    return this.appPopupService.open(AppPopupVendorComponent, {
      vendorIdList,
      vendorActivityStatusCodeList,
      vendorStatusCodeList,
      vendorCertificateStatusCodeList
    });
  }
}
