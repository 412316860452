import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../core/shared/shared.module';
import { SelectRoleComponent } from './select-role.component';
export const routes = [
  { path: '', component: SelectRoleComponent, pathMatch: 'full' }
];
@NgModule({
  imports: [ SharedModule, RouterModule.forChild(routes) ],
  declarations: [SelectRoleComponent]
})
export class SelectRoleModule { }
