import { BaseDTO } from '../../../core/base/base-dto';
import { Industry } from '../../../core/bean/industry';
import { Qualification } from '../../../core/bean/qualification';
import { Region } from '../../../core/bean/region';
import { Vendor } from '../../../core/bean/vendor';
import { VendorAddress } from '../../../core/bean/vendor-address';
import { VendorAddressHistory } from '../../../core/bean/vendor-address-history';
import { VendorEntity } from '../../../core/bean/vendor-entity';
import { VendorHistory } from '../../../core/bean/vendor-history';
import { VendorLocation } from '../../../core/bean/vendor-location';
import { VendorOwnership } from '../../../core/bean/vendor-ownership';
import { VendorPIC } from '../../../core/bean/vendor-pic';
import { VendorPICHistory } from '../../../core/bean/vendor-pic-history';

export class TabProfileCompanyDataDTO extends BaseDTO {
    vendorHistory: VendorHistory;
    vendor: Vendor;
    industryList: Industry[];
    vendorLocationList: VendorLocation[];
    vendorEntityList: VendorEntity[];
    vendorOwnershipList: VendorOwnership[];
    qualificationList: Qualification[];
    countryRegionList: Region[];
    vendorAddressHistoryList: VendorAddressHistory[];
    vendorPICHistoryList: VendorPICHistory[];

    // Kebutuhan view
    productCategoryNames: string;
    productLocationNames: string;
    industryNames: string;
    productCategoryHistoryNames: string;
    productLocationHistoryNames: string;
    industryHistoryNames: string;
    vendorAddressList: VendorAddress[];
    vendorPICList: VendorPIC[];
}
