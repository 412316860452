import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './../../core/shared/shared.module';
import { AppPopupFinesMonitoringAdjustmentComponent } from './app-popup-fines-monitoring-adjustment.component';
import { FinesMonitoringEditAddComponent } from './fines-monitoring-edit-add.component';
import { FinesMonitoringComponent } from './fines-monitoring.component';

export const routes = [
  { path: '', component: FinesMonitoringComponent, data: { breadcrumb: '' } },
  {
    path: 'edit',
    component: FinesMonitoringEditAddComponent,
    data: { breadcrumb: 'fines.breadcrumb.edit' }
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, NgbModule],
  declarations: [
    FinesMonitoringComponent,
    FinesMonitoringEditAddComponent,
    AppPopupFinesMonitoringAdjustmentComponent
  ]
})
export class FinesMonitoringModule {}
