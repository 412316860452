import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipe/pipe.module';
import { VendorModule } from '../../vendor/vendor.module';
import { AppBadgeCatalogModule } from '../app-badge-catalog/app-badge-catalog.module';
import { AppBadgeModule } from '../app-badge/app-badge.module';
import { AppCurrencyModule } from '../app-currency/app-currency.module';
import { AppLazyLoadModule } from '../app-lazy-load/app-lazy-load.module';
import { AppCatalogItemXComponent } from './app-catalog-item-x.component';

@NgModule({
  imports: [VendorModule, DirectivesModule, AppCurrencyModule, AppLazyLoadModule, PipesModule,
    AppBadgeModule, AppBadgeCatalogModule],
  declarations: [AppCatalogItemXComponent],
  entryComponents: [AppCatalogItemXComponent],
  exports: [AppCatalogItemXComponent]
})
export class AppCatalogItemXModule {}
