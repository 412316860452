import { Component } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { FileUploader } from 'src/app/core/components/upload';
import { Validators } from 'src/app/core/validators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { Currency } from '../../../core/bean/currency';
import { RfiQuestion } from '../../../core/bean/rfi-question';
import { OptionListModel } from '../../../core/model/option-list-model';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { ResponseRfiDTO } from '../dto/response-rfi.dto';

@Component({
  templateUrl: './response-rfi-question.component.html'
})
export class ResponseRfiQuestionComponent extends BaseModuleComponent {
  public rfiVendorId: number;
  public responseRfiDTO: ResponseRfiDTO = new ResponseRfiDTO();
  public rfiQuestionList: RfiQuestion[];
  public listRadioOptionList: OptionListModel<any> = new OptionListModel();
  public mapperOptionsList: Map<string, OptionListModel<any>> = new Map();
  public mapperFileUploader: Map<string, FileUploader> = new Map();
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    false,
    'code'
  );
  public todo = '';
  public formGroupValueQuestion: object;
  constructor(translateService: TranslateService) {
    super('response-rfi', translateService);
  }

  onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.buildFormObjectList();
    this.handleFormGroupValueChanges();
    this.setStateReady();
  }

  public setDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.responseRfiDTO = this.global.routerParams.get('responseRfiDTO');
    this.currencyOptionList.setRequestValues(this.responseRfiDTO.currencyList);
    this.formGroupValueQuestion = this.global.routerParams.get('formGroupValueQuestion');
  }

  public buildFormObjectList(): void {
    const { rfiQuestionList } = this.responseRfiDTO;
    rfiQuestionList.forEach((question: any, index: number) => {
      question.formObject.value = null;
      if (question.listOfValue) {
        const optionList = new OptionListModel();
        optionList.setRequestValues(question.listOfValue || []);
        this.mapperOptionsList.set('optionList' + index, optionList);
      }

      if (question.formObject.code === 'UPLOAD-SINGLE') {
        const fileUploader: FileUploader = new FileUploader('/response-rfi/', 'rfiDoc', this.global.appConstant.fileType.DOC_RFI);
        this.mapperFileUploader.set('fileUploader' + index, fileUploader);
      }

      if (question.formObject.code === 'CHECK-BOX') {
        this.formGroup
          .get('generated' + index)
          .patchValue(question.listOfValue);
      }
      /* else {
        this.formGroup
          .get('generated' + index)
          .patchValue(this.formGroup.get('generated' + index).value);
      } */
    });
  }

  public buildFormGroup(): void {
    const { rfiQuestionList } = this.responseRfiDTO;
    const formGroupObject = {};
    rfiQuestionList.forEach((rfiQuestion, i) => {
      Object.assign(formGroupObject, {
        ['generated' + i.toString()]: [
          null,
          this.validatorBuilder(rfiQuestion)
        ]
      });
    });
    this.formGroup = this.formBuilder.group(formGroupObject);

    if (this.formGroupValueQuestion) {
      this.formGroup.setValue(this.formGroupValueQuestion);
    }
  }

  public validatorBuilder(data: RfiQuestion): ValidatorFn {
    if (data.formObject.code === 'CHECK-BOX') {
      return Validators.compose([Validators.isChecked()]);
    } else if (data.formObject.code === 'TOGGLE-SWITCH') {
      return null;
    } else {
      return Validators.compose([Validators.required()]);
    }
  }

  public handleFormGroupValueChanges(): void {
    const { rfiQuestionList } = this.responseRfiDTO;
    this.formGroup.valueChanges.subscribe(() => {
      rfiQuestionList.forEach((rfiQuestion: any, index: number) => {
        rfiQuestion.formObject.value = this.formGroup.get(
          'generated' + index
        ).value;
      });
    });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();

            const answerList: string[] = [];
            Object.values(this.formGroup.value).forEach(value => {
              answerList.push(JSON.stringify(value));
            });

            this.responseRfiDTO.answer = answerList;

            const url = '/response-rfi/update';
            this.httpClientService
              .post<Response<ResponseRfiDTO>>(url, this.responseRfiDTO)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/response-rfi/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                }
                this.setStateReady();
              });
          }
        });
    }
  }

  public doBack(): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('todo', 'edit');
    this.global.routerParams.set('rfiVendorId',
      this.responseRfiDTO.rfiVendor.id);
    this.global.routerParams.set('responseRfiDTO', this.responseRfiDTO);
    this.global.routerParams.set('isFromQuestion', true);
    this.global.routerParams.set('formGroupValueQuestion', this.formGroup.value);

    this.router.navigate(['/pages/response-rfi/edit']);
  }

  public get formGroupControls(): any {
    return this.formGroup.controls;
  }
}
