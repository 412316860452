import { Oep } from 'src/app/core/bean/oep';
import { OepItem } from 'src/app/core/bean/oep-item';
import { FileObject } from 'src/app/core/components/upload';

export class OepRequest {
  oepItemList: OepItem[] = [];
  fileList: FileObject[] = [];
  oep: Oep = new Oep();
  procurementId: number;
  isSubmit: boolean;
  fileApprovalList: FileObject[] = [];
}
