import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmGuard } from '../../core/guard/confirm.guard';
import { SharedModule } from '../../core/shared/shared.module';
import { PushNotificationEditComponent } from './push-notification-edit.component';
import { PushNotificationComponent } from './push-notification.component';

const routes = [
  {
    path: '',
    component: PushNotificationComponent,
    data: { breadcrumb: '' }
  },
  {
    path: 'edit',
    component: PushNotificationEditComponent,
    data: { todo: 'edit', breadcrumb: 'push-notification.breadcrumb.edit' },
    canDeactivate: [ConfirmGuard]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  declarations: [PushNotificationComponent, PushNotificationEditComponent]
})
export class PushNotificationModule {}
