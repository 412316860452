import { BaseEntity } from '../base/base-entity';
import { ProcurementSubmissionType } from '../bean/procurement-submission-type';
import { WorkPlan } from '../bean/work-plan';

export class ProcurementSubmissionEntity extends BaseEntity {
  procurementSubmissionType: ProcurementSubmissionType = new ProcurementSubmissionType();
  workPlan: WorkPlan = new WorkPlan();
  number: string;
  date: Date = new Date();
  procurementSubmissionPmId: number;
}
