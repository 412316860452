import { Component, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { File } from '../../../core/bean/file';
import { WorkPlan } from '../../../core/bean/work-plan';
import { TableResponseModel } from '../../../core/components/app-table/model/table-response-model';
import { FileUploader } from '../../../core/components/upload/model/v1/file.uploader';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { RouteRequestModel } from '../../../core/model/route-request-model';
import { Validators } from '../../../core/validators';
import { WorkPlanRealizeProcurementDTO } from '../dto/work-plan-realize-procurement.dto';
import { Procurement } from './../../../core/bean/procurement';
import { PurchaseRequest } from './../../../core/bean/purchase-request';

@Component({
  templateUrl: 'work-plan-realize-procurement.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class WorkPlanRealizeProcurementComponent extends BaseModuleComponent {
  public readonly FILE_TYPE_CODE = this.global.appConstant.fileType.DOC_PROCUREMENT;
  public tableResponse: TableResponseModel<File>;
  public workPlanId: number;
  public workPlanRealizeId: number;
  public routeList: string[] = [
    'work-plan',
    'purchase-request',
    'procurement',
    'contract',
    'purchase-order',
    'delivery-received',
    'payment'
  ];
  public workPlanRealizeProcurementDTO: WorkPlanRealizeProcurementDTO = new WorkPlanRealizeProcurementDTO();
  organizationName: string;
  url: string;
  fileUploader: FileUploader = new FileUploader(
    '/work-plan-realize-procurement/',
    null,
    this.FILE_TYPE_CODE,
    false,
    5
  );
  public fileUploaderList: FileUploader[] = new Array();
  public isAllUploaded = false;
  public purchaseRequestIds: number[] = [];
  public msgInfo = '';
  public isProcurementNumberAlreadyUser: boolean[] = [];
  public procurementNumber: string;
  public submitted: boolean;
  public isHaveProcurement = false;
  constructor(translateService: TranslateService) {
    super('work-plan-realize', translateService);
  }

  onInit(): void {
    this.workPlanId = this.global.routerParams.get('workPlanId');
    this.workPlanRealizeId = this.global.routerParams.get('workPlanRealizeId');

    this.formGroup = this.formBuilder.group({
      procurementList: this.formBuilder.array([])
    });
    // this.workPlanRealizeId = 3;
    this.httpClientService
      .post<WorkPlanRealizeProcurementDTO>(
        '/work-plan-realize-procurement/add-edit',
        new RouteRequestModel(this.todo, this.workPlanRealizeId)
      )
      .subscribe(workPlanRealizeProcurementDTO => {
        this.workPlanRealizeProcurementDTO = workPlanRealizeProcurementDTO;
        this.workPlanRealizeProcurementDTO.workPlanRealize =
          workPlanRealizeProcurementDTO.workPlanRealize;
        this.organizationName =
          workPlanRealizeProcurementDTO.workPlanRealize.workPlan.organization.name;

        if (this.workPlanRealizeProcurementDTO.procurementList.length > 0) {
          this.todo = 'edit';
          this.workPlanRealizeProcurementDTO.procurementList.forEach(
            (procurement, index) => {
              const purchaseRequestList: PurchaseRequest[] = [];
              this.workPlanRealizeProcurementDTO.procurementList[
                index
              ].purchaseRequestList = purchaseRequestList;

              this.workPlanRealizeProcurementDTO.purchaseRequestListIsSelected.forEach(
                purchaseRequest => {
                  if (
                    procurement.id ===
                    purchaseRequest.purchaseRequestItemList[0].procurement.id
                  ) {
                    this.workPlanRealizeProcurementDTO.procurementList[
                      index
                    ].purchaseRequestList.push(purchaseRequest);
                  }
                }
              );
            }
          );
          this.buildProcument(
            this.workPlanRealizeProcurementDTO.procurementList
          );
        } else {
          this.buildProcument();
        }
        this.setStateReady();
      });
  }

  public buildProcument(procurementList?: Procurement[]): void {
    const procumentForm = this.formGroup.controls.procurementList as FormArray;
    if (procurementList !== undefined) {
      if (procurementList.length === 0) {
        const index = procumentForm.length;
        procumentForm.push(
          this.formBuilder.group({
            id: [null],
            number: [null, Validators.required()],
            name: [null, Validators.required()],
            procurementDate: [null, Validators.required()],
            description: [null, Validators.required()],
            workPlanRealize: this.workPlanRealizeProcurementDTO.workPlanRealize,
            purchaseRequestList: this.formBuilder.array([]),
            file: [null, Validators.required()]
          })
        );
        this.buildPurchaseRequestList(index);
        this.fileUploaderList.push(
          new FileUploader(
            '/work-plan-realize-procurement/',
            null,
            this.FILE_TYPE_CODE,
            false,
            5
          )
        );
      } else {
        procurementList.forEach((procurement, index) => {
          const createdDate: Date = new Date(procurement.procurementDate);
          procumentForm.push(
            this.formBuilder.group({
              id: [procurement.id],
              number: [procurement.number, Validators.required()],
              name: [procurement.name, Validators.required()],
              date: [createdDate, Validators.required()],
              description: [procurement.description, Validators.required()],
              workPlanRealize: this.workPlanRealizeProcurementDTO
                .workPlanRealize,
              purchaseRequestList: this.formBuilder.array([]),
              file: [procurement.fileList]
            })
          );
          this.buildPurchaseRequestList(index, procurement);
          this.fileUploaderList.push(
            new FileUploader(
              '/work-plan-realize-procurement/',
              null,
              this.FILE_TYPE_CODE,
              false,
              5
            )
          );
          this.fileUploaderList[index].setFileList(procurement.fileList);
          this.isHaveProcurement = true;
        });
      }
    } else {
      const index = procumentForm.length;
      procumentForm.push(
        this.formBuilder.group({
          id: [null],
          number: [null, Validators.required()],
          name: [null, Validators.required()],
          procurementDate: [null, Validators.required()],
          description: [null, Validators.required()],
          workPlanRealize: this.workPlanRealizeProcurementDTO.workPlanRealize,
          purchaseRequestList: this.formBuilder.array([]),
          file: [null, Validators.required()]
        })
      );
      this.buildPurchaseRequestList(index);
      this.fileUploaderList.push(
        new FileUploader(
          '/work-plan-realize-procurement/',
          null,
          this.FILE_TYPE_CODE,
          false,
          5
        )
      );
    }
  }

  public checkUploadedStatus(): void {
    let isAllUploaded = true;
    this.fileUploaderList.forEach(fileuploader => {
      if (!fileuploader.isAllUploaded) {
        isAllUploaded = fileuploader.isAllUploaded;
      }
    });
    this.isAllUploaded = isAllUploaded;
  }

  public buildPurchaseRequestList(
    index: number,
    procurement?: Procurement
  ): void {
    const procurementListForm = this.formGroup.controls
      .procurementList as FormArray;
    const purchaseRequestListForm = procurementListForm.controls[index].get(
      'purchaseRequestList'
    ) as FormArray;

    if (procurement === undefined) {
      purchaseRequestListForm.push(
        this.formBuilder.group({
          purchaseRequest: [null, Validators.required()],
          id: [null],
          purchaseRequestItemList: this.formBuilder.array([]),
          totalAnggaran: [null],
          convertTotalAnggaran: [null],
          totalRealisasi: [null],
          convertTotalRealisasi: [null]
        })
      );
    } else {
      procurement.purchaseRequestList.forEach((purchaseRequest, i) => {
        const indexPurchaseRequest = this.workPlanRealizeProcurementDTO.purchaseRequestList.findIndex(
          pr => pr.id === purchaseRequest.id
        );
        purchaseRequestListForm.push(
          this.formBuilder.group({
            purchaseRequest: [
              this.workPlanRealizeProcurementDTO.purchaseRequestList[
                indexPurchaseRequest
              ],
              Validators.required()
            ],
            id: purchaseRequest.id,
            purchaseRequestItemList: this.formBuilder.array([]),
            totalAnggaran: [null],
            convertTotalAnggaran: [null],
            totalRealisasi: [null],
            convertTotalRealisasi: [null]
          })
        );

        this.doOnChangePurchaseRequest(purchaseRequestListForm.controls[i]);
      });
    }
  }

  onTabChange(event): void {
    this.router.navigate([
      '/pages/work-plan-realize/' + this.routeList[event.index]
    ]);
  }

  public onClickAddProcurement(): void {
    this.buildProcument();
  }

  public onClickDeleteProcurement(): void {
    const procurementListForm = this.formGroup.controls
      .procurementList as FormArray;
    if (procurementListForm.length > 1) {
      procurementListForm.removeAt(procurementListForm.length - 1);
    }
    this.msgInfo = '';
  }

  public onClickAddPurchaseRequest(index: number): void {
    const procurementListForm = this.formGroup.controls
      .procurementList as FormArray;
    const purchaseRequestListForm = procurementListForm.controls[index].get(
      'purchaseRequestList'
    ) as FormArray;
    purchaseRequestListForm.push(
      this.formBuilder.group({
        purchaseRequest: [null, Validators.required()],
        id: [null],
        purchaseRequestItemList: this.formBuilder.array([]),
        totalAnggaran: [null],
        convertTotalAnggaran: [null],
        totalRealisasi: [null],
        convertTotalRealisasi: [null]
      })
    );
  }

  public onClickDeletePurchaseRequest(
    index: number,
    purchaseRequestList: FormArray
  ): void {
    const id = purchaseRequestList.at(index).value.id;
    this.workPlanRealizeProcurementDTO.purchaseRequestList.forEach(
      purchaseRequest => {
        if (purchaseRequest.id === id) {
          purchaseRequest.isDisabled = false;
        }
      }
    );

    this.msgInfo = '';
    if (purchaseRequestList.length > 1) {
      purchaseRequestList.removeAt(index);
    }
  }

  public doOnChangePurchaseRequest(purchaseRequestObj?: any): void {
    const procurementListForm = this.formGroup.controls
      .procurementList as FormArray;
    let purchaseRequest = new PurchaseRequest();
    purchaseRequest = purchaseRequestObj.get('purchaseRequest').value;
    const purchaseRequestItemListForm = purchaseRequestObj.controls
      .purchaseRequestItemList as FormArray;
    this.purchaseRequestIds = [];

    for (const procurement of procurementListForm.value) {
      for (const pr of procurement.purchaseRequestList) {
        this.purchaseRequestIds.push(pr.purchaseRequest.id);
      }
    }

    this.workPlanRealizeProcurementDTO.purchaseRequestList.forEach(pr => {
      pr.isDisabled = false;
      this.purchaseRequestIds.forEach(id => {
        if (pr.id === id) {
          pr.isDisabled = true;
        }
      });
    });

    if (
      this.workPlanRealizeProcurementDTO.purchaseRequestList.length ===
      this.purchaseRequestIds.length
    ) {
      this.msgInfo = this.translateService.instant(
        'work-plan-realize-procurement.tooltip.addPurhaseRequest'
      );
    }

    let totalAnggaran = 0;
    let totalRealisasi = 0;

    for (let i = 0; i <= purchaseRequestItemListForm.length; i++) {
      purchaseRequestItemListForm.removeAt(0);
    }

    purchaseRequest.purchaseRequestItemList.forEach(purchaseRequestItem => {
      let totalPriceRealize = 0;
      if (purchaseRequestItem.totalPriceRealize != null) {
        totalPriceRealize = purchaseRequestItem.totalPriceRealize;
      }
      purchaseRequestItemListForm.push(
        this.formBuilder.group({
          id: purchaseRequestItem.id,
          quantity: purchaseRequestItem.quantity,
          unitPrice: purchaseRequestItem.unitPrice,
          convertUnitPrice: this.global.converterService.convertMoney(
            purchaseRequestItem.unitPrice.toString()
          ),
          totalPrice: purchaseRequestItem.totalPrice,
          convertTotalPrice: this.global.converterService.convertMoney(
            purchaseRequestItem.totalPrice.toString()
          ),
          // tslint:disable-next-line:max-line-length
          unitPriceRealize: [
            this.global.converterService.convertMoney(
              purchaseRequestItem.unitPriceRealize === null
                ? '0'
                : purchaseRequestItem.unitPriceRealize.toString()
            ),
            Validators.required()
          ],
          totalPriceRealize,
          convertTotalPriceRealize: this.global.converterService.convertMoney(
            totalPriceRealize.toString()
          ),
          purchaseRequest,
          workPlanItem: purchaseRequestItem.workPlanItem,
          description: null,
          vendor: [purchaseRequestItem.vendor, Validators.required()]
        })
      );

      totalAnggaran = totalAnggaran + purchaseRequestItem.totalPrice;
      totalRealisasi = totalRealisasi + totalPriceRealize;
    });

    purchaseRequestObj.patchValue({
      id: purchaseRequest.id,
      totalAnggaran,
      convertTotalAnggaran: this.global.converterService.convertMoney(
        totalAnggaran.toString()
      ),
      totalRealisasi,
      convertTotalRealisasi: this.global.converterService.convertMoney(
        totalRealisasi.toString()
      )
    });
  }

  public onChangeUnitPriceRealize(
    purchaseRequestItem: FormGroup,
    purchaseRequestItemList: FormArray,
    purchaseRequestObj: FormGroup
  ): void {
    const purchaseRequestItemForm = purchaseRequestItem.value;
    const quantity = purchaseRequestItemForm.quantity;
    const unitPriceRealize = purchaseRequestItemForm.unitPriceRealize;
    const totalPriceRealize = +quantity * +unitPriceRealize;
    purchaseRequestItem.patchValue({
      totalPriceRealize,
      convertTotalPriceRealize: this.global.converterService.convertMoney(
        totalPriceRealize.toString()
      )
    });

    let totalAnggaran = 0;
    let totalRealisasi = 0;
    for (const itemPurchaseRequest of purchaseRequestItemList.value) {
      totalAnggaran = totalAnggaran + +itemPurchaseRequest.totalPrice;
      totalRealisasi = totalRealisasi + +itemPurchaseRequest.totalPriceRealize;
    }
    purchaseRequestObj.patchValue({
      totalAnggaran,
      convertTotalAnggaran: this.global.converterService.convertMoney(
        totalAnggaran.toString()
      ),
      totalRealisasi,
      convertTotalRealisasi: this.global.converterService.convertMoney(
        totalRealisasi.toString()
      )
    });
  }

  public doSave(): void {
    this.validate();
    this.workPlanRealizeProcurementDTO.procurementList = this.formGroup.controls.procurementList.value;
    this.global.modalService
      .saveConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.setStateProcessing();
          if (this.todo === 'edit') {
            this.url = '/work-plan-realize-procurement/update';
          } else {
            this.url = '/work-plan-realize-procurement/insert';
          }
          this.workPlanRealizeProcurementDTO = this.global.copyFormAttributeToModel(
            this.workPlanRealizeProcurementDTO,
            this.formGroup
          );
          this.workPlanRealizeProcurementDTO.procurementList.forEach(
            (procurement, i) => {
              procurement.fileObjectList = this.fileUploaderList[
                i
              ].fileObjectList;
              procurement.purchaseRequestList.forEach(purchaseRequest => {
                purchaseRequest.purchaseRequestItemList.forEach(
                  purchaseRequestItem => {
                    const unitPriceRealize = this.global.converterService.uConvertMoney(
                      purchaseRequestItem.unitPriceRealize.toString()
                    );
                    const convUnitPriceRealize = parseFloat(unitPriceRealize);
                    purchaseRequestItem.unitPriceRealize = convUnitPriceRealize;
                  }
                );
              });
            }
          );
          this.httpClientService
            .post<Response<WorkPlan>>(
              this.url,
              this.workPlanRealizeProcurementDTO
            )
            .subscribe(response => {
              if (response.status === ResponseStatusModel.OK) {
                this.global.alertService.showSuccessSaving();
                this.isHaveProcurement = true;
                this.setStateReady();
              } else {
                this.setStateReady();
                this.global.alertService.showError(response.statusText);
              }
            });
        }
      });
  }

  public doPrevious(): void {
    this.global.routerParams.set('workPlanId', this.workPlanId);
    this.router.navigate(['/pages/work-plan-realize/purchase-request']);
  }

  public doNext(): void {
    this.router.navigate(['/pages/work-plan-realize/contract']);
  }

  public checkProcurementNumber(index: number): void {
    let isSelected = false;
    let procurementList: Procurement[] = [];
    procurementList = this.formGroup.controls.procurementList.value;
    this.procurementNumber = procurementList[index].number;
    procurementList.forEach((procurement, i) => {
      this.isProcurementNumberAlreadyUser[i] = false;
      if (i !== index && procurement.number === this.procurementNumber) {
        isSelected = true;
      }
      if (isSelected === true) {
        this.isProcurementNumberAlreadyUser[index] = true;
      }
    });
  }

  public get formArrayProcurementList(): FormArray {
    return this.formGroup.get('procurementList') as FormArray;
  }
}
