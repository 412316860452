import { PaymentBilling } from 'src/app/core/bean/payment-billing';
import { PaymentInvoice } from 'src/app/core/bean/payment-invoice';
import { ProcurementSowPaymentTermBilling } from 'src/app/core/bean/procurement-sow-payment-billing';
import { FileObject } from 'src/app/core/components/upload';
import { PaymentBillingDoc } from '../../core/bean/payment-billing-doc';
import { SowPaymentTermBilling } from 'src/app/core/bean/sow-payment-term-billing';
import { Payment } from 'src/app/core/bean/payment';

export class VendorPaymentRequestInsertRequest {
    paymentInvoice: PaymentInvoice;
    paymentBillingDocumentList: PaymentBillingDoc[];
    procurementSowPaymentTermBillingList: ProcurementSowPaymentTermBilling[];
    fileObjectList: FileObject[];
    procurementSowPaymentTermId: number;
    procurementVendorId: number;
    isPartial: boolean;
    amount: number;
    remainingPayment: number;
    payment: Payment;
    contractId: number;
    paymentBillingDocList: PaymentBillingDoc[];
    sowPaymentTermBillingList: SowPaymentTermBilling[];
    sowPaymentTermId: number;
    paymentBilling: PaymentBilling;
    isSubmit: boolean;
}
