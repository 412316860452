import { Component, ViewEncapsulation } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { WebContent } from '../../../core/bean/web-content';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { Validators } from '../../../core/validators';
@Component({
  templateUrl: './toc-edit-add.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TocEditAddComponent extends BaseModuleComponent {
  contentVendorList: WebContent[] = [];
  constructor(translateService: TranslateService) {
    super('content-vendor-toc', translateService);
  }

  public onInit(): void {
    this.todo = this.global.routerParams.get('todo');
    const moduleCode = this.global.routerParams.get('moduleCode');
    this.formGroup = this.formBuilder.group({
      contentVendorList: this.formBuilder.array([])
    });
    this.httpClientService
      .post<WebContent[]>('/content-vendor-toc/add-edit', 
      moduleCode)
      .subscribe(contentVendorList => {
        this.buildForm(contentVendorList);
        this.setStateReady();
      });
  }

  public buildForm(contentVendorList: WebContent[]): void {
    const control = this.formGroup.controls.contentVendorList as FormArray;
    contentVendorList.forEach(contentVendorTOC => {
      control.push(
        this.formBuilder.group({
          id: contentVendorTOC.id,
          code: contentVendorTOC.code,
          key: contentVendorTOC.key,
          description: contentVendorTOC.description,
          lang: contentVendorTOC.lang,
          value: [contentVendorTOC.value, Validators.required()]
        })
      );
    });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const contentVendorList = (
              this.formGroup.controls.contentVendorList as FormArray
            ).value as Array<WebContent>;
            this.httpClientService
              .post<Response<WebContent[]>>(
                '/content-vendor-toc/update',
                contentVendorList
              )
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.router.navigate(['/pages/vendor-content-management/']);
                  this.global.alertService.showSuccessSavingOnNextRoute();
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/vendor-content-management/']);
  }
}
