import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from 'src/app/core/base/angular/base-module.component';
import { Currency } from 'src/app/core/bean/currency';
import { ProcurementFilter } from 'src/app/core/bean/procurement-filter';
import { ProcurementSegQualification } from 'src/app/core/bean/procurement-seg-qualification';
import { Region } from 'src/app/core/bean/region';
import { Segmentation } from 'src/app/core/bean/segmentation';
import { VendorCategory } from 'src/app/core/bean/vendor-category';
import { VendorType } from 'src/app/core/bean/vendor-type';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { PopupVendorView } from 'src/app/core/view/entity/bean/popup-vendor-view';
import { TablePluginData } from '../../table/interface/table-plugin-data';
import { TableResponseModel } from '../../table/model/table-response-model';
import { AppPopupChooseVendorCondition } from './app-popup-choose-vendor-condition';
import { AppPopupChooseVendorOptionResponse } from './app-popup-choose-vendor-option.response';

@Component({
  templateUrl: './app-popup-choose-vendor.component.html',
  styleUrls: ['./app-popup-choose-vendor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppPopupChooseVendorComponent extends BaseModuleComponent {
  @Input() vendorList: PopupVendorView[] = [];
  @Input() prIdList: number[] = [];
  @Input() excludeVendorIdList: number[] = [];
  @Input() isUserRefer: boolean;
  @Input() procurementMethodId: number;
  @Input() chooseVendorCondition: AppPopupChooseVendorCondition =
    new AppPopupChooseVendorCondition();
  @Input() procurementFilter: ProcurementFilter;
  @Input() regionList: Region[] = [];
  @Input() segmentationList: Segmentation[] = [];
  @Input() procurementSegQualificationList: ProcurementSegQualification[] = [];
  @Input() isOutputFilter: boolean;
  @Input() isShowCheckBox = true;
  @Input() maxParticipant: number;

  @Output() onChange = new EventEmitter();

  public tableResponse: TableResponseModel<PopupVendorView>;
  public selectedVendorViewList: PopupVendorView[] = [];
  public vendorListTemp: PopupVendorView[] = [];
  public vendorIdListTemp: number[] = [];
  public optionResponse: AppPopupChooseVendorOptionResponse =
    new AppPopupChooseVendorOptionResponse();
  public regionOptionList: OptionListModel<Region> = new OptionListModel(true);
  public vendorTypeOptionList: OptionListModel<VendorType> =
    new OptionListModel(true);
  public vendorCategoryOptionList: OptionListModel<VendorCategory> =
    new OptionListModel(true);
  public segmentationOptionList: OptionListModel<Segmentation> =
    new OptionListModel(true);
  public currencyOptionList: OptionListModel<Currency> = new OptionListModel();

  constructor(translateService: TranslateService) {
    super('app-popup-choose-vendor', translateService);
  }

  public onInit(): void {
    this.buildFormGroup();
    this.setIsView();
    this.setOptionList();
    this.setFormGroup();
    this.buildTableResponse();
    this.doSetCustomData();
    this.getListVendorForTable();
  }

  public setIsView(): void {
    if (this.chooseVendorCondition.isDisableFilter) {
      this.setViewOnly();
    }
  }
  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      prIdList: [this.prIdList],
      excludeVendorIdList: [this.excludeVendorIdList],
      procurementMethodId: [this.procurementMethodId],
      regionList: [null],
      rating: [null],
      segmentationList: [null],
      procurementSegQualificationList: [null],
      contractValue: [null],
      totalContract: [null],
      contractDate: [null],
      keyword: [null],
      vendorTypeList: [null],
      vendorCategoryList: [null],
      startContractDate: [null],
      endContractDate: [null]
    });
  }

  public setFormGroup(): void {
    if (this.procurementFilter) {
      this.formGroup.patchValue({
        contractValue: {
          price: this.procurementFilter.contractAmount
        },
        totalContract: this.procurementFilter.contractNum,
        rating: this.procurementFilter.minRating,
        contractDate: {
          from: this.procurementFilter.fromContractDate,
          to: this.procurementFilter.toContractDate
        }
      });
    }
    if (this.regionList && this.regionList.length !== 0) {
      this.formGroup.patchValue({
        regionList: this.regionList
      });
    }
    if (this.segmentationList) {
      this.formGroup.patchValue({
        segmentationList: this.segmentationList
      });
    }
    if (this.procurementSegQualificationList) {
      this.formGroup.patchValue({
        procurementSegQualificationList: this.procurementSegQualificationList
      });
    }
  }

  public getListVendorForTable(): void {
    this.httpClientService
      .post<PopupVendorView>(
        '/app-popup-choose-vendor/index',
        this.formGroup.value
      )
      .subscribe((response: PopupVendorView[]) => {
        this.tableResponse.setRecordList(response);
        this.selectedVendorViewList = [];
        this.tableResponse.reload();
        this.setVendorList();
        this.setStateReady();
      });
  }

  public doCheck(): void {
    this.selectedVendorViewList =
      this.tableResponse.selectedRow.getSelectedRecordList();
    this.selectedVendorViewList.forEach(vendorView => {
      const indexVendorTable: number = this.tableResponse
        .getRecordList()
        .indexOf(vendorView);
      this.tableResponse.getRecordList().splice(indexVendorTable, 1);
      this.tableResponse.getRecordList().unshift(vendorView);
    });
  }

  public setOptionList(): void {
    this.httpClientService
      .get<AppPopupChooseVendorOptionResponse>(
        '/app-popup-choose-vendor/get-option-list'
      )
      .subscribe((optionResponse: AppPopupChooseVendorOptionResponse) => {
        this.optionResponse = optionResponse;
        this.regionOptionList.setRequestValues(optionResponse.regionList);
        this.vendorTypeOptionList.setRequestValues(
          optionResponse.vendorTypeList
        );
        optionResponse.vendorCategoryList.forEach(vendorCategory => {
          vendorCategory.name =
            vendorCategory.name + ' (' + vendorCategory.vendorType.name + ') ';
        });
        this.vendorCategoryOptionList.setRequestValues(
          optionResponse.vendorCategoryList
        );
        this.segmentationOptionList.setRequestValues(
          optionResponse.segmentationList
        );
        this.currencyOptionList.setRequestValues(optionResponse.currencyList);
      });
  }

  public buildTableResponse(): void {
    this.isUserRefer =
      typeof this.isUserRefer === 'undefined' ? true : this.isUserRefer;
    if (!this.isUserRefer) {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'name',
          header: 'table.column.vendorName'
        },
        {
          field: 'address',
          header: 'table.column.address'
        },
        {
          field: 'phone',
          header: 'table.column.phone'
        },
        {
          field: 'averageRatingOverall',
          header: 'table.column.performance',
          plugins: {
            name: 'rating',
            isView: true
          },
          isSearchTable: false
        }
      ]);
    } else {
      this.tableResponse = new TableResponseModel(this.moduleCode, [
        {
          field: 'name',
          header: 'table.column.vendorName'
        },
        {
          field: 'address',
          header: 'table.column.address'
        },
        {
          field: 'phone',
          header: 'table.column.phone'
        },
        {
          field: 'userRefer',
          header: 'table.column.userRefer',
          dataMap:
            '{"true":"' +
            this.global.translateService.instant(
              'app-popup-choose-vendor.userRefer.yes'
            ) +
            '","false":"-"}',
          isSearchTable: false
        },

        {
          field: 'procurementTotal',
          header: 'table.column.totalProcurement',
          className: 'text-right',
          plugins: {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData) => {
              if (!tablePluginData.value) {
                tablePluginData.element.innerText = '0';
              }
            }
          }
        },
        {
          field: 'procurementWinTotal',
          header: 'table.column.winProcurement',
          className: 'text-right',
          plugins: {
            name: 'custom-plugin',
            after: (tablePluginData: TablePluginData) => {
              if (!tablePluginData.value) {
                tablePluginData.element.innerText = '0';
              }
            }
          }
        },
        {
          field: 'averageRatingOverall',
          header: 'table.column.performance',
          plugins: {
            name: 'rating',
            isView: true
          },
          isSearchTable: false
        }
      ]);
    }
  }

  public setVendorList(): void {
    if (this.vendorList && this.vendorList.length !== 0) {
      const recordList = this.tableResponse.getRecordList();
      let selectedRecordList: PopupVendorView[] = [];
      selectedRecordList = recordList.filter(record =>
        this.vendorList.map(vendor => vendor.id).includes(record.id)
      );

      if (selectedRecordList && selectedRecordList.length > 0) {
        selectedRecordList.forEach(vendor => {
          const indexVendorTable: number = this.tableResponse
            .getRecordList()
            .indexOf(vendor);
          this.tableResponse.getRecordList().splice(indexVendorTable, 1);
          this.tableResponse.getRecordList().unshift(vendor);
        });
      }
      this.tableResponse.setSelectedRowList(selectedRecordList);
    }
  }

  public doSetCustomData(): void {
    this.formGroup
      .get('contractValue')
      .patchValue(this.formGroup.value.contractValue?.price);
    this.tableResponse.setCustomData(this.formGroup.value);
  }

  public doApply(): void {
    const prompt = 'app-popup-choose-vendor.confirm.applyFilter.prompt';
    const title = 'app-popup-choose-vendor.confirm.applyFilter.title';
    const buttonLeft = 'app.button.cancel';
    const buttonRight = 'app-popup-choose-vendor.button.stillApply';
    const icon = null;
    const isShowCloseButton = false;
    this.global.modalService
      .confirmation(
        prompt,
        title,
        buttonLeft,
        buttonRight,
        icon,
        isShowCloseButton
      )
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.loadingBlockService.showInfo('app.msg.processing');
          if (this.formGroup.value.contractValue) {
            this.formGroup.patchValue({
              contractValue:
                this.formGroup.value.contractValue &&
                this.formGroup.value.contractValue.price
            });
          }
          this.formGroup.patchValue({
            startContractDate: this.formGroup.value.contractDate?.from
              ? new Date(this.formGroup.value.contractDate.from)
              : null,
            endContractDate: this.formGroup.value.contractDate?.to
              ? new Date(this.formGroup.value.contractDate.to)
              : null
          });
          this.tableResponse.setCustomData(this.formGroup.value);
          this.getListVendorForTable();
          if (this.formGroup.value.contractValue) {
            this.formGroup.patchValue({
              contractValue: {
                price: this.formGroup.value.contractValue
              }
            });
          }
          this.tableResponse.reload();
        }
      });
  }

  public doReset(): void {
    this.loadingBlockService.showInfo('app.msg.processing');
    this.formGroup.reset();
    this.formGroup.patchValue({
      prIdList: this.prIdList,
      procurementMethodId: this.procurementMethodId,
      excludeVendorIdList: this.excludeVendorIdList
    });
    this.setFormGroup();
    this.formGroup
      .get('contractValue')
      .patchValue(this.formGroup.value?.contractValue?.price);
    this.tableResponse.setCustomData(this.formGroup.value);
    this.getListVendorForTable();
  }

  public doChoose(): void {
    if (
      (this.tableResponse.selectedRow.getSelectedRecordList() &&
        this.tableResponse.selectedRow.getSelectedRecordList().length > 0) ||
      this.isOutputFilter
    ) {
      if (
        this.maxParticipant &&
        this.excludeVendorIdList &&
        this.excludeVendorIdList.length > 0
      ) {
        if (
          this.excludeVendorIdList.length +
            this.tableResponse.selectedRow.getSelectedRecordList().length >
          this.maxParticipant
        ) {
          this.global.alertService.showError(
            this.translateService.instant(
              'app-popup-choose-vendor.validation.youHaveReachMaxParticipantOnly'
            ) +
              ' ' +
              this.maxParticipant +
              ' ' +
              this.translateService.instant(
                'app-popup-choose-vendor.validation.vendorAllowedToInvited'
              ),
            '.popup-choose-vendor'
          );
        } else {
          this.doEmitData(
            this.tableResponse.selectedRow.getSelectedRecordList()
          );
        }
      } else {
        this.doEmitData(this.tableResponse.selectedRow.getSelectedRecordList());
      }
    } else if (this.isShowCheckBox) {
      this.global.alertService.showError(
        'app-popup-choose-vendor.validation.chooseVendor',
        '.popup-choose-vendor'
      );
    }
  }

  public doEmitData(response): void {
    if (this.isOutputFilter) {
      const object = {
        vendorList: response,
        filterList: this.tableResponse.getCustomData()
      };
      this.onChange.emit(object);
    } else {
      this.onChange.emit(response);
    }
  }
}
