import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { RouteRequestModel } from '../../core/model/route-request-model';
import { Validators } from '../../core/validators';
import { CostCenter } from './../../core/bean/cost-center';
import { Response } from './../../core/model/response-model';

@Component({
  templateUrl: './cost-center-edit-add.component.html'
})
export class CostCenterEditAddComponent extends BaseModuleComponent {
  public costCenterId: number;
  constructor(translateService: TranslateService) {
    super('cost-center', translateService);
  }
  public onInit(): void {
    this.todo = this.global.routerParams.get('todo');
    this.costCenterId = this.global.routerParams.get('costCenterId');
    this.buildFormGroup();
    this.setFormGroup();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      code: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(32)])
      ],
      name: [
        null,
        Validators.compose([Validators.required(), Validators.maxLength(100)])
      ],
      description: [null, Validators.maxLength(512)]
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .post<CostCenter>(
        '/cost-center/add-edit',
        new RouteRequestModel(this.todo, this.costCenterId)
      )
      .subscribe(costCenter => {
        if (costCenter != null) {
          this.formGroup.patchValue(costCenter);
        }
        this.setStateReady();
      });
    if (this.todo === 'edit') {
      this.formGroup.get('code').setIsView(true);
    }
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            const url =
              this.todo === 'edit'
                ? '/cost-center/update'
                : '/cost-center/insert';
            const costCenter: CostCenter = this.formGroup.value;
            this.httpClientService
              .post<Response<CostCenter>>(url, costCenter)
              .subscribe(response => {
                if (response.status === ResponseStatusModel.OK) {
                  this.global.alertService.showSuccessSavingOnNextRoute();
                  this.router.navigate(['/pages/cost-center/']);
                } else {
                  this.global.alertService.showError(response.statusText);
                  this.setStateReady();
                }
              });
          }
        });
    }
  }

  public doCancel(): void {
    this.router.navigate(['/pages/cost-center/']);
  }
}
