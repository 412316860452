import {
  Component,
  Pipe,
  PipeTransform,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { News } from '../../core/bean/news';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { Response } from '../../core/model/response-model';
import { PortalTableResponseModel } from '../../themes/promise-portal/components/app-portal-table/model/portal-table-response-model';
import { TableCatalogRequestModel } from './../../pages/catalog/view-catalog/core/components/app-table-catalog/model/table-catalog-request-model';
import { NewsPopupComponent } from './news-popup.component';
@Component({
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewsComponent extends BaseModuleComponent {
  public model: PortalTableResponseModel<News> = new PortalTableResponseModel(
    this.moduleCode
  );
  public tableRequest: TableCatalogRequestModel = new TableCatalogRequestModel(
    9
  );
  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService
  ) {
    super('news', translate);
  }

  public onInit(): void {
    this.httpClientService
      .post('/news/get-news', this.tableRequest)
      .subscribe((response: any) => {
        const { records } = response;
        this.model.setRecordList(records);
        this.model.reload();
        this.setStateReady();
      });
  }

  public doOpenPopupNews(news: News): void {
    news.viewCount = news.viewCount + 1;
    this.httpClientService
      .post<Response<News>>('/news/update-view-count', news)
      .subscribe();
    this.appPopupService.open(
      NewsPopupComponent,
      { news },
      { windowClass: 'news-popup' }
    );
  }

  public doReadMore(event: PointerEvent, news: News): void {
    event.stopPropagation();
    event.preventDefault();
    this.doOpenPopupNews(news);
  }

  public onClickFilterTable(record: any): void {
    console.log('Info: Come from onClickFilterTable');
    console.log(record);
  }
}

@Pipe({
  name: 'isShowReadMore'
})
export class ResolveIsShowReadMorePipe implements PipeTransform {
  transform(newsItemElement: HTMLElement): boolean {
    return newsItemElement.scrollHeight > newsItemElement.offsetHeight;
  }
}
