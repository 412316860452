import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { TableCatalogResponseModel } from './core/components/app-table-catalog/model/table-catalog-response-model';
@Component({
  templateUrl: './view-catalog.component.html',
  styleUrls: ['./view-catalog.component.scss']
})
export class ViewCatalogComponent extends BaseModuleComponent {
  public tableCatalogResponse: TableCatalogResponseModel<any> = new TableCatalogResponseModel('/view-catalog/index');
  constructor(translate: TranslateService) {
    super('app-view-catalog', translate);
  }
  public onInit(): void {
    this.setTableCatalogResponse();
  }

  public setTableCatalogResponse(): void {
    this.setStateReady();
  }

  public onChooseCatalogItem(catalogId: number): void {
    this.router.navigate([`/pages/view-catalog/detail/${catalogId}`]);
  }
}
