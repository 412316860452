import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TablePluginData } from '../../../../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../../../../core/components/table/model/table-response-model';
import { BaseWidgetComponent } from '../../base/base-widget.component';
import { WidgetProcurementMonitoringOverdueModel } from './widget-procurement-monitoring-overdue-model';

@Component({
  templateUrl: './widget-procurement-monitoring-overdue.component.html',
  encapsulation: ViewEncapsulation.None
})
export class WidgetProcurementMonitoringOverdueComponent extends BaseWidgetComponent {
  public tableResponse: TableResponseModel<WidgetProcurementMonitoringOverdueModel>;
  public static moduleCode = 'dashboard-widget-procurement-monitoring-overdue';
  constructor(public translateService: TranslateService) {
    super(WidgetProcurementMonitoringOverdueComponent.moduleCode);
  }

  public onInit(): void {
    this.buildTableResponse();
    this.getAndSetData();
  }

  public buildTableResponse(): void {
    this.tableResponse = new TableResponseModel(
      this.moduleCode,
      [
        {
          field: 'procurementNumber',
          header: 'table.column.procurementNumber',
          plugins: {
            name: 'hyperlink',
            onClick: this.doClick.bind(this)
          }
        },
        {
          field: 'requiredDate',
          header: 'table.column.requiredDate',
          plugins: [
            {
              name: 'date',
              format: 'short-date'
            }
          ]
        },
        {
          field: 'procurementName',
          header: 'table.column.procurementName'
        },
        {
          field: 'stage',
          header: 'table.column.stage'
        },
        {
          field: 'picName',
          header: 'table.column.picName'
        },
        {
          field: 'totalDays',
          header: 'table.column.days'
        },
        {
          field: 'status',
          header: 'table.column.status',
          plugins: [
            {
              name: 'badge',
              pill: true,
              colorMap: {
                UPCOMING: 'INFO',
                OVERDUE: 'WARNING'
              },
              colorField: 'status',
              callbacks: {
                text: (tablePluginData: TablePluginData): string => {
                  return this.translateService.instant(
                    this.moduleCode +
                      '.status.' +
                      tablePluginData.row.record.status.toLowerCase()
                  );
                }
              }
            }
          ]
        }
      ],
      { recordPerPage: 4 }
    );
  }

  public getAndSetData(): void {
    this.httpClientService
      .get<WidgetProcurementMonitoringOverdueModel[]>(
        '/widget-procurement-monitoring-overdue/index'
      )
      .subscribe((modelList: WidgetProcurementMonitoringOverdueModel[]) => {
        this.tableResponse.setRecordList(modelList);
        this.tableResponse.reload();
        this.setStateReady();
      });
  }

  public doClick(model: WidgetProcurementMonitoringOverdueModel): void {
    this.global.routerParams.clear();
    this.global.routerParams.set('procurementId', model.procurementId);
    this.global.routerParams.set('todo', 'view');
    this.router.navigate(['/pages/procurement-history/edit']);
  }
}
