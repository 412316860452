import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponentComponent } from 'src/app/core/base/angular/base-component.component';
import { Contract } from 'src/app/core/bean/contract';
import { FinesMaster } from 'src/app/core/bean/fines-master';
import { ProcurementFines } from 'src/app/core/bean/procurement-fines';
import { ProcurementVendor } from 'src/app/core/bean/procurement-vendor';
import { OptionListModel } from 'src/app/core/model/option-list-model';
import { Validators } from 'src/app/core/validators';

@Component({
  templateUrl: './app-popup-procurement-fines.component.html'
})
export class AppPopupProcurementFinesComponent extends BaseComponentComponent {
  @Input() header: string;
  @Input() public todo: string;
  @Input() public contractFinesEdit: ProcurementFines;
  @Input() public contract: Contract;
  @Input() public procurementVendor: ProcurementVendor;
  @Input() public finesList: FinesMaster[] = [];
  @Output() public onChange: EventEmitter<ProcurementFines> =
    new EventEmitter();

  public finesOptionList: OptionListModel<FinesMaster> = new OptionListModel();

  constructor() {
    super('app-popup-procurement-fines');
  }

  onInit(): void {
    this.finesOptionList.setRequestValues(this.finesList);
    this.buildFormGroup();
    this.setFormGroup();
    this.setStateReady();
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      fines: [null, Validators.compose([Validators.required()])],
      percentageValue: [
        null,
        Validators.compose([Validators.required(), Validators.max(100)])
      ],
      amount: [null],
      description: [null],
      currency: [null]
    });
  }

  public setFormGroup(): void {
    if (this.todo !== 'add') {
      this.formGroup.patchValue({
        id: this.contractFinesEdit.id,
        fines: this.contractFinesEdit.fines,
        percentageValue: this.contractFinesEdit.percentageValue,
        amount: this.contractFinesEdit.amount,
        description: this.contractFinesEdit.description,
        currency: this.contractFinesEdit.currency
      });
    }
  }
  public onChangePercentageValue(): void {
    const percentageValue = this.formGroup.value.percentageValue;
    const amount = this.contract
      ? this.contract.procurementVendor.amount
      : this.procurementVendor.amount;
    const value = +amount * (percentageValue / 100);
    this.formGroup.patchValue({
      amount: value,
      currency: this.contract
        ? this.contract.procurementVendor.currency
        : this.procurementVendor.currency
    });
  }

  public onChangeFines(): void {
    const fines = this.formGroup.value.fines;
    if (fines) {
      this.formGroup.patchValue({
        percentageValue: fines.percentageValue
      });
      this.onChangePercentageValue();
    }
  }
  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.onChange.emit(this.formGroup.value);
    }
  }
}
