import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ChartModel } from '../../app-chart/models/chart-model';
import * as actualPlanBarChartAction from '../actions/actual-plan-bar-chart.action';
import { dispatchActualPlanBarChart } from '../actions/dispatch-actual-plan-bar-chart.action';
import { ActualPlanBarChartModel } from '../model/actual-plan-bar-chart-model';
export class ActualPlanBarChartStoreService {
  public state: ActualPlanBarChartModel;
  public result: ChartModel;
  public resultChanges: EventEmitter<ChartModel> = new EventEmitter();

  public setState(state: ActualPlanBarChartModel): Observable<ChartModel> {
    this.state = state;
    return this.resultChanges;
  }

  public dispatch(action: actualPlanBarChartAction.All): void {
    this.result = dispatchActualPlanBarChart(this.state, action);
    this.resultChanges.emit(this.result);
  }
}
