import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { SensitiveDataService } from 'src/app/core/services/sensitive-data.service';
import { BaseModuleComponent } from '../../../core/base/angular/base-module.component';
import { Employee } from '../../../core/bean/employee';
import { EmployeeCountry } from '../../../core/bean/employee-country';
import { EmployeeTags } from '../../../core/bean/employee-tags';
import { File } from '../../../core/bean/file';
import { Region } from '../../../core/bean/region';
import {
  FileUploader,
  UpoadFileResponseModel
} from '../../../core/components/upload';
import { OptionListModel } from '../../../core/model/option-list-model';
import { Response } from '../../../core/model/response-model';
import { ResponseStatusModel } from '../../../core/model/response-status-model';
import { RouteRequestModel } from '../../../core/model/route-request-model';
import { Validators } from '../../../core/validators';
import { EmployeeDTO } from './dto/employee.dto';
@Component({
  templateUrl: 'employee-edit-add.component.html'
})
export class EmployeeEditAddComponent extends BaseModuleComponent {
  public employeeId: number;

  // sebelumnya
  public regionListBefore: Region[] = [];

  // option list section
  public regionList: Region[] = [];
  public skillOptionList: OptionListModel<any> = new OptionListModel();
  public interestOptionList: OptionListModel<any> = new OptionListModel();
  public genderOptionList: OptionListModel<any> = new OptionListModel();
  public zodiacOptionList: OptionListModel<any> = new OptionListModel();
  public bestProgrammingLanguageList: OptionListModel<any> =
    new OptionListModel();
  public vendorCategoryOptionList: OptionListModel<any> = new OptionListModel(
    true
  );

  public employee: Employee = new Employee();
  public uploadedFiles: UpoadFileResponseModel[] = [];
  public employeeDTO: EmployeeDTO = new EmployeeDTO();
  public maxBirthDate: Date = new Date();
  public countryCodeOptionList: OptionListModel<any> = new OptionListModel(
    true
  );

  // uploads section
  public fileUploader: FileUploader = new FileUploader(
    '/employee/',
    'employee.form.headerImage',
    this.global.appConstant.fileType.DOC_EMPLOYEE_ALL
  );
  public fileUploaders: FileUploader = new FileUploader(
    '/employee/',
    'employee.form.headerImage',
    this.global.appConstant.fileType.DOC_EMPLOYEE_ALL,
    false,
    5
  );

  constructor(translateService: TranslateService, public sensitiveDataService: SensitiveDataService) {
    super('employee', translateService);
  }

  public onInit(): void {
    this.setDataFromRouterParams();
    this.buildFormGroup();
    this.setEmployeeEditAddIsView();
    this.getAndSetEmployeeToFormGroup();
    this.setOptionListRequestValue();
  }

  public setDataFromRouterParams(): void {
    this.employeeId = this.global.routerParams.get('id');
    this.todo = this.global.routerParams.get('todo');
  }

  public setEmployeeEditAddIsView(): void {
    if (this.todo === 'view') {
      this.setViewOnly();
      this.todo = 'edit';
    }
  }

  public setOptionListRequestValue(): void {
    const genderList = [
      { id: 1, name: 'Male' },
      { id: 2, name: 'Female' }
    ];
    const interestList = [
      { id: 1, name: 'Olahraga' },
      { id: 2, name: 'Makan' },
      { id: 3, name: 'Minum' },
      { id: 4, name: 'Jalan-Jalan' },
      { id: 5, name: 'Bermain Musik' },
      { id: 6, name: 'Ngoding' },
      { id: 7, name: 'Bermain Game' }
    ];
    const zodiacList = [
      { id: 1, name: 'Pisces' },
      { id: 2, name: 'Leo' },
      { id: 3, name: 'Libra' },
      { id: 4, name: 'Scorpio' }
    ];
    const skillOptionList = [
      { name: 'Python', isChecked: true },
      { name: 'Ruby', isChecked: false }
    ];
    const bestProgrammingLanguageList = [
      { name: 'Java Script', isChecked: true },
      { name: 'Java', isChecked: this.employee.java }
    ];
    this.genderOptionList.setRequestValues(genderList);
    this.interestOptionList.setRequestValues(interestList);
    this.zodiacOptionList.setRequestValues(zodiacList);
    this.skillOptionList.setRequestValues(skillOptionList);
    this.bestProgrammingLanguageList.setRequestValues(
      bestProgrammingLanguageList
    );
    this.formGroup
      .get('bestProgrammingLanguage')
      .patchValue(bestProgrammingLanguageList);
    this.formGroup.get('skills').patchValue(skillOptionList);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [null],
      name: [null, Validators.required()],
      gender: [null, Validators.required()],
      address: [null, Validators.required()], // should not to required
      salary: [null, Validators.required()],
      region: [null, Validators.required()],
      multipleRegion: [null, Validators.required()],
      email: [
        null,
        Validators.compose([Validators.required(), Validators.email()])
      ],
      password: [
        null,
        Validators.compose([Validators.required(), Validators.password()])
      ],
      about: [null],
      isHasPet: [false, Validators.required()],
      vendorCategory: [null, Validators.required()],
      interest: [null, Validators.required()],
      tags: [null, Validators.required()],
      yearOfBirth: [
        null,
        Validators.compose([Validators.required(), Validators.actualLength(4)])
      ],
      startDate: [null, Validators.required()],
      endDate: [null, Validators.required()],
      uom: [null, Validators.required()],
      phone: [null, Validators.required()],
      birthDate: [null, Validators.required()],
      makeSure: [false, Validators.isChecked()],
      bestProgrammingLanguage: [null, Validators.isChecked(2)],
      skills: [null, Validators.isChecked()],
      zodiac: [null, Validators.required()],
      dokumenKTP: [null, Validators.required('File tidak boleh kosong')],
      dokumenPengadaan: [null, Validators.required('File tidak boleh kosong')],
      administration: [null],
      operator: [null],
      managerial: [null],
      donation: [
        null,
        Validators.compose([
          Validators.required(),
          Validators.max(100, 'Tidak Boleh Lebih Dari 100%')
        ])
      ],
      rating: [null],
      location: [null, Validators.required()],
      deadline: [null, Validators.required()],
      monthFrom: [null, Validators.required()],
      monthTo: [null, Validators.required()],
      npwp: [
        null,
        Validators.compose([Validators.required(), Validators.npwp()])
      ],
      docA: [null],
      docB: [null]
    });
  }

  public getAndSetEmployeeToFormGroup(): void {
    const request: RouteRequestModel = new RouteRequestModel(
      this.todo,
      this.employeeId
    );
    this.httpClientService
      .post<EmployeeDTO>('/employee/add-edit', request)
      .subscribe((employeeDTO: EmployeeDTO) => {
        this.employeeDTO = employeeDTO;
        this.vendorCategoryOptionList.setRequestValues(
          employeeDTO.vendorCategoryOptionList
        );
        if (this.employeeDTO.employee != null) {
          this.employee = employeeDTO.employee;

          this.formGroup.patchValue({
            region: employeeDTO.regionParentHierarchyList
          });

          this.setFormGroup();
        }
        this.countryCodeOptionList.setRequestValues(
          employeeDTO.regionPhoneList
        );

        this.setStateReady();
      });
  }

  public setFormGroup(): void {
    this.employee.employeeCountryList.forEach(employeeCountry => {
      this.regionList.push(employeeCountry.region);
    });

    // put tags
    const tags: string[] = [];
    this.employee.employeeTagsList.forEach(employeeTags => {
      tags.push(employeeTags.name);
    });

    // put salary
    let salary: any = this.employee.salary;
    if (this.employee.salary !== null) {
      salary = this.global.converterService.convertMoney(salary.toString());
    }

    // const vendorCategoryIndex = this.employeeDTO.vendorCategoryOptionList.findIndex((category) => category.name === this.employee.vendorCategory.name);
    // const { regionList: multipleRegion } = this;
    // const vendorCategory = this.employeeDTO.vendorCategoryOptionList[vendorCategoryIndex];

    // put Npwp single upload
    this.fileUploader.setFile(this.employee.npwpfile);

    // put employee procurement document
    const fileList: File[] = [];
    this.employee.employeeProcurementDocList.forEach(employeeProcDocFile => {
      fileList.push(employeeProcDocFile.file);
    });
    this.fileUploaders.setFileList(fileList);

    const birthDate: Date = new Date(this.employee.birthDate);
    const startDate: Date = new Date(this.employee.startDate);
    const endDate: Date = new Date(this.employee.endDate);

    this.formGroup.patchValue({
      id: this.employee.id,
      name: this.employee.name,
      gender: this.employee.gender,
      address: this.employee.address,
      salary,
      email: this.employee.email,
      password: this.employee.password,
      about: this.employee.about,
      interest: this.employee.employeeInterestList,
      multipleRegion: this.regionList,
      // vendorCategory: vendorCategory,
      tags,
      isHasPet: this.employee.isHasPet,
      uom: this.employee.uom,
      startDate,
      endDate,
      zodiac: this.employee.zodiac,
      yearOfBirt: this.employee.yearOfBirth,
      makeSure: this.employee.makeSure,
      birthDate,
      phone: {
        country: this.employee.regionPhone,
        number: this.employee.phoneNumber
      },
      dokumenPengadaan: fileList,
      dokumenKTP: this.fileUploader
    });
  }

  public doSave(): void {
    this.validate();
    if (this.formGroup.valid) {
      this.global.modalService
        .saveConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.saveEmployee();
          }
        });
    }
  }

  public saveEmployee(): void {
    const employee: Employee = this.global.copyFormAttributeToModel(
      new Employee(),
      this.formGroup
    );

    employee.password = this.sensitiveDataService.encrypt([employee.password]);

    const interests: Array<string> = this.formGroup.get('interest').value;
    const interestText = interests.map((i: any) => i.name).join(', ');
    employee.interest = interestText.trim();
    employee.employeeInterestList = this.formGroup.get('interest').value;

    // put salary and Unconvert to String
    employee.salary =
      employee.salary !== null
        ? +this.global.converterService.uConvertMoney(
            employee.salary.toString()
          )
        : null;

    // vendorCategory to vendor category Text
    const vendorCategoryList = [this.formGroup.get('vendorCategory').value];
    vendorCategoryList.forEach(category => {
      employee.vendorCategory = category.name;
    });

    // put region to employee Country
    const employeeCountryList: EmployeeCountry[] = [];
    this.regionList = this.formGroup.get('multipleRegion').value;
    this.regionList.forEach(region => {
      const employeeCountry = new EmployeeCountry();
      employeeCountry.region = region;
      employeeCountryList.push(employeeCountry);
    });
    employee.employeeCountryList = employeeCountryList;

    // put tagsList to tags text
    const tags: Array<string> = this.formGroup.get('tags').value;
    const tagsText = tags.join(', ');
    employee.tags = tagsText.trim();

    // put tagsList
    const employeeTagsList: EmployeeTags[] = [];
    tags.forEach((tag: string) => {
      const employeeTags = new EmployeeTags();
      employeeTags.name = tag;
      employeeTags.id = null;
      employeeTagsList.push(employeeTags);
    });
    employee.employeeTagsList = employeeTagsList;

    // put country code phone
    employee.regionPhone = this.formGroup.get('phone').value.country;
    employee.phoneNumber = this.formGroup.get('phone').value.number;

    // put single upload NPWP
    this.employeeDTO.fileNPWPList = this.fileUploader.fileObjectList;

    // put mutliple upload Employee Procurement Document
    this.employeeDTO.fileEmployeeProcDocList =
      this.fileUploaders.fileObjectList;

    // put region/country just last of index
    const regionList: Region[] = this.formGroup.get('region').value;
    employee.region = regionList[regionList.length - 1];

    // put programming language as vertical checkbox list
    employee.javaScript = this.formGroup.get(
      'bestProgrammingLanguage'
    ).value[0].isChecked;
    employee.java = this.formGroup.get(
      'bestProgrammingLanguage'
    ).value[1].isChecked;

    // put skills as horizontal checkbox list
    employee.python = this.formGroup.get('skills').value[0].isChecked;
    employee.ruby = this.formGroup.get('skills').value[1].isChecked;

    this.employeeDTO.employee = employee;

    const url: string = this.todo === 'add' ? 'insert' : 'update';
    this.httpClientService
      .post<Response<EmployeeDTO>>('/employee/' + url, this.employeeDTO)
      .subscribe((response: Response<EmployeeDTO>) => {
        if (response.status === ResponseStatusModel.OK) {
          this.global.alertService.showSuccessSavingOnNextRoute();
          this.router.navigate(['/pages/employee']);
        } else {
          this.global.alertService.showError(response.statusText);
        }
      });
  }

  public onProfileImageUpload(event: string): void {
    this.log.debug(event);
    /*const reponse: any = JSON.parse(event.xhr.response);
    this.employee.profileImgFile =
      reponse.uploadFileResponse[0].uploadedFileName;*/
  }

  public doBack(): void {
    this.router.navigate(['/pages/employee']);
  }
}
