<app-layout [isLoading]="formLoading">
  <div class="row main-row">
    <div class="col-lg-12 mb-4">
      <app-card
        header="pq.title"
        detail="app.action.{{ todo }}"
        [isLoading]="formLoading"
        [isSaving]="formSaving"
      >
        <form class="form-horizontal" [formGroup]="formGroup" novalidate>
          <div class="form-group row text-sm-right required">
            <label class="col-sm-3 control-label">{{
              'pq.form.code' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-field
                size="SM"
                autofocus="true"
                formControlName="code"
                maxLength="32"
                type="code"
              ></app-text-field>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label text-right required">{{
              'pq.form.name' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <ng-container formArrayName="translationList">
                <div
                  class="form-group row"
                  *ngFor="
                    let translation of translationList.controls;
                    let i = index
                  "
                  [formGroupName]="i"
                >
                  <div [ngStyle]="{ 'padding-left': '1%' }"></div>
                  <label class="col-sm-0 text-left control-label">{{
                    translationListLabel[i]
                  }}</label>
                  <div class="col-sm-4 text-left">
                    <app-text-field
                      size="XL"
                      formControlName="value"
                      maxLength="128"
                    ></app-text-field>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="form-group row text-sm-right">
            <label class="col-sm-3 control-label">{{
              'pq.form.description' | translate
            }}</label>
            <div class="col-sm-9 text-left">
              <app-text-area formControlName="description" maxLength="512">
              </app-text-area>
            </div>
          </div>

          <div class="row justify-content-center mt-5">
            <app-button
              color="LIGHT"
              [outline]="true"
              class="mr-1"
              (onClick)="doCancel()"
            >
              {{ 'app.button.back' | translate }}
            </app-button>
            <app-button color="PRIMARY" (onClick)="doSave()">
              {{ 'app.button.save' | translate }}
            </app-button>
          </div>
        </form>
      </app-card>
    </div>
  </div></app-layout
>
