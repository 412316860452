import { Uploader } from '../model/uploader';
import { FileUploader } from '../model/v1/file.uploader';
export function createUploaderModel(
  uploader: Uploader,
  fileUploader: FileUploader,
  options: InputOptions
): Uploader {
  if (fileUploader) {
    const { url, fileTypeCode, maxFile } = fileUploader;
    uploader = new Uploader(url, fileTypeCode);
    uploader.options.maxFile = maxFile;
  } else if (!fileUploader && !uploader) {
    uploader = new Uploader(options.stringUrl, options.fileTypeCode);
    uploader.options.maxFile = options.maxFile;
  }
  return uploader;
}

interface InputOptions {
  fileTypeCode: string;
  stringUrl: string;
  maxFile: number;
}
