<app-popup [header]="model.header" [isLoading]="model.isLoading">
  <ng-container
    *ngTemplateOutlet="model.treePopupContentTmpl; context: {$implicit: model.formGroup }">
  </ng-container>
  <ng-template #modalFooter let-activeModal>
    <div class="button-group button-group-right">
      <app-button color="SECONDARY" (onClick)="activeModal.close(true)">{{
        'app.button.cancel' | translate }}</app-button>
      <app-button (onClick)="doSave()">{{ 'app.button.save' | translate }}
      </app-button>
    </div>
  </ng-template>
</app-popup>