import { Currency } from '../../core/bean/currency';
import { File } from '../../core/bean/file';
import { ItemType } from '../../core/bean/item-type';
import { RfqCancellation } from '../../core/bean/rfq-cancellation';
import { RfqItem } from '../../core/bean/rfq-item';
import { RfqVendorItem } from '../../core/bean/rfq-vendor-item';

export class RfqResponseResponse {
  fileList: File[] = [];
  rfqItemList: RfqItem[] = [];
  responseFileList: File[] = [];
  itemTypeList: ItemType[] = [];
  currencyList: Currency[] = [];
  rfqVendorItemList: RfqVendorItem[] = [];
  companyCurrency: Currency = new Currency();
  rfqCancellation: RfqCancellation = new RfqCancellation();
}
