import { HttpClientService } from '../../../services/http-client.service';
import { ArrayUtils } from '../../../utils';
import { TablePageModel } from '../../app-table/model/table-page-model';
import { TableRecord } from '../model/table-record';
import { TableState } from '../model/table-state';
import { createTableCustomData } from './create-table-custom-data';
export const resolveTableRecordChildrens = (
  state: TableState,
  record: TableRecord
): Promise<{ recordList: TableRecord[]; totalRecord: number }> => {
  return new Promise(resolve => {
    if (state.model.options.group.childrensField) {
      const chilrenRecordList: Array<any> =
        ArrayUtils.resolveFieldData(
          record.record,
          state.model.options.group.childrensField
        ) || [];
      resolve({
        recordList: chilrenRecordList.map(
          childrenRecord => new TableRecord(childrenRecord, [])
        ),
        totalRecord: state.model.records.length
      });
    } else {
      record.tableRequest.globalFilter = state.tableRequest.globalFilter;
      record.tableRequest.cols = state.tableRequest.cols;
      record.tableRequest.customData = createTableCustomData({
        id: record.record.id
      });
      const httpClientService = state.injector.get(HttpClientService);
      httpClientService
        .post(state.stringUrl + '/child', record.tableRequest)
        .subscribe((page: TablePageModel<object>) => {
          record.isShowButtonLoadMore =
            record.childrens.length < page.totalRecords;
          resolve({
            recordList: page.records.map(
              pageRecord => new TableRecord(pageRecord, [])
            ),
            totalRecord: page.totalRecords
          });
        });
    }
  });
};
