import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { PaymentWorklistStatus } from 'src/app/core/bean/payment-worklist-status';
import { BaseModuleComponent } from '../../core/base/angular/base-module.component';
import { Currency } from '../../core/bean/currency';
import { PaymentVoucher } from '../../core/bean/payment-voucher';
import { PaymentVoucherBilling } from '../../core/bean/payment-voucher-billing';
import { PaymentVoucherOtherCost } from '../../core/bean/payment-voucher-other-cost';
import { PaymentVoucherTax } from '../../core/bean/payment-voucher-tax';
import { AppPopupAddBudgetItemService } from '../../core/components/app-popup/app-popup-add-budget-item/app-popup-add-budget-item.service';
import { AppPopupService } from '../../core/components/app-popup/app-popup.service';
import { TableRow } from '../../core/components/table/domain/table-row';
import { TablePluginData } from '../../core/components/table/interface/table-plugin-data';
import { TableResponseModel } from '../../core/components/table/model/table-response-model';
import { OptionListModel } from '../../core/model/option-list-model';
import { Response } from '../../core/model/response-model';
import { ResponseStatusModel } from '../../core/model/response-status-model';
import { SnackbarService } from '../../core/services/snackbar.services';
import { Validators } from '../../core/validators';
import { BudgetItemView } from '../../core/view/entity/bean/budget-item-view';
import { PaymentVoucherEditResponse } from './payment-voucher-edit-response';
import { PaymentVoucherModel } from './payment-voucher-model';
import { PaymentVoucherPopupOtherCostComponent } from './payment-voucher-popup-other-cost.component';
import { PaymentVoucherPopupTaxComponent } from './payment-voucher-popup-tax.component';
import { PaymentVoucherUpdateRequest } from './payment-voucher-update-request';

@Component({
  templateUrl: './payment-voucher-edit.component.html',
  styleUrls: ['./payment-voucher-edit.component.scss']
})
export class PaymentVoucherEditComponent extends BaseModuleComponent {
  public tableResponseTax: TableResponseModel<PaymentVoucherTax>;
  public tableResponseBillingValue: TableResponseModel<PaymentVoucherBilling>;
  public tableResponseOtherCost: TableResponseModel<PaymentVoucherOtherCost>;
  public tableResponsePaidToVendor: TableResponseModel<PaymentVoucherModel>;

  public paymentVoucherEditResponse: PaymentVoucherEditResponse;
  public paymentVoucherTaxList: PaymentVoucherTax[] = [];
  public paymentVoucherOtherCostList: PaymentVoucherOtherCost[] = [];
  public paymentVoucherBillingList: PaymentVoucherBilling[] = [];
  public budgetItemViewList: BudgetItemView[] = [];
  public paymentVoucher: PaymentVoucher;
  public paymentWorklistStatus: PaymentWorklistStatus;
  public paymentId: number;
  public approvalModelPrcsId: number;
  public totalPaid: number;
  public totalTaxBase: number;
  public totalVAT: number;
  public totalIncomeTax: number;
  public totalOtherCost: number;
  public totalOtherCostDebit: number;
  public totalOtherCostCredit: number;
  public totalPaidToVendor: number;
  public includeVAT = true;
  public urlBackOutside: string;

  public currencyOptionList: OptionListModel<Currency> = new OptionListModel(
    true
  );

  public badgeColor = {
    DRAFT: 'SECONDARY',
    REVISION: 'FEEDBACK',
    WAITING_APPROVAL: 'WARNING',
    NEW: 'INFO'
  };

  constructor(
    translate: TranslateService,
    public appPopupService: AppPopupService,
    public appPopupAddBudgetItemService: AppPopupAddBudgetItemService,
    public snackbarService: SnackbarService
  ) {
    super('payment-voucher', translate);
  }
  public onInit(): void {
    this.getDataFromRouterParams();
    this.buildFormGroup();
    this.setFormGroup();
  }

  public getDataFromRouterParams(): void {
    this.todo = this.global.routerParams.get('todo');
    this.paymentWorklistStatus = this.global.routerParams.get(
      'paymentWorklistStatus'
    );
    this.paymentId = this.global.routerParams.get('paymentId');
    if (!this.paymentId) {
      this.paymentId = this.global.routerParams.get('objectId');
    }
    this.approvalModelPrcsId = this.global.routerParams.get(
      'approvalModelPrcsId'
    );
    this.urlBackOutside = this.global.routerParams.get('urlBackOutside');
  }

  public buildTableResponse(): void {
    this.tableResponseBillingValue = new TableResponseModel(this.moduleCode, [
      { field: 'coa.code', header: 'table.column.coa' },
      { field: 'costCenter.code', header: 'table.column.costCenter' },
      {
        field: 'taxPercentageValue',
        header: 'table.column.taxPercentage',
        plugins: {
          name: 'text-field',
          type: 'decimal',
          groupText: '%',
          validators: Validators.required(),
          onChange: this.onChangeTaxPercentage.bind(this),
          onInput: this.onChangeTaxPercentage.bind(this),
          isView: this.todo === 'view' ? true : false
        },
        className: 'text-right'
      },
      {
        field: 'taxBaseAmount',
        header: 'table.column.taxBase',
        plugins: {
          name: 'currency',
          optionList: this.currencyOptionList,
          isView: this.includeVAT || this.todo === 'view' ? true : false,
          validators: Validators.required(),
          onChange: this.onChangeTaxBase.bind(this),
          onInput: this.onChangeTaxBase.bind(this)
        },
        className: 'text-right'
      },
      {
        field: 'vatAmount',
        header: 'table.column.vat',
        plugins: {
          name: 'currency',
          optionList: this.currencyOptionList,
          validators: Validators.required(),
          isView: true
        },
        className: 'text-right'
      },
      {
        field: 'totalAmount',
        header: 'table.column.totalPaid',
        plugins: {
          name: 'currency',
          optionList: this.currencyOptionList,
          onChange: this.onChangeTotalAmount.bind(this),
          onInput: this.onChangeTotalAmount.bind(this),
          validators: Validators.required(),
          isView: this.includeVAT && this.todo !== 'view' ? false : true
        },
        className: 'text-right'
      }
    ]);
    const formGroup = this.formBuilder.group({
      table: this.tableResponseBillingValue.formArray
    });
    this.voucherBillingList.push(formGroup);

    this.tableResponseTax = new TableResponseModel(this.moduleCode, [
      {
        field: 'tax.name',
        header: 'table.column.taxName',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClickTax.bind(this)
        }
      },
      { field: 'coa.code', header: 'table.column.coa' },
      { field: 'costCenter.code', header: 'table.column.costCenter' },
      {
        field: 'taxPercentageValue',
        header: 'table.column.taxPercentage',
        className: 'text-right',
        plugins: {
          name: 'custom-plugin',
          after: (tablePlugin: TablePluginData): void => {
            if (
              tablePlugin.value &&
              tablePlugin.row.record.taxMethod.code ===
                this.global.appConstant.cm.TAX_METHOD_PERCENT
            ) {
              tablePlugin.element.innerText =
                this.global.converterService.convertDecimal(
                  tablePlugin.element.innerText
                ) + '%';
            } else {
              tablePlugin.element.innerText =
                this.global.converterService.convertDecimal(
                  tablePlugin.element.innerText
                ) +
                ' ' +
                this.paymentVoucherEditResponse.currencyList[0].code;
            }
          }
        }
      },
      {
        field: 'taxBaseAmount',
        header: 'table.column.taxBase',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      },
      {
        field: 'incomeTaxAmount',
        header: 'table.column.incomeTax',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      }
    ]);

    this.tableResponseOtherCost = new TableResponseModel(this.moduleCode, [
      {
        field: 'name',
        header: 'table.column.itemName',
        plugins: {
          name: 'hyperlink',
          onClick: this.doClickOtherCost.bind(this)
        }
      },
      { field: 'coa.code', header: 'table.column.coa' },
      { field: 'costCenter.code', header: 'table.column.costCenter' },
      {
        field: 'balanceType',
        header: 'table.column.transactionStatus',
        plugins: {
          name: 'custom-plugin',
          after: (tablePlugin: TablePluginData): void => {
            if (tablePlugin.value === 1) {
              tablePlugin.element.innerText =
                this.global.translateService.instant(
                  'payment-voucher.balanceType.debit'
                );
            } else {
              tablePlugin.element.innerText =
                this.global.translateService.instant(
                  'payment-voucher.balanceType.credit'
                );
            }
          }
        }
      },
      {
        field: 'amount',
        header: 'table.column.value',
        plugins: {
          name: 'default',
          type: 'currency'
        },
        className: 'text-right'
      }
    ]);

    this.tableResponsePaidToVendor = new TableResponseModel(this.moduleCode, [
      {
        field: 'costName',
        header: 'table.column.costName',
        plugins: [
          {
            name: 'default'
          },
          {
            name: 'icon',
            className: 'pir pi-info-circle',
            isShowIconFn: this.showIconInfo.bind(this),
            tooltip: 'payment-voucher.detail.table.tooltip.isWAPU',
            text: this.global.translateService.instant(
              'payment-voucher.detail.table.info.isWAPU'
            )
          }
        ]
      },
      {
        field: 'totalAmount',
        header: 'table.column.total',
        plugins: {
          name: 'custom-plugin',
          after: (tablePlugin: TablePluginData): void => {
            if (tablePlugin.value) {
              tablePlugin.element.innerText =
                this.global.converterService.convertMoney(+tablePlugin.value) +
                ' ' +
                this.paymentVoucherEditResponse.currencyList[0].code;
              if (
                tablePlugin.row.record.balanceType ===
                this.global.appConstant.cm.BALANCE_TYPE_CREDIT
              ) {
                tablePlugin.element.innerText =
                  '- ' + tablePlugin.element.innerText;
                tablePlugin.element.className = 'text-danger text-right';
              }
            } else {
              tablePlugin.element.innerText =
                '0 ' + this.paymentVoucherEditResponse.currencyList[0].code;
            }
          }
        },
        className: 'text-right'
      },
      {
        field: 'balanceType',
        header: 'table.column.status',
        plugins: [
          {
            name: 'badge',
            field: 'balanceTypeName',
            colorMap: {
              1: 'INFO',
              2: 'DANGER'
            },
            pill: false,
            colorField: 'balanceType',
            className: 'badge-catalog ml-3'
          }
        ]
      }
    ]);
  }

  public buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      voucherNumber: [null, Validators.required()],
      voucherDate: [new Date(), Validators.required()],
      includeVat: [true],
      paymentVoucherBillingList: this.formBuilder.array([])
    });
  }

  public setFormGroup(): void {
    this.httpClientService
      .get<PaymentVoucherEditResponse>(
        '/payment-voucher/edit/' + this.paymentId
      )
      .subscribe(response => {
        if (this.todo === 'view') {
          this.formGroup.setIsView(true);
        }
        this.paymentVoucherEditResponse = response;
        this.paymentVoucher = this.paymentVoucherEditResponse.paymentVoucher;
        if (this.paymentVoucher) {
          this.paymentWorklistStatus =
            this.paymentVoucher.payment.paymentWorklistStatus;
          this.formGroup.patchValue({
            voucherNumber: this.paymentVoucher.code
          });

          if (this.paymentVoucher.voucherDate !== null) {
            this.formGroup.patchValue({
              voucherDate: this.paymentVoucher.voucherDate
            });
          }

          if (this.paymentVoucher.isIncludeVat !== null) {
            this.includeVAT = this.paymentVoucher.isIncludeVat;
            this.formGroup.patchValue({
              includeVat: this.paymentVoucher.isIncludeVat
            });
          }
        }
        this.buildTableResponse();
        this.paymentVoucherBillingList =
          this.paymentVoucherEditResponse.paymentVoucherBillingList;
        this.paymentVoucherTaxList =
          this.paymentVoucherEditResponse.paymentVoucherTaxList;
        this.paymentVoucherOtherCostList =
          this.paymentVoucherEditResponse.paymentVoucherOtherCostList;
        this.budgetItemViewList =
          this.paymentVoucherEditResponse.budgetItemViewList;
        this.paymentVoucherBillingList.forEach((data: any) => {
          const taxBaseAmount = {
            price: data.taxBaseAmount,
            currency: this.paymentVoucherEditResponse.currencyList[0]
          };
          const vatAmount = {
            price: data.vatAmount,
            currency: this.paymentVoucherEditResponse.currencyList[0]
          };
          const totalAmount = {
            price: data.totalAmount,
            currency: this.paymentVoucherEditResponse.currencyList[0]
          };

          data.taxBaseAmount = taxBaseAmount;
          data.vatAmount = vatAmount;
          data.totalAmount = totalAmount;
        });
        this.tableResponseBillingValue.setRecordList(
          this.paymentVoucherBillingList
        );
        this.tableResponseBillingValue.reload();
        this.tableResponseTax.setRecordList(this.paymentVoucherTaxList);
        this.tableResponseTax.reload();
        this.tableResponseOtherCost.setRecordList(
          this.paymentVoucherOtherCostList
        );
        this.tableResponseOtherCost.reload();
        this.setTablePaidToVendor();
        this.setOptionList();
        this.setTotalData();
        this.setStateReady();
      });
  }

  public setOptionList(): void {
    this.currencyOptionList.setRequestValues(
      this.paymentVoucherEditResponse.currencyList
    );
  }

  public onAfterRowBillingCreated(tableRow: TableRow): void {
    const amountBudget = tableRow.record.budgetAllocation.availableBudget;
    tableRow.formGroup
      .get('totalAmount')
      .setValidators([
        Validators.max(
          amountBudget,
          'payment-voucher.detail.form.validation.maxBudget'
        )
      ]);
    tableRow.formGroup.get('totalAmount').updateValueAndValidity();
    this.formGroupService.validateAllFormFields(tableRow.formGroup);
    this.onChangeIncludeVat(this.includeVAT);
    this.setTotalData();
  }

  public onChangeDetailsInput(): void {
    console.log(this.formGroup);
  }

  public doViewRequestDetail(): void {
    this.global.routerParams.set('todo', 'view');
    this.global.routerParams.set('paymentId', this.paymentId);
    this.global.routerParams.set(
      'paymentBillingId',
      this.paymentVoucherEditResponse.paymentBillingIdList[0]
    );
    this.global.routerParams.set('urlBackOutside', 'close-window');

    localStorage.setItem('todo', 'view');
    localStorage.setItem('paymentId', this.paymentId.toString());
    localStorage.setItem(
      'paymentBillingId',
      this.paymentVoucherEditResponse.paymentBillingIdList[0].toString()
    );
    localStorage.setItem('urlBackOutside', 'close-window');
    if (
      this.paymentVoucherEditResponse.requestType &&
      this.paymentVoucherEditResponse.requestType ===
        this.global.appConstant.cm.USER_TYPE_REQUEST_PAYMENT_INTERNAL
    ) {
      window.open('/pages/internal-payment-request/detail');
    } else {
      window.open('/pages/vendor-payment-request/detail');
    }
  }

  public doViewAgreementDetail(): void {
    localStorage.setItem('urlBackOutside', 'close-window');
    if (
      this.paymentVoucherEditResponse.moduleCode ===
      this.global.appConstant.core.MCODE_ORDER
    ) {
      localStorage.setItem(
        'objectId',
        this.paymentVoucherEditResponse.objectId.toString()
      );
      localStorage.setItem('todo', 'view');

      window.open('/pages/order/edit');
    } else {
      localStorage.setItem('todo', 'view');
      localStorage.setItem(
        'contractId',
        this.paymentVoucherEditResponse.objectId.toString()
      );
      localStorage.setItem(
        'contract',
        JSON.stringify(this.paymentVoucherEditResponse.contract)
      );
      window.open('/pages/contract-history/detail');
    }
  }

  public doAddBudget(): void {
    this.tableResponseBillingValue.formArray.value.forEach(
      (voucherBilling, index) => {
        this.paymentVoucherBillingList[index].taxPercentageValue =
          voucherBilling.taxPercentageValue;
        this.paymentVoucherBillingList[index].taxBaseAmount =
          +voucherBilling.taxBaseAmount?.price;
        this.paymentVoucherBillingList[index].vatAmount =
          voucherBilling.vatAmount?.price;
        this.paymentVoucherBillingList[index].totalAmount =
          +voucherBilling.totalAmount?.price;
      }
    );

    this.appPopupAddBudgetItemService
      .open(
        true,
        this.budgetItemViewList,
        new Map<number, number>(),
        new Map<number, number>(),
        this.paymentId,
        this.paymentVoucherEditResponse.paymentBillingIdList
      )
      .subscribe((budgetItemViewList: BudgetItemView[]) => {
        budgetItemViewList.forEach(budgetItemView => {
          const paymentBilling = new PaymentVoucherBilling();
          paymentBilling.budgetAllocation = budgetItemView.budgetAllocation;
          paymentBilling.coa = budgetItemView.budgetAllocation.coa;
          paymentBilling.costCenter =
            budgetItemView.budgetAllocation.costCenter;
          paymentBilling.taxPercentageValue =
            this.paymentVoucherEditResponse.companyPPN;
          paymentBilling.budgetAllocation.availableBudget =
            budgetItemView.remainingAmount;

          const index = this.paymentVoucherBillingList.findIndex(
            voucherBilling =>
              voucherBilling.budgetAllocation.id ===
              budgetItemView.budgetAllocation.id
          );
          if (index === -1) {
            this.paymentVoucherBillingList.push(paymentBilling);
          }
        });
        this.budgetItemViewList = budgetItemViewList;

        this.paymentVoucherBillingList.forEach((data: any) => {
          if (data.totalAmount) {
            const taxBaseAmount = {
              price: data.taxBaseAmount,
              currency: this.paymentVoucherEditResponse.currencyList[0]
            };
            const vatAmount = {
              price: data.vatAmount,
              currency: this.paymentVoucherEditResponse.currencyList[0]
            };
            const totalAmount = {
              price: data.totalAmount,
              currency: this.paymentVoucherEditResponse.currencyList[0]
            };

            data.taxBaseAmount = taxBaseAmount;
            data.vatAmount = vatAmount;
            data.totalAmount = totalAmount;
          }
        });

        this.tableResponseBillingValue.formArray = this.formBuilder.array([]);
        this.tableResponseBillingValue.setRecordList(
          this.paymentVoucherBillingList
        );
        this.tableResponseBillingValue.reload();
        this.setTotalData();
      });
  }

  public doDeleteBilling(event): void {
    this.global.modalService
      .newDeleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          let paymentVoucherBilling = new PaymentVoucherBilling();
          event.selectedRecordList.forEach((record: PaymentVoucherBilling) => {
            const indexOfRecord = this.paymentVoucherBillingList.findIndex(
              r => r === record
            );
            paymentVoucherBilling =
              this.paymentVoucherBillingList[indexOfRecord];
            this.paymentVoucherBillingList.splice(indexOfRecord, 1);
          });
          const indexBudget = this.budgetItemViewList.findIndex(
            budget => budget.id === paymentVoucherBilling.budgetAllocation.id
          );
          this.budgetItemViewList.splice(indexBudget, 1);
          this.tableResponseBillingValue.formArray = this.formBuilder.array([]);
          this.tableResponseBillingValue.setRecordList(
            this.paymentVoucherBillingList
          );
          this.tableResponseBillingValue.reload();
          this.setTotalData();
        }
      });
  }

  public onChangeIncludeVat(value: boolean): void {
    if (value) {
      this.tableResponseBillingValue.formArray.controls.forEach(formGroup => {
        if (this.todo !== 'view') {
          formGroup.get('totalAmount').setIsView(false);
          formGroup.get('taxBaseAmount').setIsView(true);
        }

        if (value !== this.includeVAT) {
          const taxBaseAmount = formGroup.get('taxBaseAmount').value;
          if (taxBaseAmount) {
            const total = +taxBaseAmount.price;
            const taxPercentage = +formGroup.get('taxPercentageValue').value;
            const taxBase = total / (1 + taxPercentage * 0.01);
            const taxBaseFixed = +taxBase.toFixed(2);
            const vat = (+taxBase * taxPercentage * 0.01).toFixed(2);

            formGroup.patchValue({
              taxBaseAmount: {
                price: +taxBaseFixed,
                currency: this.paymentVoucherEditResponse.currencyList[0]
              },
              vatAmount: {
                price: +vat,
                currency: this.paymentVoucherEditResponse.currencyList[0]
              },
              totalAmount: {
                price: total,
                currency: this.paymentVoucherEditResponse.currencyList[0]
              }
            });
          }
        }
      });
    } else {
      this.tableResponseBillingValue.formArray.controls.forEach(formGroup => {
        if (this.todo !== 'view') {
          formGroup.get('totalAmount').setIsView(true);
          formGroup.get('taxBaseAmount').setIsView(false);
        }

        if (value !== this.includeVAT) {
          const totalAmount = formGroup.get('totalAmount').value;
          if (totalAmount) {
            const taxBase = +totalAmount.price;
            const taxPercentage = +formGroup.get('taxPercentageValue').value;
            const vat = (+taxBase * taxPercentage * 0.01).toFixed(2);
            const total = +taxBase + +vat;
            formGroup.patchValue({
              taxBaseAmount: {
                price: taxBase,
                currency: this.paymentVoucherEditResponse.currencyList[0]
              },
              vatAmount: {
                price: +vat,
                currency: this.paymentVoucherEditResponse.currencyList[0]
              },
              totalAmount: {
                price: +total,
                currency: this.paymentVoucherEditResponse.currencyList[0]
              }
            });
          }
        }
      });
    }
    this.includeVAT = value;
    this.setTotalData();
  }

  public onChangeTaxBase(data): void {
    const formGroup =
      this.tableResponseBillingValue.currentRowChange.row.formGroup;
    if (data) {
      const taxBase = data.price;
      const taxPercentage = +formGroup.get('taxPercentageValue').value;
      if (taxBase) {
        const vat = (+taxBase * taxPercentage * 0.01).toFixed(2);
        const total = +taxBase + +vat;
        formGroup.patchValue({
          taxBaseAmount: {
            price: taxBase,
            currency: this.paymentVoucherEditResponse.currencyList[0]
          },
          vatAmount: {
            price: +vat,
            currency: this.paymentVoucherEditResponse.currencyList[0]
          },
          totalAmount: {
            price: +total,
            currency: this.paymentVoucherEditResponse.currencyList[0]
          }
        });
      }
    } else {
      formGroup.patchValue({
        totalAmount: null,
        vatAmount: null
      });
    }
    this.setTotalData();
  }

  public onChangeTotalAmount(data): void {
    const formGroup =
      this.tableResponseBillingValue.currentRowChange.row.formGroup;
    if (data) {
      const total = data.price;
      const taxPercentage = +formGroup.get('taxPercentageValue').value;
      if (total) {
        const taxBase = total / (1 + taxPercentage * 0.01);
        const taxBaseFixed = +taxBase.toFixed(2);
        const vat = (+taxBase * taxPercentage * 0.01).toFixed(2);

        formGroup.patchValue({
          taxBaseAmount: {
            price: +taxBaseFixed,
            currency: this.paymentVoucherEditResponse.currencyList[0]
          },
          vatAmount: {
            price: +vat,
            currency: this.paymentVoucherEditResponse.currencyList[0]
          },
          totalAmount: {
            price: total,
            currency: this.paymentVoucherEditResponse.currencyList[0]
          }
        });
      }
    } else {
      formGroup.patchValue({
        taxBaseAmount: null,
        vatAmount: null
      });
    }
    this.setTotalData();
  }

  public onChangeTaxPercentage(data): void {
    const formGroup =
      this.tableResponseBillingValue.currentRowChange.row.formGroup;
    if (data) {
      if (this.includeVAT) {
        const totalValue = formGroup.get('totalAmount').value;
        const taxPercentage = +data;
        if (totalValue && taxPercentage) {
          const total = +totalValue.price;
          const taxBase = total / (1 + taxPercentage * 0.01);
          const taxBaseFixed = +taxBase.toFixed(2);
          const vat = (+taxBase * taxPercentage * 0.01).toFixed(2);

          formGroup.patchValue({
            taxBaseAmount: {
              price: +taxBaseFixed,
              currency: this.paymentVoucherEditResponse.currencyList[0]
            },
            vatAmount: {
              price: +vat,
              currency: this.paymentVoucherEditResponse.currencyList[0]
            },
            totalAmount: {
              price: total,
              currency: this.paymentVoucherEditResponse.currencyList[0]
            }
          });
        }
      } else {
        const taxBase = +formGroup.get('taxBaseAmount').value.price;
        const taxPercentage = +data;
        if (taxBase && taxPercentage) {
          const vat = (+taxBase * taxPercentage * 0.01).toFixed(2);
          const total = +taxBase + +vat;
          formGroup.patchValue({
            taxBaseAmount: {
              price: taxBase,
              currency: this.paymentVoucherEditResponse.currencyList[0]
            },
            vatAmount: {
              price: +vat,
              currency: this.paymentVoucherEditResponse.currencyList[0]
            },
            totalAmount: {
              price: +total,
              currency: this.paymentVoucherEditResponse.currencyList[0]
            }
          });
        }
      }
    } else {
      if (this.includeVAT) {
        formGroup.patchValue({
          taxBaseAmount: null,
          vatAmount: null
        });
      } else {
        formGroup.patchValue({
          totalAmount: null,
          vatAmount: null
        });
      }
    }
    this.setTotalData();
  }

  public setTotalData(): void {
    this.totalTaxBase = 0;
    this.totalVAT = 0;
    this.totalPaid = 0;
    this.totalIncomeTax = 0;
    this.totalPaidToVendor = 0;
    this.totalOtherCost = 0;
    this.totalOtherCostDebit = 0;
    this.totalOtherCostCredit = 0;

    this.tableResponseBillingValue.formArray.value.forEach(voucherBilling => {
      this.totalTaxBase += voucherBilling.taxBaseAmount
        ? +voucherBilling.taxBaseAmount.price
        : 0;
      this.totalVAT += voucherBilling.vatAmount
        ? +voucherBilling.vatAmount.price
        : 0;
      this.totalPaid += voucherBilling.totalAmount
        ? +voucherBilling.totalAmount.price
        : 0;
    });

    this.paymentVoucherTaxList.forEach(voucherTax => {
      this.totalIncomeTax += voucherTax.incomeTaxAmount
        ? +voucherTax.incomeTaxAmount
        : 0;
    });
    if (this.paymentVoucherEditResponse.isWAPU) {
      this.totalPaidToVendor = +this.totalTaxBase - +this.totalIncomeTax;
    } else {
      this.totalPaidToVendor =
        +this.totalTaxBase + +this.totalVAT - +this.totalIncomeTax;
    }
    this.paymentVoucherOtherCostList.forEach(voucherOtherCost => {
      if (voucherOtherCost.amount && voucherOtherCost.balanceType) {
        if (
          voucherOtherCost.balanceType ===
          this.global.appConstant.cm.BALANCE_TYPE_DEBIT
        ) {
          this.totalPaidToVendor += +voucherOtherCost.amount;
          this.totalOtherCostDebit += +voucherOtherCost.amount;
        } else {
          this.totalPaidToVendor -= +voucherOtherCost.amount;
          this.totalOtherCostCredit += +voucherOtherCost.amount;
        }
      }
    });
    this.totalOtherCost =
      +this.totalOtherCostCredit + +this.totalOtherCostDebit;
    this.setTablePaidToVendor();
  }

  public doAddTax(): void {
    this.appPopupService
      .open(
        PaymentVoucherPopupTaxComponent,
        {
          currencyList: this.paymentVoucherEditResponse.currencyList,
          taxList: this.paymentVoucherEditResponse.taxList,
          costCenterList: this.paymentVoucherEditResponse.costCenterList
        },
        {
          size: 'lg'
        }
      )
      .subscribe((paymentVoucherTax: PaymentVoucherTax) => {
        this.paymentVoucherTaxList.push(paymentVoucherTax);
        this.tableResponseTax.setRecordList(this.paymentVoucherTaxList);
        this.tableResponseTax.reload();
        this.setTotalData();
      });
  }

  public doClickTax(paymentVoucherTax: PaymentVoucherTax): void {
    this.appPopupService
      .open(
        PaymentVoucherPopupTaxComponent,
        {
          currencyList: this.paymentVoucherEditResponse.currencyList,
          taxList: this.paymentVoucherEditResponse.taxList,
          costCenterList: this.paymentVoucherEditResponse.costCenterList,
          paymentVoucherTax,
          todo: this.todo
        },
        {
          size: 'lg'
        }
      )
      .subscribe((voucherTax: PaymentVoucherTax) => {
        this.paymentVoucherTaxList.forEach((tax, index) => {
          if (tax === paymentVoucherTax) {
            this.paymentVoucherTaxList[index] = voucherTax;
          }
        });
        this.tableResponseTax.setRecordList(this.paymentVoucherTaxList);
        this.tableResponseTax.reload();
        this.setTotalData();
      });
  }

  public doDeleteTax(event): void {
    this.global.modalService
      .newDeleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach((record: PaymentVoucherTax) => {
            const indexOfRecord = this.paymentVoucherTaxList.findIndex(
              r => r === record
            );
            this.paymentVoucherTaxList.splice(indexOfRecord, 1);
          });
          this.tableResponseTax.setRecordList(this.paymentVoucherTaxList);
          this.tableResponseTax.reload();
          this.setTotalData();
        }
      });
  }

  public doAddOtherCost(): void {
    this.appPopupService
      .open(
        PaymentVoucherPopupOtherCostComponent,
        {
          currencyList: this.paymentVoucherEditResponse.currencyList,
          otherCostList: this.paymentVoucherEditResponse.otherCostList,
          costCenterList: this.paymentVoucherEditResponse.costCenterList
        },
        {
          size: 'lg'
        }
      )
      .subscribe((paymentVoucherOtherCost: PaymentVoucherOtherCost) => {
        this.paymentVoucherOtherCostList.push(paymentVoucherOtherCost);
        this.tableResponseOtherCost.setRecordList(
          this.paymentVoucherOtherCostList
        );
        this.tableResponseOtherCost.reload();
        this.setTotalData();
      });
  }

  public doClickOtherCost(
    paymentVoucherOtherCost: PaymentVoucherOtherCost
  ): void {
    this.appPopupService
      .open(
        PaymentVoucherPopupOtherCostComponent,
        {
          currencyList: this.paymentVoucherEditResponse.currencyList,
          otherCostList: this.paymentVoucherEditResponse.otherCostList,
          costCenterList: this.paymentVoucherEditResponse.costCenterList,
          paymentVoucherOtherCost,
          todo: this.todo
        },
        {
          size: 'lg'
        }
      )
      .subscribe((otherCost: PaymentVoucherOtherCost) => {
        this.paymentVoucherOtherCostList.forEach((cost, index) => {
          if (cost === paymentVoucherOtherCost) {
            this.paymentVoucherOtherCostList[index] = otherCost;
          }
        });
        this.tableResponseOtherCost.setRecordList(
          this.paymentVoucherOtherCostList
        );
        this.tableResponseOtherCost.reload();
        this.setTotalData();
      });
  }

  public doDeleteOtherCost(event): void {
    this.global.modalService
      .newDeleteConfirmation()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          event.selectedRecordList.forEach(
            (record: PaymentVoucherOtherCost) => {
              const indexOfRecord = this.paymentVoucherOtherCostList.findIndex(
                r => r === record
              );
              this.paymentVoucherOtherCostList.splice(indexOfRecord, 1);
            }
          );
          this.tableResponseOtherCost.setRecordList(
            this.paymentVoucherOtherCostList
          );
          this.tableResponseOtherCost.reload();
          this.setTotalData();
        }
      });
  }

  public setTablePaidToVendor(): void {
    const modelList: PaymentVoucherModel[] = [];
    const totalTaxBase = new PaymentVoucherModel();
    totalTaxBase.costName = this.global.translateService.instant(
      'payment-voucher.detail.table.costName.taxBaseTotal'
    );
    totalTaxBase.totalAmount = this.totalTaxBase;
    totalTaxBase.balanceType = this.global.appConstant.cm.BALANCE_TYPE_DEBIT;
    totalTaxBase.balanceTypeName = this.global.translateService.instant(
      'payment-voucher.balanceType.debit'
    );
    totalTaxBase.isShowInfoWAPU = false;
    modelList.push(totalTaxBase);

    const totalVAT = new PaymentVoucherModel();
    totalVAT.costName = this.global.translateService.instant(
      'payment-voucher.detail.table.costName.vatTotal'
    );
    totalVAT.totalAmount = this.totalVAT;
    totalVAT.balanceType = this.global.appConstant.cm.BALANCE_TYPE_DEBIT;
    totalVAT.balanceTypeName = this.global.translateService.instant(
      'payment-voucher.balanceType.debit'
    );
    totalVAT.isShowInfoWAPU = true;
    modelList.push(totalVAT);

    const totalIncomeTax = new PaymentVoucherModel();
    totalIncomeTax.costName = this.global.translateService.instant(
      'payment-voucher.detail.table.costName.incomeTaxTotal'
    );
    totalIncomeTax.totalAmount = this.totalIncomeTax;
    totalIncomeTax.balanceType = this.global.appConstant.cm.BALANCE_TYPE_CREDIT;
    totalIncomeTax.balanceTypeName = this.global.translateService.instant(
      'payment-voucher.balanceType.credit'
    );
    totalIncomeTax.isShowInfoWAPU = false;
    modelList.push(totalIncomeTax);

    const totalOtherCostDebit = new PaymentVoucherModel();
    totalOtherCostDebit.costName = this.global.translateService.instant(
      'payment-voucher.detail.table.costName.otherCostDebit'
    );
    totalOtherCostDebit.totalAmount = this.totalOtherCostDebit;
    totalOtherCostDebit.balanceType =
      this.global.appConstant.cm.BALANCE_TYPE_DEBIT;
    totalOtherCostDebit.balanceTypeName = this.global.translateService.instant(
      'payment-voucher.balanceType.debit'
    );
    totalOtherCostDebit.isShowInfoWAPU = false;
    modelList.push(totalOtherCostDebit);

    const totalOtherCostCredit = new PaymentVoucherModel();
    totalOtherCostCredit.costName = this.global.translateService.instant(
      'payment-voucher.detail.table.costName.otherCostCredit'
    );
    totalOtherCostCredit.totalAmount = this.totalOtherCostCredit;
    totalOtherCostCredit.balanceType =
      this.global.appConstant.cm.BALANCE_TYPE_CREDIT;
    totalOtherCostCredit.balanceTypeName = this.global.translateService.instant(
      'payment-voucher.balanceType.credit'
    );
    totalOtherCostCredit.isShowInfoWAPU = false;
    modelList.push(totalOtherCostCredit);

    this.tableResponsePaidToVendor.setRecordList(modelList);
    this.tableResponsePaidToVendor.reload();
  }

  public showIconInfo(model: PaymentVoucherModel): boolean {
    if (model.isShowInfoWAPU && this.paymentVoucherEditResponse.isWAPU) {
      return true;
    } else {
      return false;
    }
  }

  public doSave(): void {
    this.setStateProcessing();
    const request = this.prepareData(false);
    this.httpClientService
      .post<Response<PaymentVoucher>>('/payment-voucher/update', request)
      .subscribe(response => {
        this.setStateReady();
        if (response.status === ResponseStatusModel.OK) {
          this.snackbarService.showWarning('app.msg.info.sucsessSave');
          this.router
            .navigateByUrl('/', {
              skipLocationChange: true
            })
            .then(() => {
              this.global.routerParams.clear();
              this.global.routerParams.set('paymentId', this.paymentId);
              this.global.routerParams.set('todo', 'edit');
              this.global.routerParams.set(
                'paymentWorklistStatus',
                this.paymentWorklistStatus
              );
              this.router.navigate(['/pages/payment-voucher/detail']);
            });
        }
      });
  }

  public doSubmit(): void {
    const request = this.prepareData(true);
    this.validate();
    if (
      this.formGroup.valid &&
      this.totalPaid === this.paymentVoucherEditResponse.payment.amount
    ) {
      this.global.modalService
        .submitConfirmation()
        .pipe(take(1))
        .subscribe(result => {
          if (result) {
            this.setStateProcessing();
            this.httpClientService
              .post<Response<PaymentVoucher>>(
                '/payment-voucher/update',
                request
              )
              .subscribe(response => {
                this.setStateReady();
                if (response.status === ResponseStatusModel.OK) {
                  this.global.modalService
                    .submitSuccess()
                    .pipe(take(1))
                    .subscribe(result => {
                      if (result) {
                        this.router.navigate(['/pages/payment-voucher']);
                      } else {
                        this.router
                          .navigateByUrl('/', {
                            skipLocationChange: true
                          })
                          .then(() => {
                            this.global.routerParams.clear();
                            this.global.routerParams.set(
                              'paymentId',
                              this.paymentId
                            );
                            this.global.routerParams.set('todo', 'view');
                            this.global.routerParams.set(
                              'paymentWorklistStatus',
                              this.paymentWorklistStatus
                            );
                            this.router.navigate([
                              '/pages/payment-voucher/detail'
                            ]);
                          });
                      }
                    });
                }
              });
          }
        });
    }
  }

  public prepareData(isSubmit: boolean): PaymentVoucherUpdateRequest {
    const request = new PaymentVoucherUpdateRequest();
    const paymentVoucherBillingList: PaymentVoucherBilling[] = [];
    this.tableResponseBillingValue.formArray.value.forEach(
      (voucherBilling, index) => {
        const paymentVoucherBilling = this.paymentVoucherBillingList[index];
        paymentVoucherBilling.taxPercentageValue =
          voucherBilling.taxPercentageValue;
        paymentVoucherBilling.taxBaseAmount =
          voucherBilling.taxBaseAmount?.price;
        paymentVoucherBilling.vatAmount = voucherBilling.vatAmount?.price;
        paymentVoucherBilling.totalAmount = voucherBilling.totalAmount?.price;
        paymentVoucherBillingList.push(paymentVoucherBilling);
      }
    );
    request.paymentVoucherBillingList = paymentVoucherBillingList;

    request.paymentVoucherTaxList = this.paymentVoucherTaxList;
    request.paymentVoucherOtherCostList = this.paymentVoucherOtherCostList;
    if (!this.paymentVoucher) {
      this.paymentVoucher = new PaymentVoucher();
    }
    this.paymentVoucher.voucherDate = this.formGroup.value.voucherDate;
    this.paymentVoucher.code = this.formGroup.value.voucherNumber;
    this.paymentVoucher.isIncludeVat = this.formGroup.value.includeVat;
    this.paymentVoucher.totalIncomeTaxAmount = this.totalIncomeTax;
    this.paymentVoucher.totalOtherCostAmount = this.totalOtherCost;
    this.paymentVoucher.totalTaxBaseAmount = this.totalTaxBase;
    this.paymentVoucher.totalVatAmount = this.totalVAT;
    this.paymentVoucher.totalVoucherAmount = this.totalPaidToVendor;
    request.paymentVoucher = this.paymentVoucher;
    request.paymentId = this.paymentId;
    request.isSubmit = isSubmit;
    return request;
  }

  public get voucherBillingList(): FormArray {
    return this.formGroup.get('paymentVoucherBillingList') as FormArray;
  }
}
