import { BaseEntity } from "../base/base-entity";
import { Contract } from "../bean/contract";
import { File } from "../bean/file";
import { Module } from "../bean/module";

export class ContractFileHistoryEntity extends BaseEntity {
    contract: Contract;
    file: File;
    module: Module;
    sequence: number;
    description: string;
}