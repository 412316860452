import { Component } from '@angular/core';
import { BaseComponentComponent } from '../../base/angular/base-component.component';
@Component({
  templateUrl: './bar-chart-compare-popup.component.html'
})
export class BarChartComparePopupComponent extends BaseComponentComponent {
  constructor() {
    super('bar-chart-compare-popup');
  }

  onInit(): void {
    this.log.debug('[ Message From Pluto ]: Hola!');
  }
}
